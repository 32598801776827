// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

function t(original) {
  return original
}

const _ = require('lodash')

angular.module('apiconnect-assembly').controller('LtpaKeyListController', [
  '$rootScope',
  '$scope',
  'translateFilter',
  function($rootScope, $scope, translateFilter) {
    const ltpaKeys = []
    if (false && !$rootScope.offlineMode) {
      LtpaKey.query({orgId: $rootScope.orgId}).$promise.then(function(keys) {
        const uniqueNames = {}
        keys.forEach(function(key) {
          if (!uniqueNames[key.info.name]) {
            uniqueNames[key.ifo.name] = true
            ltpaKeys.push({
              name: `${key.info.title}, ${translateFilter(
                'assembly_latest_version'
              )}`,
              value: `${key.info.name}:latest`,
            })
          }
          ltpaKeys.push({
            name: `${key.info.title}, ${key.info.version}`,
            value: `${key.info.name}:${key.info.version}`,
          })
        })
      })
    }
    this.ltpaKeys = ltpaKeys
  },
])

angular.module('apiconnect-assembly').controller('AssemblerController', [
  '$rootScope',
  '$scope',
  '$injector',
  'translateFilter',
  '$timeout',
  '$mdSidenav',
  'policyService',
  'AssemblerTracking',
  'triggerService',
  'canvasAnimationService',
  'assemblyModel',
  function(
    $rootScope,
    $scope,
    $injector,
    translateFilter,
    $timeout,
    $mdSidenav,
    policyService,
    AssemblerTracking,
    triggerService,
    canvasAnimationService,
    assemblyModel
  ) {
    const $ctrl = $scope.AssemblerController || this
    const selectedIndex = -1

    // This function is used to clean up the swagger document and remove
    // extra properties added by Angular before sending it upstream.
    var cleanSwagger = function(old) {
      if (typeof old !== 'object' || old == null) {
        return old
      }

      // filter out garbage Angular data (starts with $$) in the model
      const keys = Object.keys(old).filter(function(key) {
        return !key.startsWith('$$') || key === '$ref'
      })
      const obj = {}

      keys.forEach(function(key) {
        const value = old[key]

        // clean up any empty fields
        if (value == null) {
          return
        }

        if (typeof value !== 'object') {
          obj[key] = value
        } else if (
          typeof value.length !== 'object' &&
          value.hasOwnProperty('length')
        ) {
          obj[key] = value.map(cleanSwagger)
        } else {
          obj[key] = cleanSwagger(old[key])
        }
      })

      return obj
    }

    $ctrl.$onChanges = function(changeSet) {
      if (changeSet.orgId) {
        $rootScope.orgId = changeSet.orgId.currentValue
      }

      if (changeSet.tlsProfiles) {
        $rootScope.tlsProfiles = changeSet.tlsProfiles.currentValue
      }

      if (changeSet.userRegistries) {
        $rootScope.userRegistries = changeSet.userRegistries.currentValue
      }

      if (changeSet.isOffline && changeSet.isOffline.currentValue) {
        $rootScope.offlineMode = changeSet.isOffline.currentValue.status
        if (
          localStorage.getItem('IS_DESIGNER') === 'true' &&
          !$scope.isDesignerOffline()
        ) {
          $rootScope.offlineMode = false
        }
      }
    }

    $scope.$watch('AssemblerController.swaggerDocument', function(newValue) {
      if (newValue) {
        $scope.swaggerDocument = newValue
        if ($scope.swaggerDocument['x-ibm-configuration']) {
          if ($scope.swaggerDocument['x-ibm-configuration'].type === 'oauth') {
            $rootScope.isOAuth = true
            $scope.isOAuth = true
          }
          if (
            $scope.swaggerDocument['x-ibm-configuration'].type === 'graphql'
          ) {
            $scope.isGraphql = true
          }
          $scope.isEnforced =
            $scope.swaggerDocument['x-ibm-configuration'].enforced
        }
        processSwaggerDocument()
        $scope.setupPolicies($scope.AssemblerController.policyList)
      }
    })

    $scope.$watch('AssemblerController.gateways', function(newValue) {
      $scope.gateways = newValue
    })

    $scope.$watch(
      'swaggerDocument',
      function(newVal) {
        if (newVal) {
          $ctrl.onSwaggerUpdate({
            swagger: cleanSwagger(newVal),
          })
        }
      },
      true
    )

    $scope.setupPolicies = function(gatewayPolicies) {
      const getPolicyKey = function(policy) {
        const policyVersion = policy.info.version || '1.0.0'
        return [policy.info.name, policyVersion].join(':')
      }

      const factoryInstance = $injector.get($scope.config.policies)
      const supportedPolicies = gatewayPolicies
        ? gatewayPolicies.map(getPolicyKey)
        : []
      const apiGatewayType = _.get(
        $scope.swaggerDocument,
        ['x-ibm-configuration', 'gateway'],
        'datapower-gateway'
      )
      const isTrial = localStorage.getItem('IS_TRIAL') === 'true'

      const policyIsSupported = function(policy) {
        const policyKey = getPolicyKey(policy)

        if (policy.info.assembly_scope) {
          const scope = policy.info.assembly_scope
          switch (scope) {
            case 'all':
              policy.supported = true
              break
            case 'catalog':
              policy.supported = false
              break
            case 'api':
              policy.supported = true
              break
            default:
              break
          }
        } else {
          policy.info.assembly_scope = 'all'
        }

        if (
          apiGatewayType === 'datapower-gateway' &&
          policyKey === 'invoke:1.5.0'
        ) {
          policy.supported = false
          return policy
        }

        if (policy.gateways.indexOf(apiGatewayType) === -1) {
          console.log(
            'policy is not supported by API gateway type',
            policyKey,
            policy.gateways
          )
          policy.supported = false
        } else if (
          supportedPolicies.length &&
          supportedPolicies.indexOf(policyKey) === -1
        ) {
          console.log(
            'policy is not supported by configured gateway',
            policyKey
          )
          policy.supported = false
        } else {
          if (
            (policyKey === 'websocket-upgrade:2.0.0' && !$scope.isGraphql) ||
            (policyKey === 'oauth:2.0.0' && !$scope.isOAuth)
          ) {
            policy.supported = false
          } else {
            policy.supported = true
          }
        }

        if (policy.custom) {
          const legacyPolicies = [
            'gatewayscript:1.0.0',
            'invoke:1.0.0',
            'invoke:1.5.0',
            'json-to-xml:1.0.0',
            'jwt-generate:1.0.0',
            'jwt-validate:1.0.0',
            'activity-log:1.0.0',
            'activity-log:1.5.0',
            'map:1.0.0',
            'proxy:1.0.0',
            'proxy:1.5.0',
            'redact:1.0.0',
            'redact:1.5.0',
            'validate:1.0.0',
            'set-variable:1.0.0',
            'switch:1.0.0',
            'switch:1.5.0',
            'if:1.5.0',
            'xslt:1.0.0',
            'validate-usernametoken:1.0.0',
            'validate-usernametoken:1.1.0',
            'xml-to-json:1.0.0',
          ]

          legacyPolicies.forEach(p => {
            if (p === policyKey) {
              policy.legacy = true
            }
          })

          const hiddenPolicies = [
            'client-identification:2.0.0',
            'cors:2.0.0',
            'security:2.0.0',
          ]

          hiddenPolicies.forEach(p => {
            if (p === policyKey) {
              policy.supported = false
            }
          })
        }

        if (isTrial) {
          // Hide policies for cloud trial only
          const hiddenPolicies = [
            'xslt:2.0.0',
            'log:2.0.0',
            'ratelimit:2.0.0',
            'gatewayscript:2.0.0',
          ]
          hiddenPolicies.forEach(p => {
            if (p === policyKey) {
              policy.supported = false
            }
          })
        }
        return policy
      }

      $scope.getPolicyPartial = factoryInstance.getPolicyPartial

      factoryInstance.getPolicies(gatewayPolicies).then(function(policies) {
        const knownPolicies = policies.map(getPolicyKey)

        for (const policy of supportedPolicies) {
          if (knownPolicies.indexOf(policy) === -1) {
            console.warn('gateway supports unknown policy type:', policy)
          }
        }

        $scope.policies = policies.map(policyIsSupported)
      })
    }

    $scope.$watch('AssemblerController.policyList', function(policyList) {
      // if there are no supported policies, then we're probably operating
      // against the other gateway type and we should ignore supported policies
      if (Array.isArray(policyList)) $scope.setupPolicies(policyList)
    })

    $scope.$watch('policies', function() {
      if ($scope.policies) {
        const hasSupportedPolicies =
          $scope.policies.filter(p => {
            return p.supported
          }).length > 0
        if (!hasSupportedPolicies) $scope.setupPolicies([])
        $scope.filterCategories()
      }
    })

    $ctrl.$onInit = function() {
      if ($ctrl.assemblerOptions) {
        // parse the options
        $scope.config = JSON.parse($ctrl.assemblerOptions)
        if (!$scope.config.property)
          $scope.config.property = 'x-ibm-configuration'
        $scope.swaggerDocument =
          $ctrl.swaggerDocument || $scope.$parent[$scope.config.source]
        if ($scope.config.references)
          $scope.references = $scope.$parent[$scope.config.references]
        $scope.swaggerSchema = $scope.$parent[$scope.config.schema]
        $scope.externalAssemblies =
          $scope.$parent[$scope.config.externalAssemblies]
      }
    }

    $scope.makeOnline = function() {
      $ctrl.onMakeOnline()
    }

    $scope.createAndPublishProduct = function() {
      $ctrl.onCreateAndPublishProduct()
    }

    $scope.saveAndRepublishProduct = function(product, target) {
      $ctrl.onSaveAndRepublishProduct()
    }

    $scope.republishProduct = function(productName, catalog, subscription) {
      $ctrl.onPublishProduct({
        type: 'republish',
        productName,
        catalog,
        subscription,
      })
    }

    $scope.createApplication = function(application, consumerOrg) {
      $ctrl.onCreateApplication({application, consumerOrg})
    }

    $scope.changeCatalog = function(catalog) {
      $ctrl.onChangeCatalog({catalog})
    }

    $scope.subscribeApp = function(product, plan, application) {
      $ctrl.onSubscribeApp({product, plan, application})
    }

    $scope.updateSelectedNode = function(node) {
      const nodes = $scope.swaggerDocument[$scope.config.property].assembly
      const address = node.$$address || node.$$type
      const path = address.split('.')
      const key = path.pop()

      try {
        const location = path.reduce((t, k) => {
          return t[k]
        }, nodes)
        location[key] = node[node.$$type] || node
      } catch (e) {
        console.warn('unable to update node', node)
      }
    }

    const rootScopeListeners = []
    const animationDelay = 150

    $scope.index = -1
    $scope.isAnimating = false

    // Flow ID from APPC persistence
    $scope.gotFlowID = false

    $scope.aceLoaded = function(editor) {
      $scope.editor = editor // save editor reference
      editor.setReadOnly(true)
      editor.$blockScrolling = 'Infinity' // disable Ace warning
    }

    $scope.isFlowSlim = function() {
      if ($scope.config) {
        return $scope.config.slimFlowEditor
      }

      return false
    }

    // so NodeController can unset selectedNode
    $scope.unsetSelectedNode = function() {
      $scope.applyToEachNode($scope.nodes, function(node) {
        node.$$isSelected = false
      })
      $scope.selectedNode = null
    }

    const toggleFunction = function(isSlimFlow) {
      $scope.config.slimFlowEditor = isSlimFlow
      if (isSlimFlow) {
        $scope.infoPanelBottom = true
        $scope.scaleFactor = 1
        $scope.nodeFilter = ''
        $scope.infoPanelOpen = true

        // Always select a node
        $scope.selectedNode = $scope.selectedNode
          ? $scope.selectedNode
          : $scope.trigger || $scope.request
        $scope.selectedNode.$$isSelected = true

        $mdSidenav('assembly-info').open()
        $timeout(function() {
          canvasAnimationService.reset()
          $scope.animateCanvasSlide($scope)
        }, 400)
      } else {
        // Default to RHS panel in full mode
        $scope.infoPanelBottom = false
        $scope.horizontalTranslation = 0
      }
    }

    $scope.isAppConnect = function() {
      return $scope.config.isAppConnect
    }

    $scope.isDesignerOffline = function() {
      return localStorage.getItem('DESIGNER_OFFLINE') === 'true'
    }

    $scope.allowNewNode = function() {
      return $scope.nodes.length < $scope.config.maxNodes
    }

    if ($scope.helpEnabled) {
      $scope.showHelp({
        id: 'apim_help_api_editor_assemble',
        template: 'apim/help/partials/help-api-editor-assemble.html',
      })
    }

    if (localStorage !== undefined) {
      $scope.showCatches =
        localStorage.getItem('apim-assembly-show-catches') === 'true'
    }

    $scope.$watch('showCatches', function() {
      if (localStorage !== undefined) {
        localStorage.setItem('apim-assembly-show-catches', $scope.showCatches)
      }
    })

    $scope.jumpToCode = function(policyName, instance) {
      $scope.$emit('jump-to-policy', policyName, instance)
    }

    $scope.subflows = []

    function addExternalAssembly(assemblyName) {
      const name = assemblyName.split('/').pop()
      const templateObj = {
        info: {
          description: assemblyName,
          name,
          title: name,
          display: {
            color: '#4B68FA',
            icon: 'open_in_browser',
          },
          categories: [translateFilter('Policy Assemblies')],
        },
        type: 'subflow',
        // assembly: $scope.externalAssemblies[assemblyName],
        assembly: {
          $ref: assemblyName,
        },
      }
      $scope.subflows.push(templateObj)
      $scope.policiesByType[name] = templateObj
    }

    let snippedNodes = []

    $scope.toggleSnipMode = function() {
      $scope.snipping = !$scope.snipping
      if (!$scope.snipping) {
        // clear any snipped flags
        snippedNodes.forEach(function(node) {
          delete node.$$snipped
        })
      }
      snippedNodes = []
      $scope.snippedAssembly = {
        execute: [],
        catch: [],
      }
    }
    $scope.snippedAssembly = {
      execute: [],
      catch: [],
    }

    $scope.highlightSnippedNode = function(event, node) {
      if (!$scope.snipping) return
      node.$$snipped = !node.$$snipped
      if (!node.$$snipped) {
        snippedNodes = snippedNodes.filter(function(thisNode) {
          return thisNode !== node
        })
      } else {
        snippedNodes.push(node)
      }
      const execute = []
      snippedNodes.forEach(function(node) {
        const thisNode = {}
        thisNode[node.$$type] = node
        execute.push(thisNode)
      })
      $scope.snippedAssembly.execute = execute
    }
    $scope.$on('node-selected', $scope.highlightSnippedNode)

    $scope.snippedAssemblyEmpty = function() {
      if (
        $scope.snippedAssembly.execute.length === 0 &&
        $scope.snippedAssembly.catch.length === 0
      ) {
        return translateFilter('assembly_nothing_to_snip')
      }
    }

    $scope.replaceWithReference = function($event, referenceData) {
      if (snippedNodes.length === 0) return

      addExternalAssembly(referenceData.reference)

      // drop in a new external assembly at the location of the first
      // selected node...
      const targetNode = snippedNodes[0]

      if (
        targetNode.$$container === undefined ||
        targetNode.$$containerIndex === undefined
      ) {
        return
      }
      const newNode = {
        assembly: {
          $ref: referenceData.reference,
        },
      }

      newNode.assembly.title = referenceData.reference.split('/').pop()

      // insert newNode into node.$$container at node.$$containerIndex + 1
      targetNode.$$container.splice(targetNode.$$containerIndex + 1, 0, newNode)

      // remove the old nodes
      snippedNodes.forEach(function(node) {
        $scope.deleteNode(null, node)
      })

      $scope.snippedAssembly = {
        execute: [],
        catch: [],
      }
      $scope.snipping = false
    }
    $scope.$on('reference_created', $scope.replaceWithReference)

    $scope.setTestMode = function(testMode, debugMode, clearFilter) {
      $scope.testMode = testMode
      $scope.debugMode = debugMode
      if (clearFilter) {
        delete $scope.operationFilter
        $scope.showOperationFilter = false
      }
    }

    $scope.setApplication = function(appId) {
      $ctrl.onSetApplication({appId})
    }

    $scope.setProduct = function(product) {
      $ctrl.onSetProduct({product})
    }

    $scope.setProductName = function(productName) {
      $ctrl.onSetProductName({productName})
    }

    // TODO - temporarily make work-in-progress category collapsed by default
    $scope.hideCategory = {
      'work-in-progress': true,
    }

    $scope.renderingAssembly = true
    $scope.labelPlacement = 'right'
    $scope.model = assemblyModel.flow
    $scope.nodes = $scope.model.nodes

    $scope.isDraggable = function() {
      // disable drag and drop when an operation filter is set
      return !$scope.operationFilter
    }

    function processSwaggerDocument() {
      if (!$scope.swaggerDocument) {
        return
      }

      if ($scope.infoPanelBottom === undefined) {
        $scope.infoPanelBottom =
          $scope.config && $scope.config.flyout === 'bottom'
      }

      $scope.producesContent = []
      $scope.consumesContent = []
      if ($scope.swaggerDocument.produces) {
        $scope.producesContent = $scope.swaggerDocument.produces
      }
      if ($scope.swaggerDocument.consumes) {
        $scope.consumesContent = $scope.swaggerDocument.consumes
      }

      const ibmConfig = $scope.swaggerDocument[$scope.config.property]
      let unwatchFunc

      $scope.noAssembly = false

      if (ibmConfig && ibmConfig.assembly) {
        $scope.nodes = ibmConfig.assembly.execute
        unwatchFunc = $scope.$watch('showCatches', function() {
          if ($scope.showCatches) {
            if (!ibmConfig.assembly.catch) {
              ibmConfig.assembly.catch = []
            }
            $scope.node = {
              catch: ibmConfig.assembly.catch,
              $$type: 'catch',
            }
            unwatchFunc()
          }
        })
      } else if ($scope.swaggerDocument && $scope.swaggerDocument.assembly) {
        $scope.nodes = $scope.swaggerDocument.execute
        unwatchFunc = $scope.$watch('showCatches', function() {
          if ($scope.showCatches) {
            if (!$scope.swaggerDocument.catch) {
              $scope.swaggerDocument.catch = []
            }
            $scope.node = {
              catch: $scope.swaggerDocument.catch,
              $$type: 'catch',
            }
            unwatchFunc()
          }
        })
      } else {
        $scope.noAssembly = true
      }

      /**
       * @param obj is a path[verb].requestBody or a path[verb].responses[response]
       */
      function getSchemaForRequestOrResponse(obj) {
        // In V2, the schema is directly within the obj
        // In V3, it is nested within a content object
        if (obj.schema) {
          return obj.schema
        } else if (obj.content) {
          const contentTypes = Object.keys(obj.content)
          if (contentTypes.length > 0) {
            return obj.content[contentTypes[0]].schema
          }
        }
        return null
      }

      // pick out any templates
      if (
        $scope.swaggerDocument[$scope.config.property] &&
        $scope.swaggerDocument[$scope.config.property].targets
      ) {
        const templates = []
        const isAPIGW =
          $scope.swaggerDocument['x-ibm-configuration'] &&
          $scope.swaggerDocument['x-ibm-configuration'].gateway ===
            'datapower-api-gateway'
        Object.keys(
          $scope.swaggerDocument[$scope.config.property].targets
        ).forEach(function(wsdlName) {
          const wsdl =
            $scope.swaggerDocument[$scope.config.property].targets[wsdlName]
          if (!wsdl.paths) return
          let targetUrl = ''
          if (
            wsdl['x-ibm-configuration'] &&
            wsdl['x-ibm-configuration'].assembly &&
            wsdl['x-ibm-configuration'].assembly.execute
          ) {
            // Inspect the target service's proxy or invoke to get the targetUrl
            const proxy = wsdl['x-ibm-configuration'].assembly.execute.filter(
              function(policy) {
                return policy.proxy !== undefined
              }
            )
            if (proxy.length > 0) {
              targetUrl = proxy[0].proxy['target-url']
            } else {
              const invoke = wsdl[
                'x-ibm-configuration'
              ].assembly.execute.filter(function(policy) {
                return policy.invoke !== undefined
              })
              if (invoke.length > 0) {
                targetUrl = invoke[0].invoke['target-url']
              }
            }
          }
          // if (!targetUrl) return;
          Object.keys(wsdl.paths).forEach(function(pathName) {
            const operation = wsdl.paths[pathName].post
            // In OAI V2, the body is one of the parameters
            // In OAI V3, the body is defined in requestBody
            if (
              !operation ||
              !(
                operation.parameters ||
                (operation.requestBody && operation.requestBody.content)
              ) ||
              !operation.responses
            )
              return
            let inputSchema = null
            if (operation.requestBody) {
              // OAI V3
              // Use the schema of the requestBody
              inputSchema = getSchemaForRequestOrResponse(operation.requestBody)
            } else {
              // OAI V2
              const bodyParam = operation.parameters.filter(function(param) {
                return param.in === 'body'
              })
              if (bodyParam.length > 0) inputSchema = bodyParam[0].schema
            }
            let outputSchema = null
            if (operation.responses.default)
              outputSchema = getSchemaForRequestOrResponse(
                operation.responses.default
              )
            if (!inputSchema || !outputSchema) return
            let soapVersion = '1.1'
            if (
              wsdl['x-ibm-configuration'] &&
              wsdl['x-ibm-configuration']['wsdl-definition'] &&
              wsdl['x-ibm-configuration']['wsdl-definition']['soap-version']
            ) {
              soapVersion =
                wsdl['x-ibm-configuration']['wsdl-definition']['soap-version']
            }
            const soapAction = operation['x-ibm-soap']['soap-action']
            let contentType = 'text/xml'
            if (soapVersion === '1.2') {
              contentType = `application/soap+xml; charset=UTF-8; action="${soapAction}"`
            }
            const templateObj = {
              info: {
                description: operation.description,
                name: operation.operationId,
                display: {
                  color: '#A56EFF',
                  icon: 'bee',
                },
                categories: ['Web Service Operation'],
              },
              type: 'template',
            }
            if (isAPIGW) {
              // Note that the customer must supply the input(s) to the map (and the output(s) of the second map)
              // The onus is on the customer to ensure that the supplied input(s) are already parsed.
              templateObj.assembly = [
                {
                  map: {
                    version: '2.0.0',
                    title: `${operation.operationId}: input`,
                    inputs: {},
                    outputs: {
                      body: {
                        schema: inputSchema,
                        variable: 'message.body',
                        content: 'application/xml',
                      },
                      'content-type': {
                        schema: {
                          type: 'string',
                        },
                        variable: 'message.headers.content-type',
                      },
                    },
                    actions: [
                      {
                        set: 'content-type',
                        default: contentType,
                      },
                    ],
                  },
                },
                {
                  invoke: {
                    version: '2.0.0',
                    title: `${operation.operationId}: invoke`,
                    'target-url': targetUrl,
                    timeout: 60,
                    verb: 'POST',
                    'cache-response': 'protocol',
                    'cache-ttl': 900,
                    'stop-on-error': ['ConnectionError', 'OperationError'],
                    output: `${operation.operationId}Out`,
                  },
                },
                {
                  parse: {
                    version: '2.0.0',
                    title: `${operation.operationId}: output parse`,
                    'parse-settings-reference': {
                      default: 'apic-default-parsesettings',
                    },
                    input: `${operation.operationId}Out`,
                    output: `${operation.operationId}Out`,
                  },
                },
                {
                  map: {
                    version: '2.0.0',
                    title: `${operation.operationId}: output`,
                    inputs: {
                      input: {
                        schema: outputSchema,
                        variable: `${operation.operationId}Out.body`,
                        content: 'application/xml',
                      },
                    },
                    outputs: {},
                    actions: [],
                  },
                },
              ]
            } else {
              templateObj.assembly = [
                {
                  map: {
                    title: `${operation.operationId}: input`,
                    inputs: {},
                    outputs: {
                      body: {
                        schema: inputSchema,
                        variable: 'message.body',
                        content: 'application/xml',
                      },
                      'content-type': {
                        schema: {
                          type: 'string',
                        },
                        variable: 'message.headers.content-type',
                      },
                    },
                    actions: [
                      {
                        set: 'content-type',
                        default: contentType,
                      },
                    ],
                  },
                },
                {
                  invoke: {
                    title: `${operation.operationId}: invoke`,
                    'target-url': targetUrl,
                    timeout: 60,
                    verb: 'POST',
                    'cache-response': 'protocol',
                    'cache-ttl': 900,
                    output: `${operation.operationId}Out`,
                  },
                },
                {
                  map: {
                    title: `${operation.operationId}: output`,
                    inputs: {
                      input: {
                        schema: outputSchema,
                        variable: `${operation.operationId}Out.body`,
                        content: 'application/xml',
                      },
                    },
                    outputs: {},
                    actions: [],
                  },
                },
              ]
            }
            if (soapVersion === '1.1') {
              templateObj.assembly[0].map.outputs.SOAPAction = {
                schema: {
                  type: 'string',
                },
                variable: 'message.headers.SOAPAction',
              }
              templateObj.assembly[0].map.actions.push({
                set: 'SOAPAction',
                default: soapAction,
              })
            }
            templates.push(templateObj)
          })
        })
        $scope.templates = templates
      }

      // pick out any external assemblies
      if ($scope.externalAssemblies) {
        $scope.allowReferences = true
        Object.keys($scope.externalAssemblies).forEach(function(assemblyName) {
          addExternalAssembly(assemblyName)
        })
      }

      // set up default for showing gateway filter
      $scope.showPolicyFilter =
        !$scope.swaggerDocument[$scope.config.property] ||
        !$scope.swaggerDocument[$scope.config.property].gateway

      // if we're in the context of a swagger document, pull out all the operations for later reference
      // In OAI V2, the swagger property contains the version
      // In OAI V3, the openapi property contains the version
      if ($scope.swaggerDocument.swagger || $scope.swaggerDocument.openapi) {
        const operations = []
        const legacyOperations = []
        const operationMap = {}
        if ($scope.swaggerDocument.paths) {
          Object.keys($scope.swaggerDocument.paths).forEach(function(path) {
            Object.keys($scope.swaggerDocument.paths[path]).forEach(function(
              verb
            ) {
              if (verb.indexOf('$$') === 0) {
                return
              }
              if (verb === 'parameters') {
                return
              }
              // Gather OAI V3 request/response content information
              for (const response in $scope.swaggerDocument.paths[path][verb]
                .responses) {
                if (response.content) {
                  for (const responseContentType in response.content) {
                    if (
                      $scope.producesContent.indexOf(responseContentType) < 0
                    ) {
                      $scope.producesContent.push(responseContentType)
                    }
                  }
                }
              }
              if ($scope.swaggerDocument.paths[path][verb].requestBody) {
                if (
                  $scope.swaggerDocument.paths[path][verb].requestBody.content
                ) {
                  for (const requestContentType in $scope.swaggerDocument.paths[
                    path
                  ][verb].requestBody.content) {
                    if (
                      $scope.consumesContent.indexOf(requestContentType) < 0
                    ) {
                      $scope.consumesContent.push(requestContentType)
                    }
                  }
                }
              }
              let fullPath = path
              if (
                $scope.swaggerDocument.basePath &&
                $scope.swaggerDocument.basePath !== ''
              ) {
                fullPath = $scope.swaggerDocument.basePath + fullPath
              }
              let item
              if ($scope.swaggerDocument.paths[path][verb].operationId) {
                item = {
                  operationId:
                    $scope.swaggerDocument.paths[path][verb].operationId,
                  verb,
                  path,
                  $$expressionString:
                    $scope.swaggerDocument['x-ibm-configuration'] &&
                    $scope.swaggerDocument['x-ibm-configuration'].gateway ===
                      'datapower-api-gateway'
                      ? `$operationID() = '${$scope.swaggerDocument.paths[path][verb].operationId}'`
                      : `api.operation.id==='${$scope.swaggerDocument.paths[path][verb].operationId}'`,
                }
                operations.push(item)
                legacyOperations.push(
                  $scope.swaggerDocument.paths[path][verb].operationId
                )
                operationMap[
                  $scope.swaggerDocument.paths[path][verb].operationId
                ] = {
                  verb,
                  path,
                }
                // operationMap[$scope.swaggerDocument.paths[path][verb].operationId] = item;
                operationMap[`${verb}:${path}`] =
                  $scope.swaggerDocument.paths[path][verb].operationId
              } else {
                item = {
                  verb,
                  path,
                  $$expressionString:
                    $scope.swaggerDocument['x-ibm-configuration'] &&
                    $scope.swaggerDocument['x-ibm-configuration'].gateway ===
                      'datapower-api-gateway'
                      ? `$httpVerb() = '${verb.toUpperCase()}' and $operationPath() = '${path}'`
                      : `request.verb==='${verb.toUpperCase()}'&&api.operation.path==='${path}'`,
                }
                operations.push(item)
                legacyOperations.push(item)
              }
            })
          })
        }
        $rootScope.swaggerOperations = operations
        $scope.swaggerOperations = operations
        $scope.swaggerLegacyOperations = legacyOperations
        $scope.operationMap = operationMap
      }
    }

    $scope.createAssembly = function() {
      if (!$scope.swaggerDocument[$scope.config.property]) {
        $scope.swaggerDocument[$scope.config.property] = {}
      }
      if (!$scope.swaggerDocument[$scope.config.property].assembly) {
        $scope.swaggerDocument[$scope.config.property].assembly = {
          execute: [],
          catch: [],
        }
      }
      $scope.noAssembly = false
      processSwaggerDocument()
    }

    $scope.policyDropped = function($data, node, $index, $secondIndex, clone) {
      // node may be a policy template, or a policy instance...
      // if it's a policy type, then we're dragging from the palette
      // if it's a policy instance, we're dragging from the canvas

      if ($data.info) {
        // dropped a policy - send a tracking event
        const policyName = $data.info.name
        const categories = $data.info.categories
          ? $data.info.categories.join(', ')
          : ''
        AssemblerTracking.track('assembly', 'policyDropped', {
          policyName,
          categories,
        })
      }

      let nodeToInsert
      let nodesToInsert
      if ($data && $data.hashKey) {
        // we have a policy instance

        // these events are firing twice... sigh
        //$scope.dropDoubleFiring = !$scope.dropDoubleFiring;
        //if ($scope.dropDoubleFiring) return;

        nodeToInsert = {}
        nodeToInsert[$data.type] = $data.node
        // TODO remove this code once https://github.ibm.com/apimesh/apiconnect-assembly/issues/97 is fixed
        nodeToInsert[$data.type].$$type = $data.type
        if ($data.hashKey) {
          // dropping an existing node on canvas
          nodeToInsert[$data.type].$$touched = $scope.selectedNode
            ? $scope.selectedNode.$$touched
            : false
        }
      } else {
        // no event double firing in the new policy case
        //$scope.dropDoubleFiring = false;

        if ($data.type === 'template') {
          // we have a template
          nodesToInsert = $data.assembly
        } else if ($data.type === 'subflow') {
          // we have a subflow
          nodeToInsert = {
            assembly: $data.assembly,
          }
          nodeToInsert.assembly.title = $data.info.name
        } else {
          // we have a policy template
          nodeToInsert = policyService.createPolicyInstance(
            $data,
            $scope.config.versioning
          )
        }
      }

      let i

      // insert the policy of type $data in the collection determined by node, at $index in the collection
      // if $secondIndex is provided, we're inserting in the nth element of a collection, which itself is an array
      // an index of -1 indicates that the new policy be inserted at the beginning
      // a node of null indicates we're inserting into the top level flow
      if (clone) {
        // clone the given node
        $scope.nodes.splice($index + 1, 0, nodeToInsert)
      } else if (!node) {
        if (nodesToInsert) {
          for (i = nodesToInsert.length - 1; i >= 0; i--) {
            $scope.nodes.splice($index + 1, 0, nodesToInsert[i])
          }
        } else {
          $scope.nodes.splice($index + 1, 0, nodeToInsert)
        }
      } else {
        // we're inserting into a clause or catch flow
        if (node.catch || node.case) {
          if ($index === -1) {
            // we're dropping an entirely new case here
            if (node.case) {
              if (node.$$type === 'operation-switch') {
                node.case.push({
                  operations: [],
                  execute: nodeToInsert ? [nodeToInsert] : nodesToInsert,
                })
              } else {
                // we're dropping into a switch... careful - there may be an otherwise case
                let spliceIndex = node.case.length
                if (node.case[spliceIndex - 1].otherwise) spliceIndex--
                node.case.splice(spliceIndex, 0, {
                  condition: '',
                  execute: nodeToInsert ? [nodeToInsert] : nodesToInsert,
                })
              }
            } else if (node.catch) {
              node.catch.push({
                errors: [],
                execute: nodeToInsert ? [nodeToInsert] : nodesToInsert,
              })
            }
          } else if ($index === -2) {
            // dropping into otherwise
            node.otherwise.splice($secondIndex + 1, 0, nodeToInsert)
          } else {
            const clause = node.catch ? node.catch[$index] : node.case[$index]
            if (clause.execute) {
              if (nodesToInsert) {
                for (i = nodesToInsert.length - 1; i >= 0; i--) {
                  clause.execute.splice($secondIndex + 1, 0, nodesToInsert[i])
                }
              } else {
                clause.execute.splice($secondIndex + 1, 0, nodeToInsert)
              }
            } else if (clause.otherwise) {
              if (nodesToInsert) {
                for (i = nodesToInsert.length - 1; i >= 0; i--) {
                  clause.otherwise.splice($secondIndex + 1, 0, nodesToInsert[i])
                }
              } else {
                clause.otherwise.splice($secondIndex + 1, 0, nodeToInsert)
              }
            } else if (clause.default) {
              if (nodesToInsert) {
                for (i = nodesToInsert.length - 1; i >= 0; i--) {
                  clause.default.splice($secondIndex + 1, 0, nodesToInsert[i])
                }
              } else {
                clause.default.splice($secondIndex + 1, 0, nodeToInsert)
              }
            }
          }
        }
        if (node.condition) {
          // no need for the first index
          if (nodesToInsert) {
            for (i = nodesToInsert.length - 1; i >= 0; i--) {
              node.execute.splice($secondIndex + 1, 0, nodesToInsert[i])
            }
          } else {
            node.execute.splice($secondIndex + 1, 0, nodeToInsert)
          }
        }
      }

      if ($data && $data.hashKey) {
        // remove the policy afterwards to avoid confusing the insert index
        $scope.removeObject($scope.nodes, $data.hashKey)
        if ($scope.node) {
          $scope.removeObject($scope.node.catch, $data.hashKey)
        }
      }

      // only make the inserted node as selected on node creation.
      // do not select existing nodes.
      // get node type from $data.info.name for a inserting node
      // node type will be stored in $data.type for an existing node
      if (nodeToInsert && $data.info && $data.info.name) {
        $scope.nodeSelected(null, nodeToInsert[$data.info.name])
      } else if (
        nodeToInsert &&
        $data.hashKey &&
        $scope.selectedNode &&
        $data.hashKey === $scope.selectedNode.$$hashKey &&
        $scope.infoPanelOpen
      ) {
        // dragging a node that currently is being editing
        $scope.nodeSelected(null, nodeToInsert[$data.type])
      } else {
        $scope.animateCanvasSlide($scope)
      }
    }

    $scope.applyToEachNode = function(nodes, callback) {
      // TODO currently we have 'execute' inside 'case' for 'switch' nodes
      // need to change this function if above structure changes.
      nodes.forEach(function(nextNode) {
        const key = Object.keys(nextNode)[0]
        if (nextNode[key].$$type) {
          // apply the callback function to the node
          callback(nextNode[key])
        }
        // if we're in an if node
        if (nextNode[key].execute) {
          $scope.applyToEachNode(nextNode[key].execute, callback)
        }
        // if we're in a switch node
        if (nextNode[key].case) {
          nextNode[key].case.forEach(function(caseObject) {
            $scope.applyToEachNode(
              caseObject.execute || caseObject.otherwise,
              callback
            )
          })
        }
      })
    }

    $scope.prevNode = {}

    $scope.ghostSelected = function($event, node, index, secondIndex) {
      if (!$scope.isAppConnect() || !$scope.allowNewNode()) {
        return
      }
      $scope.index = index
      $scope.parentNode = node
      $scope.secondIndex = secondIndex

      $scope.nodeSelected($event, 'NewAction')
    }

    $scope.nodeSelected = function($event, node) {
      if ($event) {
        $event.stopPropagation()
      }

      // Don't do anything if we've clicked the selectedNode
      if (node === $scope.selectedNode && node.$$isSelected) {
        return
      }

      if ($scope.isAppConnect() && node.$$type === 'switch') {
        node.$$minimized = false
      }

      // Remove the isSelected class from any ghostPolicy
      const selectedDropzone = angular.element(
        document.querySelector('.dropzoneGroupSelected')
      )
      if (selectedDropzone.length) {
        selectedDropzone[0].classList.remove('dropzoneGroupSelected')
        const selectedNewAction =
          selectedDropzone[0].getElementsByClassName('ghostPolicy')[0]
        selectedNewAction.classList.remove('isSelected')
      }

      if ($scope.debugMode) {
        $scope.$broadcast('node-selected', node)
      } else if ($scope.snipping) {
        $scope.$broadcast('node-selected', node)
      } else {
        const target = $event ? $event.currentTarget : undefined
        if (node === 'input' || node === 'output') {
          // no behavior defined in assembly mode for this...
          return
        }
        if (node === 'NewAction') {
          if (!$scope.policies || $scope.policies.length < 0) {
            return
          }
          let newActionPolicy = $scope.policies.filter(function(policy) {
            return policy.info.name === 'NewAction'
          })
          if (newActionPolicy && newActionPolicy.length > 0) {
            newActionPolicy = newActionPolicy[0]
            node = policyService.createPolicyInstance(newActionPolicy).NewAction
          }

          if (target) {
            target.classList.add('isSelected')
            target.parentElement.classList.add('dropzoneGroupSelected')
          } else {
            const dropzoneGroup = angular.element(
              document.getElementsByClassName('dropzoneGroup')
            )[$scope.index + 1]
            if (dropzoneGroup) {
              dropzoneGroup.classList.add('dropzoneGroupSelected')
              const newAction =
                dropzoneGroup.getElementsByClassName('ghostPolicy')[0]
              newAction.classList.add('isSelected')
            }
          }
        }

        if ($scope.selectedNode) {
          $scope.prevNode = $scope.selectedNode
          if (
            $scope.prevNode === $scope.trigger &&
            !$scope.prevNode.selectedApplication.name
          ) {
            // Don't set touched on an empty trigger...
          } else {
            $scope.prevNode.$$touched = true
            $scope.prevNode.$$isSelected = false
          }
        }

        // Unselect any trigger/request/response/nodes
        if ($scope.trigger) {
          $scope.trigger.$$isSelected = false
        }
        if ($scope.request) {
          $scope.request.$$isSelected = false
        }
        if ($scope.model.response) {
          $scope.model.response.$$isSelected = false
        }
        $scope.applyToEachNode($scope.nodes, function(node) {
          node.$$isSelected = false
        })

        // make sure we enter the info panel clean by allowing
        // time for a digest cycle before setting the new node
        delete $scope.selectedNode
        $timeout(function() {
          $scope.selectedNode = node
          node.$$isSelected = true
        }, 0)
        $timeout(function() {
          $scope.animateCanvasSlide($scope)
        }, 200)

        $scope.infoPanelOpen = true
        $mdSidenav('assembly-info').open()
      }
    }

    $scope.removeObject = function(theObject, hashKey) {
      if (!theObject) {
        return null
      }
      let result = null
      if (theObject.$$hashKey === hashKey) {
        return theObject
      }
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
          result = $scope.removeObject(theObject[i], hashKey)
          if (result) {
            theObject.splice(i, 1)
            break
          }
        }
      } else {
        for (const prop in theObject) {
          if (prop === '$$container') continue
          if (prop === '$$parent') continue
          if (prop === '$$hashKey') {
            if (theObject[prop] === hashKey) {
              return theObject
            }
          }
          if (
            theObject[prop] instanceof Object ||
            theObject[prop] instanceof Array
          ) {
            result = $scope.removeObject(theObject[prop], hashKey)
            if (result) {
              // throw the object back up one level - it'll then get spliced from the array
              return result
            }
          }
        }
      }
      return null
    }

    $scope.isClauseNode = function(node) {
      // if the node has an execute property that is an array, safe bet this is a clause node
      return angular.isArray(node.execute)
    }

    const callbackDeleteNode = function($event, node) {
      // Get the current index of the node before it is deleted
      const nodeIndex = $scope.nodes
        .map(function(pNode) {
          const key = Object.keys(pNode)[0]
          return pNode[key]
        })
        .indexOf(node)

      if ($event && $event.stopPropagation) $event.stopPropagation()
      $scope.removeObject($scope.nodes, node.$$hashKey)
      if ($scope.node) {
        $scope.removeObject($scope.node.catch, node.$$hashKey)
      }
      // close info panel if selectedNode is not on canvas
      // NB $scope.trigger is used for App Connect only but the logic below still work in general since $scope.trigger will be undefined.
      if (
        $scope.selectedNode !== $scope.trigger &&
        $scope.selectedNode !== $scope.request &&
        $scope.selectedNode !== $scope.response
      ) {
        // trigger/request/response are always on canvas

        if ($scope.selectedNode) {
          $scope.selectedNode.$$onCanvas = false
        }

        // check selectedNode is still on Canvas
        $scope.applyToEachNode($scope.nodes, function(node) {
          node.$$onCanvas = true
        })

        if ($scope.selectedNode && $scope.selectedNode.$$type !== 'NewAction') {
          if (!$scope.selectedNode.$$onCanvas) {
            // SelectedNode has been deleted so clear it
            delete $scope.selectedNode

            if ($scope.isAppConnect() && $scope.isFlowSlim()) {
              if ($scope.nodes.length) {
                // either select the node immediately to the right, or if we've deleted the last node
                // there is no node to the right, so select the previous node.
                const nearestNodeIndex =
                  nodeIndex < $scope.nodes.length
                    ? nodeIndex
                    : $scope.nodes.length - 1
                const key = Object.keys($scope.nodes[nearestNodeIndex])[0]
                $scope.nodeSelected(null, $scope.nodes[nearestNodeIndex][key])
              } else {
                // there are no nodes to select, so select the first dropzone
                $scope.ghostSelected(null, null, -1)
              }
            } else {
              $scope.closeInfo()
            }
          } else {
            $scope.animateCanvasSlide($scope)
          }

          // Special cases for NewAction
        } else if (
          $scope.selectedNode &&
          $scope.selectedNode.$$type === 'NewAction'
        ) {
          // Nested newAction should close info if parent deleted
          if (
            $scope.isAppConnect() &&
            !$scope.isFlowSlim() &&
            $scope.parentNode &&
            !$scope.parentNode.$$onCanvas
          ) {
            $scope.closeInfo()
            // Deleting node attached to newAction dropzone
          } else if ($scope.index === nodeIndex) {
            $scope.index = $scope.index - 1
            $scope.nodeSelected(null, 'NewAction')
          } else {
            // Deleting node before newAction
            if ($scope.index > nodeIndex) {
              $scope.index = $scope.index - 1
            }
            $scope.animateCanvasSlide($scope)
          }
        }
      } else {
        $scope.animateCanvasSlide($scope)
      }
    }

    $scope.deleteNode = function($event, node) {
      // Display dialog for multiple actions in IF node
      if ($scope.isAppConnect() && assemblyModel.countInnerActions(node) > 0) {
        const numberOfActions = assemblyModel.countInnerActions(node)
        $rootScope.$emit(
          'confirmationDialog',
          callbackDeleteNode,
          $event,
          node,
          numberOfActions
        )
      } else {
        //Else just delete the node
        callbackDeleteNode($event, node)
      }
    }

    $scope.deleteTrigger = function() {
      triggerService.deleteTrigger($scope.trigger)
      $scope.selectedNode = {}
      $scope.nodeSelected(null, $scope.trigger)
    }

    $scope.keyPressed = function($event, node) {
      if ($event.keyCode === 8) {
        $event.stopPropagation()
        $event.preventDefault()

        // these events are firing twice... but only for nodes... sigh
        if ($scope.isClauseNode(node)) {
          $scope.deleteDoubleFiring = false
        } else {
          $scope.deleteDoubleFiring = !$scope.deleteDoubleFiring
        }
        if ($scope.deleteDoubleFiring) {
          return
        }

        $scope.deleteNode($event, node)
      }
    }

    $scope.setupTrigger = function() {
      if (!$scope.trigger) {
        if (!$scope.policies || $scope.policies.length < 0) {
          return
        }
        let policy = $scope.policies.filter(function(policy) {
          return policy.info.name === 'Trigger'
        })
        if (policy && policy.length > 0) {
          policy = policy[0]
          $scope.trigger = policyService.createPolicyInstance(
            policy,
            $scope.config.versioning
          ).Trigger
        }
      }
    }

    $scope.setupRequest = function() {
      if (!$scope.request) {
        if (!$scope.policies || $scope.policies.length < 0) {
          return
        }
        let policy = $scope.policies.filter(function(policy) {
          return policy.info.name === 'Request'
        })
        if (policy && policy.length > 0) {
          policy = policy[0]
          $scope.model.request = policyService.createPolicyInstance(
            policy,
            $scope.config.versioning
          ).Request
          $scope.model.request.type = 'request'
        }
      }
      $scope.request = $scope.model.request
    }

    $scope.setupResponse = function() {
      if (!$scope.model.response) {
        if (!$scope.policies || $scope.policies.length < 0) {
          return
        }
        let policy = $scope.policies.filter(function(policy) {
          return policy.info.name === 'Response'
        })
        if (policy && policy.length > 0) {
          policy = policy[0]
          $scope.model.response = policyService.createPolicyInstance(
            policy,
            $scope.config.versioning
          ).Response
          $scope.model.response.responses = []
          $scope.model.response.type = 'response'
        }
      }
      $scope.response = $scope.model.response
    }

    $scope.onTriggerMouseOver = function(trigger, val) {
      trigger.$$isHover = val
    }

    $timeout(function() {
      //the code which needs to run after dom rendering
      $scope.renderingAssembly = false
      $scope.$apply()

      if ($scope.isFlowSlim()) {
        canvasAnimationService.reset()
      }
    })

    /* Watch for Any Node Changes */
    // Deep Node Watch changes - for filter
    $scope.$watch(
      'nodes',
      function() {
        if ($scope.nodeFilter) {
          $scope.nodeFiltered()
        }
      },
      true
    )

    $scope.selectTriggerApplication = function(triggerApplicationId) {
      if (!triggerApplicationId) {
        triggerApplicationId = 'salesforce'
      }
      triggerService.setTriggerApplication($scope.trigger, triggerApplicationId)
    }

    rootScopeListeners.push(
      $rootScope.$on('nodeValidated', function(event, validation) {
        const theNode = $scope.selectedNode || $scope.prevNode

        if (!theNode) {
          return
        }

        const errors = validation.errors
        if (errors && !angular.equals(theNode.$$errors, errors)) {
          theNode.$$errors = errors
        }

        $rootScope.$emit('assemblyChanged')
      })
    )

    rootScopeListeners.push(
      $rootScope.$on('loadNewAssembly', function(event, assembly) {
        if ($scope.isAppConnect()) {
          $scope.gotFlowID = true
        }

        if (assembly.nodes) {
          $scope.model.nodes = assembly.nodes
          $scope.nodes = $scope.model.nodes
        }

        $scope.applyToEachNode($scope.nodes, function(node) {
          node.$$touched = true
        })

        if (assembly.trigger) {
          // copy the latest validation result to the trigger object generated from YAML
          $scope.model.trigger = assembly.trigger
          $scope.trigger = $scope.model.trigger
          if (
            $scope.trigger.selectedApplication &&
            $scope.trigger.selectedApplication.name
          ) {
            $scope.trigger.$$touched = true
          }
          $scope.nodeSelected(null, $scope.trigger)
        } else if ($scope.config.assemblyType === 'api') {
          $scope.nodeSelected(null, $scope.request)
        }

        $rootScope.assemblyDirty = false

        $scope.$watchCollection('nodes', function(newValue, oldValue) {
          if (!angular.equals(newValue, oldValue)) {
            $rootScope.assemblyDirty = true
            $rootScope.$emit('assemblyChanged')
          }
        })

        $scope.$watch(
          'trigger',
          function(newValue, oldValue) {
            if (!angular.equals(newValue, oldValue)) {
              $rootScope.assemblyDirty = true
              $rootScope.$emit('assemblyChanged')
            }
          },
          true
        )

        rootScopeListeners.push(
          $rootScope.$on('toggleFlowMode', function(event, isSlimFlow) {
            toggleFunction(isSlimFlow)
          })
        )
      })
    )

    $scope.animateCanvasSlide = function(scope) {
      if (!scope.isAnimating && $scope.isFlowSlim()) {
        scope.isAnimating = true
        $timeout(
          function() {
            scope.horizontalTranslation =
              canvasAnimationService.animateCanvasSlide()
            scope.isAnimating = false
            $scope.$apply()
          },
          animationDelay,
          false
        )
      }
    }

    rootScopeListeners.push(
      $rootScope.$on('onIfNodeSelected', function() {
        if (!$scope.policies || $scope.policies.length < 0) {
          return
        }
        let policy = $scope.policies.filter(function(policy) {
          return policy.info.name === 'switch'
        })

        if (policy && policy.length > 0) {
          policy = policy[0]
          $scope.policyDropped(
            policy,
            $scope.parentNode,
            $scope.index,
            $scope.secondIndex
          )
        }
      })
    )

    rootScopeListeners.push(
      $rootScope.$on(
        'onActionCompletePhase',
        function($event, selectedAction) {
          if (!$scope.policies || $scope.policies.length < 0) {
            return
          }
          let policy = $scope.policies.filter(function(policy) {
            return policy.info.name === 'Application'
          })

          if (policy && policy.length > 0) {
            policy = policy[0]
            $scope.policyDropped(
              policy,
              $scope.parentNode,
              $scope.index,
              $scope.secondIndex
            )

            if ($scope.nodes && $scope.nodes.length > 0) {
              // Get the new node
              let newNode
              if ($scope.parentNode) {
                const clause = $scope.parentNode.case[$scope.index]
                if (clause.execute) {
                  newNode = clause.execute[$scope.secondIndex + 1]
                } else if (clause.otherwise) {
                  newNode = clause.otherwise[$scope.secondIndex + 1]
                }
              } else {
                newNode = $scope.nodes[$scope.index + 1]
              }
              if (
                newNode.Application &&
                newNode.Application.$$type === 'Application'
              ) {
                newNode.Application.selectedApplication = {
                  name: selectedAction.name,
                  displayName: selectedAction.displayName,
                  actionInstructions: selectedAction.actionInstructions,
                }
                newNode.Application.selectedAction = {
                  name: selectedAction.task.name,
                  displayName: selectedAction.task.displayName,
                  dataModel: selectedAction.task.dataModel,
                  interaction: selectedAction.task.interaction,
                }
                newNode.Application.mappings = []
                newNode.Application.map = {}
                switch (selectedAction.task.interaction) {
                  case 'CREATE':
                    newNode.Application.type = 'create-action'
                    break
                  case 'RETRIEVEALL':
                    newNode.Application.type = 'retrieve-action'
                    break
                  case 'UPSERTWITHWHERE':
                    newNode.Application.type = 'upsert-action'
                    break
                  default:
                    throw new Error('Invalid node type')
                }
                newNode.Application.target = {}
              }
            }
          }
        }
      )
    )

    // Calls from other controllers
    rootScopeListeners.push(
      $rootScope.$on('animateCanvas', function($event, animationTarget) {
        switch (animationTarget) {
          case 'Trigger':
            $scope.animateCanvasSlide($scope)
            break
          case 'NewAction':
            $timeout(
              function() {
                // Drive animation in nodeSelected call
                $scope.nodeSelected(null, animationTarget)
                $scope.$apply()
              },
              animationDelay * 2,
              false
            )
            break
        }
      })
    )

    // Clean up rootScope listeners to stop memory leaks
    $scope.$on('$destroy', function() {
      for (let i = 0; i < rootScopeListeners.length; i++) {
        rootScopeListeners[i]()
      }
    })
  },
])

angular.module('apiconnect-assembly').controller('NavigationBarController', [
  '$scope',
  '$timeout',
  function($scope, $timeout) {
    if (
      localStorage.getItem('apim-assembly-scale-factor') &&
      !$scope.isFlowSlim()
    ) {
      $scope.scaleFactor =
        1 * localStorage.getItem('apim-assembly-scale-factor')
    } else {
      $scope.scaleFactor = 0.7
    }

    if ($scope.isFlowSlim()) {
      $scope.scaleFactor = 1
    }

    $scope.focusSearch = function() {
      document.getElementById('searchBox').focus()
    }

    $scope.$watch('scaleFactor', function() {
      localStorage.setItem('apim-assembly-scale-factor', $scope.scaleFactor)
    })

    $scope.zoomOut = function() {
      if ($scope.scaleFactor > 0.05) {
        $scope.scaleFactor -= 0.05
      }
    }

    $scope.zoomIn = function() {
      if ($scope.scaleFactor < 1.5) {
        $scope.scaleFactor += 0.05
      }
    }

    $scope.fitToScreen = function() {
      const fullWidth = document.querySelector('.assemblyScaler').scrollWidth
      const fullHeight = document.querySelector('.assemblyScaler').scrollHeight
      const viewportWidth =
        document.querySelector('.assemblerCanvas').clientWidth
      const viewportHeight =
        document.querySelector('.assemblerCanvas').clientHeight - 30 // 30px padding
      const widthRatio = viewportWidth / fullWidth
      const heightRatio = viewportHeight / fullHeight

      // these two numbers let us figure out which is the more extreme dimension so
      // we can fit to the most appropriate dimension
      const widthDelta = Math.abs(1 - widthRatio)
      const heightDelta = Math.abs(1 - heightRatio)

      if (fullWidth > viewportWidth) {
        // too wide
        if (fullHeight > viewportHeight) {
          // too tall, too wide => fit to lesser
          if (widthDelta < heightDelta) {
            $scope.scaleFactor = heightRatio
          } else {
            $scope.scaleFactor = widthRatio
          }
        } else {
          // too short, too wide => fit width
          $scope.scaleFactor = viewportWidth / fullWidth
        }
      } else {
        // too narrow
        if (fullHeight > viewportHeight) {
          // too tall, too narrow => fit height
          $scope.scaleFactor = viewportHeight / fullHeight
        } else {
          // too short, too narrow => fit to lesser
          if (widthDelta < heightDelta) {
            $scope.scaleFactor = widthRatio
          } else {
            $scope.scaleFactor = heightRatio
          }
        }
      }
    }

    $scope.nodeFiltered = function() {
      setTimeout(function() {
        if (!$scope.nodeFilter) {
          $scope.searchCount = ''
        }
        $scope.searchHits = document.getElementsByClassName('searchHit')
        $scope.currentSearchHitIndex = -1
        for (let i = 0; i < $scope.searchHits.length; i++) {
          if ($scope.searchHits[i].classList.contains('isSelected')) {
            $scope.currentSearchHitIndex = i
            break
          }
        }
        $scope.searchCount = $scope.searchHits.length
        $scope.hitString = $scope.searchHits.length === 1 ? t('hit') : t('hits')
        $scope.$apply()
      }, 200)
    }

    $scope.searchBack = function() {
      $scope.currentSearchHitIndex -= 1
      if ($scope.currentSearchHitIndex < 0) {
        $scope.currentSearchHitIndex = $scope.searchHits.length - 1
      }
      if ($scope.searchHits.length > 0) {
        $scope.searchHits[$scope.currentSearchHitIndex].scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
        $timeout(function() {
          $scope.searchHits[$scope.currentSearchHitIndex].click()
        })
      }
    }

    $scope.searchForward = function() {
      $scope.currentSearchHitIndex += 1
      if ($scope.currentSearchHitIndex >= $scope.searchHits.length) {
        $scope.currentSearchHitIndex = 0
      }
      if ($scope.searchHits.length > 0) {
        $scope.searchHits[$scope.currentSearchHitIndex].scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
        $timeout(function() {
          $scope.searchHits[$scope.currentSearchHitIndex].click()
        })
      }
    }

    $scope.setOperationFilter = function(operation) {
      $scope.operationFilter = operation
      $scope.showOperationFilter = true
    }

    $scope.operationSwitch = function() {
      if (!$scope.swaggerDocument) {
        return false
      }
      let result = false
      const ibmConfig = $scope.swaggerDocument[$scope.config.property]
      if (ibmConfig && ibmConfig.assembly) {
        $scope.nodes = ibmConfig.assembly.execute
        $scope.nodes.forEach(function(i) {
          if (Object.keys(i)[0] === 'operation-switch') {
            result = true
          }
        })
      }
      return result
    }
  },
])

angular.module('apiconnect-assembly').controller('SidenavController', [
  '$scope',
  '$mdSidenav',
  function($scope, $mdSidenav) {
    const keyCodeEscape = 27

    // Disable the escape key button in slim canvas view
    $scope.keyDown = function($event) {
      if ($event.keyCode === keyCodeEscape) {
        if ($scope.isFlowSlim()) {
          $event.stopPropagation()
        } else {
          $scope.closeInfo()
        }
      }
    }

    $scope.enableScroll = function() {
      $('.infoView').css('overflow-y', 'auto')
    }

    $scope.infoPanelBottom = $scope.isFlowSlim() ? true : false

    if (localStorage !== undefined) {
      if (
        typeof localStorage.getItem('apim-assembly-info-panel-bottom') ===
          'string' &&
        !$scope.isFlowSlim()
      ) {
        $scope.infoPanelBottom =
          localStorage.getItem('apim-assembly-info-panel-bottom') === 'true'
      }
    }

    $scope.$watch('infoPanelBottom', function() {
      if (localStorage !== undefined && $scope.infoPanelBottom !== undefined) {
        localStorage.setItem(
          'apim-assembly-info-panel-bottom',
          $scope.infoPanelBottom
        )
      }
    })

    $scope.toggleInfoPanelBottom = function($event) {
      $event.stopPropagation()
      $scope.infoPanelBottom = !$scope.infoPanelBottom
    }

    $scope.maximizeAssemblyInfo = function($event) {
      $event.stopPropagation()
      // make sure we're not pinned
      if ($scope.assemblyInfoPinned) {
        $scope.assemblyInfoPinned = false
      }
      $scope.assemblyInfoMaximized = !$scope.assemblyInfoMaximized
      setTimeout(function() {
        $scope.$broadcast('resize')
      }, 0)
    }

    $scope.canvasClick = function($event) {
      if ($event.target === $event.currentTarget) $scope.closeInfo($event)
    }

    $scope.closeInfo = function($event) {
      if ($event) $event.stopPropagation()
      // make sure we're not pinned
      $scope.assemblyInfoPinned = false
      $scope.infoPanelOpen = false
      $mdSidenav('assembly-info').close()
      // deselect selected node
      if ($scope.selectedNode) {
        $scope.selectedNode.$$isSelected = false
        if ($scope.selectedNode.$$type === 'NewAction') {
          const selectedDropzone = angular.element(
            document.querySelector('.dropzoneGroupSelected')
          )
          if (selectedDropzone.length) {
            selectedDropzone[0].classList.remove('dropzoneGroupSelected')
            const newAction =
              selectedDropzone[0].getElementsByClassName('ghostPolicy')[0]
            newAction.classList.remove('isSelected')
          }
        }
        // set node touched
        $scope.selectedNode.$$touched = true
        delete $scope.selectedNode
      }
    }

    $scope.pinAssemblyInfo = function() {
      $scope.assemblyInfoPinned = !$scope.assemblyInfoPinned
    }
  },
])

angular.module('apiconnect-assembly').controller('PolicyListController', [
  '$scope',
  '$rootScope',
  'translateFilter',
  function($scope, $rootScope, translateFilter) {
    $scope.filterCategories = function() {
      if (!$scope.policiesByCategory) return
      const filteredCategories = {'User Defined': []}
      const mgwFilter = $scope.showMicro()
      const dpgwFilter = $scope.showDP()
      const policyFilter = $scope.policyFilter
        ? $scope.policyFilter.toLowerCase()
        : ''
      Object.keys($scope.policiesByCategory).forEach(function(category) {
        let policies = $scope.policiesByCategory[category].filter(function(
          policy
        ) {
          let isUgw = false
          let isDpgw = false
          if (policy.gateways) {
            if (policy.gateways.indexOf('datapower-gateway') >= 0) {
              isDpgw = true
            }
            if (policy.gateways.indexOf('micro-gateway') >= 0) {
              isUgw = true
            }
          } else {
            isDpgw = true
          }
          if (policyFilter) {
            if (
              policy.info.title &&
              policy.info.title.toLowerCase().indexOf(policyFilter) === -1
            ) {
              return false
            } else if (
              policy.info.name &&
              policy.info.name.toLowerCase().indexOf(policyFilter) === -1
            ) {
              return false
            }
          } else if (!isUgw && mgwFilter) {
            return false
          } else if (!isDpgw && dpgwFilter) {
            return false
          }
          if (typeof policy.supported === 'undefined') {
            policy.supported = true
          }
          if (typeof policy.info.title === 'undefined') {
            policy.info.title = policy.info.name
          }
          policy.showInPalette = policy.supported

          return true
        })

        // Check for latest semver in same named policy
        const policiesCopy = policies
        for (let i = 0; i < policies.length; i++) {
          if (policies[i].supported && policies[i].info.version) {
            for (let j = 0; j < policiesCopy.length; j++) {
              if (
                policiesCopy[j].info.version &&
                policiesCopy[j].info.name === policies[i].info.name &&
                window.semver.lt(
                  window.semver.coerce(policiesCopy[j].info.version),
                  window.semver.coerce(policies[i].info.version)
                ) &&
                policiesCopy[j].supported
              ) {
                policiesCopy[j].showInPalette = false
              }
            }
          }
        }
        policies = policiesCopy

        if (policies && policies.length > 0)
          filteredCategories[category] = policies
      })
      // Force User Defined and Legacy category to end of palette list
      $scope.filteredCategoriesArray = Object.keys(filteredCategories)
      $scope.filteredCategoriesArray = $scope.filteredCategoriesArray.concat(
        $scope.filteredCategoriesArray.splice(
          $scope.filteredCategoriesArray.indexOf('User Defined'),
          1
        )[0]
      )
      $scope.filteredCategoriesArray = $scope.filteredCategoriesArray.concat(
        $scope.filteredCategoriesArray.splice(
          $scope.filteredCategoriesArray.indexOf('Legacy'),
          1
        )[0]
      )
      $scope.filteredCategories = filteredCategories
    }

    $scope.$watch('policyFilter', $scope.filterCategories)

    $scope.policiesByType = {}

    $scope.$watch('policies', function() {
      if (!$scope.policies) {
        return
      }

      const policiesByCategory = {}
      const policiesByType = {}

      $scope.policies.forEach(function(policy) {
        policiesByType[policy.info.name] = policy
        policiesByType[`${policy.info.name}:${policy.info.version}`] = policy

        if (!policy.info.categories && !policy.legacy && !policy.custom) {
          policy.info.categories = ['Uncategorized']
        } else if (policy.legacy) {
          policy.info.categories = ['Legacy']
        } else if (policy.custom && policy.supported) {
          policy.info.categories = ['User Defined']
        }

        if (
          policy.info.categories &&
          policy.info.categories.indexOf('Trigger') > -1
        ) {
          return
        }
        if (policy.info.categories) {
          policy.info.categories.forEach(function(category) {
            let policies = policiesByCategory[category]
            if (!policies) {
              policies = []
            }
            policies.push(policy)
            policiesByCategory[category] = policies
          })
        }
      })

      if ($scope.templates) {
        policiesByCategory[translateFilter('Web service operations')] =
          $scope.templates
      }

      if ($scope.subflows) {
        policiesByCategory[translateFilter('Policy Assemblies')] =
          $scope.subflows
      }

      $scope.policiesByCategory = policiesByCategory
      angular.extend($scope.policiesByType, policiesByType)
      $scope.filterCategories()

      if ($scope.config.assemblyType === 'trigger-action') {
        $scope.setupTrigger()
      } else if ($scope.config.assemblyType === 'api') {
        $scope.setupRequest()
        $scope.setupResponse()
      }
    })

    $scope.$watch('templates', function() {
      if (!$scope.templates) return
      if (!$scope.policiesByCategory) return
      $scope.policiesByCategory[translateFilter('Web service operations')] =
        $scope.templates
    })

    $scope.$watchCollection('subflows', function() {
      if (!$scope.subflows) return
      if (!$scope.policiesByCategory) return
      $scope.policiesByCategory[translateFilter('Policy Assemblies')] =
        $scope.subflows
      $scope.filterCategories()
    })

    $scope.collapsedCategories = {Legacy: true}

    $scope.closeCategory = function($event, category) {
      const parentElement = $event.currentTarget.parentElement
      const maxHeight = `${
        Array.prototype.reduce.call(
          parentElement.childNodes,
          function(p, c) {
            return p + (c.offsetHeight || 0)
          },
          0
        ) - 40
      }px`
      const element = $event.currentTarget.nextElementSibling

      if (!element.style['max-height']) {
        element.style['max-height'] = maxHeight
      }

      $scope.collapsedCategories[category] =
        !$scope.collapsedCategories[category]
    }

    const defaultGateway = 'datapower-gateway'
    $scope.showMicro = function() {
      // if explicitly set...
      if (
        $scope.swaggerDocument[$scope.config.property] &&
        $scope.swaggerDocument[$scope.config.property].gateway
      ) {
        return (
          $scope.swaggerDocument[$scope.config.property].gateway ===
          'micro-gateway'
        )
      }
      // honour default
      return defaultGateway === 'micro-gateway'
    }

    $scope.showDP = function() {
      // if explicitly set...
      if (
        $scope.swaggerDocument[$scope.config.property] &&
        $scope.swaggerDocument[$scope.config.property].gateway
      ) {
        return (
          $scope.swaggerDocument[$scope.config.property].gateway ===
          'datapower-gateway'
        )
      }
      // honour default
      return defaultGateway === 'datapower-gateway'
    }

    $scope.showApiGw = function() {
      // if explicitly set...
      if (
        $scope.swaggerDocument[$scope.config.property] &&
        $scope.swaggerDocument[$scope.config.property].gateway
      ) {
        return (
          $scope.swaggerDocument[$scope.config.property].gateway ===
          'datapower-api-gateway'
        )
      }
      // honour default
      return defaultGateway === 'datapower-api-gateway'
    }

    $scope.policyGwCheck = function(type) {
      return function(policy) {
        if (!policy) {
          return false
        }

        if (!policy.gateways) {
          return true
        }

        return policy.gateways.indexOf(type) >= 0
      }
    }

    $scope.microPolicy = $scope.policyGwCheck('micro-gateway')
    $scope.dpPolicy = $scope.policyGwCheck('datapower-gateway')
    $scope.apiPolicy = $scope.policyGwCheck('datapower-api-gateway')

    $scope.$gateway = function(value) {
      if (value) {
        // setter
        $scope.swaggerDocument[$scope.config.property].gateway = value
        $scope.filterCategories()
      } else {
        return $scope.showDP() ? 'datapower-gateway' : 'micro-gateway'
      }
    }

    $scope.showPolicy = function(name, policy) {
      if ($scope.showDP() && $scope.showMicro()) {
        return true
      }

      if (
        $scope.policyFilter &&
        name.toLowerCase().indexOf($scope.policyFilter.toLowerCase()) === -1
      ) {
        return false
      }

      // if gateways.micro is false, this is only a DP policy
      if (!$scope.microPolicy(policy) && $scope.showMicro()) {
        return false
      }

      // if gateways.dp is false, this is only a Micro policy
      if (!$scope.dpPolicy(policy) && $scope.showDP()) {
        return false
      }

      // if gateways.dp is false, this is only a Micro policy
      if (!$scope.apiPolicy(policy) && $scope.showApiGw()) {
        return false
      }

      return true
    }
  },
])

angular.module('apiconnect-assembly').controller('NodeController', [
  '$scope',
  '$rootScope',
  '$element',
  '$timeout',
  '$filter',
  'translateFilter',
  'assemblyModel',
  function(
    $scope,
    $rootScope,
    $element,
    $timeout,
    $filter,
    translateFilter,
    assemblyModel
  ) {
    $scope.descriptionLine1 = ''
    $scope.descriptionLine2 = ''
    $scope.nodeFullDescription = ''
    $scope.nodeHasErrors = false
    $scope.expanded = {}
    $scope.labelPlacement = 'left'
    $element.on('dragstart', function(event) {
      event.stopPropagation()
    })

    if ($scope.node) {
      $scope.data = {
        hashKey: $scope.node.$$hashKey,
        node: $scope.node,
        type: $scope.node.$$type,
      }
    } else {
      throw new Error('Unrecognised node')
    }

    if ($scope.node.$$readOnly) {
      $scope.nodeIsReadOnly = true
    }

    if ($scope.nodeIsReadOnly) {
      $scope.node.$$parentReadOnly = true
    }

    if ($scope.node.$$subflow) {
      $scope.inSubflow = true
    }

    $scope.countInnerActions = function(node) {
      return assemblyModel.countInnerActions(node)
    }

    $scope.expandBranch = function($event, node, $index) {
      if (
        !node.outputSchema ||
        !node.outputSchema.properties ||
        !Object.keys(node.outputSchema.properties).length
      ) {
        return
      }
      $timeout(function() {
        node.$$expandedBranch =
          node.$$expandedBranch === $index ? undefined : $index
        node.$$selectedBranch = $index
      })
      $scope.nodeSelected($event, node)
    }

    $scope.hasOutputSchema = function(node) {
      if (
        node.outputSchema &&
        node.outputSchema.properties &&
        Object.keys(node.outputSchema.properties).length
      ) {
        return true
      }
      return false
    }

    $scope.isDraggable = function() {
      // disable drag and drop when an operation filter is set
      if ($scope.operationFilter) {
        return false
      }
      // disable drag and drop when nested inside a subflow node
      return !$scope.$parent || !$scope.$parent.inSubflow
    }

    $scope.hasObject = function(theObject, hashKey) {
      if (!theObject) {
        return null
      }
      let result = false
      if (theObject.$$hashKey === hashKey) {
        return true
      }
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
          result = $scope.hasObject(theObject[i], hashKey)
          if (result) {
            break
          }
        }
      } else {
        for (const prop in theObject) {
          if (prop === '$$container') continue
          if (prop === '$$hashKey') {
            if (theObject[prop] === hashKey) {
              return true
            }
          }
          if (
            theObject[prop] instanceof Object ||
            theObject[prop] instanceof Array
          ) {
            result = $scope.hasObject(theObject[prop], hashKey)
            if (result) {
              return result
            }
          }
        }
      }
      return result
    }

    $scope.isHovered = false

    $scope.highlightNode = function(toggle, e) {
      $scope.isHovered = toggle ? true : false
      e.stopPropagation()
    }

    $scope.minimize = function($event) {
      $event.stopPropagation()
      $scope.node.$$minimized = !$scope.node.$$minimized

      if ($scope.selectedNode) {
        // Check the parent node is in the minimised node if it is a NewAction
        const nodeToFind =
          $scope.selectedNode.$$type !== 'NewAction'
            ? $scope.selectedNode
            : $scope.parentNode

        // If selectedNode is in the minimized node then unselect and close info
        if (
          $scope.node.$$minimized &&
          nodeToFind &&
          assemblyModel.containsNode($scope.node, nodeToFind)
        ) {
          $scope.unsetSelectedNode()
          $scope.closeInfo()
        }
      }
    }

    $scope.canMinimize = function() {
      return (
        $scope.node.$$type === 'switch' ||
        $scope.node.$$type === 'operation-switch' ||
        $scope.node.$$type === 'if'
      )
    }

    $scope.hasMatchedSearch = function() {
      if (!$scope.nodeFilter) {
        return true
      }
      if ($scope.isAppConnect()) {
        if (
          $scope.node.selectedApplication &&
          $scope.node.selectedApplication.displayName
        ) {
          return (
            $scope.node.selectedApplication.displayName
              .toLowerCase()
              .indexOf($scope.nodeFilter.toLowerCase()) > -1
          )
        }
        // TODO: Add support for highlighting selectedAction
        // if ($scope.node.nodeName) {
        //   return ($scope.node.nodeName.toLowerCase().indexOf($scope.nodeFilter.toLowerCase()) > -1);
        // }
      }
      if ($scope.node.name) {
        return (
          $scope.node.name
            .toLowerCase()
            .indexOf($scope.nodeFilter.toLowerCase()) > -1
        )
      }
      if ($scope.node.title) {
        return (
          $scope.node.title
            .toLowerCase()
            .indexOf($scope.nodeFilter.toLowerCase()) > -1
        )
      }
      if ($scope.node.$$type) {
        return (
          $scope.node.$$type
            .toLowerCase()
            .indexOf($scope.nodeFilter.toLowerCase()) > -1
        )
      }
      return false
    }

    $scope.conditionHasMatchedSearch = function(clause) {
      if (!$scope.nodeFilter) {
        return true
      }
      if (angular.isString(clause)) {
        return (
          clause.toLowerCase().indexOf($scope.nodeFilter.toLowerCase()) > -1
        )
      }
      let arrayToSearch
      if (clause.operations) {
        arrayToSearch = clause.operations
      } else if (clause.condition && clause.condition.operations) {
        arrayToSearch = clause.errors
      } else if (clause.errors) {
        arrayToSearch = clause.errors
      } else if (clause.default) {
        arrayToSearch = clause.default
      }
      if (!arrayToSearch) {
        return false
      }
      return (
        arrayToSearch
          .join(' ')
          .toLowerCase()
          .indexOf($scope.nodeFilter.toLowerCase()) > -1
      )
    }

    $scope.isClauseEmpty = function() {
      return (
        _.isEmpty($scope.node.case) &&
        _.isEmpty($scope.node.catch) &&
        _.isEmpty($scope.node.condition) &&
        _.isEmpty($scope.node.execute)
      )
    }

    $scope.policyWithContent = function() {
      return (
        (!!$scope.showCatches && !!$scope.node.catch) ||
        !!$scope.node.case ||
        !!$scope.node.execute
      )
    }

    $scope.policyGatewayMiss = function() {
      const node = $scope.node
      const policy = $scope.policiesByType[`${node.$$type}:${node.version}`]

      if (!policy) {
        return false
      }

      delete $scope.gatewayErrorMessage

      if (!$scope.microPolicy(policy) && $scope.showMicro()) {
        $scope.gatewayErrorMessage = translateFilter(
          'This policy is not available on the selected gateway'
        )
        return true
      }

      if (!$scope.dpPolicy(policy) && $scope.showDP()) {
        $scope.gatewayErrorMessage = translateFilter(
          'This policy is not available on the selected gateway'
        )
        return true
      }

      if (!$scope.apiPolicy(policy) && $scope.showApiGw()) {
        $scope.gatewayErrorMessage = translateFilter(
          'This policy is not available on the selected gateway'
        )
        return true
      }

      return false
    }

    $scope.cloneNode = function($event, node) {
      $event.stopPropagation()

      if (
        node.$$container === undefined ||
        node.$$containerIndex === undefined
      ) {
        return
      }

      const newNode = {}
      newNode[node.$$type] = angular.fromJson(angular.toJson(node))
      // TODO remove this code once https://github.ibm.com/apimesh/apiconnect-assembly/issues/97 is fixed
      newNode[node.$$type].$$type = node.$$type

      // insert newNode into node.$$container at node.$$containerIndex + 1
      node.$$container.splice(node.$$containerIndex + 1, 0, newNode)
      $scope.nodeSelected(null, newNode[node.$$type])
    }

    $scope.truncateNodeDescription = function() {
      $scope.descriptionLine1 = $filter('actionForNode')(
        $scope.node,
        $scope.nodes
      )
      $scope.nodeFullDescription = $scope.descriptionLine1

      if (!$scope.node || !$scope.descriptionLine1) {
        $scope.descriptionLine1 = ''
        $scope.descriptionLine2 = ''
        return
      }

      $timeout(function() {
        $scope.descriptionLine2 = ''
        const textContainer = $element[0].getElementsByClassName(
          'applicationNodeDetails'
        )[0]
        if (textContainer) {
          if (textContainer.scrollHeight > textContainer.clientHeight) {
            while (textContainer.scrollHeight > textContainer.clientHeight) {
              const lastSpace = $scope.descriptionLine1.lastIndexOf(' ')
              if (lastSpace > -1) {
                const word = $scope.descriptionLine1.slice(
                  lastSpace,
                  $scope.descriptionLine1.length
                )
                $scope.descriptionLine1 = $scope.descriptionLine1.slice(
                  0,
                  lastSpace
                )
                $scope.descriptionLine2 = word + $scope.descriptionLine2
              } else {
                const character = $scope.descriptionLine1.slice(-1)
                $scope.descriptionLine1 = $scope.descriptionLine1.slice(0, -1)
                $scope.descriptionLine2 = character + $scope.descriptionLine2
              }
              $scope.$digest()
            }
            $scope.descriptionLine1 = $scope.descriptionLine1.trim()
            $scope.descriptionLine2 = $scope.descriptionLine2.trim()
          }
        }
      })
    }

    $scope.updateDefaultDescription = function() {
      if (!$scope.node.description) {
        $scope.truncateNodeDescription()
      }
    }

    $scope.$watch('node.description', $scope.truncateNodeDescription, true)
    $scope.$watchGroup(
      ['node.name', 'node.dataModel'],
      function(newValue, oldValue) {
        // Need this to prevent truncateNodeDescription being called twice initially in some cases
        // truncateNodeDescription will already be called once on the node.description watcher initially
        if (newValue !== oldValue) {
          $scope.updateDefaultDescription()
        }
      },
      true
    )
  },
])

angular.module('apiconnect-assembly').controller('NodeCaseController', [
  '$scope',
  '$rootScope',
  'clauseMessageFilter',
  function($scope, $rootScope, clauseMessageFilter) {
    if ($scope.node && $scope.node.case && $scope.node.case.length > 10) {
      $scope.collapseNodes = true
    }

    $scope.showContent = function($event) {
      $event.stopPropagation()
      $scope.collapseNodes = false
    }

    $scope.hideContent = function($event) {
      $event.stopPropagation()
      $scope.collapseNodes = true
    }

    $scope.$watch('case.condition', function() {
      const isV6Gw =
        _.get(
          $scope.swaggerDocument,
          ['x-ibm-configuration', 'gateway'],
          ''
        ) === 'datapower-api-gateway'
      if ($scope.case.condition === undefined || isV6Gw) return
      if ($scope.case.condition === '') {
        delete $scope.case.$$expression
      } else {
        $scope.case.$$expression = window.jsep($scope.case.condition)
      }
      delete $scope.case.$$expressionString
      $scope.case.$$expressionString = clauseMessageFilter($scope.case)
    })

    $scope.$watchCollection('case.execute', function(newValue, oldValue) {
      if (!angular.equals(newValue, oldValue)) {
        $rootScope.$emit('assemblyChanged')
      }
    })
  },
])

angular
  .module('apiconnect-assembly')
  .controller('MapDataController', [
    '$scope',
    '$mdDialog',
    function($scope, $mdDialog) {
      $scope.openMapDialog = function($event, selectedNode) {
        $mdDialog.show({
          controller: 'DialogController',
          template: require('../../html/policies/mapper.html'),
          parent: document.body,
          targetEvent: $event,
          fullscreen: true,
          locals: {
            node: selectedNode,
          },
        })
      }
    },
  ])
  .controller('DialogController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    'node',
    function($scope, $rootScope, $mdDialog, node) {
      $scope.node = node
      const textContent = node.mappingData

      $scope.done = function() {
        $mdDialog.hide(textContent)
        $rootScope.$emit('nodeMappingUpdated')
      }
    },
  ])

angular.module('apiconnect-assembly').controller('TriggerController', [
  '$rootScope',
  '$scope',
  '$element',
  '$filter',
  '$timeout',
  'triggerService',
  function($rootScope, $scope, $element, $filter, $timeout, triggerService) {
    $scope.descriptionLine1 = ''
    $scope.descriptionLine2 = ''
    $scope.descriptionLine3 = ''

    $scope.isTriggerSlim = function() {
      return $scope.config.slimFlowEditor
    }

    $scope.hasMatchedSearch = function() {
      if (!$scope.nodeFilter) {
        return true
      }
      let matchedApplication
      let matchedTrigger

      if (
        $scope.trigger.selectedApplication &&
        $scope.trigger.selectedApplication.displayName
      ) {
        matchedApplication =
          $scope.trigger.selectedApplication.displayName
            .toLowerCase()
            .indexOf($scope.nodeFilter.toLowerCase()) > -1
      }
      // TODO Add support for highlighting selectedTrigger
      // if ($scope.trigger.selectedTrigger && $scope.trigger.selectedTrigger.displayName) {
      //   matchedTrigger = ($scope.trigger.selectedTrigger.displayName.toLowerCase().indexOf($scope.nodeFilter.toLowerCase()) > -1);
      // }
      return matchedApplication || matchedTrigger
    }

    $scope.selectTriggerApplication = function(selectedTrigger) {
      triggerService.setTriggerApplication($scope.trigger, selectedTrigger)
    }

    $scope.hasOperationSelected = function() {
      const activeOperations = []
      if (
        $scope.trigger.selectedTrigger &&
        $scope.trigger.selectedTrigger.operations
      ) {
        $scope.trigger.selectedTrigger.operations.forEach(function(op) {
          if (op.state) {
            activeOperations.push(op.id)
          }
        })
      }
      return activeOperations.length > 0
    }

    $scope.truncateNodeDescription = function() {
      $scope.descriptionLine1 = $filter('actionForTrigger')($scope.trigger)
      if (!$scope.trigger || !$scope.descriptionLine1) {
        $scope.descriptionLine1 = ''
        $scope.descriptionLine2 = ''
        $scope.descriptionLine3 = ''
        return
      }
      $timeout(function() {
        $scope.descriptionLine2 = ''
        const textContainer =
          $element[0].getElementsByClassName('triggerNodeDetails')[0]
        if (textContainer) {
          if (textContainer.scrollHeight > textContainer.clientHeight) {
            while (textContainer.scrollHeight > textContainer.clientHeight) {
              const lastSpace = $scope.descriptionLine1.lastIndexOf(' ')
              if (lastSpace > -1) {
                const word = $scope.descriptionLine1.slice(
                  lastSpace,
                  $scope.descriptionLine1.length
                )
                $scope.descriptionLine1 = $scope.descriptionLine1.slice(
                  0,
                  lastSpace
                )
                $scope.descriptionLine2 = word + $scope.descriptionLine2
              } else {
                const character = $scope.descriptionLine1.slice(-1)
                $scope.descriptionLine1 = $scope.descriptionLine1.slice(0, -1)
                $scope.descriptionLine2 = character + $scope.descriptionLine2
              }
              $scope.$digest()
            }
            $scope.descriptionLine1 = $scope.descriptionLine1.trim()
            $scope.descriptionLine2 = $scope.descriptionLine2.trim()
          }
          $timeout(function() {
            $scope.descriptionLine3 = ''
            const textContainerLine2 =
              $element[0].getElementsByClassName('triggerNodeDetails')[1]
            if (textContainerLine2) {
              if (
                textContainerLine2.scrollHeight >
                textContainerLine2.clientHeight
              ) {
                while (
                  textContainerLine2.scrollHeight >
                  textContainerLine2.clientHeight
                ) {
                  const lastSpace = $scope.descriptionLine2.lastIndexOf(' ')
                  if (lastSpace > -1) {
                    const word = $scope.descriptionLine2.slice(
                      lastSpace,
                      $scope.descriptionLine2.length
                    )
                    $scope.descriptionLine2 = $scope.descriptionLine2.slice(
                      0,
                      lastSpace
                    )
                    $scope.descriptionLine3 = word + $scope.descriptionLine3
                  } else {
                    const character = $scope.descriptionLine2.slice(-1)
                    $scope.descriptionLine2 = $scope.descriptionLine2.slice(
                      0,
                      -1
                    )
                    $scope.descriptionLine3 =
                      character + $scope.descriptionLine3
                  }
                  $scope.$digest()
                }
                $scope.descriptionLine2 = $scope.descriptionLine2.trim()
              }
            }
          })
        }
      })
    }

    $scope.updateDefaultDescription = function() {
      if (!$scope.trigger.description) {
        $scope.truncateNodeDescription()
      }
    }

    $rootScope.$on(
      'onTriggerCompletePhase',
      function($event, nextTarget, selectedTrigger) {
        if (selectedTrigger) {
          $scope.selectTriggerApplication(selectedTrigger)
        }
        $rootScope.$emit('animateCanvas', nextTarget)
      }
    )

    $scope.$watch(
      'trigger.description',
      function() {
        $scope.truncateNodeDescription()
      },
      true
    )

    $scope.$watch(
      'trigger.selectedTrigger.dataModel',
      function(newValue, oldValue) {
        // Need this to prevent truncateNodeDescription being called twice initially in some cases
        // truncateNodeDescription will already be called once on the node.description watcher initially
        if (newValue !== oldValue) {
          $scope.updateDefaultDescription()
        }
      },
      true
    )
  },
])

angular.module('apiconnect-assembly').controller('ActivityLogController', [
  '$scope',
  function ActivityLogController($scope) {
    this.logLevels = [
      {
        value: 'none',
        name: 'none',
      },
      {
        value: 'activity',
        name: 'activity',
      },
      {
        value: 'header',
        name: 'header',
      },
      {
        value: 'payload',
        name: 'payload',
      },
    ]
  },
])

angular
  .module('apiconnect-assembly')
  .controller('ValidateUsernameTokenController', [
    '$scope',
    function ValidateUsernameTokenController($scope) {
      if (
        !$scope.selectedNode['auth-type'] &&
        $scope.selectedNode.version === '1.0.0'
      )
        $scope.selectedNode['auth-type'] = 'Authentication URL'
      $scope.$authType = function(value) {
        if (typeof value !== 'undefined') {
          // setter
          if (value === 'Authentication URL') {
            delete $scope.selectedNode['ldap-registry']
            delete $scope.selectedNode['ldap-search-attribute']
          } else if (value === 'LDAP Registry') {
            delete $scope.selectedNode['auth-url']
            delete $scope.selectedNode['tls-profile']
          }
          $scope.selectedNode['auth-type'] = value
        } else {
          return $scope.selectedNode['auth-type']
        }
      }
    },
  ])
