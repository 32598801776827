// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2019
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

ace.define(
  'ace/theme/atom_dark',
  ['require', 'exports', 'module', 'ace/lib/dom'],
  function(e, t, n) {
    (t.isDark = !0),
    (t.cssClass = 'ace-atom-dark'),
    (t.cssText = `
    .ace_editor {
      font-size: 11px !important;
    }
    .ace-atom-dark .ace_gutter {
      background: #18262F;
      color: #C0C0C0;
    }
    
    .ace-atom-dark .ace_print-margin {
      width: 1px;
      background: #1a1a1a
    }
    
    .ace-atom-dark {
      background-color: #18262F;
      color: #E0E0E0
    }
    
    .ace-atom-dark .ace_cursor {
      color: white
    }
    
    .ace-atom-dark .ace_marker-layer .ace_selection {
      background: #444444
    }
    
    .ace-atom-dark.ace_multiselect .ace_selection.ace_start {
      box-shadow: 0 0 3px 0px #000000;
      border-radius: 2px
    }
    
    .ace-atom-dark .ace_marker-layer .ace_step {
      background: rgb(102, 82, 0)
    }
    
    .ace-atom-dark .ace_marker-layer .ace_bracket {
      margin: -1px 0 0 -1px;
      border: 1px solid #888888
    }
    
    .ace-atom-dark .ace_marker-layer .ace_highlight {
      border: 1px solid rgb(110, 119, 0);
      border-bottom: 0;
      box-shadow: inset 0 -1px rgb(110, 119, 0);
      margin: -1px 0 0 -1px;
      background: rgba(255, 235, 0, 0.1);
    }
    
    .ace-atom-dark .ace_marker-layer .ace_active-line {
      background: #58687536;
    }
    
    .ace-atom-dark .ace_gutter-active-line {
      background-color: #58687587;
    }
    
    .ace-atom-dark .ace_stack {
      background-color: rgb(66, 90, 44)
    }
    
    .ace-atom-dark .ace_marker-layer .ace_selected-word {
      border: 1px solid #888888
    }
    
    .ace-atom-dark .ace_invisible {
      color: #343434
    }
    
    .ace-atom-dark .ace_keyword,
    .ace-atom-dark .ace_meta,
    .ace-atom-dark .ace_storage,
    .ace-atom-dark .ace_storage.ace_type,
    .ace-atom-dark .ace_support.ace_type {
      color: #B272E4
    }
    
    .ace-atom-dark .ace_keyword.ace_operator {
      color: #A6AFB8
    }
    
    .ace-atom-dark .ace_constant.ace_character,
    .ace-atom-dark .ace_constant.ace_language,
    .ace-atom-dark .ace_keyword.ace_other.ace_unit,
    .ace-atom-dark .ace_support.ace_constant,
    .ace-atom-dark .ace_variable.ace_parameter {
      color: #E4B51C
    }

    .ace-atom-dark .ace_constant.ace_numeric {
      color: #7CC844
    }
    
    .ace-atom-dark .ace_constant.ace_language.ace_boolean {
      color: #E8E9ED
    }
    
    .ace-atom-dark .ace_constant.ace_other {
      color: #EEEEEE
    }
    
    .ace-atom-dark .ace_invalid {
      color: #CED2CF;
      background-color: #DF5F5F
    }
    
    .ace-atom-dark .ace_invalid.ace_deprecated {
      color: #CED2CF;
      background-color: #B798BF
    }
    
    .ace-atom-dark .ace_fold {
      background-color: #7AA6DA;
      border-color: #DEDEDE
    }
    
    .ace-atom-dark .ace_entity.ace_name.ace_function,
    .ace-atom-dark .ace_support.ace_function,
    .ace-atom-dark .ace_variable {
      color: #EB50AE
    }
    
    .ace-atom-dark .ace_support.ace_class,
    .ace-atom-dark .ace_support.ace_type {
      color: #E7C547
    }
    
    .ace-atom-dark .ace_heading,
    .ace-atom-dark .ace_markup.ace_heading,
    .ace-atom-dark .ace_string {
      color: #33B5E1
    }
    
    .ace-atom-dark .ace_entity.ace_name.ace_tag,
    .ace-atom-dark .ace_entity.ace_other.ace_attribute-name,
    .ace-atom-dark .ace_meta.ace_tag,
    .ace-atom-dark .ace_string.ace_regexp,
    .ace-atom-dark .ace_variable {
      color: #33B5E1
    }

    .ace-atom-dark .ace_tag.ace_xml {
      color: #F78485
    }

    .ace-atom-dark .ace_tag.ace_tag-name.ace_xml {
      color: #E88CE2;
    }

    .ace-atom-dark .ace_entity.ace_attribute-name.ace_xml {
      color: #FFA860;
    }
    
    .ace-atom-dark .ace_string.ace_attribute-value.ace_xml {
      color: #8787F3;
    }
    
    .ace-atom-dark .ace_comment {
      color: #AAE079
    }
    
    .ace-atom-dark .ace_c9searchresults.ace_keyword {
      color: #C2C280;
    }
    
    .ace-atom-dark .ace_indent-guide {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAEklEQVQImWNgYGBgYFBXV/8PAAJoAXX4kT2EAAAAAElFTkSuQmCC) right repeat-y
    }`)
    const r = e('../lib/dom')
    r.importCssString(t.cssText, t.cssClass)
  }
)
