// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

angular.module('apiconnect-assembly-localization', []).config([
  '$translateProvider',
  function($translateProvider) {
    $translateProvider.useSanitizeValueStrategy('escaped')

    const csBundle = require('Uif/locales/cs')
    const deBundle = require('Uif/locales/de')
    const enBundle = require('Uif/locales/en-US')
    const esBundle = require('Uif/locales/es')
    const frBundle = require('Uif/locales/fr')
    const itBundle = require('Uif/locales/it')
    const jaBundle = require('Uif/locales/ja')
    const koBundle = require('Uif/locales/ko')
    const nlBundle = require('Uif/locales/nl')
    const plBundle = require('Uif/locales/pl')
    const ptBRBundle = require('Uif/locales/pt-br')
    const ruBundle = require('Uif/locales/ru')
    const trBundle = require('Uif/locales/tr')
    const zhBundle = require('Uif/locales/zh-cn')
    const zhTWBundle = require('Uif/locales/zh-tw')

    $translateProvider.translations('cs', csBundle.default)
    $translateProvider.translations('de', deBundle.default)
    $translateProvider.translations('en', enBundle.default)
    $translateProvider.translations('es', esBundle.default)
    $translateProvider.translations('fr', frBundle.default)
    $translateProvider.translations('it', itBundle.default)
    $translateProvider.translations('ja', jaBundle.default)
    $translateProvider.translations('ko', koBundle.default)
    $translateProvider.translations('nl', nlBundle.default)
    $translateProvider.translations('pl', plBundle.default)
    $translateProvider.translations('pt_BR', ptBRBundle.default)
    $translateProvider.translations('ru', ruBundle.default)
    $translateProvider.translations('tr', trBundle.default)
    $translateProvider.translations('zh', zhBundle.default)
    $translateProvider.translations('zh_TW', zhTWBundle.default)

    $translateProvider.registerAvailableLanguageKeys(
      [
        'cs',
        'de',
        'en',
        'es',
        'fr',
        'it',
        'ja',
        'ko',
        'nl',
        'pl',
        'pt_BR',
        'ru',
        'tr',
        'zh',
        'zh_TW',
      ],
      {
        'cs_*': 'cs',
        'de_*': 'de',
        'en_*': 'en',
        'es_*': 'es',
        'fr_*': 'fr',
        'it_*': 'it',
        'ja_*': 'ja',
        'ko_*': 'ko',
        'nl_*': 'nl',
        'pl_*': 'pl',
        'pt_BR_*': 'pt_BR',
        'ru_*': 'ru',
        'tr_*': 'tr',
        zh_CN: 'zh',
        zh_TW: 'zh_TW',
      }
    )

    // use browser local list if it's available
    if (navigator.languages) {
      $translateProvider.determinePreferredLanguage()
    } else {
      if (typeof localStorage !== 'undefined') {
        let userLocale = localStorage.getItem('apim-client-locale')
        // check if we've got the session locale in global var
        // supports browsers that dont have the new language list
        if (window.apimSessionLocale) {
          const adjusted = window.apimSessionLocale.split('-').join('_')
          localStorage.setItem('apim-client-locale', adjusted)
          userLocale = adjusted
        }
        if (userLocale) {
          $translateProvider.preferredLanguage(userLocale)
        } else {
          $translateProvider.determinePreferredLanguage()
        }
      } else {
        // no local storage - probably private browsing, do the best we can instead
        $translateProvider.determinePreferredLanguage()
      }
    }
    $translateProvider.fallbackLanguage('en')
  },
])
