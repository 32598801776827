// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `카탈로그가 업데이트되지 않음`,
  [`Catalog has been updated`]: `카탈로그가 업데이트됨`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `드래프트 제품 {draftProduct}은(는) 하나 이상의 API를 참조해야 함`,
  [`Number of Calls`]: `호출 수`,
  [`Changes will apply to newly published APIs only`]: `변경사항은 새로 공개된 API에만 적용됩니다.`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `이 API와 게이트 유형이 다르기 때문에 호환되지 않는 {label}이(가) 있습니다.`,
  [`{label} is required`]: `{label}은(는) 필수임`,
  [`Application is required`]: `애플리케이션은 필수임`,
  [`Plan is required`]: `플랜은 필수임`,
  [`Gateway is required`]: `게이트웨이는 필수임`,
  [`Consumer Org is required`]: `이용자 조직은 필수임`,
  [`LDAP ATTRIBUTE NAME`]: `LDAP 속성 이름`,
  [`Use the build in Test Application (sandbox only)`]: `기본 제공 테스트 애플리케이션을 사용하십시오(샌드박스 전용).`,
  [`Select an Application`]: `애플리케이션 선택`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `기본 제공 테스트 애플리케이션 사용(샌드박스 전용) - 샌드박스가 아닌 카탈로그가 선택된 경우 사용 불가능`,
  [`Choose an existing Application`]: `기존 애플리케이션 선택`,
  [`Use JWT for gateway authentication to analytics service`]: `게이트웨이 인증을 분석 서비스에 인증하려면 JWT를 사용하십시오.`,
  [`Select an application to consume the API`]: `API를 이용할 애플리케이션 선택`,
  [`Use the default built-in Sandbox Catalog'`]: `기본 제공 샌드박스 카탈로그 사용`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `참고: 이 카탈로그를 다른 모든 환경 설정의 범위로 사용할 수 있습니다. 이 설정을 변경하면 다른 환경 설정을 조정해야 합니다.`,
  [`Select a Consumer Org`]: `이용자 조직 선택`,
  [`Select the Consumer Org your generated application will belong to`]: `생성된 애플리케이션이 속하는 이용자 조직을 선택합니다.`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `참고: 기존 애플리케이션이 이미 이용자 조직에 속하므로 기본 제공 샌드박스 테스트 애플리케이션을 사용하는 경우에만 이 옵션을 변경할 수 있습니다.`,
  [`Generate auto product`]: `자동 제품 생성`,
  [`Select a product to associate the current API with`]: `현재 API와 연관시킬 제품 선택`,
  [`Associate the current API to a selected product`]: `선택한 제품에 현재 API 연관`,
  [`API management`]: `API 관리`,
  [`Select the API management instance you would like this API to be published to.`]: `이 API를 공개할 API 관리 인스턴스를 선택합니다.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `참고: 이 인스턴스는 기타 모든 환경 설정의 범위로 사용됩니다. 이 설정을 변경하면 다른 환경 설정을 조정해야 합니다.`,
  [`Provider organization`]: `제공자 조직`,
  [`Select the provider organization you would like this API to be published to.`]: `이 API를 공개할 제공자 조직을 선택합니다.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `참고: 이 조직은 기타 모든 환경 설정의 범위로 사용됩니다. 이 설정을 변경하면 다른 환경 설정을 조정해야 합니다.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `참고: 이 공간은 기타 모든 환경 설정의 범위로 사용됩니다. 이 설정을 변경하면 다른 환경 설정을 조정해야 합니다.`,
  [`Selected a draft product`]: `임시 제품을 선택함`,
  [`Use any available gateway service`]: `사용 가능한 게이트웨이 서비스 사용`,
  [`Select a compatible gateway service`]: `호환되는 게이트웨이 서비스 선택`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `참고: 이 게이트웨이 서비스를 다른 모든 환경 설정의 범위로 사용할 수 있습니다. 정책 및 기타 게이트웨이 서비스 특정 컨텐츠는 이 설정에 따라 로드됩니다.`,
  [`Select a gateway`]: `게이트웨이 선택`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"참고: '기존 제품' 옵션이 선택되고 생성된 제품을 위한 기본 플랜을 대신 사용하는 경우 이 옵션은 사용 불가능합니다.`,
  [`Select a Plan`]: `플랜 선택`,
  [`Generate subscription`]: `등록 생성`,
  [`A subscription will be created between the selected Product plan and Application`]: `등록은 선택된 제품 플랜과 애플리케이션 사이에 작성됩니다.`,
  [`Apply a rate limit to the generated product`]: `생성된 제품에 비율 한계를 적용합니다.`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `생성된 제품의 기본 플랜에 비율 한계를 적용합니다. '기존 제품' 옵션이 선택되고 선택된 플랜에 정의된 비율 한계를 대신 사용하는 경우 이 옵션은 사용 불가능합니다.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `LDAP(Lightweight Directory Access Protocol)은 네트워크를 통한 분산 디렉토리 정보 서비스 액세스 및 유지보수를 위한 인터넷 프로토콜입니다. 엔터프라이즈 LDAP 서비스가 사용으로 설정되어 있는 경우, 이를 자원으로 구성하여 사용자 인증을 제공하십시오. 새 개발자 포털 사용자를 위한 사용자 온보딩도 제공하려면 속성 맵핑 섹션을 완료하여 쓰기 가능 LDAP를 사용으로 설정해야 합니다. 이 섹션에서 사용자 관리 선택란을 선택하고 소스 LDAP 속성 이름과 대상 API Connect 값의 맵핑을 제공하십시오.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `태스크 자체 승인을 사용하면 제안자와 협업자가 태스크를 승인할 수 있습니다. 이 옵션은 다른 승인자가 없을 때 편리할 수 있지만 한편으론 다른 사용자의 검사를 무시할 수 있습니다.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `청구 통합은 API Connect를 고객, 해당 지불 방법, 송장과 활성 등록을 관리하는 써드파티 등록 청구 시스템의 계정에 연결합니다.`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `원시 OAuth 제공자 오브젝트는 OAuth 토큰 생성 및 유효성 검증 등의 OAuth 처리 오퍼레이션에 대한 설정을 제공합니다. OAuth 제공자 오브젝트는 API를 보호하기 위해 OAuth 보안 정의에 의해 참조될 수 있습니다. 원시 OAuth 제공자가 사용되는 경우, OAuth 오퍼레이션이 API Connect에 의해 원시적으로 수행됩니다. 모든 OAuth 제공자 오브젝트에는 지원 API가 있습니다. 여기의 구성이 API의 swagger 문서를 자동으로 업데이트합니다. API 편집기 페이지로 이동하여 swagger 문서를 편집할 수 있습니다. 공개된 API가 OAuth 제공자 오브젝트를 참조하는 경우, 게이트웨이에서 지원 API가 자동으로 사용 가능하게 됩니다.`,
  [`About token management`]: `토큰 관리 정보`,
  [`About user security`]: `사용자 보안 정보`,
  [`APIs or products`]: `API 또는 제품`,
  [`ATM is not deployed, please contact your administrator`]: `ATM이 배치되지 않습니다. 관리자에게 문의하십시오.`,
  [`Application state change approvals will be enabled`]: `애플리케이션 상태 변경 승인이 사용으로 설정됨`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `애플리케이션 사용자의 신임 정보를 추출하고 ID를 인증하고 권한을 부여하기 위해 사용할 설정을 정의합니다.`,
  [`See console for details.`]: `세부사항은 콘솔을 참조하십시오.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `선택한 인증서 관리자 서비스는 인증서를 관리하지 않습니다.`,
  [`The selected product is not published to the Sandbox catalog`]: `선택한 제품이 샌드박스 카탈로그에 공개되지 않음`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `선택한 제품이 샌드박스 카탈로그에 공개되지 않으므로 애플리케이션을 등록할 수 없음`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `사용자 플랫폼에 해당하는 IBM Cloud CLI(이전에 설치되지 않은 경우).`,
  [`(Only supported by TLS 1.3)`]: `(TLS 1.3에서만 지원함)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `이 필드의 "@listSize" 지시문은 목록을 리턴하지 않는 크기 지정된 필드를 정의합니다.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `이 필드의 "@listSize" 지시문은 필드의 리턴 유형에 지정되지 않은 크기 지정된 필드를 정의합니다.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `이 필드의 "@listSize" 지시문은 필드에 정의되지 않은 분할 인수를 정의합니다.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `이 필드의 "@listSize" 지시문은 "Int" 또는 "Int!" 유형이 아닌 분할 인수를 정의합니다".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `이 필드의 "@listSize" 지시문은 널이 아닌 다중 분할 인수를 정의합니다.`,
  [`"{name}" (Role) has been created`]: `"{name}"(역할)이(가) 작성되었습니다.`,
  [`"{name}" (Role) has been updated`]: `"{name}"(역할)이(가) 업데이트되었습니다.`,
  [`API ({api}) has been created.`]: `API({api})가 작성되었습니다.`,
  [`API ({api}) has been renamed.`]: `API({api}) 이름을 변경했습니다.`,
  [`API ({api}) has been updated.`]: `API({api})가 업데이트되었습니다.`,
  [`API ({api}) has been deleted.`]: `API({api})가 삭제되었습니다.`,
  [`API ({api}) has been published.`]: `API({api})가 공개되었습니다.`,
  [`API ({api}) has validation error.`]: `API({api})에 유효성 검증 오류가 있습니다.`,
  [`API ({api}) has validation errors.`]: `API({api})에 유효성 검증 오류가 있습니다.`,
  [`API version`]: `API 버전`,
  [`APIM data version`]: `APIM 데이터 버전`,
  [`APIM schema update date`]: `APIM 스키마 업데이트 날짜`,
  [`APIM schema version`]: `APIM 스키마 버전`,
  [`APIM target data version`]: `APIM 대상 데이터 버전`,
  [`APIM target schema version`]: `APIM 대상 스키마 버전`,
  [`APIM update date`]: `APIM 업데이트 날짜`,
  [`Product ({product}) has been created.`]: `제품({product})이 작성되었습니다.`,
  [`Product ({product}) has been updated.`]: `제품({product})이 업데이트되었습니다.`,
  [`Product ({product}) has been delete.`]: `제품({product})이 삭제되었습니다.`,
  [`Product ({product}) has been published.`]: `제품({product})이 공개되었습니다.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}"({type})이(가) {changedName}`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}"({type})이(가) {changedName}(으)로 변경되지 않았습니다!`,
  [`({units} {timeSuffix} after being queued)`]: `(큐에 대기된 후 {units} {timeSuffix})`,
  [`({units} {timeSuffix} after being sent)`]: `(전송된 후 {units} {timeSuffix})`,
  [`(none)`]: `(없음)`,
  [`(optional)`]: `(선택사항)`,
  [`*Base endpoint list empty`]: `*기본 엔드포인트 목록이 비어 있음`,
  [`*Must be a valid url`]: `*올바른 URL이어야 함`,
  [`*System will also send an email notification to the requester.`]: `*시스템은 또한 요청자에게 이메일 알림을 발송합니다.`,
  [`+ redact from...`]: `+ 수정 위치...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. 또한 카탈로그 내 이 제품의 라이프사이클도 관리할 수 있습니다.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x 뒤에 64개의 16진 문자`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) API 이용자 조직 소유 및 관리 2) 애플리케이션 개발자 커뮤니티 관리 3) API 및 제품 정의 작성 4) API 제품 라이프사이클 관리`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) API 제공자 조직 소유 및 관리 2) 애플리케이션 개발자 커뮤니티 관리 3) API 및 제품 정의 작성 4) API 제품 라이프사이클 관리`,
  [`5 Most Active APIs`]: `최대 활성 API 상위 5개`,
  [`5 Most Active Products`]: `최대 활성 제품 상위 5개`,
  [`5 most active APIs`]: `최대 활성 API 상위 5개`,
  [`5 most active Products`]: `최대 활성 제품 상위 5개`,
  [`503 Service Unavailable`]: `503 사용 불가능한 서비스`,
  [`A request to the server was made without any credentials.`]: `신임 정보 없이 서버에 대한 요청이 작성되었습니다.`,
  [`A server error occurred`]: `서버 오류가 발생했습니다.`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `기타 시스템에 클라이언트로 연결할 때 TLS 클라이언트 프로파일이 API Connect에 의해 사용되는 인증서 체인 및 암호 스위트를 구성합니다.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `기타 시스템에 서버 엔드포인트를 제공할 때 TLS 서버 프로파일이 API Connect에 의해 사용되는 인증서 체인 및 암호 스위트를 구성합니다.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `카탈로그는 공개되면 연관된 개발자 포털에 표시되는 API 제품 콜렉션을 호스트합니다.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `카탈로그는 공개되면 연관된 개발자 포털에 표시되는 API 제품 콜렉션을 호스트합니다.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `카탈로그는 관리되는 API 제품의 콜렉션을 나타냅니다. 공개된 제품이 카탈로그와 연관된 개발자 포털에서 이용자에게 표시됩니다.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `API Connect와 게이트웨이 관리 엔드포인트의 도메인 간의 연결을 보호하려면 인증서가 필요합니다. 게이트웨이와 게이트웨이가 처리하는 도메인 간의 연결을 보호하려면 인증서가 필요합니다. 모든 인증서는 인증서 관리자 서비스에 저장되어 있어야 합니다. 이 서비스는 인증서에 대한 보안 저장소를 제공하고 인증서가 만료될 때 알림을 보내 중단을 방지하는 데 도움이 됩니다. 시작하려면 다음을 수행하십시오.`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `맵에 대한 입력이 정의되지 않은 경우 사용할 기본값입니다.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `게이트웨이 서비스는 공개된 API를 호스트하고 클라이언트 애플리케이션에 의해 사용되는 API 엔드포인트를 제공하는 게이트웨이 서버 또는 컨테이너 세트를 나타냅니다. 게이트웨이는 백엔드 시스템에 대한 API 프록시 호출을 실행하고 클라이언트 식별, 보안 및 속도 제한을 포함하여 API 정책을 적용합니다.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `게이트웨이 호스트는 API를 게시하고 클라이언트 애플리케이션에서 사용하는 API 엔드포인트를 제공합니다. 게이트웨이는 백엔드 시스템에 대한 API 프록시 호출을 실행하고 클라이언트 식별, 보안 및 속도 제한 설정을 포함한 API 정책을 시행합니다.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `소스 제품에서 마이그레이션 대상을 설정하여 등록에 대한 마이그레이션 대상을 정의할 수 있습니다.  마이그레이션 대상에는 소스 제품에 대한 플랜을 대상 제품에 대한 플랜에 맵핑하는 것을 설명하는 플랜 맵핑이 포함됩니다.  마이그레이션 대상이 소스 제품의 등록자와 통신할 수 있도록 개발자 포털에 마이그레이션 대상이 표시됩니다.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `모듈 형식의 OAuth 정책 킷입니다. 한 정책에서 모든 OAuth/OpenID Connect 프로토콜 단계를 수행하거나(기본값) 더 세밀하게 제어할 수 있도록 한 번에 하나 또는 “n”개의 단계를 수행하도록 여러 정책으로 분할할 수 있습니다. 각 단계의 입력 및 출력은 문서화된 컨텍스트 변수에 의해 주도됩니다. 필요에 따라 지원되는 OAuth 컴포넌트를 추가하거나 제거하십시오.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `포털은 사용 가능한 포털 서비스 없이 작성할 수 없습니다. 지원이 필요하면 클라우드 관리자에게 문의하십시오.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `제공자 조직은 API 및 연관된 플랜과 제품을 소유하는 팀입니다. 제공자 조직에는 모든 권한이 지정된 소유자가 필요합니다. 소유자는 구성원 중에서 지정됩니다.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `제공자 조직에는 API, 제품, 플랜, 카탈로그, 소비자 조직 및 기타 관련 자원을 관리하는 사용자가 포함됩니다. 제공자 조직을 사용하여 프로젝트, 부서, 라이프사이클 단계 등에 따라 사용자 및 API 관리 자원을 그룹화할 수 있습니다. 사용자 권한을 관리하는 역할을 관리하고 지정하려면 IBM Cloud Identity and Access Management로 이동하십시오.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `출력의 값을 구하는 단순 JavaScript 표현식입니다. 이 표현식은 정적 문자열("my static string") 또는 맵핑된 입력을 포함하는 표현식($(input.firstName) + " " + $(input.lastName))이 될 수 있습니다.`,
  [`ACTION`]: `조치`,
  [`ANALYTICS`]: `분석`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API(GraphQL)`,
  [`API (REST)`]: `API(REST)`,
  [`API (WSDL)`]: `API(WSDL)`,
  [`API (SOAP)`]: `API(SOAP)`,
  [`API Administrator`]: `API 관리자`,
  [`API CONNECT VALUE`]: `API Connect 값`,
  [`API assemble`]: `API 어셈블`,
  [`API base endpoints`]: `API 기본 엔드포인트`,
  [`API base URL`]: `API 기본 URL`,
  [`API base URLs`]: `API 기본 URL`,
  [`API Calls`]: `API 호출`,
  [`API Connect UI`]: `API Connect UI`,
  [`API Connect services`]: `API Connect 서비스`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect의 경우, 이메일 서버가 다른 목적으로 구성원에게 초대 및 활성화 링크를 보내야 합니다.`,
  [`API Connect verify token`]: `API Connect 확인 토큰`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `API Designer 라이센스`,
  [`API Designer credentials`]: `API Designer 신임 정보`,
  [`API Designer licence`]: `API Designer 라이센스`,
  [`API Designer does not currently support WSDL`]: `API Designer에서 현재 WSDL을 지원하지 않음`,
  [`API editor`]: `API 편집기`,
  [`API Endpoint`]: `API 엔드포인트`,
  [`API Endpoint Base`]: `API 엔드포인트 기본`,
  [`API Endpoint for Unenforced APIs`]: `적용되지 않은 API에 대한 API 엔드포인트`,
  [`API Endpoint for unenforced APIs`]: `적용되지 않은 API에 대한 API 엔드포인트`,
  [`API Endpoints`]: `API 엔드포인트`,
  [`API Error`]: `API 오류`,
  [`API gateway`]: `API 게이트웨이`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API 게이트웨이`,
  [`API Gateways`]: `API 게이트웨이`,
  [`API Invocation Endpoint`]: `API 호출 엔드포인트`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `API가 공개되지 않았습니다. 탐색기에서 API 테스트(시도)를 사용할 수 없습니다.`,
  [`API Key`]: `API 키`,
  [`API key`]: `API 키`,
  [`API key secret`]: `API 키 시크릿`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `API Manager 로컬 사용자 레지스트리`,
  [`API Manager URL`]: `API Manager URL`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `API 경로 매개변수`,
  [`API Security Definition`]: `API 보안 정의`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `적용되지 않았으므로 탐색기에서 이 API에 대한 API 테스트(시도)를 사용할 수 없습니다.`,
  [`API Setup`]: `API 설정`,
  [`API Subscription`]: `API 등록`,
  [`API endpoint`]: `API 엔드포인트`,
  [`API endpoint base`]: `API 엔드포인트 기본`,
  [`API endpoint for unenforced APIs`]: `적용되지 않은 API에 대한 API 엔드포인트`,
  [`API endpoints`]: `API 엔드포인트`,
  [`API error`]: `API 오류`,
  [`API invocation endpoint`]: `API 호출 엔드포인트`,
  [`API is not enforced on the API gateway.`]: `API가 API Gateway에 적용되지 않았습니다.`,
  [`API path parameters`]: `API 경로 매개변수`,
  [`API security definition`]: `API 보안 정의`,
  [`API setup`]: `API 설정`,
  [`API subscription`]: `API 등록`,
  [`API Update`]: `API 업데이트`,
  [`API user registries`]: `API 사용자 레지스트리`,
  [`API and product generated successfully!`]: `API 및 제품이 생성되었습니다.`,
  [`API deletion failed`]: `API 삭제 실패`,
  [`API does not exist!`]: `API가 없습니다!`,
  [`API from existing REST service`]: `기존 REST 서비스의 API`,
  [`API from existing SOAP service`]: `기존 SOAP 서비스의 API`,
  [`API has been updated.`]: `API가 업데이트되었습니다.`,
  [`API has been updated`]: `API가 업데이트되었습니다.`,
  [`API has not been created!`]: `API가 작성되지 않았습니다!`,
  [`API has not been updated!`]: `API가 업데이트되지 않았습니다!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `API 우선순위(OpenAPI 정의에서 정의된 베니티 엔드포인트)`,
  [`API properties`]: `API 특성`,
  [`API protection source`]: `API 보호 소스`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `제품에서 참조되는 API가 존재하지 않습니다. 먼저 API를 작성 또는 가져오십시오.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `API 요청 및 응답 페이로드 구조는 OpenAPI 스키마 정의를 사용하여 작성됩니다.`,
  [`API subscription request approved for app {{appName}}`]: `{appName} 앱에 대한 API 등록 요청이 승인됨`,
  [`API subscription request denied for app {{appName}}`]: `{appName} 앱에 대한 API 등록 요청이 거부됨`,
  [`API subscription request received for app {{appName}}`]: `{appName} 앱에 대한 API 등록 요청이 수신됨`,
  [`API type`]: `API 유형`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `'apis' 특성 아래 키가 ''{key}''인 API가 존재하지 않으며 나열할 수 없습니다. 소스 탭을 확인하십시오.`,
  [`APIs`]: `API`,
  [`APIs and products`]: `API 및 제품`,
  [`APIs included in the plan.`]: `플랜에 포함된 API.`,
  [`API Key is created`]: `API 키 작성됨`,
  [`API Key is deleted`]: `API 키 삭제됨`,
  [`API Key is successfully created`]: `API 키가 성공적으로 작성됨`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `API 키 제한시간은 **{seconds}초({time})**입니다.`,
  [`API key timeout`]: `API 키 제한시간`,
  [`API Key timeout has been changed`]: `API 키 제한시간이 변경됨`,
  [`Application type`]: `애플리케이션 유형`,
  [`Associated analytics service`]: `연관된 분석 서비스`,
  [`AVAILABILITY`]: `가용성`,
  [`AVAILABILITY ZONE`]: `가용성 구역`,
  [`About`]: `정보`,
  [`About audit setting`]: `감사 설정 정보`,
  [`About Authentication URL user registry`]: `인증 URL 사용자 레지스트리 정보`,
  [`About availability zones`]: `가용성 구역 정보`,
  [`About catalogs`]: `카탈로그 정보`,
  [`About consumer organization owners`]: `이용자 조직 소유자 정보`,
  [`About consumer organizations`]: `이용자 조직 정보`,
  [`About developer portals`]: `개발자 포털 정보`,
  [`About email servers`]: `이메일 서버 정보`,
  [`About Endpoints`]: `엔드포인트 정보`,
  [`About gateways`]: `게이트웨이 정보`,
  [`About gateway visibility`]: `게이트웨이 가시성 정보`,
  [`About Gateway Processing Status`]: `게이트웨이 처리 상태 정보`,
  [`About introspection`]: `자체 검사 정보`,
  [`About Keystores`]: `키 저장소 정보`,
  [`About LDAP`]: `LDAP 정보`,
  [`About local user registries`]: `로컬 사용자 레지스트리 정보`,
  [`About metadata`]: `메타데이터 정보`,
  [`About Migration Target`]: `마이그레이션 대상 정보`,
  [`About migration targets`]: `마이그레이션 대상 정보`,
  [`About native OAuth provider`]: `원시 OAuth 제공자 정보`,
  [`About OpenID Connect`]: `OpenID Connect 정보`,
  [`About replacing a product`]: `제품 교체 정보`,
  [`About Role defaults`]: `역할 기본값 정보`,
  [`About roles`]: `역할 정보`,
  [`About scopes`]: `범위 정보`,
  [`About Set Migration Targets`]: `마이그레이션 대상 설정 정보`,
  [`About spaces`]: `공간 정보`,
  [`About Subscription`]: `등록 정보`,
  [`About superseding a product`]: `제품 대체 정보`,
  [`About TLS server profile`]: `TLS 서버 프로파일 정보`,
  [`About TLS client profiles`]: `TLS 클라이언트 프로파일 정보`,
  [`About TLS server profiles`]: `TLS 서버 프로파일 정보`,
  [`About third party OAuth provider`]: `써드파티 OAuth 제공자 정보`,
  [`About tokens`]: `토큰 정보`,
  [`About Transfer Ownership`]: `소유권 이전 정보`,
  [`About Transferring Ownership`]: `소유권 양도 정보`,
  [`About Truststores`]: `신뢰 저장소 정보`,
  [`About adding a member`]: `구성원 추가 정보`,
  [`About adding provider organization member`]: `제공자 조직 구성원 추가 정보`,
  [`About adding members to a Space`]: `공간에 구성원 추가 정보`,
  [`About adding members to a catalog`]: `카탈로그에 구성원 추가 정보`,
  [`About changing owners`]: `소유자 변경 정보`,
  [`About enabling gateway services`]: `게이트웨이 서비스 사용 정보`,
  [`About inviting a member`]: `구성원 초대 정보`,
  [`About inviting catalog members`]: `카탈로그 구성원 초대 정보`,
  [`About inviting provider organization members`]: `제공자 조직 구성원 초대 정보`,
  [`About inviting space members`]: `공간 구성원 초대 정보`,
  [`About keystores`]: `키 저장소 정보`,
  [`About managing Space membership`]: `공간 멤버십 관리 정보`,
  [`About migrating subscriptions`]: `등록 마이그레이션 정보`,
  [`About provider organizations`]: `제공자 조직 정보`,
  [`About roles and role defaults`]: `역할 및 역할 기본값 정보`,
  [`About roles in spaces`]: `공간의 역할 정보`,
  [`About selecting gateway services`]: `게이트웨이 서비스 선택 정보`,
  [`About send message`]: `메시지 보내기 정보`,
  [`About sending messages`]: `발신 메시지 정보`,
  [`About the analytics service`]: `분석 서비스 정보`,
  [`About the DNS Scheme`]: `DNS 스킴 정보`,
  [`About the Gateway Service`]: `게이트웨이 서비스 정보`,
  [`About the Portal Service`]: `포털 서비스 정보`,
  [`About the product lifecycle`]: `제품 라이프사이클 정보`,
  [`About the provider organization owner`]: `제공자 조직 소유자에 대한 정보`,
  [`About this Cloud`]: `클라우드 정보`,
  [`About transferring ownership`]: `소유권 양도 정보`,
  [`About truststores`]: `신뢰 저장소 정보`,
  [`About visibility`]: `가시성 정보`,
  [`About visibility and subscribability`]: `가시성 및 등록 가능성 정보`,
  [`Accept`]: `동의`,
  [`Access Code`]: `액세스 코드`,
  [`Access Token`]: `액세스 토큰`,
  [`Access URL through Secure Gateway`]: `Secure Gateway를 통해 URL 액세스`,
  [`Access code`]: `액세스 코드`,
  [`Access code is not chosen in supported grant type.`]: `액세스 코드가 지원되는 권한 부여 유형에서 선택되지 않았습니다.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Secure Gateway를 통해 URL에 액세스하십시오. 게이트웨이는 스테이징된 상태에서 공개된 상태로 API를 이동할 때 설정됩니다.`,
  [`Access token TTL has been changed`]: `액세스 토큰 TTL이 변경됨`,
  [`Access token time to live`]: `액세스 토큰 TTL(Time To Live)`,
  [`Access token time-to-live`]: `액세스 토큰 TTL(Time To Live)`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `액세스 토큰이 **{seconds}초({time})** 후에 만료됨`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `애플리케이션이 애플리케이션 사용자를 대신하여 자원에 액세스할 수 있도록 허용하기 위해 클라이언트 애플리케이션에 액세스 토큰이 부여됩니다.  현재 액세스 토큰이 올바르지 않게 되거나 만기될 때 새 액세스 토큰을 얻거나 동일하거나 더 좁은 범위의 추가 액세스 토큰을 얻기 위해 클라이언트에 새로 고치기 토큰이 발행됩니다. 또한 임의의 수의 액세스 및 새로 고치기 토큰의 결합에 의해 제공된 동의가 얼마 동안 유효한지 지정할 수 있습니다.`,
  [`Access tokens time to live (seconds)`]: `액세스 토큰 수명(초)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `액세스 토큰, ID 토큰 및 임시 토큰을 기존 키 저장소에 지정할 수 있습니다. 키 저장소 지정 히스토리를 보관합니다.`,
  [`Account`]: `계정`,
  [`Account has been updated.`]: `계정이 업데이트되었습니다.`,
  [`Account password has been changed.`]: `계정 비밀번호가 변경되었습니다.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `조치`,
  [`Activate API`]: `API 활성화`,
  [`Activate API|button text`]: `API 활성화`,
  [`Activate API for testing|title`]: `API 활성화`,
  [`Activation link`]: `활성화 링크`,
  [`Active`]: `활성`,
  [`Activity log`]: `활동 로그`,
  [`Activity log API setting is not available for this API's gateway type.`]: `활동 로그 API 설정은 이 API 게이트웨이 유형에 대해 사용할 수 없습니다.`,
  [`Active loading indicator`]: `활성 로딩 표시기`,
  [`Add`]: `추가`,
  [`Add allowlist`]: `허용 목록 추가`,
  [`Add a new billing integration in Resources`]: `자원의 새 청구 통합 추가`,
  [`Add API user registries`]: `API 사용자 레지스트리 추가`,
  [`Add APIs to Product`]: `제품에 API 추가`,
  [`Add another cloud`]: `다른 클라우드 추가`,
  [`Add billing integration`]: `청구 통합 추가`,
  [`Add Burst Limit`]: `버스트 한계 추가`,
  [`Add Ciphers for TLS client profile`]: `TLS 클라이언트 프로파일에 대한 암호 추가`,
  [`Add Ciphers for TLS server profile`]: `TLS 서버 프로파일에 대한 암호 추가`,
  [`Add Condition`]: `조건 추가`,
  [`Add Consumer Role`]: `이용자 역할 추가`,
  [`Add Count Limit`]: `개수 한계 추가`,
  [`Add Credential`]: `신임 정보 추가`,
  [`Add domain`]: `도메인 추가`,
  [`Add credential`]: `신임 정보 추가`,
  [`Add Extension`]: `확장 추가`,
  [`Add GraphQL schema`]: `GraphQL 스키마 추가`,
  [`Add HTTP Endpoint`]: `HTTP 엔드포인트 추가`,
  [`Add group`]: `그룹 추가`,
  [`Add JSON schema`]: `JSON 스키마 추가`,
  [`Add Keystore to TLS client profile`]: `TLS 클라이언트 프로파일에 키 저장소 추가`,
  [`Add Keystore to TLS server profile`]: `TLS 서버 프로파일에 키 저장소 추가`,
  [`Add member`]: `구성원 추가`,
  [`Add New Role for Consumer organization`]: `이용자 조직에 대한 새 역할 추가`,
  [`Add operation`]: `오퍼레이션 추가`,
  [`Add Parameters`]: `매개변수 추가`,
  [`Add Policy`]: `정책 추가`,
  [`Add Rate limit`]: `속도 제한 추가`,
  [`Add Rate Limit`]: `속도 제한 추가`,
  [`Add role`]: `역할 추가`,
  [`Add Syslog TCP Endpoint`]: `Syslog TCP 엔드포인트 추가`,
  [`Add Syslog TLS Endpoint`]: `Syslog TLS 엔드포인트 추가`,
  [`Add Syslog UDP Endpoint`]: `Syslog UDP 엔드포인트 추가`,
  [`Add XML schema`]: `XML 스키마 추가`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `이 카탈로그에 있는 API에 대한 호출에 사용 가능하도록 설정할 게이트웨이 엔드포인트를 추가하십시오.`,
  [`Add a different Cloud Connection.`]: `다른 클라우드 연결 추가`,
  [`Add a member from the user registry`]: `사용자 레지스트리에서 구성원 추가`,
  [`Add a user to the Admin organization, and assign the required roles`]: `관리자 조직에 사용자를 추가하고 필수 역할 지정`,
  [`Add a user to the provider organization, and assign the required roles`]: `제공자 조직에 사용자를 추가하고 필수 역할 지정`,
  [`Add a user to the Catalog, and assign the required roles`]: `카탈로그에 사용자를 추가하고 필수 역할 지정`,
  [`Add a user to the Space, and assign the required roles`]: `공간에 사용자를 추가하고 필수 역할 지정`,
  [`Add action`]: `조치 추가`,
  [`Add additional client ID/client secret pairs`]: `추가적인 고객 ID/고객 시크릿 쌍 추가`,
  [`Add blocklist`]: `차단 목록 추가`,
  [`Add case`]: `케이스 추가`,
  [`Add catch`]: `발견 추가`,
  [`Add default catch`]: `기본 발견 추가`,
  [`Add destination`]: `대상 추가`,
  [`Add entry`]: `항목 추가`,
  [`Add group failed.`]: `그룹 추가에 실패했습니다.`,
  [`Add input`]: `입력 추가`,
  [`Add media type`]: `매체 유형 추가`,
  [`Add member failed`]: `구성원 추가 실패`,
  [`Add object`]: `오브젝트 추가`,
  [`Add otherwise`]: `그 외의 경우 추가`,
  [`Add output`]: `출력 추가`,
  [`Add outputs for operation...`]: `오퍼레이션에 대한 출력 추가...`,
  [`Add parameters for operation...`]: `오퍼레이션에 대한 매개변수 추가...`,
  [`Add parameters to this API`]: `이 API에 매개변수 추가`,
  [`Add plans to product`]: `제품에 플랜 추가`,
  [`Add plans to this product`]: `이 제품에 플랜 추가`,
  [`Add schema`]: `스키마 추가`,
  [`Add scopes for this OAuth provider.`]: `이 OAuth 제공자에 대한 범위를 추가합니다.`,
  [`Add scopes to allow access to`]: `액세스를 허용하기 위해 범위 추가`,
  [`Add scopes to allow access to.`]: `액세스를 허용할 범위를 추가합니다.`,
  [`Add tags and external documentation details for this API`]: `이 API에 대한 태그 및 문서 세부사항 추가`,
  [`Add to`]: `추가 대상:`,
  [`Add to existing product`]: `기존 제품에 추가`,
  [`Add/Remove APIs`]: `API 추가/제거`,
  [`AddProperties`]: `특성 추가`,
  [`Added APIs`]: `추가된 API`,
  [`Added rate limit`]: `추가된 속도 제한`,
  [`Added rate limits`]: `추가된 속도 제한`,
  [`Adding an API to a product for publishing`]: `공개를 위해 제품에 API 추가`,
  [`Additional support`]: `추가 지원`,
  [`Additional properties`]: `추가 특성`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `또한 연관된 레지스트리, {name} 카탈로그 사용자 레지스트리(있는 경우)를 제거할 것을 고려하십시오.`,
  [`Address`]: `주소`,
  [`Admin DN`]: `관리자 DN`,
  [`Admin organization invitation timeout`]: `관리자 조직 초대 제한시간`,
  [`Admin organization invitation timeout has been changed`]: `Admin 조직 초대 제한시간이 변경됨`,
  [`Admin password`]: `관리자 비밀번호`,
  [`Admin request reset password`]: `관리자 요청 재설정 비밀번호`,
  [`Admin reset password`]: `관리자 재설정 비밀번호`,
  [`Admin Sign In`]: `Admin 로그인`,
  [`Admin add catalog failed`]: `관리자 카탈로그 추가 실패`,
  [`Admin add space failed`]: `관리자 공간 추가 실패`,
  [`Administer consumer organizations`]: `이용자 조직 관리`,
  [`Administers the API consumer organization`]: `API 이용자 조직 관리`,
  [`Administers the API provider organization`]: `API 제공자 조직을 관리함`,
  [`Administers the admin organization`]: `관리 조직 관리`,
  [`Administers the admin topology`]: `관리 토폴로지 관리`,
  [`Administers the app developer organization`]: `앱 개발자 조직 관리`,
  [`Administers the catalog`]: `카탈로그 관리`,
  [`Administers the cloud topology`]: `클라우드 토폴로지 관리`,
  [`Administers the space`]: `공간 관리`,
  [`Administration management console`]: `관리 콘솔`,
  [`Administrator`]: `관리자`,
  [`Advanced analytics configuration`]: `고급 분석 구성`,
  [`Advanced features`]: `고급 기능`,
  [`Advanced scope check`]: `고급 범위 확인`,
  [`Advanced scope check after Token Validation`]: `토큰 유효성 검증 후에 고급 범위 확인`,
  [`Advanced scope check before Token Generation`]: `토큰 생성 전에 고급 범위 확인`,
  [`Aggregation`]: `집계`,
  [`Agree`]: `동의`,
  [`All`]: `모두`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `이 개발자 포털에 등록한, 이용자 조직의 인증된 모든 개발자가 이 제품을 볼 수 있습니다.`,
  [`All consumer organizations will be able to see this product.`]: `모든 이용자 조직이 이 제품을 볼 수 있습니다.`,
  [`Allowlist`]: `허용 목록`,
  [`Allow Chunked Uploads`]: `청크된 업로드 허용`,
  [`Allow chunked uploads`]: `청크된 업로드 허용`,
  [`Allow "plain" challenge method`]: `"일반" 인증 확인 방법 허용`,
  [`Allow renegotiation`]: `재협상 허용`,
  [`Allow case sensitive usernames`]: `대소문자 구분 사용자 이름 허용`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `클라이언트가 서버와의 초기 핸드쉐이크에서 원하는 호스트 이름으로 SNI 확장을 삽입하도록 허용합니다.`,
  [`Allow connection to be renegotiated`]: `연결이 재협상되도록 허용합니다.`,
  [`Allow default introspection`]: `기본 검사 허용`,
  [`Allow insecure server connections`]: `비보안 서버 연결 허용`,
  [`Allow null value`]: `널값 허용`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `access_token/refresh_token에서 로그아웃과 관련하여 302 경로 재지정을 보낼 수 있게 허용`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `모든 권장사항이 적용되었습니다. 스키마에 대한 경고는 없습니다.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `개발자 포털의 테스트 도구를 사용하여 API의 오퍼레이션을 테스트할 수 있습니다.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `취약하거나 안전하지 않은 신임 정보로 연결 진행 허용`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `취약하거나 안전하지 않은 신임 정보로 연결 진행 허용`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `외부 OIDC 제공자가 API 관리자가 아닌 개발자 포털과 통신할 수 있도록 허용합니다.`,
  [`Allow to manage this user registry`]: `해당 사용자 레지스트리 관리 허용`,
  [`Allow to manage users under this user registry`]: `해당 사용자 레지스트리에서 사용자 관리 허용`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `발행자가 발급한 토큰이 APIC에 제공될 때 사용자가 자동으로 온보딩되도록 허용`,
  [`Already have an account?`]: `계정이 이미 있습니까?`,
  [`Also transfer ownership of owned Spaces`]: `소유한 공간의 소유권도 이전`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `사용으로 설정된 경우 항상 사용자 정보 엔드포인트에서 사용자 데이터 페치`,
  [`Always output the root element`]: `루트 요소 항상 출력`,
  [`Always output the root element.`]: `루트 요소를 항상 출력하십시오.`,
  [`Always required`]: `항상 필수`,
  [`Always use userinfo endpoint`]: `항상 사용자 정보 엔드포인트 사용`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `OAuth 제공자 API는 OAuth 플로우의 토큰 엔드포인트 및 권한을 포함합니다. 여기에서 OAuth 제공자를 구성하십시오. 그러면 OAuth 보안 API를 작성할 때 필수 제공자를 선택할 수 있습니다.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `OAuth 제공자는 OAuth 플로우의 토큰 엔드포인트 및 권한을 포함합니다. OAuth를 사용하여 API 보안을 설정하는데 사용하려는 OAuth 제공자를 선택하십시오.`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `개발자가 연관된 API를 호출할 수 있도록 공간 내의 플랜에 애플리케이션을 등록하면 여기에 나열됩니다. 개발자 애플리케이션이 API에 액세스하는 것을 차단하기 위해 일시중단할 수 있습니다. 또한 직접 애플리케이션을 작성할 수 있습니다. [추가 학습]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `인증 URL은 사용자 레지스트리로 써드파티 인증 제공자를 가리킵니다. 인증 URL을 통해 LDAP이 아니라 써드파티 사용자 레지스트리와의 통합을 가능하게 합니다.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `인증 URL은 사용자 정의 ID 제공자에 대해 사용자를 인증하는 단순 메커니즘을 제공합니다.`,
  [`An error occurred communicating with the gateways subsystem.`]: `게이트웨이 하위 시스템과 통신하는 중에 오류가 발생했습니다.`,
  [`An organization is required.`]: `조직은 필수입니다.`,
  [`An unknown error occurred.`]: `알 수 없는 오류가 발생했습니다.`,
  [`Analytics`]: `분석`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Analytics Insights 서비스'`,
  [`Analytics Details`]: `분석 세부사항`,
  [`Analytics destinations`]: `분석 대상`,
  [`Analytics Director keystore`]: `분석 디렉터 키 저장소`,
  [`Analytics Service`]: `분석 서비스`,
  [`Analytics Service URL`]: `분석 서비스 URL`,
  [`Analytics Service {arg} has been removed.`]: `분석 서비스 {arg}이(가) 제거되었습니다.`,
  [`Analytics details`]: `분석 세부사항`,
  [`Analytics service`]: `분석 서비스`,
  [`Analytics service details`]: `분석 서비스 세부 정보`,
  [`Analytics service URL`]: `분석 서비스 URL`,
  [`Analytics service {arg} has been removed.`]: `분석 서비스 {arg}이(가) 제거되었습니다.`,
  [`Analytics client TLS client profile`]: `분석 클라이언트 TLS 클라이언트 프로파일`,
  [`Analytics client keystore`]: `분석 클라이언트 키 저장소`,
  [`Analytics client truststore`]: `분석 클라이언트 신뢰 저장소`,
  [`Analytics ingestion TLS client profile`]: `분석 수집 TLS 클라이언트 프로파일`,
  [`Analytics ingestion keystore`]: `분석 수집 키 저장소`,
  [`Analytics ingestion truststore`]: `분석 수집 신뢰 저장소`,
  [`Analytics service {title} has been created.`]: `분석 서비스 {title}이(가) 작성되었습니다.`,
  [`Analytics service {title} has been updated.`]: `분석 서비스 {title}이(가) 업데이트되었습니다.`,
  [`The selected consumer organization is`]: `선택된 이용자 조직:`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `분석 서비스가 구성되고 분석 데이터가 외부적으로 오프로드되었습니다.`,
  [`Analyze API usage and performance`]: `API 사용 및 성능 분석`,
  [`Anonymous bind`]: `익명 바인드`,
  [`Another user registry`]: `다른 사용자 레지스트리`,
  [`api-administrator`]: `api-administrator`,
  [`Api-Analytics`]: `Api-분석`,
  [`Api-Drafts`]: `Api-드래프트`,
  [`App`]: `앱`,
  [`App name`]: `앱 이름`,
  [`App-Analytics`]: `앱-분석`,
  [`App-Approval`]: `앱-승인`,
  [`App-Dev`]: `앱-개발`,
  [`App-analytics`]: `앱-분석`,
  [`App-dev`]: `앱-개발`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `애플리케이션`,
  [`Application Authentication`]: `애플리케이션 인증`,
  [`Application Authentication Source`]: `애플리케이션 인증 소스`,
  [`Application lifecycle`]: `애플리케이션 라이프사이클`,
  [`Application approval task for upgrading application`]: `애플리케이션 업그레이드를 위한 애플리케이션 승인 태스크`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `{username}({orgname})에서 요청한 프로덕션으로 {taskname} 애플리케이션을 업그레이드하기 위한 애플리케이션 승인 태스크`,
  [`Application authentication`]: `애플리케이션 인증`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `애플리케이션 개발자는 초기에 개발자 포털을 사용하여 하나 이상의 플랜에 애플리케이션을 등록합니다. 그러나 선택한 플랜에 대해 애플리케이션 등록을 다른 제품의 플랜으로 마이그레이션할 수 있습니다.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `애플리케이션 개발자는 초기에 개발자 포털을 사용하여 하나 이상의 플랜에 애플리케이션을 등록합니다. 그러나 선택한 플랜에 대해 애플리케이션 등록을 다른 제품의 플랜으로 마이그레이션할 수 있습니다.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `애플리케이션 개발자가 해당 플랜에서 API를 호출할 수 있도록 플랜에 애플리케이션을 등록합니다.`,
  [`Application is being created. Please Wait`]: `애플리케이션을 작성 중입니다. 잠시 기다려 주십시오.`,
  [`Application scope check`]: `애플리케이션 범위 확인`,
  [`Applications`]: `애플리케이션`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `애플리케이션 라이프사이클을 켜기 전에 존재하던 애플리케이션은 프로덕션 상태로 유지됩니다.`,
  [`Applied security`]: `적용된 보안`,
  [`Apply`]: `적용`,
  [`Apply all`]: `모두 적용`,
  [`Apply all suggestions`]: `모든 제안 적용`,
  [`Apply analysis configuration`]: `분석 구성 적용`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `오퍼레이션 내에서 기존 서비스를 호출하려면 호출 정책을 적용하십시오. JSON 또는 XML 데이터에서 정책을 사용하고 어셈블리 내에 여러 번 적용할 수 있습니다.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `오퍼레이션 내에서 기존 서비스를 호출하기 위한 웹소켓 연결을 설정하려면 웹소켓 업그레이드 정책을 적용하십시오.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `특히 대형 페이로드를 호출해야 하는 경우 오퍼레이션 내에서 프록시 API를 호출하려면 이 정책을 적용하십시오. 어셈블리당 하나의 프록시 정책만 호출할 수 있습니다.`,
  [`Apply to selected`]: `선택된 항목에 적용`,
  [`Apply type analysis configuration`]: `유형 분석 구성 적용`,
  [`Approval History`]: `승인 히스토리`,
  [`Approval tasks`]: `승인 태스크`,
  [`Approvals`]: `승인`,
  [`Approve`]: `승인`,
  [`Archive`]: `아카이브`,
  [`Archive Product`]: `제품 아카이브`,
  [`Archive|permission`]: `아카이브`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `선택한 항목에서 제한조건을 지우시겠습니까?`,
  [`Are you sure you want to delete API?`]: `API를 삭제하시겠습니까?`,
  [`Are you sure you want to delete Product?`]: `제품을 삭제하시겠습니까?`,
  [`Are you sure you want to delete a catalog?`]: `카탈로그를 삭제하시겠습니까?`,
  [`Are you sure you want to delete a space?`]: `공간을 삭제하시겠습니까?`,
  [`Are you sure you want to delete this application?`]: `이 애플리케이션을 삭제하시겠습니까?`,
  [`Are you sure you want to delete this billing integration?`]: `이 청구 통합을 삭제하시겠습니까?`,
  [`Are you sure you want to delete this field?`]: `이 필드를 삭제하시겠습니까?`,
  [`Are you sure you want to delete this policy?`]: `이 정책을 삭제하시겠습니까?`,
  [`Are you sure you want to delete this type?`]: `이 유형을 삭제하시겠습니까?`,
  [`Are you sure you want to disable spaces?`]: `공간을 사용 안함으로 설정하시겠습니까?`,
  [`Are you sure you want to disable custom email sender information?`]: `정말 사용자 정의 이메일 발신인 정보를 사용 안함으로 설정하시겠어요?`,
  [`Are you sure you want to disable custom notification templates?`]: `사용자 정의 알림 템플리트를 사용 안함으로 설정하시겠습니까?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `IBM 개발자 포털의 사용을 중단하시겠습니까? 카탈로그에 대한 모든 포털 사용자 정의가 삭제됩니다.`,
  [`Are you sure you want to enable spaces?`]: `공간을 사용으로 설정하시겠습니까?`,
  [`Are you sure you want to enable custom email sender information?`]: `정말 사용자 정의 이메일 발신인 정보를 사용함으로 설정하시겠어요?`,
  [`Are you sure you want to enable custom notification templates?`]: `사용자 정의 알림 템플리트를 사용으로 설정하시겠습니까?`,
  [`Are you sure you want to proceed with delete?`]: `삭제를 계속 하시겠습니까?`,
  [`Are you sure you want to publish?`]: `공개하시겠습니까?`,
  [`Are you sure you want to re-stage?`]: `다시 스테이징하시겠습니까?`,
  [`Are you sure you want to remove the product from the catalog?`]: `카탈로그에서 이 제품을 제거하시겠습니까?`,
  [`Are you sure you want to unassociate?`]: `연관 해제하시겠습니까?`,
  [`Are you sure you want to update Self Service Onboarding`]: `셀프 서비스 온보딩을 업데이트하시겠습니까?`,
  [`Are you sure?`]: `계속하시겠습니까?`,
  [`Argument`]: `인수`,
  [`Array`]: `배열`,
  [`Assemble`]: `어셈블`,
  [`Assemble the policy flow required for the OAuth provider.`]: `OAuth 제공자에 필요한 정책 플로우를 어셈블합니다.`,
  [`Assembly`]: `어셈블리`,
  [`Assembly Execute`]: `어셈블리 실행`,
  [`Assembly saved`]: `어셈블리 저장 완료`,
  [`Assembly burst limits`]: `어셈블리 버스트 한계`,
  [`Assembly count limits`]: `어셈블리 개수 한계`,
  [`Assign roles`]: `역할 지정`,
  [`Assign roles to the current owner`]: `현재 소유자에게 역할 지정`,
  [`Associate`]: `연관`,
  [`Associate analytics service`]: `분석 서비스 연관`,
  [`Associate analytics`]: `분석 연관`,
  [`Assumed size`]: `추정 크기`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `URL {url}에서 API를 가져오는 중에 인증 오류가 발생했습니다. 사용자 이름 및 비밀번호를 확인하십시오.`,
  [`Audience claim`]: `대상 청구`,
  [`Audience Claim`]: `대상 청구`,
  [`Audit setting`]: `감사 설정`,
  [`Audit setting has been changed`]: `감사 설정이 변경됨`,
  [`Auditing is **{mode}**`]: `감사는 **{mode}** 상태임`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `감사는 API 호출을 모니터링하고 호출 사용자, 각 호출 시간 및 각 이벤트와 관련된 활동에 대한 정보를 기록하는 데 사용됩니다.`,
  [`Authenticate Client Method`]: `클라이언트 인증 방법`,
  [`Authenticate password`]: `비밀번호 인증`,
  [`Authenticate user`]: `사용자 인증`,
  [`Authenticate user settings`]: `사용자 인증 설정`,
  [`Authenticate application users using`]: `사용 중인 애플리케이션 사용자 인증`,
  [`Authenticate using Basic Authentication`]: `기본 인증을 사용하여 인증`,
  [`Authenticate using OAuth`]: `OAuth를 사용하여 인증`,
  [`Authenticated`]: `인증됨`,
  [`Authenticated bind`]: `인증된 바인드`,
  [`Authenticated User Name`]: `인증된 사용자 이름`,
  [`Authentication`]: `인증`,
  [`Authentication Bind`]: `인증 바인드`,
  [`Authentication error`]: `인증 오류`,
  [`Authentication method`]: `인증 방법`,
  [`Authentication TLS profile`]: `TLS 프로파일 인증`,
  [`Authentication URL`]: `인증 URL`,
  [`Authentication URL user registry`]: `인증 URL 사용자 레지스트리`,
  [`Authentication URL user registry has been created.`]: `인증 URL 사용자 레지스트리가 작성되었습니다.`,
  [`Authentication URL to use for validation.`]: `유효성 검증을 위해 사용할 인증 URL입니다.`,
  [`Authentication response header credential`]: `인증 응답 헤더 신임`,
  [`Authentication response header pattern`]: `인증 응답 헤더 패턴`,
  [`Authentication type`]: `인증 유형`,
  [`Authentication type to use to validate the UsernameToken.`]: `UsernameToken을 유효성 검증하기 위해 사용할 인증 유형입니다.`,
  [`Authorization`]: `권한`,
  [`Authorization Code`]: `권한 코드`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `권한 부여 URL이 OAuth 제공자 "{providerTitle}"에서 정의한 대로 {endpoint}과(와) 일치해야 함`,
  [`Authorization endpoint`]: `권한 엔드포인트`,
  [`Authorization Request`]: `권한 요청`,
  [`Authorization URL`]: `권한 부여 URL`,
  [`Authorize`]: `권한 부여`,
  [`Authorize User Settings`]: `사용자 설정 권한 부여`,
  [`Authorize application users using`]: `사용 중인 애플리케이션 사용자 권한 부여`,
  [`Authorize path`]: `권한 부여 경로`,
  [`Authors API and product definitions`]: `작성자 API 및 제품 정의`,
  [`Auto Generate OIDC API Assembly`]: `OIDC API 어셈블리 자동 생성`,
  [`Auto onboard`]: `자동 온보딩`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `API Connect 관리 API 및 명령에서 사용하기 위해 자동 생성된 이름입니다.`,
  [`Automation`]: `자동화`,
  [`Automation Management Console`]: `자동화 관리 콘솔`,
  [`Automation management console`]: `자동화 관리 콘솔`,
  [`Availability Zone`]: `가용성 구역`,
  [`Availability zone`]: `가용성 구역`,
  [`Availability zone {arg} has been removed.`]: `가용성 구역 {arg}이(가) 제거되었습니다.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `가용성 영역을 사용하면 비즈니스 요구에 맞게 API Connect 서비스를 그룹화할 수 있습니다. 예를 들어, 사용자가 있는 지역 또는 데이터 센터에 따라 게이트웨이 서비스를 그룹화할 수 있습니다.`,
  [`Average response time: {{value}}ms`]: `평균 응답 시간: {{value}}밀리초`,
  [`Average time`]: `평균 시간`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `API Designer 서버를 로컬 프록시로 사용하여 CORS 오류를 방지하십시오.`,
  [`Back`]: `이전`,
  [`Back to sign in`]: `로그인으로 돌아가기`,
  [`Back to test`]: `테스트로 돌아가기`,
  [`Backend type`]: `백엔드 유형`,
  [`Bad gateway`]: `잘못된 게이트웨이`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `기본 DN`,
  [`Base Path`]: `기본 경로`,
  [`Base path`]: `기본 경로`,
  [`Base endpoint`]: `기본 엔드포인트`,
  [`Base endpoints`]: `기본 엔드포인트`,
  [`Base64 encoding`]: `Base64 인코딩`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `초대 및 비밀번호 재설정 토큰에 대한 Base64 인코딩은 **{base64Setting}**입니다.`,
  [`Base64 encoding setting for temporary token has been changed`]: `임시 토큰에 대한 Base64 인코딩 설정이 변경되었습니다.`,
  [`Base URL of API invocation endpoint`]: `API 호출 엔드포인트의 기본 URL`,
  [`Basepath root`]: `기본 경로 루트`,
  [`Basic`]: `기본`,
  [`Basic authentication`]: `기본 인증`,
  [`Basic authentication password`]: `기본 인증 비밀번호`,
  [`Basic authentication request header name`]: `기본 인증 요청 헤더 이름`,
  [`Basic authentication username`]: `기본 인증 사용자 이름`,
  [`Before you begin`]: `시작하기 전에`,
  [`Before you begin...`]: `시작하기 전에...`,
  [`Between 0 and 10 events are waiting to be processed`]: `처리할 0 - 10개 이벤트가 대기 중임`,
  [`Between 10 and 20 events are waiting to be processed`]: `처리할 10 - 20개 이벤트가 대기 중임`,
  [`Billing`]: `청구`,
  [`Billing and Payment`]: `청구 및 지불`,
  [`Billing has been updated`]: `청구가 업데이트됨`,
  [`Billing integrations`]: `청구 통합`,
  [`Billing integration`]: `청구 통합`,
  [`Billing Integration`]: `청구 통합`,
  [`Billing {title} successfully added.`]: `{title} 청구가 추가되었습니다.`,
  [`Billing {title} successfully deleted.`]: `{title} 청구가 삭제되었습니다.`,
  [`Billing {title} successfully updated.`]: `{title} 청구가 업데이트되었습니다.`,
  [`Billing Options`]: `청구 옵션`,
  [`Blocklist`]: `차단 목록`,
  [`Blocking`]: `블로킹`,
  [`Blocking option`]: `블로킹 옵션`,
  [`Body`]: `본문`,
  [`Boolean`]: `부울`,
  [`Both`]: `둘 다`,
  [`Browse`]: `찾아보기`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `DataPower API Gateway에서 처리되기 전에 API 요청 및 응답을 버퍼링하십시오.`,
  [`Buffering`]: `버퍼링`,
  [`Builds and manages apps in the developer organization`]: `개발자 조직의 앱 빌드 및 관리`,
  [`Burst Limit Name`]: `버스트 한계 이름`,
  [`Burst limits`]: `버스트 한계`,
  [`Burst limit value can't be negative`]: `버스트 한계 값은 음수일 수 없습니다.`,
  [`Buy`]: `구매`,
  [`but unable to remove because of limitation`]: `하지만 제한사항으로 인해 제거할 수 없습니다.`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `제품의 가시성 및 등록 가능성을 구성하여 개발자 포털에서 애플리케이션 개발자에 대한 API의 사용 가능성을 제어합니다. 제품을 볼 수 있는 애플리케이션 개발자 및 제품에서 API를 사용하도록 등록할 수 있는 애플리케이션 개발자를 제어할 수 있습니다.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `제품의 가시성 및 등록 가능성을 구성하여 개발자 포털에서 애플리케이션 개발자에 대한 API의 사용 가능성을 제어합니다. 제품을 볼 수 있는 애플리케이션 개발자 및 제품에서 API를 사용하도록 등록할 수 있는 애플리케이션 개발자를 제어할 수 있습니다.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `기본적으로 여러 소스에서 맵핑하면(길이 2인 배열 1과 길이 3인 배열 2) 대상 배열에 5개의 항목(배열 1 처리 후 2개, 배열 2 처리 후 3개 항목)이 포함됩니다. 이러한 규칙을 결합하려는 경우(각 소스 배열의 특성이 결합되어 있는 2개 또는 3개 항목이 포함된 배열 작성)에는 이 옵션을 선택하십시오.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `기본적으로 이 제품은 모든 관련 게이트웨이 서비스에 공개됩니다. 이 옵션을 사용하여 특정한 게이트웨이 서비스에 공개할 수도 있습니다.`,
  [`By payload`]: `페이로드별`,
  [`By URL parameter`]: `URL 매개변수별`,
  [`Bytes`]: `바이트`,
  [`CALLS`]: `호출`,
  [`Calls`]: `호출`,
  [`CATALOG`]: `카탈로그`,
  [`CERTIFICATE NAME`]: `인증서 이름`,
  [`CIPHER`]: `암호`,
  [`CIPHER SUITES`]: `암호 스위트`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `CLI 전용`,
  [`CLI + LoopBack + API Designer`]: `CLI + 루프백 + API Designer`,
  [`Client ID`]: `클라이언트 ID`,
  [`CLOUD ADMINISTRATOR`]: `클라우드 관리자`,
  [`Consumer organization`]: `이용자 조직`,
  [`CONSUMER ORGANIZATION / GROUP`]: `이용자 조직 / 그룹`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA 번들`,
  [`Cache Key`]: `캐시 키`,
  [`Cache Time to Live (second)`]: `캐시 TTL(초)`,
  [`Cache key`]: `캐시 키`,
  [`Cache Time-To-Live`]: `캐시 TTL(Time-To-Live)`,
  [`Cache type`]: `캐시 유형`,
  [`Can't find the one you want?`]: `원하는 것을 찾을 수 없습니까?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `메일 서버가 올바르지 않아 초대할 수 없습니다. 메일 서버 구성을 확인하고 다시 시도하십시오.`,
  [`Cancel`]: `취소`,
  [`Cannot be below {min}`]: `{min} 미만일 수 없음`,
  [`Cannot be empty`]: `비어 있을 수 없음`,
  [`Cannot delete root type.`]: `루트 유형을 삭제할 수 없습니다.`,
  [`Cannot exceed {max}`]: `{max}을(를) 초과할 수 없음`,
  [`Cannot find any APIs for this product`]: `이 제품에 대한 API를 찾을 수 없음`,
  [`Cannot find the one you want?`]: `원하는 것을 찾을 수 없습니까?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `"sizedFields"도 정의하지 않은 경우 목록을 리턴하지 않는 필드에는 "@listSize" 지시문을 사용할 수 없습니다.`,
  [`Cannot remove built-in scalar types`]: `기존 스칼라 유형을 제거할 수 없음`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `조회, 변형, 등록 루트 오퍼레이션 유형을 제거할 수 없음`,
  [`Cannot remove arguments of built-in directives`]: `기존 지시문의 인수를 제거할 수 없음`,
  [`Cannot remove non-null arguments of directives`]: `지시문에서 Null이 아닌 인수를 제거할 수 없음`,
  [`Cannot remove input types of arguments of directives`]: `지시문의 인수에 대한 입력 유형을 제거할 수 없음`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `지시문의 인수에 대한 기본값으로 사용되는  열거 값을 제거할 수 없음`,
  [`Cannot remove enum values used as default values of input fields`]: `입력 필드의 기본값으로 사용되는  열거 값을 제거할 수 없음`,
  [`Cannot remove all fields of an object type`]: `오브젝트 유형의 모든 필드를 제거할 수 없음`,
  [`Cannot remove all fields of an interface type`]: `인터페이스 유형의 모든 필드를 제거할 수 없음`,
  [`Cannot remove all slicing arguments`]: `모든 분할 인수를 제거할 수 없음`,
  [`Cannot remove all input fields of an input object type`]: `입력 오브젝트 유형의 모든 입력 필드를 제거할 수 없음`,
  [`Cannot remove all values of enum type`]: `열거 유형의 모든 값을 제거할 수 없음`,
  [`Cannot remove fields of interfaces`]: `인터페이스의 필드를 제거할 수 없음`,
  [`Cannot remove non-null input fields`]: `Null이 아닌 입력 필드를 제거할 수 없음`,
  [`Case`]: `케이스`,
  [`Case sensitive`]: `대소문자 구분`,
  [`Catalog`]: `카탈로그`,
  [`Catalog defaults`]: `카탈로그 기본값`,
  [`Catalog Invite`]: `카탈로그 초대`,
  [`Catalog invite`]: `카탈로그 초대`,
  [`Catalog invites`]: `카탈로그 초대`,
  [`Catalog name`]: `카탈로그 이름`,
  [`Catalog owner`]: `카탈로그 소유자`,
  [`Catalog owner invitation`]: `카탈로그 소유자 초대`,
  [`Catalog properties`]: `카탈로그 특성`,
  [`Catalog summary`]: `카탈로그 요약`,
  [`Catalog title`]: `카탈로그 제목`,
  [`Catalog user registries`]: `카탈로그 사용자 레지스트리`,
  [`Catalog by Name`]: `이름별 카탈로그`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `카탈로그 우선순위(카탈로그 관리자에 의해 정의된 베니티 엔드포인트)`,
  [`Catalog {name} created`]: `카탈로그 {name}이(가) 작성됨`,
  [`Catalog selection`]: `카탈로그 선택사항`,
  [`Catalog settings`]: `카탈로그 설정`,
  [`Catalog settings have been updated`]: `카탈로그 설정이 업데이트되었습니다.`,
  [`Catalog User`]: `카탈로그 사용자`,
  [`Catalog Users`]: `카탈로그 사용자`,
  [`Catalog user registry`]: `카탈로그 사용자 레지스트리`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `라이프사이클이 사용으로 설정된 카탈로그는 테스트 환경 설정에서 사용할 수 없습니다.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `라이프사이클 및 프로덕션 모드가 사용 가능한 카탈로그는 테스트 환경 설정에서 사용할 수 없습니다.`,
  [`Catalogs`]: `카탈로그`,
  [`Catch`]: `발견`,
  [`Catches`]: `발견`,
  [`Categories`]: `카테고리`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `카테고리는 개발자 포털 내의 API 제품의 계층 구조 표시를 설정합니다. top_level_element/next_level_element/lower_level_element 구문을 사용하십시오.`,
  [`Category`]: `카테고리`,
  [`Certifcate or Shared Secret for Verify`]: `확인용 인증서 또는 공유 본인확인정보`,
  [`Certificate`]: `인증서`,
  [`Certificate (Optional)`]: `인증서(선택사항)`,
  [`Certificate management`]: `인증서 관리`,
  [`Certificate Manager instance`]: `인증서 관리자 인스턴스`,
  [`Certificate (optional)`]: `인증서(선택사항)`,
  [`Certificates`]: `인증서`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `게이트웨이와 게이트웨이가 처리하는 도메인 간의 연결을 보호하려면 인증서가 필요합니다. 모든 인증서는 인증서 관리자 서비스에 저장되어 있어야 합니다. 이 서비스는 인증서에 대한 보안 저장소를 제공하고 인증서가 만료될 때 알림을 보내 중단을 방지하는 데 도움이 됩니다. 시작하려면 다음을 수행하십시오.`,
  [`Certificates details in this keystore`]: `이 키 저장소 내의 인증서 세부사항`,
  [`Certificates details in this truststore`]: `이 신뢰 저장소 내의 인증서 세부사항`,
  [`Change`]: `변경`,
  [`Change Consumer Organization owner to an existing user`]: `이용자 조직 소유자를 기존 사용자로 변경`,
  [`Change Ownership to an Existing User`]: `기존 사용자에게 소유권 변경`,
  [`Change portal service`]: `포털 서비스 변경`,
  [`Change owner to existing user`]: `기존 사용자로 소유자 변경`,
  [`Change owner to new user`]: `새 사용자로 소유자 변경`,
  [`Change ownership by inviting a new user`]: `새 사용자를 초대하여 소유권 변경`,
  [`Change ownership to`]: `소유권 변경`,
  [`Change ownership to a new user via email invitation`]: `이메일 초대를 통해 새 사용자로 소유권 변경`,
  [`Change ownership to an existing user`]: `기존 사용자에게 소유권 변경`,
  [`Change password`]: `비밀번호 변경`,
  [`Change setup`]: `설정 변경`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `OAuth 제공자 자체 정의에서 해당 값을 업데이트하지 않은 경우 OAuth 제공자의 기반이 되는 API 또는 어셈블리를 직접 변경하면 OAuth 제공자가 올바르지 않게 될 수 있습니다. 이러한 변경사항을 저장하는 경우 OAuth 제공자 정의에서 해당 사항도 업데이트해야 합니다.`,
  [`Change the following extra constraints in the schema:`]: `스키마에서 다음의 추가 제한조건 변경:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `인증서 관리자를 변경하면 기존 인증서와 CA 번들 지정이 모두 제거됩니다.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `DNS 스킴을 변경하면 API Connect 및 개발자 포털에 대한 URL 링크의 형식이 변경됩니다. 사용자에 대한 새 링크와 통신해야 합니다. 원하지 않는 경우 취소를 클릭하십시오.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `API의 이름 또는 버전을 변경하면 새 API가 작성됩니다. 변경사항을 저장하시겠습니까?`,
  [`Check your email`]: `이메일 확인`,
  [`Child`]: `하위`,
  [`Chinese (Simplified)`]: `중국어(간체)`,
  [`Chinese (Traditional)`]: `중국어(대만어)`,
  [`Choose`]: `선택`,
  [`Choose a`]: `선택`,
  [`Choose {docTypeLabel}`]: `{docTypeLabel} 선택`,
  [`Choose Catalog`]: `카탈로그 선택`,
  [`Choose a Catalog`]: `카탈로그 선택`,
  [`Choose a Consumer Org`]: `소비자 조직 선택`,
  [`Choose Gateway`]: `게이트웨이 선택`,
  [`Choose a Gateway`]: `게이트웨이 선택`,
  [`Choose Product`]: `제품 선택`,
  [`Choose a Product`]: `제품 선택`,
  [`Choose Rate Limit`]: `비율 한계 선택`,
  [`Choose a Rate Limit`]: `비율 한계 선택`,
  [`Choose Application`]: `애플리케이션 선택`,
  [`Choose an Application`]: `애플리케이션 선택`,
  [`Choose a {docTypeLabel}`]: `{docTypeLabel} 선택`,
  [`Choose a publish destination`]: `공개 대상 선택`,
  [`Choose a billing integration`]: `청구 통합 선택`,
  [`Choose a catalog to test within:`]: `테스트할 카탈로그 선택 위치:`,
  [`Choose a plan against which to test:`]: `테스트할 플랜을 선택하십시오.`,
  [`Choose a product containing this API, or create a new one:`]: `이 API를 포함하는 제품을 선택하거나 새로 작성하십시오.`,
  [`Choose a user registry`]: `사용자 레지스트리 선택`,
  [`Choose an application with which to test, or create a new one:`]: `테스트에 사용할 애플리케이션을 선택하거나 새 애플리케이션을 작성하십시오.`,
  [`Choose an existing application`]: `기존 애플리케이션을 선택하십시오.`,
  [`Choose an existing catalog`]: `기존 카탈로그 선택`,
  [`Choose an existing product published to your Sandbox catalog`]: `샌드박스 카탈로그에 공개된 기존 제품을 선택하십시오.`,
  [`Choose an operation to invoke:`]: `호출할 오퍼레이션을 선택하십시오.`,
  [`Choose an option`]: `옵션 선택`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `맵 정책이 비어 있는 배열 결과물의 핸들 방식을 정하는 옵션을 선택하십시오. 모두(기본 옵션)를 선택하면 비어 있는 배열 및 비어 있는 하위 배열 모두를 출력합니다. 상위를 선택하면 비어 있는 상위 배열만 출력합니다. 없음을 선택하면 비어 있는 배열을 출력하지 않습니다.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `블로킹 모드를 선택하면 시스템 성능에 부정적인 영향을 미침`,
  [`Choose existing directory`]: `기존 디렉토리 선택`,
  [`Choose one...`]: `하나 선택...`,
  [`Choose operations and paths to generate into this API`]: `이 API로 생성할 오퍼레이션 및 경로 선택`,
  [`Choose paths to generate into this API`]: `이 API로 생성할 경로 선택`,
  [`Choose the DNS scheme`]: `DNS 스킴 선택`,
  [`Choose the permissions for the role.`]: `역할의 권한을 선택하십시오.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `게이트웨이 서비스에 대한 인바운드 API 호출을 위해서나 개발자 포털에 액세스하는 데 동적 또는 정적 DNS 주소를 사용할지 여부를 선택하십시오.`,
  [`Cipher`]: `암호`,
  [`Ciphers`]: `암호`,
  [`Clear`]: `지우기`,
  [`Clear constraints`]: `제한조건 지우기`,
  [`Clear file`]: `파일 지우기`,
  [`Clear filters`]: `필터 지우기`,
  [`Clear from selected`]: `선택 항목에서 지우기`,
  [`Clear operation filter`]: `오퍼레이션 필터 지우기`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `인증서를 클릭하여 발급 기관 및 지문에 관한 세부사항을 확인하십시오.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `아래 링크를 클릭하여 다른 탭의 API에 계속 권한을 부여하고 2단계의 권한 코드를 가져오십시오.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `아래 링크를 클릭하면 새 탭에서 서버가 열립니다. 브라우저가 인증서 발행을 표시하면 사용자는 이를 승인하도록 선택하고 여기로 돌아와 다시 테스트할 수 있습니다.`,
  [`Click add to add a billing integration.`]: `추가를 클릭하여 청구 통합을 추가하십시오.`,
  [`Click Add to add a gateway extension.`]: `추가를 클릭하여 게이트웨이 확장을 추가하십시오.`,
  [`Click **Add domain** to add domains.`]: `** 도메인 추가 **를 클릭하여 도메인을 추가하십시오.`,
  [`Click Add to add a new base endpoint.`]: `추가를 클릭하여 새 기본 엔드포인트를 추가하십시오.`,
  [`Click Add to add a new HTTP header.`]: `추가를 클릭하여 새 HTTP 헤더를 추가하십시오.`,
  [`Click Add to add a new member.`]: `추가를 클릭하여 새 구성원을 추가하십시오.`,
  [`Click Add to add a new role.`]: `추가를 클릭하여 새 역할을 추가하십시오.`,
  [`Click Add to add a new scope.`]: `추가를 클릭하여 새 범위를 추가하십시오.`,
  [`Click Add to add a new consumer organization group.`]: `추가를 클릭하여 새 이용자 조직 그룹을 추가하십시오.`,
  [`Click Add to create the API Key.`]: `추가를 클릭하여 API 키를 작성합니다.`,
  [`Click Create to add the API Key.`]: `작성을 클릭하여 API 키를 추가합니다.`,
  [`Click Create to add a new user registry.`]: `작성을 클릭하여 새 사용자 레지스트리를 추가하십시오.`,
  [`Click Create user registry to add a new user registry.`]: `사용자 레지스트리 작성을 클릭하여 새 사용자 레지스트리를 추가하십시오.`,
  [`Click **Create provider organization** to add provider organizations.`]: `**제공자 조직 작성**을 클릭하여 제공자 조직을 추가하십시오.`,
  [`Click Edit to add OAuth providers.`]: `편집을 클릭하여 OAuth 제공자를 추가하십시오.`,
  [`Click Edit to add a user registry.`]: `편집을 클릭하여 사용자 레지스트리를 추가하십시오.`,
  [`Click Edit to add an API to the product.`]: `편집을 클릭하여 제품에 API를 추가하십시오.`,
  [`Click Create to add a property.`]: `작성을 클릭하여 특성을 추가하십시오.`,
  [`Click Edit to add TLS client profiles.`]: `편집을 클릭하여 TLS 클라이언트 프로파일을 추가하십시오.`,
  [`Click Edit to add user registries.`]: `편집을 클릭하여 사용자 레지스트리를 추가하십시오.`,
  [`Click Add to add a new consumer organization.`]: `추가를 클릭하여 새 이용자 조직을 추가하십시오.`,
  [`Click Consumer organizations to add one.`]: `이용자 조직을 클릭하여 추가하십시오.`,
  [`Click Add to add a new application.`]: `추가를 클릭하여 새 애플리케이션을 추가하십시오.`,
  [`Click Add to add parameters.`]: `추가를 클릭하여 매개변수를 추가하십시오.`,
  [`Click Add to add response.`]: `추가를 클릭하여 응답을 추가하십시오.`,
  [`Click Create to add a new TLS client profile.`]: `작성을 클릭하여 새 TLS 클라이언트 프로파일을 추가하십시오.`,
  [`Click Create to add a new keystore.`]: `작성을 클릭하여 새 키 저장소를 추가하십시오.`,
  [`Click Create to add a new truststore.`]: `작성을 클릭하여 새 신뢰 저장소를 추가하십시오.`,
  [`Click **Register remote gateway** to add gateways.`]: `**원격 게이트웨이 등록**을 클릭하여 게이트웨이를 추가하십시오.`,
  [`Click Register gateways to add new Gateway Services.`]: `게이트웨이 등록을 클릭하여 새 게이트웨이 서비스를 추가하십시오.`,
  [`Click Edit to add Gateway Services.`]: `편집을 클릭하여 게이트웨이 서비스를 추가하십시오.`,
  [`Click Register gateways to add new gateway services.`]: `게이트웨이 등록을 클릭하여 새 게이트웨이 서비스를 추가하십시오.`,
  [`Click Edit to add gateway services.`]: `편집을 클릭하여 게이트웨이 서비스를 추가하십시오.`,
  [`Click Edit to add an email server.`]: `편집을 클릭하여 이메일 서버를 추가하십시오.`,
  [`Click Create to add a new TLS server profile.`]: `작성을 클릭하여 새 TLS 서버 프로파일을 추가하십시오.`,
  [`Click Create to add a new email server.`]: `작성을 클릭하여 새 이메일 서버를 추가하십시오.`,
  [`Click Add to add a new schema definition.`]: `추가를 클릭하여 새 스키마 정의를 추가하십시오.`,
  [`Click Add to add a new property for this definition.`]: `추가를 클릭하여 이 정의에 대한 새 특성을 추가하십시오.`,
  [`Click Add to add a new parameter for this API.`]: `추가를 클릭하여 이 API에 대한 새 매개변수를 추가하십시오.`,
  [`Click Add to add tags and external documentation for this API.`]: `추가를 클릭하여 이 API에 대한 태그 및 외부 문서를 추가하십시오.`,
  [`Click Add to add a new path.`]: `추가를 클릭하여 새 경로를 추가하십시오.`,
  [`Click Add to add a property for this API.`]: `추가를 클릭하여 이 API의 특성을 추가하십시오.`,
  [`Click here to create a security definition`]: `여기를 클릭하여 보안 정의를 작성하십시오.`,
  [`Click Add to add a new security definition.`]: `추가를 클릭하여 새 보안 정의를 추가하십시오.`,
  [`Click Add to add a target service for this API.`]: `추가를 클릭하여 이 API에 대한 대상 서비스를 추가하십시오.`,
  [`Click Add to add an operation for this path.`]: `추가를 클릭하여 이 경로에 대한 오퍼레이션을 추가하십시오.`,
  [`Click Add to add a path parameter for this path.`]: `추가를 클릭하여 이 경로에 대한 경로 매개변수를 추가하십시오.`,
  [`Click Add to add a new OAuth provider.`]: `추가를 클릭하여 새 OAuth 제공자를 추가하십시오.`,
  [`Click Add to add a new provider organization.`]: `추가를 클릭하여 새 제공자 조직을 추가하십시오.`,
  [`Click Add to add a new API or product.`]: `추가를 클릭하여 새 API 또는 제품을 추가하십시오.`,
  [`Click Add to add a new API.`]: `추가를 클릭하여 새 API를 추가하십시오.`,
  [`Click Add to add a new Extension.`]: `추가를 클릭하여 새 확장을 추가하십시오.`,
  [`Click Add to add new {resource}.`]: `추가를 클릭하여 새 {resource}을(를) 추가하십시오.`,
  [`Click to expand`]: `펼치려면 클릭`,
  [`Click to collapse`]: `접으려면 클릭`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `게이트웨이에 필요한 인증서를 업로드하고 관리하는 방법을 알아보려면 클릭하십시오.`,
  [`Client Credentials`]: `클라이언트 신임 정보`,
  [`Client information`]: `클라이언트 정보`,
  [`Client revocation path`]: `클라이언트 취소 경로`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `서비스 등록을 클릭하여 서비스를 등록하십시오. 그러면 기타 서비스를 구성할 때 엔드포인트를 테스트할 수 있습니다.`,
  [`Client secret`]: `클라이언트 시크릿`,
  [`Client security`]: `클라이언트 보안`,
  [`Client Type`]: `클라이언트 유형`,
  [`Client authentication method`]: `클라이언트 인증 방법`,
  [`Clone`]: `복제`,
  [`Close`]: `닫기`,
  [`Cloud`]: `Cloud`,
  [`Cloud Admin`]: `클라우드 관리`,
  [`Cloud Administrator`]: `클라우드 관리자`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `클라우드 관리자, 조직 관리자, 토폴로지 관리자입니다. 구성원 역할이 자동으로 모든 사용자에게 지정됩니다.`,
  [`Cloud administration`]: `클라우드 관리`,
  [`Cloud Discovery Agent`]: `클라우드 발견 에이전트`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Cloud Manager 로컬 사용자 레지스트리`,
  [`Cloud-Settings`]: `클라우드-설정`,
  [`Cloud settings invitation timeout`]: `클라우드 설정 초대 제한시간`,
  [`Cloud settings invitation timeout has been changed`]: `클라우드 설정 초대 제한시간이 변경됨`,
  [`Code editor`]: `코드 편집기`,
  [`Collect Metadata`]: `메타데이터 수집`,
  [`Collect credentials using`]: `신임 정보 수집`,
  [`Collect metadata`]: `메타데이터 수집`,
  [`Combo box`]: `콤보 상자`,
  [`Community Manager`]: `커뮤니티 관리자`,
  [`community-manager`]: `community-manager`,
  [`Compatibility`]: `호환성`,
  [`Complex Definitions must be edited in the editor`]: `복합 정의는 편집기에서 편집되어야 합니다.`,
  [`Compose DN`]: `작성 DN`,
  [`Compose UPN`]: `작성 UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `경로 및 조작을 정의하여 새 REST 프록시 작성`,
  [`Compose a new product by adding rate limits and plans`]: `속도 제한 및 플랜을 추가하여 새 제품 작성`,
  [`Compression`]: `압축`,
  [`Condition`]: `조건`,
  [`Condition editor`]: `조건 편집기`,
  [`Confidential`]: `기밀`,
  [`Configuration`]: `구성`,
  [`Configurations`]: `구성`,
  [`Configure API endpoint`]: `API 엔드포인트 구성`,
  [`Configure API Gateway Service`]: `API 게이트웨이 서비스 구성`,
  [`Configure analytics service`]: `분석 서비스 구성`,
  [`Configure availability zone`]: `가용성 구역 구성`,
  [`Configure catalog user registries`]: `카탈로그 사용자 레지스트리 구성`,
  [`Configure cloud`]: `클라우드 구성`,
  [`Configure DataPower API gateway service`]: `DataPower API 게이트웨이 서비스 구성`,
  [`Configure DataPower gateway service`]: `DataPower 게이트웨이 서비스 구성`,
  [`Configure Email Server`]: `이메일 서버 구성`,
  [`Configure gateway extension`]: `게이트웨이 확장 구성`,
  [`Configure portal service`]: `포털 서비스 구성`,
  [`Configure service`]: `서비스 구성`,
  [`Configure topology`]: `토폴로지 구성`,
  [`Configure XML`]: `XML 구성`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `API 보안 설정 및 적용에 필요한 DataPower API Gateway 서비스 구성`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `API 보안 및 적용에 필요한 DataPower Gateway 서비스 구성`,
  [`Configure a developer portal service for API consumers`]: `API 이용자를 위해 개발자 포털 서비스를 구성합니다.`,
  [`Configure advanced features for OIDC settings`]: `OIDC 설정에 대한 고급 기능 구성`,
  [`Configure an analytics service to collect API call data`]: `API 호출 데이터를 수집하기 위한 분석 서비스를 구성합니다.`,
  [`Configure an email server to send invitations and notifications to users.`]: `이메일 서버가 사용자에게 초대 및 알림을 발송하도록 구성합니다.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `관리 UI, 관리자 UI, 툴킷, 관리 REST API 및 포털 사용자의 작업을 모니터링하도록 감사를 구성합니다. 각각의 사용자 작성, 업데이트 및 삭제 작업에 대한 로그가 아래에 지정된 원격 로깅 서비스로 전송됩니다.`,
  [`Configure availability zones and services`]: `가용성 구역 및 서비스 구성`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `게시된 API에서 API 엔드포인트 URL을 작성하는 방법 구성`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `개발자 포털에 베니티 엔드포인트를 표시하는 방법을 구성합니다.`,
  [`Configure mapping`]: `맵핑 구성`,
  [`Configure properties of the activity log`]: `활동 로그의 특성 구성`,
  [`Configure settings for token management and revocation.`]: `토큰 관리 및 취소에 대한 설정을 구성합니다.`,
  [`Configure settings to generate and validate tokens.`]: `토큰을 생성하고 유효성 검증을 수행하기 위해 설정을 구성합니다.`,
  [`Configure the API security`]: `API 보안을 구성하십시오.`,
  [`Configure the default set of gateway services configured for each catalog`]: `각 카탈로그에 대해 구성된 게이트웨이 서비스의 기본 세트를 구성합니다.`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `애플리케이션 개발자가 이 카탈로그에서 API에 액세스하는 데 사용되는 개발자 포털 구성`,
  [`Configure the gateway services used by default in each catalog`]: `각 카탈로그에서 기본적으로 사용되는 게이트웨이 서비스를 구성합니다.`,
  [`Configure the gateway extension for the selected gateway`]: `선택한 게이트웨이에 대한 게이트웨이 확장 구성`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `IBM 관리 DataPower API 게이트웨이를 구성하고 자체 원격 게이트웨이를 관리합니다. [추가 학습]({link})`,
  [`Configure the keystore and upload certificates`]: `신뢰 저장소 구성 및 인증서 업로드`,
  [`Configure the name and email address to be used in the from field of emails`]: `이메일의 발신인 필드에서 사용될 이름 및 이메일 주소를 구성합니다.`,
  [`Configure the portal service for the catalog`]: `카탈로그에 대한 포털 서비스를 구성합니다.`,
  [`Configure the role and assign permissions`]: `역할 구성 및 권한 지정`,
  [`Configure the security of this API`]: `이 API의 보안 구성`,
  [`Configure the sender name and address to use for email notifications`]: `이메일 알림에 사용할 발신인 이름 및 주소를 구성합니다.`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `사용자에게 초대 및 알림을 발송하는 데 사용될 발신인 이름, 주소 및 이메일 서버를 구성합니다.`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `이용자 조직이 작성될 때 기본적으로 사용될 역할 세트를 구성합니다.`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `제공자 조직이 작성될 때 기본적으로 사용될 역할 세트를 구성합니다.`,
  [`Configure the templates used to invite and notify users`]: `사용자를 초대하고 알림을 보내는 데 사용할 템플리트를 구성합니다.`,
  [`Configure the truststore and upload certificates`]: `신뢰 저장소 구성 및 인증서 업로드`,
  [`Configure user authentication using`]: `사용자 인증 사용 구성`,
  [`Configure user authentication using JSON Web Tokens`]: `JWT(JSON Web Token)를 사용하여 사용자 인증 구성`,
  [`Configure user authentication using a LDAP provider`]: `LDAP 제공자를 사용하여 사용자 인증 구성`,
  [`Configure user authentication using an API Connect Local User Registry`]: `API Connect 로컬 사용자 레지스트리를 사용하여 사용자 인증 구성`,
  [`Configure user authentication using an authentication URL`]: `인증 URL을 사용하여 사용자 인증 구성`,
  [`Configure user authentication using this user registry type`]: `이 사용자 레지스트리 유형을 사용하여 사용자 인증 구성`,
  [`Configure user registries, OAuth providers and TLS`]: `사용자 레지스트리, OAuth 제공자 및 TLS 구성`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `사용자 레지스트리, TLS, OAuth 제공자 및 이메일 서버 구성`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `액세스 토큰, ID 토큰 및 임시 토큰의 키 저장소 구성`,
  [`Configured OAuth Provider`]: `구성된 OAuth 제공자`,
  [`Configured OAuth Providers`]: `구성된 OAuth 제공자`,
  [`Configured OAuth provider`]: `구성된 OAuth 제공자`,
  [`Configured OAuth provider list has been updated`]: `구성된 OAuth 제공자 목록이 업데이트됨`,
  [`Configured OAuth provider list has not been updated`]: `구성된 OAuth 제공자 목록이 업데이트되지 않음`,
  [`Confirm`]: `확인`,
  [`Confirm deletion`]: `삭제 확인`,
  [`Confirm Deletion of Portal`]: `포털 삭제 확인`,
  [`Confirm visibility settings`]: `가시성 설정 확인`,
  [`Confirm new password`]: `새 비밀번호 확인`,
  [`Confirm password`]: `비밀번호 확인`,
  [`Confirm password should match password`]: `비밀번호 확인은 비밀번호와 일치해야 합니다.`,
  [`Confirmation`]: `확인`,
  [`Congratulations, you are now registered.`]: `축하합니다. 지금 등록되었습니다.`,
  [`Congratulations, your password has been reset!`]: `비밀번호가 재설정되었습니다.`,
  [`Connect`]: `연결`,
  [`Connect to API Connect`]: `API Connect에 연결`,
  [`Connected to API Manager Product version`]: `API Manager 제품 버전에 연결됨`,
  [`Connect to Cloud`]: `클라우드에 연결`,
  [`Connected`]: `연결됨`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `필요한 유형의 특성을 추가하여 스키마 정의를 구성하십시오. 특성을 중첩할 수 있습니다.`,
  [`Consider for nesting`]: `중첩에 대한 고려`,
  [`Consumed Media Types`]: `이용된 매체 유형`,
  [`Consumer`]: `이용자`,
  [`Consumer invitation and roles`]: `이용자 초대 및 역할`,
  [`Consumer onboarding`]: `이용자 온보딩`,
  [`Consumer organizations`]: `이용자 조직`,
  [`Consumer organizations or groups`]: `이용자 조직 또는 그룹`,
  [`Consumer organization {name} created`]: `이용자 조직 {name}이(가) 작성됨`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `이용자 조직은 개발자 포털에서 애플리케이션 개발자가 작성하며, API Manager UI를 사용하여 이용자 조직을 작성할 수도 있습니다.`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `개발자 포털에서 애플리케이션 개발자는 이용자 조직을 작성하며 여기에서도 이용자 조직을 작성할 수 있습니다.`,
  [`Consumer-Org`]: `이용자-조직`,
  [`Consumers`]: `이용자`,
  [`Consumes`]: `이용`,
  [`Contact`]: `연락처`,
  [`Contact information for the owners of the API.`]: `API 소유자에 대한 연락처 정보입니다.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `여기서 데이터를 볼 수 있도록 분석 서비스 구성에 대해 관리자에게 문의하십시오.`,
  [`Contact your administrator for more information.`]: `자세한 정보는 관리자에게 문의하십시오.`,
  [`Content`]: `컨텐츠`,
  [`Content on error`]: `오류가 발생한 컨텐츠`,
  [`Content type`]: `컨텐츠 유형`,
  [`Context Variable`]: `컨텍스트 변수`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `유효성 검증할 JWT가 포함된 컨텍스트 또는 런타임 변수입니다. 설정하지 않을 경우 정책은 request.headers.authorization에서 JWT를 검색합니다.`,
  [`Context variable`]: `컨텍스트 변수`,
  [`Continue`]: `계속`,
  [`Continue on error`]: `오류 시 계속`,
  [`Continue with`]: `계속됨`,
  [`Continue with:`]: `다음으로 계속:`,
  [`Conversion type`]: `변환 유형`,
  [`Convert to expression`]: `표현식으로 변환`,
  [`Cookie`]: `쿠키`,
  [`Copied`]: `복사됨`,
  [`Copied to clipboard`]: `클립보드에 복사됨`,
  [`Copy`]: `복사`,
  [`Copy Id Headers To Message`]: `ID 헤더를 메시지에 복사`,
  [`Copy to clipboard`]: `클립보드에 복사`,
  [`Copy file path to clipboard`]: `파일 경로를 클립보드에 복사`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `GraphQL 조회의 비용 추정을 출력에 복사`,
  [`Copy the endpoints below to`]: `아래 엔드포인트 복사`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `사용할 API 키를 복사합니다. 보기 아이콘을 사용하면 이 API 키를 다시 볼 수 있습니다.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `저작권 정보`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `위 편집기에서 오류를 정정하거나 유효한 파일을 다시 업로드하십시오.`,
  [`Cost`]: `비용`,
  [`Count`]: `개수`,
  [`Count Limit Name`]: `개수 한계 이름`,
  [`Count Limits`]: `개수 한계`,
  [`Created at`]: `작성 위치`,
  [`Counter`]: `카운터`,
  [`Create`]: `작성`,
  [`Create a [Certificate Manager]({link}) service`]: `[인증서 관리자]({link}) 서비스 작성`,
  [`Create API`]: `API 작성`,
  [`Create an API from the Develop section.`]: `개발 섹션에서 API를 작성합니다.`,
  [`Create API Connect API Key`]: `API Connect API 키 작성`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `기존 GraphQL 서비스(GraphQL 프록시)에서 API 작성`,
  [`Create API from existing OpenAPI service`]: `기존 OpenAPI 서비스에서 API 작성`,
  [`Create API from existing WSDL service (REST proxy)`]: `기존 WSDL 서비스(REST 프록시)에서 API 작성`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `기존 WSDL 서비스(SOAP 프록시)에서 API 작성`,
  [`Create API from target service`]: `대상 서비스에서 API 작성`,
  [`Create API from existing REST service`]: `기존 REST 서비스에서 API 작성`,
  [`Create API from existing SOAP service`]: `기존 SOAP 서비스에서 API 작성`,
  [`Create API from existing WSDL service`]: `기존 WSDL 서비스에서 API 작성`,
  [`Create API Key failed`]: `API 키 작성 실패함`,
  [`Create application`]: `애플리케이션 작성`,
  [`Create authentication URL user registry`]: `인증 URL 사용자 레지스트리 작성`,
  [`Create availability zone`]: `가용성 구역 작성`,
  [`Create Billing`]: `청구 작성`,
  [`Create catalog`]: `카탈로그 작성`,
  [`Create catalog property`]: `카탈로그 특성 작성`,
  [`Create consumer organization`]: `이용자 조직 작성`,
  [`Create Credentials`]: `신임 정보 작성`,
  [`Create definition`]: `정의 작성`,
  [`Create email server`]: `이메일 서버 작성`,
  [`Create IBM Developer Portal`]: `IBM 개발자 포털 작성`,
  [`Create keystore`]: `키 저장소 작성`,
  [`Create LDAP user registry`]: `LDAP 사용자 레지스트리 작성`,
  [`Create LTPA Key`]: `LTPA 키 작성`,
  [`Create local user registry`]: `로컬 사용자 레지스트리 작성`,
  [`Create native OAuth provider`]: `원시 OAuth 제공자 작성`,
  [`Create New GraphQL API`]: `GraphQL API 새로 작성`,
  [`Create new OpenAPI`]: `새 OpenAPI 작성`,
  [`Create new product`]: `새 제품 작성`,
  [`Create OIDC user registry`]: `OIDC 사용자 레지스트리 작성`,
  [`Create OpenAPI definition and product definition`]: `OpenAPI 정의 및 제품 정의를 작성합니다.`,
  [`Create operation`]: `오퍼레이션 작성`,
  [`Create organization`]: `조직 작성`,
  [`Create path`]: `경로 작성`,
  [`Create plan`]: `플랜 작성`,
  [`Create portal`]: `포털 작성`,
  [`Create Product`]: `제품 작성`,
  [`Create property`]: `특성 작성`,
  [`Create role`]: `역할 작성`,
  [`Create role default for the Organization`]: `조직에 대한 역할 기본값 작성`,
  [`Create sample user registry`]: `샘플 사용자 레지스트리 작성`,
  [`Create security definition`]: `보안 정의 작성`,
  [`Create space`]: `공간 작성`,
  [`Create subscription`]: `등록 작성`,
  [`Create TLS client profile`]: `TLS 클라이언트 프로파일 작성`,
  [`Create TLS server profile`]: `TLS 서버 프로파일 작성`,
  [`Create target service`]: `대상 서비스 작성`,
  [`Create third party OAuth provider`]: `써드파티 OAuth 제공자 작성`,
  [`Create truststore`]: `신뢰 저장소 작성`,
  [`Create user registry`]: `사용자 레지스트리 작성`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `GraphQL 서비스 기반의 GraphQL 프록시 작성`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `대상 서비스에 설명된 OpenAPI를 기반으로 REST 프록시 작성`,
  [`Create a REST proxy based upon a WSDL described target service`]: `대상 서비스에 설명된 WSDL을 기반으로 REST 프록시 작성`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `대상 서비스에 설명된 OpenAPI를 기반으로 REST 프록시 작성`,
  [`Create a REST proxy based upon the WSDL described target service`]: `대상 서비스에 설명된 WSDL을 기반으로 REST 프록시 작성`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `모든 트래픽을 대상 API 또는 서비스 엔드포인트로 라우팅하는 REST 프록시 작성`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `대상 서비스에 설명된 WSDL을 기반으로 SOAP 프록시 작성`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `대상 서비스에 설명된 WSDL을 기반으로 SOAP 프록시 작성`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `핸들바 템플리트를 통해 새 오브젝트를 작성하십시오. 변수는 컨텍스트에서 가져옵니다.`,
  [`Create a portal site for the catalog`]: `카탈로그에 대한 포털 사이트 작성`,
  [`Create a product by importing a product definition`]: `제품 정의를 가져와서 제품 작성`,
  [`Create a security definition`]: `보안 정의 작성`,
  [`Create a subscription`]: `등록 작성`,
  [`Create an API by importing an OpenAPI definition`]: `OpenAPI 정의를 가져와서 API 작성`,
  [`Create an API that calls an existing SOAP service`]: `기존 SOAP 서비스를 호출하는 API 작성`,
  [`Create and manage API provider organizations and owners`]: `API 제공자 조직과 소유자의 작성 및 관리`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `카탈로그에서 공간을 작성하고 관리하십시오. 공간을 사용하여 여러 API 제공자 개발 팀을 지원하도록 카탈로그를 분할할 수 있습니다.`,
  [`Create and publish`]: `작성 및 공개`,
  [`Create and publish APIs and Products`]: `API 및 제품 작성 및 공개`,
  [`Create and subscribe`]: `작성 및 등록`,
  [`Create assembly`]: `어셈블리 작성`,
  [`Create empty parent object for failed mapping`]: `실패한 맵핑에 대한 빈 상위 오브젝트 작성`,
  [`Create endpoint`]: `엔드포인트 작성`,
  [`Create from`]: `작성 출처`,
  [`Create from Existing WSDL service`]: `기존 WSDL 서비스에서 작성`,
  [`Create from File or URL`]: `파일 또는 URL에서 작성`,
  [`Create from existing openAPI service`]: `기존 openAPIP 서비스에서 작성`,
  [`Create gateway extension`]: `게이트웨이 확장 작성`,
  [`Create new user`]: `새 사용자 작성`,
  [`Create new version`]: `새 버전 작성`,
  [`Create product using a template`]: `템플리트를 사용하여 제품 작성`,
  [`Create provider organization`]: `제공자 조직 작성`,
  [`Create required child properties for array objects and mapped optional objects`]: `배열 오브젝트 및 맵핑된 선택적 오브젝트의 필수 하위 특성 작성`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `OAuth 제공자 구성에서 샘플로 작성되었습니다. 이 샘플을 사용하여 OAuth 제공자를 올바른 사용자 레지스트리로 업데이트해야 합니다.`,
  [`Created new product`]: `작성된 새 제품`,
  [`Create|permission`]: `작성`,
  [`Create|title`]: `작성`,
  [`Creating Draft Product`]: `드래프트 제품 작성`,
  [`Creating a Consumer organization`]: `이용자 조직 작성`,
  [`Creating a consumer organization`]: `이용자 조직 작성`,
  [`Creating a new Product`]: `새 제품 작성`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons(CC-BY-4.0) 라이센스`,
  [`Credential Extraction Method`]: `신임 정보 추출 방법`,
  [`Credentials for the Sandbox catalog`]: `샌드박스 카탈로그의 신임 정보`,
  [`Credentials for the {catalogName} catalog`]: `{catalogName} 카탈로그에 대한 신임 정보`,
  [`Credential Name`]: `신임 정보 이름`,
  [`Credential has been added.`]: `신임 정보가 추가되었습니다.`,
  [`Credential`]: `신임 정보`,
  [`Credentials`]: `신임 정보`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `API Designer 및 툴킷을 실행하려면 신임 정보가 필요합니다.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `툴킷을 실행하려면 신임 정보가 필요합니다. 툴킷이 설치되어 있는 폴더에 신임 정보 파일을 배치하십시오.`,
  [`Credit Card`]: `신용카드`,
  [`Cryptographic Algorithm`]: `암호화 알고리즘`,
  [`Currency`]: `통화`,
  [`Current password`]: `현재 비밀번호`,
  [`Current password is invalid.`]: `현재 비밀번호가 올바르지 않습니다.`,
  [`Custom`]: `사용자 정의`,
  [`Custom API URL`]: `사용자 정의 API URL`,
  [`Custom expression (optional, one per line)`]: `사용자 정의 표현식(선택사항, 행당 하나)`,
  [`Custom expression to remove (optional, one per line)`]: `제거할 사용자 정의 표현식(선택사항, 행당 하나)`,
  [`Custom HTML form`]: `사용자 정의 HTML 양식`,
  [`Custom policies`]: `사용자 정의 정책`,
  [`Custom form TLS profile`]: `사용자 정의 TLS 프로파일`,
  [`Custom form content security policy`]: `사용자 정의 양식 컨텐츠 보안 정책`,
  [`Custom form CSP header value`]: `사용자 정의 양식 CSP 헤더 값`,
  [`Custom form endpoint`]: `사용자 정의 양식 엔드포인트`,
  [`Custom header pattern`]: `사용자 정의 헤더 패턴`,
  [`Custom subscribability is required if custom visibility is selected`]: `사용자 정의 가시성을 선택한 경우 사용자 정의 등록 가능성이 필요합니다.`,
  [`Customize`]: `사용자 정의`,
  [`Customize email sender information`]: `이메일 발신인 정보 사용자 정의`,
  [`Customize notification templates`]: `알림 템플리트 사용자 정의`,
  [`Customize the plan API list`]: `플랜 API 목록 사용자 정의`,
  [`Czech`]: `체코어`,
  [`DATE MODIFIED`]: `수정한 날짜`,
  [`DELETE`]: `DELETE`,
  [`Date created`]: `작성한 날짜`,
  [`Description`]: `설명`,
  [`DETAIL`]: `세부사항`,
  [`DN Base`]: `DN 기본`,
  [`DNS Schemes`]: `DNS 스킴`,
  [`Dashboard`]: `대시보드`,
  [`Data encoded form body`]: `데이터가 인코딩된 양식 본문`,
  [`Data flow in API Connect`]: `API Connect의 데이터 플로우`,
  [`DataPower (v5 compatible)`]: `DataPower(v5 호환 가능)`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway(v5 호환 가능)`,
  [`DataPower Gateway policies`]: `DataPower Gateway 정책`,
  [`Debug`]: `디버그`,
  [`Debug rule`]: `규칙 디버그`,
  [`Debug XACML policy`]: `XACML 정책 디버그`,
  [`Dec`]: `감소`,
  [`Decline`]: `거부`,
  [`Decline and send`]: `거부 및 전송`,
  [`Decode Request Params`]: `요청 매개변수 디코딩`,
  [`Decrement`]: `감소`,
  [`Decrypt`]: `복호화`,
  [`Decrypt Crypto JWK variable name`]: `암호 JWK 변수 이름 복호화`,
  [`Decrypt Crypto Object`]: `암호 오브젝트 복호화`,
  [`Decrypt a buffer using the specified certificate`]: `지정된 인증서를 사용하여 버퍼를 복호화합니다.`,
  [`Default`]: `기본값`,
  [`Default behavior`]: `기본 동작`,
  [`Default gateways`]: `기본 게이트웨이`,
  [`default gateway services configured`]: `기본 게이트웨이 서비스 구성됨`,
  [`Default Availability Zone`]: `기본 가용성 구역`,
  [`Default Base endpoints`]: `기본값 기본 엔드포인트`,
  [`Default availability zone`]: `기본 가용성 구역`,
  [`Default base endpoints`]: `기본값 기본 엔드포인트`,
  [`Default HTML form`]: `기본 HTML 양식`,
  [`Default limit`]: `기본 한계`,
  [`Default OAuth Provider Settings`]: `기본 OAuth 제공자 설정`,
  [`Default OAuth Provider Settings Object`]: `기본 OAuth 제공자 설정 오브젝트`,
  [`Default Plan`]: `기본 플랜`,
  [`Default TLS client profile`]: `기본 TLS 클라이언트 프로파일`,
  [`Default TLS server keystore`]: `기본 TLS 서버 키 저장소`,
  [`Default TLS server profile`]: `기본 TLS 서버 프로파일`,
  [`Default burst-limit`]: `기본 버스트 한계`,
  [`Default form`]: `기본 양식`,
  [`Default keystore for access_token signing key`]: `access_token 서명 키의 기본 키 저장소`,
  [`Default keystore for id_token signing key`]: `id_token 서명 키의 기본 키 저장소`,
  [`Default keystore for temporary token signing key`]: `임시 서명 키의 기본 키 저장소`,
  [`Default rate-limit`]: `기본 비율 한계`,
  [`Default scopes`]: `기본 범위`,
  [`Default table entry`]: `기본 테이블 항목`,
  [`Default validator endpoint`]: `기본 유효성 검증기 엔드포인트`,
  [`Default validator endpoint set by OAuth provider`]: `OAuth 제공자별 기본 유효성 검증기 엔드포인트 세트`,
  [`Default value - no extra constraints apply.`]: `기본값 - 추가 제한조건이 적용되지 않습니다.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `발행할 써드파티 OAuth 제공자를 정의하고 API에 대한 액세스를 보호하기 위해 토큰의 유효성 검증을 수행합니다.`,
  [`Define catalog specific values for this property`]: `이 특성에 대한 카탈로그 특정 값 정의`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `API, 제품, 카탈로그, 애플리케이션 및 관련 설정을 관리하는 그룹을 정의합니다.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `특정 조건에 대해 실행할 정책 세트를 정의하십시오. 여러 경우가 정의될 수 있으며, 각 경우는 임의의 조건과 연관될 수도 있습니다. 첫 번째 일치하는 경우만 실행됩니다.`,
  [`Define the API protection source. At least one option must be enabled.`]: `API 보호 소스를 정의하십시오. 하나 이상의 옵션을 사용으로 설정해야 합니다.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `OIDC 권한 부여 서버는 인증 코드를 다음 엔드포인트로 경로 재지정합니다. 이 경로 재지정 엔드포인트는 고객이 OIDC 제공자에 애플리케이션을 등록할 때 필요합니다. 예를 들어 제공자 조직에서 이 사용자 레지스트리를 사용하는 경우 고객은 제공자 조직의 경로 재지정 엔드포인트를 OIDC  제공자에 등록해야 합니다.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `맵에서 사용할 입력을 정의합니다. 맵에 제목 및 설명을 지정할 수도 있습니다.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `OpenID 제공자에 인증 요청을 전송하기 위한 OpenID 제공자 엔드포인트 정의`,
  [`Define the outputs to be used in the map.`]: `맵에서 사용할 출력을 정의합니다.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `무료 체험 기간과 플랜 가격 책정을 정의합니다. 사용자는 해당 등록일을 기준으로 청구됩니다.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `정책 실행 중에 오류가 발생하면 플로우 진행 여부를 정의합니다. 선택하지 않으면 발견 플로우가 트리거됩니다.`,
  [`Definition`]: `정의`,
  [`Definition Object`]: `정의 오브젝트`,
  [`Definitions`]: `정의`,
  [`Delete`]: `삭제`,
  [`Delete Application`]: `애플리케이션 삭제`,
  [`Delete Cloud Connection.`]: `클라우드 연결 삭제`,
  [`Delete Selected APIs`]: `선택한 API 삭제`,
  [`Delete selected client profiles`]: `선택한 클라이언트 프로파일 삭제`,
  [`Delete selected keystores`]: `선택한 키 저장소 삭제`,
  [`Delete selected mail servers`]: `선택한 메일 서버 삭제`,
  [`Delete Selected Products`]: `선택된 제품 삭제`,
  [`Delete selected truststores`]: `선택한 신뢰성 저장소 삭제`,
  [`Delete selected user registries`]: `선택한 사용자 레지스트리 삭제`,
  [`Delete Staged Product`]: `스테이징된 제품 삭제`,
  [`Delete a space`]: `공간 삭제`,
  [`Delete a catalog`]: `카탈로그 삭제`,
  [`Delete API Key`]: `API 키 삭제`,
  [`Delete API Key failed`]: `API 키 삭제 실패함`,
  [`Delete availability zone`]: `가용성 구역 삭제`,
  [`Delete selected requests`]: `선택한 요청 삭제`,
  [`Delete row`]: `행 삭제`,
  [`Delete|title`]: `삭제`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `이용자 조직, 애플리케이션, 공개된 제품 및 기타 자원이 많은 경우 카탈로그 삭제가 실패할 수 있습니다.  카탈로그를 삭제하기 전에 이러한 자원을 정리할 것을 고려해 보십시오.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `카탈로그, 이용자 조직, 애플리케이션, 공개된 제품 및 기타 자원이 많은 경우 제공자 조직 삭제가 실패할 수 있습니다. 제공자 조직을 삭제하기 전에 이러한 자원을 정리할 것을 고려해 보십시오.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `이용자 조직, 애플리케이션, 공개된 제품 및 기타 자원이 많은 경우 공간 삭제가 실패할 수 있습니다.  공간을 삭제하기 전에 이러한 자원을 정리할 것을 고려해 보십시오.`,
  [`Deleting {{name}}...`]: `{name} 삭제 중...`,
  [`Deprecate`]: `사용 안 함`,
  [`Deprecate product`]: `제품을 더 이상 사용하지 않음`,
  [`Deprecated`]: `더 이상 사용되지 않음`,
  [`Deprecate|permission`]: `사용 안 함`,
  [`Description of gateway`]: `게이트웨이 설명`,
  [`Description text for the behaviour of the modal.`]: `모달의 동작에 대한 설명 텍스트입니다.`,
  [`Descriptive name`]: `설명형 이름`,
  [`Design`]: `디자인`,
  [`Designate a current member as owner of a consumer organization.`]: `현재 구성원을 이용자 조직의 소유자로 지정하십시오.`,
  [`Designate a current member as owner of a provider organization.`]: `현재 구성원을 제공자 조직의 소유자로 지정하십시오.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `대상`,
  [`Determines which credential-extraction method to use.`]: `사용할 신임 정보 추출 방법을 결정합니다.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `이 유형/필드와 적용 가능한 인수를 스키마에 포함할 것인지 결정합니다.`,
  [`Develop`]: `개발`,
  [`Develop APIs`]: `API 개발`,
  [`Develop APIs and products`]: `API 및 제품 개발`,
  [`Developer`]: `개발자`,
  [`developer`]: `개발자`,
  [`Developer Portal`]: `개발자 포털`,
  [`Developer community`]: `개발자 커뮤니티`,
  [`Developers can request a state upgrade from development to production`]: `개발자는 개발에서 프로덕션으로 상태 업그레이드를 요청할 수 있습니다.`,
  [`Development`]: `개발`,
  [`Directive`]: `지시문`,
  [`Director Endpoint`]: `디렉터 엔드포인트`,
  [`Director TLS client profile`]: `디렉터 TLS 클라이언트 프로파일`,
  [`Disable`]: `사용 안함`,
  [`Disable Activity log`]: `활동 로그 사용 안함`,
  [`Disable Self-Service Onboarding`]: `셀프 서비스 온보딩 사용 안함`,
  [`Disable spaces`]: `공간 사용 안함`,
  [`Disable buffering`]: `버퍼링 사용 안함`,
  [`Disable Self-Service Onboarding Approval`]: `셀프 서비스 온보딩 승인 사용 안함`,
  [`Disable overriding consumer organizations invitation timeout.`]: `소비자 조직 초대 제한시간 대체를를 사용하지 않습니다.`,
  [`Disabled`]: `사용 불가능`,
  [`disabled`]: `사용 안함`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `셀프 서비스 온보딩을 사용 안함으로 설정하려면 API 제공자, 기존 이용자 조직 소유자 또는 관리자가 모든 애플리케이션 개발자를 초대해야 합니다.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `셀프 서비스 온보딩 승인을 사용 안함으로 설정하면 모든 온보딩 요청이 자동으로 승인됩니다.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `사용 안함으로 설정하면 소비자 조직 초대 제한시간이 카탈로그 제한시간으로 설정되지 않습니다.`,
  [`Discover targets`]: `대상 검색`,
  [`Display table checkboxes`]: `테이블 선택란 표시`,
  [`Display vanity endpoint`]: `베니티 엔드포인트 표시`,
  [`Do not publish API (continue editting)`]: `API를 공개하지 않음(계속 편집)`,
  [`Do not require applications or users to authenticate`]: `애플리케이션 또는 사용자 인증이 필요하지 않음`,
  [`Do not require authentication`]: `인증이 필요하지 않음`,
  [`Do not share the resource with provider organizations`]: `제공자 조직과 자원을 공유하지 않습니다.`,
  [`Do not share the API gateway with provider organizations`]: `제공자 조직과 API 게이트웨이를 공유하지 마십시오.`,
  [`Do not share the service with provider organizations`]: `제공자 조직과 서비스를 공유하지 않습니다.`,
  [`Do not use Dynamic DNS`]: `동적 DNS를 사용하지 않음`,
  [`Do you want to remove these references?`]: `이 참조를 제거하시겠습니까?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker 문서`,
  [`Docs`]: `문서`,
  [`Document Editor`]: `문서 편집기`,
  [`Documentation and tutorials with step-by-step instructions`]: `단계별 지시사항이 포함된 문서 및 학습서`,
  [`Domain name`]: `도메인 이름`,
  [`Domain Name`]: `도메인 이름`,
  [`Domains handled by gateway via SNI`]: `SNI를 통해 게이트웨이에서 처리되는 도메인`,
  [`Don't have an account?`]: `계정이 없습니까?`,
  [`Done`]: `완료`,
  [`Download`]: `다운로드`,
  [`Download Gateway`]: `게이트웨이 다운로드`,
  [`Download and install`]: `다운로드 및 설치`,
  [`Download and set up DataPower API Gateway`]: `DataPower API Gateway 다운로드 및 설정`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `온프레미스 또는 클라우드에서 사용할 DataPower API Gateway를 다운로드하고 설정하십시오.`,
  [`Download for Linux`]: `Linux용 다운로드`,
  [`Download for Mac`]: `Mac용 다운로드`,
  [`Download for Windows`]: `Windows용 다운로드`,
  [`Download gateway`]: `게이트웨이 다운로드`,
  [`Download schema`]: `스키마 다운로드`,
  [`Download Toolkit`]: `툴킷 다운로드`,
  [`Download toolkit`]: `툴킷 다운로드`,
  [`Download toolkit and credentials for various platforms`]: `툴킷 및 다양한 플랫폼에 대한 신임 정보 다운로드`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `이 클라우드 연결을 위해 다운로드된 인증서를 찾을 수 없습니다. 로그인은 기본 신임 정보를 사용합니다.`,
  [`Dutch`]: `네덜란드어`,
  [`Draft {type} creation failed`]: `드래프트 {type} 작성 실패`,
  [`Drafts`]: `드래프트`,
  [`Drag and drop files here or click to upload`]: `여기에 파일을 끌어서 놓거나 클릭하여 업로드하십시오.`,
  [`Drag & Drop`]: `끌어서 놓기`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `동적 DNS`,
  [`Dynamic OAuth configuration from a URL`]: `URL에서 동적 OAuth 구성 가져오기`,
  [`Dynamic OAuth configuration from a literal string`]: `리터럴 문자열에서 동적 OAuth 구성 가져오기`,
  [`Dynamic group`]: `동적 그룹`,
  [`Dynamic table entries`]: `동적 테이블 항목`,
  [`ENABLE`]: `사용`,
  [`Encoded`]: `인코드`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `각 공간은 다른 API 제공자 개발 팀에서 사용되며 각 공간에는 연관된 팀이 해당 공간에 공개하는 API와 특별히 관련된 자체 관리 기능 세트가 있으므로 각 팀에서 독립적으로 해당 API를 관리할 수 있습니다. API를 공간이 사용으로 설정된 카탈로그에 스테이징하거나 공개할 때 스테이징하거나 공개하려는 해당 카탈로그 내의 공간을 지정합니다.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `각 제공자 조직은 API, 제품, 플랜 및 카탈로그 세트를 소유합니다.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `클라우드 및 온프레미스에 있는 API를 쉽게 작성, 보호, 관리, 공유 및 분석합니다.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `각 제공자 조직은 API, 제품, 플랜 및 카탈로그 세트를 소유합니다. [추가 학습]({link})`,
  [`Edit`]: `편집`,
  [`Edit access token TTL`]: `액세스 토큰 TTL 편집`,
  [`Edit admin organization invitation timeout`]: `관리자 조직 초대 제한시간 편집`,
  [`Edit API`]: `API 편집`,
  [`Edit API Connect UI`]: `API Connect UI 편집`,
  [`Edit API endpoint for unenforced APIs`]: `적용되지 않은 API에 대한 API 엔드포인트 편집`,
  [`Edit API gateway service`]: `API 게이트웨이 서비스 편집`,
  [`Edit API Path`]: `API 경로 편집`,
  [`Edit API security definition`]: `API 보안 정의 편집`,
  [`Edit API user registries`]: `API 사용자 레지스트리 편집`,
  [`Edit analytics service`]: `분석 서비스 편집`,
  [`Edit application`]: `애플리케이션 편집`,
  [`Edit Assembly`]: `어셈블리 편집`,
  [`Edit authentication URL user registry`]: `인증 URL 사용자 레지스트리 편집`,
  [`Edit availability zone`]: `가용성 구역 편집`,
  [`Edit Availablity Zone`]: `가용성 구역 편집`,
  [`Edit and apply constraints to your selected items.`]: `제한조건을 편집하여 선택한 항목에 적용`,
  [`Edit billing integration`]: `청구 통합 편집`,
  [`Edit Catalog Details`]: `카탈로그 세부사항 편집`,
  [`Edit cloud setting invitation timeout`]: `클라우드 설정 초대 제한시간 편집`,
  [`Edit constraints`]: `제한조건 편집`,
  [`Edit consumer organization`]: `이용자 조직 편집`,
  [`Edit DNS Scheme`]: `DNS 스킴 편집`,
  [`Edit DataPower API gateway service`]: `DataPower API 게이트웨이 서비스 편집`,
  [`Edit DataPower gateway service`]: `DataPower 게이트웨이 서비스 편집`,
  [`Edit definition`]: `정의 편집`,
  [`Edit dynamic DNS`]: `동적 DNS 편집`,
  [`Edit email server`]: `이메일 서버 편집`,
  [`Edit extensions`]: `확장기능 편집`,
  [`Edit Gateway Service`]: `게이트웨이 서비스 편집`,
  [`Edit gateway`]: `게이트웨이 편집`,
  [`Edit gateway extension`]: `게이트웨이 확장 편집`,
  [`Edit Gateways`]: `게이트웨이 편집`,
  [`Edit IBM-managed gateway`]: `IBM 관리 게이트웨이 편집`,
  [`Edit Invitation Timeout`]: `초대 제한시간 편집`,
  [`Edit gateways`]: `게이트웨이 편집`,
  [`Edit invitation timeout`]: `초대 제한시간 편집`,
  [`Edit JSON`]: `JSON 편집`,
  [`Edit keystore`]: `키 저장소 편집`,
  [`Edit LDAP user registry`]: `LDAP 사용자 레지스트리 편집`,
  [`Edit LTPA Token`]: `LTPA 토큰 편집`,
  [`Edit lifecycle approvals`]: `라이프사이클 승인 편집`,
  [`Edit local user registry`]: `로컬 사용자 레지스트리 편집`,
  [`Edit native OAuth provider`]: `원시 OAuth 제공자 편집`,
  [`Edit New Billing System`]: `새 청구 시스템 편집`,
  [`Edit Notification Server`]: `알림 서버 편집`,
  [`Edit Notification template`]: `알림 템플리트 편집`,
  [`Edit OAuth Provider`]: `OAuth 제공자 편집`,
  [`Edit OAuth provider details`]: `OAuth 제공자 세부사항 편집`,
  [`Edit OAuth Provider Visibility`]: `OAuth 제공자 가시성 편집`,
  [`Edit OIDC User Registry`]: `OIDC 사용자 레지스트리 편집`,
  [`Edit Onboarding`]: `온보딩 편집`,
  [`Edit Operation`]: `오퍼레이션 편집`,
  [`Edit Organization`]: `조직 편집`,
  [`Edit Path`]: `경로 편집`,
  [`Edit Path Parameters`]: `경로 매개변수 편집`,
  [`Edit notification template`]: `알림 템플리트 편집`,
  [`Edit OAuth provider`]: `OAuth 제공자 편집`,
  [`Edit OAuth provider visibility`]: `OAuth 제공자 가시성 편집`,
  [`Edit OIDC user registry`]: `OIDC 사용자 레지스트리 편집`,
  [`Edit onboarding`]: `온보딩 편집`,
  [`Edit operation`]: `오퍼레이션 편집`,
  [`Edit organization`]: `조직 편집`,
  [`Edit path`]: `경로 편집`,
  [`Edit path parameters`]: `경로 매개변수 편집`,
  [`Edit plan`]: `플랜 편집`,
  [`Edit portal`]: `포털 편집`,
  [`Edit portal service`]: `포털 서비스 편집`,
  [`Edit Portal Settings`]: `포털 설정 편집`,
  [`Edit product`]: `제품 편집`,
  [`Edit product APIs`]: `제품 API 편집`,
  [`Edit Product Subscribability`]: `제품 등록 가능성 편집`,
  [`Edit Product Visibility`]: `제품 가시성 편집`,
  [`Edit Property`]: `특성 편집`,
  [`Edit Provider Organization`]: `제공자 조직 편집`,
  [`Edit Refresh Token TTL`]: `토큰 새로 고치기 TTL 편집`,
  [`Edit remote gateway`]: `원격 게이트웨이 편집`,
  [`Edit Reset Password TTL`]: `비밀번호 재설정 TTL 편집`,
  [`Edit Role`]: `역할 편집`,
  [`Edit Role Default for Provider Organizations`]: `제공자 조직에 대한 역할 기본값 편집`,
  [`Edit Role for Provider Organizations`]: `제공자 조직에 대한 역할 편집`,
  [`Edit property`]: `특성 편집`,
  [`Edit provider organization`]: `제공자 조직 편집`,
  [`Edit refresh token TTL`]: `토큰 새로 고치기 TTL 편집`,
  [`Edit reset password TTL`]: `비밀번호 재설정 TTL 편집`,
  [`Edit role`]: `역할 편집`,
  [`Edit role default for Provider Organizations`]: `제공자 조직에 대한 역할 기본값 편집`,
  [`Edit role for Provider Organizations`]: `제공자 조직에 대한 역할 편집`,
  [`Edit Roles`]: `역할 편집`,
  [`Edit Schema in JSON/XML`]: `JSON/XML에서 스키마 편집`,
  [`Edit security definition`]: `보안 정의 편집`,
  [`Edit sender`]: `발신인 편집`,
  [`Edit sender & email server`]: `발신인 및 이메일 서버 편집`,
  [`Edit sender Info`]: `발신인 정보 편집`,
  [`Edit service visibility`]: `서비스 가시성 편집`,
  [`Edit show/hide settings`]: `설정 표시/숨기기 편집`,
  [`Edit space details`]: `공간 세부사항 편집`,
  [`Edit TLS client profile`]: `TLS 클라이언트 프로파일 편집`,
  [`Edit TLS client profile visibility`]: `TLS 클라이언트 프로파일 가시성 편집`,
  [`Edit TLS server profile`]: `TLS 서버 프로파일 편집`,
  [`Edit third party OAuth provider`]: `써드파티 OAuth 제공자 편집`,
  [`Edit truststore`]: `신뢰 저장소 편집`,
  [`Edit unenforced API`]: `적용되지 않은 API 편집`,
  [`Edit user registries`]: `사용자 레지스트리 편집`,
  [`Edit user registry`]: `사용자 레지스트리 편집`,
  [`Edit user registry visibility`]: `사용자 레지스트리 가시성 편집`,
  [`Edit user registry for API Manager`]: `API Manager에 대한 사용자 레지스트리 편집`,
  [`Edit user registry for Cloud Manager`]: `Cloud Manager에 대한 사용자 레지스트리 편집`,
  [`Edit vanity API endpoints`]: `베니티 API 엔드포인트 편집`,
  [`Edit visibility`]: `가시성 편집`,
  [`Edit XML`]: `XML 편집`,
  [`Edit app lifecycle approved`]: `승인된 앱 라이프사이클 편집`,
  [`Edit app lifecycle cancelled`]: `취소된 앱 라이프사이클 편집`,
  [`Edit app lifecycle denied`]: `거부된 앱 라이프사이클 편집`,
  [`Edit app lifecycle pending`]: `보류 중인 앱 라이프사이클 편집`,
  [`Edit app lifecycle request`]: `앱 라이프사이클 요청 편집`,
  [`Edit app reinstated`]: `복귀된 앱 편집`,
  [`Edit app suspended`]: `일시중단된 앱 편집`,
  [`Edit audit setting`]: `감사 설정 편집`,
  [`Edit definitions`]: `정의 편집`,
  [`Enable external group mapping`]: `외부 그룹 맵핑 사용`,
  [`Edit extra constraints for the GraphQL schema`]: `GraphQL 스키마의 추가 제한조건 편집`,
  [`Edit GraphQL schema|title`]: `GraphQL 스키마 편집`,
  [`Edit inline schema`]: `인라인 스키마 편집`,
  [`Edit inputs`]: `입력 편집`,
  [`Edit invitation`]: `초대 편집`,
  [`Edit mail server test connection`]: `메일 서버 테스트 연결 편집`,
  [`Edit member invitation`]: `멤버 초대 편집`,
  [`Edit notification templates`]: `알림 템플리트 편집`,
  [`Edit notification templates:`]: `알림 템플리트 편집:`,
  [`Edit operation list`]: `오퍼레이션 목록 편집`,
  [`Edit or upload JSON schema`]: `JSON 스키마 편집 또는 업로드`,
  [`Edit outputs`]: `출력 편집`,
  [`Edit password changed`]: `변경된 비밀번호 편집`,
  [`Edit password reset`]: `재설정된 비밀번호 편집`,
  [`Edit plan APIs`]: `플랜 API 편집`,
  [`Edit schema`]: `스키마 편집`,
  [`Edit schema|button`]: `스키마 편집`,
  [`Edit schema|title`]: `스키마 편집`,
  [`Edit settings for roles, notifications and more.`]: `역할, 알림 등을 위한 설정을 편집하십시오.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `사용자 레지스트리, 역할, 엔드포인트 등의 설정 편집`,
  [`Edit sign up`]: `가입 편집`,
  [`Edit task product lifecycle approved`]: `승인된 태스크 제품 라이프사이클 편집`,
  [`Edit task product lifecycle cancelled`]: `취소된 태스크 제품 라이프사이클 편집`,
  [`Edit task product lifecycle denied`]: `거부된 태스크 제품 라이프사이클 편집`,
  [`Edit task product lifecycle pending`]: `보류 중인 태스크 제품 라이프사이클 편집`,
  [`Edit task product lifecycle request`]: `태스크 제품 라이프사이클 요청 편집`,
  [`Edit task subscription approved`]: `승인된 태스크 등록 편집`,
  [`Edit task subscription cancelled`]: `취소된 태스크 등록 편집`,
  [`Edit task subscription denied`]: `거부된 태스크 등록 편집`,
  [`Edit task subscription pending`]: `보류 중인 태스크 등록 편집`,
  [`Edit task subscription request`]: `태스크 등록 요청 편집`,
  [`Edit template`]: `템플리트 편집`,
  [`Edit the TLS client profile.`]: `TLS 클라이언트 프로파일을 편집하십시오.`,
  [`Edit the TLS Server profiles`]: `TLS 서버 프로파일을 편집하십시오.`,
  [`Edit the keystore title.`]: `키 저장소 제목을 편집하십시오.`,
  [`Edit the organization summary details as required`]: `필요에 따라 조직 요약 세부사항 편집`,
  [`Edit the organization title`]: `조직 제목 편집`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `이메일 서버를 구성할 매개변수를 편집하십시오. 선택적으로 지정되지 않은 한 필드는 필수입니다.`,
  [`Edit signed token`]: `서명된 토큰 편집`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `이메일 템플리트의 텍스트를 편집하십시오. 변수는 중괄호로 묶이며 편집할 수 없습니다.`,
  [`Edit the truststore.`]: `신뢰 저장소를 편집하십시오.`,
  [`Edit the visibility for each resource.`]: `각 자원에 대한 가시성을 편집하십시오.`,
  [`Edit user details`]: `사용자 세부사항 편집`,
  [`Edit {notificationTemplate}`]: `{notificationTemplate} 편집`,
  [`Edit, assemble, secure and test APIs`]: `API 편집, 어셈블, 보안 및 테스트`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `API를 편집, 어셈블, 보안 설정, 테스트하십시오. 이용자에게 공개하기 위한 제품을 사용하는 패키지 API입니다.`,
  [`Edit, upload, or infer XML schema`]: `XML 스키마 편집, 업로드 또는 추론`,
  [`Editors`]: `편집기`,
  [`Edit|permission`]: `편집`,
  [`Edit API key timeout`]: `API 키 제한시간 편집`,
  [`Edit Base64 encoding for temporary tokens`]: `임시 토큰에 대한 Base64 인코딩 편집`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `"ID 토큰 서명 암호화 오브젝트" 또는 "ID 토큰 서명 키"가 제공되어야 합니다.`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `"JWT 검증 암호화 오브젝트" 또는 "JWT 검증 JWK"를 제공해야 합니다.`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `API에 정의된 엔드포인트를 사용하거나 기본 유효성 검증기 엔드포인트를 제공하는 옵션 중에서 선택하십시오.`,
  [`Element name for JSON array elements`]: `JSON 배열 요소의 요소 이름`,
  [`Email`]: `이메일`,
  [`Email address`]: `이메일 주소`,
  [`Email Content`]: `이메일 컨텐츠`,
  [`Email endpoint`]: `이메일 엔드포인트`,
  [`Email required`]: `이메일이 필요함`,
  [`Email sender`]: `이메일 발신인`,
  [`Email server`]: `이메일 서버`,
  [`Email server configuration`]: `이메일 서버 구성`,
  [`Email servers`]: `이메일 서버`,
  [`Empty case`]: `비어 있는 케이스`,
  [`Empty catch`]: `비어 있는 발견`,
  [`Empty JSON array handling`]: `비어 있는 JSON 배열 핸들`,
  [`Empty XML element handling`]: `비어 있는 XML 요소 처리`,
  [`Enable`]: `사용`,
  [`Enable API consumers to invite collaborators and assign roles`]: `API 이용자가 협업자를 초대하고 역할을 지정할 수 있도록 설정`,
  [`Enable application lifecycle`]: `애플리케이션 라이프사이클 사용`,
  [`Enable billing`]: `청구 사용`,
  [`Enable gateway services`]: `게이트웨이 서비스 사용`,
  [`Enable gateway services and policies`]: `게이트웨이 서비스 및 정책 사용`,
  [`Enable GraphiQL`]: `GraphiQL 사용`,
  [`Enable GraphiQL editor`]: `GraphiQL 편집기 사용`,
  [`Enable GraphiQL Editor on browser`]: `브라우저에서 GraphiQL 편집기 사용`,
  [`Enable JSON post processing`]: `JSON 사후 처리 사용`,
  [`Enable lifecycle approvals`]: `라이프사이클 승인 사용`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `손상된 요청이 다시 사용(재생)되지 않도록 하기 위해 난스(nonce) 확장 사용`,
  [`Enable OAuth providers`]: `OAuth 제공자 사용`,
  [`Enable OIDC`]: `OIDC 사용`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `OpenID Connect 템플리트를 사용하여 ID 토큰을 생성합니다.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `공용 클라이언트가 권한 코드에서 가로채기 위협을 완화할 수 있도록 PKCE 확장 사용`,
  [`Enable production mode`]: `프로덕션 모드 사용`,
  [`Enable Proxy`]: `프록시 사용`,
  [`Enable Self-Service Onboarding`]: `셀프 서비스 온보딩 사용`,
  [`Enable spaces`]: `공간 사용`,
  [`Enable token management`]: `토큰 관리 사용`,
  [`Enable buffering`]: `버퍼링 사용`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `API 이용자는 초대 없이 개발 포털 계정을 작성할 수 있습니다.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `API에 대해 교차 출처 리소스 공유(CORS) 액세스 제어를 사용합니다.`,
  [`Enable debug response headers`]: `디버그 응답 헤더 사용`,
  [`Enable proof key for code exchange`]: `코드 교환에 대해 교정 키 사용`,
  [`Enable publishing of this product`]: `이 제품의 공개 사용`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `포털 OIDC 엔드포인트를 통한 써드파티 권한 코드의 경로 재지정 사용`,
  [`Enable security`]: `보안 사용`,
  [`Enable Self-Service Onboarding Approval`]: `셀프 서비스 온보딩 승인 사용`,
  [`Enable the following gateway services and policies for this catalog`]: `이 카탈로그에 대해 다음 게이트웨이 서비스 및 정책 사용`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `사용자가 감사 이벤트를 구성할 수 있도록 합니다. 감사 이벤트는 누가 어떤 이벤트를 언제 수행했는지에 대한 자세한 요약입니다.`,
  [`Enable/disable custom notification templates`]: `사용자 정의 알림 템플리트 사용/사용 안함`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `초대 및 비밀번호 재설정 토큰에 대해 Base64 인코딩 사용 또는 사용 안함`,
  [`Enable overriding consumer organizations invitation timeout`]: `소비자 조직 초대 제한시간 대체 사용`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `임시 토큰에 대해 Base64 인코딩을 사용 또는 사용 안함으로 설정할 수 있습니다. 임시 토큰은 초대 및 비밀번호 재설정 토큰입니다. Base64 인코딩이 사용 가능한 경우, 임시 토큰은 Base64 형식으로 인코딩됩니다. Base64 인코딩을 사용하지 않는 경우, 임시 토큰은 JSON 웹 토큰(JWT) 형식으로 남아 있으며 사용자가 수신하는 URL의 길이가 줄어듭니다.`,
  [`Enabled`]: `사용`,
  [`enabled`]: `사용`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `애플리케이션 라이프사이클을 사용으로 설정하면 애플리케이션이 개발 또는 프로덕션 상태일 수 있고 다른 게이트웨이 및 엔드포인트로 라우팅될 수 있습니다.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `셀프 서비스 온보딩 승인을 사용으로 설정하려면 모든 온보딩 요청을 API 제공자 또는 관리자의 승인을 받아야 합니다.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `셀프 서비스 온보딩을 사용으로 설정하면 애플리케이션 개발자가 API 제공자, 기존 이용자 조직 소유자 또는 관리자의 초대를 받지 않고도 가입할 수 있습니다.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `사용으로 설정하면 모든 소비자 조직 초대 제한시간이 카탈로그 초대 제한시간으로 설정됩니다.`,
  [`Encode Query params`]: `조회 매개변수 인코딩`,
  [`Encrypt`]: `암호화`,
  [`Encrypt Crypto Object`]: `암호 오브젝트 암호화`,
  [`Encrypt JWK variable name`]: `JWK 변수 이름 암호화`,
  [`Encrypt a buffer using the specified certificate`]: `지정된 인증서를 사용하여 버퍼를 암호화합니다.`,
  [`Encryption Algorithm`]: `암호화 알고리즘`,
  [`Endpoint`]: `엔드포인트`,
  [`Endpoint URLs`]: `엔드포인트 URL`,
  [`Endpoint behavior`]: `엔드포인트 동작`,
  [`Endpoint Behavior`]: `엔드포인트 동작`,
  [`Endpoint for unenforced APIs`]: `적용되지 않은 API에 대한 엔드포인트`,
  [`Endpoints`]: `엔드포인트`,
  [`Enforce Required Params`]: `필수 매개변수 적용`,
  [`Enforce the API by using API Connect Gateway.`]: `API Connect Gateway를 사용하여 API를 적용하십시오.`,
  [`Enforced`]: `적용됨`,
  [`English`]: `영어`,
  [`Enter Summary`]: `요약 입력`,
  [`Enter URL`]: `URL 입력`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `사용자 레지스트리 이름을 입력하십시오. 비어 있으면, 기본적으로 API Manager 로컬 사용자 레지스트리를 사용합니다.`,
  [`Enter a catalog name`]: `카탈로그 이름 입력`,
  [`Enter a Display Name`]: `표시 이름 입력`,
  [`Enter a space name`]: `공간 이름 입력`,
  [`Enter a brief description`]: `간단한 설명 입력`,
  [`Enter a display name`]: `표시 이름 입력`,
  [`Enter a temporary sign in password`]: `임시 로그인 비밀번호 입력`,
  [`Enter an Organization Name`]: `조직 이름 입력`,
  [`Enter an Organization Title`]: `조직 제목 입력`,
  [`Enter catalog title`]: `카탈로그 제목 입력`,
  [`Enter details of the consumer organization`]: `이용자 조직의 세부사항 입력`,
  [`Enter details of the product`]: `제품의 세부사항 입력`,
  [`Enter details of the provider organization`]: `제공자 조직의 세부사항을 입력합니다.`,
  [`Enter details of this API`]: `이 API의 세부사항 입력`,
  [`Enter email of the new user`]: `새 사용자의 이메일 입력`,
  [`Enter first name of the new user`]: `새 사용자의 이름 입력`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `하나 이상의 이메일 주소를 입력하십시오. 여러 개의 주소는 쉼표를 사용하여 구분하십시오.`,
  [`Enter organization name`]: `조직 이름 입력`,
  [`Enter organization title`]: `조직 직위 입력`,
  [`Enter password`]: `비밀번호 입력`,
  [`Enter space title`]: `공간 제목 입력`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `호스트 이름 및 포트 번호를 제공하여 게이트웨이에 대한 API 엔드포인트를 입력하십시오. 예: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `API 요약 세부사항 입력`,
  [`Enter the API summary details. [Learn more]({link})`]: `API 요약 세부사항을 입력하십시오. [추가 학습]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `프록시하려는 대상 서비스의 URL 입력`,
  [`Enter the catalog summary details`]: `카탈로그 요약 세부사항을 입력하십시오.`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `카탈로그 요약 세부사항 입력: 작성 이후 카탈로그를 완전히 구성할 수 있습니다.`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `관리 조직의 구성원으로 초대할 사용자의 이메일 주소를 입력하십시오.`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `카탈로그의 구성원으로 초대할 사용자의 이메일 주소를 입력하십시오.`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `제공자 조직의 구성원으로 초대할 사용자의 이메일 주소를 입력하십시오.`,
  [`Enter the email address of the user to invite as a member of the space`]: `공간의 구성원으로 초대할 사용자의 이메일 주소를 입력하십시오.`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `소유권을 이전하려는 사용자의 이메일 주소를 입력하십시오.`,
  [`Enter the full path to JSON or YAML file`]: `JSON 또는 YAML 파일의 전체 경로 입력`,
  [`Enter the full path to WSDL file`]: `WSDL 파일의 전체 경로 입력`,
  [`Enter the full path to YAML file`]: `YAML 파일의 전체 경로 입력`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `설치 중에 정의한 분석 수집 엔드포인트의 완전한 도메인 이름을 입력하십시오.`,
  [`Enter the last name of the new user`]: `새 사용자의 성 입력`,
  [`Enter the owner's email address to send the invitation`]: `초대장을 발송할 소유자의 이메일 주소를 입력하십시오.`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `게이트웨이 서비스를 구성할 매개변수를 입력하십시오. 선택적으로 지정되지 않은 한 필드는 필수입니다. 엔드포인트는 DataPower의 API 게이트웨이 서비스 엔드포인트입니다. API 엔드포인트 기본은 들어오는 API 호출에 대한 API 게이트웨이 엔드포인트입니다. SNI의 경우 *를 입력하여 모든 호스트를 허용하고(필수), 호스트 이름도 입력하여 특정 호스트를 허용합니다. 각 호스트를 지원할 TLS 프로파일을 선택하십시오.`,
  [`Enter the password for the certificate file if required.`]: `필요한 경우 인증서 파일의 비밀번호를 입력하십시오.`,
  [`Enter the provider organization summary details`]: `제공자 조직 요약 세부사항을 입력하십시오.`,
  [`Enter the schema definitiom summary details`]: `스키마 정의 요약 세부사항 입력`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `공간 요약 세부사항 입력: 작성 이후 공간을 완전히 구성할 수 있습니다.`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `공간 요약 세부사항 입력: 작성 이후 공간을 완전히 구성할 수 있습니다.`,
  [`Enter the summary details for the new product`]: `새 제품에 대한 세부사항 요약을 입력하십시오.`,
  [`Enter the summary details for this role`]: `이 역할의 요약 세부사항을 입력하십시오.`,
  [`Enter title of the organization`]: `조직의 제목 입력`,
  [`Enter user email`]: `사용자 이메일 입력`,
  [`Enter username of the existing user`]: `기존 사용자의 사용자 이름 입력`,
  [`Enter username of the new user`]: `새 사용자의 사용자 이름 입력`,
  [`Error`]: `오류`,
  [`Error ID`]: `오류 ID`,
  [`Error:`]: `오류:`,
  [`Copy error ID to Clipboard`]: `클립보드에 오류 ID 복사`,
  [`Error Console`]: `오류 콘솔`,
  [`Error connecting`]: `연결 중에 오류 발생`,
  [`Error connecting to GraphQL server`]: `GraphQL 서버에 연결 중 오류 발생`,
  [`Error getting user info during login. Please login with different user.`]: `로그인하는 동안 사용자 정보를 가져오는 중에 오류가 발생했습니다. 다른 사용자로 로그인하십시오.`,
  [`Error content`]: `오류 컨텐츠`,
  [`Error message`]: `오류 메시지`,
  [`Error name`]: `오류 이름`,
  [`Error status code`]: `오류 상태 코드`,
  [`Error status code is invalid`]: `오류 상태 코드가 올바르지 않음`,
  [`Error status reason`]: `오류 상태 이유`,
  [`Errors`]: `오류`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `오류는 일반적으로 잘못된 정보 및 잘못된 문자에서 발생합니다. 테스트 전에 올바른 제공자 정보 및 클라이언트 정보를 제공했는지 확인하십시오.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `URL {url}에서 API를 가져오는 중에 오류가 발생했습니다. URL, 사용자 이름 및 비밀번호를 확인하십시오. 오류 메시지: {message}`,
  [`Event`]: `이벤트`,
  [`Event gateway management client TLS client profile`]: `이벤트 게이트웨이 관리 클라이언트 TLS 클라이언트 프로파일`,
  [`Event load`]: `이벤트 로드`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `모든 이용자 조직에는 소유자가 있습니다. 소유자는 전체 관리 권한이 있으며 등록 및 애플리케이션을 관리할 수 있습니다.`,
  [`Exchange authorization code for tokens`]: `토큰의 권한 코드 교환`,
  [`Exchange code for token`]: `토큰의 코드 교환`,
  [`Execute`]: `실행`,
  [`Execute migration target`]: `마이그레이션 대상 실행`,
  [`Executes any catalog-level post-invoke handlers`]: `카탈로그-레벨 사후 호출 핸들러를 실행합니다.`,
  [`Executes any catalog-level pre-invoke handlers`]: `카탈로그-레벨 사전 호출 핸들러를 실행합니다.`,
  [`Existing`]: `기존`,
  [`Existing {docType}`]: `기존 {docType}`,
  [`Existing API Connect user`]: `기존 API Connect 사용자`,
  [`Existing OpenAPI`]: `기존 OpenAPI`,
  [`Existing product`]: `기존 제품`,
  [`Existing SOAP API has been validated`]: `기존 SOAP API가 유효성 검증되었습니다.`,
  [`Existing products in the catalog will be moved to a default space.`]: `카탈로그 내의 기존 제품이 기본 공간으로 이동됩니다.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `기존 사용자가 현재 구성원입니다. 또한 다른 사용자 레지스트리에서 새 구성원을 활성화할 수도 있습니다.`,
  [`Expires On`]: `만기 날짜`,
  [`Expiry`]: `만기`,
  [`Explorer`]: `탐색기`,
  [`Expose`]: `공개`,
  [`Extend the schema to specify the cost of types and fields`]: `필드 및 유형의 비용을 지정하도록 스키마 확장`,
  [`Extended`]: `확장`,
  [`Extension Type:`]: `확장 유형:`,
  [`Extensions`]: `확장기능`,
  [`Extension has been uploaded.`]: `확장이 업로드되었습니다.`,
  [`External`]: `외부`,
  [`External Doc Description`]: `외부 문서 설명`,
  [`External doc description`]: `외부 문서 설명`,
  [`External Documentation URL`]: `외부 문서 URL`,
  [`External documentation URL`]: `외부 문서 URL`,
  [`External Role mapping`]: `외부 역할 맵핑`,
  [`External URL`]: `외부 URL`,
  [`Extract Identity Settings`]: `ID 추출 설정`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `요소 ID`,
  [`Fail`]: `실패`,
  [`Failed to change account password.`]: `계정 비밀번호를 변경하지 못했습니다.`,
  [`Failed to create analytics service {title}.`]: `분석 서비스 {title}을(를) 작성하는 데 실패했습니다.`,
  [`Failed to create analytics insights service {title}.`]: `Analytics Insights 서비스 {title}을(를) 작성하는 데 실패했습니다.`,
  [`Analytics insights service {title} has been updated.`]: `Analytics insights 서비스 {title}이(가) 업데이트되었습니다.`,
  [`About the analytics insights service`]: `Analytics Insights 서비스 정보`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Analytics Insights 서비스는 게이트웨이 서비스에서 API 이벤트를 수집합니다. 각 게이트웨이 서비스는 연관된 Analytics Insights 서비스를 가질 수 있습니다.`,
  [`Advanced analytics insights configuration`]: `고급 Analytics Insights 구성`,
  [`Analytics insights service {title} has been created.`]: `Analytics Insights 서비스 {title}이(가) 작성되었습니다.`,
  [`Analytics Insights details`]: `Analytics Insights 세부사항`,
  [`Management endpoint on the analytics insights service`]: `Analytics Insights 서비스의 관리 엔드포인트`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `설치 중에 정의한 Analytics Insights 디렉터 엔드포인트에 대한 완전한 도메인 이름을 입력하십시오.`,
  [`TYPE`]: `TYPE`,
  [`Failed to create email server`]: `이메일 서버를 작성하는 데 실패함`,
  [`Failed to create gateway service`]: `게이트웨이 서비스를 작성하는 데 실패함`,
  [`Failed to create gateway service {name}.`]: `게이트웨이 서비스 {name}을(를) 작성하는 데 실패했습니다.`,
  [`Failed to create user registry {title}.`]: `사용자 레지스트리 {title}의 작성에 실패했습니다.`,
  [`Failed to delete a group`]: `그룹을 삭제하는 데 실패했습니다.`,
  [`Failed to get TLS client profiles`]: `TLS 클라이언트 프로파일을 가져오는 데 실패함`,
  [`Failed to get catalogs`]: `카탈로그를 가져오는 데 실패함`,
  [`Failed to get configured OAuth providers`]: `구성된 OAuth 제공자를 가져오는 데 실패함`,
  [`Failed to get policies of gateways`]: `게이트웨이 정책을 가져오는 데 실패함`,
  [`Failed to get user registries`]: `사용자 레지스트리를 가져오는 데 실패함`,
  [`Failed to introspect schema from server:`]: `서버에서 스키마 검사에 실패함:`,
  [`Failed to make API online`]: `API를 온라인으로 만들지 못했습니다.`,
  [`Failed to register catalog member {user}.`]: `카탈로그 구성원 {user}의 등록에 실패했습니다.`,
  [`Failed to remove analytics service: {arg}`]: `분석 서비스 제거 실패: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `가용성 구역 제거 실패: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `게이트웨이 서비스 제거 실패: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `메일 서버 제거 실패: {arg}`,
  [`Failed to remove portal service: {arg}`]: `포털 서비스 제거 실패: {arg}`,
  [`Failed to create {name}.`]: `{name}을(를) 작성하는 데 실패했습니다.`,
  [`Failed to sign out! Please check the console for details.`]: `로그아웃하는 데 실패했습니다! 세부사항은 콘솔을 확인하십시오.`,
  [`Failed to update {name}.`]: `{name}을(를) 업데이트하는 데 실패했습니다.`,
  [`Failed to update OAuth provider`]: `OAuth 제공자를 업데이트하는 데 실패함`,
  [`Failed to update account.`]: `계정을 업데이트하는 데 실패했습니다.`,
  [`Failed to update analytics service {title}.`]: `분석 서비스 {title}을(를) 업데이트하는 데 실패했습니다.`,
  [`Failed to update gateway service {title}.`]: `게이트웨이 서비스 {title}을(를) 업데이트하는 데 실패했습니다.`,
  [`Failed to update task`]: `태스크 업데이트 실패`,
  [`Failed to create app {title}`]: `{title} 앱 작성 실패`,
  [`Feature flags`]: `기능 플래그`,
  [`Fetch schema`]: `스키마 페치`,
  [`Fetched endpoints`]: `페치된 엔드포인트`,
  [`Field`]: `필드`,
  [`Field label`]: `필드 레이블`,
  [`Field Mapping`]: `필드 맵핑`,
  [`Field name`]: `필드 이름`,
  [`Field weight`]: `필드 가중치`,
  [`File Name`]: `파일 이름`,
  [`File Preview`]: `파일 미리보기`,
  [`File contains validation errors`]: `파일에 유효성 검증 오류가 있음`,
  [`File is not a valid OpenAPI definition`]: `파일이 올바른 OpenAPI 정의가 아님`,
  [`File is not a valid extension format.`]: `파일이 올바른 확장 형식이 아닙니다.`,
  [`File is not a valid {fileType} format.`]: `파일이 올바른 {fileType} 형식이 아닙니다.`,
  [`File name`]: `파일 이름`,
  [`File upload`]: `파일 업로드`,
  [`File uploaded is not a valid file`]: `업로드된 파일이 올바른 파일이 아닙니다.`,
  [`File uploaded successfully`]: `파일이 업로드되었습니다.`,
  [`File was updated and validated!`]: `파일이 업데이트되어 유효성 검증되었습니다.`,
  [`File was uploaded and validated!`]: `파일이 업로드되어 유효성 검증되었습니다.`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `파일 {draftFilePath}이(가) 존재합니다. 초안의 이름/버전 조합을 변경해 보십시오.`,
  [`Fill out the details of this service`]: `이 서비스의 세부사항 채우기`,
  [`Filter`]: `필터`,
  [`Filter by operation`]: `오퍼레이션별 필터`,
  [`Filter policies`]: `필터 정책`,
  [`Final Action`]: `최종 조치`,
  [`Find existing user`]: `기존 사용자 찾기`,
  [`Find expert answers in the API Connect community forum`]: `API Connect 커뮤니티 포럼 내의 전문가 답변 찾기`,
  [`Find expert answers in the API Connect community forum.`]: `API Connect 커뮤니티 포럼 내의 전문가 답변을 찾아 보십시오.`,
  [`Find schema`]: `스키마 찾기`,
  [`Finger Print`]: `지문`,
  [`Fingerprint SHA-1`]: `지문 SHA-1`,
  [`Fingerprint SHA-256`]: `지문 SHA-256`,
  [`Finish`]: `완료`,
  [`First name`]: `이름`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `먼저 게이트웨이가 API 트래픽에 사용하는 엔드포인트의 URL을 지정하십시오. 값은 API 호출에 고유한 추가 경로가 있는 완전한 공개 도메인 이름이어야 합니다(예: https://api.mycompany.com).`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `먼저 게이트웨이 관리 엔드포인트의 URL을 지정하십시오. Kubernetes 환경에서 실행되는 게이트웨이의 경우 값은 게이트웨이 설정 명령 set gwy apic-gw-service를 통해 지정된 엔드포인트와 일치해야 합니다. 게이트웨이 어플라이언스의 경우 값은 http://<ip-address-for-gateway>:3000 형식이어야 합니다. 게이트웨이 클러스터로 작업할 때 로드 밸런서의 주소:포트를 지정하십시오.`,
  [`Fit to screen`]: `화면에 맞춤`,
  [`Flow`]: `플로우`,
  [`Follow redirects`]: `경로 재지정 수행`,
  [`Found In`]: `발견 위치`,
  [`Fonts contained in the following file are available under the`]: `다음 파일에 포함된 글꼴은 다음 조건 하에서 사용 가능합니다.`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `WSDL 정의의 경우, WSDL 파일과 API 정의(YAML) 모두를 포함하는 zip 파일을 업로드하십시오.`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `원본 제품의 각 플랜에 대해 교체 제품에서 등록을 마이그레이션할 플랜 선택`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `소스 제품의 각 플랜에 대해 대상 제품에서 등록을 마이그레이션할 플랜 선택`,
  [`For guidance on the available command set, access the CLI's help`]: `사용 가능한 명령 세트에 대한 안내를 보려면 CLI의 도움말을 참고하십시오.`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `기본 OAuth 제공자를 사용하는 사이트의 경우 모든 API 호출에서 사용할 공유 시크릿을 선택적으로 지정하십시오.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `쓰기 가능 LDAP의 경우 사용자 관리를 선택하고 소스 LDAP 레지스트리 속성과 API Connect LDAP 속성 값의 맵핑을 추가하십시오. 사용자 등록 중에 API Connect에 필요한 기본 사용자 프로파일 속성은 username, first_name, last_name, email 및 password입니다. 예제 맵핑 구성이 제공됩니다. 요구사항에 맞게 맵핑을 편집하십시오.`,
  [`Forbidden`]: `금지`,
  [`Forget token`]: `토큰 삭제`,
  [`Forgot password?`]: `비밀번호를 잊었습니까?`,
  [`Forgot password`]: `비밀번호 찾기`,
  [`Form`]: `양식`,
  [`Forum`]: `포럼`,
  [`French`]: `프랑스어`,
  [`From File`]: `원본 파일`,
  [`From Sample JSON`]: `원본 샘플 JSON`,
  [`From Sample XML`]: `원본 샘플 XML`,
  [`From URL`]: `원본 URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `기존 GraphQL 서비스(GraphQL 프록시)에서`,
  [`From existing OpenAPI service`]: `기존 OpenAPI 서비스에서`,
  [`From existing WSDL service (REST proxy)`]: `기존 WSDL 서비스(REST 프록시)에서`,
  [`From existing WSDL service (SOAP proxy)`]: `기존 WSDL 서비스(SOAP 프록시)에서`,
  [`From target service`]: `대상 서비스에서`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `WSDL 정의에서 SOAP 서비스에 대해 정의된 오퍼레이션 중 API에서 사용할 것을 선택하고 메소드 및 URL 경로를 지정하십시오.`,
  [`Gateway Extensions`]: `게이트웨이 확장`,
  [`Gateway extension`]: `게이트웨이 확장`,
  [`Gateway Services`]: `게이트웨이 서비스`,
  [`Gateway Type`]: `게이트웨이 유형`,
  [`Gateway by Name`]: `이름별 게이트웨이`,
  [`Gateway services`]: `게이트웨이 서비스`,
  [`Gateway type`]: `게이트웨이 유형`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL v2 라이센스`,
  [`GROUP`]: `그룹`,
  [`Group name`]: `그룹 이름`,
  [`Gateway`]: `게이트웨이`,
  [`Gateways`]: `게이트웨이`,
  [`Gateway details`]: `게이트웨이 세부사항`,
  [`Gateway Details`]: `게이트웨이 세부사항`,
  [`Gateway Error`]: `게이트웨이 오류`,
  [`Gateway service details`]: `게이트웨이 서비스 세부사항`,
  [`Gateway management client TLS client profile`]: `게이트웨이 관리 클라이언트 TLS 클라이언트 프로파일`,
  [`Gateway management client keystore`]: `게이트웨이 관리 클라이언트 키 저장소`,
  [`Gateway management client truststore`]: `게이트웨이 관리 클라이언트 신뢰 저장소`,
  [`Gateway processing status`]: `게이트웨이 처리 상태`,
  [`Gateway policies (TODO)`]: `게이트웨이 정책(TODO)`,
  [`Gateway service`]: `게이트웨이 서비스`,
  [`Gateway Service US East (TODO)`]: `미국 동부 게이트웨이 서비스(TODO)`,
  [`Gateway Service {arg} has been removed.`]: `게이트웨이 서비스 {arg}이(가) 제거되었습니다.`,
  [`Gateway service configuration`]: `게이트웨이 서비스 구성`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `게이트웨이 서비스 호스트가 사용 불가능합니다. 탐색기에서 API 테스트(시도)를 사용할 수 없습니다.`,
  [`Gateway service configuration has been updated.`]: `게이트웨이 서비스 구성이 업데이트되었습니다.`,
  [`Gateway service {title} has been created.`]: `게이트웨이 서비스 {title}이(가) 작성되었습니다.`,
  [`Gateway service {title} has been updated.`]: `게이트웨이 서비스 {title}이(가) 업데이트되었습니다.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `게이트웨이 서비스는 공간이 사용 가능한 경우 각 공간에서 관리됩니다. 공간 설정으로 이동하여 게이트웨이 서비스를 관리하십시오.`,
  [`Gateway version`]: `게이트웨이 버전`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `페이지당 게이트웨이`,
  [`Gather-and-send`]: `수집 및 전송`,
  [`Gather-only`]: `수집 한정`,
  [`General configuration`]: `일반 구성`,
  [`General information about the API.`]: `API에 대한 일반 정보입니다.`,
  [`Generate`]: `생성`,
  [`Generate an OpenAPI definition for the proxy`]: `프록시의 OpenAPI 정의 생성`,
  [`Generate JSON Web Token (JWT)`]: `JWT(JSON Web Token) 생성`,
  [`Generate JWT`]: `JWT 생성`,
  [`Generate LTPA Token`]: `LTPA 토큰 생성`,
  [`Generate OAuth Access Tokens`]: `OAuth 액세스 토큰 생성`,
  [`Generate OAuth access tokens`]: `OAuth 액세스 토큰 생성`,
  [`Generate a default product`]: `기본 제품 생성`,
  [`Generate access token`]: `액세스 토큰 생성`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `LTPA 토큰을 생성하여 API에서 WebSphere Application Server 웹 서버를 안전하게 인증하십시오.`,
  [`Generate an object`]: `오브젝트 생성`,
  [`Generate authorization code`]: `권한 코드 생성`,
  [`Generate from sample JSON`]: `샘플 JSON으로부터 생성`,
  [`Generate from sample XML`]: `샘플 XML로부터 생성`,
  [`Generate tests automatically to build high quality APIs`]: `고품질 API를 빌드하기 위해 자동으로 테스트 생성`,
  [`Generated OpenAPI 2.0 definition`]: `생성된 OpenAPI 2.0 정의`,
  [`Generated default product for the API`]: `API에 대해 생성된 기본 제품`,
  [`Generated name for use in commands and API calls`]: `명령 및 API 호출에 사용하기 위해 생성된 이름`,
  [`German`]: `독일어`,
  [`Get Base DN`]: `기본 DN 가져오기`,
  [`Get Code`]: `코드 가져오기`,
  [`Get Code Snippet`]: `코드 스니펫 가져오기`,
  [`Get Support`]: `지원 받기`,
  [`Get Token`]: `토큰 가져오기`,
  [`Get activation link`]: `활성화 링크 가져오기`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `게이트웨이 서비스를 등록하여 시작합니다. 그러면 기타 서비스를 구성할 때 엔드포인트를 테스트할 수 있습니다. 일반 서비스를 등록하면 여기에 표시됩니다. [추가 학습]({link})`,
  [`Get subscription ID`]: `등록 ID 가져오기`,
  [`Getting Started`]: `시작하기`,
  [`Getting started`]: `시작하기`,
  [`Git information`]: `Git 정보`,
  [`Github`]: `Github`,
  [`Global policies`]: `글로벌 정책`,
  [`Go to Spaces`]: `공간으로 이동`,
  [`Go to the Test tab`]: `테스트 탭으로 이동`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL 속도 제한`,
  [`GraphQL service`]: `GraphQL 서비스`,
  [`GraphQL schema`]: `GraphQL 스키마`,
  [`GraphQL Schema`]: `GraphQL 스키마`,
  [`GraphQL schema has been successfully validated.`]: `GraphQL 스키마의 유효성 검증이 완료되었습니다.`,
  [`GraphQL Schema URL`]: `GraphQL 스키마 URL`,
  [`GraphQL send type`]: `GraphQL 전송 유형`,
  [`GraphQL Websocket Upgrade`]: `GraphQL 웹소켓 업그레이드`,
  [`Group Based DN`]: `그룹 기반 DN`,
  [`Group {title} added`]: `그룹 {title}이(가) 추가됨`,
  [`Group {title} updated`]: `그룹 {title}이(가) 업데이트됨`,
  [`Group {title} has been deleted`]: `그룹 {title}이(가) 삭제됨`,
  [`Groups`]: `그룹`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `호스트 이름`,
  [`HOST URL`]: `호스트 URL`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML 형식`,
  [`HTML form time limit`]: `HTML 양식 시간 한계`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 필수`,
  [`HTTP Header`]: `HTTP 헤더`,
  [`HTTP Method`]: `HTTP 메소드`,
  [`HTTP Type`]: `HTTP 유형`,
  [`HTTP Version`]: `HTTP 버전`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `핸들바`,
  [`Hard limit`]: `하드 한계`,
  [`Header`]: `헤더`,
  [`Header control`]: `헤더 제어`,
  [`Header name payload`]: `헤더 이름 페이로드`,
  [`Header name token`]: `헤더 이름 토큰`,
  [`Headers`]: `헤더`,
  [`Hello`]: `환영합니다!`,
  [`Hello!`]: `안녕하세요!`,
  [`Hello [IBM](url)`]: `환영합니다. [IBM](http://ibm.com)`,
  [`Hello {name}`]: `{name}님을 환영합니다.`,
  [`Hello {name}!`]: `{name}님을 환영합니다!`,
  [`Hello {name}, [see IBM profile](url)`]: `{name}님을 환영합니다. [IBM 프로파일 참조](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{catalogTitle} 개발자 포털에 계정을 등록해 주셔서 감사합니다.\n\n등록을 완료하려면 다음 링크를 사용하십시오.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{consumerOrg} 개발자 포털의 관리자가 \n{appName} 앱을 복원했습니다. 이 앱에서의 API 호출이 더 이상 \nAPI 게이트웨이에 의해 차단되지 않습니다.\n\n다음 링크를 사용하여 앱 세부사항을 볼 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{consumerOrg} 개발자 포털의 관리자가 \n{appName} 앱을 일시중단했습니다. 이 앱에서의 API 호출이 API 게이트웨이에 의해 차단됩니다.\n\n다음 링크를 사용하여 앱 세부사항을 볼 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그에서 \n{productName} API 제품의 {productVersion} 버전에 대한 {planName} 플랜을 등록하도록 \n승인을 요청했습니다.\n\n다음 링크를 사용하여 태스크 목록에서 요청을 열 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그에서 {appName} 애플리케이션을 사용하여\n{productName} API 제품의 {productVersion} 버전에 대한 {planName} 플랜을 등록하도록 \n승인을 요청했습니다.\n\n다음 링크를 사용하여 태스크 목록에서 요청을 열 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그에서 \n{appName} 앱 {action}에 대한 승인을 요청했습니다.\n\n다음 링크를 사용하여 태스크 목록에서 요청을 열 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그에서 {productName} API 제품의\n {version} 버전 {action}에 대한 승인을 요청했습니다.\n\n다음 링크를 사용하여 태스크 목록에서 요청을 열 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{{firstName}}님을 환영합니다.\n\n{{catalog}} 개발자 포털에서 사용자 계정 {{username}}의\n비밀번호를 재설정하라는 요청을 수신했습니다.\n\n요청을 하지 않은 경우 이 메시지를 무시하십시오.그렇지 않으면\n다음 링크를 사용하여 비밀번호를 재설정할 수 있습니다.\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `{firstName}님을 환영합니다.\n\nIBM API Connect에서 사용자 계정 {username}의 비밀번호를 \n재설정하라는 요청을 수신했습니다.\n\n비밀번호를 재설정하려면 다음 링크를 사용하십시오.\n\n{link}\n\n(요청을 하지 않은 경우 이 메시지를 무시할 수 있습니다.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{catalog} 개발자 포털에서 사용자 계정 {username}의 비밀번호를 \n재설정하라는 요청을 수신했습니다.\n\n요청을 하지 않은 경우 이 메시지를 무시하십시오. 그렇지 않으면\n다음 링크를 사용하여 비밀번호를 재설정할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName}님을 환영합니다.\n\n{catalog} 카탈로그에서 {appName} 앱에 대한 등록을 \n작성하라는 요청이 승인 대기 중입니다. 요청이 처리되면\n다른 알림이 전송됩니다.\n\n다음 링크에서 앱 상태를 확인할 수 있습니다.\n\n{link}\n\n언제든지 요청을 취소하려면 다음 링크를 사용하십시오.\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}}님,\n\nAPI 제품 {{productName}} 버전 {{productVersion}의\n{{planName}} 플랜에 대한 {{appName}} 앱 등록을\n작성하라는 요청이 승인되었습니다.\n\n{{#if 주석}}\n승인자가 다음 주석을 포함시켰습니다.\n\n"{{comments}}"\n\n{{/if}}\n다음 링크에서 앱 상태를 확인할 수 있습니다.\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}님,\n\nAPI 제품 {productName} 버전 {productVersion}의\n{planName} 플랜에 대한 {appName} 앱 등록을\n작성하라는 요청이 승인되었습니다.\n\n{#if comments}\n승인자가 다음 주석을 포함했습니다.\n\n{comments}\n\n{/if}\n다음 링크에서 앱의 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}}님,\n\nAPI 제품 {{productName}} 버전 {{productVersion}의\n{{planName}} 플랜에 대한 {{appName}} 앱 등록을\n작성하라는 요청이 거부되었습니다.\n\n{{#if 주석}}\n승인자가 다음 주석을 포함시켰습니다.\n\n"{{comments}}"\n\n{{/if}}\n다음 링크에서 앱 상태를 확인할 수 있습니다.\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{{firstName}}님을 환영합니다.\n\n{{catalog}} 카탈로그에서 API 제품 {{productName}}:{{version}}\n{{action}}에 대한 요청이 승인되었습니다.\n\n{{#if 주석}}\n승인자가 다음 주석을 포함했습니다.\n\n{{comments}}\n\n{{/if}}\n다음 링크에서 제품의 상태를 확인할 수 있습니다.\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{catalog} 카탈로그에서 API 제품 {productName}:{version} {action}에 대한\n요청이 승인되었습니다.\n\n{#if comments}\n승인자가 다음 주석을 포함했습니다.\n\n{comments}\n\n{/if}\n다음 링크에서 제품의 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}}님,\n\n{catalog} 카탈로그에서 API 제품 {productName}:{version}\n{action}}에 대한 요청이 거부되었습니다.\n\n{#if comments}\n승인자가 다음 주석을 포함했습니다.\n\n{comments}\n\n{/if}\n다음 링크에서 제품의 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{catalog} 카탈로그에서 API 제품 {productName}:{version}\n{action}에 대한 요청이 거부되었습니다.\n\n{#if comments}\n승인자가 다음 주석을 포함했습니다.\n\n{comments}\n\n{/if}\n다음 링크에서 제품의 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName}님을 환영합니다.\n\n{catalog} 카탈로그에서 API 제품 {productName}:{version} {action}에 대한\n요청이 승인 대기 중입니다. 요청이 처리되면\n다른 알림이 전송됩니다.\n\n다음 링크에서 제품 상태를 확인할 수 있습니다.\n\n{link}\n\n언제든지 요청을 취소하려면 다음 링크를 사용하십시오.\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}님,\n\n{consumerOrg} 개발자 포털에서 앱 {appName} {action}에 대한\n 요청이 승인되었습니다.\n\n{#if 주석}\n승인자가 다음 주석을 포함시켰습니다.\n\n"{comments}"\n\n{/if}\n다음 링크에서 앱 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}님,\n\n{consumerOrg} 개발자 포털에서 앱 {appName} {action}에 대한\n 요청이 승인되었습니다.\n\n{#if comments}\n승인자가 다음 주석을 포함했습니다.\n\n{comments}\n\n{/if}\n다음 링크에서 앱의 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{consumerOrg} 개발자 포털에서 앱 {appName} {action}에 대한\n 요청이 거부되었습니다.\n\n{#if 주석}\n승인자가 다음 주석을 포함시켰습니다.\n\n"{comments}"\n\n{/if}\n다음 링크에서 앱 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{consumerOrg} 개발자 포털에서 앱 {appName} {action}에 대한\n 요청이 거부되었습니다.\n\n{#if comments}\n승인자가 다음 주석을 포함했습니다.\n\n{comments}\n\n{/if}\n다음 링크에서 앱의 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `{firstName}님을 환영합니다.\n\n{consumerOrg} 개발자 포털에서 앱 {appName} {action}에 대한\n 요청이 승인 대기 중입니다. 요청이 처리되면\n다른 알림이 전송됩니다.\n\n언제든지 요청을 취소하려면 다음 링크를 사용하십시오.\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\nIBM API Connect의 계정 관리자가 \nAPI 제공자 조직을 작성하도록 초대했습니다. 제공자 조직을 통해 사용자 및 사용자 팀에서 \nAPI를 개발하고 API 제품의 카탈로그를 관리할 수 있습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\nIBM API Connect에서 {org} API 제공자 조직에 속하는 \n{catalog} API 카탈로그의 관리자가 카탈로그에 공간을 작성하도록 \n초대했습니다. 공간을 통해 API 카탈로그에서 사용자와 사용자 팀에서 \nAPI 제품 세트를 관리할 수 있습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\nIBM API Connect의 {org} API 제공자 조직에 속하는 \n{catalog} API 카탈로그의 관리자가 카탈로그로 \n사용자를 초대했습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\n{catalog} 개발자 포털의 관리자가 API 이용자 조직을\n 작성하도록 초대했습니다. 이용자 조직은 \n사용자 및 사용자 팀에서 API에 액세스하고 클라이언트 앱을 등록할 수 있도록 합니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\n{catalog} 개발자 포탈의 {consumerOrg} API 이용자 조직 관리자가 \n조직으로 초대했습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\nIBM API Connect의 {org} API 제공자 조직 관리자가 \nAPI 카탈로그를 작성하도록 초대했습니다. 카탈로그를 통해 \n사용자 및 사용자에서 API 제품의 콜렉션을 공개하고 관리할 수 있습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\nIBM API Connect의 {org} API 제공자 조직 관리자가 \n조직으로 초대했습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\nIBM API Connect의 {org} API 제공자 조직에 속하는 \n{catalog} API 카탈로그의 {space} 공간 관리자가 공간으로 \n사용자를 초대했습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `환영합니다.\n\nIBM API Connect에서 사용자 계정 {username}의 비밀번호가 \n정상적으로 변경되었습니다.\n\n다음 링크에서 API Connect에 로그인할 수 있습니다. \n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `환영합니다.\n\n{portalTitle} 개발자 포털에서 사용자 계정 {username}의 비밀번호가\n 변경되었습니다.\n\n다음 링크에서 {portalTitle} 개발자 포털에 로그인할 수 있습니다.\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `환영합니다.\n\nIBM API Connect의 시스템 관리자가 \n{org} 조직으로 초대했습니다.\n\n계속하려면 다음 링크를 사용하십시오. \n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `환영합니다.\n\n이는 구성된 메일 서버 {mailServer}에서 발송된 IBM API Connect의\n 테스트 메시지입니다.\n\n예상대로 이 메시지를 수신한 경우 이 테스트는 성공입니다!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그에서 {productName} API 제품의 \n{productVersion} 버전에 대한 {planName} 플랜에 등록하라는 요청을 철회했습니다.\n\n추가 조치가 필요하지 않습니다.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그의 {appName} 앱 {action}에 대한 요청을 철회했습니다.\n\n추가 조치가 필요하지 않습니다.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그에서 {productName} API 제품의 \n{version} 버전 {action}에 대한 요청을 철회했습니다.\n\n추가 조치가 필요하지 않습니다.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `환영합니다. 이는 구성된 메일 서버 {{{mailServer}}}에서 발송된 IBM API Connect의 테스트 메시지입니다. 예상대로 이 메시지를 수신한 경우 테스트는 성공입니다.`,
  [`Hello|header`]: `환영합니다!`,
  [`Help`]: `도움말`,
  [`Hi, Steve (todo)`]: `안녕하세요, 스티브님(수행할 작업)`,
  [`Hide`]: `숨기기`,
  [`Hide field`]: `필드 숨기기`,
  [`Hide in schema`]: `스키마에 숨기기`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `새 유형, 필드, 지시문 또는 선택적 인수를 숨깁니다. 백엔드에서 더 이상 지원하지 않는 항목이 제거됩니다.`,
  [`High`]: `높음`,
  [`History`]: `히스토리`,
  [`Histories`]: `히스토리`,
  [`History log`]: `히스토리 로그`,
  [`Home`]: `홈`,
  [`Host`]: `호스트`,
  [`Host context variable`]: `호스트 컨텍스트 변수`,
  [`Host name`]: `호스트 이름`,
  [`Host value (if present)`]: `호스트 값(있는 경우)`,
  [`Hostname`]: `호스트 이름`,
  [`Hours`]: `시간`,
  [`Http basic authentication schema`]: `Http 기본 인증 스키마`,
  [`Hmm, your search didn't return any results.`]: `검색에서 아무 결과도 리턴되지 않았습니다.`,
  [`HTTP(s)`]: `HTTP(S)`,
  [`IBM API Connect cloud`]: `IBM API Connect 클라우드`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud 인증`,
  [`IBM Cloud Docs`]: `IBM Cloud Docs`,
  [`IBM Schema`]: `IBM Schema`,
  [`IBM managed`]: `IBM 관리`,
  [`ID Name`]: `ID 이름`,
  [`ID token custom claims`]: `ID 토큰 사용자 정의 청구`,
  [`ID token issuer`]: `ID 토큰 발행자`,
  [`ID token signing algorithm`]: `ID 토큰 서명 알고리즘`,
  [`ID token signing crypto object`]: `ID 토큰 서명 암호화 오브젝트`,
  [`ID token signing key`]: `ID 토큰 서명 키`,
  [`ID token signing key identifier`]: `ID 토큰 서명 키 ID`,
  [`IDENTITY PROVIDER`]: `ID 제공자`,
  [`IDENTITY PROVIDER SUPPORT`]: `ID 제공자 지원`,
  [`Identification`]: `ID`,
  [`Identity extraction`]: `ID 추출`,
  [`If`]: `가정`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `프로덕션 모드가 사용 안함으로 설정되는 경우(이는 개발 카탈로그임을 의미함) 모든 공개 오퍼레이션이 강제 실행되고 충돌이 자동으로 해결됩니다(예를 들어, 테스트 중에 동일한 제품 버전을 반복하여 재공개하도록 허용).`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `프로덕션 모드가 사용으로 설정되는 경우(이는 프로덕션 카탈로그임을 의미함) 이미 공개된 제품과의 충돌이 있으면 카탈로그에 제품을 공개하지 못하게 되며 공개 오퍼레이션을 재시도하기 전에 충돌을 해결해야 합니다.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `카탈로그에서 공간을 사용할 수 있는 경우 공간 내에서 멤버십을 관리할 수 있습니다. 공간에 새 사용자를 추가하고 추가된 사용자에게 역할을 지정하여 공간 멤버십을 관리합니다.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `사용으로 설정하면 널값을 갖는 입력 특성이 출력 문서에 맵핑됩니다. 그렇지 않으면 해당 특성이 출력에서 생략됩니다.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `프로덕션 모드가 사용으로 설정된 경우 스테이징 및 공개 조치가 강제 실행되지 않습니다. 충돌이 발생하는 경우 시스템에서 자동으로 해결됩니다. 공개 취소 조치가 자동으로 수행됩니다.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `수신인이 이메일을 받지 못한 경우 이메일 서버 설정을 확인하십시오.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `수신인이 이메일을 수신하지 않은 경우에는 {username}의 이메일 서버 설정을 확인하십시오.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `true 값으로 설정한 경우 호출 및 프록시 정책의 대상 URL에 대한 조회 매개변수 값에 있는 모든 + 문자는 %2B로 인코딩됩니다.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `true 값으로 설정한 경우 호출 대상 URL의 변수 정의로 참조되는 요청 매개변수는 URL로 디코딩됩니다.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `true 값으로 설정한 경우 호출 정책은 HTTP DELETE 메소드의 페이로드를 전송합니다.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `true로 설정하면 활동 로그에서 특성이 수정되거나 제거됩니다.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `true로 설정하면 실행 초기에 입력에서 특성이 수정되거나 제거됩니다.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `true로 설정하면 응답에서 특성이 수정되거나 제거됩니다.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `카탈로그에서 공간을 사용할 수 있는 경우 공간 내에서 사용자가 갖는 액세스 권한을 관리할 수 있습니다. 사용자 역할에 지정되는 권한을 지정하여 액세스를 관리합니다. 기본적으로 새 공간을 작성할 때 각 역할에 지정되는 권한은 기본 공간 권한 템플리트의 설정에 의해 결정됩니다.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `이 선택란을 선택하면(기본 옵션) 상위 요소에서 XML 네임스페이스가 상속됩니다. 맵 정책에서 명시적인 네임스페이스를 사용하도록 하려면 이 선택란을 선택 취소하십시오.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `이 선택란을 선택하면(기본 옵션) XML 네임스페이스를 처음 사용된 문서에 삽입합니다. 루트 요소에서 모든 네임스페이스를 정의하려면 선택란을 선택 취소하십시오.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `이 선택란을 선택하면(기본 옵션) 비어 있는 XML 요소가 맵 정책의 출력에 포함됩니다. 비어 있는 XML 요소를 맵 정책의 출력에 포함시키지 않으려면 이 선택란을 선택 취소하십시오.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `이 선택란이 선택된 경우 맵핑된 JSON 출력의 사후 처리가 사용으로 설정됩니다. JSON 출력의 사후 처리가 출력 스키마를 사용하여 특성 값이 스키마에 정의된 것과 동일한 데이터 유형인지 확인합니다. 또한 XML 입력의 오브젝트 맵핑으로 인해 Badgerfish JSON 구문이 있는 출력 특성 값을 정규화합니다. 맵핑된 JSON 출력의 사후 처리가 없는 경우 이 선택란을 선택 취소하십시오(기본값).`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `선택란이 선택된 경우(기본 선택사항) 맵 특성에서 찾은 API Connect 변수 참조가 결정됩니다. 맵 정책에서 맵 특성에서 찾은 API Connect 변수 참조를 무시하려면 이 선택란을 선택 취소하십시오.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `선택란이 선택된 경우 맵 특성에서 찾은 API Connect 변수 참조가 결정됩니다. 맵 정책에서 맵 특성에서 찾은 API Connect 변수 참조를 무시하려면 이 선택란(기본 옵션)을 선택 취소하십시오.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `선택란이 선택된 경우(기본 선택사항) 입력 순회에서 배열을 만나면 첫 번째 배열 요소만 사용합니다. 맵 정책에서 배열 요소를 모두 사용하도록 하려면 이 선택란을 선택 취소하십시오.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `이 선택란을 선택하면 스키마가 부울 또는 숫자 유형으로 구성된 XML 요소가 해당 데이터 유형으로 변환됩니다. 이를 선택하지 않으면(기본 옵션) 모든 XML 요소 값이 문자열로 리턴됩니다.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `이 선택란을 선택하면 최적화된 방식에서 복합 스키마 유형 평가가 순환 유형 참조를 핸들합니다. 표준 방식에서 이 스키마 유형을 평가하려면 이 선택란을 선택 취소하십시오(기본 옵션).`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `CORS 지원이 부족하여 오류가 발생한 경우 링크를 클릭하여 새 탭에서 서버를 여십시오. 브라우저가 인증서 발행을 표시하면 사용자는 이를 승인하도록 선택하고 여기로 돌아와 다시 테스트할 수 있습니다.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `이 선택란을 선택하면 스키마에 필수로 기록된 선택적 오브젝트의 출력 배열 또는 하위 특성에는 성공적으로 맵핑되지 않은 경우 기본값으로 초기화되는 필수 하위 오브젝트 특성이 있습니다. 해당 오브젝트의 필수 특성에 대해 버전 4 맵핑이 수행된 방식을 에뮬레이트합니다. 성공적으로 맵핑된 데이터가 있는 오브젝트만 출력하려면 이 선택란(기본값)을 지우십시오.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `이 선택란을 선택하고(기본값) 소유자 사용자가 변경된 경우 하위 자원(Space)의 소유권도 새 소유자에게 이전됩니다.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `이 옵션이 선택되는 경우 맵 정책은 구문 분석된 XML 문서로 message.body에 XML 출력을 작성합니다. 기본적으로, XML은 XML 문자열로 출력됩니다. 다른 변수에 대한 XML 출력은 항상 XML 문자열로 작성됩니다.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `스테이징, 공개 또는 바꾸기 조치에 대한 라이프사이클 승인을 사용으로 설정하면 이러한 조치를 자동으로 수행하는 일부 API 작성, 편집 또는 테스트 오퍼레이션이 올바르게 작동하지 않을 수 있습니다. 이러한 승인을 사용으로 설정하시겠습니까?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `API Connect API 정의를 통해 공개하려는 기존 SOAP 서비스가 있는 경우, API를 작성하고 대상 SOAP 서비스를 지정할 수 있습니다. 그런 다음 API Connect가 SOAP 서비스를 호출하는 API 정의를 작성합니다.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `"예, API 어셈블리 업데이트"를 클릭하여 변경사항을 저장하는 경우 이 OAuth 제공자의 기반이 되는 API 어셈블리도 업데이트됩니다. 그러나 기반 API 어셈블리를 사용자 정의한 후 이를 별도로 업데이트하려는 경우 "아니오. API 어셈블리 업데이트 안함"을 클릭한 후 API 편집기에서 기반 API 어셈블리를 업데이트하십시오.`,
  [`If you are still having issues, upload a schema definition language file.`]: `계속해서 문제가 발생하면 스키마 정의 언어 파일을 업로드하십시오.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `파일에 개인 키 및 공개 키가 둘 다 포함되는 경우 1단계에서 파일을 업로드하십시오.`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `방화벽 뒤에 있는 게이트웨이를 등록해야 하는 경우 VPN 또는 보안 게이트웨이 서비스를 사용하여 연결을 설정하는 방법에 대한 정보는 IBM Cloud 문서를 참조하십시오.`,
  [`Ignore`]: `무시`,
  [`Implement policy`]: `정책 구현`,
  [`Implementation file is required`]: `구현 파일은 피수임`,
  [`Implicit`]: `암시`,
  [`Import`]: `가져오기`,
  [`Import API`]: `API 가져오기`,
  [`Import API|title`]: `API 가져오기`,
  [`Import OpenAPI definition from asset repository`]: `자산 저장소에서 OpenAPI 정의 가져오기`,
  [`Import Product`]: `제품 가져오기`,
  [`Import policy`]: `정책 가져오기`,
  [`Import a product from a service URL`]: `서비스 URL에서 제품 가져오기`,
  [`Import a product from the definition file`]: `정의 파일에서 제품 가져오기`,
  [`Import an existing product`]: `기존 제품 가져오기`,
  [`Import an OpenAPI definition from a URL`]: `URL에서 OpenAPI 정의 가져오기`,
  [`Import an OpenAPI definition from a file`]: `파일에서 OpenAPI 정의 가져오기`,
  [`Import definitions`]: `정의 가져오기`,
  [`Import from existing API service`]: `기존 API 서비스에서 가져오기`,
  [`Import from file`]: `파일에서 가져오기`,
  [`Importing an API definition`]: `API 정의 가져오기`,
  [`Import|title`]: `가져오기`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `필요한 인증서 및 CA 번들을 인증서 관리자 서비스로 가져오십시오.`,
  [`In Cookie Header`]: `쿠키 헤더에서`,
  [`In WSSec Header`]: `WSSec 헤더에서`,
  [`In order to invoke the endpoints above, you need to first`]: `위의 엔드포인트를 호출하려면 먼저 다음을 수행해야 함`,
  [`Inactive`]: `비활성`,
  [`Inc`]: `증가`,
  [`Include empty`]: `비어 있음 포함`,
  [`Include free trial days`]: `무료 체험 일수 포함`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `구성된 게이트웨이 서비스와 호환되지 않는 API를 선택할 수 없습니다.`,
  [`Incorrect username or password`]: `올바르지 않은 사용자 이름 또는 비밀번호`,
  [`Incorrect username, password, or credentials`]: `올바르지 않은 사용자 이름, 비밀번호 또는 신임 정보`,
  [`Indicate if the invoke is the final action`]: `호출이 최종 조치인지 표시`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `JTI(JWT ID) 청구를 JWT에 추가할지 여부를 표시합니다. 선택한 경우 jti 청구 값은 UUID입니다.`,
  [`Indicates whether the clients secret is required.`]: `클라이언트 시크릿의 필수 여부를 나타냅니다.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `이 XSLT 입력 문서가 컨텍스트의 현재 페이로드를 사용하는지 여부 또는 입력이 없는지 여부를 표시합니다.`,
  [`Indicates whether to stop processing if client security fails.`]: `클라이언트 보안에 실패하면 처리를 중지할지 나타냅니다.`,
  [`Infer from JSON schema`]: `JSON 스키마에서 추론`,
  [`Infer from Sample`]: `샘플에서 추론`,
  [`Info`]: `정보`,
  [`Information context variable`]: `정보 컨텍스트 변수`,
  [`Inject proxy headers`]: `프록시 헤더 삽입`,
  [`Inline schema`]: `인라인 스키마`,
  [`Input`]: `입력`,
  [`Input field`]: `입력 필드`,
  [`Input object`]: `입력 오브젝트`,
  [`Insecure Server Connections`]: `안전하지 않은 서버 연결`,
  [`Install API Connect CLI & API Designer|button text`]: `API Connect CLI 및 API Designer 설치`,
  [`Install API Connect CLI & API Designer|title`]: `API Connect CLI 및 API Designer 설치`,
  [`Install API Connect Toolkit`]: `API Connect 툴킷 설치`,
  [`Install the API Connect plugin`]: `API Connect 플러그인 설치`,
  [`Instance ID`]: `인스턴스 ID`,
  [`Instance name`]: `인스턴스 이름`,
  [`Instance owner`]: `인스턴스 소유자`,
  [`Interact to expand Tile`]: `타일을 펼치도록 상호작용`,
  [`Interact to collapse Tile`]: `타일을 접도록 상호작용`,
  [`Interval`]: `간격`,
  [`Interface`]: `인터페이스`,
  [`Introspect`]: `자체 검사`,
  [`Introspect cache type`]: `내성(Introspect) 캐시 유형`,
  [`Introspect from Server URL`]: `서버 URL에서 검사`,
  [`Introspect from URL`]: `URL에서 검사`,
  [`Introspect URL`]: `자체 검사 URL`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `다른 URL을 사용하여 검사를 수행하면 GraphQL 서버 URL이 변경됩니다.`,
  [`Introspect token`]: `내성 토큰`,
  [`Introspection`]: `자체 검사`,
  [`Introspection Path`]: `자체 검사 경로`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `서버에서 GraphQL 스키마 검사 또는 스키마 업로드`,
  [`Introspect the GraphQL schema using the query`]: `조회를 사용하여 GraphQL 스키마 검사`,
  [`Introspection URL`]: `자체 검사 URL`,
  [`Invalid HOST URL`]: `올바르지 않은 HOST URL`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `올바르지 않은 URL입니다. 올바른 API Manager HOST URL을 입력하십시오. 예: https://api-connect-host.com`,
  [`Invalid file URL`]: `올바르지 않은 파일 URL`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `URL 형식이 올바르지 않음, http:// 또는 https://로 시작해야 하며 문자를 1자 이상 포함해야 함`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `올바르지 않은 URL 형식입니다. 호스트 이름만 지정해야 합니다. 즉 syslog: //를 제거할 수 있습니다.`,
  [`Invalid emails.`]: `올바르지 않은 이메일입니다.`,
  [`Invalid JSONata expression`]: `올바르지 않은 JSONata 표현식`,
  [`Invalid remove target`]: `올바르지 않은 제거 대상`,
  [`Invalid assumed size`]: `올바르지 않은 추정 크기`,
  [`Invalid cost target`]: `올바르지 않은 비용 부과 대상`,
  [`Invalid list size`]: `올바르지 않은 목록 크기`,
  [`Invalid port value, must not be negative`]: `올바르지 않은 포트 값, 음수가 아니어야 함`,
  [`Invalid sized fields`]: `올바르지 않은 크기 조정 필드`,
  [`Invalid value, length has to be greater than 1`]: `올바르지 않은 값, 길이는 1보다 커야 함`,
  [`Invalid weight value`]: `올바르지 않은 가중치 값`,
  [`invitation`]: `초대`,
  [`Invitation`]: `초대`,
  [`Invitation timeout`]: `초대 제한시간`,
  [`Invitation link will expire in {time}`]: `초대 링크가 **{time}**후에 만료됨`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `초대 링크가 **{seconds}초({time})** 후에 만료됨`,
  [`Invitation link will expire in __{value} {unit}__.`]: `초대 링크가 __{value}{unit}__ 후에 만료됩니다.`,
  [`Invitation sent to`]: `초대 받는 사람`,
  [`Invitation sent to {email}`]: `{email}에 초대 발송`,
  [`Invitation to an API catalog in IBM API Connect`]: `IBM API Connect의 API 카탈로그로의 초대`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `{catalog} 개발자 포털의 API 이용자 조직에 초대`,
  [`Invitation to an API provider organization in IBM API Connect`]: `IBM API Connect의 API 제공자 조직에 초대`,
  [`Invitation to an API space in IBM API Connect`]: `IBM API Connect의 API 공간으로의 초대`,
  [`Invitation to an admin organization in IBM API Connect`]: `IBM API Connect의 관리 조직에 초대`,
  [`Invitation to create an API catalog in IBM API Connect`]: `IBM API Connect의 API 카탈로그 작성 초대`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `{catalog} 개발자 포털의 API 이용자 조직 작성 초대`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `IBM API Connect의 API 제공자 조직 작성 초대`,
  [`Invitation to create an API space in IBM API Connect`]: `IBM API Connect의 API 공간 작성 초대`,
  [`Invitation ttl has been created.`]: `초대 ttl이 작성되었습니다.`,
  [`Invitation ttl has been updated.`]: `초대 ttl이 업데이트되었습니다.`,
  [`Invitation ttl has not been created!`]: `초대 ttl이 작성되지 않았습니다!`,
  [`Invitation ttl has not been updated!`]: `초대 ttl이 업데이트되지 않았습니다!`,
  [`Invite`]: `초대`,
  [`Invite catalog owner`]: `카탈로그 소유자 초대`,
  [`Invite consumer organization owner`]: `이용자 조직 소유자 초대`,
  [`Invite member`]: `구성원 초대`,
  [`Invite organization owner`]: `조직 소유자 초대`,
  [`Invite space owner`]: `공간 소유자 초대`,
  [`Invite a new consumer organization owner`]: `새 이용자 조직 소유자 초대`,
  [`Invite a new member`]: `새 구성원 초대`,
  [`Invite a new provider organization owner`]: `새 제공자 조직 소유자 초대`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `수신인 이메일 주소를 입력하여 카탈로그의 구성원으로 등록할 새 사용자를 초대하십시오. 등록 양식으로 연결되는 링크와 함께 사용자에게 초대 이메일이 발송됩니다.`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `수신인 이메일 주소를 입력하여 공간의 구성원으로 등록할 새 사용자를 초대하십시오. 등록 양식으로 연결되는 링크와 함께 사용자에게 초대 이메일이 발송됩니다.`,
  [`Invite by email`]: `이메일로 초대`,
  [`Invite from User Registry`]: `사용자 레지스트리에서 초대`,
  [`Invite team member`]: `팀 구성원 초대`,
  [`Inviting members to consumer organizations is enabled`]: `이용자 조직에 구성원을 초대할 수 있음`,
  [`Invocations`]: `호출`,
  [`Invoke`]: `호출`,
  [`Issuer Claim`]: `발행자 청구`,
  [`Issuer Origin`]: `발행자 기점`,
  [`Issuer common name`]: `발행자 공통 이름`,
  [`It might influence how the API is deployed`]: `API가 배치되는 방식에 영향을 미칠 수 있음`,
  [`Italian`]: `이탈리아어`,
  [`Items per page`]: `페이지당 항목 수`,
  [`Items per page:`]: `페이지당 항목 수:`,
  [`items selected`]: `개 항목이 선택됨`,
  [`item selected`]: `개 항목이 선택됨`,
  [`Iterate on`]: `반복 위치`,
  [`Iterate?`]: `반복 여부`,
  [`JSON objects describing re-usable channel parameters.`]: `재사용 가능한 채널 매개변수를 설명하는 JSON 오브젝트입니다.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `API에서 이용하고 생성하는 메시지를 설명하는 JSON 오브젝트입니다.`,
  [`JSON Schema URL`]: `JSON 스키마 URL`,
  [`JSON Schema preview`]: `JSON 스키마 미리보기`,
  [`JSON Web Token (JWT)`]: `JWT(JSON Web Token)`,
  [`JSON has been successfully validated`]: `JSON이 유효성 검증됨`,
  [`JSON schema is not valid. Please correct the error.`]: `JSON 스키마가 올바르지 않습니다. 오류를 정정하십시오.`,
  [`JSON to XML`]: `JSON 대 XML`,
  [`JWT ID Claim`]: `JWT ID 청구`,
  [`JWT verification JWK`]: `JWT 검증 JWK`,
  [`JWT verification crypto object`]: `JWT 검증 암호화 오브젝트`,
  [`Japanese`]: `일본어`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `작업 큐`,
  [`Jump to source`]: `소스로 건너뛰기`,
  [`Just now`]: `지금`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `키 교환 / 암호화 / MAC`,
  [`KEY SIZE`]: `키 크기`,
  [`Keystore`]: `키 저장소`,
  [`keystore`]: `keystore`,
  [`Keep`]: `보관`,
  [`Keep Payload`]: `페이로드 유지`,
  [`Key Encryption Algorithm`]: `키 암호화 알고리즘`,
  [`Key Name`]: `키 이름`,
  [`Key type`]: `키 유형`,
  [`Key name`]: `키 이름`,
  [`Keystore for the token has been updated.`]: `토큰의 키 저장소가 업데이트되었습니다.`,
  [`Keystore Certificates`]: `키 저장소 인증서`,
  [`Keystore/truststore`]: `키 저장소/신뢰 저장소`,
  [`Korean`]: `한국어`,
  [`LABEL`]: `레이블`,
  [`LABELS`]: `레이블`,
  [`LAST MODIFIED`]: `마지막 수정 날짜`,
  [`Last state changed`]: `마지막 상태 변경 날짜`,
  [`Large`]: `대형`,
  [`Lax`]: `느슨함`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAP 그룹`,
  [`LDAP user registry`]: `LDAP 사용자 레지스트리`,
  [`LDAP registry`]: `LDAP 레지스트리`,
  [`LDAP registry name`]: `LDAP 레지스트리 이름`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `역할의 LDAP 사용자 레지스트리 {{name}}이(가) 더 이상 외부 그룹 맵핑을 지원하지 않습니다. 이 사용자 레지스트리에 대한 외부 그룹 맵핑을 사용으로 설정하거나 저장하기 전에 역할에서 외부 그룹 맵핑을 사용 안함으로 설정하십시오.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `역할의 LDAP 사용자 레지스트리 {{name}}이(가) 더 이상 외부 그룹 맵핑을 지원하지 않습니다. 외부 그룹 맵핑을 사용하는 다른 사용자 레지스트리를 선택하거나 저장하기 전에 역할에서 외부 그룹 맵핑을 사용 안함으로 설정하십시오.`,
  [`LDAP search attribute`]: `LDAP 검색 속성`,
  [`LOCATED IN`]: `위치`,
  [`Located in`]: `위치`,
  [`LTPA Key`]: `LTPA 키`,
  [`LTPA Key Details`]: `LTPA 키 세부사항`,
  [`LTPA Keys`]: `LTPA 키`,
  [`LTPA key file`]: `LTPA 키 파일`,
  [`LTPA key password`]: `LTPA 키 비밀번호`,
  [`LUR data version`]: `LUR 데이터 버전`,
  [`LUR schema update date`]: `LUR 스키마 업데이트 날짜`,
  [`LUR schema version`]: `LUR 스키마 버전`,
  [`LUR target data version`]: `LUR 대상 데이터 버전`,
  [`LUR target schema version`]: `LUR 대상 스키마 버전`,
  [`LUR update date`]: `LUR 업데이트 날짜`,
  [`Last name`]: `성`,
  [`Last modified`]: `마지막 수정 날짜`,
  [`Launch portal`]: `포털 실행`,
  [`Launch IBM Automation management console`]: `IBM Automation 관리 콘솔 실행`,
  [`Launch plan upgrade`]: `플랜 업그레이드 실행`,
  [`Learn more`]: `추가 학습`,
  [`Learn More`]: `자세히 보기`,
  [`Legacy`]: `레거시`,
  [`Length cannot be below {min}`]: `길이는 {min} 미만일 수 없음`,
  [`Length cannot exceed {max}`]: `길이는 {max}을(를) 초과할 수 없음`,
  [`Let's customize API Connect`]: `API Connect 사용자 정의`,
  [`Let's get started...`]: `시작하기...`,
  [`Let's get you up and running`]: `구동하여 실행하기`,
  [`License`]: `라이센스`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `라이프사이클`,
  [`Lifecycle approvals`]: `라이프사이클 승인`,
  [`Lifecycle actions have been updated`]: `라이프사이클 조치가 업데이트됨`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `라이프사이클 승인이 샌드박스 카탈로그에 대해 사용으로 설정되어 있습니다. 자동 API 활성화 옵션을 사용하려면 먼저 샌드박스 카탈로그 설정에서 라이프사이클 승인을 사용 안함으로 설정해야 합니다.`,
  [`Limited fields`]: `한계 필드`,
  [`Limit API calls on a per key basis`]: `키 단위로 API 호출 제한`,
  [`Limit fields`]: `한계 필드`,
  [`Limit renegotiation`]: `한계 재협상`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `로드`,
  [`Load query`]: `쿼리 로드`,
  [`Load filter`]: `필터 로드`,
  [`Load schema from WSDL`]: `WSDL에서 스키마 로드`,
  [`Loading`]: `로드 중`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `로컬 사용자 레지스트리(LUR)는 API Connect에 포함되고 로컬로 저장된 기본 사용자 레지스트리입니다.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `로컬 사용자 레지스트리는 API Connect에 포함되고 로컬로 저장된 기본 사용자 레지스트리입니다. 2개의 LUR이 설치되고 API Connect 설치 중 구성됩니다. 추가 LUR이 구성될 수 있습니다.`,
  [`Local user registry`]: `로컬 사용자 레지스트리`,
  [`Local User Registry has been created.`]: `로컬 사용자 레지스트리가 작성되었습니다.`,
  [`Location`]: `위치`,
  [`Location of ciphertext to decrypt`]: `복호화할 암호문의 위치입니다.`,
  [`Location of plaintext information to encrypt`]: `암호화할 일반 텍스트의 위치입니다.`,
  [`Log`]: `로그`,
  [`Log in`]: `로그인`,
  [`Log in to:`]: `로그인 대상:`,
  [`Log in with a different account`]: `다른 계정으로 로그인`,
  [`Log into IBM Cloud`]: `IBM Cloud에 로그인`,
  [`Log out`]: `로그아웃`,
  [`Log in using the {userRegistryType} user registry`]: `{userRegistryType} 사용자 레지스트리를 사용하여 로그인`,
  [`Logging in with IBM Cloud ...`]: `IBM Cloud에 로그인 중...`,
  [`Logging out`]: `로그아웃`,
  [`Logic`]: `로직`,
  [`Login`]: `로그인`,
  [`Logs`]: `로그`,
  [`Longer, descriptive name for this policy.`]: `이 정책에 대한 길고 설명이 포함된 이름입니다.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `요청을 처리하는 중에 서버에서 오류가 발생한 것으로 보입니다.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `메일 서버`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `구성원`,
  [`MIME Types`]: `MIME 유형`,
  [`Mail server has been deleted.`]: `메일 서버가 삭제되었습니다.`,
  [`Mail server has not been deleted.`]: `메일 서버가 삭제되지 않았습니다.`,
  [`Mailserver {arg} has been removed.`]: `메일 서버 {arg}이(가) 제거되었습니다.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `서버가 실행 중이며 네트워크가 연결되었는지 확인하십시오.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `대상 서버의 CORS 지원 누락, 서버 사용 불가능, 신뢰할 수 없는 인증서 발견 또는 상호 SSL 인증 필수 등의 원인으로 인해 문제가 발생했을 수 있습니다.`,
  [`This might be caused by one or more of the following:`]: `이는 다음 중 하나 이상으로 인해 발생할 수 있습니다.`,
  [`Make a call and move on`]: `호출 작성 후 계속`,
  [`Make a call and wait for response`]: `호출 작성 후 응답 대기`,
  [`Manage`]: `관리`,
  [`Manage API life cycles`]: `API 라이프사이클 관리`,
  [`Manage API product lifecycle approval permissions`]: `API 제품 라이프사이클 승인 권한 관리`,
  [`Manage API product lifecycle approvals permissions`]: `API 제품 라이프사이클 승인 권한 관리`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `API 제공자 조직, 로컬 및 원격 게이트웨이, 관련 설정을 관리합니다.`,
  [`Manage APIs`]: `API 관리`,
  [`Manage Catalogs`]: `카탈로그 관리`,
  [`Manage Organizations`]: `조직 관리`,
  [`Manage Resources`]: `자원 관리`,
  [`Manage Settings`]: `설정 관리`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `API Connect, HTTP, Kafka, Elasticsearch 및 Syslog를 포함한 API 분석 대상을 관리합니다.`,
  [`Manage local and remote gateways that secure your APIs.`]: `API를 보호하는 로컬 및 원격 게이트웨이를 관리합니다.`,
  [`Manage catalogs`]: `카탈로그 관리`,
  [`Manage organizations`]: `조직 관리`,
  [`Manage resources`]: `자원 관리`,
  [`Manage settings`]: `설정 관리`,
  [`Manage users`]: `사용자 관리`,
  [`Manage active APIs and consumers`]: `활성 API 및 이용자 관리`,
  [`Manage availability zones and services`]: `가용성 구역 및 서비스 관리`,
  [`Manage policies`]: `정책 관리`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `애플리케이션 개발자의 제품 라이프사이클 변경 요청 및 API 등록 및 애플리케이션 업그레이드 요청을 관리합니다.`,
  [`Manage roles and permissions`]: `역할 및 권한 관리`,
  [`Manage the OAuth providers configured for API Manager`]: `API Manager에 대해 구성된 OAuth 제공자를 관리합니다.`,
  [`Manage the user registries configured for API Manager`]: `API Manager에 대해 구성된 사용자 레지스트리를 관리합니다.`,
  [`Manage the user registries configured for Cloud Manager`]: `Cloud Manager에 대해 구성된 사용자 레지스트리를 관리합니다.`,
  [`Management`]: `관리`,
  [`Management Endpoint`]: `관리 엔드포인트`,
  [`Management endpoint`]: `관리 엔드포인트`,
  [`Management endpoint on the analytics service`]: `분석 서비스의 관리 엔드포인트`,
  [`Service endpoint configuration`]: `서비스 엔드포인트 구성`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `포털 서비스에 구성 정보를 보내기 위해 관리 서비스에서 사용하는 엔드포인트`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `포털 서비스와 통신할 때 관리 서비스에서 사용하는 TLS 클라이언트 프로파일`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `게이트웨이 서비스에 구성 정보를 보내기 위해 관리 서비스에서 사용하는 엔드포인트`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `게이트웨이 서비스와 통신할 때 관리 서비스에서 사용하는 TLS 클라이언트 프로파일`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `게이트웨이와 관리 서비스 사이의 내부 트래픽에 인클러스터 통신 사용`,
  [`Use in-cluster communication for both ingestion and queries`]: `수집 및 쿼리 모두에 클러스터 내 통신 사용`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `수집에는 클러스터 내를 사용하고 쿼리에는 외부를 사용하거나 그 반대로도 사용 가능`,
  [`Use external communication for both ingestion and queries`]: `수집 및 쿼리 모두에 외부 통신 사용`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `분석과 관리 사비스 사이의 내부 트래픽에 인클러스터 통신 사용`,
  [`Management endpoint on the gateway service`]: `게이트웨이 서비스의 관리 엔드포인트`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `포털과 관리 서비스 간의 내부 트래픽에 클러스터 내 통신 사용`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `관리 및 포털 서브시스템 간에 인클러스터 통신을 사용하려면 이 스위치를 활성화하십시오. 잘 모르겠으면, 이 스위치를 비활성화 상태(기본값)로 남겨 두십시오.`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `관리 및 게이트웨이 서브시스템 간에 인클러스터 통신을 사용하려면 이 스위치를 활성화하십시오. 잘 모르겠으면, 이 스위치를 비활성화 상태(기본값)로 남겨 두십시오.`,
  [`Management endpoint on the portal service`]: `포털 서비스의 관리 엔드포인트`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `이 옵션은 기본값이며 pre-10.0.5.3 릴리스에서 업그레이드된 분석 서비스를 위해 사용됩니다. OVA 배포의 경우 또는 분석 서브시스템이 다른 환경에 있는 경우, 외부가 사용 가능한 유일한 옵션입니다. 게이트웨이와 관리 서비스 모두 외부 엔드포인트를 사용하여 분석 서비스와 통신합니다. 외부 엔드포인트는 플랫폼에 따라 Kubernetes ingress 또는 OpenShift 경로입니다.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `모든 서브시스템이 동일한 클러스터 안에 있는 Kubernetes 또는 OpenShift 배포 환경이 있는 경우 인클러스터를 선택할 수 있습니다. 이 옵션을 선택할 경우, 관리, 게이트웨이, 분석 서브시스템은 외부적으로 액세스 가능한 ingress(Kubernetes) 또는 경로(OpenShift)가 아니라 내부 서비스 엔드포인트를 통해 서로 통신합니다.`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Kubernetes 또는 OpenShift 배포 환경이 있고 서브시스템 중 일부가 동일한 클러스터에 설치된 경우, 이 옵션을 선택할 수 있습니다. 이 옵션을 선택하면 다양한 분석 통신 흐름에 대해 다양한 통신 방법을 사용할 수 있습니다. 예를 들어, 관리-분석 통신은 인클러스터를 사용하고, 게이트웨이-분석은 외부를 사용할 수 있습니다. 게이트웨이가 나머지 서브시스템과 다른 클러스터에 있어도 이 구성을 선택할 수 있습니다.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `분석 서비스를 등록할 때 인클러스터 통신을 지정한 경우, 게이트웨이가 API 이벤트 데이터를 내부 분석 엔드포인트로 보내도록 할 수 있습니다.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `게이트웨이 서비스로부터 분석 데이터 수집을 위해 내부 엔드포인트 사용`,
  [`Endpoint configuration`]: `수집 및 쿼리 모두에 외부 통신 사용`,
  [`Portal endpoint configuration`]: `포털 엔드포인트 구성`,
  [`URL used by developer portal users to access the portal website`]: `개발자 포털 사용자가 포털 웹 사이트에 액세스하는 데 사용하는 URL`,
  [`External ingestion endpoint on the analytics service`]: `분석 서비스의 외부 수집 엔드포인트`,
  [`TLS client profile for external analytics ingestion endpoint`]: `외부 분석 수집 엔드포인트에 대한 TLS 클라이언트 프로파일`,
  [`Internal ingestion endpoint on the analytics service`]: `분석 서비스의 내부 수집 엔드포인트`,
  [`TLS client profile for internal service endpoint`]: `내부 서비스 엔드포인트에 대한 TLS 클라이언트 프로파일`,
  [`Manager`]: `관리자`,
  [`Manager Request Reset Password`]: `관리자 비밀번호 재설정 요청`,
  [`Manager Reset Password`]: `관리자 비밀번호 재설정`,
  [`Manager Sign In`]: `관리자 로그인`,
  [`Manages API provider organizations`]: `API 제공자 조직 관리`,
  [`Manages application developer communities`]: `애플리케이션 개발자 커뮤니티를 관리함`,
  [`Manages the API product lifecycle`]: `API 제품 라이프사이클을 관리함`,
  [`Manage|link`]: `관리`,
  [`Manage|permission`]: `관리`,
  [`Manage|title`]: `관리:제목`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `토큰의 키 저장소 지정과 키 저장소 히스토리 관리`,
  [`Map`]: `맵핑`,
  [`Map information from OIDC provider to an APIC user`]: `OIDC 제공자에서 APIC 사용자로 정보 맵핑`,
  [`Map the migration source plans to the migration target plans`]: `마이그레이션 소스 플랜에서 마이그레이션 대상 플랜으로 맵핑합니다.`,
  [`Map the roles to groups`]: `역할을 그룹에 맵핑`,
  [`Mapped from: `]: `맵핑 시작 위치: `,
  [`Marketing`]: `영업`,
  [`Maximize / minimize`]: `최대화 / 최소화`,
  [`Maximum Consent Time to Live (seconds)`]: `최대 승인 시간 수명(초)`,
  [`Maximum consent`]: `최대 승인`,
  [`Menu`]: `메뉴`,
  [`Member`]: `구성원`,
  [`Member Invitation`]: `구성원 초대`,
  [`Member Invitation has been deleted.`]: `구성원 초대가 삭제되었습니다.`,
  [`Member has been deleted.`]: `구성원이 삭제되었습니다.`,
  [`Member {name} created`]: `구성원 {name}이(가) 작성되었습니다.`,
  [`Member {title} has been created.`]: `구성원 {title}이(가) 작성되었습니다.`,
  [`Members`]: `구성원`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `구성원 초대`,
  [`Member Roles`]: `구성원 역할`,
  [`Merchant ID`]: `판매자 ID`,
  [`Merge`]: `병합`,
  [`Merge this mapping with any others that operate on the same target array?`]: `이 맵핑을 동일한 대상 배열에서 작동하는 다른 맵핑과 병합하시겠습니까?`,
  [`Message`]: `메시지`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `구문 분석 메시지`,
  [`Message for resulting parsed data`]: `구문 분석 결과 데이터 메시지`,
  [`Metadata`]: `메타데이터(Metadata)`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `메타데이터를 사용하면 액세스 토큰 생성 프로세스 동안 포함할 사용자 정의 메타데이터를 수집할 수 있습니다. 인증 URL 또는 사용자 정의 메타데이터가 저장된 원격 서버 또는 둘 다를 통해 메타데이터를 수집할 수 있습니다.`,
  [`Micro Gateway policies`]: `Micro Gateway 정책`,
  [`Migrate`]: `마이그레이션`,
  [`Migrate Subscriptions`]: `등록 마이그레이션`,
  [`Migrate plans`]: `플랜 마이그레이션`,
  [`Migrate subscription`]: `등록 마이그레이션`,
  [`Migrate subscriptions`]: `등록 마이그레이션`,
  [`Migration Target`]: `마이그레이션 대상`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `"{source}"(제품)에서 "{target}"(제품)(으)로의 마이그레이션이 실행됨`,
  [`Migration from "{source}" failed`]: `"{source}"에서 마이그레이션 실패`,
  [`Migration source`]: `마이그레이션 소스`,
  [`Migration target`]: `마이그레이션 대상`,
  [`Minimum role`]: `최소 역할`,
  [`Minimum output escaping rule`]: `최소 출력 이스케이프 규칙`,
  [`Minutes`]: `분`,
  [`Mode`]: `모드`,
  [`Modifications`]: `수정`,
  [`Modified`]: `수정됨`,
  [`More`]: `추가`,
  [`More than 20 events are waiting to be processed`]: `20개 이상의 이벤트가 처리 대기 중임`,
  [`Move down`]: `아래로 이동`,
  [`Move up`]: `위로 이동`,
  [`Must be a number`]: `숫자여야 함`,
  [`Must be a valid URI`]: `유효한 URI여야 함`,
  [`Must be a valid email`]: `유효한 이메일이어야 함`,
  [`Must be a valid path starting with /`]: `/로 시작하는 유효한 경로여야 함`,
  [`Must be a valid url`]: `유효한 URL이어야 함`,
  [`Must be an integer`]: `정수여야 함`,
  [`Must be a valid url with no protocol`]: `프로토콜이 없는 유효한 URL이어야 함`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `64 16진 문자 형식이어야 함("0x" 접두부 사용)`,
  [`Must start with '/'`]: `'/'로 시작해야 함`,
  [`Must start with https://`]: `https://로 시작해야 함`,
  [`Must start with https:// or $(`]: `https:// 또는 $(로 시작해야 함`,
  [`Mutual auth`]: `상호 인증`,
  [`Mutual authentication`]: `상호 인증`,
  [`My account`]: `내 계정`,
  [`Name`]: `이름`,
  [`NONCE`]: `난스(nonce)`,
  [`NOT`]: `아님`,
  [`Name cannot be empty.`]: `이름은 비워둘 수 없습니다.`,
  [`Name of Schema in OpenAPI Definitions`]: `OpenAPI 정의에서 스키마의 이름`,
  [`Name of the LDAP user password attribute.`]: `LDAP 사용자 비밀번호 속성의 이름입니다.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `유효성 검증할 LDAP 사용자 레지스트리의 이름입니다. 목록에서 선택하거나 수동으로 입력하십시오.`,
  [`Name of the organization`]: `조직의 이름`,
  [`Namespace inheritance`]: `네임스페이스 상속`,
  [`Namespace inlining`]: `네임스페이스 삽입`,
  [`Native`]: `원시`,
  [`Native OAuth provider`]: `원시 OAuth 제공자`,
  [`Native OAuth provider summary`]: `원시 OAuth 제공자 요약`,
  [`Navigate to Source View`]: `소스 보기로 이동`,
  [`New`]: `새로 작성`,
  [`New API`]: `새 API`,
  [`New API Connect connection`]: `새로운 API Connect 연결`,
  [`New Application`]: `새 애플리케이션`,
  [`New Catalog`]: `새 카탈로그`,
  [`New Consumer organization`]: `새 이용자 조직`,
  [`New Endpoint`]: `새 엔드포인트`,
  [`New native OAuth provider`]: `새 원시 OAuth 제공자`,
  [`New OpenAPI`]: `새 OpenAPI`,
  [`New OpenAPI directory`]: `새 OpenAPI 디렉토리`,
  [`New Organization Title`]: `새 조직 제목`,
  [`New Parameter`]: `새 매개변수`,
  [`New Password`]: `새 비밀번호`,
  [`New product`]: `새 제품`,
  [`New Role`]: `새 역할`,
  [`New schema`]: `새 스키마`,
  [`New Test`]: `새 테스트`,
  [`New third party OAuth provider`]: `새 써드파티 OAuth 제공자`,
  [`New user`]: `새 사용자`,
  [`New applications will be created in the development state`]: `새 애플리케이션은 개발 상태에서 작성됨`,
  [`Next`]: `다음`,
  [`Next page`]: `다음 페이지`,
  [`No`]: `없음`,
  [`No account?`]: `계정이 없습니까?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `샌드박스 카탈로그에 구성된 인증 URL 또는 LDAP 사용자 레지스트리가 없음`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `샌드박스 카탈로그에 구성된 인증 URL 또는 LDAP 사용자 레지스트리가 없습니다. [여기]({link})에서 새로 작성하십시오.`,
  [`No Billing`]: `청구 없음`,
  [`No billing integration has been selected.`]: `청구 통합이 선택되지 않았습니다.`,
  [`No billing integration can be shown when offline.`]: `오프라인일 때는 비용 청구 통합을 표시할 수 없습니다.`,
  [`No gateways`]: `게이트웨이 없음`,
  [`No cache`]: `캐시 없음`,
  [`No Cache`]: `캐시 없음`,
  [`No Certificate Manager services available`]: `사용 가능한 인증서 관리자 서비스 없음`,
  [`No default gateways`]: `기본 게이트웨이 없음`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `사용자 레지스트리 내에 중복 이메일 주소가 없습니다. 이는 이메일이 있는 사용자에게만 적용됩니다.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `샌드박스 카탈로그에 대해 호환 가능한 게이트웨이가 구성되어 있지 않습니다. 탐색기에서 API 테스트(시도)를 사용할 수 없습니다.`,
  [`No Errors`]: `오류가 없음`,
  [`No Histories found`]: `히스토리를 찾을 수 없음`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `게이트웨이 서비스를 찾을 수 없습니다. 지원되는 어셈블리 정책을 표시할 게이트웨이 서비스를 구성하십시오.`,
  [`No gateways are configured for sandbox catalog`]: `샌드박스 카탈로그에 대해 구성된 게이트웨이가 없음`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `샌드박스 카탈로그에 대해 게이트웨이가 구성되어 있지 않습니다. 탐색기에서 API 테스트(시도)를 사용할 수 없습니다.`,
  [`No LDAP or authentication URL user registries found.`]: `LDAP 또는 인증 URL 사용자 레지스트리가 없습니다.`,
  [`No limit on size of list`]: `목록의 크기에 제한이 없음`,
  [`No OAuth providers configured in the sandbox catalog`]: `샌드박스 카탈로그에서 구성된 OAuth 제공자가 없음`,
  [`No OAuth providers configured in the selected catalog`]: `선택한 카탈로그에서 구성된 OAuth 제공자가 없음`,
  [`No Permission`]: `권한 없음`,
  [`No portal added to this catalog`]: `이 카탈로그에 포털이 추가되지 않음`,
  [`No Roles exist`]: `역할이 없음`,
  [`No recent items found.`]: `최근 항목을 찾을 수 없습니다.`,
  [`No TLS profile`]: `TLS 프로파일이 없음`,
  [`No TLS client profile`]: `TLS 클라이언트 프로파일 없음`,
  [`No Truststore`]: `신뢰 저장소 없음`,
  [`No analytics service is configured`]: `분석 서비스가 구성되지 않았습니다.`,
  [`No analytics services found. You can create one [here]({link})`]: `분석 서비스를 찾을 수 없습니다. [여기]({link})에서 하나를 작성할 수 있습니다.`,
  [`No assemblies found.`]: `어셈블리를 찾을 수 없습니다.`,
  [`No available extensions`]: `사용 가능한 확장기능 없음`,
  [`No compatible flows configured in this OAuth provider`]: `이 OAuth 제공자에서 호환 가능한 플로우가 없음`,
  [`No consumer organization will be able to see this product.`]: `어떠한 이용자 조직도 이 제품을 볼 수 없습니다.`,
  [`No debug data was found for this API call`]: `이 API 호출에 대한 디버그 데이터를 찾을 수 없습니다.`,
  [`No details are available.`]: `사용 가능한 세부사항이 없습니다.`,
  [`No existing products`]: `기존 제품 없음`,
  [`No extensions have been configured for the API`]: `API에 대해 구성된 확장기능이 없음`,
  [`No flow configured in this OAuth provider`]: `이 OAuth 제공자에서 구성된 플로우가 없음`,
  [`No items found`]: `항목이 없음`,
  [`No non-member user to add.`]: `추가할 구성원이 아닌 사용자가 없습니다.`,
  [`No organization found`]: `조직이 없음`,
  [`No portal service exists:`]: `포털 서비스 없음:`,
  [`No product APIs`]: `제품 API 없음`,
  [`No products or plans were found.`]: `제품 또는 플랜을 찾을 수 없습니다.`,
  [`No provider organizations`]: `제공자 조직 없음`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `수신된 응답이 없습니다. 원인에는 대상 서버의 CORS 지원 누락, 서버 사용 불가능, 신뢰할 수 없는 인증서 발견 또는 상호 SSL 인증 필요가 포함됩니다.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `사용 가능한 자원이 없습니다. 시작하려면 IBM Cloud 계정 자원에서 자원 그룹을 작성하십시오.`,
  [`No security`]: `보안 없음`,
  [`No services found`]: `서비스가 없음`,
  [`No warnings`]: `경고 없음`,
  [`No warnings.`]: `경고가 없습니다.`,
  [`No. Do not update API Assembly`]: `아니오. API 어셈블리 업데이트 안함`,
  [`Non-blocking`]: `비차단`,
  [`Non int slicing arguments`]: `정수가 아닌 분할 인수`,
  [`None`]: `없음`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `구성된 OAuth 제공자 중 이 API 유형과 일치하는 제공자가 없음`,
  [`Normal`]: `보통`,
  [`Not Available`]: `사용할 수 없음`,
  [`Not applicable for application grant type.`]: `애플리케이션 권한 부여 유형이 적용 가능하지 않습니다.`,
  [`Not valid before`]: `유효 시작 지점`,
  [`Not valid after`]: `유효 종료 지점`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `참고: '자동 제품 생성' 옵션이 선택된 경우 이 옵션은 사용 안함으로 설정됩니다.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `참고: '자동 제품 생성' 옵션이 선택된 경우 이 옵션은 사용 안함으로 설정되고 대신 생성된 제품에 대해 기본 플랜을 사용합니다.`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `참고: 게이트웨이 서비스 환경 설정은 기타 모든 환경 설정의 범위로 사용됩니다. 정책 및 기타 게이트웨이 서비스 특정 컨텐츠는 이 설정에 따라 로드됩니다.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `참고: 이 옵션은 정보용으로만 사용되며 위의 테스트 애플리케이션 옵션을 기반으로 업데이트됩니다.`,
  [`Notices`]: `주의사항`,
  [`Notification template`]: `알림 템플리트`,
  [`Notification templates`]: `알림 템플리트`,
  [`Notification server configured`]: `알림 서버가 구성됨`,
  [`Notifications`]: `알림`,
  [`Null Badgerfish`]: `널 Badgerfish`,
  [`Null`]: `널`,
  [`Number`]: `번호`,
  [`Number of Operations`]: `오퍼레이션 수`,
  [`Numeric`]: `숫자`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth 제공자`,
  [`OAuth provider`]: `OAuth 제공자`,
  [`OAuth Provider Policy Assembly`]: `OAuth 제공자 정책 어셈블리`,
  [`OAuth providers`]: `OAuth 제공자`,
  [`OAuth Redirect URL`]: `OAuth 경로 재지정 URL`,
  [`OAuth shared secret`]: `OAuth 공유 시크릿`,
  [`OAuth provider policy assembly`]: `OAuth 제공자 정책 어셈블리`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `OAuth 제공자 {currentProviderName}을(를) 찾을 수 없습니다. 구성된 OAuth 제공자를 선택하십시오.`,
  [`OAuth redirect URL`]: `OAuth 경로 재지정 URL`,
  [`OAuth Shared Secret`]: `OAuth 공유 시크릿`,
  [`OAuth Signature Method`]: `OAuth 서명 방법`,
  [`OAuth signature method`]: `OAuth 서명 방법`,
  [`OAuth providers can be created and managed in the following list.`]: `다음 목록에서 OAuth 제공자를 작성하고 관리할 수 있습니다.`,
  [`OAuth symmetric key name`]: `OAuth 대칭 키 이름`,
  [`OAuth symmetric key value`]: `OAuth 대칭 키 값`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2 액세스 코드 보안`,
  [`OAuth2 Application Security`]: `OAuth2 애플리케이션 보안`,
  [`OAuth2 Implicit Security`]: `OAuth2 내재적 보안`,
  [`OAuth2 Password Security`]: `OAuth2 비밀번호 보안`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `확인`,
  [`OPERATION ID`]: `오퍼레이션 ID`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `주문`,
  [`ORGANIZATION`]: `조직`,
  [`ORGANIZATION MANAGER`]: `조직 관리자`,
  [`Organizations`]: `조직`,
  [`Owner`]: `소유자`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Oauth 제공자는 공간이 사용 가능한 경우 각 공간에서 관리됩니다. 공간 설정으로 이동하여 OAuth 제공자를 관리하십시오.`,
  [`Off`]: `꺼짐`,
  [`Offline`]: `오프라인`,
  [`Old schema`]: `기존 스키마`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch를 사용하면 제공자 조직 또는 카탈로그 내에서 API, 제품, 애플리케이션, 등록 및 기타 자원을 검색할 수 있습니다. 또한 이러한 자원 간의 관계를 검색할 수도 있습니다.`,
  [`Omnisearch for all supported list pages`]: `지원되는 모든 목록 페이지에 대한 Omnisearch`,
  [`Omnisearch for draft APIs and products`]: `드래프트 API 및 제품에 대한 Omnisearch`,
  [`Omnisearch for published products in catalogs and spaces`]: `카탈로그 및 공간에서 공개된 제품에 대한 Omnisearch`,
  [`Omnisearch for consumer organizations`]: `이용자 조직에 대한 Omnisearch`,
  [`Omnisearch for applications`]: `애플리케이션에 대한 Omnisearch`,
  [`Omnisearch for requested approvals`]: `요청된 승인에 대한 Omnisearch`,
  [`Omnisearch for approvals tasks`]: `승인 태스크에 대한 Omnisearch`,
  [`Omnisearch for product apis`]: `제품 api에 대한 Omnisearch`,
  [`Omnisearch for subscriptions`]: `등록에 대한 Omnisearch`,
  [`Omnisearch for members`]: `구성원에 대한 Omnisearch`,
  [`Omnisearch for provider organizations`]: `제공자 조직에 대한 Omnisearch`,
  [`Disable the new editor for OpenAPI2`]: `OpenAPI2에 대해 새 편집기 사용 안함`,
  [`On`]: `켜짐`,
  [`Onboarding`]: `온보딩`,
  [`Onboarding Edit Admin Org`]: `관리 조직 온보딩 편집`,
  [`Onboarding Edit Cloud Org`]: `클라우드 조직 온보딩 편집`,
  [`Onboarding Edit Reset Password`]: `비밀번호 재설정 온보딩 편집`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `설치 후 IBM Cloud CLI를 사용하여 API Connect를 제공한 계정 및 리젼을 지정하고 CLI에서 시험삼아 사용자의 API Connect 제공업체 조직 전체를 나열해 보십시오.`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `API에서 이용하고 생성하는 스키마를 설명하는 하나 이상의 JSON 오브젝트입니다.`,
  [`One or more JSON representations for parameters`]: `매개변수에 대한 하나 이상의 JSON 표시`,
  [`One time use access token`]: `일회성 액세스 토큰`,
  [`One time use refresh token`]: `일회성 새로 고치기 토큰`,
  [`Online`]: `온라인`,
  [`Only one API file should be present in the zip file.`]: `zip 파일에는 하나의 API 파일만 있어야 합니다.`,
  [`OpenAPI Specification Version`]: `OpenAPI 사양 버전`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `OIDC(Open ID Connect)는 OAuth 2.0 기반의 추가 인증 프로토콜을 제공합니다. OIDC는 JSON Web Token 또는 JWT로 인코딩된 사용자 정보를 제공합니다. OpenID Connect를 사용하는 경우, 액세스 토큰과 함께 ID 토큰을 생성할 수 있도록 템플리트가 제공되고 필수 어셈블리 정책이 자동으로 작성됩니다.`,
  [`Open a Directory`]: `디렉토리 열기`,
  [`Open in external editor`]: `외부 편집기에서 열기`,
  [`Open the server`]: `서버 열기`,
  [`OpenAPI extensions`]: `OpenAPI 확장기능`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect(OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `게이트웨이 버전이 6000인 경우 OpenID Connect가 아직 지원되지 않음`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `선택한 권한 부여 유형에 내재 및 액세스 코드 중 하나 이상이 포함된 경우에만 OpenID Connect 설정이 적용됩니다.`,
  [`Operation`]: `오퍼레이션`,
  [`Operation Id`]: `오퍼레이션 ID`,
  [`Operation switch`]: `오퍼레이션 전환`,
  [`Operation Switch`]: `오퍼레이션 전환`,
  [`Operations`]: `오퍼레이션`,
  [`Operator build date`]: `운영자 빌드 날짜`,
  [`Operator build tag`]: `운영자 빌드 태그`,
  [`Operator version`]: `운영자 버전`,
  [`Optionally select a CA bundle`]: `선택적으로 CA 번들 선택`,
  [`Optimize schema definition`]: `스키마 정의 최적화`,
  [`Optional`]: `선택사항`,
  [`Or`]: `또는`,
  [`Or create a new application and subscribe to the selected plan`]: `또는 새 애플리케이션을 작성하고 선택된 플랜에 등록하십시오.`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `또는 새 제품을 작성하여 샌드박스 카탈로그에 공개하십시오.`,
  [`Order`]: `순서`,
  [`Org`]: `조직`,
  [`Org owner`]: `조직 소유자`,
  [`Organization`]: `조직`,
  [`Organization:`]: `조직`,
  [`Organization information`]: `조직 정보`,
  [`Organization Manager`]: `조직 관리자`,
  [`Organization has been updated.`]: `조직이 업데이트되었습니다.`,
  [`Organizations will be listed here.`]: `여기에 조직이 나열됩니다.`,
  [`Otherwise`]: `그 외의 경우`,
  [`Output`]: `출력`,
  [`Output Claims`]: `출력 청구`,
  [`Output array`]: `출력 배열`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `XML 문서를 올바르게 구성하는 데 필요하지 않더라도 루트 요소를 출력하십시오.`,
  [`Output variable`]: `출력 변수`,
  [`Override API consume types`]: `API 이용 유형 대체`,
  [`Override API produce types`]: `API 제품 유형 대체`,
  [`Override API security definitions`]: `API 보안 정의 대체`,
  [`Override rate limits`]: `속도 제한 대체`,
  [`Override default "provider" settings with configuration from this URL`]: `기본 "provider" 설정을 이 URL의 구성으로 대체`,
  [`Override default "provider" settings with configuration from this literal`]: `기본 "provider" 설정을 이 리터럴의 구성으로 대체`,
  [`Override plan rate limits`]: `플랜 속도 제한 대체`,
  [`Override plan rate limits for individual operation`]: `개별 오퍼레이션의 플랜 속도 제한 대체`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `소비자 조직 초대 제한시간을 카탈로그 초대 제한시간으로 대체`,
  [`Overview`]: `개요`,
  [`Owner scope check`]: `소유자 범위 확인`,
  [`Owner's Email`]: `소유자의 이메일`,
  [`Owner's Name`]: `소유자 이름`,
  [`Owner's email`]: `소유자의 이메일`,
  [`Owns and administers the API provider organization`]: `API 제공자 조직을 소유하고 관리함`,
  [`Owns and administers the admin organization`]: `관리 조직 소유 및 관리`,
  [`Owns and administers the app developer organization`]: `앱 개발자 조직 관리 및 소유`,
  [`Owns and administrates the app developer organization`]: `앱 개발자 조직 관리 및 소유`,
  [`PARAMETER NAME`]: `매개변수 이름`,
  [`PATCH`]: `패치`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `대상(aud) 청구를 유효성 검증하는 데 사용할 PCRE입니다.`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `발행자(iss) 청구를 유효성 검증하는 데 사용할 PCRE입니다.`,
  [`PER`]: `PER`,
  [`Per`]: `단위`,
  [`Plan`]: `플랜`,
  [`Plan:`]: `플랜:`,
  [`pending Consumer organization invitation`]: `보류 중인 이용자 조직 초대`,
  [`PLAN`]: `플랜`,
  [`PLANS`]: `플랜`,
  [`Policy`]: `정책`,
  [`policy`]: `정책`,
  [`POLICIES`]: `정책`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `제품`,
  [`Product Plan`]: `제품 플랜`,
  [`Product Plans`]: `제품 플랜`,
  [`Product Plans per page`]: `페이지당 제품 계획`,
  [`Property name`]: `특성 이름`,
  [`Property type`]: `특성 유형`,
  [`Property example`]: `특성 예`,
  [`Property description`]: `특성 설명`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `페이지를 찾을 수 없음`,
  [`Parameter name`]: `매개변수 이름`,
  [`Parameter control`]: `매개변수 제어`,
  [`Parameters`]: `매개변수`,
  [`Parent`]: `모기업`,
  [`Parse`]: `구문 분석`,
  [`Parse settings URL reference`]: `설정 URL 참조 구문 분석`,
  [`Parse settings literal configuration`]: `설정 리터럴 구성 구문 분석`,
  [`Parse settings object reference`]: `설정 오브젝트 참조 구문 분석`,
  [`Parse the GraphQL query`]: `GraphQL 조회의 구문 분석`,
  [`Parse the response from the GraphQL backend server`]: `GraphQL 백엔드 서버의 응답을 구문 분석`,
  [`Parse XML output`]: `XML 출력 구문 분석`,
  [`Pass-through`]: `경유`,
  [`Password`]: `비밀번호`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `IBM API Connect에서 사용자 계정의 비밀번호가 변경됨`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `{portalTitle} 개발자 포털에서 사용자 계정의 비밀번호가 변경됨`,
  [`Password context variable`]: `비밀번호 컨텍스트 변수`,
  [`Password is invalid. Re-enter the password.`]: `비밀번호가 올바르지 않습니다. 비밀번호를 다시 입력하십시오.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `IBM API Connect에서 사용자 계정의 비밀번호 재설정 요청`,
  [`Password reset request for your {{catalog}} developer portal account`]: `{catalog} 개발자 포털 계정의 비밀번호 재설정 요청`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `비밀번호에는 소문자, 대문자, 숫자 및 구두점(예: !, $, #, %) 중 두 개 카테고리의 문자가 하나 이상 있어야 합니다.`,
  [`Passwords must contain eight or more characters`]: `비밀번호는 8자 이상이어야 합니다.`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `비밀번호는 8자 이상을 포함해야 하며 소문자, 대문자, 숫자 및 구두점(예: !, $, #, %) 중 두 카테고리에 해당하는 문자가 하나 이상 있어야 합니다. 또한 동일한 문자를 세 번 이상 연속해서 사용할 수 없습니다.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `비밀번호에는 동일한 문자를 세 번 이상 연속해서 사용할 수 없습니다.`,
  [`Passwords should match`]: `비밀번호가 일치해야 함`,
  [`Path`]: `경로`,
  [`Path parameters`]: `경로 매개변수`,
  [`Path name`]: `경로 이름`,
  [`Paths`]: `경로`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `경로는 API에 의해 공개된 REST 자원을 식별합니다. 오퍼레이션은 경로와 HTTP verb, 매개변수, 요청 및 응답에 대한 정의를 결합합니다.`,
  [`Payments Authorization`]: `지불 권한`,
  [`Payment method`]: `결제 방법`,
  [`Pending`]: `보류 중`,
  [`Pending State`]: `보류 중 상태`,
  [`Pending approval to be published in catalog`]: `카탈로그에 공개할 보류 중인 승인`,
  [`Pending approval to be staged in catalog`]: `카탈로그에 스테이징할 보류 중인 승인`,
  [`Pending consumer organization invitations`]: `보류 중인 이용자 조직 초대`,
  [`View endpoints`]: `엔드포인트 보기`,
  [`View base endpoints for this API`]: `이 API의 기본 엔드포인트 보기`,
  [`View pending invitations`]: `보류 중인 초대 보기`,
  [`Permission Denied`]: `사용 권한이 거부됨`,
  [`Permissions`]: `권한`,
  [`Persistent Connection`]: `지속적 연결`,
  [`Pin menu`]: `핀 메뉴`,
  [`PlainText`]: `일반 텍스트`,
  [`Plan APIs`]: `플랜 API`,
  [`Plan burst limits`]: `플랜 버스트 한계`,
  [`Plan by name`]: `이름별 플랜`,
  [`Plan Default`]: `기본 플랜`,
  [`Plan Details`]: `플랜 세부사항`,
  [`Plan migration targets`]: `마이그레이션 대상 계획`,
  [`Plan rate limits`]: `플랜 속도 제한`,
  [`Plan name`]: `플랜 이름`,
  [`Plan pricing`]: `플랜 가격 책정`,
  [`Plan title already exists`]: `플랜 제목이 이미 있음`,
  [`Plans`]: `플랜`,
  [`Platform REST API endpoint for admin and provider APIs`]: `관리 및 제공자 API에 대한 플랫폼 REST API 엔드포인트`,
  [`Platform REST API endpoint for consumer APIs`]: `이용자 API에 대한 플랫폼 REST API 엔드포인트`,
  [`Please choose a bind method`]: `바인드 방법 선택`,
  [`Please choose an authentication method`]: `인증 방법을 선택`,
  [`Please contact the system administrator.`]: `시스템 관리자에게 문의하십시오.`,
  [`Please contact your administrator for support.`]: `지원이 필요하면 관리자에게 문의하십시오.`,
  [`Please enter the reason.`]: `이유를 입력하십시오.`,
  [`Please enter your username`]: `사용자 이름을 입력하십시오.`,
  [`Please go to the space settings to manage API endpoints.`]: `공간 설정으로 이동하여 API 엔드포인트를 관리하십시오.`,
  [`Please make sure the server is online and try again.`]: `서버가 온라인인지 확인하고 다시 시도하십시오.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `활동 로그를 사용하지 않으면 성공 또는 오류가 발생했을 때 기록할 컨텐츠 유형과 관련된 속성이 제거됩니다. 이를 사용 안하시겠습니까?`,
  [`Please review the license for API Connect by accessing`]: `다음에 액세스하여 API Connect에 대한 라이센스를 검토하십시오.`,
  [`Please select a TLS`]: `TLS를 선택`,
  [`Please select a TLS client profile`]: `TLS 클라이언트 프로파일 선택`,
  [`Please select a group type`]: `그룹 유형을 선택`,
  [`Please select an LDAP protocol version`]: `LDAP 프로토콜 버전 선택`,
  [`Please select authentication method`]: `인증 방법 선택`,
  [`Please select ciphers from the list below:`]: `아래 목록에서 암호를 선택하십시오.`,
  [`Please select consumer organization`]: `이용자 조직을 선택하십시오.`,
  [`Please select one Keystore from the list below:`]: `아래 목록에서 하나의 키 저장소를 선택하십시오.`,
  [`Please select one keystore from the list below:`]: `아래 목록에서 하나의 키 저장소를 선택하십시오.`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `이 새 애플리케이션에 대한 클라이언트 ID와 시크릿을 메모해 두십시오. 시크릿은 필요 시 재설정할 수 있지만, 다시 검색할 수 없으므로 안전한 곳에 저장하십시오,`,
  [`Please try again in a few minutes.`]: `몇 분 후에 다시 시도하십시오.`,
  [`Please refresh catalog/space product list page and try again.`]: `카탈로그/공간 제품 목록 페이지를 새로 고치고 다시 시도하십시오.`,
  [`Policies`]: `정책`,
  [`Policy Assemblies`]: `정책 어셈블리`,
  [`Policy assembly`]: `정책 어셈블리`,
  [`Policy description.`]: `정책 설명`,
  [`Policy title`]: `정책 제목`,
  [`Policy has been uploaded.`]: `정책이 업로드되었습니다.`,
  [`Polish`]: `폴란드어`,
  [`Port`]: `포트`,
  [`Portal`]: `포털`,
  [`Portal details`]: `포털 세부사항`,
  [`Portal Director TLS client profile`]: `포털 디렉터 TLS 클라이언트 프로파일`,
  [`Portal Director keystore`]: `포털 디렉터 키 저장소`,
  [`Portal Director truststore`]: `포털 디렉터 신뢰 저장소`,
  [`Portal service`]: `포털 서비스`,
  [`Portal Service`]: `포털 서비스`,
  [`Portal service name`]: `포털 서비스 이름`,
  [`Portal service details`]: `포털 서비스 세부 정보`,
  [`Portal Service and URL`]: `포털 서비스 및 URL`,
  [`Portal service {name} was successfully created.`]: `포털 서비스 {name}이(가) 작성되었습니다.`,
  [`Portal service {name} was successfully updated.`]: `포털 서비스 {name}이(가) 업데이트되었습니다.`,
  [`Portal Service {arg} has been removed.`]: `포털 서비스 {arg}이(가) 제거되었습니다.`,
  [`Portal site`]: `포털 사이트`,
  [`Portal URL`]: `포털 URL`,
  [`Portal site URLs cannot be greater than 200 characters`]: `포털 사이트 URL은 200자를 초과할 수 없습니다.`,
  [`Portal website URL`]: `포털 웹 사이트 URL`,
  [`Portal and Gateway Settings`]: `포털 및 게이트웨이 설정`,
  [`Portal has been created`]: `포털이 작성됨`,
  [`Portal has been created.`]: `포털이 작성되었습니다.`,
  [`Portal has been deleted`]: `포털이 삭제됨`,
  [`Portals`]: `포털`,
  [`Portuguese`]: `포루투갈어`,
  [`Post Response`]: `사후 응답`,
  [`Post-Invoke Hook`]: `후크 사후 호출`,
  [`Preserve subscriptions on re-publish`]: `재공개 시 등록 유지`,
  [`Preserve subscriptions on re-publish product`]: `제품 재공개 시 등록 유지`,
  [`Pre-Invoke Hook`]: `후크 사전 호출`,
  [`Pre-Request`]: `사전 요청`,
  [`Preference`]: `환경 설정`,
  [`Prefix`]: `접두부`,
  [`Preparing API`]: `API 준비`,
  [`Preserved request header`]: `보존된 요청 헤더`,
  [`preserved request header`]: `보존된 요청 헤더`,
  [`Preserved response header`]: `보존된 응답 헤더`,
  [`preserved response header`]: `보존된 응답 헤더`,
  [`Preview`]: `미리보기`,
  [`Preview of login screen registry prompt`]: `로그인 화면 레지스트리 프롬프트의 미리보기`,
  [`Previous`]: `이전`,
  [`Previous page`]: `이전 페이지`,
  [`Prettify`]: `장식`,
  [`Price per month`]: `월별 가격`,
  [`Private`]: `개인용`,
  [`Private Claims`]: `개인용 청구`,
  [`Private key & public key`]: `개인 키 및 공개 키`,
  [`Private Key or Shared Secret for Decrypt`]: `복호화용 개인 키 또는 공유 본인확인정보`,
  [`Private Key or Shared Secret for Sign`]: `서명용 개인 키 또는 공유 본인확인정보`,
  [`Private key detected`]: `개인 키가 발견됨`,
  [`Private key password`]: `개인 키 비밀번호`,
  [`Processed`]: `처리됨`,
  [`Problem`]: `문제점`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `추적 데이터를 페치하는 중에 문제점이 발생했습니다. 다시 시도하십시오. 오류 세부사항은 콘솔을 참조하십시오.`,
  [`Produced Media Types`]: `생성된 매체 유형`,
  [`Produces`]: `생산`,
  [`Product`]: `제품`,
  [`Product:`]: `제품:`,
  [`Product / Plan`]: `제품 / 플랜`,
  [`Product APIs`]: `제품 API`,
  [`Product editor`]: `제품 편집기`,
  [`Product lifecycle`]: `제품 라이프사이클`,
  [`Product Lifecycle`]: `제품 라이프사이클`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `제품 이름은 '-auto-product'(으)로 끝날 수 없습니다. 이름을 변경하고 가져오기를 다시 시도하십시오.`,
  [`Product setup`]: `제품 설치`,
  [`Product details`]: `제품 세부사항`,
  [`Product documentation`]: `제품 문서`,
  [`Product has been created.`]: `제품이 작성됨`,
  [`Product has been deleted`]: `제품이 삭제됨`,
  [`Product has been updated`]: `제품이 업데이트됨`,
  [`Product has been updated.`]: `제품이 업데이트되었습니다.`,
  [`Product has not been created!`]: `제품이 작성되지 않았습니다!`,
  [`Product has not been published!`]: `제품이 공개되지 않았습니다!`,
  [`Product has not been updated!`]: `제품이 업데이트되지 않았습니다!`,
  [`Product has not been staged!`]: `제품이 스테이징되지 않았습니다!`,
  [`Product information`]: `제품 정보`,
  [`Product summary details`]: `제품 요약 세부사항`,
  [`Product version`]: `제품 버전`,
  [`Product visibility`]: `제품 가시성`,
  [`Product-Approval`]: `제품-승인`,
  [`Product-Drafts`]: `제품-드래프트`,
  [`Product/plan`]: `제품/플랜`,
  [`Production`]: `프로덕션`,
  [`Production mode`]: `프로덕션 모드`,
  [`Production endpoints`]: `프로덕션 엔드포인트`,
  [`Products`]: `제품`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `애플리케이션 개발자가 사용할 수 있게 하려면 제품을 카탈로그에 스테이징한 후 공개해야 합니다. 카탈로그는 게이트웨이 및 개발자 포털의 논리 파티션으로 작동합니다. API 호출용 URL과 개발자 포털은 특정 카탈로그에 고유합니다. 일반 구성에서 API 제공자 조직은 개발 카탈로그를 사용하여 개발 중인 API를 테스트하고 프로덕션 카탈로그를 사용하여 완전히 사용할 준비가 된 API를 호스팅합니다.`,
  [`Profile`]: `프로파일`,
  [`Profile rule`]: `프로파일 규칙`,
  [`Promote to production`]: `프로덕션으로 승격`,
  [`Proof Key for Code Exchange`]: `코드 교환에 필요한 증명 키`,
  [`Proof Key for Code Exchange (PKCE)`]: `PKCE(Proof Key for Code Exchange)`,
  [`Properties`]: `특성`,
  [`Property`]: `특성`,
  [`Property Description`]: `특성 설명`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `{{target}} 특성은 아래에 입력하여 정적 값에 지정될 수 있습니다.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `{{target}} 특성은 현재 {{from}}에 맵핑됩니다. 맵핑은 아래에서 제거할 수 있습니다.`,
  [`Property Value is Base64 Encoded`]: `특성 값이 Base64로 인코딩됨`,
  [`Protocol`]: `프로토콜`,
  [`Protocols`]: `프로토콜`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `API를 호출하는 데 사용할 수 있는 프로토콜(게이트웨이 적용 API에는 https만 지원됨)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `OAuth {oauthVer} 인증 정보를 제공하여 사용자를 인증하고 OpenID 제공자의 API에 액세스할 수 있습니다.`,
  [`Provide a schema`]: `스키마 제공`,
  [`Provide GraphQL schema`]: `GraphQL 스키마 제공`,
  [`Provide the URL to the backend GraphQL server`]: `백엔드 GraphQL 서버에 대한 URL 제공`,
  [`Provide your email address to receive password reset instructions`]: `비밀번호 재설정 지시사항을 수신할 이메일 주소를 제공하십시오.`,
  [`Provide your new password below`]: `새 비밀번호를 아래 제공하십시오.`,
  [`Provider endpoint`]: `제공자 엔드포인트`,
  [`Provider Information`]: `제공자 정보`,
  [`Provider Organization`]: `제공자 조직`,
  [`Provider Organizations`]: `제공자 조직`,
  [`Provider Type`]: `제공자 유형`,
  [`Provider organizations`]: `제공자 조직`,
  [`Provider type`]: `제공자 유형`,
  [`Provider org invite failed`]: `제공자 조직 초대 실패`,
  [`Provider organization {name} created`]: `제공자 조직 {name}이(가) 작성됨`,
  [`Provider organizations will be listed here`]: `제공자 조직이 여기에 나열됨`,
  [`Provider-Org`]: `제공자-조직`,
  [`Provides contact information for the exposed API.`]: `공개된 API에 대한 담당자 정보를 제공합니다.`,
  [`Provides license information for the exposed API.`]: `공개된 API에 대한 라이센스 정보를 제공합니다.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `API에 대한 메타데이터를 제공합니다. 필요한 경우 클라이언트에서 메타데이터를 사용할 수 있습니다.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `개발자 포털의 프로비저닝이 시작되었습니다. 완료하는 데 약간의 시간이 소요됩니다. 완료되면 포털 관리자 계정의 비밀번호를 설정하기 위한 링크가 포함된 이메일을 받게 됩니다.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `개발자 포털 URL 업데이트가 시작되었습니다. 프로세스를 완료하는 데 몇 분 정도 걸립니다.`,
  [`Proxy`]: `프록시`,
  [`Proxy URL`]: `프록시 URL`,
  [`Public`]: `공용`,
  [`Public Key or Shared Secret for Encrypt`]: `암호화용 공개 키 또는 공유 본인확인정보`,
  [`Publish`]: `공개`,
  [`Publish API`]: `API 공개`,
  [`Publish a product from the Develop section.`]: `개발 섹션에서 제품을 공개합니다.`,
  [`Publish product`]: `제품 공개`,
  [`Publish to`]: `공개 대상`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `일부 제품에 대한 등록을 작성하려면 해당 제품을 이 카탈로그에 공개하십시오.`,
  [`Publish target`]: `대상 공개`,
  [`Publish this API to the Sandbox catalog.`]: `이 API를 샌드박스 카탈로그에 공개합니다.`,
  [`Publish to specific gateway services`]: `특정 게이트웨이 서비스에 공개`,
  [`Publish using a new product`]: `새 제품을 사용하여 공개`,
  [`Publish using an existing product`]: `기존 제품을 사용하여 공개`,
  [`Publishable Key`]: `공개 가능 키`,
  [`Publishable key`]: `공개 가능 키`,
  [`Publishable key tip`]: `공개 가능 키 팁`,
  [`Published`]: `공개됨`,
  [`Published product`]: `공개된 제품`,
  [`Publishing`]: `공개`,
  [`Publishing New API`]: `새 API 공개`,
  [`Publish (preserve subscriptions)`]: `공개(등록 유지)`,
  [`Publish|permission`]: `공개`,
  [`Put online`]: `온라인 상태로 전환`,
  [`Query`]: `조회`,
  [`Queryparam Encode`]: `Queryparam 인코딩`,
  [`Queued`]: `큐에 대기됨`,
  [`Queued events: **{queued}**`]: `큐에 대기된 이벤트: **{queued}**`,
  [`Retry blocked jobs`]: `차단된 작업 재시도`,
  [`REQUIRED`]: `필수`,
  [`ROLES`]: `역할`,
  [`Rate limit`]: `속도 제한`,
  [`Rate Limit`]: `비율 한계`,
  [`Rate Limit Name`]: `속도 제한 이름`,
  [`Rate limits`]: `속도 제한`,
  [`Rate limit name can't be a duplicate`]: `속도 제한 이름은 중복될 수 없음`,
  [`Rate limit name can't be empty`]: `속도 제한 이름은 비어 있을 수 없음`,
  [`Rate limit overrides`]: `속도 제한 대체`,
  [`Rate limit time interval can't be 0`]: `속도 제한 시간 간격은 0일 수 없음`,
  [`Rate limit time interval can't be empty`]: `속도 제한 시간 간격은 비어 있을 수 없음`,
  [`Rate limit time interval unit must be selected`]: `속도 제한 시간 간격 단위가 선택되어야 함`,
  [`Rate limit value can't be empty`]: `속도 제한 값은 비어 있을 수 없음`,
  [`Rate limit value can't be negative`]: `속도 제한 값은 음수일 수 없음`,
  [`Re-stage`]: `다시 스테이지`,
  [`Re-stage product`]: `제품 다시 스테이지`,
  [`Read only`]: `읽기 전용`,
  [`Read-Only`]: `읽기 전용`,
  [`Realized`]: `실현됨`,
  [`Reason`]: `이유`,
  [`Reason for declining`]: `거부 이유`,
  [`Recommendations`]: `권장사항`,
  [`Recommended configuration`]: `권장 구성`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `나중에 사용할 수 있도록 표시된 클라이언트 ID 및 시크릿을 기록해 두십시오. 클라이언트 시크릿은 나중에 표시될 수 없습니다.`,
  [`Recommendations applied`]: `권장사항이 적용됨`,
  [`Redact`]: `수정`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `요청 본문, 응답 본문 및 활동 로그에서 지정된 필드를 수정하거나 제거하십시오. XPath 표현식을 지정하여 수정하거나 제거할 필드를 정의하십시오.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `요청 본문, 응답 본문 및 활동 로그에서 지정된 필드를 수정하거나 제거하십시오. XPath 또는 JSONata 표현식을 지정하여 수정 또는 제거할 필드를 정의하십시오.`,
  [`Redaction`]: `수정`,
  [`Redactions`]: `수정`,
  [`Redirect`]: `경로 재지정`,
  [`Redirect URI`]: `경로 재지정 URI`,
  [`Redirect URL`]: `URL 경로 재지정`,
  [`Redirect through Portal`]: `포털을 통해 경로 재지정`,
  [`Redirect time limit`]: `경로 재지정 시간 한계`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `경로 재지정 시간 제한은 10초에서 600초 사이여야 합니다.`,
  [`References`]: `참조`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `추가 지침은 [IBM Cloud 문서]({link})를 참조하십시오.`,
  [`Referral`]: `위탁`,
  [`Refresh`]: `새로 고치기`,
  [`Refresh Token`]: `토큰 새로 고치기`,
  [`Refresh button`]: `새로 고치기 단추`,
  [`Refresh products`]: `제품 새로 고치기`,
  [`Refresh results from server`]: `서버의 새로 고치기 결과`,
  [`Refresh token`]: `토큰 새로 고치기`,
  [`Refresh token time to live`]: `새로 고치기 토큰 TTL(Time To Live)`,
  [`Refresh token time-to-live`]: `토큰 새로 고치기 TTL`,
  [`Refresh token time-to-live (seconds)`]: `토큰 새로 고치기 TTL(초)`,
  [`Refresh token TTL has been changed`]: `새로 고치기 토큰 TTL(Time To Live)이 변경됨`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `새로 고치기 토큰 TTL(Time To Live)이 **{seconds}초({time})** 후에 만료됨`,
  [`Refresh token time to live is **off**`]: `새로 고치기 토큰 TTL(Time To Live)이 **off**임`,
  [`Refresh tokens`]: `토큰 새로 고치기`,
  [`Refreshing`]: `새로 고치는 중`,
  [`Refreshing...`]: `새로 고치는 중...`,
  [`Region`]: `지역`,
  [`Register`]: `등록`,
  [`Register Gateways`]: `게이트웨이 등록`,
  [`Register Service`]: `서비스 등록`,
  [`Register gateways`]: `게이트웨이 등록`,
  [`Register service`]: `서비스 등록`,
  [`Register a service`]: `서비스 등록`,
  [`Register new services and manage existing services`]: `새 서비스를 등록하고 기존 서비스를 관리합니다.`,
  [`Register remote gateway`]: `원격 게이트웨이 등록`,
  [`Registered Admin Member`]: `등록된 관리자 구성원`,
  [`Registered Provider Org Owner or Member`]: `등록된 제공자 조직 소유자 또는 구성원`,
  [`Registered client information on the OpenID provider`]: `OpenID 제공자에 등록된 클라이언트 정보`,
  [`Registration completed successfully`]: `등록이 완료됨`,
  [`Registration failed.`]: `등록이 실패했습니다.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `개별 엔드포인트에 대한 상대 경로입니다. 이는 'basePath'에 상대적이어야 합니다.`,
  [`Relaxed Badgerfish`]: `온건한 Badgerfish`,
  [`Remove analysis configuration`]: `분석 구성 제거`,
  [`Remote API Connect`]: `원격 API Connect`,
  [`Remote login`]: `원격 로그인`,
  [`Remote publish`]: `원격 공개`,
  [`Remove`]: `제거`,
  [`Remove Burst Limit`]: `버스트 한계 제거`,
  [`Remove constraints`]: `제한조건 제거`,
  [`Remove Count Limit`]: `개수 한계 제거`,
  [`Remove gateway extension`]: `게이트웨이 확장 제거`,
  [`Remove product`]: `제품 제거`,
  [`Remove Rate limit`]: `속도 제한 제거`,
  [`Remove the invalid constraints`]: `올바르지 않은 제한조건 제거`,
  [`Remove the invalid constraints from`]: `올바르지 않은 제한조건 제거`,
  [`Remove action`]: `조치 제거`,
  [`Remove case`]: `케이스 제거`,
  [`Remove catch`]: `발견 제거`,
  [`Remove filter`]: `필터 제거`,
  [`Remove input`]: `입력 제거`,
  [`Remove mapping`]: `맵핑 제거`,
  [`Remove output`]: `출력 제거`,
  [`Remove static value`]: `정적 값 제거`,
  [`Removed`]: `제거됨`,
  [`Removed by user`]: `사용자가 제거함`,
  [`Reorder`]: `다시 정렬`,
  [`Repeat`]: `반복`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `API 호출을 설정된 횟수만큼 반복하거나 중지 단추를 클릭할 때까지 반복하십시오.`,
  [`Replace`]: `바꾸기`,
  [`Replace Free Trial with`]: `무료 체험판을 다음으로 대체`,
  [`Replace Gold with`]: `골드를 다음으로 대체`,
  [`Replace GraphQL schema`]: `GraphQL 스키마 대체`,
  [`Replace plans`]: `플랜 교체`,
  [`Replace private key & public key`]: `개인 키 및 공개 키 대체`,
  [`Replace Product`]: `제품 교체`,
  [`Replace product`]: `제품 교체`,
  [`Replace schema`]: `스키마 대체`,
  [`Replace Silver with`]: `실버를 다음으로 대체`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `제안 목록에서 새 소유자를 선택하여 현재 소유자를 대체하십시오. 작업을 계속할 수 있도록 대체된 소유자에게 새 권한을 지정하십시오.`,
  [`Replace|permission`]: `바꾸기`,
  [`Republish`]: `다시 공개`,
  [`Republish product`]: `제품 재공개`,
  [`Request`]: `요청`,
  [`Request catch`]: `요청 발견`,
  [`Request endpoint`]: `요청 엔드포인트`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `{catalog} 카탈로그에서 API 제품 {action}에 대한 요청이 승인됨`,
  [`Request approved to {{action}} app {{appName}}`]: `{action} 앱 {appName}에 대한 요청이 승인됨`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `{catalog} 카탈로그에서 API 제품 {action}에 대한 요청이 거부됨`,
  [`Request denied to {{action}} app {{appName}}`]: `{action} 앱 {appName}에 대한 요청이 거부됨`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `{catalog} 카탈로그에서 API 제품 등록 승인 요청`,
  [`Request for approval to {{action}} app {{appName}}`]: `{appName} 앱 {action}에 대한 승인 요청`,
  [`Request headers`]: `요청 헤더`,
  [`Request pending to promote application to production`]: `애플리케이션을 프로덕션으로 승격하기 위해 보류 중인 요청`,
  [`Request query`]: `요청 조회`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `{catalog} 카탈로그에서 API 제품 {action}에 대한 요청이 수신됨`,
  [`Request received to {{action}} app {{appName}}`]: `{appName} 앱 {action}에 대한 요청이 수신됨`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `{catalog} 카탈로그에서 API 제품 등록 취소 요청`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `{catalog} 카탈로그에서 API 제품 {action} 등록 취소 요청`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `{action} 앱 {appName}에 대한 철회 요청`,
  [`Requested approvals`]: `요청된 승인`,
  [`Requested By`]: `요청한 사용자`,
  [`Requested To`]: `요청된 대상`,
  [`Require`]: `필수`,
  [`Require applications to provide a client ID`]: `애플리케이션이 클라이언트 ID를 제공해야 함`,
  [`Require approval for all new self service development accounts`]: `모든 새 셀프 서비스 개발 계정에 대한 승인 필요`,
  [`Required`]: `필수`,
  [`Required for rate limiting`]: `속도 제한에 필수`,
  [`Required must be checked off when location is in path.`]: `위치가 경로에 있는 경우 필수를 선택해야 합니다.`,
  [`Required path`]: `필수 경로`,
  [`Resend Invite`]: `초대장 재발송`,
  [`Resend invitation`]: `다시 초대하기`,
  [`Resend invite`]: `초대장 재발송`,
  [`Reset`]: `재설정`,
  [`Reset defaults`]: `기본값 재설정`,
  [`Reset Notification template`]: `알림 템플리트 재설정`,
  [`Reset password`]: `비밀번호 재설정`,
  [`Reset password TTL has been changed`]: `비밀번호 재설정 TTL이 변경됨`,
  [`Reset password time to live`]: `비밀번호 재설정 TTL(Time To Live)`,
  [`Reset password time-to-live`]: `비밀번호 재설정 TTL(Time To Live)`,
  [`Reset password email has been sent`]: `비밀번호 재설정 이메일이 발송됨`,
  [`Reset password email has not been sent`]: `비밀번호 재설정 이메일이 발송되지 않음`,
  [`Reset password invitation timeout`]: `비밀번호 재설정 초대 제한시간`,
  [`Reset to default preferences`]: `기본 환경 설정으로 재설정`,
  [`Resolve API Connect variable references`]: `API Connect 변수 참조 결정`,
  [`Resolve XML input data type`]: `XML 입력 데이터 유형 해석`,
  [`resolver`]: `분석기`,
  [`Resource group`]: `자원 그룹`,
  [`Resource Owner`]: `자원 소유자`,
  [`Resource owner password grant`]: `자원 소유자 비밀번호 권한 부여`,
  [`Resource owner revocation`]: `자원 소유자 폐기`,
  [`Resource owner revocation path`]: `자원 소유자 취소 경로`,
  [`Resource Owner Security`]: `자원 소유자 보안`,
  [`Resource owner - JWT`]: `자원 소유자 - JWT`,
  [`Resource owner - Password`]: `자원 소유자 - 비밀번호`,
  [`Resource owner password`]: `자원 소유자 비밀번호`,
  [`Resources`]: `자원`,
  [`Response`]: `응답`,
  [`Response catch`]: `응답 발견`,
  [`Response Definition`]: `응답 정의`,
  [`Response Time`]: `응답 시간`,
  [`Response type`]: `응답 유형`,
  [`Response context variables`]: `응답 컨텍스트 변수`,
  [`Response headers`]: `응답 헤더`,
  [`Response must have a description`]: `응답에 설명이 있어야 함`,
  [`Response must have a schema`]: `응답에 스키마가 있어야 함`,
  [`Response must have a status code`]: `응답에 상태 코드가 있어야 함`,
  [`Response object variable`]: `응답 오브젝트 변수`,
  [`Response time`]: `응답 시간`,
  [`Response time: {{value}}ms`]: `응답 시간: {{value}}밀리초`,
  [`Result of removing`]: `제거 결과`,
  [`Restrict HTTP transactions to version 1.0.`]: `HTTP 트랜잭션을 버전 1.0로 제한합니다.`,
  [`Restrict to HTTP 1.0`]: `HTTP 1.0으로 제한`,
  [`Retire`]: `폐기`,
  [`Retire product`]: `제품 폐기`,
  [`Retired`]: `폐기됨`,
  [`Retire|permission`]: `폐기`,
  [`Return V5 Responses`]: `V5 응답 리턴`,
  [`Retry selected requests`]: `선택한 요청 재시도`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `GraphQL 프록시에서 표준 GraphQL 자체 검사 쿼리의 결과 리턴`,
  [`Return third-party access_token`]: `써드파티 access_token 리턴`,
  [`Return third-party access_token with the token response`]: `토큰 응답으로 써드파티 access_token 리턴`,
  [`Return third-party id_token`]: `써드파티 id_token 리턴`,
  [`Return third-party id_token with the token response`]: `토큰 응답으로 써드파티 id_token 리턴`,
  [`Return to Login`]: `로그인으로 돌아가기`,
  [`Reveal in finder`]: `파인더에서 표시`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `검토 후 스키마에서 숨기거나 표시할 내용을 결정합니다. 스키마를 유효하게 유지하려면 추가 변경이 필요할 수 있습니다.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `경고를 검토하십시오. 편집기에서 나중에 지능형 권장사항으로 스키마 개선을 지원합니다.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `경고를 검토한 후 스키마를 개선하고 백엔드를 보호하기 위해 지능형 권장사항을 적용합니다.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `API 호출 엔드포인트의 기본 URL을 검토하고 게이트웨이에서 처리하는 도메인을 지정하십시오.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `게이트웨이 유형, 제목, 이름 및 요약을 검토하십시오. API Connect 명령 및 API를 호출할 때 게이트웨이 이름을 사용하십시오.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `스키마를 유효하게 유지하기 위한 추가 변경이 포함될 수 있는 변경 요약을 검토하십시오.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `스키마에서 찾은 경고를 검토하십시오. 편집기에서 나중에 지능형 권장사항으로 스키마 개선과 백엔드 보호를 지원합니다.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `스키마에서 찾은 경고를 검토하십시오. 지능형 권장사항으로 백엔드를 보호하고 스키마를 개선하도록 지원합니다.`,
  [`Reviewed schema, paths and operations`]: `검토된 스키마, 경로 및 오퍼레이션`,
  [`Revocation protocol`]: `취소 프로토콜`,
  [`Revoke token`]: `토큰 취소`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL 문서`,
  [`Role`]: `역할`,
  [`Role default`]: `역할 기본값`,
  [`Role default has been deleted`]: `역할 기본값이 삭제됨`,
  [`Role defaults`]: `역할 기본값`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `역할 기본값은 새 조직이 작성될 때 사용 가능한 기본 역할을 판별하는 데 사용하는 사전 구성된 역할 템플리트입니다.`,
  [`Role has been created`]: `역할이 작성됨`,
  [`Role has been deleted`]: `역할이 삭제됨`,
  [`Role has been updated`]: `역할이 업데이트됨`,
  [`Roles`]: `역할`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `역할(및 지정된 권한)은 API Connect의 기능에 대한 액세스 권한을 제어합니다. 관리자 조직 역할은 Cloud Manager를 사용하는 사용자에게만 적용됩니다.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `역할 및 권한은 API Manager 기능에 대한 액세스를 제어합니다.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `역할 및 연관된 권한은 API Connect에서 태스크를 수행할 수 있는 기능에 대한 액세스를 제어합니다.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `역할 및 연관된 권한은 API Manager UI에서 태스크를 수행할 수 있는 기능에 대한 액세스를 제어합니다.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `역할 및 연관된 권한은 태스크를 수행할 수 있는 기능에 대한 액세스를 제어합니다.`,
  [`Root`]: `루트`,
  [`Root XML Element Name`]: `루트 XML 요소 이름`,
  [`Run the following command in the CLI to register the credentials:`]: `CLI에서 다음 명령을 실행하여 신임 정보를 등록하십시오.`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `CLI에서 다음 명령을 실행하여 툴킷 신임 정보를 등록하십시오.`,
  [`Running`]: `실행 중`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `JWT 복호화에 사용할 JWK가 포함된 런타임 변수입니다.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `JWT 암호화에 사용할 JWK가 포함된 런타임 변수입니다.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `JWT 서명에 사용할 JWK가 포함된 런타임 변수입니다.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `서명 확인에 사용할 JWK가 포함된 런타임 변수입니다.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `JSON 청구의 올바른 JSON 세트를 검색할 수 있는 런타임 변수입니다.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `대상(aud) 청구 문자열을 검색할 수 있는 런타임 변수입니다. 쉼표로 구분된 문자열을 통해 여러 변수를 설정합니다.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `발행자(iss) 청구 문자열을 검색할 수 있는 런타임 변수입니다. 이 청구는 JWT를 발행한 프린시펄을 나타냅니다.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `주제(sub) 청구 문자열을 검색할 수 있는 런타임 변수입니다.`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `생성된 JWT를 배치할 런타임 변수. 설정하지 않으면 JWT가 권한 부여 헤더에 전달자 토큰으로 배치됩니다.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `인증된 사용자 이름이 포함된 런타임 변수입니다. LTPA 토큰은 이 특성을 사용자로 사용하여 생성됩니다. 예를 들어, $(client.app.id) 또는 $(oauth.resource-owner) 또는 변수 설정 정책에 구성된 런타임 변수입니다.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `JWT에 있는 전체 청구 세트가 지정된 런타임 변수입니다.`,
  [`Russian`]: `러시아어`,
  [`Scalar`]: `스칼라`,
  [`Schema`]: `스키마`,
  [`SCHEMA`]: `스키마`,
  [`SCOPE`]: `범위`,
  [`SCOPES`]: `범위`,
  [`SECURITY DEFINITIONS`]: `보안 정의`,
  [`Service`]: `서비스`,
  [`Service state:`]: `서비스 상태:`,
  [`SERVICE TYPE`]: `서비스 유형`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1 라이센스`,
  [`SOURCE`]: `소스`,
  [`SSL Profile`]: `SSL 프로파일`,
  [`State`]: `상태`,
  [`STATUS`]: `상태`,
  [`Status Code`]: `상태 코드`,
  [`STATUS CODE`]: `상태 코드`,
  [`SUBMITTED`]: `제출됨`,
  [`SUBSCRIBERS`]: `등록자`,
  [`Summary`]: `요약`,
  [`Same as product`]: `제품과 동일`,
  [`Sample ID token signing key (RS256)`]: `샘플 ID 토큰 서명 키(RS256)`,
  [`Sample ID token validation key (RS256)`]: `샘플 ID 토큰 유효성 검증 키(RS256)`,
  [`Sample OAuth symmetric key value`]: `샘플 OAuth 대칭 키 값`,
  [`Sample URL: https://api.catalog.org.host/`]: `샘플 URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `샘플 URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `샘플 범위 설명 1`,
  [`Sandbox - Overview`]: `샌드박스 - 개요`,
  [`Sandbox Catalog`]: `샌드박스 카탈로그`,
  [`Sandbox Catalog User Registry`]: `샌드박스 카탈로그 사용자 레지스트리`,
  [`Sandbox Default TLS client profile`]: `샌드박스 기본 TLS 클라이언트 프로파일`,
  [`Sandbox Default TLS Client Profile`]: `샌드박스 기본 TLS 클라이언트 프로파일`,
  [`Sandbox catalog should always exist in a provider organization.`]: `샌드박스 카탈로그는 제공자 조직에 항상 있어야 합니다.`,
  [`Save`]: `저장`,
  [`Save API as a new version`]: `API를 새 버전으로 저장`,
  [`Save Product as a new version`]: `제품을 새 버전으로 저장`,
  [`Save and Edit`]: `저장 및 편집`,
  [`Save and republish`]: `저장 및 재공개`,
  [`Save as a new version`]: `새 버전으로 저장`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `클라이언트 시크릿을 저장합니다(보안 목적으로 더 이상 검색할 수 없습니다).`,
  [`Save this product as a new version`]: `이 제품을 새 버전으로 저장`,
  [`Saved`]: `저장됨`,
  [`Saving assembly...`]: `어셈블리 저장 중...`,
  [`Saving...`]: `저장 중...`,
  [`Schema Type`]: `스키마 유형`,
  [`Schema as JSON`]: `JSON 스키마`,
  [`Schema as XML`]: `XML 스키마`,
  [`Schema as YAML`]: `YAML 스키마`,
  [`Schema change`]: `스키마 변경`,
  [`Schema changes`]: `스키마 변경`,
  [`Schema definition circular reference limit`]: `스키마 정의 순환 참조 한계`,
  [`Schema name`]: `스키마 이름`,
  [`Schema warnings`]: `스키마 경고`,
  [`Schema definition`]: `스키마 정의`,
  [`Scheme`]: `스킴`,
  [`Schemes`]: `스킴`,
  [`Scope`]: `범위`,
  [`Scopes`]: `범위`,
  [`Scopes cannot be empty`]: `범위가 비어 있을 수 없음`,
  [`Search`]: `검색`,
  [`Search DN`]: `검색 DN`,
  [`Search in the field above above and select to add provider organizations`]: `위의 필드에서 검색하고 제공자 조직을 추가하도록 선택하십시오.`,
  [`Search Limit`]: `검색 한계`,
  [`Search Organization owner from`]: `조직 소유자 검색`,
  [`Search orgs/groups`]: `조직/그룹 검색`,
  [`Search Owner`]: `소유자 검색`,
  [`Search assemblies`]: `어셈블리 검색`,
  [`Search for`]: `검색 대상`,
  [`Search for provider organizations`]: `제공자 조직 검색`,
  [`Search for Owner`]: `소유자 검색`,
  [`Search for a new member to become the new owner.`]: `새 소유자가 될 새 구성원을 검색하십시오.`,
  [`Search for an existing member to become the new owner.`]: `새 소유자가 될 기존 구성원을 검색하십시오.`,
  [`Search for new owner`]: `새 소유자 검색`,
  [`Search for owner from:`]: `소유자 검색 위치:`,
  [`Search for the member`]: `구성원 검색`,
  [`Search for title or name or version`]: `제목 또는 이름 또는 버전 검색`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `구성원으로 추가하려는 사용자를 검색하고 필수 역할을 지정하십시오.`,
  [`Search overflow`]: `검색 오버플로우`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `구성원으로 추가하려는 기존 사용자의 레지스트리를 검색하고 필수 역할을 지정하십시오.`,
  [`Search types/fields`]: `검색 유형/필드`,
  [`Search user`]: `사용자 검색`,
  [`Search schema`]: `스키마 검색`,
  [`Seconds`]: `초`,
  [`Secret Key`]: `비밀 키`,
  [`Secret Name`]: `시크릿 이름`,
  [`Secret Required`]: `시크릿 필수`,
  [`Secret key`]: `비밀 키`,
  [`Secure`]: `보안`,
  [`Secure connection`]: `보안 연결`,
  [`Secure using Client ID`]: `클라이언트 ID를 사용하여 보안`,
  [`Security`]: `보안`,
  [`Security & Product Definitions`]: `보안 및 제품 정의`,
  [`Security definitions`]: `보안 정의`,
  [`Security and Publish`]: `보안 설정 및 공개`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `보안 정의는 API 키 유효성 검증, 지정된 사용자 레지스트리를 통한 기본 인증 및 OAuth를 포함하여 API 엔드포인트에 대한 클라이언트 액세스를 제어합니다.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `보안 정의는 API 키 유효성 검증, 지정된 사용자 레지스트리를 통한 기본 인증 및 OAuth를 포함하여 API 엔드포인트에 대한 클라이언트 액세스를 제어합니다. [추가 학습]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `여기서 선택된 보안 정의가 전체 API에서 적용되나 개별 오퍼레이션에 대해 대체될 수 있습니다.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `여기서 선택된 보안 정의가 전체 API에서 적용되나 개별 오퍼레이션에 대해 대체될 수 있습니다. [추가 학습]({link})`,
  [`Security object to use to decode the claim.`]: `청구 디코딩에 사용할 보안 오브젝트입니다.`,
  [`Security object used to encrypt the JWT.`]: `JWT 암호화에 사용된 보안 오브젝트입니다.`,
  [`Security object used to sign the JWT.`]: `JWT 서명에 사용된 보안 오브젝트입니다.`,
  [`Security object used to verify the claim.`]: `청구 확인에 사용된 보안 오브젝트입니다.`,
  [`Select APIs to add to this product`]: `이 제품에 추가할 API 선택`,
  [`Select API Type`]: `API 유형 선택`,
  [`Select API type`]: `API 유형 선택`,
  [`Select a billing integration in the Product plans.`]: `제품 플랜에서 청구 통합을 선택하십시오.`,
  [`Select a flow...`]: `플로우 선택...`,
  [`Select a plan from your existing product`]: `기존 제품에서 플랜 선택`,
  [`Select a policy file to import to this catalog`]: `이 카탈로그에 가져올 정책 파일 선택`,
  [`Select a policy implementation file to import to this catalog`]: `이 카탈로그에 가져올 정책 구현 파일 선택`,
  [`Select a product`]: `제품 선택`,
  [`Select Certificate Manager service`]: `인증서 관리자 서비스 선택`,
  [`Select Cloud`]: `클라우드 선택`,
  [`Select Email Server`]: `이메일 서버 선택`,
  [`Select gateway services`]: `게이트웨이 서비스 선택`,
  [`Select Import Type`]: `가져오기 유형 선택`,
  [`Select product`]: `제품 선택`,
  [`Select product type`]: `제품 유형 선택`,
  [`Select Service`]: `서비스 선택`,
  [`Select Service Type`]: `서비스 유형 선택`,
  [`Select target endpoints`]: `대상 엔드포인트 선택`,
  [`Select target service`]: `대상 서비스 선택`,
  [`Select user registry type`]: `사용자 레지스트리 유형 선택`,
  [`Select a WSDL service from the imported file`]: `가져온 파일에서 WSDL 서비스 선택`,
  [`Select a certificate`]: `인증서 선택`,
  [`Select a Certificate Manager service`]: `인증서 관리자 서비스 선택`,
  [`Select a cryptographic algorithm.`]: `암호화 알고리즘 선택`,
  [`Select a default catalog and organization`]: `기본 카탈로그 및 조직 선택`,
  [`Select a different cloud`]: `다른 클라우드 선택`,
  [`Select a key encryption algorithm.`]: `키 암호화 알고리즘을 선택하십시오.`,
  [`Select a plan to migrate`]: `마이그레이션할 플랜 선택`,
  [`Select a plan to replace`]: `대체할 플랜 선택`,
  [`Select a plan to supersede`]: `대체할 플랜 선택(이전 플랜 사용되지 않음)`,
  [`Select a product to set as the migration target`]: `마이그레이션 대상으로 설정할 제품 선택`,
  [`Select a product to supersede`]: `대체할 제품 선택`,
  [`Select a product to supersede {sourceProductName}:`]: `{sourceProductName}을(를) 대체할 제품 선택:`,
  [`Select a resource group`]: `리소스 그룹 선택`,
  [`Select a service`]: `서비스 선택`,
  [`Select a supported file ({type}) and try again.`]: `지원되는 파일({type})을 선택하고 다시 시도하십시오.`,
  [`Select a target SOAP service`]: `대상 SOAP 서비스 선택`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `다운로드할 툴킷 패키지를 선택하십시오. 툴킷이 설치되어 있는 경우 이 단계를 건너뛰십시오.`,
  [`Select an encryption algorithm.`]: `암호화 알고리즘 선택`,
  [`Select an extension file for this gateway`]: `이 게이트웨이의 확장 파일 선택`,
  [`Select an org`]: `조직 선택`,
  [`Select catalog`]: `카탈로그 선택`,
  [`Select Catalogs to Search`]: `검색할 카탈로그 선택`,
  [`Select compatible gateway services`]: `호환 가능한 게이트웨이 서비스 선택`,
  [`Select one Keystore from the list below:`]: `아래 목록에서 하나의 키 저장소 선택:`,
  [`Select one keystore from the list below:`]: `아래 목록에서 하나의 키 저장소 선택:`,
  [`Select one keystore from the list.`]: `목록에서 하나의 키 저장소를 선택하십시오.`,
  [`Select operation...`]: `오퍼레이션 선택...`,
  [`Select operations you want to include`]: `포함할 오퍼레이션 선택`,
  [`Select organization`]: `조직 선택`,
  [`Select the API definition or package file to import`]: `가져올 API 정의 또는 패키지 파일을 선택합니다`,
  [`Select the billing integration for this catalog`]: `이 카탈로그에 대한 청구 통합 선택`,
  [`Select the billing integration for this product`]: `이 제품에 대한 청구 통합 선택`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `새 카탈로그에서 API를 보호할 게이트웨이를 선택하십시오. 새 게이트웨이를 등록하려면 **게이트웨이** 페이지를 방문하십시오.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `이 게이트웨이에 대한 인증서를 관리하는 [인증서 관리자]({link}) 서비스를 선택하십시오.`,
  [`Select the APIs to add to this product`]: `이 제품에 추가할 API 선택`,
  [`Select the OpenAPI definition file to import`]: `가져올 OpenAPI 정의 파일을 선택합니다`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `승인이 필요한 제품 라이프사이클 조치를 선택하십시오. 선택한 조치가 수행될 때 승인 요청이 생성됩니다. 조치를 완료하려면 요청이 승인되어야 합니다.`,
  [`Select the TLS protocol versions for this profile.`]: `이 프로파일의 TLS 프로토콜 버전을 선택하십시오.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `게이트웨이와 연관시킬 분석 서비스를 선택하십시오.`,
  [`Select the consumer organization in which you want to create the application`]: `애플리케이션을 작성할 이용자 조직을 선택하십시오.`,
  [`Select the consumer organization that will own the application`]: `애플리케이션을 소유할 이용자 조직을 선택합니다.`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `이 API를 공개할 엔드포인트 선택 및 필요에 따라 속도 제한 정의`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `정책 실행 중에 오류가 발생하면 어셈블리 플로우를 중지시키는 오류를 선택하십시오. 오류에 구성된 발견 플로우가 있는 경우 오류 발생을 처리하기 위해 트리거됩니다. 오류가 발생하고 오류 시 중지 설정에 대해 선택된 오류가 없거나 오류 발생이 선택된 오류 중 하나가 아닌 경우 정책 실행은 완료할 수 있도록 허용되고 어셈블리 플로우는 계속 진행합니다.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `제품의 API가 사용 가능하게 될 게이트웨이 서비스를 선택하십시오.`,
  [`Select the gateway type for this API`]: `이 API의 게이트웨이 유형 선택`,
  [`Select the gateway type for this OAuth provider`]: `이 OAuth 제공자의 게이트웨이 유형으로 선택`,
  [`Select the keystores and truststores for this profile.`]: `이 프로파일의 키 저장소 및 신뢰 저장소를 선택하십시오.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `이 제품을 표시할 조직 또는 그룹 선택`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `이 제품에 등록할 조직 또는 그룹 선택`,
  [`Select the portal service to use for this catalog`]: `이 카탈로그에 대해 사용할 포털 서비스 선택`,
  [`Select the product and plan for the application subscription`]: `애플리케이션 등록에 필요한 제품 및 플랜을 선택합니다.`,
  [`Select the product definition file to import`]: `가져올 제품 정의 파일 선택`,
  [`Select the product definition file to import from`]: `가져올 제품 정의 파일 선택`,
  [`Select the product that will supersede the originally selected product`]: `원래 선택한 제품을 대체할 제품 선택`,
  [`Select the product that you want to add the API to`]: `API를 추가할 제품을 선택하십시오.`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `이 카탈로그에서 API에 대한 액세스를 제어하는 데 사용할 레지스트리를 선택하십시오.`,
  [`Select the required operations`]: `필수 오퍼레이션 선택`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `입력 데이터와 관련된 로그 메시지에 대한 심각도 레벨을 선택하십시오. 가능한 레벨은 오류, 경고, 정보입니다.`,
  [`Select the target wsdl file to create from`]: `작성할 대상 wsdl 파일 선택`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `API 정의 작성에 사용할 대상 wsdl, xsd 또는 zip 파일 선택`,
  [`Select the target WSDL file to import`]: `가져올 대상 WSDL 파일을 선택합니다`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `API Manager 사용자를 인증하기 위한 사용자 레지스트리를 선택하거나 새 사용자 레지스트리를 작성하십시오.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `API Manager의 사용자 인증을 위한 사용자 레지스트리를 선택하거나 새 사용자 레지스트리를 작성하십시오.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Cloud Manager의 사용자 인증을 위한 사용자 레지스트리를 선택하거나 새 사용자 레지스트리를 작성하십시오.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `개발자 포털에 대한 로그인을 인증하는 데 사용되는 사용자 레지스트리 선택`,
  [`Select the user registry type`]: `사용자 레지스트리 유형 선택`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `정책에서 생성된 LTPA 토큰을 배치할 출력 소스 내 위치를 선택하십시오.  WSSec 헤더는 요청의 컨텐츠 유형이 application/xml인 경우에만 선택해야 합니다.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `특성을 완전히 제거할지 아니면 데이터를 차단하기 위해 "*"를 사용하여 필드를 수정할지를 선택하십시오.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `선택한 제품이 수정되었습니다. 이 페이지를 새로 고치고 다시 시도하십시오.`,
  [`Selecting operations from WSDL service definition`]: `WSDL 서비스 정의에서 오퍼레이션 선택`,
  [`Self Service Onboarding for Consumer organization`]: `이용자 조직을 위한 셀프서비스 온보딩`,
  [`Self service onboarding`]: `셀프 서비스 온보딩`,
  [`Self service onboarding approval`]: `셀프 서비스 온보딩 승인`,
  [`Send`]: `전송`,
  [`Send Message`]: `메시지 보내기`,
  [`Send Message to Owners`]: `소유자에게 메시지 보내기`,
  [`Send To`]: `받는 사람`,
  [`Send a registration link to a new user to register as owner.`]: `소유자로 등록하도록 새 사용자에게 등록 링크를 전송하십시오.`,
  [`Send a test email`]: `테스트 이메일 발송`,
  [`Send a test email to confirm that the email server is properly configured.`]: `테스트 이메일을 발송하여 이메일 서버가 제대로 구성되었는지 확인하십시오.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `테스트 신호를 보내 엔드포인트가 제대로 구성되었는지 확인하십시오.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `테스트 신호를 보내 엔드포인트가 제대로 구성되었는지 확인`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `관리자 조직 소유자에게 이메일 메시지를 보내십시오. 소유자의 이름 옆에 체크 표시를 배치하십시오.`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `소유자에게 이메일 메시지를 보내십시오. 소유자의 이름 옆에 체크 표시를 배치하십시오.`,
  [`Send chunked-encoded documents to the target server.`]: `대상 서버로 chunked-encoded 문서를 전송합니다.`,
  [`Send message`]: `메시지 보내기`,
  [`Send message to owner`]: `소유자에게 메시지 보내기`,
  [`Send test email`]: `테스트 이메일 발송`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `사용자가 가입하거나 등록하도록 초대하기 위해 활성화 링크를 발송합니다.`,
  [`Send-only`]: `전송 전용`,
  [`Sender`]: `발신인`,
  [`Sender & email server`]: `발신인 및 이메일 서버`,
  [`Sender & email server have been changed`]: `발신인 및 이메일 서버가 변경됨`,
  [`Sender Info`]: `발신인 정보`,
  [`Sent`]: `전송`,
  [`Sent events: **{sent}**`]: `이벤트 전송: **{sent}**`,
  [`Serial`]: `일련 번호`,
  [`Serialize output`]: `출력 직렬화`,
  [`Server connection`]: `서버 연결`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `HTML을 웹 브라우저에 제공하여 GUI GraphQL 클라이언트를 사용으로 설정`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `HTML을 웹 브라우저에 제공하여 GUI GraphQL 클라이언트를 사용으로 설정합니다.`,
  [`Server Name Indication (SNI)`]: `SNI(Server Name Indication)`,
  [`Server URL`]: `서버 URL`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `서버는 OIDC 방향 재지정 URL의 토큰 대신 해시의 오류를 리턴했습니다. 해시:`,
  [`Service name`]: `서비스 이름`,
  [`Services`]: `서비스`,
  [`Set`]: `설정`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `API Designer에 대한 APIC_DESIGNER_CREDENTIALS OS 환경변수를 설정하십시오. 예를 들어 MAC에서 사용자는 API Designer를 실행하는 동안 환경 변수를 전달할 수 있습니다.`,
  [`Set Migration Target`]: `마이그레이션 대상 설정`,
  [`Set migration target`]: `마이그레이션 대상 설정`,
  [`Set Variable`]: `변수 설정`,
  [`Set as default`]: `기본값으로 설정`,
  [`Set default`]: `기본값 설정`,
  [`Set rate and burst limits in an assembly`]: `어셈블리에서 비율 및 버스트 한계를 설정합니다.`,
  [`Set the timeout period for invitations`]: `초대에 대한 제한시간을 설정합니다.`,
  [`Set the trust level for the profile`]: `프로파일의 신뢰 레벨을 설정합니다.`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `허용된 순환 스키마 정의의 반복 수로 값 설정(기본값 1, 최대값 5)`,
  [`Set the visibility for a gateway service`]: `게이트웨이 서비스의 가시성 설정`,
  [`Set the visibility for a portal service`]: `포털 서비스의 가시성 설정`,
  [`Set the visibility for each service`]: `각 서비스의 가시성을 설정합니다.`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `인증 서버가 발행한 새 액세스 토큰을 가져올 수 있도록 시간 범위 설정`,
  [`Set visibility`]: `가시성 설정`,
  [`Set visibility of gateway`]: `게이트웨이 가시성 설정`,
  [`Set time interval`]: `시간 간격 설정`,
  [`Set, Add, or Clear a runtime variable.`]: `런타임 변수를 설정하거나 추가하거나 지우십시오.`,
  [`Set the timeout period for API key`]: `API 키에 대한 제한시간 설정`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `캐시에 있는 문서의 유효 기간(초)을 설정합니다. 캐시 유형이 "TTL(Time to Live)"로 설정된 경우에만 적용됩니다. 5 - 31708800 범위의 값을 입력하십시오. 기본값은 900입니다.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `"assumedSize"의 값 설정은 기본값을 가진 분할 인수 정의 또는 "requireOneSlicingArgument" 사용 설정과 함께 사용할 수 없습니다.`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `이 카탈로그를 기본 카탈로그로 설정하면 카탈로그 이름이 생략된 더 짧은 URL을 사용하여 액세스할 수 있습니다. 한 카탈로그만 기본값으로 설정할 수 있습니다.`,
  [`Settings`]: `설정`,
  [`Setup`]: `설정`,
  [`Severity level for input data log messages`]: `입력 데이터 로그 메시지에 대한 심각도 레벨`,
  [`Share the resource with a custom list of provider organizations`]: `제공자 조직의 사용자 목록과 자원을 공유합니다.`,
  [`Share the resource with provider organizations`]: `제공자 조직과 자원을 공유합니다.`,
  [`Share the API gateway with a custom list of provider organizations`]: `공급자 조직의 사용자 정의 목록과 API 게이트웨이 공유`,
  [`Share the service with a custom list of provider organizations`]: `제공자 조직의 사용자 목록과 서비스를 공유합니다.`,
  [`Share the API gateway with provider organizations`]: `제공자 조직과 API 게이트웨이 공유`,
  [`Share the service with provider organizations`]: `제공자 조직과 서비스를 공유합니다.`,
  [`Share`]: `공유`,
  [`Shared`]: `공유`,
  [`Shared by`]: `공유 대상`,
  [`Short but descriptive name for this policy.`]: `이 정책에 대한 간결하지만 설명이 포함된 이름입니다.`,
  [`Show`]: `표시`,
  [`Show / hide policy palette`]: `정책 팔레트 표시 / 숨기기`,
  [`Show/hide`]: `표시/숨기기`,
  [`Show/hide settings`]: `설정 표시/숨기기`,
  [`Show and hide types and fields to include in the schema.`]: `스키마에 포함시킬 유형 및 필드를 표시하고 숨깁니다.`,
  [`Show added only`]: `추가 항목만 표시`,
  [`Show all`]: `모두 표시`,
  [`Show all UDP versions`]: `모든 UDP 버전 표시`,
  [`Show app identifier`]: `앱 ID 표시`,
  [`Show breaking changes only`]: `중단된 변경 항목만 표시`,
  [`Show catalog identifier`]: `카탈로그 ID 표시`,
  [`Show catches`]: `발견 표시`,
  [`Show in schema`]: `스키마에 표시`,
  [`Show less`]: `간단히 보기`,
  [`Show modified only`]: `수정 항목만 표시`,
  [`Show more`]: `자세히 보기`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `새 유형. 필드, 지시문 또는 선택적 인수를 표시합니다. 백엔드에서 더 이상 지원하지 않는 항목은 계속 제거됩니다.`,
  [`Show removed only`]: `제거 항목만 표시`,
  [`Show schema`]: `스키마 표시`,
  [`Show warnings only`]: `경고만 표시`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `현재 지정된 키 저장소를 제외하고 키 저장소 지정 히스토리를 표시합니다.`,
  [`Sign Crypto Object`]: `암호 오브젝트 서명`,
  [`Sign in`]: `로그인`,
  [`Sign JWK variable name`]: `JWK 변수 이름 서명`,
  [`Sign Out`]: `로그아웃`,
  [`Sign up`]: `등록`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `{userRegistryType} 사용자 레지스트리를 사용하여 로그인`,
  [`Sign in with`]: `로그인`,
  [`Sign out`]: `로그아웃`,
  [`Sign up with`]: `가입`,
  [`Signed token`]: `서명된 토큰`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `버퍼링이 꺼짐으로 설정되었으므로 컨텐츠 및/또는 오류 컨텐츠를 페이로드에서 헤더로 줄여야 합니다. 필수 변경을 계속하거나 최신 변경을 취소하시겠습니까?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `컨텐츠 및/또는 오류 컨텐츠가 페이로드로 설정되었으므로 버퍼링을 켜짐으로 설정해야 합니다. 필수 변경을 계속하거나 최신 변경을 취소하시겠습니까?`,
  [`Sized fields`]: `크기가 조정된 필드`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `분할 인수`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `일부 입력이 배열이거나 배열 내에 포함됩니다. 컨텐츠를 반복하려면 반복 옵션을 선택하고 반복할 입력을 선택하십시오.`,
  [`Something went wrong.`]: `문제가 발생했습니다.`,
  [`Something went wrong...`]: `문제가 발생했습니다.`,
  [`Source`]: `소스`,
  [`Source schema`]: `소스 스키마`,
  [`Space`]: `공간`,
  [`Space is not allowed in scope name`]: `공간은 범위 이름에서 허용되지 않음`,
  [`Space details`]: `공간 세부사항`,
  [`Space Enabled`]: `공간 사용`,
  [`Space Name`]: `공간 이름`,
  [`Space Summary`]: `공간 요약`,
  [`Space Title`]: `공간 제목`,
  [`Space owner`]: `공간 소유자`,
  [`Space {name} created`]: `{name} 공간이 작성됨`,
  [`Spaces`]: `공간`,
  [`Spaces are enabled`]: `공간 사용`,
  [`Spaces are not enabled`]: `공간을 사용할 수 없음`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `공간은 샌드박스 카탈로그에 사용할 수 없습니다.`,
  [`Spaces cannot be enabled.`]: `공간을 사용으로 설정할 수 없습니다.`,
  [`Spaces dropdown`]: `공간 드롭 다운`,
  [`Spaces has been enabled`]: `공백이 사용됨`,
  [`Spaces have been enabled.`]: `공간이 사용으로 설정되었습니다.`,
  [`Spaces per page`]: `페이지당 공간`,
  [`Spanish`]: `스페인어`,
  [`Special characters are not accepted`]: `특수 문자는 허용되지 않음`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `동적 오브젝트로 병합되는 직렬화된 XML 또는 JSON 특성을 검색할 이름 지정된 컨텍스트를 나타내는 URL 또는 문자열을 지정합니다. 이 특성은 기존 리터럴 또는 기본 특성보다 우선합니다.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `동적 오브젝트로 병합되는 직렬화된 XML 또는 JSON 특성으로 리터럴 문자열을 지정합니다. 이 특성은 기존 기본 특성보다 우선합니다.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `동적 오브젝트에 대한 기본 특성 값을 검색할 기존의 올바른 오브젝트를 지정합니다. 경고: 기본 문자열을 변경한 경우, DataPower 어플라이언스에서 지정된 구문 분석 설정 오브젝트가 사용 가능해야 합니다.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `수정하거나 제거할 컨텐츠가 포함된 데이터 소스를 지정합니다. 루트가 지정되지 않으면 조치가 전체 API 컨텍스트에 적용됩니다. 지원되는 JSONata 경로 표현식을 사용할 수 있습니다.\n\n"message.body"를 루트로 사용하여 요청 및 응답 데이터의 수정을 수행할 수 있습니다.수집 전용 모드를 지정하는 어셈블리 로그 조치 후에 어셈블리 수정 조치가 어셈블리에서 사용된 경우 루트가 로그된 요청 페이로드에는 "log.request_body", 로그된 응답 페이로드에는 "log.response_body"를 지정할 수 있습니다.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `API 컨텍스트에서 변수의 이름을 지정합니다. 변수의 "body" 필드 컨텐츠는 조치에 대한 입력입니다. 기본 변수 이름은 "message"입니다.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `API 컨텍스트에서 변수의 이름을 지정합니다. 변수의 "body" 필드 컨텐츠는 구문 분석에 대한 출력입니다. 구문 분석된 문서의 구문 분석 메트릭은 메시지의 다른 파트에 저장됩니다. 기본 변수 이름은 어떠한 입력 이름이라도 가능하며 기본으로 입력 메시지가 출력 메시지로 대체됩니다.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `액세스 토큰에 대한 사용자 정의 메타데이터를 얻는지 여부 및 얻는 위치를 지정합니다.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `지정된 컨텐츠 유형으로 구문 분석을 시도하는지 여부를 지정합니다. 예상되는 컨텐츠 유형이 json 또는 xml인 경우에만 적용 가능합니다. 컨텐츠 유형이 구문 분석 설정과 일치하지 않는 경우(예: Content-type:application/json이며 구문 분석 설정이 xml용으로 구성된 경우), 이 옵션을 사용으로 설정하면 구문 분석 조치가 실패합니다. 구문 분석 설정이 입력 컨텐츠를 발견하도록 구성된 경우, 지정된 컨텐츠 유형으로 자동 설정됩니다.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `변환 후 XSLT 출력 트리를 2진 데이터로 직렬화할지 여부를 지정합니다.`,
  [`Specify default gateways`]: `기본 게이트웨이 지정`,
  [`Specify email`]: `이메일 지정`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `페이로드를 유효성 검증하는 데 사용할 OpenAPI(Swagger) 스키마 정의를 지정하십시오("request", "response" 또는 "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `이용자 조직의 소유자 지정`,
  [`Specify owner of the provider organization`]: `제공자 조직의 소유자를 지정합니다.`,
  [`Specify settings for the third party OAuth provider.`]: `써드파티 OAuth 제공자에 대한 설정을 지정합니다.`,
  [`Specify the MIME types that your API consumes and produces`]: `사용자 API에서 이용하고 생성하는 MIME 유형을 지정하십시오.`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `카탈로그 소유자를 지정하십시오. 소유자는 모든 카탈로그 권한을 가집니다.`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `개발자 포털에서 제품을 볼 수 있는 개발자 커뮤니티 또는 이용자 조직을 지정하십시오.`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `제품에서 API를 사용할 수 있도록 등록할 수 있는 개발자 커뮤니티 또는 이용자 조직을 지정하십시오.`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `API 호출 엔드포인트의 기본 URL 및 게이트웨이에서 처리하는 도메인을 지정하십시오.`,
  [`Specify the details of the catalog that you want to publish to`]: `공개할 카탈로그의 세부사항을 지정하십시오.`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `새 카탈로그에서 API를 보호할 기본 게이트웨이 세트를 지정하십시오. [추가 학습]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `이 API가 공개될 엔드포인트 및 API 호출의 비율을 제한할 것인지 여부를 지정하십시오.`,
  [`Specify the new owner`]: `새 소유자 지정`,
  [`Specify the owner`]: `소유자 지정`,
  [`Specify the owner and title`]: `소유자 및 제목 지정`,
  [`Specify the properties to redact or remove.`]: `수정하거나 제거할 특성을 지정하십시오.`,
  [`Specify the publish and rate limiting requirements for this API`]: `이 API에 대한 공개 및 속도 제한 요구사항을 지정하십시오.`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `정의된 범위 외에 추가 범위 확인이 수행되는 범위 확인 엔드포인트를 지정하십시오.`,
  [`Specify the security settings for the imported API definition`]: `가져온 API 정의에 대한 보안 설정 지정`,
  [`Specify the security settings for your API definition`]: `API 정의에 대한 보안 설정 지정`,
  [`Specify the space owner; the owner has all space permissions`]: `공간 소유자를 지정하십시오. 소유자는 모든 공간 권한을 가집니다.`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `대상 SOAP 서비스 및 API 요약 세부사항 지정: 필요한 경우, API 정의를 작성한 후에 추가적으로 구성할 수 있습니다.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `사용자가 계정을 활성화하지 않은 경우 초대 이메일이 만료되는 제한시간을 지정하십시오.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `사용자가 계정을 활성화하지 않은 경우 초대 이메일이 만료되는 제한시간을 지정하십시오.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `게이트웨이 유형, 제목 및 게이트웨이를 설명하는 요약을 지정하십시오.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `API Connect 제공자 조직에 대한 API 게이트웨이의 가시성을 지정하십시오.`,
  [`Specify the way you want to validate the message.`]: `메시지 유효성 검증 방법을 지정하십시오.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `게이트웨이 관리 엔드포인트의 URL과 엔드포인트의 도메인에 대한 인증서를 지정하십시오.`,
  [`Specify what content to log in the event of an error.`]: `오류 발생 시 로그할 컨텐츠를 지정하십시오.`,
  [`Specify what content to log.`]: `로그할 컨텐츠를 지정하십시오.`,
  [`Specifying the publish target details`]: `공개 대상 세부사항 지정`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `특히 xsi:type=SOAP-ENC:Array 규칙 허용`,
  [`Stage`]: `스테이지`,
  [`Stage API`]: `API 스테이징`,
  [`Stage product`]: `제품 스테이징`,
  [`Stage to`]: `스테이징 대상`,
  [`Staged`]: `스테이징됨`,
  [`Stage|permission`]: `스테이지`,
  [`Standard OIDC`]: `표준 OIDC`,
  [`Static DNS`]: `정적 DNS`,
  [`Static group`]: `정적 그룹`,
  [`Status`]: `상태`,
  [`Status code`]: `상태 코드`,
  [`Step 1`]: `1단계`,
  [`Step 1: Download`]: `1단계: 다운로드`,
  [`Step 1: Download toolkit`]: `1단계: 툴킷 다운로드`,
  [`Step 1: Upload private key`]: `1단계: 개인 키 업로드`,
  [`Step 2`]: `2단계`,
  [`Step 2: Download credentials`]: `2단계: 신임 정보 다운로드`,
  [`Step 2: Download toolkit credentials`]: `2단계: 툴킷 신임 정보 다운로드`,
  [`Step 2: Install`]: `2단계: 설치`,
  [`Step 2: Upload public key`]: `2단계: 공개 키 업로드`,
  [`Step 3`]: `3단계`,
  [`Step 3: Configure`]: `3단계: 구성`,
  [`Step 3: Run command`]: `3단계: 명령 실행`,
  [`Step 3: Run commands`]: `3단계: 명령 실행`,
  [`Step 4: Register`]: `4단계: 등록`,
  [`Stop`]: `중지`,
  [`Stop after:`]: `중지 시간:`,
  [`Stop all the retries`]: `모든 재시도 중지`,
  [`Stop Connection`]: `연결 중지`,
  [`Stop on error`]: `오류 시 중지`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `이 API 실행을 중지하고 샌드박스 카탈로그에서 제거합니다.`,
  [`Stopped`]: `중지됨`,
  [`Stopping Published API`]: `공개된 API 중지`,
  [`Strict`]: `엄격함`,
  [`Strict SOAP envelope version`]: `엄격한 SOAP 엔벨로프 버전`,
  [`String Badgerfish`]: `문자열 Badgerfish`,
  [`String`]: `문자열`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `API 호출을 위해 URL의 조직 세그먼트에 포함된 문자열입니다. 소문자 알파벳, 숫자 및 하이픈을 사용합니다.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Stripe 통합`,
  [`Streaming rule`]: `스트리밍 규칙`,
  [`Subject`]: `주제`,
  [`Subject Claim`]: `주제 청구`,
  [`Subject Origin`]: `제목 기점`,
  [`Subject common name`]: `제목 공통 이름`,
  [`Subject|certificate`]: `주제`,
  [`Submit`]: `제출`,
  [`Submitted`]: `제출됨`,
  [`Subscribability`]: `등록 가능성`,
  [`Subscribability must be disabled if visibility is disabled`]: `가시성이 사용 안함으로 설정된 경우 등록 가능성이 사용 안함으로 설정되어야 함`,
  [`Subscribability options`]: `등록 가능성 옵션`,
  [`Subscribable by`]: `등록 가능 대상`,
  [`Subscribe`]: `등록`,
  [`Subscribe Financial Management App`]: `재무 관리 앱 등록`,
  [`Subscribe TODO App`]: `TODO 앱 등록`,
  [`Subscription`]: `등록`,
  [`Subscription approval task for subscription of`]: `등록을 위한 등록 승인 태스크`,
  [`Subscription created`]: `등록 작성`,
  [`Subscription has been added`]: `등록이 추가됨`,
  [`Subscription has not been added`]: `등록이 추가되지 않음`,
  [`Subscription pending approval`]: `등록 승인 보류 중`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `GraphQL 구독이 지원되지 않으므로 구독 유형이 제거되었습니다.`,
  [`Subscription-Approval`]: `등록 승인`,
  [`Subscriptions`]: `등록`,
  [`Success`]: `성공`,
  [`Suffix`]: `접미부`,
  [`Suggestion`]: `제안`,
  [`Supersede`]: `대체`,
  [`Supersede Product`]: `제품 대체`,
  [`Supersede product`]: `제품 대체`,
  [`Supersede plans`]: `플랜 대체`,
  [`Supersede {product1} with {product2}`]: `{product1}을(를) {product2}(으)로 대체`,
  [`Supersede|permission`]: `대체`,
  [`Supersede|supersede with`]: `대체`,
  [`Support`]: `지원`,
  [`support default introspection`]: `기본 검사 지원`,
  [`Support Server Name Indication (SNI)`]: `SNI(Server Name Indication) 지원`,
  [`Support hybrid response types`]: `혼합 응답 유형 지원`,
  [`Support standard introspection`]: `표준 검사 지원`,
  [`Supported OAuth components`]: `지원되는 OAuth 구성요소`,
  [`Supported client types`]: `지원되는 클라이언트 유형`,
  [`Supported client types can not be empty.`]: `지원되는 클라이언트 유형은 비어 있을 수 없습니다.`,
  [`Supported grant type can not be empty.`]: `지원되는 권한 부여 유형은 비어 있을 수 없습니다.`,
  [`Supported grant types`]: `지원되는 권한 부여 유형`,
  [`Suspend`]: `일시중단`,
  [`Suspend API`]: `API 일시중단`,
  [`Suspend Application`]: `애플리케이션 일시중단`,
  [`Switch`]: `전환`,
  [`Switch account`]: `계정 전환`,
  [`Switch cloud connection`]: `클라우드 연결 전환`,
  [`Switch directory`]: `디렉토리 전환`,
  [`TARGET`]: `대상`,
  [`Title`]: `제목`,
  [`TlS`]: `TLS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS 클라이언트`,
  [`TLS Client Profile`]: `TLS 클라이언트 프로파일`,
  [`TLS Client Profile has been configured.`]: `TLS 클라이언트 프로파일이 구성되었습니다.`,
  [`TLS client profile is required when endpoint starts with https://`]: `엔드포인트가 https://로 시작되는 경우 TLS 클라이언트 프로파일이 필요합니다.`,
  [`TLS Client Profiles`]: `TLS 클라이언트 프로파일`,
  [`TLS client profile has been configured.`]: `TLS 클라이언트 프로파일이 구성되었습니다.`,
  [`TLS client profiles`]: `TLS 클라이언트 프로파일`,
  [`TLS Profile`]: `TLS 프로파일`,
  [`TLS SERVER PROFILE`]: `TLS 서버 프로파일`,
  [`TLS server profile`]: `TLS 서버 프로파일`,
  [`TLS version 1.0`]: `TLS 버전 1.0`,
  [`TLS version 1.1`]: `TLS 버전 1.1`,
  [`TLS version 1.2`]: `TLS 버전 1.2`,
  [`TLS version 1.3`]: `TLS 버전 1.3`,
  [`TLS client profile`]: `TLS 클라이언트 프로파일`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `TLS 클라이언트 프로파일은 공간이 사용 가능한 경우 각 공간에서 관리됩니다. 공간 설정으로 이동하여 TLS 클라이언트 프로파일을 관리하십시오.`,
  [`TLS profile`]: `TLS 프로파일`,
  [`TLS profile is not visible or not available.`]: `TLS 프로파일이 보이지 않거나 사용할 수 없습니다.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `인증 URL에 대한 데이터 보안 전송에 사용할 TLS 프로파일입니다.`,
  [`Tabs for code language options`]: `코드 언어 옵션 탭`,
  [`Truststore`]: `신뢰 저장소`,
  [`truststore`]: `신뢰 저장소`,
  [`Type`]: `유형`,
  [`Type name`]: `유형 이름`,
  [`Type/Field`]: `유형/필드`,
  [`Type weight`]: `유형 가중치`,
  [`Types per page:`]: `페이지당 유형:`,
  [`Tags`]: `태그`,
  [`Tags and external documentation`]: `태그 및 외부 문서`,
  [`Take offline`]: `오프라인으로 설정`,
  [`Target`]: `대상`,
  [`Target API (URL)`]: `대상 API(URL)`,
  [`Target SOAP service`]: `대상 SOAP 서비스`,
  [`Target Services`]: `대상 서비스`,
  [`Target service URL`]: `대상 서비스 URL`,
  [`Target services`]: `대상 서비스`,
  [`Target URL`]: `대상 URL`,
  [`Target application`]: `대상 애플리케이션`,
  [`Target catalog`]: `대상 카탈로그`,
  [`Target gateway service`]: `대상 게이트웨이 서비스`,
  [`Target gateway services`]: `대상 게이트웨이 서비스`,
  [`Target plan`]: `대상 플랜`,
  [`Target product`]: `대상 제품`,
  [`Target product rate limit`]: `대상 제품 비율 한계`,
  [`Target product subscribability`]: `대상 제품 구독성`,
  [`Target product visibility`]: `대상 제품 가시성`,
  [`Target schema`]: `대상 스키마`,
  [`Target services have been updated.`]: `대상 서비스가 업데이트되었습니다.`,
  [`Task`]: `태스크`,
  [`Task has been updated`]: `태스크가 업데이트됨`,
  [`Task self approval`]: `태스크 자체 승인`,
  [`Task self-approval`]: `태스크 자체 승인`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `태스크`,
  [`Tasks for creating new provider organizations`]: `새 제공자 조직을 작성하기 위한 태스크`,
  [`TCP`]: `TCP`,
  [`Template`]: `템플리트`,
  [`Template: {title}`]: `템플리트: {title}`,
  [`Template: account approved`]: `템플리트: 계정 승인됨`,
  [`Template: account denied`]: `템플리트: 계정 거부됨`,
  [`Template: account pending approval`]: `템플리트: 계정 승인 보류 중`,
  [`Template: app lifecycle approved`]: `템플리트: 앱 라이프사이클 승인됨`,
  [`Template: app lifecycle denied`]: `템플리트: 앱 라이프사이클 거부됨`,
  [`Template: app lifecycle pending`]: `템플리트: 앱 라이프사이클 보류 중`,
  [`Template: app reinstated`]: `템플리트: 앱 복원됨`,
  [`Template: app suspended`]: `템플리트: 앱 일시중단됨`,
  [`Template: app lifecycle cancelled`]: `템플리트: 앱 라이프사이클 취소됨`,
  [`Template: app lifecycle request`]: `템플리트: 앱 라이프사이클 요청`,
  [`Template: invitation`]: `템플리트: 초대`,
  [`Template: mail server test connection`]: `템플리트: 메일 서버 테스트 연결`,
  [`Template: member invitation`]: `템플리트: 멤버 초대`,
  [`Template: password changed`]: `템플리트: 비밀번호 변경됨`,
  [`Template: password reset`]: `템플리트: 비밀번호 재설정`,
  [`Template: sign up`]: `템플리트: 가입`,
  [`Template: task consumer onboard request`]: `템플리트: 태스크 소비자 온보드 요청`,
  [`Template: task product lifecycle approved`]: `템플리트: 태스크 제품 라이프사이클 승인됨`,
  [`Template: task product lifecycle cancelled`]: `템플리트: 태스크 제품 라이프사이클 취소됨`,
  [`Template: task product lifecycle denied`]: `템플리트: 태스크 제품 라이프사이클 거부됨`,
  [`Template: task product lifecycle pending`]: `템플리트: 태스크 제품 라이프사이클 보류 중`,
  [`Template: task product lifecycle request`]: `템플리트: 태스크 제품 라이프사이클 요청`,
  [`Template: task subscription cancelled`]: `템플리트: 태스크 등록 취소됨`,
  [`Template: task subscription request`]: `템플리트: 태스크 등록 요청`,
  [`Template: task subscription approved`]: `템플리트: 태스크 등록 승인됨`,
  [`Template: task subscription denied`]: `템플리트: 태스크 등록 거부됨`,
  [`Template: task subscription pending`]: `템플리트: 태스크 등록 보류 중`,
  [`Temporarily disable subscribability`]: `등록 가능성을 임시로 사용 안함`,
  [`Temporarily disable visibility`]: `가시성을 임시로 사용 안함`,
  [`Terms of service`]: `이용 약관`,
  [`Test`]: `테스트`,
  [`Test API`]: `테스트 API`,
  [`Test APIs`]: `테스트 API`,
  [`Test Bind & Get Base DN`]: `바인드 테스트 및 기본 DN 가져오기`,
  [`Test configuration`]: `구성 테스트`,
  [`Test connection`]: `연결 테스트`,
  [`Test connection failed.`]: `연결 테스트에 실패했습니다.`,
  [`Test and production endpoints`]: `테스트 및 프로덕션 엔드포인트`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `테스트 및 모니터 응답 오류: redirect_uri 특성이 필요하며 {redirectUri} 발생함`,
  [`Test application`]: `테스트 애플리케이션`,
  [`Test consumer org`]: `테스트 이용자 조직`,
  [`Test email`]: `이메일 테스트`,
  [`Test endpoints`]: `테스트 엔드포인트`,
  [`Test message from IBM API Connect`]: `IBM API Connect의 테스트 메시지`,
  [`Testable`]: `테스트 가능`,
  [`TEXT`]: `텍스트`,
  [`The "Definition" property must have a valid value.`]: `"Definition" 특성에는 올바른 값이 있어야 합니다.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `"Definition" 특성이 "request", "response" 값을 갖거나 "#/definitions/"로 시작해야 합니다.`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `API Connect CLI는 IBM Cloud CLI 플러그인으로 제공됩니다. 시작하려면 다음을 수행하십시오.`,
  [`The API has unsaved changes.`]: `API에 저장하지 않은 변경사항이 있습니다.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `API가 완전히 디자인되고 내부 마일스톤을 전달했지만 아직 구현되지 않았습니다.`,
  [`The API is in the implementation phase.`]: `API는 구현 단계에 있습니다.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `API는 초기 개념 단계에 있으며 완전히 디자인되거나 구현되지 않았습니다.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `API에 공개되지 않은 변경사항이 있을 수 있습니다. 제품을 재공개하시겠습니까?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `DNS 스킴은 게이트웨이 서비스에 대한 인바운드 API 호출 및 개발자 포털 액세스를 위한 엔드포인트 URL의 컴포지션을 판별합니다.`,
  [`The GatewayScript source code to execute.`]: `실행할 GatewayScript 소스 코드입니다.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `GraphQL 페이로드를 유효성 검증하는 데 사용해야 하는 GraphQL 스키마 URL입니다.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `호출에 사용할 HTTP 메소드입니다. 생략되거나 "유지"로 설정되면 수신 요청의 메소드가 사용됩니다.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `현재 HTTP 메소드를 대체하는 데 사용할 HTTP 메소드입니다. 생략되거나 "유지"로 설정되면 수신 요청의 메소드가 사용됩니다.`,
  [`The JavaScript source code to execute.`]: `실행할 JavaScript 소스 코드입니다.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `LDAP(Lightweight Directory Access Protocol)은 네트워크를 통한 분산 디렉토리 정보 서비스 액세스 및 유지보수를 위한 인터넷 프로토콜입니다. 엔터프라이즈 LDAP 서비스가 사용으로 설정되어 있는 경우, 이를 자원으로 구성하여 사용자 인증을 제공하십시오.`,
  [`The Proxy URL to be used.`]: `사용할 프록시 URL입니다.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `데이터의 보안 전송에 사용할 SSL 프로파일입니다.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `데이터의 보안 전송에 사용할 TLS 프로파일입니다.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `JSON 페이로드의 유효성을 검증하는 데 사용할 JSON 스키마의 URL입니다.`,
  [`The URL of the XML schema for validating an XML payload.`]: `XML 페이로드의 유효성을 검증하는 데 사용할 XML 스키마의 URL입니다.`,
  [`The URL to be invoked.`]: `호출할 URL입니다.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `SOAP 페이로드의 유효성을 검증하는 데 사용할 WSDL 스키마 URL입니다.`,
  [`The XML element name to be used for JSON array elements.`]: `JSON 배열 요소에 사용될 XML 요소 이름입니다.`,
  [`The XSLT source to execute.`]: `실행할 XSLT 소스입니다.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `사용자가 계정을 활성화할 수 있도록 사용자에게 활성화 링크가 발송됩니다.`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `분석 서비스는 게이트웨이 서비스에서 API 이벤트를 수집합니다. 각 게이트웨이에는 연관된 분석 서비스가 있습니다. 시간소인이 포함된 API 이벤트는 API 오퍼레이션이 호출될 때마다 로그됩니다.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `분석 서비스는 게이트웨이 서비스에서 API 이벤트를 수집합니다. 각 게이트웨이에는 연관된 분석 서비스가 있을 수 있습니다.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `권한 URL은 클라이언트 애플리케이션이 권한 부여를 얻는 위치입니다. 토큰 URL은 클라이언트 애플리케이션이 액세스 토큰에 대한 권한 부여를 교환하는 위치입니다.  자체 검사 URL은 API 게이트웨이가 써드파티 제공자에 의해 발행된 액세스 토큰의 유효성 검증을 수행하는 위치입니다.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `기본 경로는 API의 초기 URL 세그먼트이며, 경로 또는 오퍼레이션을 위한 추가 세그먼트 또는 호스트 이름을 포함하지 않습니다.`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `캐시 유형은 서버의 응답에서 수신된 HTTP 캐시 제어 지시문을 따르거나 오버라이드하여 문서 캐시 여부를 결정합니다. 이 특성은 서버로부터 응답을 수신하는 경우에만 적용되고 그렇지 않은 경우에는 정책이 항상 캐시에 이전에 저장된 만료되지 않은 응답을 리턴합니다.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `인증서 관리자 서비스는 게이트웨이 관리 엔드포인트의 도메인과 게이트웨이가 처리하는 도메인에 대한 인증서를 관리해야 합니다. 또한 IBM Cloud IAM을 사용하여 API Connect 예약된 인스턴스 서비스가 인증서 관리자 서비스에 액세스할 수 있도록 권한을 부여해야 합니다.`,
  [`The certificate with which to decrypt the buffer`]: `버퍼를 복호화하는 인증서입니다.`,
  [`The certificate with which to encrypt the buffer`]: `버퍼를 암호화하는 인증서입니다.`,
  [`The change is critical`]: `변경은 중요합니다.`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `클라이언트 애플리케이션은 사용자가 여기에서 정의하는 범위 또는 범위의 서브세트만 요청할 수 있습니다. 범위는 제공자로부터 생성된 액세스 토큰에 포함됩니다. OAuth 보호 API가 호출되면 게이트웨이가 보안 정의 내에서 허용되는 범위의 목록에 대해 액세스 토큰에서 실행되는 범위를 검사하여 액세스 권한 부여 여부를 판별합니다. 또한 고급 범위 확인을 적용할 수 있습니다. 고급 범위 확인 URL은 URL 구성 여부를 기반으로 하여 애플리케이션 인증 또는 사용자 인증 후에 호출됩니다. 액세스 토큰에 의해 부여되는 최종 범위 권한은 모든 범위 확인의 결과입니다.`,
  [`The crypto object to use to decode the claim.`]: `청구 디코드에 사용할 암호 오브젝트입니다.`,
  [`The crypto object to use to encrypt the message.`]: `메시지 암호화에 사용할 암호 오브젝트입니다.`,
  [`The crypto object to use to sign the JWT.`]: `JWT 서명에 사용할 암호 오브젝트입니다.`,
  [`The crypto object to use to verify the signature.`]: `서명 확인에 사용할 암호 오브젝트입니다.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `현재 맵핑에 인식되지 않는 스키마 요소에 대한 참조가 포함되어 있습니다.`,
  [`The current organization does not contain any catalogs.`]: `현재 조직에 카탈로그가 없습니다.`,
  [`The default User registry must be included in the list`]: `기본 사용자 레지스트리는 목록에 포함되어야 함`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `페이로드의 유효성을 검증할 때 기준이 되는 정의입니다("#/definitions/XXXX").`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `개발자 포털은 공개된 API 제품의 이용자 보기를 제공합니다. 카탈로그를 위해 개발자 포털을 구성하고 하나 이상의 API 제품이 공개된 후 애플리케이션 개발자는 API를 찾아보고 사용할 수 있습니다.`,
  [`The document is missing draft_api.info.version field.`]: `문서에 draft_api.info.version 필드가 누락되어 있습니다.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `문서에 draft_api.info.x-ibm-name 필드가 누락되어 있습니다.`,
  [`The document is missing draft_product.info.name field.`]: `문서에 draft_product.info.name 필드가 누락되어 있습니다.`,
  [`The document is missing draft_product.info.version field.`]: `문서에 draft_product.info.version 필드가 누락되어 있습니다.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `추가 제한조건은 이 필드의 인수가 아닌 분할 인수를 지정합니다.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `이 필드에 기본값을 포함하는 한계 인수가 있습니다. 따라서 제공된 추정 크기를 적용할 수 없습니다.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `이 필드에는 기본값이거나 필수인 한계 인수가 있습니다. 따라서 제공된 추정 크기를 적용할 수 없습니다.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `이 필드에는 추정 크기도 분할 인수도 없습니다.`,
  [`This field returns unbound list of values with composite type.`]: `이 필드는 복합 유형으로 바인드 해제된 값의 목록을 리턴합니다.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `이 필드는 스칼라 유형 및 유형 가중치(!= 0.0)로 바인드 해제된 값의 목록을 리턴합니다.`,
  [`This test panel is deprecated and will be removed soon.`]: `이 테스트 패널은 더 이상 사용되지 않으며 곧 제거될 예정입니다.`,
  [`This type has no fields or no matching field.`]: `이 유형에 필드가 없거나 일치하는 필드가 없습니다.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `이 GatewayScript 정책은 이전 API 마이그레이션에만 해당되는 apim 모듈을 사용하지 않아야 합니다.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `이 XSLT 정책은 낡은 API를 마이그레이션하는 데만 사용되는 apim 모듈을 사용해서는 안됩니다.`,
  [`This type will be deleted.`]: `이 유형은 삭제됩니다.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `다음 다이어그램은 API에 대한 어셈블리를 표시합니다. 어셈블리를 수정하려면 어셈블리 편집기를 사용하십시오.`,
  [`The following files are available under the`]: `다음 파일은 다음 조건 하에서 사용 가능합니다.`,
  [`The following files contain a private key and cannot be uploaded:`]: `다음 파일은 개인 키를 포함하므로 업로드할 수 없습니다.`,
  [`The following is available under the`]: `다음은 다음 조건 하에서 사용 가능합니다.`,
  [`The following lifecycle actions require approval`]: `다음 라이프사이클 조치에는 승인이 필요합니다.`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `다음 목록에서 이 제공자 조직에서 작성된 드래프트 API를 표시함`,
  [`The following list displays the draft products that have been created in this provider organization`]: `다음 목록에서 이 제공자 조직에서 작성된 드래프트 제품을 표시함`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `다음 목록은 이 카탈로그의 구성원으로 추가된 모든 사용자 및 사용자의 지정된 역할을 표시합니다.`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `다음 목록은 이 공간의 구성원으로 추가된 모든 사용자 및 사용자의 지정된 역할을 표시합니다.`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `다음 매개변수가 OAuth 제공자에 구성된 값과 일치하지 않음`,
  [`The following roles are available in the Cloud Manager`]: `다음과 같은 역할을 Cloud Manager에서 사용할 수 있음`,
  [`The following types/fields will also be removed:`]: `다음 유형/필드도 제거됨:`,
  [`The following types/fields will be affected:`]: `다음 유형/필드가 영향을 받음:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `게이트웨이 특성 값 {gatewayType}은(는) 허용되지 않습니다. 허용되는 값은 datapower-gateway, datapower-api-gateway입니다.`,
  [`The host used to invoke the API`]: `API를 호출하는 데 사용된 호스트`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `키 저장소에는 HTTPS에서 데이터 전송을 암호화/복호화하고 ID를 확인하는 데 사용된 일치하는 공용 인증서와 개인 키 쌍이 포함되어 있습니다.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `키 저장소에는 HTTPS에서 데이터 전송을 암호화/복호화하고 ID를 확인하는 데 사용된 일치하는 공용 인증서와 개인 키 쌍이 포함되어 있습니다. 서버 프로파일은 키 저장소가 필요합니다.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `키 저장소는 해당 ID를 확인하기 위해 서버 또는 클라이언트에서 제공한 공개 키 및 개인용 인증서를 포함합니다.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `현재 날짜 및 시간에 추가된 시간 길이(초)로서 LTPA 키가 유효한 것으로 간주되는 시간입니다.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `현재 날짜 및 시간에 추가된 시간 길이(초)로서, JWT가 유효한 것으로 간주되는 시간입니다.`,
  [`The message to be sent with the error`]: `오류와 함께 보낼 메시지`,
  [`The message to be included within exception.`]: `예외에 포함될 메시지입니다.`,
  [`The HTTP status code to be included within exception.`]: `예외에 포함될 HTTP 상태 코드입니다.`,
  [`The HTTP status reason phrase to be included within exception.`]: `예외에 포함될 HTTP 상태 이유 구문입니다.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Datapower 구성에서 정의된 버스트 한계의 이름입니다.`,
  [`The name of a count limit defined in the datapower configuration.`]: `Datapower 구성에서 정의된 개수 한계의 이름입니다.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Datapower 구성에서 정의된 속도 제한의 이름입니다.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `요청의 응답 데이터를 저장하는 데 사용할 변수의 이름입니다. 이 변수는 다른 조치에서 참조될 수 있습니다(예: "Map").`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `결과를 저장하는 데 사용할 변수의 이름입니다. 기본적으로 "message"가 사용됩니다.`,
  [`The name of parameter to find the ID.`]: `ID를 찾을 매개변수 이름입니다.`,
  [`The name of parameter to find the secret.`]: `시크릿을 찾을 매개변수 이름입니다.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `LTPA 토큰 생성에 사용할 LTPA 키 이름입니다. 사용하려는 키 버전이 있는 경우 <keyname>:<version>을 지정하십시오. 예: my-ltpa-key:1.0.1. 정책에서 사용할 최신 키 버전을 자동으로 선택하려면 <keyname>:latest를 지정하십시오. 예: my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `발생한 오류 이름`,
  [`The name of the variable to be added.`]: `추가할 변수의 이름입니다.`,
  [`The name of the variable to be cleared.`]: `지우려는 변수의 이름입니다.`,
  [`The name of the variable to be set.`]: `설정할 변수의 이름입니다.`,
  [`The nature of the XML validation to be performed.`]: `수행할 XML 유효성 검증의 네이처입니다.`,
  [`The operation to apply.`]: `적용할 오퍼레이션`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `이 맵핑의 출력은 배열 내에 있으며 해당 컨텐츠는 선택한 입력을 반복하여 작성됩니다. 선택한 입력이 배열이 아닌 경우 1 길이의 배열이 작성됩니다.`,
  [`The owner must be an existing user in your user registry`]: `소유자는 사용자 레지스트리에 있는 기존 사용자여야 합니다.`,
  [`The owner of consumer organization has the following privileges:`]: `이용자 조직의 소유자에는 다음 권한이 있습니다.`,
  [`The owner of provider organization has the following privileges:`]: `제공자 조직의 소유자에는 다음 권한이 있습니다.`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `소유자는 API 제품 라이프사이클 관리, 하나 이상의 API 제공자 조직 관리, 애플리케이션 제공자 커뮤니티 관리, API 및 제품 정의 작성과 같은 업무를 담당합니다.`,
  [`The page you are looking for might have been moved or does not exist.`]: `찾고 있는 페이지가 이동했거나 존재하지 않을 수 있습니다.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `찾고 있는 페이지가 이동했거나 존재하지 않을 수 있습니다. 지원이 필요하면 관리자에게 문의하십시오.`,
  [`The parameters needed to send a valid API call.`]: `유효한 API 호출을 전송하는 데 필요한 매개변수입니다.`,
  [`The password to use for HTTP Basic authentication.`]: `HTTP 기본 인증에 사용할 비밀번호입니다.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `포털 서비스는 애플리케이션 개발자가 API 및 온보드 사용자를 검색하는 데 사용하는 개발자 포털을 제공합니다.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `포털 서비스는 애플리케이션 개발자가 API를 검색하고 구현하는 데 사용하는 개발자 포털을 제공합니다.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `처리 상태는 최근 활동의 요약과 함께 게이트웨이의 상태 보기를 제공합니다.`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `수신인은 소유자로 등록하고 새 제공자 조직을 작성합니다. 등록하고 나면 사용자는 제공자 조직 소유자로 API Manager에 로그인할 수 있습니다.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `결과 XML 문서의 루트 요소 이름입니다. 이 이름은 입력 JSON 문서가 계층 구조가 아니고 이 문서에 둘 이상의 최상위 레벨 특성이 있는 경우에만 필요합니다.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `여기의 범위는 개발자가 액세스할 수 있는 대상을 이해하기 위한 것이며 범위 확인에는 적용되지 않습니다.`,
  [`The selected catalog will only be used to search consumer organizations`]: `선택한 카탈로그는 이용자 조직을 검색하는 데만 사용됨`,
  [`The selected catalog does not have any configured gateways`]: `선택된 카탈로그에 구성된 게이트웨이가 없음`,
  [`The selected catalog does not have any compatible gateways`]: `선택된 카탈로그에 호환되는 게이트웨이가 없음`,
  [`Save Preferences`]: `환경 설정 저장`,
  [`Preferences`]: `환경 설정`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `선택한 카탈로그는 이용자 조직 및 그룹을 검색하는 데만 사용됨'`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `선택한 게이트웨이 유형은 어셈블리의 다음 정책을 올바르지 않은 것으로 렌더링합니다. 이 API를 실행하려면 먼저 이러한 정책을 삭제해야 합니다.`,
  [`The selected product does not contain this API`]: `선택한 제품이 이 API에 포함되지 않음`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `선택된 제품이 이용자 조직 또는 그룹의 특정 목록에 등록 가능하게 됩니다.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `선택한 서비스를 제공자 조직의 특정 목록에 사용할 수 있습니다.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `선택한 서비스를 기존의 모든 제공자 조직 및 새 제공자 조직에 사용할 수 있습니다.`,
  [`The selected services will not be available to any provider organizations.`]: `선택한 서비스를 제공자 조직에 사용할 수 없습니다.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `캐시의 키로 사용할 문자열입니다. 생략된 경우 전체 URL 문자열이 키로 사용됩니다.`,
  [`The server responded with a non-JSON body \n{body}`]: `비-JSON 본문으로 응답된 서버\n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `시간 간격은 액세스 토큰 TTL(Time To Live)보다 높아야 합니다.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `토큰은 쿠키 또는 로컬 스토리지에 저장되지 않습니다. 테스트 패널을 닫으면 토큰은 손실되지만 여전히 유효합니다.`,
  [`The transfer protocol of the API.`]: `API의 전송 프로토콜입니다.`,
  [`The type of authentication.`]: `인증의 유형입니다.`,
  [`The type of authorization header.`]: `인증 헤더의 유형입니다.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `설정할 변수의 유형입니다. 이는 문자열, 숫자 또는 부울입니다.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `설정할 변수의 유형입니다. 이는 문자열, 숫자 또는 부울입니다.`,
  [`The username to use for HTTP Basic authentication.`]: `HTTP 기본 인증에 사용할 사용자 이름입니다.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `이 연결에 대한 사용자는 더 이상 유효하지 않습니다. 새 연결을 작성하십시오.`,
  [`The value that the variable will be set to.`]: `변수가 설정될 값입니다.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `이 필드의 값은 복합 유형 목록이며 추정 크기도 분할 인수도 없습니다. 이러한 상황이 발생하면 조회 수신 시에 GraphQL 서버가 리턴할 수 있는 오브젝트의 수에 대한 한계를 API 게이트웨이가 설정할 수 없습니다.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `이 필드의 값은 스칼라 유형의 목록이며 추정 크기도 분할 인수도 없습니다. 이러한 상황이 발생하면 조회 수신 시에 GraphQL 서버가 리턴할 수 있는 오브젝트의 수에 대한 한계를 API 게이트웨이가 설정할 수 없습니다.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `버전은 URL Safe여야 합니다. 오브젝트 버전 {version}과(와) 슬러그 처리된 버전 {slugVersion}이(가) 일치하지 않습니다.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `"name" 특성은 URL에 안전해야 합니다(오브젝트의 이름 값 "{name}" 및 슬러그화된 이름 값 "{slugName}"이 일치해야 함).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `'draft_api' 특성에는 OpenAPI 정의가 포함되어야 합니다. OpenAPI 버전 3에서는 버전 3.0.0 - 3.0.3만 지원합니다(지정된 버전 {version}).`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `이름 필드는 제공한 제목에 따라 자동으로 채워집니다. API Connect 명령 및 API 호출에서 결과 값을 사용하십시오.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `가시성 설정은 자원에 액세스할 수 있는 제공자 조직을 판별합니다. 선택사항: 공용(모든 제공자 조직에서 자원을 사용할 수 있음), 사용자 정의(사용자가 지정한 제공자 조직만 자원을 사용할 수 있음), 개인용(자원을 표시하지 않으며 제공자 조직에서도 사용할 수 없음).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `가시성 설정은 서비스를 사용할 수 있는 제공자 조직을 판별합니다. 선택사항: 공용(모든 제공자 조직에서 서비스를 사용할 수 있음), 사용자 정의(사용자가 지정한 제공자 조직만 서비스를 사용할 수 있음), 개인용(서비스를 표시하지 않으며 제공자 조직에서도 사용할 수 없음).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `가시성 설정은 게이트웨이를 사용할 수 있는 제공자 조직을 판별합니다. 가시성 레벨에는 다음이 포함됩니다. 공용(모든 제공자 조직에서 게이트웨이를 사용할 수 있음) 사용자 정의(게이트웨이는 사용자가 지정한 제공자 조직에서만 사용할 수 있음) 및 개인(게이트웨이는 표시되지 않으며 제공자 조직에서 사용할 수 없음).`,
  [`The {title} role`]: `{title} 역할`,
  [`The {url} resource does not exist`]: `{url} 자원이 존재하지 않음`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `이 제품과 게이트웨이 유형이 다르기 때문에 호환되지 않는 API가 있습니다.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `그런 다음 관리 엔드포인트의 도메인에 대한 인증서를 선택합니다. 또한 잘 알려진 인증 기관에서 인증서를 할당하지 않은 경우 해당 CA 번들을 선택합니다. 인증서 및 번들은 인증서 관리자 서비스에서 관리해야 합니다.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `그런 다음 이 게이트웨이에서 처리할 도메인을 지정하십시오. SNI 기술이 사용됩니다. 결과적으로 동일한 인증서 없이도 동일한 IP 주소 및 포트를 통해 여러 도메인을 제공할 수 있습니다. 와일드 카드 형식이 지원됩니다. 표의 최종 항목으로‘*의 기본(포괄적) 도메인을 포함해야 합니다. 필요에 따라 다른 도메인 이름을 입력합니다. 각 경우에 인증서 관리자 서비스에서 관리하는 인증서를 지정합니다.`,
  [`There should be at least {minSelected} {label} selected.`]: `최소한 {minSelected}개의 {label}이(가) 선택되어 있어야 합니다.`,
  [`There are no API changes to publish.`]: `공개할 API 변경사항이 없습니다.`,
  [`There are no gateway services`]: `게이트웨이 서비스가 없습니다.`,
  [`There are no assemblies that match the chosen filters.`]: `선택된 필터와 일치하는 어셈블리가 없습니다.`,
  [`There are no burst limits yet.`]: `버스트 한계가 아직 없습니다.`,
  [`There are no catalogs`]: `카탈로그가 없습니다.`,
  [`There are no consumer organizations.`]: `이용자 조직이 없습니다.`,
  [`There are no consumer organizations. You can create one`]: `이용자 조직이 없습니다. 하나를 작성할 수 있습니다.`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `이용자 조직이 없습니다. [여기]({link})에서 하나를 작성할 수 있습니다.`,
  [`There are no email servers`]: `이메일 서버가 없습니다.`,
  [`There are no gateway services configured for the sandbox catalog`]: `샌드박스 카탈로그에 구성된 게이트웨이 서비스가 없음`,
  [`There are no lifecycle approvals enabled`]: `사용 가능한 라이프사이클 승인이 없습니다.`,
  [`There are no new changes.`]: `새 변경사항이 없습니다.`,
  [`There are no products to display`]: `표시할 제품이 없음`,
  [`There are no products to replace`]: `대체할 제품이 없음`,
  [`There are no products to set migration target`]: `마이그레이션 대상을 설정할 제품이 없음`,
  [`There are no products to supersede`]: `대체할 제품이 없음`,
  [`There are no properties to configure for this policy`]: `이 정책을 구성하는 특성이 없음`,
  [`There are no rate limits yet.`]: `속도 제한이 아직 없습니다.`,
  [`There are no security definitions`]: `보안 정의가 서비스가 없습니다.`,
  [`There are no spaces`]: `공간이 없습니다.`,
  [`There are no target services`]: `대상 서비스가 없습니다.`,
  [`There are no tasks to be displayed`]: `표시할 태스크가 없습니다.`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `저장되지 않은 변경사항이 있습니다. 계속하시겠습니까?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `저장되지 않은 변경사항이 있습니다. 계속하면 이러한 변경사항이 버려집니다. 계속하시겠습니까?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `yaml에 존재하지 않는 API가 있고 이를 나열할 수 없습니다. 소스 탭을 확인하십시오.`,
  [`There is no item in the list.`]: `목록에 항목이 없습니다.`,
  [`There is no space`]: `공간이 없음`,
  [`There is no warning in the schema.`]: `스키마에 경고가 없습니다.`,
  [`There was no data found to populate the data table.`]: `데이터 테이블을 채우기 위한 데이터를 찾지 못했습니다.`,
  [`These endpoints are used for all the APIs in the catalog`]: `이러한 엔드포인트는 카탈로그 내의 모든 API에 대해 사용됩니다.`,
  [`Third party OAuth provider`]: `써드파티 OAuth 제공자`,
  [`Third party OAuth provider summary`]: `써드파티 OAuth 제공자 요약`,
  [`Third party`]: `써드파티`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `이 API는 아직 어셈블리를 포함하지 않습니다. 어셈블리를 작성하시겠습니까?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `이 자원은 더 이상 존재하지 않습니다. 서버에서 결과를 새로 고치십시오.`,
  [`Please refresh the results from the server`]: `서버에서 결과를 새로 고치십시오.`,
  [`Exact match`]: `정확하게 일치`,
  [`Closest match`]: `가장 가까운 일치`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `이 API는 현재 {productName} 제품에서 사용하고 있습니다. 해당 제품에서 이 API를 삭제한 후 이 API를 삭제하십시오.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `이 API는 다음 옵션이 사용으로 설정된 경우에 호출할 수 있습니다.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `이 카탈로그의 자동 등록이 사용으로 설정되어 있지 않습니다. 이 카탈로그에서 테스트하려면 아래에서 제품, 플랜 및 애플리케이션을 선택하십시오.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `이 엔드포인트는 기본 엔드포인트를 정의하지 않는 API에 대한 기본값으로 사용됩니다.`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `이는 향후 발전하고 지속적으로 개선될 예정인 기술 미리보기 기능입니다.`,
  [`This is the currently configured notification server.`]: `이는 현재 구성된 알림 서버입니다.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `정책 및 확장 기능이 포함된 파일이 저장되는 디렉토리`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `여기에서 API를 실행하고 정책과 확장기능에 액세스할 수 있습니다.`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `이 oauth 정책은 기본적으로 발행된 경로에 필요한 모든 OAuth 프로토콜 단계를 수행합니다. 각 단계의 입력 및 출력은 문서화된 컨텍스트 변수에 의해 주도됩니다. 필요에 따라 지원되는 OAuth 컴포넌트를 추가하거나 제거하십시오.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `이 oauth 정책은 기본적으로 OAuth 유효성 검증에 필요한 모든 OAuth/OpenID Connect 프로토콜 단계를 수행합니다. 각 단계의 입력 및 출력은 문서화된 컨텍스트 변수에 의해 주도됩니다. 필요에 따라 지원되는 OAuth 컴포넌트를 추가하거나 제거하십시오.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `이 oauth 정책은 기본적으로 모든 기타 경로에 필요한 모든 OAuth/OpenID Connect 프로토콜 단계를 수행합니다. 각 단계의 입력 및 출력은 문서화된 컨텍스트 변수에 의해 주도됩니다. 필요에 따라 지원되는 OAuth 컴포넌트를 추가하거나 제거하십시오.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `이 oauth 정책은 기본적으로 az 코드 경로에 필요한 모든 OAuth/OpenID Connect 프로토콜 단계를 수행합니다. 각 단계의 입력 및 출력은 문서화된 컨텍스트 변수에 의해 주도됩니다. 필요에 따라 지원되는 OAuth 컴포넌트를 추가하거나 제거하십시오.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `이 oauth 정책은 기본적으로 토큰 경로에 필요한 모든 OAuth/OpenID Connect 프로토콜 단계를 수행합니다. 각 단계의 입력 및 출력은 문서화된 컨텍스트 변수에 의해 주도됩니다. 필요에 따라 지원되는 OAuth 컴포넌트를 추가하거나 제거하십시오.`,
  [`This operation is secured with access code flow OAuth`]: `이 오퍼레이션은 액세스 코드 플로우 OAuth로 보호됩니다.`,
  [`This operation is secured with application flow OAuth`]: `이 오퍼레이션은 애플리케이션 플로우 OAuth로 보호됩니다.`,
  [`This operation is secured with basic authentication`]: `이 오퍼레이션은 기본 인증으로 보호됩니다.`,
  [`This operation is secured with implicit flow OAuth`]: `이 오퍼레이션은 암시 플로우 OAuth로 보호됩니다.`,
  [`This operation is secured with password flow OAuth`]: `이 오퍼레이션은 비밀번호 플로우 OAuth로 보호됩니다.`,
  [`This policy is not available on the selected gateway`]: `선택한 게이트웨이에서 이 정책을 사용할 수 없음`,
  [`This policy is used by one other operation`]: `이 정책은 다른 하나의 오퍼레이션에서 사용됩니다.`,
  [`This policy is used by {{count}} other operations`]: `이 정책은 다른 {{count}}개의 오퍼레이션에서 사용됩니다.`,
  [`This role cannot be edited`]: `이 역할을 수정할 수 없습니다.`,
  [`This role cannot be edited.`]: `이 역할을 편집할 수 없습니다.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `입력이 없고 기본 맵핑이 구성되지 않았으므로 맵핑이 실패하면 이 설정이 동작을 제어합니다. 기본 동작은 출력을 변경하지 않는 것이지만, 이 선택란을 선택하면 대상 맵핑의 상위에 대해 빈 오브젝트가 작성되고 버전 4의 동작이 에뮬레이트됩니다.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `이 사용자 보안 정책은 oauth 어셈블리를 위해 EI(기본) 및 AU(auth url) 검사를 수행합니다. 필요에 따라 보안 검사 방법을 변경하십시오.`,
  [`This will enable/disable application lifecycle`]: `그러면 애플리케이션 라이프사이클이 사용/사용 안함으로 설정됩니다.`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `태스크 자체 승인을 사용/사용 안함으로 설정합니다. 태스크 자체 승인을 사용하면 작성자 및 협업자가 태스크를 승인할 수 있습니다. 이 옵션은 다른 승인자가 없을 때 편리할 수 있지만 한편으론 다른 사용자의 검사를 무시할 수 있습니다.`,
  [`This will unset the catalog as the default catalog.`]: `이는 카탈로그를 기본 카탈로그에서 설정 해제합니다.`,
  [`Throw`]: `처리`,
  [`Time`]: `시간`,
  [`Time Interval`]: `시간 간격`,
  [`Time Limit`]: `시간 한계`,
  [`Time to Live`]: `TTL(Time to Live)`,
  [`Time to live`]: `TTL(Time To Live)`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `엔드포인트의 응답을 기다리는 시간입니다. 기본값은 60초입니다.`,
  [`Timeout`]: `제한시간`,
  [`To`]: `수신인`,
  [`to`]: `수신인`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `관리자 조직의 구성원으로 사용자를 추가하려면 사용자 레지스트리를 선택하고 사용자 이름을 입력한 다음 지정할 역할을 선택하십시오. 사용자 레지스트리 유형이 로컬 사용자 레지스트리이면 구성원으로 추가할 새 사용자를 작성하는 옵션도 있습니다. 멤버십이 즉시 사용되며 지정된 사용자가 클라우드 관리자 사용자 인터페이스에 로그인할 수 있습니다.`,
  [`To add a user as a member of a provider organization`]: `사용자를 제공자 조직의 구성원으로 추가`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `사용자를 카탈로그의 구성원으로 추가하려면 사용자 이름을 선택하고 필수 역할을 지정하십시오. 제공자 조직에서 이미 다른 카탈로그 또는 공간의 구성원이고 현재 이 카탈로그의 구성원이나 카탈로그 소유자가 아닌 사용자를 추가할 수 있습니다.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `사용자를 공간의 구성원으로 추가하려면 사용자 이름을 선택하고 필수 역할을 지정하십시오. 제공자 조직에서 이미 다른 카탈로그 또는 공간의 구성원이고 현재 이 공간의 구성원이나 공간 소유자가 아닌 사용자를 추가할 수 있습니다.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `구성원을 추가하거나 제거하려면 [identity and access](/iam) 서비스를 사용하십시오.`,
  [`To add members, use the [identity and access](/iam) service.`]: `구성원을 추가하려면 [identity and access](/iam) 서비스를 사용하십시오.`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `사용자를 조직의 구성원으로 추가하려면 사용자 레지스트리를 선택하고 사용자 이름을 입력한 다음 역할 세트를 지정하십시오.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `사용자를 구성원으로 추가하려면 사용자 이름을 선택한 다음 역할 세트를 지정하십시오.`,
  [`To assign default gateways, click **Edit**.`]: `기본 게이트웨이를 할당하려면 **편집**을 클릭하십시오.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `이 소프트웨어를 계속 사용하려면 소프트웨어 라이센스 계약 조항에 동의해야 합니다.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `API Connect를 계속 사용하려면 엔터프라이즈 플랜으로 업그레이드하십시오.`,
  [`To get started, you must first enable spaces.`]: `시작하려면 먼저 공간을 사용하도록 지정해야 합니다.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `툴킷을 설치하고 실행하려면 다음 단계를 완료하십시오. [추가 학습]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `새 사용자를 관리자 조직의 구성원으로 초대하려면, 사용자의 이메일 주소 또는 이름을 입력하고 사용자 레지스트리에서 해당 이름을 선택하십시오.  모든 구성원에게는 기본 권한 세트를 제공하는 멤버 역할이 자동으로 할당되며 삭제할 수 없습니다. 다른 권한이 필요하지 않으면 다른 역할을 선택하지 않은 상태로 둡니다.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `아직 레지스트리에 없는 소유자를 초대하려면 이메일 주소를 입력하십시오.`,
  [`To register a new gateway, visit the **Gateways** page.`]: `새 게이트웨이를 등록하려면 **게이트웨이** 페이지를 방문하십시오.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `다음 공간 특성 중 하나로 값을 대체하려면 소괄호 안에 특성 이름과 함께 $()를 입력하십시오.`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `관리자 조직의 구성원으로 등록할 새 사용자에게 이메일 초대를 보내려면 올바른 이메일 주소를 입력하고 사용자에게 지정할 역할을 선택하십시오. 활성화 링크가 포함된 이메일이 등록할 사용자를 초대하는 이메일 주소로 전송됩니다. 사용자가 보류 중 상태가 되며, 계정을 활성화하기 위해 등록 양식을 완료하고 나면 사용 상태로 변경됩니다. 그런 다음 계정 활성화 중에 지정한 API Connect 사용자 이름으로 클라우드 관리자 사용자 인터페이스에 로그인할 수 있습니다.`,
  [`Toggle`]: `토글`,
  [`Toggle application lifecycle?`]: `애플리케이션 라이프사이클을 토글하시겠습니까?`,
  [`Toggle default catalog?`]: `기본 카탈로그 전환?`,
  [`Toggle info panel between right and bottom`]: `정보 패널을 오른쪽과 맨 아래 사이에 토글`,
  [`Toggle profile`]: `프로파일 전환`,
  [`Toggle spaces?`]: `공간을 토글하시겠습니까?`,
  [`Toggle task self-approval?`]: `태스크 자체 승인을 토글하시겠습니까?`,
  [`Token`]: `토큰`,
  [`Token endpoint`]: `토큰 엔드포인트`,
  [`Token Expiry`]: `토큰 만기`,
  [`Token Management`]: `토큰 관리`,
  [`Token Output`]: `토큰 출력`,
  [`Token URL`]: `토큰 URL`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `토큰 URL이 OAuth 제공자 "{providerTitle}"에서 정의한 대로 {endpoint}과(와) 일치해야 함`,
  [`Token Version`]: `토큰 버전`,
  [`Token management`]: `토큰 관리`,
  [`Token management type`]: `토큰 관리 유형`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `토큰 관리를 사용하면 반복 공격을 방지할 수 있으며 클라이언트 애플리케이션 또는 자원 소유자가 OAuth 토큰을 취소하는지 여부 및 취소 방법을 포함하여 토큰을 관리할 수 있습니다. API Connect는 원시 게이트웨이 또는 써드파티 엔드포인트를 사용하여 토큰 관리를 지원합니다. 원시 게이트웨이인 경우, 토큰을 관리하기 위해 할당량이 적용됩니다. 써드파티 엔드포인트인 경우, 토큰을 관리하기 위해 사용자가 외부 서비스에 URL을 제공합니다.`,
  [`Token path`]: `토큰 경로`,
  [`Token revocation`]: `토큰 취소`,
  [`Token secret`]: `토큰 시크릿`,
  [`Token validation`]: `토큰 유효성 검증`,
  [`Tokens`]: `토큰`,
  [`Token relay`]: `토큰 릴레이`,
  [`Toolkit credentials`]: `툴킷 신임 정보`,
  [`Token Relay`]: `토큰 릴레이`,
  [`Topology`]: `토폴로지`,
  [`Topology Administrator`]: `토폴로지 관리자`,
  [`Total time`]: `총 시간`,
  [`Transfer Ownership to a New User`]: `새 사용자에게 소유권 이전`,
  [`Transfer Ownership to an Existing User`]: `기존 사용자에게 소유권 이전`,
  [`Transfer ownership`]: `소유권 이전`,
  [`Transfer ownership to`]: `소유권 이전`,
  [`Transfer ownership to a new user`]: `새 사용자에게 소유권 이전`,
  [`Transfer ownership to an existing user`]: `기존 사용자에게 소유권 이전`,
  [`Transforms`]: `변환`,
  [`Truststore Certificates`]: `신뢰 저장소 인증서`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `신뢰 저장소 인증서는 인증 기관에서 발급한 공용 인증서입니다.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `신뢰 저장소에는 확인된 공개 키를 포함하는 신뢰할 수 있는 인증서가 포함되어 있습니다. 신뢰 저장소의 인증서는 일반적으로 써드파티 인증 기관(CA)에서 가져옵니다.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `신뢰 저장소에는 확인된 공개 키가 있는 신뢰할 수 있는 인증서가 포함되어 있습니다. 신뢰 저장소의 인증서는 일반적으로 써드파티 인증 기관(CA)에서 가져옵니다.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `신뢰 저장소는 확인된 공개 키를 포함하여 신뢰된 인증서를 저장합니다. 신뢰 저장소의 인증서는 일반적으로 써드파티 인증 기관(CA)에서 가져옵니다.`,
  [`Try again or upload a schema`]: `다시 시도 또는 스키마 업로드`,
  [`Try refreshing the page or contacting support.`]: `페이지를 새로 고치거나 지원팀에 문의하십시오.`,
  [`Turkish`]: `터키어`,
  [`Tutorials`]: `학습서`,
  [`Types`]: `유형`,
  [`Type of user`]: `사용자 유형`,
  [`Type or select a catalog`]: `카탈로그 입력 또는 선택`,
  [`Type to add organizations`]: `조직을 추가하려면 입력`,
  [`TypeError: Failed to fetch`]: `TypeError: 페치 실패`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `관리 엔드포인트의 URL`,
  [`URLs for all operations in the API begin with this value.`]: `API에서 모든 조작의 URL은 이 값으로 시작됩니다.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `사용자 정의`,
  [`Userinfo POST`]: `Userinfo POST`,
  [`USER MANAGED`]: `사용자 관리`,
  [`USERNAME`]: `사용자 이름`,
  [`User can delete the keystore history.`]: `사용자는 키 저장소 히스토리를 삭제할 수 있습니다.`,
  [`Unable to parse API definition: {msg}`]: `API 정의를 구문 분석할 수 없음: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `유형 및/또는 필드 제거 시 오류가 발견되어 계속할 수 없습니다.`,
  [`Unarchive`]: `아카이브 해제`,
  [`Unassociate`]: `연관 해제`,
  [`Unassociate analytics service`]: `분석 서비스 연관 해제`,
  [`Unauthorized`]: `권한 없음`,
  [`Unauthorized API Request`]: `권한 없는 API 요청`,
  [`Unbound lists`]: `바인드 해제 목록`,
  [`Unit`]: `단위`,
  [`Union`]: `유니온`,
  [`Union type`]: `유니온 유형`,
  [`Unique email address`]: `고유 이메일 주소`,
  [`Unlimited`]: `무제한`,
  [`Unnecessary assumed size`]: `불필요한 추정 크기`,
  [`Unnecessary slicing arguments`]: `불필요한 분할 인수`,
  [`Unrecognized Schema Element(s) Encountered`]: `인식되지 않는 스키마 요소가 발견됨`,
  [`Unsupported media type ({type})`]: `지원되지 않는 매체 유형({type})`,
  [`Untitled`]: `제목 없음`,
  [`Update`]: `업데이트`,
  [`Update gateway services`]: `게이트웨이 서비스 업데이트`,
  [`Update assembly`]: `어셈블리 업데이트`,
  [`Update group failed.`]: `그룹 업데이트에 실패했습니다.`,
  [`Update schema defaults giving preference to`]: `환경 설정을 제공하는 스키마 기본값 업데이트`,
  [`Update the sender details that are displayed on invitation emails`]: `초대 이메일에 표시되는 발신인 세부사항을 업데이트하십시오.`,
  [`Update the summary details for this role`]: `이 역할의 요약 세부사항을 업데이트하십시오.`,
  [`Updating Draft API`]: `드래프트 API 업데이트`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `스키마 기본값을 업데이트하면 변경사항을 대체합니다. 계속하시겠습니까?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `{existingType} 유형의 API를 {type} 유형의 API로 업데이트할 수 없습니다.`,
  [`Upload`]: `업로드`,
  [`Upload a schema definition language file to replace your schema.`]: `스키마를 대체하려면 스키마 정의 언어 파일을 업로드하십시오.`,
  [`Upload GraphQL schema`]: `GraphQL 스키마 업로드`,
  [`Upload local`]: `로컬 업로드`,
  [`Upload policies`]: `업로드 정책`,
  [`Upload policy`]: `업로드 정책`,
  [`Upload schema`]: `스키마 업로드`,
  [`Upload schema definition language (SDL) file`]: `SDL(Schema Definition Language) 파일 업로드`,
  [`Upload truststore certificates`]: `신뢰 저장소 인증서 업로드`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `애플리케이션에 대한 X509 인증서를 PEM 형식으로 업로드하십시오. 하나의 PEM 파일만 업로드할 수 있지만 하나의 파일에 여러 인증서를 포함시킬 수 있습니다.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `애플리케이션에 대한 X509 인증서를 PEM 형식으로 업로드하십시오.`,
  [`Upload your public certificate`]: `공용 인증서 업로드`,
  [`Uploaded and validated YAML file`]: `업로드 및 유효성 검증된 YAML 파일`,
  [`Upload extension`]: `확장 업로드`,
  [`Use all compatible gateway services`]: `호환 가능한 모든 게이트웨이 서비스 사용`,
  [`Use Content Type`]: `컨텐츠 유형 사용`,
  [`Use DataPower API Gateway`]: `DataPower API Gateway 사용`,
  [`Use DataPower Gateway`]: `DataPower Gateway 사용`,
  [`Use Dynamic DNS`]: `동적 DNS 사용`,
  [`Use JSONata to specify the property to redact or remove`]: `JSONata를 사용하여 수정하거나 제거할 특성을 지정하십시오.`,
  [`Use XPath to specify the property to redact or remove.`]: `XPath를 사용하여 수정하거나 제거할 특성을 지정하십시오.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `공개할 기본 카탈로그로 선택한 카탈로그를 사용합니다.`,
  [`Use an existing JSON or YAML definition of the product`]: `제품의 기존 JSON 또는 YAML 정의 사용`,
  [`Use an existing definition of a REST proxy`]: `REST 프록시의 기존 정의 사용`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `REST 프록시 또는 SOAP API의 기존 정의 사용`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `REST 프록시, GraphQL 프록시 또는 SOAP API의 기존 정의 사용`,
  [`Use an existing definition of a product`]: `제품의 기존 정의 사용`,
  [`Use context current payload`]: `컨텍스트의 현재 페이로드 사용`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `사용자 정의 카탈로그 엔드포인트 URL 사용(v5 호환성)`,
  [`Use dynamic DNS`]: `동적 DNS 사용`,
  [`Use endpoint from API`]: `API에서 엔드포인트 사용`,
  [`Use gateway URLs`]: `게이트웨이 URL 사용`,
  [`Use gateway service configured OAuth shared secret key`]: `게이트웨이 서비스 구성 OAuth 공유 비밀 키 사용`,
  [`Use group authentication`]: `그룹 권한 사용`,
  [`Use only first array element`]: `첫 번째 배열 요소만 사용`,
  [`Use static DNS`]: `정적 DNS 사용`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `외부 OIDC 제공자 트래픽의 엔드포인트로서 포털 사용`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `API 탐색기를 사용하여 API를 테스트하고 탐색하십시오. 탐색기에는 프로젝트 디렉토리에 포함된 모든 API에 대한 오퍼레이션, 정의 및 문서가 표시됩니다. 탐색기에는 API 실행을 위한 템플리트 코드와 함께 매개변수, 모델 인스턴스 데이터 및 응답 코드가 나열됩니다.`,
  [`Use the actions menu to manage and publish your APIs.`]: `조치 메뉴를 사용하여 API를 관리하고 게시하십시오.`,
  [`Use the actions menu to manage and publish your products`]: `조치 메뉴를 사용하여 제품을 관리하고 게시함`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `활동 로그 정책을 사용하여 분석에 저장된 API 활동에 대한 로깅 환경 설정을 구성하십시오. 지정한 환경 설정은 API 활동의 세부사항 수집 및 저장을 위한 기본 설정을 오버라이드합니다.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `클라이언트 보안 정책을 사용하여 클라이언트 신임 정보를 추출하고 인증하십시오.`,
  [`Use the default built-in Sandbox Catalog`]: `기본 제공 샌드박스 카탈로그 사용`,
  [`Use the editor for manual input or upload a file below.`]: `수동 입력을 위해 편집기를 사용하거나 아래 파일을 업로드하십시오.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `수동 입력을 위해 편집기를 사용하거나 아래 파일을 업로드하십시오. 현재 스키마는 XML/XSD 파일이 정의된 경우에만 XML에서 편집할 수 있습니다.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `적용된 확장을 사용하여 API Connect Gateway가 API를 적용하는 데 사용되는지 여부를 지정하십시오.`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `CLI에 대한 작업을 수행할 때 다음 관리 엔드포인트 URL을 사용하십시오.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `API Designer에서 클라우드 연결을 작성할 때 다음 관리 엔드포인트 URL을 사용하십시오.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `gatewayscript 정책을 사용하여 지정된 DataPower GatewayScript 프로그램을 실행하십시오.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `graphql-introspect 정책을 사용하여 GraphQL 스키마를 검사하십시오.`,
  [`Use the host value from Open API Definition`]: `OpenAPI 정의에서 호스트 값 사용`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `조건이 충족되면 if 정책을 사용하여 어셈블리 섹션을 적용하십시오.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `javascript 정책을 사용하여 지정된 JavaScript 프로그램을 실행하십시오.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `json-to-xml 정책을 사용하여 JSON에서 XML로 페이로드의 컨텐츠를 변환하십시오.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `로그 정책을 사용하여 API의 기본 활동 로깅 구성을 사용자 정의하거나 대체하십시오.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `맵핑 정책을 사용하여 변환을 어셈블리 플로우에 적용하고 변수 간 관계를 지정하십시오.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `맵핑 정책을 사용하여 API 플로우 내에서 변수 간 관계를 지정하고 이러한 변수에 변환을 적용하십시오.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `oauth 정책을 사용하여 OAuth 토큰을 생성하십시오.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `오퍼레이션 전환 정책을 사용하여 어셈블리의 섹션을 특정 오퍼레이션에 적용하십시오.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `구문 분석 정책을 사용하여 요청이나 메시지를 XML 또는 JSON으로 구문 분석하거나 2진 데이터를 BLOB로 구문 분석합니다.`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `변수 설정 정책을 사용하여 런타임 변수를 문자열 값으로 설정하거나 런타임 변수를 추가하거나 제거하십시오.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `어셈블리 플로우 실행 중에 오류가 발생하는 경우 예외 처리 정책을 사용하여 오류를 처리하십시오.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `사용자 보안 정책을 사용하여 사용자의 ID를 추출, 인증, 권한 부여하십시오.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `DataPower Gateway를 통한 유효성 검증 정책을 사용하여 JSON 또는 XML 스키마에 대한 어셈블리 플로우의 페이로드를 유효성 검증하십시오.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `xml-to-json 정책을 사용하여 XML에서 JSON으로 페이로드의 컨텐츠를 변환하십시오.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `XSLT 정책을 사용하여 페이로드에 XSLT 변환을 적용하십시오.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `업로드 시 컨텐츠 인코딩 압축을 사용/사용 안함 설정하는 데 사용됩니다.`,
  [`User`]: `사용자`,
  [`User group prefix`]: `사용자 그룹 접두어`,
  [`User group suffix`]: `사용자 그룹 접미부`,
  [`User mapping`]: `사용자 맵핑`,
  [`User Registries`]: `사용자 레지스트리`,
  [`User registries`]: `사용자 레지스트리`,
  [`User Registry Name`]: `사용자 레지스트리 이름`,
  [`User registry name`]: `사용자 레지스트리 이름`,
  [`User Registry cannot be deleted`]: `사용자 레지스트리를 삭제할 수 없음`,
  [`User Registry "{name}" has been deleted`]: `사용자 레지스트리 "{name}"이(가) 삭제되었습니다.`,
  [`User security`]: `사용자 보안`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `사용자 레지스트리는 공간이 사용 가능한 경우 각 공간에서 관리됩니다. 공간 설정으로 이동하여 사용자 레지스트리를 관리하십시오.`,
  [`User registries defined for consumer onboarding`]: `이용자 온보딩에 대해 정의된 사용자 레지스트리`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `이용자 온보딩에 대해 정의된 사용자 레지스트리\n\n개발자 포털의 모든 계정(동일한 사이트의 서로 다른 사용자 레지스트리에 있는 계정 포함)은 사이트 관리 계정을 포함하여 고유한 이메일 주소를 보유해야 합니다.`,
  [`User registry`]: `사용자 레지스트리`,
  [`User registry configuration has been updated.`]: `사용자 레지스트리 구성이 업데이트되었습니다.`,
  [`User registry has been created`]: `사용자 레지스트리가 작성됨`,
  [`User registry {title} deleted.`]: `사용자 레지스트리 {title}이(가) 삭제되었습니다.`,
  [`User registry {title} has been created.`]: `사용자 레지스트리 {title}이(가) 작성되었습니다.`,
  [`User registry is not visible or not available.`]: `사용자 레지스트리가 보이지 않거나 사용할 수 없습니다.`,
  [`User registry {title} has been updated.`]: `사용자 레지스트리 {title}이(가) 업데이트되었습니다.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `선택한 권한 부여 유형에 내재, 액세스 코드 및 자원 소유자 - 비밀번호 중 하나 이상이 포함된 경우에만 사용자 보안 설정이 적용됩니다.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `선택한 권한 부여 유형에 내재, 액세스 코드 및 자원 소유자 - 비밀번호 중 하나 이상이 포함된 경우에만 사용자 보안 설정이 적용됩니다. 이 페이지에서 변경사항을 작성하는 경우 구성 페이지에서 이러한 권한 부여 유형 중 하나 이상을 선택해야 합니다.`,
  [`UserInfo endpoint`]: `사용자 정보 엔드포인트`,
  [`UserInfo URL`]: `사용자 정보 URL`,
  [`Username`]: `사용자 이름`,
  [`Username / Email`]: `사용자 이름 / 이메일`,
  [`Username and password fields are required in order to test configuration`]: `구성을 테스트하려면 사용자 이름 및 비밀번호 필드가 필수입니다.`,
  [`Username context variable`]: `사용자 이름 컨텍스트 변수`,
  [`Username of the new user`]: `새 사용자의 사용자 이름`,
  [`Username should not contain special characters!`]: `사용자 이름에는 특수 문자를 사용하면 안됩니다!`,
  [`Username should not include spaces!`]: `사용자 이름에는 공백이 있으면 안됩니다!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `이용자 조직에 속한 사용자가 가시성 및 등록성에 의해 결정된 대로 개발자 포털 및 공개된 API 제품에 대한 액세스 권한을 가집니다.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `[IBM Cloud IAM]({link})을 사용하여 API Connect 예약된 인스턴스 서비스가 인증서 관리자 서비스에 액세스할 수 있도록 권한을 부여하십시오.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `사용자 정보 엔드포인트에 연결 시 HTTP POST 메소드 사용`,
  [`Use IBM APIC token expiration setting from the cloud`]: `클라우드의 IBM APIC 토큰 만기 설정 사용`,
  [`Update API`]: `API 업데이트`,
  [`VALUE`]: `값`,
  [`Version`]: `버전`,
  [`Visible to`]: `표시 대상`,
  [`Validate`]: `유효성 검증`,
  [`Validate message body`]: `메시지 본문 유효성 검증`,
  [`Validate message headers`]: `메시지 헤더 유효성 검증`,
  [`Validate message fault details`]: `메시지 결함 세부사항 유효성 검증`,
  [`Validate SOAP 1.1 encoding rule`]: `SOAP 1.1 인코딩 규칙 유효성 검증`,
  [`Validate JSON Web Token (JWT)`]: `JWT(JSON Web Token) 유효성 검증`,
  [`Validate JWT`]: `JWT 유효성 검증`,
  [`Validate Username Token`]: `사용자 이름 토큰 유효성 검증`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `인증 URL 또는 LDAP 사용자 레지스트리를 사용하여 웹 서비스 보안 UsernameToken 페이로드를 유효성 검증합니다.`,
  [`Validate against`]: `유효성 검증 기준`,
  [`Validate request`]: `유효성 검증 요청`,
  [`Validate the GraphQL query and analyze the expected cost`]: `GraphQL 조회의 유효성 검증 및 예상 비용 분석`,
  [`Validate the returned JSON and analyze the real cost`]: `리턴된 JSON의 유효성 검증 및 실제 비용 분석`,
  [`Validating API ...`]: `API 유효성 검증 중...`,
  [`Validating Draft API`]: `드래프트 API 유효성 검증`,
  [`Validity Period`]: `유효 기간`,
  [`Value`]: `값`,
  [`Value for located in must be selected.`]: `위치에 대한 값을 선택해야 합니다.`,
  [`Value for the variable to be added.`]: `추가할 변수의 값입니다.`,
  [`Value for type must be selected.`]: `유형에 대한 값을 선택해야 합니다.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `"@cost" 지시문의 "weight" 인수 값은 < 0입니다.`,
  [`Values`]: `값`,
  [`Vanity API endpoint`]: `베니티 API 엔드포인트`,
  [`Variable name`]: `변수 이름`,
  [`Variable value`]: `변수값`,
  [`Vendor Extensions`]: `벤더 확장기능`,
  [`Verb`]: `Verb`,
  [`Verify`]: `확인`,
  [`Verify Access Token`]: `액세스 토큰 확인`,
  [`Verify Crypto JWK variable name`]: `암호 JWK 변수 이름 확인`,
  [`Verify Crypto Object`]: `암호 오브젝트 확인`,
  [`Verify Token`]: `토큰 확인`,
  [`Verify authorization code`]: `권한 코드 확인`,
  [`Verify refresh token`]: `새로 고친 토큰 확인`,
  [`Version of the LTPA token.`]: `LTPA 토큰의 버전입니다.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `가져온 제품 파일의 '플랜' 아래에서 'apis' 특성에 체크하십시오. 각 플랜에는 'apis' 섹션의 api를 최소한 하나 가리키는 'apis' 특성이 있어야 합니다.`,
  [`View`]: `보기`,
  [`View extensions`]: `확장 보기`,
  [`View Forum`]: `포럼 보기`,
  [`View owners`]: `소유자 보기`,
  [`View policies`]: `정책 보기`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `선택한 프로토콜 버전에 대해 사용 가능한 암호를 보고 관리하십시오.`,
  [`View analytics`]: `분석 보기`,
  [`View and edit permissions for the roles in this space`]: `이 공간의 역할에 대한 권한을 보고 편집하십시오.`,
  [`View and edit the permissions for the roles in the current organization`]: `현재 조직의 역할에 대한 권한을 보고 편집하십시오.`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `이용자 조직의 역할 기본값에 포함된 역할을 보고 편집하십시오.`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `제공자 조직의 역할 기본값에 포함된 역할을 보고 편집하십시오.`,
  [`View approval history`]: `승인 히스토리 보기`,
  [`View Approval History`]: `승인 히스토리 보기`,
  [`View documentation and tutorials with step-by-step instructions.`]: `단계별 지시사항이 있는 문서 및 학습서를 보십시오.`,
  [`View error ID`]: `소유자 오류 ID`,
  [`View status`]: `상태 보기`,
  [`View more`]: `자세히 보기`,
  [`View source`]: `소스 보기`,
  [`View subscription`]: `등록 보기`,
  [`View template in`]: `템플리트 보기 위치`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `API Connect 사용자 인터페이스에 액세스하기 위한 엔드포인트를 보십시오.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `관리자 및 제공자 REST API의 엔드포인트를 봅니다.`,
  [`View the endpoints for the consumer REST APIs.`]: `이용자 REST API의 엔드포인트를 봅니다.`,
  [`Viewer`]: `뷰어`,
  [`Views the API consumer organization`]: `API 이용자 조직 보기`,
  [`Views the API provider organization`]: `API 제공자 조직 보기`,
  [`Views the admin organization`]: `관리 조직 보기`,
  [`Views the app developer organization`]: `앱 개발자 조직 보기`,
  [`Views the catalog`]: `카탈로그 보기`,
  [`Views the space`]: `공간 보기`,
  [`View|permission`]: `보기`,
  [`Visibility`]: `가시성`,
  [`Visibility options`]: `가시성 옵션`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware 문서`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL URL`,
  [`WSDL has been successfully validated`]: `WSDL이 유효성 검증됨`,
  [`WSDL to REST`]: `WSDL 대 REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `WS-I Basic Profile 유효성 검증`,
  [`Warn`]: `경고`,
  [`Warning`]: `경고`,
  [`Warning:`]: `경고:`,
  [`Warnings`]: `경고`,
  [`We just emailed recipients a test message:`]: `방금 수신인에게 테스트 메시지가 이메일로 발송되었습니다.`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `이는 API를 테스트하고 디버그할 수 있는 "테스트" 탭으로 이동됩니다.`,
  [`Weather Product`]: `기상 제품`,
  [`Web Endpoint`]: `웹 엔드포인트`,
  [`Web service operations`]: `웹 서비스 운영`,
  [`weight`]: `가중치`,
  [`Weight`]: `가중치`,
  [`Welcome to API Connect Administration`]: `API Connect 관리 시작`,
  [`Welcome to API Connect Reserved`]: `API Connect 예약 시작`,
  [`Welcome to API Designer`]: `API Designer 시작`,
  [`Welcome to API Manager`]: `API Manager 시작`,
  [`Welcome to Cloud Manager`]: `Cloud Manager 시작`,
  [`Welcome to the API Designer`]: `API Designer 시작`,
  [`Welcome to the API Manager`]: `API Manager 시작`,
  [`Welcome to the Cloud Manager`]: `Cloud Manager 시작`,
  [`What are you looking for today?`]: `무엇을 찾고 계십니까?`,
  [`What's new`]: `새로운 기능`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `다음 단계로, 카탈로그의 청구 통합을 활성화하여 사용자가 해당 제품에 플랜을 추가할 수 있게 합니다.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `true로 설정하면 호출 정책이 "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" 헤더를 대상 URL에 보낸 요청에 삽입합니다.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `공개되면 API 제품이 카탈로그와 연관된 개발자 포털에서 볼 수 있게 됩니다. 제품 내의 API에 대한 엔드포인트가 대상 카탈로그 또는 공간과 연관된 게이트웨이에서 사용 가능하게 됩니다. 스테이징된 제품은 카탈로그에 존재하나 활성 상태가 아니거나 이용자에게 표시되지 않습니다.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `공개되면 API 제품이 카탈로그와 연관된 개발자 포털에서 볼 수 있게 됩니다. 제품 내의 API에 대한 엔드포인트가 대상 카탈로그 또는 공간과 연관된 게이트웨이에서 사용 가능하게 됩니다. 스테이징된 제품은 카탈로그에 존재하나 활성 상태가 아니거나 이용자에게 표시되지 않습니다. 비프로덕션 카탈로그에서 제품을 다시 스테이징/다시 공개하면 기존 등록을 제거함을 유의하십시오.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `카탈로그에 공간을 사용할 수 있는 경우 구성된 OAuth 제공자가 공간에서 보여야 합니다.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `공간이 사용 가능하면 카탈로그 내의 기존 제품이 기본 공간으로 이동됩니다.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `사용자가 활성화 링크를 클릭하면, 등록 양식을 완료하도록 요청됩니다. 사용자가 등록한 후에 로그인할 수 있습니다.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `제공자 또는 이용자 조직에 대한 역할을 추가하는 경우 역할 기본값에 해당 역할이 포함됩니다. 역할 기본값은 새 조직이 작성될 때 사용 가능한 기본 역할을 판별하는 데 사용하는 사전 구성된 역할 템플리트입니다.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `제품을 사용 중단하는 경우 제품에 이미 등록된 애플리케이션 개발자는 제품을 계속 사용할 수 있지만 새 개발자는 제품에 등록할 수 없습니다.\n\n [Learn more]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `제공자 또는 이용자 조직에 대한 역할을 편집하는 경우 역할 기본값에 해당 역할이 포함됩니다. 역할 기본값은 새 조직이 작성될 때 사용 가능한 기본 역할을 판별하는 데 사용하는 사전 구성된 역할 템플리트입니다.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `OpenID Connect를 사용하는 경우, 액세스 토큰과 함께 ID 토큰을 생성할 수 있도록 템플리트가 제공되고 필수 어셈블리 정책이 자동으로 작성됩니다. 필요에 맞게 정책을 사용자 정의할 수 있습니다. 샘플 키는 테스트 용도로만 사용되며 JWT 토큰을 서명하는 데 사용됩니다.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `토큰 자체 검사를 사용하는 경우, 자체 검사 경로를 통해 액세스 토큰을 검사할 수 있습니다.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `토큰 자체 검사를 사용하는 경우, 액세스 토큰의 홀더가 자체 검사 경로를 사용하여 토큰의 컨텐츠를 검사할 수 있습니다. 자체 검사에 대한 액세스 토큰은 원시 OAuth 제공자를 통해 얻어야 합니다.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `제공자 조직의 구성원으로 사용자를 초대하는 경우 하나 이상의 역할을 지정하여 정보에 대한 액세스 레벨 및 수행할 수 있는 태스크를 제어합니다.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `제품 버전을 관리하는 경우, 초기에 카탈로그에 제품 버전을 스테이징하는 것부터, 공개하여 애플리케이션 개발자에게 제품 버전을 사용 가능하게 만드는 것을 통해, 결국 폐기하고 아카이브하는 것까지 일련의 라이프사이클 상태를 통해 이동합니다.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `제품을 다른 제품으로 교체하는 경우 다음 조치가 수행됩니다. 1) 교체 제품이 공개됩니다. 2) 교체 제품에서 원본 제품에서와 동일한 가시성, 등록자 및 게이트웨이 적용 설정이 사용됩니다. 3) 원본 제품의 등록자가 교체 제품으로 마이그레이션됩니다.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `제품을 다른 제품으로 대체하면 다음 조치가 수행됩니다. 1) 대체 제품은 원래 제품의 상태를 가정합니다. 2) 대체 제품의 가시성 및 구독 가능성 설정이 원래 제품과 동일하거나 덜 제한적인 경우 대체 제품의 설정이 사용됩니다. 대체 제품의 설정이 더 제한적이고 원래 제품에 대한 액세스 권한이 있는 일부 이용자 조직이 교체에 액세스하지 못하게 하는 경우, 대체 조작을 완료할 수 없습니다. 3) 원래 제품의 구독자가 대체 제품으로 마이그레이션되고, (4) 원래 제품이 폐기됩니다.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `제품을 폐기하는 경우 연관된 모든 API는 오프라인으로 전환되고 등록이 비활성화됩니다.\n\n [Learn more]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `제품을 다른 제품으로 대체하는 경우 다음 조치가 수행됩니다. 1) 대체 제품이 공개됩니다. 2) 대체 제품에서 원본 제품에서와 동일한 가시성, 등록자 및 게이트웨이 적용 설정이 사용됩니다. 3) 원본 제품이 더 이상 사용되지 않는 상태로 이동합니다. 제품이 더 이상 사용되지 않는 경우, 이미 제품에 등록된 애플리케이션 개발자는 제품을 계속 사용할 수 있지만 새 개발자는 제품에 등록할 수 없습니다. 필요한 경우 더 이상 사용되지 않는 제품을 다시 공개할 수 있습니다.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `제품을 다른 제품으로 대체하는 경우 다음 조치가 수행됩니다. 1) 대체 제품이 공개됩니다. 2) 대체 제품에서 원본 제품의 가시성 및 등록성 설정이 사용됩니다. 3) 원본 제품이 더 이상 사용되지 않는 상태로 이동합니다.  제품이 더 이상 사용되지 않는 경우, 이미 제품에 등록된 애플리케이션 개발자는 제품을 계속 사용할 수 있지만 새 개발자는 제품에 등록할 수 없습니다.  필요한 경우 더 이상 사용되지 않는 제품을 다시 공개할 수 있습니다.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `애플리케이션을 일시 중단하면 API 호출 작성에서 연관된 고객 ID가 차단됩니다. 언제든지 애플리케이션을 재개할 수 있습니다.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `사용하는 경우, 모든 소비자 조직 초대 제한시간은 카탈로그 초대 제한시간으로 설정되고 카탈로그 초대 제한시간에 대한 업데이트는 소비자 조직 초대 제한시간을 업데이트합니다.`,
  [`Where did the roles go?`]: `역할 이동 위치?`,
  [`Where to find the named rate limit.`]: `이름 지정된 속도 제한을 찾을 위치입니다.`,
  [`Where to put the decrypted result`]: `복호화된 결과를 배치할 위치입니다.`,
  [`Where to put the encrypted result`]: `암호화된 결과를 배치할 위치입니다.`,
  [`Whether to reuse the connection to the target server.`]: `대상 서버에 대한 연결을 재사용할지 여부입니다.`,
  [`Windows live`]: `Windows Live`,
  [`With`]: `대상`,
  [`Wildcards ignore xsi:type rule`]: `와일드카드가 xsi:type 규칙을 무시함`,
  [`Work offline`]: `오프라인 작업`,
  [`Work with existing definitions, models and/or data sources`]: `기존 정의, 모델 및/또는 데이터 소스에 대해 작업합니다.`,
  [`Working...`]: `작업 중입니다...`,
  [`Wrapper Policies`]: `랩퍼 정책`,
  [`Wrong slicing arguments`]: `잘못된 분할 인수`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL 및 XSD는 가져올 zip 파일 내 고유한 zip 파일에 있어야 합니다.`,
  [`XML Schema`]: `XML 스키마`,
  [`XML Schema URL`]: `XML 스키마 URL`,
  [`XML Schema preview`]: `XML 스키마 미리보기`,
  [`XML Validation Mode`]: `XML 유효성 검증 모드`,
  [`XML schema is not valid. Please correct the error.`]: `XML 스키마가 올바르지 않습니다. 오류를 정정하십시오.`,
  [`XML to JSON`]: `XML 대 JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT 버전`,
  [`YAML has been successfully validated`]: `YAML이 유효성 검증됨`,
  [`Yes`]: `예`,
  [`Yes, enable it`]: `예, 사용합니다.`,
  [`Yes, reset`]: `예, 재설정합니다.`,
  [`Yes. Update API Assembly`]: `예. API 어셈블리 업데이트`,
  [`You are authenticated!`]: `인증되었습니다!`,
  [`You do not have permission to use the Test Application`]: `테스트 애플리케이션을 사용할 수 있는 권한이 없습니다.`,
  [`You can also copy the link and send it directly to the user.`]: `해당 링크를 복사하고 직접 사용자에게 발송할 수도 있습니다.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `지금 API를 공개하지 않도록 선택하거나 테스트 또는 제품 엔드포인트 또는 둘 다에 즉시 공개할 수 있습니다. 필요에 따라 지정된 간격으로 호출의 수를 기반으로 하여 허용되는 최대 API 호출 비율을 지정할 수 있습니다.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `지금 API를 공개하지 않도록 선택하거나 테스트 또는 제품 엔드포인트 또는 둘 다에 즉시 공개할 수 있습니다. 필요에 따라 지정된 시간 간격당 호출의 수를 기반으로 하여 허용되는 최대 API 호출 비율을 지정할 수 있습니다.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `지금 API를 보안되지 않는 상태로 두거나 호출 애플리케이션이 클라이언트 ID를 제공해야 하도록 요청할 수 있습니다. API 정의를 작성한 후에 추가 보안 옵션을 구성할 수 있습니다.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `카탈로그 이름 또는 제목을 입력할 수 있습니다. 둘 다 있는 경우 카탈로그 이름이 우선합니다.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `이용자 조직 소유자에게 이메일을 보낼 수 있습니다. 메시지는 알림에서 구성된 이메일 서버를 사용하여 발송됩니다.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `이용자 조직 소유자에게 이메일을 보낼 수 있습니다. 메시지는 알림 섹션에서 구성된 이메일 서버를 사용하여 발송됩니다.`,
  [`You can send an email to a provider organization owner.`]: `제공자 조직 소유자에게 이메일을 보낼 수 있습니다.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `사용자의 이메일 주소를 입력하여 새 사용자에게 소유권을 이전할 수 있습니다. 등록하기 위한 활성화 링크가 포함된 이메일을 받게 됩니다. 등록하고 나면 사용자가 새 소유자가 됩니다. Cloud Manager에서 계속 작업할 수 있도록 현재 소유자에게 새 역할을 지정하십시오.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `사용자의 이메일 주소를 입력하여 등록된 사용자에게 소유권을 이전할 수 있습니다. Cloud Manager에서 계속 작업할 수 있도록 현재 소유자에게 새 역할을 지정하십시오.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `OpenAPI 정의 파일을 사용하여 IBM API Connect에서 REST API를 추가할 수 있습니다. 파일 형식은 JSON 또는 YAML일 수 있습니다.  API 정의를 가져온 경우 API 정의 목록에 표시됩니다.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `루트 유형('조회')이 제거되므로 이 유형은 삭제할 수 없습니다.`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `게이트웨이 유형이 이 제품의 게이트웨이 유형과 일치하지 않는 API를 선택할 수 없습니다.`,
  [`You currently don’t have any gateway extensions`]: `현재 게이트웨이 확장이 없음`,
  [`You currently don’t have any plans to migrate`]: `현재 마이그레이션할 플랜이 없음`,
  [`You currently don't have any policy.`]: `현재 정책이 없습니다.`,
  [`You do not have permission to any spaces.`]: `공간에 대한 권한이 없습니다.`,
  [`You do not have permission to publish products to any catalog or space.`]: `카탈로그 또는 공간에 제품을 공개할 권한이 없습니다.`,
  [`You do not have permission to stage products to any catalog or space.`]: `카탈로그 또는 공간에 제품을 스테이징할 권한이 없습니다.`,
  [`You don’t currently have any billing integrations.`]: `현재 청구 통합이 없습니다.`,
  [`You don't currently have any response.`]: `현재 응답이 없습니다.`,
  [`You don't currently have any user-defined policies.`]: `현재 사용자 정의 정책이 없습니다.`,
  [`You don't have permission to access this page.`]: `이 페이지에 액세스 권한이 없습니다.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `이 인증서를 수정했습니다. 이 애플리케이션을 저장하면 해당 구성이 업데이트됩니다.`,
  [`You haven't added any APIs or Products`]: `API 또는 제품을 추가하지 않았음`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `카탈로그에 새 사용자를 추가하고 추가된 사용자에게 역할을 지정하여 카탈로그 멤버십을 관리합니다.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `공간에 새 사용자를 추가하고 추가된 사용자에게 역할을 지정하여 공간 멤버십을 관리합니다.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `공간에 새 사용자를 추가하고 추가된 사용자에게 역할을 지정하여 공간 멤버십을 관리합니다.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `공개할 준비가 된 제품에 API를 추가해야 합니다. 제품은 개발자가 사용할 수 있도록 만든 하나의 오퍼링에 일련의 API 및 플랜을 수집합니다. 플랜에는 전체적으로 플랜에 적용하거나 API의 각 오퍼레이션에 대해 지정할 수 있는 속도 제한 설정이 포함됩니다.`,
  [`You must choose a definition`]: `정의를 선택해야 합니다.`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `이 카탈로그에 공개된 API가 게이트웨이 서비스 엔드포인트에서 호출될 수 있도록 하나 이상의 게이트웨이 서비스를 사용으로 설정해야 합니다. 여러 게이트웨이 서비스를 사용으로 설정할 수 있습니다.`,
  [`You must enable spaces`]: `공간을 사용할 수 있어야 합니다.`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `애플리케이션을 개발할 때 사용할 클라이언트 ID 및 시크릿을 기록해 두십시오. 클라이언트 시크릿은 다시 표시될 수 없습니다.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `API Connect 예약 인스턴스 라이센스를 통해 하나 이상의 원격 DataPower API 게이트웨이를 다운로드하고 설정할 수 있습니다. 게이트웨이는 온프레미스 또는 모든 클라우드에 있을 수 있습니다.`,
  [`Your API has been created`]: `API가 작성됨`,
  [`Your API is live!`]: `API가 활성 상태입니다.`,
  [`Your API is online!`]: `API가 온라인 상태임!`,
  [`Your Catalog is being deleted. This may take a while...`]: `카탈로그를 삭제하는 중입니다. 시간이 조금 걸릴 수 있습니다...`,
  [`Your Consumer organization is being created. This may take a while...`]: `이용자 조직을 작성하는 중입니다. 시간이 조금 걸릴 수 있습니다...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `초대가 더 이상 유효하지 않습니다. 초대 제한시간 설정을 확인하고 초대를 다시 보내십시오.`,
  [`Your Provider organization is being created. This may take a while...`]: `제공자 조직을 작성하는 중입니다. 시간이 조금 걸릴 수 있습니다...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `제공자 조직을 삭제하는 중입니다. 시간이 조금 걸릴 수 있습니다...`,
  [`Your member is being deleted. This may take a while...`]: `귀하의 멤버를 삭제 중입니다. 시간이 조금 걸릴 수 있습니다...`,
  [`Your Space is being deleted. This may take a while...`]: `공간을 삭제하는 중입니다. 시간이 조금 걸릴 수 있습니다...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `OAuth 제공자에 저장되지 않은 변경사항이 있습니다. 저장하지 않고 계속하려면 확인을 클릭하십시오.`,
  [`Your credentials have expired.`]: `신임 정보가 만료되었습니다.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `다음 초대 링크와 함께 초대장이 발송되었습니다.`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `아직 요청을 처리하는 중이며 예상보다 더 오래 걸릴 수 있습니다. 잠시 후 새로 고친 후 요청을 재시도하십시오.`,
  [`Your product will be saved before proceeding, are you sure?`]: `제품이 처리 전에 저장됩니다. 계속하시겠습니까?`,
  [`Your trial ends on {date}`]: `{date}에 평가판이 종료됩니다.`,
  [`Your trial expired on {date}.`]: `{date}에 평가판이 만료되었습니다.`,
  [`Zoom in`]: `확대`,
  [`Zoom in and out`]: `확대/축소`,
  [`Zoom out`]: `축소`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `대상 서버에서 CORS 지원 부족(아래 제안 참조)`,
  [`a LDAP provider`]: `LDAP 제공자`,
  [`activity`]: `활동`,
  [`add item...`]: `항목 추가...`,
  [`add property...`]: `특성 추가...`,
  [`added`]: `추가됨`,
  [`admin`]: `관리자`,
  [`administrator`]: `관리자`,
  [`all`]: `모두`,
  [`an API Connect Local User Registry`]: `API Connect 로컬 사용자 레지스트리`,
  [`an authentication URL`]: `인증 URL`,
  [`an untrusted certificate has been encountered`]: `신뢰할 수 없는 인증서가 발생함`,
  [`and`]: `및`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `애플리케이션:`,
  [`application/json`]: `애플리케이션/json`,
  [`application/xml`]: `애플리케이션/xml`,
  [`approval`]: `승인`,
  [`approval task for product`]: `제품의 승인 태스크`,
  [`archived`]: `아카이브됨`,
  [`authenticated`]: `인증됨`,
  [`authorization code time to live (seconds)`]: `권한 부여 코드 수명(초)`,
  [`Authorization header pass through`]: `권한 부여 헤더 패스스루`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `블로킹`,
  [`body`]: `본문`,
  [`body-param`]: `body-param`,
  [`cancel`]: `취소`,
  [`case`]: `케이스`,
  [`catalog`]: `카탈로그`,
  [`catalog:`]: `카탈로그:`,
  [`catch`]: `발견`,
  [`changed`]: `변경됨`,
  [`cipher`]: `암호`,
  [`code id_token`]: `코드 id_token`,
  [`code id_token token`]: `코드 id_token 토큰`,
  [`code token`]: `코드 토큰`,
  [`Consume`]: `이용`,
  [`consumer`]: `이용자`,
  [`consumes`]: `다음을 이용`,
  [`composite`]: `컴포지트`,
  [`created`]: `작성됨`,
  [`Created API`]: `작성된 API`,
  [`custom string`]: `사용자 정의 문자열`,
  [`day`]: `일`,
  [`day(s)`]: `일`,
  [`default`]: `기본값`,
  [`default value`]: `기본값`,
  [`definition`]: `정의`,
  [`deprecated`]: `더 이상 사용되지 않음`,
  [`description`]: `설명`,
  [`development`]: `개발`,
  [`edit condition`]: `조건 편집`,
  [`edited`]: `편집됨`,
  [`environment`]: `카탈로그`,
  [`error`]: `오류`,
  [`false`]: `false`,
  [`file`]: `파일`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 아이콘`,
  [`for`]: `대상`,
  [`header`]: `헤더`,
  [`hear`]: `듣기`,
  [`hello`]: `환영합니다!`,
  [`helloName`]: `{name}님을 환영합니다!`,
  [`here`]: `여기`,
  [`hit`]: `히트`,
  [`hits`]: `히트`,
  [`hour`]: `시간`,
  [`hour(s)`]: `시간`,
  [`hours`]: `시간`,
  [`identified`]: `식별됨`,
  [`Increased`]: `증가됨`,
  [`Increment`]: `증분`,
  [`info`]: `정보`,
  [`information about external documentation`]: `외부 문서에 대한 정보`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `OIDC 제공자의 exp 청구를 준수하는 대신 APIC에서 생성된 access_token/refresh_token은 APIC 설정을 준수`,
  [`introspect URL`]: `URL 자체 검사`,
  [`Issue`]: `문제`,
  [`Issues`]: `문제점`,
  [`item`]: `항목`,
  [`iterate over`]: `반복`,
  [`last modified`]: `마지막 수정 날짜`,
  [`Log Level`]: `로그 레벨`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `로그`,
  [`Logout`]: `로그아웃`,
  [`Logout redirect`]: `로그아웃 경로 재지정`,
  [`Logout redirect URL`]: `로그아웃 경로 재지정 URL`,
  [`location`]: `위치`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `분`,
  [`minute(s)`]: `분`,
  [`minutes`]: `분`,
  [`mutation type`]: `변형 유형`,
  [`mutual SSL authentication is required`]: `상호 SSL 인증은 필수임`,
  [`name`]: `이름`,
  [`Name|extension`]: `이름`,
  [`Name|path parameters`]: `이름`,
  [`Name|security definitions`]: `이름`,
  [`non-blocking`]: `비차단`,
  [`none`]: `없음`,
  [`none selected`]: `선택 항목 없음`,
  [`Object`]: `오브젝트`,
  [`off`]: `꺼짐`,
  [`of {total} pages`]: `/ {total} 페이지`,
  [`of {total} page`]: `{total} 페이지 중`,
  [`offline`]: `오프라인`,
  [`on`]: `켜짐`,
  [`online`]: `온라인`,
  [`or`]: `또는`,
  [`or redact from all`]: `또는 모든 위치에서 수정`,
  [`open and close list of options`]: `옵션 목록 열기 및 닫기`,
  [`organization saved`]: `저장된 조직`,
  [`organization-manager`]: `조직 관리자`,
  [`otherwise`]: `기타`,
  [`payload`]: `페이로드`,
  [`Payload`]: `페이로드`,
  [`pending`]: `보류 중`,
  [`produces`]: `작성`,
  [`production`]: `프로덕션`,
  [`production and development`]: `프로덕션 및 개발`,
  [`property`]: `특성`,
  [`provider`]: `제공자`,
  [`publish product`]: `제품 공개`,
  [`published`]: `공개됨`,
  [`query type`]: `조회 유형`,
  [`query-parameters`]: `조회-매개변수`,
  [`realized`]: `실현됨`,
  [`recipients`]: `수신인`,
  [`removed`]: `제거됨`,
  [`replaced`]: `대체됨`,
  [`repeatable`]: `반복 가능`,
  [`Replenish`]: `보충`,
  [`request`]: `요청`,
  [`requested by`]: `요청자`,
  [`Resolver weight`]: `분석기 가중치`,
  [`response`]: `응답`,
  [`response-param`]: `response-param`,
  [`retired`]: `폐기됨`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `저장됨`,
  [`scalar`]: `스칼라`,
  [`second`]: `초`,
  [`second(s)`]: `초`,
  [`seconds`]: `초`,
  [`select owner user`]: `소유자 사용자를 선택합니다`,
  [`select user`]: `사용자 선택`,
  [`soap-body`]: `soap-body`,
  [`source`]: `소스`,
  [`space`]: `공간`,
  [`Space settings`]: `공간 설정`,
  [`Skip`]: `건너뛰기`,
  [`specified`]: `지정되어 있음`,
  [`staged`]: `스테이징됨`,
  [`start generating tests automatically.`]: `테스트 자동 생성을 시작하십시오.`,
  [`subject`]: `제목`,
  [`subscription type`]: `등록 유형`,
  [`supersede`]: `선후관계`,
  [`superseded`]: `대체됨`,
  [`target`]: `대상`,
  [`the server is unavailable`]: `서버가 사용 불가능함`,
  [`this user registry type`]: `이 사용자 레지스트리 유형`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `재공개를 수행하면 등록이 유지되는 임시 공개 페이지입니다.`,
  [`to production`]: `프로덕션으로`,
  [`topology-administrator`]: `토폴로지 관리자`,
  [`Trial days left`]: `평가판에 남은 일 수`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `ttl이 변경됨`,
  [`type`]: `유형`,
  [`unarchived`]: `아카이브 해제됨`,
  [`union type`]: `유니온 유형`,
  [`unlimited`]: `무제한`,
  [`updated`]: `업데이트됨`,
  [`Updated from new schema`]: `새 스키마에서 업데이트됨`,
  [`Upgrade`]: `업그레이드`,
  [`v5 legacy behavior`]: `v5 레거시 동작`,
  [`version 2`]: `버전 2`,
  [`version 3`]: `버전 3`,
  [`viewer`]: `뷰어`,
  [`value`]: `값`,
  [`warn`]: `경고`,
  [`warning`]: `경고`,
  [`warnings`]: `경고`,
  [`was not found. Please select a configured OAuth provider.`]: `찾을 수 없습니다. 구성된 OAuth 제공자를 선택하십시오.`,
  [`week`]: `주`,
  [`with`]: `승인 방법`,
  [`with|supersede with`]: `승인 방법`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `샌드박스의 상태`,
  [`your file here, or`]: `여기에 파일을 끌어서 놓기`,
  [`{count} found!`]: `{count}개가 발견되었습니다!`,
  [`{current} of {total} page`]: `{current}/{total}페이지`,
  [`{current} of {total} pages`]: `{current}/{total} 페이지`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days}일 {hours}시간 {minutes}분`,
  [`{days} day(s) and {hours} hour(s)`]: `{days}일 {hours}시간`,
  [`{docType} is required`]: `{docType}은(는) 필수임`,
  [`{docType} title`]: `{docType} 제목`,
  [`Product title`]: `제품 제목`,
  [`Application title`]: `애플리케이션 제목`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours}시간 {minutes}분`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API 또는 제품에 지정된 게이트웨이 유형과 상충함에 따라 {incompatibleGatewayCount}개의 게이트웨이가 숨겨짐`,
  [`{min}-{max} of {total} items`]: `{min}-{max}/{total}개 항목`,
  [`{min}-{max} of {total} item`]: `{min} - {max}/{total} 항목`,
  [`{min}-{max} of {total} Policies`]: `{total}개 정책 중 {min}-{max}개`,
  [`{min}-{max} of {total} Policy`]: `{min} - {max}/{total} 정책`,
  [`{min}-{max} of {total} types`]: `{min}-{max}/{total}개 유형`,
  [`{min}-{max} of {total} type`]: `{min} - {max}/{total} 유형`,
  [`{min}-{max} of {total} search results`]: `{min} - {max}/{total}개의 검색 결과`,
  [`{min}-{max} of {total} search result`]: `{min} - {max}/{total} 검색 결과`,
  [`{min}-{max} of {total} Catalogs`]: `{total}개 카탈로그 중 {min}-{max}개`,
  [`{min}-{max} of {total} Catalog`]: `{min} - {max}/{total} 카탈로그`,
  [`{min}-{max} of {total} Spaces`]: `{total}개 공간 중 {min}-{max}개`,
  [`{min}-{max} of {total} Space`]: `{min} - {max}/{total} 공간`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max}개의 {total}개 확장`,
  [`{min}-{max} of {total} Extension`]: `{min} - {max}/{total} 확장`,
  [`{min}-{max} of {total} Product Plans`]: `{min} - {max}/{total}개 제품 플랜`,
  [`{min}-{max} of {total} Product Plan`]: `{min} - {max}/{total} 제품 플랜`,
  [`{min}-{max} of {total} Gateways`]: `{min} - {max}/{total}개 게이트웨이`,
  [`{min}-{max} of {total} Gateway`]: `{min} - {max}/{total} 게이트웨이`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (API)(API)에 'x-ibm-configuration'이 없습니다.`,
  [`{name} (Product) has been published!`]: `{name}(제품)이(가) 공개되었습니다!`,
  [`{name} (Product) has been staged!`]: `{name}(제품)이(가) 스테이징되었습니다!`,
  [`{name} (Product) has not been published!`]: `{name}(제품)이(가) 공개되지 않았습니다!`,
  [`{name} (Role) has been created`]: `{name}(역할)이(가) 작성되었습니다.`,
  [`{name} successfully deleted`]: `{name}이(가) 삭제되었습니다.`,
  [`{name} (Role) has been updated`]: `{name}(역할)이(가) 업데이트되었습니다.`,
  [`{name} has been assigned the owner`]: `{name}이(가) 소유자로 지정되었습니다.`,
  [`{number} Error`]: `{number}개 오류`,
  [`{number} Errors`]: `{number}개 오류`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{username}에 의해 요청된 {prodname}:{version} 제품에 대한 {operation} 승인 태스크`,
  [`{path} does not exist`]: `{path}이(가) 존재하지 않음`,
  [`{productName} has no migration target set.`]: `{productName}에 마이그레이션 대상이 설정되어 있지 않습니다.`,
  [`{state} Pending`]: `{state} 보류 중`,
  [`{status} API error`]: `{status} API 오류`,
  [`{status} Bad Request`]: `{status} 잘못된 요청`,
  [`{status} Forbidden`]: `{status} 금지됨`,
  [`{status} Gateway Error`]: `{status} 게이트웨이 오류`,
  [`{status} Unauthorized`]: `{status} 권한 없음`,
  [`({time} after being sent)`]: `(전송된 후 {time})`,
  [`({time} after being queued)`]: `(큐에 대기된 후 {time})`,
  [`{title} (Product) has been {state}.`]: `{title}(제품)이(가) {state}되었습니다.`,
  [`{title} has been deleted`]: `{title}이(가) 삭제됨`,
  [`{title} has been {changedName}.`]: `{title}이(가) {changedName}`,
  [`{title} has not been deleted!`]: `{title}이(가) 삭제되었습니다!`,
  [`{title} has not been {changedName}!`]: `{title}이(가) {changedName}(으)로 변경되지 않았습니다!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type}이(가) {changedName}`,
  [`{type} has not been {changedName}!`]: `{type}이(가) {changedName}(으)로 변경되지 않았습니다!`,
  [`{type} history log`]: `{type} 히스토리 로그`,
  [`{username} does not exist`]: `{username} 존재하지 않음`,
  [`{username} does not exist in the user registry`]: `{username}이(가) 사용자 레지스트리에 없음`,
  [`{username} is not associated with this consumer org`]: `{username}이(가) 이 이용자 조직과 연관되어 있지 않음`,
  [`{username} has been created as a {type}.`]: `{username}이(가) {type}(으)로 작성되었습니다.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `{consumerOrg} 개발자 포털에 {appName} 앱이 복원됨`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `{consumerOrg} 개발자 포털에서 {appName} 앱이 일시중단됨`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} 개발자 포털 계정 등록`,
  [`{{count}} errors need attention`]: `{count}개의 오류 확인 필요`,
  // lts only translations
  [`Configure JSON settings`]: `JSON 설정 구성`,
  [`Configure XML settings`]: `XML 설정 구성`,
  [`Configure options`]: `옵션 구성`,
  [`Detect`]: `발견`,
  [`Document type`]: `문서 유형`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `최대 문서 크기`,
  [`Maximum name length`]: `최대 이름 길이`,
  [`Maximum nesting depth`]: `최대 중첩 깊이`,
  [`Maximum number length`]: `최대 숫자 길이`,
  [`Maximum number of unique names`]: `최대 고유 이름 수`,
  [`Maximum number of unique namespaces`]: `최대 고유 네임스페이스 수`,
  [`Maximum number of unique prefixes`]: `최대 고유 접두부 수`,
  [`Maximum value length`]: `최대 값 길이`,
  [`Maximum width`]: `최대 너비`,
  [`Maximum stack size`]: `최대 스택 크기`,
  [`Parse settings`]: `구문 분석 설정`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `구문 분석 조치에서 허용하는 최대 문서 크기(바이트)를 지정합니다.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `구문 분석 조치에서 허용하는 XML 또는 JSON 메시지에서 중첩 요소 깊이의 최대 레벨을 지정합니다.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `구문 분석 조치에서 허용하는 XML, JSON 또는 GraphQL 메시지에서 중첩 요소 깊이의 최대 레벨을 지정합니다.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `구문 분석 조치에서 허용하는 최대 이름 길이(바이트)를 지정합니다.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `값이 숫자일 경우 레이블-값 쌍의 값 부분에서 바이트 수를 제한하는 최대 숫자 길이를 지정합니다.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `구문 분석 조치에서 허용하는 고유한 XML 네임스페이스 URI의 최대 개수를 지정합니다. 이 한계는 선언에 사용되는 접두부의 수에 관계없이 모든 XML 네임스페이스를 계수합니다.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `구문 분석 조치에서 허용하는 문서에서 고유한 XML 네임스페이스 접두부의 최대 개수를 지정합니다. 이 한계는 동일한 네임스페이스에 정의된 여러 개의 접두부를 계수하지만 단일 접두부 아래에서 입력 문서의 다른 파트에 정의된 여러 개의 네임스페이스는 계수하지 않습니다.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `구문 분석 조치에서 허용하는 최대 고유 이름 수를 지정합니다.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `구문 분석 조치에서 허용하는 최대 값 길이(바이트)를 지정합니다.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `구문 분석 조치에서 허용하는 최대 페이로드 너비를 지정합니다.`,
  [`Specifies the type of document to parse.`]: `구문 분석할 문서 유형을 지정합니다.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `XSLT 프로세서 버전을 지정합니다. 기본값은 XSLT10입니다.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `엄격한 XSLT 오류 검사를 사용할지 여부를 지정합니다. 엄격하지 않은 조작에서는 비선언 변수 사용, 비선언 템플리트 호출 등 특정 오류로부터 복구하려고 시도합니다. 기본적으로 엄격한 XSLT 오류 검사가 사용됩니다.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `스타일시트 프로파일링을 사용할지 여부를 지정합니다. 이 옵션은 프로덕션 환경에서 사용하면 안 됩니다. 기본적으로 스타일시트 프로파일링은 사용되지 않습니다.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `디버그 모드에서 스타일시트, XQuery 스크립트 및 JSONiq 스크립트를 실행할지 여부를 지정합니다. 스타일시트, XQuery 스크립트 또는 JSONiq 스크립트가 디버그 모드에서 실행되면 일반 출력을 표시하는 대신 사용자 정의 웹 페이지를 생성합니다. 웹 페이지는 변수 값과 출력의 특정 부분이 발생한 위치를 포함하여 실행 중에 발생한 결과를 정확히 설명합니다. 이 옵션은 프로덕션 환경에서 사용하지 않아야 합니다. 기본적으로 디버그 모드는 사용 안함으로 설정됩니다.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `스타일시트를 스트리밍 모드에서 실행해야 하는지 여부를 지정합니다. 입력이 완전히 구문 분석되기 전에 문서 변환이 시작됩니다. 일부 스타일시트는 스트리밍할 수 없습니다. 스타일시트를 스트리밍할 수 없지만 오류가 생성되고 입력이 처리되지 않습니다. 기본적으로 스트리밍 모드는 사용되지 않습니다.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `스트리밍 모드에서 스타일시트를 실행하려고 시도할지 여부를 지정합니다. 입력이 완전히 구문 분석되기 전에 문서 변환이 시작됩니다. 일부 스타일시트는 스트리밍할 수 없습니다. 스타일시트를 스트리밍할 수 없는 경우 컴파일링 중에 경고가 생성되고 실행 시 전체 입력에서 정상적으로 스타일시트를 읽습니다. 기본적으로 스트리밍 모드에서 스타일시트를 실행하려는 시도는 사용 불가능합니다.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `처리 중에 스타일시트에서 생성된 출력을 이스케이프 처리할지 여부를 지정합니다. 최소 이스케이프는 특히 영어 외의 문자 세트를 처리할 때 유용합니다. 기본적으로 최소 이스케이프는 사용되지 않습니다.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `스타일시트 또는 기타 컴파일된 컨텐츠를 실행하는 동안 스택이 사용할 수 있는 최대 바이트 수를 표시합니다. 이 설정은 무한 순환 차단에 사용됩니다. 최소값은 10KB 또는 10,240바이트입니다. 최대값은 100MB 또는 104,857,600바이트입니다. 기본값은 1MB 또는 1,048,576바이트입니다.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `WS-I Basic Profile(버전 1.0, 2004년 4월)의 섹션 5를 준수하는지 검사되는 WSDL 파일에 적용할 유효성 검증 동작을 지정합니다. 기본 설정은 Warn입니다.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `soap:Body에 대한 유효성 검증 동작을 지정합니다. 기본 설정은 Strict입니다.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `soap:Header에 대한 유효성 검증 동작을 지정합니다. 기본 설정은 Lax입니다.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `결함 세부사항에 대한 유효성 검증 동작을 지정합니다. 기본 설정은 Strict입니다.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `RPC-스타일 랩퍼와의 호환성을 요구할지 여부를 지정합니다. 기본적으로 RPC-스타일 랩퍼는 필요하지 않습니다.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `SOAP 1.1 섹션 5와 일치하는 xsi:type='SOAP-ENC:Array'가 포함된 요소를 최대한 사용하도록 스키마에서 허용할지 여부를 지정합니다. 일반적으로 xsi:type 속성은 요소의 실제 유형과 동일하거나 해당 유형에서 파생된 유형을 지정해야 합니다. 이 옵션으로 컴파일된 스키마의 경우 요소 유형이 SOAP-ENC:Array에서 파생된 경우 특히 SOAP 1.1 인코딩 '배열' 복합 유형에서 xsi:type이 허용됩니다. 그 반대는 일반적 허용 케이스입니다. 기본적으로 xsi:type='SOAP-ENC:Array'가 포함된 요소는 허용되지 않습니다.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `SOAP 1.1 섹션 5의 인코딩 규칙에 따라 추가 스키마 유효성 검증을 수행할지 여부를 지정합니다. 사용되면, SOAP 배열의 멤버가 유효성 검증되고 @id 및 @href와 같은 속성은 스키마에서 허용하지 않더라도 허용되며 @href 값은 해당 @id 요소를 포함하는지 확인하기 위해 검사됩니다. 기본적으로 추가 유효성 검증은 수행되지 않습니다.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `스키마의 xs:any 요소가 이름으로 하위 요소만 유효성 검증하는지 여부를 지정합니다. XML 스키마 스펙에서는 와일드카드가 요소와 일치하지만 이 요소에 요소 선언이 없으면 xsi:type 속성에 따라 요소를 대신 유효성 검증해야 합니다. 이 옵션은 xsi:type 속성을 무시합니다. 추가 유효성 검증 단계에서 SOAP 1.1 인코딩 규칙을 사용하여 와일드카드와 일치하는 컨텐츠를 유효성 검증하는 SOAP 엔벨로프 유효성 검증과 같은 경우에 이 옵션을 사용해야 합니다. 기본적으로 xsi:type 속성은 무시되지 않습니다.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `WSDL에서 SOAP 바인딩을 엄격하게 따를지 여부를 지정합니다. 사용되면, SOAP 1.2에 바인드된 메시지만 SOAP 1.2 엔벨로프에 나타나고 SOAP 1.1에 바인드된 메시지만 SOAP 1.1 엔벨로프에 나타납니다. 기본적으로 엄격한 SOAP 바인딩은 사용되지 않습니다.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `디버그 정보로 XACML 정책을 컴파일할지 여부를 지정합니다. XACML 디버깅 메시지는 XACML 카테고리의 로그 이벤트로도 제어할 수 있습니다. 전체 XACML 디버깅 메시지를 보려면 디버그 로그 레벨을 사용하십시오. 기본적으로 XACML 정책은 디버그 정보로 컴파일되지 않습니다.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `스키마 또는 WSDL 문서가 MTOM/XOP 스펙에 따라 base64로 인코딩된 2진 컨텐츠가 최적화된 메시지를 승인하는지 여부를 지정합니다. XOP 2진 최적화에서 base64로 인코딩된 2진 데이터를 첨부 파일에 있는 인코딩되지 않은 2진 데이터를 참조하는 xop:Include 참조 요소로 대체합니다. 기본적으로 MTOM/XOP 최적화 메시지는 사용되지 않습니다.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `이용자 조직 그룹은 이용자 조직의 콜렉션이며 제품의 API를 보고 등록할 수 있는 사용자를 제어하는 효율적인 방법을 제공합니다. 이용자 조직 그룹을 사용하면 조직에 대한 액세스 권한을 별도로 정의할 필요 없이 단일 오퍼레이션으로 해당 그룹의 조직 내 모든 개발자 대한 이 액세스 권한을 정의할 수 있습니다.`,
  [`API Connect Home`]: `API Connect 홈`,
  [`Add new group`]: `새 그룹 추가`,
  [`Add consumer organizations to the group as required`]: `필요에 따라 그룹에 이용자 조직 추가`,
  [`Add the following configuration`]: `다음 구성 추가`,
  [`Add the following configuration to`]: `다음 구성의 추가 대상`,
  [`Additions`]: `추가`,
  [`Additional GraphQL capabilities`]: `추가 GraphQL 기능`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `이 플랜에 포함된 graphQL API에 대한 추가 설정`,
  [`An organization or a group is required.`]: `조직 또는 그룹은 필수입니다.`,
  [`An organization/group is required.`]: `조직/그룹은 필수입니다.`,
  [`Analysis configuration`]: `분석 구성`,
  [`Application & Credentials`]: `애플리케이션 및 신임 정보`,
  [`Are you sure you want to delete this API version?`]: `이 API 버전을 삭제하시겠습니까?`,
  [`Are you sure you want to delete this API with all versions?`]: `모든 버전을 포함하여 이 API를 삭제하시겠습니까?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `게이트웨이에서 게이트웨이 확장을 제거하시겠습니까?`,
  [`Are you sure you want to rename all versions of this API?`]: `이 API의 모든 버전 이름을 바꾸시겠습니까?`,
  [`Are you sure you want to rename this API version?`]: `이 API 버전의 이름을 바꾸시겠습니까?`,
  [`Associate groups to the organization as required`]: `필요에 따라 그룹을 조직과 연관`,
  [`At least one option must be enabled`]: `하나 이상의 옵션을 사용으로 설정해야 함`,
  [`Attribute mapping`]: `속성 맵핑`,
  [`Attempt streaming rule`]: `스트리밍 규칙 시도`,
  [`Auto generated default paths and operations`]: `자동 생성된 기본 경로 및 오퍼레이션`,
  [`Base API endpoint`]: `기본 API 엔드포인트`,
  [`Consumer orgs`]: `이용자 조직`,
  [`Calculate Query Characteristics`]: `조회 특성 계산`,
  [`Calculate Response Characteristics`]: `응답 특성 계산`,
  [`Certificate or Shared Secret for Verify`]: `확인용 인증서 또는 공유 본인확인정보`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `최대 일주일 동안 전체 API 관리 기능 사용해 보기를 요청할 수 있는 Hands on Labs를 확인해 보십시오.`,
  [`Clone API`]: `API 복제`,
  [`Consumer organizations and groups`]: `이용자 조직 및 그룹`,
  [`Create new API version`]: `새 API 버전 작성`,
  [`Create new product version`]: `새 제품 버전 작성`,
  [`Create a GraphQL API based on a GraphQL service`]: `GraphQL 서비스를 기반으로 GraphQL API 작성`,
  [`Custom rate limit`]: `사용자 정의 속도 제한`,
  [`Delete API`]: `API 삭제`,
  [`Delete this version`]: `이 버전 삭제`,
  [`Deletion confirmation`]: `삭제 확인`,
  [`Deletions`]: `삭제`,
  [`Display name`]: `표시 이름`,
  [`Display Name`]: `표시 이름`,
  [`Details`]: `세부사항`,
  [`Edit GraphQL Rate Limit|title`]: `GraphQL 속도 제한|제목 편집`,
  [`Edit GraphQL rate limits`]: `GraphQL 비율 한계 편집`,
  [`Edit group`]: `그룹 편집`,
  [`Edit condition`]: `조건 편집`,
  [`Edit rate limit|title`]: `속도 제한 편집`,
  [`Edit rate limit|menu`]: `속도 제한 편집`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `이 카탈로그에 공개된 제품이 없거나 이 애플리케이션이 이미 모든 제품의 모든 플랜에 등록되었습니다.`,
  [`Enforce Policy`]: `정책 적용`,
  [`Enter a group summary`]: `그룹 요약 입력`,
  [`Enter a group title`]: `그룹 제목 입력`,
  [`Enter group details below`]: `아래에 그룹 세부사항 입력`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `프록시하려는 GraphQL 서비스의 URL 및 스키마 이름 입력`,
  [`Enum value`]: `열거 값`,
  [`Go to catalog settings to configure a new billing integration`]: `카탈로그 설정으로 이동하여 새 청구 통합 구성`,
  [`GraphQL introspect`]: `GraphQL 자체 검사`,
  [`GraphQL server`]: `GraphQL 서버`,
  [`GraphQL Setting`]: `GraphQL 설정`,
  [`GraphQL server URL`]: `GraphQL 서버 URL`,
  [`GraphQL server name`]: `GraphQL 서버 이름`,
  [`GraphQL type analysis configuration`]: `GraphQL 유형 분석 구성`,
  [`GraphQL resolver analysis configuration`]: `GraphQL 분석기 분석 구성`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `다음 목록에서 그룹을 작성하고 관리할 수 있습니다.`,
  [`Groups will be listed here.`]: `여기에 그룹이 나열됩니다.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `{firstName}님을 환영합니다.\n\n{catalog} 카탈로그에서 API 제품 {productName}:{version} {action}에 대한\n요청이 승인되었습니다.\n\n{#if 주석}\n승인자가 다음 주석을 포함했습니다.\n\n{comments}\n\n{{/if}}\n다음 링크에서 제품의 상태를 확인할 수 있습니다.\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `환영합니다.\n\n이는 구성된 메일 서버 {mailServer}에서 발송된 IBM API Connect의\n 테스트 메시지입니다.\n\n예상대로 이 메시지를 수신한 경우 이 테스트는 성공입니다!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `환영합니다.\n\n사용자 {originator}이(가) {catalog} 카탈로그에서 {appName} 애플리케이션을 사용하여\n{productName} API 제품의 {productVersion} 버전에 대한 {planName} 플랜을 등록하라는 \n요청을 철회했습니다.\n\n추가 조치가 필요하지 않습니다.\n`,
  [`Invoke GraphQL server`]: `GraphQL 서버 호출`,
  [`Invoke the backend GraphQL server`]: `백엔드 GraphQL 서버 호출`,
  [`JWKS endpoint`]: `JWKS 엔드포인트`,
  [`LDAP Field Name`]: `LDAP 필드 이름`,
  [`Limit maximum nesting per query`]: `조회당 최대 중첩 제한`,
  [`Limit resolve complexity rate on a per key basis`]: `키 단위로 해석 복잡도 비율 제한`,
  [`Limit type complexity rate on a per key basis`]: `키 단위로 유형 복잡도 비율 제한`,
  [`Manage APIs included in this product`]: `이 제품에 포함된 API 관리`,
  [`Manage groups`]: `그룹 관리`,
  [`Maximum nesting`]: `최대 중첩`,
  [`Maximum resolve complexity`]: `최대 해석 복잡도`,
  [`Maximum type complexity`]: `최대 유형 복잡도`,
  [`Name of the group`]: `그룹의 이름`,
  [`New API version`]: `새 API 버전`,
  [`New GraphQL API`]: `새 GraphQL API`,
  [`New product version`]: `새 제품 버전`,
  [`New Version`]: `새 버전`,
  [`New version`]: `새 버전`,
  [`No billing integration exists`]: `청구 통합이 없음`,
  [`No CA bundles available`]: `사용 가능한 CA 번들 없음`,
  [`No Data`]: `데이터 없음`,
  [`No certificates available`]: `사용 가능한 인증서 없음`,
  [`No data`]: `데이터 없음`,
  [`No group found`]: `그룹이 없음`,
  [`OAuth Auth URL`]: `OAuth 인증 URL`,
  [`OAuth Token URL`]: `OAuth 토큰 URL`,
  [`Open Stripe dashboard`]: `Stripe 대시보드 열기`,
  [`Operation/Path`]: `오퍼레이션/경로`,
  [`Operation Path`]: `오퍼레이션 경로`,
  [`Operations and Paths`]: `오퍼레이션 및 경로`,
  [`Operator`]: `연산자`,
  [`Organizations and groups will be listed here.`]: `여기에 조직 및 그룹이 나열됩니다.`,
  [`Page not found`]: `페이지를 찾을 수 없음`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `프로세스를 계속하려면 아래 코드를 CLI에 복사하십시오. 코드는 **{ttl}**에 만료됩니다.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Stripe 대시보드를 참조하여 API 키 및 시크릿에 액세스하십시오.`,
  [`Please upload a schema definition language (SDL) file.`]: `SDL(Schema Definition Language) 파일을 업로드하십시오.`,
  [`Please upload a valid GraphQL schema.`]: `올바른 GraphQL 스키마를 업로드하십시오.`,
  [`Please upload a valid YAML file.`]: `올바른 YAML 파일을 업로드하십시오.`,
  [`Populate Server Configuration into Context Variables`]: `서버 구성을 컨텍스트 변수로 채우기`,
  [`POST/GET a query to be validated and sent to the backend server`]: `유효성이 검증되어 백엔드 서버에 전송될 조회 게시/가져오기`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `해당 조회 호출의 예상 비용을 가져오기 위한 조회 게시/가져오기`,
  [`Preparing`]: `준비 중`,
  [`Rate limit amount cannot be negative`]: `속도 제한 양이 음수일 수 없음`,
  [`Rate limit per cannot be negative`]: `각각에 대한 속도 제한이 음수일 수 없음`,
  [`Rename API`]: `API 이름 바꾸기`,
  [`Rename`]: `이름 바꾸기`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `{catalog} 카탈로그에서 API 제품 {action}에 대한 승인 요청`,
  [`Sandbox Sample Application`]: `샌드박스 샘플 애플리케이션`,
  [`Sandbox sample application credentials`]: `Sandbox 샘플 애플리케이션 신임 정보`,
  [`sample application credentials`]: `샘플 애플리케이션 신임 정보`,
  [`Saving`]: `저장 중`,
  [`Search Orgs`]: `조직 검색`,
  [`Search definitions...`]: `정의 검색...`,
  [`Search errors...`]: `오류 검색...`,
  [`Search operations...`]: `오퍼레이션 검색...`,
  [`Search organizations and groups`]: `조직 및 그룹 검색`,
  [`Sorry, This feature is not available in`]: `죄송합니다. 이 기능은 사용할 수 없습니다.`,
  [`Stopping`]: `중지 중`,
  [`The user with username {username} already exists in the user registry.`]: `사용자 이름이 {username}인 사용자가 사용자 레지스트리에 이미 있습니다.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `실행되지 않는 API 변경사항이 있습니다. 이 API를 샌드박스 카탈로그에 재공개합니다.`,
  [`There are no plans available for subscription.`]: `등록 가능한 플랜이 없습니다.`,
  [`There are no attributes yet.`]: `아직 속성이 없습니다.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `그룹에 이용자 조직을 추가하려면 입력`,
  [`Type to add groups to the organization`]: `조직에 그룹을 추가하려면 입력`,
  [`Type to add organizations from {catalog}`]: `{catalog}에서 조직을 추가하려면 입력`,
  [`Type to add organizations or groups`]: `조직 또는 그룹을 추가하려면 입력`,
  [`Type to add organizations or groups from {catalog}`]: `{catalog}에서 조직 또는 그룹을 추가하려면 입력`,
  [`Type to add {title} to groups`]: `그룹에 {title}을(를) 추가하려면 입력`,
  [`Update Rate Limits`]: `속도 제한 업데이트`,
  [`Upload the YAML file with configuration`]: `구성이 포함된 YAML 파일 업로드`,
  [`Use the sample below as a template to configure your GraphQL server`]: `아래 샘플을 템플리트로 사용하여 GraphQL 서버 구성`,
  [`User managed`]: `사용자 관리`,
  [`URL {url} does not contain valid OpenAPI.`]: `URL {url}에 유효한 OpenAPI가 없습니다.`,
  [`Validating`]: `유효성 검사 중`,
  [`View analytics and performance metrics for your APIs`]: `API에 대한 분석 및 성능 메트릭 보기`,
  [`View and add custom policies to the catalog`]: `사용자 정책 보기 및 카탈로그에 추가`,
  [`View and add custom exentions to the catalog`]: `사용자 정의 확장 보기 및 카탈로그에 추가`,
  [`View user registries and OAuth providers`]: `사용자 레지스트리 및 OAuth 제공자 보기`,
  [`View your application and manage API credentials`]: `애플리케이션을 보고 API 신임 정보 관리`,
  [`Welcome to the API Connect Trial`]: `API Connect 평가판 시작`,
  [`You haven't added any APIs`]: `API를 추가하지 않았습니다.`,
  [`You currently don’t have any {resource}.`]: `현재 보유 중인 {resource}이(가) 없습니다.`,
  [`plans to migrate`]: `마이그레이션 계획`,
  [`gateway extensions`]: `게이트웨이 확장`,
  [`application`]: `애플리케이션`,
  [`application authentication source`]: `애플리케이션 인증 소스`,
  [`API or product`]: `API 또는 제품`,
  [`base endpoints`]: `기본 엔드포인트`,
  [`categories`]: `카테고리`,
  [`certificates`]: `인증서`,
  [`channels`]: `채널`,
  [`consumer organizations`]: `소비자 조직`,
  [`consumer organization group`]: `소비자 조직 그룹`,
  [`consumer organizations or groups`]: `소비자 조직 또는 그룹`,
  [`correlation ids`]: `상관 ID`,
  [`cors policy`]: `cors 정책`,
  [`definitions`]: `정의`,
  [`examples`]: `예`,
  [`gateway services`]: `게이트웨이 서비스`,
  [`groups`]: `그룹`,
  [`headers`]: `머리말`,
  [`links`]: `링크`,
  [`member`]: `구성원`,
  [`messages`]: `메시지`,
  [`message traits`]: `메시지 특성`,
  [`operation traits`]: `조작 특성`,
  [`parameter definitions`]: `매개변수 정의`,
  [`parameters`]: `매개변수`,
  [`path`]: `경로`,
  [`properties`]: `특성`,
  [`provider organizations`]: `제공자 조직`,
  [`request bodies`]: `요청 본문`,
  [`response definitions`]: `응답 정의`,
  [`responses`]: `응답`,
  [`role`]: `역할`,
  [`scope`]: `범위`,
  [`scopes`]: `범위`,
  [`schemas`]: `스키마`,
  [`schema definition`]: `스키마 정의`,
  [`schemes list`]: `스킴 목록`,
  [`security definitions`]: `보안 정의`,
  [`security schemes`]: `보안 스키마`,
  [`servers`]: `서버`,
  [`services`]: `서비스`,
  [`tags`]: `태그`,
  [`tags and external documentation`]: `태그 및 외부 문서`,
  [`target services`]: `대상 서비스`,
  [`tasks`]: `태스크`,
  [`user registries`]: `사용자 레지스트리`,
  [`HTTP header`]: `HTTP 헤더`,
  [`You currently don’t have any application authentication source.`]: `현재 애플리케이션 인증 소스가 없습니다.`,
  [`You currently don’t have any API or product.`]: `현재 보유 중인 API 또는 제품이 없습니다.`,
  [`You currently don’t have any API user registries`]: `현재 보유 중인 API 사용자 레지스트리가 없습니다.`,
  [`You currently don’t have any APIs.`]: `현재 보유 중인 API가 없습니다.`,
  [`You currently don’t have any TLS client profiles`]: `현재 보유 중인 TLS 클라이언트 프로파일이 없습니다.`,
  [`You currently don’t have any base endpoints.`]: `현재 보유 중인 기본 엔드포인트가 없습니다.`,
  [`You currently don’t have any catalog properties.`]: `현재 카탈로그 특성이 없습니다.`,
  [`You currently don’t have any categories.`]: `현재 카테고리가 없습니다.`,
  [`You currently don’t have any certificates.`]: `현재 인증서가 없습니다.`,
  [`You currently don’t have any channels.`]: `현재 채널이 없습니다.`,
  [`You currently don’t have any consumer organizations.`]: `현재 보유 중인 이용자 조직이 없습니다.`,
  [`You currently don’t have any consumer organizations or groups.`]: `현재 보유 중인 이용자 조직 또는 그룹이 없습니다.`,
  [`You currently don’t have any consumes.`]: `현재 이용이 없습니다.`,
  [`You currently don’t have any correlation ids.`]: `현재 상관 ID가 없습니다.`,
  [`You currently don’t have any cors policy.`]: `현재 CORS 정책이 없습니다.`,
  [`You currently don’t have any definitions.`]: `현재 정의가 없습니다.`,
  [`You currently don’t have any examples.`]: `현재 예제가 없습니다.`,
  [`You currently don’t have any gateway services.`]: `현재 보유 중인 게이트웨이 서비스가 없습니다.`,
  [`You currently don’t have any groups.`]: `현재 보유 중인 그룹이 없습니다.`,
  [`You currently don’t have any headers.`]: `현재 헤더가 없습니다.`,
  [`You currently don’t have any keystore.`]: `현재 키 저장소가 없습니다.`,
  [`You currently don’t have any links.`]: `현재 링크가 없습니다.`,
  [`You currently don’t have any messages.`]: `현재 메시지가 없습니다.`,
  [`You currently don’t have any message traits.`]: `현재 메시지 특성이 없습니다.`,
  [`You currently don’t have any OAuth providers`]: `현재 보유 중인 OAuth 제공자가 없습니다.`,
  [`You currently don’t have any operation traits.`]: `현재 오퍼레이션 특성이 없습니다.`,
  [`You currently don’t have any parameter definitions.`]: `현재 매개변수 정의가 없습니다.`,
  [`You currently don’t have any parameters.`]: `현재 보유 중인 매개변수가 없습니다.`,
  [`You currently don’t have any preserved request header.`]: `현재 보존된 요청 헤더가 없습니다.`,
  [`You currently don’t have any preserved response header.`]: `현재 보존된 응답 헤더가 없습니다.`,
  [`You currently don’t have any produces.`]: `현재 생성이 없습니다.`,
  [`You currently don’t have any properties.`]: `현재 보유 중인 특성이 없습니다.`,
  [`You currently don’t have any provider organizations.`]: `현재 보유 중인 제공자 조직이 없습니다.`,
  [`You currently don’t have any request bodies.`]: `현재 요청 본문이 없습니다.`,
  [`You currently don’t have any response definitions.`]: `현재 응답 정의가 없습니다.`,
  [`You currently don’t have any responses.`]: `현재 응답이 없습니다.`,
  [`You currently don’t have any scopes.`]: `현재 보유 중인 범위가 없습니다.`,
  [`You currently don’t have any schemas.`]: `현재 스키마가 없습니다.`,
  [`You currently don’t have any schemes list.`]: `현재 스킴 목록이 없습니다.`,
  [`You currently don’t have any security definitions.`]: `현재 보유 중인 보안 정의가 없습니다.`,
  [`You currently don’t have any security schemes.`]: `현재 보안 스킴이 없습니다.`,
  [`You currently don’t have any servers.`]: `현재 서버가 없습니다.`,
  [`You currently don’t have any services.`]: `현재 보유 중인 서비스가 없습니다.`,
  [`You currently don’t have any tags.`]: `현재 태그가 없습니다.`,
  [`You currently don’t have any tags and external documentation.`]: `현재 보유 중인 태그 및 외부 문서가 없습니다.`,
  [`You currently don’t have any target services.`]: `현재 대상 서비스가 없습니다.`,
  [`You currently don’t have any tasks.`]: `현재 보유 중인 태스크가 없습니다.`,
  [`You currently don’t have any truststore.`]: `현재 신뢰 저장소가 없습니다.`,
  [`You currently don’t have any user registries.`]: `현재 보유 중인 사용자 레지스트리가 없습니다.`,
  [`You currently don’t have any extensions.`]: `현재 확장이 없습니다.`,
  [`You currently don’t have any HTTP header.`]: `현재 HTTP 헤더가 없습니다.`,
  [`Your account is being activated. This may take a while...`]: `계정이 활성화되는 중입니다. 시간이 조금 걸릴 수 있습니다...`,
  [`all of`]: `다음 모두`,
  [`any of`]: `다음 중 일부`,
  [`calls`]: `호출`,
  [`ciphers`]: `암호`,
  [`extensions`]: `확장기능`,
  [`one of`]: `다음 중 하나`,
  [`shared`]: `공유됨`,
  [`stopping`]: `중지 중`,
  [`to:`]: `대상:`,
  [`Key`]: `키`,
  [`kind`]: `유형`,
  [`Trace`]: `추적`,
  [`Http Status`]: `Http 상태`,
  [`Parsed`]: `구문 분석됨`,
  [`Raw`]: `원시`,
  [`Showing Full Trace`]: `전체 추적 표시`,
  [`Show Entire Trace`]: `전체 추적 표시`,
  [`Show advanced`]: `고급 표시`,
  [`Advanced`]: `고급`,
  [`Sending Request`]: `요청 전송`,
  [`Please send a request above`]: `위 요청을 전송하십시오.`,
  [`Please send a request above.`]: `위 요청을 전송하십시오.`,
  [`Authentication Type`]: `인증 유형`,
  [`You don't currently have any response`]: `현재 보유 중인 응답이 없습니다.`,
  [`You don't currently have any trace.`]: `현재 추적이 없습니다.`,
  [`No response received`]: `수신된 응답이 없습니다.`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `“예외를 승인하려면 링크 열기”를 클릭하면 새 탭에서 서버가 열립니다. 브라우저가 인증서 발행을 표시하면 사용자는 이를 승인하도록 선택하고 여기로 돌아와 다시 테스트할 수 있습니다.`,
  [`Open link to accept exception`]: `예외를 승인하려면 링크 열기`,
  [`Back to {name}`]: `{name}(으)로 돌아가기`,
  [`0 seconds`]: `0초`,
  [`close.menu`]: `메뉴 닫기`,
  [`open.menu`]: `메뉴 열기`,
  [`Referenced from "{ref}"`]: `"{ref}"에서 참조함`,
  [`Go to {definition}`]: `{definition}(으)로 이동`,
  [`General`]: `일반`,
  [`Servers`]: `서버`,
  [`External Docs`]: `외부 문서`,
  [`Components`]: `컴포넌트`,
  [`Schemas`]: `스키마`,
  [`Responses`]: `응답`,
  [`Examples`]: `예`,
  [`Request Bodies`]: `요청 본문`,
  [`Security Schemes`]: `보안 스키마`,
  [`Links`]: `링크`,
  [`Callbacks`]: `콜백`,
  [`Terms of Service`]: `이용 약관`,
  [`Contact Name`]: `담당자`,
  [`Contact URL`]: `담당자 URL`,
  [`Contact Email`]: `담당자 이메일`,
  [`Contact sales`]: `판매 담당자에게 문의`,
  [`License Name`]: `라이센스 이름`,
  [`License URL`]: `라이센스 URL`,
  [`Content-Type`]: `컨텐츠 유형`,
  [`Accept-Language`]: `승인 언어`,
  [`Accept MTOM/XOP optimized messages`]: `MTOM/XOP 최적화 메시지 승인`,
  [`User-Agent`]: `사용자-에이전트`,
  [`token`]: `토큰`,
  [`Tag`]: `태그`,
  [`Request Body`]: `요청 본문`,
  [`Security Requirements`]: `보안 요구사항`,
  [`Located In`]: `위치`,
  [`Example Value`]: `예제 값`,
  [`External Value`]: `외부 값`,
  [`Server`]: `서버`,
  [`Server Description`]: `서버 설명`,
  [`Server Variables`]: `서버 변수`,
  [`Format`]: `형식`,
  [`Pattern`]: `패턴`,
  [`Multiple Of`]: `다음의 배수`,
  [`Multiple non null slicing arguments`]: `여러 개의 널이 아닌 분할 인수`,
  [`Missing slicing arguments`]: `누락된 분할 인수`,
  [`Missing sized fields`]: `누락된 크기 조정 필드`,
  [`Maximum`]: `최대`,
  [`Exclusive Maximum`]: `독점 최대값`,
  [`Minimum`]: `최소`,
  [`Exclusive Minimum`]: `독점 최소값`,
  [`Max Length`]: `최대 길이`,
  [`Min Length`]: `최소 길이`,
  [`Max Items`]: `최대 항목`,
  [`Min Items`]: `최소 항목`,
  [`Unique Items`]: `고유 항목`,
  [`Max Properties`]: `최대 특성`,
  [`Min Properties`]: `최소 특성`,
  [`Required Properties`]: `필수 특성`,
  [`Required Property Name`]: `필수 특성 이름`,
  [`Enum`]: `열거`,
  [`Enum Value`]: `열거 값`,
  [`Nullable`]: `널 입력 가능`,
  [`Read Only`]: `읽기 전용`,
  [`Write Only`]: `쓰기 전용`,
  [`api`]: `api`,
  [`version`]: `버전`,
  [`username`]: `사용자 이름`,
  [`password`]: `비밀번호`,
  [`service`]: `서비스`,
  [`Allows Empty Value`]: `빈 값 허용`,
  [`Style`]: `스타일`,
  [`Explode`]: `확대`,
  [`Allow Reserved`]: `예약 허용`,
  [`Allow WebSocket Upgrade`]: `웹소켓 업그레이드 허용`,
  [`Ref`]: `참조`,
  [`Operation ID`]: `오퍼레이션 ID`,
  [`Parameter`]: `매개변수`,
  [`Gateway and portal settings`]: `게이트웨이 및 포털 설정`,
  [`Targets`]: `대상`,
  [`Catalog Properties`]: `카탈로그 특성`,
  [`catalog properties`]: `카탈로그 특성`,
  [`Attachments`]: `첨부 파일`,
  [`Activity Log`]: `활동 로그`,
  [`API Type`]: `API 유형`,
  [`Phase`]: `단계`,
  [`Enable CORS`]: `CORS 사용`,
  [`CORS Policy`]: `CORS 정책`,
  [`Allowed Origins`]: `허용된 오리진`,
  [`Allow Credentials`]: `신임 정보 허용`,
  [`ID`]: `ID`,
  [`Success Content`]: `성공 컨텐츠`,
  [`Error Content`]: `오류 컨텐츠`,
  [`Attachment`]: `첨부 파일`,
  [`Link`]: `링크`,
  [`Operation Ref`]: `오퍼레이션 참조`,
  [`TRACE`]: `TRACE`,
  [`No Security Requirements available`]: `보안 요구사항을 사용할 수 없음`,
  [`Create a security scheme`]: `보안 스키마 작성`,
  [`Create a Security Requirement`]: `보안 요구사항 작성`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `다음 보안 요구사항 중 하나가 필요합니다. 선택 취소 시, 보안은 선택사항입니다.`,
  [`Require wrappers on fault details specified by type`]: `유형으로 지정된 결함 세부사항에 랩퍼가 필요함`,
  [`There are no "Servers"`]: `"서버"가 없음`,
  [`Write`]: `쓰기`,
  [`Server Variable`]: `서버 변수`,
  [`Security Requirement`]: `보안 요구사항`,
  [`Security Scheme Type`]: `보안 스킴 유형`,
  [`Security Scheme`]: `보안 스킴`,
  [`OAuth Flows`]: `OAuth 플로우`,
  [`Implicit Flow`]: `암시적 플로우`,
  [`Password Flow`]: `비밀번호 플로우`,
  [`Client Credentials Flow`]: `클라이언트 신임 정보 플로우`,
  [`Authorization Code Flow`]: `권한 코드 플로우`,
  [`Refresh URL`]: `새로 고치기 URL`,
  [`schema`]: `스키마`,
  [`Allow Empty Value`]: `빈 값 허용`,
  [`Encoding`]: `인코딩`,
  [`This field is required`]: `필수 필드입니다.`,
  [`Temporary token`]: `임시 토큰`,
  [`Access token`]: `액세스 토큰`,
  [`ID token`]: `ID 토큰`,
  [`No Security Schemes available`]: `사용 가능한 보안 스킴이 없음`,
  [`Security Scheme (Key)`]: `보안 스킴(키)`,
  [`There are no "{label}"`]: `"{label}" 없음`,
  [`You must select at least one scheme to define a Security Requirement`]: `하나 이상의 스킴을 선택하여 보안 요구사항을 정의해야 합니다.`,
  [`Select one or more Security Schemes`]: `하나 이상의 보안 스킴 선택`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `"{key}" 보안 스킴에 사용 가능한 OAuth 범위가 없음`,
  [`Create a Scope`]: `범위 작성`,
  [`Remove this Security Requirement`]: `이 보안 요구사항 제거`,
  [`Edit this Security Requirement`]: `이 보안 요구사항 편집`,
  [`Invalid Security Scheme Reference`]: `올바르지 않은 보안 스킴 참조`,
  [`The following Security Schemes do not exist!`]: `다음 보안 스킴이 없습니다!`,
  [`Edit source`]: `소스 편집`,
  [`OR`]: `OR`,
  [`Required Security Schemes`]: `필수 보안 스킴`,
  [`Optional Security`]: `선택적 보안`,
  [`Optional custom expression (one per line)`]: `선택적 사용자 정의 표현식(행당 하나)`,
  [`Flow Type`]: `플로우 유형`,
  [`OAuth2 Security Scheme`]: `OAuth2 보안 스킴`,
  [`Only source editing is supported.`]: `소스 편집만 지원됩니다.`,
  [`Unsupported Field.`]: `지원되지 않는 필드`,
  [`Select an option`]: `옵션 선택`,
  [`Select {labelText}`]: `{labelText} 선택`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}"은(는) 이미 사용됨'`,
  [`Referenced from "{originalRef}"`]: `"{originalRef}"에서 참조됨`,
  [`Go to {label}`]: `{label}(으)로 이동`,
  [`Delete "{label}"`]: `"{label}" 삭제`,
  [`Are you sure you want to delete "{label}"?`]: `"{label}"을(를) 삭제하시겠습니까?`,
  [`No recent items found`]: `최근 항목을 찾을 수 없음`,
  [`Found {numDocs} results ({took} seconds)`]: `{numDocs}개의 결과를 찾음({took}초)`,
  [`No Consumer Organizations found`]: `이용자 조직을 찾을 수 없음`,
  [`No Catalog Products found`]: `카탈로그 제품을 찾을 수 없음`,
  [`No APIs found`]: `API를 찾을 수 없음`,
  [`No Catalogs found`]: `카탈로그를 찾을 수 없음`,
  [`No Spaces found`]: `공간을 찾을 수 없음`,
  [`No results found`]: `결과가 없음`,
  [`No Catalog APIs found`]: `카탈로그 API를 찾을 수 없음`,
  [`No Catalogs found matching query "{query}"`]: `"{query}" 조회와 일치하는 카탈로그를 찾을 수 없음`,
  [`No APIs found matching query "{query}"`]: `"{query}" 조회와 일치하는 API를 찾을 수 없음`,
  [`No Subscriptions found`]: `등록을 찾을 수 없음`,
  [`No Plans found matching query "{query}"`]: `"{query}" 조회와 일치하는 플랜을 찾을 수 없음`,
  [`No Subscriptions found matching query "{query}"`]: `"{query}" 조회와 일치하는 등록을 찾을 수 없음`,
  [`No Catalog Products found matching query "{query}"`]: `"{query}" 조회와 일치하는 카탈로그 제품을 찾을 수 없음`,
  [`No Catalog APIs found matching query "{query}"`]: `"{query}" 조회와 일치하는 카탈로그 API를 찾을 수 없음`,
  [`No Consumer Organizations found matching query "{query}"`]: `"{query}" 조회와 일치하는 이용자 조직을 찾을 수 없음`,
  [`No Applications found matching query "{query}"`]: `"{query}" 조회와 일치하는 애플리케이션을 찾을 수 없음`,
  [`APIs per page`]: `페이지당 API 수`,
  [`Catalogs per page`]: `페이지당 카탈로그 수`,
  [`items per page`]: `페이지당 항목 수`,
  [`{min}-{max} of {total} APIs`]: `{total}개 API 중 {min}-{max}개`,
  [`{min}-{max} of {total} API`]: `{min} - {max}/{total} API`,
  [`Products per page`]: `페이지당 제품 수`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `새 청구 통합을 구성하려면 카탈로그에 제품이 게시되어야 합니다.`,
  [`{min}-{max} of {total} Products`]: `{total}개 제품 중 {min}-{max}개`,
  [`{min}-{max} of {total} Product`]: `{min} - {max}/{total} 제품`,
  [`Catalog Products per page`]: `페이지당 카탈로그 제품 수`,
  [`{min}-{max} of {total} Catalog Products`]: `{total}개 카탈로그 제품 중 {min}-{max}개`,
  [`{min}-{max} of {total} Catalog Product`]: `{min} - {max}/{total} 카탈로그 제품`,
  [`Plans per page`]: `페이지당 플랜 수`,
  [`Policies per page`]: `페이지당 정책 수`,
  [`{min}-{max} of {total} Plans`]: `{total}개 플랜 중 {min}-{max}개`,
  [`{min}-{max} of {total} Plan`]: `{min} - {max}/{total} 플랜`,
  [`Catalog APIs per page`]: `페이지당 카탈로그 API 수`,
  [`{min}-{max} of {total} Catalog APIs`]: `{total}개 카탈로그 API 중 {min}-{max}개`,
  [`{min}-{max} of {total} Catalog API`]: `{min} - {max}/{total} 카탈로그 API`,
  [`Subscriptions per page`]: `페이지당 등록 수`,
  [`{min}-{max} of {total} Subscriptions`]: `{total}개 등록 중 {min}-{max}개`,
  [`{min}-{max} of {total} Subscription`]: `{min} - {max}/{total} 등록`,
  [`Consumer Organizations per page`]: `페이지당 이용자 조직 수`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{total}개 이용자 조직 중 {min}-{max}개`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min} - {max}/{total} 이용자 조직`,
  [`Applications per page`]: `페이지당 애플리케이션 수`,
  [`{min}-{max} of {total} Applications`]: `{total}개 애플리케이션 중 {min}-{max}개`,
  [`{min}-{max} of {total} Application`]: `{min} - {max}/{total} 애플리케이션`,
  [`Histories per page`]: `페이지당 히스토리 수`,
  [`{min}-{max} of {total} Histories`]: `{total}개 히스토리 중 {min}-{max}개`,
  [`{min}-{max} of {total} History`]: `{min} - {max}/{total} 히스토리`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `등록할 수 있는 플랜을 찾을 수 없습니다. 등록을 작성하려면 제품이 "공개됨" 상태여야 합니다. 애플리케이션은 제품에서 하나의 플랜에만 등록할 수 있습니다.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `공개된 "{draftApiTitle}:{draftApiVersion}" API 표시`,
  [`Showing Consumer Organization for "{appTitle}"`]: `"{appTitle}"의 이용자 조직 표시`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}"의 등록 표시`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}"의 플랜 표시`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}"의 API 표시`,
  [`Showing Product for "{planTitle}"`]: `"{planTitle}"의 제품 표시`,
  [`Showing APIs for "{planTitle}"`]: `"{planTitle}"의 API 표시`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `제품: "{docFoundIn}"에서 플랜: "{docTitle}"의 등록 표시`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `"{apiTitle}:{apiVersion}"의 플랜 표시`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `"{catalogApiTitle}:{catalogApiVersion}"의 드래프트 API 표시`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}"의 히스토리 표시`,
  [`Showing Subscriptions for "{appTitle}"`]: `"{appTitle}"의 등록 표시`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `"{consumerOrgTitle}"의 애플리케이션 표시`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `"{consumerOrgTitle}"의 등록 표시`,
  [`Showing Application for selected Subscription`]: `선택한 등록의 애플리케이션 표시`,
  [`Showing Plan for selected Subscription`]: `선택한 등록의 플랜 표시`,
  [`Showing Product for selected Subscription`]: `선택한 등록의 제품 표시`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `"{productTitle}" 제품에 포함시킬 드래프트 API 선택`,
  [`Product Version`]: `제품 버전`,
  [`OAuth Providers`]: `OAuth 제공자`,
  [`Application Type`]: `애플리케이션 유형`,
  [`Client IDs`]: `클라이언트 ID`,
  [`Save as New Version`]: `새 버전으로 저장`,
  [`Update Selected APIs`]: `선택한 API 업데이트`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}API가 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}제품이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}카탈로그 제품이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}이용자 조직이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}이용자 그룹이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}정책이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}등록이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}카탈로그가 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}영역이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `{titleAndVersion}카탈로그 제품이 재공개됩니다. 계속하시겠습니까?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `{titleAndVersion}카탈로그 제품이 폐기됩니다. 계속하시겠습니까?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `{titleAndVersion}카탈로그 제품은 더 이상 사용되지 않습니다. 계속하시겠습니까?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `{titleAndVersion}카탈로그 제품이 다시 스테이징됩니다. 계속하시겠습니까?`,
  [`Put Online`]: `온라인으로 전환`,
  [`Take Offline`]: `오프라인으로 전환`,
  [`View Subscriptions`]: `등록 보기`,
  [`View Plans`]: `계획 보기`,
  [`No Tasks found`]: `태스크를 찾을 수 없음`,
  [`Tasks per page`]: `페이지당 태스크 수`,
  [`{min}-{max} of {total} Tasks`]: `{total}개 태스크 중 {min}-{max}개`,
  [`{min}-{max} of {total} Task`]: `{min} - {max}/{total} 태스크`,
  [`Decline and Send`]: `거부 및 전송`,
  [`View Applications`]: `애플리케이션 보기`,
  [`Publish (Preserve Subscriptions)`]: `공개(등록 유지)`,
  [`Update APIs`]: `API 업데이트`,
  [`View in Catalogs`]: `카탈로그에서 보기`,
  [`View Consumer Organization`]: `이용자 조직 보기`,
  [`View Application`]: `애플리케이션 보기`,
  [`View Plan`]: `계획 보기`,
  [`View Product`]: `제품 보기`,
  [`Create Subscription`]: `등록 작성`,
  [`No Applications found`]: `애플리케이션을 찾을 수 없음`,
  [`Select a Plan to Create a Subscription to`]: `등록을 작성할 플랜 선택`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `이 조치는 되돌릴 수 없습니다. {titleAndVersion}애플리케이션이 영구적으로 삭제됩니다. 계속하시겠습니까?`,
  [`Save as new version`]: `새 버전으로 저장`,
  [`Filter selected catalogs`]: `선택한 카탈로그 필터링`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `"{productTitle}" 제품에 포함시킬 드래프트 API 선택`,
  [`Update selected APIs`]: `선택한 API 업데이트`,
  [`Catalog API`]: `카탈로그 API`,
  [`Catalog APIs`]: `카탈로그 API`,
  [`View Products`]: `제품 보기`,
  [`View Draft APIs`]: `드래프트 API 보기`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `"{apiTitle}:{apiVersion}"을(를) 참조하는 제품 표시`,
  [`No Plans found`]: `플랜을 찾을 수 없음`,
  [`View APIs`]: `API 보기`,
  [`Document type tabs`]: `문서 유형 탭`,
  [`No Products found`]: `제품을 찾을 수 없음`,
  [`Consumer Organization`]: `이용자 조직`,
  [`Consumer Organizations`]: `이용자 조직`,
  [`Catalog Product`]: `카탈로그 제품`,
  [`Catalog Products`]: `카탈로그 제품`,
  [`Search for a Product and Plan`]: `제품 및 플랜 검색`,
  [`OpenAPI Version`]: `OpenAPI 버전`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API(REST, GraphQL 또는 SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI(Kafka 토픽)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `AsyncAPI 작성(Kafka 토픽)`,
  [`An error occurred during file upload`]: `파일을 업로드하는 중에 오류가 발생함`,
  [`Delete uploaded file`]: `업로드된 파일 삭제`,
  [`Copied!`]: `복사되었습니다!`,
  [`Uploading`]: `업로드`,
  [`Validation icon`]: `유효성 검증 아이콘`,
  [`Validation error`]: `유효성 검증 오류`,
  [`Uploading image..`]: `업로드 아이콘`,
  [`Unsupported media type {providedFileType}`]: `지원되지 않는 매체 유형 {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `지원되는 파일 {expectedFileTypes}을(를) 선택하고 다시 시도`,
  [`Pasted`]: `붙여넣음`,
  [`Enter the API summary details.`]: `API 요약 세부사항을 입력하십시오.`,
  [`Define your Kafka cluster connection details.`]: `Kafka 클러스터 연결 세부사항을 정의하십시오.`,
  [`Channel`]: `채널`,
  [`Describe your Kafka topic and its associated schema.`]: `Kafka 토픽 및 연관된 스키마에 대해 설명하십시오.`,
  [`AsyncAPI specification version`]: `AsyncAPI 스펙 버전`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `API를 설명하며 개발자가 개발자 포털에서 검색하는 데 도움을 주는 이름입니다.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `API 정의 버전입니다. 이름과 버전 조합은 API 정의를 고유하게 식별합니다.`,
  [`Description (optional)`]: `설명(선택사항)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `API를 소개하고 이를 이용하는 개발자에게 유용한 정보를 제공합니다.`,
  [`Summary (optional)`]: `요약(선택사항)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `이 API에 포함된 이벤트에 대한 간략한 요약입니다. 촤대 1000자입니다.`,
  [`Bootstrap servers`]: `부트스트랩 서버`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `클러스터에 있는 Kafka 브로커 목록입니다(쉼표로 구분됨).`,
  [`Secure the API using an API Key and Secret`]: `API 키 및 시크릿을 사용하여 API 보호`,
  [`The credential provided should be in SASL-PLAIN format`]: `제공된 신임 정보는 SASL-PLAIN 형식이어야 함`,
  [`Publish this API:`]: `이 API 공개:`,
  [`Creates a product`]: `제품 작성`,
  [`Associates this API to the product`]: `제품에 이 API 연관`,
  [`Publish the product to the Sandbox catalog`]: `샌드박스 카탈로그에 제품 공개`,
  [`Associates the product to the test app`]: `이 테스트 앱에 제품 연관`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `"{fileType}" 형식의 스키마가 업로드 및 유효성 검증되었습니다.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `업로드된 파일이 올바른 Avro 스키마가 아니며, API의 일부로 포함되지 않습니다.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `서버 응답: "{err}". "이전"을 클릭하여 API 정의 페이지로 돌아가십시오.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `서버 응답: "{err}". "완료"를 클릭하여 마법사를 종료하십시오.`,
  [`Topic name`]: `토픽 이름`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `이 API의 일부로 노출하려는 토픽의 이름입니다. 토픽은 이름 지정된 메시지 스트림입니다.`,
  [`Schema for message body in .avsc format (optional)`]: `.avsc 형식의 메시지 본문 스키마(선택사항)`,
  [`Drag and drop files here, or click to upload`]: `여기에 파일을 끌어서 놓거나 클릭하여 업로드`,
  [`About this step`]: `이 단계 정보`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `이벤트가 사용 가능해지면 이용자가 이벤트를 수신하므로 이벤트는 비동기 API로 설명할 수 있습니다. 반면, REST와 같은 동기 API는 요청이 있을 때만 응답을 수신합니다.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `이벤트 구동 비동기 API는 AsyncAPI 스펙을 사용하여 설명됩니다.`,
  [`API secret`]: `API 시크릿`,
  [`Generated AsyncAPI 2.1.0 definition`]: `생성된 AsyncAPI 2.1.0 정의`,
  [`API creating`]: `API 작성 중`,
  [`An error occurred while creating your document`]: `문서를 작성하는 중에 오류가 발생함`,
  [`Publishing your API`]: `API 공개 중`,
  [`Your API has been published`]: `API가 공개됨`,
  [`An error occurred while publishing your API`]: `API를 공개하는 중에 오류가 발생함`,
  [`Configure security for this API`]: `이 API의 보안 구성`,
  [`Cluster connection security`]: `클러스터 연결 보안`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `이벤트 게이트웨이 서비스가 이 API에 대한 요청을 처리할 때 클러스터에 연결하는 데 사용할 신임 정보를 제공하십시오. 나중에 편집기에서 이 값을 변경할 수 있습니다. 추가 정보는 신임 정보 작성에 대한 문서를 참조하십시오.`,
  [`SASL Username`]: `SASL 사용자 이름`,
  [`SASL Password`]: `SASL 비밀번호`,
  [`Transport CA certificate (.pem file - optional)`]: `CA 인증서 전송(.pem 파일 - 선택사항)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `이를 제공하면, 이벤트 게이트웨이 서비스가 게이트웨이와 Kafka 클러스터 사이의 통신을 위해 TLS 암호화를 제공하는 데 사용됩니다.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `AsyncAPI 문서를 생성하는 Apache Kafka 이벤트 소스의 API 세부사항을 설명합니다.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `그런 다음 AsyncAPI 문서를 공개하여 이벤트 소스를 애플리케이션 개발자에게 노출시킬 수 있습니다. 애플리케이션 개발자는 관련 이벤트 스트림을 구독하도록 애플리케이션을 구성할 수 있습니다.`,
  [`Set up security for your API if you want to control who can access it.`]: `액세스할 수 있는 사용자를 제어하려는 경우 API에 대한 보안을 설정하십시오.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `API에 대한 액세스는 유효한 API 키와 시크릿을 확인하는 이벤트 게이트웨이 서비스에서 관리됩니다.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `게이트웨이에서는 클라이언트가 TLS와의 통신을 암호화해야 합니다.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `API가 작성되면 ‘API 편집’을 클릭하여 이벤트 게이트웨이 서비스에서 Kafka 클러스터로 연결하기 위한 보안 세부사항과 같은 세부사항을 추가하십시오.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `그런 다음 API를 공개하여 API에 대한 액세스를 검색하고 요청할 수 있는 개발자가 API를 사용할 수 있도록 하고 정보를 사용하여 이벤트 소스에서 이용할 애플리케이션을 설정할 수 있습니다.`,
  [`Pending backend response`]: `백엔드 응답 보류 중`,
  [`Complete`]: `완료`,
  [`Rate limiting is only applied to enforced APIs`]: `비율 한계 지정은 적용된 API에만 적용됨`,
  [`No Plans Available`]: `사용 가능한 플랜 없음`,
  [`Modal content is not provided`]: `모달 컨텐츠가 제공되지 않음`,
  [`Modal heading is not provided`]: `모달 표제가 제공되지 않음`,
  [`Edit Rate Limit`]: `속도 제한 편집`,
  [`External Documentation`]: `외부 문서`,
  [`No Label Available`]: `사용 가능한 레이블 없음`,
  [`optional`]: `선택사항`,
  [`Uploading image...`]: `이미지 업로드 중...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `파일을 끌기, 놓기, 선택 또는 붙여넣기하여 첨부합니다.`,
  [`Response name`]: `응답 이름`,
  [`Click Add to add a new {resource}.`]: `추가를 클릭하여 새 {resource}을(를) 추가하십시오.`,
  [`Schema Name (Key)`]: `스키마 이름(키)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `네임스페이스`,
  [`Attribute`]: `속성`,
  [`Wrapped`]: `랩핑됨`,
  [`Example`]: `예제`,
  [`Example Name (Key)`]: `예제 이름(키)`,
  [`"{changedValue}" is already used`]: `"{changedValue}"은(는) 이미 사용됨`,
  [`Name (Key)`]: `이름(키)`,
  [`Content Type (Key)`]: `컨텐츠 유형(키)`,
  [`Header name`]: `헤더 이름`,
  [`Reference`]: `참조`,
  [`Security Scheme Name (Key)`]: `보안 스킴 이름(키)`,
  [`Choose a Reference`]: `참조 선택`,
  [`Reference from`]: `참조 소스`,
  [`Content Type`]: `컨텐츠 유형`,
  [`array`]: `배열`,
  [`boolean`]: `부울`,
  [`integer`]: `정수`,
  [`number`]: `숫자`,
  [`object`]: `오브젝트`,
  [`string`]: `문자열`,
  [`query`]: `조회`,
  [`cookie`]: `쿠키`,
  [`matrix`]: `행렬`,
  [`label`]: `레이블`,
  [`simple`]: `단순`,
  [`form`]: `양식`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth 제공자(이름)`,
  [`Authenticate using User Registry (name)`]: `사용자 레지스트리(이름)를 사용하여 인증`,
  [`Bearer Format`]: `전달자 형식`,
  [`Server Variable Name`]: `서버 변수 이름`,
  [`Verb (Key)`]: `Verb(키)`,
  [`Parameter Definitions`]: `매개변수 정의`,
  [`Path Parameters`]: `경로 매개변수`,
  [`Media Type List`]: `매체 유형 목록`,
  [`Medium`]: `중간`,
  [`Schemes List`]: `스킴 목록`,
  [`Small`]: `소형`,
  [`Response Definitions`]: `응답 정의`,
  [`Terms Of Service`]: `서비스 약관`,
  [`Url`]: `Url`,
  [`Untitled Parameter`]: `제목 없는 매개변수`,
  [`Parameter Name`]: `매개변수 이름`,
  [`Mime Type`]: `MIME 유형`,
  [`Async API`]: `비동기 API`,
  [`Channels`]: `채널`,
  [`Operation Traits`]: `오퍼레이션 특성`,
  [`Messages`]: `메시지`,
  [`Correlation IDs`]: `상관 ID`,
  [`Traits`]: `특성`,
  [`Message Traits`]: `메시지 특성`,
  [`Bindings`]: `바인딩`,
  [`Server Bindings`]: `서버 바인딩`,
  [`Channel Bindings`]: `채널 바인딩`,
  [`Operation Bindings`]: `오퍼레이션 바인딩`,
  [`Message Bindings`]: `메시지 바인딩`,
  [`Protocol Version`]: `프로토콜 버전`,
  [`Define multiple messages`]: `여러 메시지 정의`,
  [`Schema Format`]: `스키마 형식`,
  [`Unsupported Field`]: `지원되지 않는 필드`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `오퍼레이션 특성`,
  [`Message Trait`]: `메시지 특성`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `오퍼레이션 전환 정책을 사용하여 어셈블리의 섹션을 특정 오퍼레이션에 적용하십시오.`,
  [`HTML page`]: `HTML 페이지`,
  [`Rule`]: `규칙`,
  [`Origin`]: `오리진`,
  [`Property Name (Key)`]: `특성 이름(키)`,
  [`Catalog Name (Key)`]: `카탈로그 이름(키)`,
  [`Property Overrides`]: `특성 대체`,
  [`Property Name`]: `특성 이름`,
  [`Property Value`]: `특성 값`,
  [`About subscriptions and plans`]: `등록 및 플랜 정보`,
  [`Subscription Plan Name`]: `등록 플랜 이름`,
  [`Select plan`]: `플랜 선택`,
  [`Select the plan to migrate application subscriptions from`]: `애플리케이션 등록을 마이그레이션할 플랜 선택`,
  [`Select subscriptions`]: `등록 선택`,
  [`Select the application subscriptions to migrate`]: `마이그레이션할 애플리케이션 등록 선택`,
  [`Select the product to migrate application subscriptions to`]: `애플리케이션 등록을 마이그레이션할 제품 선택`,
  [`Select the plan to migrate application subscriptions to`]: `애플리케이션 등록을 마이그레이션할 플랜 선택`,
  [`Message successfully sent to {sent} owner(s)`]: `{sent} 소유자에게 메시지를 전송했습니다.`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent}개의 메시지를 전송하는 데 실패했습니다.`,
  [`Failed email addresses`]: `실패한 이메일 주소`,
  [`Failed to save the API`]: `API를 저장하는 데 실패함`,
  [`and {numExtra} more...`]: `외 {numExtra}개 더...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `전송에 실패한 이메일 주소 목록을 클립보드에 복사`,
  [`From`]: `원본`,
  [`Message Owner`]: `메시지 소유자`,
  [`Message owner`]: `메시지 소유자`,
  [`Message owners`]: `메시지 소유자`,
  [`Message group`]: `메시지 그룹`,
  [`Select consumer organizations`]: `이용자 조직 선택`,
  [`Message consumer organization owners`]: `메시지 이용자 조직 소유자`,
  [`Message consumer organization owner`]: `메시지 이용자 조직 소유자`,
  [`Message provider organization owner`]: `메시지 제공자 조직 소유자`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `이 호출 정책과 관련된 Kafka 클러스터의 ID`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Kafka 클러스터에 대한 연결을 설정하는 데 사용할 호스트:포트 쌍의 쉼표로 구분된 목록`,
  [`The username to use when connecting to the Kafka cluster.`]: `Kafka 클러스터에 연결할 때 사용할 사용자 이름입니다.`,
  [`The password to use when connecting to the Kafka cluster.`]: `Kafka 클러스터에 연결할 때 사용할 비밀번호입니다.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `이벤트 게이트웨이 서비스`,
  [`Event gateway management client keystore`]: `이벤트 게이트웨이 관리 클라이언트 키 저장소`,
  [`Edit Event gateway Service`]: `이벤트 게이트웨이 서비스 편집`,
  [`Configure Event Gateway Service`]: `이벤트 게이트웨이 서비스 구성`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `비동기 API 보안 및 적용에 필요한 이벤트 게이트웨이 서비스 구성`,
  [`Catalogs with published product`]: `공개된 제품이 포함된 카탈로그`,
  [`Cluster Config ID`]: `클러스터 구성 ID`,
  [`SASL mechanism`]: `SASL 메커니즘`,
  [`Security protocol`]: `보안 프로토콜`,
  [`Transport CA certificate`]: `CA 인증서 전송`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `최대 파일 크기는 {size}입니다. 지원되는 파일 유형은 json, xml, yml, yaml, zip 입니다.`,
  [`Supported file types are json, xml, yml & yaml.`]: `지원되는 파일 유형은 json, xml, yml, yaml 입니다.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `지원되는 파일 유형은 json, wsdl, xml, xsd, yml, yaml, zip 입니다.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `지원되는 파일 유형은 json, wsdl, xml, yml, yaml 입니다.`,
  [`Select a file`]: `파일 선택`,
  [`or specify a file URL`]: `또는 파일 URL 지정`,
  [`Generated OpenAPI {version} definition`]: `생성된 OpenAPI {version} 정의`,
  [`User registry type`]: `사용자 레지스트리 유형`,
  [`User registry types`]: `사용자 레지스트리 유형`,
  [`User registry type {title} has been created.`]: `사용자 레지스트리 유형 {title}이(가) 작성되었습니다.`,
  [`User registry type {title} has been updated.`]: `사용자 레지스트리 유형 {title}이(가) 업데이트되었습니다.`,
  [`Failed to create user registry type {title}.`]: `사용자 레지스트리 유형 {title}을(를) 작성하는 데 실패했습니다.`,
  [`Failed to update user registry type {title}.`]: `사용자 레지스트리 유형 {title}을(를) 업데이트하는 데 실패했습니다.`,
  [`Failed to update user registry {title}.`]: `사용자 레지스트리 {title}을(를) 업데이트하는 데 실패했습니다.`,
  [`Custom user registry`]: `사용자 정의 사용자 레지스트리`,
  [`Create custom user registry`]: `사용자 정의 사용자 레지스트리 작성`,
  [`Edit custom user registry`]: `사용자 정의 사용자 레지스트리 편집`,
  [`Create user registry type`]: `사용자 레지스트리 유형 작성`,
  [`Edit user registry type`]: `사용자 레지스트리 유형 편집`,
  [`Configure user authentication using a custom external user registry`]: `사용자 정의 외부 사용자 레지스트리를 사용하여 사용자 인증 구성`,
  [`Secured Endpoint`]: `보안 엔드포인트`,
  [`Configuration schema`]: `구성 스키마`,
  [`About custom user registries`]: `사용자 정의 사용자 레지스트리 정보`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `사용자를 개발자 포털에 인증하기 위해 사용자 정의 사용자 레지스트리를 사용할 수 있지만 이를 사용하여 API를 보호할 수는 없습니다. 사용자 정의 사용자 레지스트리를 API 관리자의 자원으로 구성하려면 외부 사용자 디렉토리를 작성하여 API Connect 에코시스템에서 사용할 수 있어야 합니다.`,
  [`Update Wsdl`]: `WSDL 업데이트`,
  [`Update Existing WSDL service`]: `기존 WSDL 서비스 업데이트`,
  [`Format of selected WSDL has been successfully validated`]: `선택한 WSDL의 형식이 유효성 검증됨`,
  [`Select the target WSDL file to update from`]: `업데이트할 대상 WSDL 파일 선택`,
  [`Selected WSDL has been successfully uploaded`]: `선택한 WSDL이 업로드됨`,
  [`Services available in selected API`]: `선택한 API에서 사용 가능한 서비스`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `OpenAPI 2.0 문서(더 이상 사용되지 않음)에 대해 레거시 Open API 편집기를 사용합니다`,
  [`Unknown`]: `알 수 없음`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API 또는 제품에 지정된 게이트웨이 유형과 상충함에 따라 {incompatibleCatalogCount}개의 카탈로그가 숨겨짐`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API 또는 제품에 지정된 게이트웨이 유형과 상충함에 따라 {incompatibleSpacesCount}개의 공간이 숨겨짐`,
  [`Keep in mind`]: `유의사항`,
  [`Only one type of enforced APIs is allowed per product`]: `제품마다 적용된 API 중 한 유형만 허용됨`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `제품에 지정된 게이트웨이 유형과 상충함에 따라 {incompatibleAPIsCount}개의 API를 선택할 수 없음`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `API에 지정된 게이트웨이 유형과 상충함에 따라 {incompatibleProductsCount}개의 제품을 선택할 수 없음`,
  [`Unenforced`]: `적용되지 않음`,
  [`Create a new product by adding APIs and plans.`]: `API 및 플랜을 추가하여 새 제품을 작성하십시오.`,
  [`Rate limits are only applied to enforced APIs.`]: `비율 한계는 적용된 API에만 적용됩니다.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `플랜 및 API 한계를 제품에 추가합니다. 플랜 한계는 적용된 API에만 적용되고 적용되지 않은 API는 무제한입니다.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `플랜 및 비율 한계를 제품에 추가합니다. 플랜은 적용된 API에만 적용되고 적용되지 않은 API는 무제한입니다.`,
  [`Delete plan`]: `플랜 삭제`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `AsyncAPI를 위한 이벤트 게이트 서비스를 선택하십시오. 다른 모든 유형의 API의 경우 DataPower API Gateway 또는 v5 호환 버전을 선택하십시오. 이 설정을 업데이트하려면 먼저 연관된 API를 제거하십시오.`,
  [`Edit API list`]: `API 목록 편집`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `이 제품에서 적용된 API에는 둘 이상의 게이트웨이 유형이 필요합니다.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `이 제품에서 적용된 {apiType}에는 {gatewayType}이(가) 필요합니다.`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API`,
  [`AsyncAPI(s)`]: `AsyncAPI`,
  [`Select the gateway type for this Product`]: `이 제품의 게이트웨이 유형 선택`,
  [`Added default plan`]: `추가된 기본 플랜`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `제품을 샌드박스 카탈로그에 공개합니다. 이는 제품을 테스트 앱에 연관시킵니다.`,
  [`cluster`]: `클러스터`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name}({email})`,
  [`Message from the rejector`]: `거부자의 메시지`,
  [`Vendor extensions feature disabled`]: `사용 안함으로 설정된 벤더 확장 기능`,
  [`No configured gateways found`]: `구성된 게이트웨이를 찾을 수 없음`,
  [`No OpenAPI extensions associated with the configured gateway`]: `구성된 게이트웨이와 연관된 OpenAPI 확장 없음`,
  [`Extension`]: `확장`,
  [`Select the extensions to add to the API document`]: `API 문서에 추가할 확장 선택`,
  [`(Operations: {operations})`]: `(오퍼레이션: {operations})`,
  [`Failed to get application`]: `애플리케이션을 가져오는 데 실패함`,
  [`Failed to get credentials for application`]: `애플리케이션에 대한 신임 정보를 가져오는 데 실패함`,
  [`Failed to get published product`]: `공개된 제품을 가져오는 데 실패함`,
  [`Failed to get the published API`]: `공개된 API를 가져오는 데 실패함`,
  [`API must be activated to use the Test tool`]: `테스트 도구를 사용하려면 API를 활성화해야 함`,
  [`Import from Asset repository`]: `자산 저장소에서 가져오기`,
  [`Untitled {componentLabel}`]: `제목 없음 {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `라이센스 메트릭에서 분석 서비스가 사용을 추적해야 합니다. 분석 서비스를 구성하려면 "서비스 등록"을 클릭하십시오.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `모든 DataPower 게이트웨이 서비스에는 사용량을 추적하는 분석 서비스가 필요합니다. 각 게이트웨이에 대해 "분석 서비스 연관"을 클릭하여 라이센스 항목을 준수하십시오.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `카탈로그가 API 또는 제품에 지정된 게이트웨이 유형과 호환되지 않음`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `공간이 API 또는 제품에 지정된 게이트웨이 유형과 호환되지 않음`,
  [`Preserve Subscription`]: `구독 유지`,
  [`Publish validation`]: `공개 유효성 검증`,
  [`Publish validations`]: `유효성 검증 공개`,
  [`Edit publish validations`]: `공개 유효성 검증 편집`,
  [`The following validations will be performed when publishing a product`]: `제품을 공개할 때 다음 유효성 검증이 수행됩니다.`,
  [`There are no publish validations enabled`]: `사용 가능한 공개 유효성 검증이 없습니다.`,
  [`Publish validations have been updated`]: `공개 유효성 검증이 업데이트되었습니다.`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `이벤트 게이트웨이 서비스가 백엔드 Kafka 서버 인증서를 신뢰하는 데 사용하는 인증 기관(CA)을 제공하십시오. 서버가 잘 알려진 인증 기관에서 발행한 인증서를 사용 중인 경우 CA를 제공할 필요가 없습니다.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `부트스트랩 서버에 대해 입력한 값이 올바르지 않습니다. 클러스터에 있는 각 Kafka 브로커의 호스트 및 포트를 쉼표로 구분하여 입력하십시오. 예: broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `OpenAPI 확장 업로드`,
  [`Add OpenAPI Extension`]: `OpenAPI 확장 추가`,
  [`Gateway Endpoint`]: `게이트웨이 엔드포인트`,
  [`API Dashboard`]: `API 대시보드`,
  [`API call data`]: `API 호출 데이터`,
  [`Product Dashboard`]: `제품 대시보드`,
  [`API Product consumption metrics`]: `API 제품 소비 지표`,
  [`Monitoring Latency Dashboard`]: `대기 시간 대시보드 모니터링`,
  [`Data about the time taken to serve API calls`]: `API 호출을 제공하는 데 소요되는 시간에 대한 데이터`,
  [`Monitoring Status Dashboard`]: `모니터링 상태 대시보드`,
  [`API Error and success information`]: `API 오류 및 성공 정보`,
  [`Usage Dashboard`]: `사용량 대시보드`,
  [`Usage for gateway {gateway}`]: `게이트웨이 {gateway}의 사용법`,
  [`Latency for gateway {gateway}`]: `게이트웨이 {gateway}의 대기 시간`,
  [`Most popular products, APIs and applications`]: `가장 인기 있는 제품, API 및 애플리케이션`,
  [`Consumption Dashboard`]: `소비 대시보드`,
  [`Total API volume useful for auditing and compliance`]: `감사 및 준수에 유용한 총 API 볼륨`,
  [`Monthly Summary by Status Code`]: `상태 코드별 월별 요약`,
  [`Monthly Summary Data by Status Code`]: `상태 코드별 월별 요약 데이터`,
  [`Daily Summary by Status Code`]: `상태 코드별 일별 요약`,
  [`Daily Summary Data by Status Code`]: `상태 코드별 일별 요약 데이터`,
  [`Response time (ms)`]: `응답 시간(밀리초)`,
  [`Datetime`]: `날짜/시간`,
  [`Transaction ID:`]: `트랜잭션 ID:`,
  [`Other`]: `기타`,
  [`Date time`]: `날짜 및 시간`,
  [`Total calls`]: `총 호출`,
  [`Date`]: `날짜`,
  [`Month`]: `월`,
  [`Minimum response time`]: `최소 응답 시간`,
  [`Maximum response time`]: `최대 응답 시간`,
  [`Average response time`]: `평균 응답 시간`,
  [`Total API calls`]: `총 API 호출`,
  [`Total APIs`]: `전체 API`,
  [`APIs called`]: `호출된 API`,
  [`Total products`]: `총 제품`,
  [`products called`]: `호출된 제품`,
  [`Total errors`]: `총 오류`,
  [`Status codes (detailed)`]: `상태 코드(상세)`,
  [`Response time percentiles`]: `응답 시간 백분위수`,
  [`API calls per day`]: `일별 API 호출 수`,
  [`Time zone:`]: `시간대:`,
  [`Time zone`]: `시간대`,
  [`Toggle axes`]: `토글 축`,
  [`Datetime:`]: `날짜/시간:`,
  [`datetime per 30 seconds`]: `30초당 날짜 시간`,
  [`Datetime (local time)`]: `날짜/시간(로컬 시간)`,
  [`Data usage (bytes)`]: `데이터 사용량(바이트)`,
  [`Time to Serve Request`]: `서버 요청까지의 시간`,
  [`API name`]: `API 이름`,
  [`Product name`]: `제품 이름`,
  [`Data usage (bytes received)`]: `데이터 사용량(수신된 바이트)`,
  [`Data usage (bytes sent)`]: `데이터 사용량(전송된 바이트)`,
  [`Response times (>1s)`]: `응답 시간(>1s)`,
  [`milliseconds`]: `밀리초`,
  [`errors`]: `오류`,
  [`Success Rate`]: `성공률`,
  [`Errors Data`]: `오류 데이터`,
  [`Success Data`]: `성공 데이터`,
  [`Total consumer organizations`]: `전체 이용자 조직`,
  [`making API calls`]: `API 호출 작성`,
  [`Applications per plan`]: `계획당 애플리케이션`,
  [`Top products by calls`]: `호출별 상위 제품`,
  [`Top APIs by calls`]: `호출별 상위 API`,
  [`Top client IP addresses by calls`]: `호출별 상위 클라이언트 IP 주소`,
  [`Client IP address`]: `클라이언트 IP 주소`,
  [`Top APIs by request size`]: `요청 크기별 상위 API`,
  [`Top APIs by response size`]: `응답 크기별 상위 API`,
  [`Top APIs by response time`]: `응답 시간별 상위 API`,
  [`Top applications by calls`]: `호출별 상위 애플리케이션`,
  [`Dashboards`]: `대시보드`,
  [`Discover`]: `검색`,
  [`UTC`]: `UTC`,
  [`Local time`]: `로컬 시간`,
  [`URI`]: `URI`,
  [`Response code`]: `응답 코드`,
  [`Total time elapsed (ms)`]: `총 경과 시간(ms)`,
  [`Timestamp`]: `시간소인`,
  [`Method`]: `방법`,
  [`Gateway IP`]: `게이트웨이 IP`,
  [`Gateway Operations Dashboard`]: `게이트웨이 운영 대시보드`,
  [`Usage and latency information per gateway`]: `게이트웨이별 사용량 및 대기 시간 정보`,
  [`Transaction ID`]: `트랜잭션 ID`,
  [`Filters`]: `필터`,
  [`Fields`]: `필드`,
  [`Clear all`]: `모두 지우기`,
  [`API Event`]: `API 이벤트(API Event)`,
  [`API Assembly Policy Latencies`]: `API 어셈블리 정책 대기 시간`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `아래 다이어그램은 API 어셈블리 정책을 통해 요청이 진행되는 동안 증가하는 지연 시간(밀리초)을 보여 줍니다. 이는 API 어셈블리에서 느린 지점 또는 병목 현상을 판별하는 데 도움이 될 수 있습니다.`,
  [`Collapse`]: `접기`,
  [`Expand`]: `펼치기`,
  [`enter name`]: `이름 입력`,
  [`Enter value`]: `값 입력`,
  [`Time range`]: `시간 범위`,
  [`Select one of the following time range filters`]: `다음 시간 범위 필터 중 하나 선택`,
  [`Last 1 hour`]: `최근 1시간`,
  [`Last {number} minutes`]: `최근 {number}분`,
  [`Last {number} hours`]: `최근 {number}시간`,
  [`Last {number} days`]: `최근 {number}일`,
  [`Start date`]: `시작 날짜`,
  [`End date`]: `종료 날짜`,
  [`Select from the following dropdowns and enter a value`]: `다음 드롭 다운에서 선택하고 값 입력`,
  [`Select one of the following type filters`]: `다음 유형 필터 중 하나 선택`,
  [`All events`]: `모든 이벤트`,
  [`Success only (2xx, 3xx)`]: `성공만(2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `오류만(4xx, 5xx)`,
  [`Import/Export`]: `가져오기/내보내기`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `{time}에서 {totalItems}개의 결과({searchTime}초)`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `{totalItems}개의 결과({searchTime}초) - {time}({utcTime})`,
  [`API calls`]: `API 호출`,
  [`Export as CSV`]: `CSV로 내보내기`,
  [`Export as JSON`]: `JSON으로 내보내기`,
  [`Export as PNG`]: `PNG로 내보내기`,
  [`Export as JPG`]: `JPG로 내보내기`,
  [`Edit filter`]: `필터 편집`,
  [`Edit query`]: `쿼리 편집`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `이는 공유된 쿼리입니다. 이 쿼리가 공유되는 모든 사용자에 대해 수정사항이 작성됩니다.`,
  [`This is a shared query. This will delete it for all users.`]: `이는 공유된 쿼리입니다. 이렇게 하면 모든 사용자에 대해 삭제됩니다.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{org} 제공자 조직의 모든 사용자에 대해 {queryname}이(가) 업데이트되었습니다.`,
  [`{queryname} has been updated for all users in the {space} space`]: `{space} 공간의 모든 사용자에 대해 {queryname}이(가) 업데이트되었습니다.`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{catalog} 카탈로그의 모든 사용자에 대해 {queryname}이(가) 업데이트되었습니다.`,
  [`{queryname} has been updated for all users in the cloud manager`]: `클라우드 관리자의 모든 사용자에 대해 {queryname}이(가) 업데이트되었습니다.`,
  [`Stop sharing`]: `공유 중지`,
  [`Share query`]: `공유 쿼리`,
  [`Share filter`]: `공유 필터`,
  [`You do not have permissions to delete this filter`]: `이 필터를 삭제할 수 있는 권한이 없습니다.`,
  [`You do not have permissions to delete this query`]: `이 쿼리를 삭제할 수 있는 권한이 없습니다.`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `{org} 제공자 조직 범위에서 {queryTitle} 쿼리를 공유하시겠습니까?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `{space} 공간 범위에서 {queryTitle} 쿼리를 공유하시겠습니까?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `{catalog} 카탈로그 범위에서 {queryTitle} 쿼리를 공유하시겠습니까?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `클라우드 범위에서 {queryTitle} 쿼리를 공유하시겠습니까?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `{queryTitle} 쿼리 공유를 중지하시겠습니까?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `{org} 제공자 조직 범위에서 {queryTitle} 쿼리 공유를 중지하시겠습니까?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `{space} 공간 범위에서 {queryTitle} 쿼리 공유를 중지하시겠습니까?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `{catalog} 카탈로그 범위에서 {queryTitle} 쿼리 공유를 중지하시겠습니까?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `클라우드 범위에서 {queryTitle} 쿼리 공유를 중지하시겠습니까?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `{queryTitle} 쿼리를 삭제하시겠습니까?`,
  [`At least one query parameter expected`]: `하나 이상의 쿼리 매개변수가 예상됩니다`,
  [`A provided query parameter is incomplete`]: `제공된 쿼리 매개변수가 불완전합니다`,
  [`Could not parse query string`]: `쿼리 문자열을 구문 분석할 수 없습니다`,
  [`An unsupported query parameter was provided: {query}`]: `지원되지 않는 쿼리 매개변수가 제공되었습니다.: {query}`,
  [`Invalid timeframe value: {value}`]: `유효하지 않은 시간 프레임 값: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `Value of {field} 값은 RFC 3339, 섹션 5.6에서 정의한 날짜/시간 표기법을 따라야 합니다. 예: 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `{operator} 연산자가 {field} 필드에 대해 지원되지 않습니다`,
  [`The value of {field} must be a number`]: `{field} 값은 숫자여야 합니다`,
  [`Delete query`]: `조회 삭제`,
  [`Delete filter`]: `필터 삭제`,
  [`Save query`]: `조회 저장`,
  [`Save filter`]: `필터 저장`,
  [`Save filter as`]: `필터 저장`,
  [`Save query as`]: `다른 이름으로 쿼리 저장`,
  [`Save as...`]: `다른 이름으로 저장...`,
  [`Save as`]: `다른 이름으로 저장`,
  [`Paste query string to load the query`]: `조회 문자열을 붙여넣어 조회 로드`,
  [`Copy or paste filter string to load filters`]: `필터를 로드하기 위해 필터 문자열 복사 또는 붙여넣기`,
  [`Query string`]: `조회 문자열`,
  [`View filter`]: `필터 보기`,
  [`View filter string`]: `필터 스트링 보기`,
  [`Date (local time)`]: `날짜(로컬 시간)`,
  [`Coming soon, a new built in analytics feature`]: `새로 빌드된 분석 기능이 곧 제공됨`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `분석은 API 런타임에 대한 인사이트를 수집하고, 서비스 레벨을 관리하고, 할당량을 설정하고, 제어를 설정하고, 보안 정책을 설정하고, 커뮤니티를 관리하고, 경향을 분석하는 데 도움을 줍니다.`,
  [`Documentation`]: `문서`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `API 런타임 데이터의 유형 및 AWS s3 버킷을 사용하여 시각화하는 방법을 자세히 알아봅니다.`,
  [`Blog`]: `블로그`,
  [`Learn about what data is available today and how to access it.`]: `현재 사용 가능한 데이터와 이 데이터에 액세스하는 방법을 알아봅니다.`,
  [`Next steps`]: `다음 단계`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `선택한 Secrets Manager 서비스는 인증서를 관리하지 않습니다.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `API Connect와 게이트웨이 관리 엔드포인트의 도메인 간의 연결을 보호하려면 인증서가 필요합니다. 게이트웨이와 게이트웨이가 처리하는 도메인 간의 연결을 보호하려면 인증서가 필요합니다. 모든 인증서는 Secrets Manager 서비스에 저장되어야 합니다. 서비스는 인증서에 대한 보안 저장소를 제공하며, 이벤트 알림 서비스와의 통합에 대한 인증서가 만료될 예정일 때 알림을 전송하여 중단을 방지하는 데 도움을 줍니다. 시작하려면 다음을 수행하십시오.`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `게이트웨이와 게이트웨이가 처리하는 도메인 간의 연결을 보호하려면 인증서가 필요합니다. 모든 인증서는 Secrets Manager 서비스에 저장되어야 합니다. 서비스는 인증서에 대한 보안 저장소를 제공하며, 이벤트 알림 서비스와의 통합에 대한 인증서가 만료될 예정일 때 알림을 전송하여 중단을 방지하는 데 도움을 줍니다. 시작하려면 다음을 수행하십시오.`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Secrets Manager를 변경하면 기존의 모든 인증서 및 CA 번들 지정이 제거됩니다.`,
  [`Create a [Secrets Manager]({link}) service`]: `[Secrets Manager]({link}) 서비스 작성`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `필수 인증서 및 CA 번들을 Secrets Manager 서비스로 가져오십시오.`,
  [`No Secrets Manager services available`]: `사용 가능한 Secrets Manager 서비스가 없음`,
  [`Select Secrets Manager service`]: `Secrets Manager 서비스 선택`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `이 게이트웨이의 인증서를 관리하는 [Secrets Manager]({link}) 서비스를 선택하십시오.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Secrets Manager 서비스는 게이트웨이 관리 엔드포인트의 도메인 및 게이트웨이가 처리하는 도메인에 대한 인증서를 관리해야 합니다. 또한 Secrets Manager 서비스에 액세스하려면 IBM Cloud IAM을 사용하여 API Connect Reserved Instance 서비스에 대한 권한을 부여해야 합니다.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `그런 다음 관리 엔드포인트의 도메인에 대한 인증서를 선택합니다. 또한 잘 알려진 인증 기관에서 인증서를 할당하지 않은 경우 해당 CA 번들을 선택합니다. 인증서 및 번들은 Secrets Manager 서비스에서 관리해야 합니다.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `그런 다음 이 게이트웨이에서 처리할 도메인을 지정하십시오. SNI 기술이 사용됩니다. 결과적으로 동일한 인증서 없이도 동일한 IP 주소 및 포트를 통해 여러 도메인을 제공할 수 있습니다. 와일드 카드 형식이 지원됩니다. 표의 최종 항목으로‘*의 기본(포괄적) 도메인을 포함해야 합니다. Secrets Manager 서비스에서 관리하는 인증서를 지정하는 경우 필요에 따라 다른 도메인 이름을 입력하십시오.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Secrets Manager 서비스에 액세스하려면 [IBM Cloud IAM]({link})을 사용하여 API Connect Reserved Instance 서비스에 대한 권한을 부여하십시오.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `기존 인증서 및 CA 번들 지정을 Secrets Manager에서 로드하여 업데이트하십시오.`,
  [`Access s3 data`]: `s3 데이터에 액세스`,
  [`Create resource`]: `리소스 작성`,
  [`Connect analytics data in s3 bucket with AWS`]: `AWS로 s3 버킷에 분석 데이터 연결`,
  [`AWS account`]: `AWS 계정`,
  [`AWS account number`]: `AWS 계정 번호`,
  [`Enter your 12 digit aws account id`]: `12자리 AWS 계정 ID를 입력하십시오.`,
  [`Account id must be 12 digits`]: `계정 ID는 12자리여야 함`,
  [`Failed to update AWS account id.`]: `AWS 계정 ID를 업데이트하는 데 실패했습니다.`,
  [`You have limited access to the catalogs.`]: `카탈로그에 대한 액세스가 제한됩니다.`,
  [`You have limited access to the spaces.`]: `공간에 대한 액세스가 제한되어 있습니다.`,
  [`Contact admin to get more access.`]: `추가 액세스 권한을 얻으려면 관리자에게 문의하십시오.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `이 카탈로그를 선택할 수 없습니다. 액세스가 필요한 경우 관리자에게 문의하십시오.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `이 영역을 선택할 수 없습니다. 액세스가 필요한 경우 관리자에게 문의하십시오.`,
  [`You have limited access to the product billing.`]: `제품 청구에 대한 제한된 액세스 권한이 있습니다.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `AWS 계정 ID를 업데이트했습니다. S3 데이터에 대한 액세스 권한을 부여하는 데 몇 초가 걸릴 수 있습니다.`,
  [`An organization or a group is missing in the catalog chosen.`]: `선택한 카탈로그에서 조직 또는 그룹이 누락되었습니다.`,
  [`Secrets Manager instance`]: `Secrets Manager 인스턴스`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `이메일이 누락된 소유자가 있는 이용자 조직은 선택할 수 없음`,
  [`You currently don’t have any data.`]: `현재 데이터가 없습니다.`,
  [`You currently don’t have any organizations.`]: `현재 조직이 없습니다.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `{prodname} 제품의 {plan} 플랜에 {appname} 앱을 구독하도록 요청합니다.{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `{operation} 제품에 대한 요청: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `{appname} 애플리케이션을 운영 환경으로 업그레이드하도록 요청합니다`,
  [`Onboarding request for {email}`]: `{email}에 대한 온보딩 요청`,
  [`Product lifecycle request`]: `제품 라이프사이클 요청`,
  [`Subscription request`]: `등록 요청`,
  [`Application lifecycle request`]: `애플리케이션 라이프사이클 요청`,
  [`Consumer Onboarding request`]: `소비자 온보딩 요청`,
  [`Originator`]: `시작자`,
  [`Edit analytics service advanced settings`]: `분석 서비스 고급 설정 편집`,
  [`Advanced settings`]: `고급 설정`,
  [`Must be a positive integer`]: `양의 정수여야 합니다`,
  [`Maximum value is {maxValue}`]: `최대값은 {maxValue}입니다`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `최대값은 {maxValueDays}d(일) 또는 {maxValueHours}h(시간)입니다`,
  [`Configure advanced settings for the analytics deployment.`]: `분석 배포에 대한 고급 설정을 구성합니다.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `값은 일의 경우 'd'로 끝나야 하고 시간의 경우 'h'로 끝나야 합니다. 예: 30d 또는 12h.`,
  [`Rollover settings`]: `롤오버 설정`,
  [`Retention settings`]: `보관 설정`,
  [`Delete an index when it meets the following condition:`]: `인덱스가 다음 조건을 충족하면 삭제합니다`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `관리 인덱스가 다음 조건 중 하나를 충족하는 경우 새 인덱스에 쓰기 별칭을 롤오버합니다.`,
  [`Minimum document count`]: `최소 문서 수`,
  [`The minimum number of documents required to roll over the index.`]: `인덱스를 롤오버하는 데 필요한 최소 문서 수입니다.`,
  [`Minimum index age`]: `최소 인덱스 수명`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `인덱스를 롤오버하는 데 필요한 최소 인덱스 수명입니다. 인덱스 사용 기간은 인덱스 생성 시점과 현 시점 사이의 시간입니다. 날짜에는 'd', 시간에는 'h'를 사용하세요. 예를 들어, 30d는 사용 기간이 30일에 도달하면 인덱스가 롤오버되는 것을 의미하며, 96h는 사용 기간이 96시간에 도달하면 인덱스가 롤오버되는 것을 의미합니다. 최대값은 {maxValueDays}d(일) 또는 {maxValueHours}h(시간)입니다.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `인덱스를 삭제하는 데 필요한 최소 인덱스 수명입니다. 인덱스 사용 기간은 인덱스 생성 시점과 현 시점 사이의 시간입니다. 날짜에는 'd', 시간에는 'h'를 사용하세요. 예를 들어, 30d는 사용 기간이 30일에 도달하면 인덱스가 삭제되는 것을 의미하며, 96h는 사용 기간이 96시간에 도달하면 인덱스가 삭제되는 것을 의미합니다. 최대값은 {maxValueDays}d(일) 또는 {maxValueHours}h(시간)입니다.`,
  [`Time range:`]: `시간 범위:`,
  [`Time range: Before {datetime}`]: `시간 범위: {datetime} 이전`,
  [`Time range: After {datetime}`]: `시간 범위: {datetime} 이후`,
  [`Filter before`]: `이전 필터`,
  [`Filter after`]: `이후 필터`,
  [`Filter exact`]: `정확한 필터`,
  [`Filter gt`]: `필터 GT`,
  [`Filter lt`]: `필터 IT`,
  [`Filter equals`]: `필터 같음`,
  [`Filter by`]: `필터 기준`,
  [`Filter out`]: `필터링됨`,
  [`Delete failed`]: `삭제 실패`,
  [`Delete warning`]: `경고 삭제`,
  [`Could not delete saved query {queryTitle}.`]: `저장된 쿼리 {queryTitle}을(를) 삭제할 수 없습니다.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `요청이 응답했지만 {queryTitle}은(는) 아직 삭제를 완료하지 않았습니다.`,
  [`API governance`]: `API 거버넌스`,
  [`Something's wrong`]: `문제가 발생`,
  [`We were unable to connect to the service.`]: `서비스에 연결할 수 없습니다.`,
  [`Invalid date`]: `올바르지 않은 날짜`,
  [`Invalid time`]: `잘못된 시간`,
  [`End date must be after the start date`]: `종료 날짜는 시작 날짜 이후여야 합니다.`,
  [`Duplicate`]: `중복`,
  [`Duplicate query`]: `중복 쿼리`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `{queryTitle}의 복제본을 작성하시겠습니까?`,
  [`No queries saved`]: `저장된 쿼리 없음`,
  [`No queries shared`]: `공유된 쿼리 없음`,
  [`To save a query, start by creating one then save.`]: `쿼리를 저장하려면 쿼리를 작성한 다음 저장하십시오.`,
  [`Share a saved query and allow others to use it.`]: `저장된 쿼리를 공유하고 다른 사용자가 사용할 수 있도록 합니다.`,
  [`Query updated`]: `쿼리가 업데이트됨`,
  [`Query update failed`]: `쿼리 업데이트 실패`,
  [`Share successful`]: `공유 성공`,
  [`Share failed`]: `공유 실패`,
  [`Delete successful`]: `삭제 성공`,
  [`Query unshared`]: `쿼리가 공유되지 않음`,
  [`Unshare {queryTitle} failed`]: `{queryTitle} 공유 해제 실패`,
  [`Query copied`]: `쿼리가 복사됨`,
  [`Duplicate {queryTitle} failed`]: `중복 {queryTitle} 실패`,
  [`The query {queryTitle} has been updated.`]: `{queryTitle} 쿼리가 업데이트되었습니다.`,
  [`The query {queryTitle} has been shared.`]: `{queryTitle} 쿼리가 공유되었습니다.`,
  [`The query {queryTitle} has been deleted.`]: `{queryTitle} 쿼리가 삭제되었습니다.`,
  [`The query {queryTitle} is no longer shared.`]: `{queryTitle} 쿼리는 더 이상 공유되지 않습니다.`,
  [`A copy of {queryTitle} has been created.`]: `{queryTitle}의 사본이 작성되었습니다.`,
  [`About queries`]: `쿼리 정보`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `쿼리는 대시보드와 Discover 보기에서 사용되는 분석 데이터 미세 조정 수단입니다. 쿼리가 적용되면 모든 대시보드가 업데이트되어 선택된 데이터만 포함됩니다.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `사전 정의된 시간 범위를 사용하거나 사용자 정의 옵션으로 초 단위로 반올림하여 특정 시간 범위를 사용할 수 있습니다.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `필드를 사용하여 특정 데이터만 포함되도록 지정할 수 있습니다. API 이벤트의 거의 모든 속성을 기준으로 필터링할 수 있습니다.`,
  [`There are then different operators available depending on the field type.`]: `필드 유형에 따라 여러 가지 연산자를 사용할 수 있습니다.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `문자열 필드에는 같음, 아님, 시작값, 종료값, 정규식을 사용할 수 있습니다.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `단일 필드를 위한 값의 목록(예: API 버전 목록)을 제공하기 위해 포함 및 포함하지 않음을 사용할 수 있습니다.`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP 주소 필드에는 같음 및 아님 연산자를 사용할 수 있으며, 특정 네트워크를 선택하기 위해 넷 마스크를 사용할 수 있도록 값을 CIDR 표기에서 지정할 수 있습니다.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `정규식은 특정 값을 선택할 때 매우 유용할 수 있지만 실행 속도가 느립니다. 따라서 '시작값' 및 '종료값'과 같은 다른 연산자를 사용하는 것이 좋습니다.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `여러 필드의 쿼리 필터는 논리적 AND 연산자를 사용하여 결합합니다. 따라서 동일한 필터 이름에 두 개의 필터를 추가하면 두 필터를 충족하는 값만 반환됩니다.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `{유형} 쿼리 매개변수는 성공 또는 오류 이벤트만 지정할 수 있는 간단한 방법입니다. 이것은 {상태 코드} 필드를 사용합니다. 특정 상태 코드만 필요한 경우 필드 필터를 사용할 수 있습니다. {상태 코드 2} 필드의 값은 보통 {ok 상태}와 같은 전체 문자열이며 단지 숫자로 된 HTTP 반환 코드가 아닙니다.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `나중에 재사용하기 위해 쿼리를 저장하고 다른 사람도 사용할 수 있도록 현재 범위 수준과 공유할 수도 있습니다(예: 제공자 조직 또는 카탈로그).`,
  [`n/a`]: `해당사항 없음`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `참고: '제공자 조직 ID' 및 '제공자 조직 이름' 필드는 이벤트 페이로드에 각각 {조직 Id} 및 {조직 이름}(으)로 표시됩니다. '소비자 조직 ID' 및 '소비자 조직 이름' 필드는 {개발자 조직 Id} 및 {개발자 조직 이름}(으)로 표시됩니다.`,
  [`No authentication token`]: `인증 토큰 없음`,
  [`Contact your administrator for further assistance.`]: `추가 지원은 관리자에게 문의하십시오.`
};
