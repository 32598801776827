// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Katalog güncellenmedi`,
  [`Catalog has been updated`]: `Katalog güncellendi`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `Taslak ürün {draftProduct}, bir ya da daha fazla API'ye başvurmalıdır`,
  [`Number of Calls`]: `Çağrı Sayısı`,
  [`Changes will apply to newly published APIs only`]: `Değişiklikler yalnızca yeni yayınlanan API'lar için geçerli olacak`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Bu API için farklı bir Ağ Geçidi tipine sahip olması nedeniyle uyumsuz {label} var.`,
  [`{label} is required`]: `{label} gerekli`,
  [`Application is required`]: `Uygulama gerekli`,
  [`Plan is required`]: `Plan gerekli`,
  [`Gateway is required`]: `Ağ geçidi gerekli`,
  [`Consumer Org is required`]: `Tüketici Kuruluşu gerekli`,
  [`LDAP ATTRIBUTE NAME`]: `LDAP ÖZNİTELİK ADI`,
  [`Use the build in Test Application (sandbox only)`]: `Test Uygulamasında oluşturmayı kullan (yalnızca kum havuzu)`,
  [`Select an Application`]: `Uygulama seç`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Yerleşik Test Uygulamasını kullan (yalnızca kum havuzu) - kum havuzu dışında katalog seçildiğinde devre dışıdır`,
  [`Choose an existing Application`]: `Var olan bir Uygulama seçin`,
  [`Use JWT for gateway authentication to analytics service`]: `Analitik hizmetine ilişkin ağ geçidi kimlik doğrulaması için JWT kullanın`,
  [`Select an application to consume the API`]: `API kullanmak için bir Uygulama seçin`,
  [`Use the default built-in Sandbox Catalog'`]: `Varsayılan yerleşik Kum Havuzu Kataloğunu kullan'`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Not: Bu katalog diğer tüm tercihlerin kapsamı olarak kullanılacak. Bu ayarın değiştirilmesi diğer tercihlerin uyarlanmasını gerektirir.`,
  [`Select a Consumer Org`]: `Tüketici Kuruluş seçin`,
  [`Select the Consumer Org your generated application will belong to`]: `Oluşturulan uygulamanızın ait olduğu Tüketici Kuruluşu seçin`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Not: Var olan uygulamalar zaten bir Tüketici Kuruluşa ait olacağından bu seçenek yalnızca yerleşik Kum Havuzu Test Uygulaması kullanılırken değiştirilebilir`,
  [`Generate auto product`]: `Otomatik ürün oluştur`,
  [`Select a product to associate the current API with`]: `Geçerli API'yi ilişkilendirmek için bir ürün seçin`,
  [`Associate the current API to a selected product`]: `Yürürlükteki API'yi seçilen bir ürünle ilişkilendirin`,
  [`API management`]: `API yönetimi`,
  [`Select the API management instance you would like this API to be published to.`]: `Bu API'nin yayınlanmasını istediğiniz API yönetimi eşgörünümünü seçin.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Not: Bu eşgörünüm, diğer tüm tercihlerin kapsamı olarak kullanılır. Bu ayarın değiştirilmesi diğer tercihlerin uyarlanmasını gerektirir.`,
  [`Provider organization`]: `Sağlayıcı kuruluşu`,
  [`Select the provider organization you would like this API to be published to.`]: `Bu API'nin yayınlanmasını istediğiniz sağlayıcı kuruluşunu seçin.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Not: Bu kuruluş, diğer tüm tercihler için kapsam olarak kullanılır. Bu ayarın değiştirilmesi diğer tercihlerin uyarlanmasını gerektirir.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Not: Bu alan, diğer tüm tercihler için kapsam olarak kullanılır. Bu ayarın değiştirilmesi diğer tercihlerin uyarlanmasını gerektirir.`,
  [`Selected a draft product`]: `Taslak ürün seçildi`,
  [`Use any available gateway service`]: `Herhangi bir mevcut ağ geçidi hizmeti kullan`,
  [`Select a compatible gateway service`]: `Uyumlu bir ağ geçidi hizmeti seçin`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Not: Bu Ağ Geçidi Hizmeti diğer tüm tercihlerin kapsamı olarak kullanılacak. İlkeler ve diğer Ağ Geçidi Hizmetine özgü içerik bu ayara göre yüklenecek.`,
  [`Select a gateway`]: `Ağ geçidi seç`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Not: Bu seçenek 'Var Olan Ürün' seçildiğinde devre dışı bırakılır ve bunun yerine oluşturulan ürün için Varsayılan Planı kullanır`,
  [`Select a Plan`]: `Plan seç`,
  [`Generate subscription`]: `Abonelik oluştur`,
  [`A subscription will be created between the selected Product plan and Application`]: `Seçilen Ürün planı ve Uygulama arasında bir abonelik yaratılacak`,
  [`Apply a rate limit to the generated product`]: `Oluşturulan ürüne hız sınırı uygula`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Oluşturulan ürünlerin Varsayılan Planına bir hız sınırı uygulayın. Bu seçenek 'Var Olan Ürün' seçildiğinde devre dışıdır ve bunun yerine seçilen planda tanımlı hız sınırı kullanılır`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `Basit Dizin Erişimi İletişim Kuralı (LDAP), bir ağ üzerinden dağıtılmış dizin bilgi hizmetlerine erişmeye ve bu hizmetleri korumaya ilişkin bir internet iletişim kuralıdır. Etkinleştirilen bir kurumsal LDAP hizmetiniz varsa, bunu kullanıcı kimlik doğrulaması sağlamak için bir kaynak olarak yapılandırın. Yeni Geliştirici Portalı kullanıcıları için kullanıcı katilimi da sağlamak istiyorsanız yazılabilir LDAP'yi etkinleştirmek için Öznitelik eşlemesi bölümünü doldurmanız gerekir. Bu bölümde Kullanıcı tarafından yönetilen onay kutusunu seçin ve kaynak LDAP öznitelik adlarınızın hedef API Connect değerleriyle eşlemesini sağlayın.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Görev otomatik onayının etkinleştirilmesi, katılımcıların yanı sıra oluşturan tarafından görevlerin onaylanmasını sağlar. Diğer onaylayanlar olmadığında bu seçenek kullanışlı olabilir, ancak başka bir kullanıcı tarafından denetimlerin atlanmasını sağlar.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Bir faturalama bütünleştirmesi API Connect'i müşterileri, ödeme yöntemlerini, fatura ve etkin abonelikleri yöneten üçüncü kişi bir abonelik faturalama sistemindeki bir hesaba bağlar`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Yerel OAuth sağlayıcısı nesnesi, OAuth belirteçleri oluşturma ve doğrulama gibi OAuth işlemleri ayarlarını sağlar. Bir OAuth sağlayıcısı nesnesine, bir API koruması için OAuth güvenlik tanımı tarafından başvurulabilir. Yerel bir OAuth sağlayıcısı kullanıldığında OAuth işlemleri, API Connect tarafından yerel olarak gerçekleştirilir. Her OAuth sağlayıcısı nesnesinin destekleyici bir API'si vardır. Burada yapılandırmanız API'nin swagger belgesini otomatik olarak günceller. API Düzenleyici sayfasına giderek swagger belgesini düzenleyebilirsiniz. Yayınlanan bir API bir OAuth sağlayıcısı nesnesine başvurduğunda destekleyen API ağ geçidinde otomatik olarak kullanılabilir hale getirilir.`,
  [`About token management`]: `Belirteç yönetimi hakkında`,
  [`About user security`]: `Kullanıcı güvenliği hakkında`,
  [`APIs or products`]: `API'ler veya ürünler`,
  [`ATM is not deployed, please contact your administrator`]: `ATM devreye alınmadı, lütfen sistem yöneticinize başvurun`,
  [`Application state change approvals will be enabled`]: `Uygulama durumu değişiklik onayları etkinleştirilir`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Uygulama kullanıcılarının kimlik bilgilerini çıkarmak, kimliklerini doğrulmak ve yetki vermek için kullanılacak ayarları tanımlayın.`,
  [`See console for details.`]: `Ayrıntılar için konsola bakın.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `Seçilen Sertifika Yöneticisi hizmeti sertifika yönetmiyor.`,
  [`The selected product is not published to the Sandbox catalog`]: `Seçilen ürün, Kum Havuzu kataloğuna yayınlanmadı`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `Seçilen ürün, Kum Havuzu kataloğuna yayınlanmadığından uygulamaya abone olunamaz`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `Platformunuz için IBM Cloud CLI (önceden kurulu değilse).`,
  [`(Only supported by TLS 1.3)`]: `(Yalnızca TLS 1.3 tarafından desteklenir)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `Bu alanda "@listSize" yönergesi, liste döndürmeyen boyutlu alanları tanımlar.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `Bu alanda "@listSize" yönergesi, alanın dönüş tipinde tanımlı olmayan boyutlu alanları tanımlar.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `Bu alanda "@listSize" yönergesi, alanda tanımlı olmayan dilimleme bağımsız değişkenlerini tanımlar.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `Bu alanda "@listSize" yönergesi, tipleri "Int" ya da "Int!" olmayan dilimleme bağımsız değişkenlerini tanımlar.`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `Bu alanda "@listSize" yönergesi, boş olmayan birden çok dilimleme bağımsız değişkeni tanımlar.`,
  [`"{name}" (Role) has been created`]: `"{name}" (Rol) yaratıldı.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (Rol) güncellendi.`,
  [`API ({api}) has been created.`]: `API ({api}) yaratıldı.`,
  [`API ({api}) has been renamed.`]: `API ({api}) yeniden adlandırıldı.`,
  [`API ({api}) has been updated.`]: `API ({api}) güncellendi.`,
  [`API ({api}) has been deleted.`]: `API ({api}) silindi.`,
  [`API ({api}) has been published.`]: `API ({api}) yayınlandı.`,
  [`API ({api}) has validation error.`]: `API ({api}) doğrulama hatası içeriyor`,
  [`API ({api}) has validation errors.`]: `API ({api}) doğrulama hataları içeriyor`,
  [`API version`]: `API sürümü`,
  [`APIM data version`]: `APIM veri sürümü`,
  [`APIM schema update date`]: `APIM şema güncelleme tarihi`,
  [`APIM schema version`]: `APIM şema sürümü`,
  [`APIM target data version`]: `APIM hedef veri sürümü`,
  [`APIM target schema version`]: `APIM hedef şema sürümü`,
  [`APIM update date`]: `APIM güncelleme tarihi`,
  [`Product ({product}) has been created.`]: `Ürün ({product}) yaratıldı.`,
  [`Product ({product}) has been updated.`]: `Ürün ({product}) güncellendi.`,
  [`Product ({product}) has been delete.`]: `Ürün ({product}) silindi.`,
  [`Product ({product}) has been published.`]: `Ürün ({product}) yayınlandı.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}), {changedName} oldu.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}), {changedName} olmadı!`,
  [`({units} {timeSuffix} after being queued)`]: `(kuyruğa alındıktan {units} {timeSuffix} sonra)`,
  [`({units} {timeSuffix} after being sent)`]: `(gönderildikten {units} {timeSuffix} sonra)`,
  [`(none)`]: `(yok)`,
  [`(optional)`]: `(isteğe bağlı)`,
  [`*Base endpoint list empty`]: `*Temel uç nokta listesi boş`,
  [`*Must be a valid url`]: `*Geçerli bir url olmalıdır`,
  [`*System will also send an email notification to the requester.`]: `*Sistem, istekte bulunan kullanıcıya bir e-posta bildirimi de gönderir.`,
  [`+ redact from...`]: `+ düzeltme başlangıcı...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. Bu ürünün yaşam çevrimini katalog içinde de yönetebilirsiniz.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x ardından 64 onaltılı karakter`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) API tüketici kuruluşlarının sahibi ve yöneticisidir 2) Uygulama geliştirici topluluklarını yönetir 3) API ve ürün tanımlarını yazar 4) API ürünü yaşam çevrimini yönetir`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) API sağlayıcı kuruluşlarının sahibi ve yöneticisidir 2) Uygulama geliştirici topluluklarını yönetir 3) API ve ürün tanımlarını yazar 4) API ürünü yaşam çevrimini yönetir`,
  [`5 Most Active APIs`]: `En Etkin 5 API`,
  [`5 Most Active Products`]: `En Etkin 5 Ürün`,
  [`5 most active APIs`]: `En Etkin 5 API`,
  [`5 most active Products`]: `En Etkin 5 Ürün`,
  [`503 Service Unavailable`]: `503 Hizmet Kullanılamıyor`,
  [`A request to the server was made without any credentials.`]: `Kimlik bilgileri olmadan sunucuya bir istek yapıldı.`,
  [`A server error occurred`]: `Sunucu hatası oluştu`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Bir TLS istemci profili, diğer sistemlere istemci olarak bağlanırken API Connect tarafından kullanılan sertifika zincirini ve şifre takımını yapılandırır.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Bir TLS sunucu profili diğer sistemlere sunucu uç noktalarını sunarken API Connect tarafından kullanılan sertifika zincirini ve şifre takımını yapılandırır.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Bir katalog, yayınlandığında ilişkili geliştirici portalında görülebilen bir grup API ürününü barındırır`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Katalog, yayınlandığında ilişkili geliştirici portalında görünür olan bir grup API ürününü barındırır.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Katalog, yönetilen bir grup API ürününü temsil eder. Yayınlanan ürünler, katalogla ilişkili geliştirici portalındaki tüketiciler tarafından görülebilir.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `API Connect ve ağ geçidi yönetimi uç noktasının etki alanı arasındaki bağlantı güvenliği için bir sertifika gereklidir. Sertifikalar, ağ geçidi ve işlediği etki alanları arasındaki bağlantıların güvenliği için de gerekir. Tüm sertifikalar bir Sertifika Yöneticisi hizmetinde depolanmalıdır. Hizmet, sertifikalar için güvenli bir havuz sağlar ve sertifikaların süresi dolmak üzere olduğunda size bildirimler göndererek kesintilerin önlenmesine yardımcı olur. Başlamak için,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Eşleme yönelik girişler tanımlanmadıysa varsayılan bir değer kullanılır.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Ağ geçidi hizmeti, yayınlanan API'leri barındıran ve istemci uygulamaları tarafından kullanılan API uç noktalarını sağlayan bir dizi ağ geçidi sunucusunu ya da kapsayıcısını temsil eder. Ağ geçitleri, arka uç sistemlerine API yetkili sunucu çağrılarını yürütür ve istemci tanıtıcısı, güvenlik ve hız limitleri dahil API ilkelerini uygular.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Bir ağ geçidi anasistemi API'leri yayınlar ve istemci uygulamalar tarafından kullanılan API uç noktaları sağlar. Ağ geçitleri, arka uç sistemlerine API yetkili sunucu çağrılarını yürütür ve istemci tanıtıcısı, güvenlik ve hız limitleri dahil API ilkelerini uygular.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Abonelikler için geçiş hedefini tanımlamak için, kaynak üründe bir geçiş hedefi ayarlanabilir.  Geçiş hedefi, kaynak üründeki planların hedef üründeki planlarla eşlenmesini açıklayan bir ürün eşlemesini içerir.  Geçiş hedefi, kaynak ürünün abonelerine geçiş hedefini iletmek için geliştirici portalında görünür.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Modüler bir OAuth ilke seti. Tüm OAuth/OpenID Connect iletişim protokolü adımlarını tek bir ilkede gerçekleştirebilir (varsayılan) ya da daha ayrıntılı bir denetim için bir kerede yalnızca tek bir adım ya da "n" adet adım gerçekleştirmek üzere birden çok ilkeye bölünebilir. Adımların her birinin giriş ve çıkışları, belgelenen bağlam değişkenleri tarafından yönlendirilir. Desteklenen OAuth Bileşenlerini gereken şekilde ekleyebilir ya da kaldırabilirsiniz.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Kullanılabilecek bir portal hizmeti olmadan portal yaratılamaz. Lütfen destek için bulut yöneticinizle iletişim kurun.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Bir sağlayıcı kuruluşu, API'lere ve ilişkili planlar ve ürünlere sahip olan bir ekiptir. Sağlayıcı kuruluş, tüm izinlerin atandığı bir sahip gerektirir. Sahipler üyelerden atanır.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Bir sağlayıcı kuruluş API'leri, ürünleri, planları, katalogları, tüketici kuruluşları ve diğer ilgili kaynakları yöneten kullanıcıları içerir. Kullanıcıları ve API yönetimi kaynaklarını proje, departman, yaşam çevrimi aşaması, vb. temelinde gruplandırmak için sağlayıcı kuruluşları kullanabilirsiniz. Kullanıcı ayrıcalıklarını  yöneten rolleri yönetmek ve atamak için IBM Cloud Identity and Access Management'a gidin.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Çıkış değerine çözülen basit bir JavaScript ifadesi. Bu statik bir dizgi ("my static string") ya da eşlenen girişler ($(input.firstName) + " " + $(input.lastName)) girişlerini içeren bir ifade olabilir.`,
  [`ACTION`]: `İŞLEM`,
  [`ANALYTICS`]: `ANALİTİK`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `API Yöneticisi`,
  [`API CONNECT VALUE`]: `API CONNECT DEĞERİ`,
  [`API assemble`]: `API derlemesi`,
  [`API base endpoints`]: `API temel uç noktaları`,
  [`API base URL`]: `API temel URL'si`,
  [`API base URLs`]: `API temel URL'leri`,
  [`API Calls`]: `API Çağrıları`,
  [`API Connect UI`]: `API Connect Kullanıcı Arabirimi`,
  [`API Connect services`]: `API Connect hizmetleri`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect, üyelere davet ve etkinleştirme bağlantılarının gönderilmesi için ve diğer amaçlarla bir e-posta sunucusu gerektirir.`,
  [`API Connect verify token`]: `API Connect doğrulama belirteci`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `API Designer Lisansı`,
  [`API Designer credentials`]: `API Designer kimlik bilgileri`,
  [`API Designer licence`]: `API Designer lisansı`,
  [`API Designer does not currently support WSDL`]: `API Designer şu anda WSDL'yi desteklemiyor`,
  [`API editor`]: `API düzenleyicisi`,
  [`API Endpoint`]: `API Uç Noktası`,
  [`API Endpoint Base`]: `API Uç Noktası Temeli`,
  [`API Endpoint for Unenforced APIs`]: `Zorunlu Kılınmayan API'ler İçin API Uç Noktası`,
  [`API Endpoint for unenforced APIs`]: `Zorunlu kılınmayan API'ler için API Uç noktası`,
  [`API Endpoints`]: `API Uç Noktaları`,
  [`API Error`]: `API Hatası`,
  [`API gateway`]: `API ağ geçidi`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API ağ geçitleri`,
  [`API Gateways`]: `API Ağ Geçitleri`,
  [`API Invocation Endpoint`]: `API Çağırma Uç Noktası`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `API yayınlanmadı. Explorer'da API Test(Try it) etkinleştirilmeyecek.`,
  [`API Key`]: `API Anahtarı`,
  [`API key`]: `API anahtarı`,
  [`API key secret`]: `API anahtar güvenlik dizgisi`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `API Manager Yerel Kullanıcı Kayıt Defteri`,
  [`API Manager URL`]: `API Manager URL'si`,
  [`API Management`]: `API Yönetimi`,
  [`API Path Parameters`]: `API Yolu Parametreleri`,
  [`API Security Definition`]: `API Güvenlik Tanımı`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `Explorer'da API Test (Try it) Explorer uygulanmadığından bu API için etkinleştirilmeyecek.`,
  [`API Setup`]: `API Ayarları`,
  [`API Subscription`]: `API Aboneliği`,
  [`API endpoint`]: `API uç noktası`,
  [`API endpoint base`]: `API uç noktası temeli`,
  [`API endpoint for unenforced APIs`]: `Zorunlu kılınmayan API'ler için API uç noktası`,
  [`API endpoints`]: `API uç noktaları`,
  [`API error`]: `API hatası`,
  [`API invocation endpoint`]: `API çağırma uç noktası`,
  [`API is not enforced on the API gateway.`]: `API, API ağ geçidinde uygulanmıyor.`,
  [`API path parameters`]: `API yolu parametreleri`,
  [`API security definition`]: `API güvenlik tanımı`,
  [`API setup`]: `API kurulumu`,
  [`API subscription`]: `API aboneliği`,
  [`API Update`]: `API Güncellemesi`,
  [`API user registries`]: `API kullanıcı kayıt defterleri`,
  [`API and product generated successfully!`]: `API ve ürün başarıyla yaratıldı!`,
  [`API deletion failed`]: `API silinemedi`,
  [`API does not exist!`]: `API yok!`,
  [`API from existing REST service`]: `Var olan REST hizmetinden API`,
  [`API from existing SOAP service`]: `Var olan SOAP hizmetinden API`,
  [`API has been updated.`]: `API güncellendi.`,
  [`API has been updated`]: `API güncellendi`,
  [`API has not been created!`]: `API yaratılmadı!`,
  [`API has not been updated!`]: `API güncellenmedi!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `API önceliği (OpenAPI tanımlarında tanımlanan özel uç noktaları kullan)`,
  [`API properties`]: `API özellikleri`,
  [`API protection source`]: `API koruma kaynağı`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `Üründen başvurulan API yok. Önce API'yi yaratın ya da içe aktarın.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `API istek ve yanıt bilgi yükü yapıları, OpenAPI şema tanımları kullanılarak oluşturulur.`,
  [`API subscription request approved for app {{appName}}`]: `API abonelik isteği, {appName} uygulaması için onaylandı`,
  [`API subscription request denied for app {{appName}}`]: `API abonelik isteği, {appName} uygulaması için reddedildi`,
  [`API subscription request received for app {{appName}}`]: `API abonelik isteği, {appName} uygulaması için alındı`,
  [`API type`]: `API tipi`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `'apis' özelliği altında ''{key}'' anahtarlı API yok ve listelenemiyor. Lütfen Kaynak sekmesini kontrol edin.`,
  [`APIs`]: `API'ler`,
  [`APIs and products`]: `API'ler ve ürünler`,
  [`APIs included in the plan.`]: `Plana dahil edilen API'ler.`,
  [`API Key is created`]: `API Anahtarı oluşturuldu`,
  [`API Key is deleted`]: `API Anahtarı silindi`,
  [`API Key is successfully created`]: `API anahtarı başarıyla oluşturuldu`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `API anahtarı zaman aşımı: **{seconds} saniye ({time})**`,
  [`API key timeout`]: `API anahtarı zaman aşımı`,
  [`API Key timeout has been changed`]: `API Anahtarı zaman aşımı değiştirildi`,
  [`Application type`]: `Uygulama tipi`,
  [`Associated analytics service`]: `İlişkili analitik hizmeti`,
  [`AVAILABILITY`]: `KULLANILABİLİRLİK`,
  [`AVAILABILITY ZONE`]: `KULLANILABİLİRLİK BÖLGESİ`,
  [`About`]: `Hakkında`,
  [`About audit setting`]: `Denetim ayarlaması hakkında`,
  [`About Authentication URL user registry`]: `Kimlik Doğrulama URL'si kullanıcı kaydı hakkında`,
  [`About availability zones`]: `Kullanılabilirlik bölgeleri hakkında`,
  [`About catalogs`]: `Kataloglar hakkında`,
  [`About consumer organization owners`]: `Tüketici kuruluşu sahipleri hakkında`,
  [`About consumer organizations`]: `Tüketici kuruluşları hakkında`,
  [`About developer portals`]: `Geliştirici portalları hakkında`,
  [`About email servers`]: `E-posta sunucuları hakkında`,
  [`About Endpoints`]: `Uç noktalar hakkında`,
  [`About gateways`]: `Ağ geçitleri hakkında`,
  [`About gateway visibility`]: `Ağ geçidi görünürlüğü hakkında`,
  [`About Gateway Processing Status`]: `Ağ Geçidi İşleme Durumu Hakkında`,
  [`About introspection`]: `İç gözlem hakkında`,
  [`About Keystores`]: `Anahtar Depoları Hakkında`,
  [`About LDAP`]: `LDAP hakkında`,
  [`About local user registries`]: `Yerel kullanıcı kayıt defterleri hakkında`,
  [`About metadata`]: `Meta veriler hakkında`,
  [`About Migration Target`]: `Geçiş Hedefi Hakkında`,
  [`About migration targets`]: `Geçiş hedefleri hakkında`,
  [`About native OAuth provider`]: `Yerel OAuth sağlayıcısı hakkında`,
  [`About OpenID Connect`]: `OpenID Connect hakkında`,
  [`About replacing a product`]: `Ürünü başkasıyla değiştirme hakkında`,
  [`About Role defaults`]: `Rol varsayılanları hakkında`,
  [`About roles`]: `Roller hakkında`,
  [`About scopes`]: `Kapsamlar hakkında`,
  [`About Set Migration Targets`]: `Geçiş Hedeflerini Ayarlama hakkında`,
  [`About spaces`]: `Alanlar hakkında`,
  [`About Subscription`]: `Abonelik Hakkında`,
  [`About superseding a product`]: `Ürünün yerine başkasını geçirme hakkında`,
  [`About TLS server profile`]: `TLS sunucu profili hakkında`,
  [`About TLS client profiles`]: `TLS istemci profilleri hakkında`,
  [`About TLS server profiles`]: `TLS sunucu profilleri hakkında`,
  [`About third party OAuth provider`]: `Üçüncü kişi OAuth sağlayıcısı hakkında`,
  [`About tokens`]: `Belirteçler hakkında`,
  [`About Transfer Ownership`]: `Sahiplik Aktarımı Hakkında`,
  [`About Transferring Ownership`]: `Sahipliği Aktarma Hakkında`,
  [`About Truststores`]: `Güvenli Depolar Hakkında`,
  [`About adding a member`]: `Üye ekleme hakkında`,
  [`About adding provider organization member`]: `Sağlayıcı kuruluş üyesi ekleme hakkında`,
  [`About adding members to a Space`]: `Alana üye ekleme hakkında`,
  [`About adding members to a catalog`]: `Kataloğa üye ekleme hakkında`,
  [`About changing owners`]: `Sahip değiştirme hakkında`,
  [`About enabling gateway services`]: `Ağ geçidi hizmetlerinin etkinleştirilmesi hakkında`,
  [`About inviting a member`]: `Üyeleri davet etme hakkında`,
  [`About inviting catalog members`]: `Katalog üyelerini davet etme hakkında`,
  [`About inviting provider organization members`]: `Sağlayıcı kuruluş üyelerini davet etme hakkında`,
  [`About inviting space members`]: `Alan üyelerini davet etme hakkında`,
  [`About keystores`]: `Anahtar depoları hakkında`,
  [`About managing Space membership`]: `Alan üyeliği yönetimi hakkında`,
  [`About migrating subscriptions`]: `Abonelikleri geçirme hakkında`,
  [`About provider organizations`]: `Sağlayıcı kuruluşlar hakkında`,
  [`About roles and role defaults`]: `Roller ve rol varsayılanları hakkında`,
  [`About roles in spaces`]: `Alanlardaki roller hakkında`,
  [`About selecting gateway services`]: `Ağ geçidi hizmetlerini seçme hakkında`,
  [`About send message`]: `İleti gönderme hakkında`,
  [`About sending messages`]: `İleti gönderme hakkında`,
  [`About the analytics service`]: `Analitik hizmeti hakkında`,
  [`About the DNS Scheme`]: `DNS Şeması hakkında`,
  [`About the Gateway Service`]: `Ağ Geçidi Hizmeti hakkında`,
  [`About the Portal Service`]: `Portal Hizmeti hakkında`,
  [`About the product lifecycle`]: `Ürün yaşam çevrimi hakkında`,
  [`About the provider organization owner`]: `Sağlayıcı kuruluş sahibi hakkında`,
  [`About this Cloud`]: `Bu Bulut Hakkında`,
  [`About transferring ownership`]: `Sahipliği aktarma hakkında`,
  [`About truststores`]: `Güvenli depolar hakkında`,
  [`About visibility`]: `Görünürlük hakkında`,
  [`About visibility and subscribability`]: `Görünürlük ve abone olunabilirlik hakkında`,
  [`Accept`]: `Kabul Et`,
  [`Access Code`]: `Erişim Kodu`,
  [`Access Token`]: `Erişim Belirteci`,
  [`Access URL through Secure Gateway`]: `URL'ye Güvenli Ağ Geçidi aracılığıyla erişin`,
  [`Access code`]: `Erişim kodu`,
  [`Access code is not chosen in supported grant type.`]: `Desteklenen verme tipinde erişim kodu seçilmedi.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `URL'ye Güvenli Ağ Geçidi aracılığıyla erişin. API, hazırlandı durumundan yayınlandı durumuna geçirildiğinde ağ geçidi ayarlanır.`,
  [`Access token TTL has been changed`]: `Erişim belirteci TTL değeri değiştirildi`,
  [`Access token time to live`]: `Erişim belirteci süresi`,
  [`Access token time-to-live`]: `Erişim belirteci süresi`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Erişim belirtecinin süresi **{seconds} saniye ({time})** içinde dolacak`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Erişim belirteçleri, uygulama kullanıcısı adına uygulamanın kaynaklara erişmesine izin vermek için istemci uygulamasına verilir.  Geçerli erişim belirteci geçersiz olduğunda veya süresi dolduğunda yeni bir erişim belirteci almak ya da aynı veya daha dar kapsamlı ek erişim belirteçleri almak için istemciye yenileme belirteçleri verilir. Herhangi bir sayıda erişim ve yenileme belirteci birleşimiyle verilen iznin geçerlilik süresini de belirtebilirsiniz.`,
  [`Access tokens time to live (seconds)`]: `Erişim belirteçlerinin süresi (saniye)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Erişim belirteci, tanıtıcı belirteci ve Geçici belirteç var olan Anahtar depoların birine atanabilir. Anahtar deposu atama geçmişi korunur.`,
  [`Account`]: `Hesap`,
  [`Account has been updated.`]: `Hesap güncellendi.`,
  [`Account password has been changed.`]: `Hesap parolası değişti.`,
  [`account-approved`]: `hesap-onaylandı`,
  [`account-denied`]: `hesap-reddedildi`,
  [`account-pending-approval`]: `hesap-onay-bekliyor`,
  [`Action`]: `İşlem`,
  [`Activate API`]: `API'yi Etkinleştir`,
  [`Activate API|button text`]: `API'yi Etkinleştir`,
  [`Activate API for testing|title`]: `API'yi Etkinleştir`,
  [`Activation link`]: `Etkinleştirme bağlantısı`,
  [`Active`]: `Etkin`,
  [`Activity log`]: `Etkinlik günlüğü`,
  [`Activity log API setting is not available for this API's gateway type.`]: `Etkinlik günlüğü API ayarı, bu API ağ geçidi tipi için kullanılamıyor.`,
  [`Active loading indicator`]: `Etkin yükleme göstergesi`,
  [`Add`]: `Ekle`,
  [`Add allowlist`]: `İzin listesi ekle`,
  [`Add a new billing integration in Resources`]: `Kaynaklarda yeni bir faturalama bütünleştirmesi ekle`,
  [`Add API user registries`]: `API kullanıcı kayıt defteri ekle`,
  [`Add APIs to Product`]: `Ürüne API Ekle`,
  [`Add another cloud`]: `Başka bir bulut ekle`,
  [`Add billing integration`]: `Faturalama bütünleştirmesi ekle`,
  [`Add Burst Limit`]: `Ayırma Sınırı Ekle`,
  [`Add Ciphers for TLS client profile`]: `TLS istemci profili için Şifre ekle`,
  [`Add Ciphers for TLS server profile`]: `TLS sunucu profili için şifre ekle`,
  [`Add Condition`]: `Koşul Ekle`,
  [`Add Consumer Role`]: `Tüketici Rolü Ekle`,
  [`Add Count Limit`]: `Sayı Sınırı Ekle`,
  [`Add Credential`]: `Kimlik Bilgisi Ekle`,
  [`Add domain`]: `Etki alanı ekle`,
  [`Add credential`]: `Kimlik bilgisi ekle`,
  [`Add Extension`]: `Uzantı ekle`,
  [`Add GraphQL schema`]: `GraphQL şeması ekle`,
  [`Add HTTP Endpoint`]: `HTTP Uç Noktası Ekle`,
  [`Add group`]: `Grup ekle`,
  [`Add JSON schema`]: `JSON Şeması Ekle`,
  [`Add Keystore to TLS client profile`]: `TLS istemci profiline Anahtar Deposu ekle`,
  [`Add Keystore to TLS server profile`]: `TLS sunucu profiline Anahtar Deposu ekle`,
  [`Add member`]: `Üye ekle`,
  [`Add New Role for Consumer organization`]: `Tüketici kuruluşu için Yeni Rol ekle`,
  [`Add operation`]: `İşlem ekle`,
  [`Add Parameters`]: `Parametre Ekle`,
  [`Add Policy`]: `İlke Ekle`,
  [`Add Rate limit`]: `Hız Sınırı ekle`,
  [`Add Rate Limit`]: `Hız Sınırı Ekle`,
  [`Add role`]: `Rol ekle`,
  [`Add Syslog TCP Endpoint`]: `Syslog TCP Uç Noktası Ekle`,
  [`Add Syslog TLS Endpoint`]: `Syslog TLS Uç Noktası Ekle`,
  [`Add Syslog UDP Endpoint`]: `Syslog UDP Uç Noktası Ekle`,
  [`Add XML schema`]: `XML şeması ekle`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Bu Katalogda API çağrılarının kullanımına sunmak istediğiniz bir Ağ Geçidi uç noktası ekleyin.`,
  [`Add a different Cloud Connection.`]: `Farklı bir Bulut Bağlantısı ekleyin.`,
  [`Add a member from the user registry`]: `Kullanıcı kayıt defterinden üye ekle`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Admin kuruluşuna bir kullanıcı ekleyin ve gerekli rolleri atayın`,
  [`Add a user to the provider organization, and assign the required roles`]: `Sağlayıcı kuruluşuna kullanıcı ekleyin ve gerekli rolleri atayın`,
  [`Add a user to the Catalog, and assign the required roles`]: `Kataloğa bir kullanıcı ekleyin ve gerekli rolleri atayın`,
  [`Add a user to the Space, and assign the required roles`]: `Alana bir kullanıcı ekleyin ve gerekli rolleri atayın`,
  [`Add action`]: `İşlem ekle`,
  [`Add additional client ID/client secret pairs`]: `Ek istemci tanıtıcısı/istemci güvenlik dizgisi çiftleri ekle`,
  [`Add blocklist`]: `Engelleme listesi ekle`,
  [`Add case`]: `Durum ekle`,
  [`Add catch`]: `Yakalama ekle`,
  [`Add default catch`]: `Varsayılan yakalama ekle`,
  [`Add destination`]: `Hedef ekle`,
  [`Add entry`]: `Girdi ekle`,
  [`Add group failed.`]: `Grup eklenemedi.`,
  [`Add input`]: `Giriş ekle`,
  [`Add media type`]: `Ortam tipi ekle`,
  [`Add member failed`]: `Üye eklenemedi`,
  [`Add object`]: `Nesne ekle`,
  [`Add otherwise`]: `Tersi durumda ekle`,
  [`Add output`]: `Çıkış ekle`,
  [`Add outputs for operation...`]: `İşlem için çıkış ekleyin...`,
  [`Add parameters for operation...`]: `İşlem için parametre ekleyin...`,
  [`Add parameters to this API`]: `Bu API'ye parametre ekle`,
  [`Add plans to product`]: `Ürüne plan ekle`,
  [`Add plans to this product`]: `Bu ürüne plan ekleyin`,
  [`Add schema`]: `Şema ekle`,
  [`Add scopes for this OAuth provider.`]: `Bu OAuth sağlayıcısı için kapsamlar ekleyin.`,
  [`Add scopes to allow access to`]: `Şu erişime izin vermek için kapsamları ekleyin:`,
  [`Add scopes to allow access to.`]: `Erişime izin vermek için kapsamlar ekleyin.`,
  [`Add tags and external documentation details for this API`]: `Bu API için etiket ve dış belge ayrıntıları ekle`,
  [`Add to`]: `Ekle:`,
  [`Add to existing product`]: `Var olan ürüne ekle`,
  [`Add/Remove APIs`]: `API'leri Ekle/Kaldır`,
  [`AddProperties`]: `AddProperties`,
  [`Added APIs`]: `Eklenen API'ler`,
  [`Added rate limit`]: `Eklenen hız sınırı`,
  [`Added rate limits`]: `Eklenen hız sınırları`,
  [`Adding an API to a product for publishing`]: `Yayınlamak için bir ürüne API ekleme`,
  [`Additional support`]: `Ek destek`,
  [`Additional properties`]: `Ek özellikler`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Bunun yanı sıra, kullanılmıyorsa ilişkili kayıt defterini ({name} Katalog Kullanıcı Kayıt Defteri) de kaldırmayı düşünebilirsiniz.`,
  [`Address`]: `Adres`,
  [`Admin DN`]: `Sistem Yöneticisi Ayırt Edici Adı`,
  [`Admin organization invitation timeout`]: `Yönetici kuruluş daveti zaman aşımı`,
  [`Admin organization invitation timeout has been changed`]: `Yönetici kuruluş daveti zaman aşımı değiştirildi`,
  [`Admin password`]: `Yönetici parolası`,
  [`Admin request reset password`]: `Yönetici parolası sıfırlama isteği`,
  [`Admin reset password`]: `Yönetici parolası sıfırlama`,
  [`Admin Sign In`]: `Sistem Yöneticisi Oturum Açma İşlemi`,
  [`Admin add catalog failed`]: `Yönetici katalog ekleme işlemi başarısız oldu`,
  [`Admin add space failed`]: `Yönetici katalog ekleme işlemi başarısız oldu`,
  [`Administer consumer organizations`]: `Tüketici kuruluşlarını yönet`,
  [`Administers the API consumer organization`]: `API tüketici kuruluşunu yönetir`,
  [`Administers the API provider organization`]: `API sağlayıcı kuruluşunu yönetir`,
  [`Administers the admin organization`]: `Yönetici kuruluşu yönetir`,
  [`Administers the admin topology`]: `Yönetici topolojisini yönetir`,
  [`Administers the app developer organization`]: `Uygulama geliştirici kuruluşunu yönetir`,
  [`Administers the catalog`]: `Kataloğu yönetir`,
  [`Administers the cloud topology`]: `Bulut topolojisini yönetir`,
  [`Administers the space`]: `Alanı yönetir`,
  [`Administration management console`]: `Yönetim yönetimi konsolu`,
  [`Administrator`]: `Sistem yöneticisi`,
  [`Advanced analytics configuration`]: `Gelişmiş analitik yapılandırması`,
  [`Advanced features`]: `Gelişmiş özellikler`,
  [`Advanced scope check`]: `Gelişmiş kapsam denetimi`,
  [`Advanced scope check after Token Validation`]: `Belirteç Doğrulamadan sonra gelişmiş kapsam denetimi`,
  [`Advanced scope check before Token Generation`]: `Belirteç Oluşturmadan önce gelişmiş kapsam denetimi`,
  [`Aggregation`]: `Toplama`,
  [`Agree`]: `Kabul`,
  [`All`]: `Tümü`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Geliştirici portalına kaydolan, tüketici kuruluşlarındaki kimliği doğrulanmış tüm geliştiriciler bu ürünü görebilir.`,
  [`All consumer organizations will be able to see this product.`]: `Tüm tüketici kuruluşları bu ürünü görebilir.`,
  [`Allowlist`]: `İzin listesi`,
  [`Allow Chunked Uploads`]: `Parçalı Karşıya Yüklemelere İzin Ver`,
  [`Allow chunked uploads`]: `Küçük parçalı karşıya yüklemelere izin ver`,
  [`Allow "plain" challenge method`]: `"Düz" soru-yanıt yöntemine izin ver`,
  [`Allow renegotiation`]: `Yeniden anlaşmaya izin ver`,
  [`Allow case sensitive usernames`]: `Büyük ve küçük harfe duyarlı kullanıcı adlarına izin ver`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `İstemcilerin, sunucu ile ilk anlaşma sırasında istenen anasistem adıyla bir SNI uzantısı eklemesine izin verin.`,
  [`Allow connection to be renegotiated`]: `Bağlantının yeniden uzlaştırılmasına izin ver`,
  [`Allow default introspection`]: `Varsayılan iç gözleme izin ver`,
  [`Allow insecure server connections`]: `Güvenli olmayan sunucu bağlantılarına izin ver`,
  [`Allow null value`]: `Boş değere izin ver`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `access_token/refresh_token belirtecinin oturum kapatma için 302 yeniden yönlendirmesini göndermesine izin ver`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Tüm öneriler uygulandı. Şemanız için uyarı yok.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `API işlemlerinin Developer Portal'daki test araçları kullanılarak test edilmesine izin verin.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Bağlantının zayıf ya da güvenli olmayan kimlik bilgileri ile devam etmesine izin ver`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Zayıf ya da güvenli olmayan kimlik bilgileriyle devam etmek için bağlantıya izin verin.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Dış OIDC sağlayıcısının API Manager ile değil Developer Portal ile iletişim kurmasına izin verin.`,
  [`Allow to manage this user registry`]: `Bu kullanıcı kayıt defterinin yönetilmesine izin ver`,
  [`Allow to manage users under this user registry`]: `Bu kullanıcı kayıt defteri altındaki kullanıcıların yönetilmesine izin ver`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `APIC bir yetki veren tarafından yayınlanan bir belirteç ile sunulduğunda, kullanıcıların otomatik katılımına izin verin`,
  [`Already have an account?`]: `Zaten bir hesabınız var mı?`,
  [`Also transfer ownership of owned Spaces`]: `Sahip olunan alanların sahipliğini de aktar`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Etkin olduğunda, kullanıcı verilerini her zaman kullanıcı bilgileri uç noktasından getirin`,
  [`Always output the root element`]: `Her zaman kök öğenin çıkışını yap`,
  [`Always output the root element.`]: `Her zaman kök öğenin çıkışını yapın.`,
  [`Always required`]: `Her zaman zorunludur`,
  [`Always use userinfo endpoint`]: `Her zaman kullanıcı bilgileri uç noktasını kullan`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Bir OAuth sağlayıcısı API'si, bir OAuth akışının yetkilendirme ve belirteç uç noktalarını içerir. OAuth sağlayıcınızı burada yapılandırın; daha sonra bir OAuth güvenli API'si oluşturduğunuzda gerekli sağlayıcıyı seçebilirsiniz.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Bir OAuth sağlayıcısı OAuth akışının yetkilendirme ve belirteç uç noktalarını içerir; OAuth ile API'nizin güvenliği için kullanmak istediğiniz OAuth sağlayıcısını seçin`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `İlişkili API'leri çağırabilmek için geliştirici alandaki bir plana bir uygulamayı dahil ettiğinde bu uygulama burada listelenir; API'lerinize erişmesini engellemek için bir geliştirici uygulamasını askıya alabileceğiniz gibi kendi uygulamalarınızı da oluşturabilirsiniz. [Ek bilgi]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Kimlik doğrulama URL'si, kullanıcı kayıt defteri olarak üçüncü taraf kimlik doğrulama sağlayıcısını işaret eder. Kimlik Doğrulama URL'si, LDAP dışında üçüncü taraf kullanıcı kayıt defteriyle bütünleştirmeyi etkinleştirir.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Kimlik doğrulama URL'si, özel bir kimlik sağlayıcıya karşı kullanıcı kimlik doğrulaması için basit bir mekanizma sağlar.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Ağ geçitleri alt sistemiyle iletişim kurulurken bir hata oluştu.`,
  [`An organization is required.`]: `Bir kuruluş gereklidir.`,
  [`An unknown error occurred.`]: `Bilinmeyen bir hata oluştu.`,
  [`Analytics`]: `Analitik`,
  [`Analytics Insights`]: `Analitik Öngörüler`,
  [`Analytics Insights Service'`]: `Analitik Öngörüler Hizmeti'`,
  [`Analytics Details`]: `Analitik Ayrıntıları`,
  [`Analytics destinations`]: `Analitik hedefleri`,
  [`Analytics Director keystore`]: `Analytics Director anahtar deposu`,
  [`Analytics Service`]: `Analitik Hizmeti`,
  [`Analytics Service URL`]: `Analitik Hizmeti URL'si`,
  [`Analytics Service {arg} has been removed.`]: `Analitik Hizmeti {arg} kaldırıldı.`,
  [`Analytics details`]: `Analitik ayrıntıları`,
  [`Analytics service`]: `Analitik hizmeti`,
  [`Analytics service details`]: `Analitik hizmeti ayrıntıları`,
  [`Analytics service URL`]: `Analitik hizmeti URL'si`,
  [`Analytics service {arg} has been removed.`]: `Analitik hizmeti {arg} kaldırıldı.`,
  [`Analytics client TLS client profile`]: `Analitik istemcisi TLS istemci profili`,
  [`Analytics client keystore`]: `Analitik istemcisi anahtar deposu`,
  [`Analytics client truststore`]: `Analitik istemcisi güven deposu`,
  [`Analytics ingestion TLS client profile`]: `Analitik alımı TLS istemci profili`,
  [`Analytics ingestion keystore`]: `Analitik alımı anahtar deposu`,
  [`Analytics ingestion truststore`]: `Analitik alımı güven deposu`,
  [`Analytics service {title} has been created.`]: `{title} başlıklı Analitik hizmeti oluşturuldu.`,
  [`Analytics service {title} has been updated.`]: `{title} başlıklı Analitik hizmeti güncellendi.`,
  [`The selected consumer organization is`]: `Seçilen tüketici kuruluşu:`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Analitik hizmetleri yapılandırıldı ve analitik verileri yükü dışarıya aktarıldı`,
  [`Analyze API usage and performance`]: `API kullanımı ve performansını analiz et`,
  [`Anonymous bind`]: `Anonim bağlama`,
  [`Another user registry`]: `Başka bir kullanıcı kayıt defteri`,
  [`api-administrator`]: `api-administrator`,
  [`Api-Analytics`]: `Api-Analitik`,
  [`Api-Drafts`]: `Api-Taslaklar`,
  [`App`]: `Uygulama`,
  [`App name`]: `Uygulama adı`,
  [`App-Analytics`]: `Uygulama-Analitik`,
  [`App-Approval`]: `Uygulama-Onay`,
  [`App-Dev`]: `Uygulama-Geliştirme`,
  [`App-analytics`]: `Uygulama-analitik`,
  [`App-dev`]: `Uygulama-geliştirme`,
  [`app-lifecycle-approved`]: `uygulama-yaşam çevrimi-onaylandı`,
  [`app-lifecycle-cancelled`]: `uygulama-yaşam çevrimi-iptal edildi`,
  [`app-lifecycle-denied`]: `uygulama-yaşam çevrimi-reddedildi`,
  [`app-lifecycle-pending`]: `uygulama-yaşam çevrimi-bekleniyor`,
  [`app-lifecycle-request`]: `uygulama-yaşam çevrimi-isteği`,
  [`app-reinstated`]: `uygulama-yeniden başlatıldı`,
  [`app-suspended`]: `uygulama-askıya alındı`,
  [`Application`]: `Uygulama`,
  [`Application Authentication`]: `Uygulama Kimlik Doğrulaması`,
  [`Application Authentication Source`]: `Uygulama Kimlik Doğrulama Kaynağı`,
  [`Application lifecycle`]: `Uygulama yaşam çevrimi`,
  [`Application approval task for upgrading application`]: `Uygulamayı üretime yükseltmek için`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `{username} ({orgname}) tarafından, {taskname} uygulamasını üretime yükseltmek için uygulama onayı görevi istendi.`,
  [`Application authentication`]: `Uygulama kimlik doğrulaması`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Uygulama geliştiricileri, geliştirici portalı kullanarak başlangıçta uygulamalarını bir ya da daha çok Plana abone yapar. Ancak seçilen bir plan için uygulama aboneliklerini başka bir üründeki bir plana geçirebilirsiniz.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Uygulama geliştiricileri, geliştirici portalını kullanarak başlangıçta uygulamalarını bir ya da daha çok plana abone yapar. Ancak seçilen bir plan için uygulama aboneliklerini başka bir üründeki bir plana geçirebilirsiniz.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Uygulama geliştiricileri plandaki API'leri çağırabilmek için bir uygulamayı bir plana abone ederler.`,
  [`Application is being created. Please Wait`]: `Uygulama oluşturuluyor. Lütfen Bekleyin`,
  [`Application scope check`]: `Uygulama kapsamı denetimi`,
  [`Applications`]: `Uygulamalar`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Uygulama yaşam çevrimi açılmadan önce var olan uygulamalar üretim durumunda kalır`,
  [`Applied security`]: `Uygulanan güvenlik`,
  [`Apply`]: `Uygula`,
  [`Apply all`]: `Tümünü uygula`,
  [`Apply all suggestions`]: `Tüm önerileri uygula`,
  [`Apply analysis configuration`]: `Analiz yapılandırmasını uygula`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `İşleminizin içinden var olan bir hizmeti çağırmak için çağrı ilkesini uygulayın. İlke, JSON ya da XML verileriyle kullanılabilir ve derlemeniz içinde birden çok defa uygulanabilir.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `İşleminiz içinden var olan bir hizmeti çağırmak için websocket bağlantısı oluşturmak üzere websocket yükseltme ilkesini uygulayın.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Özellikle de büyük bir bilgi yükü çağırmanız gerekirse işleminiz içinde bir yetkili sunucu API'si çağırmak için bu ilkeyi uygulayın. Derleme başına yalnızca bir yetkili sunucu ilkesi çağrılmasına izin verilir.`,
  [`Apply to selected`]: `Seçilene uygula`,
  [`Apply type analysis configuration`]: `Tip analizi yapılandırmasını uygula`,
  [`Approval History`]: `Onay Geçmişi`,
  [`Approval tasks`]: `Onay görevleri`,
  [`Approvals`]: `Onaylar`,
  [`Approve`]: `Onayla`,
  [`Archive`]: `Arşivle`,
  [`Archive Product`]: `Ürünü Arşivle`,
  [`Archive|permission`]: `Arşivle`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Seçilen öğelerinizdeki kısıtları temizlemek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete API?`]: `API'yi silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete Product?`]: `Ürünü silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete a catalog?`]: `Bir kataloğu silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete a space?`]: `Bir alanı silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete this application?`]: `Bu uygulamayı silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete this billing integration?`]: `Bu faturalama bütünleştirmesini silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete this field?`]: `Bu alanı silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete this policy?`]: `Bu ilkeyi silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete this type?`]: `Bu tipi silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to disable spaces?`]: `Alanları devre dışı bırakmak istediğinizden emin misiniz?`,
  [`Are you sure you want to disable custom email sender information?`]: `Özel e-posta gönderen bilgilerini devre dışı bırakmak istediğinizden emin misiniz?`,
  [`Are you sure you want to disable custom notification templates?`]: `Özel bildirim şablonlarını devre dışı bırakmak istediğinizden emin misiniz?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `IBM Developer Portal'ı kullanmaya devam etmek istemediğinizden emin misiniz? Kataloğa ilişkin tüm portal özelleştirmeleri silinecek.`,
  [`Are you sure you want to enable spaces?`]: `Alanları etkinleştirmek istediğinizden emin misiniz?`,
  [`Are you sure you want to enable custom email sender information?`]: `Özel e-posta gönderen bilgilerini etkinleştirmek istediğinizden emin misiniz?`,
  [`Are you sure you want to enable custom notification templates?`]: `Özel bildirim şablonlarını etkinleştirmek istediğinizden emin misiniz?`,
  [`Are you sure you want to proceed with delete?`]: `Silme işlemine devam etmek istediğinizden emin misiniz?`,
  [`Are you sure you want to publish?`]: `Yayınlamak istediğinizden emin misiniz?`,
  [`Are you sure you want to re-stage?`]: `Yeniden hazırlamak istediğinizden emin misiniz?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Ürünü katalogdan kaldırmak istediğinizden emin misiniz?`,
  [`Are you sure you want to unassociate?`]: `İlişkilendirmeyi kaldırmak istediğinizden emin misiniz?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Self Servis Katılımı güncellemek istediğinizden emin misiniz?`,
  [`Are you sure?`]: `Emin misiniz?`,
  [`Argument`]: `Bağımsız Değişken`,
  [`Array`]: `Dizi`,
  [`Assemble`]: `Derle`,
  [`Assemble the policy flow required for the OAuth provider.`]: `OAuth sağlayıcısı için gerekli ilke akışını derleyin.`,
  [`Assembly`]: `Derleme`,
  [`Assembly Execute`]: `Derleme Yürütme`,
  [`Assembly saved`]: `Derleme kaydedildi`,
  [`Assembly burst limits`]: `Derleme veri bloğu sınırları`,
  [`Assembly count limits`]: `Derleme sayı sınırları`,
  [`Assign roles`]: `Rol ata`,
  [`Assign roles to the current owner`]: `Rolleri geçerli sahibe ata`,
  [`Associate`]: `İlişkilendir`,
  [`Associate analytics service`]: `Analitik hizmetini ilişkilendir`,
  [`Associate analytics`]: `Analitiği ilişkilendir`,
  [`Assumed size`]: `Varsayılan boyut`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `{url} URL'sinden API almaya çalışan kimlik doğrulama hatası. Kullanıcı adı ve parolayı denetle.`,
  [`Audience claim`]: `Hedef kitle talebi`,
  [`Audience Claim`]: `Hedef Kitle Talebi`,
  [`Audit setting`]: `Denetim ayarı`,
  [`Audit setting has been changed`]: `Denetim ayarı değişti`,
  [`Auditing is **{mode}**`]: `Denetim: **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `Denetim API çağrılarını izlemek ve çağıran kullanıcılar, her çağrının zamanını ve her olaya dahil olan etkinlik hakkında bilgileri günlüğe kaydetmek için kullanılır.`,
  [`Authenticate Client Method`]: `İstemci Kimlik Doğrulama Yöntemi`,
  [`Authenticate password`]: `Parolayı doğrula`,
  [`Authenticate user`]: `Kullanıcının kimliğini doğrula`,
  [`Authenticate user settings`]: `Kullanıcı ayarlarını doğrula`,
  [`Authenticate application users using`]: `Şunu kullanarak uygulama kullanıcılarının kimliğini doğrula`,
  [`Authenticate using Basic Authentication`]: `Temel Kimlik Doğrulama kullanarak kimlik doğrula`,
  [`Authenticate using OAuth`]: `OAuth kullanarak kimliği doğrula`,
  [`Authenticated`]: `Kimliği Doğrulanmış`,
  [`Authenticated bind`]: `Doğrulanmış bağlama`,
  [`Authenticated User Name`]: `Kimliği Doğrulanmış Kullanıcı Adı`,
  [`Authentication`]: `Kimlik Doğrulama`,
  [`Authentication Bind`]: `Kimlik Doğrulaması Bağ Tanımı`,
  [`Authentication error`]: `Kimlik doğrulama hatası`,
  [`Authentication method`]: `Kimlik doğrulama yöntemi`,
  [`Authentication TLS profile`]: `Kimlik doğrulama TLS profili`,
  [`Authentication URL`]: `Kimlik doğrulama URL'si`,
  [`Authentication URL user registry`]: `Kimlik Doğrulama URL'si kullanıcı kaydı`,
  [`Authentication URL user registry has been created.`]: `Kimlik Doğrulama URL'si kullanıcı kayıt defteri oluşturuldu.`,
  [`Authentication URL to use for validation.`]: `Doğrulama için kullanılacak kimlik doğrulama URL'si.`,
  [`Authentication response header credential`]: `Kimlik doğrulama yanıtı üstbilgi kimlik bilgileri`,
  [`Authentication response header pattern`]: `Kimlik doğrulama yanıtı üstbilgi kalıbı`,
  [`Authentication type`]: `Kimlik denetimi tipi`,
  [`Authentication type to use to validate the UsernameToken.`]: `UsernameToken değerini doğrulamak için kullanılacak kimlik doğrulama tipi.`,
  [`Authorization`]: `Yetkilendirme`,
  [`Authorization Code`]: `Yetkilendirme Kodu`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Yetkilendirme URL'si, OAuth sağlayıcısı "{providerTitle}" tarafından tanımlandığı şekilde, {endpoint} ile eşleşmelidir`,
  [`Authorization endpoint`]: `Yetkilendirme uç noktası`,
  [`Authorization Request`]: `Yetkilendirme İsteği`,
  [`Authorization URL`]: `Yetkilendirme URL'si`,
  [`Authorize`]: `Yetkilendir`,
  [`Authorize User Settings`]: `Kullanıcı Ayarlarını Yetkilendir`,
  [`Authorize application users using`]: `Uygulama kullanıcılarını şunu kullanarak yetkilendir:`,
  [`Authorize path`]: `Yolu yetkilendir`,
  [`Authors API and product definitions`]: `API ve ürün tanımlarını yazar`,
  [`Auto Generate OIDC API Assembly`]: `Otomatik Olarak OIDC API Derlemesi Oluştur`,
  [`Auto onboard`]: `Otomatik katılım`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `API Connect yönetim API'leri ve komutlarında kullanılmak üzere otomatik oluşturulan ad.`,
  [`Automation`]: `Otomasyon`,
  [`Automation Management Console`]: `Otomasyon Yönetimi Konsolu`,
  [`Automation management console`]: `Otomasyon yönetim konsolu`,
  [`Availability Zone`]: `Kullanılabilirlik Bölgesi`,
  [`Availability zone`]: `Kullanılabilirlik bölgesi`,
  [`Availability zone {arg} has been removed.`]: `Kullanılabilirlik bölgesi {arg} kaldırıldı.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Kullanılabilirlik bölgeleri, iş ihtiyaçlarınıza uyacak şekilde API Connect hizmeti gruplandırmanızı sağlar. Örneğin, ağ geçidi hizmetlerini, bulundukları bölge ya da veri merkezine göre gruplayabilirsiniz.`,
  [`Average response time: {{value}}ms`]: `Ortalama yanıt süresi: {{value}}ms`,
  [`Average time`]: `Ortalama süre`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Yerel yetkili sunucu olarak API Designer sunucusunu kullanarak CORS hatalarını önleyin.`,
  [`Back`]: `Geri`,
  [`Back to sign in`]: `Oturum açmaya geri dön`,
  [`Back to test`]: `Teste geri dön`,
  [`Backend type`]: `Arka uç tipi`,
  [`Bad gateway`]: `Hatalı ağ geçidi`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Temel DN`,
  [`Base Path`]: `Temel Yol`,
  [`Base path`]: `Temel yol`,
  [`Base endpoint`]: `Temel uç nokta`,
  [`Base endpoints`]: `Temel uç noktaları`,
  [`Base64 encoding`]: `Base64 kodlaması`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `Davetler ve parola sıfırlama belirteçleri için Base64 kodlaması **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Geçici belirteç için Base64 kodlama ayarı değiştirildi`,
  [`Base URL of API invocation endpoint`]: `API çağırma uç noktasının temel URL'si`,
  [`Basepath root`]: `Temel yol kökü`,
  [`Basic`]: `Temel`,
  [`Basic authentication`]: `Temel kimlik doğrulama`,
  [`Basic authentication password`]: `Temel kimlik doğrulama parolası`,
  [`Basic authentication request header name`]: `Temel kimlik doğrulama isteği üstbilgi adı`,
  [`Basic authentication username`]: `Temel kimlik doğrulama kullanıcı adı`,
  [`Before you begin`]: `Başlamadan önce`,
  [`Before you begin...`]: `Başlamadan önce...`,
  [`Between 0 and 10 events are waiting to be processed`]: `0 ile 10 arasında olay işlenmeyi bekliyor`,
  [`Between 10 and 20 events are waiting to be processed`]: `10 ve 20 arasında olay işlenmeyi bekliyor`,
  [`Billing`]: `Faturalama`,
  [`Billing and Payment`]: `Faturalama ve Ödeme`,
  [`Billing has been updated`]: `Faturalama güncellendi`,
  [`Billing integrations`]: `Faturalandırma bütünleştirmeleri`,
  [`Billing integration`]: `Faturalandırma bütünleştirmesi`,
  [`Billing Integration`]: `Faturalama Bütünleştirmesi`,
  [`Billing {title} successfully added.`]: `Faturalama {title} başarıyla eklendi.`,
  [`Billing {title} successfully deleted.`]: `Faturalama {title} başarıyla silindi.`,
  [`Billing {title} successfully updated.`]: `Faturalama {title} başarıyla güncellendi.`,
  [`Billing Options`]: `Faturalandırma Seçenekleri`,
  [`Blocklist`]: `Engelleme listesi`,
  [`Blocking`]: `Engelleyici`,
  [`Blocking option`]: `Engelleme seçeneği`,
  [`Body`]: `Gövde`,
  [`Boolean`]: `Boole`,
  [`Both`]: `Her ikisi`,
  [`Browse`]: `Göz at`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `DataPower API Gateway'de işlenmeden önce API isteklerini ve yanıtlarını ara belleğe alın.`,
  [`Buffering`]: `Arabelleğe Alma`,
  [`Builds and manages apps in the developer organization`]: `Geliştirici kuruluşta uygulamalar oluşturur ve yönetir`,
  [`Burst Limit Name`]: `Veri Bloğu Sınırı Adı`,
  [`Burst limits`]: `Veri bloğu sınırları`,
  [`Burst limit value can't be negative`]: `Ayırma sınırı değeri negatif olamaz`,
  [`Buy`]: `Satın al`,
  [`but unable to remove because of limitation`]: `ancak sınırlama nedeniyle kaldırılamıyor`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Bir ürünün görünürlüğü ve abone olunabilirliğini yapılandırarak geliştirici portalındaki uygulama geliştiriciler için API'lerin kullanılabilirliğini denetleyin. Hangi uygulama geliştiricilerin ürünü görebileceğini ve hangilerinin üründeki API'leri kullanmak için abone olabileceğini denetleyebilirsiniz.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Bir ürünün görünürlüğü ve abone olunabilirliğini yapılandırarak geliştirici portalındaki uygulama geliştiriciler için API'lerin kullanılabilirliğini denetleyin. Hangi uygulama geliştiricilerin ürünü görebileceğini ve hangilerinin üründeki API'leri kullanmak için abone olabileceğini denetleyebilirsiniz.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Varsayılan olarak, birden çok kaynaktan eşleme (örn. 2 uzunluğunda dizi1 ve 3 uzunluğunda dizi2), 5 öğe (işleme dizisi1 ile 2 ve işleme dizisi2 ile 3) içeren bir hedef diziyle sonuçlanır. Bu kuralların birleştirilmesini tercih ederseniz (her bir kaynak dizideki özellikler birleştirilmiş şekilde 2 ya da 3 öğe içeren bir dizi yaratarak) bu seçeneği işaretleyin.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Varsayılan olarak bu ürün tüm ilgili ağ geçidi hizmetlerine yayınlanır. Bu seçeneği etkinleştirerek belirli ağ geçidi hizmetlerine de yayın yapabilirsiniz.`,
  [`By payload`]: `Bilgi yüküne göre`,
  [`By URL parameter`]: `URL parametresine göre`,
  [`Bytes`]: `Bayt`,
  [`CALLS`]: `ÇAĞRILAR`,
  [`Calls`]: `Çağrılar`,
  [`CATALOG`]: `KATALOG`,
  [`CERTIFICATE NAME`]: `SERTİFİKA ADI`,
  [`CIPHER`]: `ŞİFRE`,
  [`CIPHER SUITES`]: `ŞİFRE TAKIMLARI`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Yalnızca CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `İstemci Tanıtıcısı`,
  [`CLOUD ADMINISTRATOR`]: `BULUT YÖNETİCİSİ`,
  [`Consumer organization`]: `Tüketici kuruluşu`,
  [`CONSUMER ORGANIZATION / GROUP`]: `TÜKETİCİ KURULUŞU / GRUBU`,
  [`Consumer-Onboard-Approval`]: `Tüketici-Devreye Alım-Onay`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA paketi`,
  [`Cache Key`]: `Önbellek Anahtarı`,
  [`Cache Time to Live (second)`]: `Önbellek Yaşam Süresi (saniye)`,
  [`Cache key`]: `Önbellek anahtarı`,
  [`Cache Time-To-Live`]: `Önbellek Yaşam Süresi`,
  [`Cache type`]: `Önbellek tipi`,
  [`Can't find the one you want?`]: `İstediğinizi bulamıyor musunuz?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Geçersiz posta sunucuyla bir davet gönderilemiyor, lütfen posta sunucusu yapılandırmanızı kontrol edip yeniden deneyin`,
  [`Cancel`]: `İptal`,
  [`Cannot be below {min}`]: `{min} altında olamaz`,
  [`Cannot be empty`]: `Boş olamaz`,
  [`Cannot delete root type.`]: `Kök tipi silinemiyor.`,
  [`Cannot exceed {max}`]: `{max} üzerinde olamaz`,
  [`Cannot find any APIs for this product`]: `Bu ürün için API bulunamıyor`,
  [`Cannot find the one you want?`]: `İstediğinizi bulamıyor musunuz?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `"sizedFields" de tanımlanamadığında bir liste döndürmeyen alanda "@listSize" yönergesi kullanılamaz.`,
  [`Cannot remove built-in scalar types`]: `Yerleşik sayıl tipler kaldırılamaz`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Sorgu, mutasyon ve abonelik kök işlem tipi kaldırılamaz`,
  [`Cannot remove arguments of built-in directives`]: `Yerleşik yönergelerin bağımsız değişkenleri kaldırılamaz`,
  [`Cannot remove non-null arguments of directives`]: `Boş değerli olmayan direktiflerin bağımsız değişkenleri kaldırılamaz`,
  [`Cannot remove input types of arguments of directives`]: `Yönergelerin bağımsız değişkenlerinin giriş tipleri kaldırılamaz`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Yönergelerin bağımsız değişkenlerinin varsayılan değerleri olarak kullanılan sıralama değerleri kaldırılamaz`,
  [`Cannot remove enum values used as default values of input fields`]: `Giriş alanlarının varsayılan değerleri olarak kullanılan sıralama değerleri kaldırılamaz`,
  [`Cannot remove all fields of an object type`]: `Bir nesne tipinin tüm alanları kaldırılamaz`,
  [`Cannot remove all fields of an interface type`]: `Bir arabirim tipinin tüm alanları kaldırılamaz`,
  [`Cannot remove all slicing arguments`]: `Tüm dilimleme bağımsız değişkenleri kaldırılamaz`,
  [`Cannot remove all input fields of an input object type`]: `Bir giriş nesnesi tipinin tüm giriş alanları kaldırılamaz`,
  [`Cannot remove all values of enum type`]: `Sıralama tipinin tüm değerleri kaldırılamaz`,
  [`Cannot remove fields of interfaces`]: `Arabirimlerin alanları kaldırılamaz`,
  [`Cannot remove non-null input fields`]: `Boş olmayan giriş alanları kaldırılamaz`,
  [`Case`]: `Büyük Küçük Harf`,
  [`Case sensitive`]: `Büyük/küçük harf duyarlı`,
  [`Catalog`]: `Katalog`,
  [`Catalog defaults`]: `Katalog varsayılanları`,
  [`Catalog Invite`]: `Katalog Daveti`,
  [`Catalog invite`]: `Katalog daveti`,
  [`Catalog invites`]: `Katalog davetleri`,
  [`Catalog name`]: `Katalog adı`,
  [`Catalog owner`]: `Katalog sahibi`,
  [`Catalog owner invitation`]: `Katalog sahibi daveti`,
  [`Catalog properties`]: `Katalog özellikleri`,
  [`Catalog summary`]: `Katalog özeti`,
  [`Catalog title`]: `Katalog başlığı`,
  [`Catalog user registries`]: `Katalog kullanıcı kayıt defterleri`,
  [`Catalog by Name`]: `Ada Göre Katalog`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Katalog önceliği (katalog yöneticisi tarafından tanımlanan özel uç noktaları kullan)`,
  [`Catalog {name} created`]: `{name} adlı katalog yaratıldı`,
  [`Catalog selection`]: `Katalog seçimi`,
  [`Catalog settings`]: `Katalog ayarları`,
  [`Catalog settings have been updated`]: `Katalog ayarları güncellendi`,
  [`Catalog User`]: `Katalog Kullanıcısı`,
  [`Catalog Users`]: `Katalog Kullanıcıları`,
  [`Catalog user registry`]: `Katalog kullanıcı kaydı`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `LifeCycle etkinleştirilen katalog Test Tercihlerinde kullanılamıyor.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Yaşam Döngüsü ve üretim modu etkin olan katalog, Test Tercihleri öğesinde kullanılamaz.`,
  [`Catalogs`]: `Kataloglar`,
  [`Catch`]: `Yakalama`,
  [`Catches`]: `Yakalamalar`,
  [`Categories`]: `Kategoriler`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Kategoriler, geliştirici portalında API ürünlerinin sıradüzensel bir görüntüsünü oluşturur. Şu sözdizimini kullanın: top_level_element/next_level_element/lower_level_element.`,
  [`Category`]: `Kategori`,
  [`Certifcate or Shared Secret for Verify`]: `Doğrulama için Sertifika ya da Paylaşılan Güvenlik Dizgisi`,
  [`Certificate`]: `Sertifika`,
  [`Certificate (Optional)`]: `Sertifika (İsteğe Bağlı)`,
  [`Certificate management`]: `Sertifika yönetimi`,
  [`Certificate Manager instance`]: `Sertifika Yöneticisi eşgörünümü`,
  [`Certificate (optional)`]: `Sertifika (isteğe bağlı)`,
  [`Certificates`]: `Sertifikalar`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Sertifikalar, ağ geçidi ve işlediği etki alanları arasındaki bağlantıların güvenliği için gerekir. Tüm sertifikalar bir Sertifika Yöneticisi hizmetinde depolanmalıdır. Hizmet, sertifikalar için güvenli bir havuz sağlar ve sertifikaların süresi dolmak üzere olduğunda size bildirimler göndererek kesintilerin önlenmesine yardımcı olur. Başlamak için,`,
  [`Certificates details in this keystore`]: `Bu anahtar deposundaki sertifika ayrıntıları`,
  [`Certificates details in this truststore`]: `Bu güvenli depolardaki sertifika ayrıntıları`,
  [`Change`]: `Değiştir`,
  [`Change Consumer Organization owner to an existing user`]: `Tüketici Kuruluşu sahibini var olan bir kullanıcıya değiştir`,
  [`Change Ownership to an Existing User`]: `Sahipliği Var Olan Bir Kullanıcıya Değiştir`,
  [`Change portal service`]: `Portal hizmetini değiştir`,
  [`Change owner to existing user`]: `Sahibi var olan bir kullanıcıya değiştir`,
  [`Change owner to new user`]: `Sahibi yeni kullanıcıya değiştir`,
  [`Change ownership by inviting a new user`]: `Yeni bir kullanıcı davet ederek sahipliği değiştir`,
  [`Change ownership to`]: `Sahipliği değiştir`,
  [`Change ownership to a new user via email invitation`]: `E-posta daveti aracılığıyla sahipliği yeni bir kullanıcıya değiştir`,
  [`Change ownership to an existing user`]: `Sahipliği var olan bir kullanıcıya değiştir`,
  [`Change password`]: `Parolayı değiştir`,
  [`Change setup`]: `Ayarları değiştir`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Bir OAuth sağlayıcısının temelini oluşturan API ya da Derleme üzerinde doğrudan yaptığınız değişiklikler, OAuth sağlayıcısının tanımında ilgili değerleri de güncellemezseniz, OAuth sağlayıcısını geçersiz sayabilir. Bu değişiklikleri kaydederseniz, OAuth sağlayıcı tanımlamasında ilgili güncellemeleri de yaptığınızdan emin olun.`,
  [`Change the following extra constraints in the schema:`]: `Şemada aşağıdaki ek kısıtlamaları değiştirin:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `Sertifika Yöneticisi değiştirildiğinde var oaln tüm sertifika ve CA paketi atamaları kaldırılır.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `DNS şeması değiştirildiğinde API Connect ve Geliştirici Portalı URL bağlantılarının biçimi değişir. Yeni bağlantıları kullanıcılara iletmeniz gerekir. Devam etmek istemiyorsanız İptal'i tıklatın.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Bir API'nin adı veya sürümü değiştirildiğinde yeni bir API yaratılır. Değişikliklerinizi kaydetmek istediğinizden emin misiniz?`,
  [`Check your email`]: `E-postanızı kontrol edin`,
  [`Child`]: `Alt Öğe`,
  [`Chinese (Simplified)`]: `Çince (Basitleştirilmiş)`,
  [`Chinese (Traditional)`]: `Çince (Geleneksel)`,
  [`Choose`]: `Seç`,
  [`Choose a`]: `Bir değer seçin`,
  [`Choose {docTypeLabel}`]: `{docTypeLabel} seçin`,
  [`Choose Catalog`]: `Katalog Seç`,
  [`Choose a Catalog`]: `Katalog Seçin`,
  [`Choose a Consumer Org`]: `Tüketici Kuruluş seçin`,
  [`Choose Gateway`]: `Ağ Geçidi Seç`,
  [`Choose a Gateway`]: `Ağ Geçidi Seçin`,
  [`Choose Product`]: `Ürün Seç`,
  [`Choose a Product`]: `Ürün Seçin`,
  [`Choose Rate Limit`]: `Hız Sınırı Seç`,
  [`Choose a Rate Limit`]: `Bir Hız Sınırı seçin`,
  [`Choose Application`]: `Uygulama Seç`,
  [`Choose an Application`]: `Uygulama Seçin`,
  [`Choose a {docTypeLabel}`]: `Bir {docTypeLabel} seçin`,
  [`Choose a publish destination`]: `Bir yayınlama hedefi seçin`,
  [`Choose a billing integration`]: `Bir faturalama bütünleştirmesi seçin`,
  [`Choose a catalog to test within:`]: `İçinde test edilecek bir katalog seçin:`,
  [`Choose a plan against which to test:`]: `Testte ölçüt olacak bir plan seçin:`,
  [`Choose a product containing this API, or create a new one:`]: `Bu API'yi içeren bir ürün seçin ya da yenisini yaratın:`,
  [`Choose a user registry`]: `Bir kullanıcı kayıt defteri seçin`,
  [`Choose an application with which to test, or create a new one:`]: `Testle kullanılacak bir uygulama seçin ya da yenisini yaratın:`,
  [`Choose an existing application`]: `Var olan bir uygulama seçin`,
  [`Choose an existing catalog`]: `Var olan bir katalog seçin`,
  [`Choose an existing product published to your Sandbox catalog`]: `Kum Havuzu kataloğunuza yayınlanan var olan bir ürünü seçin`,
  [`Choose an operation to invoke:`]: `Yürütülecek bir işlem seçin:`,
  [`Choose an option`]: `Bir seçenek belirleyin`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Eşlem ilkesinin boş dizi çıkışını nasıl işleyeceğini belirlemek için bir seçenek belirtin. Tümü seçeneği (varsayılan seçenek), tüm boş dizileri ve boş alt dizileri verir. Üst seçeneği yalnızca üst boş diziyi verir. Yok seçeneği, boş diziyi vermez.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Engelleme modunun seçilmesi, sistem performansı üzerinde olumsuz etkilere neden olur`,
  [`Choose existing directory`]: `Var olan dizini seç`,
  [`Choose one...`]: `Birini seçin...`,
  [`Choose operations and paths to generate into this API`]: `Bu API'de oluşturmak için yolları ve işlemleri seç`,
  [`Choose paths to generate into this API`]: `Bu API'ye oluşturulacak yolları seçin`,
  [`Choose the DNS scheme`]: `DNS şeması seç`,
  [`Choose the permissions for the role.`]: `Role ilişkin izinleri seçin.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Ağ geçidi hizmetine gelen API çağrıları ve geliştirici portal erişimi için dinamik mi yoksa statik DNS adreslerinin mi kullanılacağını seçin.`,
  [`Cipher`]: `Şifre`,
  [`Ciphers`]: `Şifreler`,
  [`Clear`]: `Temizle`,
  [`Clear constraints`]: `Kısıtlamaları temizle`,
  [`Clear file`]: `Dosyayı temizle`,
  [`Clear filters`]: `Süzgeçleri kaldır`,
  [`Clear from selected`]: `Seçilenlerden temizle`,
  [`Clear operation filter`]: `İşlem süzgecini temizle`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Veren kuruluş ve parmak izi hakkında ayrıntıları görüntülemek için bir sertifikayı tıklatın.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Başka bir sekmede API'leri yetkilendirmeye devam etmek için aşağıdaki bağlantıyı tıklatın; böylece 2. adım için Yetkilendirme kodunu alırsınız.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Aşağıdaki bağlantı tıklatıldığında sunucu yeni bir sekmede açılacak. Tarayıcı bir sertifika sorunu görüntülerse bunu kabul edip yeniden test etmek için buraya geri dönmeyi seçebilirsiniz.`,
  [`Click add to add a billing integration.`]: `Bir faturalama bütünleştirmesi eklemek için ekle'yi tıklatın.`,
  [`Click Add to add a gateway extension.`]: `Bir ağ geçidi uzantısı eklemek için Ekle'yi tıklatın.`,
  [`Click **Add domain** to add domains.`]: `Etki alanları eklemek için **Etki alanı ekle** düğmesini tıklatın.`,
  [`Click Add to add a new base endpoint.`]: `Yeni bir temel uç nokta eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new HTTP header.`]: `Yeni bir HTTP üstbilgisi eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new member.`]: `Yeni bir üye eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new role.`]: `Yeni bir rol eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new scope.`]: `Yeni bir kapsam eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new consumer organization group.`]: `Yeni bir tüketici kuruluşu grubu eklemek için Ekle'yi tıklatın.`,
  [`Click Add to create the API Key.`]: `API Anahtarı oluşturmak için Ekle'yi tıklatın.`,
  [`Click Create to add the API Key.`]: `API Anahtarı eklemek için Oluştur'u tıklatın.`,
  [`Click Create to add a new user registry.`]: `Yeni bir kullanıcı kayıt defteri eklemek için Yarat düğmesini tıklatın.`,
  [`Click Create user registry to add a new user registry.`]: `Yeni bir kullanıcı kayıt defteri eklemek için Kullanıcı kayıt defteri yarat düğmesini tıklatın.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Sağlayıcı kuruluşlar eklemek için **Sağlayıcı kuruluş yarat** seçeneğini tıklatın.`,
  [`Click Edit to add OAuth providers.`]: `OAuth sağlayıcıları eklemek için Düzenle'yi tıklatın.`,
  [`Click Edit to add a user registry.`]: `Bir kullanıcı kayıt defteri eklemek için Düzenle'yi tıklatın.`,
  [`Click Edit to add an API to the product.`]: `Ürüne bir API eklemek için Düzenle'yi tıklatın.`,
  [`Click Create to add a property.`]: `Bir özellik eklemek için Yarat'ı tıklatın.`,
  [`Click Edit to add TLS client profiles.`]: `TLS istemci profilleri eklemek için Düzenle'yi tıklatın.`,
  [`Click Edit to add user registries.`]: `Kullanıcı kayıt defteri eklemek için Düzenle'yi tıklatın.`,
  [`Click Add to add a new consumer organization.`]: `Yeni bir tüketici kuruluşu eklemek için Ekle'yi tıklatın.`,
  [`Click Consumer organizations to add one.`]: `Bir tüketici kuruluşu eklemek için Tüketici Kuruluşları'nı tıklatın.`,
  [`Click Add to add a new application.`]: `Yeni bir uygulama eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add parameters.`]: `Parametre eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add response.`]: `Yanıt eklemek için Ekle'yi tıklatın.`,
  [`Click Create to add a new TLS client profile.`]: `Yeni bir TLS istemci profili eklemek için Yarat'ı tıklatın.`,
  [`Click Create to add a new keystore.`]: `Yeni bir anahtar deposu eklemek için Yarat'ı tıklatın.`,
  [`Click Create to add a new truststore.`]: `Yeni bir güvenli depo eklemek için Yarat'ı tıklatın.`,
  [`Click **Register remote gateway** to add gateways.`]: `Ağ geçitleri eklemk için **Uzak ağ geçidini kaydet** seçeneğini tıklatın.`,
  [`Click Register gateways to add new Gateway Services.`]: `Yeni Ağ Geçidi Hizmetleri eklemek için Ağ geçitlerini kaydet'i tıklatın.`,
  [`Click Edit to add Gateway Services.`]: `Ağ Geçidi Hizmetleri eklemek için Düzenle'yi tıklatın.`,
  [`Click Register gateways to add new gateway services.`]: `Yeni ağ geçidi hizmetleri eklemek için Ağ geçitlerini kaydet'i tıklatın.`,
  [`Click Edit to add gateway services.`]: `Ağ geçidi hizmetlerini eklemek için Düzenle'yi tıklatın.`,
  [`Click Edit to add an email server.`]: `Bir e-posta sunucusu eklemek için Düzenle'yi tıklatın.`,
  [`Click Create to add a new TLS server profile.`]: `Yeni bir TLS sunucu profili eklemek için Yarat'ı tıklatın.`,
  [`Click Create to add a new email server.`]: `Yeni bir e-posta sunucusu eklemek için Yarat'ı tıklatın.`,
  [`Click Add to add a new schema definition.`]: `Yeni bir şema tanımı eklemek için Ekle 'yi tıklatın.`,
  [`Click Add to add a new property for this definition.`]: `Bu tanıma ilişkin yeni bir özellik eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new parameter for this API.`]: `Bu API için yeni bir parametre eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add tags and external documentation for this API.`]: `Bu API'ye ilişkin etiket ve dış belge eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new path.`]: `Yeni bir yol eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a property for this API.`]: `Bu API'ye ilişkin bir özellik eklemek için Ekle'yi tıklatın.`,
  [`Click here to create a security definition`]: `Bir güvenlik tanımı yaratmak için burayı tıklatın`,
  [`Click Add to add a new security definition.`]: `Yeni bir güvenlik tanımı eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a target service for this API.`]: `Bu API'ye ilişkin yeni bir hedef hizmet eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add an operation for this path.`]: `Bu yola ilişkin bir işlem eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a path parameter for this path.`]: `Bu yola ilişkin bir yol parametresi eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new OAuth provider.`]: `Yeni bir OAuth sağlayıcısı eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new provider organization.`]: `Yeni bir sağlayıcı kuruluşu eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new API or product.`]: `Yeni bir API ya da ürün eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new API.`]: `Yeni API eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add a new Extension.`]: `Yeni bir uzantı eklemek için Ekle'yi tıklatın.`,
  [`Click Add to add new {resource}.`]: `Yeni {resource} eklemek için Ekle düğmesini tıklatın.`,
  [`Click to expand`]: `Genişletmek için tıklatın`,
  [`Click to collapse`]: `Daraltmak için tıklatın`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Ağ geçicinin gerektirdiği sertifikların nasıl karşıya yüklenip yönetileceğini öğrenmek için tıklatın`,
  [`Client Credentials`]: `İstemci Kimlik Bilgileri`,
  [`Client information`]: `İstemci bilgileri`,
  [`Client revocation path`]: `İstemci iptal yolu`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Bir hizmeti kaydetmek için Hizmeti kaydet'i tıklatın. Bu, diğer hizmetleri yapılandırırken uç noktaları test etmenizi sağlar.`,
  [`Client secret`]: `İstemci güvenlik dizgisi`,
  [`Client security`]: `İstemci güvenliği`,
  [`Client Type`]: `İstemci Tipi`,
  [`Client authentication method`]: `İstemci kimlik doğrulama yöntemi`,
  [`Clone`]: `Eşkopyala`,
  [`Close`]: `Kapat`,
  [`Cloud`]: `Cloud`,
  [`Cloud Admin`]: `Bulut Yöneticisi`,
  [`Cloud Administrator`]: `Bulut Yöneticisi`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Cloud Yöneticisi, Kuruluş Yöneticisi, Topoloji Yöneticisi. Üye rolü, otomatik olarak tüm kullanıcılara atanır.`,
  [`Cloud administration`]: `Bulut yönetimi`,
  [`Cloud Discovery Agent`]: `Bulut Keşif Aracısı`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Cloud Manager Yerel Kullanıcı Kayıt Defteri`,
  [`Cloud-Settings`]: `Bulut-Ayarları`,
  [`Cloud settings invitation timeout`]: `Bulut ayarları daveti zaman aşımı`,
  [`Cloud settings invitation timeout has been changed`]: `Bulut ayarları daveti zaman aşımı değiştirildi`,
  [`Code editor`]: `Kod düzenleyici`,
  [`Collect Metadata`]: `Meta Veri Topla`,
  [`Collect credentials using`]: `Kimlik bilgilerini şunu kullanarak topla:`,
  [`Collect metadata`]: `Meta verileri topla`,
  [`Combo box`]: `Birleşik giriş kutusu`,
  [`Community Manager`]: `Topluluk Yöneticisi`,
  [`community-manager`]: `community-manager`,
  [`Compatibility`]: `Uyumluluk`,
  [`Complex Definitions must be edited in the editor`]: `Karmaşık Tanımlar düzenleyicide düzenlenmelidir`,
  [`Compose DN`]: `Oluşturma Ayırt Edici Adı`,
  [`Compose UPN`]: `Oluşturma UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `Yolları ve işlemleri tanımlayarak yeni bir REST yetkili sunucusu oluştur`,
  [`Compose a new product by adding rate limits and plans`]: `Hız sınırları ve planlar ekleyerek yeni bir ürün oluşturun`,
  [`Compression`]: `Sıkıştırma`,
  [`Condition`]: `Koşul`,
  [`Condition editor`]: `Koşul düzenleyicisi`,
  [`Confidential`]: `Gizli`,
  [`Configuration`]: `Yapılandırma`,
  [`Configurations`]: `Yapılandırmalar`,
  [`Configure API endpoint`]: `API uç noktasını yapılandır`,
  [`Configure API Gateway Service`]: `API Ağ Geçidi Hizmetini Yapılandır`,
  [`Configure analytics service`]: `Analitik hizmetini yapılandır`,
  [`Configure availability zone`]: `Kullanılabilirlik bölgesini yapılandır`,
  [`Configure catalog user registries`]: `Katalog kullanıcı kayıt defterlerini yapılandır`,
  [`Configure cloud`]: `Bulutu yapılandır`,
  [`Configure DataPower API gateway service`]: `DataPower API ağ geçidi hizmetini yapılandır`,
  [`Configure DataPower gateway service`]: `DataPower ağ geçidi hizmetini yapılandır`,
  [`Configure Email Server`]: `E-posta Sunucusu Yapılandır`,
  [`Configure gateway extension`]: `Ağ geçidi uzantısını yapılandır`,
  [`Configure portal service`]: `Portal hizmetini yapılandır`,
  [`Configure service`]: `Hizmeti yapılandır`,
  [`Configure topology`]: `Topolojiyi yapılandır`,
  [`Configure XML`]: `XML'i yapılandır`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `API'lerin güvenliği ve uygulanması için bir DataPower API ağ geçidi hizmeti yapılandırın`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `API'lerin güvenliği ve uygulanması için bir Datapower ağ geçidi hizmeti yapılandırın`,
  [`Configure a developer portal service for API consumers`]: `API tüketicileri için bir geliştirici portal hizmeti yapılandırın`,
  [`Configure advanced features for OIDC settings`]: `OIDC ayarları için gelişmiş özellikleri yapılandırın`,
  [`Configure an analytics service to collect API call data`]: `API çağrı verilerini toplamak için bir analitik hizmetini yapılandırın`,
  [`Configure an email server to send invitations and notifications to users.`]: `Kullanıcılara davet ve bildirim göndermek için bir e-posta sunucusunu yapılandırın.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Admin UI, Yönetici UI, Araç takımı, Yönetim REST API ve Portal kullanıcıları işlemlerini izlemek için denetimi yapılandırın. Her oluşturma, güncelleme ve silme kullanıcı işlemi günlükleri, aşağıda belirtilen uzak günlük kaydı hizmetlerine gönderilir.`,
  [`Configure availability zones and services`]: `Kullanılabilirlik bölgelerini ve hizmetlerini yapılandırın`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `API uç noktası URL'lerinin yayınlanan API'lerde nasıl oluşturulduğunu yapılandır`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Geliştirici portalında özel uç noktaların nasıl görüntülendiğini yapılandırın`,
  [`Configure mapping`]: `Eşlemeyi yapılandır`,
  [`Configure properties of the activity log`]: `Etkinlik günlüğü özelliklerini yapılandırın`,
  [`Configure settings for token management and revocation.`]: `Belirteç yönetimi ve iptali ayarlarını yapılandırın.`,
  [`Configure settings to generate and validate tokens.`]: `Belirteçler oluşturmak ve doğrulamak için ayarları yapılandırın.`,
  [`Configure the API security`]: `API güvenliğini yapılandırın`,
  [`Configure the default set of gateway services configured for each catalog`]: `Her katalog için yapılandırılan varsayılan ağ geçidi hizmetleri kümesini yapılandırın`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Bu katalogdaki API'lere erişmek için uygulama geliştiricileri tarafından kullanılan geliştirici portalı yapılandırın`,
  [`Configure the gateway services used by default in each catalog`]: `Her katalogda varsayılan olarak kullanılan ağ geçidi hizmetlerini yapılandırın`,
  [`Configure the gateway extension for the selected gateway`]: `Seçilen ağ geçidi için uzantıyı yapılandır`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `IBM'in yönettiği DataPower API ağ geçidini yapılandırın ve kendi uzak ağ geçitlerinizi yönetin. [Ek bilgi]({link})`,
  [`Configure the keystore and upload certificates`]: `Anahtar deposunu yapılandırın ve sertifikaları karşıya yükleyin`,
  [`Configure the name and email address to be used in the from field of emails`]: `E-postaların 'Kimden' alanında kullanılacak adı ve e-posta adresini yapılandırın`,
  [`Configure the portal service for the catalog`]: `Katalog için portal hizmetini yapılandırın`,
  [`Configure the role and assign permissions`]: `Rolleri yapılandırın ve izinleri atayın`,
  [`Configure the security of this API`]: `Bu API'nin güvenliğini yapılandır`,
  [`Configure the sender name and address to use for email notifications`]: `E-posta bildirimleri için kullanılacak gönderen adı ve adresini yapılandırın`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Kullanıcılara davetler ve bildirimler göndermek için kullanılan gönderen adı, adres ve e-posta sunucusunu yapılandırın`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Bir tüketici kuruluşu oluşturulduğunda varsayılan olarak kullanılacak rol kümesini yapılandırın`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Bir sağlayıcı kuruluşu oluşturulduğunda varsayılan olarak kullanılacak rol kümesini yapılandırın`,
  [`Configure the templates used to invite and notify users`]: `Kullanıcıları davet etmek ve kullanıcıları bilgilendirmek için kullanılan şablonları yapılandırın`,
  [`Configure the truststore and upload certificates`]: `Güvenli depoyu yapılandırın ve sertifikaları karşıya yükleyin`,
  [`Configure user authentication using`]: `Kullanıcı kimlik doğrulamasını şunu kullanarak yapılandırın:`,
  [`Configure user authentication using JSON Web Tokens`]: `JSON Web Belirteçleri kullanarak kullanıcı kimlik doğrulamasını yapılandır`,
  [`Configure user authentication using a LDAP provider`]: `LDAP sağlayıcısı kullanarak kullanıcı kimlik doğrulamasını yapılandır`,
  [`Configure user authentication using an API Connect Local User Registry`]: `API Connect Yerel Kullanıcı Kayıt Defteri kullanarak kullanıcı kimlik doğrulamasını yapılandır`,
  [`Configure user authentication using an authentication URL`]: `Bir kimlik doğrulama URL'si kullanılarak kullanıcı kimlik doğrulamasını yapılandır`,
  [`Configure user authentication using this user registry type`]: `Bu kullanıcı kaydı tipini kullanarak kullanıcı kimlik doğrulamasını yapılandır`,
  [`Configure user registries, OAuth providers and TLS`]: `Kullanıcı kayıt defterlerini, OAuth sağlayıcılarını ve TLS'yi yapılandırın`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Kullanıcı kayıt defterlerini, TLS, OAuth sağlayıcılarını ve e-posta sunucularını yapılandırın`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Erişim belirteci, tanıtıcı belirteci ve Geçici belirteç için Anahtar Deposunu yapılandır`,
  [`Configured OAuth Provider`]: `Yapılandırılan OAuth Sağlayıcısı`,
  [`Configured OAuth Providers`]: `Yapılandırılan OAuth Sağlayıcıları`,
  [`Configured OAuth provider`]: `Yapılandırılan OAuth sağlayıcısı`,
  [`Configured OAuth provider list has been updated`]: `Yapılandırılan OAuth sağlayıcısı listesi güncellendi`,
  [`Configured OAuth provider list has not been updated`]: `Yapılandırılan OAuth sağlayıcısı listesi güncellenmedi`,
  [`Confirm`]: `Doğrula`,
  [`Confirm deletion`]: `Silmeyi onayla`,
  [`Confirm Deletion of Portal`]: `Portal Silinmesini Onayla`,
  [`Confirm visibility settings`]: `Görünürlük ayarlarını onayla`,
  [`Confirm new password`]: `Yeni parolayı onayla`,
  [`Confirm password`]: `Parolayı doğrula`,
  [`Confirm password should match password`]: `Parolanın parola ile eşleştiğini onaylayın`,
  [`Confirmation`]: `Onay`,
  [`Congratulations, you are now registered.`]: `Tebrikler, artık kayıtlısınız.`,
  [`Congratulations, your password has been reset!`]: `Tebrikler, parolanız sıfırlandı!`,
  [`Connect`]: `Connect`,
  [`Connect to API Connect`]: `API Connect'e Bağlan`,
  [`Connected to API Manager Product version`]: `API Manager Ürün sürümüne bağlandı`,
  [`Connect to Cloud`]: `Buluta Bağlan`,
  [`Connected`]: `Bağlandı`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Gerekli tiplerin özelliklerini ekleyerek şema tanımınızı oluşturun. Özellikleri iç içe yerleştirebilirsiniz.`,
  [`Consider for nesting`]: `İç içe yerleştirme için dikkate al`,
  [`Consumed Media Types`]: `Tüketilen Ortam Tipleri`,
  [`Consumer`]: `Tüketici`,
  [`Consumer invitation and roles`]: `Tüketici daveti ve rolleri`,
  [`Consumer onboarding`]: `Tüketici katılımı`,
  [`Consumer organizations`]: `Tüketici kuruluşları`,
  [`Consumer organizations or groups`]: `Tüketici kuruluşları ya da grupları`,
  [`Consumer organization {name} created`]: `{name} adlı tüketici kuruluşu oluşturuldu`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Tüketici kuruluşları, Geliştirici Portalı'nda uygulama geliştiricileri tarafından yaratılır. Siz de API Manager UI'sini kullanarak tüketici kuruluşları yaratabilirsiniz.`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Tüketici kuruluşları, geliştirici portalındaki uygulama geliştiriciler tarafından oluşturulur ve siz de burada tüketici kuruluşlar oluşturabilirsiniz`,
  [`Consumer-Org`]: `Tüketici-Kuruluş`,
  [`Consumers`]: `Tüketiciler`,
  [`Consumes`]: `Tüketir`,
  [`Contact`]: `İletişim`,
  [`Contact information for the owners of the API.`]: `API'nin sahipleri için iletişim bilgileri.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Verilerinizi burada görebilmek için analitik hizmetinin yapılandırılmasıyla ilgili olarak sistem yöneticinize başvurun.`,
  [`Contact your administrator for more information.`]: `Ek bilgi için sistem yöneticinizle iletişim kurun.`,
  [`Content`]: `İçerik`,
  [`Content on error`]: `Hatalı içerik`,
  [`Content type`]: `İçerik tipi`,
  [`Context Variable`]: `Bağlam Değişkeni`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Doğrulanacak JWT'yi içeren bağlam ya da çalıştırma zamanı değişkeni. Ayarlanmamışsa ilke request.headers.authorization içinde JWT arar.`,
  [`Context variable`]: `Bağlam değişkeni`,
  [`Continue`]: `Devam`,
  [`Continue on error`]: `Hata durumunda devam et`,
  [`Continue with`]: `Şununla devam et`,
  [`Continue with:`]: `Şununla devam edin:`,
  [`Conversion type`]: `Dönüştürme tipi`,
  [`Convert to expression`]: `İfadeye dönüştür`,
  [`Cookie`]: `Tanımlama bilgisi`,
  [`Copied`]: `Kopyalandı`,
  [`Copied to clipboard`]: `Panoya kopyalandı`,
  [`Copy`]: `Kopyala`,
  [`Copy Id Headers To Message`]: `Tanıtıcı Üstbilgilerini Iletiye Kopyala`,
  [`Copy to clipboard`]: `Panoya kopyala`,
  [`Copy file path to clipboard`]: `Dosya yolunu panoya kopyala`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Çıkışa GraphQL sorgusu için maliyet tahminini kopyala`,
  [`Copy the endpoints below to`]: `Şu işlemi gerçekleştirmek için uç noktaları aşağıya kopyalayın:`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Kullanılacak API Anahtarını kopyalayın. Bu API Anahtarını Görüntüle simgesi ile yeniden görebilirsiniz.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Telif Hakkı Bilgileri`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Yukarıda düzenleyicide hataları düzeltin veya geçerli bir dosyayı yeniden karşıya yükleyin`,
  [`Cost`]: `Maliyet`,
  [`Count`]: `Sayım`,
  [`Count Limit Name`]: `Sayı Sınırı Adı`,
  [`Count Limits`]: `Sayı Sınırları`,
  [`Created at`]: `Oluşturulma zamanı:`,
  [`Counter`]: `Sayaç`,
  [`Create`]: `Yarat`,
  [`Create a [Certificate Manager]({link}) service`]: `Bir [Certificate Manager]({link}) hizmeti yarat`,
  [`Create API`]: `API Yarat`,
  [`Create an API from the Develop section.`]: `Geliştirme bölümünden bir API yaratın.`,
  [`Create API Connect API Key`]: `API Connect API Anahtarı Oluştur`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Var olan GraphQL hizmetinden (GraphQL yetkili sunucu) API yarat`,
  [`Create API from existing OpenAPI service`]: `Var olan OpenAPI hizmetinden API yarat`,
  [`Create API from existing WSDL service (REST proxy)`]: `Var olan WSDL hizmetinden API yarat (REST yetkili sunucusu)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Var olan WSDL hizmetinden API yarat (SOAP yetkili sunucusu)`,
  [`Create API from target service`]: `Hedef hizmetten API yarat`,
  [`Create API from existing REST service`]: `Var olan REST hizmetinden API yarat`,
  [`Create API from existing SOAP service`]: `Var olan SOAP hizmetinden API yarat`,
  [`Create API from existing WSDL service`]: `Var olan WSDL hizmetinden API yarat`,
  [`Create API Key failed`]: `API Anahtarı oluşturma başarısız oldu`,
  [`Create application`]: `Uygulama yarat`,
  [`Create authentication URL user registry`]: `Kimlik doğrulama URL'si kullanıcı kayıt defteri yarat`,
  [`Create availability zone`]: `Kullanılabilirlik bölgesi yarat`,
  [`Create Billing`]: `Faturalandırma Yarat`,
  [`Create catalog`]: `Katalog yarat`,
  [`Create catalog property`]: `Katalog özelliği yarat`,
  [`Create consumer organization`]: `Tüketici kuruluşu yarat`,
  [`Create Credentials`]: `Kimlik Bilgileri Yarat`,
  [`Create definition`]: `Tanımlama yarat`,
  [`Create email server`]: `E-posta sunucusu oluştur`,
  [`Create IBM Developer Portal`]: `IBM Geliştirici Portalı oluştur`,
  [`Create keystore`]: `Anahtar deposu oluştur`,
  [`Create LDAP user registry`]: `LDAP kullanıcı kaydı oluştur`,
  [`Create LTPA Key`]: `LTPA Anahtarı Oluştur`,
  [`Create local user registry`]: `Yerel kullanıcı kayıt defteri oluştur`,
  [`Create native OAuth provider`]: `Yerel OAuth sağlayıcısı oluştur`,
  [`Create New GraphQL API`]: `Yeni GraphQL API'si Oluştur`,
  [`Create new OpenAPI`]: `Yeni OpenAPI oluştur`,
  [`Create new product`]: `Yeni ürün oluştur`,
  [`Create OIDC user registry`]: `OIDC kullanıcı kaydı oluştur`,
  [`Create OpenAPI definition and product definition`]: `OpenAPI tanımı ve ürün tanımını oluştur`,
  [`Create operation`]: `İşlem oluştur`,
  [`Create organization`]: `Kuruluş oluştur`,
  [`Create path`]: `Yol oluştur`,
  [`Create plan`]: `Plan oluştur`,
  [`Create portal`]: `Portal oluştur`,
  [`Create Product`]: `Ürün Oluştur`,
  [`Create property`]: `Özellik oluştur`,
  [`Create role`]: `Rol yarat`,
  [`Create role default for the Organization`]: `Kuruluş için rol varsayılan değeri yarat`,
  [`Create sample user registry`]: `Örnek kullanıcı kayıt defteri yarat`,
  [`Create security definition`]: `Güvenlik tanımı yarat`,
  [`Create space`]: `Alan yarat`,
  [`Create subscription`]: `Abonelik yarat`,
  [`Create TLS client profile`]: `TLS istemci profili yarat`,
  [`Create TLS server profile`]: `TLS sunucu profili yarat`,
  [`Create target service`]: `Hedef hizmet yarat`,
  [`Create third party OAuth provider`]: `Üçüncü kişi OAuth sağlayıcısı yarat`,
  [`Create truststore`]: `Güvenli depo yarat`,
  [`Create user registry`]: `Kullanıcı kayıt defteri yarat`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `GraphQL hizmetine dayalı bir GraphQL yetkili sunucusu yaratın`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `OpenAPI tarafından açıklanan hedef hizmet temelinde bir REST yetkili sunucusu yarat`,
  [`Create a REST proxy based upon a WSDL described target service`]: `WSDL tarafından açıklanan hedef hizmet temelinde bir REST yetkili sunucusu yarat`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `OpenAPI tarafından açıklanan hedef hizmet temelinde bir REST yetkili sunucusu yarat`,
  [`Create a REST proxy based upon the WSDL described target service`]: `WSDL tarafından açıklanan hedef hizmet temelinde bir REST yetkili sunucusu yarat`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Hedef API ya da hizmet uç noktasına tüm trafiği yönlendiren bir REST yetkili sunucusu yarat`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `WSDL tarafından açıklanan hedef hizmet temelinde bir SOAP yetkili sunucusu yarat`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `WSDL tarafından açıklanan hedef hizmet temelinde bir SOAP yetkili sunucusu yarat`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Tutamak çubukları şablonunu kullanarak yeni bir nesne yaratın. Değişkenler bağlamdan alınır.`,
  [`Create a portal site for the catalog`]: `Katalog için bir portal sitesi oluşturun`,
  [`Create a product by importing a product definition`]: `Ürün tanımını içe aktararak bir ürün oluşturun`,
  [`Create a security definition`]: `Bir güvenlik tanımı oluştur`,
  [`Create a subscription`]: `Bir abonelik yaratın`,
  [`Create an API by importing an OpenAPI definition`]: `Bir OpenAPI tanımı içe aktararak API oluşturun`,
  [`Create an API that calls an existing SOAP service`]: `Var olan bir SOAP hizmetini çağıran bir API oluşturun`,
  [`Create and manage API provider organizations and owners`]: `API sağlayıcı kuruluşlarını ve sahipleri oluşturun ve yönetin`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Kataloğunuzda alanlar oluşturun ve yönetin; alanlar, farklı API sağlayıcı geliştirme ekiplerini desteklemek için kataloğunuzu bölmenizi sağlar`,
  [`Create and publish`]: `Oluştur ve yayınla`,
  [`Create and publish APIs and Products`]: `API'leri ve Ürünleri oluştur ve yayınla`,
  [`Create and subscribe`]: `Oluştur ve abone ol`,
  [`Create assembly`]: `Derleme oluştur`,
  [`Create empty parent object for failed mapping`]: `Başarısız eşleme için boş üst nesne oluşturun`,
  [`Create endpoint`]: `Uç nokta oluştur`,
  [`Create from`]: `Yaratma kaynağı`,
  [`Create from Existing WSDL service`]: `Var Olan WSDL hizmetinden oluştur`,
  [`Create from File or URL`]: `Dosyadan ya da URL'den Oluştur`,
  [`Create from existing openAPI service`]: `Var olan openAPI hizmetinden oluştur`,
  [`Create gateway extension`]: `Ağ geçidi uzantısını yapılandır`,
  [`Create new user`]: `Yeni kullanıcı oluştur`,
  [`Create new version`]: `Yeni sürüm oluştur`,
  [`Create product using a template`]: `Şablon kullanarak ürün oluştur`,
  [`Create provider organization`]: `Sağlayıcı kuruluşu oluştur`,
  [`Create required child properties for array objects and mapped optional objects`]: `Dizi nesneleri ve eşleşen isteğe bağlı nesneler için gerekli alt özellikleri yarat`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `OAuth Sağlayıcı yapılandırması tarafından örnek olarak yaratılmıştır. Bu örneği kullanarak, OAuth sağlayıcılarını geçerli bir Kullanıcı Kayıt Defteri ile güncellediğinizden emin olun.`,
  [`Created new product`]: `Yaratılan yeni ürün`,
  [`Create|permission`]: `Yarat`,
  [`Create|title`]: `Yarat`,
  [`Creating Draft Product`]: `Taslak Ürün yaratılıyor`,
  [`Creating a Consumer organization`]: `Tüketici kuruluşu yaratma`,
  [`Creating a consumer organization`]: `Tüketici kuruluşu yaratma`,
  [`Creating a new Product`]: `Yeni bir ürün yaratma`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons (CC-BY-4.0) lisansı`,
  [`Credential Extraction Method`]: `Kimlik Bilgileri Çıkarma Yöntemi`,
  [`Credentials for the Sandbox catalog`]: `Kum Havuzu Kataloğu için Kimlik Bilgileri`,
  [`Credentials for the {catalogName} catalog`]: `{catalogName} kataloğu için kimlik bilgileri`,
  [`Credential Name`]: `Kimlik Bilgileri Adı`,
  [`Credential has been added.`]: `Kimlik bilgileri eklendi.`,
  [`Credential`]: `Kimlik Bilgileri`,
  [`Credentials`]: `Kimlik Bilgileri`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `API Designer ve Toolkit'i çalıştırmak için kimlik bilgileri gerekiyor.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Araç takımını çalıştırmak için kimlik bilgileri gerekiyor. Kimlik bilgileri dosyasını, araç takımınızın kurulu olduğu klasöre yerlesin.`,
  [`Credit Card`]: `Kredi Kartı`,
  [`Cryptographic Algorithm`]: `Şifreleme Algoritması`,
  [`Currency`]: `Para birimi`,
  [`Current password`]: `Mevcut parola`,
  [`Current password is invalid.`]: `Mevcut parola geçersiz.`,
  [`Custom`]: `Özel`,
  [`Custom API URL`]: `Özel API URL'si`,
  [`Custom expression (optional, one per line)`]: `Özel ifade (isteğe bağlı, her satır için bir)`,
  [`Custom expression to remove (optional, one per line)`]: `Kaldırılacak özel ifade (isteğe bağlı, her satır için bir)`,
  [`Custom HTML form`]: `Özel HTML formu`,
  [`Custom policies`]: `Özel ilkeler`,
  [`Custom form TLS profile`]: `Özel form TLS profili`,
  [`Custom form content security policy`]: `Özel form içeriği güvenlik ilkesi`,
  [`Custom form CSP header value`]: `Özel form CSP üstbilgi değeri`,
  [`Custom form endpoint`]: `Özel form uç noktası`,
  [`Custom header pattern`]: `Özel üstbilgi kalıbı`,
  [`Custom subscribability is required if custom visibility is selected`]: `Özel görünürlük seçilirse özel abone olunabilirlik gereklidir`,
  [`Customize`]: `Özelleştir`,
  [`Customize email sender information`]: `E-posta göndereni bilgilerini özelleştir`,
  [`Customize notification templates`]: `Bildirim şablonlarını özelleştir`,
  [`Customize the plan API list`]: `Plan API'si listesini özelleştir`,
  [`Czech`]: `Çekçe`,
  [`DATE MODIFIED`]: `DEĞİŞTİRİLME TARİHİ`,
  [`DELETE`]: `DELETE`,
  [`Date created`]: `Yaratıldığı tarih`,
  [`Description`]: `Açıklama`,
  [`DETAIL`]: `AYRINTI`,
  [`DN Base`]: `Temel Ayırt Edici Ad`,
  [`DNS Schemes`]: `DNS Şemaları`,
  [`Dashboard`]: `Gösterge Panosu`,
  [`Data encoded form body`]: `Kodlanmış biçimli veri gövdesi`,
  [`Data flow in API Connect`]: `API Connect'te veri akışı`,
  [`DataPower (v5 compatible)`]: `DataPower (v5 uyumlu)`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Ağ Geçidi`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Ağ Geçidi (v5 uyumlu)`,
  [`DataPower Gateway policies`]: `DataPower Gateway ilkeleri`,
  [`Debug`]: `Hata Ayıklama`,
  [`Debug rule`]: `Hata ayıklama kuralı`,
  [`Debug XACML policy`]: `Hata ayıklama XACML ilkesi`,
  [`Dec`]: `Reddet`,
  [`Decline`]: `Reddet`,
  [`Decline and send`]: `Reddet ve gönder`,
  [`Decode Request Params`]: `Kod Çözme İsteği Parametreleri`,
  [`Decrement`]: `Azalt`,
  [`Decrypt`]: `Şifreyi Çöz`,
  [`Decrypt Crypto JWK variable name`]: `JWK Şifresini Çözme değişken adı`,
  [`Decrypt Crypto Object`]: `Şifre Çözme Şifre Nesnesi`,
  [`Decrypt a buffer using the specified certificate`]: `Belirtilen sertifikayı kullanarak bir arabelleğin şifresini çözün`,
  [`Default`]: `Varsayılan`,
  [`Default behavior`]: `Varsayılan davranış`,
  [`Default gateways`]: `Varsayılan ağ geçitleri`,
  [`default gateway services configured`]: `varsayılan ağ geçidi hizmetleri yapılandırıldı`,
  [`Default Availability Zone`]: `Varsayılan Kullanılabilirlik Bölgesi`,
  [`Default Base endpoints`]: `Varsayılan Temel uç noktalar`,
  [`Default availability zone`]: `Varsayılan kullanılabilirlik bölgesi`,
  [`Default base endpoints`]: `Varsayılan temel uç noktalar`,
  [`Default HTML form`]: `Varsayılan HTML formu`,
  [`Default limit`]: `Varsayılan sınır`,
  [`Default OAuth Provider Settings`]: `Varsayılan OAuth Sağlayıcısı Ayarları`,
  [`Default OAuth Provider Settings Object`]: `Varsayılan OAuth Sağlayıcısı Ayarları Nesnesi`,
  [`Default Plan`]: `Varsayılan Plan`,
  [`Default TLS client profile`]: `Varsayılan TLS istemci profili`,
  [`Default TLS server keystore`]: `Varsayılan TLS sunucu anahtar deposu`,
  [`Default TLS server profile`]: `Varsayılan TLS sunucu profili`,
  [`Default burst-limit`]: `Varsayılan ayırma sınırı`,
  [`Default form`]: `Varsayılan form`,
  [`Default keystore for access_token signing key`]: `access_token imzalama anahtarı için varsayılan anahtar deposu`,
  [`Default keystore for id_token signing key`]: `id_token imzalama anahtarı için varsayılan anahtar deposu`,
  [`Default keystore for temporary token signing key`]: `Geçici belirteç imzalama anahtarı için varsayılan anahtar deposu`,
  [`Default rate-limit`]: `Varsayılan hız sınırı`,
  [`Default scopes`]: `Varsayılan kapsamlar`,
  [`Default table entry`]: `Varsayılan tablo girdisi`,
  [`Default validator endpoint`]: `Varsayılan doğrulayıcı uç nokta`,
  [`Default validator endpoint set by OAuth provider`]: `OAuth sağlayıcı tarafından ayarlanan varsayılan doğrulayıcı uç nokta`,
  [`Default value - no extra constraints apply.`]: `Varsayılan değer - ek kısıtlama uygulanmıyor.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `API erişimini korumak üzere belirteçleri yayınlamak ve doğrulamak için bir üçüncü kişi OAuth sağlayıcısı tanımlayın.`,
  [`Define catalog specific values for this property`]: `Bu özellik için kataloğa özgü değerleri tanımla`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `API'leri, ürünleri, katalogları, uygulamaları ve ilgili ayarları yöneten grupları tanımlayın.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Belirli koşullar için yürütülecek ilke kümelerini tanımlayın. Birden çok senaryo tanımlanabilir ve her senaryo rasgele bir koşulla ilişkilendirilebilir. Yalnızca, eşleşen ilk senaryo yürütülür.`,
  [`Define the API protection source. At least one option must be enabled.`]: `API koruma kaynağını tanımlayın. En az bir seçenek etkinleştirilmelidir.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `OIDC yetkilendirme sunucusu yetkilendirme kodunu aşağıdaki uç noktalara yönlendirecek. Bir müşteri uygulamayı OIDC sağlayıcısına kaydettiğinde bu yeniden yönlendirme uç noktası gereklidir. Örneğin bu kullanıcı kaydı bir sağlayıcı kuruluş tarafından kullanılıyorsa müşteri sağlayıcı kuruluşun yeniden yönlendirme uç noktasını OIDC sağlayıcısına kaydetmelidir.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Eşlemede kullanılacak girişleri tanımlayın. Eşlemeye bir başlık ve açıklama da atayabilirsiniz.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `OpenID sağlayıcınıza bir kimlik doğrulama isteği göndermek için OpenID sağlayıcı uç noktalarını tanımlayın`,
  [`Define the outputs to be used in the map.`]: `Eşlemede kullanılacak çıkışları tanımlayın.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Ücretsiz deneme günü sayısı ve plan fiyatlandırmasını tanımlayın. Kullanıcılar abonelik tarihine göre faturalandırılacak.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `İlke yürütme sırasında bir hata oluştuğunda akışın devam edip etmeyeceğini tanımlar. Seçilmezse, bir yakalama akışı tetiklenir.`,
  [`Definition`]: `Tanım`,
  [`Definition Object`]: `Tanımlama Nesnesi`,
  [`Definitions`]: `Tanımlar`,
  [`Delete`]: `Sil`,
  [`Delete Application`]: `Uygulamayı Sil`,
  [`Delete Cloud Connection.`]: `Bulut Bağlantısını silin.`,
  [`Delete Selected APIs`]: `Seçilen API'leri Sil`,
  [`Delete selected client profiles`]: `Seçilen istemci profillerini sil`,
  [`Delete selected keystores`]: `Seçilen anahtar depolarını sil`,
  [`Delete selected mail servers`]: `Seçilen posta sunucularını sil`,
  [`Delete Selected Products`]: `Seçilen Ürünleri Sil`,
  [`Delete selected truststores`]: `Seçilen güvenli depoları sil`,
  [`Delete selected user registries`]: `Seçilen kullanıcı kayıt defterlerini sil`,
  [`Delete Staged Product`]: `Hazırlanan Ürünü Sil`,
  [`Delete a space`]: `Alanı sil`,
  [`Delete a catalog`]: `Kataloğu sil`,
  [`Delete API Key`]: `API Anahtarını Sil`,
  [`Delete API Key failed`]: `API anahtarını silme başarısız oldu`,
  [`Delete availability zone`]: `Kullanılabilirlik bölgesini sil`,
  [`Delete selected requests`]: `Seçilen istekleri sil`,
  [`Delete row`]: `Satırı sil`,
  [`Delete|title`]: `Sil`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `Çok sayıda tüketici kuruluşu, uygulama, yayınlanan ürün ve başka kaynak varsa kataloğun silinmesi başarısız olabilir.  Kataloğu silmeden önce bu kaynakları temizlemeyi düşünebilirsiniz.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `Çok sayıda katalog, tüketici kuruluşu, uygulama, yayınlanan ürün ve başka kaynak varsa bir sağlayıcı kuruluşun silinmesi başarısız olabilir. Sağlayıcı kuruluşu silmeden önce bu kaynakları temizlemeyi düşünebilirsiniz.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `Çok sayıda tüketici kuruluşu, uygulama, yayınlanan ürün ve başka kaynak varsa alanın silinmesi başarısız olabilir.  Alanı silmeden önce bu kaynakları temizlemeyi düşünebilirsiniz.`,
  [`Deleting {{name}}...`]: `{name} siliniyor...`,
  [`Deprecate`]: `Kullanım dışı bırak`,
  [`Deprecate product`]: `Ürünü kullanım dışı bırak`,
  [`Deprecated`]: `Kullanım dışı bırakıldı`,
  [`Deprecate|permission`]: `Kullanım dışı bırak`,
  [`Description of gateway`]: `Ağ geçidi açıklaması`,
  [`Description text for the behaviour of the modal.`]: `Kalıcı iletişim kutusu davranışı için açıklama metni.`,
  [`Descriptive name`]: `Açıklayıcı ad`,
  [`Design`]: `Tasarla`,
  [`Designate a current member as owner of a consumer organization.`]: `Geçerli üyeyi bir tüketici kuruluş sahibi olarak atayın.`,
  [`Designate a current member as owner of a provider organization.`]: `Sağlayıcı kuruluşun sahibi olarak geçerli bir üye atayın.`,
  [`Designer`]: `Tasarımcı`,
  [`Destination`]: `Hedef`,
  [`Determines which credential-extraction method to use.`]: `Kullanılacak kimlik bilgileri çıkarma yöntemini belirler.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Bu tipin/alanın ve geçerli bağımsız değişkenlerin şemaya dahil edip edilmeyeceğini belirleyin.`,
  [`Develop`]: `Geliştirme`,
  [`Develop APIs`]: `API Geliştir`,
  [`Develop APIs and products`]: `API ve ürün geliştir`,
  [`Developer`]: `Geliştirici`,
  [`developer`]: `geliştirici`,
  [`Developer Portal`]: `Geliştirici Portalı`,
  [`Developer community`]: `Geliştirici topluluğu`,
  [`Developers can request a state upgrade from development to production`]: `Geliştiriciler geliştirme durumundan üretime yükseltme isteğinde bulunabilir`,
  [`Development`]: `Geliştirme`,
  [`Directive`]: `Yönerge`,
  [`Director Endpoint`]: `Dizin Uç Noktası`,
  [`Director TLS client profile`]: `Director TLS istemci profili`,
  [`Disable`]: `Devre Dışı Bırak`,
  [`Disable Activity log`]: `Etkinlik günlüğünü devre dışı bırak`,
  [`Disable Self-Service Onboarding`]: `Self Servis Katılımı Devre Dışı Bırak`,
  [`Disable spaces`]: `Alanları devre dışı bırak`,
  [`Disable buffering`]: `Arabelleğe almayı devre dışı bırak`,
  [`Disable Self-Service Onboarding Approval`]: `Self Servis Katılım Onayını Devre Dışı Bırak`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Tüketici kuruluşları davet zamanaşımını geçersiz kılmayı devre dışı bırakın.`,
  [`Disabled`]: `Devre dışı`,
  [`disabled`]: `devre dışı`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Self servis katılımın devre dışı bırakılması için tüm uygulama geliştiricilerinin API sağlayıcısı, var olan bir tüketici kuruluşu sahibi ya da sistem yöneticisi tarafından davet edilmesi gerekir.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Self servis katılım onayının devre dışı bırakılması tüm katılım isteklerini otomatik olarak onaylar.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `Devre dışı bırakma, tüketici kuruluşları davet zamanaşımını katalog zamanaşımı olarak ayarlamayacak.`,
  [`Discover targets`]: `Hedefleri keşfet`,
  [`Display table checkboxes`]: `Tablo onay kutularını görüntüle`,
  [`Display vanity endpoint`]: `Özel uç nokta görüntüle`,
  [`Do not publish API (continue editting)`]: `API yayınlanmasın (düzenlemeye devam et)`,
  [`Do not require applications or users to authenticate`]: `Uygulamaların veya kullanıcıların kimlik doğrulamasını gerektirmez`,
  [`Do not require authentication`]: `Kimlik doğrulaması zorunlu değil`,
  [`Do not share the resource with provider organizations`]: `Kaynağı sağlayıcı kuruluşlarla paylaşmayın`,
  [`Do not share the API gateway with provider organizations`]: `API ağ geçidini sağlayıcı kuruluşlarla paylaşmayın`,
  [`Do not share the service with provider organizations`]: `Hizmeti sağlayıcı kuruluşlarla paylaşmayın`,
  [`Do not use Dynamic DNS`]: `Dinamik DNS Kullanma`,
  [`Do you want to remove these references?`]: `Bu başvuruları kaldırmak istiyor musunuz?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker belgeleri`,
  [`Docs`]: `Belgeler`,
  [`Document Editor`]: `Belge Düzenleyicisi`,
  [`Documentation and tutorials with step-by-step instructions`]: `Adım adım yönergeler içeren belgeler ve eğitimler`,
  [`Domain name`]: `Etki alanı adı`,
  [`Domain Name`]: `Etki Alanı Adı`,
  [`Domains handled by gateway via SNI`]: `SNI aracılığıyla ağ geçidi tarafından işlenen etki alanları`,
  [`Don't have an account?`]: `Hesabınız yok mu?`,
  [`Done`]: `Bitti`,
  [`Download`]: `Karşıdan Yükle`,
  [`Download Gateway`]: `Ağ geçidini karşıdan yükle`,
  [`Download and install`]: `Karşıdan yükle ve kur`,
  [`Download and set up DataPower API Gateway`]: `DataPower API Gateway'i karşıdan yükle ve kur`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Şirket içinde ya da herhangi bir bulutta kullanmak için DataPower API Gateway'i karşıdan yükleyin ve kurun.`,
  [`Download for Linux`]: `Linux için karşıdan yükle`,
  [`Download for Mac`]: `Mac için karşıdan yükle`,
  [`Download for Windows`]: `Windows için karşıdan yükle`,
  [`Download gateway`]: `Ağ geçidini karşıdan yükle`,
  [`Download schema`]: `Şemayı karşıdan yükle`,
  [`Download Toolkit`]: `Araç Takımını Karşıdan Yükle`,
  [`Download toolkit`]: `Araç takımını karşıdan yükle`,
  [`Download toolkit and credentials for various platforms`]: `Çeşitli platformlar için araç takımı ve kimlik bilgilerini karşıdan yükleyin`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Bu bulut bağlantısı için karşıdan yüklenen kimlik bilgileri bulunamadı. Oturum açma varsayılan kimlik bilgilerini kullanacak.`,
  [`Dutch`]: `Felemenkçe`,
  [`Draft {type} creation failed`]: `Taslak {type} yaratılamadı`,
  [`Drafts`]: `Taslaklar`,
  [`Drag and drop files here or click to upload`]: `Dosyaları sürükleyip buraya bırakın veya karşıya yüklemek için tıklatın`,
  [`Drag & Drop`]: `Sürükle ve Bırak`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `Dinamik DNS`,
  [`Dynamic OAuth configuration from a URL`]: `URL'deki dinamik OAuth yapılandırması`,
  [`Dynamic OAuth configuration from a literal string`]: `Hazır bilgi dizgisinden dinamik OAuth yapılandırması`,
  [`Dynamic group`]: `Dinamik grup`,
  [`Dynamic table entries`]: `Dinamik tablo girdileri`,
  [`ENABLE`]: `ETKİNLEŞTİR`,
  [`Encoded`]: `Kodlanmış`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Her Alan farklı bir API sağlayıcı geliştirme ekibi tarafından kullanılır ve özellikle ilişkili ekibin o Alanda yayınladığı API'lerle ilgili kendi yönetim yeteneklerine sahip olduğundan her ekip kendi API'lerini bağımsız olarak yönetebilir. Alanları etkin bir Katalog için bir API'yi kullanıma hazırladığınızda ya da yayınladığınızda kullanıma hazırlamak veya yayınlamak istediğiniz bu Katalog içindeki Alanı belirtirsiniz.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Her sağlayıcı kuruluşun kendi API'leri, ürünleri, planları ve katalogları vardır.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Bulut üzerinde ve şirket içinde API'leri kolayca yaratın, koruyun, yönetin, paylaşın ve analiz edin.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Her sağlayıcı kuruluşun kendi API'leri, ürünleri, planları ve katalogları vardır. [Ek bilgi]({link})`,
  [`Edit`]: `Düzenle`,
  [`Edit access token TTL`]: `Erişim belirteci TTL değerini düzenle`,
  [`Edit admin organization invitation timeout`]: `Yönetici kuruluş daveti zaman aşımını düzenle`,
  [`Edit API`]: `API'yi Düzenle`,
  [`Edit API Connect UI`]: `API Connect Kullanıcı Arabirimini Düzenle`,
  [`Edit API endpoint for unenforced APIs`]: `Zorunlu kılınan API'ler için API uç noktasını düzenle`,
  [`Edit API gateway service`]: `API ağ geçidi hizmetini düzenle`,
  [`Edit API Path`]: `API Yolunu Düzenle`,
  [`Edit API security definition`]: `API güvenlik tanımını düzenle`,
  [`Edit API user registries`]: `API kullanıcı kayıt defterlerini düzenle`,
  [`Edit analytics service`]: `Analitik hizmetini düzenle`,
  [`Edit application`]: `Uygulamayı düzenle`,
  [`Edit Assembly`]: `Derlemeyi Düzenle`,
  [`Edit authentication URL user registry`]: `Kimlik doğrulama URL'si kullanıcı kayıt defterini düzenle`,
  [`Edit availability zone`]: `Kullanılabilirlik bölgesini düzenle`,
  [`Edit Availablity Zone`]: `Kullanılabilirlik Bölgesini Düzenle`,
  [`Edit and apply constraints to your selected items.`]: `Seçtiğiniz öğelerle ilgili kısıtlamaları düzenleyin ve uygulayın.`,
  [`Edit billing integration`]: `Faturalama bütünleştirmesini düzenle`,
  [`Edit Catalog Details`]: `Katalog Ayrıntılarını Düzenle`,
  [`Edit cloud setting invitation timeout`]: `Bulut ayarları davet zaman aşımını düzenle`,
  [`Edit constraints`]: `Kısıtlamaları düzenle`,
  [`Edit consumer organization`]: `Tüketici kuruluşunu düzenle`,
  [`Edit DNS Scheme`]: `DNS Şemasını Düzenle`,
  [`Edit DataPower API gateway service`]: `DataPower API ağ geçidi hizmetini düzenle`,
  [`Edit DataPower gateway service`]: `DataPower ağ geçidi hizmetini düzenle`,
  [`Edit definition`]: `Tanımı düzenle`,
  [`Edit dynamic DNS`]: `Dinamik DNS'yi düzenle`,
  [`Edit email server`]: `E-posta sunucusunu düzenle`,
  [`Edit extensions`]: `Uzantıları düzenle`,
  [`Edit Gateway Service`]: `Ağ Geçidi Hizmetini Düzenle`,
  [`Edit gateway`]: `Ağ geçidini düzenle`,
  [`Edit gateway extension`]: `Ağ geçidi uzantısını düzenle`,
  [`Edit Gateways`]: `Ağ Geçitlerini Düzenle`,
  [`Edit IBM-managed gateway`]: `IBM'in yönettiği ağ geçidini düzenle`,
  [`Edit Invitation Timeout`]: `Davet Zaman Aşımını Düzenle`,
  [`Edit gateways`]: `Ağ geçitlerini düzenle`,
  [`Edit invitation timeout`]: `Davet zaman aşımını düzenle`,
  [`Edit JSON`]: `JSON dosyasını düzenle`,
  [`Edit keystore`]: `Anahtar deposunu düzenle`,
  [`Edit LDAP user registry`]: `LDAP kullanıcı kayıt defterini düzenle`,
  [`Edit LTPA Token`]: `LTPA Belirtecini Düzenle`,
  [`Edit lifecycle approvals`]: `Yaşam döngüsü onaylarını düzenle`,
  [`Edit local user registry`]: `Yerel kullanıcı kayıt defterini düzenle`,
  [`Edit native OAuth provider`]: `Yerel OAuth sağlayıcısını düzenle`,
  [`Edit New Billing System`]: `Yeni Faturalama Sistemini Düzenle`,
  [`Edit Notification Server`]: `Bildirim Sunucusunu Düzenle`,
  [`Edit Notification template`]: `Bildirim şablonunu düzenle`,
  [`Edit OAuth Provider`]: `OAuth Sağlayıcısını Düzenle`,
  [`Edit OAuth provider details`]: `OAuth sağlayıcısı ayrıntılarını düzenle`,
  [`Edit OAuth Provider Visibility`]: `OAuth Sağlayıcısı Görünürlüğünü Düzenle`,
  [`Edit OIDC User Registry`]: `OIDC Kullanıcı Kaydını Düzenle`,
  [`Edit Onboarding`]: `Katılımı Düzenle`,
  [`Edit Operation`]: `İşlemi Düzenle`,
  [`Edit Organization`]: `Kuruluşu Düzenle`,
  [`Edit Path`]: `Yolu Düzenle`,
  [`Edit Path Parameters`]: `Yol Parametrelerini Düzenle`,
  [`Edit notification template`]: `Bildirim şablonunu düzenle`,
  [`Edit OAuth provider`]: `OAuth sağlayıcısını düzenle`,
  [`Edit OAuth provider visibility`]: `OAuth sağlayıcı görünürlüğünü düzenle`,
  [`Edit OIDC user registry`]: `OIDC kullanıcı kayıt defterini düzenle`,
  [`Edit onboarding`]: `Katılımı düzenleyin`,
  [`Edit operation`]: `İşlemi düzenle`,
  [`Edit organization`]: `Kuruluşu düzenle`,
  [`Edit path`]: `Yolu düzenle`,
  [`Edit path parameters`]: `Yol parametrelerini düzenle`,
  [`Edit plan`]: `Planı düzenle`,
  [`Edit portal`]: `Portalı düzenle`,
  [`Edit portal service`]: `Portal hizmetini düzenle`,
  [`Edit Portal Settings`]: `Portal Ayarlarını Düzenle`,
  [`Edit product`]: `Ürünü düzenle`,
  [`Edit product APIs`]: `Ürün API'lerini düzenle`,
  [`Edit Product Subscribability`]: `Ürünün Abone Olunabilirliğini Düzenle`,
  [`Edit Product Visibility`]: `Ürün Görünürlüğünü Düzenle`,
  [`Edit Property`]: `Özelliği Düzenle`,
  [`Edit Provider Organization`]: `Sağlayıcı Kuruluşunu Düzenle`,
  [`Edit Refresh Token TTL`]: `Belirteci Yenileme TTL Değerini Düzenle`,
  [`Edit remote gateway`]: `Uzak ağ geçidini düzenle`,
  [`Edit Reset Password TTL`]: `Parola Sıfırlama TTL Değerini Düzenle`,
  [`Edit Role`]: `Rolü Düzenle`,
  [`Edit Role Default for Provider Organizations`]: `Sağlayıcı Kuruluşları İçin Rol Varsayılanını Düzenle`,
  [`Edit Role for Provider Organizations`]: `Sağlayıcı Kuruluşları İçin Rolü Düzenle`,
  [`Edit property`]: `Özelliği düzenle`,
  [`Edit provider organization`]: `Sağlayıcı kuruluşu düzenle`,
  [`Edit refresh token TTL`]: `Yenileme belirteci TTL değerini düzenle`,
  [`Edit reset password TTL`]: `Parola sıfırlama TTL değerini düzenle`,
  [`Edit role`]: `Rolü düzenle`,
  [`Edit role default for Provider Organizations`]: `Sağlayıcı Kuruluşlar için rol varsayılan değerini düzenle`,
  [`Edit role for Provider Organizations`]: `Sağlayıcı Kuruluşlar için rolü düzenle`,
  [`Edit Roles`]: `Rolleri Düzenle`,
  [`Edit Schema in JSON/XML`]: `JSON/XML'de şemayı düzenle`,
  [`Edit security definition`]: `Güvenlik tanımını düzenle`,
  [`Edit sender`]: `Göndereni düzenle`,
  [`Edit sender & email server`]: `Gönderen ve e-posta sunucusunu düzenle`,
  [`Edit sender Info`]: `Gönderen bilgilerini düzenle`,
  [`Edit service visibility`]: `Hizmet görünürlüğünü düzenle`,
  [`Edit show/hide settings`]: `Göster/gizle ayarlarını düzenle`,
  [`Edit space details`]: `Alan ayrıntılarını düzenle`,
  [`Edit TLS client profile`]: `TLS istemci profilini düzenle`,
  [`Edit TLS client profile visibility`]: `TLS istemci profili görünürlüğünü düzenle`,
  [`Edit TLS server profile`]: `TLS sunucu profilini düzenle`,
  [`Edit third party OAuth provider`]: `Üçüncü kişi OAuth sağlayıcısını düzenle`,
  [`Edit truststore`]: `Güvenli depoyu düzenle`,
  [`Edit unenforced API`]: `Zorunlu kılınmayan API'yi düzenle`,
  [`Edit user registries`]: `Kullanıcı kayıt defterlerini düzenle`,
  [`Edit user registry`]: `Kullanıcı kayıt defterini düzenle`,
  [`Edit user registry visibility`]: `Kullanıcı kayıt defteri görünürlüğünü düzenle`,
  [`Edit user registry for API Manager`]: `API Manager için kullanıcı kayıt defterini düzenle`,
  [`Edit user registry for Cloud Manager`]: `Cloud Manager için kullanıcı kayıt defterini düzenle`,
  [`Edit vanity API endpoints`]: `Özel API uç noktalarını düzenle`,
  [`Edit visibility`]: `Görünürlüğü düzenle`,
  [`Edit XML`]: `XML'i Düzenle`,
  [`Edit app lifecycle approved`]: `Uygulama yaşam çevrimini düzenleme onaylandı`,
  [`Edit app lifecycle cancelled`]: `Uygulama yaşam çevrimini düzenleme iptal edildi`,
  [`Edit app lifecycle denied`]: `Uygulama yaşam çevrimini düzenleme reddedildi`,
  [`Edit app lifecycle pending`]: `Uygulama yaşam çevrimini düzenleme beklemede`,
  [`Edit app lifecycle request`]: `Uygulama yaşam çevrimini düzenleme isteği`,
  [`Edit app reinstated`]: `Uygulamayı düzenleme yeniden başlatıldı`,
  [`Edit app suspended`]: `Uygulamayı düzenleme askıya alındı`,
  [`Edit audit setting`]: `Denetim ayarını düzenle`,
  [`Edit definitions`]: `Tanımları düzenle`,
  [`Enable external group mapping`]: `Dış grup eşlemesini etkinleştir`,
  [`Edit extra constraints for the GraphQL schema`]: `GraphQL şeması için ek kısıtlamaları düzenle`,
  [`Edit GraphQL schema|title`]: `GraphQL şemasını düzenle`,
  [`Edit inline schema`]: `Yerleşik şemayı düzenle`,
  [`Edit inputs`]: `Girişleri düzenle`,
  [`Edit invitation`]: `Daveti düzenle`,
  [`Edit mail server test connection`]: `Posta sunucusu test bağlantısını düzenle`,
  [`Edit member invitation`]: `Üye davetini düzenle`,
  [`Edit notification templates`]: `Bildirim şablonlarını düzenle`,
  [`Edit notification templates:`]: `Bildirim şablonlarını düzenleyin:`,
  [`Edit operation list`]: `İşlem listesini düzenle`,
  [`Edit or upload JSON schema`]: `JSON şemasını düzenle ya da karşıya yükle`,
  [`Edit outputs`]: `Çıkışları düzenle`,
  [`Edit password changed`]: `Değiştirilen parolayı düzenle`,
  [`Edit password reset`]: `Parola sıfırlamayı düzenle`,
  [`Edit plan APIs`]: `Plan API'lerini düzenle`,
  [`Edit schema`]: `Şemayı düzenle`,
  [`Edit schema|button`]: `Şemayı düzenle`,
  [`Edit schema|title`]: `Şemayı düzenle`,
  [`Edit settings for roles, notifications and more.`]: `Roller, bildirimler ve daha fazlası için ayarları düzenleyin.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Kullanıcı kayıt defterleri, roller, uç noktalar, vb. ayarlarını düzenle`,
  [`Edit sign up`]: `Kaydı düzenle`,
  [`Edit task product lifecycle approved`]: `Ürün yaşam çevrimini düzenleme görevi onaylandı`,
  [`Edit task product lifecycle cancelled`]: `Ürün yaşam çevrimini düzenleme görevi iptal edildi`,
  [`Edit task product lifecycle denied`]: `Ürün yaşam çevrimini düzenleme görevi reddedildi`,
  [`Edit task product lifecycle pending`]: `Ürün yaşam çevrimini düzenleme görevi beklemede`,
  [`Edit task product lifecycle request`]: `Ürün yaşam çevrimini düzenleme görevi isteği`,
  [`Edit task subscription approved`]: `Aboneliği düzenleme görevi onaylandı`,
  [`Edit task subscription cancelled`]: `Aboneliği düzenleme görevi iptal edildi`,
  [`Edit task subscription denied`]: `Aboneliği düzenleme görevi reddedildi`,
  [`Edit task subscription pending`]: `Aboneliği düzenleme görevi beklemede`,
  [`Edit task subscription request`]: `Aboneliği düzenleme görevi isteği`,
  [`Edit template`]: `Şablonu düzenle`,
  [`Edit the TLS client profile.`]: `TLS istemci profilini düzenleyin.`,
  [`Edit the TLS Server profiles`]: `TLS Sunucu profillerini düzenleyin`,
  [`Edit the keystore title.`]: `Anahtar deposu başlığını düzenleyin.`,
  [`Edit the organization summary details as required`]: `Kuruluş özeti ayrıntılarını gerektiği gibi düzenleyin`,
  [`Edit the organization title`]: `Kuruluş başlığını düzenle`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `E-posta sunucusunu yapılandırmak için parametreleri düzenleyin. İsteğe bağlı olarak atanmadıysa alanların doldurulması zorunludur.`,
  [`Edit signed token`]: `İmzalı belirteci düzenle`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `E-posta şablonu metnini düzenleyin. Değişkenler parantez içindedir ve düzenlenemez.`,
  [`Edit the truststore.`]: `Güvenli depoları düzenleyin.`,
  [`Edit the visibility for each resource.`]: `Her kaynak için görünürlüğü düzenleyin.`,
  [`Edit user details`]: `Kullanıcı ayrıntılarını düzenle`,
  [`Edit {notificationTemplate}`]: `Düzenle: {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `API'leri düzenleyin, derleyin, koruyun ve test edin`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `API'leri düzenleyin, derleyin, koruyun ve test edin. Tüketicilere yayınlamak için ürünleri kullanarak API'leri paketleyin.`,
  [`Edit, upload, or infer XML schema`]: `XML şemasını düzenle, karşıya yükle ya da çıkarsa`,
  [`Editors`]: `Düzenleyiciler`,
  [`Edit|permission`]: `Düzenle`,
  [`Edit API key timeout`]: `API anahtarı zaman aşımını düzenle`,
  [`Edit Base64 encoding for temporary tokens`]: `Geçici belirteçler için Base64 kodlamasını düzenle`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Ya "Tanıtıcı belirteci imza şifreleme nesnesi" ya da "Tanıtıcı belirteci imza anahtarı" belirtilmelidir.`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `"JWT doğrulama şifreleme nesnesi" veya "JWT doğrulama JWK'sı" sağlanmalıdır.`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `API'de tanımlı uç noktayı kullanma seçeneğini seçin veya varsayılan doğrulayıcı uç nokta sağlayın.`,
  [`Element name for JSON array elements`]: `JSON dizi öğeleri için öğe adı`,
  [`Email`]: `E-posta`,
  [`Email address`]: `E-posta adresi`,
  [`Email Content`]: `E-posta İçeriği`,
  [`Email endpoint`]: `E-posta uç noktası`,
  [`Email required`]: `E-posta gereklidir`,
  [`Email sender`]: `E-posta gönderen`,
  [`Email server`]: `E-posta sunucusu`,
  [`Email server configuration`]: `E-posta sunucusu yapılandırması`,
  [`Email servers`]: `E-posta sunucuları`,
  [`Empty case`]: `Boş büyük/küçük harf`,
  [`Empty catch`]: `Boş yakalama`,
  [`Empty JSON array handling`]: `Boş JSON dizisi işleme`,
  [`Empty XML element handling`]: `Boş XML öğesi işleme`,
  [`Enable`]: `Etkinleştir`,
  [`Enable API consumers to invite collaborators and assign roles`]: `API tüketicilerinin işbirliği yapılacak yeni kişileri davet etmelerine ve roller atamalarına olanak sağlayın`,
  [`Enable application lifecycle`]: `Uygulama yaşam çevrimini etkinleştir`,
  [`Enable billing`]: `Faturalamayı etkinleştir`,
  [`Enable gateway services`]: `Ağ geçidi hizmetlerini etkinleştir`,
  [`Enable gateway services and policies`]: `Ağ geçidi hizmetlerini ve ilkelerini etkinleştir`,
  [`Enable GraphiQL`]: `GraphiQL'i etkinleştir`,
  [`Enable GraphiQL editor`]: `GraphiQL düzenleyicisini etkinleştir`,
  [`Enable GraphiQL Editor on browser`]: `Tarayıcıda GraphiQL Düzenleyicisini etkinleştir`,
  [`Enable JSON post processing`]: `JSON son işlemesini etkinleştir`,
  [`Enable lifecycle approvals`]: `Yaşam çevrimi onaylarını etkinleştir`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Riskli isteklerin yeniden kullanılmasını (yeniden yürütme) önlemek için NONCE uzantısını etkinleştirin`,
  [`Enable OAuth providers`]: `OAuth Sağlayıcılarını etkinleştir`,
  [`Enable OIDC`]: `OIDC'yi etkinleştir`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Kimlik belirteçleri oluşturmak için OpenID Connect şablonunu etkinleştirin.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Genel istemcilerin, yetkilendirme kodunun engellenmiş olma tehdidini azaltmasına izin vermek için PKCE uzantısını etkinleştirin`,
  [`Enable production mode`]: `Üretim kipini etkinleştir`,
  [`Enable Proxy`]: `Yetkili Sunucuyu Etkinleştir`,
  [`Enable Self-Service Onboarding`]: `Self Servis Katılımı Etkinleştir`,
  [`Enable spaces`]: `Alanları etkinleştir`,
  [`Enable token management`]: `Belirteç yönetimini etkinleştir`,
  [`Enable buffering`]: `Arabelleğe almayı etkinleştir`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `API tüketicilerinin bir davet gerektirmeden geliştirici portalı hesapları yaratmasını sağlayın.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `API'niz için kökenler arası kaynak paylaşımı (CORS) erişim denetimini etkinleştirin.`,
  [`Enable debug response headers`]: `Hata ayıklama yanıt üstbilgilerini etkinleştir`,
  [`Enable proof key for code exchange`]: `Kod değişimi için kanıt anahtarını etkinleştir`,
  [`Enable publishing of this product`]: `Bu ürünün yayınlanmasını etkinleştir`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Portal OIDC uç noktası üzerinden üçüncü kişi yetkilendirme kodunun yeniden yönlendirmesini etkinleştirin`,
  [`Enable security`]: `Güvenliği etkinleştir`,
  [`Enable Self-Service Onboarding Approval`]: `Self-Service Katılım Onayını Etkinleştir`,
  [`Enable the following gateway services and policies for this catalog`]: `Bu katalog için şu ağ geçidi hizmetlerini ve ilkelerini etkinleştirin`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Kullanıcıların bir denetim olayı yapılandırmalarına olanak verin. Denetim olayı, kimlerin hangi olayı, ne zaman gerçekleştirdiklerini gösteren ayrıntılı bir özettir.`,
  [`Enable/disable custom notification templates`]: `Özel bildirim şablonlarını etkinleştir/devre dışı bırak`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Davetiyeler ve parola sıfırlama belirteçleri için Base64 kodlamasını etkinleştir ya da devre dışı bırak`,
  [`Enable overriding consumer organizations invitation timeout`]: `Tüketici kuruluşları davet zamanaşımını geçersiz kılmayı etkinleştir`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `Geçici belirteçler için Base64 kodlamasını etkinleştirebilir ya da geçersiz kılabilirsiniz. Geçici belirteçler davetler ve parola sıfırlama belirteçleridir. Base64 kodlaması etkinleştirildiğinde, geçici belirteç Base64 biçiminde kodlanır. Base64 kodlaması devre dışı bırakıldığında, geçici belirteç JSON Web Token (JWT) biçiminde kalır ve kullanıcının aldığı URL'nin uzunluğunun azaltıldığı anlamına gelir.`,
  [`Enabled`]: `Etkin`,
  [`enabled`]: `etkin`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `Uygulama yaşam çevriminin etkinleştirilmesi, uygulamaların geliştirme ya da üretim durumunda olmasını sağlar ve farklı ağ geçitleri ve uç noktalarına yönlendirilebilir.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Self servis katılım onayının etkinleştirilmesi için tüm katılım isteklerinin API sağlayıcı veya bir yönetici tarafından onaylanması gerekir.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Self servis katılımın etkinleştirilmesi, uygulama geliştiricisinin, API sağlayıcısından, var olan bir tüketici kuruluşu sahibinden ya da sistem yöneticisinden gelen bir davet olmadan kaydolmasına olanak sağlar.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `Etkinleştirme, tüm tüketici kuruluşları davet zamanaşımını katalog davet zamanaşımı olarak ayarlayacak.`,
  [`Encode Query params`]: `Sorgu parametrelerini kodla`,
  [`Encrypt`]: `Şifrele`,
  [`Encrypt Crypto Object`]: `Şifreleme Şifre Nesnesi`,
  [`Encrypt JWK variable name`]: `JWK şifreleme değişken adı`,
  [`Encrypt a buffer using the specified certificate`]: `Bir arabelleği, belirtilen sertifikayı kullanarak şifreleyin`,
  [`Encryption Algorithm`]: `Şifreleme Algoritması`,
  [`Endpoint`]: `Uç Nokta`,
  [`Endpoint URLs`]: `Uç nokta URL'leri`,
  [`Endpoint behavior`]: `Uç nokta davranışı`,
  [`Endpoint Behavior`]: `Uç Nokta Davranışı`,
  [`Endpoint for unenforced APIs`]: `Zorunlu kılınmayan API'ler için uç nokta`,
  [`Endpoints`]: `Uç Noktalar`,
  [`Enforce Required Params`]: `Gerekli Parametreleri Uygula`,
  [`Enforce the API by using API Connect Gateway.`]: `API Connect Gateway'i kullanarak API'yi zorunlu kılın.`,
  [`Enforced`]: `Zorunlu kılınan`,
  [`English`]: `İngilizce`,
  [`Enter Summary`]: `Özet Girin`,
  [`Enter URL`]: `URL'yi girin`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Kullanıcı kayıt defteri adını girin. Boşsa, varsayılan olarak API Manager Yerel Kullanıcı Kayıt Defteri kullanılır`,
  [`Enter a catalog name`]: `Bir katalog adı girin`,
  [`Enter a Display Name`]: `Görünen Ad girin`,
  [`Enter a space name`]: `Bir alan adı girin`,
  [`Enter a brief description`]: `Kısa bir açıklama girin`,
  [`Enter a display name`]: `Bir görünen ad girin`,
  [`Enter a temporary sign in password`]: `Geçici oturum açma parolası girin`,
  [`Enter an Organization Name`]: `Kuruluş Adı Girin`,
  [`Enter an Organization Title`]: `Kuruluş Başlığı Girin`,
  [`Enter catalog title`]: `Katalog başlığı girin`,
  [`Enter details of the consumer organization`]: `Tüketici kuruluşun ayrıntılarını girin`,
  [`Enter details of the product`]: `Ürünün ayrıntılarını girin`,
  [`Enter details of the provider organization`]: `Sağlayıcı kuruluşun ayrıntılarını girin`,
  [`Enter details of this API`]: `Bu API'nin ayrıntılarını girin`,
  [`Enter email of the new user`]: `Yeni kullanıcının e-posta adresini girin`,
  [`Enter first name of the new user`]: `Yeni kullanıcının adını girin`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Bir veya daha çok e-posta adresi girin. Birden çok adresi ayırmak için virgül kullanın.`,
  [`Enter organization name`]: `Kuruluş adını girin`,
  [`Enter organization title`]: `Kuruluş başlığını girin`,
  [`Enter password`]: `Parolayı girin`,
  [`Enter space title`]: `Alan başlığını girin`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Anasistem adını ve kapı numarasını sağlayarak ağ geçidine ilişkin API uç noktasını girin. Örneğin: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `API özeti ayrıntılarını girin`,
  [`Enter the API summary details. [Learn more]({link})`]: `API özeti ayrıntılarını girin. [Ek bilgi]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Yetkili sunucu eklemek istediğiniz hedef hizmetin URL'sini girin`,
  [`Enter the catalog summary details`]: `Katalog özeti ayrıntılarını girin`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Katalog özeti ayrıntılarını girin; yarattıktan sonra, kataloğu tümüyle yapılandırabilirsiniz.`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Yönetici kuruluş üyesi olarak davet edilecek kullanıcının e-posta adresini girin`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Katalog üyesi olarak davet edilecek kullanıcının e-posta adresini girin`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Sağlayıcı kuruluş üyesi olarak davet edilecek kullanıcının e-posta adresini girin`,
  [`Enter the email address of the user to invite as a member of the space`]: `Alanın bir üyesi olarak davet edilecek kullanıcının e-posta adresini girin`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Sahipliği devretmek istediğiniz kullanıcının e-posta adresini girin.`,
  [`Enter the full path to JSON or YAML file`]: `JSON ya da YAML dosyasının tam yolunu girin`,
  [`Enter the full path to WSDL file`]: `WSDL dosyasının tam yolunu girin`,
  [`Enter the full path to YAML file`]: `YAML dosyasının tam yolunu girin`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Kuruluş sırasında tanımladığınız Analytics alma uç noktası için tam etki alanı adını girin.`,
  [`Enter the last name of the new user`]: `Yeni kullanıcının soyadını girin`,
  [`Enter the owner's email address to send the invitation`]: `Daveti göndermek için sahibin e-posta adresini girin`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Ağ geçidi hizmetini yapılandırmak için parametreleri girin. İsteğe bağlı olarak atanmadıysa alanların doldurulması zorunludur. Uç nokta, DataPower'da API ağ geçidi hizmeti uç noktasıdır. API uç nokta tabanı, gelen API çağrıları için API ağ geçidi uç noktasıdır. SNI için tüm anasistemlere (gerekli) izin vermek için * karakterini, bazı anasistemlere izin vermek için de adlarını girin. Her anasistemi desteklemek için TLS profilini seçin.`,
  [`Enter the password for the certificate file if required.`]: `Gerekirse sertifika dosyası için parolayı girin.`,
  [`Enter the provider organization summary details`]: `Sağlayıcı kuruluş özet ayrıntılarını girin`,
  [`Enter the schema definitiom summary details`]: `Şema tanımı özeti ayrıntılarını girin`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Alan özeti ayrıntılarını girin; yarattıktan sonra Alanı tam olarak yapılandırabilirsiniz`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Alan özeti ayrıntılarını girin; yarattıktan sonra, alanı tümüyle yapılandırabilirsiniz.`,
  [`Enter the summary details for the new product`]: `Yeni ürün için özet ayrıntıları girin`,
  [`Enter the summary details for this role`]: `Bu rol için özet ayrıntılarını girin`,
  [`Enter title of the organization`]: `Kuruluşun başlığını girin`,
  [`Enter user email`]: `Kullanıcı e-postası gir`,
  [`Enter username of the existing user`]: `Var olan kullanıcının kullanıcı adını girin`,
  [`Enter username of the new user`]: `Yeni kullanıcının kullanıcı adını girin`,
  [`Error`]: `Hata`,
  [`Error ID`]: `Hata tanıtıcısı`,
  [`Error:`]: `Hata:`,
  [`Copy error ID to Clipboard`]: `Panoya hata tanıtıcısını kopyala`,
  [`Error Console`]: `Hata Konsolu`,
  [`Error connecting`]: `Bağlantı kurulurken hata oluştu`,
  [`Error connecting to GraphQL server`]: `GraphQL sunucusuna bağlanılırken hata oluştu`,
  [`Error getting user info during login. Please login with different user.`]: `Oturum açma sırasında kullanıcı bilgileri alınırken hata oluştu. Lütfen farklı bir kullanıcıyla oturum açın.`,
  [`Error content`]: `Hata içeriği`,
  [`Error message`]: `Hata iletisi`,
  [`Error name`]: `Hata adı`,
  [`Error status code`]: `Hata durum kodu`,
  [`Error status code is invalid`]: `Hata durum kodu geçersiz`,
  [`Error status reason`]: `Hata durumu nedeni`,
  [`Errors`]: `Hatalar`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Hatalar genellikle yanlış bilgilerden ve yanlış karakterden kaynaklanır. Lütfen testten önce doğru sağlayıcı bilgilerini ve istemci bilgilerini sağladığınızdan emin olun.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `{url} URL'sinden API almaya çalışan hata. URL adresini, kullanıcı adını ve parolayı denetle. Hata iletisi: {message}`,
  [`Event`]: `Olay`,
  [`Event gateway management client TLS client profile`]: `Olay ağ geçidi yönetim istemcisi TLS istemci profili`,
  [`Event load`]: `Olay yükü`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Her tüketici kuruluşunun bir sahibi vardır. Sahibin tam yönetim izinleri vardır ve abonelikleri ve uygulamaları yönetebilir.`,
  [`Exchange authorization code for tokens`]: `Belirteçler için değişim yetki kodu`,
  [`Exchange code for token`]: `Belirteç için değişim kodu`,
  [`Execute`]: `Yürüt`,
  [`Execute migration target`]: `Geçiş hedefini yürüt`,
  [`Executes any catalog-level post-invoke handlers`]: `Katalog düzeyindeki çağırma sonrası işleyicilerini yürütür`,
  [`Executes any catalog-level pre-invoke handlers`]: `Katalog düzeyindeki çağırma öncesi işleyicilerini yürütür`,
  [`Existing`]: `Var olan`,
  [`Existing {docType}`]: `Var olan {docType}`,
  [`Existing API Connect user`]: `Var olan API Connect kullanıcısı`,
  [`Existing OpenAPI`]: `Var olan OpenAPI`,
  [`Existing product`]: `Var olan ürün`,
  [`Existing SOAP API has been validated`]: `Var olan SOAP API'si doğrulandı`,
  [`Existing products in the catalog will be moved to a default space.`]: `Katalogdaki var olan ürünler varsayılan bir alana taşınır.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Var olan kullanıcılar geçerli kullanıcılardır. Başka bir kullanıcı kayıt defterinden yeni bir üye etkinleştirebilirsiniz.`,
  [`Expires On`]: `Süre Sonu`,
  [`Expiry`]: `Son kullanma tarihi`,
  [`Explorer`]: `Gezgin`,
  [`Expose`]: `Göster`,
  [`Extend the schema to specify the cost of types and fields`]: `Alanların ve tiplerin maliyetini belirtmek için şemayı genişletin`,
  [`Extended`]: `Uzatıldı`,
  [`Extension Type:`]: `Uzantı Tipi:`,
  [`Extensions`]: `Uzantılar`,
  [`Extension has been uploaded.`]: `Uzantı karşıya yüklendi.`,
  [`External`]: `Dış`,
  [`External Doc Description`]: `Dış Belge Açıklaması`,
  [`External doc description`]: `Dış Belge Açıklaması`,
  [`External Documentation URL`]: `Dış Belge URL Adresi`,
  [`External documentation URL`]: `Dış Belge URL Adresi`,
  [`External Role mapping`]: `Harici Rol eşlemesi`,
  [`External URL`]: `Dış URL`,
  [`Extract Identity Settings`]: `Kimlik Ayarlarını Çıkar`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `Faktör Tanıtıcısı`,
  [`Fail`]: `Başarısız`,
  [`Failed to change account password.`]: `Hesap parolası değiştirilemedi.`,
  [`Failed to create analytics service {title}.`]: `{title} analitik hizmeti yaratılamadı.`,
  [`Failed to create analytics insights service {title}.`]: `{title} analitik öngörüler hizmeti yaratılamadı.`,
  [`Analytics insights service {title} has been updated.`]: `{title} analitik öngörüler hizmeti güncellendi.`,
  [`About the analytics insights service`]: `Analitik öngörüler hizmeti hakkında`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Analitik öngörüler hizmeti, ağ geçidi hizmetinden API olaylarını toplar. Her ağ geçidi hizmeti, ilişkili bir analitik öngörüler hizmetine sahip olabilir.`,
  [`Advanced analytics insights configuration`]: `Gelişmiş analitik öngörüler yapılandırması`,
  [`Analytics insights service {title} has been created.`]: `{title} analitik öngörüler hizmeti yaratıldı.`,
  [`Analytics Insights details`]: `Analitik Öngörüler ayrıntıları`,
  [`Management endpoint on the analytics insights service`]: `Analitik öngörüler hizmetindeki yönetim uç noktası`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Kurulum sırasında tanımladığınız Analitik Öngörüler yönelticisi uç noktası için tam olarak nitelenmiş etki alanı adını girin.`,
  [`TYPE`]: `TİP`,
  [`Failed to create email server`]: `E-posta sunucusu oluşturulamadı`,
  [`Failed to create gateway service`]: `Ağ geçidi hizmeti oluşturulamadı`,
  [`Failed to create gateway service {name}.`]: `{name} ağ geçidi hizmeti oluşturulamadı.`,
  [`Failed to create user registry {title}.`]: `{title} kullanıcı kaydı oluşturulamadı.`,
  [`Failed to delete a group`]: `Bir grup silinemedi.`,
  [`Failed to get TLS client profiles`]: `TLS istemci profilleri alınamadı`,
  [`Failed to get catalogs`]: `Kataloglar alınamadı`,
  [`Failed to get configured OAuth providers`]: `Yapılandırılan OAuth sağlayıcıları alınamadı`,
  [`Failed to get policies of gateways`]: `Ağ geçidi ilkeleri alınamadı`,
  [`Failed to get user registries`]: `Kullanıcı kayıt defterleri alınamadı`,
  [`Failed to introspect schema from server:`]: `Sunucudan şema iç gözlemi yapılamadı:`,
  [`Failed to make API online`]: `API çevrimiçi yapılamadı`,
  [`Failed to register catalog member {user}.`]: `{user} adlı katalog üyesi kaydedilemedi.`,
  [`Failed to remove analytics service: {arg}`]: `Analitik hizmeti kaldırılamadı: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Kullanılabilirlik bölgesi kaldırılamadı: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Ağ geçidi hizmeti kaldırılamadı: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Posta sunucusu kaldırılamadı: {arg}`,
  [`Failed to remove portal service: {arg}`]: `Portal hizmeti kaldırılamadı: {arg}`,
  [`Failed to create {name}.`]: `{name} oluşturulamadı.`,
  [`Failed to sign out! Please check the console for details.`]: `Oturum kapatılamadı! Lütfen ayrıntılar için konsola göz atın.`,
  [`Failed to update {name}.`]: `{name} güncellenemedi.`,
  [`Failed to update OAuth provider`]: `OAuth sağlayıcısı güncellenemedi`,
  [`Failed to update account.`]: `Hesap güncellenemedi.`,
  [`Failed to update analytics service {title}.`]: `{title} analitik hizmeti güncellenemedi.`,
  [`Failed to update gateway service {title}.`]: `{title} ağ geçidi hizmeti güncellenemedi.`,
  [`Failed to update task`]: `Görev güncelleştirilemedi`,
  [`Failed to create app {title}`]: `Uygulama {title} oluşturulamadı`,
  [`Feature flags`]: `Özellik işaretleri`,
  [`Fetch schema`]: `Şemayı getir`,
  [`Fetched endpoints`]: `Getirilen uç noktalar`,
  [`Field`]: `Alan`,
  [`Field label`]: `Alan etiketi`,
  [`Field Mapping`]: `Alan Eşleme`,
  [`Field name`]: `Alan adı`,
  [`Field weight`]: `Alan ağırlığı`,
  [`File Name`]: `Dosya Adı`,
  [`File Preview`]: `Dosya Önizlemesi`,
  [`File contains validation errors`]: `Dosya doğrulama hataları içeriyor`,
  [`File is not a valid OpenAPI definition`]: `Dosya geçerli bir OpenAPI tanımı değil`,
  [`File is not a valid extension format.`]: `Dosya geçerli bir uzantı biçimi değil.`,
  [`File is not a valid {fileType} format.`]: `Dosya geçerli bir {fileType} biçimi değil.`,
  [`File name`]: `Dosya adı`,
  [`File upload`]: `Dosya karşıya yükleme`,
  [`File uploaded is not a valid file`]: `Karşıya yüklenen dosya geçerli bir dosya değil`,
  [`File uploaded successfully`]: `Dosya başarıyla karşıya yüklendi`,
  [`File was updated and validated!`]: `Dosya güncellendi ve doğrulandı!`,
  [`File was uploaded and validated!`]: `Dosya karşıya yüklendi ve doğrulandı!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `Dosya {draftFilePath} var. Taslağın ad/sürüm birleşimini değiştirmeyi deneyin`,
  [`Fill out the details of this service`]: `Bu hizmetin ayrıntılarını doldurun`,
  [`Filter`]: `Süzgeç Uygula`,
  [`Filter by operation`]: `İşleme göre süzgeç uygula`,
  [`Filter policies`]: `Süzgeç ilkeleri`,
  [`Final Action`]: `Son İşlem`,
  [`Find existing user`]: `Var olan kullanıcıyı bul`,
  [`Find expert answers in the API Connect community forum`]: `API Connect topluluk forumunda uzman yanıtlarını bulun`,
  [`Find expert answers in the API Connect community forum.`]: `API Connect topluluk forumunda uzman yanıtlarını bulun.`,
  [`Find schema`]: `Şema bul`,
  [`Finger Print`]: `Parmak İzi`,
  [`Fingerprint SHA-1`]: `Parmak izi SHA-1`,
  [`Fingerprint SHA-256`]: `Parmak izi SHA-256`,
  [`Finish`]: `Son`,
  [`First name`]: `Ad`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Öncelikle API trafiği için ağ geçidinin kullandığı uç nokta URL'sini belirtin. Değer API çağrılarınıza özgü ek yollar içeren, genel, tam bir etki alanı adı olmalıdır, örneğin: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Öncelikle ağ geçidi yönetim uç noktasının URL'sini belirtin. Kubernetes ortamlarında çalışan ağ geçitleri için değer, ağ geçidi ayarlama komutu grubu gwy apic-gw-service aracılığıyla belirtilen uç noktayla eşleşmelidir. Ağ geçidi aygıtları için değer http://<ip-address-for-gateway>:3000 biçiminde olmalıdır. Bir ağ geçidi kümesiyle çalışırken yük dengeleyicinin adres:kapı değerini belirtin.`,
  [`Fit to screen`]: `Ekrana sığdır`,
  [`Flow`]: `Akış`,
  [`Follow redirects`]: `Yeniden yönlendirmeleri izle`,
  [`Found In`]: `Şurada bulundu`,
  [`Fonts contained in the following file are available under the`]: `Aşağıdaki dosyada bulunan yazı tipleri şu kapsamda kullanılabilir:`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `WSDL tanımları için hem WSDL dosyası hem API tanımını (YAML) içeren bir zip dosyasını karşıya yükleyin`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Orijinal üründeki her plan için, abonelikleri taşımak istediğiniz yeni üründeki planı seçin`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Kaynak üründeki her plan için, abonelikleri taşımak istediğiniz hedef üründeki planı seçin`,
  [`For guidance on the available command set, access the CLI's help`]: `Kullanılabilir komut kümesine ilişkin yönergeler için CLI'nin yardımına erişin`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Yerel OAuth sağlayıcılarını kullanan siteler için isteğe bağlı olarak tüm API çağrılarının kullandığı paylaşılan güvenlik dizgisini belirtin.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Yazılabilir LDAP için, Kullanıcı tarafından yönetilen'i seçin ve kaynak LDAP kaydı öznitelik adlarınızın API Connect LDAP öznitelik adlarıyla eşlemesini ekleyin. API Connect'in kullanıcı kaydı sırasında gerektirdiği varsayılan kullanıcı profili özellikleri kullanıcı adı, ad, soyad, e-posta ve paroladır. Örnek bir eşleme yapılandırması sağlanır; eşlemeyi gereksinimlerinize göre düzenleyin.`,
  [`Forbidden`]: `Yasak`,
  [`Forget token`]: `Belirteci unut`,
  [`Forgot password?`]: `Parolanızı mı unuttunuz?`,
  [`Forgot password`]: `Parolayı unuttum`,
  [`Form`]: `Form`,
  [`Forum`]: `Forum`,
  [`French`]: `Fransızca`,
  [`From File`]: `Dosyadan`,
  [`From Sample JSON`]: `Örnek JSON'dan`,
  [`From Sample XML`]: `Örnek XML'den`,
  [`From URL`]: `URL'den`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Var olan GraphQL hizmetinden (GraphQL yetkili sunucu)`,
  [`From existing OpenAPI service`]: `Var olan OpenAPI hizmetinden`,
  [`From existing WSDL service (REST proxy)`]: `Var olan WSDL hizmetinden (REST yetkili sunucusu)`,
  [`From existing WSDL service (SOAP proxy)`]: `Var olan WSDL hizmetinden (SOAP yetkili sunucusu)`,
  [`From target service`]: `Hedef hizmetten`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `SOAP hizmeti için WSDL tanımındaki işlemlerden API'nizde kullanmak istediklerinizi seçin ve yöntemleri ve URL yollarını belirtin.`,
  [`Gateway Extensions`]: `Ağ Geçidi Uzantıları`,
  [`Gateway extension`]: `Ağ geçidi uzantısı`,
  [`Gateway Services`]: `Ağ Geçidi Hizmetleri`,
  [`Gateway Type`]: `Ağ Geçidi Tipi`,
  [`Gateway by Name`]: `Ada Göre Ağ Geçidi`,
  [`Gateway services`]: `Ağ geçidi hizmetleri`,
  [`Gateway type`]: `Ağ geçidi tipi`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL v2 lisansı`,
  [`GROUP`]: `GRUP`,
  [`Group name`]: `Grup adı`,
  [`Gateway`]: `Ağ Geçidi`,
  [`Gateways`]: `Ağ Geçitleri`,
  [`Gateway details`]: `Ağ geçidi ayrıntıları`,
  [`Gateway Details`]: `Ağ Geçidi Ayrıntıları`,
  [`Gateway Error`]: `Ağ Geçicidi Hatası`,
  [`Gateway service details`]: `Gateway hizmeti ayrıntıları`,
  [`Gateway management client TLS client profile`]: `Ağ geçidi yönetimi istemcisi TLS istemci profili`,
  [`Gateway management client keystore`]: `Ağ geçidi yönetim istemcisi anahtar deposu`,
  [`Gateway management client truststore`]: `Ağ geçidi yönetim istemcisi güvenli deposu`,
  [`Gateway processing status`]: `Ağ geçidi işleme durumu`,
  [`Gateway policies (TODO)`]: `Ağ geçidi ilkeleri (TODO)`,
  [`Gateway service`]: `Ağ geçidi hizmeti`,
  [`Gateway Service US East (TODO)`]: `Ağ Geçidi Hizmeti ABD Doğu (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Ağ Geçidi Hizmeti {arg} kaldırıldı.`,
  [`Gateway service configuration`]: `Ağ geçidi hizmeti yapılandırması`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `Ağ geçidi hizmeti anasistemi kullanılamıyor. Explorer'da API Test(Try it) etkinleştirilmeyecek.`,
  [`Gateway service configuration has been updated.`]: `Ağ geçidi hizmet yapılandırması güncellendi.`,
  [`Gateway service {title} has been created.`]: `{title} ağ geçidi hizmeti oluşturuldu.`,
  [`Gateway service {title} has been updated.`]: `{title} ağ geçidi hizmeti güncellendi.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Alanlar etkinleştirildiğinde ağ geçidi hizmetleri, her bir alanda yönetilir. Lütfen ağ geçidi hizmetlerini yönetmek için alan ayarlarına gidin.`,
  [`Gateway version`]: `Ağ geçidi sürümü`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Sayfa başına ağ geçitleri`,
  [`Gather-and-send`]: `Topla ve gönder`,
  [`Gather-only`]: `Yalnızca topla`,
  [`General configuration`]: `Genel yapılandırma`,
  [`General information about the API.`]: `API ile ilgili genel bilgiler.`,
  [`Generate`]: `Oluştur`,
  [`Generate an OpenAPI definition for the proxy`]: `Yetkili sunucu için bir OpenAPI tanımı oluştur`,
  [`Generate JSON Web Token (JWT)`]: `JSON Web Belirteci (JWT) Oluştur`,
  [`Generate JWT`]: `JWT Oluştur`,
  [`Generate LTPA Token`]: `LTPA Belirteci Oluştur`,
  [`Generate OAuth Access Tokens`]: `OAuth Erişim Belirteçleri Oluştur`,
  [`Generate OAuth access tokens`]: `OAuth erişim belirteçleri oluştur`,
  [`Generate a default product`]: `Varsayılan ürün oluştur`,
  [`Generate access token`]: `Erişim belirteci oluştur`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `API'nizin WebSphere Uygulama Sunucusu web sunucuları ile güvenli bir şekilde kimlik doğrulaması yapabilmesi için bir LTPA belirteci oluşturun.`,
  [`Generate an object`]: `Nesne oluştur`,
  [`Generate authorization code`]: `Yetkilendirme kodu oluştur`,
  [`Generate from sample JSON`]: `Örnek JSON'dan oluştur`,
  [`Generate from sample XML`]: `Örnek XML'den oluştur`,
  [`Generate tests automatically to build high quality APIs`]: `Yüksek kaliteli API'ler oluşturmak için testleri otomatik olarak oluşturun`,
  [`Generated OpenAPI 2.0 definition`]: `Oluşturulan OpenAPI 2.0 tanımı`,
  [`Generated default product for the API`]: `API için varsayılan ürün oluşturuldu`,
  [`Generated name for use in commands and API calls`]: `Komutlarda ve API çağrılarında kullanılmak üzere oluşturulan ad`,
  [`German`]: `Almanca`,
  [`Get Base DN`]: `Temel Ayrıt Edici Adı Al`,
  [`Get Code`]: `Kodu Al`,
  [`Get Code Snippet`]: `Kod Parçacığı Al`,
  [`Get Support`]: `Destek Al`,
  [`Get Token`]: `Belirteci Al`,
  [`Get activation link`]: `Etkinleştirme bağlantısını al`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Bir ağ geçidi hizmeti kaydederek kullanmaya başlayın. Bu, diğer hizmetleri yapılandırırken uç noktaları test etmenizi sağlar. Bir hizmeti kaydettirdiğinizde, burada görürsünüz. [Ek bilgi]({link})`,
  [`Get subscription ID`]: `Abonelik tanıtıcısını al`,
  [`Getting Started`]: `Başlarken`,
  [`Getting started`]: `Çalışmaya başlama`,
  [`Git information`]: `Git bilgileri`,
  [`Github`]: `Github`,
  [`Global policies`]: `Genel ilkeler`,
  [`Go to Spaces`]: `Alanlara Git`,
  [`Go to the Test tab`]: `Test sekmesine git`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL hız sınırları`,
  [`GraphQL service`]: `GraphQL hizmeti`,
  [`GraphQL schema`]: `GraphQL şeması`,
  [`GraphQL Schema`]: `GraphQL Şeması`,
  [`GraphQL schema has been successfully validated.`]: `GraphQL şeması başarıyla doğrulandı.`,
  [`GraphQL Schema URL`]: `GraphQL Şeması URL'si`,
  [`GraphQL send type`]: `GraphQL gönderme tipi`,
  [`GraphQL Websocket Upgrade`]: `GraphQL Websocket Yükseltmesi`,
  [`Group Based DN`]: `Grup Tabanlı Ayırt Edici Ad`,
  [`Group {title} added`]: `{title} grubu eklendi`,
  [`Group {title} updated`]: `{title} grubu güncellendi`,
  [`Group {title} has been deleted`]: `{title} grubu silindi`,
  [`Groups`]: `Gruplar`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `ANASİSTEM ADI`,
  [`HOST URL`]: `ANASİSTEM URL'Sİ`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML Formu`,
  [`HTML form time limit`]: `HTML formu zaman sınırı`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 Gerekli`,
  [`HTTP Header`]: `HTTP Üstbilgisi`,
  [`HTTP Method`]: `HTTP Yöntemi`,
  [`HTTP Type`]: `HTTP Tipi`,
  [`HTTP Version`]: `HTTP Sürümü`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Tutamak çubukları`,
  [`Hard limit`]: `Sabit sınır`,
  [`Header`]: `Üstbilgi`,
  [`Header control`]: `Üstbilgi denetimi`,
  [`Header name payload`]: `Başlık adı bilgi yükü`,
  [`Header name token`]: `Başlık adı belirteci`,
  [`Headers`]: `Üstbilgiler`,
  [`Hello`]: `Merhaba`,
  [`Hello!`]: `Merhaba!`,
  [`Hello [IBM](url)`]: `Merhaba [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Merhaba {name}`,
  [`Hello {name}!`]: `Merhaba {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Merhaba {name}, [IBM profiline bakın](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{catalogTitle} geliştirici portalında bir hesap için kaydolduğunuz için teşekkür ederiz.\n\nKaydınızı tamamlamak için bu bağlantıyı kullanın:\n\n{link}}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{consumerOrg} geliştirici portalı yöneticisi, \n{appName} uygulamasını yeniden başlattı. Bu uygulamasından API çağrıları artık\nAPI ağ geçidi tarafından engellenmeyecek.\n\nBu bağlantıyı kullanarak uygulama ayrıntılarını görüntüleyebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{consumerOrg} geliştirici portalı yöneticisi, {appName} uygulamasını askıya aldı. \n Bu uygulamadan gelen API çağrıları API ağ geçidi tarafından engellenecek.\n\nBu bağlantıyı kullanarak uygulama ayrıntılarını görüntüleyebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{originator} adlı kullanıcı, {catalog} kataloğundaki {productName} API ürününün {productVersion} sürümü için {planName} planına\nabone olmak üzere onay istedi.\n\nBu bağlantıyı kullanarak görev listenizde isteği açabilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{originator} adlı kullanıcı, {appName} uygulamasıyla {catalog} kataloğundaki {productName} API ürününün {productVersion} sürümü için {planName} planına\nabone olmak üzere onay\nistedi.\n\nBu bağlantıyı kullanarak görev listenizde isteği açabilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{originator} adlı kullanıcı, {catalog} kataloğundaki {appName} uygulaması için {action} onayı\nistedi.\n\nBu bağlantıyı kullanarak görev listenizde isteği açabilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{originator} adlı kullanıcı, {catalog} kataloğunda {productName} API ürününün {version}\n sürümü için {action} onayı istedi.\n\nBu bağlantıyı kullanarak görev listenizde isteği açabilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Merhaba {{firstName}},\n\n {{catalog}} geliştirici portalında {{username}}kullanıcı hesabınız için\n  parolayı sıfırlama isteği aldık.\n\nİstekte bulunmadıysanız bu iletiyi yoksayın. Tersi durumda, şu bağlantıyı kullanarak\n parolanızı sıfırlayabilirsiniz: \n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Merhaba {firstName},\n\nIBM API Connect'te\n{username} kullanıcı hesabınız için parolayı sıfırlama isteği aldık.\n\nParolanızı sıfırlamak için bu bağlantıyı kullanın:\n\n{link}\n\n(Siz istekte bulunmadıysanız bu iletiyi yoksayabilirsiniz.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n {catalog} geliştirici portalındaki\n{username} kullanıcı hesabınız için parola sıfırlama isteği aldık.\n\nİstekte bulunmadıysanız bu iletiyi yoksayın. İstekte bulunduysanız, bu bağlantıyı\n kullanarak parolanızı sıfırlayabilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Merhaba {firstName},\n\n{catalog} kataloğunda {appName} uygulaması için\nabonelik yaratma isteğiniz onay bekliyor. İsteğiniz işlendiğinde bir bildirim daha\ngöndereceğiz.\n\nUygulamanızın durumunu buradan kontrol edebilirsiniz:\n\n{link}\n\nİsteği iptal etmek isterseniz bu bağlantıyı kullanın:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Merhaba {{firstName}},\n\n{{productName}} API ürünü\n{{productVersion}} sürümüne ilişkin {{appName}} uygulaması için\n{{planName}} planına abonelik yaratma isteğiniz onaylandı.\n\n{{#if comments}}\nOnaylayan aşağıdaki yorumları ekledi:\n\n"{{comments}}"\n\n{{/if}}\nUygulamanın durumunu buradan kontrol edebilirsiniz:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{productName} API ürünü\n{productVersion} sürümüne ilişkin {appName} uygulaması için\n{planName} planına abonelik yaratma isteğiniz onaylandı.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n{comments}\n\n{/if}\nUygulamanın durumunu buradan kontrol edebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Merhaba {{firstName}},\n\n{{productName}} API ürünü\n{{productVersion}} sürümüne ilişkin {{appName}} uygulaması için\n{{planName}} planına abonelik yaratma isteğiniz reddedildi.\n\n{{#if comments}}\nOnaylayan aşağıdaki yorumları ekledi:\n\n"{{comments}}"\n\n{{/if}}\nUygulamanın durumunu buradan kontrol edebilirsiniz:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Merhaba {{firstName}},\n\n{{catalog}} kataloğundaki {{productName}}:{{version}} API ürünü için \n {{action}} isteğiniz onaylandı.\n\n{{#if comments}}\nOnaylayan aşağıdaki yorumları ekledi: \n\n{{comments}}\n\n{{/if}}\nÜrünün durumunu buradan kontrol edebilirsiniz: \n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{catalog} kataloğundaki {productName}:{version} API ürünü için {action}\nisteğiniz onaylandı.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n{comments}\n\n{/if}\nÜrünün durumunu buradan kontrol edebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Merhaba {firstName}},\n\n{{catalog}} kataloğundaki {productName}}:{version}} API ürünü için \n {{action}} isteğiniz reddedildi.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n{comments}\n\n{/if}\nÜrünün durumunu buradan kontrol edebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Merhaba {firstName}},\n\n{{catalog}} kataloğundaki {productName}}:{version}} API ürünü için \n {{action}} isteğiniz reddedildi.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n{comments}\n\n{/if}\nÜrünün durumunu buradan kontrol edebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Merhaba {firstName},\n\n{catalog} kataloğundaki {productName}:{version} API ürünü için {action}\nisteğiniz onay bekliyor. İsteğiniz işlendiğinde bir bildirim daha\ngöndereceğiz.\n\nÜrünün durumunu buradan kontrol edebilirsiniz:\n\n{link}\n\nİsteği iptal etmek isterseniz bu bağlantıyı kullanın:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{consumerOrg} geliştirici portalında {appName} uygulaması için {action} isteğiniz\nonaylandı.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n"{comments}"\n\n{/if}\nUygulamanın durumunu buradan kontrol edebilirsiniz:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{consumerOrg} geliştirici portalında {appName} uygulaması için {action} isteğiniz\nonaylandı.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n{comments}\n\n{/if}\nUygulamanın durumunu buradan kontrol edebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{consumerOrg} geliştirici portalında {appName} uygulaması için {action} isteğiniz\nreddedildi.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n"{comments}"\n\n{/if}\nUygulamanın durumunu buradan kontrol edebilirsiniz:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{consumerOrg} geliştirici portalında {appName} uygulaması için {action} isteğiniz\nreddedildi.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n{comments}\n\n{/if}\nUygulamanın durumunu buradan kontrol edebilirsiniz: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Merhaba {firstName},\n\n{consumerOrg}\ngeliştirici portalında {appName} uygulaması için {action} isteğiniz onay bekliyor. İsteğiniz işlendiğinde bir bildirim daha\ngöndereceğiz.\n\nİsteği iptal etmek isterseniz bu bağlantıyı kullanın:\n\n{link}}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\nIBM API Connect hesap yöneticisi sizi bir\nAPI sağlayıcı kuruluşu yaratmaya davet etti. Bir sağlayıcı kuruluş sizin ve ekibinizin API geliştirmesini\nve API ürünleri kataloglarını yönetmesini sağlar.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\nIBM API Connect'te {org} API sağlayıcı kuruluşuna ait {catalog} API kataloğu\nyöneticisi sizi\nkatalogda bir alan yaratmaya davet etti. Bir alan, sizin ve\nekibinizin API kataloğunda API ürünlerini yönetmenizi sağlar.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\nIBM API Connect'te {org} API sağlayıcı kuruluşuna ait {catalog} API kataloğu\nyöneticisi sizi\nkataloğa davet etti.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\n{catalog} geliştirici portalı yöneticisi, sizi\nbir API tüketici kuruluşu yaratmaya davet etti. Bir tüketici kuruluş\nsizin ve ekibinizin API'lere erişmenizi ve istemci uygulamalarını kaydetmenizi sağlar.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\n{catalog} geliştirici portalındaki {consumerOrg} API tüketici kuruluşun yöneticisi\nsizi kuruluşa davet etti.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\nIBM API Connect'te {org} API sağlayıcı kuruluş yöneticisi\nsizi bir API kataloğu yaratmaya davet etti. Bir katalog, sizin ve ekibinizin\nAPI ürünleri yayınlamanızı ve yönetmenizi sağlar.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\nIBM API Connect'te {org} API sağlayıcı kuruluş yöneticisi\nsizi kuruluşa davet etti.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\nIBM API Connect'te {org} API sağlayıcı kuruluşa ait {catalog} API kataloğundaki {space} alanı\nyöneticisi, sizi\nalana davet etti.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Merhaba,\n\nIBM API Connect'te {username} kullanıcı\nhesabınızın parolası başarıyla değiştirildi.\n \nAPI Connect'te buradan oturum açabilirsiniz: \n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Merhaba,\n\n{portalTitle} geliştirici portalındaki {username} kullanıcı\nhesabınızın parolası değişti.\n\n{portalTitle} geliştirici portalında buradan oturum açabilirsiniz:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Merhaba,\n\nIBM API Connect sistem yöneticisi sizi\n{org} kuruluşuna davet etti.\n\nDevam etmek için lütfen aşağıdaki bağlantıyı kullanın:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Merhaba,\n\nBu, yapılandırılan\n{mailServer} posta sunucusundan IBM API Connect'ten bir test iletisidir.\n\nBu iletiyi beklendiği gibi aldıysanız test başarılıdır!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Merhaba,\n\nKullanıcı {originator}, {catalog} kataloğunda {productName} API ürününün {productVersion} sürümü için\n{planName} planı için abonelik isteğini geri çekti.\n\nBaşka işlem gerekmiyor.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Merhaba,\n\nKullanıcı {originator}, {catalog} kataloğunda {appName} uygulaması için {action} isteğini geri çekti.\n\nBaşka işlem gerekmiyor.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Merhaba,\n\nKullanıcı {originator}, {catalog} kataloğunda {productName}\nAPI ürününün {version} sürümü için {action} isteğini geri çekti.\n\nBaşka işlem gerekmiyor.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Merhaba. Bu, yapılandırılan {{{mailServer}}} posta sunucusundaki IBM API Connect'ten gönderilen bir test iletisidir. Bu iletiyi beklendiği gibi aldıysanız test başarılı olmuştur!`,
  [`Hello|header`]: `Merhaba`,
  [`Help`]: `Yardım`,
  [`Hi, Steve (todo)`]: `Merhaba, Murat Bey (yapılacak iş)`,
  [`Hide`]: `Gizle`,
  [`Hide field`]: `Alanı gizle`,
  [`Hide in schema`]: `Şemada gizle`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Yeni tipleri, alanları, yönergeleri veya isteğe bağlı bağımsız değişkenleri gizleyin. Arka uç tarafından artık desteklenmeyen öğeler kaldırılacak.`,
  [`High`]: `Yüksek`,
  [`History`]: `Geçmiş`,
  [`Histories`]: `Geçmişler`,
  [`History log`]: `Geçmiş günlüğü`,
  [`Home`]: `Ana Sayfa`,
  [`Host`]: `Sunucu`,
  [`Host context variable`]: `Anasistem bağlam değişkeni`,
  [`Host name`]: `Anasistem adı`,
  [`Host value (if present)`]: `Anasistem değeri (varsa)`,
  [`Hostname`]: `Anasistem Adı`,
  [`Hours`]: `Saat`,
  [`Http basic authentication schema`]: `Http temel kimlik doğrulama şeması`,
  [`Hmm, your search didn't return any results.`]: `Hmm, aramanız herhangi bir sonuç döndürmedi.`,
  [`HTTP(s)`]: `HTTP(ler)`,
  [`IBM API Connect cloud`]: `IBM API Connect bulutu`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud Kimlik Doğrulaması`,
  [`IBM Cloud Docs`]: `IBM Cloud Docs`,
  [`IBM Schema`]: `IBM Schema`,
  [`IBM managed`]: `IBM tarafından yönetilen`,
  [`ID Name`]: `Tanıtıcı Adı`,
  [`ID token custom claims`]: `Tanıtıcı belirteci özel talepleri`,
  [`ID token issuer`]: `Tanıtıcı belirteci yayıncısı`,
  [`ID token signing algorithm`]: `Tanıtıcı belirteci imza algoritması`,
  [`ID token signing crypto object`]: `Tanıtıcı belirteci imza şifreleme nesnesi`,
  [`ID token signing key`]: `Tanıtıcı belirteci imzalama anahtarı`,
  [`ID token signing key identifier`]: `Tanıtıcı belirteci anahtar tanıtıcısı`,
  [`IDENTITY PROVIDER`]: `KİMLİK SAĞLAYICI`,
  [`IDENTITY PROVIDER SUPPORT`]: `KİMLİK SAĞLAYICI DESTEĞİ`,
  [`Identification`]: `Tanıtıcı`,
  [`Identity extraction`]: `Kimlik çıkarma`,
  [`If`]: `Koşul`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Üretim kipi devre dışı bırakılmışsa (bunun bir Geliştirme Kataloğu anlamına gelir), tüm yayınlama işlemleri zorlanır ve çakışmalar otomatik çözümlenir; bu da, örneğin  test sırasında aynı Ürün sürümünü sürekli olarak yeniden yayınlamanıza olanak sağlar.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Üretim kipi etkinse (bunun bir Üretim Kataloğu olduğu anlamına gelir), önceden yayınlanmış Ürünlerle çakışma olması durumunda bir Ürünü Kataloğa yayınlamanız engellenir; yayınlama işlemini yeniden denemeden önce çakışmaları çözmeniz gerekir.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Bir Katalogda Alanlar etkinleştirilirse içindeki üyeleri yönetebilirsiniz. Alana yeni kullanıcılar ekleyerek ve kullanıcılara roller atayarak Alan üyeliğini yönetebilirsiniz.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Etkinleştirildiyse, boş değer içeren giriş özellikleri, çıkış belgesine eşlenir. Aksi takdirde bu özellikler, çıkıştan atılır.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Üretim kipi etkinleştirildiyse herhangi bir hazırlık ve yayınlama eylemi zorlanmaz. Çakışmalar bulunursa, sistem tarafından otomatik olarak çözümlenecek. Yayından kaldırma eylemleri otomatik olarak gerçekleşecek.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Alıcılar e-postayı almadıysa lütfen e-posta sunucusu ayarlarını kontrol edin.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Alıcılar e-posta almadıysa lütfen {username} için e-posta sunucusu ayarlarını denetleyin.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `True değerine ayarlanırsa, Çağırma ve Yetkili Sunucu ilkelerinin hedef url sorgu parametresi değerlerindeki tüm + karakterleri %2B olarak kodlanır.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `True değerine ayarlanırsa, çağırma hedef url'sinde bir değişken tanımına göre başvurulan sorgu parametrelerinin URL ile kodu çözülür.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `True değerine ayarlanırsa, çağırma ilkesi, HTTP DELETE yönteminde bir bilgi yükü gönderir.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Doğru olarak ayarlanırsa, özellik düzeltilecek ya da etkinlik günlüklerinden kaldırılacak.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Doğru olarak ayarlanırsa, özellik düzeltilecek ya da yürütme başlangıcındaki girişten kaldırılacak.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Doğru olarak ayarlanırsa, özellik düzeltilecek ya da yanıttan kaldırılacak.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Alanlar bir katalogda etkinleştirilirse kullanıcıların alan içinde sahip olduğu erişimi yönetebilirsiniz. Kullanıcı rollerine atanan izinleri belirterek erişimi yönetebilirsiniz. Yeni bir alan yarattığınızda varsayılan olarak her role atanan izinler, varsayılan alan izinleri şablonundaki ayarlara göre belirlenir.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Onay kutusu işaretliyse (varsayılan seçenek), XML ad alanları üst öğeden devralınır. Eşlem ilkesinin açık olarak ad alanlarını kullanmasını istiyorsanız onay kutusunu temizleyin.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Onay kutusu işaretliyse (varsayılan seçenek), XML ad alanları ilk kullanıldıkları belgeye yerleştirilir. Tümüne yönelik ad alanlarının kök öğede tanımlanmasını istiyorsanız onay kutusunun işaretini kaldırın.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Onay kutusu işaretliyse (varsayılan seçenek), eşlem ilkesinin çıkışı boş XML öğelerini içerir. Boş XML öğelerinin eşlem ilkesinin çıkışında yer almasını istemiyorsanız onay kutusunu temizleyin.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Onay kutusu işaretlenirse, eşlenen JSON çıktısının son işlemesi etkinleştirilir. JSON çıktısının son işlemesi, özellik değerlerinin şemada tanımlananlarla aynı veri tipinde olmasını sağlamak için çıkış şemasını kullanacaktır. Bir XML girişine nesne eşlemesi nedeniyle Badgerfish JSON sözdizimi içeren çıkış özelliği değerlerini de normalleştirecektir. Eşlenen JSON çıkışı için son işleme gerçekleştirilmemesi için onay kutusunun işaretini kaldırın.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Onay kutusu seçilirse (varsayılan seçenek), eşlem özelliklerinde bulunan API Connect değişkeni başvuruları çözümlenir. Eşlem ilkesinin kendi içindeki API Connect değişken başvurularını yoksaymasını istiyorsanız onay kutusunun işaretini kaldırın.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Onay kutusu seçilirse, eşlem özelliklerinde bulunan API Connect değişkeni başvuruları çözümlenir. Eşlem ilkesinin kendi içindeki API Connect değişken başvurularını yoksaymasını istiyorsanız onay kutusunun işaretini kaldırın (varsayılan seçenek).`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Onay kutusu seçilirse (varsayılan seçenek), girişin geçişinde bir diziyle karşılaşılması durumunda yalnızca ilk öğe kullanılır. Eşlem ilkesinin tüm dizi öğelerini kullanmasını istiyorsanız onay kutusunun işaretini kaldırın.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Onay kutusu seçilirse, şemaları boole veya sayısal tipte yapıladırılan XML öğeleri ilgili veri tipine dönüştürülür. Seçilmezse (varsayılan seçenek) tüm XML öğesi değerleri dize olarak döndürülür.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Onay kutusu seçilirse, karmaşık şema tipleri değerlendirmesi iyileştirilmiş şekilde döngüsel tip başvurularını işleyecektir. Bu şema tiplerini standart şekilde değerlendirmek için bu onay kutusunun (varsayılan seçenek) işaretini kaldırın.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Hata CORS desteği eksikliğinden kaynaklanıyorsa, sunucuyu yeni bir sekmede açmak için bağlantıyı tıklatın. Tarayıcı bir sertifika sorunu görüntülerse bunu kabul edip yeniden test etmek için buraya geri dönmeyi seçebilirsiniz.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Bu onay kutusu seçiliyse şemada gerekli olarak belirtilen isteğe bağlı bir nesnenin çıkış dizisi veya alt özelliği, başarıyla eşlenmezse gerekli alt nesne özelliklerini varsayılan bir değere geri yükler. Bu, nesneler için gerekli özelliklerde sürüm 4 eşleşmesinin nasıl yapıldığını taklit eder. Yalnızca başarıyla eşleştirilen verileri içeren bu nesneleri çıkarmak için bu onay kutusunun işaretini kaldırın (varsayılan değer).`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Bu onay kutusu işaretlenirse (varsayılan) ve sahip kullanıcı değiştirilirse, herhangi bir alt kaynağın (Alan) sahipliği de yeni sahibe aktarılır.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Bu seçilirse eşleme ilkesi XML çıkışını message.body'ye ayrıştırılmış XML belgesi olarak yazar. Varsayılan olarak XML, XML dizesi olarak çıkıştır. Başka bir değişkende XML çıkışı her zaman XML dizesi olarak yazılır.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Aşama, Yayınlama ya da Değiştirme işlemleri için yaşam çevrimi onayını etkinleştirirseniz, bu işlemleri otomatik gerçekleştiren bazı API yaratma, düzenleme ya da test operasyonları düzgün çalışmayabilir. Bu onayların birini etkinleştirmek istediğinizden emin misiniz?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Bir API Connect API tanımlaması aracılığıyla göstermek istediğiniz var olan bir SOAP hizmetiniz varsa, bir API yaratabilir ve hedef SOAP hizmetini belirtebilirsiniz. API Connect, bu SOAP hizmetini çağıran bir API tanımı yaratır.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `"Evet, API'yi Güncelle" seçeneğini tıklatarak değişikliklerinizi kaydederseniz, bu OAuth sağlayıcısının temelini oluşturan API derlemesi de güncellenir. Ancak temel API derlemesini özelleştirdiyseniz ve bunu ayrı olarak güncellemek istiyorsanız "Hayır. API Derlemesini Güncelleme" seçeneğini tıklatın, ardından temel API derlemesini API Düzenleyicisinde güncelleyin.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Hala sorun yaşıyorsanız bir şema tanımı dil dosyasını karşıya yükleyin.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Dosyanızda hem özel hem de genel anahtarlar varsa, bunları adım 1'de karşıya yükleyin`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Bir güvenlik duvarının arkasında bulunan ağ geçidini kaydetmeniz gerekiyorsa bağlantı oluşturmak için Secure Gateway hizmeti veya VPN kullanımıyla ilgili bilgi için IBM Cloud Docs'a bakın.`,
  [`Ignore`]: `Yoksay`,
  [`Implement policy`]: `İlkeyi uygula`,
  [`Implementation file is required`]: `Uygulama dosyası gereklidir`,
  [`Implicit`]: `Örtülü`,
  [`Import`]: `İçe aktar`,
  [`Import API`]: `API'yi İçe Aktar`,
  [`Import API|title`]: `API'yi İçe Aktar`,
  [`Import OpenAPI definition from asset repository`]: `Varlık havuzundan OpenAPI tanımını içe aktar`,
  [`Import Product`]: `Ürünü İçe Aktar`,
  [`Import policy`]: `İlkeyi içe aktar`,
  [`Import a product from a service URL`]: `Ürünü bir hizmet URL'sinden içe aktarın`,
  [`Import a product from the definition file`]: `Ürünü tanım dosyasından içe aktarın`,
  [`Import an existing product`]: `Var olan ürünü içe aktar`,
  [`Import an OpenAPI definition from a URL`]: `OpenAPI tanımını bir URL'den içe aktarın`,
  [`Import an OpenAPI definition from a file`]: `OpenAPI tanımını bir dosyadan içe aktarın`,
  [`Import definitions`]: `Tanımları içe aktar`,
  [`Import from existing API service`]: `Var olan API hizmetinden aktar`,
  [`Import from file`]: `Dosyadan içe aktar`,
  [`Importing an API definition`]: `API tanımını içe aktarma`,
  [`Import|title`]: `İçe aktar`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Gerekli sertifikaları ve CA paketlerini Sertifika Yöneticisi hizmetine aktarın.`,
  [`In Cookie Header`]: `Tanımlama Bilgisi Üst Bilgisinde`,
  [`In WSSec Header`]: `WSSec Üstbilgisinde`,
  [`In order to invoke the endpoints above, you need to first`]: `Yukarıdaki uç noktaları çağırmak için önce`,
  [`Inactive`]: `Devre Dışı`,
  [`Inc`]: `Dahil et`,
  [`Include empty`]: `Boş olanı dahil et`,
  [`Include free trial days`]: `Ücretsiz deneme gününü dahil et`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Yapılandırılabilir ağ geçidi hizmetine sahip uyumsuz API'ler seçilemez.`,
  [`Incorrect username or password`]: `Yanlış kullanıcı adı ya da parola`,
  [`Incorrect username, password, or credentials`]: `Hatalı kullanıcı adı, parola veya kimlik bilgileri`,
  [`Indicate if the invoke is the final action`]: `Çağırmanın son işlem olup olmadığını belirtin`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `JWT'ye bir JWT Tanıtıcısı (jti) talebi eklenip eklenmeyeceğini belirtir. Seçilirse, jti talebi değeri bir UUID olur.`,
  [`Indicates whether the clients secret is required.`]: `İstemci güvenlik dizgisinin gerekli olup olmadığını gösterir.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Bu XSLT giriş belgesinin, bağlamın geçerli bilgi yükünü kullanıp kullanmadığını ya da bir giriş olup olmadığını belirtir.`,
  [`Indicates whether to stop processing if client security fails.`]: `İstemci güvenliğinin başarısız olması durumunda işlemenin durdurulup durdurulmayacağını gösterir.`,
  [`Infer from JSON schema`]: `JSON şemasından çıkar`,
  [`Infer from Sample`]: `Örnekten çıkar`,
  [`Info`]: `Bilgi`,
  [`Information context variable`]: `Bilgi bağlam değişkeni`,
  [`Inject proxy headers`]: `Yetkili sunucu üstbilgileri ekle`,
  [`Inline schema`]: `Yerleşik şema`,
  [`Input`]: `Giriş`,
  [`Input field`]: `Giriş alanı`,
  [`Input object`]: `Giriş nesnesi`,
  [`Insecure Server Connections`]: `Güvenli Olmayan Sunucu Bağlantıları`,
  [`Install API Connect CLI & API Designer|button text`]: `API Connect CLI &amp; API Designer kur`,
  [`Install API Connect CLI & API Designer|title`]: `API Connect CLI &amp; API Designer kur`,
  [`Install API Connect Toolkit`]: `API Connect Toolkit'i Kur`,
  [`Install the API Connect plugin`]: `API Connect eklentisini kur`,
  [`Instance ID`]: `Eşgörünüm Tanıtıcısı`,
  [`Instance name`]: `Örnek Adı`,
  [`Instance owner`]: `Eşgörünüm sahibi`,
  [`Interact to expand Tile`]: `Döşemeyi genişletmek için etkileşim kur`,
  [`Interact to collapse Tile`]: `Döşemeyi daraltmak için etkileşim kur`,
  [`Interval`]: `Aralık`,
  [`Interface`]: `Arabirim`,
  [`Introspect`]: `İç gözlem`,
  [`Introspect cache type`]: `İç gözlem önbellek tipi`,
  [`Introspect from Server URL`]: `İç gözlem için kaynak sunucu URL'si`,
  [`Introspect from URL`]: `İç gözlem için kaynak URL`,
  [`Introspect URL`]: `İçgözlem URL'si`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Farklı bir URL kullanarak iç gözlem, GraphQL sunucusu URL'sini değiştirmez.`,
  [`Introspect token`]: `İçgözlem belirteci`,
  [`Introspection`]: `İçgözlem`,
  [`Introspection Path`]: `İçgözlem Yolu`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Sunucudan GraphQL şeması gözlemle veya şemayı karşıya yükle`,
  [`Introspect the GraphQL schema using the query`]: `Sorguyu kullanarak GraphQL şemasına içgözlem gerçekleştir`,
  [`Introspection URL`]: `İçgözlem URL'si`,
  [`Invalid HOST URL`]: `Geçersiz anasistem URL'si`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `Geçersiz URL. Lütfen geçerli bir API Manager anasistem URL'si girin. Örn. https://api-connect-host.com`,
  [`Invalid file URL`]: `Geçersiz dosya URL'si`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `URL adresi biçimi geçersiz; http:// ya da https:// ile başlamalı ve en az 1 karakter içermelidir`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Geçersiz URL biçimi, yalnızca anasistem adının belirtilmesi gerekir. Örn. syslog:// kaldırılabilir`,
  [`Invalid emails.`]: `Geçersiz e-postalar.`,
  [`Invalid JSONata expression`]: `Geçersiz JSONata ifadesi`,
  [`Invalid remove target`]: `Geçersiz kaldırma hedefi`,
  [`Invalid assumed size`]: `Geçersiz kabul edilen boyut`,
  [`Invalid cost target`]: `Geçersiz maliyet hedefi`,
  [`Invalid list size`]: `Geçersiz liste boyutu`,
  [`Invalid port value, must not be negative`]: `Geçersiz kapı değeri, negatif olamaz`,
  [`Invalid sized fields`]: `Geçersiz boyutlu alanlar`,
  [`Invalid value, length has to be greater than 1`]: `Geçersiz değer, uzunluk 1'den büyük olmalıdır`,
  [`Invalid weight value`]: `Geçersiz ağırlık değeri`,
  [`invitation`]: `davet`,
  [`Invitation`]: `Davet`,
  [`Invitation timeout`]: `Davet zaman aşımı`,
  [`Invitation link will expire in {time}`]: `Davet bağlantısının süresi **{time}** sonra dolacak`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Davet bağlantısının süresi **{seconds} saniye içinde ({time})** dolacak`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Davet bağlantısının __{value} {unit}__ sonra süresi dolacak.`,
  [`Invitation sent to`]: `Davet gönderilenler`,
  [`Invitation sent to {email}`]: `Davet {email} adresine gönderildi`,
  [`Invitation to an API catalog in IBM API Connect`]: `IBM API Connect'te bir API kataloğuna davet`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `{catalog} geliştirici portalında API tüketici kuruluşuna davet`,
  [`Invitation to an API provider organization in IBM API Connect`]: `IBM API Connect'te bir API sağlayıcı kuruluşuna davet`,
  [`Invitation to an API space in IBM API Connect`]: `IBM API Connect'te bir API alanına davet`,
  [`Invitation to an admin organization in IBM API Connect`]: `IBM API Connect'te bir yönetici kuruluşuna davet`,
  [`Invitation to create an API catalog in IBM API Connect`]: `IBM API Connect'te bir API kataloğu yaratma daveti`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `{catalog} geliştirici portalında bir API tüketici kuruluşu yaratma daveti`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `IBM API Connect'te bir API sağlayıcı kuruluşu yaratma daveti`,
  [`Invitation to create an API space in IBM API Connect`]: `IBM API Connect'te bir API alanı oluşturma daveti`,
  [`Invitation ttl has been created.`]: `Davet ttl'si oluşturuldu.`,
  [`Invitation ttl has been updated.`]: `Davet ttl'si güncellendi.`,
  [`Invitation ttl has not been created!`]: `Davet ttl'si oluşturulmadı!`,
  [`Invitation ttl has not been updated!`]: `Davet ttl'si güncellenmedi!`,
  [`Invite`]: `Davet Et`,
  [`Invite catalog owner`]: `Katalog sahibini davet et`,
  [`Invite consumer organization owner`]: `Tüketici kuruluş sahibini davet et`,
  [`Invite member`]: `Üyeyi davet et`,
  [`Invite organization owner`]: `Kuruluş sahibini davet et`,
  [`Invite space owner`]: `Alan sahibini davet et`,
  [`Invite a new consumer organization owner`]: `Yeni bir tüketici kuruluşu sahibi davet et`,
  [`Invite a new member`]: `Yeni üye davet et`,
  [`Invite a new provider organization owner`]: `Yeni bir sağlayıcı kuruluş sahibi davet et`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Alıcı e-posta adresini girerek bir kataloğun yöneticisi olarak kaydolması için yeni bir kullanıcıyı davet edin. Kullanıcıya, kayıt formunun bağlantısı içeren bir davet e-postası gönderilir`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Alıcı e-posta adresini girerek, yeni bir kullanıcıyı bir alanın yöneticisi olarak kaydolması için davet edin. Kayıt formu bağlantısı içeren bir davet e-postası kullanıcıya gönderilir.`,
  [`Invite by email`]: `E-postayla davet et`,
  [`Invite from User Registry`]: `Kullanıcı Kayıt Defterindan Davet Et`,
  [`Invite team member`]: `Ekip üyesini davet et`,
  [`Inviting members to consumer organizations is enabled`]: `Üyeleri tüketici kuruluşlarına davet etme etkinleştirildi`,
  [`Invocations`]: `Yürütmeler`,
  [`Invoke`]: `Çağır`,
  [`Issuer Claim`]: `Düzenleyen Talebi`,
  [`Issuer Origin`]: `Yayınlayanın Kökeni`,
  [`Issuer common name`]: `Yayınlayanın ortak adı`,
  [`It might influence how the API is deployed`]: `API'nin nasıl devreye alındığını etkileyebilir`,
  [`Italian`]: `İtalyanca`,
  [`Items per page`]: `Sayfa başına öğe`,
  [`Items per page:`]: `Sayfa başına öğe:`,
  [`items selected`]: `öğe seçildi`,
  [`item selected`]: `öğe seçildi`,
  [`Iterate on`]: `Yinelenme zamanı`,
  [`Iterate?`]: `Yinelensin mi?`,
  [`JSON objects describing re-usable channel parameters.`]: `Yeniden kullanılabilir kanal parametrelerini açıklayan JSON nesneleri.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `API tarafından kullanılmakta ve üretilmekte olan mesajları açıklayan JSON nesneleri.`,
  [`JSON Schema URL`]: `JSON Şeması URL'si`,
  [`JSON Schema preview`]: `JSON Şeması önizlemesi`,
  [`JSON Web Token (JWT)`]: `JSON Web Belirteci (JWT)`,
  [`JSON has been successfully validated`]: `JSON başarıyla doğrulandı`,
  [`JSON schema is not valid. Please correct the error.`]: `JSON şeması geçerli değil. Lütfen hatayı düzeltin.`,
  [`JSON to XML`]: `JSON'dan XML'e`,
  [`JWT ID Claim`]: `JWT Tanıtıcısı Talebi`,
  [`JWT verification JWK`]: `JWT doğrulama JWK'sı`,
  [`JWT verification crypto object`]: `JWT doğrulama şifreleme nesnesi`,
  [`Japanese`]: `Japonca`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `İşler kuyruğu`,
  [`Jump to source`]: `Kaynağa atla`,
  [`Just now`]: `Şimdi`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `ANAHTAR DEĞİŞİMİ / ŞİFRELEME / MAC`,
  [`KEY SIZE`]: `ANAHTAR BOYUTU`,
  [`Keystore`]: `Anahtar Deposu`,
  [`keystore`]: `anahtar deposu`,
  [`Keep`]: `Tut`,
  [`Keep Payload`]: `Bilgi Yükünü Koru`,
  [`Key Encryption Algorithm`]: `Anahtar Şifreleme Algoritması`,
  [`Key Name`]: `Anahtar Adı`,
  [`Key type`]: `Anahtar tipi`,
  [`Key name`]: `Anahtar adı`,
  [`Keystore for the token has been updated.`]: `Belirteç için anahtar deposu güncellendi.`,
  [`Keystore Certificates`]: `Anahtar Deposu Sertifikaları`,
  [`Keystore/truststore`]: `Anahtar deposu/güvenli depo`,
  [`Korean`]: `Korece`,
  [`LABEL`]: `ETİKET`,
  [`LABELS`]: `ETİKETLER`,
  [`LAST MODIFIED`]: `SON DEĞİŞTİRME`,
  [`Last state changed`]: `Son durum değişikliği`,
  [`Large`]: `Büyük`,
  [`Lax`]: `Gevşek`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAP grupları`,
  [`LDAP user registry`]: `LDAP kullanıcı kayıt defteri`,
  [`LDAP registry`]: `LDAP kaydı`,
  [`LDAP registry name`]: `LDAP kaydı adı`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Rolün LDAP kullanıcı kaydı {{name}} artık Dış grup eşlemesini desteklemiyor. Kaydetmeden önce roldeki Dış grup eşlemesini devre dışı bırakın veya bu kullanıcı kaydı için Dış grup eşlemesini etkinleştirin.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Rolün LDAP kullanıcı kaydı {{name}} artık Dış grup eşlemesini desteklemiyor. Dış grup eşlemesiyle başka bir kullanıcı kaydı seçin veya kaydetmeden önce rolde Dış grup eşlemesini devre dışı bırakın.`,
  [`LDAP search attribute`]: `LDAP arama özniteliği`,
  [`LOCATED IN`]: `YERİ`,
  [`Located in`]: `Bulunduğu yer`,
  [`LTPA Key`]: `LTPA Anahtarı`,
  [`LTPA Key Details`]: `LTPA Anahtarı Ayrıntıları`,
  [`LTPA Keys`]: `LTPA Anahtarları`,
  [`LTPA key file`]: `LTPA anahtarı dosyası`,
  [`LTPA key password`]: `LTPA anahtarı parolası`,
  [`LUR data version`]: `LUR veri sürümü`,
  [`LUR schema update date`]: `LUR şema güncelleme tarihi`,
  [`LUR schema version`]: `LUR şema sürümü`,
  [`LUR target data version`]: `LUR hedef veri sürümü`,
  [`LUR target schema version`]: `LUR hedef şema sürümü`,
  [`LUR update date`]: `LUR güncelleme tarihi`,
  [`Last name`]: `Soyadı`,
  [`Last modified`]: `Son değişiklik tarihi`,
  [`Launch portal`]: `Portalı başlat`,
  [`Launch IBM Automation management console`]: `IBM Automation yönetim konsolunu başlat`,
  [`Launch plan upgrade`]: `Plan yükseltmeyi başlat`,
  [`Learn more`]: `Daha fazla bilgi`,
  [`Learn More`]: `Ek Bilgi`,
  [`Legacy`]: `Eski`,
  [`Length cannot be below {min}`]: `Uzunluk {min} altında olamaz`,
  [`Length cannot exceed {max}`]: `Uzunluk {max} üzerinde olamaz`,
  [`Let's customize API Connect`]: `API Connect'i özelleştirelim`,
  [`Let's get started...`]: `Haydi başlayalım...`,
  [`Let's get you up and running`]: `Haydi başlayalım`,
  [`License`]: `Lisans`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `Yaşam Çevrimi`,
  [`Lifecycle approvals`]: `Yaşam çevirimi onayları`,
  [`Lifecycle actions have been updated`]: `Yaşam çevrimi işlemleri güncellendi`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Kum Havuzu Kataloğu için yaşam çevrimi onayları etkinleştirildi. Otomatik API'yi Etkinleştir seçeneğini kullanmak için önce Kum Havuzu Kataloğu ayarlarındaki yaşam çevrimi onaylarını devre dışı bırakmanız gerekir.`,
  [`Limited fields`]: `Sınırlı alanlar`,
  [`Limit API calls on a per key basis`]: `API çağrılarını anahtar başına olacak şekilde sınırla`,
  [`Limit fields`]: `Alanları sınırla`,
  [`Limit renegotiation`]: `Yeniden anlaşmayı sınırla`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Yükle`,
  [`Load query`]: `Sorguyu yükle`,
  [`Load filter`]: `Süzgeç yükle`,
  [`Load schema from WSDL`]: `WSDL'den şemayı yükle`,
  [`Loading`]: `Yükleniyor`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Yerel Kullanıcı Kayıt Defterleri, API Connect'te yer alan varsayılan kullanıcı kayıt defterleri olup yerel olarak depolanır.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Yerel Kullanıcı Kayıt Defterleri, API Connect'te yer alan varsayılan kullanıcı kayıt defterleri olup yerel olarak depolanır. API Connect'in kuruluşu sırasında iki yerel kullanıcı kayıt defteri kurulup yapılandırılır. Ek yerel kullanıcı kayıt defterleri yapılandırılabilir.`,
  [`Local user registry`]: `Yerel kullanıcı kayıt defteri`,
  [`Local User Registry has been created.`]: `Yerel Kullanıcı Kayıt Defteri oluşturuldu.`,
  [`Location`]: `Konum`,
  [`Location of ciphertext to decrypt`]: `Şifresi çözülecek şifre metninin konumu`,
  [`Location of plaintext information to encrypt`]: `Şifrelenecek düz metin bilgilerinin konumu`,
  [`Log`]: `Günlük`,
  [`Log in`]: `Oturum aç`,
  [`Log in to:`]: `Oturum aç:`,
  [`Log in with a different account`]: `Farklı bir hesapla oturum aç`,
  [`Log into IBM Cloud`]: `IBM Cloud'da oturum açın`,
  [`Log out`]: `Oturumu kapat`,
  [`Log in using the {userRegistryType} user registry`]: `{userRegistryType} kullanıcı kayıt defterini kullanarak oturum aç`,
  [`Logging in with IBM Cloud ...`]: `IBM Cloud'da oturum açılıyor ...`,
  [`Logging out`]: `Oturum kapatılıyor`,
  [`Logic`]: `Mantık`,
  [`Login`]: `Oturum aç`,
  [`Logs`]: `Günlükler`,
  [`Longer, descriptive name for this policy.`]: `Bu ilke için daha uzun ve açıklayıcı ad.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Sunucu, isteğinizi işlerken bir hatayla karşılaşmış gibi görünüyor.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Posta sunucusu`,
  [`mail-server-test-connection`]: `posta-sunucusu-test-bağlantısı`,
  [`MEMBER`]: `ÜYE`,
  [`MIME Types`]: `MIME Tipleri`,
  [`Mail server has been deleted.`]: `Posta sunucusu silindi.`,
  [`Mail server has not been deleted.`]: `Posta sunucusu silinmedi.`,
  [`Mailserver {arg} has been removed.`]: `{arg} adlı posta sunucusu kaldırıldı.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Sunucunun çalıştığından ve ağ bağlanırlığı olduğundan emin olun.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Bunun nedeni, hedef sunucuda CORS desteği eksikliği, sunucunun kullanılamaması, güvenilir olmayan bir sertifikayla karşılaşılması ya da Karşılıklı SSL kimlik doğrulaması gerekliliği olabilir.`,
  [`This might be caused by one or more of the following:`]: `Bunun nedeni aşağıdakilerden biri veya birkaçı olabilir:`,
  [`Make a call and move on`]: `Bir çağrı yap ve devam et`,
  [`Make a call and wait for response`]: `Bir çağrı yap ve yanıtı bekle`,
  [`Manage`]: `Yönet`,
  [`Manage API life cycles`]: `API yaşam çevrimlerini yönet`,
  [`Manage API product lifecycle approval permissions`]: `API ürünü yaşam çevrimi onayı izinlerini yönetin`,
  [`Manage API product lifecycle approvals permissions`]: `API ürün yaşam çevrimi onay izinlerini yönet`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `API sağlayıcı kuruluşlarını, yerel ve uzak ağ geçitlerini ve ilgili ayarları yönetin.`,
  [`Manage APIs`]: `API'leri Yönet`,
  [`Manage Catalogs`]: `Katalogları Yönet`,
  [`Manage Organizations`]: `Kuruluşları Yönet`,
  [`Manage Resources`]: `Kaynakları Yönet`,
  [`Manage Settings`]: `Ayarları Yönet`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `API Connect, HTTP, Kafka, Elasticsearch ve Syslog dahil API analitik hedeflerini yönetin.`,
  [`Manage local and remote gateways that secure your APIs.`]: `API'lerinizin güvenliğini sağlayan yerel ve uzak ağ geçitlerini yönetin.`,
  [`Manage catalogs`]: `Katalogları yönet`,
  [`Manage organizations`]: `Kuruluşları yönet`,
  [`Manage resources`]: `Kaynakları yönet`,
  [`Manage settings`]: `Ayarları yönet`,
  [`Manage users`]: `Kullanıcıları yönet`,
  [`Manage active APIs and consumers`]: `Etkin API'leri ve tüketicileri yönetin`,
  [`Manage availability zones and services`]: `Kullanılabilirlik bölgelerini ve hizmetlerini yönetin`,
  [`Manage policies`]: `İlkeleri yönet`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Uygulama geliştiricilerinden gelen ürün yaşam çevrimi değişiklik isteklerini, API aboneliği ve uygulama yükseltme isteklerini yönetin`,
  [`Manage roles and permissions`]: `Rolleri ve izinleri yönet`,
  [`Manage the OAuth providers configured for API Manager`]: `API Manager için yapılandırılan OAuth sağlayıcılarını yönetin`,
  [`Manage the user registries configured for API Manager`]: `API Manager için yapılandırılan kullanıcı kayıt defterlerini yönetin`,
  [`Manage the user registries configured for Cloud Manager`]: `Cloud Manager için yapılandırılan kullanıcı kayıt defterlerini yönetin`,
  [`Management`]: `Yönetim`,
  [`Management Endpoint`]: `Yönetim Uç Noktası`,
  [`Management endpoint`]: `Yönetim uç noktası`,
  [`Management endpoint on the analytics service`]: `Analitik hizmetinde yönetim uç noktası`,
  [`Service endpoint configuration`]: `Hizmet uç noktası yapılandırması`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Yapılandırma bilgilerini portal hizmetine göndermek için yönetim hizmeti tarafından kullanılan uç nokta`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Portal hizmetiyle iletişim kurulurken yönetim hizmeti tarafından kullanılan TLS istemcisi profili`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Ağ geçidi hizmetine yapılandırma bilgilerini göndermek için yönetim hizmeti tarafından kullanılan uç nokta`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `Ağ geçidi hizmetiyle iletişim kurulurken yönetim hizmeti tarafından kullanılan TLS istemcisi profili`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Ağ geçidi ve yönetim hizmeti arasındaki iç trafik için küme içi iletişim kullanın`,
  [`Use in-cluster communication for both ingestion and queries`]: `Hem alım hem de sorgular için küme içi iletişim kullanın`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Alım için küme içi ve sorgular için harici iletişimi kullan veya tersi`,
  [`Use external communication for both ingestion and queries`]: `Hem alım hem de sorgular için harici iletişimi kullanın`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Analitik ile yönetim hizmeti arasındaki iç trafik için küme içi iletişim kullanın`,
  [`Management endpoint on the gateway service`]: `Ağ geçidi hizmetindeki yönetim uç noktası`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Portal ve yönetim hizmeti arasındaki dahili trafik için küme içi iletişim kullanın`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Yönetim ve portal altsistemleri arasında küme içi iletişim kullanmak istiyorsanız, bu anahtarı etkinleştirin. Emin değilseniz, bu anahtarı devre dışı bırakın (varsayılan).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Yönetim ve ağ geçidi altsistemleri arasında küme içi iletişim kullanmak istiyorsanız, bu anahtarı etkinleştirin. Emin değilseniz, bu anahtarı devre dışı bırakın (varsayılan).`,
  [`Management endpoint on the portal service`]: `Portal hizmetinde yönetim uç noktası`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Bu seçenek varsayılan değerdir ve bir pre-10.0.5.3 yayın düzeyinden büyütülen analitik hizmetleri için kullanılan seçenektir. OVA devreye alması söz konusuysa ya da analitik altsisteminiz farklı bir ortamdaysa, kullanabileceğiniz tek seçenek dış iletişimdir. Ağ geçidi ve yönetim hizmeti, analitik hizmetiyle dış uç noktayı kullanarak iletişim kurar; bu uç nokta altyapınıza bağlı olarak bir Kubernetes girişi ya da OpenShift rotasıdır.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `Bütün altsistemlerin aynı küme içinde bulunduğu bir Kubernetes ya da OpenShift uç noktası devreye alınmışsa küme içi iletişimi seçebilirsiniz. Bu seçenek işaretlendiğinde, yönetim, ağ geçidi ve analitik altsistemleri birbirleriyle dışarıdan erişilebilir girişler (Kubernetes) ya da rotalar (OpenShift) yerine, iç hizmet uç noktaları aracılığıyla iletişim kurar.`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Bir Kubernetes ya da OpenShift uç noktası devreye alınmışsa ve altsistemlerinizin bazıları aynı kümede kuruluysa, bu seçeneği işaretleyebilirsiniz. Bu seçenek işaretlendiğinde, farklı analitik iletişim akışları için farklı iletişim yöntemleri kullanabilirsiniz. Örneğin, yönetimden analitiğe yönelik iletişimde küme içi ve ağ geçidinden analitiğe yönelik iletişimde dış iletişim yöntemi kullanabilir. Ağ geçidinizin altsistemlerinizin geri kalanından farklı bir kümede olması durumunda, bu yapılandırmayı seçebilirsiniz.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Analitik hizmetini kaydettiğinizde küme içi iletişim belirlediyseniz, ağ geçidinin API olay verilerini iç analitik uç noktasına göndermesine izin verebilirsiniz.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Ağ geçidi hizmetinden elde edilen analitik verilerinin alımı için iç uç nokta kullanın`,
  [`Endpoint configuration`]: `Hem alım hem de sorgular için harici iletişimi kullanın`,
  [`Portal endpoint configuration`]: `Portal uç noktası yapılandırması`,
  [`URL used by developer portal users to access the portal website`]: `Portal web sitesine erişmek için geliştirici portalı kullanıcıları tarafından kullanılan URL`,
  [`External ingestion endpoint on the analytics service`]: `Analitik hizmetindeki harici alım uç noktası`,
  [`TLS client profile for external analytics ingestion endpoint`]: `Harici analitik alım uç noktası için TLS istemci profili`,
  [`Internal ingestion endpoint on the analytics service`]: `Analitik hizmetindeki dahili alım uç noktası`,
  [`TLS client profile for internal service endpoint`]: `Harici hizmet uç noktası için TLS istemcisi profili`,
  [`Manager`]: `Yönetici`,
  [`Manager Request Reset Password`]: `Yönetici Parola Sıfırlama İsteği`,
  [`Manager Reset Password`]: `Yönetici Parola Sıfırlama İşlemi`,
  [`Manager Sign In`]: `Yönetici Oturum Açma İşlemi`,
  [`Manages API provider organizations`]: `API sağlayıcısı kuruluşları yönetir`,
  [`Manages application developer communities`]: `Uygulama geliştirme topluluklarını yönetir`,
  [`Manages the API product lifecycle`]: `API ürün yaşam çevrimini yönetir`,
  [`Manage|link`]: `Yönet`,
  [`Manage|permission`]: `Yönet`,
  [`Manage|title`]: `Manage:title`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Belirteçler için Anahtar Deposu ataması ve geçmişini yönet`,
  [`Map`]: `Eşle`,
  [`Map information from OIDC provider to an APIC user`]: `Bilgileri OIDC sağlayıcısından bir APIC kullanıcısına eşle`,
  [`Map the migration source plans to the migration target plans`]: `Geçiş kaynak planlarını geçiş hedef planlarıyla eşleyin`,
  [`Map the roles to groups`]: `Rolleri gruplarla eşle`,
  [`Mapped from: `]: `Eşleme kaynağı: `,
  [`Marketing`]: `Pazarlama`,
  [`Maximize / minimize`]: `Ekranı kapla / simge durumuna küçült`,
  [`Maximum Consent Time to Live (seconds)`]: `İzin Süresi Üst Sınırı (saniye)`,
  [`Maximum consent`]: `İzin üst sınırı`,
  [`Menu`]: `Menü`,
  [`Member`]: `Üye`,
  [`Member Invitation`]: `Üye Daveti`,
  [`Member Invitation has been deleted.`]: `Üye Daveti silindi.`,
  [`Member has been deleted.`]: `Üye silindi.`,
  [`Member {name} created`]: `{name} adlı üye oluşturuldu`,
  [`Member {title} has been created.`]: `{title} üyesi oluşturuldu.`,
  [`Members`]: `Üyeler`,
  [`member-invitation`]: `üye-daveti`,
  [`Member Invitations`]: `Üye Davetleri`,
  [`Member Roles`]: `Üye Rolleri`,
  [`Merchant ID`]: `Satıcı Tanıtıcısı`,
  [`Merge`]: `Birleştir`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Bu eşleme, aynı hedef dizide çalışan gösteren diğer eşlemelerle birleştirilsin mi?`,
  [`Message`]: `İleti`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Ayrıştırma için ileti`,
  [`Message for resulting parsed data`]: `Sonuçta elde edilen ayrıştırılmış veriler için ileti`,
  [`Metadata`]: `Meta veriler`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Meta veriler, erişim belirteci oluşturma süreci sırasında eklenecek özel meta verileri toplamanızı sağlar. Meta verileri özel meta verilerin depolandığı uzak sunucu veya bir kimlik doğrulama URL'siyle ya da her ikisi aracılığıyla toplayabilirsiniz.`,
  [`Micro Gateway policies`]: `Micro Gateway ilkeleri`,
  [`Migrate`]: `Geçir`,
  [`Migrate Subscriptions`]: `Abonelikleri geçir`,
  [`Migrate plans`]: `Planları geçir`,
  [`Migrate subscription`]: `Aboneliği geçir`,
  [`Migrate subscriptions`]: `Abonelikleri geçir`,
  [`Migration Target`]: `Geçiş Hedefi`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `"{source}" (Ürün) - "{target}" (Ürün) geçişi yürütüldü`,
  [`Migration from "{source}" failed`]: `"{source}" kaynağından geçiş başarısız oldu`,
  [`Migration source`]: `Geçiş kaynağı`,
  [`Migration target`]: `Geçiş hedefi`,
  [`Minimum role`]: `Minimum rol`,
  [`Minimum output escaping rule`]: `Minimum çıkış kuralı`,
  [`Minutes`]: `Dakika`,
  [`Mode`]: `Kip`,
  [`Modifications`]: `Değişiklikler`,
  [`Modified`]: `Değiştirilme zamanı`,
  [`More`]: `Daha Fazla`,
  [`More than 20 events are waiting to be processed`]: `20'den fazla olay işlenmeyi bekliyor`,
  [`Move down`]: `Aşağı taşı`,
  [`Move up`]: `Yukarı taşı`,
  [`Must be a number`]: `Bir sayı olmalıdır`,
  [`Must be a valid URI`]: `Geçerli bir URI olmalıdır`,
  [`Must be a valid email`]: `Geçerli bir e-posta olmalıdır`,
  [`Must be a valid path starting with /`]: `/ ile başlayan geçerli bir yol olmalıdır`,
  [`Must be a valid url`]: `Geçerli bir URL olmalıdır`,
  [`Must be an integer`]: `Bir tamsayı olmalıdır`,
  [`Must be a valid url with no protocol`]: `İletişim kuralı olmayan geçerli bir url olmalıdır`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `64 on altılı karakter ("0x" önekli) biçiminde olmalıdır`,
  [`Must start with '/'`]: `'/' ile başlamalıdır`,
  [`Must start with https://`]: `https:// ile başlamalıdır`,
  [`Must start with https:// or $(`]: `https:// veya $( ile başlamalıdır`,
  [`Mutual auth`]: `Karşılıklı kimlik doğrulaması`,
  [`Mutual authentication`]: `Karşılıklı kimlik doğrulaması`,
  [`My account`]: `Hesabım`,
  [`Name`]: `Ad`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NOT (DEĞİL)`,
  [`Name cannot be empty.`]: `Ad boş olamaz.`,
  [`Name of Schema in OpenAPI Definitions`]: `OpenAPI Tanımlarındaki Şema Adı`,
  [`Name of the LDAP user password attribute.`]: `LDAP kullanıcı parolası özniteliğinin adı.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Doğrulanacak LDAP kullanıcı kayıt defteri adı. Listeden seçin ya da el ile yazın.`,
  [`Name of the organization`]: `Kuruluşun adı`,
  [`Namespace inheritance`]: `Ad alanı devralma`,
  [`Namespace inlining`]: `Ad alanı gövde içine alma`,
  [`Native`]: `Yerel`,
  [`Native OAuth provider`]: `Yerel OAuth sağlayıcısı`,
  [`Native OAuth provider summary`]: `Yerel OAuth sağlayıcı özeti`,
  [`Navigate to Source View`]: `Kaynak Görünümüne Git`,
  [`New`]: `Yeni`,
  [`New API`]: `Yeni API`,
  [`New API Connect connection`]: `Yeni API Connect bağlantısı`,
  [`New Application`]: `Yeni Uygulama`,
  [`New Catalog`]: `Yeni Katalog`,
  [`New Consumer organization`]: `Yeni Tüketici kuruluşu`,
  [`New Endpoint`]: `Yeni Uç Nokta`,
  [`New native OAuth provider`]: `Yeni yerel OAuth sağlayıcısı`,
  [`New OpenAPI`]: `Yeni OpenAPI`,
  [`New OpenAPI directory`]: `Yeni OpenAPI dizini`,
  [`New Organization Title`]: `Yeni Kuruluş Başlığı`,
  [`New Parameter`]: `Yeni Parametre`,
  [`New Password`]: `Yeni Parola`,
  [`New product`]: `Yeni ürün`,
  [`New Role`]: `Yeni Rol`,
  [`New schema`]: `Yeni şema`,
  [`New Test`]: `Yeni Test`,
  [`New third party OAuth provider`]: `Yeni üçüncü kişi OAuth sağlayıcısı`,
  [`New user`]: `Yeni kullanıcı`,
  [`New applications will be created in the development state`]: `Yeni uygulamalar geliştirme durumunda oluşturulur`,
  [`Next`]: `İleri`,
  [`Next page`]: `Sonraki sayfa`,
  [`No`]: `Hayır`,
  [`No account?`]: `Hesap yok mu?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `Sandbox kataloğunda yapılandırılmış Kimlik Doğrulama URL'si ya da LDAP Kullanıcı Kayıt Defteri yok`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `Sandbox kataloğunda yapılandırılmış Kimlik Doğrulama URL'si ya da LDAP Kullanıcı Kayıt Defteri yok, [buradan]({link}) oluşturabilirsiniz.`,
  [`No Billing`]: `Faturalama Yok`,
  [`No billing integration has been selected.`]: `Faturalama bütünleştirmesi seçilmedi.`,
  [`No billing integration can be shown when offline.`]: `Çevrimdışı durumda faturalama entegrasyonu gösterilemez.`,
  [`No gateways`]: `Ağ geçidi yok`,
  [`No cache`]: `Önbellek yok`,
  [`No Cache`]: `Önbellek Yok`,
  [`No Certificate Manager services available`]: `Sertifika Yöneticisi hizmetleri yok`,
  [`No default gateways`]: `Varsayılan ağ geçidi yok`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Kullanıcı kayıt defteri için yinelenen e-posta adresi yok. Bu yalnızca e-posta içeren kullanıcılar için geçerli.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Kum havuzu kataloğu için uyumlu ağ geçidi yapılandırılmadı. Explorer'da API Test(Try it) etkinleştirilmeyecek.`,
  [`No Errors`]: `Hata Yok`,
  [`No Histories found`]: `Geçmiş bulunamadı`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Ağ geçidi hizmeti bulunamadı. Desteklenen derleme ilkelerini görüntülemek için lütfen bir Ağ Geçidi Hizmeti yapılandırın`,
  [`No gateways are configured for sandbox catalog`]: `Kum havuzu kataloğu için ağ geçidi yapılandırılmadı`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Kum havuzu kataloğu için ağ geçidi yapılandırılmadı. Explorer'da API Test(Try it) etkinleştirilmeyecek.`,
  [`No LDAP or authentication URL user registries found.`]: `LDAP ya da kimlik doğrulama URL kullanıcı kayıt defterleri bulunamadı.`,
  [`No limit on size of list`]: `Liste boyutu için sınır yok`,
  [`No OAuth providers configured in the sandbox catalog`]: `Kum havuzu kataloğunda OAuth sağlayıcısı yapılandırılmadı`,
  [`No OAuth providers configured in the selected catalog`]: `Seçili katalogda OAuth sağlayıcısı yapılandırılmadı`,
  [`No Permission`]: `İzin Yok`,
  [`No portal added to this catalog`]: `Bu kataloğa portal eklenmedi`,
  [`No Roles exist`]: `Rol yok`,
  [`No recent items found.`]: `Son öğeler bulunamadı.`,
  [`No TLS profile`]: `TLS profili yok`,
  [`No TLS client profile`]: `TLS istemci profili yok`,
  [`No Truststore`]: `Güvenilir depo yok`,
  [`No analytics service is configured`]: `Analitik hizmeti yapılandırılmadı`,
  [`No analytics services found. You can create one [here]({link})`]: `Analitik hizmeti bulunamadı. [Buradan]({link}) bir analitik hizmeti oluşturabilirsiniz.`,
  [`No assemblies found.`]: `Bir derleme bulunamadı.`,
  [`No available extensions`]: `Kullanılabilir uzantı yok`,
  [`No compatible flows configured in this OAuth provider`]: `Bu OAuth sağlayıcısında yapılandırılmış herhangi bir uyumlu akış yok`,
  [`No consumer organization will be able to see this product.`]: `Bu ürünü hiçbir tüketici kuruluşu göremez.`,
  [`No debug data was found for this API call`]: `Bu API araması için bir hata ayıklama verisi bulunamadı`,
  [`No details are available.`]: `Ayrıntı yok.`,
  [`No existing products`]: `Ürün yok`,
  [`No extensions have been configured for the API`]: `API için uzantı yapılandırılmadı`,
  [`No flow configured in this OAuth provider`]: `Bu OAuth sağlayıcısında akış yapılandırılmadı`,
  [`No items found`]: `Öğe bulunamadı`,
  [`No non-member user to add.`]: `Eklenecek üye olmayan kullanıcı yok.`,
  [`No organization found`]: `Kuruluş bulunamadı`,
  [`No portal service exists:`]: `Portal hizmeti yok:`,
  [`No product APIs`]: `Ürün API'si yok`,
  [`No products or plans were found.`]: `Ürün ya da plan bulunamadı.`,
  [`No provider organizations`]: `Sağlayıcı kuruluşu yok`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Yanıt alınmadı. Hedef sunucuda CORS desteği eksikliği, sunucunun kullanılamaması, güvenilir olmayan bir sertifikayla karşılaşılması ya da Karşılıklı SSL kimlik doğrulaması gerekliliği nedenler arasındadır.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Kaynak grubu yok. Başlamak için IBM Cloud Hesabı kaynaklarında bir kaynak grubu oluşturun.`,
  [`No security`]: `Güvenlik yok`,
  [`No services found`]: `Hizmet bulunamadı`,
  [`No warnings`]: `Uyarı yok`,
  [`No warnings.`]: `Uyarı yok.`,
  [`No. Do not update API Assembly`]: `Hayır. API Derlemesini güncellemeyin`,
  [`Non-blocking`]: `Engelleyici olmayan`,
  [`Non int slicing arguments`]: `Non-int dilimleme bağımsız değişkenleri`,
  [`None`]: `Hiçbiri`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Yapılandırılan OAuth sağlayıcılarının hiçbiri bu API'nin ağ geçidi tipiyle eşleşmiyor`,
  [`Normal`]: `Normal`,
  [`Not Available`]: `Kullanılamıyor`,
  [`Not applicable for application grant type.`]: `Uygulama izin tipi için geçerli değil.`,
  [`Not valid before`]: `Şu tarihten önce geçerli değil`,
  [`Not valid after`]: `Şu tarihten sonra geçerli değil`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Not: Bu seçenek, 'Otomatik ürün oluştur' seçeneği belirlendiğinde devre dışı bırakılır.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Not: Bu seçenek 'Otomatik ürün oluştur' seçildiğinde devre dışı bırakılır ve bunun yerine oluşturulan ürünün Varsayılan Planı kullanılır`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Not: Ağ Geçidi Hizmetleri tercihi, diğer tüm tercihler için kapsam olarak kullanılacak. İlkeler ve diğer Ağ Geçidi Hizmetine özgü içerik bu ayara göre yüklenecek.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Not: Bu seçenek yalnızca bilgi amaçlıdır ve yukarıdaki test uygulaması seçeneğine dayalı olarak güncellenir`,
  [`Notices`]: `Uyarılar`,
  [`Notification template`]: `Bildirim şablonu`,
  [`Notification templates`]: `Bildirim şablonları`,
  [`Notification server configured`]: `Bildirim sunucusu yapılandırıldı`,
  [`Notifications`]: `Bildirimler`,
  [`Null Badgerfish`]: `Boş Değerli Badgerfish`,
  [`Null`]: `Boş Değerli`,
  [`Number`]: `Numara`,
  [`Number of Operations`]: `İşlem Sayısı`,
  [`Numeric`]: `Sayısal`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth Sağlayıcısı`,
  [`OAuth provider`]: `OAuth sağlayıcısı`,
  [`OAuth Provider Policy Assembly`]: `OAuth Sağlayıcısı İlke Derlemesi`,
  [`OAuth providers`]: `OAuth sağlayıcıları`,
  [`OAuth Redirect URL`]: `OAuth Yeniden Yönlendirme URL'si`,
  [`OAuth shared secret`]: `OAuth paylaşılan güvenlik dizgisi`,
  [`OAuth provider policy assembly`]: `OAuth sağlayıcısı ilke derlemesi`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `OAuth sağlayıcısı {currentProviderName} bulunamadı. Lütfen yapılandırılmış bir OAuth sağlayıcısı seçin.`,
  [`OAuth redirect URL`]: `OAuth yeniden yönlendirme URL'si`,
  [`OAuth Shared Secret`]: `OAuth Paylaşılan Güvenlik Dizgisi`,
  [`OAuth Signature Method`]: `OAuth İmza Yöntemi`,
  [`OAuth signature method`]: `OAuth imza yöntemi`,
  [`OAuth providers can be created and managed in the following list.`]: `OAuth sağlayıcıları aşağıdaki listede yaratılabilir ve yönetilebilir.`,
  [`OAuth symmetric key name`]: `OAuth simetrik anahtar adı`,
  [`OAuth symmetric key value`]: `OAuth simetrik anahtar değeri`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2 Erişim Kodu Güvenliği`,
  [`OAuth2 Application Security`]: `OAuth2 Uygulama Güvenliği`,
  [`OAuth2 Implicit Security`]: `OAuth2 Örtülü Güvenliği`,
  [`OAuth2 Password Security`]: `OAuth2 Parola Güvenliği`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `Tamam`,
  [`OPERATION ID`]: `İŞLEM TANITICISI`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `SİPARİŞ`,
  [`ORGANIZATION`]: `KURULUŞ`,
  [`ORGANIZATION MANAGER`]: `KURULUŞ YÖNETİCİSİ`,
  [`Organizations`]: `Kuruluşlar`,
  [`Owner`]: `Sahip`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Alanlar etkinleştirildiğinde Oauth sağlayıcıları, her bir alanda yönetilir. Lütfen oauth sağlayıcılarını yönetmek için alan ayarlarına gidin.`,
  [`Off`]: `Kapalı`,
  [`Offline`]: `Çevrimdışı`,
  [`Old schema`]: `Eski şema`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch, bir sağlayıcı kuruluş ya da bir katalog içinde API'ler, Ürünler, Uygulamalar, Abonelikler ve diğer kaynaklar için arama yapma olanağı sağlar. Ayrıca, bu kaynaklar arasındaki ilişkileri keşfetmede de yardımcı olabilir.`,
  [`Omnisearch for all supported list pages`]: `Desteklenen tüm liste sayfaları için Omnisearch`,
  [`Omnisearch for draft APIs and products`]: `Taslak API'ler ve ürünler için Omnisearch`,
  [`Omnisearch for published products in catalogs and spaces`]: `Kataloglarda ve alanlarda yayınlanan ürünler için Omnisearch`,
  [`Omnisearch for consumer organizations`]: `Tüketici kuruluşları için Omnisearch`,
  [`Omnisearch for applications`]: `Uygulamalar için Omnisearch`,
  [`Omnisearch for requested approvals`]: `İstenen onaylar için Omnisearch`,
  [`Omnisearch for approvals tasks`]: `Onay görevleri için Omnisearch`,
  [`Omnisearch for product apis`]: `Ürün api'leri için Omnisearch`,
  [`Omnisearch for subscriptions`]: `Abonelikler için Omnisearch`,
  [`Omnisearch for members`]: `Üyeler için Omnisearch`,
  [`Omnisearch for provider organizations`]: `Sağlayıcı kuruluşları için Omnisearch`,
  [`Disable the new editor for OpenAPI2`]: `OpenAPI2 için yeni düzenleyiciyi devre dışı bırak`,
  [`On`]: `Açık`,
  [`Onboarding`]: `Katılım`,
  [`Onboarding Edit Admin Org`]: `Katılım - Yönetici Kuruluşunu Düzenle`,
  [`Onboarding Edit Cloud Org`]: `Katılım - Bulut Kuruluşunu Düzenle`,
  [`Onboarding Edit Reset Password`]: `Katılım - Parola Sıfırlamayı Düzenle`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Kurulduktan sonra, API Connect'i yetkilendirdiğiniz bölgeyi ve hesabı hedeflemek için IBM Cloud CLI'sini kullanın, ardından tüm API Connect sağlayıcı kuruluşlarınızı listeleyerek CLI'yi deneyin`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `API tarafından kullanılan ve üretilen şemaları açıklayan bir veya daha çok JSON nesnesi.`,
  [`One or more JSON representations for parameters`]: `Parametreler için bir ya da daha fazla JSON gösterimi`,
  [`One time use access token`]: `Tek kullanımlık erişim belirteci`,
  [`One time use refresh token`]: `Tek kullanımlık yenileme belirteci`,
  [`Online`]: `Çevrimiçi`,
  [`Only one API file should be present in the zip file.`]: `Zip dosyasında yalnızca bir API dosyası mevcut olmalıdır.`,
  [`OpenAPI Specification Version`]: `OpenAPI Özellik Sürümü`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC), OAuth 2.0'a dayalı ek bir kimlik doğrulama iletişim kuralı tabanı sağlar. OIDC, kullanıcı bilgilerini bir JSON Web Belirteci ya da JWT içinde kodlanmış olarak sağlar. OpenID Connect'i etkinleştirdiğinizde, erişim belirteçlerinin yanı sıra kimlik belirteçleri oluşturulması için bir şablon sağlanır ve gerekli derleme ilkeleri otomatik olarak oluşturulur.`,
  [`Open a Directory`]: `Dizin Aç`,
  [`Open in external editor`]: `Dış düzenleyicide aç`,
  [`Open the server`]: `Sunucuyu aç`,
  [`OpenAPI extensions`]: `OpenAPI uzantıları`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `Ağ Geçidi sürümü 6000 olduğunda OpenID Connect desteklenmez`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `OpenID Connect ayarları, yalnızca seçtiğiniz izin verme tipleriniz şunlardan birini ya da birkaçını içerirse geçerlidir: Implicit ve Access code.`,
  [`Operation`]: `İşlem`,
  [`Operation Id`]: `İşlem Tanıtıcısı`,
  [`Operation switch`]: `İşlem anahtarı`,
  [`Operation Switch`]: `İşlem Geçiş Anahtarı`,
  [`Operations`]: `İşlemler`,
  [`Operator build date`]: `İşleç oluşturma tarihi`,
  [`Operator build tag`]: `İşleç oluşturma etiketi`,
  [`Operator version`]: `İşleç sürümü`,
  [`Optionally select a CA bundle`]: `İsteğe bağlı olarak bir CA paketi seç`,
  [`Optimize schema definition`]: `Şema tanımını eniyile`,
  [`Optional`]: `İsteğe bağlı`,
  [`Or`]: `Veya`,
  [`Or create a new application and subscribe to the selected plan`]: `Ya da yeni bir uygulama yaratın ve seçilen plana abone olun`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Ya da yeni bir ürün yaratın ve Kum Havuzu kataloğunuza yayınlayın`,
  [`Order`]: `Sırala`,
  [`Org`]: `Kuruluş`,
  [`Org owner`]: `Kuruluş sahibi`,
  [`Organization`]: `Kuruluş`,
  [`Organization:`]: `Kuruluş:`,
  [`Organization information`]: `Kuruluş bilgileri`,
  [`Organization Manager`]: `Kuruluş Yöneticisi`,
  [`Organization has been updated.`]: `Kuruluş güncellendi.`,
  [`Organizations will be listed here.`]: `Kuruluşlar burada listelenir.`,
  [`Otherwise`]: `Tersi durumda`,
  [`Output`]: `Çıkış`,
  [`Output Claims`]: `Çıkış Talepleri`,
  [`Output array`]: `Çıkış dizisi`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `XML belgesini düzgün biçimli hale getirmek için gerekmese de kök öğenin çıkışını yapın.`,
  [`Output variable`]: `Çıkış değişkeni`,
  [`Override API consume types`]: `API tüketme tiplerini geçersiz kıl`,
  [`Override API produce types`]: `API üretme tiplerini geçersiz kıl`,
  [`Override API security definitions`]: `API güvenlik tanımlarını geçersiz kıl`,
  [`Override rate limits`]: `Hız sınırlarını geçersiz kıl`,
  [`Override default "provider" settings with configuration from this URL`]: `Varsayılan "sağlayıcı" ayarlarını, bu URL'deki yapılandırmayla geçersiz kıl`,
  [`Override default "provider" settings with configuration from this literal`]: `Varsayılan "sağlayıcı" ayarlarını, bu hazır bilgideki yapılandırmayla geçersiz kıl`,
  [`Override plan rate limits`]: `Plan hız sınırlarını geçersiz kıl`,
  [`Override plan rate limits for individual operation`]: `Tek bir işlem için plan hız sınırlarını geçersiz kıl`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Katalog daveti zamanaşımıyla tüketici kuruluşları davet zamanaşımını geçersiz kıl`,
  [`Overview`]: `Genel Bakış`,
  [`Owner scope check`]: `Sahip kapsamı denetimi`,
  [`Owner's Email`]: `Sahibin E-postası`,
  [`Owner's Name`]: `Sahibinin Adı`,
  [`Owner's email`]: `Sahibin e-postası`,
  [`Owns and administers the API provider organization`]: `API sağlayıcı kuruluşunun sahibidir ve kuruluşu yönetir`,
  [`Owns and administers the admin organization`]: `Yönetici kuruluşun sahibi ve yöneticisidir`,
  [`Owns and administers the app developer organization`]: `Uygulama geliştirici kuruluşa sahip ve yönetir`,
  [`Owns and administrates the app developer organization`]: `Uygulama geliştirici kuruluşuna sahiptir ve bunu yönetir`,
  [`PARAMETER NAME`]: `PARAMETRE ADI`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `Hedef Kitle (aud) talebini doğrulamak için kullanılacak PCRE.`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `Düzenleyen (iss) talebini doğrulamak için kullanılacak PCRE.`,
  [`PER`]: `HER BİRİ İÇİN`,
  [`Per`]: `Her biri için`,
  [`Plan`]: `Plan`,
  [`Plan:`]: `Plan:`,
  [`pending Consumer organization invitation`]: `bekleyen tüketici kuruluş daveti`,
  [`PLAN`]: `PLAN`,
  [`PLANS`]: `PLANLAR`,
  [`Policy`]: `İlke`,
  [`policy`]: `ilke`,
  [`POLICIES`]: `İLKELER`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `ÜRÜN`,
  [`Product Plan`]: `Ürün Planı`,
  [`Product Plans`]: `Ürün Planları`,
  [`Product Plans per page`]: `Sayfa başına Ürün Planları`,
  [`Property name`]: `Özellik adı`,
  [`Property type`]: `Özellik tipi`,
  [`Property example`]: `Özellik örneği`,
  [`Property description`]: `Özellik açıklaması`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Sayfa Bulunamadı`,
  [`Parameter name`]: `Parametre adı`,
  [`Parameter control`]: `Parametre denetimi`,
  [`Parameters`]: `Parametreler`,
  [`Parent`]: `Üst`,
  [`Parse`]: `Ayrıştır`,
  [`Parse settings URL reference`]: `Ayrıştırma ayarları URL başvurusu`,
  [`Parse settings literal configuration`]: `Ayrıştırma ayarları hazır bilgi yapılandırması`,
  [`Parse settings object reference`]: `Ayrıştırma ayarları nesne başvurusu`,
  [`Parse the GraphQL query`]: `GraphQL sorgusunu ayrıştır`,
  [`Parse the response from the GraphQL backend server`]: `GraphQL arka uç sunucusundan yanıtı ayrıştır`,
  [`Parse XML output`]: `XML çıkışını ayrıştır`,
  [`Pass-through`]: `Düzgeçiş`,
  [`Password`]: `Parola`,
  [`password-changed`]: `parola-değiştirildi`,
  [`Password changed for your user account in IBM API Connect`]: `IBM API Connect'te kullanıcı hesabınız için parola değiştirildi`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `{portalTitle} geliştirici portalında kullanıcı hesabınız için parola değiştirildi`,
  [`Password context variable`]: `Parola bağlam değişkeni`,
  [`Password is invalid. Re-enter the password.`]: `Parola geçersiz. Parolayı yeniden girin.`,
  [`password-reset`]: `parola-sıfırlandı`,
  [`Password reset request for your user account in IBM API Connect`]: `IBM API Connect'te kullanıcı hesabınız için parola sıfırlama isteği`,
  [`Password reset request for your {{catalog}} developer portal account`]: `{catalog} geliştirici portalı hesabınız için parola sıfırlama isteği`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Parolalar şu kategorilerin ikisinden en az bir karakter içermelidir: küçük harf, büyük harf, sayı ve noktalama işaretleri (örn. !, $, #, %).`,
  [`Passwords must contain eight or more characters`]: `Parolalar sekiz ya da daha fazla karakterden oluşmalıdır`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Parolalar, sekiz ya da daha fazla karakterden oluşmalı ve şu kategorilerin ikisinden en az birer karakter içermelidir: küçük harf, büyük harf, sayı ve noktalama işaretleri (örn. !, $, #, %). Bununla birlikte, aynı karakter ardışık olarak en fazla iki kez kullanılabilir.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Parolalarda aynı karakter ardışık olarak en fazla iki kez kullanılabilir.`,
  [`Passwords should match`]: `Parolalar eşleşmelidir`,
  [`Path`]: `Yol`,
  [`Path parameters`]: `Yol parametreleri`,
  [`Path name`]: `Yol adı`,
  [`Paths`]: `Yollar`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Yollar, API tarafından gösterilen REST kaynaklarını tanımlar. Bir işlem HTTP komutu, parametreler, istek ve yanıt tanımlarıyla bir yolu birleştirir.`,
  [`Payments Authorization`]: `Ödemeler Yetkisi`,
  [`Payment method`]: `Ödeme yöntemi`,
  [`Pending`]: `Beklemede`,
  [`Pending State`]: `Beklemede Durumu`,
  [`Pending approval to be published in catalog`]: `Katalogda yayınlanmak üzere onay bekleniyor`,
  [`Pending approval to be staged in catalog`]: `Katalogda hazırlanmak üzere onay bekleniyor`,
  [`Pending consumer organization invitations`]: `Bekleyen tüketici kuruluş davetleri`,
  [`View endpoints`]: `Uç noktaları görüntüle`,
  [`View base endpoints for this API`]: `Bu API için temel uç noktaları görüntüle`,
  [`View pending invitations`]: `Bekleyen davetleri görüntüle`,
  [`Permission Denied`]: `İzin Reddedildi`,
  [`Permissions`]: `İzinler`,
  [`Persistent Connection`]: `Kalıcı Bağlantı`,
  [`Pin menu`]: `Sabitleme menüsü`,
  [`PlainText`]: `Düz Metin`,
  [`Plan APIs`]: `Plan API'leri`,
  [`Plan burst limits`]: `Plan veri bloğu sınırları`,
  [`Plan by name`]: `Ada göre Plan`,
  [`Plan Default`]: `Plan Varsayılanı`,
  [`Plan Details`]: `Plan Ayrıntıları`,
  [`Plan migration targets`]: `Plan geçiş hedefleri`,
  [`Plan rate limits`]: `Plan hız sınırları`,
  [`Plan name`]: `Plan adı`,
  [`Plan pricing`]: `Plan fiyatlandırması`,
  [`Plan title already exists`]: `Plan başlığı zaten var`,
  [`Plans`]: `Planlar`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Yönetici ve sağlayıcı API'leri için platform REST API uç noktası`,
  [`Platform REST API endpoint for consumer APIs`]: `Tüketici API'leri için platform REST API'si uç noktası`,
  [`Please choose a bind method`]: `Lütfen bir bağ tanımlama yöntemi seçin`,
  [`Please choose an authentication method`]: `Lütfen bir kimlik doğrulama yöntemi seçin`,
  [`Please contact the system administrator.`]: `Lütfen sistem yöneticisiyle görüşün.`,
  [`Please contact your administrator for support.`]: `Lütfen destek için sistem yöneticinizle iletişim kurun.`,
  [`Please enter the reason.`]: `Lütfen nedeni girin.`,
  [`Please enter your username`]: `Lütfen kullanıcı adınızı girin`,
  [`Please go to the space settings to manage API endpoints.`]: `Lütfen API uç noktalarını yönetmek için alan ayarlarına gidin.`,
  [`Please make sure the server is online and try again.`]: `Lütfen sunucunun çevrimiçi olduğundan emin olun ve yeniden deneyin.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Etkinlik günlüğü devre dışı bırakıldığında başarı veya hata durumunda kaydedilecek içerik tipiyle ilişkili özelliklerin kaldırılacağını lütfen göz önünde bulundurun. Bunu geçersiz kılmak istediğinizden emin misiniz?`,
  [`Please review the license for API Connect by accessing`]: `Lütfen şuna erişerek API Connect lisansını gözden geçirin`,
  [`Please select a TLS`]: `Lütfen bir TLS seçin`,
  [`Please select a TLS client profile`]: `Lütfen bir TLS istemci profili seçin`,
  [`Please select a group type`]: `Lütfen bir grup tipi seçin`,
  [`Please select an LDAP protocol version`]: `Lütfen bir LDAP iletişim kuralı sürümü seçin`,
  [`Please select authentication method`]: `Lütfen kimlik doğrulama yöntemini seçin`,
  [`Please select ciphers from the list below:`]: `Lütfen aşağıdaki listeden şifreleri seçin:`,
  [`Please select consumer organization`]: `Lütfen tüketici kuruluş seçin`,
  [`Please select one Keystore from the list below:`]: `Lütfen aşağıdaki listeden bir Anahtar Deposu seçin:`,
  [`Please select one keystore from the list below:`]: `Lütfen aşağıdaki listeden bir anahtar deposu seçin:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Lütfen bu yeni uygulama için istemci tanıtıcısını ve güvenlik dizgisini not edin. Güvenlik dizgisi tekrar alınamayacağından bunu güvenli bir yerde saklayın. Öte yandan gerektiğinde güvenlik dizgisi sıfırlanabilir.`,
  [`Please try again in a few minutes.`]: `Lütfen birkaç dakika sonra yeniden deneyin.`,
  [`Please refresh catalog/space product list page and try again.`]: `Lütfen katalog/alan ürün listesi sayfasını yenileyin ve yeniden deneyin.`,
  [`Policies`]: `İlkeler`,
  [`Policy Assemblies`]: `İlke Derlemeleri`,
  [`Policy assembly`]: `İlke derlemesi`,
  [`Policy description.`]: `İlke açıklaması.`,
  [`Policy title`]: `İlke başlığı`,
  [`Policy has been uploaded.`]: `İlke karşıya yüklendi.`,
  [`Polish`]: `Lehçe`,
  [`Port`]: `Kapı`,
  [`Portal`]: `Portal`,
  [`Portal details`]: `Portal ayrıntıları`,
  [`Portal Director TLS client profile`]: `Portal Director TLS istemci profili`,
  [`Portal Director keystore`]: `Portal Director anahtar deposu`,
  [`Portal Director truststore`]: `Portal Director güven deposu`,
  [`Portal service`]: `Portal hizmeti`,
  [`Portal Service`]: `Portal Hizmeti`,
  [`Portal service name`]: `Portal hizmeti adı`,
  [`Portal service details`]: `Portal hizmeti ayrıntıları`,
  [`Portal Service and URL`]: `Portal Hizmeti ve URL'si`,
  [`Portal service {name} was successfully created.`]: `{name} Portal hizmeti başarıyla yaratıldı.`,
  [`Portal service {name} was successfully updated.`]: `{name} Portal hizmeti başarıyla güncellendi.`,
  [`Portal Service {arg} has been removed.`]: `Portal Hizmeti {arg} kaldırıldı.`,
  [`Portal site`]: `Portal sitesi`,
  [`Portal URL`]: `Portal URL'si`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Portal sitesi URL adresleri 200 karakterden uzun olamaz`,
  [`Portal website URL`]: `Portal web sitesi URL'si`,
  [`Portal and Gateway Settings`]: `Portal ve Ağ Geçidi Ayarları`,
  [`Portal has been created`]: `Portal yaratıldı`,
  [`Portal has been created.`]: `Portal yaratıldı.`,
  [`Portal has been deleted`]: `Portal silindi`,
  [`Portals`]: `Portallar`,
  [`Portuguese`]: `Portekizce`,
  [`Post Response`]: `Son Yanıt`,
  [`Post-Invoke Hook`]: `Çağırma Sonrası Kancası`,
  [`Preserve subscriptions on re-publish`]: `Yeniden yayında abonelikleri koru`,
  [`Preserve subscriptions on re-publish product`]: `Yeniden yayında abonelikleri koru`,
  [`Pre-Invoke Hook`]: `Çağırma Öncesi Kancası`,
  [`Pre-Request`]: `Ön İstek`,
  [`Preference`]: `Tercih`,
  [`Prefix`]: `Önek`,
  [`Preparing API`]: `API hazırlanıyor`,
  [`Preserved request header`]: `Korunan istek üstbilgisi`,
  [`preserved request header`]: `korunan istek üstbilgisi`,
  [`Preserved response header`]: `Korunan yanıt üstbilgisi`,
  [`preserved response header`]: `korunan yanıt üstbilgisi`,
  [`Preview`]: `Önizleme`,
  [`Preview of login screen registry prompt`]: `Oturum açma ekranı kayıt bilgi isteminin önizlemesi`,
  [`Previous`]: `Önceki`,
  [`Previous page`]: `Önceki sayfa`,
  [`Prettify`]: `Düzgün duruma getir`,
  [`Price per month`]: `Ay başına fiyat`,
  [`Private`]: `Gizli`,
  [`Private Claims`]: `Özel Talepler`,
  [`Private key & public key`]: `Özel anahtar ve genel anahtar`,
  [`Private Key or Shared Secret for Decrypt`]: `Şifre Çözme için Özel Anahtar ya da Paylaşılan Güvenlik Dizgisi`,
  [`Private Key or Shared Secret for Sign`]: `İmzalama için Özel Anahtar ya da Paylaşılan Güvenlik Dizgisi`,
  [`Private key detected`]: `Özel anahtar algılandı`,
  [`Private key password`]: `Özel anahtar parolası`,
  [`Processed`]: `İşlendi`,
  [`Problem`]: `Sorun`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `İzleme verileri getirilirken sorun oluştu. Lütfen yeniden deneyin. Hata ayrıntıları için konsola bakın.`,
  [`Produced Media Types`]: `Üretilen Ortam Tipleri`,
  [`Produces`]: `Üretir`,
  [`Product`]: `Ürün`,
  [`Product:`]: `Ürün:`,
  [`Product / Plan`]: `Ürün / Plan`,
  [`Product APIs`]: `Ürün API'leri`,
  [`Product editor`]: `Ürün düzenleyici`,
  [`Product lifecycle`]: `Ürün yaşam çevrimi`,
  [`Product Lifecycle`]: `Ürün Yaşam Çevrimi`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Ürün adı '-auto-product' ile bitemez. Lütfen adı değiştirin ve içe aktarmayı yeniden deneyin.`,
  [`Product setup`]: `Ürün kurulumu`,
  [`Product details`]: `Ürün ayrıntıları`,
  [`Product documentation`]: `Ürün belgeleri`,
  [`Product has been created.`]: `Ürün yaratıldı`,
  [`Product has been deleted`]: `Ürün silindi`,
  [`Product has been updated`]: `Ürün güncellendi`,
  [`Product has been updated.`]: `Ürün güncellendi.`,
  [`Product has not been created!`]: `Ürün yaratılmadı!`,
  [`Product has not been published!`]: `Ürün yayınlanmadı!`,
  [`Product has not been updated!`]: `Ürün güncellenmedi!`,
  [`Product has not been staged!`]: `Ürün hazırlanmadı!`,
  [`Product information`]: `Ürün bilgileri`,
  [`Product summary details`]: `Ürün özeti ayrıntıları`,
  [`Product version`]: `Ürün sürümü`,
  [`Product visibility`]: `Ürün görünürlüğü`,
  [`Product-Approval`]: `Ürün-Onayı`,
  [`Product-Drafts`]: `Ürün-Taslaklar`,
  [`Product/plan`]: `Ürün/plan`,
  [`Production`]: `Üretim`,
  [`Production mode`]: `Üretim kipi`,
  [`Production endpoints`]: `Üretim uç noktaları`,
  [`Products`]: `Ürünler`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Ürünler bir Katalog için hazırlanmalı ve uygulama geliştiricilerinin kullanabilmesi için yayınlanmalıdır. Bir Katalog, ağ geçidinin ve Geliştirici Portalın mantıksal bölümü olarak çalışır. API çağrıları ve Geliştirici Portal URL adresleri belirli bir Kataloğa özeldir. Genel bir yapılandırmada bir API sağlayıcı kuruluşu, geliştirilmekte olan API testi için bir geliştirme Kataloğu ve tam kullanıma hazır API'lerin barındırılması için bir üretim Kataloğu kullanır.`,
  [`Profile`]: `Profil`,
  [`Profile rule`]: `Profil kuralı`,
  [`Promote to production`]: `Üretime yükselt`,
  [`Proof Key for Code Exchange`]: `Kod Değişimi İçin Kanıt Anahtarı`,
  [`Proof Key for Code Exchange (PKCE)`]: `Kod Değişimi İçin Kanıt Anahtarı (PKCE)`,
  [`Properties`]: `Özellikler`,
  [`Property`]: `Özellik`,
  [`Property Description`]: `Özellik Açıklaması`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `{{target}} özelliğine, aşağıya değer girilerek bir statik değer atanabilir.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `{{target}} özelliği şu anda {{from}} ile eşlendi; eşleme aşağıdan kaldırılabilir.`,
  [`Property Value is Base64 Encoded`]: `Özellik Değeri Base64 Kodludur`,
  [`Protocol`]: `İletişim kuralı`,
  [`Protocols`]: `İletişim kuralları`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `API'yi çağırmak için kullanılabilecek iletişim kuralları (ağ geçidi tarafından zorunlu kılınan API'ler için yalnızca https desteklenir)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Kullanıcıların kimliğini doğrulamak ve OpenID sağlayıcısının API'lerine erişim elde etmek için OAuth {oauthVer} kimlik bilgilerini sağlayın`,
  [`Provide a schema`]: `Şema belirtin`,
  [`Provide GraphQL schema`]: `GraphQL şemasını sağlayın`,
  [`Provide the URL to the backend GraphQL server`]: `Arka uç GraphQL sunucusu URL'sini sağlayın`,
  [`Provide your email address to receive password reset instructions`]: `Parola sıfırlama yönergelerini almak için e-posta adresinizi sağlayın`,
  [`Provide your new password below`]: `Aşağıya yeni parolanızı girin`,
  [`Provider endpoint`]: `Sağlayıcı uç noktası`,
  [`Provider Information`]: `Sağlayıcı Bilgileri`,
  [`Provider Organization`]: `Sağlayıcı Kuruluşu`,
  [`Provider Organizations`]: `Sağlayıcı Kuruluşları`,
  [`Provider Type`]: `Sağlayıcı Tipi`,
  [`Provider organizations`]: `Sağlayıcı kuruluşları`,
  [`Provider type`]: `Sağlayıcı tipi`,
  [`Provider org invite failed`]: `Sağlayıcı kuruluşu daveti başarısız oldu`,
  [`Provider organization {name} created`]: `{name} adlı sağlayıcı kuruluşu yaratıldı`,
  [`Provider organizations will be listed here`]: `Sağlayıcı kuruluşlar burada listelenir`,
  [`Provider-Org`]: `Sağlayıcı-Kuruluşu`,
  [`Provides contact information for the exposed API.`]: `Gösterilen API için iletişim bilgileri sağlar.`,
  [`Provides license information for the exposed API.`]: `Gösterilen API için lisans bilgilerini sağlar.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `API ile ilgili meta veri sağlar. Meta veriler gerekirse istemciler tarafından kullanılabilir.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `Geliştirici portalın yetkilendirmesi başladı. Tamamlanması birkaç dakika sürecek. Tamamlandığında portal yönetici hesabı parolasını ayarlamak için bir bağlantı içeren e-posta alacaksınız.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `Geliştirici portalı URL'sini güncelleme işlemi başlatıldı. İşlemin tamamlanması birkaç dakika sürecek.`,
  [`Proxy`]: `Yetkili Sunucu`,
  [`Proxy URL`]: `Yetkili sunucu URL'si`,
  [`Public`]: `Genel`,
  [`Public Key or Shared Secret for Encrypt`]: `Şifreleme için Genel Anahtar ya da Paylaşılan Güvenlik Dizgisi`,
  [`Publish`]: `Yayınla`,
  [`Publish API`]: `API'yi Yayınla`,
  [`Publish a product from the Develop section.`]: `Geliştirme bölümünden bir ürün yayınlayın.`,
  [`Publish product`]: `Ürünü yayınla`,
  [`Publish to`]: `Yayınlama hedefi:`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Abonelik yaratmak istediğiniz ürünleri bu katalogda yayınlayın.`,
  [`Publish target`]: `Yayınlama hedefi`,
  [`Publish this API to the Sandbox catalog.`]: `Bu API'yi Kum Havuzu kataloğunda yayınlayın.`,
  [`Publish to specific gateway services`]: `Belirli ağ geçidi hizmetlerine yayınla`,
  [`Publish using a new product`]: `Yeni ürün kullanarak yayınla`,
  [`Publish using an existing product`]: `Var olan bir ürün kullanarak yayınla`,
  [`Publishable Key`]: `Yayınlanabilir Anahtar`,
  [`Publishable key`]: `Yayınlanabilir anahtar`,
  [`Publishable key tip`]: `Yayınlanabilir anahtar ipucu`,
  [`Published`]: `Yayınlandı`,
  [`Published product`]: `Yayınlanan ürün`,
  [`Publishing`]: `Yayınlama`,
  [`Publishing New API`]: `Yeni API yayınlanıyor`,
  [`Publish (preserve subscriptions)`]: `Yayınla (abonelikleri koru)`,
  [`Publish|permission`]: `Yayınla`,
  [`Put online`]: `Çevrimiçine al`,
  [`Query`]: `Sorgu`,
  [`Queryparam Encode`]: `Queryparam Encode`,
  [`Queued`]: `Kuyruğa alınan`,
  [`Queued events: **{queued}**`]: `Kuyruğa alınan olaylar: **{queued}**`,
  [`Retry blocked jobs`]: `Engellenen işleri yeniden dene`,
  [`REQUIRED`]: `ZORUNLU`,
  [`ROLES`]: `ROLLER`,
  [`Rate limit`]: `Hız sınırı`,
  [`Rate Limit`]: `Oran Sınırı`,
  [`Rate Limit Name`]: `Hız Sınırı Adı`,
  [`Rate limits`]: `Hız sınırları`,
  [`Rate limit name can't be a duplicate`]: `Hız sınırı adı yinelenemez`,
  [`Rate limit name can't be empty`]: `Hız sınırı adı boş olamaz`,
  [`Rate limit overrides`]: `Hız sınırı geçersiz kılma değerleri`,
  [`Rate limit time interval can't be 0`]: `Hız sınırı aralığı 0 olamaz`,
  [`Rate limit time interval can't be empty`]: `Oran sınırı zaman aralığı boş olamaz`,
  [`Rate limit time interval unit must be selected`]: `Hız sınırı süre aralığı birimi seçilmelidir`,
  [`Rate limit value can't be empty`]: `Hız sınırı değeri boş olamaz`,
  [`Rate limit value can't be negative`]: `Hız sınırı değeri negatif olamaz`,
  [`Re-stage`]: `Yeniden Hazırla`,
  [`Re-stage product`]: `Ürünü yeniden hazırla`,
  [`Read only`]: `Salt okunur`,
  [`Read-Only`]: `Salt Okunur`,
  [`Realized`]: `Tanındı`,
  [`Reason`]: `Neden`,
  [`Reason for declining`]: `Reddetme nedeni`,
  [`Recommendations`]: `Öneriler`,
  [`Recommended configuration`]: `Önerilen yapılandırma`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Aşağıda gösterilen istemci tanıtıcısı ve güvenlik dizgisini daha sonra kullanmak üzere kaydedin. İstemci güvenlik dizgisi daha sonra görüntülenemez.`,
  [`Recommendations applied`]: `Öneriler uygulandı`,
  [`Redact`]: `Düzelt`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `İstek gövdesinden, Yanıt gövdesinden ve etkinlik günlüklerinden belirtilen alanları düzeltin ya da kaldırın. Bir XPath ifadesi belirterek düzeltilecek ya da kaldırılacak alanı tanımlayın.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `İstek gövdesinden, Yanıt gövdesinden ve etkinlik günlüklerinden belirtilen alanları düzeltin ya da kaldırın. Bir  XPath veya JSONata ifadesi belirterek düzeltilecek ya da kaldırılacak alanı tanımlayın.`,
  [`Redaction`]: `Metin Düzeltme`,
  [`Redactions`]: `Metin Düzeltmeleri`,
  [`Redirect`]: `Yeniden yönlendir`,
  [`Redirect URI`]: `Yeniden Yönlendirme URI'si`,
  [`Redirect URL`]: `Yeniden Yönlendirme URL'si`,
  [`Redirect through Portal`]: `Portal aracılığıyla yeniden yönlendir`,
  [`Redirect time limit`]: `Yeniden yönlendirme zaman sınırı`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `Yeniden yönlendirme süresi sınırı 10-600 saniye arasında olmalıdır`,
  [`References`]: `Başvurular`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Ek yönlendirme için bkz. [IBM Cloud Docs]({link}).`,
  [`Referral`]: `Başvuru kaynağı`,
  [`Refresh`]: `Yenile`,
  [`Refresh Token`]: `Belirteci Yenile`,
  [`Refresh button`]: `Yenile düğmesi`,
  [`Refresh products`]: `Ürünleri yenile`,
  [`Refresh results from server`]: `Sunucudan sonuçları yenile`,
  [`Refresh token`]: `Belirteci yenile`,
  [`Refresh token time to live`]: `Yenileme belirteci yaşam süresi`,
  [`Refresh token time-to-live`]: `Yenileme belirteci yaşam süresi`,
  [`Refresh token time-to-live (seconds)`]: `Yenileme belirteci yaşam süresi (saniye)`,
  [`Refresh token TTL has been changed`]: `Yenileme belirteci yaşam süresi değiştirildi`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Yenileme belirteci yaşam süresi:  **{seconds} saniye ({time})**`,
  [`Refresh token time to live is **off**`]: `Yenileme belirteci yaşam süresi: **off**`,
  [`Refresh tokens`]: `Belirteçleri yenile`,
  [`Refreshing`]: `Yenileniyor`,
  [`Refreshing...`]: `Yenileniyor...`,
  [`Region`]: `Bölge`,
  [`Register`]: `Kaydol`,
  [`Register Gateways`]: `Ağ Geçitlerini Kaydet`,
  [`Register Service`]: `Hizmeti Kaydet`,
  [`Register gateways`]: `Ağ geçitlerini kaydet`,
  [`Register service`]: `Hizmeti kaydet`,
  [`Register a service`]: `Bir hizmeti kaydet`,
  [`Register new services and manage existing services`]: `Yeni hizmetleri kaydedin ve var olan hizmetleri yönetin`,
  [`Register remote gateway`]: `Uzak ağ geçidini kaydet`,
  [`Registered Admin Member`]: `Kayıtlı Yönetici Üye`,
  [`Registered Provider Org Owner or Member`]: `Kayıtlı Sağlayıcı Kuruluş Sahibi veya Üyesi`,
  [`Registered client information on the OpenID provider`]: `OpenID sağlayıcısındaki kaydedilmiş istemci bilgileri`,
  [`Registration completed successfully`]: `Kayıt başarıyla tamamlandı`,
  [`Registration failed.`]: `Kayıt başarısız oldu.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Tek tek uç noktaların göreli yolları. Bunlar 'basePath' ile göreli olmalıdır.`,
  [`Relaxed Badgerfish`]: `Esnek Badgerfish`,
  [`Remove analysis configuration`]: `Analiz yapılandırmasını kaldır`,
  [`Remote API Connect`]: `Uzak API Connect`,
  [`Remote login`]: `Uzaktan oturum açma`,
  [`Remote publish`]: `Uzaktan yayınlama`,
  [`Remove`]: `Kaldır`,
  [`Remove Burst Limit`]: `Ayırma Sınırını Kaldır`,
  [`Remove constraints`]: `Kısıtlamaları kaldır`,
  [`Remove Count Limit`]: `Sayı Sınırını Kaldır`,
  [`Remove gateway extension`]: `Ağ geçidi uzantısını kaldır`,
  [`Remove product`]: `Ürünü kaldır`,
  [`Remove Rate limit`]: `Hız sınırını kaldır`,
  [`Remove the invalid constraints`]: `Geçersiz kısıtlamaları kaldır`,
  [`Remove the invalid constraints from`]: `Geçersiz kısıtlamaları kaldır`,
  [`Remove action`]: `İşlemi kaldır`,
  [`Remove case`]: `Durumu kaldır`,
  [`Remove catch`]: `Yakalamayı kaldır`,
  [`Remove filter`]: `Süzgeci kaldır`,
  [`Remove input`]: `Girişi kaldır`,
  [`Remove mapping`]: `Eşlemeyi kaldır`,
  [`Remove output`]: `Çıkışı kaldır`,
  [`Remove static value`]: `Statik değeri kaldır`,
  [`Removed`]: `Kaldırıldı`,
  [`Removed by user`]: `Kullanıcı tarafından kaldırıldı`,
  [`Reorder`]: `Yeniden Sırala`,
  [`Repeat`]: `Yinele`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `API yürütmesini, belirlenen sayı kadar ya da durdur düğmesi tıklatılıncaya kadar yineleyin`,
  [`Replace`]: `Başkasıyla değiştir`,
  [`Replace Free Trial with`]: `Ücretsiz Denemeyi Şununla Değiştir:`,
  [`Replace Gold with`]: `Altın'ı Şununla Değiştir:`,
  [`Replace GraphQL schema`]: `GraphQL şemasını değiştir`,
  [`Replace plans`]: `Planları başkalarıyla değiştir`,
  [`Replace private key & public key`]: `Özel anahtarı ve genel anahtarı değiştir`,
  [`Replace Product`]: `Ürünü Başkasıyla Değiştir`,
  [`Replace product`]: `Ürünü değiştir`,
  [`Replace schema`]: `Şemayı değiştir`,
  [`Replace Silver with`]: `Gümüş'ü Şununla Değiştir:`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Öneriler listesinden yeni sahibi seçerek geçerli sahibi değiştirin. Çalışmaya devam edebilmesi için, değiştirilen sahibe yeni izinler atayın.`,
  [`Replace|permission`]: `Başkasıyla değiştir`,
  [`Republish`]: `Yeniden Yayınla`,
  [`Republish product`]: `Ürünü yeniden yayınla`,
  [`Request`]: `İstek`,
  [`Request catch`]: `İstek yakalama`,
  [`Request endpoint`]: `İstek uç noktası`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `{catalog} kataloğundaki API ürünü için {action} isteği onaylandı`,
  [`Request approved to {{action}} app {{appName}}`]: `{appName} uygulaması {action} isteği onaylandı`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `{catalog} kataloğundaki API ürünü için {action} isteği reddedildi`,
  [`Request denied to {{action}} app {{appName}}`]: `{appName} uygulaması {action} isteği reddedildi`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `{catalog} kataloğundaki bir API ürününe abonelik onayı isteği`,
  [`Request for approval to {{action}} app {{appName}}`]: `{appName} uygulaması için {action} onay isteği`,
  [`Request headers`]: `İstek üstbilgileri`,
  [`Request pending to promote application to production`]: `Uygulamayı üretime yükseltmek için istek beklemede`,
  [`Request query`]: `İstek sorgusu`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `{catalog} kataloğundaki API ürünü için {action} isteği alındı`,
  [`Request received to {{action}} app {{appName}}`]: `{appName} uygulaması {action} isteği alındı`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `{catalog} kataloğundaki bir API ürününe abonelik için istek geri çekildi`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `{catalog} kataloğundaki bir API ürünü için {action} isteği geri çekildi`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `{appName} uygulaması için {action} isteği geri çekildi`,
  [`Requested approvals`]: `İstenen onaylar`,
  [`Requested By`]: `İsteyen`,
  [`Requested To`]: `İstenen`,
  [`Require`]: `Zorunlu kıl`,
  [`Require applications to provide a client ID`]: `Uygulamaların bir istemci tanıtıcısı sağlamasını gerektir`,
  [`Require approval for all new self service development accounts`]: `Tüm yeni self servis geliştirme hesapları için onay isteyin`,
  [`Required`]: `Zorunlu`,
  [`Required for rate limiting`]: `Oran sınırlama için zorunludur`,
  [`Required must be checked off when location is in path.`]: `Konum yol (path) olduğunda Zorunlu işareti kaldırılmalıdır.`,
  [`Required path`]: `Gerekli yol`,
  [`Resend Invite`]: `Daveti Yeniden Gönder`,
  [`Resend invitation`]: `Daveti yeniden gönder`,
  [`Resend invite`]: `Daveti yeniden gönder`,
  [`Reset`]: `Sıfırla`,
  [`Reset defaults`]: `Varsayılanları sıfırla`,
  [`Reset Notification template`]: `Sıfırlama Bildirimi şablonu`,
  [`Reset password`]: `Parolayı sıfırla`,
  [`Reset password TTL has been changed`]: `Parola sıfırlama TTL değeri değiştirildi`,
  [`Reset password time to live`]: `Parola sıfırlama yaşam süresi`,
  [`Reset password time-to-live`]: `Parola sıfırlama yaşam süresi`,
  [`Reset password email has been sent`]: `Parola sıfırlama e-postası gönderildi`,
  [`Reset password email has not been sent`]: `Parola sıfırlama e-postası gönderilmedi`,
  [`Reset password invitation timeout`]: `Parola sıfırlama davet zaman aşımı`,
  [`Reset to default preferences`]: `Varsayılan tercihlere sıfırla`,
  [`Resolve API Connect variable references`]: `API Connect değişkeni başvurularını çözümle`,
  [`Resolve XML input data type`]: `XML giriş veri tipini çözümle`,
  [`resolver`]: `çözümleyici`,
  [`Resource group`]: `Kaynak grubu`,
  [`Resource Owner`]: `Kaynak Sahibi`,
  [`Resource owner password grant`]: `Kaynak sahibi parola izni`,
  [`Resource owner revocation`]: `Kaynak sahibi iptali`,
  [`Resource owner revocation path`]: `Kaynak sahibi iptal yolu`,
  [`Resource Owner Security`]: `Kaynak Sahibi Güvenliği`,
  [`Resource owner - JWT`]: `Kaynak sahibi - JWT`,
  [`Resource owner - Password`]: `Kaynak sahibi - Parola`,
  [`Resource owner password`]: `Kaynak sahibi parolası`,
  [`Resources`]: `Kaynaklar`,
  [`Response`]: `Yanıt`,
  [`Response catch`]: `Yanıt yakalama`,
  [`Response Definition`]: `Yanıt Tanımlaması`,
  [`Response Time`]: `Yanıt Süresi`,
  [`Response type`]: `Yanıt tipi`,
  [`Response context variables`]: `Yanıt bağlamı değişkenleri`,
  [`Response headers`]: `Yanıt üstbilgileri`,
  [`Response must have a description`]: `Yanıtın bir açıklaması olmalıdır`,
  [`Response must have a schema`]: `Yanıtın bir şeması olmalıdır`,
  [`Response must have a status code`]: `Yanıtın bir durum kodu olmalıdır`,
  [`Response object variable`]: `Yanıt nesnesi değişkeni`,
  [`Response time`]: `Yanıt süresi`,
  [`Response time: {{value}}ms`]: `Yanıt süresi: {{value}}ms`,
  [`Result of removing`]: `Kaldırma sonucu`,
  [`Restrict HTTP transactions to version 1.0.`]: `HTTP işlemlerini 1.0 sürümüyle kısıtla`,
  [`Restrict to HTTP 1.0`]: `HTTP 1.0 ile kısıtla`,
  [`Retire`]: `Kullanımdan kaldır`,
  [`Retire product`]: `Ürünü kullanımdan kaldır`,
  [`Retired`]: `Kullanımdan kaldırıldı`,
  [`Retire|permission`]: `Kullanımdan kaldır`,
  [`Return V5 Responses`]: `V5 Yanıtları Getir`,
  [`Retry selected requests`]: `Seçilen istekleri yeniden dene`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `GraphQL yetkili sunucusundan standart GraphQL iç gözlem sorguları için sonuçları döndür`,
  [`Return third-party access_token`]: `Üçüncü taraf erişim belirtecini döndür`,
  [`Return third-party access_token with the token response`]: `Belirteç yanıtıyla birlikte üçüncü taraf erişim belirtecini (access_token) döndürün`,
  [`Return third-party id_token`]: `Üçüncü taraf kimlik belirtecini döndür`,
  [`Return third-party id_token with the token response`]: `Belirteç yanıtıyla birlikte üçüncü taraf kimlik belirtecini (id_token) döndürün`,
  [`Return to Login`]: `Oturum Açmaya Geri Dön`,
  [`Reveal in finder`]: `Bulucuda göster`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Şemada gösterilecekleri veya gizlenecekleri inceleyin ve belirleyin. Şemanın geçerliliğini korumak için ek değişiklikler gerekebilir.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Uyarıları gözden geçirin. Düzenleyicide daha sonra akıllı önerilerle şemanızı iyileştirmenize yardımcı olacağız.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Uyarıları gözden geçirin ve arka uçunuzu korumak ve şemanızı iyileştirmek için akıllı önerilerimizi uygulayın.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `API çağırma uç noktası temel URL'sini inceleyin ve ağ geçidinin işlediği etki alanlarını belirtin.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Ağ geçidi tipi, başlığı, adı ve özetini inceleyin. API Connect komutlarını ve API'lerini çağırırken ağ geçidi adını kullanın.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Şema geçerliliğini korumak için ek değişiklikler içerebilecek değişiklik özetini inceleyin.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Şemanızda bulduğumuz uyarıları gözden geçirin. Düzenleyicide daha sonra akıllı önerilerle arka ucunuzu korumaya ve şemanızı iyileştirmeye yardımcı olacağız.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Şemanızda bulduğumuz uyarıları gözden geçirin. Akıllı önerilerle şemanızı iyileştirmenize ve arka ucunuzu korumanıza yardımcı olacağız.`,
  [`Reviewed schema, paths and operations`]: `Şema, yollar ve işlemler gözden geçirildi`,
  [`Revocation protocol`]: `İptal iletişim kuralı`,
  [`Revoke token`]: `Belirteci iptal et`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL belgeleri`,
  [`Role`]: `Rol`,
  [`Role default`]: `Rol varsayılanı`,
  [`Role default has been deleted`]: `Rol varsayılan değeri silindi`,
  [`Role defaults`]: `Rol varsayılanları`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Rol varsayılan değerleri, yeni bir kuruluş yaratıldığında kullanılabilecek varsayılan rolleri belirlemek için kullanılan, önceden yapılandırılmış rol şablonlarıdır.`,
  [`Role has been created`]: `Rol yaratıldı`,
  [`Role has been deleted`]: `Rol silindi`,
  [`Role has been updated`]: `Rol güncellendi`,
  [`Roles`]: `Roller`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Roller (ve atanan izinler) API Connect'teki özelliklere erişimi denetler. Yönetici Kuruluş rolleri yalnızca Cloud Manager kullanan kişiler için geçerlidir.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Roller ve izinler, API Manager yeteneklerine erişimi denetler.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Roller ve ilişkili izinler, özelliklere erişimi ve API Connect'teki görevleri gerçekleştirebilme yeteneğini denetler.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Roller ve ilişkili izinler, özelliklere erişimi ve API Manager UI'deki görevleri gerçekleştirebilme yeteneğini denetler.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Roller ve ilişkili izinler, özelliklere erişimi ve görevleri gerçekleştirebilme yeteneğini denetler.`,
  [`Root`]: `Kök`,
  [`Root XML Element Name`]: `Kök XML Öğesi Adı`,
  [`Run the following command in the CLI to register the credentials:`]: `Kimlik bilgilerini kaydetmek için CLI'de şu komutu çalıştırın:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Toolkit kimlik bilgilerini kaydetmek için CLI'de şu komutu çalıştırın:`,
  [`Running`]: `Çalışıyor`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `JWT'nin şifresini çözmek için kullanılacak JWK'yi içeren çalıştırma zamanı değişkeni.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `JWT'yi şifrelemek için kullanılacak JWK'yi içeren çalıştırma zamanı değişkeni.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `JWT'yi imzalamak için kullanılacak JWK'yi içeren çalıştırma zamanı değişkeni.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `İmzayı doğrulamak için kullanılacak JWK'yi içeren çalıştırma zamanı değişkeni.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `İçinden geçerli bir JSON talepleri kümesinin alınabileceği çalıştırma zamanı değişkeni.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `İçinden Hedef Kitle (aud) talebi dizgisinin alınabileceği çalıştırma zamanı değişkeni. Virgülle ayrılmış dizgi aracılığıyla birden çok değişken ayarlanır.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `İçinden Düzenleyen (iss) talebi dizgisinin alınabileceği çalıştırma zamanı değişkeni. Bu talep, JWT'yi düzenleyen Birincil Kullanıcıyı temsil eder.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `İçinden Konu (sub) talebi dizgisinin alınabileceği çalıştırma zamanı değişkeni.`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Oluşturulan JWT'nin yerleştirileceği çalıştırma zamanı değişkeni. Ayarlanmazsa, JWT, Yetkilendirme Üstbilgisine Taşıyıcı belirteci olarak yerleştirilir.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Kimliği doğrulanmış kullanıcı adını içeren çalışma zamanı değişkeni. Kullanıcı olarak bu özellikle LTPA belirteci oluşturulur. Örneğin, $(client.app.id) ya da $(oauth.resource-owner) veya değişken ayarla ilkesinde yapılandırılan bir çalışma zamanı değişkeni.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `JWT içindeki tam talep kümesinin atandığı çalıştırma zamanı değişkeni.`,
  [`Russian`]: `Rusça`,
  [`Scalar`]: `Sayıl`,
  [`Schema`]: `Şema`,
  [`SCHEMA`]: `ŞEMA`,
  [`SCOPE`]: `KAPSAM`,
  [`SCOPES`]: `KAPSAMLAR`,
  [`SECURITY DEFINITIONS`]: `GÜVENLİK TANIMLARI`,
  [`Service`]: `Hizmet`,
  [`Service state:`]: `Hizmet durumu:`,
  [`SERVICE TYPE`]: `HİZMET TİPİ`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1 lisansı`,
  [`SOURCE`]: `KAYNAK`,
  [`SSL Profile`]: `SSL Profili`,
  [`State`]: `Durum`,
  [`STATUS`]: `DURUM`,
  [`Status Code`]: `Durum Kodu`,
  [`STATUS CODE`]: `DURUM KODU`,
  [`SUBMITTED`]: `GÖNDERİLDİ`,
  [`SUBSCRIBERS`]: `ABONELER`,
  [`Summary`]: `Özet`,
  [`Same as product`]: `Ürün ile aynı`,
  [`Sample ID token signing key (RS256)`]: `Örnek tanıtıcı belirteci imzalama anahtarı (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Örnek tanıtıcı belirteci doğrulama anahtarı (RS256)`,
  [`Sample OAuth symmetric key value`]: `Örnek OAuth simetrik anahtar değeri`,
  [`Sample URL: https://api.catalog.org.host/`]: `Örnek URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `Örnek URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Örnek kapsam açıklaması 1`,
  [`Sandbox - Overview`]: `Kum Havuzuna Genel Bakış`,
  [`Sandbox Catalog`]: `Kum Havuzu Kataloğu`,
  [`Sandbox Catalog User Registry`]: `Kum Havuzu Kataloğu Kullanıcı Kayıt Defteri`,
  [`Sandbox Default TLS client profile`]: `Kum Havuzu Varsayılan TLS istemci profili`,
  [`Sandbox Default TLS Client Profile`]: `Kum Havuzu Varsayılan TLS İstemci Profili`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Kum havuzu kataloğu her zaman bir sağlayıcı kuruluşta bulunmalıdır.`,
  [`Save`]: `Kaydet`,
  [`Save API as a new version`]: `API'yi yeni bir sürüm olarak kaydet`,
  [`Save Product as a new version`]: `Ürünü yeni bir sürüm olarak kaydet`,
  [`Save and Edit`]: `Kaydet ve Düzenle`,
  [`Save and republish`]: `Kaydet ve yeniden yayınla`,
  [`Save as a new version`]: `Yeni sürüm olarak kaydet`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `İstemci güvenlik dizgisini kaydet (güvenlik nedeniyle artık alınamayacaktır)`,
  [`Save this product as a new version`]: `Bu ürünü yeni sürüm olarak kaydet`,
  [`Saved`]: `Kaydedildi`,
  [`Saving assembly...`]: `Derleme kaydediliyor...`,
  [`Saving...`]: `Kaydediliyor...`,
  [`Schema Type`]: `Şema Tipi`,
  [`Schema as JSON`]: `JSON olarak şema`,
  [`Schema as XML`]: `XML olarak şema`,
  [`Schema as YAML`]: `YAML olarak şema`,
  [`Schema change`]: `Şema değişikliği`,
  [`Schema changes`]: `Şema değişiklikleri`,
  [`Schema definition circular reference limit`]: `Şema tanımlaması çevrimsel başvuru sınırı`,
  [`Schema name`]: `Şema adı`,
  [`Schema warnings`]: `Şema uyarıları`,
  [`Schema definition`]: `Şema tanımı`,
  [`Scheme`]: `Şema`,
  [`Schemes`]: `Şemalar`,
  [`Scope`]: `Kapsam`,
  [`Scopes`]: `Kapsamlar`,
  [`Scopes cannot be empty`]: `Kapsamlar boş olamaz`,
  [`Search`]: `Arama`,
  [`Search DN`]: `Arama Ayırt Edici Adı`,
  [`Search in the field above above and select to add provider organizations`]: `Yukarıdaki alanda ara ve sağlayıcı kuruluşları eklemek için seç`,
  [`Search Limit`]: `Arama Sınırı`,
  [`Search Organization owner from`]: `Kuruluş Sahibini şuradan ara`,
  [`Search orgs/groups`]: `Kuruluşlarda/gruplarda ara`,
  [`Search Owner`]: `Sahip Ara`,
  [`Search assemblies`]: `Derlemelerde ara`,
  [`Search for`]: `Ara:`,
  [`Search for provider organizations`]: `Sağlayıcı kuruluşları ara`,
  [`Search for Owner`]: `Sahip Ara`,
  [`Search for a new member to become the new owner.`]: `Yeni sahip olacak yeni bir üyeyi arayın.`,
  [`Search for an existing member to become the new owner.`]: `Yeni sahip olacak var olan bir üyeyi arayın.`,
  [`Search for new owner`]: `Yeni sahip ara`,
  [`Search for owner from:`]: `Sahibi şuradan ara:`,
  [`Search for the member`]: `Üye ara`,
  [`Search for title or name or version`]: `Başlık ya da ad ya da sürüm ara`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Üye olarak eklemek istediğiniz kullanıcıyı arayın ve gerekli rolleri atayın.`,
  [`Search overflow`]: `Arama taşması`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Kayıt defterinde üye olarak eklemek istediğiniz var olan kullanıcıyı arayın ve gerekli rolleri atayın`,
  [`Search types/fields`]: `Arama tipleri/alanları`,
  [`Search user`]: `Kullanıcı ara`,
  [`Search schema`]: `Şema ara`,
  [`Seconds`]: `Saniye`,
  [`Secret Key`]: `Gizli Anahtar`,
  [`Secret Name`]: `Gizli Anahtar Adı`,
  [`Secret Required`]: `Gizli Anahtar Gereklidir`,
  [`Secret key`]: `Gizli anahtar`,
  [`Secure`]: `Güvenlik`,
  [`Secure connection`]: `Güvenli bağlantı`,
  [`Secure using Client ID`]: `Müşteri Kimliği kullanarak güvenliğini sağlayın`,
  [`Security`]: `Güvenlik`,
  [`Security & Product Definitions`]: `Güvenlik ve Ürün Tanımları`,
  [`Security definitions`]: `Güvenlik tanımları`,
  [`Security and Publish`]: `Güvenlik ve Yayınlama`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Güvenlik tanımları, API anahtarı doğrulaması, temel kimlik doğrulama gibi API uç noktalarına istemci erişimini belirtilen kullanıcı kaydı ve OAuth aracılığıyla denetler.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Güvenlik tanımları, API anahtarı doğrulaması, temel kimlik doğrulama gibi API uç noktalarına istemci erişimini belirtilen kullanıcı kaydı ve OAuth aracılığıyla denetler. [Ek bilgi]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Burada seçilen güvenlik tanımları API genelinde geçerlidir, ancak tek tek işlemler için geçersiz kılınabilir.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Burada seçilen güvenlik tanımları API genelinde geçerlidir, ancak tek tek işlemler için geçersiz kılınabilir. [Ek bilgi]({link})`,
  [`Security object to use to decode the claim.`]: `Talebin kodunu çözmek için kullanılacak güvenlik nesnesi.`,
  [`Security object used to encrypt the JWT.`]: `JWT'yi şifrelemek için kullanılan güvenlik nesnesi.`,
  [`Security object used to sign the JWT.`]: `JWT'yi imzalamak için kullanılan güvenlik nesnesi.`,
  [`Security object used to verify the claim.`]: `Talebi doğrulamak için kullanılan güvenlik nesnesi.`,
  [`Select APIs to add to this product`]: `Bu ürüne eklenecek API'leri seçin`,
  [`Select API Type`]: `API Tipini Seç`,
  [`Select API type`]: `API tipini seç`,
  [`Select a billing integration in the Product plans.`]: `Ürün planlarında bir faturalama bütünleştirmesi seçin.`,
  [`Select a flow...`]: `Bir akış seçin...`,
  [`Select a plan from your existing product`]: `Var olan ürününüzden bir plan seçin`,
  [`Select a policy file to import to this catalog`]: `Bu kataloğa aktarmak için bir ilke dosyası seçin`,
  [`Select a policy implementation file to import to this catalog`]: `Bu kataloğa aktarmak için bir ilke uygulama dosyası seçin`,
  [`Select a product`]: `Ürün seç`,
  [`Select Certificate Manager service`]: `Sertifika Yöneticisi hizmetini seç`,
  [`Select Cloud`]: `Bulut Seç`,
  [`Select Email Server`]: `E-posta Sunucusu Seç`,
  [`Select gateway services`]: `Ağ geçidi hizmetlerini seçin`,
  [`Select Import Type`]: `İçe Aktarma Tipini Seç`,
  [`Select product`]: `Ürün seçin`,
  [`Select product type`]: `Ürün tipini seç`,
  [`Select Service`]: `Hizmet Seç`,
  [`Select Service Type`]: `Hizmet Tipini Seç`,
  [`Select target endpoints`]: `Hedef uç noktaları seçin`,
  [`Select target service`]: `Hedef hizmet seçin`,
  [`Select user registry type`]: `Kullanıcı kayıt defteri tipini seçin`,
  [`Select a WSDL service from the imported file`]: `İçe aktarılan dosyadan bir WSDL hizmeti seçin`,
  [`Select a certificate`]: `Sertifika seç`,
  [`Select a Certificate Manager service`]: `Sertifika Yöneticisi hizmeti seç`,
  [`Select a cryptographic algorithm.`]: `Bir şifreleme algoritması seçin.`,
  [`Select a default catalog and organization`]: `Varsayılan katalog ve kuruluş seçin`,
  [`Select a different cloud`]: `Farklı bir bulut seçin`,
  [`Select a key encryption algorithm.`]: `Bir anahtar şifreleme algoritması seçin.`,
  [`Select a plan to migrate`]: `Geçirilecek bir plan seçin`,
  [`Select a plan to replace`]: `Başkasıyla değiştirilecek planı seçin`,
  [`Select a plan to supersede`]: `Yerine başkasının geçirileceği planı seçin`,
  [`Select a product to set as the migration target`]: `Geçiş hedefi olarak ayarlamak için bir ürün seçin`,
  [`Select a product to supersede`]: `Şu ürünün yerine geçirilecek yeni bir ürün seçin:`,
  [`Select a product to supersede {sourceProductName}:`]: `{sourceProductName} ürünün yerine geçirilecek yeni bir ürün seçin:`,
  [`Select a resource group`]: `Kaynak grubu seçin`,
  [`Select a service`]: `Bir hizmet seçin`,
  [`Select a supported file ({type}) and try again.`]: `Desteklenen bir dosya ({type}) seçin ve yeniden deneyin.`,
  [`Select a target SOAP service`]: `Hedef SOAP hizmeti seçin`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Karşıdan yüklemek için bir araç takımı paketi seçin. Araç takımı kurulduysa bu adımı atlayın.`,
  [`Select an encryption algorithm.`]: `Bir şifreleme algoritması seçin.`,
  [`Select an extension file for this gateway`]: `Bu ağ geçidi için bir uzantı dosyası seçin`,
  [`Select an org`]: `Kuruluş seç`,
  [`Select catalog`]: `Katalog seç`,
  [`Select Catalogs to Search`]: `Aranacak Katalogları Seç`,
  [`Select compatible gateway services`]: `Uyumlu ağ geçidi hizmetlerini seç`,
  [`Select one Keystore from the list below:`]: `Aşağıdaki listeden bir Anahtar deposu seçin:`,
  [`Select one keystore from the list below:`]: `Aşağıdaki listeden bir anahtar deposu seçin:`,
  [`Select one keystore from the list.`]: `Listeden bir anahtar deposu seçin.`,
  [`Select operation...`]: `İşlem seç...`,
  [`Select operations you want to include`]: `Dahil etmek istediğiniz işlemleri seçin`,
  [`Select organization`]: `Kuruluş seç`,
  [`Select the API definition or package file to import`]: `İçe aktarılacak API tanımını ya da paket dosyasını seçin`,
  [`Select the billing integration for this catalog`]: `Bu katalog için faturalama bütünleştirmesini seçin`,
  [`Select the billing integration for this product`]: `Bu ürün için faturalandırma bütünleştirmelerini seçin`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Yeni bir katalogda API'lerin güvenliğini sağlayacak ağ geçitlerini seçin. Yeni bir ağ geçidi kaydetmek için **Ağ geçitleri** sayfasını ziyaret edin.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Bu ağ geçidi için sertifikaları yöneten [Certificate Manager]({link}) hizmetini seçin.`,
  [`Select the APIs to add to this product`]: `Bu ürüne eklenecek API'leri seçin`,
  [`Select the OpenAPI definition file to import`]: `İçe aktarmak için OpenAPI tanım dosyasını seçin`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Onay gerektiren Ürün yaşam çevrimi işlemlerini seçin. Seçilen işlemlerden herhangi biri uygulandığında bir onay isteği oluşturulur; işlemin tamamlanması için istek onaylanmalıdır.`,
  [`Select the TLS protocol versions for this profile.`]: `Bu profile ilişkin TLS iletişim kuralı sürümlerini seçin.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Ağ geçidi hizmetiyle ilişkilendirmek istediğiniz analitik hizmetini seçin`,
  [`Select the consumer organization in which you want to create the application`]: `Uygulamayı yaratmak istediğiniz tüketici kuruluşunu seçin`,
  [`Select the consumer organization that will own the application`]: `Uygulamanın sahibi olacak tüketici kuruluşunu seçin`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Bu API'yi yayınlamak ve hız sınırını gerektiği gibi tanımlamak için uç noktaları seçin`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `İlke yürütme sırasında oluşursa, derleme akışının durdurulmasına neden olan hataları seçin. Hata için yapılandırılmış bir yakalama akışı varsa, oluşan hatayı işlemek için bu tetiklenir. Bir hata oluşursa ve hata ayarında Durdurma için bir hata seçilmediyse ya da oluşan hata, seçilen hatalardan biri değilse, ilke yürütmenin tamamlanmasına izin verilir ve derleme akışı devam eder.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Üründeki API'lerin kullanılabilir hale getirileceği ağ geçidi hizmetlerini seçin.`,
  [`Select the gateway type for this API`]: `Bu API için ağ geçidi tipini seçin`,
  [`Select the gateway type for this OAuth provider`]: `Bu OAuth sağlayıcısı için ağ geçidi tipi seçin`,
  [`Select the keystores and truststores for this profile.`]: `Bu profil için anahtar depolarını ve güvenilir depoları seçin.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Bu ürünün görünür olmasını istediğiniz kuruluşları ya da grupları seçin`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Bu ürüne abone olmasını istediğiniz kuruluşları ya da grupları seçin`,
  [`Select the portal service to use for this catalog`]: `Bu katalog için kullanılacak portal hizmetini seçin`,
  [`Select the product and plan for the application subscription`]: `Uygulama aboneliği için ürünü ve planı seçin`,
  [`Select the product definition file to import`]: `İçe aktarılacak ürün tanımı dosyasını seçin`,
  [`Select the product definition file to import from`]: `İçinden içe aktarılacak ürün tanımı dosyasını seçin`,
  [`Select the product that will supersede the originally selected product`]: `İlk seçilen ürünün yerine geçirilecek ürünü seçin`,
  [`Select the product that you want to add the API to`]: `API'yi eklemek istediğiniz ürünü seçin`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Bu katalogda API'lere erişimi denetlemek için kullanmak istediğiniz kayıt defterlerini seçin`,
  [`Select the required operations`]: `Gerekli işlemleri seçin`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Giriş verileriyle ilgili günlük iletileri için önem düzeyini seçin; olası düzeyler hata, uyarı ve bilgi düzeyleridir.`,
  [`Select the target wsdl file to create from`]: `İçinden yaratma işlemi yapılacak hedef wsdl dosyasını seçin`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `İçinden API tanımının yaratılacağı hedef wsdl, xsd ya da zip dosyasını seçin`,
  [`Select the target WSDL file to import`]: `İçe aktarılacak hedef WSDL dosyasını seçin`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `API Manager kullanıcılarının kimliğini doğrulamak için kullanıcı kayıt defterini seçin ya da yeni bir kullanıcı kayıt defteri yaratın.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `API Manager kullanıcılarının kimliğini doğrulamak için kullanıcı kayıt defterini seçin ya da yeni bir kullanıcı kayıt defteri yaratın.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Cloud Manager kullanıcılarının kimliğini doğrulamak için kullanıcı kayıt defterlerini seçin ya da yeni bir kullanıcı kayıt defteri oluşturun.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Geliştirici Portalında oturum açma kimlik doğrulamasında kullanılan kullanıcı kayıt defterlerini seçin`,
  [`Select the user registry type`]: `Kullanıcı kayıt defteri tipini seç`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `İlkenin, oluşturulan LTPA belirtecini çıktı kaynağının neresine yerleştireceğini seçin.  WSSec içinde yalnızca istekteki içerik tipi application/xml olduğunda Üstbilgi seçilmelidir.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Verileri engellemek için "*"s kullanarak özelliğin tamamen mi kaldırılacağını yoksa alanın mı düzeltileceğini seçin.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Seçilen ürün değiştirildi. Lütfen bu sayfayı yenileyin ve yeniden deneyin.`,
  [`Selecting operations from WSDL service definition`]: `WSDL hizmeti tanımlamasından işlem seçilmesi`,
  [`Self Service Onboarding for Consumer organization`]: `Tüketici Kuruluşu için Self Servis Katılım`,
  [`Self service onboarding`]: `Self servis katılım`,
  [`Self service onboarding approval`]: `Self servis katılım onayı`,
  [`Send`]: `Gönder`,
  [`Send Message`]: `İleti Gönder`,
  [`Send Message to Owners`]: `Sahiplere İleti Gönder`,
  [`Send To`]: `Gönderme Hedefi`,
  [`Send a registration link to a new user to register as owner.`]: `Sahip olarak kaydetmek için yeni kullanıcıya bir kayıt bağlantısı gönderin.`,
  [`Send a test email`]: `Test e-postası gönder`,
  [`Send a test email to confirm that the email server is properly configured.`]: `E-posta sunucusunun doğru yapılandırıldığını onaylamak için bir test e-postası gönderin.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Uç noktanın doğru yapılandırıldığını onaylamak için bir test sinyali gönderin.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Uç noktanın doğru yapılandırıldığını onaylamak için bir test sinyali gönder`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Yönetici kuruluş sahibine bir e-posta iletisi gönderin. Sahibin adının yanına bir onay işareti koyun`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Bir sahibe e-posta iletisi gönderin. Sahibin adının yanına bir onay işareti koyun`,
  [`Send chunked-encoded documents to the target server.`]: `Parçalı-kodlanmış belgeleri hedef sunucuya gönderin.`,
  [`Send message`]: `İleti gönder`,
  [`Send message to owner`]: `Sahibe ileti gönder`,
  [`Send test email`]: `Test e-postası gönder`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Oturum açma ya da kaydolmaya davet etmek için bir kullanıcıya etkinleştirme bağlantısını gönderin.`,
  [`Send-only`]: `Yalnızca gönder`,
  [`Sender`]: `Gönderen`,
  [`Sender & email server`]: `Gönderen ve e-posta sunucusu`,
  [`Sender & email server have been changed`]: `Gönderen ve e-posta sunucusu değiştirildi`,
  [`Sender Info`]: `Gönderen Bilgileri`,
  [`Sent`]: `Gönderilen`,
  [`Sent events: **{sent}**`]: `Gönderilen olaylar: **{sent}**`,
  [`Serial`]: `Seri`,
  [`Serialize output`]: `Çıktıyı serileştir`,
  [`Server connection`]: `Sunucu bağlantısı`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `GUI GraphQL istemcisini etkinleştirmek için web tarayıcılarına HTML hizmeti sağlayın`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `GUI GraphQL istemcisini etkinleştirmek için web tarayıcılarına HTML hizmeti sağlayın.`,
  [`Server Name Indication (SNI)`]: `Sunucu Adı Göstergesi (SNI)`,
  [`Server URL`]: `Sunucu URL'si`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Sunucu OIDC yeniden yönlendirme URL'sinde belirteç yerine hash içinde bir hata döndürdü. Hash:`,
  [`Service name`]: `Hizmet adı`,
  [`Services`]: `Hizmetler`,
  [`Set`]: `Ayarla`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `API Designer için APIC_DESIGNER_CREDENTIALS OS ortam değişkenini ayarlayın. Örneğin MAC'te kullanıcı API Designer'ı başlatırken ortam değişkenini iletebilir.`,
  [`Set Migration Target`]: `Geçiş Hedefini Ayarla`,
  [`Set migration target`]: `Geçiş hedefini ayarla`,
  [`Set Variable`]: `Değişken Ayarla`,
  [`Set as default`]: `Varsayılan olarak ayarla`,
  [`Set default`]: `Varsayılanı ayarla`,
  [`Set rate and burst limits in an assembly`]: `Bir derlemede hız ve ayırma sınırlarını belirleyin`,
  [`Set the timeout period for invitations`]: `Davetler için zamanaşımı süresini ayarlayın`,
  [`Set the trust level for the profile`]: `Profil için güven düzeyini ayarlayın`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Değeri izin verilen dairesel bir şema tanımı yineleme sayısına (varsayılan 1, maksimum 5) ayarla`,
  [`Set the visibility for a gateway service`]: `Bir ağ geçidi hizmetinin görünürlüğünü ayarlayın`,
  [`Set the visibility for a portal service`]: `Bir portal hizmetinin görünürlüğünü ayarlayın`,
  [`Set the visibility for each service`]: `Her bir hizmet için görünürlüğü ayarlayın.`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Kimlik doğrulama sunucusu tarafından verilen yeni bir erişim belirteci almak için zaman aralığını belirle`,
  [`Set visibility`]: `Görünürlüğü ayarla`,
  [`Set visibility of gateway`]: `Ağ geçidinin görünürlüğünü ayarla`,
  [`Set time interval`]: `Zaman aralığını ayarla`,
  [`Set, Add, or Clear a runtime variable.`]: `Çalıştırma zamanı değişkenini Ayarlayın, Ekleyin ya da Temizleyin.`,
  [`Set the timeout period for API key`]: `API anahtarı için zamanaşımı süresini ayarlayın`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Önbellekte belgeler için saniye cinsinden geçerlilik süresini belirler. Yalnızca Önbellek Tipi "Yaşam Süresi" olarak ayarlandıysa geçerli olur. 5 - 31708800 aralığında bir değer girin. Varsayılan değer 900'dür.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `"assumedSize" için bir değer belirlenmesi, varsayılan değeri olan bir dilimleme bağımsız değişkeniyle birleştirilemez ya da "requireOneSenicingArgument" ayarının  etkinleştirilmesiyle birleştirilemez.`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Bu kataloğun varsayılan olarak ayarlanması, katalog adı atılıp daha kısa bir URL kullanılarak kataloğa erişilmesini sağlar. Yalnızca bir kataloğu varsayılan olarak belirleyebilirsiniz.`,
  [`Settings`]: `Ayarlar`,
  [`Setup`]: `Ayarla`,
  [`Severity level for input data log messages`]: `Giriş verileri günlük iletiler için önem düzeyi`,
  [`Share the resource with a custom list of provider organizations`]: `Kaynağı sağlayıcı kuruluşları özel listesiyle paylaşın`,
  [`Share the resource with provider organizations`]: `Kaynağı sağlayıcı kuruluşlarla paylaşın`,
  [`Share the API gateway with a custom list of provider organizations`]: `API ağ geçidini sağlayıcı kuruluşları özel listesiyle paylaşın`,
  [`Share the service with a custom list of provider organizations`]: `Hizmeti sağlayıcı kuruluşları özel listesiyle paylaşın`,
  [`Share the API gateway with provider organizations`]: `API ağ geçidini sağlayıcı kuruluşlarıyla paylaşın`,
  [`Share the service with provider organizations`]: `Hizmeti sağlayıcı kuruluşlarıyla paylaşın`,
  [`Share`]: `Paylaş`,
  [`Shared`]: `Paylaşılan`,
  [`Shared by`]: `Paylaşan`,
  [`Short but descriptive name for this policy.`]: `Bu ilke için kısa ancak açıklayıcı bir ad.`,
  [`Show`]: `Göster`,
  [`Show / hide policy palette`]: `İlke paletini göster / gizle`,
  [`Show/hide`]: `Göster/gizle`,
  [`Show/hide settings`]: `Ayarları göster/gizle`,
  [`Show and hide types and fields to include in the schema.`]: `Şemaya dahil edilecek alanları ve tipleri gösterin ve gizleyin.`,
  [`Show added only`]: `Yalnızca ekleneni göster`,
  [`Show all`]: `Tümünü göster`,
  [`Show all UDP versions`]: `Tüm UDP sürümlerini göster`,
  [`Show app identifier`]: `Uygulama tanıtıcısını göster`,
  [`Show breaking changes only`]: `Yalnızca kesintiye neden olan değişiklikleri göster`,
  [`Show catalog identifier`]: `Katalog tanıtıcısını göster`,
  [`Show catches`]: `Yakalamaları göster`,
  [`Show in schema`]: `Şemada göster`,
  [`Show less`]: `Daha az göster`,
  [`Show modified only`]: `Yalnızca değiştirileni göster`,
  [`Show more`]: `Daha fazla göster`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Yeni tipleri, alanları, yönergeleri veya isteğe bağlı bağımsız değişkenleri gösterin. Arka uç tarafından artık desteklenmeyen öğeler yine de kaldırılacak.`,
  [`Show removed only`]: `Yalnızca kaldırılanı göster`,
  [`Show schema`]: `Şemayı göster`,
  [`Show warnings only`]: `Yalnızca uyarıları göster`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Şu anda atanmış olan anahtar deposu dışında anahtar deposu atamaları geçmişini gösterin.`,
  [`Sign Crypto Object`]: `İmzalama Şifre Nesnesi`,
  [`Sign in`]: `Oturum aç`,
  [`Sign JWK variable name`]: `JWK imzalama değişken adı`,
  [`Sign Out`]: `Oturumu Kapat`,
  [`Sign up`]: `Kaydol`,
  [`sign-up`]: `oturum açma`,
  [`Sign in using the {userRegistryType} User Registry`]: `{userRegistryType} Kullanıcı Kayıt Defterini kullanarak oturum aç`,
  [`Sign in with`]: `Oturum açın:`,
  [`Sign out`]: `Oturumu kapat`,
  [`Sign up with`]: `Kaydolun:`,
  [`Signed token`]: `İmzalı belirteç`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Arabelleğe alma kapalı olarak ayarlandığından, içeriğin ve/veya hata içeriğinin bilgi yükünden üstbilgilere azaltılması gerekir. Gerekli değişiklikle devam etmek ya da son değişikliğinizi iptal etmek istiyor musunuz?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `İçerik ve/veya hata içeriği, bilgi yükü olarak ayarlandığından arabelleğe almanın açık olarak ayarlanması gerekir. Gerekli değişiklikle devam etmek ya da son değişikliğinizi iptal etmek istiyor musunuz?`,
  [`Sized fields`]: `Boyutlandırılan alanlar`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Dilimleme bağımsız değişkenleri`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Bazı girişler dizilerdir ya da diziler içinde yer alır. İçeriklerde yineleme yapmak için yineleme seçeneğini belirleyin ve üzerinde yineleme yapılacak girişi seçin.`,
  [`Something went wrong.`]: `Bir sorun oluştu.`,
  [`Something went wrong...`]: `Bir şeyler yanlış gitti...`,
  [`Source`]: `Kaynak`,
  [`Source schema`]: `Kaynak şema`,
  [`Space`]: `Alan`,
  [`Space is not allowed in scope name`]: `Kapsam adında alana izin verilmiyor`,
  [`Space details`]: `Alan ayrıntıları`,
  [`Space Enabled`]: `Alan Etkin`,
  [`Space Name`]: `Alan Adı`,
  [`Space Summary`]: `Alan Özeti`,
  [`Space Title`]: `Alan Başlığı`,
  [`Space owner`]: `Alan sahibi`,
  [`Space {name} created`]: `{name} adlı alan oluşturuldu`,
  [`Spaces`]: `Alanlar`,
  [`Spaces are enabled`]: `Alanlar etkinleştirildi`,
  [`Spaces are not enabled`]: `Alanlar etkinleştirilmedi`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Kum havuzu kataloğu için alanlar etkinleştirilemiyor.`,
  [`Spaces cannot be enabled.`]: `Alanlar etkinleştirilemez.`,
  [`Spaces dropdown`]: `Alanlar açılır listesi`,
  [`Spaces has been enabled`]: `Alanlar etkinleştirildi`,
  [`Spaces have been enabled.`]: `Boşluklar etkinleştirildi.`,
  [`Spaces per page`]: `Sayfa başına boşluk`,
  [`Spanish`]: `İspanyolca`,
  [`Special characters are not accepted`]: `Özel karakterler kabul edilemez`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Dinamik nesneyle birleştirilen seri numarası atanmış XML ya da JSON özelliklerinin içinden alınacağı adlandırılmış bağlamı temsil eden bir URL'yi ya da dizgiyi belirtir. Bu özellikler, var olan hazır bilgi ya da varsayılan özelliklerden önceliklidir.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Dinamik nesneyle birleştirilen seri numarası atanmış XML ya da JSON özellikleri olarak bir hazır bilgi dizgisini belirtir. Bu özellikler, var olan varsayılan özelliklerden önceliklidir.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Dinamik nesne için içinden varsayılan özellik değerlerinin alınacağı var olan geçerli bir nesneyi belirtir. Uyarı: Varsayılan dizgiden değiştirirseniz, belirtilen ayrıştırma ayarı nesnesi, DataPower aracında kullanılabilir olmalıdır.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Düzeltilecek veya kaldırılacak içeriği içeren veri kaynağını belirtir. Kök belirtilmezse işlem tüm API bağlamına uygulanır. Desteklenen JSONata yolu ifadesini kullanabilirsiniz.\n\nİstek ve yanıt verilerini düzeltme işlemi "message.body" köküyle gerçekleştirilebilir. Derleme düzeltme işlemi, gather-only modundu belirten bir derleme günlüğü işleminden sonra bir derlemede kullanılıyorsa kök, günlüğe kayıtlı istek bilgi yükü için "log.request_body" veya günlüğe kayıtlı yanıt bilgi yükü için "log.response_body" belirtebilir.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `API bağlamında bir değişkenin adını belirtir. Değişkenin "gövde" alanının içeriği, işlemin girişidir. Varsayılan değişken adı "message" şeklindedir.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `API bağlamında bir değişkenin adını belirtir. Değişkenin "gövde" alanının içeriği, ayrıştırmanın çıkışıdır. Ayrıştırılan belgenin ayrıştırma metrikleri, iletinin farklı bir kısmında depolanabilir. Varsayılan değişken adı, Giriş adı ile aynıdır; bu nedenle varsayılan olarak Giriş iletisinin üzerine Çıkış iletisi yazılır.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Erişim belirteci için özel meta verilerin edinilip edinilmeyeceğini ve nerede edinileceğini belirtir.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Belirtilen content-type değeriyle bir ayrıştırma denemesi gerçekleştirip gerçekleştirilmeyeceğini belirtir. Bu yalnızca beklenen content-type json veya xml olduğunda geçerlidir. Bu seçenek geçerli kılındığında, content-type değeri parse-settings ile eşleşmezse (Content-type:application/json ise parse-settings xml için yapılandırıldıysa) ayrıştırma işlemi hatayla sonuçlanır. parse-setting, giriş içeriğini saptayacak şekilde yapılandırılırsa belirtilen content-type değerine zorlanacaktır.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Dönüştürmeden sonra XSLT çıktı ağacının ikili veriye serileştirilip serileştirilmeyeceğini belirtir.`,
  [`Specify default gateways`]: `Varsayılan ağ geçitlerini belirtin`,
  [`Specify email`]: `E-posta belirtin`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Bilgi yükünü ("request", "response" ya da "#/definitions/XXXX") doğrulamak için kullanılacak bir OpenAPI (Swagger) şema tanımını belirtin.`,
  [`Specify owner of the consumer organization`]: `Tüketici kuruluşun sahibini belirtin`,
  [`Specify owner of the provider organization`]: `Sağlayıcı kuruluşun sahibini belirtin`,
  [`Specify settings for the third party OAuth provider.`]: `Üçüncü kişi OAuth sağlayıcısı ayarlarını belirtin.`,
  [`Specify the MIME types that your API consumes and produces`]: `API'nizin kullandığı ve ürettiği MIME tiplerini belirtin`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Katalog sahibini belirtin; sahip tüm katalog izinlerine sahiptir`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Geliştirici portalında ürünü görebilecek olan tüketici kuruluşlarını ya da geliştirici topluluklarını belirtin`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Üründeki API'leri kullanabilecek ya da bunlara abone olabilecek olan geliştirici topluluklarını ya da tüketici kuruluşlarını belirtin`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `API çağırma uç noktasının temel URL'sini ve ağ geçidinin işlediği etki alanlarını belirtin.`,
  [`Specify the details of the catalog that you want to publish to`]: `Yayınlamak istediğiniz kataloğun ayrıntılarını belirtin`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Yeni bir katalogda API'lerin güvenliğini sağlayacak varsayılan ağ geçitleri grubunu belirtin. [Ek bilgi]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Bu API'nin yayınlanacağı uç noktaları ve API çağrılarının hızını sınırlamak isteyip istemediğinizi belirtin`,
  [`Specify the new owner`]: `Yeni sahibi belirtin`,
  [`Specify the owner`]: `Sahibi belirtin`,
  [`Specify the owner and title`]: `Sahibi ve başlığı belirtin`,
  [`Specify the properties to redact or remove.`]: `Düzeltilecek ya da kaldırılacak özellikleri belirtin.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Bu API için yayınlama ve hız sınırlama gereksinimlerini belirtin`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Tanımlanan kapsamların yanı sıra ek kapsam doğrulaması gerçekleştirilen kapsam denetimi uç noktasını belirtin.`,
  [`Specify the security settings for the imported API definition`]: `İçe aktarılan API tanımı için güvenlik ayarlarını belirtin`,
  [`Specify the security settings for your API definition`]: `API tanımınız için güvenlik ayarlarını belirtin`,
  [`Specify the space owner; the owner has all space permissions`]: `Alan sahibini belirtin; sahip tüm alan izinlerine sahiptir`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Hedef SOAP hizmetini ve API özet ayrıntılarını belirtin; gerekiyorsa, API tanımını yarattıktan sonra daha ayrıntılı olarak yapılandırabilirsiniz.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Kullanıcı hesabını etkinleştirmezse davet e-postalarının süresinin dolacağı zaman aşımı süresini belirtin`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Kullanıcı hesabını etkinleştirmezse davet e-postalarının süresinin dolacağı zamanaşımı süresini belirtin.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Ağ geçidini açıklayan özeti, ağ geçidi tipini ve başlığını belirtin.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `API ağ geçidinin API Connect sağlayıcı kuruluşu için görünürlüğünü belirtin.`,
  [`Specify the way you want to validate the message.`]: `İletiyi nasıl doğrulamak istediğinizi belirtin.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Ağ geçidi yönetimi uç noktasının URL'sini ve uç nokta etki alanı sertifikasını belirtin.`,
  [`Specify what content to log in the event of an error.`]: `Hata durumunda hangi içeriğin günlüğe kaydedileceğini belirtin.`,
  [`Specify what content to log.`]: `Hangi içeriğin günlüğe kaydedileceğini belirtin.`,
  [`Specifying the publish target details`]: `Yayınlama hedefi ayrıntılarını belirtme`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Özellikle xsi:type=SOAP-ENC:Array kuralına izin ver`,
  [`Stage`]: `Hazırla`,
  [`Stage API`]: `API'yi Hazırla`,
  [`Stage product`]: `Ürünü hazırla`,
  [`Stage to`]: `Hazırlama hedefi`,
  [`Staged`]: `Hazırlanmış`,
  [`Stage|permission`]: `Hazırla`,
  [`Standard OIDC`]: `Standart OIDC`,
  [`Static DNS`]: `Statik DNS`,
  [`Static group`]: `Statik grup`,
  [`Status`]: `Durum`,
  [`Status code`]: `Durum kodu`,
  [`Step 1`]: `Adım 1`,
  [`Step 1: Download`]: `Adım 1: Karşıdan yükleyin`,
  [`Step 1: Download toolkit`]: `Adım 1: Araç takımını karşıdan yükleyin`,
  [`Step 1: Upload private key`]: `Adım 1: Özel anahtarı karşıya yükleyin`,
  [`Step 2`]: `Adım 2`,
  [`Step 2: Download credentials`]: `Adım 2: Kimlik bilgilerini karşıdan yükleyin`,
  [`Step 2: Download toolkit credentials`]: `Adım 2: Araç takımı kimlik bilgilerini karşıdan yükleyin`,
  [`Step 2: Install`]: `Adım 2: Kurun`,
  [`Step 2: Upload public key`]: `Adım 2: Genel anahtarı karşıya yükleyin`,
  [`Step 3`]: `Adım 3`,
  [`Step 3: Configure`]: `Adım 3: Yapılandırın`,
  [`Step 3: Run command`]: `Adım 3: Komutu çalıştırın`,
  [`Step 3: Run commands`]: `Adım 3: Komutları çalıştırın`,
  [`Step 4: Register`]: `Adım 4: Kaydettirin`,
  [`Stop`]: `Durdur`,
  [`Stop after:`]: `Şundan sonra durdur:`,
  [`Stop all the retries`]: `Tüm yeniden denemeleri durdur`,
  [`Stop Connection`]: `Bağlantıyı Durdur`,
  [`Stop on error`]: `Hata durumunda durdur`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Bu API'yi çalıştırmayı durdurun ve Kum Havuzu kataloğundan kaldırın.`,
  [`Stopped`]: `Durduruldu`,
  [`Stopping Published API`]: `Yayınlanan API durduruluyor`,
  [`Strict`]: `Katı`,
  [`Strict SOAP envelope version`]: `Katı SOAP zarf sürümü`,
  [`String Badgerfish`]: `Dize Badgerfish`,
  [`String`]: `Dize`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `API çağrıları için URL kuruluş bölümündeki dize. Küçük harfleri, sayıları ve kısa çizgileri kullanın.`,
  [`Stripe`]: `Şerit`,
  [`Stripe integration`]: `Stripe bütünleştirmesi`,
  [`Streaming rule`]: `Akış kuralı`,
  [`Subject`]: `Konu`,
  [`Subject Claim`]: `Konu Talebi`,
  [`Subject Origin`]: `Konu Kökeni`,
  [`Subject common name`]: `Konu ortak adı`,
  [`Subject|certificate`]: `Konu`,
  [`Submit`]: `Gönder`,
  [`Submitted`]: `Gönderildi`,
  [`Subscribability`]: `Abone Olunabilirlik`,
  [`Subscribability must be disabled if visibility is disabled`]: `Görünürlük devre dışı bırakılırsa abone olunabilirlik devre dışı bırakılmalıdır`,
  [`Subscribability options`]: `Abone olunabilirlik seçenekleri`,
  [`Subscribable by`]: `Abone olabilecekler`,
  [`Subscribe`]: `Abone Ol`,
  [`Subscribe Financial Management App`]: `Finansal Yönetim Uygulamasına Abone Ol`,
  [`Subscribe TODO App`]: `TODO Uygulamasını Abone Et`,
  [`Subscription`]: `Abonelik`,
  [`Subscription approval task for subscription of`]: `Ürün için onay görevinin aboneliği için`,
  [`Subscription created`]: `Abonelik oluşturuldu`,
  [`Subscription has been added`]: `Abonelik eklendi`,
  [`Subscription has not been added`]: `Abonelik eklenmedi`,
  [`Subscription pending approval`]: `Abonelik onay bekliyor`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `GraphQL Abonelikleri desteklenmediğinden abonelik tipi kaldırıldı.`,
  [`Subscription-Approval`]: `Abonelik-Onayı`,
  [`Subscriptions`]: `Abonelikler`,
  [`Success`]: `Başarılı`,
  [`Suffix`]: `Sonek`,
  [`Suggestion`]: `Öneri`,
  [`Supersede`]: `Yerine başkasını geçir`,
  [`Supersede Product`]: `Ürünün Yerine Başkasını Geçir`,
  [`Supersede product`]: `Ürünün yerine başkasını geçir`,
  [`Supersede plans`]: `Planların yerine başkalarını geçir`,
  [`Supersede {product1} with {product2}`]: `{product1} yerine {product2} geçir`,
  [`Supersede|permission`]: `Yerine başkasını geçir`,
  [`Supersede|supersede with`]: `Yerine başkasını geçir`,
  [`Support`]: `Destek`,
  [`support default introspection`]: `varsayılan iç gözlem desteği`,
  [`Support Server Name Indication (SNI)`]: `Sunucu Adı Göstergesini (SNI) Destekle`,
  [`Support hybrid response types`]: `Hibrit yanıt tiplerini destekle`,
  [`Support standard introspection`]: `Standart iç gözlem desteği`,
  [`Supported OAuth components`]: `Desteklenen OAuth bileşenleri`,
  [`Supported client types`]: `Desteklenen istemci tipleri`,
  [`Supported client types can not be empty.`]: `Desteklenen istemci tipi boş olamaz.`,
  [`Supported grant type can not be empty.`]: `Desteklenen izin (grant) tipi boş olamaz.`,
  [`Supported grant types`]: `Desteklenen izin tipleri`,
  [`Suspend`]: `Askıya Al`,
  [`Suspend API`]: `API'yi Askıya Al`,
  [`Suspend Application`]: `Uygulamayı Askıya Al`,
  [`Switch`]: `Geçiş Yap`,
  [`Switch account`]: `Hesabı değiştir`,
  [`Switch cloud connection`]: `Bulut bağlantısını değiştir`,
  [`Switch directory`]: `Anahtar dizini`,
  [`TARGET`]: `HEDEF`,
  [`Title`]: `Başlık`,
  [`TlS`]: `TlS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS İstemcisi`,
  [`TLS Client Profile`]: `TLS İstemci Profili`,
  [`TLS Client Profile has been configured.`]: `TLS İstemci Profili yapılandırıldı.`,
  [`TLS client profile is required when endpoint starts with https://`]: `Uç nokta https:// ile başladığında TLS istemci profili gereklidir`,
  [`TLS Client Profiles`]: `TLS İstemci Profilleri`,
  [`TLS client profile has been configured.`]: `TLS istemci profili yapılandırıldı.`,
  [`TLS client profiles`]: `TLS istemci profilleri`,
  [`TLS Profile`]: `TLS Profili`,
  [`TLS SERVER PROFILE`]: `TLS SUNUCU PROFİLİ`,
  [`TLS server profile`]: `TLS sunucu profili`,
  [`TLS version 1.0`]: `TLS sürüm 1.0`,
  [`TLS version 1.1`]: `TLS sürüm 1.1`,
  [`TLS version 1.2`]: `TLS sürüm 1.2`,
  [`TLS version 1.3`]: `TLS sürüm 1.3`,
  [`TLS client profile`]: `TLS istemci profili`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `Alanlar etkinleştirildiğinde TLS istemci profilleri, her bir alanda yönetilir. Lütfen TLS istemci profillerini yönetmek için alan ayarlarına gidin.`,
  [`TLS profile`]: `TLS profili`,
  [`TLS profile is not visible or not available.`]: `TLS profili görünmez ya da kullanılamıyor.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `Kimlik doğrulama URL'sine güvenli veri aktarımı için kullanılacak TLS profili.`,
  [`Tabs for code language options`]: `Kod dili seçenekleri sekmeleri`,
  [`Truststore`]: `Güvenli Depo`,
  [`truststore`]: `güven deposu`,
  [`Type`]: `Tip`,
  [`Type name`]: `Tip adı`,
  [`Type/Field`]: `Tip/Alan`,
  [`Type weight`]: `Tip ağırlığı`,
  [`Types per page:`]: `Sayfa başına tipler:`,
  [`Tags`]: `Etiketler`,
  [`Tags and external documentation`]: `Etiketler ve dış belgeler`,
  [`Take offline`]: `Çevrimdışına al`,
  [`Target`]: `Hedef`,
  [`Target API (URL)`]: `Hedef API (URL)`,
  [`Target SOAP service`]: `Hedef SOAP hizmeti`,
  [`Target Services`]: `Hedef Hizmetler`,
  [`Target service URL`]: `Hedef hizmet URL'si`,
  [`Target services`]: `Hedef hizmetler`,
  [`Target URL`]: `Hedef URL`,
  [`Target application`]: `Hedef uygulama`,
  [`Target catalog`]: `Hedef katalog`,
  [`Target gateway service`]: `Hedef ağ geçidi hizmeti`,
  [`Target gateway services`]: `Hedef ağ geçidi hizmetleri`,
  [`Target plan`]: `Hedef plan`,
  [`Target product`]: `Hedef ürün`,
  [`Target product rate limit`]: `Hedef ürün hız sınırı`,
  [`Target product subscribability`]: `Hedef ürünü abone olunabilirlik`,
  [`Target product visibility`]: `Hedef ürün görünürlüğü`,
  [`Target schema`]: `Hedef şema`,
  [`Target services have been updated.`]: `Hedef hizmetler güncellendi.`,
  [`Task`]: `Görev`,
  [`Task has been updated`]: `Görev güncellendi`,
  [`Task self approval`]: `Otomatik görev onayı`,
  [`Task self-approval`]: `Görev otomatik onayı`,
  [`task-consumer-onboard-request`]: `görev-tüketici-yerleşik-isteği`,
  [`task-product-lifecycle-approved`]: `görev-ürün-yaşam çevrimi-onaylandı`,
  [`task-product-lifecycle-cancelled`]: `görev-ürün-yaşam çevrimi-iptal edildi`,
  [`task-product-lifecycle-denied`]: `görev-ürün-yaşam çevrimi-reddedildi`,
  [`task-product-lifecycle-pending`]: `görev-ürün-yaşam çevrimi-beklemede`,
  [`task-product-lifecycle-request`]: `görev-ürünü-yaşam çevrimi-isteği`,
  [`task-subscription-approved`]: `görev-abonelik-onaylandı`,
  [`task-subscription-cancelled`]: `görev-abonelik-iptal edildi`,
  [`task-subscription-denied`]: `görev-abonelik-reddedildi`,
  [`task-subscription-pending`]: `görev-abonelik-beklemede`,
  [`task-subscription-request`]: `görev-abonelik-isteği`,
  [`Tasks`]: `Görevler`,
  [`Tasks for creating new provider organizations`]: `Yeni sağlayıcı kuruluşlar oluşturma görevleri`,
  [`TCP`]: `TCP`,
  [`Template`]: `Şablon`,
  [`Template: {title}`]: `Şablon: {title}`,
  [`Template: account approved`]: `Şablon: hesap onaylandı`,
  [`Template: account denied`]: `Şablon: hesap reddedildi`,
  [`Template: account pending approval`]: `Şablon: onay bekleyen hesap`,
  [`Template: app lifecycle approved`]: `Şablon: uygulama yaşam çevrimi onaylandı`,
  [`Template: app lifecycle denied`]: `Şablon: uygulama yaşam çevrimi reddedildi`,
  [`Template: app lifecycle pending`]: `Şablon: uygulama yaşam çevrimi bekliyor`,
  [`Template: app reinstated`]: `Şablon: uygulama yeniden başlatıldı`,
  [`Template: app suspended`]: `Şablon: uygulama askıya alındı`,
  [`Template: app lifecycle cancelled`]: `Şablon: uygulama yaşam çevrimi iptal edildi`,
  [`Template: app lifecycle request`]: `Şablon: uygulama yaşam çevrimi isteği`,
  [`Template: invitation`]: `Şablon: davet`,
  [`Template: mail server test connection`]: `Şablon: posta sunucusu test bağlantısı`,
  [`Template: member invitation`]: `Şablon: üye daveti`,
  [`Template: password changed`]: `Şablon: parola değiştirildi`,
  [`Template: password reset`]: `Şablon: parola sıfırlama`,
  [`Template: sign up`]: `Şablon: oturum açma`,
  [`Template: task consumer onboard request`]: `Şablon: görev tüketici yerleşik isteği`,
  [`Template: task product lifecycle approved`]: `Şablon: görev ürün yaşam çevrimi onaylandı`,
  [`Template: task product lifecycle cancelled`]: `Şablon: görev ürün yaşam çevrimi iptal edildi`,
  [`Template: task product lifecycle denied`]: `Şablon: görev ürün yaşam çevrimi reddedildi`,
  [`Template: task product lifecycle pending`]: `Şablon: görev ürün yaşam çevrimi bekliyor`,
  [`Template: task product lifecycle request`]: `Şablon: görev ürün yaşam çevrimi isteği`,
  [`Template: task subscription cancelled`]: `Şablon: görev aboneliği iptal edildi`,
  [`Template: task subscription request`]: `Şablon: görev aboneliği isteği`,
  [`Template: task subscription approved`]: `Şablon: görev aboneliği onaylandı`,
  [`Template: task subscription denied`]: `Şablon: görev aboneliği reddedildi`,
  [`Template: task subscription pending`]: `Şablon: görev aboneliği bekliyor`,
  [`Temporarily disable subscribability`]: `Abone olunabilirliği geçici olarak devre dışı bırak`,
  [`Temporarily disable visibility`]: `Görünürlüğü geçici olarak devre dışı bırak`,
  [`Terms of service`]: `Hizmet koşulları`,
  [`Test`]: `Test`,
  [`Test API`]: `API'yi Test Et`,
  [`Test APIs`]: `API'leri Test Et`,
  [`Test Bind & Get Base DN`]: `Bağlamayı Test Et ve Temel DN'yi Al`,
  [`Test configuration`]: `Yapılandırmayı test et`,
  [`Test connection`]: `Bağlantıyı test et`,
  [`Test connection failed.`]: `Test bağlantısı başarısız oldu.`,
  [`Test and production endpoints`]: `Test ve üretim uç noktaları`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Test ve izleme yanıt hatası: redirect_uri özelliği gerekli, {redirectUri} alındı`,
  [`Test application`]: `Test uygulaması`,
  [`Test consumer org`]: `Test tüketici kuruluşu`,
  [`Test email`]: `Test e-postası`,
  [`Test endpoints`]: `Test uç noktaları`,
  [`Test message from IBM API Connect`]: `IBM API Connect uygulamasından test iletisi`,
  [`Testable`]: `Test Edilebilir`,
  [`TEXT`]: `METİN`,
  [`The "Definition" property must have a valid value.`]: `"Tanım" özelliğinin geçerli bir değeri olmalıdır.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `"Tanım" özelliği, bir "request", "response" değerine sahip olmalı ya da "#/definitions/" ile başlamalıdır.`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `API Connect CLI, bir IBM Cloud CLI eklentisi olarak sunulur. Başlamak için:`,
  [`The API has unsaved changes.`]: `API, kaydedilmemiş değişiklikler içeriyor.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `API tam olarak tasarlandı ve bir iç aşama iletti, ancak henüz uygulamadı.`,
  [`The API is in the implementation phase.`]: `API uygulama aşamasında.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `API erken konsept aşamasında ve tam olarak tasarlanmış veya uygulanmış durumda değil.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `API, yayınlanmamış değişiklikler içeriyor olabilir. Ürünü yeniden yayınlamak istiyor musunuz?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `DNS şeması, ağ geçidi hizmetine gelen API çağrıları ve geliştirici portala erişim için uç nokta URL'lerinin oluşumunu belirler.`,
  [`The GatewayScript source code to execute.`]: `Yürütülecek GatewayScript kaynak kodu.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `GraphQL bilgi yükünün doğrulanması gereken GraphQL şeması URL'si.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Yürütme için kullanılacak HTTP yöntemi. Atlanırsa ya da "Tut" olarak ayarlanırsa, gelen istekteki yöntem kullanılacaktır.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Geçerli HTTP yöntemini değiştirmek için kullanılacak HTTP yöntemi. Atlanırsa ya da "Tut" olarak ayarlanırsa, gelen istekteki yöntem kullanılacaktır.`,
  [`The JavaScript source code to execute.`]: `Yürütülecek JavaScript kaynak kodu.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `Basit Dizin Erişimi İletişim Kuralı (LDAP), bir ağ üzerinden dağıtılmış dizin bilgi hizmetlerine erişmeye ve bu hizmetleri korumaya ilişkin bir internet iletişim kuralıdır. Etkinleştirilen bir kurumsal LDAP hizmetiniz varsa, bunu kullanıcı kimlik doğrulaması sağlamak için bir kaynak olarak yapılandırın.`,
  [`The Proxy URL to be used.`]: `Kullanılacak yetkili sunucu URL'si.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `Güvenli veri iletimi için kullanılacak SSL Profili.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `Güvenli veri iletimi için kullanılacak TLS Profili.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `Bir JSON bilgi yükünü doğrulamak için JSON şemasının URL'si.`,
  [`The URL of the XML schema for validating an XML payload.`]: `XML bilgi yükünü doğrulamak için XML şemasının URL'si.`,
  [`The URL to be invoked.`]: `Çağrılacak URL.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `Bir SOAP bilgi yükünün doğrulanması gereken WSDL şeması URL'si.`,
  [`The XML element name to be used for JSON array elements.`]: `JSON dizi öğeleri için kullanılacak XML öğesi adı.`,
  [`The XSLT source to execute.`]: `Yürütülecek XSLT kaynağı.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `Etkinleştirme bağlantısı, hesaplarını etkinleştirmesini sağlamak için bir kullanıcıya gönderilebilir`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `Analitik hizmeti, ağ geçidi hizmetindeki API olaylarını toplar. Her ağ geçidinin ilişkili bir Analitik hizmeti vardır. Zaman damgası içeren bir API olayı, her API işlemi çağrıldığında günlüğe kaydedilir.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `Analitik hizmeti, ağ geçidi hizmetindeki API olaylarını toplar. Her ağ geçidi hizmetinin ilişkili bir analitik hizmeti olabilir.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `Yetkilendirme URL'si istemci uygulamasının yetki iznini edindiği yerdir. Belirteç URL'si, istemci uygulamasının bir erişim belirteci karşılığında yetki izni verdiği verdir.  İçgözlem URL'si, API ağ geçidinin üçüncü kişi sağlayıcı tarafından yayınlanan erişim belirteçlerini doğruladığı yerdir.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `Temel yol, API'nin ilk URL bölümüdür ve anasistem adını ya da yollar veya işlemler için ek bölümleri içermez`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `Önbellek tipi, belgelerin önbelleğe alınıp alınmayacağını, Sunucu yanıtından alınan HTTP Önbellek Denetimi yönergelerinin destekleneceğini ya da geçersiz kılınacağını belirler. Bu özellik yalnızca Sunucudan bir yanıt alındığında geçerli olur; aksi takdirde ilke her zaman önceden önbelleğe kaydedilmiş süresi dolmayan yanıtı döndürür.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Sertifika Yöneticisi hizmeti ağ geçidi yönetimi uç noktası için sertifikaları ve ağ geçidinin işlediği etki alanlarını yönetmelidir. Buna ek olarak, API Connect Reserved Instance hizmetinin Certificate Manager hizmetine erişmesine yetki vermek için IBM Cloud IAM'yi kullanmanız gerekir.`,
  [`The certificate with which to decrypt the buffer`]: `Arabelleğin şifresini çözmek için kullanılacak sertifika`,
  [`The certificate with which to encrypt the buffer`]: `Arabelleği şifrelemek için kullanılacak sertifika`,
  [`The change is critical`]: `Değişim kritik önem taşır`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `İstemci uygulamaları, yalnızca burada tanımladığınız kapsamları veya kapsamların bir alt kümesini isteyebilir. Kapsamlar, sağlayıcıdan oluşturulan erişim belirteçlerine dahil edilir. OAuth korumalı bir API çağrıldığında ağ geçidi, erişimin verilip verilmeyeceğini belirlemek için güvenlik tanımında izin verilen kapsam listesine göre erişim belirteçlerinde taşınan kapsamları denetler. Bunun yanı sıra gelişmiş kapsam denetimini de uygulayabilirsiniz. Gelişmiş kapsam denetimi URL'leri uygulama kimlik doğrulamasından veya yapılandırılan URL'lere dayalı olarak kullanıcı kimliği doğrulamasından sonra çağrılır. Erişim belirteci tarafından verilen son kapsam izni tüm kapsam denetimlerinin sonucudur.`,
  [`The crypto object to use to decode the claim.`]: `Talebin kodunu çözmek için kullanılacak şifre nesnesi.`,
  [`The crypto object to use to encrypt the message.`]: `İletiyi şifrelemek için kullanılacak şifre nesnesi.`,
  [`The crypto object to use to sign the JWT.`]: `JWT'yi imzalamak için kullanılacak şifre nesnesi.`,
  [`The crypto object to use to verify the signature.`]: `İmzayı doğrulamak için kullanılacak şifre nesnesi.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `Geçerli eşleme, tanınmayan şema öğelerine başvurular içeriyor.`,
  [`The current organization does not contain any catalogs.`]: `Geçerli kuruluş, bir katalog içermiyor.`,
  [`The default User registry must be included in the list`]: `Varsayılan Kullanıcı kayıt defteri listeye eklenmelidir`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `Bilgi yüküne karşı tanımlama doğrulanmalıdır ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Geliştirici portalı, yayınlanan API ürünlerinin tüketici görünümünü sağlar. Kataloğunuz için geliştirici portalını yapılandırdıktan sonra ve bir veya daha çok API ürünü yayınlandığında uygulama geliştiricileri API'lere göz atabilir ve API'leri kullanabilir.`,
  [`The document is missing draft_api.info.version field.`]: `Belgede draft_api.info.version alanı eksik.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `Belgede draft_api.info.x-ibm-name alanı eksik.`,
  [`The document is missing draft_product.info.name field.`]: `Belgede draft_product.info.name alanı eksik.`,
  [`The document is missing draft_product.info.version field.`]: `Belgede missing draft_product.info.version alanı eksik.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `Ek kısıtlamalar, bu alanda bağımsız değişken olmayan bir dilimleme bağımsız değişkenini belirtiyor.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Bu alan, varsayılan değere sahip bir sınır bağımsız değişkeni içeriyor. Bu nedenle, belirtilen varsayılan boyut hiçbir zaman uygulanamaz.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Bu alan, varsayılan değere sahip bir sınır bağımsız değişkeni içeriyor ya da zorunlu. Bu nedenle, belirtilen varsayılan boyut hiçbir zaman uygulanamaz.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Bu alan, ne varsayılan bir boyut ne de dilimleme bağımsız değişkeni içeriyor.`,
  [`This field returns unbound list of values with composite type.`]: `Bu alan, birleşik tipte değerlerin sınırsız listesini getirir.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Bu alan ölçekli tipte ve tip ağırlığı != 0.0 olan değerlerin sınırsız listesini getirir.`,
  [`This test panel is deprecated and will be removed soon.`]: `Bu test paneli kullanım dışı bırakıldı ve çok yakında kaldırılacak.`,
  [`This type has no fields or no matching field.`]: `Bu tipte alan veya eşleşen bir alan yok.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Bu GatewayScript ilkesi, yalnızca eski API'lerin geçişi için olan apim modülünü kullanmamalıdır.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Bu XSLT ilkesi, yalnızca eski API'lerin geçişi için olan apim modülünü kullanmamalıdır.`,
  [`This type will be deleted.`]: `Bu tip silinecek.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `Aşağıdaki şemada API'niz için derleme akışı gösterilir. Derlemeyi değiştirmek için derleme düzenleyiciyi kullanın.`,
  [`The following files are available under the`]: `Aşağıdaki dosyalar şu kapsamda kullanılabilir:`,
  [`The following files contain a private key and cannot be uploaded:`]: `Aşağıdaki dosyalar özel anahtar içerdiğinden karşıya yüklenemiyor:`,
  [`The following is available under the`]: `Aşağıdakiler şu kapsamda kullanılabilir:`,
  [`The following lifecycle actions require approval`]: `Aşağıdaki yaşam çevrimi işlemleri için onay gerekir`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `Aşağıdaki listede, bu sağlayıcı kuruluşunda yaratılmış olan taslak API'ler görüntülenmektedir.`,
  [`The following list displays the draft products that have been created in this provider organization`]: `Aşağıdaki listede, bu sağlayıcı kuruluşunda yaratılmış olan taslak ürünler görüntülenmektedir.`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `Aşağıdaki listede bu Kataloğun üyeleri olarak eklenen tüm kullanıcılar ve atanan rolleri gösterilir`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `Aşağıdaki listede bu alanın üyeleri olarak eklenen tüm kullanıcılar ve atanan rolleri gösterilir`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `Aşağıdaki parametreler, OAuth sağlayıcısında yapılandırılan değerlerle eşleşmiyor`,
  [`The following roles are available in the Cloud Manager`]: `Cloud Manager'da aşağıdaki roller kullanılabilir`,
  [`The following types/fields will also be removed:`]: `Aşağıdaki tipler/alanlar da kaldırılacak:`,
  [`The following types/fields will be affected:`]: `Aşağıdaki tipler/alanlar da etkilenecek:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `{gatewayType} ağ geçidi özellik değerine izin verilmiyor. İzin verilen değerler şunlardır: datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `API'yi çağırmak için kullanılan anasistem`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `Anahtar deposu, kimliği doğrulamak ve HTTPS üzerinden veri aktarımını şifrelemek/şifresini çözmek için kullanılan, eşleştirilmiş genel sertifika çiftlerini içerir.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `Anahtar deposu, kimliği doğrulamak ve HTTPS üzerinden veri aktarımını şifrelemek/şifresini çözmek için kullanılan, eşleştirilmiş genel sertifika çiftlerini içerir. Sunucu Profili bir anahtar deposu gerektirir.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `Anahtar deposu, kimliğini doğrulamak için sunucu ya da istemci tarafından sunulan genel anahtarları ve özel sertifikaları içerir.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `Geçerli tarih ve saate eklenen, LTPA anahtarının geçerli olarak değerlendirildiği süre (saniye).`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `Geçerli tarih ve saate eklenen, JWT'nin geçerli olarak değerlendirildiği süre (saniye).`,
  [`The message to be sent with the error`]: `Hatayla birlikte gönderilecek ileti`,
  [`The message to be included within exception.`]: `Kural dışı durum içine eklenecek ileti.`,
  [`The HTTP status code to be included within exception.`]: `Kural dışı durum içine eklenecek HTTP durum kodu.`,
  [`The HTTP status reason phrase to be included within exception.`]: `Kural dışı durum içine eklenecek HTTP durum nedeni ifadesi.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Datapower yapılandırmasında tanımlanan ayırma sınırının adı.`,
  [`The name of a count limit defined in the datapower configuration.`]: `Datapower yapılandırmasında tanımlanan sayı sınırının adı.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Datapower yapılandırmasında tanımlanan hız sınırının adı.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `İstekten yanıt verilerini depolamak için kullanılacak değişkenin adı. Buna, "Eşleme" gibi diğer eylemlerde başvurulabilir.`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `Sonucu depolamak için kullanılacak değişkenin adı. Varsayılan olarak "message" kullanılır.`,
  [`The name of parameter to find the ID.`]: `Tanıtıcıyı bulacak parametre adı.`,
  [`The name of parameter to find the secret.`]: `Gizli anahtarı bulacak parametrenin adı.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `LTPA belirtecini oluşturmak için kullanmak istediğiniz LTPA anahtarının adı. Anahtarın belirli bir sürümünün kullanılmasını istiyorsanız, bunu <keyname>:<version> şeklinde belirtin. Örneğin, benim-ltpa-anahtarim:1.0.1. İlkenin kullanılacak anahtarın en son sürümünü otomatik olarak seçmesini istiyorsanız, bunu <keyname>:latest şeklinde belirtin. Örneğin, benim-ltpa-anahtarim:latest.`,
  [`The name of the error to be thrown`]: `Yayınlanacak hatanın adı`,
  [`The name of the variable to be added.`]: `Eklenecek değişkenin adı.`,
  [`The name of the variable to be cleared.`]: `Temizlenecek değişkenin adı.`,
  [`The name of the variable to be set.`]: `Ayarlanacak değişkenin adı.`,
  [`The nature of the XML validation to be performed.`]: `Gerçekleştirilecek XML doğrulamasının doğası.`,
  [`The operation to apply.`]: `Uygulanacak işlem`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `Bu eşleme çıkışı bir dizi içindedir ve içerikleri, seçilen giriş üzerinde yinelenerek oluşturulur. Seçilen giriş bir dizi değilse 1 uzunluğunda bir dizi üretilir.`,
  [`The owner must be an existing user in your user registry`]: `Sahip, kullanıcı kayıt defterinizde var olan bir kullanıcı olmalıdır`,
  [`The owner of consumer organization has the following privileges:`]: `Tüketici kuruluşun sahibi aşağıdaki ayrıcalıklara sahip:`,
  [`The owner of provider organization has the following privileges:`]: `Sağlayıcı kuruluşun sahibi aşağıdaki ayrıcalıklara sahip:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Sahibin görevleri şunları içerir: API ürünü yaşam çevrimini yönetmek, bir ya da daha çok API sağlayıcı kuruluşunu yönetmek, uygulama sağlayıcı topluluklarını yönetmek, API ve ürün tanımlarını yazmak.`,
  [`The page you are looking for might have been moved or does not exist.`]: `Aradığınız sayfa taşınmış olabilir ya da yok.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `Aradığınız sayfa taşınmış olabilir ya da yok. Lütfen destek için sistem yöneticinizle iletişim kurun.`,
  [`The parameters needed to send a valid API call.`]: `Geçerli bir API çağrısı göndermek için gereken parametreler.`,
  [`The password to use for HTTP Basic authentication.`]: `HTTP Temel kimlik doğrulaması için kullanılacak parola.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `Portal hizmeti, API'leri keşfetmek ve tüketici katılımı sağlamak için uygulama geliştiricileri tarafından kullanılan bir geliştirici portalı sağlar.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `Portal hizmeti, uygulama geliştiricilerin API'leri keşfetmek ve gerçekleştirmek için kullandıkları bir geliştirici portalı sağlar.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `İşleme durumu, en son etkinliğin özetiyle birlikte ağ geçidinin durumunun bir görünümünü sağlar.`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `Alıcı bir sahip olarak kaydeder ve yeni bir sağlayıcı kuruluş oluşturur. Kaydolduğunda kullanıcı, API Manager'da sağlayıcı kuruluş sahibi olarak oturum açabilir.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `Sonuçta elde edilen XML belgesinin kök öğe adı. Bu yalnızca giriş JSON belgesi sıradüzensel değilse ya da birden fazla en üst düzey özellik içeriyorsa gereklidir.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Buradaki kapsamlar, geliştiricilerin erişim için neye izin verildiğini anlaması içindir ve kapsam denetimi için etkili değildir.`,
  [`The selected catalog will only be used to search consumer organizations`]: `Seçilen katalog, yalnızca tüketici kuruluşları aramak için kullanılır`,
  [`The selected catalog does not have any configured gateways`]: `Seçilen katalog yapılandırılan ağ geçidi içermiyor`,
  [`The selected catalog does not have any compatible gateways`]: `Seçilen katalog uyumlu ağ geçidi içermiyor`,
  [`Save Preferences`]: `Tercihleri Kaydet`,
  [`Preferences`]: `Tercihler`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `Seçilen katalog yalnızca tüketici kuruluşları ve grupları aramak için kullanılır`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `Seçilen ağ geçicisi tipi, derlemenizde aşağıdaki ilkeleri geçersiz kılacak. Bu API'yi çalıştırabilmek için önce bu ilkeleri silmeniz gerekir.`,
  [`The selected product does not contain this API`]: `Seçilen ürün bu API'yi içermiyor`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `Seçilen ürüne, belirli bir tüketici kuruluşları ya da grupları listesi abone olabilir.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `Seçilen hizmetler, belirli bir sağlayıcı kuruluşları listesi için kullanılabilir olacak.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `Seçilen hizmetler tüm var olan ve yeni sağlayıcı kuruluşları için kullanılabilir olacak.`,
  [`The selected services will not be available to any provider organizations.`]: `Seçilen hizmetler, hiçbir sağlayıcı kuruluşu tarafından kullanılamayacak.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `Önbellek için anahtar olarak kullanılacak dize. Atlanırsa, URL dizgisinin tamamı anahtar olarak kullanılır.`,
  [`The server responded with a non-JSON body \n{body}`]: `Sunucu JSON dışında \n{body} gövdesiyle yanıt verdi`,
  [`The time interval needs to be higher than the access token time to live.`]: `Zaman aralığı erişim belirteci süresinden yüksek olmalıdır.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Belirteç, bir tanımlama bilgisinde ya da yerel depolama alanında depolanmaz. Test panelini kapatırsanız belirteç kaybolur, ancak yine de geçerli olabilir.`,
  [`The transfer protocol of the API.`]: `API'nin aktarma protokolü.`,
  [`The type of authentication.`]: `Kimlik doğrulama tipi.`,
  [`The type of authorization header.`]: `Yetkilendirme üst bilgisi tipi.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `Ayarlanacak değerin tipi. Bu herhangi bir dize, sayı ya da boole değeri olabilir.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `Ayarlanacak değerin tipi. Bu, dizgi, sayı ya da boole değeri olabilir.`,
  [`The username to use for HTTP Basic authentication.`]: `HTTP Temel kimlik doğrulaması için kullanılacak kullanıcı adı.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `Bu bağlantıya ilişkin kullanıcı artık geçerli değil. Lütfen yeni bir bağlantı oluşturun.`,
  [`The value that the variable will be set to.`]: `Değişkenin ayarlanacağı değer.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Bu alanın değeri, bileşik tipte bir listedir; ne bir varsayılan boyutu ne de bir dilimleme bağımsız değişkeni vardır. Karşılaşılırsa API Gateway, sorguyu alırsa GraphQL sunucusunun getirebileceği nesne sayısında bir sınır belirleyemez.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Bu alanın değeri, sayıl tipte bir listedir; ne kabul edilen bir boyutu ne de herhangi bir dilimleme bağımsız değişkeni vardır. Karşılaşılırsa API Gateway, sorguyu alırsa GraphQL sunucusunun getirebileceği nesne sayısında bir sınır belirleyemez.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `Sürümün url güvenli olması gerekir. Nesnenin sürümü {version} ve {slugVersion} sürümü eşleşmiyor.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `"name" özelliği URL güvenli olmalıdır (nesnenin ad değeri "{name}" ve "{slugName}" slugified ad değeri eşleşmelidir).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `'draft_api' özelliği bir OpenAPI tanımı içermelidir. OpenAPI sürümü 3 yalnızca 3.0.0 - 3.0.3 (belirtilen sürüm {version}) sürümlerini destekler`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `Ad alanı otomatik olarak sağladığınız başlığa göre doldurulur. API Connect komutları ve API çağrılarında çıkan değeri kullanın.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `Görünürlük ayarı, hangi sağlayıcı kuruluşların bir kaynağa erişebileceğini belirler. Seçenekler şunlardır: Genel (kaynak tüm sağlayıcı kuruluşları tarafından kullanılabilir); Özel (kaynak yalnızca sizin belirlediğiniz sağlayıcı kuruluşları tarafından kullanılabilir); ve Özel (kaynak görünmez ve bir sağlayıcı kuruluşu tarafından kullanılamaz).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `Görünürlük ayarı, bir hizmeti hangi sağlayıcı kuruluşlarının kullanabileceğini belirler. Seçenekler şunlardır: Genel (hizmet, tüm sağlayıcı kuruluşları tarafından kullanılabilir); Özel (hizmet, sizin tarafınızdan belirlenen sağlayıcı kuruluşları tarafından kullanılabilir); Gizli (hizmet görünür değildir ve herhangi bir sağlayıcı kuruluşu tarafından kullanılamaz).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `Görünürlük ayarı bir ağ geçidini kullanabilen sağlayıcı kuruluşlarını belirler. Görünürlük düzeyleri: Genel (ağ geçidi, tüm sağlayıcı kuruluşları tarafından kullanılabilir); Özel (ağ geçidi, sizin tarafınızdan belirlenen sağlayıcı kuruluşları tarafından kullanılabilir); Gizli (ağ geçidi görünür değildir ve herhangi bir sağlayıcı kuruluşu tarafından kullanılamaz).`,
  [`The {title} role`]: `{title} rolü`,
  [`The {url} resource does not exist`]: `{url} kaynağı yok`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Bu ürünle farklı bir Ağ Geçidi tipine sahip olmaları nedeniyle uyumsuz API'ler var.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Ardından yönetim uç noktası etki alanı için sertifikayı seçin. Sertifika bilinen bir sertifika yetkilisi tarafından atanmamışsa ilgili CA paketini seçin. Sertifika ve paket Sertifika Yöneticisi hizmeti tarafından yönetilmelidir.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Bu ağ geçidi tarafından yönetilecek etki alanlarını belirtin. SNI tekniği kullanılır; bunun sonucunda aynı sertifikaya gerek olmadan aynı IP adresi ve kapı aracılığıyla birden çok etki alanına hizmet verilebilir. Genel arama biçimi desteklenir. Varsayılan  (catch-all) etki alanı ‘*’ tablodaki son giriş olarak eklenmelidir. Her durumda Sertifika Yöneticisi hizmeti tarafından yönetilen bir sertifika belirterek diğer etki alanı adlarını gerektiği gibi girin.`,
  [`There should be at least {minSelected} {label} selected.`]: `En az {minSelected} {label} seçili olmalıdır.`,
  [`There are no API changes to publish.`]: `Yayınlanacak API değişikliği yok.`,
  [`There are no gateway services`]: `Ağ geçidi hizmeti yok`,
  [`There are no assemblies that match the chosen filters.`]: `Seçilen süzgeçlerle eşleşen bir derleme yok.`,
  [`There are no burst limits yet.`]: `Henüz bir ayırma sınırı yok.`,
  [`There are no catalogs`]: `Katalog yok`,
  [`There are no consumer organizations.`]: `Tüketici kuruluşu yok.`,
  [`There are no consumer organizations. You can create one`]: `Tüketici kuruluşu yok. Bir analitik hizmeti oluşturabilirsiniz`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `Tüketici kuruluşu yok. [Buradan]({link}) bir analitik hizmeti oluşturabilirsiniz.`,
  [`There are no email servers`]: `E-posta sunucusu yok`,
  [`There are no gateway services configured for the sandbox catalog`]: `Kum havuzu kataloğu için yapılandırılmış bir ağ geçidi hizmeti yok`,
  [`There are no lifecycle approvals enabled`]: `Etkin yaşam çevrimi onayı yok`,
  [`There are no new changes.`]: `Yeni değişiklik yok.`,
  [`There are no products to display`]: `Görüntülenecek bir ürün yok`,
  [`There are no products to replace`]: `Değiştirilecek bir ürün yok`,
  [`There are no products to set migration target`]: `Geçiş hedefini belirlemek için ürün yok`,
  [`There are no products to supersede`]: `Yerine geçilecek bir ürün yok`,
  [`There are no properties to configure for this policy`]: `Bu ilke için yapılandırılacak bir özellik yok`,
  [`There are no rate limits yet.`]: `Henüz bir hız sınırı yok.`,
  [`There are no security definitions`]: `Güvenlik tanımı yok`,
  [`There are no spaces`]: `Alan yok`,
  [`There are no target services`]: `Hedef hizmet yok`,
  [`There are no tasks to be displayed`]: `Görüntülenecek görev yok`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Kaydedilmemiş değişiklikler var. Devam etmek istediğinizden emin misiniz?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Kaydedilmemiş değişiklikler var. Devam ederseniz bunlar atılacak. Emin misiniz?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `Var olmayan ve listelenemeyen yaml dosyanızda bir api var, lütfen kaynak sekmesini kontrol edin.`,
  [`There is no item in the list.`]: `Listede öğe yok.`,
  [`There is no space`]: `Alan yok`,
  [`There is no warning in the schema.`]: `Şemada uyarı yok.`,
  [`There was no data found to populate the data table.`]: `Veri tablosunu doldurmak için bir veri bulunamadı.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Katalogdaki tüm API'ler için bu uç noktalar kullanılır`,
  [`Third party OAuth provider`]: `Üçüncü kişi OAuth sağlayıcısı`,
  [`Third party OAuth provider summary`]: `Üçüncü kişi OAuth sağlayıcısı özeti`,
  [`Third party`]: `Üçüncü kişi`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Bu API henüz bir derleme içermiyor. Yaratmak ister misiniz?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Bu kaynak artık yok, lütfen sunucudaki sonuçları yenileyin.`,
  [`Please refresh the results from the server`]: `Lütfen sunucudan sonuçları yenileyin`,
  [`Exact match`]: `Tam eşleşme`,
  [`Closest match`]: `En yakın eşleşme`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Bu API şu anda {productName} ürünü tarafından kullanılıyor. Lütfen üründen silin ve API'yi silme işlemine devam edin.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Aşağıdaki seçenek etkinleştirildiğinde bu API çağrılabilir olacak.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Bu katalogda etkinleştirilmiş otomatik abonelik yok. Bu katalogda test etmek için lütfen aşağıda bir ürün, plan ve uygulama seçin.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Bu uç nokta, temel uç noktayı tanımlamayan API'ler için varsayılan olarak kullanılır`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Bu, gelecekte değişecek ve iyileştirilmeye devam edecek bir teknik önizleme özelliğidir.`,
  [`This is the currently configured notification server.`]: `Bu şu anda yapılandırılan bildirim sunucusudur.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Bu, ilkeler ve uzantılar dahil dosyalarınızın depolanacağı dizindir`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `Burası API'lerin çalışacağı ve ilkelere ve uzantılara erişilebilen yerdir`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Bu oauth ilkesi, varsayılan olarak yayınlanan yol için gerekli tüm OAuth iletişim kuralı adımlarını gerçekleştirir. Adımların her birinin giriş ve çıkışları, belgelenen bağlam değişkenleri tarafından yönlendirilir. Desteklenen OAuth Bileşenlerini gereken şekilde ekleyebilir ya da kaldırabilirsiniz.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Bu oauth ilkesi, varsayılan olarak OAuth Doğrulaması için gerekli tüm OAuth/OpenID Connect iletişim kuralı adımlarını gerçekleştirir. Adımların her birinin giriş ve çıkışları, belgelenen bağlam değişkenleri tarafından yönlendirilir. Desteklenen OAuth Bileşenlerini gereken şekilde ekleyebilir ya da kaldırabilirsiniz.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Bu Oauth ilkesi, varsayılan olarak diğer tüm yollar için gereken tüm OAuth/OpenID Connect iletişim kuralı adımlarını gerçekleştirir. Adımların her birinin giriş ve çıkışları, belgelenen bağlam değişkenleri tarafından yönlendirilir. Desteklenen OAuth Bileşenlerini gereken şekilde ekleyebilir ya da kaldırabilirsiniz.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Bu oauth ilkesi, varsayılan olarak az kodu yolu için gerekli tüm OAuth/OpenID Connect iletişim kuralı adımlarını gerçekleştirir. Adımların her birinin giriş ve çıkışları, belgelenen bağlam değişkenleri tarafından yönlendirilir. Desteklenen OAuth Bileşenlerini gereken şekilde ekleyebilir ya da kaldırabilirsiniz.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Bu oauth ilkesi, varsayılan olarak belirteç yolu için gerekeli tüm OAuth/OpenID Connect iletişim kuralı adımlarını gerçekleştirir. Adımların her birinin giriş ve çıkışları, belgelenen bağlam değişkenleri tarafından yönlendirilir. Desteklenen OAuth Bileşenlerini gereken şekilde ekleyebilir ya da kaldırabilirsiniz.`,
  [`This operation is secured with access code flow OAuth`]: `Bu işlem, OAuth erişim kodu akışı ile güvenli kılındı`,
  [`This operation is secured with application flow OAuth`]: `Bu işlem, OAuth uygulama akışı ile güvenli kılındı`,
  [`This operation is secured with basic authentication`]: `Bu işlem, temel kimlik doğrulama ile güvenli kılındı`,
  [`This operation is secured with implicit flow OAuth`]: `Bu işlem, OAuth örtülü akışı ile güvenli kılındı`,
  [`This operation is secured with password flow OAuth`]: `Bu işlem, OAuth parola akışı ile güvenli kılındı`,
  [`This policy is not available on the selected gateway`]: `Bu ilke, seçilen ağ geçidinde kullanılmıyor`,
  [`This policy is used by one other operation`]: `Bu ilke başka bir işlem tarafından kullanılıyor`,
  [`This policy is used by {{count}} other operations`]: `Bu ilke başka {{count}} işlem tarafından daha kullanılıyor`,
  [`This role cannot be edited`]: `Bu rol düzenlenemez`,
  [`This role cannot be edited.`]: `Bu rol düzenlenemez.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Bu ayar, girişi var olmadığı ve varsayılan bir eşleme tanımlanmadığı için bir eşlemenin başarısız olması durumunda davranışı denetler. Varsayılan davranış, çıktıda değişiklik yapmamak olur, ancak bu onay kutusunu seçerseniz, hedef eşlemenin üst öğesi için, sürüm 4'ün davranışına öykünen boş bir nesne yaratılır.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Bu kullanıcı güvenliği ilkesi, Oauth derlemesi için EI(basic) ve AU(auth url) denetimi gerçekleştirir. Güvenlik denetimi yöntemini gerektiği gibi değiştirin.`,
  [`This will enable/disable application lifecycle`]: `Bu işlem sonucunda uygulama yaşam çevrimi etkinleştirilecek/devre dışı bırakılacak`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Bu, görevin otomatik olarak onayını etkinleştirir/geçersiz kılar. Görev otomatik onayı, katkıda bulunanların yanı sıra başlatanlar tarafından görevlerin onaylanmasını sağlar. Diğer onaylayanlar olmadığında bu seçenek kullanışlı olabilir, ancak başka bir kullanıcı tarafından denetimlerin atlanmasını sağlar.`,
  [`This will unset the catalog as the default catalog.`]: `Bu, kataloğun varsayılan ayarını kaldıracak.`,
  [`Throw`]: `Yayınla`,
  [`Time`]: `Zaman`,
  [`Time Interval`]: `Zaman Aralığı`,
  [`Time Limit`]: `Zaman Sınırı`,
  [`Time to Live`]: `Yaşam Süresi`,
  [`Time to live`]: `Yaşam süresi`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Uç noktadan yanıt gelmeden önce bekleme süresi. Varsayılan değer 60 saniyedir.`,
  [`Timeout`]: `Zamanaşımı`,
  [`To`]: `Kime`,
  [`to`]: `hedef`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Admin kuruluşunun üyesi olarak bir kullanıcı eklemek için kullanıcı kaydını seçin, kullanıcı adını girin ve atamak istediğiniz rolleri seçin. Kullanıcı kaydı tipi Yerel Kullanıcı Kayıt Defteriyse, daha sonra üye olarak eklenen yeni bir kullanıcı yaratma seçeneğiniz de olur. Üyelik anında etkinleştirilir ve belirtilen kullanıcı, Cloud Manager kullanıcı arabiriminde oturum açabilir`,
  [`To add a user as a member of a provider organization`]: `Bir kullanıcıyı sağlayıcı kuruluşun üyesi olarak eklemek için`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Bir kullanıcıyı Katalog üyesi olarak eklemek için kullanıcı adını seçin ve gerekli rolleri atayın. Sağlayıcı kuruluşta başka bir Kataloğun veya bir Alanın üyesi olan ve şu anda bu Kataloğun üyesi olmayan ve Katalog sahibi olmayan bir kullanıcı ekleyebilirsiniz.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Bir kullanıcıyı Alan üyesi olarak eklemek için kullanıcı adını seçin ve gerekli rolleri atayın. Sağlayıcı kuruluşta başka bir Kataloğun veya bir Alanın üyesi olan ve şu anda bu Alanın üyesi olmayan ve Alan sahibi olmayan bir kullanıcı ekleyebilirsiniz.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Üye eklemek ya da kaldırmak için [identity and access](/iam) hizmetini kullanın.`,
  [`To add members, use the [identity and access](/iam) service.`]: `Üye eklemek için [identity and access](/iam) hizmetini kullanın.`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Kullanıcıyı kuruluşun üyesi olarak eklemek için kullanıcı kayıt defterini seçin, kullanıcı adını girin ve bir rol kümesi atayın.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Üye olarak bir kullanıcı eklemek için kullanıcı adını seçin ve birtakım roller arayın.`,
  [`To assign default gateways, click **Edit**.`]: `Varsayılan ağ geçitlerini atamak için **Düzenle** seçeneğini tıklatın.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Bu yazılımı kullanmaya devam etmek için, yazılım lisans sözleşmesinin koşullarını kabul etmeniz gerekir.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `API Connect kullanmaya devam etmek için Kurumsal plana yükseltin.`,
  [`To get started, you must first enable spaces.`]: `Başlamak için önce alanları etkinleştirmeniz gerekir.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Araç takımını kurmak ve çalıştırmak için aşağıdaki adımları tamamlayın. [Ek bilgi]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Yönetici kuruluş üyesi olarak yeni bir kullanıcıyı davet etmek için kullanıcının e-posta adresini veya adını girin ya da kullanıcı kayıt defterinden adını seçin.  Tüm üyelere otomatik olarak Üye rolü atanır ve bu da temel izinleri sağlar ve silinemez. Başka izin gerekmiyorsa, diğer rolleri boş bırakın.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Önceden kullanıcı kayıt defterinizde olmayan bir sahibi davet etmek için sahibin e-posta adresini girin`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Yeni bir ağ geçidi kaydetmek için **Ağ geçitleri** sayfasını ziyaret edin.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Değerleri aşağıdaki alan özelliklerinden biriyle değiştirmek için parantez içine özellik adıyla $() yazın`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Admin kuruluşunun bir üyesi olarak yeni bir kullanıcıyı kaydetmek üzere e-posta daveti göndermek için geçerli bir e-posta adresi girin ve kullanıcıya atamak istediğiniz rolleri seçin. Kişiyi kayıt için davet eden e-posta adresine etkinleştirme bağlantısı içeren bir e-posta gönderilir. Kullanıcı Beklemede durumuna alınır ve hesaplarını etkinleştirmek üzere kayıt formunu doldurduktan sonra Etkinleştirildi durumuna geçilir. Daha sonra hesap etkinleştirme sırasında belirttikleri API Connect kullanıcı adıyla Cloud Manager'da oturum açabilirler.`,
  [`Toggle`]: `Geçiş Yap`,
  [`Toggle application lifecycle?`]: `Uygulama yaşam çevrimini aç/kapat?`,
  [`Toggle default catalog?`]: `Varsayılan kataloğu aç/kapat?`,
  [`Toggle info panel between right and bottom`]: `Bilgi panosu için sağ ve alt arasında geçiş yapın.`,
  [`Toggle profile`]: `Profili aç/kapat`,
  [`Toggle spaces?`]: `Alanlar arasında geçiş yapılsın mı?`,
  [`Toggle task self-approval?`]: `Görev otomatik onayını aç/kapa?`,
  [`Token`]: `Belirteç`,
  [`Token endpoint`]: `Belirteç uç noktası`,
  [`Token Expiry`]: `Belirtecin Süre Bitimi`,
  [`Token Management`]: `Belirteç Yönetimi`,
  [`Token Output`]: `Belirteç Çıktısı`,
  [`Token URL`]: `Belirteç URL'si`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Belirteç URL'si, OAuth sağlayıcısı "{providerTitle}" tarafından tanımlandığı şekilde, {endpoint} ile eşleşmelidir`,
  [`Token Version`]: `Belirteç Sürümü`,
  [`Token management`]: `Belirteç yönetimi`,
  [`Token management type`]: `Belirteç yönetimi tipi`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `Belirteç yönetimi, istemci uygulamalarının ya da kaynak sahiplerinin OAuth belirteçlerini iptal edip etmediği ve nasıl iptal ettiği de dahil olmak üzere, belirteçleri yönetmenizi ve yeniden yürütme saldırılarını önlemenizi sağlar. API Connect, yerel bir ağ geçidi ya da üçüncü kişi uç noktasıyla belirteç yönetimini destekler. Yerel bir ağ geçidi durumunda kota uygulaması belirteçlerin yönetimi için kullanılır. Üçüncü kişi uç noktasında, belirteçleri yönetmek için dış hizmete bir URL sağlarsınız.`,
  [`Token path`]: `Belirteç yolu`,
  [`Token revocation`]: `Belirteç iptali`,
  [`Token secret`]: `Belirteç güvenlik dizgisi`,
  [`Token validation`]: `Belirteç doğrulama`,
  [`Tokens`]: `Belirteçler`,
  [`Token relay`]: `Belirteç geçişi`,
  [`Toolkit credentials`]: `Araç takımı kimlik bilgileri`,
  [`Token Relay`]: `Belirteç Geçişi`,
  [`Topology`]: `Topoloji`,
  [`Topology Administrator`]: `Topoloji Yöneticisi`,
  [`Total time`]: `Toplam süre`,
  [`Transfer Ownership to a New User`]: `Sahipliği Yeni Bir Kullanıcıya Aktar`,
  [`Transfer Ownership to an Existing User`]: `Sahipliği Var Olan Bir Kullanıcıya Aktar`,
  [`Transfer ownership`]: `Sahipliği aktar`,
  [`Transfer ownership to`]: `Sahipliği şuraya aktar:`,
  [`Transfer ownership to a new user`]: `Sahipliği yeni bir kullanıcıya aktar`,
  [`Transfer ownership to an existing user`]: `Sahipliği var olan bir kullanıcıya aktar`,
  [`Transforms`]: `Dönüştürmeler`,
  [`Truststore Certificates`]: `Güvenli Depo Sertifikaları`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Güvenli depo sertifikaları, sertifika yetkilisi tarafından verilen genel sertifikalardır.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Güvenli depolar, doğrulanan genel anahtarları içeren güvenli sertifikalar içerir. Güvenli depodaki sertifikalar, genellikle üçüncü kişi sertifika yetkilisinden (CA) elde edilir.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Güvenli depolar, doğrulanmış genel anahtarlarla güvenli sertifikalar içerir. Güvenli depodaki sertifikalar, genellikle üçüncü kişi sertifika yetkilisinden (CA) elde edilir.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Güvenli depolar, doğrulanmış ortak anahtarlar da dahil olmak üzere güvenilir sertifikaları depolar. Güvenli depodaki sertifikalar, genellikle üçüncü kişi sertifika yetkilisinden (CA) elde edilir.`,
  [`Try again or upload a schema`]: `Yeniden deneyin ya da bir şemayı karşıya yükleyin`,
  [`Try refreshing the page or contacting support.`]: `Sayfayı yenilemeyi veya destek ile iletişim kurmayı deneyin.`,
  [`Turkish`]: `Türkçe`,
  [`Tutorials`]: `Öğretici Malzemeler`,
  [`Types`]: `Tipler`,
  [`Type of user`]: `Kullanıcı tipi`,
  [`Type or select a catalog`]: `Bir katalog yazın ya da seçin`,
  [`Type to add organizations`]: `Kuruluş eklemek için yazın`,
  [`TypeError: Failed to fetch`]: `TypeError: Getirilemedi`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `Yönetim uç noktası URL'si`,
  [`URLs for all operations in the API begin with this value.`]: `API'deki tüm işlemlere ilişkin URL'ler bu değerle başlar.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `ABD Kamu Kullanıcıları İçin Sınırlı Haklar - Kullanımı, çoğaltılması ya da açıklanması, IBM Corp. ile yapılan GSA ADP Schedule Contract adlı sözleşmeyle sınırlandırılmıştır.`,
  [`User Defined`]: `Kullanıcı Tanımlı`,
  [`Userinfo POST`]: `Userinfo POST`,
  [`USER MANAGED`]: `KULLANICI YÖNETİMLİ`,
  [`USERNAME`]: `KULLANICI ADI`,
  [`User can delete the keystore history.`]: `Kullanıcı anahtar deposu geçmişini silebilir.`,
  [`Unable to parse API definition: {msg}`]: `API tanımı ayrıştırılamıyor: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Tipler ve/veya alanlar kaldırılırken hatalar nedeniyle devam edilemiyor.`,
  [`Unarchive`]: `Arşivden kaldır`,
  [`Unassociate`]: `İlişkiyi kaldır`,
  [`Unassociate analytics service`]: `Analitik hizmetinin ilişkilendirmesini kaldır`,
  [`Unauthorized`]: `Yetkisiz`,
  [`Unauthorized API Request`]: `Yetkisiz API İsteği`,
  [`Unbound lists`]: `Bağlı olmayan listeler`,
  [`Unit`]: `Birim`,
  [`Union`]: `Birleşim`,
  [`Union type`]: `Birleşim tipi`,
  [`Unique email address`]: `Benzersiz e-posta adresi`,
  [`Unlimited`]: `Sınırsız`,
  [`Unnecessary assumed size`]: `Gereksiz varsayılan sınır`,
  [`Unnecessary slicing arguments`]: `Gereksiz dilimleme bağımsız değişkenleri`,
  [`Unrecognized Schema Element(s) Encountered`]: `Tanınmayan Şema Öğeleriyle Karşılaşıldı`,
  [`Unsupported media type ({type})`]: `Desteklenmeyen ortam tipi ({type})`,
  [`Untitled`]: `Başlıksız`,
  [`Update`]: `Güncelle`,
  [`Update gateway services`]: `Ağ geçidi hizmetlerini güncelle`,
  [`Update assembly`]: `Derlemeyi güncelle`,
  [`Update group failed.`]: `Grup güncellenemedi.`,
  [`Update schema defaults giving preference to`]: `Tercih veren şema varsayılanlarını güncelle`,
  [`Update the sender details that are displayed on invitation emails`]: `Davet e-postalarında görüntülenen gönderen ayrıntılarını güncelleyin`,
  [`Update the summary details for this role`]: `Bu rol için özet ayrıntıları güncelleyin`,
  [`Updating Draft API`]: `Taslak API güncelleniyor`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `Şema varsayılanları güncellendiğinde değişiklikleriniz geçersiz kılınır. Devam etmek istediğinizden emin misiniz?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `{existingType} tipindeki API'nin {type} API olarak güncellenmesine izin verilmiyor.`,
  [`Upload`]: `Karşıya Yükle`,
  [`Upload a schema definition language file to replace your schema.`]: `Şemanızı değiştirmek için bir şema tanımlama dili dosyasını karşıya yükleyin.`,
  [`Upload GraphQL schema`]: `GraphQL şemasını karşıya yükle`,
  [`Upload local`]: `Yereli karşıya yükle`,
  [`Upload policies`]: `İlkeleri karşıya yükle`,
  [`Upload policy`]: `İlkeyi karşıya yükle`,
  [`Upload schema`]: `Şemayı karşıya yükle`,
  [`Upload schema definition language (SDL) file`]: `Şema tanımlama dili (SDL) dosyasını karşıya yükleyin`,
  [`Upload truststore certificates`]: `Güvenli depo sertifikalarını karşıya yükle`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Uygulamanız için X509 sertifikasını PEM biçiminde yükleyin. Yalnızca bir PEM dosyasını karşıya yükleyebilirsiniz, ancak tek bir dosyaya birden çok sertifika ekleyebilirsiniz.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Uygulamanız için PEM biçiminde X509 sertifikasını yükleyin`,
  [`Upload your public certificate`]: `Genel sertifikanızı karşıya yükleyin`,
  [`Uploaded and validated YAML file`]: `YAML dosyası karşıya yüklendi ve doğrulandı`,
  [`Upload extension`]: `Karşıya yükleme uzantısı`,
  [`Use all compatible gateway services`]: `Uyumlu tüm ağ geçidi hizmetlerini kullan`,
  [`Use Content Type`]: `İçerik Tipini kullan`,
  [`Use DataPower API Gateway`]: `DataPower API Ağ Geçidini Kullan`,
  [`Use DataPower Gateway`]: `DataPower Ağ Geçidini Kullan`,
  [`Use Dynamic DNS`]: `Dinamik DNS Kullan`,
  [`Use JSONata to specify the property to redact or remove`]: `Düzeltilecek ya da kaldırılacak özelliği belirtmek için JSONata kullanın`,
  [`Use XPath to specify the property to redact or remove.`]: `Düzeltilecek ya da kaldırılacak özelliği belirtmek için XPath kullanın.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Seçilen bir kataloğu yayınlamak istediğiniz varsayılan katalog olarak kullanın`,
  [`Use an existing JSON or YAML definition of the product`]: `Ürünün var olan bir JSON ya da YAML tanımını kullan`,
  [`Use an existing definition of a REST proxy`]: `Bir REST yetkili sunucusunun var olan bir tanımını kullan`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `REST yetkili sunucusunun veya SOAP API'sinin var olan bir tanımını kullan`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Bir REST yetkili sunucusu, GraphQL yetkili sunucusu veya SOAP API'sinin var olan bir tanımını kullan`,
  [`Use an existing definition of a product`]: `Bir ürünün var olan bir tanımını kullan`,
  [`Use context current payload`]: `Bağlamın geçerli bilgi yükünü kullan`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Özelleştirilmiş katalog uç noktası URL'si kullan (v5 uyumluluğu)`,
  [`Use dynamic DNS`]: `Dinamik DNS kullan`,
  [`Use endpoint from API`]: `API'deki uç noktayı kullan`,
  [`Use gateway URLs`]: `Ağ geçidi URL'lerini kullan`,
  [`Use gateway service configured OAuth shared secret key`]: `Ağ geçidi hizmeti yapılandırılan OAuth paylaşılan gizli anahtarı kullan`,
  [`Use group authentication`]: `Grup kimlik doğrulamasını kullan`,
  [`Use only first array element`]: `Yalnızca ilk dizi öğesini kullan`,
  [`Use static DNS`]: `Statik DNS kullan`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Dış OIDC sağlayıcı trafiği için portalı uç nokta olarak kullan`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `API'lerinizi test etmek ve keşfetmek için API Gezgini'ni kullanın. Gezgin, proje dizininizde yer alan tüm API'ler için işlemleri, tanımları ve belgeleri gösterir. Gezgin, API'nizi çalıştırmak için şablon koduyla birlikte parametreleri, model eşgörünümü verilerini ve yanıt kodlarını listeler.`,
  [`Use the actions menu to manage and publish your APIs.`]: `API'lerinizi yönetmek ve yayınlamak için işlemler menüsünü kullanın.`,
  [`Use the actions menu to manage and publish your products`]: `Ürünlerinizi yönetmek ve yayınlamak için eylemler menüsünü kullanın`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Analytics'te depolanan API etkinliğine yönelik günlük kaydı tercihlerinizi yapılandırmak için etkinlik günlüğü ilkesini kullanın. Belirttiğiniz tercihler, API etkinliğinin ayrıntılarını toplama ve depolamaya yönelik varsayılan ayarları geçersiz kılacaktır.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `İstemci kimlik bilgilerini çıkarmak ve doğrulamak için istemci güvenlik ilkesini kullanın.`,
  [`Use the default built-in Sandbox Catalog`]: `Varsayılan yerleşik Korumalı Alan Kataloğunu kullan`,
  [`Use the editor for manual input or upload a file below.`]: `El ile giriş için düzenleyiciyi kullanın veya aşağıda bir dosyayı karşıya yükleyin.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `El ile giriş için düzenleyiciyi kullanın veya aşağıda bir dosyayı karşıya yükleyin. Bir XML/XSD dosyası tanımlanırsa geçerli şema yalnızca XML'de düzenlenebilir.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `API Connect ağ geçidi API uygulaması için kullanılıp kullanılmadığını belirtmek için uygulanan uzantıyı kullanın`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `CLI ile çalışırken aşağıdaki Management Endpoint URL'yi kullanın.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `API Designer'da bir bulut bağlantısı oluştururken aşağıdaki Management Endpoint URL'yi kullanın.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Belirtilen bir DataPower GatewayScript programını yürütmek için gatewayscript ilkesini kullanın.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `GraphQL şemasında içgözlem için graphql-introspect ilkesini kullanın.`,
  [`Use the host value from Open API Definition`]: `Open API Tanımındaki anasistem değerini kullanın`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Bir koşul yerine getirildiğinde derlemenin bir bölümünü uygulamak için if ilkesini kullanın.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Belirtilen bir JavaScript programını yürütmek için javascript ilkesini kullanın.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Bilgi yükünüzün içeriğini JSON'dan XML'e dönüştürmek için json'dan xml'e ilkesini kullanın.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Bir API için varsayılan etkinlik günlüğü yapılandırmasını özelleştirmek veya geçersiz kılmak için Günlük ilkesini kullanın.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Derleme akışınıza dönüştürmeleri uygulamak ve değişkenler arasında ilişkileri belirtmek için Eşle ilkesini kullanın.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `API akışınız içindeki değişkenler arasındaki ilişkiyi belirtmek ve bu değişkenlere dönüştürmeler uygulamak için eşle ilkesini kullanın.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `OAuth belirteçleri oluşturmak için oauth ilkesini kullanın.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Belirli bir işleme derlemenin bir bölümünü uygulamak için İşlem anahtarı ilkesini kullanın.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Bir isteği ya da iletiyi XML veya JSON olarak ayrıştırmak ya da ikili verileri ikili büyük nesne (BLOB) olarak ayrıştırmak için ayrıştırma ilkesini kullanın.`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Bir çalıştırma zamanı değişkenini dizgi değerine ayarlamak için ya da bir çalıştırma zamanı değişkeni eklemek ya da temizlemek için değişken ayarla ilkesini kullanın.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Bir derleme akışının yürütmesi sırasında erişildiğinde bir hata göndermek için atma ilkesini kullanın.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Kullanıcının kimliğini almak, doğrulamak ve yetkilendirmek için kullanıcı güvenliği ilkesini kullanın.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Bir derlemedeki bilgi yükünü, bir JSON ya da XML şemasına karşı doğrulamak için DataPower Gateway ile doğrula ilkesini kullanın.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Bilgi yükünüzün içeriğini XML'den JSON'a dönüştürmek için xml'den json'a ilkesini kullanın.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Bilgi yüküne bir XSLT dönüştürmesi uygulamak için xslt ilkesini kullanın.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Karşıya yükleme işleminde İçerik Kodlama sıkıştırmasını etkinleştirmek/devre dışı bırakmak için kullanılır.`,
  [`User`]: `Kullanıcı`,
  [`User group prefix`]: `Kullanıcı grubu öneki`,
  [`User group suffix`]: `Kullanıcı grubu soneki`,
  [`User mapping`]: `Kullanıcı eşleme`,
  [`User Registries`]: `Kullanıcı Kayıt Defterleri`,
  [`User registries`]: `Kullanıcı kayıt defterleri`,
  [`User Registry Name`]: `Kullanıcı Kayıt Defteri Adı`,
  [`User registry name`]: `Kullanıcı kayıt defteri adı`,
  [`User Registry cannot be deleted`]: `Kullanıcı Kaydı silinemez`,
  [`User Registry "{name}" has been deleted`]: `"{name}" Kullanıcı Kayıt Dosyası silindi`,
  [`User security`]: `Kullanıcı güvenliği`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Kullanıcı kayıt defterleri, alanlar etkinleştirildiğinde her alanda yönetilir. Lütfen kullanıcı kayıt defterlerini yönetmek için alan ayarlarına gidin.`,
  [`User registries defined for consumer onboarding`]: `Tüketici katılımı için tanımlanan kullanıcı kayıt defterleri`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Tüketici katılımı için tanımlanan kullanıcı kayıt defterleri \n \nAynı site için farklı kullanıcı kayıt defterleri de dahil olmak üzere, Geliştirici Portalı'ndaki her hesabın benzersiz bir e-posta adresi (site Yönetici hesabı da içinde olmak üzere) olmalıdır.`,
  [`User registry`]: `Kullanıcı kayıt defteri`,
  [`User registry configuration has been updated.`]: `Kullanıcı kaydı yapılandırması güncellendi.`,
  [`User registry has been created`]: `Kullanıcı kayıt defteri oluşturuldu`,
  [`User registry {title} deleted.`]: `Kullanıcı kaydı {title} silindi.`,
  [`User registry {title} has been created.`]: `Kullanıcı kayıt defteri {title} yaratıldı.`,
  [`User registry is not visible or not available.`]: `Kullanıcı kayıt defteri görünmüyor ya da kullanılamıyor.`,
  [`User registry {title} has been updated.`]: `Kullanıcı kayıt defteri {title} güncellendi.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `Yalnızca seçili izin verme tipleri aşağıdakilerin birini veya birkaçını içeriyorsa kullanıcı güvenliği ayarları geçerlidir: Açık, Erişim kodu ve Kaynak sahibi - Parola.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `Yalnızca seçili izin verme tipleri aşağıdakilerin birini veya birkaçını içeriyorsa kullanıcı güvenliği ayarları geçerlidir: Açık, Erişim kodu ve Kaynak sahibi - Parola. Bu sayfada değişiklik yaparsanız Yapılandırma sayfasında bu izin verme tiplerinden en az birini de seçmeniz gerekir.`,
  [`UserInfo endpoint`]: `Kullanıcı bilgileri uç noktası`,
  [`UserInfo URL`]: `UserInfo URL'si`,
  [`Username`]: `Kullanıcı Adı`,
  [`Username / Email`]: `Kullanıcı adı / E-posta`,
  [`Username and password fields are required in order to test configuration`]: `Yapılandırmayı test etmek için kullanıcı adı ve parola alanları gereklidir`,
  [`Username context variable`]: `Kullanıcı adı bağlam değişkeni`,
  [`Username of the new user`]: `Yeni kullanıcının kullanıcı adı`,
  [`Username should not contain special characters!`]: `Kullanıcı adı özel karakter içermemelidir!`,
  [`Username should not include spaces!`]: `Kullanıcı adı boşluk içermemelidir!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Bir tüketici kuruluşa ait kullanıcıların, görünürlük ve abone olunabilirlik durumlarıyla belirlendiği şekilde, geliştirici portalına ve yayınlanan API ürünlerine erişimi vardır.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `[IBM Cloud IAM]({link}) ürününü kullanarak, API Connect Reserved Instance hizmetinin Certificate Manager hizmetine erişmesine yetki verin.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Userinfo uç noktasıyla iletişim kurulurken HTTP POST yöntemi kullanılıyor`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Buluttan IBM APIC belirteci süre sonu ayarını kullanın`,
  [`Update API`]: `API'yi güncelle`,
  [`VALUE`]: `DEĞER`,
  [`Version`]: `Sürüm`,
  [`Visible to`]: `Görebilecekler`,
  [`Validate`]: `Doğrula`,
  [`Validate message body`]: `İleti gövdesini doğrula`,
  [`Validate message headers`]: `İleti üstbilgilerini doğrula`,
  [`Validate message fault details`]: `İleti hatası ayrıntılarını doğrula`,
  [`Validate SOAP 1.1 encoding rule`]: `SOAP 1.1 kodlama kuralını doğrula`,
  [`Validate JSON Web Token (JWT)`]: `JSON Web Belirtecini (JWT) Doğrula`,
  [`Validate JWT`]: `JWT'yi Doğrula`,
  [`Validate Username Token`]: `Kullanıcı Adı Belirtecini Doğrula`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Kimlik Doğrulama URL ya da LDAP kullanıcı kayıt defterini kullanarak Web Services Security UsernameToken bilgi yükünü doğrulayın.`,
  [`Validate against`]: `Şuna karşı doğrula`,
  [`Validate request`]: `İsteği doğrula`,
  [`Validate the GraphQL query and analyze the expected cost`]: `GraphQL sorgusunu doğrula ve beklenen maliyeti analiz et`,
  [`Validate the returned JSON and analyze the real cost`]: `Getirilen JSON'ı doğrula ve gerçek maliyeti analiz et`,
  [`Validating API ...`]: `API doğrulanıyor...`,
  [`Validating Draft API`]: `Taslak API doğrulanıyor`,
  [`Validity Period`]: `Geçerlilik Dönemi`,
  [`Value`]: `Değer`,
  [`Value for located in must be selected.`]: `Bulunan yer değeri seçilmelidir.`,
  [`Value for the variable to be added.`]: `Eklenecek değişkenin değeri.`,
  [`Value for type must be selected.`]: `Tip değeri seçilmelidir.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `"@cost" yönergesindeki ""weight" bağımsız değişkeninin değeri < 0'dır.`,
  [`Values`]: `Değerler`,
  [`Vanity API endpoint`]: `Özel API uç noktası`,
  [`Variable name`]: `Değişken adı`,
  [`Variable value`]: `Değişken değeri`,
  [`Vendor Extensions`]: `Satıcı Firma Uzantıları`,
  [`Verb`]: `Komut`,
  [`Verify`]: `Doğrula`,
  [`Verify Access Token`]: `Erişim Belirtecini Doğrula`,
  [`Verify Crypto JWK variable name`]: `Şifre JWK'sini Doğrulama değişken adı`,
  [`Verify Crypto Object`]: `Şifre Nesnesini Doğrula`,
  [`Verify Token`]: `Belirteci Doğrula`,
  [`Verify authorization code`]: `Yetkilendirme kodunu doğrula`,
  [`Verify refresh token`]: `Yenileme belirtecini doğrula`,
  [`Version of the LTPA token.`]: `LTPA belirtecinin sürümü.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `İçe aktarılan ürün dosyasında 'plans' altındaki 'apis' özelliğini denetleyin. Her planın 'apis' bölümünden en az bir api'yi işaret eden 'apis' özelliği olması gerekir.`,
  [`View`]: `Görüntüle`,
  [`View extensions`]: `Uzantıları görüntüle`,
  [`View Forum`]: `Forumu Görüntüle`,
  [`View owners`]: `Sahipleri görüntüle`,
  [`View policies`]: `İlkeleri görüntüle`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Seçilen iletişim kuralı sürümü için kullanılabilecek şifreleri görüntüleyin ve yönetin.`,
  [`View analytics`]: `Analitiği görüntüle`,
  [`View and edit permissions for the roles in this space`]: `Bu alandaki rollere ilişkin izinleri görüntüleyin ve düzenleyin`,
  [`View and edit the permissions for the roles in the current organization`]: `Geçerli kuruluştaki roller için izinleri görüntüleyin ve düzenleyin`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Tüketici kuruluşları için rol varsayılanlarında bulunan rolleri görüntüleyin ve düzenleyin`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Sağlayıcı kuruluşları için rol varsayılanlarında bulunan rolleri görüntüleyin ve düzenleyin`,
  [`View approval history`]: `Onay geçmişini görüntüle`,
  [`View Approval History`]: `Onay Geçmişini Görüntüle`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Adım adım yönergelerle belgeleri ve eğitimleri görüntüleyin.`,
  [`View error ID`]: `Hata tanıtıcısını görüntüle`,
  [`View status`]: `Durumu görüntüle`,
  [`View more`]: `Daha fazlasını görüntüle`,
  [`View source`]: `Kaynağı görüntüle`,
  [`View subscription`]: `Aboneliği görüntüle`,
  [`View template in`]: `Şablonu şu dilde görüntüle`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `API Connect kullanıcı arabirimleri erişiminin uç noktalarını görüntüleyin.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Yönetici ve sağlayıcı REST API'leri için uç noktaları görüntüleyin.`,
  [`View the endpoints for the consumer REST APIs.`]: `Tüketici REST API'leri için uç noktaları görüntüleyin.`,
  [`Viewer`]: `Görüntüleyici`,
  [`Views the API consumer organization`]: `API tüketici kuruluşunu görüntüler`,
  [`Views the API provider organization`]: `API sağlayıcı kuruluşunu görüntüler`,
  [`Views the admin organization`]: `Yönetici kuruluşunu görüntüler`,
  [`Views the app developer organization`]: `Uygulama geliştirici kuruluşunu görüntüler`,
  [`Views the catalog`]: `Kataloğu görüntüler`,
  [`Views the space`]: `Alanı görüntüler`,
  [`View|permission`]: `Görüntüle`,
  [`Visibility`]: `Görünürlük`,
  [`Visibility options`]: `Görünürlük seçenekleri`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware belgeleri`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL URL Adresi`,
  [`WSDL has been successfully validated`]: `WSDL başarıyla doğrulandı`,
  [`WSDL to REST`]: `WSDL - REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `WS-I Temel Profil doğrulaması`,
  [`Warn`]: `Uyarı`,
  [`Warning`]: `Uyarı`,
  [`Warning:`]: `Uyarı:`,
  [`Warnings`]: `Uyarılar`,
  [`We just emailed recipients a test message:`]: `Alıcılara bir test iletisini e-postayla gönderdik:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `"Test" sekmesine taşıyoruz; burada API'nizi test edebilecek ve API'nizin hatalarını ayıklayabileceksiniz.`,
  [`Weather Product`]: `Hava Durumu Ürünü`,
  [`Web Endpoint`]: `Web Uç Noktası`,
  [`Web service operations`]: `Web hizmeti işlemleri`,
  [`weight`]: `Ağırlık`,
  [`Weight`]: `Ağırlık`,
  [`Welcome to API Connect Administration`]: `API Connect Yönetimine Hoş Geldiniz`,
  [`Welcome to API Connect Reserved`]: `API Connect Reserved'a Hoş Geldiniz`,
  [`Welcome to API Designer`]: `API Designer'a Hoş Geldiniz`,
  [`Welcome to API Manager`]: `API Manager'a Hoş Geldiniz`,
  [`Welcome to Cloud Manager`]: `Cloud Manager'a Hoş Geldiniz`,
  [`Welcome to the API Designer`]: `API Designer'a Hoş Geldiniz`,
  [`Welcome to the API Manager`]: `API Manager'a Hoş Geldiniz`,
  [`Welcome to the Cloud Manager`]: `Cloud Manager'a Hoş Geldiniz`,
  [`What are you looking for today?`]: `Bugün sizin için ne yapabiliriz?`,
  [`What's new`]: `Yenilikler`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `Sırada ne var? Bir katalogta faturalama bütünleştirmenizin kullanıcıların ürünlerine planlar eklemesine izin vermesini sağlayın.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `True olarak ayarlandığında, çağırma ilkesi, hedef URL'ye gönderilen isteğe "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" üstbilgilerini ekler.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Bir API ürünü yayınlandığında, katalogla ilişkili geliştirici portalında görünür hale gelir. Üründeki API uç noktaları, hedeflenen katalog ya da alanla ilişkili ağ geçitlerinde kullanılabilir hale gelir. Hazırlanan ürünler katalogtadır, ancak canlı değildir veya tüketiciler tarafından görünmez.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Bir API ürünü yayınlandığında, katalogla ilişkili geliştirici portalında görünür hale gelir. Üründeki API uç noktaları, hedeflenen katalog ya da alanla ilişkili ağ geçitlerinde kullanılabilir hale gelir. Hazırlanan ürünler katalogdadır, ancak canlı değildir veya tüketiciler tarafından görünmez. Bir ürünün üretim dışı bir katalogda yeniden hazırlanması ya da yeniden yayınlanmasının, var olan aboneliklerin kaldırılmasına neden olacağına lütfen dikkat edin.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Alanlar bir katalog için etkinleştirildiğinde yapılandırılan OAuth sağlayıcısı bir alandan görüntülenmelidir.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Alanlar etkinleştirildiğinde, katalogdaki var olan ürünler varsayılan bir alana taşınır.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Kullanıcı etkinleştirme bağlantısını tıklattığında, kayıt formunu doldurması istenir. Kaydolduktan sonra kullanıcı oturum açabilir.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Sağlayıcı ya da tüketici kuruluşları için bir rol eklediğinizde bu rol, rol varsayılan değerine eklenir. Rol varsayılan değerleri, yeni bir kuruluş yaratıldığında kullanılabilecek varsayılan rolleri belirlemek için kullanılan, önceden yapılandırılmış rol şablonlarıdır.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Bir ürünü kullanım dışı bıraktığınızda ürüne abone olan uygulama geliştiricileri ürünü kullanmaya devam edebilir, ancak yeni geliştiriciler ürüne abone olamaz.\n\n [Ek bilgi]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Sağlayıcı ya da tüketici kuruluşları için bir rolü düzenlediğinizde bu rol, rol varsayılan değerine eklenir. Rol varsayılan değerleri, yeni bir kuruluş yaratıldığında kullanılabilecek varsayılan rolleri belirlemek için kullanılan, önceden yapılandırılmış rol şablonlarıdır.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `OpenID Connect'i etkinleştirdiğinizde, erişim belirteçlerinin yanı sıra kimlik belirteçleri oluşturulması için bir şablon sağlanır ve gerekli derleme ilkeleri otomatik olarak yaratılır. İlkeleri gereksinimlerinize göre özelleştirebilirsiniz. Örnek anahtar yalnızca test amaçlıdır ve JWT belirtecini imzalamak için kullanılır.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Belirteç içgözlemini etkinleştirdiğinizde erişim belirteci içgözlem yoluyla incelenebilir.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Belirteç içgözlemini etkinleştirdiğinizde erişim belirteçlerinin sahipleri bir iç gözlem yolu kullanarak belirteçlerin içeriğini inceleyebilir. İçgözlem yapılacak erişim belirteci, yerel OAuth sağlayıcısıyla alınmalıdır.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Bir kullanıcıyı sağlayıcı kuruluşun üyesi olarak davet ettiğinizde, bu kullanıcının bilgilere erişim düzeyini denetlemek için bir ya da daha fazla rol ve gerçekleştirebileceği görevleri de atayabilirsiniz.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Ürün sürümlerinizi yönetirken başlangıçta bir ürün sürümünü bir katalog için hazırlamadan ürün sürümünü uygulama geliştiricileriniz için kullanılabilir hale getirme ve sonunda kullanımdan kaldırma ve arşivlemeye kadar yaşam çevrimi durumlarında taşıyabilirsiniz.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Bir ürünü başka bir ürünle değiştirdiğinizde aşağıdaki işlemler gerçekleştirilir: 1) Yeni ürün yayınlanır, 2) Orijinal üründeki aynı görünürlük, abone ve ağ geçidi uygulama ayarları yeni üründe kullanılır, 3) Orijinal ürün aboneleri yeni ürüne taşınır.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Bir ürünü başka bir ürünle değiştirdiğinizde aşağıdaki işlemler gerçekleştirilir: 1) Yeni ürün orijinal ürünün durumunu alır, 2) Yeni üründeki görünürlük ve abone olabilirlik ayarları, erişimin orijinal ürünle aynı veya daha az kısıtlayıcı olacağı şekildeyse, yeni üründeki ayarlar kullanılır. Yeni üründeki ayarlar daha kısıtlayıcıysa ve orijinal ürüne erişimi olan bazı tüketici kuruluşlarının yeni ürüne erişmelerini engelleyecekse, değiştirme işlemi tamamlanamaz. 3) orijinal ürünün aboneleri yeni ürüne geçirilir ve (4) orijinal ürün kullanımdan kaldırılır.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Bir ürünü kullanımdan kaldırdığınızda, ilişkili tüm API'ler çevrimdışına alınır ve tüm abonelikler devre dışı olur.\n\n [Ek bilgi]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Bir ürünün yerine başkasını geçirdiğinizde aşağıdaki işlemler gerçekleştirilir: 1) Yeni ürün yayınlanır, 2) İlk üründeki aynı görünürlük, abone ve ağ geçidi uygulama ayarları yeni ürün için kullanılır, 3) İlk ürün 'Kullanım dışı bırakıldı' durumuna taşınır. Bir ürün kullanım dışı bırakıldığında ürüne abone olan uygulama geliştiricileri ürünü kullanmaya devam edebilir, ancak yeni geliştiriciler ürüne abone olamaz. Kullanım dışı bırakılan ürün gerekirse tekrar yayınlanabilir.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Bir ürünün yerine başka bir ürünü geçirdiğinizde aşağıdaki işlemler gerçekleştirilir: 1) Yeni ürün yayınlanır, 2) İlk üründeki görünürlük ve abonelik ayarları yeni üründe kullanılır ve 3) İlk ürün 'Kullanım dışı bırakıldı' durumuna taşınır.  Bir ürün kullanım dışı bırakıldığında ürüne abone olan uygulama geliştiricileri ürünü kullanmaya devam edebilir, ancak yeni geliştiriciler ürüne abone olamaz.  Gerekirse kullanım dışı bırakılan ürün yeniden yayınlanabilir.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Bir uygulamayı askıya aldığınızda, ilişkili istemci tanıtıcılarının API çağrıları yapması engellenir. Uygulamayı istediğiniz zaman devam ettirebilirsiniz.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Etkinleştirildiğinde, tüm tüketici kuruluşları davet zamanaşımı, katalog daveti zamanaşımı olarak ayarlanır ve katalog daveti zamanaşımındaki her güncelleme tüketici kuruluşları davet zamanaşımını günceller.`,
  [`Where did the roles go?`]: `Roller nereye gitti?`,
  [`Where to find the named rate limit.`]: `Belirtilen hız sınırının bulunacağı yer.`,
  [`Where to put the decrypted result`]: `Şifresi çözülen sonucun konulacağı yer`,
  [`Where to put the encrypted result`]: `Şifrelenen sonucun konulacağı yer`,
  [`Whether to reuse the connection to the target server.`]: `Hedef sunucuyla bağlantının yeniden kullanılıp kullanılmayacağı.`,
  [`Windows live`]: `Windows Live`,
  [`With`]: `Bununla değiştir`,
  [`Wildcards ignore xsi:type rule`]: `Genel arama karakterleri xsi:type kuralını yok sayar`,
  [`Work offline`]: `Çevrimdışı çalış`,
  [`Work with existing definitions, models and/or data sources`]: `Var olan tanımlar, modeller ve/veya veri kaynaklarıyla çalışın`,
  [`Working...`]: `Çalışıyor...`,
  [`Wrapper Policies`]: `Sarıcı İlkeleri`,
  [`Wrong slicing arguments`]: `Yanlış dilimleme bağımsız değişkenleri`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `İçe aktarmaya çalıştığınız zip dosyası içinde, WSDL ve XSD'ler kendi zip dosyası içinde olmalıdır.`,
  [`XML Schema`]: `XML Şeması`,
  [`XML Schema URL`]: `XML Şeması URL'si`,
  [`XML Schema preview`]: `XML Şeması önizlemesi`,
  [`XML Validation Mode`]: `XML Doğrulama Kipi`,
  [`XML schema is not valid. Please correct the error.`]: `XML şeması geçerli değil. Lütfen hatayı düzeltin.`,
  [`XML to JSON`]: `XML'den JSON'a`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT sürümü`,
  [`YAML has been successfully validated`]: `YAML başarıyla doğrulandı`,
  [`Yes`]: `Evet`,
  [`Yes, enable it`]: `Evet, etkinleştir`,
  [`Yes, reset`]: `Evet, sıfırla`,
  [`Yes. Update API Assembly`]: `Evet. API Derlemesini Güncelle`,
  [`You are authenticated!`]: `Kimliğiniz doğrulandı!`,
  [`You do not have permission to use the Test Application`]: `Test Uygulamasını kullanmak için izniniz yok`,
  [`You can also copy the link and send it directly to the user.`]: `Ayrıca, bağlantıyı kopyalayıp kullanıcıya doğrudan gönderebilirsiniz.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `API'yi şu anda yayınlamamayı seçebilir ya da test veya üretim uç noktalarınıza ya da her ikisine anında yayınlayabilirsiniz. İsteğe bağlı olarak, belirtilen zaman aralığında çağrı sayısına dayalı olarak izin verilen API çağrı hızı üst sınırını belirtebilirsiniz.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `API'yi şu anda yayınlamamayı seçebilir ya da test veya üretim uç noktalarınıza ya da her ikisine anında yayınlayabilirsiniz. İsteğe bağlı olarak, belirtilen zaman aralığında çağrı sayısına dayalı olarak izin verilen API çağrı hızı üst sınırını belirtebilirsiniz.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `API'yi şu anda güvenliği sağlanmamış bir şekilde bırakmayı seçebilir ya da bir çağıran uygulamanın istemci tanıtıcısı sağlamasını zorunlu tutabilirsiniz. API tanımını yarattıktan sonra güvenlik seçeneklerini daha ayrıntılı olarak yapılandırabilirsiniz.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `Katalog adı veya başlığı girebilirsiniz. Her ikisi de varsa katalog adı öncelikli olur.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `Bir tüketici kuruluş sahibine e-posta gönderebilirsiniz. İleti, Bildirimlerde yapılandırılan e-posta sunucusu kullanılarak gönderilir.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `Bir tüketici kuruluş sahibine e-posta gönderebilirsiniz. İleti, Bildirimler bölümünde yapılandırılan e-posta sunucusu kullanılarak gönderilir.`,
  [`You can send an email to a provider organization owner.`]: `Bir sağlayıcı kuruluş sahibine e-posta gönderebilirsiniz.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `E-posta adresini girerek sahipliği yeni bir kullanıcıya aktarabilirsiniz. Kullanıcı, kayıt için etkinleştirme bağlantısı içeren bir e-posta alır. Kayıt olduğunda kullanıcı, yeni sahip olur. Geçerli sahibe yeni rolleri atayın; böylece bu kullanıcı, Cloud Manager'da çalışmaya devam edebilir.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `E-posta adresini girerek kayıtlı bir kullanıcıya sahipliği aktarabilirsiniz. Geçerli sahibe yeni rolleri atayın; böylece bu kullanıcı, Cloud Manager'da çalışmaya devam edebilir.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `IBM API Connect'te bir REST API'si eklemek için OpenAPI tanım dosyası kullanabilirsiniz. Dosyanın biçimi JSON ya da YAML olabilir.  API tanımı içe aktarıldığında API tanımları listesinde gösterilir.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `Kök tipini ('Query') kaldıracağından bu tipi silemezsiniz.`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `Ağ geçidi tipi bu Ürünün ağ geçidi tipiyle eşleşmeyen bir API seçemezsiniz.`,
  [`You currently don’t have any gateway extensions`]: `Şu anda herhangi bir ağ geçidi uzantınız yok`,
  [`You currently don’t have any plans to migrate`]: `Henüz geçiş yapmak için bir planınız yok`,
  [`You currently don't have any policy.`]: `Şu anda herhangi bir ilkeniz yok.`,
  [`You do not have permission to any spaces.`]: `Alanlar için izniniz yok.`,
  [`You do not have permission to publish products to any catalog or space.`]: `Herhangi bir kataloğa ya da alana ürünleri yayınlama izniniz yok.`,
  [`You do not have permission to stage products to any catalog or space.`]: `Ürünleri bir katalog veya alan için hazırlama izniniz yok.`,
  [`You don’t currently have any billing integrations.`]: `Şu anda faturalama bütünleştirmeniz yok.`,
  [`You don't currently have any response.`]: `Şu anda herhangi bir yanıtınız yok.`,
  [`You don't currently have any user-defined policies.`]: `Kullanıcı tanımlı ilkeleriniz yok.`,
  [`You don't have permission to access this page.`]: `Bu sayfaya erişme izniniz yok.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Bu sertifikayı değiştirdiniz. Bu uygulamanın kaydedilmesi yapılandırmasını güncelleyecek.`,
  [`You haven't added any APIs or Products`]: `Henüz bir API ya da Ürün eklemediniz`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Kataloğa yeni kullanıcılar ekleyerek ve kullanıcılara roller atayarak katalog üyeliğini yönetebilirsiniz.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `Alana yeni kullanıcılar ekleyerek ve kullanıcılara roller atayarak alan üyeliğini yönetebilirsiniz.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Alana yeni kullanıcılar ekleyerek ve kullanıcılara roller atayarak alan üyeliğini yönetebilirsiniz.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `Yayınlanmaya hazır bir ürüne API'nizi eklemeniz gerekir. Bir ürün, geliştiricilerinizin kullanabileceği bir ürünle ilgili API ve planları toplar. Bir plan, plana bütün olarak uygulanabilen ya da bir API'deki her işlem için belirtilmiş olan hız sınırı ayarlarını içerir.`,
  [`You must choose a definition`]: `Bir tanımlama seçmeniz gerekir`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `En az bir ağ geçidi hizmetini etkinleştirmeniz gerekir, böylece bu Kataloğa yayınlanan API'ler bir ağ geçidi hizmeti uç noktasında çağrılabilir. Birden çok ağ geçidi hizmetini etkinleştirebilirsiniz.`,
  [`You must enable spaces`]: `Alanları etkinleştirmeniz gerekir`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Uygulamanızı geliştirirken kullanım için aşağıda istemci tanıtıcısı ve güvenlik dizgisini kaydetmeniz gerekir. İstemci güvenlik dizgisi yeniden görüntülenemez.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `API Connect Reserved Instance lisansınız size bir veya daha çok uzak DataPower API Gateway'i karşıdan yükleme ve kurma yetkisi verir. Ağ geçitleri şirket içinde veya herhangi bir bulutta bulunabilir.`,
  [`Your API has been created`]: `API'niz yaratıldı`,
  [`Your API is live!`]: `API'niz canlı!`,
  [`Your API is online!`]: `API'niz çevrimiçi!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Kataloğunuz siliniyor. Bu işlem biraz zaman alabilir...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Tüketici kuruluşunuz yaratılıyor. Bu işlem biraz zaman alabilir...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Davetiniz artık geçerli değil. Davet zamanaşımı ayarınızı denetleyin ve daveti yeniden gönderin.`,
  [`Your Provider organization is being created. This may take a while...`]: `Sağlayıcı kuruluşunuz yaratılıyor. Bu işlem biraz zaman alabilir...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Sağlayıcı kuruluşunuz siliniyor. Bu işlem biraz zaman alabilir...`,
  [`Your member is being deleted. This may take a while...`]: `Üyeniz siliniyor. Bu işlem biraz zaman alabilir...`,
  [`Your Space is being deleted. This may take a while...`]: `Alanınız siliniyor. Bu işlem biraz zaman alabilir...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `OAuth sağlayıcınız kaydedilmemiş değişiklikler içeriyor. Kaydetmeden devam etmek için Tamam düğmesini tıklatın.`,
  [`Your credentials have expired.`]: `Kimlik bilgilerinizin süresi doldu.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Davetiniz şu davet bağlantısıyla başarıyla gönderildi:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `İsteğiniz halen işleniyor ve beklenenden uzun sürüyor. Lütfen birkaç dakika sonra, isteği yeniden denemeden önce yenileyin.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Devam etmeden önce ürününüz kaydedilecek, emin misiniz?`,
  [`Your trial ends on {date}`]: `Deneme sürümünüz {date} tarihinde sona eriyor`,
  [`Your trial expired on {date}.`]: `Deneme süreniz {date} tarihinde sona erdi.`,
  [`Zoom in`]: `Yakınlaştır`,
  [`Zoom in and out`]: `Yakınlaştır ve uzaklaştır`,
  [`Zoom out`]: `Uzaklaştır`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `hedef sunucuda CORS desteğinin yok (aşağıdaki öneriye bakın)`,
  [`a LDAP provider`]: `LDAP sağlayıcısı`,
  [`activity`]: `etkinlik`,
  [`add item...`]: `öğe ekle...`,
  [`add property...`]: `özellik ekle...`,
  [`added`]: `eklendi`,
  [`admin`]: `yönetici`,
  [`administrator`]: `yönetici`,
  [`all`]: `tümü`,
  [`an API Connect Local User Registry`]: `API Connect Yerel Kullanıcı Kayıt Defteri`,
  [`an authentication URL`]: `kimlik doğrulama URL'si`,
  [`an untrusted certificate has been encountered`]: `güvenilir olmayan bir sertifikayla karşılaşıldı`,
  [`and`]: `ve`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Uygulama:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `onay`,
  [`approval task for product`]: `abonelik onayı görevi`,
  [`archived`]: `arşivlendi`,
  [`authenticated`]: `kimlik doğrulandı`,
  [`authorization code time to live (seconds)`]: `yetkilendirme kodu süresi (saniye)`,
  [`Authorization header pass through`]: `Yetkilendirme üstbilgisi geçişi`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `engelleme`,
  [`body`]: `gövde`,
  [`body-param`]: `body-param`,
  [`cancel`]: `iptal`,
  [`case`]: `durum`,
  [`catalog`]: `Katalog`,
  [`catalog:`]: `Katalog:`,
  [`catch`]: `yakalama`,
  [`changed`]: `değiştirildi`,
  [`cipher`]: `şifre`,
  [`code id_token`]: `kod id_token`,
  [`code id_token token`]: `kod id_token belirteci`,
  [`code token`]: `kod belirteci`,
  [`Consume`]: `Tüketme`,
  [`consumer`]: `tüketici`,
  [`consumes`]: `tüketir`,
  [`composite`]: `birleşik`,
  [`created`]: `yaratıldı`,
  [`Created API`]: `API yaratıldı`,
  [`custom string`]: `özel dize`,
  [`day`]: `gün`,
  [`day(s)`]: `gün`,
  [`default`]: `varsayılan`,
  [`default value`]: `varsayılan değer`,
  [`definition`]: `tanımlama`,
  [`deprecated`]: `kullanım dışı bırakıldı`,
  [`description`]: `açıklama`,
  [`development`]: `geliştirme`,
  [`edit condition`]: `koşulu düzenle`,
  [`edited`]: `düzenlendi`,
  [`environment`]: `Katalog`,
  [`error`]: `hata`,
  [`false`]: `false`,
  [`file`]: `dosya`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 simgeleri`,
  [`for`]: `şunun için`,
  [`header`]: `üstbilgi`,
  [`hear`]: `işitme`,
  [`hello`]: `Merhaba`,
  [`helloName`]: `Merhaba {name}!`,
  [`here`]: `burayı tıklatın`,
  [`hit`]: `isabet`,
  [`hits`]: `isabet`,
  [`hour`]: `saat`,
  [`hour(s)`]: `saat`,
  [`hours`]: `saat`,
  [`identified`]: `tanındı`,
  [`Increased`]: `Artırılmış`,
  [`Increment`]: `Artım`,
  [`info`]: `bilgi`,
  [`information about external documentation`]: `dış belgeler hakkında bilgi`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `OIDC sağlayıcısından exp iddiasını dikkate almak yerine APIc tarafından oluşturulan access_token/refresh_token, APIC ayarını dikkate alır`,
  [`introspect URL`]: `iç gözlem URL'si`,
  [`Issue`]: `Sorun`,
  [`Issues`]: `Sorunlar`,
  [`item`]: `öğe`,
  [`iterate over`]: `yineleme`,
  [`last modified`]: `son değiştirme tarihi`,
  [`Log Level`]: `Günlük Kaydı Düzeyi`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `günlükler`,
  [`Logout`]: `Oturum Kapat`,
  [`Logout redirect`]: `Oturum kapatma yönlendirmesi`,
  [`Logout redirect URL`]: `Oturum kapatma yönlendirme URL'si`,
  [`location`]: `konum`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `dakika`,
  [`minute(s)`]: `dakika`,
  [`minutes`]: `dakika`,
  [`mutation type`]: `mutasyon tipi`,
  [`mutual SSL authentication is required`]: `ortak SSL kimlik doğrulaması gerekli`,
  [`name`]: `Ad`,
  [`Name|extension`]: `Ad`,
  [`Name|path parameters`]: `Ad`,
  [`Name|security definitions`]: `Ad`,
  [`non-blocking`]: `engelleme dışı`,
  [`none`]: `yok`,
  [`none selected`]: `hiçbiri seçilmedi`,
  [`Object`]: `Nesne`,
  [`off`]: `kapalı`,
  [`of {total} pages`]: `/{total} sayfa`,
  [`of {total} page`]: `/ {total} sayfa`,
  [`offline`]: `çevrimdışı`,
  [`on`]: `açık`,
  [`online`]: `çevrimiçi`,
  [`or`]: `ya da`,
  [`or redact from all`]: `ya da tümünü düzelt`,
  [`open and close list of options`]: `seçenek listesini aç ve kapat`,
  [`organization saved`]: `kuruluş kaydedildi`,
  [`organization-manager`]: `organization-manager`,
  [`otherwise`]: `tersi durumda`,
  [`payload`]: `bilgi yükü`,
  [`Payload`]: `Bilgi yükü`,
  [`pending`]: `beklemede`,
  [`produces`]: `üretir`,
  [`production`]: `üretim`,
  [`production and development`]: `üretim ve geliştirme`,
  [`property`]: `özellik`,
  [`provider`]: `sağlayıcı`,
  [`publish product`]: `ürünü yayınla`,
  [`published`]: `yayınlandı`,
  [`query type`]: `sorgu tipi`,
  [`query-parameters`]: `sorgu parametreleri`,
  [`realized`]: `tanındı`,
  [`recipients`]: `alıcılar`,
  [`removed`]: `kaldırıldı`,
  [`replaced`]: `değiştirildi`,
  [`repeatable`]: `yinelenebilir`,
  [`Replenish`]: `Takviye`,
  [`request`]: `istek`,
  [`requested by`]: `isteyen`,
  [`Resolver weight`]: `Çözümleyici ağırlığı`,
  [`response`]: `yanıt`,
  [`response-param`]: `response-param`,
  [`retired`]: `kullanımdan kaldırıldı`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `kaydedildi`,
  [`scalar`]: `sayıl`,
  [`second`]: `saniye`,
  [`second(s)`]: `saniye`,
  [`seconds`]: `saniye`,
  [`select owner user`]: `sahip kullanıcı seç`,
  [`select user`]: `kullanıcı seç`,
  [`soap-body`]: `soap-body`,
  [`source`]: `kaynak`,
  [`space`]: `alan`,
  [`Space settings`]: `Alan ayarları`,
  [`Skip`]: `Atla`,
  [`specified`]: `belirtilen`,
  [`staged`]: `hazırlandı`,
  [`start generating tests automatically.`]: `Testleri oluşturmayı otomatik olarak başlat`,
  [`subject`]: `konu`,
  [`subscription type`]: `abonelik tipi`,
  [`supersede`]: `şu ürünün yerine`,
  [`superseded`]: `yerine başkası geçirildi`,
  [`target`]: `hedef`,
  [`the server is unavailable`]: `sunucu kullanılamıyor`,
  [`this user registry type`]: `bu kullanıcı kayıt defteri tipi`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Bu, yeniden yayınlamanın abonelikleri koruduğu geçici bir yayınlama sayfasıdır.`,
  [`to production`]: `uygulama onayı görevi`,
  [`topology-administrator`]: `topoloji-yönetici`,
  [`Trial days left`]: `Deneme için kalan gün`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `ttl değiştirildi`,
  [`type`]: `tip`,
  [`unarchived`]: `arşivi kaldırıldı`,
  [`union type`]: `birleşim tipi`,
  [`unlimited`]: `sınırsız`,
  [`updated`]: `güncellendi`,
  [`Updated from new schema`]: `Yeni şemadan güncellendi`,
  [`Upgrade`]: `Yükselt`,
  [`v5 legacy behavior`]: `v5 eski davranışı`,
  [`version 2`]: `sürüm 2`,
  [`version 3`]: `sürüm 3`,
  [`viewer`]: `görüntüleyici`,
  [`value`]: `değer`,
  [`warn`]: `uyarı`,
  [`warning`]: `uyarı`,
  [`warnings`]: `uyarı`,
  [`was not found. Please select a configured OAuth provider.`]: `bulunamadı. Lütfen yapılandırılmış bir OAuth sağlayıcısı seçin.`,
  [`week`]: `hafta`,
  [`with`]: `şu ürünü geçir`,
  [`with|supersede with`]: `şu ürünü geçir`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `kum havuzundaki durum`,
  [`your file here, or`]: `Dosyanızı buraya sürükleyip bırakın ya da`,
  [`{count} found!`]: `{count} bulundu!`,
  [`{current} of {total} page`]: `{current} / {total} sayfa`,
  [`{current} of {total} pages`]: `{current} / {total} sayfa`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} gün, {hours} saat ve {minutes} dakika`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} gün ve {hours} saat`,
  [`{docType} is required`]: `{docType} gerekli`,
  [`{docType} title`]: `{docType} başlığı`,
  [`Product title`]: `Ürün başlığı`,
  [`Application title`]: `Uygulama başlığı`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} saat ve {minutes} dakika`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API veya üründe belirtilen ağ geçidi tipiyle uyumsuz nedeniyle {incompatibleGatewayCount} ağ geçitleri gizlendi`,
  [`{min}-{max} of {total} items`]: `{min}-{max} / {total} öğe`,
  [`{min}-{max} of {total} item`]: `{min}-{max} / {total} öğe`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} / {total} İlke`,
  [`{min}-{max} of {total} Policy`]: `{min}-{max} / {total} İlke`,
  [`{min}-{max} of {total} types`]: `{min}-{max} / {total} tip`,
  [`{min}-{max} of {total} type`]: `{min}-{max} / {total} tip`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} / {total} arama sonucu`,
  [`{min}-{max} of {total} search result`]: `{min}-{max} / {total} arama sonucu`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} / {total} Katalog`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{max} / {total} Katalog`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} / {total} Alan`,
  [`{min}-{max} of {total} Space`]: `{min}-{max} / {total} Alan`,
  [`{min}-{max} of {total} Extensions`]: `{min} - {max} / {total} Uzantı`,
  [`{min}-{max} of {total} Extension`]: `{min}-{max} / {total} Uzantı`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} / {total} Ürün Planı`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{max} / {total} Ürün Planı`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} / {total} Ağ Geçidi`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{max} / {total} Ağ Geçidi`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (API) 'x-ibm-configuration' içermiyor`,
  [`{name} (Product) has been published!`]: `{name} (Ürün) yayınlandı!`,
  [`{name} (Product) has been staged!`]: `{name} (Ürün) hazırlandı!`,
  [`{name} (Product) has not been published!`]: `{name} (Ürün) yayınlanmadı!`,
  [`{name} (Role) has been created`]: `{name} (Rol) yaratıldı.`,
  [`{name} successfully deleted`]: `{name} başarıyla silindi.`,
  [`{name} (Role) has been updated`]: `{name} (Rol) güncellendi.`,
  [`{name} has been assigned the owner`]: `{name}, sahibe atandı`,
  [`{number} Error`]: `{number} Hata`,
  [`{number} Errors`]: `{number} Hata`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{username} tarafından {prodname}:{version} ürünü için {operation} onay görevi istendi`,
  [`{path} does not exist`]: `{path} yok`,
  [`{productName} has no migration target set.`]: `{productName} için geçiş hedefi ayarlanmadı.`,
  [`{state} Pending`]: `{state} Beklemede`,
  [`{status} API error`]: `{status} API hatası`,
  [`{status} Bad Request`]: `{status} Geçersiz İstek`,
  [`{status} Forbidden`]: `{status} Engellendi`,
  [`{status} Gateway Error`]: `{status} Ağ Geçidi Hatası`,
  [`{status} Unauthorized`]: `{status} Yetkisiz`,
  [`({time} after being sent)`]: `(gönderildikten {time} sonra)`,
  [`({time} after being queued)`]: `(kuyruğa alındıktan {time} sonra)`,
  [`{title} (Product) has been {state}.`]: `{title} (Ürün) {state}.`,
  [`{title} has been deleted`]: `{title} silindi`,
  [`{title} has been {changedName}.`]: `{title}, {changedName} oldu.`,
  [`{title} has not been deleted!`]: `{title} silinmedi!`,
  [`{title} has not been {changedName}!`]: `{title}, {changedName} olmadı!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type}, {changedName} oldu.`,
  [`{type} has not been {changedName}!`]: `{type}, {changedName} olmadı!`,
  [`{type} history log`]: `{type} geçmiş günlüğü`,
  [`{username} does not exist`]: `{username} yok`,
  [`{username} does not exist in the user registry`]: `{username} kullanıcı kaydında yok`,
  [`{username} is not associated with this consumer org`]: `{username} bu tüketici kuruluşuyla ilişkilendirilmedi`,
  [`{username} has been created as a {type}.`]: `{username} bir {type} olarak yaratıldı.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `{consumerOrg} geliştirici portalında {appName} uygulaması geri getirildi`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `{consumerOrg} geliştirici portalında {appName} uygulaması askıya alındı`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} geliştirici portalı hesap kaydı`,
  [`{{count}} errors need attention`]: `{count} hataya dikkat edilmesi gerekiyor`,
  // lts only translations
  [`Configure JSON settings`]: `JSON ayarlarını yapılandır`,
  [`Configure XML settings`]: `XML ayarlarını yapılandır`,
  [`Configure options`]: `Seçenekleri yapılandır`,
  [`Detect`]: `Sapta`,
  [`Document type`]: `Belge tipi`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Belge boyutu üst sınırı`,
  [`Maximum name length`]: `Ad uzunluğu üst sınırı`,
  [`Maximum nesting depth`]: `İç içe yerleştirme derinliği üst sınırı`,
  [`Maximum number length`]: `Sayı uzunluğu üst sınırı`,
  [`Maximum number of unique names`]: `Benzersiz ad sayısı üst sınırı`,
  [`Maximum number of unique namespaces`]: `Benzersiz ad alanı sayısı üst sınırı`,
  [`Maximum number of unique prefixes`]: `Benzersiz önek sayısı üst sınırı`,
  [`Maximum value length`]: `Değer uzunluğu üst sınırı`,
  [`Maximum width`]: `Genişlik üst sınırı`,
  [`Maximum stack size`]: `Maksimum yığın boyutu`,
  [`Parse settings`]: `Ayrıştırma ayarları`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Ayrıştırma eyleminin kabul ettiği belge boyutu üst sınırını (bayt cinsinden) belirtir.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Ayrıştırma eyleminin kabul ettiği bir XML veya JSON iletisinde iç içe geçmiş öğe derinliği düzeyi üst sınırını belirtir.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Ayrıştırma eyleminin kabul ettiği bir XML, JSON veya bir GraphQL iletisinde iç içe geçmiş öğe derinliği düzeyi üst sınırını belirtir.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Ayrıştırma eyleminin kabul ettiği ad uzunluğu boyutunu (bayt cinsinden) belirtir.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Maksimum sayı uzunluğunun değer bir sayı olduğunda etiket-değer çiftinin değer kısmında bayt sayısını sınırladığını belirtir.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Ayrıştırma eyleminin kabul ettiği benzersiz XML ad alanı URI sayısı üst sınırını belirtir. Bu sınır açıklama için kullanılan önek sayısından bağımsız olarak tüm XML ad alanlarını sayar.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Ayrıştırma eyleminin kabul ettiği benzersiz XML ad alanı öneki sayısı üst sınırını belirtir. Bu sınır aynı ad alanı için tanımlanan birden çok önek sayar, ancak tek bir önek altında giriş belgesinin farklı kısımlarında tanımlanan birden çok ad alanını saymaz.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Ayrıştırma eyleminin kabul ettiği benzer ad sayısı üst sınırını belirtir.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Ayrıştırma eyleminin kabul ettiği değer uzunluğu üst sınırını (bayt cinsinden) belirtir.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Ayrıştırma işleminin kabul ettiği bilgi yükü genişliği üst sınırını belirtir.`,
  [`Specifies the type of document to parse.`]: `Ayrıştırılacak belge tipini belirtir.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `XSLT işlemci sürümünü belirtir. Varsayılan değer XSLT10'dur.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Katı XSLT hata denetiminin etkinleştirilip etkinleştirilmeyeceğini belirtir. Katı olmayan işlemler, bildirilmeyen değişkenlerin kullanımı, bildirilmeyen şablonların çağrılması gibi bazı hatalardan kurtarmaya çalışır. Varsayılan olarak katı XSLT hata denetimi etkindir.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Stil sayfası profili oluşturmasının etkinleştirilip etkinleştirilmeyeceğini belirtir. Bu seçenek, üretim ortamlarında kullanılmamalıdır. Varsayılan olarak stil sayfası profil oluşturma devre dışıdır.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Hata ayıklama kipinde stil sayfası, XQuery komut dosyası ve JSONiq komut dosyasının çalıştırılıp çalıştırılmayacağını belirtir. Bir stil sayfası, XQuery komut dosyası veya JSONiq komut dosyası hata ayıklama kipinde çalışırken olağan çıkışı görüntülemek yerine özel bir web sayfası oluşturur. Web sayfası değişkenlerin değerleri ve çıkışın belirli bölümlerinin nereden geldiği dahil yürütme sırasında tam olarak ne olduğunu ayrıntılandırır. Bu seçenek, üretim ortamlarında kullanılmamalıdır. Varsayılan olarak hata ayıklama kipi devre dışıdır.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Stil sayfasının akış kipinde çalıştırılması gerekip gerekmediğini belirtir. Belgenin dönüşümü giriş tam olarak ayrıştırıldığında başlar. Tüm stil sayfaları akışı gerçekleştirilemez. Stil sayfası akışı yapılamıyorsa bir hata oluşturulur ve giriş işlenmez. Varsayılan olarak akış kipi devre dışıdır.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Akış kipinde stil sayfasının çalıştırılmasının denenip denemeyeceğini belirtir. Belgenin dönüşümü giriş tam olarak ayrıştırıldığında başlar. Tüm stil sayfaları akışı gerçekleştirilemez. Stil sayfası akışı gerçekleştirilemiyorsa derleme sırasında bir uyarı oluşturulur ve stil sayfası yürütme zamanında tüm girişte olağan olarak okunur. Varsayılan olarak akış kipinde stil sayfasını çalıştırma denemesi devre dışıdır.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `İşleme sırasında stil sayfasından üretilen çıkıştan çıkılıp çıkılmayacağını belirtir. İngilizce dışındaki karakter kümelerini işleme alırken minimum çıkış özellikle yararlıdır. Varsayılan olarak minimum çıkış devre dışıdır.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Bir stil sayfası veya diğer derlenmiş içeriği yürütürken yığının kullanmasına izin verilen maksimum bayt sayısını belirtir. Bu ayar, sınırsız yinelemeyi engellemek için kullanılır. Minimum değer 10 kilobayt veya 10.240 bayttır. Maksimum değer 100 megabayt veya 104.857.600 baytttır. Varsayılan değer 1 megabayt veya 1.048.576 bayttır.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `WS-I Basic Profile bölüm 5 (sürüm 1.0, Nisan 2004) ile uyum için denetlenen WSDL dosyalarına uygulanacak doğrulama davranışını belirtir. Varsayılan ayar, Warn ayarıdır.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `soap:Body için doğrulama davranışını belirtir. Varsayılan ayar, Strict ayarıdır.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `soap:Header için doğrulama davranışını belirtir. Varsayılan ayar, Lax ayarıdır.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Arıza ayrıntısı için doğrulama davranışını belirtir. Varsayılan ayar, Strict ayarıdır.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `RPC stili sarıcılarla uyumluluğu gerektirip gerektirmeyeceğini belirtir. Varsayılan olarak RPC stili sarıcılar gerekli değildir.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Bu öznitelikler XML Schema belirtimini ihlal ettiğinde bile şemanın SOAP 1.1 Bölüm 5 ile tutarlı xsi:type='SOAP-ENC:Array' içeren öğelerin çoğu kullanımını kabul etmesini sağlayıp sağlamayacağını belirtir. Normalde xsi:type özniteliği, gerçek öğe türüne eşit veya bundan türetilen bir türü adlandırmalıdır. Bu seçenekle derlenen şemalar için xsi:type, öğe türü SOAP-ENC:Array kaynağından türetilirse  SOAP 1.1 Encoding 'Array' karmaşık türü için özellikle kabul edilir. Tersi, normal izin verilebilir durumdur. Varsayılan olarak xsi:type='SOAP-ENC:Array' içeren öğeler kabul edilmez.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `SOAP 1.1 Bölüm 5'te kodlama kurallarından sonra ekstra şema doğrulamasının gerçekleştirilip gerçekleştirilmeyeceğini belirtir. Etkinleştirildiğinde SOAP dizileri üyeleri doğrulanır, şema tarafından izin verilmese bile @id ve @href gibi özniteliklere izin verilir ve @href değerleri ilgili bir @id öğesine sahip olduklarından emin olmak için denetlenir. Varsayılan olarak ekstra doğrulama gerçekleştirilmez.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Şemadaki xs:any öğelerinin yalnızca ada göre alt öğeleri doğrulayıp doğrulamadığını belirtir. XML Şema belirtimi bir genel arama karakteri bir öğeyle eşleşirse, ancak bu öğenin bir öğe bildirimi yoksa xsi:type özniteliğine göre öğenin doğrulanmasını gerektirir. Bu seçenek, xsi:type özniteliklerini yoksayar. SOAP 1.1 kodlama kuralları kullanılarak daha fazla bir doğrulama adımının genel arama karakteriyle eşleşen içeriği doğrulayacağı SOAP zarf doğrulaması gibi durumlar için kullanılmalıdır. Varsayılan olarak xsi:type öznitelikleri yoksayılır.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `WSDL'de SOAP bağ tanımının katı bir biçimde izlenip izlenmeyeceğini belirtir. Etkinleştirildiğinde, yalnızca SOAP 1.2 ile bağlı iletiler SOAP 1.2 zarflarında görünür ve yalnızca SOAP 1.1 ile bağlı iletiler SOAP 1.1 zarflarında görünür. Varsayılan olarak katı SOAP bağ tanımı devre dışıdır.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Hata ayıklama bilgileriyle XACML ilkelerinin derlenip derlenmeyeceğini belirtir. XACML hata ayıklama iletilerinin de XACML kategorisindeki günlük olayıyla denetlendiğini göz önünde bulundurun. Tüm XACML hata ayıklama iletilerini görüntülemek için hata ayıklama günlük düzeyini kullanın. Varsayılan olarak XACML ilkeleri, hata ayıklama bilgileriyle derlenmez.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `base-64 kodlu ikili içeriğin MTOM/XOP belirtimlerine göre optimize edildiği iletileri şema veya WSDL belgesinin kabul edip etmediğini belirtir. XOP ikili optimizasyonu, base-64 kodlu ikili verileri bir ekte bulunan kodlanmamış ikili verilere başvuran bir xop:Include referans öğesiyle değiştirir. Varsayılan olarak MTOM/XOP optimize edilmiş iletileri devre dışıdır.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Tüketici kuruluş grubu, Tüketici kuruluşlarında oluşur ve Ürünlerinizde API'leri kimlerin görebileceğini ve bunlara kimlerin abone olabileceğini denetlemeniz için etkili bir yol sağlar. Bir tüketici kuruluş grubu kullanarak, kuruluşlara erişimi tek tek tanımlamak yerine, bu grupta bulunan tüm geliştiriciler için bu erişimi tek bir işlem içinde tanımlayabilirsiniz.`,
  [`API Connect Home`]: `API Connect Ana Sayfası`,
  [`Add new group`]: `Yeni grup ekle`,
  [`Add consumer organizations to the group as required`]: `Tüketici kuruluşlarını gerektiği gibi gruba ekleyin`,
  [`Add the following configuration`]: `Aşağıdaki yapılandırmayı ekle`,
  [`Add the following configuration to`]: `Aşağıdaki yapılandırmayı şuna ekle`,
  [`Additions`]: `Eklemeler`,
  [`Additional GraphQL capabilities`]: `Ek GraphQL yetenekleri`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Bu plandaki graphQL API'leri için ek ayarlar`,
  [`An organization or a group is required.`]: `Bir kuruluş ya da grup gerekli.`,
  [`An organization/group is required.`]: `Bir kuruluş/grup gerekli.`,
  [`Analysis configuration`]: `Analiz yapılandırması`,
  [`Application & Credentials`]: `Uygulama ve Kimlik Bilgileri`,
  [`Are you sure you want to delete this API version?`]: `Bu API sürümünü silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to delete this API with all versions?`]: `Tüm sürümlerle bu API'yi silmek istediğinizden emin misiniz?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Ağ geçidinden ağ geçidi uzantısını kaldırmak istediğinizden emin misiniz?`,
  [`Are you sure you want to rename all versions of this API?`]: `Bu API'nin tüm sürümlerini yeniden adlandırmak istediğinizden emin misiniz?`,
  [`Are you sure you want to rename this API version?`]: `Bu API sürümünü yeniden adlandırmak istediğinizden emin misiniz?`,
  [`Associate groups to the organization as required`]: `Grupları gerektiği gibi kuruluşla ilişkilendirin`,
  [`At least one option must be enabled`]: `En az bir seçenek etkinleştirilmelidir`,
  [`Attribute mapping`]: `Öznitelik eşlemesi`,
  [`Attempt streaming rule`]: `Akış kuralını dene`,
  [`Auto generated default paths and operations`]: `Otomatik oluşturulan varsayılan yollar ve işlemler`,
  [`Base API endpoint`]: `Temel API uç noktası`,
  [`Consumer orgs`]: `Tüketici kuruluşları`,
  [`Calculate Query Characteristics`]: `Sorgu Özelliklerini Hesapla`,
  [`Calculate Response Characteristics`]: `Yanıt Özelliklerini Hesapla`,
  [`Certificate or Shared Secret for Verify`]: `Doğrulama için Sertifika ya da Paylaşılan Güvenlik Dizgisi`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Bir haftaya kadar API yönetimi yeteneklerinin tümünü deneme isteğinde bulunabileceğiniz uygulamalı laboratuvar çalışmalarımıza göz atın!`,
  [`Clone API`]: `Klon API'si`,
  [`Consumer organizations and groups`]: `Tüketici kuruluşlar ve gruplar`,
  [`Create new API version`]: `Yeni API sürümü yarat`,
  [`Create new product version`]: `Yeni ürün sürümü yarat`,
  [`Create a GraphQL API based on a GraphQL service`]: `GraphQL hizmetine dayalı bir GraphQL API'si yaratın`,
  [`Custom rate limit`]: `Özel hız sınırı`,
  [`Delete API`]: `API'yi Sil`,
  [`Delete this version`]: `Bu sürümü sil`,
  [`Deletion confirmation`]: `Silme onayı`,
  [`Deletions`]: `Silme işlemleri`,
  [`Display name`]: `Görünen ad`,
  [`Display Name`]: `Görünen Ad`,
  [`Details`]: `Ayrıntılar`,
  [`Edit GraphQL Rate Limit|title`]: `GraphQL Hız Sınırını düzenle|başlık`,
  [`Edit GraphQL rate limits`]: `GraphQL hız sınırlarını düzenle`,
  [`Edit group`]: `Grubu düzenle`,
  [`Edit condition`]: `Koşulu düzenle`,
  [`Edit rate limit|title`]: `Hız sınırını düzenle`,
  [`Edit rate limit|menu`]: `Hız sınırını düzenle`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Bu Kataloğa yayınlanmış Ürün yok veya bu uygulama zaten tüm Ürünlerdeki tüm Planlara abone.`,
  [`Enforce Policy`]: `İlkeyi Zorunlu Kıl`,
  [`Enter a group summary`]: `Bir grup özeti girin`,
  [`Enter a group title`]: `Bir grup başlığı girin`,
  [`Enter group details below`]: `Aşağıda grup ayrıntılarını girin`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Yetkili sunucu eklemek istediğiniz GraphQL hizmeti için URL ve şema adını girin`,
  [`Enum value`]: `Sıralama değeri`,
  [`Go to catalog settings to configure a new billing integration`]: `Yeni bir faturalama entegrasyonu yapılandırmak için katalog ayarlarına gidin`,
  [`GraphQL introspect`]: `GraphQL iç gözlemi`,
  [`GraphQL server`]: `GraphQL sunucusu`,
  [`GraphQL Setting`]: `GraphQL Ayarı`,
  [`GraphQL server URL`]: `GraphQL sunucusu URL'si`,
  [`GraphQL server name`]: `GraphQL sunucusu adı`,
  [`GraphQL type analysis configuration`]: `GraphQL tipi analiz yapılandırması`,
  [`GraphQL resolver analysis configuration`]: `GraphQL çözücü analiz yapılandırması`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Gruplar aşağıdaki listede yaratılabilir ve yönetilebilir.`,
  [`Groups will be listed here.`]: `Gruplar burada listelenecektir.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Merhaba {firstName},\n\n{catalog} kataloğundaki {productName}:{version} API ürünü için {action}\nisteğiniz onaylandı.\n\n{#if comments}\nOnaylayan aşağıdaki yorumları ekledi:\n\n{comments}\n\n{{/if}}\nÜrünün durumunu buradan kontrol edebilirsiniz: \n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Merhaba,\n\nBu, yapılandırılan\n{mailServer} posta sunucusundaki IBM API Connect'ten bir test iletisidir.\n\nBu iletiyi beklendiği gibi aldıysanız test başarılıdır!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Merhaba {firstName},\n\n{originator} adlı kullanıcı, {appName} uygulamasıyla {{catalog}} kataloğundaki {productName} API ürününün {productVersion} sürümü için {planName} planına\nabone olma isteğini geri çekti.\n\nBaşka işlem gerekmiyor.\n`,
  [`Invoke GraphQL server`]: `GraphQL sunucusunu çağır`,
  [`Invoke the backend GraphQL server`]: `Arka uç GraphQL sunucusunu çağır`,
  [`JWKS endpoint`]: `JWKS uç noktası`,
  [`LDAP Field Name`]: `LDAP Alan Adı`,
  [`Limit maximum nesting per query`]: `Sorgu başına maksimum iç içe geçmeyi sınırla`,
  [`Limit resolve complexity rate on a per key basis`]: `Çözüm karmaşıklığı oranını anahtar başına sınırla`,
  [`Limit type complexity rate on a per key basis`]: `Tip karmaşıklığı oranını anahtar başına sınırla`,
  [`Manage APIs included in this product`]: `Bu ürüne eklenen API'leri yönetin`,
  [`Manage groups`]: `Grupları yönet`,
  [`Maximum nesting`]: `Maksimum iç içe yerleştirme`,
  [`Maximum resolve complexity`]: `Maksimum çözüm karmaşıklığı`,
  [`Maximum type complexity`]: `Maksimum tip karmaşıklığı`,
  [`Name of the group`]: `Grubun adı`,
  [`New API version`]: `Yeni API sürümü`,
  [`New GraphQL API`]: `Yeni GraphQL API'si`,
  [`New product version`]: `Yeni ürün sürümü`,
  [`New Version`]: `Yeni Sürüm`,
  [`New version`]: `Yeni sürüm`,
  [`No billing integration exists`]: `Faturalama bütünleştirmesi yok`,
  [`No CA bundles available`]: `CA paketi yok`,
  [`No Data`]: `Veri Yok`,
  [`No certificates available`]: `Sertifika yok`,
  [`No data`]: `Veri yok`,
  [`No group found`]: `Grup bulunamadı`,
  [`OAuth Auth URL`]: `OAuth Auth URL'si`,
  [`OAuth Token URL`]: `OAuth Token URL'si`,
  [`Open Stripe dashboard`]: `Stripe gösterge panosunu aç`,
  [`Operation/Path`]: `İşlem/Yol`,
  [`Operation Path`]: `İşlem Yolu`,
  [`Operations and Paths`]: `İşlemler ve Yollar`,
  [`Operator`]: `İşleç`,
  [`Organizations and groups will be listed here.`]: `Kuruluşlar ve gruplar burada listelenecektir.`,
  [`Page not found`]: `Sayfa bulunamadı`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Lütfen işleme devam etmek için aşağıdaki kodu CLI'ya kopyalayın. Kodun süresi şu tarihte dolacak: **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Lütfen API anahtarına ve güvenlik dizgisine erişmek için Stripe gösterge panonuza başvurun`,
  [`Please upload a schema definition language (SDL) file.`]: `Lütfen bir şema tanımlama dili (SDL) dosyasını karşıya yükleyin.`,
  [`Please upload a valid GraphQL schema.`]: `Lütfen geçerli bir GraphQL şeması karşıya yükleyin.`,
  [`Please upload a valid YAML file.`]: `Lütfen geçerli bir YAML dosyasını karşıya yükleyin.`,
  [`Populate Server Configuration into Context Variables`]: `Sunucu Yapılandırmasını Bağlam Değişkenlerine Doldur`,
  [`POST/GET a query to be validated and sent to the backend server`]: `Doğrulanacak ve arka uç sunucusuna gönderilen bir sorgu için POST/GET işlemi gerçekleştir`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `Sorguyu çağırmanın tahmini maliyetini almak için bir sorgu için POST/GET işlemi gerçekleştir`,
  [`Preparing`]: `Hazırlanıyor`,
  [`Rate limit amount cannot be negative`]: `Hız sınırı tutarı eksi olamaz`,
  [`Rate limit per cannot be negative`]: `Hız sınırı eksi olamaz`,
  [`Rename API`]: `API'yi yeniden adlandır`,
  [`Rename`]: `Yeniden adlandır`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `{catalog} kataloğundaki bir API ürünü için {action} onayı isteği`,
  [`Sandbox Sample Application`]: `Kum Havuzu Örnek Uygulaması`,
  [`Sandbox sample application credentials`]: `Kum havuzu örnek uygulama kimlik bilgileri`,
  [`sample application credentials`]: `örnek uygulama kimlik bilgileri`,
  [`Saving`]: `Kaydediliyor`,
  [`Search Orgs`]: `Kuruluşları Ara`,
  [`Search definitions...`]: `Tanımları ara...`,
  [`Search errors...`]: `Hataları ara...`,
  [`Search operations...`]: `İşlemleri ara...`,
  [`Search organizations and groups`]: `Kuruluşları ve grupları ara`,
  [`Sorry, This feature is not available in`]: `Üzgünüz, bu özellik şu anda burada mevcut değil:`,
  [`Stopping`]: `Durduruluyor`,
  [`The user with username {username} already exists in the user registry.`]: `Kullanıcı kayıt defterinde {username} kullanıcı adına sahip kullanıcı zaten var.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Çalışmayan API değişiklikleri var. Bu API'yi Kum Havuzu kataloğunda yeniden yayınlayın.`,
  [`There are no plans available for subscription.`]: `Abonelik için kullanılabilir plan yok.`,
  [`There are no attributes yet.`]: `Henüz bir öznitelik yok.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Gruba tüketici kuruluşları eklemek için yazın`,
  [`Type to add groups to the organization`]: `Kuruluşa grup eklemek için yazın`,
  [`Type to add organizations from {catalog}`]: `{catalog} kataloğundan kuruluş eklemek için yazın`,
  [`Type to add organizations or groups`]: `Kuruluş veya grup eklemek için yazın`,
  [`Type to add organizations or groups from {catalog}`]: `{catalog} kataloğundan kuruluş ya da grup eklemek için yazın`,
  [`Type to add {title} to groups`]: `Gruplara {title} eklemek  için yazın`,
  [`Update Rate Limits`]: `Hız Sınırlarını Güncelle`,
  [`Upload the YAML file with configuration`]: `Yapılandırmayla YAML dosyasını karşıya yükle`,
  [`Use the sample below as a template to configure your GraphQL server`]: `GraphQL sunucunuzu yapılandırmak için şablon olarak aşağıdaki örneği kullan`,
  [`User managed`]: `Kullanıcı tarafından yönetilen`,
  [`URL {url} does not contain valid OpenAPI.`]: `URL {url} geçerli OpenAPI içermiyor.`,
  [`Validating`]: `Doğrulanıyor`,
  [`View analytics and performance metrics for your APIs`]: `API'lerinizin analitik ve performans ölçümlerini görüntüleyin`,
  [`View and add custom policies to the catalog`]: `Özel ilkeleri görüntüleyin ve kataloğa ekleyin`,
  [`View and add custom exentions to the catalog`]: `Özel uzantıları görüntüleyin ve kataloğa ekleyin`,
  [`View user registries and OAuth providers`]: `Kullanıcı kayıt defterleri ve OAuth sağlayıcılarını görüntüleyin`,
  [`View your application and manage API credentials`]: `Uygulamanızı görüntüleyin ve API kimlik bilgilerini yönetin`,
  [`Welcome to the API Connect Trial`]: `API Connect Deneme sürümüne hoş geldiniz`,
  [`You haven't added any APIs`]: `API eklemediniz`,
  [`You currently don’t have any {resource}.`]: `Şu anda herhangi bir {resource} yok.`,
  [`plans to migrate`]: `geçiş planları`,
  [`gateway extensions`]: `ağ geçidi uzantıları`,
  [`application`]: `uygulama`,
  [`application authentication source`]: `uygulama kimlik doğrulama kaynağı`,
  [`API or product`]: `API ya da ürün`,
  [`base endpoints`]: `temel uç noktalar`,
  [`categories`]: `kategoriler`,
  [`certificates`]: `sertifikalar`,
  [`channels`]: `kanallar`,
  [`consumer organizations`]: `tüketici kuruluşları`,
  [`consumer organization group`]: `tüketici kuruluş grubu`,
  [`consumer organizations or groups`]: `tüketici kuruluşları ya da grupları`,
  [`correlation ids`]: `korelasyon tanıtıcıları`,
  [`cors policy`]: `cors ilkesi`,
  [`definitions`]: `tanımlar`,
  [`examples`]: `örnekler`,
  [`gateway services`]: `ağ geçidi hizmetleri`,
  [`groups`]: `gruplar`,
  [`headers`]: `üstbilgiler`,
  [`links`]: `bağlantılar`,
  [`member`]: `üye`,
  [`messages`]: `ileti`,
  [`message traits`]: `ileti özellikleri`,
  [`operation traits`]: `işlem özellikleri`,
  [`parameter definitions`]: `parametre tanımları`,
  [`parameters`]: `parametre`,
  [`path`]: `yol`,
  [`properties`]: `özellikler`,
  [`provider organizations`]: `sağlayıcı kuruluşları`,
  [`request bodies`]: `istek gövdeleri`,
  [`response definitions`]: `yanıt tanımları`,
  [`responses`]: `yanıtlar`,
  [`role`]: `rol`,
  [`scope`]: `kapsam`,
  [`scopes`]: `kapsamlar`,
  [`schemas`]: `şemalar`,
  [`schema definition`]: `şema tanımı`,
  [`schemes list`]: `şemalar listesi`,
  [`security definitions`]: `güvenlik tanımları`,
  [`security schemes`]: `güvenlik şemaları`,
  [`servers`]: `sunucular`,
  [`services`]: `hizmetler`,
  [`tags`]: `dönüştür`,
  [`tags and external documentation`]: `etiketler ve dış belgeler`,
  [`target services`]: `hedef hizmetler`,
  [`tasks`]: `görevler`,
  [`user registries`]: `kullanıcı kayıt defterleri`,
  [`HTTP header`]: `HTTP üstbilgisi`,
  [`You currently don’t have any application authentication source.`]: `Şu anda herhangi bir uygulama kimlik doğrulama kaynağınız yok.`,
  [`You currently don’t have any API or product.`]: `Şu anda herhangi bir API ya da ürününüz yok.`,
  [`You currently don’t have any API user registries`]: `Şu anda herhangi bir API kullanıcı kayıt defteriniz yok`,
  [`You currently don’t have any APIs.`]: `Şu anda API'niz yok.`,
  [`You currently don’t have any TLS client profiles`]: `Şu anda TLS istemci profiliniz yok`,
  [`You currently don’t have any base endpoints.`]: `Şu anda temel uç noktanız yok.`,
  [`You currently don’t have any catalog properties.`]: `Şu anda herhangi bir katalog özelliğiniz yok.`,
  [`You currently don’t have any categories.`]: `Şu anda herhangi bir kategoriniz yok.`,
  [`You currently don’t have any certificates.`]: `Şu anda herhangi bir sertifikanız yok.`,
  [`You currently don’t have any channels.`]: `Şu anda herhangi bir kanalınız yok.`,
  [`You currently don’t have any consumer organizations.`]: `Şu anda bir tüketici kuruluşunuz yok.`,
  [`You currently don’t have any consumer organizations or groups.`]: `Şu anda tüketici kuruluşunuz veya grubunuz yok.`,
  [`You currently don’t have any consumes.`]: `Şu anda herhangi bir tüketiminiz yok.`,
  [`You currently don’t have any correlation ids.`]: `Şu anda herhangi bir ilinti tanıtıcınız yok.`,
  [`You currently don’t have any cors policy.`]: `Şu anda herhangi bir cors ilkeniz yok.`,
  [`You currently don’t have any definitions.`]: `Şu anda herhangi bir tanımınız yok.`,
  [`You currently don’t have any examples.`]: `Şu anda herhangi bir örneğiniz yok.`,
  [`You currently don’t have any gateway services.`]: `Şu anda bir ağ geçidi hizmetiniz yok.`,
  [`You currently don’t have any groups.`]: `Şu anda herhangi bir grubunuz yok.`,
  [`You currently don’t have any headers.`]: `Şu anda herhangi bir üstbilginiz yok.`,
  [`You currently don’t have any keystore.`]: `Şu anda herhangi bir anahtar deponuz yok.`,
  [`You currently don’t have any links.`]: `Şu anda herhangi bir bağlantınız yok.`,
  [`You currently don’t have any messages.`]: `Şu anda herhangi bir iletiniz yok.`,
  [`You currently don’t have any message traits.`]: `Şu anda herhangi bir ileti özelliğiniz yok.`,
  [`You currently don’t have any OAuth providers`]: `Şu anda herhangi bir OAuth sağlayıcınız yok`,
  [`You currently don’t have any operation traits.`]: `Şu anda herhangi bir işlem özelliğiniz yok.`,
  [`You currently don’t have any parameter definitions.`]: `Şu anda herhangi bir parametre tanımınız yok.`,
  [`You currently don’t have any parameters.`]: `Şu anda herhangi bir parametreniz yok.`,
  [`You currently don’t have any preserved request header.`]: `Şu anda herhangi bir korunmuş istek üstbilginiz yok.`,
  [`You currently don’t have any preserved response header.`]: `Şu anda herhangi bir korunmuş yanıt üstbilginiz yok.`,
  [`You currently don’t have any produces.`]: `Şu anda herhangi bir üretiminiz yok.`,
  [`You currently don’t have any properties.`]: `Şu anda herhangi bir özelliğiniz yok.`,
  [`You currently don’t have any provider organizations.`]: `Şu anda herhangi bir sağlayıcı kuruluşunuz yok.`,
  [`You currently don’t have any request bodies.`]: `Şu anda herhangi bir istek gövdeniz yok.`,
  [`You currently don’t have any response definitions.`]: `Şu anda herhangi bir yanıt tanımınız yok.`,
  [`You currently don’t have any responses.`]: `Şu anda herhangi bir yanıtınız yok.`,
  [`You currently don’t have any scopes.`]: `Şu anda herhangi bir kapsamınız yok.`,
  [`You currently don’t have any schemas.`]: `Şu anda herhangi bir şemanız yok.`,
  [`You currently don’t have any schemes list.`]: `Şu anda herhangi bir şema listeniz yok.`,
  [`You currently don’t have any security definitions.`]: `Şu anda herhangi bir güvenlik tanımınız yok.`,
  [`You currently don’t have any security schemes.`]: `Şu anda herhangi bir güvenlik şemanız yok.`,
  [`You currently don’t have any servers.`]: `Şu anda herhangi bir sunucunuz yok.`,
  [`You currently don’t have any services.`]: `Şu anda herhangi bir hizmetiniz yok.`,
  [`You currently don’t have any tags.`]: `Şu anda herhangi bir etiketiniz yok.`,
  [`You currently don’t have any tags and external documentation.`]: `Şu anda bir etiket ve dış belgeniz yok.`,
  [`You currently don’t have any target services.`]: `Şu anda herhangi bir hedef hizmetiniz yok.`,
  [`You currently don’t have any tasks.`]: `Şu anda herhangi bir göreviniz yok.`,
  [`You currently don’t have any truststore.`]: `Şu anda herhangi bir güvenilir deponuz yok.`,
  [`You currently don’t have any user registries.`]: `Şu anda kullanıcı kayıt defteriniz yok.`,
  [`You currently don’t have any extensions.`]: `Şu anda uzantınız yok.`,
  [`You currently don’t have any HTTP header.`]: `Şu anda HTTP üstbilginiz yok.`,
  [`Your account is being activated. This may take a while...`]: `Hesabınız etkinleştiriliyor. Bu işlem biraz zaman alabilir...`,
  [`all of`]: `tümü`,
  [`any of`]: `herhangi biri`,
  [`calls`]: `çağrılar`,
  [`ciphers`]: `şifre`,
  [`extensions`]: `uzantılar`,
  [`one of`]: `biri`,
  [`shared`]: `paylaşılan`,
  [`stopping`]: `durduruluyor`,
  [`to:`]: `kime:`,
  [`Key`]: `Anahtar`,
  [`kind`]: `tür`,
  [`Trace`]: `İz`,
  [`Http Status`]: `Http Durumu`,
  [`Parsed`]: `Ayrıştırılmış`,
  [`Raw`]: `İşlenmemiş`,
  [`Showing Full Trace`]: `Tüm İz Gösteriliyor`,
  [`Show Entire Trace`]: `Tüm İzi Göster`,
  [`Show advanced`]: `Gelişmiş olarak göster`,
  [`Advanced`]: `Gelişmiş`,
  [`Sending Request`]: `İstek gönderiliyor`,
  [`Please send a request above`]: `Lütfen yukarıda bir istek gönderin`,
  [`Please send a request above.`]: `Lütfen yukarıda bir istek gönderin.`,
  [`Authentication Type`]: `Kimlik Doğrulama Tipi`,
  [`You don't currently have any response`]: `Şu anda herhangi bir yanıtınız yok`,
  [`You don't currently have any trace.`]: `Şu anda bir izlemeniz yok.`,
  [`No response received`]: `Yanıt alınmadı`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `"Özel durumu kabul etmek için bağlantıyı aç" tıklatıldığında sunucu yeni bir sekmede açılacaktır. Tarayıcı bir sertifika sorunu görüntülerse bunu kabul edip yeniden test etmek için buraya geri dönmeyi seçebilirsiniz.`,
  [`Open link to accept exception`]: `Özel durumu kabul etmek için bağlantıyı aç`,
  [`Back to {name}`]: `{name} bölümüne geri dön`,
  [`0 seconds`]: `0 saniye`,
  [`close.menu`]: `Menüyü kapat`,
  [`open.menu`]: `Menüyü aç`,
  [`Referenced from "{ref}"`]: `"{ref}" konumdan başvuruluyor`,
  [`Go to {definition}`]: `Şuraya git: {definition}`,
  [`General`]: `Genel`,
  [`Servers`]: `Sunucular`,
  [`External Docs`]: `Dış Belgeler`,
  [`Components`]: `Bileşenler`,
  [`Schemas`]: `Şemalar`,
  [`Responses`]: `Yanıtlar`,
  [`Examples`]: `Örnekler`,
  [`Request Bodies`]: `İstek Gövdeleri`,
  [`Security Schemes`]: `Güvenlik Şemaları`,
  [`Links`]: `Bağlantılar`,
  [`Callbacks`]: `Geri Çağırmalar`,
  [`Terms of Service`]: `Hizmet Koşulları`,
  [`Contact Name`]: `İletişim Sorumlusu Adı`,
  [`Contact URL`]: `İletişim Sorumlusu URL'si`,
  [`Contact Email`]: `İletişim Sorumlusu E-posta Adresi`,
  [`Contact sales`]: `Satış ile iletişime geçin`,
  [`License Name`]: `Lisans Adı`,
  [`License URL`]: `Lisans URL'si`,
  [`Content-Type`]: `İçerik Tipi`,
  [`Accept-Language`]: `Kabul Et-Dil`,
  [`Accept MTOM/XOP optimized messages`]: `MTOM/XOP optimize edilmiş iletileri kabul et`,
  [`User-Agent`]: `Kullanıcı-Aracı`,
  [`token`]: `belirteç`,
  [`Tag`]: `Etiket`,
  [`Request Body`]: `İstek Gövdesi`,
  [`Security Requirements`]: `Güvenlik Gereksinimleri`,
  [`Located In`]: `Konum:`,
  [`Example Value`]: `Örnek Değer`,
  [`External Value`]: `Dış Değer`,
  [`Server`]: `Sunucu`,
  [`Server Description`]: `Sunucu Açıklaması`,
  [`Server Variables`]: `Sunucu Değişkenleri`,
  [`Format`]: `Biçim`,
  [`Pattern`]: `Kalıp`,
  [`Multiple Of`]: `Birden Çok`,
  [`Multiple non null slicing arguments`]: `Birden çok boş değerli olmayan dilimleme bağımsız değişkeni`,
  [`Missing slicing arguments`]: `Eksik dilimleme bağımsız değişkenleri`,
  [`Missing sized fields`]: `Eksik boyutlu alanları`,
  [`Maximum`]: `Maksimum`,
  [`Exclusive Maximum`]: `Hariç Maksimum`,
  [`Minimum`]: `Minimum`,
  [`Exclusive Minimum`]: `Hariç Minimum`,
  [`Max Length`]: `Maks Uzunluk`,
  [`Min Length`]: `Min Uzunluk`,
  [`Max Items`]: `Maks Öğe`,
  [`Min Items`]: `Min Öğe`,
  [`Unique Items`]: `Benzersiz Öğe`,
  [`Max Properties`]: `Maks Özellik`,
  [`Min Properties`]: `Min Özellik`,
  [`Required Properties`]: `Gerekli Özellikler`,
  [`Required Property Name`]: `Gerekli Özellik Adı`,
  [`Enum`]: `Sıralama`,
  [`Enum Value`]: `Sıralama Değeri`,
  [`Nullable`]: `Boş Değer Verilebilir`,
  [`Read Only`]: `Salt Okunur`,
  [`Write Only`]: `Salt Yazılır`,
  [`api`]: `api`,
  [`version`]: `sürüm`,
  [`username`]: `kullanıcı adı`,
  [`password`]: `parola`,
  [`service`]: `hizmet`,
  [`Allows Empty Value`]: `Boş Değere İzin Verir`,
  [`Style`]: `Stil`,
  [`Explode`]: `Patlama`,
  [`Allow Reserved`]: `Ayrılmışa İzin Ver`,
  [`Allow WebSocket Upgrade`]: `WebSocket Yükseltmesine İzin Ver`,
  [`Ref`]: `Başv.`,
  [`Operation ID`]: `İşlem Tanıtıcısı`,
  [`Parameter`]: `Parametre`,
  [`Gateway and portal settings`]: `Ağ geçidi ve portal ayarları`,
  [`Targets`]: `Hedefler`,
  [`Catalog Properties`]: `Katalog Özellikleri`,
  [`catalog properties`]: `katalog özellikleri`,
  [`Attachments`]: `Ekler`,
  [`Activity Log`]: `Etkinlik Günlüğü`,
  [`API Type`]: `API Tipi`,
  [`Phase`]: `Aşama`,
  [`Enable CORS`]: `CORS'u etkinleştir`,
  [`CORS Policy`]: `CORS İlkesi`,
  [`Allowed Origins`]: `İzin Verilen Kökenler`,
  [`Allow Credentials`]: `Kimlik Bilgilerine İzin Ver`,
  [`ID`]: `Tanıtıcı`,
  [`Success Content`]: `Başarılı İçeriği`,
  [`Error Content`]: `Hata İçeriği`,
  [`Attachment`]: `Ek`,
  [`Link`]: `Bağlantı`,
  [`Operation Ref`]: `İşlem Başvurusu`,
  [`TRACE`]: `İZLEME`,
  [`No Security Requirements available`]: `Güvenlik Gereksinimi yok`,
  [`Create a security scheme`]: `Güvenlik şeması yarat`,
  [`Create a Security Requirement`]: `Güvenlik Gereksinimi oluştur`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Aşağıdaki Güvenlik Gereksinimlerinden birini gerektirir. İşaretli değilse güvenlik isteğe bağlıdır.`,
  [`Require wrappers on fault details specified by type`]: `Türe göre belirtilen arıza ayrıntılarında sarıcıları gerektir`,
  [`There are no "Servers"`]: `"Sunucu" yok`,
  [`Write`]: `Yaz`,
  [`Server Variable`]: `Sunucu Değişkeni`,
  [`Security Requirement`]: `Güvenlik Gereksinimi`,
  [`Security Scheme Type`]: `Güvenlik Şeması Tipi`,
  [`Security Scheme`]: `Güvenlik Şeması`,
  [`OAuth Flows`]: `OAuth Akışları`,
  [`Implicit Flow`]: `Örtülü Akış`,
  [`Password Flow`]: `Parola Akışı`,
  [`Client Credentials Flow`]: `İstemci Kimlik Bilgileri Akışı`,
  [`Authorization Code Flow`]: `Yetkilendirme Kodu Akışı`,
  [`Refresh URL`]: `URL'yi Yenile`,
  [`schema`]: `şema`,
  [`Allow Empty Value`]: `Boş Değere İzin Ver`,
  [`Encoding`]: `Kodlama`,
  [`This field is required`]: `Bu alan zorunludur`,
  [`Temporary token`]: `Geçici belirteç`,
  [`Access token`]: `Erişim belirteci`,
  [`ID token`]: `Kimlik belirteci`,
  [`No Security Schemes available`]: `Güvenlik Şeması yok`,
  [`Security Scheme (Key)`]: `Güvenlik Şeması (Anahtar)`,
  [`There are no "{label}"`]: `"{label}" yok`,
  [`You must select at least one scheme to define a Security Requirement`]: `Bir Güvenlik Gereksinimi tanımlamak için en az bir şema seçmelisiniz`,
  [`Select one or more Security Schemes`]: `Bir veya daha çok Güvenlik Şeması seç`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `"{key}" Güvenlik Şeması için kullanılabilir OAuth Kapsamı yok`,
  [`Create a Scope`]: `Kapsam oluştur`,
  [`Remove this Security Requirement`]: `Bu Güvenlik Gereksinimini kaldır`,
  [`Edit this Security Requirement`]: `Bu Güvenlik Gereksinimini düzenle`,
  [`Invalid Security Scheme Reference`]: `Geçersiz Güvenlik Şeması Başvurusu`,
  [`The following Security Schemes do not exist!`]: `Aşağıdaki Güvenlik Şemaları yok!`,
  [`Edit source`]: `Kaynağı düzenle`,
  [`OR`]: `VEYA`,
  [`Required Security Schemes`]: `Zorunlu Güvenlik Şemaları`,
  [`Optional Security`]: `İsteğe Bağlı Güvenlik`,
  [`Optional custom expression (one per line)`]: `İsteğe bağlı özel ifade (her satıra bir adet)`,
  [`Flow Type`]: `Akış Tipi`,
  [`OAuth2 Security Scheme`]: `OAuth2 Güvenlik Şeması`,
  [`Only source editing is supported.`]: `Yalnızca kaynak düzenleme desteklenir.`,
  [`Unsupported Field.`]: `Desteklenmeyen Alan.`,
  [`Select an option`]: `Bir seçenek belirleyin`,
  [`Select {labelText}`]: `{labelText} öğesini seç`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" zaten kullanılıyor'`,
  [`Referenced from "{originalRef}"`]: `"{originalRef}" kaynağından başvuruldu`,
  [`Go to {label}`]: `{label} öğesine git`,
  [`Delete "{label}"`]: `"{label}" öğesini sil`,
  [`Are you sure you want to delete "{label}"?`]: `"{label}" etiketini silmek istediğinizden emin misiniz?`,
  [`No recent items found`]: `Son öğeler bulunamadı`,
  [`Found {numDocs} results ({took} seconds)`]: `{numDocs} sonuç bulundu ({took} saniye)`,
  [`No Consumer Organizations found`]: `Tüketici Kuruluş bulunamadı`,
  [`No Catalog Products found`]: `Katalog Ürünü bulunamadı`,
  [`No APIs found`]: `API bulunamadı`,
  [`No Catalogs found`]: `Katalog bulunamadı`,
  [`No Spaces found`]: `Alan bulunamadı`,
  [`No results found`]: `Sonuç bulunamadı`,
  [`No Catalog APIs found`]: `Katalog API'si bulunamadı`,
  [`No Catalogs found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen Katalog bulunamadı`,
  [`No APIs found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen API bulunamadı`,
  [`No Subscriptions found`]: `Abonelik bulunamadı`,
  [`No Plans found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen Plan bulunamadı`,
  [`No Subscriptions found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen Abonelik bulunamadı`,
  [`No Catalog Products found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen Katalog Ürünü bulunamadı`,
  [`No Catalog APIs found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen Katalog API'si bulunamadı`,
  [`No Consumer Organizations found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen Tüketici Kuruluş bulunamadı`,
  [`No Applications found matching query "{query}"`]: `"{query}" sorgusuyla eşleşen Uygulama bulunamadı`,
  [`APIs per page`]: `Sayfa başına API sayısı`,
  [`Catalogs per page`]: `Sayfa başına katalog sayısı`,
  [`items per page`]: `sayfa başına öğe sayısı`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} / {total} API`,
  [`{min}-{max} of {total} API`]: `{min}-{max} / {total} API`,
  [`Products per page`]: `Sayfa başına ürün sayısı`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Yeni bir faturalama entegrasyonu yapılandırabilmek için, ürün katalogda yayımlanmalıdır`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} / {total} Ürün`,
  [`{min}-{max} of {total} Product`]: `{min}-{max} / {total} Ürün`,
  [`Catalog Products per page`]: `Sayfa başına Katalog Ürünü`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} / {total} Katalog Ürünü`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{max} / {total} Katalog Ürünü`,
  [`Plans per page`]: `Sayfa başına plan sayısı`,
  [`Policies per page`]: `Sayfa başına ilke sayısı`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} / {total} Plan`,
  [`{min}-{max} of {total} Plan`]: `{min}-{max} / {total} Plan`,
  [`Catalog APIs per page`]: `Sayfa başına Katalog API'si sayısı`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} / {total} Katalog API'si`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{max} / {total} Katalog API'si`,
  [`Subscriptions per page`]: `Sayfa başına abonelik sayısı`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} / {total} Abonelik`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{max} / {total} Abonelik`,
  [`Consumer Organizations per page`]: `Sayfa başına Tüketici Kuruluş sayısı`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} / {total} Tüketici Kuruluş`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{max} / {total} Tüketici Kuruluşu`,
  [`Applications per page`]: `Sayfa başına uygulama sayısı`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} / {total} Uygulama`,
  [`{min}-{max} of {total} Application`]: `{min}-{max} / {total} Uygulama`,
  [`Histories per page`]: `Sayfa başına geçmiş sayısı`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} / {total} Geçmiş`,
  [`{min}-{max} of {total} History`]: `{min}-{max} / {total} Geçmiş`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Abone olunabilecek Plan bulunamadı. Abonelik oluşturulmadan önce bir Ürün "yayınlandı" durumunda olmalıdır. Bir uygulama, bir üründe yalnızca bir plana abone olabilir.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Yayınlanan "{draftApiTitle}:{draftApiVersion}" API gösteriliyor`,
  [`Showing Consumer Organization for "{appTitle}"`]: `"{appTitle}" için Tüketici Kuruluş gösteriliyor`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}" için Abonelikler gösteriliyor`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}" için Planlar gösteriliyor`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}" için API'ler gösteriliyor`,
  [`Showing Product for "{planTitle}"`]: `"{planTitle}" için Ürün gösteriliyor`,
  [`Showing APIs for "{planTitle}"`]: `"{planTitle}" için API'ler gösteriliyor`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `"{docFoundIn}" Ürününde "{docTitle}" Planı için Abonelikler gösteriliyor`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `"{apiTitle}:{apiVersion}" için Planlar gösteriliyor`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `"{catalogApiTitle}:{catalogApiVersion}" için Taslak API'ler gösteriliyor`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `"{productTitle}:{productVersion}" için Geçmişler gösteriliyor`,
  [`Showing Subscriptions for "{appTitle}"`]: `"{appTitle}" için Abonelikler gösteriliyor`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `"{consumerOrgTitle}" için Uygulamalar gösteriliyor`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `"{consumerOrgTitle}" için Abonelikler gösteriliyor`,
  [`Showing Application for selected Subscription`]: `Seçili Abonelik için Uygulama gösteriliyor`,
  [`Showing Plan for selected Subscription`]: `Seçili Abonelik için Plan gösteriliyor`,
  [`Showing Product for selected Subscription`]: `Seçili Abonelik için Ürün gösteriliyor`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `"{productTitle}" Ürününe dahil edilecek Taslak API'leri seçin`,
  [`Product Version`]: `Ürün Sürümü`,
  [`OAuth Providers`]: `OAuth Sağlayıcıları`,
  [`Application Type`]: `Uygulama Tipi`,
  [`Client IDs`]: `İstemci Tanıtıcıları`,
  [`Save as New Version`]: `Yeni Sürüm Olarak Kaydet`,
  [`Update Selected APIs`]: `Seçili API'leri Güncelle`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion} API'si kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion} Ürünü kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion} Katalog Ürünü kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion}Tüketici Kuruluşu kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion} Tüketici Grubu kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion}İlkesi kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion}Aboneliği kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion} Kataloğu kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion} Alanı kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `{titleAndVersion} Katalog Ürünü Yeniden yayınlanacak. Devam etmek istiyor musunuz?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `{titleAndVersion} Katalog Ürünü Kullanımdan kaldırılacak. Devam etmek istiyor musunuz?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `{titleAndVersion} Katalog Ürünü Kullanım dışı bırakılacak. Devam etmek istiyor musunuz?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `{titleAndVersion} Katalog Ürünü Yeniden hazırlanacak. Devam etmek istiyor musunuz?`,
  [`Put Online`]: `Çevrimiçi Duruma Getir`,
  [`Take Offline`]: `Çevrimdışı Duruma Getir`,
  [`View Subscriptions`]: `Abonelikleri Görüntüle`,
  [`View Plans`]: `Planları Görüntüle`,
  [`No Tasks found`]: `Görev bulunamadı`,
  [`Tasks per page`]: `Sayfa başına görev sayısı`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} / {total} Görev`,
  [`{min}-{max} of {total} Task`]: `{min}-{max} / {total} Görev`,
  [`Decline and Send`]: `Reddet ve Gönder`,
  [`View Applications`]: `Uygulamaları Görüntüle`,
  [`Publish (Preserve Subscriptions)`]: `Yayınla (Abonelikleri Koru)`,
  [`Update APIs`]: `API'leri Güncelle`,
  [`View in Catalogs`]: `Kataloglarda Görüntüle`,
  [`View Consumer Organization`]: `Tüketici Kuruluşu Görüntüle`,
  [`View Application`]: `Uygulamayı Görüntüle`,
  [`View Plan`]: `Planı Görüntüle`,
  [`View Product`]: `Ürünü Görüntüle`,
  [`Create Subscription`]: `Abonelik Oluştur`,
  [`No Applications found`]: `Uygulama bulunamadı`,
  [`Select a Plan to Create a Subscription to`]: `Abonelik Oluşturmak için bir Plan Seçin`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Bu işlem geri alınamaz. {titleAndVersion}Uygulaması kalıcı olarak silinecek. Devam etmek istiyor musunuz?`,
  [`Save as new version`]: `Yeni sürüm olarak kaydet`,
  [`Filter selected catalogs`]: `Seçilen katalogları süz`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `"{productTitle}" ürününe dahil edilecek taslak API'leri seçin`,
  [`Update selected APIs`]: `Seçilen API'leri güncelle`,
  [`Catalog API`]: `Katalog API'si`,
  [`Catalog APIs`]: `Katalog API'leri`,
  [`View Products`]: `Ürünleri Görüntüle`,
  [`View Draft APIs`]: `Taslak API'lerini Görüntüle`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `"{apiTitle}:{apiVersion}" sürümüne başvuran Ürünler gösteriliyor`,
  [`No Plans found`]: `Plan Bulunamadı`,
  [`View APIs`]: `API'leri Görüntüle`,
  [`Document type tabs`]: `Belge tipi sekmeleri`,
  [`No Products found`]: `Ürün bulunamadı`,
  [`Consumer Organization`]: `Tüketici Kuruluşu`,
  [`Consumer Organizations`]: `Tüketici Kuruluşları`,
  [`Catalog Product`]: `Katalog Ürünü`,
  [`Catalog Products`]: `Katalog Ürünleri`,
  [`Search for a Product and Plan`]: `Ürün ve Plan Ara`,
  [`OpenAPI Version`]: `OpenAPI Sürümü`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (REST, GraphQL veya SOAP kaynaklı)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (Kafka konusundan)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `AsyncAPI oluştur (Kafka konusundan)`,
  [`An error occurred during file upload`]: `Dosya karşıya yükleme sırasında hata oluştu`,
  [`Delete uploaded file`]: `Karşıya yüklenen dosyayı sil`,
  [`Copied!`]: `Kopyalandı!`,
  [`Uploading`]: `Karşıya yükleniyor`,
  [`Validation icon`]: `Doğrulama simgesi`,
  [`Validation error`]: `Doğrulama hatası`,
  [`Uploading image..`]: `Resim karşıya yükleniyor..`,
  [`Unsupported media type {providedFileType}`]: `Desteklenmeyen ortam tipi {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Desteklenen bir dosya {expectedFileTypes} seçin ve yeniden deneyin`,
  [`Pasted`]: `Yapıştırıldı`,
  [`Enter the API summary details.`]: `API özeti ayrıntılarını girin.`,
  [`Define your Kafka cluster connection details.`]: `Kafka küme bağlantısı ayrıntılarınızı tanımlayın.`,
  [`Channel`]: `Kanal`,
  [`Describe your Kafka topic and its associated schema.`]: `Kafka konusu ve ilişkili şemayı tanımlayın.`,
  [`AsyncAPI specification version`]: `AsyncAPI belirtim sürümü`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `API'nizi açıklayan ve Geliştirici Portalı'nda geliştiricilerin keşfetmesini sağlayan ad.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `API tanımınız için sürüm. Ad ve Sürüm bileşimi, API tanımınızı benzersiz şekilde tanımlar.`,
  [`Description (optional)`]: `Açıklama (isteğe bağlı)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `API'nizi tanıtın ve kullanan geliştiriciler için yararlı bilgiler sağlayın.`,
  [`Summary (optional)`]: `Özet (isteğe bağlı)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Bu API'deki olayların kısa özeti. Maksimum 1000 karakter.`,
  [`Bootstrap servers`]: `Önyükleme sunucuları`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Kümenizdeki Kafka aracılarının virgülle ayrılmış listesi.`,
  [`Secure the API using an API Key and Secret`]: `API Anahtarı ve Güvenlik dizgisi kullanarak API güvenliğini sağlayın`,
  [`The credential provided should be in SASL-PLAIN format`]: `Sağlanan kimlik bilgisi SASL-PLAIN biçiminde olmalıdır`,
  [`Publish this API:`]: `Bu API'yi yayınla:`,
  [`Creates a product`]: `Bir ürün oluşturur`,
  [`Associates this API to the product`]: `Bu API'yi ürünle ilişkilendirir`,
  [`Publish the product to the Sandbox catalog`]: `Ürünü Kum Havuzu kataloğunda yayınlar`,
  [`Associates the product to the test app`]: `Ürünü test uygulamasıyla ilişkilendirir`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `"{fileType}" biçiminde bir şema başarıyla karşıya yüklendi ve doğrulandı.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `Karşıya yüklenen dosya geçerli bir Avro Şeması değil ve API'nizin bir parçası olarak dahil edilmeyecek.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Sunucu yanıtı: "{err}". API tanımı sayfasına dönmek için "Geri" düğmesini tıklatın.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Sunucu yanıtı: "{err}". Sihirbazdan çıkmak için "Bitti" düğmesini tıklatın.`,
  [`Topic name`]: `Konu adı`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `Bu API'nin bir parçası olarak göstermek istediğiniz konu adı. Bir konu iletilerin adlandırılmış akışıdır.`,
  [`Schema for message body in .avsc format (optional)`]: `.avsc biçiminde ileti gövdesi şeması (isteğe bağlı)`,
  [`Drag and drop files here, or click to upload`]: `Dosyaları sürükleyip buraya bırakın veya karşıya yüklemek için tıklatın`,
  [`About this step`]: `Bu adım hakkında`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Tüketiciler kullanılabilir oldukça olayları aldığından olaylar, zamanuyumsuz API'ler olarak açıklanabilir. Diğer yandan zamanuyumlu API'ler (REST gibi), yalnızca yalnızca istediklerinde alır.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Olay odaklı zamanuyumsuz API'ler, AsyncAPI belirtimi kullanarak belgelenir.`,
  [`API secret`]: `API güvenlik dizgisi`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Oluşturulan AsyncAPI 2.1.0 tanımı`,
  [`API creating`]: `API oluşturma`,
  [`An error occurred while creating your document`]: `Belgeniz oluşturulurken hata oluştu`,
  [`Publishing your API`]: `API'niz yayınlanıyor`,
  [`Your API has been published`]: `API'niz yayınlandı`,
  [`An error occurred while publishing your API`]: `API'niz yayınlanırken hata oluştu`,
  [`Configure security for this API`]: `Bu API için güvenliği yapılandır`,
  [`Cluster connection security`]: `Küme bağlantısı güvenliği`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Bu API isteklerini işlerken kümenize bağlanmak için Olay Ağ Geçidi Hizmetlerinin kullanacağı kimlik bilgilerini sağlayın. Bu değerleri düzenleyicide daha sonra değiştirebilirsiniz. Ek bilgi için kimlik bilgileri oluşturmaya ilişkin belgelere bakın.`,
  [`SASL Username`]: `SASL Kullanıcı Adı`,
  [`SASL Password`]: `SASL Parolası`,
  [`Transport CA certificate (.pem file - optional)`]: `Aktarım CA sertifikası (.pem dosyası - isteğe bağlı)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Sağlanırsa, ağ geçidi ve Kafka kümesi arasında iletişimde TLS şifrelemesi sağlamak için Olay Ağ Geçidi Hizmeti tarafından kullanılır.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Bir AsyncAPI belgesi oluşturmak için Apache Kafka olay kaynağınızın API ayrıntılarını açıklayın.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `Daha sonra uygulamalarını ilgili olay akışına abone olmak için yapılandırabilen uygulama geliştiricilerine olay kaynağını göstermek için AsyncAPI belgesini yayınlayabilirsiniz.`,
  [`Set up security for your API if you want to control who can access it.`]: `Kimlerin erişebileceğini kontrol etmek istiyorsanız API'niz için güvenliği ayarlayın.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `API'nize erişim, geçerli bir API Anahtarı ve güvenlik dizgisini kontrol eden bir Olay Ağ Geçidi Hizmeti tarafından yönetilir.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Ağ geçitleri, istemcilerin TLS ile iletişimlerini şifrelemesini gerektirir.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `API'niz oluşturulduktan sonra Olay Ağ Geçidi Hizmetinizden Kafka kümenize bağlanmak için güvenlik ayrıntıları gibi daha fazla ayrıntı eklemek için ‘API'yi Düzenle’ seçeneğini tıklatın.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `Daha sonra API erişimini keşfeden ve erişim isteyebilen ve olay kaynağından kullanmak üzere uygulamalarını ayarlama bilgilerini kullanabilen geliştiriciler için kullanılabilir hale getirerek API'nizi yayınlayabilirsiniz.`,
  [`Pending backend response`]: `Beklemedeki arka uç yanıtı`,
  [`Complete`]: `Tamamlandı`,
  [`Rate limiting is only applied to enforced APIs`]: `Hız sınırlama yalnızca zorunlu kılınan API'ler için geçerlidir`,
  [`No Plans Available`]: `Kullanılabilir Plan Yok`,
  [`Modal content is not provided`]: `Kalıcı iletişim kutusu içeriği sağlanmadı`,
  [`Modal heading is not provided`]: `Kalıcı iletişim kutusu başlığı sağlanmadı`,
  [`Edit Rate Limit`]: `Hız Sınırını Düzenle`,
  [`External Documentation`]: `Dış Belge`,
  [`No Label Available`]: `Kullanılabilir Etiket Yok`,
  [`optional`]: `isteğe bağlı`,
  [`Uploading image...`]: `Resim karşıya yükleniyor...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Dosyaları sürükleyerek, bırakarak, seçerek veya yapıştırarak ekleyin.`,
  [`Response name`]: `Yanıt adı`,
  [`Click Add to add a new {resource}.`]: `Yeni {resource} eklemek için Ekle'yi tıklatın.`,
  [`Schema Name (Key)`]: `Şema Adı (Anahtar)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Ad alanı`,
  [`Attribute`]: `Öznitelik`,
  [`Wrapped`]: `Sarılmış`,
  [`Example`]: `Örnek`,
  [`Example Name (Key)`]: `Örnek Ad (Anahtar)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" zaten kullanılıyor`,
  [`Name (Key)`]: `Ad (Anahtar)`,
  [`Content Type (Key)`]: `İçerik Tipi (Anahtar)`,
  [`Header name`]: `Üstbilgi adı`,
  [`Reference`]: `Referans`,
  [`Security Scheme Name (Key)`]: `Güvenlik Şeması Adı (Anahtar)`,
  [`Choose a Reference`]: `Başvuru Seçin`,
  [`Reference from`]: `Başvuru kaynağı`,
  [`Content Type`]: `İçerik Tipi`,
  [`array`]: `dizi`,
  [`boolean`]: `boole`,
  [`integer`]: `tamsayı`,
  [`number`]: `rakam`,
  [`object`]: `nesne`,
  [`string`]: `dize`,
  [`query`]: `sorgu`,
  [`cookie`]: `çerez`,
  [`matrix`]: `matris`,
  [`label`]: `etiket`,
  [`simple`]: `basit`,
  [`form`]: `form`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth Sağlayıcısı (Ad)`,
  [`Authenticate using User Registry (name)`]: `Kullanıcı Kaydı kullanarak kimliği doğrula (ad)`,
  [`Bearer Format`]: `Taşıyıcı Biçimi`,
  [`Server Variable Name`]: `Sunucu Değişken Adı`,
  [`Verb (Key)`]: `Fiil (Anahtar)`,
  [`Parameter Definitions`]: `Parametre Tanımları`,
  [`Path Parameters`]: `Yol Parametreleri`,
  [`Media Type List`]: `Ortam Tipi Listesi`,
  [`Medium`]: `Orta`,
  [`Schemes List`]: `Şemalar Listesi`,
  [`Small`]: `Küçük`,
  [`Response Definitions`]: `Yanıt Tanımları`,
  [`Terms Of Service`]: `Hizmet Koşulları`,
  [`Url`]: `Url`,
  [`Untitled Parameter`]: `Başlıksız Parametre`,
  [`Parameter Name`]: `Parametre Adı`,
  [`Mime Type`]: `Mime Tipi`,
  [`Async API`]: `Zamanuyumsuz API`,
  [`Channels`]: `Kanallar`,
  [`Operation Traits`]: `İşlem Özellikleri`,
  [`Messages`]: `İletiler`,
  [`Correlation IDs`]: `Korelasyon Tanıtıcıları`,
  [`Traits`]: `Özellikler`,
  [`Message Traits`]: `İleti Özellikleri`,
  [`Bindings`]: `Bağ Tanımları`,
  [`Server Bindings`]: `Sunucu Bağ Tanımları`,
  [`Channel Bindings`]: `Kanal Bağ Tanımları`,
  [`Operation Bindings`]: `İşlem Bağ Tanımları`,
  [`Message Bindings`]: `İleti Bağ Tanımları`,
  [`Protocol Version`]: `İletişim Kuralı Sürümü`,
  [`Define multiple messages`]: `Birden çok ileti tanımla`,
  [`Schema Format`]: `Şema Biçimi`,
  [`Unsupported Field`]: `Desteklenmeyen Alan`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `İşlem Özelliği`,
  [`Message Trait`]: `İleti Özelliği`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Derlemenin bir bölümünü belirli bir işleme uygulamak için İşlem Anahtarı ilkesini kullanın.`,
  [`HTML page`]: `HTML sayfası`,
  [`Rule`]: `Kural`,
  [`Origin`]: `Köken`,
  [`Property Name (Key)`]: `Özellik Adı (Anahtar)`,
  [`Catalog Name (Key)`]: `Katalog Adı (Anahtar)`,
  [`Property Overrides`]: `Özellik Geçersiz Kılma Değerleri`,
  [`Property Name`]: `Özellik Adı`,
  [`Property Value`]: `Özellik Değeri`,
  [`About subscriptions and plans`]: `Abonelikler ve planlar hakkında`,
  [`Subscription Plan Name`]: `Abonelik Planı Adı`,
  [`Select plan`]: `Plan seçin`,
  [`Select the plan to migrate application subscriptions from`]: `Uygulama aboneliklerinin taşınacağı kaynak planı seçin`,
  [`Select subscriptions`]: `Abonelikleri seç`,
  [`Select the application subscriptions to migrate`]: `Geçiş yapmak için uygulama aboneliklerini seçin`,
  [`Select the product to migrate application subscriptions to`]: `Uygulama aboneliklerinin taşınacağı hedef ürünü seçin`,
  [`Select the plan to migrate application subscriptions to`]: `Uygulama aboneliklerinin taşınacağı hedef planı seçin`,
  [`Message successfully sent to {sent} owner(s)`]: `İleti başarıyla sahibe/sahiplere {sent} gönderildi`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} ileti gönderilemedi`,
  [`Failed email addresses`]: `Gönderilemeyen e-posta adresleri`,
  [`Failed to save the API`]: `API kaydedilemedi`,
  [`and {numExtra} more...`]: `ve {numExtra} daha...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Gönderilemeyen e-posta adresleri listesini panoya kopyala`,
  [`From`]: `Gönderen`,
  [`Message Owner`]: `İleti Sahibi`,
  [`Message owner`]: `İleti sahibi`,
  [`Message owners`]: `İleti Sahipleri`,
  [`Message group`]: `İleti grubu`,
  [`Select consumer organizations`]: `Tüketici kuruluşlarını seçin`,
  [`Message consumer organization owners`]: `Tüketici kuruluş sahiplerine ileti gönder`,
  [`Message consumer organization owner`]: `İleti tüketici kuruluşu sahibi`,
  [`Message provider organization owner`]: `Sağlayıcı kuruluş sahibine ileti gönder`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `Bu Çağırma ilkesiyle ilgili Kafka kümesi tanıtıcısı`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Kafka kümesiyle bağlantılar oluşturmak için kullanılacak anasistem:kapı çiftlerinin virgülle ayrılmış listesi`,
  [`The username to use when connecting to the Kafka cluster.`]: `Kafka kümesine bağlanılırken kullanılacak kullanıcı adı.`,
  [`The password to use when connecting to the Kafka cluster.`]: `Kafka kümesine bağlanılırken kullanılacak parola.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Olay Ağ Geçidi Hizmeti`,
  [`Event gateway management client keystore`]: `Olay ağ geçidi yönetim istemcisi anahtar deposu`,
  [`Edit Event gateway Service`]: `Olay Ağ Geçidi Hizmetini Düzenle`,
  [`Configure Event Gateway Service`]: `Olay Ağ Geçidi Hizmetini Yapılandır`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Zamanuyumsuz API'lerin güvenliği ve uygulanması için bir Olay Ağ Geçidi Hizmeti yapılandırın`,
  [`Catalogs with published product`]: `Yayınlanan ürünü içeren kataloglar`,
  [`Cluster Config ID`]: `Küme Yapılandırma Tanıtıcısı`,
  [`SASL mechanism`]: `SASL mekanizması`,
  [`Security protocol`]: `Güvenlik protokolü`,
  [`Transport CA certificate`]: `Aktarım CA sertifikası`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `Maks. dosya boyutu {size}. Desteklenen dosya tipleri şunlardır: json, xml, yml, yaml ve zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Desteklenen dosya tipleri şunlardır: json, xml, yml ve yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Desteklenen dosya tipleri şunlardır: json, wsdl, xml, xsd, yml, yaml ve zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Desteklenen dosya tipleri şunlardır: json, wsdl, xml, yml ve yaml.`,
  [`Select a file`]: `Dosya seçin`,
  [`or specify a file URL`]: `veya bir dosya URL'si belirtin`,
  [`Generated OpenAPI {version} definition`]: `Oluşturulan OpenAPI {version} tanımı`,
  [`User registry type`]: `Kullanıcı kayıt defteri tipi`,
  [`User registry types`]: `Kullanıcı kayıt defteri tipi`,
  [`User registry type {title} has been created.`]: `Kullanıcı kayıt defteri tipi {title} yaratıldı.`,
  [`User registry type {title} has been updated.`]: `Kullanıcı kayıt defteri tipi {title} güncellendi.`,
  [`Failed to create user registry type {title}.`]: `Kullanıcı kayıt defteri tipi {title} yaratılamadı.`,
  [`Failed to update user registry type {title}.`]: `Kullanıcı kayıt defteri tipi {title} güncellenemedi.`,
  [`Failed to update user registry {title}.`]: `Kullanıcı kayıt defteri {title} güncellenemedi.`,
  [`Custom user registry`]: `Özel kullanıcı kaydı`,
  [`Create custom user registry`]: `Özel kullanıcı kayıt defteri yarat`,
  [`Edit custom user registry`]: `Özel kullanıcı kaydını düzenle`,
  [`Create user registry type`]: `Kullanıcı kayıt defteri tipi yarat`,
  [`Edit user registry type`]: `Kullanıcı kayıt defteri tipini düzenle`,
  [`Configure user authentication using a custom external user registry`]: `Özel dış kullanıcı kayıt defterini kullanarak kullanıcı kimlik doğrulamasını yapılandır`,
  [`Secured Endpoint`]: `Güvenli Uç Nokta`,
  [`Configuration schema`]: `Yapılandırma şeması`,
  [`About custom user registries`]: `Özel kullanıcı kayıt defterleri hakkında`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Özel kullanıcı kayıt defterleri, Developer Portal'da kullanıcı kimliklerini doğrulama için kullanılabilir, ancak API güvenliği için kullanılamaz. API Manager'da kaynak olarak özel bir kullanıcı kayıt defterini yapılandırmak için dış kullanıcı dizinin yaratıılması ve API Connect ekosisteminizle kullanılabilir olması gerekir.`,
  [`Update Wsdl`]: `Wsdl'yi güncelle`,
  [`Update Existing WSDL service`]: `Var Olan WSDL hizmetini güncelle`,
  [`Format of selected WSDL has been successfully validated`]: `Seçilen WSDL biçimi başarıyla doğrulandı`,
  [`Select the target WSDL file to update from`]: `Güncellemek için hedef WSDL dosyası seçin`,
  [`Selected WSDL has been successfully uploaded`]: `Seçilen WSDL başarıyla karşıya yüklendi`,
  [`Services available in selected API`]: `Seçilen API'de kullanılabilir hizmetler`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `OpenAPI 2.0 belgeleri için eski Open API Editor'ı etkinleştir (kullanımdan kaldırıldı)`,
  [`Unknown`]: `Bilinmiyor`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API veya üründe belirtilen ağ geçidi tipiyle uyumsuzluk nedeniyle {incompatibleCatalogCount} katalog gizlendi`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API veya üründe belirtilen ağ geçidi tipiyle uyumsuzluk nedeniyle {incompatibleSpacesCount} alan gizlendi`,
  [`Keep in mind`]: `Unutmayın`,
  [`Only one type of enforced APIs is allowed per product`]: `Ürün başına yalnızca bir zorunlu kılınan API tipine izin verilir`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `Üründe belirtilen ağ geçidi tipiyle uyumsuzluk nedeniyle {incompatibleAPIsCount} API seçilemiyor`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `API'de belirtilen ağ geçidi tipiyle uyumsuzluk nedeniyle {incompatibleProductsCount} Ürün seçilemiyor`,
  [`Unenforced`]: `Zorunlu kılınmayan`,
  [`Create a new product by adding APIs and plans.`]: `API ve planlar ekleyerek yeni bir ürün yaratın.`,
  [`Rate limits are only applied to enforced APIs.`]: `Hız sınırları yalnızca zorunlu kılınan API'lere uygulanır.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Ürününüze plan ve API sınırları ekleyin. Plan sınırı/sınırları yalnızca zorunlu kılınan API'lere uygulanır ve zorlanmayan API'ler sınırsız olur.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Ürününüze planlar ve hız sınırları ekleyin. Plan/Planlar yalnızca zorunlu kılınan API'lere uygulanır ve zorlu kılınmayan API'ler sınırsız olur.`,
  [`Delete plan`]: `Planı sil`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `AsyncAPI'leri için Olay Ağ Geçidi Hizmetini seçin. Diğer tüm API tipleri için DataPower API Gateway veya v5 uyumlu sürümü seçin. Bu ayarı güncellemek için önce ilişkili API'leri kaldırın.`,
  [`Edit API list`]: `API listesini düzenle`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Bu üründe zorunlu kılınan API/API'ler birden çok ağ geçidi tipi gerektirir.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `Bu üründe zorunlu kılınan {apiType}, {gatewayType} gerektirir.`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API`,
  [`AsyncAPI(s)`]: `AsyncAPI`,
  [`Select the gateway type for this Product`]: `Bu Ürün için ağ geçidi tipini seçin`,
  [`Added default plan`]: `Varsayılan plan eklendi`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Ürünü Kum Havuzu kataloğunda yayınlayın. Bu, ürünü test uygulamasıyla ilişkilendirir.`,
  [`cluster`]: `küme`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Reddedenden gelen ileti`,
  [`Vendor extensions feature disabled`]: `Satıcı uzantıları özelliği devre dışı bırakıldı`,
  [`No configured gateways found`]: `Yapılandırılmış bir ağ geçidi bulunamadı`,
  [`No OpenAPI extensions associated with the configured gateway`]: `Yapılandırılan ağ geçidi ile ilişkilendirilmiş OpenAPI uzantıları yok`,
  [`Extension`]: `Uzantı`,
  [`Select the extensions to add to the API document`]: `API belgesine eklenecek uzantıları seçin`,
  [`(Operations: {operations})`]: `(İşlemler: {operations})`,
  [`Failed to get application`]: `Uygulama alınamadı`,
  [`Failed to get credentials for application`]: `Uygulama için kimlik bilgileri alınamadı`,
  [`Failed to get published product`]: `Yayınlanan ürün alınamadı`,
  [`Failed to get the published API`]: `Yayınlanan API alınamadı`,
  [`API must be activated to use the Test tool`]: `Test aracını kullanmak için API'nin etkinleştirilmesi gerekir`,
  [`Import from Asset repository`]: `Varlık havuzundan içe aktar`,
  [`Untitled {componentLabel}`]: `Başlıksız {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Lisans metriğiniz kullanımın takibi için bir Analytics Service gerektiriyor. Analytics Service yapılandırması için "Hizmeti Kaydet" öğesini tıklatın.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Tüm DataPower ağ geçidi hizmetleri, kullanımı takip etmek için bir Analitik Hizmeti gerektirir. Lisans koşullarına uyum sağlamak amacıyla her bir ağ geçidi için "Analitik hizmeti ilişkilendir" seçeneğini tıklayın.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Katalog, API veya üründe belirtilen ağ geçidi tipiyle uyumsuz`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `Alan, API veya üründe belirtilen ağ geçidi tipiyle uyumsuz`,
  [`Preserve Subscription`]: `Aboneliği Koru`,
  [`Publish validation`]: `Yayınlama geçerlilik denetimi`,
  [`Publish validations`]: `Doğrulamaları yayınla`,
  [`Edit publish validations`]: `Yayınlama geçerlilik denetimlerini düzenle`,
  [`The following validations will be performed when publishing a product`]: `Bir ürün yayınlanırken aşağıdaki doğrulamalar gerçekleştirilecektir`,
  [`There are no publish validations enabled`]: `Etkinleştirilen yayınlama doğrulamaları yok`,
  [`Publish validations have been updated`]: `Yayınlama geçerlilik denetimleri güncellendi`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Arka uç Kafka sunucu sertifikasına güvenmek için Event Gateway Service'in kullandığı sertifika yetkilisini (CA) girin. Sunucu, iyi bilinen bir yetkili tarafından düzenlenen bir sertifika kullanıyorsa CA sağlamanız gerekmez.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `Önyükleme sunucuları için girilen değer geçerli değil. Virgüllerle ayrılan, kümenizde her Kafka aracısının kapı ve anasistemini girin. Örneğin, aracı1.com:9092,aracı2.com:9092,aracı3.com:9092.`,
  [`Upload OpenAPI Extension`]: `OpenAPI Uzantısını Kaldır`,
  [`Add OpenAPI Extension`]: `OpenAPI Uzantısını Ekle`,
  [`Gateway Endpoint`]: `Ağ Geçidi Uç Noktası`,
  [`API Dashboard`]: `API Gösterge Panosu`,
  [`API call data`]: `API çağrısı verileri`,
  [`Product Dashboard`]: `Ürün Gösterge Panosu`,
  [`API Product consumption metrics`]: `API Ürün tüketim metrikleri`,
  [`Monitoring Latency Dashboard`]: `İzleme Gecikme Süresi Gösterge Panosu`,
  [`Data about the time taken to serve API calls`]: `API çağrılarını sunmak için geçen süreyle ilgili veriler`,
  [`Monitoring Status Dashboard`]: `İzleme Durumu Gösterge Panosu`,
  [`API Error and success information`]: `API Hata ve başarı bilgileri`,
  [`Usage Dashboard`]: `Kullanım Gösterge Panosu`,
  [`Usage for gateway {gateway}`]: `Ağ geçidi {gateway} kullanımı`,
  [`Latency for gateway {gateway}`]: `Ağ geçidi {gateway} için gecikme süresi`,
  [`Most popular products, APIs and applications`]: `En popüler ürünler, API'ler ve uygulamalar`,
  [`Consumption Dashboard`]: `Tüketim Gösterge Panosu`,
  [`Total API volume useful for auditing and compliance`]: `Denetim ve uyumluluk için kullanılabilecek toplam API birimi`,
  [`Monthly Summary by Status Code`]: `Durum Kodu Temelinde Aylık Özet`,
  [`Monthly Summary Data by Status Code`]: `Durum Kodu Temelinde Aylık Özet Verileri`,
  [`Daily Summary by Status Code`]: `Durum Kodu Temelinde Günlük Özet`,
  [`Daily Summary Data by Status Code`]: `Durum Kodu Temelinde Günlük Özet Verileri`,
  [`Response time (ms)`]: `Yanıt Süresi (ms)`,
  [`Datetime`]: `Tarih/Saat`,
  [`Transaction ID:`]: `İşlem Tanıtıcısı:`,
  [`Other`]: `Diğer`,
  [`Date time`]: `Tarih saat`,
  [`Total calls`]: `Toplam çağrı`,
  [`Date`]: `Tarih`,
  [`Month`]: `Ay`,
  [`Minimum response time`]: `Minimum yanıt süresi`,
  [`Maximum response time`]: `Maksimum yanıt süresi`,
  [`Average response time`]: `Ortalama yanıt süresi`,
  [`Total API calls`]: `Toplam API çağrısı`,
  [`Total APIs`]: `Toplam API'ler`,
  [`APIs called`]: `Çağrılan API'ler`,
  [`Total products`]: `Toplam ürün sayısı`,
  [`products called`]: `çağrılan ürünler`,
  [`Total errors`]: `Toplam hata`,
  [`Status codes (detailed)`]: `Durum kodları (ayrıntılı)`,
  [`Response time percentiles`]: `Yanıt süresi yüzdelikleri`,
  [`API calls per day`]: `Gün başına API çağrıları`,
  [`Time zone:`]: `Saat dilimi:`,
  [`Time zone`]: `Saat dilimi`,
  [`Toggle axes`]: `Eksenleri değiştir`,
  [`Datetime:`]: `Tarihsaat:`,
  [`datetime per 30 seconds`]: `30 saniye başına tarih-saat`,
  [`Datetime (local time)`]: `Tarih/saat (yerel saat)`,
  [`Data usage (bytes)`]: `Veri kullanımı (bayt)`,
  [`Time to Serve Request`]: `İsteği Sunma Süresi`,
  [`API name`]: `API adı`,
  [`Product name`]: `Ürün adı`,
  [`Data usage (bytes received)`]: `Veri kullanımı (alınan bayt sayısı)`,
  [`Data usage (bytes sent)`]: `Veri kullanımı (gönderilen bayt sayısı)`,
  [`Response times (>1s)`]: `Yanıt süreleri (>1s)`,
  [`milliseconds`]: `milisaniye`,
  [`errors`]: `hata`,
  [`Success Rate`]: `Başarı Oranı`,
  [`Errors Data`]: `Hata Verileri`,
  [`Success Data`]: `Başarı Verileri`,
  [`Total consumer organizations`]: `Toplam tüketici kuruluşları`,
  [`making API calls`]: `API çağrısı yapma`,
  [`Applications per plan`]: `Plan başına uygulamalar`,
  [`Top products by calls`]: `Çağrılara göre en üstteki ürünler`,
  [`Top APIs by calls`]: `Çağrılara göre en üstteki API`,
  [`Top client IP addresses by calls`]: `Çağrılara göre en üst sıradaki istemci IP adresleri`,
  [`Client IP address`]: `İstemci IP adresi`,
  [`Top APIs by request size`]: `İstek boyutuna göre en üstteki API'ler`,
  [`Top APIs by response size`]: `Yanıt boyutuna göre en üstteki API'ler`,
  [`Top APIs by response time`]: `Yanıt süresine göre en üstteki API'ler`,
  [`Top applications by calls`]: `Çağrılara göre en üstteki uygulamalar`,
  [`Dashboards`]: `Gösterge panoları`,
  [`Discover`]: `Keşfet`,
  [`UTC`]: `UTC`,
  [`Local time`]: `Yerel saat`,
  [`URI`]: `URI`,
  [`Response code`]: `Yanıt kodu`,
  [`Total time elapsed (ms)`]: `Geçen toplam süre (ms)`,
  [`Timestamp`]: `Zaman damgası`,
  [`Method`]: `Yöntem`,
  [`Gateway IP`]: `Ağ geçidi IP`,
  [`Gateway Operations Dashboard`]: `Ağ Geçidi İşlemleri Gösterge Panosu`,
  [`Usage and latency information per gateway`]: `Ağ geçidi başına kullanım ve gecikme süresi bilgileri`,
  [`Transaction ID`]: `İşlem Tanıtıcısı`,
  [`Filters`]: `Süzgeçler`,
  [`Fields`]: `Alanlar`,
  [`Clear all`]: `Tümünü temizle`,
  [`API Event`]: `API Olayı`,
  [`API Assembly Policy Latencies`]: `API Düzeneği İlkesi Gecikmeleri`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `Aşağıdaki çizge, API düzeneği ilkeleri aracılığıyla istek ilerledikçe artan gecikme süresini (milisaniye olarak) gösterir. Bu, API düzeneğindeki yavaş noktaları ya da darboğazları belirlemede yardımcı olabilir.`,
  [`Collapse`]: `Daralt`,
  [`Expand`]: `Genişlet`,
  [`enter name`]: `ad girin`,
  [`Enter value`]: `Değer girin`,
  [`Time range`]: `Zaman aralığı`,
  [`Select one of the following time range filters`]: `Aşağıdaki zaman aralığı süzgeçlerinden birini seçin`,
  [`Last 1 hour`]: `Son 1 saat`,
  [`Last {number} minutes`]: `Son {number} dakika`,
  [`Last {number} hours`]: `Son {number} saat`,
  [`Last {number} days`]: `Son {number} gün`,
  [`Start date`]: `Başlangıç tarihi`,
  [`End date`]: `Bitiş tarihi`,
  [`Select from the following dropdowns and enter a value`]: `Aşağıdaki açılır menüden seçim yapın ve bir değer girin`,
  [`Select one of the following type filters`]: `Aşağıdaki tip süzgeçlerinden birini seçin`,
  [`All events`]: `Tüm etkinlikler`,
  [`Success only (2xx, 3xx)`]: `Yalnızca başarılı (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Yalnızca hata (4xx, 5xx)`,
  [`Import/Export`]: `İçe/Dışa Aktar`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Yaklaşık {totalItems} sonuç ({searchTime} saniye), {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Yaklaşık {totalItems} sonuç ({searchTime} saniye), {time} ({utcTime})`,
  [`API calls`]: `API çağrısı`,
  [`Export as CSV`]: `CSV olarak dışa aktar`,
  [`Export as JSON`]: `JSON olarak dışa aktar`,
  [`Export as PNG`]: `PNG olarak dışa aktar`,
  [`Export as JPG`]: `JPG olarak dışa aktar`,
  [`Edit filter`]: `Süzgeci düzenle`,
  [`Edit query`]: `Sorguyu düzenle`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Bu paylaşılan bir sorgudur. Herhangi bir değişiklik, bu sorgunun paylaşıldığı tüm kullanıcılar için yapılacaktır.`,
  [`This is a shared query. This will delete it for all users.`]: `Bu paylaşılan bir sorgudur. Bu, dosyayı tüm kullanıcılar için silecektir.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname}, {org} sağlayıcı kuruluşundaki tüm kullanıcılar için güncelleştirildi`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname}, {space} alanındaki tüm kullanıcılar için güncellendi`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname}, {catalog} kataloğundaki tüm kullanıcıları için güncellendi`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname}, bulut yöneticisindeki tüm kullanıcılar için güncellendi`,
  [`Stop sharing`]: `Paylaşımı durdur`,
  [`Share query`]: `Sorguyu paylaş`,
  [`Share filter`]: `Süzgeci paylaş`,
  [`You do not have permissions to delete this filter`]: `Bu süzgeci silmek için izinlere sahip değilsiniz`,
  [`You do not have permissions to delete this query`]: `Bu sorguyu silmek için gereken izinlere sahip değilsiniz`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `{queryTitle} sorgusunu {org} sağlayıcı kuruluşu kapsamında paylaşmak istediğinizden emin misiniz?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `{queryTitle} sorgusunu {space} alanı kapsamında paylaşmak istediğinizden emin misiniz?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `{queryTitle} sorgusunu {catalog} kataloğu kapsamında paylaşmak istediğinizden emin misiniz?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `{queryTitle} sorgusunu bulut kapsamında paylaşmak istediğinizden emin misiniz?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `{queryTitle} sorgusunu paylaşmayı durdurmak istediğinizden emin misiniz?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `{queryTitle} sorgusunu {org} sağlayıcı kuruluşu kapsamında paylaşmayı durdurmak istediğinizden emin misiniz?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `{queryTitle} sorgusunu {space} alanı kapsamında paylaşmayı durdurmak istediğinizden emin misiniz?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `{queryTitle} sorgusunu {catalog} kataloğu kapsamında paylaşmayı durdurmak istediğinizden emin misiniz?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `{queryTitle} sorgusunu bulut kapsamında paylaşmayı durdurmak istediğinizden emin misiniz?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `{queryTitle} sorgusunu silmek istediğinizden emin misiniz?`,
  [`At least one query parameter expected`]: `En az bir sorgu parametresi beklendi`,
  [`A provided query parameter is incomplete`]: `Sağlanan bir sorgu parametresi eksik`,
  [`Could not parse query string`]: `Sorgu dizgisi ayrıştırılamadı`,
  [`An unsupported query parameter was provided: {query}`]: `Desteklenmeyen bir sorgu parametresi sağlandı: {query}`,
  [`Invalid timeframe value: {value}`]: `Zaman çerçevesi değeri geçersiz: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `{field} değeri, RFC 3339, bölüm 5.6 tarafından tanımlanan tarih-saat gösterimine uygun olmalıdır. Örnekler, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `İşleç {operator}, {field} alanı için desteklenmiyor`,
  [`The value of {field} must be a number`]: `{field} değeri bir sayı olmalıdır`,
  [`Delete query`]: `Sorguyu sil`,
  [`Delete filter`]: `Süzgeci sil`,
  [`Save query`]: `Sorguyu kaydet`,
  [`Save filter`]: `Süzgeci kaydet`,
  [`Save filter as`]: `Süzgeci farklı kaydet`,
  [`Save query as`]: `Sorguyu farklı kaydet`,
  [`Save as...`]: `Farklı kaydet...`,
  [`Save as`]: `Farklı kaydet`,
  [`Paste query string to load the query`]: `Sorguyu yüklemek için sorgu dizesini yapıştır`,
  [`Copy or paste filter string to load filters`]: `Süzgeçleri yüklemek için süzgeç dizesini kopyala ya da yapıştır`,
  [`Query string`]: `Sorgu dizesi`,
  [`View filter`]: `Süzgeci görüntüle`,
  [`View filter string`]: `Süzgeç dizesini görüntüle`,
  [`Date (local time)`]: `Tarih (yerel saat)`,
  [`Coming soon, a new built in analytics feature`]: `Yeni bir yerleşik analitik özelliği yakın kullanılabilir olacak`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `Analitik, API çalıştırma zamanınıza ilişkin içgörülerin toplanmasına, hizmet seviyelerinin yönetilmesine, kotaların ayarlanmasına, denetimlerin oluşturulmasına, güvenlik ilkelerinin oluşturulmasına, toplulukların yönetilmesine ve eğilimlerin analiz edilmesine yardımcı olur.`,
  [`Documentation`]: `Belgeler`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `API çalıştırma zamanı verileri tiplerine ve bu verileri görselleştirmek için AWS s3 saklama kaplarının nasıl kullanılacağına ayrıntılı olarak bakın.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Bugün hangi verilerin kullanılabilir olduğunu ve bu verilere nasıl erişileceğini öğrenin.`,
  [`Next steps`]: `Sonraki adımlar`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `Seçilen Secrets Manager hizmeti herhangi bir sertifikayı yönetmiyor.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `API Connect ve ağ geçidi yönetimi uç noktasının etki alanı arasındaki bağlantı güvenliği için bir sertifika gereklidir. Sertifikalar, ağ geçidi ve işlediği etki alanları arasındaki bağlantıların güvenliği için de gerekir. Tüm sertifikalar bir Secrets Manager hizmetinde saklanmalıdır. Hizmet, sertifikalar için güvenli bir havuz sağlar ve Olay Bildirimleri hizmeti ile bütünleşerek sertifikaların süresi dolmak üzereyken size bildirim göndererek kesintilerin önlenmesine yardımcı olur. Başlamak için,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Sertifikalar, ağ geçidi ve işlediği etki alanları arasındaki bağlantıların güvenliği için gerekir. Tüm sertifikalar bir Secrets Manager hizmetinde saklanmalıdır. Hizmet, sertifikalar için güvenli bir havuz sağlar ve Olay Bildirimleri hizmeti ile bütünleşerek sertifikaların süresi dolmak üzereyken size bildirim göndererek kesintilerin önlenmesine yardımcı olur. Başlamak için,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Secrets Manager'ın değiştirilmesi, var olan tüm sertifika ve CA paketi atamalarını kaldıracaktır.`,
  [`Create a [Secrets Manager]({link}) service`]: `Bir [Secrets Manager]({link}) hizmeti oluşturun`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Gerekli sertifikaları ve CA paketlerini Secrets Manager hizmetine aktarın.`,
  [`No Secrets Manager services available`]: `Kullanılabilir Secrets Manager hizmeti yok`,
  [`Select Secrets Manager service`]: `Secrets Manager hizmetini seçin`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Bu ağ geçidine ilişkin sertifikaları yöneten [Secrets Manager]({link}) hizmetini seçin.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Secrets Manager hizmeti, ağ geçidi yönetim uç noktasının etki alanına ve ağ geçidinin ele aldığı etki alanlarına ilişkin sertifikaları yönetmelidir. Ayrıca, Secrets Manager hizmetine erişmesi için API Connect Ayrılmış Eşgörünüm hizmetini yetkilendirmek üzere IBM Cloud IAM'yi kullanmanız gerekir.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Ardından yönetim uç noktası etki alanı için sertifikayı seçin. Sertifika bilinen bir sertifika yetkilisi tarafından atanmamışsa ilgili CA paketini seçin. Sertifika ve paket, Secrets Manager hizmeti tarafından yönetilmelidir.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Bu ağ geçidi tarafından yönetilecek etki alanlarını belirtin. SNI tekniği kullanılır; bunun sonucunda aynı sertifikaya gerek olmadan aynı IP adresi ve kapı aracılığıyla birden çok etki alanına hizmet verilebilir. Genel arama biçimi desteklenir. Varsayılan  (catch-all) etki alanı ‘*’ tablodaki son giriş olarak eklenmelidir. Gerektiği şekilde diğer etki alanı adlarını girin ve her durumda Secrets Manager hizmeti tarafından yönetilen bir sertifika belirtin.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `[IBM Cloud IAM]'yi ({link}) kullanarak, API Connect Ayrılmış Eşgörünüm hizmetinin Secrets Manager hizmetine erişmesi için yetki verin.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Var olan sertifikayı ve CA paketi atamalarını Secrets Manager'dan yükleyerek güncelleyin.`,
  [`Access s3 data`]: `s3 verilerine erişme`,
  [`Create resource`]: `Kaynak yaratma`,
  [`Connect analytics data in s3 bucket with AWS`]: `s3 saklama kabında analitik verilerini AWS ile birleştirme`,
  [`AWS account`]: `AWS hesabı`,
  [`AWS account number`]: `AWS hesap numarası`,
  [`Enter your 12 digit aws account id`]: `12 basamaklı AWS hesap tanıtıcınızı girin`,
  [`Account id must be 12 digits`]: `Hesap tanıtıcısı 12 basamaklı olmalıdır`,
  [`Failed to update AWS account id.`]: `AWS hesap tanıtıcısı güncellenemedi.`,
  [`You have limited access to the catalogs.`]: `Kataloglara sınırlı erişiminiz var.`,
  [`You have limited access to the spaces.`]: `Alanlarla sınırlı erişiminiz var.`,
  [`Contact admin to get more access.`]: `Daha fazla erişim elde etmek için sistem yöneticisiyle iletişim kurun.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `Bu kataloğu seçemezsiniz. Erişime gerekliyse lütfen bir sistem yöneticisiyle iletişim kurun.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `Bu alanı seçemezsiniz. Erişime gerekliyse lütfen bir sistem yöneticisiyle iletişim kurun.`,
  [`You have limited access to the product billing.`]: `Ürün faturalandırmasına sınırlı erişiminiz var.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `AWS hesap tanıtıcısı başarıyla güncellendi. S3 verilerine erişim izni vermek birkaç saniye sürebilir.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Seçilen katalogda bir kuruluş ya da grup yok.`,
  [`Secrets Manager instance`]: `Secrets Manager eşgörünümü`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Bir e-postası olmayan sahipler içeren tüketici kuruluşları seçilemez`,
  [`You currently don’t have any data.`]: `Şu anda herhangi bir veriniz yok.`,
  [`You currently don’t have any organizations.`]: `Şu anda herhangi bir kuruluşunuz yok.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `{appname} uygulamasının {plan} planına ({prodname} içinde) abonelik isteği:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `{operation} ürünü için istek: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `{appname} uygulamasını üretime yükseltme isteği`,
  [`Onboarding request for {email}`]: `{email} isteği devreye alınıyor`,
  [`Product lifecycle request`]: `Ürün yaşam çevrimi isteği`,
  [`Subscription request`]: `Abonelik isteği`,
  [`Application lifecycle request`]: `Uygulama yaşam çevrimi isteği`,
  [`Consumer Onboarding request`]: `Tüketici Devreye Alma isteği`,
  [`Originator`]: `Başlatan`,
  [`Edit analytics service advanced settings`]: `Analitik hizmeti gelişmiş ayarlarını düzenle`,
  [`Advanced settings`]: `Gelişmiş ayarlar`,
  [`Must be a positive integer`]: `Pozitif bir tamsayı olmalıdır`,
  [`Maximum value is {maxValue}`]: `Değer üst sınırı: {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `Değer üst sınırı: {maxValueDays}d ya da {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Analitik konuşlandırmasına ilişkin gelişmiş ayarları yapılandırın.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `Değer, gün için 'd' veya saat için 'h' ile bitmelidir. Örneğin, 30d ya da 12h.`,
  [`Rollover settings`]: `Baştan başlama ayarları`,
  [`Retention settings`]: `Alıkoyma ayarları`,
  [`Delete an index when it meets the following condition:`]: `Şu koşulu karşıladığında bir dizini sil:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Yönetilen dizin aşağıdaki koşullardan birini karşıladığında, yazma diğer adını yeni bir dizin olarak baştan başlat:`,
  [`Minimum document count`]: `Belge sayısı alt sınırı`,
  [`The minimum number of documents required to roll over the index.`]: `Dizini baştan başlatmak için gereken belge sayısı alt sınırı.`,
  [`Minimum index age`]: `Dizin yaşı alt sınırı`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Dizini baştan başlatmak için gereken minimum dizin yaşı. Dizin yaşı, dizin oluşturma ile şimdi arasındaki süredir. Gün için 'd'; saat için 'h' kullanın. Örneğin, 30d dizinin yaşı 30 güne ulaştığında dizinin baştan başlayacağı anlamına gelir; 96h ise, dizinin yaşı 96 saate ulaşıldığında dizinin baştan başlayacağı anlamına gelir. Değer üst sınırı {maxValueDays}d ya da {maxValueHours}h değerleridir.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Dizini silmek için gereken dizin yaşı alt sınırı. Dizin yaşı, dizin oluşturma ile şimdi arasındaki süredir. Gün için 'd'; saat için 'h' kullanın. Örneğin, 30d dizinin yaşı 30 güne ulaştığında silineceği anlamına gelir, 96h ise, dizinin yaşı 96 saate ulaştığında dizin silineceği anlamına gelir. Değer üst sınırı {maxValueDays}d ya da {maxValueHours}h değerleridir.`,
  [`Time range:`]: `Zaman aralığı:`,
  [`Time range: Before {datetime}`]: `Zaman aralığı: {datetime} öncesinde`,
  [`Time range: After {datetime}`]: `Zaman aralığı: {datetime} sonrasında`,
  [`Filter before`]: `Önce süzgeç uygula`,
  [`Filter after`]: `Sonra süzgeç uygula`,
  [`Filter exact`]: `Tam olarak süz`,
  [`Filter gt`]: `gt süz`,
  [`Filter lt`]: `It süz`,
  [`Filter equals`]: `Eşit süzgeç uygula`,
  [`Filter by`]: `Süzme temeli`,
  [`Filter out`]: `Dışarıda bırak`,
  [`Delete failed`]: `Silme başarısız oldu`,
  [`Delete warning`]: `Uyarı sil`,
  [`Could not delete saved query {queryTitle}.`]: `Kayıtlı sorgu {queryTitle} silinemedi.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `İstek başarıyla yanıtlandı, ancak {queryTitle} henüz silme işlemini bitirmedi.`,
  [`API governance`]: `API yönetişimi`,
  [`Something's wrong`]: `Bir sorun var.`,
  [`We were unable to connect to the service.`]: `Hizmetle bağlantı kurulamadı.`,
  [`Invalid date`]: `Geçersiz tarih`,
  [`Invalid time`]: `Geçersiz saat`,
  [`End date must be after the start date`]: `Bitiş tarihi, başlangıç tarihinden sonra olmalıdır`,
  [`Duplicate`]: `Çoğalt`,
  [`Duplicate query`]: `Yinelenen sorgu`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `{queryTitle} sorgusunu yinelemek istediğinizden emin misiniz?`,
  [`No queries saved`]: `Herhangi bir sorgu kaydedilmedi`,
  [`No queries shared`]: `Paylaşılan sorgu yok`,
  [`To save a query, start by creating one then save.`]: `Bir sorguyu kaydetmek için, bir sorgu yaratarak başlayın ve sonra kaydedin.`,
  [`Share a saved query and allow others to use it.`]: `Kaydedilmiş bir sorguyu paylaşın ve diğer kişilerin bu sorguyu kullanmasına izin verin.`,
  [`Query updated`]: `Sorgu güncellendi`,
  [`Query update failed`]: `Sorgu güncelleme başarısız oldu`,
  [`Share successful`]: `Paylaşım başarılı oldu`,
  [`Share failed`]: `Paylaşım başarısız oldu`,
  [`Delete successful`]: `Silme başarılı oldu`,
  [`Query unshared`]: `Sorgu paylaşımı kaldırıldı`,
  [`Unshare {queryTitle} failed`]: `{queryTitle} paylaşımının kaldırılması başarısız oldu`,
  [`Query copied`]: `Sorgu kopyalandı`,
  [`Duplicate {queryTitle} failed`]: `{queryTitle} sorgusunu yineleme başarısız oldu`,
  [`The query {queryTitle} has been updated.`]: `{queryTitle} sorgusu güncellendi.`,
  [`The query {queryTitle} has been shared.`]: `{queryTitle} sorgusu paylaşıldı.`,
  [`The query {queryTitle} has been deleted.`]: `{queryTitle} sorgusu silindi.`,
  [`The query {queryTitle} is no longer shared.`]: `{queryTitle} sorgusu artık paylaşılmıyor.`,
  [`A copy of {queryTitle} has been created.`]: `{queryTitle} sorgusunun bir kopyası oluşturuldu.`,
  [`About queries`]: `Sorgular hakkında`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Sorgular, gösterge panolarında ve Keşfet görünümünde kullanılan analitik verilerinin ince ayarına yarayan araçlardır. Bir sorgu uygulandığında, bütün gösterge panoları yalnızca seçilen verileri içerecek şekilde güncellenecektir.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `Önceden tanımlanmış zaman aralıklarından birini kullanmak ya da Özel seçeneği aracılığıyla en yakın saniyeye belirli bir zaman aralığını kullanmak mümkündür.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Alanlar yalnızca dahil edilmesi gereken belirli verileri belirtmek için kullanılabilir. Bir api olayının hemen her özniteliğine göre süzgeç uygulanabilir.`,
  [`There are then different operators available depending on the field type.`]: `Ardından alan tipine bağlı olarak kullanılabilecek farklı işleçler sunulur.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Dizgi alanlarında eşittir, değil, ile başlar, ile biter ve düzenli ifadeye izin verilir.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `Tek bir alana ilişkin değerlerin bir listesini (örneğin, API sürümlerinin listesi) sağlamak için içerir ya da içermez kullanmak mümkündür.`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP adresi alanlarında işleçlere değil, eşittire izin verilir. IP adresi alanları aynı zamanda, belirli ağların seçilebilmesine olanak tanımak üzere ağ maskelerinin kullanılabilmesi için değerin CIDR gösteriminde belirlenmesine de izin verir.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Düzenli ifadeler belirli değerleri seçmede çok güçlü olabilir, ancak, yürütmede daha yavaştır; bu nedenle, mümkünse 'ile başlar' ve 'ile biter' gibi diğer işleçleri kullanmanız önerilir.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Farklı alanlara ait sorgu süzgeçleri mantıksal AND işleciyle birleştirilir. Diğer bir deyişle, aynı alan adı için iki süzgeç eklenmesi, yalnızca her iki süzgeci de karşılayan sonuçları getirecektir.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `{{type}} sorgu parametresi, yalnızca başarı ya da hata olaylarını belirtmenin basit bir yoludur. Bu, {{statusCode}} alanını kullanır. Alan süzgeci yalnızca belirli durum kodları gerekliyse kullanılabilir. {{statusCodeTwo}} alanlarının değeri genellikle yalnızca sayısal HTTP dönüş kodu değil, {{okStatus}} gibi tam bir dizgidir.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `Sorguları daha sonra yeniden kullanmak üzere kaydetmek ve başkaları tarafından kullanılabilmesi için örneğin, sağlayıcı kuruluş ya da katalog gibi geçerli kapsam düzeyinizle paylaşmak mümkündür.`,
  [`n/a`]: `yok`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Not: 'Sağlayıcı kuruluş tanıtıcısı' ve 'sağlayıcı kuruluşu adı' alanları olay bilgi yükünde sırasıyla {{orgId}} ve {{orgName}} olarak gösterilir; 'tüketici kuruluş tanıtıcısı' ve 'tüketici kuruluş adı' alanları {{developerOrgId}} ve {{developerOrgName}} olarak gösterilir.`,
  [`No authentication token`]: `Kimlik doğrulama belirteci yok`,
  [`Contact your administrator for further assistance.`]: `Ek yardım almak için yöneticinize başvurun.`
};
