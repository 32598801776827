// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2021
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

const _ = require('lodash')

angular
  .module('apiconnect-assembly-test', ['apiconnect-assembly'])

  .controller('AssemblerTestController', [
    '$scope',
    '$rootScope',
    '$q',
    '$filter',
    '$http',
    'TestCallGenerator',
    'AssemblerTracking',
    'SwaggerOAuth',
    // ProductUtil', 'DeployedProduct',
    function(
      $scope,
      $rootScope,
      $q,
      $filter,
      $http,
      TestCallGenerator,
      AssemblerTracking,
      SwaggerOAuth
    ) {
      const $ctrl = this

      this.$onInit = () => {
        $scope.config = this.config
        $scope.gateways = this.gateways
      }

      this.$onChanges = changeSet => {
        if (changeSet.swaggerDocument) {
          $ctrl.isAPIGW =
            changeSet.swaggerDocument.currentValue['x-ibm-configuration']
              .gateway === 'datapower-api-gateway'
        }
        if (changeSet.gateways) {
          $scope.gateways = changeSet.gateways.currentValue

          // TODO(jtary) - this should be included in the catalog prop
          if ($scope.gateways.length && $scope.catalog) {
            $scope.catalog.gwServices = $scope.gateways
          }
        }

        if (changeSet.application) {
          const app = changeSet.application.currentValue

          if (app && app.credentials) {
            const {client_id, client_secret} = app.credentials

            $scope.clientId = client_id
            $scope.clientSecret = client_secret
          }

          $scope.application = app
        }

        if (changeSet.operations) {
          $scope.swaggerOperations = changeSet.operations.currentValue
          $scope.operationMap = $scope.swaggerOperations.reduce((map, op) => {
            map[op.operationId] = op
            return map
          }, {})
        }

        if (changeSet.tlsProfiles) {
          $scope.tlsProfiles = changeSet.tlsProfiles.currentValue
        }

        if (changeSet.userRegistries) {
          $scope.userRegistries = changeSet.userRegistries.currentValue
        }

        if (changeSet.publishedApis) {
          $scope.publishedApis = changeSet.publishedApis.currentValue
          if ($scope.operationRef) {
            $scope.updateConfigForOperation($scope.operationRef)
          }
        }

        if (changeSet.catalog) {
          $scope.catalog = changeSet.catalog.currentValue
        }

        if (
          changeSet.deployedProduct &&
          changeSet.deployedProduct.currentValue
        ) {
          let product = changeSet.deployedProduct.currentValue

          if (product.product) {
            product = product.product
          }
          if (!product.info && product.name && product.title) {
            product.info = {
              name: product.name,
              title: product.title,
            }
          }
          if (!product.plans) {
            product.plans = {
              default: {
                title: 'default',
              },
            }
          }
          if (product.info && product.plans) {
            $scope.product = product
            $scope.plan = product.plans.default
              ? product.plans.default.title
              : undefined
            $scope.productContainsApi = productContainsApi(
              $scope.product,
              $ctrl.swaggerDocument
            )
          }
        }

        if (
          changeSet.republishStatus &&
          changeSet.republishStatus.currentValue
        ) {
          $scope.republishingProduct =
            changeSet.republishStatus.currentValue.status
        }

        if (changeSet.apiIsOnline && changeSet.apiIsOnline.currentValue) {
          $scope.apiIsOnline = changeSet.apiIsOnline.currentValue.status
          $scope.statusIsSet = true
          if (!$scope.apiIsOnline) {
            $scope.product = null
            $scope.plan = null
          }
        }

        $scope.xhrProcessing = false
        $scope.testLoaded = true
        // $ctrl.restoreSetup()
      }

      $scope.scopes = {}
      $scope.authToken = {}
      $scope.secureGatewayDeploymentInfo = []

      $scope.onClose = () => {
        this.setTestMode({
          testMode: false,
          debugMode: false,
          clearFilter: true,
        })
      }

      $scope.repeater = {
        repeat: false,
        count: 10,
        stopOnError: true,
      }

      $scope.republishProduct = () => {
        $scope.republishingProduct = true
        $ctrl.saveAndRepublishProduct()
      }

      $scope.makeOnline = () => {
        $scope.xhrProcessing = true
        $ctrl.makeOnline()
      }

      $scope.$on('api_is_dirty', function(event, isDirty) {
        $scope.apiDirty = isDirty
        if (!isDirty) {
          // API has been saved...
          $scope.saveSinceLastPublish = true

          // after the API has been saved, check the swagger and see if the API needs a secure gateway
          $ctrl.secureGatewaySetup()

          // if the API needs a secure gateway, get secure gateways, and set the page back to 1
          if ($scope.secureGatewayDeploymentInfo.length > 0) {
            $ctrl.setCurrentPage($rootScope.offlineMode ? 2 : 1)
          }
        }
      })

      $ctrl.secureGatewaySetup = function() {
        $scope.needSecureGatewaySetup = false
        $scope.secureGatewayDeploymentInfo = []

        if ($scope.offlineMode) {
          return
        }

        const config = $ctrl.swaggerDocument[$scope.config.property]

        if (!config) {
          return
        }

        const assembly = config.assembly

        if (!assembly) {
          return
        }

        const execute = assembly.execute || []

        for (let i = 0; i < execute.length; i++) {
          const secureGatewayInfo = {
            gateway: '',
            apiId: `${$ctrl.swaggerDocument.info['x-ibm-name']}:${$ctrl.swaggerDocument.info.version}`,
            spaceGUID: $rootScope.bluemixExternalSpaceId,
            orgGUID: $rootScope.bluemixExternalOrgId,
          }

          if (
            execute[i].hasOwnProperty('invoke') &&
            execute[i].invoke['secure-gateway']
          ) {
            secureGatewayInfo.destination = execute[i].invoke['target-url']
            $scope.secureGatewayDeploymentInfo.push(secureGatewayInfo)
            $scope.needSecureGatewaySetup = true
          } else if (
            execute[i].hasOwnProperty('proxy') &&
            execute[i].proxy['secure-gateway']
          ) {
            secureGatewayInfo.destination = execute[i].proxy['target-url']
            $scope.secureGatewayDeploymentInfo.push(secureGatewayInfo)
            $scope.needSecureGatewaySetup = true
          }
        }
      }

      // I'm sorry
      function getScopes() {
        if (!$scope.scopes) return []
        const selectedScopes = []
        $scope.scopes.forEach(function(scope) {
          if ($scope.scopes[scope]) selectedScopes.push(scope)
        })
        return selectedScopes
      }

      /**
       * If OpenAPI V3, then this function is invoked to build the config object
       * instead of using the one provided by the UI SwaggerServices.js
       * Consider contributing this function to the UI.
       * @param operation
       * @param path
       * @param api (which is the swaggerDocument)
       * @param securityFlow
       * @return config
       */
      function getConfigurationForOperationOAIV3(
        operation,
        path,
        api,
        securityFlow
      ) {
        const config = {}

        // check security requirements
        config.requiresClientId = false
        config.requiresClientSecret = false
        config.requiresBasicAuth = false
        config.requiresOauth = false
        config.securityFlows = []

        config.requiresSecuritySection = false
        config.requiresIdentificationSection = false
        config.requiresAuthorizationSection = false
        config.requiresRefreshToken = false
        config.requiresUserCredentials = false
        config.requiresRedirectUri = false

        const security = {}
        let securityDefinitions = operation.security
        if (!securityDefinitions) securityDefinitions = api.security
        if (securityDefinitions) {
          securityDefinitions.forEach(function(securityDefs) {
            const label = Object.keys(securityDefs)
              .filter(function(key) {
                return key.indexOf('$$') !== 0
              })
              .join(', ')
            securityDefs.$$label = label
            config.securityFlows.push(securityDefs)

            // if a security flow has been selected, switch on all
            // the necessary security flags for the given flow
            if (securityFlow && securityFlow.$$label === securityDefs.$$label) {
              Object.keys(securityDefs).forEach(function(securityDef) {
                // The V2 securityDefinitions are in components/securitySchemes for V3
                const thisDef = api.components.securitySchemes[securityDef]
                if (!thisDef) return
                security[securityDef] = thisDef
                if (
                  thisDef.type === 'apiKey' &&
                  (thisDef.name === 'client_id' ||
                    thisDef.name === 'X-IBM-Client-Id')
                ) {
                  config.requiresClientId = true
                  config.clientIdLocation = thisDef.in
                }
                if (
                  thisDef.type === 'apiKey' &&
                  (thisDef.name === 'client_secret' ||
                    thisDef.name === 'X-IBM-Client-Secret')
                ) {
                  config.requiresClientSecret = true
                  config.clientSecretLocation = thisDef.in
                }
                // The type 'basic' has been changed to type 'http' and scheme 'basic'
                // https://swagger.io/blog/news/whats-new-in-openapi-3-0/
                if (thisDef.type === 'type' && thisDef.scheme === 'basic') {
                  config.requiresBasicAuth = true
                }
                if (thisDef.type === 'oauth2') {
                  config.requiresOauth = true
                  config.requiresClientId = true
                  config.oauthFlow = thisDef.flow
                  if (
                    thisDef.flow === 'application' ||
                    thisDef.flow === 'accessCode' ||
                    thisDef.flow === 'password'
                  ) {
                    config.requiresClientSecret = true
                  }
                  if (
                    thisDef.flow === 'implicit' ||
                    thisDef.flow === 'accessCode'
                  ) {
                    config.requiresRedirectUri = true
                  }
                  if (thisDef.authorizationUrl) {
                    config.oauthAuthUrl = thisDef.authorizationUrl
                    config.requiresAuthorizationSection = true
                  }
                  if (thisDef.tokenUrl) {
                    config.oauthTokenUrl = thisDef.tokenUrl
                    config.requiresAuthorizationSection = true
                  }
                  if (
                    thisDef.scopes &&
                    Object.keys(thisDef.scopes).length !== 0
                  ) {
                    config.oauthScopes = Object.keys(thisDef.scopes)
                  }
                }
              })
            }
          })
        }
        if (!_.isEmpty(security)) config.security = security

        // any security requirements at all?
        if (
          config.requiresClientId ||
          config.requiresClientSecret ||
          config.requiresBasicAuth ||
          config.requiresOauth
        ) {
          config.requiresSecuritySection = true
        }

        // any requirement for identification?
        if (config.requiresClientId || config.requiresClientSecret) {
          config.requiresIdentificationSection = true
        }

        // any requirement for authorization?
        if (config.requiresBasicAuth || config.oauthFlow === 'password') {
          config.requiresAuthorizationSection = true
          config.requiresUserCredentials = true
        }

        // any requirement for token refresh?
        if (config.oauthFlow === 'accessCode') {
          config.requiresRefreshToken = true
        }

        // figure out which parameters apply here
        let parameters = []
        const dereferencedParameters = {}
        if (operation.parameters)
          parameters = parameters.concat(operation.parameters)
        if (path.parameters) parameters = parameters.concat(path.parameters)

        for (let i = 0; i < parameters.length; i++) {
          parameters[i] = JSON.parse(JSON.stringify(parameters[i]))
        }

        // Add a parameter representing the V3 request body
        if (operation.requestBody && operation.requestBody.content) {
          const content =
            operation.requestBody.content[
              Object.keys(operation.requestBody.content)[0]
            ]
          parameters.push({
            name: 'body',
            in: 'body',
            description: content.description,
            required: operation.requestBody.required,
            schema: content.schema,
          })
        }

        // dereference parameters
        parameters.forEach(function(parameter) {
          if (parameter.$ref) {
            // dereference $ref
            let parameterName = parameter.$ref.split('/').pop()
            parameterName = parameter.$ref.replace(
              '#/components/parameters/',
              ''
            )
            if (
              api.components.parameters &&
              api.components.parameters[parameterName]
            ) {
              dereferencedParameters[parameterName] =
                api.parameters[parameterName]
            } else {
              console.warn(`Missing reference: ${parameter.$ref}`)
              dereferencedParameters[parameterName] = {
                name: parameterName,
                description: `${$filter('translate')(
                  'explorer_missing_parameter'
                )}: ${parameter.$ref}`,
                schema: {
                  type: 'unknown',
                },
              }
            }
            if (dereferencedParameters[parameterName]) {
              dereferencedParameters[parameterName].$$tmpId = Math.random()
            }
          } else {
            dereferencedParameters[parameter.name] = parameter
            dereferencedParameters[parameter.name].$$tmpId = Math.random()
          }
        })
        if (!_.isEmpty(dereferencedParameters)) {
          config.parameters = dereferencedParameters
          const asArray = []
          Object.keys(dereferencedParameters).forEach(function(parameterName) {
            asArray.push(dereferencedParameters[parameterName])
          })
          config.parametersArray = asArray
          config.requiresParametersSection = true
        }

        // any SOAP specific content?
        if (operation['x-ibm-soap']) {
          if (operation['x-ibm-soap']['soap-action'] !== undefined) {
            config.soapAction = operation['x-ibm-soap']['soap-action']
          }
        }

        // content types
        if (operation.requestBody && operation.requestBody.content) {
          config.contentTypes = Object.keys(operation.requestBody.content)
        } else {
          config.contentTypes = ['application/json']
        }
        if (operation.responses) {
          config.accepts = []
          for (const response in operation.responses) {
            if (operation.responses[response].content) {
              for (const contentType in operation.responses[response].content) {
                if (config.accepts.indexOf(contentType) < 0) {
                  config.accepts.push(contentType)
                }
              }
            }
          }
        } else {
          config.accepts = ['application/json']
        }
        return config
      }

      // check security requirements
      $ctrl.updateConfig = function() {
        if (!$scope.operation) return
        // The TestCallGenerator only understands V2,
        // Use our config creator if V3.  May want to contribute this to UI.
        if ($scope.swaggerDocument.openapi) {
          $ctrl.config = getConfigurationForOperationOAIV3(
            $scope.operation,
            $scope.path,
            $scope.swaggerDocument,
            $scope.securityFlow
          )
        } else {
          $ctrl.config = TestCallGenerator.getConfigurationForOperation(
            $scope.operation,
            $scope.path,
            $scope.swaggerDocument,
            $scope.publishedApis,
            $scope.securityFlow
          )
        }
        // pre-select all scopes
        if ($ctrl.config.oauthScopes) {
          $ctrl.config.oauthScopes.forEach(function(scope) {
            $scope.scopes[scope] = true
          })
        }

        // send debug header for assembly when not in offline mode
        $ctrl.config.sendDebugHeader = !$scope.offlineMode
      }

      $scope.setOperationFilter = function(operation) {
        $ctrl.setOperationFilter({operation})
      }

      $scope.getSchemes = function(swaggerDocument) {
        let schemes = ['https']

        if (swaggerDocument.schemes && swaggerDocument.schemes.length) {
          schemes = swaggerDocument.schemes
        }

        return schemes
      }

      $scope.getTargetHost = function(draftApi) {
        if (localStorage.getItem('IS_DESIGNER') === 'true') {
          console.log(`isApiRunning - getTargetHost 1: ${$scope.isApiRunning}`)
          $scope.isApiRunning = true
          console.log(`isApiRunning - getTargetHost 2: ${$scope.isApiRunning}`)
          return localStorage.getItem('API_BASE_URL')
        }

        const defaultHost = localStorage.getItem('apim_local_gateway')
        const api =
          $scope.publishedApis &&
          $scope.publishedApis.find(api => {
            const conApi = api.consumer_api
            return (
              conApi.info['x-ibm-name'] === draftApi.info['x-ibm-name'] &&
              conApi.info.version === draftApi.info.version
            )
          })

        if (api) {
          const servers = api.consumer_api['x-ibm-configuration'].servers

          if (servers.length) {
            console.log(
              `isApiRunning - getTargetHost 3: ${$scope.isApiRunning}`
            )
            $scope.isApiRunning = true
            console.log(
              `isApiRunning - getTargetHost 4: ${$scope.isApiRunning}`
            )
            return servers[0].url
          }
        }

        console.log(`isApiRunning - getTargetHost 5: ${$scope.isApiRunning}`)
        $scope.isApiRunning = false
        console.log(`isApiRunning - getTargetHost 6: ${$scope.isApiRunning}`)
        return defaultHost
      }

      $scope.getTargetUrlTemplate = function(swaggerDocument, operation) {
        $scope.schemes = $scope.getSchemes(swaggerDocument)
        $scope.scheme = $scope.schemes

        // if this is unenforced, try to use the host field
        if (
          swaggerDocument['x-ibm-configuration'] &&
          swaggerDocument['x-ibm-configuration'].enforced === false
        ) {
          baseUrl = swaggerDocument.host
            ? `${$scope.scheme}://${swaggerDocument.host}`
            : defaultHost
        }

        let baseUrl = $scope.getTargetHost(swaggerDocument)
        console.log(`baseUrl - getTargetHost 1: ${baseUrl}`)
        let targetUrlTemplate = baseUrl + operation.path
        console.log(`targetUrlTemplate - getTargetHost 1: ${targetUrlTemplate}`)

        // is this SOAP? if so, ditch the operationId from the end...
        if (
          operation['x-ibm-soap'] &&
          operation.operationId &&
          _.endsWith(targetUrlTemplate, `/${operation.operationId}`)
        ) {
          targetUrlTemplate = targetUrlTemplate.substring(
            0,
            targetUrlTemplate.length - (operation.operationId.length + 1)
          )
        }

        return targetUrlTemplate
      }

      $ctrl.config = {}
      $scope.updateConfigForOperation = function(operation) {
        console.log(`operation - updateConfigForOperation 1: ${operation}`)
        if (!operation) {
          return
        }

        $scope.setOperationFilter(operation)

        console.log(
          `selectedOperation - updateConfigForOperation 1: ${$scope.selectedOperation}`
        )
        if (typeof operation === 'string') {
          operation = $scope.operationMap[$scope.selectedOperation]
          $scope.selectedOperation = operation
          return
        }
        console.log(
          `selectedOperation - updateConfigForOperation 2: ${$scope.selectedOperation}`
        )

        const operationRef = operation.operationId
          ? $scope.operationMap[operation.operationId]
          : operation

        var operation =
          $ctrl.swaggerDocument.paths[operationRef.path][operationRef.verb]
        operation.consumes = $ctrl.swaggerDocument.consumes
        operation.produces = $ctrl.swaggerDocument.produces

        $scope.operation = operation
        $scope.swaggerDocument = $ctrl.swaggerDocument
        $scope.path = $ctrl.swaggerDocument.paths[operationRef.path]

        // TestCallGenerator expects this to be on scope...
        // todo(jtary) -- fix that stupid assumption.
        $scope.operationRef = operationRef

        console.log(
          `isApiRunning - updateConfigForOperation 1: ${$scope.isApiRunning}`
        )
        $scope.targetUrlTemplate = $scope.getTargetUrlTemplate(
          $ctrl.swaggerDocument,
          operationRef
        )
        console.log(
          `isApiRunning - updateConfigForOperation 2: ${$scope.isApiRunning}`
        )

        $ctrl.updateConfig()

        // seed scopes if required
        if ($ctrl.config.requiresOauth && $ctrl.config.oauthScopes) {
          $scope.scopes = $ctrl.config.oauthScopes
        }
      }

      $scope.$watch('selectedOperation', $scope.updateConfigForOperation)
      $scope.$watch('securityFlow', $ctrl.updateConfig)

      $ctrl.invoke = function() {
        TestCallGenerator.invoke($scope, $ctrl.config, $scope.repeater)
      }

      $scope.setAuthToken = function(data) {
        $scope.authToken = data
      }

      $scope.setAuthCode = function(data) {
        $scope.authCode = data
      }

      $scope.setAuthError = function(error) {
        $scope.authError = error
      }

      $scope.clearAuthError = function() {
        delete $scope.authError
      }

      $ctrl.authorize = function() {
        const scopes = getScopes()
        $scope.clearAuthError()
        SwaggerOAuth.authorize(
          $ctrl.config,
          $scope.clientId,
          $scope.clientSecret,
          scopes,
          $scope.username,
          $scope.password,
          $scope.redirectUri
        ).then(
          function(response) {
            angular.extend($scope.authToken, response.data)
          },
          function(error) {
            $scope.setAuthError(error)
          }
        )
      }

      $ctrl.getToken = function() {
        const scopes = getScopes()
        $scope.clearAuthError()
        SwaggerOAuth.getToken(
          $ctrl.config,
          $scope.authToken,
          $scope.clientId,
          $scope.clientSecret,
          scopes,
          $scope.redirectUri
        ).then(
          function(response) {
            angular.extend($scope.authToken, response.data)
          },
          function(error) {
            $scope.setAuthError(error)
          }
        )
      }

      $ctrl.refreshToken = function() {
        $scope.clearAuthError()
        SwaggerOAuth.refreshToken(
          $ctrl.config,
          $scope.authToken,
          $scope.clientId,
          $scope.clientSecret
        ).then(
          function(data) {
            console.log(data)
          },
          function(error) {
            $scope.setAuthError(error)
          }
        )
      }

      $scope.$watch('catalog', function() {
        if (!$scope.catalog) {
          return
        }
        if (
          $scope.catalog.testAppEnabled === true &&
          $scope.catalog.testAppCredentials
        ) {
          // we have test app credentials - further setup not required
          $scope.testAppCredentials = true
          delete $scope.application
          delete $scope.plan
        }
      })

      $ctrl.gwServiceChange = function(targetChanged) {
        if (!$scope.target) {
          return
        }
        if (typeof localStorage === 'undefined') {
          return
        }

        $scope.matchingGwServices = []
        const services = $scope.target.catalog.gwServices

        if (!services) {
          // No service assigned to space
          $scope.gwServiceTarget = ''
          return
        }

        services.forEach(function(service) {
          $scope.gateways.forEach(function(candidateGateway) {
            if (candidateGateway.id === service.id) {
              $scope.matchingGwServices.push(candidateGateway) //Find matching services
            }
          })
        })

        $scope.gwServiceTarget = $scope.matchingGwServices[0].name //Auto select first gateway service
      }

      $ctrl.secureGatewayDeploymentInfoChange = function() {
        if (!$scope.secureGatewayDeploymentInfo) {
          return
        }

        let numberOfGatewaysNotAssigned = 0

        for (let i = 0; i < $scope.secureGatewayDeploymentInfo.length; i++) {
          if ($scope.secureGatewayDeploymentInfo[i].gateway.length === 0) {
            numberOfGatewaysNotAssigned++
          }
        }

        if (numberOfGatewaysNotAssigned === 0) {
          $scope.needSecureGatewaySetup = false
        }
      }

      $ctrl.testTabUrl = `${window.parent.location.href
        .split('/')
        .slice(0, -2)
        .join('/')}/debug`
      $ctrl.closeWarning = function() {
        document.getElementById('testDepracationWarning').style.display = 'none'
      }

      $ctrl.operationChange = function() {
        console.log(
          `selectedOperation - operationChange 1: ${$scope.selectedOperation}`
        )
        if (!$scope.selectedOperation) {
          return
        }
        if (typeof localStorage === 'undefined') {
          return
        }
        $scope.parameterValues = {}
        localStorage.setItem(
          'apim.test.operation',
          `${$scope.selectedOperation.verb} ${$scope.selectedOperation.path}`
        )
        console.log(
          `selectedOperation - operationChange 2: ${$scope.selectedOperation}`
        )
      }

      $ctrl.loadDebugData = function() {
        delete $scope.debugData
        if (!$scope.response || !$scope.response.transactionId) {
          return
        }
        $scope.loadingDebugData = true
        $http
          .get(
            `proxy/orgs/${$ctrl.orgId}/analytics/query?eventType=apievent&fields=ALL&size=1000&sort=datetime&timeRange=QUARTER&timeZoneOffset=0&queryString=debug.transid:"${$scope.response.transactionId}"`
          )
          .then(
            function(data) {
              $scope.loadingDebugData = false
              if (
                data.data &&
                data.data.hits &&
                data.data.hits.length > 0 &&
                data.data.hits[0].debug
              ) {
                $scope.debugData = data.data.hits[0].debug
              }
            },
            function() {
              $scope.loadingDebugData = false
            }
          )
      }

      $ctrl.focusIndex = -1
      $ctrl.previousPolicy = function() {
        if ($ctrl.focusIndex < 0) {
          return
        }
        $ctrl.focusIndex--
        if ($ctrl.focusIndex === -1) {
          $('apim-assembler')[0].focus()
        }
        if ($ctrl.focusIndex === 0) {
          $('apim-assembler .inputNode')[0].focus()
        }
        // else try to find the policy with the correct name...
        $('apim-assembler apim-node .policyName:contains(PROXY)').parent()
      }

      $ctrl.nextPolicy = function() {
        $ctrl.focusIndex++
        if ($ctrl.focusIndex === 0) {
          $('apim-assembler .inputNode')[0].focus()
        }
      }

      function getDebugDataByName(name) {
        const filtered = $scope.debugData.filter(function(data) {
          return data.name === name
        })
        if (filtered.length > 0) {
          return filtered[0]
        }
        return null
      }

      function productContainsApi(product, api) {
        const apiId = `${api.info['x-ibm-name']}:${api.info.version}`

        if (product && product.apis) {
          const match = Object.keys(product.apis).find(key => {
            return product.apis[key].name == apiId
          })

          return Boolean(match)
        }

        return false
      }

      function productIsPublished(product) {
        return true
      }

      $ctrl.showDebugForNode = function(event, node) {
        // try to tie the selected node to an entry in debug data
        let debugData
        if (node === 'input') {
          debugData = getDebugDataByName('Request')
          if (!debugData) {
            debugData = getDebugDataByName('API Information')
          }
        } else if (node === 'output') {
          debugData = getDebugDataByName('Response')
        } else {
          debugData = getDebugDataByName(node.$$type)
          // do something hacky for now
          if (node.title && node.title.toLowerCase().indexOf('proxy') > -1) {
            // probably a proxy then...
            debugData = getDebugDataByName('Assembly Proxy')
          }
        }
        if (debugData && debugData.name) {
          // scroll into view
          $scope.selectedDebugData = debugData
          $(
            `apim-assembly-test .testItemHeader:contains(${debugData.name})`
          )[0].scrollIntoView()
        }
      }

      $scope.$on('node-selected', $ctrl.showDebugForNode)
    },
  ])

angular
  .module('apiconnect-assembly-test')
  .controller('AssemblerTestParameterController', [
    '$scope',
    function($scope) {
      const $ctrl = this
      let swaggerDocument = null
      let testScope = $scope

      // TODO(jtary) - ugly hack to find the swagger document
      while (swaggerDocument == null && testScope.$parent) {
        swaggerDocument = testScope.swaggerDocument
        testScope = testScope.$parent
      }

      $scope.canGenerate = true
      if ($scope.parameter && $scope.parameter.format === 'JSON') {
        // generator doesn't know this type...
        $scope.canGenerate = false
      }
      if ($scope.parameter && $scope.parameter.type === 'boolean') {
        // generating booleans isn't very valuable...
        $scope.canGenerate = false
      }

      // take a copy of the schema so we can de-ref for display without touching the model
      if ($scope.parameter && $scope.parameter.schema) {
        $scope.schema = angular.copy($scope.parameter.schema)
        if ($scope.schema.example) {
          $scope.parameterValues[$scope.parameter.name] = $scope.schema.example
        }
      }

      const path = $scope.operationRef
        ? $scope.operationRef.path
        : $scope.pathName
      const verb = $scope.operationRef ? $scope.operationRef.verb : $scope.verb

      $ctrl.generateExample = function() {
        const example = window.exampleGenerator.generateExampleParameter(
          $scope.swaggerDocument,
          path,
          verb,
          $scope.parameter,
          $scope.contentTypeHeader
        )
        // SOAP messages do not have comments, but most SOAP endpoints are smart enough to discard comments if they are between elements.
        // But if the comment is within an atomic value, then there may be problems.
        // To avoid usage issues, the <!-- mandatory --> comment (which was generated by apiconnect-wsdl) is removed if it is
        // not at the end of a line.  The mandatory comment does not provide much value, and has been a stumbling block for users
        // who fail to remove it from the message before invoking the api.
        $scope.parameterValues[$scope.parameter.name] =
          typeof example === 'string'
            ? example.replace(/(<!-- mandatory -->)(.)/g, '$2')
            : example
      }
    },
  ])

angular
  .module('apiconnect-assembly-test')
  .controller('AssemblyTestDebugDataController', [
    '$scope',
    function($scope) {
      $scope.yamlValue = jsyaml.dump(
        angular.fromJson(angular.toJson($scope.data)),
        {lineWidth: -1}
      )
    },
  ])
