// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

'use strict'

function ConditionController($scope) {
  $scope.expression = window.jsep($scope.condition)
  $scope.$watch(
    'expression',
    function() {
      const expressionString = window.jseb($scope.expression)
      $scope.$emit('condition-change', expressionString)
      console.log(expressionString)
    },
    true
  )
  function pruneExpression(tree, leaf) {
    if (!tree || !leaf) return tree
    if (angular.equals(tree.left, leaf)) return tree.right
    if (angular.equals(tree.right, leaf)) return tree.left
    tree.left = pruneExpression(tree.left, leaf)
    tree.right = pruneExpression(tree.right, leaf)
    return tree
  }
  $scope.deleteExpression = function(expression) {
    const newExpression = pruneExpression($scope.expression, expression)
    for (const prop in $scope.expression) {
      if ($scope.expression.hasOwnProperty(prop)) {
        delete $scope.expression[prop]
      }
    }
    angular.extend($scope.expression, newExpression)
  }
}

angular
  .module('apiconnect-assembly')
  .controller('ConditionController', ['$scope', ConditionController])

function ConditionExpressionController($scope, Expressions) {
  $scope.$watch('expression', function() {
    $scope.expressionString = window.jseb($scope.expression)

    if (Expressions.isOperationExpression($scope.expression)) {
      $scope.isOperation = true
      $scope.operationString = Expressions.getOperationString($scope.expression)
    } else {
      $scope.isOperation = false
    }
  })

  $scope.expand = function(expanded) {
    $scope.expanded = expanded
  }

  $scope.isLogicalExpression = function() {
    return $scope.expression.type === 'LogicalExpression'
  }
  $scope.changeOperator = function(operator) {
    $scope.expression.operator = operator
  }
  $scope.insertLogicalExpression = function(operator) {
    const currentExpression = angular.copy($scope.expression)
    for (const prop in $scope.expression) {
      if ($scope.expression.hasOwnProperty(prop)) {
        delete $scope.expression[prop]
      }
    }
    // left operator right type
    $scope.expression.operator = operator
    $scope.expression.type = 'LogicalExpression'
    const newExpression = window.jsep('$(operation.verb) == "get"')
    newExpression.$$edit = true
    $scope.expression.right = newExpression
    $scope.expression.left = currentExpression
  }

  $scope.commitExpression = function() {
    try {
      const newExpression = window.jsep($scope.expressionString)
      for (const prop in $scope.expression) {
        if ($scope.expression.hasOwnProperty(prop)) {
          delete $scope.expression[prop]
        }
      }
      angular.extend($scope.expression, newExpression)
    } catch (e) {}
  }
}

angular
  .module('apiconnect-assembly')
  .controller('ConditionExpressionController', [
    '$scope',
    'Expressions',
    ConditionExpressionController,
  ])

function ConditionExpressionLeftController($scope, $controller) {
  $scope.parentExpression = $scope.expression
  $scope.expression = $scope.parentExpression.left
  $scope.$watch(
    'parentExpression',
    function() {
      $scope.expression = $scope.parentExpression.left
    },
    true
  )
  $controller('ConditionExpressionController', {$scope})
}

angular
  .module('apiconnect-assembly')
  .controller('ConditionExpressionLeftController', [
    '$scope',
    '$controller',
    ConditionExpressionLeftController,
  ])

function ConditionExpressionRightController($scope, $controller) {
  $scope.parentExpression = $scope.expression
  $scope.expression = $scope.parentExpression.right
  $scope.$watch(
    'parentExpression',
    function() {
      $scope.expression = $scope.parentExpression.right
    },
    true
  )
  $controller('ConditionExpressionController', {$scope})
}

angular
  .module('apiconnect-assembly')
  .controller('ConditionExpressionRightController', [
    '$scope',
    '$controller',
    ConditionExpressionRightController,
  ])
