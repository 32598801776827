// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

angular.module('apiconnect-assembly').factory('SchemaReferences', function() {
  const self = this

  function findAllOfExtendingTypesHelper(
    refToMatch,
    definitions,
    matches,
    parentTypes
  ) {
    Object.keys(definitions).forEach(function(definitionName) {
      const definition = definitions[definitionName]
      if (!definition.allOf) return false
      const filtered = definition.allOf.filter(function(allOfSchema) {
        return allOfSchema.$ref === refToMatch
      })
      if (filtered.length > 0) {
        if (!matches[definitionName]) {
          const prunedDefinition = angular.copy(definition)
          // strip out the matching allOf
          prunedDefinition.allOf = prunedDefinition.allOf.filter(function(
            allOfSchema
          ) {
            if (allOfSchema.$ref !== refToMatch) {
              allOfSchema.$defName = definitionName
              allOfSchema.$$extendedParents = parentTypes
              return true
            }
            return false
          })
          matches[definitionName] = prunedDefinition
          // check for any nested sub-types
          const lastSlash = refToMatch.lastIndexOf('/')
          const coreRef = refToMatch.substring(0, lastSlash + 1)
          const subTypeRef = coreRef + definitionName
          const parentSubTypes = {
            parentTypes,
            types: [],
          }
          parentSubTypes.types = parentSubTypes.types.concat(
            prunedDefinition.allOf
          )
          findAllOfExtendingTypesHelper(
            subTypeRef,
            definitions,
            matches,
            parentSubTypes
          )
        }
      }
    })
  }

  /*
   * Insert referenced schema in place of $ref, where the definitions have been provided
   */
  function findAllOfExtendingTypes(schema, swaggerDocument) {
    if (!schema.$ref) {
      return
    }
    const refToMatch = schema.$ref
    const matchingDefinitions = {}

    // OAI V2
    if (swaggerDocument.definitions) {
      findAllOfExtendingTypesHelper(
        refToMatch,
        swaggerDocument.definitions,
        matchingDefinitions,
        null
      )
    }
    if (
      swaggerDocument['x-ibm-configuration'] &&
      swaggerDocument['x-ibm-configuration'].targets
    ) {
      Object.keys(swaggerDocument['x-ibm-configuration'].targets).forEach(
        function(targetName) {
          const target =
            swaggerDocument['x-ibm-configuration'].targets[targetName]
          if (target.definitions) {
            findAllOfExtendingTypesHelper(
              refToMatch,
              target.definitions,
              matchingDefinitions,
              null
            )
          }
        }
      )
    }

    // OAI V3
    if (swaggerDocument.components && swaggerDocument.components.schemas) {
      findAllOfExtendingTypesHelper(
        refToMatch,
        swaggerDocument.components.schemas,
        matchingDefinitions,
        null
      )
    }
    if (
      swaggerDocument['x-ibm-configuration'] &&
      swaggerDocument['x-ibm-configuration'].targets
    ) {
      Object.keys(swaggerDocument['x-ibm-configuration'].targets).forEach(
        function(targetName) {
          const target =
            swaggerDocument['x-ibm-configuration'].targets[targetName]
          if (target.components && target.components.schemas) {
            findAllOfExtendingTypesHelper(
              refToMatch,
              target.components.schemas,
              matchingDefinitions,
              null
            )
          }
        }
      )
    }
    return matchingDefinitions
  }

  function findRef(ref, swaggerDocument, references) {
    if (references && references[ref]) {
      return angular.copy(references[ref])
    }
    const sections = ref.split('/')
    let theSchema = swaggerDocument
    for (let i = 1; i < sections.length; i++) {
      theSchema = theSchema[sections[i]]
      if (!theSchema) return
    }
    const refSchema = angular.copy(theSchema)
    if (refSchema.properties) {
      Object.keys(refSchema.properties).forEach(function(propertyName) {
        refSchema.properties[propertyName].name = propertyName
      })
    }
    return refSchema
  }

  /*
   * Insert referenced schema in place of $ref, where the swaggerDocument have been provided
   */
  self.unwindRefs = function(schema, swaggerDocument, references) {
    if (!schema || !swaggerDocument) {
      return
    }

    // is it already unwound?
    if (schema.$$unwound) return
    schema.$$unwound = true

    // If this is a reference to another definition, resolve it before doing any other processing
    if (schema.$ref) {
      const refSchema = findRef(schema.$ref, swaggerDocument, references)
      if (refSchema) angular.extend(schema, refSchema)
    }
    if (schema.anyOf) {
      schema.anyOf.forEach(function(anyOfSchema) {
        self.unwindRefs(anyOfSchema, swaggerDocument, references)
      })
    }
    if (schema.oneOf) {
      schema.oneOf.forEach(function(oneOfSchema) {
        self.unwindRefs(oneOfSchema, swaggerDocument, references)
      })
    }
    if (schema.items) {
      self.unwindRefs(schema.items, swaggerDocument, references)
    }
    if (schema.allOf) {
      // Make sure all of the refs are unwound
      schema.allOf.forEach(function(allOfSchema) {
        self.unwindRefs(allOfSchema, swaggerDocument, references)
      })
      // There are two patterns for expressing polymorphism.
      // - definition D has an x-ibm-discriminator and suitable
      //   extensions reference D as the first reference in their allOf list.
      // - definition D has a oneOf, and each oneOf element is one of the
      //   the suitable extensions.
      // These two patterns are mutually exclusive.
      // The following code is for the first pattern.
      // The first item in the allOf
      // might be the polymporhpic base.
      // When viewing the base through the allOf, we don't want
      // to see the polymorphic information.  Use the $$viewNoPoly.
      const schemaBase = schema.allOf[0]
      if (schemaBase.$$viewNoPoly) {
        schema.allOf[0] = schemaBase.$$viewNoPoly
      }
    }

    const viewNoPoly = {}
    let polyTypes
    if (schema['x-ibm-discriminator']) {
      // Polymorphism Pattern 1: allOf with x-ibm-discriminator
      schema.$$polyPattern = 'allOf'
      polyTypes = []
      const matches = findAllOfExtendingTypes(schema, swaggerDocument)
      if (matches) {
        Object.keys(matches).forEach(function(definitionName) {
          polyTypes.push(matches[definitionName])
        })
      }
      if (polyTypes.length > 0) {
        schema.$$polyTypes = polyTypes

        // When this schema is viewed within a hierarchy (i.e. through an allOf)
        // then we don't want to see the polymorphic information.
        // A $$viewPoly object is created that contains the original view of the object.
        angular.extend(viewNoPoly, schema)
        delete viewNoPoly['x-ibm-discriminator']
        delete viewNoPoly.discriminator
        delete viewNoPoly.$$polyTypes
        schema.$$viewNoPoly = viewNoPoly
        schema.$$viewNoPoly.$$viewPoly = schema // backpointer to access poly view from noPoly view

        // For polymorphism,
        //  - use properties to define the properties in this type and ancestor types
        //  - use allOf to show the extended types
        schema.isPolymorphicView = true // Indicate that this is a poly view, thus allOf contains extended types
        schema.type = 'object'
        schema.properties = schema.properties || {}
        schema.required = schema.required || {}
        schema.properties['x-ibm-discriminator'] = {
          type: 'string',
        }
        // If there is an allOf, gather the properties and required
        // values.
        if (schema.allOf) {
          schema.allOf.forEach(function(obj) {
            let prop
            // If the parent and grandparent ancestors are polymorphic
            // then the parent already has collected all of the
            // properties of the ancestors in its polymoprhic view.
            if (obj.$$viewPoly) {
              obj = obj.$$viewPoly
            }
            if (obj.properties) {
              for (prop in obj.properties) {
                schema.properties[prop] = obj.properties[prop]
              }
            }
            if (obj.required) {
              for (prop in obj.required) {
                schema.required[prop] = obj.required[prop]
              }
            }
          })
        }

        // Now fill the allOf with the polyTypes
        schema.allOf = []
        schema.$$polyTypes.forEach(function(extendingType) {
          schema.allOf = schema.allOf.concat(extendingType.allOf)
        })
      } else {
        schema.$$polyTypes = []
      }
    } else if (schema.discriminator) {
      // Polymorphism Pattern 2: oneOf with discriminator
      schema.$$polyPattern = 'oneOf'

      polyTypes = []
      schema.oneOf.forEach(function(schemaOneOf) {
        const schema = angular.copy(schemaOneOf)
        if (schema.$ref) {
          schema.$defName = schema.$ref.substring(
            schema.$ref.lastIndexOf('/') + 1
          )
        }
        polyTypes.push(schema)
      })
      schema.$$polyTypes = polyTypes

      // When this schema is viewed within a hierarchy (i.e. through an allOf)
      // then we don't want to see the polymorphic information.
      // A $$viewPoly object is created that contains the original view of the object.
      angular.extend(viewNoPoly, schema)
      delete viewNoPoly['x-ibm-discriminator']
      delete viewNoPoly.discriminator
      delete viewNoPoly.$$polyTypes
      schema.$$viewNoPoly = viewNoPoly
      schema.$$viewNoPoly.$$viewPoly = schema // backpointer to access poly view from noPoly view
      schema.$$discriminatorMapping = Object.keys(schema.discriminator.mapping)

      // For polymorphism,
      //  - use properties to define the properties in this type and ancestor types
      //  - use allOf to show the extended types
      schema.isPolymorphicView = true // Indicate that this is a poly view, thus allOf contains extended types
      schema.type = 'object'
      schema.properties = schema.properties || {}
      schema.required = schema.required || {}
      if (schema.discriminator) {
        schema.properties[schema.discriminator.propertyName] = {
          type: 'string',
        }
      }

      // Now fill the allOf with the polyTypes
      schema.oneOf = polyTypes
    }
  }

  function propertyExistsInSchema(
    property,
    schema,
    swaggerDocument,
    references,
    keepDiscriminator
  ) {
    self.unwindRefs(schema, swaggerDocument, references)

    // have we matched the whole thing?
    // properties may themselves contain "." characters...
    const fullPath = property.join('.')
    let title = schema.name || schema.$$title || schema.title
    if (title) {
      if (title === fullPath) {
        return true
      }

      // perhaps we're array-mapping a non-repeating type...
      title += '.$item'
      if (title === fullPath) {
        return true
      }
    }

    // else match parts
    title = schema.name || schema.$$title || schema.title
    // if we are in the right object, or if the object itself is untitled
    // either due to a missing title or it's use as an anonymous array item
    if (title === property[0] || !title || property[0] === '$item') {
      if (property.length === 1) return true
      // look through properties, which may contain "."
      if (schema.properties) {
        let x = 1
        let propertySegment = property[x]
        while (x < property.length) {
          x++
          if (
            keepDiscriminator &&
            (schema['x-ibm-discriminator'] ||
              (schema.discriminator &&
                schema.discriminator.propertyName === 'x-ibm-discriminator'))
          ) {
            return true
          }
          if (schema.properties[propertySegment]) {
            return propertyExistsInSchema(
              [propertySegment].concat(property.slice(x)),
              schema.properties[propertySegment],
              swaggerDocument,
              references,
              keepDiscriminator
            )
          }
          // maybe we're looking at an attribute?
          if (propertySegment.indexOf('@') === 0) {
            const attributeName = propertySegment.substring(1)
            if (
              schema.properties[attributeName] &&
              schema.properties[attributeName].xml &&
              schema.properties[attributeName].xml.attribute === true
            ) {
              return true
            }
          }
          propertySegment += `.${property[x]}`
        }
      }
      if (schema.items) {
        if (property[1] === '$item') return true
        property[0] = '$item'
        return propertyExistsInSchema(
          property,
          schema.items,
          swaggerDocument,
          references,
          keepDiscriminator
        )
      }
      if (schema.allOf || schema.anyOf || schema.oneOf) {
        const schemaArray = schema.allOf || schema.anyOf || schema.oneOf
        for (let i = 0; i < schemaArray.length; i++) {
          if (
            propertyExistsInSchema(
              property,
              schemaArray[i],
              swaggerDocument,
              references,
              keepDiscriminator
            )
          ) {
            return true
          }
        }
      }
    }
    return false
  }

  // Separates out property name into correct segments even if the name(s) contain periods (.) apiconnect-assembly/#446
  // abc\\.def.item1.ghi\\.jkl -> [abc.def, item1, ghi.jkl]
  self.splitProperties = function(property) {
    const split = property.split('.')
    const propertyArray = []
    let prop = ''
    let j

    for (j = 0; j < split.length; j++) {
      if (split[j].indexOf('\\') !== -1) {
        // Check if split property is actually a part of a period containing property
        prop += `${split[j].slice(0, -1)}.`
      } else {
        // append next part of period containing property, then push onto the array as its own segment.
        prop += split[j]
        propertyArray.push(prop)
        prop = ''
      }
    }

    return propertyArray
  }

  self.propertyExistsInSchemas = function(
    property,
    schemas,
    swaggerDocument,
    keepDiscriminator
  ) {
    if (property.indexOf('#/') === 0) {
      // this is an absolute reference - strip it off
      property = property.substring(2)
    }
    for (let i = 0; i < schemas.length; i++) {
      if (
        propertyExistsInSchema(
          self.splitProperties(property),
          schemas[i],
          swaggerDocument,
          null,
          keepDiscriminator
        )
      )
        return true
    }
    return false
  }

  return self
})
