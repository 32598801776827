// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2021
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

import jsyaml from 'js-yaml'

const basicTypes = {
  integer: {
    type: 'integer',
    format: 'int32',
  },
  long: {
    type: 'integer',
    format: 'int64',
  },
  float: {
    type: 'number',
    format: 'float',
  },
  double: {
    type: 'number',
    format: 'double',
  },
  string: {
    type: 'string',
  },
  byte: {
    type: 'string',
    format: 'byte',
  },
  binary: {
    type: 'string',
    format: 'binary',
  },
  boolean: {
    type: 'boolean',
  },
  date: {
    type: 'string',
    format: 'date',
  },
  dateTime: {
    type: 'string',
    format: 'date-time',
  },
  password: {
    type: 'string',
    format: 'password',
  },
  array: {
    type: 'array',
  },
  object: {
    type: 'object',
  },
}

/**
 * @param target
 * @param paths
 * @return true if target is within an array
 */
function isTargetWithinArray(target, paths) {
  const path = paths && paths[target.connectorPath]
  if (path) {
    const dim = path.getDimensionality()
    if (dim === 0) {
      return false
    }
    if (dim > 1) {
      return true
    }
    if (dim === 1 && path.container === target.connectorPath) {
      return false
    }
    return true
  }
  return false
}
function mapperController(
  $rootScope,
  $scope,
  $mdDialog,
  $timeout,
  translateFilter,
  Path,
  Map,
  ExtensionType
) {
  /*jshint validthis:true */
  const self = this

  // unnamed item name
  self.unnamed = '$item'

  self.currentPage = 'map'

  const policyNavScope = $scope.$parent

  $scope.swaggerDocument = policyNavScope.swaggerDocument
  $scope.selectedNode = policyNavScope.selectedNode

  // set up a definitions array for the dropdowns
  const definitions = [
    {
      name: translateFilter('Inline schema'),
      value: 'inline',
    },
    {
      name: 'integer',
      type: 'integer',
      format: 'int32',
    },
    {
      name: 'long',
      type: 'integer',
      format: 'int64',
    },
    {
      name: 'float',
      type: 'number',
      format: 'float',
    },
    {
      name: 'double',
      type: 'number',
      format: 'double',
    },
    {
      name: 'string',
      type: 'string',
    },
    {
      name: 'byte',
      type: 'string',
      format: 'byte',
    },
    {
      name: 'binary',
      type: 'string',
      format: 'binary',
    },
    {
      name: 'boolean',
      type: 'boolean',
    },
    {
      name: 'date',
      type: 'string',
      format: 'date',
    },
    {
      name: 'dateTime',
      type: 'string',
      format: 'date-time',
    },
    {
      name: 'password',
      type: 'string',
      format: 'password',
    },
    {
      name: 'array',
      type: 'array',
    },
    {
      name: 'object',
      type: 'object',
    },
  ]
  let schemas = []
  // OAI V2
  if ($scope.swaggerDocument.definitions) {
    Object.keys($scope.swaggerDocument.definitions).forEach(function(
      definition
    ) {
      definitions.push({
        name: `#/definitions/${definition}`,
        value: `#/definitions/${definition}`,
      })
    })
    schemas = Object.keys($scope.swaggerDocument.definitions)
  }
  if (
    $scope.swaggerDocument['x-ibm-configuration'] &&
    $scope.swaggerDocument['x-ibm-configuration'].targets
  ) {
    Object.keys($scope.swaggerDocument['x-ibm-configuration'].targets).forEach(
      function(targetName) {
        const target =
          $scope.swaggerDocument['x-ibm-configuration'].targets[targetName]
        if (target.definitions) {
          Object.keys(target.definitions).forEach(function(definition) {
            definitions.push({
              name: `#/${targetName}/definitions/${definition}`,
              value: `#/x-ibm-configuration/targets/${targetName}/definitions/${definition}`,
            })
          })
        }
      }
    )
  }

  // OAI V3
  if (
    $scope.swaggerDocument.components &&
    $scope.swaggerDocument.components.schemas
  ) {
    Object.keys($scope.swaggerDocument.components.schemas).forEach(function(
      definition
    ) {
      definitions.push({
        name: `#/components/schemas/${definition}`,
        value: `#/components/schemas/${definition}`,
      })
    })
    schemas = Object.keys($scope.swaggerDocument.components.schemas)
  }
  if (
    $scope.swaggerDocument['x-ibm-configuration'] &&
    $scope.swaggerDocument['x-ibm-configuration'].targets
  ) {
    Object.keys($scope.swaggerDocument['x-ibm-configuration'].targets).forEach(
      function(targetName) {
        const target =
          $scope.swaggerDocument['x-ibm-configuration'].targets[targetName]
        if (target.components && target.components.schemas) {
          Object.keys(target.components.schemas).forEach(function(definition) {
            definitions.push({
              name: `#/${targetName}/components/schemas/${definition}`,
              value: `#/x-ibm-configuration/targets/${targetName}//components/schemas/${definition}`,
            })
          })
        }
      }
    )
  }
  $scope.definitions = definitions
  $scope.schemas = schemas
  $scope.swaggerOperations = $rootScope.swaggerOperations

  let backoffTime = 0
  if (
    window.navigator &&
    (/firefox/i.test(window.navigator.userAgent) ||
      /AppleWebKit/i.test(window.navigator.userAgent))
  ) {
    backoffTime = 1000
  }

  $scope.includeEmptyXMLElements = function(value) {
    if (typeof value !== 'undefined') {
      // setter
      if (value) {
        if ($scope.selectedNode.options) {
          delete $scope.selectedNode.options.includeEmptyXMLElements
        }
      } else {
        if (!$scope.selectedNode.options) {
          $scope.selectedNode.options = {}
        }
        $scope.selectedNode.options.includeEmptyXMLElements = false
      }
    } else {
      // getter
      if (!$scope.selectedNode.options) return true
      if (
        typeof $scope.selectedNode.options.includeEmptyXMLElements ===
        'undefined'
      )
        return true
      return $scope.selectedNode.options.includeEmptyXMLElements
    }
  }

  $scope.namespaceInheritance = function(value) {
    if (typeof value !== 'undefined') {
      // setter
      if (value) {
        if ($scope.selectedNode.options) {
          delete $scope.selectedNode.options.namespaceInheritance
        }
      } else {
        if (!$scope.selectedNode.options) {
          $scope.selectedNode.options = {}
        }
        $scope.selectedNode.options.namespaceInheritance = false
      }
    } else {
      // getter
      if (!$scope.selectedNode.options) return true
      if (
        typeof $scope.selectedNode.options.namespaceInheritance === 'undefined'
      )
        return true
      return $scope.selectedNode.options.namespaceInheritance
    }
  }

  $scope.inlineNamespaces = function(value) {
    if (typeof value !== 'undefined') {
      // setter
      if (value) {
        if ($scope.selectedNode.options) {
          delete $scope.selectedNode.options.inlineNamespaces
        }
      } else {
        if (!$scope.selectedNode.options) {
          $scope.selectedNode.options = {}
        }
        $scope.selectedNode.options.inlineNamespaces = false
      }
    } else {
      // getter
      if (!$scope.selectedNode.options) return true
      if (typeof $scope.selectedNode.options.inlineNamespaces === 'undefined')
        return true
      return $scope.selectedNode.options.inlineNamespaces
    }
  }

  $scope.mapReferenceLimit = function(value) {
    const defaultOptions = {
      mapReferenceLimit: value || 1,
    }
    if (value) {
      $scope.selectedNode.options
        ? ($scope.selectedNode.options.mapReferenceLimit = value)
        : ($scope.selectedNode.options = defaultOptions)
    }
    if (!$scope.selectedNode.options.mapReferenceLimit) {
      $scope.selectedNode.options.mapReferenceLimit = 1
    }
    return $scope.selectedNode.options.mapReferenceLimit
  }

  $scope.mapResolveXMLInputDataType = function(value) {
    if (value !== undefined) {
      $scope.selectedNode.options = $scope.selectedNode.options || {}
      // setter
      if (!value) {
        delete $scope.selectedNode.options.mapResolveXMLInputDataType
      } else {
        $scope.selectedNode.options.mapResolveXMLInputDataType = true
      }
    } else {
      // getter
      if (!$scope.selectedNode.options) return false
      if ($scope.selectedNode.options.mapResolveXMLInputDataType === undefined)
        return false
      return $scope.selectedNode.options.mapResolveXMLInputDataType
    }
  }

  $scope.mapParseXMLOutput = function(value) {
    if (value !== undefined) {
      $scope.selectedNode.options = $scope.selectedNode.options || {}
      // setter
      if (!value) {
        delete $scope.selectedNode.options.mapParseXMLOutput
      } else {
        $scope.selectedNode.options.mapParseXMLOutput = true
      }
    } else {
      // getter
      if (!$scope.selectedNode.options) return false
      if ($scope.selectedNode.options.mapParseXMLOutput === undefined)
        return false
      return $scope.selectedNode.options.mapParseXMLOutput
    }
  }

  $scope.firstElementValue = function(value) {
    const options = $scope.selectedNode.options || {}

    if (value !== undefined) {
      options.mapArrayFirstElementValue = Boolean(value)

      if (options.mapArrayFirstElementValue === false) {
        delete options.mapArrayFirstElementValue
      }

      $scope.selectedNode.options = options
    }

    return Boolean(options.mapArrayFirstElementValue)
  }

  $scope.resolveApicVariables = function(value) {
    if (value !== undefined) {
      $scope.selectedNode.options = $scope.selectedNode.options || {}
      $scope.selectedNode.options.mapResolveApicVariables = value
    } else {
      if (!$scope.selectedNode.options) return false
      if ($scope.selectedNode.options.mapResolveApicVariables === undefined)
        return false
      return $scope.selectedNode.options.mapResolveApicVariables
    }
  }

  $scope.mapEmulateV4EmptyJSONObject = function(value) {
    const defaultOptions = {
      mapEmulateV4EmptyJSONObject: value || false,
    }
    if (value !== undefined) {
      $scope.selectedNode.options
        ? ($scope.selectedNode.options.mapEmulateV4EmptyJSONObject = value)
        : ($scope.selectedNode.options = defaultOptions)
    }
    if ($scope.selectedNode.options.mapEmulateV4EmptyJSONObject === undefined) {
      $scope.selectedNode.options.mapEmulateV4EmptyJSONObject = false
    }
    return $scope.selectedNode.options.mapEmulateV4EmptyJSONObject
  }

  $scope.mapEmulateV4DefaultRequiredProps = function(value) {
    const defaultOptions = {
      mapEmulateV4DefaultRequiredProps: value || false,
    }
    if (value !== undefined) {
      $scope.selectedNode.options
        ? ($scope.selectedNode.options.mapEmulateV4DefaultRequiredProps = value)
        : ($scope.selectedNode.options = defaultOptions)
    }
    if (
      $scope.selectedNode.options.mapEmulateV4DefaultRequiredProps === undefined
    ) {
      $scope.selectedNode.options.mapEmulateV4DefaultRequiredProps = false
    }
    return $scope.selectedNode.options.mapEmulateV4DefaultRequiredProps
  }

  $scope.mapEnablePostProcessingJSON = function(value) {
    const defaultOptions = {
      mapEnablePostProcessingJSON: value || false,
    }
    if (value !== undefined) {
      $scope.selectedNode.options
        ? ($scope.selectedNode.options.mapEnablePostProcessingJSON = value)
        : ($scope.selectedNode.options = defaultOptions)
    }
    if ($scope.selectedNode.options.mapEnablePostProcessingJSON === undefined) {
      $scope.selectedNode.options.mapEnablePostProcessingJSON = false
    }
    return $scope.selectedNode.options.mapEnablePostProcessingJSON
  }

  $scope.nullValue = function(value) {
    if (value !== undefined) {
      $scope.selectedNode.options = $scope.selectedNode.options || {}
      if (!value) {
        delete $scope.selectedNode.options.mapNullValue
      } else {
        $scope.selectedNode.options.mapNullValue = true
      }
    } else {
      if (!$scope.selectedNode.options) return false
      if ($scope.selectedNode.options.mapNullValue === undefined) return false
      return $scope.selectedNode.options.mapNullValue
    }
  }

  $scope.optimizeSchemaDefinition = function(value) {
    if (value !== undefined) {
      $scope.selectedNode.options = $scope.selectedNode.options || {}
      if (!value) {
        delete $scope.selectedNode.options.mapOptimizeSchemaDefinition
      } else {
        $scope.selectedNode.options.mapOptimizeSchemaDefinition = true
      }
    } else {
      if (!$scope.selectedNode.options) return false
      if ($scope.selectedNode.options.mapOptimizeSchemaDefinition === undefined)
        return false
      return $scope.selectedNode.options.mapOptimizeSchemaDefinition
    }
  }

  $scope.$createEmptyArray = function(value) {
    $scope.selectedNode.options = $scope.selectedNode.options || {}
    if (value !== undefined) {
      $scope.selectedNode.options.mapCreateEmptyArray = value
    } else {
      if ($scope.selectedNode.options.mapCreateEmptyArray === undefined)
        return 'all'
      return $scope.selectedNode.options.mapCreateEmptyArray
    }
  }

  $scope.$messagesInputData = function(value) {
    $scope.selectedNode.options = $scope.selectedNode.options || {}
    if (value !== undefined) {
      $scope.selectedNode.options.messagesInputData = value
    } else {
      if ($scope.selectedNode.options.messagesInputData === undefined)
        return 'error'
      return $scope.selectedNode.options.messagesInputData
    }
  }

  $scope.$handleEmptyXMLElement = function(value) {
    $scope.selectedNode.options = $scope.selectedNode.options || {}
    if (value !== undefined) {
      $scope.selectedNode.options.mapXMLEmptyElement = value
    } else {
      if ($scope.selectedNode.options.mapXMLEmptyElement === undefined)
        return 'string'
      return $scope.selectedNode.options.mapXMLEmptyElement
    }
  }

  $scope.$on('resize', function() {
    $timeout(self.constructCanvas, backoffTime)
  })

  $scope.markDirty = function() {
    $scope.$emit('mark_api_dirty')
  }

  // seem to be unused...
  // $scope.inputSchemaName = "source";
  // $scope.outputSchemaName = "target";

  self.switchToMap = function() {
    self.currentPage = 'map'
    self.eventsBound = false // Reset eventsBound so that scrolling will be reactivated by constructCanvas
    $timeout(self.constructCanvas, backoffTime)
  }

  /**
   * Add an input to the map inputs
   * @param inputName input name
   * @param schema schema
   * @param variable source variable
   * @param content (optional) content type
   */
  self.addInput = function(inputName, schema, variable, content) {
    if (!schema) schema = {type: 'object'}
    if (!variable) variable = 'request.body'
    if (!inputName) inputName = 'input'
    const rootName = inputName
    let counter = 1
    while ($scope.selectedNode.inputs.hasOwnProperty(inputName)) {
      inputName = `${rootName}_${counter++}`
    }
    $scope.selectedNode.inputs[inputName] = {
      schema,
      variable,
    }
    if (content) {
      $scope.selectedNode.inputs[inputName].content = content
    }
  }

  /**
   * @param obj is a path[verb].requestBody or a path[verb].responses[response]
   */
  function getSchemaForRequestOrResponse(obj) {
    // In V2, the schema is directly within the obj
    // In V3, it is nested within a content object
    if (obj.schema) {
      return obj.schema
    } else if (obj.content) {
      const contentTypes = Object.keys(obj.content)
      if (contentTypes.length > 0) {
        return obj.content[contentTypes[0]].schema
      }
    }
    return null
  }

  self.numberOfRequestParameters = function(parameters) {
    let count = 0
    parameters.forEach(function(parameter) {
      let parameterName = parameter.name
      if (parameter.$ref) {
        if ($scope.swaggerDocument.swagger) {
          // OAI V2
          parameterName = parameter.$ref.replace('#/parameters/', '')
          parameter = $scope.swaggerDocument.parameters[parameterName]
        } else {
          // OAI V3
          parameterName = parameter.$ref.replace('#/components/parameters/', '')
          parameter =
            $scope.swaggerDocument.components.parameters[parameterName]
        }
      }
      if (parameter.in === 'query' || parameter.in === 'path') {
        count++
      }
    })
    return count
  }

  self.numberOfRequestHeaders = function(parameters) {
    let count = 0
    parameters.forEach(function(parameter) {
      let parameterName = parameter.name
      if (parameter.$ref) {
        if ($scope.swaggerDocument.swagger) {
          // OAI V2
          parameterName = parameter.$ref.replace('#/parameters/', '')
          parameter = $scope.swaggerDocument.parameters[parameterName]
        } else {
          // OAI V3
          parameterName = parameter.$ref.replace('#/components/parameters/', '')
          parameter =
            $scope.swaggerDocument.components.parameters[parameterName]
        }
      }
      if (parameter.in === 'header') {
        count++
      }
    })
    return count
  }

  self.addParametersForOperation = function(selectedOperation) {
    let parameters = []

    const path = $scope.swaggerDocument.paths[selectedOperation.path]
    const operation = path[selectedOperation.verb]

    if (operation.parameters)
      parameters = parameters.concat(operation.parameters)
    if (path.parameters) parameters = parameters.concat(path.parameters)

    // If there are more than 1 request parameters/headers, bundle them into a single object.
    // Reducing the number of parameters increases the map policy throughput.
    const requestParamsObject =
      self.numberOfRequestParameters(parameters) > 1
        ? {
          type: 'object',
          properties: {},
        }
        : null
    const requestHeadersObject =
      self.numberOfRequestHeaders(parameters) > 1
        ? {
          type: 'object',
          properties: {},
        }
        : null

    parameters.forEach(function(parameter) {
      let parameterName = parameter.name
      if (parameter.$ref) {
        if ($scope.swaggerDocument.swagger) {
          // OAI V2
          parameterName = parameter.$ref.replace('#/parameters/', '')
          parameter = $scope.swaggerDocument.parameters[parameterName]
        } else {
          // OAI V3
          parameterName = parameter.$ref.replace('#/components/parameters/', '')
          parameter =
            $scope.swaggerDocument.components.parameters[parameterName]
        }
      }
      const schema = parameter.schema
        ? parameter.schema
        : {type: parameter.type}
      if (
        requestParamsObject &&
        (parameter.in === 'query' || parameter.in === 'path')
      ) {
        // Add this parameter as a property within the query parameters object
        requestParamsObject.properties[parameterName] = angular.copy(schema)
        requestParamsObject.properties[parameterName].name = parameterName
      } else if (requestHeadersObject && parameter.in === 'header') {
        // Add this parameter as a property within the query headers object
        requestHeadersObject.properties[parameterName] = angular.copy(schema)
        requestHeadersObject.properties[parameterName].name = parameterName
      } else {
        const variable =
          parameter.in === 'body'
            ? 'request.body'
            : parameter.in === 'header'
              ? `request.headers.${parameterName}`
              : `request.parameters.${parameterName}`
        self.addInput(parameterName, schema, variable)
      }
    })
    if (requestParamsObject) {
      self.addInput(
        'requestParameters',
        requestParamsObject,
        'request.parameters',
        'application/json'
      )
    }
    if (requestHeadersObject) {
      self.addInput(
        'requestHeaders',
        requestHeadersObject,
        'request.headers',
        'application/json'
      )
    }
    if (operation.requestBody && operation.requestBody.content) {
      // OAI V3 stores the request body in requestBody
      self.addInput(
        'body',
        getSchemaForRequestOrResponse(operation.requestBody),
        'request.body'
      )
    }
  }

  self.addOutputsForOperation = function(selectedOperation) {
    const operation =
      $scope.swaggerDocument.paths[selectedOperation.path][
        selectedOperation.verb
      ]

    const output =
      operation.responses['200'] ||
      operation.responses['201'] ||
      operation.responses.default
    if (!output) return

    const schema = getSchemaForRequestOrResponse(output) || {type: 'object'}
    const variable = 'message.body'
    self.addOutput('response', schema, variable)
  }

  self.addOutput = function(outputName, schema, variable) {
    if (!schema) schema = {type: 'object'}
    if (!variable) variable = 'message.body'
    if (!outputName) outputName = 'output'
    const rootName = outputName
    let counter = 1
    while ($scope.selectedNode.outputs.hasOwnProperty(outputName)) {
      outputName = `${rootName}_${counter++}`
    }
    $scope.selectedNode.outputs[outputName] = {
      schema,
      variable,
    }
  }

  self.removeInput = function(inputName) {
    delete $scope.selectedNode.inputs[inputName]
  }

  self.removeOutput = function(outputName) {
    delete $scope.selectedNode.outputs[outputName]
  }

  const mapperConfig = {
    connectorRadius: 4,
    backOff: 4,
    indentFactor: 8,
    curveFactor: 0.3,
  }

  let currentLine = null
  let currentGroup = null
  let currentInput = null
  let currentMapping = null
  const mappings = []
  let automappable = true
  let initialScrollOffsetLeft = 0
  let scrollOffsetLeft = 0
  let initialScrollOffsetRight = 0
  let scrollOffsetRight = 0

  self.updateModelActions = function(actionInfo) {
    $scope.selectedNode.actions = actionInfo.actions

    // If a merge was specified for a wire, then issue a message if there is more
    // than one target for that wire
    const actionTargets = Object.keys(actionInfo.info.unmergedTargets)
    if (!$scope.actionDialog && actionTargets.length > 0) {
      $scope.actionDialog = $mdDialog.show({
        controller: 'ActionConfirmationController',
        flex: '50',
        backdrop: 'static',
        keyboard: false,
        templateUrl: 'src/html/action-dialog.html',
        parent: angular.element(document.body),
        focusOnOpen: false,
        locals: {
          parentScope: $scope,
          actionTargets,
        },
      })
    }
  }

  $scope.map = new Map(
    $scope.selectedNode,
    $scope.swaggerDocument,
    self.updateModelActions
  )

  self.showTargetDetails = function(target) {
    //$scope.selectedTargetNode = target;
    const targetPath = $scope.paths[target.connectorPath]
    let mapping = $scope.map.getMappingForPath(targetPath)
    const valueType = targetPath.type

    $mdDialog
      .show({
        controller: 'TargetConnectorController',
        flex: '50',
        backdrop: 'static',
        keyboard: false,
        template: require('../../html/target-connector.html'),
        parent: angular.element(document.body),
        focusOnOpen: false,
        locals: {
          mapping,
          target,
          paths: $scope.paths,
          valueType,
        },
      })
      .then(function(response) {
        let responseDefault
        let responseDefaultAsString

        if (response.default) {
          responseDefault = `${response.default}`
          responseDefaultAsString = responseDefault
        }

        if (
          response.remove === true ||
          (_.isEmpty(response.from) &&
            _.isEmpty(response.value) &&
            _.isEmpty(responseDefault) &&
            !response.emptyString)
        ) {
          // Deleting a mapping
          $scope.map.deleteMappingForPath(targetPath)
          $scope.markDirty()
        } else {
          // Creating or Modifying a mapping
          if (!mapping) {
            $scope.map.setMappingForPath(targetPath)
            $scope.markDirty()
            mapping = $scope.map.getMappingForPath(targetPath)
          }
          if (
            mapping.$$parentCreate ||
            isTargetWithinArray(target, $scope.paths)
          ) {
            // The normal behavior is a `set` of the target with the value provided in the value.
            // But if the target is within an array, then `create` the parent array element(s) and then `set` the target.
            mapping.$$parentCreate = true
          } else {
            delete mapping.foreach
          }
          if (!response.from || response.from.length === 0) {
            delete mapping.source
          } else {
            mapping.source = response.from
          }
        }

        // A mapping won't exist in the following scenario.
        // There is no wire, and the customer clicks on the target.
        // The Configure Mapping panel is shown (above), but the
        // customer does not add any information and selects okay.
        // Thus we leave the panel and flow to here, but there was no
        // original mapping and there is no created mapping.
        if (mapping) {
          if (response.value) {
            // Update the mapping if it has changed
            mapping.value =
              response.value === mapping.$stringifiedValue
                ? mapping.value
                : response.value
          } else {
            delete mapping.value
          }

          // The manualForeach object is set if this is for a SET and not a CREATE
          if (mapping.foreach && !mapping.$$parentCreate) {
            mapping.manualForeach = response.foreach || mapping.foreach
          }

          if (response.foreach) {
            mapping.foreach = response.foreach

            // If the source of the foreach is itself an array, then set the
            // $foreachSourceIsArray flag.  This flag is used inside of serializeMapping to
            // determine if the foreach source or its parent should be used in the actual foreach action.
            if (
              mapping.foreach[0] &&
              mapping.foreach[0].source &&
              $scope.paths[mapping.foreach[0].source]
            ) {
              const dims =
                $scope.paths[mapping.foreach[0].source].dimensionality
              mapping.$foreachSourceIsArray =
                dims.length > 0 &&
                dims[dims.length - 1] === mapping.foreach[0].source
            } else {
              delete mapping.$foreachSourceIsArray
            }
          } else if (response.from) {
            // only clear out foreach if the mapping hasn't
            // 1) got a foreach defined, and
            // 2) got sources defined
            // no sources means implicit iteration
            delete mapping.foreach
          }

          if (response.default !== undefined) {
            if (response.valueType) {
              let aNumber
              switch (response.valueType) {
                case 'boolean':
                  responseDefault = responseDefault.toLowerCase()
                  // only convert boolean values
                  if (
                    responseDefault === 'true' ||
                    responseDefault === 'false'
                  ) {
                    responseDefault = responseDefault === 'true'
                  }
                  break
                case 'number':
                case 'integer':
                  aNumber = Number(responseDefault)
                  // only convert number values
                  if (isFinite(aNumber)) {
                    responseDefault = aNumber
                  }
                  break
                case 'array':
                case 'object':
                  try {
                    responseDefault = JSON.parse(responseDefaultAsString)
                  } catch (e) {
                    responseDefault = responseDefaultAsString
                  }
                  break
              }
            }

            mapping.default =
              responseDefaultAsString === mapping.$stringifiedDefault
                ? mapping.default
                : responseDefault
          } else {
            delete mapping.default
          }

          if (response.merge) {
            mapping.merge = true
          } else {
            delete mapping.merge
          }
        }
        self.updateModelActions($scope.map.getActions(true))

        $timeout(self.constructCanvas, backoffTime)
      })
  }

  $scope.paths = {}
  ExtensionType.setHandler($scope)

  function constructInputNodes(
    cssRule,
    cssClass,
    connectorNodeData,
    clickZoneData
  ) {
    const yOffset = 40 // allow for column headers
    const mappableInputs = $(cssRule)
    let i
    let position
    let height
    let parent
    let item

    for (i = 0; i < mappableInputs.length; i++) {
      const input = $(`${cssRule}:nth(${i})`)
      position = input.position()
      // detect IE8 and above, and Edge
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        // Using  div.mapper-container as offsetParent for ie11/Edge since jquery was not able
        position.top =
          input.offset().top - $('div.mapper-container').offset().top
        position.left =
          input.offset().left - $('div.mapper-container').offset().left
      }
      if (position.top === 0 && position.left === 0) {
        // this signifies a collapsed input - do not render any connectors
        continue
      }
      height = input.height()
      $scope.paths[input.attr('path')] = new Path(
        input.attr('path'),
        input.attr('dimensions'),
        input.attr('container'),
        input.attr('type')
      )
      const containerAdjust = 0
      // if (input.hasClass('mapper-schema-container')) containerAdjust = -24;
      connectorNodeData.push({
        cx: mapperConfig.connectorRadius * 2,
        cy: position.top - yOffset + containerAdjust + 12,
        radius: mapperConfig.connectorRadius,
        class: input.hasClass('collapsed') ? `collapsed-${cssClass}` : cssClass,
        connectorPath: $scope.paths[input.attr('path')].getPath(),
        connectorDimension: $scope.paths[
          input.attr('path')
        ].getDimensionality(),
        connectorParent: parent,
        connectorItem: item,
      })
      if (clickZoneData) {
        clickZoneData.push({
          width: 30,
          height: 20,
          x: 0,
          y: position.top - yOffset + containerAdjust + 2,
          class: 'click-zone-input',
          connectorPath: $scope.paths[input.attr('path')].getPath(),
          connectorDimension: $scope.paths[
            input.attr('path')
          ].getDimensionality(),
          connectorParent: parent,
          connectorItem: item,
          position: 'input',
        })
      }
    }
  }

  function constructOutputNodes(
    cssRule,
    cssClass,
    connectorNodeData,
    clickZoneData
  ) {
    const yOffset = 40 // allow for column headers
    const canvas = $('svg.mapper-canvas')
    const mappableTargets = $(cssRule)
    let i
    let position
    let height
    let target
    let className
    for (i = 0; i < mappableTargets.length; i++) {
      target = $(`${cssRule}:nth(${i})`)
      position = target.position()
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        // Using  div.mapper-container as offsetParent for ie11/Edge since jquery was not able
        position.top =
          input.offset().top - $('div.mapper-container').offset().top
        position.left =
          input.offset().left - $('div.mapper-container').offset().left
      }
      if (position.top === 0 && position.left === 0) {
        // this signifies a collapsed input - do not render any connectors
        continue
      }
      height = target.height()
      $scope.paths[target.attr('path')] = new Path(
        target.attr('path'),
        target.attr('dimensions'),
        target.attr('container'),
        target.attr('type')
      )
      className = cssClass
      if ($scope.map.hasMapping($scope.paths[target.attr('path')]))
        className += ' mapped'
      const containerAdjust = 0
      // if (target.hasClass('mapper-schema-container')) containerAdjust = -24;
      connectorNodeData.push({
        cx: canvas.width() - mapperConfig.connectorRadius * 2,
        cy: position.top - yOffset + containerAdjust + 12,
        radius: mapperConfig.connectorRadius,
        class: target.hasClass('collapsed')
          ? `collapsed-${className}`
          : className,
        connectorPath: $scope.paths[target.attr('path')].getPath(),
        connectorDimension: $scope.paths[
          target.attr('path')
        ].getDimensionality(),
      })
      if (clickZoneData) {
        clickZoneData.push({
          width: 30,
          height: 20,
          x: canvas.width() - 30,
          y: position.top - yOffset + containerAdjust + 2,
          class: 'click-zone-output',
          connectorPath: $scope.paths[target.attr('path')].getPath(),
          connectorDimension: $scope.paths[
            target.attr('path')
          ].getDimensionality(),
          position: 'output',
        })
      }
    }
  }
  /**
   * Compare paths for equality.  Either path may contain a $item
   */
  function comparePaths(string1, string2) {
    if (string1 === string2) {
      return true
    }
    if (!string1 && !string2) {
      return true
    }
    if (!string1 || !string2) {
      return false
    }
    if (`${string1}.$item` === string2 || string1 === `${string2}.$item`) {
      return true
    }
    return false
  }

  function selectMapping(e, targetPath) {
    d3.event.stopPropagation()
    if (currentMapping)
      currentMapping.attr(
        'class',
        currentMapping.attr('class').replace(' mapper-group-selected', '')
      )
    e.attr('class', `${e.attr('class')} mapper-group-selected`)
    currentMapping = e

    const thisMapping = mappings.filter(function(mapping) {
      return comparePaths(mapping.target, targetPath)
    })
    if (
      thisMapping.length > 0 &&
      thisMapping[0].config &&
      thisMapping[0].config.fn
    ) {
      $('.mapper-function textarea')[0].value = thisMapping[0].config.fn
      $('.mapper-function textarea')[0].connectorPath = targetPath
    }
  }

  function createPath(startX, startY, endX, endY, connectorPath, isCollapsed) {
    // safety first - string safety
    startX = startX * 1
    startY = startY * 1
    endX = endX * 1
    endY = endY * 1

    // offset tracking
    if (scrollOffsetLeft > 0) startY -= scrollOffsetLeft
    // add back in any scroll offset we started with
    if (initialScrollOffsetLeft > 0) startY += initialScrollOffsetLeft
    // nuance here... if we're given a connector path, that means we've completed a mapping,
    // which means we're trying to connect the line to a circle (which might be offset due to scrolling).
    // If not, we're freeform drawing and don't yet care about offsets
    if (connectorPath && scrollOffsetRight > 0) endY -= scrollOffsetRight
    // add back in any scroll offset we started with
    if (connectorPath && initialScrollOffsetRight > 0)
      endY += initialScrollOffsetRight

    // back the line off from the connector centre
    startX += mapperConfig.connectorRadius / 2 + 2
    endX -= mapperConfig.connectorRadius / 2 + 2

    // calculate an indent relative to the lines X-length
    // indentLeft must = width / 2 when endX = width
    // add 5% to the left indent. Without this, a map from
    // top left to bottom right
    // will curve off on the right while still within the
    // zone of the function, and look weird
    const indentRight = (endX - startX) / 2 + (endX - startX) * 0.05
    const indentLeft = mapperConfig.curveFactor * (endX - startX)
    let pathString = `M ${endX} ${endY}`

    // draw the curve
    pathString += `L ${endX - indentRight} ${endY}`
    pathString += `C ${endX - indentLeft - indentRight} ${endY}`
    pathString += ` ${startX + indentLeft} ${startY}`
    pathString += ` ${startX} ${startY}`

    // create the shape
    if (!currentGroup) {
      // create a new group
      const canvas = d3.select('svg.mapper-canvas')
      currentGroup = canvas.append('g')
      currentGroup.attr('class', 'mapper-group')
    }
    if (!currentLine) {
      // create a new line
      currentLine = currentGroup.insert('path', '.function-node')
      if (isCollapsed) {
        currentLine.attr('class', 'collapsed-mapper-line')
      } else {
        currentLine.attr('class', 'mapper-line')
      }
    }
    currentLine.attr('d', pathString)
    if (connectorPath) {
      // line is complete and has a target
      const scopedGroup = currentGroup
      currentLine.attr('connectorPath', connectorPath)
      currentLine.on('click', function() {
        selectMapping(scopedGroup, connectorPath)
      })
    }
  }

  /**
   * Split the path into terms (separated by .)
   * @param path
   * @return terms[]
   */
  function splitTerms(path) {
    // A dot within a name is always '\.'
    // A dot that is a separator is an unescaped '.'
    // Create a temporary string with separators that are '..'
    // (We could choose any sequence that cannot be in the initial term, .. seems reasonable)
    const path2 = path.replace(/([^\\])\./g, '$1..')
    // now split on the new separator
    return path2.split('..')
  }

  /**
   * @param input name
   * @return propert name (trailing array indices are removed)
   */
  function getPropertyName(input) {
    const name = splitTerms(input)
    let done = false
    while (name.length > 0 && !done) {
      if (isNaN(parseInt(name[name.length - 1]))) {
        done = true
      } else {
        name.pop() // Ignore trailing array indices
      }
    }
    return name.join('.')
  }

  function restoreMap() {
    const model = $scope.map.getModel()
    const targetConnectors = d3.selectAll('circle.output-node')
    const targetCollapsedConnectors = d3.selectAll(
      'circle.collapsed-output-node'
    )
    const sourceConnectors = d3.selectAll('circle.input-node')
    const sourceCollapsedConnectors = d3.selectAll(
      'circle.collapsed-input-node'
    )
    let targets
    let sources
    let targetPath
    model.forEach(function(mapping) {
      // mappings do not have to have a source...
      if (!mapping.source) return
      // target doesn't exist (illegal action like in apiconnect-assembly/#416)
      if (!mapping.target) return
      // find the best target match
      let targetCollapsed = false
      let arrayItem
      targets = targetConnectors
        .filter(function(item) {
          // An array has two circles, one for the array and one for the item.
          // Only use the first one.
          let match
          if (item.connectorPath === arrayItem && item.connectorDimension > 0) {
            match = false
          } else {
            match =
              item.connectorPath === getPropertyName(mapping.target) ||
              `${item.connectorPath}.$item` === mapping.target ||
              item.connectorPath === `${mapping.target}.$item`
          }
          arrayItem =
            match && item.connectorDimension > 0
              ? item.connectorPath
              : undefined
          return match
        })
        .data()
      if (targets.length === 0) {
        targetCollapsed = true
        // find the most relevant collapsed node
        const targetConnectorSections = mapping.target.split('.')
        while (targets.length === 0 && targetConnectorSections.length > 0) {
          targetPath = targetConnectorSections.join('.')
          targets = targetCollapsedConnectors
            .filter(function(targetConnector) {
              return (
                targetConnector.connectorPath === targetPath ||
                `${targetConnector.connectorPath}.$item` === targetPath ||
                targetConnector.connectorPath === `${targetPath}.$item`
              )
            })
            .data()
          targetConnectorSections.pop()
        }
      }
      mapping.source.forEach(function(sourcePath) {
        // find the best source match
        let sourceCollapsed = false
        // handle absolute paths
        if (sourcePath.indexOf('#/') === 0) sourcePath = sourcePath.substring(2)
        let arrayItem
        sources = sourceConnectors
          .filter(function(item) {
            // An array has two circles, one for the array and one for the item.
            // Only use the first one.
            let match
            if (
              item.connectorPath === arrayItem &&
              item.connectorDimension > 0
            ) {
              match = false
            } else {
              match =
                item.connectorPath === getPropertyName(sourcePath) ||
                `${item.connectorPath}.$item` === sourcePath ||
                item.connectorPath === `${sourcePath}.$item`
            }
            arrayItem =
              match && item.connectorDimension > 0
                ? item.connectorPath
                : undefined
            return match
          })
          .data()
        if (sources.length === 0) {
          sourceCollapsed = true
          // find the most relevant collapsed node
          const sourceConnectorSections = sourcePath.split('.')
          while (sources.length === 0 && sourceConnectorSections.length > 0) {
            sourcePath = sourceConnectorSections.join('.')
            sources = sourceCollapsedConnectors
              .filter(function(sourceConnector) {
                return (
                  sourceConnector.connectorPath === sourcePath ||
                  `${sourceConnector.connectorPath}.$item` === sourcePath ||
                  sourceConnector.connectorPath === `${sourcePath}.$item`
                )
              })
              .data()
            sourceConnectorSections.pop()
          }
        }
        sources.forEach(function(source) {
          targets.forEach(function(target) {
            createPath(
              source.cx,
              source.cy,
              target.cx,
              target.cy,
              target.connectorPath,
              sourceCollapsed || targetCollapsed
            )
            currentLine = null
          })
        })
      })
    })
  }

  function restoreMapFromModel() {
    const svgContainer = d3.select('svg.mapper-canvas')
    // restore stored mappings
    svgContainer.selectAll('g').remove()

    restoreMap()
    currentGroup = null
  }

  function canvasMouseUp() {
    if ($scope.map.readOnly) return
    if (currentGroup && currentInput) {
      // we're mapping
      const canvas = $('svg.mapper-canvas')
      canvas.unbind('mousemove')
      currentGroup.remove()
    }
    currentLine = null
    currentGroup = null
    currentInput = null
    currentMapping = null
  }

  const isFF = window.navigator && /firefox/i.test(window.navigator.userAgent)
  // var isSafari = (window.navigator && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent));
  function trackMouse(e) {
    let offsetX = e.offsetX
    let offsetY = e.offsetY
    if (e.target && e.target.nodeName !== 'svg') {
      const position = e.target.getAttribute('position')
      if (position === 'input') {
        //offsetY += (initialScrollOffsetLeft - scrollOffsetLeft);
      } else if (position === 'output') {
        // offsetY += (initialScrollOffsetRight - scrollOffsetRight);
      }
      if (isFF) {
        // firefox defines offsets relative to the event target rather than the absolute parent
        offsetX += 1 * e.target.getAttribute('x')
        offsetY += 1 * e.target.getAttribute('y')
      }
    }
    createPath(8, currentInput.y + 10, offsetX - mapperConfig.backOff, offsetY)
  }

  function connectorMouseUp(e) {
    // allow click events - mouse down & mouse up on same element
    d3.event.stopPropagation()
    if (currentInput === e) return
    if ($scope.map.readOnly) return
    const canvas = $('svg.mapper-canvas')
    if (
      e.class === 'output-node' ||
      e.class === 'click-zone-output' ||
      e.class === 'output-node mapped' ||
      e.class === 'click-zone-output mapped'
    ) {
      // if we aren't mapping, show the target details dialog
      if (!currentInput || !currentGroup) {
        self.showTargetDetails(e)
        return
      }
      // otherwise add the new mapping
      const sourcePath = $scope.paths[currentInput.connectorPath]
      const targetPath = $scope.paths[e.connectorPath]
      $scope.map.setMappingForPath(targetPath, sourcePath)
      $scope.markDirty()
      const mapping = $scope.map.getMappingForPath(targetPath)
      if (mapping) {
        // Determine if this new mapping should be a SET or a CREATE
        // The normal behavior is a `set` of the target with the value provided in the value.
        // But if the target is within an array, then `create` the parent array element(s) and then `set` the target.
        if (isTargetWithinArray(e, $scope.paths)) {
          mapping.$$parentCreate = true
        } else {
          delete mapping.foreach
        }
      }
      $scope.$apply()
      createPath(8, currentInput.y + 10, 192, e.y + 10, e.connectorPath)

      // ensure the connector node now has the mapped class
      $('.output-node:not(.mapped)')
        .filter(function(index, node) {
          return node.getAttribute('connectorPath') === e.connectorPath
        })
        .attr('class', 'output-node mapped')

      // user has started to interact with the map - let's not do anything annoying with automapping now
      automappable = false

      canvas.unbind('mousemove')
      currentLine = null
      currentGroup = null
      currentInput = null

      restoreMapFromModel()
    }
  }

  function connectorMouseDown(e) {
    // allow click events - mouse down & mouse up on same element
    if (currentInput === e) return
    d3.event.stopPropagation()
    if ($scope.map.readOnly) return
    const canvas = $('svg.mapper-canvas')
    if (e.class === 'input-node' || e.class === 'click-zone-input') {
      if (currentGroup) {
        // flow through - we're cancelling the existing mapping and starting a new one
        // but we'll just keep using this line to make life easy
      }
      currentInput = e
      canvas.bind('mousemove', trackMouse)
    }
  }

  // scrolling functions - allow the user to scroll the left and right panes independently,
  // and transform the central mapping panel. This allows mapping from the bottom of a long
  // list on the left, to the top on the right (for example)
  function transformLeftNodes() {
    scrollOffsetLeft = $('.mapper-inputs-container')[0].scrollTop
    // console.log(scrollOffsetLeft);
    d3.selectAll('.input-node').attr(
      'transform',
      `translate(0, ${0 - scrollOffsetLeft + initialScrollOffsetLeft})`
    )
    d3.selectAll('.collapsed-input-node').attr(
      'transform',
      `translate(0, ${0 - scrollOffsetLeft + initialScrollOffsetLeft})`
    )
    d3.selectAll('.click-zone-input').attr(
      'transform',
      `translate(0, ${0 - scrollOffsetLeft + initialScrollOffsetLeft})`
    )
  }

  function scrollLeft() {
    transformLeftNodes()
    restoreMapFromModel()
  }

  function transformRightNodes() {
    scrollOffsetRight = $('.mapper-outputs-container')[0].scrollTop
    d3.selectAll('.output-node').attr(
      'transform',
      `translate(0, ${0 - scrollOffsetRight + initialScrollOffsetRight})`
    )
    d3.selectAll('.collapsed-output-node').attr(
      'transform',
      `translate(0, ${0 - scrollOffsetRight + initialScrollOffsetRight})`
    )
    d3.selectAll('.click-zone-output').attr(
      'transform',
      `translate(0, ${0 - scrollOffsetRight + initialScrollOffsetRight})`
    )
  }

  function scrollRight() {
    transformRightNodes()
    restoreMapFromModel()
  }

  // function scrollToTop() {
  //   $(".mapper-inputs-container")[0].scrollTop = 0;
  //   scrollOffsetLeft = 0;
  //   $(".mapper-outputs-container")[0].scrollTop = 0;
  //   scrollOffsetRight = 0;
  // }

  // end scrolling functions

  self.eventsBound = false
  self.splitTerms = splitTerms // For tests
  self.getPropertyName = getPropertyName // For tests
  self.constructCanvas = function(forceClear) {
    if (self.currentPage !== 'map') return

    // when constructing the canvas, we need to record the initial left and right scroll offsets
    // then we can adjust the rendered heights of the input nodes and wires for the life of the canvas
    const inputsContainer = $('.mapper-inputs-container')[0]
    const outputsContainer = $('.mapper-outputs-container')[0]
    if (!inputsContainer || !outputsContainer) return
    initialScrollOffsetLeft = inputsContainer ? inputsContainer.scrollTop : 0
    initialScrollOffsetRight = outputsContainer ? outputsContainer.scrollTop : 0

    // listen for scroll events
    if (!self.eventsBound) {
      $('.mapper-inputs-container').on(
        'scroll',
        _.throttle(scrollLeft, 100, {leading: true})
      )
      $('.mapper-outputs-container').on(
        'scroll',
        _.throttle(scrollRight, 100, {leading: true})
      )
    }

    const canvas = $('svg.mapper-canvas')
    if (!self.eventsBound) {
      canvas.on('mouseup', canvasMouseUp)
    }
    self.eventsBound = true
    const svgContainer = d3.select('svg.mapper-canvas')
    const connectorNodeData = []
    const clickZoneData = []

    constructInputNodes(
      '.mapper-input-schema .mapper-schema-path',
      'input-node',
      connectorNodeData,
      clickZoneData
    )

    constructOutputNodes(
      '.mapper-output-schema .mapper-schema-path',
      'output-node',
      connectorNodeData,
      clickZoneData
    )

    // now check that the model is consistent with the available inputs and outputs
    let saveDiscriminator = true
    if (forceClear) {
      saveDiscriminator = false
    }

    if (
      $scope.map &&
      $scope.inputSchemas &&
      $scope.outputSchemas &&
      !$scope.pruneDialog
    ) {
      const unrecognized = $scope.map.pruneModel(
        $scope.inputSchemas,
        $scope.outputSchemas,
        saveDiscriminator,
        true
      )

      if (unrecognized.length > 0) {
        // prompt the user if pruning should be performed
        $scope.pruneDialog = $mdDialog.show({
          controller: 'PruneConfirmationController',
          flex: '50',
          backdrop: 'static',
          keyboard: false,
          template: require('../../html/confirmation-dialog.html'),
          parent: angular.element(document.body),
          focusOnOpen: false,
          locals: {
            parentScope: $scope,
            saveDiscriminator,
            unrecognized,
          },
        })
      }
    }

    svgContainer.selectAll('circle').remove()
    const circles = svgContainer
      .selectAll('circle')
      .data(connectorNodeData)
      .enter()
      .append('circle')
    circles
      .attr('cx', function(d) {
        return d.cx
      })
      .attr('cy', function(d) {
        return d.cy
      })
      .attr('r', function(d) {
        return d.radius
      })
      .attr('class', function(d) {
        return d.class
      })
      .attr('connectorPath', function(d) {
        return d.connectorPath
      })
    svgContainer.selectAll('rect').remove()
    const rects = svgContainer
      .selectAll('rect')
      .data(clickZoneData)
      .enter()
      .append('rect')
    rects
      .attr('x', function(d) {
        return d.x
      })
      .attr('y', function(d) {
        return d.y
      })
      .attr('width', function(d) {
        return d.width
      })
      .attr('height', function(d) {
        return d.height
      })
      .attr('class', function(d) {
        return d.class
      })
      .attr('connectorPath', function(d) {
        return d.connectorPath
      })
      .attr('position', function(d) {
        return d.position
      })
      // .on("click", connectorClick)
      .on('mousedown', connectorMouseDown)
      .on('mouseup', connectorMouseUp)

    // TODO - offsets are being incorrectly applied... scrolling to top for now
    // // initialize and act on any current scroll offsets
    transformLeftNodes()
    transformRightNodes()

    restoreMapFromModel()
  }

  function getSchema(definition) {
    let schema
    // var schema, shortName;
    // do we have an inline definition, a definition reference, or a basic type
    if (definition.properties || definition.items || definition.allOf) {
      // inline definition, so just use it as is
      schema = definition
    } else if (definition.$ref) {
      // reference, just use it as is
      schema = definition
    } else if (definition.type) {
      definition.$$byref = true
      schema = definition
    }
    return schema
  }

  function constructInputs(force) {
    if (!$scope.selectedNode.inputs) return
    if (!force && self.currentPage === 'map' && $scope.inputSchemas) {
      // inputs aren't being added or removed in this view, so no need to re-generate
      return
    }
    const inputSchemas = []

    Object.keys($scope.selectedNode.inputs).forEach(function(input) {
      const definition = $scope.selectedNode.inputs[input].schema
      let schema = getSchema(definition)
      if (!schema) return
      // take a copy if this is a reference to avoid dereferencing it in the model
      schema = schema.$ref ? angular.copy(schema) : schema
      schema.$$title = input
      inputSchemas.push(schema)
    })
    $scope.inputSchemas = inputSchemas
  }

  function constructOutputs(force) {
    if (!$scope.selectedNode.outputs) return
    if (!force && self.currentPage === 'map' && $scope.outputSchemas) {
      // inputs aren't being added or removed in this view, so no need to re-generate
      return
    }
    const outputSchemas = []
    Object.keys($scope.selectedNode.outputs).forEach(function(output) {
      const definition = $scope.selectedNode.outputs[output].schema
      let schema = getSchema(definition)
      if (!schema) return
      // take a copy if this is a reference to avoid dereferencing it in the model
      schema = schema.$ref ? angular.copy(schema) : schema
      schema.$$title = output
      outputSchemas.push(schema)
    })
    $scope.outputSchemas = outputSchemas
  }

  $scope.$on('input-output-modified', function() {
    constructInputs()
    constructOutputs()
  })

  $scope.$watch('inputSchemas', function() {
    $timeout(self.constructCanvas, backoffTime)
  })
  $scope.$watch('outputSchema', function() {
    $timeout(self.constructCanvas, backoffTime)
  })

  $scope.$watch(
    'selectedNode.inputs',
    function() {
      constructInputs()
    },
    true
  )
  $scope.$watch(
    'selectedNode.outputs',
    function() {
      constructOutputs()
    },
    true
  )

  $scope.$on('json-schema-view-property-collapsed', function() {
    $timeout(self.constructCanvas, backoffTime)
  })

  $scope.$on('json-schema-view-property-modified', function() {
    constructInputs(true)
    constructOutputs(true)
  })

  $scope.$on('json-schema-view-refresh', function(event, forceClear) {
    $timeout(function() {
      self.constructCanvas(forceClear)
    }, backoffTime)
  })

  let hashKey
  $scope.$watch(
    'selectedNode.$$hashKey',
    function() {
      if (hashKey === $scope.selectedNode.$$hashKey) return
      if (!hashKey) hashKey = $scope.selectedNode.$$hashKey
      $timeout(function() {
        $scope.map = new Map(
          $scope.selectedNode,
          $scope.swaggerDocument,
          self.updateModelActions
        )
        constructInputs(true)
        constructOutputs(true)
        self.constructCanvas()
      }, backoffTime)
    },
    true
  )
}

angular
  .module('apiconnect-assembly')
  .controller('MapperController', [
    '$rootScope',
    '$scope',
    '$mdDialog',
    '$timeout',
    'translateFilter',
    'Path',
    'Map',
    'ExtensionType',
    mapperController,
  ])

function targetConnectorController(
  $scope,
  $mdDialog,
  mapping,
  target,
  paths,
  valueType
) {
  if (mapping && mapping.source) {
    $scope.sources = angular.copy(mapping.source)
  }

  $scope.valueType = valueType

  $scope.merge = mapping && mapping.merge === true ? true : false

  $scope.emptyString = mapping && mapping.default === '' ? true : false

  if (mapping) {
    mapping.$stringifiedDefault =
      mapping.default === undefined || angular.isString(mapping.default)
        ? mapping.default
        : JSON.stringify(mapping.default)
    $scope.default = angular.copy(mapping.$stringifiedDefault)
  }

  if (mapping) {
    // Create a stringified value for the editor
    mapping.$stringifiedValue =
      mapping.value === undefined || angular.isString(mapping.value)
        ? mapping.value
        : JSON.stringify(mapping.value)
    $scope.value = angular.copy(mapping.$stringifiedValue)
  }

  // issue 411 - record the dimensionality of the target
  // this will be used to control whether or not to show users the merge checkbox
  // we can't rely on looking at source iterators here as there is an edge case
  // where the user is only setting a default value - no sources at all
  // instead, we should show the merge option for any connector with a positive
  // dimensionality
  $scope.mergeable = target.connectorDimension > 0

  if (mapping && mapping.target) {
    $scope.target = mapping.target
    let outputPath = paths[mapping.target]
    if (!outputPath && mapping.target.endsWith('.$item')) {
      const t = mapping.target.substring(
        0,
        mapping.target.lastIndexOf('.$item')
      )
      outputPath = paths[t]
    }
    const foreach = {}
    if (outputPath.getDimensionality() > 0 && $scope.sources) {
      const dimensions = outputPath.dimensionality
      dimensions.forEach(function(dimension) {
        foreach[dimension] = null
      })
      if (mapping && mapping.foreach) {
        let sourceContext = ''
        let targetContext = ''
        mapping.foreach.forEach(function(foreachObj) {
          if (foreachObj.source) {
            foreach[targetContext + foreachObj.target] =
              sourceContext + foreachObj.source
            sourceContext += `${foreachObj.source}.`
          } else {
            foreach[targetContext + foreachObj.target] = undefined // See Note_A below
          }
          targetContext += `${foreachObj.target}.`
        })
      }
      $scope.foreach = foreach
    }
  }

  // allow iterating over any input property - not just array types
  const iterators = []
  if ($scope.sources && $scope.sources.length > 0) {
    $scope.sources.forEach(function(inputPath) {
      if (!inputPath) return
      const sections = inputPath.split('.')
      if (sections.length === 1) {
        iterators.push(sections[0])
      } else {
        for (let i = 0; i < sections.length; i++) {
          const section = sections.slice(0, i + 1).join('.')
          if (iterators.indexOf(section) < 0) iterators.push(section)
        }
      }
    })
  }
  if (iterators.length > 0) $scope.iterators = iterators

  $scope.removeMapping = function(from) {
    $scope.sources = $scope.sources.filter(function(source) {
      return from !== source
    })
    if ($scope.sources.length === 0) delete $scope.sources
  }
  $scope.removeStaticValue = function() {
    delete $scope.value
  }
  $scope.deleteMapping = function() {
    $mdDialog.hide({
      remove: true,
    })
  }
  $scope.updateModel = function() {
    const response = {
      from: $scope.sources,
      value: $scope.value,
      remove: $scope.remove,
    }
    if ($scope.valueType) {
      response.valueType = $scope.valueType
    }
    if ($scope.emptyString === true) {
      response.default = ''
      response.emptyString = $scope.emptyString
    } else if ($scope.default !== undefined) {
      response.default = $scope.default
    }
    if ($scope.merge === true) {
      response.merge = $scope.merge
    }
    if ($scope.foreach) {
      response.foreach = []
      let sourceContext = ''
      let targetContext = ''
      Object.keys($scope.foreach).forEach(function(key) {
        if ($scope.foreach[key] === null) return
        if ($scope.foreach[key] === undefined) {
          // Note_A: A create was found for this target, but there is no source
          response.foreach.push({
            target: key.replace(targetContext, ''),
          })
          targetContext = `${key}.`
        } else {
          response.foreach.push({
            target: key.replace(targetContext, ''),
            source: $scope.foreach[key].replace(sourceContext, ''),
          })
          targetContext = `${key}.`
          sourceContext = `${$scope.foreach[key]}.`
        }
      })
      if (response.foreach.length === 0) delete response.foreach
    }
    $mdDialog.hide(response)
  }
  $scope.cancel = function() {
    $mdDialog.cancel()
  }
  $scope.aceLoaded = function(editor) {
    editor.focus()
  }
}

angular
  .module('apiconnect-assembly')
  .controller('TargetConnectorController', [
    '$scope',
    '$mdDialog',
    'mapping',
    'target',
    'paths',
    'valueType',
    targetConnectorController,
  ])

function pruneConfirmationController(
  $scope,
  $mdDialog,
  parentScope,
  saveDiscriminator,
  unrecognized
) {
  $scope.unrecognized = unrecognized
  $scope.prune = function() {
    parentScope.map.pruneModel(
      parentScope.inputSchemas,
      parentScope.outputSchemas,
      saveDiscriminator,
      false
    )
    $mdDialog.cancel()
    parentScope.pruneDialog = null
    parentScope.$emit('json-schema-view-refresh', false) // trigger canvas update
  }
  $scope.cancel = function() {
    $mdDialog.cancel()
    parentScope.pruneDialog = null
  }
}

angular
  .module('apiconnect-assembly')
  .controller('PruneConfirmationController', [
    '$scope',
    '$mdDialog',
    'parentScope',
    'saveDiscriminator',
    'unrecognized',
    pruneConfirmationController,
  ])

function actionConfirmationController(
  $scope,
  $mdDialog,
  parentScope,
  actionTargets
) {
  $scope.actionTargets = actionTargets
  $scope.ok = function() {
    $mdDialog.cancel()
    parentScope.actionDialog = null
  }
}

angular
  .module('apiconnect-assembly')
  .controller('ActionConfirmationController', [
    '$scope',
    '$mdDialog',
    'parentScope',
    'actionTargets',
    actionConfirmationController,
  ])

function MapInputOutputController($scope, $uibModal, translateFilter) {
  const self = this

  if ($scope.object.schema) {
    if (_.isEmpty($scope.object.schema)) {
      $scope.selectedDefinition = ''
    } else if ($scope.object.schema.$ref) {
      $scope.selectedDefinition = $scope.object.schema.$ref
    } else if (
      $scope.object.schema.type &&
      !$scope.object.schema.items &&
      !$scope.object.schema.properties &&
      !$scope.object.schema.allOf
    ) {
      $scope.selectedDefinition = $scope.object.schema.type
    } else {
      $scope.selectedDefinition = 'inline'
    }
  }

  self.contentTypes = [
    {
      value: 'none',
      name: translateFilter('none'),
    },
    {
      value: 'application/json',
      name: 'application/json',
    },
    {
      value: 'application/xml',
      name: 'application/xml',
    },
    {
      value: 'text/xml',
      name: 'text/xml',
    },
    {
      value: 'text/plain',
      name: 'text/plain',
    },
  ]

  $scope.$contentType = function(type) {
    if (arguments.length) {
      // Setter
      if (type === '' || type === 'none') {
        delete $scope.object.content
      } else {
        $scope.object.content = type
      }
    } else {
      // Getter
      return $scope.object.content || 'none'
    }
  }

  $scope.contentType = $scope.object.content || 'none'
  $scope.$watch('contentType', function() {
    if (typeof $scope.contentType === 'string')
      $scope.$contentType($scope.contentType)
  })

  $scope.$inputName = function(newName) {
    if (arguments.length) {
      // Setter
      const updatedInputs = {}
      for (const input in $scope.selectedNode.inputs) {
        if (input === $scope.name) {
          updatedInputs[newName] = $scope.selectedNode.inputs[input]
          $scope.map.inputRenamed(newName, input)
        } else {
          updatedInputs[input] = $scope.selectedNode.inputs[input]
        }
      }
      $scope.selectedNode.inputs = updatedInputs
      $scope.name = newName
    } else {
      // Getter
      return $scope.name
    }
  }
  $scope.$outputName = function(newName) {
    if (arguments.length) {
      // Setter
      const updatedOutputs = {}
      for (const output in $scope.selectedNode.outputs) {
        if (output === $scope.name) {
          updatedOutputs[newName] = $scope.selectedNode.outputs[output]
          $scope.map.outputRenamed(newName, output)
        } else {
          updatedOutputs[output] = $scope.selectedNode.outputs[output]
        }
      }
      $scope.selectedNode.outputs = updatedOutputs
      $scope.name = newName
    } else {
      // Getter
      return $scope.name
    }
  }
  let firstWatchSelectedDefinition = false
  $scope.$watch('selectedDefinition', function() {
    if (!firstWatchSelectedDefinition) {
      firstWatchSelectedDefinition = true
      return
    }
    if ($scope.selectedDefinition === 'inline') {
      self.launchInlineEditor()
    } else if ($scope.selectedDefinition.indexOf('#/') === 0) {
      $scope.object.schema = {
        $ref: $scope.selectedDefinition,
      }
    } else {
      // The selectedDefinition (i.e. 'float') is used to set the
      // schema using the built-in basicTypes
      if (basicTypes && basicTypes[$scope.selectedDefinition]) {
        $scope.object.schema = angular.copy(
          basicTypes[$scope.selectedDefinition]
        )
      } else {
        $scope.object.schema = {
          type: $scope.selectedDefinition,
        }
      }
    }
    $scope.$emit('input-output-modified')
  })
  self.launchInlineEditor = function() {
    $uibModal.open({
      template: require('../../html/inline-schema.html'),
      windowTemplate: require('../../html/main-template.html'),
      controller: 'InlineSchemaController',
      size: 'lg',
      scope: $scope,
    })
  }
}

angular
  .module('apiconnect-assembly')
  .controller('MapInputOutputController', [
    '$scope',
    '$uibModal',
    'translateFilter',
    MapInputOutputController,
  ])

function InlineSchemaController($scope, $uibModalInstance) {
  let x2js
  if (typeof window.X2JS === 'function') {
    x2js = new window.X2JS({
      attributePrefix: '@',
    })
  } else {
    x2js = {
      xml2js(xml) {
        return xml
      },
    }
  }

  // default to YAML
  $scope.selectedTabIndex = 0
  if (!_.isEmpty($scope.object.schema)) {
    $scope.inlineJsonSchema = angular.toJson($scope.object.schema, true)
    $scope.inlineYamlSchema = jsyaml.dump(
      angular.fromJson(angular.toJson($scope.object.schema)),
      {lineWidth: -1}
    )
    if ($scope.object.schema.example) {
      // JSON or XML?
      try {
        JSON.parse($scope.object.schema.example)
        $scope.inlineJsonObject = $scope.object.schema.example
      } catch (e) {
        $scope.inlineXMLObject = $scope.object.schema.example
      }
    }
  }

  $scope.validSchema = false
  $scope.$watch('inlineYamlSchema', function() {
    if ($scope.selectedTabIndex !== 0) return
    try {
      jsyaml.load($scope.inlineYamlSchema)
      $scope.validSchema = true
    } catch (error) {
      $scope.validSchema = false
    }
  })
  $scope.$watch('inlineJsonSchema', function() {
    if ($scope.selectedTabIndex !== 1) return
    try {
      JSON.parse($scope.inlineJsonSchema)
      $scope.validSchema = true
    } catch (error) {
      $scope.validSchema = false
    }
  })
  $scope.$watch('inlineJsonObject', function() {
    if ($scope.selectedTabIndex !== 2) return
    try {
      window.generateSchema(JSON.parse($scope.inlineJsonObject))
      $scope.validSchema = true
    } catch (error) {
      $scope.validSchema = false
    }
  })
  $scope.$watch('inlineXMLObject', function() {
    if ($scope.selectedTabIndex !== 3) return
    try {
      const asJson = x2js.xml2js($scope.inlineXMLObject)
      if (asJson === null) {
        $scope.validSchema = false
        return
      }
      window.generateSchema(asJson)
      $scope.validSchema = true
    } catch (error) {
      $scope.validSchema = false
    }
  })

  $scope.generate = function() {
    const schema = {
      type: 'object',
      allOf: [],
    }
    $scope.inputSchemas.forEach(function(input) {
      schema.allOf.push(input)
    })
    $scope.inlineSchema = $scope.asJson
      ? JSON.stringify(schema)
      : jsyaml.dump(schema)
  }

  var cleanJsonSchema = function(schema, object) {
    if (!schema || typeof object === 'undefined') return
    delete schema.required
    delete schema.$schema

    // have we worked our way down to a basic type?
    if (typeof object !== 'object') {
      delete schema.properties
      schema.type = typeof object
      return
    }

    if (schema.type === 'array') {
      delete schema.uniqueItems
      delete schema.minItems
      // look for a missing type property
      if (schema.items && schema.items.properties && !schema.items.type) {
        schema.items.type = 'object'
      }
      cleanJsonSchema(schema.items, object[0])
      return
    }
    for (const property in schema.properties) {
      delete schema.properties[property].minLength
      cleanJsonSchema(schema.properties[property], object[property])
    }
  }

  let namespaceMap = {}

  // ensures namespaces, prefixes, properties ordering
  function xmlSortFunction(a, b) {
    if (
      (a.indexOf('@') === 0 && b.indexOf('@') === 0) ||
      (a.indexOf('__') === 0 && b.indexOf('__') === 0)
    ) {
      return 0
    }
    if (a.indexOf('@') === 0 && b.indexOf('@') === 0) {
      return 0
    }
    if (a.indexOf('@') === 0) {
      return -1
    }
    if (a.indexOf('__') === 0 && b.indexOf('__') === 0) {
      return 0
    }
    if (a.indexOf('__') === 0 && b.indexOf('@') === 0) {
      return 1
    }
    if (a.indexOf('__') === 0) {
      return -1
    }
    if (b.indexOf('@') === 0 || b.indexOf('__') === 0) {
      return 1
    }
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }

  var injectXmlConfig = function(schema, object, currentNamespace) {
    if (!schema || typeof object === 'undefined') return

    delete schema.required
    delete schema.$schema

    // have we worked our way down to a basic type?
    if (typeof object !== 'object') {
      delete schema.properties
      schema.type = typeof object
      if (currentNamespace !== '') {
        // we have no namespace prefix here, but our parent does
        // so override...
        schema.xml = {
          namespace: '',
        }
      }
      return
    }
    if (schema.type === 'array') {
      delete schema.uniqueItems
      delete schema.minItems
      // look for a missing type property
      if (schema.items && schema.items.properties && !schema.items.type) {
        schema.items.type = 'object'
      }
      injectXmlConfig(schema.items, object[0], currentNamespace)
      return
    }
    // ensure iteration order
    // this way we pick up namespaces first
    const keys = Object.keys(schema.properties).sort(xmlSortFunction)

    // default override of parent namespace
    schema.xml = {
      namespace: '',
    }

    keys.forEach(function(property) {
      delete schema.properties[property].minLength
      if (property.indexOf('@') === 0) {
        // we have an attribute
        let nsName
        let attrProperty = property.substring(1)
        if (attrProperty.indexOf('xmlns') === 0) {
          delete schema.properties[property]
          schema.xml = {
            namespace: object[property],
          }
          nsName = attrProperty.split(':')
          nsName = nsName.length > 1 ? nsName[1] : ''
          namespaceMap[nsName] = object[property]
        } else {
          // is the attribute namespaced?
          if (attrProperty.indexOf(':') > 0) {
            const split = attrProperty.split(':')
            attrProperty = split[1]
            nsName = split[0]
          }
          schema.properties[attrProperty] = schema.properties[property]
          delete schema.properties[property]
          schema.properties[attrProperty].xml = {attribute: true}
          if (nsName) {
            schema.properties[attrProperty].xml.prefix = nsName
          }
        }
      } else if (property.indexOf('__') === 0) {
        // could be __prefix or __text
        // if it's __prefix, we want to add it to the xml config
        if (property === '__prefix') {
          if (object[property] !== currentNamespace) {
            currentNamespace = object[property]
            if (!schema.xml) schema.xml = {}
            schema.xml.prefix = object[property]
            if (namespaceMap[schema.xml.prefix]) {
              schema.xml.namespace = namespaceMap[schema.xml.prefix]
            }
          } else {
            // inherit...
            delete schema.xml
          }
        } else if (property === '__text') {
          // we're a text leaf node
          schema.type = 'string'
        }
        delete schema.properties[property]
      } else {
        if (!object[property] || typeof object[property] === 'function') {
          delete schema.properties[property]
        } else {
          injectXmlConfig(
            schema.properties[property],
            object[property],
            currentNamespace
          )
        }
      }
    })
    // we may have cleaned out all properties if those properties were namespace-related
    if (schema.properties && Object.keys(schema.properties).length === 0)
      delete schema.properties
  }

  $scope.generateAndShow = function() {
    let definition = ''
    let object
    let sanitizedExampleJSON
    switch ($scope.selectedTabIndex) {
      case 2:
        object = JSON.parse($scope.inlineJsonObject)
        definition = window.generateSchema(object)
        cleanJsonSchema(definition, object)
        sanitizedExampleJSON = JSON.stringify(object)
        definition.example = sanitizedExampleJSON
        break
      case 3:
        object = x2js.xml2js($scope.inlineXMLObject)
        definition = window.generateSchema(object)
        // clear any existing namespace map
        namespaceMap = {}
        injectXmlConfig(definition, object, '')
        definition.example = $scope.inlineXMLObject
        break
    }
    try {
      $scope.inlineYamlSchema = jsyaml.dump(definition)
    } catch (e) {
      $scope.inlineYamlSchema = jsyaml.dump(definition)
    }
    $scope.inlineJsonSchema = JSON.stringify(definition)
    $scope.object.schema = definition
    $scope.selectedTabIndex = 0
  }

  $scope.commit = function() {
    let definition = ''
    let object
    switch ($scope.selectedTabIndex) {
      case 0:
        definition = jsyaml.load($scope.inlineYamlSchema)
        break
      case 1:
        definition = JSON.parse($scope.inlineJsonSchema)
        break
      case 2:
        object = JSON.parse($scope.inlineJsonObject)
        definition = window.generateSchema(object)
        cleanJsonSchema(definition, object)
        definition.example = $scope.inlineJsonObject
        break
      case 3:
        object = x2js.xml2js($scope.inlineXMLObject)
        definition = window.generateSchema(object)
        // clear any existing namespace map
        namespaceMap = {}
        injectXmlConfig(definition, object, '')
        definition.example = $scope.inlineXMLObject
        break
    }
    $scope.object.schema = definition
    $uibModalInstance.close()
  }

  $scope.cancel = function() {
    $uibModalInstance.close()
  }
}

angular
  .module('apiconnect-assembly')
  .controller('InlineSchemaController', [
    '$scope',
    '$uibModalInstance',
    InlineSchemaController,
  ])
