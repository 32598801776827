// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

angular
  .module('apiconnect-assembly.recursion-helper', [])
  .factory('RecursionHelper', [
    '$compile',
    function($compile) {
      return {
        /**
         * Manually compiles the element, fixing the recursion loop.
         * @param {Element} element
         * @param {function} [link] A post-link function, or an object with function(s)
         * registered via pre and post properties.
         * @returns An object containing the linking functions.
         */
        compile(element, link) {
          // Normalize the link parameter
          if (angular.isFunction(link)) {
            link = {post: link}
          }

          // Break the recursion loop by removing the contents
          const contents = element.contents().remove()
          let compiledContents
          return {
            pre: link && link.pre ? link.pre : null,
            /**
             * Compiles and re-adds the contents
             */
            post(scope, element) {
              // Compile the contents
              if (!compiledContents) {
                compiledContents = $compile(contents)
              }
              // Re-add the compiled contents to the element
              compiledContents(scope, function(clone) {
                element.append(clone)
              })

              // Call the post-linking function, if any
              if (link && link.post) {
                link.post.apply(null, arguments)
              }
            },
          }
        },
      }
    },
  ])
