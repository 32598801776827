// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2018, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

angular.module('apiconnect-assembly-test').component('apimAssemblyTest', {
  bindings: {
    swaggerDocument: '<',
    orgId: '<',
    gateways: '<',
    products: '<',
    draftProducts: '<',
    applications: '<',
    application: '<',
    publishTargets: '<',
    tlsProfiles: '<',
    userRegistries: '<',
    config: '<',
    operations: '<',
    catalog: '<',
    consumerOrgs: '<',
    publishedApis: '<',
    deployedProduct: '<',
    republishStatus: '<',
    apiIsOnline: '<',
    statusIsSet: '<',
    setOperationFilter: '&',
    setTestMode: '&',
    setApplication: '&',
    setProduct: '&',
    setProductName: '&',
    createAndPublishProduct: '&',
    saveAndRepublishProduct: '&',
    republishProduct: '&',
    createApplication: '&',
    changeCatalog: '&',
    subscribeApp: '&',
    makeOnline: '&',
  },
  controller: 'AssemblerTestController',
  controllerAs: 'ctrl',
  template: require('../../html/assembly-test.html'),
})
