// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `カタログが更新されていません`,
  [`Catalog has been updated`]: `カタログが更新されました`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `ドラフト製品 {draftProduct}では、1 つ以上の API を参照する必要があります`,
  [`Number of Calls`]: `呼び出しの数`,
  [`Changes will apply to newly published APIs only`]: `変更は新しく公開された API にのみ適用されます`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `この API とゲートウェイ・タイプが異なるために互換性のない {label} があります。`,
  [`{label} is required`]: `{label} は必須です`,
  [`Application is required`]: `アプリケーションは必須です`,
  [`Plan is required`]: `プランは必須です`,
  [`Gateway is required`]: `ゲートウェイは必須です`,
  [`Consumer Org is required`]: `コンシューマー組織は必須です`,
  [`LDAP ATTRIBUTE NAME`]: `LDAP 属性名`,
  [`Use the build in Test Application (sandbox only)`]: `組み込みテスト・アプリケーションの使用 (サンドボックスのみ)`,
  [`Select an Application`]: `アプリケーションの選択`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `組み込みテスト・アプリケーションの使用 (サンドボックスのみ) - サンドボックス以外のカタログを選択した場合は無効になります`,
  [`Choose an existing Application`]: `既存アプリケーションの選択`,
  [`Use JWT for gateway authentication to analytics service`]: `分析サービスへのゲートウェイ認証に JWT を使用`,
  [`Select an application to consume the API`]: `API を使用するアプリケーションを選択します`,
  [`Use the default built-in Sandbox Catalog'`]: `デフォルトの組み込みサンドボックス・カタログの使用`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注: このカタログは、他のすべての設定の有効範囲として使用されます。この設定を変更するには、他の設定を調整する必要があります。`,
  [`Select a Consumer Org`]: `コンシューマー組織の選択`,
  [`Select the Consumer Org your generated application will belong to`]: `生成されたアプリケーションが属するコンシューマー組織を選択します`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `注: このオプションは、既存のアプリケーションが既にコンシューマー組織に属しているため、組み込みサンドボックス・テスト・アプリケーションを使用する場合にのみ変更できます`,
  [`Generate auto product`]: `自動製品の生成`,
  [`Select a product to associate the current API with`]: `現在の API を関連付ける製品を選択します`,
  [`Associate the current API to a selected product`]: `現在の API を選択した製品に関連付けます`,
  [`API management`]: `API 管理`,
  [`Select the API management instance you would like this API to be published to.`]: `この API の公開先とする API 管理インスタンスを選択します。`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注: このインスタンスは、他のすべての設定のスコープとして使用されます。 この設定を変更するには、他の設定を調整する必要があります。`,
  [`Provider organization`]: `プロバイダー組織`,
  [`Select the provider organization you would like this API to be published to.`]: `この API の公開先とするプロバイダー組織を選択します。`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注: この組織は、他のすべての設定のスコープとして使用されます。 この設定を変更するには、他の設定を調整する必要があります。`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注: このスペースは、他のすべての設定のスコープとして使用されます。 この設定を変更するには、他の設定を調整する必要があります。`,
  [`Selected a draft product`]: `ドラフト製品が選択されました`,
  [`Use any available gateway service`]: `使用可能な任意のゲートウェイ・サービスを使用します`,
  [`Select a compatible gateway service`]: `互換性のあるゲートウェイ・サービスの選択`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `注: このゲートウェイ・サービスは、他のすべての設定の有効範囲として使用されます。ポリシーおよびその他のゲートウェイ・サービスに固有のコンテンツは、この設定に基づいてロードされます。`,
  [`Select a gateway`]: `ゲートウェイの選択`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `注:「既存の製品」オプションが選択されている場合、このオプションは無効になり、代わりに生成された製品のデフォルト・プランが使用されます`,
  [`Select a Plan`]: `プランの選択`,
  [`Generate subscription`]: `サブスクリプションの生成`,
  [`A subscription will be created between the selected Product plan and Application`]: `選択した製品プランおよびアプリケーションの間でサブスクリプションが作成されます`,
  [`Apply a rate limit to the generated product`]: `生成した製品にレート制限を適用します`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `生成した製品のデフォルト・プランにレート制限を適用します。「既存の製品」オプションが選択されている場合、このオプションは無効になり、代わりに選択されたプランで定義されているレート制限が使用されます。`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `Lightweight Directory Access Protocol (LDAP) は、ネットワーク上に分散したディレクトリー情報サービスにアクセスし、維持するためのインターネット・プロトコルです。 エンタープライズ LDAP サービスが有効な場合は、ユーザー認証を提供するリソースとして構成してください。 新しい開発者ポータル・ユーザーにもユーザーのオンボーディングを可能にするには、「属性マッピング」セクションに記入して書き込み可能な LDAP を有効にする必要があります。 このセクションでは、「ユーザー管理」チェック・ボックスを選択して、ソース LDAP 属性名からターゲット API Connect の値へのマッピングを可能にします。`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `タスクの自己承認を有効にすると、オリジネーターのほか、コラボレーターもタスクを承認できるようになります。 このオプションは他の承認者がいない場合に便利ですが、実質的に別のユーザーによる確認を省略できます。`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `請求統合によって、顧客、支払方法、請求、およびアクティブ・サブスクリプションを管理するサード・パーティーのサブスクリプション請求システム内のアカウントに、API Connect を接続します`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `ネイティブ OAuth プロバイダー・オブジェクトは、OAuth トークンの生成や検証などの OAuth 処理操作のための設定を提供します。 OAuth プロバイダー・オブジェクトは、API を保護するための OAuth セキュリティー定義により参照できます。 ネイティブ OAuth プロバイダーが使用される場合、OAuth 操作は API Connect によりネイティブに実行されます。 すべての OAuth プロバイダー・オブジェクトにはバッキング API があります。 ここでの構成により、API の Swagger 文書が自動的に更新されます。 「API エディター」ページにナビゲートして、Swagger 文書を編集できます。 公開された API で OAuth プロバイダー・オブジェクトを参照すると、バッキング API が自動的にゲートウェイで使用可能になります。`,
  [`About token management`]: `トークン管理について`,
  [`About user security`]: `ユーザー・セキュリティーについて`,
  [`APIs or products`]: `API または製品`,
  [`ATM is not deployed, please contact your administrator`]: `ATM がデプロイされていません。管理者にお問い合わせください。`,
  [`Application state change approvals will be enabled`]: `アプリケーション状態変更の承認が有効になります`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `アプリケーション・ユーザーの資格情報の抽出、ID の認証、および権限の付与のために使用する設定を定義します。`,
  [`See console for details.`]: `詳細については、コンソールを参照してください。`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `選択された証明書マネージャー・サービスで管理している証明書はありません。`,
  [`The selected product is not published to the Sandbox catalog`]: `選択された製品はサンドボックス・カタログに公開されていません`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `選択された製品がサンドボックス・カタログに公開されていないため、アプリケーションをサブスクライブすることができません`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `ご使用のプラットフォーム用の IBM Cloud CLI (まだインストールされていない場合)。`,
  [`(Only supported by TLS 1.3)`]: `(TLS 1.3 でのみサポートされます)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `このフィールドの「@listSize」ディレクティブで、リストを返さないサイズ付きフィールドが定義されています。`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `このフィールドの「@listSize」ディレクティブで、フィールドの戻りの型で定義されていないサイズ付きフィールドが定義されています。`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `このフィールドの「@listSize」ディレクティブで、フィールドで定義されていないスライス引数が定義されています。`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `このフィールドの「@listSize」ディレクティブで、型が「Int」でも「Int!」でもないスライス引数が定義されています。`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `このフィールドの「@listSize」ディレクティブで、複数の非ヌルのスライス引数が定義されています。`,
  [`"{name}" (Role) has been created`]: `「{name}」 (ロール) が作成されました。`,
  [`"{name}" (Role) has been updated`]: `「{name}」 (ロール) が更新されました。`,
  [`API ({api}) has been created.`]: `API ({api}) が作成されました。`,
  [`API ({api}) has been renamed.`]: `API ({api}) が名前変更されました。`,
  [`API ({api}) has been updated.`]: `API ({api}) が更新されました。`,
  [`API ({api}) has been deleted.`]: `API ({api}) が削除されました。`,
  [`API ({api}) has been published.`]: `API ({api}) が公開されました。`,
  [`API ({api}) has validation error.`]: `API ({api}) で検証エラーが発生しました`,
  [`API ({api}) has validation errors.`]: `API ({api}) で検証エラーが発生しました`,
  [`API version`]: `API バージョン`,
  [`APIM data version`]: `APIM データのバージョン`,
  [`APIM schema update date`]: `APIM スキーマの更新日`,
  [`APIM schema version`]: `APIM スキーマのバージョン`,
  [`APIM target data version`]: `APIM ターゲット・データのバージョン`,
  [`APIM target schema version`]: `APIM ターゲット・スキーマのバージョン`,
  [`APIM update date`]: `APIM の更新日`,
  [`Product ({product}) has been created.`]: `製品 ({product}) が作成されました。`,
  [`Product ({product}) has been updated.`]: `製品 ({product}) が更新されました。`,
  [`Product ({product}) has been delete.`]: `製品 ({product}) が削除されました。`,
  [`Product ({product}) has been published.`]: `製品 ({product}) が公開されました。`,
  [`"{title}" ({type}) has been {changedName}.`]: `「{title}」 ({type}) が {changedName} されました。`,
  [`"{title}" ({type}) has not been {changedName}!`]: `「{title}」 ({type}) は {changedName} されていません。`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} (キューに入れられた後))`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} (送信された後))`,
  [`(none)`]: `(なし)`,
  [`(optional)`]: `(オプション)`,
  [`*Base endpoint list empty`]: `*基本エンドポイント・リストが空です`,
  [`*Must be a valid url`]: `* 有効な URL である必要があります`,
  [`*System will also send an email notification to the requester.`]: `*要求側への E メール通知も送信されます。`,
  [`+ redact from...`]: `+ 編集元...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. カタログ内でこの製品のライフサイクルを管理することもできます。`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x の後には 64 文字の 16 進数が続きます。`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) API コンシューマー組織を所有および管理する 2) アプリケーション開発者コミュニティーを管理する 3) API 定義および製品定義を作成する 4) API 製品ライフサイクルを管理する`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) API プロバイダー組織を所有および管理する 2) アプリケーション開発者コミュニティーを管理する 3) API 定義および製品定義を作成する 4) API 製品ライフサイクルを管理する`,
  [`5 Most Active APIs`]: `アクティブな API - 上位 5 件`,
  [`5 Most Active Products`]: `アクティブな製品 - 上位 5 件`,
  [`5 most active APIs`]: `アクティブな API - 上位 5 件`,
  [`5 most active Products`]: `アクティブな製品 - 上位 5 件`,
  [`503 Service Unavailable`]: `503 Service Unavailable`,
  [`A request to the server was made without any credentials.`]: `資格情報を指定せずにサーバーへの要求が行われました。`,
  [`A server error occurred`]: `サーバー・エラーが発生しました`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `TLS クライアント・プロファイルにより、API Connect が他のシステムにクライアントとして接続する際に使用される証明書チェーンと暗号スイートが構成されます。`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `TLS サーバー・プロファイルにより、API Connect が他のシステムにサーバー・エンドポイントを提示する際に使用される証明書チェーンと暗号スイートが構成されます。`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `カタログは、API 製品が公開されたときに、関連付けられた開発者ポータルに表示される API 製品のコレクションをホストします`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `カタログは、API 製品が公開されたときに、関連付けられた開発者ポータルに表示される API 製品のコレクションをホストします。`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `カタログは管理対象 API 製品のコレクションを表します。 公開された製品は、カタログに関連付けられた開発者ポータルでコンシューマーに表示されます。`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `API Connect とゲートウェイ管理エンドポイントのドメインとの間の接続を保護するには、証明書が必要です。 ゲートウェイとその処理対象ドメインの間の接続を保護する場合にも、証明書が必要です。 すべての証明書は、証明書マネージャー・サービスに保管されている必要があります。 このサービスは、証明書用のセキュアなリポジトリーを提供し、証明書の有効期限切れが近づくとユーザーに通知を送るので、障害の防止に役立ちます。 開始するには、`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `マップに対する入力が定義されていない場合に使用されるデフォルト値です。`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `ゲートウェイ・サービスは、公開済みの API をホストし、クライアント・アプリケーションが使用する API エンドポイントを提供する、ゲートウェイ・サーバーまたはコンテナーのセットを表します。 ゲートウェイは、バックエンド・システムに対する API プロキシー呼び出しを実行し、クライアント識別、セキュリティーおよびレート制限を含む API ポリシーを適用します。`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `ゲートウェイ・ホストは API を公開し、クライアント・アプリケーションが使用する API エンドポイントを提供します。 ゲートウェイはバックエンド・システムに対する API プロキシー呼び出しを実行し、クライアント 識別、セキュリティー、レート制限などを含む API ポリシーを適用します。`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `ソース製品にマイグレーション・ターゲットを設定して、サブスクリプションのマイグレーション・ターゲットを定義できます。  マイグレーション・ターゲットには、ソース製品のプランとターゲット製品のプランのマッピングを記述するプラン・マッピングが含まれます。  マイグレーション・ターゲットは開発者ポータルに表示され、ソース製品のサブスクライバーにマイグレーション・ターゲットを通知します。`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `モジュラー OAuth ポリシー・キット。 これは、すべての OAuth/OpenID Connect プロトコル・ステップを 1 つのポリシーで実行することも (デフォルト)、微調整するために複数のポリシーに分割して、1 つまたは「n」個のステップのみを実行することもできます。 各ステップの入出力は、記載されたコンテキスト変数によって駆動されます。 必要に応じて、サポートされる OAuth コンポーネントを追加または削除してください。`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `使用可能なポータル・サービスがなければ、ポータルは作成できません。 サポートについては、クラウド管理者にお問い合わせください。`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `プロバイダー組織とは、API および関連するプランと製品を所有するチームのことです。 プロバイダー組織には、すべての許可を割り当てた所有者が 1 人必要です。 所有者はメンバーから割り当てられます。`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `プロバイダー組織には、API、製品、プラン、カタログ、コンシューマー組織、およびその他の関連リソースを管理するユーザーが含まれます。 プロバイダー組織を使用して、ユーザーおよび API 管理リソースをプロジェクト別、部門別、ライフサイクル・ステージ別などにグループ化することができます。 ユーザー特権を管理するロールの管理および割り当てをするには、IBM Cloud Identity and Access Management に進みます。`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `出力の値に解決されるシンプルな JavaScript 式。 これは静的ストリング (「任意の静的ストリング」) にするか、マップされる入力に関する式 ($(input.firstName) + " " + $(input.lastName)) にすることができます`,
  [`ACTION`]: `アクション`,
  [`ANALYTICS`]: `分析`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `API 管理者`,
  [`API CONNECT VALUE`]: `API Connect の値`,
  [`API assemble`]: `API アセンブル`,
  [`API base endpoints`]: `API の基本エンドポイント`,
  [`API base URL`]: `API の基本 URL`,
  [`API base URLs`]: `API の基本 URL`,
  [`API Calls`]: `API 呼び出し`,
  [`API Connect UI`]: `API Connect UI`,
  [`API Connect services`]: `API Connect サービス`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect では、招待およびアクティベーション・リンクをメンバーに送信するなどの目的のために E メール・サーバーが必要です。`,
  [`API Connect verify token`]: `API Connect のトークンの検証`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `API Designer ライセンス`,
  [`API Designer credentials`]: `API Designer 資格情報`,
  [`API Designer licence`]: `API Designer ライセンス`,
  [`API Designer does not currently support WSDL`]: `現在、API Designer では WSDL はサポートされていません`,
  [`API editor`]: `API エディター`,
  [`API Endpoint`]: `API エンドポイント`,
  [`API Endpoint Base`]: `API エンドポイント・ベース`,
  [`API Endpoint for Unenforced APIs`]: `強制されない API に対する API エンドポイント`,
  [`API Endpoint for unenforced APIs`]: `強制されない API に対する API エンドポイント`,
  [`API Endpoints`]: `API エンドポイント`,
  [`API Error`]: `API エラー`,
  [`API gateway`]: `API ゲートウェイ`,
  [`API Gateway`]: `API ゲートウェイ`,
  [`API gateways`]: `API ゲートウェイ`,
  [`API Gateways`]: `API ゲートウェイ`,
  [`API Invocation Endpoint`]: `API 呼び出しエンドポイント`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `API は公開されません。エクスプローラーでの API テスト (試す) は無効になります。`,
  [`API Key`]: `API キー`,
  [`API key`]: `API キー`,
  [`API key secret`]: `API キー・シークレット`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `API Manager のローカル・ユーザー・レジストリー`,
  [`API Manager URL`]: `API Manager の URL`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `API パス・パラメーター`,
  [`API Security Definition`]: `API セキュリティー定義`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `この API に対するエクスプローラーでの API テスト (試す) は施行されていないため、有効になりません。`,
  [`API Setup`]: `API のセットアップ`,
  [`API Subscription`]: `API サブスクリプション`,
  [`API endpoint`]: `API エンドポイント`,
  [`API endpoint base`]: `API エンドポイント・ベース`,
  [`API endpoint for unenforced APIs`]: `強制されない API に対する API エンドポイント`,
  [`API endpoints`]: `API エンドポイント`,
  [`API error`]: `API エラー`,
  [`API invocation endpoint`]: `API 呼び出しエンドポイント`,
  [`API is not enforced on the API gateway.`]: `API ゲートウェイに API が施行されていません。`,
  [`API path parameters`]: `API パス・パラメーター`,
  [`API security definition`]: `API セキュリティー定義`,
  [`API setup`]: `API のセットアップ`,
  [`API subscription`]: `API サブスクリプション`,
  [`API Update`]: `API の更新`,
  [`API user registries`]: `API ユーザー・レジストリー`,
  [`API and product generated successfully!`]: `API および製品が正常に生成されました。`,
  [`API deletion failed`]: `API の削除に失敗しました`,
  [`API does not exist!`]: `API が存在しません。`,
  [`API from existing REST service`]: `既存の REST サービスからの API`,
  [`API from existing SOAP service`]: `既存の SOAP サービスからの API`,
  [`API has been updated.`]: `API が更新されました。`,
  [`API has been updated`]: `APIが更新されました`,
  [`API has not been created!`]: `API は作成されていません。`,
  [`API has not been updated!`]: `API が更新されていません。`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `API 優先順位 (OpenAPI 定義に定義されたバニティー・エンドポイントを使用)`,
  [`API properties`]: `API プロパティー`,
  [`API protection source`]: `API 保護ソース`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `製品から参照された API は存在しません。 最初に API を作成またはインポートしてください。`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `API の要求および応答のペイロード構造は、OpenAPI スキーマ定義を使用して構成されます。`,
  [`API subscription request approved for app {{appName}}`]: `アプリケーション {appName}の API サブスクリプション要求が承認されました`,
  [`API subscription request denied for app {{appName}}`]: `アプリケーション {appName}の API サブスクリプション要求が拒否されました`,
  [`API subscription request received for app {{appName}}`]: `アプリケーション {appName}の API サブスクリプション要求を受け取りました`,
  [`API type`]: `API タイプ`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `「apis」プロパティーの下にキー「{key}」を持つ API が存在しないため、リストできません。 「ソース」タブを確認してください。`,
  [`APIs`]: `API`,
  [`APIs and products`]: `API および製品`,
  [`APIs included in the plan.`]: `プランに組み込まれている API。`,
  [`API Key is created`]: `API キーが作成されました`,
  [`API Key is deleted`]: `API キーが削除されました`,
  [`API Key is successfully created`]: `API キーが正常に作成されました`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `API キーのタイムアウト: **{seconds} 秒 ({time})**`,
  [`API key timeout`]: `API キーのタイムアウト`,
  [`API Key timeout has been changed`]: `API キーのタイムアウトが変更されました`,
  [`Application type`]: `アプリケーション・タイプ`,
  [`Associated analytics service`]: `関連付けられた分析サービス`,
  [`AVAILABILITY`]: `可用性`,
  [`AVAILABILITY ZONE`]: `アベイラビリティー・ゾーン`,
  [`About`]: `製品情報`,
  [`About audit setting`]: `監査設定について`,
  [`About Authentication URL user registry`]: `認証 URL ユーザー・レジストリーについて`,
  [`About availability zones`]: `アベイラビリティー・ゾーンについて`,
  [`About catalogs`]: `カタログについて`,
  [`About consumer organization owners`]: `コンシューマー組織の所有者について`,
  [`About consumer organizations`]: `コンシューマー組織について`,
  [`About developer portals`]: `開発者ポータルについて`,
  [`About email servers`]: `E メール・サーバーについて`,
  [`About Endpoints`]: `エンドポイントについて`,
  [`About gateways`]: `ゲートウェイについて`,
  [`About gateway visibility`]: `ゲートウェイの可視性について`,
  [`About Gateway Processing Status`]: `ゲートウェイ処理状況について`,
  [`About introspection`]: `イントロスペクションについて`,
  [`About Keystores`]: `鍵ストアについて`,
  [`About LDAP`]: `LDAP について`,
  [`About local user registries`]: `ローカル・ユーザー・レジストリーについて`,
  [`About metadata`]: `メタデータについて`,
  [`About Migration Target`]: `マイグレーション・ターゲットについて`,
  [`About migration targets`]: `マイグレーション・ターゲットについて`,
  [`About native OAuth provider`]: `ネイティブ OAuth プロバイダーについて`,
  [`About OpenID Connect`]: `OpenID Connect について`,
  [`About replacing a product`]: `製品の置き換えについて`,
  [`About Role defaults`]: `ロールのデフォルトについて`,
  [`About roles`]: `ロールについて`,
  [`About scopes`]: `スコープについて`,
  [`About Set Migration Targets`]: `マイグレーション・ターゲットの設定について`,
  [`About spaces`]: `スペースについて`,
  [`About Subscription`]: `サブスクリプションについて`,
  [`About superseding a product`]: `製品の取り替えについて`,
  [`About TLS server profile`]: `TLS サーバー・プロファイルについて`,
  [`About TLS client profiles`]: `TLS クライアント・プロファイルについて`,
  [`About TLS server profiles`]: `TLS サーバー・プロファイルについて`,
  [`About third party OAuth provider`]: `サード・パーティー OAuth プロバイダーについて`,
  [`About tokens`]: `トークンについて`,
  [`About Transfer Ownership`]: `所有権の移転について`,
  [`About Transferring Ownership`]: `所有権の移転について`,
  [`About Truststores`]: `トラストストアについて`,
  [`About adding a member`]: `メンバーの追加について`,
  [`About adding provider organization member`]: `プロバイダー組織メンバーの追加について`,
  [`About adding members to a Space`]: `スペースへのメンバーの追加について`,
  [`About adding members to a catalog`]: `カタログへのメンバーの追加について`,
  [`About changing owners`]: `所有者の変更について`,
  [`About enabling gateway services`]: `ゲートウェイ・サービスの有効化について`,
  [`About inviting a member`]: `メンバーの招待について`,
  [`About inviting catalog members`]: `カタログ・メンバーの招待について`,
  [`About inviting provider organization members`]: `プロバイダー組織のメンバーの招待について`,
  [`About inviting space members`]: `スペース・メンバーの招待について`,
  [`About keystores`]: `鍵ストアについて`,
  [`About managing Space membership`]: `スペース・メンバーシップの管理について`,
  [`About migrating subscriptions`]: `サブスクリプションのマイグレーションについて`,
  [`About provider organizations`]: `プロバイダー組織について`,
  [`About roles and role defaults`]: `ロールおよびロールのデフォルトについて`,
  [`About roles in spaces`]: `スペース内のロールについて`,
  [`About selecting gateway services`]: `ゲートウェイ・サービスの選択について`,
  [`About send message`]: `メッセージの送信について`,
  [`About sending messages`]: `メッセージの送信について`,
  [`About the analytics service`]: `分析サービスについて`,
  [`About the DNS Scheme`]: `DNS スキームについて`,
  [`About the Gateway Service`]: `ゲートウェイ・サービスについて`,
  [`About the Portal Service`]: `ポータル・サービスについて`,
  [`About the product lifecycle`]: `製品ライフサイクルについて`,
  [`About the provider organization owner`]: `プロバイダー組織の所有者について`,
  [`About this Cloud`]: `このクラウドについて`,
  [`About transferring ownership`]: `所有権の移転について`,
  [`About truststores`]: `トラストストアについて`,
  [`About visibility`]: `可視性について`,
  [`About visibility and subscribability`]: `可視性およびサブスクライブ可能性について`,
  [`Accept`]: `Accept`,
  [`Access Code`]: `アクセス・コード`,
  [`Access Token`]: `アクセス・トークン`,
  [`Access URL through Secure Gateway`]: `Secure Gateway 経由で URL にアクセス`,
  [`Access code`]: `アクセス・コード`,
  [`Access code is not chosen in supported grant type.`]: `サポートされる権限付与タイプでアクセス・コードが選択されていません。`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Secure Gateway 経由で URL にアクセスします。 ゲートウェイが設定されるのは、API がステージング済み状態から公開済み状態に移行したときです。`,
  [`Access token TTL has been changed`]: `アクセス・トークン TTL が変更されました`,
  [`Access token time to live`]: `アクセス・トークンの有効期間`,
  [`Access token time-to-live`]: `アクセス・トークンの有効期間`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `アクセス・トークンの有効期限: **{seconds} 秒 ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `アクセス・トークンは、クライアント・アプリケーションがアプリケーション・ユーザーに代わってリソースにアクセスできるようにするためにアプリケーションに付与されます。  リフレッシュ・トークンは、現行のアクセス・トークンが無効または期限切れになったときに新規アクセス・トークンを取得するため、または同じスコープあるいはより狭いスコープの追加アクセス・トークンを取得するために、クライアントに対して発行されます。 さらに、任意の数のアクセス・トークンとリフレッシュ・トークンの組み合わせにより付与される同意の有効期間を指定できます。`,
  [`Access tokens time to live (seconds)`]: `アクセス・トークンの有効期間 (秒)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `アクセス・トークン、ID トークン、および一時トークンを任意の既存の鍵ストアに割り当てることができます。 鍵ストア割り当ての履歴が保持されます。`,
  [`Account`]: `アカウント`,
  [`Account has been updated.`]: `アカウントが更新されました。`,
  [`Account password has been changed.`]: `アカウント・パスワードが変更されました。`,
  [`account-approved`]: `アカウント承認済み`,
  [`account-denied`]: `アカウント拒否`,
  [`account-pending-approval`]: `アカウント保留承認`,
  [`Action`]: `アクション`,
  [`Activate API`]: `API のアクティブ化`,
  [`Activate API|button text`]: `API のアクティブ化`,
  [`Activate API for testing|title`]: `API のアクティブ化`,
  [`Activation link`]: `アクティベーション・リンク`,
  [`Active`]: `アクティブ`,
  [`Activity log`]: `アクティビティー・ログ`,
  [`Activity log API setting is not available for this API's gateway type.`]: `この API のゲートウェイ・タイプの場合、アクティビティー・ログ API の設定は使用できません。`,
  [`Active loading indicator`]: `アクティブ・ロード・インディケーター`,
  [`Add`]: `追加`,
  [`Add allowlist`]: `許可リストの追加`,
  [`Add a new billing integration in Resources`]: `リソース内の新規請求統合の追加`,
  [`Add API user registries`]: `API ユーザー・レジストリーの追加`,
  [`Add APIs to Product`]: `製品への API の追加`,
  [`Add another cloud`]: `別のクラウドの追加`,
  [`Add billing integration`]: `請求統合の追加`,
  [`Add Burst Limit`]: `バースト制限の追加`,
  [`Add Ciphers for TLS client profile`]: `TLS クライアント・プロファイルの暗号の追加`,
  [`Add Ciphers for TLS server profile`]: `TLS サーバー・プロファイルの暗号の追加`,
  [`Add Condition`]: `条件の追加`,
  [`Add Consumer Role`]: `コンシューマー・ロールの追加`,
  [`Add Count Limit`]: `カウント制限の追加`,
  [`Add Credential`]: `資格情報の追加`,
  [`Add domain`]: `ドメインの追加`,
  [`Add credential`]: `資格情報の追加`,
  [`Add Extension`]: `拡張の追加`,
  [`Add GraphQL schema`]: `GraphQL スキーマの追加`,
  [`Add HTTP Endpoint`]: `HTTP エンドポイントの追加`,
  [`Add group`]: `グループの追加`,
  [`Add JSON schema`]: `JSON スキーマの追加`,
  [`Add Keystore to TLS client profile`]: `TLS クライアント・プロファイルへの鍵ストアの追加`,
  [`Add Keystore to TLS server profile`]: `TLS サーバー・プロファイルへの鍵ストアの追加`,
  [`Add member`]: `メンバーの追加`,
  [`Add New Role for Consumer organization`]: `コンシューマー組織用の新規ロールの追加`,
  [`Add operation`]: `操作の追加`,
  [`Add Parameters`]: `パラメーターの追加`,
  [`Add Policy`]: `ポリシーの追加`,
  [`Add Rate limit`]: `レート制限の追加`,
  [`Add Rate Limit`]: `レート制限の追加`,
  [`Add role`]: `ロールの追加`,
  [`Add Syslog TCP Endpoint`]: `Syslog TCP エンドポイントの追加`,
  [`Add Syslog TLS Endpoint`]: `Syslog TLS エンドポイントの追加`,
  [`Add Syslog UDP Endpoint`]: `Syslog UDP エンドポイントの追加`,
  [`Add XML schema`]: `XML スキーマの追加`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `このカタログ内の API に対して呼び出しを使用できるようにするゲートウェイ・エンドポイントを追加します。`,
  [`Add a different Cloud Connection.`]: `異なるクラウド接続を追加します。`,
  [`Add a member from the user registry`]: `ユーザー・レジストリーからメンバーを追加`,
  [`Add a user to the Admin organization, and assign the required roles`]: `ユーザーを管理者組織に追加して、必要なロールを割り当てます`,
  [`Add a user to the provider organization, and assign the required roles`]: `ユーザーをプロバイダー組織に追加して、必要なロールを割り当てます`,
  [`Add a user to the Catalog, and assign the required roles`]: `ユーザーをカタログに追加して、必要なロールを割り当てます`,
  [`Add a user to the Space, and assign the required roles`]: `ユーザーをスペースに追加して、必要なロールを割り当てます`,
  [`Add action`]: `アクションの追加`,
  [`Add additional client ID/client secret pairs`]: `クライアント ID/クライアント秘密鍵のペアを追加`,
  [`Add blocklist`]: `ブロックリストの追加`,
  [`Add case`]: `ケースの追加`,
  [`Add catch`]: `Catch の追加`,
  [`Add default catch`]: `デフォルト Catch の追加`,
  [`Add destination`]: `宛先の追加`,
  [`Add entry`]: `エントリーの追加`,
  [`Add group failed.`]: `グループの追加が失敗しました。`,
  [`Add input`]: `入力の追加`,
  [`Add media type`]: `メディア・タイプの追加`,
  [`Add member failed`]: `メンバーの追加に失敗しました`,
  [`Add object`]: `オブジェクトの追加`,
  [`Add otherwise`]: `それ以外の場合の追加`,
  [`Add output`]: `出力の追加`,
  [`Add outputs for operation...`]: `操作用の出力の追加...`,
  [`Add parameters for operation...`]: `操作用のパラメーターの追加...`,
  [`Add parameters to this API`]: `この API にパラメーターを追加`,
  [`Add plans to product`]: `製品にプランを追加します`,
  [`Add plans to this product`]: `この製品にプランを追加します`,
  [`Add schema`]: `スキーマの追加`,
  [`Add scopes for this OAuth provider.`]: `この OAuth プロバイダーのスコープを追加します。`,
  [`Add scopes to allow access to`]: `次へのアクセスを許可するためにスコープを追加します:`,
  [`Add scopes to allow access to.`]: `アクセスを許可するためにスコープを追加します。`,
  [`Add tags and external documentation details for this API`]: `この API のタグおよび外部ドキュメンテーションの詳細を追加`,
  [`Add to`]: `追加先`,
  [`Add to existing product`]: `既存の製品への追加`,
  [`Add/Remove APIs`]: `API の追加/削除`,
  [`AddProperties`]: `プロパティーの追加`,
  [`Added APIs`]: `API が追加されました`,
  [`Added rate limit`]: `レート制限が追加されました`,
  [`Added rate limits`]: `レート制限が追加されました`,
  [`Adding an API to a product for publishing`]: `公開のための製品への API の追加`,
  [`Additional support`]: `その他のサポート`,
  [`Additional properties`]: `追加プロパティー`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `また、関連付けられているレジストリーである {name} カタログ・ユーザー・レジストリーが使用されていない場合は、そのレジストリーの削除を検討してください。`,
  [`Address`]: `アドレス`,
  [`Admin DN`]: `管理 DN`,
  [`Admin organization invitation timeout`]: `管理者組織の招待タイムアウト`,
  [`Admin organization invitation timeout has been changed`]: `管理者組織の招待タイムアウトが変更されました`,
  [`Admin password`]: `管理パスワード`,
  [`Admin request reset password`]: `管理パスワードのリセット要求`,
  [`Admin reset password`]: `管理パスワードのリセット`,
  [`Admin Sign In`]: `管理サインイン`,
  [`Admin add catalog failed`]: `管理者によるカタログの追加が失敗しました`,
  [`Admin add space failed`]: `管理者によるスペースの追加が失敗しました`,
  [`Administer consumer organizations`]: `コンシューマー組織の管理`,
  [`Administers the API consumer organization`]: `API コンシューマー組織を管理します`,
  [`Administers the API provider organization`]: `API プロバイダー組織を管理します`,
  [`Administers the admin organization`]: `管理者組織を管理します`,
  [`Administers the admin topology`]: `管理トポロジーを管理します`,
  [`Administers the app developer organization`]: `アプリケーション開発者組織を管理します`,
  [`Administers the catalog`]: `カタログを管理します`,
  [`Administers the cloud topology`]: `クラウド・トポロジーを管理します`,
  [`Administers the space`]: `スペースを管理します`,
  [`Administration management console`]: `管理コンソール`,
  [`Administrator`]: `管理者`,
  [`Advanced analytics configuration`]: `拡張分析構成`,
  [`Advanced features`]: `拡張機能`,
  [`Advanced scope check`]: `拡張スコープ検査`,
  [`Advanced scope check after Token Validation`]: `トークン検証後の拡張スコープ検査`,
  [`Advanced scope check before Token Generation`]: `トークン生成前の拡張スコープ検査`,
  [`Aggregation`]: `集約`,
  [`Agree`]: `同意`,
  [`All`]: `すべて`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `開発者ポータルに登録しているコンシューマー組織内のすべての認証済み開発者が、この製品を表示できます。`,
  [`All consumer organizations will be able to see this product.`]: `すべてのコンシューマー組織がこの製品を表示できるようになります。`,
  [`Allowlist`]: `許可リスト`,
  [`Allow Chunked Uploads`]: `チャンク・アップロードを許可`,
  [`Allow chunked uploads`]: `チャンク・アップロードを許可`,
  [`Allow "plain" challenge method`]: `「プレーン」チャレンジ方式を許可`,
  [`Allow renegotiation`]: `再ネゴシエーションを許可`,
  [`Allow case sensitive usernames`]: `大/小文字が区別されるユーザー名を許可`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `クライアントが、サーバーとの最初のハンドシェーク中に、SNI 拡張内に目的のホスト名を挿入できるようにします。`,
  [`Allow connection to be renegotiated`]: `接続の再ネゴシエーションを許可します`,
  [`Allow default introspection`]: `デフォルト・イントロスペクションを許可`,
  [`Allow insecure server connections`]: `非セキュア・サーバー接続を許可する`,
  [`Allow null value`]: `ヌル値を許可`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `ログアウトのための 302 リダイレクトで access_token/refresh_token の送信を許可`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `すべての推奨が適用されています。 スキーマに関する警告はありません。`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `開発者ポータルでテスト・ツールを使用して API の操作をテストできるようにします。`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `脆弱または非セキュアな資格情報を使用して接続を続行できるようにします`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `脆弱または非セキュアな資格情報を使用して接続を続行できるようにします。`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `外部 OIDC プロバイダーが、API Manager ではなく開発者ポータルと通信できるようにします。`,
  [`Allow to manage this user registry`]: `このユーザー・レジストリーの管理を許可`,
  [`Allow to manage users under this user registry`]: `このユーザー・レジストリーの下のユーザーの管理を許可`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `発行者が発行したトークンが APIC に提示された場合に、ユーザーに自動オンボードを許可します`,
  [`Already have an account?`]: `既にアカウントをお持ちの場合`,
  [`Also transfer ownership of owned Spaces`]: `所有しているスペースの所有権も移転`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `有効にすると、常に userinfo エンドポイントからユーザー・データをフェッチします`,
  [`Always output the root element`]: `常にルート・エレメントを出力`,
  [`Always output the root element.`]: `常にルート・エレメントを出力`,
  [`Always required`]: `常に必須`,
  [`Always use userinfo endpoint`]: `常に userinfo エンドポイントを使用します`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `OAuth プロバイダー API には、OAuth フローの許可エンドポイントとトークン・エンドポイントが含まれています。 ここで OAuth プロバイダーを構成します。その後、OAuth で保護された API を作成するときに、必要なプロバイダーを選択できます。`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `OAuth プロバイダーには、OAuth フローの許可エンドポイントとトークン・エンドポイントが含まれます。OAuth を使用して API を保護するために使用する OAuth プロバイダーを選択してください`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `開発者が、アプリケーションで関連 API を呼び出せるように、アプリケーションでスペース内のプランをサブスクライブすると、そのアプリケーションがここにリストされます。開発者のアプリケーションを中断して、API へのアクセスをブロックできるほか、独自のアプリケーションを作成することもできます。 [詳細情報]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `認証 URL は、ユーザー・レジストリーとして第三者認証プロバイダーを指します。 認証 URL を使用すると、LDAP 以外のサード・パーティー・ユーザー・レジストリーと統合することができます。`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `認証 URL は、カスタム ID プロバイダーに対してユーザーを認証するためのシンプルな仕組みです。`,
  [`An error occurred communicating with the gateways subsystem.`]: `ゲートウェイのサブシステムとの通信中にエラーが発生しました。`,
  [`An organization is required.`]: `組織が必要です。`,
  [`An unknown error occurred.`]: `不明なエラーが発生しました。`,
  [`Analytics`]: `分析`,
  [`Analytics Insights`]: `分析のインサイト`,
  [`Analytics Insights Service'`]: `アナリティクス・インサイト・サービス`,
  [`Analytics Details`]: `分析の詳細`,
  [`Analytics destinations`]: `分析の宛先`,
  [`Analytics Director keystore`]: `Analytics Director 鍵ストア`,
  [`Analytics Service`]: `分析サービス`,
  [`Analytics Service URL`]: `分析サービス URL`,
  [`Analytics Service {arg} has been removed.`]: `分析サービス {arg} は削除されました。`,
  [`Analytics details`]: `分析の詳細`,
  [`Analytics service`]: `分析サービス`,
  [`Analytics service details`]: `分析サービスの詳細`,
  [`Analytics service URL`]: `分析サービス URL`,
  [`Analytics service {arg} has been removed.`]: `分析サービス {arg} は削除されました。`,
  [`Analytics client TLS client profile`]: `分析クライアント TLS クライアント・プロファイル`,
  [`Analytics client keystore`]: `分析クライアント鍵ストア`,
  [`Analytics client truststore`]: `分析クライアント・トラストストア`,
  [`Analytics ingestion TLS client profile`]: `分析取り込み TLS クライアント・プロファイル`,
  [`Analytics ingestion keystore`]: `分析取り込み鍵ストア`,
  [`Analytics ingestion truststore`]: `分析取り込みトラストストア`,
  [`Analytics service {title} has been created.`]: `分析サービス {title} が作成されました。`,
  [`Analytics service {title} has been updated.`]: `分析サービス {title} が更新されました。`,
  [`The selected consumer organization is`]: `選択したコンシューマー組織:`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `分析サービスは構成済みで、分析データは外部にオフロードされました`,
  [`Analyze API usage and performance`]: `API 使用量とパフォーマンスの分析`,
  [`Anonymous bind`]: `匿名バインド`,
  [`Another user registry`]: `別のユーザー・レジストリー`,
  [`api-administrator`]: `API 管理者`,
  [`Api-Analytics`]: `API 分析`,
  [`Api-Drafts`]: `API-ドラフト`,
  [`App`]: `アプリケーション`,
  [`App name`]: `アプリケーション名`,
  [`App-Analytics`]: `アプリケーションの分析`,
  [`App-Approval`]: `アプリケーションの承認`,
  [`App-Dev`]: `アプリケーションの開発`,
  [`App-analytics`]: `アプリケーションの分析`,
  [`App-dev`]: `アプリケーションの開発`,
  [`app-lifecycle-approved`]: `アプリ・ライフサイクル承認済み`,
  [`app-lifecycle-cancelled`]: `アプリ・ライフサイクルのキャンセル`,
  [`app-lifecycle-denied`]: `アプリ・ライフサイクルの拒否`,
  [`app-lifecycle-pending`]: `アプリ・ライフサイクルの保留`,
  [`app-lifecycle-request`]: `アプリ・ライフサイクル要求`,
  [`app-reinstated`]: `アプリの復元`,
  [`app-suspended`]: `アプリの中断`,
  [`Application`]: `アプリケーション`,
  [`Application Authentication`]: `アプリケーション認証`,
  [`Application Authentication Source`]: `アプリケーション認証ソース`,
  [`Application lifecycle`]: `アプリケーション・ライフサイクル`,
  [`Application approval task for upgrading application`]: `アプリケーションを次の状態にアップグレードするためのアプリケーションの承認タスク:`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `{username} ({orgname}) によって要求された、アプリケーション {taskname} を実動にアップグレードするためのアプリケーションの承認タスク`,
  [`Application authentication`]: `アプリケーション認証`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `アプリケーション開発者は、開発者ポータルを使用して、最初に 1 つ以上のプランに対してアプリケーションをサブスクライブします。 ただし、選択したプランについて、アプリケーション・サブスクリプションを別の製品のプランにマイグレーションできます。`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `アプリケーション開発者は、開発者ポータルを使用して、最初に 1 つ以上のプランに対してアプリケーションをサブスクライブします。 ただし、選択したプランについて、アプリケーション・サブスクリプションを別の製品のプランにマイグレーションできます。`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `アプリケーション開発者はアプリケーションでプランをサブスクライブすることで、そのプラン内の API を呼び出せるようにします。`,
  [`Application is being created. Please Wait`]: `アプリケーションを作成中です。 お待ちください。`,
  [`Application scope check`]: `アプリケーション・スコープ検査`,
  [`Applications`]: `アプリケーション`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `アプリケーション・ライフサイクルをオンにする前に存在していたアプリケーションは、実動状態のままです`,
  [`Applied security`]: `セキュリティーが適用されました`,
  [`Apply`]: `適用`,
  [`Apply all`]: `すべて適用`,
  [`Apply all suggestions`]: `すべての候補を適用`,
  [`Apply analysis configuration`]: `分析の構成を適用`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `操作内から既存のサービスを呼び出すには、invoke ポリシーを適用します。 このポリシーは、JSON データまたは XML データとともに使用することが可能で、アセンブリー内で複数回適用できます。`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `操作内から既存のサービスを呼び出すには、websocket アップグレード・ポリシーを適用して websocket 接続を確立します。`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `操作内でプロキシー API を呼び出すには、このポリシーを適用します (特にサイズの大きなペイロードを呼び出す必要がある場合)。 1つのアセンブリーで呼び出すことのできるプロキシー・ポリシーは 1 つだけです。`,
  [`Apply to selected`]: `選択に適用`,
  [`Apply type analysis configuration`]: `タイプ分析の構成を適用`,
  [`Approval History`]: `承認履歴`,
  [`Approval tasks`]: `承認タスク`,
  [`Approvals`]: `承認`,
  [`Approve`]: `承認`,
  [`Archive`]: `アーカイブ`,
  [`Archive Product`]: `製品のアーカイブ`,
  [`Archive|permission`]: `アーカイブ`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `選択した項目から制約をクリアしますか?`,
  [`Are you sure you want to delete API?`]: `API を削除しますか?`,
  [`Are you sure you want to delete Product?`]: `製品を削除しますか?`,
  [`Are you sure you want to delete a catalog?`]: `カタログを削除しますか?`,
  [`Are you sure you want to delete a space?`]: `スペースを削除しますか?`,
  [`Are you sure you want to delete this application?`]: `このアプリケーションを削除しますか?`,
  [`Are you sure you want to delete this billing integration?`]: `この請求統合を削除しますか?`,
  [`Are you sure you want to delete this field?`]: `このフィールドを削除しますか?`,
  [`Are you sure you want to delete this policy?`]: `このポリシーを削除しますか?`,
  [`Are you sure you want to delete this type?`]: `このタイプを削除しますか?`,
  [`Are you sure you want to disable spaces?`]: `スペースを無効にしますか?`,
  [`Are you sure you want to disable custom email sender information?`]: `カスタム E メール送信者情報を無効にしてもよろしいですか。`,
  [`Are you sure you want to disable custom notification templates?`]: `カスタム通知テンプレートを無効にしますか?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `IBM 開発者ポータルの使用を中止しますか? カタログのポータルのカスタマイズはすべて削除されます`,
  [`Are you sure you want to enable spaces?`]: `スペースを有効にしますか?`,
  [`Are you sure you want to enable custom email sender information?`]: `カスタム E メール送信者情報を有効ににしてもよろしいですか。`,
  [`Are you sure you want to enable custom notification templates?`]: `カスタム通知テンプレートを有効にしますか?`,
  [`Are you sure you want to proceed with delete?`]: `削除を続行しますか?`,
  [`Are you sure you want to publish?`]: `公開しますか?`,
  [`Are you sure you want to re-stage?`]: `再ステージングしますか?`,
  [`Are you sure you want to remove the product from the catalog?`]: `この製品をカタログから削除しますか?`,
  [`Are you sure you want to unassociate?`]: `関連付けを解除しますか?`,
  [`Are you sure you want to update Self Service Onboarding`]: `セルフサービス・オンボーディングを更新しますか`,
  [`Are you sure?`]: `よろしいですか?`,
  [`Argument`]: `引数`,
  [`Array`]: `配列`,
  [`Assemble`]: `アセンブル`,
  [`Assemble the policy flow required for the OAuth provider.`]: `OAuth プロバイダーで必要なポリシー・フローをアセンブルします。`,
  [`Assembly`]: `アセンブリー`,
  [`Assembly Execute`]: `アセンブリー実行`,
  [`Assembly saved`]: `アセンブリーが保存されました`,
  [`Assembly burst limits`]: `アセンブリーのバースト制限`,
  [`Assembly count limits`]: `アセンブリーのカウント制限`,
  [`Assign roles`]: `ロールの割り当て`,
  [`Assign roles to the current owner`]: `現在の所有者へのロールの割り当て`,
  [`Associate`]: `関連付け`,
  [`Associate analytics service`]: `分析サービスの関連付け`,
  [`Associate analytics`]: `分析の関連付け`,
  [`Assumed size`]: `想定サイズ`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `URL {url}からAPIを取得しようとした際に、認証エラーが発生しました。 ユーザー名とパスワードを確認してください。`,
  [`Audience claim`]: `Audience クレーム`,
  [`Audience Claim`]: `Audience クレーム`,
  [`Audit setting`]: `監査設定`,
  [`Audit setting has been changed`]: `監査設定が変更されました`,
  [`Auditing is **{mode}**`]: `監査: **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `監査は、API 呼び出しをモニターし、呼び出し側ユーザー、各呼び出しの時間、各イベント関連のアクティビティーに関する情報をログに記録するために使用されます。`,
  [`Authenticate Client Method`]: `クライアント認証方式`,
  [`Authenticate password`]: `パスワードの認証`,
  [`Authenticate user`]: `ユーザーの認証`,
  [`Authenticate user settings`]: `ユーザー認証の設定`,
  [`Authenticate application users using`]: `アプリケーション・ユーザーの認証に使用:`,
  [`Authenticate using Basic Authentication`]: `基本認証を使用して認証`,
  [`Authenticate using OAuth`]: `OAuth を使用して認証`,
  [`Authenticated`]: `認証済み`,
  [`Authenticated bind`]: `認証済みバインド`,
  [`Authenticated User Name`]: `認証済みユーザー名`,
  [`Authentication`]: `認証`,
  [`Authentication Bind`]: `認証バインド`,
  [`Authentication error`]: `認証エラー`,
  [`Authentication method`]: `認証方式`,
  [`Authentication TLS profile`]: `TLS プロファイルの認証`,
  [`Authentication URL`]: `認証 URL`,
  [`Authentication URL user registry`]: `認証 URL ユーザー・レジストリー`,
  [`Authentication URL user registry has been created.`]: `認証 URL ユーザー・レジストリーが作成されました。`,
  [`Authentication URL to use for validation.`]: `検証に使用する認証 URL。`,
  [`Authentication response header credential`]: `認証応答ヘッダーの資格情報`,
  [`Authentication response header pattern`]: `認証応答ヘッダーのパターン`,
  [`Authentication type`]: `認証タイプ`,
  [`Authentication type to use to validate the UsernameToken.`]: `UsernameToken の検証に使用する認証タイプ。`,
  [`Authorization`]: `許可`,
  [`Authorization Code`]: `許可コード`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `許可 URL は、OAuth プロバイダー「{providerTitle}」で定義されている {endpoint} に一致する必要があります`,
  [`Authorization endpoint`]: `許可エンドポイント`,
  [`Authorization Request`]: `許可要求`,
  [`Authorization URL`]: `許可 URL`,
  [`Authorize`]: `許可`,
  [`Authorize User Settings`]: `ユーザー許可の設定`,
  [`Authorize application users using`]: `アプリケーション・ユーザーに次を使用して権限を与える`,
  [`Authorize path`]: `許可パス`,
  [`Authors API and product definitions`]: `API と製品定義を作成します`,
  [`Auto Generate OIDC API Assembly`]: `OIDC API アセンブリーの自動生成`,
  [`Auto onboard`]: `自動オンボード`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `API Connect の管理 API およびコマンドで使用するための自動生成名`,
  [`Automation`]: `自動化`,
  [`Automation Management Console`]: `自動化管理コンソール`,
  [`Automation management console`]: `自動化管理コンソール`,
  [`Availability Zone`]: `アベイラビリティー・ゾーン`,
  [`Availability zone`]: `アベイラビリティー・ゾーン`,
  [`Availability zone {arg} has been removed.`]: `アベイラビリティー・ゾーン {arg} は削除されました。`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `アベイラビリティー・ゾーンを使用すると、ビジネス・ニーズに合わせて API Connect サービスをグループ化できます。 例えば、ゲートウェイ・サービスが存在する地域またはデータ・センターに応じて、ゲートウェイ・サービスをグループ化することができます。`,
  [`Average response time: {{value}}ms`]: `平均応答時間: {{value}}ms`,
  [`Average time`]: `平均時間`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `API Designer サーバーをローカル・プロキシーとして使用することにより、CORS エラーを回避します。`,
  [`Back`]: `戻る`,
  [`Back to sign in`]: `サインインに戻る`,
  [`Back to test`]: `テストに戻る`,
  [`Backend type`]: `バックエンド・タイプ`,
  [`Bad gateway`]: `正しくないゲートウェイ`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `基本 DN`,
  [`Base Path`]: `基本パス`,
  [`Base path`]: `基本パス`,
  [`Base endpoint`]: `基本エンドポイント`,
  [`Base endpoints`]: `基本エンドポイント`,
  [`Base64 encoding`]: `Base64エンコード`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `招待およびパスワードのリセット・トークンの Base64 エンコードは、**{base64Setting}** です`,
  [`Base64 encoding setting for temporary token has been changed`]: `一時トークンの Base64 エンコード設定が変更されました`,
  [`Base URL of API invocation endpoint`]: `API 呼び出しエンドポイントの基本 URL`,
  [`Basepath root`]: `基本パス・ルート`,
  [`Basic`]: `基本`,
  [`Basic authentication`]: `基本認証`,
  [`Basic authentication password`]: `基本認証パスワード`,
  [`Basic authentication request header name`]: `基本認証要求ヘッダー名`,
  [`Basic authentication username`]: `基本認証ユーザー名`,
  [`Before you begin`]: `始める前に`,
  [`Before you begin...`]: `始める前に...`,
  [`Between 0 and 10 events are waiting to be processed`]: `0 から 10 のイベントが処理を待機しています`,
  [`Between 10 and 20 events are waiting to be processed`]: `10 から 20 のイベントが処理を待機しています`,
  [`Billing`]: `請求`,
  [`Billing and Payment`]: `請求および支払い`,
  [`Billing has been updated`]: `請求が更新されました`,
  [`Billing integrations`]: `請求統合`,
  [`Billing integration`]: `請求統合`,
  [`Billing Integration`]: `請求統合`,
  [`Billing {title} successfully added.`]: `請求 {title} が正常に追加されました。`,
  [`Billing {title} successfully deleted.`]: `請求 {title} が正常に削除されました。`,
  [`Billing {title} successfully updated.`]: `請求 {title} が正常に更新されました。`,
  [`Billing Options`]: `請求オプション`,
  [`Blocklist`]: `ブロックリスト`,
  [`Blocking`]: `ブロッキング`,
  [`Blocking option`]: `ブロッキング・オプション`,
  [`Body`]: `本文`,
  [`Boolean`]: `ブール`,
  [`Both`]: `両方`,
  [`Browse`]: `参照`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `DataPower API Gateway での処理の前に、API の要求および応答をバッファーに入れます。`,
  [`Buffering`]: `バッファリング`,
  [`Builds and manages apps in the developer organization`]: `開発者組織でアプリケーションを作成および管理します`,
  [`Burst Limit Name`]: `バースト制限名`,
  [`Burst limits`]: `バースト制限`,
  [`Burst limit value can't be negative`]: `バースト制限値を負にすることはできません`,
  [`Buy`]: `購入`,
  [`but unable to remove because of limitation`]: `しかし、制限のため、削除できません`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `製品の可視性およびサブスクライブ可能性を構成することにより、製品の API を開発者ポータルでアプリケーション開発者が使用できるようにするかどうかを制御します。 どのアプリケーション開発者に製品を表示し、どのアプリケーション開発者が製品の API を使用するためにサブスクライブできるかを制御できます。`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `製品の可視性およびサブスクライブ可能性を構成することにより、製品の API を開発者ポータルでアプリケーション開発者が使用できるようにするかどうかを制御します。 どのアプリケーション開発者に製品を表示し、どのアプリケーション開発者が製品の API を使用するためにサブスクライブできるかを制御できます。`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `デフォルトでは、複数のソース (長さ 2 の array1 と長さ 3 の array2 を仮定) からマッピングを実行すると、ターゲット配列の項目数が 5 つになります (array1 を処理することにより 2 つ、次に array2 を処理することにより 3 つ)。 これらのルールを組み合わせる場合は (各ソース配列からのプロパティーが組み合わされた、項目が 2 つまたは 3 つ含まれる配列を作成)、このオプションにチェック・マークを付けます。`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `デフォルトでは、この製品はすべての関連ゲートウェイ・サービスに公開されます。このオプションを有効にすることで、特定のゲートウェイ・サービスに公開することもできます。`,
  [`By payload`]: `ペイロード別`,
  [`By URL parameter`]: `URL パラメーター別`,
  [`Bytes`]: `バイト数`,
  [`CALLS`]: `呼び出し`,
  [`Calls`]: `呼び出し`,
  [`CATALOG`]: `カタログ`,
  [`CERTIFICATE NAME`]: `証明書名`,
  [`CIPHER`]: `暗号`,
  [`CIPHER SUITES`]: `暗号スイート`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `CLI のみ`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `クライアント ID`,
  [`CLOUD ADMINISTRATOR`]: `クラウド管理者`,
  [`Consumer organization`]: `コンシューマー組織`,
  [`CONSUMER ORGANIZATION / GROUP`]: `コンシューマー組織/グループ`,
  [`Consumer-Onboard-Approval`]: `コンシューマー・オンボード承認`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA バンドル`,
  [`Cache Key`]: `キャッシュ・キー`,
  [`Cache Time to Live (second)`]: `キャッシュ存続時間 (秒)`,
  [`Cache key`]: `キャッシュ・キー`,
  [`Cache Time-To-Live`]: `キャッシュ存続時間`,
  [`Cache type`]: `キャッシュ・タイプ`,
  [`Can't find the one you want?`]: `目的のものが見つかりませんか?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `無効なメール・サーバーでは招待を送信できません。メール・サーバー構成を確認して、再試行してください`,
  [`Cancel`]: `キャンセル`,
  [`Cannot be below {min}`]: `{min} を下回ることはできません`,
  [`Cannot be empty`]: `空にすることはできません`,
  [`Cannot delete root type.`]: `ルート・タイプは削除できません。`,
  [`Cannot exceed {max}`]: `{max} を上回ることはできません`,
  [`Cannot find any APIs for this product`]: `この製品の API が見つかりません`,
  [`Cannot find the one you want?`]: `目的のレジストリーが見つからない場合は、`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `「sizedFields」も定義していない場合は、リストを返さないフィールドでディレクティブ「@listSize」を使用することはできません。`,
  [`Cannot remove built-in scalar types`]: `組み込みのスカラー型を削除することはできません`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `照会、ミューテーション、およびサブスクリプション・ルートの操作タイプを削除することはできません`,
  [`Cannot remove arguments of built-in directives`]: `組み込みのディレクティブの引数を削除することはできません`,
  [`Cannot remove non-null arguments of directives`]: `ディレクティブの非ヌルの引数を削除することはできません`,
  [`Cannot remove input types of arguments of directives`]: `ディレクティブの引数の入力タイプを削除することはできません`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `ディレクティブの引数のデフォルト値として使用される列挙値を削除することはできません`,
  [`Cannot remove enum values used as default values of input fields`]: `入力フィールドのデフォルト値として使用される列挙値を削除することはできません`,
  [`Cannot remove all fields of an object type`]: `オブジェクト・タイプのすべてのフィールドを削除することはできません`,
  [`Cannot remove all fields of an interface type`]: `インターフェース・タイプのすべてのフィールドを削除することはできません`,
  [`Cannot remove all slicing arguments`]: `すべてのスライス引数を削除することはできません`,
  [`Cannot remove all input fields of an input object type`]: `入力オブジェクト・タイプのすべての入力フィールドを削除することはできません`,
  [`Cannot remove all values of enum type`]: `列挙タイプのすべての値を削除することはできません`,
  [`Cannot remove fields of interfaces`]: `インターフェースのフィールドを削除することはできません`,
  [`Cannot remove non-null input fields`]: `非ヌルの入力フィールドを削除することはできません`,
  [`Case`]: `Case`,
  [`Case sensitive`]: `大/小文字の区別`,
  [`Catalog`]: `カタログ`,
  [`Catalog defaults`]: `カタログのデフォルト`,
  [`Catalog Invite`]: `カタログの招待`,
  [`Catalog invite`]: `カタログの招待`,
  [`Catalog invites`]: `カタログの招待`,
  [`Catalog name`]: `カタログ名`,
  [`Catalog owner`]: `カタログ所有者`,
  [`Catalog owner invitation`]: `カタログ所有者の招待`,
  [`Catalog properties`]: `カタログのプロパティー`,
  [`Catalog summary`]: `カタログ要約`,
  [`Catalog title`]: `カタログのタイトル`,
  [`Catalog user registries`]: `カタログ・ユーザー・レジストリー`,
  [`Catalog by Name`]: `名前順のカタログ`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `カタログ優先順位 (カタログ管理者により定義されたバニティー・エンドポイントを使用)`,
  [`Catalog {name} created`]: `カタログ {name} が作成されました`,
  [`Catalog selection`]: `カタログ選択`,
  [`Catalog settings`]: `カタログ設定`,
  [`Catalog settings have been updated`]: `カタログ設定が更新されました`,
  [`Catalog User`]: `カタログ・ユーザー`,
  [`Catalog Users`]: `カタログ・ユーザー`,
  [`Catalog user registry`]: `カタログ・ユーザー・レジストリー`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `ライフサイクルが有効なカタログは、テスト設定では使用できません。`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `ライフサイクルおよび本番モードを有効にしたカタログは、テスト設定では使用できません。`,
  [`Catalogs`]: `カタログ`,
  [`Catch`]: `Catch`,
  [`Catches`]: `Catch`,
  [`Categories`]: `カテゴリー`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `カテゴリーにより、開発者ポータルでの API 製品の階層型の表示が確立されます。 次の構文を使用してください: top_level_element/next_level_element/lower_level_element。`,
  [`Category`]: `カテゴリー`,
  [`Certifcate or Shared Secret for Verify`]: `検証する証明書または共有秘密鍵`,
  [`Certificate`]: `証明書`,
  [`Certificate (Optional)`]: `証明書 (オプション)`,
  [`Certificate management`]: `証明書管理`,
  [`Certificate Manager instance`]: `証明書マネージャー・インスタンス`,
  [`Certificate (optional)`]: `証明書 (オプション)`,
  [`Certificates`]: `証明書`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `証明書は、ゲートウェイとその処理対象ドメインの間の接続を保護するために必要です。 すべての証明書は、証明書マネージャー・サービスに保管されている必要があります。 このサービスは、証明書用のセキュアなリポジトリーを提供し、証明書の有効期限切れが近づくとユーザーに通知を送るので、障害の防止に役立ちます。 開始するには、`,
  [`Certificates details in this keystore`]: `この鍵ストア内の証明書の詳細`,
  [`Certificates details in this truststore`]: `このトラストストア内の証明書の詳細`,
  [`Change`]: `変更`,
  [`Change Consumer Organization owner to an existing user`]: `既存のユーザーへのコンシューマー組織の所有者の変更`,
  [`Change Ownership to an Existing User`]: `既存のユーザーへの所有権の変更`,
  [`Change portal service`]: `ポータル・サービスの変更`,
  [`Change owner to existing user`]: `既存のユーザーへの所有者の変更`,
  [`Change owner to new user`]: `新規ユーザーへの所有者の変更`,
  [`Change ownership by inviting a new user`]: `新規ユーザーを招待して所有権を変更`,
  [`Change ownership to`]: `所有権の変更`,
  [`Change ownership to a new user via email invitation`]: `招待 E メールを使用して新規ユーザーに所有権を変更`,
  [`Change ownership to an existing user`]: `既存のユーザーへの所有権の変更`,
  [`Change password`]: `パスワードの変更`,
  [`Change setup`]: `セットアップの変更`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `OAuth プロバイダーの基礎となる API またはアセンブリーを直接変更する場合は、OAuth プロバイダー自体の定義内の対応する値もすべて更新しないと、OAuth プロバイダーが無効になることがあります。 これらの変更を保存する場合は、OAuth プロバイダー定義内の対応する値もすべて更新してください。`,
  [`Change the following extra constraints in the schema:`]: `スキーマで次の追加の制約を変更:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `証明書マネージャーを変更すると、既存の証明書および CA バンドルの割り当てがすべて削除されます。`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `DNS スキームを変更すると、API Connect および開発者ポータルへの URL リンクの形式が変更されます。 新しいリンクをユーザーに連絡する必要があります。 続行しない場合は「キャンセル」をクリックします。`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `API の名前またはバージョンを変更すると、新しい API が作成されます。 変更を保存しますか?`,
  [`Check your email`]: `E メールを確認してください`,
  [`Child`]: `子`,
  [`Chinese (Simplified)`]: `中国語 (簡体字)`,
  [`Chinese (Traditional)`]: `中国語 (繁体字)`,
  [`Choose`]: `選択`,
  [`Choose a`]: `選択してください`,
  [`Choose {docTypeLabel}`]: `{docTypeLabel} の選択`,
  [`Choose Catalog`]: `カタログの選択`,
  [`Choose a Catalog`]: `カタログを選択します`,
  [`Choose a Consumer Org`]: `コンシューマー組織の選択`,
  [`Choose Gateway`]: `ゲートウェイの選択`,
  [`Choose a Gateway`]: `ゲートウェイを選択します`,
  [`Choose Product`]: `製品の選択`,
  [`Choose a Product`]: `製品を選択します`,
  [`Choose Rate Limit`]: `レート制限の選択`,
  [`Choose a Rate Limit`]: `レート制限を選択します`,
  [`Choose Application`]: `アプリケーションの選択`,
  [`Choose an Application`]: `アプリケーションの選択`,
  [`Choose a {docTypeLabel}`]: `{docTypeLabel} の選択`,
  [`Choose a publish destination`]: `公開宛先の選択`,
  [`Choose a billing integration`]: `請求統合の選択`,
  [`Choose a catalog to test within:`]: `テストするカタログを選択してください:`,
  [`Choose a plan against which to test:`]: `テストするプランを選択してください:`,
  [`Choose a product containing this API, or create a new one:`]: `この API が含まれている製品を選択するか、新しい製品を作成します:`,
  [`Choose a user registry`]: `ユーザー・レジストリーの選択`,
  [`Choose an application with which to test, or create a new one:`]: `テストで使用するアプリケーションを選択するか、新規作成します:`,
  [`Choose an existing application`]: `既存アプリケーションの選択`,
  [`Choose an existing catalog`]: `既存のカタログの選択`,
  [`Choose an existing product published to your Sandbox catalog`]: `サンドボックス・カタログに公開される既存の製品を選択`,
  [`Choose an operation to invoke:`]: `呼び出す操作を選択:`,
  [`Choose an option`]: `オプションの選択`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `マップ・ポリシーで空の配列出力を処理する方法を決定するオプションを選択します。 「すべて」(デフォルト選択項目) を選択すると、空の配列および空の子配列がすべて出力されます。 「親」を選択すると、空の親配列のみが出力されます。 「なし」を選択すると、空の配列は出力されません。`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `ブロッキング・モードを選択すると、システム・パフォーマンスに悪影響を及ぼします`,
  [`Choose existing directory`]: `既存のディレクトリーの選択`,
  [`Choose one...`]: `1 つ選択してください...`,
  [`Choose operations and paths to generate into this API`]: `この API に生成する操作およびパスを選択`,
  [`Choose paths to generate into this API`]: `この API に生成するパスの選択`,
  [`Choose the DNS scheme`]: `DNS スキームの選択`,
  [`Choose the permissions for the role.`]: `ロールの許可を選択します。`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `ゲートウェイ・サービスに対するインバウンド API 呼び出しおよび開発者ポータルへのアクセスに、動的 DNS アドレスと静的 DNS アドレスのいずれを使用するかを選択してください。`,
  [`Cipher`]: `暗号`,
  [`Ciphers`]: `暗号`,
  [`Clear`]: `クリア`,
  [`Clear constraints`]: `制約のクリア`,
  [`Clear file`]: `ファイルのクリア`,
  [`Clear filters`]: `フィルターのクリア`,
  [`Clear from selected`]: `選択項目からクリア`,
  [`Clear operation filter`]: `操作フィルターのクリア`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `証明書をクリックすると、その証明書の発行組織とフィンガープリントに関する詳細が表示されます。`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `以下のリンクをクリックして、API の許可を別のタブで続行してください。ステップ 2 で許可コードを取得できます。`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `以下のリンクをクリックすると、新しいタブにサーバーを開きます。 ブラウザーに証明書の問題が表示される場合は、それを受け入れることを選択し、ここに戻って再度テストすることができます。`,
  [`Click add to add a billing integration.`]: `請求統合を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a gateway extension.`]: `ゲートウェイ拡張を追加するには、「追加」をクリックしてください。`,
  [`Click **Add domain** to add domains.`]: `ドメインを追加するには、「**ドメインの追加**」をクリックしてください。`,
  [`Click Add to add a new base endpoint.`]: `新しい基本エンドポイントを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new HTTP header.`]: `新しい HTTP ヘッダーを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new member.`]: `新規メンバーを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new role.`]: `新規ロールを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new scope.`]: `新規スコープを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new consumer organization group.`]: `新規コンシューマー組織グループを追加するには、「追加」をクリックしてください。`,
  [`Click Add to create the API Key.`]: `「追加」をクリックして API キーを作成します。`,
  [`Click Create to add the API Key.`]: `「作成」をクリックして API キーを追加します。`,
  [`Click Create to add a new user registry.`]: `新しいユーザー・レジストリーを追加するには、「作成」をクリックしてください。`,
  [`Click Create user registry to add a new user registry.`]: `新しいユーザー・レジストリーを追加するには、「ユーザー・レジストリーの作成」をクリックしてください。`,
  [`Click **Create provider organization** to add provider organizations.`]: `プロバイダー組織を追加するには、「**プロバイダー組織の作成**」をクリックしてください。`,
  [`Click Edit to add OAuth providers.`]: `OAuth プロバイダーを追加するには、「編集」をクリックしてください。`,
  [`Click Edit to add a user registry.`]: `ユーザー・レジストリーを追加するには、「編集」をクリックしてください。`,
  [`Click Edit to add an API to the product.`]: `製品に API を追加するには、「編集」をクリックしてください。`,
  [`Click Create to add a property.`]: `プロパティーを追加するには、「作成」をクリックしてください。`,
  [`Click Edit to add TLS client profiles.`]: `TLS クライアント・プロファイルを追加するには、「編集」をクリックしてください。`,
  [`Click Edit to add user registries.`]: `ユーザー・レジストリーを追加するには、「編集」をクリックしてください。`,
  [`Click Add to add a new consumer organization.`]: `新規コンシューマー組織を追加するには、「追加」をクリックしてください。`,
  [`Click Consumer organizations to add one.`]: `コンシューマー組織を追加するには、「コンシューマー組織」をクリックしてください。`,
  [`Click Add to add a new application.`]: `新規アプリケーションを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add parameters.`]: `パラメーターを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add response.`]: `応答を追加するには、「追加」をクリックしてください。`,
  [`Click Create to add a new TLS client profile.`]: `新しい TLS クライアント・プロファイルを追加するには、「作成」をクリックしてください。`,
  [`Click Create to add a new keystore.`]: `新しい鍵ストアを追加するには、「作成」をクリックしてください。`,
  [`Click Create to add a new truststore.`]: `新しいトラストストアを追加するには、「作成」をクリックしてください。`,
  [`Click **Register remote gateway** to add gateways.`]: `ゲートウェイを追加するには、「**リモート・ゲートウェイの登録**」をクリックしてください。`,
  [`Click Register gateways to add new Gateway Services.`]: `新しいゲートウェイ・サービスを追加するには、「ゲートウェイを登録してください」をクリックしてください。`,
  [`Click Edit to add Gateway Services.`]: `ゲートウェイ・サービスを追加するには、「編集」をクリックしてください。`,
  [`Click Register gateways to add new gateway services.`]: `新しいゲートウェイ・サービスを追加するには、「ゲートウェイを登録してください」をクリックしてください。`,
  [`Click Edit to add gateway services.`]: `ゲートウェイ・サービスを追加するには、「編集」をクリックしてください。`,
  [`Click Edit to add an email server.`]: `E メール・サーバーを追加するには、「編集」をクリックしてください。`,
  [`Click Create to add a new TLS server profile.`]: `新しい TLS サーバー・プロファイルを追加するには、「作成」をクリックしてください。`,
  [`Click Create to add a new email server.`]: `新しい E メール・サーバーを追加するには、「作成」をクリックしてください。`,
  [`Click Add to add a new schema definition.`]: `新規スキーマ定義を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new property for this definition.`]: `この定義の新規プロパティーを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new parameter for this API.`]: `この API の新規パラメーターを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add tags and external documentation for this API.`]: `この API のタグおよび外部ドキュメンテーションを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new path.`]: `新規パスを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a property for this API.`]: `この API のプロパティーを追加するには、「追加」をクリックしてください。`,
  [`Click here to create a security definition`]: `セキュリティー定義を作成するには、ここをクリックしてください。`,
  [`Click Add to add a new security definition.`]: `新規セキュリティー定義を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a target service for this API.`]: `この API のターゲット・サービスを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add an operation for this path.`]: `このパスの操作を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a path parameter for this path.`]: `このパスのパス・パラメーターを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new OAuth provider.`]: `新規 OAuth プロバイダーを追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new provider organization.`]: `新規プロバイダー組織を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new API or product.`]: `新規 API または製品を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new API.`]: `新規 API を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add a new Extension.`]: `新規拡張を追加するには、「追加」をクリックしてください。`,
  [`Click Add to add new {resource}.`]: `「追加」をクリックして、新しい {resource} を追加してください。`,
  [`Click to expand`]: `クリックすると展開されます`,
  [`Click to collapse`]: `クリックすると省略表示されます`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `クリックすると、ゲートウェイで必要な証明書をアップロードおよび管理する方法が示されます。`,
  [`Client Credentials`]: `クライアント資格情報`,
  [`Client information`]: `クライアント情報`,
  [`Client revocation path`]: `クライアント失効パス`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `サービスを登録するには、「サービスの登録」をクリックしてください。 これにより、その他のサービスを構成する際にエンドポイントをテストできます。`,
  [`Client secret`]: `クライアント秘密鍵`,
  [`Client security`]: `クライアント・セキュリティー`,
  [`Client Type`]: `クライアント・タイプ`,
  [`Client authentication method`]: `クライアント認証方式`,
  [`Clone`]: `クローン`,
  [`Close`]: `閉じる`,
  [`Cloud`]: `クラウド`,
  [`Cloud Admin`]: `クラウド管理者`,
  [`Cloud Administrator`]: `クラウド管理者`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `クラウド管理者、組織管理者、トポロジー管理者。 メンバーのロールは、すべてのユーザーに自動的に割り当てられます。`,
  [`Cloud administration`]: `クラウド管理`,
  [`Cloud Discovery Agent`]: `クラウド・ディスカバリー・エージェント`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Cloud Manager のローカル・ユーザー・レジストリー`,
  [`Cloud-Settings`]: `クラウド設定`,
  [`Cloud settings invitation timeout`]: `クラウド設定の招待タイムアウト`,
  [`Cloud settings invitation timeout has been changed`]: `クラウド設定の招待タイムアウトが変更されました`,
  [`Code editor`]: `コード・エディター`,
  [`Collect Metadata`]: `メタデータの収集`,
  [`Collect credentials using`]: `資格情報の収集に使用する条件`,
  [`Collect metadata`]: `メタデータの収集`,
  [`Combo box`]: `コンボ・ボックス`,
  [`Community Manager`]: `コミュニティー・マネージャー`,
  [`community-manager`]: `コミュニティー・マネージャー`,
  [`Compatibility`]: `互換性`,
  [`Complex Definitions must be edited in the editor`]: `複雑な定義はエディターで編集する必要があります`,
  [`Compose DN`]: `DN の作成`,
  [`Compose UPN`]: `UPN の作成`,
  [`Compose a new REST proxy by defining paths and operations`]: `パスと操作を定義して新しい REST プロキシーを作成します`,
  [`Compose a new product by adding rate limits and plans`]: `レート制限とプランを追加して新規製品を作成します`,
  [`Compression`]: `圧縮`,
  [`Condition`]: `条件`,
  [`Condition editor`]: `条件エディター`,
  [`Confidential`]: `機密`,
  [`Configuration`]: `構成`,
  [`Configurations`]: `構成`,
  [`Configure API endpoint`]: `API エンドポイントの構成`,
  [`Configure API Gateway Service`]: `API ゲートウェイ・サービスの構成`,
  [`Configure analytics service`]: `分析サービスの構成`,
  [`Configure availability zone`]: `アベイラビリティー・ゾーンの構成`,
  [`Configure catalog user registries`]: `カタログ・ユーザー・レジストリーの構成`,
  [`Configure cloud`]: `クラウドの構成`,
  [`Configure DataPower API gateway service`]: `DataPower API ゲートウェイ・サービスの構成`,
  [`Configure DataPower gateway service`]: `DataPower ゲートウェイ・サービスの構成`,
  [`Configure Email Server`]: `E メール・サーバーの構成`,
  [`Configure gateway extension`]: `ゲートウェイ拡張の構成`,
  [`Configure portal service`]: `ポータル・サービスの構成`,
  [`Configure service`]: `サービスの構成`,
  [`Configure topology`]: `トポロジーの構成`,
  [`Configure XML`]: `XML の構成`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `API の保護および実行のための DataPower API ゲートウェイ・サービスを構成します`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `API の保護および実行のための DataPower ゲートウェイ・サービスを構成します`,
  [`Configure a developer portal service for API consumers`]: `API コンシューマー用の開発者ポータル・サービスを構成します`,
  [`Configure advanced features for OIDC settings`]: `OIDC 設定の拡張機能を構成します`,
  [`Configure an analytics service to collect API call data`]: `API 呼び出しデータを収集するための分析サービスを構成します`,
  [`Configure an email server to send invitations and notifications to users.`]: `ユーザーに招待および通知を送信するための E メール・サーバーを構成します。`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `管理 UI、マネージャー UI、ツールキット、管理 REST API、およびポータル・ユーザーの操作をモニターするように監査を構成します。 ユーザーの作成、更新、および削除の各操作のログは、以下に指定されたリモート・ロギング・サービスに送信されます。`,
  [`Configure availability zones and services`]: `アベイラビリティー・ゾーンおよびサービスを構成します`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `公開済み API で API エンドポイント URL が作成される方法を構成します`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `開発者ポータルにバニティー・エンドポイントを表示する方法を構成します`,
  [`Configure mapping`]: `マッピングの構成`,
  [`Configure properties of the activity log`]: `アクティビティー・ログのプロパティーを構成します`,
  [`Configure settings for token management and revocation.`]: `トークンの管理および取り消しのための設定を構成します。`,
  [`Configure settings to generate and validate tokens.`]: `トークンを生成および検証するための設定を構成します。`,
  [`Configure the API security`]: `API セキュリティーの構成`,
  [`Configure the default set of gateway services configured for each catalog`]: `各カタログに対して構成されるゲートウェイ・サービスのデフォルト・セットを構成します`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `このカタログ内の API にアクセスするためにアプリケーション開発者が使用する開発者ポータルを構成します`,
  [`Configure the gateway services used by default in each catalog`]: `各カタログでデフォルトで使用されるゲートウェイ・サービスを構成します`,
  [`Configure the gateway extension for the selected gateway`]: `選択したゲートウェイのゲートウェイ拡張を構成します`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `IBM で管理される DataPower API ゲートウェイを構成し、独自のリモート・ゲートウェイを管理します。 [詳細情報]({link})`,
  [`Configure the keystore and upload certificates`]: `鍵ストアを構成し、証明書をアップロードします`,
  [`Configure the name and email address to be used in the from field of emails`]: `E メールの送信元フィールドに使用する名前と E メール・アドレスを構成します`,
  [`Configure the portal service for the catalog`]: `カタログ用のポータル・サービスを構成します`,
  [`Configure the role and assign permissions`]: `ロールを構成し、許可を割り当てます`,
  [`Configure the security of this API`]: `この API のセキュリティーを構成します`,
  [`Configure the sender name and address to use for email notifications`]: `E メール通知に使用する送信者の名前とアドレスを構成します`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `ユーザーに招待および通知を送信するために使用する送信者名、アドレス、および E メール・サーバーを構成します`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `コンシューマー組織の作成時にデフォルトで使用するロールのセットを構成します`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `プロバイダー組織の作成時にデフォルトで使用するロールのセットを構成します`,
  [`Configure the templates used to invite and notify users`]: `ユーザーに対する招待と通知に使用するテンプレートを構成します`,
  [`Configure the truststore and upload certificates`]: `トラストストアを構成し、証明書をアップロードします`,
  [`Configure user authentication using`]: `ユーザー認証の構成方法:`,
  [`Configure user authentication using JSON Web Tokens`]: `JSON Web トークンを使用したユーザー認証の構成`,
  [`Configure user authentication using a LDAP provider`]: `LDAP プロバイダーを使用したユーザー認証の構成`,
  [`Configure user authentication using an API Connect Local User Registry`]: `API Connect ローカル・ユーザー・レジストリーを使用したユーザー認証の構成`,
  [`Configure user authentication using an authentication URL`]: `認証 URL を使用したユーザー認証の構成`,
  [`Configure user authentication using this user registry type`]: `このユーザー・レジストリー・タイプを使用したユーザー認証の構成`,
  [`Configure user registries, OAuth providers and TLS`]: `ユーザー・レジストリー、OAuth プロバイダー、および TLS を構成します`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `ユーザー・レジストリー、TLS、OAuth プロバイダー、および Eメール・サーバーを構成します`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `アクセス・トークン、ID トークン、および一時トークンの鍵ストアを構成します`,
  [`Configured OAuth Provider`]: `構成済みの OAuth プロバイダー`,
  [`Configured OAuth Providers`]: `構成済みの OAuth プロバイダー`,
  [`Configured OAuth provider`]: `構成済みの OAuth プロバイダー`,
  [`Configured OAuth provider list has been updated`]: `構成済みの OAuth プロバイダー・リストが更新されました`,
  [`Configured OAuth provider list has not been updated`]: `構成済みの OAuth プロバイダー・リストが更新されていません`,
  [`Confirm`]: `確認`,
  [`Confirm deletion`]: `削除の確認`,
  [`Confirm Deletion of Portal`]: `ポータルの削除の確認`,
  [`Confirm visibility settings`]: `可視性の設定の確認`,
  [`Confirm new password`]: `新規パスワードの確認`,
  [`Confirm password`]: `パスワードの確認`,
  [`Confirm password should match password`]: `確認パスワードはパスワードと一致する必要があります`,
  [`Confirmation`]: `確認`,
  [`Congratulations, you are now registered.`]: `正常に登録されました。`,
  [`Congratulations, your password has been reset!`]: `パスワードがリセットされました。`,
  [`Connect`]: `接続`,
  [`Connect to API Connect`]: `API Connect に接続します`,
  [`Connected to API Manager Product version`]: `API Manager 製品のバージョンに接続しました`,
  [`Connect to Cloud`]: `クラウドに接続`,
  [`Connected`]: `接続済み`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `必須タイプのプロパティーを追加して、スキーマ定義を作成します。 プロパティーをネストできます`,
  [`Consider for nesting`]: `ネストに考慮`,
  [`Consumed Media Types`]: `使用されたメディア・タイプ`,
  [`Consumer`]: `コンシューマー`,
  [`Consumer invitation and roles`]: `コンシューマーの招待とロール`,
  [`Consumer onboarding`]: `コンシューマーのオンボーディング`,
  [`Consumer organizations`]: `コンシューマー組織`,
  [`Consumer organizations or groups`]: `コンシューマー組織またはグループ`,
  [`Consumer organization {name} created`]: `コンシューマー組織 {name} が作成されました`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `コンシューマー組織は開発者ポータルでアプリケーション開発者によって作成されます。API Manager UI を使用してコンシューマー組織を作成することもできます`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `コンシューマー組織は開発者ポータルでアプリケーション開発者によって作成されます。ここでコンシューマー組織を作成することもできます`,
  [`Consumer-Org`]: `コンシューマー組織`,
  [`Consumers`]: `コンシューマー`,
  [`Consumes`]: `コンシューム`,
  [`Contact`]: `問い合わせ先`,
  [`Contact information for the owners of the API.`]: `API の所有者の連絡先情報。`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `ここにデータを表示できるようにするには、管理者に分析サービスの構成方法をお問い合わせください。`,
  [`Contact your administrator for more information.`]: `詳しくは、管理者にお問い合わせください。`,
  [`Content`]: `内容`,
  [`Content on error`]: `エラー時の内容`,
  [`Content type`]: `コンテンツ・タイプ`,
  [`Context Variable`]: `コンテキスト変数`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `検証対象の JWT を格納するコンテキストまたはランタイム変数。 設定しない場合、ポリシーは request.headers.authorization で JWT を検索します。`,
  [`Context variable`]: `コンテキスト変数`,
  [`Continue`]: `続行`,
  [`Continue on error`]: `エラーのまま続行`,
  [`Continue with`]: `次を使用して続行`,
  [`Continue with:`]: `次を使用して続行:`,
  [`Conversion type`]: `変換のタイプ`,
  [`Convert to expression`]: `式に変換`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `コピー済み`,
  [`Copied to clipboard`]: `クリップボードにコピーされました`,
  [`Copy`]: `コピー`,
  [`Copy Id Headers To Message`]: `メッセージへの ID ヘッダーのコピー`,
  [`Copy to clipboard`]: `クリップボードにコピー`,
  [`Copy file path to clipboard`]: `クリップボードにファイル・パスをコピー`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `GraphQL 照会のコスト見積もりを出力にコピー`,
  [`Copy the endpoints below to`]: `下のエンドポイントを次にコピーします:`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `使用する API キーをコピーします。 「表示」アイコンを使用して、この API キーを再度表示することができます。`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `著作権情報`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `上にあるエディターでエラーを訂正するか、有効なファイルを再アップロードしてください`,
  [`Cost`]: `コスト`,
  [`Count`]: `カウント`,
  [`Count Limit Name`]: `カウント制限名`,
  [`Count Limits`]: `カウント制限`,
  [`Created at`]: `作成日時`,
  [`Counter`]: `カウンター`,
  [`Create`]: `作成`,
  [`Create a [Certificate Manager]({link}) service`]: `[証明書マネージャー]({link})・サービスの作成`,
  [`Create API`]: `API の作成`,
  [`Create an API from the Develop section.`]: `開発セクションから API を作成します。`,
  [`Create API Connect API Key`]: `API Connect API キーの作成`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `既存の GraphQL サービスからの API の作成 (GraphQL プロキシー)`,
  [`Create API from existing OpenAPI service`]: `既存の OpenAPI サービスからの API の作成`,
  [`Create API from existing WSDL service (REST proxy)`]: `既存の WSDL サービスからの API の作成 (REST プロキシー)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `既存の WSDL サービスからの API の作成 (SOAP プロキシー)`,
  [`Create API from target service`]: `ターゲット・サービスからの API の作成`,
  [`Create API from existing REST service`]: `既存の REST サービスからの API の作成`,
  [`Create API from existing SOAP service`]: `既存の SOAP サービスからの API の作成`,
  [`Create API from existing WSDL service`]: `既存の WSDL サービスからの API の作成`,
  [`Create API Key failed`]: `API キーの作成に失敗しました`,
  [`Create application`]: `アプリケーションの作成`,
  [`Create authentication URL user registry`]: `認証 URL ユーザー・レジストリーの作成`,
  [`Create availability zone`]: `アベイラビリティー・ゾーンの作成`,
  [`Create Billing`]: `請求の作成`,
  [`Create catalog`]: `カタログの作成`,
  [`Create catalog property`]: `カタログ・プロパティーの作成`,
  [`Create consumer organization`]: `コンシューマー組織の作成`,
  [`Create Credentials`]: `資格情報の作成`,
  [`Create definition`]: `定義の作成`,
  [`Create email server`]: `E メール・サーバーの作成`,
  [`Create IBM Developer Portal`]: `IBM 開発者ポータルの作成`,
  [`Create keystore`]: `鍵ストアの作成`,
  [`Create LDAP user registry`]: `LDAP ユーザー・レジストリーの作成`,
  [`Create LTPA Key`]: `LTPA 鍵の作成`,
  [`Create local user registry`]: `ローカル・ユーザー・レジストリーの作成`,
  [`Create native OAuth provider`]: `ネイティブ OAuth プロバイダーの作成`,
  [`Create New GraphQL API`]: `新規 GraphQL API の作成`,
  [`Create new OpenAPI`]: `新規 OpenAPI の作成`,
  [`Create new product`]: `新規製品の作成`,
  [`Create OIDC user registry`]: `OIDC ユーザー・レジストリーの作成`,
  [`Create OpenAPI definition and product definition`]: `OpenAPI 定義および製品定義を作成します`,
  [`Create operation`]: `操作の作成`,
  [`Create organization`]: `組織の作成`,
  [`Create path`]: `パスの作成`,
  [`Create plan`]: `プランの作成`,
  [`Create portal`]: `ポータルの作成`,
  [`Create Product`]: `製品の作成`,
  [`Create property`]: `プロパティーの作成`,
  [`Create role`]: `ロールの作成`,
  [`Create role default for the Organization`]: `組織のデフォルト・ロールの作成`,
  [`Create sample user registry`]: `サンプル・ユーザー・レジストリーの作成`,
  [`Create security definition`]: `セキュリティー定義の作成`,
  [`Create space`]: `スペースの作成`,
  [`Create subscription`]: `サブスクリプションの作成`,
  [`Create TLS client profile`]: `TLS クライアント・プロファイルの作成`,
  [`Create TLS server profile`]: `TLS サーバー・プロファイルの作成`,
  [`Create target service`]: `ターゲット・サービスの作成`,
  [`Create third party OAuth provider`]: `サード・パーティー OAuth プロバイダーの作成`,
  [`Create truststore`]: `トラストストアの作成`,
  [`Create user registry`]: `ユーザー・レジストリーの作成`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `GraphQL サービスに基づいた GraphQL プロキシーの作成`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `OpenAPI で記述されたターゲット・サービスに基づいて REST プロキシーを作成します`,
  [`Create a REST proxy based upon a WSDL described target service`]: `WSDL で記述されたターゲット・サービスに基づいて REST プロキシーを作成します`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `OpenAPI で記述されたターゲット・サービスに基づいて REST プロキシーを作成します`,
  [`Create a REST proxy based upon the WSDL described target service`]: `WSDL で記述されたターゲット・サービスに基づいて REST プロキシーを作成します`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `すべてのトラフィックをターゲット API またはサービス・エンドポイントにルーティングする REST プロキシーを作成します`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `WSDL で記述されたターゲット・サービスに基づいて SOAP プロキシーを作成します`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `WSDL で記述されたターゲット・サービスに基づいて SOAP プロキシーを作成します`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `ハンドル・バー・テンプレートを使用して新しいオブジェクトを作成します。 変数はコンテキストから取得されます。`,
  [`Create a portal site for the catalog`]: `カタログ用のポータル・サイトを作成します`,
  [`Create a product by importing a product definition`]: `製品定義をインポートして製品を作成します`,
  [`Create a security definition`]: `セキュリティー定義を作成します`,
  [`Create a subscription`]: `サブスクリプションの作成`,
  [`Create an API by importing an OpenAPI definition`]: `OpenAPI 定義をインポートして API を作成します`,
  [`Create an API that calls an existing SOAP service`]: `既存の SOAP サービスを呼び出す API の作成`,
  [`Create and manage API provider organizations and owners`]: `API プロバイダー組織と所有者を作成および管理します`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `カタログ内のスペースの作成と管理を行います。スペースを使用すると、カタログをパーティション化してさまざまな API プロバイダー開発チームをサポートできます`,
  [`Create and publish`]: `作成および公開`,
  [`Create and publish APIs and Products`]: `API および製品の作成および公開`,
  [`Create and subscribe`]: `作成およびサブスクライブ`,
  [`Create assembly`]: `アセンブリーの作成`,
  [`Create empty parent object for failed mapping`]: `失敗したマッピングに対する空の親オブジェクトの作成`,
  [`Create endpoint`]: `エンドポイントの作成`,
  [`Create from`]: `作成元`,
  [`Create from Existing WSDL service`]: `既存の WSDL サービスからの作成`,
  [`Create from File or URL`]: `ファイルまたは URL からの作成`,
  [`Create from existing openAPI service`]: `既存の OpenAPI サービスからの作成`,
  [`Create gateway extension`]: `ゲートウェイ拡張の作成`,
  [`Create new user`]: `新規ユーザーの作成`,
  [`Create new version`]: `新規バージョンの作成`,
  [`Create product using a template`]: `テンプレートを使用して製品を作成`,
  [`Create provider organization`]: `プロバイダー組織の作成`,
  [`Create required child properties for array objects and mapped optional objects`]: `配列オブジェクトとマップ済みオプション・オブジェクトに必要な子プロパティーの作成`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `OAuth プロバイダー構成により、サンプルとして作成されました。 このサンプルと正しいユーザー・レジストリーを使用して、OAuth プロバイダーを更新してください。`,
  [`Created new product`]: `新規製品が作成されました`,
  [`Create|permission`]: `作成`,
  [`Create|title`]: `作成`,
  [`Creating Draft Product`]: `ドラフト製品の作成中`,
  [`Creating a Consumer organization`]: `コンシューマー組織の作成`,
  [`Creating a consumer organization`]: `コンシューマー組織の作成`,
  [`Creating a new Product`]: `新規製品の作成`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons (CC-BY-4.0) ライセンス`,
  [`Credential Extraction Method`]: `資格情報の抽出方式`,
  [`Credentials for the Sandbox catalog`]: `サンドボックス・カタログの資格情報`,
  [`Credentials for the {catalogName} catalog`]: `{catalogName} カタログの資格情報`,
  [`Credential Name`]: `資格情報名`,
  [`Credential has been added.`]: `資格情報が追加されました。`,
  [`Credential`]: `資格情報`,
  [`Credentials`]: `資格情報`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `資格情報は、API Designer およびツールキットを実行するために必要です。`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `資格情報は、ツールキットを実行するために必要です。 ツールキットがインストールされているフォルダーに資格情報ファイルを配置してください。`,
  [`Credit Card`]: `クレジット・カード`,
  [`Cryptographic Algorithm`]: `暗号アルゴリズム`,
  [`Currency`]: `通貨`,
  [`Current password`]: `現行パスワード`,
  [`Current password is invalid.`]: `現在のパスワードは無効です。`,
  [`Custom`]: `カスタム`,
  [`Custom API URL`]: `カスタム API URL`,
  [`Custom expression (optional, one per line)`]: `カスタム式 (オプション、1 行に 1 つずつ)`,
  [`Custom expression to remove (optional, one per line)`]: `削除するカスタム式 (オプション、1 行に 1 つずつ)`,
  [`Custom HTML form`]: `カスタム HTML フォーム`,
  [`Custom policies`]: `カスタム・ポリシー`,
  [`Custom form TLS profile`]: `カスタム・フォーム TLS プロファイル`,
  [`Custom form content security policy`]: `カスタム・フォームのコンテンツ・セキュリティー・ポリシー`,
  [`Custom form CSP header value`]: `カスタム・フォームCSPヘッダー値`,
  [`Custom form endpoint`]: `カスタム・フォーム・エンドポイント`,
  [`Custom header pattern`]: `カスタム・ヘッダーのパターン`,
  [`Custom subscribability is required if custom visibility is selected`]: `カスタムの可視性が選択されている場合はカスタムのサブスクライブ可能性が必要です`,
  [`Customize`]: `カスタマイズ`,
  [`Customize email sender information`]: `E メール送信者情報のカスタマイズ`,
  [`Customize notification templates`]: `通知テンプレートのカスタマイズ`,
  [`Customize the plan API list`]: `プラン API リストのカスタマイズ`,
  [`Czech`]: `チェコ語`,
  [`DATE MODIFIED`]: `変更日`,
  [`DELETE`]: `DELETE`,
  [`Date created`]: `作成日`,
  [`Description`]: `説明`,
  [`DETAIL`]: `詳細`,
  [`DN Base`]: `DN ベース`,
  [`DNS Schemes`]: `DNS スキーム`,
  [`Dashboard`]: `ダッシュボード`,
  [`Data encoded form body`]: `データ・エンコード形式の本文`,
  [`Data flow in API Connect`]: `API Connect のデータ・フロー`,
  [`DataPower (v5 compatible)`]: `DataPower (v5 互換)`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway (v5 互換)`,
  [`DataPower Gateway policies`]: `DataPower Gateway ポリシー`,
  [`Debug`]: `デバッグ`,
  [`Debug rule`]: `デバッグ・ルール`,
  [`Debug XACML policy`]: `XACML ポリシーのデバッグ`,
  [`Dec`]: `減らす`,
  [`Decline`]: `同意しない`,
  [`Decline and send`]: `同意せずに送信`,
  [`Decode Request Params`]: `要求パラメーターのデコード`,
  [`Decrement`]: `減分`,
  [`Decrypt`]: `暗号化解除`,
  [`Decrypt Crypto JWK variable name`]: `暗号化解除用の暗号 JWK 変数名`,
  [`Decrypt Crypto Object`]: `暗号化解除用の暗号オブジェクト`,
  [`Decrypt a buffer using the specified certificate`]: `指定された証明書を使用して、バッファーを暗号化解除します`,
  [`Default`]: `デフォルト`,
  [`Default behavior`]: `デフォルトの動作`,
  [`Default gateways`]: `デフォルトのゲートウェイ`,
  [`default gateway services configured`]: `デフォルト・ゲートウェイ・サービスの構成`,
  [`Default Availability Zone`]: `デフォルトのアベイラビリティー・ゾーン`,
  [`Default Base endpoints`]: `デフォルトの基本エンドポイント`,
  [`Default availability zone`]: `デフォルトのアベイラビリティー・ゾーン`,
  [`Default base endpoints`]: `デフォルトの基本エンドポイント`,
  [`Default HTML form`]: `デフォルトの HTML フォーム`,
  [`Default limit`]: `デフォルトの制限`,
  [`Default OAuth Provider Settings`]: `デフォルトの OAuth プロバイダー設定`,
  [`Default OAuth Provider Settings Object`]: `デフォルトの OAuth プロバイダー設定オブジェクト`,
  [`Default Plan`]: `デフォルトのプラン`,
  [`Default TLS client profile`]: `デフォルトの TLS クライアント・プロファイル`,
  [`Default TLS server keystore`]: `デフォルトの TLS サーバー鍵ストア`,
  [`Default TLS server profile`]: `デフォルトの TLS サーバー・プロファイル`,
  [`Default burst-limit`]: `デフォルトのバースト制限`,
  [`Default form`]: `デフォルト・フォーム`,
  [`Default keystore for access_token signing key`]: `access_token 署名鍵のデフォルト鍵ストア`,
  [`Default keystore for id_token signing key`]: `id_token 署名鍵のデフォルト鍵ストア`,
  [`Default keystore for temporary token signing key`]: `一時トークン署名鍵のデフォルト鍵ストア`,
  [`Default rate-limit`]: `デフォルトのレート制限`,
  [`Default scopes`]: `デフォルト・スコープ`,
  [`Default table entry`]: `デフォルトのテーブル・エントリー`,
  [`Default validator endpoint`]: `デフォルト・バリデーター・エンドポイント`,
  [`Default validator endpoint set by OAuth provider`]: `デフォルト・バリデーター・エンドポイントが OAuth プロバイダーによって設定されています`,
  [`Default value - no extra constraints apply.`]: `デフォルト値 - 追加の制約は適用されません。`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `API へのアクセスを保護するトークンを発行および検証するサード・パーティー OAuth プロバイダーを定義します。`,
  [`Define catalog specific values for this property`]: `このプロパティーのカタログ固有の値を定義します`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `API、製品、カタログ、アプリケーション、および関連の設定を管理するグループを定義します。`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `特定の条件で実行するポリシーのセットを定義します。 複数のケースを定義でき、各ケースを任意の条件と関連付けることができます。 最初に一致したケースのみが実行されます。`,
  [`Define the API protection source. At least one option must be enabled.`]: `API 保護ソースを定義します。 少なくとも 1 つのオプションが有効である必要があります。`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `OIDC 許可サーバーは、許可コードを以下のエンドポイントにリダイレクトします。 このリダイレクト・エンドポイントは、お客様がアプリケーションを OIDC プロバイダーに登録する際に必要です。 例えば、このユーザー・レジストリーをプロバイダー組織が使用している場合、お客様は、プロバイダー組織のリダイレクト・エンドポイントを OIDC プロバイダーに登録する必要があります。`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `マップで使用する入力を定義します。 タイトルおよび説明をマップに割り当てることもできます。`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `OpenID プロバイダーに認証要求を送信する OpenID プロバイダー・エンドポイントを定義します`,
  [`Define the outputs to be used in the map.`]: `マップで使用する出力を定義します。`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `無料トライアル日数とプラン価格設定を定義します。 ユーザーは、それぞれのサブスクリプション日付に基づいて請求されます。`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `ポリシー実行時にエラーがスローされた場合、フローを継続するかどうかを決定します。 これを選択しなかった場合は、キャッチ・フローがトリガーされます。`,
  [`Definition`]: `定義`,
  [`Definition Object`]: `定義オブジェクト`,
  [`Definitions`]: `定義`,
  [`Delete`]: `削除`,
  [`Delete Application`]: `アプリケーションの削除`,
  [`Delete Cloud Connection.`]: `クラウド接続を削除します。`,
  [`Delete Selected APIs`]: `選択した API の削除`,
  [`Delete selected client profiles`]: `選択したクライアント・プロファイルの削除`,
  [`Delete selected keystores`]: `選択した鍵ストアの削除`,
  [`Delete selected mail servers`]: `選択したメール・サーバーの削除`,
  [`Delete Selected Products`]: `選択した製品の削除`,
  [`Delete selected truststores`]: `選択したトラストストアの削除`,
  [`Delete selected user registries`]: `選択したユーザー・レジストリーの削除`,
  [`Delete Staged Product`]: `ステージング済み製品の削除`,
  [`Delete a space`]: `スペースを削除する`,
  [`Delete a catalog`]: `カタログの削除`,
  [`Delete API Key`]: `API キーの削除`,
  [`Delete API Key failed`]: `API キーの削除に失敗しました`,
  [`Delete availability zone`]: `アベイラビリティー・ゾーンの削除`,
  [`Delete selected requests`]: `選択した要求の削除`,
  [`Delete row`]: `行の削除`,
  [`Delete|title`]: `削除`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `コンシューマー組織、アプリケーション、公開済み製品、およびその他のリソースが多数ある場合、カタログの削除が失敗する可能性があります。  カタログを削除する前に、これらのリソースのクリーンアップを検討してください。`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `カタログ、コンシューマー組織、アプリケーション、公開済み製品、およびその他のリソースが多数ある場合、プロバイダー組織の削除が失敗する可能性があります。 プロバイダー組織を削除する前に、これらのリソースのクリーンアップを検討してください。`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `コンシューマー組織、アプリケーション、公開済み製品、およびその他のリソースが多数ある場合、スペースの削除が失敗する可能性があります。  スペースを削除する前に、これらのリソースのクリーンアップを検討してください。`,
  [`Deleting {{name}}...`]: `{name} の削除...`,
  [`Deprecate`]: `非推奨`,
  [`Deprecate product`]: `製品を非推奨にする`,
  [`Deprecated`]: `非推奨`,
  [`Deprecate|permission`]: `非推奨`,
  [`Description of gateway`]: `ゲートウェイの説明`,
  [`Description text for the behaviour of the modal.`]: `モーダルの動作の説明テキスト。`,
  [`Descriptive name`]: `記述名`,
  [`Design`]: `設計`,
  [`Designate a current member as owner of a consumer organization.`]: `現在のメンバーをコンシューマー組織の所有者として指定します。`,
  [`Designate a current member as owner of a provider organization.`]: `現在のメンバーをプロバイダー組織の所有者として指定します。`,
  [`Designer`]: `Designer`,
  [`Destination`]: `宛先`,
  [`Determines which credential-extraction method to use.`]: `使用する資格情報の抽出方式を決定します。`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `このタイプ/フィールドおよびすべての該当する引数をスキーマに含めるかどうかを決定します。`,
  [`Develop`]: `開発`,
  [`Develop APIs`]: `API の開発`,
  [`Develop APIs and products`]: `API および製品の開発`,
  [`Developer`]: `開発者`,
  [`developer`]: `開発者`,
  [`Developer Portal`]: `開発者ポータル`,
  [`Developer community`]: `開発者コミュニティー`,
  [`Developers can request a state upgrade from development to production`]: `開発者は開発から実動への状態アップグレードを要求できます`,
  [`Development`]: `開発`,
  [`Directive`]: `ディレクティブ`,
  [`Director Endpoint`]: `ディレクター・エンドポイント`,
  [`Director TLS client profile`]: `ディレクター TLS クライアント・プロファイル`,
  [`Disable`]: `無効にする`,
  [`Disable Activity log`]: `アクティビティー・ログの無効化`,
  [`Disable Self-Service Onboarding`]: `セルフサービス・オンボーディングを無効にする`,
  [`Disable spaces`]: `スペースを無効にする`,
  [`Disable buffering`]: `バッファリングを無効にする`,
  [`Disable Self-Service Onboarding Approval`]: `セルフサービス・オンボーディング承認の無効化`,
  [`Disable overriding consumer organizations invitation timeout.`]: `コンシューマー組織の招待タイムアウトのオーバーライドを無効にします。`,
  [`Disabled`]: `無効`,
  [`disabled`]: `無効`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `セルフサービス・オンボーディングを無効にするには、すべてのアプリケーション開発者が API プロバイダー、既存のコンシューマー組織の所有者、または管理者によって招待されている必要があります。`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `セルフサービス・オンボーディング承認を無効にすると、すべてのオンボーディング要求が自動的に承認されます。`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `無効にすると、コンシューマー組織の招待タイムアウトはカタログ・タイムアウトに設定されません。`,
  [`Discover targets`]: `ターゲットの検出`,
  [`Display table checkboxes`]: `テーブル・チェック・ボックスの表示`,
  [`Display vanity endpoint`]: `バニティー・エンドポイントを表示する`,
  [`Do not publish API (continue editting)`]: `API を公開しない (編集を続ける)`,
  [`Do not require applications or users to authenticate`]: `アプリケーションおよびユーザーに認証を要求しません`,
  [`Do not require authentication`]: `認証を要求しない`,
  [`Do not share the resource with provider organizations`]: `プロバイダー組織とリソースを共有しません`,
  [`Do not share the API gateway with provider organizations`]: `プロバイダー組織と API ゲートウェイを共有しません。`,
  [`Do not share the service with provider organizations`]: `プロバイダー組織とサービスを共有しません`,
  [`Do not use Dynamic DNS`]: `動的 DNS を使用しない`,
  [`Do you want to remove these references?`]: `これらの参照を削除しますか?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker 資料`,
  [`Docs`]: `資料`,
  [`Document Editor`]: `文書エディター`,
  [`Documentation and tutorials with step-by-step instructions`]: `ステップバイステップの手順を含むドキュメンテーションとチュートリアル`,
  [`Domain name`]: `ドメイン・ネーム`,
  [`Domain Name`]: `ドメイン・ネーム`,
  [`Domains handled by gateway via SNI`]: `SNI によりゲートウェイが処理するドメイン`,
  [`Don't have an account?`]: `アカウントをお持ちでない場合`,
  [`Done`]: `完了`,
  [`Download`]: `ダウンロード`,
  [`Download Gateway`]: `ゲートウェイのダウンロード`,
  [`Download and install`]: `次のものをダウンロードしてインストールします`,
  [`Download and set up DataPower API Gateway`]: `DataPower API Gateway のダウンロードとセットアップ`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `オンプレミスまたは任意のクラウドで使用するための DataPower API Gateway のダウンロードとセットアップ`,
  [`Download for Linux`]: `ダウンロード (Linux 用)`,
  [`Download for Mac`]: `ダウンロード (Mac 用)`,
  [`Download for Windows`]: `ダウンロード (Windows 用)`,
  [`Download gateway`]: `ゲートウェイのダウンロード`,
  [`Download schema`]: `スキーマのダウンロード`,
  [`Download Toolkit`]: `ツールキットのダウンロード`,
  [`Download toolkit`]: `ツールキットのダウンロード`,
  [`Download toolkit and credentials for various platforms`]: `各種プラットフォーム用のツールキットと資格情報をダウンロードします。`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `このクラウド接続用のダウンロードされた資格情報が見つかりません。 ログインでは、デフォルトの資格情報が使用されます。`,
  [`Dutch`]: `オランダ語`,
  [`Draft {type} creation failed`]: `ドラフト {type} の作成が失敗しました`,
  [`Drafts`]: `ドラフト`,
  [`Drag and drop files here or click to upload`]: `ここにファイルをドラッグ・アンド・ドロップするか、クリックしてアップロードしてください`,
  [`Drag & Drop`]: `ドラッグ &amp;amp; ドロップ`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `動的 DNS`,
  [`Dynamic OAuth configuration from a URL`]: `URL からの動的 OAuth 構成`,
  [`Dynamic OAuth configuration from a literal string`]: `リテラル・ストリングからの動的 OAuth 構成`,
  [`Dynamic group`]: `動的グループ`,
  [`Dynamic table entries`]: `動的テーブル・エントリー`,
  [`ENABLE`]: `有効`,
  [`Encoded`]: `エンコード済み`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `各スペースは異なる API プロバイダー開発チームにより使用され、その関連付けられたチームがそのスペースに公開する API 専用の独自の管理機能のセットを備えているため、各チームは自分達の API を独立して管理できます。 スペースが有効になっているカタログに API をステージングまたは公開するときに、ステージングまたは公開の対象とするカタログ内のスペースを指定します。`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `各プロバイダー組織は API、製品、プラン、およびカタログのセットを所有します。`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `クラウドおよびオンプレミスにある API を簡単に作成、保護、管理、共有、分析できます。`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `各プロバイダー組織は API、製品、プラン、およびカタログのセットを所有します。 [詳細情報]({link})`,
  [`Edit`]: `編集`,
  [`Edit access token TTL`]: `アクセス・トークン TTL の編集`,
  [`Edit admin organization invitation timeout`]: `管理者組織の招待タイムアウトの編集`,
  [`Edit API`]: `API の編集`,
  [`Edit API Connect UI`]: `API Connect UI の編集`,
  [`Edit API endpoint for unenforced APIs`]: `強制されない API に対するエンドポイントの編集`,
  [`Edit API gateway service`]: `API ゲートウェイ・サービスの編集`,
  [`Edit API Path`]: `API パスの編集`,
  [`Edit API security definition`]: `API セキュリティー定義の編集`,
  [`Edit API user registries`]: `API ユーザー・レジストリーの編集`,
  [`Edit analytics service`]: `分析サービスの編集`,
  [`Edit application`]: `アプリケーションの編集`,
  [`Edit Assembly`]: `アセンブリーの編集`,
  [`Edit authentication URL user registry`]: `認証 URL ユーザー・レジストリーの編集`,
  [`Edit availability zone`]: `アベイラビリティー・ゾーンの編集`,
  [`Edit Availablity Zone`]: `アベイラビリティー・ゾーンの編集`,
  [`Edit and apply constraints to your selected items.`]: `制約を編集して、選択した項目に適用します。`,
  [`Edit billing integration`]: `請求統合の編集`,
  [`Edit Catalog Details`]: `カタログの詳細の編集`,
  [`Edit cloud setting invitation timeout`]: `クラウド設定の招待タイムアウトの編集`,
  [`Edit constraints`]: `制約の編集`,
  [`Edit consumer organization`]: `コンシューマー組織の編集`,
  [`Edit DNS Scheme`]: `DNS スキームの編集`,
  [`Edit DataPower API gateway service`]: `DataPower API ゲートウェイ・サービスの編集`,
  [`Edit DataPower gateway service`]: `DataPower ゲートウェイ・サービスの編集`,
  [`Edit definition`]: `定義の編集`,
  [`Edit dynamic DNS`]: `動的 DNS の編集`,
  [`Edit email server`]: `E メール・サーバーの編集`,
  [`Edit extensions`]: `n拡張の編集`,
  [`Edit Gateway Service`]: `ゲートウェイ・サービスの編集`,
  [`Edit gateway`]: `ゲートウェイの編集`,
  [`Edit gateway extension`]: `ゲートウェイ拡張の編集`,
  [`Edit Gateways`]: `ゲートウェイの編集`,
  [`Edit IBM-managed gateway`]: `IBM で管理されるゲートウェイの編集`,
  [`Edit Invitation Timeout`]: `招待タイムアウトの編集`,
  [`Edit gateways`]: `ゲートウェイの編集`,
  [`Edit invitation timeout`]: `招待タイムアウトの編集`,
  [`Edit JSON`]: `JSON の編集`,
  [`Edit keystore`]: `鍵ストアの編集`,
  [`Edit LDAP user registry`]: `LDAP ユーザー・レジストリーの編集`,
  [`Edit LTPA Token`]: `LTPA トークンの編集`,
  [`Edit lifecycle approvals`]: `ライフサイクルの承認の編集`,
  [`Edit local user registry`]: `ローカル・ユーザー・レジストリーの編集`,
  [`Edit native OAuth provider`]: `ネイティブ OAuth プロバイダーの編集`,
  [`Edit New Billing System`]: `新規請求システムの編集`,
  [`Edit Notification Server`]: `通知サーバーの編集`,
  [`Edit Notification template`]: `通知テンプレートの編集`,
  [`Edit OAuth Provider`]: `OAuth プロバイダーの編集`,
  [`Edit OAuth provider details`]: `OAuth プロバイダーの詳細を編集`,
  [`Edit OAuth Provider Visibility`]: `OAuth プロバイダーの可視性の編集`,
  [`Edit OIDC User Registry`]: `OIDC ユーザー・レジストリーの編集`,
  [`Edit Onboarding`]: `オンボーディングの編集`,
  [`Edit Operation`]: `操作の編集`,
  [`Edit Organization`]: `組織の編集`,
  [`Edit Path`]: `パスの編集`,
  [`Edit Path Parameters`]: `パス・パラメーターの編集`,
  [`Edit notification template`]: `通知テンプレートの編集`,
  [`Edit OAuth provider`]: `OAuth プロバイダーの編集`,
  [`Edit OAuth provider visibility`]: `OAuth プロバイダーの可視性の編集`,
  [`Edit OIDC user registry`]: `OIDC ユーザー・レジストリーの編集`,
  [`Edit onboarding`]: `オンボーディングの編集`,
  [`Edit operation`]: `操作の編集`,
  [`Edit organization`]: `組織の編集`,
  [`Edit path`]: `パスの編集`,
  [`Edit path parameters`]: `パス・パラメーターの編集`,
  [`Edit plan`]: `プランの編集`,
  [`Edit portal`]: `ポータルの編集`,
  [`Edit portal service`]: `ポータル・サービスの編集`,
  [`Edit Portal Settings`]: `ポータル設定の編集`,
  [`Edit product`]: `製品の編集`,
  [`Edit product APIs`]: `製品 API の編集`,
  [`Edit Product Subscribability`]: `製品のサブスクライブ可能性の編集`,
  [`Edit Product Visibility`]: `製品の可視性の編集`,
  [`Edit Property`]: `プロパティーの編集`,
  [`Edit Provider Organization`]: `プロバイダー組織の編集`,
  [`Edit Refresh Token TTL`]: `リフレッシュ・トークン TTL の編集`,
  [`Edit remote gateway`]: `リモート・ゲートウェイの編集`,
  [`Edit Reset Password TTL`]: `パスワードのリセット TTL の編集`,
  [`Edit Role`]: `ロールの編集`,
  [`Edit Role Default for Provider Organizations`]: `プロバイダー組織のデフォルト・ロールの編集`,
  [`Edit Role for Provider Organizations`]: `プロバイダー組織のロールの編集`,
  [`Edit property`]: `プロパティーの編集`,
  [`Edit provider organization`]: `プロバイダー組織の編集`,
  [`Edit refresh token TTL`]: `リフレッシュ・トークン TTL の編集`,
  [`Edit reset password TTL`]: `パスワードのリセット TTL の編集`,
  [`Edit role`]: `ロールの編集`,
  [`Edit role default for Provider Organizations`]: `プロバイダー組織のデフォルト・ロールの編集`,
  [`Edit role for Provider Organizations`]: `プロバイダー組織のロールの編集`,
  [`Edit Roles`]: `ロールの編集`,
  [`Edit Schema in JSON/XML`]: `JSON/XML でのスキーマの編集`,
  [`Edit security definition`]: `セキュリティー定義の編集`,
  [`Edit sender`]: `送信者の編集`,
  [`Edit sender & email server`]: `送信者および E メール・サーバーの編集`,
  [`Edit sender Info`]: `送信者情報の編集`,
  [`Edit service visibility`]: `サービスの可視性の編集`,
  [`Edit show/hide settings`]: `表示/非表示の設定の編集`,
  [`Edit space details`]: `スペース詳細の編集`,
  [`Edit TLS client profile`]: `TLS クライアント・プロファイルの編集`,
  [`Edit TLS client profile visibility`]: `TLS クライアント・プロファイルの可視性の編集`,
  [`Edit TLS server profile`]: `TLS サーバー・プロファイルの編集`,
  [`Edit third party OAuth provider`]: `サード・パーティー OAuth プロバイダーの編集`,
  [`Edit truststore`]: `トラストストアの編集`,
  [`Edit unenforced API`]: `強制されない API の編集`,
  [`Edit user registries`]: `ユーザー・レジストリーの編集`,
  [`Edit user registry`]: `ユーザー・レジストリーの編集`,
  [`Edit user registry visibility`]: `ユーザー・レジストリーの可視性の編集`,
  [`Edit user registry for API Manager`]: `API Manager 用のユーザー・レジストリーを編集`,
  [`Edit user registry for Cloud Manager`]: `Cloud Manager 用のユーザー・レジストリーの編集`,
  [`Edit vanity API endpoints`]: `バニティー API エンドポイントの編集`,
  [`Edit visibility`]: `可視性の編集`,
  [`Edit XML`]: `XML の編集`,
  [`Edit app lifecycle approved`]: `承認されたアプリケーション・ライフサイクルの編集`,
  [`Edit app lifecycle cancelled`]: `キャンセルされたアプリケーション・ライフサイクルの編集`,
  [`Edit app lifecycle denied`]: `拒否されたアプリケーション・ライフサイクルの編集`,
  [`Edit app lifecycle pending`]: `保留中のアプリケーション・ライフサイクルの編集`,
  [`Edit app lifecycle request`]: `アプリケーション・ライフサイクル要求の編集`,
  [`Edit app reinstated`]: `復元されたアプリケーションの編集`,
  [`Edit app suspended`]: `中断されたアプリケーションの編集`,
  [`Edit audit setting`]: `監査設定の編集`,
  [`Edit definitions`]: `定義の編集`,
  [`Enable external group mapping`]: `外部グループ・マッピングを有効にします`,
  [`Edit extra constraints for the GraphQL schema`]: `GraphQL スキーマの追加の制約を編集`,
  [`Edit GraphQL schema|title`]: `GraphQL スキーマの編集`,
  [`Edit inline schema`]: `インライン・スキーマの編集`,
  [`Edit inputs`]: `入力の編集`,
  [`Edit invitation`]: `招待の編集`,
  [`Edit mail server test connection`]: `メール・サーバーのテスト接続の編集`,
  [`Edit member invitation`]: `メンバーの招待の編集`,
  [`Edit notification templates`]: `通知テンプレートの編集`,
  [`Edit notification templates:`]: `通知テンプレートの編集:`,
  [`Edit operation list`]: `操作リストの編集`,
  [`Edit or upload JSON schema`]: `JSON スキーマの編集またはアップロード`,
  [`Edit outputs`]: `出力の編集`,
  [`Edit password changed`]: `変更されたパスワードの編集`,
  [`Edit password reset`]: `リセットされたパスワードの編集`,
  [`Edit plan APIs`]: `プラン API の編集`,
  [`Edit schema`]: `スキーマの編集`,
  [`Edit schema|button`]: `スキーマの編集`,
  [`Edit schema|title`]: `スキーマの編集`,
  [`Edit settings for roles, notifications and more.`]: `ロール、通知、その他の設定を編集します。`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `ユーザー・レジストリー、ロール、エンドポイントなどの設定を編集します`,
  [`Edit sign up`]: `サインアップの編集`,
  [`Edit task product lifecycle approved`]: `承認されたタスク製品ライフサイクルの編集`,
  [`Edit task product lifecycle cancelled`]: `キャンセルされたタスク製品ライフサイクルの編集`,
  [`Edit task product lifecycle denied`]: `拒否されたタスク製品ライフサイクルの編集`,
  [`Edit task product lifecycle pending`]: `保留中のタスク製品ライフサイクルの編集`,
  [`Edit task product lifecycle request`]: `タスク製品ライフサイクル要求の編集`,
  [`Edit task subscription approved`]: `承認されたタスク・サブスクリプションの編集`,
  [`Edit task subscription cancelled`]: `キャンセルされたタスク・サブスクリプションの編集`,
  [`Edit task subscription denied`]: `拒否されたタスク・サブスクリプションの編集`,
  [`Edit task subscription pending`]: `保留中のタスク・サブスクリプションの編集`,
  [`Edit task subscription request`]: `タスク・サブスクリプション要求の編集`,
  [`Edit template`]: `テンプレートの編集`,
  [`Edit the TLS client profile.`]: `TLS クライアント・プロファイルを編集します。`,
  [`Edit the TLS Server profiles`]: `TLS サーバー・プロファイルを編集します。`,
  [`Edit the keystore title.`]: `鍵ストアのタイトルを編集します。`,
  [`Edit the organization summary details as required`]: `必要に応じて、組織要約の詳細を編集します`,
  [`Edit the organization title`]: `組織のタイトルを編集します`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `E メール・サーバーを構成するためのパラメーターを編集します。 オプションと示されていないフィールドは必須です。`,
  [`Edit signed token`]: `署名済みトークンの編集`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `E メール・テンプレートのテキストを編集します。 変数は中括弧で囲まれており、編集できません。`,
  [`Edit the truststore.`]: `トラストストアを編集します。`,
  [`Edit the visibility for each resource.`]: `各リソースの可視性を編集します。`,
  [`Edit user details`]: `ユーザーの詳細の編集`,
  [`Edit {notificationTemplate}`]: `{notificationTemplate} の編集`,
  [`Edit, assemble, secure and test APIs`]: `API の編集、アセンブル、保護、およびテスト`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `API を編集、アセンブル、保護、およびテストします。 コンシューマーへの公開のために、製品を使用する API をパッケージします`,
  [`Edit, upload, or infer XML schema`]: `XML スキーマの編集、アップロード、または推定`,
  [`Editors`]: `エディター`,
  [`Edit|permission`]: `編集`,
  [`Edit API key timeout`]: `API キーのタイムアウトの編集`,
  [`Edit Base64 encoding for temporary tokens`]: `一時トークンの Base64 エンコードの編集`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `「ID トークン署名暗号オブジェクト」と「ID トークン署名鍵」のいずれかを指定する必要があります。`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `「JWT 検証暗号オブジェクト」または「JWT 検証 JWK」のいずれかを指定する必要があります。`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `API で定義されているエンドポイントを使用するオプションを選択するか、デフォルト・バリデーター・エンドポイントを指定してください。`,
  [`Element name for JSON array elements`]: `JSON 配列エレメントのエレメント名`,
  [`Email`]: `E メール`,
  [`Email address`]: `メールアドレス`,
  [`Email Content`]: `E メールの内容`,
  [`Email endpoint`]: `E メール・エンドポイント`,
  [`Email required`]: `E メールは必須です`,
  [`Email sender`]: `メール送信者`,
  [`Email server`]: `E メール・サーバー`,
  [`Email server configuration`]: `E メール・サーバーの構成`,
  [`Email servers`]: `E メール・サーバー`,
  [`Empty case`]: `空のケース`,
  [`Empty catch`]: `空の Catch`,
  [`Empty JSON array handling`]: `空の JSON 配列の処理`,
  [`Empty XML element handling`]: `空の XML エレメントの処理`,
  [`Enable`]: `有効にする`,
  [`Enable API consumers to invite collaborators and assign roles`]: `API コンシューマーを有効にしてコラボレーターを招待し、ロールを割り当てる`,
  [`Enable application lifecycle`]: `アプリケーション・ライフサイクルの有効化`,
  [`Enable billing`]: `請求の有効化`,
  [`Enable gateway services`]: `ゲートウェイ・サービスの有効化`,
  [`Enable gateway services and policies`]: `ゲートウェイ・サービスおよびポリシーの有効化`,
  [`Enable GraphiQL`]: `GraphiQL の有効化`,
  [`Enable GraphiQL editor`]: `GraphiQL エディターの有効化`,
  [`Enable GraphiQL Editor on browser`]: `ブラウザーでの GraphiQL エディターの有効化`,
  [`Enable JSON post processing`]: `JSON 後処理を有効にする`,
  [`Enable lifecycle approvals`]: `ライフサイクルの承認を有効にする`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `NONCE 拡張を有効にして、漏えいした要求が再び使用 (リプレイ) されるのを防止します`,
  [`Enable OAuth providers`]: `OAuth プロバイダーの有効化`,
  [`Enable OIDC`]: `OIDC を有効にする`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `OpenID Connect テンプレートを有効にして ID トークンを生成します。`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `PKCE 拡張を有効にして、許可コードがインターセプトされる脅威を公開クライアントが軽減できるようにします`,
  [`Enable production mode`]: `実動モードを有効にする`,
  [`Enable Proxy`]: `プロキシーを有効にする`,
  [`Enable Self-Service Onboarding`]: `セルフサービス・オンボーディングを有効にする`,
  [`Enable spaces`]: `スペースを有効にする`,
  [`Enable token management`]: `トークン管理の有効化`,
  [`Enable buffering`]: `バッファリングを有効にする`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `招待なしで開発ポータル・アカウントを作成するには、API コンシューマーを有効にします。`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `API の Cross-Origin Resource Sharing (CORS) アクセス制御を有効にします。`,
  [`Enable debug response headers`]: `デバッグ応答ヘッダーの有効化`,
  [`Enable proof key for code exchange`]: `Proof Key for Code Exchange を有効にする`,
  [`Enable publishing of this product`]: `この製品の公開を有効にします`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `ポータル OIDC エンドポイント経由のサード・パーティー許可コードのリダイレクトを有効にします`,
  [`Enable security`]: `セキュリティーの有効化`,
  [`Enable Self-Service Onboarding Approval`]: `セルフサービス・オンボーディング承認の有効化`,
  [`Enable the following gateway services and policies for this catalog`]: `このカタログで以下のゲートウェイ・サービスおよびポリシーを有効にします`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `ユーザーが監査イベントを構成できるようにします。 監査イベントとは、誰がどのイベントをいつ実行したかの詳細な要約です。`,
  [`Enable/disable custom notification templates`]: `カスタム通知テンプレートを有効/無効にします`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `招待およびパスワードのリセット・トークンの Base64 エンコードを有効または無効にします`,
  [`Enable overriding consumer organizations invitation timeout`]: `コンシューマー組織の招待タイムアウトのオーバーライドを有効にする`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `一時トークンの Base64 エンコードを有効または無効にすることができます。 一時トークンは、招待およびパスワードのリセット・トークンです。 Base64 エンコードが有効になっている場合、一時トークンは Base64 形式でエンコードされます。 Base64 エンコードが無効になっている場合、一時トークンは JSON Web Token (JWT) 形式のままです。つまり、ユーザーが受け取る URL の長さは短くなります。`,
  [`Enabled`]: `有効`,
  [`enabled`]: `有効`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `アプリケーション・ライフサイクルを有効にすると、アプリケーションに開発状況または実動状況を指定でき、別々のゲートウェイおよびエンドポイントにルーティングできます。`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `セルフサービス・オンボーディング承認を有効にすると、すべてのオンボーディング要求について、API プロバイダーまたは管理者による承認が必要となります。`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `セルフサービス・オンボーディングを有効にすると、アプリケーション開発者は、API プロバイダー、既存のコンシューマー組織の所有者、または管理者から招待されていなくてもサインアップできます。`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `有効にすると、すべてのコンシューマー組織の招待タイムアウトがカタログの招待タイムアウトに設定されます。`,
  [`Encode Query params`]: `照会パラメーターのエンコード`,
  [`Encrypt`]: `暗号化`,
  [`Encrypt Crypto Object`]: `暗号化用暗号オブジェクト`,
  [`Encrypt JWK variable name`]: `暗号化用 JWK 変数名`,
  [`Encrypt a buffer using the specified certificate`]: `指定された証明書を使用して、バッファーを暗号化します`,
  [`Encryption Algorithm`]: `暗号化アルゴリズム`,
  [`Endpoint`]: `終了点`,
  [`Endpoint URLs`]: `エンドポイント URL`,
  [`Endpoint behavior`]: `エンドポイントの動作`,
  [`Endpoint Behavior`]: `エンドポイントの動作`,
  [`Endpoint for unenforced APIs`]: `強制されない API に対するエンドポイント`,
  [`Endpoints`]: `エンドポイント`,
  [`Enforce Required Params`]: `必須パラメーターの使用の強制`,
  [`Enforce the API by using API Connect Gateway.`]: `API Connect ゲートウェイを使用して API を強制します。`,
  [`Enforced`]: `強制`,
  [`English`]: `英語`,
  [`Enter Summary`]: `要約の入力`,
  [`Enter URL`]: `URL を入力`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `ユーザー・レジストリー名を入力してください。 空の場合、API Manager のローカル・ユーザー・レジストリーがデフォルトで使用されます。`,
  [`Enter a catalog name`]: `カタログ名を入力してください`,
  [`Enter a Display Name`]: `表示名を入力してください`,
  [`Enter a space name`]: `スペース名を入力してください`,
  [`Enter a brief description`]: `短い説明を入力してください`,
  [`Enter a display name`]: `表示名を入力してください`,
  [`Enter a temporary sign in password`]: `一時サインイン・パスワードを入力`,
  [`Enter an Organization Name`]: `組織名の入力`,
  [`Enter an Organization Title`]: `組織のタイトルの入力`,
  [`Enter catalog title`]: `カタログのタイトルを入力してください`,
  [`Enter details of the consumer organization`]: `コンシューマー組織の詳細を入力します`,
  [`Enter details of the product`]: `製品の詳細を入力します`,
  [`Enter details of the provider organization`]: `プロバイダー組織の詳細を入力します`,
  [`Enter details of this API`]: `この API の詳細を入力します`,
  [`Enter email of the new user`]: `新規ユーザーの E メールを入力`,
  [`Enter first name of the new user`]: `新規ユーザーの名を入力`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `1 つ以上の E メール・アドレスを入力します。 複数のアドレスを区切るには、コンマを使用してください。`,
  [`Enter organization name`]: `組織名を入力してください`,
  [`Enter organization title`]: `組織のタイトルを入力してください`,
  [`Enter password`]: `パスワードを入力してください`,
  [`Enter space title`]: `スペースのタイトルを入力してください`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `ホスト名とポート番号を指定して、ゲートウェイの API エンドポイントを入力します。 例: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `API 要約の詳細を入力してください`,
  [`Enter the API summary details. [Learn more]({link})`]: `API 要約の詳細を入力してください。 [詳細情報]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `プロキシー処理するターゲット・サービスの URL を入力します`,
  [`Enter the catalog summary details`]: `カタログ要約の詳細を入力してください`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `カタログ要約の詳細を入力します。カタログの作成後に、カタログを詳細に構成できます`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `管理者組織のメンバーとして招待するユーザーの E メール・アドレスを入力します`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `カタログのメンバーとして招待するユーザーの E メール・アドレスを入力します`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `プロバイダー組織のメンバーとして招待するユーザーの E メール・アドレスを入力します`,
  [`Enter the email address of the user to invite as a member of the space`]: `スペースのメンバーとして招待するユーザーの E メール・アドレスを入力します`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `所有権の移転先のユーザーの E メール・アドレスを入力します。`,
  [`Enter the full path to JSON or YAML file`]: `JSON ファイルまたは YAML ファイルの絶対パスを入力してください`,
  [`Enter the full path to WSDL file`]: `WSDL ファイルの絶対パスを入力してください`,
  [`Enter the full path to YAML file`]: `YAML ファイルの絶対パスを入力してください`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `インストール時に定義した Analytics 取り込みエンドポイントの完全修飾ドメイン・ネームを入力します。`,
  [`Enter the last name of the new user`]: `新規ユーザーの姓を入力`,
  [`Enter the owner's email address to send the invitation`]: `招待を送信するための所有者の E メール・アドレスを入力します`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `ゲートウェイ・サービスを構成するためのパラメーターを入力してください。 オプションと示されていないフィールドは必須です。 エンドポイントは、DataPower 上の API ゲートウェイ・サービス・エンドポイントです。 API エンドポイント・ベースは、着信 API 呼び出し用の API ゲートウェイ・エンドポイントです。 SNI の場合、すべてのホストを許可するために * を入力し (必須)、特定のホストを許可するにはホスト名も入力します。 各ホストをサポートする TLS プロファイルを選択します。`,
  [`Enter the password for the certificate file if required.`]: `必要に応じて、証明書ファイルのパスワードを入力します。`,
  [`Enter the provider organization summary details`]: `プロバイダー組織の要約の詳細を入力してください`,
  [`Enter the schema definitiom summary details`]: `スキーマ定義要約の詳細を入力してください`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `スペース要約の詳細を入力します。スペースの作成後に、スペースを詳細に構成できます`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `スペース要約の詳細を入力します。スペースの作成後に、スペースを詳細に構成できます`,
  [`Enter the summary details for the new product`]: `新規製品の要約の詳細を入力します`,
  [`Enter the summary details for this role`]: `このロールの要約の詳細を入力してください`,
  [`Enter title of the organization`]: `組織のタイトルを入力`,
  [`Enter user email`]: `ユーザー E メールの入力`,
  [`Enter username of the existing user`]: `既存のユーザーのユーザー名を入力`,
  [`Enter username of the new user`]: `新規ユーザーのユーザー名を入力`,
  [`Error`]: `エラー`,
  [`Error ID`]: `エラー ID`,
  [`Error:`]: `エラー:`,
  [`Copy error ID to Clipboard`]: `エラー ID をクリップボードにコピー`,
  [`Error Console`]: `エラー・コンソール`,
  [`Error connecting`]: `接続中のエラー`,
  [`Error connecting to GraphQL server`]: `GraphQL サーバーに接続する際にエラーが発生しました`,
  [`Error getting user info during login. Please login with different user.`]: `ログイン時にユーザー情報を取得中にエラーが発生しました。 別のユーザーでログインしてください。`,
  [`Error content`]: `エラーの内容`,
  [`Error message`]: `エラー・メッセージ`,
  [`Error name`]: `エラー名`,
  [`Error status code`]: `エラー状況コード`,
  [`Error status code is invalid`]: `エラー状況コードが無効です`,
  [`Error status reason`]: `エラー状況の理由`,
  [`Errors`]: `エラー`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `エラーは通常、情報の誤りおよびタイプミスが原因で起こります。 テストする前に、プロバイダー情報およびクライアント情報を正確に指定していることを確認してください。`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `URL {url}からAPIを取得しようとした際に、エラーが発生しました。 URL、ユーザー名、パスワードを確認してください。 エラー・メッセージ: {message}`,
  [`Event`]: `イベント`,
  [`Event gateway management client TLS client profile`]: `イベント・ゲートウェイ管理クライアント TLS クライアント・プロファイル`,
  [`Event load`]: `イベントのロード`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `各コンシューマー組織には所有者が存在します。 この所有者は、全管理許可を持ち、サブスクリプションとアプリケーションを管理できます。`,
  [`Exchange authorization code for tokens`]: `トークンの許可コードを交換します`,
  [`Exchange code for token`]: `トークンのコードを交換します`,
  [`Execute`]: `実行`,
  [`Execute migration target`]: `マイグレーション・ターゲットの実行`,
  [`Executes any catalog-level post-invoke handlers`]: `カタログ・レベルの呼び出し後ハンドラーを実行します`,
  [`Executes any catalog-level pre-invoke handlers`]: `カタログ・レベルの呼び出し前ハンドラーを実行します`,
  [`Existing`]: `既存`,
  [`Existing {docType}`]: `既存の {docType}`,
  [`Existing API Connect user`]: `既存の API Connect ユーザー`,
  [`Existing OpenAPI`]: `既存の OpenAPI`,
  [`Existing product`]: `既存の製品`,
  [`Existing SOAP API has been validated`]: `既存の SOAP API が検証されました`,
  [`Existing products in the catalog will be moved to a default space.`]: `カタログ内の既存の製品はデフォルト・スペースに移動されます。`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `既存のユーザーは現行メンバーです。 別のユーザー・レジストリーから新規メンバーをアクティブにすることもできます。`,
  [`Expires On`]: `有効期限`,
  [`Expiry`]: `有効期限`,
  [`Explorer`]: `Explorer`,
  [`Expose`]: `公開`,
  [`Extend the schema to specify the cost of types and fields`]: `スキーマを拡張してタイプおよびフィールドのコストを指定`,
  [`Extended`]: `拡張`,
  [`Extension Type:`]: `拡張タイプ:`,
  [`Extensions`]: `拡張`,
  [`Extension has been uploaded.`]: `拡張がアップロードされました。`,
  [`External`]: `外部`,
  [`External Doc Description`]: `外部ドキュメンテーションの説明`,
  [`External doc description`]: `外部ドキュメンテーションの説明`,
  [`External Documentation URL`]: `外部ドキュメンテーションの URL`,
  [`External documentation URL`]: `外部ドキュメンテーションの URL`,
  [`External Role mapping`]: `外部役割のマッピング`,
  [`External URL`]: `外部 URL`,
  [`Extract Identity Settings`]: `ID 抽出の設定`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `ファクター ID`,
  [`Fail`]: `失敗`,
  [`Failed to change account password.`]: `アカウント・パスワードの変更に失敗しました。`,
  [`Failed to create analytics service {title}.`]: `分析サービス {title} を作成できませんでした。`,
  [`Failed to create analytics insights service {title}.`]: `分析インサイト・サービスの作成に失敗しました{title}。`,
  [`Analytics insights service {title} has been updated.`]: `分析インサイト・サービスの{title} が更新されました。`,
  [`About the analytics insights service`]: `分析インサイト・サービスについて`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `分析インサイト・サービスは、ゲートウェイ・サービスからAPIイベントを収集します。 各ゲートウェイ・サービスには、関連する分析サービスを提供できます。`,
  [`Advanced analytics insights configuration`]: `拡張分析のインサイトの構成`,
  [`Analytics insights service {title} has been created.`]: `分析インサイト・サービスの{title} が作成されました。`,
  [`Analytics Insights details`]: `分析インサイトの詳細`,
  [`Management endpoint on the analytics insights service`]: `分析インサイト・サービスでの管理エンドポイント`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `インストール時に、定義した分析インサイトのディレクター・エンドポイントの完全修飾ドメインの名前を入力します。`,
  [`TYPE`]: `型`,
  [`Failed to create email server`]: `E メール・サーバーの作成に失敗しました`,
  [`Failed to create gateway service`]: `ゲートウェイ・サービスの作成に失敗しました`,
  [`Failed to create gateway service {name}.`]: `ゲートウェイ・サービス {name} の作成に失敗しました。`,
  [`Failed to create user registry {title}.`]: `ユーザー・レジストリー {title} の作成に失敗しました。`,
  [`Failed to delete a group`]: `グループの削除に失敗しました。`,
  [`Failed to get TLS client profiles`]: `TLS クライアント・プロファイルの取得に失敗しました`,
  [`Failed to get catalogs`]: `カタログの取得に失敗しました`,
  [`Failed to get configured OAuth providers`]: `構成済みの OAuth プロバイダーの取得に失敗しました`,
  [`Failed to get policies of gateways`]: `ゲートウェイのポリシーの取得に失敗しました`,
  [`Failed to get user registries`]: `ユーザー・レジストリーの取得に失敗しました`,
  [`Failed to introspect schema from server:`]: `サーバーからのスキーマのイントロスペクトに失敗しました:`,
  [`Failed to make API online`]: `APIをオンライン化にできません`,
  [`Failed to register catalog member {user}.`]: `カタログ・メンバー {user} の登録に失敗しました。`,
  [`Failed to remove analytics service: {arg}`]: `分析サービス {arg} の削除に失敗しました`,
  [`Failed to remove availability zone: {arg}`]: `アベイラビリティー・ゾーン {arg} の削除に失敗しました`,
  [`Failed to remove gateway service: {arg}`]: `ゲートウェイ・サービス {arg} の削除に失敗しました`,
  [`Failed to remove mailserver: {arg}`]: `メール・サーバー {arg} の削除に失敗しました`,
  [`Failed to remove portal service: {arg}`]: `ポータル・サービス {arg} の削除に失敗しました`,
  [`Failed to create {name}.`]: `{name} の作成に失敗しました。`,
  [`Failed to sign out! Please check the console for details.`]: `サインアウトに失敗しました。 詳細については、コンソールを確認してください。`,
  [`Failed to update {name}.`]: `{name} の更新に失敗しました。`,
  [`Failed to update OAuth provider`]: `OAuth プロバイダーの更新に失敗しました`,
  [`Failed to update account.`]: `アカウントを更新できませんでした。`,
  [`Failed to update analytics service {title}.`]: `分析サービス {title} を更新できませんでした。`,
  [`Failed to update gateway service {title}.`]: `ゲートウェイ・サービス {title} の更新に失敗しました。`,
  [`Failed to update task`]: `タスクを更新できませんでした`,
  [`Failed to create app {title}`]: `アプリ {title} の作成に失敗しました`,
  [`Feature flags`]: `機能フラグ`,
  [`Fetch schema`]: `スキーマのフェッチ`,
  [`Fetched endpoints`]: `エンドポイントをフェッチしました`,
  [`Field`]: `フィールド`,
  [`Field label`]: `フィールド・ラベル`,
  [`Field Mapping`]: `フィールド・マッピング`,
  [`Field name`]: `フィールド名`,
  [`Field weight`]: `フィールドの重み`,
  [`File Name`]: `ファイル名`,
  [`File Preview`]: `ファイル・プレビュー`,
  [`File contains validation errors`]: `ファイルに検証エラーがあります`,
  [`File is not a valid OpenAPI definition`]: `ファイルは有効な OpenAPI 定義ではありません`,
  [`File is not a valid extension format.`]: `ファイルは有効な拡張の形式ではありません。`,
  [`File is not a valid {fileType} format.`]: `ファイルは有効な {fileType} 形式ではありません。`,
  [`File name`]: `ファイル名`,
  [`File upload`]: `ファイルのアップロード`,
  [`File uploaded is not a valid file`]: `アップロードされたファイルは有効なファイルではありません`,
  [`File uploaded successfully`]: `ファイルが正常にアップロードされました`,
  [`File was updated and validated!`]: `ファイルが更新され、検証されました。`,
  [`File was uploaded and validated!`]: `ファイルがアップロードされ、検証されました。`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `ファイル {draftFilePath} は存在します。 ドラフトの名前/バージョンの組み合わせの変更を試行してください`,
  [`Fill out the details of this service`]: `このサービスの詳細を入力します。`,
  [`Filter`]: `フィルター`,
  [`Filter by operation`]: `操作によるフィルタリング`,
  [`Filter policies`]: `ポリシーのフィルター`,
  [`Final Action`]: `最終アクション`,
  [`Find existing user`]: `既存のユーザーを検索`,
  [`Find expert answers in the API Connect community forum`]: `API Connect コミュニティー・フォーラムで専門家の回答を検索します`,
  [`Find expert answers in the API Connect community forum.`]: `API Connect コミュニティー・フォーラムで専門家の回答を検索します。`,
  [`Find schema`]: `スキーマの検索`,
  [`Finger Print`]: `指紋`,
  [`Fingerprint SHA-1`]: `指紋 SHA-1`,
  [`Fingerprint SHA-256`]: `指紋 SHA-256`,
  [`Finish`]: `終了`,
  [`First name`]: `名`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `最初に、ゲートウェイが API トラフィックに使用するエンドポイントの URL を指定します。 値は、公開された完全修飾ドメイン・ネームに、使用する API 呼び出しに固有のパスを追加したものでなければなりません (例: https://api.mycompany.com)。`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `最初に、ゲートウェイ管理エンドポイントの URL を指定します。 Kubernetes 環境で実行中のゲートウェイの場合、この値はゲートウェイ・セットアップ・コマンド・セット gwy apic-gw-service で指定されたエンドポイントに一致する必要があります。 ゲートウェイ・アプライアンスの場合、この値は http://<ip-address-for-gateway>:3000 の形式にする必要があります。 ゲートウェイ・クラスターで作業する場合は、ロード・バランサーの address:port を指定します。`,
  [`Fit to screen`]: `画面に合わせる`,
  [`Flow`]: `フロー`,
  [`Follow redirects`]: `リダイレクトに従う`,
  [`Found In`]: `検出場所`,
  [`Fonts contained in the following file are available under the`]: `以下のファイルに含まれているフォントは次の下で使用できます:`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `WSDL 定義については、WSDL ファイルと API 定義 (YAML) の両方が含まれている zip ファイルをアップロードします`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `元の製品内のプランごとに、サブスクリプションのマイグレーション先とする置き換え後の製品内のプランを選択します`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `ソース製品内のプランごとに、サブスクリプションのマイグレーション先とするターゲット製品内のプランを選択します`,
  [`For guidance on the available command set, access the CLI's help`]: `使用可能なコマンド・セットについては、CLI のヘルプを参照してください`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `ネイティブの OAuth プロバイダーを使用するサイトでは、オプションで、すべての API 呼び出しで使用される共有秘密鍵を入力します。`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `書き込み可能な LDAP の場合、「ユーザー管理」を選択し、ソース LDAP レジストリーの属性名から API Connect のLDAP 属性値へのマッピングを追加します。 ユーザー登録中に API Connect が必要とするデフォルトのユーザー・プロファイル・プロパティーはユーザー名、名、姓、E メール、およびパスワードです。 マッピング構成例が提供されています。要件に適合するようにマッピングを編集してください`,
  [`Forbidden`]: `禁止`,
  [`Forget token`]: `トークンを忘れた場合`,
  [`Forgot password?`]: `パスワードを忘れた場合`,
  [`Forgot password`]: `パスワードを忘れた場合`,
  [`Form`]: `フォーム`,
  [`Forum`]: `フォーラム`,
  [`French`]: `フランス語`,
  [`From File`]: `ファイルから`,
  [`From Sample JSON`]: `サンプル JSON から`,
  [`From Sample XML`]: `サンプル XML から`,
  [`From URL`]: `URL から`,
  [`From existing GraphQL service (GraphQL proxy)`]: `既存の GraphQL サービスから (GraphQL プロキシー)`,
  [`From existing OpenAPI service`]: `既存の OpenAPI サービスから`,
  [`From existing WSDL service (REST proxy)`]: `既存の WSDL サービスから (REST プロキシー)`,
  [`From existing WSDL service (SOAP proxy)`]: `既存の WSDL サービスから (SOAP プロキシー)`,
  [`From target service`]: `ターゲット・サービスから`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `SOAP サービスの WSDL 定義に定義されている操作から、API で使用する操作を選択し、メソッドと URL パスを指定してください。`,
  [`Gateway Extensions`]: `ゲートウェイ拡張`,
  [`Gateway extension`]: `ゲートウェイ拡張`,
  [`Gateway Services`]: `ゲートウェイ・サービス`,
  [`Gateway Type`]: `ゲートウェイ・タイプ`,
  [`Gateway by Name`]: `名前順のゲートウェイ`,
  [`Gateway services`]: `ゲートウェイ・サービス`,
  [`Gateway type`]: `ゲートウェイ・タイプ`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL v2 ライセンス`,
  [`GROUP`]: `グループ`,
  [`Group name`]: `グループ名`,
  [`Gateway`]: `ゲートウェイ`,
  [`Gateways`]: `ゲートウェイ`,
  [`Gateway details`]: `ゲートウェイの詳細`,
  [`Gateway Details`]: `ゲートウェイの詳細`,
  [`Gateway Error`]: `ゲートウェイ・エラー`,
  [`Gateway service details`]: `ゲートウェイ・サービスの詳細`,
  [`Gateway management client TLS client profile`]: `ゲートウェイ管理クライアントの TLS クライアント・プロファイル`,
  [`Gateway management client keystore`]: `ゲートウェイ管理クライアントの鍵ストア`,
  [`Gateway management client truststore`]: `ゲートウェイ管理クライアントのトラストストア`,
  [`Gateway processing status`]: `ゲートウェイ処理状況`,
  [`Gateway policies (TODO)`]: `ゲートウェイ・ポリシー (TODO)`,
  [`Gateway service`]: `ゲートウェイ・サービス`,
  [`Gateway Service US East (TODO)`]: `ゲートウェイ・サービス US East (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `ゲートウェイ・サービス {arg} は削除されました。`,
  [`Gateway service configuration`]: `ゲートウェイ・サービス構成`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `ゲートウェイ・サービス・ホストは使用できません。 エクスプローラーでの API テスト (試す) は無効になります。`,
  [`Gateway service configuration has been updated.`]: `ゲートウェイ・サービス構成が更新されました。`,
  [`Gateway service {title} has been created.`]: `ゲートウェイ・サービス {title} が作成されました。`,
  [`Gateway service {title} has been updated.`]: `ゲートウェイ・サービス {title} が更新されました。`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `ゲートウェイ・サービスは、スペースが有効な場合は各スペース内で管理されます。 スペース設定にアクセスしてゲートウェイ・サービスを管理してください。`,
  [`Gateway version`]: `ゲートウェイのバージョン`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `ページごとのゲートウェイ`,
  [`Gather-and-send`]: `収集と送信`,
  [`Gather-only`]: `収集のみ`,
  [`General configuration`]: `一般的な構成`,
  [`General information about the API.`]: `API に関する一般情報。`,
  [`Generate`]: `生成`,
  [`Generate an OpenAPI definition for the proxy`]: `プロキシー用の OpenAPI 定義を生成`,
  [`Generate JSON Web Token (JWT)`]: `JSON Web トークン (JWT) の生成`,
  [`Generate JWT`]: `JWT の生成`,
  [`Generate LTPA Token`]: `LTPA トークンの生成`,
  [`Generate OAuth Access Tokens`]: `OAuth アクセス・トークンの生成`,
  [`Generate OAuth access tokens`]: `OAuth アクセス・トークンの生成`,
  [`Generate a default product`]: `デフォルト製品の生成`,
  [`Generate access token`]: `アクセス・トークンの生成`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `WebSphere Application Server Web サーバーに対して API が安全に認証できるようにするために、LTPA トークンを生成します。`,
  [`Generate an object`]: `オブジェクトの生成`,
  [`Generate authorization code`]: `許可コードの生成`,
  [`Generate from sample JSON`]: `サンプル JSON からの生成`,
  [`Generate from sample XML`]: `サンプル XML からの生成`,
  [`Generate tests automatically to build high quality APIs`]: `高品質 API を作成するためにテストを自動的に生成`,
  [`Generated OpenAPI 2.0 definition`]: `OpenAPI 2.0 定義が生成されました`,
  [`Generated default product for the API`]: `API のデフォルト製品が生成されました`,
  [`Generated name for use in commands and API calls`]: `コマンドおよび API 呼び出しで使用するための生成名`,
  [`German`]: `ドイツ語`,
  [`Get Base DN`]: `基本 DN の取得`,
  [`Get Code`]: `コードの取得`,
  [`Get Code Snippet`]: `コード・スニペットの取得`,
  [`Get Support`]: `サポート`,
  [`Get Token`]: `トークンの取得`,
  [`Get activation link`]: `アクティベーション・リンクの取得`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `最初にゲートウェイ・サービスを登録します。 これにより、その他のサービスを構成する際にエンドポイントをテストできます。 サービスを登録すると、ここに表示されます。 [詳細情報]({link})`,
  [`Get subscription ID`]: `サブスクリプション ID の取得`,
  [`Getting Started`]: `入門`,
  [`Getting started`]: `入門`,
  [`Git information`]: `Git 情報`,
  [`Github`]: `Github`,
  [`Global policies`]: `グローバル・ポリシー`,
  [`Go to Spaces`]: `スペースに移動`,
  [`Go to the Test tab`]: `「テスト」タブに移動`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL レート制限`,
  [`GraphQL service`]: `GraphQL サービス`,
  [`GraphQL schema`]: `GraphQL スキーマ`,
  [`GraphQL Schema`]: `GraphQL スキーマ`,
  [`GraphQL schema has been successfully validated.`]: `GraphQL スキーマの検証に成功しました。`,
  [`GraphQL Schema URL`]: `GraphQL スキーマ URL`,
  [`GraphQL send type`]: `GraphQL 送信タイプ`,
  [`GraphQL Websocket Upgrade`]: `GraphQL Websocket アップグレード`,
  [`Group Based DN`]: `グループ基本 DN`,
  [`Group {title} added`]: `グループ {title} が追加されました`,
  [`Group {title} updated`]: `グループ {title} が更新されました`,
  [`Group {title} has been deleted`]: `グループ {title} が削除されました`,
  [`Groups`]: `グループ`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `ホスト名`,
  [`HOST URL`]: `ホスト URL`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML フォーム`,
  [`HTML form time limit`]: `HTML フォーム制限時間`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 必須`,
  [`HTTP Header`]: `HTTP ヘッダー`,
  [`HTTP Method`]: `HTTP メソッド`,
  [`HTTP Type`]: `HTTP タイプ`,
  [`HTTP Version`]: `HTTP バージョン`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `ハンドル・バー`,
  [`Hard limit`]: `ハード制限`,
  [`Header`]: `ヘッダー`,
  [`Header control`]: `ヘッダー制御機構`,
  [`Header name payload`]: `ヘッダー名ペイロード`,
  [`Header name token`]: `ヘッダー名トークン`,
  [`Headers`]: `ヘッダー`,
  [`Hello`]: `ご連絡`,
  [`Hello!`]: `こんにちは`,
  [`Hello [IBM](url)`]: `[IBM](http://ibm.com) さん、こんにちは`,
  [`Hello {name}`]: `{name} さん、こんにちは`,
  [`Hello {name}!`]: `{name} さん、こんにちは。`,
  [`Hello {name}, [see IBM profile](url)`]: `{name} さん、こんにちは。[IBM プロファイルを見る](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{catalogTitle} 開発者ポータルのアカウントにご登録いただき、ありがとうございます。\n\n登録を完了するために、次のリンクをご利用ください。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{consumerOrg} 開発者ポータルの管理者が\n{appName} アプリケーションを復元しました。 このアプリケーションからの API 呼び出しは、API ゲートウェイによって\nブロックされなくなります。\n\n次のリンクを使用して、アプリの詳細を表示できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{consumerOrg} 開発者ポータルの管理者が\n{appName} アプリケーションを中断しました。 このアプリケーションからの API 呼び出しは、API ゲートウェイによってブロックされます。\n\n次のリンクを使用して、アプリの詳細を表示できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\nユーザー {originator} が {catalog} カタログ内の\n{productName} API 製品のバージョン {productVersion} の {planName} プランへのサブスクライブの承認を\n要求しています。\n\n次のリンクを使用して、タスク・リストで要求を開くことができます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\nユーザー {originator} が {catalog} カタログ内の {productName} API 製品の\nバージョン {productVersion} の {planName} プランに対するアプリケーション {appName}\nのサブスクライブの承認を要求しています。\n\n次のリンクを使用して、タスク・リストで要求を開くことができます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\nユーザー {originator} が {catalog} カタログ内のアプリケーション {appName} の {action} の承認を\n要求しています。\n\n次のリンクを使用して、タスク・リストで要求を開くことができます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\nユーザー {originator} が {catalog} カタログ内の {productName} API 製品のバージョン {version}\n の {action} の承認を要求しています。\n\n次のリンクを使用して、タスク・リストで要求を開くことができます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{{firstName}} さん、こんにちは。\n\n{{catalog}} 開発者ポータルのユーザー・アカウント {{username}} のパスワードを\nリセットする要求を受け取りました。\n\n要求していない場合は、このメッセージは無視してください。 要求した場合、\n次のリンクを使用してパスワードをリセットできます。\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `{firstName} さん、こんにちは。\n\nIBM API Connect のユーザー・アカウント \n{username} のパスワードをリセットする要求を受け取りました。\n\nパスワードをリセットするには、次のリンクを使用してください。\n\n{link}\n\n(リセットを要求していない場合、このメッセージは無視してください。)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{catalog} 開発者ポータルのユーザー・アカウント \n{username} のパスワードをリセットする要求を受け取りました。\n\n要求していない場合は、このメッセージは無視してください。 要求した場合、\n次のリンクを使用してパスワードをリセットできます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName} さん、こんにちは。\n\n{catalog} カタログ内の {appName} アプリケーションに対するサブスクリプション作成の要求は\n承認待ちです。 要求が処理された後に、\n別の通知をお送りします。\n\nアプリケーションの状況を以下で確認できます。\n\n{link}\n\n要求をキャンセルする場合は、次のリンクを使用してください。\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}} さん、こんにちは。\n\nAPI 製品 {{productName}} バージョン {{productVersion}} の\n{{appName}} アプリケーションについて {{planName}} プランの\nサブスクリプション作成要求が承認されました。\n\n{{#if comments}}\n承認者から以下のコメントがあります。\n\n"{{comments}}"\n\n{{/if}}\nアプリケーションの状況を以下のリンクで確認できます。\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\nAPI 製品 {productName} バージョン {productVersion} の\n{appName} アプリケーションについて {planName} プランのサブスクリプション作成要求が\n承認されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n{comments}\n\n{/if}\nアプリケーションの状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}} さん、こんにちは。\n\nAPI 製品 {{productName}} バージョン {{productVersion}} の\n{{appName}} アプリケーションについて {{planName}} プランの\nサブスクリプション作成要求が却下されました。\n\n{{#if comments}}\n承認者から以下のコメントがあります。\n\n"{{comments}}"\n\n{{/if}}\nアプリケーションの状況を以下のリンクで確認できます。\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{{firstName}}さん、こんにちは。\n\n{{catalog}} カタログ内の API 製品 {{productName}}:{{version}} を {{action}} する要求が\n承認されました。\n\n{{#if comments}}\n承認者から以下のコメントがあります。\n\n{{comments}}\n\n{{/if}}\n製品の状況を以下のリンクで確認できます。\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{catalog} カタログ内の API 製品 {productName}:{version} を {action} する\n要求が承認されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n{comments}\n\n{/if}\n製品の状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}} さん、こんにちは。\n\n{catalog} カタログ内の API 製品 {productName}:{version} を {action}} する要求が\n却下されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n{comments}\n\n{/if}\n製品の状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{catalog} カタログ内の API 製品 {productName}:{version} を {action} する要求が\n却下されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n{comments}\n\n{/if}\n製品の状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName} さん、こんにちは。\n\n{catalog} カタログ内の API 製品 {productName}:{version} を {action} する\n要求は承認待ちです。 要求が処理された後に、\n別の通知をお送りします。\n\n製品の状況を以下で確認できます。\n\n{link}\n\n要求をキャンセルする場合は、次のリンクを使用してください。\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{consumerOrg} 開発者ポータル内のアプリケーション {appName} を {action} する\n要求が承認されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n"{comments}"\n\n{/if}\nアプリケーションの状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{consumerOrg} 開発者ポータル内のアプリケーション {appName} を {action} する\n要求が承認されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n{comments}\n\n{/if}\nアプリケーションの状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{consumerOrg} 開発者ポータル内のアプリケーション {appName} を {action} する\n要求が却下されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n"{comments}"\n\n{/if}\nアプリケーションの状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{consumerOrg} 開発者ポータル内のアプリケーション {appName} を {action} する\n要求が却下されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n{comments}\n\n{/if}\nアプリケーションの状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `{firstName} さん、こんにちは。\n\n{consumerOrg} 開発者ポータル内の {appName} アプリケーションを {action} する\n要求は承認待ちです。 要求が処理された後に、\n別の通知をお送りします。\n\n要求をキャンセルする場合は、次のリンクを使用してください。\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\nIBM API Connect のアカウント管理者から、API プロバイダー組織を作成するように\n招待されました。 プロバイダー組織により、\nチームで API を開発し、API 製品のカタログを管理できます。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\nIBM API Connect の {org} API プロバイダー組織に属する\n{catalog} API カタログの管理者から、\nカタログ内にスペースを作成するように招待されました。 スペースにより、ユーザーとチームは\nAPI カタログ内の API 製品のセットを管理できます。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\nIBM API Connect の {org} API プロバイダー組織に属する\n{catalog} API カタログの管理者から、\nカタログに招待されました。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\n{catalog} 開発者ポータルの管理者から\nAPI コンシューマー組織を作成するように招待されました。 コンシューマー組織は、\nユーザーとチームが API にアクセスしてクライアント・アプリを登録できるようにします。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\n{catalog} 開発者ポータルの {consumerOrg} API コンシューマー組織の\n管理者から組織に招待されました。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\nIBM API Connect の {org} API プロバイダー組織の\n管理者から、API カタログを作成するように招待されました。 カタログを使用すると、\nユーザーとチームは API 製品のコレクションを公開および管理できます。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\nIBM API Connect の {org} API プロバイダー組織の\n管理者から組織に招待されました。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\nIBM API Connect の {org} API プロバイダー組織に属する\n{catalog} API カタログ内の {space} スペースの\n管理者からスペースに招待されました。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `ご連絡\n\nIBM API Connect のユーザー・アカウント {username}\nのパスワードが正常に変更されました。\n\nAPI Connect に次からログインできます:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `ご連絡\n\n{portalTitle} 開発者ポータルのユーザー・アカウント {username}\nのパスワードが変更されました。\n\n{portalTitle} 開発者ポータルに次からログインできます:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `ご連絡\n\nIBM API Connect のシステム管理者から\n{org} 組織に招待されました。\n\n続行するには、以下のリンクをご利用ください:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `ご連絡\n\nこれは、構成済みメール・サーバー {mailServer} の IBM API Connect から\n送信されたテスト・メッセージです。\n\nこのメッセージが受信できていれば、テストは成功です。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `ご連絡\n\nユーザー {originator} は、{catalog} カタログ内の {productName} API 製品のバージョン {productVersion} の\n{planName} プランへのサブスクライブ要求を撤回しました。\n\n追加のアクションは不要です。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `ご連絡\n\nユーザー {originator} は、{catalog} カタログ内のアプリ {appName} への {action} 要求を撤回しました。\n\n追加のアクションは不要です。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `ご連絡\n\nユーザー {originator} は、{catalog} カタログ内の {productName} API 製品のバージョン {version} の\n{action} 要求を撤回しました。\n\n追加のアクションは不要です。\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `ご連絡: これは、構成済みメール・サーバー {{{mailServer}}} の IBM API Connect から送信されたテスト・メッセージです。 このメッセージが受信できていれば、テストは成功です`,
  [`Hello|header`]: `ご連絡`,
  [`Help`]: `ヘルプ`,
  [`Hi, Steve (todo)`]: `Steve さん、こんにちは (todo)`,
  [`Hide`]: `非表示`,
  [`Hide field`]: `フィールドの非表示`,
  [`Hide in schema`]: `スキーマで非表示`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `新規のタイプ、フィールド、ディレクティブ、またはオプション引数を非表示にします。 バックエンドによってサポートされなくなった項目は、削除されます。`,
  [`High`]: `高`,
  [`History`]: `履歴`,
  [`Histories`]: `履歴`,
  [`History log`]: `履歴ログ`,
  [`Home`]: `ホーム`,
  [`Host`]: `ホスト`,
  [`Host context variable`]: `ホストのコンテキスト変数`,
  [`Host name`]: `ホスト名`,
  [`Host value (if present)`]: `ホストの値 (存在する場合)`,
  [`Hostname`]: `ホスト名`,
  [`Hours`]: `時間`,
  [`Http basic authentication schema`]: `HTTP 基本認証スキーマ`,
  [`Hmm, your search didn't return any results.`]: `検索結果がありませんでした。`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect クラウド`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud Authentication`,
  [`IBM Cloud Docs`]: `IBM Cloud の資料`,
  [`IBM Schema`]: `IBM スキーマ`,
  [`IBM managed`]: `IBM で管理`,
  [`ID Name`]: `ID 名`,
  [`ID token custom claims`]: `ID トークン・カスタム・クレーム`,
  [`ID token issuer`]: `ID トークン発行者`,
  [`ID token signing algorithm`]: `ID トークン署名アルゴリズム`,
  [`ID token signing crypto object`]: `ID トークン署名暗号オブジェクト`,
  [`ID token signing key`]: `ID トークン署名鍵`,
  [`ID token signing key identifier`]: `ID トークン署名鍵 ID`,
  [`IDENTITY PROVIDER`]: `ID プロバイダー`,
  [`IDENTITY PROVIDER SUPPORT`]: `ID プロバイダー・サポート`,
  [`Identification`]: `識別`,
  [`Identity extraction`]: `ID 抽出`,
  [`If`]: `If`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `実動モードが無効である (これが開発カタログである) 場合、すべての公開操作は強制的に行われ、競合があれば自動的に解決されます。これにより、テスト中に同じ製品バージョンを繰り返し再公開するなどの操作が可能になります。`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `実動モードが有効である (これが実動カタログである) 場合、公開済みの製品との競合が存在するときは製品をカタログに公開できません。必ずすべての競合を解決してから公開操作を再試行してください。`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `カタログでスペースが有効になっている場合、スペース内のメンバーを管理できます。 スペースに新規ユーザーを追加し、ユーザーにロールを割り当てることによってスペース・メンバーシップを管理します。`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `これを有効にすると、ヌル値がある入力プロパティーが出力文書にマップされます。 有効にしない場合、それらのプロパティーは出力から省略されます。`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `実動モードを有効にすると、すべてのステージング・アクションと公開アクションが強制的に実行されません。 競合が見つかった場合は、システムによって自動的に解決されます。 公開解除アクションは自動的に実行されます。`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `受信者に E メールが届いていない場合は、E メール・サーバーの設定を確認してください。`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `受信者に E メールが届いていない場合は、{username} の E メール・サーバーの設定を確認してください。`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `値を true に設定すると、Invoke ポリシーと Proxy ポリシーの target-url の照会パラメーター値にあるすべての + 文字が %2B にエンコードされます。`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `値を true に設定すると、target-url 呼び出しの変数定義によって参照されるすべての要求パラメーターは URL デコードされます。`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `値を true に設定すると、invoke ポリシーでは HTTP DELETE メソッドでペイロードが送信されます。`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `true に設定された場合は、アクティビティー・ログにあるプロパティーが、編集または削除されます。`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `true に設定された場合は、入力にあるこのプロパティーは、実行の開始時に編集または削除されます。`,
  [`If set to true, the property will be redacted or removed from the response.`]: `true に設定された場合は、応答にあるプロパティーが、編集または削除されます。`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `カタログでスペースが有効になっている場合、ユーザーにスペース内で与えるアクセス権限を管理できます。 ユーザー・ロールに割り当てる許可を指定することにより、アクセス権限を管理します。 新規スペースを作成したときに各ロールにデフォルトで割り当てられる許可は、デフォルトのスペース許可テンプレートの設定により決まります。`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `このチェック・ボックスを選択すると (デフォルトで選択されます)、親エレメントから XML の名前空間が継承されます。 明示的な名前空間をマップ・ポリシーで使用する場合は、このチェック・ボックスをクリアしてください。`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `このチェック・ボックスを選択すると (デフォルトで選択されます)、XML の名前空間は、それが最初に使用されたドキュメントに挿入されます。 すべての名前空間をルート・エレメントで定義する場合は、このチェック・ボックスをクリアします。`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `このチェック・ボックスを選択すると (デフォルトで選択されます)、マップ・ポリシーの出力に空の XML エレメントが含まれます。 マップ・ポリシーの出力に空の XML エレメントを含めたくない場合は、このチェック・ボックスをクリアしてください。`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `このチェック・ボックスを選択すると、マップされた JSON 出力の後処理が有効になります。 JSON 出力の後処理では、プロパティー値のデータ型がスキーマで定義されたデータ型と同じであることを確認するために出力スキーマが使用されます。 また、XML 入力のオブジェクト・マッピングが原因で Badgerfish JSON 構文を持つ出力プロパティー値が正規化されます。 マップされた JSON 出力を後処理しない場合は、このチェック・ボックスをクリアしてください (デフォルト)。`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `このチェック・ボックスを選択すると (デフォルトで選択されます)、マップのプロパティーで検出される API Connect 変数参照が解決されます。 マップ・ポリシー内の API Connect 変数参照を無視する場合は、このチェック・ボックスをクリアしてください。`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `このチェック・ボックスを選択すると、マップのプロパティーで検出される API Connect 変数参照が解決されます。 マップ・ポリシー内の API Connect 変数参照を無視する場合は、このチェック・ボックスをクリアしてください (デフォルトのオプション)。`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `このチェック・ボックスを選択すると (デフォルトで選択されます)、入力の全探索で配列が検出される場合、最初のエレメントのみが使用されます。 すべての配列エレメントをマップ・ポリシーで使用する場合は、このチェック・ボックスをクリアしてください。`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `このチェック・ボックスを選択すると、スキーマがブール型または数値型として構成されている XML エレメントがそのデータ型に変換されます。 選択しない場合 (デフォルト・オプション)、すべての XML エレメント値がストリングとして返されます。`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `このチェック・ボックスを選択すると、複合スキーマ・タイプ評価で循環タイプの参照が最適化された方法で処理されます。 これらのスキーマ・タイプを標準の方法で評価するには、(デフォルトで選択されている) このチェック・ボックスをクリアしてください。`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `エラーの原因が CORS サポートの不足である場合は、リンクをクリックして新しいタブでサーバーを開きます。 ブラウザーに証明書の問題が表示される場合は、それを受け入れることを選択し、ここに戻って再度テストすることができます。`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `このチェック・ボックスを選択すると、マッピングが成功しなかった場合、必要に応じてスキーマに記述したオプション・オブジェクトの任意の出力配列または子プロパティーで、子オブジェクトの必須プロパティーがデフォルト値に初期化されます。 これにより、これらのオブジェクトの必須プロパティーに対するバージョン 4 マッピングの実行方法がエミュレートされます。 このチェック・ボックスをクリアすると (デフォルト値)、正常にマッピングされたデータを含むオブジェクトのみが出力されます。`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `このチェック・ボックスが選択済み (デフォルト) の場合、所有権ユーザーが変更されると、子リソース (スペース) の所有権も新規所有者に移転します。`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `このオプションが選択されると、マップ・ポリシーにより、解析済み XML 文書として XML 出力が message.body に書き込まれます。 デフォルトでは、XML は XML ストリングとして出力されます。 他の変数への XML 出力は常に XML ストリングとして書き込まれます。`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `ステージ・アクション、公開アクション、または置換アクションのライフサイクルの承認を有効にすると、これらのアクションを自動的に実行する一部の API 作成、編集、またはテスト操作が正しく機能しない場合があります。 これらの承認を有効にしますか?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `API Connect の API 定義を使用して公開したい既存の SOAP サービスがある場合、API を作成してターゲット SOAP サービスを指定できます。 その後 API Connect により、その SOAP サービスを呼び出す API 定義が作成されます。`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `「はい。API アセンブリーを更新します」をクリックして変更を保存する場合は、この OAuth プロバイダーの基礎となる API アセンブリーも更新されます。 ただし、基礎となる API アセンブリーがカスタマイズ済みであり、それを別個に更新したい場合は、「いいえ。API アセンブリーを更新しません」をクリックしてから、API エディターで基礎となる API アセンブリーを更新します。`,
  [`If you are still having issues, upload a schema definition language file.`]: `まだ問題がある場合は、スキーマ定義言語ファイルをアップロードしてください。`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `ファイルに秘密鍵と公開鍵の両方が含まれている場合は、ステップ 1 でアップロードします`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `ファイアウォールの内側にあるゲートウェイを登録する必要がある場合は、VPN または Secure Gateway サービスを使用して接続を確立する情報について、IBM Cloud の資料を参照してください。`,
  [`Ignore`]: `無視`,
  [`Implement policy`]: `ポリシーの実装`,
  [`Implementation file is required`]: `実装ファイルが必要です`,
  [`Implicit`]: `暗黙`,
  [`Import`]: `インポート`,
  [`Import API`]: `API のインポート`,
  [`Import API|title`]: `API のインポート`,
  [`Import OpenAPI definition from asset repository`]: `資産リポジトリーから OpenAPI 定義をインポートします`,
  [`Import Product`]: `製品のインポート`,
  [`Import policy`]: `ポリシーのインポート`,
  [`Import a product from a service URL`]: `サービス URL から製品をインポートします`,
  [`Import a product from the definition file`]: `定義ファイルから製品をインポートします`,
  [`Import an existing product`]: `既存の製品をインポート`,
  [`Import an OpenAPI definition from a URL`]: `URL から OpenAPI 定義をインポートします`,
  [`Import an OpenAPI definition from a file`]: `ファイルから OpenAPI 定義をインポートします`,
  [`Import definitions`]: `定義のインポート`,
  [`Import from existing API service`]: `既存の API サービスからインポートします`,
  [`Import from file`]: `ファイルからインポート`,
  [`Importing an API definition`]: `API 定義をインポートしています`,
  [`Import|title`]: `インポート`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `必要な証明書と CA バンドルを証明書マネージャー・サービスにインポートしてください。`,
  [`In Cookie Header`]: `Cookie ヘッダー`,
  [`In WSSec Header`]: `WSSec ヘッダー`,
  [`In order to invoke the endpoints above, you need to first`]: `上記のエンドポイントを呼び出すには、最初に次の処理を行う必要があります:`,
  [`Inactive`]: `非アクティブ`,
  [`Inc`]: `増やす`,
  [`Include empty`]: `空を含める`,
  [`Include free trial days`]: `無料トライアル日数を含める`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `構成済みのゲートウェイ・サービスと互換性のない API を選択することはできません。`,
  [`Incorrect username or password`]: `ユーザー名またはパスワードが正しくありません`,
  [`Incorrect username, password, or credentials`]: `ユーザー名、パスワード、または資格情報が正しくありません`,
  [`Indicate if the invoke is the final action`]: `呼び出しが最終アクションであるかどうかを示します`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `JWT ID (jti) クレームを JWT に追加するかどうかを指定します。 選択した場合、jti クレーム値が UUID になります。`,
  [`Indicates whether the clients secret is required.`]: `クライアント秘密鍵が必須であるかどうかを指定します。`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `この XSLT 入力文書でコンテキストの現在のペイロードを使用するかどうか、または入力がないかどうかを指定します。`,
  [`Indicates whether to stop processing if client security fails.`]: `クライアント・セキュリティーが失敗した場合に処理を停止するかどうかを指定します。`,
  [`Infer from JSON schema`]: `JSON スキーマからの推定`,
  [`Infer from Sample`]: `サンプルからの推定`,
  [`Info`]: `情報`,
  [`Information context variable`]: `通知のコンテキスト変数`,
  [`Inject proxy headers`]: `プロキシー・ヘッダーの挿入`,
  [`Inline schema`]: `インライン・スキーマ`,
  [`Input`]: `入力`,
  [`Input field`]: `入力フィールド`,
  [`Input object`]: `入力オブジェクト`,
  [`Insecure Server Connections`]: `非セキュアなサーバー接続`,
  [`Install API Connect CLI & API Designer|button text`]: `API Connect CLI &amp;amp; API Designer のインストール`,
  [`Install API Connect CLI & API Designer|title`]: `API Connect CLI &amp;amp; API Designer のインストール`,
  [`Install API Connect Toolkit`]: `API Connect Toolkit のインストール`,
  [`Install the API Connect plugin`]: `API Connect プラグインをインストールします`,
  [`Instance ID`]: `インスタンス ID`,
  [`Instance name`]: `インスタンス名`,
  [`Instance owner`]: `インスタンス所有者`,
  [`Interact to expand Tile`]: `タイルを展開するための対話`,
  [`Interact to collapse Tile`]: `タイルを省略するための対話`,
  [`Interval`]: `間隔`,
  [`Interface`]: `インターフェース`,
  [`Introspect`]: `イントロスペクト`,
  [`Introspect cache type`]: `イントロスペクト・キャッシュ・タイプ`,
  [`Introspect from Server URL`]: `サーバー URL からのイントロスペクト`,
  [`Introspect from URL`]: `URL からのイントロスペクト`,
  [`Introspect URL`]: `イントロスペクト URL`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `別の URL を使用してイントロスペクトしても、GraphQL サーバー URL は変更されません。`,
  [`Introspect token`]: `イントロスペクト・トークン`,
  [`Introspection`]: `イントロスペクション`,
  [`Introspection Path`]: `イントロスペクション・パス`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `サーバーから GraphQL スキーマをイントロスペクトするか、スキーマをアップロードしてください`,
  [`Introspect the GraphQL schema using the query`]: `照会を使用した GraphQL スキーマのイントロスペクト`,
  [`Introspection URL`]: `イントロスペクション URL`,
  [`Invalid HOST URL`]: `ホスト URL が無効です`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `URL が無効です。 有効な API Manager のホスト URL を入力してください。 例: https://api-connect-host.com`,
  [`Invalid file URL`]: `ファイル URL が無効です`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `URL 形式が無効です。http:// または https:// で開始し、少なくとも 1 文字が含まれている必要があります`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `URL 形式が無効です。ホスト名のみを指定する必要があります。 つまり、syslog:// は削除できます`,
  [`Invalid emails.`]: `E メールが無効です。`,
  [`Invalid JSONata expression`]: `JSONata 式が無効です`,
  [`Invalid remove target`]: `削除のターゲットが無効です`,
  [`Invalid assumed size`]: `想定サイズが無効です`,
  [`Invalid cost target`]: `コスト・ターゲットが無効です`,
  [`Invalid list size`]: `リスト・サイズが無効です`,
  [`Invalid port value, must not be negative`]: `ポート値が無効です。負にすることはできません`,
  [`Invalid sized fields`]: `サイズ設定されたフィールドが無効です`,
  [`Invalid value, length has to be greater than 1`]: `無効な値です。長さは 1 より大きくする必要があります`,
  [`Invalid weight value`]: `重み値が無効です`,
  [`invitation`]: `招待`,
  [`Invitation`]: `招待`,
  [`Invitation timeout`]: `招待タイムアウト`,
  [`Invitation link will expire in {time}`]: `招待リンクの有効期限: **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `招待リンクの有効期限: **{seconds} 秒 ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `招待リンクの有効期限: __{value} {unit}__。`,
  [`Invitation sent to`]: `招待の送信先`,
  [`Invitation sent to {email}`]: `{email} に送信された招待`,
  [`Invitation to an API catalog in IBM API Connect`]: `IBM API Connect の API カタログへの招待`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `{catalog} 開発者ポータルの API コンシューマー組織への招待`,
  [`Invitation to an API provider organization in IBM API Connect`]: `IBM API Connect の API プロバイダー組織への招待`,
  [`Invitation to an API space in IBM API Connect`]: `IBM API Connect の API スペースへの招待`,
  [`Invitation to an admin organization in IBM API Connect`]: `IBM API Connect の管理者組織への招待`,
  [`Invitation to create an API catalog in IBM API Connect`]: `IBM API Connect で API カタログを作成するための招待`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `{catalog} 開発者ポータルで API コンシューマー組織を作成するための招待`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `IBM API Connect で API プロバイダー組織を作成するための招待`,
  [`Invitation to create an API space in IBM API Connect`]: `IBM API Connect で API スペースを作成するための招待`,
  [`Invitation ttl has been created.`]: `招待 ttl が作成されました。`,
  [`Invitation ttl has been updated.`]: `招待 ttl が更新されました。`,
  [`Invitation ttl has not been created!`]: `招待 ttl は作成されていません。`,
  [`Invitation ttl has not been updated!`]: `招待 ttl が更新されていません。`,
  [`Invite`]: `招待`,
  [`Invite catalog owner`]: `カタログ所有者の招待`,
  [`Invite consumer organization owner`]: `コンシューマー組織の所有者の招待`,
  [`Invite member`]: `メンバーの招待`,
  [`Invite organization owner`]: `組織の所有者の招待`,
  [`Invite space owner`]: `スペース所有者の招待`,
  [`Invite a new consumer organization owner`]: `新規コンシューマー組織の所有者の招待`,
  [`Invite a new member`]: `新規メンバーを招待`,
  [`Invite a new provider organization owner`]: `新規プロバイダー組織の所有者の招待`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `新規ユーザーの宛先 E メール・アドレスを入力して、カタログの管理者として登録するように招待します。 登録フォームへのリンクを含む招待 E メールがそのユーザーに送信されます`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `新規ユーザーの宛先 E メール・アドレスを入力して、スペースの管理者として登録するように招待します。 登録フォームへのリンクを含む招待 E メールがそのユーザーに送信されます。`,
  [`Invite by email`]: `E メールで招待`,
  [`Invite from User Registry`]: `ユーザー・レジストリーから招待`,
  [`Invite team member`]: `チーム・メンバーの招待`,
  [`Inviting members to consumer organizations is enabled`]: `コンシューマー組織へのメンバーの招待は有効になっています`,
  [`Invocations`]: `呼び出し`,
  [`Invoke`]: `呼び出し`,
  [`Issuer Claim`]: `Issuer クレーム`,
  [`Issuer Origin`]: `発行者のオリジン`,
  [`Issuer common name`]: `発行者の共通名`,
  [`It might influence how the API is deployed`]: `API のデプロイ方法に影響を及ぼす可能性があります`,
  [`Italian`]: `イタリア語`,
  [`Items per page`]: `項目/ページ`,
  [`Items per page:`]: `項目/ページ:`,
  [`items selected`]: `個の項目が選択されています`,
  [`item selected`]: `個の項目が選択されています`,
  [`Iterate on`]: `反復対象`,
  [`Iterate?`]: `反復?`,
  [`JSON objects describing re-usable channel parameters.`]: `再使用可能チャネル・パラメーターを記述する JSON オブジェクト。`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `API によってコンシュームおよびプロデュースされるメッセージを記述する JSON オブジェクト。`,
  [`JSON Schema URL`]: `JSON スキーマ URL`,
  [`JSON Schema preview`]: `JSON スキーマ・プレビュー`,
  [`JSON Web Token (JWT)`]: `JSON Web トークン (JWT)`,
  [`JSON has been successfully validated`]: `JSON が正常に検証されました`,
  [`JSON schema is not valid. Please correct the error.`]: `JSON スキーマが無効です。 エラーを訂正してください。`,
  [`JSON to XML`]: `JSON から XML`,
  [`JWT ID Claim`]: `JWT ID クレーム`,
  [`JWT verification JWK`]: `JWT 検証 JWK`,
  [`JWT verification crypto object`]: `JWT 検証暗号オブジェクト`,
  [`Japanese`]: `日本語`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `ジョブ・キュー`,
  [`Jump to source`]: `ソースにジャンプ`,
  [`Just now`]: `直前`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `鍵交換 /暗号化/MAC`,
  [`KEY SIZE`]: `鍵のサイズ`,
  [`Keystore`]: `鍵ストア`,
  [`keystore`]: `keystore`,
  [`Keep`]: `保持`,
  [`Keep Payload`]: `ペイロードの保持`,
  [`Key Encryption Algorithm`]: `鍵暗号化アルゴリズム`,
  [`Key Name`]: `キー名`,
  [`Key type`]: `鍵タイプ`,
  [`Key name`]: `キー名`,
  [`Keystore for the token has been updated.`]: `トークンの鍵ストアが更新されました。`,
  [`Keystore Certificates`]: `鍵ストア証明書`,
  [`Keystore/truststore`]: `鍵ストア/トラストストア`,
  [`Korean`]: `韓国語`,
  [`LABEL`]: `ラベル`,
  [`LABELS`]: `ラベル`,
  [`LAST MODIFIED`]: `最終変更`,
  [`Last state changed`]: `状態の最終変更日`,
  [`Large`]: `大`,
  [`Lax`]: `穏健`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAPグループ`,
  [`LDAP user registry`]: `LDAP ユーザー・レジストリー`,
  [`LDAP registry`]: `LDAP レジストリー`,
  [`LDAP registry name`]: `LDAP レジストリー名`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `役割の LDAP ユーザー・レジストリー {{name}} では、外部グループ・マッピングがサポートされなくなりました。 保存する前に、このユーザー・レジストリーの外部グループ・マッピングを有効にするか、役割内の外部グループ・マッピングを無効にします。`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `役割の LDAP ユーザー・レジストリー {{name}} では、外部グループ・マッピングがサポートされなくなりました。 保存する前に、外部グループ・マッピングが有効な別のユーザー・レジストリーを選択するか、役割内の外部グループ・マッピングを無効にします。`,
  [`LDAP search attribute`]: `LDAP 検索属性`,
  [`LOCATED IN`]: `場所`,
  [`Located in`]: `場所`,
  [`LTPA Key`]: `LTPA 鍵`,
  [`LTPA Key Details`]: `LTPA 鍵の詳細`,
  [`LTPA Keys`]: `LTPA 鍵`,
  [`LTPA key file`]: `LTPA 鍵ファイル`,
  [`LTPA key password`]: `LTPA 鍵パスワード`,
  [`LUR data version`]: `LUR データのバージョン`,
  [`LUR schema update date`]: `LUR スキーマの更新日`,
  [`LUR schema version`]: `LUR スキーマのバージョン`,
  [`LUR target data version`]: `LUR ターゲット・データのバージョン`,
  [`LUR target schema version`]: `LUR ターゲット・スキーマのバージョン`,
  [`LUR update date`]: `LUR の更新日`,
  [`Last name`]: `姓`,
  [`Last modified`]: `最終変更`,
  [`Launch portal`]: `ポータルの起動`,
  [`Launch IBM Automation management console`]: `IBM 自動化管理コンソールの起動`,
  [`Launch plan upgrade`]: `プラン・アップグレードの起動`,
  [`Learn more`]: `詳細情報`,
  [`Learn More`]: `詳細情報`,
  [`Legacy`]: `レガシー`,
  [`Length cannot be below {min}`]: `長さが {min} を下回ることはできません`,
  [`Length cannot exceed {max}`]: `長さが {max} を上回ることはできません`,
  [`Let's customize API Connect`]: `API Connect をカスタマイズしましょう`,
  [`Let's get started...`]: `始めましょう...`,
  [`Let's get you up and running`]: `さっそく開始しましょう`,
  [`License`]: `使用許諾条件`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `ライフサイクル`,
  [`Lifecycle approvals`]: `ライフサイクル承認`,
  [`Lifecycle actions have been updated`]: `ライフサイクル・アクションが更新されました`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `サンドボックス・カタログのライフサイクル承認が有効になっています。 自動的な「API のアクティブ化」オプションを使用するには、最初にサンドボックス・カタログ設定でライフサイクル承認を無効にする必要があります。`,
  [`Limited fields`]: `制限されたフィールド`,
  [`Limit API calls on a per key basis`]: `キー単位で API 呼び出しを制限する`,
  [`Limit fields`]: `フィールドを制限する`,
  [`Limit renegotiation`]: `再ネゴシエーションを制限`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `ロード`,
  [`Load query`]: `ロードクエリー`,
  [`Load filter`]: `フィルターのロード`,
  [`Load schema from WSDL`]: `WSDL からのスキーマのロード`,
  [`Loading`]: `ロード中`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `ローカル・ユーザー・レジストリー (LUR) は、API Connect に組み込まれたデフォルト・ユーザー・レジストリーで、ローカルに格納されます。`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `ローカル・ユーザー・レジストリーは、API Connect に組み込まれたデフォルト・ユーザー・レジストリーで、ローカルに格納されます。 API Connect のインストール中には、2 つの LUR がインストールされて構成されます。 追加の LUR を構成することもできます。`,
  [`Local user registry`]: `ローカル・ユーザー・レジストリー`,
  [`Local User Registry has been created.`]: `ローカル・ユーザー・レジストリーが作成されました。`,
  [`Location`]: `ロケーション`,
  [`Location of ciphertext to decrypt`]: `暗号化解除する暗号文の場所`,
  [`Location of plaintext information to encrypt`]: `暗号化するプレーン・テキスト情報の場所`,
  [`Log`]: `ログ`,
  [`Log in`]: `ログイン`,
  [`Log in to:`]: `ログイン先:`,
  [`Log in with a different account`]: `別のアカウントでログイン`,
  [`Log into IBM Cloud`]: `IBM Cloud へのログイン`,
  [`Log out`]: `ログアウト`,
  [`Log in using the {userRegistryType} user registry`]: `{userRegistryType} ユーザー・レジストリーを使用してログイン`,
  [`Logging in with IBM Cloud ...`]: `IBM Cloud でログイン...`,
  [`Logging out`]: `ログアウト`,
  [`Logic`]: `ロジック`,
  [`Login`]: `ログイン`,
  [`Logs`]: `ログ`,
  [`Longer, descriptive name for this policy.`]: `このポリシーの長い記述名。`,
  [`Looks like the server is experiencing an error while processing your request.`]: `要求の処理中にサーバーでエラーが発生した可能性があります。`,
  [`LoopBack`]: `ループバック`,
  [`Mail server`]: `メール・サーバー`,
  [`mail-server-test-connection`]: `メール・サーバーの接続テスト`,
  [`MEMBER`]: `メンバー`,
  [`MIME Types`]: `MIME タイプ`,
  [`Mail server has been deleted.`]: `メール・サーバーが削除されました。`,
  [`Mail server has not been deleted.`]: `メール・サーバーは削除されませんでした。`,
  [`Mailserver {arg} has been removed.`]: `メール・サーバー {arg} は削除されました。`,
  [`Make sure that the server is running and that there is network connectivity.`]: `サーバーが実行中であること、およびネットワーク接続があることを確認してください。`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `原因としては、ターゲット・サーバーで CORS がサポートされていない、サーバーにアクセスできない、信頼できない証明書が検出された、相互 SSL 認証が必要である、などが考えられます。`,
  [`This might be caused by one or more of the following:`]: `これは、以下の 1 つ以上が原因で発生した可能性があります。`,
  [`Make a call and move on`]: `呼び出して次に進む`,
  [`Make a call and wait for response`]: `呼び出して応答を待機`,
  [`Manage`]: `管理`,
  [`Manage API life cycles`]: `API のライフサイクルの管理`,
  [`Manage API product lifecycle approval permissions`]: `API 製品のライフサイクルの承認許可の管理`,
  [`Manage API product lifecycle approvals permissions`]: `API 製品のライフサイクルの承認許可の管理`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `API プロバイダー組織、ローカルおよびリモートのゲートウェイ、および関連の設定を管理します。`,
  [`Manage APIs`]: `APIの管理`,
  [`Manage Catalogs`]: `カタログの管理`,
  [`Manage Organizations`]: `組織の管理`,
  [`Manage Resources`]: `リソースの管理`,
  [`Manage Settings`]: `設定の管理`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `API Connect、HTTP、Kafka、Elasticsearch、および Syslog など、API 分析の宛先を管理します。`,
  [`Manage local and remote gateways that secure your APIs.`]: `使用する API を保護するローカルおよびリモートのゲートウェイを管理します。`,
  [`Manage catalogs`]: `カタログの管理`,
  [`Manage organizations`]: `組織の管理`,
  [`Manage resources`]: `リソースの管理`,
  [`Manage settings`]: `設定の管理`,
  [`Manage users`]: `ユーザーの管理`,
  [`Manage active APIs and consumers`]: `アクティブな API とコンシューマーを管理します`,
  [`Manage availability zones and services`]: `アベイラビリティー・ゾーンおよびサービスを管理します`,
  [`Manage policies`]: `ポリシーの管理`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `アプリケーション開発者からの製品ライフサイクル変更要求、および API サブスクリプションとアプリケーションのアップグレード要求を管理します`,
  [`Manage roles and permissions`]: `ロールおよび許可を管理します`,
  [`Manage the OAuth providers configured for API Manager`]: `API Manager 用に構成される OAuth プロバイダーを管理します`,
  [`Manage the user registries configured for API Manager`]: `API Manager 用に構成されるユーザー・レジストリーを管理します`,
  [`Manage the user registries configured for Cloud Manager`]: `Cloud Manager 用に構成されるユーザー・レジストリーを管理します`,
  [`Management`]: `管理`,
  [`Management Endpoint`]: `管理エンドポイント`,
  [`Management endpoint`]: `管理エンドポイント`,
  [`Management endpoint on the analytics service`]: `分析サービス上の管理エンドポイント`,
  [`Service endpoint configuration`]: `サービス・エンドポイント構成`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `ポータル・サービスに構成情報を送信するために管理サービスによって使用されるエンドポイント`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `ポータル・サービスとの通信時に管理サービスによって使用される TLS クライアント・プロファイル`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `管理サービスが構成情報をゲートウェイ・サービスに送信するために使用されるエンドポイント`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `ゲートウェイ・サービスとの通信時に管理サービスによって使用される TLS クライアント・プロファイル`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `ゲートウェイと管理サービスの間の内部トラフィックにクラスター内通信を使用する`,
  [`Use in-cluster communication for both ingestion and queries`]: `取り込みと照会の両方にクラスター内通信を使用する`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `取り込みにクラスター内を使用し、照会に外部を使用する、またはその逆`,
  [`Use external communication for both ingestion and queries`]: `取り込みと照会の両方に外部通信を使用する`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `分析と管理サービスの間の内部トラフィックにクラスター内通信を使用する`,
  [`Management endpoint on the gateway service`]: `ゲートウェイ・サービス上の管理エンドポイント`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `ポータルと管理サービスの間の内部トラフィックにクラスター内通信を使用する`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `管理サブシステムとポータル・サブシステムの間でクラスター内通信を使用する場合は、このスイッチを有効にします。 不明な場合は、このスイッチを無効のままにします (デフォルト)。`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `管理サブシステムとゲートウェイ・サブシステムの間でクラスター内通信を使用する場合は、このスイッチを有効にします。 不明な場合は、このスイッチを無効のままにします (デフォルト)。`,
  [`Management endpoint on the portal service`]: `ポータル・サービス上の管理エンドポイント`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `このオプションはデフォルトであり、pre-10.0.5.3 リリースからアップグレードされた分析サービスに使用されます。 OVA デプロイメントの場合、または分析サブシステムが別の環境にある場合は、使用できるオプションは「外部」のみです。 ゲートウェイと管理サービスの両方が、外部エンドポイントを使用して分析サービスと通信します。外部エンドポイントは、ご使用のプラットフォームに応じた Kubernetes Ingress または OpenShift 経路です。`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `すべてのサブシステムが同じクラスター内にある Kubernetes または OpenShift デプロイメントがある場合は、クラスター内を選択できます。 このオプションを選択すると、管理サブシステム、ゲートウェイ・サブシステム、および分析サブシステムは、外部からアクセス可能な Ingress (Kubernetes) または経路 (OpenShift) ではなく、内部サービス・エンドポイントを介して相互に通信します。`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Kubernetes または OpenShift デプロイメントがあり、一部のサブシステムが同じクラスターにインストールされている場合は、このオプションを選択できます。 このオプションを選択すると、分析通信フローごとに異なる通信方式を使用できます。 例えば、管理から分析への通信ではクラスター内を使用でき、分析へのゲートウェイでは外部を使用できます。 ご使用のゲートウェイが残りのサブシステムとは異なるクラスター内にある場合は、この構成を選択できます。`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `分析サービスの登録時にクラスター内通信を指定した場合は、ゲートウェイが API イベント・データを内部分析エンドポイントに送信できるようにすることができます。`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `ゲートウェイ・サービスから分析データを取り込むための内部エンドポイントの使用`,
  [`Endpoint configuration`]: `取り込みと照会の両方に外部通信を使用する`,
  [`Portal endpoint configuration`]: `ポータル・エンドポイントの構成`,
  [`URL used by developer portal users to access the portal website`]: `開発者ポータル・ユーザーがポータル Web サイトにアクセスするために使用する URL`,
  [`External ingestion endpoint on the analytics service`]: `アナリティクス・サービス上の外部取り込みエンドポイント`,
  [`TLS client profile for external analytics ingestion endpoint`]: `外部分析取り込みエンドポイントの TLS クライアント・プロファイル`,
  [`Internal ingestion endpoint on the analytics service`]: `アナリティクス・サービス上の内部取り込みエンドポイント`,
  [`TLS client profile for internal service endpoint`]: `内部サービス・エンドポイントの TLS クライアント・プロファイル`,
  [`Manager`]: `管理者`,
  [`Manager Request Reset Password`]: `管理者パスワードのリセット要求`,
  [`Manager Reset Password`]: `管理者パスワードのリセット`,
  [`Manager Sign In`]: `管理者サインイン`,
  [`Manages API provider organizations`]: `API プロバイダー組織を管理します`,
  [`Manages application developer communities`]: `アプリケーション開発者コミュニティーを管理します`,
  [`Manages the API product lifecycle`]: `API 製品のライフサイクルを管理します`,
  [`Manage|link`]: `管理`,
  [`Manage|permission`]: `管理`,
  [`Manage|title`]: `管理:タイトル`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `トークンの鍵ストア割り当ておよび鍵ストア履歴を管理します`,
  [`Map`]: `マップ`,
  [`Map information from OIDC provider to an APIC user`]: `IDC プロバイダーからの情報を APIC ユーザーにマップします`,
  [`Map the migration source plans to the migration target plans`]: `マイグレーション・ソースのプランをマイグレーション・ターゲットのプランにマップします`,
  [`Map the roles to groups`]: `グループへの役割のマップ`,
  [`Mapped from: `]: `マップ元: `,
  [`Marketing`]: `マーケティング`,
  [`Maximize / minimize`]: `最大化/最小化`,
  [`Maximum Consent Time to Live (seconds)`]: `同意の最大の有効期間 (秒)`,
  [`Maximum consent`]: `同意の最大`,
  [`Menu`]: `メニュー`,
  [`Member`]: `メンバー`,
  [`Member Invitation`]: `メンバーの招待`,
  [`Member Invitation has been deleted.`]: `メンバーの招待が削除されました。`,
  [`Member has been deleted.`]: `メンバーが削除されました。`,
  [`Member {name} created`]: `メンバー {name} が作成されました`,
  [`Member {title} has been created.`]: `メンバー {title} が作成されました。`,
  [`Members`]: `メンバー`,
  [`member-invitation`]: `メンバーの招待`,
  [`Member Invitations`]: `メンバーの招待`,
  [`Member Roles`]: `メンバーのロール`,
  [`Merchant ID`]: `マーチャント ID`,
  [`Merge`]: `マージ`,
  [`Merge this mapping with any others that operate on the same target array?`]: `このマッピングを、同じターゲット配列で機能している他のすべてのマッピングとマージしますか?`,
  [`Message`]: `メッセージ`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `解析用のメッセージ`,
  [`Message for resulting parsed data`]: `結果の解析済みデータ用のメッセージ`,
  [`Metadata`]: `メタデータ`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `メタデータを使用すると、アクセス・トークンの生成処理中に組み込むカスタム・メタデータを収集できます。 メタデータは、認証 URL またはカスタム・メタデータが保管されているリモート・サーバー (あるいはその両方) を使用して収集できます。`,
  [`Micro Gateway policies`]: `Micro Gateway ポリシー`,
  [`Migrate`]: `マイグレーション`,
  [`Migrate Subscriptions`]: `サブスクリプションのマイグレーション`,
  [`Migrate plans`]: `プランのマイグレーション`,
  [`Migrate subscription`]: `サブスクリプションのマイグレーション`,
  [`Migrate subscriptions`]: `サブスクリプションのマイグレーション`,
  [`Migration Target`]: `マイグレーション・ターゲット`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `「{source}」(製品) から「{target}」(製品) へのマイグレーションが実行されました`,
  [`Migration from "{source}" failed`]: `「{source}」からのマイグレーションが失敗しました`,
  [`Migration source`]: `マイグレーション・ソース`,
  [`Migration target`]: `マイグレーション・ターゲット`,
  [`Minimum role`]: `最小ロール`,
  [`Minimum output escaping rule`]: `最小出力エスケープ・ルール`,
  [`Minutes`]: `分`,
  [`Mode`]: `モード`,
  [`Modifications`]: `変更`,
  [`Modified`]: `変更済み`,
  [`More`]: `詳細を表示`,
  [`More than 20 events are waiting to be processed`]: `20 を超えるイベントが処理を待機しています`,
  [`Move down`]: `下へ移動`,
  [`Move up`]: `上へ移動`,
  [`Must be a number`]: `数値でなければなりません`,
  [`Must be a valid URI`]: `有効な URI でなければなりません`,
  [`Must be a valid email`]: `有効な E メール・アドレスを入力してください`,
  [`Must be a valid path starting with /`]: `/ で始まる有効なパスである必要があります`,
  [`Must be a valid url`]: `有効な URL を入力してください`,
  [`Must be an integer`]: `整数を入力してください`,
  [`Must be a valid url with no protocol`]: `プロトコルを含まない有効な URL でなければなりません`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `64 桁の 16 進文字の形式にしてください (先頭に「0x」を付けます)`,
  [`Must start with '/'`]: `「/」で始まる必要があります`,
  [`Must start with https://`]: `https:// で始まる必要があります`,
  [`Must start with https:// or $(`]: `https:// または $( で始まる必要があります`,
  [`Mutual auth`]: `相互認証`,
  [`Mutual authentication`]: `相互認証`,
  [`My account`]: `マイ・アカウント`,
  [`Name`]: `名前`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `該当しない`,
  [`Name cannot be empty.`]: `名前を空にすることはできません。`,
  [`Name of Schema in OpenAPI Definitions`]: `OpenAPI 定義でのスキーマの名前`,
  [`Name of the LDAP user password attribute.`]: `LDAP ユーザー・パスワード属性の名前。`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `検証対象の LDAP ユーザー・レジストリーの名前。 リストから選択するか、手動で入力します。`,
  [`Name of the organization`]: `組織の名前`,
  [`Namespace inheritance`]: `名前空間の継承`,
  [`Namespace inlining`]: `名前空間のインライン化`,
  [`Native`]: `ネイティブ`,
  [`Native OAuth provider`]: `ネイティブ OAuth プロバイダー`,
  [`Native OAuth provider summary`]: `ネイティブ OAuth プロバイダーの要約`,
  [`Navigate to Source View`]: `ソース・ビューにナビゲート`,
  [`New`]: `新規`,
  [`New API`]: `新規 API`,
  [`New API Connect connection`]: `API Connect の新規接続`,
  [`New Application`]: `新規アプリケーション`,
  [`New Catalog`]: `新規カタログ`,
  [`New Consumer organization`]: `新規コンシューマー組織`,
  [`New Endpoint`]: `新規エンドポイント`,
  [`New native OAuth provider`]: `新規のネイティブ OAuth プロバイダー`,
  [`New OpenAPI`]: `新規 OpenAPI`,
  [`New OpenAPI directory`]: `新規 OpenAPI ディレクトリー`,
  [`New Organization Title`]: `新規組織のタイトル`,
  [`New Parameter`]: `新規パラメーター`,
  [`New Password`]: `新規パスワード`,
  [`New product`]: `新規製品`,
  [`New Role`]: `新規ロール`,
  [`New schema`]: `新規スキーマ`,
  [`New Test`]: `新規テスト`,
  [`New third party OAuth provider`]: `新規のサード・パーティー OAuth プロバイダー`,
  [`New user`]: `新規ユーザー`,
  [`New applications will be created in the development state`]: `新規アプリケーションは開発状態で作成されます`,
  [`Next`]: `次へ`,
  [`Next page`]: `次のページ`,
  [`No`]: `いいえ`,
  [`No account?`]: `アカウントをお持ちでない場合`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `サンドボックス・カタログに認証 URL も LDAP ユーザー・レジストリーも構成されていません`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `サンドボックス・カタログに認証 URL も LDAP ユーザー・レジストリーも構成されていません。[こちら]({link})で作成してください`,
  [`No Billing`]: `請求なし`,
  [`No billing integration has been selected.`]: `請求統合が選択されていません。`,
  [`No billing integration can be shown when offline.`]: `オフライン時に請求統合を表示することはできません。`,
  [`No gateways`]: `ゲートウェイがありません`,
  [`No cache`]: `キャッシュなし`,
  [`No Cache`]: `キャッシュなし`,
  [`No Certificate Manager services available`]: `使用可能な証明書マネージャー・サービスがありません`,
  [`No default gateways`]: `デフォルトのゲートウェイがありません`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `ユーザー・レジストリーに重複する E メール・アドレスはありません。これは、E メールを持つユーザーにのみ該当します。`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `サンドボックス・カタログに対応するゲートウェイが構成されていません。 エクスプローラーでの API テスト (試す) は無効になります。`,
  [`No Errors`]: `エラーはありません`,
  [`No Histories found`]: `履歴が見つかりません`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `ゲートウェイ・サービスが見つかりません。 サポートされているアセンブリー・ポリシーを表示するようにゲートウェイ・サービスを構成してください`,
  [`No gateways are configured for sandbox catalog`]: `サンドボックス・カタログに対してゲートウェイが構成されていません`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `サンドボックス・カタログ用のゲートウェイが構成されていません。 エクスプローラーでの API テスト (試す) は無効になります`,
  [`No LDAP or authentication URL user registries found.`]: `LDAP ユーザー・レジストリーも認証 URL ユーザー・レジストリーも見つかりません。`,
  [`No limit on size of list`]: `リストのサイズに制限が無い`,
  [`No OAuth providers configured in the sandbox catalog`]: `サンドボックス・カタログに OAuth プロバイダーが構成されていません`,
  [`No OAuth providers configured in the selected catalog`]: `選択されたカタログに OAuth プロバイダーが構成されていません`,
  [`No Permission`]: `権限がありません`,
  [`No portal added to this catalog`]: `このカタログに追加されたポータルはありません`,
  [`No Roles exist`]: `ロールがありません`,
  [`No recent items found.`]: `最近の項目が見つかりません。`,
  [`No TLS profile`]: `TLS プロファイルなし`,
  [`No TLS client profile`]: `TLS クライアント・プロファイルがありません`,
  [`No Truststore`]: `トラストストアなし`,
  [`No analytics service is configured`]: `分析サービスは構成されていません`,
  [`No analytics services found. You can create one [here]({link})`]: `分析サービスが見つかりませんでした。 [こちら]({link}) で作成できます`,
  [`No assemblies found.`]: `アセンブリーが見つかりません。`,
  [`No available extensions`]: `使用可能な拡張がありません`,
  [`No compatible flows configured in this OAuth provider`]: `この OAuth プロバイダーに適合するフローは構成されていません`,
  [`No consumer organization will be able to see this product.`]: `この製品を表示できるようになるコンシューマー組織はありません。`,
  [`No debug data was found for this API call`]: `この API 呼び出しのデバッグ・データが見つかりませんでした`,
  [`No details are available.`]: `使用可能な詳細はありません。`,
  [`No existing products`]: `既存の製品はありません`,
  [`No extensions have been configured for the API`]: `API に対して構成された拡張はありません`,
  [`No flow configured in this OAuth provider`]: `この OAuth プロバイダーにフローは構成されていません`,
  [`No items found`]: `項目が見つかりません`,
  [`No non-member user to add.`]: `追加する非メンバー・ユーザー ID はありません。`,
  [`No organization found`]: `組織が見つかりません`,
  [`No portal service exists:`]: `ポータル・サービスは存在しません:`,
  [`No product APIs`]: `製品 API がありません`,
  [`No products or plans were found.`]: `製品もプランも見つかりません。`,
  [`No provider organizations`]: `プロバイダー組織がありません`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `応答を受信しませんでした。 原因としては、ターゲット・サーバーで CORS がサポートされていない、サーバーにアクセスできない、信頼できない証明書が検出された、相互 SSL 認証が必要である、などがあります。`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `使用できるリソース・グループがありません。 開始するには、IBM Cloud Account リソースでリソース・グループを作成してください。`,
  [`No security`]: `セキュリティーなし`,
  [`No services found`]: `サービスが見つかりません`,
  [`No warnings`]: `警告なし`,
  [`No warnings.`]: `警告はありません。`,
  [`No. Do not update API Assembly`]: `いいえ。API アセンブリーを更新しません`,
  [`Non-blocking`]: `非ブロッキング`,
  [`Non int slicing arguments`]: `非 int 型スライス引数`,
  [`None`]: `なし`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `構成されている OAuth プロバイダーがこの API のゲートウェイ・タイプと一致しません`,
  [`Normal`]: `正常`,
  [`Not Available`]: `使用不可`,
  [`Not applicable for application grant type.`]: `アプリケーションの権限付与タイプには適用されません。`,
  [`Not valid before`]: `予定有効日時`,
  [`Not valid after`]: `これより後は無効`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `注:「自動製品の生成」オプションが選択されている場合、このオプションは無効になります。`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `注:「自動製品の生成」オプションが選択されているときは、このオプションは無効になり、代わりに生成された製品のデフォルト・プランが使用されます`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `注: 「ゲートウェイ・サービス」設定は、他のすべての設定の有効範囲として使用されます。 ポリシーおよびその他のゲートウェイ・サービスに固有のコンテンツは、この設定に基づいてロードされます。`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `注: このオプションは、通知目的でのみ使用され、上記のテスト・アプリケーション・オプションに基づいて更新されます。`,
  [`Notices`]: `特記事項`,
  [`Notification template`]: `通知テンプレート`,
  [`Notification templates`]: `通知テンプレート`,
  [`Notification server configured`]: `通知サーバーが構成されました`,
  [`Notifications`]: `通知`,
  [`Null Badgerfish`]: `ヌルの Badgerfish`,
  [`Null`]: `ヌル`,
  [`Number`]: `数値`,
  [`Number of Operations`]: `操作の数`,
  [`Numeric`]: `数字`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth プロバイダー`,
  [`OAuth provider`]: `OAuth プロバイダー`,
  [`OAuth Provider Policy Assembly`]: `OAuth プロバイダー・ポリシー・アセンブリー`,
  [`OAuth providers`]: `OAuth プロバイダー`,
  [`OAuth Redirect URL`]: `OAuth リダイレクト URL`,
  [`OAuth shared secret`]: `OAuth 共有秘密鍵`,
  [`OAuth provider policy assembly`]: `OAuth プロバイダー・ポリシー・アセンブリー`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `OAuth プロバイダー {currentProviderName} が見つかりませんでした。 構成済みの OAuth プロバイダーを選択してください。`,
  [`OAuth redirect URL`]: `OAuth リダイレクト URL`,
  [`OAuth Shared Secret`]: `OAuth 共有秘密鍵`,
  [`OAuth Signature Method`]: `OAuth 署名方式`,
  [`OAuth signature method`]: `OAuth 署名方式`,
  [`OAuth providers can be created and managed in the following list.`]: `以下のリストで、OAuth プロバイダーの作成や管理を行うことができます。`,
  [`OAuth symmetric key name`]: `OAuth 対称鍵の名前`,
  [`OAuth symmetric key value`]: `OAuth 対称鍵の値`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2 アクセス・コード・セキュリティー`,
  [`OAuth2 Application Security`]: `OAuth2 アプリケーション・セキュリティー`,
  [`OAuth2 Implicit Security`]: `OAuth2 暗黙セキュリティー`,
  [`OAuth2 Password Security`]: `OAuth2 パスワード・セキュリティー`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `操作 ID`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `順序`,
  [`ORGANIZATION`]: `組織`,
  [`ORGANIZATION MANAGER`]: `組織管理者`,
  [`Organizations`]: `組織`,
  [`Owner`]: `所有者`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Oauth プロバイダーは、スペースが有効な場合は各スペース内で管理されます。 スペース設定にアクセスして OAuth プロバイダーを管理してください。`,
  [`Off`]: `オフ`,
  [`Offline`]: `オフライン`,
  [`Old schema`]: `旧スキーマ`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch を使用すると、プロバイダー組織またはカタログ内で API、製品、アプリケーション、サブスクリプション、およびその他のリソースを検索できます。 また、これらのリソース間の関係を検出するのにも役立ちます。`,
  [`Omnisearch for all supported list pages`]: `すべてのサポートされるリスト・ページの Omnisearch`,
  [`Omnisearch for draft APIs and products`]: `ドラフト API および製品の Omnisearch`,
  [`Omnisearch for published products in catalogs and spaces`]: `カタログおよびスペース内の公開済み製品の Omnisearch`,
  [`Omnisearch for consumer organizations`]: `コンシューマー組織の Omnisearch`,
  [`Omnisearch for applications`]: `アプリケーションの Omnisearch`,
  [`Omnisearch for requested approvals`]: `承認の要求の Omnisearch`,
  [`Omnisearch for approvals tasks`]: `承認タスクの Omnisearch`,
  [`Omnisearch for product apis`]: `製品 API の Omnisearch`,
  [`Omnisearch for subscriptions`]: `サブスクリプションの Omnisearch`,
  [`Omnisearch for members`]: `メンバーの Omnisearch`,
  [`Omnisearch for provider organizations`]: `プロバイダー組織の Omnisearch`,
  [`Disable the new editor for OpenAPI2`]: `OpenAPI2 の新規エディターを無効にする`,
  [`On`]: `オン`,
  [`Onboarding`]: `オンボーディング`,
  [`Onboarding Edit Admin Org`]: `オンボーディングの管理者組織の編集`,
  [`Onboarding Edit Cloud Org`]: `オンボーディングのクラウド組織の編集`,
  [`Onboarding Edit Reset Password`]: `オンボーディングのパスワードのリセットの編集`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `インストール後、IBM Cloud CLI を使用して、API Connect をプロビジョンしたアカウントと地域をターゲットにしてから、すべての API Connect プロバイダー組織をリストすることによって CLI を実際に使ってみます`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `API によって消費および生成されるスキーマを記述する、1 つ以上の JSON オブジェクト。`,
  [`One or more JSON representations for parameters`]: `パラメーターの 1 つ以上の JSON 表記`,
  [`One time use access token`]: `ワンタイム使用のアクセス・トークン`,
  [`One time use refresh token`]: `ワンタイム使用のリフレッシュ・トークン`,
  [`Online`]: `オンライン`,
  [`Only one API file should be present in the zip file.`]: `zip ファイルには API ファイルが 1 つだけ存在する必要があります。`,
  [`OpenAPI Specification Version`]: `OpenAPI 仕様バージョン`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) は、OAuth 2.0 に基づく追加の認証プロトコルを提供します。 OIDC は、JSON Web トークンすなわち JWT でエンコードされたユーザー情報を提供します。 OpenID Connect を有効にすると、アクセス・トークンと共に、ID トークンを生成するためのテンプレートが提供され、必要なアセンブリー・ポリシーが自動的に作成されます。`,
  [`Open a Directory`]: `ディレクトリーを開く`,
  [`Open in external editor`]: `外部エディターで開く`,
  [`Open the server`]: `サーバーを開く`,
  [`OpenAPI extensions`]: `OpenAPI 拡張`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `ゲートウェイ・バージョンが 6000 の場合は、まだ OpenID Connect がサポートされません`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `OpenID Connect 設定を適用できるのは、選択した権限付与タイプに「暗黙」および「アクセス・コード」のうちの 1 つ以上が含まれている場合に限られます。`,
  [`Operation`]: `操作`,
  [`Operation Id`]: `操作 ID`,
  [`Operation switch`]: `操作切り替え`,
  [`Operation Switch`]: `操作切り替え`,
  [`Operations`]: `操作`,
  [`Operator build date`]: `オペレーターの作成日`,
  [`Operator build tag`]: `オペレーターの作成タグ`,
  [`Operator version`]: `オペレーター・バージョン`,
  [`Optionally select a CA bundle`]: `オプションで、CA バンドルを選択します`,
  [`Optimize schema definition`]: `スキーマ定義の最適化`,
  [`Optional`]: `オプション`,
  [`Or`]: `または`,
  [`Or create a new application and subscribe to the selected plan`]: `または、新規アプリケーションを作成し、選択したプランをサブスクライブ`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `または、新しい製品を作成し、サンドボックス・カタログに公開`,
  [`Order`]: `順序`,
  [`Org`]: `組織`,
  [`Org owner`]: `組織所有者`,
  [`Organization`]: `組織`,
  [`Organization:`]: `組織:`,
  [`Organization information`]: `組織情報`,
  [`Organization Manager`]: `組織管理者`,
  [`Organization has been updated.`]: `組織が更新されました。`,
  [`Organizations will be listed here.`]: `ここに組織がリストされます。`,
  [`Otherwise`]: `それ以外の場合`,
  [`Output`]: `出力`,
  [`Output Claims`]: `出力クレーム`,
  [`Output array`]: `出力配列`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `XML 文書を整形式にする必要がない場合であっても、ルート・エレメントを出力します。`,
  [`Output variable`]: `出力変数`,
  [`Override API consume types`]: `API コンシューム・タイプのオーバーライド`,
  [`Override API produce types`]: `API 生成タイプのオーバーライド`,
  [`Override API security definitions`]: `API セキュリティー定義のオーバーライド`,
  [`Override rate limits`]: `レート制限のオーバーライド`,
  [`Override default "provider" settings with configuration from this URL`]: `デフォルトの「provider」設定を、この URL からの構成でオーバーライドする`,
  [`Override default "provider" settings with configuration from this literal`]: `デフォルトの「provider」設定を、このリテラルからの構成でオーバーライドする`,
  [`Override plan rate limits`]: `プランのレート制限をオーバーライドする`,
  [`Override plan rate limits for individual operation`]: `各操作のプラン・レート制限をオーバーライドする`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `カタログ招待タイムアウトによるコンシューマー組織招待タイムアウトのオーバーライド`,
  [`Overview`]: `概要`,
  [`Owner scope check`]: `所有者スコープ検査`,
  [`Owner's Email`]: `所有者の E メール`,
  [`Owner's Name`]: `所有者の名前`,
  [`Owner's email`]: `所有者の E メール`,
  [`Owns and administers the API provider organization`]: `API プロバイダー組織を所有し、管理します`,
  [`Owns and administers the admin organization`]: `管理者組織を所有し、管理します`,
  [`Owns and administers the app developer organization`]: `アプリケーション開発者組織を所有し、管理します`,
  [`Owns and administrates the app developer organization`]: `アプリケーション開発者組織を所有し、管理します`,
  [`PARAMETER NAME`]: `パラメーター名`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `Audience (aud) クレームを検証するために使用する PCRE。`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `Issuer (iss) クレームを検証するために使用する PCRE。`,
  [`PER`]: `単位時間`,
  [`Per`]: `単位`,
  [`Plan`]: `プラン`,
  [`Plan:`]: `プラン:`,
  [`pending Consumer organization invitation`]: `保留中のコンシューマー組織の招待`,
  [`PLAN`]: `プラン`,
  [`PLANS`]: `プラン`,
  [`Policy`]: `ポリシー`,
  [`policy`]: `ポリシー`,
  [`POLICIES`]: `ポリシー`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `製品`,
  [`Product Plan`]: `製品プラン`,
  [`Product Plans`]: `製品プラン`,
  [`Product Plans per page`]: `ページあたりの製品プラン数`,
  [`Property name`]: `プロパティー名`,
  [`Property type`]: `プロパティー・タイプ`,
  [`Property example`]: `プロパティーの例`,
  [`Property description`]: `プロパティーの説明`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `ページが見つかりません`,
  [`Parameter name`]: `パラメーター名`,
  [`Parameter control`]: `パラメーター制御`,
  [`Parameters`]: `パラメーター`,
  [`Parent`]: `親`,
  [`Parse`]: `解析`,
  [`Parse settings URL reference`]: `解析設定 URL 参照`,
  [`Parse settings literal configuration`]: `解析設定リテラル構成`,
  [`Parse settings object reference`]: `解析設定オブジェクト参照`,
  [`Parse the GraphQL query`]: `GraphQL 照会を解析`,
  [`Parse the response from the GraphQL backend server`]: `GraphQL バックエンド・サーバーからの応答を解析`,
  [`Parse XML output`]: `XML 出力の解析`,
  [`Pass-through`]: `パススルー`,
  [`Password`]: `パスワード`,
  [`password-changed`]: `パスワード変更`,
  [`Password changed for your user account in IBM API Connect`]: `IBM API Connect でユーザー・アカウントのパスワードが変更されました`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `{portalTitle} 開発者ポータルでユーザー・アカウントのパスワードが変更されました`,
  [`Password context variable`]: `パスワードのコンテキスト変数`,
  [`Password is invalid. Re-enter the password.`]: `パスワードが無効です。 パスワードを再入力してください。`,
  [`password-reset`]: `パスワードのリセット`,
  [`Password reset request for your user account in IBM API Connect`]: `IBM API Connect のユーザー・アカウントに対するパスワード再設定要求`,
  [`Password reset request for your {{catalog}} developer portal account`]: `{catalog} 開発者ポータル・アカウントのパスワード再設定要求`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `パスワードには、大文字、小文字、数字、記号 (!、$、#、% など) のうちの 2 種類を使用して、それぞれ 1 文字以上を指定する必要があります`,
  [`Passwords must contain eight or more characters`]: `パスワードは 8 文字以上で指定する必要があります`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `パスワードは 8 文字以上で指定し、大文字、小文字、数字、記号 (!、$、#、% など) のうちの 2 種類を使用して、それぞれ 1 文字以上を指定する必要があります。 また、同じ文字を 3 回以上続けて使用することはできません。`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `パスワードには同じ文字を 3 回以上続けることはできません。`,
  [`Passwords should match`]: `パスワードは一致する必要があります`,
  [`Path`]: `パス`,
  [`Path parameters`]: `パス・パラメーター`,
  [`Path name`]: `パス名`,
  [`Paths`]: `パス`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `パスにより、API で公開されている REST リソースが特定されます。 操作では、パスを要求および応答の HTTP 動詞、パラメーター、および定義と組み合わせます。`,
  [`Payments Authorization`]: `支払許可`,
  [`Payment method`]: `支払方法`,
  [`Pending`]: `保留`,
  [`Pending State`]: `保留状態`,
  [`Pending approval to be published in catalog`]: `カタログに公開されるための承認が保留されています`,
  [`Pending approval to be staged in catalog`]: `カタログにステージングされるための承認が保留されています`,
  [`Pending consumer organization invitations`]: `保留中のコンシューマー組織の招待`,
  [`View endpoints`]: `エンドポイントの表示`,
  [`View base endpoints for this API`]: `この API の基本エンドポイントの表示`,
  [`View pending invitations`]: `保留中の招待を表示`,
  [`Permission Denied`]: `許可が拒否されました`,
  [`Permissions`]: `許可`,
  [`Persistent Connection`]: `持続接続`,
  [`Pin menu`]: `メニューのピン留め`,
  [`PlainText`]: `プレーン・テキスト`,
  [`Plan APIs`]: `プラン API`,
  [`Plan burst limits`]: `プランのバースト制限`,
  [`Plan by name`]: `名前順のプラン`,
  [`Plan Default`]: `デフォルトのプラン`,
  [`Plan Details`]: `プランの詳細`,
  [`Plan migration targets`]: `マイグレーション・ターゲットの計画`,
  [`Plan rate limits`]: `プランのレート制限`,
  [`Plan name`]: `プラン名`,
  [`Plan pricing`]: `プラン価格設定`,
  [`Plan title already exists`]: `プランのタイトルは既に存在しています`,
  [`Plans`]: `プラン`,
  [`Platform REST API endpoint for admin and provider APIs`]: `管理 API およびプロバイダー API 用のプラットフォーム REST API エンドポイント`,
  [`Platform REST API endpoint for consumer APIs`]: `コンシューマー API 用のプラットフォーム REST API エンドポイント`,
  [`Please choose a bind method`]: `バインド方式を選択してください`,
  [`Please choose an authentication method`]: `認証方式を選択してください`,
  [`Please contact the system administrator.`]: `システム管理者にお問い合わせください。`,
  [`Please contact your administrator for support.`]: `管理者に連絡してサポートを依頼してください。`,
  [`Please enter the reason.`]: `理由を入力してください。`,
  [`Please enter your username`]: `ユーザー名を入力してください`,
  [`Please go to the space settings to manage API endpoints.`]: `スペース設定にアクセスして API エンドポイントを管理してください。`,
  [`Please make sure the server is online and try again.`]: `サーバーがオンラインであることを確認し、再試行してください。`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `アクティビティー・ログを無効にすると、成功またはエラーの場合に記録されるコンテンツのタイプに関連したプロパティーが削除されるので注意してください。 無効にしますか?`,
  [`Please review the license for API Connect by accessing`]: `以下にアクセスして、API Connect のライセンスを確認してください。`,
  [`Please select a TLS`]: `TLS を選択してください`,
  [`Please select a TLS client profile`]: `TLS クライアント・プロファイルを選択してください`,
  [`Please select a group type`]: `グループ・タイプを選択してください`,
  [`Please select an LDAP protocol version`]: `LDAP プロトコル・バージョンを選択してください`,
  [`Please select authentication method`]: `認証方式を選択してください`,
  [`Please select ciphers from the list below:`]: `次のリストから暗号を選択してください。`,
  [`Please select consumer organization`]: `コンシューマー組織を選択してください`,
  [`Please select one Keystore from the list below:`]: `次のリストから鍵ストアを 1 つ選択してください。`,
  [`Please select one keystore from the list below:`]: `次のリストから鍵ストアを 1 つ選択してください:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `この新しいアプリケーションのクライアント ID と秘密鍵をメモしておいてください。 シークレットをもう一度取得することはできないため、メモしたシークレットは安全な場所に保管してください。ただし、必要な場合は、シークレットをリセットすることができます。`,
  [`Please try again in a few minutes.`]: `数分後にもう一度実行してください。`,
  [`Please refresh catalog/space product list page and try again.`]: `カタログ/スペース製品リスト・ページをリフレッシュして、再試行してください。`,
  [`Policies`]: `ポリシー`,
  [`Policy Assemblies`]: `ポリシー・アセンブリー`,
  [`Policy assembly`]: `ポリシー・アセンブリー`,
  [`Policy description.`]: `ポリシーの説明。`,
  [`Policy title`]: `ポリシーのタイトル`,
  [`Policy has been uploaded.`]: `ポリシーがアップロードされました。`,
  [`Polish`]: `ポーランド語`,
  [`Port`]: `ポート`,
  [`Portal`]: `ポータル`,
  [`Portal details`]: `ポータルの詳細`,
  [`Portal Director TLS client profile`]: `ポータル・ディレクター TLS クライアント・プロファイル`,
  [`Portal Director keystore`]: `ポータル・ディレクター鍵ストア`,
  [`Portal Director truststore`]: `ポータル・ディレクター・トラストストア`,
  [`Portal service`]: `ポータル・サービス`,
  [`Portal Service`]: `ポータル・サービス`,
  [`Portal service name`]: `ポータル・サービス名`,
  [`Portal service details`]: `ポータル・サービスの詳細`,
  [`Portal Service and URL`]: `ポータル・サービスおよび URL`,
  [`Portal service {name} was successfully created.`]: `ポータル・サービス {name} が正常に作成されました。`,
  [`Portal service {name} was successfully updated.`]: `ポータル・サービス {name} が正常に更新されました。`,
  [`Portal Service {arg} has been removed.`]: `ポータル・サービス {arg} は削除されました。`,
  [`Portal site`]: `ポータル・サイト`,
  [`Portal URL`]: `ポータル URL`,
  [`Portal site URLs cannot be greater than 200 characters`]: `ポータル・サイトURLは200文字を超えてはなりません`,
  [`Portal website URL`]: `ポータル Web サイトの URL`,
  [`Portal and Gateway Settings`]: `ポータル設定およびゲートウェイ設定`,
  [`Portal has been created`]: `ポータルが作成されました`,
  [`Portal has been created.`]: `ポータルが作成されました。`,
  [`Portal has been deleted`]: `ポータルが削除されました`,
  [`Portals`]: `ポータル`,
  [`Portuguese`]: `ポルトガル語`,
  [`Post Response`]: `Post-Response`,
  [`Post-Invoke Hook`]: `呼び出し後フック`,
  [`Preserve subscriptions on re-publish`]: `再公開時にサブスクリプションを保持`,
  [`Preserve subscriptions on re-publish product`]: `製品の再公開時にサブスクリプションを保持`,
  [`Pre-Invoke Hook`]: `呼び出し前フック`,
  [`Pre-Request`]: `Pre-Request`,
  [`Preference`]: `設定`,
  [`Prefix`]: `接頭部`,
  [`Preparing API`]: `API の準備中`,
  [`Preserved request header`]: `要求ヘッダーが保存されました`,
  [`preserved request header`]: `要求ヘッダーが保存されました`,
  [`Preserved response header`]: `応答ヘッダーが保存されました`,
  [`preserved response header`]: `応答ヘッダーが保存されました`,
  [`Preview`]: `プレビュー`,
  [`Preview of login screen registry prompt`]: `ログイン画面のレジストリー・プロンプトのプレビュー`,
  [`Previous`]: `前へ`,
  [`Previous page`]: `前のページ`,
  [`Prettify`]: `Prettify`,
  [`Price per month`]: `月額`,
  [`Private`]: `非公開`,
  [`Private Claims`]: `プライベート・クレーム`,
  [`Private key & public key`]: `秘密鍵と公開鍵`,
  [`Private Key or Shared Secret for Decrypt`]: `暗号化解除する秘密鍵または共有秘密鍵`,
  [`Private Key or Shared Secret for Sign`]: `署名する秘密鍵または共有秘密鍵`,
  [`Private key detected`]: `秘密鍵が検出されました`,
  [`Private key password`]: `秘密鍵のパスワード`,
  [`Processed`]: `処理済み`,
  [`Problem`]: `問題`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `トレース・データのフェッチ中に問題が発生しました。 再試行してください。 エラーの詳細については、コンソールを参照してください。`,
  [`Produced Media Types`]: `生成されたメディア・タイプ`,
  [`Produces`]: `生成`,
  [`Product`]: `製品`,
  [`Product:`]: `製品:`,
  [`Product / Plan`]: `製品/プラン`,
  [`Product APIs`]: `製品 API`,
  [`Product editor`]: `製品エディター`,
  [`Product lifecycle`]: `製品ライフサイクル`,
  [`Product Lifecycle`]: `製品ライフサイクル`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `製品名の末尾を「-auto-product」にすることはできません。 名前を変更して、インポートを再試行してください。`,
  [`Product setup`]: `製品のセットアップ`,
  [`Product details`]: `製品詳細`,
  [`Product documentation`]: `製品資料`,
  [`Product has been created.`]: `製品が作成されました`,
  [`Product has been deleted`]: `製品が削除されました`,
  [`Product has been updated`]: `製品が更新されました`,
  [`Product has been updated.`]: `製品が更新されました。`,
  [`Product has not been created!`]: `製品は作成されていません。`,
  [`Product has not been published!`]: `製品は公開されていません。`,
  [`Product has not been updated!`]: `製品は更新されていません。`,
  [`Product has not been staged!`]: `製品はステージングされていません。`,
  [`Product information`]: `製品情報`,
  [`Product summary details`]: `製品要約の詳細`,
  [`Product version`]: `製品のバージョン`,
  [`Product visibility`]: `製品の可視性`,
  [`Product-Approval`]: `製品の承認`,
  [`Product-Drafts`]: `製品-ドラフト`,
  [`Product/plan`]: `製品/プラン`,
  [`Production`]: `実動`,
  [`Production mode`]: `実動モード`,
  [`Production endpoints`]: `実動エンドポイント`,
  [`Products`]: `製品`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `アプリケーション開発者が製品を使用できるようにするには、製品をカタログにステージングしてから公開する必要があります。 カタログは、ゲートウェイおよび開発者ポータルの論理区画として動作します。 API 呼び出し用の URL と開発者ポータルは、特定のカタログに固有です。 標準的な構成では、API プロバイダー組織は、開発中の API をテストするための開発カタログと、完全な使用の準備ができた API をホストするための実動カタログを使用します。`,
  [`Profile`]: `プロファイル`,
  [`Profile rule`]: `プロファイル・ルール`,
  [`Promote to production`]: `実動にレベル上げ`,
  [`Proof Key for Code Exchange`]: `Proof Key for Code Exchange`,
  [`Proof Key for Code Exchange (PKCE)`]: `Proof Key for Code Exchange (PKCE)`,
  [`Properties`]: `プロパティー`,
  [`Property`]: `プロパティー`,
  [`Property Description`]: `プロパティーの説明`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `以下で静的な値を入力することにより、その値をプロパティー {{target}} に割り当てることができます。`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `プロパティー {{target}} は現在、{{from}} にマップされています。このマッピングは、以下で削除できます。`,
  [`Property Value is Base64 Encoded`]: `プロパティー値は Base64 でエンコードされています`,
  [`Protocol`]: `プロトコル`,
  [`Protocols`]: `プロトコル`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `API を呼び出すために使用できるプロトコル (ゲートウェイが強制される API では https のみがサポートされます)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `ユーザーを認証し、OpenID プロバイダーの API にアクセスするための OAuth {oauthVer} 資格情報を指定します`,
  [`Provide a schema`]: `スキーマの指定`,
  [`Provide GraphQL schema`]: `GraphQL スキーマの指定`,
  [`Provide the URL to the backend GraphQL server`]: `バックエンド GraphQL サーバーの URL を指定`,
  [`Provide your email address to receive password reset instructions`]: `パスワードのリセット手順を受け取る E メール・アドレスを指定してください`,
  [`Provide your new password below`]: `新規パスワードを以下に入力してください`,
  [`Provider endpoint`]: `プロバイダー・エンドポイント`,
  [`Provider Information`]: `プロバイダー情報`,
  [`Provider Organization`]: `プロバイダー組織`,
  [`Provider Organizations`]: `プロバイダー組織`,
  [`Provider Type`]: `プロバイダー・タイプ`,
  [`Provider organizations`]: `プロバイダー組織`,
  [`Provider type`]: `プロバイダー・タイプ`,
  [`Provider org invite failed`]: `プロバイダー組織の招待に失敗しました`,
  [`Provider organization {name} created`]: `プロバイダー組織 {name} が作成されました`,
  [`Provider organizations will be listed here`]: `ここにプロバイダー組織がリストされます`,
  [`Provider-Org`]: `プロバイダー組織`,
  [`Provides contact information for the exposed API.`]: `公開された API の連絡先情報を提供します。`,
  [`Provides license information for the exposed API.`]: `公開された API のライセンス情報を提供します。`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `API に関するメタデータを提供します。 メタデータは必要に応じてクライアントで使用できます。`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `開発者ポータルのプロビジョニングが開始されました。 完了までに数分かかります。 完了すると、ポータル管理者アカウントのパスワードを設定するためのリンクが記載された E メールが送信されます。`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `開発者ポータル URL の更新が開始されました。 このプロセスが完了するまでに数分かかります。`,
  [`Proxy`]: `プロキシー`,
  [`Proxy URL`]: `プロキシー URL`,
  [`Public`]: `公開`,
  [`Public Key or Shared Secret for Encrypt`]: `暗号化のための公開鍵または共有秘密鍵`,
  [`Publish`]: `公開`,
  [`Publish API`]: `API の公開`,
  [`Publish a product from the Develop section.`]: `開発セクションから製品を公開します。`,
  [`Publish product`]: `製品の公開`,
  [`Publish to`]: `公開先:`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `製品へのサブスクリプションを作成するために、このカタログにいくつかの製品を公開します。`,
  [`Publish target`]: `公開ターゲット`,
  [`Publish this API to the Sandbox catalog.`]: `この API をサンドボックス・カタログに公開します。`,
  [`Publish to specific gateway services`]: `特定のゲートウェイ・サービスに公開する`,
  [`Publish using a new product`]: `新規製品を使用して公開します`,
  [`Publish using an existing product`]: `既存の製品を使用して公開します`,
  [`Publishable Key`]: `公開鍵`,
  [`Publishable key`]: `公開鍵`,
  [`Publishable key tip`]: `公開鍵のヒント`,
  [`Published`]: `公開済み`,
  [`Published product`]: `製品が公開されました`,
  [`Publishing`]: `公開中`,
  [`Publishing New API`]: `新規 APIの公開中`,
  [`Publish (preserve subscriptions)`]: `公開 (サブスクリプションを保持)`,
  [`Publish|permission`]: `公開`,
  [`Put online`]: `オンラインにする`,
  [`Query`]: `照会`,
  [`Queryparam Encode`]: `Queryparam のエンコード`,
  [`Queued`]: `キューに登録済み`,
  [`Queued events: **{queued}**`]: `キューに入れられたイベント: **{queued}**`,
  [`Retry blocked jobs`]: `ブロックされたジョブの再試行`,
  [`REQUIRED`]: `必須`,
  [`ROLES`]: `ロール`,
  [`Rate limit`]: `レート制限`,
  [`Rate Limit`]: `速度制限`,
  [`Rate Limit Name`]: `レート制限名`,
  [`Rate limits`]: `レート制限`,
  [`Rate limit name can't be a duplicate`]: `レート制限名は重複していてはなりません`,
  [`Rate limit name can't be empty`]: `レート制限名を空にすることはできません`,
  [`Rate limit overrides`]: `レート制限のオーバーライド`,
  [`Rate limit time interval can't be 0`]: `レート制限の時間間隔を 0 にすることはできません`,
  [`Rate limit time interval can't be empty`]: `レート制限の時間間隔を空にすることはできません`,
  [`Rate limit time interval unit must be selected`]: `レート制限の時間間隔の単位を選択する必要があります`,
  [`Rate limit value can't be empty`]: `レート制限値を空にすることはできません`,
  [`Rate limit value can't be negative`]: `レート制限値を負にすることはできません`,
  [`Re-stage`]: `再ステージング`,
  [`Re-stage product`]: `製品の再ステージング`,
  [`Read only`]: `読み取り専用`,
  [`Read-Only`]: `読み取り専用`,
  [`Realized`]: `実現済み`,
  [`Reason`]: `理由`,
  [`Reason for declining`]: `同意しない理由`,
  [`Recommendations`]: `推奨`,
  [`Recommended configuration`]: `推奨される構成`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `後で使用するために、以下のクライアント ID と秘密鍵を記録してください。 クライアント秘密鍵を後で表示することはできません。`,
  [`Recommendations applied`]: `推奨が適用されました`,
  [`Redact`]: `編集`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `要求の本文、応答の本文、およびアクティビティー・ログから、指定したフィールドを編集または削除します。 XPath 式を指定して、編集または削除するフィールドを定義します。`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `要求の本文、応答の本文、およびアクティビティー・ログから、指定したフィールドを編集または削除します。 XPath 式または JSONata 式を指定して、編集または削除対象のフィールドを定義します。`,
  [`Redaction`]: `編集`,
  [`Redactions`]: `編集`,
  [`Redirect`]: `リダイレクト`,
  [`Redirect URI`]: `リダイレクト URI`,
  [`Redirect URL`]: `リダイレクト URL`,
  [`Redirect through Portal`]: `ポータル経由のリダイレクト`,
  [`Redirect time limit`]: `リダイレクト制限時間`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `リダイレクト時間制限は10秒から600秒の間でなければなりません`,
  [`References`]: `参照`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `追加のガイダンスについては、[IBM Cloud の資料]({link})を参照してください。`,
  [`Referral`]: `参照`,
  [`Refresh`]: `最新表示`,
  [`Refresh Token`]: `リフレッシュ・トークン`,
  [`Refresh button`]: `「最新表示」ボタン`,
  [`Refresh products`]: `製品の最新表示`,
  [`Refresh results from server`]: `サーバーからの結果を最新表示`,
  [`Refresh token`]: `リフレッシュ・トークン`,
  [`Refresh token time to live`]: `リフレッシュ・トークンの有効期間`,
  [`Refresh token time-to-live`]: `リフレッシュ・トークンの有効期間`,
  [`Refresh token time-to-live (seconds)`]: `リフレッシュ・トークンの有効時間 (秒)`,
  [`Refresh token TTL has been changed`]: `リフレッシュ・トークンの有効期間が変更されました`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `リフレッシュ・トークンの有効期間: **{seconds} 秒 ({time})**`,
  [`Refresh token time to live is **off**`]: `リフレッシュ・トークンの有効期間: **オフ**`,
  [`Refresh tokens`]: `リフレッシュ・トークン`,
  [`Refreshing`]: `最新表示中`,
  [`Refreshing...`]: `最新表示中...`,
  [`Region`]: `リージョン`,
  [`Register`]: `登録`,
  [`Register Gateways`]: `ゲートウェイを登録してください`,
  [`Register Service`]: `サービスの登録`,
  [`Register gateways`]: `ゲートウェイを登録してください`,
  [`Register service`]: `サービスの登録`,
  [`Register a service`]: `サービスの登録`,
  [`Register new services and manage existing services`]: `新規サービスの登録および既存のサービスの管理を行います`,
  [`Register remote gateway`]: `リモート・ゲートウェイの登録`,
  [`Registered Admin Member`]: `登録された管理者メンバー`,
  [`Registered Provider Org Owner or Member`]: `登録されたプロバイダー組織の所有者またはメンバー`,
  [`Registered client information on the OpenID provider`]: `OpenID プロバイダーに関する登録済みのクライアント情報`,
  [`Registration completed successfully`]: `登録が正常に完了しました`,
  [`Registration failed.`]: `登録に失敗しました。`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `個々のエンドポイントへの相対パス。 これらは、「basePath」からの相対パスでなければなりません。`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `分析の構成を削除`,
  [`Remote API Connect`]: `リモート API Connect`,
  [`Remote login`]: `リモート・ログイン`,
  [`Remote publish`]: `リモート公開`,
  [`Remove`]: `除去`,
  [`Remove Burst Limit`]: `バースト制限の削除`,
  [`Remove constraints`]: `制約の削除`,
  [`Remove Count Limit`]: `カウント制限の削除`,
  [`Remove gateway extension`]: `ゲートウェイ拡張の削除`,
  [`Remove product`]: `製品の削除`,
  [`Remove Rate limit`]: `レート制限の削除`,
  [`Remove the invalid constraints`]: `無効な制約の削除`,
  [`Remove the invalid constraints from`]: `次からの無効な制約の削除`,
  [`Remove action`]: `アクションの削除`,
  [`Remove case`]: `ケースの削除`,
  [`Remove catch`]: `Catch の削除`,
  [`Remove filter`]: `フィルターの削除`,
  [`Remove input`]: `入力の削除`,
  [`Remove mapping`]: `マッピングの削除`,
  [`Remove output`]: `出力の削除`,
  [`Remove static value`]: `静的値の削除`,
  [`Removed`]: `削除済み`,
  [`Removed by user`]: `ユーザーによって削除済み`,
  [`Reorder`]: `再配列`,
  [`Repeat`]: `反復`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `API の呼び出しを指定された回数だけ繰り返すか、停止ボタンがクリックされるまで繰り返します`,
  [`Replace`]: `置換`,
  [`Replace Free Trial with`]: `無料トライアルからの切り替え`,
  [`Replace Gold with`]: `ゴールドからの切り替え`,
  [`Replace GraphQL schema`]: `GraphQL スキーマの置換`,
  [`Replace plans`]: `プランの置き換え`,
  [`Replace private key & public key`]: `秘密鍵と公開鍵の置換`,
  [`Replace Product`]: `製品の置き換え`,
  [`Replace product`]: `製品の置き換え`,
  [`Replace schema`]: `スキーマの置き換え`,
  [`Replace Silver with`]: `シルバーからの切り替え`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `候補のリストから新規所有者を選択して、現在の所有者を置き換えます。 置き換え後の所有者に新しい許可を付与して、その所有者が作業を続行できるようにします。`,
  [`Replace|permission`]: `置換`,
  [`Republish`]: `再公開`,
  [`Republish product`]: `製品の再公開`,
  [`Request`]: `要求`,
  [`Request catch`]: `要求 Catch`,
  [`Request endpoint`]: `要求エンドポイント`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `カタログ {catalog} 内の API 製品に対する {action} 要求が承認されました`,
  [`Request approved to {{action}} app {{appName}}`]: `アプリケーション {appName} の {action} 要求が承認されました`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `カタログ {catalog} 内の API 製品に対する {action} 要求が却下されました`,
  [`Request denied to {{action}} app {{appName}}`]: `アプリケーション {appName} の {action} 要求が却下されました`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `{catalog} カタログ内の API 製品にサブスクライブするための承認の要求`,
  [`Request for approval to {{action}} app {{appName}}`]: `アプリケーション {appName} に対する {action} の承認要求`,
  [`Request headers`]: `要求ヘッダー`,
  [`Request pending to promote application to production`]: `アプリケーションを実動にレベル上げする要求が保留中です`,
  [`Request query`]: `要求の照会`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `カタログ {catalog} 内の API 製品に対する {action} 要求を受け取りました`,
  [`Request received to {{action}} app {{appName}}`]: `アプリケーション {appName} の {action} 要求を受け取りました`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `{catalog} カタログ内の API 製品に対するサブスクライブ要求が撤回されました`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `{catalog} カタログ内の API 製品に対する {action} 要求が撤回されました`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `アプリケーション {appName} に対する {action} の要求の撤回`,
  [`Requested approvals`]: `承認の要求`,
  [`Requested By`]: `要求元`,
  [`Requested To`]: `要求先`,
  [`Require`]: `必須`,
  [`Require applications to provide a client ID`]: `アプリケーションにクライアント ID の指定を要求します`,
  [`Require approval for all new self service development accounts`]: `すべての新規セルフサービス開発アカウントに承認を必要とします`,
  [`Required`]: `必須`,
  [`Required for rate limiting`]: `レート制限に必要です`,
  [`Required must be checked off when location is in path.`]: `ロケーションがパス内に含まれる場合は、「必須」にチェック・マークを入れる必要があります。`,
  [`Required path`]: `必須のパス`,
  [`Resend Invite`]: `招待の再送信`,
  [`Resend invitation`]: `招待の再送`,
  [`Resend invite`]: `招待の再送信`,
  [`Reset`]: `リセット`,
  [`Reset defaults`]: `デフォルトにリセット`,
  [`Reset Notification template`]: `通知テンプレートのリセット`,
  [`Reset password`]: `パスワードのリセット`,
  [`Reset password TTL has been changed`]: `パスワードのリセット TTL が変更されました`,
  [`Reset password time to live`]: `パスワードのリセットの有効期間`,
  [`Reset password time-to-live`]: `パスワードのリセットの有効期間`,
  [`Reset password email has been sent`]: `パスワードのリセットの E メールが送信されました`,
  [`Reset password email has not been sent`]: `パスワードのリセットの E メールが送信されていません`,
  [`Reset password invitation timeout`]: `パスワードのリセットの招待タイムアウト`,
  [`Reset to default preferences`]: `デフォルトの設定にリセット`,
  [`Resolve API Connect variable references`]: `API Connect 変数参照の解決`,
  [`Resolve XML input data type`]: `XML 入力データ型の解決`,
  [`resolver`]: `リゾルバー`,
  [`Resource group`]: `リソース・グループ`,
  [`Resource Owner`]: `リソース所有者`,
  [`Resource owner password grant`]: `リソース所有者パスワード付与`,
  [`Resource owner revocation`]: `リソース所有者失効`,
  [`Resource owner revocation path`]: `リソース所有者失効パス`,
  [`Resource Owner Security`]: `リソース所有者のセキュリティー`,
  [`Resource owner - JWT`]: `リソース所有者 - JWT`,
  [`Resource owner - Password`]: `リソース所有者 - パスワード`,
  [`Resource owner password`]: `リソース所有者のパスワード`,
  [`Resources`]: `リソース`,
  [`Response`]: `応答`,
  [`Response catch`]: `応答 Catch`,
  [`Response Definition`]: `応答の定義`,
  [`Response Time`]: `応答時間`,
  [`Response type`]: `応答タイプ`,
  [`Response context variables`]: `応答コンテキスト変数`,
  [`Response headers`]: `応答ヘッダー`,
  [`Response must have a description`]: `応答には説明が必要です`,
  [`Response must have a schema`]: `応答にはスキーマが必要です`,
  [`Response must have a status code`]: `応答には状況コードが必要です`,
  [`Response object variable`]: `応答オブジェクト変数`,
  [`Response time`]: `応答時間`,
  [`Response time: {{value}}ms`]: `応答時間: {{value}}ms`,
  [`Result of removing`]: `削除の結果`,
  [`Restrict HTTP transactions to version 1.0.`]: `HTTP トランザクションをバージョン 1.0 に制限します。`,
  [`Restrict to HTTP 1.0`]: `HTTP 1.0 に制限`,
  [`Retire`]: `廃棄`,
  [`Retire product`]: `製品の廃棄`,
  [`Retired`]: `廃棄済み`,
  [`Retire|permission`]: `廃棄`,
  [`Return V5 Responses`]: `V5 応答を戻す`,
  [`Retry selected requests`]: `選択した要求を再試行`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `GraphQL プロキシーからの標準の GraphQL イントロスペクション照会の結果を返します`,
  [`Return third-party access_token`]: `サード・パーティーの access_token を返します`,
  [`Return third-party access_token with the token response`]: `サード・パーティーの access_token をトークンの応答で返します`,
  [`Return third-party id_token`]: `サード・パーティーの id_token を返します`,
  [`Return third-party id_token with the token response`]: `サード・パーティーの id_token をトークンの応答で返します`,
  [`Return to Login`]: `ログインに戻る`,
  [`Reveal in finder`]: `ファインダーに表示`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `スキーマで表示または非表示にする内容を確認および決定します。 スキーマの有効性を維持するための追加の変更が必要になることがあります。`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `すべての警告を確認してください。 後でエディターでインテリジェントな推奨を使うことでスキーマの改善に役立ちます。`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `スキーマを改善してバックエンドを保護するために、警告を確認して、インテリジェントな推奨を適用してください。`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `API 呼び出しエンドポイントの基本 URL を確認し、ゲートウェイで処理するドメインを指定します。`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `ゲートウェイのタイプ、タイトル、名前、および要約を確認します。 API Connect のコマンドおよび API を呼び出す場合は、ゲートウェイ名を使用してください。`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `変更の要約を確認します。これには、スキーマの有効性を維持するための追加の変更が含まれることがあります。`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `スキーマで見つかった警告を確認してください。 後でエディターでインテリジェントな推奨を使うことで、スキーマを改善し、バックエンドを保護するのに役立ちます。`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `スキーマで見つかった警告を確認してください。 インテリジェントな推奨を使用することで、スキーマを改善し、バックエンドを保護するのに役立ちます。`,
  [`Reviewed schema, paths and operations`]: `確認されたスキーマ、パス、および操作`,
  [`Revocation protocol`]: `失効プロトコル`,
  [`Revoke token`]: `トークンの取り消し`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL 資料`,
  [`Role`]: `ロール`,
  [`Role default`]: `ロールのデフォルト`,
  [`Role default has been deleted`]: `ロールのデフォルトが削除されました`,
  [`Role defaults`]: `ロールのデフォルト`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `ロールのデフォルトは、新規組織の作成時に使用可能なデフォルトのロールを決定するために使用される事前構成のロール・テンプレートです。`,
  [`Role has been created`]: `ロールが作成されました`,
  [`Role has been deleted`]: `ロールが削除されました`,
  [`Role has been updated`]: `ロールが更新されました`,
  [`Roles`]: `ロール`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `ロール (およびロールに割り当てられた許可) によって、API Connect の機能へのアクセスが制御されます。 管理者組織ロールは、Cloud Manager を使用するユーザーにのみ適用されます。`,
  [`Roles and permissions control access to API Manager capabilities.`]: `ロールおよび許可により、API Manager の機能へのアクセスが制御されます。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `ロールおよびロールに割り当てられた許可によって、API Connect の機能へのアクセス、および API Connect でタスクを実行する権限が制御されます。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `ロールおよびロールに割り当てられた許可によって、API Manager UI の機能へのアクセス、および API Manager UI でタスクを実行する権限が制御されます。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `ロールおよびロールに割り当てられた許可によって、機能へのアクセスおよびタスクを実行する権限が制御されます。`,
  [`Root`]: `ルート`,
  [`Root XML Element Name`]: `ルート XML エレメント名`,
  [`Run the following command in the CLI to register the credentials:`]: `資格情報を登録するには、CLI で次のコマンドを実行します。`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `ツールキット資格情報を登録するには、CLI で次のコマンドを実行します。`,
  [`Running`]: `実行中`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `JWT を暗号化解除するために使用する JWK を格納するランタイム変数。`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `JWT を暗号化するために使用する JWK を格納するランタイム変数。`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `JWT に署名するために使用する JWK を格納するランタイム変数。`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `署名を検証するために使用する JWK を格納するランタイム変数。`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `一連の有効な JSON クレームの取得元となるランタイム変数。`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Audience (aud) クレーム・ストリングの取得元となるランタイム変数。 複数の変数を設定する場合は、コンマ区切りのストリングを使用します。`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Issuer (iss) クレーム・ストリングの取得元となるランタイム変数。 このクレームは、JWT を発行したプリンシパルを表しています。`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Subject (sub) クレーム・ストリングの取得元となるランタイム変数。`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `生成された JWT を格納するためのランタイム変数。 設定しない場合、JWT はベアラー・トークンとして許可ヘッダーに格納されます。`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `認証済みユーザー名を含むランタイム変数。 LTPA トークンの生成時、このプロパティーはユーザーとして設定されます。 例えば、$(client.app.id)、$(oauth.resource-owner)、または set-variable ポリシーで構成されたランタイム変数として設定されます。`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `JWT にあるクレーム一式が代入されるランタイム変数。`,
  [`Russian`]: `ロシア語`,
  [`Scalar`]: `スカラー`,
  [`Schema`]: `スキーマ`,
  [`SCHEMA`]: `スキーマ`,
  [`SCOPE`]: `スコープ`,
  [`SCOPES`]: `スコープ`,
  [`SECURITY DEFINITIONS`]: `セキュリティー定義`,
  [`Service`]: `サービス`,
  [`Service state:`]: `サービス状態:`,
  [`SERVICE TYPE`]: `サービス・タイプ`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1 ライセンス`,
  [`SOURCE`]: `ソース`,
  [`SSL Profile`]: `SSL プロファイル`,
  [`State`]: `状態`,
  [`STATUS`]: `状況`,
  [`Status Code`]: `状況コード`,
  [`STATUS CODE`]: `状況コード`,
  [`SUBMITTED`]: `送信済み`,
  [`SUBSCRIBERS`]: `サブスクライバー`,
  [`Summary`]: `要約`,
  [`Same as product`]: `製品と同じ`,
  [`Sample ID token signing key (RS256)`]: `サンプル ID トークン署名鍵 (RS256)`,
  [`Sample ID token validation key (RS256)`]: `サンプル ID トークン検証鍵 (RS256)`,
  [`Sample OAuth symmetric key value`]: `OAuth 対称鍵の値の例`,
  [`Sample URL: https://api.catalog.org.host/`]: `サンプル URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `サンプル URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `サンプル・スコープの説明 1`,
  [`Sandbox - Overview`]: `サンドボックス - 概要`,
  [`Sandbox Catalog`]: `サンドボックス・カタログ`,
  [`Sandbox Catalog User Registry`]: `サンドボックス・カタログ・ユーザー・レジストリー`,
  [`Sandbox Default TLS client profile`]: `サンドボックスのデフォルトの TLS クライアント・プロファイル`,
  [`Sandbox Default TLS Client Profile`]: `サンドボックスのデフォルトの TLS クライアント・プロファイル`,
  [`Sandbox catalog should always exist in a provider organization.`]: `サンドボックス・カタログは常にプロバイダー組織に存在する必要があります。`,
  [`Save`]: `保存`,
  [`Save API as a new version`]: `API を新規バージョンとして保存`,
  [`Save Product as a new version`]: `製品を新規バージョンとして保存`,
  [`Save and Edit`]: `保存と編集`,
  [`Save and republish`]: `保存して再公開`,
  [`Save as a new version`]: `新規バージョンとして保存`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `クライアント秘密鍵を保存します (セキュリティー上の理由からこれ以降取得できなくなります)`,
  [`Save this product as a new version`]: `この製品を新規バージョンとして保存`,
  [`Saved`]: `保存済み`,
  [`Saving assembly...`]: `アセンブリーを保存しています...`,
  [`Saving...`]: `保存しています...`,
  [`Schema Type`]: `スキーマ・タイプ`,
  [`Schema as JSON`]: `JSON 形式のスキーマ`,
  [`Schema as XML`]: `XML 形式のスキーマ`,
  [`Schema as YAML`]: `YAML 形式のスキーマ`,
  [`Schema change`]: `スキーマの変更`,
  [`Schema changes`]: `スキーマの変更`,
  [`Schema definition circular reference limit`]: `スキーマ定義の循環参照の制限`,
  [`Schema name`]: `スキーマ名`,
  [`Schema warnings`]: `スキーマの警告`,
  [`Schema definition`]: `スキーマ定義`,
  [`Scheme`]: `スキーム`,
  [`Schemes`]: `スキーム`,
  [`Scope`]: `スコープ`,
  [`Scopes`]: `スコープ`,
  [`Scopes cannot be empty`]: `スコープを空にすることはできません`,
  [`Search`]: `検索`,
  [`Search DN`]: `DN の検索`,
  [`Search in the field above above and select to add provider organizations`]: `上のフィールドを検索して、追加するプロバイダー組織を選択`,
  [`Search Limit`]: `検索限界`,
  [`Search Organization owner from`]: `次から組織所有者を検索:`,
  [`Search orgs/groups`]: `組織/グループの検索`,
  [`Search Owner`]: `所有者の検索`,
  [`Search assemblies`]: `アセンブリーの検索`,
  [`Search for`]: `検索対象`,
  [`Search for provider organizations`]: `プロバイダー組織の検索`,
  [`Search for Owner`]: `所有者の検索`,
  [`Search for a new member to become the new owner.`]: `新規所有者となる新規メンバーを検索します。`,
  [`Search for an existing member to become the new owner.`]: `新規所有者となる既存メンバーを検索します。`,
  [`Search for new owner`]: `新規所有者の検索`,
  [`Search for owner from:`]: `所有者の検索対象:`,
  [`Search for the member`]: `メンバーの検索`,
  [`Search for title or name or version`]: `タイトル、名前、またはバージョンの検索`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `メンバーとして追加するユーザーを検索し、必要なロールを割り当てます。`,
  [`Search overflow`]: `検索オーバーフロー`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `メンバーとして追加する既存のユーザーのレジストリーを検索し、必要なロールを割り当てます`,
  [`Search types/fields`]: `検索タイプ/フィールド`,
  [`Search user`]: `検索ユーザー`,
  [`Search schema`]: `スキーマの検索`,
  [`Seconds`]: `秒`,
  [`Secret Key`]: `秘密鍵`,
  [`Secret Name`]: `秘密名`,
  [`Secret Required`]: `秘密鍵が必須`,
  [`Secret key`]: `秘密鍵`,
  [`Secure`]: `セキュア`,
  [`Secure connection`]: `セキュア接続`,
  [`Secure using Client ID`]: `クライアント ID を使用した保護`,
  [`Security`]: `セキュリティー`,
  [`Security & Product Definitions`]: `セキュリティーおよび製品の定義`,
  [`Security definitions`]: `セキュリティー定義`,
  [`Security and Publish`]: `セキュリティーおよび公開`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `セキュリティー定義により、API キー検証、指定されたユーザー・レジストリーによる基本認証、OAuth などの API エンドポイントへのクライアント・アクセスが制御されます。`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `セキュリティー定義により、API キー検証、指定されたユーザー・レジストリーによる基本認証、OAuth などの API エンドポイントへのクライアント・アクセスが制御されます。 [詳細情報]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `ここで選択されたセキュリティー定義は API 全体に適用されますが、個別の操作についてオーバーライドできます。`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `ここで選択されたセキュリティー定義は API 全体に適用されますが、個別の操作についてオーバーライドできます。 [詳細情報]({link})`,
  [`Security object to use to decode the claim.`]: `クレームをデコードするために使用するセキュリティー・オブジェクト。`,
  [`Security object used to encrypt the JWT.`]: `JWT を暗号化するために使用するセキュリティー・オブジェクト。`,
  [`Security object used to sign the JWT.`]: `JWT に署名するために使用するセキュリティー・オブジェクト。`,
  [`Security object used to verify the claim.`]: `クレームを検証するために使用するセキュリティー・オブジェクト。`,
  [`Select APIs to add to this product`]: `この製品に追加する API を選択します`,
  [`Select API Type`]: `API タイプの選択`,
  [`Select API type`]: `API タイプの選択`,
  [`Select a billing integration in the Product plans.`]: `製品プラン内の請求統合を選択してください。`,
  [`Select a flow...`]: `フローを選択してください...`,
  [`Select a plan from your existing product`]: `既存の製品からプランを選択します`,
  [`Select a policy file to import to this catalog`]: `このカタログにインポートするポリシー・ファイルを選択してください`,
  [`Select a policy implementation file to import to this catalog`]: `このカタログにインポートするポリシー実装ファイルを選択してください`,
  [`Select a product`]: `製品の選択`,
  [`Select Certificate Manager service`]: `証明書マネージャー・サービスの選択`,
  [`Select Cloud`]: `クラウドの選択`,
  [`Select Email Server`]: `E メール・サーバーの選択`,
  [`Select gateway services`]: `ゲートウェイ・サービスの選択`,
  [`Select Import Type`]: `インポート・タイプの選択`,
  [`Select product`]: `製品の選択`,
  [`Select product type`]: `製品タイプの選択`,
  [`Select Service`]: `サービスの選択`,
  [`Select Service Type`]: `サービス・タイプの選択`,
  [`Select target endpoints`]: `ターゲット・エンドポイントの選択`,
  [`Select target service`]: `ターゲット・サービスの選択`,
  [`Select user registry type`]: `ユーザー・レジストリー・タイプの選択`,
  [`Select a WSDL service from the imported file`]: `インポートされたファイルから WSDL サービスを選択します`,
  [`Select a certificate`]: `証明書の選択`,
  [`Select a Certificate Manager service`]: `証明書マネージャー・サービスの選択`,
  [`Select a cryptographic algorithm.`]: `暗号アルゴリズムを選択します。`,
  [`Select a default catalog and organization`]: `デフォルトのカタログと組織の選択`,
  [`Select a different cloud`]: `別のクラウドの選択`,
  [`Select a key encryption algorithm.`]: `鍵暗号化アルゴリズムを選択します。`,
  [`Select a plan to migrate`]: `マイグレーションするプランの選択`,
  [`Select a plan to replace`]: `置換するプランの選択`,
  [`Select a plan to supersede`]: `取り替えるプランの選択`,
  [`Select a product to set as the migration target`]: `マイグレーション・ターゲットとして設定する製品を選択します`,
  [`Select a product to supersede`]: `取り替える製品の選択`,
  [`Select a product to supersede {sourceProductName}:`]: `{sourceProductName} に取り替わる製品の選択:`,
  [`Select a resource group`]: `リソース・グループの選択`,
  [`Select a service`]: `サービスの選択`,
  [`Select a supported file ({type}) and try again.`]: `サポートされるファイル ({type}) を選択し、再試行してください。`,
  [`Select a target SOAP service`]: `ターゲット SOAP サービスの選択`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `ダウンロードするツールキット・パッケージを選択してください。 ツールキットがインストールされている場合は、このステップをスキップしてください。`,
  [`Select an encryption algorithm.`]: `暗号化アルゴリズムを選択します。`,
  [`Select an extension file for this gateway`]: `このゲートウェイの拡張ファイルの選択`,
  [`Select an org`]: `組織の選択`,
  [`Select catalog`]: `カタログの選択`,
  [`Select Catalogs to Search`]: `検索するカタログの選択`,
  [`Select compatible gateway services`]: `互換性のあるすべてのゲートウェイ・サービスを使用します`,
  [`Select one Keystore from the list below:`]: `次のリストから鍵ストアを 1 つ選択してください。`,
  [`Select one keystore from the list below:`]: `次のリストから鍵ストアを 1 つ選択してください。`,
  [`Select one keystore from the list.`]: `リストから鍵ストアを 1 つ選択します。`,
  [`Select operation...`]: `操作の選択...`,
  [`Select operations you want to include`]: `含める操作を選択します`,
  [`Select organization`]: `組織の選択`,
  [`Select the API definition or package file to import`]: `インポートする API 定義またはパッケージ・ファイルを選択する`,
  [`Select the billing integration for this catalog`]: `このカタログの請求統合を選択します`,
  [`Select the billing integration for this product`]: `この製品の請求統合を選択します`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `新規カタログの API を保護するゲートウェイを選択します。 新規ゲートウェイを登録するには、「**ゲートウェイ**」ページにアクセスします。`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `このゲートウェイの証明書を管理する [証明書マネージャー]({link}) サービスを選択します。`,
  [`Select the APIs to add to this product`]: `この製品に追加する API を選択します`,
  [`Select the OpenAPI definition file to import`]: `インポートする OpenAPI 定義ファイルを選択する`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `承認が必要な製品ライフサイクル・アクションを選択します。 選択したアクションのいずれかが実行されると、承認要求が生成されます。アクションが完了するためには、この要求が承認されなければなりません。`,
  [`Select the TLS protocol versions for this profile.`]: `このプロファイルの TLS プロトコル・バージョンを選択します。`,
  [`Select the analytics service you would like to associate with the gateway service`]: `ゲートウェイ・サービスに関連付ける分析サービスを選択します`,
  [`Select the consumer organization in which you want to create the application`]: `アプリケーションを作成するコンシューマー組織を選択します`,
  [`Select the consumer organization that will own the application`]: `アプリケーションの所有者となるコンシューマー組織を選択します。`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `この API を公開するためのエンドポイントを選択し、必要に応じてレート制限を定義します`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `ポリシーの実行中にスローされた場合、アセンブリー・フローを停止させるエラーを選択します。 エラーに対してキャッチ・フローが構成されている場合は、スローされたエラーを処理するためにそのフローがトリガーされます。 エラーがスローされ、「エラー発生時に停止」設定でエラーが選択されていない場合、またはスローされたエラーが選択されたエラーの 1 つでない場合、ポリシーの実行の完了が許可され、アセンブリー・フローが続行されます。`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `製品の API を使用できるようにするゲートウェイ・サービスを選択します。`,
  [`Select the gateway type for this API`]: `この API のゲートウェイ・タイプを選択してください`,
  [`Select the gateway type for this OAuth provider`]: `この OAuth プロバイダーに対するゲートウェイ・タイプを選択してください`,
  [`Select the keystores and truststores for this profile.`]: `このプロファイルの鍵ストアおよびトラストストアを選択します。`,
  [`Select the organizations or groups you would like to make this product visible to`]: `この製品を表示可能にする組織またはグループを選択します`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `この製品をサブスクライブする組織またはグループを選択します`,
  [`Select the portal service to use for this catalog`]: `このカタログで使用するポータル・サービスの選択`,
  [`Select the product and plan for the application subscription`]: `アプリケーション・サブスクリプション用の製品とプランを選択します`,
  [`Select the product definition file to import`]: `インポートする製品定義ファイルの選択`,
  [`Select the product definition file to import from`]: `インポート元となる製品定義ファイルを選択します`,
  [`Select the product that will supersede the originally selected product`]: `選択した元の製品の取り替え後の製品を選択します`,
  [`Select the product that you want to add the API to`]: `API を追加する製品を選択します`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `このカタログ内の API へのアクセスを制御するために使用するレジストリーを選択します`,
  [`Select the required operations`]: `必要な操作を選択してください`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `入力レベルに関連付けるログ・メッセージの重大度レベルを選択します。使用可能なレベルは、エラー、警告、および情報です。`,
  [`Select the target wsdl file to create from`]: `作成元となるターゲット WSDL ファイルを選択します`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `次からターゲットの wsdl、xsd、または zip ファイルを選択して、API 定義を作成します`,
  [`Select the target WSDL file to import`]: `インポートするターゲット WSDL ファイルを選択する`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `API Manager ユーザーを認証するためのユーザー・レジストリーを選択するか、新規ユーザー・レジストリーを作成してください。`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `API Manager のユーザーを認証するためのユーザー・レジストリーを選択するか、新規ユーザー・レジストリーを作成してください。`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Cloud Manager のユーザーを認証するためのユーザー・レジストリーを選択するか、新規ユーザー・レジストリーを作成してください。`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `開発者ポータルへのログイン認証に使用するユーザー・レジストリーを選択します`,
  [`Select the user registry type`]: `ユーザー・レジストリー・タイプの選択`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `生成された LTPA トークンをポリシーが格納する出力ソース内の場所を選択します。  「WSSec ヘッダー」を選択する必要があるのは、要求の content-type が application/xml の場合に限られます。`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `プロパティーを完全に削除するか、「*」を使用してデータをブロックすることでフィールドを編集するかを選択します。`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `選択した製品が変更されました。 ページをリフレッシュして、再試行してください。`,
  [`Selecting operations from WSDL service definition`]: `WSDL サービス定義からの操作の選択`,
  [`Self Service Onboarding for Consumer organization`]: `コンシューマー組織のセルフサービス・オンボーディング`,
  [`Self service onboarding`]: `セルフサービス・オンボーディング`,
  [`Self service onboarding approval`]: `セルフサービス・オンボーディング承認`,
  [`Send`]: `送信`,
  [`Send Message`]: `メッセージの送信`,
  [`Send Message to Owners`]: `所有者にメッセージを送信`,
  [`Send To`]: `送信先`,
  [`Send a registration link to a new user to register as owner.`]: `新規ユーザーに対して、所有者として登録するための登録リンクを送信します。`,
  [`Send a test email`]: `テスト E メールを送信する`,
  [`Send a test email to confirm that the email server is properly configured.`]: `テスト E メールを送信して、E メール・サーバーが正しく構成されていることを確認します。`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `エンドポイントが適切に構成されていることを確認するためにテスト信号を送信します。`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `エンドポイントが適切に構成されていることを確認するためにテスト信号を送信します`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `管理者組織の所有者に E メール・メッセージを送信します。 所有者の名前の横にチェック・マークを付けてください`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `所有者に E メール・メッセージを送信します。 所有者の名前の横にチェック・マークを付けてください`,
  [`Send chunked-encoded documents to the target server.`]: `チャンク・エンコード文書をターゲット・サーバーに送信してください。`,
  [`Send message`]: `メッセージの送信`,
  [`Send message to owner`]: `所有者にメッセージを送信`,
  [`Send test email`]: `テスト E メールの送信`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `ユーザーにアクティベーション・リンクを送信して、サインインまたは登録するように招待します。`,
  [`Send-only`]: `送信のみ`,
  [`Sender`]: `送信者`,
  [`Sender & email server`]: `送信者および E メール・サーバー`,
  [`Sender & email server have been changed`]: `送信者および E メール・サーバーが変更されました`,
  [`Sender Info`]: `送信者情報`,
  [`Sent`]: `送信済み`,
  [`Sent events: **{sent}**`]: `送信されたイベント: **{sent}**`,
  [`Serial`]: `シリアル`,
  [`Serialize output`]: `出力のシリアライズ`,
  [`Server connection`]: `サーバー接続`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `GUI GraphQL クライアントを有効にするために、Web ブラウザーに HTML を提供します`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `GUI GraphQL クライアントを有効にするために、Web ブラウザーに HTML を提供します。`,
  [`Server Name Indication (SNI)`]: `Server Name Indication (SNI)`,
  [`Server URL`]: `サーバー URL`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `OIDC リダイレクト URL で、サーバーがトークンの代わりにハッシュでエラーを返しました。 ハッシュ：`,
  [`Service name`]: `サービス名`,
  [`Services`]: `サービス`,
  [`Set`]: `設定`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `API Designer の APIC_DESIGNER_CREDENTIALS OS 環境変数を設定します。 例えば MAC の場合、ユーザーは API Designer の起動時に環境変数を渡すことができます。`,
  [`Set Migration Target`]: `マイグレーション・ターゲットの設定`,
  [`Set migration target`]: `マイグレーション・ターゲットの設定`,
  [`Set Variable`]: `変数の設定`,
  [`Set as default`]: `デフォルトに設定`,
  [`Set default`]: `デフォルトの設定`,
  [`Set rate and burst limits in an assembly`]: `アセンブリー内のレート制限とバースト制限を設定します`,
  [`Set the timeout period for invitations`]: `招待のタイムアウト期間を設定します`,
  [`Set the trust level for the profile`]: `プロファイルのトラスト・レベルを設定します`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `値を、許可される循環スキーマ定義の反復回数に設定します (デフォルト値は 1、最大値は 5)`,
  [`Set the visibility for a gateway service`]: `ゲートウェイ・サービスの可視性を設定します`,
  [`Set the visibility for a portal service`]: `ポータル・サービスの可視性を設定します`,
  [`Set the visibility for each service`]: `各サービスの可視性を設定します`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `認証サーバーが発行した新規アクセス・トークンを取得する期限をセットアップします`,
  [`Set visibility`]: `可視性の設定`,
  [`Set visibility of gateway`]: `ゲートウェイの可視性の設定`,
  [`Set time interval`]: `時間間隔の設定`,
  [`Set, Add, or Clear a runtime variable.`]: `ランタイム変数の設定、追加、またはクリア。`,
  [`Set the timeout period for API key`]: `API キーのタイムアウト期間を設定します`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `キャッシュ内の文書の有効期間を秒単位で設定します。 キャッシュ・タイプが「有効期間」に設定されている場合にのみ適用されます。 5 から 31708800 の範囲の値を入力します。 デフォルト値は 900 です。`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `「assumedSize」の値の設定は、デフォルト値を持つスライス引数の定義と組み合わせることはできず、また「requireOneSlicingArgument」の有効化とも組み合わせることはできません。`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `このカタログをデフォルトのカタログとして設定すると、カタログ名を除いた短い URL でアクセスできるようになります。 デフォルトとして設定できるカタログは 1 つだけです。`,
  [`Settings`]: `設定`,
  [`Setup`]: `セットアップ`,
  [`Severity level for input data log messages`]: `入力データ・ログ・メッセージの重大度レベル`,
  [`Share the resource with a custom list of provider organizations`]: `プロバイダー組織のカスタム・リストとリソースを共有します`,
  [`Share the resource with provider organizations`]: `プロバイダー組織とリソースを共有します`,
  [`Share the API gateway with a custom list of provider organizations`]: `プロバイダー組織のカスタム・リストと API ゲートウェイを共有します`,
  [`Share the service with a custom list of provider organizations`]: `プロバイダー組織のカスタム・リストとサービスを共有します`,
  [`Share the API gateway with provider organizations`]: `プロバイダー組織と API ゲートウェイを共有します`,
  [`Share the service with provider organizations`]: `プロバイダー組織とサービスを共有します`,
  [`Share`]: `共有`,
  [`Shared`]: `共有`,
  [`Shared by`]: `共有者`,
  [`Short but descriptive name for this policy.`]: `このポリシーの短い記述名。`,
  [`Show`]: `表示`,
  [`Show / hide policy palette`]: `ポリシー・パレットの表示/非表示`,
  [`Show/hide`]: `表示/非表示`,
  [`Show/hide settings`]: `表示/非表示の設定`,
  [`Show and hide types and fields to include in the schema.`]: `スキーマに含めるタイプとフィールドの表示と非表示を切り替えます。`,
  [`Show added only`]: `追加済みのみを表示`,
  [`Show all`]: `すべて表示`,
  [`Show all UDP versions`]: `UDP バージョンをすべて表示`,
  [`Show app identifier`]: `アプリケーション ID の表示`,
  [`Show breaking changes only`]: `破壊的変更のみを表示`,
  [`Show catalog identifier`]: `カタログ ID の表示`,
  [`Show catches`]: `Catch の表示`,
  [`Show in schema`]: `スキーマで表示`,
  [`Show less`]: `詳細非表示`,
  [`Show modified only`]: `変更済みのみを表示`,
  [`Show more`]: `詳細を表示`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `新規のタイプ、フィールド、ディレクティブ、またはオプション引数を表示します。 バックエンドによってサポートされなくなった項目は、この場合も削除されます。`,
  [`Show removed only`]: `削除済みのみを表示`,
  [`Show schema`]: `スキーマの表示`,
  [`Show warnings only`]: `警告のみを表示`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `現在割り当てられている鍵ストアを除いて、鍵ストア割り当ての履歴を表示します。`,
  [`Sign Crypto Object`]: `署名用暗号オブジェクト`,
  [`Sign in`]: `サインイン`,
  [`Sign JWK variable name`]: `署名用 JWK 変数名`,
  [`Sign Out`]: `サインアウト`,
  [`Sign up`]: `サインアップ`,
  [`sign-up`]: `サインアップ`,
  [`Sign in using the {userRegistryType} User Registry`]: `{userRegistryType} ユーザー・レジストリーを使用してサインイン`,
  [`Sign in with`]: `サインイン -`,
  [`Sign out`]: `サインアウト`,
  [`Sign up with`]: `次を使用してサインアップ`,
  [`Signed token`]: `署名済みトークン`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `バッファリングがオフに設定されたため、payload から headers への content または error-content、あるいはその両方を減らす必要があります。 必要な変更を加えて続行しますか、それとも最近の変更をキャンセルしますか?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `content または error-content、あるいはその両方が payload に設定されたため、バッファリングをオンに設定する必要があります。 必要な変更を加えて続行しますか、それとも最近の変更をキャンセルしますか?`,
  [`Sized fields`]: `サイズ設定されたフィールド`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `スライス引数`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `一部の入力は、配列であるか、配列の中に含まれています。 このコンテンツを反復処理するには、反復オプションを選択し、反復対象となる入力を選択します。`,
  [`Something went wrong.`]: `問題が発生しました。`,
  [`Something went wrong...`]: `問題が発生しました...`,
  [`Source`]: `ソース`,
  [`Source schema`]: `ソース・スキーマ`,
  [`Space`]: `スペース`,
  [`Space is not allowed in scope name`]: `スコープ名にスペースは使用できません`,
  [`Space details`]: `スペース詳細`,
  [`Space Enabled`]: `スペース有効`,
  [`Space Name`]: `スペース名`,
  [`Space Summary`]: `スペース要約`,
  [`Space Title`]: `スペースのタイトル`,
  [`Space owner`]: `スペース所有者`,
  [`Space {name} created`]: `スペース {name} が作成されました`,
  [`Spaces`]: `スペース`,
  [`Spaces are enabled`]: `スペースが有効です`,
  [`Spaces are not enabled`]: `スペースが有効になっていません`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `サンドボックス・カタログに対してスペースを有効化できません。`,
  [`Spaces cannot be enabled.`]: `スペースを有効にできません。`,
  [`Spaces dropdown`]: `「スペース」ドロップダウン`,
  [`Spaces has been enabled`]: `スペースが有効になりました`,
  [`Spaces have been enabled.`]: `スペースが有効になりました。`,
  [`Spaces per page`]: `ページあたりのスペース`,
  [`Spanish`]: `スペイン語`,
  [`Special characters are not accepted`]: `特殊文字は許可されません`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `動的オブジェクトにマージされている、シリアル化された XML または JSON プロパティーの取得元となる名前付きコンテキストの URL またはストリングを指定します。 これらのプロパティーは、既存のリテラルまたはデフォルトのプロパティーよりも優先されます。`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `動的オブジェクトにマージされている、シリアル化された XML または JSON プロパティーを表すリテラル・ストリングを指定します。 これらのプロパティーは、既存のデフォルトのプロパティーより優先されます。`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `動的オブジェクトのデフォルトのプロパティー値の取得元となる、既存の有効なオブジェクトを指定します。 警告: デフォルトのストリングを変更する場合、指定された解析設定オブジェクトが DataPower アプライアンスで使用可能でなければなりません。`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `編集または削除するコンテンツが含まれているデータ・ソースを指定します。 ルートを指定しない場合は、API コンテキスト全体にアクションが適用されます。 サポートされている任意の JSONata パス式を使用できます。\n\n要求と応答のデータの編集は、「message.body」のルートで実行できます。 収集のみのモードを指定するアセンブリー・ログ・アクションの後にアセンブリーでアセンブリー編集アクションが使用される場合、ルートは、ログに記録された要求ペイロードの「log.request_body」、またはログに記録された応答ペイロードの「log.response_body」を指定できます。`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `API コンテキストの変数の名前を指定します。 この変数の「body」フィールドの内容が、このアクションに対する入力です。 デフォルトの変数名は「message」です。`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `API コンテキストの変数の名前を指定します。 この変数の「body」フィールドの内容が、解析の出力です。 構文解析された文書の構文解析メトリックを、メッセージの別の部分に格納することができます。 デフォルトの変数名は任意の入力名であるため、デフォルトで入力メッセージが出力メッセージで上書きされます。`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `アクセス・トークンのカスタム・メタデータを取得できるかどうか、およびどこで取得するかを指定します。`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `指定したコンテンツ・タイプで解析を試行するかどうかを指定します。 これが適用できるのは、予期されるコンテンツ・タイプが JSON または XML である場合のみです。 このオプションを有効にすると、コンテンツ・タイプが解析設定と一致しない場合 (つまり、コンテンツ・タイプが application/json であり、解析設定が XML 用に構成されている場合) に解析アクションが失敗します。 入力コンテンツを検出するように解析設定が構成されている場合、指定したコンテンツ・タイプに強制的に設定されます。`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `変換後に XSLT 出力ツリーをバイナリー・データにシリアライズするかどうかを指定します。`,
  [`Specify default gateways`]: `デフォルトのゲートウェイを指定します`,
  [`Specify email`]: `E メールの指定`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `ペイロードの検証に使用する OpenAPI (Swagger) スキーマ定義を指定します (「request」、「response」、または「#/definitions/XXXX」)。`,
  [`Specify owner of the consumer organization`]: `コンシューマー組織の所有者を指定します`,
  [`Specify owner of the provider organization`]: `プロバイダー組織の所有者を指定します`,
  [`Specify settings for the third party OAuth provider.`]: `サード・パーティー OAuth プロバイダーの設定を指定します。`,
  [`Specify the MIME types that your API consumes and produces`]: `API が使用および生成する MIME タイプを指定します`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `カタログ所有者を指定します。所有者はカタログの許可をすべて持ちます`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `開発者ポータルで製品を表示できるようにするコンシューマー組織または開発者のコミュニティーを指定します`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `製品内の API を使用するためにサブスクライブできるようにするコンシューマー組織または開発者のコミュニティーを指定します`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `API 呼び出しエンドポイントの基本 URL と、ゲートウェイで処理するドメインを指定します。`,
  [`Specify the details of the catalog that you want to publish to`]: `公開先のカタログの詳細を指定します`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `新規カタログの API を保護するゲートウェイのデフォルト・セットを指定します。 [詳細情報]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `この API を公開するエンドポイントを指定し、さらに API 呼び出しのレートを制限するかどうかを指定します`,
  [`Specify the new owner`]: `新規所有者の指定`,
  [`Specify the owner`]: `所有者の指定`,
  [`Specify the owner and title`]: `所有者およびタイトルの指定`,
  [`Specify the properties to redact or remove.`]: `編集または削除するプロパティーを指定します。`,
  [`Specify the publish and rate limiting requirements for this API`]: `この API の公開要件およびレート制限要件を指定してください`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `定義済みのスコープに加えて追加のスコープ検査が実行されるスコープ検査エンドポイントを指定します。`,
  [`Specify the security settings for the imported API definition`]: `インポートされた API 定義のセキュリティー設定を指定します`,
  [`Specify the security settings for your API definition`]: `API 定義のセキュリティー設定の指定`,
  [`Specify the space owner; the owner has all space permissions`]: `スペース所有者を指定します。所有者はスペースの許可をすべて持ちます`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `ターゲット SOAP サービスおよび API の要約の詳細を入力してください。必要に応じて、API 定義の作成後に API 定義をさらに構成できます`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `ユーザーがアカウントをアクティブ化しない場合に招待 E メールが有効期限切れになるまでのタイムアウト期間を指定してください`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `ユーザーがアカウントをアクティブ化しない場合に招待 E メールが有効期限切れになるまでのタイムアウト期間を指定してください。`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `ゲートウェイのタイプ、タイトル、およびゲートウェイを説明する要約を指定します。`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `API Connect プロバイダー組織に対する API ゲートウェイの可視性を指定します。`,
  [`Specify the way you want to validate the message.`]: `メッセージの検証方法を指定します。`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `ゲートウェイ管理エンドポイントの URL と、エンドポイントのドメインの証明書を指定します。`,
  [`Specify what content to log in the event of an error.`]: `エラーが発生した場合にログに記録する内容を指定します。`,
  [`Specify what content to log.`]: `ログに記録する内容を指定します。`,
  [`Specifying the publish target details`]: `公開ターゲット詳細の指定`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `xsi:type=SOAP-ENC:Array ルールに特定して許可します`,
  [`Stage`]: `ステージ`,
  [`Stage API`]: `API のステージング`,
  [`Stage product`]: `製品のステージング`,
  [`Stage to`]: `ステージング先`,
  [`Staged`]: `ステージング済み`,
  [`Stage|permission`]: `ステージ`,
  [`Standard OIDC`]: `標準 OIDC`,
  [`Static DNS`]: `静的 DNS`,
  [`Static group`]: `静的グループ`,
  [`Status`]: `状況`,
  [`Status code`]: `状況コード`,
  [`Step 1`]: `ステップ 1`,
  [`Step 1: Download`]: `ステップ 1: ダウンロード`,
  [`Step 1: Download toolkit`]: `ステップ 1: ツールキットのダウンロード`,
  [`Step 1: Upload private key`]: `ステップ 1: 秘密鍵のアップロード`,
  [`Step 2`]: `ステップ 2`,
  [`Step 2: Download credentials`]: `ステップ 2: 資格情報のダウンロード`,
  [`Step 2: Download toolkit credentials`]: `ステップ 2: ツールキット資格情報のダウンロード`,
  [`Step 2: Install`]: `ステップ 2: インストール`,
  [`Step 2: Upload public key`]: `ステップ 2: 公開鍵のアップロード`,
  [`Step 3`]: `ステップ 3`,
  [`Step 3: Configure`]: `ステップ 3: 構成`,
  [`Step 3: Run command`]: `ステップ 3: コマンドの実行`,
  [`Step 3: Run commands`]: `ステップ 3: コマンドの実行`,
  [`Step 4: Register`]: `ステップ 4: 登録`,
  [`Stop`]: `停止`,
  [`Stop after:`]: `停止するまでの回数:`,
  [`Stop all the retries`]: `すべての再試行を停止`,
  [`Stop Connection`]: `接続の停止`,
  [`Stop on error`]: `エラー発生時に停止`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `この API の実行を停止して、サンドボックス・カタログから削除します。`,
  [`Stopped`]: `停止済み`,
  [`Stopping Published API`]: `公開済み API の停止中`,
  [`Strict`]: `厳格`,
  [`Strict SOAP envelope version`]: `厳格な SOAP エンベロープ・バージョン`,
  [`String Badgerfish`]: `ストリング Badgerfish`,
  [`String`]: `ストリング`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `API 呼び出しの URL の組織セグメントに含まれるストリング。 小文字の英字、数字、およびハイフンを使用してください。`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Stripe 統合`,
  [`Streaming rule`]: `ストリーミング・ルール`,
  [`Subject`]: `件名`,
  [`Subject Claim`]: `Subject クレーム`,
  [`Subject Origin`]: `サブジェクトのオリジン`,
  [`Subject common name`]: `サブジェクトの共通名`,
  [`Subject|certificate`]: `件名`,
  [`Submit`]: `送信`,
  [`Submitted`]: `送信日時`,
  [`Subscribability`]: `サブスクライブ可能性`,
  [`Subscribability must be disabled if visibility is disabled`]: `可視性が無効な場合、サブスクライブ可能性を無効にする必要があります`,
  [`Subscribability options`]: `サブスクライブ可能性オプション`,
  [`Subscribable by`]: `サブスクライブ可能`,
  [`Subscribe`]: `サブスクライブ`,
  [`Subscribe Financial Management App`]: `財務管理アプリケーションのサブスクライブ`,
  [`Subscribe TODO App`]: `TODO アプリケーションのサブスクライブ`,
  [`Subscription`]: `サブスクリプション`,
  [`Subscription approval task for subscription of`]: `次のサブスクリプションについてのサブスクリプションの承認タスク:`,
  [`Subscription created`]: `サブスクリプションが作成されました`,
  [`Subscription has been added`]: `サブスクリプションが追加されました`,
  [`Subscription has not been added`]: `サブスクリプションが追加されていません`,
  [`Subscription pending approval`]: `サブスクリプションは承認待ちです`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `GraphQL サブスクリプションはサポートされないため、サブスクリプション・タイプが削除されました。`,
  [`Subscription-Approval`]: `サブスクリプションの承認`,
  [`Subscriptions`]: `サブスクリプション`,
  [`Success`]: `成功`,
  [`Suffix`]: `接尾部`,
  [`Suggestion`]: `候補`,
  [`Supersede`]: `取り替え`,
  [`Supersede Product`]: `製品の取り替え`,
  [`Supersede product`]: `製品の取り替え`,
  [`Supersede plans`]: `プランの取り替え`,
  [`Supersede {product1} with {product2}`]: `{product1} を {product2} で取り替え`,
  [`Supersede|permission`]: `取り替え`,
  [`Supersede|supersede with`]: `取り替え`,
  [`Support`]: `サポート`,
  [`support default introspection`]: `デフォルトのイントロスペクションをサポート`,
  [`Support Server Name Indication (SNI)`]: `Server Name Indication (SNI) のサポート`,
  [`Support hybrid response types`]: `サポートされるハイブリッド応答タイプ`,
  [`Support standard introspection`]: `標準のイントロスペクションをサポート`,
  [`Supported OAuth components`]: `サポートされる OAuth コンポーネント`,
  [`Supported client types`]: `サポートされるクライアント・タイプ`,
  [`Supported client types can not be empty.`]: `サポートされるクライアント・タイプを空にすることはできません。`,
  [`Supported grant type can not be empty.`]: `サポートされている権限付与タイプを空にすることはできません。`,
  [`Supported grant types`]: `サポートされている権限付与タイプ`,
  [`Suspend`]: `保留`,
  [`Suspend API`]: `API の中断`,
  [`Suspend Application`]: `アプリケーションの中断`,
  [`Switch`]: `切り替え`,
  [`Switch account`]: `アカウントの切り替え`,
  [`Switch cloud connection`]: `クラウド接続の切り替え`,
  [`Switch directory`]: `ディレクトリーの切り替え`,
  [`TARGET`]: `ターゲット`,
  [`Title`]: `タイトル`,
  [`TlS`]: `TLS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS クライアント`,
  [`TLS Client Profile`]: `TLS クライアント・プロファイル`,
  [`TLS Client Profile has been configured.`]: `TLS クライアント・プロファイルが構成されました。`,
  [`TLS client profile is required when endpoint starts with https://`]: `エンドポイントが https:// で始まる場合は、TLS クライアント・プロファイルが必要です。`,
  [`TLS Client Profiles`]: `TLS クライアント・プロファイル`,
  [`TLS client profile has been configured.`]: `TLS クライアント・プロファイルが構成されました。`,
  [`TLS client profiles`]: `TLS クライアント・プロファイル`,
  [`TLS Profile`]: `TLS プロファイル`,
  [`TLS SERVER PROFILE`]: `TLS サーバー・プロファイル`,
  [`TLS server profile`]: `TLS サーバー・プロファイル`,
  [`TLS version 1.0`]: `TLS バージョン 1.0`,
  [`TLS version 1.1`]: `TLS バージョン 1.1`,
  [`TLS version 1.2`]: `TLS バージョン 1.2`,
  [`TLS version 1.3`]: `TLS バージョン 1.3`,
  [`TLS client profile`]: `TLS クライアント・プロファイル`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `TLS クライアント・プロファイルは、スペースが有効な場合は各スペース内で管理されます。 スペース設定にアクセスして TLS クライアント・プロファイルを管理してください。`,
  [`TLS profile`]: `TLS プロファイル`,
  [`TLS profile is not visible or not available.`]: `TLS プロファイルが表示されていないか使用できません。`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `認証 URL へのデータ送信をセキュアにするために使用する TLS プロファイル。`,
  [`Tabs for code language options`]: `コード言語オプションのタブ`,
  [`Truststore`]: `トラストストア`,
  [`truststore`]: `トラストストア`,
  [`Type`]: `タイプ`,
  [`Type name`]: `タイプ名`,
  [`Type/Field`]: `タイプ/フィールド`,
  [`Type weight`]: `タイプの重み`,
  [`Types per page:`]: `ページごとのタイプ数:`,
  [`Tags`]: `タグ`,
  [`Tags and external documentation`]: `タグと外部ドキュメンテーション`,
  [`Take offline`]: `オフラインにする`,
  [`Target`]: `ターゲット`,
  [`Target API (URL)`]: `ターゲット API (URL)`,
  [`Target SOAP service`]: `ターゲット SOAP サービス`,
  [`Target Services`]: `ターゲット・サービス`,
  [`Target service URL`]: `ターゲット・サービス URL`,
  [`Target services`]: `ターゲット・サービス`,
  [`Target URL`]: `ターゲット URL`,
  [`Target application`]: `ターゲット・アプリケーション`,
  [`Target catalog`]: `ターゲット・カタログ`,
  [`Target gateway service`]: `ターゲット・ゲートウェイ・サービス`,
  [`Target gateway services`]: `ターゲット・ゲートウェイ・サービス`,
  [`Target plan`]: `ターゲット・プラン`,
  [`Target product`]: `ターゲット製品`,
  [`Target product rate limit`]: `ターゲット製品のレート制限`,
  [`Target product subscribability`]: `ターゲット製品のサブスクライブ可能性`,
  [`Target product visibility`]: `ターゲット製品の可視性`,
  [`Target schema`]: `ターゲット・スキーマ`,
  [`Target services have been updated.`]: `ターゲット・サービスが更新されました。`,
  [`Task`]: `タスク`,
  [`Task has been updated`]: `タスクが更新されました`,
  [`Task self approval`]: `タスクの自己承認`,
  [`Task self-approval`]: `タスクの自己承認`,
  [`task-consumer-onboard-request`]: `コンシューマー・オンボード要求タスク`,
  [`task-product-lifecycle-approved`]: `製品ライフサイクルの承認済みタスク`,
  [`task-product-lifecycle-cancelled`]: `製品ライフサイクルのキャンセル済みタスク`,
  [`task-product-lifecycle-denied`]: `製品ライフサイクル拒否タスク`,
  [`task-product-lifecycle-pending`]: `製品ライフサイクルの保留タスク`,
  [`task-product-lifecycle-request`]: `製品ライフサイクル要求タスク`,
  [`task-subscription-approved`]: `サブスクリプション承認済みタスク`,
  [`task-subscription-cancelled`]: `サブスクリプション・キャンセル済みタスク`,
  [`task-subscription-denied`]: `サブスクリプション拒否タスク`,
  [`task-subscription-pending`]: `サブスクリプションの保留タスク`,
  [`task-subscription-request`]: `サブスクリプション要求タスク`,
  [`Tasks`]: `タスク`,
  [`Tasks for creating new provider organizations`]: `新規プロバイダー組織を作成するためのタスク`,
  [`TCP`]: `TCP`,
  [`Template`]: `テンプレート`,
  [`Template: {title}`]: `テンプレート: {title}`,
  [`Template: account approved`]: `テンプレート: 承認されたアカウント`,
  [`Template: account denied`]: `テンプレート: 拒否されたアカウント`,
  [`Template: account pending approval`]: `テンプレート: 承認保留中のアカウント`,
  [`Template: app lifecycle approved`]: `テンプレート: 承認されたアプリケーション・ライフサイクル`,
  [`Template: app lifecycle denied`]: `テンプレート: 拒否されたアプリケーション・ライフサイクル`,
  [`Template: app lifecycle pending`]: `テンプレート: 保留中のアプリケーション・ライフサイクル`,
  [`Template: app reinstated`]: `テンプレート: 復元されたアプリケーション`,
  [`Template: app suspended`]: `テンプレート: 中断されましたアプリケーション`,
  [`Template: app lifecycle cancelled`]: `テンプレート: キャンセルされたアプリケーション・ライフサイクル`,
  [`Template: app lifecycle request`]: `テンプレート: アプリケーション・ライフサイクルの要求`,
  [`Template: invitation`]: `テンプレート: 招待`,
  [`Template: mail server test connection`]: `テンプレート: メール・サーバーのテスト接続`,
  [`Template: member invitation`]: `テンプレート: メンバーの招待`,
  [`Template: password changed`]: `テンプレート:変更されたパスワード`,
  [`Template: password reset`]: `テンプレート: リセットされたパスワード`,
  [`Template: sign up`]: `テンプレート: 登録`,
  [`Template: task consumer onboard request`]: `テンプレート: コンシューマー・オンボードの要求タスク`,
  [`Template: task product lifecycle approved`]: `テンプレート: 製品ライフサイクルの承認されたタスク`,
  [`Template: task product lifecycle cancelled`]: `テンプレート: 製品ライフサイクルのキャンセルされたタスク`,
  [`Template: task product lifecycle denied`]: `テンプレート: 製品ライフサイクルの拒否されたタスク`,
  [`Template: task product lifecycle pending`]: `テンプレート: 製品ライフサイクルの保留中のタスク`,
  [`Template: task product lifecycle request`]: `テンプレート: 製品ライフサイクルの要求タスク`,
  [`Template: task subscription cancelled`]: `テンプレート: サブスクリプションのキャンセルしたタスク`,
  [`Template: task subscription request`]: `テンプレート: サブスクリプションの要求タスク`,
  [`Template: task subscription approved`]: `テンプレート: サブスクリプションの承認したタスク`,
  [`Template: task subscription denied`]: `テンプレート: サブスクリプションの拒否したタスク`,
  [`Template: task subscription pending`]: `テンプレート: サブスクリプションの保留中のタスク`,
  [`Temporarily disable subscribability`]: `一時的にサブスクライブ不可にする`,
  [`Temporarily disable visibility`]: `一時的に表示不可にする`,
  [`Terms of service`]: `サービスのご利用条件`,
  [`Test`]: `テスト`,
  [`Test API`]: `API のテスト`,
  [`Test APIs`]: `API のテスト`,
  [`Test Bind & Get Base DN`]: `バインドのテストと基本 DN の取得`,
  [`Test configuration`]: `構成のテスト`,
  [`Test connection`]: `接続のテスト`,
  [`Test connection failed.`]: `接続のテストに失敗しました。`,
  [`Test and production endpoints`]: `テスト・エンドポイントと実動エンドポイント`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `テストとモニターの応答エラー: redirect_uri prop が必要ですが、{redirectUri} を取得しました`,
  [`Test application`]: `テスト・アプリケーション`,
  [`Test consumer org`]: `テスト・コンシューマー組織`,
  [`Test email`]: `E メールのテスト`,
  [`Test endpoints`]: `テスト・エンドポイント`,
  [`Test message from IBM API Connect`]: `IBM API Connect からのテスト・メッセージ`,
  [`Testable`]: `テスト可能`,
  [`TEXT`]: `テキスト`,
  [`The "Definition" property must have a valid value.`]: `「定義」プロパティーには有効な値が含まれていなければなりません。`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `「定義」プロパティーの値は「request」か「response」であるか、「#/definitions/」で開始する必要があります。`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `API Connect CLI は IBM Cloud CLI プラグインとして提供されています。 開始するには、以下のようにします。`,
  [`The API has unsaved changes.`]: `API には、保存されていない変更があります。`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `API は、完全に設計され、内部のマイルストーンを通過しましたが、まだ実装されていません。`,
  [`The API is in the implementation phase.`]: `API は実装フェーズにあります。`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `API は、初期の概念的なフェーズにあり、完全には設計されておらず、実装もされていません。`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `この API には、未公開の変更が含まれている可能性があります。 製品を再公開しますか?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `DNS スキームにより、ゲートウェイ・サービスに対するインバウンド API 呼び出しおよび開発者ポータルへのアクセスのためのエンドポイント URL の構成が決まります。`,
  [`The GatewayScript source code to execute.`]: `実行する GatewayScript ソース・コード。`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `GraphQL ペイロードの検証対象にする必要がある GraphQL スキーマ URL。`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `呼び出しに使用する HTTP メソッド。 省略するか「保持」に設定した場合は、着信要求のメソッドが使用されます。`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `現在の HTTP メソッドを置き換えるために使用する HTTP メソッド。 省略するか「保持」に設定した場合は、着信要求のメソッドが使用されます。`,
  [`The JavaScript source code to execute.`]: `実行する JavaScript ソース・コード。`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `LDAP (Lightweight Directory Access Protocol) は、ネットワーク経由で分散ディレクトリー情報サービスへのアクセス、およびその保守を行うためのインターネット・プロトコルです。 エンタープライズ LDAP サービスが有効な場合は、ユーザー認証を提供するリソースとして構成してください。`,
  [`The Proxy URL to be used.`]: `使用するプロキシー URL。`,
  [`The SSL Profile to use for the secure transmission of data.`]: `データ送信をセキュアにするために使用する SSL プロファイル。`,
  [`The TLS Profile to use for the secure transmission of data.`]: `データ送信をセキュアにするために使用する TLS プロファイル。`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `JSON ペイロードを検証するための JSON スキーマの URL。`,
  [`The URL of the XML schema for validating an XML payload.`]: `XML ペイロードを検証するための XML スキーマの URL。`,
  [`The URL to be invoked.`]: `呼び出される URL です。`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `SOAP ペイロードの検証対象にする必要がある WSDL スキーマ URL。`,
  [`The XML element name to be used for JSON array elements.`]: `JSON 配列エレメントに使用される XML エレメント名です。`,
  [`The XSLT source to execute.`]: `実行する XSLT ソース。`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `アクティベーション・リンクをユーザーに送信して、ユーザーが自分のアカウントをアクティブにできるようにします`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `分析サービスは、ゲートウェイ・サービスから API イベントを収集します。 各ゲートウェイには分析サービスが関連付けられます。 API 操作が呼び出されると、API イベントがタイム・スタンプとともに記録されます。`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `分析サービスは、ゲートウェイ・サービスから API イベントを収集します。 各ゲートウェイ・サービスに分析サービスを関連付けることができます。`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `許可 URL は、クライアント・アプリケーションが権限付与を取得する場所です。 トークン URL は、クライアント・アプリケーションがアクセス・トークンの権限付与を交換する場所です。  イントロスペクション URL は、API ゲートウェイによりサード・パーティー・プロバイダーが発行したアクセス・トークンが検証される場所です。`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `基本パスは API の最初の URL セグメントであり、ホスト名や、パスおよび操作の追加セグメントは含まれません。`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `キャッシュ・タイプにより、文書をキャッシュするかどうかが決まります。サーバーの応答から受信した HTTP キャッシュ制御ディレクティブが優先されるか、またはオーバーライドされます。 このプロパティーは、サーバーから応答を受信した場合にのみ有効になります。サーバーから応答を受信しなかった場合、ポリシーは常に、以前にキャッシュに保存されていた有効期限の切れていない応答を返します。`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `証明書マネージャー・サービスは、ゲートウェイ管理エンドポイントのドメインおよびゲートウェイで処理するドメインの証明書を管理する必要があります。 また、IBM Cloud IAM を使用して、証明書マネージャー・サービスへのアクセスを API Connect Reserved Instance サービスに許可する必要があります。`,
  [`The certificate with which to decrypt the buffer`]: `バッファーを暗号化解除する際に使用される証明書`,
  [`The certificate with which to encrypt the buffer`]: `バッファーを暗号化する際に使用される証明書`,
  [`The change is critical`]: `重大な変更です`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `クライアント・アプリケーションで要求できるのは、ここに定義されたスコープまたはスコープのサブセットのみです。 これらのスコープは、プロバイダーから生成されるアクセス・トークンに組み込まれます。 OAuth で保護された API が呼び出されると、アクセス・トークン内に設定されたスコープが、ゲートウェイによりセキュリティー定義内の許可スコープのリストと照らして検査され、アクセス権を付与するかどうかが決定されます。 さらに、拡張スコープ検査も適用できます。 拡張スコープ検査の URL は、アプリケーション認証の後またはユーザー認証の後に、どちらの URL が構成されているかに基づいて呼び出されます。 すべてのスコープ検査の結果、アクセス・トークンによって最終的なスコープの権限が付与されます。`,
  [`The crypto object to use to decode the claim.`]: `クレームをデコードするために使用する暗号オブジェクト。`,
  [`The crypto object to use to encrypt the message.`]: `メッセージを暗号化するために使用する暗号オブジェクト。`,
  [`The crypto object to use to sign the JWT.`]: `JWT に署名するために使用する暗号オブジェクト。`,
  [`The crypto object to use to verify the signature.`]: `署名を検証するために使用する暗号オブジェクト。`,
  [`The current mapping contains references to unrecognized schema elements.`]: `現在のマッピングには、認識されないスキーマ・エレメントへの参照が含まれています。`,
  [`The current organization does not contain any catalogs.`]: `現在の組織にはカタログが含まれていません。`,
  [`The default User registry must be included in the list`]: `デフォルトのユーザー・レジストリーをリストに含める必要があります`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `ペイロードの検証対象にする必要がある定義 ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `開発者ポータルは、公開済みの API 製品のコンシューマー・ビューを提供します。 カタログ用の開発者ポータルを構成した後、API 製品が公開されると、アプリケーション開発者は API を参照して使用できます。`,
  [`The document is missing draft_api.info.version field.`]: `文書に draft_api.info.version フィールドがありません。`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `文書に draft_api.info.x-ibm-name フィールドがありません。`,
  [`The document is missing draft_product.info.name field.`]: `文書に draft_product.info.name フィールドがありません。`,
  [`The document is missing draft_product.info.version field.`]: `文書に draft_product.info.version フィールドがありません。`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `追加の制約では、このフィールドの引数ではないスライス引数を指定します。`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `このフィールドには、デフォルト値を持つ制限引数があります。 そのため、指定された想定サイズは適用できません。`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `このフィールドには、デフォルト値を持つか必須である制限引数があります。 そのため、指定された想定サイズは適用できません。`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `このフィールドには、想定サイズもスライス引数もありません。`,
  [`This field returns unbound list of values with composite type.`]: `このフィールドは、複合タイプの値のアンバウンド・リストを返します。`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `このフィールドは、スカラー・タイプの値のアンバウンド・リストを返します。タイプの重みは != 0.0 です。`,
  [`This test panel is deprecated and will be removed soon.`]: `このテスト・パネルは非推奨であり、間もなく削除されます。`,
  [`This type has no fields or no matching field.`]: `このタイプにはフィールドも一致するフィールドもありません。`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `この GatewayScript ポリシーでは、古い API のみをマイグレーションするための apim モジュールは使用しないようにします。`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `この XSLT ポリシーは、古い API をマイグレーションするためだけの apim モジュールを使用しないようにします。`,
  [`This type will be deleted.`]: `このタイプは削除されます。`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `次の図は、API のアセンブリー・フローを示しています。 アセンブリーを変更するには、アセンブリー・エディターを使用します。`,
  [`The following files are available under the`]: `以下のファイルは次の下で使用できます:`,
  [`The following files contain a private key and cannot be uploaded:`]: `次のファイルには秘密鍵が格納されているため、アップロードできません。`,
  [`The following is available under the`]: `以下は次の下で使用できます:`,
  [`The following lifecycle actions require approval`]: `以下のライフサイクル・アクションには承認が必要です`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `このプロバイダー組織で作成されたドラフト API を以下のリストに示します`,
  [`The following list displays the draft products that have been created in this provider organization`]: `このプロバイダー組織で作成されたドラフト製品を以下のリストに示します`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `次のリストは、このカタログのメンバーとして追加されたすべてのユーザーと、それらのユーザーに割り当てられたロールを示しています`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `次のリストは、このスペースのメンバーとして追加されたすべてのユーザーと、それらのユーザーに割り当てられたロールを示しています`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `次のパラメーターは、OAuth プロバイダーで構成された値と一致しません`,
  [`The following roles are available in the Cloud Manager`]: `Cloud Manager で使用可能なロールは以下のとおりです`,
  [`The following types/fields will also be removed:`]: `次のタイプ/フィールドも削除されます:`,
  [`The following types/fields will be affected:`]: `次のタイプ/フィールドが影響を受けます:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `ゲートウェイ・プロパティー値 {gatewayType} は許可されていません。 許容値は datapower-gateway と datapower-api-gateway です`,
  [`The host used to invoke the API`]: `API を呼び出すために使用されるホスト`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `鍵ストアには、身元の確認と HTTPS を介したデータ伝送の暗号化/復号に使用する公開証明書と秘密鍵の一致するペアが含まれます。`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `鍵ストアには、身元の確認と HTTPS を介したデータ伝送の暗号化/復号に使用する公開証明書と秘密鍵の一致するペアが含まれます。 サーバー・プロファイルには鍵ストアが必要です。`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `鍵ストアには、身元確認のためにサーバーまたはクライアントが提示するプライベート証明書および公開鍵が格納されます。`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `LTPA 鍵が有効とみなされる期間として、現在の日時に追加される時間の長さ (秒)。`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `JWT が有効とみなされる期間として、現在の日時に追加される時間の長さ (秒)。`,
  [`The message to be sent with the error`]: `エラーとともに送信されるメッセージ`,
  [`The message to be included within exception.`]: `例外に組み込まれるメッセージ。`,
  [`The HTTP status code to be included within exception.`]: `例外に組み込まれる HTTP 状況コード。`,
  [`The HTTP status reason phrase to be included within exception.`]: `例外に組み込まれる HTTP 状況の理由フレーズ。`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Datapower 構成で定義されたバースト制限の名前。`,
  [`The name of a count limit defined in the datapower configuration.`]: `Datapower 構成で定義されたカウント制限の名前。`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Datapower 構成で定義されたレート制限の名前。`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `要求からの応答データを保管するために使用する変数の名前。 その後、「マップ」などの他のアクションで参照できます。`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `結果を格納するために使用される変数の名前。 デフォルトでは、「message」が使用されます。`,
  [`The name of parameter to find the ID.`]: `ID を検索するパラメーターの名前。`,
  [`The name of parameter to find the secret.`]: `秘密を検索するパラメーターの名前。`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `LTPA トークンを生成する際に使用する LTPA 鍵の名前。 特定のバージョンの鍵を使用する場合は、<keyname>:<version> の形式で指定します。 例えば、my-ltpa-key:1.0.1 のように指定します。 使用する最新バージョンの鍵をポリシーで自動的に選択する場合は、<keyname>:latest の形式で指定します。 例えば、my-ltpa-key:latest のように指定します。`,
  [`The name of the error to be thrown`]: `スローされるエラーの名前。`,
  [`The name of the variable to be added.`]: `追加する変数の名前。`,
  [`The name of the variable to be cleared.`]: `クリアする変数の名前。`,
  [`The name of the variable to be set.`]: `設定する変数の名前。`,
  [`The nature of the XML validation to be performed.`]: `実行される XML 検証の性質。`,
  [`The operation to apply.`]: `適用する操作`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `このマッピングの出力は配列内にあり、選択した入力を反復処理することでそのコンテンツが作成されます。 選択した入力が配列ではない場合、長さが 1 の配列が生成されます。`,
  [`The owner must be an existing user in your user registry`]: `所有者は、ユーザー・レジストリー内の既存のユーザーでなければなりません`,
  [`The owner of consumer organization has the following privileges:`]: `コンシューマー組織の所有者には、次の特権があります。`,
  [`The owner of provider organization has the following privileges:`]: `プロバイダー組織の所有者には、次の特権があります。`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `所有者の責務としては、API 製品のライフサイクルの管理、1 つ以上の API プロバイダー組織の管理、アプリケーション・プロバイダー・コミュニティーの管理、API および製品の定義の作成などがあります。`,
  [`The page you are looking for might have been moved or does not exist.`]: `お探しのページは、移動されたか存在しない可能性があります。`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `お探しのページは、移動されたか存在しない可能性があります。 管理者に連絡してサポートを依頼してください。`,
  [`The parameters needed to send a valid API call.`]: `有効な API 呼び出しを送信するために必要なパラメーター。`,
  [`The password to use for HTTP Basic authentication.`]: `HTTP 基本認証で使用するパスワード。`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `ポータル・サービスは、アプリケーション開発者が API を見つけてコンシューマーをオンボーディングするために使用する開発者ポータルを提供します。`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `ポータル・サービスは、アプリケーション開発者が API を見つけて実装するために使用する開発者ポータルを提供します。`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `処理状況には、ゲートウェイの正常性と最近のアクティビティーの要約のビューが示されます。`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `受信者は所有者として登録し、新規プロバイダー組織を作成します。 登録が完了すると、ユーザーはプロバイダー組織の所有者として API Manager にログインできます。`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `結果の XML 文書のルート・エレメント名。 階層構造になっていない入力 JSON 文書に複数の最上位プロパティーが含まれている場合にのみ必要です。`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `ここのスコープは、開発者が何にアクセスできるかを確認するためのもので、スコープ検査には影響しません。`,
  [`The selected catalog will only be used to search consumer organizations`]: `選択されたカタログはコンシューマー組織の検索のみに使用されます`,
  [`The selected catalog does not have any configured gateways`]: `選択されたカタログにはゲートウェイが構成されていません`,
  [`The selected catalog does not have any compatible gateways`]: `選択されたカタログには互換性のあるゲートウェイがありません`,
  [`Save Preferences`]: `設定の保存`,
  [`Preferences`]: `設定`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `選択されたカタログはコンシューマー組織とコンシューマー・グループの検索のみに使用されます'`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `選択したゲートウェイ・タイプを使用すると、アセンブリー内の次のポリシーが無効としてレンダリングされます。 この API を実行するには、これらのポリシーを削除しておく必要があります。`,
  [`The selected product does not contain this API`]: `選択された製品にはこの API が含まれていません`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `選択した製品は、コンシューマー組織またはグループの特定のリストでサブスクライブ可能になります。`,
  [`The selected services will be available to a specific list of provider organizations.`]: `選択したサービスは、プロバイダー組織の特定のリストで使用可能になります。`,
  [`The selected services will be available to all existing and new provider organizations.`]: `選択したサービスは、すべての既存のプロバイダー組織と新規プロバイダー組織で使用可能になります。`,
  [`The selected services will not be available to any provider organizations.`]: `選択したサービスは、どのプロバイダー組織でも使用できません。`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `キャッシュのキーとして使用するストリング。 省略した場合、URL ストリング全体がキーとして使用されます。`,
  [`The server responded with a non-JSON body \n{body}`]: `サーバーが非 JSON 本文で応答しました \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `時間間隔は、アクセス・トークンの有効期間よりも長くなければなりません。`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `このトークンは、Cookie にもローカル・ストレージにも保存されません。 テスト・パネルを閉じるとこのトークンが失われますが、有効性は維持される可能性があります。`,
  [`The transfer protocol of the API.`]: `API の転送プロトコル。`,
  [`The type of authentication.`]: `認証のタイプ。`,
  [`The type of authorization header.`]: `許可ヘッダーのタイプ。`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `設定する値のタイプ。 これは任意、ストリング、数値、またはブール値にすることができます。`,
  [`The type of the value to set. This can be string, number or boolean.`]: `設定する値のタイプ。 ストリング、数値、またはブール値にすることができます。`,
  [`The username to use for HTTP Basic authentication.`]: `HTTP 基本認証で使用するユーザー名。`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `この接続のユーザーはもう有効ではありません。 新規接続を作成してください。`,
  [`The value that the variable will be set to.`]: `変数に設定する値。`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `このフィールドの値は、想定サイズもスライス引数もない複合タイプのリストです。 検出された場合、API Gateway は、GraphQL サーバーが照会を受け取った場合に返す可能性があるオブジェクトの数に対して制限を設定できません。`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `このフィールドの値は、想定サイズもスライス引数もないスカラー型のリストです。 検出された場合、API Gateway は、GraphQL サーバーが照会を受け取った場合に返す可能性があるオブジェクトの数に対して制限を設定できません。`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `バージョンは URL セーフにする必要があります。 オブジェクトのバージョン {version} とスラッグ化されたバージョン {slugVersion} が一致しません。`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `「name」プロパティーは URL セーフでなければなりません (オブジェクトの名前の値「{name}」と、指定された名前の値「{slugName}」が一致している必要があります )。`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `「draft_api」プロパティーには OpenAPI 定義が含まれている必要があります。 OpenAPI バージョン 3 では、バージョン 3.0.0 - 3.0.3 のみがサポートされます (指定されたバージョン: {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `名前フィールドには、ユーザーが指定するタイトルに基づいて値が自動的に入力されます。 結果の値を API Connect コマンドおよび API 呼び出し内部で使用してください。`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `可視性の設定により、リソースにアクセスできるプロバイダー組織が決定されます。 選択肢は、「公開」(リソースはすべてのプロバイダー組織で使用可能)、「カスタム」(リソースはユーザーが指定したプロバイダー組織でのみ使用可能)、および「非公開」(リソースは表示されず、どのプロバイダー組織でも使用できません) です。`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `可視性の設定により、サービスを使用できるプロバイダー組織が決定されます。 選択肢は、「公開」(サービスはすべてのプロバイダー組織で使用可能)、「カスタム」(サービスはユーザーが指定したプロバイダー組織でのみ使用可能)、および「非公開」(サービスは表示されず、どのプロバイダー組織でも使用できません) です。`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `可視性の設定により、ゲートウェイを使用できるプロバイダー組織が決定されます。 可視性レベルには以下が含まれます。「公開」(ゲートウェイはすべてのプロバイダー組織で使用可能)、「カスタム」(ゲートウェイはユーザーが指定したプロバイダー組織でのみ使用可能)、および「非公開」(ゲートウェイは表示されず、どのプロバイダー組織でも使用できません)。`,
  [`The {title} role`]: `{title} ロール`,
  [`The {url} resource does not exist`]: `{url} リソースは存在しません`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `この製品とゲートウェイ・タイプが異なるために互換性のない API があります。`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `次に、管理エンドポイントのドメインの証明書を選択します。 その証明書が既知の認証局が割り当てたものでない場合は、対応する CA バンドルも選択してください。 証明書とバンドルは、証明書マネージャー・サービスで管理する必要があります。`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `次に、このゲートウェイによって処理するドメインを指定します。 SNI 技法が使用されます。その結果、同一の IP アドレスとポートを使用して複数のドメインに対するサービスを提供できます。証明書が同一である必要はありません。 ワイルドカード・フォーマットがサポートされています。 テーブルの最終項目に、デフォルトの (キャッチオール) ドメインとして「*」を含める必要があります。 他のドメイン名は、証明書マネージャー・サービスで管理する証明書を指定するたびに、必要に応じて指定します。`,
  [`There should be at least {minSelected} {label} selected.`]: `少なくとも {minSelected} 個の {label} が選択されている必要があります。`,
  [`There are no API changes to publish.`]: `公開する API の変更はありません。`,
  [`There are no gateway services`]: `ゲートウェイ・サービスがありません`,
  [`There are no assemblies that match the chosen filters.`]: `選択したフィルターに一致するアセンブリーがありません。`,
  [`There are no burst limits yet.`]: `まだバースト制限がありません。`,
  [`There are no catalogs`]: `カタログがありません`,
  [`There are no consumer organizations.`]: `コンシューマー組織がありません。`,
  [`There are no consumer organizations. You can create one`]: `コンシューマー組織がありません。 分析サービスを作成できます`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `コンシューマー組織がありません。 [こちら]({link}) で作成できます`,
  [`There are no email servers`]: `E メール・サーバーがありません`,
  [`There are no gateway services configured for the sandbox catalog`]: `サンドボックス・カタログに対して構成されたゲートウェイ・サービスがありません`,
  [`There are no lifecycle approvals enabled`]: `有効なライフサイクル承認はありません`,
  [`There are no new changes.`]: `新しい変更はありません。`,
  [`There are no products to display`]: `表示する製品がありません`,
  [`There are no products to replace`]: `置き換える製品がありません`,
  [`There are no products to set migration target`]: `マイグレーション・ターゲットを設定する製品がありません`,
  [`There are no products to supersede`]: `取り替える製品がありません`,
  [`There are no properties to configure for this policy`]: `このポリシーには構成するプロパティーがありません`,
  [`There are no rate limits yet.`]: `まだレート制限がありません。`,
  [`There are no security definitions`]: `セキュリティー定義がありません`,
  [`There are no spaces`]: `スペースがありません`,
  [`There are no target services`]: `ターゲット・サービスがありません`,
  [`There are no tasks to be displayed`]: `表示するタスクがありません`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `未保存の変更があります。 続行してよろしいですか?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `未保存の変更があります。 続行すると、破棄されます。 よろしいですか?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `存在していないためにリストできない API が YAML にあります。ソース・タブを確認してください。`,
  [`There is no item in the list.`]: `リストに項目がありません。`,
  [`There is no space`]: `スペースがありません`,
  [`There is no warning in the schema.`]: `スキーマに警告はありません。`,
  [`There was no data found to populate the data table.`]: `データ・テーブルに入力するデータが見つかりませんでした。`,
  [`These endpoints are used for all the APIs in the catalog`]: `これらのエンドポイントがカタログ内のすべての API で使用されます`,
  [`Third party OAuth provider`]: `サード・パーティー OAuth プロバイダー`,
  [`Third party OAuth provider summary`]: `サード・パーティー OAuth プロバイダーの要約`,
  [`Third party`]: `サード・パーティー`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `この API には、アセンブリーがまだ含まれていません。 作成しますか?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `このリソースはもう存在しません。サーバーからの結果を最新表示してください。`,
  [`Please refresh the results from the server`]: `サーバーからの結果を最新表示してください`,
  [`Exact match`]: `完全一致`,
  [`Closest match`]: `最も近似した一致項目`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `この API は現在製品 {productName} で使用されています。 製品から API を削除してから API の削除を続行してください。`,
  [`This API will be available to be invoked when the following option is enabled.`]: `この API は、次のオプションが有効な場合に呼び出すことができます。`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `このカタログは自動サブスクリプションが有効になっていません。 このカタログでテストするには、以下の製品、プラン、アプリケーションを選択してください。`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `このエンドポイントは、基本エンドポイントが定義されていない API のデフォルトとして使用されます`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `これは技術プレビュー機能であり、今後、進化させると同時に、継続的な改善を行っていきます。`,
  [`This is the currently configured notification server.`]: `現在構成されている通知サーバーです。`,
  [`This is the directory where your files including policies and extensions will be stored`]: `これは、ポリシーと拡張を含むファイルが格納されるディレクトリーです`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `ここで API が実行され、ポリシーと拡張にアクセスできます`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `この oauth ポリシーは、デフォルトで、発行されたパスに必要なすべての OAuth プロトコルのステップを実行します。 各ステップの入出力は、記載されたコンテキスト変数によって駆動されます。 必要に応じて、サポートされる OAuth コンポーネントを追加または削除してください。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `この oauth ポリシーは、デフォルトで、OAuth 検証に必要なすべての OAuth/OpenID Connect プロトコルのステップを実行します。 各ステップの入出力は、記載されたコンテキスト変数によって駆動されます。 必要に応じて、サポートされる OAuth コンポーネントを追加または削除してください。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `この oauth ポリシーは、デフォルトで、その他すべてのパスに必要なすべての OAuth/OpenID Connect プロトコルのステップを実行します。 各ステップの入出力は、記載されたコンテキスト変数によって駆動されます。 必要に応じて、サポートされる OAuth コンポーネントを追加または削除してください。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `この oauth ポリシーは、デフォルトで、az コード・パスに必要なすべての OAuth/OpenID Connect プロトコルのステップを実行します。 各ステップの入出力は、記載されたコンテキスト変数によって駆動されます。 必要に応じて、サポートされる OAuth コンポーネントを追加または削除してください。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `この oauth ポリシーは、デフォルトで、トークン・パスに必要なすべての OAuth/OpenID Connect プロトコルのステップを実行します。 各ステップの入出力は、記載されたコンテキスト変数によって駆動されます。 必要に応じて、サポートされる OAuth コンポーネントを追加または削除してください。`,
  [`This operation is secured with access code flow OAuth`]: `この操作は、アクセス・コード・フロー OAuth で保護されています`,
  [`This operation is secured with application flow OAuth`]: `この操作は、アプリケーション・フロー OAuth で保護されています`,
  [`This operation is secured with basic authentication`]: `この操作は、基本認証で保護されています`,
  [`This operation is secured with implicit flow OAuth`]: `この操作は、暗黙的なフロー OAuth で保護されています`,
  [`This operation is secured with password flow OAuth`]: `この操作は、パスワード・フロー OAuth で保護されています`,
  [`This policy is not available on the selected gateway`]: `選択したゲートウェイではこのポリシーを使用できません。`,
  [`This policy is used by one other operation`]: `このポリシーは、他の 1 つの操作で使用されます`,
  [`This policy is used by {{count}} other operations`]: `このポリシーは、他の {{count}} 件の操作で使用されています`,
  [`This role cannot be edited`]: `このロールは編集できません`,
  [`This role cannot be edited.`]: `このロールは編集できません。`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `この設定は、マッピングの入力が存在せず、デフォルトのマッピングが構成されていないためにマッピングが失敗した場合の動作を制御します。 デフォルトの動作では、出力は変更されませんが、このチェック・ボックスを選択すると、バージョン 4 の動作がエミュレートされ、ターゲット・マッピングの親に対して空のオブジェクトが作成されます。`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `このユーザー・セキュリティー・ポリシーは、oauth アセンブリーに対して EI (基本) 検査と AU (認証 URL) 検査を実行します。 必要に応じてセキュリティー検査方式を変更してください。`,
  [`This will enable/disable application lifecycle`]: `これにより、アプリケーション・ライフサイクルが有効/無効になります`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `これにより、タスクの自己承認が有効/無効になります。 タスクの自己承認によって、オリジネーターのほか、コラボレーターもタスクを承認できるようになります。 このオプションは他の承認者がいない場合に便利ですが、実質的に別のユーザーによる確認を省略できます。`,
  [`This will unset the catalog as the default catalog.`]: `これにより、デフォルトのカタログとしてのカタログの設定が解除されます。`,
  [`Throw`]: `Throw`,
  [`Time`]: `時刻`,
  [`Time Interval`]: `時間間隔`,
  [`Time Limit`]: `時間制限`,
  [`Time to Live`]: `有効期間`,
  [`Time to live`]: `有効期間`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `応答がエンドポイントから戻ってくるのを待機する時間。 デフォルトは 60 秒です。`,
  [`Timeout`]: `タイムアウト`,
  [`To`]: `宛先`,
  [`to`]: `終わり`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `ユーザーを管理者組織のメンバーとして追加するには、ユーザーのユーザー・レジストリーを選択し、ユーザー名を入力して、割り当てるロールを選択します。 ユーザー・レジストリー・タイプが「ローカル・ユーザー・レジストリー」の場合は、新規ユーザーを作成してからメンバーとして追加するオプションも選択できます。 メンバーシップが直ちに有効になり、指定されたユーザーが Cloud Manager ユーザー・インターフェースにログインできるようになります`,
  [`To add a user as a member of a provider organization`]: `プロバイダー組織のメンバーとしてユーザーを追加する方法`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `ユーザーをカタログのメンバーとして追加するには、ユーザー名を選択して、必要なロールを割り当てます。 プロバイダー組織内で既に別のカタログのメンバーであるかスペースのメンバーであり、現在はこのカタログのメンバーでなく、カタログ所有者でもないユーザーを追加できます。`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `ユーザーをスペースのメンバーとして追加するには、ユーザー名を選択して、必要なロールを割り当てます。 プロバイダー組織内で既に別のカタログのメンバーであるかスペースのメンバーであり、現在はこのスペースのメンバーでなく、スペース所有者でもないユーザーを追加できます。`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `メンバーを追加または削除するには、[identity and access](/iam) サービスを使用します。`,
  [`To add members, use the [identity and access](/iam) service.`]: `メンバーを追加するには、[identity and access](/iam) サービスを使用します。`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `ユーザーを組織のメンバーとして追加するには、ユーザーのユーザー・レジストリーを選択し、ユーザー名を入力し、ロールのセットを割り当てます。`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `ユーザーをメンバーとして追加するには、ユーザー名を選択し、一連のロールを割り当てます。`,
  [`To assign default gateways, click **Edit**.`]: `デフォルトのゲートウェイを割り当てるには、「**編集**」をクリックします。`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `このソフトウェアの使用を続けるには、ソフトウェアのご使用条件の条項に同意する必要があります。`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `API Connect を引き続き使用するには、エンタープライズ・プランにアップグレードしてください。`,
  [`To get started, you must first enable spaces.`]: `開始するには、最初にスペースを有効にする必要があります。`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `ツールキットをインストールして実行するには、以下の手順を実行します。 [詳細情報]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `新規ユーザーを管理者組織のメンバーとして招待するには、そのユーザーの E メール・アドレスまたは名前を入力するか、ユーザー・レジストリーからそのユーザーの名前を選択します。  すべてのメンバーに自動的にメンバー・ロールが割り当てられます。このロールは、基本的な許可のセットを提供し、削除できません。 他の許可が不要な場合は、他のロールのチェック・マークは外したままにします。`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `ユーザー・レジストリー内にまだ存在しない所有者を招待するには、その所有者の E メール・アドレスを入力します`,
  [`To register a new gateway, visit the **Gateways** page.`]: `新規ゲートウェイを登録するには、「**ゲートウェイ**」ページにアクセスします。`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `値を以下のスペース・プロパティーのいずれかで置き換えるには、$() と入力し、括弧内にプロパティーの名前を指定します`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `管理者組織のメンバーとして登録するように招待する E メールを新規ユーザーに送信するには、有効な E メール・アドレスを入力して、ユーザーに割り当てるロールを選択します。 アクティベーション・リンクを含む登録招待の E メールがその E メール・アドレスに送信されます。 ユーザーは「保留」状況になりますが、登録フォームの入力が完了すると「使用可能」状況に変わり、アカウントがアクティブ化されます。 これにより、ユーザーは、アカウントのアクティベーション時に指定した API Connect ユーザー名を使用して、Cloud Manager ユーザー・インターフェースにログインできるようになります。`,
  [`Toggle`]: `切り替え`,
  [`Toggle application lifecycle?`]: `アプリケーション・ライフサイクルを切り替えますか?`,
  [`Toggle default catalog?`]: `デフォルトのカタログを切り替えますか?`,
  [`Toggle info panel between right and bottom`]: `情報パネルの表示位置を右と下とで切り替えます`,
  [`Toggle profile`]: `プロファイルの切り替え`,
  [`Toggle spaces?`]: `スペースを切り替えますか?`,
  [`Toggle task self-approval?`]: `タスクの自己承認を切り替えますか?`,
  [`Token`]: `トークン`,
  [`Token endpoint`]: `トークン・エンドポイント`,
  [`Token Expiry`]: `トークンの有効期限`,
  [`Token Management`]: `トークン管理`,
  [`Token Output`]: `トークンの出力`,
  [`Token URL`]: `トークン URL`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `トークン URL は、OAuth プロバイダー「{providerTitle}」で定義されている {endpoint} に一致する必要があります`,
  [`Token Version`]: `トークンのバージョン`,
  [`Token management`]: `トークン管理`,
  [`Token management type`]: `トークン管理タイプ`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `トークン管理により、リプレイ・アタックを防いだり、クライアント・アプリケーションやリソース所有者が OAuth トークンを取り消せるかどうか、およびその方法など、トークンを管理できます。 API Connect では、ネイティブ・ゲートウェイまたはサード・パーティー・エンドポイントを使用したトークン管理をサポートします。 ネイティブ・ゲートウェイの場合、トークンを管理するために割り当て制約が使用されます。 サード・パーティー・エンドポイントの場合、トークンを管理するための外部サービスへの URL を指定します。`,
  [`Token path`]: `トークン・パス`,
  [`Token revocation`]: `トークンの失効`,
  [`Token secret`]: `トークンの秘密`,
  [`Token validation`]: `トークン検証`,
  [`Tokens`]: `トークン`,
  [`Token relay`]: `トークン・リレー`,
  [`Toolkit credentials`]: `ツールキット資格情報`,
  [`Token Relay`]: `トークン・リレー`,
  [`Topology`]: `トポロジー`,
  [`Topology Administrator`]: `トポロジー管理者`,
  [`Total time`]: `合計時間`,
  [`Transfer Ownership to a New User`]: `新規ユーザーへの所有権の移転`,
  [`Transfer Ownership to an Existing User`]: `既存のユーザーへの所有権の移転`,
  [`Transfer ownership`]: `所有権の移転`,
  [`Transfer ownership to`]: `所有権の移転`,
  [`Transfer ownership to a new user`]: `新規ユーザーへの所有権の移転`,
  [`Transfer ownership to an existing user`]: `既存のユーザーへの所有権の移転`,
  [`Transforms`]: `変換`,
  [`Truststore Certificates`]: `トラストストア証明書`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `トラストストア証明書は、認証局によって発行される公開証明書です。`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `トラストストアには、検証済みの公開鍵が格納された信頼できる証明書が含まれます。 トラストストアの証明書は通常、サード・パーティーの認証局 (CA) から取得されます。`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `トラストストアには、信頼できる証明書と検証済みの公開鍵が格納されています。 トラストストアの証明書は通常、サード・パーティーの認証局 (CA) から取得されます。`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `トラストストアは、信頼できる証明書 (および検証済みの公開鍵) を格納します。 トラストストアの証明書は通常、サード・パーティーの認証局 (CA) から取得されます。`,
  [`Try again or upload a schema`]: `再試行するか、スキーマをアップロードしてください`,
  [`Try refreshing the page or contacting support.`]: `ページを最新表示してみるか、サポートにお問い合わせください。`,
  [`Turkish`]: `トルコ語`,
  [`Tutorials`]: `チュートリアル`,
  [`Types`]: `タイプ`,
  [`Type of user`]: `ユーザーのタイプ`,
  [`Type or select a catalog`]: `カタログを入力するか選択してください`,
  [`Type to add organizations`]: `追加する組織を入力`,
  [`TypeError: Failed to fetch`]: `TypeError: フェッチに失敗しました`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `管理エンドポイントの URL`,
  [`URLs for all operations in the API begin with this value.`]: `API 内のすべての操作の URL はこの値で始まります。`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `定義されているユーザー`,
  [`Userinfo POST`]: `Userinfo POST`,
  [`USER MANAGED`]: `ユーザー管理`,
  [`USERNAME`]: `ユーザー名`,
  [`User can delete the keystore history.`]: `ユーザーは鍵ストア履歴を削除できます。`,
  [`Unable to parse API definition: {msg}`]: `API 定義を解析できません: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `タイプまたはフィールド (あるいはその両方) を削除するときにエラーが検出されたため、続行できません。`,
  [`Unarchive`]: `解凍`,
  [`Unassociate`]: `関連付け解除`,
  [`Unassociate analytics service`]: `分析サービスの関連付け解除`,
  [`Unauthorized`]: `許可されていません`,
  [`Unauthorized API Request`]: `無許可の API 要求`,
  [`Unbound lists`]: `アンバウンド・リスト`,
  [`Unit`]: `単位`,
  [`Union`]: `和集合`,
  [`Union type`]: `共用体タイプ`,
  [`Unique email address`]: `固有の E メール・アドレス`,
  [`Unlimited`]: `無制限`,
  [`Unnecessary assumed size`]: `不要な想定サイズ`,
  [`Unnecessary slicing arguments`]: `不要なスライス引数`,
  [`Unrecognized Schema Element(s) Encountered`]: `認識されないスキーマ・エレメントが検出されました`,
  [`Unsupported media type ({type})`]: `サポートされないメディア・タイプ ({type})`,
  [`Untitled`]: `タイトルなし`,
  [`Update`]: `更新`,
  [`Update gateway services`]: `ゲートウェイ・サービスの更新`,
  [`Update assembly`]: `アセンブリーの更新`,
  [`Update group failed.`]: `グループの更新が失敗しました。`,
  [`Update schema defaults giving preference to`]: `スキーマのデフォルトを更新して、次の設定を優先:`,
  [`Update the sender details that are displayed on invitation emails`]: `招待 E メールに表示される送信者の詳細を更新します`,
  [`Update the summary details for this role`]: `このロールの要約の詳細を更新します`,
  [`Updating Draft API`]: `ドラフト API の更新中`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `スキーマのデフォルトを更新すると、変更内容がオーバーライドされます。 続行してよろしいですか?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `タイプ {existingType} の API をタイプ {type} の API で更新することは許可されません。`,
  [`Upload`]: `アップロード`,
  [`Upload a schema definition language file to replace your schema.`]: `スキーマを置き換えるスキーマ定義言語ファイルをアップロードしてください。`,
  [`Upload GraphQL schema`]: `GraphQL スキーマのアップロード`,
  [`Upload local`]: `ローカル・アップロード`,
  [`Upload policies`]: `ポリシーのアップロード`,
  [`Upload policy`]: `ポリシーのアップロード`,
  [`Upload schema`]: `スキーマのアップロード`,
  [`Upload schema definition language (SDL) file`]: `スキーマ定義言語 (SDL) ファイルをアップロードする`,
  [`Upload truststore certificates`]: `トラストストア証明書のアップロード`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `アプリケーションの X509 証明書を PEM 形式でアップロードします。 アップロードできる PEM ファイルは 1 つだけですが、1 つのファイルに複数の証明書を含めることができます。`,
  [`Upload the X509 certificate for your application, in PEM format`]: `アプリケーションの X509 証明書を PEM 形式でアップロードします`,
  [`Upload your public certificate`]: `公開証明書をアップロードします`,
  [`Uploaded and validated YAML file`]: `YAML ファイルがアップロードおよび検証されました`,
  [`Upload extension`]: `拡張をアップロード`,
  [`Use all compatible gateway services`]: `互換性のあるすべてのゲートウェイ・サービスを使用`,
  [`Use Content Type`]: `コンテンツ・タイプを使用`,
  [`Use DataPower API Gateway`]: `DataPower API Gateway の使用`,
  [`Use DataPower Gateway`]: `DataPower Gateway の使用`,
  [`Use Dynamic DNS`]: `動的 DNS を使用する`,
  [`Use JSONata to specify the property to redact or remove`]: `JSONata を使用して、編集または削除するプロパティーを指定します`,
  [`Use XPath to specify the property to redact or remove.`]: `XPath を使用して、編集または削除するプロパティーを指定します。`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `選択したカタログを、公開先のデフォルトのカタログとして使用します`,
  [`Use an existing JSON or YAML definition of the product`]: `製品の既存の JSON 定義または YAML 定義を使用します`,
  [`Use an existing definition of a REST proxy`]: `REST プロキシーの既存の定義を使用します`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `REST プロキシーまたは SOAP API の既存の定義を使用します`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `REST プロキシー、GraphQL プロキシー、または SOAP API の既存の定義を使用します`,
  [`Use an existing definition of a product`]: `製品の既存の定義を使用します`,
  [`Use context current payload`]: `コンテキストの現在のペイロードを使用`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `カスタマイズされたカタログのエンドポイント URL を使用 (v5 互換)`,
  [`Use dynamic DNS`]: `動的 DNS を使用`,
  [`Use endpoint from API`]: `API からのエンドポイントを使用`,
  [`Use gateway URLs`]: `ゲートウェイ URL を使用`,
  [`Use gateway service configured OAuth shared secret key`]: `ゲートウェイ・サービスで構成されている OAuth 共有秘密鍵を使用`,
  [`Use group authentication`]: `グループ認証を使用`,
  [`Use only first array element`]: `最初の配列エレメントのみを使用`,
  [`Use static DNS`]: `静的 DNS を使用`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `外部 OIDC プロバイダー・トラフィックのエンドポイントとしてポータルを使用`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `API Explorer を使用して、API のテストと探索を行います。 Explorer には、プロジェクト・ディレクトリーに含まれているすべての API の操作、定義、およびドキュメンテーションが示されます。 Explorer には、API を実行するためのテンプレート・コードとともに、パラメーター、モデル・インスタンス・データ、および応答コードがリストされます。`,
  [`Use the actions menu to manage and publish your APIs.`]: `API の管理および公開にはアクション・メニューを使用してください。`,
  [`Use the actions menu to manage and publish your products`]: `製品の管理および公開にはアクション・メニューを使用してください`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `分析に保管されている API アクティビティー用にロギングの設定を構成するには、activity-log ポリシーを使用します。 ユーザーが指定する設定は、API アクティビティーの詳細の収集および保管に関するデフォルトの設定値をオーバーライドします。`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `クライアント資格情報を抽出して認証するには、クライアント・セキュリティー・ポリシーを使用します。`,
  [`Use the default built-in Sandbox Catalog`]: `デフォルトの組み込みサンドボックス・カタログの使用`,
  [`Use the editor for manual input or upload a file below.`]: `エディターを使用して手動入力するか、下のファイルをアップロードします。`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `エディターを使用して手動入力するか、下のファイルをアップロードします。 XML/XSD ファイルが定義されている場合、現在のスキーマは XML でのみ編集できます。`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `施行されている拡張を使用して、API の施行に API Connect ゲートウェイを使用するかどうかを指定します`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `CLI を操作する際は、以下の管理エンドポイント URL を使用します。`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `API Designer でクラウド接続を作成する際は、以下の管理エンドポイント URL を使用します。`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `指定した DataPower GatewayScript プログラムを実行するには、gatewayscript ポリシーを使用します。`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `GraphQL スキーマをイントロスペクトするには、graphql-introspect ポリシーを使用します。`,
  [`Use the host value from Open API Definition`]: `Open API 定義のホスト値を使用します`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `条件が満たされている場合にアセンブリーの一部分を適用するには、if ポリシーを使用します。`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `指定した JavaScript プログラムを実行するには、javascript ポリシーを使用します。`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `ペイロードのコンテンツを JSON から XML に変換するには、json-to-xml ポリシーを使用します。`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `API のデフォルトのアクティビティー・ロギング構成をカスタマイズまたはオーバーライドするには、log ポリシーを使用します。`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `変換をアセンブリー・フローに適用し、変数間の関係を指定するには、map ポリシーを使用します。`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `API フロー内の各変数の間の関係を指定し、これらの変数に変換を適用するには、map ポリシーを使用します。`,
  [`Use the oauth policy to generate OAuth tokens.`]: `OAuth ポリシーを使用して OAuth トークンを生成します。`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `アセンブリーの一部分を特定の操作に適用するには、操作切り替えポリシーを使用します。`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `解析ポリシーを使用して、要求またはメッセージを XML または JSON として解析するか、またはバイナリー・データをバイナリー・ラージ・オブジェクト (BLOB) に解析します。`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `ランタイム変数をストリング値に設定したり、ランタイム変数の追加やクリアを行ったりするには、set-variable ポリシーを使用します。`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `アセンブリー・フローの実行中にエラーが発生した場合にエラーをスローするには、throw ポリシーを使用します。`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `ユーザー・セキュリティー・ポリシーを使用して、ユーザーの ID を抽出、認証、および許可します。`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `JSON スキーマまたは XML スキーマに対してアセンブリー・フローのペイロードを検証するには、DataPower Gateway で validate ポリシーを使用します。`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `ペイロードのコンテンツを XML から JSON に変換するには、xml-to-json ポリシーを使用します。`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `XSLT 変換をペイロードに適用するには、xslt ポリシーを使用します。`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `アップロードでのコンテンツ・エンコード圧縮を有効/無効にするために使用します。`,
  [`User`]: `ユーザー`,
  [`User group prefix`]: `ユーザー・グループ接頭部`,
  [`User group suffix`]: `ユーザー・グループ接尾辞`,
  [`User mapping`]: `ユーザー・マッピング`,
  [`User Registries`]: `ユーザー・レジストリー`,
  [`User registries`]: `ユーザー・レジストリー`,
  [`User Registry Name`]: `ユーザー・レジストリー名`,
  [`User registry name`]: `ユーザー・レジストリー名`,
  [`User Registry cannot be deleted`]: `ユーザー・レジストリーは削除できません`,
  [`User Registry "{name}" has been deleted`]: `ユーザー・レジストリー "{name}" が削除されました`,
  [`User security`]: `ユーザー・セキュリティー`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `ユーザー・レジストリーは、スペースが有効な場合は各スペース内で管理されます。 スペース設定にアクセスしてユーザー・レジストリーを管理してください。`,
  [`User registries defined for consumer onboarding`]: `コンシューマーのオンボーディング用に定義されたユーザー・レジストリー`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `コンシューマーのオンボーディング用に定義されたユーザー・レジストリー\n\n開発者ポータルの各アカウントは、サイト管理者アカウントを含め、同じサイトの異なるユーザー・レジストリー全体にわたって固有の E メール・アドレスを所有している必要があります。`,
  [`User registry`]: `ユーザー・レジストリー`,
  [`User registry configuration has been updated.`]: `ユーザー・レジストリー構成が更新されました。`,
  [`User registry has been created`]: `ユーザー・レジストリーが作成されました`,
  [`User registry {title} deleted.`]: `ユーザー・レジストリー {title} が削除されました。`,
  [`User registry {title} has been created.`]: `ユーザー・レジストリー {title} が作成されました。`,
  [`User registry is not visible or not available.`]: `ユーザー・レジストリーが表示されていないか使用できません。`,
  [`User registry {title} has been updated.`]: `ユーザー・レジストリー {title} が更新されました`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `ユーザー・セキュリティー設定を適用できるのは、選択した権限付与タイプに「暗黙」、「アクセス・コード」、および「リソース所有者 - パスワード」のうちの 1 つ以上が含まれている場合に限られます。`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `ユーザー・セキュリティー設定を適用できるのは、選択した権限付与タイプに「暗黙」、「アクセス・コード」、および「リソース所有者 - パスワード」のうちの 1 つ以上が含まれている場合に限られます。 このページに何らかの変更を行った場合は、「構成」ページでこれらの権限付与タイプのうちの少なくとも 1 つも選択する必要があります。`,
  [`UserInfo endpoint`]: `ユーザー情報エンドポイント`,
  [`UserInfo URL`]: `UserInfo URL`,
  [`Username`]: `ユーザー名`,
  [`Username / Email`]: `ユーザー名/E メール`,
  [`Username and password fields are required in order to test configuration`]: `構成をテストするにはユーザー名およびパスワードのフィールドは必須です`,
  [`Username context variable`]: `ユーザー名のコンテキスト変数`,
  [`Username of the new user`]: `新規ユーザーのユーザー名`,
  [`Username should not contain special characters!`]: `ユーザー名に特殊文字を含めることはできません。`,
  [`Username should not include spaces!`]: `ユーザー名にスペースを含めることはできません。`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `コンシューマー組織に属するユーザーは、その可視性とサブスクライブ可能性により決定された、開発者ポータルおよびその公開済み API へのアクセス権を持ちます。`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `[IBM Cloud IAM]({link}) を使用して、証明書マネージャー・サービスへのアクセスを API Connect Reserved Instance サービスに許可します。`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Userinfo エンドポイントとの通信時に HTTP POST メソッドを使用`,
  [`Use IBM APIC token expiration setting from the cloud`]: `クラウドの IBM APIC トークンの有効期限設定の使用`,
  [`Update API`]: `API の更新`,
  [`VALUE`]: `値`,
  [`Version`]: `バージョン`,
  [`Visible to`]: `表示可能`,
  [`Validate`]: `妥当性検査`,
  [`Validate message body`]: `メッセージ本文の妥当性検査`,
  [`Validate message headers`]: `メッセージ・ヘッダーの妥当性検査`,
  [`Validate message fault details`]: `メッセージ障害の詳細の妥当性検査`,
  [`Validate SOAP 1.1 encoding rule`]: `SOAP 1.1 のエンコード・ルールの妥当性検査`,
  [`Validate JSON Web Token (JWT)`]: `JSON Web トークン (JWT) の検証`,
  [`Validate JWT`]: `JWT の検証`,
  [`Validate Username Token`]: `ユーザー名トークンの妥当性検査`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `認証 URL または LDAP ユーザー・レジストリーを使用して Web Services Security UsernameToken ペイロードを検証します。`,
  [`Validate against`]: `検証対象:`,
  [`Validate request`]: `要求の妥当性検査`,
  [`Validate the GraphQL query and analyze the expected cost`]: `GraphQL 照会を検証して予想コストを分析`,
  [`Validate the returned JSON and analyze the real cost`]: `返された JSON を検証して実コストを分析`,
  [`Validating API ...`]: `API の検証中...`,
  [`Validating Draft API`]: `ドラフト API の検証中`,
  [`Validity Period`]: `有効期間`,
  [`Value`]: `値`,
  [`Value for located in must be selected.`]: `場所の値を選択する必要があります。`,
  [`Value for the variable to be added.`]: `追加する変数の値。`,
  [`Value for type must be selected.`]: `タイプの値を選択する必要があります。`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `「@cost」ディレクティブの「weight」引数の値が < 0 になっています。`,
  [`Values`]: `値`,
  [`Vanity API endpoint`]: `バニティー API エンドポイント`,
  [`Variable name`]: `変数名`,
  [`Variable value`]: `変数値`,
  [`Vendor Extensions`]: `ベンダー拡張`,
  [`Verb`]: `verb`,
  [`Verify`]: `検証`,
  [`Verify Access Token`]: `アクセス・トークンの検証`,
  [`Verify Crypto JWK variable name`]: `検証用暗号 JWK 変数名`,
  [`Verify Crypto Object`]: `検証用暗号オブジェクト`,
  [`Verify Token`]: `トークンの検証`,
  [`Verify authorization code`]: `許可コードの検証`,
  [`Verify refresh token`]: `リフレッシュ・トークンの検証`,
  [`Version of the LTPA token.`]: `LTPA トークンのバージョン。`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `インポートされた製品ファイルの「plans」の下にある「apis」プロパティーを確認してください。 各プランには、「apis」セクションから少なくとも 1 つの API を指す「apis」プロパティーが必要です。`,
  [`View`]: `表示`,
  [`View extensions`]: `拡張の表示`,
  [`View Forum`]: `フォーラムの表示`,
  [`View owners`]: `所有者の表示`,
  [`View policies`]: `ポリシーの表示`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `選択したプロトコル・バージョンに使用可能な暗号の表示と管理を行います。`,
  [`View analytics`]: `分析の表示`,
  [`View and edit permissions for the roles in this space`]: `このスペース内のロールの許可を表示および編集します`,
  [`View and edit the permissions for the roles in the current organization`]: `現行組織内のロールの許可を表示および編集します`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `コンシューマー組織のロールのデフォルトに含まれているロールを表示および編集します`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `プロバイダー組織のロールのデフォルトに含まれているロールを表示および編集します`,
  [`View approval history`]: `承認履歴の表示`,
  [`View Approval History`]: `承認履歴の表示`,
  [`View documentation and tutorials with step-by-step instructions.`]: `ステップバイステップの手順を含むドキュメンテーションとチュートリアルの表示`,
  [`View error ID`]: `エラー ID の表示`,
  [`View status`]: `状況の表示`,
  [`View more`]: `詳細表示`,
  [`View source`]: `ソースの表示`,
  [`View subscription`]: `サブスクリプションを表示`,
  [`View template in`]: `以下のテンプレートを表示:`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `API Connect ユーザー・インターフェースにアクセスするためのエンドポイントを表示します。`,
  [`View the endpoints for the admin and provider REST APIs.`]: `管理 REST API とプロバイダー REST API のエンドポイントを表示します。`,
  [`View the endpoints for the consumer REST APIs.`]: `コンシューマー REST API のエンドポイントを表示します。`,
  [`Viewer`]: `閲覧者`,
  [`Views the API consumer organization`]: `API コンシューマー組織を表示します`,
  [`Views the API provider organization`]: `API プロバイダー組織を表示します`,
  [`Views the admin organization`]: `管理者組織を表示します`,
  [`Views the app developer organization`]: `アプリケーション開発者組織を表示します`,
  [`Views the catalog`]: `カタログを表示します`,
  [`Views the space`]: `スペースを表示します`,
  [`View|permission`]: `表示`,
  [`Visibility`]: `可視性`,
  [`Visibility options`]: `可視性のオプション`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware 資料`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL の URL`,
  [`WSDL has been successfully validated`]: `WSDL が正常に検証されました`,
  [`WSDL to REST`]: `WSDL から REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `WS-I Basic Profile 妥当性検査`,
  [`Warn`]: `警告`,
  [`Warning`]: `警告`,
  [`Warning:`]: `警告:`,
  [`Warnings`]: `警告`,
  [`We just emailed recipients a test message:`]: `受信者にテスト・メッセージを E メールで送信しました:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `「テスト」タブに移動します。ここで、API のテストとデバッグを行えます。`,
  [`Weather Product`]: `天候製品`,
  [`Web Endpoint`]: `Web エンドポイント`,
  [`Web service operations`]: `Web サービス操作`,
  [`weight`]: `ウェイト`,
  [`Weight`]: `ウェイト`,
  [`Welcome to API Connect Administration`]: `API Connect Administration へようこそ`,
  [`Welcome to API Connect Reserved`]: `API Connect Reserved へようこそ`,
  [`Welcome to API Designer`]: `API Designer へようこそ`,
  [`Welcome to API Manager`]: `API Manager へようこそ`,
  [`Welcome to Cloud Manager`]: `Cloud Manager へようこそ`,
  [`Welcome to the API Designer`]: `API Designer へようこそ`,
  [`Welcome to the API Manager`]: `API Manager へようこそ`,
  [`Welcome to the Cloud Manager`]: `Cloud Manager へようこそ`,
  [`What are you looking for today?`]: `今日は何をお探しですか?`,
  [`What's new`]: `新機能`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `次のステップ: カタログで請求統合を有効にして、ユーザーが自分の製品にプランを追加できるようにします。`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `これが true に設定されると、invoke ポリシーにより、ヘッダー「X-Forwarded-For」、「X-Forwarded-To」、「X-Forwarded-Host」、「X-Forwarded-Proto」がターゲット URL への要求送信に挿入されます。`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `公開された API 製品は、カタログに関連付けられた開発者ポータルで表示できるようになります。 製品内の API のエンドポイントは、ターゲットのカタログまたはスペースに関連付けられたゲートウェイで使用可能になります。 カタログにはステージング済みの製品が存在しますが、これらは稼働せず、コンシューマーにも表示されません。`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `公開された API 製品は、カタログに関連付けられた開発者ポータルで表示できるようになります。 製品内の API のエンドポイントは、ターゲットのカタログまたはスペースに関連付けられたゲートウェイで使用可能になります。 カタログにはステージング済みの製品が存在しますが、これらは稼働せず、コンシューマーにも表示されません。 非実稼働カタログの製品を再ステージング/再公開すると、既存のサブスクリプションが削除されることに注意してください。`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `スペースがカタログに対して有効になっている場合、構成済みの OAuth プロバイダーをスペースから表示する必要があります。`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `スペースを有効にすると、カタログ内の既存の製品はデフォルト・スペースに移動されます。`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `ユーザーがアクティベーション・リンクをクリックすると、登録フォームへの入力が求められます。 登録が完了すると、ユーザーはログインできます。`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `プロバイダー組織またはコンシューマー組織のロールを追加すると、そのロールはロールのデフォルトに含められます。 ロールのデフォルトは、新規組織の作成時に使用可能なデフォルトのロールを決定するために使用される事前構成のロール・テンプレートです。`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `製品を非推奨にすると、その製品を既にサブスクライブしているアプリケーション開発者は引き続きその製品を使用できますが、新規の開発者がその製品をサブスクライブすることはできません。\n\n [詳細情報]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `プロバイダー組織またはコンシューマー組織のロールを編集すると、そのロールはロールのデフォルトに含められます。 ロールのデフォルトは、新規組織の作成時に使用可能なデフォルトのロールを決定するために使用される事前構成のロール・テンプレートです。`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `OpenID Connect を有効にすると、アクセス・トークンと共に、ID トークンを生成するためのテンプレートが提供され、必要なアセンブリー・ポリシーが自動的に作成されます。 このポリシーは要件に合わせてカスタマイズできます。 サンプル・キーはあくまでテスト用であり、JWT トークンに署名するために使用されます。`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `トークン・イントロスペクションを有効にすると、イントロスペクション・パスを通じてアクセス・トークンを検査できます。`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `トークン・イントロスペクションを有効にすると、アクセス・トークンの所有者は、イントロスペクション・パスを使用してトークンのコンテンツを検査できます。 イントロスペクトするアクセス・トークンは、ネイティブ OAuth プロバイダーを通じて取得する必要があります。`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `プロバイダー組織のメンバーになるようにユーザーを招待する場合は、そのユーザーに、情報へのアクセス・レベルと実行可能なタスクを制御するための 1 つ以上のロールを割り当てます。`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `製品バージョンを管理する際は、製品バージョンが一連のライフサイクル状態を経ていくようにします。最初は製品バージョンをカタログにステージングします。それからその製品バージョンをアプリケーション開発者が利用できるように公開し、最後に、廃棄とアーカイブを行います。`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `ある製品を別の製品と置き換えると、以下のアクションが実行されます。1) 置き換え後の製品が公開されます。2) 元の製品と同じ可視性、サブスクライバー、およびゲートウェイ強制の設定が置き換え後の製品で使用されます。3) 元の製品のサブスクライバーは置き換え後の製品にマイグレーションされます。`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `製品を別の製品に置き換えると、以下のアクションが実行されます。1) 交換製品は元の製品の状態を想定します。2) 交換製品の可視性およびサブスクライブ可能性の設定で、アクセスが元の製品と同じかそれより制限が少ない場合、交換製品の設定が使用されます。 置換製品の設定がより制限的であり、元の製品へのアクセス権限を持つ一部のコンシューマー組織が置換にアクセスできない場合、置換操作を完了できません。 3) 元の製品のサブスクライバーが代替製品にマイグレーションされ、(4) 元の製品が廃止されます。`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `製品が廃棄されると、すべての関連付けられた API がオフラインになり、すべてのサブスクリプションが非アクティブになります。\n\n [詳細情報]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `ある製品を別の製品と取り替えると、以下のアクションが実行されます。1) 取り替え後の製品が公開されます。2) 元の製品と同じ可視性、サブスクライバー、およびゲートウェイ強制の設定が取り替え後の製品で使用されます。3) 元の製品は非推奨状態に移行します。 製品を非推奨にすると、その製品を既にサブスクライブしているアプリケーション開発者は引き続きその製品を使用できますが、新規の開発者がその製品をサブスクライブすることはできません。 非推奨の製品は、必要に応じて再度公開できます。`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `特定の製品を別の製品に取り替えると、以下のアクションが実行されます。1) 取り替え後の製品が公開されます。2) 元の製品の可視性とサブスクライブ可能性の設定が取り替え後の製品で使用されます。3) 元の製品は非推奨の状態に移行します。  製品を非推奨にすると、その製品を既にサブスクライブしているアプリケーション開発者は引き続きその製品を使用できますが、新規の開発者がその製品をサブスクライブすることはできません。  非推奨の製品は、必要に応じて再公開できます。`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `アプリケーションを中断すると、関連付けられたクライアント ID は API 呼び出しを行うことができなくなります。 アプリケーションはいつでも再開できます。`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `有効にすると、すべてのコンシューマー組織の招待タイムアウトがカタログ招待タイムアウトに設定され、カタログ招待タイムアウトの更新により、コンシューマー組織の招待タイムアウトが更新されます。`,
  [`Where did the roles go?`]: `ロールの場所が不明です。`,
  [`Where to find the named rate limit.`]: `指定されたレート制限の検索場所`,
  [`Where to put the decrypted result`]: `暗号化解除された結果が配置される場所`,
  [`Where to put the encrypted result`]: `暗号化された結果が配置される場所`,
  [`Whether to reuse the connection to the target server.`]: `ターゲット・サーバーへの接続を再使用するかどうか。`,
  [`Windows live`]: `Windows Live`,
  [`With`]: `対象`,
  [`Wildcards ignore xsi:type rule`]: `ワイルドカードで xsi:type のルールを無視`,
  [`Work offline`]: `オフラインで作業`,
  [`Work with existing definitions, models and/or data sources`]: `既存の定義、モデル、またはデータ・ソースを処理します`,
  [`Working...`]: `処理中...`,
  [`Wrapper Policies`]: `ラッパー・ポリシー`,
  [`Wrong slicing arguments`]: `正しくないスライス引数`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL および XSD は、インポートしようとしている zip ファイル内のそれ自体の zip ファイル内になければなりません。`,
  [`XML Schema`]: `XML スキーマ`,
  [`XML Schema URL`]: `XML スキーマ URL`,
  [`XML Schema preview`]: `XML スキーマ・プレビュー`,
  [`XML Validation Mode`]: `XML 検証モード`,
  [`XML schema is not valid. Please correct the error.`]: `XML スキーマが無効です。 エラーを訂正してください。`,
  [`XML to JSON`]: `XML から JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT のバージョン`,
  [`YAML has been successfully validated`]: `YAML が正常に検証されました`,
  [`Yes`]: `はい`,
  [`Yes, enable it`]: `はい、有効にします`,
  [`Yes, reset`]: `はい、リセットします`,
  [`Yes. Update API Assembly`]: `はい。 API アセンブリーを更新します`,
  [`You are authenticated!`]: `認証されました。`,
  [`You do not have permission to use the Test Application`]: `テスト・アプリケーションを使用する権限がありません`,
  [`You can also copy the link and send it directly to the user.`]: `リンクをコピーしてユーザーに直接送信することもできます。`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `現時点では API を公開しないことにするか、または即時に API をテスト・エンドポイントまたは実動エンドポイント (あるいはその両方) に公開することを選択できます。 オプションで、特定の時間間隔での呼び出し数に基づく API 呼び出しの許可される最大レートを指定できます。`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `現時点では API を公開しないことにするか、または即時に API をテスト・エンドポイントまたは実動エンドポイント (あるいはその両方) に公開することを選択できます。 オプションで、指定された時間間隔での呼び出し数に基づく API 呼び出しの許可される最大レートを指定できます。`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `現時点では API を無保護のままにするか、または呼び出し元のアプリケーションでクライアント ID の指定を必須にするかを選択できます。 API 定義の作成後にさらにセキュリティー・オプションを構成できます。`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `カタログ名またはタイトルを入力できます。 両方が使用可能な場合は、カタログ名が優先されます。`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `コンシューマー組織の所有者に E メールを送信できます。 メッセージは、「通知」で構成された E メール・サーバーを使用して送信されます。`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `コンシューマー組織の所有者に E メールを送信できます。 メッセージは、「通知」セクションで構成された E メール・サーバーを使用して送信されます。`,
  [`You can send an email to a provider organization owner.`]: `プロバイダー組織の所有者に E メールを送信できます。`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `新規ユーザーの E メール・アドレスを入力することによって、そのユーザーに所有権を移転できます。 そのユーザーは、登録するためのアクティベーション・リンクが記載された E メールを受け取ります。 登録が完了すると、そのユーザーは新規所有者になります。 現在の所有者に新規ロールを割り当てて、その所有者が Cloud Manager で引き続き作業できるようにします。`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `登録済みユーザーの E メール・アドレスを入力することによって、そのユーザーに所有権を移転できます。 現在の所有者に新規ロールを割り当てて、その所有者が Cloud Manager で引き続き作業できるようにします。`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `OpenAPI 定義ファイルを使用して IBM API Connect で REST API を追加できます。 有効なファイル・フォーマットは、JSON と YAML です。  API 定義のインポートが完了すると、API 定義のリストに表示されます。`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `ルート・タイプ (「照会」) が削除されることになるため、このタイプは削除できません。`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `ゲートウェイ・タイプがこの製品のゲートウェイ・タイプと一致しない API は選択できません。`,
  [`You currently don’t have any gateway extensions`]: `現在、ゲートウェイ拡張はありません`,
  [`You currently don’t have any plans to migrate`]: `現在、マイグレーションするプランがありません`,
  [`You currently don't have any policy.`]: `現時点では、ポリシーはありません。`,
  [`You do not have permission to any spaces.`]: `どのスペースに対しても権限がありません。`,
  [`You do not have permission to publish products to any catalog or space.`]: `カタログおよびスペースに製品を公開する権限がありません。`,
  [`You do not have permission to stage products to any catalog or space.`]: `カタログおよびスペースに製品をステージングする権限がありません。`,
  [`You don’t currently have any billing integrations.`]: `現在、請求統合はありません。`,
  [`You don't currently have any response.`]: `現在、応答がありません。`,
  [`You don't currently have any user-defined policies.`]: `現在、ユーザー定義のポリシーがありません。`,
  [`You don't have permission to access this page.`]: `このページにアクセスするための許可がありません。`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `この証明書に変更を加えました。 このアプリケーションを保存すると、アプリケーションの構成が更新されます。`,
  [`You haven't added any APIs or Products`]: `API も製品も追加されていません`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `カタログに新規ユーザーを追加し、ユーザーにロールを割り当てることによってカタログ・メンバーシップを管理します。`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `スペースに新規ユーザーを追加し、ユーザーにロールを割り当てることによってスペース・メンバーシップを管理します。`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `スペースに新規ユーザーを追加し、ユーザーにロールを割り当てることによってスペース・メンバーシップを管理します。`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `公開の準備ができた製品に API を追加する必要があります。 製品により一連の API とプランが 1 つのオファリングにまとめられ、これを開発者が使用できるようにします。 プランには、プラン全体に適用できるレート制限設定、または API の各操作に対して指定できるレート制限設定が含まれています。`,
  [`You must choose a definition`]: `定義を選択する必要があります`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `このカタログに公開された API をゲートウェイ・サービス・エンドポイントで呼び出すことができるように、少なくとも 1 つのゲートウェイ・サービスを有効にする必要があります。 複数のゲートウェイ・サービスを有効にすることができます。`,
  [`You must enable spaces`]: `スペースを有効にする必要があります`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `アプリケーションの開発時に使用するために以下のクライアント ID と秘密鍵を記録してください。 クライアント秘密鍵を再度表示することはできません。`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `ご使用の API Connect Reserved Instance ライセンスで、1 つ以上の DataPower API Gateway をダウンロードしてセットアップできます。 ゲートウェイはオンプレミスまたは任意のクラウド上に置くことができます。`,
  [`Your API has been created`]: `API が作成されました`,
  [`Your API is live!`]: `API は稼働中です`,
  [`Your API is online!`]: `API はオンラインです`,
  [`Your Catalog is being deleted. This may take a while...`]: `カタログを削除しています。 これにはしばらく時間がかかる場合があります...`,
  [`Your Consumer organization is being created. This may take a while...`]: `コンシューマー組織を作成中です。 これにはしばらく時間がかかる場合があります...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `招待は無効になりました。 招待のタイムアウト設定を確認し、招待を再送してください。`,
  [`Your Provider organization is being created. This may take a while...`]: `プロバイダー組織を作成中です。 これにはしばらく時間がかかる場合があります...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `プロバイダー組織を削除中です。 これにはしばらく時間がかかる場合があります...`,
  [`Your member is being deleted. This may take a while...`]: `メンバーを削除中です。 これにはしばらく時間がかかる場合があります...`,
  [`Your Space is being deleted. This may take a while...`]: `スペースを削除しています。 これにはしばらく時間がかかる場合があります...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `OAuth プロバイダーに未保存の変更があります。 保存せずに続行するには「OK」をクリックしてください。`,
  [`Your credentials have expired.`]: `資格情報の期限が切れています。`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `招待は、以下の招待リンクとともに正常に送信されました:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `要求はまだ処理中であり、予想より長い時間がかかっています。 数分後に最新表示した後で、要求を再試行してください。`,
  [`Your product will be saved before proceeding, are you sure?`]: `続行する前に製品が保存されます。よろしいですか?`,
  [`Your trial ends on {date}`]: `試用版は {date} に終了します。`,
  [`Your trial expired on {date}.`]: `試用版は {date} に有効期限が切れました。`,
  [`Zoom in`]: `ズームイン`,
  [`Zoom in and out`]: `ズームインとズームアウト`,
  [`Zoom out`]: `ズームアウト`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `ターゲット・サーバーで CORS がサポートされていません (以下の提案を参照)`,
  [`a LDAP provider`]: `LDAP プロバイダー`,
  [`activity`]: `アクティビティー`,
  [`add item...`]: `項目の追加...`,
  [`add property...`]: `プロパティーの追加...`,
  [`added`]: `追加済み`,
  [`admin`]: `管理者`,
  [`administrator`]: `管理者`,
  [`all`]: `すべて`,
  [`an API Connect Local User Registry`]: `API Connect のローカル・ユーザー・レジストリー`,
  [`an authentication URL`]: `認証 URL`,
  [`an untrusted certificate has been encountered`]: `信頼できない証明書が検出されました`,
  [`and`]: `および`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `アプリケーション:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `承認`,
  [`approval task for product`]: `製品の承認タスク`,
  [`archived`]: `アーカイブ済み`,
  [`authenticated`]: `認証済み`,
  [`authorization code time to live (seconds)`]: `許可コードの有効期間 (秒)`,
  [`Authorization header pass through`]: `許可ヘッダーのパススルー`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `ブロッキング`,
  [`body`]: `本文`,
  [`body-param`]: `本文パラメーター`,
  [`cancel`]: `キャンセル`,
  [`case`]: `ケース`,
  [`catalog`]: `カタログ`,
  [`catalog:`]: `カタログ:`,
  [`catch`]: `Catch`,
  [`changed`]: `変更済み`,
  [`cipher`]: `暗号`,
  [`code id_token`]: `コード id_token`,
  [`code id_token token`]: `コード id_token トークン`,
  [`code token`]: `コード・トークン`,
  [`Consume`]: `コンシューム`,
  [`consumer`]: `コンシューマー`,
  [`consumes`]: `コンシューム`,
  [`composite`]: `複合`,
  [`created`]: `作成済み`,
  [`Created API`]: `作成済み API`,
  [`custom string`]: `カスタム・ストリング`,
  [`day`]: `日`,
  [`day(s)`]: `日`,
  [`default`]: `デフォルト`,
  [`default value`]: `デフォルト値`,
  [`definition`]: `定義`,
  [`deprecated`]: `非推奨`,
  [`description`]: `説明`,
  [`development`]: `開発`,
  [`edit condition`]: `条件の編集`,
  [`edited`]: `編集済み`,
  [`environment`]: `カタログ`,
  [`error`]: `エラー`,
  [`false`]: `false`,
  [`file`]: `ファイル`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 のアイコン`,
  [`for`]: `対象`,
  [`header`]: `ヘッダー`,
  [`hear`]: `聞く`,
  [`hello`]: `ご連絡`,
  [`helloName`]: `{name} さん、こんにちは。`,
  [`here`]: `ここ`,
  [`hit`]: `ヒット`,
  [`hits`]: `ヒット`,
  [`hour`]: `時間`,
  [`hour(s)`]: `時間`,
  [`hours`]: `時間`,
  [`identified`]: `識別済み`,
  [`Increased`]: `増加`,
  [`Increment`]: `増分`,
  [`info`]: `情報`,
  [`information about external documentation`]: `外部資料に関する情報`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `APIC によって生成された access_token/refresh_token は、OIDC プロバイダーの exp クレーム ではなく、APIC 設定を優先する`,
  [`introspect URL`]: `イントロスペクト URL`,
  [`Issue`]: `問題`,
  [`Issues`]: `問題`,
  [`item`]: `項目`,
  [`iterate over`]: `反復処理`,
  [`last modified`]: `最終変更`,
  [`Log Level`]: `ログ・レベル`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `ログ`,
  [`Logout`]: `ログアウト`,
  [`Logout redirect`]: `ログアウト・リダイレクト`,
  [`Logout redirect URL`]: `ログアウト・リダイレクト URL`,
  [`location`]: `ロケーション`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `分`,
  [`minute(s)`]: `分間`,
  [`minutes`]: `分`,
  [`mutation type`]: `ミューテーション・タイプ`,
  [`mutual SSL authentication is required`]: `相互 SSL 認証が必要です`,
  [`name`]: `名前`,
  [`Name|extension`]: `名前`,
  [`Name|path parameters`]: `名前`,
  [`Name|security definitions`]: `名前`,
  [`non-blocking`]: `非ブロッキング`,
  [`none`]: `なし`,
  [`none selected`]: `何も選択されていません`,
  [`Object`]: `オブジェクト`,
  [`off`]: `オフ`,
  [`of {total} pages`]: `/{total} ページ`,
  [`of {total} page`]: `/{total} ページ`,
  [`offline`]: `オフライン`,
  [`on`]: `オン`,
  [`online`]: `オンライン`,
  [`or`]: `または`,
  [`or redact from all`]: `またはすべてから編集`,
  [`open and close list of options`]: `オプションのリストの開閉`,
  [`organization saved`]: `組織が保存されました`,
  [`organization-manager`]: `組織管理者`,
  [`otherwise`]: `それ以外の場合`,
  [`payload`]: `ペイロード`,
  [`Payload`]: `ペイロード`,
  [`pending`]: `保留`,
  [`produces`]: `生成`,
  [`production`]: `実動`,
  [`production and development`]: `実動と開発`,
  [`property`]: `プロパティー`,
  [`provider`]: `プロバイダー`,
  [`publish product`]: `製品の公開`,
  [`published`]: `公開済み`,
  [`query type`]: `照会タイプ`,
  [`query-parameters`]: `照会パラメーター`,
  [`realized`]: `実現済み`,
  [`recipients`]: `受信者`,
  [`removed`]: `削除済み`,
  [`replaced`]: `置き換え済み`,
  [`repeatable`]: `反復可能`,
  [`Replenish`]: `補充`,
  [`request`]: `要求`,
  [`requested by`]: `要求元`,
  [`Resolver weight`]: `リゾルバーの重み`,
  [`response`]: `応答`,
  [`response-param`]: `応答パラメーター`,
  [`retired`]: `廃棄済み`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `保存済み`,
  [`scalar`]: `スカラー`,
  [`second`]: `秒`,
  [`second(s)`]: `秒`,
  [`seconds`]: `秒`,
  [`select owner user`]: `所有者ユーザーの選択`,
  [`select user`]: `ユーザーの選択`,
  [`soap-body`]: `SOAP 本体`,
  [`source`]: `ソース`,
  [`space`]: `スペース`,
  [`Space settings`]: `スペース設定`,
  [`Skip`]: `スキップ`,
  [`specified`]: `指定済み`,
  [`staged`]: `ステージング済み`,
  [`start generating tests automatically.`]: `テストの生成を自動的に開始します。`,
  [`subject`]: `件名`,
  [`subscription type`]: `サブスクリプション・タイプ`,
  [`supersede`]: `取り替え`,
  [`superseded`]: `取り替え済み`,
  [`target`]: `ターゲット`,
  [`the server is unavailable`]: `サーバーが使用可能ではありません`,
  [`this user registry type`]: `このユーザー・レジストリー・タイプ`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `これは一時的な公開ページであり、再公開を実行したときにサブスクリプションが保持されます。`,
  [`to production`]: `実動`,
  [`topology-administrator`]: `トポロジー管理者`,
  [`Trial days left`]: `試用期間の残り日数`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `ttl が変更されました`,
  [`type`]: `タイプ`,
  [`unarchived`]: `解凍済み`,
  [`union type`]: `共用体タイプ`,
  [`unlimited`]: `無制限`,
  [`updated`]: `更新済み`,
  [`Updated from new schema`]: `新規スキーマから更新済み`,
  [`Upgrade`]: `アップグレード`,
  [`v5 legacy behavior`]: `v5 レガシー動作`,
  [`version 2`]: `バージョン 2`,
  [`version 3`]: `バージョン 3`,
  [`viewer`]: `閲覧者`,
  [`value`]: `値`,
  [`warn`]: `警告`,
  [`warning`]: `警告`,
  [`warnings`]: `警告`,
  [`was not found. Please select a configured OAuth provider.`]: `が見つかりませんでした。 構成済みの OAuth プロバイダーを選択してください。`,
  [`week`]: `週`,
  [`with`]: `対象`,
  [`with|supersede with`]: `対象`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `サンドボックス内のステータス`,
  [`your file here, or`]: `ファイルをここにドラッグ &amp;amp; ドロップするか、または「参照」をクリックしてファイルを選択してください。`,
  [`{count} found!`]: `{count} 個見つかりました。`,
  [`{current} of {total} page`]: `{total} の  {current} ページ`,
  [`{current} of {total} pages`]: `{current}/{total} ページ`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} 日、{hours} 時間、{minutes} 分`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} 日、{hours} 時間`,
  [`{docType} is required`]: `{docType} は必須です`,
  [`{docType} title`]: `{docType} のタイトル`,
  [`Product title`]: `製品タイトル`,
  [`Application title`]: `アプリケーション・タイトル`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} 時間、{minutes} 分`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API または製品で指定されたゲートウェイ・タイプと一致しないため、{incompatibleGatewayCount} 個のゲートウェイが非表示になっています`,
  [`{min}-{max} of {total} items`]: `{min}-{max}/{total} 項目`,
  [`{min}-{max} of {total} item`]: `{min} - {total} の {max}アイテム`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max}/{total} ポリシー`,
  [`{min}-{max} of {total} Policy`]: `{min}-{total} の {max} ポリシー`,
  [`{min}-{max} of {total} types`]: `{min}-{max}/{total} タイプ`,
  [`{min}-{max} of {total} type`]: `{min} - {total} の {max} タイプ`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} / {total} 個の検索結果`,
  [`{min}-{max} of {total} search result`]: `{min}-{total} の {max} 検索結果`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max}/{total} カタログ`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{total} の {max} カタログ`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max}/{total} スペース`,
  [`{min}-{max} of {total} Space`]: `{min}-{total} の {max} スペース`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max}/{total} 個の拡張`,
  [`{min}-{max} of {total} Extension`]: `{min}-{total} の {max} 拡張`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max}/{total} 製品プラン`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{total} の {max} 製品プラン`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max}/{total} ゲートウェイ`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{total} の{max} ゲートウェイ`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (API) に 'x-ibm-configuration' が欠落しています`,
  [`{name} (Product) has been published!`]: `{name} (製品) が公開されました。`,
  [`{name} (Product) has been staged!`]: `{name} (製品) がステージングされました。`,
  [`{name} (Product) has not been published!`]: `{name} (製品) は公開されていません。`,
  [`{name} (Role) has been created`]: `{name} (ロール) が作成されました。`,
  [`{name} successfully deleted`]: `{name} が正常に削除されました。`,
  [`{name} (Role) has been updated`]: `{name} (ロール) が更新されました。`,
  [`{name} has been assigned the owner`]: `{name} に所有者が割り当てられました`,
  [`{number} Error`]: `{number} 個のエラー`,
  [`{number} Errors`]: `{number} 個のエラー`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{username} によって要求された、製品 {prodname}:{version} の {operation} 承認タスク`,
  [`{path} does not exist`]: `{path} が存在しません`,
  [`{productName} has no migration target set.`]: `{productName} にはマイグレーション・ターゲットが設定されていません。`,
  [`{state} Pending`]: `{state} 保留中`,
  [`{status} API error`]: `{status} API エラー`,
  [`{status} Bad Request`]: `{status} 不正要求`,
  [`{status} Forbidden`]: `{status} 禁止`,
  [`{status} Gateway Error`]: `{status} ゲートウェイ・エラー`,
  [`{status} Unauthorized`]: `{status} 無許可`,
  [`({time} after being sent)`]: `({time} (送信された後))`,
  [`({time} after being queued)`]: `({time} (キューに入れられた後))`,
  [`{title} (Product) has been {state}.`]: `{title} (製品) が {state} されました。`,
  [`{title} has been deleted`]: `{title} は削除されました`,
  [`{title} has been {changedName}.`]: `{title} が {changedName} されました。`,
  [`{title} has not been deleted!`]: `{title} は削除されていません。`,
  [`{title} has not been {changedName}!`]: `{title} は {changedName} されていません。`,
  [`{title} {changedName}.`]: `{title} {changedName}。`,
  [`{type} has been {changedName}.`]: `{type} が {changedName} されました。`,
  [`{type} has not been {changedName}!`]: `{type} は {changedName} されていません。`,
  [`{type} history log`]: `{type} 履歴ログ`,
  [`{username} does not exist`]: `{username} は存在しません`,
  [`{username} does not exist in the user registry`]: `{username} はユーザー・レジストリーに存在しません`,
  [`{username} is not associated with this consumer org`]: `{username} はこのコンシューマー組織には関連付けられていません`,
  [`{username} has been created as a {type}.`]: `{username} が {type} として作成されました。`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `{appName} アプリケーションが {consumerOrg} 開発者ポータルで復元されました`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `{appName} アプリケーションが {consumerOrg} 開発者ポータルで中断されました`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} 開発者ポータル・アカウントの登録`,
  [`{{count}} errors need attention`]: `注意が必要なエラーが {count} 個あります`,
  // lts only translations
  [`Configure JSON settings`]: `JSON 設定の構成`,
  [`Configure XML settings`]: `XML 設定の構成`,
  [`Configure options`]: `オプションの構成`,
  [`Detect`]: `検出`,
  [`Document type`]: `ドキュメント・タイプ`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `最大ドキュメント・サイズ`,
  [`Maximum name length`]: `名前の最大長`,
  [`Maximum nesting depth`]: `ネストの最大の深さ`,
  [`Maximum number length`]: `数値最大長`,
  [`Maximum number of unique names`]: `固有の名前の最大数`,
  [`Maximum number of unique namespaces`]: `固有名前空間の最大数`,
  [`Maximum number of unique prefixes`]: `固有の接頭部の最大数`,
  [`Maximum value length`]: `値の最大長`,
  [`Maximum width`]: `最大幅`,
  [`Maximum stack size`]: `最大スタック・サイズ`,
  [`Parse settings`]: `解析設定`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `解析アクションで受け入れられる最大ドキュメント・サイズを、バイト数として指定します。`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `解析アクションで受け入れられる XML または JSON メッセージの、ネストした要素の深さの最大レベルを指定します。`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `解析アクションで受け入れられる XML、JSON、または GraphQL メッセージの、ネストした要素の深さの最大レベルを指定します。`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `解析アクションで受け入れられる名前の最大長を、バイト数として指定します。`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `ラベルと値のペアの値部分が数値のときにそのバイト数を制限する数値の最大長を指定します。`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `解析アクションで受け入れられる固有 XML 名前空間 URI の最大数を指定します。 この制限では、すべての XML 名前空間を (その名前空間を宣言するために使用された接頭部の数を問わず) カウントします。`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `解析アクションで受け入れられる、ドキュメント内の固有の XML 名前空間接頭部の最大数を指定します。 この制限では、同じ名前空間に定義された複数の接頭部をカウントしますが、同一の接頭部が使用される入力ドキュメントの各部分に定義された複数の名前空間はカウントしません。`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `解析アクションで受け入れられる固有の名前の最大数を指定します。`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `解析アクションで受け入れられる値の最大長を、バイト数として指定します。`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `解析アクションで受け入れるペイロードの最大幅を指定します。`,
  [`Specifies the type of document to parse.`]: `解析するドキュメントのタイプを指定します。`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `XSLT プロセッサーのバージョンを指定します。 デフォルトは XSLT10 です。`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `厳密な XSLT エラー検査を有効にするかどうかを指定します。 厳密でない操作では、宣言されていない変数の使用や、宣言されていないテンプレートの呼び出しなどの、特定のエラーからのリカバリーを試みます。 デフォルトでは、厳密な XSLT エラー検査は有効になっています。`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `スタイルシート・プロファイルを有効にするかどうかを指定します。 このオプションは実稼働環境では使用しないでください。 デフォルトでは、スタイルシート・プロファイルは無効になっています。`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `スタイルシート、XQuery スクリプト、および JSONiq スクリプトをデバッグ・モードで実行するかどうかを指定します。 スタイルシート、XQuery スクリプト、または JSONiq スクリプトがデバッグ・モードで実行している場合、通常出力を表示する代わりに、カスタム Web ページが生成されます。 この Web ページには、実行の間に何が発生したかが、変数の値や、出力の特定の部分の取得元も含めて正確に詳述されます。 このオプションは実稼働環境では使用しないでください。 デフォルトでは、デバッグ・モードは無効になっています。`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `スタイルシートをストリーム・モードで実行する必要があるかどうかを指定します。 入力の構文解析が完全に終わる前に、文書の変換が開始されます。 すべてのスタイルシートでストリーミングが可能とは限りません。 スタイルシートをストリーミングできない場合は、エラーが生成され、入力は処理されません。 デフォルトでは、ストリーム・モードは無効になっています。`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `ストリーム・モードでのスタイルシートの実行を試みるかどうかを指定します。 入力の構文解析が完全に終わる前に、文書の変換が開始されます。 すべてのスタイルシートでストリーミングが可能とは限りません。 ストリーミングが不可能なスタイルシートの場合、コンパイル時に警告が生成され、そのスタイルシートは実行時に通常どおりに入力全体が読み込まれます。 デフォルトでは、ストリーム・モードでのスタイルシートの実行を試みることはできないようになっています。`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `処理中にスタイルシートから生成された出力をエスケープするかどうかを指定します。 最小エスケープは、英語以外の文字セットを処理している場合には特に便利です。 デフォルトでは、最小エスケープは無効になっています。`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `スタイルシートまたはその他のコンパイル済みコンテンツを実行するときにスタックで使用することができる最大バイト数を示します。 この設定は、無限再帰を阻止するために使用します。 最小値は 10 キロバイト (10,240 バイト) です。 最大値は 100 メガバイト (104,857,600 バイト) です。 デフォルト値は 1 メガバイト (1,048,576 バイト) です。`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `WS-I Basic Profile (バージョン 1.0、2004 年 4 月) のセクション 5 に対する規格適合について検査された WSDL ファイルに適用される妥当性検査の動作を指定します。 デフォルトの設定は「警告」です。`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `soap:Body の妥当性検査の動作を指定します。 デフォルトの設定は「厳格」です。`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `soap:Header の妥当性検査の動作を指定します。 デフォルトの設定は「穏健」です。`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `障害の詳細の妥当性検査の動作を指定します。 デフォルトの設定は「厳格」です。`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `RPC スタイルのラッパーとの互換性が必要かどうかを指定します。 デフォルトでは、RPC スタイルのラッパーは必要ありません。`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `SOAP 1.1 のセクション 5 に従う xsi:type='SOAP-ENC:Array' が指定された要素の使用を、その属性が XML スキーマ仕様に違反する場合も、スキーマで大部分受け入れることを許可するかどうかを指定します。 通常、xsi:type 属性は、実際の要素のタイプと同じであるか、その要素から派生したタイプを指定する必要があります。 このオプションを指定してコンパイルされるスキーマでは、xsi:type の要素タイプが SOAP-ENC:Array から派生したものである場合、xsi:type は特に SOAP 1.1 エンコード方式「Array」複合タイプ用に受け入れられます。 この逆は通常の許容されるケースです。 デフォルトでは、xsi:type='SOAP-ENC:Array' が指定された要素は受け入れられません。`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `SOAP 1.1 のセクション 5 のエンコード・ルールに従って追加のスキーマ妥当性検査を実行するかどうかを指定します。有効にした場合、SOAP 配列のメンバーが妥当性検査され、@id および @href などの属性が (スキーマで許可されない場合であっても) 許可され、@href 値に対応する @id 要素があるかどうかを確認する検査が行われます。 デフォルトでは、この追加の妥当性検査は実行されません。`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `スキーマ内の xs:any 要素で子要素の妥当性検査を名前のみによって行うかどうかを指定します。 XML スキーマ仕様は、ワイルドカードが要素に一致するが、その要素に要素宣言がない場合、代わりにその要素がそこに指定された xsi:type 属性に従って妥当性検査されることを必要とします。 このオプションでは、それらの xsi:type 属性が無視されます。 これは、SOAP エンベロープの妥当性検査 (以降の妥当性検査ステップで、可能であれば SOAP 1.1 エンコード・ルールを使用して、ワイルドカードに一致するコンテンツが妥当性検査される) のようなケースで使用する必要があります。 デフォルトでは、xsi:type 属性は無視されません。`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `WSDL で SOAP バインディングに厳密に従うかどうかを指定します。 有効にすると、SOAP 1.2 エンベロープには SOAP 1.2 にバインドされたメッセージのみが表示され、SOAP 1.1 エンベロープには SOAP 1.1 にバインドされたメッセージのみが表示されます。 デフォルトでは、厳格な SOAP バインディングは無効になっています。`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `デバッグ情報を含めて XACML ポリシーをコンパイルするかどうかを指定します。 XACML デバッグ・メッセージも XACML カテゴリーのログ・イベントによって制御されます。 デバッグ・ログ・レベルは、詳細な XACML デバッグ・メッセージを表示するために使用します。 デフォルトでは、XACML ポリシーはデバッグ情報を含めてコンパイルされません。`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `base64 でエンコードされたバイナリー・コンテンツが MTOM/XOP 仕様に従って最適化されたメッセージを、スキーマ文書または WSDL 文書で受け入れるかどうかを指定します。 XOP バイナリー最適化は、base64 でエンコードされたバイナリー・データを、添付に配置された未エンコード・バイナリー・データを参照する xop:Include 参照要素と置き換えます。 デフォルトでは、MTOM/XOP 最適化メッセージは無効になっています。`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `コンシューマー組織グループとはコンシューマー組織の集合であり、製品内の API の表示とサブスクライブが可能なユーザーを制御するための効率的な方法を提供します。 コンシューマー組織グループを使用することで、組織に対してアクセス権を別個に定義しなくても、単一の操作でそのグループに属する組織のすべての開発者に対してこのアクセス権を定義できます。`,
  [`API Connect Home`]: `API Connect ホーム`,
  [`Add new group`]: `新規グループの追加`,
  [`Add consumer organizations to the group as required`]: `必要に応じてコンシューマー組織をグループに追加`,
  [`Add the following configuration`]: `次の構成を追加`,
  [`Add the following configuration to`]: `次の構成の追加先:`,
  [`Additions`]: `追加`,
  [`Additional GraphQL capabilities`]: `その他の GraphQL 機能`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `このプランに含まれている GraphQL API のその他の設定`,
  [`An organization or a group is required.`]: `組織またはグループが必要です。`,
  [`An organization/group is required.`]: `組織/グループが必要です。`,
  [`Analysis configuration`]: `分析の構成`,
  [`Application & Credentials`]: `アプリケーションと資格情報`,
  [`Are you sure you want to delete this API version?`]: `この API バージョンを削除しますか?`,
  [`Are you sure you want to delete this API with all versions?`]: `この API とすべてのバージョンを削除しますか?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `ゲートウェイ拡張をゲートウェイから削除しますか?`,
  [`Are you sure you want to rename all versions of this API?`]: `この API のすべてのバージョンを名前変更しますか?`,
  [`Are you sure you want to rename this API version?`]: `この API バージョンを名前変更しますか?`,
  [`Associate groups to the organization as required`]: `必要に応じてグループを組織に関連付ける`,
  [`At least one option must be enabled`]: `少なくとも 1 つのオプションが有効である必要があります`,
  [`Attribute mapping`]: `属性マッピング`,
  [`Attempt streaming rule`]: `ストリーミング試行ルール`,
  [`Auto generated default paths and operations`]: `自動生成されたデフォルトのパスと操作`,
  [`Base API endpoint`]: `基本 API エンドポイント`,
  [`Consumer orgs`]: `コンシューマー組織`,
  [`Calculate Query Characteristics`]: `照会特性の計算`,
  [`Calculate Response Characteristics`]: `応答特性の計算`,
  [`Certificate or Shared Secret for Verify`]: `検証する証明書または共有秘密鍵`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `ハンズオン・ラボをご確認ください。ここでは、API 管理の全機能を最大 1 週間試用することをリクエストできます。`,
  [`Clone API`]: `クローン API`,
  [`Consumer organizations and groups`]: `コンシューマー組織とグループ`,
  [`Create new API version`]: `新規 API バージョンの作成`,
  [`Create new product version`]: `新規製品バージョンの作成`,
  [`Create a GraphQL API based on a GraphQL service`]: `GraphQL サービスに基づいた GraphQL API の作成`,
  [`Custom rate limit`]: `カスタム・レート制限`,
  [`Delete API`]: `API の削除`,
  [`Delete this version`]: `このバージョンの削除`,
  [`Deletion confirmation`]: `削除の確認`,
  [`Deletions`]: `削除`,
  [`Display name`]: `表示名`,
  [`Display Name`]: `表示名`,
  [`Details`]: `詳細`,
  [`Edit GraphQL Rate Limit|title`]: `GraphQL レート制限|タイトルの編集`,
  [`Edit GraphQL rate limits`]: `GraphQL レート制限の編集`,
  [`Edit group`]: `グループの編集`,
  [`Edit condition`]: `条件の編集`,
  [`Edit rate limit|title`]: `レート制限の編集`,
  [`Edit rate limit|menu`]: `レート制限の編集`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `このカタログに公開されている製品がないか、このアプリケーションは既にすべての製品のすべてのプランにサブスクライブされています。`,
  [`Enforce Policy`]: `ポリシーの適用`,
  [`Enter a group summary`]: `グループの要約を入力してください`,
  [`Enter a group title`]: `グループのタイトルを入力してください`,
  [`Enter group details below`]: `グループの詳細を以下に入力してください`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `プロキシー処理する GraphQL サービスの URL およびスキーマ名を入力してください`,
  [`Enum value`]: `列挙値`,
  [`Go to catalog settings to configure a new billing integration`]: `新しい請求統合を構成するには、カタログ設定に移動してください`,
  [`GraphQL introspect`]: `GraphQL イントロスペクト`,
  [`GraphQL server`]: `GraphQL サーバー`,
  [`GraphQL Setting`]: `GraphQL 設定`,
  [`GraphQL server URL`]: `GraphQL サーバー URL`,
  [`GraphQL server name`]: `GraphQL サーバー名`,
  [`GraphQL type analysis configuration`]: `GraphQL タイプの分析の構成`,
  [`GraphQL resolver analysis configuration`]: `GraphQL リゾルバーの分析の構成`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `以下のリストで、グループの作成や管理を行うことができます。`,
  [`Groups will be listed here.`]: `ここにグループがリストされます。`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `{firstName} さん、こんにちは。\n\n{catalog} カタログ内の API 製品 {productName}:{version} を {action} する\n要求が承認されました。\n\n{#if comments}\n承認者から以下のコメントがあります。\n\n{comments}\n\n{{/if}}\n製品の状況を以下のリンクで確認できます。\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `ご連絡\n\nこれは、構成済みメール・サーバー {mailServer} の IBM API Connect から\n送信されたテスト・メッセージです。\n\nこのメッセージが受信できていれば、テストは成功です。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `ご連絡\n\nユーザー {originator} は、{catalog} カタログ内の {productName} API 製品のバージョン {productVersion} の\n{planName} プランに対するアプリケーション {appName}\nのサブスクライブ要求を撤回しました。\n\n追加のアクションは不要です。\n`,
  [`Invoke GraphQL server`]: `GraphQL サーバーの起動`,
  [`Invoke the backend GraphQL server`]: `バックエンド GraphQL サーバーを起動`,
  [`JWKS endpoint`]: `JWKS エンドポイント`,
  [`LDAP Field Name`]: `LDAP フィールド名`,
  [`Limit maximum nesting per query`]: `照会ごとの最大ネストを制限する`,
  [`Limit resolve complexity rate on a per key basis`]: `キー単位で解決の複雑さのレートを制限する`,
  [`Limit type complexity rate on a per key basis`]: `キー単位でタイプの複雑さのレートを制限する`,
  [`Manage APIs included in this product`]: `この製品に含まれている API の管理`,
  [`Manage groups`]: `グループの管理`,
  [`Maximum nesting`]: `最大ネスト`,
  [`Maximum resolve complexity`]: `解決の最大の複雑さ`,
  [`Maximum type complexity`]: `タイプの最大の複雑さ`,
  [`Name of the group`]: `グループの名前`,
  [`New API version`]: `新規 API バージョン`,
  [`New GraphQL API`]: `新規 GraphQL API`,
  [`New product version`]: `新規製品バージョン`,
  [`New Version`]: `新規バージョン`,
  [`New version`]: `新規バージョン`,
  [`No billing integration exists`]: `請求統合が存在しません`,
  [`No CA bundles available`]: `使用可能な CA バンドルがありません`,
  [`No Data`]: `データがありません`,
  [`No certificates available`]: `使用可能な証明書がありません`,
  [`No data`]: `データがありません`,
  [`No group found`]: `グループが見つかりません`,
  [`OAuth Auth URL`]: `OAuth 認証 URL`,
  [`OAuth Token URL`]: `OAuth トークン URL`,
  [`Open Stripe dashboard`]: `Stripeダッシュボードを開く`,
  [`Operation/Path`]: `操作/パス`,
  [`Operation Path`]: `操作パス`,
  [`Operations and Paths`]: `操作とパス`,
  [`Operator`]: `演算子`,
  [`Organizations and groups will be listed here.`]: `ここに組織とグループがリストされます。`,
  [`Page not found`]: `ページが見つかりません`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `以下のコードを CLI にコピーして、プロセスを続行してください。 このコードは、**{ttl}** 後に有効期限が切れます。`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `API キーと秘密鍵にアクセスするには、Stripe ダッシュボードを参照してください`,
  [`Please upload a schema definition language (SDL) file.`]: `スキーマ定義言語 (SDL) ファイルをアップロードしてください。`,
  [`Please upload a valid GraphQL schema.`]: `有効な GraphQL スキーマをアップロードしてください。`,
  [`Please upload a valid YAML file.`]: `有効な YAML ファイルをアップロードしてください。`,
  [`Populate Server Configuration into Context Variables`]: `コンテキスト変数へのサーバー構成の取り込み`,
  [`POST/GET a query to be validated and sent to the backend server`]: `検証されてバックエンド・サーバーに送信される照会を POST/GET`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `当該照会を呼び出す推定コストを取得するための照会を POST/GET`,
  [`Preparing`]: `準備中`,
  [`Rate limit amount cannot be negative`]: `レート制限量を負にすることはできません`,
  [`Rate limit per cannot be negative`]: `対象ごとのレート制限を負にすることはできません`,
  [`Rename API`]: `API の名前変更`,
  [`Rename`]: `名前変更`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `{catalog} カタログ内の API 製品に対する {action} の承認要求`,
  [`Sandbox Sample Application`]: `サンドボックスのサンプル・アプリケーション`,
  [`Sandbox sample application credentials`]: `サンドボックスのサンプル・アプリケーションの資格情報`,
  [`sample application credentials`]: `サンプル・アプリケーションの資格情報`,
  [`Saving`]: `保存中`,
  [`Search Orgs`]: `組織の検索`,
  [`Search definitions...`]: `定義の検索...`,
  [`Search errors...`]: `エラーの検索...`,
  [`Search operations...`]: `操作の検索...`,
  [`Search organizations and groups`]: `組織およびグループの検索`,
  [`Sorry, This feature is not available in`]: `申し訳ありません。この機能は次では使用できません。`,
  [`Stopping`]: `停止中`,
  [`The user with username {username} already exists in the user registry.`]: `ユーザー名 {username} のユーザーは、ユーザー・レジストリーに既に存在します。`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `実行中の API の変更はありません。 この API をサンドボックス・カタログに再公開します。`,
  [`There are no plans available for subscription.`]: `サブスクリプションに使用できるプランはありません。`,
  [`There are no attributes yet.`]: `まだ属性がありません。`,
  [`Twitter`]: `ツイッター`,
  [`Type to add consumer organizations to the group`]: `グループに追加するコンシューマー組織を入力`,
  [`Type to add groups to the organization`]: `組織に追加するグループを入力`,
  [`Type to add organizations from {catalog}`]: `{catalog} から追加する組織を入力`,
  [`Type to add organizations or groups`]: `追加する組織またはグループを入力`,
  [`Type to add organizations or groups from {catalog}`]: `{catalog} から追加する組織またはグループを入力`,
  [`Type to add {title} to groups`]: `グループに追加する {title} を入力`,
  [`Update Rate Limits`]: `レート制限の更新`,
  [`Upload the YAML file with configuration`]: `構成が含まれた YAML ファイルをアップロードします`,
  [`Use the sample below as a template to configure your GraphQL server`]: `GraphQL サーバーを構成するためのテンプレートとして下記のサンプルを使用してください`,
  [`User managed`]: `ユーザー管理`,
  [`URL {url} does not contain valid OpenAPI.`]: `URL {url} に有効なOpenAPIが含まれていません。`,
  [`Validating`]: `妥当性検査中`,
  [`View analytics and performance metrics for your APIs`]: `API の分析およびパフォーマンス・メトリックの表示`,
  [`View and add custom policies to the catalog`]: `カスタム・ポリシーの表示およびカタログへの追加`,
  [`View and add custom exentions to the catalog`]: `カスタム拡張の表示およびカタログへの追加`,
  [`View user registries and OAuth providers`]: `ユーザー・レジストリーおよび OAuth プロバイダーの表示`,
  [`View your application and manage API credentials`]: `アプリケーションの表示と API 資格情報の管理`,
  [`Welcome to the API Connect Trial`]: `API Connect 試用版へようこそ`,
  [`You haven't added any APIs`]: `API はまだ追加されていません`,
  [`You currently don’t have any {resource}.`]: `現在、{resource} はありません。`,
  [`plans to migrate`]: `マイグレーションのプラン`,
  [`gateway extensions`]: `ゲートウェイ拡張`,
  [`application`]: `アプリケーション`,
  [`application authentication source`]: `アプリケーション認証ソース`,
  [`API or product`]: `API または製品`,
  [`base endpoints`]: `基本エンドポイント`,
  [`categories`]: `カテゴリー`,
  [`certificates`]: `証明書`,
  [`channels`]: `チャネル`,
  [`consumer organizations`]: `コンシューマー組織`,
  [`consumer organization group`]: `コンシューマー組織グループ`,
  [`consumer organizations or groups`]: `コンシューマー組織またはグループ`,
  [`correlation ids`]: `相関 ID`,
  [`cors policy`]: `CORS ポリシー`,
  [`definitions`]: `定義`,
  [`examples`]: `例`,
  [`gateway services`]: `ゲートウェイ・サービス`,
  [`groups`]: `グループ`,
  [`headers`]: `ヘッダー`,
  [`links`]: `リンク`,
  [`member`]: `メンバー`,
  [`messages`]: `messages`,
  [`message traits`]: `メッセージの特性`,
  [`operation traits`]: `操作の特性`,
  [`parameter definitions`]: `パラメーター定義`,
  [`parameters`]: `パラメーター`,
  [`path`]: `パス`,
  [`properties`]: `プロパティー`,
  [`provider organizations`]: `プロバイダー組織`,
  [`request bodies`]: `要求の本文`,
  [`response definitions`]: `応答の定義`,
  [`responses`]: `応答`,
  [`role`]: `ロール`,
  [`scope`]: `スコープ`,
  [`scopes`]: `スコープ`,
  [`schemas`]: `スキーマ`,
  [`schema definition`]: `スキーマ定義`,
  [`schemes list`]: `スキーム・リスト`,
  [`security definitions`]: `セキュリティー定義`,
  [`security schemes`]: `セキュリティー・スキーム`,
  [`servers`]: `サーバー`,
  [`services`]: `サービス`,
  [`tags`]: `タグ`,
  [`tags and external documentation`]: `タグと外部ドキュメンテーション`,
  [`target services`]: `ターゲット・サービス`,
  [`tasks`]: `タスク`,
  [`user registries`]: `ユーザー・レジストリ`,
  [`HTTP header`]: `HTTP ヘッダー`,
  [`You currently don’t have any application authentication source.`]: `現在、アプリケーション認証ソースがありません。`,
  [`You currently don’t have any API or product.`]: `現在、API も製品もありません。`,
  [`You currently don’t have any API user registries`]: `現在、API ユーザー・レジストリーはありません`,
  [`You currently don’t have any APIs.`]: `現在、API はありません。`,
  [`You currently don’t have any TLS client profiles`]: `現在、TLS クライアント・プロファイルはありません`,
  [`You currently don’t have any base endpoints.`]: `現在、基本エンドポイントはありません。`,
  [`You currently don’t have any catalog properties.`]: `現在、カタログ・プロパティーがありません。`,
  [`You currently don’t have any categories.`]: `現在のユーザーにはカテゴリーがありません。`,
  [`You currently don’t have any certificates.`]: `現在、証明書がありません。`,
  [`You currently don’t have any channels.`]: `現在、チャネルがありません。`,
  [`You currently don’t have any consumer organizations.`]: `現在、コンシューマー組織はありません。`,
  [`You currently don’t have any consumer organizations or groups.`]: `現在、コンシューマー組織またはグループはありません。`,
  [`You currently don’t have any consumes.`]: `現在、コンシュームがありません。`,
  [`You currently don’t have any correlation ids.`]: `現在、相関 ID がありません。`,
  [`You currently don’t have any cors policy.`]: `現在、CORSポリシーがありません。`,
  [`You currently don’t have any definitions.`]: `現在、定義がありません。`,
  [`You currently don’t have any examples.`]: `現在、例はありません。`,
  [`You currently don’t have any gateway services.`]: `現在、ゲートウェイ・サービスはありません。`,
  [`You currently don’t have any groups.`]: `現在、グループはありません。`,
  [`You currently don’t have any headers.`]: `現在、ヘッダーがありません。`,
  [`You currently don’t have any keystore.`]: `現在、鍵ストアはありません。`,
  [`You currently don’t have any links.`]: `現在、リンクがありません。`,
  [`You currently don’t have any messages.`]: `現在、メッセージがありません。`,
  [`You currently don’t have any message traits.`]: `現在、メッセージの特性はありません。`,
  [`You currently don’t have any OAuth providers`]: `現在、OAuth プロバイダーはありません`,
  [`You currently don’t have any operation traits.`]: `現時点では、操作の特性はありません。`,
  [`You currently don’t have any parameter definitions.`]: `現在、パラメーター定義がありません。`,
  [`You currently don’t have any parameters.`]: `現在、パラメーターはありません。`,
  [`You currently don’t have any preserved request header.`]: `現在保存されている要求ヘッダーがありません。`,
  [`You currently don’t have any preserved response header.`]: `現在保存されている応答ヘッダーがありません。`,
  [`You currently don’t have any produces.`]: `現在、生成されたものがありません。`,
  [`You currently don’t have any properties.`]: `現在、プロパティーはありません。`,
  [`You currently don’t have any provider organizations.`]: `現在、プロバイダー組織はありません。`,
  [`You currently don’t have any request bodies.`]: `現在、要求本文がありません。`,
  [`You currently don’t have any response definitions.`]: `現在、応答定義がありません。`,
  [`You currently don’t have any responses.`]: `現在、応答がありません。`,
  [`You currently don’t have any scopes.`]: `現在、スコープはありません。`,
  [`You currently don’t have any schemas.`]: `現在、スキーマがありません。`,
  [`You currently don’t have any schemes list.`]: `現在、スキーム・リストがありません。`,
  [`You currently don’t have any security definitions.`]: `現在、セキュリティー定義はありません。`,
  [`You currently don’t have any security schemes.`]: `現在、セキュリティー・スキームがありません。`,
  [`You currently don’t have any servers.`]: `現在、サーバーがありません。`,
  [`You currently don’t have any services.`]: `現在、サービスはありません。`,
  [`You currently don’t have any tags.`]: `現在、タグがありません。`,
  [`You currently don’t have any tags and external documentation.`]: `現在、タグと外部ドキュメンテーションはありません。`,
  [`You currently don’t have any target services.`]: `現在、ターゲット・サービスがありません。`,
  [`You currently don’t have any tasks.`]: `現在、タスクはありません。`,
  [`You currently don’t have any truststore.`]: `現在、トラストストアはありません。`,
  [`You currently don’t have any user registries.`]: `現在、ユーザー・レジストリーはありません。`,
  [`You currently don’t have any extensions.`]: `現在、拡張はありません。`,
  [`You currently don’t have any HTTP header.`]: `現在、HTTP ヘッダーはありません。`,
  [`Your account is being activated. This may take a while...`]: `アカウントのアクティブ化が進行中です。 これにはしばらく時間がかかる場合があります...`,
  [`all of`]: `次のすべて`,
  [`any of`]: `次のいずれか`,
  [`calls`]: `呼び出し`,
  [`ciphers`]: `暗号`,
  [`extensions`]: `拡張`,
  [`one of`]: `次のうち 1 つ`,
  [`shared`]: `共有`,
  [`stopping`]: `停止中`,
  [`to:`]: `が必要な操作は以下の通りです:`,
  [`Key`]: `キー`,
  [`kind`]: `種類`,
  [`Trace`]: `トレース`,
  [`Http Status`]: `HTTP ステータス`,
  [`Parsed`]: `解析済み`,
  [`Raw`]: `未加工`,
  [`Showing Full Trace`]: `全トレースの表示中`,
  [`Show Entire Trace`]: `トレース全体の表示`,
  [`Show advanced`]: `拡張の表示`,
  [`Advanced`]: `拡張`,
  [`Sending Request`]: `要求の送信`,
  [`Please send a request above`]: `上記の要求を送信してください`,
  [`Please send a request above.`]: `上記の要求を送信してください。`,
  [`Authentication Type`]: `認証タイプ`,
  [`You don't currently have any response`]: `現在、応答はありません`,
  [`You don't currently have any trace.`]: `現在、トレースはありません。`,
  [`No response received`]: `応答を受信しませんでした`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `「例外を受け入れるためのリンクを開く」をクリックすると、サーバーが新しいタブで開きます。 ブラウザーに証明書の問題が表示される場合は、それを受け入れることを選択し、ここに戻って再度テストすることができます。`,
  [`Open link to accept exception`]: `例外を受け入れるためのリンクを開く`,
  [`Back to {name}`]: `{name} に戻る`,
  [`0 seconds`]: `0 秒`,
  [`close.menu`]: `メニューを閉じる`,
  [`open.menu`]: `メニューを開く`,
  [`Referenced from "{ref}"`]: `参照元: "{ref}"`,
  [`Go to {definition}`]: `{definition} に移動`,
  [`General`]: `全般`,
  [`Servers`]: `サーバー`,
  [`External Docs`]: `外部ドキュメンテーション`,
  [`Components`]: `コンポーネント`,
  [`Schemas`]: `スキーマ`,
  [`Responses`]: `応答`,
  [`Examples`]: `例`,
  [`Request Bodies`]: `要求の本文`,
  [`Security Schemes`]: `セキュリティー・スキーム`,
  [`Links`]: `リンク`,
  [`Callbacks`]: `コールバック`,
  [`Terms of Service`]: `サービスのご利用条件`,
  [`Contact Name`]: `連絡先名`,
  [`Contact URL`]: `連絡先 URL`,
  [`Contact Email`]: `連絡先 E メール`,
  [`Contact sales`]: `営業担当へのお問い合わせ`,
  [`License Name`]: `ライセンス名`,
  [`License URL`]: `ライセンス URL`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `MTOM/XOP 最適化メッセージを受け入れます`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `トークン`,
  [`Tag`]: `タグ`,
  [`Request Body`]: `要求の本文`,
  [`Security Requirements`]: `セキュリティー要件`,
  [`Located In`]: `場所`,
  [`Example Value`]: `サンプル値`,
  [`External Value`]: `外部値`,
  [`Server`]: `サーバー`,
  [`Server Description`]: `サーバーの説明`,
  [`Server Variables`]: `サーバー変数`,
  [`Format`]: `フォーマット`,
  [`Pattern`]: `パターン`,
  [`Multiple Of`]: `次の倍数:`,
  [`Multiple non null slicing arguments`]: `複数の非ヌル・スライス引数`,
  [`Missing slicing arguments`]: `スライス引数がありません`,
  [`Missing sized fields`]: `サイズ設定されたフィールドがありません`,
  [`Maximum`]: `最大`,
  [`Exclusive Maximum`]: `境界値を含まない最大値`,
  [`Minimum`]: `最小`,
  [`Exclusive Minimum`]: `境界値を含まない最小値`,
  [`Max Length`]: `最大長`,
  [`Min Length`]: `最小長`,
  [`Max Items`]: `最大項目数`,
  [`Min Items`]: `最小項目数`,
  [`Unique Items`]: `固有の項目数`,
  [`Max Properties`]: `最大プロパティー数`,
  [`Min Properties`]: `最小プロパティー数`,
  [`Required Properties`]: `必須プロパティー数`,
  [`Required Property Name`]: `必須プロパティー名`,
  [`Enum`]: `列挙`,
  [`Enum Value`]: `列挙値`,
  [`Nullable`]: `ヌル可能`,
  [`Read Only`]: `読み取り専用`,
  [`Write Only`]: `書き込み専用`,
  [`api`]: `API`,
  [`version`]: `バージョン`,
  [`username`]: `ユーザー名`,
  [`password`]: `パスワード`,
  [`service`]: `サービス`,
  [`Allows Empty Value`]: `空値を許可`,
  [`Style`]: `スタイル`,
  [`Explode`]: `展開`,
  [`Allow Reserved`]: `予約済みを許可`,
  [`Allow WebSocket Upgrade`]: `WebSocket アップグレードを許可`,
  [`Ref`]: `参照`,
  [`Operation ID`]: `操作 ID`,
  [`Parameter`]: `パラメーター`,
  [`Gateway and portal settings`]: `ゲートウェイおよびポータルの設定`,
  [`Targets`]: `ターゲット`,
  [`Catalog Properties`]: `カタログ・プロパティー`,
  [`catalog properties`]: `カタログのプロパティー`,
  [`Attachments`]: `添付ファイル`,
  [`Activity Log`]: `アクティビティー・ログ`,
  [`API Type`]: `API タイプ`,
  [`Phase`]: `フェーズ`,
  [`Enable CORS`]: `CORS を有効にする`,
  [`CORS Policy`]: `CORS ポリシー`,
  [`Allowed Origins`]: `許可されたオリジン`,
  [`Allow Credentials`]: `資格情報を許可`,
  [`ID`]: `ID`,
  [`Success Content`]: `成功の内容`,
  [`Error Content`]: `エラーの内容`,
  [`Attachment`]: `添付ファイル`,
  [`Link`]: `リンク`,
  [`Operation Ref`]: `操作参照`,
  [`TRACE`]: `トレース`,
  [`No Security Requirements available`]: `使用可能なセキュリティー要件がありません`,
  [`Create a security scheme`]: `セキュリティー・スキームの作成`,
  [`Create a Security Requirement`]: `セキュリティー要件の作成`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `以下のセキュリティー要件のいずれかが必須です。 チェック・マークが付いていない場合、セキュリティーはオプションです。`,
  [`Require wrappers on fault details specified by type`]: `タイプ別に指定された障害の詳細にラッパーが必要`,
  [`There are no "Servers"`]: `「サーバー」がありません`,
  [`Write`]: `書き込み`,
  [`Server Variable`]: `サーバー変数`,
  [`Security Requirement`]: `セキュリティー要件`,
  [`Security Scheme Type`]: `セキュリティー・スキーム・タイプ`,
  [`Security Scheme`]: `セキュリティー・スキーム`,
  [`OAuth Flows`]: `OAuth フロー`,
  [`Implicit Flow`]: `暗黙的なフロー`,
  [`Password Flow`]: `パスワード・フロー`,
  [`Client Credentials Flow`]: `クライアント資格情報フロー`,
  [`Authorization Code Flow`]: `許可コード・フロー`,
  [`Refresh URL`]: `リフレッシュ URL`,
  [`schema`]: `スキーマ`,
  [`Allow Empty Value`]: `空値を許可`,
  [`Encoding`]: `エンコード`,
  [`This field is required`]: `このフィールドは必須です`,
  [`Temporary token`]: `一時トークン`,
  [`Access token`]: `アクセス・トークン`,
  [`ID token`]: `ID トークン`,
  [`No Security Schemes available`]: `使用可能なセキュリティー・スキーマがありません`,
  [`Security Scheme (Key)`]: `セキュリティー・スキーム (キー)`,
  [`There are no "{label}"`]: `「{label}」がありません`,
  [`You must select at least one scheme to define a Security Requirement`]: `セキュリティー要件を定義するには、少なくとも 1 つのスキームを選択する必要があります`,
  [`Select one or more Security Schemes`]: `1 つ以上のセキュリティー・スキームを選択してください`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `「{key}」セキュリティー・スキームに対して使用できる OAuth スコープはありません`,
  [`Create a Scope`]: `スコープの作成`,
  [`Remove this Security Requirement`]: `このセキュリティー要件の削除`,
  [`Edit this Security Requirement`]: `このセキュリティー要件の編集`,
  [`Invalid Security Scheme Reference`]: `セキュリティー・スキームの参照が無効です`,
  [`The following Security Schemes do not exist!`]: `次のセキュリティー・スキームは存在しません。`,
  [`Edit source`]: `ソースの編集`,
  [`OR`]: `または`,
  [`Required Security Schemes`]: `必須のセキュリティー・スキーム`,
  [`Optional Security`]: `オプションのセキュリティー`,
  [`Optional custom expression (one per line)`]: `オプションのカスタム式（1行に1つ）`,
  [`Flow Type`]: `フロー・タイプ`,
  [`OAuth2 Security Scheme`]: `OAuth2 セキュリティー・スキーム`,
  [`Only source editing is supported.`]: `ソースの編集のみがサポートされています。`,
  [`Unsupported Field.`]: `サポートされないフィールドです。`,
  [`Select an option`]: `オプションの選択`,
  [`Select {labelText}`]: `{labelText} の選択`,
  [`'"{changedValue}" is already used'`]: `「「{changedValue}」は既に使用されています」`,
  [`Referenced from "{originalRef}"`]: `参照元: "{originalRef}"`,
  [`Go to {label}`]: `{label} に移動`,
  [`Delete "{label}"`]: `「{label}」の削除`,
  [`Are you sure you want to delete "{label}"?`]: `「{label}」を削除しますか?`,
  [`No recent items found`]: `最近の項目は見つかりません`,
  [`Found {numDocs} results ({took} seconds)`]: `{numDocs} 件の結果が見つかりました ({took} 秒)`,
  [`No Consumer Organizations found`]: `コンシューマー組織が見つかりません`,
  [`No Catalog Products found`]: `カタログ製品が見つかりません`,
  [`No APIs found`]: `API が見つかりません`,
  [`No Catalogs found`]: `カタログが見つかりません`,
  [`No Spaces found`]: `スペースが見つかりません`,
  [`No results found`]: `結果が見つかりません`,
  [`No Catalog APIs found`]: `カタログ API が見つかりません`,
  [`No Catalogs found matching query "{query}"`]: `照会「{query}」に一致するカタログが見つかりません`,
  [`No APIs found matching query "{query}"`]: `照会「{query}」に一致する API が見つかりません`,
  [`No Subscriptions found`]: `サブスクリプションがありません`,
  [`No Plans found matching query "{query}"`]: `照会「{query}」に一致するプランが見つかりません`,
  [`No Subscriptions found matching query "{query}"`]: `照会「{query}」に一致するサブスクリプションが見つかりません`,
  [`No Catalog Products found matching query "{query}"`]: `照会「{query}」に一致するカタログの製品が見つかりません`,
  [`No Catalog APIs found matching query "{query}"`]: `照会「{query}」に一致するカタログ API が見つかりません`,
  [`No Consumer Organizations found matching query "{query}"`]: `照会「{query}」に一致するコンシューマー組織が見つかりません`,
  [`No Applications found matching query "{query}"`]: `照会「{query}」に一致するアプリケーションが見つかりません`,
  [`APIs per page`]: `API/ページ`,
  [`Catalogs per page`]: `カタログ/ページ`,
  [`items per page`]: `項目/ページ`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max}/{total} API`,
  [`{min}-{max} of {total} API`]: `{min}-{total} の {max} API`,
  [`Products per page`]: `製品/ページ`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `新しい請求統合を構成するには、製品をカタログに公開する必要があります`,
  [`{min}-{max} of {total} Products`]: `{min}-{max}/{total} 製品`,
  [`{min}-{max} of {total} Product`]: `{min}-{total} の {max} 製品`,
  [`Catalog Products per page`]: `カタログ製品/ページ`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max}/{total} カタログ製品`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{total} の {max} カタログ製品`,
  [`Plans per page`]: `プラン/ページ`,
  [`Policies per page`]: `ポリシー/ページ`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max}/{total} プラン`,
  [`{min}-{max} of {total} Plan`]: `{min}-{total} の {max} プラン`,
  [`Catalog APIs per page`]: `カタログ API/ページ`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max}/{total} カタログ API`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{total} の {max} カタログ API`,
  [`Subscriptions per page`]: `サブスクリプション/ページ`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max}/{total} サブスクリプション`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{total} の {max} サブスクリプション`,
  [`Consumer Organizations per page`]: `コンシューマー組織/ページ`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max}/{total} コンシューマー組織`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{total} の {max} コンシューマー組織`,
  [`Applications per page`]: `アプリケーション/ページ`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max}/{total} アプリケーション`,
  [`{min}-{max} of {total} Application`]: `{min}-{total} の {max} アプリケーション`,
  [`Histories per page`]: `履歴/ページ`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max}/{total} 履歴`,
  [`{min}-{max} of {total} History`]: `{min}-{total} の {max} 履歴`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `サブスクライブしていると考えられるプランが見つかりません。 サブスクリプションを作成するには、製品が「公開済み」の状態である必要があります。 アプリケーションがサブスクライブできるのは、製品の 1 つのプランのみです。`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `公開済みの「{draftApiTitle}:{draftApiVersion}」API を表示しています`,
  [`Showing Consumer Organization for "{appTitle}"`]: `「{appTitle}」のコンシューマー組織を表示しています`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `「{productTitle}:{productVersion}」のサブスクリプションを表示しています`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `「{productTitle}:{productVersion}」のプランを表示しています`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `「{productTitle}:{productVersion}」の API を表示しています`,
  [`Showing Product for "{planTitle}"`]: `「{planTitle}」の製品を表示しています`,
  [`Showing APIs for "{planTitle}"`]: `「{planTitle}」の API を表示しています`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `製品「{docFoundIn} のプラン「{docTitle}」に対するサブスクリプションを表示しています`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `「{apiTitle}:{apiVersion}」のプランを表示しています`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `「{catalogApiTitle}:{catalogApiVersion}」のドラフト API を表示しています`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `「{productTitle}:{productVersion}」の履歴を表示しています`,
  [`Showing Subscriptions for "{appTitle}"`]: `「{appTitle}」のサブスクリプションを表示しています`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `「{consumerOrgTitle}」のアプリケーションを表示しています`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `「{consumerOrgTitle}」のサブスクリプションを表示しています`,
  [`Showing Application for selected Subscription`]: `選択したサブスクリプションのアプリケーションを表示しています`,
  [`Showing Plan for selected Subscription`]: `選択したサブスクリプションのプランを表示しています`,
  [`Showing Product for selected Subscription`]: `選択したサブスクリプションの製品を表示しています`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `「{productTitle}」製品に含めるドラフト API を選択してください`,
  [`Product Version`]: `製品のバージョン`,
  [`OAuth Providers`]: `OAuth プロバイダー`,
  [`Application Type`]: `アプリケーション・タイプ`,
  [`Client IDs`]: `クライアント ID`,
  [`Save as New Version`]: `新規バージョンとして保存`,
  [`Update Selected APIs`]: `選択した API の更新`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion} API は完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion} 製品は完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion} カタログの製品は完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion} コンシューマー組織は完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion}コンシューマー・グループは完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion} ポリシーは完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion} サブスクリプションは完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion}カタログは完全に削除されます。 続行しますか?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion}スペースは完全に削除されます。 続行しますか?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `{titleAndVersion} カタログの製品は再公開されます。 続行しますか?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `{titleAndVersion} カタログの製品は廃棄されます。 続行しますか?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `{titleAndVersion} カタログの製品は非推奨になります。 続行しますか?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `{titleAndVersion} カタログの製品は再ステージングされます。 続行しますか?`,
  [`Put Online`]: `オンラインにする`,
  [`Take Offline`]: `オフラインにする`,
  [`View Subscriptions`]: `サブスクリプションの表示`,
  [`View Plans`]: `プランの表示`,
  [`No Tasks found`]: `タスクが見つかりません`,
  [`Tasks per page`]: `タスク/ページ`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max}/{total} タスク`,
  [`{min}-{max} of {total} Task`]: `{min}-{total} の {max} タスク`,
  [`Decline and Send`]: `同意せずに送信`,
  [`View Applications`]: `アプリケーションの表示`,
  [`Publish (Preserve Subscriptions)`]: `公開 (サブスクリプションを保持)`,
  [`Update APIs`]: `API の更新`,
  [`View in Catalogs`]: `カタログに表示`,
  [`View Consumer Organization`]: `コンシューマー組織の表示`,
  [`View Application`]: `アプリケーションの表示`,
  [`View Plan`]: `プランの表示`,
  [`View Product`]: `製品の表示`,
  [`Create Subscription`]: `サブスクリプションの作成`,
  [`No Applications found`]: `アプリケーションが見つかりません`,
  [`Select a Plan to Create a Subscription to`]: `サブスクリプションを作成するプランを選択してください`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `このアクションは元に戻すことができません。 {titleAndVersion} アプリケーションは完全に削除されます。 続行しますか?`,
  [`Save as new version`]: `新規バージョンとして保存`,
  [`Filter selected catalogs`]: `選択したカタログのフィルタリング`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `「{productTitle}」製品に含めるドラフト API を選択してください`,
  [`Update selected APIs`]: `選択した API の更新`,
  [`Catalog API`]: `カタログ API`,
  [`Catalog APIs`]: `カタログ API`,
  [`View Products`]: `製品の表示`,
  [`View Draft APIs`]: `ドラフト API の表示`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `「{apiTitle}:{apiVersion}」を参照する製品を表示しています`,
  [`No Plans found`]: `プランが見つかりません`,
  [`View APIs`]: `API の表示`,
  [`Document type tabs`]: `「ドキュメント・タイプ」タブ`,
  [`No Products found`]: `製品が見つかりません`,
  [`Consumer Organization`]: `コンシューマー組織`,
  [`Consumer Organizations`]: `コンシューマー組織`,
  [`Catalog Product`]: `カタログの製品`,
  [`Catalog Products`]: `カタログの製品`,
  [`Search for a Product and Plan`]: `製品とプランの検索`,
  [`OpenAPI Version`]: `OpenAPI バージョン`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (REST、GraphQL、または SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (Kafka トピック)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `AsyncAPI (Kafka トピック) の作成`,
  [`An error occurred during file upload`]: `ファイルのアップロード中にエラーが発生しました`,
  [`Delete uploaded file`]: `アップロードしたファイルの削除`,
  [`Copied!`]: `コピーされました。`,
  [`Uploading`]: `アップロード中`,
  [`Validation icon`]: `検証アイコン`,
  [`Validation error`]: `検証エラー`,
  [`Uploading image..`]: `イメージのアップロード中..`,
  [`Unsupported media type {providedFileType}`]: `サポートされないメディア・タイプ {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `サポートされるファイル {expectedFileTypes} を選択し、再試行してください`,
  [`Pasted`]: `貼り付け`,
  [`Enter the API summary details.`]: `API 要約の詳細を入力してください。`,
  [`Define your Kafka cluster connection details.`]: `Kafka クラスター接続の詳細を定義してください。`,
  [`Channel`]: `チャネル`,
  [`Describe your Kafka topic and its associated schema.`]: `Kafka トピックとその関連するスキーマについて説明してください。`,
  [`AsyncAPI specification version`]: `AsyncAPI 仕様のバージョン`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `開発者が開発者ポータルで検出しやすくなるように API を記述する名前。`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `API 定義のバージョン。 名前とバージョンを組み合わせることで API 定義が一意的に識別されます。`,
  [`Description (optional)`]: `説明 (オプション)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `API を導入し、その API を使用する開発者に役立つ情報を入力してください。`,
  [`Summary (optional)`]: `要約 (オプション)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `この API に含まれているイベントの簡単な要約。 最大文字数は 1000 文字です。`,
  [`Bootstrap servers`]: `ブートストラップ・サーバー`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `クラスター内の Kafka ブローカーをコンマで区切ったリスト。`,
  [`Secure the API using an API Key and Secret`]: `API キーと秘密鍵を使用して API を保護`,
  [`The credential provided should be in SASL-PLAIN format`]: `指定される資格情報は SASL-PLAIN 形式である必要があります`,
  [`Publish this API:`]: `この API の公開:`,
  [`Creates a product`]: `製品の作成`,
  [`Associates this API to the product`]: `この API を製品に関連付けます`,
  [`Publish the product to the Sandbox catalog`]: `製品をサンドボックス・カタログに公開します`,
  [`Associates the product to the test app`]: `製品をテスト・アプリケーションに関連付けます`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `「{fileType}」形式のスキーマが正常にアップロードおよび検証されました。`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `アップロードされたファイルは有効な Avro スキーマではないため、API の一部として組み込まれません。`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `サーバーの応答:「{err}」。 「戻る」をクリックして API 定義のページに戻ってください。`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `サーバーの応答:「{err}」。 「完了」をクリックしてウィザードを終了してください。`,
  [`Topic name`]: `トピック名`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `この API の一部として公開するトピックの名前。 トピックとは、名前付きのメッセージ・ストリームです。`,
  [`Schema for message body in .avsc format (optional)`]: `.avsc 形式のメッセージ本体のスキーマ (オプション)`,
  [`Drag and drop files here, or click to upload`]: `ここにファイルをドラッグ・アンド・ドロップするか、クリックしてアップロードしてください`,
  [`About this step`]: `このステップについて`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `イベントは、使用可能になった時点でコンシューマーによって受信されるため、非同期 API として記述できます。 一方、REST などの同期 API は、イベントを要求した場合にのみ応答を受信します。`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `イベント・ドリブン型の非同期 API は、AsyncAPI 仕様で文書化されます。`,
  [`API secret`]: `API 秘密鍵`,
  [`Generated AsyncAPI 2.1.0 definition`]: `AsyncAPI 2.1.0 定義が生成されました`,
  [`API creating`]: `API 作成中`,
  [`An error occurred while creating your document`]: `文書の作成中にエラーが発生しました`,
  [`Publishing your API`]: `APIの公開中`,
  [`Your API has been published`]: `API が公開されました`,
  [`An error occurred while publishing your API`]: `API の公開中にエラーが発生しました`,
  [`Configure security for this API`]: `この API のセキュリティーを構成します`,
  [`Cluster connection security`]: `クラスター接続セキュリティー`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `この API への要求を処理する際に、イベント・ゲートウェイ・サービスがクラスターに接続するために使用する資格情報を指定します。 これらの値は、後でエディターで変更できます。 詳しくは、資格情報の作成に関する資料を参照してください。`,
  [`SASL Username`]: `SASL ユーザー名`,
  [`SASL Password`]: `SASL パスワード`,
  [`Transport CA certificate (.pem file - optional)`]: `CA 証明書のトランスポート (.pem ファイル - オプション)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `提供された場合、これはイベント・ゲートウェイ・サービスがゲートウェイと Kafka クラスターの間の通信に TLS 暗号化を提供するために使用されます。`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `AsyncAPI 文書を生成するための Apache Kafka イベント・ソースの API 詳細を記述します。`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `その後、AsyncAPI 文書を公開してイベント・ソースをアプリケーション開発者に公開することができます。アプリケーション開発者は、関連するイベント・ストリームをサブスクライブするようにアプリケーションを構成できます。`,
  [`Set up security for your API if you want to control who can access it.`]: `API にアクセスできるユーザーを制御する場合は、その API のセキュリティーをセットアップします。`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `API へのアクセスは、有効な API キーと秘密鍵を検査するイベント・ゲートウェイ・サービスによって管理されます。`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `ゲートウェイでは、クライアントが TLS で通信を暗号化する必要があります。`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `API が作成されたら、「API の編集」をクリックして、イベント・ゲートウェイ・サービスから Kafka クラスターに接続するためのセキュリティーの詳細など、詳細情報を追加します。`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `その後、API を公開して開発者が使用できるようにします。これにより、開発者は API をディスカバーしてその API へのアクセスを要求し、その情報を使用して、アプリケーションがイベント・ソースからコンシュームするように設定できるようになります。`,
  [`Pending backend response`]: `バックエンドの応答が保留されています`,
  [`Complete`]: `完了`,
  [`Rate limiting is only applied to enforced APIs`]: `レート制限は強制された API のみに適用されます`,
  [`No Plans Available`]: `使用可能なプランがありません`,
  [`Modal content is not provided`]: `モーダルのコンテンツが指定されていません`,
  [`Modal heading is not provided`]: `モーダルの見出しが指定されていません`,
  [`Edit Rate Limit`]: `レート制限の編集`,
  [`External Documentation`]: `外部ドキュメンテーション`,
  [`No Label Available`]: `使用可能なラベルがありません`,
  [`optional`]: `オプション`,
  [`Uploading image...`]: `イメージのアップロード中...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `ファイルをドラッグ、ドロップ、選択、または貼り付けて添付します。`,
  [`Response name`]: `応答名`,
  [`Click Add to add a new {resource}.`]: `新規 {resource} を追加するには、「追加」をクリックしてください。`,
  [`Schema Name (Key)`]: `スキーマ名 (キー)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `名前空間`,
  [`Attribute`]: `属性`,
  [`Wrapped`]: `ラップ`,
  [`Example`]: `例`,
  [`Example Name (Key)`]: `例の名前 (キー)`,
  [`"{changedValue}" is already used`]: `「{changedValue}」は既に使用されています`,
  [`Name (Key)`]: `名前 (キー)`,
  [`Content Type (Key)`]: `コンテンツ・タイプ (Key)`,
  [`Header name`]: `ヘッダー名`,
  [`Reference`]: `参照`,
  [`Security Scheme Name (Key)`]: `セキュリティー・スキーム名 (キー)`,
  [`Choose a Reference`]: `リファレンスの選択`,
  [`Reference from`]: `リファレンス元`,
  [`Content Type`]: `コンテンツ・タイプ`,
  [`array`]: `配列`,
  [`boolean`]: `ブール`,
  [`integer`]: `整数`,
  [`number`]: `数値`,
  [`object`]: `オブジェクト`,
  [`string`]: `ストリング`,
  [`query`]: `照会`,
  [`cookie`]: `Cookie`,
  [`matrix`]: `マトリックス`,
  [`label`]: `ラベル`,
  [`simple`]: `シンプル`,
  [`form`]: `フォーム`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth プロバイダー (名前)`,
  [`Authenticate using User Registry (name)`]: `ユーザー・レジストリー (名前) を使用した認証`,
  [`Bearer Format`]: `ベアラー・フォーマット`,
  [`Server Variable Name`]: `サーバー変数名`,
  [`Verb (Key)`]: `動詞 (キー)`,
  [`Parameter Definitions`]: `パラメーター定義`,
  [`Path Parameters`]: `パス・パラメーター`,
  [`Media Type List`]: `メディア・タイプ・リスト`,
  [`Medium`]: `中程度`,
  [`Schemes List`]: `スキーム・リスト`,
  [`Small`]: `小`,
  [`Response Definitions`]: `応答の定義`,
  [`Terms Of Service`]: `サービスのご利用条件`,
  [`Url`]: `URL`,
  [`Untitled Parameter`]: `タイトルなしパラメーター`,
  [`Parameter Name`]: `パラメーター名`,
  [`Mime Type`]: `MIME タイプ`,
  [`Async API`]: `非同期 API`,
  [`Channels`]: `チャネル`,
  [`Operation Traits`]: `操作の特性`,
  [`Messages`]: `メッセージ`,
  [`Correlation IDs`]: `相関 ID`,
  [`Traits`]: `特性`,
  [`Message Traits`]: `メッセージの特性`,
  [`Bindings`]: `バインディング`,
  [`Server Bindings`]: `サーバー・バインディング`,
  [`Channel Bindings`]: `チャネル・バインディング`,
  [`Operation Bindings`]: `操作バインディング`,
  [`Message Bindings`]: `メッセージ・バインディング`,
  [`Protocol Version`]: `プロトコル・バージョン`,
  [`Define multiple messages`]: `複数のメッセージの定義`,
  [`Schema Format`]: `スキーマ形式`,
  [`Unsupported Field`]: `サポートされないフィールド`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `操作の特性`,
  [`Message Trait`]: `メッセージの特性`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `アセンブリーの一部分を特定の操作に適用するには、操作切り替えポリシーを使用します。`,
  [`HTML page`]: `HTML ページ`,
  [`Rule`]: `ルール`,
  [`Origin`]: `オリジン`,
  [`Property Name (Key)`]: `プロパティー名 (キー)`,
  [`Catalog Name (Key)`]: `カタログ名 (キー)`,
  [`Property Overrides`]: `プロパティーのオーバーライド`,
  [`Property Name`]: `プロパティー名`,
  [`Property Value`]: `プロパティー値`,
  [`About subscriptions and plans`]: `サブスクリプションとプランについて`,
  [`Subscription Plan Name`]: `サブスクリプション・プラン名`,
  [`Select plan`]: `プランの選択`,
  [`Select the plan to migrate application subscriptions from`]: `アプリケーション・サブスクリプションのマイグレーション元となるプランを選択します`,
  [`Select subscriptions`]: `サブスクリプションを選択`,
  [`Select the application subscriptions to migrate`]: `マイグレーションするアプリケーション・サブスクリプションの選択`,
  [`Select the product to migrate application subscriptions to`]: `アプリケーション・サブスクリプションのマイグレーション先となる製品を選択します`,
  [`Select the plan to migrate application subscriptions to`]: `アプリケーション・サブスクリプションのマイグレーション先となるプランを選択します`,
  [`Message successfully sent to {sent} owner(s)`]: `メッセージが {sent} 人の所有者に正常に送信されました`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} 件のメッセージの送信に失敗しました`,
  [`Failed email addresses`]: `失敗した E メール・アドレス`,
  [`Failed to save the API`]: `API を保存できませんでした`,
  [`and {numExtra} more...`]: `さらに {numExtra} 件...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `送信に失敗した E メール・アドレスのリストをクリップボードにコピーします`,
  [`From`]: `From`,
  [`Message Owner`]: `メッセージ所有者`,
  [`Message owner`]: `メッセージ所有者`,
  [`Message owners`]: `メッセージ所有者`,
  [`Message group`]: `メッセージ・グループ`,
  [`Select consumer organizations`]: `コンシューマー組織の選択`,
  [`Message consumer organization owners`]: `メッセージ・コンシューマー組織の所有者`,
  [`Message consumer organization owner`]: `メッセージ・コンシューマー組織の所有者`,
  [`Message provider organization owner`]: `メッセージ・プロバイダー組織の所有者`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `この Invoke ポリシーに関連する Kafka クラスターの ID`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Kafka クラスターへの接続を確立するために使用する host:port ペアのコンマ区切りリスト`,
  [`The username to use when connecting to the Kafka cluster.`]: `Kafka クラスターに接続するときに使用するユーザー名。`,
  [`The password to use when connecting to the Kafka cluster.`]: `Kafka クラスターに接続するときに使用するパスワード。`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `イベント・ゲートウェイ・サービス`,
  [`Event gateway management client keystore`]: `ゲートウェイ管理クライアント鍵ストアのイベント`,
  [`Edit Event gateway Service`]: `イベント・ゲートウェイ・サービスの編集`,
  [`Configure Event Gateway Service`]: `イベント・ゲートウェイ・サービスの構成`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `非同期 API の保護および強制のためのイベント・ゲートウェイ・サービスを構成します`,
  [`Catalogs with published product`]: `公開済み製品があるカタログ`,
  [`Cluster Config ID`]: `クラスター構成 ID`,
  [`SASL mechanism`]: `SASL メカニズム`,
  [`Security protocol`]: `セキュリティー・プロトコル`,
  [`Transport CA certificate`]: `CA 証明書のトランスポート`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `最大ファイル・サイズは {size} です。 サポートされるファイル・タイプは、json、xml、yml、yaml & zip です。`,
  [`Supported file types are json, xml, yml & yaml.`]: `サポートされるファイル・タイプは、json、xml、yml & yaml です。`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `サポートされるファイル・タイプは、json、wsdl、xml、xsd、yml、yaml & zip です。`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `サポートされるファイル・タイプは、json、wsdl、xml、yml & yaml です。`,
  [`Select a file`]: `ファイルの選択`,
  [`or specify a file URL`]: `または、ファイル URL を指定します`,
  [`Generated OpenAPI {version} definition`]: `OpenAPI {version} 定義が生成されました`,
  [`User registry type`]: `ユーザー・レジストリー・タイプ`,
  [`User registry types`]: `ユーザー・レジストリー・タイプ`,
  [`User registry type {title} has been created.`]: `ユーザー・レジストリー・タイプ {title} が作成されました。`,
  [`User registry type {title} has been updated.`]: `ユーザー・レジストリー・タイプ {title} が更新されました`,
  [`Failed to create user registry type {title}.`]: `ユーザー・レジストリー・タイプ {title} の作成に失敗しました。`,
  [`Failed to update user registry type {title}.`]: `ユーザー・レジストリー・タイプ {title} の更新に失敗しました。`,
  [`Failed to update user registry {title}.`]: `ユーザー・レジストリー {title} の更新に失敗しました。`,
  [`Custom user registry`]: `カスタム・ユーザー・レジストリー`,
  [`Create custom user registry`]: `カスタム・ユーザー・レジストリーの作成`,
  [`Edit custom user registry`]: `カスタム・ユーザー・レジストリーの編集`,
  [`Create user registry type`]: `ユーザー・レジストリー・タイプの作成`,
  [`Edit user registry type`]: `ユーザー・レジストリー・タイプの編集`,
  [`Configure user authentication using a custom external user registry`]: `カスタム外部ユーザー・レジストリーを使用したユーザー認証の構成`,
  [`Secured Endpoint`]: `保護されたエンドポイント`,
  [`Configuration schema`]: `構成スキーマ`,
  [`About custom user registries`]: `カスタム・ユーザー・レジストリーについて`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `カスタム・ユーザー・レジストリーは、開発者ポータルに対するユーザー認証に使用できますが、API の保護には使用できません。 API Manager でカスタム・ユーザー・レジストリーをリソースとして構成するには、外部ユーザー・ディレクトリーガ作成されており、API Connect エコシステムで使用できる必要があります。`,
  [`Update Wsdl`]: `Wsdl の更新`,
  [`Update Existing WSDL service`]: `既存の WSDL サービスの更新`,
  [`Format of selected WSDL has been successfully validated`]: `選択された WSDL の形式が正常に検証されました`,
  [`Select the target WSDL file to update from`]: `更新元となるターゲット WSDL ファイルを選択します`,
  [`Selected WSDL has been successfully uploaded`]: `選択された WSDL が正常にアップロードされました`,
  [`Services available in selected API`]: `選択された API で使用可能なサービス`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `OpenAPI 2.0 文書用 (非推奨) にレガシー Open API Editor を使用可能にする`,
  [`Unknown`]: `不明`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API または製品で指定されたゲートウェイ・タイプと一致しないため、{incompatibleCatalogCount} 個のカタログが非表示になっています`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `API または製品で指定されたゲートウェイ・タイプと一致しないため、{incompatibleSpacesCount} 個のスペースが非表示になっています`,
  [`Keep in mind`]: `留意点`,
  [`Only one type of enforced APIs is allowed per product`]: `製品ごとに許可される強制 API のタイプは 1 つのみです`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `製品で指定されたゲートウェイ・タイプと非互換であるため、{incompatibleAPIsCount} 個の API を選択できません`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `API で指定されたゲートウェイ・タイプとの互換性がないため、{incompatibleProductsCount} 個の製品を選択できません`,
  [`Unenforced`]: `強制なし`,
  [`Create a new product by adding APIs and plans.`]: `API とプランを追加して、新規製品を作成します。`,
  [`Rate limits are only applied to enforced APIs.`]: `レート制限は、強制される API のみに適用されます。`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `プランと API の制限を製品に追加します。 プランの制限は、強制される API のみに適用され、強制されない API は制限されません。`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `プランとレート制限を製品に追加します。 プランは、強制される API のみに適用され、強制されない API は制限されません。`,
  [`Delete plan`]: `プランの削除`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `AsyncAPI 用のイベント・ゲートウェイ・サービスを選択します。 その他のすべてのタイプの API には、DataPower API Gateway または v5 互換バージョンを選択します。 この設定を更新するには、最初に、関連付けられている API を削除します。`,
  [`Edit API list`]: `API リストの編集`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `この製品の強制される API には、複数のゲートウェイ・タイプが必要です。`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `この製品の強制される {apiType} には、{gatewayType} が必要です。`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API`,
  [`AsyncAPI(s)`]: `AsyncAPI`,
  [`Select the gateway type for this Product`]: `この製品のゲートウェイ・タイプを選択してください`,
  [`Added default plan`]: `デフォルトのプランが追加されました`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `製品をサンドボックス・カタログに公開します。 これにより、製品がテスト・アプリケーションに関連付けられます。`,
  [`cluster`]: `クラスター`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `拒否側からのメッセージ`,
  [`Vendor extensions feature disabled`]: `ベンダー拡張機能は無効です`,
  [`No configured gateways found`]: `構成済みゲートウェイが見つかりません`,
  [`No OpenAPI extensions associated with the configured gateway`]: `構成されたゲートウェイに関連付けられた OpenAPI 拡張がありません`,
  [`Extension`]: `拡張`,
  [`Select the extensions to add to the API document`]: `API 文書に追加する拡張を選択します`,
  [`(Operations: {operations})`]: `(操作: {operations})`,
  [`Failed to get application`]: `アプリケーションの取得に失敗しました`,
  [`Failed to get credentials for application`]: `アプリケーションの資格情報の取得に失敗しました`,
  [`Failed to get published product`]: `公開済み製品の取得に失敗しました`,
  [`Failed to get the published API`]: `公開済み API の取得に失敗しました`,
  [`API must be activated to use the Test tool`]: `テスト・ツールを使用するには API をアクティブにする必要があります`,
  [`Import from Asset repository`]: `資産リポジトリーからインポート`,
  [`Untitled {componentLabel}`]: `タイトルなし {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `お客様のライセンス・メトリックは、使用状況を追跡するために分析サービスを必要とします。 分析サービスを構成するには、「サービスの登録」をクリックしてください。`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `すべてのDataPower Gatewayサービスでは、使用状況を追跡するためにAnalytics Serviceが必須となります。 各ゲートウェイがライセンス条項に準拠するようにするには、「分析サービスの関連付け」をクリックしてください。`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `カタログに、API または製品で指定されているゲートウェイ・タイプとの互換性がありません`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `API または製品で指定されているゲートウェイ・タイプとの互換性がスペースにありません`,
  [`Preserve Subscription`]: `サブスクリプションを保持`,
  [`Publish validation`]: `公開検証`,
  [`Publish validations`]: `検証の公開`,
  [`Edit publish validations`]: `公開検証の編集`,
  [`The following validations will be performed when publishing a product`]: `製品の公開時に以下の検証が実行されます。`,
  [`There are no publish validations enabled`]: `有効なパブリッシュ検証はありません。`,
  [`Publish validations have been updated`]: `パブリッシュ検証が更新されました。`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `イベント・ゲートウェイ・サービスがバックエンドの Kafka サーバー証明書を信頼するために使用する認証局 (CA) を指定します。 サーバーが既知の認証局によって発行された証明書を使用している場合は、CA を指定する必要はありません。`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `ブートストラップ・サーバーに対して入力された値が無効です。 クラスター上のそれぞれの Kafka ブローカーのホストとポートを、コンマで区切って入力します。 例えば、「broker1.com:9092,broker2.com:9092,broker3.com:9092」のように入力します。`,
  [`Upload OpenAPI Extension`]: `OpenAPI 拡張のアップロード`,
  [`Add OpenAPI Extension`]: `OpenAPI 拡張の追加`,
  [`Gateway Endpoint`]: `ゲートウェイ・エンドポイント`,
  [`API Dashboard`]: `API ダッシュボード`,
  [`API call data`]: `API 呼び出しデータ`,
  [`Product Dashboard`]: `製品ダッシュボード`,
  [`API Product consumption metrics`]: `API 製品使用量メトリック`,
  [`Monitoring Latency Dashboard`]: `待ち時間ダッシュボードのモニター`,
  [`Data about the time taken to serve API calls`]: `API 呼び出しの処理にかかった時間に関するデータ`,
  [`Monitoring Status Dashboard`]: `状況ダッシュボードのモニター`,
  [`API Error and success information`]: `API のエラーおよび成功の情報`,
  [`Usage Dashboard`]: `使用状況ダッシュボード`,
  [`Usage for gateway {gateway}`]: `ゲートウェイ{gateway}の使用状況`,
  [`Latency for gateway {gateway}`]: `ゲートウェイ{gateway}の待ち時間`,
  [`Most popular products, APIs and applications`]: `最も人気のある製品、API、およびアプリケーション`,
  [`Consumption Dashboard`]: `調達ダッシュボード`,
  [`Total API volume useful for auditing and compliance`]: `監査およびコンプライアンスに役立つ合計 API ボリューム`,
  [`Monthly Summary by Status Code`]: `状況コード別月次要約`,
  [`Monthly Summary Data by Status Code`]: `状況コード別月次要約データ`,
  [`Daily Summary by Status Code`]: `状況コード別日次サマリー`,
  [`Daily Summary Data by Status Code`]: `状況コード別日次要約データ`,
  [`Response time (ms)`]: `応答時間 (ミリ秒)`,
  [`Datetime`]: `日付 / 時刻`,
  [`Transaction ID:`]: `トランザクションID:`,
  [`Other`]: `その他`,
  [`Date time`]: `日時`,
  [`Total calls`]: `全呼び出し数`,
  [`Date`]: `日付`,
  [`Month`]: `月`,
  [`Minimum response time`]: `最小応答時間`,
  [`Maximum response time`]: `最大応答時間`,
  [`Average response time`]: `平均応答時間`,
  [`Total API calls`]: `API 呼び出しの総数`,
  [`Total APIs`]: `合計 API 数`,
  [`APIs called`]: `呼び出される API`,
  [`Total products`]: `製品の合計`,
  [`products called`]: `呼び出される製品`,
  [`Total errors`]: `エラーの総数`,
  [`Status codes (detailed)`]: `状況コード (詳細)`,
  [`Response time percentiles`]: `応答時間の百分位数`,
  [`API calls per day`]: `1 日当たりの API 呼び出し数`,
  [`Time zone:`]: `タイム・ゾーン:`,
  [`Time zone`]: `タイム・ゾーン`,
  [`Toggle axes`]: `軸の切り替え`,
  [`Datetime:`]: `日時:`,
  [`datetime per 30 seconds`]: `30 秒当たりの日時`,
  [`Datetime (local time)`]: `日時(現地時間)`,
  [`Data usage (bytes)`]: `データ使用量 (バイト)`,
  [`Time to Serve Request`]: `要求の処理時間`,
  [`API name`]: `API 名`,
  [`Product name`]: `製品名`,
  [`Data usage (bytes received)`]: `データ使用量 (受信バイト数)`,
  [`Data usage (bytes sent)`]: `データ使用量 (送信バイト数)`,
  [`Response times (>1s)`]: `応答時間 (>1秒)`,
  [`milliseconds`]: `ミリ秒`,
  [`errors`]: `エラー`,
  [`Success Rate`]: `成功率`,
  [`Errors Data`]: `エラー・データ`,
  [`Success Data`]: `成功データ`,
  [`Total consumer organizations`]: `コンシューマー組織の総数`,
  [`making API calls`]: `API 呼び出しの実行`,
  [`Applications per plan`]: `プラン当たりのアプリケーション数`,
  [`Top products by calls`]: `呼び出し数に基づく上位製品`,
  [`Top APIs by calls`]: `呼び出し数に基づく上位API`,
  [`Top client IP addresses by calls`]: `上位のクライアント IP アドレス (呼び出し別)`,
  [`Client IP address`]: `クライアント IP アドレス`,
  [`Top APIs by request size`]: `要求サイズに基づく上位API`,
  [`Top APIs by response size`]: `応答サイズに基づく上位API`,
  [`Top APIs by response time`]: `応答時間に基づく上位API`,
  [`Top applications by calls`]: `呼び出し数に基づく上位アプリケーション`,
  [`Dashboards`]: `ダッシュボード`,
  [`Discover`]: `検出`,
  [`UTC`]: `UTC`,
  [`Local time`]: `現地時間`,
  [`URI`]: `URI`,
  [`Response code`]: `応答コード`,
  [`Total time elapsed (ms)`]: `合計経過時間 (ミリ秒)`,
  [`Timestamp`]: `タイムスタンプ`,
  [`Method`]: `メソッド`,
  [`Gateway IP`]: `ゲートウェイ IP`,
  [`Gateway Operations Dashboard`]: `ゲートウェイ操作ダッシュボード`,
  [`Usage and latency information per gateway`]: `ゲートウェイごとの使用量と待ち時間の情報`,
  [`Transaction ID`]: `トランザクション ID`,
  [`Filters`]: `フィルター`,
  [`Fields`]: `フィールド`,
  [`Clear all`]: `すべてクリア`,
  [`API Event`]: `API イベント (API Event)`,
  [`API Assembly Policy Latencies`]: `API アセンブリー・ポリシーの待ち時間`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `以下の図は、API アセンブリー・ポリシーで要求が進行するにつれて増加する待ち時間 (ミリ秒) を示しています。 これは、API アセンブリー内の低速ポイントまたはボトルネックを判別するのに役立ちます。`,
  [`Collapse`]: `省略`,
  [`Expand`]: `展開`,
  [`enter name`]: `名前の入力`,
  [`Enter value`]: `値の入力`,
  [`Time range`]: `時刻範囲`,
  [`Select one of the following time range filters`]: `以下の時刻範囲フィルターのいずれかを選択します`,
  [`Last 1 hour`]: `過去 1 時間`,
  [`Last {number} minutes`]: `過去 {number} 分間`,
  [`Last {number} hours`]: `過去 {number} 時間`,
  [`Last {number} days`]: `過去 {number} 日間`,
  [`Start date`]: `開始日`,
  [`End date`]: `終了日`,
  [`Select from the following dropdowns and enter a value`]: `以下のドロップダウンから選択し、値を入力します`,
  [`Select one of the following type filters`]: `以下のいずれかのタイプ・フィルターを選択します。`,
  [`All events`]: `すべてのイベント`,
  [`Success only (2xx, 3xx)`]: `成功のみ (2xx、3xx)`,
  [`Errors only (4xx, 5xx)`]: `エラーのみ (4xx、5xx)`,
  [`Import/Export`]: `インポート/エクスポート`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `{time} での {totalItems} の結果 ({searchTime} 秒) について`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `約 {totalItems} 件の結果 ({searchTime} 秒) {time} ({utcTime}) 時点`,
  [`API calls`]: `API 呼び出し`,
  [`Export as CSV`]: `CSV としてエクスポート`,
  [`Export as JSON`]: `JSON としてエクスポート`,
  [`Export as PNG`]: `PNG としてエクスポート`,
  [`Export as JPG`]: `JPG としてエクスポート`,
  [`Edit filter`]: `フィルターの編集`,
  [`Edit query`]: `照会の編集`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `これは共有照会です。 この照会を共有するすべてのユーザーに対してすべての変更が行われます。`,
  [`This is a shared query. This will delete it for all users.`]: `これは共有照会です。 これにより、すべてのユーザーに対して削除されます。`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{org} プロバイダー組織のすべてのユーザーの {queryname} が更新されました`,
  [`{queryname} has been updated for all users in the {space} space`]: `{space} スペース内のすべてのユーザーの {queryname} が更新されました`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{catalog} カタログ内のすべてのユーザーの {queryname} が更新されました`,
  [`{queryname} has been updated for all users in the cloud manager`]: `クラウド・マネージャーのすべてのユーザーの {queryname} が更新されました`,
  [`Stop sharing`]: `共有の停止`,
  [`Share query`]: `照会の共有`,
  [`Share filter`]: `共有フィルター`,
  [`You do not have permissions to delete this filter`]: `このフィルターを削除する権限がありません`,
  [`You do not have permissions to delete this query`]: `この照会を削除する権限がありません`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `{org} プロバイダー組織の有効範囲で照会 {queryTitle} を共有してもよろしいですか。`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `{space} スペースの有効範囲で照会 {queryTitle} を共有してもよろしいですか。`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `{catalog} カタログの有効範囲で照会 {queryTitle} を共有してもよろしいですか。`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `クラウドの有効範囲で照会 {queryTitle} を共有してもよろしいですか。`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `照会 {queryTitle}の共有を停止してもよろしいですか。`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `{org} プロバイダー組織の有効範囲で照会 {queryTitle} の共有を停止してもよろしいですか。`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `{space} スペースの有効範囲で照会 {queryTitle} の共有を停止してもよろしいですか。`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `{catalog} カタログの有効範囲で照会 {queryTitle} の共有を停止してもよろしいですか。`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `クラウドの有効範囲で照会 {queryTitle} の共有を停止してもよろしいですか。`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `照会 {queryTitle} を削除してもよろしいですか。`,
  [`At least one query parameter expected`]: `少なくとも 1 つの照会パラメーターが必要です`,
  [`A provided query parameter is incomplete`]: `指定された照会パラメーターが不完全です`,
  [`Could not parse query string`]: `照会ストリングを構文解析できませんでした`,
  [`An unsupported query parameter was provided: {query}`]: `サポートされない照会パラメーターが指定されました。{query}`,
  [`Invalid timeframe value: {value}`]: `無効な時間フレーム値: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `{field} の値は、RFC 3339 のセクション 5.6 で定義されている日時表記に従う必要があります。 例: 2023、2023-01、2023-01-02、2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `演算子 {operator} は、フィールド {field} ではサポートされていません`,
  [`The value of {field} must be a number`]: `{field} の値は数値でなければなりません`,
  [`Delete query`]: `照会の削除`,
  [`Delete filter`]: `フィルターの削除`,
  [`Save query`]: `照会の保存`,
  [`Save filter`]: `フィルターの保存`,
  [`Save filter as`]: `フィルターの別名保存`,
  [`Save query as`]: `照会の別名保存`,
  [`Save as...`]: `別名保存...`,
  [`Save as`]: `名前を付けて保存`,
  [`Paste query string to load the query`]: `照会ストリングを貼り付けて照会をロードします`,
  [`Copy or paste filter string to load filters`]: `フィルター・ストリングをコピーまたは貼り付けしてフィルターをロードする`,
  [`Query string`]: `照会ストリング`,
  [`View filter`]: `フィルターの表示`,
  [`View filter string`]: `フィルター・ストリングの表示`,
  [`Date (local time)`]: `日付 (現地時間)`,
  [`Coming soon, a new built in analytics feature`]: `近日中に、新しい組み込み分析機能が導入されます`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `分析は、API ランタイムへの洞察の収集、サービス・レベルの管理、割り当て量の設定、制御の確立、セキュリティー・ポリシーのセットアップ、コミュニティーの管理、およびトレンドの分析に役立ちます。`,
  [`Documentation`]: `資料`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `API ランタイム・データのタイプと、AWS s3 バケットを使用して視覚化する方法について詳しく説明します。`,
  [`Blog`]: `ブログ`,
  [`Learn about what data is available today and how to access it.`]: `現在使用可能なデータとそのアクセス方法について説明します。`,
  [`Next steps`]: `次のステップ`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `選択された Secrets Manager サービスは、証明書を管理しません。`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `API Connect とゲートウェイ管理エンドポイントのドメインとの間の接続を保護するには、証明書が必要です。 ゲートウェイとその処理対象ドメインの間の接続を保護する場合にも、証明書が必要です。 すべての証明書は、Secrets Manager サービスに保管する必要があります。 このサービスは、証明書のセキュア・リポジトリーを提供し、Event Notifications サービスとの統合で証明書の有効期限が間もなく切れるときに通知を送信することにより、停止を防止するのに役立ちます。 開始するには、`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `証明書は、ゲートウェイとその処理対象ドメインの間の接続を保護するために必要です。 すべての証明書は、Secrets Manager サービスに保管する必要があります。 このサービスは、証明書のセキュア・リポジトリーを提供し、Event Notifications サービスとの統合で証明書の有効期限が間もなく切れるときに通知を送信することにより、停止を防止するのに役立ちます。 開始するには、`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Secrets Manager を変更すると、既存の証明書および CA バンドルの割り当てがすべて削除されます。`,
  [`Create a [Secrets Manager]({link}) service`]: `[Secrets Manager]({link}) サービスの作成`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `必要な証明書と CA バンドルを Secrets Manager サービスにインポートします。`,
  [`No Secrets Manager services available`]: `使用可能な Secrets Manager サービスがありません`,
  [`Select Secrets Manager service`]: `Secrets Manager サービスの選択`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `このゲートウェイの証明書を管理する [Secrets Manager]({link}) サービスを選択します。`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Secrets Manager サービスは、ゲートウェイ管理エンドポイントのドメインおよびゲートウェイが処理するドメインの証明書を管理する必要があります。 さらに、IBM Cloud IAM を使用して、Secrets Manager サービスにアクセスするための API Connect Reserved Instance サービスを許可する必要があります。`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `次に、管理エンドポイントのドメインの証明書を選択します。 その証明書が既知の認証局が割り当てたものでない場合は、対応する CA バンドルも選択してください。 証明書とバンドルは、Secrets Manager サービスによって管理される必要があります。`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `次に、このゲートウェイによって処理するドメインを指定します。 SNI 技法が使用されます。その結果、同一の IP アドレスとポートを使用して複数のドメインに対するサービスを提供できます。証明書が同一である必要はありません。 ワイルドカード・フォーマットがサポートされています。 テーブルの最終項目に、デフォルトの (キャッチオール) ドメインとして「*」を含める必要があります。 Secrets Manager サービスによって管理される証明書を指定する場合は、必要に応じて他のドメイン・ネームを入力します。`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `[IBM Cloud IAM]({link}) を使用して、Secrets Manager サービスにアクセスするための API Connect Reserved Instance サービスを許可します。`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Secrets Manager からロードして、既存の証明書および CA バンドルの割り当てを更新します。`,
  [`Access s3 data`]: `s3 データへのアクセス`,
  [`Create resource`]: `リソースの作成`,
  [`Connect analytics data in s3 bucket with AWS`]: `AWS を使用した s3 バケット内の分析データの接続`,
  [`AWS account`]: `AWS アカウント`,
  [`AWS account number`]: `AWS アカウント番号`,
  [`Enter your 12 digit aws account id`]: `12 桁の AWS アカウント ID を入力してください`,
  [`Account id must be 12 digits`]: `アカウント ID は 12 桁でなければなりません`,
  [`Failed to update AWS account id.`]: `AWS アカウント ID の更新に失敗しました。`,
  [`You have limited access to the catalogs.`]: `カタログへのアクセスが制限されています。`,
  [`You have limited access to the spaces.`]: `スペースへのアクセス権限が制限されています。`,
  [`Contact admin to get more access.`]: `管理者に連絡して、追加のアクセス権限を取得してください。`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `このカタログは選択できません。 アクセス権限が必要な場合は、管理者に連絡してください。`,
  [`You can't select this space. Please contact an admin if you require access.`]: `このスペースは選択できません。 アクセス権限が必要な場合は、管理者に連絡してください。`,
  [`You have limited access to the product billing.`]: `製品請求に対するアクセス権限が制限されています。`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `AWS アカウント ID が正常に更新されました。 S3 データへのアクセス権限の付与には数秒かかる場合があります。`,
  [`An organization or a group is missing in the catalog chosen.`]: `選択されたカタログ内に組織やグループが含まれていません。`,
  [`Secrets Manager instance`]: `Secrets Manager インスタンス`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `コンシューマー組織の所有者に E メールがない場合、そのコンシューマー組織を選択することはできません`,
  [`You currently don’t have any data.`]: `現在、データはありません。`,
  [`You currently don’t have any organizations.`]: `現在、組織はありません。`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `製品 {prodname} :{version} ({orgname}) のプラン {plan} へのアプリ {appname} のサブスクライブ要求`,
  [`Request to {operation} product: {prodname}:{version}`]: `{operation} 製品: {prodname}:{version} の要求`,
  [`Request to upgrade application {appname} to production`]: `アプリケーション {appname} を実動にアップグレードする要求`,
  [`Onboarding request for {email}`]: `{email} のオンボーディング要求`,
  [`Product lifecycle request`]: `製品ライフサイクル要求`,
  [`Subscription request`]: `サブスクリプション要求`,
  [`Application lifecycle request`]: `アプリケーション・ライフサイクル要求`,
  [`Consumer Onboarding request`]: `コンシューマー・オンボーディング要求`,
  [`Originator`]: `オリジネーター`,
  [`Edit analytics service advanced settings`]: `分析サービスの詳細設定の編集`,
  [`Advanced settings`]: `詳細設定`,
  [`Must be a positive integer`]: `正整数でなければなりません`,
  [`Maximum value is {maxValue}`]: `最大値は {maxValue} です`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `最大値は {maxValueDays} 日または {maxValueHours} 時間です`,
  [`Configure advanced settings for the analytics deployment.`]: `分析デプロイメントの拡張設定を構成します。`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `値の末尾は、日の場合は「d」、時間の場合は「h」でなければなりません。 例えば、30d または 12h です。`,
  [`Rollover settings`]: `ロールオーバー設定`,
  [`Retention settings`]: `保存設定`,
  [`Delete an index when it meets the following condition:`]: `以下の条件を満たす場合に索引を削除します。`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `管理対象索引が以下のいずれかの条件を満たす場合に、書き込み別名を新規索引にロールオーバーします。`,
  [`Minimum document count`]: `最小文書数`,
  [`The minimum number of documents required to roll over the index.`]: `索引をロールオーバーするために必要な、文書の最小数。`,
  [`Minimum index age`]: `最小索引経過時間`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `索引をロールオーバーするために必要な、索引の最小経過時間。 索引の経過時間は、索引の作成から現在までの時間です。 日の場合は「d」、時間の場合は「h」を使用します。 例えば、30d は、経過時間が 30 日に達すると索引がロールオーバーされることを意味し、96h は、経過時間が 96 時間に達すると索引がロールオーバーされることを意味します。 最大値は {maxValueDays} 日または {maxValueHours} 時間です。`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `索引を削除するために必要な、索引の最小経過時間。 索引の経過時間は、索引の作成から現在までの時間です。 日の場合は「d」、時間の場合は「h」を使用します。 例えば、30d は、存続期間が 30 日に達すると索引が削除されることを意味し、96h は、存続期間が 96 時間に達すると索引が削除されることを意味します。 最大値は {maxValueDays} 日または {maxValueHours} 時間です。`,
  [`Time range:`]: `時刻範囲:`,
  [`Time range: Before {datetime}`]: `時刻範囲: {datetime} より前`,
  [`Time range: After {datetime}`]: `時刻範囲: {datetime} より後`,
  [`Filter before`]: `次より前のものでフィルタリング`,
  [`Filter after`]: `次より後のものでフィルタリング`,
  [`Filter exact`]: `次と同一のものでフィルタリング`,
  [`Filter gt`]: `次より大きなものでフィルタリング`,
  [`Filter lt`]: `次より小さなものでフィルタリング`,
  [`Filter equals`]: `次と等しいものでフィルタリング`,
  [`Filter by`]: `フィルター基準`,
  [`Filter out`]: `フィルターで除外`,
  [`Delete failed`]: `削除失敗`,
  [`Delete warning`]: `削除警告`,
  [`Could not delete saved query {queryTitle}.`]: `保存済み照会{queryTitle}を削除できませんでした。`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `要求は正常に応答しましたが、{queryTitle}の削除はまだ終了していません。`,
  [`API governance`]: `API ガバナンス`,
  [`Something's wrong`]: `問題が発生しました`,
  [`We were unable to connect to the service.`]: `サービスに接続できませんでした。`,
  [`Invalid date`]: `無効な日付`,
  [`Invalid time`]: `無効な時間`,
  [`End date must be after the start date`]: `終了日は開始日より後でなければなりません`,
  [`Duplicate`]: `重複`,
  [`Duplicate query`]: `照会の複製`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `{queryTitle} の複製を作成してもよろしいですか。`,
  [`No queries saved`]: `保存された照会なし`,
  [`No queries shared`]: `共有された照会なし`,
  [`To save a query, start by creating one then save.`]: `照会を保存するには、まず作成してから保存します。`,
  [`Share a saved query and allow others to use it.`]: `保存された照会を共有し、他のユーザーが使用できるようにします。`,
  [`Query updated`]: `更新された照会`,
  [`Query update failed`]: `照会の更新に失敗しました`,
  [`Share successful`]: `共有が成功しました`,
  [`Share failed`]: `共有に失敗しました`,
  [`Delete successful`]: `正常に削除されました`,
  [`Query unshared`]: `非共有の照会`,
  [`Unshare {queryTitle} failed`]: `{queryTitle} の共有解除に失敗しました`,
  [`Query copied`]: `コピーされた照会`,
  [`Duplicate {queryTitle} failed`]: `{queryTitle} の複製に失敗しました`,
  [`The query {queryTitle} has been updated.`]: `照会 {queryTitle} が更新されました。`,
  [`The query {queryTitle} has been shared.`]: `照会 {queryTitle} が共有されました。`,
  [`The query {queryTitle} has been deleted.`]: `照会 {queryTitle} が削除されました。`,
  [`The query {queryTitle} is no longer shared.`]: `照会 {queryTitle} は共有されなくなりました。`,
  [`A copy of {queryTitle} has been created.`]: `{queryTitle} のコピーが作成されました。`,
  [`About queries`]: `照会について`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `照会は、ダッシュボードおよび「Discover」ビューで使用される分析データを微調整する手段です。 照会が適用されると、すべてのダッシュボードが更新され、選択したデータのみが含まれるようになります。`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `カスタム・オプションを使用して、事前定義された時刻範囲の 1 つを使用することも、最も近い秒までの特定の時刻範囲を使用することもできます。`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `フィールドは、特定のデータのみを含めることを指定するために使用できます。 API イベントのほとんどすべての属性でフィルターに掛けることができます。`,
  [`There are then different operators available depending on the field type.`]: `これにより、フィールド・タイプに応じて異なる演算子が使用可能になります。`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `ストリング・フィールドでは、「等しい」、「等しくない」、「で始まる」、「で終わる」、「正規表現」を使用できます。`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `「次を含む」と「次を含まない」を使用して、単一フィールドの値のリスト (API バージョンのリストなど) を提供することができます。`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP アドレス・フィールドでは、等号演算子と不等号演算子を使用できます。また、ネットマスクを使用して特定のネットワークを選択できるようにするために、CIDR 表記で値を指定することもできます。`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `正規表現は、特定の値を選択するには非常に強力ですが、実行に時間がかかるため、可能であれば「で始まる」や「で終わる」などの他の演算子を使用することをお勧めします。`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `異なるフィールドの照会フィルターは、論理 AND 演算子を使用して結合されます。 これは、同じフィールド名に対して 2 つのフィルターを追加すると、両方のフィルターを満たす結果のみが返されることを意味します。`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `{type} 照会パラメーターは、成功イベントまたはエラー・イベントのみを指定する簡単な方法です。 これは {statusCode} フィールドを使用します。 特定の状況コードのみが必要な場合は、フィールド・フィルターを使用できます。 {statusCodeTwo} フィールドの値は通常、数値の HTTP 戻りコードだけではなく、{okStatus} などの完全なストリングです。`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `後で再利用するためにクエリーを保存し、他のユーザー (プロバイダー組織やカタログなど) が使用できるように現在のスコープ・レベルで共有することもできます。`,
  [`n/a`]: `n/a`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `注:「プロバイダー組織 ID」フィールドと「プロバイダー組織名」フィールドは、それぞれ {orgId} および {orgName} としてイベント・ペイロードに表示されます。「コンシューマー組織 ID」フィールドと「コンシューマー組織名」フィールドは、{developerOrgId} および {developerOrgName}として表示されます。`,
  [`No authentication token`]: `認証トークンがありません`,
  [`Contact your administrator for further assistance.`]: `さらに支援が必要な場合は、管理者に連絡してください。`
};
