// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Katalog wurde nicht aktualisiert`,
  [`Catalog has been updated`]: `Katalog wurde aktualisiert`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `Das Entwurfsprodukt {draftProduct} muss auf eine oder mehrere APIs verweisen`,
  [`Number of Calls`]: `Anzahl der Aufrufe`,
  [`Changes will apply to newly published APIs only`]: `Änderungen gelten nur für neu veröffentlichte APIs`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Es sind nicht kompatible {label} vorhanden, die einen jeweils anderen Gateway-Typ für diese API aufweisen.`,
  [`{label} is required`]: `{label} ist erforderlich`,
  [`Application is required`]: `Anwendung ist erforderlich`,
  [`Plan is required`]: `Plan ist erforderlich`,
  [`Gateway is required`]: `Gateway ist erforderlich`,
  [`Consumer Org is required`]: `Konsumentenorganisation ist erforderlich`,
  [`LDAP ATTRIBUTE NAME`]: `NAME DES LDAP-ATTRIBUTS`,
  [`Use the build in Test Application (sandbox only)`]: `Build in Testanwendung verwenden (nur Sandbox)`,
  [`Select an Application`]: `Anwendung auswählen`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Integrierte Testanwendung verwenden (nur Sandbox) - inaktiviert, wenn ein Nicht-Sandbox-Katalog ausgewählt ist.`,
  [`Choose an existing Application`]: `Wählen Sie eine bereits vorhandene Anwendung aus`,
  [`Use JWT for gateway authentication to analytics service`]: `JWT für Gateway-Authentifizierung beim Analyseservice verwenden`,
  [`Select an application to consume the API`]: `Anwendung auswählen, um die API zu nutzen`,
  [`Use the default built-in Sandbox Catalog'`]: `Standardmäßig integrierten Sandbox-Katalog verwenden`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Hinweis: Dieser Katalog wird als Bereich für alle anderen Vorgaben verwendet. Durch das Ändern dieser Einstellung ist die Anpassung anderer Vorgaben erforderlich.`,
  [`Select a Consumer Org`]: `Konsumentenorganisation auswählen`,
  [`Select the Consumer Org your generated application will belong to`]: `Wählen Sie die Konsumentenorganisation aus, zu der Ihre generierte Anwendung gehört.`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Hinweis: Diese Option kann nur geändert werden, wenn die in der Sandbox-Testanwendung erstellte Anwendung verwendet wird, da bereits vorhandene Anwendungen bereits zu einer Konsumentenorganisation gehören.`,
  [`Generate auto product`]: `Automatisches Produkt generieren`,
  [`Select a product to associate the current API with`]: `Wählen Sie ein Produkt aus, dem die aktuelle API zugeordnet werden soll.`,
  [`Associate the current API to a selected product`]: `Aktuelle API einem ausgewählten Produkt zuordnen`,
  [`API management`]: `API-Management`,
  [`Select the API management instance you would like this API to be published to.`]: `Wählen Sie die API-Management-Instanz aus, in der diese API veröffentlicht werden soll.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Hinweis: Diese Instanz wird als Geltungsbereich für alle anderen Vorgaben verwendet. Durch das Ändern dieser Einstellung ist die Anpassung anderer Vorgaben erforderlich.`,
  [`Provider organization`]: `Provider-Organisation`,
  [`Select the provider organization you would like this API to be published to.`]: `Wählen Sie die Providerorganisation aus, in der diese API veröffentlicht werden soll.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Hinweis: Diese Organisation wird als Geltungsbereich für alle anderen Vorgaben verwendet. Durch das Ändern dieser Einstellung ist die Anpassung anderer Vorgaben erforderlich.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Hinweis: Dieser Bereich wird als Geltungsbereich für alle anderen Vorgaben verwendet. Durch das Ändern dieser Einstellung ist die Anpassung anderer Vorgaben erforderlich.`,
  [`Selected a draft product`]: `Produktentwurf ausgewählt`,
  [`Use any available gateway service`]: `Einen beliebigen verfügbaren Gateway-Service verwenden`,
  [`Select a compatible gateway service`]: `Wählen Sie einen kompatiblen Gateway-Service aus`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Hinweis: Dieser Gateway-Service wird als Bereich für alle anderen Vorgaben verwendet. Richtlinien und andere spezifische Inhalte des Gateway-Service werden auf Grundlage dieser Einstellung geladen.`,
  [`Select a gateway`]: `Gateway auswählen`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `Hinweis: Diese Option ist inaktiviert, wenn die Option 'Vorhandenes Produkt' ausgewählt ist. Stattdessen wird der Standardplan für das generierte Produkt verwendet`,
  [`Select a Plan`]: `Einen Plan auswählen`,
  [`Generate subscription`]: `Abonnement generieren`,
  [`A subscription will be created between the selected Product plan and Application`]: `Ein Abonnement wird zwischen dem ausgewählten Produktplan und der ausgewählten Anwendung erstellt.`,
  [`Apply a rate limit to the generated product`]: `Ratenbegrenzung auf das generierte Produkt anwenden`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Wenden Sie eine Ratenbegrenzung auf den Standardplan der generierten Produkte an. Diese Option ist inaktiviert, wenn die Option 'Vorhandenes Produkt' ausgewählt ist und stattdessen die im ausgewählten Plan definierte Ratenbegrenzung verwenden wird.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `LDAP (Lightweight Directory Access Protocol) ist ein Internetprotokoll für den Zugriff auf und die Verwaltung von verteilten Verzeichnisinformationsservices über ein Netz. Wenn Sie einen Unternehmens-LDAP-Service aktiviert haben, konfigurieren Sie ihn als Ressource, um eine Benutzerauthentifizierung bereitzustellen. Wenn Sie für neue Benutzer von Developer Portal auch Benutzer-Onboarding zur Verfügung stellen möchten, müssen Sie den Abschnitt Attributzuordnung ausführen, um ein beschreibbares LDAP zu aktivieren. Wählen Sie in diesem Abschnitt das Kontrollkästchen Benutzerverwaltet aus, um die Zuordnung Ihrer LDAP-Quellenattributsnamen zu den API Connect-Zielwerten bereitzustellen.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Durch die Aktivierung der Task 'self-approval' können Tasks sowohl von ihrem Ersteller als auch von Mitbearbeitern genehmigt werden. Diese Option kann praktisch sein, wenn andere Genehmiger nicht zur Verfügung stehen, sie ermöglicht aber effektiv die Umgehung von Prüfungen durch einen anderen Benutzer.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Eine Abrechnungsintegration verbindet API Connect mit einem Konto in einem Rechnungsabrechnungssystem eines Fremdanbieters, das Kunden, deren Zahlungsmethoden, Rechnungsstellung und aktive Abonnements verwaltet.`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Ein natives OAuth-Providerobjekt stellt Einstellungen für OAuth-Verarbeitungsoperationen bereit, wie z. B. zum Generieren und Überprüfen von OAuth-Tokens. Auf ein OAuth-Providerobjekt kann durch eine OAuth-Sicherheitsdefinition verwiesen werden, um eine API zu schützen. Wenn ein nativer OAuth-Provider verwendet wird, werden die OAuth-Operationen nativ von API Connect ausgeführt. Jedes OAuth-Providerobjekt verfügt über eine Sicherungs-API. Ihre Konfiguration aktualisiert hier automatisch das Swagger-Dokument der API. Sie können das Swagger-Dokument bearbeiten, indem Sie zur Seite mit dem API-Editor navigieren.  Wenn eine veröffentlichte API auf ein OAuth-Providerobjekt verweist, wird die Sicherungs-API automatisch im Gateway bereitgestellt.`,
  [`About token management`]: `Informationen zum Token-Management`,
  [`About user security`]: `Informationen zur Benutzersicherheit`,
  [`APIs or products`]: `APIs oder Produkte`,
  [`ATM is not deployed, please contact your administrator`]: `ATM ist nicht implementiert, wenden Sie sich an Ihren Administrator`,
  [`Application state change approvals will be enabled`]: `Genehmigungen für Anwendungsstatusänderungen werden aktiviert.`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Definieren Sie die Einstellungen, die verwendet werden sollen, um die Berechtigungsnachweise der Anwendungsbenutzer zu extrahieren, ihre Identitäten zu authentifizieren und Berechtigungen zu erteilen.`,
  [`See console for details.`]: `Details finden Sie in der Konsole.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `Der ausgewählte Zertifikatmanagerservice verwaltet keine Zertifikate.`,
  [`The selected product is not published to the Sandbox catalog`]: `Das ausgewählte Produkt wurde nicht im Sandbox-Katalog veröffentlicht`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `Das ausgewählte Produkt wurde nicht im Sandbox-Katalog veröffentlicht, sodass Sie die Anwendung nicht abonnieren können`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `für Ihre Plattform herunter und installieren Sie sie (falls noch nicht installiert).`,
  [`(Only supported by TLS 1.3)`]: `(Nur unterstützt von TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `Die Anweisung "@listSize" in diesem Feld definiert Felder mit festgelegter Größe, die keine Listen zurückgeben.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `Die Anweisung "@listSize" in diesem Feld definiert Felder mit festgelegter Größe, die nicht im Rückgabetyp des Felds definiert sind.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `Die Anweisung "@listSize" in diesem Feld definiert Slicing-Argumente, die nicht im Feld definiert sind.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `Die Anweisung "@listSize" in diesem Feld definiert Slicing-Argumente, deren Typen nicht "Int" oder "Int!" sind.`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `Die Anweisung "@listSize" in diesem Feld definiert mehrere Slicing-Argumente ungleich null.`,
  [`"{name}" (Role) has been created`]: `"{name}" (Rolle) wurde erstellt.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (Rolle) wurde aktualisiert.`,
  [`API ({api}) has been created.`]: `API ({api}) wurde erstellt.`,
  [`API ({api}) has been renamed.`]: `API ({api}) wurde umbenannt.`,
  [`API ({api}) has been updated.`]: `API ({api}) wurde aktualisiert.`,
  [`API ({api}) has been deleted.`]: `API ({api}) wurde gelöscht.`,
  [`API ({api}) has been published.`]: `API ({api}) wurde veröffentlicht.`,
  [`API ({api}) has validation error.`]: `API ({api}) weist Gültigkeitsfehler auf`,
  [`API ({api}) has validation errors.`]: `API ({api}) weist Gültigkeitsfehler auf`,
  [`API version`]: `API-Version`,
  [`APIM data version`]: `APIM-Datenversion`,
  [`APIM schema update date`]: `Datum der APIM-Schemaaktualisierung`,
  [`APIM schema version`]: `APIM-Schemaversion`,
  [`APIM target data version`]: `APIM-Zieldatenversion`,
  [`APIM target schema version`]: `APIM-Zielschemaversion`,
  [`APIM update date`]: `APIM-Aktualisierungsdatum`,
  [`Product ({product}) has been created.`]: `Produkt ({product}) wurde erstellt.`,
  [`Product ({product}) has been updated.`]: `Produkt ({product}) wurde aktualisiert.`,
  [`Product ({product}) has been delete.`]: `Produkt ({product}) wurde gelöscht.`,
  [`Product ({product}) has been published.`]: `Produkt ({product}) wurde veröffentlicht.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) war {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) war nicht {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} nach dem Stellen in die Warteschlange)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} nach dem Senden)`,
  [`(none)`]: `(keine)`,
  [`(optional)`]: `(optional)`,
  [`*Base endpoint list empty`]: `*Basisendpunktliste leer`,
  [`*Must be a valid url`]: `*Muss eine gültige URL sein`,
  [`*System will also send an email notification to the requester.`]: `*Das System sendet auch eine E-Mail-Benachrichtigung an den Anforderer.`,
  [`+ redact from...`]: `+ neu bearbeiten von...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. Sie können den Lebenszyklus dieses Produkts auch im Katalog verwalten.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x gefolgt von 64 Hexadezimalzeichen`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Ist Eigner und Verwalter für API-Konsumentenorganisationen 2) Verwaltet Communitys für Anwendungsentwickler 3) Verfasst API- und Produktdefinitionen 4) Verwaltet den API-Produktlebenszyklus`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Ist Eigner und Verwalter für API-Provider-Organisationen 2) Verwaltet Communitys für Anwendungsentwickler 3) Verfasst API- und Produktdefinitionen 4) Verwaltet den API-Produktlebenszyklus`,
  [`5 Most Active APIs`]: `Die 5 aktivsten APIs`,
  [`5 Most Active Products`]: `Die 5 aktivsten Produkte`,
  [`5 most active APIs`]: `Die 5 aktivsten APIs`,
  [`5 most active Products`]: `Die 5 aktivsten Produkte`,
  [`503 Service Unavailable`]: `503 Service Unavailable`,
  [`A request to the server was made without any credentials.`]: `An den Server wurde eine Anforderung ohne Berechtigungsnachweise gestellt.`,
  [`A server error occurred`]: `Ein Serverfehler ist aufgetreten`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Ein TLS-Clientprofil konfiguriert die Zertifikatkette und die Cipher-Suite, die von API Connect verwendet werden, wenn eine Verbindung als Client mit anderen Systemen hergestellt wird.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Ein TLS-Serverprofil konfiguriert die Zertifikatkette und die Cipher-Suite, die von API Connect verwendet werden, wenn Serverendpunkte auf anderen Systemen dargestellt werden.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Ein Katalog enthält eine Sammlung von API-Produkten, die im zugehörigen Developer Portal sichtbar sind, wenn sie veröffentlicht werden.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Ein Katalog enthält eine Sammlung von API-Produkten, die im zugehörigen Developer Portal sichtbar sind, wenn sie veröffentlicht werden.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Ein Katalog stellt eine Sammlung verwalteter API-Produkte dar. Produkte, die veröffentlicht werden, sind für die Konsumenten auf dem Developer Portal sichtbar, das dem Katalog zugeordnet ist.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Ein Zertifikat ist erforderlich, um die Verbindung zwischen API Connect und der Domäne des Gateway-Managementendpunkts zu sichern. Zertifikate sind auch erforderlich, um die Verbindungen zwischen dem Gateway und den Domänen, die es verarbeitet, zu sichern. Alle Zertifikate müssen in einem Zertifikatmanagerservice gespeichert werden. Der Service stellt ein sicheres Repository für die Zertifikate bereit und hilft dabei, Ausfälle zu verhindern, indem er Ihnen kurz vor Ablauf der Zertifikate entsprechende Benachrichtigungen sendet. Um zu starten,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Ein Standardwert wird verwendet, wenn die Eingaben in die Zuordnung nicht definiert werden.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Ein Gateway-Service stellt eine Gruppe von Gateway-Servern oder -Containern dar, die veröffentlichte APIs hosten und die API-Endpunkte bereitstellen, die von Clientanwendungen verwendet werden. Gateways führen API-Proxy-Aufrufe an Back-End-Systeme aus und erzwingen API-Richtlinien, einschließlich Clientidentifikation, Sicherheit und Ratenbegrenzung.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Ein Gateway-Host veröffentlicht APIs und stellt die API-Endpunkte bereit, die von Clientanwendungen verwendet werden. Gateways führen API-Proxy-Aufrufe an Back-End-Systeme aus und erzwingen API-Richtlinien, einschließlich Clientidentifikation, Sicherheit und Ratenbegrenzung.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Ein Migrationsziel kann auf ein Quellenprodukt festgelegt werden, um das Quellenprodukt für Abonnements zu definieren.  Das Migrationsziel beinhaltet eine Planzuordnung, die die Zuordnung von Plänen im Quellenprodukt zu Plänen im Zielprodukt beschreibt.  Das Migrationsziel ist im Developer Portal sichtbar, um Abonnenten des Quellenprodukts über das Migrationsziel zu informieren.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Ein modulares OAuth-Richtlinien-Kit. Es kann alle OAuth/OpenID Connect-Protokollschritte in einer Richtlinie ausführen (Standard) oder es kann für eine differenziertere Steuerung in mehrere Richtlinien aufgeteilt werden, um nur jeweils einen Schritt oder 'n' Schritte auszuführen. Die Eingaben und Ausgaben der einzelnen Schritte werden durch dokumentierte Kontextvariablen gesteuert. Fügen Sie unterstützte OAuth-Komponenten hinzu oder entfernen Sie sie nach Bedarf.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Ein Portal kann ohne einen verfügbaren Portalservice nicht erstellt werden. Bitten Sie Ihren Cloudadministrator um Unterstützung.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Eine Provider-Organisation ist ein Team, das Eigner von APIs sowie der zugehörigen Pläne und Produkte ist. Für eine Provider-Organisation ist ein Eigner erforderlich, dem alle Berechtigungen zugewiesen sind. Die Eigner werden aus den Mitgliedern zugewiesen.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Eine Provider-Organisation beinhaltet Benutzer, die APIs, Produkte, Pläne, Kataloge, Konsumentenorganisationen und weitere zugehörige Ressourcen verwalten. Sie können Provider-Organisationen verwenden, um Benutzer und API-Managementressourcen nach Projekt, Abteilung, Lebenszyklusphase usw. zu gruppieren. Um Rollen, die Benutzerberechtigungen regulieren, zu verwalten und zuzuweisen, rufen Sie "IBM Cloud Identity and Access Management" auf.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Ein einfacher JavaScript-Ausdruck, der als Wert der Ausgabe aufgelöst wird. Dies kann eine statische Zeichenfolge ("meine statische Zeichenfolge") oder ein Ausdruck mit beliebigen zugeordneten Eingaben ($(input.firstName) + " " + $(input.lastName)) sein`,
  [`ACTION`]: `AKTION`,
  [`ANALYTICS`]: `ANALYSE`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `API-Administrator`,
  [`API CONNECT VALUE`]: `API CONNECT-WERT`,
  [`API assemble`]: `API-Assembly`,
  [`API base endpoints`]: `API-Basisendpunkte`,
  [`API base URL`]: `API-Basis-URL`,
  [`API base URLs`]: `API-Basis-URLs`,
  [`API Calls`]: `API-Aufrufe`,
  [`API Connect UI`]: `API Connect-Benutzerschnittstelle`,
  [`API Connect services`]: `API Connect-Services`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect erfordert einen E-Mail-Server zum Senden von Einladungen und Aktivierungslinks an die Mitglieder und für andere Zwecke.`,
  [`API Connect verify token`]: `API Connect-Überprüfungstoken`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `API Designer-Lizenz`,
  [`API Designer credentials`]: `API Designer-Berechtigungsnachweise`,
  [`API Designer licence`]: `API Designer-Lizenz`,
  [`API Designer does not currently support WSDL`]: `API Designer unterstützt WSDL nicht`,
  [`API editor`]: `API-Editor`,
  [`API Endpoint`]: `API-Endpunkt`,
  [`API Endpoint Base`]: `API-Endpunktbasis`,
  [`API Endpoint for Unenforced APIs`]: `API-Endpunkt für nicht erzwungene APIs`,
  [`API Endpoint for unenforced APIs`]: `API-Endpunkt für nicht erzwungene APIs`,
  [`API Endpoints`]: `API-Endpunkte`,
  [`API Error`]: `API-Fehler`,
  [`API gateway`]: `API-Gateway`,
  [`API Gateway`]: `API-Gateway`,
  [`API gateways`]: `API-Gateways`,
  [`API Gateways`]: `API-Gateways`,
  [`API Invocation Endpoint`]: `API-Aufrufendpunkt`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `API wird nicht veröffentlicht. Der API-Test (Try it) im Explorer ist nicht aktiviert.`,
  [`API Key`]: `API-Schlüssel`,
  [`API key`]: `API-Schlüssel`,
  [`API key secret`]: `Geheimer API-Schlüssel`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `Lokale Benutzerregistry für API Manager`,
  [`API Manager URL`]: `API Manager-URL`,
  [`API Management`]: `API-Management`,
  [`API Path Parameters`]: `API-Pfadparameter`,
  [`API Security Definition`]: `API-Sicherheitsdefinition`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `API-Test (Probieren Sie es) im Explorer wird für diese API nicht aktiviert, da sie nicht umgesetzt wird.`,
  [`API Setup`]: `API-Konfiguration`,
  [`API Subscription`]: `API-Abonnement`,
  [`API endpoint`]: `API-Endpunkt`,
  [`API endpoint base`]: `API-Endpunktbasis`,
  [`API endpoint for unenforced APIs`]: `API-Endpunkt für nicht erzwungene APIs`,
  [`API endpoints`]: `API-Endpunkte`,
  [`API error`]: `API-Fehler`,
  [`API invocation endpoint`]: `API-Aufrufendpunkt`,
  [`API is not enforced on the API gateway.`]: `Die API wird auf dem API-Gateway nicht umgesetzt.`,
  [`API path parameters`]: `API-Pfadparameter`,
  [`API security definition`]: `API-Sicherheitsdefinition`,
  [`API setup`]: `API-Konfiguration`,
  [`API subscription`]: `API-Abonnement`,
  [`API Update`]: `API-Aktualisierung`,
  [`API user registries`]: `API-Benutzerregistrys`,
  [`API and product generated successfully!`]: `API und Produkt erfolgreich erstellt!`,
  [`API deletion failed`]: `Löschen der API fehlgeschlagen`,
  [`API does not exist!`]: `API ist nicht vorhanden!`,
  [`API from existing REST service`]: `API von einem vorhandenen REST-Service`,
  [`API from existing SOAP service`]: `API aus vorhandenem SOAP-Service`,
  [`API has been updated.`]: `API wurde aktualisiert.`,
  [`API has been updated`]: `Die API wurde aktualisiert`,
  [`API has not been created!`]: `API wurde nicht erstellt!`,
  [`API has not been updated!`]: `API wurde nicht aktualisiert!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `API-Priorität (Vanity-Endpunkt(e) verwenden, die in den OpenAPI-Definitionen definiert wurden)`,
  [`API properties`]: `API-Eigenschaften`,
  [`API protection source`]: `API-Schutzquelle`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `Die aus dem Produkt referenzierte API ist nicht vorhanden. Sie müssen die API zunächst erstellen oder importieren.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `API-Strukturen für Anforderungs- und Antwortnutzlasten werden mithilfe von OpenAPI-Schemadefinitionen zusammengesetzt.`,
  [`API subscription request approved for app {{appName}}`]: `API-Abonnementanforderung genehmigt für App {appName}`,
  [`API subscription request denied for app {{appName}}`]: `API-Abonnementanforderung abgelehnt für App {appName}`,
  [`API subscription request received for app {{appName}}`]: `API-Abonnementanforderung empfangen für App {appName}`,
  [`API type`]: `API-Typ`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `Die API mit dem Schlüssel ''{key}'' unter der Eigenschaft 'apis' ist nicht vorhanden und kann nicht aufgelistet werden. Überprüfen Sie die Quellregisterkarte.`,
  [`APIs`]: `APIs`,
  [`APIs and products`]: `APIs und Produkte`,
  [`APIs included in the plan.`]: `Im Plan enthaltene APIs.`,
  [`API Key is created`]: `API-Schlüssel ist erstellt`,
  [`API Key is deleted`]: `API-Schlüssel ist gelöscht`,
  [`API Key is successfully created`]: `API-Schlüssel wurde erfolgreich erstellt`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `Zeitlimit des API-Schlüssels ist **{seconds} Sekunden ({time})**`,
  [`API key timeout`]: `Zeitlimit des API-Schlüssels`,
  [`API Key timeout has been changed`]: `Das Zeitlimit des API-Schlüssels wurde geändert.`,
  [`Application type`]: `Anwendungstyp`,
  [`Associated analytics service`]: `Zugeordneter Analyseservice`,
  [`AVAILABILITY`]: `VERFÜGBARKEIT`,
  [`AVAILABILITY ZONE`]: `VERFÜGBARKEITSZONE`,
  [`About`]: `Informationen`,
  [`About audit setting`]: `Informationen zur Auditeinstellung`,
  [`About Authentication URL user registry`]: `Informationen zur Benutzerregistry für Authentifizierungs-URL`,
  [`About availability zones`]: `Informationen zu Verfügbarkeitszonen`,
  [`About catalogs`]: `Informationen zu Katalogen`,
  [`About consumer organization owners`]: `Informationen zu Eignern von Konsumentenorganisationen`,
  [`About consumer organizations`]: `Informationen zu Konsumentenorganisationen`,
  [`About developer portals`]: `Informationen zu Developer Portals`,
  [`About email servers`]: `Informationen zu E-Mail-Servern`,
  [`About Endpoints`]: `Informationen zu Endpunkten`,
  [`About gateways`]: `Informationen zu Gateways`,
  [`About gateway visibility`]: `Informationen zur Gateway-Sichtbarkeit`,
  [`About Gateway Processing Status`]: `Informationen zum Gateway-Verarbeitungsstatus`,
  [`About introspection`]: `Informationen zur Introspektion`,
  [`About Keystores`]: `Informationen zu Keystores`,
  [`About LDAP`]: `Informationen zu LDAP`,
  [`About local user registries`]: `Informationen zu lokalen Benutzerregistrys`,
  [`About metadata`]: `Informationen zu Metadaten`,
  [`About Migration Target`]: `Informationen zum Migrationsziel`,
  [`About migration targets`]: `Informationen zu Migrationszielen`,
  [`About native OAuth provider`]: `Informationen zu nativen OAuth-Providern`,
  [`About OpenID Connect`]: `Informationen zu OpenID Connect`,
  [`About replacing a product`]: `Informationen zum Ersetzen eines Produkts`,
  [`About Role defaults`]: `Informationen zu Rollenstandardwerten`,
  [`About roles`]: `Informationen zu Rollen`,
  [`About scopes`]: `Informationen zu Bereichen`,
  [`About Set Migration Targets`]: `Informationen zum Festlegen von Migrationszielen`,
  [`About spaces`]: `Informationen zu Bereichen`,
  [`About Subscription`]: `Informationen zum Abonnement`,
  [`About superseding a product`]: `Informationen zum Außerkraftsetzen eines Produkts`,
  [`About TLS server profile`]: `Informationen zum TLS-Serverprofil`,
  [`About TLS client profiles`]: `Informationen zu TLS-Clientprofilen`,
  [`About TLS server profiles`]: `Informationen zu TLS-Serverprofilen`,
  [`About third party OAuth provider`]: `Informationen zu OAuth-Providern eines Drittanbieters`,
  [`About tokens`]: `Informationen zu Tokens`,
  [`About Transfer Ownership`]: `Informationen zum Übertragen des Eigentumsrechts`,
  [`About Transferring Ownership`]: `Informationen zum Übertragen des Eigentumsrechts`,
  [`About Truststores`]: `Informationen zu Truststores`,
  [`About adding a member`]: `Informationen zum Hinzufügen eines Mitglieds`,
  [`About adding provider organization member`]: `Informationen zum Hinzufügen von Provider-Organisationsmitgliedern`,
  [`About adding members to a Space`]: `Informationen zum Hinzufügen von Mitgliedern zu einem Bereich`,
  [`About adding members to a catalog`]: `Informationen zum Hinzufügen von Mitgliedern zu einem Katalog`,
  [`About changing owners`]: `Informationen zum Ändern von Eignern`,
  [`About enabling gateway services`]: `Informationen zum Aktivieren von Gateway-Services`,
  [`About inviting a member`]: `Informationen zum Einladen eines Mitglieds`,
  [`About inviting catalog members`]: `Informationen zum Einladen von Katalogmitgliedern`,
  [`About inviting provider organization members`]: `Informationen zum Einladen von Mitgliedern von Provider-Organisationen`,
  [`About inviting space members`]: `Informationen zum Einladen von Bereichsmitgliedern`,
  [`About keystores`]: `Informationen zu Keystores`,
  [`About managing Space membership`]: `Informationen zur Verwaltung der Bereichsmitgliedschaft`,
  [`About migrating subscriptions`]: `Informationen zum Migrieren von Abonnements`,
  [`About provider organizations`]: `Informationen zu Provider-Organisationen`,
  [`About roles and role defaults`]: `Informationen zu Rollen und Rollenstandardwerten`,
  [`About roles in spaces`]: `Informationen zu Rollen in Bereichen`,
  [`About selecting gateway services`]: `Informationen zur Auswahl von Gateway-Services`,
  [`About send message`]: `Informationen zum Senden von Nachrichten`,
  [`About sending messages`]: `Informationen zum Senden von Nachrichten`,
  [`About the analytics service`]: `Informationen zum Analyseservice`,
  [`About the DNS Scheme`]: `Informationen zum DNS-Schema`,
  [`About the Gateway Service`]: `Informationen zum Gateway-Service`,
  [`About the Portal Service`]: `Informationen zum Portalservice`,
  [`About the product lifecycle`]: `Informationen zum Produktlebenszyklus`,
  [`About the provider organization owner`]: `Informationen zum Eigner der Provider-Organisation`,
  [`About this Cloud`]: `Informationen zu dieser Cloud`,
  [`About transferring ownership`]: `Informationen zum Übertragen des Eigentumsrechts`,
  [`About truststores`]: `Informationen zu Truststores`,
  [`About visibility`]: `Informationen zur Sichtbarkeit`,
  [`About visibility and subscribability`]: `Informationen zu Sichtbarkeit und Abonnierbarkeit`,
  [`Accept`]: `Akzeptieren`,
  [`Access Code`]: `Zugriffscode`,
  [`Access Token`]: `Zugriffstoken`,
  [`Access URL through Secure Gateway`]: `Zugriff auf URL über Secure Gateway`,
  [`Access code`]: `Zugriffscode`,
  [`Access code is not chosen in supported grant type.`]: `Der Zugriffscode wurde im unterstützten Bewilligungstyp nicht ausgewählt.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Der Zugriff auf die URL erfolgt über ein Secure Gateway. Das Gateway wird festgelegt, wenn die API vom Status 'Bereitgestellt' in den Status 'Veröffentlicht' versetzt wird.`,
  [`Access token TTL has been changed`]: `Zugriffstoken-TTL wurde geändert`,
  [`Access token time to live`]: `Lebensdauer des Zugriffstokens`,
  [`Access token time-to-live`]: `Lebensdauer des Zugriffstokens`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Zugriffstoken läuft ab in **{seconds} Sekunden ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Zugriffstokens werden für die Clientanwendung erteilt, damit die Anwendung im Namen des Anwendungsbenutzers Zugriff auf Ressourcen erhält.  Aktualisierungstokens werden an den Client ausgegeben, um ein neues Zugriffstoken zu erhalten, wenn das aktuelle Zugriffstoken ungültig wird oder abläuft, oder um zusätzliche Zugriffstokens mit identischem oder engerem Bereich zu erhalten. Sie können auch angeben, wie lange sie durch die Kombination einer beliebigen Anzahl von Zugriffs- und Aktualisierungstokens gegebene Einverständnis gültig bleibt.`,
  [`Access tokens time to live (seconds)`]: `Lebensdauer für Zugriffstokens (Sekunden)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Zugriffstoken, ID-Token und temporäre Token können einem beliebigen der vorhandenen Keystores zugeordnet werden. Der Verlauf der Keystore-Zuordnung bleibt erhalten.`,
  [`Account`]: `Account`,
  [`Account has been updated.`]: `Account wurde aktualisiert.`,
  [`Account password has been changed.`]: `Das Accountkennwort wurde geändert.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Aktion`,
  [`Activate API`]: `API aktivieren`,
  [`Activate API|button text`]: `API aktivieren`,
  [`Activate API for testing|title`]: `API aktivieren`,
  [`Activation link`]: `Aktivierungslink`,
  [`Active`]: `Aktiv`,
  [`Activity log`]: `Aktivitätenprotokoll`,
  [`Activity log API setting is not available for this API's gateway type.`]: `Die API-Einstellung für das Aktivitätenprotokoll ist für den Gateway-Typ dieser API nicht verfügbar.`,
  [`Active loading indicator`]: `Aktiver Ladeanzeiger`,
  [`Add`]: `Hinzufügen`,
  [`Add allowlist`]: `Zulassungsliste hinzufügen`,
  [`Add a new billing integration in Resources`]: `Neue Abrechnungsintegration in Ressourcen hinzufügen`,
  [`Add API user registries`]: `API-Benutzerregistrys hinzufügen`,
  [`Add APIs to Product`]: `APIs zu Produkt hinzufügen`,
  [`Add another cloud`]: `Weitere Cloud hinzufügen`,
  [`Add billing integration`]: `Abrechnungsintegration hinzufügen`,
  [`Add Burst Limit`]: `Burstbegrenzung hinzufügen`,
  [`Add Ciphers for TLS client profile`]: `Verschlüsselungen für TLS-Clientprofil hinzufügen`,
  [`Add Ciphers for TLS server profile`]: `Verschlüsselungen für TLS-Serverprofil hinzufügen`,
  [`Add Condition`]: `Bedingung hinzufügen`,
  [`Add Consumer Role`]: `Konsumentenrolle hinzufügen`,
  [`Add Count Limit`]: `Zählergrenze hinzufügen`,
  [`Add Credential`]: `Berechtigungsnachweis hinzufügen`,
  [`Add domain`]: `Domäne hinzufügen`,
  [`Add credential`]: `Berechtigungsnachweis hinzufügen`,
  [`Add Extension`]: `Erweiterung hinzufügen`,
  [`Add GraphQL schema`]: `GraphQL-Schema hinzufügen`,
  [`Add HTTP Endpoint`]: `HTTP-Endpunkt hinzufügen`,
  [`Add group`]: `Gruppe hinzufügen`,
  [`Add JSON schema`]: `JSON-Schema hinzufügen`,
  [`Add Keystore to TLS client profile`]: `Keystore zum TLS-Clientprofil hinzufügen`,
  [`Add Keystore to TLS server profile`]: `Keystore zum TLS-Serverprofil hinzufügen`,
  [`Add member`]: `Mitglied hinzufügen`,
  [`Add New Role for Consumer organization`]: `Neue Rolle für Konsumentenorganisation hinzufügen`,
  [`Add operation`]: `Operation hinzufügen`,
  [`Add Parameters`]: `Parameter hinzufügen`,
  [`Add Policy`]: `Richtlinie hinzufügen`,
  [`Add Rate limit`]: `Ratenbegrenzung hinzufügen`,
  [`Add Rate Limit`]: `Ratenbegrenzung hinzufügen`,
  [`Add role`]: `Rolle hinzufügen`,
  [`Add Syslog TCP Endpoint`]: `Syslog-TCP-Endpunkt hinzufügen`,
  [`Add Syslog TLS Endpoint`]: `Syslog-TLS-Endpunkt hinzufügen`,
  [`Add Syslog UDP Endpoint`]: `Syslog-UDP-Endpunkt hinzufügen`,
  [`Add XML schema`]: `XML-Schema hinzufügen`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Fügen Sie einen Gateway-Endpunkt hinzu, den Sie für Aufrufe von APIs in diesem Katalog verfügbar machen möchten.`,
  [`Add a different Cloud Connection.`]: `Fügen Sie eine andere Cloudverbindung hinzu.`,
  [`Add a member from the user registry`]: `Mitglied aus dem Benutzerregistry hinzufügen`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Fügen Sie einen Benutzer zur Verwaltungsorganisation hinzu und ordnen Sie die erforderlichen Rollen zu`,
  [`Add a user to the provider organization, and assign the required roles`]: `Fügen Sie einen Benutzer zur Providerorganisation hinzu und ordnen Sie die erforderlichen Rollen zu`,
  [`Add a user to the Catalog, and assign the required roles`]: `Fügen Sie einen Benutzer zum Katalog hinzu und ordnen Sie die erforderlichen Rollen zu.`,
  [`Add a user to the Space, and assign the required roles`]: `Fügen Sie einen Benutzer zum Bereich hinzu und ordnen Sie die erforderlichen Rollen zu`,
  [`Add action`]: `Aktion hinzufügen`,
  [`Add additional client ID/client secret pairs`]: `Zusätzliche Paare aus Client-ID und geheimem Clientschlüssel hinzufügen`,
  [`Add blocklist`]: `Blockierliste hinzufügen`,
  [`Add case`]: `Fall hinzufügen`,
  [`Add catch`]: `Catch hinzufügen`,
  [`Add default catch`]: `Standardcatch hinzufügen`,
  [`Add destination`]: `Ziel hinzufügen`,
  [`Add entry`]: `Eintrag hinzufügen`,
  [`Add group failed.`]: `Hinzufügen der Gruppe ist fehlgeschlagen.`,
  [`Add input`]: `Eingabe hinzufügen`,
  [`Add media type`]: `Datenträgertyp hinzufügen`,
  [`Add member failed`]: `Hinzufügen des Mitglieds fehlgeschlagen`,
  [`Add object`]: `Objekt hinzufügen`,
  [`Add otherwise`]: `Andere hinzufügen`,
  [`Add output`]: `Ausgabe hinzufügen`,
  [`Add outputs for operation...`]: `Ausgaben für Operation hinzufügen...`,
  [`Add parameters for operation...`]: `Parameter für Operation hinzufügen...`,
  [`Add parameters to this API`]: `Parameter zu dieser API hinzufügen`,
  [`Add plans to product`]: `Fügen Sie Pläne zum Produkt hinzu.`,
  [`Add plans to this product`]: `Fügen Sie Pläne zu diesem Produkt hinzu`,
  [`Add schema`]: `Schema hinzufügen`,
  [`Add scopes for this OAuth provider.`]: `Fügen Sie Bereiche für diesen OAuth-Provider hinzu.`,
  [`Add scopes to allow access to`]: `Fügen Sie Bereiche hinzu, um den Zugriff darauf zu ermöglichen.`,
  [`Add scopes to allow access to.`]: `Fügen Sie Bereiche hinzu, um den Zugriff darauf zu ermöglichen.`,
  [`Add tags and external documentation details for this API`]: `Tags und externe Dokumentationsdetails für diese API hinzufügen`,
  [`Add to`]: `Hinzufügen zu`,
  [`Add to existing product`]: `Zu vorhandenem Produkt hinzufügen`,
  [`Add/Remove APIs`]: `APIs hinzufügen/entfernen`,
  [`AddProperties`]: `Eigenschaften hinzufügen`,
  [`Added APIs`]: `Hinzugefügte APIs`,
  [`Added rate limit`]: `Hinzugefügte Ratenbegrenzung`,
  [`Added rate limits`]: `Ratenbegrenzungen hinzugefügt`,
  [`Adding an API to a product for publishing`]: `API zur Veröffentlichung zu einem Produkt hinzufügen`,
  [`Additional support`]: `Zusätzliche Unterstützung`,
  [`Additional properties`]: `Zusätzliche Eigenschaften`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Erwägen Sie zudem, die zugeordnete Registry, Katalogbenutzerregistry {name}, zu entfernen, wenn sie nicht verwendet wird.`,
  [`Address`]: `Adresse`,
  [`Admin DN`]: `Admin-DN`,
  [`Admin organization invitation timeout`]: `Zeitlimit für Einladung der Verwaltungsorganisation`,
  [`Admin organization invitation timeout has been changed`]: `Zeitlimit für Einladung der Verwaltungsorganisation wurde geändert`,
  [`Admin password`]: `Administratorkennwort`,
  [`Admin request reset password`]: `Admin - Anforderung zum Zurücksetzen des Kennworts`,
  [`Admin reset password`]: `Admin - Kennwort zurücksetzen`,
  [`Admin Sign In`]: `Admin - Anmeldung`,
  [`Admin add catalog failed`]: `Hinzufügen des Katalogs mit Administratorberechtigung fehlgeschlagen`,
  [`Admin add space failed`]: `Hinzufügen des Bereichs mit Administratorberechtigung fehlgeschlagen`,
  [`Administer consumer organizations`]: `Konsumentenorganisationen verwalten`,
  [`Administers the API consumer organization`]: `Verwaltet die API-Konsumentenorganisation`,
  [`Administers the API provider organization`]: `Verwaltet die API-Provider-Organisation`,
  [`Administers the admin organization`]: `Verwaltet die Verwaltungsorganisation`,
  [`Administers the admin topology`]: `Verwaltet die Admin-Topologie`,
  [`Administers the app developer organization`]: `Verwaltet die App-Entwicklerorganisation`,
  [`Administers the catalog`]: `Verwaltet den Katalog`,
  [`Administers the cloud topology`]: `Verwaltet die Cloud-Topologie`,
  [`Administers the space`]: `Verwaltet den Bereich`,
  [`Administration management console`]: `Verwaltungsmanagementkonsole`,
  [`Administrator`]: `Administrator`,
  [`Advanced analytics configuration`]: `Erweiterte Analysekonfiguration`,
  [`Advanced features`]: `Erweiterte Funktionen`,
  [`Advanced scope check`]: `Erweiterte Bereichsüberprüfung`,
  [`Advanced scope check after Token Validation`]: `Erweiterte Bereichsprüfung nach Tokenvalidierung`,
  [`Advanced scope check before Token Generation`]: `Erweiterte Bereichsprüfung vor Tokengenerierung`,
  [`Aggregation`]: `Aggregation`,
  [`Agree`]: `Zustimmen`,
  [`All`]: `Alle`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Alle authentifizierten Entwickler in Konsumentenorganisationen, die sich für das Developer Portal angemeldet haben, können dieses Produkt anzeigen.`,
  [`All consumer organizations will be able to see this product.`]: `Alle Konsumentenorganisationen können dieses Produkt anzeigen.`,
  [`Allowlist`]: `Zulassungsliste`,
  [`Allow Chunked Uploads`]: `Aufgeteilte Uploads zulassen`,
  [`Allow chunked uploads`]: `Aufgeteilte Uploads zulassen`,
  [`Allow "plain" challenge method`]: `"Einfache" Abfragemethode zulassen`,
  [`Allow renegotiation`]: `Neuvereinbarung zulassen`,
  [`Allow case sensitive usernames`]: `Benutzernamen mit Beachtung von Groß-/Kleinschreibung zulassen`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Clients erlauben, eine SNI-Erweiterung mit dem gewünschten Hostnamen in den ersten Handshake mit dem Server einzufügen.`,
  [`Allow connection to be renegotiated`]: `Neuaushandlung der Verbindung zulassen`,
  [`Allow default introspection`]: `Standardintrospektion zulassen`,
  [`Allow insecure server connections`]: `Unsichere Serververbindungen zulassen`,
  [`Allow null value`]: `Nullwert zulassen`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Zulassen, dass access_token/refresh_token 302-Umleitung für Abmeldung senden`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Alle Empfehlungen wurden angewendet. Es liegen keine Warnungen für Ihr Schema vor.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Zulassen, dass die Operationen der API mit den Testtools im Developer Portal getestet werden.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Zulassen, dass die Verbindung mit schwachen oder unsicheren Berechtigungsnachweisen fortgesetzt wird`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Zulassen, dass die Verbindung mit schwachen oder unsicheren Berechtigungsnachweisen fortgesetzt wird.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Kommunikation des externen OIDC-Providers mit dem Developer Portal zulassen, nicht mit dem API Manager.`,
  [`Allow to manage this user registry`]: `Verwaltung dieser Benutzerregistry zulassen`,
  [`Allow to manage users under this user registry`]: `Verwaltung von Benutzern unter dieser Benutzerregistry zulassen`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Ermöglicht Benutzern das automatische Onboarding, wenn APIC ein vom Aussteller ausgestelltes Token präsentiert wird.`,
  [`Already have an account?`]: `Sie haben bereits einen Account?`,
  [`Also transfer ownership of owned Spaces`]: `Übertragen Sie auch das Eigentumsrecht an Eigner von Bereichen`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Bei Aktivierung immer Benutzerdaten von Benutzerinformatinsendpunkt abrufen`,
  [`Always output the root element`]: `Stammelement immer ausgeben`,
  [`Always output the root element.`]: `Stammelement immer ausgeben.`,
  [`Always required`]: `Immer erforderlich`,
  [`Always use userinfo endpoint`]: `Immer Benutzerinformationsendpunkt verwenden`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Eine OAuth-Provider-API enthält die Berechtigungs- und Tokenendpunkte eines OAuth-Ablaufs. Konfigurieren Sie hier Ihre OAuth-Provider. Wenn Sie eine sichere OAuth-API erstellen, können Sie den erforderlichen Provider auswählen.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Ein OAuth-Provider enthält die Berechtigungs- und Tokenendpunkte eines OAuth-Ablaufs. Wählen Sie den OAuth-Provider aus, den Sie verwenden möchten, um Ihre API mit OAuth zu sichern.`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Eine Anwendung wird hier aufgelistet, wenn ein Entwickler sie für einen Plan im Bereich abonniert, um die zugeordneten APIs aufrufen zu können. Sie können eine Entwickleranwendung aussetzen, um ihren Zugriff auf Ihre APIs zu blockieren. Sie können auch eigene Anwendungen erstellen. [Weitere Informationen]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Eine Authentifizierungs-URL referenziert einen Authentifizierungsprovider eines Drittanbieters als Benutzerregistry. Eine Authentifizierungs-URL ermöglicht die Integration in eine andere Benutzerregistry eines Drittanbieters als LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Eine Authentifizierungs-URL stellt einen einfachen Mechanismus zur Authentifizierung von Benutzern gegenüber einem angepassten Identitätsprovider bereit.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Ein Fehler ist bei der Kommunikation mit dem Gateways-Subsystem aufgetreten.`,
  [`An organization is required.`]: `Eine Organisation ist erforderlich.`,
  [`An unknown error occurred.`]: `Ein unbekannter Fehler ist aufgetreten.`,
  [`Analytics`]: `Analyse`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Analytics Insights-Service'`,
  [`Analytics Details`]: `Analysedetails`,
  [`Analytics destinations`]: `Analyseziele`,
  [`Analytics Director keystore`]: `Analytics Director-Keystore`,
  [`Analytics Service`]: `Analyseservice`,
  [`Analytics Service URL`]: `Analyseservice-URL`,
  [`Analytics Service {arg} has been removed.`]: `Analyseservice {arg} wurde entfernt.`,
  [`Analytics details`]: `Analysedetails`,
  [`Analytics service`]: `Analyseservice`,
  [`Analytics service details`]: `Details des Analyseservice`,
  [`Analytics service URL`]: `Analyseservice-URL`,
  [`Analytics service {arg} has been removed.`]: `Analyseservice {arg} wurde entfernt.`,
  [`Analytics client TLS client profile`]: `TLS-Clientprofil des Analyseclients`,
  [`Analytics client keystore`]: `Keystore des Analyseclients`,
  [`Analytics client truststore`]: `Truststore des Analyseclients`,
  [`Analytics ingestion TLS client profile`]: `TLS-Clientprofil für Analyseaufnahme`,
  [`Analytics ingestion keystore`]: `Keystore für Analyseaufnahme`,
  [`Analytics ingestion truststore`]: `Truststore für Analyseaufnahme`,
  [`Analytics service {title} has been created.`]: `Analyseservice {title} wurde erstellt.`,
  [`Analytics service {title} has been updated.`]: `Analyseservice {title} wurde aktualisiert.`,
  [`The selected consumer organization is`]: `Die ausgewählte Konsumentenorganisation ist`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Analyseservices werden konfiguriert und Analysedaten werden extern ausgelagert`,
  [`Analyze API usage and performance`]: `API-Nutzung und -Leistung analysieren`,
  [`Anonymous bind`]: `Anonymes Binden`,
  [`Another user registry`]: `Andere Benutzerregistry`,
  [`api-administrator`]: `API-Administrator`,
  [`Api-Analytics`]: `API-Analyse`,
  [`Api-Drafts`]: `API-Entwürfe`,
  [`App`]: `App`,
  [`App name`]: `App-Name`,
  [`App-Analytics`]: `App-Analyse`,
  [`App-Approval`]: `App-Genehmigung`,
  [`App-Dev`]: `App-Entwicklung`,
  [`App-analytics`]: `App-Analyse`,
  [`App-dev`]: `App-Ent`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Anwendung`,
  [`Application Authentication`]: `Anwendungsauthentifizierung`,
  [`Application Authentication Source`]: `Anwendungsauthentifizierungsquelle`,
  [`Application lifecycle`]: `Anwendungslebenszyklus`,
  [`Application approval task for upgrading application`]: `Anwendungsgenehmigungstask für das Upgrade der Anwendung`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Anwendungsgenehmigungstask für das Upgrade der Anwendung {taskname} auf die Produktion, die von {username} ({orgname}) angefordert wurde`,
  [`Application authentication`]: `Anwendungsauthentifizierung`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Anwendungsentwickler abonnieren ihre Anwendungen zunächst mithilfe des Developer Portals für einen oder mehrere Pläne. Für einen ausgewählten Plan können Sie jedoch Anwendungsabonnements in einen Plan in einem anderen Produkt migrieren.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Anwendungsentwickler abonnieren ihre Anwendungen zunächst mithilfe des Developer Portals für einen oder mehrere Pläne. Für einen ausgewählten Plan können Sie jedoch Anwendungsabonnements in einen Plan in einem anderen Produkt migrieren.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Anwendungsentwickler abonnieren eine Anwendung für einen Plan, um dann die APIs in diesem Plan aufzurufen.`,
  [`Application is being created. Please Wait`]: `Anwendung wird erstellt. Bitte warten Sie`,
  [`Application scope check`]: `Anwendungsbereichsüberprüfung`,
  [`Applications`]: `Anwendungen`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Anwendungen, die vor dem Einschalten des Anwendungslebenszyklus vorhanden waren, bleiben weiterhin im Produktionsstatus.`,
  [`Applied security`]: `Angewandte Sicherheit`,
  [`Apply`]: `Anwenden`,
  [`Apply all`]: `Alle anwenden`,
  [`Apply all suggestions`]: `Alle Vorschläge anwenden`,
  [`Apply analysis configuration`]: `Analysekonfiguration anwenden`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Wenden Sie die Aufrufrichtlinie an, um einen vorhandenen Service aus Ihrer Operation heraus aufzurufen. Die Richtlinie kann mit JSON- oder XML-Daten verwendet werden und kann mehrmals in Ihrer Assembly angewendet werden.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Wenden Sie die WebSocket-Upgraderichtlinie an, um eine WebSocket-Verbindung herzustellen, mit der ein vorhandener Service von innerhalb Ihrer Operation aufgerufen wird.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Wenden Sie diese Richtlinie an, um eine Proxy-API in Ihrer Operation aufzurufen, besonders dann, wenn Sie eine große Menge Nutzdaten aufrufen müssen. Nur eine Proxy-Richtlinie kann pro Assembly aufgerufen werden.`,
  [`Apply to selected`]: `Auf Auswahl anwenden`,
  [`Apply type analysis configuration`]: `Typanalysekonfiguration anwenden`,
  [`Approval History`]: `Genehmigungsverlauf`,
  [`Approval tasks`]: `Genehmigungstasks`,
  [`Approvals`]: `Genehmigungen`,
  [`Approve`]: `Genehmigen`,
  [`Archive`]: `Archivieren`,
  [`Archive Product`]: `Produkt archivieren`,
  [`Archive|permission`]: `Archivieren`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Sollen die Bedingungen wirklich aus Ihren ausgewählten Elementen gelöscht werden?`,
  [`Are you sure you want to delete API?`]: `Soll die API wirklich gelöscht werden?`,
  [`Are you sure you want to delete Product?`]: `Soll das Produkt wirklich gelöscht werden?`,
  [`Are you sure you want to delete a catalog?`]: `Soll der Katalog wirklich gelöscht werden?`,
  [`Are you sure you want to delete a space?`]: `Möchten Sie wirklich einen Bereich löschen?`,
  [`Are you sure you want to delete this application?`]: `Soll diese Anwendung gelöscht werden?`,
  [`Are you sure you want to delete this billing integration?`]: `Soll diese Abrechnungsintegration tatsächlich gelöscht werden?`,
  [`Are you sure you want to delete this field?`]: `Soll dieses Feld wirklich gelöscht werden?`,
  [`Are you sure you want to delete this policy?`]: `Soll diese Richtlinie wirklich gelöscht werden?`,
  [`Are you sure you want to delete this type?`]: `Soll dieser Typ wirklich gelöscht werden?`,
  [`Are you sure you want to disable spaces?`]: `Möchten Sie Bereiche wirklich inaktivieren?`,
  [`Are you sure you want to disable custom email sender information?`]: `Möchten Sie die angepassten Informationen zum E-Mail-Absender wirklich inaktivieren?`,
  [`Are you sure you want to disable custom notification templates?`]: `Möchten Sie die Vorlagen für angepasste Benachrichtigungen wirklich inaktivieren?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Sind Sie sicher, dass Sie IBM Developer Portal nicht mehr verwenden möchten? Alle Portalanpassungen für den Katalog werden gelöscht.`,
  [`Are you sure you want to enable spaces?`]: `Möchten Sie Bereiche wirklich aktivieren?`,
  [`Are you sure you want to enable custom email sender information?`]: `Möchten Sie die angepassten Informationen zum E-Mail-Absender wirklich aktivieren?`,
  [`Are you sure you want to enable custom notification templates?`]: `Möchten Sie die Vorlagen für angepasste Benachrichtigungen wirklich aktivieren?`,
  [`Are you sure you want to proceed with delete?`]: `Soll wirklich mit dem Löschen fortgefahren werden?`,
  [`Are you sure you want to publish?`]: `Soll wirklich eine Veröffentlichung durchgeführt werden?`,
  [`Are you sure you want to re-stage?`]: `Soll wirklich eine erneute Bereitstellung durchgeführt werden?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Soll dieses Produkt wirklich aus dem Katalog entfernt werden?`,
  [`Are you sure you want to unassociate?`]: `Sind Sie sicher, dass Sie die Zuordnung aufheben möchten?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Möchten Sie Self-Service-Onboarding wirklich aktualisieren?`,
  [`Are you sure?`]: `Soll die Aktion tatsächlich ausgeführt werden?`,
  [`Argument`]: `Argument`,
  [`Array`]: `Array`,
  [`Assemble`]: `Assemblieren`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Setzen Sei den Richtlinien-Datenfluss zusammen, der für den OAuth-Provider erforderlich ist.`,
  [`Assembly`]: `Assemblierung`,
  [`Assembly Execute`]: `Assemblierung ausführen`,
  [`Assembly saved`]: `Assembly gespeichert`,
  [`Assembly burst limits`]: `Assembly-Burstbegrenzungen`,
  [`Assembly count limits`]: `Assembly-Zählergrenzen`,
  [`Assign roles`]: `Rollen zuweisen`,
  [`Assign roles to the current owner`]: `Dem aktuellen Benutzer Rollen zuweisen`,
  [`Associate`]: `Zuordnen`,
  [`Associate analytics service`]: `Analyseservice zuordnen`,
  [`Associate analytics`]: `Analyse zuordnen`,
  [`Assumed size`]: `Angenommene Größe`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Authentifizierungsfehler beim Versuch, die API von der URL {url} abzurufen. Überprüfen Sie den Benutzernamen und das Kennwort.`,
  [`Audience claim`]: `Zielgruppenanforderung`,
  [`Audience Claim`]: `Zielgruppenanforderung`,
  [`Audit setting`]: `Auditeinstellung`,
  [`Audit setting has been changed`]: `Auditeinstellung wurde geändert`,
  [`Auditing is **{mode}**`]: `Auditierung ist **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `Die Auditierung wird verwendet, um API-Aufrufe und Protokollinformationen zu den aufrufenden Benutzern, zum Zeitpunkt der einzelnen Aufrufe und zur Aktivität bei jedem Ereignis zu überwachen.`,
  [`Authenticate Client Method`]: `Authentifizierungsclientmethode`,
  [`Authenticate password`]: `Kennwort authentifizieren`,
  [`Authenticate user`]: `Benutzer authentifizieren`,
  [`Authenticate user settings`]: `Benutzereinstellungen authentifizieren`,
  [`Authenticate application users using`]: `Anwendungsbenutzer authentifizieren mithilfe von`,
  [`Authenticate using Basic Authentication`]: `Mit Basisauthentifizierung authentifizieren`,
  [`Authenticate using OAuth`]: `Authentifizierung mit OAuth durchführen`,
  [`Authenticated`]: `Authentifiziert`,
  [`Authenticated bind`]: `Authentifiziertes Binden`,
  [`Authenticated User Name`]: `Name des authentifizierten Benutzers`,
  [`Authentication`]: `Authentifizierung`,
  [`Authentication Bind`]: `Authentifiziertes Binden`,
  [`Authentication error`]: `Authentifizierungsfehler`,
  [`Authentication method`]: `Authentifizierungsmethode`,
  [`Authentication TLS profile`]: `TLS-Profil für Authentifizierung`,
  [`Authentication URL`]: `Authentifizierungs-URL`,
  [`Authentication URL user registry`]: `Benutzer-Registry für Authentifizierungs-URL`,
  [`Authentication URL user registry has been created.`]: `Die Benutzerregistry für die Authentifizierungs-URL wurde erstellt.`,
  [`Authentication URL to use for validation.`]: `Authentifizierungs-URL für die Validierung.`,
  [`Authentication response header credential`]: `Berechtigungsnachweis für Authentifizierungsantwortheader`,
  [`Authentication response header pattern`]: `Headermuster für Authentifizierungsantwort`,
  [`Authentication type`]: `Authentifizierungstyp`,
  [`Authentication type to use to validate the UsernameToken.`]: `Authentifizierungstyp, der zum Validieren des UsernameToken verwendet werden soll.`,
  [`Authorization`]: `Berechtigung`,
  [`Authorization Code`]: `Berechtigungscode`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Berechtigungs-URL muss mit {endpoint} übereinstimmen, wie durch OAuth-Provider {providerTitle} definiert`,
  [`Authorization endpoint`]: `Berechtigungsendpunkt`,
  [`Authorization Request`]: `Berechtigungsanforderung`,
  [`Authorization URL`]: `Berechtigungs-URL`,
  [`Authorize`]: `Berechtigen`,
  [`Authorize User Settings`]: `Benutzereinstellungen autorisieren`,
  [`Authorize application users using`]: `Anwendungsbenutzer autorisieren mithilfe von`,
  [`Authorize path`]: `Pfad berechtigen`,
  [`Authors API and product definitions`]: `Ist Autor für API- und Produktdefinitionen`,
  [`Auto Generate OIDC API Assembly`]: `OIDC-API-Assembly automatisch generieren`,
  [`Auto onboard`]: `Automatisches Onboarding`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Automatisch generierter Name zur Verwendung in den API Connect-Management-APIs und -Befehlen.`,
  [`Automation`]: `Automatisierung`,
  [`Automation Management Console`]: `Managementkonsole für Automation`,
  [`Automation management console`]: `Managementkonsole für Automation`,
  [`Availability Zone`]: `Verfügbarkeitszone`,
  [`Availability zone`]: `Verfügbarkeitszone`,
  [`Availability zone {arg} has been removed.`]: `Verfügbarkeitszone {arg} wurde entfernt.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Verfügbarkeitszonen ermöglichen Ihnen, API Connect-Services entsprechend Ihren Geschäftsanforderungen zu gruppieren. Sie können Gateway-Services beispielsweise entsprechend der Region oder dem Rechenzentrum gruppieren, in der bzw. dem sie sich befinden.`,
  [`Average response time: {{value}}ms`]: `Durchschnittliche Antwortzeit: {{value}}ms`,
  [`Average time`]: `Durchschnittszeit`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Vermeiden Sie CORS-Fehler, indem Sie den API Designer-Server als lokalen Proxy verwenden.`,
  [`Back`]: `Zurück`,
  [`Back to sign in`]: `Zurück zur Anmeldung`,
  [`Back to test`]: `Zurück zum Test`,
  [`Backend type`]: `Back-End-Typ`,
  [`Bad gateway`]: `Fehlerhaftes Gateway`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Basis-DN`,
  [`Base Path`]: `Basispfad`,
  [`Base path`]: `Basispfad`,
  [`Base endpoint`]: `Basisendpunkt`,
  [`Base endpoints`]: `Basisendpunkte`,
  [`Base64 encoding`]: `Base64-Codierung`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `Die Base64-Codierung für Einladungen und Tokens zum Zurücksetzen des Kennworts ist **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Einstellung der Base64-Verschlüsselung für temporäres Token wurde geändert`,
  [`Base URL of API invocation endpoint`]: `Basis-URL des API-Aufrufendpunkts`,
  [`Basepath root`]: `Basispfadroot`,
  [`Basic`]: `Basis`,
  [`Basic authentication`]: `Basisauthentifizierung`,
  [`Basic authentication password`]: `Kennwort für Basisauthentifizierung`,
  [`Basic authentication request header name`]: `Name des Anforderungsheaders für Basisauthentifizierung`,
  [`Basic authentication username`]: `Benutzername für Basisauthentifizierung`,
  [`Before you begin`]: `Vorbereitungen`,
  [`Before you begin...`]: `Vorbereitungen...`,
  [`Between 0 and 10 events are waiting to be processed`]: `Zwischen 0 und 10 Ereignisse warten auf die Verarbeitung`,
  [`Between 10 and 20 events are waiting to be processed`]: `Zwischen 10 und 20 Ereignisse warten auf die Verarbeitung`,
  [`Billing`]: `Abrechnung`,
  [`Billing and Payment`]: `Abrechnung und Zahlung`,
  [`Billing has been updated`]: `Die Abrechnung wurde aktualisiert.`,
  [`Billing integrations`]: `Abrechnungsintegrationen`,
  [`Billing integration`]: `Abrechnungsintegration`,
  [`Billing Integration`]: `Abrechnungsintegration`,
  [`Billing {title} successfully added.`]: `Abrechnung {title} wurde erfolgreich hinzugefügt.`,
  [`Billing {title} successfully deleted.`]: `Abrechnung {title} wurde erfolgreich gelöscht.`,
  [`Billing {title} successfully updated.`]: `Abrechnung {title} wurde erfolgreich aktualisiert.`,
  [`Billing Options`]: `Abrechnungsoptionen`,
  [`Blocklist`]: `Blockierliste`,
  [`Blocking`]: `Blockierung`,
  [`Blocking option`]: `Blockierungsoption`,
  [`Body`]: `Hauptteil`,
  [`Boolean`]: `Boolesch`,
  [`Both`]: `Beides`,
  [`Browse`]: `Durchsuchen`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Puffern Sie API-Anforderungen und -Antworten, bevor sie auf dem DataPower-API-Gateway verarbeitet werden.`,
  [`Buffering`]: `Pufferung`,
  [`Builds and manages apps in the developer organization`]: `Erstellt und verwaltet Apps in der Entwicklerorganisation`,
  [`Burst Limit Name`]: `Name der Burstbegrenzung`,
  [`Burst limits`]: `Burstbegrenzungen`,
  [`Burst limit value can't be negative`]: `Der Wert der Burstbegrenzung darf nicht negativ sein`,
  [`Buy`]: `Kaufen`,
  [`but unable to remove because of limitation`]: `kann aber aufgrund der Einschränkung nicht entfernt werden`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Durch das Konfigurieren der Sichtbarkeit und Abonnierbarkeit eines Produkts steuern Sie die Verfügbarkeit seiner APIs für Anwendungsentwickler im Developer Portal. Sie können steuern, welche Anwendungsentwickler das Produkt sehen können und welche Anwendungsentwickler die APIs im Produkt abonnieren können.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Durch das Konfigurieren der Sichtbarkeit und Abonnierbarkeit eines Produkts steuern Sie die Verfügbarkeit seiner APIs für Anwendungsentwickler im Developer Portal. Sie können steuern, welche Anwendungsentwickler das Produkt sehen können und welche Anwendungsentwickler die APIs im Produkt abonnieren können.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Die Zuordnung aus mehreren Quellen (wie z. B. Array1 mit Länge 2 und Array2 mit Länge 3) hat standardmäßig ein Zielarray zur Folge, das 5 Elemente enthält (2 durch die Verarbeitung von Array1 und 3 durch die Verarbeitung von Array2). Wenn diese Regeln kombiniert werden sollen (Erstellen eines Arrays, der entweder 2 oder 3 Elemente mit kombinierten Eigenschaften aus allen Quellenarrays enthält), wählen Sie diese Option aus.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Dieses Produkt wird standardmäßig in allen relevanten Gateway-Services veröffentlicht. Sie können auch auf bestimmten Gateway-Services veröffentlichen, indem Sie diese Option aktivieren.`,
  [`By payload`]: `Nach Nutzinformationen`,
  [`By URL parameter`]: `Nach URL-Parameter`,
  [`Bytes`]: `Byte`,
  [`CALLS`]: `AUFRUFE`,
  [`Calls`]: `Aufrufe`,
  [`CATALOG`]: `KATALOG`,
  [`CERTIFICATE NAME`]: `ZERTIFIKATSNAME`,
  [`CIPHER`]: `CIPHER`,
  [`CIPHER SUITES`]: `CIPHER-SUITES`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Nur CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `Client-ID`,
  [`CLOUD ADMINISTRATOR`]: `CLOUDADMINISTRATOR`,
  [`Consumer organization`]: `Konsumentenorganisation`,
  [`CONSUMER ORGANIZATION / GROUP`]: `KONSUMENTENORGANISATION/-GRUPPE`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA-Bundle`,
  [`Cache Key`]: `Cacheschlüssel`,
  [`Cache Time to Live (second)`]: `Cachelebensdauer (Sekunde)`,
  [`Cache key`]: `Cacheschlüssel`,
  [`Cache Time-To-Live`]: `Cachelebensdauer`,
  [`Cache type`]: `Cachetyp`,
  [`Can't find the one you want?`]: `Sie finden den gewünschten Eintrag nicht?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Es kann keine Einladung mit einem ungültigen E-Mail-Server gesendet werden. Prüfe Sie Ihre E-Mail-Server-Konfigurationen und versuchen Sie es erneut`,
  [`Cancel`]: `Abbrechen`,
  [`Cannot be below {min}`]: `Darf nicht unter {min} liegen`,
  [`Cannot be empty`]: `Darf nicht leer sein`,
  [`Cannot delete root type.`]: `Stammtyp kann nicht gelöscht werden.`,
  [`Cannot exceed {max}`]: `Darf nicht über {max} liegen`,
  [`Cannot find any APIs for this product`]: `Es können keine APIs für dieses Produkt gefunden werden`,
  [`Cannot find the one you want?`]: `Sie finden den gewünschten Eintrag nicht?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Die Anweisung "@listSize" kann auf das Feld, das keine Liste zurückgibt, angewendet werden, wenn "sizedFields" auch nicht definiert wird.`,
  [`Cannot remove built-in scalar types`]: `Integrierte Skalartypen können nicht entfernt werden`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Der Abfrage-, Mutations- und Abonnement-Stammoperationstyp kann nicht entfernt werden`,
  [`Cannot remove arguments of built-in directives`]: `Argumente der integrierten Direktiven können nicht entfernt werden`,
  [`Cannot remove non-null arguments of directives`]: `Argumente der integrierten Direktiven, die ungleich null sind, können nicht entfernt werden`,
  [`Cannot remove input types of arguments of directives`]: `Eingabetypen von Argumenten von Direktiven können nicht entfernt werden`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Aufzählungswerte, die als Standardwerte von Argumenten von Anweisungen verwendet werden, können nicht entfernt werden`,
  [`Cannot remove enum values used as default values of input fields`]: `Aufzählungswerte, die als Standardwerte von Eingabefeldern verwendet werden, können nicht entfernt werden`,
  [`Cannot remove all fields of an object type`]: `Es können nicht alle Felder eines Objekttyps entfernt werden`,
  [`Cannot remove all fields of an interface type`]: `Es können nicht alle Felder eines Schnittstellentyps entfernt werden.`,
  [`Cannot remove all slicing arguments`]: `Es können nicht alle Slicing-Argumente entfernt werden`,
  [`Cannot remove all input fields of an input object type`]: `Es können nicht alle Eingabefelder eines Eingabeobjekttyps entfernt werden.`,
  [`Cannot remove all values of enum type`]: `Es können nicht alle Werte des Aufzählungstyps entfernt werden`,
  [`Cannot remove fields of interfaces`]: `Schnittstellenfelder können nicht entfernt werden`,
  [`Cannot remove non-null input fields`]: `Eingabefelder, die ungleich null sind, können nicht entfernt werden`,
  [`Case`]: `Fall`,
  [`Case sensitive`]: `Groß-/Kleinschreibung muss beachtet werden`,
  [`Catalog`]: `Katalog`,
  [`Catalog defaults`]: `Katalogstandardwerte`,
  [`Catalog Invite`]: `Katalogeinladung`,
  [`Catalog invite`]: `Katalogeinladung`,
  [`Catalog invites`]: `Katalogeinladungen`,
  [`Catalog name`]: `Katalogname`,
  [`Catalog owner`]: `Katalogeigner`,
  [`Catalog owner invitation`]: `Einladung des Katalogeigners`,
  [`Catalog properties`]: `Katalogeigenschaften`,
  [`Catalog summary`]: `Katalogzusammenfassung`,
  [`Catalog title`]: `Katalogtitel`,
  [`Catalog user registries`]: `Katalogbenutzerregistrys`,
  [`Catalog by Name`]: `Katalog nach Name`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Katalogpriorität (Vanity-Endpunkt(e) verwenden, die vom Katalogadministrator definiert wurden)`,
  [`Catalog {name} created`]: `Katalog {name} erstellt`,
  [`Catalog selection`]: `Katalogauswahl`,
  [`Catalog settings`]: `Katalogeinstellungen`,
  [`Catalog settings have been updated`]: `Katalogeinstellungen wurden aktualisiert`,
  [`Catalog User`]: `Katalogbenutzer`,
  [`Catalog Users`]: `Katalogbenutzer`,
  [`Catalog user registry`]: `Katalogbenutzerregistry`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `Der Katalog mit aktiviertem Lebenszyklus kann nicht in den Testvorgaben verwendet werden.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Der Katalog mit aktiviertem LifeCycle- und Produktionsmodus kann nicht in den Testvorgaben verwendet werden.`,
  [`Catalogs`]: `Kataloge`,
  [`Catch`]: `Catch`,
  [`Catches`]: `Catches`,
  [`Categories`]: `Kategorien`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Kategorien erstellen eine hierarchische Anzeige von API-Produkten im Developer Portal. Verwenden Sie die folgende Syntax: Element_der_höchsten_Ebene/Element_der_nächsten_Ebene/Element_einer_niedrigeren_Ebene.`,
  [`Category`]: `Kategorie`,
  [`Certifcate or Shared Secret for Verify`]: `Zertifikat oder geheimer Schlüssel für gemeinsame Nutzung zum Bestätigen`,
  [`Certificate`]: `Zertifikat`,
  [`Certificate (Optional)`]: `Zertifikat (optional)`,
  [`Certificate management`]: `Zertifikatsmanagement`,
  [`Certificate Manager instance`]: `Zertifikatmanagerinstanz`,
  [`Certificate (optional)`]: `Zertifikat (optional)`,
  [`Certificates`]: `Zertifikate`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Zertifikate sind erforderlich, um die Verbindungen zwischen dem Gateway und den Domänen, die es verarbeitet, zu sichern. Alle Zertifikate müssen in einem Zertifikatmanagerservice gespeichert werden. Der Service stellt ein sicheres Repository für die Zertifikate bereit und hilft dabei, Ausfälle zu verhindern, indem er Ihnen kurz vor Ablauf der Zertifikate entsprechende Benachrichtigungen sendet. Um zu starten,`,
  [`Certificates details in this keystore`]: `Zertifikatdetails in diesem Keystore`,
  [`Certificates details in this truststore`]: `Zertifikatdetails in diesem Truststore`,
  [`Change`]: `Ändern`,
  [`Change Consumer Organization owner to an existing user`]: `Eigner der Konsumentenorganisation in einen vorhandenen Benutzer ändern`,
  [`Change Ownership to an Existing User`]: `Eigentumsrecht auf einen vorhandenen Benutzer übertragen`,
  [`Change portal service`]: `Portalservice ändern`,
  [`Change owner to existing user`]: `Eigner in vorhandenen Benutzer ändern`,
  [`Change owner to new user`]: `Eigner in neuen Benutzer ändern`,
  [`Change ownership by inviting a new user`]: `Eigentumsrecht durch Einladen eines neuen Benutzers übertragen`,
  [`Change ownership to`]: `Eigentumsrecht übertragen auf`,
  [`Change ownership to a new user via email invitation`]: `Eigentumsrecht per E-Mail-Einladung auf neuen Benutzer übertragen`,
  [`Change ownership to an existing user`]: `Eigentumsrecht auf einen vorhandenen Benutzer übertragen`,
  [`Change password`]: `Kennwort ändern`,
  [`Change setup`]: `Konfiguration ändern`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Änderungen, die Sie direkt an der AIP oder Assembly vornehmen, die einem OAuth-Provider zugrunde liegt, können den OAuth-Provider ungültig machen, wenn Sie nicht auch alle entsprechenden Werte in der Definition des OAuth-Providers selbst ändern. Wenn Sie diese Änderungen speichern, stellen Sie sicher, dass Sie auch alle entsprechenden Aktualisierungen in der OAuth-Provider-Definition vornehmen.`,
  [`Change the following extra constraints in the schema:`]: `Folgende zusätzliche Bedingungen im Schema ändern:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `Durch das Ändern des Zertifikatmanagers werden alle vorhandenen Zertifikat- und CA-Bundle-Zuweisungen entfernt.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Wenn Sie das DNS-Schema ändern, ändert sich das Format der URL-Links für API Connect und das Developer Portal. Sie müssen den Benutzern die neuen Links mitteilen. Klicken Sie auf "Abbrechen", wenn Sie nicht fortfahren möchten.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Durch das Ändern des Namens oder der Version einer API wird eine neue API erstellt. Sind Sie sicher, dass Sie Ihre Änderungen speichern möchten?`,
  [`Check your email`]: `Ihre E-Mail überprüfen`,
  [`Child`]: `Untergeordnet`,
  [`Chinese (Simplified)`]: `Chinesisch (vereinfacht)`,
  [`Chinese (Traditional)`]: `Chinesisch (traditionell)`,
  [`Choose`]: `Auswählen`,
  [`Choose a`]: `Wählen Sie eine`,
  [`Choose {docTypeLabel}`]: `{docTypeLabel} auswählen`,
  [`Choose Catalog`]: `Katalog auswählen`,
  [`Choose a Catalog`]: `Einen Katalog auswählen`,
  [`Choose a Consumer Org`]: `Konsumentenorganisation auswählen`,
  [`Choose Gateway`]: `Gateway auswählen`,
  [`Choose a Gateway`]: `Ein Gateway auswählen`,
  [`Choose Product`]: `Produkt auswählen`,
  [`Choose a Product`]: `Ein Produkt auswählen`,
  [`Choose Rate Limit`]: `Ratenbegrenzung auswählen`,
  [`Choose a Rate Limit`]: `Eine Ratenbegrenzung auswählen`,
  [`Choose Application`]: `Anwendung auswählen`,
  [`Choose an Application`]: `Anwendung auswählen`,
  [`Choose a {docTypeLabel}`]: `Einen {docTypeLabel} auswählen`,
  [`Choose a publish destination`]: `Veröffentlichungsziel auswählen`,
  [`Choose a billing integration`]: `Rechnungsintegration auswählen`,
  [`Choose a catalog to test within:`]: `Wählen Sie einen Katalog für den Test aus:`,
  [`Choose a plan against which to test:`]: `Wählen Sie einen Plan für den Test aus:`,
  [`Choose a product containing this API, or create a new one:`]: `Wählen Sie ein Produkt aus, das diese API enthält, oder erstellen Sie ein neues:`,
  [`Choose a user registry`]: `Benutzerregistry auswählen`,
  [`Choose an application with which to test, or create a new one:`]: `Wählen Sie eine Anwendung für den Test aus oder erstellen Sie eine neue:`,
  [`Choose an existing application`]: `Wählen Sie eine bereits vorhandene Anwendung aus`,
  [`Choose an existing catalog`]: `Vorhandenen Katalog auswählen`,
  [`Choose an existing product published to your Sandbox catalog`]: `Wählen Sie ein vorhandenes Produkt aus, das in Ihrem Sandbox-Katalog veröffentlicht wurde`,
  [`Choose an operation to invoke:`]: `Wählen Sie eine Operation zum Aufrufen:`,
  [`Choose an option`]: `Wählen Sie eine Option aus`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Wählen Sie eine Option aus, um festzulegen, wie die map-Richtlinie leere Array-Ausgaben behandeln soll. Bei der Auswahl aller Optionen (die Standardauswahl) werden alle leeren Arrays und alle leeren untergeordneten Arrays ausgegeben. Durch die Auswahl des übergeordneten Elements wird nur das übergeordnete leere Array ausgegeben. Bei der Auswahl 'keine' wird das leere Array nicht ausgegeben.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Die Auswahl des Blockierungsmodus hat nachteilige Auswirkungen auf die Systemleistung.`,
  [`Choose existing directory`]: `Vorhandenes Verzeichnis auswählen`,
  [`Choose one...`]: `Eine(n) auswählen...`,
  [`Choose operations and paths to generate into this API`]: `Wählen Sie Operationen und Pfade aus, die in dieser API generiert werden sollen`,
  [`Choose paths to generate into this API`]: `Wählen Sie Pfade aus, die in dieser API generiert werden sollen`,
  [`Choose the DNS scheme`]: `Wählen Sie das DNS-Schema aus`,
  [`Choose the permissions for the role.`]: `Wählen Sie die Berechtigungen für die Rolle aus.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Wählen Sie aus, ob dynamische oder statische DNS-Adressen für eingehende API-Aufrufe für den Gateway-Service und für den Zugriff auf das Developer Portal verwendet werden sollen.`,
  [`Cipher`]: `Verschlüsselung`,
  [`Ciphers`]: `Verschlüsselungen`,
  [`Clear`]: `Inhalt löschen`,
  [`Clear constraints`]: `Bedingungen löschen`,
  [`Clear file`]: `Datei löschen`,
  [`Clear filters`]: `Filter löschen`,
  [`Clear from selected`]: `Aus Auswahl entfernen`,
  [`Clear operation filter`]: `Operationsfilter löschen`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Klicken Sie auf ein Zertifikat, um Details zur jeweiligen ausgebenden Organisation und zum Fingerabdruck anzuzeigen.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Klicken Sie auf den folgenden Link, um mit der Berechtigung von APIs in einer anderen Registerkarte fortzufahren. Sie erhalten dann den Berechtigungscode für Schritt 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Durch Klicken auf den Link weiter unten wird der Server in einer neuen Registerkarte geöffnet. Wenn im Browser ein Zertifikatsproblem angezeigt wird, können Sie das Zertifikat akzeptieren und hierher zurückkehren, um den Test zu wiederholen.`,
  [`Click add to add a billing integration.`]: `Klicken Sie auf 'Hinzufügen', um eine Abrechnungsintegration hinzuzufügen.`,
  [`Click Add to add a gateway extension.`]: `Klicken Sie auf 'Hinzufügen', um eine Gateway-Erweiterung hinzuzufügen.`,
  [`Click **Add domain** to add domains.`]: `Klicken Sie auf **Domäne hinzufügen**, um Domänen hinzuzufügen.`,
  [`Click Add to add a new base endpoint.`]: `Klicken Sie auf 'Hinzufügen', um einen neuen Basisendpunkt hinzuzufügen.`,
  [`Click Add to add a new HTTP header.`]: `Klicken Sie auf 'Hinzufügen', um einen neuen HTTP-Header hinzuzufügen.`,
  [`Click Add to add a new member.`]: `Klicken Sie auf 'Hinzufügen', um ein neues Mitglied hinzuzufügen.`,
  [`Click Add to add a new role.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Rolle hinzuzufügen.`,
  [`Click Add to add a new scope.`]: `Klicken Sie auf 'Hinzufügen', um einen neuen Bereich hinzuzufügen.`,
  [`Click Add to add a new consumer organization group.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Konsumentenorganisationsgruppe hinzuzufügen.`,
  [`Click Add to create the API Key.`]: `Klicken Sie auf 'Hinzufügen', um den API-Schlüssel zu erstellen.`,
  [`Click Create to add the API Key.`]: `Klicken Sie auf 'Erstellen', um den API-Schlüssel hinzuzufügen.`,
  [`Click Create to add a new user registry.`]: `Klicken Sie auf 'Erstellen', um eine neue Benutzerregistry hinzuzufügen.`,
  [`Click Create user registry to add a new user registry.`]: `Klicken Sie auf 'Benutzerregistry erstellen', um eine neue Benutzerregistry hinzuzufügen.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Klicken Sie auf **Provider-Organisation erstellen**, um Provider-Organisationen hinzuzufügen.`,
  [`Click Edit to add OAuth providers.`]: `Klicken Sie auf 'Bearbeiten', um OAuth-Provider hinzuzufügen.`,
  [`Click Edit to add a user registry.`]: `Klicken Sie auf 'Bearbeiten', um eine Benutzerregistry hinzuzufügen.`,
  [`Click Edit to add an API to the product.`]: `Klicken Sie auf 'Bearbeiten', um eine API zum Produkt hinzuzufügen.`,
  [`Click Create to add a property.`]: `Klicken Sie auf 'Erstellen', um eine Eigenschaft hinzuzufügen.`,
  [`Click Edit to add TLS client profiles.`]: `Klicken Sie auf 'Bearbeiten', um TLS-Clientprofile hinzuzufügen.`,
  [`Click Edit to add user registries.`]: `Klicken Sie auf 'Bearbeiten', um Benutzerregistrys hinzuzufügen.`,
  [`Click Add to add a new consumer organization.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Konsumentenorganisation hinzuzufügen.`,
  [`Click Consumer organizations to add one.`]: `Klicken Sie anschließend auf 'Konsumentenorganisationen', um eine Konsumentenorganisation hinzuzufügen.`,
  [`Click Add to add a new application.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Anwendung hinzuzufügen.`,
  [`Click Add to add parameters.`]: `Klicken Sie auf 'Hinzufügen', um Parameter hinzuzufügen.`,
  [`Click Add to add response.`]: `Klicken Sie auf 'Hinzufügen', um eine Reaktion hinzuzufügen.`,
  [`Click Create to add a new TLS client profile.`]: `Klicken Sie auf 'Erstellen', um ein neues TLS-Clientprofil hinzuzufügen.`,
  [`Click Create to add a new keystore.`]: `Klicken Sie auf 'Erstellen', um einen neuen Keystore hinzuzufügen.`,
  [`Click Create to add a new truststore.`]: `Klicken Sie auf 'Erstellen', um einen neuen Truststore hinzuzufügen.`,
  [`Click **Register remote gateway** to add gateways.`]: `Klicken Sie auf **Fernes Gateway hinzufügen**, um Gateways hinzuzufügen.`,
  [`Click Register gateways to add new Gateway Services.`]: `Klicken Sie auf 'Gateways registrieren', um neue Gateway-Services hinzuzufügen.`,
  [`Click Edit to add Gateway Services.`]: `Klicken Sie auf 'Bearbeiten', um Gateway-Services hinzuzufügen.`,
  [`Click Register gateways to add new gateway services.`]: `Klicken Sie auf 'Gateways registrieren', um neue Gateway-Services hinzuzufügen.`,
  [`Click Edit to add gateway services.`]: `Klicken Sie auf 'Bearbeiten', um Gateway-Services hinzuzufügen.`,
  [`Click Edit to add an email server.`]: `Klicken Sie auf 'Bearbeiten', um einen E-Mail-Server hinzuzufügen.`,
  [`Click Create to add a new TLS server profile.`]: `Klicken Sie auf 'Erstellen', um ein neues TLS-Serverprofil hinzuzufügen.`,
  [`Click Create to add a new email server.`]: `Klicken Sie auf 'Erstellen', um einen neuen E-Mail-Server hinzuzufügen.`,
  [`Click Add to add a new schema definition.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Schemadefinition hinzuzufügen.`,
  [`Click Add to add a new property for this definition.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Eigenschaft für diese Definition hinzuzufügen.`,
  [`Click Add to add a new parameter for this API.`]: `Klicken Sie auf 'Hinzufügen', um einen neuen Parameter für diese API hinzuzufügen.`,
  [`Click Add to add tags and external documentation for this API.`]: `Klicken Sie auf 'Hinzufügen', um Tags und externe Dokumentation für diese API hinzuzufügen.`,
  [`Click Add to add a new path.`]: `Klicken Sie auf 'Hinzufügen', um einen neuen Pfad hinzuzufügen.`,
  [`Click Add to add a property for this API.`]: `Klicken Sie auf 'Hinzufügen', um eine Eigenschaft für diese API hinzuzufügen.`,
  [`Click here to create a security definition`]: `Klicken Sie hier, um eine Sicherheitsdefinition zu erstellen.`,
  [`Click Add to add a new security definition.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Sicherheitsdefinition hinzuzufügen.`,
  [`Click Add to add a target service for this API.`]: `Klicken Sie auf 'Hinzufügen', um einen Zielservice für diese API hinzuzufügen.`,
  [`Click Add to add an operation for this path.`]: `Klicken Sie auf 'Hinzufügen', um eine Operation für diesen Pfad hinzuzufügen.`,
  [`Click Add to add a path parameter for this path.`]: `Klicken Sie auf 'Hinzufügen', um einen Pfadparameter für diesen Pfad hinzuzufügen.`,
  [`Click Add to add a new OAuth provider.`]: `Klicken Sie auf 'Hinzufügen', um einen neuen OAuth-Provider hinzuzufügen.`,
  [`Click Add to add a new provider organization.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Provider-Organisation hinzuzufügen.`,
  [`Click Add to add a new API or product.`]: `Klicken Sie auf 'Hinzufügen', um eine neue API oder ein neues Produkt hinzuzufügen.`,
  [`Click Add to add a new API.`]: `Klicken Sie auf 'Hinzufügen', um eine neue API hinzuzufügen.`,
  [`Click Add to add a new Extension.`]: `Klicken Sie auf 'Hinzufügen', um eine neue Erweiterung hinzuzufügen.`,
  [`Click Add to add new {resource}.`]: `Klicken Sie auf 'Hinzufügen', um neue {resource} hinzuzufügen.`,
  [`Click to expand`]: `Zum Erweitern klicken`,
  [`Click to collapse`]: `Zum Ausblenden klicken`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Klicken Sie hier, um zu erfahren, wie die vom Gateway erforderlichen Zertifikate hochgeladen und verwaltet werden.`,
  [`Client Credentials`]: `Clientberechtigungsnachweise`,
  [`Client information`]: `Clientinformationen`,
  [`Client revocation path`]: `Widerrufspfad für Client`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Klicken Sie auf 'Service registrieren', um einen Service zu registrieren. Dies ermöglicht Ihnen das Testen von Endpunkten beim Konfigurieren anderer Services.`,
  [`Client secret`]: `Geheimer Clientschlüssel`,
  [`Client security`]: `Clientsicherheit`,
  [`Client Type`]: `Clienttyp`,
  [`Client authentication method`]: `Clientauthentifizierungsverfahren`,
  [`Clone`]: `Klonen`,
  [`Close`]: `Schließen`,
  [`Cloud`]: `Cloud`,
  [`Cloud Admin`]: `Cloud-Administrator`,
  [`Cloud Administrator`]: `Cloudadministrator`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Cloudadministrator, Organisationsmanager, Topologieadministrator. Die Rolle "Mitglied" wird automatisch allen Benutzern zugewiesen.`,
  [`Cloud administration`]: `Cloudadministration`,
  [`Cloud Discovery Agent`]: `Cloud-Erkennungsagent`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Lokale Benutzerregistry für Cloud-Manager`,
  [`Cloud-Settings`]: `Cloud-Einstellungen`,
  [`Cloud settings invitation timeout`]: `Cloudeinstellungen für Einladungszeitlimit`,
  [`Cloud settings invitation timeout has been changed`]: `Cloudeinstellungen für Einladungszeitlimit wurden geändert`,
  [`Code editor`]: `Code-Editor`,
  [`Collect Metadata`]: `Metadaten erfassen`,
  [`Collect credentials using`]: `Berechtigungsnachweise sammeln mithilfe von`,
  [`Collect metadata`]: `Metadaten erfassen`,
  [`Combo box`]: `Kombinationsfeld`,
  [`Community Manager`]: `Community-Manager`,
  [`community-manager`]: `Community-Manager`,
  [`Compatibility`]: `Kompatibilität`,
  [`Complex Definitions must be edited in the editor`]: `Komplexe Definitionen müssen im Editor bearbeitet werden.`,
  [`Compose DN`]: `DN erstellen`,
  [`Compose UPN`]: `UPN erstellen`,
  [`Compose a new REST proxy by defining paths and operations`]: `Erstellen Sie einen neuen REST-Proxy, indem Sie Pfade und Operationen definieren`,
  [`Compose a new product by adding rate limits and plans`]: `Erstellen Sie ein neues Produkt, indem Sie Ratenbegrenzungen und Pläne hinzufügen`,
  [`Compression`]: `Komprimierung`,
  [`Condition`]: `Bedingung`,
  [`Condition editor`]: `Bedingungseditor`,
  [`Confidential`]: `Vertraulich`,
  [`Configuration`]: `Konfiguration`,
  [`Configurations`]: `Konfigurationen`,
  [`Configure API endpoint`]: `API-Endpunkt konfigurieren`,
  [`Configure API Gateway Service`]: `API-Gateway-Service konfigurieren`,
  [`Configure analytics service`]: `Analyseservice konfigurieren`,
  [`Configure availability zone`]: `Verfügbarkeitszone konfigurieren`,
  [`Configure catalog user registries`]: `Katalog-Benutzerregistrys konfigurieren`,
  [`Configure cloud`]: `Cloud konfigurieren`,
  [`Configure DataPower API gateway service`]: `DataPower-API-Gateway-Service konfigurieren`,
  [`Configure DataPower gateway service`]: `DataPower-Gateway-Service konfigurieren`,
  [`Configure Email Server`]: `E-Mail-Server konfigurieren`,
  [`Configure gateway extension`]: `Gateway-Erweiterung konfigurieren`,
  [`Configure portal service`]: `Portalservice konfigurieren`,
  [`Configure service`]: `Service konfigurieren`,
  [`Configure topology`]: `Topologie konfigurieren`,
  [`Configure XML`]: `XML konfigurieren`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Konfigurieren Sie einen DataPower-API-Gateway-Service zum Sichern und Erzwingen von APIs.`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Konfigurieren Sie einen DataPower-Gateway-Service zum Sichern und Erzwingen von APIs.`,
  [`Configure a developer portal service for API consumers`]: `Konfigurieren Sie einen Developer Portalservice für API-Konsumenten.`,
  [`Configure advanced features for OIDC settings`]: `Erweiterte Funktionen für OIDC-Einstellungen konfigurieren`,
  [`Configure an analytics service to collect API call data`]: `Konfigurieren Sie einen Analyseservice für die Erfassung von API-Aufrufdaten.`,
  [`Configure an email server to send invitations and notifications to users.`]: `Konfigurieren Sie einen E-Mail-Server für das Senden von Einladungen und Benachrichtigungen an Benutzer.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Konfigurieren Sie die Prüfung, um die Operationen der Admin-Benutzerschnittstelle, der Manager-Benutzerschnittstelle, des Toolkits, der Management-REST-API und der Portalbenutzer zu überwachen. Protokolle jeder Operation zum Erstellen, Aktualisieren und Löschen von Benutzern werden an die unten angegebenen fernen Protokollierungsservices gesendet.`,
  [`Configure availability zones and services`]: `Verfügbarkeitszonen und Services konfigurieren`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Konfigurieren, wie API-Endpunkt-URLs in veröffentlichten APIs zusammengesetzt werden`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Konfigurieren Sie, wie Vanity-Endpunkte im Developer Portal angezeigt werden.`,
  [`Configure mapping`]: `Zuordnung konfigurieren`,
  [`Configure properties of the activity log`]: `Eigenschaften des Aktivitätenprotokolls konfigurieren`,
  [`Configure settings for token management and revocation.`]: `Konfigurieren Sie die Einstellungen für Token-Management und -Widerruf.`,
  [`Configure settings to generate and validate tokens.`]: `Konfigurieren Sie die Einstellungen zum Generieren und Überprüfen von Tokens.`,
  [`Configure the API security`]: `API-Sicherheit konfigurieren`,
  [`Configure the default set of gateway services configured for each catalog`]: `Konfigurieren Sie die Standardgruppe der Gateway-Services, die für jeden Katalog konfiguriert sind.`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Konfigurieren Sie das Developer Portal, das von Anwendungsentwicklern für den Zugriff auf die APIs in diesem Katalog verwendet wird.`,
  [`Configure the gateway services used by default in each catalog`]: `Konfigurieren Sie die Gateway-Services, die standardmäßig in jedem Katalog verwendet werden.`,
  [`Configure the gateway extension for the selected gateway`]: `Gateway-Erweiterung für das ausgewählte Gateway konfigurieren`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Konfigurieren Sie das von IBM verwaltete DataPower-API-Gateway und verwalten Sie Ihre eigenen fernen Gateways. [Weitere Informationen]({link})`,
  [`Configure the keystore and upload certificates`]: `Keystore konfigurieren und Zertifikate hochladen`,
  [`Configure the name and email address to be used in the from field of emails`]: `Konfigurieren Sie den zu verwendenden Namen und die E-Mail-Adresse im Feld für den Absender von E-Mails.`,
  [`Configure the portal service for the catalog`]: `Konfigurieren Sie den Portalservice für den Katalog.`,
  [`Configure the role and assign permissions`]: `Rolle konfigurieren und Berechtigungen zuweisen`,
  [`Configure the security of this API`]: `Konfigurieren Sie die Sicherheit dieser API`,
  [`Configure the sender name and address to use for email notifications`]: `Konfigurieren Sie den Namen und die Adresse des Absenders für E-Mail-Benachrichtigungen`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Konfigurieren Sie den Namen und die Adresse des Absenders und den E-Mail-Server, der für das Senden von Einladungen und Benachrichtigungen an Benutzer verwendet wird.`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Konfigurieren Sie die Gruppe der Rollen, die standardmäßig verwendet werden sollen, wenn eine Konsumentenorganisation erstellt wird.`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Konfigurieren Sie die Gruppe der Rollen, die standardmäßig verwendet werden sollen, wenn eine Provider-Organisation erstellt wird.`,
  [`Configure the templates used to invite and notify users`]: `Konfigurieren Sie die Vorlagen, die zum Einladen und Benachrichtigen von Benutzern verwendet werden.`,
  [`Configure the truststore and upload certificates`]: `Konfigurieren Sie den Truststore und laden Sie Zertifikate hoch.`,
  [`Configure user authentication using`]: `Benutzerauthentifizierung konfigurieren mit`,
  [`Configure user authentication using JSON Web Tokens`]: `Benutzerauthentifizierung konfigurieren mit JSON Web Tokens`,
  [`Configure user authentication using a LDAP provider`]: `Benutzerauthentifizierung konfigurieren mit einem LDAP-Provider`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Benutzerauthentifizierung konfigurieren mit einer lokalen API Connect-Benutzerregistry`,
  [`Configure user authentication using an authentication URL`]: `Benutzerauthentifizierung konfigurieren mit einer Authentifizierungs-URL`,
  [`Configure user authentication using this user registry type`]: `Benutzerauthentifizierung konfigurieren mit diesem Benutzerregistrytyp`,
  [`Configure user registries, OAuth providers and TLS`]: `Benutzerregistrys, OAuth-Provider und TLS konfigurieren`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Benutzerregistrys, TLS, OAuth-Provider und E-Mail-Server konfigurieren`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Keystore für Zugriffstoken, ID-Token und temporäres Token konfigurieren`,
  [`Configured OAuth Provider`]: `Konfigurierter OAuth-Provider`,
  [`Configured OAuth Providers`]: `Konfigurierte OAuth-Provider`,
  [`Configured OAuth provider`]: `Konfigurierter OAuth-Provider`,
  [`Configured OAuth provider list has been updated`]: `Die Liste konfigurierter OAuth-Provider wurde aktualisiert.`,
  [`Configured OAuth provider list has not been updated`]: `Die Liste konfigurierter OAuth-Provider wurde nicht aktualisiert.`,
  [`Confirm`]: `Bestätigen`,
  [`Confirm deletion`]: `Löschvorgang bestätigen`,
  [`Confirm Deletion of Portal`]: `Löschen des Portals bestätigen`,
  [`Confirm visibility settings`]: `Sichtbarkeitseinstellungen bestätigen`,
  [`Confirm new password`]: `Neues Kennwort bestätigen`,
  [`Confirm password`]: `Kennwort bestätigen`,
  [`Confirm password should match password`]: `Bestätigungskennwort muss mit dem Kennwort übereinstimmen`,
  [`Confirmation`]: `Bestätigung`,
  [`Congratulations, you are now registered.`]: `Herzlichen Glückwunsch, Sie sind jetzt registriert.`,
  [`Congratulations, your password has been reset!`]: `Ihr Kennwort wurde zurückgesetzt!`,
  [`Connect`]: `Verbinden`,
  [`Connect to API Connect`]: `Verbindung zu API Connect herstellen`,
  [`Connected to API Manager Product version`]: `Verbunden mit API Manager-Produktversion`,
  [`Connect to Cloud`]: `Verbindung zu Cloud herstellen`,
  [`Connected`]: `Verbunden`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Erstellen Sie Ihre Schemadefinition, indem Sie Eigenschaften der erforderlichen Typen hinzufügen. Sie können Eigenschaften verschachteln`,
  [`Consider for nesting`]: `Für Verschachtelung berücksichtigen`,
  [`Consumed Media Types`]: `Konsumierte Medientypen`,
  [`Consumer`]: `Konsument`,
  [`Consumer invitation and roles`]: `Konsumenteneinladungen und -rollen`,
  [`Consumer onboarding`]: `Onboarding für Konsumenten`,
  [`Consumer organizations`]: `Konsumentenorganisationen`,
  [`Consumer organizations or groups`]: `Konsumentenorganisationen oder -gruppen`,
  [`Consumer organization {name} created`]: `Konsumentenorganisation {name} erstellt`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Konsumentenorganisationen werden von Anwendungsentwicklern im Developer Portal erstellt. Mithilfe der Benutzerschnittstelle von API Manager können Sie auch Konsumentenorganisationen erstellen`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Konsumentenorganisationen werden von Anwendungsentwicklern im Developer Portal erstellt. Hier können Sie auch Konsumentenorganisationen erstellen.`,
  [`Consumer-Org`]: `Konsumentenorganisation`,
  [`Consumers`]: `Konsumenten`,
  [`Consumes`]: `Verbraucht`,
  [`Contact`]: `Ansprechpartner`,
  [`Contact information for the owners of the API.`]: `Kontaktinformationen für die Eigner der API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Wenden Sie sich an Ihren Administrator, um Hilfe beim Konfigurieren des Analyseservice zu erhalten, damit Sie Ihre Daten hier anzeigen können.`,
  [`Contact your administrator for more information.`]: `Wenden Sie sich an den Administrator, um weitere Informationen zu erhalten.`,
  [`Content`]: `Inhalt`,
  [`Content on error`]: `Inhalt bei Fehler`,
  [`Content type`]: `Inhaltstyp`,
  [`Context Variable`]: `Kontextvariable`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Kontext- oder Laufzeitvariable, die das zu validierende JWT enthält. Falls nicht festgelegt, sucht die Richtlinie nach dem JWT in request.headers.authorization.`,
  [`Context variable`]: `Kontextvariable`,
  [`Continue`]: `Weiter`,
  [`Continue on error`]: `Weiter bei Fehler`,
  [`Continue with`]: `Weiter mit`,
  [`Continue with:`]: `Fortfahren mit:`,
  [`Conversion type`]: `Konvertierungstyp`,
  [`Convert to expression`]: `In Ausdruck konvertieren`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `Kopiert`,
  [`Copied to clipboard`]: `In Zwischenablage kopiert`,
  [`Copy`]: `Kopieren`,
  [`Copy Id Headers To Message`]: `Id-Header in Nachricht kopieren`,
  [`Copy to clipboard`]: `In Zwischenablage kopieren`,
  [`Copy file path to clipboard`]: `Dateipfad in Zwischenablage kopieren`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Kostenschätzung für die GraphQL-Abfrage in die Ausgabe kopieren`,
  [`Copy the endpoints below to`]: `Kopieren Sie die folgenden Endpunkte in`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Kopieren Sie den zu verwendenden API-Schlüssel. Sie können diesen API-Schlüssel erneut mit dem Symbol 'Anzeigen' anzeigen.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Copyrightvermerk`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Korrigieren Sie die Fehler im Editor oben oder laden Sie eine gültige Datei hoch.`,
  [`Cost`]: `Kosten`,
  [`Count`]: `Anzahl`,
  [`Count Limit Name`]: `Name der Zählergrenze`,
  [`Count Limits`]: `Zählergrenze`,
  [`Created at`]: `Erstellt am`,
  [`Counter`]: `Zähler`,
  [`Create`]: `Erstellen`,
  [`Create a [Certificate Manager]({link}) service`]: `[Zertifikatmanagerservice]({link}) erstellen`,
  [`Create API`]: `API erstellen`,
  [`Create an API from the Develop section.`]: `Erstellen Sie eine API aus dem Abschnitt 'Entwickeln'.`,
  [`Create API Connect API Key`]: `API Connect-API-Schlüssel erstellen`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `API aus vorhandenem GraphQL-Service (GraphQL-Proxy) erstellen`,
  [`Create API from existing OpenAPI service`]: `API aus vorhandenem OpenAPI-Service erstellen`,
  [`Create API from existing WSDL service (REST proxy)`]: `API aus vorhandenem WSDL-Service erstellen (REST-Proxy)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `API aus vorhandenem WSDL-Service erstellen (SOAP-Proxy)`,
  [`Create API from target service`]: `API aus Zielservice erstellen`,
  [`Create API from existing REST service`]: `API aus vorhandenem REST-Service erstellen`,
  [`Create API from existing SOAP service`]: `API aus vorhandenem SOAP-Service erstellen`,
  [`Create API from existing WSDL service`]: `API aus vorhandenem WSDL-Service erstellen`,
  [`Create API Key failed`]: `Erstellen des API-Schlüssels fehlgeschlagen`,
  [`Create application`]: `Anwendung erstellen`,
  [`Create authentication URL user registry`]: `Benutzerregistry mit Authentifizierungs-URL erstellen`,
  [`Create availability zone`]: `Verfügbarkeitszone erstellen`,
  [`Create Billing`]: `Abrechnung erstellen`,
  [`Create catalog`]: `Katalog erstellen`,
  [`Create catalog property`]: `Katalogeigenschaft erstellen`,
  [`Create consumer organization`]: `Konsumentenorganisation erstellen`,
  [`Create Credentials`]: `Berechtigungsnachweise erstellen`,
  [`Create definition`]: `Definition erstellen`,
  [`Create email server`]: `E-Mail-Server erstellen`,
  [`Create IBM Developer Portal`]: `IBM Developer Portal erstellen`,
  [`Create keystore`]: `Keystore erstellen`,
  [`Create LDAP user registry`]: `LDAP-Benutzerregistry erstellen`,
  [`Create LTPA Key`]: `LTPA-Schlüssel erstellen`,
  [`Create local user registry`]: `Lokale Benutzerregistry erstellen`,
  [`Create native OAuth provider`]: `Nativen OAuth-Provider erstellen`,
  [`Create New GraphQL API`]: `Neue GraphQL-API erstellen`,
  [`Create new OpenAPI`]: `Neue OpenAPI erstellen`,
  [`Create new product`]: `Neues Produkt erstellt`,
  [`Create OIDC user registry`]: `OIDC-Benutzerregistry erstellen`,
  [`Create OpenAPI definition and product definition`]: `OpenAPI-Definition und Produktdefinition erstellen`,
  [`Create operation`]: `Operation erstellen`,
  [`Create organization`]: `Organisation erstellen`,
  [`Create path`]: `Pfad erstellen`,
  [`Create plan`]: `Plan erstellen`,
  [`Create portal`]: `Portal erstellen`,
  [`Create Product`]: `Produkt erstellen`,
  [`Create property`]: `Eigenschaft erstellen`,
  [`Create role`]: `Rolle erstellen`,
  [`Create role default for the Organization`]: `Rollenstandardwert für die Organisation erstellen`,
  [`Create sample user registry`]: `Beispiel-Benutzerregistry erstellen`,
  [`Create security definition`]: `Sicherheitsdefinition erstellen`,
  [`Create space`]: `Bereich erstellen`,
  [`Create subscription`]: `Abonnement erstellen`,
  [`Create TLS client profile`]: `TLS-Clientprofil erstellen`,
  [`Create TLS server profile`]: `TLS-Serverprofil erstellen`,
  [`Create target service`]: `Zielservice erstellen`,
  [`Create third party OAuth provider`]: `OAuth-Provider eines anderen Anbieters erstellen`,
  [`Create truststore`]: `Truststore erstellen`,
  [`Create user registry`]: `Benutzerregistry erstellen`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `GraphQL-Proxy basierend auf einem GraphQL-Service erstellen`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Erstellen Sie einen REST-Proxy basierend auf einem von der OpenAPI beschriebenen Zielservice`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Erstellen Sie einen REST-Proxy basierend auf einem vom WSDL beschriebenen Zielservice`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Erstellen Sie einen REST-Proxy basierend auf einem von der OpenAPI beschriebenen Zielservice`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Erstellen Sie einen REST-Proxy basierend auf dem vom WSDL beschriebenen Zielservice`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Erstellen Sie einen REST-Proxy, der den gesamten Verkehr an eine Ziel-API oder einen Serviceendpunkt weiterleitet`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Erstellen Sie einen WSDL-Proxy basierend auf einem vom WSDL beschriebenen Zielservice`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Erstellen Sie einen WSDL-Proxy basierend auf dem vom WSDL beschriebenen Zielservice`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Erstellt ein neues Objekt mithilfe der Ziehleistenvorlage. Variablen werden aus dem Kontext genommen.`,
  [`Create a portal site for the catalog`]: `Erstellen Sie eine Portalsite für den Katalog`,
  [`Create a product by importing a product definition`]: `Erstellen Sie ein Produkt, indem Sie eine Produktdefinition importieren.`,
  [`Create a security definition`]: `Erstellen Sie eine Sicherheitsdefinition.`,
  [`Create a subscription`]: `Abonnement erstellen`,
  [`Create an API by importing an OpenAPI definition`]: `Erstellen Sie eine API, indem Sie eine OpenAPI-Definition importieren.`,
  [`Create an API that calls an existing SOAP service`]: `API erstellen, die einen vorhandenen SOAP-Service aufruft`,
  [`Create and manage API provider organizations and owners`]: `API-Provider-Organisationen und -Eigner erstellen und verwalten`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Erstellen und verwalten Sie die Bereiche in Ihrem Katalog. Mit Bereichen können Sie Ihren Katalog partitionieren, um verschiedene Entwicklungsteams des API-Providers zu unterstützen.`,
  [`Create and publish`]: `Erstellen und veröffentlichen`,
  [`Create and publish APIs and Products`]: `APIs und Produkte erstellen und veröffentlichen`,
  [`Create and subscribe`]: `Erstellen und abonnieren`,
  [`Create assembly`]: `Assembly erstellen`,
  [`Create empty parent object for failed mapping`]: `Leeres übergeordnetes Objekt für fehlgeschlagene Zuordnung erstellen`,
  [`Create endpoint`]: `Endpunkt erstellen`,
  [`Create from`]: `Erstellen aus`,
  [`Create from Existing WSDL service`]: `Aus vorhandenem WSDL-Service erstellen`,
  [`Create from File or URL`]: `Aus Datei oder URL erstellen`,
  [`Create from existing openAPI service`]: `Aus vorhandenem openAPI-Service erstellen`,
  [`Create gateway extension`]: `Gateway-Erweiterung erstellen`,
  [`Create new user`]: `Neuen Benutzer erstellen`,
  [`Create new version`]: `Neue Version erstellen`,
  [`Create product using a template`]: `Produkt mit einer Vorlage erstellen`,
  [`Create provider organization`]: `Provider-Organisation erstellen`,
  [`Create required child properties for array objects and mapped optional objects`]: `Erforderliche untergeordnete Eigenschaften für Array-Objekte und zugeordnete optionale Objekte erstellen`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Wurde von der OAuth-Provider-Konfiguration als Beispiel erstellt. Achten Sie darauf, die OAuth-Provider unter Verwendung dieses Beispiels mit einer gültigen Benutzerregistry zu aktualisieren.`,
  [`Created new product`]: `Neues Produkt erstellt`,
  [`Create|permission`]: `Erstellen`,
  [`Create|title`]: `Erstellen`,
  [`Creating Draft Product`]: `Entwurfsprodukt erstellen`,
  [`Creating a Consumer organization`]: `Konsumentenorganisation erstellen`,
  [`Creating a consumer organization`]: `Konsumentenorganisation erstellen`,
  [`Creating a new Product`]: `Neues Produkt erstellen`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons-Lizenz (CC-BY-4.0)`,
  [`Credential Extraction Method`]: `Extraktionsmethode für Berechtigungsnachweis`,
  [`Credentials for the Sandbox catalog`]: `Berechtigungsnachweise für den Sandboxkatalog`,
  [`Credentials for the {catalogName} catalog`]: `Berechtigungsnachweis für den Katalog {catalogName}`,
  [`Credential Name`]: `Berechtigungsnachweisname`,
  [`Credential has been added.`]: `Berechtigungsnachweis wurde hinzugefügt.`,
  [`Credential`]: `Berechtigungsnachweis`,
  [`Credentials`]: `Berechtigungsnachweise`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Berechtigungsnachweise sind für die Ausführung von API Designer and Toolkit erforderlich.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Berechtigungsnachweise sind zum Ausführen des Toolkits erforderlich. Speichern Sie die Berechtigungsnachweisdateien in dem Ordner, in dem Ihr Toolkit installiert ist.`,
  [`Credit Card`]: `Kreditkarte`,
  [`Cryptographic Algorithm`]: `Kryptografischer Algorithmus`,
  [`Currency`]: `Währung`,
  [`Current password`]: `Aktuelles Kennwort`,
  [`Current password is invalid.`]: `Das aktuelle Kennwort ist ungültig.`,
  [`Custom`]: `Angepasst`,
  [`Custom API URL`]: `URL für angepasste API`,
  [`Custom expression (optional, one per line)`]: `Angepasster Ausdruck (optional, einer pro Zeile)`,
  [`Custom expression to remove (optional, one per line)`]: `Zu entfernender angepasster Ausdruck (optional, einer pro Zeile)`,
  [`Custom HTML form`]: `Angepasstes HTML-Formular`,
  [`Custom policies`]: `Angepasste Richtlinien`,
  [`Custom form TLS profile`]: `TLS-Profil mit angepasstem Formular`,
  [`Custom form content security policy`]: `Sicherheitsrichtlinie mit angepasstem Formularinhalt`,
  [`Custom form CSP header value`]: `CSP-Headerwert für benutzerdefiniertes Formular`,
  [`Custom form endpoint`]: `Endpunkt mit angepasstem Formular`,
  [`Custom header pattern`]: `Angepasstes Headermuster`,
  [`Custom subscribability is required if custom visibility is selected`]: `Angepasste Abonnierbarkeit ist erforderlich, wenn angepasste Sichtbarkeit ausgewählt ist`,
  [`Customize`]: `Anpassen`,
  [`Customize email sender information`]: `Informationen zum E-Mail-Absender anpassen`,
  [`Customize notification templates`]: `Benachrichtigungsvorlagen anpassen`,
  [`Customize the plan API list`]: `Plan-API-Liste anpassen`,
  [`Czech`]: `Tschechisch`,
  [`DATE MODIFIED`]: `ÄNDERUNGSDATUM`,
  [`DELETE`]: `LÖSCHEN`,
  [`Date created`]: `Erstellungsdatum`,
  [`Description`]: `Beschreibung`,
  [`DETAIL`]: `DETAIL`,
  [`DN Base`]: `DN-Basis`,
  [`DNS Schemes`]: `DNS-Schemata`,
  [`Dashboard`]: `Dashboard`,
  [`Data encoded form body`]: `Datencodierter Formulartext`,
  [`Data flow in API Connect`]: `Datenfluss in API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (v5-kompatibel)`,
  [`DataPower API`]: `DataPower-API`,
  [`DataPower API Gateway`]: `DataPower-API-Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower-Gateway (v5-kompatibel)`,
  [`DataPower Gateway policies`]: `DataPower-Gateway-Richtlinien`,
  [`Debug`]: `Debugging`,
  [`Debug rule`]: `Debugregel`,
  [`Debug XACML policy`]: `XACML-Richtlinie debuggen`,
  [`Dec`]: `Abl`,
  [`Decline`]: `Ablehnen`,
  [`Decline and send`]: `Ablehnen und senden`,
  [`Decode Request Params`]: `Anforderungsparameter entschlüsseln`,
  [`Decrement`]: `Verringern`,
  [`Decrypt`]: `Entschlüsseln`,
  [`Decrypt Crypto JWK variable name`]: `Crypto-JWK-Variablenname entschlüsseln`,
  [`Decrypt Crypto Object`]: `Crypto-Objekt entschlüsseln`,
  [`Decrypt a buffer using the specified certificate`]: `Einen Puffer mithilfe des angegebenen Zertifikats entschlüsseln`,
  [`Default`]: `Standard`,
  [`Default behavior`]: `Standardverhalten`,
  [`Default gateways`]: `Standardgateways`,
  [`default gateway services configured`]: `Standardgateway-Services konfiguriert`,
  [`Default Availability Zone`]: `Standardverfügbarkeitszone`,
  [`Default Base endpoints`]: `Standardbasisendpunkte`,
  [`Default availability zone`]: `Standardverfügbarkeitszone`,
  [`Default base endpoints`]: `Standardbasisendpunkte`,
  [`Default HTML form`]: `Standard-HTML-Formular`,
  [`Default limit`]: `Standardgrenzwert`,
  [`Default OAuth Provider Settings`]: `Standardeinstellungen für OAuth-Provider`,
  [`Default OAuth Provider Settings Object`]: `Standardeinstellungsobjekt des OAuth-Providers`,
  [`Default Plan`]: `Standardplan`,
  [`Default TLS client profile`]: `TLS-Standardclientprofil`,
  [`Default TLS server keystore`]: `Standard-TLS-Server-Keystore`,
  [`Default TLS server profile`]: `Standard-TLS-Serverprofil`,
  [`Default burst-limit`]: `Standardburstbegrenzung`,
  [`Default form`]: `Standardformular`,
  [`Default keystore for access_token signing key`]: `Standardkeystore für den access_token-Signierschlüssel`,
  [`Default keystore for id_token signing key`]: `Standardkeystore für den id_token-Signierschlüssel`,
  [`Default keystore for temporary token signing key`]: `Standardkeystore für den temporären token-Signierschlüssel`,
  [`Default rate-limit`]: `Standardratenbegrenzung`,
  [`Default scopes`]: `Standardbereiche`,
  [`Default table entry`]: `Standardtabelleneintrag`,
  [`Default validator endpoint`]: `Standardvalidatorendpunkt`,
  [`Default validator endpoint set by OAuth provider`]: `Vom OAuth-Provider festgelegter Standardvalidatorendpunkt`,
  [`Default value - no extra constraints apply.`]: `Standardwert - es gelten keine zusätzlichen Bedingungen.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Definieren Sie einen OAuth-Provider eines Drittanbieters, um Tokens auszugeben und zu überprüfen, um den Zugriff auf die API zu schützen.`,
  [`Define catalog specific values for this property`]: `Katalogspezifische Werte für diese Eigenschaft definieren`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Definieren Sie Gruppen, die APIs, Produkte, Kataloge, Anwendungen und zugehörige Einstellungen verwalten.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Definieren Sie Richtliniengruppen, die bei bestimmten Bedingungen ausgeführt werden. Es können mehrere Fälle definiert werden und jeder Fall kann einer beliebigen Bedingung zugeordnet werden. Nur der erste übereinstimmende Fall wird ausgeführt.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Definieren Sie die API-Schutzquelle. Mindestens eine Option muss aktiviert sein.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `Der OIDC-Berechtigungsserver leitet den Berechtigungscode an die folgenden Endpunkte weiter. Diese Umleitungsendpunkte sind erforderlich, wenn ein Kunde die Anwendung für den OIDC-Provider registriert. Wenn die Benutzerregistry z. B. von einer Provider-Organisation verwendet wird, muss der Kunde den Umleitungsendpunkt der Provider-Organisation beim OIDC-Provider registrieren.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Definieren Sie die Eingaben für die Zuordnung. Sie können der Zuordnung auch einen Titel und eine Beschreibung zuweisen.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Definieren Sie die Endpunkte des OpenID-Providers, um eine Authentifizierungsanforderung an Ihren OpenID-Provider zu senden.`,
  [`Define the outputs to be used in the map.`]: `Definieren Sie die Ausgaben für die Zuordnung.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Definieren Sie die Anzahl der freien Testtage und den Preistarif. Benutzer erhalten eine Abrechnung auf der Basis ihres Abonnementdatums.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Definiert, ob der Datenfluss fortgesetzt wird, wenn ein Fehler während der Richtlinienausführung ausgelöst wird. Falls nicht ausgewählt, wird ein Abfangdatenfluss ausgelöst.`,
  [`Definition`]: `Definition`,
  [`Definition Object`]: `Definitionsobjekt`,
  [`Definitions`]: `Definitionen`,
  [`Delete`]: `Löschen`,
  [`Delete Application`]: `Anwendung löschen`,
  [`Delete Cloud Connection.`]: `Cloudverbindung löschen.`,
  [`Delete Selected APIs`]: `Ausgewählte APIs löschen`,
  [`Delete selected client profiles`]: `Ausgewählte Clientprofile löschen`,
  [`Delete selected keystores`]: `Ausgewählte Keystores löschen`,
  [`Delete selected mail servers`]: `Ausgewählte E-Mail-Server löschen`,
  [`Delete Selected Products`]: `Ausgewählte Produkte löschen`,
  [`Delete selected truststores`]: `Ausgewählte Truststores löschen`,
  [`Delete selected user registries`]: `Ausgewählte Benutzerregistrys löschen`,
  [`Delete Staged Product`]: `Bereitgestelltes Produkt löschen`,
  [`Delete a space`]: `Bereich löschen`,
  [`Delete a catalog`]: `Katalog löschen`,
  [`Delete API Key`]: `API-Schlüssel löschen`,
  [`Delete API Key failed`]: `Löschen des API-Schlüssels fehlgeschlagen`,
  [`Delete availability zone`]: `Verfügbarkeitszone löschen`,
  [`Delete selected requests`]: `Ausgewählte Anforderungen löschen`,
  [`Delete row`]: `Zeile löschen`,
  [`Delete|title`]: `Löschen`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `Das Löschen eines Katalogs kann fehlschlagen, wenn Sie über eine große Anzahl an Konsumentenorganisationen, Anwendungen, veröffentlichten Produkten und weiteren Ressourcen verfügen.  Ziehen Sie eine Bereinigung dieser Ressourcen vor dem Löschen des Katalogs in Betracht.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `Das Löschen einer Provider-Organisation kann fehlschlagen, wenn Sie über eine große Anzahl an Katalogen, Konsumentenorganisationen, Anwendungen, veröffentlichten Produkten und weiteren Ressourcen verfügen. Ziehen Sie eine Bereinigung dieser Ressourcen vor dem Löschen der Provider-Organisation in Betracht.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `Das Löschen eines Bereichs kann fehlschlagen, wenn Sie über eine große Anzahl an Konsumentenorganisationen, Anwendungen, veröffentlichten Produkten und weiteren Ressourcen verfügen.  Ziehen Sie eine Bereinigung dieser Ressourcen vor dem Löschen des Bereichs in Betracht.`,
  [`Deleting {{name}}...`]: `{name} wird gelöscht...`,
  [`Deprecate`]: `Nicht mehr unterstützen`,
  [`Deprecate product`]: `Produkt nicht mehr unterstützen`,
  [`Deprecated`]: `Nicht weiter unterstützt`,
  [`Deprecate|permission`]: `Nicht mehr unterstützen`,
  [`Description of gateway`]: `Beschreibung des Gateways`,
  [`Description text for the behaviour of the modal.`]: `Beschreibungstext für das Verhalten des Modals.`,
  [`Descriptive name`]: `Beschreibender Name`,
  [`Design`]: `Design`,
  [`Designate a current member as owner of a consumer organization.`]: `Legen Sie ein aktuelles Mitglied als Eigner einer Konsumentenorganisation fest.`,
  [`Designate a current member as owner of a provider organization.`]: `Legen Sie ein aktuelles Mitglied als Eigner einer Provider-Organisation fest.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Ziel`,
  [`Determines which credential-extraction method to use.`]: `Bestimmt, welche Extraktionsmethode für Berechtigungsnachweise verwendet werden soll.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Legen Sie fest, ob dieser Typ/dieses Feld und alle zutreffenden Argumente in das Schema eingeschlossen werden sollen.`,
  [`Develop`]: `Entwickeln`,
  [`Develop APIs`]: `APIs entwickeln`,
  [`Develop APIs and products`]: `APIs und Produkte entwickeln`,
  [`Developer`]: `Entwickler`,
  [`developer`]: `Entwickler`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `Entwickler-Community`,
  [`Developers can request a state upgrade from development to production`]: `Entwickler können ein Statusupgrade von der Entwicklung zur Produktion anfordern.`,
  [`Development`]: `Entwicklung`,
  [`Directive`]: `Anweisung`,
  [`Director Endpoint`]: `Director-Endpunkt`,
  [`Director TLS client profile`]: `TLS-Clientprofil für Director`,
  [`Disable`]: `Inaktivieren`,
  [`Disable Activity log`]: `Aktivitätenprotokoll inaktivieren`,
  [`Disable Self-Service Onboarding`]: `Self-Service-Onboarding inaktivieren`,
  [`Disable spaces`]: `Bereiche inaktivieren`,
  [`Disable buffering`]: `Pufferung inaktivieren`,
  [`Disable Self-Service Onboarding Approval`]: `Self-Service-Onboarding-Genehmigung inaktivieren`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Überschreiben des Einladungszeitlimits für Verbraucherorganisationen inaktivieren.`,
  [`Disabled`]: `Inaktiviert`,
  [`disabled`]: `inaktiviert`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Zum Inaktivieren des Self-Service-Onboarding müssen alle Anwendungsentwickler vom API-Provider, vom Eigner oder vom Administrator einer vorhandenen Konsumentenorganisation eingeladen werden.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Durch Inaktivieren der Self-Service-Onboarding-Genehmigung werden alle Onboarding-Anforderungen automatisch genehmigt.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `Durch Inaktivierung wird das Einladungszeitlimit für Verbraucherorganisationen nicht auf das Katalogzeitlimit gesetzt.`,
  [`Discover targets`]: `Ziele erkennen`,
  [`Display table checkboxes`]: `Kontrollkästchen für Tabellen anzeigen`,
  [`Display vanity endpoint`]: `Vanity-Endpunkt anzeigen`,
  [`Do not publish API (continue editting)`]: `API nicht veröffentlichen (Bearbeitung fortsetzen)`,
  [`Do not require applications or users to authenticate`]: `Anwendungen oder Benutzer müssen nicht zur Authentifizierung aufgefordert werden`,
  [`Do not require authentication`]: `Keine Authentifizierung erforderlich`,
  [`Do not share the resource with provider organizations`]: `Die Ressource nicht mit Provider-Organisationen gemeinsam nutzen`,
  [`Do not share the API gateway with provider organizations`]: `Nutzen Sie das API-Gateway nicht gemeinsam mit Provider-Organisationen`,
  [`Do not share the service with provider organizations`]: `Service nicht mit Provider-Organisationen gemeinsam nutzen`,
  [`Do not use Dynamic DNS`]: `Kein dynamisches DNS verwenden`,
  [`Do you want to remove these references?`]: `Möchten Sie diese Referenzen entfernen?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker-Dokumentation`,
  [`Docs`]: `Dokumentation`,
  [`Document Editor`]: `Dokumenteditor`,
  [`Documentation and tutorials with step-by-step instructions`]: `Dokumentation und Tutorials mit schrittweisen Anleitungen`,
  [`Domain name`]: `Domänenname`,
  [`Domain Name`]: `Domänenname`,
  [`Domains handled by gateway via SNI`]: `Domänen, die vom Gateway über SNI verarbeitet werden`,
  [`Don't have an account?`]: `Haben Sie noch keinen Account?`,
  [`Done`]: `Fertig`,
  [`Download`]: `Herunterladen`,
  [`Download Gateway`]: `Gateway herunterladen`,
  [`Download and install`]: `Herunterladen und installieren`,
  [`Download and set up DataPower API Gateway`]: `DataPower-API-Gateway herunterladen und einrichten`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `DataPower-API-Gateway zur Verwendung vor Ort oder in einer beliebigen Cloud herunterladen und einrichten.`,
  [`Download for Linux`]: `Download für Linux`,
  [`Download for Mac`]: `Download für Mac`,
  [`Download for Windows`]: `Download für Windows`,
  [`Download gateway`]: `Gateway herunterladen`,
  [`Download schema`]: `Schema herunterladen`,
  [`Download Toolkit`]: `Toolkit herunterladen`,
  [`Download toolkit`]: `Toolkit herunterladen`,
  [`Download toolkit and credentials for various platforms`]: `Laden Sie das Toolkit und die Berechtigungsnachweise für verschiedene Plattformen herunter.`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Die heruntergeladenen Berechtigungsnachweise wurden für diese Cloudverbindung nicht gefunden. Die Anmeldung verwendet die Standardberechtigungsnachweise.`,
  [`Dutch`]: `Niederländisch`,
  [`Draft {type} creation failed`]: `Erstellung des Entwurfs {type} fehlgeschlagen`,
  [`Drafts`]: `Entwürfe`,
  [`Drag and drop files here or click to upload`]: `Datei hierher ziehen und ablegen oder für Upload klicken`,
  [`Drag & Drop`]: `Ziehen und ablegen`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `Dynamisches DNS`,
  [`Dynamic OAuth configuration from a URL`]: `Dynamische OAuth-Konfiguration aus einer URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Dynamische OAuth-Konfiguration aus einer Literalzeichenfolge`,
  [`Dynamic group`]: `Dynamische Gruppe`,
  [`Dynamic table entries`]: `Dynamische Tabelleneinträge`,
  [`ENABLE`]: `AKTIVIEREN`,
  [`Encoded`]: `Verschlüsselt`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Jeder Bereich wird von einem anderen API-Provider-Entwicklungsteam verwendet und verfügt über eigene Verwaltungsfunktionen, die sich speziell auf die APIs beziehen, die das zugehörige Team in diesem Bereich veröffentlicht, wodurch jedes Team seine APIs unabhängig verwalten kann. Wenn Sie eine API in einem Katalog bereitstellen oder veröffentlichen, für den Bereiche aktiviert sind, geben Sie den Bereich in diesem Katalog an, in dem Sie die Bereitstellung oder Veröffentlichung durchführen möchten.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Jede Provider-Organisation verfügt über APIs, Produkte, Pläne und Kataloge.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Erstellen, sichern, verwalten, teilen und analysieren Sie ohne großen Aufwand APIs in einer Cloud und lokal vor Ort.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Jede Provider-Organisation verfügt über APIs, Produkte, Pläne und Kataloge. [Weitere Informationen]({link})`,
  [`Edit`]: `Bearbeiten`,
  [`Edit access token TTL`]: `Zugriffstoken-TTL bearbeiten`,
  [`Edit admin organization invitation timeout`]: `Verwaltungsorganisation - Zeitlimit für Einladungen bearbeiten`,
  [`Edit API`]: `API bearbeiten`,
  [`Edit API Connect UI`]: `API Connect-Benutzerschnittstelle bearbeiten`,
  [`Edit API endpoint for unenforced APIs`]: `API-Endpunkt für nicht erzwungene APIs bearbeiten`,
  [`Edit API gateway service`]: `API-Gateway-Service bearbeiten`,
  [`Edit API Path`]: `API-Pfad bearbeiten`,
  [`Edit API security definition`]: `API-Sicherheitsdefinition bearbeiten`,
  [`Edit API user registries`]: `API-Benutzerregistrys bearbeiten`,
  [`Edit analytics service`]: `Analyseservice bearbeiten`,
  [`Edit application`]: `Anwendung bearbeiten`,
  [`Edit Assembly`]: `Assembly bearbeiten`,
  [`Edit authentication URL user registry`]: `Benutzerregistry für Authentifizierungs-URL bearbeiten`,
  [`Edit availability zone`]: `Verfügbarkeitszone bearbeiten`,
  [`Edit Availablity Zone`]: `Verfügbarkeitszone bearbeiten`,
  [`Edit and apply constraints to your selected items.`]: `Bearbeiten Sie Bedingungen für Ihre ausgewählten Elemente und wenden Sie sie an.`,
  [`Edit billing integration`]: `Abrechnungsintegration bearbeiten`,
  [`Edit Catalog Details`]: `Katalogdetails bearbeiten`,
  [`Edit cloud setting invitation timeout`]: `Cloudeinstellungen - Zeitlimit für Einladungen bearbeiten`,
  [`Edit constraints`]: `Bedingungen bearbeiten`,
  [`Edit consumer organization`]: `Konsumentenorganisation bearbeiten`,
  [`Edit DNS Scheme`]: `DNS-Schema bearbeiten`,
  [`Edit DataPower API gateway service`]: `DataPower-API-Gateway-Service bearbeiten`,
  [`Edit DataPower gateway service`]: `DataPower-Gateway-Service bearbeiten`,
  [`Edit definition`]: `Definition bearbeiten`,
  [`Edit dynamic DNS`]: `Dynamic DNS bearbeiten`,
  [`Edit email server`]: `E-Mail-Server bearbeiten`,
  [`Edit extensions`]: `Erweiterungen bearbeiten`,
  [`Edit Gateway Service`]: `Gateway-Service bearbeiten`,
  [`Edit gateway`]: `Gateway bearbeiten`,
  [`Edit gateway extension`]: `Gateway-Erweiterung bearbeiten`,
  [`Edit Gateways`]: `Gateways bearbeiten`,
  [`Edit IBM-managed gateway`]: `Von IBM verwaltetes Gateway bearbeiten`,
  [`Edit Invitation Timeout`]: `Zeitlimit für Einladungen bearbeiten`,
  [`Edit gateways`]: `Gateways bearbeiten`,
  [`Edit invitation timeout`]: `Zeitlimit für Einladungen bearbeiten`,
  [`Edit JSON`]: `JSON bearbeiten`,
  [`Edit keystore`]: `Keystore bearbeiten`,
  [`Edit LDAP user registry`]: `LDAP-Benutzerregistry bearbeiten`,
  [`Edit LTPA Token`]: `LTPA-Token bearbeiten`,
  [`Edit lifecycle approvals`]: `Lebenszyklusgenehmigungen bearbeiten`,
  [`Edit local user registry`]: `Lokale Benutzerregistry bearbeiten`,
  [`Edit native OAuth provider`]: `Nativen OAuth-Provider bearbeiten`,
  [`Edit New Billing System`]: `Neues Abrechnungssystem bearbeiten`,
  [`Edit Notification Server`]: `Benachrichtigungsserver bearbeiten`,
  [`Edit Notification template`]: `Benachrichtigungsvorlage bearbeiten`,
  [`Edit OAuth Provider`]: `OAuth-Provider bearbeiten`,
  [`Edit OAuth provider details`]: `OAuth-Providerdetails bearbeiten`,
  [`Edit OAuth Provider Visibility`]: `Sichtbarkeit des OAuth-Providers bearbeiten`,
  [`Edit OIDC User Registry`]: `OIDC-Benutzerregistry bearbeiten`,
  [`Edit Onboarding`]: `Onboarding bearbeiten`,
  [`Edit Operation`]: `Operation bearbeiten`,
  [`Edit Organization`]: `Organisation bearbeiten`,
  [`Edit Path`]: `Pfad bearbeiten`,
  [`Edit Path Parameters`]: `Pfadparameter bearbeiten`,
  [`Edit notification template`]: `Benachrichtigungsvorlage bearbeiten`,
  [`Edit OAuth provider`]: `OAuth-Provider bearbeiten`,
  [`Edit OAuth provider visibility`]: `Sichtbarkeit des OAuth-Providers bearbeiten`,
  [`Edit OIDC user registry`]: `OIDC-Benutzerregistry bearbeiten`,
  [`Edit onboarding`]: `Onboarding bearbeiten`,
  [`Edit operation`]: `Operation bearbeiten`,
  [`Edit organization`]: `Organisation bearbeiten`,
  [`Edit path`]: `Pfad bearbeiten`,
  [`Edit path parameters`]: `Pfadparameter bearbeiten`,
  [`Edit plan`]: `Plan bearbeiten`,
  [`Edit portal`]: `Portal bearbeiten`,
  [`Edit portal service`]: `Portalservice bearbeiten`,
  [`Edit Portal Settings`]: `Portaleinstellungen bearbeiten`,
  [`Edit product`]: `Produkt bearbeiten`,
  [`Edit product APIs`]: `Produkt-APIs bearbeiten`,
  [`Edit Product Subscribability`]: `Produktabonnierbarkeit bearbeiten`,
  [`Edit Product Visibility`]: `Produktsichtbarkeit bearbeiten`,
  [`Edit Property`]: `Eigenschaft bearbeiten`,
  [`Edit Provider Organization`]: `Provider-Organisation bearbeiten`,
  [`Edit Refresh Token TTL`]: `Aktualisierungstoken-TTL bearbeiten`,
  [`Edit remote gateway`]: `Fernes Gateway bearbeiten`,
  [`Edit Reset Password TTL`]: `TTL für Kennwort für Zurücksetzen bearbeiten`,
  [`Edit Role`]: `Rolle bearbeiten`,
  [`Edit Role Default for Provider Organizations`]: `Rollenstandardwert für Provider-Organisationen bearbeiten`,
  [`Edit Role for Provider Organizations`]: `Rolle für Provider-Organisationen bearbeiten`,
  [`Edit property`]: `Eigenschaft bearbeiten`,
  [`Edit provider organization`]: `Provider-Organisation bearbeiten`,
  [`Edit refresh token TTL`]: `Aktualisierungstoken-TTL bearbeiten`,
  [`Edit reset password TTL`]: `TTL für Kennwort für Zurücksetzen bearbeiten`,
  [`Edit role`]: `Rolle bearbeiten`,
  [`Edit role default for Provider Organizations`]: `Rollenstandardwert für Provider-Organisationen bearbeiten`,
  [`Edit role for Provider Organizations`]: `Rolle für Provider-Organisationen bearbeiten`,
  [`Edit Roles`]: `Rollen bearbeiten`,
  [`Edit Schema in JSON/XML`]: `Schema in JSON/XML bearbeiten`,
  [`Edit security definition`]: `Sicherheitsdefinition bearbeiten`,
  [`Edit sender`]: `Absender bearbeiten`,
  [`Edit sender & email server`]: `Absender und E-Mail-Server bearbeiten`,
  [`Edit sender Info`]: `Informationen zum Absender bearbeiten`,
  [`Edit service visibility`]: `Servicesichtbarkeit bearbeiten`,
  [`Edit show/hide settings`]: `Einstellungen zum Ein-/Ausblenden bearbeiten`,
  [`Edit space details`]: `Bereichsdetails bearbeiten`,
  [`Edit TLS client profile`]: `TLS-Clientprofil bearbeiten`,
  [`Edit TLS client profile visibility`]: `Sichtbarkeit des TLS-Clientprofils bearbeiten`,
  [`Edit TLS server profile`]: `TLS-Serverprofil bearbeiten`,
  [`Edit third party OAuth provider`]: `OAuth-Provider eines anderen Anbieters bearbeiten`,
  [`Edit truststore`]: `Truststore bearbeiten`,
  [`Edit unenforced API`]: `Nicht erzwungene API bearbeiten`,
  [`Edit user registries`]: `Benutzerregistrys bearbeiten`,
  [`Edit user registry`]: `Benutzerregistry bearbeiten`,
  [`Edit user registry visibility`]: `Sichtbarkeit der Benutzerregistry bearbeiten`,
  [`Edit user registry for API Manager`]: `Benutzerregistry für API Manager bearbeiten`,
  [`Edit user registry for Cloud Manager`]: `Benutzerregistry für Cloud Manager bearbeiten`,
  [`Edit vanity API endpoints`]: `Vanity-API-Endpunkte bearbeiten`,
  [`Edit visibility`]: `Sichtbarkeit bearbeiten`,
  [`Edit XML`]: `XML bearbeiten`,
  [`Edit app lifecycle approved`]: `Bearbeiten des App-Lebenszyklus genehmigt`,
  [`Edit app lifecycle cancelled`]: `Bearbeiten des App-Lebenszyklus abgebrochen`,
  [`Edit app lifecycle denied`]: `Bearbeiten des App-Lebenszyklus verweigert`,
  [`Edit app lifecycle pending`]: `Bearbeiten des App-Lebenszyklus anstehend`,
  [`Edit app lifecycle request`]: `Bearbeiten des App-Lebenszyklus angefordert`,
  [`Edit app reinstated`]: `Bearbeiten der App wieder eingesetzt`,
  [`Edit app suspended`]: `Bearbeiten der App ausgesetzt`,
  [`Edit audit setting`]: `Auditeinstellung bearbeiten`,
  [`Edit definitions`]: `Definitionen bearbeiten`,
  [`Enable external group mapping`]: `Externe Gruppenzuordnung aktivieren`,
  [`Edit extra constraints for the GraphQL schema`]: `Zusätzliche Bedingungen für das GraphQL-Schema bearbeiten`,
  [`Edit GraphQL schema|title`]: `GraphQL-Schema bearbeiten`,
  [`Edit inline schema`]: `Inlineschema bearbeiten`,
  [`Edit inputs`]: `Eingaben bearbeiten`,
  [`Edit invitation`]: `Einladung bearbeiten`,
  [`Edit mail server test connection`]: `Testverbindung für Mail-Server bearbeiten`,
  [`Edit member invitation`]: `Einladung von Mitgliedern bearbeiten`,
  [`Edit notification templates`]: `Benachrichtigungsvorlagen bearbeiten`,
  [`Edit notification templates:`]: `Benachrichtigungsvorlagen bearbeiten:`,
  [`Edit operation list`]: `Operationsliste bearbeiten`,
  [`Edit or upload JSON schema`]: `JSON-Schema bearbeiten oder hochladen`,
  [`Edit outputs`]: `Ausgaben bearbeiten`,
  [`Edit password changed`]: `Geändertes Kennwort bearbeiten`,
  [`Edit password reset`]: `Zurückgesetztes Kennwort bearbeiten`,
  [`Edit plan APIs`]: `Plan-APIs bearbeiten`,
  [`Edit schema`]: `Schema bearbeiten`,
  [`Edit schema|button`]: `Schema bearbeiten`,
  [`Edit schema|title`]: `Schema bearbeiten`,
  [`Edit settings for roles, notifications and more.`]: `Einstellungen für Rollen, Benachrichtigungen usw. bearbeiten.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Einstellungen für Benutzerregistrys, Rollen, Endpunkte und mehr bearbeiten`,
  [`Edit sign up`]: `Anmeldung bearbeiten`,
  [`Edit task product lifecycle approved`]: `Bearbeiten des Taskproduktlebenszyklus genehmigt`,
  [`Edit task product lifecycle cancelled`]: `Bearbeiten des Taskproduktlebenszyklus abgebrochen`,
  [`Edit task product lifecycle denied`]: `Bearbeiten des Taskproduktlebenszyklus verweigert`,
  [`Edit task product lifecycle pending`]: `Bearbeiten des Taskproduktlebenszyklus anstehend`,
  [`Edit task product lifecycle request`]: `Bearbeiten des Taskproduktlebenszyklus angefordert`,
  [`Edit task subscription approved`]: `Bearbeiten von Taskabonnements genehmigt`,
  [`Edit task subscription cancelled`]: `Bearbeiten von Taskabonnements abgebrochen`,
  [`Edit task subscription denied`]: `Bearbeiten von Taskabonnements verweigert`,
  [`Edit task subscription pending`]: `Bearbeiten von Taskabonnements anstehend`,
  [`Edit task subscription request`]: `Bearbeiten von Taskabonnements angefordert`,
  [`Edit template`]: `Vorlage bearbeiten`,
  [`Edit the TLS client profile.`]: `Bearbeiten Sie das TLS-Clientprofil.`,
  [`Edit the TLS Server profiles`]: `Bearbeiten Sie das TLS-Serverprofil.`,
  [`Edit the keystore title.`]: `Bearbeiten Sie den Keystore-Titel.`,
  [`Edit the organization summary details as required`]: `Bearbeiten Sie nach Bedarf die Details der Organisationszusammenfassung`,
  [`Edit the organization title`]: `Bearbeiten Sie den Organisationstitel`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Bearbeiten Sie die Parameter zum Konfigurieren eines E-Mail-Servers. Die Felder sind erforderlich, es sei denn, sie sind als optional gekennzeichnet.`,
  [`Edit signed token`]: `Signiertes Token bearbeiten`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Bearbeiten Sie den Text für die E-Mail-Vorlage. Variablen sind in geschweiften Klammern enthalten und können nicht bearbeitet werden.`,
  [`Edit the truststore.`]: `Bearbeiten Sie den Truststore.`,
  [`Edit the visibility for each resource.`]: `Bearbeiten Sie die Sichtbarkeit für jede Ressource.`,
  [`Edit user details`]: `Benutzerdetails bearbeiten`,
  [`Edit {notificationTemplate}`]: `{notificationTemplate} bearbeiten`,
  [`Edit, assemble, secure and test APIs`]: `APIs bearbeiten, assemblieren, sichern und testen`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `APIs bearbeiten, assemblieren, sichern und testen. APIs mithilfe von Produkten zur Veröffentlichung für Konsumenten paketieren.`,
  [`Edit, upload, or infer XML schema`]: `XML-Schema bearbeiten, hochladen oder übertragen`,
  [`Editors`]: `Bearbeiter`,
  [`Edit|permission`]: `Bearbeiten`,
  [`Edit API key timeout`]: `Zeitlimit des API-Schlüssels bearbeiten`,
  [`Edit Base64 encoding for temporary tokens`]: `Base64-Codierung für temporäre Token bearbeiten`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Es muss entweder ein 'ID-Token-Signaturverschlüsselungsobjekt' oder ein 'Signierschlüssel für das ID-Token' angegeben werden.`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Es muss entweder 'JWT-Prüfschlüsselobjekt' oder 'JWT-Prüfung - JWK' angegeben werden.`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Wählen Sie entweder die Option, den in der API definierten Endpunkt zu verwenden, oder stellen Sie einen Standard-Validatorenendpunkt bereit.`,
  [`Element name for JSON array elements`]: `Elementname für JSON-Arrayelemente`,
  [`Email`]: `E-Mail`,
  [`Email address`]: `E-Mail-Adresse`,
  [`Email Content`]: `E-Mail-Inhalt`,
  [`Email endpoint`]: `E-Mail-Endpunkt`,
  [`Email required`]: `E-Mail erforderlich`,
  [`Email sender`]: `E-Mail-Absender`,
  [`Email server`]: `E-Mail-Server`,
  [`Email server configuration`]: `E-Mail-Server-Konfiguration`,
  [`Email servers`]: `E-Mail-Server`,
  [`Empty case`]: `Leerer Fall`,
  [`Empty catch`]: `Leeres Catch`,
  [`Empty JSON array handling`]: `Behandlung leerer JSON-Arrays`,
  [`Empty XML element handling`]: `Handhabung leerer XML-Elemente`,
  [`Enable`]: `Aktivieren`,
  [`Enable API consumers to invite collaborators and assign roles`]: `API-Konsumenten aktivieren, um Mitarbeiter einzuladen und Rollen zuzuweisen`,
  [`Enable application lifecycle`]: `Anwendungslebenszyklus aktivieren`,
  [`Enable billing`]: `Abrechnung aktivieren`,
  [`Enable gateway services`]: `Gateway-Service aktivieren`,
  [`Enable gateway services and policies`]: `Gateway-Services und Richtlinien aktivieren`,
  [`Enable GraphiQL`]: `GraphiQL aktivieren`,
  [`Enable GraphiQL editor`]: `GraphiQL-Editor aktivieren`,
  [`Enable GraphiQL Editor on browser`]: `GraphiQL-Editor im Browser aktivieren`,
  [`Enable JSON post processing`]: `JSON-Nachverarbeitung aktivieren`,
  [`Enable lifecycle approvals`]: `Lebenszyklusgenehmigungen aktivieren`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Aktivieren Sie die NONCE-Erweiterung, um zu verhindern, dass verdächtige Anfragen wiederverwendet (erneut abgespielt) werden.`,
  [`Enable OAuth providers`]: `OAuth-Provider aktivieren`,
  [`Enable OIDC`]: `OIDC aktivieren`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Aktivieren Sie die OpenID Connect-Vorlage für das Generieren von ID-Tokens.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `PKCE-Erweiterung aktivieren, damit öffentliche Clients die Bedrohung durch das Abfangen des Berechtigungscodes minimieren können`,
  [`Enable production mode`]: `Produktionsmodus aktivieren`,
  [`Enable Proxy`]: `Proxy aktivieren`,
  [`Enable Self-Service Onboarding`]: `Self-Service-Onboarding aktivieren`,
  [`Enable spaces`]: `Bereiche aktivieren`,
  [`Enable token management`]: `Token-Management aktivieren`,
  [`Enable buffering`]: `Pufferung aktivieren`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `API-Konsumenten für die Erstellung von Development-Portal-Accounts ohne Einladung aktivieren.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `CORS-Zugriffssteuerung (Cross-Origin Resource Sharing) für Ihre API aktivieren.`,
  [`Enable debug response headers`]: `Debug-Antwortheader aktivieren`,
  [`Enable proof key for code exchange`]: `Nachweisschlüssel für Codeaustausch aktivieren`,
  [`Enable publishing of this product`]: `Veröffentlichung dieses Produkts aktivieren`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Umleitung von Berechtigungscode von Drittanbietern über den Portal OIDC-Endpunkt aktivieren`,
  [`Enable security`]: `Sicherheit aktivieren`,
  [`Enable Self-Service Onboarding Approval`]: `Self-Service-Onboarding-Genehmigung aktivieren`,
  [`Enable the following gateway services and policies for this catalog`]: `Aktivieren Sie die folgenden Gateway-Services und Richtlinien für diesen Katalog.`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Benutzern das Konfigurieren eines Auditereignisses ermöglichen. Ein Auditereignis ist eine detaillierte Zusammenfassung davon, wer wann welches Ereignis ausgeführt hat.`,
  [`Enable/disable custom notification templates`]: `Angepasste Benachrichtigungsvorlagen aktivieren/inaktivieren`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Base64-Codierung für Einladungen und Tokens zum Zurücksetzen des Kennworts aktivieren oder inaktivieren`,
  [`Enable overriding consumer organizations invitation timeout`]: `Überschreiben des Einladungszeitlimits für Verbraucherorganisationen aktivieren`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `Sie können die Base64-Codierung für temporäre Tokens aktivieren oder inaktivieren. Temporäre Token sind Einladungen und Token zum Zurücksetzen des Kennworts. Wenn die Base64-Codierung aktiviert ist, wird das temporäre Token im Base64-Format codiert. Wenn die Base64-Codierung inaktiviert ist, verbleibt das temporäre Token im JWT-Format (JSON Web Token) und bedeutet, dass die Länge der URL, die der Benutzer empfängt, reduziert wird.`,
  [`Enabled`]: `Aktiviert`,
  [`enabled`]: `aktiviert`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `Das Aktivieren des Anwendungslebenszyklus macht es möglich, dass Anwendungen den Entwicklungs- oder Produktionsstatus aufweisen und an unterschiedliche Gateways und Endpunkte weitergeleitet werden können.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Die Aktivierung der Onboarding-Genehmigung für das Self-Service-Onboarding macht erforderlich, dass alle Onboarding-Anforderungen vom API-Provider oder einem Administrator genehmigt werden.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Wenn Sie Self-Service-Onboarding aktivieren, kann ein Anwendungsentwickler sich ohne eine Einladung des API-Providers oder des Eigners oder Administrators einer vorhandenen Konsumentenorganisation anmelden.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `Durch Aktivierung wird das Einladungszeitlimit für alle Verbraucherorganisationen auf das Katalogzeitlimit gesetzt.`,
  [`Encode Query params`]: `Abfrageparameter verschlüsseln`,
  [`Encrypt`]: `Verschlüsseln`,
  [`Encrypt Crypto Object`]: `Crypto-Objekt verschlüsseln`,
  [`Encrypt JWK variable name`]: `JWK-Variablenname verschlüsseln`,
  [`Encrypt a buffer using the specified certificate`]: `Einen Puffer mithilfe des angegebenen Zertifikats verschlüsseln`,
  [`Encryption Algorithm`]: `Verschlüsselungsalgorithmus`,
  [`Endpoint`]: `Endpunkt`,
  [`Endpoint URLs`]: `Endpunkt-URLs`,
  [`Endpoint behavior`]: `Endpunktverhalten`,
  [`Endpoint Behavior`]: `Endpunktverhalten`,
  [`Endpoint for unenforced APIs`]: `Endpunkt für nicht erzwungene APIs`,
  [`Endpoints`]: `Endpunkte`,
  [`Enforce Required Params`]: `Erforderliche Parameter erzwingen`,
  [`Enforce the API by using API Connect Gateway.`]: `API mithilfe des API Connect-Gateways erzwingen.`,
  [`Enforced`]: `Erzwungen`,
  [`English`]: `Englisch`,
  [`Enter Summary`]: `Zusammenfassung eingeben`,
  [`Enter URL`]: `URL eingeben`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Geben Sie den Benutzerregistrynamen ein. Wenn das Feld leer ist, wird standardmäßig die lokale Benutzerregistry von API Manager verwendet`,
  [`Enter a catalog name`]: `Katalognamen eingeben`,
  [`Enter a Display Name`]: `Anzeigenamen eingeben`,
  [`Enter a space name`]: `Bereichsnamen eingeben`,
  [`Enter a brief description`]: `Kurzbeschreibung eingeben`,
  [`Enter a display name`]: `Anzeigenamen eingeben`,
  [`Enter a temporary sign in password`]: `Vorläufiges Anmeldekennwort eingeben`,
  [`Enter an Organization Name`]: `Organisationsnamen eingeben`,
  [`Enter an Organization Title`]: `Organisationstitel eingeben`,
  [`Enter catalog title`]: `Katalogtitel eingeben`,
  [`Enter details of the consumer organization`]: `Details zur Konsumentenorganisation eingeben`,
  [`Enter details of the product`]: `Details des Produkts eingeben`,
  [`Enter details of the provider organization`]: `Details zur Provider-Organisation eingeben`,
  [`Enter details of this API`]: `Details dieser API eingeben`,
  [`Enter email of the new user`]: `E-Mail-Adresse des neuen Benutzers eingeben`,
  [`Enter first name of the new user`]: `Vornamen des neuen Benutzers eingeben`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Geben Sie eine oder mehrere E-Mail-Adressen ein. Verwenden Sie Kommas, um mehrere Adressen voneinander zu trennen.`,
  [`Enter organization name`]: `Namen der Organisation eingeben`,
  [`Enter organization title`]: `Titel der Organisation eingeben`,
  [`Enter password`]: `Kennwort eingeben`,
  [`Enter space title`]: `Bereichstitel eingeben`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Geben Sie den API-Endpunkt für das Gateway ein, indem Sie den Hostnamen und die Portnummer angeben. Beispiel: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Details der API-Zusammenfassung eingeben`,
  [`Enter the API summary details. [Learn more]({link})`]: `Geben Sie die Details der API-Zusammenfassung ein. [Weitere Informationen]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Geben Sie die URL für den Zielservice ein, der als Proxy fungieren soll`,
  [`Enter the catalog summary details`]: `Geben Sie die Details zur Katalogzusammenfassung ein`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Geben Sie die Details der Katalogzusammenfassung ein. Sie können den Katalog nach seiner Erstellung vollständig konfigurieren.`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Geben Sie die E-Mail-Adresse des Benutzers ein, um ihn als Mitglied der Verwaltungsorganisation einzuladen.`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Geben Sie die E-Mail-Adresse des Benutzers ein, um ihn als Mitglied des Katalogs einzuladen.`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Geben Sie die E-Mail-Adresse des Benutzers ein, um ihn als Mitglied der Provider-Organisation einzuladen.`,
  [`Enter the email address of the user to invite as a member of the space`]: `Geben Sie die E-Mail-Adresse des Benutzers ein, um ihn als Mitglied des Bereichs einzuladen.`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Geben Sie die E-Mail-Adresse des Benutzers ein, an den das Eigentumsrecht übertragen werden soll.`,
  [`Enter the full path to JSON or YAML file`]: `Geben Sie den vollständigen Pfad zur JSON- oder YAML-Datei ein`,
  [`Enter the full path to WSDL file`]: `Geben Sie den vollständigen Pfad zur WSDL-Datei ein`,
  [`Enter the full path to YAML file`]: `Geben Sie den vollständigen Pfad zur YAML-Datei ein`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Geben Sie den vollständig qualifizierten Domänennamen für den Analyseaufnahmeendpunkt ein, den Sie während der Installation definiert haben.`,
  [`Enter the last name of the new user`]: `Nachnamen des neuen Benutzers eingeben`,
  [`Enter the owner's email address to send the invitation`]: `Geben Sie die E-Mail-Adresse des Eigners ein, um die Einladung zu senden`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Geben Sie die Parameter zum Konfigurieren des Gateway-Service ein. Die Felder sind erforderlich, es sei denn, sie sind als optional gekennzeichnet. Der Endpunkt ist der API-Gateway-Service-Endpunkt für DataPower. Die API-Endpunktbasis ist der API-Gateway-Endpunkt für eingehende API-Aufrufe. Geben Sie für SNI * ein, um alle Hosts (erforderlich) zuzulassen, oder auch Hostnamen, um bestimmte Hosts zuzulassen. Wählen Sie das TLS-Profil aus, um die einzelnen Hosts zu unterstützen.`,
  [`Enter the password for the certificate file if required.`]: `Geben Sie bei Bedarf das Kennwort für die Zertifikatsdatei ein.`,
  [`Enter the provider organization summary details`]: `Geben Sie die Zusammenfassungsdetails der Provider-Organisation ein.`,
  [`Enter the schema definitiom summary details`]: `Geben Sie die Zusammenfassungsdetails der Schemadefinition ein.`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Geben Sie die Details der Bereichszusammenfassung ein. Sie können den Bereich nach seiner Erstellung vollständig konfigurieren.`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Geben Sie die Details der Bereichszusammenfassung ein. Sie können den Bereich nach seiner Erstellung vollständig konfigurieren.`,
  [`Enter the summary details for the new product`]: `Geben Sie die Details der Zusammenfassung für das neue Produkt ein.`,
  [`Enter the summary details for this role`]: `Geben Sie die Zusammenfassungsdetails für diese Rolle ein.`,
  [`Enter title of the organization`]: `Titel der Organisation eingeben`,
  [`Enter user email`]: `Benutzer-E-Mail eingeben`,
  [`Enter username of the existing user`]: `Benutzernamen des vorhandenen Benutzers eingeben`,
  [`Enter username of the new user`]: `Benutzernamen des neuen Benutzers eingeben`,
  [`Error`]: `Fehler`,
  [`Error ID`]: `Fehler-ID`,
  [`Error:`]: `Fehler:`,
  [`Copy error ID to Clipboard`]: `Fehler-ID in Zwischenablage kopieren`,
  [`Error Console`]: `Fehlerkonsole`,
  [`Error connecting`]: `Fehler beim Verbinden`,
  [`Error connecting to GraphQL server`]: `Fehler beim Verbinden mit GraphQL-Server`,
  [`Error getting user info during login. Please login with different user.`]: `Fehler beim Abrufen von Benutzerinformationen während der Anmeldung. Melden Sie sich mit einem anderen Benutzer an.`,
  [`Error content`]: `Fehlerinhalt`,
  [`Error message`]: `Fehlernachricht`,
  [`Error name`]: `Fehlername`,
  [`Error status code`]: `Fehlerstatuscode`,
  [`Error status code is invalid`]: `Fehlerstatuscode ist ungültig`,
  [`Error status reason`]: `Fehlerstatusursache`,
  [`Errors`]: `Fehlermeldungen`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Fehler entstehen in der Regel aus falschen Informationen und Bezeichnungen. Stellen Sie vor dem Test sicher, dass Sie die richtigen Provider- und Clientinformationen angeben.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Fehler beim Versuch, die API von der URL {url} abzurufen. Überprüfen Sie die URL, den Benutzernamen und das Kennwort. Fehlernachricht: {message}`,
  [`Event`]: `Ereignis`,
  [`Event gateway management client TLS client profile`]: `Profil für TLS-Client des Ereignisgateway-Management-Clients`,
  [`Event load`]: `Ereignislast`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Jede Konsumentenorganisation hat einen Eigner. Der Eigner verfügt über vollständige Verwaltungsberechtigungen und kann Abonnements und Anwendungen verwalten.`,
  [`Exchange authorization code for tokens`]: `Berechtigungscode für Tokens austauschen`,
  [`Exchange code for token`]: `Code für Token austauschen`,
  [`Execute`]: `Ausführen`,
  [`Execute migration target`]: `Migrationsziel ausführen`,
  [`Executes any catalog-level post-invoke handlers`]: `Führt alle Nachaufruf-Handler auf Katalogebene aus`,
  [`Executes any catalog-level pre-invoke handlers`]: `Führt alle Voraufruf-Handler auf Katalogebene aus`,
  [`Existing`]: `Vorhanden`,
  [`Existing {docType}`]: `Vorhandener {docType}`,
  [`Existing API Connect user`]: `Vorhandener API Connect-Benutzer`,
  [`Existing OpenAPI`]: `Vorhandene OpenAPI`,
  [`Existing product`]: `Vorhandenes Produkt`,
  [`Existing SOAP API has been validated`]: `Vorhandene SOAP-API wurde geprüft.`,
  [`Existing products in the catalog will be moved to a default space.`]: `Vorhandene Produkte im Katalog werden in einen Standardbereich verschoben.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Vorhandene Benutzer sind aktuelle Mitglieder. Sie können auch ein neues Mitglied aus einer anderen Benutzerregistry aktivieren.`,
  [`Expires On`]: `Läuft ab am`,
  [`Expiry`]: `Ablauf`,
  [`Explorer`]: `Explorer`,
  [`Expose`]: `Verfügbar machen`,
  [`Extend the schema to specify the cost of types and fields`]: `Schema erweitern, um Kosten von Typen und Feldern anzugeben`,
  [`Extended`]: `Erweitert`,
  [`Extension Type:`]: `Erweiterungstyp:`,
  [`Extensions`]: `Erweiterungen`,
  [`Extension has been uploaded.`]: `Die Erweiterung wurde hochgeladen.`,
  [`External`]: `Extern`,
  [`External Doc Description`]: `Beschreibung des externen Dokuments`,
  [`External doc description`]: `Beschreibung des externen Dokuments`,
  [`External Documentation URL`]: `URL der externen Dokumentation`,
  [`External documentation URL`]: `URL der externen Dokumentation`,
  [`External Role mapping`]: `Zuordnung von externen Rollen`,
  [`External URL`]: `Externe URL`,
  [`Extract Identity Settings`]: `Identitätseinstellungen extrahieren`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `Faktor-ID`,
  [`Fail`]: `Fehlgeschlagen`,
  [`Failed to change account password.`]: `Fehler beim Ändern des Accountkennworts.`,
  [`Failed to create analytics service {title}.`]: `Fehler beim Erstellen von Analyseservice {title}.`,
  [`Failed to create analytics insights service {title}.`]: `Fehler beim Erstellen des Analytics Insights-Service {title}.`,
  [`Analytics insights service {title} has been updated.`]: `Der Analytics Insights-Service {title} wurde aktualisiert.`,
  [`About the analytics insights service`]: `Informationen zum Analytics Insights-Service`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Der Analyseerkennungs-Service erfasst API-Ereignisse aus dem Gateway-Service. Jeder Gateway-Service kann über einen zugehörigen Analytics Insights-Service verfügen.`,
  [`Advanced analytics insights configuration`]: `Erweiterte Analytics Insights-Konfiguration`,
  [`Analytics insights service {title} has been created.`]: `Analytics Insights-Service {title} wurde erstellt.`,
  [`Analytics Insights details`]: `Details für Analytics Insights`,
  [`Management endpoint on the analytics insights service`]: `Managementendpunkt auf dem Analytics Insights-Service`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Geben Sie den vollständig qualifizierten Domänennamen für den Analytics Insights-Director-Endpunkt ein, den Sie während der Installation definiert haben.`,
  [`TYPE`]: `TYP`,
  [`Failed to create email server`]: `Fehler beim Erstellen des E-Mail-Servers`,
  [`Failed to create gateway service`]: `Erstellen des Gateway-Service fehlgeschlagen`,
  [`Failed to create gateway service {name}.`]: `Fehler beim Erstellen des Gateway-Service {name}.`,
  [`Failed to create user registry {title}.`]: `Benutzerregistry {title} konnte nicht erstellt werden.`,
  [`Failed to delete a group`]: `Das Löschen einer Gruppe ist fehlgeschlagen.`,
  [`Failed to get TLS client profiles`]: `Fehler beim Abrufen von TLS-Clientprofilen`,
  [`Failed to get catalogs`]: `Fehler beim Abrufen von Katalogen`,
  [`Failed to get configured OAuth providers`]: `Fehler beim Abrufen der konfigurierten OAuth-Provider`,
  [`Failed to get policies of gateways`]: `Fehler beim Abrufen von Richtlinien von Gateways`,
  [`Failed to get user registries`]: `Fehler beim Abrufen von Benutzerregistrys`,
  [`Failed to introspect schema from server:`]: `Introspektion des Schemas vom Server ist fehlgeschlagen:`,
  [`Failed to make API online`]: `API konnte nicht online gemacht werden`,
  [`Failed to register catalog member {user}.`]: `Registrierung des Katalogmitglieds {user} ist fehlgeschlagen.`,
  [`Failed to remove analytics service: {arg}`]: `Folgender Analyseservice konnte nicht entfernt werden: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Folgende Verfügbarkeitszone konnte nicht entfernt werden: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Folgender Gateway-Service konnte nicht entfernt werden: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Folgender Mail-Server konnte nicht entfernt werden: {arg}`,
  [`Failed to remove portal service: {arg}`]: `Folgender Portal-Service konnte nicht entfernt werden: {arg}`,
  [`Failed to create {name}.`]: `Fehler beim Erstellen von {name}.`,
  [`Failed to sign out! Please check the console for details.`]: `Fehler beim Abmelden! Überprüfen Sie die Konsole auf Details.`,
  [`Failed to update {name}.`]: `Fehler beim Aktualisieren von {name}.`,
  [`Failed to update OAuth provider`]: `Fehler beim Aktualisieren des OAuth-Providers`,
  [`Failed to update account.`]: `Fehler beim Aktualisieren des Accounts.`,
  [`Failed to update analytics service {title}.`]: `Fehler beim Aktualisieren von Analyseservice {title}.`,
  [`Failed to update gateway service {title}.`]: `Fehler beim Aktualisieren des Gateway-Service {title}.`,
  [`Failed to update task`]: `Task konnte nicht aktualisiert werden`,
  [`Failed to create app {title}`]: `Erstellen der App {title} fehlgeschlagen`,
  [`Feature flags`]: `Funktionsflags`,
  [`Fetch schema`]: `Schema abrufen`,
  [`Fetched endpoints`]: `Abgerufene Endpunkte`,
  [`Field`]: `Feld`,
  [`Field label`]: `Feldbezeichnung`,
  [`Field Mapping`]: `Feldzuordnung`,
  [`Field name`]: `Feldname`,
  [`Field weight`]: `Feldgewicht`,
  [`File Name`]: `Dateiname`,
  [`File Preview`]: `Dateivorschau`,
  [`File contains validation errors`]: `Datei enthält Gültigkeitsfehler`,
  [`File is not a valid OpenAPI definition`]: `Datei hat keine gültige OpenAPI-Definition`,
  [`File is not a valid extension format.`]: `Die Datei hat kein gültiges Erweiterungsformat.`,
  [`File is not a valid {fileType} format.`]: `Die Datei hat kein gültiges {fileType}-Format.`,
  [`File name`]: `Dateiname`,
  [`File upload`]: `Dateiupload`,
  [`File uploaded is not a valid file`]: `Bei der hochgeladenen Datei handelt es sich nicht um eine gültige Datei.`,
  [`File uploaded successfully`]: `Datei erfolgreich hochgeladen`,
  [`File was updated and validated!`]: `Datei wurde aktualisiert und validiert!`,
  [`File was uploaded and validated!`]: `Datei wurde hochgeladen und validiert!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `Die Datei {draftFilePath} ist bereits vorhanden. Versuchen Sie, die Kombination aus Name und Version des Entwurfs zu ändern`,
  [`Fill out the details of this service`]: `Geben Sie die Details zu diesem Service ein.`,
  [`Filter`]: `Filter`,
  [`Filter by operation`]: `Filtern nach Operation`,
  [`Filter policies`]: `Filterrichtlinien`,
  [`Final Action`]: `Endaktion`,
  [`Find existing user`]: `Vorhandenen Benutzer suchen`,
  [`Find expert answers in the API Connect community forum`]: `Expertenantworten im API Connect-Community-Forum suchen`,
  [`Find expert answers in the API Connect community forum.`]: `Suchen Sie im API Connect-Community-Forum nach Expertenantworten.`,
  [`Find schema`]: `Schema suchen`,
  [`Finger Print`]: `Fingerabdruck`,
  [`Fingerprint SHA-1`]: `Fingerabdruck SHA-1`,
  [`Fingerprint SHA-256`]: `Fingerabdruck SHA-256`,
  [`Finish`]: `Fertigstellen`,
  [`First name`]: `Vorname`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Geben Sie zunächst die URL des Endpunkts ein, den das Gateway für den API-Datenverkehr verwendet. Der Wert muss ein öffentlicher, vollständig qualifizierter Domänenname mit zusätzlichen Pfaden sein, die für Ihre API-Aufrufe spezifisch sind, z. B.: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Geben Sie zunächst die URL des Gateway-Managementendpunkts an. Bei Gateways, die in Kubernetes-Umgebungen ausgeführt werden, muss der Wert dem Endpunkt entsprechen, der über den Gatewayeinrichtungs-Befehl 'set gwy apic-gw-service' angegeben wurde. Bei Gateway-Appliances muss der Wert das folgende Format aufweisen: http://<ip-address-for-gateway>:3000. Wenn Sie mit einem Gateway-Cluster arbeiten, geben Sie 'address:port' für die Lastausgleichsfunktion an.`,
  [`Fit to screen`]: `In Ansicht einpassen`,
  [`Flow`]: `Ablauf`,
  [`Follow redirects`]: `Weiterleitungen folgen`,
  [`Found In`]: `Gefunden in`,
  [`Fonts contained in the following file are available under the`]: `Die in den folgenden Dateien enthaltenen Schriftarten sind verfügbar unter`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `Laden Sie für WSDL-Definitionen eine ZIP-Datei hoch, die sowohl die WSDL-Datei als auch die API-Definition (YAML) enthält.`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Wählen Sie für jeden Plan im ursprünglichen Produkt den Plan in dem Ersatzprodukt aus, in das die Abonnements migriert werden sollen.`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Wählen Sie für jeden Plan im Quellenprodukt den Plan in dem Zielprodukt aus, in das die Abonnements migriert werden sollen.`,
  [`For guidance on the available command set, access the CLI's help`]: `Informationen zum verfügbaren Befehlssatz finden Sie in der Hilfe zur Befehlszeilenschnittstelle`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Geben Sie bei Sites, die native OAuth-Provider verwenden, optional den geheimen Schlüssel für die gemeinsame Nutzung an, der von allen API-Aufrufen verwendet wird.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Wählen Sie für beschreibbares LDAP 'Benutzerverwaltet' aus und fügen Sie die Zuordnung Ihrer Quellen-LDAP-Registry-Attributnamen zu den LDAP-Attributwerten für API Connect hinzu. Die Standardeigenschaften des Benutzerprofils, die für API Connect bei der Benutzerregistrierung erforderlich sind, sind username (Benutzername), first_name (Vorname), last_name (Nachname), email (E-Mail) und password (Kennwort). Eine Beispielzuordnungskonfiguration wird bereitgestellt. Bearbeiten Sie die Zuordnung so, dass sie Ihren Anforderungen entspricht.`,
  [`Forbidden`]: `Verboten`,
  [`Forget token`]: `Token übergehen`,
  [`Forgot password?`]: `Kennwort vergessen?`,
  [`Forgot password`]: `Kennwort vergessen`,
  [`Form`]: `Formular`,
  [`Forum`]: `Forum`,
  [`French`]: `Französisch`,
  [`From File`]: `Aus Datei`,
  [`From Sample JSON`]: `Aus Beispiel-JSON`,
  [`From Sample XML`]: `Aus Beispiel-XML`,
  [`From URL`]: `Aus URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Vom vorhandenen GraphQL-Service (GraphQL-Proxy)`,
  [`From existing OpenAPI service`]: `Vom vorhandenen OpenAPI-Service`,
  [`From existing WSDL service (REST proxy)`]: `Vom vorhandenen WSDL-Service (REST-Proxy)`,
  [`From existing WSDL service (SOAP proxy)`]: `Vom vorhandenen WSDL-Service (SOAP-Proxy)`,
  [`From target service`]: `Vom Zielservice`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `Wählen Sie aus den Operationen, die in der WSDL-Definition für den SOAP-Service definiert sind, die Operationen aus, die Sie in Ihrer API verwenden möchten, und geben Sie die Methoden und URL-Pfade an.`,
  [`Gateway Extensions`]: `Gateway-Erweiterungen`,
  [`Gateway extension`]: `Gateway-Erweiterung`,
  [`Gateway Services`]: `Gateway-Services`,
  [`Gateway Type`]: `Gateway-Typ`,
  [`Gateway by Name`]: `Gateway nach Namen`,
  [`Gateway services`]: `Gateway-Services`,
  [`Gateway type`]: `Gateway-Typ`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL v2-Lizenz`,
  [`GROUP`]: `GRUPPE`,
  [`Group name`]: `Gruppenname`,
  [`Gateway`]: `Gateway`,
  [`Gateways`]: `Gateways`,
  [`Gateway details`]: `Gateway-Details`,
  [`Gateway Details`]: `Gateway-Details`,
  [`Gateway Error`]: `Gateway-Fehler`,
  [`Gateway service details`]: `Details des Gateway-Service`,
  [`Gateway management client TLS client profile`]: `TLS-Clientprofil für Gateway-Management-Client`,
  [`Gateway management client keystore`]: `Keystore des Gateway-Management-Clients`,
  [`Gateway management client truststore`]: `Truststore des Gateway-Management-Clients`,
  [`Gateway processing status`]: `Gateway-Verarbeitungsstatus`,
  [`Gateway policies (TODO)`]: `Gateway-Richtlinien (TODO)`,
  [`Gateway service`]: `Gateway-Service`,
  [`Gateway Service US East (TODO)`]: `Gateway-Service US East (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Gateway-Service {arg} wurde entfernt.`,
  [`Gateway service configuration`]: `Gateway-Service-Konfiguration`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `Der Gateway-Service-Host ist nicht verfügbar. Der API-Test (Try it) im Explorer ist nicht aktiviert.`,
  [`Gateway service configuration has been updated.`]: `Gateway-Servicekonfiguration wurde aktualisiert.`,
  [`Gateway service {title} has been created.`]: `Gateway-Service {title} wurde erstellt.`,
  [`Gateway service {title} has been updated.`]: `Gateway-Service {title} wurde aktualisiert.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Gateway-Services werden in jedem Bereich verwaltet, wenn Bereiche aktiviert sind. Rufen Sie die Bereichseinstellungen auf, um Gateway-Services zu verwalten.`,
  [`Gateway version`]: `Gateway-Version`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Gateways pro Seite`,
  [`Gather-and-send`]: `Zusammenstellen und senden`,
  [`Gather-only`]: `Nur zusammenstellen`,
  [`General configuration`]: `Allgemeine Konfiguration`,
  [`General information about the API.`]: `Allgemeine Informationen zur API.`,
  [`Generate`]: `Generieren`,
  [`Generate an OpenAPI definition for the proxy`]: `OpenAPI-Definition für den Proxy generieren`,
  [`Generate JSON Web Token (JWT)`]: `JSON-Web-Token (JWT) generieren`,
  [`Generate JWT`]: `JWT generieren`,
  [`Generate LTPA Token`]: `LTPA-Token generieren`,
  [`Generate OAuth Access Tokens`]: `OAuth-Zugriffstoken generieren`,
  [`Generate OAuth access tokens`]: `OAuth-Zugriffstoken generieren`,
  [`Generate a default product`]: `Standardprodukt generieren`,
  [`Generate access token`]: `Zugriffstoken generieren`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Generieren Sie ein LTPA-Token, sodass Ihre API sicher bei WebSphere Application Server-Web-Servern authentifiziert werden kann.`,
  [`Generate an object`]: `Ein Objekt generieren`,
  [`Generate authorization code`]: `Autorisierungscode generieren`,
  [`Generate from sample JSON`]: `Aus Beispiel-JSON generieren`,
  [`Generate from sample XML`]: `Aus Beispiel-XML generieren`,
  [`Generate tests automatically to build high quality APIs`]: `Tests automatisch generieren, um hochwertige APIs zu erstellen`,
  [`Generated OpenAPI 2.0 definition`]: `Generierte OpenAPI 2.0-Definition`,
  [`Generated default product for the API`]: `Generiertes Standardprodukt für die API`,
  [`Generated name for use in commands and API calls`]: `Generierter Name für die Verwendung in Befehlen und API-Aufrufen`,
  [`German`]: `Deutsch`,
  [`Get Base DN`]: `Basis-DN abrufen`,
  [`Get Code`]: `Code abrufen`,
  [`Get Code Snippet`]: `Codeausschnitt abrufen`,
  [`Get Support`]: `Unterstützung erhalten`,
  [`Get Token`]: `Get-Token`,
  [`Get activation link`]: `Aktivierungslink abrufen`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Beginnen Sie mit der Registrierung eines Gateway-Service. Dies ermöglicht Ihnen das Testen von Endpunkten beim Konfigurieren anderer Services. Nach dem Registrieren eines Service wird er hier angezeigt. [Weitere Informationen]({link})`,
  [`Get subscription ID`]: `Abonnement-ID abrufen`,
  [`Getting Started`]: `Einführung`,
  [`Getting started`]: `Erste Schritte`,
  [`Git information`]: `Git-Informationen`,
  [`Github`]: `Github`,
  [`Global policies`]: `Globale Richtlinien`,
  [`Go to Spaces`]: `Bereiche aufrufen`,
  [`Go to the Test tab`]: `Rufen Sie die Registerkarte 'Testen' auf.`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL-Ratenbegrenzungen`,
  [`GraphQL service`]: `GraphQL-Service`,
  [`GraphQL schema`]: `GraphQL-Schema`,
  [`GraphQL Schema`]: `GraphQL-Schema`,
  [`GraphQL schema has been successfully validated.`]: `GraphQL-Schema wurde erfolgreich validiert.`,
  [`GraphQL Schema URL`]: `GraphQL-Schema-URL`,
  [`GraphQL send type`]: `GraphQL-Sendeart`,
  [`GraphQL Websocket Upgrade`]: `GraphQL-Websocket-Upgrade`,
  [`Group Based DN`]: `Gruppenbasierter DN`,
  [`Group {title} added`]: `Gruppe {title} hinzugefügt`,
  [`Group {title} updated`]: `Gruppe {title} aktualisiert`,
  [`Group {title} has been deleted`]: `Gruppe {title} wurde gelöscht`,
  [`Groups`]: `Gruppen`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `HOSTNAME`,
  [`HOST URL`]: `HOST-URL`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML-Formular`,
  [`HTML form time limit`]: `Zeitlimit mit HTML-Formular`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 erforderlich`,
  [`HTTP Header`]: `HTTP-Header`,
  [`HTTP Method`]: `HTTP-Methode`,
  [`HTTP Type`]: `HTTP-Typ`,
  [`HTTP Version`]: `HTTP-Version`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Ziehleisten`,
  [`Hard limit`]: `Fester Grenzwert`,
  [`Header`]: `Header`,
  [`Header control`]: `Headersteuerung`,
  [`Header name payload`]: `Headername - Nutzdaten`,
  [`Header name token`]: `Headername - Token`,
  [`Headers`]: `Header`,
  [`Hello`]: `Hallo`,
  [`Hello!`]: `Hallo!`,
  [`Hello [IBM](url)`]: `Hallo [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Hallo {name}`,
  [`Hello {name}!`]: `Hallo {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Hallo {name}, [siehe IBM Profil](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\ndanke für Ihre Registrierung für einen Account auf dem {catalogTitle}-Developer Portal.\n\nVerwenden Sie diesen Link, um Ihre Registrierung abzuschließen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nder Administrator für das {consumerOrg}-Developer Portal hat die App \n{appName} wiedereingesetzt. API-Aufrufe von dieser App werden nicht mehr vom\nAPI-Gateway blockiert.\n\nSie können die App-Details über diesen Link anzeigen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nder Administrator für das {consumerOrg}-Developer Portal hat die App \n{appName} zurückgesetzt. API-Aufrufe von dieser App werden vom API-Gateway blockiert.\n\nSie können die App-Details über diesen Link anzeigen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nder Benutzer {originator} hat die Genehmigung zum Abonnieren des {planName}-Plans\nfür Version {productVersion} des {productName}-API-Produkts im {catalog}\n-Katalog angefordert.\n\nSie können die Anforderung in Ihrer Taskliste über den folgenden Link öffnen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nBenutzer {originator} hat eine Genehmigung zum Abonnieren mit der Anwendung {appName}\ndes Plans {planName} für Version {productVersion} des API-Produkts {productName} \nim Katalog {catalog} angefordert.\n\nSie können die Anforderung in Ihrer Taskliste über den folgenden Link öffnen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nder Benutzer {originator} hat die Genehmigung zum {action} der App {appName} im\n{catalog}-Katalog angefordert.\n\nSie können die Anforderung in Ihrer Taskliste über den folgenden Link öffnen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nBenutzer {originator} hat eine Genehmigung für {action} Version {version}\ndes API-Produkts {productName} im Katalog {catalog} angefordert.\n\nSie können die Anforderung in Ihrer Taskliste über den folgenden Link öffnen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hallo {{firstName}},\n\nwir haben eine Anforderung zum Zurücksetzen des Kennworts für Ihren Benutzeraccount\n{{username}} im {{catalog}}Developer Portal erhalten.\n\nWenn Sie die Anforderung nicht gesendet haben, können Sie diese Nachricht ignorieren. Andernfalls können Sie das Kennwort\nüber diesen Link zurücksetzen:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Hallo {firstName},\n\nwir haben eine Anforderung zum Zurücksetzen des Kennworts für Ihren Benutzeraccount\n{username} in IBM API Connect erhalten.\n\nVerwenden Sie diesen Link, um Ihr Kennwort zurückzusetzen:\n\n{link}\n\n(Wenn Sie die Anforderung nicht gesendet haben, können Sie diese Nachricht ignorieren.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nwir haben eine Anforderung zum Zurücksetzen des Kennworts für Ihren Benutzeraccount\n{username} im {catalog}-Developer Portal erhalten.\n\nWenn Sie die Anforderung nicht gesendet haben, können Sie diese Nachricht ignorieren. Andernfalls können Sie das Kennwort\nüber diesen Link zurücksetzen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hallo {firstName},\n\nfür Ihre Anforderung zum Erstellen eines Abonnements für die {appName}-App im\n{catalog}-Katalog steht die Genehmigung an. Wir werden Ihnen eine weitere Benachrichtigung senden,\nwenn Ihre Anforderung bearbeitet wurde.\n\nSie können den Status der App hier anzeigen:\n\n{link}\n\nWenn Sie die Anforderung zu einem beliebigen Zeitpunkt abbrechen möchten, verwenden Sie diesen Link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {{firstName}},\n\nIhre Anforderung zum Erstellen eines Abonements für die App {{appName}} für den Plan \n{{planName}} für das API-Produkt {{productName}} Version\n{{productVersion}} wurde genehmigt.\n\n{{#if comments}}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n"{{comments}}"\n\n{{/if}}\nSie können den Status der App hier überprüfen: \n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum Erstellen eines Abonnements für die {appName}-App für den\n{planName}-Plan für die Version des API-Produkts {productName}\n{productVersion} wurde genehmigt.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{comments}\n\n{/if}\nSie können den Status der App hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {{firstName}},\n\nIhre Anforderung zum Erstellen eines Abonements für die App {{appName}} für den Plan \n{{planName}} für das API-Produkt {{productName}} Version\n{{productVersion}} wurde abgelehnt.\n\n{{#if comments}}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n"{{comments}}"\n\n{{/if}}\nSie können den Status der App hier überprüfen: \n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {{firstName}},\n\nIhre Anforderung zum {{action}} des API-Produkts {{productName}}:{{version}} im\n{{catalog}}-Katalog wurde genehmigt.\n\n{{#if comments}}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{{comments}}\n\n{{/if}}\nSie können den Status des Produkts hier überprüfen:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum {action} des API-Produkts {productName}:{version} im\n{catalog}-Katalog wurde genehmigt.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{comments}\n\n{/if}\nSie können den Status des Produkts hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {firstName}},\n\nIhre Anforderung zum {action} des API-Produkts {productName}:{version} im\n{catalog}-Katalog wurde verweigert.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{comments}\n\n{/if}\nSie können den Status des Produkts hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum {action} des API-Produkts {productName}:{version} im\n{catalog}-Katalog wurde verweigert.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{comments}\n\n{/if}\nSie können den Status des Produkts hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hallo {firstName},\n\nfür Ihre Anforderung zum {action} des API-Produkts {productName}:{version} im\n{catalog}-Katalog steht die Genehmigung an. Wir werden Ihnen eine weitere Benachrichtigung senden,\nwenn Ihre Anforderung bearbeitet wurde.\n\nSie können den Status des Produkts hier anzeigen:\n\n{link}\n\nWenn Sie die Anforderung zu einem beliebigen Zeitpunkt abbrechen möchten, verwenden Sie diesen Link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum {action} der App {appName} im\n{consumerOrg}-Developer Portal wurde genehmigt.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n"{comments}"\n\n{/if}\nSie können den Status der App hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum {action} der App {appName} im\n{consumerOrg}-Developer Portal wurde genehmigt.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{comments}\n\n{/if}\nSie können den Status der App hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum {action} der App {appName} im\n{consumerOrg}-Developer Portal wurde verweigert.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n"{comments}"\n\n{/if}\nSie können den Status der App hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum {action} der App {appName} im\n{consumerOrg}-Developer Portal wurde verweigert.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{comments}\n\n{/if}\nSie können den Status der App hier überprüfen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nfür Ihre Anforderung zum {action} der App {appName} im {consumerOrg}\n-Developer Portal steht die Genehmigung an. Wir werden Ihnen eine weitere Benachrichtigung senden,\nwenn Ihre Anforderung bearbeitet wurde.\n\nWenn Sie die Anforderung zu einem beliebigen Zeitpunkt abbrechen möchten, verwenden Sie diesen Link:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Account-Administrator für IBM API Connect hat Sie eingeladen, eine\nAPI-Provider-Organisation zu erstellen. Eine Provider-Organisation ermöglicht es Ihnen und Ihrem\nTeam, APIs zu entwickeln und Kataloge mit API-Produkten zu verwalten.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Administrator für den {catalog}-API-Katalog, der der \n{org}-API-Provider-Organisation in IBM API Connect angehört, hat Sie zur Erstellung\neines Bereichs im Katalog eingeladen. Ein Bereich ermöglicht Ihnen und Ihrem Team das Verwalten\neiner Reihe von API-Produkten in einem API-Katalog.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Administrator für den {catalog}-API-Katalog, der der \n{org}-API-Provider-Organisation in IBM API Connect angehört, hat Sie \nzum Katalog eingeladen.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Administrator für das {catalog}-Developer Portal hat Sie\nzur Erstellung einer API-Konsumentenorganisation eingeladen. Eine Konsumentenorganisation\nermöglicht Ihnen und Ihrem Team den Zugriff auf APIs und das Registrieren von Client-Apps.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Administrator für die {consumerOrg}-API-Konsumentenorganisation im {catalog}\n-Developer Portal hat Sie in die Organisation eingeladen.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Administrator für die {org}-API-Provider-Organisation in IBM API\nConnect hat Sie zum Erstellen eines API-Katalogs eingeladen. Ein Katalog ermöglicht Ihnen\nund Ihrem Team das Veröffentlichen und Verwalten einer Reihe von API-Produkten.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Administrator für die {org}-API-Provider-Organisation in IBM API\nConnect hat Sie in die Organisation eingeladen.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Administrator für den {space}-Bereich im {catalog}-API-Katalog,\nder der {org}-API-Provider-Organisation in IBM API Connect angehört,\nhat Sie in den Bereich eingeladen.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Hallo,\n\ndas Kennwort für Ihren Benutzeraccount {username}\nin IBM API Connect wurde erfolgreich geändert.\n\nSie können sich hier bei API Connect anmelden:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Hallo,\n\ndas Kennwort für Ihren Benutzeraccount {username}\nim {portalTitle}-Developer Portal wurde geändert.\n\nSie können sich hier beim {portalTitle}-Developer Portal anmelden:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nder Systemadministrator für IBM API Connect hat Sie in die \n{org}-Organisation eingeladen.\n\nVerwenden Sie den folgenden Link, um fortzufahren:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Hallo,\n\ndies ist eine Testnachricht von IBM API Connect vom konfigurierten E-Mail-Server\n{mailServer}.\n\nWenn Sie diese Nachricht wie erwartet erhalten haben, war der Test erfolgreich!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo,\n\nder Benutzer {originator} hat seine Anforderung zum Abonnieren des {planName}-Plans für\nVersion {productVersion} des {productName}-API-Produkts im {catalog}-Katalog zurückgezogen.\n\nEs sind keine weiteren Maßnahmen erforderlich.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo,\n\nder Benutzer {originator} hat seine Anforderung zum {action} der App {appName} im {catalog}-Katalog zurückgezogen.\n\nEs sind keine weiteren Maßnahmen erforderlich.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo,\n\nder Benutzer {originator} hat seine Anforderung zum {action} von Version {version} des {productName}\n-API-Produkts im {catalog}-Katalog zurückgezogen.\n\nEs sind keine weiteren Maßnahmen erforderlich.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Hallo, dies ist eine Testnachricht von IBM API Connect vom konfigurierten E-Mail-Server {{{mailServer}}}. Wenn Sie diese Nachricht wie erwartet erhalten haben, war der Test erfolgreich`,
  [`Hello|header`]: `Hallo`,
  [`Help`]: `Hilfe`,
  [`Hi, Steve (todo)`]: `Hallo Steve (todo)`,
  [`Hide`]: `Ausblenden`,
  [`Hide field`]: `Feld ausblenden`,
  [`Hide in schema`]: `Im Schema ausblenden`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Neue Typen, Felder, Anweisungen oder optionale Argumente ausblenden. Elemente, die nicht mehr vom Back-End unterstützt werden, werden entfernt.`,
  [`High`]: `Hoch`,
  [`History`]: `Protokoll`,
  [`Histories`]: `Verläufe`,
  [`History log`]: `Verlaufsprotokoll`,
  [`Home`]: `Home`,
  [`Host`]: `Host`,
  [`Host context variable`]: `Kontextvariable für Host`,
  [`Host name`]: `Hostname`,
  [`Host value (if present)`]: `Hostwert (falls vorhanden)`,
  [`Hostname`]: `Hostname`,
  [`Hours`]: `Stunden`,
  [`Http basic authentication schema`]: `HTTP-Schema für die Basisauthentifizierung`,
  [`Hmm, your search didn't return any results.`]: `Hmm, Ihre Suche hat keine Ergebnisse geliefert.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect-Cloud`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud-Authentifizierung`,
  [`IBM Cloud Docs`]: `IBM Cloud Docs`,
  [`IBM Schema`]: `IBM Schema`,
  [`IBM managed`]: `Von IBM verwaltet`,
  [`ID Name`]: `ID-Name`,
  [`ID token custom claims`]: `Angepasste Anforderungen für ID-Token`,
  [`ID token issuer`]: `ID-Token-Aussteller`,
  [`ID token signing algorithm`]: `ID-Token-Signaturalgorithmus`,
  [`ID token signing crypto object`]: `Verschlüsselungs-Objekt für ID-Token-Signatur`,
  [`ID token signing key`]: `Signierschlüssel für ID-Token`,
  [`ID token signing key identifier`]: `Signierschlüssel-ID für ID-Token`,
  [`IDENTITY PROVIDER`]: `IDENTITÄTSPROVIDER`,
  [`IDENTITY PROVIDER SUPPORT`]: `UNTERSTÜTZUNG FÜR IDENTITÄTSPROVIDER`,
  [`Identification`]: `Identifikation`,
  [`Identity extraction`]: `Identitätextraktion`,
  [`If`]: `Wenn`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Wenn der Produktionsmodus inaktiviert ist und es sich also um einen Entwicklungskatalog handelt, werden alle Veröffentlichungsoperationen erzwungen und alle Konflikte automatisch gelöst, wodurch Sie beispielsweise dieselbe Produktversion während des Testens wiederholt neu veröffentlichen können.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Wenn der Produktionsmodus aktiviert ist und es sich also um einen Produktionskatalog handelt, wird verhindert, dass Sie ein Produkt im Katalog veröffentlichen, solange Konflikte mit einem beliebigen bereits veröffentlichten Produkt bestehen. Sie müssen bestehende Konflikte lösen, bevor Sie die Veröffentlichungsoperation erneut ausführen.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Wenn Bereiche in einem Katalog aktiviert sind, können Sie die Mitglieder im Bereich verwalten. Sie können die Bereichsmitgliedschaft verwalten, indem Sie dem Bereich neue Benutzer hinzufügen und den Benutzern Rollen zuweisen.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Ist diese Option aktiviert, werden Eingabeeigenschaften mit einem Nullwert dem Ausgabedokument zugeordnet. Andernfalls werden diese Eigenschaften aus der Ausgabe ausgelassen.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Wenn der Produktionsmodus aktiviert ist, werden keine Bereitstellungs- und Veröffentlichungsaktionen erzwungen. Wenn Konflikte erkannt werden, werden sie automatisch vom System aufgelöst. Aktionen zum Rückgängigmachen von Veröffentlichungen erfolgen automatisch.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Wenn die Empfänger die E-Mail nicht empfangen haben, überprüfen Sie die E-Mail-Servereinstellungen.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Wenn die Empfänger die E-Mail nicht empfangen haben, überprüfen Sie die E-Mail-Servereinstellungen für {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Wenn der Wert auf 'wahr' (true) gesetzt ist, werden alle +-Zeichen bei den Abfrageparameterwerten der Ziel-URL der Aufruf- und Proxy-Richtlinien mit %2B codiert.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Wenn der Wert auf 'wahr' (true) gesetzt ist, werden alle Anforderungsparameter, auf die durch eine Variablendefinition in einer Aufruf-Ziel-URL verwiesen wird, URL-decodiert.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Falls der Wert auf 'wahr' (true) gesetzt ist, sendet die Aufrufrichtlinie Nutzdaten über eine HTTP DELETE-Methode.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Falls auf 'wahr' (true) gesetzt, wird die Eigenschaft neu bearbeitet oder von Aktivitätenprotokollen entfernt.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Falls auf 'wahr' (true) gesetzt, wird die Eigenschaft anhand der Eingabe zu Beginn der Ausführung neu bearbeitet oder entfernt.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Falls auf 'wahr' (true) gesetzt, wird die Eigenschaft neu bearbeitet oder von der Antwort entfernt.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Wenn Bereiche in einem Katalog aktiviert sind, können Sie den Zugriff für Benutzer im Bereich verwalten. Sie verwalten den Zugriff, indem Sie die Berechtigungen angeben, die Benutzerrollen zugewiesen sind. Die Berechtigungen, die jeder Rolle standardmäßig zugewiesen werden, wenn Sie einen neuen Bereich erstellen, werden durch die Einstellungen in der Standardvorlage für Bereichsberechtigungen festgelegt.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Wenn das Kontrollkästchen ausgewählt ist (die Standardoption), werden XML-Namensbereiche vom übergeordneten Element übernommen. Heben Sie die Auswahl des Kontrollkästchens auf, wenn die map-Richtlinie explizite Namensbereiche verwenden soll.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Wenn das Kontrollkästchen ausgewählt ist (die Standardoption), werden XML-Namensbereiche in das Dokument eingefügt, in dem Sie zuerst verwendet werden. Heben Sie die Auswahl dieses Kontrollkästchens auf, wenn alle Namensbereiche im Stammelement definiert werden sollen.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Wenn das Kontrollkästchen ausgewählt ist (die Standardoption), werden leere XML-Elemente in die Ausgabe der map-Richtlinie einbezogen. Heben Sie die Auswahl des Kontrollkästchens auf, wenn leere XML-Elemente nicht in die Ausgabe der map-Richtlinie einbezogen werden sollen.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Wenn das Kontrollkästchen aktiviert ist, ist die Nachbearbeitung der abgebildeten JSON-Ausgabe aktiviert. Bei der Nachbearbeitung der JSON-Ausgabe wird das Ausgabeschema verwendet, um sicherzustellen, dass die Eigenschaftswerte den gleichen Datentyp aufweisen wie im Schema definiert. Sie normalisiert auch Ausgabeeigenschaftswerte, die aufgrund der Objektzuordnung eines XML-Eingangs eine Badgerfish-JSON-Syntax aufweisen. Wählen Sie dieses Kontrollkästchen ab (Standardeinstellung), damit keine Nachverarbeitung der zugeordneten JSON-Ausgabe erfolgt.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Wenn das Kontrollkästchen ausgewählt ist (die Standardoption), werden API Connect-Variablenreferenzen, die in den map-Eigenschaften gefunden werden, aufgelöst. Heben Sie die Auswahl dieses Kontrollkästchens auf, wenn die map-Richtlinie API Connect-Variablenreferenzen in den map-Richtlinien ignorieren soll.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Wenn das Kontrollkästchen ausgewählt ist, werden API Connect-Variablenreferenzen, die in den map-Eigenschaften gefunden werden, aufgelöst. Heben Sie die Auswahl dieses Kontrollkästchens (Standardoption) auf, wenn die map-Richtlinie API Connect-Variablenreferenzen in den map-Richtlinien ignorieren soll.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Wenn das Kontrollkästchen ausgewählt ist (die Standardoption), wenn ein Array in der Traversierung der Eingabe gefunden wird, wird nur das erste Element verwendet. Heben Sie die Auswahl dieses Kontrollkästchens auf, wenn die map-Richtlinie alle Arrayelemente verwenden soll.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Wenn das Kontrollkästchen ausgewählt ist, werden XML-Elemente, deren Schema als boolescher oder numerischer Typ konfiguriert wurde, in diesen Datentyp konvertiert. Ist es nicht ausgewählt, (Standardoption), werden alle Werte für XML-Elemente als Zeichenfolge zurückgegeben.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Wenn das Kontrollkästchen ausgewählt ist, werden bei der Auswertung komplexer Schematypen zirkuläre Typenreferenzen auf optimierte Weise behandelt. Heben Sie die Auswahl dieses Kontrollkästchens auf (die Standardoption), wenn diese Schematypen auf normale Weise behandelt werden sollen.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Wenn der Fehler durch fehlende CORS-Unterstützung verursacht wird, klicken Sie auf den Link, um den Server in einer neuen Registerkarte zu öffnen. Wenn im Browser ein Zertifikatsproblem angezeigt wird, können Sie das Zertifikat akzeptieren und hierher zurückkehren, um den Test zu wiederholen.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Wenn dieses Kontrollkästchen aktiviert ist, ist für jedes Ausgabe-Array oder jede untergeordnete Eigenschaft eines optionalen Objekts, das bzw. die im Schema als erforderlich vermerkt ist, anfangs ein Standardwert für die erforderlichen untergeordneten Objekteigenschaften eingestellt, sofern der Wert nicht erfolgreich zugeordnet wurde. Dadurch wird emuliert, wie die Version-4-Zuordnung für die erforderlichen Eigenschaften für diese Objekte durchgeführt wurde. Inaktivieren Sie dieses Kontrollkästchen (den Standardwert), damit diese Objekte nur mit erfolgreich zugeordneten Daten ausgegeben werden.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Wenn dieses Kontrollkästchen ausgewählt ist (Standardeinstellung) und der Eigner geändert wird, wird das Eigentumsrecht für alle untergeordneten Ressourcen (Bereiche) auch an den neuen Eigner übertragen.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Wenn diese Option ausgewählt ist, schreibt die map-Richtlinie die XML-Ausgabe in 'message.body' als analysiertes XML-Dokument. Standardmäßig wird die XML als XML-Zeichenfolge ausgegeben. Die XML-Ausgabe für eine beliebige andere Variable wird immer als XML-Zeichenfolge geschrieben.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Wenn Sie die Lebenszyklusgenehmigung für die Aktionen 'Bereitstellen', 'Veröffentlichen' oder 'Ersetzen' aktivieren, funktionieren möglicherweise einige der API-Operationen zum Erstellen, Bearbeiten oder Testen, die diese Aktionen automatisch ausführen, nicht ordnungsgemäß. Möchten Sie diese Genehmigungen wirklich aktivieren?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Wenn Sie über einen vorhandenen SOAP-Service verfügen, der über eine API Connect-API-Definition bereitgestellt werden soll, können Sie eine API erstellen und den Ziel-SOAP-Service angeben. API Connect erstellt dann eine API-Definition, die den SOAP-Service aufruft.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Wenn Sie Ihre Änderungen speichern, indem Sie auf 'Ja. API-Assembly aktualisieren' klicken, wird die API-Assembly, die diesem OAuth-Provider zugrunde liegt, ebenfalls aktualisiert. Wenn Sie jedoch die zugrunde liegende API-Assembly angepasst haben und sie separat aktualisieren möchten, klicken Sie auf 'Nein. API-Assembly nicht aktualisieren' und aktualisieren Sie dann die zugrunde liegende API-Assembly im API-Editor.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Falls die Probleme weiterhin auftreten, laden Sie eine Schemadefinitionssprachendatei hoch.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Wenn Ihre Datei sowohl den privaten als auch den öffentlichen Schlüssel enthält, laden Sie sie in Schritt 1 hoch.`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Wenn Sie ein Gateway registrieren müssen, das sich hinter einer Firewall befindet, finden Sie in der IBM Cloud-Dokumentation Informationen zur Verwendung eines VPN oder des Secure Gateway-Service zum Herstellen der Verbindung.`,
  [`Ignore`]: `Ignorieren`,
  [`Implement policy`]: `Richtlinie implementieren`,
  [`Implementation file is required`]: `Implementierungsdatei ist erforderlich`,
  [`Implicit`]: `Implizit`,
  [`Import`]: `Importieren`,
  [`Import API`]: `API importieren`,
  [`Import API|title`]: `API importieren`,
  [`Import OpenAPI definition from asset repository`]: `OpenAPI-Definition aus Asset-Repository importieren`,
  [`Import Product`]: `Produkt importieren`,
  [`Import policy`]: `Richtlinie importieren`,
  [`Import a product from a service URL`]: `Importieren Sie ein Produkt aus einer Service-URL.`,
  [`Import a product from the definition file`]: `Importieren Sie ein Produkt aus der Definitionsdatei.`,
  [`Import an existing product`]: `Vorhandenes Produkt importieren`,
  [`Import an OpenAPI definition from a URL`]: `OpenAPI-Definition aus einer URL importieren`,
  [`Import an OpenAPI definition from a file`]: `OpenAPI-Definition aus einer Datei importieren`,
  [`Import definitions`]: `Definitionen importieren`,
  [`Import from existing API service`]: `Aus vorhandenem API-Service importieren`,
  [`Import from file`]: `Aus Datei importieren`,
  [`Importing an API definition`]: `API-Definition importieren`,
  [`Import|title`]: `Importieren`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Importieren Sie die erforderlichen Zertifikate und CA-Bundles in den Zertifikatmanagerservice.`,
  [`In Cookie Header`]: `In Cookie-Header`,
  [`In WSSec Header`]: `In WSSec-Header`,
  [`In order to invoke the endpoints above, you need to first`]: `Um die oben genannten Endpunkte aufzurufen, müssen Sie zunächst Folgendes tun:`,
  [`Inactive`]: `Inaktiv`,
  [`Inc`]: `Einschl`,
  [`Include empty`]: `Leere einschließen`,
  [`Include free trial days`]: `Kostenlose Testtage einschließen`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Mit dem konfigurierten Gateway-Service inkompatible APIs; Service kann nicht ausgewählt werden.`,
  [`Incorrect username or password`]: `Falscher Benutzername oder falsches Kennwort`,
  [`Incorrect username, password, or credentials`]: `Falscher Benutzername, falsches Kennwort oder falsche Berechtigungsnachweise`,
  [`Indicate if the invoke is the final action`]: `Gibt an, ob es sich beim Aufruf um die Endaktion handelt`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Gibt an, ob eine JWT-ID-Anforderung (jti) zum JWT hinzugefügt werden soll. Falls ausgewählt, ist der jti-Anforderungswert eine UUID.`,
  [`Indicates whether the clients secret is required.`]: `Gibt an, ob ein geheimer Clientschlüssel erforderlich ist.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Gibt an, ob dieses XSLT-Eingabedokument die aktuellen Kontextnutzdaten verwendet oder ob keine Eingabe vorhanden ist.`,
  [`Indicates whether to stop processing if client security fails.`]: `Gibt an, ob die Verarbeitung gestoppt werden soll, wenn die Clientsicherheit fehlschlägt.`,
  [`Infer from JSON schema`]: `Aus JSON-Schema übernehmen`,
  [`Infer from Sample`]: `Aus Beispiel ableiten`,
  [`Info`]: `Info`,
  [`Information context variable`]: `Kontextvariable für Informationen`,
  [`Inject proxy headers`]: `Proxy-Header einfügen`,
  [`Inline schema`]: `Inlineschema`,
  [`Input`]: `Eingabe`,
  [`Input field`]: `Eingabefeld`,
  [`Input object`]: `Eingabeobjekt`,
  [`Insecure Server Connections`]: `Unsichere Serververbindungen`,
  [`Install API Connect CLI & API Designer|button text`]: `API Connect-Befehlszeilenschnittstelle und API Designer installieren`,
  [`Install API Connect CLI & API Designer|title`]: `API Connect-Befehlszeilenschnittstelle und API Designer installieren`,
  [`Install API Connect Toolkit`]: `Installieren Sie das API Connect-Toolkit`,
  [`Install the API Connect plugin`]: `Installieren Sie das API Connect-Plug-in`,
  [`Instance ID`]: `Instanz-ID`,
  [`Instance name`]: `Instanzname`,
  [`Instance owner`]: `Instanzeigner`,
  [`Interact to expand Tile`]: `Interagieren Sie zum Erweitern der Kachel`,
  [`Interact to collapse Tile`]: `Interagieren Sie zum Ausblenden der Kachel`,
  [`Interval`]: `Intervall`,
  [`Interface`]: `Schnittstelle`,
  [`Introspect`]: `Introspektion`,
  [`Introspect cache type`]: `Introspektionscachetyp`,
  [`Introspect from Server URL`]: `Introspektion über Server-URL`,
  [`Introspect from URL`]: `Introspektion über URL`,
  [`Introspect URL`]: `Introspektions-URL`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Die Introspektion mit einer anderen URL ändert nicht die GraphQL-Server-URL.`,
  [`Introspect token`]: `Introspektionstoken`,
  [`Introspection`]: `Introspektion`,
  [`Introspection Path`]: `Introspektionspfad`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Introspektion des GraphQL-Schemas vom Server aus oder Upload des Schemas`,
  [`Introspect the GraphQL schema using the query`]: `GraphQL-Schema mithilfe der Abfrage überwachen`,
  [`Introspection URL`]: `Introspektions-URL`,
  [`Invalid HOST URL`]: `Ungültige Host-URL`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `Ungültige URL. Geben Sie eine gültige HOST-URL für den API-Manager ein. Beispiel: https://api-connect-host.com`,
  [`Invalid file URL`]: `Ungültige Datei-URL`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Ungültiges URL-Format. Die URL muss mit 'http://' oder 'https://' beginnen und mindestens ein Zeichen enthalten`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Ungültiges URL-Format. Es muss nur der Hostname angegeben werden. Beispiel: syslog:// kann entfernt werden`,
  [`Invalid emails.`]: `Ungültige E-Mails.`,
  [`Invalid JSONata expression`]: `Ungültiger JSONata-Ausdruck`,
  [`Invalid remove target`]: `Ungültiges Entfernungsziel`,
  [`Invalid assumed size`]: `Ungültige angenommene Größe`,
  [`Invalid cost target`]: `Ungültiges Kostenziel`,
  [`Invalid list size`]: `Ungültige Listengröße`,
  [`Invalid port value, must not be negative`]: `Ungültiger Portwert, darf nicht negativ sein`,
  [`Invalid sized fields`]: `Ungültige Felder für Größe`,
  [`Invalid value, length has to be greater than 1`]: `Ungültiger Wert, Länge muss größer als 1 sein`,
  [`Invalid weight value`]: `Ungültiger Gewichtungswert`,
  [`invitation`]: `invitation`,
  [`Invitation`]: `Einladung`,
  [`Invitation timeout`]: `Zeitlimit für Einladungen`,
  [`Invitation link will expire in {time}`]: `Einladungslink läuft in **{time}** ab`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Einladungslink läuft ab in **{seconds} Sekunden ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Einladungslink läuft ab in __{value} {unit}__.`,
  [`Invitation sent to`]: `Einladung gesendet an`,
  [`Invitation sent to {email}`]: `Einladung gesendet an {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Einladung zu einem API-Katalog in IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Einladung in eine API-Konsumentenorganisation im {catalog}-Developer Portal`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Einladung in eine API-Provider-Organisation in IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Einladung in einen API-Bereich in IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Einladung zu einer Verwaltungsorganisation in IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Einladung zur Erstellung eines API-Katalogs in IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Einladung zur Erstellung einer API-Konsumentenorganisation im {catalog}-Developer Portal`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Einladung zur Erstellung einer API-Provider-Organisation in IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Einladung zur Erstellung eines API-Bereichs in IBM API Connect`,
  [`Invitation ttl has been created.`]: `Einladungstitel wurde erstellt.`,
  [`Invitation ttl has been updated.`]: `Die Einladungslebensdauer wurde aktualisiert.`,
  [`Invitation ttl has not been created!`]: `Einladungstitel wurde nicht erstellt!`,
  [`Invitation ttl has not been updated!`]: `Einladungslebensdauer wurde nicht aktualisiert!`,
  [`Invite`]: `Einladen`,
  [`Invite catalog owner`]: `Katalogeigner einladen`,
  [`Invite consumer organization owner`]: `Eigner der Konsumentenorganisation einladen`,
  [`Invite member`]: `Mitglied einladen`,
  [`Invite organization owner`]: `Organisationseigner einladen`,
  [`Invite space owner`]: `Bereichseigner einladen`,
  [`Invite a new consumer organization owner`]: `Neuen Eigner einer Konsumentenorganisation einladen`,
  [`Invite a new member`]: `Neues Mitglied einladen`,
  [`Invite a new provider organization owner`]: `Neuen Eigner einer Provider-Organisation einladen`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Laden Sie einen neuen Benutzer ein, um sich als Manager eines Katalogs zu registrieren, indem Sie die Empfänger-E-Mail-Adresse eingeben. An den Benutzer wird eine Einladungs-E-Mail mit einem Link zum Registrierungsformular gesendet.`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Laden Sie einen neuen Benutzer ein, um sich als Manager eines Bereichs zu registrieren, indem Sie die Empfänger-E-Mail-Adresse eingeben. An den Benutzer wird eine Einladungs-E-Mail mit einem Link zum Registrierungsformular gesendet.`,
  [`Invite by email`]: `Per E-Mail einladen`,
  [`Invite from User Registry`]: `Aus Benutzerregistry einladen`,
  [`Invite team member`]: `Teammitglied einladen`,
  [`Inviting members to consumer organizations is enabled`]: `Mitglieder zu Verbraucherorganisationen einladen ist aktiviert`,
  [`Invocations`]: `Aufrufe`,
  [`Invoke`]: `Aufrufen`,
  [`Issuer Claim`]: `Ausstelleranforderung`,
  [`Issuer Origin`]: `Herkunft des Ausstellers`,
  [`Issuer common name`]: `Allgemeiner Name des Ausstellers`,
  [`It might influence how the API is deployed`]: `Sie kann beeinflussen, wie die API implementiert wird.`,
  [`Italian`]: `Italienisch`,
  [`Items per page`]: `Elemente pro Seite`,
  [`Items per page:`]: `Elemente pro Seite:`,
  [`items selected`]: `Elemente ausgewählt`,
  [`item selected`]: `Element ausgewählt`,
  [`Iterate on`]: `Iterieren auf`,
  [`Iterate?`]: `Iterieren?`,
  [`JSON objects describing re-usable channel parameters.`]: `JSON-Objekte, die wiederverwendbare Kanalparameter beschreiben.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `JSON-Objekte, die die von der API konsumierten und erstellten Nachrichten beschreiben.`,
  [`JSON Schema URL`]: `JSON-Schema-URL`,
  [`JSON Schema preview`]: `Vorschau für JSON-Schema`,
  [`JSON Web Token (JWT)`]: `JSON-Web-Token (JWT)`,
  [`JSON has been successfully validated`]: `JSON wurde erfolgreich geprüft`,
  [`JSON schema is not valid. Please correct the error.`]: `Das JSON-Schema ist ungültig. Beheben Sie den Fehler.`,
  [`JSON to XML`]: `JSON in XML`,
  [`JWT ID Claim`]: `JWT-ID-Anforderung`,
  [`JWT verification JWK`]: `JWT-Prüfung - JWK`,
  [`JWT verification crypto object`]: `JWT-Prüfschlüsselobjekt`,
  [`Japanese`]: `Japanisch`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Jobwarteschlange`,
  [`Jump to source`]: `Zur Quelle springen`,
  [`Just now`]: `Jetzt`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `SCHLÜSSELAUSTAUSCH/VERSCHLÜSSELUNG/MAC`,
  [`KEY SIZE`]: `SCHLÜSSELGRÖSSE`,
  [`Keystore`]: `Keystore`,
  [`keystore`]: `Keystore`,
  [`Keep`]: `Beibehalten`,
  [`Keep Payload`]: `Nutzdaten beibehalten`,
  [`Key Encryption Algorithm`]: `Schlüsselverschlüsselungsalgorithmus`,
  [`Key Name`]: `Schlüsselname`,
  [`Key type`]: `Schlüsseltyp`,
  [`Key name`]: `Schlüsselname`,
  [`Keystore for the token has been updated.`]: `Der Keystore für das Token wurde aktualisiert.`,
  [`Keystore Certificates`]: `Keystore-Zertifikate`,
  [`Keystore/truststore`]: `Keystore/Truststore`,
  [`Korean`]: `Koreanisch`,
  [`LABEL`]: `BEZEICHNUNG`,
  [`LABELS`]: `BEZEICHNUNGEN`,
  [`LAST MODIFIED`]: `LETZTE ÄNDERUNG`,
  [`Last state changed`]: `Letzte Statusänderung`,
  [`Large`]: `Groß`,
  [`Lax`]: `Nicht streng`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAP-Gruppen`,
  [`LDAP user registry`]: `LDAP-Benutzerregistry`,
  [`LDAP registry`]: `LDAP-Registry`,
  [`LDAP registry name`]: `Name der LDAP-Registry`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Die LDAP-Benutzerregistry der Rolle {{name}} unterstützt keine Zuordnung von externen Gruppen mehr. Aktivieren Sie die externe Gruppenzuordnung für diese Benutzerregistry, oder inaktivieren Sie die Zuordnung der externen Gruppe in der Rolle, bevor Sie speichern.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Die LDAP-Benutzerregistry der Rolle {{name}} unterstützt keine Zuordnung von externen Gruppen mehr. Wählen Sie eine andere Benutzerregistry mit einer externen Gruppenzuordnung aus, oder inaktivieren Sie die Zuordnung der externen Gruppe in der Rolle vor dem Speichern.`,
  [`LDAP search attribute`]: `LDAP-Suchattribut`,
  [`LOCATED IN`]: `BEFINDET SICH IN`,
  [`Located in`]: `Befindet sich unter`,
  [`LTPA Key`]: `LTPA-Schlüssel`,
  [`LTPA Key Details`]: `LTPA-Schlüsseldetails`,
  [`LTPA Keys`]: `LTPA-Schlüssel`,
  [`LTPA key file`]: `LTPA-Schlüsseldatei`,
  [`LTPA key password`]: `LTPA-Schlüsselkennwort`,
  [`LUR data version`]: `LUR-Datenversion`,
  [`LUR schema update date`]: `Datum der Aktualisierung des LUR-Schemas`,
  [`LUR schema version`]: `LUR-Schemaversion`,
  [`LUR target data version`]: `LUR-Zieldatenversion`,
  [`LUR target schema version`]: `LUR-Zielschemaversion`,
  [`LUR update date`]: `LUR-Aktualisierungsdatum`,
  [`Last name`]: `Nachname`,
  [`Last modified`]: `Letzte Änderung`,
  [`Launch portal`]: `Portal starten`,
  [`Launch IBM Automation management console`]: `IBM Automatisierungsmanagement Konsole starten`,
  [`Launch plan upgrade`]: `Planupgrade starten`,
  [`Learn more`]: `Weitere Informationen`,
  [`Learn More`]: `Weitere Informationen`,
  [`Legacy`]: `Herkömmlich`,
  [`Length cannot be below {min}`]: `Länge darf nicht unter {min} liegen`,
  [`Length cannot exceed {max}`]: `Länge darf nicht über {max} liegen`,
  [`Let's customize API Connect`]: `API Connect anpassen`,
  [`Let's get started...`]: `Erste Schritte`,
  [`Let's get you up and running`]: `Betriebsbereit machen`,
  [`License`]: `Lizenz`,
  [`Licensed Materials - Property of IBM`]: `Lizenziertes Material - Eigentum von IBM`,
  [`Lifecycle`]: `Lebenszyklus`,
  [`Lifecycle approvals`]: `Lebenszyklusgenehmigungen`,
  [`Lifecycle actions have been updated`]: `Lebenszyklusaktionen wurden aktualisiert`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Lebenszyklusgenehmigungen sind für den Sandbox-Katalog aktiviert. Um die Option automatischen Aktivieren der API zu verwenden, müssen Sie zunächst die Lebenszyklusgenehmigungen in den Einstellungen des Sandbox-Katalogs inaktivieren.`,
  [`Limited fields`]: `Begrenzte Felder`,
  [`Limit API calls on a per key basis`]: `API-Aufrufe auf Schlüsselbasis begrenzen`,
  [`Limit fields`]: `Grenzwertfelder`,
  [`Limit renegotiation`]: `Neuvereinbarung des Grenzwerts`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Laden`,
  [`Load query`]: `Abfrage laden`,
  [`Load filter`]: `Filter laden`,
  [`Load schema from WSDL`]: `Schema aus WSDL laden`,
  [`Loading`]: `Ladevorgang läuft`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Lokale Benutzerregistrys sind die Standardbenutzerregistrys, die in API Connect enthalten sind, und werden lokal gespeichert werden.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Lokale Benutzerregistrys sind die Standardbenutzerregistrys, die in API Connect enthalten sind und lokal gespeichert werden. Während der Installation von API Connect werden zwei lokale Benutzerregistrys installiert und konfiguriert. Es können auch zusätzliche lokale Benutzerregistrys konfiguriert werden.`,
  [`Local user registry`]: `Lokale Benutzerregistry`,
  [`Local User Registry has been created.`]: `Die lokale Benutzerregistry wurde erstellt.`,
  [`Location`]: `Position`,
  [`Location of ciphertext to decrypt`]: `Position des verschlüsselten Textes, der entschlüsselt werden soll`,
  [`Location of plaintext information to encrypt`]: `Position der Informationen in einfachem Text, der verschlüsselt werden soll`,
  [`Log`]: `Protokoll`,
  [`Log in`]: `Anmelden`,
  [`Log in to:`]: `Anmelden bei:`,
  [`Log in with a different account`]: `Mit einem anderen Account anmelden`,
  [`Log into IBM Cloud`]: `Bei IBM Cloud anmelden`,
  [`Log out`]: `Abmelden`,
  [`Log in using the {userRegistryType} user registry`]: `Melden Sie sich mit der {userRegistryType}-Benutzerregistry an`,
  [`Logging in with IBM Cloud ...`]: `Anmeldung mit IBM Cloud läuft...`,
  [`Logging out`]: `Abmeldung`,
  [`Logic`]: `Logik`,
  [`Login`]: `Anmelden`,
  [`Logs`]: `Protokolle`,
  [`Longer, descriptive name for this policy.`]: `Langer, beschreibender Name für diese Richtlinie.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Anscheinend tritt beim Server bei der Verarbeitung Ihrer Anforderung ein Fehler auf.`,
  [`LoopBack`]: `Loopback`,
  [`Mail server`]: `E-Mail-Server`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `MITGLIED`,
  [`MIME Types`]: `MIME-Typen`,
  [`Mail server has been deleted.`]: `Mail-Server wurde gelöscht.`,
  [`Mail server has not been deleted.`]: `Mail-Server wurde nicht gelöscht.`,
  [`Mailserver {arg} has been removed.`]: `Mail-Server {arg} wurde entfernt.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Stellen Sie sicher, dass der Server ausgeführt wird und dass eine Netzkonnektivität vorhanden ist.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Zu den möglichen Ursachen gehören fehlende CORS-Unterstützung auf dem Zielserver, Nichtverfügbarkeit des Servers, Vorhandensein eines nicht vertrauenswürdigen Zertifikats oder die Notwendigkeit einer gegenseitigen SSL-Authentifizierung.`,
  [`This might be caused by one or more of the following:`]: `Dies kann durch einen oder mehrere der folgenden Gründe verursacht werden:`,
  [`Make a call and move on`]: `Aufruf starten und fortfahren`,
  [`Make a call and wait for response`]: `Aufruf starten und auf Antwort warten`,
  [`Manage`]: `Verwalten`,
  [`Manage API life cycles`]: `API-Lebenszyklen verwalten`,
  [`Manage API product lifecycle approval permissions`]: `Berechtigungen für Genehmigungen des API-Produktlebenszyklus verwalten`,
  [`Manage API product lifecycle approvals permissions`]: `Berechtigungen für Genehmigungen des API-Produktlebenszyklus verwalten`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Verwalten Sie API-Provider-Organisationen, lokale und ferne Gateways und zugehörige Einstellungen.`,
  [`Manage APIs`]: `APIs verwalten`,
  [`Manage Catalogs`]: `Kataloge verwalten`,
  [`Manage Organizations`]: `Organisationen verwalten`,
  [`Manage Resources`]: `Ressourcen verwalten`,
  [`Manage Settings`]: `Einstellungen verwalten`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Verwalten Sie Ziele für die API-Analyse, einschließlich API Connect, HTTP, Kafka, Elasticsearch und Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Verwalten Sie lokale und ferne Gateways, die Ihre APIs sichern.`,
  [`Manage catalogs`]: `Kataloge verwalten`,
  [`Manage organizations`]: `Organisationen verwalten`,
  [`Manage resources`]: `Ressourcen verwalten`,
  [`Manage settings`]: `Einstellungen verwalten`,
  [`Manage users`]: `Benutzer verwalten`,
  [`Manage active APIs and consumers`]: `Aktive APIs und Konsumenten verwalten`,
  [`Manage availability zones and services`]: `Verfügbarkeitszonen und -services verwalten`,
  [`Manage policies`]: `Richtlinien verwalten`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Verwalten Sie Änderungsanforderungen für den Produktlebenszyklus sowie Anforderungen von API-Abonnements und Anwendungs-Upgrades von Anwendungsentwicklern.`,
  [`Manage roles and permissions`]: `Rollen und Berechtigungen verwalten`,
  [`Manage the OAuth providers configured for API Manager`]: `Verwalten Sie die OAuth-Provider, die für API Manager konfiguriert sind.`,
  [`Manage the user registries configured for API Manager`]: `Verwalten Sie die für API Manager konfigurierten Benutzerregistrys.`,
  [`Manage the user registries configured for Cloud Manager`]: `Verwalten Sie die für Cloud Manager konfigurierten Benutzerregistrys.`,
  [`Management`]: `Management`,
  [`Management Endpoint`]: `Managementendpunkt`,
  [`Management endpoint`]: `Managementendpunkt`,
  [`Management endpoint on the analytics service`]: `Managementendpunkt auf dem Analyseservice`,
  [`Service endpoint configuration`]: `Konfiguration des Serviceendpunkts`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Endpunkt, der vom Management-Service zum Senden von Konfigurationsinformationen an den Portalservice verwendet wird`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Vom Management-Service bei der Kommunikation mit dem Portalservice verwendetes TLS-Clientprofil`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Endpunkt, der vom Management-Service zum Senden von Konfigurationsdaten an den Gateway-Service verwendet wird`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `TLS-Clientprofil, das vom Management-Service bei der Kommunikation mit dem Gateway-Service verwendet wird`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Clusterinterne Kommunikation für internen Datenverkehr zwischen Gateway und Management-Service verwenden`,
  [`Use in-cluster communication for both ingestion and queries`]: `Clusterinterne Kommunikation sowohl für Aufnahme als auch für Abfragen verwenden`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Verwendung im Cluster für Aufnahme und extern für Abfragen oder umgekehrt`,
  [`Use external communication for both ingestion and queries`]: `Externe Kommunikation sowohl für Aufnahme als auch für Abfragen verwenden`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Verwendung der clusterinternen Kommunikation für internen Datenverkehr zwischen Analyse und Management-Service`,
  [`Management endpoint on the gateway service`]: `Managementendpunkt auf dem Gateway-Service`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Clusterinterne Kommunikation für internen Datenverkehr zwischen dem Portal und dem Management-Service verwenden`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Wenn Sie die clusterinterne Kommunikation zwischen den Management- und Portalsubsystemen verwenden wollen, aktivieren Sie diesen Switch. Wenn Sie nicht sicher sind, lassen Sie diesen Switch inaktiviert (Standardeinstellung).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Wenn Sie die clusterinterne Kommunikation zwischen Management- und Gateway-Subsystemen verwenden wollen, aktivieren Sie diesen Switch. Wenn Sie nicht sicher sind, lassen Sie diesen Switch inaktiviert (Standardeinstellung).`,
  [`Management endpoint on the portal service`]: `Managementendpunkt auf dem Portalservice`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Diese Option ist die Standardeinstellung und wird für Analyseservices verwendet, für die ein Upgrade von einem Release vor pre-10.0.5.3 durchgeführt wird. Bei OVA-Implementierungen oder wenn sich Ihr Analysesubsystem in einer anderen Umgebung befindet, ist die externe Option die einzige, die Sie verwenden können. Sowohl das Gateway als auch der Management-Service kommunizieren mit dem Analyseservice über den externen Endpunkt, bei dem es sich je nach Plattform um eine Kubernetes-Ingress- oder OpenShift-Route handelt.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `Sie können im Cluster auswählen, wenn Sie eine Kubernetes-oder OpenShift-Bereitstellung haben, bei der sich alle Subsysteme in demselben Cluster befinden. Wenn diese Option ausgewählt ist, kommunizieren die Management-, Gateway-und Analysesubsysteme über interne Serviceendpunkte und nicht über extern zugängliche Ingress-Ressourcen (Kubernetes) oder Routen (OpenShift) miteinander.`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Sie können diese Option auswählen, wenn Sie eine Kubernetes- oder OpenShift-Bereitstellung haben und einige Ihrer Subsysteme in demselben Cluster installiert sind. Wenn diese Option ausgewählt ist, können Sie verschiedene Übertragungsmethoden für die verschiedenen Analysekommunikationsabläufe verwenden. Beispielsweise kann die Kommunikation zwischen Management und Analyse innerhalb des Clusters und das Gateway zur Analyse außerhalb des Clusters verwendet werden. Sie können diese Konfiguration wählen, wenn sich Ihr Gateway in einem anderen Cluster als die übrigen Subsysteme befindet.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Wenn Sie bei der Registrierung des Analyseservice die Kommunikation im Cluster angegeben haben, können Sie das Gateway aktivieren, um API-Ereignisdaten an den internen Analyseendpunkt zu senden.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Internen Endpunkt für die Aufnahme von Analysedaten aus dem Gateway-Service verwenden`,
  [`Endpoint configuration`]: `Externe Kommunikation sowohl für Aufnahme als auch für Abfragen verwenden`,
  [`Portal endpoint configuration`]: `Konfiguration des Portalendpunkts`,
  [`URL used by developer portal users to access the portal website`]: `URL, die von Benutzern des Entwicklerportals für den Zugriff auf die Portalwebsite verwendet wird`,
  [`External ingestion endpoint on the analytics service`]: `Externer Aufnahmeendpunkt im Analyseservice`,
  [`TLS client profile for external analytics ingestion endpoint`]: `TLS-Clientprofil für externen Endpunkt der Analyseaufnahme`,
  [`Internal ingestion endpoint on the analytics service`]: `Interner Aufnahmeendpunkt im Analyseservice`,
  [`TLS client profile for internal service endpoint`]: `TLS-Clientprofil für internen Serviceendpunkt`,
  [`Manager`]: `Manager`,
  [`Manager Request Reset Password`]: `Manager - Anforderung zum Zurücksetzen des Kennworts`,
  [`Manager Reset Password`]: `Manager - Kennwort zurücksetzen`,
  [`Manager Sign In`]: `Manager - Anmeldung`,
  [`Manages API provider organizations`]: `Verwaltet API-Provider-Organisationen`,
  [`Manages application developer communities`]: `Verwaltet Anwendungsentwickler-Communitys`,
  [`Manages the API product lifecycle`]: `Verwaltet den API-Produktlebenszyklus`,
  [`Manage|link`]: `Verwalten`,
  [`Manage|permission`]: `Verwalten`,
  [`Manage|title`]: `Verwalten:Titel`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Keystore-Zuordnung und Keystore-Verlauf für Tokens verwalten`,
  [`Map`]: `Zuordnen`,
  [`Map information from OIDC provider to an APIC user`]: `Informationen vom OIDC-Provider einem APIC-Benutzer zuordnen`,
  [`Map the migration source plans to the migration target plans`]: `Ordnen Sie die Migrationsquellenpläne Migrationszielplänen zu`,
  [`Map the roles to groups`]: `Rollen den Gruppen zuordnen`,
  [`Mapped from: `]: `Zugeordnet von: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Maximieren/minimieren`,
  [`Maximum Consent Time to Live (seconds)`]: `Lebensdauer für maximale Zustimmung (Sekunden)`,
  [`Maximum consent`]: `Maximale Zustimmung`,
  [`Menu`]: `Menü`,
  [`Member`]: `Mitglied`,
  [`Member Invitation`]: `Mitgliedereinladung`,
  [`Member Invitation has been deleted.`]: `Die Mitgliedereinladung wurde gelöscht.`,
  [`Member has been deleted.`]: `Mitglied wurde gelöscht.`,
  [`Member {name} created`]: `Mitglied {name} erstellt`,
  [`Member {title} has been created.`]: `Mitglied {title} wurde erstellt.`,
  [`Members`]: `Mitglieder`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Mitgliedseinladungen`,
  [`Member Roles`]: `Mitgliedsrollen`,
  [`Merchant ID`]: `Händler-ID`,
  [`Merge`]: `Zusammenfügen`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Diese Zuordnung mit anderen zusammenführen, die im selben Zielarray verwendet werden?`,
  [`Message`]: `Nachricht`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Nachricht für das Parsing`,
  [`Message for resulting parsed data`]: `Nachricht für resultierende geparste Daten`,
  [`Metadata`]: `Metadaten`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Mit Metadaten können Sie angepasste Metadaten erfassen, um sie während des Generierungsprozesses für Zugriffstokens einzuschließen. Sie können die Metadaten über eine Authentifizierungs-URL oder einen fernen Server erfassen, auf dem die Metadaten gespeichert sind, oder beides.`,
  [`Micro Gateway policies`]: `Micro Gateway-Richtlinien`,
  [`Migrate`]: `Migrieren`,
  [`Migrate Subscriptions`]: `Abonnements migrieren`,
  [`Migrate plans`]: `Pläne migrieren`,
  [`Migrate subscription`]: `Abonnement migrieren`,
  [`Migrate subscriptions`]: `Abonnements migrieren`,
  [`Migration Target`]: `Migrationsziel`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `Die Migration von "{source}" (Produkt) zu "{target}" (Produkt) wurde ausgeführt`,
  [`Migration from "{source}" failed`]: `Die Migration von"{source}" ist fehlgeschlagen`,
  [`Migration source`]: `Migrationsquelle`,
  [`Migration target`]: `Migrationsziel`,
  [`Minimum role`]: `Mindestrolle`,
  [`Minimum output escaping rule`]: `Escape-Regel für Mindestausgabe`,
  [`Minutes`]: `Minuten`,
  [`Mode`]: `Modus`,
  [`Modifications`]: `Änderungen`,
  [`Modified`]: `Geändert`,
  [`More`]: `Mehr`,
  [`More than 20 events are waiting to be processed`]: `Mehr als 20 Ereignisse warten auf die Verarbeitung`,
  [`Move down`]: `Nach unten`,
  [`Move up`]: `Nach oben`,
  [`Must be a number`]: `Muss eine Zahl sein`,
  [`Must be a valid URI`]: `Muss ein gültiger URI sein`,
  [`Must be a valid email`]: `Muss eine gültige E-Mail-Adresse sein`,
  [`Must be a valid path starting with /`]: `Muss ein gültiger Pfad sein, der mit / beginnt`,
  [`Must be a valid url`]: `Muss eine gültige URL sein`,
  [`Must be an integer`]: `Muss eine ganze Zahl sein`,
  [`Must be a valid url with no protocol`]: `Es muss sich um eine gültige URL ohne Protokoll handeln.`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Muss das Format mit 64 Hexadezimalzeichen (mit Präfix "0x") aufweisen.`,
  [`Must start with '/'`]: `Muss mit '/' beginnen`,
  [`Must start with https://`]: `Muss mit https:// beginnen`,
  [`Must start with https:// or $(`]: `Muss mit https:// oder $( beginnen`,
  [`Mutual auth`]: `Gegenseitige Authentifizierung`,
  [`Mutual authentication`]: `Gegenseitige Authentifizierung`,
  [`My account`]: `Mein Account`,
  [`Name`]: `Name`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NICHT`,
  [`Name cannot be empty.`]: `Name darf nicht leer sein.`,
  [`Name of Schema in OpenAPI Definitions`]: `Name des Schemas in OpenAPI-Definitionen`,
  [`Name of the LDAP user password attribute.`]: `Name des LDAP-Benutzerkennwortattributs.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Name der LDAP-Benutzerregistry für die Validierung. Wählen Sie sie aus der Liste aus oder geben Sie sie manuell ein.`,
  [`Name of the organization`]: `Name der Organisation`,
  [`Namespace inheritance`]: `Übernahme des Namensbereichs`,
  [`Namespace inlining`]: `Namensbereichs-Inlining`,
  [`Native`]: `Nativ`,
  [`Native OAuth provider`]: `Nativer OAuth-Provider`,
  [`Native OAuth provider summary`]: `Zusammenfassung zu nativem OAuth-Provider`,
  [`Navigate to Source View`]: `Navigieren Sie zur Quellenansicht.`,
  [`New`]: `Neu`,
  [`New API`]: `Neue API`,
  [`New API Connect connection`]: `Neue API Connect-Verbindung`,
  [`New Application`]: `Neue Anwendung`,
  [`New Catalog`]: `Neuer Katalog`,
  [`New Consumer organization`]: `Neue Konsumentenorganisation`,
  [`New Endpoint`]: `Neuer Endpunkt`,
  [`New native OAuth provider`]: `Neuer nativer OAuth-Provider`,
  [`New OpenAPI`]: `Neue OpenAPI`,
  [`New OpenAPI directory`]: `Neues OpenAPI-Verzeichnis`,
  [`New Organization Title`]: `Neuer Organisationstitel`,
  [`New Parameter`]: `Neuer Parameter`,
  [`New Password`]: `Neues Kennwort`,
  [`New product`]: `Neues Produkt`,
  [`New Role`]: `Neue Rolle`,
  [`New schema`]: `Neues Schema`,
  [`New Test`]: `Neuer Test`,
  [`New third party OAuth provider`]: `Neuer OAuth-Provider eines Drittanbieters`,
  [`New user`]: `Neuer Benutzer`,
  [`New applications will be created in the development state`]: `Neue Anwendungen werden im Entwicklungsstatus erstellt.`,
  [`Next`]: `Weiter`,
  [`Next page`]: `Nächste Seite`,
  [`No`]: `Nein`,
  [`No account?`]: `Kein Account?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `Im Sandbox-Katalog sind keine Authentifizierungs-URL und keine LDAP-Benutzerregistrys konfiguriert.`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `Im Sandbox-Katalog sind keine Authentifizierungs-URL und keine LDAP-Benutzerregistrys konfiguriert. Erstellen Sie sie [hier]({link}`,
  [`No Billing`]: `Keine Abrechnung`,
  [`No billing integration has been selected.`]: `Es wurde keine Abrechnungsintegration ausgewählt.`,
  [`No billing integration can be shown when offline.`]: `Es kann keine Abrechnungsintegration angezeigt werden, wenn sie offline ist.`,
  [`No gateways`]: `Keine Gateways`,
  [`No cache`]: `Kein Cache`,
  [`No Cache`]: `Kein Cache`,
  [`No Certificate Manager services available`]: `Keine Zertifikatmanagerservices verfügbar`,
  [`No default gateways`]: `Keine Standardgateways`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Keine doppelte E-Mail-Adresse in der Benutzerregistry. Dies gilt nur für die Benutzer mit E-Mail.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Es sind keine kompatiblen Gateways für den Sandbox-Katalog konfiguriert. Der API-Test (Try it) im Explorer ist nicht aktiviert.`,
  [`No Errors`]: `Keine Fehler`,
  [`No Histories found`]: `Keine Verläufe gefunden`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Keine Gateway-Services gefunden. Konfigurieren Sie einen Gateway-Service, um unterstützte Assembly-Richtlinien anzuzeigen`,
  [`No gateways are configured for sandbox catalog`]: `Es sind keine Gateways für den Sandbox-Katalog konfiguriert.`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Es sind keine Gateways für den Sandbox-Katalog konfiguriert. API-Test (Try it) im Explorer ist nicht aktiviert`,
  [`No LDAP or authentication URL user registries found.`]: `Keine LDAP-Benutzerregistrys oder Benutzerregistrys für Authentifizierungs-URL gefunden.`,
  [`No limit on size of list`]: `Keine Größenbegrenzung der Liste`,
  [`No OAuth providers configured in the sandbox catalog`]: `Im Sandbox-Katalog sind keine OAuth-Provider konfiguriert.`,
  [`No OAuth providers configured in the selected catalog`]: `Keine OAuth-Provider im ausgewählten Katalog konfiguriert`,
  [`No Permission`]: `Keine Berechtigung`,
  [`No portal added to this catalog`]: `Diesem Katalog wurde kein Portal hinzugefügt.`,
  [`No Roles exist`]: `Keine Rollen vorhanden`,
  [`No recent items found.`]: `Keine aktuellen Elemente gefunden.`,
  [`No TLS profile`]: `Kein TLS-Profil`,
  [`No TLS client profile`]: `Kein TLS-Clientprofil`,
  [`No Truststore`]: `Kein Truststore`,
  [`No analytics service is configured`]: `Es wird kein Analyseservice konfiguriert.`,
  [`No analytics services found. You can create one [here]({link})`]: `Keine Analyseservices gefunden. Sie könne [here]({link}) einen erstellen`,
  [`No assemblies found.`]: `Keine Assemblys gefunden.`,
  [`No available extensions`]: `Keine verfügbaren Erweiterungen`,
  [`No compatible flows configured in this OAuth provider`]: `In diesem OAuth-Provider ist kein kompatibler Ablauf konfiguriert.`,
  [`No consumer organization will be able to see this product.`]: `Keine Konsumentenorganisation kann dieses Produkt anzeigen.`,
  [`No debug data was found for this API call`]: `Für diesen API-Aufruf wurden keine Debug-Daten gefunden`,
  [`No details are available.`]: `Es sind keine Details verfügbar.`,
  [`No existing products`]: `Keine Produkte vorhanden`,
  [`No extensions have been configured for the API`]: `Für die API wurden keine Erweiterungen konfiguriert.`,
  [`No flow configured in this OAuth provider`]: `In diesem OAuth-Provider ist kein Ablauf konfiguriert.`,
  [`No items found`]: `Keine Elemente gefunden`,
  [`No non-member user to add.`]: `Kein Benutzer, der kein Mitglied ist, zum Hinzufügen vorhanden.`,
  [`No organization found`]: `Keine Organisation gefunden`,
  [`No portal service exists:`]: `Kein Portalservice vorhanden:`,
  [`No product APIs`]: `Keine Produkt-APIs`,
  [`No products or plans were found.`]: `Keine Produkte oder Pläne gefunden.`,
  [`No provider organizations`]: `Keine Provider-Organisationen`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Keine Antwort empfangen. Zu den Ursachen gehören fehlende CORS-Unterstützung auf dem Zielserver, Nichtverfügbarkeit des Servers, Vorhandensein eines nicht vertrauenswürdigen Zertifikats oder die Notwendigkeit einer gegenseitigen SSL-Authentifizierung.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Keine Ressourcengruppen verfügbar. Um zu starten, erstellen Sie eine Ressourcengruppe in IBM Cloud Account-Ressourcen.`,
  [`No security`]: `Keine Sicherheit`,
  [`No services found`]: `Keine Services gefunden`,
  [`No warnings`]: `Keine Warnungen`,
  [`No warnings.`]: `Keine Warnungen.`,
  [`No. Do not update API Assembly`]: `Nein. API-Assembly nicht aktualisieren`,
  [`Non-blocking`]: `Nicht blockierend`,
  [`Non int slicing arguments`]: `Non-int-Slicing-Argumente`,
  [`None`]: `Keine`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Keiner der konfigurierten OAuth-Provider stimmt mit dem Gateway-Typ dieser API überein`,
  [`Normal`]: `Normal`,
  [`Not Available`]: `Nicht verfügbar`,
  [`Not applicable for application grant type.`]: `Nicht anwendbar für Anwendungsbewilligungstyp.`,
  [`Not valid before`]: `Nicht gültig vor`,
  [`Not valid after`]: `Nicht gültig nach`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Hinweis: Diese Option ist inaktiviert, wenn die Option 'Autoprodukt generieren' ausgewählt ist.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Hinweis: Diese Option ist inaktiviert, wenn die Option 'Autoprodukt generieren' ausgewählt ist, und stattdessen den Standardplan für das generierte Produkt verwendet wird.`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Hinweis: Die Benutzervorgabe für Gateway Services wird als Geltungsbereich für alle anderen Vorgaben verwendet. Richtlinien und andere spezifische Inhalte des Gateway-Service werden auf Grundlage dieser Einstellung geladen.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Hinweis: Diese Option ist nur für Informationszwecke verfügbar und wird basierend auf der obigen Testanwendungsoption aktualisiert`,
  [`Notices`]: `Bemerkungen`,
  [`Notification template`]: `Benachrichtigungsvorlage`,
  [`Notification templates`]: `Benachrichtigungsvorlagen`,
  [`Notification server configured`]: `Benachrichtigungsserver konfiguriert`,
  [`Notifications`]: `Benachrichtigungen`,
  [`Null Badgerfish`]: `Null Badgerfish`,
  [`Null`]: `Null`,
  [`Number`]: `Nummer`,
  [`Number of Operations`]: `Anzahl der Operationen`,
  [`Numeric`]: `Numerisch`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth-Provider`,
  [`OAuth provider`]: `OAuth-Provider`,
  [`OAuth Provider Policy Assembly`]: `OAuth-Provider-Richtlinien-Assembly`,
  [`OAuth providers`]: `OAuth-Provider`,
  [`OAuth Redirect URL`]: `OAuth-Umleitungs-URL`,
  [`OAuth shared secret`]: `Geheimer OAuth-Schlüssel für gemeinsame Nutzung`,
  [`OAuth provider policy assembly`]: `OAuth-Provider-Richtlinien-Assembly`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `OAuth-Provider {currentProviderName} wurde nicht gefunden. Wählen Sie einen konfigurierten OAuth-Provider aus.`,
  [`OAuth redirect URL`]: `OAuth-Umleitungs-URL`,
  [`OAuth Shared Secret`]: `Geheimer OAuth-Schlüssel für gemeinsame Nutzung`,
  [`OAuth Signature Method`]: `OAuth-Signaturverfahren`,
  [`OAuth signature method`]: `OAuth-Signaturverfahren`,
  [`OAuth providers can be created and managed in the following list.`]: `OAuth-Provider können in der folgenden Liste erstellt und verwaltet werden.`,
  [`OAuth symmetric key name`]: `Symmetrischer OAuth-Schlüsselname`,
  [`OAuth symmetric key value`]: `Symmetrischer OAuth-Schlüsselwert`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2-Zugriffscodesicherheit`,
  [`OAuth2 Application Security`]: `OAuth2-Anwendungssicherheit`,
  [`OAuth2 Implicit Security`]: `Implizite OAuth2-Sicherheit`,
  [`OAuth2 Password Security`]: `OAuth2-Kennwortsicherheit`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `OPERATIONS-ID`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `AUFTRAG`,
  [`ORGANIZATION`]: `ORGANISATION`,
  [`ORGANIZATION MANAGER`]: `ORGANISATIONSMANAGER`,
  [`Organizations`]: `Organisationen`,
  [`Owner`]: `Eigner`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `OAuth-Provider werden in jedem Bereich verwaltet, wenn Bereiche aktiviert sind. Rufen Sie die Bereichseinstellungen auf, um OAuth-Provider zu verwalten.`,
  [`Off`]: `Aus`,
  [`Offline`]: `Offline`,
  [`Old schema`]: `Altes Schema`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch ermöglicht das Suchen nach APIs, Produkten, Anwendungen, Abonnements und anderen Ressourcen in einer Organisation oder einem Katalog. Zudem kann es beim Erkennen von Beziehungen zwischen diesen Ressourcen hilfreich sein.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch für alle unterstützten Listenseiten`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch für API-Entwürfe und Produkte`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch für veröffentlichte Produkte in Katalogen und Bereichen`,
  [`Omnisearch for consumer organizations`]: `Omnisearch für Konsumentenorganisationen`,
  [`Omnisearch for applications`]: `Omnisearch für Anwendungen`,
  [`Omnisearch for requested approvals`]: `Omnisearch für angeforderte Genehmigungen`,
  [`Omnisearch for approvals tasks`]: `Omnisearch für Genehmigungstasks`,
  [`Omnisearch for product apis`]: `Omnisearch für Produkt-APIs`,
  [`Omnisearch for subscriptions`]: `Omnisearch für Abonnements`,
  [`Omnisearch for members`]: `Omnisearch für Mitglieder`,
  [`Omnisearch for provider organizations`]: `Omnisuche nach Anbieterorganisationen`,
  [`Disable the new editor for OpenAPI2`]: `Neuen Editor für OpenAPI2 inaktivieren`,
  [`On`]: `Ein`,
  [`Onboarding`]: `Onboarding`,
  [`Onboarding Edit Admin Org`]: `Onboarding - Verwaltungsorganisation bearbeiten`,
  [`Onboarding Edit Cloud Org`]: `Onboarding - Cloud-Organisation bearbeiten`,
  [`Onboarding Edit Reset Password`]: `Onboarding - Zurücksetzen des Kennworts bearbeiten`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Verwenden Sie nach der Installation die Befehlszeilenschnittstelle von IBM Cloud, um den Account und die Region, in der Sie API Connect bereitgestellt haben, als Ziel zu setzen, Testen Sie dann die Befehlszeilenschnittstelle, indem Sie alle Ihre API Connect-Provider-Organisationen aufführen`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Ein oder mehrere JSON-Objekte, welche die von der API konsumierten und erstellten Schemata beschreiben.`,
  [`One or more JSON representations for parameters`]: `Eine oder mehrere JSON-Darstellungen für Parameter`,
  [`One time use access token`]: `Zugriffstoken zur einmaligen Verwendung`,
  [`One time use refresh token`]: `Aktualisierungstoken zur einmaligen Verwendung`,
  [`Online`]: `Online`,
  [`Only one API file should be present in the zip file.`]: `Die ZIP-Datei darf nur eine API-Datei enthalten.`,
  [`OpenAPI Specification Version`]: `OpenAPI-Spezifikationsversion`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) bietet ein zusätzliches Authentifizierungsprotokoll auf Basis von OAuth 2.0. OIDC stellt Benutzerinformationen bereit, die in einem Web-Token von JSON oder JWT codiert sind. Wenn Sie OpenID Connect aktivieren, wird eine Vorlage zum Generieren von ID-Tokens gemeinsam mit Zugriffstokens bereitgestellt. Die erforderlichen Assembly-Richtlinien werden automatisch erstellt.`,
  [`Open a Directory`]: `Verzeichnis öffnen`,
  [`Open in external editor`]: `In externem Editor öffnen`,
  [`Open the server`]: `Server öffnen`,
  [`OpenAPI extensions`]: `OpenAPI-Erweiterungen`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect wird bei Gateway-Version 6000 noch nicht unterstützt`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `OpenID Connect-Einstellungen gelten nur, wenn die ausgewählten Bewilligungstypen einen oder mehrere der folgenden Werte enthalten: Implizit und Zugriffscode.`,
  [`Operation`]: `Operation`,
  [`Operation Id`]: `Operations-ID`,
  [`Operation switch`]: `Operationsswitch`,
  [`Operation Switch`]: `Operationsswitch`,
  [`Operations`]: `Operationen`,
  [`Operator build date`]: `Operatorbuilddatum`,
  [`Operator build tag`]: `Operatorbuild-Tag`,
  [`Operator version`]: `Operatorversion`,
  [`Optionally select a CA bundle`]: `Optional ein CA-Bundle auswählen`,
  [`Optimize schema definition`]: `Schemadefinition optimieren`,
  [`Optional`]: `Optional`,
  [`Or`]: `Oder`,
  [`Or create a new application and subscribe to the selected plan`]: `Oder erstellen Sie eine neue Anwendung und abonnieren Sie den ausgewählten Plan`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Oder erstellen Sie ein neues Produkt und veröffentlichen Sie es in Ihrem Sandbox-Katalog`,
  [`Order`]: `Reihenfolge`,
  [`Org`]: `Org`,
  [`Org owner`]: `Organisationseigner`,
  [`Organization`]: `Organisation`,
  [`Organization:`]: `Organisation:`,
  [`Organization information`]: `Informationen zur Organisation`,
  [`Organization Manager`]: `Organisationsmanager`,
  [`Organization has been updated.`]: `Organisation wurde aktualisiert.`,
  [`Organizations will be listed here.`]: `Die Organisationen werden hier aufgelistet.`,
  [`Otherwise`]: `Andernfalls`,
  [`Output`]: `Ausgabe`,
  [`Output Claims`]: `Ausgabeanforderungen`,
  [`Output array`]: `Ausgabearray`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Stammelement auch dann ausgeben, wenn es nicht erforderlich ist, um das XML-Dokument ordnungsgemäß zu formatieren.`,
  [`Output variable`]: `Ausgabevariable`,
  [`Override API consume types`]: `API-Konsumenttypen außer Kraft setzen`,
  [`Override API produce types`]: `API-Produkttypen außer Kraft setzen`,
  [`Override API security definitions`]: `API-Sicherheitsdefinitionen außer Kraft setzen`,
  [`Override rate limits`]: `Ratenbegrenzungen überschreiben`,
  [`Override default "provider" settings with configuration from this URL`]: `Standardeinstellungen für 'provider' durch Konfiguration aus dieser URL überschreiben`,
  [`Override default "provider" settings with configuration from this literal`]: `Standardeinstellungen für 'provider' mit Konfiguration aus diesem Literal überschreiben`,
  [`Override plan rate limits`]: `Planratenbegrenzungen überschreiben`,
  [`Override plan rate limits for individual operation`]: `Planratenbegrenzungen für eine einzelne Operation überschreiben`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Einladungszeitlimit für Verbraucherorganisationen mit Katalogzeitlimit überschreiben`,
  [`Overview`]: `Überblick`,
  [`Owner scope check`]: `Eignerbereichsüberprüfung`,
  [`Owner's Email`]: `E-Mail-Adresse des Eigners`,
  [`Owner's Name`]: `Name des Eigners`,
  [`Owner's email`]: `E-Mail-Adresse des Eigners`,
  [`Owns and administers the API provider organization`]: `Ist Eigner und Verwalter der API-Provider-Organisation`,
  [`Owns and administers the admin organization`]: `Ist Eigner und Verwalter der Verwaltungsorganisation`,
  [`Owns and administers the app developer organization`]: `Ist Eigner und Verwalter der App-Entwicklerorganisation`,
  [`Owns and administrates the app developer organization`]: `Ist Eigner und Verwalter der App-Entwicklerorganisation`,
  [`PARAMETER NAME`]: `PARAMETERNAME`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `PCRE zum Validieren der Zielgruppenanforderung (aud).`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `PCRE zum Validieren der Ausstelleranforderung (iss).`,
  [`PER`]: `PER`,
  [`Per`]: `Pro`,
  [`Plan`]: `Plan`,
  [`Plan:`]: `Plan:`,
  [`pending Consumer organization invitation`]: `anstehende Einladung für Konsumentenorganisation`,
  [`PLAN`]: `PLAN`,
  [`PLANS`]: `PLÄNE`,
  [`Policy`]: `Richtlinie`,
  [`policy`]: `Richtlinie`,
  [`POLICIES`]: `RICHTLINIEN`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUKT`,
  [`Product Plan`]: `Produktplan`,
  [`Product Plans`]: `Produktpläne`,
  [`Product Plans per page`]: `Produktpläne pro Seite`,
  [`Property name`]: `Eigenschaftsname`,
  [`Property type`]: `Eigenschaftstyp`,
  [`Property example`]: `Beispiel für Eigenschaft`,
  [`Property description`]: `Eigenschaftsbeschreibung`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Seite nicht gefunden`,
  [`Parameter name`]: `Parametername`,
  [`Parameter control`]: `Parametersteuerung`,
  [`Parameters`]: `Parameter`,
  [`Parent`]: `Übergeordnet`,
  [`Parse`]: `Parsing`,
  [`Parse settings URL reference`]: `URL-Referenz für Parsing-Einstellungen`,
  [`Parse settings literal configuration`]: `Literalkonfiguration für Parsing-Einstellungen`,
  [`Parse settings object reference`]: `Objektreferenz für Parsing-Einstellungen`,
  [`Parse the GraphQL query`]: `GraphQL-Abfrage analysieren`,
  [`Parse the response from the GraphQL backend server`]: `Antwort vom GraphQL-Back-End-Server analysieren`,
  [`Parse XML output`]: `XML-Ausgabe analysieren`,
  [`Pass-through`]: `Durchgriff`,
  [`Password`]: `Kennwort`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `Kennwort für Ihren Benutzeraccount in IBM API Connect geändert`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `Kennwort für Ihren Benutzeraccount im {portalTitle}-Developer Portal geändert`,
  [`Password context variable`]: `Kontextvariable für Kennwort`,
  [`Password is invalid. Re-enter the password.`]: `Das Kennwort ist ungültig. Geben Sie das Kennwort erneut ein.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `Anforderung zum Zurücksetzen des Kennworts für Ihren Benutzeraccount in IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Anforderung zum Zurücksetzen des Kennworts für Ihren Account im {catalog}-Developer Portal`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Kennwörter müssen jeweils ein Zeichen aus zwei der folgenden Kategorien enthalten: Kleinbuchstaben, Großbuchstaben, Ziffern und Interpunktion (z. B. !, $, #, %).`,
  [`Passwords must contain eight or more characters`]: `Kennwörter müssen acht oder mehr Zeichen enthalten`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Kennwörter müssen mindestens acht Zeichen enthalten, wobei jeweils ein Zeichen aus zwei der folgenden Kategorien enthalten sein muss: Kleinbuchstaben, Großbuchstaben, Ziffern und Interpunktion (z. B. !, $, #, %). Außerdem darf dasselbe Zeichen höchstens zwei Mal in Folge verwendet werden.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Kennwörter dürfen dasselbe Zeichen höchstens zweimal in Folge enthalten.`,
  [`Passwords should match`]: `Kennwörter müssen übereinstimmen`,
  [`Path`]: `Pfad`,
  [`Path parameters`]: `Pfadparameter`,
  [`Path name`]: `Pfadname`,
  [`Paths`]: `Pfade`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Pfade identifizieren die REST-Ressourcen, die von der API verfügbar gemacht werden. Eine Operation kombiniert einen Pfad mit einem HTTP-Verb, Parametern und Definitionen für Anforderungen und Antworten.`,
  [`Payments Authorization`]: `Zahlungsvollmacht`,
  [`Payment method`]: `Zahlungsmethode`,
  [`Pending`]: `Anstehend`,
  [`Pending State`]: `Wartestatus`,
  [`Pending approval to be published in catalog`]: `Genehmigung zum Veröffentlichen im Katalog steht an`,
  [`Pending approval to be staged in catalog`]: `Genehmigung zum Bereitstellen im Katalog steht an`,
  [`Pending consumer organization invitations`]: `Anstehende Einladungen für Konsumentenorganisationen`,
  [`View endpoints`]: `Endpunkte anzeigen`,
  [`View base endpoints for this API`]: `Basisendpunkte für diese API anzeigen`,
  [`View pending invitations`]: `Anstehende Einladungen anzeigen`,
  [`Permission Denied`]: `Berechtigung verweigert`,
  [`Permissions`]: `Berechtigungen`,
  [`Persistent Connection`]: `Persistente Verbindung`,
  [`Pin menu`]: `Menü pinnen`,
  [`PlainText`]: `PlainText`,
  [`Plan APIs`]: `Plan-APIs`,
  [`Plan burst limits`]: `Planburstbegrenzungen`,
  [`Plan by name`]: `Plan nach Name`,
  [`Plan Default`]: `Planstandard`,
  [`Plan Details`]: `Plandetails`,
  [`Plan migration targets`]: `Migrationsziele planen`,
  [`Plan rate limits`]: `Planratenbegrenzungen`,
  [`Plan name`]: `Planname`,
  [`Plan pricing`]: `Preistarif`,
  [`Plan title already exists`]: `Plantitel bereits vorhanden`,
  [`Plans`]: `Pläne`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Plattform-REST-API-Endpunkt für Administrator- und Provider-APIs`,
  [`Platform REST API endpoint for consumer APIs`]: `Plattform-REST-API-Endpunkt für Konsumenten-APIs`,
  [`Please choose a bind method`]: `Wählen Sie eine Bindungsmethode aus.`,
  [`Please choose an authentication method`]: `Wählen Sie eine Authentifizierungsmethode aus.`,
  [`Please contact the system administrator.`]: `Wenden Sie sich an den Systemadministrator.`,
  [`Please contact your administrator for support.`]: `Bitten Sie Ihren Administrator um Unterstützung.`,
  [`Please enter the reason.`]: `Geben Sie den Grund ein.`,
  [`Please enter your username`]: `Geben Sie Ihren Benutzernamen ein`,
  [`Please go to the space settings to manage API endpoints.`]: `Rufen Sie die Bereichseinstellungen auf, um API-Endpunkte zu verwalten.`,
  [`Please make sure the server is online and try again.`]: `Stellen Sie sicher, dass der Server online ist, und versuchen Sie es erneut.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Beachten Sie, dass durch die Inaktivierung des Aktivitätenprotokolls die Eigenschaften entfernt werden, die dem Inhaltstyp zugeordnet sind, der bei einem Erfolg oder Fehler protokolliert wird. Möchten Sie es wirklich inaktivieren?`,
  [`Please review the license for API Connect by accessing`]: `Überprüfen Sie die Lizenz für API Connect, indem Sie auf Folgendes zugreifen:`,
  [`Please select a TLS`]: `Wählen Sie ein TLS aus.`,
  [`Please select a TLS client profile`]: `Wählen Sie ein TLS-Clientprofil aus.`,
  [`Please select a group type`]: `Wählen Sie einen Gruppentyp aus.`,
  [`Please select an LDAP protocol version`]: `Wählen Sie eine LDAP-Protokollversion aus.`,
  [`Please select authentication method`]: `Wählen Sie eine Authentifizierungsmethode aus.`,
  [`Please select ciphers from the list below:`]: `Wählen Sie Verschlüsselungen aus der folgenden Liste aus:`,
  [`Please select consumer organization`]: `Wählen Sie eine Konsumentenorganisation aus`,
  [`Please select one Keystore from the list below:`]: `Wählen Sie einen Keystore aus der folgenden Liste aus:`,
  [`Please select one keystore from the list below:`]: `Wählen Sie einen Keystore aus der folgenden Liste aus:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Notieren Sie die Client-ID und den geheimen Schlüssel für diese neue Anwendung. Speichern Sie den geheimen Schlüssel, da er nicht erneut abgerufen werden kann. Er kann jedoch zurückgesetzt werden, falls erforderlich:`,
  [`Please try again in a few minutes.`]: `Versuchen Sie es in wenigen Minuten erneut.`,
  [`Please refresh catalog/space product list page and try again.`]: `Aktualisieren Sie die Seite 'Katalog-/Speicherplatzproduktliste' und versuchen Sie es erneut.`,
  [`Policies`]: `Richtlinien`,
  [`Policy Assemblies`]: `Richtlinienassemblys`,
  [`Policy assembly`]: `Richtlinienassembly`,
  [`Policy description.`]: `Richtlinienbeschreibung.`,
  [`Policy title`]: `Richtlinientitel`,
  [`Policy has been uploaded.`]: `Die Richtlinie wurde hochgeladen.`,
  [`Polish`]: `Polnisch`,
  [`Port`]: `Port`,
  [`Portal`]: `Portal`,
  [`Portal details`]: `Portaldetails`,
  [`Portal Director TLS client profile`]: `TLS-Clientprofil für Portal Director`,
  [`Portal Director keystore`]: `Keystore für Portal Director`,
  [`Portal Director truststore`]: `Truststore für Portal Director`,
  [`Portal service`]: `Portalservice`,
  [`Portal Service`]: `Portalservice`,
  [`Portal service name`]: `Portalservicename`,
  [`Portal service details`]: `Details des Portalservice`,
  [`Portal Service and URL`]: `Portalservice und URL`,
  [`Portal service {name} was successfully created.`]: `Portalservice {name} wurde erfolgreich erstellt.`,
  [`Portal service {name} was successfully updated.`]: `Portalservice {name} wurde erfolgreich aktualisiert.`,
  [`Portal Service {arg} has been removed.`]: `Portal-Service {arg} wurde entfernt.`,
  [`Portal site`]: `Portalsite`,
  [`Portal URL`]: `Portal-URL`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Portalsite-URLs dürfen nicht länger als 200 Zeichen sein`,
  [`Portal website URL`]: `Portalwebsite-URL`,
  [`Portal and Gateway Settings`]: `Portal- und Gateway-Einstellungen`,
  [`Portal has been created`]: `Portal wurde erstellt`,
  [`Portal has been created.`]: `Portal wurde erstellt.`,
  [`Portal has been deleted`]: `Portal wurde gelöscht`,
  [`Portals`]: `Portale`,
  [`Portuguese`]: `Portugiesisch`,
  [`Post Response`]: `Nach der Antwort`,
  [`Post-Invoke Hook`]: `Nachaufruf-Hook`,
  [`Preserve subscriptions on re-publish`]: `Abonnements bei erneuter Veröffentlichung beibehalten`,
  [`Preserve subscriptions on re-publish product`]: `Abonnements bei erneuter Veröffentlichung des Produkts beibehalten`,
  [`Pre-Invoke Hook`]: `Voraufruf-Hook`,
  [`Pre-Request`]: `Vor der Anforderung`,
  [`Preference`]: `Vorgabe`,
  [`Prefix`]: `Präfix`,
  [`Preparing API`]: `API wird vorbereitet`,
  [`Preserved request header`]: `Beibehaltener Anforderungsheader`,
  [`preserved request header`]: `Beibehaltener Anforderungsheader`,
  [`Preserved response header`]: `Beibehaltener Antwortheader`,
  [`preserved response header`]: `Beibehaltener Antwortheader`,
  [`Preview`]: `Vorschau`,
  [`Preview of login screen registry prompt`]: `Vorschau der Registry-Eingabeaufforderung der Anmeldeanzeige`,
  [`Previous`]: `Zurück`,
  [`Previous page`]: `Vorherige Seite`,
  [`Prettify`]: `Prettify`,
  [`Price per month`]: `Preis pro Monat`,
  [`Private`]: `Privat`,
  [`Private Claims`]: `Privatanforderung`,
  [`Private key & public key`]: `Privater Schlüssel und öffentlicher Schlüssel`,
  [`Private Key or Shared Secret for Decrypt`]: `Privater Schlüssel oder geheimer Schlüssel für gemeinsame Nutzung zum Entschlüsseln`,
  [`Private Key or Shared Secret for Sign`]: `Privater Schlüssel oder geheimer Schlüssel für gemeinsame Nutzung zum Signieren`,
  [`Private key detected`]: `Privater Schlüssel erkannt`,
  [`Private key password`]: `Privates Schlüsselkennwort`,
  [`Processed`]: `Verarbeitet`,
  [`Problem`]: `Problem`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Fehler beim Abrufen von Tracedaten. Versuchen Sie es erneut. Fehlerdetails finden Sie in der Konsole.`,
  [`Produced Media Types`]: `Produzierte Medientypen`,
  [`Produces`]: `Produziert`,
  [`Product`]: `Produkt`,
  [`Product:`]: `Produkt:`,
  [`Product / Plan`]: `Produkt/Plan`,
  [`Product APIs`]: `Produkt-APIs`,
  [`Product editor`]: `Produkteditor`,
  [`Product lifecycle`]: `Produktlebenszyklus`,
  [`Product Lifecycle`]: `Produktlebenszyklus`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Produktname darf nicht mit '-auto-product' enden. Ändern Sie den Namen und wiederholen Sie den Import.`,
  [`Product setup`]: `Produktkonfiguration`,
  [`Product details`]: `Produktdetails`,
  [`Product documentation`]: `Produktdokumentation`,
  [`Product has been created.`]: `Produkt wurde erstellt`,
  [`Product has been deleted`]: `Produkt wurde gelöscht`,
  [`Product has been updated`]: `Produkt wurde aktualisiert`,
  [`Product has been updated.`]: `Produkt wurde aktualisiert.`,
  [`Product has not been created!`]: `Produkt wurde nicht erstellt!`,
  [`Product has not been published!`]: `Produkt wurde nicht veröffentlicht!`,
  [`Product has not been updated!`]: `Produkt wurde nicht aktualisiert!`,
  [`Product has not been staged!`]: `Produkt wurde nicht bereitgestellt!`,
  [`Product information`]: `Produktinformationen`,
  [`Product summary details`]: `Details zur Produktzusammenfassung`,
  [`Product version`]: `Produktversion`,
  [`Product visibility`]: `Produktsichtbarkeit`,
  [`Product-Approval`]: `Produktgenehmigung`,
  [`Product-Drafts`]: `Produktentwürfe`,
  [`Product/plan`]: `Produkt/Plan`,
  [`Production`]: `Produktion`,
  [`Production mode`]: `Produktionsmodus`,
  [`Production endpoints`]: `Produktionsendpunkte`,
  [`Products`]: `Produkte`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Produkte müssen in einem Katalog bereitgestellt und dann veröffentlicht werden, damit sie für Anwendungsentwickler verfügbar sind. Ein Katalog dient als logische Partition des Gateways und des Developer Portals. Die URL für API-Aufrufe und das Developer Portal sind spezifisch für einen bestimmten Katalog. In einer typischen Konfiguration verwendet eine API-Provider-Organisation einen Entwicklungskatalog zum Testen von APIs, die sich in der Entwicklung befinden, und einen Produktionskatalog zum Hosten von APIs, die zur vollen Verwendung bereit sind.`,
  [`Profile`]: `Profil`,
  [`Profile rule`]: `Profilregel`,
  [`Promote to production`]: `Zu Produktion hochstufen`,
  [`Proof Key for Code Exchange`]: `Nachweisschlüssel für Codeaustausch`,
  [`Proof Key for Code Exchange (PKCE)`]: `PKCE (Proof Key for Code Exchange)`,
  [`Properties`]: `Eigenschaften`,
  [`Property`]: `Eigenschaft`,
  [`Property Description`]: `Eigenschaftsbeschreibung`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `Der Eigenschaft {{target}} kann durch eine Eingabe weiter unten ein statischer Wert zugeordnet werden.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `Eigenschaft {{target}} ist derzeit {{from}} zugeordnet - die Zuordnung kann unten entfernt werden.`,
  [`Property Value is Base64 Encoded`]: `Eigenschaftswert ist Base64 codiert`,
  [`Protocol`]: `Protokoll`,
  [`Protocols`]: `Protokolle`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protokolle, die zum Aufrufen der API verwendet werden können (nur https wird für vom Gateway erzwungene APIs unterstützt)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `OAuth {oauthVer}-Berechtigungsnachweise angeben, um Benutzer zu authentifizieren und Zugriff auf die APIs Ihres OpenID-Providers zu erhalten`,
  [`Provide a schema`]: `Schema angeben`,
  [`Provide GraphQL schema`]: `GraphQL-Schema angeben`,
  [`Provide the URL to the backend GraphQL server`]: `URL für den Back-End-GraphQL-Server angeben`,
  [`Provide your email address to receive password reset instructions`]: `Geben Sie Ihre E-Mail-Adresse an, um Anweisungen zum Zurücksetzen des Kennworts zu erhalten`,
  [`Provide your new password below`]: `Geben Sie unten Ihr neues Kennwort an.`,
  [`Provider endpoint`]: `Provider-Endpunkt`,
  [`Provider Information`]: `Providerinformationen`,
  [`Provider Organization`]: `Provider-Organisation`,
  [`Provider Organizations`]: `Provider-Organisationen`,
  [`Provider Type`]: `Providertyp`,
  [`Provider organizations`]: `Provider-Organisationen`,
  [`Provider type`]: `Providertyp`,
  [`Provider org invite failed`]: `Einladen der Provider-Organisation fehlgeschlagen`,
  [`Provider organization {name} created`]: `Provider-Organisation {name} erstellt`,
  [`Provider organizations will be listed here`]: `Provider-Organisationen werden hier aufgelistet`,
  [`Provider-Org`]: `Provider-Organisation`,
  [`Provides contact information for the exposed API.`]: `Stellt Kontaktinformationen für die verfügbare API bereit.`,
  [`Provides license information for the exposed API.`]: `Stellt Lizenzinformationen für die verfügbare API bereit.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Stellt Metadaten zur API bereit. Die Metadaten können bei Bedarf von den Clients verwendet werden.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `Die Bereitstellung des Developer Portals wurde eingeleitet. Es dauert einige Minuten, bis sie abgeschlossen ist. Nach dem Abschluss erhalten Sie eine E-Mail mit einem Link zum Festlegen des Kennworts für den Portal-Administratoraccount.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `Die Aktualisierung der Developer Portal-URL wurde eingeleitet. Der Vorgang dauert einige Minuten.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `Proxy-URL`,
  [`Public`]: `Öffentlich`,
  [`Public Key or Shared Secret for Encrypt`]: `Öffentlicher Schlüssel oder geheimer Schlüssel für gemeinsame Nutzung zum Verschlüsseln`,
  [`Publish`]: `Veröffentlichen`,
  [`Publish API`]: `API veröffentlichen`,
  [`Publish a product from the Develop section.`]: `Veröffentlichen Sie ein Produkt aus dem Abschnitt 'Entwickeln'.`,
  [`Publish product`]: `Produkt veröffentlichen`,
  [`Publish to`]: `Veröffentlichen in`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Veröffentlichen Sie Produkte in diesem Katalog, um Abonnements für sie zu erstellen.`,
  [`Publish target`]: `Ziel veröffentlichen`,
  [`Publish this API to the Sandbox catalog.`]: `Diese API im Sandbox-Katalog veröffentlichen.`,
  [`Publish to specific gateway services`]: `In bestimmten Gateway-Services veröffentlichen`,
  [`Publish using a new product`]: `Veröffentlichen mit einem neuen Produkt`,
  [`Publish using an existing product`]: `Veröffentlichen mit einem vorhandenen Produkt`,
  [`Publishable Key`]: `Veröffentlichbarer Schlüssel`,
  [`Publishable key`]: `Veröffentlichbarer Schlüssel`,
  [`Publishable key tip`]: `Tipp zum veröffentlichbaren Schlüssel`,
  [`Published`]: `Veröffentlicht`,
  [`Published product`]: `Produkt veröffentlicht`,
  [`Publishing`]: `Veröffentlichen`,
  [`Publishing New API`]: `Neue API wird veröffentlicht`,
  [`Publish (preserve subscriptions)`]: `Veröffentlichen (Abonnements beibehalten)`,
  [`Publish|permission`]: `Veröffentlichen`,
  [`Put online`]: `Online stellen`,
  [`Query`]: `Abfrage`,
  [`Queryparam Encode`]: `Abfrageparameter verschlüsseln`,
  [`Queued`]: `In der Warteschlange`,
  [`Queued events: **{queued}**`]: `Ereignisse in der Warteschlange: **{queued}**`,
  [`Retry blocked jobs`]: `Blockierte Jobs wiederholen`,
  [`REQUIRED`]: `ERFORDERLICH`,
  [`ROLES`]: `ROLLEN`,
  [`Rate limit`]: `Ratenbegrenzung`,
  [`Rate Limit`]: `Ratenbegrenzung`,
  [`Rate Limit Name`]: `Name der Ratenbegrenzung`,
  [`Rate limits`]: `Ratenbegrenzungen`,
  [`Rate limit name can't be a duplicate`]: `Der Name der Ratenbegrenzung darf kein Duplikat sein.`,
  [`Rate limit name can't be empty`]: `Der Name der Ratenbegrenzung darf nicht leer sein`,
  [`Rate limit overrides`]: `Ratenbegrenzungsüberschreibungen`,
  [`Rate limit time interval can't be 0`]: `Das Zeitintervall der Ratenbegrenzung darf nicht 0 sein`,
  [`Rate limit time interval can't be empty`]: `Das Zeitintervall der Ratenbegrenzung darf nicht leer sein`,
  [`Rate limit time interval unit must be selected`]: `Die Zeitintervalleinheit für die Ratenbegrenzung muss ausgewählt sein`,
  [`Rate limit value can't be empty`]: `Der Wert der Ratenbegrenzung darf nicht leer sein`,
  [`Rate limit value can't be negative`]: `Der Wert der Ratenbegrenzung darf nicht negativ sein`,
  [`Re-stage`]: `Erneut bereitstellen`,
  [`Re-stage product`]: `Produkt erneut bereitstellen`,
  [`Read only`]: `Schreibgeschützt`,
  [`Read-Only`]: `Schreibgeschützt`,
  [`Realized`]: `Realisiert`,
  [`Reason`]: `Ursache`,
  [`Reason for declining`]: `Grund für die Ablehnung`,
  [`Recommendations`]: `Empfehlungen`,
  [`Recommended configuration`]: `Empfohlene Konfiguration`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Notieren Sie die Client-ID und den geheimen Clientschlüssel, die unten angezeigt werden, für die spätere Verwendung. Der geheime Clientschlüssel kann später nicht mehr angezeigt werden.`,
  [`Recommendations applied`]: `Angewendete Empfehlungen`,
  [`Redact`]: `Neu bearbeiten`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Bearbeiten Sie angegebene Felder im Anforderungshauptteil, im Antworthauptteil und in den Aktivitätenprotokollen neu oder entfernen Sie sie. Definieren Sie das neu zu bearbeitende oder zu entfernende Feld durch Angeben eines XPath-Ausdrucks.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Bearbeiten Sie angegebene Felder im Anforderungshauptteil, im Antworthauptteil und in den Aktivitätenprotokollen neu oder entfernen Sie sie. Definieren Sie das neu zu bearbeitende oder zu entfernende Feld durch Angeben eines XPath- oder JSONata-Ausdrucks.`,
  [`Redaction`]: `Neubearbeitung`,
  [`Redactions`]: `Neubearbeitungen`,
  [`Redirect`]: `Umleiten`,
  [`Redirect URI`]: `Umleitungs-URI`,
  [`Redirect URL`]: `Umleitungs-URL`,
  [`Redirect through Portal`]: `Über Portal umleiten`,
  [`Redirect time limit`]: `Zeitlimit für Umleitung`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `Das Zeitlimit für die Weiterleitung muss zwischen 10 und 600 Sekunden liegen`,
  [`References`]: `Referenzen`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Zusätzliche Anweisungen finden Sie in den [IBM Cloud Docs]({link}).`,
  [`Referral`]: `Verweis`,
  [`Refresh`]: `Aktualisieren`,
  [`Refresh Token`]: `Aktualisierungstoken`,
  [`Refresh button`]: `Schaltfläche 'Aktualisierung'`,
  [`Refresh products`]: `Produkte aktualisieren`,
  [`Refresh results from server`]: `Ergebnisse vom Server aktualisieren`,
  [`Refresh token`]: `Token aktualisieren`,
  [`Refresh token time to live`]: `Lebensdauer des Aktualisierungstokens`,
  [`Refresh token time-to-live`]: `Lebensdauer des Aktualisierungstokens`,
  [`Refresh token time-to-live (seconds)`]: `Lebensdauer des Aktualisierungstokens (Sekunden)`,
  [`Refresh token TTL has been changed`]: `Lebensdauer des Aktualisierungstokens wurde geändert`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Lebensdauer des Aktualisierungstokens ist **{seconds} Sekunden ({time})**`,
  [`Refresh token time to live is **off**`]: `Lebensdauer des Aktualisierungstokens ist **abgelaufen**`,
  [`Refresh tokens`]: `Aktualisierungstokens`,
  [`Refreshing`]: `Aktualisierung läuft`,
  [`Refreshing...`]: `Aktualisierung läuft...`,
  [`Region`]: `Bereich`,
  [`Register`]: `Registrieren`,
  [`Register Gateways`]: `Gateways registrieren`,
  [`Register Service`]: `Service registrieren`,
  [`Register gateways`]: `Gateways registrieren`,
  [`Register service`]: `Service registrieren`,
  [`Register a service`]: `Service registrieren`,
  [`Register new services and manage existing services`]: `Registrieren Sie neue Services und verwalten Sie vorhandene Services.`,
  [`Register remote gateway`]: `Fernes Gateway registrieren`,
  [`Registered Admin Member`]: `Registriertes Admin-Mitglied`,
  [`Registered Provider Org Owner or Member`]: `Registrierter Eigner oder registriertes Mitglied einer Provider-Organisation`,
  [`Registered client information on the OpenID provider`]: `Registrierte Clientinformationen zum OpenID-Anbieter`,
  [`Registration completed successfully`]: `Registrierung erfolgreich abgeschlossen`,
  [`Registration failed.`]: `Registrierung fehlgeschlagen.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Relative Pfade zu den einzelnen Endpunkten. Sie müssen relativ zu dem 'basePath' sein.`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `Analysekonfiguration entfernen`,
  [`Remote API Connect`]: `Remote API Connect`,
  [`Remote login`]: `Fern anmelden`,
  [`Remote publish`]: `Fern veröffentlichen`,
  [`Remove`]: `Entfernen`,
  [`Remove Burst Limit`]: `Burstbegrenzung entfernen`,
  [`Remove constraints`]: `Bedingungen entfernen`,
  [`Remove Count Limit`]: `Zählergrenze entfernen`,
  [`Remove gateway extension`]: `Gateway-Erweiterung entfernen`,
  [`Remove product`]: `Produkt entfernen`,
  [`Remove Rate limit`]: `Ratenbegrenzung entfernen`,
  [`Remove the invalid constraints`]: `Ungültige Bedingungen entfernen`,
  [`Remove the invalid constraints from`]: `Ungültige Bedingungen entfernen von`,
  [`Remove action`]: `Aktion entfernen`,
  [`Remove case`]: `Fall entfernen`,
  [`Remove catch`]: `Catch entfernen`,
  [`Remove filter`]: `Filter entfernen`,
  [`Remove input`]: `Eingabe entfernen`,
  [`Remove mapping`]: `Zuordnung entfernen`,
  [`Remove output`]: `Ausgabe entfernen`,
  [`Remove static value`]: `Statischen Wert entfernen`,
  [`Removed`]: `Entfernt`,
  [`Removed by user`]: `Entfernt vom Benutzer`,
  [`Reorder`]: `Neu ordnen`,
  [`Repeat`]: `Wiederholen`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Den API-Aufruf eine festgelegte Anzahl von Versuchen oder bis auf 'Stoppen' geklickt wird wiederholen`,
  [`Replace`]: `Ersetzen`,
  [`Replace Free Trial with`]: `Kostenlose Testversion ersetzen durch`,
  [`Replace Gold with`]: `Gold ersetzen durch`,
  [`Replace GraphQL schema`]: `GraphQL-Schema ersetzen`,
  [`Replace plans`]: `Pläne ersetzen`,
  [`Replace private key & public key`]: `Privaten Schlüssel und öffentlichen Schlüssel ersetzen`,
  [`Replace Product`]: `Produkte ersetzen`,
  [`Replace product`]: `Produkt ersetzen`,
  [`Replace schema`]: `Schema ersetzen`,
  [`Replace Silver with`]: `Silber ersetzen durch`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Ersetzen Sie den aktuellen Eigner, indem Sie den neuen Eigner aus der Vorschlagsliste auswählen. Weisen Sie dem ersetzten Eigner neue Berechtigungen zu, damit er weiterhin arbeiten kann.`,
  [`Replace|permission`]: `Ersetzen`,
  [`Republish`]: `Erneut veröffentlichen`,
  [`Republish product`]: `Produkt erneut veröffentlichen`,
  [`Request`]: `Anforderung`,
  [`Request catch`]: `Anforderungscatch`,
  [`Request endpoint`]: `Anforderungsendpunkt`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Anforderung zum {action} des API-Produkts im Katalog {catalog} genehmigt`,
  [`Request approved to {{action}} app {{appName}}`]: `Anforderung zum {action} der App {appName} genehmigt`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Anforderung zum {action} des API-Produkts im Katalog {catalog} verweigert`,
  [`Request denied to {{action}} app {{appName}}`]: `Anforderung zum {action} der App {appName} verweigert`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Anforderung zur Genehmigung des Abonnierens eines API-Produkts im {catalog}-Katalog`,
  [`Request for approval to {{action}} app {{appName}}`]: `Anforderung der Genehmigung zum {action} der App {appName}`,
  [`Request headers`]: `Anforderungsheader`,
  [`Request pending to promote application to production`]: `Anstehende Anforderung zum Hochstufen der Anwendung in die Produktion`,
  [`Request query`]: `Anforderungsabfrage`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Anforderung zum {action} des API-Produkts im Katalog {catalog} erhalten`,
  [`Request received to {{action}} app {{appName}}`]: `Anforderung zum {action} der App {appName} erhalten`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Anforderung zum Abonnieren eines API-Produkts im {catalog}-Katalog zurückgezogen`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Anforderung zum {action} eines API-Produkts im {catalog}-Katalog zurückgezogen`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Anforderung zurückgezogen für {action} von App {appName}`,
  [`Requested approvals`]: `Angeforderte Genehmigungen`,
  [`Requested By`]: `Angefordert von`,
  [`Requested To`]: `Angefordert für`,
  [`Require`]: `Erfordern`,
  [`Require applications to provide a client ID`]: `Anwendungen müssen eine Client-ID angeben`,
  [`Require approval for all new self service development accounts`]: `Genehmigung für alle neuen Self-Service-Entwicklungskonten erforderlich`,
  [`Required`]: `Erforderlich`,
  [`Required for rate limiting`]: `Erforderlich für Ratenbegrenzung`,
  [`Required must be checked off when location is in path.`]: `'Erforderlich' muss abgewählt werden, wenn die Position im Pfad angegeben ist.`,
  [`Required path`]: `Erforderlicher Pfad`,
  [`Resend Invite`]: `Einladung erneut senden`,
  [`Resend invitation`]: `Einladung erneut senden`,
  [`Resend invite`]: `Einladung erneut senden`,
  [`Reset`]: `Zurücksetzen`,
  [`Reset defaults`]: `Standardwerte zurücksetzen`,
  [`Reset Notification template`]: `Benachrichtigungsvorlage zurücksetzen`,
  [`Reset password`]: `Kennwort zurücksetzen`,
  [`Reset password TTL has been changed`]: `TTL zum Zurücksetzen des Kennworts wurde geändert`,
  [`Reset password time to live`]: `Lebensdauer zum Zurücksetzen des Kennworts`,
  [`Reset password time-to-live`]: `Lebensdauer zum Zurücksetzen des Kennworts`,
  [`Reset password email has been sent`]: `E-Mail zum Zurücksetzen des Kennworts wurde gesendet`,
  [`Reset password email has not been sent`]: `E-Mail zum Zurücksetzen des Kennworts wurde nicht gesendet`,
  [`Reset password invitation timeout`]: `Zeitlimit für Einladung zum Zurücksetzen des Kennworts`,
  [`Reset to default preferences`]: `Auf Standardvorgaben zurücksetzen`,
  [`Resolve API Connect variable references`]: `Variablenreferenzen für API Connect auflösen`,
  [`Resolve XML input data type`]: `XML-Eingabedatentyp auflösen`,
  [`resolver`]: `Resolver`,
  [`Resource group`]: `Ressourcengruppe`,
  [`Resource Owner`]: `Ressourceneigner`,
  [`Resource owner password grant`]: `Erteilung des Kennworts für Ressourceneigner`,
  [`Resource owner revocation`]: `Widerruf des Ressourceneigners`,
  [`Resource owner revocation path`]: `Widerrufspfad für Ressourceneigner`,
  [`Resource Owner Security`]: `Sicherheit des Ressourceneigners`,
  [`Resource owner - JWT`]: `Ressourceneigner - JWT`,
  [`Resource owner - Password`]: `Ressourceneigner - Kennwort`,
  [`Resource owner password`]: `Kennwort des Ressourceneigners`,
  [`Resources`]: `Ressourcen`,
  [`Response`]: `Antwort`,
  [`Response catch`]: `Antwortcatch`,
  [`Response Definition`]: `Antwortdefinition`,
  [`Response Time`]: `Antwortzeit`,
  [`Response type`]: `Antworttyp`,
  [`Response context variables`]: `Antwortkontextvariablen`,
  [`Response headers`]: `Antwortheader`,
  [`Response must have a description`]: `Die Antwort muss über eine Beschreibung verfügen`,
  [`Response must have a schema`]: `Die Antwort muss über ein Schema verfügen`,
  [`Response must have a status code`]: `Antwort muss einen Statuscode aufweisen`,
  [`Response object variable`]: `Antwortobjektvariable`,
  [`Response time`]: `Antwortzeit`,
  [`Response time: {{value}}ms`]: `Antwortzeit: {{value}}ms`,
  [`Result of removing`]: `Ergebnis des Entfernens`,
  [`Restrict HTTP transactions to version 1.0.`]: `HTTP-Transaktionen auf Version 1.0 beschränken`,
  [`Restrict to HTTP 1.0`]: `Auf HTTP 1.0 beschränken`,
  [`Retire`]: `Zurückziehen`,
  [`Retire product`]: `Produkt zurückziehen`,
  [`Retired`]: `Zurückgezogen`,
  [`Retire|permission`]: `Zurückziehen`,
  [`Return V5 Responses`]: `V5-Antworten zurückgeben`,
  [`Retry selected requests`]: `Ausgewählte Anforderungen wiederholen`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Ergebnisse für Standard-GraphQL-Introspektionsabfragen von GraphQL-Proxy zurückgeben`,
  [`Return third-party access_token`]: `access_token von Drittanbietern zurückgeben`,
  [`Return third-party access_token with the token response`]: `access_token von Drittanbietern mit der Token-Antwort zurückgeben`,
  [`Return third-party id_token`]: `id_token eines Drittanbieters zurückgeben`,
  [`Return third-party id_token with the token response`]: `id_token von Drittanbietern mit der Token-Antwort zurückgeben`,
  [`Return to Login`]: `Zurück zur Anmeldung`,
  [`Reveal in finder`]: `Im Finder sichtbar machen`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Überprüfen und bestimmen Sie, was im Schema angezeigt oder ausgeblendet werden soll. Es können zusätzliche Änderungen erforderlich sein, damit das Schema gültig bleibt.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Überprüfen Sie alle Warnungen. Wir helfen Ihnen, Ihr Schema später im Editor mit intelligenten Empfehlungen zu verbessern.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Überprüfen Sie alle Warnungen und wenden Sie unsere intelligenten Empfehlungen an, um Ihr Schema zu verbessern und Ihr Back-End zu schützen.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Überprüfen Sie die Basis-URL des API-Aufrufendpunkts und geben Sie die Domäne an, die vom Gateway verarbeitet wird.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Überprüfen Sie den Gateway-Typ, den Titel, den Namen und die Zusammenfassung. Verwenden Sie beim Aufrufen von API Connect-Befehlen und -APIs den Gateway-Namen.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Sehen Sie sich die Zusammenfassung der Änderungen an, die zusätzliche Änderungen enthalten kann, damit das Schema gültig bleibt.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Überprüfen Sie die Warnungen, die wir in Ihrem Schema gefunden haben. Wir helfen Ihnen später im Editor, Ihr Schema zu verbessern und Ihr Back-End mit intelligenten Empfehlungen zu schützen.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Überprüfen Sie die Warnungen, die wir in Ihrem Schema gefunden haben. Wir helfen Ihnen, Ihr Schema zu verbessern und Ihr Back-End mit intelligenten Empfehlungen zu schützen.`,
  [`Reviewed schema, paths and operations`]: `Schema, Pfade und Operationen überprüft`,
  [`Revocation protocol`]: `Widerrufsprotokoll`,
  [`Revoke token`]: `Token widerrufen`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL-Docs`,
  [`Role`]: `Rolle`,
  [`Role default`]: `Rollenstandardwert`,
  [`Role default has been deleted`]: `Rollenstandardwert wurde gelöscht`,
  [`Role defaults`]: `Rollenstandardwerte`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Rollenstandardwerte sind vorkonfigurierte Rollenvorlagen, die zum Bestimmen der Standardrollen verwendet werden, die beim Erstellen einer neuen Organisation verfügbar sind.`,
  [`Role has been created`]: `Rolle wurde erstellt`,
  [`Role has been deleted`]: `Rolle wurde gelöscht`,
  [`Role has been updated`]: `Rolle wurde aktualisiert`,
  [`Roles`]: `Rollen`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Rollen (und die ihnen zugewiesenen Berechtigungen) steuern den Zugriff auf Funktionen in API Connect. Die Verwaltungsorganisationsrollen gelten nur für Personen, die Cloud Manager verwenden.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Rollen und Berechtigungen steuern den Zugriff auf die API Manager-Funktionen.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Rollen und die ihnen zugehörigen Berechtigungen steuern den Zugriff auf Funktionen und die Möglichkeit, Tasks in API Connect auszuführen.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Rollen und die ihnen zugehörigen Berechtigungen steuern den Zugriff auf Funktionen und die Möglichkeit, Tasks in der Benutzerschnittstelle von API Manager auszuführen.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Rollen und die ihnen zugehörigen Berechtigungen steuern den Zugriff auf Funktionen und die Möglichkeit, Tasks auszuführen.`,
  [`Root`]: `Stammverzeichnis`,
  [`Root XML Element Name`]: `Stamm-XML-Elementname`,
  [`Run the following command in the CLI to register the credentials:`]: `Führen Sie den folgenden Befehl in der Befehlszeilenschnittstelle aus, um die Berechtigungsnachweise zu registrieren:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Führen Sie den folgenden Befehl in der Befehlszeilenschnittstelle aus, um die Toolkit-Berechtigungsnachweise zu registrieren:`,
  [`Running`]: `Aktiv`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Laufzeitvariable, die die JWK zum Entschlüsseln des JWT enthält.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Laufzeitvariable, die die JWK zum Verschlüsseln des JWT enthält.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Laufzeitvariable, die die JWK zum Signieren des JWT enthält.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Laufzeitvariable, die die JWK zum Überprüfen der Signatur enthält.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Laufzeitvariable, aus der eine gültige JSON-Anforderungsgruppe abgerufen werden kann.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Laufzeitvariable, aus der die Zeichenfolge für die Zielgruppenanforderung (aud) abgerufen werden kann. Mehrere Variablen werden durch eine durch Kommata getrennte Zeichenfolge festgelegt.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Laufzeitvariable, aus der die Zeichenfolge für die Ausstelleranforderung (iss) abgerufen werden kann. Diese Anforderung stellt den Principal dar, der das JWT ausgestellt hat.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Laufzeitvariable, aus der die Zeichenfolge für die Betreffanforderung (sub) abgerufen werden kann.`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Laufzeitvariable, in der das generierte JWT gespeichert werden soll. Falls nicht festgelegt, wird das JWT im Berechtigungsheader als Trägertoken gespeichert.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Laufzeitvariable, die den Namen des authentifizierten Benutzers enthält. Das LTPA-Token wird mit dieser Eigenschaft als Benutzer generiert. Beispielsweise mit $(client.app.id), $(oauth.resource-owner) oder mit einer Laufzeitvariable, die in einer set-variable-Richtlinie konfiguriert ist.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `Laufzeitvariable, der die gesamte Anforderungsgruppe im JWT zugeordnet wird.`,
  [`Russian`]: `Russisch`,
  [`Scalar`]: `Scalar`,
  [`Schema`]: `Schema`,
  [`SCHEMA`]: `SCHEMA`,
  [`SCOPE`]: `BEREICH`,
  [`SCOPES`]: `BEREICHE`,
  [`SECURITY DEFINITIONS`]: `SICHERHEITSDEFINITIONEN`,
  [`Service`]: `Service`,
  [`Service state:`]: `Servicestatus:`,
  [`SERVICE TYPE`]: `SERVICETYP`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1-Lizenz`,
  [`SOURCE`]: `QUELLE`,
  [`SSL Profile`]: `SSL-Profil`,
  [`State`]: `Status`,
  [`STATUS`]: `STATUS`,
  [`Status Code`]: `Statuscode`,
  [`STATUS CODE`]: `STATUSCODE`,
  [`SUBMITTED`]: `EINGEREICHT`,
  [`SUBSCRIBERS`]: `ABONNENTEN`,
  [`Summary`]: `Zusammenfassung`,
  [`Same as product`]: `Entspricht Produkt`,
  [`Sample ID token signing key (RS256)`]: `Beispiel für ID-Token-Signierschlüssel (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Beispiel für ID-Token-Validierungsschlüssel (RS256)`,
  [`Sample OAuth symmetric key value`]: `Beispiel für symmetrischen OAuth-Schlüsselwert`,
  [`Sample URL: https://api.catalog.org.host/`]: `Beispiel-URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `Beispiel-URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Beispiel für Bereichsbeschreibung 1`,
  [`Sandbox - Overview`]: `Sandbox - Übersicht`,
  [`Sandbox Catalog`]: `Sandbox-Katalog`,
  [`Sandbox Catalog User Registry`]: `Benutzerregistry des Sandbox-Katalogs`,
  [`Sandbox Default TLS client profile`]: `Standard-TLS-Clientprofil für Sandbox`,
  [`Sandbox Default TLS Client Profile`]: `Standard-TLS-Clientprofil für Sandbox`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Sandbox-Katalog muss in einer Provider-Organisation immer vorhanden sein.`,
  [`Save`]: `Speichern`,
  [`Save API as a new version`]: `API als neue Version speichern`,
  [`Save Product as a new version`]: `Produkt als neue Version speichern`,
  [`Save and Edit`]: `Speichern und bearbeiten`,
  [`Save and republish`]: `Speichern und erneut veröffentlichen`,
  [`Save as a new version`]: `Als neue Version speichern`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Speichern Sie den geheimen Clientschlüssel (er kann anschließend aus Sicherheitsgründen nicht mehr abgerufen werden)`,
  [`Save this product as a new version`]: `Dieses Produkt als neue Version speichern`,
  [`Saved`]: `Gespeichert`,
  [`Saving assembly...`]: `Assembly wird gespeichert...`,
  [`Saving...`]: `Speichern...`,
  [`Schema Type`]: `Schematyp`,
  [`Schema as JSON`]: `Schema als JSON`,
  [`Schema as XML`]: `Schema als XML`,
  [`Schema as YAML`]: `Schema als YAML`,
  [`Schema change`]: `Schemaänderung`,
  [`Schema changes`]: `Schemaänderungen`,
  [`Schema definition circular reference limit`]: `Zirkelbezugsgrenzwert für Schemadefinition`,
  [`Schema name`]: `Schemaname`,
  [`Schema warnings`]: `Schemawarnungen`,
  [`Schema definition`]: `Schemadefinition`,
  [`Scheme`]: `Schema`,
  [`Schemes`]: `Schemas`,
  [`Scope`]: `Bereich`,
  [`Scopes`]: `Bereiche`,
  [`Scopes cannot be empty`]: `Bereiche dürfen nicht leer sein`,
  [`Search`]: `Suchen`,
  [`Search DN`]: `DN suchen`,
  [`Search in the field above above and select to add provider organizations`]: `Durchsuchen Sie die Felder oben und treffen Sie eine Auswahl, um Provider-Organisationen hinzuzufügen`,
  [`Search Limit`]: `Grenzwert suchen`,
  [`Search Organization owner from`]: `Nach Organisationseigner suchen in`,
  [`Search orgs/groups`]: `Organisationen/Gruppen suchen`,
  [`Search Owner`]: `Eigner suchen`,
  [`Search assemblies`]: `Assemblys suchen`,
  [`Search for`]: `Suchen nach`,
  [`Search for provider organizations`]: `Nach Provider-Organisationen suchen`,
  [`Search for Owner`]: `Nach Eigner suchen`,
  [`Search for a new member to become the new owner.`]: `Suchen Sie nach einem neuen Mitglied, um es als neuen Eigner einzusetzen.`,
  [`Search for an existing member to become the new owner.`]: `Suchen Sie nach einem vorhandenen Mitglied, um es als neuen Eigner einzusetzen.`,
  [`Search for new owner`]: `Nach neuem Eigner suchen`,
  [`Search for owner from:`]: `Nach Eigner suchen in:`,
  [`Search for the member`]: `Nach Mitglied suchen`,
  [`Search for title or name or version`]: `Nach Titel oder Name oder Version suchen`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Suchen Sie den Benutzer, der als Mitglied hinzugefügt werden soll, und weisen Sie die erforderlichen Rollen zu.`,
  [`Search overflow`]: `Suchüberlauf`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Durchsuchen Sie das Registry nach dem vorhandenen Benutzer, der als Mitglied hinzugefügt werden soll, und weisen Sie die erforderlichen Rollen zu.`,
  [`Search types/fields`]: `Suchtypen/-felder`,
  [`Search user`]: `Benutzer suchen`,
  [`Search schema`]: `Schema suchen`,
  [`Seconds`]: `Sekunden`,
  [`Secret Key`]: `Geheimer Schlüssel`,
  [`Secret Name`]: `Name des geheimen Schlüssels`,
  [`Secret Required`]: `Geheimer Schlüssel erforderlich`,
  [`Secret key`]: `Geheimer Schlüssel`,
  [`Secure`]: `Sichern`,
  [`Secure connection`]: `Sichere Verbindung`,
  [`Secure using Client ID`]: `Mit Client-ID sichern`,
  [`Security`]: `Sicherheit`,
  [`Security & Product Definitions`]: `Sicherheits- und Produktdefinitionen`,
  [`Security definitions`]: `Sicherheitsdefinitionen`,
  [`Security and Publish`]: `Sicherheit und Veröffentlichung`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Sicherheitsdefinitionen steuern den Clientzugriff auf API-Endpunkte, einschließlich API-Schlüsselprüfung, Basisauthentifizierung über eine angegebene Benutzerregistry und OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Sicherheitsdefinitionen steuern den Clientzugriff auf API-Endpunkte, einschließlich API-Schlüsselprüfung, Basisauthentifizierung über eine angegebene Benutzerregistry und OAuth. [Weitere Informationen]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Die hier ausgewählten Sicherheitsdefinitionen gelten für die gesamte API, können jedoch für einzelne Operationen überschrieben werden.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Die hier ausgewählten Sicherheitsdefinitionen gelten für die gesamte API, können jedoch für einzelne Operationen überschrieben werden. [Weitere Informationen]({link})`,
  [`Security object to use to decode the claim.`]: `Sicherheitsobjekt zum Entschlüsseln der Anforderung.`,
  [`Security object used to encrypt the JWT.`]: `Sicherheitsobjekt zum Verschlüsseln des JWT.`,
  [`Security object used to sign the JWT.`]: `Sicherheitsobjekt zum Signieren des JWT.`,
  [`Security object used to verify the claim.`]: `Sicherheitsobjekt zum Bestätigen der Anforderung.`,
  [`Select APIs to add to this product`]: `APIs auswählen, die zu diesem Produkt hinzugefügt werden sollen`,
  [`Select API Type`]: `API-Typ auswählen`,
  [`Select API type`]: `API-Typ auswählen`,
  [`Select a billing integration in the Product plans.`]: `Wählen Sie eine Abrechnungsintegration in den Produktplänen aus.`,
  [`Select a flow...`]: `Ablauf auswählen...`,
  [`Select a plan from your existing product`]: `Wählen Sie einen Plan aus Ihrem vorhandenen Produkt aus`,
  [`Select a policy file to import to this catalog`]: `Wählen Sie eine Richtliniendatei aus, die in diesen Katalog importiert werden soll`,
  [`Select a policy implementation file to import to this catalog`]: `Wählen Sie eine Richtlinienimplementierungsdatei aus, die in diesen Katalog importiert werden soll`,
  [`Select a product`]: `Produkt auswählen`,
  [`Select Certificate Manager service`]: `Zertifikatmanagerservice auswählen`,
  [`Select Cloud`]: `Cloud auswählen`,
  [`Select Email Server`]: `E-Mail-Server auswählen`,
  [`Select gateway services`]: `Gateway-Services auswählen`,
  [`Select Import Type`]: `Importtyp auswählen`,
  [`Select product`]: `Produkt auswählen`,
  [`Select product type`]: `Produkttyp auswählen`,
  [`Select Service`]: `Service auswählen`,
  [`Select Service Type`]: `Servicetyp auswählen`,
  [`Select target endpoints`]: `Zielendpunkte auswählen`,
  [`Select target service`]: `Zielservice auswählen`,
  [`Select user registry type`]: `Benutzerregistrytyp auswählen`,
  [`Select a WSDL service from the imported file`]: `Wählen Sie einen WSDL-Service aus der importierten Datei aus.`,
  [`Select a certificate`]: `Wählen Sie ein Zertifikat aus.`,
  [`Select a Certificate Manager service`]: `Wählen Sie einen Zertifikatmanagerservice aus.`,
  [`Select a cryptographic algorithm.`]: `Wählen Sie einen kryptografischen Algorithmus aus.`,
  [`Select a default catalog and organization`]: `Standardkatalog und Standardorganisation auswählen`,
  [`Select a different cloud`]: `Andere Cloud auswählen`,
  [`Select a key encryption algorithm.`]: `Wählen Sie einen Schlüsselverschlüsselungsalgorithmus aus.`,
  [`Select a plan to migrate`]: `Wählen Sie einen Plan zum Migrieren aus`,
  [`Select a plan to replace`]: `Plan auswählen, der ersetzt werden soll`,
  [`Select a plan to supersede`]: `Plan auswählen, der außer Kraft gesetzt werden soll`,
  [`Select a product to set as the migration target`]: `Wählen Sie ein Produkt aus, das als Migrationsziel festgelegt werden soll.`,
  [`Select a product to supersede`]: `Außer Kraft zu setzendes Produkt auswählen`,
  [`Select a product to supersede {sourceProductName}:`]: `Außer Kraft zu setzendes Produkt auswählen {sourceProductName}:`,
  [`Select a resource group`]: `Wählen Sie eine Ressourcengruppe aus.`,
  [`Select a service`]: `Wählen Sie einen Service aus.`,
  [`Select a supported file ({type}) and try again.`]: `Wählen Sie eine unterstützte Datei ({type}) aus und versuchen Sie es erneut.`,
  [`Select a target SOAP service`]: `Wählen Sie einen SOAP-Zielservice aus.`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Wählen Sie ein Toolkit-Paket zum Herunterladen aus. Überspringen Sie diesen Schritt, wenn Sie das Toolkit bereits installiert haben.`,
  [`Select an encryption algorithm.`]: `Wählen Sie einen Verschlüsselungsalgorithmus aus.`,
  [`Select an extension file for this gateway`]: `Wählen Sie eine Erweiterungsdatei für dieses Gateway aus.`,
  [`Select an org`]: `Organisation auswählen`,
  [`Select catalog`]: `Katalog auswählen`,
  [`Select Catalogs to Search`]: `Kataloge auswählen, die gesucht werden sollen`,
  [`Select compatible gateway services`]: `Alle kompatiblen Gateway-Services verwenden`,
  [`Select one Keystore from the list below:`]: `Wählen Sie einen Keystore aus der folgenden Liste aus:`,
  [`Select one keystore from the list below:`]: `Wählen Sie einen Keystore aus der folgenden Liste aus:`,
  [`Select one keystore from the list.`]: `Wählen Sie einen Keystore aus der Liste aus.`,
  [`Select operation...`]: `Operation auswählen...`,
  [`Select operations you want to include`]: `Einzuschließende Operationen auswählen`,
  [`Select organization`]: `Organisation auswählen`,
  [`Select the API definition or package file to import`]: `Wählen Sie die zu importierende API-Definition oder Paketdatei aus`,
  [`Select the billing integration for this catalog`]: `Abrechnungsintegration für diesen Katalog auswählen`,
  [`Select the billing integration for this product`]: `Abrechnungsintegration für dieses Produkt auswählen`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Wählen Sie die Gateways aus, die die APIs in einem neuen Katalog sichern. Besuchen Sie die Seite **Gateways**, um ein neues Gateway zu registrieren.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Wählen Sie den [Certificate Manager]({link})-Service aus, der die Zertifikate für dieses Gateway verwaltet.`,
  [`Select the APIs to add to this product`]: `Wählen Sie die APIs aus, die diesem Produkt hinzugefügt werden sollen`,
  [`Select the OpenAPI definition file to import`]: `Zu importierende OpenAPI-Definitionsdatei auswählen`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Wählen Sie die Produktlebenszyklusaktionen aus, die eine Genehmigung erfordern. Wenn eine der ausgewählten Aktionen ausgeführt wird, wird eine Genehmigungsanforderung generiert. Die Anforderung muss genehmigt werden, damit die Aktion ausgeführt werden kann.`,
  [`Select the TLS protocol versions for this profile.`]: `Wählen Sie die TLS-Protokollversionen für dieses Profil aus.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Wählen Sie den Analyseservice aus, den Sie dem angegebenen Gateway-Service zuordnen möchten.`,
  [`Select the consumer organization in which you want to create the application`]: `Wählen Sie die Konsumentenorganisation aus, in der die Anwendung erstellt werden soll.`,
  [`Select the consumer organization that will own the application`]: `Wählen Sie die Konsumentenorganisation aus, die Eigner der Anwendung werden soll.`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Wählen Sie die Endpunkte für die Veröffentlichung dieser API aus und definieren Sie nach Bedarf die Ratenbegrenzung.`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Wählen Sie die Fehler aus, die, wenn sie während der Richtlinienausführung ausgelöst werden, den Assembly-Ablauf stoppen. Wenn ein Abfangdatenfluss für den Fehler konfiguriert ist, wird er ausgelöst, um den ausgelösten Fehler zu verarbeiten. Wenn ein Fehler ausgelöst wird und keine Fehler für die Einstellung "Stoppen bei Fehler" ausgewählt sind, oder wenn der ausgelöste Fehler keiner der ausgewählten Fehler ist, wird die Richtlinienausführung abgeschlossen und der Assembly-Ablauf wird fortgesetzt.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Wählen Sie die Gateway-Services aus, für die die APIs im Produkt verfügbar gemacht werden sollen.`,
  [`Select the gateway type for this API`]: `Wählen Sie den Gateway-Typ für diese API aus.`,
  [`Select the gateway type for this OAuth provider`]: `Wählen Sie den Gateway-Typ für diesen OAuth-Provider aus`,
  [`Select the keystores and truststores for this profile.`]: `Wählen Sie die Keystores und Truststores für dieses Profil aus.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Wählen Sie die Organisationen oder Gruppen aus, für die Sie dieses Produkt sichtbar machen möchten`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Wählen Sie die Organisationen oder Gruppen aus, die dieses Produkt abonnieren sollen`,
  [`Select the portal service to use for this catalog`]: `Wählen Sie den Portalservice aus, der für diesen Katalog verwendet werden soll.`,
  [`Select the product and plan for the application subscription`]: `Wählen Sie das Produkt und den Plan für das Anwendungsabonnement aus`,
  [`Select the product definition file to import`]: `Zu importierende Produktdefinitionsdatei auswählen`,
  [`Select the product definition file to import from`]: `Wählen Sie die Produktdefinitionsdatei aus, aus der importiert werden soll`,
  [`Select the product that will supersede the originally selected product`]: `Wählen Sie das Produkt aus, das das ursprünglich ausgewählte Produkt außer Kraft setzen soll.`,
  [`Select the product that you want to add the API to`]: `Wählen Sie das Produkt aus, zu dem die API hinzugefügt werden soll.`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Wählen Sie die Registrys aus, die Sie zum Steuern des Zugriffs auf die APIs in diesem Katalog verwenden möchten.`,
  [`Select the required operations`]: `Wählen Sie die erforderlichen Operationen aus.`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Wählen Sie den Schweregrad für Protokollnachrichten aus, die mit Eingabedaten zusammenhängen. Möglich sind die Schweregrade Fehler, Warnung und Information.`,
  [`Select the target wsdl file to create from`]: `Wählen Sie die wsdl-Zieldatei aus, aus der erstellt werden soll`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Wählen Sie die Zieldatei (wsdl, xsd oder zip) aus, aus der die API-Definition erstellt werden soll`,
  [`Select the target WSDL file to import`]: `Zu importierende WSDL-Zieldatei auswählen`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Wählen Sie die Benutzerregistrys für die Authentifizierung von Benutzern von API Manager aus oder erstellen Sie eine neue Benutzerregistry.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Wählen Sie die Benutzerregistrys für die Authentifizierung von Benutzern von API Manager aus oder erstellen Sie eine neue Benutzerregistry.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Wählen Sie die Benutzerregistrys für die Authentifizierung von Benutzern von Cloud Manager aus oder erstellen Sie eine neue Benutzerregistry.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Wählen Sie die Benutzerregistrys aus, die zur Authentifizierung der Anmeldung beim Developer Portal verwendet werden`,
  [`Select the user registry type`]: `Benutzerregistrytyp auswählen`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Wählen Sie aus, wo die Richtlinie das generierte LTPA-Token in der Ausgabequelle positionieren soll.  Der WSSec-Header sollte nur dann ausgewählt werden, wenn der Inhaltstyp (content-type) in der Anforderung 'application/xml' lautet.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Wählen Sie aus, ob die Eigenschaft vollständig entfernt oder ob das Feld neu bearbeitet werden soll, indem "*"s zum Blockieren der Daten verwendet werden.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Das ausgewählte Produkt wurde geändert. Aktualisieren Sie die Seitenanzeige und versuchen Sie es erneut.`,
  [`Selecting operations from WSDL service definition`]: `Operationen aus WSDL-Servicedefinition auswählen`,
  [`Self Service Onboarding for Consumer organization`]: `Self-Service-Onboarding für Konsumentenorganisation`,
  [`Self service onboarding`]: `Self-Service-Onboarding`,
  [`Self service onboarding approval`]: `Self-Service-Onboarding-Genehmigung`,
  [`Send`]: `Senden`,
  [`Send Message`]: `Nachricht senden`,
  [`Send Message to Owners`]: `Nachricht an Eigner senden`,
  [`Send To`]: `Senden an`,
  [`Send a registration link to a new user to register as owner.`]: `Senden Sie einen Registrierungslink an einen neuen Benutzer, um ihn als Eigner zu registrieren.`,
  [`Send a test email`]: `Test-E-Mail senden`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Senden Sie eine Test-E-Mail, um zu bestätigen, dass der E-Mail-Server ordnungsgemäß konfiguriert ist.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Testsignal senden, um zu bestätigen, dass der Endpunkt ordnungsgemäß konfiguriert ist.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Testsignal senden, um zu bestätigen, dass der Endpunkt ordnungsgemäß konfiguriert ist.`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Senden Sie eine E-Mail-Nachricht an einen Verwaltungsorganisationseigner. Setzen Sie ein Häkchen neben den Namen des Eigners`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Senden Sie eine E-Mail-Nachricht an einen Eigner. Setzen Sie ein Häkchen neben den Namen des Eigners`,
  [`Send chunked-encoded documents to the target server.`]: `Aufgeteilte verschlüsselte Dokumente an den Zielserver senden.`,
  [`Send message`]: `Nachricht senden`,
  [`Send message to owner`]: `Nachricht an Eigner senden`,
  [`Send test email`]: `Test-E-Mail senden`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Senden Sie den Aktivierungslink an einen Benutzer, um ihn zum Anmelden oder Registrieren einzuladen.`,
  [`Send-only`]: `Nur senden`,
  [`Sender`]: `Absender`,
  [`Sender & email server`]: `Absender und E-Mail-Server`,
  [`Sender & email server have been changed`]: `Absender und E-Mail-Server wurden geändert`,
  [`Sender Info`]: `Informationen zum Absender`,
  [`Sent`]: `Gesendet`,
  [`Sent events: **{sent}**`]: `Gesendete Ereignisse: **{sent}**`,
  [`Serial`]: `Seriell`,
  [`Serialize output`]: `Ausgabe serialisieren`,
  [`Server connection`]: `Serververbindung`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `HTML für Web-Browser bereitstellen, um GUI-GraphQL-Client zu aktivieren`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `HTML für Web-Browser bereitstellen, um GUI-GraphQL-Client zu aktivieren.`,
  [`Server Name Indication (SNI)`]: `Server Name Indication (SNI)`,
  [`Server URL`]: `Server-URL`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Server hat einen Fehler im Hashwert anstelle eines Tokens in OIDC-Umleitungs-URL zurückgegeben. Hashwert:`,
  [`Service name`]: `Servicename`,
  [`Services`]: `Services`,
  [`Set`]: `Festlegen`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Legen Sie die Betriebssytemumgebungsvariable APIC_DESIGNER_CREDENTIALS für API Designer fest. Auf MAC können Benutzer z. B. die Umgebungsvariable während des Starts von API Designer übergeben.`,
  [`Set Migration Target`]: `Migrationsziel festlegen`,
  [`Set migration target`]: `Migrationsziel festlegen`,
  [`Set Variable`]: `Variable festlegen`,
  [`Set as default`]: `Als Standardwert festlegen`,
  [`Set default`]: `Standard festlegen`,
  [`Set rate and burst limits in an assembly`]: `Raten- und Burstbegrenzung in einer Assembly festlegen`,
  [`Set the timeout period for invitations`]: `Legen Sie das Zeitlimitintervall für Einladungen fest.`,
  [`Set the trust level for the profile`]: `Legen Sie die Vertrauensebene für das Profil fest`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Setzen Sie den Wert auf die zulässige Iterationsanzahl für eine zirkuläre Schemadefinition (Standardwert 1, Maximalwert 5).`,
  [`Set the visibility for a gateway service`]: `Sichtbarkeit für einen Gateway-Service festlegen`,
  [`Set the visibility for a portal service`]: `Sichtbarkeit für einen Portalservice festlegen`,
  [`Set the visibility for each service`]: `Legen Sie die Sichtbarkeit für jeden Service fest.`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Legen Sie den Zeitrahmen fest, um ein neues Zugriffstoken abzurufen, das vom Authentifizierungsserver ausgegeben wird.`,
  [`Set visibility`]: `Sichtbarkeit festlegen`,
  [`Set visibility of gateway`]: `Sichtbarkeit des Gateways festlegen`,
  [`Set time interval`]: `Zeitintervall festlegen`,
  [`Set, Add, or Clear a runtime variable.`]: `Laufzeitvariable festlegen, hinzufügen oder löschen`,
  [`Set the timeout period for API key`]: `Zeitlimitintervall für API-Schlüssel festlegen`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Legt den Gültigkeitszeitraum für Dokumente im Cache in Sekunden fest. Gilt nur, wenn für den Cachetyp "Lebensdauer" festgelegt ist. Geben Sie einen Wert im Bereich 5 - 31708800 ein. Der Standardwert ist 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `Die Festlegung eines Werts für "assumedSize" ist nicht kombinierbar mit der Definition eines Slicing-Arguments mit einem Standardwert oder mit der Aktivierung von "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Wenn Sie diesen Katalog als Standardkatalog festlegen, kann der Zugriff darauf über eine kürzere URL erfolgen, wobei der Katalogname weggelassen wird. Sie können nur einen Katalog als Standard festlegen.`,
  [`Settings`]: `Einstellungen`,
  [`Setup`]: `Konfiguration`,
  [`Severity level for input data log messages`]: `Schweregrad für Protokollnachrichten zu Eingabedaten`,
  [`Share the resource with a custom list of provider organizations`]: `Ressource mit einer angepassten Liste von Provider-Organisationen gemeinsam nutzen`,
  [`Share the resource with provider organizations`]: `Ressource mit Provider-Organisationen gemeinsam nutzen`,
  [`Share the API gateway with a custom list of provider organizations`]: `API-Gateway mit einer angepassten Liste von Provider-Organisationen gemeinsam nutzen`,
  [`Share the service with a custom list of provider organizations`]: `Service mit einer angepassten Liste von Provider-Organisationen gemeinsam nutzen`,
  [`Share the API gateway with provider organizations`]: `API-Gateway mit Provider-Organisationen gemeinsam nutzen`,
  [`Share the service with provider organizations`]: `Service mit Provider-Organisationen gemeinsam nutzen`,
  [`Share`]: `Gemeinsam nutzen`,
  [`Shared`]: `Geteilt`,
  [`Shared by`]: `Geteilt von`,
  [`Short but descriptive name for this policy.`]: `Kurzer, beschreibender Name für diese Richtlinie.`,
  [`Show`]: `Anzeigen`,
  [`Show / hide policy palette`]: `Richtlinienpalette ein-/ausblenden`,
  [`Show/hide`]: `Einblenden/ausblenden`,
  [`Show/hide settings`]: `Einstellungen anzeigen/ausblenden`,
  [`Show and hide types and fields to include in the schema.`]: `Typen und Felder, die in das Schema eingeschlossen werden sollen, anzeigen und ausblenden.`,
  [`Show added only`]: `Nur hinzugefügte anzeigen`,
  [`Show all`]: `Alles anzeigen`,
  [`Show all UDP versions`]: `Alle UDP-Versionen anzeigen`,
  [`Show app identifier`]: `App-Kennung anzeigen`,
  [`Show breaking changes only`]: `Nur unterbrechende Änderungen anzeigen`,
  [`Show catalog identifier`]: `Katalogkennung anzeigen`,
  [`Show catches`]: `Catches anzeigen`,
  [`Show in schema`]: `Im Schema anzeigen`,
  [`Show less`]: `Weniger zeigen`,
  [`Show modified only`]: `Nur geänderte anzeigen`,
  [`Show more`]: `Mehr zeigen`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Neue Typen, Felder, Anweisungen oder optionale Argumente anzeigen. Elemente, die nicht mehr vom Back-End unterstützt werden, werden dennoch entfernt.`,
  [`Show removed only`]: `Nur entfernte anzeigen`,
  [`Show schema`]: `Schema anzeigen`,
  [`Show warnings only`]: `Nur Warnungen anzeigen`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Zeigt den Verlauf der Keystore-Zuordnungen mit Ausnahme des derzeit zugeordneten Keystores an.`,
  [`Sign Crypto Object`]: `Crypto-Objekt signieren`,
  [`Sign in`]: `Anmeldung`,
  [`Sign JWK variable name`]: `JWK-Variablenname signieren`,
  [`Sign Out`]: `Abmelden`,
  [`Sign up`]: `Registrieren`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Anmelden mit der {userRegistryType}-Benutzerregistry`,
  [`Sign in with`]: `Anmelden mit`,
  [`Sign out`]: `Abmelden`,
  [`Sign up with`]: `Registrieren unter`,
  [`Signed token`]: `Signiertes Token`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Da die Pufferung inaktiviert wurde, ist es erforderlich, dass der Inhalt und/oder der Fehlerinhalt von den Nutzdaten zu den Headern reduziert wird. Möchten Sie mit der erforderlichen Änderung fortfahren oder Ihre letzte Änderung abbrechen?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Da der Inhalt und/oder Fehlerinhalt auf Nutzdaten gesetzt wurde, ist es erforderlich, dass die Pufferung inaktiviert wird. Möchten Sie mit der erforderlichen Änderung fortfahren oder Ihre letzte Änderung abbrechen?`,
  [`Sized fields`]: `Felder mit festgelegter Größe`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Slicing-Argumente`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Einige Eingaben sind Arrays oder in Arrays enthalten. Um die Inhalte zu iterieren, wählen Sie die Iterationsoption und anschließend die zu iterierende Eingabe.`,
  [`Something went wrong.`]: `Ein Fehler ist aufgetreten.`,
  [`Something went wrong...`]: `Ein Fehler ist aufgetreten.`,
  [`Source`]: `Quelle`,
  [`Source schema`]: `Quellenschema`,
  [`Space`]: `Bereich`,
  [`Space is not allowed in scope name`]: `Bereich ist im Bereichsnamen nicht zulässig`,
  [`Space details`]: `Bereichsdetails`,
  [`Space Enabled`]: `Bereich aktiviert`,
  [`Space Name`]: `Bereichsname`,
  [`Space Summary`]: `Bereichszusammenfassung`,
  [`Space Title`]: `Bereichstitel`,
  [`Space owner`]: `Bereichseigner`,
  [`Space {name} created`]: `Bereich {name} erstellt`,
  [`Spaces`]: `Bereiche`,
  [`Spaces are enabled`]: `Bereiche sind aktiviert`,
  [`Spaces are not enabled`]: `Bereiche sind nicht aktiviert`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Es können keine Bereiche für den Sandbox-Katalog aktiviert werden.`,
  [`Spaces cannot be enabled.`]: `Bereiche können nicht aktiviert werden.`,
  [`Spaces dropdown`]: `Dropdown-Liste 'Bereich'`,
  [`Spaces has been enabled`]: `Bereiche wurden aktiviert`,
  [`Spaces have been enabled.`]: `Bereiche wurden aktiviert.`,
  [`Spaces per page`]: `Leerzeichen pro Seite`,
  [`Spanish`]: `Spanisch`,
  [`Special characters are not accepted`]: `Sonderzeichen werden nicht akzeptiert`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Gibt eine URL oder Zeichenfolge an, die einen benannten Kontext darstellt, aus dem die serialisierten XML- oder JSON-Eigenschaften abgerufen werden sollen, die in das dynamische Objekt zusammengeführt werden. Diese Eigenschaften haben Vorrang vor allen vorhandenen Literal- oder Standardeigenschaften.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Gibt eine Literalzeichenfolge als serialisierte XML- oder JSON-Eigenschaften an, die in das dynamische Objekt zusammengeführt werden. Diese Eigenschaften haben Vorrang vor allen vorhandenen Standardeigenschaften.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Gibt ein vorhandenes gültiges Objekt an, aus dem die Standardeigenschaftswerte für das dynamische Objekt abgerufen werden sollen. Warnung: Wenn Sie die Standardzeichenfolge ändern, muss das angegebene Objekt mit den Parsing-Einstellungen auf der DataPower-Appliance verfügbar sein.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Gibt die Datenquelle an, die den Inhalt enthält, der bearbeitet oder entfernt werden soll. Wenn das Stammverzeichnis nicht angegeben ist, wird die Aktion auf den gesamten API-Kontext angewendet. Sie können jeden unterstützten JSONata-Pfadausdruck verwenden.\n\nDie Neubearbeitung der Anforderungs-und Antwortdaten kann mit einem Stammverzeichnis von "message.body" ausgeführt werden. Wenn die Aktion zur Neubearbeitung der Assembly in einer Assembly nach einer Assemblierungsprotokollaktion verwendet wird, die den Modus "Nur zusammenstellen" angibt, kann das Stammverzeichnis "log.request_body" für die protokollierten Anforderungsnutzdaten oder "log.response_body" für die protokollierten Antwortnutzdaten angeben.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Gibt den Namen einer Variablen im API-Kontext an. Der Inhalt des Felds "body" der Variablen ist die Eingabe der Aktion. Der Standardvariablenname lautet "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Gibt den Namen einer Variablen im API-Kontext an. Der Inhalt des Felds "body" der Variablen ist die Ausgabe des Parsings. Die Parsing-Messwerte des geparsten Dokuments können in einem anderen Teil der Nachricht gespeichert werden. Der Standardvariablenname entspricht dem Eingabenamen. Daher wird die Eingabenachricht standardmäßig mit der Ausgabenachricht überschrieben.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Gibt an, ob und wo angepasste Metadaten für das Zugriffstoken angefordert werden.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Gibt an, ob eine Analyse mit dem angegebenen Inhaltstyp versucht werden soll. Dies gilt nur, wenn der erwartete Inhaltstyp entweder JSON oder XML ist. Wenn diese Option aktiviert ist, führt eine Analyseaktion zu einem Fehler, wenn der Inhaltstyp nicht mit den Analyseeinstellungen übereinstimmt (d.h. Content-type:application/json und die parse-settings sind für xml konfiguriert). Wenn parse-setting für das Erkennen von Inhalten konfiguriert ist, wird der angegebene content-type erzwungen.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Gibt an, ob die XSLT-Ausgabebaumstruktur nach der Konvertierung als binäre Daten serialisiert werden soll.`,
  [`Specify default gateways`]: `Standardgateways angeben`,
  [`Specify email`]: `E-Mail-Adresse angeben`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Geben Sie eine OpenAPI-Schemadefinition (Swagger) an, mit der die Nutzdaten validiert werden sollen ("request", "response" oder "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Geben Sie den Eigner der Konsumentenorganisation an`,
  [`Specify owner of the provider organization`]: `Geben Sie den Eigner der Provider-Organisation an`,
  [`Specify settings for the third party OAuth provider.`]: `Geben Sie Einstellungen für den OAuth-Provider eines Drittanbieters an.`,
  [`Specify the MIME types that your API consumes and produces`]: `Geben Sie die MIME-Typen an, die Ihre API konsumiert und erzeugt.`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Geben Sie den Katalogeigner an. Der Eigner verfügt über alle Katalogberechtigungen`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Geben Sie die Konsumentenorganisationen oder Communitys von Entwicklern an, die das Produkt im Developer Portal anzeigen können.`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Geben Sie die Konsumentenorganisationen oder Communitys der Entwickler an, die die APIs im Produkt abonnieren können.`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Geben Sie die Basis-URL des API-Aufrufendpunkts und die Domänen an, die vom Gateway verarbeitet werden.`,
  [`Specify the details of the catalog that you want to publish to`]: `Geben Sie die Details des Katalogs an, in dem Sie veröffentlichen möchten.`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Geben Sie die Standardgateway-Gruppe an, die die APIs in einem neuen Katalog sichern sollen. [Weitere Informationen]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Endpunkte angeben, auf denen diese API veröffentlicht werden soll, und angeben, ob die Rate der API-Aufrufe begrenzt werden soll`,
  [`Specify the new owner`]: `Neuen Eigner angeben`,
  [`Specify the owner`]: `Eigner angeben`,
  [`Specify the owner and title`]: `Eigner und Titel angeben`,
  [`Specify the properties to redact or remove.`]: `Geben Sie die Eigenschaften zur Neubearbeitung oder zum Entfernen an.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Geben Sie die Anforderungen für Veröffentlichung und Ratenbegrenzung für diese API an.`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Geben Sie den Endpunkt der Bereichsüberprüfung an, an dem, zusätzlich zu den definierten Bereichen, eine weitere Bereichsüberprüfung durchgeführt wird.`,
  [`Specify the security settings for the imported API definition`]: `Geben Sie die Sicherheitseinstellungen für die importierte API-Definition an.`,
  [`Specify the security settings for your API definition`]: `Sicherheitseinstellungen für Ihre API-Definition angeben`,
  [`Specify the space owner; the owner has all space permissions`]: `Geben Sie den Bereichseigner an. Der Eigner verfügt über alle Bereichsberechtigungen.`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Geben Sie den Ziel-SOAP-Service und die Details der API-Zusammenfassung an. Falls erforderlich, können Sie die API-Definition nach der Erstellung weiter konfigurieren.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Geben Sie das Zeitlimitintervall an, nach dem Einladungs-E-Mails ablaufen, wenn der Benutzer seinen Account nicht aktiviert.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Geben Sie das Zeitlimitintervall an, nach dem Einladungs-E-Mails ablaufen, wenn der Benutzer seinen Account nicht aktiviert.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Geben Sie den Typ des Gateways, einen Titel und eine Zusammenfassung zur Beschreibung des Gateways an.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Geben Sie die Sichtbarkeit des API-Gateways für die API Connect-Provider-Organisation an.`,
  [`Specify the way you want to validate the message.`]: `Geben Sie an, wie die Nachricht validiert werden soll.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Geben Sie die URL des Gateway-Managementendpunkts und das Zertifikat für die Domäne des Endpunkts an.`,
  [`Specify what content to log in the event of an error.`]: `Geben Sie den bei einem Fehler zu protokollierenden Inhalt an.`,
  [`Specify what content to log.`]: `Geben Sie den zu protokollierenden Inhalt an.`,
  [`Specifying the publish target details`]: `Details zum Veröffentlichungsziel angeben`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Regel xsi:type=SOAP-ENC:Array spezifisch zulassen`,
  [`Stage`]: `Bereitstellen`,
  [`Stage API`]: `API bereitstellen`,
  [`Stage product`]: `Produkt bereitstellen`,
  [`Stage to`]: `Bereitstellen in`,
  [`Staged`]: `Bereitgestellt`,
  [`Stage|permission`]: `Bereitstellen`,
  [`Standard OIDC`]: `Standard-OIDC`,
  [`Static DNS`]: `Statisches DNS`,
  [`Static group`]: `Statische Gruppe`,
  [`Status`]: `Status`,
  [`Status code`]: `Statuscode`,
  [`Step 1`]: `Schritt 1`,
  [`Step 1: Download`]: `Schritt 1: Herunterladen`,
  [`Step 1: Download toolkit`]: `Schritt 1: Toolkit herunterladen`,
  [`Step 1: Upload private key`]: `Schritt 1: privaten Schlüssel hochladen`,
  [`Step 2`]: `Schritt 2`,
  [`Step 2: Download credentials`]: `Schritt 2: Berechtigungsnachweise herunterladen`,
  [`Step 2: Download toolkit credentials`]: `Schritt 2: Toolkitberechtigungsnachweise herunterladen`,
  [`Step 2: Install`]: `Schritt 2: Installieren`,
  [`Step 2: Upload public key`]: `Schritt 2: öffentlichen Schlüssel hochladen`,
  [`Step 3`]: `Schritt 3`,
  [`Step 3: Configure`]: `Schritt 3: Konfigurieren`,
  [`Step 3: Run command`]: `Schritt 3: Befehl ausführen`,
  [`Step 3: Run commands`]: `Schritt 3: Befehle ausführen`,
  [`Step 4: Register`]: `Schritt 4: Registrieren`,
  [`Stop`]: `Stoppen`,
  [`Stop after:`]: `Stoppen nach:`,
  [`Stop all the retries`]: `Alle Wiederholungen stoppen`,
  [`Stop Connection`]: `Verbindung stoppen`,
  [`Stop on error`]: `Stoppen bei Fehler`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Stoppen Sie die Ausführung dieser API und entfernen Sie sie aus dem Sandbox-Katalog.`,
  [`Stopped`]: `Gestoppt`,
  [`Stopping Published API`]: `Veröffentlichte API wird gestoppt`,
  [`Strict`]: `Streng`,
  [`Strict SOAP envelope version`]: `Strenge SOAP-Envelope-Version`,
  [`String Badgerfish`]: `Zeichenfolge Badgerfish`,
  [`String`]: `Zeichenfolge`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `Zeichenfolge, die im Organisationssegment der URL für API-Aufrufe enthalten ist. Verwenden Sie Kleinbuchstaben, Ziffern und Bindestriche.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Stripe-Integration`,
  [`Streaming rule`]: `Streamingregel`,
  [`Subject`]: `Betreff`,
  [`Subject Claim`]: `Betreffanforderung`,
  [`Subject Origin`]: `Subjektherkunft`,
  [`Subject common name`]: `Allgemeiner Subjektname`,
  [`Subject|certificate`]: `Betreff`,
  [`Submit`]: `Übergeben`,
  [`Submitted`]: `Eingereicht`,
  [`Subscribability`]: `Abonnierbarkeit`,
  [`Subscribability must be disabled if visibility is disabled`]: `Die Abonnierbarkeit muss inaktiviert werden, wenn die Sichtbarkeit inaktiviert ist.`,
  [`Subscribability options`]: `Abonnementoptionen`,
  [`Subscribable by`]: `Abonnierbar von`,
  [`Subscribe`]: `Abonnieren`,
  [`Subscribe Financial Management App`]: `Finanzmanagement-App abonnieren`,
  [`Subscribe TODO App`]: `TODO-App abonnieren`,
  [`Subscription`]: `Abonnement`,
  [`Subscription approval task for subscription of`]: `Abonnementgenehmigungstask für das Abonnement von`,
  [`Subscription created`]: `Abonnement erstellt`,
  [`Subscription has been added`]: `Abonnement wurde hinzugefügt`,
  [`Subscription has not been added`]: `Abonnement wurde nicht hinzugefügt`,
  [`Subscription pending approval`]: `Genehmigung für Abonnement anstehend`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `Abonnementtyp wurde entfernt, da GraphQL-Abonnements nicht unterstützt werden.`,
  [`Subscription-Approval`]: `Abonnementgenehmigung`,
  [`Subscriptions`]: `Abonnements`,
  [`Success`]: `Erfolg`,
  [`Suffix`]: `Suffix`,
  [`Suggestion`]: `Vorschlag`,
  [`Supersede`]: `Außer Kraft setzen`,
  [`Supersede Product`]: `Produkt außer Kraft setzen`,
  [`Supersede product`]: `Produkt außer Kraft setzen`,
  [`Supersede plans`]: `Pläne außer Kraft setzen`,
  [`Supersede {product1} with {product2}`]: `{product1} durch {product2} außer Kraft setzen`,
  [`Supersede|permission`]: `Außer Kraft setzen`,
  [`Supersede|supersede with`]: `Außer Kraft setzen`,
  [`Support`]: `Unterstützung`,
  [`support default introspection`]: `Standardintrospektion unterstützen`,
  [`Support Server Name Indication (SNI)`]: `SNI unterstützen (Server Name Indication)`,
  [`Support hybrid response types`]: `Hybrid-Antworttypen unterstützen`,
  [`Support standard introspection`]: `Standardintrospektion unterstützen`,
  [`Supported OAuth components`]: `Unterstützte OAuth-Komponenten`,
  [`Supported client types`]: `Unterstützte Clienttypen`,
  [`Supported client types can not be empty.`]: `Unterstützter Clienttyp darf nicht leer sein.`,
  [`Supported grant type can not be empty.`]: `Unterstützter Bewilligungstyp darf nicht leer sein.`,
  [`Supported grant types`]: `Unterstützte Bewilligungstypen`,
  [`Suspend`]: `Aussetzen`,
  [`Suspend API`]: `API aussetzen`,
  [`Suspend Application`]: `Anwendung aussetzen`,
  [`Switch`]: `Switch`,
  [`Switch account`]: `Account wechseln`,
  [`Switch cloud connection`]: `Cloudverbindung wechseln`,
  [`Switch directory`]: `Verzeichnis wechseln`,
  [`TARGET`]: `ZIEL`,
  [`Title`]: `Titel`,
  [`TlS`]: `TIS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS-Client`,
  [`TLS Client Profile`]: `TLS-Clientprofil`,
  [`TLS Client Profile has been configured.`]: `Das TLS-Clientprofil wurde konfiguriert.`,
  [`TLS client profile is required when endpoint starts with https://`]: `Das TLS-Clientprofil ist erforderlich, wenn der Endpunkt mit https:// beginnt.`,
  [`TLS Client Profiles`]: `TLS-Clientprofile`,
  [`TLS client profile has been configured.`]: `Das TLS-Clientprofil wurde konfiguriert.`,
  [`TLS client profiles`]: `TLS-Clientprofile`,
  [`TLS Profile`]: `TLS-Profil`,
  [`TLS SERVER PROFILE`]: `TLS-SERVERPROFIL`,
  [`TLS server profile`]: `TLS-Serverprofil`,
  [`TLS version 1.0`]: `TLS Version 1.0`,
  [`TLS version 1.1`]: `TLS Version 1.1`,
  [`TLS version 1.2`]: `TLS Version 1.2`,
  [`TLS version 1.3`]: `TLS Version 1.3`,
  [`TLS client profile`]: `TLS-Clientprofil`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `TLS-Clientprofile werden in jedem Bereich verwaltet, wenn Bereiche aktiviert sind. Rufen Sie die Bereichseinstellungen auf, um TLS-Clientprofile zu verwalten.`,
  [`TLS profile`]: `TLS-Profil`,
  [`TLS profile is not visible or not available.`]: `TLS-Profil ist nicht sichtbar oder nicht verfügbar.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `TLS-Profil für die sichere Übertragung von Daten an die Authentifizierungs-URL.`,
  [`Tabs for code language options`]: `Registerkarten für Codesprachenoptionen`,
  [`Truststore`]: `Truststore`,
  [`truststore`]: `Truststore`,
  [`Type`]: `Typ`,
  [`Type name`]: `Typname`,
  [`Type/Field`]: `Typ/Feld`,
  [`Type weight`]: `Typgewichtung`,
  [`Types per page:`]: `Typen pro Seite:`,
  [`Tags`]: `Tags`,
  [`Tags and external documentation`]: `Tags und externe Dokumentation`,
  [`Take offline`]: `Offline schalten`,
  [`Target`]: `Ziel`,
  [`Target API (URL)`]: `Target-API (URL)`,
  [`Target SOAP service`]: `Ziel-SOAP-Service`,
  [`Target Services`]: `Zielservices`,
  [`Target service URL`]: `Zielservice-URL`,
  [`Target services`]: `Zielservices`,
  [`Target URL`]: `Target-URL`,
  [`Target application`]: `Zielanwendung`,
  [`Target catalog`]: `Zielkatalog`,
  [`Target gateway service`]: `Ziel-Gateway-Service`,
  [`Target gateway services`]: `Ziel-Gateway-Services`,
  [`Target plan`]: `Zielplan`,
  [`Target product`]: `Zielprodukt`,
  [`Target product rate limit`]: `Grenzwert für Zielproduktrate`,
  [`Target product subscribability`]: `Abonnierbarkeit des Zielprodukts`,
  [`Target product visibility`]: `Sichtbarkeit des Zielprodukts`,
  [`Target schema`]: `Zielschema`,
  [`Target services have been updated.`]: `Zielservices wurden aktualisiert.`,
  [`Task`]: `Task`,
  [`Task has been updated`]: `Task wurde aktualisiert`,
  [`Task self approval`]: `Selbstgenehmigung für Tasks`,
  [`Task self-approval`]: `Selbstgenehmigung für Tasks`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Tasks`,
  [`Tasks for creating new provider organizations`]: `Tasks zum Erstellen neuer Provider-Organisationen`,
  [`TCP`]: `TCP`,
  [`Template`]: `Vorlage`,
  [`Template: {title}`]: `Vorlage: {title}`,
  [`Template: account approved`]: `Vorlage: Account genehmigt`,
  [`Template: account denied`]: `Vorlage: Account verweigert`,
  [`Template: account pending approval`]: `Vorlage: Account mit ausstehender Genehmigung`,
  [`Template: app lifecycle approved`]: `Vorlage: App-Lebenszyklus genehmigt`,
  [`Template: app lifecycle denied`]: `Vorlage: App-Lebenszyklus verweigert`,
  [`Template: app lifecycle pending`]: `Vorlage: App-Lebenszyklus ausstehend`,
  [`Template: app reinstated`]: `Vorlage: App wiederhergestellt`,
  [`Template: app suspended`]: `Vorlage: App ausgesetzt`,
  [`Template: app lifecycle cancelled`]: `Vorlage: App-Lebenszyklus abgebrochen`,
  [`Template: app lifecycle request`]: `Vorlage: App-Lebenszyklus angefordert`,
  [`Template: invitation`]: `Vorlage: Einladung`,
  [`Template: mail server test connection`]: `Vorlage: Mail-Server-Testverbindung`,
  [`Template: member invitation`]: `Vorlage: Mitgliedereinladung`,
  [`Template: password changed`]: `Vorlage: Kennwort geändert`,
  [`Template: password reset`]: `Vorlage: Kennwort zurückgesetzt`,
  [`Template: sign up`]: `Vorlage: Anmelden`,
  [`Template: task consumer onboard request`]: `Vorlage: Task-Benutzeronboarding angefordert`,
  [`Template: task product lifecycle approved`]: `Vorlage: Task-Produktlebenszyklus genehmigt`,
  [`Template: task product lifecycle cancelled`]: `Voralge: Task-Produktlebenszyklus abgebrochen`,
  [`Template: task product lifecycle denied`]: `Vorlage: Task-Produktlebenszyklus verweigert`,
  [`Template: task product lifecycle pending`]: `Vorlage: Task-Produktlebenszyklus ausstehend`,
  [`Template: task product lifecycle request`]: `Vorlage: Task-Produktlebenszyklus angefordert`,
  [`Template: task subscription cancelled`]: `Vorlage: Task-Subskription abgebrochen`,
  [`Template: task subscription request`]: `Vorlage: Task-Subskription angefordert`,
  [`Template: task subscription approved`]: `Vorlage: Task-Subskription genehmigt`,
  [`Template: task subscription denied`]: `Vorlage: Task-Subskription verweigert`,
  [`Template: task subscription pending`]: `Vorlage: Task-Subskription ausstehend`,
  [`Temporarily disable subscribability`]: `Abonnierbarkeit vorübergehend inaktivieren`,
  [`Temporarily disable visibility`]: `Sichtbarkeit vorübergehend inaktivieren`,
  [`Terms of service`]: `Servicebedingungen`,
  [`Test`]: `Testen`,
  [`Test API`]: `API testen`,
  [`Test APIs`]: `APIs testen`,
  [`Test Bind & Get Base DN`]: `Binden testen & Basis-DN abrufen`,
  [`Test configuration`]: `Konfiguration testen`,
  [`Test connection`]: `Verbindung testen`,
  [`Test connection failed.`]: `Testverbindung fehlgeschlagen.`,
  [`Test and production endpoints`]: `Test- und Produktionsendpunkte`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Antwortfehler testen und überwachen: Eigenschaft redirect_uri erforderlich, {redirectUri} erhalten`,
  [`Test application`]: `Testanwendung`,
  [`Test consumer org`]: `Konsumentenorganisation testen`,
  [`Test email`]: `Test-E-Mail`,
  [`Test endpoints`]: `Testendpunkte`,
  [`Test message from IBM API Connect`]: `Testnachricht von IBM API Connect`,
  [`Testable`]: `Testbar`,
  [`TEXT`]: `TEXT`,
  [`The "Definition" property must have a valid value.`]: `Die Eigenschaft "Definition" muss einen gültigen Wert aufweisen.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `Die Eigenschaft "Definition" muss den Wert "request" oder "response" aufweisen oder mit "#/definitions/" beginnen.`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `Die API Connect-Befehlszeilenschnittstelle wird als Befehlszeilen-Plug-in für IBM Cloud angeboten. Erste Schritte:`,
  [`The API has unsaved changes.`]: `Die API enthält nicht gespeicherte Änderungen.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `Die API wurde vollständig entworfen und hat einen internen Meilenstein erreicht, ist jedoch noch nicht implementiert.`,
  [`The API is in the implementation phase.`]: `Die API befindet sich in der Implementierungsphase.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `Die API befindet sich in der frühen Konzeptphase und ist weder vollständig entwickelt noch implementiert.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `Diese API enthält möglicherweise nicht veröffentlichte Änderungen. Möchten Sie das Produkt erneut veröffentlichen?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `Das DNS-Schema bestimmt die Zusammensetzung der Endpunkt-URLs für eingehende API-Aufrufe an den Gateway-Service und für den Zugriff auf das Developer Portal.`,
  [`The GatewayScript source code to execute.`]: `Der auszuführende GatewayScript-Quellcode.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `Die GraphQL-Schema-URL, mit der die GraphQL-Nutzdaten validiert werden müssen.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Die für den Aufruf zu verwendende HTTP-Methode. Wird dieser Wert ausgelassen oder auf "Beibehalten" gesetzt, wird die Methode von der eingehenden Anforderung verwendet.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Die HTTP-Methode, die die aktuelle HTTP-Methode ersetzen soll. Wird dieser Wert ausgelassen oder auf "Beibehalten" gesetzt, wird die Methode von der eingehenden Anforderung verwendet.`,
  [`The JavaScript source code to execute.`]: `Der auszuführende JavaScript-Quellcode.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `LDAP (Lightweight Directory Access Protocol) ist ein Internetprotokoll für den Zugriff auf und die Verwaltung von verteilten Verzeichnisinformationsservices über ein Netz. Wenn Sie einen Unternehmens-LDAP-Service aktiviert haben, konfigurieren Sie ihn als Ressource, um eine Benutzerauthentifizierung bereitzustellen.`,
  [`The Proxy URL to be used.`]: `Die zu verwendende Proxy-URL.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `Das SSL-Profil für die sichere Übertragung von Daten.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `Das TLS-Profil für die sichere Übertragung von Daten.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `Die URL des JSON-Schemas für die Validierung von JSON-Nutzdaten.`,
  [`The URL of the XML schema for validating an XML payload.`]: `Die URL des XML-Schemas für die Validierung von XML-Nutzdaten.`,
  [`The URL to be invoked.`]: `Die aufzurufende URL.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `Die WSDL-Schema-URL, mit der die SOAP-Nutzdaten validiert werden müssen.`,
  [`The XML element name to be used for JSON array elements.`]: `Der für JSON-Arrayelemente zu verwendende XML-Elementname.`,
  [`The XSLT source to execute.`]: `Die auszuführende XSLT-Quelle.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `Der Aktivierungslink kann an einen Benutzer gesendet werden, damit er seinen Account aktivieren kann.`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `Der Analyseservice erfasst API-Ereignisse vom Gateway-Service. Jedem Gateway ist ein Analyseservice zugeordnet. Ein API-Ereignis mit einer Zeitmarke wird immer dann protokolliert, wenn eine API-Operation aufgerufen wird.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `Der Analyseservice erfasst API-Ereignisse vom Gateway-Service. Jeder Gateway-Service kann über einen zugeordneten Analyseservice verfügen.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `Die Berechtigungs-URL gibt an, wo die Clientanwendung Berechtigungserteilung erhält. Die Token-URL gibt an, wo die Clientanwendung eine Berechtigungserteilung für ein Zugriffstoken austauscht.  Die Introspektions-URL ist der Ort, an dem das API-Gateway die Zugriffstokens überprüft, die vom Provider des Drittanbieters ausgegeben werden.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `Der Basispfad ist das erste URL-Segment der API und schließt weder den Hostnamen noch zusätzliche Segmente für Pfade oder Operationen ein.`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `Der Cachetyp bestimmt, ob Dokument im Cache gespeichert werden. Dabei werden die HTTP-Cachesteuerungsanweisungen aus der Antwort des Servers beachtet oder übergangen. Diese Eigenschaft wird nur dann wirksam, wenn eine Antwort vom Server empfangen wird. Andernfalls gibt die Richtlinie immer die nicht abgelaufene Antwort zurück, die zuvor im Cache gespeichert wurde.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Der Zertifikatmanagerservice muss die Zertifikate für die Domäne des Gateway-Managementendpunkts und der Domänen verwalten, die das Gateway verarbeitet. Zudem müssen Sie mithilfe von IBM Cloud IAM den Service "API Connect Reserved Instance" für den Zugriff auf den Zertifikatmanagerservice berechtigen.`,
  [`The certificate with which to decrypt the buffer`]: `Das Zertifikat für die Entschlüsselung des Puffers`,
  [`The certificate with which to encrypt the buffer`]: `Das Zertifikat für die Verschlüsselung des Puffers`,
  [`The change is critical`]: `Die Änderung ist kritisch`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Die Clientanwendungen können nur die Bereiche oder eine Untergruppe der Bereiche anfordern, die Sie hier definieren. Die Bereiche sind in den Zugriffstokens enthalten, die vom Provider generiert werden. Wenn eine mit OAuth geschützte API aufgerufen wird, überprüft das Gateway die Bereiche in den Zugriffstokens anhand der Liste der zulässigen Bereiche in der Sicherheitsdefinition, um zu bestimmen, ob der Zugriff erteilt werden soll. Darüber hinaus können Sie die erweiterte Bereichsüberprüfung erzwingen. Die erweiterten Bereichsüberprüfungs-URLs werden nach der Anwendungsauthentifizierung oder nach der Benutzerauthentifizierung aufgerufen, je nachdem, welche URLs konfiguriert sind. Die endgültige Bereichsberechtigung, die vom Zugriffstoken erteilt wird, ist das Ergebnis aller Bereichsprüfungen.`,
  [`The crypto object to use to decode the claim.`]: `Das Crypto-Objekt zum Entschlüsseln der Anforderung.`,
  [`The crypto object to use to encrypt the message.`]: `Das Crypto-Objekt zum Verschlüsseln des JWT.`,
  [`The crypto object to use to sign the JWT.`]: `Das Crypto-Objekt zum Signieren des JWT.`,
  [`The crypto object to use to verify the signature.`]: `Das Crypto-Objekt zum Überprüfen der Signatur.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `Die aktuelle Zuordnung enthält Referenzen zu nicht erkannten Schemaelementen.`,
  [`The current organization does not contain any catalogs.`]: `Die aktuelle Organisation enthält keine Kataloge.`,
  [`The default User registry must be included in the list`]: `Die Standard-Benutzerregistry muss in der Liste enthalten sein.`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `Die Definition, mit der die Nutzdaten validiert werden müssen ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Das Developer Portal stellt die Konsumentenansicht der veröffentlichten API-Produkte bereit. Nachdem Sie das Developer Portal für Ihren Katalog konfiguriert haben und ein oder mehrere API-Produkte veröffentlicht wurden, können Anwendungsentwickler die APIs durchsuchen und verwenden.`,
  [`The document is missing draft_api.info.version field.`]: `Für das Dokument fehlt das Feld 'draft_api.info.version'.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `Für das Dokument fehlt das Feld 'draft_api.info.x-ibm-name'.`,
  [`The document is missing draft_product.info.name field.`]: `Für das Dokument fehlt das Feld 'draft_product.info.name'.`,
  [`The document is missing draft_product.info.version field.`]: `Für das Dokument fehlt das Feld 'draft_product.info.version'.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `Die zusätzlichen Bedingungen geben ein Slicing-Argument an, das kein Argument für dieses Feld ist.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Dieses Feld enthält ein Grenzwertargument mit einem Standardwert. Daher kann die angegebene angenommene Größe nie angewendet werden.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Dieses Feld enthält ein Grenzwertargument mit einem Standardwert oder es ist ein Standardwert erforderlich. Daher kann die angegebene angenommene Größe nie angewendet werden.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Dieses Feld enthält weder eine angenommene Größe noch Slicing-Argumente.`,
  [`This field returns unbound list of values with composite type.`]: `Dieses Feld gibt die ungebundene Liste der Werte mit dem zusammengesetzten Typ zurück.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Dieses Feld gibt die ungebundene Liste der Werte mit dem Skalartyp zurück.`,
  [`This test panel is deprecated and will be removed soon.`]: `Diese Testanzeige ist veraltet und wird bald entfernt.`,
  [`This type has no fields or no matching field.`]: `Dieser Typ weist keine Felder oder übereinstimmende Felder auf.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Diese GatewayScript-Richtlinie sollte das APIM-Modul nicht verwenden, das nur für die Migration alter APIs vorgesehen ist.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Diese XSLT-Richtlinie sollte das APIM-Modul nicht verwenden, das nur für die Migration alter APIs bestimmt ist.`,
  [`This type will be deleted.`]: `Dieser Typ wird gelöscht.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `Das folgende Diagramm zeigt den Assembly-Ablauf für Ihre API. Verwenden Sie zum Ändern der Assembly den Assembly-Editor.`,
  [`The following files are available under the`]: `Die folgenden Dateien sind verfügbar unter`,
  [`The following files contain a private key and cannot be uploaded:`]: `Die folgenden Dateien enthalten einen privaten Schlüssel und können nicht hochgeladen werden:`,
  [`The following is available under the`]: `Folgendes ist verfügbar unter`,
  [`The following lifecycle actions require approval`]: `Für die folgenden Produktlebenszyklusaktionen ist eine Genehmigung erforderlich.`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `In der folgenden Liste werden die API-Entwürfe angezeigt, die in dieser Provider-Organisation erstellt wurden`,
  [`The following list displays the draft products that have been created in this provider organization`]: `In der folgenden Liste werden die Produktentwürfe angezeigt, die in dieser Provider-Organisation erstellt wurden.`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `Die folgende Liste zeigt alle Benutzer an, die als Mitglieder dieses Katalogs hinzugefügt wurden, sowie die ihnen zugeordneten Rollen.`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `Die folgende Liste zeigt alle Benutzer an, die als Mitglieder dieses Bereichs hinzugefügt wurden, sowie die ihnen zugeordneten Rollen.`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `Die folgenden Parameter entsprechen nicht den im OAuth-Provider konfigurierten Werten`,
  [`The following roles are available in the Cloud Manager`]: `Die folgenden Rollen sind im Cloud Manager verfügbar`,
  [`The following types/fields will also be removed:`]: `Die folgenden Typen/Felder werden ebenfalls entfernt:`,
  [`The following types/fields will be affected:`]: `Die folgenden Typen/Felder sind betroffen:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `Der Gateway-Eigenschaftswert {gatewayType} ist nicht zulässig. Zulässige Werte sind datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `Der zum Aufrufen der API verwendete Host`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `Der Keystore enthält übereinstimmende Paare aus öffentlichen Zertifikaten und privaten Schlüsseln, die zum Bestätigen der Identität und zum Verschlüsseln/Entschlüsseln der Datenübertragung über HTTPS verwendet werden.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `Der Keystore enthält übereinstimmende Paare aus öffentlichen Zertifikaten und privaten Schlüsseln, die zum Bestätigen der Identität und zum Verschlüsseln/Entschlüsseln der Datenübertragung über HTTPS verwendet werden. Für das Serverprofil ist ein Keystore erforderlich.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `Der Keystore enthält die öffentlichen Schlüssel und privaten Zertifikate, die vom Server oder Client zur Bestätigung der Identität präsentiert werden.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `Die Dauer (in Sekunden), die zum aktuellen Datum und der Uhrzeit hinzugefügt wird, in der der LTPA-Schlüssel gültig ist.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `Die Dauer (in Sekunden), die zum aktuellen Datum und der Uhrzeit hinzugefügt wird, in der das JWT gültig ist.`,
  [`The message to be sent with the error`]: `Die Nachricht, die mit dem Fehler gesendet wird`,
  [`The message to be included within exception.`]: `Die Nachricht, die in eine Ausnahmebedingung eingeschlossen werden soll.`,
  [`The HTTP status code to be included within exception.`]: `Der HTTP-Statuscode, der in eine Ausnahme eingeschlossen werden soll.`,
  [`The HTTP status reason phrase to be included within exception.`]: `Der Ausdruck für die HTTP-Statusursache, der in die Ausnahmebedingung eingeschlossen werden soll.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Der Name einer in der DataPower-Konfiguration definierten Burstbegrenzung.`,
  [`The name of a count limit defined in the datapower configuration.`]: `Der Name einer in der DataPower-Konfiguration definierten Zählergrenze.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Der Name einer in der DataPower-Konfiguration definierten Ratenbegrenzung.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `Der Name einer Variable, die zum Speichern der Antwortdaten von der Anforderung verwendet wird. Diese Variable kann anschließend in anderen Aktionen referenziert werden, wie z. B. in "Zuordnen".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `Der Name einer Variable, die zum Speichern des Ergebnis verwendet wird. Standardmäßig wird "message" verwendet.`,
  [`The name of parameter to find the ID.`]: `Der Name des Parameters zum Suchen der ID.`,
  [`The name of parameter to find the secret.`]: `Der Name des Parameters zum Suchen des geheimen Schlüssels.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `Der Name des LTPA-Schlüssels, die Sie zum Generieren des LTPA-Token verwenden möchten. Wenn Sie möchten, dass eine bestimmte Version des Schlüssels verwendet werden soll, geben Sie <keyname>:<version> an. Beispiel: my-ltpa-key:1.0.1. Wenn die Richtlinie automatisch die neueste Version des Schlüssels verwenden soll, geben Sie <keyname>:latest an. Beispiel: my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `Der Name des ausgelösten Fehlers`,
  [`The name of the variable to be added.`]: `Der Name der hinzuzufügenden Variable.`,
  [`The name of the variable to be cleared.`]: `Der Name der zu löschenden Variable.`,
  [`The name of the variable to be set.`]: `Der Name der festzulegenden Variable.`,
  [`The nature of the XML validation to be performed.`]: `Die Art der auszuführenden XML-Validierung.`,
  [`The operation to apply.`]: `Die anzuwendende Operation`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `Die Ausgabe dieser Zuordnung liegt innerhalb eines Arrays und ihre Inhalte werden durch Iterieren über die ausgewählte Eingabe erstellt. Wenn die ausgewählte Eingabe kein Array ist, wird ein Array mit der Länge '1' erstellt.`,
  [`The owner must be an existing user in your user registry`]: `Der Eigner muss ein vorhandener Benutzer in Ihrer Benutzerregistry sein.`,
  [`The owner of consumer organization has the following privileges:`]: `Der Eigner der Konsumentenorganisation verfügt über die folgenden Berechtigungen:`,
  [`The owner of provider organization has the following privileges:`]: `Der Eigner der Provider-Organisation verfügt über die folgenden Berechtigungen:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Der Eigner hat die folgenden Aufgaben: Er verwaltet den API-Produktlebenszyklus sowie eine oder mehrere API-Provider-Organisationen und Anwendungsprovider-Communitys. Außerdem ist er Autor von APIs und Produktdefinitionen.`,
  [`The page you are looking for might have been moved or does not exist.`]: `Die Seite, die Sie suchen, wurde möglicherweise verschoben oder ist nicht vorhanden.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `Die Seite, die Sie suchen, wurde möglicherweise verschoben oder ist nicht vorhanden. Bitten Sie Ihren Administrator um Unterstützung.`,
  [`The parameters needed to send a valid API call.`]: `Die Parameter, die zum Senden eines gültigen API-Aufrufs erforderlich sind.`,
  [`The password to use for HTTP Basic authentication.`]: `Das Kennwort für die HTTP-Basisauthentifizierung.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `Der Portalservice stellt ein Developer Portal bereit, das von Anwendungsentwicklern für die Erkennung von APIs und Onboard-Konsumenten verwendet wird.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `Der Portalservice stellt ein Developer Portal bereit, das von Anwendungsentwicklern für die Erkennung und Implementierung von APIs verwendet wird.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `Der Verarbeitungsstatus stellt eine Ansicht des Allgemeinzustands eines Gateways mit einer Zusammenfassung der neuesten Aktivitäten bereit.`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `Der Empfänger registriert sich als Eigner und erstellt eine neue Provider-Organisation. Nach der Registrierung kann sich der Benutzer bei API Manager als Eigner der Provider-Organisation anmelden.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `Der Stammelementname des XML-Dokuments. Dies ist nur erforderlich, wenn das JSON-Eingabedokument nicht hierarchisch ist und über mehrere Eigenschaften der Ausgangsebene verfügt.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Die Bereiche hier dienen dazu, Entwicklern verständlich zu machen, was für den Zugriff zulässig ist. Sie wirken sich nicht auf die Bereichsprüfung aus.`,
  [`The selected catalog will only be used to search consumer organizations`]: `Der ausgewählte Katalog wird nur zum Suchen von Konsumentenorganisationen verwendet.`,
  [`The selected catalog does not have any configured gateways`]: `Der ausgewählte Katalog verfügt über keine konfigurierten Gateways.`,
  [`The selected catalog does not have any compatible gateways`]: `Der ausgewählte Katalog verfügt über keine kompatiblen Gateways.`,
  [`Save Preferences`]: `Vorgaben speichern`,
  [`Preferences`]: `Vorgaben`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `Der ausgewählte Katalog wird nur zum Suchen von Konsumentenorganisationen und -gruppen verwendet.`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `Der ausgewählte Gateway-Typ macht die folgenden Richtlinien in Ihrer Assembly ungültig. Sie müssen diese Richtlinien löschen, bevor Sie diese API ausführen.`,
  [`The selected product does not contain this API`]: `Das ausgewählte Produkt enthält diese API nicht`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `Das ausgewählte Produkt wird abonnierbar für eine bestimmte Liste von Konsumentenorganisationen oder -gruppen.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `Die ausgewählten Services sind für eine bestimmte Liste von Provider-Organisationen verfügbar.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `Die ausgewählten Services sind für alle vorhandenen und neuen Provider-Organisationen verfügbar.`,
  [`The selected services will not be available to any provider organizations.`]: `Die ausgewählten Services sind für keine Provider-Organisationen verfügbar.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `Die Zeichenfolge, die als Schlüssel für den Cache verwendet werden soll. Wird sie nicht angegeben, wird die ganze URL-Zeichenfolge als Schlüssel verwendet.`,
  [`The server responded with a non-JSON body \n{body}`]: `Der Server antwortete mit einem Nicht-JSON-Hauptteil \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `Das Zeitintervall muss größer sein als die Lebensdauer des Zugriffstokens.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Das Token wird nicht in einem Cookie oder im lokalen Speicher gespeichert. Wenn Sie die Testanzeige schließen, geht das Token verloren, ist jedoch möglicherweise noch gültig.`,
  [`The transfer protocol of the API.`]: `Das Übertragungsprotokoll der API.`,
  [`The type of authentication.`]: `Der Typ der Authentifizierung.`,
  [`The type of authorization header.`]: `Der Typ des Berechtigungsheaders.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `Der Typ der festzulegenden Variable. Dies kann eine beliebige Zeichenfolge, eine beliebige Zahl oder ein beliebiger boolescher Wert sein.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `Der Typ der festzulegenden Variable. Dies kann eine Zeichenfolge, eine Zahl oder ein boolescher Wert sein.`,
  [`The username to use for HTTP Basic authentication.`]: `Der Benutzername für die HTTP-Basisauthentifizierung.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `Der Benutzer für diese Verbindung ist nicht mehr gültig. Erstellen Sie eine neue Verbindung.`,
  [`The value that the variable will be set to.`]: `Der Wert der festzulegenden Variable.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Der Wert dieses Felds ist eine Liste des zusammengesetzten Typs, der weder eine angenommene Größe noch Slicing-Argumente aufweist. Ist dies der Fall, kann das API-Gateway keinen Grenzwert für die Anzahl der Objekte festlegen, die der GraphQL-Server möglicherweise zurückgibt, wenn er die Abfrage empfängt.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Der Wert dieses Felds ist eine Liste des Skalartyps, der weder eine angenommene Größe noch Slicing-Argumente aufweist. Ist dies der Fall, kann das API-Gateway keinen Grenzwert für die Anzahl der Objekte festlegen, die der GraphQL-Server möglicherweise zurückgibt, wenn er die Abfrage empfängt.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `Die Version muss URL-sicher sein. Die Version des Objekts {version} und die per Slugify geänderte Version {slugVersion} stimmen nicht überein.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `Die "Name"-Eigenschaft muss URL-sicher sein (der Namenswert des Objekts "{Name}" und der Slugified-Namenswert "{slugName}" müssen übereinstimmen).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `Die Eigenschaft 'draft_api' muss eine OpenAPI-Definition enthalten. Die OpenAPI-Version 3 unterstützt nur Versionen 3.0.0-3.0.3 (angegebene Version {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `Das Namensfeld wird automatisch basierend auf dem von Ihnen angegebenen Titel ausgefüllt. Verwenden Sie den resultierenden Wert in API Connect-Befehlen und API-Aufrufen.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `Die Sichtbarkeitseinstellung bestimmt, welche Provider-Organisationen auf eine Ressource zugreifen können. Die Auswahlmöglichkeiten sind: "Öffentlich" (die Ressource kann von allen Provider-Organisationen verwendet werden), "Angepasst" (die Ressource kann nur von den von Ihnen festgelegten Provider-Organisationen verwendet werden) und "Privat" (die Ressource ist nicht sichtbar und kann von keiner Provider-Organisation verwendet werden).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `Die Sichtbarkeitseinstellung bestimmt, welche Provider-Organisationen einen Service verwenden können. Die Auswahlmöglichkeiten sind: "Öffentlich" (der Service kann von allen Provider-Organisationen verwendet werden), "Angepasst" (der Service kann nur von den von Ihnen festgelegten Provider-Organisationen verwendet werden) und "Privat" (der Service ist nicht sichtbar und kann von keiner Provider-Organisation verwendet werden).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `Die Sichtbarkeitseinstellung bestimmt, welche Provider-Organisationen ein Gateway verwenden können. Folgende Sichtbarkeitsebenen sind vorhanden: "Öffentlich" (das Gateway kann von allen Provider-Organisationen verwendet werden); "Angepasst" (das Gateway kann nur von der von Ihnen angegebenen Provider-Organisation verwendet werden) und "Privat" (das Gateway ist nicht sichtbar und kann von keiner Provider-Organisation verwendet werden).`,
  [`The {title} role`]: `Die {title}-Rolle`,
  [`The {url} resource does not exist`]: `Die Ressource {url} ist nicht vorhanden.`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Es gibt inkompatible APIs, da diese über einen anderen Gateway-Typ für dieses Produkt verfügen.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Wählen Sie dann das Zertifikat für die Domäne des Managementendpunkts aus. Wählen Sie außerdem das entsprechende CA-Bundle aus, wenn das Zertifikat nicht von einer gängigen Zertifizierungsstelle zugewiesen wurde. Das Zertifikat und das Bundle müssen vom Zertifikatmanagerservice verwaltet werden.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Geben Sie dann die Domänen an, die von diesem Gateway verarbeitet werden. Die SNI-Technik wird angewendet. Dadurch können mehrere Domänen über dieselbe IP-Adresse und denselben Port bedient werden, ohne dass dasselbe Zertifikat erforderlich ist. Das Platzhalterformat wird unterstützt. Die (catch-all)-Standarddomäne '*' muss als letzter Eintrag in der Tabelle enthalten sein. Geben Sie ggf. andere Domänennamen ein und geben Sie in jedem Fall ein Zertifikat an, das vom Zertifikatmanagerservice verwaltet wird.`,
  [`There should be at least {minSelected} {label} selected.`]: `Es sollte(n) mindestens {minSelected} {label} ausgewählt werden.`,
  [`There are no API changes to publish.`]: `Es sind keine API-Änderungen zum Veröffentlichen vorhanden.`,
  [`There are no gateway services`]: `Es sind keine Gateway-Services vorhanden.`,
  [`There are no assemblies that match the chosen filters.`]: `Es gibt keine Assemblys, die den ausgewählten Filtern entsprechen.`,
  [`There are no burst limits yet.`]: `Es sind noch keine Burstbegrenzungen vorhanden.`,
  [`There are no catalogs`]: `Es sind keine Kataloge vorhanden.`,
  [`There are no consumer organizations.`]: `Es sind keine Konsumentenorganisationen vorhanden.`,
  [`There are no consumer organizations. You can create one`]: `Es sind keine Konsumentenorganisationen vorhanden. Sie können einen erstellen.`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `Es sind keine Konsumentenorganisationen vorhanden. Sie könne [here]({link}) einen erstellen`,
  [`There are no email servers`]: `Es gibt keine E-Mail-Server`,
  [`There are no gateway services configured for the sandbox catalog`]: `Für den Sandbox-Katalog sind keine Gateway-Services konfiguriert`,
  [`There are no lifecycle approvals enabled`]: `Es sind keine Lebenszyklusgenehmigungen aktiviert.`,
  [`There are no new changes.`]: `Es gibt keine neuen Änderungen.`,
  [`There are no products to display`]: `Es gibt keine Produkte, die angezeigt werden können`,
  [`There are no products to replace`]: `Es gibt keine zu ersetzenden Produkte`,
  [`There are no products to set migration target`]: `Es gibt keine Produkte zum Festlegen des Migrationsziels`,
  [`There are no products to supersede`]: `Es gibt keine Produkte, die außer Kraft gesetzt werden können`,
  [`There are no properties to configure for this policy`]: `Für diese Richtlinie sind keine Eigenschaften zum Konfigurieren vorhanden`,
  [`There are no rate limits yet.`]: `Es sind noch keine Ratenbegrenzungen vorhanden.`,
  [`There are no security definitions`]: `Es sind keine Sicherheitsdefinitionen vorhanden.`,
  [`There are no spaces`]: `Es sind keine Bereiche vorhanden.`,
  [`There are no target services`]: `Es sind keine Zielservices vorhanden.`,
  [`There are no tasks to be displayed`]: `Keine Tasks zum Anzeigen vorhanden`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Es gibt nicht gespeicherte Änderungen. Möchten Sie wirklich fortfahren?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Es gibt nicht gespeicherte Änderungen. Sie werden gelöscht, wenn Sie fortfahren. Soll die Aktion tatsächlich ausgeführt werden?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `In Ihrer YAML-Datei ist eine nicht vorhandene API vorhanden, die nicht aufgeführt werden kann. Prüfen Sie die Registerkarte 'Quelle'.`,
  [`There is no item in the list.`]: `In der Liste ist kein Element vorhanden.`,
  [`There is no space`]: `Kein Bereich vorhanden`,
  [`There is no warning in the schema.`]: `Im Schema ist keine Warnung vorhanden.`,
  [`There was no data found to populate the data table.`]: `Es wurden keine Daten zum Ausfüllen der Datentabelle gefunden.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Diese Endpunkte werden für alle APIs im Katalog verwendet.`,
  [`Third party OAuth provider`]: `OAuth-Provider eines Drittanbieters`,
  [`Third party OAuth provider summary`]: `Zusammenfassung zu OAuth-Provider eines Drittanbieters`,
  [`Third party`]: `Drittanbieter`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Diese API enthält noch keine Assembly. Möchten Sie eine erstellen?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Diese Ressource ist nicht mehr vorhanden. Aktualisieren Sie die Ergebnisse vom Server.`,
  [`Please refresh the results from the server`]: `Aktualisieren Sie die Ergebnisse vom Server.`,
  [`Exact match`]: `Exakte Übereinstimmung`,
  [`Closest match`]: `Größte Übereinstimmung`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Diese API wird derzeit vom Produkt {productName} verwendet. Löschen Sie sie zuerst aus dem Produkt und löschen Sie dann die API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Diese API steht zum Aufrufen zur Verfügung, wenn die folgende Option aktiviert ist.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Für diesen Katalog ist kein automatisches Abonnement aktiviert. Um in diesem Katalog zu testen, wählen Sie weiter unten ein Produkt, einen Plan und eine Anwendung aus.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Dieser Endpunkt wird als Standardwert für APIs verwendet, die keinen Basisendpunkt definieren.`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Dies ist eine Technologievorschaufunktion, die zukünftig entwickelt und kontinuierlich verbessert werden wird.`,
  [`This is the currently configured notification server.`]: `Dies ist der derzeit konfigurierte Benachrichtigungsserver.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Dies ist das Verzeichnis, in dem Ihre Dateien, einschließlich Richtlinien und Erweiterungen, gespeichert werden`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `In diesem Fall werden APIs ausgeführt und Richtlinien und Erweiterungen können aufgerufen werden.`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Diese OAuth-Richtlinie führt alle OAuth-Protokollschritte aus, die standardmäßig für den ausgegebenen Pfad erforderlich sind. Die Eingaben und Ausgaben der einzelnen Schritte werden durch dokumentierte Kontextvariablen gesteuert. Fügen Sie unterstützte OAuth-Komponenten hinzu oder entfernen Sie sie nach Bedarf.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Diese Oauth-Richtlinie führt alle OAuth/OpenID Connect-Protokollschritte aus, die für die OAuth-Validierung standardmäßig erforderlich sind. Die Eingaben und Ausgaben der einzelnen Schritte werden durch dokumentierte Kontextvariablen gesteuert. Fügen Sie unterstützte OAuth-Komponenten hinzu oder entfernen Sie sie nach Bedarf.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Diese Oauth-Richtlinie führt alle OAuth/OpenID Connect-Protokollschritte aus, die für alle anderen Pfade standardmäßig erforderlich sind. Die Eingaben und Ausgaben der einzelnen Schritte werden durch dokumentierte Kontextvariablen gesteuert. Fügen Sie unterstützte OAuth-Komponenten hinzu oder entfernen Sie sie nach Bedarf.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Diese Oauth-Richtlinie führt alle OAuth/OpenID Connect-Protokollschritte aus, die für AZ-Code-Pfade standardmäßig erforderlich sind. Die Eingaben und Ausgaben der einzelnen Schritte werden durch dokumentierte Kontextvariablen gesteuert. Fügen Sie unterstützte OAuth-Komponenten hinzu oder entfernen Sie sie nach Bedarf.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Diese Oauth-Richtlinie führt alle OAuth/OpenID Connect-Protokollschritte aus, die für Tokenpfade standardmäßig erforderlich sind. Die Eingaben und Ausgaben der einzelnen Schritte werden durch dokumentierte Kontextvariablen gesteuert. Fügen Sie unterstützte OAuth-Komponenten hinzu oder entfernen Sie sie nach Bedarf.`,
  [`This operation is secured with access code flow OAuth`]: `Diese Operation wird mit Zugriffscode-Flow-OAuth gesichert`,
  [`This operation is secured with application flow OAuth`]: `Diese Operation wird mit Anwendungs-Flow-OAuth gesichert`,
  [`This operation is secured with basic authentication`]: `Diese Operation wird mit einer Basisauthentifizierung gesichert`,
  [`This operation is secured with implicit flow OAuth`]: `Diese Operation wird mit impliziertem Flow-OAuth gesichert`,
  [`This operation is secured with password flow OAuth`]: `Diese Operation wird mit Kennwort-Flow-OAuth gesichert`,
  [`This policy is not available on the selected gateway`]: `Diese Richtlinie ist auf dem ausgewählten Gateway nicht verfügbar`,
  [`This policy is used by one other operation`]: `Diese Richtlinie wird von einer weiteren Operation verwendet`,
  [`This policy is used by {{count}} other operations`]: `Diese Richtlinie wird von {{count}} weiteren Operationen verwendet`,
  [`This role cannot be edited`]: `Diese Rolle kann nicht bearbeitet werden.`,
  [`This role cannot be edited.`]: `Diese Rolle kann nicht bearbeitet werden.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Diese Einstellung steuert das Verhalten, wenn eine Zuordnung fehlschlägt, weil die zugehörige Eingabe nicht vorhanden ist und keine Standardzuordnung konfiguriert ist. Das Standardverhalten besteht darin, keine Änderungen an der Ausgabe vorzunehmen, aber wenn Sie dieses Kontrollkästchen aktivieren, wird ein leeres Objekt für den übergeordneten Teil der Zielzuordnung erstellt, das das Verhalten der Version 4 simuliert.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Diese Benutzersicherheitsrichtlinie führt die Prüfungen 'EI' (einfach) und 'AU' (Authentifizierungs-URL) für die OAuth-Assembly durch. Ändern Sie die Sicherheitsprüfmethode nach Bedarf.`,
  [`This will enable/disable application lifecycle`]: `Dadurch wird der Anwendungslebenszyklus aktiviert/inaktiviert`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Damit wird die Selbstgenehmigung für Tasks aktiviert/inaktiviert. Die Selbstgenehmigung für Tasks ermöglicht die Genehmigung von Tasks sowohl durch deren Ersteller als auch durch Mitarbeiter. Diese Option kann praktisch sein, wenn andere Genehmiger nicht zur Verfügung stehen, sie ermöglicht aber effektiv die Umgehung von Prüfungen durch einen anderen Benutzer.`,
  [`This will unset the catalog as the default catalog.`]: `Dadurch wird der Katalog nicht als Standardkatalog festgelegt.`,
  [`Throw`]: `Auslösen`,
  [`Time`]: `Zeit`,
  [`Time Interval`]: `Zeitintervall`,
  [`Time Limit`]: `Zeitlimit`,
  [`Time to Live`]: `Lebensdauer`,
  [`Time to live`]: `Lebensdauer`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Wartezeit für eine Antwort vom Endpunkt. Standardwert sind 60 Sekunden.`,
  [`Timeout`]: `Zeitlimit`,
  [`To`]: `An`,
  [`to`]: `bis`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Wenn Sie einen Benutzer als Mitglied der Verwaltungsorganisation hinzufügen möchten, wählen Sie die Benutzerregistry aus, geben Sie ihren Benutzernamen ein und wählen Sie die Rollen aus, die Sie zuordnen möchten. Wenn der Benutzerregistrytyp 'Lokale Benutzerregistry' ist, haben Sie außerdem die Möglichkeit, einen neuen Benutzer zu erstellen, der dann als Mitglied hinzugefügt wird. Die Mitgliedschaft wird sofort aktiviert und der angegebene Benutzer kann sich an der Benutzerschnittstelle von Cloud Manager anmelden`,
  [`To add a user as a member of a provider organization`]: `Gehen Sie wie folgt vor, um einen Benutzer als Mitglied einer Provider-Organisation hinzuzufügen`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Um einen Benutzer als Katalogmitglied hinzuzufügen, wählen sie den Benutzernamen aus und ordnen Sie die erforderlichen Rollen hinzu. Sie können jeden Benutzer hinzufügen, der bereits Mitglied eines anderen Katalogs oder Bereichs in der Provider-Organisation ist und der derzeit weder ein Mitglied noch der Eigner dieses Katalogs ist.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Um einen Benutzer als Bereichsmitglied hinzuzufügen, wählen sie den Benutzernamen aus und ordnen Sie die erforderlichen Rollen hinzu. Sie können jeden Benutzer hinzufügen, der bereits Mitglied eines anderen Katalogs oder Bereichs in der Provider-Organisation ist und der derzeit weder ein Mitglied noch der Eigner dieses Bereichs ist.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Wenn Sie Mitglieder hinzufügen oder entfernen möchten, verwenden Sie den Service [identity and access](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Wenn Sie Mitglieder hinzufügen möchten, verwenden Sie den Service [identity and access](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Um einen Benutzer als Mitglied der Organisation hinzuzufügen, wählen Sie dessen Benutzerregistry aus, geben Sie den entsprechenden Benutzernamen ein und ordnen Sie ihm eine Gruppe von Rollen zu.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Um einen Benutzer als Mitglied hinzuzufügen, wählen Sie den entsprechenden Benutzernamen aus und ordnen Sie ihm eine Gruppe von Rollen zu.`,
  [`To assign default gateways, click **Edit**.`]: `Klicken Sie zum Zuweisen von Standardgateways auf **Bearbeiten**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Um diese Software weiter verwenden zu können, müssen Sie den Bedingungen der Softwarelizenzvereinbarung zustimmen.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Führen Sie ein Upgrade auf einen Plan für Unternehmen durch, um mit der Verwendung von API Connect fortzufahren.`,
  [`To get started, you must first enable spaces.`]: `Um zu beginnen, müssen Sie zunächst Bereiche aktivieren.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Führen Sie zum Installieren und Ausführen des Toolkits die folgenden Schritte aus. [Weitere Informationen]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Geben Sie zum Einladen eines neuen Benutzers als Mitglied der Verwaltungsorganisation die E-Mail-Adresse oder den Namen des Benutzers ein oder wählen Sie den Namen eines Benutzers in einer Benutzerregistry aus.  Allen Mitgliedern wird automatisch die Mitgliederrolle zugewiesen, die grundlegende Berechtigungen bereitstellt und nicht gelöscht werden kann. Wenn keine weiteren Berechtigungen erforderlich sind, lassen Sie die übrigen Rollen unausgewählt.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Um einen Eigner einzuladen, der noch nicht in Ihrer Benutzerregistry vorhanden ist, geben Sie die entsprechende E-Mail-Adresse ein.`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Besuchen Sie die Seite **Gateways**, um ein neues Gateway zu registrieren.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Um Werte durch eine der folgenden Bereichseigenschaften zu ersetzen, geben Sie $() mit dem Namen der Eigenschaft in den Klammern ein.`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Um eine E-Mail-Einladung an einen neuen Benutzer zu senden, der sich als Mitglied der Admin-Organisation registrieren soll, geben Sie eine gültige E-Mail-Adresse ein und wählen Sie die Rollen aus, die Sie dem Benutzer zuordnen möchten. Eine E-Mail mit einem Aktivierungslink, in der die Person zur Registrierung eingeladen wird, wird an die E-Mail-Adresse gesendet. Der Benutzer wird in den Status 'Anstehend' versetzt. Nach dem Ausfüllen des Registrierungsformulars zum Aktivieren des Accounts wird der Status in 'Aktiviert' geändert. Sie können sich dann an der Cloud Manager-Benutzerschnittstelle mit dem API Connect-Benutzernamen anmelden, den sie bei der Kontoaktivierung angegeben haben.`,
  [`Toggle`]: `Umschalten`,
  [`Toggle application lifecycle?`]: `Anwendungslebenszyklus ein-/ausschalten?`,
  [`Toggle default catalog?`]: `Standardkatalog umschalten?`,
  [`Toggle info panel between right and bottom`]: `Infofenster zwischen rechts und unten hin- und herschalten`,
  [`Toggle profile`]: `Profil ein-/ausschalten`,
  [`Toggle spaces?`]: `Bereiche wechseln?`,
  [`Toggle task self-approval?`]: `Selbstgenehmigung der Task ein/-ausschalten?`,
  [`Token`]: `Token`,
  [`Token endpoint`]: `Tokenendpunkt`,
  [`Token Expiry`]: `Tokenablaufzeit`,
  [`Token Management`]: `Token-Management`,
  [`Token Output`]: `Tokenausgabe`,
  [`Token URL`]: `Token-URL`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Token-URL muss mit {endpoint} übereinstimmen, wie durch OAuth-Provider {providerTitle} definiert`,
  [`Token Version`]: `Tokenversion`,
  [`Token management`]: `Token-Management`,
  [`Token management type`]: `Token-Managementtyp`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `Das Token-Management ermöglicht Ihnen das Verhindern von Attacken durch Nachrichtenaufzeichnung und -wiederholung, das Verwalten von Tokens einschließlich der Angabe, ob und wie Clientanwendungen oder Ressourceneigner OAuth-Token widerrufen. API Connect unterstützt Token-Management mit einem native Gateway oder einem Endpunkt eines Drittanbieters. Bei Verwendung eines nativen Gateways wird die Quotenerzwingung zum Verwalten von Tokens verwendet. Bei Verwendung eines Endpunkts eines Drittanbieters stellen Sie zur Verwaltung von Tokens eine URL zu einem externen Service bereit.`,
  [`Token path`]: `Tokenpfad`,
  [`Token revocation`]: `Tokenwiderruf`,
  [`Token secret`]: `Geheimer Tokenschlüssel`,
  [`Token validation`]: `Tokenvalidierung`,
  [`Tokens`]: `Tokens`,
  [`Token relay`]: `Tokenrelais`,
  [`Toolkit credentials`]: `Toolkit-Berechtigungsnachweise`,
  [`Token Relay`]: `Tokenrelais`,
  [`Topology`]: `Topologie`,
  [`Topology Administrator`]: `Topologieadministrator`,
  [`Total time`]: `Gesamtzeit`,
  [`Transfer Ownership to a New User`]: `Eigentumsrecht auf einen neuen Benutzer übertragen`,
  [`Transfer Ownership to an Existing User`]: `Eigentumsrecht auf einen vorhandenen Benutzer übertragen`,
  [`Transfer ownership`]: `Eigentumsrecht übertragen`,
  [`Transfer ownership to`]: `Eigentumsrecht übertragen auf`,
  [`Transfer ownership to a new user`]: `Eigentumsrecht auf einen neuen Benutzer übertragen`,
  [`Transfer ownership to an existing user`]: `Eigentumsrecht auf einen vorhandenen Benutzer übertragen`,
  [`Transforms`]: `Umwandlungen`,
  [`Truststore Certificates`]: `Truststore-Zertifikate`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Truststore-Zertifikate sind öffentliche Zertifikate, die von einer Zertifizierungsstelle ausgestellt wurden.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores enthalten vertrauenswürdige Zertifikate, die verifizierte öffentliche Schlüssel enthalten. Die Zertifikate im Truststore werden in der Regel von einer unabhängigen Zertifizierungsstelle angefordert.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores enthalten vertrauenswürdige Zertifikate mit überprüften öffentlichen Schlüsseln. Die Zertifikate im Truststore werden in der Regel von einer unabhängigen Zertifizierungsstelle angefordert.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores speichern vertrauenswürdige Zertifikate, einschließlich verifizierter öffentlicher Schlüssel. Die Zertifikate im Truststore werden in der Regel von einer unabhängigen Zertifizierungsstelle angefordert.`,
  [`Try again or upload a schema`]: `Versuchen Sie es erneut oder laden Sie ein Schema hoch.`,
  [`Try refreshing the page or contacting support.`]: `Aktualisieren Sie die Seite oder wenden Sie sich an den Support.`,
  [`Turkish`]: `Türkisch`,
  [`Tutorials`]: `Tutorials`,
  [`Types`]: `Typen`,
  [`Type of user`]: `Benutzertyp`,
  [`Type or select a catalog`]: `Einen Katalog eingeben oder auswählen`,
  [`Type to add organizations`]: `Organisationen durch Eingeben hinzufügen`,
  [`TypeError: Failed to fetch`]: `TypeError: Fehler beim Abrufen`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `URL des Managementendpunkts`,
  [`URLs for all operations in the API begin with this value.`]: `URLs für alle Operationen in der API beginnen mit diesem Wert.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `Benutzerdefiniert`,
  [`Userinfo POST`]: `Benutzerinfo-POST`,
  [`USER MANAGED`]: `BENUTZERVERWALTET`,
  [`USERNAME`]: `BENUTZERNAME`,
  [`User can delete the keystore history.`]: `Der Benutzer kann den Keystore-Verlauf löschen.`,
  [`Unable to parse API definition: {msg}`]: `API-Definition kann nicht analysiert werden: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Aufgrund von Fehlern, die beim Entfernen von Typen und/oder Feldern gefunden wurden, kann der Vorgang nicht fortgesetzt werden.`,
  [`Unarchive`]: `Archivierung aufheben`,
  [`Unassociate`]: `Zuordnung aufheben`,
  [`Unassociate analytics service`]: `Zuordnung des Analyseservice aufheben`,
  [`Unauthorized`]: `Nicht autorisiert`,
  [`Unauthorized API Request`]: `Nicht berechtigte API-Anforderung`,
  [`Unbound lists`]: `Nicht gebundene Listen`,
  [`Unit`]: `Einheit`,
  [`Union`]: `Union`,
  [`Union type`]: `Union-Verknüpfungstyp`,
  [`Unique email address`]: `Eindeutige E-Mail-Adresse`,
  [`Unlimited`]: `Unbegrenzt`,
  [`Unnecessary assumed size`]: `Nicht erforderliche angenommene Größe`,
  [`Unnecessary slicing arguments`]: `Nicht erforderliche Slicing-Argumente`,
  [`Unrecognized Schema Element(s) Encountered`]: `Nicht erkannte(s) Schemaelement(e)`,
  [`Unsupported media type ({type})`]: `Nicht unterstützter Medientyp ({type})`,
  [`Untitled`]: `Unbenannt`,
  [`Update`]: `Aktualisieren`,
  [`Update gateway services`]: `Gateway-Services aktualisieren`,
  [`Update assembly`]: `Assembly aktualisieren`,
  [`Update group failed.`]: `Aktualisieren der Gruppe ist fehlgeschlagen.`,
  [`Update schema defaults giving preference to`]: `Schemastandardwerte aktualisieren, und Folgendes vorziehen`,
  [`Update the sender details that are displayed on invitation emails`]: `Aktualisieren Sie die Absenderdetails, die in Einladungs-E-Mails angezeigt werden.`,
  [`Update the summary details for this role`]: `Aktualisieren Sie die Zusammenfassungsdetails für diese Rolle.`,
  [`Updating Draft API`]: `Entwurfs-API wird aktualisiert`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `Beim Aktualisieren von Schemastandardwerten werden Ihre Änderungen überschrieben. Möchten Sie wirklich fortfahren?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `Die Aktualisierung der API des Typs {existingType} mit einer API des Typs {type} ist nicht zulässig.`,
  [`Upload`]: `Hochladen`,
  [`Upload a schema definition language file to replace your schema.`]: `Laden Sie eine SDL-Datei (Scheme Definition Language) hoch, um Ihr Schema zu ersetzen.`,
  [`Upload GraphQL schema`]: `GraphQL-Schema hochladen`,
  [`Upload local`]: `Lokal hochladen`,
  [`Upload policies`]: `Richtlinien hochladen`,
  [`Upload policy`]: `Richtlinie hochladen`,
  [`Upload schema`]: `Schema hochladen`,
  [`Upload schema definition language (SDL) file`]: `SDL-Datei (Schema Definition Language) hochladen`,
  [`Upload truststore certificates`]: `Truststore-Zertifikate hochladen`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Laden Sie das X509-Zertifikat für Ihre Anwendung im PEM-Format hoch. Sie können nur eine PEM-Datei hochladen, aber Sie können mehrere Zertifikate in eine einzelne Datei einschließen.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Laden Sie das X509-Zertifikat für Ihre Anwendung im PEM-Format hoch.`,
  [`Upload your public certificate`]: `Laden Sie Ihr öffentliches Zertifikat hoch`,
  [`Uploaded and validated YAML file`]: `Hochgeladene und geprüfte YAML-Datei`,
  [`Upload extension`]: `Erweiterung hochladen`,
  [`Use all compatible gateway services`]: `Alle kompatiblen Gateway-Services verwenden`,
  [`Use Content Type`]: `Inhaltstyp verwenden`,
  [`Use DataPower API Gateway`]: `DataPower-API-Gateway verwenden`,
  [`Use DataPower Gateway`]: `DataPower-Gateway verwenden`,
  [`Use Dynamic DNS`]: `Dynamisches DNS verwenden`,
  [`Use JSONata to specify the property to redact or remove`]: `Anhand von JSONata die Eigenschaft zur Neubearbeitung oder zum Entfernen festlegen`,
  [`Use XPath to specify the property to redact or remove.`]: `Legen Sie anhand von XPath die Eigenschaft zur Neubearbeitung oder zum Entfernen fest.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Verwenden Sie einen ausgewählten Katalog als Standardkatalog für die Veröffentlichung.`,
  [`Use an existing JSON or YAML definition of the product`]: `Verwenden sie eine vorhandene JSON- oder YAML-Definition des Produkts`,
  [`Use an existing definition of a REST proxy`]: `Verwenden Sie eine vorhandene Definition eines REST-Proxys`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Vorhandene Definition einer REST-Proxy- oder SOAP-API verwenden`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Vorhandene Definition eines REST-Proxy, eines GraphQL-Proxy oder einer SOAP-API verwenden`,
  [`Use an existing definition of a product`]: `Verwenden Sie eine vorhandene Definition eines Produkts`,
  [`Use context current payload`]: `Aktuelle Kontextnutzdaten verwenden`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Angepasste Katalogendpunkt-URL verwenden (v5-Kompatibilität)`,
  [`Use dynamic DNS`]: `Dynamisches DNS verwenden`,
  [`Use endpoint from API`]: `Endpunkt aus API verwenden`,
  [`Use gateway URLs`]: `Gateway-URLs verwenden`,
  [`Use gateway service configured OAuth shared secret key`]: `Vom Gateway-Service konfigurierten geheimen OAuth-Schlüssel für gemeinsame Nutzung verwenden`,
  [`Use group authentication`]: `Gruppenauthentifizierung verwenden`,
  [`Use only first array element`]: `Nur das erste Arrayelement verwenden`,
  [`Use static DNS`]: `Statisches DNS verwenden`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Portal als Endpunkt für Datenverkehr mit externem OIDC-Provider verwenden`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Verwenden Sie den API Explorer zum Testen und Untersuchen Ihrer APIs. Der Explorer zeigt die Operationen, Definitionen und die Dokumentation für alle APIs, die in Ihrem Projektverzeichnis enthalten sind. Der Explorer führt die Parameter, Modellinstanzdaten und Antwortcodes gemeinsam mit dem Vorlagencode für die Ausführung Ihrer API auf.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Verwenden Sie das Aktionsmenü, um Ihre APIs zu verwalten und zu veröffentlichen.`,
  [`Use the actions menu to manage and publish your products`]: `Verwenden Sie das Aktionsmenü, um Ihre Produkte zu verwalten und zu veröffentlichen.`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Verwenden Sie die Aktivitätenprotokollrichtlinie, um Ihre Protokollierungsvorgaben für die API-Aktivität zu konfigurieren, die in der Analyse gespeichert wird. Die Vorgaben, die Sie angeben, setzen die Standardeinstellungen zum Erfassen und Speichern von Details der API-Aktivität außer Kraft.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Verwenden Sie die Clientsicherheitsrichtlinie, um die Berechtigungsnachweise der Clients zu extrahieren und zu authentifizieren.`,
  [`Use the default built-in Sandbox Catalog`]: `Standardmäßigen integrierten Sandbox-Katalog verwenden`,
  [`Use the editor for manual input or upload a file below.`]: `Verwenden Sie den Editor für manuelle Eingabe oder laden Sie unten eine Datei hoch.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Verwenden Sie den Editor für manuelle Eingabe oder laden Sie unten eine Datei hoch. Das aktuelle Schema kann nur in XML bearbeitet werden, wenn eine XML/XSD-Datei definiert ist.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Verwenden Sie die erzwungene Erweiterung, um anzugeben, ob das API Connect-Gateway verwendet wird, um die API zu erzwingen.`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Verwenden Sie die folgende Managementendpunkt-URL, wenn Sie mit der Befehlszeilenschnittstelle arbeiten.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Verwenden Sie die folgende Managementendpunkt-URL, wenn Sie eine Cloudverbindung in API Designer erstellen.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Verwenden Sie die gatewayscript-Richtlinie, um ein angegebenes DataPower-GatewayScript-Programm auszuführen.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Verwenden Sie die graphql-introspect-Richtlinie, um ein GraphQL-Schema zu überwachen.`,
  [`Use the host value from Open API Definition`]: `Verwenden Sie den Hostwert der Open API-Definition.`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Verwenden Sie die if-Richtlinie, um einen Abschnitt der Assembly anzuwenden, wenn eine Bedingung erfüllt ist.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Verwenden Sie die javascript-Richtlinie, um ein angegebenes JavaScript-Programm auszuführen.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Verwenden Sie die json-in-xml-Richtlinie, um den Inhalt Ihrer Nutzdaten von JSON in XML zu konvertieren.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Verwenden Sie die log-Richtlinie, um die Standardkonfiguration für die Aktivitätsprotokollierung für eine API anzupassen oder zu überschreiben.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Verwenden Sie die map-Richtlinie zum Zuordnen von Umwandlungen zu Ihrem Assembly-Ablauf und geben Sie die Beziehungen zwischen Variablen an.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Verwenden Sie die Zuordnungsrichtlinie, um die Beziehungen zwischen Variablen in Ihrem API-Datenfluss anzugeben und um Umwandlungen auf diese Variablen anzuwenden.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Verwenden Sie die OAuth-Richtlinie zum Generieren von OAuth-Tokens.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Verwenden Sie die operation-switch-Richtlinie, um einen Abschnitt der Assembly auf eine bestimmte Operation anzuwenden.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Verwenden Sie die Parsing-Richtlinie, um eine Anforderung oder Nachricht als XML oder JSON zu parsen oder um Binärdaten in ein großes Binärobjekt (BLOB) zu parsen.`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Verwenden Sie die Richtlinie zum Festlegen von Variablen, um eine Laufzeitvariable auf einen Zeichenfolgewert zu setzen oder um eine Laufzeitvariable hinzuzufügen oder zu löschen.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Verwenden Sie die throw-Richtlinie, um einen Fehler auszulösen, wenn die Richtlinie während der Ausführung eines Assembly-Ablaufs erreicht wird.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Verwenden Sie die Benutzersicherheitsrichtlinie, um die Identität des Benutzers zu extrahieren, authentifizieren und zu berechtigen.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Verwenden Sie die Validierungsrichtlinie mit einem DataPower-Gateway, um die Nutzdaten in einem Assemblydatenfluss anhand eines JSON- oder XML-Schemas zu validieren.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Verwenden Sie die xml-in-json-Richtlinie, um den Inhalt Ihrer Nutzdaten von XML in JSON zu konvertieren.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Verwenden Sie die xslt-Richtlinie, um eine XSLT-Umwandlung für die Nutzdaten auszuführen.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Wird zum Aktivieren/Inaktivieren einer Content-Encoding-Komprimierung beim Upload verwendet.`,
  [`User`]: `Benutzer`,
  [`User group prefix`]: `Benutzergruppenpräfix`,
  [`User group suffix`]: `Benutzergruppensuffix`,
  [`User mapping`]: `Benutzerzuordnung`,
  [`User Registries`]: `Benutzerregistrys`,
  [`User registries`]: `Benutzerregistrys`,
  [`User Registry Name`]: `Benutzerregistry-Name`,
  [`User registry name`]: `Benutzerregistry-Name`,
  [`User Registry cannot be deleted`]: `Benutzerregistry kann nicht gelöscht werden`,
  [`User Registry "{name}" has been deleted`]: `Benutzerregistry "{name}" wurde gelöscht`,
  [`User security`]: `Benutzersicherheit`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Benutzerregistrys werden in jedem Bereich verwaltet, wenn Bereiche aktiviert sind. Rufen Sie die Bereichseinstellungen auf, um Benutzerregistrys zu verwalten.`,
  [`User registries defined for consumer onboarding`]: `Für Konsumenten-Onboarding definierte Benutzerregistrys`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Benutzerregistrys, die für das Konsumentenonboarding definiert sind \n\nJeder Account im Developer Portal, einschließlich verschiedener Benutzerregistrys für dieselbe Site, muss über eine eindeutige E-Mail-Adresse verfügen, einschließlich des Administratorkontos der Site.`,
  [`User registry`]: `Benutzerregistry`,
  [`User registry configuration has been updated.`]: `Die Benutzerregistry-Konfiguration wurde aktualisiert.`,
  [`User registry has been created`]: `Benutzerregistry wurde erstellt`,
  [`User registry {title} deleted.`]: `Benutzerregistry {title} wurde gelöscht.`,
  [`User registry {title} has been created.`]: `Benutzerregistry {title} wurde erstellt.`,
  [`User registry is not visible or not available.`]: `Benutzerregistry ist nicht sichtbar oder nicht verfügbar.`,
  [`User registry {title} has been updated.`]: `Die Benutzerregistry {title} wurde aktualisiert.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `Die Benutzersicherheitseinstellungen gelten nur, wenn die ausgewählten Bewilligungstypen einen oder mehrere der folgenden Werte enthalten: Implizit, Zugriffscode und Ressourceneigner - Kennwort.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `Die Benutzersicherheitseinstellungen gelten nur, wenn die ausgewählten Bewilligungstypen einen oder mehrere der folgenden Werte enthalten: Implizit, Zugriffscode und Ressourceneigner - Kennwort. Wenn Sie an dieser Seite Änderungen vornehmen, sollen Sie außerdem auf der Seite 'Konfiguration' mindestens einen dieser Bewilligungstypen auswählen.`,
  [`UserInfo endpoint`]: `UserInfo-Endpunkt`,
  [`UserInfo URL`]: `UserInfo-URL`,
  [`Username`]: `Benutzername`,
  [`Username / Email`]: `Benutzername/E-Mail`,
  [`Username and password fields are required in order to test configuration`]: `Für die Testkonfiguration sind Felder für Benutzername und Kennwort erforderlich.`,
  [`Username context variable`]: `Kontextvariable für Benutzernamen`,
  [`Username of the new user`]: `Benutzername des neuen Benutzers`,
  [`Username should not contain special characters!`]: `Der Benutzername darf keine Sonderzeichen enthalten!`,
  [`Username should not include spaces!`]: `Der Beutzername darf keine Leerzeichen enthalten!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Benutzer, die einer Konsumentenorganisation angehören, haben Zugriff auf das Developer Portal und die zugehörigen veröffentlichten API-Produkte, wie von ihrer Sichtbarkeit und Abonnierbarkeit bestimmt.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Berechtigen Sie mithilfe von [IBM Cloud IAM]({link}) den Service "API Connect Reserved Instance" für den Zugriff auf den Zertifikatmanagerservice.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `HTTP-POST-Methode beim Kontaktieren des Userinfo-Endpunkts verwenden`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Verfallseinstellung für IBM APIC-Token aus der Cloud verwenden`,
  [`Update API`]: `API aktualisieren`,
  [`VALUE`]: `WERT`,
  [`Version`]: `Version`,
  [`Visible to`]: `Sichtbar für`,
  [`Validate`]: `Validieren`,
  [`Validate message body`]: `Nachrichtentext validieren`,
  [`Validate message headers`]: `Nachrichtenheader validieren`,
  [`Validate message fault details`]: `Fehlerdetails für Nachrichten validieren`,
  [`Validate SOAP 1.1 encoding rule`]: `SOAP 1.1-Codierungsregel validieren`,
  [`Validate JSON Web Token (JWT)`]: `JSON-Web-Token (JWT) validieren`,
  [`Validate JWT`]: `JWT validieren`,
  [`Validate Username Token`]: `Benutzernamenstoken validieren`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Validieren Sie die Nutzdaten eines Web Services Security-UsernameToken mithilfe einer Authentifizierungs-URL oder einer LDAP-Benutzerregistry.`,
  [`Validate against`]: `Validieren mit`,
  [`Validate request`]: `Anforderung validieren`,
  [`Validate the GraphQL query and analyze the expected cost`]: `GraphQL-Abfrage validieren und erwartete Kosten analysieren`,
  [`Validate the returned JSON and analyze the real cost`]: `Zurückgegebenes JSON validieren und tatsächliche Kosten analysieren`,
  [`Validating API ...`]: `API wird validiert...`,
  [`Validating Draft API`]: `Entwurfs-API wird validiert`,
  [`Validity Period`]: `Gültigkeitszeitraum`,
  [`Value`]: `Wert`,
  [`Value for located in must be selected.`]: `Wert für 'Befindet sich in' muss ausgewählt sein.`,
  [`Value for the variable to be added.`]: `Der Wert für die hinzuzufügende Variable.`,
  [`Value for type must be selected.`]: `Wert für 'Typ' muss ausgewählt sein.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `Der Wert des Arguments "Gewichtung" in der Anweisung "@cost" ist < 0.`,
  [`Values`]: `Werte`,
  [`Vanity API endpoint`]: `Vanity-API-Endpunkt`,
  [`Variable name`]: `Variablenname`,
  [`Variable value`]: `Variablenwert`,
  [`Vendor Extensions`]: `Anbietererweiterungen`,
  [`Verb`]: `Verb`,
  [`Verify`]: `Prüfen`,
  [`Verify Access Token`]: `Zugriffstoken überprüfen`,
  [`Verify Crypto JWK variable name`]: `Crypto-JWK-Variablenname überprüfen`,
  [`Verify Crypto Object`]: `Crypto-Objekt überprüfen`,
  [`Verify Token`]: `Token überprüfen`,
  [`Verify authorization code`]: `Autorisierungscode prüfen`,
  [`Verify refresh token`]: `Aktualisierungstoken prüfen`,
  [`Version of the LTPA token.`]: `Version des LTPA-Token.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Überprüfen Sie die Eigenschaft 'apis' unter 'plans' in der importierten Produktdatei. Jeder Plan sollte über die Eigenschaft 'apis' verfügen, die auf mindestens eine API im Abschnitt 'apis' verweist.`,
  [`View`]: `Anzeigen`,
  [`View extensions`]: `Erweiterungen anzeigen`,
  [`View Forum`]: `Forum anzeigen`,
  [`View owners`]: `Eigner anzeigen`,
  [`View policies`]: `Richtlinien anzeigen`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Zeigen Sie die für die ausgewählte Protokollversion verfügbaren Verschlüsselungen an und verwalten Sie sie.`,
  [`View analytics`]: `Analyse anzeigen`,
  [`View and edit permissions for the roles in this space`]: `Zeigen Sie Berechtigungen für die Rollen in diesem Bereich an und bearbeiten Sie sie.`,
  [`View and edit the permissions for the roles in the current organization`]: `Zeigen Sie Berechtigungen für die Rollen in der aktuellen Organisation an und bearbeiten Sie sie.`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Sie können die Rollen anzeigen und bearbeiten, die in den Rollenstandardwerten für Konsumentenorganisationen enthalten sind.`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Sie können die Rollen anzeigen und bearbeiten, die in den Rollenstandardwerten für Provider-Organisationen enthalten sind.`,
  [`View approval history`]: `Genehmigungsverlauf anzeigen`,
  [`View Approval History`]: `Genehmigungsverlauf anzeigen`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Zeigen Sie Dokumentation und Tutorials mit schrittweisen Anleitungen an.`,
  [`View error ID`]: `Fehler-ID anzeigen`,
  [`View status`]: `Status anzeigen`,
  [`View more`]: `Mehr anzeigen`,
  [`View source`]: `Quelle anzeigen`,
  [`View subscription`]: `Abonnement anzeigen`,
  [`View template in`]: `Vorlage anzeigen in`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Zeigen Sie die Endpunkte für zugreifende API Connect-Benutzerschnittstellen an.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Zeigen Sie die Endpunkte für die Administrator- und Provider-REST-APIs an.`,
  [`View the endpoints for the consumer REST APIs.`]: `Zeigen Sie die Endpunkte für die Konsumenten-REST-APIs an.`,
  [`Viewer`]: `Leser`,
  [`Views the API consumer organization`]: `Zeigt die API-Konsumentenorganisation an`,
  [`Views the API provider organization`]: `Zeigt die API-Provider-Organisation an`,
  [`Views the admin organization`]: `Zeigt die Verwaltungsorganisation an`,
  [`Views the app developer organization`]: `Zeigt die App-Entwicklerorganisation an`,
  [`Views the catalog`]: `Zeigt den Katalog an`,
  [`Views the space`]: `Zeigt den Bereich an`,
  [`View|permission`]: `Anzeigen`,
  [`Visibility`]: `Sichtbarkeit`,
  [`Visibility options`]: `Sichtbarkeitsoptionen`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware-Docs`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL-URL`,
  [`WSDL has been successfully validated`]: `WSDL wurde erfolgreich geprüft`,
  [`WSDL to REST`]: `WSDL zu REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `WS-I Basic Profile-Validierung`,
  [`Warn`]: `Warnung`,
  [`Warning`]: `Warnung`,
  [`Warning:`]: `Warnung:`,
  [`Warnings`]: `Warnungen`,
  [`We just emailed recipients a test message:`]: `Wir haben soeben den Empfängern eine Testnachricht per E-Mail gesendet:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `Wir verschieben die API auf die Registerkarte 'Testen', wo Sie Ihre API testen und debuggen können.`,
  [`Weather Product`]: `Wetterprodukt`,
  [`Web Endpoint`]: `Webendpunkt`,
  [`Web service operations`]: `Web-Service-Operationen`,
  [`weight`]: `Gewichtung`,
  [`Weight`]: `Gewichtung`,
  [`Welcome to API Connect Administration`]: `Willkommen bei API Connect Administration`,
  [`Welcome to API Connect Reserved`]: `Willkommen bei API Connect Reserved`,
  [`Welcome to API Designer`]: `Willkommen bei API Designer`,
  [`Welcome to API Manager`]: `Willkommen bei API Manager`,
  [`Welcome to Cloud Manager`]: `Willkommen bei Cloud Manager`,
  [`Welcome to the API Designer`]: `Willkommen bei API Designer`,
  [`Welcome to the API Manager`]: `Willkommen bei API Manager`,
  [`Welcome to the Cloud Manager`]: `Willkommen bei Cloud Manager`,
  [`What are you looking for today?`]: `Wonach suchen Sie heute?`,
  [`What's new`]: `Neuerungen`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `Weitere Schritte Aktivieren Sie die Abrechnungsintegration in einem Katalog, damit die Benutzer Pläne zu ihren Produkten hinzufügen können.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `Wenn der Wert auf "true" gesetzt ist, wird die invoke-Richtlinie die Header "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host" und "X-Forwarded-Proto" in die Anforderung, die an die Ziel-URL gesendet wird, einfügen.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Wenn ein API-Produkt veröffentlicht wird, wird es in dem Developer Portal angezeigt, das dem Katalog zugeordnet ist. Endpunkte für APIs in dem Produkt werden auf Gateways zur Verfügung gestellt, die dem Zielkatalog oder dem Zielbereich zugeordnet sind. Bereitgestellte Produkte sind im Katalog vorhanden, sind aber nicht live oder für die Konsumenten sichtbar.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Wenn ein API-Produkt veröffentlicht wird, wird es in dem Developer Portal angezeigt, das dem Katalog zugeordnet ist. Endpunkte für APIs in dem Produkt werden auf Gateways zur Verfügung gestellt, die dem Zielkatalog oder dem Zielbereich zugeordnet sind. Bereitgestellte Produkte sind im Katalog vorhanden, sind aber nicht live oder für die Konsumenten sichtbar. Beachten Sie, dass beim erneuten Bereitstellen oder Veröffentlichen eines Produkts in einem Nicht-Produktionskatalog vorhandene Abonnements entfernt werden.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Wenn Bereiche für einen Katalog aktiviert sind, muss der konfigurierte OAuth-Provider von einem Bereich aus angezeigt werden.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Wenn Bereiche aktiviert sind, werden vorhandene Produkte im Katalog in einen Standardbereich verschoben.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Wenn der Benutzer auf den Aktivierungslink klickt, wird er aufgefordert, das Registrierungsformular auszufüllen. Nach der Registrierung kann sich der Benutzer anmelden.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Wenn Sie eine Rolle für Provider- oder Konsumentenorganisationen hinzufügen, wird sie in die Rollenstandardwerte eingeschlossen. Rollenstandardwerte sind vorkonfigurierte Rollenvorlagen, die zum Bestimmen der Standardrollen verwendet werden, die beim Erstellen einer neuen Organisation verfügbar sind.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Wenn ein Produkt nicht mehr unterstützt werden soll, können Anwendungsentwickler, die das Produkt bereits abonniert haben, es weiterhin verwenden, es kann jedoch nicht von neuen Entwicklern abonniert werden.\n\n [Weitere Informationen]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Wenn Sie eine Rolle für Provider- oder Konsumentenorganisationen bearbeiten, wird sie in die Rollenstandardwerte eingeschlossen. Rollenstandardwerte sind vorkonfigurierte Rollenvorlagen, die zum Bestimmen der Standardrollen verwendet werden, die beim Erstellen einer neuen Organisation verfügbar sind.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Wenn Sie OpenID Connect aktivieren, wird eine Vorlage zum Generieren von ID-Tokens gemeinsam mit Zugriffstokens bereitgestellt. Die erforderlichen Assembly-Richtlinien werden automatisch erstellt. Sie können die Richtlinien an Ihre Bedürfnisse anpassen. Der Musterschlüssel dient nur zu Testzwecken und wird zum Signieren des JWT-Tokens verwendet.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Wenn Sie die Tokenintrospektion aktivieren, kann das Zugriffstoken über den Introspektionspfad überprüft werden.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Wenn Sie die Tokenintrospektion aktivieren, können die Inhaber von Zugriffstokens den Inhalt von Tokens unter Verwendung eines Introspektionspfads untersuchen. Das für die Introspektion vorgesehen Zugriffstoken muss über den nativen OAuth-Provider abgerufen werden.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Wenn Sie einen Benutzer als Mitglied einer Provider-Organisation einladen, weisen Sie ihm eine oder mehrere Rollen zu, um die Ebene für den Zugriff auf Informationen und die Tasks, die der Benutzer ausführen kann, zu steuern.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Wenn Sie Ihre Produktversionen verwalten, bewegen Sie sie durch eine Reihe von Lebenszyklusstatus, von der anfänglichen Bereitstellung einer Produktversion in einem Katalog über die Veröffentlichung, um die Produktversion Ihren Anwendungsentwicklern zur Verfügung zu stellen, bis hin zum Zurückziehen und Archivieren.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Wenn Sie ein Produkt durch ein anderes Produkt ersetzen, werden die folgenden Aktionen durchgeführt: 1) Das Ersatzprodukt wird veröffentlicht, 2) im Ersatzprodukt werden dieselben Einstellungen für Sichtbarkeit, Abonnenten und Gateway-Erzwingung wie im ursprünglichen Produkt verwendet, 3) die Abonnenten des ursprünglichen Produkts werden in das Ersatzprodukt migriert.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Wenn Sie ein Produkt durch ein anderes Produkt ersetzen, werden die folgenden Aktionen ausgeführt: 1) Das Ersatzprodukt nimmt den Status des Originalprodukts an. 2) Wenn die Einstellungen für Sichtbarkeit und Abonnierbarkeit im Ersatzprodukt so festgelegt sind, dass der Zugriff dem auf das Originalprodukt entspricht oder weniger restriktiv ist, werden die Einstellungen im Ersatzprodukt verwendet. Wenn die Einstellungen im Ersatzprodukt restriktiver sind und einige Konsumentenorganisationen mit Zugriff auf das Originalprodukt am Zugriff auf das Ersatzprodukt hindern würden, kann die Ersatzoperation nicht ausgeführt werden. 3) Die Abonnenten des Originalprodukts werden auf das Ersatzprodukt migriert und (4) das Originalprodukt wird zurückgezogen.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Wenn Sie ein Produkt zurückziehen, werden alle zugeordneten APIs in den Offlinemodus versetzt und alle Abonnements werden inaktiv.\n\n [Weitere Informationen]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Wenn Sie ein Produkt durch ein anderes Produkt außer Kraft setzen, werden die folgenden Aktionen ausgeführt: 1) Das außer Kraft setzende Produkt wird veröffentlicht, 2) für das außer Kraft setzende Produkt werden dieselben Einstellungen für Sichtbarkeit, Abonnenten und Gateway-Erzwingung verwendet, 3) das ursprüngliche Produkt wird in den nicht mehr unterstützten Status versetzt. Wenn ein Produkt nicht mehr unterstützt werden soll, können Anwendungsentwickler, die das Produkt bereits abonniert haben, es weiterhin verwenden, es kann jedoch nicht von neuen Entwicklern abonniert werden. Ein nicht mehr unterstütztes Produkt kann bei Bedarf erneut veröffentlicht werden.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Wenn Sie ein Produkt durch ein anderes Produkt außer Kraft setzen, werden die folgenden Aktionen durchgeführt: 1) Das außer Kraft setzende Produkt wird veröffentlicht, 2) die Einstellungen für Sichtbarkeit und Abonnierbarkeit werden vom ursprünglichen Produkt für das außer Kraft setzende Produkt übernommen und 3) das ursprüngliche Produkt wird in den nicht weiter unterstützten Status versetzt.  Wenn ein Produkt nicht mehr unterstützt werden soll, können Anwendungsentwickler, die das Produkt bereits abonniert haben, es weiterhin verwenden, es kann jedoch nicht von neuen Entwicklern abonniert werden.  Ein nicht mehr unterstütztes Produkt kann bei Bedarf erneut veröffentlicht werden.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Wenn Sie eine Anwendung aussetzen, werden die zugehörigen Client-IDs für API-Aufrufe blockiert. Sie können die Anwendung jederzeit wiederaufnehmen.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Wenn diese Option aktiviert ist, wird das Einladungszeitlimit für alle Verbraucherorganisationen auf das Katalogzeitlimit gesetzt und bei jeder Aktualisierung des Katalogzeitlimits wird das Einladungszeitlimit für Verbraucherorganisationen aktualisiert.`,
  [`Where did the roles go?`]: `Wo sind die Rollen?`,
  [`Where to find the named rate limit.`]: `Angabe, wo die genannte Ratenbegrenzung zu finden ist.`,
  [`Where to put the decrypted result`]: `Ziel für das entschlüsselte Ergebnis`,
  [`Where to put the encrypted result`]: `Ziel für das verschlüsselte Ergebnis`,
  [`Whether to reuse the connection to the target server.`]: `Gibt an, ob die Verbindung zum Zielserver wiederverwendet werden soll.`,
  [`Windows live`]: `Windows live`,
  [`With`]: `Mit`,
  [`Wildcards ignore xsi:type rule`]: `Platzhalterzeichen ignorieren die Regel xsi:type`,
  [`Work offline`]: `Offline arbeiten`,
  [`Work with existing definitions, models and/or data sources`]: `Arbeiten Sie mit vorhandenen Definitionen, Modellen und/oder Datenquellen.`,
  [`Working...`]: `Vorgang wird ausgeführt...`,
  [`Wrapper Policies`]: `Oberflächenrichtlinien`,
  [`Wrong slicing arguments`]: `Falsche Slicing-Argumente`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL und XSDs sollten sich in einer eigenen ZIP-Datei in der ZIP-Datei befinden, die Sie importieren möchten.`,
  [`XML Schema`]: `XML Schema`,
  [`XML Schema URL`]: `XML-Schema-URL`,
  [`XML Schema preview`]: `Vorschau für XML-Schema`,
  [`XML Validation Mode`]: `XML-Validierungsmodus`,
  [`XML schema is not valid. Please correct the error.`]: `XML-Schema ist ungültig. Beheben Sie den Fehler.`,
  [`XML to JSON`]: `XML in JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT-Version`,
  [`YAML has been successfully validated`]: `YAML wurde erfolgreich geprüft`,
  [`Yes`]: `Ja`,
  [`Yes, enable it`]: `Ja, aktivieren`,
  [`Yes, reset`]: `Ja, zurücksetzen`,
  [`Yes. Update API Assembly`]: `Ja. API-Assembly aktualisieren`,
  [`You are authenticated!`]: `Sie sind authentifiziert!`,
  [`You do not have permission to use the Test Application`]: `Sie haben keine Berechtigung zur Verwendung der Testanwendung`,
  [`You can also copy the link and send it directly to the user.`]: `Sie können den Link auch kopieren und direkt an den Benutzer senden.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `Sie können sich dafür entscheiden, die API zu diesem Zeitpunkt nicht zu veröffentlichen, oder Sie können sie sofort auf Ihren Test- und/oder Produktionsendpunkten veröffentlichen. Optional können Sie, basierend auf einer Anzahl von Aufrufen in einem angegebenen Zeitintervall, eine maximal zulässige API-Aufrufrate angeben.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `Sie können sich dafür entscheiden, die API zu diesem Zeitpunkt nicht zu veröffentlichen, oder Sie können sie sofort auf Ihren Test- und/oder Produktionsendpunkten veröffentlichen. Optional können Sie, basierend auf einer Anzahl von Aufrufen pro angegebener Zeit, eine maximal zulässige API-Aufrufrate angeben.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `Sie haben die Möglichkeit, die API zu diesem Zeitpunkt ungesichert zu lassen, oder Sie können anfordern, dass eine aufrufende Anwendung eine Client-ID angeben muss. Sie können weitere Sicherheitsoptionen konfigurieren, nachdem Sie die API-Definition erstellt haben.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `Sie können einen Katalognamen oder einen Titel eingeben. Der Katalogname hat Vorrang, wenn beide verfügbar sind.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `Sie können eine E-Mail an den Eigner einer Konsumentenorganisation senden. Die Nachricht wird über den E-Mail-Server gesendet, der in den Benachrichtigungen konfiguriert ist.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `Sie können eine E-Mail an den Eigner einer Konsumentenorganisation senden. Die Nachricht wird über den E-Mail-Server gesendet, der im Abschnitt mit den Benachrichtigungen konfiguriert ist.`,
  [`You can send an email to a provider organization owner.`]: `Sie können eine E-Mail an den Eigner einer Provider-Organisation senden.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Sie können das Eigentumsrecht an einen neuen Benutzer übertragen, indem Sie dessen E-Mail-Adresse eingeben. Er erhält eine E-Mail mit einem Aktivierungslink zum Registrieren. Sobald der Benutzer registriert ist, wird er der neue Eigner. Weisen Sie dem aktuellen Eigner neue Rollen zu, damit er weiterhin in Cloud Manager arbeiten kann.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Sie können das Eigentumsrecht an einen registrierten Benutzer übertragen, indem Sie dessen E-Mail-Adresse eingeben. Weisen Sie dem aktuellen Eigner neue Rollen zu, damit er weiterhin in Cloud Manager arbeiten kann.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `Sie können eine OpenAPI-Definitionsdatei verwenden, um eine REST-API zu IBM API Connect hinzuzufügen. Das Format der Datei kann JSON oder YAML sein.  Wenn die API-Definition importiert wurde, wird sie in der Liste der API-Definitionen angezeigt.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `Sie können diesen Typ nicht löschen, weil dadurch der Stammtyp ('Query') entfernt wird.`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `Sie können keine API auswählen, deren Gateway-Typ nicht mit dem Gateway-Typ dieses Produkts übereinstimmt.`,
  [`You currently don’t have any gateway extensions`]: `Sie haben derzeit keine Gateway-Erweiterungen`,
  [`You currently don’t have any plans to migrate`]: `Sie haben derzeit keine Pläne zur Migration`,
  [`You currently don't have any policy.`]: `Sie haben derzeit keine Richtlinie.`,
  [`You do not have permission to any spaces.`]: `Sie sind für keine Bereiche berechtigt.`,
  [`You do not have permission to publish products to any catalog or space.`]: `Sie sind nicht berechtigt, Produkte in Katalogen oder Bereichen zu veröffentlichen.`,
  [`You do not have permission to stage products to any catalog or space.`]: `Sie sind nicht berechtigt, Produkte in einem beliebigen Katalog oder Bereich bereitzustellen.`,
  [`You don’t currently have any billing integrations.`]: `Sie verfügen derzeit nicht über Abrechnungsintegrationen.`,
  [`You don't currently have any response.`]: `Sie haben derzeit keine Antwort.`,
  [`You don't currently have any user-defined policies.`]: `Sie haben derzeit keine benutzerdefinierten Richtlinien.`,
  [`You don't have permission to access this page.`]: `Sie sind nicht berechtigt, auf diese Seite zuzugreifen.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Sie haben dieses Zertifikat geändert. Durch das Speichern dieser Anwendung wird ihre Konfiguration aktualisiert.`,
  [`You haven't added any APIs or Products`]: `Sie haben keine APIs oder Produkte hinzugefügt`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Sie können die Katalogsmitgliedschaft verwalten, indem Sie dem Katalog neue Benutzer hinzufügen und den Benutzern Rollen zuweisen.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `Sie können die Bereichsmitgliedschaft verwalten, indem Sie dem Bereich neue Benutzer hinzufügen und den Benutzern Rollen zuweisen.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Sie können die Bereichsmitgliedschaft verwalten, indem Sie dem Bereich neue Benutzer hinzufügen und den Benutzern Rollen zuweisen.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `Sie müssen Ihre API zu einem Produkt hinzufügen, das bereit für die Veröffentlichung ist. Ein Produkt sammelt eine Gruppe von APIs und Plänen in einem Angebot, das Sie Ihren Entwicklern zur Verfügung stellen. Ein Plan umfasst Ratenbegrenzungseinstellungen, die auf den Plan als Ganzes angewendet oder für jede einzelne Operation in einer API festgelegt werden können.`,
  [`You must choose a definition`]: `Sie müssen eine Definition auswählen`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `Sie müssen mindestens einen Gateway-Service aktivieren, damit APIs, die in diesem Katalog veröffentlicht werden, an einem Gateway-Serviceendpunkt aufgerufen werden können. Sie können mehrere Gateway-Services aktivieren.`,
  [`You must enable spaces`]: `Sie müssen Bereiche aktivieren.`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Sie müssen die Client-ID und den geheimen Clientschlüssel unten notieren, wenn Sie Ihre Anwendung entwickeln. Der geheime Clientschlüssel kann nicht erneut angezeigt werden.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Ihre Lizenz für API Connect Reserved Instance berechtigt Sie zum Herunterladen und Einrichten von einem oder mehreren fernen DataPower-API-Gateways. Die Gateways können sich vor Ort oder in einer Cloud befinden.`,
  [`Your API has been created`]: `Ihre API wurde erstellt.`,
  [`Your API is live!`]: `Ihre API ist aktiv!`,
  [`Your API is online!`]: `Ihre API ist online!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Ihr Katalog wird gelöscht. Dies kann einige Zeit in Anspruch nehmen ...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Ihre Konsumentenorganisation wird erstellt. Dies kann einige Zeit in Anspruch nehmen ...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Ihre Einladung ist nicht mehr gültig. Überprüfen Sie Ihre Einstellung für das Einladungszeitlimit und senden Sie die Einladung erneut.`,
  [`Your Provider organization is being created. This may take a while...`]: `Ihre Provider-Organisation wird erstellt. Dies kann einige Zeit in Anspruch nehmen ...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Ihre Provider-Organisation wird gelöscht. Dies kann einige Zeit in Anspruch nehmen ...`,
  [`Your member is being deleted. This may take a while...`]: `Ihre Teildatei wird gelöscht. Dies kann einige Zeit in Anspruch nehmen ...`,
  [`Your Space is being deleted. This may take a while...`]: `Ihr Bereich wird gelöscht. Dies kann einige Zeit in Anspruch nehmen ...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `Ihr OAuth-Provider verfügt über nicht gespeicherte Änderungen. Klicken Sie auf OK, um ohne Speichern fortzufahren.`,
  [`Your credentials have expired.`]: `Ihre Berechtigungsnachweise sind abgelaufen.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Ihre Einladung wurde erfolgreich mit dem folgenden Einladungslink gesendet:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `Ihre Anforderung wird noch bearbeitet. Sie benötigt mehr Zeit als erwartet. Aktualisieren Sie die Anzeige in einigen Minuten, bevor Sie die Anforderung erneut starten.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Ihr Produkt wird gespeichert, bevor Sie fortfahren. Sind Sie sicher?`,
  [`Your trial ends on {date}`]: `Ihre Testversion endet am {date}`,
  [`Your trial expired on {date}.`]: `Ihre Testversion ist am {date}abgelaufen.`,
  [`Zoom in`]: `Vergrößern`,
  [`Zoom in and out`]: `Vergrößern und verkleinern`,
  [`Zoom out`]: `Verkleinern`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `mangelnde CORS-Unterstützung auf dem Zielserver (siehe Vorschlag unten)`,
  [`a LDAP provider`]: `einem LDAP-Provider`,
  [`activity`]: `Aktivität`,
  [`add item...`]: `Element hinzufügen...`,
  [`add property...`]: `Eigenschaft hinzufügen...`,
  [`added`]: `hinzugefügt`,
  [`admin`]: `Admin`,
  [`administrator`]: `Administrator`,
  [`all`]: `alle`,
  [`an API Connect Local User Registry`]: `einer lokalen API Connect-Benutzerregistry`,
  [`an authentication URL`]: `einer Authentifizierungs-URL`,
  [`an untrusted certificate has been encountered`]: `ein nicht vertrauenswürdiges Zertifikat wurde festgestellt`,
  [`and`]: `und`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Anwendung:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `Genehmigung`,
  [`approval task for product`]: `Genehmigungstask für das Produkt`,
  [`archived`]: `archiviert`,
  [`authenticated`]: `authentifiziert`,
  [`authorization code time to live (seconds)`]: `Lebensdauer für Berechtigungscode (Sekunden)`,
  [`Authorization header pass through`]: `Berechtigungsheader durchlaufen`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `blockierend`,
  [`body`]: `Hauptteil`,
  [`body-param`]: `body-param`,
  [`cancel`]: `abbrechen`,
  [`case`]: `Fall`,
  [`catalog`]: `Katalog`,
  [`catalog:`]: `Katalog:`,
  [`catch`]: `Catch`,
  [`changed`]: `geändert`,
  [`cipher`]: `Cipher`,
  [`code id_token`]: `Code-ID-Token`,
  [`code id_token token`]: `Code-ID-Token - Token`,
  [`code token`]: `Code-Token`,
  [`Consume`]: `Verbrauchen`,
  [`consumer`]: `Konsument`,
  [`consumes`]: `verbraucht`,
  [`composite`]: `Verbund`,
  [`created`]: `erstellt`,
  [`Created API`]: `Erstellte API`,
  [`custom string`]: `angepasste Zeichenfolge`,
  [`day`]: `Tag`,
  [`day(s)`]: `Tag(e)`,
  [`default`]: `Standard`,
  [`default value`]: `Standardwert`,
  [`definition`]: `Definition`,
  [`deprecated`]: `nicht weiter unterstützt`,
  [`description`]: `Beschreibung`,
  [`development`]: `Entwicklung`,
  [`edit condition`]: `Bedingung bearbeiten`,
  [`edited`]: `bearbeitet`,
  [`environment`]: `Katalog`,
  [`error`]: `Fehler`,
  [`false`]: `falsch`,
  [`file`]: `Datei`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 - Symbole`,
  [`for`]: `für`,
  [`header`]: `Header`,
  [`hear`]: `hören`,
  [`hello`]: `Hallo`,
  [`helloName`]: `Hallo {name}!`,
  [`here`]: `hier`,
  [`hit`]: `Treffer`,
  [`hits`]: `Treffer`,
  [`hour`]: `Stunde`,
  [`hour(s)`]: `Stunde(n)`,
  [`hours`]: `Stunden`,
  [`identified`]: `identifiziert`,
  [`Increased`]: `Erhöht`,
  [`Increment`]: `Erhöhen`,
  [`info`]: `Information`,
  [`information about external documentation`]: `Informationen zur externen Dokumentation`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `Anstatt den Exp-Anspruch des OIDC-Providers anzuerkennen, berücksichtigt das von APIC generierte access_token/refresh_token die APIC-Einstellung.`,
  [`introspect URL`]: `Introspektions-URL`,
  [`Issue`]: `Problem`,
  [`Issues`]: `Probleme`,
  [`item`]: `Element`,
  [`iterate over`]: `iterieren über`,
  [`last modified`]: `letzte Änderung`,
  [`Log Level`]: `Protokollebene`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `Protokolle`,
  [`Logout`]: `Abmelden`,
  [`Logout redirect`]: `Abmeldeumleitung`,
  [`Logout redirect URL`]: `URL für Abmeldeumleitung`,
  [`location`]: `Position`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `Minute`,
  [`minute(s)`]: `Minute(n)`,
  [`minutes`]: `Minuten`,
  [`mutation type`]: `Mutationstyp`,
  [`mutual SSL authentication is required`]: `gegenseitige SSL-Authentifizierung ist erforderlich`,
  [`name`]: `Name`,
  [`Name|extension`]: `Name`,
  [`Name|path parameters`]: `Name`,
  [`Name|security definitions`]: `Name`,
  [`non-blocking`]: `nicht blockierend`,
  [`none`]: `Keine`,
  [`none selected`]: `keine ausgewählt`,
  [`Object`]: `Objekt`,
  [`off`]: `aus`,
  [`of {total} pages`]: `von {total} Seiten`,
  [`of {total} page`]: `von {total} Seite`,
  [`offline`]: `offline`,
  [`on`]: `ein`,
  [`online`]: `Online`,
  [`or`]: `oder`,
  [`or redact from all`]: `oder neu bearbeiten von allen`,
  [`open and close list of options`]: `Optionsliste öffnen und schließen`,
  [`organization saved`]: `Organisation gespeichert`,
  [`organization-manager`]: `Organisationsmanager`,
  [`otherwise`]: `andernfalls`,
  [`payload`]: `Nutzdaten`,
  [`Payload`]: `Nutzdaten`,
  [`pending`]: `anstehend`,
  [`produces`]: `produziert`,
  [`production`]: `Produktion`,
  [`production and development`]: `Produktion und Entwicklung`,
  [`property`]: `Eigenschaft`,
  [`provider`]: `Provider`,
  [`publish product`]: `Produkt veröffentlichen`,
  [`published`]: `veröffentlicht`,
  [`query type`]: `Abfragetyp`,
  [`query-parameters`]: `Abfrageparameter`,
  [`realized`]: `realisiert`,
  [`recipients`]: `Empfänger`,
  [`removed`]: `entfernt`,
  [`replaced`]: `ersetzt`,
  [`repeatable`]: `wiederholbar`,
  [`Replenish`]: `Nachfüllen`,
  [`request`]: `Anforderung`,
  [`requested by`]: `angefordert von`,
  [`Resolver weight`]: `Resolver-Gewichtung`,
  [`response`]: `Antwort`,
  [`response-param`]: `response-param`,
  [`retired`]: `zurückgezogen`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `gespeichert`,
  [`scalar`]: `Skalar`,
  [`second`]: `Sekunde`,
  [`second(s)`]: `Sekunde(n)`,
  [`seconds`]: `Sekunden`,
  [`select owner user`]: `Eignerbenutzer auswählen`,
  [`select user`]: `Benutzer auswählen`,
  [`soap-body`]: `soap-body`,
  [`source`]: `Quelle`,
  [`space`]: `Bereich`,
  [`Space settings`]: `Bereichseinstellungen`,
  [`Skip`]: `Überspringen`,
  [`specified`]: `angegeben`,
  [`staged`]: `bereitgestellt`,
  [`start generating tests automatically.`]: `automatisch mit dem Generieren von Tests beginnen.`,
  [`subject`]: `Betreff`,
  [`subscription type`]: `Abonnementtyp`,
  [`supersede`]: `außer Kraft setzen`,
  [`superseded`]: `außer Kraft gesetzt`,
  [`target`]: `Ziel`,
  [`the server is unavailable`]: `der Server ist nicht verfügbar`,
  [`this user registry type`]: `diesem Benutzerregistrytyp`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Dies ist eine temporäre Veröffentlichungsseite, auf der bei einer erneuten Veröffentlichung Abonnements beibehalten werden.`,
  [`to production`]: `für Produktion`,
  [`topology-administrator`]: `Topologieadministrator`,
  [`Trial days left`]: `Verbliebene Testtage`,
  [`true`]: `wahr`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `Titel wurde geändert`,
  [`type`]: `Typ`,
  [`unarchived`]: `nicht archiviert`,
  [`union type`]: `Union-Verknüpfungstyp`,
  [`unlimited`]: `unbegrenzt`,
  [`updated`]: `aktualisiert`,
  [`Updated from new schema`]: `Aus neuem Schema aktualisiert`,
  [`Upgrade`]: `Upgrade durchführen`,
  [`v5 legacy behavior`]: `Traditionelles v5-Verhalten`,
  [`version 2`]: `Version 2`,
  [`version 3`]: `Version 3`,
  [`viewer`]: `Leser`,
  [`value`]: `Wert`,
  [`warn`]: `Warnung`,
  [`warning`]: `Warnung`,
  [`warnings`]: `Warnungen`,
  [`was not found. Please select a configured OAuth provider.`]: `wurde nicht gefunden. Wählen Sie einen konfigurierten OAuth-Provider aus.`,
  [`week`]: `Woche`,
  [`with`]: `mit`,
  [`with|supersede with`]: `mit`,
  [`wsdl`]: `WSDL`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `Status in Sandbox`,
  [`your file here, or`]: `Sie Ihre Datei hier oder`,
  [`{count} found!`]: `{count} gefunden!`,
  [`{current} of {total} page`]: `{aktuell} von {gesamt} Seite`,
  [`{current} of {total} pages`]: `{current} von {total} Seiten`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} Tag(e), {hours} Stunde(n) und {minutes} Minute(n)`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} Tag(e) und {hours} Stunde(n)`,
  [`{docType} is required`]: `{docType} ist erforderlich`,
  [`{docType} title`]: `{docType} Titel`,
  [`Product title`]: `Projekttitel`,
  [`Application title`]: `Anwendungstitel`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} Stunde(n) und {minutes} Minute(n)`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} Gateway(s) ausgeblendet aufgrund von Inkompatibilität mit dem in der API oder im Produkt angegebenen Gatewaytyp`,
  [`{min}-{max} of {total} items`]: `{min}-{max} von {total} Elementen`,
  [`{min}-{max} of {total} item`]: `{min.}-{max.} von {gesamtl} Item`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} von {total} Richtlinien`,
  [`{min}-{max} of {total} Policy`]: `{min.}-{max.} von {gesamt} Richtlinie`,
  [`{min}-{max} of {total} types`]: `{min}-{max} von {total} Typen`,
  [`{min}-{max} of {total} type`]: `{min.}-{max.} von {gesamtl} Typ`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} von {total} Suchergebnissen`,
  [`{min}-{max} of {total} search result`]: `{min.}-{max.} von {gesamt} Suchergebnis`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} von {total} Katalogen`,
  [`{min}-{max} of {total} Catalog`]: `{min.}-{max.} von {gesamt} Katalog`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} von {total} Bereichen`,
  [`{min}-{max} of {total} Space`]: `{min.}-{max.} von {gesamt} Speicherplatz`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} von {total} Erweiterungen`,
  [`{min}-{max} of {total} Extension`]: `{min.}-{max.} von {gesamtl} Erweiterung`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} von {total} Produktplänen`,
  [`{min}-{max} of {total} Product Plan`]: `{min.}-{max.} von {gesamt} Produktplan`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} von {total} Gateways`,
  [`{min}-{max} of {total} Gateway`]: `{min.}-{max.} von {gesamt} Gateway`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `Für {name} (API) fehlt 'x-ibm-configuration'`,
  [`{name} (Product) has been published!`]: `{name} (Produkt) wurde veröffentlicht!`,
  [`{name} (Product) has been staged!`]: `{name} (Produkt) wurde bereitgestellt!`,
  [`{name} (Product) has not been published!`]: `{name} (Produkt) wurde nicht veröffentlicht!`,
  [`{name} (Role) has been created`]: `{name} (Rolle) wurde erstellt.`,
  [`{name} successfully deleted`]: `{name} erfolgreich gelöscht.`,
  [`{name} (Role) has been updated`]: `{name} (Rolle) wurde aktualisiert.`,
  [`{name} has been assigned the owner`]: `{name} wurde dem Eigner zugeordnet`,
  [`{number} Error`]: `{number} Fehler`,
  [`{number} Errors`]: `{number} Fehler`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{operation}-Genehmigungstask für Produkt {prodname}:{version} angefordert von {username}`,
  [`{path} does not exist`]: `{path} ist nicht vorhanden`,
  [`{productName} has no migration target set.`]: `{productName} hat keine Migrationszielgruppe.`,
  [`{state} Pending`]: `{state} anstehend`,
  [`{status} API error`]: `{status} - API-Fehler`,
  [`{status} Bad Request`]: `{status} - fehlerhafte Anfrage`,
  [`{status} Forbidden`]: `{status} - nicht zulässig`,
  [`{status} Gateway Error`]: `{status} - Gateway-Fehler`,
  [`{status} Unauthorized`]: `{status} - nicht berechtigt`,
  [`({time} after being sent)`]: `({time} nach dem Senden)`,
  [`({time} after being queued)`]: `({time} nach dem Stellen in die Warteschlange)`,
  [`{title} (Product) has been {state}.`]: `{title} (Produkt) war {state}.`,
  [`{title} has been deleted`]: `{title} wurde gelöscht`,
  [`{title} has been {changedName}.`]: `{title} war {changedName}.`,
  [`{title} has not been deleted!`]: `{title} wurde nicht gelöscht!`,
  [`{title} has not been {changedName}!`]: `{title} war nicht {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type} war {changedName}.`,
  [`{type} has not been {changedName}!`]: `{type} war nicht {changedName}!`,
  [`{type} history log`]: `{type} Systemprotokoll`,
  [`{username} does not exist`]: `{username} ist nicht vorhanden`,
  [`{username} does not exist in the user registry`]: `{username} ist in der Benutzerregistry nicht vorhanden`,
  [`{username} is not associated with this consumer org`]: `{username} ist dieser Konsumentenorganisation nicht zugeordnet`,
  [`{username} has been created as a {type}.`]: `{username} wurde als {type} erstellt.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `App {appName} wieder eingesetzt im {consumerOrg}-Developer Portal`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `App {appName} ausgesetzt im {consumerOrg}-Developer Portal`,
  [`{{catalogTitle}} developer portal account registration`]: `Accountregistrierung für {catalogTitle}-Developer Portal`,
  [`{{count}} errors need attention`]: `{count} Fehler müssen beachtet werden`,
  // lts only translations
  [`Configure JSON settings`]: `JSON-Einstellungen konfigurieren`,
  [`Configure XML settings`]: `XML-Einstellungen konfigurieren`,
  [`Configure options`]: `Optionen konfigurieren`,
  [`Detect`]: `Erkennen`,
  [`Document type`]: `Dokumenttyp`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Maximale Dokumentgröße`,
  [`Maximum name length`]: `Maximale Namenslänge`,
  [`Maximum nesting depth`]: `Maximale Verschachtelungstiefe`,
  [`Maximum number length`]: `Maximale Anzahl für Länge`,
  [`Maximum number of unique names`]: `Maximale Anzahl eindeutiger Namen`,
  [`Maximum number of unique namespaces`]: `Maximale Anzahl eindeutiger Namensbereiche`,
  [`Maximum number of unique prefixes`]: `Maximale Anzahl eindeutiger Präfixe`,
  [`Maximum value length`]: `Maximale Wertlänge`,
  [`Maximum width`]: `Maximale Breite`,
  [`Maximum stack size`]: `Maximale Stackgröße`,
  [`Parse settings`]: `Analyseeinstellungen`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Gibt die maximale Dokumentgröße in Byte an, die die Analyseaktion akzeptiert.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Gibt die maximale Ebene verschachtelter Elementtiefe in einer XML-oder JSON-Nachricht an, die von der Analyseaktion akzeptiert wird.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Gibt die maximale Ebene verschachtelter Elementtiefe in einer XML-, JSON- oder GraphQL-Nachricht an, die von der Analyseaktion akzeptiert wird.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Gibt die maximale Namenslänge in Byte an, die die Analyseaktion akzeptiert.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Gibt die maximale Zahlenlänge an, die die Anzahl der Bytes im Wertebereich eines Bezeichnungs-Wert-Paares begrenzt, wenn der Wert eine Zahl ist.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Gibt die maximale Anzahl eindeutiger XML-Namensbereiche an, die die Analyseaktion akzeptiert. Dieser Grenzwert gilt für alle XML-Namensbereiche, unabhängig davon, wie viele Präfixe für die Deklaration verwendet werden.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Gibt die maximale Anzahl eindeutiger XML-Namensbereichspräfixe in einem Dokument an, das die Analyseaktion akzeptiert. Für diesen Grenzwert zählen mehrere Präfixe, die für denselben Namensbereich definiert sind, es zählen jedoch nicht mehrere Namensbereiche, die in verschiedenen Teilen des Eingabedokuments unter einem einzigen Präfix definiert sind.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Gibt die maximale Anzahl eindeutiger Namen an, die die Analyseaktion akzeptiert.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Geben Sie die maximale Wertlänge in Byte an, die die Analyseaktion akzeptiert.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Gibt die maximale Breite von Nutzdaten an, die die Analyseaktion akzeptiert.`,
  [`Specifies the type of document to parse.`]: `Gibt den Typ des zu analysierenden Dokuments an.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Gibt die Version des XSLT-Prozessors an. Der Standardwert ist XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Gibt an, ob eine strenge XSLT-Fehlerprüfung aktiviert werden soll. Bei nicht strengen Operationen wird versucht, nach bestimmten Fehlern, wie z. B. die Verwendung nicht deklarierter Variablen, das Aufrufen nicht deklarierter Voragen usw., eine normale Verarbeitung wiederherzustellen. Standardmäßig ist die strenge XSLT-Fehlerprüfung aktiviert.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Gibt an, ob die Erstellung von Style-Sheet-Profilen aktiviert werden soll. Diese Option sollte nicht in Produktionsumgebungen verwendet werden. Standardmäßig ist die Erstellung von Style-Sheet-Profilen inaktiviert.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Gibt an, ob das Style-Sheet, das XQuery-Script und das JSONiq-Script im Debugmodus ausgeführt werden sollen. Wenn ein Style-Sheet, ein XQuery-Script oder ein JSONiq-Script im Debugmodus ausgeführt wird, wird eine angepasste Webseite generiert, anstatt die normale Ausgabe anzuzeigen. Die Webseite gibt genau an, was während der Ausführung passiert ist, einschließlich der Werte von Variablen und der Herkunft bestimmter Bestandteile der Ausgabe. Diese Option sollte nicht in Produktionsumgebungen verwendet werden. Standardmäßig ist der Debugmodus inaktiviert.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Gibt an, ob das Style-Sheet im Streaming-Modus ausgeführt werden muss. Die Umsetzung des Dokuments beginnt, bevor die Eingabe vollständig syntaktisch analysiert wird. Nicht alle Style-Sheets können gestreamt werden. Wenn das Style-Sheet nicht gestreamt werden kann, wird ein Fehler generiert und die Eingabe wird nicht verarbeitet. Der Streaming-Modus ist standardmäßig inaktiviert.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Gibt an, ob versucht werden soll, das Style-Sheet im Streaming-Modus auszuführen. Die Umsetzung des Dokuments beginnt, bevor die Eingabe vollständig syntaktisch analysiert wird. Nicht alle Style-Sheets können gestreamt werden. Wenn das Style-Sheet nicht gestreamt werden kann, wird während der Kompilierung eine Warnung generiert und das Style-Sheet wird wie üblich in der gesamten Eingabe während der Ausführung gelesen. Standardmäßig ist der Versuch, das Style-Sheet im Streaming-Modus auszuführen, inaktiviert.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Gibt an, ob die Ausgabe, die während der Verarbeitung aus dem Style-Sheet erzeugt wird,  mit Escapezeichen versehen werden soll. Minimales Versehen mit Escapezeichen ist besonders nützlich, wenn Sie nicht englischsprachige Zeichensätze verarbeiten. Standardmäßig ist das minimale Versehen mit Escapezeichen inaktiviert.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Gibt die maximale Anzahl an Byte an, die der Stack während der Ausführung eines Style-Sheets oder eines anderen kompilierten Inhalts verwenden darf. Diese Einstellung wird verwendet, um die unendliche Rekursion zu blockieren. Der Mindestwert ist 10 Kilobyte bzw. 10.240 Byte. Der Maximalwert ist 100 Megabyte bzw. 104.857.600 Byte. Der Standardwert ist 1 Megabyte bzw. 1.048.576 Byte.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Gibt das Validierungsverhalten an, das auf WSDL-Dateien angewendet werden soll, die auf die Konformität mit Abschnitt 5 von WS-I Basic Profile (Version 1.0, April 2004) geprüft werden. Die Standardeinstellung ist 'Warnung'.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Gibt das Validierungsverhalten für soap:Body an. Die Standardeinstellung ist 'Streng'.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Gibt das Validierungsverhalten für soap:Header an. Die Standardeinstellung ist 'Nicht streng'.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Gibt das Validierungsverhalten für das Fehlerdetail an. Die Standardeinstellung ist 'Streng'.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Gibt an, ob die Kompatibilität mit RPC-Style-Wrappern erforderlich ist. Standardmäßig sind keine RPC-Wrapper erforderlich.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Gibt an, ob das Schema die meisten Verwendungen von Elementen mit xsi:type= 'SOAP-ENC:Array' akzeptieren kann, die mit SOAP 1.1 Abschnitt 5 konsistent sind, auch wenn diese Attribute gegen die XML-Schema-Spezifikation verstoßen. In der Regel muss im xsi:type-Attribut ein Typ benannt werden, der dem tatsächlichen Typ des Elements entspricht oder von diesem abgeleitet ist. Bei Schemata, die mit dieser Option kompiliert wurden, wird xsi: type speziell für den komplexen Typ der SOAP 1.1-Codierung 'Array' akzeptiert, wenn der Elementtyp von SOAP-ENC:Array abgeleitet ist. Das Gegenteil ist der normal zulässige Fall. Standardmäßig werden Elemente mit xsi:type= 'SOAP-ENC:Array' nicht akzeptiert.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Gibt an, ob eine zusätzliche Schemavalidierung nach den Codierungsregeln in SOAP 1.1 Abschnitt 5 ausgeführt werden soll. Wenn diese Option aktiviert ist, werden Member von SOAP-Arrays validiert, Attribute wie @id und @href sind zulässig, auch wenn sie im Schema nicht zulässig sind, und @href -Werte werden überprüft, um sicherzustellen, dass sie über ein entsprechendes @id -Element verfügen. Die zusätzliche Validierung wird standardmäßig nicht ausgeführt.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Gibt an, ob xs:any-Elemente im Schema nur untergeordnete Elemente anhand des Namens validieren. Die XML-Schemaspezifikation erfordert, dass, wenn ein Platzhalterzeichen mit einem Element übereinstimmt, dieses Element jedoch keine Elementdeklaration enthält, das Element stattdessen gemäß einem Attribut vom Typ 'xsi:type' für das Element validiert wird. Diese Option ignoriert die Attribute des Typs 'xsi:type'. Sie sollte für Fälle wie die Validierung von SOAP-Envelopes verwendet werden, bei denen ein weiterer Validierungsschritt den mit dem Platzhalter übereinstimmenden Inhalt validiert, möglicherweise unter Verwendung der SOAP 1.1-Codierungsregeln. Standardmäßig werden die Attribute des Typs 'xsi:type' nicht ignoriert.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Gibt an, ob die SOAP-Bindung in der WSDL-Datei streng eingehalten werden soll. Wenn diese Option aktiviert ist, werden nur Nachrichten, die an SOAP 1.2 gebunden sind, in SOAP 1.2-Envelopes angezeigt und nur Nachrichten, die an SOAP 1.1 gebunden sind, werden in SOAP 1.1-Envelopes angezeigt. Die strenge SOAP-Bindung ist standardmäßig inaktiviert.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Gibt an, ob XACML-Richtlinien mit Debuginformationen kompiliert werden sollen. Beachten Sie, dass die XACML-Debugnachrichten auch durch das Protokollereignis in der XACML-Kategorie gesteuert werden. Verwenden Sie die Debugprotokollstufe, um die vollständigen XACML-Debugnachrichten anzuzeigen. Die XACML-Richtlinien werden standardmäßig nicht mit Debuginformationen kompiliert.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Gibt an, ob das Schema oder das WSDL-Dokument Nachrichten akzeptiert, bei denen base64-codierte binäre Inhalte entsprechend den MTOM/XOP-Spezifikationen optimiert wurden. Die XOP-Binäroptimierung ersetzt base64-codierte binäre Daten durch ein xop:Include-Referenzelement, das auf die uncodierten Binärdaten verweist, die sich in einem Anhang befinden. Für MTOM/XOP optimierte Nachrichten sind standardmäßig inaktiviert.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Eine Konsumentenorganisationsgruppe ist eine Sammlung von Konsumentenorganisationen und bietet eine effiziente Möglichkeit zu kontrollieren, wer die APIs in Ihren Produkten sehen und abonnieren kann. Durch die Verwendung einer Konsumentenorganisationsgruppe können Sie diesen Zugriff für alle Entwickler in den Unternehmen dieser Gruppe in einem einzigen Vorgang definieren, anstatt den Zugriff für die Organisationen separat definieren zu müssen.`,
  [`API Connect Home`]: `API Connect-Ausgangsverzeichnis`,
  [`Add new group`]: `Neue Gruppe hinzufügen`,
  [`Add consumer organizations to the group as required`]: `Konsumentenorganisationen nach Bedarf zur Gruppe hinzufügen`,
  [`Add the following configuration`]: `Folgende Konfiguration hinzufügen`,
  [`Add the following configuration to`]: `Folgende Konfiguration hinzufügen zu`,
  [`Additions`]: `Zusätze`,
  [`Additional GraphQL capabilities`]: `Zusätzliche GraphQL-Funktionen`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Zusätzliche Einstellungen für graphQL-APIs, die in diesem Plan enthalten sind`,
  [`An organization or a group is required.`]: `Eine Organisation oder Gruppe ist erforderlich.`,
  [`An organization/group is required.`]: `Eine Organisation/Gruppe ist erforderlich.`,
  [`Analysis configuration`]: `Analysekonfiguration`,
  [`Application & Credentials`]: `Anwendung & Berechtigungsnachweise`,
  [`Are you sure you want to delete this API version?`]: `Soll diese API-Version tatsächlich gelöscht werden?`,
  [`Are you sure you want to delete this API with all versions?`]: `Sind Sie sicher, dass diese API mit allen Versionen gelöscht werden soll?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Sind Sie sicher, dass Sie die Gateway-Erweiterung vom Gateway entfernen möchten?`,
  [`Are you sure you want to rename all versions of this API?`]: `Sind Sie sicher, dass Sie alle Versionen dieser API umbenennen möchten?`,
  [`Are you sure you want to rename this API version?`]: `Sind Sie sicher, dass diese API-Version umbenannt werden soll?`,
  [`Associate groups to the organization as required`]: `Gruppen nach Bedarf der Organisation zuordnen`,
  [`At least one option must be enabled`]: `Mindestens eine Option muss aktiviert sein`,
  [`Attribute mapping`]: `Attributzuordnung`,
  [`Attempt streaming rule`]: `Streaming-Regel versuchen`,
  [`Auto generated default paths and operations`]: `Automatisch generierte Standardpfade und -operationen`,
  [`Base API endpoint`]: `Basis-API-Endpunkt`,
  [`Consumer orgs`]: `Konsumentenorganisationen`,
  [`Calculate Query Characteristics`]: `Abfragemerkmale berechnen`,
  [`Calculate Response Characteristics`]: `Antwortmerkmale berechnen`,
  [`Certificate or Shared Secret for Verify`]: `Zertifikat oder geheimer Schlüssel für gemeinsame Nutzung zum Bestätigen`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Schauen Sie sich unsere praktischen Übungen an, in denen Sie die vollständigen API-Management-Funktionen bis zu einer Woche lang ausprobieren können!`,
  [`Clone API`]: `API klonen`,
  [`Consumer organizations and groups`]: `Konsumentenorganisationen und -gruppen`,
  [`Create new API version`]: `Neue API-Version erstellen`,
  [`Create new product version`]: `Neue Produktversion erstellen`,
  [`Create a GraphQL API based on a GraphQL service`]: `GraphQL-API basierend auf einem GraphQL-Service erstellen`,
  [`Custom rate limit`]: `Angepasste Ratenbegrenzung`,
  [`Delete API`]: `API löschen`,
  [`Delete this version`]: `Diese Version löschen`,
  [`Deletion confirmation`]: `Löschbestätigung`,
  [`Deletions`]: `Löschvorgänge`,
  [`Display name`]: `Anzeigename`,
  [`Display Name`]: `Anzeigename`,
  [`Details`]: `Details`,
  [`Edit GraphQL Rate Limit|title`]: `GraphQL-Ratenbegrenzung|Titel bearbeiten`,
  [`Edit GraphQL rate limits`]: `GraphQL-Ratenbegrenzungen bearbeiten`,
  [`Edit group`]: `Gruppe bearbeiten`,
  [`Edit condition`]: `Bedingung bearbeiten`,
  [`Edit rate limit|title`]: `Ratenbegrenzung bearbeiten`,
  [`Edit rate limit|menu`]: `Ratenbegrenzung bearbeiten`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Entweder gibt es keine Produkte, die in diesem Katalog veröffentlicht wurden, oder diese Anwendung hat bereits alle Pläne in allen Produkten abonniert.`,
  [`Enforce Policy`]: `Richtlinie umsetzen`,
  [`Enter a group summary`]: `Gruppenzusammenfassung eingeben`,
  [`Enter a group title`]: `Gruppentitel eingeben`,
  [`Enter group details below`]: `Details zur Gruppe unten eingeben`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Geben Sie die URL und den Schemanamen für den GraphQL-Service ein, der als Proxy fungieren soll`,
  [`Enum value`]: `Enum-Wert`,
  [`Go to catalog settings to configure a new billing integration`]: `Zu den Katalogeinstellungen wechseln, um eine neue Abrechnungsintegration zu konfigurieren`,
  [`GraphQL introspect`]: `GraphQL-Introspektion`,
  [`GraphQL server`]: `GraphQL-Server`,
  [`GraphQL Setting`]: `GraphQL-Einstellung`,
  [`GraphQL server URL`]: `GraphQL-Server-URL`,
  [`GraphQL server name`]: `GraphQL-Servername`,
  [`GraphQL type analysis configuration`]: `Analysekonfiguration für GraphQL-Typ`,
  [`GraphQL resolver analysis configuration`]: `Analysekonfiguration für GraphQL-Resolver`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `In der folgenden Liste können Gruppen erstellt und verwaltet werden.`,
  [`Groups will be listed here.`]: `Die Gruppen werden hier aufgelistet.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Hallo {firstName},\n\nIhre Anforderung zum {action} des API-Produkts {productName}:{version} im\n{catalog}-Katalog wurde genehmigt.\n\n{#if comments}\nDer Genehmiger hat die folgenden Kommentare hinzugefügt:\n\n{comments}\n\n{{/if}}\nSie können den Status des Produkts hier überprüfen:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Hallo,\n\ndies ist eine Testnachricht von IBM API Connect vom konfigurierten E-Mail-Server\n(mailServer.\n\nWenn Sie diese Nachricht wie erwartet erhalten haben, war der Test erfolgreich!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo, \n\nBenutzer {originator} hat seine Abonnementanforderung mit der Anwendung {appName}\nfür den Plan {planName} für Version {productVersion} des API-Produkts {productName} im \nKatalog {catalog} zurückgezogen.\n\nEs sind keine weiteren Maßnahmen erforderlich.\n`,
  [`Invoke GraphQL server`]: `GraphQL-Server aufrufen`,
  [`Invoke the backend GraphQL server`]: `Back-End-GraphQL-Server aufrufen`,
  [`JWKS endpoint`]: `JWKS-Endpunkt`,
  [`LDAP Field Name`]: `LDAP-Feldname`,
  [`Limit maximum nesting per query`]: `Maximale Verschachtelung pro Abfrage begrenzen`,
  [`Limit resolve complexity rate on a per key basis`]: `Auflösungskomplexitätsrate auf Schlüsselbasis begrenzen`,
  [`Limit type complexity rate on a per key basis`]: `Typenkomplexitätsrate auf Schlüsselbasis begrenzen`,
  [`Manage APIs included in this product`]: `In diesem Produkt enthaltene APIs verwalten`,
  [`Manage groups`]: `Gruppen verwalten`,
  [`Maximum nesting`]: `Maximale Verschachtelung`,
  [`Maximum resolve complexity`]: `Maximale Auflösungskomplexität`,
  [`Maximum type complexity`]: `Maximale Typenkomplexität`,
  [`Name of the group`]: `Name der Gruppe`,
  [`New API version`]: `Neue API-Version`,
  [`New GraphQL API`]: `Neue GraphQL-API`,
  [`New product version`]: `Neue Produktversion`,
  [`New Version`]: `Neue Version`,
  [`New version`]: `Neue Version`,
  [`No billing integration exists`]: `Es ist keine Abrechnungsintegration vorhanden`,
  [`No CA bundles available`]: `Keine CA-Bundles verfügbar`,
  [`No Data`]: `Keine Daten`,
  [`No certificates available`]: `Keine Zertifikate verfügbar`,
  [`No data`]: `Keine Daten`,
  [`No group found`]: `Keine Gruppe gefunden`,
  [`OAuth Auth URL`]: `OAuth-Auth-URL`,
  [`OAuth Token URL`]: `OAuth-Token-URL`,
  [`Open Stripe dashboard`]: `Stripe-Dashboard öffnen`,
  [`Operation/Path`]: `Operation/Pfad`,
  [`Operation Path`]: `Operationspfad`,
  [`Operations and Paths`]: `Operationen und Pfade`,
  [`Operator`]: `Operator`,
  [`Organizations and groups will be listed here.`]: `Die Organisationen und Gruppen werden hier aufgelistet.`,
  [`Page not found`]: `Seite nicht gefunden`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Kopieren Sie den Code unten in die Befehlszeilenschnittstelle, um den Prozess fortzusetzen. Der Code läuft in **{ttl}** ab.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Referenzieren Sie Ihr Stripe-Dashboard für den Zugriff auf den API-Schlüssel und den geheimen Schlüssel.`,
  [`Please upload a schema definition language (SDL) file.`]: `Laden Sie eine SDL-Datei (Schema Definition Language) hoch`,
  [`Please upload a valid GraphQL schema.`]: `Laden Sie ein gültiges GraphQL-Schema hoch.`,
  [`Please upload a valid YAML file.`]: `Laden Sie eine gültige YAML-Datei hoch.`,
  [`Populate Server Configuration into Context Variables`]: `Serverkonfiguration in Kontextvariablen füllen`,
  [`POST/GET a query to be validated and sent to the backend server`]: `Abfrage mit POST/GET senden bzw. abrufen, die überprüft und an den Back-End-Server gesendet werden soll`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `Abfrage mit POST/GET senden bzw. abrufen, um die geschätzen Kosten zum Aufrufen dieser Abfrage abzurufen`,
  [`Preparing`]: `Vorbereiten`,
  [`Rate limit amount cannot be negative`]: `Der Betrag der Ratenbegrenzung darf nicht negativ sein`,
  [`Rate limit per cannot be negative`]: `Die Ratenbegrenzung darf nicht negativ sein`,
  [`Rename API`]: `API umbenennen`,
  [`Rename`]: `Umbenennen`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Anforderung zur Genehmigung für {action} für ein API-Produkt im Katalog {catalog} catalog`,
  [`Sandbox Sample Application`]: `Sandbox-Beispielanwendung`,
  [`Sandbox sample application credentials`]: `Sandbox-Berechtigungsnachweise für Beispielanwendung`,
  [`sample application credentials`]: `Beispiele für Anwendungsberechtigungsnachweise`,
  [`Saving`]: `Speichern`,
  [`Search Orgs`]: `Organisationen suchen`,
  [`Search definitions...`]: `Definitionen suchen...`,
  [`Search errors...`]: `Fehler suchen...`,
  [`Search operations...`]: `Operationen suchen...`,
  [`Search organizations and groups`]: `Organisationen und Gruppen suchen`,
  [`Sorry, This feature is not available in`]: `Diese Funktion ist nicht verfügbar in`,
  [`Stopping`]: `Stoppen`,
  [`The user with username {username} already exists in the user registry.`]: `Der Benutzer mit dem Benutzernamen {username} ist bereits im Identitätsprovider  vorhanden.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Es sind API-Änderungen vorhanden, die nicht aktiv sind. Veröffentlichen Sie diese API erneut im Sandbox-Katalog.`,
  [`There are no plans available for subscription.`]: `Es sind keine Pläne zum Abonnieren verfügbar.`,
  [`There are no attributes yet.`]: `Es sind noch keine Attribute vorhanden.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Geben Sie etwas ein, um Konsumentenorganisationen zur Gruppe hinzuzufügen`,
  [`Type to add groups to the organization`]: `Geben Sie etwas ein, um Gruppen zur Organisation hinzuzufügen`,
  [`Type to add organizations from {catalog}`]: `Geben Sie etwas ein, um Organisationen aus {catalog} hinzuzufügen`,
  [`Type to add organizations or groups`]: `Geben Sie etwas ein, um Organisationen oder Gruppen hinzuzufügen`,
  [`Type to add organizations or groups from {catalog}`]: `Geben Sie etwas ein, um Organisationen oder Gruppen aus {catalog} hinzuzufügen`,
  [`Type to add {title} to groups`]: `Geben Sie etwas ein, um {title} zu Gruppen hinzuzufügen`,
  [`Update Rate Limits`]: `Ratenbegrenzungen aktualisieren`,
  [`Upload the YAML file with configuration`]: `YAML-Datei mit Konfiguration hochladen`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Verwenden Sie das unten stehende Beispiel als Vorlage für die Konfiguration Ihres GraphQL-Servers.`,
  [`User managed`]: `Benutzerverwaltet`,
  [`URL {url} does not contain valid OpenAPI.`]: `Die URL {url} enthält keine gültige OpenAPI.`,
  [`Validating`]: `Validieren`,
  [`View analytics and performance metrics for your APIs`]: `Analyse- und Leistungsmessdaten für Ihre APIs anzeigen`,
  [`View and add custom policies to the catalog`]: `Angepasste Richtlinien anzeigen und zum Katalog hinzufügen`,
  [`View and add custom exentions to the catalog`]: `Angepasste Erweiterungen für den Katalog anzeigen und hinzufügen`,
  [`View user registries and OAuth providers`]: `Benutzerregistrys und OAuth-Provider anzeigen`,
  [`View your application and manage API credentials`]: `Anwendung anzeigen und API-Anmeldeinformationen verwalten`,
  [`Welcome to the API Connect Trial`]: `Willkommen bei der API Connect-Testversion`,
  [`You haven't added any APIs`]: `Sie haben keine APIs hinzugefügt`,
  [`You currently don’t have any {resource}.`]: `Sie haben derzeit keine {resource}.`,
  [`plans to migrate`]: `Migrationspläne`,
  [`gateway extensions`]: `Gateway-Erweiterungen`,
  [`application`]: `Anwendung`,
  [`application authentication source`]: `Anwendungsauthentifizierungsquelle`,
  [`API or product`]: `API oder Produkt`,
  [`base endpoints`]: `Basisendpunkte`,
  [`categories`]: `Kategorien`,
  [`certificates`]: `Zertifikate`,
  [`channels`]: `Kanäle`,
  [`consumer organizations`]: `Verbraucherorganisationen`,
  [`consumer organization group`]: `Verbraucherorganisationsgruppe`,
  [`consumer organizations or groups`]: `Verbraucherorganisationen oder -gruppen`,
  [`correlation ids`]: `Korrelations-IDs`,
  [`cors policy`]: `CORS-Richtlinie`,
  [`definitions`]: `Definitionen`,
  [`examples`]: `Beispiele`,
  [`gateway services`]: `Gateway-Services`,
  [`groups`]: `Gruppen`,
  [`headers`]: `Header`,
  [`links`]: `Links`,
  [`member`]: `Mitglied`,
  [`messages`]: `Nachrichten`,
  [`message traits`]: `Nachrichteneigenschaften`,
  [`operation traits`]: `Operationseigenschaften`,
  [`parameter definitions`]: `Parameterdefinitionen`,
  [`parameters`]: `Parameter`,
  [`path`]: `Pfad`,
  [`properties`]: `Eigenschaften`,
  [`provider organizations`]: `Providerorganisationen`,
  [`request bodies`]: `Anforderungsstellen`,
  [`response definitions`]: `Antwortdefinitionen`,
  [`responses`]: `Antworten`,
  [`role`]: `Rolle`,
  [`scope`]: `Bereich`,
  [`scopes`]: `Bereiche`,
  [`schemas`]: `Schemas`,
  [`schema definition`]: `Schemadefinition`,
  [`schemes list`]: `Schemataliste`,
  [`security definitions`]: `Sicherheitsdefinitionen`,
  [`security schemes`]: `Sicherheitsschemata`,
  [`servers`]: `Server`,
  [`services`]: `Services`,
  [`tags`]: `Tags`,
  [`tags and external documentation`]: `Tags und externe Dokumentation`,
  [`target services`]: `Zielservices`,
  [`tasks`]: `Tasks`,
  [`user registries`]: `Benutzerregistrys`,
  [`HTTP header`]: `HTTP-Header`,
  [`You currently don’t have any application authentication source.`]: `Sie verfügen derzeit über keine Anwendungsauthentifizierungsquelle.`,
  [`You currently don’t have any API or product.`]: `Sie haben derzeit keine API und kein Produkt.`,
  [`You currently don’t have any API user registries`]: `Sie haben derzeit keine API-Benutzerregistrys.`,
  [`You currently don’t have any APIs.`]: `Sie haben derzeit keine APIs.`,
  [`You currently don’t have any TLS client profiles`]: `Sie haben derzeit keine TLS-Clientprofile.`,
  [`You currently don’t have any base endpoints.`]: `Derzeit verfügen Sie über keine Basisendpunkte.`,
  [`You currently don’t have any catalog properties.`]: `Sie haben derzeit keine Katalogeigenschaften.`,
  [`You currently don’t have any categories.`]: `Sie haben derzeit keine Kategorien.`,
  [`You currently don’t have any certificates.`]: `Sie haben derzeit keine Zertifikate.`,
  [`You currently don’t have any channels.`]: `Sie haben derzeit keine Kanäle.`,
  [`You currently don’t have any consumer organizations.`]: `Sie haben derzeit keine Konsumentenorganisationen.`,
  [`You currently don’t have any consumer organizations or groups.`]: `Derzeit verfügen Sie über keine Konsumentenorganisationen oder -gruppen.`,
  [`You currently don’t have any consumes.`]: `Sie haben derzeit keine Verbrauchsmaterialien.`,
  [`You currently don’t have any correlation ids.`]: `Sie haben derzeit keine Korrelations-IDs.`,
  [`You currently don’t have any cors policy.`]: `Sie haben derzeit keine Kors-Richtlinie.`,
  [`You currently don’t have any definitions.`]: `Sie haben derzeit keine Definitionen.`,
  [`You currently don’t have any examples.`]: `Sie haben derzeit keine Beispiele.`,
  [`You currently don’t have any gateway services.`]: `Sie haben derzeit keine Gateway-Services.`,
  [`You currently don’t have any groups.`]: `Sie haben derzeit keine Gruppen.`,
  [`You currently don’t have any headers.`]: `Sie haben derzeit keine Header.`,
  [`You currently don’t have any keystore.`]: `Sie haben derzeit keinen Keystore.`,
  [`You currently don’t have any links.`]: `Sie haben derzeit keine Links.`,
  [`You currently don’t have any messages.`]: `Sie haben derzeit keine Nachrichten.`,
  [`You currently don’t have any message traits.`]: `Sie haben derzeit keine Nachrichteneigenschaften.`,
  [`You currently don’t have any OAuth providers`]: `Sie verfügen derzeit nicht über OAuth-Provider.`,
  [`You currently don’t have any operation traits.`]: `Sie haben derzeit keine Betriebstraits.`,
  [`You currently don’t have any parameter definitions.`]: `SIe haben derzeit keine Parameterdefinitionen.`,
  [`You currently don’t have any parameters.`]: `Sie haben derzeit keine Parameter.`,
  [`You currently don’t have any preserved request header.`]: `Sie haben derzeit keinen beibehaltenen Anforderungsheader.`,
  [`You currently don’t have any preserved response header.`]: `Sie haben derzeit keinen beibehaltenen Antwortheader.`,
  [`You currently don’t have any produces.`]: `Sie haben derzeit keine Produkte.`,
  [`You currently don’t have any properties.`]: `Sie haben derzeit keine Eigenschaften.`,
  [`You currently don’t have any provider organizations.`]: `Sie haben derzeit keine Provider-Organisationen.`,
  [`You currently don’t have any request bodies.`]: `Sie haben derzeit keine Anforderungshauptteile.`,
  [`You currently don’t have any response definitions.`]: `Sie haben derzeit keine Antwortdefinitionen.`,
  [`You currently don’t have any responses.`]: `Sie haben derzeit keine Antworten.`,
  [`You currently don’t have any scopes.`]: `Sie haben derzeit keine Bereiche.`,
  [`You currently don’t have any schemas.`]: `Sie haben derzeit keine Schemata.`,
  [`You currently don’t have any schemes list.`]: `Sie haben derzeit keine Schemataliste.`,
  [`You currently don’t have any security definitions.`]: `Sie haben derzeit keine Sicherheitsdefinitionen.`,
  [`You currently don’t have any security schemes.`]: `Sie haben derzeit keine Sicherheitsschemata.`,
  [`You currently don’t have any servers.`]: `Sie haben derzeit keine Server.`,
  [`You currently don’t have any services.`]: `Sie haben derzeit keine Services.`,
  [`You currently don’t have any tags.`]: `Sie haben derzeit keine Tags.`,
  [`You currently don’t have any tags and external documentation.`]: `Sie haben derzeit keine Tags und keine externe Dokumentation.`,
  [`You currently don’t have any target services.`]: `Sie haben derzeit keine Zielservices.`,
  [`You currently don’t have any tasks.`]: `Sie haben derzeit keine Tasks.`,
  [`You currently don’t have any truststore.`]: `Sie haben derzeit keinen Truststore.`,
  [`You currently don’t have any user registries.`]: `Sie verfügen derzeit über keine Benutzerregistrys.`,
  [`You currently don’t have any extensions.`]: `Sie haben derzeit keine Erweiterungen.`,
  [`You currently don’t have any HTTP header.`]: `Sie haben derzeit keinen HTTP-Header.`,
  [`Your account is being activated. This may take a while...`]: `Ihr Account wird aktiviert. Dies kann einige Zeit in Anspruch nehmen ...`,
  [`all of`]: `alle`,
  [`any of`]: `beliebige von`,
  [`calls`]: `Aufrufe`,
  [`ciphers`]: `Ciphers`,
  [`extensions`]: `Erweiterungen`,
  [`one of`]: `eine von`,
  [`shared`]: `geteilt`,
  [`stopping`]: `wird gestoppt`,
  [`to:`]: `an:`,
  [`Key`]: `Schlüssel`,
  [`kind`]: `Art`,
  [`Trace`]: `Trace`,
  [`Http Status`]: `Http-Status`,
  [`Parsed`]: `Analysiert`,
  [`Raw`]: `Roh`,
  [`Showing Full Trace`]: `Vollständigen Trace anzeigen`,
  [`Show Entire Trace`]: `Gesamten Trace anzeigen`,
  [`Show advanced`]: `Erweitert anzeigen`,
  [`Advanced`]: `Erweitert`,
  [`Sending Request`]: `Anforderung wird gesendet`,
  [`Please send a request above`]: `Senden Sie oben eine Anforderung.`,
  [`Please send a request above.`]: `Senden Sie oben eine Anforderung.`,
  [`Authentication Type`]: `Authentifizierungstyp`,
  [`You don't currently have any response`]: `Sie haben derzeit keine Antwort.`,
  [`You don't currently have any trace.`]: `Sie haben derzeit keinen Trace.`,
  [`No response received`]: `Keine Antwort erhalten`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Durch Klicken auf 'Link öffnen, um Ausnahme zu akzeptieren' wird der Server in einer neuen Registerkarte geöffnet. Wenn im Browser ein Zertifikatsproblem angezeigt wird, können Sie das Zertifikat akzeptieren und hierher zurückkehren, um den Test zu wiederholen.`,
  [`Open link to accept exception`]: `Link öffnen, um Ausnahme zu akzeptieren`,
  [`Back to {name}`]: `Zurück zu {name}`,
  [`0 seconds`]: `0 Sekunden`,
  [`close.menu`]: `Menü schließen`,
  [`open.menu`]: `Menü öffnen`,
  [`Referenced from "{ref}"`]: `Referenziert von "{ref}"`,
  [`Go to {definition}`]: `Wechseln zu {definition}`,
  [`General`]: `Allgemein`,
  [`Servers`]: `Server`,
  [`External Docs`]: `Externe Dokumente`,
  [`Components`]: `Komponenten`,
  [`Schemas`]: `Schemata`,
  [`Responses`]: `Antworten`,
  [`Examples`]: `Beispiele`,
  [`Request Bodies`]: `Anforderungshauptteile`,
  [`Security Schemes`]: `Sicherheitsschemata`,
  [`Links`]: `Links`,
  [`Callbacks`]: `Callbacks`,
  [`Terms of Service`]: `Servicebedingungen`,
  [`Contact Name`]: `Name des Ansprechpartners`,
  [`Contact URL`]: `URL des Ansprechpartners`,
  [`Contact Email`]: `E-Mail des Ansprechpartners`,
  [`Contact sales`]: `Vertrieb kontaktieren`,
  [`License Name`]: `Name der Lizenz`,
  [`License URL`]: `URL der Lizenz`,
  [`Content-Type`]: `Inhaltstyp`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `Für MTOM/XOP optimierte Nachrichten akzeptieren`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `Token`,
  [`Tag`]: `Tag`,
  [`Request Body`]: `Anforderungshauptteil`,
  [`Security Requirements`]: `Sicherheitsanforderungen`,
  [`Located In`]: `Befindet sich in`,
  [`Example Value`]: `Beispielwert`,
  [`External Value`]: `Externer Wert`,
  [`Server`]: `Server`,
  [`Server Description`]: `Serverbeschreibung`,
  [`Server Variables`]: `Servervariablen`,
  [`Format`]: `Format`,
  [`Pattern`]: `Muster`,
  [`Multiple Of`]: `Mehrfaches von`,
  [`Multiple non null slicing arguments`]: `Mehrere Slicing-Argumente ungleich null`,
  [`Missing slicing arguments`]: `Fehlende Slicing-Argumente`,
  [`Missing sized fields`]: `Fehlende Felder für Größe`,
  [`Maximum`]: `Maximal`,
  [`Exclusive Maximum`]: `Exklusives Maximum`,
  [`Minimum`]: `Minimum`,
  [`Exclusive Minimum`]: `Exklusives Minimum`,
  [`Max Length`]: `Max. Länge`,
  [`Min Length`]: `Min. Länge`,
  [`Max Items`]: `Max. Elemente`,
  [`Min Items`]: `Min. Elemente`,
  [`Unique Items`]: `Eindeutige Elemente`,
  [`Max Properties`]: `Max. Eigenschaften`,
  [`Min Properties`]: `Min. Eigenschaften`,
  [`Required Properties`]: `Erforderliche Eigenschaften`,
  [`Required Property Name`]: `Erforderlicher Eigenschaftsname`,
  [`Enum`]: `Enum`,
  [`Enum Value`]: `Enum-Wert`,
  [`Nullable`]: `Kann Nullwerte enthalten`,
  [`Read Only`]: `Schreibgeschützt`,
  [`Write Only`]: `Nur Schreibzugriff`,
  [`api`]: `API`,
  [`version`]: `Version`,
  [`username`]: `Benutzername`,
  [`password`]: `Kennwort`,
  [`service`]: `Service`,
  [`Allows Empty Value`]: `Leerer Wert zulässig`,
  [`Style`]: `Stil`,
  [`Explode`]: `Zerlegen`,
  [`Allow Reserved`]: `Reserviert zulassen`,
  [`Allow WebSocket Upgrade`]: `WebSocket-Upgrade zulassen`,
  [`Ref`]: `Ref`,
  [`Operation ID`]: `Operations-ID`,
  [`Parameter`]: `Parameter`,
  [`Gateway and portal settings`]: `Gateway- und Portaleinstellungen`,
  [`Targets`]: `Ziele`,
  [`Catalog Properties`]: `Katalogeigenschaften`,
  [`catalog properties`]: `Katalogeigenschaften`,
  [`Attachments`]: `Anhänge`,
  [`Activity Log`]: `Aktivitätenprotokoll`,
  [`API Type`]: `API-Typ`,
  [`Phase`]: `Phase`,
  [`Enable CORS`]: `CORS aktivieren`,
  [`CORS Policy`]: `CORS-Richtlinie`,
  [`Allowed Origins`]: `Zulässige Herkunft`,
  [`Allow Credentials`]: `Berechtigungsnachweise zulassen`,
  [`ID`]: `ID`,
  [`Success Content`]: `Erfolgsinhalt`,
  [`Error Content`]: `Fehlerinhalt`,
  [`Attachment`]: `Anhang`,
  [`Link`]: `Link`,
  [`Operation Ref`]: `Operationsreferenz`,
  [`TRACE`]: `TRACE`,
  [`No Security Requirements available`]: `Keine Sicherheitsanforderungen verfügbar`,
  [`Create a security scheme`]: `Sicherheitsschema erstellen`,
  [`Create a Security Requirement`]: `Sicherheitsanforderung erstellen`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Fordern Sie eine der folgenden Sicherheitsanforderungen an. Wenn diese Option nicht aktiviert ist, ist die Sicherheit optional.`,
  [`Require wrappers on fault details specified by type`]: `Erfordert nach Typ angegebene Wrapper für Fehlerdetails`,
  [`There are no "Servers"`]: `Es gibt keine "Server"`,
  [`Write`]: `Schreiben`,
  [`Server Variable`]: `Servervariable`,
  [`Security Requirement`]: `Sicherheitsanforderung`,
  [`Security Scheme Type`]: `Sicherheitsschematyp`,
  [`Security Scheme`]: `Sicherheitsschema`,
  [`OAuth Flows`]: `OAuth-Datenflüsse`,
  [`Implicit Flow`]: `Impliziter Ablauf`,
  [`Password Flow`]: `Kennwortablauf`,
  [`Client Credentials Flow`]: `Ablauf für Clientberechtigungsnachweise`,
  [`Authorization Code Flow`]: `Berechtigungscodeablauf`,
  [`Refresh URL`]: `Aktualisierungs-URL`,
  [`schema`]: `Schema`,
  [`Allow Empty Value`]: `Leeren Wert zulassen`,
  [`Encoding`]: `Codierung`,
  [`This field is required`]: `Dieses Feld ist erforderlich`,
  [`Temporary token`]: `Temporäres Token`,
  [`Access token`]: `Zugriffstoken`,
  [`ID token`]: `ID-Token`,
  [`No Security Schemes available`]: `Keine Sicherheitsschemata verfügbar`,
  [`Security Scheme (Key)`]: `Sicherheitsschema (Schlüssel)`,
  [`There are no "{label}"`]: `Es sind keine '{label}' vorhanden`,
  [`You must select at least one scheme to define a Security Requirement`]: `Sie müssen mindestens ein Schema auswählen, um eine Sicherheitsanforderung zu definieren.`,
  [`Select one or more Security Schemes`]: `Wählen Sie mindestens ein Sicherheitsschema aus.`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `Keine OAuth-Bereiche für das Sicherheitsschema '{key}' verfügbar`,
  [`Create a Scope`]: `Bereich erstellen`,
  [`Remove this Security Requirement`]: `Diese Sicherheitsanforderung entfernen`,
  [`Edit this Security Requirement`]: `Diese Sicherheitsanforderung bearbeiten`,
  [`Invalid Security Scheme Reference`]: `Ungültige Referenz für Sicherheitsschema`,
  [`The following Security Schemes do not exist!`]: `Die folgenden Sicherheitsschemata sind nicht vorhanden!`,
  [`Edit source`]: `Quelle bearbeiten`,
  [`OR`]: `ODER`,
  [`Required Security Schemes`]: `Erforderliche Sicherheitsschemata`,
  [`Optional Security`]: `Optionale Sicherheit`,
  [`Optional custom expression (one per line)`]: `Optionaler benutzerdefinierter Ausdruck (einer pro Zeile)`,
  [`Flow Type`]: `Datenflusstyp`,
  [`OAuth2 Security Scheme`]: `OAuth2-Sicherheitsschema`,
  [`Only source editing is supported.`]: `Nur die Quellenbearbeitung wird unterstützt.`,
  [`Unsupported Field.`]: `Nicht unterstütztes Feld.`,
  [`Select an option`]: `Option auswählen`,
  [`Select {labelText}`]: `{labelText} auswählen`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" wird bereits verwendet'`,
  [`Referenced from "{originalRef}"`]: `Referenziert von "{originalRef}"`,
  [`Go to {label}`]: `Zu {label} wechseln`,
  [`Delete "{label}"`]: `"{label}" löschen`,
  [`Are you sure you want to delete "{label}"?`]: `Soll "{label}" wirklich gelöscht werden?`,
  [`No recent items found`]: `Keine aktuellen Elemente gefunden`,
  [`Found {numDocs} results ({took} seconds)`]: `{numDocs} Ergebnisse gefunden ({took} Sekunden)`,
  [`No Consumer Organizations found`]: `Keine Konsumentenorganisationen gefunden`,
  [`No Catalog Products found`]: `Keine Katalogprodukte gefunden`,
  [`No APIs found`]: `Keine APIs gefunden`,
  [`No Catalogs found`]: `Keine Kataloge gefunden`,
  [`No Spaces found`]: `Keine Bereiche gefunden`,
  [`No results found`]: `Keine Ergebnisse gefunden`,
  [`No Catalog APIs found`]: `Keine Katalog-APIs gefunden`,
  [`No Catalogs found matching query "{query}"`]: `Keine Kataloge gefunden, die der Abfrage "{query}" entsprechen`,
  [`No APIs found matching query "{query}"`]: `Keine APIs gefunden, die der Abfrage "{query}" entsprechen`,
  [`No Subscriptions found`]: `Keine Abonnements gefunden`,
  [`No Plans found matching query "{query}"`]: `Keine Pläne gefunden, die der Abfrage "{query}" entsprechen`,
  [`No Subscriptions found matching query "{query}"`]: `Keine Abonnements gefunden, die der Abfrage "{query}" entsprechen`,
  [`No Catalog Products found matching query "{query}"`]: `Keine Katalogprodukte gefunden, die der Abfrage "{query}" entsprechen`,
  [`No Catalog APIs found matching query "{query}"`]: `Keine Katalog-APIs gefunden, die der Abfrage "{query}" entsprechen`,
  [`No Consumer Organizations found matching query "{query}"`]: `Keine Konsumentenorganisationen gefunden, die der Abfrage "{query}" entsprechen`,
  [`No Applications found matching query "{query}"`]: `Keine Anwendungen gefunden, die der Abfrage "{query}" entsprechen`,
  [`APIs per page`]: `APIs pro Seite`,
  [`Catalogs per page`]: `Kataloge pro Seite`,
  [`items per page`]: `Elemente pro Seite`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} von {total} APIs`,
  [`{min}-{max} of {total} API`]: `{min.}-{max.} von {gesamt} API`,
  [`Products per page`]: `Produkte pro Seite`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Das Produkt sollte im Katalog veröffentlicht werden, damit eine neue Abrechnungsintegration konfiguriert werden kann`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} von {total} Produkten`,
  [`{min}-{max} of {total} Product`]: `{min.}{max.} von {gesamtl} Produkt`,
  [`Catalog Products per page`]: `Katalogprodukte pro Seite`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} von {total} Katalogprodukten`,
  [`{min}-{max} of {total} Catalog Product`]: `{min.}-{max.} von {gesamt} Katalogprodukt`,
  [`Plans per page`]: `Pläne pro Seite`,
  [`Policies per page`]: `Richtlinien pro Seite`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} von {total} Plänen`,
  [`{min}-{max} of {total} Plan`]: `{min.}-{max.} von {gesamt} Plan`,
  [`Catalog APIs per page`]: `Katalog-APIs pro Seite`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} von {total} Katalog-APIs`,
  [`{min}-{max} of {total} Catalog API`]: `{min.}-{max.} von {gesamt} Katalog-API`,
  [`Subscriptions per page`]: `Abonnements pro Seite`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} von {total} Abonnements`,
  [`{min}-{max} of {total} Subscription`]: `{min.}-{max.} von {gesamt} Abonnement`,
  [`Consumer Organizations per page`]: `Konsumentenorganisationen pro Seite`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} von {total} Konsumentenorganisationen`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min.}-{max.} von {gesamt} Konsumentenorganisation`,
  [`Applications per page`]: `Anwendungen pro Seite`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} von {total} Anwendungen`,
  [`{min}-{max} of {total} Application`]: `{min.}-{max.} von {gesamt} Anwendung`,
  [`Histories per page`]: `Protokolle pro Seite`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} von {total} Protokollen`,
  [`{min}-{max} of {total} History`]: `{min.}-{max.} von {gesmt} Verlauf`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Es wurden keine Pläne gefunden, die abonniert werden können. Ein Produkt muss sich im Status "veröffentlicht" befinden, damit ein Abonnement erstellt werden kann. Eine Anwendung kann nur einen Plan in einem Produkt abonnieren.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Veröffentlichte APIs "{draftApiTitle}:{draftApiVersion}" werden angezeigt`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Konsumentenorganisation für "{appTitle}" wird angezeigt`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Abonnements für "{productTitle}:{productVersion}" werden angezeigt`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Pläne für "{productTitle}:{productVersion}" werden angezeigt`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `APIs für "{productTitle}:{productVersion}" werden angezeigt`,
  [`Showing Product for "{planTitle}"`]: `Produkt für "{planTitle}" wird angezeigt`,
  [`Showing APIs for "{planTitle}"`]: `APIs für "{planTitle}" werden angezeigt`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Abonnements für Plan "{docTitle}" in Produkt "{docFoundIn}" werden angezeigt`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Pläne für "{apiTitle}:{apiVersion}" werden angezeigt`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Entwurfs-APIs für "{catalogApiTitle}:{catalogApiVersion}" werden angezeigt`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Protokolle für "{productTitle}:{productVersion}" werden angezeigt`,
  [`Showing Subscriptions for "{appTitle}"`]: `Abonnements für "{appTitle}" werden angezeigt`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Anwendungen für "{consumerOrgTitle}" werden angezeigt`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Abonnements für "{consumerOrgTitle}" werden angezeigt`,
  [`Showing Application for selected Subscription`]: `Anwendung für ausgewähltes Abonnement wird angezeigt`,
  [`Showing Plan for selected Subscription`]: `Plan für ausgewähltes Abonnement wird angezeigt`,
  [`Showing Product for selected Subscription`]: `Produkt für ausgewähltes Abonnement wird angezeigt`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Entwurfs-APIs auswählen, die in das Produkt "{productTitle}" eingeschlossen werden sollen`,
  [`Product Version`]: `Produktversion`,
  [`OAuth Providers`]: `OAuth-Provider`,
  [`Application Type`]: `Anwendungstyp`,
  [`Client IDs`]: `Client-IDs`,
  [`Save as New Version`]: `Als neue Version speichern`,
  [`Update Selected APIs`]: `Ausgewählte APIs aktualisieren`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Die API {titleAndVersion} wird dauerhaft gelöscht. Möchten Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Das Produkt {titleAndVersion} wird dauerhaft gelöscht. Möchten Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Das Katalogprodukt {titleAndVersion} wird dauerhaft gelöscht. Möchten Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Die Konsumentenorganisation {titleAndVersion} wird dauerhaft gelöscht. Möchten Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Die Konsumentengruppe '{titleAndVersion}' wird dauerhaft gelöscht. Wollen Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Die Richtlinie {titleAndVersion} wird dauerhaft gelöscht. Möchten Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Das Abonnement {titleAndVersion} wird dauerhaft gelöscht. Möchten Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Der Katalog '{titleAndVersion}' wird dauerhaft gelöscht. Wollen Sie fortfahren?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Der Bereich '{titleAndVersion}' wird dauerhaft gelöscht. Wollen Sie fortfahren?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `Das Katalogprodukt {titleAndVersion} wird erneut veröffentlicht. Möchten Sie fortfahren?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `Das Katalogprodukt {titleAndVersion} wird zurückgezogen. Möchten Sie fortfahren?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `Das Katalogprodukt {titleAndVersion} wird nicht mehr unterstützt. Möchten Sie fortfahren?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `Das Katalogprodukt {titleAndVersion} wird erneut bereitgestellt. Möchten Sie fortfahren?`,
  [`Put Online`]: `Online stellen`,
  [`Take Offline`]: `Offline schalten`,
  [`View Subscriptions`]: `Abonnement anzeigen`,
  [`View Plans`]: `Pläne anzeigen`,
  [`No Tasks found`]: `Keine Tasks gefunden`,
  [`Tasks per page`]: `Tasks pro Seite`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} von {total} Tasks`,
  [`{min}-{max} of {total} Task`]: `{min.}-{max.} von {gesamt} Task`,
  [`Decline and Send`]: `Ablehnen und senden`,
  [`View Applications`]: `Anwendungen anzeigen`,
  [`Publish (Preserve Subscriptions)`]: `Veröffentlichen (Abonnements beibehalten)`,
  [`Update APIs`]: `APIs aktualisieren`,
  [`View in Catalogs`]: `In Katalogen anzeigen`,
  [`View Consumer Organization`]: `Konsumentenorganisation anzeigen`,
  [`View Application`]: `Anwendung anzeigen`,
  [`View Plan`]: `Plan anzeigen`,
  [`View Product`]: `Produkt anzeigen`,
  [`Create Subscription`]: `Abonnement erstellen`,
  [`No Applications found`]: `Keine Anwendungen gefunden`,
  [`Select a Plan to Create a Subscription to`]: `Wählen Sie einen Plan zum Erstellen eines Abonnements aus`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Diese Aktion kann nicht rückgängig gemacht werden. Die Anwendung {titleAndVersion} wird dauerhaft gelöscht. Möchten Sie fortfahren?`,
  [`Save as new version`]: `Als neue Version speichern`,
  [`Filter selected catalogs`]: `Ausgewählte Kataloge filtern`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Entwurfs-APIs auswählen, die in das Produkt "{productTitle}" eingeschlossen werden sollen`,
  [`Update selected APIs`]: `Ausgewählte APIs aktualisieren`,
  [`Catalog API`]: `Katalog-API`,
  [`Catalog APIs`]: `Katalog-APIs`,
  [`View Products`]: `Produkte anzeigen`,
  [`View Draft APIs`]: `API-Entwürfe anzeigen`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Produkte anzeigen, die auf "{apiTitle}:{apiVersion}" verweisen`,
  [`No Plans found`]: `Keine Pläne gefunden`,
  [`View APIs`]: `APIs anzeigen`,
  [`Document type tabs`]: `Dokumenttypregisterkarten`,
  [`No Products found`]: `Keine Produkte gefunden`,
  [`Consumer Organization`]: `Konsumentenorganisation`,
  [`Consumer Organizations`]: `Konsumentenorganisationen`,
  [`Catalog Product`]: `Katalogprodukte`,
  [`Catalog Products`]: `Katalogprodukte`,
  [`Search for a Product and Plan`]: `Nach Produkt und Plan suchen`,
  [`OpenAPI Version`]: `OpenAPI-Version`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (REST, GraphQL oder SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (aus Kafka-Thema)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `AsyncAPI erstellen (aus Kafka-Thema)`,
  [`An error occurred during file upload`]: `Beim Hochladen der Datei ist ein Fehler aufgetreten`,
  [`Delete uploaded file`]: `Hochgeladene Datei löschen`,
  [`Copied!`]: `Kopiert!`,
  [`Uploading`]: `Upload läuft`,
  [`Validation icon`]: `Überprüfungssymbol`,
  [`Validation error`]: `Gültigkeitsfehler`,
  [`Uploading image..`]: `Grafik wird hochgeladen...`,
  [`Unsupported media type {providedFileType}`]: `Nicht unterstützter Datenträgertyp {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Wählen Sie eine unterstützte Datei {expectedFileTypes} aus und versuchen Sie es erneut.`,
  [`Pasted`]: `Eingefügt`,
  [`Enter the API summary details.`]: `Geben Sie die Details der API-Zusammenfassung ein.`,
  [`Define your Kafka cluster connection details.`]: `Definieren Sie die Kafka-Clusterverbindungsdetails.`,
  [`Channel`]: `Kanal`,
  [`Describe your Kafka topic and its associated schema.`]: `Beschreiben Sie Ihr Kafka-Thema und das zugehörige Schema.`,
  [`AsyncAPI specification version`]: `AsyncAPI-Spezifikationsversion`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Ein Name, der Ihre API beschreibt und Entwicklern hilft, sie im Developer Portal zu entdecken.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Eine Version für Ihre API-Definition. Die Kombination aus Name und Version identifiziert eindeutig Ihre API-Definition.`,
  [`Description (optional)`]: `Beschreibung (optional)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Führen Sie Ihre API ein und stellen Sie hilfreiche Informationen für Entwickler bereit.`,
  [`Summary (optional)`]: `Zusammenfassung (optional)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Eine kurze Zusammenfassung der Ereignisse, die in dieser API enthalten sind. Maximal 1000 Zeichen.`,
  [`Bootstrap servers`]: `Bootstrap-Server`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Eine durch Kommas getrennte Liste der Kafka-Broker in Ihrem Cluster.`,
  [`Secure the API using an API Key and Secret`]: `API mithilfe eines API-Schlüssels und eines geheimen Schlüssels sichern`,
  [`The credential provided should be in SASL-PLAIN format`]: `Der angegebene Berechtigungsnachweis sollte im SASL-PLAIN-Format angegeben werden.`,
  [`Publish this API:`]: `Diese API veröffentlichen:`,
  [`Creates a product`]: `Erstellt ein Produkt`,
  [`Associates this API to the product`]: `Ordnet diese API dem Produkt zu`,
  [`Publish the product to the Sandbox catalog`]: `Produkt im Sandbox-Katalog veröffentlichen`,
  [`Associates the product to the test app`]: `Ordnet das Produkt der Test-App zu`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Ein Schema im Format "{fileType}" wurde erfolgreich hochgeladen und validiert.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `Die hochgeladene Datei ist kein gültiges Avro-Schema und wird nicht als Teil Ihrer API eingeschlossen.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Serverantwort: "{err}". Klicken Sie auf "Zurück", um zur API-Definitionsseite zurückzukehren.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Serverantwort: "{err}". Klicken Sie auf "Fertig", um den Assistenten zu beenden.`,
  [`Topic name`]: `Themenname`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `Der Name des Themas, das als Teil dieser API verfügbar gemacht werden soll. Ein Thema ist ein benannter Datenstrom von Nachrichten.`,
  [`Schema for message body in .avsc format (optional)`]: `Schema für Nachrichtenhauptteil im .avsc-Format (optional)`,
  [`Drag and drop files here, or click to upload`]: `Datei hierher ziehen und ablegen oder für Upload klicken`,
  [`About this step`]: `Informationen zu diesem Schritt`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Ereignisse können als asynchrone APIs beschrieben werden, da die Konsumenten Ereignisse empfangen, sobald sie verfügbar werden. Synchrone APIs, wie z. B. REST, empfangen hingegen nur Antworten, wenn sie sie anfordern.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Ereignisgesteuerte asynchrone APIs werden unter Verwendung der AsyncAPI-Spezifikation dokumentiert.`,
  [`API secret`]: `Geheimer API-Schlüssel`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Generierte Definition für AsyncAPI 2.1.0`,
  [`API creating`]: `API-Erstellung`,
  [`An error occurred while creating your document`]: `Beim Erstellen Ihres Dokuments ist ein Fehler aufgetreten`,
  [`Publishing your API`]: `API veröffentlichen`,
  [`Your API has been published`]: `Ihre API wurde veröffentlicht`,
  [`An error occurred while publishing your API`]: `Beim Veröffentlichen der API ist ein Fehler aufgetreten.`,
  [`Configure security for this API`]: `Sicherheit für diese API konfigurieren`,
  [`Cluster connection security`]: `Clusterverbindungssicherheit`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Geben Sie die Anmeldeinformationen an, die Ereignisgateway-Services verwenden würden, um sich mit Ihrem Cluster zu verbinden, wenn sie Anfragen an diese API bearbeiten. Sie können diese Werte später im Editor ändern. Weitere Informationen finden Sie in der Dokumentation zum Erstellen von Berechtigungsnachweisen.`,
  [`SASL Username`]: `SASL-Benutzername`,
  [`SASL Password`]: `SASL-Kennwort`,
  [`Transport CA certificate (.pem file - optional)`]: `CA-Transportzertifikat (.pem file-optional)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Falls angegeben, wird dies vom Ereignisgateway-Service verwendet, um TLS-Verschlüsselung für die Kommunikation zwischen dem Gateway und dem Kafka-Cluster bereitzustellen.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Beschreiben Sie die API-Details Ihrer Apache-Kafka-Ereignisquelle, um ein AsyncAPI-Dokument zu generieren.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `Anschließend können Sie das AsyncAPI-Dokument veröffentlichen, um die Ereignisquelle für Anwendungsentwickler zugänglich zu machen, die ihre Anwendungen so konfigurieren können, dass sie den zugehörigen Datenstrom abonnieren.`,
  [`Set up security for your API if you want to control who can access it.`]: `Konfigurieren Sie die Sicherheit für Ihre API, wenn Sie steuern möchten, wer auf sie zugreifen kann.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `Der Zugriff auf Ihre API wird von einem Ereignisgateway-Service verwaltet, das auf einen gültigen API-Schlüssel und einen gültigen geheimen API-Schlüssel prüft.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Für Gateways müssen Clients ihre Kommunikation mit TLS zu verschlüsseln.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Nachdem Ihre API erstellt wurde, klicken Sie auf 'API bearbeiten', um weitere Details hinzuzufügen, wie z. B. Sicherheitsdetails, mit denen eine Verbindung zu Ihrem Kafka-Cluster von Ihrem Ereignisgateway-Service hergestellt werden kann.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `Anschließend können Sie Ihre API veröffentlichen und sie Entwicklern zur Verfügung stellen, die dann Zugriff auf die API erkennen und anfordern können. Außerdem können Sie die Informationen verwenden, um ihre Anwendungen so zu konfigurieren, dass Sie die Ereignisquelle nutzen.`,
  [`Pending backend response`]: `Anstehende Back-End-Antwort`,
  [`Complete`]: `Abgeschlossen`,
  [`Rate limiting is only applied to enforced APIs`]: `Die Ratenbegrenzung wird nur auf erzwungene APIs angewendet`,
  [`No Plans Available`]: `Keine Pläne verfügbar`,
  [`Modal content is not provided`]: `Modalinhalt wird nicht bereitgestellt`,
  [`Modal heading is not provided`]: `Modalüberschrift wird nicht bereitgestellt`,
  [`Edit Rate Limit`]: `Ratenbegrenzung bearbeiten`,
  [`External Documentation`]: `Externe Dokumentation`,
  [`No Label Available`]: `Keine Beschriftung verfügbar`,
  [`optional`]: `optional`,
  [`Uploading image...`]: `Bild wird hochgeladen...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Hängen Sie Dateien an, indem Sie sie ziehen, ablegen, auswählen oder einfügen.`,
  [`Response name`]: `Antwortname`,
  [`Click Add to add a new {resource}.`]: `Klicken Sie auf 'Hinzufügen', um eine neue {resource} hinzuzufügen.`,
  [`Schema Name (Key)`]: `Schemaname (Schlüssel)`,
  [`Xml`]: `XML`,
  [`Namespace`]: `Namensbereich`,
  [`Attribute`]: `Attribut`,
  [`Wrapped`]: `Eingeschlossen`,
  [`Example`]: `Beispiel`,
  [`Example Name (Key)`]: `Beispielname (Key)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" wird bereits verwendet`,
  [`Name (Key)`]: `Name (Schlüssel)`,
  [`Content Type (Key)`]: `Inhaltstyp (Schlüssel)`,
  [`Header name`]: `Headername`,
  [`Reference`]: `Referenz`,
  [`Security Scheme Name (Key)`]: `Name des Sicherheitsschemas (Schlüssel)`,
  [`Choose a Reference`]: `Referenz auswählen`,
  [`Reference from`]: `Referenz von`,
  [`Content Type`]: `Inhaltstyp`,
  [`array`]: `Array`,
  [`boolean`]: `boolesch`,
  [`integer`]: `Ganzzahl`,
  [`number`]: `Zahl`,
  [`object`]: `Objekt`,
  [`string`]: `Zeichenfolge`,
  [`query`]: `Abfrage`,
  [`cookie`]: `Cookie`,
  [`matrix`]: `Matrix`,
  [`label`]: `Beschriftung`,
  [`simple`]: `Einfach`,
  [`form`]: `Formular`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth-Provider (Name)`,
  [`Authenticate using User Registry (name)`]: `Authentifizierung mit Benutzerregistry (Name)`,
  [`Bearer Format`]: `Trägerformat`,
  [`Server Variable Name`]: `Servervariablenname`,
  [`Verb (Key)`]: `Verb (Schlüssel)`,
  [`Parameter Definitions`]: `Parameterdefinitionen`,
  [`Path Parameters`]: `Pfadparameter`,
  [`Media Type List`]: `Datenträgertypenliste`,
  [`Medium`]: `Medium`,
  [`Schemes List`]: `Schemataliste`,
  [`Small`]: `Klein`,
  [`Response Definitions`]: `Antwortdefinitionen`,
  [`Terms Of Service`]: `Servicebedingungen`,
  [`Url`]: `URL`,
  [`Untitled Parameter`]: `Unbenannte Parameter`,
  [`Parameter Name`]: `Parametername`,
  [`Mime Type`]: `MIME-Typ`,
  [`Async API`]: `AsyncAPI`,
  [`Channels`]: `Kanäle`,
  [`Operation Traits`]: `Operationseigenschaften`,
  [`Messages`]: `Nachrichten`,
  [`Correlation IDs`]: `Korrelations-IDs`,
  [`Traits`]: `Eigenschaften`,
  [`Message Traits`]: `Nachrichteneigenschaften`,
  [`Bindings`]: `Bindungen`,
  [`Server Bindings`]: `Serverbindungen`,
  [`Channel Bindings`]: `Kanalbindungen`,
  [`Operation Bindings`]: `Operationsbindungen`,
  [`Message Bindings`]: `Nachrichtenbindungen`,
  [`Protocol Version`]: `Protokollversion`,
  [`Define multiple messages`]: `Mehrere Nachrichten definieren`,
  [`Schema Format`]: `Schemaformat`,
  [`Unsupported Field`]: `Nicht unterstütztes Feld`,
  [`OpenIdConnectUrl`]: `OpenID Connect-URL`,
  [`Operation Trait`]: `Operationseigenschaft`,
  [`Message Trait`]: `Nachrichteneigenschaft`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Verwenden Sie die operation-switch-Richtlinie, um einen Abschnitt der Assembly auf eine bestimmte Operation anzuwenden.`,
  [`HTML page`]: `HTML-Seite`,
  [`Rule`]: `Regel`,
  [`Origin`]: `Ursprung`,
  [`Property Name (Key)`]: `Eigenschaftsname (Schlüssel)`,
  [`Catalog Name (Key)`]: `Katalogname (Schlüssel)`,
  [`Property Overrides`]: `Eigenschaftsüberschreibungen`,
  [`Property Name`]: `Eigenschaftsname`,
  [`Property Value`]: `Eigenschaftswert`,
  [`About subscriptions and plans`]: `Informationen zu Abonnements und Plänen`,
  [`Subscription Plan Name`]: `Abonnementplanname`,
  [`Select plan`]: `Plan auswählen`,
  [`Select the plan to migrate application subscriptions from`]: `Wählen Sie den Plan für die Migration von Anwendungsabonnements aus`,
  [`Select subscriptions`]: `Abonnements auswählen`,
  [`Select the application subscriptions to migrate`]: `Anwendungsabonnements für Migration auswählen`,
  [`Select the product to migrate application subscriptions to`]: `Wählen Sie das Produkt für die Migration von Anwendungsabonnements aus`,
  [`Select the plan to migrate application subscriptions to`]: `Wählen Sie den Plan für die Migration von Anwendungsabonnements aus`,
  [`Message successfully sent to {sent} owner(s)`]: `Nachricht wurde erfolgreich an  {sent} Eigner gesendet`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} Nachrichten konnten nicht gesendet werden`,
  [`Failed email addresses`]: `Fehlgeschlagene E-Mail-Adressen`,
  [`Failed to save the API`]: `API konnte nicht gespeichert werden`,
  [`and {numExtra} more...`]: `und {numExtra} weitere...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Der Liste der E-Mail-Adressen, die nicht gesendet werden konnten, in die Zwischenablage kopieren`,
  [`From`]: `Von`,
  [`Message Owner`]: `Nachrichteneigner`,
  [`Message owner`]: `Nachrichteneigner`,
  [`Message owners`]: `Nachrichteneigentümer`,
  [`Message group`]: `Nachrichtengruppe`,
  [`Select consumer organizations`]: `Konsumentenorganisationen auswählen`,
  [`Message consumer organization owners`]: `Nachricht an Eigner der Konsumentenorganisation senden`,
  [`Message consumer organization owner`]: `Nachricht an Eigner der Verbraucherorganisation senden`,
  [`Message provider organization owner`]: `Nachricht an Eigner der Providerorganisation senden`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `Die Kennung für den Kafka-Cluster, der sich auf diese Aufrufrichtlinie bezieht.`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Eine durch Kommas getrennte Liste von Host-Port-Paaren, die für die Herstellung von Verbindungen zum Kafka-Cluster verwendet werden sollen.`,
  [`The username to use when connecting to the Kafka cluster.`]: `Der für die Verbindung zum Kafka-Cluster zu verwendende Benutzername.`,
  [`The password to use when connecting to the Kafka cluster.`]: `Das für die Verbindung zum Kafka-Cluster zu verwendende Kennwort.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Ereignisgateway-Service`,
  [`Event gateway management client keystore`]: `Keystore des Ereignisgateway-Management-Clients`,
  [`Edit Event gateway Service`]: `Ereignisgateway-Service bearbeiten`,
  [`Configure Event Gateway Service`]: `Ereignisgateway-Service konfigurieren`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Konfigurieren Sie einen Ereignisgateway-Service zum Sichern und Erzwingen von asynchronen APIs`,
  [`Catalogs with published product`]: `Kataloge mit veröffentlichtem Produkt`,
  [`Cluster Config ID`]: `Clusterkonfigurations-ID`,
  [`SASL mechanism`]: `SASL-Mechanismus`,
  [`Security protocol`]: `Sicherheitsprotokoll`,
  [`Transport CA certificate`]: `Transport-CA-Zertifikat`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `Die maximale Dateigröße ist {size}. Unterstützte Dateitypen sind json, xml, yml, yaml & zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Unterstützte Dateitypen sind json, xml, yml und yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Unterstützte Dateitypen sind json, wsdl, xml, xsd, yml, yaml & zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Unterstützte Dateitypen sind json, wsdl, xml, yml und yaml.`,
  [`Select a file`]: `Wählen Sie eine Datei aus`,
  [`or specify a file URL`]: `oder geben Sie eine Datei-URL an`,
  [`Generated OpenAPI {version} definition`]: `OpenAPI {version}-Definition generiert`,
  [`User registry type`]: `Benutzerregistrytyp`,
  [`User registry types`]: `Benutzerregistrytypen`,
  [`User registry type {title} has been created.`]: `Benutzerregistrytyp {title} wurde erstellt.`,
  [`User registry type {title} has been updated.`]: `Benutzerregistrytyp {title} wurde aktualisiert.`,
  [`Failed to create user registry type {title}.`]: `Benutzerregistrytyp {title} konnte nicht erstellt werden.`,
  [`Failed to update user registry type {title}.`]: `Benutzerregistrytyp {title} konnte nicht aktualisiert werden.`,
  [`Failed to update user registry {title}.`]: `Benutzerregistry {title} konnte nicht aktualisiert werden.`,
  [`Custom user registry`]: `Angepasste Benutzerregistry`,
  [`Create custom user registry`]: `Angepasste Benutzerregistry erstellen`,
  [`Edit custom user registry`]: `Angepasste Benutzerregistry bearbeiten`,
  [`Create user registry type`]: `Benutzerregistrytyp erstellen`,
  [`Edit user registry type`]: `Benutzerregistrytyp bearbeiten`,
  [`Configure user authentication using a custom external user registry`]: `Benutzerauthentifizierung mit einer angepassten externen Benutzerregistry konfigurieren`,
  [`Secured Endpoint`]: `Gesicherter Endpunkt`,
  [`Configuration schema`]: `Konfigurationsschema`,
  [`About custom user registries`]: `Informationen zu angepassten Benutzerregistrys`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Angepasste Benutzerregistrys können zum Authentifizieren von Benutzern für das Developer Portal, aber nicht für APIs verwendet werden. Um eine angepasste Benutzerregistry als Ressource in API Manager zu konfigurieren, muss das externe Benutzerverzeichnis erstellt und für die Verwendung mit Ihrem API Connect-Ökosystem zur Verfügung gestellt werden.`,
  [`Update Wsdl`]: `WSDL aktualisieren`,
  [`Update Existing WSDL service`]: `Vorhandenen WSDL-Service aktualisieren`,
  [`Format of selected WSDL has been successfully validated`]: `Das Format der ausgewählten WSDL wurde erfolgreich geprüft.`,
  [`Select the target WSDL file to update from`]: `Wählen Sie die WSDL-Zieldatei aus, aus der aktualisiert werden soll`,
  [`Selected WSDL has been successfully uploaded`]: `Ausgewählte WSDL wurde erfolgreich hochgeladen`,
  [`Services available in selected API`]: `Verfügbare Services in ausgewählter API`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Traditionellen Open API Editor für OpenAPI 2.0-Dokumente aktivieren (veraltet)`,
  [`Unknown`]: `Unbekannt`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleCatalogCount} Katalog (e) wegen Inkompatibilität mit dem in der API oder dem Produkt angegebenen Gateway-Typ ausgeblendet`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} Bereich(e) wegen Inkompatibilität mit dem in der API oder dem Produkt angegebenen Gateway-Typ ausgeblendet`,
  [`Keep in mind`]: `Nicht vergessen`,
  [`Only one type of enforced APIs is allowed per product`]: `Pro Produkt ist nur ein Typ von erzwungenen APIs zulässig.`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `{incompatibleAPIsCount} API(s) kann/können aufgrund der Inkompatibilität mit dem im Produkt angegebenen Gateway-Typ nicht ausgewählt werden.`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `{incompatibleProductsCount} Produkt(e) kann/können aufgrund der Inkompatibilität mit dem in der API angegebenen Gateway-Typ nicht ausgewählt werden.`,
  [`Unenforced`]: `Nicht erzwungen`,
  [`Create a new product by adding APIs and plans.`]: `Erstellen Sie ein neues Produkt, indem Sie APIs und Pläne hinzufügen.`,
  [`Rate limits are only applied to enforced APIs.`]: `Ratenbegrenzungen werden nur auf erzwungene APIs angewendet.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Fügen Sie Pläne und API-Grenzwerte zu Ihrem Produkt hinzu. Planbegrenzungen gelten nur für erzwungene APIs; nicht erzwungene APIs haben keine Begrenzungen.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Fügen Sie Pläne und Ratenbegrenzungen zu Ihrem Produkt hinzu. Pläne gelten nur für erzwungene APIs; nicht erzwungene APIs haben keine Begrenzungen.`,
  [`Delete plan`]: `Plan löschen`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Wählen Sie einen Ereignisgateway-Service für AsyncAPIs aus. Wählen Sie für alle anderen Typen von APIs entweder das DataPower-API-Gateway oder die mit v5 kompatible Version aus. Um diese Einstellung zu aktualisieren, entfernen Sie zuerst die zugeordneten APIs.`,
  [`Edit API list`]: `API-Liste bearbeiten`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Für erzwungene API(s) in diesem Produkt sind mehrere Gateway-Typen erforderlich`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `Für den erzwungenen API-Typ {apiType} in diesem Produkt ist der Gateway-Typ {gatewayType} erforderlich.`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0-API(s)`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0-API(s)`,
  [`AsyncAPI(s)`]: `AsyncAPI(s)`,
  [`Select the gateway type for this Product`]: `Wählen Sie den Gateway-Typ für dieses Produkt aus`,
  [`Added default plan`]: `Standardplan hinzugefügt`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Veröffentlichen Sie das Produkt im Sandbox-Katalog. Dadurch wird das Produkt der Test-App zugeordnet.`,
  [`cluster`]: `Cluster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Nachricht vom Zurückweiser`,
  [`Vendor extensions feature disabled`]: `Funktion 'Anbietererweiterungen' inaktiviert`,
  [`No configured gateways found`]: `Keine konfigurierten Gateways gefunden`,
  [`No OpenAPI extensions associated with the configured gateway`]: `Dem konfigurierten Gateway sind keine OpenAPI-Erweiterungen zugeordnet`,
  [`Extension`]: `Erweiterung`,
  [`Select the extensions to add to the API document`]: `Wählen Sie die Erweiterungen aus, die zum API-Dokument hinzugefügt werden sollen`,
  [`(Operations: {operations})`]: `(Operationen: {operations})`,
  [`Failed to get application`]: `Anwendung konnte nicht abgerufen werden`,
  [`Failed to get credentials for application`]: `Berechtigungsnachweise für Anwendung konnten nicht abgerufen werden`,
  [`Failed to get published product`]: `Fehler beim Abrufen des veröffentlichten Produkts`,
  [`Failed to get the published API`]: `Fehler beim Abrufen der veröffentlichten API`,
  [`API must be activated to use the Test tool`]: `API muss aktiviert sein, damit das Testtool verwendet werden kann`,
  [`Import from Asset repository`]: `Aus Asset-Repository importieren`,
  [`Untitled {componentLabel}`]: `{componentLabel} ohne Titel`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Für Ihre Lizenzmessdaten ist ein Analyseservice erforderlich, um die Nutzung zu verfolgen. Klicken Sie auf "Service registrieren", um einen Analyseservice zu konfigurieren.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Für alle DataPower-Gateway-Services ist ein Analyseservice erforderlich, der die Verwendung überwacht Klicken Sie für jedes Gateway auf "Analyseservice zuordnen", um die Lizenzbedingungen einzuhalten.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Katalog ist inkompatibel mit dem Gateway-Typ, der in der API oder dem Produkt angegeben ist`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `Platz ist Inkompatibilität mit dem in der API oder dem Produkt angegebenen Gateway-Typ`,
  [`Preserve Subscription`]: `Subskription beibehalten`,
  [`Publish validation`]: `Validierung veröffentlichen`,
  [`Publish validations`]: `Validierungen veröffentlichen`,
  [`Edit publish validations`]: `Veröffentlichungsvalidierungen bearbeiten`,
  [`The following validations will be performed when publishing a product`]: `Die folgenden Validierungen werden beim Veröffentlichen eines Produkts ausgeführt.`,
  [`There are no publish validations enabled`]: `Es sind keine Veröffentlichungsvalidierungen aktiviert.`,
  [`Publish validations have been updated`]: `Veröffentlichungsvalidierungen wurden aktualisiert`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Geben Sie die Zertifizierungsstelle (CA) an, die der Ereignisgateway-Service verwendet, um dem Back-End-Serverzertifikat von Kafka zu vertrauen. Wenn der Server ein Zertifikat verwendet, das von einer bekannten Behörde ausgegeben wurde, müssen Sie die Zertifizierungsstelle nicht angeben.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `Der für die Bootstrap-Server eingegebene Wert ist nicht gültig. Geben Sie den Host und den Port jedes Kafka -Brokers in Ihrem Cluster ein, durch Kommas getrennt. Zum Beispiel: broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `OpenAPI-Erweiterung hochladen`,
  [`Add OpenAPI Extension`]: `OpenAPI-Erweiterung hinzufügen`,
  [`Gateway Endpoint`]: `Gateway-Endpunkt`,
  [`API Dashboard`]: `API-Dashboard`,
  [`API call data`]: `API-Aufrufdaten`,
  [`Product Dashboard`]: `Produktdashboard`,
  [`API Product consumption metrics`]: `API-Produktnutzungsmetriken`,
  [`Monitoring Latency Dashboard`]: `Dashboard "Überwachungslatenz"`,
  [`Data about the time taken to serve API calls`]: `Daten zur Zeit, die zum Bedienen von API-Aufrufen benötigt wurde`,
  [`Monitoring Status Dashboard`]: `Statusdashboard überwachen`,
  [`API Error and success information`]: `Informationen zu API-Fehlern und -Erfolg`,
  [`Usage Dashboard`]: `Nutzungsdashboard`,
  [`Usage for gateway {gateway}`]: `Syntax für Gateway {gateway}`,
  [`Latency for gateway {gateway}`]: `Latenz für Gateway {gateway}`,
  [`Most popular products, APIs and applications`]: `Die beliebtesten Produkte, APIs und Anwendungen`,
  [`Consumption Dashboard`]: `Konsumierungsdashboard`,
  [`Total API volume useful for auditing and compliance`]: `Gesamtes API-Volumen, das für die Prüfung und Konformität nützlich ist`,
  [`Monthly Summary by Status Code`]: `Monatsübersicht nach Statuscode`,
  [`Monthly Summary Data by Status Code`]: `Monatsübersichtsdaten nach Statuscode`,
  [`Daily Summary by Status Code`]: `Tagesübersicht nach Statuscode`,
  [`Daily Summary Data by Status Code`]: `Tagesübersichtsdaten nach Statuscode`,
  [`Response time (ms)`]: `Antwortzeit (ms)`,
  [`Datetime`]: `Datum/Uhrzeit`,
  [`Transaction ID:`]: `Transaktions-ID:`,
  [`Other`]: `Sonstige`,
  [`Date time`]: `Datum/Uhrzeit`,
  [`Total calls`]: `Gesamtzahl der Aufrufe`,
  [`Date`]: `Datum`,
  [`Month`]: `Monat`,
  [`Minimum response time`]: `Minimale Antwortzeit`,
  [`Maximum response time`]: `Maximale Antwortzeit`,
  [`Average response time`]: `Durchschnittliche Antwortzeit`,
  [`Total API calls`]: `Gesamte API-Aufrufe`,
  [`Total APIs`]: `APIs insgesamt`,
  [`APIs called`]: `Aufgerufene APIs`,
  [`Total products`]: `Produkte insgesamt`,
  [`products called`]: `Aufgerufene Produkte`,
  [`Total errors`]: `Gesamtanzahl der Fehler`,
  [`Status codes (detailed)`]: `Statuscodes (detailliert)`,
  [`Response time percentiles`]: `Antwortzeitperzentile`,
  [`API calls per day`]: `API-Aufrufe pro Tag`,
  [`Time zone:`]: `Zeitzone:`,
  [`Time zone`]: `Zeitzone`,
  [`Toggle axes`]: `Achsen wechseln`,
  [`Datetime:`]: `Datum/Uhrzeit:`,
  [`datetime per 30 seconds`]: `Datum/Uhrzeit pro 30 Sekunden`,
  [`Datetime (local time)`]: `Datum/Uhrzeit (Ortszeit)`,
  [`Data usage (bytes)`]: `Datennutzung (Bytes)`,
  [`Time to Serve Request`]: `Zeit für Serveranforderung`,
  [`API name`]: `API-Name`,
  [`Product name`]: `Produktname`,
  [`Data usage (bytes received)`]: `Datennutzung (empfangene Byte)`,
  [`Data usage (bytes sent)`]: `Datennutzung (gesendete Byte)`,
  [`Response times (>1s)`]: `Antwortzeiten (>1s)`,
  [`milliseconds`]: `Millisekunden`,
  [`errors`]: `Fehler`,
  [`Success Rate`]: `Erfolgsquote`,
  [`Errors Data`]: `Fehlerdaten`,
  [`Success Data`]: `Erfolgsdaten`,
  [`Total consumer organizations`]: `Gesamtanzahl Konsumentenorganisationen`,
  [`making API calls`]: `Durchführung von API-Aufrufen`,
  [`Applications per plan`]: `Anwendungen pro Plan`,
  [`Top products by calls`]: `Häufigste Produkte nach Aufrufen`,
  [`Top APIs by calls`]: `Häufigste APIs nach Aufrufen`,
  [`Top client IP addresses by calls`]: `Häufigste Client-IP-Adressen nach Aufrufen`,
  [`Client IP address`]: `Client-IP-Adresse`,
  [`Top APIs by request size`]: `Häufigste APIs nach Anforderungsgröße`,
  [`Top APIs by response size`]: `Häufigste APIs nach Antwortgröße`,
  [`Top APIs by response time`]: `Häufigste APIs nach Antwortzeit`,
  [`Top applications by calls`]: `Häufigste Anwendungen nach Aufrufen`,
  [`Dashboards`]: `Dashboards`,
  [`Discover`]: `Erkennen`,
  [`UTC`]: `UTC-Zeit`,
  [`Local time`]: `Ortszeit`,
  [`URI`]: `URI`,
  [`Response code`]: `Antwortcode`,
  [`Total time elapsed (ms)`]: `Insgesamt abgelaufene Zeit (Millisekunden)`,
  [`Timestamp`]: `Zeitmarke`,
  [`Method`]: `Methode`,
  [`Gateway IP`]: `Gateway-IP`,
  [`Gateway Operations Dashboard`]: `Dashboard 'Gateway-Operationen'`,
  [`Usage and latency information per gateway`]: `Nutzungs- und Latenzinformationen pro Gateway`,
  [`Transaction ID`]: `Transaktions-ID`,
  [`Filters`]: `Filter`,
  [`Fields`]: `Felder`,
  [`Clear all`]: `Alle löschen`,
  [`API Event`]: `API-Ereignis`,
  [`API Assembly Policy Latencies`]: `API-Assembly-Richtlinienlatenzen`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `Das folgende Diagramm zeigt die zunehmende Latenzzeit in Millisekunden, während die Anforderung die API-Assembly-Richtlinien durchläuft. Dies kann hilfreich sein, um langsame Punkte oder Engpässe in der API-Assembly zu ermitteln.`,
  [`Collapse`]: `Ausblenden`,
  [`Expand`]: `Einblenden`,
  [`enter name`]: `Name eingeben`,
  [`Enter value`]: `Wert eingeben`,
  [`Time range`]: `Zeitbereich`,
  [`Select one of the following time range filters`]: `Einen der folgenden Zeitbereichsfilter auswählen`,
  [`Last 1 hour`]: `Letzte Stunde`,
  [`Last {number} minutes`]: `Letzte {number} Minuten`,
  [`Last {number} hours`]: `Letzte {number} Stunden`,
  [`Last {number} days`]: `Letzte {number} Tage`,
  [`Start date`]: `Startdatum`,
  [`End date`]: `Enddatum`,
  [`Select from the following dropdowns and enter a value`]: `Aus den folgenden Dropdown-Listen auswählen und einen Wert eingeben`,
  [`Select one of the following type filters`]: `Einen der folgenden Typfilter auswählen`,
  [`All events`]: `Alle Ereignisse`,
  [`Success only (2xx, 3xx)`]: `Nur Erfolg (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Nur Fehler (4xx, 5xx)`,
  [`Import/Export`]: `Importieren/Exportieren`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Etwa {totalItems} Ergebnisse ({searchTime} Sekunden) um {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Etwa {totalItems} Ergebnisse ({searchTime} Sekunden) um {time} ({utcTime})`,
  [`API calls`]: `API calls (API-Aufrufe)`,
  [`Export as CSV`]: `Als CSV exportieren`,
  [`Export as JSON`]: `Als JSON exportieren`,
  [`Export as PNG`]: `Als PNG exportieren`,
  [`Export as JPG`]: `Als JPG exportieren`,
  [`Edit filter`]: `Filter bearbeiten`,
  [`Edit query`]: `Abfrage bearbeiten`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Dies ist eine gemeinsam genutzte Abfrage. Alle Änderungen werden für alle Benutzer vorgenommen, mit denen diese Abfrage gemeinsam genutzt wird.`,
  [`This is a shared query. This will delete it for all users.`]: `Dies ist eine gemeinsam genutzte Abfrage. Dadurch wird es für alle Benutzer gelöscht.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname} wurde für alle Benutzer in der Anbieterorganisation {org} aktualisiert`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname} wurde für alle Benutzer im Bereich {space} aktualisiert`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname} wurde für alle Benutzer im Katalog {catalog} aktualisiert`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname} wurde für alle Benutzer in Cloud-Manager aktualisiert`,
  [`Stop sharing`]: `Gemeinsame Nutzung beenden`,
  [`Share query`]: `Abfrage gemeinsam nutzen`,
  [`Share filter`]: `Filter gemeinsam nutzen`,
  [`You do not have permissions to delete this filter`]: `Sie sind nicht dazu berechtigt, diesen Filter zu löschen`,
  [`You do not have permissions to delete this query`]: `Sie haben keine Berechtigungen zum Löschen dieser Abfrage`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Möchten Sie die Abfrage {queryTitle} im Bereich der Anbieterorganisation {org} wirklich gemeinsam nutzen?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Möchten Sie die Abfrage {queryTitle} im Bereich {space} wirklich gemeinsam nutzen?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Möchten Sie die Abfrage {queryTitle} im Katalogbereich {catalog} wirklich gemeinsam nutzen?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Möchten Sie die Abfrage {queryTitle} im Cloudbereich wirklich gemeinsam nutzen?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Möchten Sie die gemeinsame Nutzung der Abfrage {queryTitle} wirklich beenden?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Möchten Sie die gemeinsame Nutzung der Abfrage {queryTitle} im Bereich der Anbieterorganisation {org} wirklich beenden?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Möchten Sie die gemeinsame Nutzung der Abfrage {queryTitle} im Bereich {space} wirklich beenden?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Möchten Sie die gemeinsame Nutzung der Abfrage {queryTitle} im Katalogbereich {catalog} wirklich beenden?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Möchten Sie die gemeinsame Nutzung der Abfrage {queryTitle} im Cloudbereich wirklich beenden?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Möchten Sie die Abfrage {queryTitle} wirklich löschen?`,
  [`At least one query parameter expected`]: `Mindestens ein Abfrageparameter erwartet`,
  [`A provided query parameter is incomplete`]: `Ein angegebener Abfrageparameter ist unvollständig`,
  [`Could not parse query string`]: `Abfragezeichenfolge konnte nicht geparst werden`,
  [`An unsupported query parameter was provided: {query}`]: `Es wurde ein nicht unterstützter Abfrageparameter angegeben: {query}`,
  [`Invalid timeframe value: {value}`]: `Ungültiger Wert für Zeitrahmen: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `Der Wert von {field} muss der Datums-/Uhrzeitnotation entsprechen, wie in RFC 3339, Abschnitt 5.6 definiert. Beispiele: 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `Operator {operator} wird für Feld {field} nicht unterstützt`,
  [`The value of {field} must be a number`]: `Der Wert von {field} muss eine Zahl sein`,
  [`Delete query`]: `Abfrage löschen`,
  [`Delete filter`]: `Filter löschen`,
  [`Save query`]: `Abfrage speichern`,
  [`Save filter`]: `Filter speichern`,
  [`Save filter as`]: `Filter speichern unter`,
  [`Save query as`]: `Abfrage speichern unter`,
  [`Save as...`]: `Speichern unter ...`,
  [`Save as`]: `Speichern unter`,
  [`Paste query string to load the query`]: `Abfragezeichenfolge zum Laden der Abfrage einfügen`,
  [`Copy or paste filter string to load filters`]: `Filterzeichenfolge zum Laden von Filtern kopieren oder einfügen`,
  [`Query string`]: `Abfragezeichenfolge`,
  [`View filter`]: `Filter anzeigen`,
  [`View filter string`]: `Filterzeichenfolge anzeigen`,
  [`Date (local time)`]: `Datum (Ortszeit)`,
  [`Coming soon, a new built in analytics feature`]: `In Kürze wird eine neue integrierte Analysefunktion`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `Mithilfe von Analysen können Sie Einblicke in Ihre API-Laufzeit gewinnen, Service-Levels verwalten, Quoten festlegen, Kontrollmechanismen einrichten, Sicherheitsrichtlinien definieren, Communitys verwalten und Trends analysieren.`,
  [`Documentation`]: `Dokumentation`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Machen Sie sich im Detail mit den einzelnen Typen von API-Laufzeitdaten vertraut und lernen Sie, wie Sie s3-Buckets von AWS zu ihrer Visualisierung einsetzen.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Erfahren Sie, welche Daten heute verfügbar sind und wie Sie darauf zugreifen.`,
  [`Next steps`]: `Nächste Schritte`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `Der ausgewählte Secrets Manager-Service verwaltet keine Zertifikate.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Ein Zertifikat ist erforderlich, um die Verbindung zwischen API Connect und der Domäne des Gateway-Managementendpunkts zu sichern. Zertifikate sind auch erforderlich, um die Verbindungen zwischen dem Gateway und den Domänen, die es verarbeitet, zu sichern. Alle Zertifikate müssen in einem Secrets Manager-Service gespeichert werden. Der Service stellt ein sicheres Repository für die Zertifikate bereit und hilft, Ausfälle zu verhindern, indem er Ihnen bei der Integration mit dem Event Notifications-Service Benachrichtigungen sendet, wenn die Zertifikate in Kürze ablaufen. Um zu starten,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Zertifikate sind erforderlich, um die Verbindungen zwischen dem Gateway und den Domänen, die es verarbeitet, zu sichern. Alle Zertifikate müssen in einem Secrets Manager-Service gespeichert werden. Der Service stellt ein sicheres Repository für die Zertifikate bereit und hilft, Ausfälle zu verhindern, indem er Ihnen bei der Integration mit dem Event Notifications-Service Benachrichtigungen sendet, wenn die Zertifikate in Kürze ablaufen. Um zu starten,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Wenn Sie Secrets Manager ändern, werden alle vorhandenen Zuweisungen von Zertifikaten und CA-Bundles entfernt.`,
  [`Create a [Secrets Manager]({link}) service`]: `[Secrets Manager]({link})-Service erstellen`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Importieren Sie die erforderlichen Zertifikate und CA-Bundles in den Secrets Manager-Service.`,
  [`No Secrets Manager services available`]: `Keine Secrets Manager-Services verfügbar`,
  [`Select Secrets Manager service`]: `Secrets Manager-Service auswählen`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Wählen Sie den [Secrets Manager]({link})-Service aus, der die Zertifikate für dieses Gateway verwaltet.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Der Secrets Manager-Service muss die Zertifikate für die Domäne des Gateway-Management-Endpunkts und die vom Gateway verwalteten Domänen verwalten. Außerdem müssen mit IBM Cloud IAM dem Reserved Instance-Service von API Connect die Berechtigung für den Zugriff auf den Secrets Manager-Service erteilen.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Wählen Sie dann das Zertifikat für die Domäne des Managementendpunkts aus. Wählen Sie außerdem das entsprechende CA-Bundle aus, wenn das Zertifikat nicht von einer gängigen Zertifizierungsstelle zugewiesen wurde. Das Zertifikat und das Bundle müssen vom Secrets Manager-Service verwaltet werden.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Geben Sie dann die Domänen an, die von diesem Gateway verarbeitet werden. Die SNI-Technik wird angewendet. Dadurch können mehrere Domänen über dieselbe IP-Adresse und denselben Port bedient werden, ohne dass dasselbe Zertifikat erforderlich ist. Das Platzhalterformat wird unterstützt. Die (catch-all)-Standarddomäne '*' muss als letzter Eintrag in der Tabelle enthalten sein. Geben Sie bei Bedarf weitere Domänennamen ein und geben Sie dabei jeweils ein vom Secrets Manager-Service verwaltetes Zertifikat an.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Erteilen Sie dem Reserved Instance-Service von API Connect mithilfe von [IBM Cloud IAM]({link}) die Berechtigung für den Zugriff auf den Secrets Manager-Service.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Aktualisieren Sie vorhandene Zuordnungen von Zertifikaten und CA-Bundles, indem Sie sie aus Secrets Manager laden.`,
  [`Access s3 data`]: `Auf s3-Daten zugreifen`,
  [`Create resource`]: `Ressource erstellen`,
  [`Connect analytics data in s3 bucket with AWS`]: `Analysedaten im s3-Bucket mit AWS verbinden`,
  [`AWS account`]: `AWS-Konto`,
  [`AWS account number`]: `Nummer des AWS-Kontos`,
  [`Enter your 12 digit aws account id`]: `Geben Sie Ihre 12-stellige ID für das AWS-Konto ein`,
  [`Account id must be 12 digits`]: `Die Konto-ID muss aus 12 Ziffern bestehen`,
  [`Failed to update AWS account id.`]: `Die AWS-Konto-ID konnte nicht aktualisiert werden.`,
  [`You have limited access to the catalogs.`]: `Sie haben eingeschränkten Zugriff auf die Kataloge.`,
  [`You have limited access to the spaces.`]: `Sie haben eingeschränkten Zugriff auf die Bereiche.`,
  [`Contact admin to get more access.`]: `Wenden Sie sich an den Administrator, um mehr Zugriff zu erhalten.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `Sie können diesen Katalog nicht auswählen. Wenden Sie sich an einen Administrator, wenn Sie Zugriff benötigen.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `Sie können diesen Bereich nicht auswählen. Wenden Sie sich an einen Administrator, wenn Sie Zugriff benötigen.`,
  [`You have limited access to the product billing.`]: `Sie haben eingeschränkten Zugriff auf die Rechnungsstellung für das Produkt.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `Die AWS-Konto-ID wurde erfolgreich aktualisiert. Die Erteilung des Zugriffs auf S3-Daten kann einige Sekunden in Anspruch nehmen.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Im ausgewählten Katalog fehlt eine Organisation oder Gruppe.`,
  [`Secrets Manager instance`]: `Secrets Manager-Instanz`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Konsumentenorganisationen mit Eignern ohne E-Mail können nicht ausgewählt werden`,
  [`You currently don’t have any data.`]: `Sie haben momentan keine Daten.`,
  [`You currently don’t have any organizations.`]: `Sie haben derzeit keine Organisationen.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Anforderung zum Abonnieren der App {appname} zur Planung von {plan} im Produkt {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Anforderung an Produkt {operation}: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Anforderung zum Upgrade der Anwendung {appname} auf die Produktion`,
  [`Onboarding request for {email}`]: `Onboarding-Anforderung für {email}`,
  [`Product lifecycle request`]: `Anforderung für Produktlebenszyklus`,
  [`Subscription request`]: `Abonnementanforderung`,
  [`Application lifecycle request`]: `Anforderung für Anwendungslebenszyklus`,
  [`Consumer Onboarding request`]: `Anforderung für Kunden-Onboarding`,
  [`Originator`]: `Veranlasser`,
  [`Edit analytics service advanced settings`]: `Erweiterte Einstellungen für Analyseservice bearbeiten`,
  [`Advanced settings`]: `Erweiterte Einstellungen`,
  [`Must be a positive integer`]: `Muss eine positive Ganzzahl sein`,
  [`Maximum value is {maxValue}`]: `Maximalwert ist {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `Der Maximalwert ist {maxValueDays}d oder {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Konfigurieren Sie erweiterte Einstellungen für die Analyseimplementierung.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `Wert muss mit 'd' für Tage oder' h ' für Stunden enden. Beispiel: 30d oder 12h.`,
  [`Rollover settings`]: `Rollover-Einstellungen`,
  [`Retention settings`]: `Aufbewahrungseinstellungen`,
  [`Delete an index when it meets the following condition:`]: `Einen Index löschen, wenn er die folgende Bedingung erfüllt:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Den Schreibaliasnamen in einen neuen Index übertragen, wenn der verwaltete Index eine der folgenden Bedingungen erfüllt:`,
  [`Minimum document count`]: `Mindestanzahl Dokumente`,
  [`The minimum number of documents required to roll over the index.`]: `Die erforderliche Mindestanzahl von Dokumenten für die Indexrotation.`,
  [`Minimum index age`]: `Mindestalter des Index`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Das erforderliche Mindestalter des Index für die Indexrotation. Das Indexalter ist die Zeit zwischen der Indexerstellung und jetzt. Verwenden Sie 'd' für Tage und 'h' für Stunden. Beispiel: 30d bedeutet, dass der Index übertragen wird, sobald sein Alter 30 Tage erreicht, während 96h bedeutet, dass der Index übertragen wird, sobald sein Alter 96 Stunden erreicht. Die Maximalwerte sind {maxValueDays}d oder {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Das erforderliche Mindestalter des Index, damit der Index gelöscht wird. Das Indexalter ist die Zeit zwischen der Indexerstellung und jetzt. Verwenden Sie 'd' für Tage und 'h' für Stunden. Beispiel: 30d bedeutet, dass der Index gelöscht wird, wenn sein Alter 30 Tage erreicht, während 96h bedeutet, dass der Index gelöscht wird, sobald sein Alter 96 Stunden erreicht. Die Maximalwerte sind {maxValueDays}d oder {maxValueHours}h.`,
  [`Time range:`]: `Zeitbereich:`,
  [`Time range: Before {datetime}`]: `Zeitbereich: Vor {datetime}`,
  [`Time range: After {datetime}`]: `Zeitbereich: Nach {datetime}`,
  [`Filter before`]: `Filter vor`,
  [`Filter after`]: `Filter nach`,
  [`Filter exact`]: `Filter genau`,
  [`Filter gt`]: `Filter >`,
  [`Filter lt`]: `Filter <`,
  [`Filter equals`]: `Filter =`,
  [`Filter by`]: `Filtern nach`,
  [`Filter out`]: `Herausfiltern`,
  [`Delete failed`]: `Löschen fehlgeschlagen`,
  [`Delete warning`]: `Löschwarnung`,
  [`Could not delete saved query {queryTitle}.`]: `Die gespeicherte Abfrage {queryTitle} konnte nicht gelöscht werden.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `Die Anforderung wurde erfolgreich beantwortet, aber das Löschen von {queryTitle} ist noch nicht abgeschlossen.`,
  [`API governance`]: `API-Governance`,
  [`Something's wrong`]: `Etwas stimmt nicht`,
  [`We were unable to connect to the service.`]: `Die Verbindung zum Service konnte nicht hergestellt werden.`,
  [`Invalid date`]: `Ungültiges Datum`,
  [`Invalid time`]: `Ungültige Zeit`,
  [`End date must be after the start date`]: `Enddatum muss nach dem Startdatum liegen`,
  [`Duplicate`]: `Duplizieren`,
  [`Duplicate query`]: `Doppelte Abfrage`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Möchten Sie wirklich ein Duplikat von {queryTitle} erstellen?`,
  [`No queries saved`]: `Keine Abfragen gespeichert`,
  [`No queries shared`]: `Keine Abfragen gemeinsam genutzt`,
  [`To save a query, start by creating one then save.`]: `Um eine Abfrage zu speichern, erstellen Sie zunächst eine Abfrage und speichern Sie sie anschließend.`,
  [`Share a saved query and allow others to use it.`]: `Sie können eine gespeicherte Abfrage gemeinsam nutzen und anderen Benutzern die Verwendung ermöglichen.`,
  [`Query updated`]: `Abfrage aktualisiert`,
  [`Query update failed`]: `Abfrageaktualisierung fehlgeschlagen`,
  [`Share successful`]: `Freigabe erfolgreich`,
  [`Share failed`]: `Freigabe fehlgeschlagen`,
  [`Delete successful`]: `Löschen erfolgreich`,
  [`Query unshared`]: `Freigabe der Abfrage aufgehoben`,
  [`Unshare {queryTitle} failed`]: `Aufheben der Freigabe von {queryTitle} fehlgeschlagen`,
  [`Query copied`]: `Abfrage kopiert`,
  [`Duplicate {queryTitle} failed`]: `Duplizieren von {queryTitle} fehlgeschlagen`,
  [`The query {queryTitle} has been updated.`]: `Die Abfrage {queryTitle} wurde aktualisiert.`,
  [`The query {queryTitle} has been shared.`]: `Die Abfrage {queryTitle} wurde gemeinsam genutzt.`,
  [`The query {queryTitle} has been deleted.`]: `Die Abfrage {queryTitle} wurde gelöscht.`,
  [`The query {queryTitle} is no longer shared.`]: `Die Abfrage {queryTitle} wird nicht mehr gemeinsam genutzt.`,
  [`A copy of {queryTitle} has been created.`]: `Eine Kopie von {queryTitle} wurde erstellt.`,
  [`About queries`]: `Informationen zu Abfragen`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Abfragen sind ein Mittel zur Optimierung der Analysedaten, die in den Dashboards und in der Erkennungsansicht verwendet werden. Wenn eine Abfrage angewendet wird, werden alle Dashboards aktualisiert, sodass sie nur die ausgewählten Daten enthalten.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `Mit der Option "Benutzerdefiniert" können Sie einen der vordefinierten Zeitbereiche oder einen bestimmten Zeitbereich bis zur nächsten Sekunde verwenden.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Felder können verwendet werden, um anzugeben, dass nur bestimmte Daten eingeschlossen werden sollen. Es ist möglich, nach fast jedem Attribut eines API-Ereignisses zu filtern.`,
  [`There are then different operators available depending on the field type.`]: `Je nach Feldtyp sind dann verschiedene Operatoren verfügbar.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Zeichenfolgefelder lassen "gleich", "nicht", "beginnt mit", "endet mit" und "regex" zu.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `Es ist möglich, contains und not contains zu verwenden, um eine Liste von Werten für ein einzelnes Feld bereitzustellen (z. B. eine Liste von API-Versionen).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP-Adressfelder lassen Gleichheitszeichen und keine Operatoren zu. Sie ermöglichen auch die Angabe des Werts in CIDR-Notation, um die Verwendung von Netzmasken zur Auswahl bestimmter Netzwerke zu ermöglichen.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Reguläre Ausdrücke können sehr leistungsfähig sein, um bestimmte Werte auszuwählen, sie werden jedoch langsamer ausgeführt, sodass empfohlen wird, wenn möglich andere Operatoren wie 'beginnt mit' und 'endet mit ' zu verwenden.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Abfragefilter für verschiedene Felder werden mit dem Operator für logisches UND kombiniert. Dies bedeutet, dass das Hinzufügen von zwei Filtern für denselben Feldnamen nur Ergebnisse zurückgibt, die beide Filter erfüllen.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `Der Abfrageparameter {type} ist eine einfache Möglichkeit, nur Erfolgs- oder Fehlerereignisse anzugeben. Verwendet das Feld {statusCode}. Werden nur bestimmte Statuscodes benötigt, kann ein Feldfilter verwendet werden. Der Wert für Felder {statusCodeTwo} ist normalerweise eine vollständige Zeichenfolge wie {okStatus} und nicht nur der numerische HTTP-Rückgabecode.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `Es ist möglich, Abfragen zur späteren Wiederverwendung zu speichern und sie auch mit Ihrer aktuellen Bereichsebene gemeinsam zu nutzen, damit sie auch von anderen verwendet werden können - z. B. Anbieterorganisation oder Katalog.`,
  [`n/a`]: `nicht zutreffend`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Hinweis: Die Felder 'Anbieterorganisations-ID' und 'Anbieterorganisationsname 'werden in den Ereignisnutzdaten als {orgId} bzw. {orgName} angezeigt. Die Felder'Verbraucherorganisations-ID 'und 'Verbraucherorganisationsname' werden als {developerOrgId} und {developerOrgName} angezeigt.`,
  [`No authentication token`]: `Kein Authentifizierungstoken`,
  [`Contact your administrator for further assistance.`]: `Wenden Sie sich für weitere Unterstützung an Ihren Administrator.`
};
