// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `尚未更新型錄`,
  [`Catalog has been updated`]: `已更新型錄`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `草稿產品 {draftProduct} 必須參照一或多個 API`,
  [`Number of Calls`]: `呼叫次數`,
  [`Changes will apply to newly published APIs only`]: `變更將僅套用至新發佈的 API`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `存在不相容的 {label}，因為其閘道類型與此 API 不同。`,
  [`{label} is required`]: `{label} 是必要項`,
  [`Application is required`]: `應用程式是必要項`,
  [`Plan is required`]: `方案是必要項`,
  [`Gateway is required`]: `閘道是必要項`,
  [`Consumer Org is required`]: `消費者組織是必要項`,
  [`LDAP ATTRIBUTE NAME`]: `LDAP 屬性名稱`,
  [`Use the build in Test Application (sandbox only)`]: `使用內建測試應用程式（僅限沙箱）`,
  [`Select an Application`]: `選取應用程式`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `使用內建測試應用程式（僅限沙箱）- 在選擇非沙箱型錄時停用`,
  [`Choose an existing Application`]: `選擇現有應用程式`,
  [`Use JWT for gateway authentication to analytics service`]: `使用 JWT 對分析服務進行閘道鑑別`,
  [`Select an application to consume the API`]: `選取要耗用 API 的應用程式`,
  [`Use the default built-in Sandbox Catalog'`]: `使用預設的內建沙箱型錄`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `附註：此型錄將用作所有其他喜好設定的範圍。變更此設定將需要調整其他喜好設定。`,
  [`Select a Consumer Org`]: `選取消費者組織`,
  [`Select the Consumer Org your generated application will belong to`]: `選取產生的應用程式將所屬的消費者組織`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `附註：只能在使用內建沙箱測試應用程式時變更此選項，因為現有應用程式已經屬於消費者組織`,
  [`Generate auto product`]: `產生自動產品`,
  [`Select a product to associate the current API with`]: `選取要與現行 API 相關聯的產品`,
  [`Associate the current API to a selected product`]: `將現行 API 與選取的產品相關聯`,
  [`API management`]: `API 管理`,
  [`Select the API management instance you would like this API to be published to.`]: `選取您想要將此 API 發佈至其中的 API Management 實例。`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `附註：此實例將用作所有其他喜好設定的範圍。 變更此設定將需要調整其他喜好設定。`,
  [`Provider organization`]: `提供者組織`,
  [`Select the provider organization you would like this API to be published to.`]: `選取您想要將此 API 發佈至其中的提供者組織。`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `附註：此組織將用作所有其他喜好設定的範圍。 變更此設定將需要調整其他喜好設定。`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `附註：此空間將用作所有其他喜好設定的範圍。 變更此設定將需要調整其他喜好設定。`,
  [`Selected a draft product`]: `選取的草稿產品`,
  [`Use any available gateway service`]: `使用任何可用的閘道服務`,
  [`Select a compatible gateway service`]: `選取相容的閘道服務`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `附註：此閘道服務將用作所有其他喜好設定的範圍。將根據此設定載入原則及其他閘道服務特定的內容。`,
  [`Select a gateway`]: `選取閘道`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `「附註：此選項在選取「現有產品」選項時會停用，且將針對產生的產品改為使用「預設方案」`,
  [`Select a Plan`]: `選取方案`,
  [`Generate subscription`]: `產生訂閱`,
  [`A subscription will be created between the selected Product plan and Application`]: `將在選取的產品方案與應用程式之間建立訂閱`,
  [`Apply a rate limit to the generated product`]: `將速率限制套用至產生的產品`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `將速率限制套用至所產生產品的「預設方案」。此選項在選取「現有產品」選項時會停用，並改為使用選定方案中所定義的速率限制`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `「輕量型目錄存取通訊協定 (LDAP)」是一種網際網路通訊協定，用於透過網路存取及維護分散式目錄資訊服務。 如果已啟用企業 LDAP 服務，請將其配置成資源以提供使用者鑑別。 如果您希望也為新的 Developer Portal 使用者提供使用者登錄，則必須完成「屬性對映」區段才能啟用可寫入的 LDAP。 在這個區段中，選取「使用者管理」勾選框，並提供來源 LDAP 屬性名稱到目標 API Connect 值的對映。`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `啟用作業自行核准容許作業由其發起者及合作者核准。此選項在其他核准者沒有空時很便利，但實際上容許略過其他使用者進行的檢查。`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `計費整合將 API Connect 連接至第三方訂閱計費系統中的帳戶，該系統管理客戶、其付款方法、發票開立及現行有效的訂閱。`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `原生 OAuth 提供者物件提供用於 OAuth 處理作業（例如產生及驗證 OAuth 記號）的設定。 OAuth 安全定義可以參照 OAuth 提供者物件以保護 API。 使用原生 OAuth 提供者時，API Connect 會以原生方式執行 OAuth 作業。 每個 OAuth 提供者物件都具有一個支援 API。 您在這裡的配置會自動更新 API 的 Swagger 文件。 可以導覽至「API 編輯器」頁面來編輯 Swagger 文件。 如果已發佈的 API 參照 OAuth 提供者物件，則支援 API 會自動在閘道中變為可用。`,
  [`About token management`]: `關於記號管理`,
  [`About user security`]: `關於使用者安全`,
  [`APIs or products`]: `API 或產品`,
  [`ATM is not deployed, please contact your administrator`]: `ATM 未部署，請聯絡管理者`,
  [`Application state change approvals will be enabled`]: `將啟用應用程式狀態變更核准`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `定義用來擷取應用程式使用者認證、鑑別應用程式使用者身分及授與權限的設定。`,
  [`See console for details.`]: `如需詳細資料，請參閱主控台。`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `選取的「憑證管理程式」服務不會管理任何憑證。`,
  [`The selected product is not published to the Sandbox catalog`]: `選取的產品未發佈至沙箱型錄`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `選取的產品未發佈至沙箱型錄，因此無法訂閱應用程式`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `用於您平台的 IBM Cloud CLI（如果先前未安裝）。`,
  [`(Only supported by TLS 1.3)`]: `（只有 TLS 1.3 才支援）`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `此欄位上的 "@listSize" 指引用來定義不會傳回清單的調整大小欄位。`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `此欄位上的 "@listSize" 指引用來定義未定義在欄位之傳回類型上的調整大小欄位。`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `此欄位上的 "@listSize" 指引用來定義未定義在欄位上的切割引數。`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `此欄位上的 "@listSize" 指引用來定義其類型不是 "Int" 或 "Int!" 的切割引數.`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `此欄位上的 "@listSize" 指引用來定義多個非空值的切割引數。`,
  [`"{name}" (Role) has been created`]: `已建立 "{name}"（角色）。`,
  [`"{name}" (Role) has been updated`]: `已更新 "{name}"（角色）。`,
  [`API ({api}) has been created.`]: `已建立 API ({api})。`,
  [`API ({api}) has been renamed.`]: `API ({api}) 已重新命名。`,
  [`API ({api}) has been updated.`]: `已更新 API ({api})。`,
  [`API ({api}) has been deleted.`]: `已刪除 API ({api})。`,
  [`API ({api}) has been published.`]: `已發佈 API ({api})。`,
  [`API ({api}) has validation error.`]: `API ({api}) 有驗證錯誤。`,
  [`API ({api}) has validation errors.`]: `API ({api}) 有驗證錯誤`,
  [`API version`]: `API 版本`,
  [`APIM data version`]: `APIM 資料版本`,
  [`APIM schema update date`]: `APIM 綱目更新日期`,
  [`APIM schema version`]: `APIM 綱目版本`,
  [`APIM target data version`]: `APIM 目標資料版本`,
  [`APIM target schema version`]: `APIM 目標綱目版本`,
  [`APIM update date`]: `APIM 更新日期`,
  [`Product ({product}) has been created.`]: `已建立產品 ({product})。`,
  [`Product ({product}) has been updated.`]: `已更新產品 ({product})。`,
  [`Product ({product}) has been delete.`]: `已刪除產品 ({product})。`,
  [`Product ({product}) has been published.`]: `已發佈產品 ({product})。`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) 已{changedName}。`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) 尚未{changedName}！`,
  [`({units} {timeSuffix} after being queued)`]: `（排入佇列之後 {units} {timeSuffix}）`,
  [`({units} {timeSuffix} after being sent)`]: `（傳送之後 {units} {timeSuffix}）`,
  [`(none)`]: `（無）`,
  [`(optional)`]: `（選用）`,
  [`*Base endpoint list empty`]: `*基本端點清單空白`,
  [`*Must be a valid url`]: `*必須是有效的 URL`,
  [`*System will also send an email notification to the requester.`]: `*系統也將傳送電子郵件通知給要求者。`,
  [`+ redact from...`]: `+ 編寫自...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. 您還可以在型錄內部管理此產品的生命週期。`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x 後接 64 個十六進位字元`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) 擁有和管理 API 消費者組織 2) 管理應用程式開發者社群 3) 編寫 API 和產品定義 4) 管理 API 產品生命週期`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) 擁有和管理 API 提供者組織 2) 管理應用程式開發者社群 3) 編寫 API 和產品定義 4) 管理 API 產品生命週期`,
  [`5 Most Active APIs`]: `前 5 大熱門 API`,
  [`5 Most Active Products`]: `前 5 大熱門產品`,
  [`5 most active APIs`]: `前 5 大熱門 API`,
  [`5 most active Products`]: `前 5 大熱門產品`,
  [`503 Service Unavailable`]: `503 服務無法使用`,
  [`A request to the server was made without any credentials.`]: `對伺服器發出的要求沒有任何認證。`,
  [`A server error occurred`]: `發生伺服器錯誤`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `以用戶端身分連接至其他系統時，TLS 用戶端設定檔會配置 API Connect 所使用的憑證鏈和密碼組合。`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `將伺服器端點呈現給其他系統時，TLS 伺服器設定檔會配置 API Connect 所使用的憑證鏈和密碼組合。`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `型錄管理發佈後在相關聯 Developer Portal 中可見的 API 產品集合`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `型錄管理發佈後在相關聯 Developer Portal 中可見的 API 產品集合。`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `型錄代表受管理 API 產品的集合。與型錄相關聯的 Developer Portal 上的消費者可以看見所發佈的產品。`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `需要憑證，以保護 API Connect 與閘道管理端點之間的連線安全。此外也需要憑證，以保護閘道與它所處理的網域之間的連線安全。所有憑證必須儲存在「憑證管理程式」服務中。該服務會為憑證提供一個安全儲存庫，並且有助於防止中斷現象，因為它會在憑證快要到期時傳送通知給您。如果要著手進行，`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `未定義對映的輸入時將使用的預設值。`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `閘道服務代表一組代管已發佈 API 並提供用戶端應用程式所使用 API 端點的閘道伺服器或儲存器。閘道執行對後端系統的 API Proxy 呼叫並強制執行包括用戶端識別、安全和速率限制在內的 API 原則。`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `閘道主機會發佈 API，並提供用戶端應用程式使用的 API 端點。閘道會執行對後端系統的 API Proxy 呼叫，並施行包括用戶端識別、安全和速率限制在內的 API 原則。`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `可以在來源產品上設定移轉目標以定義訂閱的移轉目標。移轉目標包含一個方案對映，用於說明來源產品上方案與目標產品上方案之間的對映。移轉目標在 Developer Portal 中是可見的，便於將移轉目標傳達給來源產品的訂閱者。`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `模組化 OAuth 原則套件。它可以在一個原則（預設值）中執行所有 OAuth/OpenID Connect 通訊協定步驟，或者可以分割為多個原則以一次僅執行一個或 "n" 個步驟進行更精細的控制。每個步驟的輸入及輸出由記載的環境定義變數驅動。視需要新增或移除支援的 OAuth 元件。`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `在沒有可用的入口網站服務時，無法建立入口網站。請聯絡雲端管理者以取得支援。`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `提供者組織是擁有 API 及相關聯方案和產品的團隊。提供者組織需要獲指派所有權限的擁有者。擁有者是從成員進行指派。`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `提供者組織包含會管理 API、產品、方案、型錄、消費者組織和其他相關資源的使用者。您可以使用提供者組織，依專案、部門、生命週期階段等，將使用者和 API 管理資源加以分組。如果要管理及指派角色，以便控管使用者專用權，請前往 IBM Cloud 身分與存取管理。`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `解析成輸出值的簡易 JavaScript 表示式。這可能是靜態字串 ("my static string")，或包括任何已對映輸入的表示式 ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `動作`,
  [`ANALYTICS`]: `分析`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `API 管理者`,
  [`API CONNECT VALUE`]: `API Connect 值`,
  [`API assemble`]: `API 組合`,
  [`API base endpoints`]: `API 基本端點`,
  [`API base URL`]: `API 基本 URL`,
  [`API base URLs`]: `API 基本 URL`,
  [`API Calls`]: `API 呼叫`,
  [`API Connect UI`]: `API Connect UI`,
  [`API Connect services`]: `API Connect 服務`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect 可要求電子郵件伺服器將邀請及啟動鏈結傳送至成員，以及用於其他用途。`,
  [`API Connect verify token`]: `API Connect 驗證記號`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `API Designer 授權`,
  [`API Designer credentials`]: `API Designer 認證`,
  [`API Designer licence`]: `API Designer 授權`,
  [`API Designer does not currently support WSDL`]: `API Designer 目前不支援 WSDL`,
  [`API editor`]: `API 編輯器`,
  [`API Endpoint`]: `API 端點`,
  [`API Endpoint Base`]: `基本 API 端點`,
  [`API Endpoint for Unenforced APIs`]: `未強制執行 API 的 API 端點`,
  [`API Endpoint for unenforced APIs`]: `未強制執行 API 的 API 端點`,
  [`API Endpoints`]: `API 端點`,
  [`API Error`]: `API 錯誤`,
  [`API gateway`]: `API Gateway`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API Gateway`,
  [`API Gateways`]: `API Gateway`,
  [`API Invocation Endpoint`]: `API 呼叫端點`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `未發佈 API。將不會啟用瀏覽器中的「API 測試」（試用）。`,
  [`API Key`]: `API 金鑰`,
  [`API key`]: `API 金鑰`,
  [`API key secret`]: `API 金鑰密碼`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `API Manager 本端使用者登錄`,
  [`API Manager URL`]: `API Manager URL`,
  [`API Management`]: `API 管理`,
  [`API Path Parameters`]: `API 路徑參數`,
  [`API Security Definition`]: `API 安全定義`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `將不會啟用瀏覽器中的「API 測試」（試用），因為並未強制施行。`,
  [`API Setup`]: `API 設定`,
  [`API Subscription`]: `API 訂閱`,
  [`API endpoint`]: `API 端點`,
  [`API endpoint base`]: `基本 API 端點`,
  [`API endpoint for unenforced APIs`]: `未強制執行 API 的 API 端點`,
  [`API endpoints`]: `API 端點`,
  [`API error`]: `API 錯誤`,
  [`API invocation endpoint`]: `API 呼叫端點`,
  [`API is not enforced on the API gateway.`]: `API 未在 API Gateway 上強制施行。`,
  [`API path parameters`]: `API 路徑參數`,
  [`API security definition`]: `API 安全定義`,
  [`API setup`]: `API 設定`,
  [`API subscription`]: `API 訂閱`,
  [`API Update`]: `API 更新`,
  [`API user registries`]: `API 使用者登錄`,
  [`API and product generated successfully!`]: `已順利產生 API 和產品！`,
  [`API deletion failed`]: `API 刪除失敗`,
  [`API does not exist!`]: `API 不存在！`,
  [`API from existing REST service`]: `來自現有 REST 服務的 API`,
  [`API from existing SOAP service`]: `來自現有 SOAP 服務的 API`,
  [`API has been updated.`]: `已更新 API。`,
  [`API has been updated`]: `API 已更新`,
  [`API has not been created!`]: `尚未建立 API！`,
  [`API has not been updated!`]: `尚未更新 API！`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `API 優先順序（使用 OpenAPI 定義中定義的虛名端點）`,
  [`API properties`]: `API 內容`,
  [`API protection source`]: `API 保護來源`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `從產品參照的 API 不存在。請先建立或匯入 API。`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `使用 OpenAPI 綱目定義來編寫 API 要求和回應有效負載結構。`,
  [`API subscription request approved for app {{appName}}`]: `已核准對應用程式 {appName} 的 API 訂閱申請`,
  [`API subscription request denied for app {{appName}}`]: `已拒絕對應用程式 {appName} 的 API 訂閱申請`,
  [`API subscription request received for app {{appName}}`]: `已收到對應用程式 {appName} 的 API 訂閱申請`,
  [`API type`]: `API 類型`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `'apis' 內容下索引鍵為 ''{key}'' 的 API 不存在，無法列出。請檢查「來源」標籤。`,
  [`APIs`]: `API`,
  [`APIs and products`]: `API 和產品`,
  [`APIs included in the plan.`]: `方案中包含的 API。`,
  [`API Key is created`]: `已建立 API 金鑰`,
  [`API Key is deleted`]: `已刪除 API 金鑰`,
  [`API Key is successfully created`]: `已順利建立 API 金鑰`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `API 金鑰逾時值為 **{seconds} 秒 ({time})**`,
  [`API key timeout`]: `API 金鑰逾時值`,
  [`API Key timeout has been changed`]: `API 金鑰逾時值已變更`,
  [`Application type`]: `應用程式類型`,
  [`Associated analytics service`]: `相關聯的分析服務`,
  [`AVAILABILITY`]: `可用性`,
  [`AVAILABILITY ZONE`]: `可用性區域`,
  [`About`]: `關於`,
  [`About audit setting`]: `關於審核設定`,
  [`About Authentication URL user registry`]: `關於鑑別 URL 使用者登錄`,
  [`About availability zones`]: `關於可用性區域`,
  [`About catalogs`]: `關於型錄`,
  [`About consumer organization owners`]: `關於消費者組織擁有者`,
  [`About consumer organizations`]: `關於消費者組織`,
  [`About developer portals`]: `關於 Developer Portal`,
  [`About email servers`]: `關於電子郵件伺服器`,
  [`About Endpoints`]: `關於端點`,
  [`About gateways`]: `關於閘道`,
  [`About gateway visibility`]: `關於閘道可見性`,
  [`About Gateway Processing Status`]: `關於閘道處理狀態`,
  [`About introspection`]: `關於內部檢查`,
  [`About Keystores`]: `關於金鑰儲存庫`,
  [`About LDAP`]: `關於 LDAP`,
  [`About local user registries`]: `關於本端使用者登錄`,
  [`About metadata`]: `關於 meta 資料`,
  [`About Migration Target`]: `關於移轉目標`,
  [`About migration targets`]: `關於移轉目標`,
  [`About native OAuth provider`]: `關於原生 OAuth 提供者`,
  [`About OpenID Connect`]: `關於 OpenID Connect`,
  [`About replacing a product`]: `關於取代產品`,
  [`About Role defaults`]: `關於角色預設值`,
  [`About roles`]: `關於角色`,
  [`About scopes`]: `關於範圍`,
  [`About Set Migration Targets`]: `關於設定移轉目標`,
  [`About spaces`]: `關於空間`,
  [`About Subscription`]: `關於訂閱`,
  [`About superseding a product`]: `關於接替產品`,
  [`About TLS server profile`]: `關於 TLS 伺服器設定檔`,
  [`About TLS client profiles`]: `關於 TLS 用戶端設定檔`,
  [`About TLS server profiles`]: `關於 TLS 伺服器設定檔`,
  [`About third party OAuth provider`]: `關於協力廠商 OAuth 提供者`,
  [`About tokens`]: `關於記號`,
  [`About Transfer Ownership`]: `關於轉移所有權`,
  [`About Transferring Ownership`]: `關於轉移所有權`,
  [`About Truststores`]: `關於信任儲存庫`,
  [`About adding a member`]: `關於新增成員`,
  [`About adding provider organization member`]: `關於新增提供者組織成員`,
  [`About adding members to a Space`]: `關於新增成員至空間`,
  [`About adding members to a catalog`]: `關於新增成員至型錄`,
  [`About changing owners`]: `關於變更擁有者`,
  [`About enabling gateway services`]: `關於啟用閘道服務`,
  [`About inviting a member`]: `關於邀請成員`,
  [`About inviting catalog members`]: `關於邀請型錄成員`,
  [`About inviting provider organization members`]: `關於邀請提供者組織成員`,
  [`About inviting space members`]: `關於邀請空間成員`,
  [`About keystores`]: `關於金鑰儲存庫`,
  [`About managing Space membership`]: `關於管理空間成員資格`,
  [`About migrating subscriptions`]: `關於移轉訂閱`,
  [`About provider organizations`]: `關於提供者組織`,
  [`About roles and role defaults`]: `關於角色和角色預設值`,
  [`About roles in spaces`]: `關於空間中的角色`,
  [`About selecting gateway services`]: `關於選取閘道服務`,
  [`About send message`]: `關於傳送訊息`,
  [`About sending messages`]: `關於傳送訊息`,
  [`About the analytics service`]: `關於分析服務`,
  [`About the DNS Scheme`]: `關於 DNS 架構`,
  [`About the Gateway Service`]: `關於閘道服務`,
  [`About the Portal Service`]: `關於入口網站服務`,
  [`About the product lifecycle`]: `關於產品生命週期`,
  [`About the provider organization owner`]: `關於提供者組織擁有者`,
  [`About this Cloud`]: `關於此雲端`,
  [`About transferring ownership`]: `關於轉移所有權`,
  [`About truststores`]: `關於信任儲存庫`,
  [`About visibility`]: `關於可見性`,
  [`About visibility and subscribability`]: `關於可見性及訂閱功能`,
  [`Accept`]: `接受`,
  [`Access Code`]: `存取碼`,
  [`Access Token`]: `存取記號`,
  [`Access URL through Secure Gateway`]: `透過 Secure Gateway 存取 URL`,
  [`Access code`]: `存取碼`,
  [`Access code is not chosen in supported grant type.`]: `未在支援的授權類型中選擇存取碼。`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `透過 Secure Gateway 存取 URL。此閘道是在 API 從暫置狀態變成已發佈狀態時設定。`,
  [`Access token TTL has been changed`]: `存取記號 TTL 已變更`,
  [`Access token time to live`]: `存取記號的存活時間`,
  [`Access token time-to-live`]: `存取記號的存活時間`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `存取記號將在 **{seconds} 秒 ({time})** 後到期`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `系統會向用戶端應用程式授與存取記號，以容許應用程式代表應用程式使用者存取資源。系統會向用戶端發出重新整理記號以在現行存取記號失效或過期時取得新的存取記號，或者取得其他具有相同或更小範圍的存取記號。您還可以指定任何數量存取記號和重新整理記號之組合所提供的許可保持有效的時間長度。`,
  [`Access tokens time to live (seconds)`]: `存取記號的存活時間（秒）`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `存取記號、ID 記號及暫時記號可指派給任何現有的金鑰儲存庫。會保留金鑰儲存庫指派的歷程。`,
  [`Account`]: `帳戶`,
  [`Account has been updated.`]: `已更新帳戶。`,
  [`Account password has been changed.`]: `帳戶密碼已變更。`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `動作`,
  [`Activate API`]: `啟動 API`,
  [`Activate API|button text`]: `啟動 API`,
  [`Activate API for testing|title`]: `啟動 API`,
  [`Activation link`]: `啟動鏈結`,
  [`Active`]: `作用中`,
  [`Activity log`]: `活動日誌`,
  [`Activity log API setting is not available for this API's gateway type.`]: `「活動日誌 API」設定不適用於此 API 的閘道類型。`,
  [`Active loading indicator`]: `作用中的載入指示器`,
  [`Add`]: `新增`,
  [`Add allowlist`]: `新增允許清單`,
  [`Add a new billing integration in Resources`]: `在資源中新增計費整合`,
  [`Add API user registries`]: `新增 API 使用者登錄`,
  [`Add APIs to Product`]: `新增 API 至產品`,
  [`Add another cloud`]: `新增另一個雲端`,
  [`Add billing integration`]: `新增計費整合`,
  [`Add Burst Limit`]: `新增高載限制`,
  [`Add Ciphers for TLS client profile`]: `為 TLS 用戶端設定檔新增密碼`,
  [`Add Ciphers for TLS server profile`]: `為 TLS 伺服器設定檔新增密碼`,
  [`Add Condition`]: `新增條件`,
  [`Add Consumer Role`]: `新增消費者角色`,
  [`Add Count Limit`]: `新增計數限制`,
  [`Add Credential`]: `新增認證`,
  [`Add domain`]: `新增網域`,
  [`Add credential`]: `新增認證`,
  [`Add Extension`]: `新增延伸`,
  [`Add GraphQL schema`]: `新增 GraphQL 綱目`,
  [`Add HTTP Endpoint`]: `新增 HTTP 端點`,
  [`Add group`]: `新增群組`,
  [`Add JSON schema`]: `新增 JSON 綱目`,
  [`Add Keystore to TLS client profile`]: `新增金鑰儲存庫至 TLS 用戶端設定檔`,
  [`Add Keystore to TLS server profile`]: `新增金鑰儲存庫至 TLS 伺服器設定檔`,
  [`Add member`]: `新增成員`,
  [`Add New Role for Consumer organization`]: `為消費者組織新增角色`,
  [`Add operation`]: `新增作業`,
  [`Add Parameters`]: `新增參數`,
  [`Add Policy`]: `新增原則`,
  [`Add Rate limit`]: `新增速率限制`,
  [`Add Rate Limit`]: `新增速率限制`,
  [`Add role`]: `新增角色`,
  [`Add Syslog TCP Endpoint`]: `新增 Syslog TCP 端點`,
  [`Add Syslog TLS Endpoint`]: `新增 Syslog TLS 端點`,
  [`Add Syslog UDP Endpoint`]: `新增 Syslog UDP 端點`,
  [`Add XML schema`]: `新增 XML 綱目`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `新增閘道端點以用於呼叫此型錄中的 API。`,
  [`Add a different Cloud Connection.`]: `新增不同的雲端連線。`,
  [`Add a member from the user registry`]: `從使用者登錄新增成員`,
  [`Add a user to the Admin organization, and assign the required roles`]: `新增使用者至管理者組織，並指派必要的角色`,
  [`Add a user to the provider organization, and assign the required roles`]: `新增使用者至提供者組織，並指派必要的角色`,
  [`Add a user to the Catalog, and assign the required roles`]: `新增使用者至型錄，並指派必要的角色`,
  [`Add a user to the Space, and assign the required roles`]: `新增使用者至空間，並指派必要的角色`,
  [`Add action`]: `新增動作`,
  [`Add additional client ID/client secret pairs`]: `新增其他用戶端 ID/用戶端密碼對組`,
  [`Add blocklist`]: `新增封鎖清單`,
  [`Add case`]: `新增案例`,
  [`Add catch`]: `新增捕捉`,
  [`Add default catch`]: `新增預設捕捉`,
  [`Add destination`]: `新增目的地`,
  [`Add entry`]: `新增項目`,
  [`Add group failed.`]: `新增群組失敗。`,
  [`Add input`]: `新增輸入`,
  [`Add media type`]: `新增媒體類型`,
  [`Add member failed`]: `新增成員失敗`,
  [`Add object`]: `新增物件`,
  [`Add otherwise`]: `新增 Otherwise`,
  [`Add output`]: `新增輸出`,
  [`Add outputs for operation...`]: `新增作業的輸出...`,
  [`Add parameters for operation...`]: `新增作業的參數...`,
  [`Add parameters to this API`]: `將參數新增至此 API`,
  [`Add plans to product`]: `將方案新增至產品`,
  [`Add plans to this product`]: `將方案新增至此產品`,
  [`Add schema`]: `新增綱目`,
  [`Add scopes for this OAuth provider.`]: `新增此 OAuth 提供者的範圍。`,
  [`Add scopes to allow access to`]: `新增範圍以容許存取`,
  [`Add scopes to allow access to.`]: `新增範圍以容許存取。`,
  [`Add tags and external documentation details for this API`]: `為此 API 新增標籤及外部文件詳細資料`,
  [`Add to`]: `新增至`,
  [`Add to existing product`]: `新增至現有的產品`,
  [`Add/Remove APIs`]: `新增/移除 API`,
  [`AddProperties`]: `新增內容`,
  [`Added APIs`]: `已新增 API`,
  [`Added rate limit`]: `已新增速率限制`,
  [`Added rate limits`]: `已新增速率限制`,
  [`Adding an API to a product for publishing`]: `將 API 新增至要發佈的產品`,
  [`Additional support`]: `其他支援`,
  [`Additional properties`]: `其他內容`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `此外，請考量移除相關聯的登錄，亦即 {name} 型錄使用者登錄（如果未使用的話）。`,
  [`Address`]: `位址`,
  [`Admin DN`]: `管理者 DN`,
  [`Admin organization invitation timeout`]: `管理者組織的邀請逾時值`,
  [`Admin organization invitation timeout has been changed`]: `管理者組織的邀請逾時值已變更`,
  [`Admin password`]: `管理者密碼`,
  [`Admin request reset password`]: `管理者要求重設密碼`,
  [`Admin reset password`]: `管理者重設密碼`,
  [`Admin Sign In`]: `管理者登入`,
  [`Admin add catalog failed`]: `管理者新增型錄失敗`,
  [`Admin add space failed`]: `管理者新增空間失敗`,
  [`Administer consumer organizations`]: `管理消費者組織`,
  [`Administers the API consumer organization`]: `管理 API 消費者組織`,
  [`Administers the API provider organization`]: `管理 API 提供者組織`,
  [`Administers the admin organization`]: `管理管理者組織`,
  [`Administers the admin topology`]: `管理管理者拓蹼`,
  [`Administers the app developer organization`]: `管理應用程式開發者組織`,
  [`Administers the catalog`]: `管理型錄`,
  [`Administers the cloud topology`]: `管理雲端拓蹼`,
  [`Administers the space`]: `管理空間`,
  [`Administration management console`]: `管理主控台`,
  [`Administrator`]: `管理者`,
  [`Advanced analytics configuration`]: `進階分析配置`,
  [`Advanced features`]: `進階特性`,
  [`Advanced scope check`]: `進階範圍檢查`,
  [`Advanced scope check after Token Validation`]: `驗證記號後進行進階範圍檢查`,
  [`Advanced scope check before Token Generation`]: `產生記號前進行進階範圍檢查`,
  [`Aggregation`]: `聚集`,
  [`Agree`]: `同意`,
  [`All`]: `全部`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `消費者組織中所有已在此 Developer Portal 註冊的已鑑別開發者，皆可查看此產品。`,
  [`All consumer organizations will be able to see this product.`]: `所有消費者組織皆可查看此產品。`,
  [`Allowlist`]: `允許清單`,
  [`Allow Chunked Uploads`]: `容許區塊上傳`,
  [`Allow chunked uploads`]: `容許區塊上傳`,
  [`Allow "plain" challenge method`]: `容許「一般」盤查方法`,
  [`Allow renegotiation`]: `容許重新協議`,
  [`Allow case sensitive usernames`]: `容許使用者名稱區分大小寫`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `容許用戶端在其與伺服器的起始信號交換中使用所需的主機名稱注入 SNI 延伸。`,
  [`Allow connection to be renegotiated`]: `容許重新協議連線`,
  [`Allow default introspection`]: `容許預設內部檢查`,
  [`Allow insecure server connections`]: `容許不安全的伺服器連線`,
  [`Allow null value`]: `容許使用空值`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `容許在 302重新導向中傳送存取記號/重新整理記號以進行登出`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `已套用所有建議。您的綱目沒有警告。`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `容許使用 Developer Portal 中的測試工具來測試 API 的作業。`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `容許連線繼續使用弱式或不安全認證`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `容許連線繼續使用弱式或不安全認證。`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `容許外部 OIDC 提供者與 Developer Portal 通訊，而不是與 API Manager 通訊。`,
  [`Allow to manage this user registry`]: `容許管理此使用者登錄`,
  [`Allow to manage users under this user registry`]: `容許管理此使用者登錄下的使用者`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `容許使用者在系統向 APIC 呈現發證者發出的記號時自動上線`,
  [`Already have an account?`]: `已經有帳戶了嗎？`,
  [`Also transfer ownership of owned Spaces`]: `同時轉移所擁有空間的所有權`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `一律從使用者資訊端點提取使用者資料（如果啟用的話）`,
  [`Always output the root element`]: `一律輸出根元素`,
  [`Always output the root element.`]: `一律輸出根元素。`,
  [`Always required`]: `一律需要`,
  [`Always use userinfo endpoint`]: `一律使用使用者資訊端點`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `OAuth 提供者 API 包含 OAuth 流程的授權和記號端點。請在此處配置您的 OAuth 提供者；然後當您建立 OAuth 安全 API 時，可以選取需要的提供者。`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `OAuth 提供者 API 包含 OAuth 流程的授權和記號端點；請選取要用來利用 OAuth 保護 API 的 OAuth 提供者`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `在開發者為空間內的某個方案訂閱應用程式以便呼叫相關聯的 API 時，該應用程式會在這裡列出；您可以暫停開發者應用程式來阻止其存取 API，亦可建立自己的應用程式。[進一步瞭解]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `鑑別 URL 指向作為使用者登錄的協力廠商鑑別提供者。 「鑑別 URL」允許與 LDAP 以外的協力廠商使用者登錄進行整合。`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `鑑別 URL 提供簡式機制，用於向自訂身分提供者鑑別使用者。`,
  [`An error occurred communicating with the gateways subsystem.`]: `與閘道子系統進行通訊時發生錯誤。`,
  [`An organization is required.`]: `需要組織。`,
  [`An unknown error occurred.`]: `發生不明錯誤。`,
  [`Analytics`]: `分析`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Analytics Insights 服務'`,
  [`Analytics Details`]: `分析詳細資料`,
  [`Analytics destinations`]: `分析目的地`,
  [`Analytics Director keystore`]: `Analytics Director 金鑰儲存庫`,
  [`Analytics Service`]: `分析服務`,
  [`Analytics Service URL`]: `分析服務 URL`,
  [`Analytics Service {arg} has been removed.`]: `已移除分析服務 {arg}。`,
  [`Analytics details`]: `分析詳細資料`,
  [`Analytics service`]: `分析服務`,
  [`Analytics service details`]: `分析服務詳細資料`,
  [`Analytics service URL`]: `分析服務 URL`,
  [`Analytics service {arg} has been removed.`]: `已移除分析服務 {arg}。`,
  [`Analytics client TLS client profile`]: `分析用戶端 TLS 用戶端設定檔`,
  [`Analytics client keystore`]: `分析用戶端金鑰儲存庫`,
  [`Analytics client truststore`]: `分析用戶端信任儲存庫`,
  [`Analytics ingestion TLS client profile`]: `分析汲取 TLS 用戶端設定檔`,
  [`Analytics ingestion keystore`]: `分析汲取金鑰儲存庫`,
  [`Analytics ingestion truststore`]: `分析汲取信任儲存庫`,
  [`Analytics service {title} has been created.`]: `已建立分析服務 {title}。`,
  [`Analytics service {title} has been updated.`]: `已更新分析服務 {title}。`,
  [`The selected consumer organization is`]: `選取的消費者組織是`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `已配置分析服務且已在外部卸載分析資料`,
  [`Analyze API usage and performance`]: `分析 API 用量和效能`,
  [`Anonymous bind`]: `匿名連結`,
  [`Another user registry`]: `其他使用者登錄`,
  [`api-administrator`]: `API 管理者`,
  [`Api-Analytics`]: `Api 分析`,
  [`Api-Drafts`]: `API-草稿`,
  [`App`]: `應用程式`,
  [`App name`]: `應用程式名稱`,
  [`App-Analytics`]: `應用程式分析`,
  [`App-Approval`]: `應用程式核准`,
  [`App-Dev`]: `應用程式開發`,
  [`App-analytics`]: `應用程式分析`,
  [`App-dev`]: `應用程式開發`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `應用程式`,
  [`Application Authentication`]: `應用程式鑑別`,
  [`Application Authentication Source`]: `應用程式鑑別來源`,
  [`Application lifecycle`]: `應用程式生命週期`,
  [`Application approval task for upgrading application`]: `升級下列應用程式的應用程式核准作業：`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `用於將應用程式 {taskname} 升級至 {username} ({orgname}) 所要求的正式作業的應用程式核准作業`,
  [`Application authentication`]: `應用程式鑑別`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `應用程式開發者最初是使用 Developer Portal 為一個以上的方案訂閱他們的應用程式。然而，對於所選方案，您可以將應用程式訂閱移轉至另一個產品中的方案。`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `應用程式開發者最初是使用 Developer Portal 為一個以上的方案訂閱他們的應用程式。然而，對於所選方案，您可以將應用程式訂閱移轉至另一個產品中的方案。`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `應用程式開發者可為應用程式訂閱方案，以便隨後能夠在該方案中呼叫 API。`,
  [`Application is being created. Please Wait`]: `正在建立應用程式。請稍候`,
  [`Application scope check`]: `應用程式範圍檢查`,
  [`Applications`]: `應用程式`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `在開啟應用程式生命週期之前存在的應用程式將保持正式作業狀態`,
  [`Applied security`]: `已套用安全`,
  [`Apply`]: `套用`,
  [`Apply all`]: `全部套用`,
  [`Apply all suggestions`]: `套用所有建議`,
  [`Apply analysis configuration`]: `套用分析配置`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `套用 'invoke' 原則，以便在作業中呼叫現有的服務。此原則可以和 JSON 或 XML 資料一起使用，而且可以在組件中套用多次。`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `套用 WebSocket 升級原則以建立 WebSocket 連線，以便在作業中呼叫現有的服務。`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `套用此原則以便在作業中呼叫 Proxy API，特別是在您需要呼叫大量有效負載時。每個組件只允許呼叫一個 'proxy' 原則。`,
  [`Apply to selected`]: `套用至選定項目`,
  [`Apply type analysis configuration`]: `套用類型分析配置`,
  [`Approval History`]: `核准歷程`,
  [`Approval tasks`]: `核准作業`,
  [`Approvals`]: `核准`,
  [`Approve`]: `核准`,
  [`Archive`]: `保存`,
  [`Archive Product`]: `保存產品`,
  [`Archive|permission`]: `保存`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `確定要從選取的項目中清除限制項嗎？`,
  [`Are you sure you want to delete API?`]: `確定要刪除 API 嗎？`,
  [`Are you sure you want to delete Product?`]: `確定要刪除產品嗎？`,
  [`Are you sure you want to delete a catalog?`]: `確定要刪除型錄嗎？`,
  [`Are you sure you want to delete a space?`]: `確定要刪除空間嗎？`,
  [`Are you sure you want to delete this application?`]: `確定要刪除此應用程式嗎？`,
  [`Are you sure you want to delete this billing integration?`]: `您確定要刪除此計費整合嗎？`,
  [`Are you sure you want to delete this field?`]: `確定要刪除此欄位嗎？`,
  [`Are you sure you want to delete this policy?`]: `確定要刪除此原則嗎？`,
  [`Are you sure you want to delete this type?`]: `確定要刪除此類型嗎？`,
  [`Are you sure you want to disable spaces?`]: `確定要停用空間嗎？`,
  [`Are you sure you want to disable custom email sender information?`]: `您確定要停用自訂電子郵件寄件者資訊嗎？`,
  [`Are you sure you want to disable custom notification templates?`]: `確定要停用自訂通知範本嗎？`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `確定要停止使用 IBM Developer Portal 嗎？將會刪除對型錄的所有入口網站自訂作業`,
  [`Are you sure you want to enable spaces?`]: `確定要啟用空間嗎？`,
  [`Are you sure you want to enable custom email sender information?`]: `您確定要啟用自訂電子郵件寄件者資訊嗎？`,
  [`Are you sure you want to enable custom notification templates?`]: `確定要啟用自訂通知範本嗎？`,
  [`Are you sure you want to proceed with delete?`]: `確定要繼續刪除嗎？`,
  [`Are you sure you want to publish?`]: `確定要發佈嗎？`,
  [`Are you sure you want to re-stage?`]: `確定要重新暫置嗎？`,
  [`Are you sure you want to remove the product from the catalog?`]: `確定要將產品從型錄中移除嗎？`,
  [`Are you sure you want to unassociate?`]: `確定要取消關聯嗎？`,
  [`Are you sure you want to update Self Service Onboarding`]: `確定要更新自助式上線嗎`,
  [`Are you sure?`]: `確定嗎？`,
  [`Argument`]: `引數`,
  [`Array`]: `陣列`,
  [`Assemble`]: `組合`,
  [`Assemble the policy flow required for the OAuth provider.`]: `組合 OAuth 提供者所需的原則流程。`,
  [`Assembly`]: `組件`,
  [`Assembly Execute`]: `組件執行`,
  [`Assembly saved`]: `已儲存組件`,
  [`Assembly burst limits`]: `組件高載限制`,
  [`Assembly count limits`]: `組件計數限制`,
  [`Assign roles`]: `指派角色`,
  [`Assign roles to the current owner`]: `指派角色給現行擁有者`,
  [`Associate`]: `關聯`,
  [`Associate analytics service`]: `關聯分析服務`,
  [`Associate analytics`]: `關聯分析`,
  [`Assumed size`]: `假設大小`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `嘗試從 URL {url} 取得 API 時發生「鑑別」錯誤。 請檢查使用者名稱和密碼。`,
  [`Audience claim`]: `對象要求`,
  [`Audience Claim`]: `對象要求`,
  [`Audit setting`]: `審核設定`,
  [`Audit setting has been changed`]: `審核設定已變更`,
  [`Auditing is **{mode}**`]: `審核是**{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `「審核」用來監視 API 呼叫，並記載下列的相關資訊：呼叫端使用者、每次呼叫的時間，以及每一個事件中涉及的活動。`,
  [`Authenticate Client Method`]: `鑑別用戶端方法`,
  [`Authenticate password`]: `鑑別密碼`,
  [`Authenticate user`]: `鑑別使用者`,
  [`Authenticate user settings`]: `鑑別使用者設定`,
  [`Authenticate application users using`]: `鑑別應用程式使用者的方式`,
  [`Authenticate using Basic Authentication`]: `使用基本鑑別進行鑑別`,
  [`Authenticate using OAuth`]: `使用 OAuth 進行鑑別`,
  [`Authenticated`]: `已鑑別`,
  [`Authenticated bind`]: `已鑑別的連結`,
  [`Authenticated User Name`]: `已鑑別使用者名稱`,
  [`Authentication`]: `鑑別`,
  [`Authentication Bind`]: `鑑別連結`,
  [`Authentication error`]: `鑑別錯誤`,
  [`Authentication method`]: `鑑別方法`,
  [`Authentication TLS profile`]: `鑑別 TLS 設定檔`,
  [`Authentication URL`]: `鑑別 URL`,
  [`Authentication URL user registry`]: `鑑別 URL 使用者登錄`,
  [`Authentication URL user registry has been created.`]: `已建立鑑別 URL 使用者登錄。`,
  [`Authentication URL to use for validation.`]: `用於驗證的鑑別 URL。`,
  [`Authentication response header credential`]: `鑑別回應標頭認證`,
  [`Authentication response header pattern`]: `鑑別回應標頭型樣`,
  [`Authentication type`]: `鑑別類型`,
  [`Authentication type to use to validate the UsernameToken.`]: `用於驗證 UsernameToken 的鑑別類型。`,
  [`Authorization`]: `授權`,
  [`Authorization Code`]: `授權碼`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `授權 URL 必須符合 OAuth 提供者 "{providerTitle}" 所定義的 {endpoint}`,
  [`Authorization endpoint`]: `授權端點`,
  [`Authorization Request`]: `授權要求`,
  [`Authorization URL`]: `授權 URL`,
  [`Authorize`]: `授權`,
  [`Authorize User Settings`]: `授權使用者設定`,
  [`Authorize application users using`]: `授權應用程式使用者方式`,
  [`Authorize path`]: `授權路徑`,
  [`Authors API and product definitions`]: `編寫 API 和產品定義`,
  [`Auto Generate OIDC API Assembly`]: `自動產生 OIDC API 組件`,
  [`Auto onboard`]: `自動上線`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `自動產生用於 API Connect 管理 API 和指令中的名稱。`,
  [`Automation`]: `自動化`,
  [`Automation Management Console`]: `自動化管理主控台`,
  [`Automation management console`]: `自動化管理主控台`,
  [`Availability Zone`]: `可用性區域`,
  [`Availability zone`]: `可用性區域`,
  [`Availability zone {arg} has been removed.`]: `已移除可用性區域 {arg}。`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `可用性區域可讓您將 API Connect 服務分組，以符合您的商業需要。 例如，您可以根據閘道服務所在的地區或資料中心來分組閘道服務。`,
  [`Average response time: {{value}}ms`]: `平均回應時間：{{value}} 毫秒`,
  [`Average time`]: `平均時間`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `將 API Designer 伺服器用作本端 Proxy 以避免 CORS 錯誤。`,
  [`Back`]: `上一步`,
  [`Back to sign in`]: `回到登入`,
  [`Back to test`]: `回到測試`,
  [`Backend type`]: `後端類型`,
  [`Bad gateway`]: `閘道錯誤`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `基本 DN`,
  [`Base Path`]: `基本路徑`,
  [`Base path`]: `基本路徑`,
  [`Base endpoint`]: `基本端點`,
  [`Base endpoints`]: `基本端點`,
  [`Base64 encoding`]: `Base64 編碼`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `對邀請和密碼重設記號進行 Base64 編碼**{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `暫時記號的 Base64 編碼設定已變更`,
  [`Base URL of API invocation endpoint`]: `API 呼叫端點的基本 URL`,
  [`Basepath root`]: `基本路徑根目錄`,
  [`Basic`]: `基本`,
  [`Basic authentication`]: `基本鑑別`,
  [`Basic authentication password`]: `基本鑑別密碼`,
  [`Basic authentication request header name`]: `基本鑑別要求標頭名稱`,
  [`Basic authentication username`]: `基本鑑別使用者名稱`,
  [`Before you begin`]: `開始之前`,
  [`Before you begin...`]: `開始之前...`,
  [`Between 0 and 10 events are waiting to be processed`]: `0 到 10 個事件等待處理`,
  [`Between 10 and 20 events are waiting to be processed`]: `10 到 20 個事件等待處理`,
  [`Billing`]: `計費`,
  [`Billing and Payment`]: `計費和付款`,
  [`Billing has been updated`]: `計費已更新`,
  [`Billing integrations`]: `計費整合`,
  [`Billing integration`]: `計費整合`,
  [`Billing Integration`]: `計費整合`,
  [`Billing {title} successfully added.`]: `已順利新增計費 {title}。`,
  [`Billing {title} successfully deleted.`]: `已順利刪除計費 {title}。`,
  [`Billing {title} successfully updated.`]: `已順利更新計費 {title}。`,
  [`Billing Options`]: `計費選項`,
  [`Blocklist`]: `封鎖清單`,
  [`Blocking`]: `封鎖`,
  [`Blocking option`]: `封鎖選項`,
  [`Body`]: `內文`,
  [`Boolean`]: `布林`,
  [`Both`]: `兩者`,
  [`Browse`]: `瀏覽`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `先將 API 要求和回應放在緩衝區，再於 DataPower API Gateway 上處理。`,
  [`Buffering`]: `緩衝`,
  [`Builds and manages apps in the developer organization`]: `在開發者組織中建置及管理應用程式`,
  [`Burst Limit Name`]: `高載限制名稱`,
  [`Burst limits`]: `高載限制`,
  [`Burst limit value can't be negative`]: `高載限制值不能是負數`,
  [`Buy`]: `購買`,
  [`but unable to remove because of limitation`]: `但因為限制而無法移除`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `您可以透過配置產品的可見性及訂閱功能，來控制其 API 在 Developer Portal 中對應用程式開發者的可用性。您可以控制哪些應用程式開發者可以查看產品，以及哪些應用程式開發者可以訂閱以使用產品中的 API。`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `您可以透過配置產品的可見性及訂閱功能，來控制其 API 在 Developer Portal 中對應用程式開發者的可用性。您可以控制哪些應用程式開發者可以查看產品，以及哪些應用程式開發者可以訂閱以使用產品中的 API。`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `依預設，從多個來源對映（例如，長度 2 的 array1 和長度 3 的 array2）會產生包含 5 個項目的目標陣列（處理 array1 產生 2 個，處理 array2 產生 3 個）。如果您偏好結合這些規則（結合每一個來源陣列的內容建立包含 2 或 3 個項目的陣列），請勾選此選項。`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `依預設，此產品會發佈到所有相關閘道服務。您也可以透過啟用此選項來發佈到特定閘道服務。`,
  [`By payload`]: `依內容`,
  [`By URL parameter`]: `依 URL 參數`,
  [`Bytes`]: `位元組`,
  [`CALLS`]: `呼叫`,
  [`Calls`]: `呼叫`,
  [`CATALOG`]: `型錄`,
  [`CERTIFICATE NAME`]: `憑證名稱`,
  [`CIPHER`]: `密碼`,
  [`CIPHER SUITES`]: `密碼組合`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `僅 CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `用戶端 ID`,
  [`CLOUD ADMINISTRATOR`]: `雲端管理者`,
  [`Consumer organization`]: `消費者組織`,
  [`CONSUMER ORGANIZATION / GROUP`]: `消費者組織/群組`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA 組合`,
  [`Cache Key`]: `快取索引鍵`,
  [`Cache Time to Live (second)`]: `快取存活時間（秒）`,
  [`Cache key`]: `快取索引鍵`,
  [`Cache Time-To-Live`]: `快取存活時間`,
  [`Cache type`]: `快取類型`,
  [`Can't find the one you want?`]: `找不到您想要的項目嗎？`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `無法以無效的郵件伺服器傳送邀請，請檢查您的郵件伺服器配置，然後再試一次`,
  [`Cancel`]: `取消`,
  [`Cannot be below {min}`]: `不能低於 {min}`,
  [`Cannot be empty`]: `不能是空的`,
  [`Cannot delete root type.`]: `無法刪除根類型。`,
  [`Cannot exceed {max}`]: `不能超過 {max}`,
  [`Cannot find any APIs for this product`]: `找不到此產品的任何 API`,
  [`Cannot find the one you want?`]: `找不到您需要的項目？`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `在不會傳回清單的欄位上，如果也沒有定義 "sizedFields"，則無法使用 "@listSize" 指引。`,
  [`Cannot remove built-in scalar types`]: `無法移除內建純量類型`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `無法移除查詢、突變及訂閱根作業類型`,
  [`Cannot remove arguments of built-in directives`]: `無法移除內建指引的引數`,
  [`Cannot remove non-null arguments of directives`]: `無法移除指引的非空值引數`,
  [`Cannot remove input types of arguments of directives`]: `無法移除指引的引數輸入類型`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `無法移除作為指引引數之預設值的列舉值`,
  [`Cannot remove enum values used as default values of input fields`]: `無法移除作為輸入欄位之預設值的列舉值`,
  [`Cannot remove all fields of an object type`]: `無法移除物件類型的所有欄位`,
  [`Cannot remove all fields of an interface type`]: `無法移除介面類型的所有欄位`,
  [`Cannot remove all slicing arguments`]: `無法移除所有切割引數`,
  [`Cannot remove all input fields of an input object type`]: `無法移除輸入物件類型的所有輸入欄位`,
  [`Cannot remove all values of enum type`]: `無法移除列舉類型的所有值`,
  [`Cannot remove fields of interfaces`]: `無法移除介面的欄位`,
  [`Cannot remove non-null input fields`]: `無法移除非空值輸入欄位`,
  [`Case`]: `大小寫`,
  [`Case sensitive`]: `區分大小寫`,
  [`Catalog`]: `型錄`,
  [`Catalog defaults`]: `型錄預設值`,
  [`Catalog Invite`]: `型錄邀請`,
  [`Catalog invite`]: `型錄邀請`,
  [`Catalog invites`]: `型錄邀請`,
  [`Catalog name`]: `型錄名稱`,
  [`Catalog owner`]: `型錄擁有者`,
  [`Catalog owner invitation`]: `型錄擁有者邀請`,
  [`Catalog properties`]: `型錄內容`,
  [`Catalog summary`]: `型錄摘要`,
  [`Catalog title`]: `型錄標題`,
  [`Catalog user registries`]: `型錄使用者登錄`,
  [`Catalog by Name`]: `型錄（依名稱）`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `型錄優先順序（使用由型錄管理者定義的虛名端點）`,
  [`Catalog {name} created`]: `已建立型錄 {name}`,
  [`Catalog selection`]: `型錄選擇`,
  [`Catalog settings`]: `型錄設定`,
  [`Catalog settings have been updated`]: `已更新型錄設定`,
  [`Catalog User`]: `型錄使用者`,
  [`Catalog Users`]: `型錄使用者`,
  [`Catalog user registry`]: `型錄使用者登錄`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `無法在「測試喜好設定」中使用已啟用生命週期的型錄。`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `無法在「測試喜好設定」中使用已啟用生命周期和正式作業模式的型錄。`,
  [`Catalogs`]: `型錄`,
  [`Catch`]: `捕捉`,
  [`Catches`]: `捕捉`,
  [`Categories`]: `種類`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `種類在 Developer Portal 中建立 API 產品的階層式顯示。使用下列語法：top_level_element/next_level_element/lower_level_element。`,
  [`Category`]: `種類`,
  [`Certifcate or Shared Secret for Verify`]: `用於驗證的憑證或共用密碼`,
  [`Certificate`]: `憑證`,
  [`Certificate (Optional)`]: `憑證（選用）`,
  [`Certificate management`]: `憑證管理`,
  [`Certificate Manager instance`]: `憑證管理程式實例`,
  [`Certificate (optional)`]: `憑證（選用）`,
  [`Certificates`]: `憑證`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `需要憑證，以保護閘道與它所處理的網域之間的連線安全。所有憑證必須儲存在「憑證管理程式」服務中。該服務會為憑證提供一個安全儲存庫，並且有助於防止中斷現象，因為它會在憑證快要到期時傳送通知給您。如果要著手進行，`,
  [`Certificates details in this keystore`]: `此金鑰儲存庫中的憑證詳細資料`,
  [`Certificates details in this truststore`]: `此信任儲存庫中的憑證詳細資料`,
  [`Change`]: `變更`,
  [`Change Consumer Organization owner to an existing user`]: `將消費者組織擁有者變更為現有使用者`,
  [`Change Ownership to an Existing User`]: `變更所有權給現有使用者`,
  [`Change portal service`]: `變更入口網站服務`,
  [`Change owner to existing user`]: `將擁有者變更為現有使用者`,
  [`Change owner to new user`]: `將擁有者變更為新使用者`,
  [`Change ownership by inviting a new user`]: `透過邀請新使用者變更所有權`,
  [`Change ownership to`]: `變更所有權給`,
  [`Change ownership to a new user via email invitation`]: `透過電子郵件邀請變更所有權給新使用者`,
  [`Change ownership to an existing user`]: `變更所有權給現有使用者`,
  [`Change password`]: `變更密碼`,
  [`Change setup`]: `變更設定`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `如果您沒有同時更新 OAuth 提供者自身定義中的任何對應值，則您對 OAuth 提供者所基於的 API 或組件所做的直接變更可能會將 OAuth 提供者呈現為無效。如果儲存這些變更，請確定您同時在 OAuth 提供者定義中進行了所有對應更新。`,
  [`Change the following extra constraints in the schema:`]: `變更綱目中的下列額外限制：`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `如果變更「憑證管理程式」，將會移除所有現有的憑證和 CA 組合指派。`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `變更 DNS 架構會將 URL 鏈結的格式變更為 API Connect 和 Developer Portal。您需要將新鏈結傳達給使用者。如果不要繼續，請按一下「取消」。`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `變更 API 的名稱或版本時將建立新的 API。您確定要儲存您的變更嗎？`,
  [`Check your email`]: `檢查您的電子郵件`,
  [`Child`]: `子項`,
  [`Chinese (Simplified)`]: `簡體中文`,
  [`Chinese (Traditional)`]: `繁體中文`,
  [`Choose`]: `選擇`,
  [`Choose a`]: `選擇`,
  [`Choose {docTypeLabel}`]: `選擇「{docTypeLabel}」`,
  [`Choose Catalog`]: `選擇型錄`,
  [`Choose a Catalog`]: `選擇型錄`,
  [`Choose a Consumer Org`]: `選擇消費者組織`,
  [`Choose Gateway`]: `選擇閘道`,
  [`Choose a Gateway`]: `選擇閘道`,
  [`Choose Product`]: `選擇產品`,
  [`Choose a Product`]: `選擇產品`,
  [`Choose Rate Limit`]: `選擇速率限制`,
  [`Choose a Rate Limit`]: `選擇速率限制`,
  [`Choose Application`]: `選擇應用程式`,
  [`Choose an Application`]: `選擇應用程式`,
  [`Choose a {docTypeLabel}`]: `選擇「{docTypeLabel}」`,
  [`Choose a publish destination`]: `選擇發佈目的地`,
  [`Choose a billing integration`]: `選擇計費整合`,
  [`Choose a catalog to test within:`]: `選擇要在其中進行測試的型錄：`,
  [`Choose a plan against which to test:`]: `選擇要對其進行測試的方案：`,
  [`Choose a product containing this API, or create a new one:`]: `選擇包含此 API 的產品，或建立新的產品：`,
  [`Choose a user registry`]: `選擇使用者登錄`,
  [`Choose an application with which to test, or create a new one:`]: `選擇用來測試的應用程式，或建立新的應用程式：`,
  [`Choose an existing application`]: `選擇現有應用程式`,
  [`Choose an existing catalog`]: `選擇現有型錄`,
  [`Choose an existing product published to your Sandbox catalog`]: `選擇已發佈至沙箱型錄的現有產品`,
  [`Choose an operation to invoke:`]: `選擇要呼叫的作業：`,
  [`Choose an option`]: `選擇選項`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `選擇一個選項以決定對映原則如何處理空陣列輸出。選項全部（預設選項）將輸出所有空陣列及空子項陣列。選項母項將僅輸出母項空陣列。選項無將不會輸出空陣列。`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `如果選擇封鎖模式，會對系統效能造成負面影響`,
  [`Choose existing directory`]: `選擇現有目錄`,
  [`Choose one...`]: `選擇一個...`,
  [`Choose operations and paths to generate into this API`]: `選擇要產生到此 API 中的作業和路徑`,
  [`Choose paths to generate into this API`]: `選擇要產生到此 API 中的路徑`,
  [`Choose the DNS scheme`]: `選擇 DNS 架構`,
  [`Choose the permissions for the role.`]: `選擇角色的權限。`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `選擇使用動態還是靜態 DNS 位址，對閘道服務進行入埠 API 呼叫以及存取 Developer Portal。`,
  [`Cipher`]: `密碼`,
  [`Ciphers`]: `密碼`,
  [`Clear`]: `清除`,
  [`Clear constraints`]: `清除限制項`,
  [`Clear file`]: `清除檔案`,
  [`Clear filters`]: `清除過濾器`,
  [`Clear from selected`]: `從選定項目中清除`,
  [`Clear operation filter`]: `清除作業過濾器`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `按一下憑證來檢視有關其核發組織和指紋的詳細資料。`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `按下面的鏈結，繼續在其他標籤中授權 API，並且您將取得步驟 2 的授權碼。`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `按下面的鏈結，將在新標籤中開啟伺服器。如果瀏覽器顯示憑證問題，您可以選擇接受它，然後回到這裡以重新測試。`,
  [`Click add to add a billing integration.`]: `按一下「新增」，以新增計費整合。`,
  [`Click Add to add a gateway extension.`]: `按一下「新增」，以新增閘道延伸。`,
  [`Click **Add domain** to add domains.`]: `按一下「新增網域」，以新增網域。`,
  [`Click Add to add a new base endpoint.`]: `按一下「新增」，以新增基本端點。`,
  [`Click Add to add a new HTTP header.`]: `按一下「新增」，以新增 HTTP 標頭。`,
  [`Click Add to add a new member.`]: `按一下「新增」，以新增成員。`,
  [`Click Add to add a new role.`]: `按一下「新增」，以新增角色。`,
  [`Click Add to add a new scope.`]: `按一下「新增」，以新增範圍。`,
  [`Click Add to add a new consumer organization group.`]: `按一下「新增」，以新增消費者組織群組。`,
  [`Click Add to create the API Key.`]: `按一下「新增」以建立 API 金鑰。`,
  [`Click Create to add the API Key.`]: `按一下「建立」以新增 API 金鑰。`,
  [`Click Create to add a new user registry.`]: `按一下「建立」，以新增使用者登錄。`,
  [`Click Create user registry to add a new user registry.`]: `按一下「建立使用者登錄」，以新增使用者登錄。`,
  [`Click **Create provider organization** to add provider organizations.`]: `按一下「建立提供者組織」，以新增提供者組織。`,
  [`Click Edit to add OAuth providers.`]: `按一下「編輯」，以新增 OAuth 提供者。`,
  [`Click Edit to add a user registry.`]: `按一下「編輯」，以新增使用者登錄。`,
  [`Click Edit to add an API to the product.`]: `按一下「編輯」，以新增 API 至產品。`,
  [`Click Create to add a property.`]: `按一下「建立」，以新增內容。`,
  [`Click Edit to add TLS client profiles.`]: `按一下「編輯」，以新增 TLS 用戶端設定檔。`,
  [`Click Edit to add user registries.`]: `按一下「編輯」，以新增使用者登錄。`,
  [`Click Add to add a new consumer organization.`]: `按一下「新增」，以新增消費者組織。`,
  [`Click Consumer organizations to add one.`]: `按一下「消費者組織」，以新增一個。`,
  [`Click Add to add a new application.`]: `按一下「新增」，以新增應用程式。`,
  [`Click Add to add parameters.`]: `按一下「新增」，以新增參數。`,
  [`Click Add to add response.`]: `按一下「新增」，以新增回應。`,
  [`Click Create to add a new TLS client profile.`]: `按一下「建立」，以新增 TLS 用戶端設定檔。`,
  [`Click Create to add a new keystore.`]: `按一下「建立」，以新增金鑰儲存庫。`,
  [`Click Create to add a new truststore.`]: `按一下「建立」，以新增信任儲存庫。`,
  [`Click **Register remote gateway** to add gateways.`]: `按一下「登錄遠端閘道」，以新增閘道。`,
  [`Click Register gateways to add new Gateway Services.`]: `按一下「登錄閘道」，以新增閘道服務。`,
  [`Click Edit to add Gateway Services.`]: `按一下「編輯」，以新增閘道服務。`,
  [`Click Register gateways to add new gateway services.`]: `按一下「登錄閘道」，以新增閘道服務。`,
  [`Click Edit to add gateway services.`]: `按一下「編輯」，以新增閘道服務。`,
  [`Click Edit to add an email server.`]: `按一下「編輯」，以新增電子郵件伺服器。`,
  [`Click Create to add a new TLS server profile.`]: `按一下「建立」，以新增 TLS 伺服器設定檔。`,
  [`Click Create to add a new email server.`]: `按一下「建立」，以新增電子郵件伺服器。`,
  [`Click Add to add a new schema definition.`]: `按一下「新增」，以新增綱目定義。`,
  [`Click Add to add a new property for this definition.`]: `按一下「新增」，以新增這項定義的內容。`,
  [`Click Add to add a new parameter for this API.`]: `按一下「新增」，以新增這個 API 的參數。`,
  [`Click Add to add tags and external documentation for this API.`]: `按一下「新增」，以新增這個 API 的標籤和外部文件。`,
  [`Click Add to add a new path.`]: `按一下「新增」，以新增路徑。`,
  [`Click Add to add a property for this API.`]: `按一下「新增」，以新增這個 API 的內容。`,
  [`Click here to create a security definition`]: `請按一下這裡，以建立安全定義`,
  [`Click Add to add a new security definition.`]: `按一下「新增」，以新增安全定義。`,
  [`Click Add to add a target service for this API.`]: `按一下「新增」，以新增這個 API 的目標服務。`,
  [`Click Add to add an operation for this path.`]: `按一下「新增」，以新增這個路徑的作業。`,
  [`Click Add to add a path parameter for this path.`]: `按一下「新增」，以新增這個路徑的路徑參數。`,
  [`Click Add to add a new OAuth provider.`]: `按一下「新增」，以新增 OAuth 提供者。`,
  [`Click Add to add a new provider organization.`]: `按一下「新增」，以新增提供者組織。`,
  [`Click Add to add a new API or product.`]: `按一下「新增」，以新增 API 或產品。`,
  [`Click Add to add a new API.`]: `按一下「新增」，以新增 API。`,
  [`Click Add to add a new Extension.`]: `按一下「新增」，以新增延伸。`,
  [`Click Add to add new {resource}.`]: `按一下「新增」以新增{resource}。`,
  [`Click to expand`]: `按一下以展開`,
  [`Click to collapse`]: `按一下以收合`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `按一下以瞭解如何上傳及管理閘道所需的憑證。`,
  [`Client Credentials`]: `用戶端認證`,
  [`Client information`]: `用戶端資訊`,
  [`Client revocation path`]: `用戶端撤銷路徑`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `按一下「登錄服務」以登錄服務。這將容許您在配置其他服務時測試端點。`,
  [`Client secret`]: `用戶端密碼`,
  [`Client security`]: `用戶端安全`,
  [`Client Type`]: `用戶端類型`,
  [`Client authentication method`]: `用戶端鑑別方法`,
  [`Clone`]: `複製`,
  [`Close`]: `關閉`,
  [`Cloud`]: `雲端`,
  [`Cloud Admin`]: `雲端管理者`,
  [`Cloud Administrator`]: `雲端管理者`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `雲端管理者、組織管理員、拓蹼管理者。「成員」角色會自動指派給所有使用者。`,
  [`Cloud administration`]: `雲端管理`,
  [`Cloud Discovery Agent`]: `雲端探索代理程式`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Cloud Manager 本端使用者登錄`,
  [`Cloud-Settings`]: `Cloud 設定`,
  [`Cloud settings invitation timeout`]: `雲端設定的邀請逾時值`,
  [`Cloud settings invitation timeout has been changed`]: `雲端設定的邀請逾時值已變更`,
  [`Code editor`]: `程式碼編輯器`,
  [`Collect Metadata`]: `收集 meta 資料`,
  [`Collect credentials using`]: `收集認證方式`,
  [`Collect metadata`]: `收集 meta 資料`,
  [`Combo box`]: `組合框`,
  [`Community Manager`]: `社群管理員`,
  [`community-manager`]: `社群管理員`,
  [`Compatibility`]: `相容性`,
  [`Complex Definitions must be edited in the editor`]: `必須在編輯器中編輯複式定義`,
  [`Compose DN`]: `編寫 DN`,
  [`Compose UPN`]: `編寫 UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `透過定義路徑和作業來編寫新的 REST Proxy`,
  [`Compose a new product by adding rate limits and plans`]: `透過新增速率限制和方案來編製新產品`,
  [`Compression`]: `壓縮`,
  [`Condition`]: `條件`,
  [`Condition editor`]: `條件編輯器`,
  [`Confidential`]: `機密`,
  [`Configuration`]: `配置`,
  [`Configurations`]: `配置`,
  [`Configure API endpoint`]: `配置 API 端點`,
  [`Configure API Gateway Service`]: `配置 API Gateway 服務`,
  [`Configure analytics service`]: `配置分析服務`,
  [`Configure availability zone`]: `配置可用性區域`,
  [`Configure catalog user registries`]: `配置型錄使用者登錄`,
  [`Configure cloud`]: `配置雲端`,
  [`Configure DataPower API gateway service`]: `配置 DataPower API Gateway 服務`,
  [`Configure DataPower gateway service`]: `配置 DataPower Gateway 服務`,
  [`Configure Email Server`]: `配置電子郵件伺服器`,
  [`Configure gateway extension`]: `配置閘道延伸`,
  [`Configure portal service`]: `配置入口網站服務`,
  [`Configure service`]: `配置服務`,
  [`Configure topology`]: `配置拓蹼`,
  [`Configure XML`]: `配置 XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `配置 DataPower API Gateway 服務以保護和強制執行 API`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `配置 DataPower Gateway 服務以保護和強制執行 API`,
  [`Configure a developer portal service for API consumers`]: `為 API 消費者配置 Developer Portal 服務`,
  [`Configure advanced features for OIDC settings`]: `配置 OIDC 設定的進階特性`,
  [`Configure an analytics service to collect API call data`]: `配置分析服務以收集 API 呼叫資料`,
  [`Configure an email server to send invitations and notifications to users.`]: `配置電子郵件伺服器以傳送邀請和通知給使用者。`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `配置審核以監視管理使用者介面、管理程式使用者介面、工具箱、管理 REST API 及入口網站使用者的作業。 每一個建立、更新及刪除使用者作業的日誌都會傳送至下方指定的遠端記載服務。`,
  [`Configure availability zones and services`]: `配置可用性區域及服務`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `配置如何在發佈的 API 中編寫 API 端點 URL`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `配置虛名端點如何顯示在 Developer Portal 中`,
  [`Configure mapping`]: `配置對映`,
  [`Configure properties of the activity log`]: `配置活動日誌的內容`,
  [`Configure settings for token management and revocation.`]: `配置用於記號管理和撤銷的設定。`,
  [`Configure settings to generate and validate tokens.`]: `配置設定以產生和驗證記號。`,
  [`Configure the API security`]: `配置 API 安全`,
  [`Configure the default set of gateway services configured for each catalog`]: `配置已針對每個型錄進行配置的預設閘道服務集`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `配置應用程式開發者用來存取此型錄中 API 的 Developer Portal`,
  [`Configure the gateway services used by default in each catalog`]: `配置每個型錄中依預設使用的閘道服務`,
  [`Configure the gateway extension for the selected gateway`]: `配置所選取閘道的閘道延伸`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `配置由 IBM 管理的 DataPower API Gateway，並管理您自己的遠端閘道。[進一步瞭解]({link})`,
  [`Configure the keystore and upload certificates`]: `配置金鑰儲存庫及上傳憑證`,
  [`Configure the name and email address to be used in the from field of emails`]: `配置要在電子郵件寄件者欄位中使用的名稱和電子郵件位址`,
  [`Configure the portal service for the catalog`]: `配置型錄的入口網站服務`,
  [`Configure the role and assign permissions`]: `配置角色並指派權限`,
  [`Configure the security of this API`]: `配置此 API 的安全`,
  [`Configure the sender name and address to use for email notifications`]: `配置要用於電子郵件通知的寄件者名稱和位址`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `配置用於將邀請和通知傳送給使用者的寄件者名稱、位址和電子郵件伺服器。`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `配置在建立消費者組織時依預設使用的角色集`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `配置在建立提供者組織時依預設使用的角色集`,
  [`Configure the templates used to invite and notify users`]: `配置用於邀請和通知使用者的範本`,
  [`Configure the truststore and upload certificates`]: `配置信任儲存庫及上傳憑證`,
  [`Configure user authentication using`]: `配置使用者鑑別，使用：`,
  [`Configure user authentication using JSON Web Tokens`]: `使用 JSON Web 記號配置使用者鑑別`,
  [`Configure user authentication using a LDAP provider`]: `使用 LDAP 提供者來配置使用者鑑別`,
  [`Configure user authentication using an API Connect Local User Registry`]: `使用 API Connect 本端使用者登錄來配置使用者鑑別`,
  [`Configure user authentication using an authentication URL`]: `使用鑑別 URL 來配置使用者鑑別`,
  [`Configure user authentication using this user registry type`]: `使用此使用者登錄類型來配置使用者鑑別`,
  [`Configure user registries, OAuth providers and TLS`]: `配置使用者登錄、OAuth 提供者和 TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `配置使用者登錄、TLS、OAuth 提供者及電子郵件伺服器`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `配置存取記號、ID 記號及暫時記號的金鑰儲存庫`,
  [`Configured OAuth Provider`]: `所配置的 OAuth 提供者`,
  [`Configured OAuth Providers`]: `所配置的 OAuth 提供者`,
  [`Configured OAuth provider`]: `所配置的 OAuth 提供者`,
  [`Configured OAuth provider list has been updated`]: `已更新配置的 OAuth 提供者清單`,
  [`Configured OAuth provider list has not been updated`]: `所配置的 OAuth 提供者清單未更新`,
  [`Confirm`]: `確認`,
  [`Confirm deletion`]: `確認刪除`,
  [`Confirm Deletion of Portal`]: `確認刪除入口網站`,
  [`Confirm visibility settings`]: `確認可見性設定`,
  [`Confirm new password`]: `確認新密碼`,
  [`Confirm password`]: `確認密碼`,
  [`Confirm password should match password`]: `確認密碼應該與密碼相符`,
  [`Confirmation`]: `確認`,
  [`Congratulations, you are now registered.`]: `恭喜，您現在已登錄。`,
  [`Congratulations, your password has been reset!`]: `恭喜，您的密碼已重設！`,
  [`Connect`]: `連接`,
  [`Connect to API Connect`]: `連接至 API Connect`,
  [`Connected to API Manager Product version`]: `已連接至 API Manager 產品版本`,
  [`Connect to Cloud`]: `連接至雲端`,
  [`Connected`]: `已連接`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `新增必要類型的內容來建構綱目定義。您可以建立巢狀內容`,
  [`Consider for nesting`]: `巢狀的考量`,
  [`Consumed Media Types`]: `耗用的媒體類型`,
  [`Consumer`]: `消費者`,
  [`Consumer invitation and roles`]: `消費者邀請及角色`,
  [`Consumer onboarding`]: `消費者上線`,
  [`Consumer organizations`]: `消費者組織`,
  [`Consumer organizations or groups`]: `消費者組織或群組`,
  [`Consumer organization {name} created`]: `已建立消費者組織 {name}`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `消費者組織是由應用程式開發者在 Developer Portal 中建立，您也可以使用 API Manager UI 來建立消費者組織`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `消費者組織是由應用程式開發者在 Developer Portal 上建立，您也可以在這裡建立消費者組織`,
  [`Consumer-Org`]: `消費者組織`,
  [`Consumers`]: `消費者`,
  [`Consumes`]: `耗用`,
  [`Contact`]: `聯絡`,
  [`Contact information for the owners of the API.`]: `API 擁有者的聯絡資訊。`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `請聯絡您的管理者，瞭解如何配置分析服務以便能在這裡檢視您的資料。`,
  [`Contact your administrator for more information.`]: `請聯絡您的管理者，取得相關資訊。`,
  [`Content`]: `內容`,
  [`Content on error`]: `錯誤時的內容`,
  [`Content type`]: `內容類型`,
  [`Context Variable`]: `環境定義變數`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `含有要驗證之 JWT 的環境定義或執行時期變數。如果未設定，則原則會在 request.headers.authorization 中尋找 JWT。`,
  [`Context variable`]: `環境定義變數`,
  [`Continue`]: `繼續`,
  [`Continue on error`]: `發生錯誤時繼續`,
  [`Continue with`]: `繼續`,
  [`Continue with:`]: `繼續使用：`,
  [`Conversion type`]: `轉換類型`,
  [`Convert to expression`]: `轉換成表示式`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `已複製`,
  [`Copied to clipboard`]: `已複製到剪貼簿`,
  [`Copy`]: `複製`,
  [`Copy Id Headers To Message`]: `將 ID 標頭複製到訊息`,
  [`Copy to clipboard`]: `複製目標到剪貼簿`,
  [`Copy file path to clipboard`]: `將檔案路徑複製到剪貼簿`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `將 GraphQL 查詢的成本預估複製到輸出`,
  [`Copy the endpoints below to`]: `將下方的端點複製至`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `複製要使用的 API 金鑰。 您可以使用「檢視」圖示再次查看此 API 金鑰。`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `著作權資訊`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `在上面的編輯器中更正錯誤，或者重新上傳有效檔案。`,
  [`Cost`]: `成本`,
  [`Count`]: `計數`,
  [`Count Limit Name`]: `計數限制名稱`,
  [`Count Limits`]: `計數限制`,
  [`Created at`]: `建立於`,
  [`Counter`]: `計數器`,
  [`Create`]: `建立`,
  [`Create a [Certificate Manager]({link}) service`]: `建立 [憑證管理程式]({link}) 服務`,
  [`Create API`]: `建立 API`,
  [`Create an API from the Develop section.`]: `從「開發」區段建立 API。`,
  [`Create API Connect API Key`]: `建立 API Connect API 金鑰`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `從現有 GraphQL 服務 (GraphQL Proxy) 建立 API`,
  [`Create API from existing OpenAPI service`]: `從現有 OpenAPI 服務建立 API`,
  [`Create API from existing WSDL service (REST proxy)`]: `從現有 WSDL 服務建立 API (REST Proxy)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `從現有 WSDL 服務建立 API (SOAP Proxy)`,
  [`Create API from target service`]: `從目標服務建立 API`,
  [`Create API from existing REST service`]: `從現有 REST 服務建立 API`,
  [`Create API from existing SOAP service`]: `從現有 SOAP 服務建立 API`,
  [`Create API from existing WSDL service`]: `從現有 WSDL 服務建立 API`,
  [`Create API Key failed`]: `建立 API 金鑰失敗`,
  [`Create application`]: `建立應用程式`,
  [`Create authentication URL user registry`]: `建立鑑別 URL 使用者登錄`,
  [`Create availability zone`]: `建立可用性區域`,
  [`Create Billing`]: `建立帳單`,
  [`Create catalog`]: `建立型錄`,
  [`Create catalog property`]: `建立型錄內容`,
  [`Create consumer organization`]: `建立消費者組織`,
  [`Create Credentials`]: `建立認證`,
  [`Create definition`]: `建立定義`,
  [`Create email server`]: `建立電子郵件伺服器`,
  [`Create IBM Developer Portal`]: `建立 IBM Developer Portal`,
  [`Create keystore`]: `建立金鑰儲存庫`,
  [`Create LDAP user registry`]: `建立 LDAP 使用者登錄`,
  [`Create LTPA Key`]: `建立 LTPA 金鑰`,
  [`Create local user registry`]: `建立本端使用者登錄`,
  [`Create native OAuth provider`]: `建立原生 OAuth 提供者`,
  [`Create New GraphQL API`]: `建立新 GraphQL API`,
  [`Create new OpenAPI`]: `建立新的 OpenAPI`,
  [`Create new product`]: `建立新的產品`,
  [`Create OIDC user registry`]: `建立 OIDC 使用者登錄`,
  [`Create OpenAPI definition and product definition`]: `建立 OpenAPI 產品和產品定義`,
  [`Create operation`]: `建立作業`,
  [`Create organization`]: `建立組織`,
  [`Create path`]: `建立路徑`,
  [`Create plan`]: `建立方案`,
  [`Create portal`]: `建立入口網站`,
  [`Create Product`]: `建立產品`,
  [`Create property`]: `建立內容`,
  [`Create role`]: `建立角色`,
  [`Create role default for the Organization`]: `建立組織的角色預設值`,
  [`Create sample user registry`]: `建立範例使用者登錄`,
  [`Create security definition`]: `建立安全定義`,
  [`Create space`]: `建立空間`,
  [`Create subscription`]: `建立訂閱`,
  [`Create TLS client profile`]: `建立 TLS 用戶端設定檔`,
  [`Create TLS server profile`]: `建立 TLS 伺服器設定檔`,
  [`Create target service`]: `建立目標服務`,
  [`Create third party OAuth provider`]: `建立協力廠商 OAuth 提供者`,
  [`Create truststore`]: `建立信任儲存庫`,
  [`Create user registry`]: `建立使用者登錄`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `根據 GraphQL 服務建立 GraphQL Proxy`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `根據 OpenAPI 說明的目標服務建立 REST Proxy`,
  [`Create a REST proxy based upon a WSDL described target service`]: `根據 WSDL 說明的目標服務建立 REST Proxy`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `根據 OpenAPI 說明的目標服務建立 REST Proxy`,
  [`Create a REST proxy based upon the WSDL described target service`]: `根據 WSDL 說明的目標服務建立 REST Proxy`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `建立 REST Proxy 以將所有資料流量路由至目標 API 或服務端點`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `根據 WSDL 說明的目標服務建立 SOAP Proxy`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `根據 WSDL 說明的目標服務建立 SOAP Proxy`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `使用 Handlebar 範本建立新物件。變數取自環境定義。`,
  [`Create a portal site for the catalog`]: `為型錄建立入口網站`,
  [`Create a product by importing a product definition`]: `透過匯入產品定義來建立產品`,
  [`Create a security definition`]: `建立安全定義`,
  [`Create a subscription`]: `建立訂閱`,
  [`Create an API by importing an OpenAPI definition`]: `透過匯入 OpenAPI 定義來建立 API`,
  [`Create an API that calls an existing SOAP service`]: `建立用於呼叫現有 SOAP 服務的 API`,
  [`Create and manage API provider organizations and owners`]: `建立及管理 API 提供者組織及擁有者`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `建立及管理型錄中的空間；空間可讓您將型錄進行分割，以支援不同的 API 提供者開發團隊`,
  [`Create and publish`]: `建立及發佈`,
  [`Create and publish APIs and Products`]: `建立及發佈 API 和產品`,
  [`Create and subscribe`]: `建立及訂閱`,
  [`Create assembly`]: `建立組件`,
  [`Create empty parent object for failed mapping`]: `為失敗的對映建立空白母項物件`,
  [`Create endpoint`]: `建立端點`,
  [`Create from`]: `建立來源`,
  [`Create from Existing WSDL service`]: `從現有 WSDL 服務建立`,
  [`Create from File or URL`]: `從檔案或 URL 建立`,
  [`Create from existing openAPI service`]: `從現有 openAPI 服務建立`,
  [`Create gateway extension`]: `建立閘道延伸`,
  [`Create new user`]: `建立新使用者`,
  [`Create new version`]: `建立新的版本`,
  [`Create product using a template`]: `使用範本建立產品`,
  [`Create provider organization`]: `建立提供者組織`,
  [`Create required child properties for array objects and mapped optional objects`]: `為陣列物件及被對映的選用物件建立必要的子項內容`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `由 OAuth 提供者配置作為範例建立。確保使用此範例和有效使用者登錄更新 OAuth 提供者。`,
  [`Created new product`]: `已建立新的產品`,
  [`Create|permission`]: `建立`,
  [`Create|title`]: `建立`,
  [`Creating Draft Product`]: `正在建立草稿產品`,
  [`Creating a Consumer organization`]: `建立消費者組織`,
  [`Creating a consumer organization`]: `建立消費者組織`,
  [`Creating a new Product`]: `建立新產品`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons (CC-BY-4.0) 授權`,
  [`Credential Extraction Method`]: `認證擷取方法`,
  [`Credentials for the Sandbox catalog`]: `「沙箱」型錄的認證`,
  [`Credentials for the {catalogName} catalog`]: `{catalogName} 型錄的認證`,
  [`Credential Name`]: `認證名稱`,
  [`Credential has been added.`]: `已新增認證。`,
  [`Credential`]: `認證`,
  [`Credentials`]: `認證`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `需要認證，才能執行 API Designer 和工具箱。`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `需要認證才能執行工具箱。請將認證檔放在工具箱的安裝資料夾中。`,
  [`Credit Card`]: `信用卡`,
  [`Cryptographic Algorithm`]: `加密演算法`,
  [`Currency`]: `貨幣`,
  [`Current password`]: `現行密碼`,
  [`Current password is invalid.`]: `現行密碼無效。`,
  [`Custom`]: `自訂`,
  [`Custom API URL`]: `自訂 API URL`,
  [`Custom expression (optional, one per line)`]: `自訂表示式（選用，一行一個）`,
  [`Custom expression to remove (optional, one per line)`]: `要移除的自訂表示式（選用，一行一個）`,
  [`Custom HTML form`]: `自訂 HTML 表單`,
  [`Custom policies`]: `自訂原則`,
  [`Custom form TLS profile`]: `自訂表單 TLS 設定檔`,
  [`Custom form content security policy`]: `自訂表單內容安全原則`,
  [`Custom form CSP header value`]: `自訂表單 CSP 標頭值`,
  [`Custom form endpoint`]: `自訂表單端點`,
  [`Custom header pattern`]: `自訂標頭型樣`,
  [`Custom subscribability is required if custom visibility is selected`]: `如果選取了自訂可見性，則需要自訂訂閱功能`,
  [`Customize`]: `自訂`,
  [`Customize email sender information`]: `自訂電子郵件寄件者資訊`,
  [`Customize notification templates`]: `自訂通知範本`,
  [`Customize the plan API list`]: `自訂方案 API 清單`,
  [`Czech`]: `捷克文`,
  [`DATE MODIFIED`]: `修改日期`,
  [`DELETE`]: `刪除`,
  [`Date created`]: `建立日期`,
  [`Description`]: `說明`,
  [`DETAIL`]: `詳細資料`,
  [`DN Base`]: `基本 DN`,
  [`DNS Schemes`]: `DNS 架構`,
  [`Dashboard`]: `儀表板`,
  [`Data encoded form body`]: `資料編碼表單主體`,
  [`Data flow in API Connect`]: `API Connect 中的資料流程`,
  [`DataPower (v5 compatible)`]: `DataPower（與第 5 版相容）`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway（與第 5 版相容）`,
  [`DataPower Gateway policies`]: `DataPower Gateway 原則`,
  [`Debug`]: `除錯`,
  [`Debug rule`]: `除錯規則`,
  [`Debug XACML policy`]: `除錯 XACML 原則`,
  [`Dec`]: `十二月`,
  [`Decline`]: `拒絕`,
  [`Decline and send`]: `拒絕並傳送`,
  [`Decode Request Params`]: `解碼要求參數`,
  [`Decrement`]: `減量`,
  [`Decrypt`]: `解密`,
  [`Decrypt Crypto JWK variable name`]: `解密加密 JWK 變數名稱`,
  [`Decrypt Crypto Object`]: `解密加密物件`,
  [`Decrypt a buffer using the specified certificate`]: `使用指定的憑證來解密緩衝區`,
  [`Default`]: `預設`,
  [`Default behavior`]: `預設行為`,
  [`Default gateways`]: `預設閘道`,
  [`default gateway services configured`]: `已配置預設閘道服務`,
  [`Default Availability Zone`]: `預設可用性區域`,
  [`Default Base endpoints`]: `預設基本端點`,
  [`Default availability zone`]: `預設可用性區域`,
  [`Default base endpoints`]: `預設基本端點`,
  [`Default HTML form`]: `預設 HTML 表單`,
  [`Default limit`]: `預設限制`,
  [`Default OAuth Provider Settings`]: `預設 OAuth 提供者設定`,
  [`Default OAuth Provider Settings Object`]: `預設 OAuth 提供者設定物件`,
  [`Default Plan`]: `預設方案`,
  [`Default TLS client profile`]: `預設 TLS 用戶端設定檔`,
  [`Default TLS server keystore`]: `預設 TLS 伺服器金鑰儲存庫`,
  [`Default TLS server profile`]: `預設 TLS 伺服器設定檔`,
  [`Default burst-limit`]: `預設高載限制`,
  [`Default form`]: `預設表單`,
  [`Default keystore for access_token signing key`]: `存取記號簽署金鑰的預設金鑰儲存庫`,
  [`Default keystore for id_token signing key`]: `ID 記號簽署金鑰的預設金鑰儲存庫`,
  [`Default keystore for temporary token signing key`]: `暫時記號簽署金鑰的預設金鑰儲存庫`,
  [`Default rate-limit`]: `預設速率限制`,
  [`Default scopes`]: `預設範圍`,
  [`Default table entry`]: `預設表格項目`,
  [`Default validator endpoint`]: `預設驗證器端點`,
  [`Default validator endpoint set by OAuth provider`]: `OAuth 提供者設定的預設驗證器端點`,
  [`Default value - no extra constraints apply.`]: `預設值 - 不套用額外限制。`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `定義協力廠商 OAuth 提供者以發出和驗證記號來保護對 API 的存取權。`,
  [`Define catalog specific values for this property`]: `為此內容定義型錄特定值`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `定義群組，以管理 API、產品、型錄、應用程式和相關的設定。`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `定義針對特定條件而執行的原則集。可以定義多個案例，且每個案例可以與任意條件相關聯。只有第一個相符的案例會執行。`,
  [`Define the API protection source. At least one option must be enabled.`]: `定義 API 保護來源。必須啟用至少一個選項。`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `OIDC 授權伺服器會將授權碼重新導向至下列端點。當客戶在 OIDC 提供者上登錄應用程式時，需要這個重新導向端點。例如，若有提供者組織使用這項使用者登錄，則客戶必須向 OIDC 提供者登錄該提供者組織的重新導向端點。`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `定義用於對映的輸入。您也可以將標題和說明指派給對映。`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `定義 OpenID 提供者端點以傳送鑑別要求至您的 OpenID 提供者`,
  [`Define the outputs to be used in the map.`]: `定義用於對映的輸出。`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `定義免費試用天數和計價方案。將根據使用者的訂閱日期向使用者收費。`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `定義在原則執行期間，該流程是否在擲出錯誤時繼續執行。如果未選取，則會觸發捕捉流程。`,
  [`Definition`]: `定義`,
  [`Definition Object`]: `定義物件`,
  [`Definitions`]: `定義`,
  [`Delete`]: `刪除`,
  [`Delete Application`]: `刪除應用程式`,
  [`Delete Cloud Connection.`]: `刪除雲端連線。`,
  [`Delete Selected APIs`]: `刪除選取的 API`,
  [`Delete selected client profiles`]: `刪除選取的用戶端設定檔`,
  [`Delete selected keystores`]: `刪除選取的金鑰儲存庫`,
  [`Delete selected mail servers`]: `刪除選取的郵件伺服器`,
  [`Delete Selected Products`]: `刪除選取的產品`,
  [`Delete selected truststores`]: `刪除選取的信任儲存庫`,
  [`Delete selected user registries`]: `刪除選取的使用者登錄`,
  [`Delete Staged Product`]: `刪除暫置產品`,
  [`Delete a space`]: `刪除空間`,
  [`Delete a catalog`]: `刪除型錄`,
  [`Delete API Key`]: `刪除 API 金鑰`,
  [`Delete API Key failed`]: `刪除 API 金鑰失敗`,
  [`Delete availability zone`]: `刪除可用性區域`,
  [`Delete selected requests`]: `刪除選取的要求`,
  [`Delete row`]: `刪除列`,
  [`Delete|title`]: `刪除`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `如果您有大量消費者組織、應用程式、已發佈的產品及其他資源，則刪除型錄會失敗。刪除型錄前請考量清除這些資源。`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `如果您有大量型錄、消費者組織、應用程式、已發佈的產品及其他資源，則刪除提供者組織會失敗。刪除提供者組織之前，請考量清理這些資源。`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `如果您有大量消費者組織、應用程式、已發佈的產品及其他資源，則刪除空間會失敗。刪除空間前請考量清除這些資源。`,
  [`Deleting {{name}}...`]: `正在刪除 {name}...`,
  [`Deprecate`]: `淘汰`,
  [`Deprecate product`]: `淘汰產品`,
  [`Deprecated`]: `已淘汰`,
  [`Deprecate|permission`]: `淘汰`,
  [`Description of gateway`]: `閘道的說明`,
  [`Description text for the behaviour of the modal.`]: `限制模式行為的說明文字。`,
  [`Descriptive name`]: `敘述名稱`,
  [`Design`]: `設計`,
  [`Designate a current member as owner of a consumer organization.`]: `將現行成員指定為消費者組織的擁有者。`,
  [`Designate a current member as owner of a provider organization.`]: `將現行成員指定為提供者組織的擁有者。`,
  [`Designer`]: `Designer`,
  [`Destination`]: `目的地`,
  [`Determines which credential-extraction method to use.`]: `判斷要使用的認證擷取方法。`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `判斷是否在綱目中包含此類型/欄位及任何適用的引數。`,
  [`Develop`]: `開發`,
  [`Develop APIs`]: `開發 API`,
  [`Develop APIs and products`]: `開發 API 和產品`,
  [`Developer`]: `開發者`,
  [`developer`]: `開發人員`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `開發人員社群`,
  [`Developers can request a state upgrade from development to production`]: `開發者可以要求將狀態從開發升級至正式作業`,
  [`Development`]: `開發`,
  [`Directive`]: `指引`,
  [`Director Endpoint`]: `引導器端點`,
  [`Director TLS client profile`]: `Director TLS 用戶端設定檔`,
  [`Disable`]: `停用`,
  [`Disable Activity log`]: `停用活動日誌`,
  [`Disable Self-Service Onboarding`]: `停用自助式上線`,
  [`Disable spaces`]: `停用空間`,
  [`Disable buffering`]: `停用緩衝`,
  [`Disable Self-Service Onboarding Approval`]: `停用自助上線核准`,
  [`Disable overriding consumer organizations invitation timeout.`]: `停用置換消費者組織邀請逾時值。`,
  [`Disabled`]: `已停用`,
  [`disabled`]: `已停用`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `停用自助式上線將需要 API 提供者、現有消費者組織擁有者或管理者邀請所有應用程式開發者。`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `停用自助式上線核准將自動核准所有上線要求。`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `停用不會將消費者組織邀請逾時值設為型錄逾時值。`,
  [`Discover targets`]: `探索目標`,
  [`Display table checkboxes`]: `顯示表格勾選框`,
  [`Display vanity endpoint`]: `顯示虛名端點`,
  [`Do not publish API (continue editting)`]: `不要發佈 API（繼續編輯）`,
  [`Do not require applications or users to authenticate`]: `不需要應用程式或使用者進行鑑別`,
  [`Do not require authentication`]: `不需要鑑別`,
  [`Do not share the resource with provider organizations`]: `不要與提供者組織共用資源`,
  [`Do not share the API gateway with provider organizations`]: `不要與提供者組織共用 API Gateway`,
  [`Do not share the service with provider organizations`]: `不要與提供者組織共用服務`,
  [`Do not use Dynamic DNS`]: `不使用動態 DNS`,
  [`Do you want to remove these references?`]: `您要移除這些參照嗎？`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker 文件`,
  [`Docs`]: `文件`,
  [`Document Editor`]: `文件編輯器`,
  [`Documentation and tutorials with step-by-step instructions`]: `含有逐步指示的文件和指導教學`,
  [`Domain name`]: `網域名稱`,
  [`Domain Name`]: `網域名稱`,
  [`Domains handled by gateway via SNI`]: `閘道經由 SNI 來處理的網域`,
  [`Don't have an account?`]: `沒有帳戶嗎？`,
  [`Done`]: `完成`,
  [`Download`]: `下載`,
  [`Download Gateway`]: `下載閘道`,
  [`Download and install`]: `下載並安裝`,
  [`Download and set up DataPower API Gateway`]: `下載並設定 DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `下載並設定 DataPower API Gateway，以用於內部部署或任何雲端上。`,
  [`Download for Linux`]: `適用於 Linux 的下載`,
  [`Download for Mac`]: `適用於 Mac 的下載`,
  [`Download for Windows`]: `適用於 Windows 的下載`,
  [`Download gateway`]: `下載閘道`,
  [`Download schema`]: `下載綱目`,
  [`Download Toolkit`]: `下載工具箱`,
  [`Download toolkit`]: `下載工具箱`,
  [`Download toolkit and credentials for various platforms`]: `下載工具箱和各種平台的認證`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `找不到所下載的認證來進行此雲端連線。登入將使用預設認證。`,
  [`Dutch`]: `荷蘭文`,
  [`Draft {type} creation failed`]: `建立草稿 {type} 失敗`,
  [`Drafts`]: `草稿`,
  [`Drag and drop files here or click to upload`]: `將檔案拖放到這裡或按一下以上傳`,
  [`Drag & Drop`]: `拖放`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `動態 DNS`,
  [`Dynamic OAuth configuration from a URL`]: `來自 URL 的動態 OAuth 配置`,
  [`Dynamic OAuth configuration from a literal string`]: `來自文字字串的動態 OAuth 配置`,
  [`Dynamic group`]: `動態群組`,
  [`Dynamic table entries`]: `動態表格項目`,
  [`ENABLE`]: `啟用`,
  [`Encoded`]: `已編碼`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `每個空間由不同的 API 提供者開發團隊使用，而且具有專屬的管理功能集，這些功能專門與相關聯的團隊發佈至該空間的 API 相關，可讓每個團隊獨立管理其 API。將 API 暫置或發佈至已啟用空間的型錄時，您可以在該型錄中指定您想要暫置或發佈至的空間。`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `每一個提供者組織都擁有一組 API、產品、方案和型錄。`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `輕鬆建立、保護、管理、共用及分析位於雲端和內部部署上的 API。`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `每一個提供者組織都擁有一組 API、產品、方案和型錄。[進一步瞭解]({link})`,
  [`Edit`]: `編輯`,
  [`Edit access token TTL`]: `編輯存取記號 TTL`,
  [`Edit admin organization invitation timeout`]: `編輯管理者組織的邀請逾時值`,
  [`Edit API`]: `編輯 API`,
  [`Edit API Connect UI`]: `編輯 API Connect UI`,
  [`Edit API endpoint for unenforced APIs`]: `編輯未強制執行 API 的 API 端點`,
  [`Edit API gateway service`]: `編輯 API Gateway 服務`,
  [`Edit API Path`]: `編輯 API 路徑`,
  [`Edit API security definition`]: `編輯 API 安全定義`,
  [`Edit API user registries`]: `編輯 API 使用者登錄`,
  [`Edit analytics service`]: `編輯分析服務`,
  [`Edit application`]: `編輯應用程式`,
  [`Edit Assembly`]: `編輯組件`,
  [`Edit authentication URL user registry`]: `編輯鑑別 URL 使用者登錄`,
  [`Edit availability zone`]: `編輯可用性區域`,
  [`Edit Availablity Zone`]: `編輯可用性區域`,
  [`Edit and apply constraints to your selected items.`]: `編輯限制項並套用至您選取的項目。`,
  [`Edit billing integration`]: `編輯計費整合`,
  [`Edit Catalog Details`]: `編輯型錄詳細資料`,
  [`Edit cloud setting invitation timeout`]: `編輯雲端設定的邀請逾時值`,
  [`Edit constraints`]: `編輯限制項`,
  [`Edit consumer organization`]: `編輯消費者組織`,
  [`Edit DNS Scheme`]: `編輯 DNS 架構`,
  [`Edit DataPower API gateway service`]: `編輯 DataPower API Gateway 服務`,
  [`Edit DataPower gateway service`]: `編輯 DataPower Gateway 服務`,
  [`Edit definition`]: `編輯定義`,
  [`Edit dynamic DNS`]: `編輯動態 DNS`,
  [`Edit email server`]: `編輯電子郵件伺服器`,
  [`Edit extensions`]: `編輯延伸`,
  [`Edit Gateway Service`]: `編輯閘道服務`,
  [`Edit gateway`]: `編輯閘道`,
  [`Edit gateway extension`]: `編輯閘道延伸`,
  [`Edit Gateways`]: `編輯閘道`,
  [`Edit IBM-managed gateway`]: `編輯 IBM 管理的閘道`,
  [`Edit Invitation Timeout`]: `編輯邀請逾時`,
  [`Edit gateways`]: `編輯閘道`,
  [`Edit invitation timeout`]: `編輯邀請逾時`,
  [`Edit JSON`]: `編輯 JSON`,
  [`Edit keystore`]: `編輯金鑰儲存庫`,
  [`Edit LDAP user registry`]: `編輯 LDAP 使用者登錄`,
  [`Edit LTPA Token`]: `編輯 LTPA 記號`,
  [`Edit lifecycle approvals`]: `編輯生命週期核准`,
  [`Edit local user registry`]: `編輯本端使用者登錄`,
  [`Edit native OAuth provider`]: `編輯原生 OAuth 提供者`,
  [`Edit New Billing System`]: `編輯新的計費系統`,
  [`Edit Notification Server`]: `編輯通知伺服器`,
  [`Edit Notification template`]: `編輯通知範本`,
  [`Edit OAuth Provider`]: `編輯 OAuth 提供者`,
  [`Edit OAuth provider details`]: `編輯 OAuth 提供者詳細資料`,
  [`Edit OAuth Provider Visibility`]: `編輯 OAuth 提供者可見性`,
  [`Edit OIDC User Registry`]: `編輯 OIDC 使用者登錄`,
  [`Edit Onboarding`]: `編輯上線`,
  [`Edit Operation`]: `編輯作業`,
  [`Edit Organization`]: `編輯組織`,
  [`Edit Path`]: `編輯路徑`,
  [`Edit Path Parameters`]: `編輯路徑參數`,
  [`Edit notification template`]: `編輯通知範本`,
  [`Edit OAuth provider`]: `編輯 OAuth 提供者`,
  [`Edit OAuth provider visibility`]: `編輯 OAuth 提供者可見性`,
  [`Edit OIDC user registry`]: `編輯 OIDC 使用者登錄`,
  [`Edit onboarding`]: `編輯上線`,
  [`Edit operation`]: `編輯作業`,
  [`Edit organization`]: `編輯組織`,
  [`Edit path`]: `編輯路徑`,
  [`Edit path parameters`]: `編輯路徑參數`,
  [`Edit plan`]: `編輯方案`,
  [`Edit portal`]: `編輯入口網站`,
  [`Edit portal service`]: `編輯入口網站服務`,
  [`Edit Portal Settings`]: `編輯入口網站設定`,
  [`Edit product`]: `編輯產品`,
  [`Edit product APIs`]: `編輯產品 API`,
  [`Edit Product Subscribability`]: `編輯產品訂閱功能`,
  [`Edit Product Visibility`]: `編輯產品可見性`,
  [`Edit Property`]: `編輯內容`,
  [`Edit Provider Organization`]: `編輯提供者組織`,
  [`Edit Refresh Token TTL`]: `編輯重新整理記號 TTL`,
  [`Edit remote gateway`]: `編輯遠端閘道`,
  [`Edit Reset Password TTL`]: `編輯重設密碼 TTL`,
  [`Edit Role`]: `編輯角色`,
  [`Edit Role Default for Provider Organizations`]: `編輯提供者組織的角色預設值`,
  [`Edit Role for Provider Organizations`]: `編輯提供者組織的角色`,
  [`Edit property`]: `編輯內容`,
  [`Edit provider organization`]: `編輯提供者組織`,
  [`Edit refresh token TTL`]: `編輯重新整理記號 TTL`,
  [`Edit reset password TTL`]: `編輯重設密碼 TTL`,
  [`Edit role`]: `編輯角色`,
  [`Edit role default for Provider Organizations`]: `編輯提供者組織的角色預設值`,
  [`Edit role for Provider Organizations`]: `編輯提供者組織的角色`,
  [`Edit Roles`]: `編輯角色`,
  [`Edit Schema in JSON/XML`]: `編輯 JSON/XML 格式的綱目`,
  [`Edit security definition`]: `編輯安全定義`,
  [`Edit sender`]: `編輯寄件者`,
  [`Edit sender & email server`]: `編輯寄件者和電子郵件伺服器`,
  [`Edit sender Info`]: `編輯寄件者資訊`,
  [`Edit service visibility`]: `編輯服務可見性`,
  [`Edit show/hide settings`]: `編輯顯示/隱藏設定`,
  [`Edit space details`]: `編輯空間詳細資料`,
  [`Edit TLS client profile`]: `編輯 TLS 用戶端設定檔`,
  [`Edit TLS client profile visibility`]: `編輯 TLS 用戶端設定檔可見性`,
  [`Edit TLS server profile`]: `編輯 TLS 伺服器設定檔`,
  [`Edit third party OAuth provider`]: `編輯協力廠商 OAuth 提供者`,
  [`Edit truststore`]: `編輯信任儲存庫`,
  [`Edit unenforced API`]: `編輯未施行的 API`,
  [`Edit user registries`]: `編輯使用者登錄`,
  [`Edit user registry`]: `編輯使用者登錄`,
  [`Edit user registry visibility`]: `編輯使用者登錄可見性`,
  [`Edit user registry for API Manager`]: `為 API Manager 編輯使用者登錄`,
  [`Edit user registry for Cloud Manager`]: `編輯 Cloud Manager 的使用者登錄`,
  [`Edit vanity API endpoints`]: `編輯虛名 API 端點`,
  [`Edit visibility`]: `編輯可見性`,
  [`Edit XML`]: `編輯 XML`,
  [`Edit app lifecycle approved`]: `編輯應用程式生命週期已核准`,
  [`Edit app lifecycle cancelled`]: `編輯應用程式生命週期已取消`,
  [`Edit app lifecycle denied`]: `編輯應用程式生命週期已拒絕`,
  [`Edit app lifecycle pending`]: `編輯應用程式生命週期擱置中`,
  [`Edit app lifecycle request`]: `編輯應用程式生命週期要求`,
  [`Edit app reinstated`]: `編輯應用程式已恢復`,
  [`Edit app suspended`]: `編輯應用程式已暫停`,
  [`Edit audit setting`]: `編輯審核設定`,
  [`Edit definitions`]: `編輯定義`,
  [`Enable external group mapping`]: `啟用外部群組對映`,
  [`Edit extra constraints for the GraphQL schema`]: `編輯 GraphQL 綱目的額外限制`,
  [`Edit GraphQL schema|title`]: `編輯 GraphQL 綱目`,
  [`Edit inline schema`]: `編輯行內綱目`,
  [`Edit inputs`]: `編輯輸入`,
  [`Edit invitation`]: `編輯邀請`,
  [`Edit mail server test connection`]: `編輯郵件伺服器測試連線`,
  [`Edit member invitation`]: `編輯成員邀請`,
  [`Edit notification templates`]: `編輯通知範本`,
  [`Edit notification templates:`]: `編輯通知範本：`,
  [`Edit operation list`]: `編輯作業清單`,
  [`Edit or upload JSON schema`]: `編輯或上傳 JSON 綱目`,
  [`Edit outputs`]: `編輯輸出`,
  [`Edit password changed`]: `編輯密碼已變更`,
  [`Edit password reset`]: `編輯密碼已重設`,
  [`Edit plan APIs`]: `編輯方案 API`,
  [`Edit schema`]: `編輯綱目`,
  [`Edit schema|button`]: `編輯綱目`,
  [`Edit schema|title`]: `編輯綱目`,
  [`Edit settings for roles, notifications and more.`]: `編輯角色和通知等的設定。`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `編輯使用者登錄、角色、端點等的設定`,
  [`Edit sign up`]: `編輯註冊`,
  [`Edit task product lifecycle approved`]: `編輯作業產品生命週期已核准`,
  [`Edit task product lifecycle cancelled`]: `編輯作業產品生命週期已取消`,
  [`Edit task product lifecycle denied`]: `編輯作業產品生命週期已拒絕`,
  [`Edit task product lifecycle pending`]: `編輯作業產品生命週期擱置中`,
  [`Edit task product lifecycle request`]: `編輯作業產品生命週期要求`,
  [`Edit task subscription approved`]: `編輯作業訂閱已核准`,
  [`Edit task subscription cancelled`]: `編輯作業訂閱已取消`,
  [`Edit task subscription denied`]: `編輯作業訂閱已拒絕`,
  [`Edit task subscription pending`]: `編輯作業訂閱擱置中`,
  [`Edit task subscription request`]: `編輯作業訂閱申請`,
  [`Edit template`]: `編輯範本`,
  [`Edit the TLS client profile.`]: `編輯 TLS 用戶端設定檔。`,
  [`Edit the TLS Server profiles`]: `編輯 TLS 伺服器設定檔`,
  [`Edit the keystore title.`]: `編輯金鑰儲存庫標題。`,
  [`Edit the organization summary details as required`]: `視需要編輯組織摘要詳細資料`,
  [`Edit the organization title`]: `編輯組織標題`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `編輯參數以配置電子郵件伺服器。除非指定為選用項目，否則這些欄位是必要項目。`,
  [`Edit signed token`]: `編輯已簽章的記號`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `編輯電子郵件範本的文字。變數包含在大括弧中，且無法編輯。`,
  [`Edit the truststore.`]: `編輯信任儲存庫。`,
  [`Edit the visibility for each resource.`]: `編輯每個資源的可見性。`,
  [`Edit user details`]: `編輯使用者詳細資料`,
  [`Edit {notificationTemplate}`]: `編輯「{notificationTemplate}」`,
  [`Edit, assemble, secure and test APIs`]: `編輯、組合、保護安全和測試 API`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `編輯、組合、保護安全和測試 API。使用產品來發佈至消費者的套件 API。`,
  [`Edit, upload, or infer XML schema`]: `編輯、上傳或推斷 XML 綱目`,
  [`Editors`]: `編輯器`,
  [`Edit|permission`]: `編輯`,
  [`Edit API key timeout`]: `編輯 API 金鑰逾時值`,
  [`Edit Base64 encoding for temporary tokens`]: `編輯暫時記號的 Base64 編碼`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `必須提供「ID 記號簽章加密物件」或「ID 記號簽章金鑰」。`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `必須提供「JWT 驗證加密物件」或「JWT 驗證 JWK」。`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `選取相應選項來使用 API 中定義的端點，或提供預設驗證器端點。`,
  [`Element name for JSON array elements`]: `JSON 陣列元素的元素名稱`,
  [`Email`]: `電子郵件`,
  [`Email address`]: `電子郵件位址`,
  [`Email Content`]: `電子郵件內容`,
  [`Email endpoint`]: `電子郵件端點`,
  [`Email required`]: `需要電子郵件`,
  [`Email sender`]: `電子郵件寄件者`,
  [`Email server`]: `電子郵件伺服器`,
  [`Email server configuration`]: `電子郵件伺服器配置`,
  [`Email servers`]: `電子郵件伺服器`,
  [`Empty case`]: `沒有案例`,
  [`Empty catch`]: `沒有捕捉`,
  [`Empty JSON array handling`]: `空白 JSON 陣列處理`,
  [`Empty XML element handling`]: `空白 XML 元素處理`,
  [`Enable`]: `啟用`,
  [`Enable API consumers to invite collaborators and assign roles`]: `讓 API 消費者能夠邀請合作人員並指派角色`,
  [`Enable application lifecycle`]: `啟用應用程式生命週期`,
  [`Enable billing`]: `啟用計費`,
  [`Enable gateway services`]: `啟用閘道服務`,
  [`Enable gateway services and policies`]: `啟用閘道服務及原則`,
  [`Enable GraphiQL`]: `啟用 GraphiQL`,
  [`Enable GraphiQL editor`]: `啟用 GraphiQL 編輯器`,
  [`Enable GraphiQL Editor on browser`]: `在瀏覽器上啟用 GraphiQL 編輯器`,
  [`Enable JSON post processing`]: `啟用 JSON 後處理`,
  [`Enable lifecycle approvals`]: `啟用生命週期核准`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `啟用暫時性要求 (NONCE) 延伸以防止再次使用（重播）受損的要求`,
  [`Enable OAuth providers`]: `啟用 OAuth 提供者`,
  [`Enable OIDC`]: `啟用 OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `啟用 OpenID Connect 範本以產生 ID 記號。`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `啟用 PKCE 延伸以容許公用用戶端緩解授權碼遭截取的威脅`,
  [`Enable production mode`]: `啟用正式作業模式`,
  [`Enable Proxy`]: `啟用 Proxy`,
  [`Enable Self-Service Onboarding`]: `啟用自助式上線`,
  [`Enable spaces`]: `啟用空間`,
  [`Enable token management`]: `啟用記號管理`,
  [`Enable buffering`]: `啟用緩衝`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `讓 API 消費者無需邀請即可建立開發入口網站帳戶。`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `針對您的 API 啟用跨原點資源共用 (CORS) 存取控制。`,
  [`Enable debug response headers`]: `啟用除錯回應標頭`,
  [`Enable proof key for code exchange`]: `啟用用於程式碼交換的驗證金鑰`,
  [`Enable publishing of this product`]: `啟用此產品的發佈`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `啟用透過入口網站 OIDC 端點進行協力廠商授權碼重新導向的功能`,
  [`Enable security`]: `啟用安全`,
  [`Enable Self-Service Onboarding Approval`]: `啟用自助上線核准`,
  [`Enable the following gateway services and policies for this catalog`]: `啟用此型錄的下列閘道服務和原則`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `讓使用者能夠配置審核事件。審核事件是一份詳細摘要，指出何人在何時執行了什麼事件。`,
  [`Enable/disable custom notification templates`]: `啟用/停用自訂通知範本`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `對邀請和密碼重設記號啟用或停用 Base64 編碼`,
  [`Enable overriding consumer organizations invitation timeout`]: `啟用置換消費者組織邀請逾時值`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `您可以啟用或停用暫時記號的 Base64 編碼。 暫時記號是邀請及密碼重設記號。 當啟用 Base64 編碼時，會以 Base64 格式來編碼暫時記號。 當停用 Base64 編碼時，暫時記號會保留 JSON Web 記號 (JWT) 格式，表示使用者所接收 URL 的長度會減少。`,
  [`Enabled`]: `已啟用`,
  [`enabled`]: `已啟用`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `啟用應用程式生命週期將容許應用程式處於開發或正式作業狀態，並且可以遞送至其他閘道和端點。`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `啟用自助式上線核准將需要所有上線要求均由 API 提供者或管理者核准。`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `啟用自助式上線容許應用程式開發者無需 API 提供者、現有消費者組織擁有者或管理者的邀請即可完成註冊。`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `啟用會將所有消費者組織邀請逾時值設為型錄邀請逾時值。`,
  [`Encode Query params`]: `編碼查詢參數`,
  [`Encrypt`]: `加密`,
  [`Encrypt Crypto Object`]: `加密加密物件`,
  [`Encrypt JWK variable name`]: `加密 JWK 變數名稱`,
  [`Encrypt a buffer using the specified certificate`]: `使用指定的憑證來加密緩衝區`,
  [`Encryption Algorithm`]: `加密演算法`,
  [`Endpoint`]: `端點`,
  [`Endpoint URLs`]: `端點 URL`,
  [`Endpoint behavior`]: `端點行為`,
  [`Endpoint Behavior`]: `端點行為`,
  [`Endpoint for unenforced APIs`]: `未強制執行 API 的端點`,
  [`Endpoints`]: `端點`,
  [`Enforce Required Params`]: `施行必要的參數`,
  [`Enforce the API by using API Connect Gateway.`]: `使用「API Connect 閘道」來施行 API。`,
  [`Enforced`]: `強制執行`,
  [`English`]: `英文`,
  [`Enter Summary`]: `輸入摘要`,
  [`Enter URL`]: `輸入 URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `輸入使用者登錄名稱。如果此項為空白，則依預設會使用 API Manager 本端使用者登錄`,
  [`Enter a catalog name`]: `輸入型錄名稱`,
  [`Enter a Display Name`]: `輸入顯示名稱`,
  [`Enter a space name`]: `輸入空間名稱`,
  [`Enter a brief description`]: `輸入簡要說明`,
  [`Enter a display name`]: `輸入顯示名稱`,
  [`Enter a temporary sign in password`]: `輸入暫時登入密碼`,
  [`Enter an Organization Name`]: `輸入組織名稱`,
  [`Enter an Organization Title`]: `輸入組織標題`,
  [`Enter catalog title`]: `輸入型錄標題`,
  [`Enter details of the consumer organization`]: `輸入消費者組織的詳細資料`,
  [`Enter details of the product`]: `輸入產品的詳細資料`,
  [`Enter details of the provider organization`]: `輸入提供者組織的詳細資料`,
  [`Enter details of this API`]: `輸入此 API 的詳細資料`,
  [`Enter email of the new user`]: `輸入新使用者的電子郵件`,
  [`Enter first name of the new user`]: `輸入新使用者的名字`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `輸入一個以上電子郵件位址。使用逗點來分隔多個位址。`,
  [`Enter organization name`]: `輸入組織名稱`,
  [`Enter organization title`]: `輸入組織標題`,
  [`Enter password`]: `輸入密碼`,
  [`Enter space title`]: `輸入空間標題`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `通過提供主機名稱和埠號，輸入閘道的 API 端點。 例如：api.mycompany.com:8090`,
  [`Enter the API summary details`]: `輸入 API 摘要詳細資料`,
  [`Enter the API summary details. [Learn more]({link})`]: `輸入 API 摘要詳細資料。[進一步瞭解]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `輸入您想要代理的目標服務的 URL`,
  [`Enter the catalog summary details`]: `輸入型錄摘要詳細資料`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `輸入型錄摘要詳細資料；您可以在建立型錄之後完整配置型錄`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `輸入要邀請成為管理者組織成員之使用者的電子郵件位址`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `輸入要邀請成為型錄成員之使用者的電子郵件位址`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `輸入要邀請成為提供者組織成員之使用者的電子郵件位址`,
  [`Enter the email address of the user to invite as a member of the space`]: `輸入要邀請成為空間成員之使用者的電子郵件位址`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `輸入您要轉移所有權之使用者的電子郵件位址。`,
  [`Enter the full path to JSON or YAML file`]: `輸入 JSON 或 YAML 檔的完整路徑`,
  [`Enter the full path to WSDL file`]: `輸入 WSDL 檔的完整路徑`,
  [`Enter the full path to YAML file`]: `輸入 YAML 檔的完整路徑`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `輸入您在安裝期間所定義 Analytics 汲取端點的完整網域名稱。`,
  [`Enter the last name of the new user`]: `輸入新使用者的姓氏`,
  [`Enter the owner's email address to send the invitation`]: `輸入擁有者的電子郵件位址來傳送邀請`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `輸入參數以配置閘道服務。除非指定為選用項目，否則這些欄位是必要項目。端點是 DataPower 上的 API Gateway 服務端點。基本 API 端點是送入的 API 呼叫的 API Gateway 端點。對於 SNI，請輸入 * 以容許所有主機（必要），也請輸入主機名稱以容許特定主機。選擇 TLS 設定檔以支援每個主機。`,
  [`Enter the password for the certificate file if required.`]: `必要時輸入憑證檔的密碼。`,
  [`Enter the provider organization summary details`]: `輸入提供者組織摘要詳細資料`,
  [`Enter the schema definitiom summary details`]: `輸入綱目定義摘要詳細資料`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `輸入空間摘要詳細資料；您可以在建立空間之後完整配置空間`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `輸入空間摘要詳細資料；您可以在建立空間之後完整配置空間`,
  [`Enter the summary details for the new product`]: `輸入新產品的摘要詳細資料`,
  [`Enter the summary details for this role`]: `輸入此角色的摘要詳細資料`,
  [`Enter title of the organization`]: `輸入組織的標題`,
  [`Enter user email`]: `輸入使用者電子郵件`,
  [`Enter username of the existing user`]: `輸入現有使用者的使用者名稱`,
  [`Enter username of the new user`]: `輸入新使用者的使用者名稱`,
  [`Error`]: `錯誤`,
  [`Error ID`]: `錯誤 ID`,
  [`Error:`]: `錯誤：`,
  [`Copy error ID to Clipboard`]: `將錯誤 ID 複製到剪貼簿`,
  [`Error Console`]: `錯誤主控台`,
  [`Error connecting`]: `連接時發生錯誤`,
  [`Error connecting to GraphQL server`]: `連接至 GraphQL 伺服器時發生錯誤`,
  [`Error getting user info during login. Please login with different user.`]: `登入期間取得使用者資訊時發生錯誤。 請以不同的使用者身分登入。`,
  [`Error content`]: `錯誤內容`,
  [`Error message`]: `錯誤訊息`,
  [`Error name`]: `錯誤名稱`,
  [`Error status code`]: `錯誤狀態碼`,
  [`Error status code is invalid`]: `錯誤狀態碼無效`,
  [`Error status reason`]: `錯誤狀態原因`,
  [`Errors`]: `錯誤`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `錯誤資訊及錯誤字元通常會造成錯誤。請確保在測試前提供正確的提供者資訊和用戶端資源。`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `嘗試從 URL {url} 取得 API 時發生錯誤。 請檢查 URL、使用者名稱及密碼。 錯誤訊息：{message}`,
  [`Event`]: `事件`,
  [`Event gateway management client TLS client profile`]: `事件閘道管理用戶端 TLS 用戶端設定檔`,
  [`Event load`]: `事件負載`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `每個消費者組織都具有一個擁有者。擁有者具有完整管理權限，並且可以管理訂閱和應用程式。`,
  [`Exchange authorization code for tokens`]: `交換記號的授權碼`,
  [`Exchange code for token`]: `交換記號的代碼`,
  [`Execute`]: `執行`,
  [`Execute migration target`]: `執行移轉目標`,
  [`Executes any catalog-level post-invoke handlers`]: `執行任何型錄層次的後置呼叫處理程式`,
  [`Executes any catalog-level pre-invoke handlers`]: `執行任何型錄層次的預先呼叫處理程式`,
  [`Existing`]: `現有`,
  [`Existing {docType}`]: `現有「{docType}」`,
  [`Existing API Connect user`]: `現有的 API Connect 使用者`,
  [`Existing OpenAPI`]: `現有 OpenAPI`,
  [`Existing product`]: `現有產品`,
  [`Existing SOAP API has been validated`]: `已驗證現有的 SOAP API`,
  [`Existing products in the catalog will be moved to a default space.`]: `型錄中的現有產品將移至預設空間。`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `現有使用者即為現行成員。您亦可從其他使用者登錄啟動新成員。`,
  [`Expires On`]: `到期時間`,
  [`Expiry`]: `期限`,
  [`Explorer`]: `瀏覽器`,
  [`Expose`]: `公開`,
  [`Extend the schema to specify the cost of types and fields`]: `延伸綱目以指定類型和欄位的成本`,
  [`Extended`]: `延伸`,
  [`Extension Type:`]: `延伸類型：`,
  [`Extensions`]: `延伸`,
  [`Extension has been uploaded.`]: `已上傳延伸。`,
  [`External`]: `外部`,
  [`External Doc Description`]: `外部文件說明`,
  [`External doc description`]: `外部文件說明`,
  [`External Documentation URL`]: `外部文件 URL`,
  [`External documentation URL`]: `外部文件 URL`,
  [`External Role mapping`]: `外部角色對映`,
  [`External URL`]: `外部 URL`,
  [`Extract Identity Settings`]: `擷取身分設定`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `因素 ID`,
  [`Fail`]: `失敗`,
  [`Failed to change account password.`]: `無法變更帳戶密碼。`,
  [`Failed to create analytics service {title}.`]: `無法建立分析服務 {title}。`,
  [`Failed to create analytics insights service {title}.`]: `無法建立 Analytics Insights 服務 {title}。`,
  [`Analytics insights service {title} has been updated.`]: `Analytics Insights 服務 {title} 已更新。`,
  [`About the analytics insights service`]: `Analytics Insights 服務簡介`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Analytics Insights 服務會從閘道服務收集 API 事件。 每一個閘道服務都可以有相關聯的 Analytics Insights 服務。`,
  [`Advanced analytics insights configuration`]: `進階 Analytics Insights 配置`,
  [`Analytics insights service {title} has been created.`]: `已建立 Analytics Insights 服務 {title}。`,
  [`Analytics Insights details`]: `Analytics Insights 詳細資料`,
  [`Management endpoint on the analytics insights service`]: `Analytics Insights 服務上的管理端點`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `輸入您在安裝期間定義的 Analytics Insights Director 端點的完整網域名稱。`,
  [`TYPE`]: `類型`,
  [`Failed to create email server`]: `無法建立電子郵件伺服器`,
  [`Failed to create gateway service`]: `無法建立閘道服務`,
  [`Failed to create gateway service {name}.`]: `無法建立閘道服務 {name}。`,
  [`Failed to create user registry {title}.`]: `無法建立使用者登錄 {title}。`,
  [`Failed to delete a group`]: `無法刪除群組。`,
  [`Failed to get TLS client profiles`]: `無法取得 TLS 用戶端設定檔`,
  [`Failed to get catalogs`]: `無法取得型錄`,
  [`Failed to get configured OAuth providers`]: `無法取得配置的 OAuth 提供者`,
  [`Failed to get policies of gateways`]: `無法取得閘道的原則`,
  [`Failed to get user registries`]: `無法取得使用者登錄`,
  [`Failed to introspect schema from server:`]: `無法從伺服器來內部檢查綱目：`,
  [`Failed to make API online`]: `API 上線失敗`,
  [`Failed to register catalog member {user}.`]: `無法登錄型錄成員 {user}。`,
  [`Failed to remove analytics service: {arg}`]: `無法移除分析服務：{arg}`,
  [`Failed to remove availability zone: {arg}`]: `無法移除可用性區域：{arg}`,
  [`Failed to remove gateway service: {arg}`]: `無法移除閘道服務：{arg}`,
  [`Failed to remove mailserver: {arg}`]: `無法移除郵件伺服器：{arg}`,
  [`Failed to remove portal service: {arg}`]: `無法移除入口網站服務：{arg}`,
  [`Failed to create {name}.`]: `無法建立 {name}。`,
  [`Failed to sign out! Please check the console for details.`]: `無法登出！如需詳細資料，請檢查主控台。`,
  [`Failed to update {name}.`]: `無法更新 {name}。`,
  [`Failed to update OAuth provider`]: `無法更新 OAuth 提供者`,
  [`Failed to update account.`]: `無法更新帳戶。`,
  [`Failed to update analytics service {title}.`]: `無法更新分析服務 {title}。`,
  [`Failed to update gateway service {title}.`]: `無法更新閘道服務 {title}。`,
  [`Failed to update task`]: `無法更新工作`,
  [`Failed to create app {title}`]: `無法建立應用程式 {title}`,
  [`Feature flags`]: `功能旗標`,
  [`Fetch schema`]: `提取綱目`,
  [`Fetched endpoints`]: `提取的端點`,
  [`Field`]: `欄位`,
  [`Field label`]: `欄位標籤`,
  [`Field Mapping`]: `欄位對映`,
  [`Field name`]: `欄位名稱`,
  [`Field weight`]: `欄位加權`,
  [`File Name`]: `檔名`,
  [`File Preview`]: `檔案預覽`,
  [`File contains validation errors`]: `檔案包含驗證錯誤`,
  [`File is not a valid OpenAPI definition`]: `檔案不是有效的 OpenAPI 定義`,
  [`File is not a valid extension format.`]: `檔案不是有效的延伸格式。`,
  [`File is not a valid {fileType} format.`]: `檔案不是有效的 {fileType} 格式。`,
  [`File name`]: `檔名`,
  [`File upload`]: `檔案上傳`,
  [`File uploaded is not a valid file`]: `上傳的檔案不是有效的檔案`,
  [`File uploaded successfully`]: `已順利上傳檔案`,
  [`File was updated and validated!`]: `檔案已更新並驗證！`,
  [`File was uploaded and validated!`]: `檔案已上傳並驗證！`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `檔案 {draftFilePath} 已存在。嘗試變更草稿的名稱/版本組合`,
  [`Fill out the details of this service`]: `填寫此服務的詳細資料`,
  [`Filter`]: `過濾`,
  [`Filter by operation`]: `依據作業過濾`,
  [`Filter policies`]: `過濾原則`,
  [`Final Action`]: `最終動作`,
  [`Find existing user`]: `尋找現有使用者`,
  [`Find expert answers in the API Connect community forum`]: `在 API Connect 社群討論區中尋找專家解答`,
  [`Find expert answers in the API Connect community forum.`]: `在 API Connect 社群討論區中尋找專家解答。`,
  [`Find schema`]: `尋找綱目`,
  [`Finger Print`]: `指紋`,
  [`Fingerprint SHA-1`]: `指紋 SHA-1`,
  [`Fingerprint SHA-256`]: `指紋 SHA-256`,
  [`Finish`]: `完成`,
  [`First name`]: `名字`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `首先，請指定端點的 URL，以供閘道用於 API 傳輸。此值必須是一個公用的完整網域名稱，再加上您 API 呼叫的特定路徑，例如：https://api.mycompany.com。`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `首先，請指定閘道管理端點的 URL。如果閘道是在 Kubernetes 環境中執行，此值必須符合閘道設定指令 set gwy apic-gw-service 所指定的端點。對於閘道軟體驅動裝置，此值的格式必須是 http://<ip-address-for-gateway>:3000。當使用閘道叢集時，請指定負載平衡器的 address:port。`,
  [`Fit to screen`]: `符合畫面大小`,
  [`Flow`]: `流程`,
  [`Follow redirects`]: `遵循重新導向`,
  [`Found In`]: `發現位置`,
  [`Fonts contained in the following file are available under the`]: `下列檔案包含的字型位於`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `對於 WSDL 定義，上傳包含 WSDL 檔案和 API 定義 (YAML) 的 zip 檔案`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `對於原始產品中的每個方案，選取替換產品中您要將訂閱移轉至的方案`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `對於來源產品中的每個方案，選取目標產品中您要將訂閱移轉至的方案`,
  [`For guidance on the available command set, access the CLI's help`]: `如需可用指令集的指引，請存取 CLI 的說明`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `對於使用原生 OAuth 提供者的網站，則可選擇性地指定共用密碼，以供所有 API 呼叫使用。`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `對於可寫入的 LDAP，則可選取「使用者管理」，並新增來源 LDAP 登錄屬性名稱到 API Connect LDAP 屬性值的對映。API Connect 在使用者登錄期間需要的預設使用者設定檔內容是 username、first_name、last_name、email 及 password。提供了範例對映配置 - 可編輯對映以符合您的需求`,
  [`Forbidden`]: `已禁止`,
  [`Forget token`]: `忘記記號`,
  [`Forgot password?`]: `忘記密碼？`,
  [`Forgot password`]: `忘記密碼`,
  [`Form`]: `表單`,
  [`Forum`]: `討論區`,
  [`French`]: `法文`,
  [`From File`]: `從檔案`,
  [`From Sample JSON`]: `從範例 JSON`,
  [`From Sample XML`]: `從範例 XML`,
  [`From URL`]: `從 URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `從現有 GraphQL 服務 (GraphQL Proxy)`,
  [`From existing OpenAPI service`]: `從現有 OpenAPI 服務`,
  [`From existing WSDL service (REST proxy)`]: `從現有 WSDL 服務 (REST Proxy)`,
  [`From existing WSDL service (SOAP proxy)`]: `從現有 WSDL 服務 (SOAP Proxy)`,
  [`From target service`]: `從目標服務`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `從 WSDL 定義中針對 SOAP 服務所定義的作業，選取要在 API 中使用的作業，並指定方法和 URL 路徑。`,
  [`Gateway Extensions`]: `閘道延伸`,
  [`Gateway extension`]: `閘道延伸`,
  [`Gateway Services`]: `閘道服務`,
  [`Gateway Type`]: `閘道類型`,
  [`Gateway by Name`]: `閘道（依名稱）`,
  [`Gateway services`]: `閘道服務`,
  [`Gateway type`]: `閘道類型`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL 第 2 版授權`,
  [`GROUP`]: `群組`,
  [`Group name`]: `群組名稱`,
  [`Gateway`]: `閘道`,
  [`Gateways`]: `閘道`,
  [`Gateway details`]: `閘道詳細資料`,
  [`Gateway Details`]: `閘道詳細資料`,
  [`Gateway Error`]: `閘道錯誤`,
  [`Gateway service details`]: `閘道服務詳細資料`,
  [`Gateway management client TLS client profile`]: `閘道管理用戶端的 TLS 用戶端設定檔`,
  [`Gateway management client keystore`]: `閘道管理用戶端金鑰儲存庫`,
  [`Gateway management client truststore`]: `閘道管理用戶端信任儲存庫`,
  [`Gateway processing status`]: `閘道處理狀態`,
  [`Gateway policies (TODO)`]: `閘道原則 (TODO)`,
  [`Gateway service`]: `閘道服務`,
  [`Gateway Service US East (TODO)`]: `閘道服務美國東部 (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `已移除閘道服務 {arg}。`,
  [`Gateway service configuration`]: `閘道服務配置`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `閘道服務主機無法使用。將不會啟用瀏覽器中的「API 測試」（試用）。`,
  [`Gateway service configuration has been updated.`]: `已更新閘道服務配置。`,
  [`Gateway service {title} has been created.`]: `已建立閘道服務 {title}。`,
  [`Gateway service {title} has been updated.`]: `已更新閘道服務 {title}。`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `啟用空間後，會在每個空間中管理閘道服務。請移至空間設定以管理閘道服務。`,
  [`Gateway version`]: `閘道版本`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `每頁閘道數`,
  [`Gather-and-send`]: `收集並傳送`,
  [`Gather-only`]: `僅收集`,
  [`General configuration`]: `一般配置`,
  [`General information about the API.`]: `API 的一般資訊。`,
  [`Generate`]: `產生`,
  [`Generate an OpenAPI definition for the proxy`]: `產生 Proxy 的 OpenAPI 定義`,
  [`Generate JSON Web Token (JWT)`]: `產生 JSON Web 記號 (JWT)`,
  [`Generate JWT`]: `產生 JWT`,
  [`Generate LTPA Token`]: `產生 LTPA 記號`,
  [`Generate OAuth Access Tokens`]: `產生 OAuth 存取記號`,
  [`Generate OAuth access tokens`]: `產生 OAuth 存取記號`,
  [`Generate a default product`]: `產生預設產品`,
  [`Generate access token`]: `產生存取記號`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `產生 LTPA 記號，讓 API 可以安全地向 WebSphere Application Server Web 伺服器鑑別。`,
  [`Generate an object`]: `產生物件`,
  [`Generate authorization code`]: `產生授權碼`,
  [`Generate from sample JSON`]: `從範例 JSON 產生`,
  [`Generate from sample XML`]: `從範例 XML 產生`,
  [`Generate tests automatically to build high quality APIs`]: `自動產生測試，以建置高品質的 API`,
  [`Generated OpenAPI 2.0 definition`]: `已產生 OpenAPI 2.0 定義`,
  [`Generated default product for the API`]: `已產生 API 的預設產品`,
  [`Generated name for use in commands and API calls`]: `已產生用於指令和 API 呼叫中的名稱`,
  [`German`]: `德文`,
  [`Get Base DN`]: `取得基本 DN`,
  [`Get Code`]: `取得程式碼`,
  [`Get Code Snippet`]: `取得程式碼 Snippet`,
  [`Get Support`]: `取得支援`,
  [`Get Token`]: `取得記號`,
  [`Get activation link`]: `取得啟動鏈結`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `透過登錄閘道服務來開始。這將容許您在配置其他服務時測試端點。一旦您登錄服務，您將在這裡看到該服務。[進一步瞭解]({link})`,
  [`Get subscription ID`]: `取得訂閱 ID`,
  [`Getting Started`]: `入門`,
  [`Getting started`]: `入門`,
  [`Git information`]: `Git 資訊`,
  [`Github`]: `Github`,
  [`Global policies`]: `廣域原則`,
  [`Go to Spaces`]: `移至空間`,
  [`Go to the Test tab`]: `移至「測試」標籤`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL 速率限制`,
  [`GraphQL service`]: `GraphQL 服務`,
  [`GraphQL schema`]: `GraphQL 綱目`,
  [`GraphQL Schema`]: `GraphQL 綱目`,
  [`GraphQL schema has been successfully validated.`]: `已順利驗證 GraphQL 綱目。`,
  [`GraphQL Schema URL`]: `GraphQL 綱目 URL`,
  [`GraphQL send type`]: `GraphQL 傳送類型`,
  [`GraphQL Websocket Upgrade`]: `GraphQL Websocket 升級`,
  [`Group Based DN`]: `群組型 DN`,
  [`Group {title} added`]: `已新增群組 {title}`,
  [`Group {title} updated`]: `已更新群組 {title}`,
  [`Group {title} has been deleted`]: `已刪除群組 {title}`,
  [`Groups`]: `群組`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `主機名稱`,
  [`HOST URL`]: `主機 URL`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML 表單`,
  [`HTML form time limit`]: `HTML 表單時間限制`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `需要 HTTP/2`,
  [`HTTP Header`]: `HTTP 標頭`,
  [`HTTP Method`]: `HTTP 方法`,
  [`HTTP Type`]: `HTTP 類型`,
  [`HTTP Version`]: `HTTP 版本`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Handlebar`,
  [`Hard limit`]: `硬性限制`,
  [`Header`]: `標頭`,
  [`Header control`]: `標頭控制項`,
  [`Header name payload`]: `標頭名稱有效負載`,
  [`Header name token`]: `標頭名稱記號`,
  [`Headers`]: `標頭`,
  [`Hello`]: `您好`,
  [`Hello!`]: `您好！`,
  [`Hello [IBM](url)`]: `您好！[IBM](http://ibm.com)`,
  [`Hello {name}`]: `{name}，您好！`,
  [`Hello {name}!`]: `{name}，您好！`,
  [`Hello {name}, [see IBM profile](url)`]: `{name}，您好！[請參閱 IBM 人員資訊](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n感謝您在 {catalogTitle} Developer Portal 上註冊帳戶。\n\n若要完成註冊，請使用下列鏈結：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{consumerOrg} Developer Portal 的管理者已恢復\n{appName} 應用程式。API Gateway 將不再封鎖來自此應用程式的\nAPI 呼叫。\n\n您可以使用此鏈結來檢視應用程式詳細資料：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{consumerOrg} Developer Portal 的管理者已暫停\n{appName} 應用程式。API Gateway 將封鎖來自此應用程式的 API 呼叫。\n\n您可以使用此鏈結來檢視應用程式詳細資料：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n使用者 {originator} 已申請對\n {catalog} 型錄中 {productName} API 產品版本 {productVersion} 的 {planName} \n方案的訂閱進行核准。\n\n您可以使用下列鏈結在您的作業清單中開啟申請：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n使用者 {originator} 已透過應用程式 {appName}\n申請對 {catalog} 型錄中 {productName} API 產品版本 {productVersion} 的 {planName} 方案的\n訂閱進行核准。\n\n您可以使用下列鏈結在您的作業清單中開啟申請：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n使用者 {originator} 已申請對 {catalog} 型錄中 {appName} \n應用程式的 {action} 進行核准。\n\n您可以使用下列鏈結在您的作業清單中開啟申請：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n使用者 {originator} 已申請對 {catalog} 型錄中 {productName} \n API 產品版本 {version} 的 {action} 進行核准。\n\n您可以使用下列鏈結在您的作業清單中開啟申請：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{{firstName}}，您好！\n\n我們已收到您對 {{catalog}} Developer Portal 中使用者帳戶\n{{username}} 的密碼的重設申請。\n\n如果您沒有提出申請，則只需忽略此訊息。 否則，您可以\n使用下列鏈結來重設密碼：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `{firstName}，您好！\n\n我們已收到您對 IBM API Connect 中使用者帳戶\n{username} 的密碼的重設申請。\n\n若要重設您的密碼，請使用下列鏈結：\n\n{link}\n\n（如果您沒有提出申請，則可以忽略此訊息。）\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n我們已收到您對 {catalog} Developer Portal 中使用者帳戶\n{username} 的密碼的重設申請。\n\n如果您沒有提出申請，則只需忽略此訊息。 否則，您可以\n使用下列鏈結來重設密碼：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName}，您好！\n\n您對 {catalog} 型錄中 {appName} 應用程式\n建立訂閱的申請正在等待核准。系統處理您的申請後，\n我們將傳送其他通知。\n\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{link}\n\n任何時候，只要您想要取消申請，請使用下列鏈結：\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}}，您好！\n\n您針對 API 產品 {{productName}} 版本\n{{productVersion}} 建立 {{appName}} 應用程式對 \n{{planName}} 方案的訂閱申請已得到核准。\n\n{{#if comments}}\n核准者併入了下列註解：\n\n"{{comments}}"\n\n{{/if}}\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您針對 API 產品 {productName} 版本\n{productVersion} 建立 {appName} 應用程式對 \n{planName} 方案的訂閱申請已得到核准。\n\n{#if comments}\n核准者併入了下列註解：\n\n{comments}\n\n{/if}\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}}，您好！\n\n您針對 API 產品 {{productName}} 版本\n{{productVersion}} 建立 {{appName}} 應用程式對 \n{{planName}} 方案的訂閱申請已遭拒絕。\n\n{{#if comments}}\n核准者併入了下列註解：\n\n"{{comments}}"\n\n{{/if}}\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{{firstName}}，您好！\n\n您對 {{catalog}} 型錄中 API 產品 {{productName}}:{{version}} 的\n{{action}} 申請已得到核准。\n\n{{#if comments}}\n核准者併入了下列註解：\n\n{{comments}}\n\n{{/if}}\n您可以透過下列鏈結來檢查產品的狀態：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {catalog} 型錄中 API 產品 {productName}:{version} 的\n {action} 申請已得到核准。\n\n{#if comments}\n核准者併入了下列註解：\n\n{comments}\n\n{/if}\n您可以透過下列鏈結來檢查產品的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {catalog} 型錄中 API 產品 {productName}:{version} 的\n{action} 申請已遭拒絕。\n\n{#if comments}\n核准者併入了下列註解：\n\n{comments}\n\n{/if}\n您可以透過下列鏈結來檢查產品的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {catalog} 型錄中 API 產品 {productName}:{version} 的\n{action} 申請已遭拒絕。\n\n{#if comments}\n核准者併入了下列註解：\n\n{comments}\n\n{/if}\n您可以透過下列鏈結來檢查產品的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName}，您好！\n\n您對 {catalog} 型錄中 API 產品 {productName}:{version} 的\n {action} 申請已得到核准。系統處理您的申請後，\n我們將傳送其他通知。\n\n您可以透過下列鏈結來檢查產品的狀態：\n\n{link}\n\n任何時候，只要您想要取消申請，請使用下列鏈結：\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {consumerOrg} Developer Portal 中應用程式 {appName} 的 {action} 申請\n已得到核准。\n\n{#if comments}\n核准者併入了下列註解：\n\n"{comments}"\n\n{/if}\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {consumerOrg} Developer Portal 中應用程式 {appName} 的 {action} 申請\n已得到核准。\n\n{#if comments}\n核准者併入了下列註解：\n\n{comments}\n\n{/if}\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {consumerOrg} Developer Portal 中應用程式 {appName} 的 {action} 申請\n已遭拒絕。\n\n{#if comments}\n核准者併入了下列註解：\n\n"{comments}"\n\n{/if}\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {consumerOrg} Developer Portal 中應用程式 {appName} 的 {action} 申請\n已遭拒絕。\n\n{#if comments}\n核准者併入了下列註解：\n\n{comments}\n\n{/if}\n您可以透過下列鏈結來檢查應用程式的狀態：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n您對 {consumerOrg} Developer Portal 中應用程式\n{appName} 的 {action} 申請正在等待核准。系統處理您的申請後，\n我們將傳送其他通知。\n\n任何時候，只要您想要取消申請，請使用下列鏈結：\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\nIBM API Connect 的帳戶管理者已邀請您建立\nAPI 提供者組織。提供者組織容許您和您的\n團隊開發 API 及管理 API 產品的型錄。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\n{catalog} API 型錄（屬於 IBM API Connect 中的\n{org} API 提供者組織）的管理者已邀請您\n在型錄中建立空間。空間容許您和您的團隊\n管理 API 型錄中的 API 產品集。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\n{catalog} API 型錄（屬於 IBM API Connect 中的\n{org} API 提供者組織）的管理者已邀請您\n加入型錄。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\n{catalog} Developer Portal 的管理者已邀請\n您建立 API 消費者組織。消費者組織\n容許您和您的團隊存取 API 及登錄用戶端應用程式。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\n{catalog} Developer Portal 中 {consumerOrg} API\n消費者組織的管理者已邀請您加入組織。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\nIBM API Connect 中 {org} API 提供者組織的管理者\n已邀請您建立 API 型錄。型錄容許您\n和您的團隊發佈及管理 API 產品集合。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\nIBM API Connect 中 {org} API 提供者組織的管理者\n已邀請您加入組織。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\n{catalog} API 型錄（屬於 IBM API Connect 中的\n{org} API 提供者組織）中 {space} 空間的管理者已邀請您\n加入空間。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `您好！\n\nIBM API Connect 中使用者帳戶 {username} 的\n密碼已順利變更。\n\n您可以透過下列鏈結來登入 API Connect：\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `您好！\n\n{portalTitle} Developer Portal 中使用者帳戶 {username} 的\n密碼已變更。\n\n您可以透過下列鏈結來登入 {portalTitle} Developer Portal：\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好！\n\nIBM API Connect 的系統管理者已邀請您加入\n{org} 組織。\n\n若要繼續作業，請使用下列鏈結：\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `您好！\n\n此測試訊息來自已配置郵件伺服器\n{mailServer} 中的 IBM API Connect。\n\n如果您如預期的那樣收到此訊息，則測試成功！\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好！\n\n使用者 {originator} 已撤銷其對\n {catalog} 型錄中 {productName} API 產品版本 {productVersion} 的 {planName} 方案的訂閱申請。\n\n不需執行進一步動作。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好！\n\n使用者 {originator} 已撤銷其對 {catalog} 型錄中 {appName} 應用程式的 {action} 申請。\n\n不需執行進一步動作。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好！\n\n使用者 {originator} 已撤銷其對 {catalog} 型錄中 {productName}\nAPI 產品版本 {version} 的 {action} 申請。\n\n不需執行進一步動作。\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `您好！此測試訊息來自已配置郵件伺服器 {{{mailServer}}} 中的 IBM API Connect。如果您如預期的那樣收到此訊息，則測試成功！`,
  [`Hello|header`]: `您好`,
  [`Help`]: `說明`,
  [`Hi, Steve (todo)`]: `Hi，Steve (todo)`,
  [`Hide`]: `隱藏`,
  [`Hide field`]: `隱藏欄位`,
  [`Hide in schema`]: `在綱目中隱藏`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `隱藏新的類型、欄位、指引或選用引數。將移除後端不再支援的項目。`,
  [`High`]: `高`,
  [`History`]: `歷程`,
  [`Histories`]: `歷程`,
  [`History log`]: `歷程日誌`,
  [`Home`]: `首頁`,
  [`Host`]: `主機`,
  [`Host context variable`]: `主機環境定義變數`,
  [`Host name`]: `主機名稱`,
  [`Host value (if present)`]: `主機值（如果存在的話）`,
  [`Hostname`]: `主機名稱`,
  [`Hours`]: `小時`,
  [`Http basic authentication schema`]: `HTTP 基本鑑別綱目`,
  [`Hmm, your search didn't return any results.`]: `嗯，您的搜尋沒有傳回任何結果。`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect 雲端`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud 鑑別`,
  [`IBM Cloud Docs`]: `IBM Cloud 文件`,
  [`IBM Schema`]: `IBM 綱目`,
  [`IBM managed`]: `IBM 管理`,
  [`ID Name`]: `ID 名稱`,
  [`ID token custom claims`]: `ID 記號自訂要求`,
  [`ID token issuer`]: `ID 記號發證者`,
  [`ID token signing algorithm`]: `ID 記號簽章演算法`,
  [`ID token signing crypto object`]: `ID 記號簽章加密物件`,
  [`ID token signing key`]: `ID 記號簽章金鑰`,
  [`ID token signing key identifier`]: `ID 記號簽章金鑰 ID`,
  [`IDENTITY PROVIDER`]: `身分提供者`,
  [`IDENTITY PROVIDER SUPPORT`]: `身分提供者支援`,
  [`Identification`]: `身分識別`,
  [`Identity extraction`]: `身分擷取`,
  [`If`]: `如果`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `如果已停用正式作業模式，則表示這是開發型錄，將強制執行所有發佈作業，並且自動解決任何衝突，例如，允許在測試期間反復重新發佈相同的產品版本。`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `如果已啟用正式作業模式，則表示這是正式作業型錄，如果與已發佈的任何產品有衝突，則系統會阻止您將產品發佈至型錄；必須在重試發佈作業之前解決任何衝突。`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `如果已在型錄中啟用空間，則可以管理該空間中的成員。您可以透過將使用者新增至空間並為使用者指派角色來管理空間成員資格。`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `如果已啟用，則將具有空值的輸入內容對映至輸出文件。否則，將從輸出中省略這些內容。`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `如果已啟用正式作業模式，將不會強制執行任何暫置及發佈動作。如果發生衝突，系統會自動解決。取消發佈動作也會自動執行。`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `如果收件者未收到電子郵件，請檢查電子郵件伺服器設定。`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `如果收件者未收到電子郵件，請檢查 {username} 的電子郵件伺服器設定。`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `如果值設為 true，則在 'invoke' 原則和 'proxy' 原則的 target-url 的查詢參數值中，會將所有 + 字元編碼為 %2B。`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `如果值設為 true，在呼叫 target-url 的變數定義所參照的任何要求參數會經過 URL 解碼。`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `如果值設為 true，'invoke' 原則會在 HTTP DELETE 方法上傳送有效負載。`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `如果設為 true，則會編寫或移除活動日誌中的內容。`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `如果設為 true，則會在執行開始時，編寫或移除輸入中的內容。`,
  [`If set to true, the property will be redacted or removed from the response.`]: `如果設為 true，則會編寫或移除回應中的內容。`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `如果已在型錄中啟用空間，您可以管理使用者在空間內具有的存取權。您可以透過指定指派給使用者角色的權限來管理存取權。建立新空間時依預設指派給每一個角色的權限，是由預設空間權限範本中的設定決定。`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `如果選取此勾選框（預設選項），則會從母元素繼承 XML 名稱空間。如果您希望對映原則使用明確的名稱空間，請清除勾選框。`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `如果選取此勾選框（預設選項），則會將 XML 名稱空間插入文件中最先用到它們的地方。如果您希望名稱空間全部定義在根元素上，請清除此勾選框。`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `如果選取此勾選框（預設選項），則對映原則的輸出會包含空白 XML 元素。如果您不希望對映原則的輸出包含空白 XML 元素，請清除勾選框。`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `如果選取了勾選框，則將啟用被對映 JSON 輸出的後處理。JSON 輸出的後處理將使用輸出綱目來確保該內容的值和綱目中定義的資料類型相同。它也將正規化由於 XML 輸入的物件對映而具有 Badgerfish JSON 語法的輸出內容值。如果被對映的 JSON 輸出不進行後處理，請清除這個勾選框（預設值）。`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `如果選取此勾選框（預設選項），則將解析在對映內容中找到的 API Connect 變數參照。如果您想要對映原則忽略對映原則中的 API Connect 變數參照，請清除此勾選框。`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `如果選取此勾選框，則將解析在對映內容中找到的 API Connect 變數參照。如果您想要對映原則忽略對映原則中的 API Connect 變數參照，請清除勾選框（預設選項）。`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `如果選取此勾選框（預設選項），又在輸入的遍訪中遇到陣列，則將僅使用第一個元素。如果您希望對映原則使用所有陣列元素，請清除此勾選框。`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `如果選取了勾選框，則其綱目已配置為布林值或數值類型的 XML 元素將轉換為該資料類型。如果未選取（預設選項），則所有 XML 元素值都將以字串形式傳回。`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `如果選取此勾選框，則複式綱目類型評估將以最佳化方式處理循環類型參照。清除此勾選框（預設選項）以標準方式評估這些綱目類型。`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `如果錯誤是由於缺少 CORS 支援所造成，請按一下鏈結以在新標籤中開啟伺服器。 如果瀏覽器顯示憑證問題，您可以選擇接受它，然後回到這裡以重新測試。`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `如果選取這個勾選框，則綱目中註明為必要的選用物件的任何輸出陣列或子項內容會將必要的子項物件內容起始設定為預設值，除非已順利對映。這將模擬針對這些物件的必要內容完成第 4 版對映的方式。清除這個勾選框（預設值）以僅輸出這些物件及順利對映的資料。`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `如果選取此勾選框（預設值）並變更擁有者使用者，則任何子項資源（空間）的所有權也會轉移給新擁有者。`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `如果選取這個選項，對映原則會將 XML 輸出寫入至 message.body，作為已剖析的 XML 文件。依預設，XML 會輸出成 XML 字串。其他任何變數的 XML 輸出一律會寫入成 XML 字串。`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `如果您針對「暫置」、「發佈」或「取代」動作啟用生命週期核准，則一些執行這些動作的 API 建立、編輯或測試作業可能無法正常運作。您確定要啟用其中的任何核准嗎？`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `如果您具有要透過 API Connect API 定義來公開的現有 SOAP 服務，則可以建立 API 並指定目標 SOAP 服務。API Connect 隨後會建立用於呼叫該 SOAP 服務的 API 定義。`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `如果您透過按一下「是。更新 API 組件」來儲存變更，則將也會更新此 OAuth 提供者所基於的 API 組件。不過，如果已自訂基礎 API 組件並且想要單獨更新該組件，請按一下「否。請勿更新 API 組件」，然後在 API 編輯器中更新基礎 API 組件。`,
  [`If you are still having issues, upload a schema definition language file.`]: `如果您還有問題，請上傳綱目定義語言檔。`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `如果檔案同時包含私密金鑰與公開金鑰，請在步驟 1 中將其上傳`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `如果您需要登錄一個有防火牆保護的閘道，請參閱 IBM Cloud 文件，以取得使用 VPN 或 Secure Gateway 服務來建立連線的相關資訊。`,
  [`Ignore`]: `忽略`,
  [`Implement policy`]: `實作原則`,
  [`Implementation file is required`]: `需要實作檔案`,
  [`Implicit`]: `隱含`,
  [`Import`]: `匯入`,
  [`Import API`]: `匯入 API`,
  [`Import API|title`]: `匯入 API`,
  [`Import OpenAPI definition from asset repository`]: `從資產儲存庫中匯入 OpenAPI 定義`,
  [`Import Product`]: `匯入產品`,
  [`Import policy`]: `匯入原則`,
  [`Import a product from a service URL`]: `從服務 URL 匯入產品`,
  [`Import a product from the definition file`]: `從定義檔匯入產品`,
  [`Import an existing product`]: `匯入現有的產品`,
  [`Import an OpenAPI definition from a URL`]: `從 URL 匯入 OpenAPI 定義`,
  [`Import an OpenAPI definition from a file`]: `從檔案匯入 OpenAPI 定義`,
  [`Import definitions`]: `匯入定義`,
  [`Import from existing API service`]: `從現有 API 服務匯入`,
  [`Import from file`]: `從檔案中匯入`,
  [`Importing an API definition`]: `匯入 API 定義`,
  [`Import|title`]: `匯入`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `將必要的憑證和 CA 組合匯入至「憑證管理程式」服務。`,
  [`In Cookie Header`]: `在 Cookie 標頭中`,
  [`In WSSec Header`]: `在 WSSec 標頭中`,
  [`In order to invoke the endpoints above, you need to first`]: `若要呼叫上面的端點，則需要先`,
  [`Inactive`]: `非作用中`,
  [`Inc`]: `Inc`,
  [`Include empty`]: `併入空白`,
  [`Include free trial days`]: `包含免費試用天數`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `無法選取與所配置閘道服務不相容的 API。`,
  [`Incorrect username or password`]: `不正確的使用者名稱或密碼`,
  [`Incorrect username, password, or credentials`]: `不正確的使用者名稱、密碼或認證`,
  [`Indicate if the invoke is the final action`]: `指出該項呼叫是否為最終動作`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `指出是否應將 JWT ID (jti) 要求新增至 JWT。如果已選取，則 jti 要求值將會是 UUID。`,
  [`Indicates whether the clients secret is required.`]: `指出用戶端密碼是否為必要項。`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `指出此 XSLT 輸入文件（或沒有任何輸入時）是否使用環境定義現行有效負載。`,
  [`Indicates whether to stop processing if client security fails.`]: `指出在用戶端安全失敗時是否要停止處理。`,
  [`Infer from JSON schema`]: `從 JSON 綱目推斷`,
  [`Infer from Sample`]: `從範例推斷`,
  [`Info`]: `資訊`,
  [`Information context variable`]: `資訊環境定義變數`,
  [`Inject proxy headers`]: `注入 Proxy 標頭`,
  [`Inline schema`]: `行內綱目`,
  [`Input`]: `輸入`,
  [`Input field`]: `輸入欄位`,
  [`Input object`]: `輸入物件`,
  [`Insecure Server Connections`]: `不安全的伺服器連線`,
  [`Install API Connect CLI & API Designer|button text`]: `安裝 API Connect CLI 與 API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `安裝 API Connect CLI 與 API Designer`,
  [`Install API Connect Toolkit`]: `安裝 API Connect 工具箱`,
  [`Install the API Connect plugin`]: `安裝 API Connect 外掛程式`,
  [`Instance ID`]: `實例 ID`,
  [`Instance name`]: `實例名稱`,
  [`Instance owner`]: `實例擁有者`,
  [`Interact to expand Tile`]: `互動以展開圖磚`,
  [`Interact to collapse Tile`]: `互動以收合圖磚`,
  [`Interval`]: `間隔`,
  [`Interface`]: `介面`,
  [`Introspect`]: `內部檢查`,
  [`Introspect cache type`]: `內部檢查快取類型`,
  [`Introspect from Server URL`]: `從伺服器 URL 內部檢查`,
  [`Introspect from URL`]: `從 URL 來內部檢查`,
  [`Introspect URL`]: `內部檢查 URL`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `使用不同 URL 來內部檢查不會變更 GraphQL Server URL。`,
  [`Introspect token`]: `內部檢查記號`,
  [`Introspection`]: `內部檢查`,
  [`Introspection Path`]: `內部檢查路徑`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `從伺服器或上傳綱目來內部檢查 GraphQL 綱目`,
  [`Introspect the GraphQL schema using the query`]: `使用查詢來內部檢查 GraphQL 綱目`,
  [`Introspection URL`]: `內部檢查 URL`,
  [`Invalid HOST URL`]: `主機 URL 無效`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `URL 無效。請輸入有效的 API Manager 主機 URL，例如 https://api-connect-host.com`,
  [`Invalid file URL`]: `檔案 URL 無效`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `無效的 URL 格式，開頭必須是 http:// 或 https://，並且包含至少一個字元`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `URL 格式無效，只需要指定主機名稱。亦即 syslog:// 可移除`,
  [`Invalid emails.`]: `電子郵件無效。`,
  [`Invalid JSONata expression`]: `JSONata 表示式無效`,
  [`Invalid remove target`]: `移除目標無效`,
  [`Invalid assumed size`]: `假設大小無效`,
  [`Invalid cost target`]: `成本目標無效`,
  [`Invalid list size`]: `清單大小無效`,
  [`Invalid port value, must not be negative`]: `埠值無效，不得為負數`,
  [`Invalid sized fields`]: `大小欄位無效`,
  [`Invalid value, length has to be greater than 1`]: `值無效，長度必須大於 1`,
  [`Invalid weight value`]: `加權值無效`,
  [`invitation`]: `邀請`,
  [`Invitation`]: `邀請`,
  [`Invitation timeout`]: `邀請逾時`,
  [`Invitation link will expire in {time}`]: `邀請鏈結將在 **{time}**後到期`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `邀請鏈結將在 **{seconds} 秒 ({time})** 後到期`,
  [`Invitation link will expire in __{value} {unit}__.`]: `邀請鏈結將在 __{value} {unit}__後到期。`,
  [`Invitation sent to`]: `邀請已送給`,
  [`Invitation sent to {email}`]: `邀請已送給 {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `邀請加入 IBM API Connect 中的 API 型錄`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `邀請加入 {catalog} Developer Portal 中的 API 消費者組織`,
  [`Invitation to an API provider organization in IBM API Connect`]: `邀請加入 IBM API Connect 中的 API 提供者組織`,
  [`Invitation to an API space in IBM API Connect`]: `邀請加入 IBM API Connect 中的 API 空間`,
  [`Invitation to an admin organization in IBM API Connect`]: `邀請加入 IBM API Connect 中的管理者組織`,
  [`Invitation to create an API catalog in IBM API Connect`]: `邀請在 IBM API Connect 中建立 API 型錄`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `邀請在 {catalog} Developer Portal 中建立 API 消費者組織`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `邀請在 IBM API Connect 中建立 API 提供者組織`,
  [`Invitation to create an API space in IBM API Connect`]: `邀請在 IBM API Connect 中建立 API 空間`,
  [`Invitation ttl has been created.`]: `已建立邀請 ttl。`,
  [`Invitation ttl has been updated.`]: `已更新邀請 ttl。`,
  [`Invitation ttl has not been created!`]: `尚未建立邀請 ttl！`,
  [`Invitation ttl has not been updated!`]: `尚未更新邀請 ttl！`,
  [`Invite`]: `邀請`,
  [`Invite catalog owner`]: `邀請型錄擁有者`,
  [`Invite consumer organization owner`]: `邀請消費者組織擁有者`,
  [`Invite member`]: `邀請成員`,
  [`Invite organization owner`]: `邀請組織擁有者`,
  [`Invite space owner`]: `邀請空間擁有者`,
  [`Invite a new consumer organization owner`]: `邀請新消費者組織擁有者`,
  [`Invite a new member`]: `邀請新成員`,
  [`Invite a new provider organization owner`]: `邀請新提供者組織擁有者`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `透過輸入收件者電子郵件位址來邀請新使用者登錄為型錄的管理員。這時會傳送邀請電子郵件給使用者，其中包含登錄表單的鏈結`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `透過輸入收件者電子郵件位址來邀請新使用者登錄為空間的管理員。這時會傳送邀請電子郵件給使用者，其中包含登錄表單的鏈結。`,
  [`Invite by email`]: `透過電子郵件邀請`,
  [`Invite from User Registry`]: `透過使用者登錄邀請`,
  [`Invite team member`]: `邀請團隊成員`,
  [`Inviting members to consumer organizations is enabled`]: `已啟用邀請成員加入消費者組織`,
  [`Invocations`]: `呼叫`,
  [`Invoke`]: `呼叫`,
  [`Issuer Claim`]: `發出者要求`,
  [`Issuer Origin`]: `發證者來源`,
  [`Issuer common name`]: `發證者通用名稱`,
  [`It might influence how the API is deployed`]: `它可能會影響 API 的部署方式`,
  [`Italian`]: `義大利文`,
  [`Items per page`]: `每頁項目數`,
  [`Items per page:`]: `每頁項目數：`,
  [`items selected`]: `個已選取項目`,
  [`item selected`]: `個已選取項目`,
  [`Iterate on`]: `反覆運算對象`,
  [`Iterate?`]: `反覆運算？`,
  [`JSON objects describing re-usable channel parameters.`]: `說明可重複使用通道參數的 JSON 物件。`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `說明 API 所耗用及產生之訊息的 JSON 物件。`,
  [`JSON Schema URL`]: `JSON 綱目 URL`,
  [`JSON Schema preview`]: `JSON 綱目預覽`,
  [`JSON Web Token (JWT)`]: `JSON Web 記號 (JWT)`,
  [`JSON has been successfully validated`]: `已順利驗證 JSON`,
  [`JSON schema is not valid. Please correct the error.`]: `JSON 綱目無效。請更正錯誤。`,
  [`JSON to XML`]: `JSON 至 XML`,
  [`JWT ID Claim`]: `JWT ID 要求`,
  [`JWT verification JWK`]: `JWT 驗證 JWK`,
  [`JWT verification crypto object`]: `JWT 驗證加密物件`,
  [`Japanese`]: `日文`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `工作佇列`,
  [`Jump to source`]: `跳至來源`,
  [`Just now`]: `剛才`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `金鑰交換/加密/MAC`,
  [`KEY SIZE`]: `金鑰大小`,
  [`Keystore`]: `金鑰儲存庫`,
  [`keystore`]: `金鑰儲存庫`,
  [`Keep`]: `保留`,
  [`Keep Payload`]: `保留有效負載`,
  [`Key Encryption Algorithm`]: `金鑰加密演算法`,
  [`Key Name`]: `金鑰名稱`,
  [`Key type`]: `金鑰類型`,
  [`Key name`]: `金鑰名稱`,
  [`Keystore for the token has been updated.`]: `已更新記號的金鑰儲存庫。`,
  [`Keystore Certificates`]: `金鑰儲存庫憑證`,
  [`Keystore/truststore`]: `金鑰儲存庫/信任儲存庫`,
  [`Korean`]: `韓文`,
  [`LABEL`]: `標籤`,
  [`LABELS`]: `標籤`,
  [`LAST MODIFIED`]: `前次修改時間`,
  [`Last state changed`]: `前次狀態變更時間`,
  [`Large`]: `大型`,
  [`Lax`]: `Lax`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAP 群組`,
  [`LDAP user registry`]: `LDAP 使用者登錄`,
  [`LDAP registry`]: `LDAP 登錄`,
  [`LDAP registry name`]: `LDAP 登錄名稱`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `角色的 LDAP 使用者登錄 {{name}} 不再支援外部群組對映。 在儲存之前，請為此使用者登錄啟用外部群組對映或停用角色中的外部群組對映。`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `角色的 LDAP 使用者登錄 {{name}} 不再支援外部群組對映。 在儲存之前，請選取另一個具有外部群組對映的使用者登錄或在角色中停用外部群組對映。`,
  [`LDAP search attribute`]: `LDAP 搜尋屬性`,
  [`LOCATED IN`]: `位於`,
  [`Located in`]: `位於`,
  [`LTPA Key`]: `LTPA 金鑰`,
  [`LTPA Key Details`]: `LTPA 金鑰詳細資料`,
  [`LTPA Keys`]: `LTPA 金鑰`,
  [`LTPA key file`]: `LTPA 金鑰檔`,
  [`LTPA key password`]: `LTPA 金鑰密碼`,
  [`LUR data version`]: `LUR 資料版本`,
  [`LUR schema update date`]: `LUR 綱目更新日期`,
  [`LUR schema version`]: `LUR 綱目版本`,
  [`LUR target data version`]: `LUR 目標資料版本`,
  [`LUR target schema version`]: `LUR 目標綱目版本`,
  [`LUR update date`]: `LUR 更新日期`,
  [`Last name`]: `姓氏`,
  [`Last modified`]: `前次修改時間`,
  [`Launch portal`]: `啟動入口網站`,
  [`Launch IBM Automation management console`]: `啟動 IBM 自動化管理主控台`,
  [`Launch plan upgrade`]: `啟動計劃升級`,
  [`Learn more`]: `進一步瞭解`,
  [`Learn More`]: `進一步瞭解`,
  [`Legacy`]: `舊式`,
  [`Length cannot be below {min}`]: `長度不能低於 {min}`,
  [`Length cannot exceed {max}`]: `長度不能超過 {max}`,
  [`Let's customize API Connect`]: `讓我們自訂 API Connect`,
  [`Let's get started...`]: `讓我們開始使用...`,
  [`Let's get you up and running`]: `讓我們開始進行`,
  [`License`]: `授權`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `生命週期`,
  [`Lifecycle approvals`]: `生命週期核准`,
  [`Lifecycle actions have been updated`]: `已更新生命週期動作`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `已針對沙箱型錄啟用生命週期核准。若要使用自動「啟動 API」選項，您必須先在「沙箱型錄」設定中停用生命週期核准。`,
  [`Limited fields`]: `限制的欄位`,
  [`Limit API calls on a per key basis`]: `以每一個金鑰為基礎限制 API 呼叫數`,
  [`Limit fields`]: `限制欄位`,
  [`Limit renegotiation`]: `限制重新協議`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `載入`,
  [`Load query`]: `載入查詢`,
  [`Load filter`]: `載入過濾器`,
  [`Load schema from WSDL`]: `從 WSDL 載入綱目`,
  [`Loading`]: `載入中`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `「本端使用者登錄 (LUR)」是包含在 API Connect 中且在本端儲存的預設使用者登錄。`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `「本端使用者登錄」是包含在 API Connect 中且在本端儲存的預設使用者登錄。在安裝 API Connect 期間，會安裝及配置兩個 LUR。可能還會配置其他 LUR。`,
  [`Local user registry`]: `本端使用者登錄`,
  [`Local User Registry has been created.`]: `已建立本端使用者登錄。`,
  [`Location`]: `位置`,
  [`Location of ciphertext to decrypt`]: `要解密之密文的位置`,
  [`Location of plaintext information to encrypt`]: `要加密之純文字資訊的位置`,
  [`Log`]: `日誌`,
  [`Log in`]: `登入`,
  [`Log in to:`]: `登入至：`,
  [`Log in with a different account`]: `以不同帳戶登入`,
  [`Log into IBM Cloud`]: `登入 IBM Cloud`,
  [`Log out`]: `登出`,
  [`Log in using the {userRegistryType} user registry`]: `使用 {userRegistryType} 使用者登錄來登入`,
  [`Logging in with IBM Cloud ...`]: `使用 IBM Cloud 登入...`,
  [`Logging out`]: `正在登出`,
  [`Logic`]: `邏輯`,
  [`Login`]: `登入`,
  [`Logs`]: `日誌`,
  [`Longer, descriptive name for this policy.`]: `此原則的完整敘述性名稱。`,
  [`Looks like the server is experiencing an error while processing your request.`]: `在處理您的申請時，伺服器似乎發生錯誤。`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `郵件伺服器`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `成員`,
  [`MIME Types`]: `MIME 類型`,
  [`Mail server has been deleted.`]: `郵件伺服器已刪除。`,
  [`Mail server has not been deleted.`]: `郵件伺服器尚未刪除。`,
  [`Mailserver {arg} has been removed.`]: `已移除郵件伺服器 {arg}。`,
  [`Make sure that the server is running and that there is network connectivity.`]: `請確定伺服器在執行中而且有網路連線功能。`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `這可能是因為目標伺服器缺少 CORS 支援、伺服器無法使用、發現不受信任的憑證，或需要相互 SSL 鑑別。`,
  [`This might be caused by one or more of the following:`]: `這可能是由下列一或多個原因所造成：`,
  [`Make a call and move on`]: `發出呼叫並繼續進行`,
  [`Make a call and wait for response`]: `發出呼叫並等待回應`,
  [`Manage`]: `管理`,
  [`Manage API life cycles`]: `管理 API 生命週期`,
  [`Manage API product lifecycle approval permissions`]: `管理 API 產品生命週期核准權限`,
  [`Manage API product lifecycle approvals permissions`]: `管理 API 產品生命週期核准權限`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `管理 API 提供者組織、本端和遠端閘道以及相關設定。`,
  [`Manage APIs`]: `管理 API`,
  [`Manage Catalogs`]: `管理型錄`,
  [`Manage Organizations`]: `管理組織`,
  [`Manage Resources`]: `管理資源`,
  [`Manage Settings`]: `管理設定`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `管理 API 分析的目的地，包括：API Connect、HTTP、Kafka、Elasticsearch 和 Syslog。`,
  [`Manage local and remote gateways that secure your APIs.`]: `管理用來保護您 API 安全的本端和遠端閘道。`,
  [`Manage catalogs`]: `管理型錄`,
  [`Manage organizations`]: `管理組織`,
  [`Manage resources`]: `管理資源`,
  [`Manage settings`]: `管理設定`,
  [`Manage users`]: `管理使用者`,
  [`Manage active APIs and consumers`]: `管理作用中的 API 和消費者`,
  [`Manage availability zones and services`]: `管理可用性區域及服務`,
  [`Manage policies`]: `管理原則`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `管理來自應用程式開發者的產品生命週期變更要求及 API 訂閱和應用程式升級要求`,
  [`Manage roles and permissions`]: `管理角色和權限`,
  [`Manage the OAuth providers configured for API Manager`]: `管理為 API Manager 配置的 OAuth 提供者`,
  [`Manage the user registries configured for API Manager`]: `管理已配置給 API Manager 的使用者登錄`,
  [`Manage the user registries configured for Cloud Manager`]: `管理為 Cloud Manager 配置的使用者登錄`,
  [`Management`]: `管理`,
  [`Management Endpoint`]: `管理端點`,
  [`Management endpoint`]: `管理端點`,
  [`Management endpoint on the analytics service`]: `分析服務的管理端點`,
  [`Service endpoint configuration`]: `服務端點配置`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `管理服務用來將配置資訊傳送至入口網站服務的端點`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `管理服務在與入口網站服務通訊時使用的 TLS 用戶端設定檔`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `管理服務用來將配置資訊傳送至閘道服務的端點`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `管理服務在與閘道服務通訊時使用的 TLS 用戶端設定檔`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `對閘道與管理服務之間的內部資料流量使用叢集內通訊`,
  [`Use in-cluster communication for both ingestion and queries`]: `同時對汲取和查詢使用叢集內通訊`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `叢集內用於汲取，外部用於查詢，反之亦然`,
  [`Use external communication for both ingestion and queries`]: `同時對汲取和查詢使用外部通訊`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `對分析與管理服務之間的內部資料流量使用叢集內通訊`,
  [`Management endpoint on the gateway service`]: `閘道服務上的管理端點`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `將叢集內通訊用於入口網站與管理服務之間的內部資料流量`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `如果您想要在管理與入口網站子系統之間使用叢集內通訊，請啟用此交換器。 如果您不確定，請維持停用此交換器（預設值）。`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `如果您想要在管理與閘道子系統之間使用叢集內通訊，請啟用此交換器。 如果您不確定，請維持停用此交換器（預設值）。`,
  [`Management endpoint on the portal service`]: `入口網站服務的管理端點`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `此選項是預設值，用於從 pre-10.0.5.3 之前版本升級的分析服務。 對於 OVA 部署，或者如果您的分析子系統位於不同的環境中，則外部是您可以使用的唯一選項。 閘道和管理服務兩者都使用外部端點與分析服務進行通訊，這是視您的平台而定的 Kubernetes 入口或 OpenShift 路徑。`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `如果您具有 Kubernetes 或 OpenShift 部署，其中所有子系統都位於相同叢集內，則可以選取叢集內。 選取此選項時，管理、閘道及分析子系統會透過內部服務端點彼此通訊，而不是透過外部可存取的進入 (Kubernetes) 或路徑 (OpenShift)。`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `如果您具有 Kubernetes 或 OpenShift 部署，且部分子系統安裝在同一叢集裡，則可以選取此選項。 選取此選項時，您可以針對不同的分析通訊流程使用不同的通訊方法。 例如，分析通訊的管理可以在叢集內使用，而分析的閘道可以在外部使用。 如果您的閘道位於與其餘子系統不同的叢集中，則可以選擇此配置。`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `如果您在登錄分析服務時指定叢集內通訊，則可以啟用閘道將 API 事件資料傳送至內部分析端點。`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `使用內部端點從閘道服務汲取分析資料`,
  [`Endpoint configuration`]: `同時對汲取和查詢使用外部通訊`,
  [`Portal endpoint configuration`]: `入口網站端點配置`,
  [`URL used by developer portal users to access the portal website`]: `開發者入口網站使用者用來存取入口網站的 URL`,
  [`External ingestion endpoint on the analytics service`]: `分析服務上的外部汲取端點`,
  [`TLS client profile for external analytics ingestion endpoint`]: `外部分析汲取端點的 TLS 用戶端設定檔`,
  [`Internal ingestion endpoint on the analytics service`]: `分析服務上的內部汲取端點`,
  [`TLS client profile for internal service endpoint`]: `內部服務端點的 TLS 用戶端設定檔`,
  [`Manager`]: `管理程式`,
  [`Manager Request Reset Password`]: `管理程式要求重設密碼`,
  [`Manager Reset Password`]: `管理程式重設密碼`,
  [`Manager Sign In`]: `管理程式登入`,
  [`Manages API provider organizations`]: `管理 API 提供者組織`,
  [`Manages application developer communities`]: `管理應用程式開發者社群`,
  [`Manages the API product lifecycle`]: `管理 API 產品生命週期`,
  [`Manage|link`]: `管理`,
  [`Manage|permission`]: `管理`,
  [`Manage|title`]: `管理：標題`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `管理記號的金鑰儲存庫指派和金鑰儲存庫歷程`,
  [`Map`]: `對映`,
  [`Map information from OIDC provider to an APIC user`]: `將資訊從 OIDC 提供者對映至 APIC 使用者`,
  [`Map the migration source plans to the migration target plans`]: `將移轉來源方案對映至移轉目標方案`,
  [`Map the roles to groups`]: `將角色對映至群組`,
  [`Mapped from: `]: `對映自： `,
  [`Marketing`]: `行銷`,
  [`Maximize / minimize`]: `最大化/最小化`,
  [`Maximum Consent Time to Live (seconds)`]: `同意存活時間上限（秒）`,
  [`Maximum consent`]: `同意上限`,
  [`Menu`]: `功能表`,
  [`Member`]: `成員`,
  [`Member Invitation`]: `成員邀請`,
  [`Member Invitation has been deleted.`]: `成員邀請已經刪除。`,
  [`Member has been deleted.`]: `成員已經刪除。`,
  [`Member {name} created`]: `已建立成員 {name}`,
  [`Member {title} has been created.`]: `已建立成員 {title}。`,
  [`Members`]: `成員`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `成員邀請`,
  [`Member Roles`]: `成員角色`,
  [`Merchant ID`]: `特約商家 ID`,
  [`Merge`]: `合併`,
  [`Merge this mapping with any others that operate on the same target array?`]: `將此對映與相同目標陣列上運作的其他任何對映合併嗎？`,
  [`Message`]: `訊息`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `剖析訊息`,
  [`Message for resulting parsed data`]: `產生之剖析資料的訊息`,
  [`Metadata`]: `Meta 資料`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `meta 資料可讓您在存取記號產生程序期間收集要併入的自訂 meta 資料。 您可以透過鑑別 URL 及/或用來儲存自訂 meta 資料的遠端伺服器來收集 meta 資料。`,
  [`Micro Gateway policies`]: `Micro 閘道原則`,
  [`Migrate`]: `移轉`,
  [`Migrate Subscriptions`]: `移轉訂閱`,
  [`Migrate plans`]: `移轉方案`,
  [`Migrate subscription`]: `移轉訂閱`,
  [`Migrate subscriptions`]: `移轉訂閱`,
  [`Migration Target`]: `移轉目標`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `已執行從 "{source}"（產品）至 "{target}"（產品）的移轉`,
  [`Migration from "{source}" failed`]: `從 "{source}" 移轉失敗`,
  [`Migration source`]: `移轉來源`,
  [`Migration target`]: `移轉目標`,
  [`Minimum role`]: `最小角色`,
  [`Minimum output escaping rule`]: `最小輸出跳出規則`,
  [`Minutes`]: `分鐘`,
  [`Mode`]: `模式`,
  [`Modifications`]: `修改`,
  [`Modified`]: `已修改`,
  [`More`]: `更多`,
  [`More than 20 events are waiting to be processed`]: `超過 20 個事件等待處理`,
  [`Move down`]: `下移`,
  [`Move up`]: `上移`,
  [`Must be a number`]: `必須是數字`,
  [`Must be a valid URI`]: `必須是有效的 URI`,
  [`Must be a valid email`]: `必須是有效的電子郵件`,
  [`Must be a valid path starting with /`]: `必須是以 / 開頭的有效路徑`,
  [`Must be a valid url`]: `必須是有效的 URL`,
  [`Must be an integer`]: `必須是整數`,
  [`Must be a valid url with no protocol`]: `必須是沒有通訊協定的有效 URL`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `格式必須是 64 個十六進位字元（字首為 "0x"）`,
  [`Must start with '/'`]: `開頭必須是 '/'`,
  [`Must start with https://`]: `開頭必須是 https://`,
  [`Must start with https:// or $(`]: `開頭必須是 https:// 或 $(`,
  [`Mutual auth`]: `交互鑑別`,
  [`Mutual authentication`]: `交互鑑別`,
  [`My account`]: `我的帳戶`,
  [`Name`]: `名稱`,
  [`NONCE`]: `暫時性要求`,
  [`NOT`]: `NOT`,
  [`Name cannot be empty.`]: `名稱不能為空白。`,
  [`Name of Schema in OpenAPI Definitions`]: `OpenAPI 定義中綱目的名稱`,
  [`Name of the LDAP user password attribute.`]: `LDAP 使用者密碼屬性的名稱。`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `驗證時依據的 LDAP 使用者登錄名稱。請從清單中選取，或手動輸入。`,
  [`Name of the organization`]: `組織的名稱`,
  [`Namespace inheritance`]: `名稱空間繼承`,
  [`Namespace inlining`]: `名稱空間列入`,
  [`Native`]: `原生`,
  [`Native OAuth provider`]: `原生 OAuth 提供者`,
  [`Native OAuth provider summary`]: `原生 OAuth 提供者摘要`,
  [`Navigate to Source View`]: `導覽至程式碼視圖`,
  [`New`]: `新建`,
  [`New API`]: `新建 API`,
  [`New API Connect connection`]: `新建 API Connect 連線`,
  [`New Application`]: `新建應用程式`,
  [`New Catalog`]: `新建型錄`,
  [`New Consumer organization`]: `新消費者組織`,
  [`New Endpoint`]: `新建端點`,
  [`New native OAuth provider`]: `新建原生 OAuth 提供者`,
  [`New OpenAPI`]: `新建 OpenAPI`,
  [`New OpenAPI directory`]: `新建 OpenAPI 目錄`,
  [`New Organization Title`]: `新組織標題`,
  [`New Parameter`]: `新增參數`,
  [`New Password`]: `新密碼`,
  [`New product`]: `新產品`,
  [`New Role`]: `新建角色`,
  [`New schema`]: `新綱目`,
  [`New Test`]: `新建測試`,
  [`New third party OAuth provider`]: `新建協力廠商 OAuth 提供者`,
  [`New user`]: `新增使用者`,
  [`New applications will be created in the development state`]: `將在開發狀態下建立新的應用程式`,
  [`Next`]: `下一步`,
  [`Next page`]: `下一頁`,
  [`No`]: `否`,
  [`No account?`]: `沒有帳戶？`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `沙箱型錄中沒有配置任何鑑別 URL 或 LDAP 使用者登錄`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `沙箱型錄中沒有配置任何鑑別 URL 或 LDAP 使用者登錄，請建立一個 [這裡]({link})`,
  [`No Billing`]: `無計費`,
  [`No billing integration has been selected.`]: `未選取任何計費整合。`,
  [`No billing integration can be shown when offline.`]: `離線時無法顯示計費整合。`,
  [`No gateways`]: `無閘道`,
  [`No cache`]: `無快取`,
  [`No Cache`]: `無快取`,
  [`No Certificate Manager services available`]: `沒有可用的「憑證管理程式」服務`,
  [`No default gateways`]: `無預設閘道`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `使用者登錄內沒有重複的電子郵件位址。這僅適用於具有電子郵件的使用者。`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `沒有為沙箱型錄配置相容的閘道。將不會啟用瀏覽器中的「API 測試」（試用）。`,
  [`No Errors`]: `無錯誤`,
  [`No Histories found`]: `找不到歷程`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `找不到閘道服務。請配置閘道服務以顯示支援的組件原則`,
  [`No gateways are configured for sandbox catalog`]: `沒有為沙箱型錄配置閘道`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `沒有為沙箱型錄配置閘道。將不會啟用瀏覽器中的「API 測試」（試用）`,
  [`No LDAP or authentication URL user registries found.`]: `找不到 LDAP 或鑑別 URL 使用者登錄。`,
  [`No limit on size of list`]: `清單大小無限制`,
  [`No OAuth providers configured in the sandbox catalog`]: `沙箱型錄中沒有配置 OAuth 提供者`,
  [`No OAuth providers configured in the selected catalog`]: `所選取的型錄中沒有配置 OAuth 提供者`,
  [`No Permission`]: `無權限`,
  [`No portal added to this catalog`]: `沒有入口網站新增至此型錄`,
  [`No Roles exist`]: `不存在任何角色`,
  [`No recent items found.`]: `找不到最近的項目。`,
  [`No TLS profile`]: `無 TLS 設定檔`,
  [`No TLS client profile`]: `沒有 TLS 用戶端設定檔`,
  [`No Truststore`]: `無信任儲存庫`,
  [`No analytics service is configured`]: `未配置分析服務`,
  [`No analytics services found. You can create one [here]({link})`]: `找不到分析服務。您可以在 [這裡]({link}) 建立一個`,
  [`No assemblies found.`]: `找不到組件。`,
  [`No available extensions`]: `沒有可用的延伸`,
  [`No compatible flows configured in this OAuth provider`]: `此 OAuth 提供者中沒有配置相容的流程`,
  [`No consumer organization will be able to see this product.`]: `沒有任何消費者組織可查看此產品。`,
  [`No debug data was found for this API call`]: `找不到此 API 呼叫的除錯資料`,
  [`No details are available.`]: `沒有可用的詳細資料。`,
  [`No existing products`]: `無現有產品`,
  [`No extensions have been configured for the API`]: `尚未給 API 配置任何延伸`,
  [`No flow configured in this OAuth provider`]: `此 OAuth 提供者中沒有配置流程`,
  [`No items found`]: `找不到項目`,
  [`No non-member user to add.`]: `沒有非成員使用者可以新增。`,
  [`No organization found`]: `找不到組織`,
  [`No portal service exists:`]: `不存在入口網站服務：`,
  [`No product APIs`]: `無產品 API`,
  [`No products or plans were found.`]: `找不到任何產品或方案。`,
  [`No provider organizations`]: `無提供者組織`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `未收到回應。原因包括目標伺服器缺少 CORS 支援、伺服器無法使用、發現未授信憑證或需要相互 SSL 驗證。`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `沒有可用的資源群組。若要開始，請在「IBM Cloud 帳戶」資源中建立資源群組。`,
  [`No security`]: `無安全`,
  [`No services found`]: `找不到服務`,
  [`No warnings`]: `無警告`,
  [`No warnings.`]: `無警告。`,
  [`No. Do not update API Assembly`]: `否。請勿更新 API 組件`,
  [`Non-blocking`]: `未封鎖`,
  [`Non int slicing arguments`]: `非整型切割引數`,
  [`None`]: `無`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `配置的 OAuth 提供者都不符合此 API 的閘道類型`,
  [`Normal`]: `正常`,
  [`Not Available`]: `無法使用`,
  [`Not applicable for application grant type.`]: `不適用於應用程式授權類型。`,
  [`Not valid before`]: `生效時間`,
  [`Not valid after`]: `失效時間`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `附註：當選取「產生自動產品」選項時，會停用此選項。`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `附註：此選項在選取「產生自動產品」選項時會停用，且將針對產生的產品改為使用「預設方案」`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `附註：「閘道服務」喜好設定將用作所有其他喜好設定的範圍。 將根據此設定載入原則及其他閘道服務特定的內容。`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `附註：此選項僅供參考，且會根據上方的測試應用程式選項進行更新`,
  [`Notices`]: `注意事項`,
  [`Notification template`]: `通知範本`,
  [`Notification templates`]: `通知範本`,
  [`Notification server configured`]: `已配置通知伺服器`,
  [`Notifications`]: `通知`,
  [`Null Badgerfish`]: `空值 Badgerfish`,
  [`Null`]: `空值`,
  [`Number`]: `數字`,
  [`Number of Operations`]: `作業數目`,
  [`Numeric`]: `數值`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth 提供者`,
  [`OAuth provider`]: `OAuth 提供者`,
  [`OAuth Provider Policy Assembly`]: `OAuth 提供者原則組件`,
  [`OAuth providers`]: `OAuth 提供者`,
  [`OAuth Redirect URL`]: `OAuth 重新導向 URL`,
  [`OAuth shared secret`]: `OAuth 共用密碼`,
  [`OAuth provider policy assembly`]: `OAuth 提供者原則組件`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `找不到 OAuth 提供者 {currentProviderName}。請選取已配置的 OAuth 提供者。`,
  [`OAuth redirect URL`]: `OAuth 重新導向 URL`,
  [`OAuth Shared Secret`]: `OAuth 共用密碼`,
  [`OAuth Signature Method`]: `OAuth 簽章方法`,
  [`OAuth signature method`]: `OAuth 簽章方法`,
  [`OAuth providers can be created and managed in the following list.`]: `可以在下列清單中建立與管理 OAuth 提供者。`,
  [`OAuth symmetric key name`]: `OAuth 對稱金鑰名稱`,
  [`OAuth symmetric key value`]: `OAuth 對稱金鑰值`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2 存取碼安全`,
  [`OAuth2 Application Security`]: `OAuth2 應用程式安全`,
  [`OAuth2 Implicit Security`]: `OAuth2 隱含安全`,
  [`OAuth2 Password Security`]: `OAuth2 密碼安全`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `確定`,
  [`OPERATION ID`]: `作業 ID`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `排序`,
  [`ORGANIZATION`]: `組織`,
  [`ORGANIZATION MANAGER`]: `組織管理員`,
  [`Organizations`]: `組織`,
  [`Owner`]: `擁有者`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `啟用空間後，會在每個空間中管理 OAuth 提供者。請移至空間設定以管理 OAuth 提供者。`,
  [`Off`]: `關閉`,
  [`Offline`]: `離線`,
  [`Old schema`]: `舊綱目`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch 容許搜尋提供者組織或型錄內的 API、產品、應用程式、訂閱和其他資源。此外，它也有助於探索這些資源之間的關係。`,
  [`Omnisearch for all supported list pages`]: `使用 Omnisearch 來搜尋所有支援的清單頁面`,
  [`Omnisearch for draft APIs and products`]: `使用 Omnisearch 來搜尋草稿 API 和產品`,
  [`Omnisearch for published products in catalogs and spaces`]: `使用 Omnisearch 來搜尋已發佈至型錄和空間中的產品`,
  [`Omnisearch for consumer organizations`]: `使用 Omnisearch 來搜尋消費者組織`,
  [`Omnisearch for applications`]: `使用 Omnisearch 來搜尋應用程式`,
  [`Omnisearch for requested approvals`]: `使用 Omnisearch 來搜尋所要求的核准`,
  [`Omnisearch for approvals tasks`]: `使用 Omnisearch 來搜尋核准作業`,
  [`Omnisearch for product apis`]: `使用 Omnisearch 來搜尋產品 API`,
  [`Omnisearch for subscriptions`]: `使用 Omnisearch 來搜尋訂閱`,
  [`Omnisearch for members`]: `使用 Omnisearch 來搜尋成員`,
  [`Omnisearch for provider organizations`]: `使用 Omnisearch 來搜尋提供者組織`,
  [`Disable the new editor for OpenAPI2`]: `停用 OpenAPI2 的新編輯器`,
  [`On`]: `開啟`,
  [`Onboarding`]: `上線`,
  [`Onboarding Edit Admin Org`]: `編輯管理組織上線`,
  [`Onboarding Edit Cloud Org`]: `編輯雲端組織上線`,
  [`Onboarding Edit Reset Password`]: `編輯重設密碼上線`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `安裝後，使用 IBM Cloud CLI 將您在其中供應 API Connect 的帳戶和區域鎖定為目標，然後透過列出您的所有 API Connect 提供者組織來試用 CLI`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `一個以上的 JSON 物件，說明 API 所耗用及產生的綱目。`,
  [`One or more JSON representations for parameters`]: `參數的一種以上 JSON 表示法`,
  [`One time use access token`]: `一次性使用存取記號`,
  [`One time use refresh token`]: `一次性使用重新整理記號`,
  [`Online`]: `線上`,
  [`Only one API file should be present in the zip file.`]: `在 zip 檔中只能有一個 API 檔案。`,
  [`OpenAPI Specification Version`]: `OpenAPI 規格版本`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) 根據 OAuth 2.0 提供其他鑑別通訊協定。OIDC 提供以 JSON Web 記號 (JWT) 編碼的使用者資訊。當您啟用 OpenID Connect 時，系統會提供一個範本以產生 ID 記號及存取記號，並且會自動建立必要的組件原則。`,
  [`Open a Directory`]: `開啟目錄`,
  [`Open in external editor`]: `在外部編輯器中開啟`,
  [`Open the server`]: `開啟伺服器`,
  [`OpenAPI extensions`]: `OpenAPI 延伸`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `閘道版本為 6000 時尚不支援 OpenID Connect`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `只有在所選授權類型包含下列一或多個項目時，OpenID Connect 設定才適用：隱含和存取碼。`,
  [`Operation`]: `作業`,
  [`Operation Id`]: `作業 ID`,
  [`Operation switch`]: `作業切換`,
  [`Operation Switch`]: `作業切換`,
  [`Operations`]: `作業`,
  [`Operator build date`]: `Operator 建置日期`,
  [`Operator build tag`]: `Operator 建置標籤`,
  [`Operator version`]: `Operator 版本`,
  [`Optionally select a CA bundle`]: `選擇性選取 CA 組合`,
  [`Optimize schema definition`]: `最佳化綱目定義`,
  [`Optional`]: `選用`,
  [`Or`]: `或`,
  [`Or create a new application and subscribe to the selected plan`]: `或者，建立新的應用程式並訂閱選取的方案`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `或者，建立新的產品，並將它發佈至您的沙箱型錄`,
  [`Order`]: `排序`,
  [`Org`]: `組織`,
  [`Org owner`]: `組織擁有者`,
  [`Organization`]: `組織`,
  [`Organization:`]: `組織：`,
  [`Organization information`]: `組織資訊`,
  [`Organization Manager`]: `組織管理員`,
  [`Organization has been updated.`]: `已更新組織。`,
  [`Organizations will be listed here.`]: `組織將在這裡列出。`,
  [`Otherwise`]: `否則`,
  [`Output`]: `輸出`,
  [`Output Claims`]: `輸出要求`,
  [`Output array`]: `輸出陣列`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `輸出根元素，即使它不是可讓 XML 文件形式完整的必要項目也一樣。`,
  [`Output variable`]: `輸出變數`,
  [`Override API consume types`]: `置換 API 耗用類型`,
  [`Override API produce types`]: `置換 API 產生類型`,
  [`Override API security definitions`]: `置換 API 安全定義`,
  [`Override rate limits`]: `置換速率限制`,
  [`Override default "provider" settings with configuration from this URL`]: `使用來自此 URL 的配置來置換預設 "provider" 設定`,
  [`Override default "provider" settings with configuration from this literal`]: `使用來自此文字的配置來置換預設 "provider" 設定`,
  [`Override plan rate limits`]: `置換方案速率限制`,
  [`Override plan rate limits for individual operation`]: `置換個別作業的方案速率限制`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `將消費者組織邀請逾時值置換為型錄邀請逾時值`,
  [`Overview`]: `概觀`,
  [`Owner scope check`]: `擁有者範圍檢查`,
  [`Owner's Email`]: `擁有者的電子郵件`,
  [`Owner's Name`]: `擁有者的名稱`,
  [`Owner's email`]: `擁有者的電子郵件`,
  [`Owns and administers the API provider organization`]: `擁有及管理 API 提供者組織`,
  [`Owns and administers the admin organization`]: `擁有及管理管理者組織`,
  [`Owns and administers the app developer organization`]: `擁有及管理應用程式開發者組織`,
  [`Owns and administrates the app developer organization`]: `擁有及管理應用程式開發者組織`,
  [`PARAMETER NAME`]: `參數名稱`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `用來驗證「對象 (aud) 要求」的 PCRE。`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `用來驗證「發出者 (iss) 要求」的 PCRE。`,
  [`PER`]: `每`,
  [`Per`]: `每`,
  [`Plan`]: `方案`,
  [`Plan:`]: `方案：`,
  [`pending Consumer organization invitation`]: `擱置消費者組織邀請`,
  [`PLAN`]: `方案`,
  [`PLANS`]: `方案`,
  [`Policy`]: `原則`,
  [`policy`]: `原則`,
  [`POLICIES`]: `原則`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `產品`,
  [`Product Plan`]: `產品計劃`,
  [`Product Plans`]: `產品計劃`,
  [`Product Plans per page`]: `每頁產品計劃數`,
  [`Property name`]: `內容名稱`,
  [`Property type`]: `內容類型`,
  [`Property example`]: `內容範例`,
  [`Property description`]: `內容說明`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `找不到頁面`,
  [`Parameter name`]: `參數名稱`,
  [`Parameter control`]: `參數控制項`,
  [`Parameters`]: `參數`,
  [`Parent`]: `母項`,
  [`Parse`]: `剖析`,
  [`Parse settings URL reference`]: `剖析設定 URL 參照`,
  [`Parse settings literal configuration`]: `剖析設定文字配置`,
  [`Parse settings object reference`]: `剖析設定物件參照`,
  [`Parse the GraphQL query`]: `剖析 GraphQL 查詢`,
  [`Parse the response from the GraphQL backend server`]: `剖析 GraphQL 後端伺服器的回應`,
  [`Parse XML output`]: `剖析 XML 輸出`,
  [`Pass-through`]: `透通`,
  [`Password`]: `密碼`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `IBM API Connect 中您的使用者帳戶密碼已變更`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `{portalTitle} Developer Portal 中您的使用者帳戶密碼已變更`,
  [`Password context variable`]: `密碼環境定義變數`,
  [`Password is invalid. Re-enter the password.`]: `密碼無效。請重新輸入密碼。`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `IBM API Connect 中您使用者帳戶的密碼重設申請`,
  [`Password reset request for your {{catalog}} developer portal account`]: `{catalog} Developer Portal 帳戶的密碼重設申請`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `密碼必須至少有一個字元是下列種類的其中之兩：小寫、大寫、數字及標點符號（例如 !、$、#、%）`,
  [`Passwords must contain eight or more characters`]: `密碼必須包含 8 個以上字元`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `密碼必須包含 8 個以上的字元，且至少含有下列種類中的兩種字元：小寫、大寫、數字及標點符號（例如 !、$、#、%）。此外，同一個字元不能連續使用兩次以上。`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `同一個字元不能在密碼中連續使用兩次以上。`,
  [`Passwords should match`]: `密碼應該相符`,
  [`Path`]: `路徑`,
  [`Path parameters`]: `路徑參數`,
  [`Path name`]: `路徑名稱`,
  [`Paths`]: `路徑`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `路徑可識別 API 所公開的 REST 資源。作業結合路徑與 HTTP 動詞、參數及定義以用於要求和回應。`,
  [`Payments Authorization`]: `付款授權`,
  [`Payment method`]: `付款方式`,
  [`Pending`]: `擱置中`,
  [`Pending State`]: `擱置狀態`,
  [`Pending approval to be published in catalog`]: `在型錄中發佈的核准擱置中`,
  [`Pending approval to be staged in catalog`]: `在型錄中暫置的核准擱置中`,
  [`Pending consumer organization invitations`]: `擱置的消費者組織邀請`,
  [`View endpoints`]: `檢視端點`,
  [`View base endpoints for this API`]: `檢視這個 API 的基本端點`,
  [`View pending invitations`]: `檢視擱置邀請`,
  [`Permission Denied`]: `權限遭拒`,
  [`Permissions`]: `權限`,
  [`Persistent Connection`]: `持續性連線`,
  [`Pin menu`]: `固定功能表`,
  [`PlainText`]: `純文字`,
  [`Plan APIs`]: `方案 API`,
  [`Plan burst limits`]: `方案高載限制`,
  [`Plan by name`]: `方案（依名稱）`,
  [`Plan Default`]: `方案預設值`,
  [`Plan Details`]: `方案詳細資料`,
  [`Plan migration targets`]: `方案移轉目標`,
  [`Plan rate limits`]: `方案速率限制`,
  [`Plan name`]: `方案名稱`,
  [`Plan pricing`]: `計價方案`,
  [`Plan title already exists`]: `方案標題已存在`,
  [`Plans`]: `方案`,
  [`Platform REST API endpoint for admin and provider APIs`]: `管理者和提供者 API 的平台 REST API 端點`,
  [`Platform REST API endpoint for consumer APIs`]: `消費者 API 的平台 REST API 端點`,
  [`Please choose a bind method`]: `請選擇連結方法`,
  [`Please choose an authentication method`]: `請選擇鑑別方法`,
  [`Please contact the system administrator.`]: `請聯絡系統管理者。`,
  [`Please contact your administrator for support.`]: `請與管理者聯絡，以取得支援。`,
  [`Please enter the reason.`]: `請輸入原因。`,
  [`Please enter your username`]: `請輸入您的使用者名稱`,
  [`Please go to the space settings to manage API endpoints.`]: `請移至空間設定以管理 API 端點。`,
  [`Please make sure the server is online and try again.`]: `請確定伺服器在線上，然後再試一次。`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `請注意，如果停用活動日誌，則在成功或出錯時，將會移除與要記載的內容類型相關聯的內容。確定要停用活動日誌嗎？`,
  [`Please review the license for API Connect by accessing`]: `請檢閱 API Connect 的授權，方法是存取`,
  [`Please select a TLS`]: `請選取 TLS`,
  [`Please select a TLS client profile`]: `請選取 TLS 用戶端設定檔`,
  [`Please select a group type`]: `請選取群組類型`,
  [`Please select an LDAP protocol version`]: `請選取一個 LDAP 通訊協定版本`,
  [`Please select authentication method`]: `請選取鑑別方法`,
  [`Please select ciphers from the list below:`]: `請從下列清單選取密碼：`,
  [`Please select consumer organization`]: `請選取消費者組織`,
  [`Please select one Keystore from the list below:`]: `請從下列清單選取一個金鑰儲存庫：`,
  [`Please select one keystore from the list below:`]: `請從下列清單選取一個金鑰儲存庫：`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `請記錄這個新應用程式的用戶端 ID 及密碼。請將密碼安全地儲存在某個位置，因為無法重新擷取，雖然需要時可以進行重設：`,
  [`Please try again in a few minutes.`]: `請在幾分鐘後重試。`,
  [`Please refresh catalog/space product list page and try again.`]: `請重新整理型錄/空間產品清單頁面，然後再試一次。`,
  [`Policies`]: `原則`,
  [`Policy Assemblies`]: `原則組件`,
  [`Policy assembly`]: `原則組件`,
  [`Policy description.`]: `原則說明。`,
  [`Policy title`]: `原則標題`,
  [`Policy has been uploaded.`]: `已上傳原則。`,
  [`Polish`]: `波蘭文`,
  [`Port`]: `埠`,
  [`Portal`]: `入口網站`,
  [`Portal details`]: `入口網站詳細資料`,
  [`Portal Director TLS client profile`]: `Portal Director TLS 用戶端設定檔`,
  [`Portal Director keystore`]: `Portal Director 金鑰儲存庫`,
  [`Portal Director truststore`]: `Portal Director 信任儲存庫`,
  [`Portal service`]: `入口網站服務`,
  [`Portal Service`]: `入口網站服務`,
  [`Portal service name`]: `入口網站服務名稱`,
  [`Portal service details`]: `入口網站服務詳細資料`,
  [`Portal Service and URL`]: `入口網站服務及 URL`,
  [`Portal service {name} was successfully created.`]: `已順利建立入口網站服務 {name}。`,
  [`Portal service {name} was successfully updated.`]: `已順利更新入口網站服務 {name}。`,
  [`Portal Service {arg} has been removed.`]: `已移除入口網站服務 {arg}。`,
  [`Portal site`]: `入口網站`,
  [`Portal URL`]: `入口網站 URL`,
  [`Portal site URLs cannot be greater than 200 characters`]: `入口網站 URL 不能大於 200 個字元`,
  [`Portal website URL`]: `入口網站 URL`,
  [`Portal and Gateway Settings`]: `入口網站及閘道設定`,
  [`Portal has been created`]: `已建立入口網站`,
  [`Portal has been created.`]: `已建立入口網站。`,
  [`Portal has been deleted`]: `已刪除入口網站`,
  [`Portals`]: `入口網站`,
  [`Portuguese`]: `葡萄牙文`,
  [`Post Response`]: `後置回應`,
  [`Post-Invoke Hook`]: `後置呼叫連結鉤`,
  [`Preserve subscriptions on re-publish`]: `重新發佈時保留訂閱`,
  [`Preserve subscriptions on re-publish product`]: `重新發佈產品時保留訂閱`,
  [`Pre-Invoke Hook`]: `預先呼叫連結鉤`,
  [`Pre-Request`]: `前置要求`,
  [`Preference`]: `喜好設定`,
  [`Prefix`]: `字首`,
  [`Preparing API`]: `正在準備 API`,
  [`Preserved request header`]: `保留的要求標頭`,
  [`preserved request header`]: `保留的要求標頭`,
  [`Preserved response header`]: `保留回應標頭`,
  [`preserved response header`]: `保留的回應標頭`,
  [`Preview`]: `預覽`,
  [`Preview of login screen registry prompt`]: `登入畫面登錄提示預覽`,
  [`Previous`]: `上一步`,
  [`Previous page`]: `上一頁`,
  [`Prettify`]: `修飾`,
  [`Price per month`]: `每月價格`,
  [`Private`]: `私密`,
  [`Private Claims`]: `專用要求`,
  [`Private key & public key`]: `私密金鑰與公開金鑰`,
  [`Private Key or Shared Secret for Decrypt`]: `用於解密的私密金鑰或共用密碼`,
  [`Private Key or Shared Secret for Sign`]: `用於簽署的私密金鑰或共用密碼`,
  [`Private key detected`]: `偵測到私密金鑰`,
  [`Private key password`]: `私密金鑰密碼`,
  [`Processed`]: `已處理`,
  [`Problem`]: `問題`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `提取追蹤資料時發生問題。請重試。如需錯誤詳細資料，請參閱主控台。`,
  [`Produced Media Types`]: `產生的媒體類型`,
  [`Produces`]: `產生`,
  [`Product`]: `產品`,
  [`Product:`]: `產品：`,
  [`Product / Plan`]: `產品/方案`,
  [`Product APIs`]: `產品 API`,
  [`Product editor`]: `產品編輯器`,
  [`Product lifecycle`]: `產品生命週期`,
  [`Product Lifecycle`]: `產品生命週期`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `產品名稱不能以 '-auto-product' 結尾。 請變更名稱，然後重試匯入。`,
  [`Product setup`]: `產品設定`,
  [`Product details`]: `產品詳細資料`,
  [`Product documentation`]: `產品說明文件`,
  [`Product has been created.`]: `已建立產品`,
  [`Product has been deleted`]: `已刪除產品`,
  [`Product has been updated`]: `已更新產品`,
  [`Product has been updated.`]: `已更新產品。`,
  [`Product has not been created!`]: `尚未建立產品！`,
  [`Product has not been published!`]: `尚未發佈產品！`,
  [`Product has not been updated!`]: `尚未更新產品！`,
  [`Product has not been staged!`]: `尚未暫置產品！`,
  [`Product information`]: `產品資訊`,
  [`Product summary details`]: `產品摘要詳細資料`,
  [`Product version`]: `產品版本`,
  [`Product visibility`]: `產品可見性`,
  [`Product-Approval`]: `產品核准`,
  [`Product-Drafts`]: `產品-草稿`,
  [`Product/plan`]: `產品/方案`,
  [`Production`]: `正式作業`,
  [`Production mode`]: `正式作業模式`,
  [`Production endpoints`]: `正式作業端點`,
  [`Products`]: `產品`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `產品必須暫置到型錄然後發佈，才能提供給應用程式開發者使用。型錄是作為閘道的邏輯分割區及 Developer Portal。API 呼叫的 URL 及 Developer Portal 特定於特定型錄。在一般配置中，API 提供者組織使用開發型錄來測試開發中的 API，以及使用正式作業型錄來代管已備妥可供充分使用的 API。`,
  [`Profile`]: `設定檔`,
  [`Profile rule`]: `設定檔規則`,
  [`Promote to production`]: `提升至正式作業`,
  [`Proof Key for Code Exchange`]: `程式碼交換驗證金鑰`,
  [`Proof Key for Code Exchange (PKCE)`]: `程式碼交換的證明金鑰 (PKCE)`,
  [`Properties`]: `內容`,
  [`Property`]: `內容`,
  [`Property Description`]: `內容說明`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `藉由在下方輸入靜態值，可以將其指派給內容 {{target}}。`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `內容 {{target}} 目前對映至 {{from}} - 可以在下方移除該對映。`,
  [`Property Value is Base64 Encoded`]: `內容值為 Base64 編碼`,
  [`Protocol`]: `通訊協定`,
  [`Protocols`]: `通訊協定`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `可以用來呼叫 API 的通訊協定（閘道強制執行的 API 僅支援 HTTPS）`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `提供 OAuth {oauthVer} 認證來鑑別使用者，並取得 OpenID 提供者 API 的存取權`,
  [`Provide a schema`]: `提供綱目`,
  [`Provide GraphQL schema`]: `提供 GraphQL 綱目`,
  [`Provide the URL to the backend GraphQL server`]: `提供後端 GraphQL 伺服器的 URL`,
  [`Provide your email address to receive password reset instructions`]: `提供您的電子郵件位址來接收密碼重設指示`,
  [`Provide your new password below`]: `在下方提供新密碼`,
  [`Provider endpoint`]: `提供者端點`,
  [`Provider Information`]: `提供者資訊`,
  [`Provider Organization`]: `提供者組織`,
  [`Provider Organizations`]: `提供者組織`,
  [`Provider Type`]: `提供者類型`,
  [`Provider organizations`]: `提供者組織`,
  [`Provider type`]: `提供者類型`,
  [`Provider org invite failed`]: `提供者組織邀請失敗`,
  [`Provider organization {name} created`]: `已建立提供者組織 {name}`,
  [`Provider organizations will be listed here`]: `提供者組織將在這裡列出`,
  [`Provider-Org`]: `提供者組織`,
  [`Provides contact information for the exposed API.`]: `提供所公開 API 的聯絡資訊。`,
  [`Provides license information for the exposed API.`]: `提供所公開 API 的授權資訊。`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `提供 API 的 meta 資料。必要的話，用戶端可以使用這些 meta 資料。`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `已起始 Developer Portal 佈建。將需要數分鐘的時間才能完成。一旦完成，您會收到一封電子郵件，其中含有用於設定入口網站管理者帳戶密碼的鏈結。`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `已起始 Developer Portal URL 的更新。此程序需要數分鐘才能完成。`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `Proxy URL`,
  [`Public`]: `公開`,
  [`Public Key or Shared Secret for Encrypt`]: `用於加密的公開金鑰或共用密碼`,
  [`Publish`]: `發佈`,
  [`Publish API`]: `發佈 API`,
  [`Publish a product from the Develop section.`]: `從「開發」區段發佈產品。`,
  [`Publish product`]: `發佈產品`,
  [`Publish to`]: `發佈至`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `將部分產品發佈到此型錄以便建立對這些產品的訂閱。`,
  [`Publish target`]: `發佈目標`,
  [`Publish this API to the Sandbox catalog.`]: `將此 API 發佈至沙箱型錄。`,
  [`Publish to specific gateway services`]: `發佈至特定的閘道服務`,
  [`Publish using a new product`]: `使用新產品發佈`,
  [`Publish using an existing product`]: `使用現有產品發佈`,
  [`Publishable Key`]: `可發佈的金鑰`,
  [`Publishable key`]: `可發佈的金鑰`,
  [`Publishable key tip`]: `可發佈的金鑰提示`,
  [`Published`]: `已發佈`,
  [`Published product`]: `已發佈產品`,
  [`Publishing`]: `發佈`,
  [`Publishing New API`]: `正在發佈新的 API`,
  [`Publish (preserve subscriptions)`]: `發佈（保留訂閱）`,
  [`Publish|permission`]: `發佈`,
  [`Put online`]: `上線`,
  [`Query`]: `查詢`,
  [`Queryparam Encode`]: `查詢參數編碼`,
  [`Queued`]: `已排入佇列`,
  [`Queued events: **{queued}**`]: `已排入佇列的事件：**{queued}**`,
  [`Retry blocked jobs`]: `請重試被封鎖的工作`,
  [`REQUIRED`]: `必要`,
  [`ROLES`]: `角色`,
  [`Rate limit`]: `速率限制`,
  [`Rate Limit`]: `速率限制`,
  [`Rate Limit Name`]: `速率限制名稱`,
  [`Rate limits`]: `速率限制`,
  [`Rate limit name can't be a duplicate`]: `速率限制名稱不能重複`,
  [`Rate limit name can't be empty`]: `速率限制名稱不能是空的`,
  [`Rate limit overrides`]: `速率限制置換`,
  [`Rate limit time interval can't be 0`]: `速率限制時間間隔不能為 0`,
  [`Rate limit time interval can't be empty`]: `速率限制時間間隔不能為空白`,
  [`Rate limit time interval unit must be selected`]: `必須選取速率限制時間間隔單位`,
  [`Rate limit value can't be empty`]: `速率限制值不能是空的`,
  [`Rate limit value can't be negative`]: `速率限制值不能是負數`,
  [`Re-stage`]: `重新暫置`,
  [`Re-stage product`]: `重新暫置產品`,
  [`Read only`]: `唯讀`,
  [`Read-Only`]: `唯讀`,
  [`Realized`]: `已實現`,
  [`Reason`]: `原因`,
  [`Reason for declining`]: `拒絕的原因`,
  [`Recommendations`]: `建議`,
  [`Recommended configuration`]: `建議配置`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `記錄下面顯示的用戶端 ID 和密碼，供稍後使用。以後無法顯示用戶端密碼。`,
  [`Recommendations applied`]: `已套用建議`,
  [`Redact`]: `編寫`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `編寫或移除「要求」內文、「回應」內文及活動日誌中的指定欄位。指定 XPath 表示式，以定義要編寫或移除的欄位。`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `編寫或移除「要求」內文、「回應」內文及活動日誌中的指定欄位。指定 XPath 或 JSONata 表示式，以定義要編寫或移除的欄位。`,
  [`Redaction`]: `編寫`,
  [`Redactions`]: `編寫`,
  [`Redirect`]: `重新導向`,
  [`Redirect URI`]: `重新導向 URI`,
  [`Redirect URL`]: `重新導向 URL`,
  [`Redirect through Portal`]: `透過入口網站進行重新導向`,
  [`Redirect time limit`]: `重新導向時間限制`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `重新導向時間限制必須介於 10 到 600 秒之間`,
  [`References`]: `參照`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `請參閱 [IBM Cloud 說明文件]({link})，取得其他指引。`,
  [`Referral`]: `轉介`,
  [`Refresh`]: `重新整理`,
  [`Refresh Token`]: `重新整理記號`,
  [`Refresh button`]: `「重新整理」按鈕`,
  [`Refresh products`]: `重新整理產品`,
  [`Refresh results from server`]: `重新整理來自伺服器的結果`,
  [`Refresh token`]: `重新整理記號`,
  [`Refresh token time to live`]: `重新整理記號的存活時間`,
  [`Refresh token time-to-live`]: `重新整理記號的存活時間`,
  [`Refresh token time-to-live (seconds)`]: `重新整理記號的存活時間（秒）`,
  [`Refresh token TTL has been changed`]: `重新整理記號的存活時間已變更`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `重新整理記號的存活時間是 **{seconds} 秒 ({time})**`,
  [`Refresh token time to live is **off**`]: `重新整理記號的存活時間是 **off**`,
  [`Refresh tokens`]: `重新整理記號`,
  [`Refreshing`]: `重新整理`,
  [`Refreshing...`]: `正在重新整理...`,
  [`Region`]: `地區`,
  [`Register`]: `登錄`,
  [`Register Gateways`]: `登錄閘道`,
  [`Register Service`]: `登錄服務`,
  [`Register gateways`]: `登錄閘道`,
  [`Register service`]: `登錄服務`,
  [`Register a service`]: `登錄服務`,
  [`Register new services and manage existing services`]: `登錄新服務和管理現有服務`,
  [`Register remote gateway`]: `登錄遠端閘道`,
  [`Registered Admin Member`]: `登錄的管理者成員`,
  [`Registered Provider Org Owner or Member`]: `登錄的提供者組織擁有者或成員`,
  [`Registered client information on the OpenID provider`]: `已登錄關於 OpenID 提供者的用戶端資訊`,
  [`Registration completed successfully`]: `順利完成登錄`,
  [`Registration failed.`]: `登錄失敗。`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `個別端點的相對路徑。 它們必須相對於 'basePath'。`,
  [`Relaxed Badgerfish`]: `放寬的 Badgerfish`,
  [`Remove analysis configuration`]: `移除分析配置`,
  [`Remote API Connect`]: `遠端 API Connect`,
  [`Remote login`]: `遠端登入`,
  [`Remote publish`]: `遠端發佈`,
  [`Remove`]: `移除`,
  [`Remove Burst Limit`]: `移除高載限制`,
  [`Remove constraints`]: `移除限制`,
  [`Remove Count Limit`]: `移除計數限制`,
  [`Remove gateway extension`]: `移除閘道延伸`,
  [`Remove product`]: `移除產品`,
  [`Remove Rate limit`]: `移除速率限制`,
  [`Remove the invalid constraints`]: `移除無效的限制`,
  [`Remove the invalid constraints from`]: `從中移除無效的限制`,
  [`Remove action`]: `移除動作`,
  [`Remove case`]: `移除案例`,
  [`Remove catch`]: `移除捕捉`,
  [`Remove filter`]: `移除過濾器`,
  [`Remove input`]: `移除輸入`,
  [`Remove mapping`]: `移除對映`,
  [`Remove output`]: `移除輸出`,
  [`Remove static value`]: `移除靜態值`,
  [`Removed`]: `已移除`,
  [`Removed by user`]: `由使用者移除`,
  [`Reorder`]: `重新排序`,
  [`Repeat`]: `重複`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `重複 API 呼叫所設定的次數，或者直到按一下停止按鈕`,
  [`Replace`]: `取代`,
  [`Replace Free Trial with`]: `將免費試用取代為`,
  [`Replace Gold with`]: `將金級取代為`,
  [`Replace GraphQL schema`]: `取代 GraphQL 綱目`,
  [`Replace plans`]: `取代方案`,
  [`Replace private key & public key`]: `取代私密金鑰與公開金鑰`,
  [`Replace Product`]: `取代產品`,
  [`Replace product`]: `取代產品`,
  [`Replace schema`]: `取代綱目`,
  [`Replace Silver with`]: `將銀級取代為`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `從建議清單中選擇新擁有者來取代現行擁有者。為取代的擁有者指派新權限，讓他們可以繼續工作。`,
  [`Replace|permission`]: `取代`,
  [`Republish`]: `重新發佈`,
  [`Republish product`]: `重新發佈產品`,
  [`Request`]: `要求`,
  [`Request catch`]: `要求捕捉`,
  [`Request endpoint`]: `要求端點`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `已核准在型錄 {catalog} 中 {action} API 產品的申請`,
  [`Request approved to {{action}} app {{appName}}`]: `已核准 {action} 應用程式 {appName} 的申請`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `已拒絕在型錄 {catalog} 中 {action} API 產品的申請`,
  [`Request denied to {{action}} app {{appName}}`]: `已拒絕 {action} 應用程式 {appName} 的申請`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `申請核准訂閱 {catalog} 型錄中的 API 產品`,
  [`Request for approval to {{action}} app {{appName}}`]: `申請核准 {action} 應用程式 {appName}`,
  [`Request headers`]: `要求標頭`,
  [`Request pending to promote application to production`]: `將應用程式提升至正式作業的要求擱置中`,
  [`Request query`]: `要求查詢`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `已收到在型錄 {catalog} 中 {action} API 產品的申請`,
  [`Request received to {{action}} app {{appName}}`]: `已收到 {action} 應用程式 {appName} 的申請`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `已撤銷在 {catalog} 型錄中訂閱 API 產品的申請`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `已撤銷在 {catalog} 型錄中 {action} API 產品的申請`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `要求已撤銷以 {action} 應用程式 {appName}`,
  [`Requested approvals`]: `要求的核准`,
  [`Requested By`]: `要求者`,
  [`Requested To`]: `要求內容`,
  [`Require`]: `需要`,
  [`Require applications to provide a client ID`]: `需要應用程式提供用戶端 ID`,
  [`Require approval for all new self service development accounts`]: `需要核准所有新的自助開發帳戶`,
  [`Required`]: `必要`,
  [`Required for rate limiting`]: `速率限制所需`,
  [`Required must be checked off when location is in path.`]: `如果位置是路徑，必須取消勾選「必要」。`,
  [`Required path`]: `必要路徑`,
  [`Resend Invite`]: `重新傳送邀請`,
  [`Resend invitation`]: `重新傳送邀請`,
  [`Resend invite`]: `重新傳送邀請`,
  [`Reset`]: `重設`,
  [`Reset defaults`]: `重設預設值`,
  [`Reset Notification template`]: `重設通知範本`,
  [`Reset password`]: `重設密碼`,
  [`Reset password TTL has been changed`]: `重設密碼 TTL 已變更`,
  [`Reset password time to live`]: `重設密碼的存活時間`,
  [`Reset password time-to-live`]: `重設密碼的存活時間`,
  [`Reset password email has been sent`]: `重設密碼電子郵件已傳送`,
  [`Reset password email has not been sent`]: `重設密碼電子郵件尚未傳送`,
  [`Reset password invitation timeout`]: `重設密碼的邀請逾時值`,
  [`Reset to default preferences`]: `重設為預設喜好設定`,
  [`Resolve API Connect variable references`]: `解析 API Connect 變數參照`,
  [`Resolve XML input data type`]: `解析 XML 輸入資料類型`,
  [`resolver`]: `解析器`,
  [`Resource group`]: `資源群組`,
  [`Resource Owner`]: `資源擁有者`,
  [`Resource owner password grant`]: `資源擁有者密碼授權`,
  [`Resource owner revocation`]: `資源擁有者撤銷`,
  [`Resource owner revocation path`]: `資源擁有者撤銷路徑`,
  [`Resource Owner Security`]: `資源擁有者安全`,
  [`Resource owner - JWT`]: `資源擁有者 - JWT`,
  [`Resource owner - Password`]: `資源擁有者 - 密碼`,
  [`Resource owner password`]: `資源擁有者密碼`,
  [`Resources`]: `資源`,
  [`Response`]: `回應`,
  [`Response catch`]: `回應捕捉`,
  [`Response Definition`]: `回應定義`,
  [`Response Time`]: `回應時間`,
  [`Response type`]: `回應類型`,
  [`Response context variables`]: `回應環境定義變數`,
  [`Response headers`]: `回應標頭`,
  [`Response must have a description`]: `回應必須具有說明`,
  [`Response must have a schema`]: `回應必須具有綱目`,
  [`Response must have a status code`]: `回應必須具有狀態碼`,
  [`Response object variable`]: `回應物件變數`,
  [`Response time`]: `回應時間`,
  [`Response time: {{value}}ms`]: `回應時間：{{value}} 毫秒`,
  [`Result of removing`]: `移除的結果`,
  [`Restrict HTTP transactions to version 1.0.`]: `限制 HTTP 交易為 1.0 版。`,
  [`Restrict to HTTP 1.0`]: `限制為 HTTP 1.0`,
  [`Retire`]: `汰換`,
  [`Retire product`]: `汰換產品`,
  [`Retired`]: `已汰換`,
  [`Retire|permission`]: `汰換`,
  [`Return V5 Responses`]: `傳回 V5 回應`,
  [`Retry selected requests`]: `重試選取的要求`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `從 GraphQL Proxy 傳回標準 GraphQL 內部檢查查詢的結果`,
  [`Return third-party access_token`]: `傳回協力廠商存取記號`,
  [`Return third-party access_token with the token response`]: `隨記號回應傳回協力廠商存取記號`,
  [`Return third-party id_token`]: `傳回協力廠商 ID 記號`,
  [`Return third-party id_token with the token response`]: `隨記號回應傳回協力廠商 ID 記號`,
  [`Return to Login`]: `回到登入畫面`,
  [`Reveal in finder`]: `在搜尋器中顯示`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `檢閱並決定要在綱目中顯示或隱藏的內容。可能需要其他變更才能保持綱目有效。`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `請檢閱所有警告。我們稍後會在編輯器中用智慧型建議來協助改善您的綱目。`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `請檢閱所有警告，並套用智慧型建議來協助改善您的綱目及保護您的後端系統。`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `檢閱 API 呼叫端點的基本 URL，並指定閘道所處理的網域。`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `檢閱閘道類型、標題、名稱和摘要。當呼叫 API Connect 指令和 API 時，請使用閘道名稱。`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `檢閱變更的摘要，其中可能包含能保持綱目有效的其他變更。`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `請檢閱我們在您的綱目中所找到的警告。我們稍後會在編輯器中用智慧型建議來協助改善您的綱目及保護您的後端系統。`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `請檢閱我們在您的綱目中所找到的警告。我們會採用智慧型建議來協助改善您的綱目及保護您的後端系統。`,
  [`Reviewed schema, paths and operations`]: `已檢閱的綱目、路徑和作業`,
  [`Revocation protocol`]: `撤銷通訊協定`,
  [`Revoke token`]: `撤銷記號`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL 文件`,
  [`Role`]: `角色`,
  [`Role default`]: `角色預設值`,
  [`Role default has been deleted`]: `角色預設值已刪除`,
  [`Role defaults`]: `角色預設值`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `「角色預設值」是預先配置的角色範本，用來決定建立新組織時的可用預設角色。`,
  [`Role has been created`]: `已建立角色`,
  [`Role has been deleted`]: `已刪除角色`,
  [`Role has been updated`]: `已更新角色`,
  [`Roles`]: `角色`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `角色（及其獲指派的權限）可以控制對 API Connect 中特性的存取權。管理者組織角色僅適用於使用 Cloud Manager 的人員。`,
  [`Roles and permissions control access to API Manager capabilities.`]: `角色和權限可以控制對 API Manager 功能的存取權。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `角色及其相關聯的權限可以控制對特性的存取權以及在 API Connect 中執行作業的能力。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `角色及其相關聯的權限可以控制對特性的存取權以及在 API Manager 使用者介面中執行作業的能力。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `角色及其相關聯的權限可以控制對特性的存取權以及執行作業的能力。`,
  [`Root`]: `根`,
  [`Root XML Element Name`]: `根 XML 元素名稱`,
  [`Run the following command in the CLI to register the credentials:`]: `請在 CLI 中執行下列指令來登錄認證：`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `請在 CLI 中執行下列指令來登錄「工具箱」認證：`,
  [`Running`]: `執行中`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `含有用來解密 JWT 之 JWK 的執行時期變數。`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `含有用來加密 JWT 之 JWK 的執行時期變數。`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `含有用來簽署 JWT 之 JWK 的執行時期變數。`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `含有用來驗證簽章之 JWK 的執行時期變數。`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `可從中擷取一組有效 JSON 要求的執行時期變數。`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `可從中擷取「對象 (aud) 要求」字串的執行時期變數。透過以逗點區隔的字串，可以設定多個變數。`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `可從中擷取「發出者 (iss) 要求」字串的執行時期變數。此要求代表發出 JWT 的「主體」。`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `可從中擷取「主旨 (sub) 要求」字串的執行時期變數。`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `要在其中放置所產生 JWT 的執行時期變數。如果未設定，則 JWT 會以「持有人」記號放置在「授權標頭」中。`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `包含已鑑別使用者名稱的執行時期變數。LTPA 記號是以這個內容作為使用者而產生。例如，$(client.app.id) 或 $(oauth.resource-owner)，或是 'set-variable' 原則中配置的執行時期變數。`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `將指派 JWT 中的一整組要求的執行時期變數。`,
  [`Russian`]: `俄文`,
  [`Scalar`]: `純量`,
  [`Schema`]: `綱目`,
  [`SCHEMA`]: `綱目`,
  [`SCOPE`]: `範圍`,
  [`SCOPES`]: `範圍`,
  [`SECURITY DEFINITIONS`]: `安全定義`,
  [`Service`]: `服務`,
  [`Service state:`]: `服務狀態：`,
  [`SERVICE TYPE`]: `服務類型`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1 授權`,
  [`SOURCE`]: `來源`,
  [`SSL Profile`]: `SSL 設定檔`,
  [`State`]: `狀態`,
  [`STATUS`]: `狀態`,
  [`Status Code`]: `狀態碼`,
  [`STATUS CODE`]: `狀態碼`,
  [`SUBMITTED`]: `已提交`,
  [`SUBSCRIBERS`]: `訂閱者`,
  [`Summary`]: `摘要`,
  [`Same as product`]: `與產品相同`,
  [`Sample ID token signing key (RS256)`]: `範例 ID 記號簽章金鑰 (RS256)`,
  [`Sample ID token validation key (RS256)`]: `範例 ID 記號驗證金鑰 (RS256)`,
  [`Sample OAuth symmetric key value`]: `範例 OAuth 對稱金鑰值`,
  [`Sample URL: https://api.catalog.org.host/`]: `範例 URL：https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `範例 URL：https://host/org/catalog/`,
  [`Sample scope description 1`]: `範例範圍說明 1`,
  [`Sandbox - Overview`]: `沙箱 - 概觀`,
  [`Sandbox Catalog`]: `沙箱型錄`,
  [`Sandbox Catalog User Registry`]: `沙箱型錄使用者登錄`,
  [`Sandbox Default TLS client profile`]: `沙箱預設 TLS 用戶端設定檔`,
  [`Sandbox Default TLS Client Profile`]: `沙箱預設 TLS 用戶端設定檔`,
  [`Sandbox catalog should always exist in a provider organization.`]: `沙箱型錄應該一律存在於提供者組織中。`,
  [`Save`]: `儲存`,
  [`Save API as a new version`]: `將 API 另存為新版本`,
  [`Save Product as a new version`]: `將產品另存為新版本`,
  [`Save and Edit`]: `儲存並編輯`,
  [`Save and republish`]: `儲存並重新發佈`,
  [`Save as a new version`]: `儲存為新版本`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `儲存用戶端密碼（出於安全考慮，不再可擷取）`,
  [`Save this product as a new version`]: `將此產品儲存為新版本`,
  [`Saved`]: `已儲存`,
  [`Saving assembly...`]: `正在儲存組件...`,
  [`Saving...`]: `正在儲存...`,
  [`Schema Type`]: `綱目類型`,
  [`Schema as JSON`]: `綱目為 JSON`,
  [`Schema as XML`]: `綱目為 XML`,
  [`Schema as YAML`]: `綱目為 YAML`,
  [`Schema change`]: `綱目變更`,
  [`Schema changes`]: `綱目變更`,
  [`Schema definition circular reference limit`]: `綱目定義循環參照限制`,
  [`Schema name`]: `綱目名稱`,
  [`Schema warnings`]: `綱目警告`,
  [`Schema definition`]: `綱目定義`,
  [`Scheme`]: `架構`,
  [`Schemes`]: `架構`,
  [`Scope`]: `範圍`,
  [`Scopes`]: `範圍`,
  [`Scopes cannot be empty`]: `範圍不能是空的`,
  [`Search`]: `搜尋`,
  [`Search DN`]: `搜尋 DN`,
  [`Search in the field above above and select to add provider organizations`]: `在上方的欄位中搜尋，並選取要新增提供者組織`,
  [`Search Limit`]: `搜尋限制`,
  [`Search Organization owner from`]: `搜尋組織擁有者的位置`,
  [`Search orgs/groups`]: `搜尋組織/群組`,
  [`Search Owner`]: `搜尋擁有者`,
  [`Search assemblies`]: `搜尋組合`,
  [`Search for`]: `搜尋目標`,
  [`Search for provider organizations`]: `搜尋提供者組織`,
  [`Search for Owner`]: `搜尋擁有者`,
  [`Search for a new member to become the new owner.`]: `搜尋要成為新擁有者的新成員。`,
  [`Search for an existing member to become the new owner.`]: `搜尋要成為新擁有者的現有成員。`,
  [`Search for new owner`]: `搜尋新擁有者`,
  [`Search for owner from:`]: `搜尋擁有者的位置：`,
  [`Search for the member`]: `搜尋成員`,
  [`Search for title or name or version`]: `搜尋標題或名稱或版本`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `搜尋您要新增為成員的使用者，並指派需要的角色。`,
  [`Search overflow`]: `搜尋溢位`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `搜尋登錄以取得您要新增為成員的現有使用者，並指派需要的角色`,
  [`Search types/fields`]: `搜尋類型/欄位`,
  [`Search user`]: `搜尋使用者`,
  [`Search schema`]: `搜尋綱目`,
  [`Seconds`]: `秒`,
  [`Secret Key`]: `秘密金鑰`,
  [`Secret Name`]: `密碼名稱`,
  [`Secret Required`]: `需要密碼`,
  [`Secret key`]: `秘密金鑰`,
  [`Secure`]: `保護安全`,
  [`Secure connection`]: `安全連線`,
  [`Secure using Client ID`]: `使用用戶端 ID 進行保護`,
  [`Security`]: `安全`,
  [`Security & Product Definitions`]: `安全與產品定義`,
  [`Security definitions`]: `安全定義`,
  [`Security and Publish`]: `安全與發佈`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `安全定義會控制用戶端對 API 端點的存取權，包括 API 金鑰驗證、經由指定使用者登錄進行基本鑑別，以及 OAuth。`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `安全定義會控制用戶端對 API 端點的存取權，包括 API 金鑰驗證、經由指定使用者登錄進行基本鑑別，以及 OAuth。[進一步瞭解]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `在這裡選取的安全定義適用於整個 API，但可以針對個別作業進行置換。`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `在這裡選取的安全定義適用於整個 API，但可以針對個別作業進行置換。[進一步瞭解]({link})`,
  [`Security object to use to decode the claim.`]: `用來解密要求的安全物件。`,
  [`Security object used to encrypt the JWT.`]: `用來加密 JWT 的安全物件。`,
  [`Security object used to sign the JWT.`]: `用來簽署 JWT 的安全物件。`,
  [`Security object used to verify the claim.`]: `用來驗證要求的安全物件。`,
  [`Select APIs to add to this product`]: `選取要新增至此產品的 API`,
  [`Select API Type`]: `選取 API 類型`,
  [`Select API type`]: `選取 API 類型`,
  [`Select a billing integration in the Product plans.`]: `選取產品方案中的一項計費整合。`,
  [`Select a flow...`]: `選取流程...`,
  [`Select a plan from your existing product`]: `從現有產品中選取方案`,
  [`Select a policy file to import to this catalog`]: `選取要匯入至此型錄的原則檔`,
  [`Select a policy implementation file to import to this catalog`]: `選取要匯入至此型錄的原則實作檔案`,
  [`Select a product`]: `選取產品`,
  [`Select Certificate Manager service`]: `選取「憑證管理程式」服務`,
  [`Select Cloud`]: `選取雲端`,
  [`Select Email Server`]: `選取電子郵件伺服器`,
  [`Select gateway services`]: `選取閘道服務`,
  [`Select Import Type`]: `選取匯入類型`,
  [`Select product`]: `選取產品`,
  [`Select product type`]: `選取產品類型`,
  [`Select Service`]: `選取服務`,
  [`Select Service Type`]: `選取服務類型`,
  [`Select target endpoints`]: `選取目標端點`,
  [`Select target service`]: `選取目標服務`,
  [`Select user registry type`]: `選取使用者登錄類型`,
  [`Select a WSDL service from the imported file`]: `從匯入的檔案中選取 WSDL 服務`,
  [`Select a certificate`]: `選取憑證`,
  [`Select a Certificate Manager service`]: `選取「憑證管理程式」服務`,
  [`Select a cryptographic algorithm.`]: `選取加密演算法。`,
  [`Select a default catalog and organization`]: `選取預設型錄和組織`,
  [`Select a different cloud`]: `選取不同雲端`,
  [`Select a key encryption algorithm.`]: `選取金鑰加密演算法。`,
  [`Select a plan to migrate`]: `選取要移轉的方案`,
  [`Select a plan to replace`]: `選取要取代的方案`,
  [`Select a plan to supersede`]: `選取要接替的方案`,
  [`Select a product to set as the migration target`]: `選取一項產品來設為移轉目標`,
  [`Select a product to supersede`]: `選取一項產品來接替`,
  [`Select a product to supersede {sourceProductName}:`]: `選取一項產品來接替 {sourceProductName}：`,
  [`Select a resource group`]: `選取資源群組`,
  [`Select a service`]: `選取服務`,
  [`Select a supported file ({type}) and try again.`]: `選取支援的檔案 ({type})，然後重試。`,
  [`Select a target SOAP service`]: `選取目標 SOAP 服務`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `選取要下載的工具箱套件。如果您已安裝工具箱，請跳過此步驟。`,
  [`Select an encryption algorithm.`]: `選取加密演算法。`,
  [`Select an extension file for this gateway`]: `選取用於此閘道的延伸檔`,
  [`Select an org`]: `選取組織`,
  [`Select catalog`]: `選取型錄`,
  [`Select Catalogs to Search`]: `選取要搜尋的型錄`,
  [`Select compatible gateway services`]: `使用相容的閘道服務`,
  [`Select one Keystore from the list below:`]: `從下列清單選取一個金鑰儲存庫：`,
  [`Select one keystore from the list below:`]: `從下列清單選取一個金鑰儲存庫：`,
  [`Select one keystore from the list.`]: `從清單選取一個金鑰儲存庫。`,
  [`Select operation...`]: `選取作業...`,
  [`Select operations you want to include`]: `選取要併入的作業`,
  [`Select organization`]: `選取組織`,
  [`Select the API definition or package file to import`]: `選取要匯入的 API 定義或套件檔`,
  [`Select the billing integration for this catalog`]: `選取此型錄的計費整合`,
  [`Select the billing integration for this product`]: `針對這個產品，選取計費整合`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `選取閘道以用來保護新型錄中的 API 安全。若要登錄新閘道，請造訪「閘道」頁面。`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `選取[憑證管理程式]({link})服務，用來管理此閘道的憑證。`,
  [`Select the APIs to add to this product`]: `選取要新增至此產品的 API`,
  [`Select the OpenAPI definition file to import`]: `選取要匯入的 OpenAPI 定義檔`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `選取需要核准的產品生命週期動作。採用選取的任一動作時，會產生核准要求；必須核准要求，動作才能完成。`,
  [`Select the TLS protocol versions for this profile.`]: `選取此設定檔的 TLS 通訊協定版本。`,
  [`Select the analytics service you would like to associate with the gateway service`]: `選取要與閘道服務相關聯的分析服務`,
  [`Select the consumer organization in which you want to create the application`]: `選取要在其中建立應用程式的消費者組織`,
  [`Select the consumer organization that will own the application`]: `選取將擁有應用程式的消費者組織`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `選取端點來發佈此 API 並依需要定義速率限制`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `選取執行原則期間若拋出則會導致組件流程停止的錯誤。如果為錯誤配置了捕捉流程，則會觸發捕捉流程來處理拋出的錯誤。如果拋出了錯誤，並且沒有為「錯誤時停止」設定選取任何錯誤，或者如果拋出的錯誤不是其中一個所選錯誤，則系統允許原則完成執行，並且組件流程會繼續執行。`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `選取將在其中提供產品的 API 的閘道服務。`,
  [`Select the gateway type for this API`]: `選取此 API 的閘道類型`,
  [`Select the gateway type for this OAuth provider`]: `選取此 OAuth 提供者的閘道類型`,
  [`Select the keystores and truststores for this profile.`]: `選取此設定檔的金鑰儲存庫和信任儲存庫。`,
  [`Select the organizations or groups you would like to make this product visible to`]: `選取您想要其可以看見此產品的組織或群組`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `選取您想要其訂閱此產品的組織或群組`,
  [`Select the portal service to use for this catalog`]: `選取用於此型錄的入口網站服務`,
  [`Select the product and plan for the application subscription`]: `選取應用程式訂閱的產品和方案`,
  [`Select the product definition file to import`]: `選取要匯入的產品定義檔`,
  [`Select the product definition file to import from`]: `選取要從中匯入的產品定義檔`,
  [`Select the product that will supersede the originally selected product`]: `選取將接替原始選取產品的產品`,
  [`Select the product that you want to add the API to`]: `選取要新增 API 的產品`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `選取您要用來控制此型錄中 API 存取權的登錄`,
  [`Select the required operations`]: `選取所需的作業`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `選取與輸入資料相關的日誌訊息的嚴重性層次；可能層次為錯誤、警告和參考。`,
  [`Select the target wsdl file to create from`]: `選取要從中建立的目標 WSDL 檔`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `選取要從中建立 API 定義的目標 wsdl、xsd 或 zip 檔`,
  [`Select the target WSDL file to import`]: `選取要匯入的目標 WSDL 檔`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `選取用於鑑別 API Manager 使用者的使用者登錄，或建立新的使用者登錄。`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `選取用於鑑別 API Manager 使用者的使用者登錄，或建立新的使用者登錄。`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `選取用於鑑別 Cloud Manager 使用者的使用者登錄，或建立新的使用者登錄。`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `選取用來鑑別 Developer Portal 登入的使用者登錄`,
  [`Select the user registry type`]: `選取使用者登錄類型`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `選取原則應該將產生的 LTPA 記號放在輸出來源中的何處。在 WSSec 中，只有當要求的「內容類型」是 application/xml 時，才應該選取標頭。`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `選取要移除全部內容，或是使用 "*" 加以編寫，讓資料無法顯示。`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `已修改選取的產品。 請重新整理此頁面，然後再試一次。`,
  [`Selecting operations from WSDL service definition`]: `從 WSDL 服務定義中選取作業`,
  [`Self Service Onboarding for Consumer organization`]: `消費者組織的自助式上線`,
  [`Self service onboarding`]: `自助式上線`,
  [`Self service onboarding approval`]: `自助上線核准`,
  [`Send`]: `傳送`,
  [`Send Message`]: `傳送訊息`,
  [`Send Message to Owners`]: `傳送訊息給擁有者`,
  [`Send To`]: `傳送至`,
  [`Send a registration link to a new user to register as owner.`]: `傳送登錄鏈結給新使用者以登錄為擁有者。`,
  [`Send a test email`]: `傳送測試電子郵件`,
  [`Send a test email to confirm that the email server is properly configured.`]: `傳送測試電子郵件以確認電子郵件伺服器已適當配置。`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `傳送測試信號，以確認該端點已適當地配置。`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `傳送測試信號，以確認該端點已適當地配置`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `傳送電子郵件訊息給管理者組織擁有者。請在擁有者名稱旁邊加上勾號`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `傳送電子郵件訊息給擁有者。請在擁有者名稱旁邊加上勾號`,
  [`Send chunked-encoded documents to the target server.`]: `傳送區塊編碼文件至目標伺服器。`,
  [`Send message`]: `傳送訊息`,
  [`Send message to owner`]: `傳送訊息給擁有者`,
  [`Send test email`]: `傳送測試電子郵件`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `傳送啟動鏈結至使用者以邀請使用者登入或登錄。`,
  [`Send-only`]: `僅傳送`,
  [`Sender`]: `寄件者`,
  [`Sender & email server`]: `寄件者和電子郵件伺服器`,
  [`Sender & email server have been changed`]: `已變更寄件者和電子郵件伺服器`,
  [`Sender Info`]: `寄件者資訊`,
  [`Sent`]: `已傳送`,
  [`Sent events: **{sent}**`]: `已傳送的事件：**{sent}**`,
  [`Serial`]: `序列`,
  [`Serialize output`]: `序列化輸出`,
  [`Server connection`]: `伺服器連線`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `提供 HTML 給 Web 瀏覽器以啟用 GUI GraphQL 用戶端`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `提供 HTML 給 Web 瀏覽器以啟用 GUI GraphQL 用戶端。`,
  [`Server Name Indication (SNI)`]: `伺服器名稱指示 (SNI)`,
  [`Server URL`]: `伺服器 URL`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `伺服器傳回了雜湊錯誤，而不是 OIDC 重新導向 URL 中的記號。 雜湊：`,
  [`Service name`]: `服務名稱`,
  [`Services`]: `服務`,
  [`Set`]: `設定`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `設定 API Designer 的 APIC_DESIGNER_CREDENTIALS 環境變數。以 MAC 為例，使用者可以在啟動 API Designer 時傳入環境變數。`,
  [`Set Migration Target`]: `設定移轉目標`,
  [`Set migration target`]: `設定移轉目標`,
  [`Set Variable`]: `設定變數`,
  [`Set as default`]: `設為預設值`,
  [`Set default`]: `設定預設值`,
  [`Set rate and burst limits in an assembly`]: `在組合中設定速率限制和高載限制`,
  [`Set the timeout period for invitations`]: `設定邀請的逾時期間`,
  [`Set the trust level for the profile`]: `設定設定檔的信任層次`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `將值設為容許的循環綱目定義的疊代次數（預設值為 1，上限值為 5）`,
  [`Set the visibility for a gateway service`]: `設定閘道服務的可見性`,
  [`Set the visibility for a portal service`]: `設定入口網站服務的可見性`,
  [`Set the visibility for each service`]: `設定每個服務的可見性。`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `設定時間範圍，以取得鑑別伺服器所簽發的新存取記號`,
  [`Set visibility`]: `設定可見性`,
  [`Set visibility of gateway`]: `設定閘道的可見性`,
  [`Set time interval`]: `設定時間間隔`,
  [`Set, Add, or Clear a runtime variable.`]: `設定、新增或清除執行時期變數。`,
  [`Set the timeout period for API key`]: `設定 API 金鑰的逾時期間`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `設定文件在快取中的有效期間（以秒為單位）。只有在「快取類型」設為「存活時間」時才適用。請輸入在 5 - 31708800 範圍內的值。預設值為 900。`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `一旦設定 "assumedSize" 的值，就無法同時定義具有預設值的切割引數，或無法同時啟用 "requireOneSlicingArgument"。`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `將此型錄設定為預設型錄，將容許使用省略型錄名稱的簡短 URL 來存取此型錄。您只能將一個型錄設定為預設型錄。`,
  [`Settings`]: `設定`,
  [`Setup`]: `設定`,
  [`Severity level for input data log messages`]: `輸入資料日誌訊息的嚴重性層次`,
  [`Share the resource with a custom list of provider organizations`]: `與自訂清單中的提供者組織共用資源`,
  [`Share the resource with provider organizations`]: `與提供者組織共用資源`,
  [`Share the API gateway with a custom list of provider organizations`]: `與自訂清單中的提供者組織共用 API Gateway`,
  [`Share the service with a custom list of provider organizations`]: `與自訂清單中的提供者組織共用服務`,
  [`Share the API gateway with provider organizations`]: `與提供者組織共用 API Gateway`,
  [`Share the service with provider organizations`]: `與提供者組織共用服務`,
  [`Share`]: `共用`,
  [`Shared`]: `已共用`,
  [`Shared by`]: `共用者`,
  [`Short but descriptive name for this policy.`]: `此原則的簡短敘述性名稱。`,
  [`Show`]: `顯示`,
  [`Show / hide policy palette`]: `顯示/隱藏原則選用區`,
  [`Show/hide`]: `顯示/隱藏`,
  [`Show/hide settings`]: `顯示/隱藏設定`,
  [`Show and hide types and fields to include in the schema.`]: `顯示及隱藏要併入綱目中的類型和欄位。`,
  [`Show added only`]: `僅顯示已新增項目`,
  [`Show all`]: `全部顯示`,
  [`Show all UDP versions`]: `顯示所有 UDP 版本`,
  [`Show app identifier`]: `顯示應用程式 ID`,
  [`Show breaking changes only`]: `僅顯示重大變更`,
  [`Show catalog identifier`]: `顯示型錄 ID`,
  [`Show catches`]: `顯示捕捉`,
  [`Show in schema`]: `在綱目中顯示`,
  [`Show less`]: `顯示更少`,
  [`Show modified only`]: `僅顯示已修改項目`,
  [`Show more`]: `顯示更多`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `顯示新的類型、欄位、指引或選用引數。仍將移除後端不再支援的項目。`,
  [`Show removed only`]: `僅顯示已移除項目`,
  [`Show schema`]: `顯示綱目`,
  [`Show warnings only`]: `僅顯示警告`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `顯示金鑰儲存庫的指派歷程，但不包括目前指派的金鑰儲存庫。`,
  [`Sign Crypto Object`]: `簽署加密物件`,
  [`Sign in`]: `登入`,
  [`Sign JWK variable name`]: `簽署 JWK 變數名稱`,
  [`Sign Out`]: `登出`,
  [`Sign up`]: `註冊`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `使用 {userRegistryType} 使用者登錄來登入`,
  [`Sign in with`]: `登入方式`,
  [`Sign out`]: `登出`,
  [`Sign up with`]: `註冊方式`,
  [`Signed token`]: `已簽章的記號`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `由於緩衝已經設定為關閉，因此必須將內容及/或錯誤內容從有效負載縮減為標頭。您想要繼續進行必要的變更還是取消最新變更？`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `由於內容及/或錯誤內容已經設定為有效負載，因此必須將緩衝設定為開啟。您想要繼續進行必要的變更還是取消最新變更？`,
  [`Sized fields`]: `調整大小的欄位`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `切割引數`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `部分輸入是陣列，或內含於陣列之中。若要對內容進行反覆運算，請選擇反覆運算選項，然後選擇要反覆運算的輸入。`,
  [`Something went wrong.`]: `出錯了。`,
  [`Something went wrong...`]: `出錯了...`,
  [`Source`]: `來源`,
  [`Source schema`]: `來源綱目`,
  [`Space`]: `空間`,
  [`Space is not allowed in scope name`]: `範圍名稱中不容許空格`,
  [`Space details`]: `空間詳細資料`,
  [`Space Enabled`]: `已啟用空間`,
  [`Space Name`]: `空間名稱`,
  [`Space Summary`]: `空間摘要`,
  [`Space Title`]: `空間標題`,
  [`Space owner`]: `空間擁有者`,
  [`Space {name} created`]: `已建立空間 {name}`,
  [`Spaces`]: `空間`,
  [`Spaces are enabled`]: `已啟用空間`,
  [`Spaces are not enabled`]: `未啟用空間`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `無法為沙箱型錄啟用空間。`,
  [`Spaces cannot be enabled.`]: `無法啟用空間。`,
  [`Spaces dropdown`]: `空間下拉清單`,
  [`Spaces has been enabled`]: `已啟用空間`,
  [`Spaces have been enabled.`]: `已啟用空間。`,
  [`Spaces per page`]: `每頁的空間`,
  [`Spanish`]: `西班牙文`,
  [`Special characters are not accepted`]: `不接受特殊字元`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `指定代表具名環境定義的 URL 或字串，從中可擷取要併入動態物件的已序列化 XML 或 JSON 內容。這些內容的優先順序高於任何現有的文字或預設內容。`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `指定合併至動態物件、形式為已序列化的 XML 或 JSON 內容的文字字串。這些內容的優先順序高於任何現有的預設內容。`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `指定現有的有效物件，可從其中擷取動態物件的預設內容值。警告：如果您變更預設字串，則必須在 DataPower 應用裝置上提供指定的剖析設定物件。`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `指定編寫或移除的內容所在的資料來源。如果未指定根，則會將動作套用至整個 API 環境定義。您可以使用任何受支援的 JSONata 路徑表示式。\n\n可透過以 "message.body" 作為根來執行要求和回應資料的編寫。 如果在組合中，先使用組合日誌動作來指定僅限收集模式，然後使用組合編寫動作，則根可以指定 "log.request_body" 給已記載的要求有效負載或者指定 "log.response_body" 給已記載的回應內容。`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `指定 API 環境定義中變數的名稱。變數的 "body" 欄位內容已輸入到動作中。預設變數名稱為 "message"。`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `指定 API 環境定義中變數的名稱。變數的 "body" 欄位內容是剖析的輸出。剖析文件的剖析度量可以儲存到訊息的不同組件中。預設變數名稱是無論輸入名稱為何，輸入訊息都會因此依預設由輸出訊息覆寫。`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `指定是否及在何處取得存取記號的自訂 meta 資料。`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `指定是否要以指定的內容類型嘗試剖析。這只有在預期的內容類型為 json 或 xml 時才適用。在啟用此選項的情況下，如果內容類型與剖析設定不相符（亦即內容類型為 application/json 而針對 xml 配置了剖析設定），則剖析動作將導致失敗。如果剖析設定配置成偵測輸入內容，則將被強制成指定的內容類型。`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `指定是否要在轉換後將 XSLT 輸出樹狀結構序列化為二進位資料。`,
  [`Specify default gateways`]: `指定預設閘道`,
  [`Specify email`]: `指定電子郵件`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `指定用來驗證有效負載（"request"、"response" 或 "#/definitions/XXXX"）的 OpenAPI (Swagger) 綱目定義。`,
  [`Specify owner of the consumer organization`]: `指定消費者組織的擁有者`,
  [`Specify owner of the provider organization`]: `指定提供者組織的擁有者`,
  [`Specify settings for the third party OAuth provider.`]: `指定協力廠商 OAuth 提供者的設定。`,
  [`Specify the MIME types that your API consumes and produces`]: `指定 API 耗用和產生的 MIME 類型`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `指定型錄擁有者；擁有者具有所有型錄權限`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `指定將可以在 Developer Portal 中查看產品的消費者組織或開發者社群`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `指定將可以訂閱以使用產品中 API 的消費者組織或開發者社群`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `指定 API 呼叫端點的基本 URL 以及閘道所處理的網域。`,
  [`Specify the details of the catalog that you want to publish to`]: `指定要發佈至的型錄詳細資料`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `指定預設的一組閘道，以用來保護新型錄中的 API 安全。[進一步瞭解]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `指定要將此 API 發佈至的端點，以及是否要限制 API 呼叫的速率`,
  [`Specify the new owner`]: `指定新擁有者`,
  [`Specify the owner`]: `指定擁有者`,
  [`Specify the owner and title`]: `指定擁有者和標題`,
  [`Specify the properties to redact or remove.`]: `指定要編寫或移除的內容。`,
  [`Specify the publish and rate limiting requirements for this API`]: `為此 API 指定發佈及速率限制需求`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `指定範圍檢查端點，在其中執行定義的範圍及其他範圍驗證。`,
  [`Specify the security settings for the imported API definition`]: `指定已匯入 API 定義的安全設定`,
  [`Specify the security settings for your API definition`]: `指定 API 定義的安全設定`,
  [`Specify the space owner; the owner has all space permissions`]: `指定空間擁有者；擁有者具有所有空間權限`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `指定目標 SOAP 服務及 API 摘要詳細資料；如有必要，您可以在建立之後進一步配置 API 定義`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `指定逾時期間，如果使用者未在此期間內啟動其帳戶，則邀請電子郵件會到期`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `指定逾時期間，如果使用者未在此期間內啟動其帳戶，則邀請電子郵件會到期。`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `指定閘道的類型、標題，以及用來說明閘道的摘要。`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `指定 API Gateway 對 API Connect 提供者組織的可見性。`,
  [`Specify the way you want to validate the message.`]: `指定要用來驗證訊息的方式。`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `指定閘道管理端點的 URL 以及端點網域的憑證。`,
  [`Specify what content to log in the event of an error.`]: `指定發生錯誤時要記載的內容。`,
  [`Specify what content to log.`]: `指定要記載的內容。`,
  [`Specifying the publish target details`]: `指定發佈目標詳細資料`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `特別容許 xsi:type=SOAP-ENC:Array 規則`,
  [`Stage`]: `暫置`,
  [`Stage API`]: `暫置 API`,
  [`Stage product`]: `暫置產品`,
  [`Stage to`]: `暫置至`,
  [`Staged`]: `已暫置`,
  [`Stage|permission`]: `暫置`,
  [`Standard OIDC`]: `標準 OIDC`,
  [`Static DNS`]: `靜態 DNS`,
  [`Static group`]: `靜態群組`,
  [`Status`]: `狀態`,
  [`Status code`]: `狀態碼`,
  [`Step 1`]: `步驟 1`,
  [`Step 1: Download`]: `步驟 1：下載`,
  [`Step 1: Download toolkit`]: `步驟 1：下載工具箱`,
  [`Step 1: Upload private key`]: `步驟 1：上傳私密金鑰`,
  [`Step 2`]: `步驟 2`,
  [`Step 2: Download credentials`]: `步驟 2：下載認證`,
  [`Step 2: Download toolkit credentials`]: `步驟 2：下載工具箱認證`,
  [`Step 2: Install`]: `步驟 2：安裝`,
  [`Step 2: Upload public key`]: `步驟 2：上傳公開金鑰`,
  [`Step 3`]: `步驟 3`,
  [`Step 3: Configure`]: `步驟 3：配置`,
  [`Step 3: Run command`]: `步驟 3：執行指令`,
  [`Step 3: Run commands`]: `步驟 3：執行指令`,
  [`Step 4: Register`]: `步驟 4：登錄`,
  [`Stop`]: `停止`,
  [`Stop after:`]: `在下者之後停止：`,
  [`Stop all the retries`]: `停止所有重試`,
  [`Stop Connection`]: `停止連線`,
  [`Stop on error`]: `錯誤時停止`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `停止執行此 API 並將其從沙箱型錄中移除。`,
  [`Stopped`]: `已停止`,
  [`Stopping Published API`]: `正在停止已發佈的 API`,
  [`Strict`]: `嚴格`,
  [`Strict SOAP envelope version`]: `嚴格 SOAP 封套版本`,
  [`String Badgerfish`]: `字串 Badgerfish`,
  [`String`]: `字串`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `API 呼叫之 URL 組織區段所包含的字串。請使用小寫英文字母、數字和連字號。`,
  [`Stripe`]: `分段`,
  [`Stripe integration`]: `分段整合`,
  [`Streaming rule`]: `串流規則`,
  [`Subject`]: `主旨`,
  [`Subject Claim`]: `主旨要求`,
  [`Subject Origin`]: `主體來源`,
  [`Subject common name`]: `主體通用名稱`,
  [`Subject|certificate`]: `主旨`,
  [`Submit`]: `提交`,
  [`Submitted`]: `已提交`,
  [`Subscribability`]: `訂閱功能`,
  [`Subscribability must be disabled if visibility is disabled`]: `如果可見性已停用，則必須停用訂閱功能`,
  [`Subscribability options`]: `訂閱功能選項`,
  [`Subscribable by`]: `可供訂閱對象`,
  [`Subscribe`]: `訂閱`,
  [`Subscribe Financial Management App`]: `訂閱金融管理應用程式`,
  [`Subscribe TODO App`]: `訂閱 TODO 應用程式`,
  [`Subscription`]: `訂閱`,
  [`Subscription approval task for subscription of`]: `下列訂閱的訂閱核准作業：`,
  [`Subscription created`]: `已建立訂閱`,
  [`Subscription has been added`]: `已新增訂閱`,
  [`Subscription has not been added`]: `尚未新增訂閱`,
  [`Subscription pending approval`]: `訂閱核准擱置中`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `已移除訂閱類型，因為不支援 GraphQL 訂閱。`,
  [`Subscription-Approval`]: `訂閱核准`,
  [`Subscriptions`]: `訂閱`,
  [`Success`]: `成功`,
  [`Suffix`]: `字尾`,
  [`Suggestion`]: `建議`,
  [`Supersede`]: `接替`,
  [`Supersede Product`]: `接替產品`,
  [`Supersede product`]: `接替產品`,
  [`Supersede plans`]: `接替方案`,
  [`Supersede {product1} with {product2}`]: `將 {product1} 接替為 {product2}`,
  [`Supersede|permission`]: `接替`,
  [`Supersede|supersede with`]: `接替`,
  [`Support`]: `支援`,
  [`support default introspection`]: `支援預設內部檢查`,
  [`Support Server Name Indication (SNI)`]: `支援伺服器名稱指示 (SNI)`,
  [`Support hybrid response types`]: `支援混合式回應類型`,
  [`Support standard introspection`]: `支援標準內部檢查`,
  [`Supported OAuth components`]: `支援的 OAuth 元件`,
  [`Supported client types`]: `支援的用戶端類型`,
  [`Supported client types can not be empty.`]: `支援的用戶端類型不能是空的`,
  [`Supported grant type can not be empty.`]: `支援的授權類型不能是空的`,
  [`Supported grant types`]: `支援的授權類型`,
  [`Suspend`]: `暫停`,
  [`Suspend API`]: `暫停 API`,
  [`Suspend Application`]: `暫停應用程式`,
  [`Switch`]: `切換`,
  [`Switch account`]: `切換帳戶`,
  [`Switch cloud connection`]: `切換雲端連線`,
  [`Switch directory`]: `切換目錄`,
  [`TARGET`]: `目標`,
  [`Title`]: `標題`,
  [`TlS`]: `TLS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS 用戶端`,
  [`TLS Client Profile`]: `TLS 用戶端設定檔`,
  [`TLS Client Profile has been configured.`]: `已配置 TLS 用戶端設定檔。`,
  [`TLS client profile is required when endpoint starts with https://`]: `當端點的開頭是 https:// 時，需要 TLS 用戶端設定檔`,
  [`TLS Client Profiles`]: `TLS 用戶端設定檔`,
  [`TLS client profile has been configured.`]: `已配置 TLS 用戶端設定檔。`,
  [`TLS client profiles`]: `TLS 用戶端設定檔`,
  [`TLS Profile`]: `TLS 設定檔`,
  [`TLS SERVER PROFILE`]: `TLS 伺服器設定檔`,
  [`TLS server profile`]: `TLS 伺服器設定檔`,
  [`TLS version 1.0`]: `TLS 1.0 版`,
  [`TLS version 1.1`]: `TLS 1.1 版`,
  [`TLS version 1.2`]: `TLS 1.2 版`,
  [`TLS version 1.3`]: `TLS 1.3 版`,
  [`TLS client profile`]: `TLS 用戶端設定檔`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `啟用空間後，會在每個空間中管理 TLS 用戶端設定檔。請移至空間設定以管理 TLS 用戶端設定檔。`,
  [`TLS profile`]: `TLS 設定檔`,
  [`TLS profile is not visible or not available.`]: `TLS 設定檔不可見或無法使用。`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `用於將資料安全傳輸至「鑑別 URL」的 TLS 設定檔。`,
  [`Tabs for code language options`]: `程式碼語言選項的標籤`,
  [`Truststore`]: `信任儲存庫`,
  [`truststore`]: `信任儲存庫`,
  [`Type`]: `類型`,
  [`Type name`]: `類型名稱`,
  [`Type/Field`]: `類型/欄位`,
  [`Type weight`]: `類型加權`,
  [`Types per page:`]: `每頁類型數：`,
  [`Tags`]: `標籤`,
  [`Tags and external documentation`]: `標籤和外部文件`,
  [`Take offline`]: `離線`,
  [`Target`]: `目標`,
  [`Target API (URL)`]: `目標 API (URL)`,
  [`Target SOAP service`]: `目標 SOAP 服務`,
  [`Target Services`]: `目標服務`,
  [`Target service URL`]: `目標服務 URL`,
  [`Target services`]: `目標服務`,
  [`Target URL`]: `目標 URL`,
  [`Target application`]: `目標應用程式`,
  [`Target catalog`]: `目標型錄`,
  [`Target gateway service`]: `目標閘道服務`,
  [`Target gateway services`]: `目標閘道服務`,
  [`Target plan`]: `目標方案`,
  [`Target product`]: `目標產品`,
  [`Target product rate limit`]: `目標產品速率限制`,
  [`Target product subscribability`]: `目標產品訂閱功能`,
  [`Target product visibility`]: `目標產品可見性`,
  [`Target schema`]: `目標綱目`,
  [`Target services have been updated.`]: `已更新目標服務。`,
  [`Task`]: `作業`,
  [`Task has been updated`]: `已更新作業`,
  [`Task self approval`]: `作業自我核准`,
  [`Task self-approval`]: `作業自我核准`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `作業`,
  [`Tasks for creating new provider organizations`]: `用於建立新提供者組織的作業`,
  [`TCP`]: `TCP`,
  [`Template`]: `範本`,
  [`Template: {title}`]: `範本：{title}`,
  [`Template: account approved`]: `範本：帳戶已核准`,
  [`Template: account denied`]: `範本：帳戶已遭拒`,
  [`Template: account pending approval`]: `範本：帳戶擱置核准`,
  [`Template: app lifecycle approved`]: `範本：應用程式生命週期已核准`,
  [`Template: app lifecycle denied`]: `範本：應用程式生命週期已遭拒`,
  [`Template: app lifecycle pending`]: `範本：應用程式生命週期擱置`,
  [`Template: app reinstated`]: `範本：應用程式已恢復`,
  [`Template: app suspended`]: `範本：應用程式已暫停`,
  [`Template: app lifecycle cancelled`]: `範本：應用程式生命週期已取消`,
  [`Template: app lifecycle request`]: `範本：應用程式生命週期要求`,
  [`Template: invitation`]: `範本：邀請`,
  [`Template: mail server test connection`]: `範本：郵件伺服器測試連線`,
  [`Template: member invitation`]: `範本：成員邀請`,
  [`Template: password changed`]: `範本：密碼已變更`,
  [`Template: password reset`]: `範本：密碼重設`,
  [`Template: sign up`]: `範本：註冊`,
  [`Template: task consumer onboard request`]: `範本：作業消費者加入要求`,
  [`Template: task product lifecycle approved`]: `範本：作業產品生命週期已核准`,
  [`Template: task product lifecycle cancelled`]: `範本：作業產品生命週期已取消`,
  [`Template: task product lifecycle denied`]: `範本：作業產品生命週期已遭拒`,
  [`Template: task product lifecycle pending`]: `範本：作業產品生命週期擱置`,
  [`Template: task product lifecycle request`]: `範本：作業產品生命週期要求`,
  [`Template: task subscription cancelled`]: `範本：作業訂閱已取消`,
  [`Template: task subscription request`]: `範本：作業訂閱要求`,
  [`Template: task subscription approved`]: `範本：作業訂閱已核准`,
  [`Template: task subscription denied`]: `範本：作業訂閱已遭拒`,
  [`Template: task subscription pending`]: `範本：作業訂閱擱置`,
  [`Temporarily disable subscribability`]: `暫時停用訂閱功能`,
  [`Temporarily disable visibility`]: `暫時停用可見性`,
  [`Terms of service`]: `服務條款`,
  [`Test`]: `測試`,
  [`Test API`]: `測試 API`,
  [`Test APIs`]: `測試 API`,
  [`Test Bind & Get Base DN`]: `測試連結並取得基本 DN`,
  [`Test configuration`]: `測試配置`,
  [`Test connection`]: `測試連線`,
  [`Test connection failed.`]: `測試連線失敗。`,
  [`Test and production endpoints`]: `測試和正式作業端點`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `測試和監視回應錯誤：需要 redirect_uri Prop，卻取得 {redirectUri}`,
  [`Test application`]: `測試應用程式`,
  [`Test consumer org`]: `測試消費者組織`,
  [`Test email`]: `測試電子郵件`,
  [`Test endpoints`]: `測試端點`,
  [`Test message from IBM API Connect`]: `來自 IBM API Connect 的測試訊息`,
  [`Testable`]: `可測試`,
  [`TEXT`]: `TEXT`,
  [`The "Definition" property must have a valid value.`]: `"Definition" 內容必須包含有效值。`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `"Definition" 內容必須含有 "request"、"response" 或開頭為 "#/definitions/" 的值。`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `API Connect CLI 是作為 IBM Cloud CLI 外掛程式提供。若要開始使用，請執行下列動作：`,
  [`The API has unsaved changes.`]: `API 有未儲存的變更。`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `API 已充分設計並通過內部里程碑，但尚未實作。`,
  [`The API is in the implementation phase.`]: `API 處於實作階段。`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `API 還在初期的概念階段，既未充分設計，也未實作。`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `API 可能有未發佈的變更。您要重新發佈產品嗎？`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `DNS 架構決定端點 URL 組合，後者用於對閘道服務進行入埠 API 呼叫以及用於存取 Developer Portal。`,
  [`The GatewayScript source code to execute.`]: `要執行的 GatewayScript 原始碼。`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `必須用來驗證 GraphQL 有效負載的 GraphQL 綱目 URL。`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `用於呼叫的 HTTP 方法。如果已省略或設為「保留」，則將使用送入要求中的方法。`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `用來取代現行 HTTP 方法的 HTTP 方法。如果已省略或設為「保留」，則將使用送入要求中的方法。`,
  [`The JavaScript source code to execute.`]: `要執行的 JavaScript 原始碼。`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `「輕量型目錄存取通訊協定 (LDAP)」是一種網際網路通訊協定，用於透過網路存取及維護分散式目錄資訊服務。如果已啟用企業 LDAP 服務，請將其配置成資源以提供使用者鑑別。`,
  [`The Proxy URL to be used.`]: `要使用的 Proxy URL。`,
  [`The SSL Profile to use for the secure transmission of data.`]: `用於安全傳輸資料的「SSL 設定檔」。`,
  [`The TLS Profile to use for the secure transmission of data.`]: `用於安全傳輸資料的「TLS 設定檔」。`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `用來驗證 JSON 有效負載的 JSON 綱目 URL。`,
  [`The URL of the XML schema for validating an XML payload.`]: `用來驗證 XML 有效負載的 XML 綱目 URL。`,
  [`The URL to be invoked.`]: `要呼叫的 URL。`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `必須用來驗證 SOAP 有效負載的 WSDL 綱目 URL。`,
  [`The XML element name to be used for JSON array elements.`]: `要用於 JSON 陣列元素的 XML 元素名稱。`,
  [`The XSLT source to execute.`]: `要執行的 XSLT 來源。`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `可以將啟動鏈結傳送給使用者，讓使用者可以啟動其帳戶`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `分析服務會從閘道服務收集 API 事件。每個閘道都具有一個相關聯的分析服務。每當呼叫 API 作業時，即會記載具有時間戳記的 API 事件。`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `分析服務會從閘道服務收集 API 事件。每個閘道服務都可以具有一個相關聯的分析服務。`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `授權 URL 是用戶端應用程式取得授權的位置。記號 URL 是用戶端應用程式用授權換取存取記號的位置。內部檢查 URL 是 API Gateway 驗證協力廠商提供者所發出存取記號的位置。`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `基本路徑是 API 的起始 URL 區段，並且不包括路徑或作業的主機名稱或任何其他區段`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `快取類型決定是否要快取文件，同時允許使用或置換接收自「伺服器」回應的「HTTP 快取控制」指引。此內容只有在接收的回應是來自「伺服器」時才有效，否則，原則一律傳回之前儲存在快取中的未到期回應。`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `「憑證管理程式」服務必須管理閘道管理端點的網域憑證以及閘道所處理的網域。此外，您必須使用 IBM Cloud IAM，以授權「API Connect 保留實例」服務存取「憑證管理程式」服務。`,
  [`The certificate with which to decrypt the buffer`]: `用來解密緩衝區的憑證`,
  [`The certificate with which to encrypt the buffer`]: `用來加密緩衝區的憑證`,
  [`The change is critical`]: `變更很重要`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `用戶端應用程式只能要求您在這裡定義的範圍或範圍子集。這些範圍會併入到從提供者產生的存取記號中。如果呼叫 OAuth 保護的 API，則閘道會根據安全定義中容許範圍的清單，檢查存取記號中包含的範圍，以判斷是否授與存取權。此外，您還可以強制執行進階範圍檢查。在應用程式鑑別之後或在使用者鑑別之後，會根據所配置的 URL 來呼叫進階範圍檢查 URL。存取記號授與的最終範圍權限是所有範圍檢查的結果。`,
  [`The crypto object to use to decode the claim.`]: `用來解密要求的加密物件。`,
  [`The crypto object to use to encrypt the message.`]: `用來加密訊息的加密物件。`,
  [`The crypto object to use to sign the JWT.`]: `用來簽署 JWT 的加密物件。`,
  [`The crypto object to use to verify the signature.`]: `用來驗證簽章的加密物件。`,
  [`The current mapping contains references to unrecognized schema elements.`]: `現行對映包含無法辨識綱目元素的參照。`,
  [`The current organization does not contain any catalogs.`]: `現行組織不包含任何型錄。`,
  [`The default User registry must be included in the list`]: `預設使用者登錄必須包括在清單中`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `必須作為有效負載驗證依據的定義 ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Developer Portal 提供已發佈 API 產品的消費者視圖。配置型錄的 Developer Portal 並且發佈一個以上 API 產品之後，應用程式開發者就可以瀏覽及使用 API。`,
  [`The document is missing draft_api.info.version field.`]: `文件遺漏了 draft_api.info.version 欄位。`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `文件遺漏了 draft_api.info.x-ibm-name 欄位。`,
  [`The document is missing draft_product.info.name field.`]: `文件遺漏了 draft_product.info.name 欄位。`,
  [`The document is missing draft_product.info.version field.`]: `文件遺漏了 draft_product.info.version 欄位。`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `額外限制指定的切割引數不是此欄位中的引數。`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `這個欄位有預設值的限制引數。因此，永遠不會套用給定的假設大小。`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `這個欄位的限制引數有預設值或為必要。因此，永遠不會套用給定的假設大小。`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `這個欄位沒有假設大小也沒有任何切割引數。`,
  [`This field returns unbound list of values with composite type.`]: `這個欄位會傳回無界限的值清單，且這些值為複合式類型。`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `這個欄位會傳回無界限的值清單，且這些值為純量類型和類型加權 != 0.0。`,
  [`This test panel is deprecated and will be removed soon.`]: `這個測試畫面已淘汰，很快便會移除。`,
  [`This type has no fields or no matching field.`]: `這個類型沒有欄位或任何相符欄位。`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `此 GatewayScript 原則不得使用 apim 模組，該模組僅用於移轉舊 API。`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `此 XSLT 原則不得使用 apim 模組，該模組僅用於移轉舊 API。`,
  [`This type will be deleted.`]: `將會刪除這個類型。`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `下圖顯示您的 API 的組件流程。若要修改組件，請使用組件編輯器。`,
  [`The following files are available under the`]: `下列檔案位於`,
  [`The following files contain a private key and cannot be uploaded:`]: `下列檔案包含私密金鑰，因此無法上傳：`,
  [`The following is available under the`]: `下列項目位於`,
  [`The following lifecycle actions require approval`]: `下列生命週期動作需要核准`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `下列清單會顯示在此提供者組織中已建立的草稿 API`,
  [`The following list displays the draft products that have been created in this provider organization`]: `下列清單會顯示已在此提供者組織中建立的草稿產品`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `下列清單顯示已新增為此型錄成員的所有使用者，以及其獲指派的角色`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `下列清單顯示已新增為此空間成員的所有使用者，以及其獲指派的角色`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `下列參數與 OAuth 提供者中配置的值不相符`,
  [`The following roles are available in the Cloud Manager`]: `在 Cloud Manager 中可以使用下列角色`,
  [`The following types/fields will also be removed:`]: `下列類型/欄位也會被移除：`,
  [`The following types/fields will be affected:`]: `下列類型/欄位將會受影響：`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `不容許閘道內容值 {gatewayType}。容許值為 datapower-gateway 和 datapower-api-gateway`,
  [`The host used to invoke the API`]: `用於呼叫 API 的主機`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `金鑰儲存庫包含公開憑證與私密金鑰的相符配對，用來確認身分和加密/解密透過 HTTPS 的資料傳輸。`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `金鑰儲存庫包含公開憑證與私密金鑰的相符配對，用來確認身分和加密/解密透過 HTTPS 的資料傳輸。伺服器設定檔需要金鑰儲存庫。`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `金鑰儲存庫包含伺服器或用戶端為了確認其身分所呈現的公開金鑰和私密憑證。`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `對現行日期和時間增加的時間長度（以秒為單位），LTPA 金鑰在這段時間內視為有效。`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `已新增至現行日期和時間的時間長度（以秒為單位），且在這段時間內 JWT 被視為有效。`,
  [`The message to be sent with the error`]: `要與錯誤一起傳送的訊息`,
  [`The message to be included within exception.`]: `要併入到異常狀況中的訊息。`,
  [`The HTTP status code to be included within exception.`]: `要併入到異常狀況中的 HTTP 狀態碼。`,
  [`The HTTP status reason phrase to be included within exception.`]: `要併入到異常狀況中的 HTTP 狀態原因詞組。`,
  [`The name of a burst limit defined in the datapower configuration.`]: `DataPower 配置中所定義高載限制的名稱。`,
  [`The name of a count limit defined in the datapower configuration.`]: `DataPower 配置中所定義計數限制的名稱。`,
  [`The name of a rate limit defined in the datapower configuration.`]: `DataPower 配置中所定義速率限制的名稱。`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `將用來儲存要求之回應資料的變數名稱。然後，可以在其他動作（例如「對映」）中參照此變數。`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `將用來儲存結果的變數名稱。依預設，將使用 "message"。`,
  [`The name of parameter to find the ID.`]: `用來尋找 ID 的參數名稱。`,
  [`The name of parameter to find the secret.`]: `用來尋找密碼的參數名稱。`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `您要用來產生 LTPA 記號的 LTPA 金鑰名稱。如果您要使用特定的金鑰版本，請指定 <keyname>:<version>。例如，my-ltpa-key:1.0.1。如果您要讓原則自動選取最新版本的金鑰來使用，請指定 <keyname>:latest。例如，my-ltpa-key:latest。`,
  [`The name of the error to be thrown`]: `要擲出之錯誤的名稱`,
  [`The name of the variable to be added.`]: `要新增之變數的名稱。`,
  [`The name of the variable to be cleared.`]: `要清除之變數的名稱。`,
  [`The name of the variable to be set.`]: `要設定之變數的名稱。`,
  [`The nature of the XML validation to be performed.`]: `要執行的 XML 驗證的本質。`,
  [`The operation to apply.`]: `要套用的作業`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `此對映的輸出在陣列內，將會對選擇的輸入反覆運算來建立其內容。如果選擇的輸入不是陣列，則會產生長度為 1 的陣列。`,
  [`The owner must be an existing user in your user registry`]: `擁有者必須是您的使用者登錄中的現有使用者`,
  [`The owner of consumer organization has the following privileges:`]: `消費者組織的擁有者具有下列特權：`,
  [`The owner of provider organization has the following privileges:`]: `提供者組織的擁有者具有下列特權：`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `擁有者的責任包括：管理 API 產品生命週期、管理一個以上 API 提供者組織、管理應用程式提供者社群，以及編寫 API 和產品定義。`,
  [`The page you are looking for might have been moved or does not exist.`]: `您尋找的頁面可能已移動或不存在。`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `您尋找的頁面可能已移動或不存在。請與管理者聯絡，以取得支援。`,
  [`The parameters needed to send a valid API call.`]: `傳送有效 API 呼叫所需的參數。`,
  [`The password to use for HTTP Basic authentication.`]: `用於 HTTP 基本鑑別的密碼。`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `入口網站服務提供 Developer Portal，供應用程式開發者用來探索 API 及上線消費者。`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `入口網站服務提供 Developer Portal，供應用程式開發者用來探索及實作 API。`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `處理狀態提供閘道性能的視圖及最近活動的摘要`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `收件者登錄為擁有者，並建立新的提供者組織。一旦登錄，使用者就能以提供者組織擁有者的身分登入 API Manager。`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `產生的 XML 文件的根元素名稱。只有在輸入 JSON 文件不是階層式文件，且最上層內容不只一個時，才需要此內容。`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `這裡所指的範圍供開發者理解允許存取的項目，對於範圍檢查無效。`,
  [`The selected catalog will only be used to search consumer organizations`]: `所選型錄將僅用於搜尋消費者組織`,
  [`The selected catalog does not have any configured gateways`]: `選取的型錄沒有任何配置的閘道`,
  [`The selected catalog does not have any compatible gateways`]: `選取的型錄沒有任何相容的閘道`,
  [`Save Preferences`]: `儲存喜好設定`,
  [`Preferences`]: `喜好設定`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `所選型錄將僅用於搜尋消費者組織和群組`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `所選閘道類型會導致組件中的下列原則無效。您將需要刪除這些原則，然後才能執行此 API。`,
  [`The selected product does not contain this API`]: `選取的產品不含此 API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `選取的產品將可訂閱至特定清單的消費者組織或群組。`,
  [`The selected services will be available to a specific list of provider organizations.`]: `選取的服務將可供特定清單的提供者組織使用。`,
  [`The selected services will be available to all existing and new provider organizations.`]: `選取的服務將可供所有現有和新提供者組織使用。`,
  [`The selected services will not be available to any provider organizations.`]: `選取的服務將無法供任何提供者組織使用。`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `要用作快取索引鍵的字串。如果已省略，則會使用整個 URL 字串作為索引鍵。`,
  [`The server responded with a non-JSON body \n{body}`]: `伺服器回應了非 JSON 主體 \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `時間間隔需要比存取記號的存活時間還長。`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `記號不會儲存在 Cookie 或本端儲存體中。如果您關閉測試畫面，將遺失記號，但記號仍然有效。`,
  [`The transfer protocol of the API.`]: `API 的傳送通訊協定。`,
  [`The type of authentication.`]: `鑑別的類型。`,
  [`The type of authorization header.`]: `授權標頭的類型。`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `要設定的值類型。可以是全部、字串、數字或布林值。`,
  [`The type of the value to set. This can be string, number or boolean.`]: `要設定的值類型。可以是字串、數字或布林值。`,
  [`The username to use for HTTP Basic authentication.`]: `用於 HTTP 基本鑑別的使用者名稱。`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `此使用者的連線不再有效。 請建立新的連線。`,
  [`The value that the variable will be set to.`]: `此變數將設為的值。`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `這個欄位的值是複合式類型的清單，沒有假設大小也沒有任何切割引數。如果發現，API Gateway 無法設定 GraphQL 伺服器在收到查詢時，可以傳回的物件數限制。`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `這個欄位的值是純量類型的清單，沒有假設大小也沒有任何切割引數。如果發現，API Gateway 無法設定 GraphQL 伺服器在收到查詢時，可以傳回的物件數限制。`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `版本必須能在 URL 中使用。物件版本 {version} 和 Slug 化版本 {slugVersion} 不符。`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `"name" 內容必須滿足 URL 安全要求（即物件的名稱值 "{name}" 與 slugified 名稱值 "{slugName}" 必須相符）。`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `'draft_api' 內容必須包含 OpenAPI 定義。OpenAPI 第 3 版僅支援 3.0.0 版 - 3.0.3 版（指定 {version} 版）`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `根據您提供的標題，自動將資料移入名稱欄位。請使用 API Connect 指令和 API 呼叫內產生的值。`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `可見性設定會決定可以存取資源的提供者組織。選項如下：「公開」（所有提供者組織皆可使用資源）；「自訂」（只有您指定的提供者組織可以使用資源）；及「私密」（任何提供者組織皆看不見且無法使用資源）。`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `可見性設定會決定可以使用服務的提供者組織。選項如下：「公開」（所有提供者組織皆可使用服務）；「自訂」（只有您指定的提供者組織可以使用服務）；及「私密」（任何提供者組織皆看不見且無法使用服務）。`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `可見性設定會決定可以使用閘道的提供者組織。可見性層次包括：「公開」（所有提供者組織皆可使用閘道）；「自訂」（只有您指定的提供者組織可以使用閘道）；及「私密」（任何提供者組織皆看不見且無法使用閘道）。`,
  [`The {title} role`]: `{title} 角色`,
  [`The {url} resource does not exist`]: `{url} 資源不存在`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `有不相容的 API，因為其閘道類型不同於此產品。`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `然後，為管理端點的網域選取憑證。此外，如果憑證並非由已知的憑證管理中心所指派，請選取對應的 CA 組合。憑證和組合必須受「憑證管理程式」服務管理。`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `然後，指定將由此閘道處理的網域。會利用 SNI 技術；如此一來，就可以透過相同的 IP 位址和埠提供多個網域，且不需提供相同的憑證。支援萬用字元格式。預設（籠統）網域 '*' 必須包含在表格中，以作為最終項目。必要的話，可輸入其他網域名稱，並在個別的情況下，指定憑證以交由「憑證管理程式」服務管理。`,
  [`There should be at least {minSelected} {label} selected.`]: `應該至少選取 {minSelected} 個「{label}」。`,
  [`There are no API changes to publish.`]: `沒有 API 變更可以發佈。`,
  [`There are no gateway services`]: `沒有任何閘道服務`,
  [`There are no assemblies that match the chosen filters.`]: `沒有符合所選過濾器的組件。`,
  [`There are no burst limits yet.`]: `尚不存在高載限制。`,
  [`There are no catalogs`]: `沒有型錄`,
  [`There are no consumer organizations.`]: `沒有任何消費者組織。`,
  [`There are no consumer organizations. You can create one`]: `沒有任何消費者組織。您可以在這裡建立一個`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `沒有任何消費者組織。您可以在 [這裡]({link}) 建立一個`,
  [`There are no email servers`]: `沒有任何電子郵件伺服器`,
  [`There are no gateway services configured for the sandbox catalog`]: `未給沙箱型錄配置任何閘道服務`,
  [`There are no lifecycle approvals enabled`]: `未啟用生命週期核准`,
  [`There are no new changes.`]: `沒有新變更。`,
  [`There are no products to display`]: `沒有要顯示的產品`,
  [`There are no products to replace`]: `沒有要取代的產品`,
  [`There are no products to set migration target`]: `沒有要設定移轉目標的產品`,
  [`There are no products to supersede`]: `沒有要接替的產品`,
  [`There are no properties to configure for this policy`]: `沒有可為此原則配置的內容`,
  [`There are no rate limits yet.`]: `尚不存在速率限制。`,
  [`There are no security definitions`]: `沒有安全定義`,
  [`There are no spaces`]: `沒有空間`,
  [`There are no target services`]: `沒有目標服務`,
  [`There are no tasks to be displayed`]: `沒有要顯示的作業`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `有變更尚未儲存。您確定要繼續嗎？`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `有變更尚未儲存。如果您繼續將會捨棄這些變更。確定嗎？`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `您的 yaml 中有一個不存在且無法列出的 API，請檢查來源標籤。`,
  [`There is no item in the list.`]: `清單中沒有項目。`,
  [`There is no space`]: `沒有空間`,
  [`There is no warning in the schema.`]: `綱目中沒有警告。`,
  [`There was no data found to populate the data table.`]: `找不到任何資料來移入至資料表。`,
  [`These endpoints are used for all the APIs in the catalog`]: `這些端點用於型錄中的所有 API`,
  [`Third party OAuth provider`]: `協力廠商 OAuth 提供者`,
  [`Third party OAuth provider summary`]: `協力廠商 OAuth 提供者摘要`,
  [`Third party`]: `協力廠商`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `此 API 尚未包含組件。您要建立一個嗎？`,
  [`This resource no longer exists, please refresh the results from the server.`]: `此資源已不存在，請重新整理以顯示來自伺服器的結果。`,
  [`Please refresh the results from the server`]: `請重新整理以顯示來自伺服器的結果`,
  [`Exact match`]: `完全相符項`,
  [`Closest match`]: `最接近的相符項`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `此 API 目前正在由產品 {productName} 使用。請從該產品刪除它，然後再繼續刪除 API。`,
  [`This API will be available to be invoked when the following option is enabled.`]: `啟用下列選項時，將可以呼叫此 API。`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `此型錄未啟用自動訂閱。若要在此型錄中測試，請在下面選取產品、方案及應用程式。`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `此端點用作未定義基本端點之 API 的預設值`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `這是技術預覽特性，未來仍會演進並持續改良。`,
  [`This is the currently configured notification server.`]: `這是目前配置的通知伺服器。`,
  [`This is the directory where your files including policies and extensions will be stored`]: `這是將在其中儲存您的檔案（包括原則和延伸）的目錄`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `將在此位置執行 API，而且原則和延伸可以存取`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `此 OAuth 原則會執行所有 OAuth 通訊協定步驟，依預設，發出的路徑需要這些步驟。每個步驟的輸入及輸出由記載的環境定義變數驅動。視需要新增或移除支援的 OAuth 元件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `此 OAuth 原則會執行所有 OAuth/OpenID Connect 通訊協定步驟，依預設，OAuth 驗證需要這些步驟。每個步驟的輸入及輸出由記載的環境定義變數驅動。視需要新增或移除支援的 OAuth 元件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `此 OAuth 原則會執行所有 OAuth/OpenID Connect 通訊協定步驟，依預設，所有其他路徑需要這些步驟。每個步驟的輸入及輸出由記載的環境定義變數驅動。視需要新增或移除支援的 OAuth 元件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `此 OAuth 原則會執行所有 OAuth/OpenID Connect 通訊協定步驟，依預設，az 程式碼路徑需要這些步驟。每個步驟的輸入及輸出由記載的環境定義變數驅動。視需要新增或移除支援的 OAuth 元件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `此 OAuth 原則會執行所有 OAuth/OpenID Connect 通訊協定步驟，依預設，記號路徑需要這些步驟。每個步驟的輸入及輸出由記載的環境定義變數驅動。視需要新增或移除支援的 OAuth 元件。`,
  [`This operation is secured with access code flow OAuth`]: `此作業使用存取碼流 OAuth 保護。`,
  [`This operation is secured with application flow OAuth`]: `此作業使用應用程式流程 OAuth 保護。`,
  [`This operation is secured with basic authentication`]: `此作業使用基本鑑別 (BA) 保護。`,
  [`This operation is secured with implicit flow OAuth`]: `此作業使用隱含流程 OAuth 保護。`,
  [`This operation is secured with password flow OAuth`]: `此作業使用密碼流程 OAuth 保護。`,
  [`This policy is not available on the selected gateway`]: `此原則不適用於選取的閘道`,
  [`This policy is used by one other operation`]: `此原則是由一個其他作業使用`,
  [`This policy is used by {{count}} other operations`]: `此原則由另外 {{count}} 項作業所使用`,
  [`This role cannot be edited`]: `無法編輯此角色`,
  [`This role cannot be edited.`]: `無法編輯此角色。`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `此設定控制在對映由其輸入不存在且未配置任何預設對映而失敗時的行為。預設行為是不變更輸出，但如果您選取這個勾選框，則會為目標對映的母項建立空白物件，模擬第 4 版的行為。`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `此使用者安全原則對 OAuth 組件執行 EI(basic) 及 AU(auth url) 檢查。視需要變更安全檢查方法`,
  [`This will enable/disable application lifecycle`]: `這將啟用/停用應用程式生命週期`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `這將啟用/停用作業自我核准。 作業自我核准可讓作業由其創始者及合作人員核准。 此選項在其他核准者沒有空時很便利，但實際上容許略過其他使用者進行的檢查。`,
  [`This will unset the catalog as the default catalog.`]: `這會將型錄取消設定為預設型錄。`,
  [`Throw`]: `擲出`,
  [`Time`]: `時間`,
  [`Time Interval`]: `時間間隔`,
  [`Time Limit`]: `時間限制`,
  [`Time to Live`]: `存活時間`,
  [`Time to live`]: `存活時間`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `端點回覆之前的等待時間。預設值是 60 秒。`,
  [`Timeout`]: `逾時`,
  [`To`]: `收件者`,
  [`to`]: `收件者`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `若要將使用者新增為管理者組織的成員，請選取其使用者登錄，輸入其使用者名稱，然後選取要指派的角色。如果使用者登錄類型為本端使用者登錄，您也可以選擇建立新的使用者，隨後將其新增為成員。成員已立即啟用，且指定的使用者可以登入 Cloud Manager 使用者介面`,
  [`To add a user as a member of a provider organization`]: `若要將使用者新增為提供者組織的成員`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `若要將使用者新增為型錄的成員，請選取其使用者名稱並指派必要的角色。您可以新增任何已是提供者組織中另一個「型錄」或「空間」成員的使用者，而且目前不是這個「型錄」的成員，也不是「型錄」擁有者。`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `若要將使用者新增為空間的成員，請選取其使用者名稱並指派必要的角色。您可以新增任何已是提供者組織中另一個「型錄」或「空間」成員的使用者，而且目前不是這個「空間」的成員，也不是「空間」擁有者。`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `若要新增或移除成員，請使用 [身分及存取](/iam) 服務。`,
  [`To add members, use the [identity and access](/iam) service.`]: `若要新增成員，請使用 [身分及存取](/iam) 服務。`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `若要將使用者新增為組織成員，請選取其使用者登錄，輸入其使用者名稱，然後指派角色集。`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `若要將使用者新增為成員，請選取其使用者名稱並給使用者指派一組角色。`,
  [`To assign default gateways, click **Edit**.`]: `如果要指派預設閘道，請按一下「編輯」。`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `若要繼續使用此軟體，您必須同意軟體授權合約的條款。`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `若要繼續使用 API Connect，請升級至企業方案。`,
  [`To get started, you must first enable spaces.`]: `若要開始，則必須先啟用空間。`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `如果要安裝及執行工具箱，請完成下列步驟。[進一步瞭解]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `若要邀請新使用者成為管理者組織的成員，請輸入使用者的電子郵件位址或名稱，或是從使用者登錄選取其名稱。系統會自動為所有成員指派成員角色，成員角色會提供一組基本權限且無法刪除。如果不需要其他權限，請保留其他角色不勾選。`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `若要邀請尚不在您的使用者登錄中的擁有者，請輸入其電子郵件位址`,
  [`To register a new gateway, visit the **Gateways** page.`]: `若要登錄新閘道，請造訪「閘道」頁面。`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `若要將值替換為下列其中一個空間內容，請輸入 $() 並且在兩個括弧之間輸入內容名稱`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `若要將電子郵件邀請傳送至要登錄為管理者組織成員的新使用者，請輸入有效的電子郵件位址，然後選取要指派給使用者的角色。一封含有啟動鏈結的電子郵件會傳送至該電子郵件位址，邀請人員進行登錄。使用者會置於「擱置」狀態，且在其完成登錄表單以啟動其帳戶後切換至「已啟用」狀態。他們隨後可以使用他們在啟動帳戶期間指定的 API Connect 使用者名稱來登入 Cloud Manager 使用者介面。`,
  [`Toggle`]: `切換`,
  [`Toggle application lifecycle?`]: `切換應用程式生命週期？`,
  [`Toggle default catalog?`]: `是否切換預設型錄？`,
  [`Toggle info panel between right and bottom`]: `切換資訊畫面位置在右側或底部`,
  [`Toggle profile`]: `切換設定檔`,
  [`Toggle spaces?`]: `是否切換空間？`,
  [`Toggle task self-approval?`]: `切換作業自我核准？`,
  [`Token`]: `記號`,
  [`Token endpoint`]: `記號端點`,
  [`Token Expiry`]: `記號期限`,
  [`Token Management`]: `記號管理`,
  [`Token Output`]: `記號輸出`,
  [`Token URL`]: `記號 URL`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `記號 URL 必須符合 OAuth 提供者 "{providerTitle}" 所定義的 {endpoint}`,
  [`Token Version`]: `記號版本`,
  [`Token management`]: `記號管理`,
  [`Token management type`]: `記號管理類型`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `記號管理使您可防止重播攻擊及管理記號，其中包括用戶端應用程式或資源擁有者是否及如何撤銷 OAuth 記號。API Connect 藉由原生閘道或協力廠商端點來支援記號管理。如果是原生閘道，則使用配額強制執行來管理記號。如果是協力廠商端點，則由您提供外部服務的 URL 來管理記號。`,
  [`Token path`]: `記號路徑`,
  [`Token revocation`]: `記號撤銷`,
  [`Token secret`]: `記號密碼`,
  [`Token validation`]: `記號驗證`,
  [`Tokens`]: `記號`,
  [`Token relay`]: `記號中繼`,
  [`Toolkit credentials`]: `工具箱認證`,
  [`Token Relay`]: `記號中繼`,
  [`Topology`]: `拓蹼`,
  [`Topology Administrator`]: `拓蹼管理者`,
  [`Total time`]: `時間總計`,
  [`Transfer Ownership to a New User`]: `轉移所有權給新使用者`,
  [`Transfer Ownership to an Existing User`]: `轉移所有權給現有使用者`,
  [`Transfer ownership`]: `轉移所有權`,
  [`Transfer ownership to`]: `轉移所有權給`,
  [`Transfer ownership to a new user`]: `轉移所有權給新使用者`,
  [`Transfer ownership to an existing user`]: `轉移所有權給現有使用者`,
  [`Transforms`]: `轉換`,
  [`Truststore Certificates`]: `信任儲存庫憑證`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `信任儲存庫憑證為憑證管理中心發出的公開憑證。`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `信任儲存庫包含授信憑證，其中含有已驗證的公開金鑰。信任儲存庫中的憑證通常是從協力廠商憑證管理中心 (CA) 取得。`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `信任儲存庫包含授信憑證，其中含有已驗證的公開金鑰。信任儲存庫中的憑證通常是從協力廠商憑證管理中心 (CA) 取得。`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `信任儲存庫會儲存授信憑證，其中含有已驗證的公開金鑰。信任儲存庫中的憑證通常是從協力廠商憑證管理中心 (CA) 取得。`,
  [`Try again or upload a schema`]: `重試或上傳綱目`,
  [`Try refreshing the page or contacting support.`]: `請嘗試重新整理頁面，或與支援中心聯絡。`,
  [`Turkish`]: `土耳其文`,
  [`Tutorials`]: `指導教學`,
  [`Types`]: `類型`,
  [`Type of user`]: `使用者類型`,
  [`Type or select a catalog`]: `輸入或選取型錄`,
  [`Type to add organizations`]: `輸入以新增組織`,
  [`TypeError: Failed to fetch`]: `TypeError：無法提取`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `管理端點的 URL`,
  [`URLs for all operations in the API begin with this value.`]: `API 中所有作業的 URL 都以此值開頭。`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `使用者定義`,
  [`Userinfo POST`]: `使用者資訊 POST`,
  [`USER MANAGED`]: `使用者管理`,
  [`USERNAME`]: `使用者名稱`,
  [`User can delete the keystore history.`]: `使用者可以刪除金鑰儲存庫歷程。`,
  [`Unable to parse API definition: {msg}`]: `無法剖析 API 定義：{msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `無法繼續進行，因為在移除類型及/或欄位時發現錯誤。`,
  [`Unarchive`]: `取消保存`,
  [`Unassociate`]: `取消關聯`,
  [`Unassociate analytics service`]: `將分析服務取消關聯`,
  [`Unauthorized`]: `未獲授權`,
  [`Unauthorized API Request`]: `未獲授權的 API 要求`,
  [`Unbound lists`]: `無界限的清單`,
  [`Unit`]: `單位`,
  [`Union`]: `聯集`,
  [`Union type`]: `聯集類型`,
  [`Unique email address`]: `唯一的電子郵件位址`,
  [`Unlimited`]: `無限制`,
  [`Unnecessary assumed size`]: `不需要的假設大小`,
  [`Unnecessary slicing arguments`]: `不需要的切割引數`,
  [`Unrecognized Schema Element(s) Encountered`]: `發現了無法辨識的綱目元素`,
  [`Unsupported media type ({type})`]: `不受支援的媒體類型 ({type})`,
  [`Untitled`]: `未命名`,
  [`Update`]: `更新`,
  [`Update gateway services`]: `更新閘道服務`,
  [`Update assembly`]: `更新組件`,
  [`Update group failed.`]: `更新群組失敗`,
  [`Update schema defaults giving preference to`]: `更新喜好設定的綱目預設值`,
  [`Update the sender details that are displayed on invitation emails`]: `更新邀請電子郵件上顯示的寄件者詳細資料`,
  [`Update the summary details for this role`]: `更新此角色的摘要詳細資料`,
  [`Updating Draft API`]: `正在更新草稿 API`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `更新綱目預設值將置換您的變更。您確定要繼續嗎？`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `不容許使用 {type} 類型的 API 來更新 {existingType} 類型的 API。`,
  [`Upload`]: `上傳`,
  [`Upload a schema definition language file to replace your schema.`]: `上傳綱目定義語言檔以取代您的綱目。`,
  [`Upload GraphQL schema`]: `上傳 GraphQL 綱目`,
  [`Upload local`]: `上傳本端`,
  [`Upload policies`]: `上傳原則`,
  [`Upload policy`]: `上傳原則`,
  [`Upload schema`]: `上傳綱目`,
  [`Upload schema definition language (SDL) file`]: `上傳綱目定義語言 (SDL) 檔案`,
  [`Upload truststore certificates`]: `上傳信任儲存庫憑證`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `為應用程式上傳 PEM 格式的 X509 憑證。您只能上傳一個 PEM 檔案，但可以將多個憑證併入單一檔案中。`,
  [`Upload the X509 certificate for your application, in PEM format`]: `為應用程式上傳 PEM 格式的 X509 憑證`,
  [`Upload your public certificate`]: `上傳您的公用憑證`,
  [`Uploaded and validated YAML file`]: `已上傳並驗證 YAML 檔案`,
  [`Upload extension`]: `上傳延伸`,
  [`Use all compatible gateway services`]: `使用所有相容的閘道服務`,
  [`Use Content Type`]: `使用內容類型`,
  [`Use DataPower API Gateway`]: `使用 DataPower API Gateway`,
  [`Use DataPower Gateway`]: `使用 DataPower Gateway`,
  [`Use Dynamic DNS`]: `使用動態 DNS`,
  [`Use JSONata to specify the property to redact or remove`]: `使用 JSONata 指定要編寫或移除的內容`,
  [`Use XPath to specify the property to redact or remove.`]: `使用 XPath 以指定要編寫或移除的內容。`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `使用選取的型錄作為您要發佈的目標預設型錄`,
  [`Use an existing JSON or YAML definition of the product`]: `使用產品的現有 JSON 或 YAML 定義`,
  [`Use an existing definition of a REST proxy`]: `使用 REST Proxy 的現有定義`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `使用 REST Proxy 或 SOAP API 的現有定義`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `使用 REST Proxy、GraphQL Proxy 或 SOAP API 的現有定義`,
  [`Use an existing definition of a product`]: `使用產品的現有定義`,
  [`Use context current payload`]: `使用環境定義現行有效負載`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `使用自訂的型錄端點 URL（第 5 版相容性）`,
  [`Use dynamic DNS`]: `使用動態 DNS`,
  [`Use endpoint from API`]: `從 API 使用端點`,
  [`Use gateway URLs`]: `使用閘道 URL`,
  [`Use gateway service configured OAuth shared secret key`]: `使用閘道服務配置的 OAuth 共用密碼金鑰`,
  [`Use group authentication`]: `使用群組鑑別`,
  [`Use only first array element`]: `僅使用第一個陣列元素`,
  [`Use static DNS`]: `使用靜態 DNS`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `使用入口網站作為外部 OIDC 提供者傳輸的端點`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `使用 API Explorer 來測試並瀏覽 API。Explorer 會顯示專案目錄所含全部 API 的作業、定義和文件。Explorer 會列出參數、模型實例資料和回應碼以及用於執行 API 的範本程式碼。`,
  [`Use the actions menu to manage and publish your APIs.`]: `使用動作功能表來管理及發佈 API。`,
  [`Use the actions menu to manage and publish your products`]: `使用動作功能表來管理及發佈您的產品`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `使用活動日誌原則，可配置儲存在「分析」中的 API 活動的記載喜好設定。您指定的喜好設定將置換用來收集及儲存 API 活動詳細資料的預設值。`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `使用用戶端安全原則來擷取並對用戶端認證進行鑑別。`,
  [`Use the default built-in Sandbox Catalog`]: `使用預設內建沙盤推演型錄`,
  [`Use the editor for manual input or upload a file below.`]: `使用編輯器進行手動輸入或在下面上傳檔案。`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `使用編輯器進行手動輸入或在下面上傳檔案。只有在定義 XML/XSD 檔後，才能以 XML 格式編輯現行綱目。`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `使用強制施行的延伸來指定是否使用 API Connect 閘道來強制施行 API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `當使用 CLI 時，請使用下列「管理端點 URL」。`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `在 API Designer 中建立雲端連線時，請使用下列「管理端點 URL」。`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `使用 GatewayScript 原則，可執行指定的 DataPower GatewayScript 程式。`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `使用 graphql-introspect 原則，對 GraphQL 綱目進行內部檢查。`,
  [`Use the host value from Open API Definition`]: `使用 Open API 定義中的主機值`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `使用「如果」原則，在履行條件時套用組合中的區段。`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `使用 JavaScript 原則，可執行指定的 JavaScript 程式。`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `使用 'json-to-xml' 原則，可將有效負載的內容從 JSON 轉換成 XML。`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `使用「記載」原則，自訂或置換 API 的預設活動記載配置。`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `使用「對映」原則，將轉換套用至您的組件流程，並指定變數之間的關係。`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `使用「對映」原則，可指定 API 流程中變數之間的關係，以及將轉換套用至這些變數。`,
  [`Use the oauth policy to generate OAuth tokens.`]: `使用 OAuth 原則來產生 OAuth 記號。`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `使用「作業切換」原則，將組件的區段套用至特定的作業。`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `使用「剖析」原則將要求或訊息剖析為 XML 或 JSON，或者將二進位資料剖析為二進位大型物件 (BLOB)。`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `使用「設定變數」原則，可將執行時期變數設為字串值，或新增或清除執行時期變數。`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `使用「擲出」原則，以便在執行組件流程期間遇到此原則時，即擲出錯誤。`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `使用「使用者安全」原則來擷取、鑑別及授權使用者身分。`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `使用「驗證」原則搭配 DataPower Gateway，以根據 JSON 或 XML 綱目來驗證組件流程中的有效負載。`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `使用「XML 至 JSON」原則，可將有效負載的內容從 XML 轉換成 JSON。`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `使用 XSLT 原則，可將 XSLT 轉換套用至有效負載。`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `用於上傳時啟用/停用 Content-Encoding 壓縮。`,
  [`User`]: `使用者`,
  [`User group prefix`]: `使用者群組字首`,
  [`User group suffix`]: `使用者群組字尾`,
  [`User mapping`]: `使用者對映`,
  [`User Registries`]: `使用者登錄`,
  [`User registries`]: `使用者登錄`,
  [`User Registry Name`]: `使用者登錄名稱`,
  [`User registry name`]: `使用者登錄名稱`,
  [`User Registry cannot be deleted`]: `無法刪除使用者登錄`,
  [`User Registry "{name}" has been deleted`]: `已刪除使用者登錄 "{name}"`,
  [`User security`]: `使用者安全`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `啟用空間後，會在每個空間中管理使用者登錄。請移至空間設定以管理使用者登錄。`,
  [`User registries defined for consumer onboarding`]: `針對消費者上線定義的使用者登錄`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `針對消費者上線定義的使用者登錄\n\nDeveloper Portal 中的每個帳戶（包括相同網站不同使用者登錄中的帳戶）必須具有唯一的電子郵件位址，其中包括網站管理者帳戶。`,
  [`User registry`]: `使用者登錄`,
  [`User registry configuration has been updated.`]: `使用者登錄配置已更新。`,
  [`User registry has been created`]: `已建立入使用者登錄`,
  [`User registry {title} deleted.`]: `已刪除使用者登錄 {title}。`,
  [`User registry {title} has been created.`]: `已建立使用者登錄 {title}。`,
  [`User registry is not visible or not available.`]: `使用者登錄不可見或無法使用。`,
  [`User registry {title} has been updated.`]: `已更新使用者登錄 {title}。`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `只有在所選授權類型包含下列其中一個以上的項目時，使用者安全設定才適用：隱含、存取碼和資源擁有者 - 密碼。`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `只有在所選授權類型包含下列其中一個以上的項目時，使用者安全設定才適用：隱含、存取碼和資源擁有者 - 密碼。如果您在這個頁面上做出任何變更，則也應該在「配置」頁面上至少選取其中的一種授權類型。`,
  [`UserInfo endpoint`]: `使用者資訊端點`,
  [`UserInfo URL`]: `使用者資訊 URL`,
  [`Username`]: `使用者名稱`,
  [`Username / Email`]: `使用者名稱/電子郵件`,
  [`Username and password fields are required in order to test configuration`]: `需要使用者名稱和密碼欄位，才能測試配置`,
  [`Username context variable`]: `使用者名稱環境定義變數`,
  [`Username of the new user`]: `新使用者的使用者名稱`,
  [`Username should not contain special characters!`]: `使用者名稱不應該包含特殊字元！`,
  [`Username should not include spaces!`]: `使用者名稱不應該包含空格！`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `屬於消費者組織的使用者可以存取 Developer Portal 及其發佈的 API 產品，取決於其可見性和訂閱功能。`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `使用 [IBM Cloud IAM]({link})，以授權「API Connect 保留實例」服務存取「憑證管理程式」服務。`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `聯絡使用者資訊端點時使用 HTTP POST 方法`,
  [`Use IBM APIC token expiration setting from the cloud`]: `使用雲端中的 IBM APIC 記號到期設定`,
  [`Update API`]: `更新 API`,
  [`VALUE`]: `值`,
  [`Version`]: `版本`,
  [`Visible to`]: `可見對象`,
  [`Validate`]: `驗證`,
  [`Validate message body`]: `驗證訊息內文`,
  [`Validate message headers`]: `驗證訊息標頭`,
  [`Validate message fault details`]: `驗證訊息錯誤詳細資料`,
  [`Validate SOAP 1.1 encoding rule`]: `驗證 SOAP 1.1 編碼規則`,
  [`Validate JSON Web Token (JWT)`]: `驗證 JSON Web 記號 (JWT)`,
  [`Validate JWT`]: `驗證 JWT`,
  [`Validate Username Token`]: `驗證使用者名稱記號`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `使用鑑別 URL 或 LDAP 使用者登錄來驗證 Web 服務安全 UsernameToken 有效負載。`,
  [`Validate against`]: `驗證依據`,
  [`Validate request`]: `驗證要求`,
  [`Validate the GraphQL query and analyze the expected cost`]: `驗證 GraphQL 查詢及分析預期的成本`,
  [`Validate the returned JSON and analyze the real cost`]: `驗證傳回的 JSON 及分析真實的成本`,
  [`Validating API ...`]: `正在驗證 API ...`,
  [`Validating Draft API`]: `正在驗證草稿 API`,
  [`Validity Period`]: `有效期間`,
  [`Value`]: `值`,
  [`Value for located in must be selected.`]: `必須選取「位於」的值。`,
  [`Value for the variable to be added.`]: `要新增之變數的值。`,
  [`Value for type must be selected.`]: `必須選取類型的值。`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `"@cost" 指引中的 "weight" 引數值小於 0。`,
  [`Values`]: `值`,
  [`Vanity API endpoint`]: `虛名 API 端點`,
  [`Variable name`]: `變數名稱`,
  [`Variable value`]: `變數值`,
  [`Vendor Extensions`]: `供應商延伸`,
  [`Verb`]: `動詞`,
  [`Verify`]: `驗證`,
  [`Verify Access Token`]: `驗證存取記號`,
  [`Verify Crypto JWK variable name`]: `驗證加密 JWK 變數名稱`,
  [`Verify Crypto Object`]: `驗證加密物件`,
  [`Verify Token`]: `驗證記號`,
  [`Verify authorization code`]: `驗證授權碼`,
  [`Verify refresh token`]: `驗證重新整理記號`,
  [`Version of the LTPA token.`]: `LTPA 記號的版本。`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `請在匯入的產品檔案中檢查 'plans' 下的 'apis' 內容。 每一個計劃都應該有 'apis' 內容指向 'apis' 區段中至少一個 API。`,
  [`View`]: `檢視`,
  [`View extensions`]: `檢視延伸`,
  [`View Forum`]: `檢視討論區`,
  [`View owners`]: `檢視擁有者`,
  [`View policies`]: `檢視原則`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `檢視及管理可供所選取通訊協定版本使用的密碼。`,
  [`View analytics`]: `檢視分析`,
  [`View and edit permissions for the roles in this space`]: `檢視及編輯此空間中角色的權限`,
  [`View and edit the permissions for the roles in the current organization`]: `檢視及編輯現行組織中角色的權限`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `檢視及編輯消費者組織的角色預設值中包含的角色`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `檢視及編輯提供者組織的角色預設值中包含的角色`,
  [`View approval history`]: `檢視核准歷程`,
  [`View Approval History`]: `檢視核准歷程`,
  [`View documentation and tutorials with step-by-step instructions.`]: `檢視含有逐步指示的說明文件和指導教學。`,
  [`View error ID`]: `檢視錯誤 ID`,
  [`View status`]: `檢視狀態`,
  [`View more`]: `檢視更多`,
  [`View source`]: `檢視來源`,
  [`View subscription`]: `檢視訂閱`,
  [`View template in`]: `檢視範本：`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `檢視用於存取 API Connect 使用者介面的端點。`,
  [`View the endpoints for the admin and provider REST APIs.`]: `檢視管理者和提供者 REST API 的端點。`,
  [`View the endpoints for the consumer REST APIs.`]: `檢視消費者 REST API 的端點。`,
  [`Viewer`]: `檢視者`,
  [`Views the API consumer organization`]: `檢視 API 消費者組織`,
  [`Views the API provider organization`]: `檢視 API 提供者組織`,
  [`Views the admin organization`]: `檢視管理者組織`,
  [`Views the app developer organization`]: `檢視應用程式開發者組織`,
  [`Views the catalog`]: `檢視型錄`,
  [`Views the space`]: `檢視空間`,
  [`View|permission`]: `檢視`,
  [`Visibility`]: `可見性`,
  [`Visibility options`]: `可見性選項`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware 文件`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL URL`,
  [`WSDL has been successfully validated`]: `已順利驗證 WSDL`,
  [`WSDL to REST`]: `WSDL 至 REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `WS-I 基本設定檔驗證`,
  [`Warn`]: `警告`,
  [`Warning`]: `警告`,
  [`Warning:`]: `警告：`,
  [`Warnings`]: `警告`,
  [`We just emailed recipients a test message:`]: `我們剛剛向收件者傳送了含有測試訊息的電子郵件：`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `正在移往「測試」標籤，以供您測試及除錯 API。`,
  [`Weather Product`]: `天氣產品`,
  [`Web Endpoint`]: `Web 端點`,
  [`Web service operations`]: `Web 服務作業`,
  [`weight`]: `加權`,
  [`Weight`]: `加權`,
  [`Welcome to API Connect Administration`]: `歡迎使用「API Connect 管理」`,
  [`Welcome to API Connect Reserved`]: `歡迎使用 API Connect 保留實例`,
  [`Welcome to API Designer`]: `歡迎使用 API Designer`,
  [`Welcome to API Manager`]: `歡迎使用 API Manager`,
  [`Welcome to Cloud Manager`]: `歡迎使用 Cloud Manager`,
  [`Welcome to the API Designer`]: `歡迎使用 API Designer`,
  [`Welcome to the API Manager`]: `歡迎使用 API Manager`,
  [`Welcome to the Cloud Manager`]: `歡迎使用 Cloud Manager`,
  [`What are you looking for today?`]: `您今天想要搜尋什麼？`,
  [`What's new`]: `最新消息`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `下一步為何？在型錄中啟用計費整合，以容許使用者新增其產品的方案。`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `如果設為 true，'invoke' 原則會將 "X-Forwarded-For"、"X-Forwarded-To"、"X-Forwarded-Host"、"X-Forwarded-Proto" 標頭注入傳送至目標 URL 的要求。`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `發佈後，API 產品在與型錄相關聯的 Developer Portal 中會變成可見。產品中 API 的端點在與目標型錄或空間相關聯的閘道上會變為可用。暫置產品存在於型錄中，但對於消費者而言不是活動或可見的。`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `發佈後，API 產品在與型錄相關聯的 Developer Portal 中會變成可見。產品中 API 的端點在與目標型錄或空間相關聯的閘道上會變為可用。暫置產品存在於型錄中，但對於消費者而言不是活動或可見的。請注意，在非正式作業型錄中重新暫置/重新發佈產品將移除現有訂閱。`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `為型錄啟用空間後，必須從空間檢視已配置的 OAuth 提供者。`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `啟用空間後，型錄中的現有產品將移至預設空間。`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `在使用者按一下啟動鏈結時，即會要求其完成登錄表單。登錄之後，使用者即可登入。`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `當您新增提供者或消費者組織的角色時，系統會將該角色併入在角色預設值中。「角色預設值」是預先配置的角色範本，用來決定建立新組織時的可用預設角色。`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `淘汰產品時，已訂閱該產品的應用程式開發者可以繼續使用它，但新開發者無法訂閱該產品。\n\n [進一步瞭解]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `當您編輯提供者或消費者組織的角色時，系統會將該角色併入在角色預設值中。「角色預設值」是預先配置的角色範本，用來決定建立新組織時的可用預設角色。`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `當您啟用 OpenID Connect 時，系統會提供一個範本以產生 ID 記號及存取記號，並且會自動建立必要的組件原則。 您可以自訂原則以適合需求。 範例金鑰僅供測試之用，並且用來簽署 JWT 記號。`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `啟用記號內部檢查時，可以透過內部檢查路徑來檢查存取記號。`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `當您啟用記號內部檢查時，存取記號的持有者可以透過使用內部檢查路徑來檢查記號的內容。 必須透過原生 OAuth 提供者來取得要進行內部檢查的存取記號。`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `邀請使用者成為提供者組織的成員時，您可以為他們指派一個以上角色，以控制其對於資訊的存取層次，以及可執行的作業。`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `管理您的產品版本時，您可以讓產品經歷一系列的生命週期狀態，從最初將產品版本暫置到型錄、到發佈以讓產品版本可供應用程式開發者使用，以及到最終淘汰與保存。`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `如果將一個產品替換為另一個產品，則會發生下列情況：1) 發佈替換產品，2) 在替換產品中使用原始產品的相同可見性、訂閱者，以及閘道強制執行設定，3) 原始產品的訂閱者移轉至替換產品。`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `當您將產品取代為另一個產品時，會採取下列動作：1) 取代產品會採用原始產品的狀態；2) 如果取代產品中的可見性和可訂閱性設定，使得存取權與原始產品相同或限制較少，則會使用取代產品中的設定。 如果取代產品中的設定更具限制性，且會阻止部分具有原始產品存取權的消費者組織存取取代，則無法完成取代作業。 3) 將原始產品的訂閱者移轉至替代產品，以及 4) 將原始產品汰換。`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `汰換產品時，所有關聯的 API 會離線，並且所有訂閱都會變成非作用中。\n\n [進一步瞭解]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `如果將一個產品接替為另一個產品，則會發生下列情況：1) 發佈接替產品，2) 在替換產品中使用原始產品的相同可見性、訂閱者，以及閘道強制執行設定，3) 原始產品移至「已淘汰」狀態。淘汰產品時，已訂閱該產品的應用程式開發者可以繼續使用它，但新開發者無法訂閱該產品。必要的話，可以重新發佈已淘汰的產品。`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `如果將一個產品接替為另一個產品，則會發生下列情況：1) 發佈接替產品，2) 在替換產品中使用原始產品的可見性和訂閱功能設定，以及 3) 原始產品移至「已淘汰」狀態。淘汰產品時，已訂閱該產品的應用程式開發者可以繼續使用它，但新開發者無法訂閱該產品。必要的話，可以重新發佈已淘汰的產品。`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `暫停應用程式將阻止相關聯的用戶端 ID 呼叫 API。您可以隨時回復該應用程式。`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `啟用時，所有消費者組織邀請逾時值都會設為型錄邀請逾時值，任何型錄邀請逾時值的更新都會更新消費者組織邀請逾時值。`,
  [`Where did the roles go?`]: `這些角色前往的位置`,
  [`Where to find the named rate limit.`]: `要在其中尋找指定速率限制的位置。`,
  [`Where to put the decrypted result`]: `放置解密結果的位置`,
  [`Where to put the encrypted result`]: `放置加密結果的位置`,
  [`Whether to reuse the connection to the target server.`]: `是否要重複使用目標伺服器的連線。`,
  [`Windows live`]: `Windows Live`,
  [`With`]: `使用`,
  [`Wildcards ignore xsi:type rule`]: `萬用字元會忽略 xsi:type 規則`,
  [`Work offline`]: `離線工作`,
  [`Work with existing definitions, models and/or data sources`]: `使用現有定義、模型及/或資料來源`,
  [`Working...`]: `工作中...`,
  [`Wrapper Policies`]: `封套原則`,
  [`Wrong slicing arguments`]: `錯誤的切割引數`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL 和 XSD 應該位於您嘗試匯入之 zip 檔內部其本身的 zip 檔中。`,
  [`XML Schema`]: `XML 綱目`,
  [`XML Schema URL`]: `XML 綱目 URL`,
  [`XML Schema preview`]: `XML 綱目預覽`,
  [`XML Validation Mode`]: `XML 驗證模式`,
  [`XML schema is not valid. Please correct the error.`]: `XML 綱目無效。請更正錯誤。`,
  [`XML to JSON`]: `XML 至 JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT 版本`,
  [`YAML has been successfully validated`]: `已順利驗證 YAML`,
  [`Yes`]: `是`,
  [`Yes, enable it`]: `是，啟用它`,
  [`Yes, reset`]: `是，重設`,
  [`Yes. Update API Assembly`]: `是。更新 API 組件`,
  [`You are authenticated!`]: `您已經過鑑別！`,
  [`You do not have permission to use the Test Application`]: `您沒有使用「測試應用程式」的許可權`,
  [`You can also copy the link and send it directly to the user.`]: `您也可以複製鏈結，並將它直接傳送給使用者。`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `您可以選擇此時不發佈 API，亦可立即發佈至測試及/或正式作業端點。您可以根據給定時間間隔的一些呼叫，選擇性地指定容許的 API 呼叫速率上限。`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `您可以選擇此時不發佈 API，亦可立即發佈至測試及/或正式作業端點。您可以根據每個指定時間間隔的大量呼叫，選擇性地指定容許的 API 呼叫速率上限。`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `您可以選擇讓 API 此時不受保護，亦可要求呼叫應用程式必須提供用戶端 ID。建立 API 定義之後，您可以配置進一步的安全選項。`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `您可以輸入型錄名稱或標題。如果兩者皆可用，則型錄名稱優先。`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `您可以將電子郵件傳送至消費者組織擁有者。這時會使用「通知」中配置的電子郵件伺服器來傳送訊息。`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `您可以將電子郵件傳送至消費者組織擁有者。這時會使用「通知」區段中配置的電子郵件伺服器來傳送訊息。`,
  [`You can send an email to a provider organization owner.`]: `您可以將電子郵件傳送至提供者組織擁有者。`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `您可以將所有權轉移給新使用者，方法是輸入其電子郵件位址。他們會收到電子郵件，其中包含進行登錄的啟動鏈結。一旦登錄，使用者將成為新擁有者。指派新角色給現行擁有者，讓他們可以在 Cloud Manager 中持續作業。`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `您可以將所有權轉移給已登錄的使用者，方法是輸入其電子郵件位址。指派新角色給現行擁有者，讓他們可以在 Cloud Manager 中持續作業。`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `您可以使用 OpenAPI 定義檔在 IBM API Connect 中新增 REST API。此檔案的格式可以是 JSON 或 YAML。匯入 API 定義後，它會顯示在 API 定義的清單中。`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `您無法刪除此類型，因為它將移除根類型（「查詢」）。`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `您不能選取其閘道類型與此產品的閘道類型不相符的 API。`,
  [`You currently don’t have any gateway extensions`]: `您目前沒有任何閘道延伸`,
  [`You currently don’t have any plans to migrate`]: `您目前沒有任何可移轉的方案`,
  [`You currently don't have any policy.`]: `您目前沒有任何原則。`,
  [`You do not have permission to any spaces.`]: `您不具有任何空間的權限。`,
  [`You do not have permission to publish products to any catalog or space.`]: `您無權將產品發佈到任何型錄或空間。`,
  [`You do not have permission to stage products to any catalog or space.`]: `您無權將產品暫置到任何型錄或空間。`,
  [`You don’t currently have any billing integrations.`]: `您目前沒有任何計費整合。`,
  [`You don't currently have any response.`]: `您目前沒有任何回應。`,
  [`You don't currently have any user-defined policies.`]: `您目前沒有任何使用者定義的原則。`,
  [`You don't have permission to access this page.`]: `您無權存取此頁面。`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `您已修改此憑證。儲存此應用程式將更新其配置。`,
  [`You haven't added any APIs or Products`]: `您尚未新增任何 API 或產品`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `您可以透過將使用者新增至型錄並為使用者指派角色來管理型錄成員資格。`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `您可以透過將使用者新增至空間並為使用者指派角色來管理空間成員資格。`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `您可以透過將使用者新增至空間並為使用者指派角色來管理空間成員資格。`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `必須將 API 新增至準備發佈的產品。產品會將一組 API 及方案收集成一個可供開發者使用的供應項目。方案包括可整體套用至方案的速率限制設定或為 API 中每項作業指定的速率限制設定。`,
  [`You must choose a definition`]: `必須選擇定義`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `您必須至少啟用一個閘道服務，讓發佈到此型錄的 API 可以在閘道服務端點進行呼叫。您可以啟用多個閘道服務。`,
  [`You must enable spaces`]: `您必須啟用空間`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `您應該記錄下面的用戶端 ID 和密碼以在開發應用程式時使用。無法再次顯示用戶端密碼。`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `您的「API Connect 保留實例」授權讓您有資格下載及設定一或多個遠端 DataPower API Gateway。閘道可以位於內部部署或任何雲端上。`,
  [`Your API has been created`]: `已建立您的 API`,
  [`Your API is live!`]: `您的 API 是活動的！`,
  [`Your API is online!`]: `您的 API 已連線！`,
  [`Your Catalog is being deleted. This may take a while...`]: `正在刪除您的「型錄」。這可能會花一點時間...`,
  [`Your Consumer organization is being created. This may take a while...`]: `正在建立您的「消費者組織」。這可能會花一點時間...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `您的邀請不再有效。 請檢查您的邀請逾時設定，然後重新傳送邀請。`,
  [`Your Provider organization is being created. This may take a while...`]: `正在建立您的「提供者組織」。這可能會花一點時間...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `正在刪除您的「提供者組織」。這可能會花一點時間...`,
  [`Your member is being deleted. This may take a while...`]: `正在刪除您的成員。 這可能會花一點時間...`,
  [`Your Space is being deleted. This may take a while...`]: `正在刪除您的「空間」。這可能會花一點時間...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `您的 OAuth 提供者具有未儲存的變更。按一下「確定」以繼續作業而不儲存。`,
  [`Your credentials have expired.`]: `您的憑證已過期。`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `已順利傳送您的邀請，其中包含下列邀請鏈結：`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `您的要求正在處理中，且花費的時間比預期長。請在數分鐘內重新整理，然後重試要求。`,
  [`Your product will be saved before proceeding, are you sure?`]: `將先儲存產品再繼續，確定嗎？`,
  [`Your trial ends on {date}`]: `您的試用結束於 {date}`,
  [`Your trial expired on {date}.`]: `您的試用已在 {date} 過期。`,
  [`Zoom in`]: `放大`,
  [`Zoom in and out`]: `放大及縮小`,
  [`Zoom out`]: `縮小`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `目標伺服器上缺少 CORS 支援（請參閱下面的建議）`,
  [`a LDAP provider`]: `LDAP 提供者`,
  [`activity`]: `活動`,
  [`add item...`]: `新增項目...`,
  [`add property...`]: `新增內容...`,
  [`added`]: `已新增`,
  [`admin`]: `管理者`,
  [`administrator`]: `管理者`,
  [`all`]: `全部`,
  [`an API Connect Local User Registry`]: `API Connect 本端使用者登錄`,
  [`an authentication URL`]: `鑑別 URL`,
  [`an untrusted certificate has been encountered`]: `發現不受信任的憑證`,
  [`and`]: `和`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `應用程式：`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `核准`,
  [`approval task for product`]: `下列產品的核准作業：`,
  [`archived`]: `已保存`,
  [`authenticated`]: `已鑑別`,
  [`authorization code time to live (seconds)`]: `授權碼存活時間（秒）`,
  [`Authorization header pass through`]: `授權標頭傳遞`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `封鎖`,
  [`body`]: `內文`,
  [`body-param`]: `body-param`,
  [`cancel`]: `取消`,
  [`case`]: `案例`,
  [`catalog`]: `型錄`,
  [`catalog:`]: `型錄：`,
  [`catch`]: `捕捉`,
  [`changed`]: `已變更`,
  [`cipher`]: `密碼`,
  [`code id_token`]: `code id_token`,
  [`code id_token token`]: `code id_token 記號`,
  [`code token`]: `程式碼記號`,
  [`Consume`]: `耗用`,
  [`consumer`]: `消費者`,
  [`consumes`]: `耗用`,
  [`composite`]: `複合式`,
  [`created`]: `建立`,
  [`Created API`]: `已建立 API`,
  [`custom string`]: `自訂字串`,
  [`day`]: `日`,
  [`day(s)`]: `天`,
  [`default`]: `預設`,
  [`default value`]: `預設值`,
  [`definition`]: `定義`,
  [`deprecated`]: `已淘汰`,
  [`description`]: `說明`,
  [`development`]: `開發`,
  [`edit condition`]: `編輯條件`,
  [`edited`]: `已編輯`,
  [`environment`]: `型錄`,
  [`error`]: `錯誤`,
  [`false`]: `false`,
  [`file`]: `檔案`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 圖示`,
  [`for`]: `for`,
  [`header`]: `標頭`,
  [`hear`]: `聽取`,
  [`hello`]: `您好`,
  [`helloName`]: `{name}，您好！`,
  [`here`]: `在這裡`,
  [`hit`]: `次命中`,
  [`hits`]: `次命中`,
  [`hour`]: `小時`,
  [`hour(s)`]: `小時`,
  [`hours`]: `小時`,
  [`identified`]: `已識別`,
  [`Increased`]: `已增加`,
  [`Increment`]: `增量`,
  [`info`]: `資訊`,
  [`information about external documentation`]: `外部文件的相關資訊`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `由 APIC 產生的 access_token/refresh_token 將遵循 APIC 設定，而不是遵循 OIDC 提供者的到期要求。`,
  [`introspect URL`]: `內部檢查 URL`,
  [`Issue`]: `問題`,
  [`Issues`]: `問題`,
  [`item`]: `項目`,
  [`iterate over`]: `反覆運算`,
  [`last modified`]: `前次修改時間`,
  [`Log Level`]: `記載層次`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `日誌`,
  [`Logout`]: `登出`,
  [`Logout redirect`]: `登出重新導向`,
  [`Logout redirect URL`]: `登出重新導向 URL`,
  [`location`]: `位置`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `分鐘`,
  [`minute(s)`]: `分鐘`,
  [`minutes`]: `分鐘`,
  [`mutation type`]: `突變類型`,
  [`mutual SSL authentication is required`]: `需要 SSL 交互鑑別`,
  [`name`]: `名稱`,
  [`Name|extension`]: `名稱`,
  [`Name|path parameters`]: `名稱`,
  [`Name|security definitions`]: `名稱`,
  [`non-blocking`]: `未封鎖`,
  [`none`]: `無`,
  [`none selected`]: `未選取任何項目`,
  [`Object`]: `物件`,
  [`off`]: `關閉`,
  [`of {total} pages`]: `（共 {total} 頁）`,
  [`of {total} page`]: `/ {total} 頁`,
  [`offline`]: `離線`,
  [`on`]: `開啟`,
  [`online`]: `線上`,
  [`or`]: `或`,
  [`or redact from all`]: `或全部編寫`,
  [`open and close list of options`]: `開啟及關閉選項清單`,
  [`organization saved`]: `已儲存組織`,
  [`organization-manager`]: `組織管理員`,
  [`otherwise`]: `否則`,
  [`payload`]: `有效負載`,
  [`Payload`]: `有效負載`,
  [`pending`]: `擱置`,
  [`produces`]: `產生`,
  [`production`]: `正式作業`,
  [`production and development`]: `正式作業和開發`,
  [`property`]: `內容`,
  [`provider`]: `提供者`,
  [`publish product`]: `發佈產品`,
  [`published`]: `已發佈`,
  [`query type`]: `查詢類型`,
  [`query-parameters`]: `查詢參數`,
  [`realized`]: `已實現`,
  [`recipients`]: `收件者`,
  [`removed`]: `已移除`,
  [`replaced`]: `已取代`,
  [`repeatable`]: `可重複`,
  [`Replenish`]: `補充`,
  [`request`]: `要求`,
  [`requested by`]: `要求者`,
  [`Resolver weight`]: `解析器加權`,
  [`response`]: `回應`,
  [`response-param`]: `response-param`,
  [`retired`]: `已汰換`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `已儲存`,
  [`scalar`]: `純量`,
  [`second`]: `秒`,
  [`second(s)`]: `秒`,
  [`seconds`]: `秒`,
  [`select owner user`]: `選取擁有者使用者`,
  [`select user`]: `選取使用者`,
  [`soap-body`]: `soap-body`,
  [`source`]: `來源`,
  [`space`]: `空間`,
  [`Space settings`]: `空間設定`,
  [`Skip`]: `跳過`,
  [`specified`]: `已指定`,
  [`staged`]: `已暫置`,
  [`start generating tests automatically.`]: `開始自動產生測試。`,
  [`subject`]: `主旨`,
  [`subscription type`]: `訂閱類型`,
  [`supersede`]: `接替`,
  [`superseded`]: `已接替`,
  [`target`]: `目標`,
  [`the server is unavailable`]: `伺服器無法使用`,
  [`this user registry type`]: `此使用者登錄類型`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `這是暫時的發佈頁面，當您執行重新發佈時會保留訂閱。`,
  [`to production`]: `至正式作業`,
  [`topology-administrator`]: `拓蹼管理者`,
  [`Trial days left`]: `剩餘試用天數`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `已變更 ttl`,
  [`type`]: `類型`,
  [`unarchived`]: `已取消保存`,
  [`union type`]: `聯集類型`,
  [`unlimited`]: `無限`,
  [`updated`]: `已更新`,
  [`Updated from new schema`]: `已由新綱目更新`,
  [`Upgrade`]: `升級`,
  [`v5 legacy behavior`]: `第 5 版舊式行為`,
  [`version 2`]: `第 2 版`,
  [`version 3`]: `第 3 版`,
  [`viewer`]: `檢視者`,
  [`value`]: `值`,
  [`warn`]: `警告`,
  [`warning`]: `警告`,
  [`warnings`]: `警告`,
  [`was not found. Please select a configured OAuth provider.`]: `找不到。請選取已配置的 OAuth 提供者。`,
  [`week`]: `週`,
  [`with`]: `使用`,
  [`with|supersede with`]: `使用`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `沙箱中的狀態`,
  [`your file here, or`]: `您的檔案到這裡，或者`,
  [`{count} found!`]: `找到 {count} 個！`,
  [`{current} of {total} page`]: `第 {current} 頁（共 {total} 頁）`,
  [`{current} of {total} pages`]: `{current}（共 {total} 頁）`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} 天 {hours} 小時{minutes} 分鐘`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} 天 {hours} 小時`,
  [`{docType} is required`]: `{docType} 是必要項`,
  [`{docType} title`]: `「{docType}」標題`,
  [`Product title`]: `專案標題`,
  [`Application title`]: `應用程式標題`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} 小時 {minutes} 分鐘`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `由於與 API 或產品中指定的閘道類型不相容而隱藏了 {incompatibleGatewayCount} 個閘道`,
  [`{min}-{max} of {total} items`]: `{min}-{max}（共 {total} 項）`,
  [`{min}-{max} of {total} item`]: `{min}-{max} 個項目（共 {total} 個）`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} 個原則（共 {total} 個）`,
  [`{min}-{max} of {total} Policy`]: `{min}-{max} 項原則（共 {total} 項）`,
  [`{min}-{max} of {total} types`]: `{min}-{max}（共 {total} 個類型）`,
  [`{min}-{max} of {total} type`]: `{min}-{max} 個類型（共 {total} 個）`,
  [`{min}-{max} of {total} search results`]: `{min}-{max}（共 {total} 筆搜尋結果）`,
  [`{min}-{max} of {total} search result`]: `{min}-{max} 個搜尋結果（共 {total} 個）`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} 個型錄（共 {total} 個）`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{max} 個型錄（共 {total} 個）`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} 個空間（共 {total} 個）`,
  [`{min}-{max} of {total} Space`]: `{min}-{max} 個空間（共 {total} 個）`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} 個延伸（共 {total} 個）`,
  [`{min}-{max} of {total} Extension`]: `{min}-{max} 個延伸（共 {total} 個）`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} 個產品方案（共 {total} 個）`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{max} 個產品計劃（共 {total} 個）`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} 個閘道（共 {total} 個）`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{max} 個閘道（共 {total} 個）`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (API) 遺漏 'x-ibm-configuration'`,
  [`{name} (Product) has been published!`]: `已發佈 {name}（產品）！`,
  [`{name} (Product) has been staged!`]: `已暫置 {name}（產品）！`,
  [`{name} (Product) has not been published!`]: `尚未發佈 {name}（產品）！`,
  [`{name} (Role) has been created`]: `已建立 {name}（角色）。`,
  [`{name} successfully deleted`]: `已順利刪除 {name}。`,
  [`{name} (Role) has been updated`]: `已更新 {name}（角色）。`,
  [`{name} has been assigned the owner`]: `已指派 {name} 為擁有者`,
  [`{number} Error`]: `{number} 個錯誤`,
  [`{number} Errors`]: `{number} 個錯誤`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{username} 所要求產品 {prodname}:{version} 的 {operation} 核准作業`,
  [`{path} does not exist`]: `{path} 不存在`,
  [`{productName} has no migration target set.`]: `{productName} 未設定任何移轉目標。`,
  [`{state} Pending`]: `「{state}」擱置中`,
  [`{status} API error`]: `{status} API 錯誤`,
  [`{status} Bad Request`]: `{status} 不正確的要求`,
  [`{status} Forbidden`]: `{status} 已禁止`,
  [`{status} Gateway Error`]: `{status} 閘道錯誤`,
  [`{status} Unauthorized`]: `{status} 未獲授權`,
  [`({time} after being sent)`]: `（傳送之後 {time}）`,
  [`({time} after being queued)`]: `（排入佇列之後 {time}）`,
  [`{title} (Product) has been {state}.`]: `{title}（產品）已 {state}.`,
  [`{title} has been deleted`]: `已刪除{title}`,
  [`{title} has been {changedName}.`]: `「{title}」{changedName}。`,
  [`{title} has not been deleted!`]: `{title} 尚未刪除！`,
  [`{title} has not been {changedName}!`]: `「{title}」尚未{changedName}！`,
  [`{title} {changedName}.`]: `{title} {changedName}。`,
  [`{type} has been {changedName}.`]: `「{type}」{changedName}。`,
  [`{type} has not been {changedName}!`]: `「{type}」尚未{changedName}！`,
  [`{type} history log`]: `{type} 歷程日誌`,
  [`{username} does not exist`]: `{username} 不存在`,
  [`{username} does not exist in the user registry`]: `{username} 不存在於使用者登錄中`,
  [`{username} is not associated with this consumer org`]: `{username} 與此消費者組織無關聯`,
  [`{username} has been created as a {type}.`]: `{username} 已建立為 {type}。`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `在 {consumerOrg} Developer Portal 中恢復了 {appName} 應用程式`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `在 {consumerOrg} Developer Portal 中暫停了 {appName} 應用程式`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} Developer Portal 帳戶登錄`,
  [`{{count}} errors need attention`]: `{count} 個錯誤需要注意`,
  // lts only translations
  [`Configure JSON settings`]: `配置 JSON 設定`,
  [`Configure XML settings`]: `配置 XML 設定`,
  [`Configure options`]: `配置選項`,
  [`Detect`]: `偵測`,
  [`Document type`]: `文件類型`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `文件大小上限`,
  [`Maximum name length`]: `名稱長度上限`,
  [`Maximum nesting depth`]: `巢狀深度上限`,
  [`Maximum number length`]: `數值長度上限`,
  [`Maximum number of unique names`]: `唯一名稱數目上限`,
  [`Maximum number of unique namespaces`]: `唯一名稱空間數目上限`,
  [`Maximum number of unique prefixes`]: `唯一字首數目上限`,
  [`Maximum value length`]: `值長度上限`,
  [`Maximum width`]: `寬度上限`,
  [`Maximum stack size`]: `堆疊大小上限`,
  [`Parse settings`]: `剖析設定`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `指定剖析動作可接受的文件大小上限（以位元組為單位）。`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `指定剖析動作接受的 XML 或 JSON 訊息中巢狀元素深度層次上限。`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `指定剖析動作接受的 XML、JSON 或 GraphQL 訊息中巢狀元素深度層次上限。`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `指定剖析動作可接受的名稱長度上限（以位元組為單位）。`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `如果值為數字，則指定數字長度上限會限制標籤值配對的值部分中的位元組數。`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `指定剖析動作可接受的唯一 XML 名稱空間 URI 數目上限。不論使用多少字首來宣告 XML 名稱空間，此限制計算所有 XML 名稱空間。`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `指定剖析動作可接受的文件中唯一 XML 名稱空間字首的數目上限。此限制會計算對相同名稱空間定義的多個字首，但不計算在輸入文件的不同部分中針對單一字首定義的多個名稱空間。`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `指定剖析動作可接受的唯一名稱數目上限。`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `指定剖析動作可接受的值長度上限（以位元組為單位）。`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `指定剖析動作可接受的內容寬度上限。`,
  [`Specifies the type of document to parse.`]: `指定要剖析的文件類型。`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `指定 XSLT 處理器版本。預設值為 XSLT10。`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `指定是否啟用嚴格 XSLT 錯誤檢查。非嚴格作業會嘗試從某些錯誤中回復，如使用未宣告的變數、呼叫未宣告的範本等。依預設，會啟用嚴格 XSLT 錯誤檢查。`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `指定是否啟用樣式表側寫。在正式作業環境中不應該使用此選項。依預設，會停用樣式表側寫。`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `指定是否要在除錯模式中執行樣式表、XQuery Script 及 JSONiq Script。 樣式表、XQuery Script 或 JSONiq Script 在除錯模式中執行時，會產生自訂網頁，而非顯示其正常輸出。 此網頁會確切地詳細說明在執行期間發生的情況，其中包括變數的值，以及特定的輸出片段來自何處。 在正式作業環境中不應該使用此選項。 依預設，會停用除錯模式。`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `指定是否必須在串流模式中執行樣式表。在完全剖析輸入之前，會開始轉換文件。並非所有樣式表皆可進行串流處理。如果無法對樣式表進行串流處理，則會產生錯誤，且不會處理輸入。依預設，會停用串流模式。`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `指定是否嘗試在串流模式中執行樣式表。在完全剖析輸入之前，會開始轉換文件。並非所有樣式表皆可進行串流處理。如果無法對樣式表進行串流處理，則在編譯期間會產生警告，且在執行時間會在整個輸入中正常讀取樣式表。依預設，會停用嘗試在串流模式中執行樣式表。`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `指定在處理期間是否跳出從樣式表產生的輸出。處理非英文字集時，最小跳出特別有用。依預設，會停用最小跳出。`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `指出在執行樣式表或其他已編譯內容時，容許堆疊使用的位元組數上限。此設定用來封鎖無限遞迴。下限值為 10 KB 或 10,240 個位元組。上限值為 100 MB 或 104,857,600 個位元組。預設值為 1 MB 或 1,048,576 個位元組。`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `指定要套用至 WSDL 檔的驗證行為，以檢查這些檔案是否符合 WS-I 基本設定檔第 5 節（1.0 版，2004 年 4 月）。預設值為 Warn。`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `指定 soap:Body 的驗證行為。預設值為 Strict。`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `指定 soap:Header 的驗證行為。預設值為 Lax。`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `指定錯誤詳細資料的驗證行為。預設值為 Strict。`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `指定是否需要與 RPC 樣式封套相容。依預設，不需要 RPC 樣式封套。`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `指定是否容許綱目接受 xsi:type='SOAP-ENC:Array' 且符合 SOAP 1.1 第 5 節要求的元素大部分使用，即使這些屬性違反 XML 綱目規格亦如此。一般而言，xsi:type 屬性必須指定等於或衍生自元素實際類型的類型。對於使用此選項編譯的綱目，如果元素類型衍生自 SOAP-ENC:Array，則會針對 SOAP 1.1 編碼 'Array' 複式類型，特別接受 xsi:type。與之相反的是正常容許的情況。依預設，不接受 xsi:type='SOAP-ENC:Array' 的元素。`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `指定是否要遵循 SOAP 1.1 第 5 節中的編碼規則來執行額外綱目驗證。如果已啟用，則會驗證 SOAP 陣列的成員，並容許 @id 及 @href 等屬性，即使綱目不容許這些屬性亦如此。同時還會檢查 @href 值，以確保它們具有對應的 @id 元素。依預設，不會執行額外驗證。`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `指定綱目中的 xs:any 元素是否僅依名稱驗證子元素。XML 綱目規格要求：如果萬用字元符合某個元素，但該元素沒有元素宣告，則會改為根據其中的 xsi:type 屬性來驗證該元素。此選項會忽略這些 xsi:type 屬性。它應該用於 SOAP 封套驗證等情況，在這些情況下，進一步的驗證步驟可能會使用 SOAP 1.1 編碼規則，來驗證符合萬用字元的內容。依預設，不會忽略 xsi:type 屬性。`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `指定是否嚴格遵循 WSDL 中的 SOAP 連結。如果已啟用，則只有連結至 SOAP 1.2 的訊息會出現在 SOAP 1.2 封套中，且只有連結至 SOAP 1.1 的訊息會出現在 SOAP 1.1 封套中。依預設，會停用嚴格 SOAP 連結。`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `指定是否要使用除錯資訊來編譯 XACML 原則。請注意，XACML 除錯訊息也是由 XACML 種類中的日誌事件控制。請使用除錯日誌層次來檢視完整 XACML 除錯訊息。依預設，不會使用除錯資訊來編譯 XACML 原則。`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `指定綱目或 WSDL 文件是否接受根據 MTOM/XOP 規格來最佳化 base64 編碼二進位內容的訊息。XOP 二進位最佳化會將 base64 編碼的二進位資料，取代為參照附件中未編碼二進位資料的 xop:Include 參照元素。依預設，會停用 MTOM/XOP 最佳化訊息。`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `消費者組織群組是消費者組織的集合，提供有效的方式來控制誰可以查看和訂閱您產品中的 API。透過使用消費者組織群組，您只需執行一次作業即可為該群組的組織中的所有開發者定義此存取權，而不必單獨為組織定義存取權。`,
  [`API Connect Home`]: `API Connect 首頁`,
  [`Add new group`]: `新增群組`,
  [`Add consumer organizations to the group as required`]: `視需要新增消費者組織至群組`,
  [`Add the following configuration`]: `新增下列配置`,
  [`Add the following configuration to`]: `新增下列配置至`,
  [`Additions`]: `新增項目`,
  [`Additional GraphQL capabilities`]: `其他 GraphQL 功能`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `此方案所併入的 graphQL API 的其他設定`,
  [`An organization or a group is required.`]: `需要組織或群組。`,
  [`An organization/group is required.`]: `需要組織/群組。`,
  [`Analysis configuration`]: `分析配置`,
  [`Application & Credentials`]: `應用程式與認證`,
  [`Are you sure you want to delete this API version?`]: `確定要刪除此 API 版本嗎？`,
  [`Are you sure you want to delete this API with all versions?`]: `確定要刪除此 API 及其所有版本嗎？`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `確定要將閘道延伸從閘道中移除嗎？`,
  [`Are you sure you want to rename all versions of this API?`]: `確定要重新命名此 API 的所有版本嗎？`,
  [`Are you sure you want to rename this API version?`]: `確定要重新命名此 API 版本嗎？`,
  [`Associate groups to the organization as required`]: `視需要將群組與組織相關聯`,
  [`At least one option must be enabled`]: `必須啟用至少一個選項`,
  [`Attribute mapping`]: `屬性對映`,
  [`Attempt streaming rule`]: `嘗試串流規則`,
  [`Auto generated default paths and operations`]: `自動產生的預設路徑和作業`,
  [`Base API endpoint`]: `基本 API 端點`,
  [`Consumer orgs`]: `消費者組織`,
  [`Calculate Query Characteristics`]: `計算查詢性質`,
  [`Calculate Response Characteristics`]: `計算回應性質`,
  [`Certificate or Shared Secret for Verify`]: `用於驗證的憑證或共用密碼`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `看看我們的操作實驗室，您可以在其中申請試用完整的 API 管理功能長達一週！`,
  [`Clone API`]: `複製 API`,
  [`Consumer organizations and groups`]: `消費者組織及群組`,
  [`Create new API version`]: `建立新的 API 版本`,
  [`Create new product version`]: `建立新的產品版本`,
  [`Create a GraphQL API based on a GraphQL service`]: `根據 GraphQL 服務建立 GraphQL API`,
  [`Custom rate limit`]: `自訂速率限制`,
  [`Delete API`]: `刪除 API`,
  [`Delete this version`]: `刪除此版本`,
  [`Deletion confirmation`]: `刪除確認`,
  [`Deletions`]: `刪除項目`,
  [`Display name`]: `顯示名稱`,
  [`Display Name`]: `顯示名稱`,
  [`Details`]: `詳細資料`,
  [`Edit GraphQL Rate Limit|title`]: `編輯 GraphQL 速率限制|標題`,
  [`Edit GraphQL rate limits`]: `編輯 GraphQL 速率限制`,
  [`Edit group`]: `編輯群組`,
  [`Edit condition`]: `編輯條件`,
  [`Edit rate limit|title`]: `編輯速率限制`,
  [`Edit rate limit|menu`]: `編輯速率限制`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `沒有將任何產品發佈至此型錄，或者此應用程式已訂閱所有產品中的所有方案。`,
  [`Enforce Policy`]: `強制實施原則`,
  [`Enter a group summary`]: `輸入群組摘要`,
  [`Enter a group title`]: `輸入群組標題`,
  [`Enter group details below`]: `在下面輸入群組詳細資料`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `輸入您想要代理的 GraphQL 服務的 URL 和綱目名稱`,
  [`Enum value`]: `列舉值`,
  [`Go to catalog settings to configure a new billing integration`]: `移至型錄設定以配置新的計費整合`,
  [`GraphQL introspect`]: `GraphQL 內部檢查`,
  [`GraphQL server`]: `GraphQL 伺服器`,
  [`GraphQL Setting`]: `GraphQL 設定`,
  [`GraphQL server URL`]: `GraphQL 伺服器 URL`,
  [`GraphQL server name`]: `GraphQL 伺服器名稱`,
  [`GraphQL type analysis configuration`]: `GraphQL 類型分析配置`,
  [`GraphQL resolver analysis configuration`]: `GraphQL 解析器分析配置`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `可以在下列清單中建立與管理群組。`,
  [`Groups will be listed here.`]: `群組將在這裡列出。`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `{firstName}，您好！\n\n您對 {catalog} 型錄中 API 產品 {productName}:{version} 的\n {action} 申請已得到核准。\n\n{#if comments}\n核准者併入了下列註解：\n\n{comments}\n\n{{/if}}\n您可以透過下列鏈結來檢查產品的狀態：\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `您好！\n\n此測試訊息來自已配置郵件伺服器\n{mailServer} 中的 IBM API Connect。\n\n如果您如預期的那樣收到此訊息，則測試成功！\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好！\n\n使用者 {originator} 已透過應用程式 {appName}\n撤銷其對 {catalog} 型錄中 {productName} API 產品版本 {productVersion} 的 {planName} 方案的\n訂閱申請。\n\n不需執行進一步動作。\n`,
  [`Invoke GraphQL server`]: `呼叫 GraphQL 伺服器`,
  [`Invoke the backend GraphQL server`]: `呼叫後端 GraphQL 伺服器`,
  [`JWKS endpoint`]: `JWKS 端點`,
  [`LDAP Field Name`]: `LDAP 欄位名稱`,
  [`Limit maximum nesting per query`]: `限制每個查詢的巢狀結構上限`,
  [`Limit resolve complexity rate on a per key basis`]: `以每一個金鑰為基礎限制解析複雜性比率`,
  [`Limit type complexity rate on a per key basis`]: `以每一個金鑰為基礎限制類型複雜性比率`,
  [`Manage APIs included in this product`]: `管理此產品所包含的 API`,
  [`Manage groups`]: `管理群組`,
  [`Maximum nesting`]: `巢狀結構上限`,
  [`Maximum resolve complexity`]: `解析複雜性上限`,
  [`Maximum type complexity`]: `類型複雜性上限`,
  [`Name of the group`]: `群組的名稱`,
  [`New API version`]: `新建 API 版本`,
  [`New GraphQL API`]: `新建 GraphQL API`,
  [`New product version`]: `新建產品版本`,
  [`New Version`]: `新建版本`,
  [`New version`]: `新建版本`,
  [`No billing integration exists`]: `不存在任何計費整合`,
  [`No CA bundles available`]: `沒有可用的 CA 組合`,
  [`No Data`]: `無資料`,
  [`No certificates available`]: `沒有可用的憑證`,
  [`No data`]: `無資料`,
  [`No group found`]: `找不到群組`,
  [`OAuth Auth URL`]: `OAuth 授權 URL`,
  [`OAuth Token URL`]: `OAuth 記號 URL`,
  [`Open Stripe dashboard`]: `開啟 Stripe 儀表板`,
  [`Operation/Path`]: `作業/路徑`,
  [`Operation Path`]: `作業路徑`,
  [`Operations and Paths`]: `作業和路徑`,
  [`Operator`]: `運算子`,
  [`Organizations and groups will be listed here.`]: `組織和群組將在這裡列出。`,
  [`Page not found`]: `找不到頁面`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `請將下方的程式碼複製到 CLI，以繼續處理。程式碼將在 **{ttl}** 到期。`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `請參照您的 Stripe 儀表板，以存取 API 金鑰和密碼`,
  [`Please upload a schema definition language (SDL) file.`]: `請上傳綱目定義語言 (SDL) 檔案。`,
  [`Please upload a valid GraphQL schema.`]: `請上傳有效的 GraphQL 綱目。`,
  [`Please upload a valid YAML file.`]: `請上傳有效的 YAML 檔案。`,
  [`Populate Server Configuration into Context Variables`]: `將伺服器配置資料移入環境定義變數`,
  [`POST/GET a query to be validated and sent to the backend server`]: `POST/GET 要驗證並傳送至後端伺服器的查詢`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `POST/GET 查詢以取得呼叫該查詢的預估成本`,
  [`Preparing`]: `正在準備`,
  [`Rate limit amount cannot be negative`]: `速率限制數量不能是負數`,
  [`Rate limit per cannot be negative`]: `速率限制的分母 (每 ...) 不能是負數`,
  [`Rename API`]: `重新命名 API`,
  [`Rename`]: `重新命名`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `申請核准 {action} {catalog} 型錄中的 API 產品`,
  [`Sandbox Sample Application`]: `沙箱範例應用程式`,
  [`Sandbox sample application credentials`]: `「沙箱」範例應用程式認證`,
  [`sample application credentials`]: `範例應用程式認證`,
  [`Saving`]: `儲存`,
  [`Search Orgs`]: `搜尋組織`,
  [`Search definitions...`]: `搜尋定義...`,
  [`Search errors...`]: `搜尋錯誤...`,
  [`Search operations...`]: `搜尋作業...`,
  [`Search organizations and groups`]: `搜尋組織和群組`,
  [`Sorry, This feature is not available in`]: `很抱歉，此功能無法使用`,
  [`Stopping`]: `停止中`,
  [`The user with username {username} already exists in the user registry.`]: `使用者名稱為 {username} 的使用者已存在於使用者登錄中。`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `沒有處於執行狀態的 API 變更。將此 API 重新發佈至沙箱型錄。`,
  [`There are no plans available for subscription.`]: `沒有可用於訂閱的方案。`,
  [`There are no attributes yet.`]: `尚不具有屬性。`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `輸入以新增消費者組織至群組`,
  [`Type to add groups to the organization`]: `輸入以新增群組至組織`,
  [`Type to add organizations from {catalog}`]: `輸入以從 {catalog} 新增組織`,
  [`Type to add organizations or groups`]: `輸入以新增組織或群組`,
  [`Type to add organizations or groups from {catalog}`]: `輸入以從 {catalog} 新增組織或群組`,
  [`Type to add {title} to groups`]: `輸入以新增 {title} 至群組`,
  [`Update Rate Limits`]: `更新速率限制`,
  [`Upload the YAML file with configuration`]: `上傳含有配置的 YAML 檔`,
  [`Use the sample below as a template to configure your GraphQL server`]: `使用下面的範例作為範本來配置您的 GraphQL 伺服器`,
  [`User managed`]: `使用者管理`,
  [`URL {url} does not contain valid OpenAPI.`]: `URL {url} 不包含有效的 OpenAPI。`,
  [`Validating`]: `正在驗證`,
  [`View analytics and performance metrics for your APIs`]: `檢視 API 的分析和效能指標`,
  [`View and add custom policies to the catalog`]: `檢視自訂原則並將其新增至型錄`,
  [`View and add custom exentions to the catalog`]: `檢視自訂延伸並將其新增至型錄`,
  [`View user registries and OAuth providers`]: `檢視使用者登錄和 OAuth 提供者`,
  [`View your application and manage API credentials`]: `檢視應用程式並管理 API 認證`,
  [`Welcome to the API Connect Trial`]: `歡迎使用 API Connect 試用版`,
  [`You haven't added any APIs`]: `您尚未新增任何 API`,
  [`You currently don’t have any {resource}.`]: `您目前沒有任何「{resource}」。`,
  [`plans to migrate`]: `計劃移轉`,
  [`gateway extensions`]: `閘道延伸`,
  [`application`]: `應用程式 (application)`,
  [`application authentication source`]: `應用程式鑑別來源`,
  [`API or product`]: `API 或產品`,
  [`base endpoints`]: `基本端點`,
  [`categories`]: `種類`,
  [`certificates`]: `憑證`,
  [`channels`]: `通道`,
  [`consumer organizations`]: `消費者組織`,
  [`consumer organization group`]: `消費者組織群組`,
  [`consumer organizations or groups`]: `消費者組織或群組`,
  [`correlation ids`]: `相關性 ID`,
  [`cors policy`]: `CORS 原則`,
  [`definitions`]: `定義`,
  [`examples`]: `範例`,
  [`gateway services`]: `閘道服務`,
  [`groups`]: `群組`,
  [`headers`]: `頁首`,
  [`links`]: `links`,
  [`member`]: `成員`,
  [`messages`]: `messages`,
  [`message traits`]: `訊息特質`,
  [`operation traits`]: `作業特質`,
  [`parameter definitions`]: `參數定義`,
  [`parameters`]: `參數`,
  [`path`]: `路徑`,
  [`properties`]: `內容`,
  [`provider organizations`]: `提供者組織`,
  [`request bodies`]: `要求內文`,
  [`response definitions`]: `回應定義`,
  [`responses`]: `回應`,
  [`role`]: `角色`,
  [`scope`]: `範圍`,
  [`scopes`]: `範圍`,
  [`schemas`]: `綱目`,
  [`schema definition`]: `綱目定義`,
  [`schemes list`]: `方法清單`,
  [`security definitions`]: `安全定義`,
  [`security schemes`]: `安全方法`,
  [`servers`]: `伺服器`,
  [`services`]: `服務`,
  [`tags`]: `標籤`,
  [`tags and external documentation`]: `標籤和外部說明文件`,
  [`target services`]: `目標服務`,
  [`tasks`]: `作業`,
  [`user registries`]: `使用者登錄`,
  [`HTTP header`]: `HTTP 標頭`,
  [`You currently don’t have any application authentication source.`]: `您目前沒有任何應用程式鑑別來源。`,
  [`You currently don’t have any API or product.`]: `您目前沒有任何 API 或產品。`,
  [`You currently don’t have any API user registries`]: `您目前沒有任何 API 使用者登錄`,
  [`You currently don’t have any APIs.`]: `您目前沒有任何 API。`,
  [`You currently don’t have any TLS client profiles`]: `您目前沒有任何 TLS 用戶端設定檔`,
  [`You currently don’t have any base endpoints.`]: `您目前沒有任何基本端點。`,
  [`You currently don’t have any catalog properties.`]: `您目前沒有任何型錄內容。`,
  [`You currently don’t have any categories.`]: `您目前沒有任何類別。`,
  [`You currently don’t have any certificates.`]: `您目前沒有任何憑證。`,
  [`You currently don’t have any channels.`]: `您目前沒有任何通道。`,
  [`You currently don’t have any consumer organizations.`]: `您目前沒有任何消費者組織。`,
  [`You currently don’t have any consumer organizations or groups.`]: `您目前沒有任何消費者組織或群組。`,
  [`You currently don’t have any consumes.`]: `您目前沒有任何耗用。`,
  [`You currently don’t have any correlation ids.`]: `您目前沒有任何相關性 ID。`,
  [`You currently don’t have any cors policy.`]: `您目前沒有任何相關性原則。`,
  [`You currently don’t have any definitions.`]: `您目前沒有任何定義。`,
  [`You currently don’t have any examples.`]: `您目前沒有任何範例。`,
  [`You currently don’t have any gateway services.`]: `您目前沒有任何閘道服務。`,
  [`You currently don’t have any groups.`]: `您目前沒有任何群組。`,
  [`You currently don’t have any headers.`]: `您目前沒有任何標頭。`,
  [`You currently don’t have any keystore.`]: `您目前沒有任何金鑰儲存庫。`,
  [`You currently don’t have any links.`]: `您目前沒有任何鏈結。`,
  [`You currently don’t have any messages.`]: `您目前沒有任何訊息。`,
  [`You currently don’t have any message traits.`]: `您目前沒有任何訊息特質。`,
  [`You currently don’t have any OAuth providers`]: `您目前沒有任何 OAuth 提供者`,
  [`You currently don’t have any operation traits.`]: `您目前沒有任何作業特質。`,
  [`You currently don’t have any parameter definitions.`]: `您目前沒有任何參數定義。`,
  [`You currently don’t have any parameters.`]: `您目前沒有任何參數。`,
  [`You currently don’t have any preserved request header.`]: `您目前沒有任何保留的要求標頭。`,
  [`You currently don’t have any preserved response header.`]: `您目前沒有任何保留的回應標頭。`,
  [`You currently don’t have any produces.`]: `您目前沒有任何產生內容。`,
  [`You currently don’t have any properties.`]: `您目前沒有任何內容。`,
  [`You currently don’t have any provider organizations.`]: `您目前沒有任何提供者組織。`,
  [`You currently don’t have any request bodies.`]: `您目前沒有任何要求內文。`,
  [`You currently don’t have any response definitions.`]: `您目前沒有任何回應定義。`,
  [`You currently don’t have any responses.`]: `您目前沒有任何回應。`,
  [`You currently don’t have any scopes.`]: `您目前沒有任何範圍。`,
  [`You currently don’t have any schemas.`]: `您目前沒有任何綱目。`,
  [`You currently don’t have any schemes list.`]: `您目前沒有任何架構清單。`,
  [`You currently don’t have any security definitions.`]: `您目前沒有任何安全定義。`,
  [`You currently don’t have any security schemes.`]: `您目前沒有任何安全架構。`,
  [`You currently don’t have any servers.`]: `您目前沒有任何伺服器。`,
  [`You currently don’t have any services.`]: `您目前沒有任何服務。`,
  [`You currently don’t have any tags.`]: `您目前沒有任何標籤。`,
  [`You currently don’t have any tags and external documentation.`]: `您目前沒有任何標籤和外部文件。`,
  [`You currently don’t have any target services.`]: `您目前沒有任何目標服務。`,
  [`You currently don’t have any tasks.`]: `您目前沒有任何作業。`,
  [`You currently don’t have any truststore.`]: `您目前沒有任何信任儲存庫。`,
  [`You currently don’t have any user registries.`]: `您目前沒有任何使用者登錄。`,
  [`You currently don’t have any extensions.`]: `您目前沒有任何延伸。`,
  [`You currently don’t have any HTTP header.`]: `您目前沒有任何 HTTP 標頭。`,
  [`Your account is being activated. This may take a while...`]: `您的帳戶正在啟動中。這可能會花一點時間...`,
  [`all of`]: `所有`,
  [`any of`]: `任何`,
  [`calls`]: `呼叫`,
  [`ciphers`]: `密碼`,
  [`extensions`]: `延伸`,
  [`one of`]: `下列的其中之一：`,
  [`shared`]: `已共用`,
  [`stopping`]: `停止中`,
  [`to:`]: `為：`,
  [`Key`]: `金鑰`,
  [`kind`]: `類型`,
  [`Trace`]: `追蹤`,
  [`Http Status`]: `HTTP 狀態`,
  [`Parsed`]: `已剖析`,
  [`Raw`]: `原始`,
  [`Showing Full Trace`]: `顯示完整追蹤`,
  [`Show Entire Trace`]: `顯示整個追蹤`,
  [`Show advanced`]: `顯示進階`,
  [`Advanced`]: `進階`,
  [`Sending Request`]: `傳送要求`,
  [`Please send a request above`]: `請在上方傳送要求`,
  [`Please send a request above.`]: `請在上方傳送要求。`,
  [`Authentication Type`]: `鑑別類型`,
  [`You don't currently have any response`]: `您目前沒有任何回應`,
  [`You don't currently have any trace.`]: `您目前沒有任何追蹤。`,
  [`No response received`]: `未收到回應`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `按一下「開啟鏈結以接受異常狀況」會在新標籤中開啟伺服器。如果瀏覽器顯示憑證問題，您可以選擇接受它，然後回到這裡以重新測試。`,
  [`Open link to accept exception`]: `開啟鏈結以接受異常狀況`,
  [`Back to {name}`]: `回到 {name}`,
  [`0 seconds`]: `0 秒`,
  [`close.menu`]: `關閉功能表`,
  [`open.menu`]: `開啟功能表`,
  [`Referenced from "{ref}"`]: `參照來源 "{ref}"`,
  [`Go to {definition}`]: `跳至 {definition}`,
  [`General`]: `一般`,
  [`Servers`]: `伺服器`,
  [`External Docs`]: `外部文件`,
  [`Components`]: `元件`,
  [`Schemas`]: `綱目`,
  [`Responses`]: `回應`,
  [`Examples`]: `範例`,
  [`Request Bodies`]: `要求內文`,
  [`Security Schemes`]: `安全保護方法`,
  [`Links`]: `鏈結`,
  [`Callbacks`]: `回呼`,
  [`Terms of Service`]: `服務條款`,
  [`Contact Name`]: `聯絡人名稱`,
  [`Contact URL`]: `聯絡人 URL`,
  [`Contact Email`]: `聯絡人電子郵件`,
  [`Contact sales`]: `聯絡銷售人員`,
  [`License Name`]: `授權名稱`,
  [`License URL`]: `授權 URL`,
  [`Content-Type`]: `內容類型`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `接受 MTOM/XOP 最佳化訊息`,
  [`User-Agent`]: `使用者 ─ 代理程式`,
  [`token`]: `記號`,
  [`Tag`]: `標記`,
  [`Request Body`]: `要求內文`,
  [`Security Requirements`]: `安全需求`,
  [`Located In`]: `位於`,
  [`Example Value`]: `範例值`,
  [`External Value`]: `外部值`,
  [`Server`]: `伺服器`,
  [`Server Description`]: `伺服器說明`,
  [`Server Variables`]: `伺服器變數`,
  [`Format`]: `格式`,
  [`Pattern`]: `型樣`,
  [`Multiple Of`]: `多個`,
  [`Multiple non null slicing arguments`]: `多個非空值切割引數`,
  [`Missing slicing arguments`]: `遺漏切割引數`,
  [`Missing sized fields`]: `遺漏大小欄位`,
  [`Maximum`]: `上限`,
  [`Exclusive Maximum`]: `上限（不含）`,
  [`Minimum`]: `下限`,
  [`Exclusive Minimum`]: `下限（不含）`,
  [`Max Length`]: `長度上限`,
  [`Min Length`]: `長度下限`,
  [`Max Items`]: `項目數上限`,
  [`Min Items`]: `最小項目數`,
  [`Unique Items`]: `唯一項目數`,
  [`Max Properties`]: `最大內容數`,
  [`Min Properties`]: `最小內容數`,
  [`Required Properties`]: `必要內容數`,
  [`Required Property Name`]: `必要的內容名稱`,
  [`Enum`]: `列舉`,
  [`Enum Value`]: `列舉值`,
  [`Nullable`]: `可為空值`,
  [`Read Only`]: `唯讀`,
  [`Write Only`]: `唯寫`,
  [`api`]: `API`,
  [`version`]: `版本`,
  [`username`]: `使用者名稱`,
  [`password`]: `密碼`,
  [`service`]: `服務`,
  [`Allows Empty Value`]: `容許空值`,
  [`Style`]: `樣式`,
  [`Explode`]: `分解`,
  [`Allow Reserved`]: `容許保留`,
  [`Allow WebSocket Upgrade`]: `容許 WebSocket 升級`,
  [`Ref`]: `參照`,
  [`Operation ID`]: `作業 ID`,
  [`Parameter`]: `參數`,
  [`Gateway and portal settings`]: `閘道和入口網站設定`,
  [`Targets`]: `目標`,
  [`Catalog Properties`]: `型錄內容`,
  [`catalog properties`]: `型錄內容`,
  [`Attachments`]: `附件`,
  [`Activity Log`]: `活動日誌`,
  [`API Type`]: `API 類型`,
  [`Phase`]: `階段`,
  [`Enable CORS`]: `啟用 CORS`,
  [`CORS Policy`]: `CORS 原則`,
  [`Allowed Origins`]: `容許的原始伺服器`,
  [`Allow Credentials`]: `容許認證`,
  [`ID`]: `ID`,
  [`Success Content`]: `成功內容`,
  [`Error Content`]: `錯誤內容`,
  [`Attachment`]: `附件`,
  [`Link`]: `鏈結`,
  [`Operation Ref`]: `作業參照`,
  [`TRACE`]: `追蹤`,
  [`No Security Requirements available`]: `沒有可用的安全需求`,
  [`Create a security scheme`]: `建立安全保護方法`,
  [`Create a Security Requirement`]: `建立安全需求`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `需要下列其中一項安全需求。若不勾選，則安全是選用的。`,
  [`Require wrappers on fault details specified by type`]: `需要依類型指定錯誤詳細資料中的封套`,
  [`There are no "Servers"`]: `沒有任何「伺服器」`,
  [`Write`]: `寫入`,
  [`Server Variable`]: `伺服器變數`,
  [`Security Requirement`]: `安全需求`,
  [`Security Scheme Type`]: `安全保護方法類型`,
  [`Security Scheme`]: `安全保護方法`,
  [`OAuth Flows`]: `OAuth 流程`,
  [`Implicit Flow`]: `隱含流程`,
  [`Password Flow`]: `密碼流程`,
  [`Client Credentials Flow`]: `用戶端認證流程`,
  [`Authorization Code Flow`]: `授權碼流程`,
  [`Refresh URL`]: `重新整理 URL`,
  [`schema`]: `綱目`,
  [`Allow Empty Value`]: `容許空值`,
  [`Encoding`]: `編碼`,
  [`This field is required`]: `此欄位是必要的`,
  [`Temporary token`]: `暫時記號`,
  [`Access token`]: `存取記號`,
  [`ID token`]: `ID 記號`,
  [`No Security Schemes available`]: `沒有可用的安全保護方法`,
  [`Security Scheme (Key)`]: `安全保護方法（索引鍵）`,
  [`There are no "{label}"`]: `沒有任何 "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `您必須至少選取一種方法以定義安全需求`,
  [`Select one or more Security Schemes`]: `選取一種以上的安全保護方法`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `"{key}" 安全保護方法沒有可用的 OAuth 範圍`,
  [`Create a Scope`]: `建立範圍`,
  [`Remove this Security Requirement`]: `移除此安全需求`,
  [`Edit this Security Requirement`]: `編輯此安全需求`,
  [`Invalid Security Scheme Reference`]: `安全保護方法參照無效`,
  [`The following Security Schemes do not exist!`]: `下列安全保護方法不存在！`,
  [`Edit source`]: `編輯程式碼`,
  [`OR`]: `或`,
  [`Required Security Schemes`]: `必要的安全保護方法`,
  [`Optional Security`]: `選用的安全保護`,
  [`Optional custom expression (one per line)`]: `選用的自訂表示式（每行一個）`,
  [`Flow Type`]: `流程類型`,
  [`OAuth2 Security Scheme`]: `OAuth2 安全保護方法`,
  [`Only source editing is supported.`]: `僅支援程式碼編輯。`,
  [`Unsupported Field.`]: `不支援的欄位。`,
  [`Select an option`]: `選取選項`,
  [`Select {labelText}`]: `選取 {labelText}`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" 已被使用'`,
  [`Referenced from "{originalRef}"`]: `參照來源 "{originalRef}"`,
  [`Go to {label}`]: `移至 {label}`,
  [`Delete "{label}"`]: `刪除 "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `您確定要刪除 "{label}" 嗎？`,
  [`No recent items found`]: `找不到最近的項目`,
  [`Found {numDocs} results ({took} seconds)`]: `找到 {numDocs} 個結果（{took} 秒）`,
  [`No Consumer Organizations found`]: `找不到消費者組織`,
  [`No Catalog Products found`]: `找不到型錄產品`,
  [`No APIs found`]: `找不到 API`,
  [`No Catalogs found`]: `找不到型錄`,
  [`No Spaces found`]: `找不到空間`,
  [`No results found`]: `找不到結果`,
  [`No Catalog APIs found`]: `找不到型錄 API`,
  [`No Catalogs found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的型錄`,
  [`No APIs found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的 API`,
  [`No Subscriptions found`]: `找不到訂閱`,
  [`No Plans found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的方案`,
  [`No Subscriptions found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的訂閱`,
  [`No Catalog Products found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的型錄產品`,
  [`No Catalog APIs found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的型錄 API`,
  [`No Consumer Organizations found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的消費者組織`,
  [`No Applications found matching query "{query}"`]: `找不到與查詢 "{query}" 相符的應用程式`,
  [`APIs per page`]: `每頁 API 數`,
  [`Catalogs per page`]: `每頁型錄數`,
  [`items per page`]: `每頁項目數`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} 個 API（共 {total} 個）`,
  [`{min}-{max} of {total} API`]: `{min}-{max} 個 API（共 {total} 個）`,
  [`Products per page`]: `每頁產品數`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `產品應該發佈在型錄上，才能配置新的計費整合`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} 個產品（共 {total} 個）`,
  [`{min}-{max} of {total} Product`]: `{min}-{max} 個產品（共 {total} 個）`,
  [`Catalog Products per page`]: `每頁型錄產品數`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} 個型錄產品（共 {total} 個）`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{max} 個型錄產品（共 {total} 個）`,
  [`Plans per page`]: `每頁方案數`,
  [`Policies per page`]: `每頁原則數`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} 個方案（共 {total} 個）`,
  [`{min}-{max} of {total} Plan`]: `{min}-{max} 個計劃（共 {total} 個）`,
  [`Catalog APIs per page`]: `每頁型錄 API 數`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} 個型錄 API（共 {total} 個）`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{max} 個型錄 API（共 {total} 個）`,
  [`Subscriptions per page`]: `每頁訂閱數`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} 個訂閱（共 {total} 個）`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{max} 次訂閱（共 {total} 次）`,
  [`Consumer Organizations per page`]: `每頁消費者組織數`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} 個消費者組織（共 {total} 個）`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{max} 個消費者組織（共 {total} 個）`,
  [`Applications per page`]: `每頁應用程式數`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} 個應用程式（共 {total} 個）`,
  [`{min}-{max} of {total} Application`]: `{min}-{max} 個應用程式（共 {total} 個）`,
  [`Histories per page`]: `每頁歷程數`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} 個歷程（共 {total} 個）`,
  [`{min}-{max} of {total} History`]: `{min}-{max} 個歷程（共 {total} 個）`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `找不到可訂閱的方案。產品必須處於「已發佈」狀態，才能建立訂閱。一個應用程式只能訂閱一個產品中的一個方案。`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `顯示已發佈的 "{draftApiTitle}:{draftApiVersion}" API`,
  [`Showing Consumer Organization for "{appTitle}"`]: `顯示 "{appTitle}" 的消費者組織`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `顯示 "{productTitle}:{productVersion}" 的訂閱`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `顯示 "{productTitle}:{productVersion}" 的方案`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `顯示 "{productTitle}:{productVersion}" 的 API`,
  [`Showing Product for "{planTitle}"`]: `顯示 "{planTitle}" 的產品`,
  [`Showing APIs for "{planTitle}"`]: `顯示 "{planTitle}" 的 API`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `顯示產品 "{docFoundIn}" 中方案 "{docTitle}" 的訂閱`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `顯示 "{apiTitle}:{apiVersion}" 的方案`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `顯示 "{catalogApiTitle}:{catalogApiVersion}" 的草稿 API`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `顯示 "{productTitle}:{productVersion}" 的歷程`,
  [`Showing Subscriptions for "{appTitle}"`]: `顯示 "{appTitle}" 的訂閱`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `顯示 "{consumerOrgTitle}" 的應用程式`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `顯示 "{consumerOrgTitle}" 的訂閱`,
  [`Showing Application for selected Subscription`]: `顯示所選訂閱的應用程式`,
  [`Showing Plan for selected Subscription`]: `顯示所選訂閱的方案`,
  [`Showing Product for selected Subscription`]: `顯示所選訂閱的產品`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `選取要併入到 "{productTitle}" 產品中的草稿 API`,
  [`Product Version`]: `產品版本`,
  [`OAuth Providers`]: `OAuth 提供者`,
  [`Application Type`]: `應用程式類型`,
  [`Client IDs`]: `用戶端 ID`,
  [`Save as New Version`]: `儲存為新版本`,
  [`Update Selected APIs`]: `更新選取的 API`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。{titleAndVersion}API 將永久地刪除。您要繼續作業嗎？`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。{titleAndVersion}產品將永久地刪除。您要繼續作業嗎？`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。{titleAndVersion}型錄產品將永久地刪除。您要繼續作業嗎？`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。{titleAndVersion}消費者組織將永久地刪除。您要繼續作業嗎？`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。 將永久刪除「{titleAndVersion}消費者群組」。 要繼續嗎？`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。{titleAndVersion}原則將永久地刪除。您要繼續作業嗎？`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。{titleAndVersion}訂閱將永久地刪除。您要繼續作業嗎？`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。 將永久刪除「{titleAndVersion} 型錄」。 要繼續嗎？`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。 將永久刪除「{titleAndVersion} 空間」。 要繼續嗎？`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `{titleAndVersion}型錄產品將重新發佈。您要繼續作業嗎？`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `{titleAndVersion}型錄產品將汰換。您要繼續作業嗎？`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `{titleAndVersion}型錄產品將淘汰。您要繼續作業嗎？`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `{titleAndVersion}型錄產品將重新暫置。您要繼續作業嗎？`,
  [`Put Online`]: `上線`,
  [`Take Offline`]: `離線`,
  [`View Subscriptions`]: `檢視訂閱`,
  [`View Plans`]: `檢視方案`,
  [`No Tasks found`]: `找不到作業`,
  [`Tasks per page`]: `每頁作業數`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} 個作業（共 {total} 個）`,
  [`{min}-{max} of {total} Task`]: `{min}-{max} 個作業（共 {total} 個）`,
  [`Decline and Send`]: `拒絕並傳送`,
  [`View Applications`]: `檢視應用程式`,
  [`Publish (Preserve Subscriptions)`]: `發佈（保留訂閱）`,
  [`Update APIs`]: `更新 API`,
  [`View in Catalogs`]: `在型錄中檢視`,
  [`View Consumer Organization`]: `檢視消費者組織`,
  [`View Application`]: `檢視應用程式`,
  [`View Plan`]: `檢視方案`,
  [`View Product`]: `檢視產品`,
  [`Create Subscription`]: `建立訂閱`,
  [`No Applications found`]: `找不到應用程式`,
  [`Select a Plan to Create a Subscription to`]: `選取要建立訂閱的方案`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `這個動作無法回復。{titleAndVersion}應用程式將永久地刪除。您要繼續作業嗎？`,
  [`Save as new version`]: `另存為新版本`,
  [`Filter selected catalogs`]: `過濾選取的型錄`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `選取要併入 "{productTitle}" 產品中的草稿 API`,
  [`Update selected APIs`]: `更新選取的 API`,
  [`Catalog API`]: `型錄 API`,
  [`Catalog APIs`]: `型錄 API`,
  [`View Products`]: `檢視產品`,
  [`View Draft APIs`]: `檢視草稿 API`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `正在顯示產品參照 "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `找不到方案`,
  [`View APIs`]: `檢視 API`,
  [`Document type tabs`]: `文件類型標籤`,
  [`No Products found`]: `找不到產品`,
  [`Consumer Organization`]: `消費者組織`,
  [`Consumer Organizations`]: `消費者組織`,
  [`Catalog Product`]: `型錄產品`,
  [`Catalog Products`]: `型錄產品`,
  [`Search for a Product and Plan`]: `搜尋產品和方案`,
  [`OpenAPI Version`]: `OpenAPI 版本`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API（來自 REST、GraphQL 或 SOAP）`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI（來自 Kafka 主題）`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `建立 AsyncAPI（來自 Kafka 主題）`,
  [`An error occurred during file upload`]: `檔案上傳期間發生錯誤`,
  [`Delete uploaded file`]: `刪除已上傳的檔案`,
  [`Copied!`]: `已複製！`,
  [`Uploading`]: `上傳中`,
  [`Validation icon`]: `驗證圖示`,
  [`Validation error`]: `驗證錯誤`,
  [`Uploading image..`]: `正在上傳影像..`,
  [`Unsupported media type {providedFileType}`]: `不支援媒體類型 {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `選取支援的檔案 {expectedFileTypes}，然後再試一次`,
  [`Pasted`]: `已貼上`,
  [`Enter the API summary details.`]: `輸入 API 摘要詳細資料。`,
  [`Define your Kafka cluster connection details.`]: `定義 Kafka 叢集連線詳細資料。`,
  [`Channel`]: `通道`,
  [`Describe your Kafka topic and its associated schema.`]: `說明 Kafka 主題及其關聯綱目。`,
  [`AsyncAPI specification version`]: `AsyncAPI 規格版本`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `用於說明 API並協助開發者在開發者入口網站中探索 API 的名稱。`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `API 定義的版本。名稱和版本的組合可以唯一地識別 API 定義。`,
  [`Description (optional)`]: `說明（選用）`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `介紹 API 並提供有用資訊給使用 API 的開發者。`,
  [`Summary (optional)`]: `摘要（選用）`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `此 API 中所含事件的簡要。最多 1000 個字元。`,
  [`Bootstrap servers`]: `引導伺服器`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `叢集中的 Kafka 分配管理系統清單（以逗點區隔）。`,
  [`Secure the API using an API Key and Secret`]: `使用 API 金鑰和密碼保護 API`,
  [`The credential provided should be in SASL-PLAIN format`]: `提供的認證應該是 SASL-PLAIN 格式`,
  [`Publish this API:`]: `發佈此 API：`,
  [`Creates a product`]: `建立產品`,
  [`Associates this API to the product`]: `將此 API 與產品相關聯`,
  [`Publish the product to the Sandbox catalog`]: `將產品發佈至沙箱型錄`,
  [`Associates the product to the test app`]: `將產品與測試應用程式相關聯`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `已順利上傳並驗證 "{fileType}" 格式的綱目。`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `上傳的檔案不是有效的 Avro 綱目，不會作為 API 的一部分包含在內。`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `伺服器回應："{err}"。按一下「上一步」以回到 API 定義頁面。`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `伺服器回應："{err}"。按一下「完成」以結束精靈。`,
  [`Topic name`]: `主題名稱`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `您要公開為此 API 一部分的主題名稱。主題是訊息的具名串流。`,
  [`Schema for message body in .avsc format (optional)`]: `訊息內文的綱目，採用 .avsc 格式（選用）`,
  [`Drag and drop files here, or click to upload`]: `將檔案拖放到這裡或按一下以上傳`,
  [`About this step`]: `關於此步驟`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `可以將事件描述為非同步 API，因為消費者可在事件變成可用時立即接收事件。相反，同步 API（如 REST）僅在要求時才接收回應。`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `事件驅動型非同步 API 是使用 AsyncAPI 規格來記載的。`,
  [`API secret`]: `API 密碼`,
  [`Generated AsyncAPI 2.1.0 definition`]: `已產生 AsyncAPI 2.1.0 定義`,
  [`API creating`]: `正在建立 API`,
  [`An error occurred while creating your document`]: `建立文件時發生錯誤`,
  [`Publishing your API`]: `正在發佈您的 API`,
  [`Your API has been published`]: `已發佈您的 API`,
  [`An error occurred while publishing your API`]: `發佈您的 API 時發生錯誤`,
  [`Configure security for this API`]: `配置此 API 的安全`,
  [`Cluster connection security`]: `叢集連線安全`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `提供事件閘道服務在處理提交至此 API 的要求時，將用於連接至叢集的認證。 您稍後可以在編輯器中變更這些值。 如需相關資訊，請參閱建立認證的說明文件。`,
  [`SASL Username`]: `SASL 使用者名稱`,
  [`SASL Password`]: `SASL 密碼`,
  [`Transport CA certificate (.pem file - optional)`]: `傳輸 CA 憑證（.pem 檔 - 選用）`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `如果已提供，事件閘道服務將使用其為閘道與 Kafka 叢集之間的通訊提供 TLS 加密。`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `說明 Apache Kafka 事件來源的 API 詳細資料，以產生 AsyncAPI 文件。`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `然後，您可以發佈 AsyncAPI 文件以將事件來源公開給應用程式開發者，應用程式開發者可以配置其應用程式以訂閱事件的相關串流。`,
  [`Set up security for your API if you want to control who can access it.`]: `如果您想要控制可以存取 API 的使用者，請設定 API 的安全。`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `API 的存取權將由檢查有效 API 金鑰和密碼的事件閘道服務管理。`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `閘道需要用戶端使用 TLS 來加密其通訊。`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `建立 API 之後，按一下「編輯 API」以新增更多詳細資料，如用於從事件閘道服務連接至 Kafka 叢集的安全詳細資料。`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `然後，您可以發佈 API 以使其可供開發人員使用，開發人員隨後可以探索和要求對 API 的存取，並使用這些資訊將其應用程式設定為從事件來源取用 API。`,
  [`Pending backend response`]: `後端回應擱置中`,
  [`Complete`]: `完成`,
  [`Rate limiting is only applied to enforced APIs`]: `速率限制僅套用至已施行的 API`,
  [`No Plans Available`]: `沒有可用的方案`,
  [`Modal content is not provided`]: `未提供限制模式內容`,
  [`Modal heading is not provided`]: `未提供限制模式標題`,
  [`Edit Rate Limit`]: `編輯速率限制`,
  [`External Documentation`]: `外部說明文件`,
  [`No Label Available`]: `沒有可用的標籤`,
  [`optional`]: `選用`,
  [`Uploading image...`]: `正在上傳影像...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `透過拖曳、放置、選取或貼上檔案來附加檔案。`,
  [`Response name`]: `回應名稱`,
  [`Click Add to add a new {resource}.`]: `請按一下「新增」以新增「{resource}」。`,
  [`Schema Name (Key)`]: `綱目名稱（索引鍵）`,
  [`Xml`]: `XML`,
  [`Namespace`]: `名稱空間`,
  [`Attribute`]: `屬性`,
  [`Wrapped`]: `封套`,
  [`Example`]: `範例`,
  [`Example Name (Key)`]: `範例名稱（索引鍵）`,
  [`"{changedValue}" is already used`]: `"{changedValue}" 已被使用`,
  [`Name (Key)`]: `名稱（索引鍵）`,
  [`Content Type (Key)`]: `內容類型（索引鍵）`,
  [`Header name`]: `標頭名稱`,
  [`Reference`]: `參照`,
  [`Security Scheme Name (Key)`]: `安全保護方法名稱（索引鍵）`,
  [`Choose a Reference`]: `選擇參照`,
  [`Reference from`]: `參照來源`,
  [`Content Type`]: `內容類型`,
  [`array`]: `陣列`,
  [`boolean`]: `布林`,
  [`integer`]: `整數`,
  [`number`]: `數字`,
  [`object`]: `物件`,
  [`string`]: `字串`,
  [`query`]: `查詢`,
  [`cookie`]: `cookie`,
  [`matrix`]: `矩陣`,
  [`label`]: `標籤`,
  [`simple`]: `簡式`,
  [`form`]: `表單`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth 提供者（名稱）`,
  [`Authenticate using User Registry (name)`]: `利用使用者登錄（名稱）進行鑑別`,
  [`Bearer Format`]: `持有人格式`,
  [`Server Variable Name`]: `伺服器變數名稱`,
  [`Verb (Key)`]: `動詞（索引鍵）`,
  [`Parameter Definitions`]: `參數定義`,
  [`Path Parameters`]: `路徑參數`,
  [`Media Type List`]: `媒體類型清單`,
  [`Medium`]: `中`,
  [`Schemes List`]: `方法清單`,
  [`Small`]: `小`,
  [`Response Definitions`]: `回應定義`,
  [`Terms Of Service`]: `服務條款`,
  [`Url`]: `URL`,
  [`Untitled Parameter`]: `未命名參數`,
  [`Parameter Name`]: `參數名稱`,
  [`Mime Type`]: `MIME 類型`,
  [`Async API`]: `非同步 API`,
  [`Channels`]: `通道`,
  [`Operation Traits`]: `作業特質`,
  [`Messages`]: `訊息`,
  [`Correlation IDs`]: `相關性 ID`,
  [`Traits`]: `特質`,
  [`Message Traits`]: `訊息特質`,
  [`Bindings`]: `連結`,
  [`Server Bindings`]: `伺服器連結`,
  [`Channel Bindings`]: `通道連結`,
  [`Operation Bindings`]: `作業連結`,
  [`Message Bindings`]: `訊息連結`,
  [`Protocol Version`]: `通訊協定版本`,
  [`Define multiple messages`]: `定義多則訊息`,
  [`Schema Format`]: `綱目格式`,
  [`Unsupported Field`]: `不受支援的欄位`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `作業特質`,
  [`Message Trait`]: `訊息特質`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `使用「作業切換」原則，將組件的區段套用至特定的作業。`,
  [`HTML page`]: `HTML 頁面`,
  [`Rule`]: `規則`,
  [`Origin`]: `來源`,
  [`Property Name (Key)`]: `內容名稱（索引鍵）`,
  [`Catalog Name (Key)`]: `型錄名稱（索引鍵）`,
  [`Property Overrides`]: `內容置換`,
  [`Property Name`]: `內容名稱`,
  [`Property Value`]: `內容值`,
  [`About subscriptions and plans`]: `關於訂閱和方案`,
  [`Subscription Plan Name`]: `訂閱方案名稱`,
  [`Select plan`]: `選取方案`,
  [`Select the plan to migrate application subscriptions from`]: `選取方案以從中移轉應用程式訂閱`,
  [`Select subscriptions`]: `選取訂閱`,
  [`Select the application subscriptions to migrate`]: `選取要移轉的應用程式訂閱`,
  [`Select the product to migrate application subscriptions to`]: `選取產品以向其中移轉應用程式訂閱`,
  [`Select the plan to migrate application subscriptions to`]: `選取方案以向其中移轉應用程式訂閱`,
  [`Message successfully sent to {sent} owner(s)`]: `訊息已順利傳送至 {sent} 個擁有者`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} 則訊息傳送失敗`,
  [`Failed email addresses`]: `失敗電子郵件位址`,
  [`Failed to save the API`]: `無法儲存 API`,
  [`and {numExtra} more...`]: `及另 {numExtra} 個...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `將傳送失敗的電子郵件位址清單複製到剪貼簿`,
  [`From`]: `寄件者`,
  [`Message Owner`]: `訊息擁有者`,
  [`Message owner`]: `訊息擁有者`,
  [`Message owners`]: `訊息擁有者`,
  [`Message group`]: `訊息群組`,
  [`Select consumer organizations`]: `選取消費者組織`,
  [`Message consumer organization owners`]: `訊息消費者組織擁有者`,
  [`Message consumer organization owner`]: `訊息消費者組織擁有者`,
  [`Message provider organization owner`]: `訊息提供者組織擁有者`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `與此「呼叫」原則相關的 Kafka 叢集 ID`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `以逗點區隔的 host:port 配對清單，用來建立與 Kafka 叢集的連線`,
  [`The username to use when connecting to the Kafka cluster.`]: `連接至 Kafka 叢集時要使用的使用者名稱。`,
  [`The password to use when connecting to the Kafka cluster.`]: `連接至 Kafka 叢集時要使用的密碼。`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `事件閘道服務`,
  [`Event gateway management client keystore`]: `事件閘道管理用戶端金鑰儲存庫`,
  [`Edit Event gateway Service`]: `編輯事件閘道服務`,
  [`Configure Event Gateway Service`]: `配置事件閘道服務`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `配置事件閘道服務以保護和強制執行非同步 API`,
  [`Catalogs with published product`]: `含有已發佈產品的型錄`,
  [`Cluster Config ID`]: `叢集配置 ID`,
  [`SASL mechanism`]: `SASL 機制`,
  [`Security protocol`]: `安全通訊協定`,
  [`Transport CA certificate`]: `傳輸 CA 憑證`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `檔案大小上限為 {size}。 支援的檔案類型為 json、xml、yml、yaml 及 zip。`,
  [`Supported file types are json, xml, yml & yaml.`]: `支援的檔案類型為 json、xml、yml 及 yaml。`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `支援的檔案類型為 json、wsdl、xml、xsd、yml、yaml 及 zip。`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `支援的檔案類型為 json、wsdl、xml、yml 及 yaml。`,
  [`Select a file`]: `選取檔案`,
  [`or specify a file URL`]: `或指定檔案 URL`,
  [`Generated OpenAPI {version} definition`]: `產生的 OpenAPI {version} 定義`,
  [`User registry type`]: `使用者登錄類型`,
  [`User registry types`]: `使用者登錄類型`,
  [`User registry type {title} has been created.`]: `已建立使用者登錄類型 {title}。`,
  [`User registry type {title} has been updated.`]: `已更新使用者登錄類型 {title}。`,
  [`Failed to create user registry type {title}.`]: `無法建立使用者登錄類型 {title}。`,
  [`Failed to update user registry type {title}.`]: `無法更新使用者登錄類型 {title}。`,
  [`Failed to update user registry {title}.`]: `無法更新使用者登錄 {title}。`,
  [`Custom user registry`]: `自訂使用者登錄`,
  [`Create custom user registry`]: `建立自訂使用者登錄`,
  [`Edit custom user registry`]: `編輯自訂使用者登錄`,
  [`Create user registry type`]: `建立使用者登錄類型`,
  [`Edit user registry type`]: `編輯使用者登錄類型`,
  [`Configure user authentication using a custom external user registry`]: `使用自訂外部使用者登錄來配置使用者鑑別`,
  [`Secured Endpoint`]: `安全端點`,
  [`Configuration schema`]: `配置綱目`,
  [`About custom user registries`]: `關於自訂使用者登錄`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `自訂使用者登錄可用於向 Developer Portal 鑑別使用者，但無法用於保護 API 安全。若要將自訂使用者登錄配置為 API Manager 中的資源，外部使用者目錄必須建立且可供與 API Connect 生態系統搭配使用。`,
  [`Update Wsdl`]: `更新 Wsdl`,
  [`Update Existing WSDL service`]: `更新現有 WSDL 服務`,
  [`Format of selected WSDL has been successfully validated`]: `已順利驗證所選 WSDL 的格式`,
  [`Select the target WSDL file to update from`]: `選取要從中更新的目標 WSDL 檔`,
  [`Selected WSDL has been successfully uploaded`]: `已順利上傳所選 WSDL`,
  [`Services available in selected API`]: `所選 API 中可用的服務`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `啟用 OpenAPI 2.0 文件的舊式 Open API 編輯器（已淘汰）`,
  [`Unknown`]: `不明`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `由於與 API 或產品中指定的閘道類型不相容而隱藏了 {incompatibleCatalogCount} 個型錄`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `由於與 API 或產品中指定的閘道類型不相容而隱藏了 {incompatibleSpacesCount} 個空間`,
  [`Keep in mind`]: `請記住`,
  [`Only one type of enforced APIs is allowed per product`]: `每個產品僅容許一種類型的強制 API`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `由於與產品中指定的閘道類型不相容而無法選取 {incompatibleAPIsCount} 個 API`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `由於與 API 中指定的閘道類型不相容而無法選取 {incompatibleProductsCount} 個產品`,
  [`Unenforced`]: `未強制執行`,
  [`Create a new product by adding APIs and plans.`]: `透過新增 API 及方案來建立新產品。`,
  [`Rate limits are only applied to enforced APIs.`]: `速率限制僅套用至已施行的 API。`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `將方案及 API 限制新增至產品。方案限制僅套用至已施行的 API，未施行的 API 將沒有限制。`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `將方案及速率限制新增至產品。方案僅套用至已施行的 API，未施行的 API 將沒有限制。`,
  [`Delete plan`]: `刪除方案`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `為 AsyncAPI 選取事件閘道服務。對於所有其他類型的 API，請選取 DataPower API Gateway 或與第 5 版相容的版本。若要更新此設定，請先移除相關聯的 API。`,
  [`Edit API list`]: `編輯 API 清單`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `此產品中的強制執行 API 需要多種閘道類型。`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `此產品中的強制執行 {apiType} 需要 {gatewayType}。`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API`,
  [`AsyncAPI(s)`]: `AsyncAPI`,
  [`Select the gateway type for this Product`]: `選取此產品的閘道類型`,
  [`Added default plan`]: `已新增預設方案`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `將產品發佈至沙箱型錄。這會將產品與測試應用程式相關聯。`,
  [`cluster`]: `叢集`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `來自拒絕者的訊息`,
  [`Vendor extensions feature disabled`]: `已停用供應商延伸特性`,
  [`No configured gateways found`]: `找不到配置的閘道`,
  [`No OpenAPI extensions associated with the configured gateway`]: `沒有與已配置閘道相關聯的 OpenAPI 延伸`,
  [`Extension`]: `延伸`,
  [`Select the extensions to add to the API document`]: `選取要新增至 API 文件的延伸`,
  [`(Operations: {operations})`]: `（作業：{operations}）`,
  [`Failed to get application`]: `無法取得應用程式`,
  [`Failed to get credentials for application`]: `無法取得應用程式的認證`,
  [`Failed to get published product`]: `無法取得已發佈的產品`,
  [`Failed to get the published API`]: `無法取得已發佈的 API`,
  [`API must be activated to use the Test tool`]: `必須啟動 API 才能使用測試工具`,
  [`Import from Asset repository`]: `從資產儲存庫中匯入`,
  [`Untitled {componentLabel}`]: `未命名 {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `您的授權標準需要分析服務才能追蹤用量。按一下「登錄服務」以配置分析服務。`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `所有 DataPower 閘道服務都需要「分析服務」才能追蹤用量。 按一下每個閘道的「關聯分析服務」，以符合授權條款。`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `型錄與 API 或產品中指定的閘道類型不相容`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `空間與 API 或產品中指定的閘道類型不相容`,
  [`Preserve Subscription`]: `保留訂閱`,
  [`Publish validation`]: `發佈驗證`,
  [`Publish validations`]: `發佈驗證`,
  [`Edit publish validations`]: `編輯發佈驗證`,
  [`The following validations will be performed when publishing a product`]: `發佈產品時，將執行下列驗證`,
  [`There are no publish validations enabled`]: `未啟用發佈驗證`,
  [`Publish validations have been updated`]: `發佈驗證已更新`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `提供「事件閘道服務」用來信任後端 Kafka 伺服器憑證的憑證管理中心 (CA)。 如果伺服器使用由知名憑證管理中心發出的憑證，您就不需要提供 CA。`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `為引導伺服器輸入的值無效。 輸入叢集上每一個 Kafka 分配管理系統的主機和埠，並以逗點區隔。 例如，broker1.com:9092,broker2.com:9092,broker3.com:9092。`,
  [`Upload OpenAPI Extension`]: `上傳 OpenAPI 延伸`,
  [`Add OpenAPI Extension`]: `新增 OpenAPI 延伸`,
  [`Gateway Endpoint`]: `閘道端點`,
  [`API Dashboard`]: `API 儀表板`,
  [`API call data`]: `API 呼叫資料`,
  [`Product Dashboard`]: `產品儀表板`,
  [`API Product consumption metrics`]: `API 產品耗用度量`,
  [`Monitoring Latency Dashboard`]: `監視延遲儀表板`,
  [`Data about the time taken to serve API calls`]: `處理 API 呼叫所花費時間的相關資料`,
  [`Monitoring Status Dashboard`]: `監視狀態儀表板`,
  [`API Error and success information`]: `API 錯誤和成功資訊`,
  [`Usage Dashboard`]: `用量儀表板`,
  [`Usage for gateway {gateway}`]: `閘道 {gateway} 的使用情形`,
  [`Latency for gateway {gateway}`]: `閘道 {gateway} 的延遲`,
  [`Most popular products, APIs and applications`]: `最熱門的產品、API 和應用程式`,
  [`Consumption Dashboard`]: `耗用儀表板`,
  [`Total API volume useful for auditing and compliance`]: `適用於審核及相符性的 API 數量總計`,
  [`Monthly Summary by Status Code`]: `每月摘要（依狀態碼）`,
  [`Monthly Summary Data by Status Code`]: `每月摘要資料（依狀態碼）`,
  [`Daily Summary by Status Code`]: `每日摘要（依狀態碼）`,
  [`Daily Summary Data by Status Code`]: `每日摘要資料（依狀態碼）`,
  [`Response time (ms)`]: `回應時間（毫秒）`,
  [`Datetime`]: `日期時間`,
  [`Transaction ID:`]: `交易 ID：`,
  [`Other`]: `其他`,
  [`Date time`]: `日期時間`,
  [`Total calls`]: `呼叫次數總計`,
  [`Date`]: `日期`,
  [`Month`]: `月`,
  [`Minimum response time`]: `回應時間下限`,
  [`Maximum response time`]: `回應時間上限`,
  [`Average response time`]: `平均回應時間`,
  [`Total API calls`]: `API 呼叫總數`,
  [`Total APIs`]: `API 總數`,
  [`APIs called`]: `呼叫的 API 數目`,
  [`Total products`]: `產品總計`,
  [`products called`]: `呼叫的產品數`,
  [`Total errors`]: `錯誤總數`,
  [`Status codes (detailed)`]: `狀態碼（詳細）`,
  [`Response time percentiles`]: `回應時間百分位數`,
  [`API calls per day`]: `每天的 API 呼叫次數`,
  [`Time zone:`]: `時區：`,
  [`Time zone`]: `時區`,
  [`Toggle axes`]: `切換軸`,
  [`Datetime:`]: `日期時間：`,
  [`datetime per 30 seconds`]: `每 30 秒的日期時間`,
  [`Datetime (local time)`]: `日期時間（當地時間）`,
  [`Data usage (bytes)`]: `資料用量（位元組）`,
  [`Time to Serve Request`]: `處理要求的時間`,
  [`API name`]: `API 名稱`,
  [`Product name`]: `產品名稱`,
  [`Data usage (bytes received)`]: `資料用量（接收的位元組數）`,
  [`Data usage (bytes sent)`]: `資料用量（傳送的位元組數）`,
  [`Response times (>1s)`]: `回應時間（>1 秒）`,
  [`milliseconds`]: `毫秒`,
  [`errors`]: `錯誤數`,
  [`Success Rate`]: `成功率`,
  [`Errors Data`]: `錯誤資料`,
  [`Success Data`]: `成功資料`,
  [`Total consumer organizations`]: `消費者組織總計`,
  [`making API calls`]: `發出 API 呼叫`,
  [`Applications per plan`]: `每個方案的應用程式`,
  [`Top products by calls`]: `依呼叫數列出的前幾個產品`,
  [`Top APIs by calls`]: `依呼叫數列出的前幾個 API`,
  [`Top client IP addresses by calls`]: `依呼叫列出的前幾個用戶端 IP 位址`,
  [`Client IP address`]: `用戶端 IP 位址`,
  [`Top APIs by request size`]: `依要求大小列出的前幾個 API`,
  [`Top APIs by response size`]: `依回應大小列出的前幾個 API`,
  [`Top APIs by response time`]: `依回應時間列出的前幾個 API`,
  [`Top applications by calls`]: `依呼叫數列出的前幾個應用程式`,
  [`Dashboards`]: `儀表板`,
  [`Discover`]: `探索`,
  [`UTC`]: `UTC`,
  [`Local time`]: `當地時間`,
  [`URI`]: `URI`,
  [`Response code`]: `回應碼`,
  [`Total time elapsed (ms)`]: `經歷時間總計（毫秒）`,
  [`Timestamp`]: `時間戳記`,
  [`Method`]: `方法`,
  [`Gateway IP`]: `閘道 IP`,
  [`Gateway Operations Dashboard`]: `閘道作業儀表板`,
  [`Usage and latency information per gateway`]: `每個閘道的使用情形及延遲資訊`,
  [`Transaction ID`]: `交易 ID`,
  [`Filters`]: `過濾器`,
  [`Fields`]: `欄位`,
  [`Clear all`]: `全部清除`,
  [`API Event`]: `API 事件 (API Event)`,
  [`API Assembly Policy Latencies`]: `API 組件原則延遲`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `下圖顯示根據 API 組件原則進行要求期間，增加的延遲時間（毫秒）。 這有助於判斷 API 組件中的慢速點或瓶頸。`,
  [`Collapse`]: `收合`,
  [`Expand`]: `展開`,
  [`enter name`]: `輸入名稱`,
  [`Enter value`]: `輸入值`,
  [`Time range`]: `時間範圍`,
  [`Select one of the following time range filters`]: `選取下列其中一個時間範圍過濾器`,
  [`Last 1 hour`]: `過去 1 小時`,
  [`Last {number} minutes`]: `過去 {number} 分鐘`,
  [`Last {number} hours`]: `過去 {number} 小時`,
  [`Last {number} days`]: `過去 {number} 天`,
  [`Start date`]: `開始日期`,
  [`End date`]: `結束日期`,
  [`Select from the following dropdowns and enter a value`]: `從下列下拉清單中選取並輸入值`,
  [`Select one of the following type filters`]: `選取下列其中一個類型過濾器`,
  [`All events`]: `所有事件`,
  [`Success only (2xx, 3xx)`]: `僅成功（2xx、3xx）`,
  [`Errors only (4xx, 5xx)`]: `僅錯誤（4xx、5xx）`,
  [`Import/Export`]: `匯入/匯出`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `關於 {totalItems} 個結果（{searchTime} 秒），網址為 {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `大約 {totalItems} 個結果（{searchTime} 秒），時間為 {time} ({utcTime})`,
  [`API calls`]: `API 呼叫數`,
  [`Export as CSV`]: `匯出為 CSV`,
  [`Export as JSON`]: `匯出為 JSON`,
  [`Export as PNG`]: `匯出為 PNG`,
  [`Export as JPG`]: `匯出為 JPG`,
  [`Edit filter`]: `編輯過濾器`,
  [`Edit query`]: `編輯查詢`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `這是共用查詢。 將對共用此查詢的所有使用者進行任何修改。`,
  [`This is a shared query. This will delete it for all users.`]: `這是共用查詢。 這將針對所有使用者刪除它。`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `已針對 {org} 提供者組織中的所有使用者更新 {queryname}`,
  [`{queryname} has been updated for all users in the {space} space`]: `已針對 {space} 空間中的所有使用者更新 {queryname}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `已針對 {catalog} 型錄中的所有使用者更新 {queryname}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `已針對雲端管理程式中的所有使用者更新 {queryname}`,
  [`Stop sharing`]: `停止共用`,
  [`Share query`]: `共用查詢`,
  [`Share filter`]: `共用過濾器`,
  [`You do not have permissions to delete this filter`]: `您沒有刪除此過濾器的許可權`,
  [`You do not have permissions to delete this query`]: `您沒有刪除此查詢的許可權`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `您確定要在 {org} 提供者組織範圍中共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `您確定要在 {space} 空間範圍中共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `您確定要在 {catalog} 型錄範圍中共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `您確定要在雲端範圍共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `您確定要停止共用查詢 {queryTitle}嗎？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `您確定要在 {org} 提供者組織範圍停止共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `您確定要在 {space} 空間範圍停止共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `您確定要在 {catalog} 型錄範圍停止共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `您確定要在雲端範圍停止共用查詢 {queryTitle} 嗎？`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `您確定要刪除查詢 {queryTitle}嗎？`,
  [`At least one query parameter expected`]: `預期至少一個查詢參數`,
  [`A provided query parameter is incomplete`]: `提供的查詢參數不完整`,
  [`Could not parse query string`]: `無法剖析查詢字串`,
  [`An unsupported query parameter was provided: {query}`]: `提供了不受支援的查詢參數：{query}`,
  [`Invalid timeframe value: {value}`]: `無效的時間範圍值：{value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `{field} 值必須遵循 RFC 3339 5.6 小節所定義的日期時間表示法。 範例：2023、2023-01、2023-01-02、2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `欄位 {field} 不支援運算子 {operator}`,
  [`The value of {field} must be a number`]: `{field} 的值必須是數字`,
  [`Delete query`]: `刪除查詢`,
  [`Delete filter`]: `刪除過濾器`,
  [`Save query`]: `儲存查詢`,
  [`Save filter`]: `儲存過濾器`,
  [`Save filter as`]: `將過濾器另存為`,
  [`Save query as`]: `將查詢另存為`,
  [`Save as...`]: `另存新檔...`,
  [`Save as`]: `另存新檔`,
  [`Paste query string to load the query`]: `貼上查詢字串以載入查詢`,
  [`Copy or paste filter string to load filters`]: `複製或貼上過濾器字串以載入過濾器`,
  [`Query string`]: `查詢字串`,
  [`View filter`]: `檢視過濾器`,
  [`View filter string`]: `檢視過濾器字串`,
  [`Date (local time)`]: `日期（當地時間）`,
  [`Coming soon, a new built in analytics feature`]: `即將推出全新的內建分析功能`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `分析功能將協助收集 API 執行時期洞察、管理服務水準、設定配額、建立控制項、設定安全原則、管理社群，以及分析趨勢。`,
  [`Documentation`]: `文件`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `深入瞭解 API 執行時期資料的類型，以及如何使用 AWS s3 儲存區來將它視覺化。`,
  [`Blog`]: `部落格`,
  [`Learn about what data is available today and how to access it.`]: `瞭解目前可用的資料以及如何存取它。`,
  [`Next steps`]: `下一步`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `所選取的 Secrets Manager 服務不會管理任何憑證。`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `需要憑證，以保護 API Connect 與閘道管理端點之間的連線安全。 此外也需要憑證，以保護閘道與它所處理的網域之間的連線安全。 所有憑證都必須儲存在 Secrets Manager 服務中。 此服務為憑證提供安全儲存庫，並在憑證與 Event Notifications 服務的整合即將到期時傳送通知給您，以協助防止中斷。 如果要著手進行，`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `需要憑證，以保護閘道與它所處理的網域之間的連線安全。 所有憑證都必須儲存在 Secrets Manager 服務中。 此服務為憑證提供安全儲存庫，並在憑證與 Event Notifications 服務的整合即將到期時傳送通知給您，以協助防止中斷。 如果要著手進行，`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `變更 Secrets Manager 將會移除所有現有的憑證及 CA 組合指派。`,
  [`Create a [Secrets Manager]({link}) service`]: `建立 [Secrets Manager]({link}) 服務`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `將必要的憑證及 CA 組合匯入 Secrets Manager 服務。`,
  [`No Secrets Manager services available`]: `沒有 Secrets Manager 服務可用`,
  [`Select Secrets Manager service`]: `選取 Secrets Manager 服務`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `選取管理此閘道之憑證的 [Secrets Manager]({link}) 服務。`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Secrets Manager 服務必須為閘道管理端點的網域及閘道所處理的網域管理其憑證。 此外，您必須使用 IBM Cloud IAM 來授權 API Connect Reserved Instance 服務，以便其存取 Secrets Manager 服務。`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `然後，為管理端點的網域選取憑證。 此外，如果憑證並非由已知的憑證管理中心所指派，請選取對應的 CA 組合。 憑證及組合必須由 Secrets Manager 服務進行管理。`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `然後，指定將由此閘道處理的網域。 會利用 SNI 技術；如此一來，就可以透過相同的 IP 位址和埠提供多個網域，且不需提供相同的憑證。 支援萬用字元格式。 預設（籠統）網域 '*' 必須包含在表格中，以作為最終項目。 視需要輸入其他網域名稱，在每一種情況下，請指定由 Secrets Manager 服務進行管理的憑證。`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `使用 [IBM Cloud IAM]({link}) 來授權 API Connect Reserved Instance 服務，以便其存取 Secrets Manager 服務。`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `從 Secrets Manager 載入現有憑證及 CA 組合指派，以更新該憑證及 CA 組合指派。`,
  [`Access s3 data`]: `存取 s3 資料`,
  [`Create resource`]: `建立資源`,
  [`Connect analytics data in s3 bucket with AWS`]: `將 s3 儲存區中的分析資料與 AWS 相連接`,
  [`AWS account`]: `AWS 帳戶`,
  [`AWS account number`]: `AWS 帳號`,
  [`Enter your 12 digit aws account id`]: `輸入 12 位數的 AWS 帳戶 ID`,
  [`Account id must be 12 digits`]: `帳戶 ID 必須為 12 位數`,
  [`Failed to update AWS account id.`]: `無法更新 AWS 帳戶 ID。`,
  [`You have limited access to the catalogs.`]: `您擁有型錄的有限存取權。`,
  [`You have limited access to the spaces.`]: `您具有空間的有限存取權。`,
  [`Contact admin to get more access.`]: `請聯絡管理者以取得更多存取權。`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `您無法選取此型錄。 如果您需要存取權，請聯絡管理者。`,
  [`You can't select this space. Please contact an admin if you require access.`]: `您無法選取此空間。 如果您需要存取權，請聯絡管理者。`,
  [`You have limited access to the product billing.`]: `您具有產品計費的有限存取權。`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `已順利更新 AWS 帳戶 ID。 可能需要幾秒鐘才能授與 S3 資料的存取權。`,
  [`An organization or a group is missing in the catalog chosen.`]: `所選型錄中遺漏組織或群組。`,
  [`Secrets Manager instance`]: `密鑰管理程式實例`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `無法選取遺漏電子郵件擁有者的消費者組織`,
  [`You currently don’t have any data.`]: `您目前沒有任何資料。`,
  [`You currently don’t have any organizations.`]: `您目前沒有任何組織。`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `要求訂閱產品 {prodname} 中計劃 {plan} 的應用程式 {appname}：{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `{operation} 產品的要求：{prodname}：{version}`,
  [`Request to upgrade application {appname} to production`]: `要求將應用程式 {appname} 升級至正式作業`,
  [`Onboarding request for {email}`]: `{email} 的上線要求`,
  [`Product lifecycle request`]: `產品生命週期要求`,
  [`Subscription request`]: `訂閱要求`,
  [`Application lifecycle request`]: `應用程式生命週期要求`,
  [`Consumer Onboarding request`]: `消費者上線要求`,
  [`Originator`]: `發起者`,
  [`Edit analytics service advanced settings`]: `編輯分析服務進階設定`,
  [`Advanced settings`]: `進階設定`,
  [`Must be a positive integer`]: `必須是正整數`,
  [`Maximum value is {maxValue}`]: `最大值為 {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `最大值為 {maxValueDays}d 或 {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `配置分析部署的進階設定。`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `值必須以 'd' 表示日，或以' h ' 表示小時。 例如：30d 或 12h。`,
  [`Rollover settings`]: `輪替設定`,
  [`Retention settings`]: `保留設定`,
  [`Delete an index when it meets the following condition:`]: `刪除符合下列條件的索引：`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `當受管理索引符合下列其中一個條件時，將寫入別名輪替為新索引：`,
  [`Minimum document count`]: `文件計數下限`,
  [`The minimum number of documents required to roll over the index.`]: `輪替索引所需的文件數目下限。`,
  [`Minimum index age`]: `索引經歷時間下限`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `輪替索引所需的索引經歷時間下限。 索引經歷時間是索引建立到現在的時間。 使用 'd' 表示日，使用 ' h ' 表示小時。 例如，30d 表示索引在經歷時間達到 30 天時將會輪替，而 96h 表示索引在經歷時間達到 96 小時時將會輪替。 最大值為 {maxValueDays}d 或 {maxValueHours}h。`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `刪除索引所需的索引經歷時間下限。 索引經歷時間是索引建立到現在的時間。 使用 'd' 表示日，使用 ' h ' 表示小時。 例如，30d 表示一旦索引經歷時間達到 30 天，即會刪除該索引；而 96h 表示一旦索引經歷時間達到 96 小時，即會刪除該索引。 最大值為 {maxValueDays}d 或 {maxValueHours}h。`,
  [`Time range:`]: `時間範圍：`,
  [`Time range: Before {datetime}`]: `時間範圍：{datetime} 之前`,
  [`Time range: After {datetime}`]: `時間範圍：{datetime} 之後`,
  [`Filter before`]: `之前過濾`,
  [`Filter after`]: `之後過濾`,
  [`Filter exact`]: `確切過濾`,
  [`Filter gt`]: `過濾 gt`,
  [`Filter lt`]: `過濾 lt`,
  [`Filter equals`]: `過濾器等於`,
  [`Filter by`]: `過濾依據：`,
  [`Filter out`]: `濾出`,
  [`Delete failed`]: `刪除失敗`,
  [`Delete warning`]: `刪除警告`,
  [`Could not delete saved query {queryTitle}.`]: `無法刪除已儲存的查詢 {queryTitle}。`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `要求已順利回應，但 {queryTitle} 尚未完成刪除。`,
  [`API governance`]: `API 控管`,
  [`Something's wrong`]: `發生錯誤`,
  [`We were unable to connect to the service.`]: `無法連接服務。`,
  [`Invalid date`]: `日期無效`,
  [`Invalid time`]: `無效時間`,
  [`End date must be after the start date`]: `結束日期必須晚於開始日期`,
  [`Duplicate`]: `複製`,
  [`Duplicate query`]: `重複查詢`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `您確定要複製 {queryTitle} 嗎？`,
  [`No queries saved`]: `未儲存查詢`,
  [`No queries shared`]: `未共用查詢`,
  [`To save a query, start by creating one then save.`]: `若要儲存查詢，請先建立一個查詢，然後再儲存。`,
  [`Share a saved query and allow others to use it.`]: `共用已儲存的查詢，並容許其他人使用它。`,
  [`Query updated`]: `已更新查詢`,
  [`Query update failed`]: `查詢更新失敗`,
  [`Share successful`]: `共用成功`,
  [`Share failed`]: `共用失敗`,
  [`Delete successful`]: `刪除成功`,
  [`Query unshared`]: `未共用查詢`,
  [`Unshare {queryTitle} failed`]: `取消共用 {queryTitle} 失敗`,
  [`Query copied`]: `已複製查詢`,
  [`Duplicate {queryTitle} failed`]: `複製 {queryTitle} 失敗`,
  [`The query {queryTitle} has been updated.`]: `已更新查詢 {queryTitle}。`,
  [`The query {queryTitle} has been shared.`]: `已共用查詢 {queryTitle}。`,
  [`The query {queryTitle} has been deleted.`]: `已刪除查詢 {queryTitle}。`,
  [`The query {queryTitle} is no longer shared.`]: `不再共用查詢 {queryTitle}。`,
  [`A copy of {queryTitle} has been created.`]: `已建立 {queryTitle} 的副本。`,
  [`About queries`]: `關於查詢`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `查詢是對儀表板及「探索」視圖中使用的分析資料進行細部調整的方法。 當套用查詢時，會更新所有儀表板，只包含選取的資料。`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `您可以使用其中一個預先定義的時間範圍，或使用自訂選項來使用特定時間範圍到最接近的秒。`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `欄位可用來指定只應包含某些資料。 幾乎可以依 API 事件的每個屬性來過濾。`,
  [`There are then different operators available depending on the field type.`]: `然後，根據欄位類型，有不同的運算子可用。`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `字串欄位容許 equals、not、starts with、ends with、regex。`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `可以使用 "contains" 和 "not contains" 來提供單一欄位的值清單（例如 API 版本清單）。`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP 位址欄位容許等於而非運算子，它們也容許在 CIDR 表示法中指定值，以容許使用網路遮罩來選取特定網路。`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `正規表示式可以非常強大來選取特定值，但執行速度較慢，因此建議使用其他運算子，例如 'starts with' 和 'ends with'（如果可能的話）。`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `使用邏輯 AND 運算子結合不同欄位的查詢過濾器。 這表示為相同欄位名稱新增兩個過濾器只會傳回滿足兩個過濾器的結果。`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `{type} 查詢參數是僅指定成功或錯誤事件的簡單方法。 這是使用 {statusCode} 欄位。 如果只需要特定的狀態碼，則可以使用欄位過濾器。 {statusCodeTwo} 欄位的值通常是完整字串，例如 {okStatus}，而不只是數值 HTTP 回覆碼。`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `可以儲存查詢以供稍後重複使用，也可以與您的現行範圍層次共用它們，讓其他人也可以使用，例如提供者組織或型錄。`,
  [`n/a`]: `n/a`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `附註：在事件有效負載中，「提供者組織 ID」和「提供者組織名稱」欄位將分別顯示為 {orgId} 和 {orgName}；「消費者組織 ID」和「消費者組織名稱」欄位將顯示為 {developerOrgId} 和 {developerOrgName}。`,
  [`No authentication token`]: `沒有鑑別記號`,
  [`Contact your administrator for further assistance.`]: `請聯絡您的管理者，以取得進一步協助。`
};
