// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2021
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'
import docsService from 'Apps/docsService'

angular.module('apiconnect-assembly').factory('ApimPolicyFactory', [
  '$rootScope',
  '$q',
  'translateFilter',
  function($rootScope, $q, translateFilter) {
    const policies = []

    policies.push({
      info: {
        title: translateFilter('Switch'),
        description: translateFilter(
          'Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.'
        ),
        docLink: docsService('com.ibm.apic.toolkit.doc/rapim_switch'),
        name: 'switch',
        version: '1.0.0',
        categories: [translateFilter('Logic')],
        display: {
          color: '#878d96',
          icon: 'choices',
        },
      },
      logic: true,
      classes: ['logic'],
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['case'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          case: {
            type: 'array',
            minItems: 1,
            items: {
              type: 'object',
              additionalProperties: false,
              oneOf: [
                {required: ['condition', 'execute']},
                {required: ['otherwise']},
              ],
              properties: {
                condition: {
                  type: 'string',
                },
                execute: {
                  type: 'array',
                },
                otherwise: {
                  type: 'array',
                },
              },
            },
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Switch'),
        description: translateFilter(
          'Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.'
        ),
        docLink: docsService('com.ibm.apic.toolkit.doc/rapim_switch.html'),
        name: 'switch',
        version: '2.0.0',
        categories: [translateFilter('Logic')],
        display: {
          color: '#878d96',
          icon: 'choices',
        },
      },
      logic: true,
      classes: ['logic'],
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['case'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          case: {
            type: 'array',
            minItems: 1,
            items: {
              type: 'object',
              additionalProperties: false,
              oneOf: [
                {required: ['condition', 'execute']},
                {required: ['otherwise']},
              ],
              properties: {
                condition: {
                  type: 'string',
                },
                execute: {
                  type: 'array',
                },
                otherwise: {
                  type: 'array',
                },
              },
            },
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Operation Switch'),
        description: translateFilter(
          'Use the Operation Switch policy to apply a section of the assembly to a specific operation.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_operation_switch.html'
        ),
        name: 'operation-switch',
        version: '1.0.0',
        categories: [translateFilter('Logic')],
        display: {
          color: '#878d96',
          icon: 'choices',
        },
      },
      logic: true,
      classes: ['logic'],
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['case'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          case: {
            type: 'array',
            minItems: 1,
            items: {
              type: 'object',
              additionalProperties: false,
              required: ['operations', 'execute'],
              properties: {
                operations: {
                  type: 'array',
                  minItems: 1,
                  items: {
                    oneOf: [
                      {
                        $ref: '#/definitions/operationId',
                      },
                      {
                        $ref: '#/definitions/operationVerbPath',
                      },
                    ],
                  },
                  uniqueItems: true,
                },
                execute: {
                  type: 'array',
                },
              },
            },
          },
          otherwise: {
            type: 'array',
          },
        },
        definitions: {
          operationId: {
            type: 'string',
            additionalProperties: false,
            not: {
              type: 'string',
              pattern: '^\\s*$',
            },
          },
          operationVerbPath: {
            type: 'object',
            additionalProperties: false,
            required: ['verb', 'path'],
            properties: {
              verb: {
                type: 'string',
              },
              path: {
                type: 'string',
              },
            },
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Operation Switch'),
        description: translateFilter(
          'Use the Operation Switch policy to apply a section of the assembly to a specific operation.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_operation_switch.html'
        ),
        name: 'operation-switch',
        version: '2.0.0',
        categories: [translateFilter('Logic')],
        display: {
          color: '#878d96',
          icon: 'choices',
        },
      },
      logic: true,
      classes: ['logic'],
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['case'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          case: {
            type: 'array',
            minItems: 1,
            items: {
              type: 'object',
              additionalProperties: false,
              required: ['operations', 'execute'],
              properties: {
                operations: {
                  type: 'array',
                  minItems: 1,
                  items: {
                    oneOf: [
                      {
                        $ref: '#/definitions/operationId',
                      },
                      {
                        $ref: '#/definitions/operationVerbPath',
                      },
                    ],
                  },
                  uniqueItems: true,
                },
                execute: {
                  type: 'array',
                },
              },
            },
          },
          otherwise: {
            type: 'array',
          },
        },
        definitions: {
          operationId: {
            type: 'string',
            additionalProperties: false,
            not: {
              type: 'string',
              pattern: '^\\s*$',
            },
          },
          operationVerbPath: {
            type: 'object',
            additionalProperties: false,
            required: ['verb', 'path'],
            properties: {
              verb: {
                type: 'string',
              },
              path: {
                type: 'string',
              },
            },
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('If'),
        description: translateFilter(
          'Use the if policy to apply a section of the assembly when a condition is fulfilled.'
        ),
        docLink: docsService('com.ibm.apic.toolkit.doc/rapim_if.html'),
        name: 'if',
        version: '1.0.0',
        categories: [translateFilter('Logic')],
        display: {
          color: '#878d96',
          icon: 'arrow--up-right',
        },
      },
      logic: true,
      classes: ['logic'],
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['condition'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          condition: {
            type: 'string',
          },
          execute: {
            type: 'array',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Throw'),
        description: translateFilter(
          'Use the throw policy to throw an error when it is reached during the execution of an assembly flow.'
        ),
        docLink: docsService('com.ibm.apic.toolkit.doc/rapim_throw.html'),
        name: 'throw',
        version: '1.0.0',
        categories: [translateFilter('Logic')],
        display: {
          color: 'orange',
          icon: 'warning--alt',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['name'],
        properties: {
          title: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          message: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Throw'),
        description: translateFilter(
          'Use the throw policy to throw an error when it is reached during the execution of an assembly flow.'
        ),
        docLink: docsService('com.ibm.apic.toolkit.doc/rapim_throw.html'),
        name: 'throw',
        version: '2.0.0',
        categories: [translateFilter('Logic')],
        display: {
          color: 'orange',
          icon: 'warning--alt',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['name'],
        properties: {
          title: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          message: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Throw'),
        description: translateFilter(
          'Use the throw policy to throw an error when it is reached during the execution of an assembly flow.'
        ),
        docLink: docsService('com.ibm.apic.toolkit.doc/rapim_throw.html'),
        name: 'throw',
        version: '2.1.0',
        categories: [translateFilter('Logic')],
        display: {
          color: 'orange',
          icon: 'warning--alt',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['name'],
        properties: {
          title: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          message: {
            type: 'string',
          },
          'error-status-code': {
            type: 'string',
          },
          'error-status-reason': {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Redaction'),
        description: translateFilter(
          'Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyredaction.html'
        ),
        name: 'redact',
        version: '1.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'erase',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          actions: {
            type: 'array',
            minItems: 1,
            items: {
              $ref: '#/definitions/redact_actions',
            },
          },
        },
        definitions: {
          redact_actions: {
            type: 'object',
            additionalProperties: false,
            required: ['path'],
            properties: {
              path: {
                type: 'string',
              },
              action: {
                type: 'string',
                enum: ['remove', 'redact'],
                default: 'redact',
              },
              from: {
                type: 'array',
                minItems: 1,
                items: {
                  $ref: '#/definitions/redact_from',
                },
                default: ['all'],
              },
            },
          },
          redact_from: {
            type: 'string',
            additionalProperties: false,
            enum: ['all', 'request', 'response', 'logs'],
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Redaction'),
        description: translateFilter(
          'Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyredaction_apigw.html'
        ),
        name: 'redact',
        version: '2.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'erase',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          redactions: {
            type: 'array',
            minItems: 1,
            items: {
              $ref: '#/definitions/redact_actions',
            },
          },
          root: {
            type: 'string',
          },
        },
        definitions: {
          redact_actions: {
            type: 'object',
            additionalProperties: false,
            required: ['path'],
            properties: {
              path: {
                type: 'string',
              },
              action: {
                type: 'string',
                enum: ['remove', 'redact'],
                default: 'redact',
              },
            },
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('policy.handlebars.title'),
        description: translateFilter('policy.handlebars.description'),
        name: 'handlebars',
        version: '1.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#FF5050',
          icon: 'clear_all',
        },
      },
      gateways: [/*'datapower-gateway', */ 'micro-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['source'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          source: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Map'),
        description: translateFilter(
          'Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policy_map.html'
        ),
        name: 'map',
        version: '1.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'data--unstructured',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          inputs: {
            type: 'object',
          },
          outputs: {
            type: 'object',
          },
          actions: {
            type: 'array',
            items: {
              type: 'object',
            },
          },
          options: {
            type: 'object',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Map'),
        description: translateFilter(
          'Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policy_map.html'
        ),
        name: 'map',
        version: '2.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'data--unstructured',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          inputs: {
            type: 'object',
          },
          outputs: {
            type: 'object',
          },
          actions: {
            type: 'array',
            items: {
              type: 'object',
            },
          },
          options: {
            type: 'object',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('XSLT'),
        description: translateFilter(
          'Use the xslt policy to apply an XSLT transform to the payload.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policytransformxslt.html'
        ),
        categories: [translateFilter('Transforms')],
        name: 'xslt',
        version: '1.0.0',
        display: {
          color: '#4589ff',
          icon: 'data--2',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['source', 'input'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          input: {
            type: 'boolean',
            default: false,
          },
          source: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('XSLT'),
        description: translateFilter(
          'Use the xslt policy to apply an XSLT transform to the payload.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policytransformxslt.html'
        ),
        categories: [translateFilter('Transforms')],
        name: 'xslt',
        version: '2.0.0',
        display: {
          color: '#4589ff',
          icon: 'data--2',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['source', 'input'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          input: {
            type: 'boolean',
            default: false,
          },
          source: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('XML to JSON'),
        description: translateFilter(
          'Use the xml-to-json policy to convert the content of your payload from XML to JSON.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyxml2json.html'
        ),
        name: 'xml-to-json',
        version: '1.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'curve--auto-colon',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('XML to JSON'),
        description: translateFilter(
          'Use the xml-to-json policy to convert the content of your payload from XML to JSON.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyxml2json.html'
        ),
        name: 'xml-to-json',
        version: '2.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'curve--auto-colon',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          input: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
          conversionType: {
            type: 'string',
            enum: ['badgerFish', 'apicv5'],
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('HTML page'),
        description: '',
        name: 'html-page',
        version: '2.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009D9A',
          icon: 'document--tasks',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
        },
      },
      deprecated: true,
    })
    policies.push({
      info: {
        title: translateFilter('JSON to XML'),
        description: translateFilter(
          'Use the json-to-xml policy to convert the content of your payload from JSON to XML.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjson2xml.html'
        ),
        name: 'json-to-xml',
        version: '1.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'curve--auto-colon',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['root-element-name', 'always-output-root-element'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          input: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
          'conversion-type': {
            type: 'string',
          },
          'root-element-name': {
            label: 'Root XML Element Name',
            description:
              'The root element name of the resultant XML document.  This is only required if the input JSON document is not hierarchial and has more than one top level properties.',
            type: 'string',
            default: 'json',
          },
          'always-output-root-element': {
            label: 'Always output the root element.',
            description:
              'Output the root element even if it is not required to make the XML document well formed.',
            type: 'boolean',
            default: false,
          },
          'unnamed-element-name': {
            label: 'Element name for JSON array elements',
            description:
              'The XML element name to be used for JSON array elements.',
            type: 'string',
            default: 'element',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('JSON to XML'),
        description: translateFilter(
          'Use the json-to-xml policy to convert the content of your payload from JSON to XML.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjson2xml.html'
        ),
        name: 'json-to-xml',
        version: '2.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'curve--auto-colon',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['root-element-name', 'always-output-root-element'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          conversionType: {
            type: 'string',
            enum: ['badgerFish', 'relaxed-badgerfish'],
          },
          'root-element-name': {
            label: 'Root XML Element Name',
            description:
              'The root element name of the resultant XML document.  This is only required if the input JSON document is not hierarchial and has more than one top level properties.',
            type: 'string',
            default: 'json',
          },
          'always-output-root-element': {
            label: 'Always output the root element.',
            description:
              'Output the root element even if it is not required to make the XML document well formed.',
            type: 'boolean',
            default: false,
          },
          'unnamed-element-name': {
            label: 'Element name for JSON array elements',
            description:
              'The XML element name to be used for JSON array elements.',
            type: 'string',
            default: 'element',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Rate limit'),
        description: translateFilter(
          'Set rate and burst limits in an assembly'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyratelimit.html'
        ),
        categories: [translateFilter('Policies')],
        name: 'ratelimit',
        version: '2.0.0',
        display: {
          color: '#009d9a',
          icon: 'meter',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          source: {
            label: 'Source',
            description: 'Where to find the named rate limit.',
            type: 'string',
            enum: ['catalog-named', 'plan-named', 'plan-default'],
            default: 'plan-default',
          },
          'rate-limit': {
            label: 'Rate Limit Names',
            type: 'array',
            description:
              'The name of a rate limit object defined in the datapower configuration. This property is required only if source = catalog',
          },
          'burst-limit': {
            label: 'Burst Limit Names',
            type: 'array',
            description:
              'The name of a burst limit defined in the datapower configuration. This property is required only if source = catalog',
          },
          'count-limit': {
            label: 'Count Limit',
            description:
              'The information about one or more count limits defined in the catalog',
            type: 'array',
          },
        },
        required: ['source'],
      },
    })
    policies.push({
      info: {
        title: translateFilter('Rate limit'),
        description: translateFilter(
          'Set rate and burst limits in an assembly'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyratelimit.html'
        ),
        categories: [translateFilter('Policies')],
        name: 'ratelimit',
        version: '2.1.0',
        display: {
          color: '#009d9a',
          icon: 'meter',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          source: {
            label: 'Source',
            description: 'Where to find the named rate limit.',
            type: 'string',
            enum: [
              'catalog-named',
              'plan-named',
              'gateway-named',
              'plan-default',
            ],
            default: 'plan-default',
          },
          'rate-limit': {
            label: 'Rate Limit Names',
            type: 'array',
            description:
              'The name of a rate limit object defined in the datapower configuration. This property is required only if source = catalog',
          },
          'burst-limit': {
            label: 'Burst Limit Names',
            type: 'array',
            description:
              'The name of a burst limit defined in the datapower configuration. This property is required only if source = catalog',
          },
          'count-limit': {
            label: 'Count Limit',
            description:
              'The information about one or more count limits defined in the catalog',
            type: 'array',
          },
        },
        required: ['source'],
      },
    })
    policies.push({
      info: {
        title: translateFilter('Rate limit'),
        description: translateFilter(
          'Set rate and burst limits in an assembly'
        ),
        docLink: 'rapim_ref_ootb_policyratelimit',
        categories: [translateFilter('Policies')],
        name: 'ratelimit',
        version: '2.2.0',
        display: {
          color: '#009d9a',
          icon: 'meter',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          source: {
            label: 'Source',
            description: 'Where to find the named rate limit.',
            type: 'string',
            enum: [
              'catalog-named',
              'plan-named',
              'gateway-named',
              'plan-default',
            ],
            default: 'plan-default',
          },
          'rate-limit': {
            label: 'Rate Limit Names',
            type: 'array',
            description:
              'The name of a rate limit object defined in the datapower configuration. This property is required only if source = catalog',
          },
          'burst-limit': {
            label: 'Burst Limit Names',
            type: 'array',
            description:
              'The name of a burst limit defined in the datapower configuration. This property is required only if source = catalog',
          },
          'count-limit': {
            label: 'Count Limit',
            description:
              'The information about one or more count limits defined in the catalog',
            type: 'array',
          },
        },
        required: ['source'],
      },
    })
    policies.push({
      info: {
        title: translateFilter('Log'),
        description: translateFilter(
          'Use the Log policy to customize or override the default activity logging configuration for an API.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policylog.html'
        ),
        name: 'log',
        version: '2.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'catalog',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          actions: {
            type: 'string',
            enum: ['gather-only', 'send-only', 'gather-and-send'],
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Log'),
        description: translateFilter(
          'Use the Log policy to customize or override the default activity logging configuration for an API.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policylog.html'
        ),
        name: 'log',
        version: '2.1.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'catalog',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          mode: {
            type: 'string',
            enum: ['gather-only', 'send-only', 'gather-and-send'],
          },
          'log-level': {
            type: 'string',
            default: 'default',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Invoke'),
        description: translateFilter(
          'Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyinvoke.html'
        ),
        name: 'invoke',
        version: '1.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'rss',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['target-url'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'target-url': {
            label: 'Invoke URL',
            description: 'The Invoke URL to be used.',
            type: 'string',
          },
          'secure-gateway': {
            label: 'Access URL through Secure Gateway',
            description:
              'Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.',
            type: 'boolean',
          },
          'tls-profile': {
            label: 'SSL Profile',
            description: 'The SSL Profile to use for the request',
            type: 'string',
          },
          timeout: {
            label: 'Timeout',
            description:
              'Time to wait before a reply back from the endpoint. Default is 60 seconds.',
            type: 'integer',
            default: 60,
          },
          username: {
            label: 'Username',
            description: 'The username to use for HTTP Basic authentication.',
            type: 'string',
          },
          password: {
            label: 'Password',
            description: 'The password to use for HTTP Basic authentication.',
            type: 'string',
          },
          verb: {
            label: 'HTTP Method',
            description: 'HTTP Method to use for the Invoke.',
            type: 'string',
            enum: [
              'keep',
              'GET',
              'POST',
              'PUT',
              'DELETE',
              'PATCH',
              'HEAD',
              'OPTIONS',
            ],
            default: 'GET',
          },
          compression: {
            label: 'Compression',
            description:
              'Used to enable/disable Content-Encoding compression on upload.',
            type: 'boolean',
          },
          'cache-response': {
            label: 'Cache Type',
            description:
              "The cache type determines whether to cache documents and the mechanism to use to remove cached entries. The default is 'no-cache'.",
            type: 'string',
            enum: ['protocol', 'time-to-live', 'no-cache'],
            default: 'protocol',
          },
          'cache-ttl': {
            label: 'Cache Time-to-Live',
            description:
              "Sets the validity period in seconds for documents in the cache. TTL applies to only the 'time-to-live' policy type. Enter a value in the range 5 - 31708800. The default value is 900.",
            type: 'integer',
            default: 900,
            minimum: 5,
            maximum: 31708800,
          },
          'cache-key': {
            label: 'Cache Key',
            description:
              'The cache key to use to identify the cache item. If this is not set the target-url will be used.',
            type: 'string',
          },
          'stop-on-error': {
            label: 'Stop on error',
            description: 'Used to enable/disable stop-on-error semantics.',
            type: 'array',
            items: {
              type: 'string',
            },
          },
          output: {
            label: 'Response object variable',
            description:
              "A name of a variable that will be used to store the response data from the request. This can then be used in other actions like 'Map' etc.",
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Invoke'),
        description: translateFilter(
          'Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyinvoke.html'
        ),
        name: 'invoke',
        version: '1.5.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'rss',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['target-url'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'target-url': {
            label: 'Invoke URL',
            description: 'The Invoke URL to be used.',
            type: 'string',
          },
          'secure-gateway': {
            label: 'Access URL through Secure Gateway',
            description:
              'Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.',
            type: 'boolean',
          },
          'tls-profile': {
            label: 'SSL Profile',
            description: 'The SSL Profile to use for the request',
            type: 'string',
          },
          timeout: {
            label: 'Timeout',
            description:
              'Time to wait before a reply back from the endpoint. Default is 60 seconds.',
            type: 'integer',
            default: 60,
          },
          username: {
            label: 'Username',
            description: 'The username to use for HTTP Basic authentication.',
            type: 'string',
          },
          password: {
            label: 'Password',
            description: 'The password to use for HTTP Basic authentication.',
            type: 'string',
          },
          verb: {
            label: 'HTTP Method',
            description: 'HTTP Method to use for the Invoke.',
            type: 'string',
            enum: [
              'keep',
              'GET',
              'POST',
              'PUT',
              'DELETE',
              'PATCH',
              'HEAD',
              'OPTIONS',
            ],
            default: 'GET',
          },
          compression: {
            label: 'Compression',
            description:
              'Used to enable/disable Content-Encoding compression on upload.',
            type: 'boolean',
          },
          'cache-response': {
            label: 'Cache Type',
            description:
              "The cache type determines whether to cache documents and the mechanism to use to remove cached entries. The default is 'no-cache'.",
            type: 'string',
            enum: ['protocol', 'time-to-live', 'no-cache'],
            default: 'protocol',
          },
          'cache-ttl': {
            label: 'Cache Time-to-Live',
            description:
              "Sets the validity period in seconds for documents in the cache. TTL applies to only the 'time-to-live' policy type. Enter a value in the range 5 - 31708800. The default value is 900.",
            type: 'integer',
            default: 900,
            minimum: 5,
            maximum: 31708800,
          },
          'cache-key': {
            label: 'Cache Key',
            description:
              'The cache key to use to identify the cache item. If this is not set the target-url will be used.',
            type: 'string',
          },
          'stop-on-error': {
            label: 'Stop on error',
            description: 'Used to enable/disable stop-on-error semantics.',
            type: 'array',
            items: {
              type: 'string',
            },
          },
          output: {
            label: 'Response object variable',
            description:
              "A name of a variable that will be used to store the response data from the request. This can then be used in other actions like 'Map' etc.",
            type: 'string',
          },
          'final-action': {
            label: 'Final Action',
            description: 'Indicate if the invoke is the final action',
            type: 'string',
            default: false,
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Proxy'),
        description: translateFilter(
          'Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyproxy.html'
        ),
        name: 'proxy',
        version: '1.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'rss',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['target-url'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'target-url': {
            label: 'Invoke URL',
            description: 'The Invoke URL to be used.',
            type: 'string',
          },
          'secure-gateway': {
            label: 'Access URL through Secure Gateway',
            description:
              'Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.',
            type: 'boolean',
          },
          'tls-profile': {
            label: 'SSL Profile',
            description: 'The SSL Profile to use for the request',
            type: 'string',
          },
          timeout: {
            label: 'Timeout',
            description:
              'Time to wait before a reply back from the endpoint. Default is 60 seconds.',
            type: 'integer',
            default: 60,
          },
          username: {
            label: 'Username',
            description: 'The username to use for HTTP Basic authentication.',
            type: 'string',
          },
          password: {
            label: 'Password',
            description: 'The password to use for HTTP Basic authentication.',
            type: 'string',
          },
          verb: {
            label: 'HTTP Method',
            description:
              'HTTP method to use to replace the current api HTTP method',
            type: 'string',
            enum: [
              'keep',
              'GET',
              'POST',
              'PUT',
              'DELETE',
              'PATCH',
              'HEAD',
              'OPTIONS',
            ],
            default: 'keep',
          },
          compression: {
            label: 'Compression',
            description:
              'Used to enable/disable Content-Encoding compression on upload.',
            type: 'boolean',
          },
          'cache-response': {
            label: 'Cache Type',
            description:
              "The cache type determines whether to cache documents and the mechanism to use to remove cached entries. The default is 'no-cache'.",
            type: 'string',
            enum: ['protocol', 'time-to-live', 'no-cache'],
            default: 'protocol',
          },
          'cache-ttl': {
            label: 'Cache Time-to-Live',
            description:
              "Sets the validity period in seconds for documents in the cache. TTL applies to only the 'time-to-live' policy type. Enter a value in the range 5 - 31708800. The default value is 900.",
            type: 'integer',
            default: 900,
            minimum: 5,
            maximum: 31708800,
          },
          'cache-key': {
            label: 'Cache Key',
            description:
              'The cache key to use to identify the cache item. If this is not set the target-url will be used.',
            type: 'string',
          },
          output: {
            label: 'Response object variable',
            description:
              "A name of a variable that will be used to store the response data from the request. This can then be used in other actions like 'Map' etc.",
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Activity Log'),
        description: translateFilter(
          'Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyactivitylog.html'
        ),
        name: 'activity-log',
        version: '1.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'catalog',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['content', 'error-content'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          content: {
            description: 'Activity to log when operation is succesful',
            type: 'string',
            default: 'activity',
          },
          'error-content': {
            description: 'Activity to log when operation is not succesful',
            type: 'string',
            default: 'payload',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Validate'),
        description: translateFilter(
          'Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyschemaval.html'
        ),
        name: 'validate',
        version: '1.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'checkmark',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['definition'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          definition: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Validate'),
        description: translateFilter(
          'Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyschemaval_apigw.html'
        ),
        name: 'validate',
        version: '2.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'checkmark',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['definition'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          definition: {
            type: 'string',
          },
          'json-schema': {
            type: 'string',
          },
          'xml-schema': {
            type: 'string',
          },
          'graphql-schema': {
            type: 'string',
          },
          'xml-validation-mode': {
            type: 'string',
            enum: ['xsd', 'soap-body', 'wsdl'],
          },
          input: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Validate'),
        description: translateFilter(
          'Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyschemaval_apigw.html'
        ),
        name: 'validate',
        version: '2.1.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'checkmark',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['definition'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          definition: {
            type: 'string',
          },
          'json-schema': {
            type: 'string',
          },
          'xml-schema': {
            type: 'string',
          },
          'graphql-schema': {
            type: 'string',
          },
          'xml-validation-mode': {
            type: 'string',
            enum: ['xsd', 'soap-body', 'wsdl'],
          },
          input: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Validate'),
        description: translateFilter(
          'Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyschemaval_apigw.html'
        ),
        name: 'validate',
        version: '2.2.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'checkmark',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['definition'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          definition: {
            type: 'string',
          },
          'json-schema': {
            type: 'string',
          },
          'xml-schema': {
            type: 'string',
          },
          'graphql-schema': {
            type: 'string',
          },
          'xml-validation-mode': {
            type: 'string',
            enum: ['xsd', 'soap-body', 'wsdl'],
          },
          input: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Validate'),
        description: translateFilter(
          'Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyschemaval_apigw.html'
        ),
        name: 'validate',
        version: '2.3.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'checkmark',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['definition'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          definition: {
            type: 'string',
          },
          'json-schema': {
            type: 'string',
          },
          'xml-schema': {
            type: 'string',
          },
          'graphql-schema': {
            type: 'string',
          },
          'xml-validation-mode': {
            type: 'string',
            enum: ['xsd', 'soap-body', 'wsdl'],
          },
          input: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
          'graphql-error-policy': {
            type: 'string',
            label: 'GraphQL Error Policy',
            description:
              'The behavior of the assembly validate action when the GraphQL backend server returns an error. "none" indicates that the assembly validate action stops and the error is returned. "ignore" indicates that the assembly validate action removes the error and validates the payload. "all" indicates that the assembly validate action returns the backend error and validates the payload.',
            default: 'all',
            enum: ['none', 'ignore', 'all'],
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('GatewayScript'),
        description: translateFilter(
          'Use the gatewayscript policy to execute a specified DataPower GatewayScript program.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policygatewayscript.html'
        ),
        name: 'gatewayscript',
        version: '1.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'data--2',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['source'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          source: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('GatewayScript'),
        description: translateFilter(
          'Use the gatewayscript policy to execute a specified DataPower GatewayScript program.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policygatewayscript.html'
        ),
        name: 'gatewayscript',
        version: '2.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'data--2',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['source'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          source: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('JavaScript'),
        description: translateFilter(
          'Use the javascript policy to execute a specified JavaScript program.'
        ),
        name: 'javascript',
        version: '1.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#FF5050',
          icon: 'clear_all',
        },
      },
      gateways: ['micro-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['source'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          source: {
            type: 'string',
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Set Variable'),
        description: translateFilter(
          'Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policysetvariable.html'
        ),
        name: 'set-variable',
        version: '1.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'calibrate',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['actions'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          actions: {
            type: 'array',
            items: {
              oneOf: [
                {
                  $ref: '#/definitions/set-variable-policy-set-action',
                },
                {
                  $ref: '#/definitions/set-variable-policy-add-action',
                },
                {
                  $ref: '#/definitions/set-variable-policy-clear-action',
                },
              ],
            },
          },
        },
        definitions: {
          'set-variable-policy-clear-action': {
            type: 'object',
            title: 'clear',
            additionalProperties: false,
            required: ['clear'],
            properties: {
              clear: {
                type: 'string',
              },
            },
          },
          'set-variable-policy-add-action': {
            type: 'object',
            title: 'add',
            additionalProperties: false,
            required: ['add', 'value'],
            properties: {
              add: {
                type: 'string',
              },
              value: {
                anyOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'number',
                  },
                  {
                    type: 'boolean',
                  },
                  {
                    type: 'integer',
                  },
                ],
              },
            },
          },
          'set-variable-policy-set-action': {
            type: 'object',
            title: 'set',
            additionalProperties: false,
            required: ['set', 'value'],
            properties: {
              set: {
                type: 'string',
              },
              value: {
                anyOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'number',
                  },
                  {
                    type: 'boolean',
                  },
                  {
                    type: 'integer',
                  },
                ],
              },
            },
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Set Variable'),
        description: translateFilter(
          'Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policysetvariable.html'
        ),
        name: 'set-variable',
        version: '2.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'calibrate',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['actions'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          actions: {
            type: 'array',
            items: {
              oneOf: [
                {
                  $ref: '#/definitions/set-variable-policy-set-action',
                },
                {
                  $ref: '#/definitions/set-variable-policy-add-action',
                },
                {
                  $ref: '#/definitions/set-variable-policy-clear-action',
                },
              ],
            },
          },
        },
        definitions: {
          'set-variable-policy-clear-action': {
            type: 'object',
            title: 'clear',
            additionalProperties: false,
            required: ['clear'],
            properties: {
              clear: {
                type: 'string',
              },
            },
          },
          'set-variable-policy-add-action': {
            type: 'object',
            title: 'add',
            additionalProperties: false,
            required: ['add', 'value'],
            properties: {
              add: {
                type: 'string',
              },
              type: {
                type: 'string',
                enum: ['string', 'number', 'boolean', 'any'],
                default: 'any',
              },
              value: {
                anyOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'number',
                  },
                  {
                    type: 'boolean',
                  },
                  {
                    type: 'integer',
                  },
                ],
              },
            },
          },
          'set-variable-policy-set-action': {
            type: 'object',
            title: 'set',
            additionalProperties: false,
            required: ['set', 'value'],
            properties: {
              set: {
                type: 'string',
              },
              type: {
                type: 'string',
                enum: ['string', 'number', 'boolean', 'any'],
                default: 'any',
              },
              value: {
                anyOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'number',
                  },
                  {
                    type: 'boolean',
                  },
                  {
                    type: 'integer',
                  },
                ],
              },
            },
          },
        },
      },
    })
    policies.push({
      info: {
        title: translateFilter('Validate JWT'),
        description: translateFilter('Validate JSON Web Token (JWT)'),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjwtval.html'
        ),
        name: 'jwt-validate',
        version: '1.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'document--tasks',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          jwt: {
            label: 'JSON Web Token (JWT)',
            description:
              'Context or runtime variable containing JWT to be validated',
            type: 'string',
            default: 'request.headers.authorization',
          },
          'output-claims': {
            label: 'Validated Claims variable name',
            description:
              'Runtime variable to which the set of claims returned from the JWT validation step will be assigned',
            type: 'string',
            default: 'decoded.claims',
          },
          'iss-claim': {
            label: 'Issuer Claim',
            description: 'PCRE used to validate the Issuer (iss) Claim',
            type: 'string',
          },
          'aud-claim': {
            label: 'Audience Claim',
            description: 'PCRE used to validate the Audience (aud) Claim',
            type: 'string',
          },
          'jwe-crypto': {
            label: 'Decrypt Crypto Object',
            description:
              'Specify the crypto object used to decrypt the message',
            type: 'string',
          },
          'jwe-jwk': {
            label: 'Decrypt Crypto JWK variable name',
            description:
              'Runtime variable containing JWK to be used to decrypt the JWT',
            type: 'string',
          },
          'jws-crypto': {
            label: 'Verify Crypto Object',
            description:
              'Specify the crypto object used to verify the signature',
            type: 'string',
          },
          'jws-jwk': {
            label: 'Verify Crypto JWK variable name',
            description:
              'Runtime variable containing JWK to be used to verify the signature',
            type: 'string',
          },
        },
        required: ['jwt'],
      },
    })

    policies.push({
      info: {
        title: translateFilter('Validate JWT'),
        description: translateFilter('Validate JSON Web Token (JWT)'),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjwtval.html'
        ),
        name: 'jwt-validate',
        version: '2.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'document--tasks',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          jwt: {
            label: 'JSON Web Token (JWT)',
            description:
              'Context or runtime variable containing JWT to be validated',
            type: 'string',
            default: 'request.headers.authorization',
          },
          'output-claims': {
            label: 'Validated Claims variable name',
            description:
              'Runtime variable to which the set of claims returned from the JWT validation step will be assigned',
            type: 'string',
            default: 'decoded.claims',
          },
          'iss-claim': {
            label: 'Issuer Claim',
            description: 'PCRE used to validate the Issuer (iss) Claim',
            type: 'string',
          },
          'aud-claim': {
            label: 'Audience Claim',
            description: 'PCRE used to validate the Audience (aud) Claim',
            type: 'string',
          },
          'jwe-crypto': {
            label: 'Decrypt Crypto Object',
            description:
              'Specify the crypto object used to decrypt the message',
            type: 'string',
          },
          'jwe-jwk': {
            label: 'Decrypt Crypto JWK variable name',
            description:
              'Runtime variable containing JWK to be used to decrypt the JWT',
            type: 'string',
          },
          'jws-crypto': {
            label: 'Verify Crypto Object',
            description:
              'Specify the crypto object used to verify the signature',
            type: 'string',
          },
          'jws-jwk': {
            label: 'Verify Crypto JWK variable name',
            description:
              'Runtime variable containing JWK to be used to verify the signature',
            type: 'string',
          },
        },
        required: ['jwt'],
      },
    })

    policies.push({
      info: {
        title: translateFilter('GraphQL'),
        description: '',
        name: 'GraphQL',
        version: '2.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#E535AB',
          icon: 'graphql',
          custom: true,
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          'populate-server-config': {
            type: 'boolean',
          },
          'calculate-query-characteristics': {
            type: 'boolean',
          },
          'enforce-policy': {
            type: 'boolean',
          },
          'invoke-graphql-server': {
            type: 'boolean',
          },
          'calculate-response-characteristics': {
            type: 'boolean',
          },
          'update-rate-limits': {
            type: 'boolean',
          },
        },
      },
      deprecated: true,
    })

    policies.push({
      info: {
        title: translateFilter('OAuth'),
        description: translateFilter(
          'Use the oauth policy to generate OAuth tokens.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyoauth.html'
        ),
        name: 'oauth',
        version: '2.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'password',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          'oauth-provider-settings-ref': {
            type: 'object',
            properties: {
              url: {
                type: 'string',
              },
              default: {
                type: 'string',
              },
              literal: {
                type: 'string',
              },
            },
          },
          'supported-oauth-components': {
            type: 'array',
            enum: [
              'OAuthValidateRequest',
              'OAuthGenerateAZCode',
              'OAuthVerifyAZCode',
              'OAuthVerifyRefreshToken',
              'OAuthCollectMetadata',
              'OAuthGenerateAccessToken',
              'OAuthIntrospectToken',
            ],
            default: [
              'OAuthValidateRequest',
              'OAuthGenerateAZCode',
              'OAuthVerifyAZCode',
              'OAuthVerifyRefreshToken',
              'OAuthCollectMetadata',
              'OAuthGenerateAccessToken',
              'OAuthIntrospectToken',
            ],
          },
        },
      },
    })

    policies.push({
      info: {
        title: translateFilter('User security'),
        description: translateFilter(
          "Use the user security policy to extract, authenticate, and authorize the user's identity."
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyusersecurity.html'
        ),
        name: 'user-security',
        version: '2.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'locked',
        },
      },
      required: ['ei-custom-form', 'az-custom-form'],
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          'factor-id': {
            type: 'string',
            default: 'default',
          },
          hostname: {
            type: 'string',
          },
          'query-parameters': {
            type: 'string',
          },
          'az-table-display-checkboxes': {
            type: 'boolean',
          },
          'az-table-dynamic-entries': {
            type: 'string',
            default: 'user.default.az.dynamic_entries',
          },
          'az-table-default-entry': {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                },
                description: {
                  type: 'string',
                },
              },
            },
          },
          'extract-identity-method': {
            type: 'string',
            enum: ['basic', 'context-var', 'disabled', 'html-form', 'redirect'],
            default: 'basic',
          },
          'ei-stop-on-error': {
            type: 'boolean',
            default: true,
          },
          'user-context-var': {
            type: 'string',
          },
          'pass-context-var': {
            type: 'string',
          },
          'redirect-url': {
            type: 'string',
          },
          'redirect-time-limit': {
            type: 'integer',
            default: 300,
          },
          'info-context-var': {
            type: 'string',
          },
          'ei-default-form': {
            type: 'boolean',
            default: true,
          },
          'ei-custom-form': {
            type: 'string',
          },
          'ei-custom-form-tls-client-profile': {
            type: 'string',
            oneOf: [
              {
                $ref: '#/definitions/tlsclientprofiletype',
              },
            ],
          },
          'ei-form-time-limit': {
            type: 'integer',
            default: 300,
          },
          'ei-custom-form-csp': {
            type: 'string',
            default: "default-src 'self'",
          },
          'user-auth-method': {
            type: 'string',
            enum: ['disabled', 'auth-url', 'ldap'],
            default: 'auth-url',
          },
          'au-stop-on-error': {
            type: 'boolean',
            default: true,
          },
          'auth-url': {
            type: 'string',
            default: 'https://example.com',
          },
          'auth-tls-client-profile': {
            type: 'string',
          },
          'auth-response-headers-pattern': {
            type: 'string',
            default: '(?i)x-api*',
          },
          'auth-response-header-credential': {
            type: 'string',
            default: 'X-API-Authenticated-Credential',
          },
          'ldap-registry': {
            type: 'string',
            oneOf: [
              {
                $ref: '#/definitions/userregistry',
              },
            ],
          },
          'user-az-method': {
            type: 'string',
            enum: ['disabled', 'authenticated', 'html-form'],
            default: 'authenticated',
          },
          'az-stop-on-error': {
            type: 'boolean',
            default: true,
          },
          'az-default-form': {
            type: 'string',
          },
          'az-custom-form': {
            type: 'string',
          },
          'az-custom-form-tls-client-profile': {
            type: 'string',
          },
          'az-form-time-limit': {
            type: 'integer',
            default: 300,
          },
          'az-custom-form-csp': {
            type: 'string',
            default: "default-src 'self'",
          },
          'host-context-var': {
            type: 'string',
          },
        },
      },
      deprecated: true,
    })

    policies.push({
      info: {
        title: translateFilter('User security'),
        description: translateFilter(
          "Use the user security policy to extract, authenticate, and authorize the user's identity."
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyusersecurity.html'
        ),
        name: 'user-security',
        version: '2.1.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'locked',
        },
      },
      required: ['ei-custom-form', 'az-custom-form'],
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          'factor-id': {
            type: 'string',
            default: 'default',
          },
          hostname: {
            type: 'string',
          },
          'query-parameters': {
            type: 'string',
          },
          'az-table-display-checkboxes': {
            type: 'boolean',
          },
          'az-table-dynamic-entries': {
            type: 'string',
            default: 'user.default.az.dynamic_entries',
          },
          'az-table-default-entry': {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                },
                description: {
                  type: 'string',
                },
              },
            },
          },
          'extract-identity-method': {
            type: 'string',
            enum: ['basic', 'context-var', 'disabled', 'html-form', 'redirect'],
            default: 'basic',
          },
          'ei-stop-on-error': {
            type: 'boolean',
            default: true,
          },
          'user-context-var': {
            type: 'string',
          },
          'pass-context-var': {
            type: 'string',
          },
          'redirect-url': {
            type: 'string',
          },
          'redirect-time-limit': {
            type: 'integer',
            default: 300,
          },
          'info-context-var': {
            type: 'string',
          },
          'ei-default-form': {
            type: 'boolean',
            default: true,
          },
          'ei-custom-form': {
            type: 'string',
          },
          'ei-custom-form-tls-client-profile': {
            type: 'string',
            oneOf: [
              {
                $ref: '#/definitions/tlsclientprofiletype',
              },
            ],
          },
          'ei-form-time-limit': {
            type: 'integer',
            default: 300,
          },
          'ei-custom-form-csp': {
            type: 'string',
            default: "default-src 'self'",
          },
          'user-auth-method': {
            type: 'string',
            enum: ['disabled', 'user-registry'],
            default: 'user-registry',
          },
          'au-stop-on-error': {
            type: 'boolean',
            default: true,
          },
          'user-registry': {
            type: 'string',
            default: '',
          },
          'auth-tls-client-profile': {
            type: 'string',
          },
          'auth-response-headers-pattern': {
            type: 'string',
            default: '(?i)x-api*',
          },
          'auth-response-header-credential': {
            type: 'string',
            default: 'X-API-Authenticated-Credential',
          },
          'user-az-method': {
            type: 'string',
            enum: ['disabled', 'authenticated', 'html-form'],
            default: 'authenticated',
          },
          'az-stop-on-error': {
            type: 'boolean',
            default: true,
          },
          'az-default-form': {
            type: 'string',
          },
          'az-custom-form': {
            type: 'string',
          },
          'az-custom-form-tls-client-profile': {
            type: 'string',
          },
          'az-form-time-limit': {
            type: 'integer',
            default: 300,
          },
          'az-custom-form-csp': {
            type: 'string',
            default: "default-src 'self'",
          },
          'host-context-var': {
            type: 'string',
          },
        },
      },
    })

    policies.push({
      info: {
        title: translateFilter('Generate JWT'),
        description: translateFilter('Generate JSON Web Token (JWT)'),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjwtgen.html'
        ),
        name: 'jwt-generate',
        version: '1.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'password',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          jwt: {
            label: 'JSON Web Token (JWT)',
            description:
              'Runtime variable in which to place the generated JWT. If not set JWT is placed in Authorization Header as Bearer Token.',
            type: 'string',
            default: 'generated.jwt',
          },
          'jti-claim': {
            label: 'JWT ID Claim',
            description:
              'Indicate whether a JWT ID (jti) claim should be included in JWT.  If selected, jti claim value will be a uuid',
            type: 'boolean',
          },
          'iss-claim': {
            label: 'Issuer Claim',
            description:
              'Runtime variable from which from which a string representing the Principal (iss) that issued the JWT can be retrieved',
            type: 'string',
            default: 'iss.claim',
          },
          'sub-claim': {
            label: 'Subject Claim',
            description:
              'Runtime variable from which a string representing the Subject (sub) Claim can be retrieved',
            type: 'string',
          },
          'aud-claim': {
            label: 'Audience Claim',
            description:
              'Runtime variable from which the Audience (aud) Claim string can be retrieved. Multiple are set via a comma separated string',
            type: 'string',
          },
          'exp-claim': {
            label: 'Validity Period',
            description:
              'The validity period (in seconds) for calculating the JWT expiration time (exp) claim of the JWT',
            type: 'integer',
            default: 3600,
          },
          'private-claims': {
            label: 'Private Claims',
            description:
              'Runtime variable from which a valid JSON set of claims can be retrieved',
            type: 'string',
          },
          'jws-jwk': {
            label: 'Sign JWK variable name',
            description:
              'Runtime variable containing JWK to be used to sign the JWT',
            type: 'string',
          },
          'jws-alg': {
            label: 'Cryptographic Algorithm',
            description: 'Select a cryptographic algorithm',
            type: 'string',
            enum: [
              'HS256',
              'HS384',
              'HS512',
              'RS256',
              'RS384',
              'RS512',
              'ES256',
              'ES384',
              'ES512',
              'PS256',
              'PS384',
              'PS512',
            ],
          },
          'jws-crypto': {
            label: 'Sign Crypto Object',
            description: 'Specify the crypto object used to sign the message',
            type: 'string',
          },
          'jwe-enc': {
            label: 'Encryption Algorithm',
            description: 'Select an encryption algorithm',
            type: 'string',
            enum: ['A128CBC-HS256', 'A192CBC-HS384', 'A256CBC-HS512'],
          },
          'jwe-jwk': {
            label: 'Encrypt JWK variable name',
            description:
              'Runtime variable containing JWK to be used to encrypt the JWT',
            type: 'string',
          },
          'jwe-alg': {
            label: 'Key Encryption Algorithm',
            description: 'Select a key encryption algorithm',
            type: 'string',
            enum: [
              'RSA1_5',
              'RSA-OAEP',
              'RSA-OAEP-256',
              'dir',
              'A128KW',
              'A192KW',
              'A256KW',
            ],
          },
          'jwe-crypto': {
            label: 'Encrypt Crypto Object',
            description:
              'Specify the crypto object used to encrypt the message',
            type: 'string',
          },
        },
        required: ['exp-claim', 'iss-claim'],
      },
    })

    policies.push({
      info: {
        title: translateFilter('Validate JWT'),
        description: translateFilter('Validate JSON Web Token (JWT)'),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjwtval.html'
        ),
        name: 'jwt-validate',
        version: '1.0.1',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'document--tasks',
        },
      },
      gateways: [],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          jwt: {
            label: 'JSON Web Token (JWT)',
            description:
              'Context or runtime variable containing JWT to be validated',
            type: 'string',
            default: 'request.headers.authorization',
          },
          'output-claims': {
            label: 'Validated Claims variable name',
            description:
              'Runtime variable to which the set of claims returned from the JWT validation step will be assigned',
            type: 'string',
            default: 'decoded.claims',
          },
          'iss-claim': {
            label: 'Issuer Claim',
            description: 'PCRE used to validate the Issuer (iss) Claim',
            type: 'string',
          },
          'aud-claim': {
            label: 'Audience Claim',
            description: 'PCRE used to validate the Audience (aud) Claim',
            type: 'string',
          },
          'jwe-obj': {
            label: 'Private Key or Shared Secret for Decrypt',
            description: 'Security object to use to decode the claim.',
            type: 'string',
          },
          'jws-obj': {
            label: 'Certificate or Shared Secret for Verify',
            description: 'Security object used to verify the claim.',
            type: 'string',
          },
        },
        required: ['jwt'],
      },
    })

    policies.push({
      info: {
        title: translateFilter('Generate JWT'),
        description: translateFilter('Generate JSON Web Token (JWT)'),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjwtval.html'
        ),
        name: 'jwt-generate',
        version: '2.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'password',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          jwt: {
            label: 'JSON Web Token (JWT)',
            description:
              'Runtime variable in which to place the generated JWT. If not set JWT is placed in Authorization Header as Bearer Token.',
            type: 'string',
            default: 'generated.jwt',
          },
          'jti-claim': {
            label: 'JWT ID Claim',
            description:
              'Indicate whether a JWT ID (jti) claim should be included in JWT.  If selected, jti claim value will be a uuid',
            type: 'boolean',
          },
          'iss-claim': {
            label: 'Issuer Claim',
            description:
              'Runtime variable from which from which a string representing the Principal (iss) that issued the JWT can be retrieved',
            type: 'string',
            default: 'iss.claim',
          },
          'sub-claim': {
            label: 'Subject Claim',
            description:
              'Runtime variable from which a string representing the Subject (sub) Claim can be retrieved',
            type: 'string',
          },
          'aud-claim': {
            label: 'Audience Claim',
            description:
              'Runtime variable from which the Audience (aud) Claim string can be retrieved. Multiple are set via a comma separated string',
            type: 'string',
          },
          'exp-claim': {
            label: 'Validity Period',
            description:
              'The validity period (in seconds) for calculating the JWT expiration time (exp) claim of the JWT',
            type: 'integer',
            default: 3600,
          },
          'private-claims': {
            label: 'Private Claims',
            description:
              'Runtime variable from which a valid JSON set of claims can be retrieved',
            type: 'string',
          },
          'jws-jwk': {
            label: 'Sign JWK variable name',
            description:
              'Runtime variable containing JWK to be used to sign the JWT',
            type: 'string',
          },
          'jws-alg': {
            label: 'Cryptographic Algorithm',
            description: 'Select a cryptographic algorithm',
            type: 'string',
            enum: [
              'HS256',
              'HS384',
              'HS512',
              'RS256',
              'RS384',
              'RS512',
              'ES256',
              'ES384',
              'ES512',
              'PS256',
              'PS384',
              'PS512',
            ],
          },
          'jws-crypto': {
            label: 'Sign Crypto Object',
            description: 'Specify the crypto object used to sign the message',
            type: 'string',
          },
          'jwe-enc': {
            label: 'Encryption Algorithm',
            description: 'Select an encryption algorithm',
            type: 'string',
            enum: ['A128CBC-HS256', 'A192CBC-HS384', 'A256CBC-HS512'],
          },
          'jwe-jwk': {
            label: 'Encrypt JWK variable name',
            description:
              'Runtime variable containing JWK to be used to encrypt the JWT',
            type: 'string',
          },
          'jwe-alg': {
            label: 'Key Encryption Algorithm',
            description: 'Select a key encryption algorithm',
            type: 'string',
            enum: [
              'RSA1_5',
              'RSA-OAEP',
              'RSA-OAEP-256',
              'dir',
              'A128KW',
              'A192KW',
              'A256KW',
            ],
          },
          'jwe-crypto': {
            label: 'Encrypt Crypto Object',
            description:
              'Specify the crypto object used to encrypt the message',
            type: 'string',
          },
        },
        required: ['exp-claim', 'iss-claim'],
      },
    })

    policies.push({
      info: {
        title: translateFilter('Parse'),
        description: translateFilter(
          'Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyparse.html'
        ),
        name: 'parse',
        version: '2.0.0',
        categories: [translateFilter('Transforms')],
        display: {
          color: '#4589ff',
          icon: 'data--reference',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'parse-settings-reference': {
            type: 'object',
            properties: {
              url: {
                type: 'string',
              },
              default: {
                type: 'string',
                default: 'apic-default-parsesettings',
              },
              literal: {
                type: 'string',
              },
            },
          },
          input: {
            type: 'string',
          },
          output: {
            type: 'string',
          },
        },
      },
    })

    policies.push({
      info: {
        title: translateFilter('Generate JWT'),
        description: translateFilter('Generate JSON Web Token (JWT)'),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyjwtgen.html'
        ),
        name: 'jwt-generate',
        version: '1.0.1',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'password',
        },
      },
      gateways: [],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          jwt: {
            label: 'JSON Web Token (JWT)',
            description:
              'Runtime variable in which to place the generated JWT. If not set JWT is placed in Authorization Header as Bearer Token.',
            type: 'string',
          },
          'jti-claim': {
            label: 'JWT ID Claim',
            description:
              'Indicate whether a JWT ID (jti) claim should be included in JWT.  If selected, jti claim value will be a uuid',
            type: 'boolean',
          },
          'iss-claim': {
            label: 'Issuer Claim',
            description:
              'Runtime variable from which from which a string representing the Principal (iss) that issued the JWT can be retrieved',
            type: 'string',
            default: 'iss.claim',
          },
          'sub-claim': {
            label: 'Subject Claim',
            description:
              'Runtime variable from which a string representing the Subject (sub) Claim can be retrieved',
            type: 'string',
          },
          'aud-claim': {
            label: 'Audience Claim',
            description:
              'Runtime variable from which the Audience (aud) Claim string can be retrieved. Multiple are set via a comma separated string',
            type: 'string',
          },
          'exp-claim': {
            label: 'Validity Period',
            description:
              'The validity period (in seconds) for calculating the JWT expiration time (exp) claim of the JWT',
            type: 'integer',
            default: 3600,
          },
          'private-claims': {
            label: 'Private Claims',
            description:
              'Runtime variable from which a valid JSON set of claims can be retrieved',
            type: 'string',
          },
          'jws-obj': {
            label: 'Private Key or Shared Secret for Sign',
            description: 'Security object used to sign the JWT.',
            type: 'string',
          },
          'jws-alg': {
            label: 'Cryptographic Algorithm',
            description: 'Select a cryptographic algorithm',
            type: 'string',
            enum: [
              'HS256',
              'HS384',
              'HS512',
              'RS256',
              'RS384',
              'RS512',
              'ES256',
              'ES384',
              'ES512',
            ],
          },
          'jwe-enc': {
            label: 'Encryption Algorithm',
            description: 'Select an encryption algorithm',
            type: 'string',
            enum: ['A128CBC-HS256', 'A192CBC-HS384', 'A256CBC-HS512'],
          },
          'jwe-obj': {
            label: 'Public Key or Shared Secret for Encrypt',
            description: 'Security object used to encrypt the JWT.',
            type: 'string',
          },
          'jwe-alg': {
            label: 'Key Encryption Algorithm',
            description: 'Select a key encryption algorithm',
            type: 'string',
            enum: [
              'RSA1_5',
              'RSA-OAEP',
              'RSA-OAEP-256',
              'dir',
              'A128KW',
              'A192KW',
              'A256KW',
            ],
          },
        },
        required: ['exp-claim', 'iss-claim'],
      },
    })

    policies.push({
      info: {
        title: translateFilter('Validate Username Token'),
        description: translateFilter(
          'Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyvalusername.html'
        ),
        name: 'validate-usernametoken',
        version: '1.0.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'document--tasks',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'auth-type': {
            label: 'Authentication Type',
            description: 'Select how username token should be authenticated.',
            type: 'string',
            enum: ['Authentication URL', 'LDAP Registry'],
            default: 'Authentication URL',
          },
          'auth-url': {
            label: 'Authentication URL Definition',
            description:
              'Select authentication URL definition to use for validation.',
            type: 'string',
          },
          'tls-profile': {
            label: 'TLS Profile',
            description:
              'The TLS Profile to use for the secure transmission of data.',
            type: 'string',
          },
          'ldap-user-registry': {
            label: 'User Registry Definition',
            description: 'Select user registry definition to validate with.',
            type: 'string',
          },
        },
        required: ['auth-type'],
      },
      deprecated: true,
    })

    policies.push({
      info: {
        title: translateFilter('Validate Username Token'),
        description: translateFilter(
          'Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyvalusername.html'
        ),
        name: 'validate-usernametoken',
        version: '1.1.0',
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'document--tasks',
        },
      },
      gateways: ['datapower-gateway'],
      properties: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          registry: {
            type: 'string',
          },
          'ldap-search-attribute': {
            type: 'string',
          },
        },
        required: ['registry'],
      },
    })

    // Disabling ltpa-generate velox/ui#16143
    // policies.push({
    //   policy: '1.0.0',
    //   info: {
    //     title: translateFilter('Generate LTPA Token'),
    //     description: translateFilter('Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.'),
    //     name: 'ltpa-generate',
    //     version: '1.0.0',
    //     contact: {
    //       name: 'IBM API Management',
    //       email: 'ibmapi@us.ibm.com',
    //       url: 'http://www.ibm.com/apimanagement'
    //     },
    //     categories: [translateFilter('Security')],
    //     display: {
    //       color: '#ee5396',
    //       icon: 'adjust'
    //     }
    //   },
    //   attach: [
    //     'rest',
    //     'soap'
    //   ],
    //   properties: {
    //     '$schema': 'http://json-schema.org/draft-04/schema#',
    //     type: 'object',
    //     properties: {
    //       title: {
    //         type: 'string'
    //       },
    //       description: {
    //         type: 'string'
    //       },
    //       version: {
    //         type: 'string'
    //       },
    //       authenticatedUserName: {
    //         label: '${messages:ltpagenerate.properties.authenticatedusername.label}',
    //         description: '${messages:ltpagenerate.properties.authenticatedusername.description}',
    //         type: 'string'
    //       },
    //       tokenVersion: {
    //         label: '${messages:ltpagenerate.properties.tokenversion.label}',
    //         description: '${messages:ltpagenerate.properties.tokenversion.description}',
    //         enum: [
    //           'WebSphereVersion1',
    //           'WebSphereVersion1-FIPS',
    //           'WebSphereVersion2',
    //           'WebSphere70Version2'
    //         ],
    //         default: 'WebSphereVersion2',
    //         type: 'string'
    //       },
    //       tokenExpiry: {
    //         label: '${messages:ltpagenerate.properties.tokenexpiry.label}',
    //         description: '${messages:ltpagenerate.properties.tokenexpiry.description}',
    //         default: 600,
    //         type: 'integer'
    //       },
    //       key: {
    //         label: '${messages:ltpagenerate.properties.key.label}',
    //         description: '${messages:ltpagenerate.properties.key.description}',
    //         type: 'string'
    //       },
    //       tokenOutput: {
    //         label: '${messages:ltpagenerate.properties.tokenoutput.label}',
    //         description: '${messages:ltpagenerate.properties.tokenoutput.description}',
    //         enum: [
    //           'in-cookie-header',
    //           'in-wssec-header'
    //         ],
    //         default: 'in-cookie-header',
    //         type: 'string'
    //       }
    //     },
    //     required: [
    //       'authenticatedUserName',
    //       'tokenVersion',
    //       'tokenExpiry',
    //       'key',
    //       'tokenOutput'
    //     ]
    //   },
    //   gateways: [],
    //   errors: [
    //     'LTPAGenerateError'
    //   ]
    // });

    policies.push({
      info: {
        title: translateFilter('Client security'),
        name: 'client-security',
        version: '2.0.0',
        description: translateFilter(
          'Use the client security policy to extract and authenticate the clients credentials.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyclientsecurity.html'
        ),
        categories: [translateFilter('Security')],
        display: {
          color: '#ee5396',
          icon: 'locked',
        },
        contact: {
          name: 'IBM API Management',
          email: 'ibmapi@us.ibm.com',
          url: 'http://www.ibm.com/apimanagement',
        },
      },
      gateways: ['datapower-api-gateway'],
      attach: ['rest'],
      properties: {
        type: 'object',
        properties: {
          'stop-on-error': {
            type: 'boolean',
            default: true,
          },
          'secret-required': {
            type: 'boolean',
            default: true,
          },
          'extract-credential-method': {
            type: 'string',
            enum: ['header', 'query', 'form', 'cookie', 'http', 'context-var'],
            default: 'header',
          },
          'id-name': {
            type: 'string',
          },
          'secret-name': {
            type: 'string',
          },
          'http-type': {
            type: 'string',
            enum: ['basic'],
          },
          'client-auth-method': {
            type: 'string',
            enum: ['native', 'third-party'],
            default: 'native',
          },
          'user-registry': {
            type: 'string',
            oneOf: [
              {
                $ref: '#/definitions/userregistry',
              },
            ],
            default: '',
          },
        },
      },
      definitions: {
        userregistry: {},
      },
    })

    policies.push({
      info: {
        title: translateFilter('GraphQL introspect'),
        description: translateFilter(
          'Use the graphql-introspect policy to introspect a GraphQL schema.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policygraphqlintrospect.html'
        ),
        categories: [translateFilter('Policies')],
        name: 'graphql-introspect',
        version: '2.0.0',
        display: {
          color: '#009D9A',
          icon: 'graphql',
          custom: true,
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          input: {
            type: 'string',
            default: 'message',
          },
          output: {
            type: 'string',
          },
        },
      },
    })

    policies.push({
      info: {
        title: translateFilter('Invoke'),
        description: translateFilter(
          'Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyinvoke.html'
        ),
        name: 'invoke',
        version: '2.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'rss',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['target-url'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'target-url': {
            label: 'Invoke URL',
            description: 'The Invoke URL to be used.',
            type: 'string',
          },
          'backend-type': {
            label: 'Backend type',
            description: '',
            type: 'string',
            enum: ['graphql', 'json', 'xml', 'binary', 'detect'],
            default: 'detect',
          },
          'header-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'blocklist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'parameter-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'allowlist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'follow-redirects': {
            type: 'boolean',
          },
          'secure-gateway': {
            label: 'Access URL through Secure Gateway',
            description:
              'Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.',
            type: 'boolean',
          },
          'tls-profile': {
            label: 'SSL Profile',
            description: 'The SSL Profile to use for the request',
            type: 'string',
          },
          timeout: {
            label: 'Timeout',
            description:
              'Time to wait before a reply back from the endpoint. Default is 60 seconds.',
            type: 'integer',
            default: 60,
          },
          username: {
            label: 'Username',
            description: 'The username to use for HTTP Basic authentication.',
            type: 'string',
          },
          password: {
            label: 'Password',
            description: 'The password to use for HTTP Basic authentication.',
            type: 'string',
          },
          verb: {
            label: 'HTTP Method',
            description: 'HTTP Method to use for the Invoke.',
            type: 'string',
            enum: [
              'keep',
              'GET',
              'POST',
              'PUT',
              'DELETE',
              'PATCH',
              'HEAD',
              'OPTIONS',
            ],
            default: 'keep',
          },
          compression: {
            label: 'Compression',
            description:
              'Used to enable/disable Content-Encoding compression on upload.',
            type: 'boolean',
          },
          'use-http-10': {
            label: 'Restrict to HTTP 1.0',
            description: 'Restrict HTTP transactions to version 1.0.',
            type: 'boolean',
            default: false,
          },
          'inject-proxy-headers': {
            label: 'Inject proxy headers',
            description:
              'When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.',
            type: 'boolean',
          },
          'decode-request-params': {
            label: 'Decode Request Params',
            description:
              'If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.',
            type: 'boolean',
          },
          'encode-plus-char': {
            label: 'Queryparam Encode',
            description:
              'If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2F.',
            type: 'boolean',
          },
          'keep-payload': {
            label: 'Keep Payload',
            description:
              'If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.',
            type: 'boolean',
          },
          'chunked-uploads': {
            label: 'Allow chunked uploads',
            description: 'Send chunked-encoded documents to the target server.',
            type: 'boolean',
            default: true,
          },
          'persistent-connection': {
            label: 'Persistent Connection',
            description:
              'Whether to reuse the connection to the target server.',
            type: 'boolean',
            default: true,
          },
          'cache-response': {
            label: 'Cache Type',
            description:
              "The cache type determines whether to cache documents and the mechanism to use to remove cached entries. The default is 'no-cache'.",
            type: 'string',
            enum: ['protocol', 'time-to-live', 'no-cache'],
            default: 'protocol',
          },
          'cache-ttl': {
            label: 'Cache Time-to-Live',
            description:
              "Sets the validity period in seconds for documents in the cache. TTL applies to only the 'time-to-live' policy type. Enter a value in the range 5 - 31708800. The default value is 900.",
            type: 'integer',
            default: 900,
            minimum: 5,
            maximum: 31708800,
          },
          'cache-key': {
            label: 'Cache Key',
            description:
              'The cache key to use to identify the cache item. If this is not set the target-url will be used.',
            type: 'string',
          },
          'stop-on-error': {
            label: 'Stop on error',
            description: 'Used to enable/disable stop-on-error semantics.',
            type: 'array',
            items: {
              type: 'string',
            },
          },
          output: {
            label: 'Response object variable',
            description:
              "A name of a variable that will be used to store the response data from the request. This can then be used in other actions like 'Map' etc.",
            type: 'string',
          },
        },
      },
    })

    policies.push({
      info: {
        title: translateFilter('GraphQL Websocket Upgrade'),
        description: translateFilter(
          'Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.'
        ),
        docLink: docsService(''),
        name: 'websocket-upgrade',
        version: '2.0.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'rss',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['target-url'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'target-url': {
            label: 'Invoke URL',
            description: 'The Invoke URL to be used.',
            type: 'string',
          },
          'header-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'blocklist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'parameter-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'allowlist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'follow-redirects': {
            type: 'boolean',
          },
          'secure-gateway': {
            label: 'Access URL through Secure Gateway',
            description:
              'Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.',
            type: 'boolean',
          },
          'tls-profile': {
            label: 'SSL Profile',
            description: 'The SSL Profile to use for the request',
            type: 'string',
          },
          timeout: {
            label: 'Timeout',
            description:
              'Time to wait before a reply back from the endpoint. Default is 60 seconds.',
            type: 'integer',
            default: 60,
          },
          username: {
            label: 'Username',
            description: 'The username to use for HTTP Basic authentication.',
            type: 'string',
          },
          password: {
            label: 'Password',
            description: 'The password to use for HTTP Basic authentication.',
            type: 'string',
          },
          'inject-proxy-headers': {
            label: 'Inject proxy headers',
            description:
              'When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.',
            type: 'boolean',
          },
          'decode-request-params': {
            label: 'Decode Request Params',
            description:
              'If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.',
            type: 'boolean',
          },
          'encode-plus-char': {
            label: 'Queryparam Encode',
            description:
              'If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2F.',
            type: 'boolean',
          },
        },
      },
    })

    policies.push({
      info: {
        title: translateFilter('Invoke'),
        description: translateFilter(
          'Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyinvoke.html'
        ),
        name: 'invoke',
        version: '2.1.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'rss',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['target-url'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'target-url': {
            label: 'Invoke URL',
            description: 'The Invoke URL to be used.',
            type: 'string',
          },
          'backend-type': {
            label: 'Backend type',
            description: '',
            type: 'string',
            enum: ['graphql', 'json', 'xml', 'binary', 'detect'],
            default: 'detect',
          },
          'header-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'blocklist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'websocket-upgrade': {
            type: 'boolean',
          },
          'parameter-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'allowlist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'http-version': {
            type: 'string',
            enum: ['HTTP/1.0', 'HTTP/1.1', 'HTTP/2.0'],
            default: 'HTTP/1.1',
          },
          'http2-required': {
            type: 'boolean',
            default: false,
          },
          'follow-redirects': {
            type: 'boolean',
          },
          'secure-gateway': {
            label: 'Access URL through Secure Gateway',
            description:
              'Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.',
            type: 'boolean',
          },
          'tls-profile': {
            label: 'SSL Profile',
            description: 'The SSL Profile to use for the request',
            type: 'string',
          },
          timeout: {
            label: 'Timeout',
            description:
              'Time to wait before a reply back from the endpoint. Default is 60 seconds.',
            type: 'integer',
            default: 60,
          },
          username: {
            label: 'Username',
            description: 'The username to use for HTTP Basic authentication.',
            type: 'string',
          },
          password: {
            label: 'Password',
            description: 'The password to use for HTTP Basic authentication.',
            type: 'string',
          },
          verb: {
            label: 'HTTP Method',
            description: 'HTTP Method to use for the Invoke.',
            type: 'string',
            enum: [
              'keep',
              'GET',
              'POST',
              'PUT',
              'DELETE',
              'PATCH',
              'HEAD',
              'OPTIONS',
            ],
            default: 'keep',
          },
          compression: {
            label: 'Compression',
            description:
              'Used to enable/disable Content-Encoding compression on upload.',
            type: 'boolean',
          },
          'inject-proxy-headers': {
            label: 'Inject proxy headers',
            description:
              'When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.',
            type: 'boolean',
          },
          'decode-request-params': {
            label: 'Decode Request Params',
            description:
              'If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.',
            type: 'boolean',
          },
          'encode-plus-char': {
            label: 'Queryparam Encode',
            description:
              'If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2F.',
            type: 'boolean',
          },
          'keep-payload': {
            label: 'Keep Payload',
            description:
              'If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.',
            type: 'boolean',
          },
          'chunked-uploads': {
            label: 'Allow chunked uploads',
            description: 'Send chunked-encoded documents to the target server.',
            type: 'boolean',
            default: true,
          },
          'persistent-connection': {
            label: 'Persistent Connection',
            description:
              'Whether to reuse the connection to the target server.',
            type: 'boolean',
            default: true,
          },
          'cache-response': {
            label: 'Cache Type',
            description:
              "The cache type determines whether to cache documents and the mechanism to use to remove cached entries. The default is 'no-cache'.",
            type: 'string',
            enum: ['protocol', 'time-to-live', 'no-cache'],
            default: 'protocol',
          },
          'cache-ttl': {
            label: 'Cache Time-to-Live',
            description:
              "Sets the validity period in seconds for documents in the cache. TTL applies to only the 'time-to-live' policy type. Enter a value in the range 5 - 31708800. The default value is 900.",
            type: 'integer',
            default: 900,
            minimum: 5,
            maximum: 31708800,
          },
          'cache-key': {
            label: 'Cache Key',
            description:
              'The cache key to use to identify the cache item. If this is not set the target-url will be used.',
            type: 'string',
          },
          'stop-on-error': {
            label: 'Stop on error',
            description: 'Used to enable/disable stop-on-error semantics.',
            type: 'array',
            items: {
              type: 'string',
            },
          },
          output: {
            label: 'Response object variable',
            description:
              "A name of a variable that will be used to store the response data from the request. This can then be used in other actions like 'Map' etc.",
            type: 'string',
          },
        },
      },
    })

    policies.push({
      info: {
        title: translateFilter('Invoke'),
        description: translateFilter(
          'Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.'
        ),
        docLink: docsService(
          'com.ibm.apic.toolkit.doc/rapim_ref_ootb_policyinvoke.html'
        ),
        name: 'invoke',
        version: '2.2.0',
        categories: [translateFilter('Policies')],
        display: {
          color: '#009d9a',
          icon: 'rss',
        },
      },
      gateways: ['datapower-api-gateway'],
      properties: {
        type: 'object',
        additionalProperties: false,
        required: ['target-url'],
        properties: {
          title: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          'target-url': {
            label: 'Invoke URL',
            description: 'The Invoke URL to be used.',
            type: 'string',
          },
          'backend-type': {
            label: 'Backend type',
            description: '',
            type: 'string',
            enum: ['graphql', 'json', 'xml', 'binary', 'detect'],
            default: 'detect',
          },
          'header-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'blocklist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'websocket-upgrade': {
            type: 'boolean',
          },
          'parameter-control': {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: ['blocklist', 'allowlist'],
                default: 'allowlist',
              },
              values: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
          'http-version': {
            type: 'string',
            enum: ['HTTP/1.0', 'HTTP/1.1', 'HTTP/2.0'],
            default: 'HTTP/1.1',
          },
          'http2-required': {
            type: 'boolean',
            default: false,
          },
          'follow-redirects': {
            type: 'boolean',
          },
          'secure-gateway': {
            label: 'Access URL through Secure Gateway',
            description:
              'Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.',
            type: 'boolean',
          },
          'tls-profile': {
            label: 'SSL Profile',
            description: 'The SSL Profile to use for the request',
            type: 'string',
          },
          timeout: {
            label: 'Timeout',
            description:
              'Time to wait before a reply back from the endpoint. Default is 60 seconds.',
            type: 'integer',
            default: 60,
          },
          username: {
            label: 'Username',
            description: 'The username to use for HTTP Basic authentication.',
            type: 'string',
          },
          password: {
            label: 'Password',
            description: 'The password to use for HTTP Basic authentication.',
            type: 'string',
          },
          verb: {
            label: 'HTTP Method',
            description: 'HTTP Method to use for the Invoke.',
            type: 'string',
            enum: [
              'keep',
              'GET',
              'POST',
              'PUT',
              'DELETE',
              'PATCH',
              'HEAD',
              'OPTIONS',
            ],
            default: 'keep',
          },
          compression: {
            label: 'Compression',
            description:
              'Used to enable/disable Content-Encoding compression on upload.',
            type: 'boolean',
          },
          'inject-proxy-headers': {
            label: 'Inject proxy headers',
            description:
              'When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.',
            type: 'boolean',
          },
          'decode-request-params': {
            label: 'Decode Request Params',
            description:
              'If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.',
            type: 'boolean',
          },
          'encode-plus-char': {
            label: 'Queryparam Encode',
            description:
              'If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2F.',
            type: 'boolean',
          },
          'keep-payload': {
            label: 'Keep Payload',
            description:
              'If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.',
            type: 'boolean',
          },
          'chunked-uploads': {
            label: 'Allow chunked uploads',
            description: 'Send chunked-encoded documents to the target server.',
            type: 'boolean',
            default: true,
          },
          'persistent-connection': {
            label: 'Persistent Connection',
            description:
              'Whether to reuse the connection to the target server.',
            type: 'boolean',
            default: true,
          },
          'cache-response': {
            label: 'Cache Type',
            description:
              "The cache type determines whether to cache documents and the mechanism to use to remove cached entries. The default is 'no-cache'.",
            type: 'string',
            enum: ['protocol', 'time-to-live', 'no-cache'],
            default: 'protocol',
          },
          'cache-ttl': {
            label: 'Cache Time-to-Live',
            description:
              "Sets the validity period in seconds for documents in the cache. TTL applies to only the 'time-to-live' policy type. Enter a value in the range 5 - 31708800. The default value is 900.",
            type: 'integer',
            default: 900,
            minimum: 5,
            maximum: 31708800,
          },
          'cache-key': {
            label: 'Cache Key',
            description:
              'The cache key to use to identify the cache item. If this is not set the target-url will be used.',
            type: 'string',
          },
          'stop-on-error': {
            label: 'Stop on error',
            description: 'Used to enable/disable stop-on-error semantics.',
            type: 'array',
            items: {
              type: 'string',
            },
          },
          output: {
            label: 'Response object variable',
            description:
              "A name of a variable that will be used to store the response data from the request. This can then be used in other actions like 'Map' etc.",
            type: 'string',
          },
          'graphql-send-type': {
            label: 'GraphQL Send Type',
            description:
              'The format type to send the GraphQL payload as, when applicable.',
            type: 'string',
            enum: ['detect', 'graphql', 'json'],
            default: 'detect',
          },
        },
      },
    })

    const policyPartials = {
      'map:1.0.0': require('../../html/policies/mapper.html'),
      'map:2.0.0': require('../../html/policies/mapper-2.0.0.html'),
      'set-variable:1.0.0': require('../../html/policies/set-variable.html'),
      'set-variable:2.0.0': require('../../html/policies/set-variable-2.0.0.html'),
      'if:1.0.0': require('../../html/policies/if.html'),
      'if:1.5.0': require('../../html/policies/if.html'),
      'throw:1.0.0': require('../../html/policies/throw.html'),
      'throw:2.0.0': require('../../html/policies/throw-2.0.0.html'),
      'throw:2.1.0': require('../../html/policies/throw-2.1.0.html'),
      'redact:1.0.0': require('../../html/policies/redact.html'),
      'redact:1.5.0': require('../../html/policies/redact.html'),
      'redact:2.0.0': require('../../html/policies/new-redact.html'),
      'switch:1.0.0': require('../../html/policies/switch.html'),
      'switch:1.5.0': require('../../html/policies/switch.html'),
      'switch:2.0.0': require('../../html/policies/new-switch.html'),
      'client-security:2.0.0': require('../../html/policies/client-security-2.0.0.html'),
      'operation-switch:1.0.0': require('../../html/policies/operation-switch.html'),
      'operation-switch:2.0.0': require('../../html/policies/operation-switch.html'),
      'validate:1.0.0': require('../../html/policies/validate.html'),
      'validate:2.0.0': require('../../html/policies/validate-2.0.0.html'),
      'validate:2.1.0': require('../../html/policies/validate-2.0.0.html'),
      'validate:2.2.0': require('../../html/policies/validate-2.0.0.html'),
      'validate:2.3.0': require('../../html/policies/validate-2.3.0.html'),
      'websocket-upgrade:2.0.0': require('../../html/policies/websocket-upgrade-2.0.0.html'),
      'activity-log:1.0.0': require('../../html/policies/activity-log.html'),
      'activity-log:1.5.0': require('../../html/policies/activity-log.html'),
      'log:2.0.0': require('../../html/policies/log.html'),
      'log:2.1.0': require('../../html/policies/log-2.1.0.html'),
      'invoke:1.0.0': require('../../html/policies/invoke.html'),
      'invoke:1.5.0': require('../../html/policies/invoke-1.5.0.html'),
      'invoke:2.0.0': require('../../html/policies/invoke-2.0.0.html'),
      'invoke:2.1.0': require('../../html/policies/invoke-2.1.0.html'),
      'invoke:2.2.0': require('../../html/policies/invoke-2.2.0.html'),
      'ratelimit:2.0.0': require('../../html/policies/rate-limit-2.0.0.html'),
      'ratelimit:2.1.0': require('../../html/policies/rate-limit-2.1.0.html'),
      'ratelimit:2.2.0': require('../../html/policies/rate-limit-2.1.0.html'),
      'proxy:1.0.0': require('../../html/policies/invoke.html'),
      'proxy:1.5.0': require('../../html/policies/invoke-1.5.0.html'),
      'xml-to-json:1.0.0': require('../../html/policies/xml2json.html'),
      'xml-to-json:2.0.0': require('../../html/policies/xml2json-2.0.0.html'),
      'json-to-xml:1.0.0': require('../../html/policies/json2xml.html'),
      'json-to-xml:2.0.0': require('../../html/policies/json2xml-2.0.0.html'),
      'catch:1.0.0': require('../../html/policies/catch.html'),
      'catch:2.0.0': require('../../html/policies/catch.html'),
      'gatewayscript:1.0.0': require('../../html/policies/gatewayscript.html'),
      'gatewayscript:2.0.0': require('../../html/policies/gatewayscript.html'),
      'javascript:1.0.0': require('../../html/policies/gatewayscript.html'),
      'xslt:1.0.0': require('../../html/policies/transform-xslt.html'),
      'xslt:2.0.0': require('../../html/policies/transform-xslt-2.0.0.html'),
      'jwt-generate:1.0.0': require('../../html/policies/jwt-generate-1.0.0.html'),
      'jwt-generate:1.0.1': require('../../html/policies/jwt-generate-1.0.1.html'),
      'jwt-generate:2.0.0': require('../../html/policies/jwt-generate-2.0.0.html'),
      'jwt-validate:1.0.0': require('../../html/policies/jwt-validate-1.0.0.html'),
      'oauth:2.0.0': require('../../html/policies/oauth.html'),
      'user-security:2.0.0': require('../../html/policies/user-security.html'),
      'user-security:2.1.0': require('../../html/policies/user-security.html'),
      'jwt-validate:2.0.0': require('../../html/policies/jwt-validate-2.0.0.html'),
      'jwt-validate:1.0.1': require('../../html/policies/jwt-validate-1.0.1.html'),
      'oauth-generate:1.0.0': require('../../html/policies/oauth-generate.html'),
      'GraphQL:2.0.0': require('../../html/policies/GraphQL.html'),
      'validate-usernametoken:1.0.0': require('../../html/policies/validate-usernametoken.html'),
      'validate-usernametoken:1.1.0': require('../../html/policies/validate-usernametoken-1.1.0.html'),
      // 'ltpa-generate:1.0.0': require('../../html/policies/ltpa-generate.html'),
      'encrypt:1.0.0': require('../../html/policies/encrypt.html'),
      'decrypt:1.0.0': require('../../html/policies/decrypt.html'),
      'assembly:1.0.0': require('../../html/policies/assembly.html'),
      'pre-invoke-hook:1.0.0': require('../../html/policies/pre-invoke-hook.html'),
      'post-invoke-hook:1.0.0': require('../../html/policies/post-invoke-hook.html'),
      'handlebars:1.0.0': require('../../html/policies/handlebars.html'),
      'html-page:2.0.0': require('../../html/policies/html-page.html'),
      'parse:2.0.0': require('../../html/policies/parse.html'),
      'graphql-introspect:2.0.0': require('../../html/policies/graphql-introspect.html'),
      custom: require('../../html/policies/generator.html'),
    }

    const legacyPolicies = [
      'gatewayscript:1.0.0',
      'invoke:1.0.0',
      'invoke:1.5.0',
      'json-to-xml:1.0.0',
      'jwt-generate:1.0.0',
      'jwt-validate:1.0.0',
      'activity-log:1.0.0',
      'activity-log:1.5.0',
      'map:1.0.0',
      'proxy:1.0.0',
      'proxy:1.5.0',
      'redact:1.0.0',
      'redact:1.5.0',
      'validate:1.0.0',
      'set-variable:1.0.0',
      'switch:1.0.0',
      'switch:1.5.0',
      'if:1.5.0',
      'xslt:1.0.0',
      'validate-usernametoken:1.0.0',
      'validate-usernametoken:1.1.0',
      'xml-to-json:1.0.0',
    ]

    const getPolicyKey = function(policy) {
      const policyVersion = policy.info.version || '1.0.0'
      const policyName = [policy.info.name, policyVersion].join(':')

      if (
        legacyPolicies.indexOf(policyName) !== -1 &&
        policy.gateways[0] === 'datapower-api-gateway'
      ) {
        return `${policyName}:custom`
      }
      return policyName
    }

    const policyKeys = policies.map(getPolicyKey)
    const isCustomPolicy = function(policy) {
      return !policyKeys.some(p => {
        return getPolicyKey(policy) === p
      })
    }

    return {
      getPolicies(gatewayPolicies = []) {
        return $q(function(resolve) {
          const allPolicies = policies.concat(
            gatewayPolicies.filter(isCustomPolicy).map(policy => {
              if (policy.info.name === 'switch' || policy.info.name === 'if') {
                return {
                  ...policy,
                  info: {
                    ...policy.info,
                    display: {
                      color: '#878d96',
                      icon: 'choices',
                    },
                  },
                  custom: true,
                }
              }
              return {
                ...policy,
                info: {
                  ...policy.info,
                  display: {
                    color: '#A56EFF',
                    icon: 'bee',
                  },
                },
                custom: true,
              }
            })
          )

          resolve(allPolicies)
        })
      },
      getPolicyPartial(policyName, version) {
        let key = policyName

        if (!version || !policyPartials[`${key}:${version}`]) {
          $rootScope.$$childHead &&
          $rootScope.$$childHead.api &&
          $rootScope.$$childHead.api['x-ibm-configuration'] &&
          $rootScope.$$childHead.api['x-ibm-configuration'].gateway ===
            'datapower-api-gateway'
            ? (version = '2.0.0')
            : (version = '1.0.0')
        }

        key += `:${version}`
        const customPartial = policyPartials[key] || policyPartials[policyName]
        return customPartial ? customPartial : policyPartials.custom
      },
    }
  },
])
