// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

'use strict'

angular.module('apiconnect-assembly').factory('Expressions', function() {
  function isVerbExpression(expression) {
    // request.verb==='GET'
    if (expression.type !== 'BinaryExpression') return false
    if (expression.left.type !== 'MemberExpression') return false
    if (expression.left.object.name !== 'request') return false
    if (expression.left.property.name !== 'verb') return false
    if (expression.right.type !== 'Literal') return false
    return true
  }
  function isPathExpression(expression) {
    // api.operation.path==='/path'
    if (expression.type !== 'BinaryExpression') return false
    if (expression.operator !== '==' && expression.operator !== '===')
      return false
    if (expression.left.type !== 'MemberExpression') return false
    if (expression.left.object.type !== 'MemberExpression') return false
    if (expression.left.object.object.name !== 'api') return false
    if (expression.left.object.property.name !== 'operation') return false
    if (expression.left.property.name !== 'path') return false
    if (expression.right.type !== 'Literal') return false
    return true
  }
  function isOperationExpression(expression) {
    if (expression.type !== 'LogicalExpression') return false
    if (expression.operator !== '&&') return false
    // one of them must be a verb expression
    if (
      !isVerbExpression(expression.left) &&
      !isVerbExpression(expression.right)
    )
      return false
    // one of them must be a path expression
    if (
      !isPathExpression(expression.left) &&
      !isPathExpression(expression.right)
    )
      return false
    return true
  }
  function isOperationIdExpression(expression) {
    // api.operation.id==='opId'
    if (expression.type !== 'BinaryExpression') return false
    if (expression.operator !== '==' && expression.operator !== '===')
      return false
    if (expression.left.type !== 'MemberExpression') return false
    if (expression.left.object.type !== 'MemberExpression') return false
    if (expression.left.object.object.name !== 'api') return false
    if (expression.left.object.property.name !== 'operation') return false
    if (expression.left.property.name !== 'id') return false
    if (expression.right.type !== 'Literal') return false
    return true
  }
  function getOperationString(expression) {
    if (!isOperationExpression(expression)) return ''
    if (
      ['get', 'put', 'post', 'delete', 'options', 'patch', 'head'].indexOf(
        expression.left.right.value.toLowerCase()
      ) >= 0
    ) {
      return `${expression.left.right.value} ${expression.right.right.value}`
    }
    return `${expression.right.right.value} ${expression.left.right.value}`
  }
  function getOperationObject(expression) {
    if (
      !expression ||
      !expression.left ||
      !expression.left.right ||
      !expression.right ||
      !expression.right.right
    )
      return {}
    if (
      ['get', 'put', 'post', 'delete', 'options', 'patch', 'head'].indexOf(
        expression.left.right.value.toLowerCase()
      ) >= 0
    ) {
      return {
        verb: expression.left.right.value,
        path: expression.right.right.value,
        $$expressionString: window.jseb(expression),
      }
    }
    return {
      verb: expression.right.right.value,
      path: expression.left.right.value,
      $$expressionString: window.jseb(expression),
    }
  }
  function getOperationIdString(expression) {
    if (!isOperationIdExpression(expression)) return ''
    return expression.right.value
  }
  function getOperationIdObject(expression) {
    if (!expression || !expression.right) return {}
    return {
      operationId: expression.right.value,
      $$expressionString: window.jseb(expression),
    }
  }
  function knownExpression(expression) {
    if (isOperationExpression(expression)) return true
    if (isOperationIdExpression(expression)) return true
    return false
  }
  function knownExpressionString(expression) {
    if (isOperationExpression(expression)) return getOperationString(expression)
    if (isOperationIdExpression(expression))
      return getOperationIdString(expression)
  }
  function knownExpressionObject(expression) {
    if (isOperationExpression(expression)) return getOperationObject(expression)
    if (isOperationIdExpression(expression))
      return getOperationIdObject(expression)
  }
  function highLevelExpression(expression) {
    if (knownExpression(expression)) return true
    if (!expression.left || !highLevelExpression(expression.left)) return false
    if (!expression.right || !highLevelExpression(expression.right))
      return false
    return true
  }
  function highLevelExpressionString(expression) {
    const asString = knownExpressionString(expression)
    if (asString) return asString
    if (expression.left && expression.right) {
      const leftString = highLevelExpressionString(expression.left)
      if (!leftString) return
      const rightString = highLevelExpressionString(expression.right)
      if (!rightString) return
      return `${leftString} or ${rightString}`
    }
  }
  function expressionAsChipsHelper(expression, chips) {
    const asObj = knownExpressionObject(expression)
    if (asObj) {
      chips.push(asObj)
      return chips
    }
    if (expression.left) expressionAsChipsHelper(expression.left, chips)
    if (expression.right) expressionAsChipsHelper(expression.right, chips)
    return chips
  }
  function expressionAsChips(expression) {
    return expressionAsChipsHelper(expression, [])
  }
  function chipsAsExpressionString(chips, v6) {
    if (!chips || chips.length === 0) return ''
    let expressionString = `(${chips[0].$$expressionString || chips[0]})`
    for (let i = 1; i < chips.length; i++) {
      expressionString += `${v6 ? ' or (' : '||('}${chips[i]
        .$$expressionString || chips[i]})`
    }
    return expressionString
  }
  function expressionAsString(expression) {
    return highLevelExpressionString(expression) || window.jseb(expression)
  }
  return {
    isOperationExpression,
    isOperationIdExpression,
    getOperationString,
    getOperationIdString,
    highLevelExpression,
    expressionAsString,
    expressionAsChips,
    chipsAsExpressionString,
  }
})
