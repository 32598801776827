// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'
import _ from 'lodash'

function t(original) {
  return original
}

function AssemblerInfoController($scope, SchemaFormOptions) {
  $scope.minorTab = 'configure'
  SchemaFormOptions.setOptions({
    ajax: true,
    theme: 'bootstrap3',
    show_errors: 'change',
    no_additional_properties: true,
    disable_edit_json: true,
    required_by_default: true,
  })

  $scope.aceLoaded = function() {
    window.e.setOption('enableBasicAutocompletion', false)
    window.e.setOption('enableLiveAutocompletion', false)
  }

  const modelChanged = function(model, updateInScope) {
    if (updateInScope) {
      $scope.model = model
    }
    angular.extend($scope.selectedNode, model)
    // clear out any properties present in the selected node which aren't present in the new node
    Object.keys($scope.selectedNode).forEach(function(key) {
      if (key.indexOf('$$') !== 0) {
        if (
          $scope.selectedNode.hasOwnProperty(key) &&
          !model.hasOwnProperty(key)
        ) {
          delete $scope.selectedNode[key]
        }
      }
    })
  }

  $scope.policyAsYaml = function(newYaml) {
    if (arguments.length) {
      // Setter
      modelChanged(jsyaml.load(newYaml), true)
    } else {
      // Getter
      const cleanObject = JSON.parse(angular.toJson($scope.selectedNode))
      if (_.isEmpty(cleanObject)) return ''
      return jsyaml.dump(cleanObject, {lineWidth: -1})
    }
  }

  $scope.formChanged = _.debounce(modelChanged, 200)
  $scope.aceChanged = _.debounce(modelChanged, 200)

  let currentHash
  // deep watch the object
  $scope.$watch(
    'selectedNode',
    function() {
      if (!$scope.selectedNode) {
        return
      }

      // remove anything explicitly set to null
      Object.keys($scope.selectedNode).forEach(function(key) {
        if (
          $scope.selectedNode.hasOwnProperty(key) &&
          $scope.selectedNode[key] === null
        ) {
          delete $scope.selectedNode[key]
        }
      })
      if (!currentHash || currentHash !== $scope.selectedNode.$$hashKey) {
        currentHash = $scope.selectedNode.$$hashKey
        $scope.schema = $scope.selectedNode.$$schema
        $scope.model = angular.copy($scope.selectedNode)
      }
    },
    true
  )
}

angular
  .module('apiconnect-assembly')
  .controller('AssemblerInfoController', [
    '$scope',
    'SchemaFormOptions',
    AssemblerInfoController,
  ])

function OperationSwitchController($scope) {
  const self = this
  self.addCase = function() {
    $scope.selectedNode.case.push({operations: [], execute: []})
  }
  self.removeCase = function(index) {
    $scope.selectedNode.case.splice(index, 1)
  }
}

angular
  .module('apiconnect-assembly')
  .controller('OperationSwitchController', [
    '$scope',
    OperationSwitchController,
  ])

function OperationCaseController($scope) {
  if (!$scope.case.operations) $scope.case.operations = []
  if (!$scope.case.execute) $scope.case.execute = []
  const self = this
  self.filter = function() {
    const search = this.searchText.toLowerCase()
    const swaggerLegacyOperations = $scope.$parent.swaggerLegacyOperations

    return swaggerLegacyOperations.filter(function(operation) {
      if (typeof operation === 'string') {
        if (operation.toLowerCase().indexOf(search) === -1) return false
      } else {
        if (
          operation.verb.toLowerCase().indexOf(search) === -1 &&
          operation.path.toLowerCase().indexOf(search) === -1
        )
          return false
      }
      // we match, so return true only if the operation isn't used elsewhere already
      for (let i = 0; i < $scope.selectedNode.case.length; i++) {
        for (
          let j = 0;
          j < $scope.selectedNode.case[i].operations.length;
          j++
        ) {
          const usedOperation = $scope.selectedNode.case[i].operations[j]
          if (typeof operation === 'string' && operation === usedOperation)
            return false
          if (
            typeof operation === 'object' &&
            operation.verb === usedOperation.verb &&
            operation.path === usedOperation.path
          )
            return false
        }
      }
      return true
    })
  }

  self.disableScroll = function() {
    $('.infoView').css('overflow-y', 'hidden')
  }
}

angular
  .module('apiconnect-assembly')
  .controller('OperationCaseController', ['$scope', OperationCaseController])

function SwitchController($scope) {
  const self = this
  self.addCase = function() {
    const newCase = {
      condition: $scope.selectedNode.version === '2.0.0' ? 'true' : '',
      execute: [],
    }
    if ($scope.hasOtherwise()) {
      $scope.selectedNode.case.splice(
        $scope.selectedNode.case.length - 1,
        0,
        newCase
      )
    } else {
      $scope.selectedNode.case.push(newCase)
    }
  }
  self.removeCase = function(index) {
    $scope.selectedNode.case.splice(index, 1)
  }
  self.moveUp = function(index) {
    if (index <= 0) return
    const newCases = []
    $scope.selectedNode.case.forEach(function(thisCase, currentIndex) {
      if (currentIndex === index) return
      if (currentIndex === index - 1) {
        newCases.push($scope.selectedNode.case[index])
        newCases.push(thisCase)
      } else {
        newCases.push(thisCase)
      }
    })
    $scope.selectedNode.case = newCases
  }
  self.moveDown = function(index) {
    if (index >= $scope.selectedNode.case.length - 1) return
    const newCases = []
    $scope.selectedNode.case.forEach(function(thisCase, currentIndex) {
      if (currentIndex === index) return
      if (currentIndex === index + 1) {
        newCases.push(thisCase)
        newCases.push($scope.selectedNode.case[index])
      } else {
        newCases.push(thisCase)
      }
    })
    $scope.selectedNode.case = newCases
  }
  $scope.hasOtherwise = function() {
    return (
      $scope.selectedNode.case[$scope.selectedNode.case.length - 1] &&
      $scope.selectedNode.case[$scope.selectedNode.case.length - 1]
        .otherwise !== undefined
    )
  }
  self.addOtherwise = function() {
    $scope.selectedNode.case.push({otherwise: []})
  }
}

angular
  .module('apiconnect-assembly')
  .controller('SwitchController', ['$scope', SwitchController])

function CaseController($scope, $rootScope, $mdDialog, Expressions) {
  const self = this

  if (!$scope.case.otherwise && !$scope.case.execute) {
    $scope.case.execute = []
  }

  self.getOperations = function() {
    let scope = $scope

    // TODO(jtary) - fix the controller setup to get rid of this hack
    while (scope && scope.$parent && scope.$parent !== scope) {
      if (scope.swaggerOperations) {
        return scope.swaggerOperations
      }

      scope = scope.$parent
    }

    return []
  }

  self.filter = function() {
    const operations = this.getOperations()

    if (!$scope.chips || $scope.chips.length === 0) {
      return operations
    }

    const search = this.searchText.toLowerCase()
    return operations.filter(function(operation) {
      if (
        operation.operationId &&
        operation.operationId.toLowerCase().indexOf(search) === -1
      )
        return false
      if (
        operation.verb &&
        operation.path &&
        operation.verb.toLowerCase().indexOf(search) === -1 &&
        operation.path.toLowerCase().indexOf(search) === -1
      )
        return false
      // we match, so return true only if the operation isn't used elsewhere already
      for (let i = 0; i < $scope.chips.length; i++) {
        if (
          operation.verb &&
          $scope.chips[i].verb &&
          $scope.chips[i].verb.toLowerCase() === operation.verb.toLowerCase() &&
          operation.path &&
          $scope.chips[i].path &&
          $scope.chips[i].path === operation.path
        )
          return false
        if (
          operation.operationId &&
          $scope.chips[i].operationId === operation.operationId
        )
          return false
      }
      return true
    })
  }
  self.canMoveUp = function($index) {
    if ($scope.case.otherwise) return false
    return $index > 0
  }
  self.canMoveDown = function($index) {
    if ($scope.case.otherwise) return false
    if ($scope.hasOtherwise())
      return $index < $scope.selectedNode.case.length - 2
    return $index < $scope.selectedNode.case.length - 1
  }
  self.convertToExpression = function() {
    // empty case
    let expression
    if (
      !$scope.case.condition.operations ||
      $scope.case.condition.operations.length === 0
    ) {
      delete $scope.case.condition.operations
      $scope.case.condition.expression = '(function() {\n  return false;\n})();'
      return
    }
    $scope.case.condition.operations.forEach(function(operation) {
      if (typeof operation === 'string') {
        expression += `$(operation.operationId) === '${operation}' || `
      } else {
        expression += `($(operation.verb) === '${operation.verb}' && $(operation.path) === '${operation.path}') || `
      }
    })
    expression = `${expression.substring(0, expression.length - 4)});\n})();`
    delete $scope.case.condition.operations
    $scope.case.condition.expression = expression
  }
  self.launchExpressionEditor = function() {
    $mdDialog
      .show({
        template: require('../../html/expression-editor.html'),
        controller: 'ConditionEditorController',
        locals: {
          expression: $scope.case.condition,
        },
      })
      .then(function(response) {
        $scope.case.condition = response
      })
  }

  self.launchNewConditionEditor = function() {
    $mdDialog
      .show({
        template: require('../../html/new-condition.html'),
        controller: 'MainController',
        locals: {
          expression: $scope.case.condition,
          swaggerDocument: $scope.swaggerDocument,
        },
      })
      .then(function(response) {
        $scope.case.condition = response
      })
  }

  if (!$scope.case.otherwise) {
    $scope.$watch('case.condition', function() {
      if ($scope.case.condition && $scope.case.condition !== '') {
        $scope.expression = window.jsep($scope.case.condition)
        $scope.highLevelExpression = Expressions.highLevelExpression(
          $scope.expression
        )
        if ($scope.highLevelExpression)
          $scope.chips = Expressions.expressionAsChips($scope.expression)
      } else {
        $scope.highLevelExpression = true
        $scope.chips = []
      }
    })

    $scope.$watchCollection('chips', function() {
      if (!$scope.highLevelExpression) return
      if (!$scope.chips || $scope.chips.length === 0) {
        $scope.case.condition = ''
      } else {
        const v6 =
          $scope.swaggerDocument['x-ibm-configuration'] &&
          $scope.swaggerDocument['x-ibm-configuration'].gateway ===
            'datapower-api-gateway'
        const result = Expressions.chipsAsExpressionString($scope.chips, v6)
        $scope.case.condition = result
      }
    })
  }

  self.disableScroll = function() {
    $('.infoView').css('overflow-y', 'hidden')
  }
}

angular
  .module('apiconnect-assembly')
  .controller('CaseController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    'Expressions',
    CaseController,
  ])

function ConditionEditorController($scope, $mdDialog, expression) {
  $scope.expressionString = expression
  $scope.$on('condition-change', function($event, expression) {
    $scope.expressionString = expression
  })

  $scope.commit = function() {
    $mdDialog.hide($scope.expressionString)
  }

  $scope.cancel = function() {
    $mdDialog.cancel()
  }
}

angular
  .module('apiconnect-assembly')
  .controller('ConditionEditorController', [
    '$scope',
    '$mdDialog',
    'expression',
    ConditionEditorController,
  ])

function CatchController($scope) {
  const self = this

  self.addCatch = function() {
    if (!$scope.selectedNode.catch) $scope.selectedNode.catch = []
    let insertIndex = $scope.selectedNode.catch.length
    if (self.hasDefault()) insertIndex--
    $scope.selectedNode.catch.splice(insertIndex, 0, {
      errors: [],
      execute: [],
    })
  }
  self.hasDefault = function() {
    const lastCatch = $scope.selectedNode.catch.length - 1
    if (lastCatch < 0) return false
    return $scope.selectedNode.catch[lastCatch].default !== undefined
  }
  self.addCatchDefault = function() {
    if (self.hasDefault()) return
    $scope.selectedNode.catch.push({default: []})
  }
  self.removeCatch = function(index) {
    $scope.selectedNode.catch.splice(index, 1)
  }
  self.moveUp = function(index) {
    if (index <= 0) return
    const newCatches = []
    $scope.selectedNode.catch.forEach(function(thisCatch, currentIndex) {
      if (currentIndex === index) return
      if (currentIndex === index - 1) {
        newCatches.push($scope.selectedNode.catch[index])
        newCatches.push(thisCatch)
      } else {
        newCatches.push(thisCatch)
      }
    })
    $scope.selectedNode.catch = newCatches
  }
  self.moveDown = function(index) {
    if (index >= $scope.selectedNode.catch.length - 1) return
    const newCatches = []
    $scope.selectedNode.catch.forEach(function(thisCatch, currentIndex) {
      if (currentIndex === index) return
      if (currentIndex === index + 1) {
        newCatches.push(thisCatch)
        newCatches.push($scope.selectedNode.catch[index])
      } else {
        newCatches.push(thisCatch)
      }
    })
    $scope.selectedNode.catch = newCatches
  }
  self.canMoveUp = function($index) {
    if ($scope.selectedNode.catch[$index].default !== undefined) return false
    return $index > 0
  }
  self.canMoveDown = function($index) {
    if ($scope.selectedNode.catch[$index].default !== undefined) return false
    if (self.hasDefault()) return $index < $scope.selectedNode.catch.length - 2
    return $index < $scope.selectedNode.catch.length - 1
  }
}

angular
  .module('apiconnect-assembly')
  .controller('CatchController', ['$scope', CatchController])

function ErrorFilterController($scope) {
  const self = this
  self.errors = {
    ConnectionError: true,
    SOAPError: true,
    OperationError: true,
  }

  $scope.selectedNode.$$stopOnError =
    $scope.selectedNode['stop-on-error'] !== undefined

  $scope.$watch('selectedNode.$$stopOnError', function() {
    if ($scope.selectedNode.$$stopOnError) {
      // NOTE @sanchit: This behaviour is V5 parity (if the first item in the array is null, reset to empty an array)
      // Ref: https://github.ibm.com/velox/ui/issues/16411 for design of new behavior
      if (
        !Array.isArray($scope.selectedNode['stop-on-error']) ||
        (Array.isArray($scope.selectedNode['stop-on-error']) &&
          $scope.selectedNode['stop-on-error'][0] === null)
      ) {
        $scope.selectedNode['stop-on-error'] = []
      }
    } else {
      delete $scope.selectedNode['stop-on-error']
    }
  })

  self.filter = function() {
    const search = this.searchText.toLowerCase()
    return Object.keys(self.errors).filter(function(error) {
      if (error.toLowerCase().indexOf(search) === -1) return false
      if (!$scope.selectedNode['stop-on-error']) return true
      return $scope.selectedNode['stop-on-error'].indexOf(error) < 0
    })
  }

  self.disableScroll = function() {
    $('.infoView').css('overflow-y', 'hidden')
  }
}

angular
  .module('apiconnect-assembly')
  .controller('ErrorFilterController', ['$scope', ErrorFilterController])

function CatchBlockController($scope) {
  const self = this
  self.errors = {
    ConnectionError: true,
    JavaScriptError: true,
    PropertyError: true,
    RedactionError: true,
    TransformError: true,
    RuntimeError: true,
    BadRequestError: true,
    SOAPError: true,
    OperationError: true,
    ValidateError: true,
    AssemblyRateLimitError: true,
  }

  // bring in any errors defined on the policies themselves
  if ($scope.policies) {
    $scope.policies.forEach(function(policy) {
      if (policy.errors) {
        policy.errors.forEach(function(error) {
          self.errors[error] = true
        })
      }
    })
  }

  let unwrapAndFlattenNodes

  var flattenNodes = function(nodeArray, flattenedModel) {
    nodeArray.forEach(function(node) {
      if (node.$$type === 'throw' && node.name) flattenedModel[node.name] = true
      if (node.execute) unwrapAndFlattenNodes(node.execute, flattenedModel)
      if (node.otherwise) unwrapAndFlattenNodes(node.otherwise, flattenedModel)
      if (node.case) flattenNodes(node.case, flattenedModel)
    })
  }

  unwrapAndFlattenNodes = function(nodeArray, flattenedModel) {
    nodeArray.forEach(function(node) {
      let policyInstance = null
      Object.keys(node).forEach(function(policyName) {
        policyInstance = node[policyName]
        if (policyInstance.$$type === 'throw' && policyInstance.name)
          flattenedModel[policyInstance.name] = true
        if (policyInstance.execute)
          unwrapAndFlattenNodes(policyInstance.execute, flattenedModel)
        if (policyInstance.case)
          flattenNodes(policyInstance.case, flattenedModel)
      })
    })
  }

  unwrapAndFlattenNodes($scope.nodes, self.errors)

  if (!$scope.catch.default && !$scope.catch.errors) $scope.catch.errors = []
  if (!$scope.catch.default && !$scope.catch.execute) $scope.catch.execute = []

  self.filter = function() {
    const search = this.searchText.toLowerCase()
    return Object.keys(self.errors).filter(function(error) {
      if (error.toLowerCase().indexOf(search) === -1) return false
      // we match, so return true only if the operation isn't used elsewhere already
      for (let i = 0; i < $scope.selectedNode.catch.length; i++) {
        if ($scope.selectedNode.catch[i].default) continue
        for (let j = 0; j < $scope.selectedNode.catch[i].errors.length; j++) {
          const usedError = $scope.selectedNode.catch[i].errors[j]
          if (error === usedError) return false
        }
      }
      return true
    })
  }
  self.isDefault = function() {
    return $scope.catch.default !== undefined
  }

  self.disableScroll = function() {
    $('.infoView').css('overflow-y', 'hidden')
  }
}

angular
  .module('apiconnect-assembly')
  .controller('CatchBlockController', ['$scope', CatchBlockController])

function ValidationDefinitionController($scope) {
  $scope.t = t
  const isV6Gw =
    _.get($scope.swaggerDocument, ['x-ibm-configuration', 'gateway'], '') ===
    'datapower-api-gateway'
  const api_type = _.get(
    $scope.swaggerDocument,
    ['x-ibm-configuration', 'type'],
    ''
  )

  const definitions = [
    {
      name: 'request',
    },
    {
      name: 'response',
    },
  ]

  const definitionsV6 = [
    {
      name: t('You must choose a definition'),
      value: undefined,
    },
  ]

  $scope.validations = [
    {
      name: 'definition',
      label: 'Definition',
    },
    {
      name: 'url',
      label: 'URL',
    },
    {
      name: 'body-param',
      label: 'body-param',
    },
    {
      name: 'response-param',
      label: 'response-param',
    },
  ]

  if (api_type === 'wsdl-to-rest' || api_type === 'wsdl')
    $scope.validations.push({
      name: 'wsdl',
      label: 'WSDL',
    })
  if (api_type === 'graphql')
    $scope.validations.push({
      name: 'graphql',
      label: 'GraphQL',
    })

  $scope.xmlModeOptions = [
    {
      name: 'None',
      value: undefined,
    },
    {
      name: 'xsd',
      value: 'xsd',
    },
    {
      name: 'wsdl',
      value: 'wsdl',
    },
    {
      name: 'soap-body',
      value: 'soap-body',
    },
  ]

  if (
    ($scope.selectedNode.version === '2.0.0' && !$scope.selectedNode.input) ||
    $scope.selectedNode.input === ''
  ) {
    $scope.selectedNode.input = 'message'
  }

  // OAI V2
  if ($scope.swaggerDocument.definitions) {
    Object.keys($scope.swaggerDocument.definitions).forEach(function(
      definition
    ) {
      definitions.push({name: `#/definitions/${definition}`})
      definitionsV6.push({
        name: `#/definitions/${definition}`,
        value: `#/definitions/${definition}`,
      })
    })
  }
  // OAI V3
  if (
    $scope.swaggerDocument.components &&
    $scope.swaggerDocument.components.schemas
  ) {
    Object.keys($scope.swaggerDocument.components.schemas).forEach(function(
      definition
    ) {
      definitions.push({name: `#/components/schemas/${definition}`})
      definitionsV6.push({
        name: `#/components/schemas/${definition}`,
        value: `#/components/schemas/${definition}`,
      })
    })
  }
  $scope.$watch('selectedNode["validate-against"]', function() {
    if (!isV6Gw) return
    if ($scope.selectedNode['validate-against'] === 'definition') {
      delete $scope.selectedNode['json-schema']
      delete $scope.selectedNode['xml-schema']
      delete $scope.selectedNode['graphql-schema']
      delete $scope.selectedNode['xml-validation-mode']
    } else if ($scope.selectedNode['validate-against'] === 'url') {
      delete $scope.selectedNode.definition
    } else {
      delete $scope.selectedNode.definition
      delete $scope.selectedNode['json-schema']
      delete $scope.selectedNode['graphql-schema']
      delete $scope.selectedNode['xml-schema']
      delete $scope.selectedNode['xml-validation-mode']
    }
  })

  $scope.$watch('selectedNode["xml-validation-mode"]', function() {
    if (!isV6Gw) return
    if (!$scope.selectedNode['xml-validation-mode']) {
      delete $scope.selectedNode['xml-schema']
      delete $scope.selectedNode['xml-validation-mode']
    }
  })

  $scope.$watch('selectedNode["definition"]', function() {
    if (!$scope.selectedNode.definition) {
      delete $scope.selectedNode.definition
    }
  })

  $scope.definitions = definitions
  $scope.definitionsV6 = definitionsV6
}

angular
  .module('apiconnect-assembly')
  .controller('ValidationDefinitionController', [
    '$scope',
    ValidationDefinitionController,
  ])

function TLSProfileController($rootScope, $scope) {
  const tlsProfiles = [{name: '(none)'}]
  let tlsProfile = $scope.selectedNode['tls-profile']
  let customTlsProfile = typeof tlsProfile !== 'undefined'

  if (!$rootScope.offlineMode) {
    $rootScope.tlsProfiles &&
      $rootScope.tlsProfiles.forEach(function(profile) {
        const profileValue = `${profile.name}:${profile.version}`
        customTlsProfile = customTlsProfile && profileValue !== tlsProfile
        tlsProfiles.push({
          name: `${profile.title}:${profile.version}`,
          value: `${profile.name}:${profile.version}`,
        })
      })
  }

  if (customTlsProfile) {
    tlsProfiles.push({name: tlsProfile, value: tlsProfile})
  }

  this.tlsProfiles = tlsProfiles

  $scope.$watch("selectedNode['secure-gateway']", function() {
    if ($scope.selectedNode['secure-gateway']) {
      $scope.tlsStyle = {color: '#e0e0e0'}
      tlsProfile = $scope.selectedNode['tls-profile']
      delete $scope.selectedNode['tls-profile']
    } else {
      $scope.selectedNode['tls-profile'] = tlsProfile
      $scope.tlsStyle = {}
    }
  })
}

angular
  .module('apiconnect-assembly')
  .controller('TLSProfileController', [
    '$rootScope',
    '$scope',
    TLSProfileController,
  ])

function ClientSecurityController($rootScope, $scope) {
  $scope.selectedNode['extract-credential-method'] =
    $scope.selectedNode['extract-credential-method'] || 'header'
  $scope.selectedNode['client-auth-method'] =
    $scope.selectedNode['client-auth-method'] || 'native'
  $scope.selectedNode['stop-on-error'] === undefined
    ? ($scope.selectedNode['stop-on-error'] = true)
    : $scope.selectedNode['stop-on-error']
  $scope.selectedNode['secret-required'] === undefined
    ? ($scope.selectedNode['secret-required'] = true)
    : $scope.selectedNode['secret-required']
  $scope.$watch("selectedNode['extract-credential-method']", function(newVal) {
    if (newVal === 'http') {
      $scope.selectedNode['http-type'] =
        $scope.selectedNode['http-type'] || 'basic'
      if ($scope.selectedNode['id-name']) {
        delete $scope.selectedNode['id-name']
      }
      if ($scope.selectedNode['secret-name']) {
        delete $scope.selectedNode['secret-name']
      }
    } else {
      delete $scope.selectedNode['http-type']
    }
  })
  $scope.$watch("selectedNode['client-auth-method']", function(newVal) {
    if (newVal === 'native') {
      delete $scope.selectedNode['user-registry']
    }
  })
}

angular
  .module('apiconnect-assembly')
  .controller('ClientSecurityController', [
    '$rootScope',
    '$scope',
    ClientSecurityController,
  ])

function InvokeController($rootScope, $scope) {
  const self = this
  $scope.selectedNode['header-control'] = $scope.selectedNode[
    'header-control'
  ] || {
    type: 'blocklist',
    values: [],
  }
  $scope.selectedNode['parameter-control'] = $scope.selectedNode[
    'parameter-control'
  ] || {
    type: 'allowlist',
    values: [],
  }

  if ($scope.selectedNode['header-control'].type === 'blacklist') {
    $scope.selectedNode['header-control'].type = 'blocklist'
  } else if ($scope.selectedNode['header-control'].type === 'whitelist') {
    $scope.selectedNode['header-control'].type = 'allowlist'
  }

  if ($scope.selectedNode['parameter-control'].type === 'blacklist') {
    $scope.selectedNode['parameter-control'].type = 'blocklist'
  } else if ($scope.selectedNode['parameter-control'].type === 'whitelist') {
    $scope.selectedNode['parameter-control'].type = 'allowlist'
  }

  self.removeFromList = function(index, values) {
    values.splice(index, 1)
  }
  self.addToList = function(values) {
    values.push('')
  }
  $scope.tempHeaderList = {
    allowlist: [],
    blocklist: [],
  }
  $scope.$watch(
    "selectedNode['header-control'].type",
    function(newVal, oldVal) {
      if (newVal !== oldVal) {
        $scope.tempHeaderList[oldVal] =
          $scope.selectedNode['header-control'].values
        $scope.selectedNode['header-control'].values =
          $scope.tempHeaderList[newVal]
      }
    }
  )
  $scope.tempParameterList = {
    allowlist: [],
    blocklist: [],
  }
  $scope.$watch(
    "selectedNode['parameter-control'].type",
    function(newVal, oldVal) {
      if (newVal !== oldVal) {
        $scope.tempParameterList[oldVal] =
          $scope.selectedNode['parameter-control'].values
        $scope.selectedNode['parameter-control'].values =
          $scope.tempParameterList[newVal]
      }
    }
  )
  $scope.$watch("selectedNode['http-version']", function(newVal, oldVal) {
    if (!newVal) return
    if (newVal === 'HTTP/1.1') {
      if (typeof $scope.selectedNode['chunked-uploads'] === 'undefined')
        $scope.selectedNode['chunked-uploads'] = true
      if (typeof $scope.selectedNode['websocket-upgrade'] === 'undefined')
        $scope.selectedNode['websocket-upgrade'] = false
    } else {
      delete $scope.selectedNode['chunked-uploads']
      delete $scope.selectedNode['websocket-upgrade']
    }
    if (newVal === 'HTTP/2') {
      if (typeof $scope.selectedNode['http2-required'] === 'undefined')
        $scope.selectedNode['http2-required'] = false
    } else {
      delete $scope.selectedNode['http2-required']
    }
  })

  const cleanGraphqlSendType = () => {
    if (
      !['graphql', 'detect'].includes($scope.selectedNode['backend-type']) ||
      !['keep', 'POST'].includes($scope.selectedNode['verb'])
    ) {
      delete $scope.selectedNode['graphql-send-type']
    }
    if (
      ['graphql', 'detect'].includes($scope.selectedNode['backend-type']) &&
      ['keep', 'POST'].includes($scope.selectedNode['verb']) &&
      !$scope.selectedNode['graphql-send-type']
    ) {
      $scope.selectedNode['graphql-send-type'] = 'detect'
    }
  }

  $scope.$watch("selectedNode['backend-type']", function() {
    cleanGraphqlSendType()
  })

  $scope.$watch("selectedNode['verb']", function() {
    cleanGraphqlSendType()
  })
}

angular
  .module('apiconnect-assembly')
  .controller('InvokeController', ['$rootScope', '$scope', InvokeController])

function UserRegistryController($rootScope, $scope) {
  $scope.t = t
  const userRegistries = []
  if (!$rootScope.offlineMode) {
    $rootScope.userRegistries &&
      $rootScope.userRegistries.forEach(function(registry) {
        userRegistries.push({
          name: registry.title,
          value: registry.name,
          registry_type: registry.registry_type,
        })
      })
  }
  this.userRegistries = userRegistries
  $scope.$watch('selectedNode.registry', function(newVal, oldVal) {
    const selectedUserReg =
      userRegistries.find(d => {
        return d.name === newVal
      }) || {}
    if (
      selectedUserReg.registry_type === 'ldap' ||
      $scope.selectedNode['auth-type'] === 'LDAP Registry'
    ) {
      $scope.isLdap = true
    } else {
      delete $scope.selectedNode['ldap-search-attribute']
      $scope.isLdap = false
    }
  })

  $scope.userRegFilter = function(userReg) {
    return (
      userReg.registry_type === 'authurl' || userReg.registry_type === 'ldap'
    )
  }
}

angular
  .module('apiconnect-assembly')
  .controller('UserRegistryController', [
    '$rootScope',
    '$scope',
    UserRegistryController,
  ])

function SetVariableController($scope) {
  $scope.t = t
  const self = this
  self.addAction = function() {
    $scope.selectedNode.actions.push({
      set: '',
      value: '',
    })
  }
  self.removeAction = function($index) {
    $scope.selectedNode.actions.splice($index, 1)
  }
}

angular
  .module('apiconnect-assembly')
  .controller('SetVariableController', ['$scope', SetVariableController])

function GraphQLController($scope) {
  $scope.t = t
}

angular
  .module('apiconnect-assembly')
  .controller('GraphQLController', ['$scope', GraphQLController])

function SetVariableActionController($scope) {
  const self = this
  $scope.valueType = 'string'
  if ($scope.action.type === undefined) {
    if (typeof $scope.action.value === 'boolean') {
      $scope.valueType = 'boolean'
    } else if (typeof $scope.action.value === 'number') {
      $scope.valueType = 'number'
    }
  }
  if ($scope.action.add) {
    $scope.actionValue = 'add'
  } else if ($scope.action.clear) {
    $scope.actionValue = 'clear'
  } else {
    $scope.actionValue = 'set'
  }
  let firstWatch = false
  $scope.$watch('actionValue', function() {
    if (!firstWatch) {
      firstWatch = true
      return
    }
    // no value field for a clear
    if ($scope.actionValue === 'clear') {
      delete $scope.action.value
    }

    // keep any previously entered action value
    ['set', 'add', 'clear'].forEach(function(actionValue) {
      if (actionValue === $scope.actionValue) return
      if ($scope.action[actionValue] !== undefined) {
        $scope.action[$scope.actionValue] = $scope.action[actionValue]
        delete $scope.action[actionValue]
      }
    })
  })

  self.modifyValue = function() {
    if (
      $scope.action.type === 'boolean' &&
      typeof $scope.action.value !== 'boolean'
    ) {
      $scope.action.value = true
    } else if (
      $scope.action.type === 'number' &&
      typeof $scope.action.value !== 'number'
    ) {
      $scope.action.value = 0
    } else if (
      $scope.action.type === 'string' &&
      typeof $scope.action.value !== 'string'
    ) {
      $scope.action.value = ''
    } else if (
      $scope.action.type === 'any' &&
      typeof $scope.action.value !== 'string'
    ) {
      $scope.action.value = ''
    }
  }
  self.modifyValue100 = function() {
    if (
      $scope.valueType === 'boolean' &&
      typeof $scope.action.value !== 'boolean'
    ) {
      $scope.action.value = true
    } else if (
      $scope.valueType === 'number' &&
      typeof $scope.action.value !== 'number'
    ) {
      $scope.action.value = 0
    } else if (
      $scope.valueType === 'string' &&
      typeof $scope.action.value !== 'string'
    ) {
      $scope.action.value = ''
    }
  }
}

angular
  .module('apiconnect-assembly')
  .controller('SetVariableActionController', [
    '$scope',
    SetVariableActionController,
  ])

function RedactionController($scope) {
  $scope.t = t
  const self = this
  self.addRedact = function() {
    $scope.selectedNode.redactions.push({action: 'redact'})
  }
  self.removeRedact = function($index) {
    $scope.selectedNode.redactions.splice($index, 1)
  }
}

angular
  .module('apiconnect-assembly')
  .controller('RedactionController', ['$scope', RedactionController])

function RedactController($scope) {
  const self = this
  self.addRedact = function() {
    $scope.selectedNode.actions.push({from: [], action: 'redact'})
  }
  self.removeRedact = function($index) {
    $scope.selectedNode.actions.splice($index, 1)
  }
}

angular
  .module('apiconnect-assembly')
  .controller('RedactController', ['$scope', RedactController])

function RedactItemController($scope) {
  this.allFields = function() {
    if (!$scope.action.from) return false
    return $scope.action.from.length === 1 && $scope.action.from[0] === 'all'
  }

  $scope.$watch(
    'action.from',
    function() {
      if (!$scope.action.from) return
      if (
        $scope.action.from.indexOf('all') > -1 &&
        $scope.action.from.length > 1
      )
        $scope.action.from = ['all']
    },
    true
  )

  self.disableScroll = function() {
    $('.infoView').css('overflow-y', 'hidden')
  }
}

angular
  .module('apiconnect-assembly')
  .controller('RedactItemController', ['$scope', RedactItemController])

function RatelimitController($scope) {
  $scope.t = t
  const self = this

  self.addRatelimit = function() {
    if (!$scope.selectedNode['rate-limit']) {
      $scope.selectedNode['rate-limit'] = []
    }
    $scope.selectedNode['rate-limit'].push({
      name: '',
      operation: 'consume',
    })
  }

  self.removeRatelimit = function($index) {
    $scope.selectedNode['rate-limit'].splice($index, 1)
  }

  self.addBurstlimit = function() {
    if (!$scope.selectedNode['burst-limit']) {
      $scope.selectedNode['burst-limit'] = []
    }
    $scope.selectedNode['burst-limit'].push({
      name: '',
      operation: 'consume',
    })
  }

  self.removeBurstlimit = function($index) {
    $scope.selectedNode['burst-limit'].splice($index, 1)
  }

  self.addCountlimit = function() {
    if (!$scope.selectedNode['count-limit']) {
      $scope.selectedNode['count-limit'] = []
    }
    $scope.selectedNode['count-limit'].push({
      name: '',
      operation: 'inc',
    })
  }

  self.removeCountlimit = function($index) {
    $scope.selectedNode['count-limit'].splice($index, 1)
  }

  $scope.$watch('selectedNode["rate-limit"]', function(newVal, oldVal) {
    // backward compatibility, convert string to object
    Array.isArray(newVal) &&
      newVal.map(rlimit => {
        if (typeof rlimit === 'string')
          return {
            name: rlimit,
            operation: 'consume',
          }
        return rlimit
      })
    $scope.selectedNode['rate-limit'] = newVal
  })

  $scope.$watch('selectedNode["burst-limit"]', function(newVal, oldVal) {
    // backward compatibility, convert string to object
    Array.isArray(newVal) &&
      newVal.map(blimit => {
        if (typeof blimit === 'string')
          return {
            name: blimit,
            operation: 'consume',
          }
        return blimit
      })
    $scope.selectedNode['burst-limit'] = newVal
  })

  $scope.$watch('selectedNode.source', function(newVal, oldVal) {
    if (newVal === 'plan-default') {
      delete $scope.selectedNode['rate-limit']
      delete $scope.selectedNode['burst-limit']
      delete $scope.selectedNode['count-limit']
    }
  })
}

angular
  .module('apiconnect-assembly')
  .controller('RatelimitController', ['$scope', RatelimitController])

angular.module('apiconnect-assembly').controller('oauthController', [
  '$rootScope',
  '$scope',
  function($rootScope, $scope) {
    $scope.t = t
    $scope.selectedNode['oauth-provider-settings-ref'] =
      $scope.selectedNode['oauth-provider-settings-ref'] || {}
    $scope.selectedNode['oauth-provider-settings-ref'].default =
      $scope.swaggerDocument.info.title
    $scope.addComponents = function(value) {
      const idx =
        $scope.selectedNode['supported-oauth-components'].indexOf(value)
      if (idx > -1) {
        $scope.selectedNode['supported-oauth-components'].splice(idx, 1)
      } else {
        $scope.selectedNode['supported-oauth-components'].push(value)
      }
    }
    $scope.selectedNode.description =
      $scope.selectedNode.description ||
      t(
        'A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.'
      )
    $scope.$watch(
      'selectedNode["supported-oauth-components"]',
      function() {
        if (!$scope.selectedNode['supported-oauth-components'])
          $scope.selectedNode['supported-oauth-components'] = [
            'OAuthValidateRequest',
            'OAuthGenerateAZCode',
            'OAuthVerifyAZCode',
            'OAuthVerifyRefreshToken',
            'OAuthCollectMetadata',
            'OAuthGenerateAccessToken',
            'OAuthIntrospectToken',
          ]
      },
      true
    )
    $scope.$watch(
      'selectedNode["oauth-provider-settings-ref"].url',
      function() {
        !$scope.selectedNode['oauth-provider-settings-ref'].url &&
          delete $scope.selectedNode['oauth-provider-settings-ref'].url
      }
    )
  },
])

function ThrowController($scope) {
  $scope.regex = /^\$\([^) ]+\)|\d{3}$/
}

angular
  .module('apiconnect-assembly')
  .controller('ThrowController', ['$scope', ThrowController])

function JwtGenerateController($scope) {
  $scope.$jws_alg = function(value) {
    if (typeof value !== 'undefined') {
      !value
        ? delete $scope.selectedNode['jws-alg']
        : ($scope.selectedNode['jws-alg'] = value)
    } else {
      return $scope.selectedNode['jws-alg']
    }
  }
  $scope.$jwe_alg = function(value) {
    if (typeof value !== 'undefined') {
      !value
        ? delete $scope.selectedNode['jwe-alg']
        : ($scope.selectedNode['jwe-alg'] = value)
    } else {
      return $scope.selectedNode['jwe-alg']
    }
  }
  $scope.$jwe_enc = function(value) {
    if (typeof value !== 'undefined') {
      !value
        ? delete $scope.selectedNode['jwe-enc']
        : ($scope.selectedNode['jwe-enc'] = value)
    } else {
      return $scope.selectedNode['jwe-enc']
    }
  }
}

angular
  .module('apiconnect-assembly')
  .controller('JwtGenerateController', ['$scope', JwtGenerateController])

function xsltController($scope) {
  $scope.t = t
}

angular
  .module('apiconnect-assembly')
  .controller('xsltController', ['$scope', xsltController])

angular.module('apiconnect-assembly').controller('parseController', [
  '$rootScope',
  '$scope',
  function($rootScope, $scope) {
    const self = this
    $scope.selectedNode['parse-settings-reference'] =
      $scope.selectedNode['parse-settings-reference'] || {}
    if ($scope.selectedNode['parse-settings-reference'].default) {
      $scope.parse_reference = 'default'
    } else if (
      $scope.selectedNode['parse-settings-reference']['parse-settings']
    ) {
      $scope.parse_reference = 'parse-settings'
    }
    $scope.$watch('parse_reference', function(newVal, oldVal) {
      if (newVal === 'default') {
        delete $scope.selectedNode['parse-settings-reference']['parse-settings']
        $scope.showParseSetting = false
        $scope.selectedNode['parse-settings-reference'].default =
          'apic-default-parsesettings'
      }
      if (newVal === 'parse-settings') {
        $scope.showParseSetting = true
        delete $scope.selectedNode['parse-settings-reference'].default
      }
    })

    $scope.$watch(
      'selectedNode["parse-settings-reference"]["parse-settings"].document_type',
      function(newVal, oldVal) {
        if (newVal === 'JSON') {
          delete $scope.selectedNode['parse-settings-reference'][
            'parse-settings'
          ].max_unique_prefixes
          delete $scope.selectedNode['parse-settings-reference'][
            'parse-settings'
          ].max_unique_namespaces
        }
        if (newVal === 'XML') {
          delete $scope.selectedNode['parse-settings-reference'][
            'parse-settings'
          ].max_number_length
        }
        if (newVal === 'graphql') {
          delete $scope.selectedNode['parse-settings-reference'][
            'parse-settings'
          ].max_unique_prefixes
          delete $scope.selectedNode['parse-settings-reference'][
            'parse-settings'
          ].max_unique_namespaces
          delete $scope.selectedNode['parse-settings-reference'][
            'parse-settings'
          ].max_unique_names
        }
      }
    )
  },
])

angular.module('apiconnect-assembly').controller('userSecurityController', [
  '$rootScope',
  '$scope',
  function($rootScope, $scope) {
    const self = this
    $scope.t = t
    // remove extra properties when first add policy
    removeProperty('ei', null, $scope.selectedNode['extract-identity-method'])
    removeProperty(
      $scope.selectedNode.version === '2.0.0' ? 'auth' : 'auth210',
      null,
      $scope.selectedNode['user-auth-method']
    )
    removeProperty('ua', null, $scope.selectedNode['user-az-method'])

    self.addAction = function() {
      $scope.selectedNode['az-table-default-entry'] =
        $scope.selectedNode['az-table-default-entry'] || []
      $scope.selectedNode['az-table-default-entry'].push({
        name: '',
        description: '',
      })
    }
    self.removeAction = function($index) {
      $scope.selectedNode['az-table-default-entry'] &&
        $scope.selectedNode['az-table-default-entry'].splice($index, 1)
    }

    function removeProperty(section, oldVal, newVal, addDefault) {
      if (
        $scope.selectedNode['user-az-method'] !== 'html-form' &&
        $scope.selectedNode['extract-identity-method'] !== 'redirect' &&
        $scope.selectedNode['extract-identity-method'] !== 'html-form'
      ) {
        delete $scope.selectedNode.hostname
      }
      switch (section) {
        case 'ei': {
          if (newVal !== 'context-var') {
            delete $scope.selectedNode['pass-context-var']
            delete $scope.selectedNode['user-context-var']
          } else if (addDefault) {
            $scope.selectedNode['pass-context-var'] =
              $scope.selectedNode['pass-context-var'] || ''
            $scope.selectedNode['user-context-var'] =
              $scope.selectedNode['user-context-var'] || ''
          }
          if (newVal !== 'redirect') {
            delete $scope.selectedNode['redirect-url']
            delete $scope.selectedNode['info-var']
            delete $scope.selectedNode['redirect-time-limit']
            delete $scope.selectedNode['query-parameters']
          } else if (addDefault) {
            $scope.selectedNode['redirect-url'] =
              $scope.selectedNode['redirect-url'] || ''
            $scope.selectedNode['redirect-time-limit'] =
              $scope.selectedNode['redirect-time-limit'] || 300
            $scope.selectedNode['query-parameters'] =
              $scope.selectedNode['query-parameters'] || ''
          }
          if (newVal !== 'html-form') {
            delete $scope.selectedNode['ei-default-form']
            delete $scope.selectedNode['ei-custom-form']
            delete $scope.selectedNode['ei-custom-form-tls-client-profile']
            delete $scope.selectedNode['ei-form-time-limit']
            delete $scope.selectedNode['ei-custom-form-csp']
          } else if (addDefault) {
            if ($scope.selectedNode['ei-default-form'] === undefined)
              $scope.selectedNode['ei-default-form'] = true
            $scope.selectedNode['ei-form-time-limit'] =
              $scope.selectedNode['ei-form-time-limit'] || 300
          }
          if (
            newVal !== 'disabled' &&
            typeof $scope.selectedNode['ei-stop-on-error'] === 'undefined'
          )
            $scope.selectedNode['ei-stop-on-error'] = true
          break
        }
        case 'auth': {
          if (newVal !== 'ldap') {
            delete $scope.selectedNode['ldap-registry']
          } else if (addDefault) {
            $scope.selectedNode['ldap-registry'] =
              $scope.selectedNode['ldap-registry'] || ''
          }
          if (
            newVal !== 'disabled' &&
            typeof $scope.selectedNode['au-stop-on-error'] === 'undefined'
          )
            $scope.selectedNode['au-stop-on-error'] = true
          if (newVal !== 'auth-url') {
            delete $scope.selectedNode['auth-response-headers-pattern']
            delete $scope.selectedNode['auth-response-header-credential']
            delete $scope.selectedNode['auth-url']
            delete $scope.selectedNode['auth-tls-client-profile']
          } else if (addDefault) {
            $scope.selectedNode['auth-response-headers-pattern'] =
              $scope.selectedNode['auth-response-headers-pattern'] ||
              '(?i)x-api*'
            $scope.selectedNode['auth-response-header-credential'] =
              $scope.selectedNode['auth-response-header-credential'] ||
              'X-API-Authenticated-Credential'
            $scope.selectedNode['auth-url'] =
              $scope.selectedNode['auth-url'] || 'https://example.com'
          }
          break
        }
        case 'auth210': {
          if (
            newVal !== 'disabled' &&
            typeof $scope.selectedNode['au-stop-on-error'] === 'undefined'
          )
            $scope.selectedNode['au-stop-on-error'] = true
          if (newVal !== 'user-registry') {
            delete $scope.selectedNode['auth-response-headers-pattern']
            delete $scope.selectedNode['auth-response-header-credential']
            delete $scope.selectedNode['user-registry']
          } else {
            if ($scope.isAuthURL) {
              if (addDefault) {
                $scope.selectedNode['auth-response-headers-pattern'] =
                  $scope.selectedNode['auth-response-headers-pattern'] ||
                  '(?i)x-api*'
                $scope.selectedNode['auth-response-header-credential'] =
                  $scope.selectedNode['auth-response-header-credential'] ||
                  'X-API-Authenticated-Credential'
              }
            } else {
              delete $scope.selectedNode['auth-response-headers-pattern']
              delete $scope.selectedNode['auth-response-header-credential']
            }
            $scope.selectedNode['user-registry'] =
              $scope.selectedNode['user-registry'] || ''
          }
          break
        }
        case 'ua': {
          if (newVal !== 'html-form') {
            delete $scope.selectedNode['az-default-form']
            delete $scope.selectedNode['az-custom-form-tls-client-profile']
            delete $scope.selectedNode['az-custom-form']
            delete $scope.selectedNode['az-form-time-limit']
            delete $scope.selectedNode['az-table-display-checkboxes']
            delete $scope.selectedNode['az-table-dynamic-entries']
            delete $scope.selectedNode['az-table-default-entry']
          } else if (addDefault) {
            if ($scope.selectedNode['az-default-form'] === undefined)
              $scope.selectedNode['az-default-form'] = true
            $scope.selectedNode['az-form-time-limit'] =
              $scope.selectedNode['az-form-time-limit'] || 300
            $scope.selectedNode['az-table-dynamic-entries'] =
              $scope.selectedNode['az-table-dynamic-entries'] ||
              'user.default.az.dynamic_entries'
            $scope.selectedNode['az-table-display-checkboxes'] =
              $scope.selectedNode['az-table-display-checkboxes'] || false
            $scope.selectedNode['az-table-default-entry'] =
              $scope.selectedNode['az-table-default-entry'] || []
          }
          if (
            newVal !== 'disabled' &&
            typeof $scope.selectedNode['az-stop-on-error'] !== 'undefined'
          )
            $scope.selectedNode['az-stop-on-error'] = true
          break
        }
      }
    }
    $scope.$watch(
      'selectedNode["extract-identity-method"]',
      function(newVal, oldVal) {
        if (newVal === oldVal) return
        removeProperty('ei', oldVal, newVal, true)
      },
      true
    )
    $scope.$watch(
      'selectedNode["user-auth-method"]',
      function(newVal, oldVal) {
        if (newVal === oldVal) return
        removeProperty(
          $scope.selectedNode.version === '2.0.0' ? 'auth' : 'auth210',
          oldVal,
          newVal,
          true
        )
      },
      true
    )
    $scope.$watch(
      'selectedNode["user-az-method"]',
      function(newVal, oldVal) {
        if (newVal === oldVal) return
        removeProperty('ua', oldVal, newVal, true)
      },
      true
    )
    $scope.$watch(
      'selectedNode["ei-default-form"]',
      function(newVal, oldVal) {
        if (newVal === undefined) return
        if (!newVal) {
          $scope.selectedNode['ei-custom-form'] =
            $scope.selectedNode['ei-custom-form'] || ''
          $scope.selectedNode['ei-custom-form-tls-client-profile'] =
            $scope.selectedNode['ei-custom-form-tls-client-profile'] || ''
        } else {
          delete $scope.selectedNode['ei-custom-form']
          delete $scope.selectedNode['ei-custom-form-tls-client-profile']
        }
      },
      true
    )
    $scope.$watch(
      'selectedNode["az-default-form"]',
      function(newVal, oldVal) {
        if (newVal === undefined) return
        if (!newVal) {
          $scope.selectedNode['az-custom-form'] =
            $scope.selectedNode['az-custom-form'] || ''
          $scope.selectedNode['az-custom-form-tls-client-profile'] =
            $scope.selectedNode['az-custom-form-tls-client-profile'] || ''
        } else {
          delete $scope.selectedNode['az-custom-form']
          delete $scope.selectedNode['az-custom-form-tls-client-profile']
        }
      },
      true
    )
    $scope.$watch(
      'selectedNode["user-registry"]',
      function(newVal, oldVal) {
        if (!newVal) return
        const selectedUserReg =
          userRegistries.find(d => {
            return d.name === newVal
          }) || {}
        if (selectedUserReg.registry_type === 'authurl') {
          $scope.isAuthURL = true
          $scope.selectedNode['auth-response-headers-pattern'] =
            $scope.selectedNode['auth-response-headers-pattern'] || '(?i)x-api*'
          $scope.selectedNode['auth-response-header-credential'] =
            $scope.selectedNode['auth-response-header-credential'] ||
            'X-API-Authenticated-Credential'
        } else {
          delete $scope.selectedNode['auth-response-headers-pattern']
          delete $scope.selectedNode['auth-response-header-credential']
          $scope.isAuthURL = false
        }
      },
      true
    )
    const tlsProfiles = []
    if (!$rootScope.offlineMode) {
      $rootScope.tlsProfiles &&
        $rootScope.tlsProfiles.forEach(function(profile) {
          tlsProfiles.push({
            name: profile.title,
            value: `${profile.name}:${profile.version}`,
          })
        })
      tlsProfiles.push({name: 'None', value: ''})
    }
    this.tlsProfiles = tlsProfiles

    var userRegistries = []
    const ldapUserRegistries = []
    if (!$rootScope.offlineMode) {
      $rootScope.userRegistries &&
        $rootScope.userRegistries.forEach(function(registry) {
          if (
            registry.registry_type === 'ldap' ||
            registry.registry_type === 'authurl'
          ) {
            userRegistries.push({
              name: registry.title,
              value: registry.name,
              registry_type: registry.registry_type,
            })
            if (registry.registry_type === 'ldap')
              ldapUserRegistries.push({
                name: registry.title,
                value: registry.name,
              })
          }
        })
    }
    this.userRegistries = userRegistries
    this.ldapUserRegistries = ldapUserRegistries
    $scope.isOAuth = $rootScope.isOAuth
  },
])
