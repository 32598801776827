// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly
// esversion: 6

'use strict'

const jsonSchemaDefaults = require('json-schema-defaults')

/**
 * Split the path into terms (separated by .)
 * @param path
 * @return terms[]
 */
function splitTerms(path) {
  // A dot within a name is always '\.'
  // A dot that is a separator is an unescaped '.'
  // Create a new string with separators that are '..'
  // (We could choose any sequence that cannot be in the initial term, .. seems reasonable)
  const path2 = path.replace(/([^\\])\./g, '$1..')
  // now split on the new separator
  return path2.split('..')
}

/**
 * @param name
 * @param n count
 * @return name with n words removed
 */
function sliceOff(name, n) {
  const words = splitTerms(name)
  for (let i = 0; i < n; i++) {
    words.pop()
  }
  return words.join('.')
}

/**
 * @param name
 * @return last word (after last '.')
 */
function last(name) {
  const words = splitTerms(name)
  return words[words.length - 1]
}

/**
 * Get the referenced definition
 * @param swagger (open api object)
 * @param ref (i.e. '#/definitions/ABC')
 * @return definition
 */
function getDefFromRef(swagger, ref) {
  const keys = ref.split('/')
  let def = swagger
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] !== '#') {
      def = def[keys[i]]
      if (!def) {
        return null
      }
    }
  }
  return def
}

/**
 * Add the source to an existing mapping to produce
 * a mapping with multiple sources.
 *
 * The source is added in simple cases, but if we detect
 * a complicated case (i.e. involving arrays) then
 * the new source is not added. (In such cases, the caller
 * produces a new mapping for the sourcePath).
 * @param mapping existing mapping or null
 * @param sourcePath is the new source
 * @return true if added
 */
function addSourceToExistingMapping(mapping, sourcePath) {
  if (
    mapping &&
    !sourcePath &&
    (!mapping.source || mapping.source.length === 0)
  ) {
    // If there is no source and the existing mapping doesn't have a source, then
    // simply return true.  This can happen when a default value is being updated.
    return true
  }
  let add = mapping && sourcePath

  if (add) {
    if (!mapping.foreach) {
      // If the existing mapping is not an array (no foreach) and the sourcePath
      // is not within an array, then it is safe to add the existing source
      add =
        sourcePath.getDimensionality() === 0 ||
        (sourcePath.getDimensionality() === 1 &&
          sourcePath.getPath() === sourcePath.dimensionality[0])
    } else {
      // This gets complicated when arrays are involved.
      if (sourcePath.getDimensionality() === 0) {
        // The source has zero dimensions, but there is a foreach
        if (!mapping.source) {
          // Not sure why there is no existing source..probably a dead path
          add = false
        } else if (mapping.source.length === 1) {
          // First time adding a new source
          if (mapping.source[0] !== mapping.foreach[0].source) {
            // existing source is multiple dimensions, too complicated
            add = false
          } else {
            // Broaden the foreach to include this new source
            const period = mapping.foreach[0].source.indexOf('.')
            if (period > 0) {
              mapping.foreach[0].source = mapping.foreach[0].source.substring(
                0,
                period
              )
            }
            add = true
          }
        } else {
          add = true
        }
      } else if (sourcePath.getDimensionality() !== mapping.foreach.length) {
        // Too complicated if source and existing source are different dimensions
        add = false
      } else if (sourcePath.getDimensionality() === 1) {
        // If the the source and existing mapping are 1 dim arrays
        // then add the source if the arrays match.
        add = sourcePath.dimensionality[0] === mapping.foreach[0].source
      } else {
        // Multiple dimenions but same number of dimensions
        // We could do a lot of work here to check a bunch of cases, but
        // let's just make sure the first dimension is a match.
        add = sourcePath.dimensionality[0] === mapping.foreach[0].source
      }
    }
  }

  // If that source can be safely added, then add it
  if (add) {
    mapping.source = mapping.source || []
    mapping.source.push(sourcePath.getPath())
  }
  return add
}

angular
  .module('apiconnect-assembly')
  .provider('SchemaFormOptions', function() {
    const options = angular.extend(JSONEditor.defaults.options, {
      disable_edit_json: true,
      disable_properties: true,
      no_additional_properties: true,
      disable_collapse: true,
    })
    return {
      $get: () => {
        return {
          options,
          setOptions: newOptions => {
            return angular.extend(options, newOptions)
          },
        }
      },
    }
  })
  .factory('Assembly', [
    '$resource',
    function($resource) {
      return $resource(
        'proxy/orgs/:orgId/assemblies/:assemblyId/',
        {},
        {
          query: {
            method: 'GET',
            params: {
              orgId: '@orgId',
            },
            isArray: true,
          },
          get: {
            method: 'GET',
            params: {
              orgId: '@orgId',
              assemblyId: '@assemblyId',
            },
            isArray: false,
          },
          update: {
            method: 'PUT',
            params: {
              orgId: '@orgId',
              assemblyId: '@assemblyId',
            },
            isArray: false,
          },
        }
      )
    },
  ])
  .factory('Path', function() {
    return function(path, dimensionality, container, type) {
      const self = this
      self.path = path
      if (typeof dimensionality === 'string') {
        dimensionality = JSON.parse(dimensionality)
      }
      self.dimensionality = dimensionality
      self.container = container
      self.type = type

      // var unnamed = "$item";
      // var unnamedRe = new RegExp("\\$item", "g");

      self.getPath = function() {
        return path
      }

      // getDimensionality
      // get the dimension of the given path
      // items[0].items[2].name has dimension 2 - two nested arrays
      self.getDimensionality = function() {
        return self.dimensionality.length
      }

      self.getType = function() {
        return self.type
      }

      return self
    }
  })
  .factory('DynamicIcon', function() {
    return function(key, policy) {
      const icon = 'default'
      switch (key) {
        case 'invoke': {
          if (policy['backend-type'] === 'graphql') {
            return {
              color: '#E535AB',
              icon: 'graphql',
              custom: true,
            }
          }
          return {
            color: '#009d9a',
            icon: 'rss',
          }
        }
        case 'graphql-introspect': {
          return {
            color: '#009D9A',
            icon: 'graphql',
            custom: true,
          }
        }
      }
      return icon
    }
  })
  .factory('Map', [
    'SchemaReferences',
    function(SchemaReferences) {
      return function(map, definitions, callback) {
        const self = this
        const swagger = definitions
        const inputSchemas = map.inputs
        const outputSchemas = map.outputs
        let model = []

        self.readOnly = !!map.$$readOnly || !!map.$$parentReadOnly

        function processMapping(mapping) {
          if (mapping.actions) {
            // go deeper
            mapping.actions.forEach(function(nextMapping) {
              nextMapping.$$parent = mapping
              processMapping(nextMapping)
            })
          } else {
            // we're at a root
            // A foreach implies a from.  If from is missing, then set it.
            if (mapping.foreach && !mapping.from) {
              mapping.from = angular.copy(mapping.foreach)
            }
            let foreach = []
            if (mapping.foreach && mapping.set) {
              foreach = mapping.foreach
            }
            let source = angular.copy(mapping.from)
            let target = mapping.set || mapping.create
            let parentMapping = mapping

            if (typeof source === 'string') {
              source = [source]
            }
            let merge = false
            while (parentMapping.$$parent) {
              parentMapping = parentMapping.$$parent
              if (source) {
                for (let i = 0; i < source.length; i++) {
                  if (source[i].indexOf('#/') !== 0) {
                    // if the source is not absolute, prepend the parent context (if it exists)
                    if (parentMapping.foreach || parentMapping.source) {
                      source[i] = `${parentMapping.foreach ||
                        parentMapping.source}.${source[i]}`
                    } else {
                      merge = true
                    }
                  } else {
                    merge = true
                  }
                }
              }
              target = `${parentMapping.set || parentMapping.create}.${target}`
              foreach.splice(0, 0, {
                source: parentMapping.foreach || parentMapping.source,
                target: parentMapping.set || parentMapping.create,
              })
            }
            const item = {
              target,
            }
            if (source) {
              item.source = source
            }
            if (mapping.create) {
              item.$$create = true
            }

            if (parentMapping.create) {
              item.$$parentCreate = true
            }

            if (merge === true) {
              item.merge = true
            }

            if (mapping.value) {
              item.value = mapping.value
            }
            if (mapping.default) {
              item.default = mapping.default
            }
            if (foreach.length > 0) {
              item.foreach = foreach
            }
            if (mapping.foreach && mapping.set) {
              item.manualForeach = foreach
            }
            model.push(item)
          }
        }

        if (map.actions) {
          map.actions.forEach(function(mapping) {
            processMapping(mapping)
          })
        }

        /**
         * Compare paths for equality.  Either path may contain a $item
         */
        function comparePaths(string1, string2) {
          if (string1 === string2) {
            return true
          }
          if (!string1 && !string2) {
            return true
          }
          if (!string1 || !string2) {
            return false
          }
          if (
            `${string1}.$item` === string2 ||
            string1 === `${string2}.$item`
          ) {
            return true
          }
          return false
        }

        self.getMapping = function(targetPath) {
          const filtered = model.filter(function(mapping) {
            return comparePaths(mapping.target, targetPath)
          })
          if (filtered.length > 0) {
            return filtered[0]
          }
          return null
        }

        /**
         * Get Schema for an identifier path
         * @param path (full path of an input or output identifier)
         * @return an object containing the schema of the identifier and
         * the signature (including [] for arrays)
         */
        self.getSchemaObject = function(path) {
          let ret = {}
          // If the identifier path is absolute, remove the absolute part
          if (path.indexOf('#/') === 0) {
            path = path.substring(2)
          }
          const name = splitTerms(path)
          let schema
          let schemaFallback
          if (angular.isArray(inputSchemas)) {
            schemaFallback =
              inputSchemas.length === 1 ? inputSchemas[0] : undefined
            inputSchemas.forEach(function(item) {
              if (item.$$title === name[0] || item.title === name[0]) {
                schema = item
              }
            })
          } else {
            schema = inputSchemas[name[0]]
          }
          if (!schema) {
            if (angular.isArray(outputSchemas)) {
              if (!schemaFallback) {
                schemaFallback =
                  outputSchemas.length === 1 ? outputSchemas[0] : undefined
              }
              outputSchemas.forEach(function(item) {
                if (item.$$title === name[0]) {
                  schema = item
                }
              })
            } else {
              schema = outputSchemas[name[0]]
            }
          }
          schema = schema || schemaFallback
          if (schema) {
            if (schema.schema) {
              schema = schema.schema
            }
            ret = {
              signature: name[0],
            }
            let i = 1
            while (schema && i < name.length) {
              if (schema.$ref) {
                schema = getDefFromRef(swagger, schema.$ref)
                ret.schema = schema
              } else {
                // LAX processing for schema that does not have a type
                if (!schema.type) {
                  if (schema.properties) {
                    schema.type = 'object'
                  } else if (schema.items) {
                    schema.type = 'array'
                  }
                }
                switch (schema.type) {
                  case 'object':
                    ret.signature = `${ret.signature}.${name[i]}`
                    schema = schema.properties[name[i]]
                    ret.schema = schema
                    i++
                    break
                  case 'array':
                    ret.signature = `${ret.signature}[]`
                    schema = schema.items
                    ret.schema = schema
                    break
                  default:
                    ret.signature = `${ret.signature}.${name[i]}`
                    schema = null
                    ret.schema = schema
                    break
                }
              }
            }
            if (schema && schema.$ref) {
              schema = getDefFromRef(swagger, schema.$ref)
              ret.schema = schema
            }
          }
          return ret
        }

        self.getNumberOfMappings = function(targetPath) {
          const filtered = model.filter(function(mapping) {
            return comparePaths(mapping.target, targetPath)
          })
          return filtered.length
        }

        // determine if a given path has a mapping
        // if a path is mapped, return the mapping for that path
        // direct parameter (defaults to true) - only consider a direct mapping
        // as true - sub-element mappings would not be considered true
        // otherwide return null
        self.hasMapping = function(path) {
          if (self.getMapping(path.getPath()) !== null) {
            return true
          }
          return self.getMapping(`${path.getPath()}.$item`) !== null
        }

        // gets the mapping for this path, creating where necessary
        self.getMappingForPath = function(path) {
          return self.getMapping(path.getPath())
        }

        self.getNumberOfMappingsForPath = function(path) {
          return self.getNumberOfMappings(path.getPath())
        }

        // gets the mapping for this path, creating where necessary
        self.setMappingForPath = function(targetPath, sourcePath) {
          let mapping = self.getMapping(targetPath.getPath())

          // subtle case - if the user has set a default value on the target
          // path already, and that target path resides within an array, it's
          // hard to retro-fit the existing mapping with all the necessary
          // foreach iterators. But the existing mapping is simpley a default
          // value string, so the simplest thing to do is to remember the default,
          // clear out the existing mapping, and recreate it with the default value.
          // It's an edge case, so get it out of the way...
          let defaultValue
          if (
            mapping &&
            mapping.default &&
            mapping.foreach &&
            mapping.foreach.length > 0 &&
            !mapping.foreach[0].source
          ) {
            // this is such a mapping...
            defaultValue = mapping.default
            self.deleteMappingForPath(targetPath)
            mapping = null
          }

          // now continue as normal, safe in the knowledge that that awkward
          // case is no longer lying around to trip us up

          // Reuse an existing mapping if it is simple, otherwise create a mapping
          if (!addSourceToExistingMapping(mapping, sourcePath)) {
            mapping = {
              target: targetPath.getPath(),
            }
            if (sourcePath) {
              mapping.source = [sourcePath.getPath()]
            }
            // re-instate any previously set defaultValue from the edge case above
            if (defaultValue) {
              mapping.default = defaultValue
            }

            // do we have any dimensionality to address?
            const foreach = []
            if (targetPath.getDimensionality() > 0) {
              // indeed we do...
              // take a simple approach and map each level on the source to the equivalent on the right
              // this will be right most of the time, I swear...
              // but if the source path does not have enough dimensions, then compensate on the source side.
              let index = 0
              let targetContext = ''
              let sourceContext = ''
              while (index < targetPath.getDimensionality()) {
                let sourceDimension = '$item'
                if (sourcePath) {
                  const sourceIndex =
                    sourcePath.getDimensionality() >=
                    targetPath.getDimensionality()
                      ? index
                      : sourcePath.getDimensionality() -
                        targetPath.getDimensionality() +
                        index
                  if (sourceIndex >= 0) {
                    sourceDimension = sourcePath.dimensionality[
                      sourceIndex
                    ].replace(sourceContext, '')
                    sourceContext = `${sourcePath.dimensionality[sourceIndex]}.`
                  } else if (targetPath.getDimensionality() > 1) {
                    const source = sliceOff(
                      sourcePath.getPath(),
                      1 - sourceIndex
                    )
                    sourceDimension = source.replace(sourceContext, '')
                    sourceContext = `${source}.`
                  } else {
                    sourceDimension = sourcePath.getPath()
                  }
                }
                // if there is no sourcePath, there is nothing to iterate over
                const foreachObj = {
                  target: targetPath.dimensionality[index].replace(
                    targetContext,
                    ''
                  ),
                }
                if (sourcePath) foreachObj.source = sourceDimension
                foreach.push(foreachObj)
                targetContext = `${targetPath.dimensionality[index]}.`
                index++
              }
            }
            if (foreach.length > 0) {
              // If a simple assignment of array to array, then don't set foreach,
              // which will result in a set.
              // If not a simple assignment of array to array, then set foreach,
              // which will result in a create.
              if (
                foreach.length > 1 ||
                foreach[0].target !== mapping.target ||
                foreach[0].source !==
                  (mapping.source ? mapping.source[0] : undefined) ||
                !sourcePath ||
                targetPath.getDimensionality() !==
                  sourcePath.getDimensionality()
              ) {
                mapping.foreach = foreach
              }
            }
            model.push(mapping)
          }
          callback(self.getActions(true))
          return mapping
        }

        self.deleteMappingForPath = function(targetPath) {
          self.deleteMapping(targetPath.getPath())
        }

        self.deleteMapping = function(targetPath) {
          model = model.filter(function(mapping) {
            return !comparePaths(mapping.target, targetPath)
          })
          callback(self.getActions(true))
        }

        function serializeMapping(mapping, actions) {
          let action = {}
          const contextualizedSources = []
          if (
            mapping.manualForeach ||
            !mapping.foreach ||
            mapping.foreach.length === 0
          ) {
            // simple case
            // are we within a target context?
            let target = mapping.targetContext
              ? mapping.target.replace(`${mapping.targetContext}.`, '')
              : mapping.target
            // are we mapping something simple within a target context?
            if (mapping.targetContext === mapping.target) {
              target = '$item'
            }
            action[mapping.$$create ? 'create' : 'set'] = target
            if (mapping.source) {
              mapping.source.forEach(function(source) {
                let contextualizedSource = mapping.sourceContext
                  ? source.replace(`${mapping.sourceContext}.`, '')
                  : source
                if (contextualizedSource.indexOf('.$item') > 0) {
                  contextualizedSource = contextualizedSource.replace(
                    '.$item',
                    ''
                  )
                } else if (contextualizedSource.indexOf('.$item') === 0) {
                  contextualizedSource = contextualizedSource.replace(
                    '.$item',
                    ''
                  )
                }
                contextualizedSources.push(contextualizedSource)
              })
              action.from =
                contextualizedSources.length > 1
                  ? contextualizedSources
                  : contextualizedSources[0]
            }
            if (mapping.manualForeach) {
              action.foreach = mapping.manualForeach
            }

            if (mapping.value) {
              action.value = mapping.value
            }
            if (mapping.default !== undefined) {
              action.default = mapping.default
            }
          } else {
            action = {
              create: mapping.foreach[0].target,
            }

            if (mapping.merge) {
              action.$$merge = true
            }

            if (mapping.foreach[0].source)
              action.foreach = mapping.foreach[0].source
            if (mapping.source) {
              mapping.source.forEach(function(source) {
                let contextualizedSource = mapping.sourceContext
                  ? source.replace(`${mapping.sourceContext}.`, '')
                  : source
                if (contextualizedSource === action.foreach) {
                  // ok, our source is the same as the iterator, so either
                  // 1) We want to merge this with another iterator, so use the absolute path, or
                  // 2) If this is a not an array, iterate over its parent.
                  // 3) Else the source is $item
                  if (mapping.merge) {
                    contextualizedSource = `#/${contextualizedSource}`
                  } else {
                    if (
                      mapping.source.length === 1 &&
                      !mapping.$foreachSourceIsArray &&
                      contextualizedSource.indexOf('.') > 0
                    ) {
                      // Iterate over the parent of the simple object
                      action.foreach = sliceOff(action.foreach, 1)
                      action.from = action.foreach
                      contextualizedSource = last(contextualizedSource)
                    } else {
                      contextualizedSource = '$item'
                    }
                  }
                }
                contextualizedSources.push(contextualizedSource)
              })
              if (!action.from && mapping.foreach[0].source) {
                action.from =
                  mapping.foreach[0].source.length > 1
                    ? mapping.foreach[0].source
                    : mapping.foreach[0].source[0]
              }
            }

            // Prevent UI deletion of the 'from' property in the create action when child only has default mapping
            // apiconnect-assembly/#409
            if (
              !action.from &&
              !mapping.source &&
              mapping.default !== undefined
            ) {
              if (mapping.foreach[0].source) {
                action.from =
                  mapping.foreach[0].source.length > 1
                    ? mapping.foreach[0].source
                    : mapping.foreach[0].source[0]
              }
            }

            action.actions = []
            const subMapping = {
              source: contextualizedSources,
              target: mapping.targetContext
                ? mapping.target.replace(`${mapping.targetContext}.`, '')
                : mapping.target,
              foreach: mapping.foreach.slice(1),
              targetContext: mapping.foreach[0].target,
            }

            if (mapping.$$create) {
              subMapping.$$create = true
            }
            if (mapping.foreach[0].source) {
              subMapping.sourceContext = mapping.foreach[0].source
            }
            if (mapping.value) {
              subMapping.value = mapping.value
            }
            if (mapping.default != undefined) {
              subMapping.default = mapping.default
            }
            if (mapping.merge) {
              subMapping.merge = true
            }
            serializeMapping(subMapping, action.actions)
          }
          actions.push(action)
        }

        /**
         * Get the signature of the array that it iterated over.  This is used to determine
         * if two actions can be merged.
         * @param action
         * @param prefixPath (if the action is nested, this is the accumated source from the parent)
         * @param getSchemaObject function
         * @return returns the full signature of the array that it is iterated over.
         * if the from is not an array, then a '' is returned
         */
        function getPrimarySourceArraySignature(
          action,
          prefixPath,
          getSchemaObject
        ) {
          // Get the full path of the source that it is iterated over.
          let fullPath
          if (action.foreach) {
            fullPath = action.foreach
          } else {
            fullPath = action.from
              ? action.from.replace('$item', `${action.foreach}.`)
              : ''
          }
          if (prefixPath && fullPath.indexOf('#/') !== 0) {
            fullPath = `${prefixPath}.${fullPath}`
          }

          // Using the full identifier path, get the schema information so that we can
          // determine if this an array, multi-dim array, etc.
          const info = getSchemaObject(fullPath)

          // The info object contains a signature that contains [] to indicate the intervening
          // arrays.  If the schema that is an array, then append [] to the signature
          let signature = info.signature || ''
          if (
            info.schema &&
            (info.schema.type === 'array' ||
              (!info.schema.type && info.schema.items))
          ) {
            signature += '[]'
          }

          // Now we have the full signature. But in order to determine if two mappings can be merged,
          // we only need the portion of the signature to the last array.
          const i = signature.lastIndexOf('[]')
          if (i < 0) {
            signature = '' // This indicates that source is not an array
          } else {
            signature = signature.substring(0, i)
          }
          return signature
        }
        /**
         * Determine if these two actions match
         * @param targetAction
         * @param sourceAction
         */
        function actionsMatch(targetAction, sourceAction) {
          if (!targetAction.actions || !sourceAction.actions) {
            return false
          }
          const targetActionTarget = targetAction.create || targetAction.set
          const sourceActionTarget = sourceAction.create || sourceAction.set
          // Merge all actions with same target #apiconnect-assembly/411
          return (
            targetActionTarget === sourceActionTarget &&
            (targetAction.foreach === sourceAction.foreach ||
              targetAction.foreach === undefined ||
              sourceAction.foreach === undefined)
          )
        }
        /**
         * Determine if these two actions if one them has 'merge' selected
         * @param targetAction
         * @param sourceAction
         * @param prefixPath (the parent's source if the actions are nested)
         * @param getSchemaOject function
         */
        function actionsMergeMatch(
          targetAction,
          sourceAction,
          prefixPath,
          getSchemaObject
        ) {
          if (!targetAction.actions || !sourceAction.actions) {
            return false
          }
          const targetActionTarget = targetAction.create || targetAction.set
          const sourceActionTarget = sourceAction.create || sourceAction.set
          if (targetActionTarget === sourceActionTarget) {
            // Get the source array for the two actions
            const targetSignature = getPrimarySourceArraySignature(
              targetAction,
              prefixPath,
              getSchemaObject
            )
            const sourceSignature = getPrimarySourceArraySignature(
              sourceAction,
              prefixPath,
              getSchemaObject
            )

            // If the source is not an array (i.e. just an object), then
            // merging is allowed.
            // Otherwise the source and target arrays must match
            return !sourceSignature || sourceSignature === targetSignature
          }
          return false
        }

        /**
         * @param actions
         * @return true if any actions has a non-absolute 'from' value
         */
        function hasRelativeFrom(actions) {
          let isRelative = false
          if (actions) {
            actions.forEach(function(action) {
              if (action.from) {
                try {
                  if (typeof action.from === 'string') {
                    if (action.from[0] !== '#') {
                      isRelative = true
                    }
                  } else {
                    action.from.forEach(function(ref) {
                      if (ref && ref[0] !== '#') {
                        isRelative = true
                      }
                    })
                  }
                } catch (e) {
                  throw e
                }
              }
            })
          }
          return isRelative
        }

        /**
         * Prior to consolodating these two actions, the
         * from values must be rebased to make sure that they are
         * using the same path.
         * @param action1
         * @param action2
         * @param mergePath (specified if a mergePath should be used for the rebasing)
         */
        function rebaseActions(action1, action2, mergePath) {
          // Determine from paths
          const fromPath1 = action1.from
            ? action1.from.replace('$item', `${action1.foreach}.`)
            : ''
          const fromPath2 = action2.from
            ? action2.from.replace('$item', `${action2.foreach}.`)
            : ''
          if (fromPath1 === fromPath2 && !mergePath) {
            return // no rebase required
          }

          // Find the common path between the two from paths
          const from1 = splitTerms(fromPath1)
          const from2 = splitTerms(fromPath2)
          let i = 0
          let common = []
          if (mergePath) {
            common = splitTerms(mergePath)
          } else {
            while (from1[i] === from2[i]) {
              common.push(from1[i])
              i++
            }
          }
          // If there is no common path between the two from values, then nothing to rebase
          if (common.length === 0) {
            return
          }

          function rebase(action, from, common) {
            let prepend

            const fromString = from.join('.')
            const commonString = common.join('.')
            if (fromString.indexOf(commonString) === 0) {
              // If the action starts with the common path, then rebase all of the actions
              // to use the common path
              action.from = commonString
              action.foreach = commonString
              // Calculate what is after the common path and prepend it to nested actions' from
              prepend = from.slice(common.length).join('.')
              if (prepend) {
                action.actions.forEach(function(action) {
                  if (action.from) {
                    if (typeof action.from === 'string') {
                      if (action.from === '$item') {
                        action.from = prepend
                      } else if (action.from[0] !== '#') {
                        action.from = `${prepend}.${action.from}`
                      }
                    } else {
                      for (let i = 0; i < action.from.length; i++) {
                        if (action.from[i] === '$item') {
                          action.from[i] = prepend
                        } else if (action.from[i][0] !== '#') {
                          action.from[i] = `${prepend}.${action.from[i]}`
                        }
                      }
                    }
                  }
                })
              }
            } else {
              // Change nested actions to absolute
              prepend = `#/${action.from}`
              action.from = commonString
              action.foreach = commonString
              action.actions.forEach(function(action) {
                if (action.from) {
                  if (typeof action.from === 'string') {
                    if (action.from === '$item') {
                      action.from = prepend
                    } else if (action.from[0] !== '#') {
                      action.from = `${prepend}.${action.from}`
                    }
                  } else {
                    for (let i = 0; i < action.from.length; i++) {
                      if (action.from[i] === '$item') {
                        action.from[i] = prepend
                      } else if (action.from[i][0] !== '#') {
                        action.from[i] = `${prepend}.${action.from[i]}`
                      }
                    }
                  }
                }
              })
            }
          }
          rebase(action1, from1, common)
          rebase(action2, from2, common)
        }

        /*
         * Consolidates the mapping rules down to a canonical set
         * @param actions
         * @param prefixPath (if the actions are nested, this is the source path of the parent)
         * @param getSchemaObject function
         * @return actionInfo object
         * by merging rules with common ancestors
         */
        function consolidateActions(actions, prefixPath, getSchemaObject) {
          let current = 0
          let compare
          let merge
          let currentAction
          let compareAction
          let mergeAction
          let fromPath

          const info = {unmergedTargets: {}}
          const mergeSpecified = {}

          // First Pass: Consolidate actions that have exactly the same
          // target and source
          while (current < actions.length) {
            currentAction = actions[current]
            if (!currentAction.actions) {
              // no actions means no iteration nesting, so no consolidation required
              current++
              continue
            }
            // Remember that merge is specified for this target
            if (currentAction.$$merge) {
              mergeSpecified[currentAction.create || currentAction.set] = true
            }
            compare = current + 1
            while (compare < actions.length) {
              compareAction = actions[compare]
              if (!compareAction.actions) {
                compare++
                continue
              }
              // Remember that merge is specified for this target
              if (compareAction.$$merge) {
                mergeSpecified[compareAction.create || compareAction.set] = true
              }
              // If there is a direct match, then consolidate the compareAction into the currentAction
              if (actionsMatch(currentAction, compareAction)) {
                rebaseActions(currentAction, compareAction)
                currentAction.from = currentAction.from || compareAction.from
                currentAction.foreach =
                  currentAction.foreach || compareAction.foreach
                currentAction.actions = currentAction.actions.concat(
                  compareAction.actions
                )
                currentAction.$$merge =
                  currentAction.$$merge || compareAction.$$merge
                currentAction.$$merged = true
                compareAction.$$merged = true
                actions.splice(compare, 1) // remove the compare action
                compare = current + 1 // compare the actions starting after the currentAction index
              } else {
                compare++
              }
            }
            current++
          }

          // Second Pass: If an action has 'merge' selected and has an array source, then
          // merge other compatible actions into this action
          let signature
          let mergePath
          let isMerge
          merge = 0
          while (merge < actions.length) {
            mergeAction = actions[merge]
            if (!mergeAction.actions || !mergeAction.$$merge) {
              merge++
              continue
            }
            // If this merge action has a signature, then its source is an array
            signature = getPrimarySourceArraySignature(
              mergeAction,
              prefixPath,
              getSchemaObject
            )
            if (!signature) {
              merge++
              continue
            }
            if (hasRelativeFrom(mergeAction.actions)) {
              mergePath = mergeAction.foreach
            }
            compare = 0
            isMerge = false
            while (compare < actions.length && !isMerge) {
              compareAction = actions[compare]
              if (merge === compare || !compareAction.actions) {
                compare++
                continue
              }
              if (compareAction.$$merge) {
                // If the comparison action is for a non-array, defer until the third pass.
                if (
                  !getPrimarySourceArraySignature(
                    compareAction,
                    prefixPath,
                    getSchemaObject
                  )
                ) {
                  compare++
                  continue
                }
              }

              // If a match, merge the compareAction into the mergeAction
              if (
                actionsMergeMatch(
                  mergeAction,
                  compareAction,
                  prefixPath,
                  getSchemaObject
                )
              ) {
                isMerge = true
                rebaseActions(mergeAction, compareAction, mergePath)
                mergeAction.from = compareAction.from || mergeAction.from
                mergeAction.foreach =
                  compareAction.foreach || mergeAction.foreach
                mergeAction.actions = compareAction.actions.concat(
                  mergeAction.actions
                )
                mergeAction.$$merged = true
                actions.splice(compare, 1)
              } else {
                compare++
              }
            }
            if (!isMerge) {
              merge++
            } else {
              merge = 0 // Start over and evaluate again
            }
          }

          // Third Pass: If an action has 'merge' selected and is NOT an array source, then
          // merge this action into another compatible action.
          merge = 0
          while (merge < actions.length) {
            mergeAction = actions[merge]
            if (!mergeAction.actions || !mergeAction.$$merge) {
              merge++
              continue
            }
            // If this merge action has NO signature, then its source is not an array
            signature = getPrimarySourceArraySignature(
              mergeAction,
              prefixPath,
              getSchemaObject
            )
            if (signature) {
              merge++
              continue
            }
            compare = 0
            isMerge = false
            // Current semantic is to merge the non-array map into evey
            // matching action.  To change this behavior to merge it into
            // only the first matching action, use:
            //
            // while (compare < actions.length && !isMerge) {
            while (compare < actions.length) {
              compareAction = actions[compare]
              if (merge === compare || !compareAction.actions) {
                compare++
                continue
              }

              if (hasRelativeFrom(compareAction.actions)) {
                mergePath = compareAction.foreach
              }

              // If a match, merge the compareAction with the mergeAction
              mergeAction = angular.copy(actions[merge])
              if (
                actionsMergeMatch(
                  compareAction,
                  mergeAction,
                  prefixPath,
                  getSchemaObject
                )
              ) {
                isMerge = true
                rebaseActions(compareAction, mergeAction, mergePath)
                compareAction.from = compareAction.from || mergeAction.from
                compareAction.foreach =
                  compareAction.foreach || mergeAction.foreach
                compareAction.actions = compareAction.actions.concat(
                  mergeAction.actions
                )
              }
              compare++
            }
            if (!isMerge) {
              merge++
            } else {
              actions.splice(merge, 1) // remove merged action
            }
          }

          // Fourth Pass: Now consolidate nested actions
          const targets = {}
          for (let i = 0; i < actions.length; i++) {
            currentAction = actions[i]
            if (currentAction.actions) {
              // Record the number of actions for this target
              targets[currentAction.create || currentAction.set] = targets[
                currentAction.create || currentAction.set
              ]
                ? targets[currentAction.create || currentAction.set] + 1
                : 1
              fromPath = currentAction.from
                ? currentAction.from.replace(
                  '$item',
                  `${currentAction.foreach}.`
                )
                : ''
              fromPath = prefixPath ? `${prefixPath}.${fromPath}` : fromPath
              const actionInfo = consolidateActions(
                currentAction.actions,
                fromPath,
                getSchemaObject
              )
              for (const key in actionInfo.info.unmergedTargets) {
                info.unmergedTargets[key] = actionInfo.info.unmergedTargets[key]
              }
              currentAction.actions = actionInfo.actions
            }
          }
          // If there are multiple targets in this array of actions AND
          // merge was specified by the user, indicate that there are multiple unmerged targets.
          // This information will trigger a popup in the calling code.
          for (const tgt in targets) {
            if (mergeSpecified[tgt] && targets[tgt] > 1) {
              info.unmergedTargets[tgt] = true
            }
          }

          return {info, actions}
        }

        /**
         * @param moreInfo (optional) if true return actions plus additional information, else just the actions array
         * @return actions array or actionInfo object
         */
        self.getActions = function(moreInfo) {
          const actions = []
          model.forEach(function(mapping) {
            serializeMapping(mapping, actions)
          })
          const actionInfo = consolidateActions(
            actions,
            null,
            self.getSchemaObject
          )
          // The to/from code removes the temporary $$var keys from the objects
          actionInfo.actions = angular.fromJson(
            angular.toJson(actionInfo.actions)
          )
          if (moreInfo) {
            return actionInfo
          }
          return actionInfo.actions
        }

        self.getModel = function() {
          return model
        }

        /**
         * @param input name
         * @return propert name (trailing array indices are removed)
         */
        function getPropertyName(input) {
          const name = splitTerms(input) // Only split at non-escaped periods
          let done = false
          while (name.length > 0 && !done) {
            if (isNaN(parseInt(name[name.length - 1]))) {
              done = true
            } else {
              name.pop() // Ignore trailing array indices
            }
          }
          return name.join('.')
        }

        self.pruneModel = function(
          inputs,
          outputs,
          saveDiscriminator,
          checkOnly
        ) {
          if (self.readOnly) return
          const inputSchemas = inputs
          const outputSchemas = outputs
          const prunedModel = []
          let modelChanged = false
          let keepDiscriminator = true
          if (saveDiscriminator === false) {
            keepDiscriminator = false
          }
          const unrecognized = []
          model.forEach(function(mapping) {
            const source = []
            let propertyName
            // check the sources
            if (mapping.source) {
              for (let i = 0; i < mapping.source.length; i++) {
                propertyName = getPropertyName(mapping.source[i])
                if (
                  !SchemaReferences.propertyExistsInSchemas(
                    propertyName,
                    inputs,
                    definitions,
                    keepDiscriminator
                  )
                ) {
                  if (unrecognized.indexOf(propertyName) === -1) {
                    unrecognized.push(propertyName)
                  }
                  modelChanged = true
                } else {
                  source.push(propertyName)
                }
              }
              if (source.length === 0) return
            }

            // check the target
            propertyName = getPropertyName(mapping.target)
            if (
              mapping.target &&
              !SchemaReferences.propertyExistsInSchemas(
                propertyName,
                outputs,
                definitions,
                keepDiscriminator
              )
            ) {
              if (unrecognized.indexOf(propertyName) === -1) {
                unrecognized.push(propertyName)
              }
              modelChanged = true
              return
            } else if (!mapping.target) {
              // target doesn't exist (illegal action like in apiconnect-assembly/#416)
              if (unrecognized.indexOf(mapping.source[0]) === -1) {
                unrecognized.push(mapping.source[0])
              }
              modelChanged = true
              return
            }

            // it exists, keep it
            if (
              source.length === 0 ||
              source.length === mapping.source.length
            ) {
              // no changes to source, so we can use the existing mapping
              prunedModel.push(mapping)
            } else {
              // one (or more) of the source schemas was not found, so we need to create a new
              // mapping object that can be used if the user decides to perform the pruning
              const newMapping = angular.fromJson(angular.toJson(mapping))
              newMapping.source = source
              prunedModel.push(newMapping)
            }
          })

          if (modelChanged) {
            if (!checkOnly) {
              model = prunedModel
              callback(self.getActions(true))
            }
          }

          return unrecognized
        }

        function updateSource(obj, newText, oldText) {
          let changed = false
          if (obj && obj.source) {
            if (typeof obj.source === 'string') {
              if (obj.source === oldText) {
                obj.source = newText
                changed = true
              } else if (obj.source.indexOf(`${oldText}.`) === 0) {
                obj.source = newText + obj.source.substring(oldText.length)
                changed = true
              }
            } else {
              for (let i = 0; i < obj.source.length; i++) {
                if (obj.source[i] === oldText) {
                  obj.source[i] = newText
                  changed = true
                } else if (obj.source[i].indexOf(`${oldText}.`) === 0) {
                  obj.source[i] =
                    newText + obj.source[i].substring(oldText.length)
                  changed = true
                }
              }
            }
          }
          return changed
        }

        function updateTarget(obj, newText, oldText) {
          let changed = false
          if (obj && obj.target) {
            if (obj.target === oldText) {
              obj.target = newText
              changed = true
            } else if (obj.target.indexOf(`${oldText}.`) === 0) {
              obj.target = newText + obj.target.substring(oldText.length)
              changed = true
            }
          }
          return changed
        }

        function updateValue(obj, newText, oldText) {
          let changed = false
          if (obj.value) {
            let i = obj.value.indexOf(oldText)
            while (i > -1) {
              obj.value =
                obj.value.substring(0, i) +
                newText +
                obj.value.substring(i + oldText.length)
              i = obj.value.indexOf(oldText, i + newText.length)
              changed = true
            }
          }
          return changed
        }

        self.inputRenamed = function(newName, oldName) {
          const newPath = newName.replace(/\./g, '\\.')
          const oldPath = oldName.replace(/\./g, '\\.')
          const newAbsolute = `#/${newPath}`
          const oldAbsolute = `#/${oldPath}`
          let modelChanged = false
          model.forEach(function(mapping) {
            if (mapping.source) {
              if (updateSource(mapping, newPath, oldPath)) modelChanged = true
              if (updateSource(mapping, newAbsolute, oldAbsolute))
                modelChanged = true
            }
            if (mapping.value) {
              if (updateValue(mapping, `$(${newPath})`, `$(${oldPath})`))
                modelChanged = true
              if (updateValue(mapping, `$(${newPath}.`, `$(${oldPath}.`))
                modelChanged = true
              if (
                updateValue(mapping, `$(${newAbsolute})`, `$(${oldAbsolute})`)
              )
                modelChanged = true
              if (
                updateValue(mapping, `$(${newAbsolute}.`, `$(${oldAbsolute}.`)
              )
                modelChanged = true
            }
            if (mapping.foreach) {
              if (mapping.foreach[0]) {
                if (mapping.foreach[0].source) {
                  if (updateSource(mapping.foreach[0], newPath, oldPath))
                    modelChanged = true
                }
                if (mapping.foreach[0].value) {
                  if (
                    updateValue(
                      mapping.foreach[0],
                      `$(${newPath})`,
                      `$(${oldPath})`
                    )
                  )
                    modelChanged = true
                  if (
                    updateValue(
                      mapping.foreach[0],
                      `$(${newPath}.`,
                      `$(${oldPath}.`
                    )
                  )
                    modelChanged = true
                }
              }
              mapping.foreach.forEach(function(fe) {
                if (fe.source) {
                  if (updateSource(fe, newAbsolute, oldAbsolute))
                    modelChanged = true
                }
                if (fe.value) {
                  if (
                    updateValue(
                      mapping,
                      `$(${newAbsolute})`,
                      `$(${oldAbsolute})`
                    )
                  )
                    modelChanged = true
                  if (
                    updateValue(
                      mapping,
                      `$(${newAbsolute}.`,
                      `$(${oldAbsolute}.`
                    )
                  )
                    modelChanged = true
                }
              })
            }
          })
          if (modelChanged) {
            callback(self.getActions(true))
          }
        }

        self.outputRenamed = function(newName, oldName) {
          const newPath = newName.replace(/\./g, '\\.')
          const oldPath = oldName.replace(/\./g, '\\.')
          const newAbsolute = `#/${newPath}`
          const oldAbsolute = `#/${oldPath}`
          let modelChanged = false
          model.forEach(function(mapping) {
            if (mapping.target) {
              if (updateTarget(mapping, newPath, oldPath)) modelChanged = true
              if (updateTarget(mapping, newAbsolute, oldAbsolute))
                modelChanged = true
            }
            if (mapping.value) {
              if (updateValue(mapping, `$(${newPath})`, `$(${oldPath})`))
                modelChanged = true
              if (updateValue(mapping, `$(${newPath}.`, `$(${oldPath}.`))
                modelChanged = true
              if (
                updateValue(mapping, `$(${newAbsolute})`, `$(${oldAbsolute})`)
              )
                modelChanged = true
              if (
                updateValue(mapping, `$(${newAbsolute}.`, `$(${oldAbsolute}.`)
              )
                modelChanged = true
            }
            if (mapping.foreach) {
              if (mapping.foreach[0]) {
                if (mapping.foreach[0].target) {
                  if (updateTarget(mapping.foreach[0], newPath, oldPath))
                    modelChanged = true
                }
                if (mapping.foreach[0].value) {
                  if (
                    updateValue(
                      mapping.foreach[0],
                      `$(${newPath})`,
                      `$(${oldPath})`
                    )
                  )
                    modelChanged = true
                  if (
                    updateValue(
                      mapping.foreach[0],
                      `$(${newPath}.`,
                      `$(${oldPath}.`
                    )
                  )
                    modelChanged = true
                }
              }
              mapping.foreach.forEach(function(fe) {
                if (fe.target) {
                  if (updateTarget(fe, newAbsolute, oldAbsolute))
                    modelChanged = true
                }
                if (fe.value) {
                  if (
                    updateValue(
                      mapping,
                      `$(${newAbsolute})`,
                      `$(${oldAbsolute})`
                    )
                  )
                    modelChanged = true
                  if (
                    updateValue(
                      mapping,
                      `$(${newAbsolute}.`,
                      `$(${oldAbsolute}.`
                    )
                  )
                    modelChanged = true
                }
              })
            }
          })
          if (modelChanged) {
            callback(self.getActions(true))
          }
        }

        return self
      }
    },
  ])
  .factory('outputParser', () => {
    function parse(group, sourceTypes) {
      if (!group) return ''
      let str = group.isNotGroup ? '$not(' : '('
      for (let i = 0; i < group.conditions.length; i++) {
        const condition = group.conditions[i]
        if (!!condition && !!condition.sourceField) {
          const sourceType = _.find(sourceTypes[0].sourceFields, function(
            field
          ) {
            return field.displayName === condition.sourceField.displayName
          })
          if (
            !!condition.inputItem &&
            condition.sourceField.displayName !== 'Custom'
          ) {
            const param = condition.inputItem.parameter
            if (typeof param !== 'undefined') {
              const paramList = []
              for (const i of param) {
                typeof i.value !== 'undefined' &&
                  paramList.push(
                    i.quoteParameter ? `"${i.value}"` : i.value ? i.value : "''"
                  )
              }
              for (
                let removeIdx = paramList.length - 1;
                removeIdx > -1;
                removeIdx--
              ) {
                if (!paramList[removeIdx] || paramList[removeIdx] === "''") {
                  paramList.splice(removeIdx, 1)
                } else {
                  break
                }
              }
              str += `${condition.sourceField.displayName.slice(0, -1) +
                paramList.join(', ')})`
            } else {
              str += condition.sourceField.displayName
            }
            if (
              condition.comparisonOperator &&
              typeof condition.inputItem.displayName !== 'undefined'
            )
              str += ` ${condition.comparisonOperator.displayName}`
            if (condition.inputItem.displayName) {
              str += sourceType.quoteInput
                ? ` '${condition.inputItem.displayName}'`
                : ` ${condition.inputItem.displayName}`
            }
          }
          if (
            condition.inputItem &&
            condition.sourceField.displayName === 'Custom'
          ) {
            str += condition.inputItem.custom
          }
        } else if (condition.logicalOperator) {
          str += ` ${condition.logicalOperator} `
        }
      }

      if (!!group.groups && group.groups instanceof Array) {
        for (let x = 0; x < group.groups.length; x++) {
          if (!!group.logicalOperator && !!group.conditions) {
            if (group.conditions.length > 0 || x > 0) {
              str += ` ${group.logicalOperator.name} `
            }
            str += parse(group.groups[x], sourceTypes)
          }
        }
      }
      return `${str})`
    }

    function collectSpecialChar(str) {
      const specialChars = {
        '"': [],
        "'": [],
        ']': [],
        '[': [],
      }
      const res = {}
      for (let i = 0; i < str.length; i++) {
        if (
          typeof specialChars[str[i]] !== 'undefined' &&
          specialChars[str[i - 1]] !== '\\'
        ) {
          specialChars[str[i]].push(i)
        }
      }
      // collect double/single quote literal strings
      res.literalGroups = parseQuote(specialChars)
      // collect brackets
      const brackets = ['[', ']']
      brackets.forEach(char => {
        const ref = specialChars[char]
        for (let i = ref.length - 1; i > -1; i--) {
          if (
            !isParamSeparator(
              {
                literalGroups: res.literalGroups,
              },
              ref[i]
            )
          )
            ref.splice(i, 1)
        }
      })
      res.bracketGroups = specialChars['['].map((idx, i) => {
        return [idx, specialChars[']'][i]]
      })
      return res
    }

    function parseQuote(arr) {
      const doubleQuote = '"'
      const singleQuote = "'"
      const temp = {}
      let cur = '0'
      const res = []
      if (arr[doubleQuote].length < 1 && arr[singleQuote].length < 1) {
        return []
      } else if (arr[doubleQuote].length < 1) {
        temp['0'] = arr[singleQuote]
        res.push(arr[singleQuote].shift())
      } else if (arr[singleQuote].length < 1) {
        temp['0'] = arr[doubleQuote]
        res.push(arr[doubleQuote].shift())
      } else if (arr[doubleQuote][0] < arr[singleQuote][0]) {
        temp['0'] = arr[doubleQuote]
        temp['1'] = arr[singleQuote]
        curNum = arr[doubleQuote][0]
        res.push(arr[doubleQuote].shift())
      } else {
        temp['0'] = arr[singleQuote]
        temp['1'] = arr[doubleQuote]
        curNum = arr[singleQuote][0]
        res.push(arr[singleQuote].shift())
      }
      while (temp['0'].length > 0 || temp['1'].length > 0) {
        const target = cur ^ 1
        let nextShift = (temp[target] && temp[target].shift()) || undefined
        const nextNum = temp[cur].shift()
        if (!isUndefined(nextNum)) {
          res.push(nextNum)
        } else if (!isUndefined(nextShift)) {
          cur = target
          res.pop()
          res.push(nextShift)
          continue
        }
        while (!isUndefined(nextShift) && nextShift < nextNum) {
          nextShift = temp[target].shift()
        }
        if (!isUndefined(nextShift)) {
          if (temp[cur][0] < nextShift) {
            res.push(temp[cur].shift())
          } else {
            res.push(nextShift)
            cur = target
          }
        } else {
          if (!isUndefined(temp[cur][0]) && !isUndefined(temp[cur][1])) {
            res.push(temp[cur].shift())
          } else {
            return groupQuote(res)
          }
        }
      }
      return groupQuote(res)
    }

    function groupQuote(arr) {
      const res = []
      for (let i = 0; i < arr.length;) {
        if (!isUndefined(arr[i]) && !isUndefined(arr[i + 1])) {
          res.push([arr[i], arr[i + 1]])
        }
        i = i + 2
      }
      return res
    }

    function isParamSeparator(groups, i) {
      // check if is inside literal string
      let res = true
      Object.keys(groups).forEach(key => {
        groups[key].forEach(range => {
          if (i > range[0] && i < range[1]) res = false
        })
      })
      return res
    }

    function splitParameters(str) {
      const literalStrings = collectSpecialChar(str)
      const res = []
      let pointer = 0
      for (let i = 0; i <= str.length; i++) {
        if (
          (str[i] === ',' && isParamSeparator(literalStrings, i)) ||
          isUndefined(str[i])
        ) {
          // only separate when it's not in literal string
          res.push(str.substring(pointer, i))
          pointer = i + 1
        }
      }
      return res
    }

    function isUndefined(target) {
      return typeof target === 'undefined'
    }

    return {
      parse,
      collectSpecialChar,
      splitParameters,
    }
  })
  .service('ExtensionType', [
    'Path',
    function(Path) {
      let handler = null
      const self = this

      self.setHandler = function(inHandler) {
        handler = inHandler
      }

      self.getValueForPath = function(path) {
        const targetPath = handler.paths[path]
        let mapping = null
        if (targetPath) {
          mapping = handler.map.getMappingForPath(targetPath)
        }
        // If there is a default and no source, then return the default
        return mapping &&
          !mapping.from &&
          (!mapping.source || mapping.source.length === 0)
          ? mapping.default
          : null
      }

      self.getDefaultValueForPath = function(path) {
        const targetPath = handler.paths[path]
        let mapping = null
        if (targetPath) {
          mapping = handler.map.getMappingForPath(targetPath)
        }
        return mapping ? mapping.default : null
      }

      self.getNumberOfMappingsForPath = function(path) {
        const targetPath = handler.paths[path]
        return targetPath
          ? handler.map.getNumberOfMappingsForPath(targetPath)
          : 0
      }

      self.setValueForPath = function(path, value) {
        let targetPath = handler.paths[path]
        if (value === undefined || value === null) {
          // remove the mapping
          if (targetPath) {
            handler.map.deleteMappingForPath(targetPath)
          }
        } else {
          if (!targetPath) {
            targetPath = new Path(path, 0)
            handler.paths[path] = targetPath
          }
          let mapping = handler.map.getMapping(targetPath)
          if (!mapping) {
            mapping = handler.map.setMappingForPath(targetPath) // creates the output target path
            mapping.default = value
            handler.map.setMappingForPath(targetPath) // updates and serialises the new mapping value
          } else {
            mapping.default = value
            handler.map.setMappingForPath(targetPath)
          }
        }
        handler.markDirty()
        handler.selectedNode.actions = handler.map.getActions()
      }
    },
  ])
  .service('policyService', function() {
    const self = this
    self.createPolicyInstance = function(policy, injectVersion) {
      if (!policy.info) {
        // we don't have a schema here, assume it's already an instance
        return policy
      }
      const node = {}
      if (!policy.info.name) {
        return node
      }
      // policy.info.name is e.g. "SAP", "operation-switch", "m
      const properties = policy.properties
      let action = {
        $$type: policy.info.name,
        $$categories: policy.info.categories,
        $$display: policy.info.display,
        $$schema: properties,
      }
      // default a title if there are any properties defined
      if (properties) {
        // be careful - display is not required
        if (policy.info.display && policy.info.display.name) {
          action.title = policy.info.display.name
        } else {
          action.title = policy.info.name
        }
      }
      if (policy.info.name === 'operation-switch') {
        angular.extend(action, {
          case: [
            {
              operations: [],
              execute: [],
            },
          ],
          otherwise: [],
        })
      } else if (policy.info.name === 'switch') {
        angular.extend(action, {
          case: [
            {
              condition: policy.info.version === '2.0.0' ? 'true' : '',
              execute: [],
            },
          ],
        })

        // Load if with else statement if in AppC
        if (
          policy.info.categories &&
          policy.info.categories.indexOf('AppConnect') > -1
        ) {
          action.case.push({otherwise: []})
        }
      } else if (policy.info.name === 'if') {
        angular.extend(action, {
          condition: 'true',
          execute: [],
        })
      } else if (policy.info.name === 'map') {
        angular.extend(action, {
          inputs: {},
          outputs: {},
          actions: [],
        })
      } else if (policy.info.name === 'set-variable') {
        angular.extend(action, {
          actions: [],
        })
      } else if (policy.info.name === 'Application') {
        angular.extend(action, {
          selectedApplication: {},
          selectedAction: {},
          $$advanced: false,
        })
      } else if (policy.info.name === 'Trigger') {
        angular.extend(action, {
          selectedApplication: {},
          selectedTrigger: {},
        })
      } else {
        // there may not be any properties...
        if (properties) {
          angular.extend(action, jsonSchemaDefaults(properties))
        }
        // fix stop-on-error bug
        if (policy.info.name === 'invoke' && node && node.invoke) {
          delete node.invoke['stop-on-error']
        }
      }
      if ((policy.custom || injectVersion) && policy.info.version) {
        // also inject the version at the top
        action = {
          version: policy.info.version,
          ...action,
        }
      }
      node[policy.info.name] = action
      return node
    }
  })
  .service('triggerService', function() {
    const self = this

    self.deleteTrigger = function(trigger) {
      trigger.selectedApplication = {}
      trigger.selectedTrigger = {}
      delete trigger.accountName
      delete trigger.$$touched
      delete trigger.$$errors
      delete trigger.auth
      delete trigger.options
      delete trigger.outputSchema
    }

    self.setTriggerApplication = function(trigger, selectedApplication) {
      delete trigger.$$errors
      const triggerApplication = {
        name: selectedApplication.name,
        displayName: selectedApplication.displayName,
      }

      trigger.selectedApplication = angular.copy(triggerApplication)

      // Single target object
      trigger.selectedTrigger.name = selectedApplication.task.name
      trigger.selectedTrigger.displayName = selectedApplication.task.displayName
      trigger.selectedTrigger.dataModel = selectedApplication.task.dataModel
      trigger.selectedTrigger.hasConfig = selectedApplication.task.hasConfig
    }
  })
  .factory('errorFactory', [
    '$rootScope',
    '$mdDialog',
    '$mdPanel',
    function($rootScope, $mdDialog, $mdPanel) {
      return {
        throwError(errorObject) {
          // Setup scope for error dialog directive
          const $newScope = $rootScope.$new()
          $newScope.errorObject = errorObject
          $mdDialog.show({
            fullscreen: true,
            parent: document.body,
            scope: $newScope,
            template:
              '<md-dialog class="error-dialog"><error-dialog></error-dialog></md-dialog>',
          })
        },

        showWarning(warningObject) {
          // Setup scope for error dialog directive
          const $newScope = $rootScope.$new()
          $newScope.warningObject = warningObject
          $newScope.panelReference = $mdPanel.create({
            attachTo: document.body.querySelector('apim-assembler'),
            panelClass: 'warning-dialog',
            scope: $newScope,
            template: '<warning-dialog></warning-dialog>',
            trapFocus: false,
          })
          $newScope.panelReference.open()

          return $newScope.panelReference
        },
      }
    },
  ])
  .factory('AssemblerTracking', [
    '$rootScope',
    function($rootScope) {
      const svc = {}

      const trackingDictionary = {
        assembly: {
          policyDropped: {
            eventName: 'Created Object',
            data: {
              object: 'Drop Assembler Policy',
              productTitle: 'API Connect',
              productVersion: 'APIConnect-V6',
            },
          },
        },
      }

      // todo: ettinger - add tracking back when telemetry is added to velox
      svc.track = function(ns, key, data) {
        if (parent.window.bluemixAnalytics) {
          if (trackingDictionary[ns] && trackingDictionary[ns][key]) {
            const eventName = trackingDictionary[ns][key]
            const dataObj = {
              category: data.categories,
              objectType: data.policyName,
            }
            const eventData = Object.assign(
              trackingDictionary[ns][key].data,
              dataObj
            )
            parent.window.bluemixAnalytics.trackEvent(
              eventName.eventName,
              eventData
            )
          }
        } else return null
      }

      return svc
    },
  ])
  .service('canvasAnimationService', [
    function() {
      const self = this
      self.canvasXPosition = 0

      self.reset = function() {
        self.initialPositionSet = false
        self.canvasXPosition = 0
      }

      self.animateCanvasSlide = function() {
        let newPosition = self.canvasXPosition || 0
        let bounds = {}
        const windowWidth = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        )
        const focusNodeElement = document.querySelector('.isSelected')

        // Only animate to selected nodes
        if (focusNodeElement) {
          bounds = focusNodeElement.getBoundingClientRect()
          newPosition = windowWidth / 2 - bounds.right + bounds.width / 2
          newPosition += self.canvasXPosition
          self.canvasXPosition = newPosition
        }

        return newPosition
      }
    },
  ])
  .service('assemblyModel', function() {
    const self = this
    self.flow = {
      trigger: null,
      nodes: [],
      request: null,
      response: null,
    }

    self._searchForSelectedNode = function(nodesArray) {
      // Remove top level keys from nodes array
      const nodes = nodesArray.map(function(node) {
        return node.$$type ? node : node[Object.keys(node)[0]]
      })

      // loop through top level nodes
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].$$isSelected) {
          return nodes[i]
        }

        if (nodes[i].$$type === 'switch') {
          // loop through each case statement
          for (let j = 0; j < nodes[i].case.length; j++) {
            const foundNode = self._searchForSelectedNode(
              nodes[i].case[j].execute || nodes[i].case[j].otherwise
            )
            if (foundNode) {
              return foundNode
            }
          }
        }
      }
      return
    }

    self._searchForNode = function(nodes, nodeToFind) {
      // loop through top level nodes
      for (let i = 0; i < nodes.length; i++) {
        // Remove any top level keys (i.e 'Application')
        const keys = Object.keys(nodes[i])
        const currentNode = keys.length === 1 ? nodes[i][keys[0]] : nodes[i]

        if (currentNode === nodeToFind) {
          return true
        }

        if (currentNode.$$type === 'switch') {
          // loop through each case statement
          for (let j = 0; j < currentNode.case.length; j++) {
            const nodesArray =
              currentNode.case[j].execute || currentNode.case[j].otherwise
            const foundNode = self._searchForNode(nodesArray, nodeToFind)
            if (foundNode) {
              return true
            }
          }
        }
      }
      return false
    }

    self.getSelectedNode = function() {
      if (self.flow.trigger && self.flow.trigger.$$isSelected) {
        return self.flow.trigger
      } else if (self.flow.response && self.flow.response.$$isSelected) {
        return self.flow.response
      }
      return self._searchForSelectedNode(self.flow.nodes)
    }

    self.containsSelectedNode = function(node) {
      return self._searchForSelectedNode([node]) ? true : false
    }

    self.containsNode = function(node, nodeToFind) {
      return self._searchForNode([node], nodeToFind)
    }

    self.countInnerActions = function(node) {
      let count = 0

      if (node.$$type !== 'switch') {
        return 0
      }

      var recursiveCount = function(node) {
        // loop through each case statement
        for (let i = 0; i < node.case.length; i++) {
          const nodeBranch = node.case[i].execute || node.case[i].otherwise

          for (let j = 0; j < nodeBranch.length; j++) {
            if (nodeBranch[j].switch) {
              recursiveCount(nodeBranch[j].switch)
            } else {
              count++
            }
          }
        }
      }

      recursiveCount(node)
      return count
    }
  })
