// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `No se ha actualizado el catálogo`,
  [`Catalog has been updated`]: `Se ha actualizado el catálogo`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `El producto borrador {draftProduct} debe hacer referencia a una o más API`,
  [`Number of Calls`]: `Número de llamadas`,
  [`Changes will apply to newly published APIs only`]: `Los cambios solo se aplicarán a las API recién publicadas`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Hay {label} incompatibles debido a que tienen un tipo de pasarela diferente en esta API.`,
  [`{label} is required`]: `{label} es obligatorio`,
  [`Application is required`]: `La aplicación es obligatoria`,
  [`Plan is required`]: `El plan es obligatorio`,
  [`Gateway is required`]: `La pasarela es obligatoria`,
  [`Consumer Org is required`]: `La organización de consumidores es obligatoria`,
  [`LDAP ATTRIBUTE NAME`]: `NOMBRE DE ATRIBUTO DE LDAP`,
  [`Use the build in Test Application (sandbox only)`]: `Utilizar la aplicación de prueba incorporada (sólo recinto de pruebas)`,
  [`Select an Application`]: `Seleccione una aplicación`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Utilizar la aplicación de prueba incorporada (solo recinto de pruebas) - inhabilitada cuando se elige un catálogo que no sea de recinto de pruebas`,
  [`Choose an existing Application`]: `Elegir una aplicación existente`,
  [`Use JWT for gateway authentication to analytics service`]: `Utilizar JWT para la autenticación de pasarela en el servicio de análisis`,
  [`Select an application to consume the API`]: `Seleccionar una aplicación para consumir la API`,
  [`Use the default built-in Sandbox Catalog'`]: `Utilizar el catálogo de recinto de pruebas incorporado predeterminado`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: este catálogo se utilizará como ámbito para todas las demás preferencias. El cambio de este valor requerirá ajustar otras preferencias.`,
  [`Select a Consumer Org`]: `Seleccionar una organización de consumidores`,
  [`Select the Consumer Org your generated application will belong to`]: `Seleccione la organización de consumidores a la que pertenecerá la aplicación generada`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Nota: esta opción sólo se puede cambiarse cuando se utiliza la aplicación de prueba de recinto de pruebas incorporada, ya que las aplicaciones existentes ya pertenecerán a una organización de consumidores`,
  [`Generate auto product`]: `Generar producto automático`,
  [`Select a product to associate the current API with`]: `Seleccione un producto al que asociar la API actual`,
  [`Associate the current API to a selected product`]: `Asociar la API actual a un producto seleccionado`,
  [`API management`]: `Gestión de API`,
  [`Select the API management instance you would like this API to be published to.`]: `Seleccione la instancia de gestión de API para la que desea que se publique esta API.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: esta instancia se utilizará como ámbito para todas las demás preferencias. El cambio de este valor requerirá ajustar otras preferencias.`,
  [`Provider organization`]: `Organización de proveedores`,
  [`Select the provider organization you would like this API to be published to.`]: `Seleccione la organización de proveedor para la que desea que se publique esta API.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: esta organización se utilizará como ámbito para todas las demás preferencias. El cambio de este valor requerirá ajustar otras preferencias.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: este espacio se utilizará como ámbito para todas las demás preferencias. El cambio de este valor requerirá ajustar otras preferencias.`,
  [`Selected a draft product`]: `Seleccionar un borrador de producto`,
  [`Use any available gateway service`]: `Utilizar cualquier servicio de pasarela disponible`,
  [`Select a compatible gateway service`]: `Seleccionar un servicio de pasarela compatible`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Nota: este servicio de pasarela se utilizará como ámbito para todas las demás preferencias. Las políticas y otro contenido específico del servicio de pasarela se cargarán en función de este valor.`,
  [`Select a gateway`]: `Seleccionar una pasarela`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Nota: esta opción está inhabilitada cuando se selecciona la opción 'Producto existente' y, en su lugar, se utilizará el plan predeterminado para el producto generado`,
  [`Select a Plan`]: `Seleccionar un plan`,
  [`Generate subscription`]: `Generar suscripción`,
  [`A subscription will be created between the selected Product plan and Application`]: `Se creará una suscripción entre el plan de producto seleccionado y la aplicación`,
  [`Apply a rate limit to the generated product`]: `Aplicar un límite de velocidad al producto generado`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Aplique un límite de velocidad al plan predeterminado de los productos generados. Esta opción está inhabilitada cuando se selecciona la opción 'Producto existente' y en su lugar se utilizará el límite de velocidad definido en el plan seleccionado`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `LDAP (Lightweight Directory Access Protocol) es un protocolo Internet para acceder y mantener servicios de información de directorio distribuido a través de una red. Si tiene habilitado un servicio LDAP empresarial, configúrelo como recurso para proporcionar autenticación de usuario. Si además desea facilitar la incorporación de los nuevos usuarios del portal del desarrollador, debe completar la sección de correlación de atributos para permitir LDAP grabable. En esta sección, seleccione el recuadro de selección Gestionado por el usuario y proporcione la correlación de los nombres de atributo LDAP de origen con los valores de API Connect de destino.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Habilitar la autoaprobación de tareas permite que tanto el originador como los colaboradores puedan aprobar las tareas. Esta opción puede resultar conveniente cuando no haya otros aprobadores disponibles, pero efectivamente permite ignorar las comprobaciones realizadas por otro usuario.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Una integración de facturación conecta API Connect con una cuenta de un sistema de facturación de suscripción de terceros que gestiona clientes, sus métodos de pago, la facturación y las suscripciones activas.`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Un objeto de proveedor de OAuth nativo proporciona valores para las operaciones de proceso de OAuth como por ejemplo generar y validar las señales de OAuth. Un objeto de proveedor de OAuth puede ser referenciado por una definición de seguridad de OAuth para proteger una API. Cuando se utiliza un proveedor de OAuth nativo, API Connect realiza de forma nativa las operaciones de OAuth. Cada objeto de proveedor de OAuth tiene una API de respaldo. La configuración que realiza aquí actualiza automáticamente el documento Swagger de la API. Puede editar el documento Swagger yendo a la página Editor de API.  Cuando una API publicada hace referencia a un objeto de proveedor de OAuth, la API de respaldo queda automáticamente disponible en la pasarela.`,
  [`About token management`]: `Acerca de la gestión de señales`,
  [`About user security`]: `Acerca de la seguridad de usuario`,
  [`APIs or products`]: `APIs o productos`,
  [`ATM is not deployed, please contact your administrator`]: `ATM no está desplegado, póngase en contacto con el administrador.`,
  [`Application state change approvals will be enabled`]: `Se habilitarán las aprobaciones de cambio de estado de aplicación`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Defina los valores a utilizar para extraer las credenciales de los usuarios de aplicación, autenticar sus identidades y otorgar autorización.`,
  [`See console for details.`]: `Consulte la consola para conocer los detalles.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `El servicio de Certificate Manager seleccionado no gestiona ningún certificado.`,
  [`The selected product is not published to the Sandbox catalog`]: `El producto seleccionado no se ha publicado en el catálogo de recinto de pruebas`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `El producto seleccionado no se ha publicado en el catálogo de recinto de pruebas, por lo que no es posible suscribir la aplicación`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `La CLI de IBM Cloud de la plataforma (si no está ya instalado).`,
  [`(Only supported by TLS 1.3)`]: `(Solo soportado por TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `La directiva "@listSize" de este campo define campos dimensionados que no devuelven listas.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `La directiva "@listSize" de este campo define campos dimensionados no definidos en el tipo de retorno del campo.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `La directiva "@listSize" de este campo define argumentos de porcionado no definidos en el campo.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `La directiva "@listSize" de este campo define argumentos de porcionado cuyos tipos no son "Int" o "Int!".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `La directiva "@listSize" de este campo define varios argumentos de porcionado no nulos.`,
  [`"{name}" (Role) has been created`]: `Se ha creado "{name}" (rol).`,
  [`"{name}" (Role) has been updated`]: `Se ha actualizado "{name}" (rol).`,
  [`API ({api}) has been created.`]: `Se ha creado la API ({api}).`,
  [`API ({api}) has been renamed.`]: `Se ha renombrado la API ({api}).`,
  [`API ({api}) has been updated.`]: `Se ha actualizado la API ({api}).`,
  [`API ({api}) has been deleted.`]: `Se ha suprimido la API ({api}).`,
  [`API ({api}) has been published.`]: `Se ha publicado la API ({api}).`,
  [`API ({api}) has validation error.`]: `La API ({api}) tiene un error de validación`,
  [`API ({api}) has validation errors.`]: `La API ({api}) tiene errores de validación`,
  [`API version`]: `Versión de API`,
  [`APIM data version`]: `Versión de datos de APIM`,
  [`APIM schema update date`]: `Fecha de actualización de esquema de APIM`,
  [`APIM schema version`]: `Versión de esquema de APIM`,
  [`APIM target data version`]: `Versión de datos de destino de APIM`,
  [`APIM target schema version`]: `Versión de esquema de destino de APIM`,
  [`APIM update date`]: `Fecha de actualización de APIM`,
  [`Product ({product}) has been created.`]: `Se ha creado el producto ({product}).`,
  [`Product ({product}) has been updated.`]: `Se ha actualizado el producto ({product}).`,
  [`Product ({product}) has been delete.`]: `Se ha suprimido el producto ({product}).`,
  [`Product ({product}) has been published.`]: `Se ha publicado el producto ({product}).`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) ha sido {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `¡"{title}" ({type}) no ha sido {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} después de ponerse en cola)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} después de enviarse)`,
  [`(none)`]: `(ninguno)`,
  [`(optional)`]: `(opcional)`,
  [`*Base endpoint list empty`]: `*Lista de puntos finales base vacía`,
  [`*Must be a valid url`]: `*Debe ser un URL válido`,
  [`*System will also send an email notification to the requester.`]: `*El sistema también enviará una notificación de correo electrónico al solicitante.`,
  [`+ redact from...`]: `+ redactar desde...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. También puede gestionar el ciclo de vida de este producto dentro del catálogo.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x seguido de 64 caracteres hexadecimales.`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Posee y administra organizaciones de consumidores de API 2) Gestiona las comunidades de desarrolladores de aplicaciones 3) Crea definiciones de API y producto 4) Gestiona el ciclo de vida del producto de API`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Posee y administra organizaciones de proveedores de API 2) Gestiona las comunidades de desarrolladores de aplicaciones 3) Crea definiciones de API y producto 4) Gestiona el ciclo de vida del producto de API`,
  [`5 Most Active APIs`]: `5 API más activas`,
  [`5 Most Active Products`]: `5 productos más activos`,
  [`5 most active APIs`]: `5 API más activas`,
  [`5 most active Products`]: `5 productos más activos`,
  [`503 Service Unavailable`]: `503  Servicio no disponible`,
  [`A request to the server was made without any credentials.`]: `Se ha hecho una solicitud al servidor sin credenciales.`,
  [`A server error occurred`]: `Se ha producido un error en el servidor`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Un perfil de cliente TLS configura la cadena de certificados y la suite de cifrado utilizadas por API Connect cuando se conecta como un cliente a otros sistemas.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Un perfil de servidor TLS configura la cadena de certificados y la suite de cifrado utilizadas por API Connect cuando presenta puntos finales de servidor a otros sistemas.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Un catálogo aloja una colección de productos de API que son visibles en el portal de desarrollador asociado cuando se publican`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Un catálogo aloja una colección de productos de API que son visibles en el portal de desarrollador asociado cuando se publican.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Un catálogo representa una colección de productos de API gestionada. Los productos que se publican son visibles para los consumidores en el portal de desarrollador asociado al catálogo.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Se necesita un certificado para proteger la conexión entre API Connect y el dominio del punto final de gestión de pasarela. Los certificados también se necesitan para proteger las conexiones entre la pasarela y los dominios que maneja. Todos los certificados deben almacenarse en un servicio de Certificate Manager. El servicio proporciona un repositorio seguro para los certificados y ayuda a evitar interrupciones enviándole notificaciones cuando los certificados estén a punto de caducar. Para empezar,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Un valor predeterminado que se utilizará si las entradas a la correlación no están definidas.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Un servicio de pasarela representa un conjunto de contenedores o servidores de pasarela que alojan API publicadas y proporcionan los puntos finales de API utilizados por las aplicaciones de cliente. Las pasarelas ejecutan invocaciones de proxy de API a sistemas de fondo e imponen políticas de API, incluidas la identificación de cliente, la seguridad y la limitación de velocidad.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Un host de pasarela publica las API y proporciona los puntos finales de API utilizados por aplicaciones cliente. Las pasarelas ejecutan invocaciones de proxy de API a sistemas de fondo e imponen políticas de API, incluidas la identificación de cliente, la seguridad y la limitación de velocidad.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Se puede establecer un destino de migración en un producto de origen para definir el destino de migración de las suscripciones.  El destino de migración incluye una correlación de planes que describe la correlación de planes del producto de origen con planes del producto de destino.  El destino de migración es visible en el portal de desarrollador para comunicar el destino de migración a los suscriptores del producto de origen.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Un kit de políticas de OAuth modular. Puede realizar todos los pasos de protocolo de OAuth/OpenID Connect en una política (valor predeterminado) o se puede dividir en varias políticas para realizar un solo paso o “n” pasos a la vez para tener un control más preciso. Las entradas y salidas de cada uno de los pasos se controlan a través de variables de contexto documentadas. Añada o elimine componentes de OAuth soportados según sea necesario.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `No puede crearse un portal sin un servicio de portal disponible. Póngase en contacto con el administrador de nube para obtener ayuda.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Una organización de proveedores es un equipo que posee API y los planes y productos asociados. Una organización de proveedores requiere un propietario que tenga asignados todos los permisos. Los propietarios se asignan a partir de miembros.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Una organización de proveedores incluye usuarios que gestionan API, productos, planes, catálogos, organizaciones de consumidores y otros recursos relacionados. Puede utilizar organizaciones de proveedores para agrupar usuarios y recursos de gestión de API por proyecto, departamento, etapa de ciclo de vida, etc. Para gestionar y asignar roles que rigen los privilegios de usuario, vaya a IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Una expresión JavaScript simple que se resuelve en un valor de la salida. Podría ser una serie estática ("mi serie estática") o una expresión que implique entradas correlacionadas ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `ACCIÓN`,
  [`ANALYTICS`]: `ANÁLISIS`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `Administrador de API`,
  [`API CONNECT VALUE`]: `VALOR DE API CONNECT`,
  [`API assemble`]: `Ensamblaje de API`,
  [`API base endpoints`]: `Puntos finales base de API`,
  [`API base URL`]: `URL base de API`,
  [`API base URLs`]: `URLs base de API`,
  [`API Calls`]: `Llamadas de la API`,
  [`API Connect UI`]: `IU de API Connect`,
  [`API Connect services`]: `Servicios de API Connect`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect necesita un servidor de correo electrónico para enviar invitaciones y enlaces de activación a miembros y para otros fines.`,
  [`API Connect verify token`]: `Verificación de señal de API Connect`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `Licencia de API Designer`,
  [`API Designer credentials`]: `Credenciales de API Designer`,
  [`API Designer licence`]: `Licencia de API Designer`,
  [`API Designer does not currently support WSDL`]: `API Designer no da soporte actualmente a WSDL`,
  [`API editor`]: `Editor de API`,
  [`API Endpoint`]: `Punto final de API`,
  [`API Endpoint Base`]: `Base de punto final de API`,
  [`API Endpoint for Unenforced APIs`]: `Punto final de API para API no vigentes`,
  [`API Endpoint for unenforced APIs`]: `Punto final de API para API no vigentes`,
  [`API Endpoints`]: `Puntos finales de API`,
  [`API Error`]: `Error de API`,
  [`API gateway`]: `Pasarela de API`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `Pasarelas de API`,
  [`API Gateways`]: `Pasarelas de API`,
  [`API Invocation Endpoint`]: `Punto final de invocación de API`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `La API no está publicada. No se habilitará la Prueba de API(Probar) en el Explorador.`,
  [`API Key`]: `Clave de API`,
  [`API key`]: `Clave de API`,
  [`API key secret`]: `Secreto de clave de API`,
  [`API Manager`]: `Gestor de API`,
  [`API Manager Local User Registry`]: `Registro de usuarios local del Gestor de API`,
  [`API Manager URL`]: `URL de Gestor de API`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `Parámetros de vía de acceso de API`,
  [`API Security Definition`]: `Definición de seguridad de API`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `La prueba de API (Intentar) en Explorer no se habilitará para esta API porque no está vigente.`,
  [`API Setup`]: `Configuración de API`,
  [`API Subscription`]: `Suscripción de API`,
  [`API endpoint`]: `Punto final de API`,
  [`API endpoint base`]: `Base de punto final de API`,
  [`API endpoint for unenforced APIs`]: `Punto final de API para las API no vigentes`,
  [`API endpoints`]: `Puntos finales de API`,
  [`API error`]: `Error de API`,
  [`API invocation endpoint`]: `Punto final de invocación de API`,
  [`API is not enforced on the API gateway.`]: `La API no está vigente en la pasarela de API.`,
  [`API path parameters`]: `Parámetros de vía de acceso de API`,
  [`API security definition`]: `Definición de seguridad de API`,
  [`API setup`]: `Configuración de API`,
  [`API subscription`]: `Suscripción de API`,
  [`API Update`]: `Actualización de API`,
  [`API user registries`]: `Registros de usuarios de API`,
  [`API and product generated successfully!`]: `La API y el producto se han generado satisfactoriamente.`,
  [`API deletion failed`]: `La supresión de la API ha fallado`,
  [`API does not exist!`]: `La API no existe.`,
  [`API from existing REST service`]: `API de servicio REST existente`,
  [`API from existing SOAP service`]: `API de servicio SOAP existente`,
  [`API has been updated.`]: `Se ha actualizado la API.`,
  [`API has been updated`]: `La API se ha actualizado`,
  [`API has not been created!`]: `La API no se ha creado.`,
  [`API has not been updated!`]: `La API no se ha actualizado.`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `Prioridad de API (utilizar los puntos finales de vanidad definidos en las definiciones de OpenAPI)`,
  [`API properties`]: `Propiedades de API`,
  [`API protection source`]: `Origen de protección de API`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `La API referenciada por el producto no existe. Cree o importe la API primero.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `Las estructuras de carga útil de solicitud y respuesta de API se componen de definiciones de esquema de OpenAPI.`,
  [`API subscription request approved for app {{appName}}`]: `La solicitud de suscripción de API se ha aprobado para la aplicación {appName}`,
  [`API subscription request denied for app {{appName}}`]: `La solicitud de suscripción de API se ha denegado para la aplicación {appName}`,
  [`API subscription request received for app {{appName}}`]: `La solicitud de suscripción de API se ha recibido para la aplicación {appName}`,
  [`API type`]: `Tipo de API`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `La API con la clave ''{key}'' bajo la propiedad 'apis' no existe y no se puede listar. Compruebe la pestaña Origen.`,
  [`APIs`]: `API`,
  [`APIs and products`]: `API y productos`,
  [`APIs included in the plan.`]: `APIs incluidas en el plan.`,
  [`API Key is created`]: `Se ha creado la clave de API`,
  [`API Key is deleted`]: `Se ha suprimido la clave de API`,
  [`API Key is successfully created`]: `La clave de API se ha creado correctamente`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `El tiempo de espera de clave de API es **{seconds} segundos ({time})**`,
  [`API key timeout`]: `Tiempo de espera de clave de API`,
  [`API Key timeout has been changed`]: `El tiempo de espera de clave de API se ha cambiado`,
  [`Application type`]: `Tipo de aplicación`,
  [`Associated analytics service`]: `Servicio de análisis asociado`,
  [`AVAILABILITY`]: `DISPONIBILIDAD`,
  [`AVAILABILITY ZONE`]: `ZONA DE DISPONIBILIDAD`,
  [`About`]: `Acerca de`,
  [`About audit setting`]: `Acerca del valor de auditoría`,
  [`About Authentication URL user registry`]: `Acerca del registro de usuarios de URL de autenticación`,
  [`About availability zones`]: `Acerca de zonas de disponibilidad`,
  [`About catalogs`]: `Acerca de los catálogos`,
  [`About consumer organization owners`]: `Acerca de los propietarios de organización de consumidores`,
  [`About consumer organizations`]: `Acerca de organizaciones de consumidores`,
  [`About developer portals`]: `Acerca de portales de desarrollador`,
  [`About email servers`]: `Acerca de los servidores de correo electrónico`,
  [`About Endpoints`]: `Acerca de los puntos finales`,
  [`About gateways`]: `Acerca de las pasarelas`,
  [`About gateway visibility`]: `Acerca de la visibilidad de pasarela`,
  [`About Gateway Processing Status`]: `Acerca del estado de proceso de pasarela`,
  [`About introspection`]: `Acerca de la introspección`,
  [`About Keystores`]: `Acerca de Almacenes de claves`,
  [`About LDAP`]: `Acerca de LDAP`,
  [`About local user registries`]: `Acerca de registros de usuarios locales`,
  [`About metadata`]: `Acerca de los metadatos`,
  [`About Migration Target`]: `Acerca del Destino de migración`,
  [`About migration targets`]: `Acerca de destinos de migración`,
  [`About native OAuth provider`]: `Acerca del proveedor de OAuth nativo`,
  [`About OpenID Connect`]: `Acerca de OpenID Connect`,
  [`About replacing a product`]: `Acerca de sustituir un producto`,
  [`About Role defaults`]: `Acerca de los valores predeterminados de rol`,
  [`About roles`]: `Acerca de los roles`,
  [`About scopes`]: `Acerca de los ámbitos`,
  [`About Set Migration Targets`]: `Acerca de Definir destinos de migración`,
  [`About spaces`]: `Acerca de los espacios`,
  [`About Subscription`]: `Acerca de suscripción`,
  [`About superseding a product`]: `Acerca del reemplazo de un producto`,
  [`About TLS server profile`]: `Acerca del perfil de servidor TLS`,
  [`About TLS client profiles`]: `Acerca de los perfiles de cliente TLS`,
  [`About TLS server profiles`]: `Acerca de los perfiles de servidor TLS`,
  [`About third party OAuth provider`]: `Acerca del proveedor de OAuth de terceros`,
  [`About tokens`]: `Acerca de las señales`,
  [`About Transfer Ownership`]: `Acerca de la transferencia de propiedad`,
  [`About Transferring Ownership`]: `Acerca de la transferencia de propiedad`,
  [`About Truststores`]: `Acerca de Almacenes de confianza`,
  [`About adding a member`]: `Acerca de la adición de un miembro`,
  [`About adding provider organization member`]: `Acerca de la adición de un miembro de organización de proveedores`,
  [`About adding members to a Space`]: `Acerca de la adición de miembros a un espacio`,
  [`About adding members to a catalog`]: `Acerca de la adición de miembros a un catálogo`,
  [`About changing owners`]: `Acerca del cambio de propietario`,
  [`About enabling gateway services`]: `Acerca de la habilitación de servicios de pasarela`,
  [`About inviting a member`]: `Acerca de la invitación de un miembro`,
  [`About inviting catalog members`]: `Acerca de invitar a miembros de catálogo`,
  [`About inviting provider organization members`]: `Acerca de la invitación a miembros de organización`,
  [`About inviting space members`]: `Acerca de la invitación a miembros de espacio`,
  [`About keystores`]: `Acerca de los almacenes de claves`,
  [`About managing Space membership`]: `Acerca de la gestión de la pertenencia a un espacio`,
  [`About migrating subscriptions`]: `Acerca de la migración de suscripciones`,
  [`About provider organizations`]: `Acerca de las organizaciones de proveedores`,
  [`About roles and role defaults`]: `Acerca de los roles y valores predeterminados de rol`,
  [`About roles in spaces`]: `Acerca de los roles en espacios`,
  [`About selecting gateway services`]: `Acerca de la selección de servicios de pasarela`,
  [`About send message`]: `Acerca del envío de mensajes`,
  [`About sending messages`]: `Acerca del envío de mensajes`,
  [`About the analytics service`]: `Acerca del servicio de análisis`,
  [`About the DNS Scheme`]: `Acerca del esquema de DNS`,
  [`About the Gateway Service`]: `Acerca del servicio de pasarela`,
  [`About the Portal Service`]: `Acerca del servicio de portal`,
  [`About the product lifecycle`]: `Acerca del ciclo de vida del producto`,
  [`About the provider organization owner`]: `Acerca del propietario de la organización de proveedores`,
  [`About this Cloud`]: `Acerca de esta nube`,
  [`About transferring ownership`]: `Acerca de la transferencia de propiedad`,
  [`About truststores`]: `Acerca de los almacenes de confianza`,
  [`About visibility`]: `Acerca de la visibilidad`,
  [`About visibility and subscribability`]: `Acerca de la visibilidad y la capacidad de suscripción`,
  [`Accept`]: `Aceptar`,
  [`Access Code`]: `Código de acceso`,
  [`Access Token`]: `Señal de acceso`,
  [`Access URL through Secure Gateway`]: `Acceder a URL a través de pasarela segura`,
  [`Access code`]: `Código de acceso`,
  [`Access code is not chosen in supported grant type.`]: `El código de acceso no se ha elegido en el tipo de otorgamiento soportado.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Acceder al URL a través de la pasarela segura. La pasarela se establece cuando la API se mueve del estado Desplegada al estado Publicada.`,
  [`Access token TTL has been changed`]: `Se ha cambiado el tiempo de vida de la señal de acceso`,
  [`Access token time to live`]: `Tiempo de vida de señal de acceso`,
  [`Access token time-to-live`]: `Tiempo de vida de señal de acceso`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `La señal de acceso caducará en **{seconds} segundos ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Las señales de acceso se otorgan a la aplicación de cliente para permitir que la aplicación acceda a los recursos en nombre del usuario de aplicación.  Se emiten señales para renovación al cliente para obtener una señal de acceso nueva cuando la señal de acceso actual se vuelve no válida o caduca o para obtener señales de acceso adicionales con ámbito idéntico o más restringido. También puede especificar el periodo de validez del consentimiento dado por la combinación de un número de acceso y una señal de renovación.`,
  [`Access tokens time to live (seconds)`]: `Tiempo de vida de señales de acceso (segundos)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Se puede asignar una señal de acceso, una señal de ID y una señal temporal a cualquiera de los almacenes de claves existentes. Se conserva el historial de asignación de almacén de claves.`,
  [`Account`]: `Cuenta`,
  [`Account has been updated.`]: `Se ha actualizado la cuenta.`,
  [`Account password has been changed.`]: `Se ha cambiado la contraseña de la cuenta.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Acción`,
  [`Activate API`]: `Activar API`,
  [`Activate API|button text`]: `Activar API`,
  [`Activate API for testing|title`]: `Activar API`,
  [`Activation link`]: `Enlace de activación`,
  [`Active`]: `Activo`,
  [`Activity log`]: `Registro de actividad`,
  [`Activity log API setting is not available for this API's gateway type.`]: `El parámetro API de registro de actividad no está disponible para el tipo de pasarela de esta API.`,
  [`Active loading indicator`]: `Indicador de carga activa`,
  [`Add`]: `Añadir`,
  [`Add allowlist`]: `Añadir lista de elementos permitidos`,
  [`Add a new billing integration in Resources`]: `Añadir una integración de facturación nueva en Recursos`,
  [`Add API user registries`]: `Añadir registros de usuarios de API`,
  [`Add APIs to Product`]: `Añadir API a producto`,
  [`Add another cloud`]: `Añadir otra nube`,
  [`Add billing integration`]: `Añadir integración de facturación`,
  [`Add Burst Limit`]: `Añadir límite de ráfaga`,
  [`Add Ciphers for TLS client profile`]: `Añadir cifrados para perfil de cliente TLS`,
  [`Add Ciphers for TLS server profile`]: `Añadir cifrados para perfil de servidor TLS`,
  [`Add Condition`]: `Añadir condición`,
  [`Add Consumer Role`]: `Añadir rol de consumidor`,
  [`Add Count Limit`]: `Añadir límite de recuento`,
  [`Add Credential`]: `Añadir credencial`,
  [`Add domain`]: `Añadir dominio`,
  [`Add credential`]: `Añadir credencial`,
  [`Add Extension`]: `Añadir extensión`,
  [`Add GraphQL schema`]: `Añadir esquema GraphQL`,
  [`Add HTTP Endpoint`]: `Añadir punto final HTTP`,
  [`Add group`]: `Añadir grupo`,
  [`Add JSON schema`]: `Añadir esquema JSON`,
  [`Add Keystore to TLS client profile`]: `Añadir almacén de claves al perfil de cliente TLS`,
  [`Add Keystore to TLS server profile`]: `Añadir almacén de claves al perfil de servidor TLS`,
  [`Add member`]: `Añadir miembro`,
  [`Add New Role for Consumer organization`]: `Añadir nuevo rol de organización de consumidores`,
  [`Add operation`]: `Añadir operación`,
  [`Add Parameters`]: `Añadir parámetros`,
  [`Add Policy`]: `Añadir política`,
  [`Add Rate limit`]: `Añadir límite de velocidad`,
  [`Add Rate Limit`]: `Añadir límite de velocidad`,
  [`Add role`]: `Añadir rol`,
  [`Add Syslog TCP Endpoint`]: `Añadir punto final TCP de Syslog`,
  [`Add Syslog TLS Endpoint`]: `Añadir punto final TLS de Syslog`,
  [`Add Syslog UDP Endpoint`]: `Añadir punto final UDP de Syslog`,
  [`Add XML schema`]: `Añadir esquema XML`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Añada un punto final de pasarela que desee poner a disposición de las llamadas a APIs en este catálogo.`,
  [`Add a different Cloud Connection.`]: `Añadir una conexión de nube diferente.`,
  [`Add a member from the user registry`]: `Añadir un miembro del registro de usuarios`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Añadir un usuario a la organización de administración y asignar los roles necesarios`,
  [`Add a user to the provider organization, and assign the required roles`]: `Añadir un usuario a la organización de proveedores y asignarle los roles necesarios`,
  [`Add a user to the Catalog, and assign the required roles`]: `Añadir un usuario al catálogo y asignar los roles necesarios`,
  [`Add a user to the Space, and assign the required roles`]: `Añadir un usuario al espacio y asignar los roles necesarios`,
  [`Add action`]: `Añadir acción`,
  [`Add additional client ID/client secret pairs`]: `Añadir pares de ID de cliente/secreto de cliente adicionales`,
  [`Add blocklist`]: `Añadir lista de elementos bloqueados`,
  [`Add case`]: `Añadir caso`,
  [`Add catch`]: `Añadir captura`,
  [`Add default catch`]: `Añadir captura predeterminada`,
  [`Add destination`]: `Añadir destino`,
  [`Add entry`]: `Añadir entrada`,
  [`Add group failed.`]: `La adición de grupo ha fallado.`,
  [`Add input`]: `Añadir entrada`,
  [`Add media type`]: `Añadir tipo de soporte`,
  [`Add member failed`]: `Error al añadir miembro`,
  [`Add object`]: `Añadir objeto`,
  [`Add otherwise`]: `Añadir de lo contrario`,
  [`Add output`]: `Añadir salida`,
  [`Add outputs for operation...`]: `Añadir salidas para operación...`,
  [`Add parameters for operation...`]: `Añadir parámetros para operación...`,
  [`Add parameters to this API`]: `Añadir parámetros a esta API`,
  [`Add plans to product`]: `Añadir planes a producto`,
  [`Add plans to this product`]: `Añadir planes a este producto`,
  [`Add schema`]: `Añadir esquema`,
  [`Add scopes for this OAuth provider.`]: `Añadir ámbitos para este proveedor de OAuth.`,
  [`Add scopes to allow access to`]: `Añada ámbitos a los que permitir acceso`,
  [`Add scopes to allow access to.`]: `Añada ámbitos a los que permitir acceso.`,
  [`Add tags and external documentation details for this API`]: `Añadir etiquetas y detalles de documentación externa para esta API`,
  [`Add to`]: `Añadir a`,
  [`Add to existing product`]: `Añadir a producto existente`,
  [`Add/Remove APIs`]: `Añadir/eliminar API`,
  [`AddProperties`]: `Añadir propiedades`,
  [`Added APIs`]: `Se han añadido APIs`,
  [`Added rate limit`]: `Se ha añadido el límite de velocidad`,
  [`Added rate limits`]: `Se han añadido límites de velocidad`,
  [`Adding an API to a product for publishing`]: `Añadir una API a un producto para su publicación`,
  [`Additional support`]: `Soporte adicional`,
  [`Additional properties`]: `Propiedades adicionales`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Además, considere la posibilidad de eliminar el registro asociado, Registro de usuarios de catálogo {name}, si no se utiliza.`,
  [`Address`]: `Dirección`,
  [`Admin DN`]: `Nombre distinguido de administrador`,
  [`Admin organization invitation timeout`]: `Tiempo de espera de invitación de la organización de administración`,
  [`Admin organization invitation timeout has been changed`]: `Se ha cambiado el tiempo de espera de la invitación de la organización de administración`,
  [`Admin password`]: `Contraseña del administrador`,
  [`Admin request reset password`]: `Restablecimiento de contraseña de solicitud de administrador`,
  [`Admin reset password`]: `Restablecimiento de contraseña de administrador`,
  [`Admin Sign In`]: `Inicio de sesión de administrador`,
  [`Admin add catalog failed`]: `La operación de administrador Añadir catálogo ha fallado`,
  [`Admin add space failed`]: `La operación de administrador Añadir espacio ha fallado`,
  [`Administer consumer organizations`]: `Administrar organizaciones de consumidores`,
  [`Administers the API consumer organization`]: `Administra la organización de consumidores de API`,
  [`Administers the API provider organization`]: `Administra la organización de proveedores de API`,
  [`Administers the admin organization`]: `Administra la organización de administración`,
  [`Administers the admin topology`]: `Administra la topología de administración`,
  [`Administers the app developer organization`]: `Administra la organización de desarrolladores de aplicaciones.`,
  [`Administers the catalog`]: `Administra el catálogo`,
  [`Administers the cloud topology`]: `Administra la topología de nube`,
  [`Administers the space`]: `Administra el espacio`,
  [`Administration management console`]: `Consola de gestión de administración`,
  [`Administrator`]: `Administrador`,
  [`Advanced analytics configuration`]: `Configuración de análisis avanzados`,
  [`Advanced features`]: `Funciones avanzadas`,
  [`Advanced scope check`]: `Comprobación de ámbito avanzada`,
  [`Advanced scope check after Token Validation`]: `Comprobación de ámbito avanzada tras validación de señal`,
  [`Advanced scope check before Token Generation`]: `Comprobación de ámbito avanzada antes de generación de señal`,
  [`Aggregation`]: `Agregación`,
  [`Agree`]: `Acepto`,
  [`All`]: `Todo`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Todos los desarrolladores autenticados en las organizaciones del consumidor que hayan iniciado sesión en el portal del desarrollador pueden ver este producto.`,
  [`All consumer organizations will be able to see this product.`]: `Todas las organizaciones de consumidores podrán ver este producto.`,
  [`Allowlist`]: `Lista de elementos permitidos`,
  [`Allow Chunked Uploads`]: `Permitir cargas fragmentadas`,
  [`Allow chunked uploads`]: `Permitir cargas fragmentadas`,
  [`Allow "plain" challenge method`]: `Permitir método de desafío "simple"`,
  [`Allow renegotiation`]: `Permitir renegociación`,
  [`Allow case sensitive usernames`]: `Permitir nombres de usuario sensibles a mayúsculas y minúsculas`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Permitir a los clientes inyectar una extensión SNI con el nombre de host deseado en su reconocimiento inicial con el servidor.`,
  [`Allow connection to be renegotiated`]: `Permitir renegociar la conexión`,
  [`Allow default introspection`]: `Permitir introspección predeterminada`,
  [`Allow insecure server connections`]: `Permitir conexiones de servidor no seguras`,
  [`Allow null value`]: `Permitir valor nulo`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Permitir envío de access_token/refresh_token en redirección 302 para cierre de sesión`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Se han aplicado todas las recomendaciones. No hay avisos para el esquema.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Permitir que las operaciones de la API se prueben mediante las herramientas de prueba en el Portal del desarrollador.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Permitir que la conexión continúe con credenciales débiles o inseguras`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Permitir que la conexión continúe con credenciales débiles o inseguras.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Permitir que el proveedor de OIDC externo se comunique con el Portal del desarrollador, no con el Gestor de API.`,
  [`Allow to manage this user registry`]: `Permitir gestionar este registro de usuarios`,
  [`Allow to manage users under this user registry`]: `Permitir gestionar usuarios de este registro de usuarios`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Permite que los usuarios se incorporen automáticamente cuando APIC se presenta con una señal emitida por el emisor`,
  [`Already have an account?`]: `¿Ya dispone de una cuenta?`,
  [`Also transfer ownership of owned Spaces`]: `Asimismo transfiera la propiedad de espacios en propiedad`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Captar siempre los datos de usuario del punto final de información de usuario, si se ha habilitado`,
  [`Always output the root element`]: `Ofrecer siempre la salida del elemento raíz`,
  [`Always output the root element.`]: `Ofrecer siempre la salida del elemento raíz.`,
  [`Always required`]: `Siempre necesario`,
  [`Always use userinfo endpoint`]: `Utilizar siempre el punto final de información de usuario`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Una API de proveedor de OAuth contiene los puntos finales de autorización y señal de un flujo OAuth. Configure aquí los proveedores de OAuth; a continuación, al crear una API protegida por OAuth, podrá seleccionar el proveedor necesario.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Un proveedor de OAuth contiene los puntos finales de autorización y señal de un flujo OAuth; seleccione el proveedor de OAuth que desea utilizar para proteger las API con OAuth`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Una aplicación aparece en esta lista cuando un desarrollador la suscribe a un plan del espacio para poder llamar a las API asociadas; puede suspender una aplicación de desarrollador para impedir que acceda a las API y también puede crear sus propias aplicaciones. [Más información]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Un URL de autenticación apunta a un proveedor de autenticación de terceros como registro de usuarios. Un URL de autenticación permite la integración con un registro de usuarios de terceros que no sea LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Un URL de autenticación proporciona un mecanismo simple para autenticar usuarios contra un proveedor de identidad personalizado.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Se ha producido un error al establecer comunicación con el subsistema de pasarelas.`,
  [`An organization is required.`]: `Es obligatorio especificar una organización.`,
  [`An unknown error occurred.`]: `Se ha producido un error desconocido.`,
  [`Analytics`]: `Análisis`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Servicio de Insights Service'`,
  [`Analytics Details`]: `Detalles de Análisis`,
  [`Analytics destinations`]: `Destinos de análisis`,
  [`Analytics Director keystore`]: `Almacén de claves del director de análisis`,
  [`Analytics Service`]: `Servicio de análisis`,
  [`Analytics Service URL`]: `URL de servicio de análisis`,
  [`Analytics Service {arg} has been removed.`]: `Se ha eliminado el servicio de análisis {arg}.`,
  [`Analytics details`]: `Detalles de análisis`,
  [`Analytics service`]: `Servicio de análisis`,
  [`Analytics service details`]: `Detalles del servicio Analytics`,
  [`Analytics service URL`]: `URL de servicio de análisis`,
  [`Analytics service {arg} has been removed.`]: `Se ha eliminado el servicio de análisis {arg}.`,
  [`Analytics client TLS client profile`]: `Perfil de cliente TLS de cliente de análisis`,
  [`Analytics client keystore`]: `Almacén de claves de cliente de análisis`,
  [`Analytics client truststore`]: `Almacén de confianza de cliente de análisis`,
  [`Analytics ingestion TLS client profile`]: `Perfil de cliente TLS de ingesta de análisis`,
  [`Analytics ingestion keystore`]: `Almacén de claves de ingesta de análisis`,
  [`Analytics ingestion truststore`]: `Almacén de confianza de ingesta de análisis`,
  [`Analytics service {title} has been created.`]: `Se ha creado el servicio de análisis {title}.`,
  [`Analytics service {title} has been updated.`]: `Se ha actualizado el servicio de análisis {title}.`,
  [`The selected consumer organization is`]: `La organización de consumidores seleccionada es`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Los servicios de análisis están configurados y los datos de análisis se han descargado externamente`,
  [`Analyze API usage and performance`]: `Analizar el uso y el rendimiento de las API`,
  [`Anonymous bind`]: `Enlace anónimo`,
  [`Another user registry`]: `Otro registro de usuarios`,
  [`api-administrator`]: `administrador de API`,
  [`Api-Analytics`]: `Análisis de API`,
  [`Api-Drafts`]: `Borradores de API`,
  [`App`]: `Aplicación`,
  [`App name`]: `Nombre de aplicación`,
  [`App-Analytics`]: `Analíticas de aplicación`,
  [`App-Approval`]: `Aprobación de aplicación`,
  [`App-Dev`]: `Aplicaciones de desarrollo`,
  [`App-analytics`]: `Analíticas de aplicación`,
  [`App-dev`]: `Aplicaciones de desarrollo`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Aplicación`,
  [`Application Authentication`]: `Autenticación de aplicación`,
  [`Application Authentication Source`]: `Origen de autenticación de aplicación`,
  [`Application lifecycle`]: `Ciclo de vida de aplicación`,
  [`Application approval task for upgrading application`]: `Tarea de aprobación de aplicación para actualizar la aplicación`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Tarea de aprobación de aplicación para actualizar la aplicación {taskname} a la producción solicitada por {username} ({orgname})`,
  [`Application authentication`]: `Autenticación de aplicación`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Los desarrolladores de aplicaciones suscriben inicialmente sus aplicaciones a uno o más planes utilizando el portal de desarrollador. Sin embargo, para un plan seleccionado, puede migrar suscripciones de aplicación a un plan de otro producto.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Los desarrolladores de aplicaciones suscriben inicialmente sus aplicaciones a uno o más planes utilizando el portal de desarrollador. Sin embargo, para un plan seleccionado, puede migrar suscripciones de aplicación a un plan de otro producto.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Los desarrolladores de aplicaciones suscriben una aplicación a un plan para poder llamar a las API en ese plan.`,
  [`Application is being created. Please Wait`]: `Se está creando la aplicación. Espere`,
  [`Application scope check`]: `Comprobación de ámbito de aplicación`,
  [`Applications`]: `Aplicaciones`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Las aplicaciones existentes antes de activar el ciclo de vida de aplicación permanecerá en el estado de producción`,
  [`Applied security`]: `Seguridad aplicada`,
  [`Apply`]: `Aplicar`,
  [`Apply all`]: `Aplicar todos`,
  [`Apply all suggestions`]: `Aplicar todas las sugerencias`,
  [`Apply analysis configuration`]: `Aplicar configuración de análisis`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Aplique la política de invocación para llamar a un servicio existente desde el interior de su operación. La política se puede utilizar con datos JSON o XML y se puede aplicar varias veces en el ensamblaje.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Aplique la política de actualización de websocket para establecer una conexión de websocket para llamar a un servicio existente desde la operación.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Aplique esta política para invocar una API proxy en su operación, en particular si debe invocar una carga útil grande. Solamente se permite invocar una política de proxy por ensamblaje.`,
  [`Apply to selected`]: `Aplicar a seleccionado`,
  [`Apply type analysis configuration`]: `Aplicar configuración de análisis de tipo`,
  [`Approval History`]: `Historial de aprobación`,
  [`Approval tasks`]: `Tareas de aprobación`,
  [`Approvals`]: `Aprobaciones`,
  [`Approve`]: `Aprobar`,
  [`Archive`]: `Archivar`,
  [`Archive Product`]: `Archivar producto`,
  [`Archive|permission`]: `Archivar`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `¿Está seguro de que desea borrar las restricciones de los elementos seleccionados?`,
  [`Are you sure you want to delete API?`]: `¿Está seguro de que desea suprimir la API?`,
  [`Are you sure you want to delete Product?`]: `¿Está seguro de que desea suprimir el producto?`,
  [`Are you sure you want to delete a catalog?`]: `¿Está seguro de que desea suprimir un catálogo?`,
  [`Are you sure you want to delete a space?`]: `¿Está seguro de que desea suprimir un espacio?`,
  [`Are you sure you want to delete this application?`]: `¿Está seguro de que desea suprimir esta aplicación?`,
  [`Are you sure you want to delete this billing integration?`]: `¿Está seguro de que desea suprimir esta integración de facturación?`,
  [`Are you sure you want to delete this field?`]: `¿Está seguro de que desea suprimir este campo?`,
  [`Are you sure you want to delete this policy?`]: `¿Está seguro de que desea suprimir esta política?`,
  [`Are you sure you want to delete this type?`]: `¿Está seguro de que desea suprimir este tipo?`,
  [`Are you sure you want to disable spaces?`]: `¿Está seguro de que desea inhabilitar los espacios?`,
  [`Are you sure you want to disable custom email sender information?`]: `¿Está seguro de que desea inhabilitar la información del remitente de correo electrónico personalizado?`,
  [`Are you sure you want to disable custom notification templates?`]: `¿Está seguro de que desea inhabilitar plantillas de notificación personalizadas?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `¿Está seguro de que desea dejar de utilizar IBM Developer Portal? Todas las personalizaciones del portal para el catálogo se suprimirán`,
  [`Are you sure you want to enable spaces?`]: `¿Está seguro de que desea habilitar los espacios?`,
  [`Are you sure you want to enable custom email sender information?`]: `¿Está seguro de que desea habilitar la información del remitente de correo electrónico personalizado?`,
  [`Are you sure you want to enable custom notification templates?`]: `¿Está seguro de que desea habilitar plantillas de notificación personalizadas?`,
  [`Are you sure you want to proceed with delete?`]: `¿Está seguro de que desea continuar con la supresión?`,
  [`Are you sure you want to publish?`]: `¿Está seguro de que desea publicar?`,
  [`Are you sure you want to re-stage?`]: `¿Está seguro de que desea volver a desplegar?`,
  [`Are you sure you want to remove the product from the catalog?`]: `¿Está seguro de que desea eliminar el producto del catálogo?`,
  [`Are you sure you want to unassociate?`]: `¿Está seguro de que desea desasociar?`,
  [`Are you sure you want to update Self Service Onboarding`]: `¿Está seguro de que desea actualizar la Incorporación de autoservicio?`,
  [`Are you sure?`]: `¿Está seguro?`,
  [`Argument`]: `Argumento`,
  [`Array`]: `Matriz`,
  [`Assemble`]: `Ensamblar`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Ensamblar el flujo de políticas necesarias para el proveedor de OAuth.`,
  [`Assembly`]: `Ensamblaje`,
  [`Assembly Execute`]: `Ejecución del ensamblaje`,
  [`Assembly saved`]: `Se ha guardado el ensamblaje`,
  [`Assembly burst limits`]: `Límites de ráfaga de ensamblaje`,
  [`Assembly count limits`]: `Límites de recuento de ensamblaje`,
  [`Assign roles`]: `Asignar roles`,
  [`Assign roles to the current owner`]: `Asignar roles al propietario actual`,
  [`Associate`]: `Asociar`,
  [`Associate analytics service`]: `Asociar servicio de análisis`,
  [`Associate analytics`]: `Asociar análisis`,
  [`Assumed size`]: `Tamaño adoptado`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Error de autenticación al intentar obtener la API del URL {url}. Compruebe el nombre de usuario y la contraseña.`,
  [`Audience claim`]: `Reclamación del público`,
  [`Audience Claim`]: `Reclamación pública`,
  [`Audit setting`]: `Valor de auditoría`,
  [`Audit setting has been changed`]: `Se ha cambiado el valor de auditoría`,
  [`Auditing is **{mode}**`]: `Estado de la auditoría: **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `La auditoría se utiliza para supervisar las llamadas de API y registrar sobre los usuarios que llaman, la hora de cada llamada y la actividad implicada en cada suceso.`,
  [`Authenticate Client Method`]: `Autenticar método de cliente`,
  [`Authenticate password`]: `Autenticar contraseña`,
  [`Authenticate user`]: `Autenticar usuario`,
  [`Authenticate user settings`]: `Autenticar valores de usuario`,
  [`Authenticate application users using`]: `Autenticar usuarios de aplicación utilizando`,
  [`Authenticate using Basic Authentication`]: `Autenticar utilizando autenticación básica`,
  [`Authenticate using OAuth`]: `Autenticar utilizando OAuth`,
  [`Authenticated`]: `Autenticado`,
  [`Authenticated bind`]: `Enlace autenticado`,
  [`Authenticated User Name`]: `Nombre de usuario autenticado`,
  [`Authentication`]: `Autenticación`,
  [`Authentication Bind`]: `Enlace de autenticación`,
  [`Authentication error`]: `Error de autenticación`,
  [`Authentication method`]: `Método de autenticación`,
  [`Authentication TLS profile`]: `Perfil de TLS de autenticación`,
  [`Authentication URL`]: `URL de autenticación`,
  [`Authentication URL user registry`]: `Registro de usuarios de URL de autenticación`,
  [`Authentication URL user registry has been created.`]: `Se ha creado el registro de usuarios de URL de autenticación.`,
  [`Authentication URL to use for validation.`]: `URL de autenticación para utilizar en la validación.`,
  [`Authentication response header credential`]: `Credencial de cabecera de respuesta de autenticación`,
  [`Authentication response header pattern`]: `Patrón de cabecera de respuesta de autenticación`,
  [`Authentication type`]: `Tipo de autenticación`,
  [`Authentication type to use to validate the UsernameToken.`]: `Tipo de autenticación a utilizar para validar UsernameToken.`,
  [`Authorization`]: `Autorización`,
  [`Authorization Code`]: `Código de autorización`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `La URL de autorización tiene que coincidir con {endpoint} tal como lo define el proveedor OAuth "{providerTitle}"`,
  [`Authorization endpoint`]: `Punto final de autorización`,
  [`Authorization Request`]: `Solicitud de autorización`,
  [`Authorization URL`]: `URL de autorización`,
  [`Authorize`]: `Autorizar`,
  [`Authorize User Settings`]: `Autorizar valores de usuario`,
  [`Authorize application users using`]: `Autorizar a usuarios de aplicación utilizando`,
  [`Authorize path`]: `Vía de acceso de autorización`,
  [`Authors API and product definitions`]: `Crea definiciones de API y producto`,
  [`Auto Generate OIDC API Assembly`]: `Generar automáticamente ensamblaje de API de OIDC`,
  [`Auto onboard`]: `Incorporación automática`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Nombre generado automáticamente para su uso en los mandatos y las API de gestión de API Connect.`,
  [`Automation`]: `Automatización`,
  [`Automation Management Console`]: `Consola de gestión de automatización`,
  [`Automation management console`]: `Consola de gestión de automatización`,
  [`Availability Zone`]: `Zona de disponibilidad`,
  [`Availability zone`]: `Zona de disponibilidad`,
  [`Availability zone {arg} has been removed.`]: `Se ha eliminado la zona de disponibilidad {arg}.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Las zonas de disponibilidad le permiten agrupar los servicios de API Connect para que se adapten a sus necesidades empresariales. Por ejemplo, puede agrupar servicios de pasarela según la región o el centro de datos en el que se encuentran.`,
  [`Average response time: {{value}}ms`]: `Tiempo de respuesta promedio: {{value}}ms`,
  [`Average time`]: `Tiempo promedio`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Puede evitar errores de CORS utilizando el servidor de API Designer como proxy local.`,
  [`Back`]: `Atrás`,
  [`Back to sign in`]: `Volver a inicio de sesión`,
  [`Back to test`]: `Volver a prueba`,
  [`Backend type`]: `Tipo de programa de fondo`,
  [`Bad gateway`]: `Pasarela errónea`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Nombre distinguido base`,
  [`Base Path`]: `Vía de acceso base`,
  [`Base path`]: `Vía de acceso base`,
  [`Base endpoint`]: `Punto final base`,
  [`Base endpoints`]: `Puntos finales base`,
  [`Base64 encoding`]: `Codificación en Base64`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `La codificación de Base64 para las invitaciones y las señales de restablecimiento de contraseña está **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Se ha cambiado el valor de codificación Base64 para la señal temporal`,
  [`Base URL of API invocation endpoint`]: `URL base del punto final de invocación de API`,
  [`Basepath root`]: `Raíz de vía de acceso base`,
  [`Basic`]: `Básica`,
  [`Basic authentication`]: `Autenticación básica`,
  [`Basic authentication password`]: `Contraseña de autenticación básica`,
  [`Basic authentication request header name`]: `Nombre de cabecera de solicitud de autenticación básica`,
  [`Basic authentication username`]: `Nombre de usuario de autenticación básica`,
  [`Before you begin`]: `Antes de empezar`,
  [`Before you begin...`]: `Antes de empezar...`,
  [`Between 0 and 10 events are waiting to be processed`]: `Hay entre 0 y 10 sucesos a la espera de proceso`,
  [`Between 10 and 20 events are waiting to be processed`]: `Hay entre 10 y 20 sucesos a la espera de proceso`,
  [`Billing`]: `Facturación`,
  [`Billing and Payment`]: `Facturación y pago`,
  [`Billing has been updated`]: `La facturación se ha actualizado`,
  [`Billing integrations`]: `Integraciones de facturación`,
  [`Billing integration`]: `Integración de facturación`,
  [`Billing Integration`]: `Integración de facturación`,
  [`Billing {title} successfully added.`]: `La facturación {title} se ha añadido correctamente.`,
  [`Billing {title} successfully deleted.`]: `La facturación {title} se ha suprimido correctamente.`,
  [`Billing {title} successfully updated.`]: `La facturación {title} se ha actualizado correctamente.`,
  [`Billing Options`]: `Opciones de facturación`,
  [`Blocklist`]: `Lista de elementos bloqueados`,
  [`Blocking`]: `Con bloqueo`,
  [`Blocking option`]: `Opción de bloqueo`,
  [`Body`]: `Cuerpo`,
  [`Boolean`]: `Booleano`,
  [`Both`]: `Ambos`,
  [`Browse`]: `Examinar`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Incluir solicitudes y respuestas de API en almacenamiento intermedio antes de procesarlas en DataPower API Gateway.`,
  [`Buffering`]: `Almacenamiento intermedio`,
  [`Builds and manages apps in the developer organization`]: `Crea y gestiona aplicaciones en la organización de desarrolladores`,
  [`Burst Limit Name`]: `Nombre de límite de ráfaga`,
  [`Burst limits`]: `Límites de ráfaga`,
  [`Burst limit value can't be negative`]: `El valor del límite de ráfaga no puede ser negativo`,
  [`Buy`]: `Comprar`,
  [`but unable to remove because of limitation`]: `pero no se puede eliminar debido a la limitación`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Configurando la visibilidad y capacidad de suscripción de un producto, puede controlar la disponibilidad de sus API para los desarrolladores de aplicaciones en el portal del desarrollador. Puede controlar qué desarrolladores de aplicaciones pueden ver el producto, y qué desarrolladores de aplicaciones pueden suscribirse para utilizar las API del producto.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Configurando la visibilidad y capacidad de suscripción de un producto, puede controlar la disponibilidad de sus API para los desarrolladores de aplicaciones en el portal del desarrollador. Puede controlar qué desarrolladores de aplicaciones pueden ver el producto, y qué desarrolladores de aplicaciones pueden suscribirse para utilizar las API del producto.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `De forma predeterminada, la correlación desde varios orígenes (por ejemplo, matriz1 de longitud 2 y matriz2 de longitud 3) dará como resultado una matriz de destino que contendrá 5 elementos (2 por el proceso de matriz1 y 3 por el proceso posterior de matriz2). Si prefiere combinar estas reglas (la creación de una matriz que contendrá 2 o 3 elementos con propiedades de cada matriz de origen combinadas), seleccione esta opción.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `De forma predeterminada, este producto se publica en todos los servicios de pasarela relevantes. También puede publicar en servicios de pasarela específicos habilitando esta opción.`,
  [`By payload`]: `Por carga útil`,
  [`By URL parameter`]: `Por parámetro de URL`,
  [`Bytes`]: `Bytes`,
  [`CALLS`]: `LLAMADAS`,
  [`Calls`]: `Llamadas`,
  [`CATALOG`]: `CATÁLOGO`,
  [`CERTIFICATE NAME`]: `NOMBRE DE CERTIFICADO`,
  [`CIPHER`]: `CIFRADO`,
  [`CIPHER SUITES`]: `SUITES DE CIFRADO`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Solo CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `ID de cliente`,
  [`CLOUD ADMINISTRATOR`]: `ADMINISTRADOR DE NUBE`,
  [`Consumer organization`]: `Organización de consumidores`,
  [`CONSUMER ORGANIZATION / GROUP`]: `ORGANIZACIÓN DE CONSUMIDORES / GRUPO`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `Paquete de CA`,
  [`Cache Key`]: `Clave de memoria caché`,
  [`Cache Time to Live (second)`]: `Vida de memoria caché (segundo)`,
  [`Cache key`]: `Clave de memoria caché`,
  [`Cache Time-To-Live`]: `Vida de memoria caché`,
  [`Cache type`]: `Tipo de memoria caché`,
  [`Can't find the one you want?`]: `¿No puede encontrar el que desea?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `No se puede enviar una invitación con un servidor de correo no válido, compruebe las configuraciones del servidor de correo y vuelva a intentarlo`,
  [`Cancel`]: `Cancelar`,
  [`Cannot be below {min}`]: `No puede estar por debajo de {min}`,
  [`Cannot be empty`]: `No puede estar vacío`,
  [`Cannot delete root type.`]: `No se puede suprimir el tipo raíz.`,
  [`Cannot exceed {max}`]: `No puede superar {max}`,
  [`Cannot find any APIs for this product`]: `No se ha encontrado ninguna API para este producto`,
  [`Cannot find the one you want?`]: `¿No puede encontrar el que desea?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `No puede utilizarse la directiva "@listSize" en un campo que no devuelve una lista cuando tampoco se define "sizedFields".`,
  [`Cannot remove built-in scalar types`]: `No se pueden eliminar los tipos escalares incorporados`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `No se pueden eliminar los tipos de operación raíz consulta, mutación y suscripción`,
  [`Cannot remove arguments of built-in directives`]: `No se pueden eliminar los argumentos de directivas incorporadas`,
  [`Cannot remove non-null arguments of directives`]: `No se pueden eliminar los argumentos no nulos de directivas`,
  [`Cannot remove input types of arguments of directives`]: `No se pueden eliminar los tipos de entrada de argumentos de directivas`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `No se pueden eliminar los valores de enumeración utilizados como valores predeterminados de argumentos de directivas`,
  [`Cannot remove enum values used as default values of input fields`]: `No se pueden eliminar los valores de enumeración utilizados como valores predeterminados de campos de entrada`,
  [`Cannot remove all fields of an object type`]: `No se pueden eliminar todos los campos de un tipo de objeto`,
  [`Cannot remove all fields of an interface type`]: `No se pueden eliminar todos los campos de un tipo de interfaz`,
  [`Cannot remove all slicing arguments`]: `No se pueden eliminar todos los argumentos de porcionado`,
  [`Cannot remove all input fields of an input object type`]: `No se pueden eliminar todos los campos de entrada de un tipo de objeto de entrada`,
  [`Cannot remove all values of enum type`]: `No se pueden eliminar todos los valores de tipo de enumeración`,
  [`Cannot remove fields of interfaces`]: `No se pueden eliminar campos de interfaces`,
  [`Cannot remove non-null input fields`]: `No se pueden eliminar campos de entrada no nulos`,
  [`Case`]: `Caso`,
  [`Case sensitive`]: `Sensible a mayúsculas y minúsculas`,
  [`Catalog`]: `Catálogo`,
  [`Catalog defaults`]: `Valores predeterminados de catálogo`,
  [`Catalog Invite`]: `Invitar catálogo`,
  [`Catalog invite`]: `Invitación de catálogo`,
  [`Catalog invites`]: `Invitaciones de catálogo`,
  [`Catalog name`]: `Nombre de catálogo`,
  [`Catalog owner`]: `Propietario de catálogo`,
  [`Catalog owner invitation`]: `Invitación de propietario de catálogo`,
  [`Catalog properties`]: `Propiedades de catálogo`,
  [`Catalog summary`]: `Resumen de catálogo`,
  [`Catalog title`]: `Título del catálogo`,
  [`Catalog user registries`]: `Registros de usuarios de catálogo`,
  [`Catalog by Name`]: `Catálogo por nombre`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Prioridad de catálogo (utilizar los puntos finales de vanidad definidos por el administrador del catálogo)`,
  [`Catalog {name} created`]: `Se ha creado el catálogo {name}`,
  [`Catalog selection`]: `Selección de catálogo`,
  [`Catalog settings`]: `Valores de catálogo`,
  [`Catalog settings have been updated`]: `Se han actualizado los valores de catálogo`,
  [`Catalog User`]: `Usuario de catálogo`,
  [`Catalog Users`]: `Usuarios de catálogo`,
  [`Catalog user registry`]: `Registro de usuarios de catálogo`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `El catálogo con LifeCycle habilitado no se puede utilizar en Preferencias de prueba.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `El catálogo con el ciclo de vida y la modalidad de producción habilitados no se puede utilizar en Preferencias de prueba.`,
  [`Catalogs`]: `Catálogos`,
  [`Catch`]: `Captar`,
  [`Catches`]: `Capturas`,
  [`Categories`]: `Categorías`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Las categorías establecen una visualización jerárquica de los productos de API en el portal del desarrollador. Utilice la sintaxis siguiente: elemento_nivel_superior/elemento_nivel_siguiente/elemento_nivel_inferior.`,
  [`Category`]: `Categoría`,
  [`Certifcate or Shared Secret for Verify`]: `Certificado o secreto compartido para verificar`,
  [`Certificate`]: `Certificado`,
  [`Certificate (Optional)`]: `Certificado (Opcional)`,
  [`Certificate management`]: `Gestión de certificados`,
  [`Certificate Manager instance`]: `Instancia de Certificate Manager`,
  [`Certificate (optional)`]: `Certificado (opcional)`,
  [`Certificates`]: `Certificados`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Los certificados se necesitan para proteger las conexiones entre la pasarela y los dominios que maneja. Todos los certificados deben almacenarse en un servicio de Certificate Manager. El servicio proporciona un repositorio seguro para los certificados y ayuda a evitar interrupciones enviándole notificaciones cuando los certificados estén a punto de caducar. Para empezar,`,
  [`Certificates details in this keystore`]: `Detalles de certificados en este almacén de claves`,
  [`Certificates details in this truststore`]: `Detalles de certificados en este almacén de confianza`,
  [`Change`]: `Cambiar`,
  [`Change Consumer Organization owner to an existing user`]: `Cambiar propietario de organización de consumidores a un usuario existente`,
  [`Change Ownership to an Existing User`]: `Cambiar propiedad a un usuario existente`,
  [`Change portal service`]: `Cambiar servicio de portal`,
  [`Change owner to existing user`]: `Cambiar propiedad a usuario existente`,
  [`Change owner to new user`]: `Cambiar propietario a usuario nuevo`,
  [`Change ownership by inviting a new user`]: `Cambiar propiedad invitando a un usuario nuevo`,
  [`Change ownership to`]: `Cambiar propiedad a`,
  [`Change ownership to a new user via email invitation`]: `Cambiar propiedad a un usuario nuevo mediante invitación por correo electrónico`,
  [`Change ownership to an existing user`]: `Cambiar propiedad a un usuario existente`,
  [`Change password`]: `Cambiar contraseña`,
  [`Change setup`]: `Cambiar configuración`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Los cambios realizados directamente en la API o el Ensamblaje subyacentes a un proveedor de OAuth pueden representar al proveedor de OAuth como no válido si no se actualizan también los valores correspondientes en la definición del mismo proveedor de OAuth. Si guarda estos cambios, asegúrese de realizar también las actualizaciones correspondientes en la definición de proveedor de OAuth.`,
  [`Change the following extra constraints in the schema:`]: `Cambie las restricciones adicionales siguientes en el esquema:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `El cambio de Certificate Manager eliminará todas las asignaciones existentes de certificados y paquetes de CA.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Al cambiar el esquema de DNS, cambiará el formato de los enlaces de URL a API Connect y al Portal del desarrollador. Deberá comunicar los enlaces nuevos a los usuarios. Pulse Cancelar si no desea continuar.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Al crear el nombre o la versión de una API se creará una API nueva. ¿Está seguro de que desea guardar los cambios?`,
  [`Check your email`]: `Revise su correo electrónico`,
  [`Child`]: `Hijo`,
  [`Chinese (Simplified)`]: `Chino (Simplificado)`,
  [`Chinese (Traditional)`]: `Chino (Tradicional)`,
  [`Choose`]: `Seleccionar`,
  [`Choose a`]: `Elija un`,
  [`Choose {docTypeLabel}`]: `Elegir {docTypeLabel}`,
  [`Choose Catalog`]: `Seleccionar catálogo`,
  [`Choose a Catalog`]: `Elegir un catálogo`,
  [`Choose a Consumer Org`]: `Elegir una organización de consumidor`,
  [`Choose Gateway`]: `Seleccionar pasarela`,
  [`Choose a Gateway`]: `Seleccionar una pasarela`,
  [`Choose Product`]: `Seleccionar producto`,
  [`Choose a Product`]: `Seleccionar un producto`,
  [`Choose Rate Limit`]: `Seleccionar límite de tarifa`,
  [`Choose a Rate Limit`]: `Seleccionar un límite de tarifa`,
  [`Choose Application`]: `Seleccionar aplicación`,
  [`Choose an Application`]: `Elegir una aplicación`,
  [`Choose a {docTypeLabel}`]: `Elegir una {docTypeLabel}`,
  [`Choose a publish destination`]: `Elegir un destino de publicación`,
  [`Choose a billing integration`]: `Elegir una integración de facturación`,
  [`Choose a catalog to test within:`]: `Elija un catálogo para hacer pruebas:`,
  [`Choose a plan against which to test:`]: `Elija un plan para hacer pruebas:`,
  [`Choose a product containing this API, or create a new one:`]: `Elija un producto donde resida la API o cree uno nuevo:`,
  [`Choose a user registry`]: `Elija un registro de usuarios`,
  [`Choose an application with which to test, or create a new one:`]: `Elija una aplicación con la que probar o crear una nueva:`,
  [`Choose an existing application`]: `Elegir una aplicación existente`,
  [`Choose an existing catalog`]: `Elegir un catálogo existente`,
  [`Choose an existing product published to your Sandbox catalog`]: `Elija un producto existente publicado en el catálogo de recinto de pruebas`,
  [`Choose an operation to invoke:`]: `Elija una operación para invocar:`,
  [`Choose an option`]: `Elija una opción`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Elija una opción para determinar cómo la política de correlación maneja una salida de matriz vacía. Si se elige todo (la opción predeterminada) se sacarán todas las matrices vacías y las matrices hijas vacías. Si se elije padre, se sacará solo la matriz vacía padre. Si se elige ninguna, no se sacará la matriz vacía.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `La elección de la modalidad de bloqueo tendrá efectos adversos sobre el rendimiento del sistema`,
  [`Choose existing directory`]: `Elegir directorio existente`,
  [`Choose one...`]: `Elija uno...`,
  [`Choose operations and paths to generate into this API`]: `Elija operaciones y vías de acceso que se van a generar en esta API`,
  [`Choose paths to generate into this API`]: `Elija las vías de acceso que se van a generar en esta API`,
  [`Choose the DNS scheme`]: `Elija el esquema de DNS`,
  [`Choose the permissions for the role.`]: `Elija los permisos para el rol.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Elija si deben utilizarse direcciones DNS dinámicas o estáticas para llamadas de API entrantes al servicio de pasarela y para acceder al portal del desarrollador.`,
  [`Cipher`]: `Cifrado`,
  [`Ciphers`]: `Cifrados`,
  [`Clear`]: `Borrar`,
  [`Clear constraints`]: `Borrar restricciones`,
  [`Clear file`]: `Borrar archivo`,
  [`Clear filters`]: `Borrar filtros`,
  [`Clear from selected`]: `Borrar de seleccionados`,
  [`Clear operation filter`]: `Borrar filtro de operación`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Pulse un certificado para ver los detalles acerca de su organización emisora y huellas dactilares.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Pulse el enlace siguiente para seguir autorizando API en otra pestaña y obtendrá el código de autorización para el paso 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Al pulsar el enlace siguiente se abrirá el servidor en una pestaña nueva. Si el navegador muestra un problema de certificado, puede elegir aceptarlo y volver aquí para probarlo de nuevo.`,
  [`Click add to add a billing integration.`]: `Pulse añadir para añadir una integración de facturación.`,
  [`Click Add to add a gateway extension.`]: `Pulse Añadir para añadir una extensión de pasarela.`,
  [`Click **Add domain** to add domains.`]: `Pulse **Añadir dominio** para añadir dominios.`,
  [`Click Add to add a new base endpoint.`]: `Pulse Añadir para añadir un nuevo punto final base.`,
  [`Click Add to add a new HTTP header.`]: `Pulse Añadir para añadir una cabecera HTTP nueva.`,
  [`Click Add to add a new member.`]: `Pulse Añadir para añadir un miembro nuevo.`,
  [`Click Add to add a new role.`]: `Pulse Añadir para añadir un rol nuevo.`,
  [`Click Add to add a new scope.`]: `Pulse Añadir para añadir un ámbito nuevo.`,
  [`Click Add to add a new consumer organization group.`]: `Pulse Añadir para añadir una nuevo grupo de organizaciones de consumidores.`,
  [`Click Add to create the API Key.`]: `Pulse Añadir para crear la clave de API.`,
  [`Click Create to add the API Key.`]: `Pulse Crear para añadir la clave de API.`,
  [`Click Create to add a new user registry.`]: `Pulse Crear para añadir un nuevo registro de usuarios.`,
  [`Click Create user registry to add a new user registry.`]: `Pulse Crear registro de usuarios para añadir un nuevo registro de usuarios.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Pulse **Crear organización de proveedores** para añadir organizaciones de proveedores.`,
  [`Click Edit to add OAuth providers.`]: `Pulse Editar para añadir proveedores de OAuth.`,
  [`Click Edit to add a user registry.`]: `Pulse Editar para añadir un registro de usuarios.`,
  [`Click Edit to add an API to the product.`]: `Pulse Editar para añadir una API al producto.`,
  [`Click Create to add a property.`]: `Pulse Crear para añadir una propiedad.`,
  [`Click Edit to add TLS client profiles.`]: `Pulse Editar para añadir perfiles de cliente TLS.`,
  [`Click Edit to add user registries.`]: `Pulse Editar para añadir registros de usuarios.`,
  [`Click Add to add a new consumer organization.`]: `Pulse Añadir para añadir una nueva organización de consumidores.`,
  [`Click Consumer organizations to add one.`]: `Pulse Organizaciones de consumidores para añadir una.`,
  [`Click Add to add a new application.`]: `Pulse Añadir para añadir una nueva aplicación.`,
  [`Click Add to add parameters.`]: `Pulse Añadir para añadir parámetros.`,
  [`Click Add to add response.`]: `Pulse Añadir para añadir una respuesta.`,
  [`Click Create to add a new TLS client profile.`]: `Pulse Crear para añadir un perfil de cliente TLS nuevo.`,
  [`Click Create to add a new keystore.`]: `Pulse Crear para añadir un almacén de claves nuevo.`,
  [`Click Create to add a new truststore.`]: `Pulse Crear para añadir un almacén de confianza nuevo.`,
  [`Click **Register remote gateway** to add gateways.`]: `Pulse **Registrar pasarela remota** para añadir pasarelas.`,
  [`Click Register gateways to add new Gateway Services.`]: `Pulse Registrar pasarelas para añadir nuevos servicios de pasarela.`,
  [`Click Edit to add Gateway Services.`]: `Pulse Editar para añadir servicios de pasarela.`,
  [`Click Register gateways to add new gateway services.`]: `Pulse Registrar pasarelas para añadir nuevos servicios de pasarela.`,
  [`Click Edit to add gateway services.`]: `Pulse Editar para añadir servicios de pasarela.`,
  [`Click Edit to add an email server.`]: `Pulse Editar para añadir un servidor de correo electrónico.`,
  [`Click Create to add a new TLS server profile.`]: `Pulse Crear para añadir un perfil de servidor TLS nuevo.`,
  [`Click Create to add a new email server.`]: `Pulse Crear para añadir un servidor de correo electrónico nuevo.`,
  [`Click Add to add a new schema definition.`]: `Pulse Añadir para añadir una nueva definición de esquema.`,
  [`Click Add to add a new property for this definition.`]: `Pulse Añadir para añadir una propiedad nueva para esta definición.`,
  [`Click Add to add a new parameter for this API.`]: `Pulse Añadir para añadir un parámetro nuevo para esta API.`,
  [`Click Add to add tags and external documentation for this API.`]: `Pulse Añadir para añadir etiquetas y documentación externa para esta API.`,
  [`Click Add to add a new path.`]: `Pulse Añadir para añadir una nueva vía de acceso.`,
  [`Click Add to add a property for this API.`]: `Pulse Añadir para añadir una propiedad para esta API.`,
  [`Click here to create a security definition`]: `Pulse aquí para crear una definición de seguridad`,
  [`Click Add to add a new security definition.`]: `Pulse Añadir para añadir una nueva definición de seguridad`,
  [`Click Add to add a target service for this API.`]: `Pulse Añadir para añadir un servicio de destino para esta API.`,
  [`Click Add to add an operation for this path.`]: `Pulse Añadir para añadir una operación para esta vía de acceso.`,
  [`Click Add to add a path parameter for this path.`]: `Pulse Añadir para añadir un parámetro de vía de acceso para esta vía de acceso.`,
  [`Click Add to add a new OAuth provider.`]: `Pulse Añadir para añadir un proveedor de OAuth nuevo.`,
  [`Click Add to add a new provider organization.`]: `Pulse Añadir para añadir una nueva organización de proveedores.`,
  [`Click Add to add a new API or product.`]: `Pulse Añadir para añadir una API o un producto nuevos.`,
  [`Click Add to add a new API.`]: `Pulse Añadir para añadir una API nueva.`,
  [`Click Add to add a new Extension.`]: `Pulse Añadir para añadir una nueva extensión.`,
  [`Click Add to add new {resource}.`]: `Pulse Añadir para añadir un nuevo {resource}.`,
  [`Click to expand`]: `Pulse para expandir`,
  [`Click to collapse`]: `Pulse para contraer`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Pulse aquí para obtener información sobre la carga y gestión de los certificados necesarios para la pasarela.`,
  [`Client Credentials`]: `Credenciales de cliente`,
  [`Client information`]: `Información de cliente`,
  [`Client revocation path`]: `Vía de acceso de revocación de cliente`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Pulse Registrar servicio para registrar un servicio. Esto le permitirá probar puntos finales al configurar otros servicios.`,
  [`Client secret`]: `Secreto de cliente`,
  [`Client security`]: `Seguridad de cliente`,
  [`Client Type`]: `Tipo de cliente`,
  [`Client authentication method`]: `Método de autenticación de cliente`,
  [`Clone`]: `Clonar`,
  [`Close`]: `Cerrar`,
  [`Cloud`]: `Nube`,
  [`Cloud Admin`]: `Administrador de nube`,
  [`Cloud Administrator`]: `Administrador de nube`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Administrador de nube, Gestor de organización, Administrador de topología. El rol Miembro se asigna automáticamente a todos los usuarios.`,
  [`Cloud administration`]: `Administración de nube`,
  [`Cloud Discovery Agent`]: `Agente de descubrimiento de nube`,
  [`Cloud Manager`]: `Gestor de nube`,
  [`Cloud Manager Local User Registry`]: `Registro de usuarios local del Gestor de nube`,
  [`Cloud-Settings`]: `Valores de Cloud`,
  [`Cloud settings invitation timeout`]: `Tiempo de espera de invitación de valores de nube`,
  [`Cloud settings invitation timeout has been changed`]: `Se ha cambiado el tiempo de espera de la invitación de valores de nube`,
  [`Code editor`]: `Editor de código`,
  [`Collect Metadata`]: `Recopilar metadatos`,
  [`Collect credentials using`]: `Recopilar credenciales utilizando`,
  [`Collect metadata`]: `Recopilar metadatos`,
  [`Combo box`]: `Recuadro combinado`,
  [`Community Manager`]: `Gestor de comunidad`,
  [`community-manager`]: `gestor de comunidad`,
  [`Compatibility`]: `Compatibilidad`,
  [`Complex Definitions must be edited in the editor`]: `Las definiciones complejas se deben editar en el editor`,
  [`Compose DN`]: `Componer DN`,
  [`Compose UPN`]: `Componer UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `Componer un proxy REST nuevo definiendo vías de acceso y operaciones`,
  [`Compose a new product by adding rate limits and plans`]: `Componer un producto nuevo añadiendo límites de velocidad y planes`,
  [`Compression`]: `Compresión`,
  [`Condition`]: `Condición`,
  [`Condition editor`]: `Editor de condiciones`,
  [`Confidential`]: `Confidencial`,
  [`Configuration`]: `Configuración`,
  [`Configurations`]: `Configuraciones`,
  [`Configure API endpoint`]: `Configurar punto final de API`,
  [`Configure API Gateway Service`]: `Configurar servicio de pasarela de API`,
  [`Configure analytics service`]: `Configurar servicio de análisis`,
  [`Configure availability zone`]: `Configurar zona de disponibilidad`,
  [`Configure catalog user registries`]: `Configurar registros de usuarios de catálogo`,
  [`Configure cloud`]: `Configurar nube`,
  [`Configure DataPower API gateway service`]: `Configurar servicio de pasarela de API de DataPower`,
  [`Configure DataPower gateway service`]: `Configurar servicio de pasarela de DataPower`,
  [`Configure Email Server`]: `Configurar el servidor de correo electrónico`,
  [`Configure gateway extension`]: `Configurar extensión de pasarela`,
  [`Configure portal service`]: `Configurar servicio de portal`,
  [`Configure service`]: `Configurar servicio`,
  [`Configure topology`]: `Configurar topología`,
  [`Configure XML`]: `Configurar XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Configurar un servicio de pasarela de API de DataPower para proteger y ejecutar las API`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Configurar un servicio de pasarela de DataPower para proteger y ejecutar las API`,
  [`Configure a developer portal service for API consumers`]: `Configurar un servicio de portal de desarrollador para consumidores de API`,
  [`Configure advanced features for OIDC settings`]: `Se configuran las funciones avanzadas para los valores de OIDC`,
  [`Configure an analytics service to collect API call data`]: `Configurar un servicio de análisis para recopilar datos de llamada de API`,
  [`Configure an email server to send invitations and notifications to users.`]: `Configurar un servidor de correo electrónico para enviar invitaciones y notificaciones a los usuarios.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Configure la auditoría para supervisar las operaciones de la interfaz de usuario de administrador, la interfaz de usuario de gestor, el kit de herramientas, la API REST de gestión y los usuarios del portal. Los registros de cada operación de usuario de creación, actualización y supresión se envían a los servicios de registro remotos especificados a continuación.`,
  [`Configure availability zones and services`]: `Configure zonas de disponibilidad y servicios`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Configure cómo se componen los URL de punto final de API en las API publicadas`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Configure cómo se visualizan los puntos finales de vanidad en el portal de desarrollador`,
  [`Configure mapping`]: `Configurar correlación`,
  [`Configure properties of the activity log`]: `Configurar las propiedades del registro de actividad`,
  [`Configure settings for token management and revocation.`]: `Configurar valores para la gestión y la revocación de señales.`,
  [`Configure settings to generate and validate tokens.`]: `Configurar valores para generar y validar señales.`,
  [`Configure the API security`]: `Configure la seguridad de API`,
  [`Configure the default set of gateway services configured for each catalog`]: `Configurar el conjunto predeterminado de servicios de pasarela configurado para cada catálogo`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Configure el portal del desarrollador utilizado por los desarrolladores de aplicaciones para acceder a las API de este catálogo`,
  [`Configure the gateway services used by default in each catalog`]: `Configurar los servicios de pasarela utilizados de forma predeterminada en cada catálogo`,
  [`Configure the gateway extension for the selected gateway`]: `Configure la extensión de pasarela para la pasarela seleccionada`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Configure la pasarela de API DataPower gestionada por IBM y gestione sus propias pasarelas remotas. [Más información]({link})`,
  [`Configure the keystore and upload certificates`]: `Configurar el almacén de claves y cargar certificados`,
  [`Configure the name and email address to be used in the from field of emails`]: `Configurar el nombre y la dirección de correo electrónico a utilizar en el campo desde de los correos electrónicos`,
  [`Configure the portal service for the catalog`]: `Configure el servicio de portal para el catálogo`,
  [`Configure the role and assign permissions`]: `Configurar el rol y asignar permisos`,
  [`Configure the security of this API`]: `Configurar la seguridad de esta API`,
  [`Configure the sender name and address to use for email notifications`]: `Configurar el nombre y la dirección del remitente a utilizar para notificaciones de correo electrónico`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Configurar el nombre de remitente, la dirección y el servidor de correo electrónico utilizado para enviar invitaciones y notificaciones a los usuarios.`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Configurar el conjunto de roles para utilizar de forma predeterminada cuando se crea una organización de consumidores`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Configurar el conjunto de roles para utilizar de forma predeterminada cuando se crea una organización de proveedores`,
  [`Configure the templates used to invite and notify users`]: `Configurar las plantillas utilizadas para invitar a los usuarios y enviarles notificaciones`,
  [`Configure the truststore and upload certificates`]: `Configurar el almacén de confianza y cargar certificados`,
  [`Configure user authentication using`]: `Configurar la autenticación de usuario utilizando`,
  [`Configure user authentication using JSON Web Tokens`]: `Configurar la autenticación de usuario mediante Señales web JSON`,
  [`Configure user authentication using a LDAP provider`]: `Configurar la autenticación de usuario mediante un proveedor LDAP`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Configurar la autenticación de usuario mediante un registro de usuarios local de API Connect`,
  [`Configure user authentication using an authentication URL`]: `Configurar la autenticación de usuario mediante un URL de autenticación`,
  [`Configure user authentication using this user registry type`]: `Configurar la autenticación de usuario mediante este tipo de registro de usuarios`,
  [`Configure user registries, OAuth providers and TLS`]: `Configurar registros de usuarios, proveedores de OAuth y TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Configurar registros de usuarios, TLS, proveedores de OAuth y servidores de correo electrónico`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Configurar almacén de claves para señal de acceso, señal de ID y señal temporal`,
  [`Configured OAuth Provider`]: `Proveedor de OAuth configurado`,
  [`Configured OAuth Providers`]: `Proveedores de OAuth configurados`,
  [`Configured OAuth provider`]: `Proveedor de OAuth configurado`,
  [`Configured OAuth provider list has been updated`]: `Se ha actualizado la lista de proveedores de OAuth configurados`,
  [`Configured OAuth provider list has not been updated`]: `No se ha actualizado la lista de proveedores de OAuth configurados`,
  [`Confirm`]: `Confirmar`,
  [`Confirm deletion`]: `Confirmar supresión`,
  [`Confirm Deletion of Portal`]: `Confirmar supresión de Portal`,
  [`Confirm visibility settings`]: `Confirmar valores de visibilidad`,
  [`Confirm new password`]: `Confirmar contraseña nueva`,
  [`Confirm password`]: `Confirmar contraseña`,
  [`Confirm password should match password`]: `La contraseña de confirmación debe coincidir con la contraseña`,
  [`Confirmation`]: `Confirmación`,
  [`Congratulations, you are now registered.`]: `Enhorabuena, ahora está registrado.`,
  [`Congratulations, your password has been reset!`]: `¡Enhorabuena, se ha restablecido la contraseña!`,
  [`Connect`]: `Conectar`,
  [`Connect to API Connect`]: `Conectarse a API Connect`,
  [`Connected to API Manager Product version`]: `Conectado a la versión del producto de API Manager`,
  [`Connect to Cloud`]: `Conectarse a la nube`,
  [`Connected`]: `Conectado`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Construya la definición de esquema añadiendo propiedades de los tipos necesarios. Puede anidar propiedades`,
  [`Consider for nesting`]: `Tener en cuenta para anidamiento`,
  [`Consumed Media Types`]: `Tipos de soportes consumidos`,
  [`Consumer`]: `Consumidor`,
  [`Consumer invitation and roles`]: `Invitación y roles de consumidor`,
  [`Consumer onboarding`]: `Incorporación de consumidor`,
  [`Consumer organizations`]: `Organización de consumidores`,
  [`Consumer organizations or groups`]: `Organizaciones de consumidores o grupos`,
  [`Consumer organization {name} created`]: `Se ha creado la organización de consumidores {name}`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Las organizaciones de consumidores las crean desarrolladores de aplicaciones en el Portal de desarrollador y también puede crearlas utilizando la interfaz de usuario del Gestor de API.`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Las organizaciones de consumidores las crean desarrolladores de aplicaciones en el portal de desarrollador y también puede crearlas aquí`,
  [`Consumer-Org`]: `Organización de consumidores`,
  [`Consumers`]: `Consumidores`,
  [`Consumes`]: `Consume`,
  [`Contact`]: `Contacto`,
  [`Contact information for the owners of the API.`]: `Información de contacto de los propietarios de la API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Consulte al administrador sobre la configuración del servicio de análisis para que pueda ver sus datos aquí.`,
  [`Contact your administrator for more information.`]: `Póngase en contacto con el administrador para obtener más información.`,
  [`Content`]: `Contenido`,
  [`Content on error`]: `Contenido en caso de error`,
  [`Content type`]: `Tipo de contenido`,
  [`Context Variable`]: `Variable de contexto`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Variable de contexto o de tiempo de ejecución que contiene la JWT que se va a validar. Si no se ha establecido, la política busca la JWT en request.headers.authorization.`,
  [`Context variable`]: `Variable de contexto`,
  [`Continue`]: `Continuar`,
  [`Continue on error`]: `Continuar en caso de error`,
  [`Continue with`]: `Continúe con`,
  [`Continue with:`]: `Continuar con:`,
  [`Conversion type`]: `Tipo de conversión`,
  [`Convert to expression`]: `Convertir en expresión`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `Copiado`,
  [`Copied to clipboard`]: `Copiado en el portapapeles`,
  [`Copy`]: `Copiar`,
  [`Copy Id Headers To Message`]: `Copiar cabeceras de ID en mensaje`,
  [`Copy to clipboard`]: `Copiar en el portapapeles`,
  [`Copy file path to clipboard`]: `Copiar vía de acceso de archivo en el portapapeles`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Copiar estimación de costes para la consulta de GraphQL en la salida`,
  [`Copy the endpoints below to`]: `Copiar los puntos finales que figuran a continuación en`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Copiar la clave de API que va a utilizar. Puede volver a ver esta clave de API con el icono Ver.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Información de copyright`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Corrija los errores del editor anterior o vuelva a cargar un archivo válido`,
  [`Cost`]: `Coste`,
  [`Count`]: `Recuento`,
  [`Count Limit Name`]: `Nombre de límite de recuento`,
  [`Count Limits`]: `Límites de recuento`,
  [`Created at`]: `Hora de creación`,
  [`Counter`]: `Contador`,
  [`Create`]: `Crear`,
  [`Create a [Certificate Manager]({link}) service`]: `Crear un servicio de [Certificate Manager]({link})`,
  [`Create API`]: `Crear API`,
  [`Create an API from the Develop section.`]: `Cree una API desde la sección Desarrollar.`,
  [`Create API Connect API Key`]: `Crear clave de API de API Connect`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Crear API a partir de servicio GraphQL existente (proxy GraphQL)`,
  [`Create API from existing OpenAPI service`]: `Crear API a partir de servicio OpenAPI existente`,
  [`Create API from existing WSDL service (REST proxy)`]: `Crear API a partir de servicio WSDL existente (proxy REST)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Crear API a partir de servicio WSDL existente (proxy SOAP)`,
  [`Create API from target service`]: `Crear API a partir de servicio de destino`,
  [`Create API from existing REST service`]: `Crear API de servicio REST existente`,
  [`Create API from existing SOAP service`]: `Crear API de servicio SOAP existente`,
  [`Create API from existing WSDL service`]: `Crear API de servicio WSDL existente`,
  [`Create API Key failed`]: `No se ha podido crear la clave de API`,
  [`Create application`]: `Crear aplicación`,
  [`Create authentication URL user registry`]: `Crear registro de usuarios de URL de autenticación`,
  [`Create availability zone`]: `Crear zona de disponibilidad`,
  [`Create Billing`]: `Crear facturación`,
  [`Create catalog`]: `Crear catálogo`,
  [`Create catalog property`]: `Crear propiedad de catálogo`,
  [`Create consumer organization`]: `Crear organización de consumidores`,
  [`Create Credentials`]: `Crear credenciales`,
  [`Create definition`]: `Crear definición`,
  [`Create email server`]: `Crear servidor de correo electrónico`,
  [`Create IBM Developer Portal`]: `Crear IBM Developer Portal`,
  [`Create keystore`]: `Crear almacén de claves`,
  [`Create LDAP user registry`]: `Crear registro de usuarios de LDAP`,
  [`Create LTPA Key`]: `Crear clave LTPA`,
  [`Create local user registry`]: `Crear registro de usuarios local`,
  [`Create native OAuth provider`]: `Proveedor de OAuth nativo`,
  [`Create New GraphQL API`]: `Crear API GraphQL nueva`,
  [`Create new OpenAPI`]: `Crear OpenAPI`,
  [`Create new product`]: `Crear producto`,
  [`Create OIDC user registry`]: `Crear registro de usuarios OIDC`,
  [`Create OpenAPI definition and product definition`]: `Crear una definición de OpenAPI y una definición de producto`,
  [`Create operation`]: `Crear operación`,
  [`Create organization`]: `Crear organización`,
  [`Create path`]: `Crear vía de acceso`,
  [`Create plan`]: `Crear plan`,
  [`Create portal`]: `Crear portal`,
  [`Create Product`]: `Crear producto`,
  [`Create property`]: `Crear propiedad`,
  [`Create role`]: `Crear rol`,
  [`Create role default for the Organization`]: `Crear valor predeterminado de rol para la organización`,
  [`Create sample user registry`]: `Crear registro de usuarios de ejemplo`,
  [`Create security definition`]: `Crear definición de seguridad`,
  [`Create space`]: `Crear espacio`,
  [`Create subscription`]: `Crear suscripción`,
  [`Create TLS client profile`]: `Crear perfil de cliente TLS`,
  [`Create TLS server profile`]: `Crear perfil de servidor TLS`,
  [`Create target service`]: `Crear servicio de destino`,
  [`Create third party OAuth provider`]: `Crear proveedor de OAuth de terceros`,
  [`Create truststore`]: `Crear almacén de confianza`,
  [`Create user registry`]: `Crear registro de usuarios`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Crear un proxy GraphQL basado en el servicio GraphQL`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Crear un proxy REST basado en un servicio de destino descrito de OpenAPI`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Crear un proxy REST basado en un servicio de destino descrito de WSDL`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Crear un proxy REST basado en un servicio de destino descrito de OpenAPI`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Crear un proxy REST basado en el servicio de destino descrito de WSDL`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Crear un proxy REST que direcciona todo el tráfico a una API de destino o al punto final de servicio`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Crear un proxy SOAP basado en un servicio de destino descrito de WSDL`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Crear un proxy SOAP basado en el servicio de destino descrito de WSDL`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Cree un objeto nuevo mediante una plantilla de barra de descriptor de contexto. Las variables se sacan del contexto.`,
  [`Create a portal site for the catalog`]: `Crear un sitio de portal para el catálogo`,
  [`Create a product by importing a product definition`]: `Crear un producto importando una definición de producto`,
  [`Create a security definition`]: `Crear una definición de seguridad`,
  [`Create a subscription`]: `Crear una suscripción`,
  [`Create an API by importing an OpenAPI definition`]: `Crear una API importando una definición de OpenAPI`,
  [`Create an API that calls an existing SOAP service`]: `Crear una API que llame a un servicio SOAP existente`,
  [`Create and manage API provider organizations and owners`]: `Crear y gestionar propietarios y organizaciones de proveedores de API`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Crear y gestionar los espacios del catálogo; los espacios permiten particionar el catálogo para dar soporte a distintos equipos de desarrollo de proveedor de API`,
  [`Create and publish`]: `Crear y publicar`,
  [`Create and publish APIs and Products`]: `Crear y publicar las API y los productos`,
  [`Create and subscribe`]: `Crear y suscribir`,
  [`Create assembly`]: `Crear ensamblaje`,
  [`Create empty parent object for failed mapping`]: `Crear objeto padre vacío para un error de correlación`,
  [`Create endpoint`]: `Crear punto final`,
  [`Create from`]: `Crear a partir de`,
  [`Create from Existing WSDL service`]: `Crear a partir del servicio WSDL existente`,
  [`Create from File or URL`]: `Crear a partir de archivo o URL`,
  [`Create from existing openAPI service`]: `Crear a partir de servicio openAPI existente`,
  [`Create gateway extension`]: `Crear extensión de pasarela`,
  [`Create new user`]: `Crear el usuario nuevo`,
  [`Create new version`]: `Crear versión nueva`,
  [`Create product using a template`]: `Crear producto utilizando una plantilla`,
  [`Create provider organization`]: `Crear organización de proveedores`,
  [`Create required child properties for array objects and mapped optional objects`]: `Crear propiedades hijo necesarias para objetos de matriz y objetos opcionales correlacionados`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Creado como ejemplo por configuración de Proveedor de OAuth. Asegúrese de actualizar los proveedores de OAuth mediante este ejemplo con un registro de usuarios válido.`,
  [`Created new product`]: `Se ha creado un producto nuevo`,
  [`Create|permission`]: `Crear`,
  [`Create|title`]: `Crear`,
  [`Creating Draft Product`]: `Creación de borrador de producto`,
  [`Creating a Consumer organization`]: `Creación de una organización de consumidores`,
  [`Creating a consumer organization`]: `Creación de una organización de consumidores`,
  [`Creating a new Product`]: `Creación de un producto nuevo`,
  [`Creative Commons (CC-BY-4.0) license`]: `Licencia de Creative Commons (CC-BY-4.0)`,
  [`Credential Extraction Method`]: `Método de extracción de credenciales`,
  [`Credentials for the Sandbox catalog`]: `Credenciales para el catálogo de recinto de pruebas`,
  [`Credentials for the {catalogName} catalog`]: `Credenciales para el catálogo {catalogName}`,
  [`Credential Name`]: `Nombre de credencial`,
  [`Credential has been added.`]: `Se ha añadido la credencial.`,
  [`Credential`]: `Credencial`,
  [`Credentials`]: `Credenciales`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Las credenciales son necesarias para ejecutar el kit de herramientas y API Designer.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Las credenciales son necesarias para ejecutar el kit de herramientas. Ponga los archivos de credenciales en la carpeta en la que se ha instalado el kit de herramientas.`,
  [`Credit Card`]: `Tarjeta de crédito`,
  [`Cryptographic Algorithm`]: `Algoritmo criptográfico`,
  [`Currency`]: `Moneda`,
  [`Current password`]: `Contraseña actual`,
  [`Current password is invalid.`]: `La contraseña actual no es válida.`,
  [`Custom`]: `Personalizado`,
  [`Custom API URL`]: `URL de API personalizado`,
  [`Custom expression (optional, one per line)`]: `Expresión personalizada (opcional, una por línea)`,
  [`Custom expression to remove (optional, one per line)`]: `Expresión personalizada a eliminar (opcional, una por línea)`,
  [`Custom HTML form`]: `Formulario HTML personalizado`,
  [`Custom policies`]: `Políticas personalizadas`,
  [`Custom form TLS profile`]: `Perfil de TLS de formulario personalizado`,
  [`Custom form content security policy`]: `Política de seguridad de contenido de formulario personalizado`,
  [`Custom form CSP header value`]: `Valor de cabecera CSP de formulario personalizado`,
  [`Custom form endpoint`]: `Punto final de formulario personalizado`,
  [`Custom header pattern`]: `Patrón de cabecera personalizado`,
  [`Custom subscribability is required if custom visibility is selected`]: `La capacidad de suscripción personalizada es necesaria si se selecciona la visibilidad personalizada`,
  [`Customize`]: `Personalizar`,
  [`Customize email sender information`]: `Personalizar información de remitente de correo electrónico`,
  [`Customize notification templates`]: `Personalizar plantillas de notificación`,
  [`Customize the plan API list`]: `Personalizar la lista de API de plan`,
  [`Czech`]: `Checo`,
  [`DATE MODIFIED`]: `FECHA DE MODIFICACIÓN`,
  [`DELETE`]: `SUPRIMIR`,
  [`Date created`]: `Fecha de creación`,
  [`Description`]: `Descripción`,
  [`DETAIL`]: `DETALLE`,
  [`DN Base`]: `DN base`,
  [`DNS Schemes`]: `Esquemas de DNS`,
  [`Dashboard`]: `Panel de control`,
  [`Data encoded form body`]: `Cuerpo de formato codificado de datos`,
  [`Data flow in API Connect`]: `Flujo de datos en API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (compatible con v5)`,
  [`DataPower API`]: `API de DataPower`,
  [`DataPower API Gateway`]: `API Gateway de DataPower`,
  [`DataPower Gateway (v5 compatible)`]: `Pasarela de DataPower (compatible con v5)`,
  [`DataPower Gateway policies`]: `Políticas de DataPower Gateway`,
  [`Debug`]: `Depurar`,
  [`Debug rule`]: `Regla de depuración`,
  [`Debug XACML policy`]: `Política XACML de depuración`,
  [`Dec`]: `Dic`,
  [`Decline`]: `Rechazar`,
  [`Decline and send`]: `Rechazar y enviar`,
  [`Decode Request Params`]: `Decodificar parámetros de solicitud`,
  [`Decrement`]: `Disminuir`,
  [`Decrypt`]: `Descifrar`,
  [`Decrypt Crypto JWK variable name`]: `Nombre de la variable JWK criptográfica de descifrado`,
  [`Decrypt Crypto Object`]: `Objeto de criptográfico de descifrado`,
  [`Decrypt a buffer using the specified certificate`]: `Descifrar un almacenamiento intermedio utilizando el certificado especificado`,
  [`Default`]: `Predeterminado`,
  [`Default behavior`]: `Comportamiento predeterminado`,
  [`Default gateways`]: `Pasarelas predeterminadas`,
  [`default gateway services configured`]: `servicios de pasarela predeterminados configurados`,
  [`Default Availability Zone`]: `Zona de disponibilidad predeterminada`,
  [`Default Base endpoints`]: `Puntos finales base predeterminados`,
  [`Default availability zone`]: `Zona de disponibilidad predeterminada`,
  [`Default base endpoints`]: `Puntos finales base predeterminados`,
  [`Default HTML form`]: `Formulario HTML predeterminado`,
  [`Default limit`]: `Límite predeterminado`,
  [`Default OAuth Provider Settings`]: `Valores de proveedor OAuth predeterminados`,
  [`Default OAuth Provider Settings Object`]: `Objeto de valores de proveedor OAuth predeterminados`,
  [`Default Plan`]: `Plan predeterminado`,
  [`Default TLS client profile`]: `Perfil de cliente TLS predeterminado`,
  [`Default TLS server keystore`]: `Almacén de claves de servidor TLS predeterminado`,
  [`Default TLS server profile`]: `Perfil de servidor TLS predeterminado`,
  [`Default burst-limit`]: `Límite de ráfaga predeterminado`,
  [`Default form`]: `Formulario predeterminado`,
  [`Default keystore for access_token signing key`]: `Almacén de claves predeterminado para clave de firma de señal de acceso`,
  [`Default keystore for id_token signing key`]: `Almacén de claves predeterminado para clave de firma de señal de id`,
  [`Default keystore for temporary token signing key`]: `Almacén de claves predeterminado para clave de firma de señal temporal`,
  [`Default rate-limit`]: `Límite de velocidad predeterminado`,
  [`Default scopes`]: `Ámbitos predeterminados`,
  [`Default table entry`]: `Entrada de tabla predeterminada`,
  [`Default validator endpoint`]: `Punto final de validador predeterminado`,
  [`Default validator endpoint set by OAuth provider`]: `Punto final de validador predeterminado establecido por el proveedor de OAuth`,
  [`Default value - no extra constraints apply.`]: `Valor predeterminado. No se aplican restricciones adicionales.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Defina un proveedor de OAuth de terceros para emitir y validar señales para proteger el acceso a la API.`,
  [`Define catalog specific values for this property`]: `Defina los valores específicos de catálogo para esta propiedad`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Defina grupos que gestionen API, productos, catálogos, aplicaciones y valores relacionados.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Defina conjuntos de políticas a ejecutar para condiciones específicas. Pueden definirse varios casos, y cada caso puede asociarse con una condición arbitraria. Sólo se ejecutará el primer caso coincidente.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Defina el origen de protección de API. Debe habilitar una opción como mínimo.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `El servidor de autorizaciones de OIDC redirigirá el código de autorización a los puntos finales siguientes. Este punto final de redirección es necesario cuando un cliente registra la aplicación en el proveedor de OIDC. Por ejemplo, si una organización de proveedores utiliza este registro de usuarios es utilizado, el cliente debe registrar el punto final de redirección de la organización de proveedores con el proveedor de OIDC.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Defina las entradas que se van a utilizar en la correlación. También puede asignar un título y una descripción a la correlación.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Se definen los puntos finales del proveedor de OpenID para enviar una solicitud de autenticación a su proveedor de OpenID`,
  [`Define the outputs to be used in the map.`]: `Defina las salidas que se van a utilizar en la correlación.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Defina el número de días de prueba libres y el precio del plan. La facturación a los usuarios estará basada en la fecha de suscripción correspondiente.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Define si el flujo continua cuando se genera un error durante la ejecución de políticas. Si no se selecciona, se desencadena un flujo de captación.`,
  [`Definition`]: `Definición`,
  [`Definition Object`]: `Objeto de definición`,
  [`Definitions`]: `Definiciones`,
  [`Delete`]: `Suprimir`,
  [`Delete Application`]: `Suprimir aplicación`,
  [`Delete Cloud Connection.`]: `Suprimir conexión de nube.`,
  [`Delete Selected APIs`]: `Suprimir API seleccionadas`,
  [`Delete selected client profiles`]: `Suprimir perfiles de cliente seleccionados`,
  [`Delete selected keystores`]: `Suprimir almacenes de claves seleccionadas`,
  [`Delete selected mail servers`]: `Suprimir servidores de correo seleccionadas`,
  [`Delete Selected Products`]: `Suprimir productos seleccionados`,
  [`Delete selected truststores`]: `Suprimir almacenes de confianza seleccionados`,
  [`Delete selected user registries`]: `Suprimir registros de usuarios seleccionados`,
  [`Delete Staged Product`]: `Suprimir producto desplegado`,
  [`Delete a space`]: `Suprimir un espacio`,
  [`Delete a catalog`]: `Suprimir un catálogo`,
  [`Delete API Key`]: `Suprimir clave de API`,
  [`Delete API Key failed`]: `No se ha podido suprimir la clave de API`,
  [`Delete availability zone`]: `Suprimir zona de disponibilidad`,
  [`Delete selected requests`]: `Suprimir solicitudes seleccionadas`,
  [`Delete row`]: `Suprimir fila`,
  [`Delete|title`]: `Suprimir`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `La supresión de un catálogo puede fallar si tiene un gran número de organizaciones de consumidores, aplicaciones, productos publicados y otros recursos.  Considere la posibilidad de realizar una limpieza de estos recursos antes de suprimir el catálogo.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `La supresión de una organización de proveedores puede fallar si tiene un gran número de catálogos, organizaciones de consumidores, aplicaciones, productos publicados y otros recursos. Considere la posibilidad de realizar una limpieza de estos recursos antes de suprimir la organización de proveedores.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `La supresión de un espacio puede fallar si tiene un gran número de organizaciones de consumidores, aplicaciones, productos publicados y otros recursos.  Considere la posibilidad de realizar una limpieza de estos recursos antes de suprimir el espacio.`,
  [`Deleting {{name}}...`]: `Se está suprimiendo {name}...`,
  [`Deprecate`]: `Dejar en desuso`,
  [`Deprecate product`]: `Dejar de utilizar el producto`,
  [`Deprecated`]: `En desuso`,
  [`Deprecate|permission`]: `Dejar en desuso`,
  [`Description of gateway`]: `Descripción de pasarela`,
  [`Description text for the behaviour of the modal.`]: `Texto de descripción del comportamiento del modelo.`,
  [`Descriptive name`]: `Nombre descriptivo`,
  [`Design`]: `Diseñar`,
  [`Designate a current member as owner of a consumer organization.`]: `Designar un miembro actual como propietario de una organización de consumidores.`,
  [`Designate a current member as owner of a provider organization.`]: `Designar un miembro actual como propietario de una organización de proveedores.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Destino`,
  [`Determines which credential-extraction method to use.`]: `Determina qué método de extracción de credenciales debe utilizarse.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Determina si se debe incluir este tipo/campo y los argumentos aplicables en el esquema.`,
  [`Develop`]: `Desarrollar`,
  [`Develop APIs`]: `Desarrollar API`,
  [`Develop APIs and products`]: `Desarrollar API y productos`,
  [`Developer`]: `Desarrollador`,
  [`developer`]: `desarrollador`,
  [`Developer Portal`]: `Portal del desarrollador`,
  [`Developer community`]: `Comunidad de desarrolladores`,
  [`Developers can request a state upgrade from development to production`]: `Los desarrolladores pueden solicitar una actualización de estado de producción a desarrollo`,
  [`Development`]: `Desarrollo`,
  [`Directive`]: `Directiva`,
  [`Director Endpoint`]: `Punto final director`,
  [`Director TLS client profile`]: `Perfil de cliente TLS de director`,
  [`Disable`]: `Inhabilitar`,
  [`Disable Activity log`]: `Inhabilitar el registro de actividad`,
  [`Disable Self-Service Onboarding`]: `Inhabilitar la incorporación de autoservicio`,
  [`Disable spaces`]: `Inhabilitar espacios`,
  [`Disable buffering`]: `Inhabilitar almacenamiento intermedio`,
  [`Disable Self-Service Onboarding Approval`]: `Inhabilitar aprobación de incorporación de autoservicio`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Inhabilitar la alteración del tiempo de espera de invitación de organizaciones de consumidores.`,
  [`Disabled`]: `Desactivado`,
  [`disabled`]: `desactivado`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Al inhabilitar la incorporación de autoservicio todos los desarrolladores de aplicaciones deberán recibir una invitación del proveedor de API, de un administrador o de un propietario de organización de consumidores existente.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `La inhabilitación de la aprobación de incorporación de autoservicio aprobará automáticamente todas las solicitudes de incorporación.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `La inhabilitación no establecerá el tiempo de espera de invitación de organizaciones de consumidores en el tiempo de espera de catálogo.`,
  [`Discover targets`]: `Descubrir destinos`,
  [`Display table checkboxes`]: `Visualizar recuadros de selección de tabla`,
  [`Display vanity endpoint`]: `Visualizar punto final de vanidad`,
  [`Do not publish API (continue editting)`]: `No publicar API (continuar editando)`,
  [`Do not require applications or users to authenticate`]: `No requerir la autenticación de aplicaciones o usuarios`,
  [`Do not require authentication`]: `No requerir autenticación`,
  [`Do not share the resource with provider organizations`]: `No compartir el recurso con organizaciones de proveedores`,
  [`Do not share the API gateway with provider organizations`]: `No compartir la pasarela de API con organizaciones de proveedores`,
  [`Do not share the service with provider organizations`]: `No compartir el servicio con organizaciones de proveedores`,
  [`Do not use Dynamic DNS`]: `No utilizar DNS dinámico`,
  [`Do you want to remove these references?`]: `¿Desea eliminar estas referencias?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Documentos de Docker`,
  [`Docs`]: `Documentos`,
  [`Document Editor`]: `Editor de documentos`,
  [`Documentation and tutorials with step-by-step instructions`]: `Documentación y guías de aprendizaje con instrucciones paso a paso`,
  [`Domain name`]: `Nombre de dominio`,
  [`Domain Name`]: `Nombre de dominio`,
  [`Domains handled by gateway via SNI`]: `Dominios gestionados por pasarela a través de SNI`,
  [`Don't have an account?`]: `¿No tiene una cuenta?`,
  [`Done`]: `Finalizado`,
  [`Download`]: `Descargar`,
  [`Download Gateway`]: `Descargar Gateway`,
  [`Download and install`]: `Descargue e instale`,
  [`Download and set up DataPower API Gateway`]: `Descargar y configurar DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Descargue y configure DataPower API Gateway para utilizarlo localmente o en cualquier nube.`,
  [`Download for Linux`]: `Descargar para Linux`,
  [`Download for Mac`]: `Descargar para Mac`,
  [`Download for Windows`]: `Descargar para Windows`,
  [`Download gateway`]: `Descargar pasarela`,
  [`Download schema`]: `Descargar esquema`,
  [`Download Toolkit`]: `Descargar kit de herramientas`,
  [`Download toolkit`]: `Descargar kit de herramientas`,
  [`Download toolkit and credentials for various platforms`]: `Descargar kit de herramientas y credenciales para varias plataformas`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `No se han encontrado credenciales descargadas para esta conexión de tipo nube. El inicio de sesión utilizará credenciales predeterminadas.`,
  [`Dutch`]: `Holandés`,
  [`Draft {type} creation failed`]: `No se ha podido crear el borrador {type}`,
  [`Drafts`]: `Borradores`,
  [`Drag and drop files here or click to upload`]: `Arrastre y suelte archivos aquí o pulse para cargar`,
  [`Drag & Drop`]: `Arrastrar y soltar`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `DNS dinámico`,
  [`Dynamic OAuth configuration from a URL`]: `Configuración de OAuth dinámica de un URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Configuración de OAuth dinámica a partir de una serie literal`,
  [`Dynamic group`]: `Grupo dinámico`,
  [`Dynamic table entries`]: `Entradas de tabla dinámica`,
  [`ENABLE`]: `HABILITAR`,
  [`Encoded`]: `Codificado`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Cada Espacio lo utiliza un equipo diferente de desarrollo de proveedores de API y tiene su propio conjunto de prestaciones de gestión relacionadas específicamente con las API que el equipo asociado publica en ese espacio, lo que permite a cada equipo gestionar las API correspondientes de forma independiente. Cuando despliega o publica una API en un catálogo con espacios habilitados, puede especificar el espacio dentro del catálogo en el que desea desplegar o publicar.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Cada organización de proveedores posee un conjunto de API, productos, planes y catálogos.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Cree, proteja, gestione, comparta y analice fácilmente APIs ubicadas en la nube o localmente.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Cada organización de proveedores posee un conjunto de API, productos, planes y catálogos. [Más información]({link})`,
  [`Edit`]: `Editar`,
  [`Edit access token TTL`]: `Editar TTL de señal de acceso`,
  [`Edit admin organization invitation timeout`]: `Editar tiempo de espera de invitación de la organización de administración`,
  [`Edit API`]: `Editar API`,
  [`Edit API Connect UI`]: `Editar IU de API Connect`,
  [`Edit API endpoint for unenforced APIs`]: `Editar punto final de API para las API no vigentes`,
  [`Edit API gateway service`]: `Editar servicio de pasarela de API`,
  [`Edit API Path`]: `Editar vía de acceso de API`,
  [`Edit API security definition`]: `Editar definición de seguridad de API`,
  [`Edit API user registries`]: `Editar registros de usuarios de API`,
  [`Edit analytics service`]: `Editar servicio de análisis`,
  [`Edit application`]: `Editar aplicación`,
  [`Edit Assembly`]: `Editar ensamblaje`,
  [`Edit authentication URL user registry`]: `Editar registro de usuarios de URL de autenticación`,
  [`Edit availability zone`]: `Editar zona de disponibilidad`,
  [`Edit Availablity Zone`]: `Editar zona de disponibilidad`,
  [`Edit and apply constraints to your selected items.`]: `Edite y aplique restricciones a los elementos seleccionados.`,
  [`Edit billing integration`]: `Editar integración de facturación`,
  [`Edit Catalog Details`]: `Editar detalles de catálogo`,
  [`Edit cloud setting invitation timeout`]: `Editar tiempo de espera de invitación de valor de nube`,
  [`Edit constraints`]: `Editar restricciones`,
  [`Edit consumer organization`]: `Editar organización de consumidores`,
  [`Edit DNS Scheme`]: `Editar esquema de DNS`,
  [`Edit DataPower API gateway service`]: `Editar servicio de pasarela de API DataPower`,
  [`Edit DataPower gateway service`]: `Editar servicio de pasarela DataPower`,
  [`Edit definition`]: `Editar definición`,
  [`Edit dynamic DNS`]: `Editar DNS dinámico`,
  [`Edit email server`]: `Editar servidor de correo electrónico`,
  [`Edit extensions`]: `Editar extensiones`,
  [`Edit Gateway Service`]: `Editar servicio de pasarela`,
  [`Edit gateway`]: `Editar pasarela`,
  [`Edit gateway extension`]: `Editar extensión de pasarela`,
  [`Edit Gateways`]: `Editar pasarelas`,
  [`Edit IBM-managed gateway`]: `Editar pasarela gestionada por IBM`,
  [`Edit Invitation Timeout`]: `Editar tiempo de espera de invitación`,
  [`Edit gateways`]: `Editar pasarelas`,
  [`Edit invitation timeout`]: `Editar tiempo de espera de invitación`,
  [`Edit JSON`]: `Editar JSON`,
  [`Edit keystore`]: `Editar almacén de claves`,
  [`Edit LDAP user registry`]: `Editar registro de usuarios de LDAP`,
  [`Edit LTPA Token`]: `Editar señal LTPA`,
  [`Edit lifecycle approvals`]: `Editar aprobaciones de ciclo de vida`,
  [`Edit local user registry`]: `Editar registro de usuarios local`,
  [`Edit native OAuth provider`]: `Editar proveedor de OAuth nativo`,
  [`Edit New Billing System`]: `Editar nuevo sistema de facturación`,
  [`Edit Notification Server`]: `Editar servidor de notificación`,
  [`Edit Notification template`]: `Editar plantilla de notificación`,
  [`Edit OAuth Provider`]: `Editar proveedor de OAuth`,
  [`Edit OAuth provider details`]: `Editar detalles de proveedor de OAuth`,
  [`Edit OAuth Provider Visibility`]: `Editar visibilidad de proveedor de OAuth`,
  [`Edit OIDC User Registry`]: `Editar registro de usuarios de OIDC`,
  [`Edit Onboarding`]: `Editar incorporación`,
  [`Edit Operation`]: `Editar operación`,
  [`Edit Organization`]: `Editar organización`,
  [`Edit Path`]: `Editar vía de acceso`,
  [`Edit Path Parameters`]: `Editar parámetros de vía de acceso`,
  [`Edit notification template`]: `Editar plantilla de notificación`,
  [`Edit OAuth provider`]: `Editar proveedor de OAuth`,
  [`Edit OAuth provider visibility`]: `Editar visibilidad de proveedor de OAuth`,
  [`Edit OIDC user registry`]: `Editar registro de usuarios OIDC`,
  [`Edit onboarding`]: `Editar incorporación`,
  [`Edit operation`]: `Editar operación`,
  [`Edit organization`]: `Editar organización`,
  [`Edit path`]: `Editar vía de acceso`,
  [`Edit path parameters`]: `Editar parámetros de vía de acceso`,
  [`Edit plan`]: `Editar plan`,
  [`Edit portal`]: `Editar portal`,
  [`Edit portal service`]: `Editar servicio de portal`,
  [`Edit Portal Settings`]: `Editar configuración del portal`,
  [`Edit product`]: `Editar producto`,
  [`Edit product APIs`]: `Editar API de producto`,
  [`Edit Product Subscribability`]: `Editar capacidad de suscripción de producto`,
  [`Edit Product Visibility`]: `Editar visibilidad de producto`,
  [`Edit Property`]: `Editar propiedad`,
  [`Edit Provider Organization`]: `Editar organización de proveedores`,
  [`Edit Refresh Token TTL`]: `Editar el tiempo de vida de la señal de renovación`,
  [`Edit remote gateway`]: `Editar pasarela remota`,
  [`Edit Reset Password TTL`]: `Editar tiempo de vida de restablecimiento de contraseña`,
  [`Edit Role`]: `Editar rol`,
  [`Edit Role Default for Provider Organizations`]: `Editar valor predeterminado de rol para organizaciones de proveedores`,
  [`Edit Role for Provider Organizations`]: `Editar rol para organizaciones de proveedores`,
  [`Edit property`]: `Editar propiedad`,
  [`Edit provider organization`]: `Editar organización de proveedores`,
  [`Edit refresh token TTL`]: `Editar tiempo de vida de señal de renovación`,
  [`Edit reset password TTL`]: `Editar tiempo de vida de restablecimiento de contraseña`,
  [`Edit role`]: `Editar rol`,
  [`Edit role default for Provider Organizations`]: `Editar valor predeterminado de rol para organizaciones de proveedores`,
  [`Edit role for Provider Organizations`]: `Editar rol para organizaciones de proveedores`,
  [`Edit Roles`]: `Editar roles`,
  [`Edit Schema in JSON/XML`]: `Editar esquema en JSON/XML`,
  [`Edit security definition`]: `Editar definición de seguridad`,
  [`Edit sender`]: `Editar remitente`,
  [`Edit sender & email server`]: `Editar remitente y servidor de correo electrónico`,
  [`Edit sender Info`]: `Editar información de remitente`,
  [`Edit service visibility`]: `Editar visibilidad del servicio`,
  [`Edit show/hide settings`]: `Editar mostrar/ocultar valores`,
  [`Edit space details`]: `Editar detalles de espacio`,
  [`Edit TLS client profile`]: `Editar perfil de cliente TLS`,
  [`Edit TLS client profile visibility`]: `Editar visibilidad de perfil de cliente TLS`,
  [`Edit TLS server profile`]: `Editar perfil de servidor TLS`,
  [`Edit third party OAuth provider`]: `Editar proveedor de OAuth de terceros`,
  [`Edit truststore`]: `Editar almacén de confianza`,
  [`Edit unenforced API`]: `Editar API no vigente`,
  [`Edit user registries`]: `Editar registros de usuarios`,
  [`Edit user registry`]: `Editar registro de usuarios`,
  [`Edit user registry visibility`]: `Editar visibilidad de registro de usuarios`,
  [`Edit user registry for API Manager`]: `Editar registro de usuarios para gestor de API`,
  [`Edit user registry for Cloud Manager`]: `Editar registro de usuarios para gestor de nube`,
  [`Edit vanity API endpoints`]: `Editar puntos finales de API de vanidad`,
  [`Edit visibility`]: `Editar visibilidad`,
  [`Edit XML`]: `Editar XML`,
  [`Edit app lifecycle approved`]: `Editar ciclo de vida de aplicación aprobado`,
  [`Edit app lifecycle cancelled`]: `Editar ciclo de vida de aplicación cancelado`,
  [`Edit app lifecycle denied`]: `Editar ciclo de vida de aplicación denegado`,
  [`Edit app lifecycle pending`]: `Editar ciclo de vida de aplicación pendiente`,
  [`Edit app lifecycle request`]: `Editar solicitud ciclo de vida de aplicación`,
  [`Edit app reinstated`]: `Editar aplicación restablecida`,
  [`Edit app suspended`]: `Editar aplicación suspendida`,
  [`Edit audit setting`]: `Editar valor de auditoría`,
  [`Edit definitions`]: `Editar definiciones`,
  [`Enable external group mapping`]: `Habilitar correlación de grupos externos`,
  [`Edit extra constraints for the GraphQL schema`]: `Editar restricciones adicionales para el esquema GraphQL`,
  [`Edit GraphQL schema|title`]: `Editar esquema GraphQL`,
  [`Edit inline schema`]: `Editar esquema en línea`,
  [`Edit inputs`]: `Editar datos de entrada`,
  [`Edit invitation`]: `Editar invitación`,
  [`Edit mail server test connection`]: `Editar conexión de prueba de servidor de correo`,
  [`Edit member invitation`]: `Editar invitación de miembro`,
  [`Edit notification templates`]: `Editar plantillas de notificación`,
  [`Edit notification templates:`]: `Editar plantillas de notificación:`,
  [`Edit operation list`]: `Editar lista de operación`,
  [`Edit or upload JSON schema`]: `Editar o cargar esquema JSON`,
  [`Edit outputs`]: `Editar datos de salida`,
  [`Edit password changed`]: `Editar contraseña cambiada`,
  [`Edit password reset`]: `Editar contraseña restablecida`,
  [`Edit plan APIs`]: `Editar APIs de plan`,
  [`Edit schema`]: `Editar esquema`,
  [`Edit schema|button`]: `Editar esquema`,
  [`Edit schema|title`]: `Editar esquema`,
  [`Edit settings for roles, notifications and more.`]: `Editar valores para roles, notificaciones y más.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Editar valores para registros de usuarios, roles, puntos finales, etc`,
  [`Edit sign up`]: `Editar registro`,
  [`Edit task product lifecycle approved`]: `Editar ciclo de vida de producto de tarea aprobado`,
  [`Edit task product lifecycle cancelled`]: `Editar ciclo de vida de producto de tarea cancelado`,
  [`Edit task product lifecycle denied`]: `Editar ciclo de vida de producto de tarea denegado`,
  [`Edit task product lifecycle pending`]: `Editar ciclo de vida de producto de tarea pendiente`,
  [`Edit task product lifecycle request`]: `Editar solicitud ciclo de vida de producto de tarea`,
  [`Edit task subscription approved`]: `Editar suscripción de tarea aprobada`,
  [`Edit task subscription cancelled`]: `Editar suscripción de tarea cancelada`,
  [`Edit task subscription denied`]: `Editar suscripción de tarea denegada`,
  [`Edit task subscription pending`]: `Editar suscripción de tarea pendiente`,
  [`Edit task subscription request`]: `Editar solicitud de suscripción de tarea`,
  [`Edit template`]: `Editar plantilla`,
  [`Edit the TLS client profile.`]: `Edite el perfil de cliente TLS.`,
  [`Edit the TLS Server profiles`]: `Edite los perfiles de servidor TLS`,
  [`Edit the keystore title.`]: `Edite el título del almacén de confianza.`,
  [`Edit the organization summary details as required`]: `Edite los detalles de resumen de organización según sea necesario`,
  [`Edit the organization title`]: `Edite el titulo de la organización`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Edite los parámetros para configurar un servidor de correo electrónico. Los campos son obligatorios a menos que estén designados como opcionales.`,
  [`Edit signed token`]: `Editar señal firmada`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Edite el texto de la plantilla de correo electrónico. Las variables se encuentran entre corchetes y no pueden editarse.`,
  [`Edit the truststore.`]: `Edite el almacén de confianza.`,
  [`Edit the visibility for each resource.`]: `Edite la visibilidad para cada recurso.`,
  [`Edit user details`]: `Editar detalles de usuario`,
  [`Edit {notificationTemplate}`]: `Editar {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `Editar, ensamblar, proteger y probar API`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `Editar, ensamblar, proteger y probar API. Empaquetar API mediante productos para su publicación para consumidores.`,
  [`Edit, upload, or infer XML schema`]: `Editar, cargar o inferir esquema XML`,
  [`Editors`]: `Editores`,
  [`Edit|permission`]: `Editar`,
  [`Edit API key timeout`]: `Editar tiempo de espera de clave de API`,
  [`Edit Base64 encoding for temporary tokens`]: `Editar codificación en Base64 para señales temporales`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Es necesario proporcionar o bien un "Objeto criptográfico de firma de señal de ID" o bien una "Clave de firma de señal de ID".`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Es necesario proporcionar o bien un "Objeto criptográfico de verificación de JWT" o bien un "JWK de verificación de JWT".`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Seleccione la opción para utilizar el punto final definido en la API o proporcione un punto final de validador predeterminado.`,
  [`Element name for JSON array elements`]: `Nombre de elemento de elementos de matriz JSON`,
  [`Email`]: `Correo electrónico`,
  [`Email address`]: `Dirección de correo electrónico`,
  [`Email Content`]: `Contenido de correo electrónico`,
  [`Email endpoint`]: `Punto final de correo electrónico`,
  [`Email required`]: `Se requiere una dirección de correo electrónico`,
  [`Email sender`]: `Remitente de correo electrónico`,
  [`Email server`]: `Servidor de correo electrónico`,
  [`Email server configuration`]: `Configuración de servidor de correo electrónico`,
  [`Email servers`]: `Servidores de correo electrónico`,
  [`Empty case`]: `Caso vacío`,
  [`Empty catch`]: `Captura vacía`,
  [`Empty JSON array handling`]: `Manejo de matriz JSON vacía`,
  [`Empty XML element handling`]: `Tratamiento de elemento XML vacío`,
  [`Enable`]: `Habilitar`,
  [`Enable API consumers to invite collaborators and assign roles`]: `Permitir a los consumidores de API invitar a colaboradores y asignar roles`,
  [`Enable application lifecycle`]: `Habilitar ciclo de vida de aplicación`,
  [`Enable billing`]: `Habilitar facturación`,
  [`Enable gateway services`]: `Habilita servicios de pasarela`,
  [`Enable gateway services and policies`]: `Habilitar servicios y políticas de pasarela`,
  [`Enable GraphiQL`]: `Habilitar GraphiQL`,
  [`Enable GraphiQL editor`]: `Habilitar editor GraphiQL`,
  [`Enable GraphiQL Editor on browser`]: `Habilitar el editor de GraphiQL en el navegador`,
  [`Enable JSON post processing`]: `Habilitar procesamiento posterior de JSON`,
  [`Enable lifecycle approvals`]: `Habilitar aprobaciones de ciclo de vida`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Se habilita la extensión NONCE para evitar que las solicitudes comprometidas se vuelvan a utilizar (reproducir)`,
  [`Enable OAuth providers`]: `Habilitar proveedores de OAuth`,
  [`Enable OIDC`]: `Habilitar OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Habilite la plantilla de OpenID Connect para generar señales de ID.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Se habilita la extensión PKCE para permitir que se reduzca la amenaza de los clientes públicos a que se les intercepte el código de autorización`,
  [`Enable production mode`]: `Habilitar modalidad de producción`,
  [`Enable Proxy`]: `Habilitar proxy`,
  [`Enable Self-Service Onboarding`]: `Habilitar la incorporación de autoservicio`,
  [`Enable spaces`]: `Habilitar espacios`,
  [`Enable token management`]: `Habilitar gestión de señales`,
  [`Enable buffering`]: `Habilitar almacenamiento intermedio`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `Habilitar a los consumidores de API para crear cuentas de portal de desarrollo sin necesidad de una invitación.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `Habilitar el control de acceso compartido de recursos de origen cruzado (CORS) para la API.`,
  [`Enable debug response headers`]: `Habilitar cabeceras de respuesta de depuración`,
  [`Enable proof key for code exchange`]: `Habilitar clave de prueba para intercambio de código`,
  [`Enable publishing of this product`]: `Habilitar la publicación de este producto`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Se permite la redirección del código de autorización de terceros a través del punto final de OIDC de Portal`,
  [`Enable security`]: `Habilitar seguridad`,
  [`Enable Self-Service Onboarding Approval`]: `Habilitar aprobación de incorporación de autoservicio`,
  [`Enable the following gateway services and policies for this catalog`]: `Habilite los siguientes servicios y políticas de pasarela para este catálogo`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Habilitar usuarios para configurar un suceso de auditoría. Un suceso de auditoría es un resumen detallado de quién ha realizado que suceso y cuándo.`,
  [`Enable/disable custom notification templates`]: `Habilitar/inhabilitar plantillas de notificación personalizadas`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Habilitar o inhabilitar la codificación en Base64 para las invitaciones y las señales de restablecimiento de contraseña`,
  [`Enable overriding consumer organizations invitation timeout`]: `Habilitar la anulación del tiempo de espera de invitación de organizaciones de consumidores`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `Puede habilitar o inhabilitar la codificación de Base64 para las señales temporales. Las señales temporales son invitaciones y señales de restablecimiento de contraseña. Cuando la codificación Base64 está habilitada, la señal temporal se codifica en formato Base64. Cuando la codificación Base64 está inhabilitada, la señal temporal permanece en el formato de señal web JSON (JWT) y significa que la longitud del URL que recibe el usuario se reduce.`,
  [`Enabled`]: `Habilitado`,
  [`enabled`]: `activado`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `Habilitar el ciclo de vida de aplicación permitirá que las aplicaciones estén en estado de desarrollo o producción y se puedan direccionar a diferentes pasarelas y puntos finales.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `La habilitación de la aprobación de incorporación de autoservicio requerirá que el proveedor de la API o un administrador apruebe todas las solicitudes de incorporación.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Al habilitar la incorporación de autoservicio se permite que un desarrollador de aplicaciones inicie la sesión sin una invitación del proveedor de API, de un administrador o de un propietario de organización de consumidores existente.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `La habilitación establecerá el tiempo de espera de invitación de todas las organizaciones de consumidores en el tiempo de espera de invitación de catálogo.`,
  [`Encode Query params`]: `Codificar parámetros de consulta`,
  [`Encrypt`]: `Cifrar`,
  [`Encrypt Crypto Object`]: `Objeto criptográfico de cifrado`,
  [`Encrypt JWK variable name`]: `Nombre de la variable JWK de cifrado`,
  [`Encrypt a buffer using the specified certificate`]: `Cifrar un almacenamiento intermedio utilizando el certificado especificado`,
  [`Encryption Algorithm`]: `Algoritmo de cifrado`,
  [`Endpoint`]: `Punto final`,
  [`Endpoint URLs`]: `URL de punto final`,
  [`Endpoint behavior`]: `Comportamiento del punto final`,
  [`Endpoint Behavior`]: `Comportamiento del punto final`,
  [`Endpoint for unenforced APIs`]: `Punto final para las API no vigentes`,
  [`Endpoints`]: `Puntos finales`,
  [`Enforce Required Params`]: `Imponer parámetros obligatorios`,
  [`Enforce the API by using API Connect Gateway.`]: `Aplicar la API mediante la pasarela de API Connect.`,
  [`Enforced`]: `Aplicado`,
  [`English`]: `Inglés`,
  [`Enter Summary`]: `Especificar resumen`,
  [`Enter URL`]: `Especificar URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Especifique el nombre del registro de usuarios. Si está vacío, se utiliza de forma predeterminada el registro de usuarios local del Gestor de API.`,
  [`Enter a catalog name`]: `Especifique un nombre de catálogo`,
  [`Enter a Display Name`]: `Especifique un nombre de visualización`,
  [`Enter a space name`]: `Especifique un nombre de espacio`,
  [`Enter a brief description`]: `Especifique una breve descripción`,
  [`Enter a display name`]: `Especifique un nombre de visualización`,
  [`Enter a temporary sign in password`]: `Especifique un signo temporal en la contraseña`,
  [`Enter an Organization Name`]: `Especifique un nombre de organización`,
  [`Enter an Organization Title`]: `Especifique un título de organización`,
  [`Enter catalog title`]: `Especifique el título del catálogo`,
  [`Enter details of the consumer organization`]: `Especifique los detalles de la organización de consumidores`,
  [`Enter details of the product`]: `Especifique los detalles del producto`,
  [`Enter details of the provider organization`]: `Especifique los detalles de la organización de proveedores`,
  [`Enter details of this API`]: `Especifique los detalles de esta API`,
  [`Enter email of the new user`]: `Especifique el correo electrónico del usuario nuevo`,
  [`Enter first name of the new user`]: `Especifique el nombre del usuario nuevo`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Introduzca una o varias direcciones de correo electrónico. Utilice comas para separar varias direcciones.`,
  [`Enter organization name`]: `Especificar el nombre de la organización`,
  [`Enter organization title`]: `Especifique el título de la organización`,
  [`Enter password`]: `Especifique la contraseña`,
  [`Enter space title`]: `Especifique el título del espacio`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Especifique el punto final de API para la pasarela proporcionando el nombre de host y el número de puerto. Por ejemplo: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Especifique los detalles de resumen de API`,
  [`Enter the API summary details. [Learn more]({link})`]: `Especifique los detalles de resumen de API. [Más información]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Especifique el URL del servicio de destino para el proxy`,
  [`Enter the catalog summary details`]: `Especifique los detalles de resumen del catálogo`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Especifique los detalles de resumen de catálogo; puede configurar totalmente el catálogo después de crearlo`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Especifique la dirección de correo electrónico del usuario al que desea invitar como miembro de la organización de administración`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Especifique la dirección de correo electrónico del usuario al que desea invitar como miembro del catálogo`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Especifique la dirección de correo electrónico del usuario al que desea invitar como miembro de la organización de proveedores`,
  [`Enter the email address of the user to invite as a member of the space`]: `Especifique la dirección de correo electrónico del usuario al que desea invitar como miembro del espacio`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Especifique la dirección de correo electrónico del usuario al que desea transferir la propiedad.`,
  [`Enter the full path to JSON or YAML file`]: `Especifique la vía de acceso completa al archivo JSON o YAML`,
  [`Enter the full path to WSDL file`]: `Especifique la vía de acceso completa al archivo WSDL`,
  [`Enter the full path to YAML file`]: `Especifique la vía de acceso completa al archivo YAML`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Especifique el nombre de dominio completo para el punto final de ingestión de Analytics que ha definido durante la instalación.`,
  [`Enter the last name of the new user`]: `Especifique el apellido del usuario nuevo`,
  [`Enter the owner's email address to send the invitation`]: `Especifique la dirección de correo electrónico del propietario al que desea enviar la invitación`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Especifique los parámetros para configurar el servicio de pasarela. Los campos son obligatorios a menos que estén designados como opcionales. El punto final es el punto final del servicio de pasarela de API en DataPower. Base de punto final de API es el punto final de pasarela de API para las llamadas de API entrantes. Para SNI, especifique * para permitir todos los hosts (necesario), y también especifique nombres de host para permitir hosts específicos. Elija el perfil TLS que debe dar soporte a cada host.`,
  [`Enter the password for the certificate file if required.`]: `Especifique la contraseña para el archivo de certificado si es necesario.`,
  [`Enter the provider organization summary details`]: `Especifique los detalles de resumen de la organización de proveedores`,
  [`Enter the schema definitiom summary details`]: `Especifique los detalles de resumen de definición de esquema`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Especifique los detalles de resumen de espacio; puede configurar totalmente el espacio después de crearlo`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Especifique los detalles de resumen de espacio; puede configurar totalmente el espacio después de crearlo`,
  [`Enter the summary details for the new product`]: `Especifique los detalles de resumen del producto nuevo`,
  [`Enter the summary details for this role`]: `Especifique los detalles de resumen de este rol`,
  [`Enter title of the organization`]: `Especifique el título de la organización`,
  [`Enter user email`]: `Especifique el correo electrónico del usuario`,
  [`Enter username of the existing user`]: `Especifique el nombre de usuario del usuario existente`,
  [`Enter username of the new user`]: `Especifique el nombre de usuario del usuario nuevo`,
  [`Error`]: `Error`,
  [`Error ID`]: `ID de error`,
  [`Error:`]: `Error:`,
  [`Copy error ID to Clipboard`]: `Copiar el ID de error en el portapapeles`,
  [`Error Console`]: `Consola de errores`,
  [`Error connecting`]: `Error al conectar`,
  [`Error connecting to GraphQL server`]: `Error al conectar con el servidor de GraphQL`,
  [`Error getting user info during login. Please login with different user.`]: `Error al obtener información de usuario durante el inicio de sesión. Inicie sesión con otro usuario.`,
  [`Error content`]: `Contenido de error`,
  [`Error message`]: `Mensaje de error`,
  [`Error name`]: `Nombre de error`,
  [`Error status code`]: `Código de estado de error`,
  [`Error status code is invalid`]: `Código de estado de error no válido`,
  [`Error status reason`]: `Razón de estado de error`,
  [`Errors`]: `Errores`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Los errores vienen normalmente de información y descripciones equivocadas. Asegúrese de proporcionar la información de proveedor y de cliente correcta antes de la prueba.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Error al intentar obtener la API del URL {url}. Compruebe el URL, el nombre de usuario y la contraseña. Mensaje de error: {message}`,
  [`Event`]: `Suceso`,
  [`Event gateway management client TLS client profile`]: `Perfil de cliente TLS del cliente de gestión de pasarela de sucesos`,
  [`Event load`]: `Carga de sucesos`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Cada organización de consumidores tiene un propietario. El propietario tiene permisos de administración completos y puede gestionar suscripciones y aplicaciones.`,
  [`Exchange authorization code for tokens`]: `Intercambiar código de autorización para señales`,
  [`Exchange code for token`]: `Intercambiar código para señal`,
  [`Execute`]: `Ejecución`,
  [`Execute migration target`]: `Ejecutar destino de migración`,
  [`Executes any catalog-level post-invoke handlers`]: `Ejecuta controladores de postinvocación de nivel de catálogo`,
  [`Executes any catalog-level pre-invoke handlers`]: `Ejecuta controladores de preinvocación de nivel de catálogo`,
  [`Existing`]: `Existente`,
  [`Existing {docType}`]: `{docType} existente`,
  [`Existing API Connect user`]: `Usuario de API Connect existente`,
  [`Existing OpenAPI`]: `OpenAPI existente`,
  [`Existing product`]: `Producto existente`,
  [`Existing SOAP API has been validated`]: `La API SOAP existente se ha validado`,
  [`Existing products in the catalog will be moved to a default space.`]: `Los productos existentes en el catálogo se trasladarán a un espacio predeterminado.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Los usuarios existentes son miembros actuales. También puede activar un miembro nuevo de otro registro de usuarios.`,
  [`Expires On`]: `Caduca el`,
  [`Expiry`]: `Caducidad`,
  [`Explorer`]: `Explorador`,
  [`Expose`]: `Exponer`,
  [`Extend the schema to specify the cost of types and fields`]: `Ampliar el esquema para especificar el coste de tipos y campos`,
  [`Extended`]: `Ampliado`,
  [`Extension Type:`]: `Tipo de extensión:`,
  [`Extensions`]: `Extensiones`,
  [`Extension has been uploaded.`]: `La extensión se ha cargado.`,
  [`External`]: `Externo`,
  [`External Doc Description`]: `Descripción de documento externo`,
  [`External doc description`]: `Descripción de documento externo`,
  [`External Documentation URL`]: `URL de documentación externa`,
  [`External documentation URL`]: `URL de documentación externa`,
  [`External Role mapping`]: `Correlación de roles externos`,
  [`External URL`]: `URL externo`,
  [`Extract Identity Settings`]: `Extraer valores de identidad`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `ID de factor`,
  [`Fail`]: `Suspendido`,
  [`Failed to change account password.`]: `No se ha podido cambiar la contraseña de cuenta.`,
  [`Failed to create analytics service {title}.`]: `No se ha podido crear el servicio de análisis {title}.`,
  [`Failed to create analytics insights service {title}.`]: `No se ha podido crear el servicio de Analytics Insights {title}.`,
  [`Analytics insights service {title} has been updated.`]: `El servicio de Analytics Insights {title} se ha actualizado.`,
  [`About the analytics insights service`]: `Acerca del servicio de Analytics Insights`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `El servicio de Analytics Insights recopila sucesos de API del servicio de pasarela. Cada servicio de pasarela puede tener un servicio de Analytics Insights asociado.`,
  [`Advanced analytics insights configuration`]: `Configuración avanzada de Analytics Insights`,
  [`Analytics insights service {title} has been created.`]: `Se ha creado el servicio de Analytics Insights {title}.`,
  [`Analytics Insights details`]: `Detalles de Analytics Insights`,
  [`Management endpoint on the analytics insights service`]: `Punto final de gestión en el servicio de Analytics Insights`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Especifique el nombre de dominio completo para el punto final del director de Analytics Insights que ha definido durante la instalación.`,
  [`TYPE`]: `TYPE`,
  [`Failed to create email server`]: `No se ha podido crear el servidor de correo electrónico`,
  [`Failed to create gateway service`]: `No se ha podido crear el servicio de pasarela`,
  [`Failed to create gateway service {name}.`]: `No se ha podido crear el servicio de pasarela {name}.`,
  [`Failed to create user registry {title}.`]: `No se ha podido crear el registro de usuarios {title}.`,
  [`Failed to delete a group`]: `No se ha podido crear un grupo.`,
  [`Failed to get TLS client profiles`]: `No se han podido obtener perfiles de cliente TLS`,
  [`Failed to get catalogs`]: `No se han podido obtener catálogos`,
  [`Failed to get configured OAuth providers`]: `No se han podido obtener proveedores de OAuth configurados`,
  [`Failed to get policies of gateways`]: `No se han podido obtener políticas de pasarelas`,
  [`Failed to get user registries`]: `No se han podido obtener registros de usuarios`,
  [`Failed to introspect schema from server:`]: `No se ha podido realizar la introspección del esquema desde el servidor:`,
  [`Failed to make API online`]: `No se ha podido poner la API en línea`,
  [`Failed to register catalog member {user}.`]: `No se ha podido registrar el miembro de catálogo {user}.`,
  [`Failed to remove analytics service: {arg}`]: `No se ha podido eliminar el servicio de análisis: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `No se ha podido eliminar la zona de disponibilidad: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `No se ha podido eliminar el servicio de pasarela: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `No se ha podido eliminar el servidor de correo: {arg}`,
  [`Failed to remove portal service: {arg}`]: `No se ha podido eliminar el servicio de portal: {arg}`,
  [`Failed to create {name}.`]: `No se ha podido crear {name}.`,
  [`Failed to sign out! Please check the console for details.`]: `No se ha podido cerrar la sesión. Compruebe la consola para ver los detalles.`,
  [`Failed to update {name}.`]: `No se ha podido actualizar {name}.`,
  [`Failed to update OAuth provider`]: `No se ha podido actualizar el proveedor de OAuth`,
  [`Failed to update account.`]: `No se ha podido actualizar la cuenta.`,
  [`Failed to update analytics service {title}.`]: `No se ha podido actualizar el servicio de análisis {title}.`,
  [`Failed to update gateway service {title}.`]: `No se ha podido actualizar el servicio de pasarela {title}.`,
  [`Failed to update task`]: `Error al actualizar tarea`,
  [`Failed to create app {title}`]: `No se ha podido crear la aplicación {title}`,
  [`Feature flags`]: `Distintivos de característica`,
  [`Fetch schema`]: `Captar esquema`,
  [`Fetched endpoints`]: `Se han captado puntos finales`,
  [`Field`]: `Campo`,
  [`Field label`]: `Etiqueta de campo`,
  [`Field Mapping`]: `Correlación de campos`,
  [`Field name`]: `Nombre de campo`,
  [`Field weight`]: `Peso de campo`,
  [`File Name`]: `Nombre de archivo`,
  [`File Preview`]: `Vista previa de archivo`,
  [`File contains validation errors`]: `El archivo contiene errores de validación`,
  [`File is not a valid OpenAPI definition`]: `El archivo no es una definición de OpenAPI válida`,
  [`File is not a valid extension format.`]: `El archivo no está en un formato de extensión válido.`,
  [`File is not a valid {fileType} format.`]: `El archivo no está en un formato {fileType} válido.`,
  [`File name`]: `Nombre de archivo`,
  [`File upload`]: `Subida de archivos`,
  [`File uploaded is not a valid file`]: `El archivo cargado no es válido`,
  [`File uploaded successfully`]: `El archivo se ha cargado satisfactoriamente`,
  [`File was updated and validated!`]: `¡Se ha actualizado y validado el archivo!`,
  [`File was uploaded and validated!`]: `¡Se ha cargado y validado el archivo!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `El archivo {draftFilePath} existe. Intente cambiar la combinación nombre/versión del borrador.`,
  [`Fill out the details of this service`]: `Rellene los detalles de este servicio`,
  [`Filter`]: `Filtrar`,
  [`Filter by operation`]: `Filtrar por operación`,
  [`Filter policies`]: `Filtrar políticas`,
  [`Final Action`]: `Acción final`,
  [`Find existing user`]: `Buscar el usuario existente`,
  [`Find expert answers in the API Connect community forum`]: `Buscar respuestas de expertos en el foro de la comunidad de API Connect`,
  [`Find expert answers in the API Connect community forum.`]: `Buscar respuestas de expertos en el foro de la comunidad de API Connect.`,
  [`Find schema`]: `Buscar esquema`,
  [`Finger Print`]: `Huella dactilar`,
  [`Fingerprint SHA-1`]: `Huella dactilar SHA-1`,
  [`Fingerprint SHA-256`]: `Huella dactilar SHA-256`,
  [`Finish`]: `Finalizar`,
  [`First name`]: `Nombre`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `En primer lugar, especifique el URL del punto final que la pasarela utiliza para el tráfico de API. El valor debe ser un nombre de dominio público completo con vías de acceso adicionales que sean específicas de las llamadas de la API, por ejemplo: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `En primer lugar, especifique el URL del punto final de gestión de pasarela. Para las pasarelas que se ejecutan en entornos de Kubernetes el valor debe coincidir con el punto final especificado a través del mandato de configuración de la pasarela set gwy apic-gw-service. Para dispositivos de pasarela, el valor debe tener el formato http://<ip-address-for-gateway>:3000. Cuando trabaje con un clúster de pasarela, especifique la dirección:puerto del equilibrador de carga.`,
  [`Fit to screen`]: `Ajustar a pantalla`,
  [`Flow`]: `Flujo`,
  [`Follow redirects`]: `Seguir redirecciones`,
  [`Found In`]: `Encontrado en`,
  [`Fonts contained in the following file are available under the`]: `Los fonts contenidos en el archivo siguiente están disponibles bajo el`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `Para las definiciones WSDL, cargue un archivo zip que contenga el archivo WSDL y la definición de API (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Para cada plan del producto original, seleccione el plan del producto de sustitución al que desea migrar las suscripciones`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Para cada plan del producto de origen, seleccione el plan del producto de destino al que desea migrar las suscripciones`,
  [`For guidance on the available command set, access the CLI's help`]: `Para obtener ayuda sobre el conjunto de mandatos disponible, acceda a la ayuda de la CLI`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Para sitios que utilizan proveedores de OAuth nativos, puede especificar el secreto compartido que utilizarán todas las llamadas de API.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Para LDAP grabable, seleccione Gestionado por el usuario y añada la correlación de los nombres de atributos del registro LDAP de origen a los valores de atributos LDAP de API Connect. Las propiedades del perfil de usuario predeterminado que requiere API Connect durante el registro de usuario, son nombre de usuario, nombre, apellido y contraseña. Se proporciona una configuración de la correlación - edite la correlación para que se ajuste a sus requisitos`,
  [`Forbidden`]: `Prohibido`,
  [`Forget token`]: `Olvidar señal`,
  [`Forgot password?`]: `¿Ha olvidado la contraseña?`,
  [`Forgot password`]: `Contraseña olvidada`,
  [`Form`]: `Formulario`,
  [`Forum`]: `Foro`,
  [`French`]: `Francés`,
  [`From File`]: `Desde archivo`,
  [`From Sample JSON`]: `Desde JSON de ejemplo`,
  [`From Sample XML`]: `Desde XML de ejemplo`,
  [`From URL`]: `Desde URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Desde servicio GraphQL existente (proxy GraphQL)`,
  [`From existing OpenAPI service`]: `Desde servicio OpenAPI existente`,
  [`From existing WSDL service (REST proxy)`]: `Desde servicio WSDL existente (proxy REST)`,
  [`From existing WSDL service (SOAP proxy)`]: `Desde servicio WSDL existente (proxy SOAP)`,
  [`From target service`]: `Desde servicio de destino`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `Entre las operaciones que se definen en la definición de WSDL para el servicio SOAP, seleccione las que desee utilizar en su API y especifique los métodos y vías de acceso de URL.`,
  [`Gateway Extensions`]: `Extensiones de pasarela`,
  [`Gateway extension`]: `Extensión de pasarela`,
  [`Gateway Services`]: `Servicios de pasarela`,
  [`Gateway Type`]: `Tipo de pasarela`,
  [`Gateway by Name`]: `Pasarela por nombre`,
  [`Gateway services`]: `Servicios de pasarela`,
  [`Gateway type`]: `Tipo de pasarela`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `Licencia de GPL v2`,
  [`GROUP`]: `GRUPO`,
  [`Group name`]: `Nombre de grupo`,
  [`Gateway`]: `Pasarela`,
  [`Gateways`]: `Pasarelas`,
  [`Gateway details`]: `Detalles de pasarela`,
  [`Gateway Details`]: `Detalles de pasarela`,
  [`Gateway Error`]: `Error de pasarela`,
  [`Gateway service details`]: `Detalles del servicio Gateway`,
  [`Gateway management client TLS client profile`]: `Perfil de cliente TLS de cliente de gestión de pasarela`,
  [`Gateway management client keystore`]: `Almacén de claves de cliente de gestión de pasarela`,
  [`Gateway management client truststore`]: `Almacén de confianza de cliente de gestión de pasarela`,
  [`Gateway processing status`]: `Estado de proceso de pasarela`,
  [`Gateway policies (TODO)`]: `Políticas de pasarela (POR HACER)`,
  [`Gateway service`]: `Servicio de pasarela`,
  [`Gateway Service US East (TODO)`]: `Servicio de pasarela Este de EEUU (POR HACER)`,
  [`Gateway Service {arg} has been removed.`]: `Se ha eliminado el servicio de pasarela {arg}.`,
  [`Gateway service configuration`]: `Configuración de servicio de pasarela`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `El host de servicio de pasarela no está disponible. No se habilitará la Prueba de API(Probar) en el Explorador.`,
  [`Gateway service configuration has been updated.`]: `Se ha actualizado la configuración de servicio de pasarela.`,
  [`Gateway service {title} has been created.`]: `Se ha creado el servicio de pasarela {title}.`,
  [`Gateway service {title} has been updated.`]: `Se ha actualizado el servicio de pasarela {title}.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Los servicios de pasarela se gestionan en cada espacio cuando se habilitan los espacios. Vaya a los valores de espacio para gestionar servicios de pasarela.`,
  [`Gateway version`]: `Versión de pasarela`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Pasarelas por página`,
  [`Gather-and-send`]: `Gather-and-send`,
  [`Gather-only`]: `Gather-only`,
  [`General configuration`]: `Configuración general`,
  [`General information about the API.`]: `Información general sobre la API.`,
  [`Generate`]: `Generar`,
  [`Generate an OpenAPI definition for the proxy`]: `Generar una definición de OpenAPI para el proxy`,
  [`Generate JSON Web Token (JWT)`]: `Generar señal web JSON (JWT)`,
  [`Generate JWT`]: `Generar JWT`,
  [`Generate LTPA Token`]: `Generar señal LTPA`,
  [`Generate OAuth Access Tokens`]: `Generar señales de acceso de OAuth`,
  [`Generate OAuth access tokens`]: `Generar señales de acceso de OAuth`,
  [`Generate a default product`]: `Generar un producto predeterminado`,
  [`Generate access token`]: `Generar señal de acceso`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Genere una señal LTPA para que la API pueda autenticarse de forma segura con servidores web de WebSphere Application Server.`,
  [`Generate an object`]: `Generar un objeto`,
  [`Generate authorization code`]: `Generar código de autorización`,
  [`Generate from sample JSON`]: `Generar a partir de ejemplo JSON`,
  [`Generate from sample XML`]: `Generar a partir de ejemplo XML`,
  [`Generate tests automatically to build high quality APIs`]: `Generar automáticamente pruebas para crear unas API de calidad alta`,
  [`Generated OpenAPI 2.0 definition`]: `Se ha generado una definición de OpenAPI 2.0`,
  [`Generated default product for the API`]: `Se ha generado un producto determinado para la API`,
  [`Generated name for use in commands and API calls`]: `Nombre generado para el uso en mandatos y llamadas de API.`,
  [`German`]: `Alemán`,
  [`Get Base DN`]: `Obtener DN base`,
  [`Get Code`]: `Obtener código`,
  [`Get Code Snippet`]: `Obtener fragmento de código`,
  [`Get Support`]: `Obtener soporte`,
  [`Get Token`]: `Obtener señal`,
  [`Get activation link`]: `Obtener enlace de activación`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Empiece registrando un servicio de pasarela. Esto le permitirá probar puntos finales al configurar otros servicios. Una vez registrado un servicio, este aparecerá aquí. [Más información]({link})`,
  [`Get subscription ID`]: `Obtener ID de suscripción`,
  [`Getting Started`]: `Guía de inicio`,
  [`Getting started`]: `Guía de inicio`,
  [`Git information`]: `Información de Git`,
  [`Github`]: `Github`,
  [`Global policies`]: `Políticas globales`,
  [`Go to Spaces`]: `Ir a Espacios`,
  [`Go to the Test tab`]: `Ir a la pestaña Probar`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `Límites de velocidad de GraphQL`,
  [`GraphQL service`]: `Servicio GraphQL`,
  [`GraphQL schema`]: `Esquema GraphQL`,
  [`GraphQL Schema`]: `Esquema GraphQL`,
  [`GraphQL schema has been successfully validated.`]: `El esquema GraphQL se ha validado satisfactoriamente.`,
  [`GraphQL Schema URL`]: `URL de esquema GraphQL`,
  [`GraphQL send type`]: `Tipo de envío de GraphQL`,
  [`GraphQL Websocket Upgrade`]: `Actualización de Websocket de GraphQL`,
  [`Group Based DN`]: `DN basado en grupo`,
  [`Group {title} added`]: `Se ha añadido el grupo {title}`,
  [`Group {title} updated`]: `Se ha actualizado el grupo {title}`,
  [`Group {title} has been deleted`]: `Se ha suprimido el grupo {title}`,
  [`Groups`]: `Grupos`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `NOMBRE DE HOST`,
  [`HOST URL`]: `URL DE HOST`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `Formulario HTML`,
  [`HTML form time limit`]: `Límite de tiempo de formulario HTML`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 necesario`,
  [`HTTP Header`]: `Cabecera HTTP`,
  [`HTTP Method`]: `Método HTTP`,
  [`HTTP Type`]: `Tipo HTTP`,
  [`HTTP Version`]: `Versión HTTP`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Barras de descriptor de contexto`,
  [`Hard limit`]: `Límite estricto`,
  [`Header`]: `Cabecera`,
  [`Header control`]: `Control de cabecera`,
  [`Header name payload`]: `Carga útil de nombre de cabecera`,
  [`Header name token`]: `Señal de nombre de cabecera`,
  [`Headers`]: `Cabeceras`,
  [`Hello`]: `Hola`,
  [`Hello!`]: `¡Hola!`,
  [`Hello [IBM](url)`]: `Hola [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Hola {name}`,
  [`Hello {name}!`]: `¡Hola, {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Hola {name}, [ver perfil de IBM](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nGracias por registrarse para la cuenta del portal de desarrollador {catalogTitle}.\n\nPara completar el registro, utilice este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hola, {firstName},\n\nEl administrador del portal de desarrollador {consumerOrg} ha restablecido la aplicación\n{appName}. Las llamadas de API de esta aplicación ya no estarán bloqueadas por la\npasarela de API.\n\nPuede ver los detalles de la aplicación mediante este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hola, {firstName},\n\nEl administrador del portal de desarrollador {consumerOrg} ha suspendido la aplicación\n{appName}. Las llamadas de API de esta aplicación estarán bloqueadas por la pasarela de API.\n\nPuede ver los detalles de la aplicación mediante este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hola, {firstName},\n\nEl usuario {originator} ha solicitado aprobación para suscribirse al plan {planName} plan\npara la versión {productVersion} del producto de API {productName} en el catálogo\n{catalog}.\n\nPuede abrir la solicitud en la lista de tareas mediante este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hola, {firstName},\n\nEl usuario {originator} ha solicitado aprobación para suscribirse con la aplicación {appName}\nal plan {planName} para la versión {productVersion} del producto de API {productName}\nen el catálogo {catalog}.\n\nPuede abrir la solicitud en la lista de tareas mediante este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nEl usuario {originator} ha solicitado aprobación para {action} la aplicación {appName} en el catálogo \n{catalog}.\n\nPuede abrir la solicitud en la lista de tareas mediante este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hola, {firstName},\n\nEl usuario {originator} ha solicitado aprobación para {action} la versión {version}\ndel producto de API {productName} en el catálogo {catalog}.\n\nPuede abrir la solicitud en la lista de tareas mediante este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hola {{firstName}},\n\nHemos recibido una solicitud para restablecer la contraseña de su cuenta de usuario\n{{username}} en el portal de desarrollador {{catalog}}.\n\nSi no realizó la solicitud, ignore este mensaje. De lo contrario\npuede restablecer la contraseña mediante este enlace:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Hola, {firstName},\n\nHemos recibido una solicitud para restablecer la contraseña de su cuenta de usuario\n{username} en IBM API Connect.\n\nPara restablecer la contraseña, utilice este enlace:\n\n{link}\n\n(Si no realizó la solicitud, puede ignorar este mensaje.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hola, {firstName},\n\nHemos recibido una solicitud para restablecer la contraseña de su cuenta de usuario\n{username} en el portal de desarrollador {catalog}.\n\nSi no realizó la solicitud, ignore este mensaje. De lo contrario\npuede restablecer la contraseña mediante este enlace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hola, {firstName},\n\nLa solicitud para crear una suscripción para la aplicación {appName} en el\ncatálogo {catalog} está en espera de aprobación. Enviaremos otra notificación\ncuando se haya gestionado la solicitud.\n\nPuede comprobar el estado de la aplicación aquí:\n\n{link}\n\nSi en cualquier momento desea cancelar la solicitud, utilice este enlace:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hola, {{firstName}},\n\nLa solicitud para crear una suscripción para la aplicación {{appName}} al plan\n{{planName}} para el producto de API {{productName}} versión\n{{productVersion}} se ha aprobado.\n\n{{#if comments}}\nEl aprobador ha incluido los comentarios siguientes:\n\n"{{comments}}"\n\n{{/if}}\nPuede comprobar el estado de la aplicación aquí:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para crear una suscripción de la aplicación {appName} al plan\n{planName} para el producto de API {productName}, versión\n{productVersion} se ha aprobado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n{comments}\n\n{/if}\nPuede comprobar el estado de la aplicación aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hola, {{firstName}},\n\nLa solicitud para crear una suscripción de la aplicación {{appName}} al plan\n{{planName}} para el producto de API {{productName}} versión\n{{productVersion}} se ha denegado.\n\n{{#if comments}}\nEl aprobador ha incluido los comentarios siguientes:\n\n"{{comments}}"\n\n{{/if}}\nPuede comprobar el estado de la aplicación aquí:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hola, {{firstName}}.\n\nLa solicitud para {{action}} el producto de API {{productName}}:{{version}} en el catálogo\n{{catalog}} se ha aprobado.\n\n{{#if comments}}\nEl aprobador ha incluido los comentarios siguientes:\n\n{{comments}}\n\n{{/if}}\nPuede comprobar el estado del producto aquí:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} el producto de API {productName}:{version} en el catálogo\n{catalog} se ha aprobado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n{comments}\n\n{/if}\nPuede comprobar el estado del producto aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hola, {firstName}},\n\nLa solicitud para {action}} el producto de API {productName}:{version} en el\ncatálogo {catalog} se ha denegado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n{comments}\n\n{/if}\nPuede comprobar el estado del producto aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} el producto de API {productName}:{version} en el catálogo\n{catalog} se ha denegado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n{comments}\n\n{/if}\nPuede comprobar el estado del producto aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} el producto de API {productName}:{version} en el catálogo\n{catalog} está en espera de aprobación. Enviaremos otra notificación\ncuando se haya gestionado la solicitud.\n\nPuede comprobar el estado del producto aquí:\n\n{link}\n\nSi en cualquier momento desea cancelar la solicitud, utilice este enlace:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} la aplicación {appName} en el portal de desarrollador\n{consumerOrg} se ha aprobado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n"{comments}"\n\n{/if}\nPuede comprobar el estado de la aplicación aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} la aplicación {appName} en el portal de desarrollador\n{consumerOrg} se ha aprobado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n{comments}\n\n{/if}\nPuede comprobar el estado de la aplicación aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} la aplicación {appName} en el portal de desarrollador\n{consumerOrg} se ha denegado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n"{comments}"\n\n{/if}\nPuede comprobar el estado de la aplicación aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} la aplicación {appName} en el portal de desarrollador\n{consumerOrg} se ha denegado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n{comments}\n\n{/if}\nPuede comprobar el estado de la aplicación aquí:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Hola, {firstName},\n\nLa solicitud para {action} la aplicación´n {appName} en el portal de desarrollador {consumerOrg}\nestá en espera de aprobación. Enviaremos otra notificación\ncuando se haya gestionado la solicitud.\n\nSi en cualquier momento desea cancelar la solicitud, utilice este enlace:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador de cuentas de IBM API Connect le ha invitado a crear\nuna organización de proveedores de API. Una organización de proveedores le permite a usted y\na su equipo desarrollar API y gestionar catálogos de productos de API.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador del catálogo de API {catalog} API que pertenece a la organización\nde proveedores de API {org} de IBM API Connect, le ha invitado a crear un\nespacio en el catálogo. Un espacio le permite a usted y a su equipo\ngestionar un conjunto de productos de API en un catálogo de API.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador del catálogo de API {catalog} API que pertenece a la organización\nde proveedores de API {org} de IBM API Connect, le ha invitado al\ncatálogo.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador del portal de desarrollador {catalog} le ha invitado a\ncrear una organización de consumidores de API. Una organización de consumidores\nle permite a usted y a su equipo acceder a las API y registrar aplicaciones de cliente.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador de la organización de consumidores de API {consumerOrg} del portal de desarrollador {catalog}\nle ha invitado a la organización.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador de la organización de proveedores de API {org} de IBM API\nConnect le ha invitado a crear un catálogo de API. Un catálogo le permite a usted\ny a su equipo publicar y gestionar una colección de productos de API.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador de la organización de proveedores de API {org} de IBM API\nConnect le ha invitado a la organización.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador del espacio {space}, en el catálogo de API {catalog}\nque pertenece a la organización de proveedores de API {org} en IBM API Connect,\nle ha invitado al espacio.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Hola.\n\nLa contraseña de la cuenta de usuario {username}\nen IBM API Connect se ha cambiado satisfactoriamente.\n\nPuede iniciar la sesión en API Connect aquí:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Hola.\n\nLa contraseña de la cuenta de usuario {username}\nen el portal de desarrollador {portalTitle} se ha cambiado.\n\nPuede iniciar la sesión en el portal de desarrollador {portalTitle} aquí:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hola.\n\nEl administrador del sistema de IBM API Connect le ha invitado a la\norganización {org}.\n\nPara continuar utilice el enlace siguiente:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Hola.\n\nEsto es un mensaje de prueba de IBM API Connect desde el servidor de correo configurado\n{mailServer}.\n\nSi ha recibido este mensaje tal como se esperaba, ¡la prueba ha sido satisfactoria!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hola.\n\nEl usuario {originator} ha retirado la solicitud para suscribirse al plan {planName} de la\nversión {productVersion} del producto de API {productName} en el catálogo {catalog}.\n\nNo es necesario hacer nada más.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hola.\n\nEl usuario {originator} ha retirado la solicitud para {action} la aplicación {appName} en el catálogo {catalog}.\n\nNo es necesario hacer nada más.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hola.\n\nEl usuario {originator} ha retirado la solicitud para {action} la versión {version} del producto de API {productName}\nen el catálogo {catalog}.\n\nNo es necesario hacer nada más.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Hola. Esto es un mensaje de prueba de IBM API Connect desde el servidor de correo configurado {{{mailServer}}}. Si ha recibido este mensaje tal como se esperaba, ¡la prueba ha sido satisfactoria`,
  [`Hello|header`]: `Hola`,
  [`Help`]: `Ayuda`,
  [`Hi, Steve (todo)`]: `Hola, Steve (por hacer)`,
  [`Hide`]: `Ocultar`,
  [`Hide field`]: `Ocultar campo`,
  [`Hide in schema`]: `Ocultar en esquema`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Ocultar tipos, campos, directivas u argumentos opcionales nuevos. Los elementos que ya no estén soportados por el programa de fondo se eliminarán.`,
  [`High`]: `Alto`,
  [`History`]: `Historial`,
  [`Histories`]: `Historiales`,
  [`History log`]: `Registro histórico`,
  [`Home`]: `Inicio`,
  [`Host`]: `Host`,
  [`Host context variable`]: `Variable de contexto de host`,
  [`Host name`]: `Nombre de host`,
  [`Host value (if present)`]: `Valor de host (si está presente)`,
  [`Hostname`]: `Nombre de host`,
  [`Hours`]: `Horas`,
  [`Http basic authentication schema`]: `Esquema de autenticación básica http`,
  [`Hmm, your search didn't return any results.`]: `Su búsqueda no ha devuelto resultados.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `nube de IBM API Connect`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `Autenticación de IBM Cloud`,
  [`IBM Cloud Docs`]: `Documentos de IBM Cloud`,
  [`IBM Schema`]: `Esquema de IBM`,
  [`IBM managed`]: `Gestionado por IBM`,
  [`ID Name`]: `Nombre de ID`,
  [`ID token custom claims`]: `Reclamaciones personalizadas de señal de ID`,
  [`ID token issuer`]: `Emisor de señal de ID`,
  [`ID token signing algorithm`]: `Algoritmo de firma de señal de ID`,
  [`ID token signing crypto object`]: `Objeto criptográfico de señal de ID`,
  [`ID token signing key`]: `Clave de firma de señal de ID`,
  [`ID token signing key identifier`]: `Identificador de clave de firma de señal de ID`,
  [`IDENTITY PROVIDER`]: `PROVEEDOR DE IDENTIDAD`,
  [`IDENTITY PROVIDER SUPPORT`]: `SOPORTE DE PROVEEDOR DE IDENTIDAD`,
  [`Identification`]: `Identificación`,
  [`Identity extraction`]: `Extracción de identidad`,
  [`If`]: `Si`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Si el modo de producción está inhabilitado, lo que significa que esto es un Catálogo de desarrollo, se fuerzan todas las operaciones de publicación y los conflictos se resuelven automáticamente, lo que permite. por ejemplo, volver a publicar repetidamente la misma versión del producto durante la prueba.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Si el modo de producción está habilitado, lo que significa que esto es un Catálogo de producción, no podrá publicar un producto en el catálogo si hay conflictos con cualesquiera productos que ya estén publicados; debe resolver los conflictos antes de volver a intentar la operación de publicación.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Si los espacios están habilitados en un catálogo, puede gestionar los miembros del espacio. Puede gestionar la pertenencia a un espacio añadiendo nuevos usuarios al espacio y asignando roles a los usuarios.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Si esta opción está habilitada, las propiedades de entrada con un valor nulo se correlacionarán con el documento de salida. De lo contrario, esas propiedades se omitirán de la salida.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Si la modalidad de producción está habilitada, no se forzarán las acciones de despliegue y publicación. Si se encuentran conflictos, el sistema los resolverá automáticamente. Las acciones de anular publicación se producirán de forma automática.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Si los destinatarios no han recibido el correo electrónico, compruebe los valores de servidor de correo electrónico.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Si los destinatarios no han recibido el correo electrónico, compruebe los valores de servidor de correo electrónico para {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Si se establece en un valor true, todos los caracteres + de los valores de los parámetros de consulta del target-url de las políticas Invoke y Proxy se codifican a %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Si se establece en un valor true, los parámetros de solicitud referenciados por una definición de variable o un URL de destino de invocación se descodifican por URL.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Si se establece en un valor true, la política de invocación envía una carga útil en un método DELETE de HTTP.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Si se establece en verdadero, la propiedad se redactará o se eliminará de los registros de actividad.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Si se establece en verdadero, la propiedad se redactará o se eliminará de la entrada al principio de la ejecución.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Si se establece en verdadero, la propiedad se redactará o se eliminará de la respuesta.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Si los espacios están habilitados en un catálogo, puede gestionar el acceso de los usuarios en el espacio. Puede gestionar el acceso especificando los permisos que están asignados a roles de usuario. Los permisos que están asignados a cada rol de forma predeterminada cuando se crea un espacio están determinados por los valores de la plantilla de permisos de espacio predeterminados.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Si el recuadro de selección está seleccionado (la opción predeterminada), los espacios de nombres XML se heredan del elemento padre. Desmarque el recuadro de selección si desea que la política de correlación utilice espacios de nombres explícitos.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Si el recuadro de selección está seleccionado (la opción predeterminada), los espacios de nombres XML se insertarán en el documento donde se utilicen por primera vez. Desmarque el recuadro de selección si desea que todos los espacios de nombres se definan en el elemento raíz.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Si el recuadro de selección está seleccionado (la opción predeterminada), los elementos XML vacíos se incluyen en la salida de la política de correlación. Desmarque el recuadro de selección si no desea que los elementos XML vacíos se incluyan en la salida de la política de correlación.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Si se marca el recuadro de selección, se habilita el procesamiento posterior de la salida JSON. El procesamiento posterior de la salida JSON utilizará el esquema de salida para garantizar que los valores de propiedad tienen el mismo tipo de datos que se ha definido en el esquema. También se normalizarán los valores de propiedad de salida que tienen una sintaxis JSON Badgerfish debido a la correlación de objetos de una entrada XML. Desmarque el recuadro de selección (el valor predeterminado) para no que se produzca el procesamiento posterior de la salida JSON correlacionada.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Si el recuadro de selección está marcado (la opción predeterminada), se resolverán las referencias de variable de API Connect encontradas en las propiedades de correlación. Quite la marca del recuadro de selección si desea que la política de correlación ignore las referencias de variable de API Connect en las políticas de correlación.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Si el recuadro de selección está marcado, se resolverán las referencias de variable de API Connect encontradas en las propiedades de correlación. Quite la marca del recuadro de selección (la opción predeterminada) si desea que la política de correlación ignore las referencias de variable de API Connect en las políticas de correlación.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Si el recuadro de selección está marcado (la opción predeterminada), si se encuentra una matriz al atravesar la entrada, solo se utilizará el primer elemento. Quite la marca del recuadro de selección si desea que la política de correlación utilice todos los elementos de matriz.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Si el recuadro de selección está seleccionado, los elementos XML cuyo esquema esté configurado como tipo booleano o numérico se convertirán a ese tipo de datos. Si no está seleccionado (la opción predeterminada), todos los valores de elemento XML se devolverán como una serie.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Si el recuadro de selección está marcado, la evaluación de tipos de esquema complejos manejará las referencias de tipo circular de forma optimizada. Quite la marca de este recuadro de selección (la opción predeterminada) para evaluar estos tipos de esquema de manera estándar.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Si el error se debe a la falta de soporte de CORS, pulse el enlace para abrir el servidor en una nueva pestaña. Si el navegador muestra un problema de certificado, puede elegir aceptarlo y volver aquí para probarlo de nuevo.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Si este recuadro de selección está marcado, cualquier matriz de salida o propiedad hijo de un objeto opcional indicado en el esquema como necesario, tendrá propiedades de objeto hijo necesarias inicializadas en un valor predeterminado a menos que se hayan correlacionado satisfactoriamente. Esto emulará cómo se realizaba la correlación de la versión 4 para propiedades necesarias de estos objetos. Quite la marca de este recuadro de selección (el valor predeterminado) para sacar estos objetos solamente con datos correlacionados satisfactoriamente.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Si este recuadro de selección está marcado (de forma predeterminada) y se cambia el usuario propietario, la propiedad de cualquier recurso hijo (espacios) también se transferirá al nuevo propietario.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Si se selecciona esta opción, la política de correlación escribirá la salida XML en message.body como un documento XML analizado. De forma predeterminada, el XML se generará como una serie XML. La salida de XML a cualquier otra variable se escribirá siempre como una serie XML.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Si habilita la aprobación de ciclo de vida para las acciones Desplegar, Publicar o Sustituir, algunas operaciones de creación, edición o prueba de API que ejecuten automáticamente estas acciones no funcionarán correctamente. ¿Está seguro de que desea habilitar alguna de estas aprobaciones?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Si tiene un servicio SOAP existente que desea exponer a través de una definición de API de API Connect, puede crear una API y especificar el servicio SOAP de destino. API Connect creará entonces una definición de API que llame a ese servicio SOAP.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Si guarda los cambios pulsando "Sí. Actualizar el ensamblaje de API", también se actualizará el ensamblaje de API subyacente a este proveedor de OAuth. Sin embargo, si ha personalizado el ensamblaje de API subyacente y desea actualizarlo aparte, pulse "No. No actualizar ensamblaje de API" y a continuación actualice el ensamblaje de API subyacente en el Editor de API.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Si sigue teniendo problemas, cargue un archivo de lenguaje de definición de esquema.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Si el archivo contiene claves privadas y públicas, cárguelo en el paso 1`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Si necesita registrar una pasarela ubicada detrás de un cortafuegos, consulte los documentos de IBM Cloud para obtener información sobre el uso de una VPN o el servicio de pasarela segura para establecer la conexión.`,
  [`Ignore`]: `Ignorar`,
  [`Implement policy`]: `Implementar política`,
  [`Implementation file is required`]: `Es necesario el archivo de implementación`,
  [`Implicit`]: `Implícito`,
  [`Import`]: `Importar`,
  [`Import API`]: `Importar API`,
  [`Import API|title`]: `Importar API`,
  [`Import OpenAPI definition from asset repository`]: `Importar la definición de OpenAPI del repositorio de activos`,
  [`Import Product`]: `Importar producto`,
  [`Import policy`]: `Importar política`,
  [`Import a product from a service URL`]: `Importar un producto de un URL de servicio`,
  [`Import a product from the definition file`]: `Importar un producto del archivo de definición`,
  [`Import an existing product`]: `Importar un producto existente`,
  [`Import an OpenAPI definition from a URL`]: `Importar una definición de OpenAPI de una URL`,
  [`Import an OpenAPI definition from a file`]: `Importar una definición de OpenAPI de un archivo`,
  [`Import definitions`]: `Importar definiciones`,
  [`Import from existing API service`]: `Importar de servicio de API existente`,
  [`Import from file`]: `Importar de archivo`,
  [`Importing an API definition`]: `Importación de una definición de API`,
  [`Import|title`]: `Importar`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Importe los certificados necesarios y los paquetes de CA en el servicio de Certificate Manager.`,
  [`In Cookie Header`]: `En cabecera de cookie`,
  [`In WSSec Header`]: `En cabecera WSSec`,
  [`In order to invoke the endpoints above, you need to first`]: `Para poder invocar los puntos finales anteriores, primero debe`,
  [`Inactive`]: `Inactivo`,
  [`Inc`]: `Inc`,
  [`Include empty`]: `Incluir vacío`,
  [`Include free trial days`]: `Incluir días de prueba gratuitos`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `No se pueden seleccionar API incompatibles con el servicio de pasarela configurado.`,
  [`Incorrect username or password`]: `Nombre de usuario o contraseña incorrectos`,
  [`Incorrect username, password, or credentials`]: `Nombre de usuario, contraseña o credenciales incorrectos`,
  [`Indicate if the invoke is the final action`]: `Indicar si la invocación es la acción final`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Indica si se debe añadir una reclamación de ID de JWT (jti) en la JWT. Si se selecciona, el valor de reclamación de jti será un UUID.`,
  [`Indicates whether the clients secret is required.`]: `Indica si se necesita el secreto de cliente.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Indica si este documento de entrada XSLT utiliza la carga útil actual de contexto o si no hay ninguna entrada.`,
  [`Indicates whether to stop processing if client security fails.`]: `Indica si se debe detener el proceso si la seguridad de cliente falla.`,
  [`Infer from JSON schema`]: `Inferir de esquema JSON`,
  [`Infer from Sample`]: `Inferir del ejemplo`,
  [`Info`]: `Información`,
  [`Information context variable`]: `Variable de contexto de información`,
  [`Inject proxy headers`]: `Inyectar cabeceras de proxy`,
  [`Inline schema`]: `Esquema en línea`,
  [`Input`]: `Entrada`,
  [`Input field`]: `Campo de entrada`,
  [`Input object`]: `Objeto de entrada`,
  [`Insecure Server Connections`]: `Conexiones de servidor inseguras`,
  [`Install API Connect CLI & API Designer|button text`]: `Instalar la CLI de API Connect y API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `Instalar la CLI de API Connect y API Designer`,
  [`Install API Connect Toolkit`]: `Instalar el kit de herramientas de API Connect`,
  [`Install the API Connect plugin`]: `Instale el plug-in de API Connect`,
  [`Instance ID`]: `ID de instancia`,
  [`Instance name`]: `Nombre de instancia`,
  [`Instance owner`]: `Propietario de instancia`,
  [`Interact to expand Tile`]: `Interactuar para expandir mosaico`,
  [`Interact to collapse Tile`]: `Interactuar para contraer mosaico`,
  [`Interval`]: `Intervalo`,
  [`Interface`]: `Interfaz`,
  [`Introspect`]: `Realizar introspección`,
  [`Introspect cache type`]: `Realizar introspección de tipo de memoria caché`,
  [`Introspect from Server URL`]: `Realizar introspección desde el URL de servidor`,
  [`Introspect from URL`]: `Realizar introspección desde el URL`,
  [`Introspect URL`]: `URL de introspección`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Realizar una introspección mediante un URL diferente no cambiará el URL del servidor GraphQL.`,
  [`Introspect token`]: `Realizar introspección de señal`,
  [`Introspection`]: `Introspección`,
  [`Introspection Path`]: `Vía de acceso de introspección`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Realizar introspección del esquema GraphQL desde el servidor o cargar el esquema`,
  [`Introspect the GraphQL schema using the query`]: `Realizar introspección del esquema GraphQL mediante la consulta`,
  [`Introspection URL`]: `URL de introspección`,
  [`Invalid HOST URL`]: `URL de HOST no válido`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `URL no válido. Especifique un URL de HOST de API Manager. p. ej. https://api-connect-host.com`,
  [`Invalid file URL`]: `URL de archivo no válido`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Formato de URL no válido, debe empezar por http:// o https:// y contener al menos 1 carácter`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Formato de URL no válido, solo se debe especificar el nombre de host. Es decir, syslog:// puede eliminarse`,
  [`Invalid emails.`]: `Correos electrónicos no válidos.`,
  [`Invalid JSONata expression`]: `Expresión JSONata no válida`,
  [`Invalid remove target`]: `Destino de eliminación no válido`,
  [`Invalid assumed size`]: `Tamaño adoptado no válido`,
  [`Invalid cost target`]: `Destino de coste no válido`,
  [`Invalid list size`]: `Tamaño de lista no válido`,
  [`Invalid port value, must not be negative`]: `Valor de puerto no válido, no debe ser negativo`,
  [`Invalid sized fields`]: `Campos dimensionados no válidos`,
  [`Invalid value, length has to be greater than 1`]: `Valor no válido, la longitud debe ser mayor que 1`,
  [`Invalid weight value`]: `Valor de peso no válido`,
  [`invitation`]: `invitación`,
  [`Invitation`]: `Invitación`,
  [`Invitation timeout`]: `Tiempo de espera de invitación`,
  [`Invitation link will expire in {time}`]: `El enlace de invitación caducará en **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `El enlace de invitación caducará en **{seconds} segundos ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `El enlace de invitación caducará en __{value} {unit}__.`,
  [`Invitation sent to`]: `Invitación enviada a`,
  [`Invitation sent to {email}`]: `Invitación enviada a {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Invitación a un catálogo de API en IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Invitación a una organización de consumidores de API en el portal de desarrollador {catalog}`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Invitación a una organización de proveedores de API en IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Invitación a un espacio de API en IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Invitación a una organización de administración en IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Invitación a crear un catálogo de API en IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Invitación a crear una organización de consumidores de API en el portal de desarrollador {catalog}`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Invitación a crear una organización de proveedores de API en IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Invitación a crear un espacio de API en IBM API Connect`,
  [`Invitation ttl has been created.`]: `Se ha creado el ttl de invitación.`,
  [`Invitation ttl has been updated.`]: `La ttl de invitación se ha actualizado.`,
  [`Invitation ttl has not been created!`]: `¡No se ha creado el ttl de invitación!`,
  [`Invitation ttl has not been updated!`]: `La ttl de invitación no se ha actualizado.`,
  [`Invite`]: `Invitar`,
  [`Invite catalog owner`]: `Invitar a propietario de catálogo`,
  [`Invite consumer organization owner`]: `Invitar a propietario de organización de consumidores`,
  [`Invite member`]: `Invitar a miembro`,
  [`Invite organization owner`]: `Invitar a propietario de organización`,
  [`Invite space owner`]: `Invitar a propietario de espacio`,
  [`Invite a new consumer organization owner`]: `Invitar a un propietario de organización de consumidores nuevo`,
  [`Invite a new member`]: `Invitar a un miembro nuevo`,
  [`Invite a new provider organization owner`]: `Invitar a un propietario de organización de proveedores nuevo`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Invite a un usuario nuevo a registrarse como gestor de un catálogo especificando la dirección de correo electrónico del destinatario. Se enviará un correo electrónico de invitación al usuario con un enlace al formulario de registro`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Invite a un usuario nuevo a registrarse como gestor de un espacio especificando la dirección de correo electrónico del destinatario. Se enviará un correo electrónico de invitación al usuario con un enlace al formulario de registro.`,
  [`Invite by email`]: `Invitar por correo electrónico`,
  [`Invite from User Registry`]: `Invitar desde registro de usuarios`,
  [`Invite team member`]: `Invitar a un miembro del equipo`,
  [`Inviting members to consumer organizations is enabled`]: `Invitar a miembros a organizaciones de consumidores está habilitado`,
  [`Invocations`]: `Invocaciones`,
  [`Invoke`]: `Invocar`,
  [`Issuer Claim`]: `Reclamación del emisor`,
  [`Issuer Origin`]: `Origen del emisor`,
  [`Issuer common name`]: `Nombre común del emisor`,
  [`It might influence how the API is deployed`]: `Puede influir en cómo se despliega la API`,
  [`Italian`]: `Italiano`,
  [`Items per page`]: `Elementos por página`,
  [`Items per page:`]: `Elementos por página:`,
  [`items selected`]: `elementos seleccionados`,
  [`item selected`]: `elemento seleccionado`,
  [`Iterate on`]: `Iterar en`,
  [`Iterate?`]: `¿Iterar?`,
  [`JSON objects describing re-usable channel parameters.`]: `Objetos JSON que describen parámetros de canal reutilizables.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `Objetos JSON que describen los mensajes consumidos y producidos por la API.`,
  [`JSON Schema URL`]: `URL de esquema JSON`,
  [`JSON Schema preview`]: `Vista previa de esquema JSON`,
  [`JSON Web Token (JWT)`]: `Señal web JSON (JWT)`,
  [`JSON has been successfully validated`]: `El JSON se ha validado satisfactoriamente`,
  [`JSON schema is not valid. Please correct the error.`]: `El esquema JSON no es válido. Corrija el error.`,
  [`JSON to XML`]: `JSON a XML`,
  [`JWT ID Claim`]: `Reclamación de ID de JWT`,
  [`JWT verification JWK`]: `JWK de verificación de JWT`,
  [`JWT verification crypto object`]: `Objeto criptográfico de verificación de JWT`,
  [`Japanese`]: `Japonés`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Cola de trabajos`,
  [`Jump to source`]: `Ir al origen`,
  [`Just now`]: `Ahora mismo`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `INTERCAMBIO DE CLAVES / CIFRADO / MAC`,
  [`KEY SIZE`]: `TAMAÑO DE CLAVE`,
  [`Keystore`]: `Almacén de claves`,
  [`keystore`]: `almacén de claves`,
  [`Keep`]: `Conservar`,
  [`Keep Payload`]: `Conservar carga útil`,
  [`Key Encryption Algorithm`]: `Algoritmo de cifrado de clave`,
  [`Key Name`]: `Nombre de clave`,
  [`Key type`]: `Tipo de clave`,
  [`Key name`]: `Nombre de clave`,
  [`Keystore for the token has been updated.`]: `Se ha actualizado el almacén de claves para la señal.`,
  [`Keystore Certificates`]: `Certificados de almacén de claves`,
  [`Keystore/truststore`]: `Almacén de claves/almacén de confianza`,
  [`Korean`]: `Coreano`,
  [`LABEL`]: `ETIQUETA`,
  [`LABELS`]: `ETIQUETAS`,
  [`LAST MODIFIED`]: `ÚLTIMA MODIFICACIÓN`,
  [`Last state changed`]: `Último estado cambiado`,
  [`Large`]: `Grande`,
  [`Lax`]: `Lax`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `Grupos LDAP`,
  [`LDAP user registry`]: `Registro de usuarios de LDAP`,
  [`LDAP registry`]: `Registro LDAP`,
  [`LDAP registry name`]: `Nombre del registro LDAP.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `El registro de usuarios LDAP de rol {{name}} ya no da soporte a la correlación de grupos externos. Habilite la correlación de grupos externos para este registro de usuarios o inhabilite la correlación de grupos externos en rol antes de guardar.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `El registro de usuarios LDAP de rol {{name}} ya no da soporte a la correlación de grupos externos. Seleccione otro registro de usuarios con la correlación de grupos externos o inhabilite la correlación de grupos externos en rol antes de guardar.`,
  [`LDAP search attribute`]: `Atributo de búsqueda LDAP`,
  [`LOCATED IN`]: `UBICADA EN`,
  [`Located in`]: `Ubicado en`,
  [`LTPA Key`]: `Clave LTPA`,
  [`LTPA Key Details`]: `Detalles de clave LTPA`,
  [`LTPA Keys`]: `Claves LTPA`,
  [`LTPA key file`]: `Archivo de claves LTPA`,
  [`LTPA key password`]: `Contraseña de clave LTPA`,
  [`LUR data version`]: `Versión de datos de LUR`,
  [`LUR schema update date`]: `Fecha de actualización de esquema de LUR`,
  [`LUR schema version`]: `Versión de esquema de LUR`,
  [`LUR target data version`]: `Versión de datos de destino de LUR`,
  [`LUR target schema version`]: `Versión de esquema de destino de LUR`,
  [`LUR update date`]: `Fecha de actualización de LUR`,
  [`Last name`]: `Apellido`,
  [`Last modified`]: `Última modificación`,
  [`Launch portal`]: `Lanzar portal`,
  [`Launch IBM Automation management console`]: `Iniciar la consola de gestión de IBM Automation`,
  [`Launch plan upgrade`]: `Iniciar plan de actualización`,
  [`Learn more`]: `Más información`,
  [`Learn More`]: `Más información`,
  [`Legacy`]: `Herencia`,
  [`Length cannot be below {min}`]: `La longitud no puede estar por debajo de {min}`,
  [`Length cannot exceed {max}`]: `La longitud no puede superar {max}`,
  [`Let's customize API Connect`]: `Vamos a personalizar API Connect`,
  [`Let's get started...`]: `Vamos a empezar...`,
  [`Let's get you up and running`]: `Vamos a ponerle en marcha`,
  [`License`]: `Licencia`,
  [`Licensed Materials - Property of IBM`]: `Materiales bajo licencia - Propiedad de IBM`,
  [`Lifecycle`]: `Ciclo de vida`,
  [`Lifecycle approvals`]: `Aprobaciones de ciclo de vida`,
  [`Lifecycle actions have been updated`]: `Se han actualizado las acciones de ciclo de vida`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Las aprobaciones de ciclo de vida están habilitadas para el catálogo de recinto de pruebas. Para utilizar la opción Activar API, primero debe inhabilitar las aprobaciones de ciclo de vida en los valores del catálogo de recinto de pruebas.`,
  [`Limited fields`]: `Campos limitados`,
  [`Limit API calls on a per key basis`]: `Limitar llamadas de API según la clave`,
  [`Limit fields`]: `Limitar campos`,
  [`Limit renegotiation`]: `Limitar renegociación`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Carga`,
  [`Load query`]: `Cargar consulta`,
  [`Load filter`]: `Cargar filtro`,
  [`Load schema from WSDL`]: `Cargar esquema desde WSDL`,
  [`Loading`]: `Cargando`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Los registros de usuarios locales (LUR) son los registros de usuarios predeterminados incluidos en API Connect y se almacenan localmente.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Los registros de usuarios locales son los registros de usuarios predeterminados incluidos en API Connect y almacenados localmente. Durante la instalación de API Connect se instalan y configuran dos LUR. Pueden configurarse LUR adicionales.`,
  [`Local user registry`]: `Registro de usuarios local`,
  [`Local User Registry has been created.`]: `Se ha creado el registro de usuarios local.`,
  [`Location`]: `Ubicación`,
  [`Location of ciphertext to decrypt`]: `Ubicación del texto cifrado a descifrar`,
  [`Location of plaintext information to encrypt`]: `Ubicación de información en texto sin formato a cifrar`,
  [`Log`]: `Registro`,
  [`Log in`]: `Iniciar sesión`,
  [`Log in to:`]: `Iniciar sesión en:`,
  [`Log in with a different account`]: `Iniciar sesión con una cuenta diferente`,
  [`Log into IBM Cloud`]: `Iniciar sesión en IBM Cloud`,
  [`Log out`]: `Cerrar sesión`,
  [`Log in using the {userRegistryType} user registry`]: `Iniciar sesión mediante el registro de usuario de {userRegistryType}`,
  [`Logging in with IBM Cloud ...`]: `Iniciando sesión en IBM Cloud ...`,
  [`Logging out`]: `Finalización de la sesión`,
  [`Logic`]: `Lógica`,
  [`Login`]: `Iniciar sesión`,
  [`Logs`]: `Registros`,
  [`Longer, descriptive name for this policy.`]: `Nombre más largo y descriptivo para esta política.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Parece que se está produciendo un error en el servidor mientras se procesa la solicitud.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Servidor de correo`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `MIEMBRO`,
  [`MIME Types`]: `Tipos MIME`,
  [`Mail server has been deleted.`]: `Se ha suprimido el servidor de correo.`,
  [`Mail server has not been deleted.`]: `No se ha suprimido el servidor de correo.`,
  [`Mailserver {arg} has been removed.`]: `Se ha eliminado el servidor de correo {arg}.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Asegúrese de que el servidor esté en funcionamiento y haya conectividad de red.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Esto puede ser debido a una falta de soporte de CORS en el servidor de destino, que el servidor no esté disponible, que se haya encontrado un certificado no fiable o que se necesite autenticación SSL mutua.`,
  [`This might be caused by one or more of the following:`]: `Esto podría deberse a una o varias de las causas siguientes:`,
  [`Make a call and move on`]: `Hacer una llamada y seguir adelante`,
  [`Make a call and wait for response`]: `Hacer una llamada y esperar respuesta`,
  [`Manage`]: `Gestionar`,
  [`Manage API life cycles`]: `Gestionar ciclos de vida de API`,
  [`Manage API product lifecycle approval permissions`]: `Gestionar permisos de aprobación de ciclo de vida de producto de API`,
  [`Manage API product lifecycle approvals permissions`]: `Gestionar permisos de aprobaciones de ciclo de vida de producto de API`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Gestionar organizaciones de proveedores de API, pasarelas locales y remotas y valores relacionados.`,
  [`Manage APIs`]: `Gestión de API`,
  [`Manage Catalogs`]: `Gestionar catálogos`,
  [`Manage Organizations`]: `Gestionar organizaciones`,
  [`Manage Resources`]: `Gestionar recursos`,
  [`Manage Settings`]: `Gestionar valores`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Gestionar destinos para análisis de API, incluidos API Connect, HTTP, Kafka, Elasticsearch y Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Gestione pasarelas locales y remotas que protegen las API.`,
  [`Manage catalogs`]: `Gestionar catálogos`,
  [`Manage organizations`]: `Gestionar organizaciones`,
  [`Manage resources`]: `Gestionar recursos`,
  [`Manage settings`]: `Gestionar valores`,
  [`Manage users`]: `Gestionar usuarios`,
  [`Manage active APIs and consumers`]: `Gestionar consumidores y API activas`,
  [`Manage availability zones and services`]: `Gestionar zonas de disponibilidad y servicios`,
  [`Manage policies`]: `Gestionar políticas`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Gestione las solicitudes de cambio de ciclo de vida de producto y las solicitudes de suscripción a API y actualización de aplicaciones de desarrolladores de aplicaciones`,
  [`Manage roles and permissions`]: `Gestionar roles y permisos`,
  [`Manage the OAuth providers configured for API Manager`]: `Gestionar los proveedores de OAuth configurados para el Gestor de API`,
  [`Manage the user registries configured for API Manager`]: `Gestionar los registros de usuarios configurados para el Gestor de API`,
  [`Manage the user registries configured for Cloud Manager`]: `Gestionar los registros de usuarios configurados para el Gestor de nube`,
  [`Management`]: `Gestión`,
  [`Management Endpoint`]: `Punto final de gestión`,
  [`Management endpoint`]: `Punto final de gestión`,
  [`Management endpoint on the analytics service`]: `Punto final de gestión en el servicio de análisis`,
  [`Service endpoint configuration`]: `Configuración de punto final de servicio`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Punto final utilizado por el servicio de gestión para enviar información de configuración al servicio de portal`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Perfil de cliente TLS utilizado por el servicio de gestión al comunicarse con el servicio de portal`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Punto final utilizado por el servicio de gestión para enviar información de configuración al servicio de pasarela`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `Perfil de cliente TLS utilizado por el servicio de gestión al comunicarse con el servicio de pasarela`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Utilizar la comunicación en clúster para el tráfico interno entre la pasarela y el servicio de gestión`,
  [`Use in-cluster communication for both ingestion and queries`]: `Utilizar la comunicación en clúster tanto para ingestión como para consultas`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Utilizar comunicación en clúster para ingestión y externa para consultas, o viceversa`,
  [`Use external communication for both ingestion and queries`]: `Utilizar comunicación externa tanto para ingestión como para consultas`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Utilizar la comunicación en clúster para el tráfico interno entre la analítica y el servicio de gestión`,
  [`Management endpoint on the gateway service`]: `Punto final de gestión en el servicio de pasarela`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Utilizar la comunicación en clúster para el tráfico interno entre el portal y el servicio de gestión`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Si desea utilizar la comunicación en clúster entre los subsistemas de gestión y portal, habilite este conmutador. Si no está seguro, deje este conmutador inhabilitado (el valor predeterminado).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Si desea utilizar la comunicación en clúster entre los subsistemas de gestión y pasarela, habilite este conmutador. Si no está seguro, deje este conmutador inhabilitado (el valor predeterminado).`,
  [`Management endpoint on the portal service`]: `Punto final de gestión en el servicio de portal`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Esta opción es el valor predeterminado y es lo que se utiliza para los servicios de análisis que se actualizan desde un release pre-10.0.5.3. Para despliegues OVA, o si el subsistema de análisis está en un entorno diferente, la única opción que puede utilizar es externa. Tanto la pasarela como el servicio de gestión se comunican con el servicio de análisis utilizando el punto final externo, que es una entrada de Kubernetes o una ruta de OpenShift en función de la plataforma.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `Puede seleccionar dentro del clúster si tiene un despliegue de Kubernetes u OpenShift donde todos los subsistemas están dentro del mismo clúster. Cuando se selecciona esta opción, los subsistemas de gestión, pasarela y análisis se comunican entre sí a través de puntos finales de servicio internos en lugar de ingresos accesibles externamente (Kubernetes) o rutas (OpenShift).`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Puede seleccionar esta opción si tiene un despliegue de Kubernetes u OpenShift y tiene algunos de los subsistemas instalados en el mismo clúster. Cuando esta opción está seleccionada, puede utilizar distintos métodos de comunicación para los distintos flujos de comunicación de análisis. Por ejemplo, la comunicación de gestión a análisis puede utilizar en clúster, y la pasarela a análisis puede utilizar externa. Puede elegir esta configuración si la pasarela está en un clúster distinto al resto de los subsistemas.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Si ha especificado una comunicación en clúster al registrar el servicio de análisis, puede habilitar la pasarela para enviar datos de sucesos de la API al punto final de análisis interno.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Utilizar punto final interno para la ingestión de datos analíticos desde el servicio de pasarela`,
  [`Endpoint configuration`]: `Utilizar comunicación externa tanto para ingestión como para consultas`,
  [`Portal endpoint configuration`]: `Configuración de punto final de portal`,
  [`URL used by developer portal users to access the portal website`]: `URL utilizado por los usuarios del portal del desarrollador para acceder al sitio web del portal`,
  [`External ingestion endpoint on the analytics service`]: `Punto final de ingestión externo en el servicio de análisis`,
  [`TLS client profile for external analytics ingestion endpoint`]: `Perfil de cliente TLS para punto final de ingestión externo de análisis`,
  [`Internal ingestion endpoint on the analytics service`]: `Punto final de ingestión interno en el servicio de análisis`,
  [`TLS client profile for internal service endpoint`]: `Perfil de cliente TLS para punto final de servicio interno`,
  [`Manager`]: `Gestor`,
  [`Manager Request Reset Password`]: `Solicitud de restablecimiento de contraseña de gestor`,
  [`Manager Reset Password`]: `Restablecimiento de contraseña de gestor`,
  [`Manager Sign In`]: `Inicio de sesión de gestor`,
  [`Manages API provider organizations`]: `Gestiona las organizaciones de proveedores de API`,
  [`Manages application developer communities`]: `Gestiona las comunidades de desarrolladores de aplicaciones`,
  [`Manages the API product lifecycle`]: `Gestiona el ciclo de vida del producto de API`,
  [`Manage|link`]: `Gestionar`,
  [`Manage|permission`]: `Gestionar`,
  [`Manage|title`]: `Gestionar título`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Gestionar asignación de almacén de claves e historial de almacén de claves para señales`,
  [`Map`]: `Correlación`,
  [`Map information from OIDC provider to an APIC user`]: `Correlacionar información del proveedor de OIDC con un usuario APIC`,
  [`Map the migration source plans to the migration target plans`]: `Correlacionar los planes de origen de migración con los planes de destino de migración`,
  [`Map the roles to groups`]: `Correlacionar los roles con grupos`,
  [`Mapped from: `]: `Correlacionado desde: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Maximizar / minimizar`,
  [`Maximum Consent Time to Live (seconds)`]: `Tiempo de vida de consentimiento máximo (segundos)`,
  [`Maximum consent`]: `Consentimiento máximo`,
  [`Menu`]: `Menú`,
  [`Member`]: `Miembro`,
  [`Member Invitation`]: `Invitación de miembro`,
  [`Member Invitation has been deleted.`]: `Se ha suprimido la invitación de miembro.`,
  [`Member has been deleted.`]: `Se ha suprimido el miembro.`,
  [`Member {name} created`]: `Se ha creado el miembro {name}`,
  [`Member {title} has been created.`]: `Se ha creado el miembro {title}.`,
  [`Members`]: `Miembros`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Invitaciones de miembro`,
  [`Member Roles`]: `Roles de miembro`,
  [`Merchant ID`]: `ID de comerciante`,
  [`Merge`]: `Fusionar`,
  [`Merge this mapping with any others that operate on the same target array?`]: `¿Fusionar esta correlación con cualquier otra que opere en la misma matriz destino?`,
  [`Message`]: `Mensaje`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Mensaje para el análisis`,
  [`Message for resulting parsed data`]: `Mensaje para los datos analizados resultantes`,
  [`Metadata`]: `Metadatos`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Los metadatos permiten recopilar metadatos personalizados para incluirlos durante el proceso de generación de señales de acceso. Puede recopilar los metadatos a través de un URL de autenticación o de un servidor remoto en el que se almacenan los metadatos, o ambas opciones.`,
  [`Micro Gateway policies`]: `Políticas de Micro Gateway`,
  [`Migrate`]: `Migrar`,
  [`Migrate Subscriptions`]: `Migrar suscripciones`,
  [`Migrate plans`]: `Migrar planes`,
  [`Migrate subscription`]: `Migrar suscripción`,
  [`Migrate subscriptions`]: `Migrar suscripciones`,
  [`Migration Target`]: `Destino de migración`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `Se ha ejecutado la migración de "{source}" (Product) a "{target}" (Product)`,
  [`Migration from "{source}" failed`]: `La migración de "{source}" ha fallado`,
  [`Migration source`]: `Origen de migración`,
  [`Migration target`]: `Destino de migración`,
  [`Minimum role`]: `Rol mínimo`,
  [`Minimum output escaping rule`]: `Regla de escape de salida mínimo`,
  [`Minutes`]: `Minutos`,
  [`Mode`]: `Modo`,
  [`Modifications`]: `Modificaciones`,
  [`Modified`]: `Modificado`,
  [`More`]: `Más`,
  [`More than 20 events are waiting to be processed`]: `Hay más de 20 sucesos a la espera de proceso`,
  [`Move down`]: `Mover hacia abajo`,
  [`Move up`]: `Mover hacia arriba`,
  [`Must be a number`]: `Tiene que ser un número`,
  [`Must be a valid URI`]: `Tiene que ser un URI válido`,
  [`Must be a valid email`]: `Tiene que ser un correo electrónico válido`,
  [`Must be a valid path starting with /`]: `Tiene que ser una vía de acceso válida que empiece por /`,
  [`Must be a valid url`]: `Tiene que ser un URL válido`,
  [`Must be an integer`]: `Tiene que ser un entero`,
  [`Must be a valid url with no protocol`]: `Debe ser un URL válido sin ningún protocolo`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Debe tener un formato de 64 caracteres hexadecimales (prefijado con "0x")`,
  [`Must start with '/'`]: `Debe empezar por '/'`,
  [`Must start with https://`]: `Debe empezar por https://`,
  [`Must start with https:// or $(`]: `Debe empezar por https:// o $(`,
  [`Mutual auth`]: `Autenticación mutua`,
  [`Mutual authentication`]: `Autenticación mutua`,
  [`My account`]: `Mi cuenta`,
  [`Name`]: `Nombre`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NO`,
  [`Name cannot be empty.`]: `El nombre no puede estar vacío.`,
  [`Name of Schema in OpenAPI Definitions`]: `Nombre de esquema en definiciones de OpenAPI`,
  [`Name of the LDAP user password attribute.`]: `Nombre del atributo de contraseña del usuario de LDAP.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Nombre del registro de usuarios LDAP contra el que se valida. Selecciónelo de la lista o escríbalo manualmente.`,
  [`Name of the organization`]: `Nombre de la organización`,
  [`Namespace inheritance`]: `Herencia de espacio de nombres`,
  [`Namespace inlining`]: `Incorporación de espacio de nombres`,
  [`Native`]: `Nativo`,
  [`Native OAuth provider`]: `Proveedor de OAuth nativo`,
  [`Native OAuth provider summary`]: `Resumen de proveedor de OAuth nativo`,
  [`Navigate to Source View`]: `Vaya a la vista Origen`,
  [`New`]: `Nuevo`,
  [`New API`]: `Nueva API`,
  [`New API Connect connection`]: `Conexión de API Connect nueva`,
  [`New Application`]: `Aplicación nueva`,
  [`New Catalog`]: `Catálogo nuevo`,
  [`New Consumer organization`]: `Nueva organización de consumidores`,
  [`New Endpoint`]: `Nuevo punto final`,
  [`New native OAuth provider`]: `Nuevo proveedor de OAuth nativo`,
  [`New OpenAPI`]: `Nueva OpenAPI`,
  [`New OpenAPI directory`]: `Directorio OpenAPI nuevo`,
  [`New Organization Title`]: `Título de organización nuevo`,
  [`New Parameter`]: `Parámetro nuevo`,
  [`New Password`]: `Contraseña nueva`,
  [`New product`]: `Producto nuevo`,
  [`New Role`]: `Rol nuevo`,
  [`New schema`]: `Esquema nuevo`,
  [`New Test`]: `Prueba nueva`,
  [`New third party OAuth provider`]: `Nuevo proveedor de OAuth de terceros`,
  [`New user`]: `Nuevo usuario`,
  [`New applications will be created in the development state`]: `Las aplicaciones nuevas se crearán en el estado de desarrollo`,
  [`Next`]: `Siguiente`,
  [`Next page`]: `Página siguiente`,
  [`No`]: `No`,
  [`No account?`]: `¿No hay ninguna cuenta?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `No hay ningún URL de autenticación ni ningún Registro de usuarios LDAP configurado en el catálogo de recinto de pruebas`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `No hay ningún URL de autenticación ni ningún Registro de usuarios LDAP configurado en el catálogo de recinto de pruebas; cree uno [aquí]({link})`,
  [`No Billing`]: `Sin facturación`,
  [`No billing integration has been selected.`]: `No se ha seleccionado ninguna integración de facturación.`,
  [`No billing integration can be shown when offline.`]: `No se puede mostrar ninguna integración de facturación cuando se está fuera de línea.`,
  [`No gateways`]: `No hay pasarelas`,
  [`No cache`]: `Sin caché`,
  [`No Cache`]: `Sin memoria caché`,
  [`No Certificate Manager services available`]: `No hay servicios de Certificate Manager disponibles`,
  [`No default gateways`]: `No hay pasarelas predeterminadas`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `No hay ninguna dirección de correo electrónico duplicada en el registro de usuarios. Esto solo se aplica a los usuarios con correo electrónico.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `No hay pasarelas compatibles configuradas para el catálogo de recinto de pruebas. No se habilitará la Prueba de API(Probar) en el Explorador.`,
  [`No Errors`]: `No hay errores`,
  [`No Histories found`]: `No se ha encontrado ningún historial`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `No se han encontrado servicios de pasarela. Configure un Servicio de pasarela para visualizar políticas de ensamblaje soportadas`,
  [`No gateways are configured for sandbox catalog`]: `No se han configurado pasarelas para el catálogo de recinto de pruebas`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `No hay pasarelas configuradas para el catálogo de recinto de pruebas. No se habilitará la Prueba de API(Probar) en el Explorador`,
  [`No LDAP or authentication URL user registries found.`]: `No se han encontrado registros de usuario de LDAP o de URL.`,
  [`No limit on size of list`]: `No hay límite para el tamaño de la lista`,
  [`No OAuth providers configured in the sandbox catalog`]: `No hay proveedores de OAuth configurados en el catálogo de recinto de pruebas`,
  [`No OAuth providers configured in the selected catalog`]: `No hay proveedores de OAuth configurados en el catálogo seleccionado`,
  [`No Permission`]: `Sin permiso`,
  [`No portal added to this catalog`]: `No se ha añadido ningún portal a este catálogo`,
  [`No Roles exist`]: `No existen roles`,
  [`No recent items found.`]: `No se han encontrado elementos recientes.`,
  [`No TLS profile`]: `Ningún perfil TLS`,
  [`No TLS client profile`]: `Ningún perfil de cliente TLS`,
  [`No Truststore`]: `Sin almacén de confianza`,
  [`No analytics service is configured`]: `No se ha configurado ningún servicio de análisis`,
  [`No analytics services found. You can create one [here]({link})`]: `No se han encontrado servicios de análisis. Puede crear uno [aquí]({link})`,
  [`No assemblies found.`]: `No se ha encontrado ningún ensamblaje.`,
  [`No available extensions`]: `No hay extensiones disponibles`,
  [`No compatible flows configured in this OAuth provider`]: `No hay flujos compatibles configurados en este proveedor de OAuth`,
  [`No consumer organization will be able to see this product.`]: `Ninguna organización de consumidores podrá ver este producto.`,
  [`No debug data was found for this API call`]: `No se han encontrado datos de depuración para esta llamada de API`,
  [`No details are available.`]: `No hay detalles disponibles.`,
  [`No existing products`]: `No existen productos`,
  [`No extensions have been configured for the API`]: `No se han configurado extensiones para la API`,
  [`No flow configured in this OAuth provider`]: `No hay flujo configurado en este proveedor de OAuth`,
  [`No items found`]: `No se han encontrado elementos`,
  [`No non-member user to add.`]: `No hay ningún usuario no miembro por añadir.`,
  [`No organization found`]: `No se ha encontrado ninguna organización`,
  [`No portal service exists:`]: `No existe ningún servicio de portal:`,
  [`No product APIs`]: `No hay API de producto`,
  [`No products or plans were found.`]: `No se han encontrado productos o planes.`,
  [`No provider organizations`]: `No hay organizaciones de proveedores`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `No se ha recibido ninguna respuesta. Entre las causas se incluye una falta de soporte de CORS en el servidor de destino, el servidor no está disponible, se ha encontrado un certificado no fiable o se necesita autenticación SSL mutua.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `No hay grupos de recursos disponibles. Para empezar, cree un grupo de recursos en los recursos de IBM Cloud Account.`,
  [`No security`]: `Sin seguridad`,
  [`No services found`]: `No se han encontrado servicios`,
  [`No warnings`]: `Sin avisos`,
  [`No warnings.`]: `Sin avisos.`,
  [`No. Do not update API Assembly`]: `No. No actualizar el ensamblaje de API`,
  [`Non-blocking`]: `Sin bloqueo`,
  [`Non int slicing arguments`]: `Argumentos de porcionado no enteros`,
  [`None`]: `Ninguno`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Ninguno de los proveedores de OAuth configurados coincide con el tipo de pasarela de esta API`,
  [`Normal`]: `Normal`,
  [`Not Available`]: `No disponible`,
  [`Not applicable for application grant type.`]: `No es aplicable para el tipo de concesión de aplicación.`,
  [`Not valid before`]: `No válido antes de`,
  [`Not valid after`]: `No válido después de`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Nota: esta opción está inhabilitada cuando se selecciona la opción 'Generar producto automático'.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Nota: esta opción está inhabilitada cuando se selecciona la opción 'Generar producto automático' y, en su lugar, utilizará el plan predeterminado para el producto generado.`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Nota: la preferencia de servicio de pasarela se utilizará como ámbito para todas las demás preferencias. Las políticas y otros contenidos específicos del servicio de pasarela se cargarán en función de este valor.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Nota: esta opción es sólo para fines informativos y se actualiza según la opción de aplicación de prueba anterior`,
  [`Notices`]: `Avisos`,
  [`Notification template`]: `Plantilla de notificación`,
  [`Notification templates`]: `Plantillas de notificación`,
  [`Notification server configured`]: `Servidor de notificaciones configurado`,
  [`Notifications`]: `Notificaciones`,
  [`Null Badgerfish`]: `Badgerfish nulo`,
  [`Null`]: `Nulo`,
  [`Number`]: `Número`,
  [`Number of Operations`]: `Número de operaciones`,
  [`Numeric`]: `Numérico`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `Proveedor de OAuth`,
  [`OAuth provider`]: `Proveedor de OAuth`,
  [`OAuth Provider Policy Assembly`]: `Ensamblaje de políticas de proveedor de OAuth`,
  [`OAuth providers`]: `Proveedores de OAuth`,
  [`OAuth Redirect URL`]: `URL de redireccionamiento de OAuth`,
  [`OAuth shared secret`]: `Secreto compartido de OAuth`,
  [`OAuth provider policy assembly`]: `Ensamblaje de política de proveedor de OAuth`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `No se ha encontrado el proveedor de OAuth {currentProviderName} Seleccione un proveedor de OAuth configurado.`,
  [`OAuth redirect URL`]: `URL de redireccionamiento de OAuth`,
  [`OAuth Shared Secret`]: `Secreto compartido de OAuth`,
  [`OAuth Signature Method`]: `Método de firma de OAuth`,
  [`OAuth signature method`]: `Método de firma de OAuth`,
  [`OAuth providers can be created and managed in the following list.`]: `Los proveedores de OAuth pueden crearse y gestionarse en la lista siguiente.`,
  [`OAuth symmetric key name`]: `Nombre de clave simétrica de OAuth`,
  [`OAuth symmetric key value`]: `Valor de clave simétrica de OAuth`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `Seguridad de código de acceso de OAuth2`,
  [`OAuth2 Application Security`]: `Seguridad de aplicación de OAuth2`,
  [`OAuth2 Implicit Security`]: `Seguridad implícita de OAuth2`,
  [`OAuth2 Password Security`]: `Seguridad de contraseña de OAuth2`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `Aceptar`,
  [`OPERATION ID`]: `ID DE OPERACIÓN`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `ORDEN`,
  [`ORGANIZATION`]: `ORGANIZACIÓN`,
  [`ORGANIZATION MANAGER`]: `GESTOR DE ORGANIZACIÓN`,
  [`Organizations`]: `Organizaciones`,
  [`Owner`]: `Propietario`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Los proveedores de Oauth se gestionan en cada espacio cuando se habilitan los espacios. Vaya a los valores de espacio para gestionar proveedores de Oauth.`,
  [`Off`]: `Desactivar`,
  [`Offline`]: `Fuera de línea`,
  [`Old schema`]: `Esquema antiguo`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch permite buscar APIs, productos, aplicaciones, suscripciones y otros recursos dentro de una organización de proveedores o un catálogo. Además, también puede ayudar a descubrir las relaciones entre estos recursos.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch para todas las páginas de lista soportadas`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch para borradores de APIs y productos`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch para productos publicados en catálogos y espacios`,
  [`Omnisearch for consumer organizations`]: `Omnisearch para organizaciones de consumidores`,
  [`Omnisearch for applications`]: `Omnisearch para aplicaciones`,
  [`Omnisearch for requested approvals`]: `Omnisearch para aprobaciones solicitadas`,
  [`Omnisearch for approvals tasks`]: `Omnisearch para tareas de aprobación`,
  [`Omnisearch for product apis`]: `Omnisearch para apis de producto`,
  [`Omnisearch for subscriptions`]: `Omnisearch para suscripciones`,
  [`Omnisearch for members`]: `Omnisearch para miembros`,
  [`Omnisearch for provider organizations`]: `Omnisearch para organizaciones de proveedores`,
  [`Disable the new editor for OpenAPI2`]: `Inhabilitar el nuevo editor de OpenAPI2`,
  [`On`]: `Activar`,
  [`Onboarding`]: `Incorporación`,
  [`Onboarding Edit Admin Org`]: `Organización de Administración de edición de incorporación`,
  [`Onboarding Edit Cloud Org`]: `Organización de Cloud de edición de incorporación`,
  [`Onboarding Edit Reset Password`]: `Restablecer contraseña de edición de incorporación`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Una vez instalado, utilice la CLI de IBM Cloud para apuntar a la cuenta y la región en la que ha proporcionado API Connect; a continuación, pruebe la CLI listando todas las organizaciones de proveedor de API Connect`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Uno o más objetos JSON que describen los esquemas consumidos y generados por la API.`,
  [`One or more JSON representations for parameters`]: `Una o más representaciones JSON para parámetros`,
  [`One time use access token`]: `Señal de acceso de un solo uso`,
  [`One time use refresh token`]: `Señal de renovación de un solo uso`,
  [`Online`]: `En línea`,
  [`Only one API file should be present in the zip file.`]: `El archivo zip solo debe contener un archivo de API.`,
  [`OpenAPI Specification Version`]: `Versión de Especificación OpenAPI`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) proporciona un protocolo de autenticación adicional básico en OAuth 2.0. OIDC proporciona información de usuario codificada en una señal web JSON o JWT. Cuando habilita OpenID Connect, se proporciona una plantilla para generar señales de ID conjuntamente con las señales de acceso y las políticas de ensamblaje se crean automáticamente.`,
  [`Open a Directory`]: `Abrir un directorio`,
  [`Open in external editor`]: `Abrir en un editor externo`,
  [`Open the server`]: `Abrir el servidor`,
  [`OpenAPI extensions`]: `Extensiones de OpenAPI`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect aún no soportado cuando la versión de pasarela es 6000`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `Los valores de OpenID Connect solo son aplicables si los tipos de otorgamiento seleccionados incluyen uno o varios de los siguientes: Implícito y Código de acceso.`,
  [`Operation`]: `Operación`,
  [`Operation Id`]: `ID de operación`,
  [`Operation switch`]: `Conmutador de operación`,
  [`Operation Switch`]: `Conmutación de operación`,
  [`Operations`]: `Operaciones`,
  [`Operator build date`]: `Fecha de compilación del operador`,
  [`Operator build tag`]: `Etiqueta de compilación de operador`,
  [`Operator version`]: `Versión de operador`,
  [`Optionally select a CA bundle`]: `Seleccionar opcionalmente un paquete de CA`,
  [`Optimize schema definition`]: `Optimizar definición de esquema`,
  [`Optional`]: `Opcional`,
  [`Or`]: `O`,
  [`Or create a new application and subscribe to the selected plan`]: `O crear una aplicación nueva y suscribirla al plan seleccionado`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `O cree un producto nuevo y publíquelo en el catálogo de recinto de pruebas`,
  [`Order`]: `Orden`,
  [`Org`]: `Org`,
  [`Org owner`]: `Propietario de organización`,
  [`Organization`]: `Organización`,
  [`Organization:`]: `Organización:`,
  [`Organization information`]: `Información de organización`,
  [`Organization Manager`]: `Gestor de organización`,
  [`Organization has been updated.`]: `Se ha actualizado la organización.`,
  [`Organizations will be listed here.`]: `Las organizaciones aparecerán aquí.`,
  [`Otherwise`]: `De lo contrario`,
  [`Output`]: `Datos de salida`,
  [`Output Claims`]: `Reclamaciones de salida`,
  [`Output array`]: `Matriz de salida`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Ofrecer la salida del elemento raíz si no es necesario que el documento XML tenga un formato adecuado.`,
  [`Output variable`]: `Variable de salida`,
  [`Override API consume types`]: `Alterar temporalmente tipos de consumo de API`,
  [`Override API produce types`]: `Alterar temporalmente tipos de producción de API`,
  [`Override API security definitions`]: `Alterar temporalmente definiciones de seguridad de API`,
  [`Override rate limits`]: `Alterar temporalmente límites de velocidad`,
  [`Override default "provider" settings with configuration from this URL`]: `Alterar temporalmente los valores de "proveedor" predeterminados con la configuración desde este URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Alterar temporalmente los valores de "proveedor" predeterminados con la configuración desde este literal`,
  [`Override plan rate limits`]: `Alterar temporalmente los límites de velocidad del plan`,
  [`Override plan rate limits for individual operation`]: `Alterar temporalmente los límites de velocidad del plan para operación individual`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Alterar temporalmente el tiempo de espera de invitación de organizaciones de consumidor con el tiempo de espera de invitación de catálogo`,
  [`Overview`]: `Información general`,
  [`Owner scope check`]: `Comprobación de ámbito de propietario`,
  [`Owner's Email`]: `Correo electrónico del propietario`,
  [`Owner's Name`]: `Nombre del propietario`,
  [`Owner's email`]: `Correo electrónico del propietario`,
  [`Owns and administers the API provider organization`]: `Es propietario y administra la organización de proveedores de API`,
  [`Owns and administers the admin organization`]: `Es propietario y administra la organización de administración`,
  [`Owns and administers the app developer organization`]: `Es propietario y administra la organización de desarrolladores de aplicaciones.`,
  [`Owns and administrates the app developer organization`]: `Es propietario y administra la organización de desarrolladores de aplicaciones.`,
  [`PARAMETER NAME`]: `NOMBRE DE PARÁMETRO`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `PCRE que se va a utilizar para validar la reclamación Pública (aud).`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `PCRE que se va a utilizar para validar la reclamación Emisor (iss).`,
  [`PER`]: `POR`,
  [`Per`]: `Por`,
  [`Plan`]: `Plan`,
  [`Plan:`]: `Plan:`,
  [`pending Consumer organization invitation`]: `invitación de organización de consumidores pendiente`,
  [`PLAN`]: `PLAN`,
  [`PLANS`]: `PLANES`,
  [`Policy`]: `Política`,
  [`policy`]: `política`,
  [`POLICIES`]: `POLÍTICAS`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUCTO`,
  [`Product Plan`]: `Plan del producto`,
  [`Product Plans`]: `Planes del producto`,
  [`Product Plans per page`]: `Planes de producto por página`,
  [`Property name`]: `Nombre de propiedad`,
  [`Property type`]: `Tipo de propiedad`,
  [`Property example`]: `Ejemplo de propiedad`,
  [`Property description`]: `Descripción de propiedad`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `No se ha encontrado la página`,
  [`Parameter name`]: `Nombre de parámetro`,
  [`Parameter control`]: `Control de parámetro`,
  [`Parameters`]: `Parámetros`,
  [`Parent`]: `Padre`,
  [`Parse`]: `Analizar`,
  [`Parse settings URL reference`]: `Referencia de URL de valores de análisis`,
  [`Parse settings literal configuration`]: `Configuración de literal de valores de análisis`,
  [`Parse settings object reference`]: `Referencia de objeto de valores de análisis`,
  [`Parse the GraphQL query`]: `Analizar la consulta de GraphQL`,
  [`Parse the response from the GraphQL backend server`]: `Analizar la respuesta del servidor de fondo de GraphQL`,
  [`Parse XML output`]: `Analizar la salida de XML`,
  [`Pass-through`]: `Paso a través`,
  [`Password`]: `Contraseña`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `La contraseña de su cuenta de usuario en IBM API Connect ha cambiado`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `La contraseña de su cuenta de usuario en el portal de desarrollador {portalTitle} ha cambiado`,
  [`Password context variable`]: `Variable de contexto de contraseña`,
  [`Password is invalid. Re-enter the password.`]: `La contraseña no es válida. Vuelva a escribir la contraseña.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `Solicitud de restablecimiento de contraseña de su cuenta de usuario en IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Solicitud de restablecimiento de contraseña de su cuento de portal de desarrollador {catalog}`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Las contraseñas deben contener al menos un carácter perteneciente a dos de las categorías siguientes: mayúsculas, minúsculas, números y signos de puntuación (ejemplos: !, $, #, %).`,
  [`Passwords must contain eight or more characters`]: `Las contraseñas deben tener un mínimo de ocho caracteres`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Las contraseñas deben contener ocho o más caracteres, con al menos un carácter perteneciente a dos de las categorías siguientes: mayúsculas, minúsculas, números y signos de puntuación (ejemplos: !, $, #, %). Además, no se puede utilizar un mismo carácter más de dos veces consecutivas.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Las contraseñas no pueden contener un mismo carácter utilizado más de dos veces consecutivas.`,
  [`Passwords should match`]: `Las contraseñas deberían coincidir`,
  [`Path`]: `Vía de acceso`,
  [`Path parameters`]: `Parámetros de vía de acceso`,
  [`Path name`]: `Nombre de vía de acceso`,
  [`Paths`]: `Vías de acceso`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Las vías de acceso identifican los recursos REST expuestos por la API. Una operación combina una vía de acceso con un verbo HTTP, parámetros y definiciones para solicitudes y respuestas.`,
  [`Payments Authorization`]: `Autorización de pagos`,
  [`Payment method`]: `Método de pago`,
  [`Pending`]: `Pendiente`,
  [`Pending State`]: `Estado pendiente`,
  [`Pending approval to be published in catalog`]: `Aprobación pendiente para publicación en catálogo`,
  [`Pending approval to be staged in catalog`]: `Aprobación pendiente para despliegue en catálogo`,
  [`Pending consumer organization invitations`]: `Invitaciones de organización de consumidores pendientes`,
  [`View endpoints`]: `Ver puntos finales`,
  [`View base endpoints for this API`]: `Ver puntos finales base para esta API`,
  [`View pending invitations`]: `Ver invitaciones pendientes`,
  [`Permission Denied`]: `Permiso denegado`,
  [`Permissions`]: `Permisos`,
  [`Persistent Connection`]: `Conexión persistente`,
  [`Pin menu`]: `Anclar menú`,
  [`PlainText`]: `Texto sin formato`,
  [`Plan APIs`]: `APIs de plan`,
  [`Plan burst limits`]: `Límites de ráfaga de plan`,
  [`Plan by name`]: `Plan por nombre`,
  [`Plan Default`]: `Valor predeterminado de plan`,
  [`Plan Details`]: `Detalles del plan`,
  [`Plan migration targets`]: `Destinos de migración del plan`,
  [`Plan rate limits`]: `Límites de velocidad de plan`,
  [`Plan name`]: `Nombre de plan`,
  [`Plan pricing`]: `Precio de plan`,
  [`Plan title already exists`]: `El título de plan ya existe`,
  [`Plans`]: `Planes`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Punto final de API REST para API de administrador y de proveedor`,
  [`Platform REST API endpoint for consumer APIs`]: `Punto final de API REST para API de consumidor`,
  [`Please choose a bind method`]: `Elija un método de enlace`,
  [`Please choose an authentication method`]: `Elija un método de autenticación`,
  [`Please contact the system administrator.`]: `Póngase en contacto con el administrador del sistema.`,
  [`Please contact your administrator for support.`]: `Póngase en contacto con el administrador para obtener ayuda.`,
  [`Please enter the reason.`]: `Especifique el motivo.`,
  [`Please enter your username`]: `Especifique su nombre de usuario`,
  [`Please go to the space settings to manage API endpoints.`]: `Vaya a los valores de espacio para gestionar puntos finales de API.`,
  [`Please make sure the server is online and try again.`]: `Asegúrese de que el servidor esté en línea y vuelva a intentarlo.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Tenga en cuenta que al inhabilitar el registro de actividad se eliminarán las propiedades asociadas al tipo de contenido a registrar en caso de éxito o de error. ¿Está seguro de que desea inhabilitarlo?`,
  [`Please review the license for API Connect by accessing`]: `Revise la licencia de API Connect accediendo a`,
  [`Please select a TLS`]: `Seleccione un TLS`,
  [`Please select a TLS client profile`]: `Seleccione un perfil de cliente TLS`,
  [`Please select a group type`]: `Seleccione un tipo de grupo`,
  [`Please select an LDAP protocol version`]: `Seleccione una versión de protocolo LDAP`,
  [`Please select authentication method`]: `Seleccione un método de autenticación`,
  [`Please select ciphers from the list below:`]: `Seleccione cifrados de la lista siguiente:`,
  [`Please select consumer organization`]: `Seleccione la organización de consumidores`,
  [`Please select one Keystore from the list below:`]: `Seleccione un almacén de claves de la lista siguiente:`,
  [`Please select one keystore from the list below:`]: `Seleccione un almacén de claves de la lista siguiente:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Tome nota del identificador y secreto del cliente para esta nueva aplicación. Guarde el secreto en algún lugar seguro, pues no se puede recuperar de nuevo, pero se puede restablecer si es necesario:`,
  [`Please try again in a few minutes.`]: `Vuelva a intentarlo en unos minutos.`,
  [`Please refresh catalog/space product list page and try again.`]: `Renueve la página de lista de productos de catálogo/espacio e inténtelo de nuevo.`,
  [`Policies`]: `Políticas`,
  [`Policy Assemblies`]: `Ensamblajes de política`,
  [`Policy assembly`]: `Ensamblaje de política`,
  [`Policy description.`]: `Descripción de política.`,
  [`Policy title`]: `Título de la política`,
  [`Policy has been uploaded.`]: `Se ha cargado la política.`,
  [`Polish`]: `Polaco`,
  [`Port`]: `Puerto`,
  [`Portal`]: `Portal`,
  [`Portal details`]: `Detalles de portal`,
  [`Portal Director TLS client profile`]: `Perfil de cliente TLS de director de portal`,
  [`Portal Director keystore`]: `Almacén de claves de Director de portal`,
  [`Portal Director truststore`]: `Almacén de confianza de Director de portal`,
  [`Portal service`]: `Servicio de portal`,
  [`Portal Service`]: `Servicio de portal`,
  [`Portal service name`]: `Nombre de servicio de portal`,
  [`Portal service details`]: `Detalles del servicio del portal`,
  [`Portal Service and URL`]: `Servicio y URL de portal`,
  [`Portal service {name} was successfully created.`]: `El Servicio de portal {name} se ha creado correctamente.`,
  [`Portal service {name} was successfully updated.`]: `El Servicio de portal {name} se ha actualizado correctamente.`,
  [`Portal Service {arg} has been removed.`]: `Se ha eliminado el servicio de portal {arg}.`,
  [`Portal site`]: `Sitio del portal`,
  [`Portal URL`]: `URL de portal`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Los URL del sitio del portal no pueden tener más de 200 caracteres`,
  [`Portal website URL`]: `URL del sitio web del portal`,
  [`Portal and Gateway Settings`]: `Valores de portal y pasarela`,
  [`Portal has been created`]: `Se ha creado el portal`,
  [`Portal has been created.`]: `Se ha creado el portal.`,
  [`Portal has been deleted`]: `Se ha suprimido el portal`,
  [`Portals`]: `Portales`,
  [`Portuguese`]: `Portugués`,
  [`Post Response`]: `Postrespuesta`,
  [`Post-Invoke Hook`]: `Postinvocar gancho`,
  [`Preserve subscriptions on re-publish`]: `Conservar suscripciones al volver a publicar`,
  [`Preserve subscriptions on re-publish product`]: `Conservar suscripciones al volver a publicar producto`,
  [`Pre-Invoke Hook`]: `Preinvocar gancho`,
  [`Pre-Request`]: `Presolicitud`,
  [`Preference`]: `Preferencia`,
  [`Prefix`]: `Prefijo`,
  [`Preparing API`]: `Preparando API`,
  [`Preserved request header`]: `Cabecera de solicitud conservada`,
  [`preserved request header`]: `cabecera de solicitud conservada`,
  [`Preserved response header`]: `Cabecera de respuesta conservada`,
  [`preserved response header`]: `cabecera de respuesta conservada`,
  [`Preview`]: `Vista previa`,
  [`Preview of login screen registry prompt`]: `Vista previa de la solicitud de registro de la pantalla de inicio de sesión`,
  [`Previous`]: `Anterior`,
  [`Previous page`]: `Página anterior`,
  [`Prettify`]: `Prettify`,
  [`Price per month`]: `Precio por mes`,
  [`Private`]: `Privado`,
  [`Private Claims`]: `Reclamaciones privadas`,
  [`Private key & public key`]: `Clave privada y clave pública`,
  [`Private Key or Shared Secret for Decrypt`]: `Clave privada o secreto compartido para descifrar`,
  [`Private Key or Shared Secret for Sign`]: `Clave privada o secreto compartido para firmar`,
  [`Private key detected`]: `Clave privada detectada`,
  [`Private key password`]: `Contraseña de clave privada`,
  [`Processed`]: `Procesado`,
  [`Problem`]: `Problema`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Problema al captar datos de rastreo. Vuelva a intentarlo. Consulte la consola para obtener detalles de error.`,
  [`Produced Media Types`]: `Tipos de soportes producidos`,
  [`Produces`]: `Produce`,
  [`Product`]: `Producto`,
  [`Product:`]: `Producto:`,
  [`Product / Plan`]: `Producto / Plan`,
  [`Product APIs`]: `API del producto`,
  [`Product editor`]: `Editor de producto`,
  [`Product lifecycle`]: `Ciclo de vida del producto`,
  [`Product Lifecycle`]: `Ciclo de vida del producto`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `El nombre de producto no puede terminar por '-auto-product'. Cambie el nombre y vuelva a intentar la importación.`,
  [`Product setup`]: `Configuración del producto`,
  [`Product details`]: `Detalles de producto`,
  [`Product documentation`]: `Documentación del producto`,
  [`Product has been created.`]: `Se ha creado el producto`,
  [`Product has been deleted`]: `Se ha suprimido el producto`,
  [`Product has been updated`]: `Se ha actualizado el producto`,
  [`Product has been updated.`]: `Se ha actualizado el producto.`,
  [`Product has not been created!`]: `¡El producto no se ha creado!`,
  [`Product has not been published!`]: `¡El producto no se ha publicado!`,
  [`Product has not been updated!`]: `¡El producto no se ha actualizado!`,
  [`Product has not been staged!`]: `¡El producto no se ha desplegado!`,
  [`Product information`]: `Información sobre el producto`,
  [`Product summary details`]: `Detalles de resumen de producto`,
  [`Product version`]: `Versión del producto`,
  [`Product visibility`]: `Visibilidad de producto`,
  [`Product-Approval`]: `Aprobación de producto`,
  [`Product-Drafts`]: `Borradores de producto`,
  [`Product/plan`]: `Producto/plan`,
  [`Production`]: `Producción`,
  [`Production mode`]: `Modo de producción`,
  [`Production endpoints`]: `Puntos finales de producción`,
  [`Products`]: `Productos`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Los productos se deben desplegar en un catálogo y después publicarse para que estén disponibles para los desarrolladores de aplicaciones. Un catálogo se comporta como una partición lógica de la pasarela y el Portal de desarrollador. El URL de llamadas de API y el Portal de desarrollador son específicos de un catálogo determinado. En una configuración típica, una organización de proveedores de API utiliza un catálogo de desarrollo para probar API en desarrollo y un catálogo de producción para alojar API listas para su uso.`,
  [`Profile`]: `Perfil`,
  [`Profile rule`]: `Regla de perfil`,
  [`Promote to production`]: `Promocionar a producción`,
  [`Proof Key for Code Exchange`]: `Clave de prueba para intercambio de código`,
  [`Proof Key for Code Exchange (PKCE)`]: `Clave de prueba para intercambio de código (PKCE)`,
  [`Properties`]: `Propiedades`,
  [`Property`]: `Propiedad`,
  [`Property Description`]: `Descripción de la propiedad`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `Se puede asignar un valor estático a la propiedad {{target}} entrando el valor más abajo.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `La propiedad {{target}} está correlacionada actualmente con {{from}}. La correlación se puede eliminar más abajo.`,
  [`Property Value is Base64 Encoded`]: `El valor de propiedad está codificado en Base64`,
  [`Protocol`]: `Protocolo`,
  [`Protocols`]: `Protocolos`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protocolos que se pueden utilizar para invocar la API (para las API impuestas por la pasarela solo se admite https)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Proporcione credenciales de OAuth {oauthVer} para autenticar usuarios y obtener acceso a las API del proveedor de OpenID`,
  [`Provide a schema`]: `Proporcionar un esquema`,
  [`Provide GraphQL schema`]: `Proporcionar esquema GraphQL`,
  [`Provide the URL to the backend GraphQL server`]: `Proporcionar el URL al servidor de GraphQL de fondo`,
  [`Provide your email address to receive password reset instructions`]: `Proporcione la dirección de correo electrónico para recibir instrucciones de restablecimiento de contraseña`,
  [`Provide your new password below`]: `Especifique la contraseña nueva`,
  [`Provider endpoint`]: `Punto final de proveedor`,
  [`Provider Information`]: `Información de proveedor`,
  [`Provider Organization`]: `Organización de proveedores`,
  [`Provider Organizations`]: `Organizaciones de proveedores`,
  [`Provider Type`]: `Tipo de proveedor`,
  [`Provider organizations`]: `Organizaciones de proveedores`,
  [`Provider type`]: `Tipo de proveedor`,
  [`Provider org invite failed`]: `La invitación de organización de proveedores ha fallado`,
  [`Provider organization {name} created`]: `Se ha creado la organización de proveedores {name}`,
  [`Provider organizations will be listed here`]: `Las organizaciones de proveedores aparecerán aquí`,
  [`Provider-Org`]: `Organización de proveedores`,
  [`Provides contact information for the exposed API.`]: `Proporciona información de contacto para la API expuesta.`,
  [`Provides license information for the exposed API.`]: `Proporciona información de licencia para la API expuesta.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Proporciona metadatos sobre la API. Los clientes pueden utilizar los metadatos si es necesario.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `El suministro del portal de desarrollador se ha iniciado. Tardará unos minutos en completarse. Una vez finalizado, recibirá un correo electrónico que contiene un enlace para establecer la contraseña de la cuenta de administración del portal.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `Se ha iniciado la actualización del URL del portal del desarrollador. El proceso tardará unos minutos en completarse.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `URL de proxy`,
  [`Public`]: `Público`,
  [`Public Key or Shared Secret for Encrypt`]: `Clave pública o secreto compartido para cifrar`,
  [`Publish`]: `Publicar`,
  [`Publish API`]: `Publicar API`,
  [`Publish a product from the Develop section.`]: `Publicar un producto de la sección Desarrollar.`,
  [`Publish product`]: `Publicar producto`,
  [`Publish to`]: `Publicar en`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Publique algunos productos en este catálogo para crear suscripciones a los mismos.`,
  [`Publish target`]: `Destino de publicación`,
  [`Publish this API to the Sandbox catalog.`]: `Publicar esta API en el catálogo de recinto de pruebas.`,
  [`Publish to specific gateway services`]: `Publicar en servicios de pasarela específicos`,
  [`Publish using a new product`]: `Publicar mediante un producto nuevo`,
  [`Publish using an existing product`]: `Publicar mediante un producto existente`,
  [`Publishable Key`]: `Clave publicable`,
  [`Publishable key`]: `Clave publicable`,
  [`Publishable key tip`]: `Sugerencia de clave publicable`,
  [`Published`]: `Publicado`,
  [`Published product`]: `Producto publicado`,
  [`Publishing`]: `Publicación`,
  [`Publishing New API`]: `Publicando API nueva`,
  [`Publish (preserve subscriptions)`]: `Publicar (conservar suscripciones)`,
  [`Publish|permission`]: `Publicar`,
  [`Put online`]: `Poner en línea`,
  [`Query`]: `Consulta`,
  [`Queryparam Encode`]: `Codificación de parámetros de consulta`,
  [`Queued`]: `En cola`,
  [`Queued events: **{queued}**`]: `Sucesos en cola: **{queued}**`,
  [`Retry blocked jobs`]: `Reintentar trabajos bloqueados`,
  [`REQUIRED`]: `OBLIGATORIO`,
  [`ROLES`]: `ROLES`,
  [`Rate limit`]: `Límite de velocidad`,
  [`Rate Limit`]: `Límite de velocidad`,
  [`Rate Limit Name`]: `Nombre de límite de velocidad:`,
  [`Rate limits`]: `Límites de velocidad`,
  [`Rate limit name can't be a duplicate`]: `El nombre de límite de velocidad no puede ser un duplicado`,
  [`Rate limit name can't be empty`]: `El nombre de límite de velocidad no puede estar vacío`,
  [`Rate limit overrides`]: `Alteración temporales de límite de velocidad`,
  [`Rate limit time interval can't be 0`]: `El intervalo de tiempo de límite de velocidad no puede ser 0`,
  [`Rate limit time interval can't be empty`]: `El intervalo de tiempo de límite de velocidad no puede estar vacío`,
  [`Rate limit time interval unit must be selected`]: `Debe seleccionarse la unidad de intervalo de tiempo de límite de velocidad`,
  [`Rate limit value can't be empty`]: `El valor de límite de velocidad no puede estar vacío`,
  [`Rate limit value can't be negative`]: `El valor de límite de velocidad no puede ser negativo`,
  [`Re-stage`]: `Volver a desplegar`,
  [`Re-stage product`]: `Volver a desplegar producto`,
  [`Read only`]: `Sólo lectura`,
  [`Read-Only`]: `Sólo lectura`,
  [`Realized`]: `Realizado`,
  [`Reason`]: `Motivo`,
  [`Reason for declining`]: `Motivo del rechazo`,
  [`Recommendations`]: `Recomendaciones`,
  [`Recommended configuration`]: `Configuración recomendada`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Registre el ID y el secreto de cliente que se muestran a continuación para utilizarlos posteriormente. El secreto de cliente no se puede visualizar posteriormente.`,
  [`Recommendations applied`]: `Recomendaciones aplicadas`,
  [`Redact`]: `Redactar`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Redacte o elimine campos específicos del cuerpo de Solicitud, del cuerpo de Respuesta y de los registros de actividad. Defina el campo que se va a redactar o eliminar especificando una expresión XPath.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Redacte o elimine campos específicos del cuerpo de Solicitud, del cuerpo de Respuesta y de los registros de actividad. Defina el campo que se va a redactar o eliminar especificando una expresión XPath o JSONata.`,
  [`Redaction`]: `Redacción`,
  [`Redactions`]: `Redacciones`,
  [`Redirect`]: `Redirigir`,
  [`Redirect URI`]: `URI de redirección`,
  [`Redirect URL`]: `Redirigir URL`,
  [`Redirect through Portal`]: `Redirigir a través de Portal`,
  [`Redirect time limit`]: `Redirigir límite de tiempo`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `El límite de tiempo de redirección debe estar comprendido entre 10 y 600 segundos`,
  [`References`]: `Referencias`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Consulte [IBM Cloud Docs]({link}) para obtener instrucciones adicionales.`,
  [`Referral`]: `Referencia`,
  [`Refresh`]: `Renovar`,
  [`Refresh Token`]: `Señal para renovación`,
  [`Refresh button`]: `Botón Renovar`,
  [`Refresh products`]: `Renovar productos`,
  [`Refresh results from server`]: `Renovar resultados del servidor`,
  [`Refresh token`]: `Renovar señal`,
  [`Refresh token time to live`]: `Tiempo de vida de señal para renovación`,
  [`Refresh token time-to-live`]: `Tiempo de vida de señal de renovación`,
  [`Refresh token time-to-live (seconds)`]: `Tiempo de vida de señal de renovación (segundos)`,
  [`Refresh token TTL has been changed`]: `Se ha cambiado el tiempo de vida de la señal de renovación`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `El tiempo de vida de la señal de renovación es de **{seconds} segundos ({time})**`,
  [`Refresh token time to live is **off**`]: `El tiempo de vida de la señal de renovación es **off**`,
  [`Refresh tokens`]: `Señales para renovación`,
  [`Refreshing`]: `Renovando`,
  [`Refreshing...`]: `Renovando...`,
  [`Region`]: `Región`,
  [`Register`]: `Registrarme`,
  [`Register Gateways`]: `Registrar pasarelas`,
  [`Register Service`]: `Registrar servicio`,
  [`Register gateways`]: `Registrar pasarelas`,
  [`Register service`]: `Registrar servicio`,
  [`Register a service`]: `Registrar un servicio`,
  [`Register new services and manage existing services`]: `Registrar nuevos servicios y gestionar servicios existentes`,
  [`Register remote gateway`]: `Registrar pasarela remota`,
  [`Registered Admin Member`]: `Miembro administrador registrado`,
  [`Registered Provider Org Owner or Member`]: `Propietario o miembro de organización de proveedores registrado`,
  [`Registered client information on the OpenID provider`]: `Información de cliente registrada en el proveedor de OpenID`,
  [`Registration completed successfully`]: `El registro se ha completado satisfactoriamente`,
  [`Registration failed.`]: `El registro ha fallado.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Vías de acceso relativas a puntos finales individuales. Deben ser relativas a 'basePath'.`,
  [`Relaxed Badgerfish`]: `Badgerfish relajado`,
  [`Remove analysis configuration`]: `Eliminar configuración de análisis`,
  [`Remote API Connect`]: `API Connect remoto`,
  [`Remote login`]: `Inicio de sesión remoto`,
  [`Remote publish`]: `Publicación remota`,
  [`Remove`]: `Eliminar`,
  [`Remove Burst Limit`]: `Eliminar límite de ráfaga`,
  [`Remove constraints`]: `Eliminar restricciones`,
  [`Remove Count Limit`]: `Eliminar límite de recuento`,
  [`Remove gateway extension`]: `Eliminar extensión de pasarela`,
  [`Remove product`]: `Eliminar producto`,
  [`Remove Rate limit`]: `Eliminar límite de velocidad`,
  [`Remove the invalid constraints`]: `Eliminar las restricciones no válidas`,
  [`Remove the invalid constraints from`]: `Eliminar las restricciones no válidas de`,
  [`Remove action`]: `Eliminar acción`,
  [`Remove case`]: `Eliminar caso`,
  [`Remove catch`]: `Eliminar captura`,
  [`Remove filter`]: `Eliminar filtro`,
  [`Remove input`]: `Eliminar entrada`,
  [`Remove mapping`]: `Eliminar correlación`,
  [`Remove output`]: `Eliminar salida`,
  [`Remove static value`]: `Eliminar valor estático`,
  [`Removed`]: `Eliminado`,
  [`Removed by user`]: `Eliminado por el usuario`,
  [`Reorder`]: `Reordenar`,
  [`Repeat`]: `Repetir`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Repetir la invocación de la API un número definido de veces, o hasta que se pulse el botón Detener`,
  [`Replace`]: `Sustituir`,
  [`Replace Free Trial with`]: `Sustituir prueba gratuita por`,
  [`Replace Gold with`]: `Sustituir Oro por`,
  [`Replace GraphQL schema`]: `Sustituir esquema GraphQL`,
  [`Replace plans`]: `Sustituir planes`,
  [`Replace private key & public key`]: `Sustituir clave privada y clave pública`,
  [`Replace Product`]: `Sustituir producto`,
  [`Replace product`]: `Sustituir producto`,
  [`Replace schema`]: `Sustituir esquema`,
  [`Replace Silver with`]: `Sustituir Plata por`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Sustituya el propietario actual eligiendo el nuevo propietario en la lista de sugerencias. Asigne permisos al nuevo propietario sustituido para que pueda seguir trabajando.`,
  [`Replace|permission`]: `Sustituir`,
  [`Republish`]: `Volver a publicar`,
  [`Republish product`]: `Volver a publicar producto`,
  [`Request`]: `Solicitar`,
  [`Request catch`]: `Captura de solicitud`,
  [`Request endpoint`]: `Punto final de solicitud`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Solicitud aprobada para {action} producto de API en el catálogo {catalog}`,
  [`Request approved to {{action}} app {{appName}}`]: `Solicitud aprobada para {action} la aplicación {appName}`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Solicitud denegada para {action} producto de API en el catálogo {catalog}`,
  [`Request denied to {{action}} app {{appName}}`]: `Solicitud denegada para {action} la aplicación {appName}`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Solicitud de aprobación para suscribirse a un producto de API en el catálogo {catalog}`,
  [`Request for approval to {{action}} app {{appName}}`]: `Solicitud de aprobación para {action} la aplicación {appName}`,
  [`Request headers`]: `Cabeceras de solicitud`,
  [`Request pending to promote application to production`]: `Solicitud pendiente de promocionar aplicación a producción`,
  [`Request query`]: `Consulta de solicitud`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Solicitud recibida para {action} producto de API en el catálogo {catalog}`,
  [`Request received to {{action}} app {{appName}}`]: `Solicitud recibida para {action} la aplicación {appName}`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Solicitud retirada para suscribirse a un producto de API en el catálogo {catalog}`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Solicitud retirada para {action} un producto de API en el catálogo {catalog}`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Solicitud retirada para {action} la aplicación {appName}`,
  [`Requested approvals`]: `Aprobaciones solicitadas`,
  [`Requested By`]: `Solicitado por`,
  [`Requested To`]: `Solicitado a`,
  [`Require`]: `Requerir`,
  [`Require applications to provide a client ID`]: `Requerir que las aplicaciones proporcionen un ID de cliente`,
  [`Require approval for all new self service development accounts`]: `Requerir aprobación para todas las nuevas cuentas de desarrollo de autoservicio`,
  [`Required`]: `Necesario`,
  [`Required for rate limiting`]: `Necesario para limitación de velocidad`,
  [`Required must be checked off when location is in path.`]: `Necesario no debe estar seleccionado cuando la ubicación está en la vía de acceso.`,
  [`Required path`]: `Vía de acceso necesaria`,
  [`Resend Invite`]: `Reenviar invitación`,
  [`Resend invitation`]: `Reenviar invitación`,
  [`Resend invite`]: `Volver a enviar invitación`,
  [`Reset`]: `Restablecer`,
  [`Reset defaults`]: `Restablecer valores predeterminados`,
  [`Reset Notification template`]: `Restablecer plantilla de notificación`,
  [`Reset password`]: `Restablecer contraseña`,
  [`Reset password TTL has been changed`]: `Se ha cambiado el tiempo de vida de restablecimiento de contraseña`,
  [`Reset password time to live`]: `Tiempo de vida de restablecimiento de contraseña`,
  [`Reset password time-to-live`]: `Tiempo de vida de restablecimiento de contraseña`,
  [`Reset password email has been sent`]: `Se ha enviado el correo electrónico de restablecimiento de contraseña`,
  [`Reset password email has not been sent`]: `No se ha enviado el correo electrónico de restablecimiento de contraseña`,
  [`Reset password invitation timeout`]: `Tiempo de espera de invitación de restablecimiento de contraseña`,
  [`Reset to default preferences`]: `Restablecer a preferencias predeterminadas`,
  [`Resolve API Connect variable references`]: `Resolver referencias de variable de API Connect`,
  [`Resolve XML input data type`]: `Resolver tipo de datos de entrada XML`,
  [`resolver`]: `resolutor`,
  [`Resource group`]: `Grupo de recursos`,
  [`Resource Owner`]: `Propietario del recurso`,
  [`Resource owner password grant`]: `Otorgamiento de contraseña de propietario de recurso`,
  [`Resource owner revocation`]: `Revocación de propietario de recurso`,
  [`Resource owner revocation path`]: `Vía de acceso de revocación de propietario de recurso`,
  [`Resource Owner Security`]: `Seguridad de propietario de recurso`,
  [`Resource owner - JWT`]: `Propietario de recursos - JWT`,
  [`Resource owner - Password`]: `Propietario de recursos - Contraseña`,
  [`Resource owner password`]: `Contraseña de propietario de recurso`,
  [`Resources`]: `Recursos`,
  [`Response`]: `Respuesta`,
  [`Response catch`]: `Captura de respuesta`,
  [`Response Definition`]: `Definición de respuesta`,
  [`Response Time`]: `Tiempo de respuesta`,
  [`Response type`]: `Tipo de respuesta`,
  [`Response context variables`]: `Variables de contexto de respuesta`,
  [`Response headers`]: `Cabeceras de respuesta`,
  [`Response must have a description`]: `La respuesta debe tener una descripción`,
  [`Response must have a schema`]: `La respuesta debe tener un esquema`,
  [`Response must have a status code`]: `La respuesta debe tener un código de estado`,
  [`Response object variable`]: `Variable de objeto de respuesta`,
  [`Response time`]: `Tiempo de respuesta`,
  [`Response time: {{value}}ms`]: `Tiempo de respuesta: {{value}} ms`,
  [`Result of removing`]: `Resultado de la eliminación`,
  [`Restrict HTTP transactions to version 1.0.`]: `Restringir transacciones HTTP a la versión 1.0.`,
  [`Restrict to HTTP 1.0`]: `Restringir a HTTP 1.0`,
  [`Retire`]: `Retirar`,
  [`Retire product`]: `Retirar producto`,
  [`Retired`]: `Retirado`,
  [`Retire|permission`]: `Retirar`,
  [`Return V5 Responses`]: `Respuestas V5 de retorno`,
  [`Retry selected requests`]: `Reintentar solicitudes seleccionadas`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Devolver resultados para consultas de introspección de GraphQL estándar del proxy GraphQL`,
  [`Return third-party access_token`]: `Devolver el valor de access_token de terceros`,
  [`Return third-party access_token with the token response`]: `Se devuelve el valor de la señal de acceso de terceros con la respuesta de señal`,
  [`Return third-party id_token`]: `Devolver el valor de id_token de terceros`,
  [`Return third-party id_token with the token response`]: `Se devuelve el valor de id_token de terceros con la respuesta de señal`,
  [`Return to Login`]: `Volver al Inicio de sesión`,
  [`Reveal in finder`]: `Mostrar en buscador`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Revise y determine qué mostrar u ocultar en el esquema. Puede que sea necesario realizar cambios adicionales para que el esquema siga siendo válido.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Revise todos los avisos. Le ayudaremos posteriormente a mejorar el esquema con recomendaciones inteligentes en el editor.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Revise todos los avisos y aplique las recomendaciones inteligentes para mejorar el esquema y proteger el programa de fondo.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Revise el URL base del punto final de invocación de API y especifique los dominios gestionados por la pasarela.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Revise el tipo de pasarela, el título, el nombre y el resumen. Al invocar los mandatos y las API de API Connect, utilice el nombre de pasarela.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Revise el resumen de los cambios, entre los que puede haber cambios adicionales para que el esquema siga siendo válido.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Revise los avisos que hemos encontrado en el esquema. Le ayudaremos posteriormente a mejorar el esquema y a proteger el programa de fondo con recomendaciones inteligentes en el editor.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Revise los avisos que hemos encontrado en el esquema. Le ayudaremos a mejorar el esquema y a proteger el programa de fondo con recomendaciones inteligentes.`,
  [`Reviewed schema, paths and operations`]: `Esquema, vías de acceso y operaciones revisados`,
  [`Revocation protocol`]: `Protocolo de revocación`,
  [`Revoke token`]: `Revocar señal`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `Documentos de RHEL`,
  [`Role`]: `Rol`,
  [`Role default`]: `Valor predeterminado de rol`,
  [`Role default has been deleted`]: `Se ha suprimido el valor predeterminado de rol`,
  [`Role defaults`]: `Valores predeterminados de rol`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Los valores predeterminados de rol son plantillas de rol preconfiguradas utilizadas para determinar los roles predeterminados que están disponibles cuando se crea una organización.`,
  [`Role has been created`]: `Se ha creado el rol`,
  [`Role has been deleted`]: `Se ha suprimido el rol`,
  [`Role has been updated`]: `Se ha actualizado el rol`,
  [`Roles`]: `Roles`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Los roles (y sus permisos asignados) controlan el acceso a las características de API Connect. Los roles de organización de administración sólo son aplicables a los usuarios que utilizan el Gestor de nube.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Los roles y los permisos controlan el acceso a las prestaciones del Gestor de API.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Los roles y sus permisos asociados controlan el acceso a las funciones y la capacidad de realizar tareas en API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Los roles y sus permisos asociados controlan el acceso a las funciones y la capacidad de realizar tareas en la interfaz de usuario del Gestor de API.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Los roles y sus permisos asociados controlan el acceso a las funciones y la capacidad de realizar tareas.`,
  [`Root`]: `Raíz`,
  [`Root XML Element Name`]: `Nombre de elemento XML raíz`,
  [`Run the following command in the CLI to register the credentials:`]: `Ejecute el mandato siguiente en la CLI para registrar las credenciales:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Ejecute el mandato siguiente en la CLI para registrar las credenciales del kit de herramientas:`,
  [`Running`]: `En ejecución`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Variable de tiempo de ejecución que contiene la JWK que se va a utilizar para descifrar el valor JWT.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Variable de tiempo de ejecución que contiene el valor JWK que se va a utilizar para cifrar la JWT.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Variable de tiempo de ejecución que contiene el valor JWK que se va a utilizar para firmar la JWT.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Variable de tiempo de ejecución que contiene el valor JWK que se va a utilizar para verificar la firma.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Variable de tiempo de ejecución desde la que se puede recuperar un conjunto de reclamaciones JSON válidas.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Variable de tiempo de ejecución desde la que se puede recuperar la serie de la reclamación Pública (aud). Diversas variables se establecen mediante una serie separada por comas.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Variable de tiempo de ejecución desde la que se puede recuperar la serie de la reclamación Emisor (iss). Esta reclamación representa al Principal que ha emitido la JWT.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Variable de tiempo de ejecución desde la que se puede recuperar la serie de la reclamación Asunto (sub).`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Variable de tiempo de ejecución donde se colocará la JWT generada. Si no se establece, la JWT se coloca en la cabecera de autorización como señal portadora.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Variable de tiempo de ejecución que contiene el nombre de usuario autenticado. La señal LTPA se genera con esta propiedad como usuario. Por ejemplo, $(client.app.id) o $(oauth.resource-owner) o una variable de tiempo de ejecución configurada en una política de establecimiento de variable (set-variable).`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `Variable de tiempo de ejecución a la que está asignado el conjunto completo de reclamaciones que se halla en la JWT.`,
  [`Russian`]: `Ruso`,
  [`Scalar`]: `Escalar`,
  [`Schema`]: `Esquema`,
  [`SCHEMA`]: `ESQUEMA`,
  [`SCOPE`]: `ÁMBITO`,
  [`SCOPES`]: `ÁMBITOS`,
  [`SECURITY DEFINITIONS`]: `DEFINICIONES DE SEGURIDAD`,
  [`Service`]: `Servicio`,
  [`Service state:`]: `Estado del servicio:`,
  [`SERVICE TYPE`]: `TIPO DE SERVICIO`,
  [`SIL-OFL 1.1 license`]: `Licencia de SIL-OFL 1.1`,
  [`SOURCE`]: `ORIGEN`,
  [`SSL Profile`]: `Perfil SSL`,
  [`State`]: `Estado`,
  [`STATUS`]: `ESTADO`,
  [`Status Code`]: `Código de estado`,
  [`STATUS CODE`]: `CÓDIGO DE ESTADO`,
  [`SUBMITTED`]: `ENVIADO`,
  [`SUBSCRIBERS`]: `SUSCRIPTORES`,
  [`Summary`]: `Resumen`,
  [`Same as product`]: `Igual que el producto`,
  [`Sample ID token signing key (RS256)`]: `Ejemplo de clave de firma de señal de ID (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Ejemplo de clave de validación de señal de ID (RS256)`,
  [`Sample OAuth symmetric key value`]: `Valor de clave simétrica de OAuth de ejemplo`,
  [`Sample URL: https://api.catalog.org.host/`]: `URL de ejemplo: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `URL de ejemplo: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Descripción de ámbito de ejemplo 1`,
  [`Sandbox - Overview`]: `Recinto de pruebas - Visión general`,
  [`Sandbox Catalog`]: `Catálogo de recinto de pruebas`,
  [`Sandbox Catalog User Registry`]: `Registro de usuarios de catálogo de recinto de pruebas`,
  [`Sandbox Default TLS client profile`]: `Perfil de cliente TLS predeterminado de recinto de pruebas`,
  [`Sandbox Default TLS Client Profile`]: `Perfil de cliente TLS predeterminado de recinto de pruebas`,
  [`Sandbox catalog should always exist in a provider organization.`]: `El catálogo de recinto de pruebas debe existir siempre en una organización de proveedores.`,
  [`Save`]: `Guardar`,
  [`Save API as a new version`]: `Guardar API como una versión nueva`,
  [`Save Product as a new version`]: `Guardar producto como una versión nueva`,
  [`Save and Edit`]: `Guardar y editar`,
  [`Save and republish`]: `Guardar y volver a publicar`,
  [`Save as a new version`]: `Guardar como versión nueva`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Guardar el secreto de cliente (ya no será recuperable a efectos de seguridad)`,
  [`Save this product as a new version`]: `Guardar este producto como versión nueva`,
  [`Saved`]: `Guardado`,
  [`Saving assembly...`]: `Se está guardando el ensamblaje...`,
  [`Saving...`]: `Guardando...`,
  [`Schema Type`]: `Tipo de esquema`,
  [`Schema as JSON`]: `Esquema como JSON`,
  [`Schema as XML`]: `Esquema como XML`,
  [`Schema as YAML`]: `Esquema como YAML`,
  [`Schema change`]: `Cambio de esquema`,
  [`Schema changes`]: `Cambios de esquemas`,
  [`Schema definition circular reference limit`]: `Límite de referencia circular de la definición de esquema`,
  [`Schema name`]: `Nombre de esquema`,
  [`Schema warnings`]: `Avisos de esquema`,
  [`Schema definition`]: `Definición de esquema`,
  [`Scheme`]: `Esquema`,
  [`Schemes`]: `Esquemas`,
  [`Scope`]: `Ámbito`,
  [`Scopes`]: `Ámbitos`,
  [`Scopes cannot be empty`]: `Los ámbitos no pueden estar vacíos`,
  [`Search`]: `Buscar`,
  [`Search DN`]: `Buscar DN`,
  [`Search in the field above above and select to add provider organizations`]: `Busque en el campo que figura más arriba y selecciónelo para añadir organizaciones de proveedores.`,
  [`Search Limit`]: `Límite de búsqueda`,
  [`Search Organization owner from`]: `Buscar propietario de organización desde`,
  [`Search orgs/groups`]: `Buscar organizaciones/grupos`,
  [`Search Owner`]: `Buscar propietario`,
  [`Search assemblies`]: `Buscar ensamblajes`,
  [`Search for`]: `Texto de búsqueda`,
  [`Search for provider organizations`]: `Buscar organizaciones de proveedores`,
  [`Search for Owner`]: `Buscar propietario`,
  [`Search for a new member to become the new owner.`]: `Busque un miembro nuevo para que se convierta en el propietario nuevo.`,
  [`Search for an existing member to become the new owner.`]: `Buscar un miembro existente para que se convierta en un propietario nuevo.`,
  [`Search for new owner`]: `Buscar propietario nuevo`,
  [`Search for owner from:`]: `Buscar el propietario de:`,
  [`Search for the member`]: `Buscar el miembro`,
  [`Search for title or name or version`]: `Buscar título, nombre o versión`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Busque el usuario que desea añadir como miembro y asigne los roles necesarios.`,
  [`Search overflow`]: `Desbordamiento de búsqueda`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Busque en el registro el usuario existente que desea añadir como miembro y asígnele los roles necesarios`,
  [`Search types/fields`]: `Tipos/campos de búsqueda`,
  [`Search user`]: `Buscar usuario`,
  [`Search schema`]: `Buscar esquema`,
  [`Seconds`]: `Segundos`,
  [`Secret Key`]: `Clave secreta`,
  [`Secret Name`]: `Nombre de secreto`,
  [`Secret Required`]: `Secreto necesario`,
  [`Secret key`]: `Clave secreta`,
  [`Secure`]: `Proteger`,
  [`Secure connection`]: `Conexión segura`,
  [`Secure using Client ID`]: `Proteger mediante ID de cliente`,
  [`Security`]: `Seguridad`,
  [`Security & Product Definitions`]: `Definiciones de producto y seguridad`,
  [`Security definitions`]: `Definiciones de seguridad`,
  [`Security and Publish`]: `Seguridad y publicación`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Las definiciones de seguridad controlan el acceso de cliente a los puntos finales de la API, incluidas la validación de claves de API, la autenticación básica a través de un registro de usuarios especificado y OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Las definiciones de seguridad controlan el acceso de cliente a los puntos finales de la API, incluidas la validación de claves de API, la autenticación básica a través de un registro de usuarios especificado y OAuth. [Más información]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Las definiciones de seguridad seleccionadas aquí se aplican en la API, pero se pueden alterar temporalmente para operaciones individuales.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Las definiciones de seguridad seleccionadas aquí se aplican en la API, pero se pueden alterar temporalmente para operaciones individuales. [Más información]({link})`,
  [`Security object to use to decode the claim.`]: `Objeto de seguridad que se va a utilizar para decodificar la reclamación.`,
  [`Security object used to encrypt the JWT.`]: `Objeto de seguridad utilizado para cifrar la JWT.`,
  [`Security object used to sign the JWT.`]: `Objeto de seguridad utilizado para firmar la JWT.`,
  [`Security object used to verify the claim.`]: `Objeto de seguridad utilizado para verificar la reclamación.`,
  [`Select APIs to add to this product`]: `Seleccionar las API que se van a añadir a este producto`,
  [`Select API Type`]: `Seleccionar tipo de API`,
  [`Select API type`]: `Seleccionar tipo de API`,
  [`Select a billing integration in the Product plans.`]: `Seleccione una integración de facturación en los planes de producto.`,
  [`Select a flow...`]: `Seleccione un flujo...`,
  [`Select a plan from your existing product`]: `Seleccione un plan del producto existente`,
  [`Select a policy file to import to this catalog`]: `Seleccione un archivo de política para importar a este catálogo`,
  [`Select a policy implementation file to import to this catalog`]: `Seleccione un archivo de implementación de política para importar a este catálogo`,
  [`Select a product`]: `Seleccionar un producto`,
  [`Select Certificate Manager service`]: `Seleccionar servicio de Certificate Manager`,
  [`Select Cloud`]: `Seleccionar nube`,
  [`Select Email Server`]: `Seleccionar servidor de correo electrónico`,
  [`Select gateway services`]: `Seleccionar servicios de pasarela`,
  [`Select Import Type`]: `Seleccionar tipo de importación`,
  [`Select product`]: `Seleccionar producto`,
  [`Select product type`]: `Seleccionar tipo de producto`,
  [`Select Service`]: `Seleccionar servicio`,
  [`Select Service Type`]: `Seleccionar tipo de servicio`,
  [`Select target endpoints`]: `Seleccionar puntos finales de destino`,
  [`Select target service`]: `Seleccionar servicio de destino`,
  [`Select user registry type`]: `Seleccionar tipo de registro de usuarios`,
  [`Select a WSDL service from the imported file`]: `Seleccionar un servicio WSDL a partir del archivo importado`,
  [`Select a certificate`]: `Seleccionar un certificado`,
  [`Select a Certificate Manager service`]: `Seleccionar un servicio de Certificate Manager`,
  [`Select a cryptographic algorithm.`]: `Seleccione un algoritmo criptográfico.`,
  [`Select a default catalog and organization`]: `Seleccionar un organización y un catálogo predeterminado`,
  [`Select a different cloud`]: `Seleccionar una nube diferente`,
  [`Select a key encryption algorithm.`]: `Seleccione un algoritmo de cifrado de clave.`,
  [`Select a plan to migrate`]: `Seleccionar un plan para migrarlo`,
  [`Select a plan to replace`]: `Seleccionar un plan para sustituirlo`,
  [`Select a plan to supersede`]: `Seleccionar un plan para reemplazarlo`,
  [`Select a product to set as the migration target`]: `Seleccione un producto para establecerlo como destino de migración`,
  [`Select a product to supersede`]: `Seleccione un producto para reemplazar`,
  [`Select a product to supersede {sourceProductName}:`]: `Seleccione un producto para reemplazar {sourceProductName}:`,
  [`Select a resource group`]: `Seleccionar un grupo de recursos`,
  [`Select a service`]: `Seleccionar un servicio`,
  [`Select a supported file ({type}) and try again.`]: `Seleccione un archivo soportado ({type}) y vuelva a intentarlo.`,
  [`Select a target SOAP service`]: `Seleccionar un servicio SOAP de destino`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Seleccione un paquete de kit de herramientas a descargar. Sáltese este paso si ya tiene el kit de herramientas instalado.`,
  [`Select an encryption algorithm.`]: `Seleccione un algoritmo de cifrado.`,
  [`Select an extension file for this gateway`]: `Seleccione un archivo de extensión para esta pasarela`,
  [`Select an org`]: `Seleccione una organización`,
  [`Select catalog`]: `Seleccionar catálogo`,
  [`Select Catalogs to Search`]: `Seleccionar catálogos para buscar`,
  [`Select compatible gateway services`]: `Seleccione un servicio de pasarela compatible`,
  [`Select one Keystore from the list below:`]: `Seleccione un almacén de claves de la lista siguiente:`,
  [`Select one keystore from the list below:`]: `Seleccione un almacén de claves de la lista siguiente:`,
  [`Select one keystore from the list.`]: `Seleccione un almacén de claves de la lista.`,
  [`Select operation...`]: `Seleccionar operación...`,
  [`Select operations you want to include`]: `Seleccione las operaciones que desee incluir`,
  [`Select organization`]: `Seleccionar organización`,
  [`Select the API definition or package file to import`]: `Seleccione la definición de API o el archivo de paquete que importar`,
  [`Select the billing integration for this catalog`]: `Seleccione la integración de facturación para este catálogo`,
  [`Select the billing integration for this product`]: `Seleccione la integración de facturación de este producto`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Seleccione las pasarelas que protegerán las API en un catálogo nuevo. Para registrar una pasarela nueva, visite la página **Pasarelas**.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Seleccione el servicio [Certificate Manager]({link}) que gestiona los certificados para esta pasarela.`,
  [`Select the APIs to add to this product`]: `Seleccione las API que desea añadir a este producto`,
  [`Select the OpenAPI definition file to import`]: `Seleccione el archivo de definición OpenAPI que importar`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Seleccione las acciones de ciclo de vida de producto que requieren aprobación. Cuando se realiza alguna de las acciones seleccionadas, se genera una solicitud de aprobación; la solicitud debe ser aprobada para que la acción pueda realizarse.`,
  [`Select the TLS protocol versions for this profile.`]: `Seleccione las versiones de protocolo TLS para este perfil.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Seleccione el servicio de análisis que desea asociar con el servicio de pasarela`,
  [`Select the consumer organization in which you want to create the application`]: `Seleccione la organización de consumidores en la que desea crear la aplicación`,
  [`Select the consumer organization that will own the application`]: `Seleccione la organización de consumidores que será propietaria de la aplicación`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Seleccione los puntos finales para publicar esta API y definir el límite de velocidad según sea necesario`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Seleccione los errores que, si se generan durante la ejecución de la política, hacen que se detenga el flujo de ensamblaje. Si hay un flujo de captación configurado para el error, se desencadena para manejar el error generado. Si se emite un error y no hay errores seleccionados para el valor de Detener en caso de error, o si el error emitido no es uno de los errores seleccionados, se permite que se complete la ejecución de la política y el flujo de ensamblaje continúa.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Seleccione los servicios de pasarela en los que las API del producto deben estar disponibles.`,
  [`Select the gateway type for this API`]: `Seleccione el tipo de pasarela para esta API`,
  [`Select the gateway type for this OAuth provider`]: `Seleccione el tipo de pasarela para este proveedor de OAuth`,
  [`Select the keystores and truststores for this profile.`]: `Seleccione los almacenes de claves y de confianza para este perfil.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Seleccione las organizaciones o los grupos para los que quisiera hacer visible este producto`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Seleccione las organizaciones o los grupos que quisiera suscribir a este producto`,
  [`Select the portal service to use for this catalog`]: `Seleccione el servicio de portal a utilizar para este catálogo`,
  [`Select the product and plan for the application subscription`]: `Seleccione el producto y el plan para la suscripción de aplicación`,
  [`Select the product definition file to import`]: `Seleccione el archivo de definición de producto que se va a importar`,
  [`Select the product definition file to import from`]: `Seleccione el archivo de definición de producto del que se va a importar`,
  [`Select the product that will supersede the originally selected product`]: `Seleccione el producto que reemplazará al producto seleccionado originalmente`,
  [`Select the product that you want to add the API to`]: `Seleccione el producto al que desea añadir la API`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Seleccione los registros que desea utilizar para controlar el acceso a las API de este catálogo`,
  [`Select the required operations`]: `Seleccione las operaciones necesarias`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Seleccione el nivel de gravedad para los mensajes de registro relacionados con datos de entrada; los niveles posibles son error, aviso e información.`,
  [`Select the target wsdl file to create from`]: `Seleccione el archivo wsdl de destino a partir del cual se va a crear`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Seleccione el archivo wsdl, xsd o zip de destino desde el que se creará la definición de API`,
  [`Select the target WSDL file to import`]: `Seleccione el archivo WSDL de destino que importar`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Seleccione los registros de usuarios para autenticar usuarios del Gestor de API, o cree un registro de usuarios.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Seleccione los registros de usuarios para autenticar usuarios del Gestor de API, o cree un registro de usuarios.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Seleccione los registros de usuarios para autenticar usuarios del Gestor de nube, o cree un registro de usuarios.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Seleccione los registros de usuarios que se utilizan para autenticar el inicio de sesión en el Portal del desarrollador`,
  [`Select the user registry type`]: `Seleccionar el tipo de registro de usuarios`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Seleccione en qué lugar del origen de salida debe colocar la política la señal LTPA generada.  Sólo debe seleccionarse En la cabecera WSSec si el tipo de contenido de la solicitud es application/xml.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Seleccione si se debe eliminar completamente la propiedad o si se debe redactar el campo utilizando caracteres "*" para bloquear los datos.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `El producto seleccionado se ha modificado. Renueve la página y vuelva a intentarlo.`,
  [`Selecting operations from WSDL service definition`]: `Selección de operaciones de definición de servicio de WSDL`,
  [`Self Service Onboarding for Consumer organization`]: `Incorporación de autoservicio para organización de consumidores`,
  [`Self service onboarding`]: `Incorporación de autoservicio`,
  [`Self service onboarding approval`]: `Aprobación de incorporación de autoservicio`,
  [`Send`]: `Enviar`,
  [`Send Message`]: `Enviar mensaje`,
  [`Send Message to Owners`]: `Enviar mensaje a propietarios`,
  [`Send To`]: `Enviar a`,
  [`Send a registration link to a new user to register as owner.`]: `Envíe un enlace de registro a un nuevo usuario para registrarlo como propietario.`,
  [`Send a test email`]: `Enviar un correo electrónico de prueba`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Envíe un correo electrónico para confirmar que el servidor de correo electrónico se ha configurado adecuadamente.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Enviar una señal de prueba para confirmar que el punto final está adecuadamente configurado.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Enviar una señal de prueba para confirmar que el punto final está adecuadamente configurado`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Envíe un mensaje de correo electrónico al propietario de la organización de administración. Ponga una marca de selección junto al nombre del propietario`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Enviar un mensaje de correo electrónico a un propietario. Ponga una marca de selección junto al nombre del propietario`,
  [`Send chunked-encoded documents to the target server.`]: `Enviar documentos codificados y fragmentados al servidor de destino.`,
  [`Send message`]: `Enviar mensaje`,
  [`Send message to owner`]: `Enviar mensaje a propietario`,
  [`Send test email`]: `Enviar correo electrónico de prueba`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Enviar el enlace de activación a un usuario para invitarlo a iniciar la sesión o a registrarse.`,
  [`Send-only`]: `Send-only`,
  [`Sender`]: `Remitente`,
  [`Sender & email server`]: `Remitente y servidor de correo electrónico`,
  [`Sender & email server have been changed`]: `Se han cambiado el remitente y el servidor de correo electrónico`,
  [`Sender Info`]: `Información de remitente`,
  [`Sent`]: `Enviado`,
  [`Sent events: **{sent}**`]: `Sucesos enviados: **{sent}**`,
  [`Serial`]: `Serie`,
  [`Serialize output`]: `Serializar salida`,
  [`Server connection`]: `Conexión con servidor`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `Servir HTML a navegadores web para habilitar el cliente GraphQL de la interfaz gráfica de usuario`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Servir HTML a navegadores web para habilitar el cliente GraphQL de la interfaz gráfica de usuario.`,
  [`Server Name Indication (SNI)`]: `Indicación de nombre de servidor (SNI)`,
  [`Server URL`]: `URL de servidor`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `El servidor ha devuelto un error en hash en lugar de señal en el URL de redirección de OIDC. Hash:`,
  [`Service name`]: `Nombre de servicio`,
  [`Services`]: `Servicios`,
  [`Set`]: `Establecer`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Establezca la variable de entorno de sistema operativo APIC_DESIGNER_CREDENTIALS para el API Designer. Por ejemplo, en MAC, el usuario puede pasar la variable de entorno al iniciar API Designer.`,
  [`Set Migration Target`]: `Establecer destino de migración`,
  [`Set migration target`]: `Definir destino de migración`,
  [`Set Variable`]: `Establecer variable`,
  [`Set as default`]: `Establecer como valor predeterminado`,
  [`Set default`]: `Establecer valor predeterminado`,
  [`Set rate and burst limits in an assembly`]: `Establecer límites de velocidad y ráfaga en un ensamblaje`,
  [`Set the timeout period for invitations`]: `Establecer el periodo de tiempo de espera para las invitaciones`,
  [`Set the trust level for the profile`]: `Establezca el nivel de confianza para el perfil`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Establezca el valor en el número de iteraciones de una definición de esquema circular permitidas (valor predeterminado 1, máximo 5)`,
  [`Set the visibility for a gateway service`]: `Establezca la visibilidad de un servicio de pasarela`,
  [`Set the visibility for a portal service`]: `Establezca la visibilidad de un servicio de portal`,
  [`Set the visibility for each service`]: `Establezca la visibilidad para cada servicio`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Configurar el marco de tiempo para obtener una señal de acceso nueva emitida por el servidor de autenticación`,
  [`Set visibility`]: `Establecer visibilidad`,
  [`Set visibility of gateway`]: `Establecer la visibilidad de la pasarela`,
  [`Set time interval`]: `Establecer un intervalo de tiempo`,
  [`Set, Add, or Clear a runtime variable.`]: `Establecer, añadir o borrar una variable de tiempo de ejecución.`,
  [`Set the timeout period for API key`]: `Establecer el periodo de tiempo de espera para la clave de API`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Establece el periodo de validez en segundos para documentos en la memoria caché. Sólo se aplica si el tipo de memoria caché se establece en "Tiempo de vida". Entre un valor en el rango de 5 a 31708800. El valor predeterminado es 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `El establecimiento de un valor para "assumedSize" no puede combinarse con la definición de un argumento de porcionado que tenga un valor predeterminado o no puede combinarse con la habilitación de "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Si se establece este catálogo como el catálogo predeterminado, se podrá acceder a él utilizando un URL más corto, omitiendo el nombre de catálogo. Sólo puede establecer un catálogo como predeterminado.`,
  [`Settings`]: `Valores`,
  [`Setup`]: `Configuración`,
  [`Severity level for input data log messages`]: `Nivel de gravedad para mensajes de registro de datos de entrada`,
  [`Share the resource with a custom list of provider organizations`]: `Compartir el recurso con una lista personalizada de organizaciones de proveedores`,
  [`Share the resource with provider organizations`]: `Compartir el recurso con organizaciones de proveedores`,
  [`Share the API gateway with a custom list of provider organizations`]: `Compartir la pasarela de API con una lista personalizada de organizaciones de proveedores.`,
  [`Share the service with a custom list of provider organizations`]: `Compartir el servicio con una lista personalizada de organizaciones de proveedores`,
  [`Share the API gateway with provider organizations`]: `Compartir la pasarela de API con organizaciones de proveedores.`,
  [`Share the service with provider organizations`]: `Compartir el servicio con organizaciones de proveedores`,
  [`Share`]: `Compartir`,
  [`Shared`]: `Compartida`,
  [`Shared by`]: `Compartido por`,
  [`Short but descriptive name for this policy.`]: `Nombre abreviado pero descriptivo para esta política.`,
  [`Show`]: `Mostrar`,
  [`Show / hide policy palette`]: `Mostrar / ocultar paleta de política`,
  [`Show/hide`]: `Mostrar/Ocultar`,
  [`Show/hide settings`]: `Mostrar/ocultar valores`,
  [`Show and hide types and fields to include in the schema.`]: `Mostrar y ocultar tipos y campos para incluir en el esquema.`,
  [`Show added only`]: `Mostrar solo añadidos`,
  [`Show all`]: `Mostrar todo`,
  [`Show all UDP versions`]: `Mostrar todas las versiones UDP`,
  [`Show app identifier`]: `Mostrar identificador de aplicación`,
  [`Show breaking changes only`]: `Mostrar solo cambios de última hora`,
  [`Show catalog identifier`]: `Mostrar identificador de catálogo`,
  [`Show catches`]: `Mostrar capturas`,
  [`Show in schema`]: `Mostrar en esquema`,
  [`Show less`]: `Mostrar menos`,
  [`Show modified only`]: `Mostrar solo modificados`,
  [`Show more`]: `Mostrar más`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Mostrar tipos, campos, directivas u argumentos opcionales nuevos. Los elementos que ya no estén soportados por el programa de fondo se eliminarán de todos modos.`,
  [`Show removed only`]: `Mostrar solo eliminados`,
  [`Show schema`]: `Mostrar esquema`,
  [`Show warnings only`]: `Mostrar solo avisos`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Muestra el historial de las asignaciones de almacén de claves excepto el almacén de claves asignado actualmente`,
  [`Sign Crypto Object`]: `Objeto criptográfico de firma`,
  [`Sign in`]: `Iniciar sesión`,
  [`Sign JWK variable name`]: `Nombre de variable JWK de firma`,
  [`Sign Out`]: `Finalizar sesión`,
  [`Sign up`]: `Registrarse`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Inicie sesión mediante el registro de usuario {userRegistryType}`,
  [`Sign in with`]: `Inicie la sesión con`,
  [`Sign out`]: `Salir de sesión`,
  [`Sign up with`]: `Regístrese con`,
  [`Signed token`]: `Señal firmada`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Puesto que el almacenamiento intermedio se ha desactivado, es necesario disminuir el contenido y/o el contenido de error de la carga útil a las cabeceras. ¿Desea continuar con el cambio necesario o cancelar el cambio reciente?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Puesto que el contenido y/o el contenido de error se ha establecido en carga útil, es necesario activar el almacenamiento intermedio. ¿Desea continuar con el cambio necesario o cancelar el cambio reciente?`,
  [`Sized fields`]: `Campos dimensionados`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Argumentos de porcionado`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Algunas entradas son matrices o se hallan dentro de matrices. Para iterar en contenido, elija la opción de iteración y elija la entrada en la que va a realizar la iteración.`,
  [`Something went wrong.`]: `Se ha producido un error.`,
  [`Something went wrong...`]: `Se ha producido un error...`,
  [`Source`]: `Origen`,
  [`Source schema`]: `Esquema de origen`,
  [`Space`]: `Espacio`,
  [`Space is not allowed in scope name`]: `El espacio no está permitido en el nombre de ámbito`,
  [`Space details`]: `Detalles de espacio`,
  [`Space Enabled`]: `Espacio habilitado`,
  [`Space Name`]: `Nombre de espacio`,
  [`Space Summary`]: `Resumen de espacio`,
  [`Space Title`]: `Título de espacio`,
  [`Space owner`]: `Propietario del espacio`,
  [`Space {name} created`]: `Se ha creado el espacio {name}`,
  [`Spaces`]: `Espacios`,
  [`Spaces are enabled`]: `Los espacios están habilitados`,
  [`Spaces are not enabled`]: `Los espacios no están habilitados`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `No se puede habilitar los espacios para el catálogo de recinto de pruebas.`,
  [`Spaces cannot be enabled.`]: `No es posible habilitar espacios.`,
  [`Spaces dropdown`]: `Desplegable de espacios`,
  [`Spaces has been enabled`]: `Se han habilitado espacios`,
  [`Spaces have been enabled.`]: `Se han habilitado espacios.`,
  [`Spaces per page`]: `Espacios por página`,
  [`Spanish`]: `Español`,
  [`Special characters are not accepted`]: `No se aceptan caracteres especiales`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Especifica un URL o una serie que representa un contexto indicado a partir del cual recuperar las propiedades XML o JSON serializadas que se combinan en el objeto dinámico. Estas propiedades son prioritarias sobre cualquier otra propiedad predeterminada o literal existente.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Especifica una serie literal como propiedades serializadas de XML o JSON que se fusionan en el objeto dinámico. Estas propiedades son prioritarias sobre cualquier otra propiedad predeterminada existente.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Especifica un objeto válido existente del que recuperar valores de propiedad predeterminados para el objeto dinámico. Aviso: si utiliza una serie que no sea la predeterminada, el objeto de valor de análisis especificado debe estar disponible en el dispositivo DataPower.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Especifica el origen de datos que incluye el contenido que debe redactarse o eliminarse. Si no se especifica la raíz, la acción se aplica a todo el contexto de API. Puede utilizar cualquier expresión de vía de acceso JSONata soportada.\n\nLa redacción de los datos de solicitud y respuesta puede realizarse con una raíz "message.body". Si se utiliza la acción de ensamblar redactado en un ensamblaje después de una acción de ensamblar registro que especifica la modalidad de sólo recopilación (gather-only), la raíz puede especificar "log.request_body" para la carga útil de solicitud registrada o "log.response_body" para la carga útil de respuesta registrada.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Especifica el nombre de una variable en el contexto de la API. El contenido del campo "body" de la variable es la entrada de la acción. El nombre de la variable predeterminada es "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Especifica el nombre de una variable en el contexto de la API. El contenido del campo "body" de la variable es la salida del análisis. Las métricas de análisis del documento analizado se pueden almacenar en una parte diferente del mensaje. El nombre de la variable predeterminado es lo que sea el nombre de Entrada, así que de forma predeterminada, el mensaje de Entrada se sobrescribirá con el mensaje de Salida.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Especifica si se obtienen metadatos personalizados para la señal de acceso y dónde.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Especifica si desea intentar un análisis con el content-type especificado. Esto solo es aplicable si el content-type esperado es json o xml. Con esta opción habilitada, una acción de análisis provocará un error si content-type no coincide con parse-settings (es decir, Content-type:application/json y parse-settings está configurado para xml). Si parse-setting está configurado para detectar el contenido de entrada, se forzará al content-type especificado.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Especifica si se va a serializar el árbol de salida XSLT en datos binarios después de la transformación.`,
  [`Specify default gateways`]: `Especificar pasarelas predeterminadas`,
  [`Specify email`]: `Especificar correo electrónico`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Especifique una definición de esquema OpenAPI (Swagger) con el que se va a validar la carga útil ("request", "response", "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Especifique el propietario de la organización de consumidores`,
  [`Specify owner of the provider organization`]: `Especifique el propietario de la organización de proveedores`,
  [`Specify settings for the third party OAuth provider.`]: `Especifique valores para el proveedor de OAuth de terceros.`,
  [`Specify the MIME types that your API consumes and produces`]: `Especifique los tipos MIME que la API consume y produce`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Especifique el propietario del catálogo; el propietario tiene todos los permisos de catálogo`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Especifique las organizaciones de consumidores o las comunidades de desarrolladores que podrán ver el producto en el portal de desarrollador`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Especifique las organizaciones de consumidores o las comunidades de desarrolladores que podrán suscribirse para utilizar las API en el producto`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Especifique el URL base del punto final de invocación de API y los dominios gestionados por la pasarela.`,
  [`Specify the details of the catalog that you want to publish to`]: `Especifique los detalles del catálogo en el que desea publicar`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Especifique el conjunto predeterminado de pasarelas que protegerán las API en un catálogo nuevo. [Más información]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Especifique los puntos finales en los que se debe publicar esta API y si desea limitar la velocidad de las llamadas de API`,
  [`Specify the new owner`]: `Especifique el nuevo propietario`,
  [`Specify the owner`]: `Especifique el propietario`,
  [`Specify the owner and title`]: `Especifique el propietario y el título`,
  [`Specify the properties to redact or remove.`]: `Especifique las propiedades que se van a redactar o eliminar.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Especifique los requisitos de publicación y limitación de velocidad de esta API`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Especifique el punto final de comprobación de ámbito donde se realiza la verificación de ámbito adicional, además de los ámbitos definidos.`,
  [`Specify the security settings for the imported API definition`]: `Especifique los valores de seguridad para la definición de API importada`,
  [`Specify the security settings for your API definition`]: `Especifique los valores de seguridad para la definición de API`,
  [`Specify the space owner; the owner has all space permissions`]: `Especifique el propietario del espacio; el propietario tiene todos los permisos de espacio`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Especifique los detalles de resumen de la API y del servicio SOAP de destino; si es necesario, puede seguir configurar la definición de API después de crearla`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Especifique el período de espera después del cual caducan los correos electrónicos de invitación si el usuario no activa su cuenta`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Especifique el período de espera después del cual caducan los correos electrónicos de invitación si el usuario no activa su cuenta.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Especifique el tipo de pasarela, un título y un resumen que describa la pasarela.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Especifique la visibilidad de la pasarela de API a la organización de proveedores de API Connect.`,
  [`Specify the way you want to validate the message.`]: `Especifique la manera en que desea validar el mensaje.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Especifique el URL del punto final de gestión de pasarela y el certificado del dominio del punto final.`,
  [`Specify what content to log in the event of an error.`]: `Especifique qué contenido se debe registrar en caso de un error.`,
  [`Specify what content to log.`]: `Especifique qué contenido se va a registrar.`,
  [`Specifying the publish target details`]: `Especificación de los detalles de destino de publicación`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Permitir específicamente la regla xsi:type=SOAP-ENC:Array`,
  [`Stage`]: `Desplegar`,
  [`Stage API`]: `Desplegar API`,
  [`Stage product`]: `Desplegar producto`,
  [`Stage to`]: `Desplegar en`,
  [`Staged`]: `Desplegado`,
  [`Stage|permission`]: `Desplegar`,
  [`Standard OIDC`]: `OIDC estándar`,
  [`Static DNS`]: `DNS estático`,
  [`Static group`]: `Grupo estático`,
  [`Status`]: `Estado`,
  [`Status code`]: `Código de estado`,
  [`Step 1`]: `Paso 1`,
  [`Step 1: Download`]: `Paso 1: Descargar`,
  [`Step 1: Download toolkit`]: `Paso 1: Descargar kit de herramientas`,
  [`Step 1: Upload private key`]: `Paso 1: Cargar clave privada`,
  [`Step 2`]: `Paso 2`,
  [`Step 2: Download credentials`]: `Paso 2: Descargar credenciales`,
  [`Step 2: Download toolkit credentials`]: `Paso 2: Descargar credenciales de kit de herramientas`,
  [`Step 2: Install`]: `Paso 2: Instalar`,
  [`Step 2: Upload public key`]: `Paso 2: Cargar clave pública`,
  [`Step 3`]: `Paso 3`,
  [`Step 3: Configure`]: `Paso 3: Configurar`,
  [`Step 3: Run command`]: `Paso 3: Ejecutar mandato`,
  [`Step 3: Run commands`]: `Paso 3: Ejecutar mandatos`,
  [`Step 4: Register`]: `Paso 4: Registrar`,
  [`Stop`]: `Detener`,
  [`Stop after:`]: `Después después de:`,
  [`Stop all the retries`]: `Detener todos los reintentos`,
  [`Stop Connection`]: `Detener conexión`,
  [`Stop on error`]: `Detener en caso de error`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Detener la ejecución de esta API y eliminarla del catálogo de recinto de pruebas.`,
  [`Stopped`]: `Detenido`,
  [`Stopping Published API`]: `Deteniendo API publicada`,
  [`Strict`]: `Estricto`,
  [`Strict SOAP envelope version`]: `Versión de sobre de SOAP estricto`,
  [`String Badgerfish`]: `Badgerfish de serie`,
  [`String`]: `Serie`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `Serie incluida en el segmento de organización de URL para llamadas de API. Utilice caracteres alfabéticos en minúsculas, numerales y guiones.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Integración de Stripe`,
  [`Streaming rule`]: `Regla de transmisión`,
  [`Subject`]: `Asunto`,
  [`Subject Claim`]: `Reclamación de asunto`,
  [`Subject Origin`]: `Origen del asunto`,
  [`Subject common name`]: `Nombre común del asunto`,
  [`Subject|certificate`]: `Asunto`,
  [`Submit`]: `Enviar`,
  [`Submitted`]: `Enviado`,
  [`Subscribability`]: `Capacidad de suscripción`,
  [`Subscribability must be disabled if visibility is disabled`]: `La capacidad de suscripción debe estar inhabilitada si la visibilidad está inhabilitada`,
  [`Subscribability options`]: `Opciones de capacidad de suscripción`,
  [`Subscribable by`]: `Suscribible por`,
  [`Subscribe`]: `Suscribirse`,
  [`Subscribe Financial Management App`]: `Suscribir aplicación de gestión financiera`,
  [`Subscribe TODO App`]: `Suscribir POR HACER Aplicación`,
  [`Subscription`]: `Suscripción`,
  [`Subscription approval task for subscription of`]: `Tarea de aprobación de suscripción para la suscripción de`,
  [`Subscription created`]: `Se ha creado una suscripción`,
  [`Subscription has been added`]: `Se ha añadido una suscripción`,
  [`Subscription has not been added`]: `No se ha añadido la suscripción`,
  [`Subscription pending approval`]: `Aprobación pendiente de suscripción`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `El tipo de suscripción se ha eliminado porque las suscripciones de GraphQL no están soportadas.`,
  [`Subscription-Approval`]: `Aprobación de suscripción`,
  [`Subscriptions`]: `Suscripciones`,
  [`Success`]: `Satisfactorio`,
  [`Suffix`]: `Sufijo`,
  [`Suggestion`]: `Sugerencia`,
  [`Supersede`]: `Reemplazar`,
  [`Supersede Product`]: `Reemplazar producto`,
  [`Supersede product`]: `Reemplazar producto`,
  [`Supersede plans`]: `Reemplazar planes`,
  [`Supersede {product1} with {product2}`]: `Reemplazar {product1} con {product2}`,
  [`Supersede|permission`]: `Reemplazar`,
  [`Supersede|supersede with`]: `Reemplazar`,
  [`Support`]: `Soporte`,
  [`support default introspection`]: `admitir introspección predeterminada`,
  [`Support Server Name Indication (SNI)`]: `Soporte de SNI (Indicación de nombre de servidor)`,
  [`Support hybrid response types`]: `Tipos de respuesta híbridos soportados`,
  [`Support standard introspection`]: `Admitir introspección estándar`,
  [`Supported OAuth components`]: `Componentes de OAuth soportados`,
  [`Supported client types`]: `Tipos de cliente admitidos`,
  [`Supported client types can not be empty.`]: `El tipo de cliente admitido no puede estar vacío.`,
  [`Supported grant type can not be empty.`]: `El tipo de concesiones admitidas no puede estar vacío.`,
  [`Supported grant types`]: `Tipos de concesiones admitidas`,
  [`Suspend`]: `Suspender`,
  [`Suspend API`]: `Suspender API`,
  [`Suspend Application`]: `Suspender aplicación`,
  [`Switch`]: `Conmutador`,
  [`Switch account`]: `Cambiar de cuenta`,
  [`Switch cloud connection`]: `Conmutar conexión de nube`,
  [`Switch directory`]: `Conmutar directorio`,
  [`TARGET`]: `DESTINO`,
  [`Title`]: `Título`,
  [`TlS`]: `TlS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `Cliente TLS`,
  [`TLS Client Profile`]: `Perfil de cliente TLS`,
  [`TLS Client Profile has been configured.`]: `El perfil de cliente TLS se ha configurado.`,
  [`TLS client profile is required when endpoint starts with https://`]: `El perfil de cliente TLS es necesario cuando el punto final empieza por https://`,
  [`TLS Client Profiles`]: `Perfiles de cliente TLS`,
  [`TLS client profile has been configured.`]: `El perfil de cliente TLS se ha configurado.`,
  [`TLS client profiles`]: `Perfiles de cliente TLS`,
  [`TLS Profile`]: `Perfil TLS`,
  [`TLS SERVER PROFILE`]: `PERFIL DE SERVIDOR DE TLS`,
  [`TLS server profile`]: `Perfil de servidor TLS`,
  [`TLS version 1.0`]: `TLS versión 1.0`,
  [`TLS version 1.1`]: `TLS versión 1.1`,
  [`TLS version 1.2`]: `TLS versión 1.2`,
  [`TLS version 1.3`]: `TLS versión 1.3`,
  [`TLS client profile`]: `Perfil de cliente TLS`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `Los perfiles de cliente TLS se gestionan en cada espacio cuando se habilitan los espacios. Vaya a los valores de espacio para gestionar los perfiles de cliente TLS.`,
  [`TLS profile`]: `Perfil TLS`,
  [`TLS profile is not visible or not available.`]: `El perfil de TLS no es visible o no está disponible.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `Perfil TLS que se va a utilizar en la transmisión de datos segura al URL de autenticación.`,
  [`Tabs for code language options`]: `Pestañas de opciones de lenguaje de código`,
  [`Truststore`]: `Almacén de confianza`,
  [`truststore`]: `almacén de confianza`,
  [`Type`]: `Tipo`,
  [`Type name`]: `Nombre de tipo`,
  [`Type/Field`]: `Tipo/Campo`,
  [`Type weight`]: `Peso de tipo`,
  [`Types per page:`]: `Tipos por página:`,
  [`Tags`]: `Etiquetas`,
  [`Tags and external documentation`]: `Etiquetas y documentación externa`,
  [`Take offline`]: `Poner fuera de línea`,
  [`Target`]: `Destino`,
  [`Target API (URL)`]: `API de destino (URL)`,
  [`Target SOAP service`]: `Servicio SOAP de destino`,
  [`Target Services`]: `Servicios de destino`,
  [`Target service URL`]: `URL de servicio de destino`,
  [`Target services`]: `Servicios de destino`,
  [`Target URL`]: `URL de destino`,
  [`Target application`]: `Aplicación de destino`,
  [`Target catalog`]: `Catálogo de destino`,
  [`Target gateway service`]: `Servicio de pasarela de destino`,
  [`Target gateway services`]: `Servicios de pasarela de destino`,
  [`Target plan`]: `Plan de destino`,
  [`Target product`]: `Producto de destino`,
  [`Target product rate limit`]: `Límite de velocidad de producto objetivo`,
  [`Target product subscribability`]: `Capacidad de suscripción del producto de destino`,
  [`Target product visibility`]: `Visibilidad del producto de destino`,
  [`Target schema`]: `Esquema de destino`,
  [`Target services have been updated.`]: `Se han actualizado los servicios de destino.`,
  [`Task`]: `Tarea`,
  [`Task has been updated`]: `Se ha actualizado la tarea`,
  [`Task self approval`]: `Autoaprobación de tarea`,
  [`Task self-approval`]: `Autoaprobación de tareas`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Tareas`,
  [`Tasks for creating new provider organizations`]: `Tareas para crear organizaciones de proveedores nuevas`,
  [`TCP`]: `TCP`,
  [`Template`]: `Plantilla`,
  [`Template: {title}`]: `Plantilla: {title}`,
  [`Template: account approved`]: `Plantilla: cuenta aprobada`,
  [`Template: account denied`]: `Plantilla: cuenta denegada`,
  [`Template: account pending approval`]: `Plantilla: cuenta pendiente de aprobación`,
  [`Template: app lifecycle approved`]: `Plantilla: ciclo de vida de aplicación aprobado`,
  [`Template: app lifecycle denied`]: `Plantilla: ciclo de vida de aplicación denegado`,
  [`Template: app lifecycle pending`]: `Plantilla: ciclo de vida de aplicación pendiente`,
  [`Template: app reinstated`]: `Plantilla: aplicación reinstaurada`,
  [`Template: app suspended`]: `Plantilla: aplicación suspendida`,
  [`Template: app lifecycle cancelled`]: `Plantilla: ciclo de vida de aplicación cancelado`,
  [`Template: app lifecycle request`]: `Plantilla: solicitud de ciclo de vida de aplicación`,
  [`Template: invitation`]: `Plantilla: invitación`,
  [`Template: mail server test connection`]: `Plantilla: conexión de prueba de servidor de correo`,
  [`Template: member invitation`]: `Plantilla: invitación de miembro`,
  [`Template: password changed`]: `Plantilla: cambio de contraseña`,
  [`Template: password reset`]: `Plantilla: restablecimiento de contraseña`,
  [`Template: sign up`]: `Plantilla: inicio de sesión`,
  [`Template: task consumer onboard request`]: `Plantilla: solicitud de consumidor de tareas incorporadas`,
  [`Template: task product lifecycle approved`]: `Plantilla: ciclo de vida del producto aprobado`,
  [`Template: task product lifecycle cancelled`]: `Plantilla: ciclo de vida del producto de tarea cancelado`,
  [`Template: task product lifecycle denied`]: `Plantilla: ciclo de vida del producto de tarea denegado`,
  [`Template: task product lifecycle pending`]: `Plantilla: ciclo vital del producto de tarea pendiente`,
  [`Template: task product lifecycle request`]: `Plantilla: solicitud de ciclo de vida del producto de tarea`,
  [`Template: task subscription cancelled`]: `Plantilla: suscripción de tarea cancelada`,
  [`Template: task subscription request`]: `Plantilla: solicitud de suscripción de tarea`,
  [`Template: task subscription approved`]: `Plantilla: suscripción de tarea aprobada`,
  [`Template: task subscription denied`]: `Plantilla: suscripción de tarea denegada`,
  [`Template: task subscription pending`]: `Plantilla: suscripción de tarea pendiente`,
  [`Temporarily disable subscribability`]: `Inhabilitar temporalmente capacidad de suscripción`,
  [`Temporarily disable visibility`]: `Inhabilitar temporalmente visibilidad`,
  [`Terms of service`]: `Condiciones de servicio`,
  [`Test`]: `Probar`,
  [`Test API`]: `API de prueba`,
  [`Test APIs`]: `APIs de prueba`,
  [`Test Bind & Get Base DN`]: `Probar enlace y obtener nombre distinguido base`,
  [`Test configuration`]: `Configuración de prueba`,
  [`Test connection`]: `Conexión de prueba`,
  [`Test connection failed.`]: `La conexión de prueba ha fallado.`,
  [`Test and production endpoints`]: `Puntos finales de prueba y producción`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Probar y supervisar error de respuesta: se necesita la propiedad redirect_uri, se ha obtenido {redirectUri}`,
  [`Test application`]: `Aplicación de prueba`,
  [`Test consumer org`]: `Organización de consumidores de prueba`,
  [`Test email`]: `Probar correo electrónico`,
  [`Test endpoints`]: `Puntos finales de prueba`,
  [`Test message from IBM API Connect`]: `Mensaje de prueba de IBM API Connect`,
  [`Testable`]: `Verificable`,
  [`TEXT`]: `TEXTO`,
  [`The "Definition" property must have a valid value.`]: `La propiedad "Definición" debe tener un valor válido.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `La propiedad "Definición" debe tener un valor de "request", "response" o empezar con "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `La CLI de API Connect se ofrece como un plug-in de la CLI de IBM Cloud. Para empezar:`,
  [`The API has unsaved changes.`]: `La API tiene cambios no guardados.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `La API se ha diseñado completamente y ha pasado un hito interno, pero todavía no se ha implementado.`,
  [`The API is in the implementation phase.`]: `La API está en la fase de implementación.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `La API está en una fase conceptual temprana y no está totalmente diseñada ni implementada.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `La API puede tener cambios no publicados. ¿Desea volver a publicar el producto?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `El esquema de DNS determina la composición de los URL de punto final para las llamadas de API entrantes al servicio de pasarela y para acceder al portal del desarrollador.`,
  [`The GatewayScript source code to execute.`]: `El código fuente de GatewayScript que se va a ejecutar.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `El URL de esquema GraphQL con el que se debe validar un carga útil de GraphQL.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `El método HTTP que se va a utilizar para la invocación. Si se omite o se establece en "Conservar", se utilizará el método de la solicitud entrante.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `El método HTTP que se va a utilizar para sustituir el método HTTP actual. Si se omite o se establece en "Conservar", se utilizará el método de la solicitud entrante.`,
  [`The JavaScript source code to execute.`]: `El código fuente de JavaScript que se va a ejecutar.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `LDAP (Lightweight Directory Access Protocol) es un protocolo Internet para acceder y mantener servicios de información de directorio distribuido a través de una red. Si tiene habilitado un servicio LDAP empresarial, configúrelo como recurso para proporcionar autenticación de usuario.`,
  [`The Proxy URL to be used.`]: `El URL de proxy que se va a utilizar.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `El perfil SSL que se va a utilizar para la transmisión de datos segura.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `El perfil TLS que se va a utilizar para la transmisión de datos segura.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `El URL del esquema JSON para validar una carga útil de JSON.`,
  [`The URL of the XML schema for validating an XML payload.`]: `El URL del esquema XML para validar una carga útil de XML.`,
  [`The URL to be invoked.`]: `El URL que se va a invocar.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `El URL de esquema WSDL con el que se debe validar un carga útil de SOAP.`,
  [`The XML element name to be used for JSON array elements.`]: `El nombre de elemento XML que se utilizará para elementos de matriz JSON.`,
  [`The XSLT source to execute.`]: `El origen de XSLT que se va a ejecutar.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `El enlace de activación se puede enviar a un usuario para permitirle activar la cuenta`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `El servicio de análisis recopila sucesos de API del servicio de pasarela. Cada pasarela tiene un servicio de análisis asociado. Se registra un suceso de API con una indicación de fecha y hora cada vez que se invoca una operación de API.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `El servicio de análisis recopila sucesos de API del servicio de pasarela. Cada servicio de pasarela puede tener un servicio de análisis asociado.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `El URL de autorización es donde la aplicación de cliente obtiene otorgamiento de autorización. El URL de señal es donde la aplicación intercambia un otorgamiento de autorización para una señal de acceso.  El URL de introspección es donde la pasarela de API valida las señales de acceso emitidas por el proveedor de terceros.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `La vía de acceso base es el segmento de URL inicial de la API y no incluye el nombre de host ni ningún segmento adicional para operaciones o vías de acceso`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `El tipo de memoria caché determina si se van a almacenar documentos en memoria caché, respetando o alterando temporalmente las directrices de control de memoria caché de HTTP recibidas de la respuesta del servidor. Esta propiedad solamente entra en vigor cuando se recibe una respuesta del servidor; de lo contrario, la política siempre devuelve la respuesta no caducada que se ha guardado anteriormente en la memoria caché.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `El servicio de Certificate Manager debe gestionar los certificados para el dominio del punto final de gestión de pasarela y los dominios que la pasarela gestiona. Además, debe utilizar IBM Cloud IAM para autorizar al servicio API Connect Reserved Instance a acceder al servicio Certificate Manager.`,
  [`The certificate with which to decrypt the buffer`]: `El certificado con el que descifrar el almacenamiento intermedio`,
  [`The certificate with which to encrypt the buffer`]: `El certificado con el que cifrar el almacenamiento intermedio`,
  [`The change is critical`]: `El cambio es crítico`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Las aplicaciones de los ámbitos solo pueden solicitar los ámbitos o un subconjunto de los ámbitos que se definen aquí. Los ámbitos se incluyen en las señales de acceso generadas por el proveedor. Cuando se invoca una API protegida por OAuth, la pasarela comprueba los ámbitos llevados en las señales de acceso con la lista de ámbitos permitidos en la definición de seguridad para determinar si se debe otorgar acceso. Además, puede imponer la comprobación de ámbito avanzada. Los URL de comprobación de ámbito avanzada se invocan después de la autenticación de la aplicación o después la autenticación del usuario según los URL que se configuren. El permiso de ámbito final otorgado por la señal de acceso es el resultado de todas las comprobaciones de ámbito.`,
  [`The crypto object to use to decode the claim.`]: `El objeto criptográfico que se va a utilizar para decodificar la reclamación.`,
  [`The crypto object to use to encrypt the message.`]: `El objeto criptográfico que se va a utilizar para cifrar el mensaje.`,
  [`The crypto object to use to sign the JWT.`]: `El objeto criptográfico que se va a utilizar para firmar la JWT.`,
  [`The crypto object to use to verify the signature.`]: `El objeto criptográfico que se va a utilizar para verificar la firma.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `LA correlación actual contiene referencias a elementos de esquema no reconocidos`,
  [`The current organization does not contain any catalogs.`]: `La organización actual no contiene catálogos.`,
  [`The default User registry must be included in the list`]: `El Registro de usuarios predeterminado debe estar incluido en la lista`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `La definición contra la que se debe validar la carga útil ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `El portal de desarrollador proporciona la vista de consumidor de los productos de API publicados. Después de haber configurado el portal del desarrollador para el catálogo y publicar uno o varios productos de API, los desarrolladores de aplicaciones pueden examinar y utilizar las API.`,
  [`The document is missing draft_api.info.version field.`]: `Al documento le falta el campo draft_api.info.version.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `Al documento le falta el campo draft_api.info.x-ibm-name.`,
  [`The document is missing draft_product.info.name field.`]: `Al documento le falta el campo draft_product.info.name.`,
  [`The document is missing draft_product.info.version field.`]: `Al documento le falta el campo draft_product.info.version.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `Las restricciones adicionales especifican un argumento de porcionado que no es un argumento en este campo.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Este campo tiene un argumento de límite con un valor predeterminado. Por lo tanto, el tamaño adoptado dado no se puede aplicar nunca.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Este campo tiene un argumento de límite con un valor predeterminado o es obligatorio. Por lo tanto, el tamaño adoptado dado no se puede aplicar nunca.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Este campo no tiene un tamaño adoptado ni argumentos de porcionado.`,
  [`This field returns unbound list of values with composite type.`]: `Este campo devuelve una lista de valores desenlazada con el tipo compuesto.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Este campo devuelve una lista de valores desenlazada con el tipo escalar y el peso de tipo != 0.0.`,
  [`This test panel is deprecated and will be removed soon.`]: `Este panel de prueba está en desuso y se eliminará pronto.`,
  [`This type has no fields or no matching field.`]: `Este tipo no tiene campos o no tiene un campo coincidente.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Esta política GatewayScript no debe utilizar el módulo apim, que solo es para migrar las API antiguas.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Esta política XSLT no debe utilizar el módulo apim, que solo es para migrar las API antiguas.`,
  [`This type will be deleted.`]: `Este tipo se suprimirá.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `El diagrama siguiente muestra el flujo de ensamblaje para la API. Para modificar el ensamblaje, utilice el editor de ensamblaje.`,
  [`The following files are available under the`]: `Los archivos siguientes están disponibles bajo el`,
  [`The following files contain a private key and cannot be uploaded:`]: `Los archivos siguientes contienen una clave privada y no pueden cargarse:`,
  [`The following is available under the`]: `Lo siguiente está disponible bajo el`,
  [`The following lifecycle actions require approval`]: `Las acciones de ciclo de vida siguientes necesitan aprobación`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `La lista siguiente visualiza las API de borrador que se han creado en esta organización de proveedores`,
  [`The following list displays the draft products that have been created in this provider organization`]: `La lista siguiente visualiza los productos de borrador que se han creado en esta organización de proveedores`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `La siguiente lista muestra todos los usuarios que se han añadido como miembros de este catálogo y sus roles asignados`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `La siguiente lista muestra todos los usuarios que se han añadido como miembros de este espacio y sus roles asignados`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `Los parámetros siguientes no coinciden con los valores configurados en el proveedor de OAuth`,
  [`The following roles are available in the Cloud Manager`]: `Los roles siguientes están disponibles en el Gestor de nube`,
  [`The following types/fields will also be removed:`]: `También se eliminarán los tipos/campos siguientes:`,
  [`The following types/fields will be affected:`]: `Se verán afectados los tipos/campos siguientes:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `El valor de propiedad de pasarela {gatewayType} no está permitido. Los valores permitidos son datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `El host utilizado para invocar la API`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `El almacén de claves contiene pares coincidentes de certificados públicos y claves privadas utilizados para confirmar la identidad y cifrar/descifrar la transmisión de datos a través de HTTPS.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `El almacén de claves contiene pares coincidentes de certificados públicos y claves privadas utilizados para confirmar la identidad y cifrar/descifrar la transmisión de datos a través de HTTPS. El perfil de servidor requiere un almacén de claves.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `El almacén de claves contiene las claves públicas y certificados privados presentados por el servidor o el cliente para confirmar su identidad.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `El periodo de tiempo (en segundos) que se añade a la fecha y hora actuales dentro del cual la clave LTPA se considera válida.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `El periodo de tiempo (en segundos), que se añade a la fecha y hora actuales, dentro del cual la JWT se considera válida.`,
  [`The message to be sent with the error`]: `El mensaje que se enviará con el error`,
  [`The message to be included within exception.`]: `El mensaje que se va a incluir en la excepción.`,
  [`The HTTP status code to be included within exception.`]: `El código de estado de HTTP que se va a incluir en la excepción.`,
  [`The HTTP status reason phrase to be included within exception.`]: `La expresión de razón de estado HTTP que se va a incluir en la excepción.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `El nombre de un límite de ráfaga definido en la configuración de datapower.`,
  [`The name of a count limit defined in the datapower configuration.`]: `El nombre de un límite de recuento definido en la configuración de datapower.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `El nombre de un límite de velocidad definido en la configuración de datapower.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `El nombre de una variable que se utilizará para almacenar los datos de respuesta de la solicitud. Se puede hacer referencia más adelante en otras acciones como, por ejemplo, "Correlación".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `El nombre de una variable que se utilizará para almacenar el resultado. De forma predeterminada, se utilizará "message".`,
  [`The name of parameter to find the ID.`]: `El nombre del parámetro para buscar el ID.`,
  [`The name of parameter to find the secret.`]: `El nombre del parámetro para buscar el secreto.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `El nombre de la clave LTPA que desea utilizar para generar la señal LTPA. Si desea utilizar una versión específica de la clave, especifique  <keyname>:<version>. Por ejemplo, my-ltpa-key:1.0.1. Si desea que la política seleccione automáticamente la última versión de la clave que debe utilizarse, especifique <keyname>:latest. Por ejemplo, my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `El nombre del error que se va a generar`,
  [`The name of the variable to be added.`]: `El nombre de la variable que se va a añadir.`,
  [`The name of the variable to be cleared.`]: `El nombre de la variable que se va a borrar.`,
  [`The name of the variable to be set.`]: `El nombre de la variable que se va a establecer.`,
  [`The nature of the XML validation to be performed.`]: `La naturaleza de la validación de XML a realizar.`,
  [`The operation to apply.`]: `La operación que se va a aplicar`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `La salida de esta correlación está dentro de una matriz y su contenido se creará iterando la salida seleccionada. Si la salida seleccionada no es una matriz, se generará una matriz de longitud 1.`,
  [`The owner must be an existing user in your user registry`]: `El propietario debe ser un usuario existente en el registro de usuarios`,
  [`The owner of consumer organization has the following privileges:`]: `El propietario de la organización de consumidores tiene los privilegios siguientes:`,
  [`The owner of provider organization has the following privileges:`]: `El propietario de la organización de proveedores tiene los privilegios siguientes:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Las responsabilidades del propietario son las siguientes: gestionar el ciclo de vida del producto de API, gestionar una o varias organizaciones de proveedores de API, gestionar comunidades de proveedores de aplicaciones y crear definiciones de API y de producto.`,
  [`The page you are looking for might have been moved or does not exist.`]: `Es posible que la página que está buscando se haya movido o ya no exista.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `Es posible que la página que está buscando se haya movido o ya no exista. Póngase en contacto con el administrador para obtener ayuda.`,
  [`The parameters needed to send a valid API call.`]: `Los parámetros necesarios para enviar una llamada de API válida.`,
  [`The password to use for HTTP Basic authentication.`]: `La contraseña que se va a utilizar para la autenticación básica HTTP.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `El servicio de portal proporciona un portal de desarrollador utilizado por los desarrolladores de aplicaciones para descubrir API y consumidores incorporados.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `El servicio de portal proporciona un portal de desarrollador utilizado por los desarrolladores de aplicaciones para descubrir e implementar API.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `El estado de proceso proporciona una vista del estado de una pasarela con un resumen de la actividad reciente`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `El destinatario se registra como propietario y crea una organización de proveedores nueva. Una vez registrado, el usuario puede iniciar sesión en el Gestor de API como propietario de la organización de proveedores.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `El nombre del elemento raíz del documento XML resultante. Solamente es necesario si el documento JSON de entrada no es jerárquico y tiene más de una propiedad de nivel superior.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Los ámbitos sirven aquí para que los desarrolladores sepan a qué tienen acceso y no afectan a la comprobación de ámbito.`,
  [`The selected catalog will only be used to search consumer organizations`]: `El catálogo seleccionado solo se utilizará para buscar organizaciones de consumidores`,
  [`The selected catalog does not have any configured gateways`]: `El catálogo seleccionado no tiene pasarelas configuradas`,
  [`The selected catalog does not have any compatible gateways`]: `El catálogo seleccionado no tiene pasarelas compatibles`,
  [`Save Preferences`]: `Guardar preferencias`,
  [`Preferences`]: `Preferencias`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `El catálogo seleccionado solo se utilizará para buscar organizaciones y grupos de consumidores'`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `El tipo de pasarela seleccionado representará las políticas siguientes en el ensamblaje como no válidas. Deberá suprimir estas políticas para poder ejecutar esta API.`,
  [`The selected product does not contain this API`]: `El producto seleccionado no contiene esta API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `El producto seleccionado será suscribible para una lista específica de organizaciones de consumidores o grupos.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `Los servicios seleccionados estarán disponibles para una lista específica de organizaciones de proveedores.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `Los servicios seleccionados estarán disponibles para todas las organizaciones de proveedores nuevas y existentes.`,
  [`The selected services will not be available to any provider organizations.`]: `Los servicios seleccionados no estarán disponibles para cualquier organización de proveedores.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `La serie que se va a utilizar como clave para la memoria caché. Si se omite, se utiliza la serie URL completa como clave.`,
  [`The server responded with a non-JSON body \n{body}`]: `El servidor ha respondido con un cuerpo no JSON \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `El intervalo de tiempo debe ser superior al tiempo de vida de la señal de acceso.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Esta señal no se almacenará en una cookie ni en almacenamiento local. Si cierra el panel de prueba, la señal se perderá, pero puede ser válida todavía.`,
  [`The transfer protocol of the API.`]: `El protocolo de transferencia de la API.`,
  [`The type of authentication.`]: `El tipo de autenticación.`,
  [`The type of authorization header.`]: `El tipo de cabecera de autorización.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `El tipo de valor que se debe establecer. Puede ser cualquier serie, número o valor booleano.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `El tipo de valor que se debe establecer. Puede ser una serie, un número o un valor booleano.`,
  [`The username to use for HTTP Basic authentication.`]: `El nombre de usuario que se va a utilizar para la autenticación básica HTTP.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `El usuario de esta conexión ya no es válido. Cree una nueva conexión.`,
  [`The value that the variable will be set to.`]: `El valor en el que se establecerá la variable.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `El valor de este campo es una lista del tipo compuesto, que no tiene un tamaño adoptado ni argumentos de porcionado. Si se encuentra, API Gateway no puede establecer un límite sobre el número de objetos que el servidor GraphQL puede devolver si recibe la consulta.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `El valor de este campo es una lista del tipo escalar, que no tiene un tamaño adoptado ni argumentos de porcionado. Si se encuentra, API Gateway no puede establecer un límite sobre el número de objetos que el servidor GraphQL puede devolver si recibe la consulta.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `La versión debe utilizar caracteres reservados para URL. La versión del objeto {version} y la versión legible {slugVersion} no coinciden.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `La propiedad "name" debe ser un URL seguro (el valor de nombre "{name}"  del objeto y el valor de nombre "{slugName}" con slug deben coincidir).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `La propiedad 'draft_api' debe contener una definición de OpenAPI. La versión 3 de OpenAPI solo da soporte a las versiones 3.0.0 - 3.0.3 (versión especificada {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `El campo de nombre se rellena automáticamente en función del título que se proporciona. Utilice el valor resultante en los mandatos de API Connect y las llamadas de API.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `El valor de visibilidad determina qué organizaciones de proveedores pueden acceder a un recurso. Las opciones son: Público (todas las organizaciones de proveedores pueden utilizar el recurso); Personalizado (solo pueden utilizar el recurso las organizaciones de proveedores designadas por usted), y Privado (el recurso no es visible y no lo puede utilizar ninguna organización de proveedores).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `El valor de visibilidad determina qué organizaciones de proveedores pueden utilizar un servicio. Las opciones son: Público (todas las organizaciones de proveedores pueden utilizar el servicio); Personalizado (solo pueden utilizar el servicio las organizaciones de proveedores designadas por usted), y Privado (el servicio no es visible y no lo puede utilizar ninguna organización de proveedores).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `El valor de visibilidad determina qué organizaciones de proveedores pueden utilizar una pasarela. Los niveles de visibilidad son: Público (todas las organizaciones de proveedores pueden utilizar la pasarela); Personalizado (solo pueden utilizar la pasarela las organizaciones de proveedores designadas por usted) y Privado (la pasarela no es visible y no lo puede utilizar ninguna organización de proveedores).`,
  [`The {title} role`]: `El rol {title}`,
  [`The {url} resource does not exist`]: `El recurso {url} no existe`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Hay API incompatibles porque tienen un tipo de pasarela diferente a este producto.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `A continuación, seleccione el certificado para el dominio del punto final de gestión. Seleccione también el paquete de CA correspondiente si el certificado no lo ha asignado una entidad emisora de certificados conocida. El certificado y el paquete deben estar gestionados por el servicio de Certificate Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `A continuación, especifique los dominios que esta pasarela va a gestionar. Se utiliza la técnica SNI; como resultado, se puede dar servicio a servir varios dominios a través de la misma dirección IP y el mismo puerto sin que sea necesario el mismo certificado. Se da soporte al formato de comodín. El dominio (catch-all) predeterminado ‘*’ se debe incluir como la entrada final de la tabla. Especifique otros nombres de dominio según sea necesario, especificando en cada caso un certificado gestionado por el servicio de Certificate Manager.`,
  [`There should be at least {minSelected} {label} selected.`]: `Debe haber como mínimo {minSelected} {label} seleccionado.`,
  [`There are no API changes to publish.`]: `No hay cambios de API que publicar.`,
  [`There are no gateway services`]: `No hay servicios de pasarela`,
  [`There are no assemblies that match the chosen filters.`]: `No hay ningún ensamblaje que coincida con los filtros elegidos.`,
  [`There are no burst limits yet.`]: `Aún no hay límites de ráfaga.`,
  [`There are no catalogs`]: `No hay catálogos`,
  [`There are no consumer organizations.`]: `No hay organizaciones de consumidores.`,
  [`There are no consumer organizations. You can create one`]: `No hay organizaciones de consumidores. Puede crear uno`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `No hay organizaciones de consumidores. Puede crear uno [aquí]({link})`,
  [`There are no email servers`]: `No hay servidores de correo electrónico`,
  [`There are no gateway services configured for the sandbox catalog`]: `No hay servicios de pasarela configurados para el catálogo de recinto de pruebas.`,
  [`There are no lifecycle approvals enabled`]: `No hay aprobaciones de ciclo de vida habilitadas`,
  [`There are no new changes.`]: `No hay cambios nuevos.`,
  [`There are no products to display`]: `No hay productos que mostrar`,
  [`There are no products to replace`]: `No hay productos que sustituir`,
  [`There are no products to set migration target`]: `No hay productos para establecer el destino de la migración`,
  [`There are no products to supersede`]: `No hay productos que reemplazar`,
  [`There are no properties to configure for this policy`]: `Esta política no tiene propiedades para configurar`,
  [`There are no rate limits yet.`]: `Aún no hay límites de velocidad.`,
  [`There are no security definitions`]: `No hay definiciones de seguridad`,
  [`There are no spaces`]: `No hay espacios`,
  [`There are no target services`]: `No hay servicios de destino`,
  [`There are no tasks to be displayed`]: `No hay tareas para visualizar`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Existen cambios sin guardar. ¿Está seguro de que desea continuar?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Existen cambios sin guardar. Se descartarán si continúa. ¿Está seguro?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `Hay una api en el yaml que no existe y no se puede listar; compruebe la pestaña de origen.`,
  [`There is no item in the list.`]: `No hay ningún elemento en la lista.`,
  [`There is no space`]: `No hay ningún espacio`,
  [`There is no warning in the schema.`]: `No hay ningún aviso en el esquema.`,
  [`There was no data found to populate the data table.`]: `No se han encontrado datos para llenar la tabla de datos.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Estos puntos finales se utilizan para todas las API del catálogo`,
  [`Third party OAuth provider`]: `Proveedor de OAuth de terceros`,
  [`Third party OAuth provider summary`]: `Resumen de proveedor de OAuth de terceros`,
  [`Third party`]: `De terceros`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Esta API no contiene un ensamblaje todavía. ¿Desea crear uno?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Este recurso ya no existe, renueve los resultados del servidor.`,
  [`Please refresh the results from the server`]: `Renueve los resultados del servidor`,
  [`Exact match`]: `Coincidencia exacta`,
  [`Closest match`]: `Coincidencia más cercana`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Esta API está siendo utilizada actualmente por el producto {productName}. Suprímala del producto y continúe con la supresión de la API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Esta API estará disponible para su invocación cuando esté habilitada la opción siguiente.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Este catálogo no tiene una suscripción automática habilitada. Para probar en este catálogo, seleccione un producto, un plan y una aplicación abajo.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Este punto final se utiliza como valor predeterminado para las API que no definen un punto final base`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Esta es una característica de avance de novedades tecnológicas que evolucionará y seguirá mejorando en el futuro.`,
  [`This is the currently configured notification server.`]: `Este es el servidor de notificaciones configurado actualmente.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Este es el directorio donde se almacenarán sus archivos, incluyendo las políticas y las extensiones`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `Aquí es donde se ejecutarán las APIs y donde se puede acceder a las políticas y las extensiones`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Esta política de oauth realiza todos los pasos de protocolo de OAuth necesarios para la vía de acceso emitida de forma predeterminada. Las entradas y salidas de cada uno de los pasos se controlan a través de variables de contexto documentadas. Añada o elimine componentes de OAuth soportados según sea necesario.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Esta política de oauth realiza todos los pasos de protocolo de OAuth/OpenID Connect necesarios para la validación de OAuth de forma predeterminada. Las entradas y salidas de cada uno de los pasos se controlan a través de variables de contexto documentadas. Añada o elimine componentes de OAuth soportados según sea necesario.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Esta política de oauth realiza todos los pasos de protocolo de OAuth/OpenID Connect necesarios para todas las demás vías de acceso de forma predeterminada. Las entradas y salidas de cada uno de los pasos se controlan a través de variables de contexto documentadas. Añada o elimine componentes de OAuth soportados según sea necesario.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Esta política de oauth realiza todos los pasos de protocolo de OAuth/OpenID Connect necesarios para la vía de acceso de código az de forma predeterminada. Las entradas y salidas de cada uno de los pasos se controlan a través de variables de contexto documentadas. Añada o elimine componentes de OAuth soportados según sea necesario.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Esta política de oauth realiza todos los pasos de protocolo de OAuth/OpenID Connect necesarios para la vía de acceso de señal de forma predeterminada. Las entradas y salidas de cada uno de los pasos se controlan a través de variables de contexto documentadas. Añada o elimine componentes de OAuth soportados según sea necesario.`,
  [`This operation is secured with access code flow OAuth`]: `Esta operación está protegida por OAuth de flujo de código de acceso`,
  [`This operation is secured with application flow OAuth`]: `Esta operación está protegida por OAuth de flujo de aplicación`,
  [`This operation is secured with basic authentication`]: `Esta operación está protegida por autenticación básica`,
  [`This operation is secured with implicit flow OAuth`]: `Esta operación está protegida por OAuth de flujo implícito`,
  [`This operation is secured with password flow OAuth`]: `Esta operación está protegida por OAuth de flujo de contraseña`,
  [`This policy is not available on the selected gateway`]: `Esta política no está disponible en la pasarela seleccionada`,
  [`This policy is used by one other operation`]: `Esta política es utilizada por otra aplicación`,
  [`This policy is used by {{count}} other operations`]: `Esta política es utilizada por {{count}} otras operaciones`,
  [`This role cannot be edited`]: `Este rol no se puede editar`,
  [`This role cannot be edited.`]: `Este rol no se puede editar.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Este valor controla el comportamiento si se produce un error de correlación porque falta la entrada correspondiente y no se ha configurado ninguna correlación predeterminada. El comportamiento predeterminado consiste en no cambiar la salida, pero si marca este recuadro de selección, se crea un objeto vacío para el padre de la correlación de destino, emulando el comportamiento de la versión 4.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Esta política de seguridad de usuario realiza la comprobación EI(básica) y AU(URL de autenticación) para el ensamblaje de oauth. Cambie el método de comprobación de seguridad según sea necesario`,
  [`This will enable/disable application lifecycle`]: `Esta acción habilitará/inhabilitará el ciclo de vida de la aplicación`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Como resultado, se habilitará/inhabilitará la autoaprobación de tareas. La autoaprobación de tareas permite que las tareas sean aprobadas por su originador, así como por los colaboradores. Esta opción puede resultar conveniente cuando no haya otros aprobadores disponibles, pero efectivamente permite ignorar las comprobaciones realizadas por otro usuario.`,
  [`This will unset the catalog as the default catalog.`]: `Esto desestablecerá el catálogo como el catálogo predeterminado.`,
  [`Throw`]: `Lanzar`,
  [`Time`]: `Hora`,
  [`Time Interval`]: `Intervalo de tiempo`,
  [`Time Limit`]: `Límite de tiempo`,
  [`Time to Live`]: `Tiempo de vida`,
  [`Time to live`]: `Tiempo de vida`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Tiempo de espera antes de obtener respuesta del punto final. El valor predeterminado es de 60 segundos.`,
  [`Timeout`]: `Tiempo de espera`,
  [`To`]: `Para`,
  [`to`]: `a`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Para añadir un usuario como un miembro de la organización de administración, seleccione el registro de usuarios, especifique el nombre de usuario y seleccione los roles que desea asignar. Si el tipo de registro es Registro de usuarios local, también tiene la opción de crear un usuario nuevo que se añade entonces como miembro. La pertenencia se habilita inmediatamente y el usuario especificado puede iniciar sesión en la interfaz de usuario del Gestor de nube`,
  [`To add a user as a member of a provider organization`]: `Para añadir un usuario como miembro de una organización de proveedores`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Para añadir un usuario como un miembro del catálogo, seleccione el nombre de usuario y asigne los roles necesarios. Puede añadir cualquier usuario que ya sea un miembro de otro catálogo o de un espacio, en la organización de proveedores y que actualmente no sea ni un miembro de este catálogo ni el propietario del catálogo.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Para añadir un usuario como un miembro del espacio, seleccione el nombre de usuario y asigne los roles necesarios. Puede añadir cualquier usuario que ya sea un miembro de otro catálogo o de un espacio, en la organización de proveedores y que actualmente no sea ni un miembro de este espacio ni el propietario del espacio.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Para añadir o eliminar miembros, utilice el servicio [identidad y acceso](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Para añadir miembros, utilice el servicio [identidad y acceso](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Para añadir el usuario como un miembro de la organización, seleccione el registro de usuarios, especifique el nombre de usuario y asigne un conjunto de roles.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Para añadir el usuario como un miembro, seleccione su nombre y asígnele un conjunto de roles.`,
  [`To assign default gateways, click **Edit**.`]: `Para asignar pasarelas predeterminadas, pulse **Editar**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Para continuar utilizando este software, debe aceptar los términos del acuerdo de licencia de software.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Para continuar utilizando API Connect, actualice a un plan Enterprise.`,
  [`To get started, you must first enable spaces.`]: `Para empezar, primero debe habilitar espacios.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Para instalar y ejecutar el kit de herramientas, siga estos pasos. [Más información]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Para invitar a un nuevo usuario como miembro de la organización de administración, especifique la dirección de correo electrónico o el nombre del usuario, o seleccione su nombre de un registro de usuarios.  A todos los miembros se les asigna automáticamente el rol de Miembro, que proporciona un conjunto básico de permisos y no se puede suprimir. Si no son necesarios otros permisos, deje sin seleccionar los demás roles.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Para invitar a un propietario que aún no está en el registro de usuarios, especifique su dirección de correo electrónico`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Para registrar una pasarela nueva, visite la página **Pasarelas**.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Para sustituir los valores por una de las propiedades de espacio siguientes, escriba $() con el nombre de la propiedad entre paréntesis.`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Para enviar una invitación de correo electrónico a un usuario nuevo para que se registre como miembro de la organización de administración, especifique una dirección de correo electrónico válida y seleccione los roles que desea asignar al usuario. Se envía un correo electrónico que contiene un enlace de activación a la dirección de correo como invitación para que la persona se registre. El usuario se coloca en estado Pendiente y pasa a estado Habilitado una vez que cumplimenta el formulario de registro para activar la cuenta. A continuación puede iniciar la sesión en la interfaz de usuario del Gestor de nube con el nombre de usuario de API Connect que ha especificado durante la activación de la cuenta.`,
  [`Toggle`]: `Conmutar`,
  [`Toggle application lifecycle?`]: `¿Desea conmutar el ciclo de vida de la aplicación?`,
  [`Toggle default catalog?`]: `¿Desea conmutar el catálogo predeterminado?`,
  [`Toggle info panel between right and bottom`]: `Conmute al panel de información entre la derecha y la parte inferior`,
  [`Toggle profile`]: `Alternar perfil`,
  [`Toggle spaces?`]: `¿Conmutar espacios?`,
  [`Toggle task self-approval?`]: `¿Alternar la aprobación automática de tarea?`,
  [`Token`]: `Señal`,
  [`Token endpoint`]: `Punto final de señal`,
  [`Token Expiry`]: `Caducidad de señal`,
  [`Token Management`]: `Gestión de señales`,
  [`Token Output`]: `Salida de señal`,
  [`Token URL`]: `URL de señal`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `La URL del token tiene que coincidir con {endpoint} tal como lo define el proveedor OAuth "{providerTitle}"`,
  [`Token Version`]: `Versión de señal`,
  [`Token management`]: `Gestión de señales`,
  [`Token management type`]: `Tipo de gestión de señales`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `La gestión de señales permite evitar ataques de reproducción, gestionar señales, incluido si las aplicaciones de cliente o los propietarios de recurso revocan señales de OAuth y cómo lo hacen. API Connect da soporte a la gestión de señales con una pasarela nativa o un punto final de terceros. Con una pasarela nativa se utiliza la imposición de cuota para gestionar señales. Con un punto final de terceros, se proporciona un URL a un servicio externo para gestionar señales.`,
  [`Token path`]: `Vía de acceso de señal`,
  [`Token revocation`]: `Revocación de señal`,
  [`Token secret`]: `Secreto de la señal`,
  [`Token validation`]: `Validación de señal`,
  [`Tokens`]: `Señales`,
  [`Token relay`]: `Retransmisión de señal`,
  [`Toolkit credentials`]: `Credenciales de kit de herramientas`,
  [`Token Relay`]: `Retransmisión de señal`,
  [`Topology`]: `Topología`,
  [`Topology Administrator`]: `Administrador de topología`,
  [`Total time`]: `Tiempo total`,
  [`Transfer Ownership to a New User`]: `Transferir propiedad a un usuario nuevo`,
  [`Transfer Ownership to an Existing User`]: `Transferir propiedad a un usuario existente`,
  [`Transfer ownership`]: `Transferir propiedad`,
  [`Transfer ownership to`]: `Transferir propiedad a`,
  [`Transfer ownership to a new user`]: `Transferir propiedad a un usuario nuevo`,
  [`Transfer ownership to an existing user`]: `Transferir propiedad a un usuario existente`,
  [`Transforms`]: `Transformaciones`,
  [`Truststore Certificates`]: `Certificados de almacén de confianza`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Los certificados de almacén de confianza son certificados públicos emitidos por una autoridad emisora de certificados.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Los almacenes de confianza contienen certificados de confianza que contienen claves públicas verificadas. Los certificados del almacén de confianza se obtienen generalmente de una entidad emisora de certificados (CA) de terceros.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Los almacenes de confianza contienen certificados de confianza con claves públicas verificadas. Los certificados del almacén de confianza se obtienen generalmente de una entidad emisora de certificados (CA) de terceros.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Los almacenes de confianza almacenan certificados de confianza, incluidas las claves públicas verificadas. Los certificados del almacén de confianza se obtienen generalmente de una entidad emisora de certificados (CA) de terceros.`,
  [`Try again or upload a schema`]: `Vuelva a intentarlo o cargue un esquema`,
  [`Try refreshing the page or contacting support.`]: `Intente renovar la página o póngase en contacto con el equipo de soporte.`,
  [`Turkish`]: `Turco`,
  [`Tutorials`]: `Guías de aprendizaje`,
  [`Types`]: `Tipos`,
  [`Type of user`]: `Tipo de usuario`,
  [`Type or select a catalog`]: `Especifique o seleccione un catálogo`,
  [`Type to add organizations`]: `Escriba para añadir organizaciones`,
  [`TypeError: Failed to fetch`]: `TypeError: no se ha podido captar`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `URL del punto final de gestión`,
  [`URLs for all operations in the API begin with this value.`]: `Los URL de todas las operaciones de la API empiezan por este valor.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `Derechos restringidos de los usuarios del Gobierno de los Estados Unidos - El uso, duplicación o divulgación están restringidos por el GSA ADP Schedule Contract con IBM Corp.`,
  [`User Defined`]: `Definido por usuario`,
  [`Userinfo POST`]: `POST de información de usuario`,
  [`USER MANAGED`]: `GESTIONADO POR USUARIO`,
  [`USERNAME`]: `NOMBRE DE USUARIO`,
  [`User can delete the keystore history.`]: `El usuario puede suprimir el historial de almacén de claves.`,
  [`Unable to parse API definition: {msg}`]: `No se puede analizar la definición de API: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `No se puede continuar debido a los errores encontrados al eliminar tipos y/o campos.`,
  [`Unarchive`]: `Eliminación de archivado`,
  [`Unassociate`]: `Desasociar`,
  [`Unassociate analytics service`]: `Desasociar servicio de análisis`,
  [`Unauthorized`]: `No autorizado`,
  [`Unauthorized API Request`]: `Solicitud de API no autorizada`,
  [`Unbound lists`]: `Listas desenlazadas`,
  [`Unit`]: `Unidad`,
  [`Union`]: `Unión`,
  [`Union type`]: `Tipo de unión`,
  [`Unique email address`]: `Dirección exclusiva de correo electrónico`,
  [`Unlimited`]: `Ilimitado`,
  [`Unnecessary assumed size`]: `Tamaño adoptado innecesario`,
  [`Unnecessary slicing arguments`]: `Argumentos de porcionado innecesarios`,
  [`Unrecognized Schema Element(s) Encountered`]: `Se han encontrado elementos de esquema no reconocidos`,
  [`Unsupported media type ({type})`]: `Tipo de soporte ({type}) no admitido`,
  [`Untitled`]: `Sin título`,
  [`Update`]: `Actualizar`,
  [`Update gateway services`]: `Actualizar servicios de pasarela`,
  [`Update assembly`]: `Actualizar ensamblaje`,
  [`Update group failed.`]: `La actualización de grupo ha fallado.`,
  [`Update schema defaults giving preference to`]: `Actualice los valores predeterminados de esquema dando preferencia a`,
  [`Update the sender details that are displayed on invitation emails`]: `Actualice los detalles del remitente que se visualizan en los correos electrónicos de invitación`,
  [`Update the summary details for this role`]: `Actualice los detalles de resumen para este rol`,
  [`Updating Draft API`]: `Actualizando Borrador de API`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `La actualización de los valores predeterminados de esquema alterará temporalmente los cambios. ¿Está seguro de que desea continuar?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `No se permite la actualización de la API de tipo {existingType} con la API de tipo {type}.`,
  [`Upload`]: `Cargar`,
  [`Upload a schema definition language file to replace your schema.`]: `Cargue un archivo de lenguaje de definición de esquema para sustituir el esquema.`,
  [`Upload GraphQL schema`]: `Cargar esquema GraphQL`,
  [`Upload local`]: `Cargar local`,
  [`Upload policies`]: `Cargar políticas`,
  [`Upload policy`]: `Cargar política`,
  [`Upload schema`]: `Cargar esquema`,
  [`Upload schema definition language (SDL) file`]: `Cargar archivo de lenguaje de definición de esquema (SDL)`,
  [`Upload truststore certificates`]: `Cargar certificados de almacén de confianza`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Cargue el certificado X509 para su aplicación en formato PEM. Solo puede cargar un archivo PEM pero puede incluir varios certificados en un único archivo.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Cargue el certificado X509 para su aplicación en formato PEM`,
  [`Upload your public certificate`]: `Cargar el certificado público`,
  [`Uploaded and validated YAML file`]: `Archivo YAML cargado y validado`,
  [`Upload extension`]: `Cargar extensión`,
  [`Use all compatible gateway services`]: `Utilizar todos los servicios de pasarela compatibles`,
  [`Use Content Type`]: `Utilizar tipo de contenido`,
  [`Use DataPower API Gateway`]: `Utilizar pasarela de API de DataPower`,
  [`Use DataPower Gateway`]: `Utilizar la pasarela de DataPower`,
  [`Use Dynamic DNS`]: `Utilizar DNS dinámico`,
  [`Use JSONata to specify the property to redact or remove`]: `Utilice JSONata para especificar la propiedad que se va a redactar o eliminar`,
  [`Use XPath to specify the property to redact or remove.`]: `Utilice XPath para especificar la propiedad que se va a redactar o eliminar.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Utilice un catálogo seleccionado como catálogo predeterminado en el que desea publicar`,
  [`Use an existing JSON or YAML definition of the product`]: `Utilizar una definición de JSON o YAML existente del producto`,
  [`Use an existing definition of a REST proxy`]: `Utilizar una definición existente de un proxy REST`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Utilizar una definición existente de un proxy REST o API SOAP`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Utilizar una definición existente de un proxy REST o un proxy GraphQL o API SOAP`,
  [`Use an existing definition of a product`]: `Utilizar una definición existente de un producto`,
  [`Use context current payload`]: `Utilizar la carga útil actual de contexto`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Utilizar URL de punto final de catálogo personalizado (compatibilidad con v5)`,
  [`Use dynamic DNS`]: `Utilizar DNS dinámico`,
  [`Use endpoint from API`]: `Utilizar el punto final desde la API`,
  [`Use gateway URLs`]: `Utilizar URL de pasarela`,
  [`Use gateway service configured OAuth shared secret key`]: `Utilizar la clave secreta compartida de OAuth configurada por el servicio de pasarela`,
  [`Use group authentication`]: `Utilizar grupo de autenticación`,
  [`Use only first array element`]: `Utilizar solo el primer elemento de matriz`,
  [`Use static DNS`]: `Utilizar DNS estático`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Utilice el portal como punto final para el tráfico de proveedor de OIDC externo`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Utilice el Explorador de API para probar y explorar las APIs. El explorador muestra las operaciones, las definiciones y la documentación de todas las APIs contenidas en el directorio del proyecto. El explorador lista los parámetros, los datos de instancia de modelo y los códigos de respuesta conjuntamente con el código de plantilla para ejecutar la API.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Utilice el menú de acciones para gestionar y publicar las API.`,
  [`Use the actions menu to manage and publish your products`]: `Utilice el menú de acciones para gestionar y publicar los productos.`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Utilice la política de registro de actividad para configurar sus preferencias de registro para la actividad de la API que se ha almacenado en Analítica. Las preferencias que especifique van a alterar temporalmente los valores predeterminados en cuanto a la recopilación y almacenado de detalles de la actividad de la API.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Utilice la política de seguridad de cliente para extraer y autenticar las credenciales de los clientes.`,
  [`Use the default built-in Sandbox Catalog`]: `Utilizar el catálogo de recinto de seguridad incorporado predeterminado`,
  [`Use the editor for manual input or upload a file below.`]: `Utilice el editor para la entrada manual o cargue un archivo a continuación.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Utilice el editor para la entrada manual o cargue un archivo a continuación. El esquema actual solo se puede editar en XML si se ha definido un archivo XML/XSD.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Utilice la extensión impuesta para especificar si la pasarela de API Connect se utiliza para imponer la API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Utilice el URL de punto final de gestión siguiente cuando trabaje con la CLI.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Utilice el URL de punto final de gestión siguiente cuando cree una conexión de nube en API Designer.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Utilice la política gatewayscript para ejecutar un programa DataPower GatewayScript especificado.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Utilice la política graphql-introspect para realizar una introspección de un esquema de GraphQL.`,
  [`Use the host value from Open API Definition`]: `Utilice el valor de host de la definición de Open API`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Utilice la política if para aplicar una sección del ensamblaje cuando se cumple una condición.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Utilice la política javascript para ejecutar un programa JavaScript especificado.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Utilice la política json-to-xml para convertir el contenido de su carga útil de JSON a XML.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Utilice la política de registro para personalizar o alterar temporalmente la configuración del registro de actividad predeterminada para una API.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Utilice la política de correlación para aplicar transformaciones al flujo de ensamblaje y especificar relaciones entre variables.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Utilice la política de correlación para especificar las relaciones entre variables en su flujo de API y para aplicar transformaciones a estas variables.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Utilice la política oauth para generar señales de OAuth.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Utilice la política de conmutador de operación para aplicar una sección del ensamblaje a una operación específica.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Utilice la política de análisis para analizar una solicitud o un mensaje como XML o como JSON, o para analizar datos binarios en un objeto binario grande (BLOB).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Utilice la política de variables establecidas para definir una variable de tiempo de ejecución para un valor de serie o para añadir o borrar una variable de tiempo de ejecución.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Utilice la política throw para emitir un error cuando se alcanza durante la ejecución de un flujo de ensamblaje.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Utilice la política de seguridad de usuario para extraer, autenticar y autorizar la identidad del usuario.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Utilice la política de validación con una pasarela de DataPower para validar la carga útil en un flujo de ensamblaje contra un esquema JSON o XML.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Utilice la política xml-to-json para convertir el contenido de su carga útil de XML a JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Utilice la política xslt para aplicar una transformación XSLT a la carga útil.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Se utiliza para habilitar/inhabilitar la compresión Contenido-Codificación en la carga.`,
  [`User`]: `Usuario`,
  [`User group prefix`]: `Prefijo de grupo de usuarios`,
  [`User group suffix`]: `Sufijo de grupo de usuarios`,
  [`User mapping`]: `Correlación de usuarios`,
  [`User Registries`]: `Registros de usuarios`,
  [`User registries`]: `Registros de usuario`,
  [`User Registry Name`]: `Nombre del registro de usuarios`,
  [`User registry name`]: `Nombre del registro de usuarios`,
  [`User Registry cannot be deleted`]: `No se puede suprimir el registro de usuarios`,
  [`User Registry "{name}" has been deleted`]: `El registro de usuarios "{name}" se ha suprimido`,
  [`User security`]: `Seguridad de usuario`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Los registros se gestionan en cada espacio cuando se habilitan los espacios. Vaya a los valores de espacio para gestionar registros de usuario.`,
  [`User registries defined for consumer onboarding`]: `Registros de usuario definidos para la incorporación de consumidores`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Registros de usuarios definidos para la incorporación de los consumidores\n\nCada cuenta en el Portal del Desarrollador, incluso a través de diferentes registros de usuarios para el mismo sitio, debe tener una dirección de correo electrónico única, incluyendo la cuenta del Administrador del sitio.`,
  [`User registry`]: `Registro de usuarios`,
  [`User registry configuration has been updated.`]: `Se ha actualizado la configuración del registro de usuarios.`,
  [`User registry has been created`]: `Se ha creado el registro de usuarios`,
  [`User registry {title} deleted.`]: `Se ha suprimido el registro de usuarios {title}.`,
  [`User registry {title} has been created.`]: `Se ha creado el registro de usuarios {title}.`,
  [`User registry is not visible or not available.`]: `El registro de usuarios no es visible o no está disponible.`,
  [`User registry {title} has been updated.`]: `Se ha actualizado el registro de usuarios {title}.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `Los valores de seguridad de usuario solo son aplicables si los tipos de otorgamiento seleccionados incluyen uno o varios de los siguientes: Implícito, Código de acceso y Propietario de recursos - Contraseña.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `Los valores de seguridad de usuario solo son aplicables si los tipos de otorgamiento seleccionados incluyen uno o varios de los siguientes: Implícito, Código de acceso y Propietario de recursos - Contraseña. Si realiza cambios en esta página, también debe seleccionar, en la Configuración, como mínimo uno de estos tipos de otorgamiento.`,
  [`UserInfo endpoint`]: `Punto final de UserInfo`,
  [`UserInfo URL`]: `URL de información de usuario`,
  [`Username`]: `Nombre de usuario`,
  [`Username / Email`]: `Nombre de usuario / Correo electrónico`,
  [`Username and password fields are required in order to test configuration`]: `Los campos de nombre de usuario y contraseña son necesarios para probar la configuración`,
  [`Username context variable`]: `Variable de contexto de nombre de usuario`,
  [`Username of the new user`]: `Nombre de usuario del usuario nuevo`,
  [`Username should not contain special characters!`]: `¡El nombre de usuario no debe contener caracteres especiales!`,
  [`Username should not include spaces!`]: `¡El nombre de usuario no debe incluir espacios!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Los usuarios que pertenecen a una organización pueden acceder al portal de desarrollador y los correspondientes productos de API publicados, según queda determinado por su visibilidad y capacidad de suscripción.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Mediante [IBM Cloud IAM]({link}), autorice al servicio API Connect Reserved Instance a acceder al servicio Certificate Manager.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Utilización del método HTTP POST al contactar con el punto final de información del usuario`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Utilizar el valor de caducidad de señal de IBM APIc desde la nube`,
  [`Update API`]: `Actualizar API`,
  [`VALUE`]: `VALOR`,
  [`Version`]: `Versión`,
  [`Visible to`]: `Visible para`,
  [`Validate`]: `Validar`,
  [`Validate message body`]: `Validar cuerpo del mensaje`,
  [`Validate message headers`]: `Validar cabeceras de mensaje`,
  [`Validate message fault details`]: `Validar detalles de error de mensaje`,
  [`Validate SOAP 1.1 encoding rule`]: `Validar regla de codificación SOAP 1.1`,
  [`Validate JSON Web Token (JWT)`]: `Validar la señal web JSON (JWT)`,
  [`Validate JWT`]: `Validar JWT`,
  [`Validate Username Token`]: `Validar señal de nombre de usuario`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Validar carga útil de UsernameToken de seguridad de servicios web usando un URL de autenticación o un registro de usuarios LDAP.`,
  [`Validate against`]: `Validar contra`,
  [`Validate request`]: `Validar solicitud`,
  [`Validate the GraphQL query and analyze the expected cost`]: `Validar la consulta de GraphQL y analizar el coste esperado`,
  [`Validate the returned JSON and analyze the real cost`]: `Validar el JSON devuelto y analizar el coste real`,
  [`Validating API ...`]: `Validando API ...`,
  [`Validating Draft API`]: `Validando Borrador de API`,
  [`Validity Period`]: `Periodo de validez`,
  [`Value`]: `Valor`,
  [`Value for located in must be selected.`]: `Es necesario seleccionar un valor para Ubicado en.`,
  [`Value for the variable to be added.`]: `El valor de la variable que se añadirá.`,
  [`Value for type must be selected.`]: `Es necesario seleccionar un valor para Tipo.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `El valor del argumento "weight" en la directiva "@cost" es < 0.`,
  [`Values`]: `Valores`,
  [`Vanity API endpoint`]: `Punto final de API de vanidad`,
  [`Variable name`]: `Nombre de variable`,
  [`Variable value`]: `Valor de la variable`,
  [`Vendor Extensions`]: `Extensión de proveedor`,
  [`Verb`]: `Verbo`,
  [`Verify`]: `Verificar`,
  [`Verify Access Token`]: `Verificar señal de acceso`,
  [`Verify Crypto JWK variable name`]: `Nombre de la variable JWK criptográfica de verificación`,
  [`Verify Crypto Object`]: `Objeto criptográfico de verificación`,
  [`Verify Token`]: `Verificar señal`,
  [`Verify authorization code`]: `Verificar código de autorización`,
  [`Verify refresh token`]: `Verificar señal de renovación`,
  [`Version of the LTPA token.`]: `Versión de la señal LTPA.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Compruebe la propiedad 'apis' bajo 'planes' en el archivo de producto importado. Cada plan debe tener una propiedad 'apis' que apunte al menos a una API de la sección 'apis'.`,
  [`View`]: `Ver`,
  [`View extensions`]: `Ver extensiones`,
  [`View Forum`]: `Ver foro`,
  [`View owners`]: `Ver propietarios`,
  [`View policies`]: `Ver políticas`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Ver y gestionar los cifrados disponibles para la versión de protocolo seleccionada.`,
  [`View analytics`]: `Ver análisis`,
  [`View and edit permissions for the roles in this space`]: `Ver y editar los permisos para los roles de este espacio`,
  [`View and edit the permissions for the roles in the current organization`]: `Ver y editar los permisos para los roles de la organización actual`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Ver y editar los roles contenidos en los valores predeterminados de rol para las organizaciones de consumidores`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Ver y editar los roles contenidos en los valores predeterminados de rol para las organizaciones de proveedores`,
  [`View approval history`]: `Ver el historial de aprobaciones`,
  [`View Approval History`]: `Ver el historial de aprobaciones`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Ver la documentación y las guías de aprendizaje con instrucciones paso a paso.`,
  [`View error ID`]: `Ver ID de error`,
  [`View status`]: `Ver estado`,
  [`View more`]: `Ver más`,
  [`View source`]: `Ver origen`,
  [`View subscription`]: `Ver suscripción`,
  [`View template in`]: `Ver plantilla en`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Visualice los puntos finales para acceder a las interfaces de usuario de API Connect.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Vea los puntos finales para las API REST de administrador y proveedor.`,
  [`View the endpoints for the consumer REST APIs.`]: `Vea los puntos finales para las API REST de consumidor.`,
  [`Viewer`]: `Visor`,
  [`Views the API consumer organization`]: `Visualiza la organización de consumidores de API`,
  [`Views the API provider organization`]: `Visualiza la organización de proveedores de API`,
  [`Views the admin organization`]: `Visualiza la organización de administración`,
  [`Views the app developer organization`]: `Visualiza la organización de desarrolladores de aplicaciones.`,
  [`Views the catalog`]: `Visualiza el catálogo`,
  [`Views the space`]: `Visualiza el espacio`,
  [`View|permission`]: `Ver`,
  [`Visibility`]: `Visibilidad`,
  [`Visibility options`]: `Opciones de visibilidad`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `Documentos de VMware`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `URL de WSDL`,
  [`WSDL has been successfully validated`]: `El WSDL se ha validado satisfactoriamente`,
  [`WSDL to REST`]: `WSDL a REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `Validación de perfil básico de WS-I`,
  [`Warn`]: `Avisos`,
  [`Warning`]: `Aviso`,
  [`Warning:`]: `Aviso:`,
  [`Warnings`]: `Advertencias`,
  [`We just emailed recipients a test message:`]: `Acabamos de enviar por correo electrónico un mensaje de prueba a los destinatarios:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `Se está trasladando a la pestaña "Probar", donde podrá probar y depurar la API.`,
  [`Weather Product`]: `Producto de meteorología`,
  [`Web Endpoint`]: `Punto final web`,
  [`Web service operations`]: `Operaciones de los servicios web`,
  [`weight`]: `Peso`,
  [`Weight`]: `Peso`,
  [`Welcome to API Connect Administration`]: `Bienvenido a API Connect Administration`,
  [`Welcome to API Connect Reserved`]: `Bienvenido a API Connect Reserved`,
  [`Welcome to API Designer`]: `Bienvenido al Diseñador de API`,
  [`Welcome to API Manager`]: `Bienvenido al Gestor de API`,
  [`Welcome to Cloud Manager`]: `Bienvenido al Gestor de nube`,
  [`Welcome to the API Designer`]: `Bienvenido al Diseñador de API`,
  [`Welcome to the API Manager`]: `Bienvenido al Gestor de API`,
  [`Welcome to the Cloud Manager`]: `Bienvenido al Gestor de nube`,
  [`What are you looking for today?`]: `¿Qué está buscando hoy?`,
  [`What's new`]: `Novedades`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `¿Qué es lo siguiente? Habilite la integración de la facturación en un catálogo para permitir a los usuarios añadir planes a sus productos.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `Cuando esta opción se establece en true, la política de invocación inyectará las cabeceras "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" en la solicitud enviada al URL de destino.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Cuando se publica, un producto de API se hace visible en el portal de desarrollador asociado al catálogo. Los puntos finales de las API del producto están disponibles en pasarelas asociadas al catálogo o espacio de destino. Los productos desplegados están presentes en el catálogo pero no están activos ni son visibles para los consumidores.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Cuando se publica, un producto de API se hace visible en el portal de desarrollador asociado al catálogo. Los puntos finales de las API del producto están disponibles en pasarelas asociadas al catálogo o espacio de destino. Los productos desplegados están presentes en el catálogo pero no están activos ni son visibles para los consumidores. Tenga en cuenta que al volver a desplegar / publicar un producto en un catálogo de producción se eliminarán las suscripciones existentes.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Cuando los espacios están habilitados para un catálogo, el proveedor de OAuth configurado debe visualizarse desde un espacio.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Cuando los espacios están habilitados, los productos existentes en el catálogo se trasladan a un espacio predeterminado.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Cuando el usuario pulsa el enlace de activación, se le solicita que cumplimente el formulario de registro. Después del registro, el usuario puede iniciar la sesión.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Cuando añada un rol para una organización de proveedores o consumidores, se incluirá en el valor predeterminado de rol. Los valores predeterminados de rol son plantillas de rol preconfiguradas utilizadas para determinar los roles predeterminados que están disponibles cuando se crea una organización.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Cuando un producto queda en desuso, los desarrolladores de aplicaciones que ya están suscritos al producto pueden seguir usándolo, pero ningún desarrollador nuevo puede suscribirse al producto.\n\n [Más información]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Cuando edite un rol para una organización de proveedores o consumidores, se incluirá en el valor predeterminado de rol. Los valores predeterminados de rol son plantillas de rol preconfiguradas utilizadas para determinar los roles predeterminados que están disponibles cuando se crea una organización.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Cuando habilita OpenID Connect, se proporciona una plantilla para generar señales de ID conjuntamente con las señales de acceso y las políticas de ensamblaje se crean automáticamente. Puede personalizar las políticas para que se ajusten a sus necesidades. La clave de ejemplo solo es para pruebas y se utiliza para firmar la señal JWT.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Cuando habilita la introspección de señales, la señal de acceso se puede examinar a través de la vía de acceso de introspección.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Cuando se habilita la introspección de señales, los poseedores de señales de acceso pueden examinar el contenido de las señales mediante una vía de acceso de introspección. La señal de acceso para realizar la introspección se debe obtener a través del proveedor de OAuth nativo.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Cuando se invita a un usuario a ser miembro de una organización de proveedores, se le asignan uno o varios roles para controlar su nivel de acceso a la información y las tareas que puede realizar.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Al gestionar las versiones del producto, éstas pasan por una serie de estados de ciclo de vida, desde el despliegue inicial de una versión de producto en un catálogo, a la publicación para poner la versión del producto a disposición de los desarrolladores de aplicaciones, y a la eventual retirada y archivado.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Al sustituir un producto por otro, se realizan las acciones siguientes: 1) Se publica el producto de sustitución, 2) Se utilizan los mismos valores de obligatoriedad de visibilidad, suscriptor y pasarela del producto original en el producto de sustitución, 3) Los suscriptores al producto original se migran al producto de sustitución.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Cuando sustituye un producto por otro, se realizan las acciones siguientes: 1) el producto de sustitución asume el estado del producto original, 2) si los valores de visibilidad y capacidad de suscripción del producto de sustitución son tales que el acceso es el mismo o menos restrictivo que el producto original, se utilizan los valores del producto de sustitución. Si los valores del producto de sustitución son más restrictivos e impedirían que algunas organizaciones de consumidores con acceso al producto original accedieran a la sustitución, la operación de sustitución no se puede completar. 3) los suscriptores del producto original se migran al producto de sustitución, y (4) el producto original se retira.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Cuando se retira un producto, todas las API asociadas se sitúan fuera de línea y las suscripciones quedan inactivas.\n\n [Más información]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Al reemplazar un producto por otro, se realizan las acciones siguientes: 1) Se publica el producto de reemplazo, 2) Se utilizan los mismos valores de obligatoriedad de visibilidad, suscriptor y pasarela del producto original en el producto de reemplazo, 3) El producto original se sitúa en estado En desuso. Cuando un producto queda en desuso, los desarrolladores de aplicaciones que ya están suscritos al producto pueden seguir usándolo, pero ningún desarrollador nuevo puede suscribirse al producto. Un producto en desuso puede publicarse de nuevo si es necesario.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Al reemplazar un producto por otro, se realizan las acciones siguientes: 1) se publica el producto de reemplazo, 2) los valores de visibilidad y capacidad de suscripción del producto original se utilizan en el producto de reemplazo y 3) el producto original se pasa al estado en desuso.  Cuando un producto queda en desuso, los desarrolladores de aplicaciones que ya están suscritos al producto pueden seguir usándolo, pero ningún desarrollador nuevo puede suscribirse al producto.  Un producto en desuso se puede publicar de nuevo si es necesario.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Cuando se suspende una aplicación, los ID de cliente asociados quedan bloqueadas para hacer llamadas de API. Puede reanudar la aplicación en cualquier momento.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Cuando está habilitado, el tiempo de espera de invitación de todas las organizaciones de consumidores se establecerá en el tiempo de espera de invitación de catálogo y cualquier actualización al tiempo de espera de invitación de catálogo actualizará el tiempo de espera de invitación de las organizaciones`,
  [`Where did the roles go?`]: `¿Dónde están los roles?`,
  [`Where to find the named rate limit.`]: `Donde hallar el límite de velocidad en cuestión.`,
  [`Where to put the decrypted result`]: `Dónde colocar el resultado descifrado`,
  [`Where to put the encrypted result`]: `Dónde colocar el resultado cifrado`,
  [`Whether to reuse the connection to the target server.`]: `Indica si se debe reutilizar la conexión con el servidor de destino.`,
  [`Windows live`]: `Windows Live`,
  [`With`]: `Por`,
  [`Wildcards ignore xsi:type rule`]: `Regla xsi:type para ignorar caracteres comodín`,
  [`Work offline`]: `Trabajar desconectado`,
  [`Work with existing definitions, models and/or data sources`]: `Trabajar con definiciones, modelos y orígenes de datos existentes`,
  [`Working...`]: `Trabajando...`,
  [`Wrapper Policies`]: `Políticas de derivador`,
  [`Wrong slicing arguments`]: `Argumentos de porcionado equivocados`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL y XSDs deben estar dentro de su propio archivo zip dentro del archivo zip que está intentando importar.`,
  [`XML Schema`]: `Esquema XML`,
  [`XML Schema URL`]: `URL de esquema XML`,
  [`XML Schema preview`]: `Vista previa de esquema XML`,
  [`XML Validation Mode`]: `Modalidad de validación de XML`,
  [`XML schema is not valid. Please correct the error.`]: `El esquema XML no es válido. Corrija el error.`,
  [`XML to JSON`]: `XML a JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `Versión de XSLT`,
  [`YAML has been successfully validated`]: `El YAML se ha validado satisfactoriamente`,
  [`Yes`]: `Sí`,
  [`Yes, enable it`]: `Sí, habilitar`,
  [`Yes, reset`]: `Sí, restablecer`,
  [`Yes. Update API Assembly`]: `Sí. Actualizar el ensamblaje de API`,
  [`You are authenticated!`]: `¡Ha sido autenticado!`,
  [`You do not have permission to use the Test Application`]: `No tiene permiso para utilizar la aplicación de prueba`,
  [`You can also copy the link and send it directly to the user.`]: `También puede copiar el enlace y enviarlo directamente al usuario.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `Puede optar por no publicar la API en este momento o puede publicar inmediatamente en los puntos finales de prueba o producción o en ambos. También puede especificar una velocidad máxima de llamada de API permitida en función de un número de llamadas durante un intervalo de tiempo especificado.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `Puede optar por no publicar la API en este momento o puede publicar inmediatamente en los puntos finales de prueba o producción o en ambos. También puede especificar una velocidad máxima de llamada de API permitida en función de un número de llamadas por intervalo de tiempo especificado.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `Puede optar por dejar la API sin proteger en este momento o puede requerir que una aplicación de llamada debe proporcionar un ID de cliente. Puede seguir configurando las opciones de seguridad después de haber creado la definición de API.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `Puede especificar un nombre de catálogo o título. El nombre de catálogo tendrá prioridad si ambos están disponibles.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `Puede enviar un correo electrónico a un propietario de organización de consumidores. El mensaje se envía mediante el servidor de correo electrónico configurado en Notificaciones.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `Puede enviar un correo electrónico a un propietario de organización de consumidores. El mensaje se envía mediante el servidor de correo electrónico configurado en la sección Notificaciones.`,
  [`You can send an email to a provider organization owner.`]: `Puede enviar un correo electrónico a un propietario de organización de proveedores.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Puede transferir la propiedad a un usuario nuevo especificando su dirección de correo electrónico. El usuario recibirá un correo electrónico con un enlace de activación para registrarse. Una vez registrado, el usuario se convertirá en el nuevo propietario. Asigne roles nuevos al propietario actual, de modo que pueda seguir trabajando en el Gestor de nube.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Puede transferir la propiedad a un usuario registrado especificando su dirección de correo electrónico. Asigne roles nuevos al propietario actual, de modo que pueda seguir trabajando en el Gestor de nube.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `Puede utilizar un archivo de definición de OpenAPI para añadir una API REST a IBM API Connect. El formato del archivo puede ser JSON o YAML.  Una vez importada, la definición de API se muestra en la lista de definiciones de API.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `No puede suprimir este tipo porque eliminará el tipo raíz ('Query').`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `No puede seleccionar una API cuyo tipo de pasarela no coincida con el tipo de pasarela de este producto.`,
  [`You currently don’t have any gateway extensions`]: `En este momento no tiene ninguna extensión de pasarela`,
  [`You currently don’t have any plans to migrate`]: `Actualmente no tiene ningún plan para migrar`,
  [`You currently don't have any policy.`]: `Actualmente no tiene ninguna política.`,
  [`You do not have permission to any spaces.`]: `No tiene permiso para ningún espacio.`,
  [`You do not have permission to publish products to any catalog or space.`]: `No tiene permiso para publicar productos en ningún catálogo o espacio.`,
  [`You do not have permission to stage products to any catalog or space.`]: `No tiene permiso para desplegar productos en ningún catálogo o espacio.`,
  [`You don’t currently have any billing integrations.`]: `Actualmente no tiene ninguna integración de facturación.`,
  [`You don't currently have any response.`]: `Actualmente no tienes ninguna respuesta.`,
  [`You don't currently have any user-defined policies.`]: `Actualmente no tiene políticas definidas por el usuario.`,
  [`You don't have permission to access this page.`]: `No tiene permiso para acceder a esta página.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Ha modificado este certificado. Si guarda esta aplicación se actualizará su configuración.`,
  [`You haven't added any APIs or Products`]: `No ha añadido API ni productos`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Puede gestionar la pertenencia a un catálogo añadiendo nuevos usuarios al catálogo y asignando roles a los usuarios.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `Puede gestionar la pertenencia a un espacio añadiendo nuevos usuarios al espacio y asignando roles a los usuarios.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Puede gestionar la pertenencia a un espacio añadiendo nuevos usuarios al espacio y asignando roles a los usuarios.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `Debe añadir la API a un producto listo para publicar. Un producto recopila un conjunto de API y planes en una oferta que se pone a disposición de los desarrolladores. Un plan incluye valores de límite de velocidad que se pueden aplicar al plan como un todo o bien de forma específica para cada operación de una API.`,
  [`You must choose a definition`]: `Debe elegir una definición`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `Debe habilitar como mínimo un servicio de pasarela para que las APIs publicadas en este catálogo estén disponibles para ser llamadas en un punto final de servicio de pasarela. Puede habilitar varios servicios de pasarela.`,
  [`You must enable spaces`]: `Debe habilitar espacios`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Debe registrar el ID y el secreto de cliente a continuación para utilizarlos al desarrollar la aplicación. El secreto de cliente no se puede volver a visualizar.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Su licencia de API Connect Reserved Instance le autoriza a descargar y configurar una o varias pasarelas de API de DataPower remotas. Las pasarelas se pueden ubicar en local o en la nube.`,
  [`Your API has been created`]: `La API se ha creado`,
  [`Your API is live!`]: `¡La API está activa!`,
  [`Your API is online!`]: `¡La API está en línea!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Su catálogo se está suprimiendo. Esta operación podría tardar un rato...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Su organización de consumidores se está creando. Esta operación podría tardar un rato...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Su invitación ya no es válida. Compruebe el valor de tiempo de espera de invitación y vuelva a enviar la invitación.`,
  [`Your Provider organization is being created. This may take a while...`]: `Se está creando la organización de proveedores. Esta operación podría tardar un rato...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Se está suprimiendo la organización de proveedores. Esta operación podría tardar un rato...`,
  [`Your member is being deleted. This may take a while...`]: `Su miembro se está suprimiendo. Esta operación podría tardar un rato...`,
  [`Your Space is being deleted. This may take a while...`]: `Su espacio se está suprimiendo. Esta operación podría tardar un rato...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `El proveedor de OAuth tiene cambios no guardados. Pulse Aceptar para continuar sin guardar.`,
  [`Your credentials have expired.`]: `Sus credenciales han caducado.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Su invitación se ha enviado satisfactoriamente con el siguiente enlace de invitación:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `La solicitud todavía se está procesando y tarda más de lo esperado. Renueve en unos pocos minutos antes de volver a intentar la solicitud.`,
  [`Your product will be saved before proceeding, are you sure?`]: `El producto se guardará antes de continuar. ¿Está seguro?`,
  [`Your trial ends on {date}`]: `Su prueba termina el {date}`,
  [`Your trial expired on {date}.`]: `Su prueba caducó el {date}.`,
  [`Zoom in`]: `Acercar`,
  [`Zoom in and out`]: `Acercar y alejar`,
  [`Zoom out`]: `Alejar`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `una falta de soporte de CORS en el servidor de destino (consulte la sugerencia que figura más abajo)`,
  [`a LDAP provider`]: `un proveedor LDAP`,
  [`activity`]: `actividad`,
  [`add item...`]: `añadir elemento...`,
  [`add property...`]: `añadir propiedad...`,
  [`added`]: `añadido`,
  [`admin`]: `admin`,
  [`administrator`]: `administrador`,
  [`all`]: `todos`,
  [`an API Connect Local User Registry`]: `un registro de usuarios local de API Connect`,
  [`an authentication URL`]: `un URL de autenticación`,
  [`an untrusted certificate has been encountered`]: `se ha encontrado un certificado que no es de confianza`,
  [`and`]: `y`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Aplicación:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `aprobación`,
  [`approval task for product`]: `tarea de aprobación para el producto`,
  [`archived`]: `archivado`,
  [`authenticated`]: `autenticado`,
  [`authorization code time to live (seconds)`]: `tiempo de vida de código de autorización (segundos)`,
  [`Authorization header pass through`]: `Pass through de cabecera de autorización`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `bloqueo`,
  [`body`]: `cuerpo`,
  [`body-param`]: `parámetro de cuerpo`,
  [`cancel`]: `cancelar`,
  [`case`]: `caso`,
  [`catalog`]: `Catálogo`,
  [`catalog:`]: `Catálogo:`,
  [`catch`]: `captura`,
  [`changed`]: `cambiado`,
  [`cipher`]: `cifrado`,
  [`code id_token`]: `id_token de código`,
  [`code id_token token`]: `Señal de id_token de código`,
  [`code token`]: `Señal de código`,
  [`Consume`]: `Consumir`,
  [`consumer`]: `consumidor`,
  [`consumes`]: `consume`,
  [`composite`]: `compuesto`,
  [`created`]: `creado`,
  [`Created API`]: `API creada`,
  [`custom string`]: `serie personalizada`,
  [`day`]: `día`,
  [`day(s)`]: `día(s)`,
  [`default`]: `predeterminado`,
  [`default value`]: `valor predeterminado`,
  [`definition`]: `definición`,
  [`deprecated`]: `en desuso`,
  [`description`]: `descripción`,
  [`development`]: `desarrollo`,
  [`edit condition`]: `editar condición`,
  [`edited`]: `editado`,
  [`environment`]: `Catálogo`,
  [`error`]: `error`,
  [`false`]: `false`,
  [`file`]: `archivo`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `Iconos de font-awesome 4.5.0`,
  [`for`]: `para`,
  [`header`]: `cabecera`,
  [`hear`]: `oír`,
  [`hello`]: `Hola`,
  [`helloName`]: `¡Hola, {name}!`,
  [`here`]: `aquí`,
  [`hit`]: `acierto`,
  [`hits`]: `aciertos`,
  [`hour`]: `hora`,
  [`hour(s)`]: `hora(s)`,
  [`hours`]: `horas`,
  [`identified`]: `identificado`,
  [`Increased`]: `Aumentado`,
  [`Increment`]: `Incrementar`,
  [`info`]: `información`,
  [`information about external documentation`]: `información sobre la documentación externa`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `En lugar de respetar la reclamación de caducidad del proveedor de OIDC, el access_token/refresh_token que generado por APIc respetará el valor de APIc.`,
  [`introspect URL`]: `URL de introspección`,
  [`Issue`]: `Problema`,
  [`Issues`]: `Problemas`,
  [`item`]: `elemento`,
  [`iterate over`]: `iterar por`,
  [`last modified`]: `última modificación`,
  [`Log Level`]: `Nivel de registro`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `registros`,
  [`Logout`]: `Fin de sesión`,
  [`Logout redirect`]: `Redirección de cierre de sesión`,
  [`Logout redirect URL`]: `URL de redirección de cierre de sesión`,
  [`location`]: `ubicación`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `minuto`,
  [`minute(s)`]: `minuto(s)`,
  [`minutes`]: `minutos`,
  [`mutation type`]: `tipo de mutación`,
  [`mutual SSL authentication is required`]: `es necesaria la autenticación SSL mutua`,
  [`name`]: `Nombre`,
  [`Name|extension`]: `Nombre`,
  [`Name|path parameters`]: `Nombre`,
  [`Name|security definitions`]: `Nombre`,
  [`non-blocking`]: `sin bloqueo`,
  [`none`]: `ninguno`,
  [`none selected`]: `ninguno seleccionado`,
  [`Object`]: `Objeto`,
  [`off`]: `desactivado`,
  [`of {total} pages`]: `de {total} páginas`,
  [`of {total} page`]: `de {total} página`,
  [`offline`]: `fuera de línea`,
  [`on`]: `activado`,
  [`online`]: `en línea`,
  [`or`]: `o`,
  [`or redact from all`]: `o redactar desde todo`,
  [`open and close list of options`]: `abrir y cerrar lista de opciones`,
  [`organization saved`]: `Organización guardada`,
  [`organization-manager`]: `gestor de organización`,
  [`otherwise`]: `de lo contrario`,
  [`payload`]: `carga útil`,
  [`Payload`]: `Carga útil`,
  [`pending`]: `pendiente`,
  [`produces`]: `produce`,
  [`production`]: `producción`,
  [`production and development`]: `producción y desarrollo`,
  [`property`]: `propiedad`,
  [`provider`]: `proveedor`,
  [`publish product`]: `publicar producto`,
  [`published`]: `publicado`,
  [`query type`]: `tipo de consulta`,
  [`query-parameters`]: `parámetros de consulta`,
  [`realized`]: `realizado`,
  [`recipients`]: `destinatarios`,
  [`removed`]: `eliminado`,
  [`replaced`]: `sustituido`,
  [`repeatable`]: `repetible`,
  [`Replenish`]: `Reponer`,
  [`request`]: `solicitud`,
  [`requested by`]: `solicitado por`,
  [`Resolver weight`]: `Peso de resolutor`,
  [`response`]: `respuesta`,
  [`response-param`]: `parámetro de respuesta`,
  [`retired`]: `retirado`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `guardado`,
  [`scalar`]: `escalar`,
  [`second`]: `segundo`,
  [`second(s)`]: `segundo(s)`,
  [`seconds`]: `segundos`,
  [`select owner user`]: `seleccionar usuario propietario`,
  [`select user`]: `seleccionar usuario`,
  [`soap-body`]: `cuerpo de SOAP`,
  [`source`]: `origen`,
  [`space`]: `espacio`,
  [`Space settings`]: `Valores de espacio`,
  [`Skip`]: `Omitir`,
  [`specified`]: `especificado`,
  [`staged`]: `desplegado`,
  [`start generating tests automatically.`]: `empezar a generar pruebas automáticamente.`,
  [`subject`]: `asunto`,
  [`subscription type`]: `tipo de suscripción`,
  [`supersede`]: `reemplazar`,
  [`superseded`]: `reemplazado`,
  [`target`]: `destino`,
  [`the server is unavailable`]: `el servidor no está disponible`,
  [`this user registry type`]: `este tipo de registro de usuarios`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Esta es una página de publicación temporal en la que, al volver a publicar, se conservan las suscripciones.`,
  [`to production`]: `a producción`,
  [`topology-administrator`]: `administrador de topología`,
  [`Trial days left`]: `Días de prueba restantes`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `Se ha cambiado el ttl`,
  [`type`]: `tipo`,
  [`unarchived`]: `no archivado`,
  [`union type`]: `tipo de unión`,
  [`unlimited`]: `ilimitado`,
  [`updated`]: `actualizado`,
  [`Updated from new schema`]: `Actualizado a partir de esquema nuevo`,
  [`Upgrade`]: `Actualizar`,
  [`v5 legacy behavior`]: `Comportamiento heredado de v5`,
  [`version 2`]: `versión 2`,
  [`version 3`]: `versión 3`,
  [`viewer`]: `visor`,
  [`value`]: `valor`,
  [`warn`]: `aviso`,
  [`warning`]: `aviso`,
  [`warnings`]: `avisos`,
  [`was not found. Please select a configured OAuth provider.`]: `no se ha encontrado. Seleccione un proveedor de OAuth configurado.`,
  [`week`]: `semana`,
  [`with`]: `con`,
  [`with|supersede with`]: `con`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `estado en recinto de pruebas`,
  [`your file here, or`]: `aquí el archivo o`,
  [`{count} found!`]: `¡Se han encontrado {count}!`,
  [`{current} of {total} page`]: `Página {current} de {total}`,
  [`{current} of {total} pages`]: `{current} de {total} páginas`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} día(s), {hours} hora(s) y {minutes} minuto(s)`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} día(s) y {hours} hora(s)`,
  [`{docType} is required`]: `{docType} es obligatorio`,
  [`{docType} title`]: `Título de {docType}`,
  [`Product title`]: `Título del producto`,
  [`Application title`]: `Título de la aplicación`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} hora(s) y {minutes} minuto(s)`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} pasarela(s) oculta(s) debido a la incompatibilidad con el tipo de pasarela especificado en la API o el producto`,
  [`{min}-{max} of {total} items`]: `{min}-{max} de {total} elementos`,
  [`{min}-{max} of {total} item`]: `Elemento {min}-{max} de {total}`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} de {total} políticas`,
  [`{min}-{max} of {total} Policy`]: `Política {min}-{max} de {total}`,
  [`{min}-{max} of {total} types`]: `{min}-{max} de {total} tipos`,
  [`{min}-{max} of {total} type`]: `Tipo {min}-{max} de {total}`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} de {total} resultados de la búsqueda`,
  [`{min}-{max} of {total} search result`]: `Resultado de búsqueda {min}-{max} de {total}`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} de {total} catálogos`,
  [`{min}-{max} of {total} Catalog`]: `Catálogo {min}-{max} de {total}`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} de {total} espacios`,
  [`{min}-{max} of {total} Space`]: `Espacio {min}-{max} de {total}`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} de {total} extensiones`,
  [`{min}-{max} of {total} Extension`]: `Extensión {min}-{max} de {total}`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} de {total} planes de producto`,
  [`{min}-{max} of {total} Product Plan`]: `Plan de producto {min}-{max} de {total}`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} de {total} pasarelas`,
  [`{min}-{max} of {total} Gateway`]: `Pasarela {min}-{max} de {total}`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `Falta 'x-ibm-configuration' en {name} (API)`,
  [`{name} (Product) has been published!`]: `¡Se ha publicado {name} (producto)!`,
  [`{name} (Product) has been staged!`]: `¡Se ha desplegado {name} (producto)!`,
  [`{name} (Product) has not been published!`]: `¡No se ha publicado {name} (producto)!`,
  [`{name} (Role) has been created`]: `Se ha creado {name} (rol).`,
  [`{name} successfully deleted`]: `{name} se ha suprimido correctamente.`,
  [`{name} (Role) has been updated`]: `Se ha actualizado {name} (rol).`,
  [`{name} has been assigned the owner`]: `{name} asignado propietario`,
  [`{number} Error`]: `{number} Error`,
  [`{number} Errors`]: `{number} Errores`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `Tarea de aprobación de {operation} para el producto {prodname}:{version} solicitada por {username}`,
  [`{path} does not exist`]: `{path} no existe`,
  [`{productName} has no migration target set.`]: `{productName} tiene un conjunto de destino de migración.`,
  [`{state} Pending`]: `Pendiente de {state}`,
  [`{status} API error`]: `Error de API {status}`,
  [`{status} Bad Request`]: `{status} Solicitud no válida`,
  [`{status} Forbidden`]: `{status} Prohibido`,
  [`{status} Gateway Error`]: `{status} Error de pasarela`,
  [`{status} Unauthorized`]: `{status} No autorizado`,
  [`({time} after being sent)`]: `({time} después de enviarse)`,
  [`({time} after being queued)`]: `({time} después de ponerse en cola)`,
  [`{title} (Product) has been {state}.`]: `{title} (Producto) se ha {state}.`,
  [`{title} has been deleted`]: `Se ha suprimido {title}`,
  [`{title} has been {changedName}.`]: `{title} ha sido {changedName}.`,
  [`{title} has not been deleted!`]: `¡{title} no se ha suprimido!`,
  [`{title} has not been {changedName}!`]: `¡{title} no ha sido {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type} ha sido {changedName}.`,
  [`{type} has not been {changedName}!`]: `¡{type} no ha sido {changedName}!`,
  [`{type} history log`]: `Registro histórico de {type}`,
  [`{username} does not exist`]: `{username} no existe`,
  [`{username} does not exist in the user registry`]: `{username} no existe en el registro de usuarios`,
  [`{username} is not associated with this consumer org`]: `{username} no está asociado con esta organización de consumidores`,
  [`{username} has been created as a {type}.`]: `{username} se ha creado como un {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `Aplicación {appName} restablecida en el portal de desarrollador {consumerOrg}`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `Aplicación {appName} suspendida en el portal de desarrollador {consumerOrg}`,
  [`{{catalogTitle}} developer portal account registration`]: `Registro de cuenta de portal de desarrollador {catalogTitle}`,
  [`{{count}} errors need attention`]: `{count} errores requieren atención`,
  // lts only translations
  [`Configure JSON settings`]: `Configurar valores de JSON`,
  [`Configure XML settings`]: `Configurar valores de XML`,
  [`Configure options`]: `Configurar opciones`,
  [`Detect`]: `Detectar`,
  [`Document type`]: `Tipo de documento`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Tamaño máximo de documento`,
  [`Maximum name length`]: `Longitud máxima de nombre`,
  [`Maximum nesting depth`]: `Profundidad máxima de anidamiento`,
  [`Maximum number length`]: `Longitud máxima de número`,
  [`Maximum number of unique names`]: `Número máximo de nombres exclusivos`,
  [`Maximum number of unique namespaces`]: `Número máximo de espacios de nombres exclusivos`,
  [`Maximum number of unique prefixes`]: `Número máximo de prefijos exclusivos`,
  [`Maximum value length`]: `Longitud máxima del valor`,
  [`Maximum width`]: `Anchura máxima`,
  [`Maximum stack size`]: `Tamaño máximo de pila`,
  [`Parse settings`]: `Valores de análisis`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Especifica el tamaño máximo del documento en bytes que acepta la acción de análisis.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Especifica el nivel máximo de profundidad de elementos anidados en un mensaje XML o JSON que la acción de análisis acepta.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Especifica el nivel máximo de profundidad de elementos anidados en un mensaje XML, JSON o GraphQL que la acción de análisis acepta.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Especifica la longitud máxima que acepta la acción de análisis.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Especifica los límites de longitud de número máximo del número de bytes de la parte de valor de un par de etiqueta-valor cuando el valor es un número.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Especifica el número máximo de URI de espacio de nombres XML exclusivos que acepta la acción de análisis. Este límite cuenta todos los espacios de nombres XML, independientemente de cuántos prefijos se utilicen para declararlos.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Especifica el número máximo de prefijos de espacio de nombres XML exclusivos en un documento que acepta la acción de análisis. Este límite cuenta varios prefijos definidos para el mismo espacio de nombres, pero no cuenta varios espacios de nombres definidos en diferentes partes del documento de entrada en un solo prefijo.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Especifica el número máximo de nombres exclusivos que acepta la acción de análisis.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Especifica la longitud máxima del valor en bytes que acepta la acción de análisis.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Especifica la anchura máxima de una carga útil que acepta la acción de análisis.`,
  [`Specifies the type of document to parse.`]: `Especifica el tipo de documento que se va a analizar.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Especifica la versión del procesador XSLT. El valor predeterminado es XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Especifica si se debe habilitar la comprobación de errores XSLT estricta. Las operaciones no estrictas intentan recuperarse de ciertos errores, como uso de variables no declaradas, llamadas a plantillas no declaradas, etc. De forma predeterminada, la comprobación de errores XSLT estricta está habilitada.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Especifica si se debe habilitar el perfilado de hojas de estilo. Esta opción no se debe utilizar en entornos de producción. De forma predeterminada, el perfilado de hojas de estilo está inhabilitado.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Especifica si se debe ejecutar la hoja de estilo, el script XQuery y el script JSONiq en modalidad de depuración. Cuando una hoja de estilo, un script XQuery o un script JSONiq se ejecuta en modalidad de depuración, genera una página web personalizada en lugar de mostrar su salida normal. La página web muestra detalles exactos sobre lo que ha ocurrido durante la ejecución, incluidos los valores de las variables y de dónde proceden determinadas partes de la salida. Esta opción no debe utilizarse en entornos de producción. De forma predeterminada, la modalidad de depuración está disabled.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Especifica si la hoja de se estilo debe ejecutar en modalidad de transmisión. La transformación del documento comienza antes de que se haya analizado completamente la entrada. No todas las hojas de estilo se pueden transmitir. Si la hoja de estilo no se puede transmitir, se genera un error y no se procesa la entrada. De forma predeterminada, la modalidad de transmisión está inhabilitada.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Especifica si se debe intentar ejecutar la hoja de estilo en modalidad de transmisión. La transformación del documento comienza antes de que se haya analizado completamente la entrada. No todas las hojas de estilo se pueden transmitir. Si la hoja de estilo no se puede transmitir, se genera un aviso durante la compilación y la hoja de estilo se lee en toda la entrada como es habitual en tiempo de ejecución. De forma predeterminada, el intento de ejecutar la hoja de estilo en modalidad de transmisión está inhabilitado.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Especifica si se debe aplicar el escape de la salida generada desde la hoja de estilo durante el proceso. El escape mínimo resulta especialmente útil cuando se manejan juegos de caracteres distintos del inglés. De forma predeterminada, el escape mínimo está inhabilitado.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Indica el número máximo de bytes que la pila puede utilizar al ejecutar una hoja de estilo u otro contenido compilado. Este valor se utiliza para bloquear la recurrencia infinita. El valor mínimo es 10 kilobytes o 10.240 bytes. El valor máximo es 100 megabytes o 104.857.600 bytes. El valor predeterminado es 1 megabyte o 1.048.576 bytes.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Especifica el comportamiento de validación que se va a aplicar a los archivos WSDL cuya conformidad con la sección 5 de WS-I Basic Profile (versión 1.0, abril 2004) se comprueba. El valor predeterminado es Warn.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Especifica el comportamiento de validación para soap:Body. El valor predeterminado es Strict.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Especifica el comportamiento de validación para soap:Header. El valor predeterminado es Lax.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Especifica el comportamiento de validación para el detalle de error. El valor predeterminado es Strict.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Especifica si se requiere compatibilidad con encapsuladores de estilo RPC. De forma predeterminada, no se requieren encapsuladores estilo RPC.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Especifica si se debe permitir que el esquema acepte la mayoría de los usos de los elementos con xsi:type='SOAP-ENC:Array' coherentes con SOAP 1.1 Sección 5, aunque estos atributos infrinjan la especificación de esquema XML. Normalmente, el atributo xsi:type debe nombrar un tipo igual al tipo real del elemento o derivado de dicho tipo. Para los esquemas compilados con esta opción, xsi:type se acepta específicamente para el tipo complejo SOAP 1.1 Encoding 'Array' si el tipo de elemento se deriva de SOAP-ENC:Array. Lo contrario es el caso normal permitido. De forma predeterminada, no se aceptan los elementos con xsi:type='SOAP-ENC:Array'.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Especifica si se debe realizar una validación de esquema adicional siguiendo las reglas de codificación de la sección 5 de SOAP 1.1. Si esta opción está habilitada, los miembros de matrices de SOAP se validan, los atributos como @id y @href se permiten aunque no estén permitidos por el esquema y los valores @href se comprueban para garantizar que tienen un elemento @id correspondiente. De forma predeterminada, no se realiza la validación adicional.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Especifica si los elementos xs:any del esquema validan solo los elementos hijo por nombre. La especificación XML Schema exige que si un carácter comodín coincide con un elemento pero dicho elemento no tiene una declaración de elemento, el elemento se valide de acuerdo con un atributo xsi:type que contiene. Esta opción pasa por alto estos atributos xsi:type. Debe utilizarse en casos como la validación de sobres SOAP donde un paso de validación adicional validará el contenido que coincida con el carácter comodín, posiblemente utilizando las reglas de codificación SOAP 1.1. De forma predeterminada, los atributos xsi:type no se pasan por alto.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Especifica si se debe seguir estrictamente el enlace SOAP en el WSDL. Cuando esta opción está habilitada, solo los mensajes enlazados a SOAP 1.2 aparecen en sobres SOAP 1.2 y solo los mensajes enlazados a SOAP 1.1 aparecen en sobres SOAP 1.1. De forma predeterminada, el enlace SOAP estricto está inhabilitado.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Especifica si se deben compilar las políticas XACML con información de depuración. Tenga en cuenta que los mensajes de depuración XACML también están controlados por el suceso de registro en la categoría XACML. Utilice el nivel de registro de depuración para ver los mensajes de depuración XACML completos. De forma predeterminada, las políticas XACML no se compilan con información de depuración.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Especifica si el documento de esquema o WSDL acepta mensajes en los que el contenido binario codificado en base64 se ha optimizado según las especificaciones MTOM/XOP. La optimización binaria XOP sustituye los datos binarios codificados en base 64 por un elemento de referencia xop:Include que hace referencia a los datos binarios descodificados ubicados en un archivo adjunto. De forma predeterminada, los mensajes optimizados de MTOM/XOP están inhabilitados.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Un grupo de organización de consumidores es un conjunto de organizaciones de consumidores y proporciona una forma eficiente de controlar quien puede ver y suscribirse a las API de sus productos. Utilizando un grupo de organización de consumidores, es posible definir este acceso para todos los desarrolladores de las organizaciones de ese grupo en una única operación, en lugar de tener que definir el acceso para las organizaciones por separado.`,
  [`API Connect Home`]: `Inicio de API Connect`,
  [`Add new group`]: `Añadir grupo nuevo`,
  [`Add consumer organizations to the group as required`]: `Añadir organizaciones de consumidores al grupo según sea necesario`,
  [`Add the following configuration`]: `Añadir la configuración siguiente`,
  [`Add the following configuration to`]: `Añadir la configuración siguiente a`,
  [`Additions`]: `Adiciones`,
  [`Additional GraphQL capabilities`]: `Prestaciones de GraphQL adicionales`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Valores adicionales para las API graphQL que no están incluidas en este plan`,
  [`An organization or a group is required.`]: `Es obligatorio especificar una organización o un grupo.`,
  [`An organization/group is required.`]: `Es obligatorio especificar una organización o un grupo.`,
  [`Analysis configuration`]: `Configuración de análisis`,
  [`Application & Credentials`]: `Aplicación & Credenciales`,
  [`Are you sure you want to delete this API version?`]: `¿Está seguro de que desea suprimir esta versión de API?`,
  [`Are you sure you want to delete this API with all versions?`]: `¿Está seguro de que desea suprimir esta API con todas las versiones?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `¿Está seguro de que desea eliminar la extensión de pasarela de la pasarela?`,
  [`Are you sure you want to rename all versions of this API?`]: `¿Está seguro de que desea renombrar todas las versiones de esta API?`,
  [`Are you sure you want to rename this API version?`]: `¿Está seguro de que desea renombrar esta versión de API?`,
  [`Associate groups to the organization as required`]: `Asociar grupos a la organización, según sea necesario`,
  [`At least one option must be enabled`]: `Debe habilitarse una opción como mínimo`,
  [`Attribute mapping`]: `Correlación de atributos`,
  [`Attempt streaming rule`]: `Regla de intento de transmisión`,
  [`Auto generated default paths and operations`]: `Vías de acceso y operaciones predeterminadas generadas automáticamente`,
  [`Base API endpoint`]: `Punto final de API base`,
  [`Consumer orgs`]: `Organizaciones de consumidores`,
  [`Calculate Query Characteristics`]: `Calcular características de consulta`,
  [`Calculate Response Characteristics`]: `Calcular características de respuesta`,
  [`Certificate or Shared Secret for Verify`]: `Certificado o secreto compartido para verificar`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `¡Consulte en nuestros laboratorios prácticos, donde puede solicitar la prueba de todas las prestaciones de gestión de API durante hasta una semana!`,
  [`Clone API`]: `Clonar API`,
  [`Consumer organizations and groups`]: `Organizaciones de consumidores y grupos`,
  [`Create new API version`]: `Crear versión de API`,
  [`Create new product version`]: `Crear versión de producto`,
  [`Create a GraphQL API based on a GraphQL service`]: `Crear una API GraphQL basada en el servicio GraphQL`,
  [`Custom rate limit`]: `Límite de velocidad personalizado`,
  [`Delete API`]: `Suprimir API`,
  [`Delete this version`]: `Suprimir esta versión`,
  [`Deletion confirmation`]: `Confirmación de supresión`,
  [`Deletions`]: `Supresiones`,
  [`Display name`]: `Nombre de visualización`,
  [`Display Name`]: `Nombre de visualización`,
  [`Details`]: `Detalles`,
  [`Edit GraphQL Rate Limit|title`]: `Editar límite de velocidad de GraphQL|título`,
  [`Edit GraphQL rate limits`]: `Editar límites de velocidad de GraphQL`,
  [`Edit group`]: `Editar grupo`,
  [`Edit condition`]: `Editar condición`,
  [`Edit rate limit|title`]: `Editar límite de velocidad`,
  [`Edit rate limit|menu`]: `Editar límite de velocidad`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `No hay productos publicados en este catálogo o esta aplicación ya está suscrita a todos los planes de todos los productos.`,
  [`Enforce Policy`]: `Imponer política`,
  [`Enter a group summary`]: `Especifique un resumen de grupo`,
  [`Enter a group title`]: `Especifique un título de grupo`,
  [`Enter group details below`]: `Especifique los detalles del grupo a continuación`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Especifique el URL y el nombre de esquema del servicio GraphQL para el proxy`,
  [`Enum value`]: `Valor enum`,
  [`Go to catalog settings to configure a new billing integration`]: `Vaya a los valores del catálogo para configurar una nueva integración de facturación`,
  [`GraphQL introspect`]: `Introspección de GraphQL`,
  [`GraphQL server`]: `Servidor de GraphQL`,
  [`GraphQL Setting`]: `Valor de GraphQL`,
  [`GraphQL server URL`]: `URL del servidor de GraphQL`,
  [`GraphQL server name`]: `Nombre del servidor de GraphQL`,
  [`GraphQL type analysis configuration`]: `Configuración de análisis de tipo de GraphQL`,
  [`GraphQL resolver analysis configuration`]: `Configuración de análisis de resolutor de GraphQL`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Los grupos se pueden crear y gestionar en la lista siguiente.`,
  [`Groups will be listed here.`]: `Los grupos se listarán aquí.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Hola, {firstName}.\n\nLa solicitud para {action} el producto de API {productName}:{version} en el catálogo\n{catalog} se ha aprobado.\n\n{#if comments}\nEl aprobador ha incluido los comentarios siguientes:\n\n{comments}\n\n{{/if}}\nPuede comprobar el estado del producto aquí:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Hola.\n\nEsto es un mensaje de prueba de IBM API Connect desde el servidor de correo configurado\n(mailServer.\n\nSi ha recibido este mensaje tal como se esperaba, ¡la prueba ha sido satisfactoria!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hola,\n\nEl usuario {originator} ha retirado la solicitud para suscribirse con la aplicación {appName}\nal plan {planName} para la versión {productVersion} del producto de API {productName}\nen el catálogo {catalog}.\n\nNo es necesario hacer nada más.\n`,
  [`Invoke GraphQL server`]: `Invocar servidor GraphQL`,
  [`Invoke the backend GraphQL server`]: `Invocar el servidor GraphQL de fondo`,
  [`JWKS endpoint`]: `Punto final JWKS`,
  [`LDAP Field Name`]: `Nombre de campo LDAP`,
  [`Limit maximum nesting per query`]: `Limitar anidamiento máximo por consulta`,
  [`Limit resolve complexity rate on a per key basis`]: `Limitar índice de complejidad de resolución según la clave`,
  [`Limit type complexity rate on a per key basis`]: `Limitar índice de complejidad de tipo según la clave`,
  [`Manage APIs included in this product`]: `Gestionar las API incluidas en este producto`,
  [`Manage groups`]: `Gestionar grupos`,
  [`Maximum nesting`]: `Anidamiento máximo`,
  [`Maximum resolve complexity`]: `Complejidad de resolución máxima`,
  [`Maximum type complexity`]: `Complejidad de tipo máxima`,
  [`Name of the group`]: `Nombre del grupo`,
  [`New API version`]: `Nueva versión de API`,
  [`New GraphQL API`]: `Nueva API de GraphQL`,
  [`New product version`]: `Nueva versión de producto`,
  [`New Version`]: `Versión nueva`,
  [`New version`]: `Nueva versión`,
  [`No billing integration exists`]: `No existe ninguna integración de facturación`,
  [`No CA bundles available`]: `No hay paquetes de CA disponibles`,
  [`No Data`]: `No hay datos`,
  [`No certificates available`]: `No hay certificados disponibles`,
  [`No data`]: `No hay datos`,
  [`No group found`]: `No se ha encontrado ningún grupo`,
  [`OAuth Auth URL`]: `URL de autorización de OAuth`,
  [`OAuth Token URL`]: `URL de señal de OAuth`,
  [`Open Stripe dashboard`]: `Abrir panel de control de Stripe`,
  [`Operation/Path`]: `Operación/Vía de acceso`,
  [`Operation Path`]: `Vía de acceso de operación`,
  [`Operations and Paths`]: `Operaciones y vías de acceso`,
  [`Operator`]: `Operador`,
  [`Organizations and groups will be listed here.`]: `Las organizaciones y los grupos aparecerán aquí.`,
  [`Page not found`]: `No se ha encontrado la página`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Copie el código que se indica a continuación en CLI para continuar el proceso. El código caducará en **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Haga referencia al panel de control de Stripe para acceder a la clave y el secreto de API`,
  [`Please upload a schema definition language (SDL) file.`]: `Cargue un archivo de lenguaje de definición de esquema (SDL).`,
  [`Please upload a valid GraphQL schema.`]: `Cargue un esquema GraphQL válido.`,
  [`Please upload a valid YAML file.`]: `Cargue un archivo YAML válido.`,
  [`Populate Server Configuration into Context Variables`]: `Llenar configuración de servidor en variables de contexto`,
  [`POST/GET a query to be validated and sent to the backend server`]: `Realizar POST/GET con una consulta para que se valide y envíe al servidor de fondo`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `Realizar POST/GET con una consulta para obtener el coste estimado de invocar esa consulta`,
  [`Preparing`]: `Preparando`,
  [`Rate limit amount cannot be negative`]: `El límite de velocidad no puede ser negativo`,
  [`Rate limit per cannot be negative`]: `El límite de velocidad no puede ser negativo`,
  [`Rename API`]: `Renombrar API`,
  [`Rename`]: `Renombrar`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Solicitud de aprobación para {action} un producto de API en el catálogo {catalog}`,
  [`Sandbox Sample Application`]: `Aplicación de ejemplo de recinto de pruebas`,
  [`Sandbox sample application credentials`]: `Credenciales de aplicación de ejemplo de recinto de pruebas`,
  [`sample application credentials`]: `credenciales de aplicación de ejemplo`,
  [`Saving`]: `Guardando`,
  [`Search Orgs`]: `Buscar organizaciones`,
  [`Search definitions...`]: `Buscar definiciones...`,
  [`Search errors...`]: `Buscar errores...`,
  [`Search operations...`]: `Buscar operaciones...`,
  [`Search organizations and groups`]: `Buscar organizaciones y grupos`,
  [`Sorry, This feature is not available in`]: `Lo sentimos, esta característica no está disponible en`,
  [`Stopping`]: `Deteniéndose`,
  [`The user with username {username} already exists in the user registry.`]: `El usuario con el nombre de usuario {username} ya existe en el registro de usuarios.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Hay cambios de API que no se están ejecutando. Vuelva a publicar esta API en el catálogo de recinto de pruebas.`,
  [`There are no plans available for subscription.`]: `No hay planes disponibles para suscripción.`,
  [`There are no attributes yet.`]: `Todavía no hay atributos.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Escriba para añadir organizaciones de consumidores al grupo`,
  [`Type to add groups to the organization`]: `Escriba para añadir grupos a la organización`,
  [`Type to add organizations from {catalog}`]: `Escriba para añadir organizaciones de {catalog}`,
  [`Type to add organizations or groups`]: `Escriba para añadir organizaciones o grupos`,
  [`Type to add organizations or groups from {catalog}`]: `Escriba para añadir organizaciones o grupos de {catalog}`,
  [`Type to add {title} to groups`]: `Escriba para añadir {title} a grupos`,
  [`Update Rate Limits`]: `Actualizar límites de velocidad`,
  [`Upload the YAML file with configuration`]: `Cargar el archivo YAML con configuración`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Utilice el ejemplo que figura más abajo como plantilla para configurar el servidor GraphQL`,
  [`User managed`]: `Gestionado por el usuario`,
  [`URL {url} does not contain valid OpenAPI.`]: `El URL {url} no contiene OpenAPI válido.`,
  [`Validating`]: `Validando`,
  [`View analytics and performance metrics for your APIs`]: `Ver medidas de rendimiento y análisis para las API`,
  [`View and add custom policies to the catalog`]: `Ver y añadir políticas personalizadas al catálogo`,
  [`View and add custom exentions to the catalog`]: `Ver y añadir extensiones personalizadas al catálogo`,
  [`View user registries and OAuth providers`]: `Ver registros de usuario y proveedores de OAuth`,
  [`View your application and manage API credentials`]: `Visualiza la aplicación y gestiona las credenciales de la API`,
  [`Welcome to the API Connect Trial`]: `Bienvenido a la prueba de API Connect`,
  [`You haven't added any APIs`]: `No ha añadido ninguna API`,
  [`You currently don’t have any {resource}.`]: `Actualmente no tiene {resource}.`,
  [`plans to migrate`]: `planes de migración`,
  [`gateway extensions`]: `extensiones de pasarela`,
  [`application`]: `aplicación`,
  [`application authentication source`]: `origen de autenticación de aplicación`,
  [`API or product`]: `API o producto`,
  [`base endpoints`]: `puntos finales base`,
  [`categories`]: `categorías`,
  [`certificates`]: `certificados`,
  [`channels`]: `canales`,
  [`consumer organizations`]: `organizaciones de consumidores`,
  [`consumer organization group`]: `grupo de organizaciones de consumidores`,
  [`consumer organizations or groups`]: `organizaciones de consumidores o grupos`,
  [`correlation ids`]: `ID de correlación`,
  [`cors policy`]: `política de cors`,
  [`definitions`]: `definiciones`,
  [`examples`]: `ejemplos`,
  [`gateway services`]: `servicios de pasarela`,
  [`groups`]: `grupos`,
  [`headers`]: `cabeceras`,
  [`links`]: `enlaces`,
  [`member`]: `miembro`,
  [`messages`]: `mensajes`,
  [`message traits`]: `rasgos del mensaje`,
  [`operation traits`]: `rasgos de la operación`,
  [`parameter definitions`]: `definiciones de parámetros`,
  [`parameters`]: `parámetros`,
  [`path`]: `vía de acceso`,
  [`properties`]: `propiedades`,
  [`provider organizations`]: `organizaciones de proveedores`,
  [`request bodies`]: `cuerpos de solicitud`,
  [`response definitions`]: `definiciones de respuesta`,
  [`responses`]: `respuestas`,
  [`role`]: `rol`,
  [`scope`]: `Ámbito`,
  [`scopes`]: `ámbitos`,
  [`schemas`]: `esquemas`,
  [`schema definition`]: `definición de esquema`,
  [`schemes list`]: `lista de esquemas`,
  [`security definitions`]: `definiciones de seguridad`,
  [`security schemes`]: `esquemas de seguridad`,
  [`servers`]: `servidores`,
  [`services`]: `servicios`,
  [`tags`]: `etiquetas`,
  [`tags and external documentation`]: `etiquetas y documentación externa`,
  [`target services`]: `servicios de destino`,
  [`tasks`]: `tareas`,
  [`user registries`]: `registros de usuarios`,
  [`HTTP header`]: `Cabecera HTTP`,
  [`You currently don’t have any application authentication source.`]: `Actualmente no tiene ningún origen de autenticación de aplicación.`,
  [`You currently don’t have any API or product.`]: `Actualmente no tiene ninguna API o producto.`,
  [`You currently don’t have any API user registries`]: `Actualmente no tiene ningún registro de usuarios de API.`,
  [`You currently don’t have any APIs.`]: `Actualmente no tiene ninguna API.`,
  [`You currently don’t have any TLS client profiles`]: `Actualmente no tiene ningún perfil de cliente TLS.`,
  [`You currently don’t have any base endpoints.`]: `Actualmente no tiene ningún punto final base.`,
  [`You currently don’t have any catalog properties.`]: `Actualmente no tiene ninguna propiedad de catálogo.`,
  [`You currently don’t have any categories.`]: `Actualmente no tiene ninguna categoría.`,
  [`You currently don’t have any certificates.`]: `Actualmente no tiene ningún certificado.`,
  [`You currently don’t have any channels.`]: `Actualmente no tiene ningún canal.`,
  [`You currently don’t have any consumer organizations.`]: `Actualmente no tiene organizaciones de consumidores.`,
  [`You currently don’t have any consumer organizations or groups.`]: `Actualmente no tiene organizaciones de consumidores o grupos.`,
  [`You currently don’t have any consumes.`]: `Actualmente no tiene ningún consumo.`,
  [`You currently don’t have any correlation ids.`]: `Actualmente no tiene ningún ID de correlación.`,
  [`You currently don’t have any cors policy.`]: `Actualmente no tiene ninguna política de cors.`,
  [`You currently don’t have any definitions.`]: `Actualmente no tiene ninguna definición.`,
  [`You currently don’t have any examples.`]: `Actualmente no tiene ningún ejemplo.`,
  [`You currently don’t have any gateway services.`]: `Actualmente no tiene servicios de pasarela.`,
  [`You currently don’t have any groups.`]: `Actualmente no tiene grupos.`,
  [`You currently don’t have any headers.`]: `Actualmente no tiene ninguna cabecera.`,
  [`You currently don’t have any keystore.`]: `Actualmente no tiene ningún almacén de claves.`,
  [`You currently don’t have any links.`]: `Actualmente no tiene ningún enlace.`,
  [`You currently don’t have any messages.`]: `Actualmente no tiene ningún mensaje.`,
  [`You currently don’t have any message traits.`]: `Actualmente no tiene ningún rasgo de mensaje.`,
  [`You currently don’t have any OAuth providers`]: `Actualmente no tiene proveedores OAuth`,
  [`You currently don’t have any operation traits.`]: `Actualmente no tiene ningún rasgo de operación.`,
  [`You currently don’t have any parameter definitions.`]: `Actualmente no tiene ninguna definición de parámetro.`,
  [`You currently don’t have any parameters.`]: `Actualmente no tiene parámetros.`,
  [`You currently don’t have any preserved request header.`]: `Actualmente no tiene ninguna cabecera de solicitud conservada.`,
  [`You currently don’t have any preserved response header.`]: `Actualmente no tiene ninguna cabecera de respuesta conservada.`,
  [`You currently don’t have any produces.`]: `Actualmente no tiene ninguna producción.`,
  [`You currently don’t have any properties.`]: `Actualmente no tiene propiedades.`,
  [`You currently don’t have any provider organizations.`]: `Actualmente no tiene organizaciones de proveedores.`,
  [`You currently don’t have any request bodies.`]: `Actualmente no tiene ningún cuerpo de solicitud.`,
  [`You currently don’t have any response definitions.`]: `Actualmente no tiene ninguna definición de respuesta.`,
  [`You currently don’t have any responses.`]: `Actualmente no tiene ninguna respuesta.`,
  [`You currently don’t have any scopes.`]: `Actualmente no tiene ámbitos.`,
  [`You currently don’t have any schemas.`]: `Actualmente no tiene ningún esquema.`,
  [`You currently don’t have any schemes list.`]: `Actualmente no tiene ninguna lista de esquemas.`,
  [`You currently don’t have any security definitions.`]: `Actualmente no tiene definiciones de seguridad.`,
  [`You currently don’t have any security schemes.`]: `Actualmente no tiene ningún esquema de seguridad.`,
  [`You currently don’t have any servers.`]: `Actualmente no tiene ningún servidor.`,
  [`You currently don’t have any services.`]: `Actualmente no tiene servicios.`,
  [`You currently don’t have any tags.`]: `Actualmente no tiene ninguna etiqueta.`,
  [`You currently don’t have any tags and external documentation.`]: `Actualmente no tiene etiquetas y documentación externa.`,
  [`You currently don’t have any target services.`]: `Actualmente no tiene ningún servicio de destino.`,
  [`You currently don’t have any tasks.`]: `Actualmente no tiene tareas.`,
  [`You currently don’t have any truststore.`]: `Actualmente no tiene ningún almacén de confianza.`,
  [`You currently don’t have any user registries.`]: `Actualmente no tiene registros de usuarios.`,
  [`You currently don’t have any extensions.`]: `Actualmente no tiene ninguna extensión.`,
  [`You currently don’t have any HTTP header.`]: `Actualmente no tiene ninguna cabecera HTTP.`,
  [`Your account is being activated. This may take a while...`]: `Su cuenta se está activando. Esta operación podría tardar un rato...`,
  [`all of`]: `todos de`,
  [`any of`]: `cualquiera de`,
  [`calls`]: `llamadas`,
  [`ciphers`]: `cifrados`,
  [`extensions`]: `extensiones`,
  [`one of`]: `uno de`,
  [`shared`]: `compartido`,
  [`stopping`]: `deteniéndose`,
  [`to:`]: `a:`,
  [`Key`]: `Clave`,
  [`kind`]: `especie`,
  [`Trace`]: `Rastreo`,
  [`Http Status`]: `Estado http`,
  [`Parsed`]: `Analizado`,
  [`Raw`]: `Original`,
  [`Showing Full Trace`]: `Mostrando rastreo completo`,
  [`Show Entire Trace`]: `Mostrar todo el rastreo`,
  [`Show advanced`]: `Mostrar avanzado`,
  [`Advanced`]: `Avanzado`,
  [`Sending Request`]: `Enviando solicitud`,
  [`Please send a request above`]: `Envíe una solicitud más arriba`,
  [`Please send a request above.`]: `Por favor, envíe una solicitud.`,
  [`Authentication Type`]: `Tipo de autenticación`,
  [`You don't currently have any response`]: `Actualmente no tiene ninguna respuesta`,
  [`You don't currently have any trace.`]: `Actualmente no tiene ningún rastreo.`,
  [`No response received`]: `No se ha recibido ninguna respuesta`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Al pulsar “Abrir enlace para aceptar excepción” se abrirá el servidor en una pestaña nueva. Si el navegador muestra un problema de certificado, puede elegir aceptarlo y volver aquí para probarlo de nuevo.`,
  [`Open link to accept exception`]: `Abrir enlace para aceptar excepción`,
  [`Back to {name}`]: `Volver a {name}`,
  [`0 seconds`]: `0 segundos`,
  [`close.menu`]: `Cerrar menú`,
  [`open.menu`]: `Abrir menú`,
  [`Referenced from "{ref}"`]: `Referenciado desde "{ref}"`,
  [`Go to {definition}`]: `Ir a {definition}`,
  [`General`]: `General`,
  [`Servers`]: `Servidores`,
  [`External Docs`]: `Documentos externos`,
  [`Components`]: `Componentes`,
  [`Schemas`]: `Esquemas`,
  [`Responses`]: `Respuestas`,
  [`Examples`]: `Ejemplos`,
  [`Request Bodies`]: `Cuerpos de solicitud`,
  [`Security Schemes`]: `Esquemas de seguridad`,
  [`Links`]: `Enlaces`,
  [`Callbacks`]: `Devoluciones de llamada`,
  [`Terms of Service`]: `Condiciones de servicio`,
  [`Contact Name`]: `Nombre de contacto`,
  [`Contact URL`]: `URL de contacto`,
  [`Contact Email`]: `Correo electrónico de contacto`,
  [`Contact sales`]: `Contactar con ventas`,
  [`License Name`]: `Nombre de licencia`,
  [`License URL`]: `URL de licencia`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `Aceptar mensajes optimizados de MTOM/XOP`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `señal`,
  [`Tag`]: `Etiqueta`,
  [`Request Body`]: `Cuerpo de la solicitud`,
  [`Security Requirements`]: `Requisitos de seguridad`,
  [`Located In`]: `Ubicado en`,
  [`Example Value`]: `Valor de ejemplo`,
  [`External Value`]: `Valor externo`,
  [`Server`]: `Servidor`,
  [`Server Description`]: `Descripción de servidor`,
  [`Server Variables`]: `Variables de servidor`,
  [`Format`]: `Formato`,
  [`Pattern`]: `Patrón`,
  [`Multiple Of`]: `Múltiplo de`,
  [`Multiple non null slicing arguments`]: `Varios argumentos de porcionado no nulos`,
  [`Missing slicing arguments`]: `Faltan argumentos de porcionado`,
  [`Missing sized fields`]: `Faltan campos dimensionados`,
  [`Maximum`]: `Máximo`,
  [`Exclusive Maximum`]: `Máximo excluyente`,
  [`Minimum`]: `Mínimo`,
  [`Exclusive Minimum`]: `Mínimo excluyente`,
  [`Max Length`]: `Longitud máxima`,
  [`Min Length`]: `Longitud mínima`,
  [`Max Items`]: `Número máximo de elementos`,
  [`Min Items`]: `Número mínimo de elementos`,
  [`Unique Items`]: `Elementos exclusivos`,
  [`Max Properties`]: `Número máximo de propiedades`,
  [`Min Properties`]: `Número mínimo de propiedades`,
  [`Required Properties`]: `Propiedades necesarias`,
  [`Required Property Name`]: `Nombre de propiedad necesaria`,
  [`Enum`]: `Enum`,
  [`Enum Value`]: `Valor enum`,
  [`Nullable`]: `Admite nulos`,
  [`Read Only`]: `Sólo lectura`,
  [`Write Only`]: `Sólo escritura`,
  [`api`]: `api`,
  [`version`]: `versión`,
  [`username`]: `nombre de usuario`,
  [`password`]: `contraseña`,
  [`service`]: `servicio`,
  [`Allows Empty Value`]: `Permite valor vacío`,
  [`Style`]: `Estilo`,
  [`Explode`]: `Explosión`,
  [`Allow Reserved`]: `Permitir reservado`,
  [`Allow WebSocket Upgrade`]: `Permitir actualización de WebSocket`,
  [`Ref`]: `Ref`,
  [`Operation ID`]: `ID de operación`,
  [`Parameter`]: `Parámetro`,
  [`Gateway and portal settings`]: `Valores de pasarela y portal`,
  [`Targets`]: `Destinos`,
  [`Catalog Properties`]: `Propiedades de catálogo`,
  [`catalog properties`]: `propiedades de catálogo`,
  [`Attachments`]: `Anexos`,
  [`Activity Log`]: `Registro de actividad`,
  [`API Type`]: `Tipo de API`,
  [`Phase`]: `Fase`,
  [`Enable CORS`]: `Habilitar CORS`,
  [`CORS Policy`]: `Política de CORS`,
  [`Allowed Origins`]: `Orígenes permitidos`,
  [`Allow Credentials`]: `Permitir credenciales`,
  [`ID`]: `ID`,
  [`Success Content`]: `Contenido de éxito`,
  [`Error Content`]: `Contenido de error`,
  [`Attachment`]: `Adjunto`,
  [`Link`]: `Enlace`,
  [`Operation Ref`]: `Referencia de operación`,
  [`TRACE`]: `RASTREO`,
  [`No Security Requirements available`]: `No hay requisitos de seguridad disponibles`,
  [`Create a security scheme`]: `Crear un esquema de seguridad`,
  [`Create a Security Requirement`]: `Crear un requisito de seguridad`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Requerir uno de los requisitos de seguridad siguientes. Si no se selecciona, la seguridad es opcional.`,
  [`Require wrappers on fault details specified by type`]: `Requerir encapsuladores en los detalles de error especificados por tipo`,
  [`There are no "Servers"`]: `No hay "Servidores"`,
  [`Write`]: `Escritura`,
  [`Server Variable`]: `Variable de servidor`,
  [`Security Requirement`]: `Requisito de seguridad`,
  [`Security Scheme Type`]: `Tipo de esquema de seguridad`,
  [`Security Scheme`]: `Esquema de seguridad`,
  [`OAuth Flows`]: `Flujos de OAuth`,
  [`Implicit Flow`]: `Flujo implícito`,
  [`Password Flow`]: `Flujo de contraseña`,
  [`Client Credentials Flow`]: `Flujo de credenciales de cliente`,
  [`Authorization Code Flow`]: `Flujo de código de autorización`,
  [`Refresh URL`]: `URL de renovación`,
  [`schema`]: `esquema`,
  [`Allow Empty Value`]: `Permitir valor vacío`,
  [`Encoding`]: `Codificación`,
  [`This field is required`]: `Este campo es necesario`,
  [`Temporary token`]: `Señal temporal`,
  [`Access token`]: `Señal de acceso`,
  [`ID token`]: `Señal de ID`,
  [`No Security Schemes available`]: `No hay ningún esquema de seguridad disponible`,
  [`Security Scheme (Key)`]: `Esquema de seguridad (clave)`,
  [`There are no "{label}"`]: `No hay ninguna "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `Debe seleccionar al menos un esquema para definir un requisito de seguridad`,
  [`Select one or more Security Schemes`]: `Seleccionar uno o varios esquemas de seguridad`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `No hay ámbitos de OAuth disponibles para el esquema de seguridad de "{key}"`,
  [`Create a Scope`]: `Crear un ámbito`,
  [`Remove this Security Requirement`]: `Eliminar este requisito de seguridad`,
  [`Edit this Security Requirement`]: `Editar este requisito de seguridad`,
  [`Invalid Security Scheme Reference`]: `Referencia de esquema de seguridad no válido`,
  [`The following Security Schemes do not exist!`]: `Los siguientes esquemas de seguridad no existen.`,
  [`Edit source`]: `Editar origen`,
  [`OR`]: `O bien`,
  [`Required Security Schemes`]: `Esquemas de seguridad necesarios`,
  [`Optional Security`]: `Seguridad opcional`,
  [`Optional custom expression (one per line)`]: `Expresión personalizada opcional (una por línea)`,
  [`Flow Type`]: `Tipo de flujo`,
  [`OAuth2 Security Scheme`]: `Esquema de seguridad de OAuth2`,
  [`Only source editing is supported.`]: `Solo se admite la edición de origen.`,
  [`Unsupported Field.`]: `Campo no soportado.`,
  [`Select an option`]: `Seleccionar una opción`,
  [`Select {labelText}`]: `Seleccionar {labelText}`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" ya se está utilizando'`,
  [`Referenced from "{originalRef}"`]: `Referenciado en "{originalRef}"`,
  [`Go to {label}`]: `Ir a {label}`,
  [`Delete "{label}"`]: `Suprimir "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `¿Está seguro de que desea suprimir "{label}"?`,
  [`No recent items found`]: `No se han encontrado elementos recientes`,
  [`Found {numDocs} results ({took} seconds)`]: `Se han encontrado {numDocs} resultados ({took} segundos)`,
  [`No Consumer Organizations found`]: `No se ha encontrado ninguna organización de consumidores`,
  [`No Catalog Products found`]: `No se han encontrado productos de catálogo`,
  [`No APIs found`]: `No se ha encontrado ninguna API`,
  [`No Catalogs found`]: `No se han encontrado catálogos`,
  [`No Spaces found`]: `No se han encontrado espacios`,
  [`No results found`]: `No se han encontrado resultados`,
  [`No Catalog APIs found`]: `No se han encontrado API de catálogo`,
  [`No Catalogs found matching query "{query}"`]: `No se ha encontrado ningún catálogo coincidente con la consulta "{query}"`,
  [`No APIs found matching query "{query}"`]: `No se ha encontrado ninguna API que coincida con la consulta "{query}"`,
  [`No Subscriptions found`]: `No se han encontrado suscripciones`,
  [`No Plans found matching query "{query}"`]: `No se ha encontrado ningún plan que coincida con la consulta "{query}"`,
  [`No Subscriptions found matching query "{query}"`]: `No se ha encontrado ninguna suscripción que coincida con la consulta "{query}"`,
  [`No Catalog Products found matching query "{query}"`]: `No se ha encontrado ningún producto de catálogo que coincida con la consulta "{query}"`,
  [`No Catalog APIs found matching query "{query}"`]: `No se ha encontrado ninguna API de catálogo que coincida con la consulta "{query}"`,
  [`No Consumer Organizations found matching query "{query}"`]: `No se ha encontrado ninguna organización de consumidores que coincida con la consulta "{query}"`,
  [`No Applications found matching query "{query}"`]: `No se ha encontrado ninguna aplicación que coincida con la consulta "{query}"`,
  [`APIs per page`]: `API por página`,
  [`Catalogs per page`]: `Catálogos por página`,
  [`items per page`]: `elementos por página`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} de {total} API`,
  [`{min}-{max} of {total} API`]: `Api {min}-{max} de {total}`,
  [`Products per page`]: `Productos por página`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `El producto debe publicarse en el catálogo para poder configurar una nueva integración de facturación`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} de {total} productos`,
  [`{min}-{max} of {total} Product`]: `Producto {min}-{max} de {total}`,
  [`Catalog Products per page`]: `Productos de catálogo por página`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} de {total} Productos de catálogo`,
  [`{min}-{max} of {total} Catalog Product`]: `Producto de catálogo {min}-{max} de {total}`,
  [`Plans per page`]: `Planes por página`,
  [`Policies per page`]: `Políticas por página`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} de {total} planes`,
  [`{min}-{max} of {total} Plan`]: `Plan {min}-{max} de {total}`,
  [`Catalog APIs per page`]: `API de catálogo por página`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} de {total} API de catálogo`,
  [`{min}-{max} of {total} Catalog API`]: `API de catálogo {min}-{max} de {total}`,
  [`Subscriptions per page`]: `Suscripciones por página`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} de {total} suscripciones`,
  [`{min}-{max} of {total} Subscription`]: `Suscripción {min}-{max} de {total}`,
  [`Consumer Organizations per page`]: `Organizaciones de consumidores por página`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} de {total} organizaciones de consumidores`,
  [`{min}-{max} of {total} Consumer Organization`]: `Organización de consumidores {min}-{max} de {total}`,
  [`Applications per page`]: `Aplicaciones por página`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} de {total} aplicaciones`,
  [`{min}-{max} of {total} Application`]: `Aplicación {min}-{max} de {total}`,
  [`Histories per page`]: `Historiales por página`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} de {total} historiales`,
  [`{min}-{max} of {total} History`]: `Historial {min}-{max} de {total}`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `No se ha encontrado ningún plan al que se pueda suscribir. Un producto debe tener un estado "publicado" para que se pueda crear una suscripción. Una aplicación solo puede suscribirse a un plan en un producto.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Mostrando las API "{draftApiTitle}:{draftApiVersion}" publicadas`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Mostrando la organización de consumidores para "{appTitle}"`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Mostrando las suscripciones para "{productTitle}:{productVersion}"`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Mostrando los planes para "{productTitle}:{productVersion}"`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `Mostrando las API para "{productTitle}:{productVersion}"`,
  [`Showing Product for "{planTitle}"`]: `Mostrando el producto para "{planTitle}"`,
  [`Showing APIs for "{planTitle}"`]: `Mostrando las API para "{planTitle}"`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Mostrando las suscripciones para el plan: "{docTitle}" en el producto: "{docFoundIn}"`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Mostrando los planes para "{apiTitle}:{apiVersion}"`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Mostrando las API de borrador para "{catalogApiTitle}:{catalogApiVersion}"`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Mostrando historiales para "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Mostrando las suscripciones para "{appTitle}"`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Mostrando las aplicaciones para "{consumerOrgTitle}"`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Mostrando las suscripciones para "{consumerOrgTitle}"`,
  [`Showing Application for selected Subscription`]: `Mostrando la aplicación para la suscripción seleccionada`,
  [`Showing Plan for selected Subscription`]: `Mostrando el plan para la suscripción seleccionada`,
  [`Showing Product for selected Subscription`]: `Mostrando el producto para la suscripción seleccionada`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Seleccionar las API de borrador que se van a incluir en el producto "{productTitle}"`,
  [`Product Version`]: `Versión del producto`,
  [`OAuth Providers`]: `Proveedores de OAuth`,
  [`Application Type`]: `Tipo de Aplicación`,
  [`Client IDs`]: `IDs de cliente`,
  [`Save as New Version`]: `Guardar como versión nueva`,
  [`Update Selected APIs`]: `Actualizar las API seleccionadas`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. La API {titleAndVersion} se suprimirá permanentemente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. El producto {titleAndVersion}se suprimirá permanentemente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. El producto de catálogo {titleAndVersion} se suprimirá de forma permanente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. La organización de consumidores {titleAndVersion} se suprimirá permanentemente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. El grupo de consumidores {titleAndVersion} se suprimirá de forma permanente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. La política {titleAndVersion} se suprimirá permanentemente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. La suscripción {titleAndVersion} se suprimirá permanentemente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. El catálogo {titleAndVersion} se suprimirá de forma permanente. ¿Desea continuar?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. El espacio {titleAndVersion} se suprimirá de forma permanente. ¿Desea continuar?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `El producto de catálogo {titleAndVersion} se volverá a publicar. ¿Desea continuar?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `El producto de catálogo {titleAndVersion} se retirará. ¿Desea continuar?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `El producto de catálogo {titleAndVersion} quedará en desuso. ¿Desea continuar?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `El producto de catálogo {titleAndVersion} se volverá a desplegar. ¿Desea continuar?`,
  [`Put Online`]: `Poner en línea`,
  [`Take Offline`]: `Poner fuera de línea`,
  [`View Subscriptions`]: `Ver suscripciones`,
  [`View Plans`]: `Ver planes`,
  [`No Tasks found`]: `No se han encontrado tareas`,
  [`Tasks per page`]: `Tareas por página`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} de {total} tareas`,
  [`{min}-{max} of {total} Task`]: `Tarea {min}-{max} de {total}`,
  [`Decline and Send`]: `Rechazar y enviar`,
  [`View Applications`]: `Ver aplicaciones`,
  [`Publish (Preserve Subscriptions)`]: `Publicar (Conservar suscripciones)`,
  [`Update APIs`]: `Actualizar las API`,
  [`View in Catalogs`]: `Ver en catálogos`,
  [`View Consumer Organization`]: `Ver organización de consumidores`,
  [`View Application`]: `Ver aplicación`,
  [`View Plan`]: `Ver plan`,
  [`View Product`]: `Ver producto`,
  [`Create Subscription`]: `Crear suscripción`,
  [`No Applications found`]: `No se han encontrado aplicaciones`,
  [`Select a Plan to Create a Subscription to`]: `Seleccione un plan para crear una suscripción a`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Esta acción no es reversible. La aplicación {titleAndVersion} se suprimirá permanentemente. ¿Desea continuar?`,
  [`Save as new version`]: `Guardar como versión nueva`,
  [`Filter selected catalogs`]: `Filtrar catálogos seleccionados`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Seleccionar las API de borrador que se van a incluir en el producto "{productTitle}"`,
  [`Update selected APIs`]: `Actualizar las API seleccionadas`,
  [`Catalog API`]: `API de catálogo`,
  [`Catalog APIs`]: `APIs de catálogo`,
  [`View Products`]: `Ver productos`,
  [`View Draft APIs`]: `Ver borradores de API`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Mostrando productos que hacen referencia a "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `No se han encontrado planes`,
  [`View APIs`]: `Ver las API`,
  [`Document type tabs`]: `Separadores de tipo de documento`,
  [`No Products found`]: `No se han encontrado productos`,
  [`Consumer Organization`]: `Organización de consumidores`,
  [`Consumer Organizations`]: `Organizaciones de consumidores`,
  [`Catalog Product`]: `Producto de catálogo`,
  [`Catalog Products`]: `Productos de catálogo`,
  [`Search for a Product and Plan`]: `Buscar un producto y un plan`,
  [`OpenAPI Version`]: `Versión de OpenAPI`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (de REST, GraphQL o SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (de tema Kafka)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `Crear AsyncAPI (de tema Kafka)`,
  [`An error occurred during file upload`]: `Se ha producido un error al cargar el archivo`,
  [`Delete uploaded file`]: `Suprimir archivo cargado`,
  [`Copied!`]: `¡Copiado!`,
  [`Uploading`]: `Subiendo`,
  [`Validation icon`]: `Icono de validación`,
  [`Validation error`]: `Error de validación`,
  [`Uploading image..`]: `Cargando imagen...`,
  [`Unsupported media type {providedFileType}`]: `Tipo de soporte {providedFileType} no admitido`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Seleccione un archivo soportado {expectedFileTypes} y vuelva a intentarlo`,
  [`Pasted`]: `Pegado`,
  [`Enter the API summary details.`]: `Especifique los detalles de resumen de API.`,
  [`Define your Kafka cluster connection details.`]: `Defina los detalles de conexión de clúster de Kafka.`,
  [`Channel`]: `Canal`,
  [`Describe your Kafka topic and its associated schema.`]: `Describa el tema Kafka y su esquema asociado.`,
  [`AsyncAPI specification version`]: `Versión de especificación de AsyncAPI`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Nombre que describe la API y ayuda a los desarrolladores a descubrirla en el Portal de desarrollador.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Una versión de la definición de API. La combinación de nombre y versión identifica de forma exclusiva la definición de API.`,
  [`Description (optional)`]: `Descripción (opcional)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Introduzca la API y proporcione información útil a los desarrolladores que la consumen.`,
  [`Summary (optional)`]: `Resumen (opcional)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Breve resumen de los sucesos contenidos en esta API. Máximo de 1000 caracteres.`,
  [`Bootstrap servers`]: `Servidores de programa de arranque`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Una lista de los intermediarios de Kafka en el clúster, separados por comas.`,
  [`Secure the API using an API Key and Secret`]: `Proteger la API utilizando una clave y un secreto de API`,
  [`The credential provided should be in SASL-PLAIN format`]: `La credencial proporcionada debe estar en formato SASL-PLAIN`,
  [`Publish this API:`]: `Publicar esta API:`,
  [`Creates a product`]: `Crea un producto`,
  [`Associates this API to the product`]: `Asocia esta API al producto`,
  [`Publish the product to the Sandbox catalog`]: `Publicar el producto en el catálogo de recinto de pruebas`,
  [`Associates the product to the test app`]: `Asocia el producto a la aplicación de prueba`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Se ha cargado y validado satisfactoriamente un esquema en formato "{fileType}".`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `El archivo cargado no es un esquema Avro válido y no formará parte del API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Respuesta del servidor: "{err}". Pulse "Atrás" para volver a la página de definición de API.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Respuesta del servidor: "{err}". Pulse "Finalizado" para salir del asistente.`,
  [`Topic name`]: `Nombre de tema`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `El nombre del tema que desea exponer como parte de esta API. Un tema es una secuencia de mensajes con nombre.`,
  [`Schema for message body in .avsc format (optional)`]: `Esquema del cuerpo de mensaje en formato .avsc (opcional)`,
  [`Drag and drop files here, or click to upload`]: `Arrastre y suelte los archivos aquí o pulse para cargar`,
  [`About this step`]: `Acerca de este paso`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Los sucesos se pueden describir como API asíncronas porque los consumidores reciben sucesos a medida que pasan a estar disponibles. Por otra parte, las API síncronas, como por ejemplo REST, solo reciben respuestas cuando las solicitan.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Las API asíncronas de sucesos se documentan mediante la especificación AsyncAPI.`,
  [`API secret`]: `Secreto de API`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Definición AsyncAPI 2.1.0 generada`,
  [`API creating`]: `Creación de API`,
  [`An error occurred while creating your document`]: `Se ha producido un error al crear el documento`,
  [`Publishing your API`]: `Publicando la API`,
  [`Your API has been published`]: `La API se ha publicado`,
  [`An error occurred while publishing your API`]: `Se ha producido un error al publicar la API`,
  [`Configure security for this API`]: `Configurar la seguridad para esta API`,
  [`Cluster connection security`]: `Seguridad de conexión de clúster`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Proporcione las credenciales que usarán los servicios de pasarela de sucesos para conectarse con el clúster cuando manejen solicitudes dirigidas a esta API. Puede cambiar estos valores más adelante en el editor. Para obtener información adicional, consulte la documentación sobre la creación de credenciales.`,
  [`SASL Username`]: `Nombre de usuario de SASL`,
  [`SASL Password`]: `Contraseña de SASL`,
  [`Transport CA certificate (.pem file - optional)`]: `Certificado de CA de transporte (archivo .pem - opcional)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Si se proporcionan, el servicio de pasarela de sucesos las usará para proporcionar cifrado TLS a la comunicación entre la pasarela y el clúster Kafka.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Describa los detalles de API del origen de sucesos Apache Kafka para generar un documento AsyncAPI.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `A continuación, puede publicar el documento AsyncAPI para exponer el origen de sucesos a los desarrolladores de aplicaciones, que pueden configurar sus aplicaciones para suscribirlas a la secuencia de sucesos relacionada.`,
  [`Set up security for your API if you want to control who can access it.`]: `Configure la seguridad de la API si desea controlar quién puede acceder a ella.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `El acceso al API será gestionado por un servicio de pasarela de sucesos que espera recibir un secreto y una contraseña de API válidos.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Las pasarelas requieren que los clientes cifren su comunicación con TLS.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Una vez creada el API, pulse 'Editar API' para añadir más detalles como, por ejemplo, los detalles de seguridad para conectarse al clúster Kafka desde el servicio de pasarela de sucesos.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `A continuación, puede publicar la API, poniéndola a disposición de los desarrolladores que podrán descubrir y solicitar acceso a la API y utilizar la información para establecer las aplicaciones que se van a consumir del origen de sucesos.`,
  [`Pending backend response`]: `Respuesta de programa de fondo pendiente`,
  [`Complete`]: `Completado`,
  [`Rate limiting is only applied to enforced APIs`]: `La limitación de velocidad solo se aplica a las API vigentes`,
  [`No Plans Available`]: `No hay planes disponibles`,
  [`Modal content is not provided`]: `No se proporciona contenido modal`,
  [`Modal heading is not provided`]: `No se proporciona ninguna cabecera modal`,
  [`Edit Rate Limit`]: `Editar límite de velocidad`,
  [`External Documentation`]: `Documentación externa`,
  [`No Label Available`]: `No hay ninguna etiqueta disponible`,
  [`optional`]: `opcional`,
  [`Uploading image...`]: `Cargando imagen...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Adjunte archivos arrastrándolos, soltándolos, seleccionándolos o pegándolos.`,
  [`Response name`]: `Nombre de la respuesta`,
  [`Click Add to add a new {resource}.`]: `Pulse Añadir para añadir un nuevo {resource}.`,
  [`Schema Name (Key)`]: `Nombre de esquema (clave)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Espacio de nombres`,
  [`Attribute`]: `Atributo`,
  [`Wrapped`]: `En derivación`,
  [`Example`]: `Ejemplo`,
  [`Example Name (Key)`]: `Nombre de ejemplo (clave)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" ya se utiliza`,
  [`Name (Key)`]: `Nombre (clave)`,
  [`Content Type (Key)`]: `Tipo de contenido (clave)`,
  [`Header name`]: `Nombre de la cabecera`,
  [`Reference`]: `Referencia`,
  [`Security Scheme Name (Key)`]: `Nombre de esquema de seguridad (clave)`,
  [`Choose a Reference`]: `Elija una referencia`,
  [`Reference from`]: `Referencia de`,
  [`Content Type`]: `Tipo de contenido`,
  [`array`]: `matriz`,
  [`boolean`]: `booleano`,
  [`integer`]: `entero`,
  [`number`]: `número`,
  [`object`]: `objeto`,
  [`string`]: `serie`,
  [`query`]: `consulta`,
  [`cookie`]: `cookie`,
  [`matrix`]: `matriz`,
  [`label`]: `etiqueta`,
  [`simple`]: `simple`,
  [`form`]: `formulario`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `Proveedor de OAuth (nombre)`,
  [`Authenticate using User Registry (name)`]: `Autenticar utilizando el registro de usuarios (nombre)`,
  [`Bearer Format`]: `Formato portador`,
  [`Server Variable Name`]: `Nombre de variable de servidor`,
  [`Verb (Key)`]: `Verbo (clave)`,
  [`Parameter Definitions`]: `Definiciones de parámetros`,
  [`Path Parameters`]: `Parámetros de vía de acceso`,
  [`Media Type List`]: `Lista de tipos de soportes`,
  [`Medium`]: `Medio`,
  [`Schemes List`]: `Lista de esquemas`,
  [`Small`]: `Pequeño`,
  [`Response Definitions`]: `Definiciones de respuesta`,
  [`Terms Of Service`]: `Condiciones de servicio`,
  [`Url`]: `Url`,
  [`Untitled Parameter`]: `Parámetro sin título`,
  [`Parameter Name`]: `Nombre de parámetro`,
  [`Mime Type`]: `Tipo MIME`,
  [`Async API`]: `API Asínc`,
  [`Channels`]: `Canales`,
  [`Operation Traits`]: `Características de la operación`,
  [`Messages`]: `Mensajes`,
  [`Correlation IDs`]: `ID de correlación`,
  [`Traits`]: `Características`,
  [`Message Traits`]: `Características del mensaje`,
  [`Bindings`]: `Enlaces`,
  [`Server Bindings`]: `Enlaces de servidor`,
  [`Channel Bindings`]: `Enlaces de canal`,
  [`Operation Bindings`]: `Enlaces de operación`,
  [`Message Bindings`]: `Enlaces de mensaje`,
  [`Protocol Version`]: `Versión de protocolo`,
  [`Define multiple messages`]: `Definir varios mensajes`,
  [`Schema Format`]: `Formato de esquema`,
  [`Unsupported Field`]: `Campo no soportado`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Característica de la operación`,
  [`Message Trait`]: `Característica del mensaje`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Utilice la política de conmutador de operación para aplicar una sección del ensamblaje a una operación específica.`,
  [`HTML page`]: `Página HTML`,
  [`Rule`]: `Regla`,
  [`Origin`]: `Origen`,
  [`Property Name (Key)`]: `Nombre de propiedad (clave)`,
  [`Catalog Name (Key)`]: `Nombre de catálogo (clave)`,
  [`Property Overrides`]: `Alteraciones temporales de propiedad`,
  [`Property Name`]: `Nombre de propiedad`,
  [`Property Value`]: `Valor de propiedad`,
  [`About subscriptions and plans`]: `Acerca de suscripciones y planes`,
  [`Subscription Plan Name`]: `Nombre de plan de suscripción`,
  [`Select plan`]: `Seleccionar plan`,
  [`Select the plan to migrate application subscriptions from`]: `Seleccione el plan del que migrar las suscripciones de aplicación`,
  [`Select subscriptions`]: `Seleccionar suscripciones`,
  [`Select the application subscriptions to migrate`]: `Seleccione las suscripciones de aplicación que desea migrar`,
  [`Select the product to migrate application subscriptions to`]: `Seleccione el producto al que desea migrar las suscripciones de aplicación`,
  [`Select the plan to migrate application subscriptions to`]: `Seleccione el plan al que migrar las suscripciones de aplicación`,
  [`Message successfully sent to {sent} owner(s)`]: `Mensaje enviado correctamente a {sent} propietario(s)`,
  [`{failed}/{sent} messages failed to send`]: `No han podido enviarse {failed}/{sent} mensajes`,
  [`Failed email addresses`]: `Direcciones de correo electrónico erróneas`,
  [`Failed to save the API`]: `No se ha podido guardar la API`,
  [`and {numExtra} more...`]: `y {numExtra} más...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Copiar en el portapapeles la lista de direcciones de correo electrónico en las que ha fallado el envío`,
  [`From`]: `Desde`,
  [`Message Owner`]: `Propietario del mensaje`,
  [`Message owner`]: `Propietario del mensaje`,
  [`Message owners`]: `Propietarios de mensajes`,
  [`Message group`]: `Grupo de mensajes`,
  [`Select consumer organizations`]: `Seleccionar organizaciones de consumidores`,
  [`Message consumer organization owners`]: `Propietarios de organización de consumidores de mensajes`,
  [`Message consumer organization owner`]: `Propietario de la organización consumidora de mensajes`,
  [`Message provider organization owner`]: `Propietario de organización de proveedores de mensajes`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `El identificador del clúster de Kafka relacionado con esta política de invocación`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Una lista separada por comas de pares host:port que se utilizarán para establecer conexiones con el clúster de Kafka`,
  [`The username to use when connecting to the Kafka cluster.`]: `El nombre de usuario que se debe utilizar al conectarse al clúster de Kafka.`,
  [`The password to use when connecting to the Kafka cluster.`]: `La contraseña que se debe utilizar al conectarse al clúster de Kafka.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Servicio de pasarela de sucesos`,
  [`Event gateway management client keystore`]: `Almacén de claves del cliente de gestión de pasarelas de sucesos`,
  [`Edit Event gateway Service`]: `Editar servicio de pasarela de sucesos`,
  [`Configure Event Gateway Service`]: `Configurar servicio de pasarela de sucesos`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Configurar un servicio de pasarela de sucesos para proteger y aplicar las API asíncronas`,
  [`Catalogs with published product`]: `Catálogos con producto publicado`,
  [`Cluster Config ID`]: `ID de configuración de clúster`,
  [`SASL mechanism`]: `Mecanismo SASL`,
  [`Security protocol`]: `Protocolo de seguridad`,
  [`Transport CA certificate`]: `Certificado de CA de transporte`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `El tamaño de archivo de archivo es {size}. Los tipos de archivo admitidos son json, xml, yml, yaml y zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Los tipos de archivo admitidos son json, xml, yml y yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Los tipos de archivo admitidos son json, wsdl, xml, xsd, yml, yaml y zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Los tipos de archivo admitidos son json, wsdl, xml, yml y yaml.`,
  [`Select a file`]: `Seleccione un archivo`,
  [`or specify a file URL`]: `o especifique un URL de archivo`,
  [`Generated OpenAPI {version} definition`]: `Definición de OpenAPI {version} generada`,
  [`User registry type`]: `Tipo de registro de usuarios`,
  [`User registry types`]: `Tipos de registro de usuarios`,
  [`User registry type {title} has been created.`]: `Se ha creado el tipo de registro de usuarios {title}.`,
  [`User registry type {title} has been updated.`]: `Se ha actualizado el tipo de registro de usuarios {title}.`,
  [`Failed to create user registry type {title}.`]: `No se ha podido crear el tipo de registro de usuarios {title}.`,
  [`Failed to update user registry type {title}.`]: `No se ha podido actualizar el tipo de registro de usuarios {title}.`,
  [`Failed to update user registry {title}.`]: `No se ha podido actualizar el registro de usuarios {title}.`,
  [`Custom user registry`]: `Registro de usuarios personalizado`,
  [`Create custom user registry`]: `Crear registro de usuarios personalizado`,
  [`Edit custom user registry`]: `Editar registro de usuarios personalizado`,
  [`Create user registry type`]: `Crear tipo de registro de usuarios`,
  [`Edit user registry type`]: `Editar tipo de registro de usuarios`,
  [`Configure user authentication using a custom external user registry`]: `Configurar la autenticación de usuario mediante un registro de usuarios externo personalizado`,
  [`Secured Endpoint`]: `Punto final asegurado`,
  [`Configuration schema`]: `Esquema de configuración`,
  [`About custom user registries`]: `Acerca de los registros de usuarios personalizados`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Los registros de usuarios personalizados pueden utilizarse para autenticar usuarios en el Portal del desarrollador, pero no se pueden utilizar para proteger las API. Para configurar un registro de usuarios personalizado como un recurso en el Gestor de API, se debe crear el directorio de usuario externo y hacerlo disponible para utilizarlo con el ecosistema de API Connect.`,
  [`Update Wsdl`]: `Actualizar Wsdl`,
  [`Update Existing WSDL service`]: `Actualizar servicio WSDL existente`,
  [`Format of selected WSDL has been successfully validated`]: `El formato del WSDL seleccionado se ha validado satisfactoriamente`,
  [`Select the target WSDL file to update from`]: `Seleccione el archivo WSDL de destino desde el que se va a actualizar`,
  [`Selected WSDL has been successfully uploaded`]: `El WSDL seleccionado se ha cargado satisfactoriamente`,
  [`Services available in selected API`]: `Servicios disponibles en la API seleccionada`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Habilitar editor de Open API anterior para documentos de OpenAPI 2.0 (en desuso)`,
  [`Unknown`]: `Desconocido`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleCatalogCount} catálogo(s) oculto(s) debido a la incompatibilidad con el tipo de pasarela especificado en la API o el producto`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} espacio(s) oculto(s) debido a la incompatibilidad con el tipo de pasarela especificado en la API o el producto`,
  [`Keep in mind`]: `Recuerde`,
  [`Only one type of enforced APIs is allowed per product`]: `Sólo se permite un tipo de API vigentes por producto`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `{incompatibleAPIsCount} API(s) no pueden seleccionarse debido a la incompatibilidad con el tipo de pasarela especificado en el producto`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `No se pueden seleccionar {incompatibleProductsCount} productos debido a una incompatibilidad con el tipo de pasarela especificado en la API`,
  [`Unenforced`]: `No vigente`,
  [`Create a new product by adding APIs and plans.`]: `Cree un producto nuevo añadiendo API y planes.`,
  [`Rate limits are only applied to enforced APIs.`]: `Los límites de velocidad sólo se aplican a las API vigentes.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Añada planes y límites de API al producto. Los límites de plan sólo se aplican a las API vigentes, y las API no vigentes no tendrán límite.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Añada planes y límites de velocidad al producto. Los planes sólo se aplican a las API vigentes, y las API no vigentes no tendrán límite.`,
  [`Delete plan`]: `Suprimir plan`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Seleccionar servicio de pasarela de sucesos para API asíncronas. Para todos los demás tipos de API, seleccione DataPower API Gateway o la versión compatible con v5. Para actualizar este valor, elimine primero las API asociadas.`,
  [`Edit API list`]: `Editar lista de API`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Las API vigentes en este producto requieren más de un tipo de pasarela.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `El {apiType} vigente en este producto requiere {gatewayType}.`,
  [`OpenAPI 2.0 API(s)`]: `API de OpenAPI 2.0`,
  [`OpenAPI 3.0 API(s)`]: `API de OpenAPI 3.0`,
  [`AsyncAPI(s)`]: `AsyncAPI(s)`,
  [`Select the gateway type for this Product`]: `Seleccione el tipo de pasarela para este producto`,
  [`Added default plan`]: `Se ha añadido plan predeterminado`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Publique el producto en el catálogo de recinto de pruebas. Esto asocia el producto a la aplicación de prueba.`,
  [`cluster`]: `clúster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Mensaje del rechazador`,
  [`Vendor extensions feature disabled`]: `Característica de extensiones de proveedor inhabilitada`,
  [`No configured gateways found`]: `No se ha encontrado ninguna pasarela configurada`,
  [`No OpenAPI extensions associated with the configured gateway`]: `No hay extensiones de OpenAPI asociadas con la pasarela configurada`,
  [`Extension`]: `Extensión`,
  [`Select the extensions to add to the API document`]: `Seleccione las extensiones que hay que añadir al documento de API`,
  [`(Operations: {operations})`]: `(Operaciones: {operations})`,
  [`Failed to get application`]: `No se ha podido obtener la aplicación`,
  [`Failed to get credentials for application`]: `No se han podido obtener las credenciales de la aplicación`,
  [`Failed to get published product`]: `No se ha podido obtener el producto publicado`,
  [`Failed to get the published API`]: `No se ha podido obtener el API publicada`,
  [`API must be activated to use the Test tool`]: `Hay que activar el API para utilizar la herramienta de prueba`,
  [`Import from Asset repository`]: `Importar desde repositorio de activos`,
  [`Untitled {componentLabel}`]: `Sin título {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Su métrica de licencia requiere un servicio de análisis para realizar un seguimiento del uso. Pulse "Registrar servicio" para configurar un servicio de análisis.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Todos los servicios de pasarela requieren un servicio de análisis para realizar un seguimiento del uso. Pulse "Asociar servicio de análisis" para cada pasarela para cumplir con los términos de licencia.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `El catálogo es incompatible con el tipo de pasarela especificado en la API o el producto`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `El espacio es incompatible con el tipo de pasarela especificado en la API o el producto`,
  [`Preserve Subscription`]: `Conservar suscripción`,
  [`Publish validation`]: `Validación de la publicación`,
  [`Publish validations`]: `Publicar validaciones`,
  [`Edit publish validations`]: `Editar validaciones de publicación`,
  [`The following validations will be performed when publishing a product`]: `Las validaciones siguientes se realizarán al publicar un producto`,
  [`There are no publish validations enabled`]: `No hay validaciones de publicación habilitadas`,
  [`Publish validations have been updated`]: `Las validaciones de publicación se han actualizado`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Proporcione la entidad emisora de certificados (CA) que el servicio de pasarela de sucesos utiliza para confiar en el certificado de servidor Kafka de fondo. Si el servidor está utilizando un certificado emitido por una autoridad conocida, no es necesario que proporcione la CA.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `El valor especificado para los servidores de programa de arranque no es válido. Especifique el host y el puerto de cada intermediario de Kafka en el clúster, separados por comas. Por ejemplo, broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `Cargar extensión de OpenAPI`,
  [`Add OpenAPI Extension`]: `Añadir extensión de OpenAPI`,
  [`Gateway Endpoint`]: `Punto final de pasarela`,
  [`API Dashboard`]: `Panel de control de API`,
  [`API call data`]: `Datos de llamada de API`,
  [`Product Dashboard`]: `Panel de control de productos`,
  [`API Product consumption metrics`]: `Métricas de consumo de productos de API`,
  [`Monitoring Latency Dashboard`]: `Supervisión de panel de control de latencia`,
  [`Data about the time taken to serve API calls`]: `Datos sobre el tiempo que se ha tardado en servir las llamadas de API`,
  [`Monitoring Status Dashboard`]: `Supervisión del panel de control de estado`,
  [`API Error and success information`]: `Información sobre errores y éxitos de la API`,
  [`Usage Dashboard`]: `Panel de control de uso`,
  [`Usage for gateway {gateway}`]: `Uso de la pasarela {gateway}`,
  [`Latency for gateway {gateway}`]: `Latencia de la pasarela {gateway}`,
  [`Most popular products, APIs and applications`]: `Productos, API y aplicaciones más populares`,
  [`Consumption Dashboard`]: `Panel de control de consumo`,
  [`Total API volume useful for auditing and compliance`]: `Volumen total de API útil para auditoría y conformidad`,
  [`Monthly Summary by Status Code`]: `Resumen mensual por código de estado`,
  [`Monthly Summary Data by Status Code`]: `Datos de resumen mensual por código de estado`,
  [`Daily Summary by Status Code`]: `Resumen diario por código de estado`,
  [`Daily Summary Data by Status Code`]: `Datos de resumen diario por código de estado`,
  [`Response time (ms)`]: `Tiempo de respuesta (ms)`,
  [`Datetime`]: `Fecha y hora`,
  [`Transaction ID:`]: `ID de transacción:`,
  [`Other`]: `Otros`,
  [`Date time`]: `Fecha y hora`,
  [`Total calls`]: `Total de llamadas`,
  [`Date`]: `Fecha`,
  [`Month`]: `Mes`,
  [`Minimum response time`]: `Tiempo mínimo de respuesta`,
  [`Maximum response time`]: `Tiempo máximo de respuesta`,
  [`Average response time`]: `Tiempo medio de respuesta`,
  [`Total API calls`]: `Llamadas de API totales`,
  [`Total APIs`]: `Total de API`,
  [`APIs called`]: `API llamadas`,
  [`Total products`]: `Total de productos`,
  [`products called`]: `productos llamados`,
  [`Total errors`]: `Errores totales`,
  [`Status codes (detailed)`]: `Códigos de estado (detallados)`,
  [`Response time percentiles`]: `Percentiles de tiempo de respuesta`,
  [`API calls per day`]: `Llamadas de API por día`,
  [`Time zone:`]: `Huso horario:`,
  [`Time zone`]: `Huso horario`,
  [`Toggle axes`]: `Conmutar ejes`,
  [`Datetime:`]: `Fecha y hora:`,
  [`datetime per 30 seconds`]: `Fecha y hora por 30 segundos`,
  [`Datetime (local time)`]: `Fecha y hora (hora local)`,
  [`Data usage (bytes)`]: `Uso de datos (bytes)`,
  [`Time to Serve Request`]: `Tiempo para atender la solicitud`,
  [`API name`]: `Nombre de API`,
  [`Product name`]: `Nombre de producto`,
  [`Data usage (bytes received)`]: `Uso de datos (bytes recibidos)`,
  [`Data usage (bytes sent)`]: `Uso de datos (bytes enviados)`,
  [`Response times (>1s)`]: `Tiempos de respuesta (>1s)`,
  [`milliseconds`]: `milisegundos`,
  [`errors`]: `errores`,
  [`Success Rate`]: `Tasa de éxito`,
  [`Errors Data`]: `Datos de errores`,
  [`Success Data`]: `Datos de éxitos`,
  [`Total consumer organizations`]: `Total de organizaciones de consumidores`,
  [`making API calls`]: `Realización de llamadas de API`,
  [`Applications per plan`]: `Aplicaciones por plan`,
  [`Top products by calls`]: `Productos principales por llamadas`,
  [`Top APIs by calls`]: `Principales API por llamadas`,
  [`Top client IP addresses by calls`]: `Principales direcciones IP de cliente por llamadas`,
  [`Client IP address`]: `Dirección IP del cliente`,
  [`Top APIs by request size`]: `Principales API por tamaño de solicitud`,
  [`Top APIs by response size`]: `Principales API por tamaño de respuesta`,
  [`Top APIs by response time`]: `Principales API por tiempo de respuesta`,
  [`Top applications by calls`]: `Principales aplicaciones por llamadas`,
  [`Dashboards`]: `Paneles de control`,
  [`Discover`]: `Descubrir`,
  [`UTC`]: `UTC`,
  [`Local time`]: `Nombre local`,
  [`URI`]: `URI`,
  [`Response code`]: `Código de respuesta`,
  [`Total time elapsed (ms)`]: `Tiempo total transcurrido (ms)`,
  [`Timestamp`]: `Indicación de fecha y hora`,
  [`Method`]: `Método`,
  [`Gateway IP`]: `IP de pasarela`,
  [`Gateway Operations Dashboard`]: `Panel de control de operaciones de pasarela`,
  [`Usage and latency information per gateway`]: `Información de uso y latencia por pasarela`,
  [`Transaction ID`]: `ID de transacción`,
  [`Filters`]: `Filtros`,
  [`Fields`]: `Campos`,
  [`Clear all`]: `Borrar todo`,
  [`API Event`]: `Suceso de API`,
  [`API Assembly Policy Latencies`]: `Latencias de política de ensamblaje de API`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `El diagrama siguiente muestra el aumento del tiempo de latencia en milisegundos a medida que la solicitud avanzaba a través de las políticas de ensamblaje de API. Puede resultar útil para determinar puntos lentos o cuellos de botella en el conjunto de API.`,
  [`Collapse`]: `Contraer`,
  [`Expand`]: `Expandir`,
  [`enter name`]: `especifique el nombre`,
  [`Enter value`]: `Especificar valor`,
  [`Time range`]: `Rango de tiempo`,
  [`Select one of the following time range filters`]: `Seleccione uno de los filtros de rango de tiempo siguientes`,
  [`Last 1 hour`]: `Última hora`,
  [`Last {number} minutes`]: `Últimos {number} minutos`,
  [`Last {number} hours`]: `Últimas {number} horas`,
  [`Last {number} days`]: `Últimos {number} días`,
  [`Start date`]: `Fecha de inicio`,
  [`End date`]: `Fecha de finalización`,
  [`Select from the following dropdowns and enter a value`]: `Seleccione entre los siguientes menús desplegables y especifique un valor`,
  [`Select one of the following type filters`]: `Seleccione uno de los filtros de tipo siguientes`,
  [`All events`]: `Todos los sucesos`,
  [`Success only (2xx, 3xx)`]: `Solo éxito (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Solo errores (4xx, 5xx)`,
  [`Import/Export`]: `Importar/exportar`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Acerca de {totalItems} resultados ({searchTime} segundos) en {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Acerca de los resultados de {totalItems} ({searchTime} segundos) en {time} ({utcTime})`,
  [`API calls`]: `Llamadas de API`,
  [`Export as CSV`]: `Exportar como CSV`,
  [`Export as JSON`]: `Exportar como JSON`,
  [`Export as PNG`]: `Exportar como PNG`,
  [`Export as JPG`]: `Exportar como JPG`,
  [`Edit filter`]: `Editar filtro`,
  [`Edit query`]: `Editar consulta`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Esta es una consulta compartida. Las modificaciones se realizarán para todos los usuarios con los que se comparte esta consulta.`,
  [`This is a shared query. This will delete it for all users.`]: `Esta es una consulta compartida. Esto la suprimirá para todos los usuarios.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname} se ha actualizado para todos los usuarios de la organización de proveedores {org}`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname} se ha actualizado para todos los usuarios del espacio {space}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname} se ha actualizado para todos los usuarios del catálogo {catalog}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname} se ha actualizado para todos los usuarios en el gestor de nube`,
  [`Stop sharing`]: `Detener uso compartido`,
  [`Share query`]: `Compartir consulta`,
  [`Share filter`]: `Compartir filtro`,
  [`You do not have permissions to delete this filter`]: `No tiene permisos para suprimir este filtro`,
  [`You do not have permissions to delete this query`]: `No tiene permisos para suprimir esta consulta`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `¿Seguro que desea compartir la consulta {queryTitle} en el ámbito de la organización de proveedores {org}?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `¿Seguro que desea compartir la consulta {queryTitle} en el ámbito de espacio {space}?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `¿Seguro que desea compartir la consulta {queryTitle} en el ámbito de catálogo {catalog}?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `¿Seguro que desea compartir la consulta {queryTitle} en el ámbito de nube?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `¿Seguro que desea dejar de compartir la consulta {queryTitle}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `¿Seguro que desea dejar de compartir la consulta {queryTitle} en el ámbito de la organización de proveedores {org}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `¿Seguro que desea dejar de compartir la consulta {queryTitle} en el ámbito de espacio {space}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `¿Seguro que desea dejar de compartir la consulta {queryTitle} en el ámbito de catálogo {catalog}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `¿Seguro que desea dejar de compartir la consulta {queryTitle} en el ámbito de nube?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `¿Seguro que desea suprimir la consulta {queryTitle}?`,
  [`At least one query parameter expected`]: `Se esperaba al menos un parámetro de consulta`,
  [`A provided query parameter is incomplete`]: `Un parámetro de consulta proporcionado está incompleto`,
  [`Could not parse query string`]: `No se ha podido analizar la serie de consulta`,
  [`An unsupported query parameter was provided: {query}`]: `Se ha proporcionado un parámetro de consulta no admitido: {query}`,
  [`Invalid timeframe value: {value}`]: `Valor de periodo de tiempo no válido: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `El valor de {field} debe seguir a la notación de fecha y hora tal como se define en RFC 3339, sección 5.6. Ejemplos, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `El operador {operator} no está admitido para el campo {field}`,
  [`The value of {field} must be a number`]: `El valor de {field} debe ser un número`,
  [`Delete query`]: `Suprimir consulta`,
  [`Delete filter`]: `Suprimir filtro`,
  [`Save query`]: `Guardar consulta`,
  [`Save filter`]: `Guardar filtro`,
  [`Save filter as`]: `Guardar filtro como`,
  [`Save query as`]: `Guardar consulta como`,
  [`Save as...`]: `Guardar como...`,
  [`Save as`]: `Guardar como`,
  [`Paste query string to load the query`]: `Pegar serie de consulta para cargar la consulta`,
  [`Copy or paste filter string to load filters`]: `Copiar o pegar una serie de filtro para cargar filtros`,
  [`Query string`]: `Serie de consulta`,
  [`View filter`]: `Ver filtro`,
  [`View filter string`]: `Serie de filtro de vista`,
  [`Date (local time)`]: `Fecha (hora local)`,
  [`Coming soon, a new built in analytics feature`]: `Próximamente, una nueva característica de analítica incorporada`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `La analítica ayudará a recopilar información sobre el tiempo de ejecución de la API, gestionar los niveles de servicio, establecer cuotas, establecer controles, configurar políticas de seguridad, gestionar comunidades y analizar tendencias.`,
  [`Documentation`]: `Documentación`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Profundice en los tipos de datos de tiempo de ejecución de la API y en cómo utilizar los grupos s3 de AWS para visualizarlos.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Conozca qué datos están disponibles en la actualidad y cómo acceder a ellos.`,
  [`Next steps`]: `Próximos pasos`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `El servicio Secrets Manager seleccionado no gestiona ningún certificado.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Se necesita un certificado para proteger la conexión entre API Connect y el dominio del punto final de gestión de pasarela. Los certificados también se necesitan para proteger las conexiones entre la pasarela y los dominios que maneja. Todos los certificados deben almacenarse en un servicio de Secrets Manager. El servicio proporciona un repositorio seguro para los certificados y ayuda a evitar interrupciones enviando notificaciones al usuario cuando los certificados están a punto de caducar en la integración con el servicio Event Notifications. Para empezar,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Los certificados se necesitan para proteger las conexiones entre la pasarela y los dominios que maneja. Todos los certificados deben almacenarse en un servicio de Secrets Manager. El servicio proporciona un repositorio seguro para los certificados y ayuda a evitar interrupciones enviando notificaciones al usuario cuando los certificados están a punto de caducar en la integración con el servicio Event Notifications. Para empezar,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Cambiar el Secrets Manager eliminará todas las asignaciones de certificados y paquetes de CA existentes.`,
  [`Create a [Secrets Manager]({link}) service`]: `Cree un servicio de [Secrets Manager]({link})`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Importe los certificados y paquetes de CA necesarios en el servicio de Secrets Manager.`,
  [`No Secrets Manager services available`]: `No hay servicios de Secrets Manager disponibles`,
  [`Select Secrets Manager service`]: `Seleccione el servicio de Secrets Manager`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Seleccione el servicio de [Secrets Manager]({link}) que gestiona los certificados para esta pasarela.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `El servicio de Secrets Manager debe gestionar los certificados para el dominio del punto final de gestión de pasarela y los dominios que maneja la pasarela. Además, debe utilizar IBM Cloud IAM para autorizar el servicio de instancia reservada de API Connect para acceder al servicio de Secrets Manager.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `A continuación, seleccione el certificado para el dominio del punto final de gestión. Seleccione también el paquete de CA correspondiente si el certificado no lo ha asignado una entidad emisora de certificados conocida. El servicio de Secrets Manager debe gestionar el certificado y el paquete.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `A continuación, especifique los dominios que esta pasarela va a gestionar. Se utiliza la técnica SNI; como resultado, se puede dar servicio a servir varios dominios a través de la misma dirección IP y el mismo puerto sin que sea necesario el mismo certificado. Se da soporte al formato de comodín. El dominio (catch-all) predeterminado ‘*’ se debe incluir como la entrada final de la tabla. Especifique otros nombres de dominio según sea necesario, especificando en cada caso un certificado gestionado por el servicio de Secrets Manager.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Utilizando [IBM Cloud IAM]({link}), autorice el servicio de instancia reservada de API Connect para acceder al servicio de Secrets Manager.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Actualice las asignaciones existentes de certificados y paquetes de CA cargándolas desde Secrets Manager.`,
  [`Access s3 data`]: `Acceso a datos de s3`,
  [`Create resource`]: `Crear recurso`,
  [`Connect analytics data in s3 bucket with AWS`]: `Conectar datos de analítica en el grupo s3 con AWS`,
  [`AWS account`]: `Cuenta AWS`,
  [`AWS account number`]: `Número de cuenta AWS`,
  [`Enter your 12 digit aws account id`]: `Especifique el ID de cuenta de aws de 12 dígitos`,
  [`Account id must be 12 digits`]: `El ID de cuenta debe tener 12 dígitos`,
  [`Failed to update AWS account id.`]: `No se ha podido actualizar el ID de cuenta AWS.`,
  [`You have limited access to the catalogs.`]: `Tiene acceso limitado a los catálogos.`,
  [`You have limited access to the spaces.`]: `Tiene acceso limitado a los espacios.`,
  [`Contact admin to get more access.`]: `Póngase en contacto con el administrador para obtener más acceso.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `No puede seleccionar este catálogo. Póngase en contacto con un administrador si necesita acceso.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `No puede seleccionar este espacio. Póngase en contacto con un administrador si necesita acceso.`,
  [`You have limited access to the product billing.`]: `Tiene acceso limitado a la facturación del producto.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `Se ha actualizado correctamente el ID de cuenta AWS. Puede tardar unos segundos en otorgar acceso a los datos de S3.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Falta una organización o un grupo en el catálogo elegido.`,
  [`Secrets Manager instance`]: `Instancia de Secrets Manager`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Las organizaciones de consumidores con propietarios a los que les falta un correo electrónico no se pueden seleccionar`,
  [`You currently don’t have any data.`]: `Actualmente no tiene ningún dato.`,
  [`You currently don’t have any organizations.`]: `Actualmente no tiene ninguna organización.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Solicitud para suscribir la app {appname} para planificar {plan} en el producto {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Solicitud para {operation} el producto: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Solicitud para actualizar la aplicación {appname} a producción`,
  [`Onboarding request for {email}`]: `Solicitud de incorporación para {email}`,
  [`Product lifecycle request`]: `Solicitud de ciclo de vida de producto`,
  [`Subscription request`]: `Solicitud de suscripción`,
  [`Application lifecycle request`]: `Solicitud de ciclo de vida de aplicación`,
  [`Consumer Onboarding request`]: `Solicitud de incorporación de consumidor`,
  [`Originator`]: `Originador`,
  [`Edit analytics service advanced settings`]: `Editar valores avanzados del servicio de análisis`,
  [`Advanced settings`]: `Valores avanzados`,
  [`Must be a positive integer`]: `Debe ser un entero positivo`,
  [`Maximum value is {maxValue}`]: `El valor máximo es {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `El valor máximo es {maxValueDays}d o {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Configurar valores avanzados para el despliegue de análisis.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `El valor debe terminar por 'd' para días o por 'h' para horas. Por ejemplo, 30d o 12h.`,
  [`Rollover settings`]: `Valores de vuelco`,
  [`Retention settings`]: `Valores de retención`,
  [`Delete an index when it meets the following condition:`]: `Suprimir un índice cuando cumpla la condición siguiente:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Volcar el alias de escritura a un nuevo índice cuando el índice gestionado cumpla una de las condiciones siguientes:`,
  [`Minimum document count`]: `Recuento mínimo de documentos`,
  [`The minimum number of documents required to roll over the index.`]: `El número mínimo de documentos necesarios para volcar el indice.`,
  [`Minimum index age`]: `Antigüedad mínima del índice`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `La antigüedad de índice mínima necesaria para volcar el índice. La antigüedad del índice es el tiempo entre la creación del índice y ahora. Utilice 'd' para días y 'h' para horas. Por ejemplo, 30d significa que el índice se volcará una vez que su antigüedad alcance los 30 días, mientras que 96h significa que el índice se volcará una vez que su antigüedad alcance las 96 horas. Los valores máximos son {maxValueDays}d o {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `La antigüedad de índice mínima necesaria para suprimir el índice. La antigüedad del índice es el tiempo entre la creación del índice y ahora. Utilice 'd' para días y 'h' para horas. Por ejemplo, 30d significa que el índice se suprimirá una vez que su antigüedad alcance los 30 días, mientras que 96h significa que el índice se suprimirá una vez que su antigüedad alcance las 96 horas. Los valores máximos son {maxValueDays}d o {maxValueHours}h.`,
  [`Time range:`]: `Rango de tiempo:`,
  [`Time range: Before {datetime}`]: `Rango de tiempo: antes de {datetime}`,
  [`Time range: After {datetime}`]: `Rango de tiempo: después de {datetime}`,
  [`Filter before`]: `Filtrar antes de`,
  [`Filter after`]: `Filtrar después de`,
  [`Filter exact`]: `Filtrar exacto`,
  [`Filter gt`]: `Filtrar gt`,
  [`Filter lt`]: `Filtrar lt`,
  [`Filter equals`]: `Filtrar igual a`,
  [`Filter by`]: `Filtrar por`,
  [`Filter out`]: `Filtrar`,
  [`Delete failed`]: `Error al suprimir`,
  [`Delete warning`]: `Aviso de supresión`,
  [`Could not delete saved query {queryTitle}.`]: `No se ha podido suprimir la consulta guardada {queryTitle}.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `La solicitud ha respondido correctamente pero {queryTitle} no ha terminado de suprimirse todavía.`,
  [`API governance`]: `Gobierno de API`,
  [`Something's wrong`]: `Algo ha fallado`,
  [`We were unable to connect to the service.`]: `No hemos podido conectar con el servicio.`,
  [`Invalid date`]: `Fecha no válida`,
  [`Invalid time`]: `Hora no válida`,
  [`End date must be after the start date`]: `La fecha de finalización debe ser posterior a la fecha de inicio`,
  [`Duplicate`]: `Duplicado`,
  [`Duplicate query`]: `Consulta duplicada`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `¿Seguro que desea realizar un duplicado de {queryTitle}?`,
  [`No queries saved`]: `No se ha guardado ninguna consulta`,
  [`No queries shared`]: `No hay consultas compartidas`,
  [`To save a query, start by creating one then save.`]: `Para guardar una consulta, empiece creando una y después guárdela.`,
  [`Share a saved query and allow others to use it.`]: `Comparta una consulta guardada y permita que otros la utilicen.`,
  [`Query updated`]: `Consulta actualizada`,
  [`Query update failed`]: `No se ha podido actualizar la consulta`,
  [`Share successful`]: `Se ha compartido satisfactoriamente`,
  [`Share failed`]: `No se ha podido compartir`,
  [`Delete successful`]: `Supresión satisfactoria`,
  [`Query unshared`]: `Se ha dejado de compartir la consulta`,
  [`Unshare {queryTitle} failed`]: `No se ha podido dejar de compartir {queryTitle}`,
  [`Query copied`]: `Consulta copiada`,
  [`Duplicate {queryTitle} failed`]: `No se ha podido duplicar {queryTitle}`,
  [`The query {queryTitle} has been updated.`]: `Se ha actualizado la consulta {queryTitle}.`,
  [`The query {queryTitle} has been shared.`]: `La consulta {queryTitle} se ha compartido.`,
  [`The query {queryTitle} has been deleted.`]: `La consulta {queryTitle} se ha suprimido.`,
  [`The query {queryTitle} is no longer shared.`]: `La consulta {queryTitle} ya no se comparte.`,
  [`A copy of {queryTitle} has been created.`]: `Se ha creado una copia de {queryTitle}.`,
  [`About queries`]: `Acerca de las consultas`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Las consultas son un medio de ajustar los datos de análisis utilizados en los paneles de control y en la vista Descubrir. Cuando se aplica una consulta, todos los paneles de control se actualizarán de modo que incluyan solo los datos seleccionados.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `Es posible utilizar uno de los rangos de tiempo predefinidos o utilizar un rango de tiempo específico al segundo más cercano utilizando la opción Personalizado.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Los campos se pueden utilizar para especificar que solo se deben incluir determinados datos. Es posible filtrar por casi todos los atributos de un suceso de API.`,
  [`There are then different operators available depending on the field type.`]: `A continuación, hay distintos operadores disponibles en función del tipo de campo.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Los campos de serie admiten equals, not, starts with, ends with, regex.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `Es posible utilizar contiene y no contiene para proporcionar una lista de valores para un único campo (por ejemplo, una lista de versiones de API).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `Los campos de dirección IP admiten los operadores equals y not, también permiten especificar el valor en la notación CIDR para permitir el uso de máscaras de red para seleccionar redes específicas.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Las expresiones regulares pueden ser muy potentes para seleccionar valores específicos, pero son más lentas de ejecutar, por lo que se recomienda utilizar otros operadores como 'starts with' y 'ends with' si es posible.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Los filtros de consulta para campos diferentes se combinan utilizando el operador AND lógico. Esto significa que la adición de dos filtros para el mismo nombre de campo solo devolverá resultados que satisfagan ambos filtros.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `El parámetro de consulta {type} es una forma sencilla de especificar solo sucesos de éxito o de error. Esto utiliza el campo {statusCode}. Si solo se necesitan códigos de estado específicos, se puede utilizar un filtro de campo. El valor de los campos {statusCodeTwo} normalmente es una serie completa como {okStatus} y no solo el código de retorno HTTP numérico.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `Es posible guardar las consultas para su reutilización posterior, además de compartirlas con el nivel de ámbito actual para que otras personas también las utilicen, por ejemplo, la organización de proveedores o el catálogo.`,
  [`n/a`]: `n/d`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Nota: los campos 'ID de organización de proveedores' y 'nombre de organización de proveedores' se mostrarán en la carga útil de sucesos como {orgId} y {orgName} respectivamente; los campos 'ID de organización de consumidores' y 'nombre de organización de consumidores' se mostrarán como {developerOrgId} y {developerOrgName}.`,
  [`No authentication token`]: `No hay ninguna señal de autenticación`,
  [`Contact your administrator for further assistance.`]: `Póngase en contacto con el administrador para obtener más ayuda.`
};
