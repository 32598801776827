// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Katalog nebyl aktualizován`,
  [`Catalog has been updated`]: `Katalog byl aktualizován`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `Koncept produktu {draftProduct} musí odkazovat na jedno nebo více rozhraní API`,
  [`Number of Calls`]: `Počet volání`,
  [`Changes will apply to newly published APIs only`]: `Změny se projeví pouze u nově publikovaných rozhraní API.`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Existují nekompatibilní {label}, protože mají jiný typ brány než toto rozhraní API.`,
  [`{label} is required`]: `{label} je povinný`,
  [`Application is required`]: `Aplikace je povinná`,
  [`Plan is required`]: `Plán je povinný`,
  [`Gateway is required`]: `Brána je povinná`,
  [`Consumer Org is required`]: `Zákaznická organizace je povinná`,
  [`LDAP ATTRIBUTE NAME`]: `NÁZEV ATRIBUTU LDAP`,
  [`Use the build in Test Application (sandbox only)`]: `Použít sestavení v testovací aplikaci (pouze sandbox)`,
  [`Select an Application`]: `Vybrat aplikaci`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Použít vestavěnou testovací aplikaci (pouze sandbox) - zakázáno, je-li vybrán katalog bez sandboxu`,
  [`Choose an existing Application`]: `Vybrat existující aplikaci`,
  [`Use JWT for gateway authentication to analytics service`]: `Použít JWT pro ověření brány k analytické službě`,
  [`Select an application to consume the API`]: `Vyberte aplikaci, která má rozhraní API konzumovat`,
  [`Use the default built-in Sandbox Catalog'`]: `Použít výchozí vestavěný katalog sandboxů '`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Poznámka: Tento katalog bude použit jako rozsah pro všechny ostatní předvolby. Změna tohoto nastavení bude vyžadovat úpravu dalších předvoleb.`,
  [`Select a Consumer Org`]: `Vybrat zákaznickou organizaci`,
  [`Select the Consumer Org your generated application will belong to`]: `Vybrat zákaznickou organizaci, do které bude vaše vygenerovaná aplikace patřit`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Poznámka: Tato volba může být změněna pouze při použití sestavení v testovací aplikaci sandboxu, protože existující aplikace již patří do zákaznické organizace`,
  [`Generate auto product`]: `Generovat automatický produkt`,
  [`Select a product to associate the current API with`]: `Vyberte produkt, ke kterému se má přidružit aktuální rozhraní API.`,
  [`Associate the current API to a selected product`]: `Přidruží aktuální rozhraní API k vybranému produktu.`,
  [`API management`]: `API Management`,
  [`Select the API management instance you would like this API to be published to.`]: `Vyberte instanci komponenty API Management, kam se má toto rozhraní API publikovat.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Pozn.: Tato instance bude použita jako rozsah pro všechny ostatní předvolby. Změna tohoto nastavení bude vyžadovat úpravu dalších předvoleb.`,
  [`Provider organization`]: `Organizace poskytovatele`,
  [`Select the provider organization you would like this API to be published to.`]: `Vyberte organizaci poskytovatele, do které má být toto rozhraní API publikováno.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Pozn.: Tato organizace bude použita jako rozsah pro všechny ostatní předvolby. Změna tohoto nastavení bude vyžadovat úpravu dalších předvoleb.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Pozn.: Tento prostor bude použit jako rozsah pro všechny ostatní předvolby. Změna tohoto nastavení bude vyžadovat úpravu dalších předvoleb.`,
  [`Selected a draft product`]: `Vybraný koncept produktu`,
  [`Use any available gateway service`]: `Použít jakoukoli dostupnou službu brány`,
  [`Select a compatible gateway service`]: `Vybrat kompatibilní službu brány`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Poznámka: Tato služba brány se použije jako rozsah pro všechny ostatní předvolby. Zásady a další specifický obsah služby brány budou načteny na základě tohoto nastavení.`,
  [`Select a gateway`]: `Vybrat bránu`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `Poznámka: Tato volba je zakázána, je-li vybrána volba 'Generovat automatický produkt', a místo ní se použije výchozí plán pro vygenerovaný produkt.`,
  [`Select a Plan`]: `Vybrat plán`,
  [`Generate subscription`]: `Generovat odběr`,
  [`A subscription will be created between the selected Product plan and Application`]: `Bude vytvořen odběr mezi vybraným plánem produktu a aplikací`,
  [`Apply a rate limit to the generated product`]: `Použít limit četnosti na generovaný produkt`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Na výchozí plán generovaných produktů se použije limit četnosti. Tato volba je zakázána, je-li vybrána volba 'Existující produkt', a místo ní se použije limit četnosti nadefinovaný ve vybraném plánu.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `Protokol LDAP (Lightweight Directory Access Protocol) je internetový protokol pro přístup a údržbu distribuovaných informačních služeb adresáře po síti. Máte-li povolenou podnikovou službu LDAP, nakonfigurujte ji jako prostředek, aby bylo možné poskytnout ověření uživatele. Chcete-li pro nové uživatele Developer Portal také poskytnout zahrnutí uživatele, musíte vyplnit sekci Mapování atributů, abyste povolili zapisovatelný LDAP. V této sekci zaškrtněte políčko Spravováno uživatelem a poskytněte mapování názvů zdrojových atributů LDAP na cílové hodnoty API Connect.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Povolení samoschválení úlohy umožňuje schválení úloh jejich původcem, jakož i spolupracovníky. Tato volba se může hodit v případě, že jiní schvalovatelé nejsou k dispozici, ale fakticky umožňuje obejití kontroly jiným uživatelem.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Integrace s platebním systémem připojuje službu API Connect k účtu v platebním systému předplatného třetích stran, který spravuje zákazníky, jejich způsoby platby, vytváření faktur a aktivní odběry`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Objekt nativního poskytovatele OAuth poskytuje nastavení pro operace zpracování OAuth, jako např. generování a ověřování tokenů OAuth. Na objekt poskytovatele OAuth může odkazovat definice zabezpečení OAuth za účelem ochrany rozhraní API. Je-li použit nativní poskytovatel OAuth, jsou operace OAuth prováděny nativně službou API Connect. Každý objekt poskytovatele OAuth má záložní rozhraní API. Vaše konfigurace zde automaticky aktualizuje dokument Swagger rozhraní API. Dokument Swagger můžete upravit po přechodu na stránku Editor rozhraní API. Když publikované rozhraní API odkazuje na objekt poskytovatele OAuth, je v rámci brány automaticky zpřístupněno záložní rozhraní API.`,
  [`About token management`]: `O správě tokenů`,
  [`About user security`]: `O zabezpečení uživatelů`,
  [`APIs or products`]: `Rozhraní API nebo produkty`,
  [`ATM is not deployed, please contact your administrator`]: `ATM není implementováno, obraťte se na administrátora`,
  [`Application state change approvals will be enabled`]: `Schválení změny stavu aplikace budou povolena`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Definujte nastavení, která se mají použít k extrahování pověření uživatelů aplikace, ověření jejich identit a udělení autorizace.`,
  [`See console for details.`]: `Podrobnosti naleznete v konzole.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `Vybraná služba správce certifikátů nespravuje žádné certifikáty.`,
  [`The selected product is not published to the Sandbox catalog`]: `Vybraný produkt není publikován v katalogu sandboxu`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `Vybraný produkt není publikován v katalogu sandboxu, takže není možné aplikaci přihlásit k odběru`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `Rozhraní CLI služby IBM Cloud pro vaši platformu (není-li již instalováno).`,
  [`(Only supported by TLS 1.3)`]: `(Podporováno pouze protokolem TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `Direktiva "@listSize" pro toto pole definuje pole vhodné velikosti, která nevrací seznamy.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `Direktiva "@listSize" pro toto pole definuje pole vhodné velikosti, která nejsou pro návratový typ pole definovaná.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `Direktiva "@listSize" pro toto pole definuje argumenty ořezání, které nejsou pro pole definované.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `Direktiva "@listSize" pro toto pole definuje argumenty ořezání, jejichž typy nejsou "Int" ani "Int!".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `Direktiva "@listSize" pro toto pole definuje více nenulových argumentů ořezání.`,
  [`"{name}" (Role) has been created`]: `"{name}" (Role) byla vytvořena.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (Role) byla aktualizována.`,
  [`API ({api}) has been created.`]: `Rozhraní API ({api}) bylo vytvořeno.`,
  [`API ({api}) has been renamed.`]: `Rozhraní API ({api}) bylo přejmenováno.`,
  [`API ({api}) has been updated.`]: `Rozhraní API ({api}) bylo aktualizováno.`,
  [`API ({api}) has been deleted.`]: `Rozhraní API ({api}) bylo odstraněno.`,
  [`API ({api}) has been published.`]: `Rozhraní API ({api}) bylo publikováno.`,
  [`API ({api}) has validation error.`]: `Rozhraní API ({api}) má chybu ověření platnosti.`,
  [`API ({api}) has validation errors.`]: `Rozhraní API ({api}) má chyby ověření platnosti.`,
  [`API version`]: `Verze rozhraní API`,
  [`APIM data version`]: `Verze dat APIM`,
  [`APIM schema update date`]: `Datum aktualizace schématu APIM`,
  [`APIM schema version`]: `Verze schématu APIM`,
  [`APIM target data version`]: `Cílová verze dat APIM`,
  [`APIM target schema version`]: `Cílová verze schématu APIM`,
  [`APIM update date`]: `Datum aktualizace APIM`,
  [`Product ({product}) has been created.`]: `Produkt ({product}) byl vytvořen.`,
  [`Product ({product}) has been updated.`]: `Produkt ({product}) byl aktualizován.`,
  [`Product ({product}) has been delete.`]: `Produkt ({product}) byl odstraněn.`,
  [`Product ({product}) has been published.`]: `Produkt ({product}) byl publikován.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) byl {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) nebyl {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} po zařazení do fronty)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} po odeslání)`,
  [`(none)`]: `(není)`,
  [`(optional)`]: `(volitelné)`,
  [`*Base endpoint list empty`]: `*Seznam základních koncových bodů je prázdný.`,
  [`*Must be a valid url`]: `*Musí se jednat o platnou adresu URL.`,
  [`*System will also send an email notification to the requester.`]: `*Systém také odešle e-mailové oznámení žadateli.`,
  [`+ redact from...`]: `+ redigovat z...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. Můžete rovněž spravovat životní cyklus tohoto produkt uvnitř katalogu.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x následované 64 hexadecimálními znaky`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Vlastní a spravuje zákaznické organizace rozhraní API. 2) Spravuje komunity vývojářů aplikací. 3) Vytváří definice produktů a rozhraní API. 4) Spravuje životní cyklus produktu API.`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Vlastní a spravuje organizace poskytovatele rozhraní API. 2) Spravuje komunity vývojářů aplikací. 3) Vytváří definice produktů a rozhraní API. 4) Spravuje životní cyklus produktu API.`,
  [`5 Most Active APIs`]: `5 nejaktivnějších rozhraní API`,
  [`5 Most Active Products`]: `5 nejaktivnějších produktů`,
  [`5 most active APIs`]: `5 nejaktivnějších rozhraní API`,
  [`5 most active Products`]: `5 nejaktivnějších produktů`,
  [`503 Service Unavailable`]: `503 Služba je nedostupná`,
  [`A request to the server was made without any credentials.`]: `Požadavek na server byl proveden bez jakýchkoli pověření.`,
  [`A server error occurred`]: `Došlo k chybě serveru`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Profil klienta TLS konfiguruje řetěz certifikátů a šifrovací sadu používanou službou API Connect, když se připojuje jako klient k jiným systémům.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Profil serveru TLS konfiguruje řetěz certifikátů a šifrovací sadu používanou službou API Connect při prezentování koncových bodů serveru jiným systémům.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Katalog je hostitelem kolekce produktů API, které jsou viditelné v přidruženém nástroji Developer Portal po publikování.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Katalog je hostitelem kolekce produktů API, které jsou viditelné v přidruženém nástroji Developer Portal po publikování.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Katalog představuje kolekci spravovaných produktů API. Publikované produkty jsou viditelné odběratelům v nástroji Developer Portal, který je přidružený ke katalogu.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Certifikát je vyžadován pro zabezpečení připojení mezi službou API Connect a doménou koncového bodu správy brány. Certifikáty jsou také nezbytné pro zabezpečení spojení mezi bránou a doménami, které zpracovává. Všechny certifikáty musí být uložené ve službě správce certifikátů. Služba poskytuje zabezpečené úložiště pro certifikáty a pomáhá předcházet výpadkům tím, že vám odešle oznámení, až se bude blížit vypršení platnosti certifikátů. Chcete-li začít:`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Výchozí hodnota, která se použije v případě, že vstupy do mapy nejsou definovány.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Služba brány představuje sadu serverů nebo kontejnerů bran, které jsou hostiteli publikovaných rozhraní API a poskytují koncové body rozhraní API používané aplikacemi klienta. Brány provádějí vyvolání serveru proxy rozhraní API pro back-endové systémy a vynucují zásady rozhraní API včetně identifikace klienta, zabezpečení a limitu četnosti.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Hostitel brány publikuje rozhraní API a poskytuje koncové body rozhraní API používané aplikacemi klienta. Brány provádějí vyvolání serveru proxy rozhraní API pro back-endové systémy a vynucují zásady rozhraní API včetně identifikace klienta, zabezpečení a limitu četnosti.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Cíl migrace může být nastaven na zdrojovém produktu k definování cíle migrace pro odběry. Cíl migrace zahrnuje mapování plánů, které popisuje mapování plánů na zdrojovém produktu na plány na cílovém produktu. Cíl migrace je viditelný v nástroji Developer Portal, aby mohl sdělit cíl migrace odběratelům zdrojového produktu.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Modulární sada zásad OAuth. Může provádět všechny kroky protokolu OAuth/OpenID Connect v jedné zásadě (výchozí), nebo je lze rozdělit do více zásad, aby bylo možné provést pouze jeden nebo "n" krok(ů) v daném okamžiku pro jemnější kontrolu. Vstupy a výstupy každého z kroků jsou řízeny dokumentovanými kontextovými proměnnými. Podle potřeby přidejte nebo odeberte podporované komponenty OAuth.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Portál nelze vytvořit bez dostupné portálové služby. Požádejte administrátora cloudu o podporu.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Organizace poskytovatele je tým, který vlastní rozhraní API a přidružené plány a produkty. Organizace poskytovatele vyžaduje vlastníka, kterému jsou přiřazena veškerá oprávnění. Vlastníci jsou přiřazeni od členů.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Organizace poskytovatele zahrnuje uživatele, kteří spravují rozhraní API, produkty, plány, katalogy, zákaznické organizace a další související prostředky. Organizace poskytovatele můžete použít k seskupení uživatelů a prostředků správy rozhraní API podle projektu, oddělení, fáze životního cyklu atd. Chcete-li spravovat a přiřazovat role, které řídí oprávnění uživatele, přejděte do produktu IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Jednoduchý výraz ve skriptu JavaScript, který se přeloží jako hodnota výstupu. Může se jednat o statický řetězec ("můj statický řetězec") nebo výraz zahrnující jakékoli mapované vstupy ($(input.firstName) + " " + $(input.lastName)).`,
  [`ACTION`]: `AKCE`,
  [`ANALYTICS`]: `ANALÝZY`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `Administrátor rozhraní API`,
  [`API CONNECT VALUE`]: `HODNOTA API CONNECT`,
  [`API assemble`]: `Sestavení rozhraní API`,
  [`API base endpoints`]: `Základní koncové body rozhraní API`,
  [`API base URL`]: `Základní adresa URL rozhraní API`,
  [`API base URLs`]: `Základní adresy URL rozhraní API`,
  [`API Calls`]: `Volání API`,
  [`API Connect UI`]: `Uživatelské rozhraní služby API Connect`,
  [`API Connect services`]: `Služby API Connect`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `Služba API Connect vyžaduje, aby e-mailový server odesílal pozvánky a aktivační odkazy členům, a pro jiné účely.`,
  [`API Connect verify token`]: `Ověřit token služby API Connect`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `Licence programu API Designer`,
  [`API Designer credentials`]: `Pověření nástroje API Designer`,
  [`API Designer licence`]: `Licence programu API Designer`,
  [`API Designer does not currently support WSDL`]: `Nástroj API Designer aktuálně nepodporuje WSDL`,
  [`API editor`]: `Editor rozhraní API`,
  [`API Endpoint`]: `Koncový bod rozhraní API`,
  [`API Endpoint Base`]: `Základ koncového bodu rozhraní API`,
  [`API Endpoint for Unenforced APIs`]: `Koncový bod rozhraní API pro nevynucená rozhraní API`,
  [`API Endpoint for unenforced APIs`]: `Koncový bod rozhraní API pro nevynucená rozhraní API`,
  [`API Endpoints`]: `Koncové body rozhraní API`,
  [`API Error`]: `Chyba rozhraní API`,
  [`API gateway`]: `API Gateway`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API Gateways`,
  [`API Gateways`]: `API gateways`,
  [`API Invocation Endpoint`]: `Koncový bod vyvolání API`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `Rozhraní API není publikováno. Test API (Vyzkoušet) v Exploreru nebude povoleno.`,
  [`API Key`]: `Klíč rozhraní API`,
  [`API key`]: `Klíč rozhraní API`,
  [`API key secret`]: `Tajný klíč rozhraní API`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `Lokální registr uživatelů nástroje API Manager`,
  [`API Manager URL`]: `Adresa URL nástroje API Manager`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `Parametry cesty k rozhraní API`,
  [`API Security Definition`]: `Definice zabezpečení rozhraní API`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `Test rozhraní API (Vyzkoušet) v Průzkumníku nebude pro toto rozhraní API povolen, protože není vynucen.`,
  [`API Setup`]: `Nastavení API`,
  [`API Subscription`]: `Odběr rozhraní API`,
  [`API endpoint`]: `Koncový bod rozhraní API`,
  [`API endpoint base`]: `Základ koncového bodu rozhraní API`,
  [`API endpoint for unenforced APIs`]: `Koncový bod rozhraní API pro nevynucená rozhraní API`,
  [`API endpoints`]: `Koncové body rozhraní API`,
  [`API error`]: `Chyba rozhraní API`,
  [`API invocation endpoint`]: `Koncový bod vyvolání API`,
  [`API is not enforced on the API gateway.`]: `Rozhraní API není vynuceno na bráně rozhraní API.`,
  [`API path parameters`]: `Parametry cesty k rozhraní API`,
  [`API security definition`]: `Definice zabezpečení rozhraní API`,
  [`API setup`]: `Nastavení API`,
  [`API subscription`]: `Odběr rozhraní API`,
  [`API Update`]: `Aktualizace rozhraní API`,
  [`API user registries`]: `Registry uživatelů rozhraní API`,
  [`API and product generated successfully!`]: `Rozhraní API a produkt byly úspěšně vygenerovány!`,
  [`API deletion failed`]: `Odstranění rozhraní API se nezdařilo`,
  [`API does not exist!`]: `Rozhraní API neexistuje!`,
  [`API from existing REST service`]: `Rozhraní API z existující služby REST`,
  [`API from existing SOAP service`]: `Rozhraní API z existující služby SOAP`,
  [`API has been updated.`]: `Rozhraní API bylo aktualizováno.`,
  [`API has been updated`]: `Rozhraní API bylo aktualizováno`,
  [`API has not been created!`]: `Rozhraní API nebylo vytvořeno!`,
  [`API has not been updated!`]: `Rozhraní API nebylo aktualizováno!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `Priorita rozhraní API (použití jednoduchých koncových bodů definovaných v definicích OpenAPI)`,
  [`API properties`]: `Vlastnosti rozhraní API`,
  [`API protection source`]: `Zdroj ochrany rozhraní API`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `Rozhraní API odkazované z produktu neexistuje. Nejprve vytvořte nebo naimportujte rozhraní API.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `Struktury informačního obsahu požadavku a odezvy rozhraní API jsou sestaveny pomocí definic schématu OpenAPI.`,
  [`API subscription request approved for app {{appName}}`]: `Požadavek na odběr rozhraní API byl schválen pro aplikaci {appName}`,
  [`API subscription request denied for app {{appName}}`]: `Požadavek na odběr rozhraní API byl zamítnut pro aplikaci {appName}`,
  [`API subscription request received for app {{appName}}`]: `Požadavek na odběr rozhraní API byl přijat pro aplikaci {appName}`,
  [`API type`]: `Typ rozhraní API`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `Rozhraní API s klíčem ''{key}'' pod vlastností 'apis' neexistuje a nelze jej vypsat. Zkontrolujte kartu Zdroj.`,
  [`APIs`]: `Rozhraní API`,
  [`APIs and products`]: `Rozhraní API a produkty`,
  [`APIs included in the plan.`]: `Rozhraní API zahrnutá do plánu.`,
  [`API Key is created`]: `Klíč rozhraní API je vytvořen`,
  [`API Key is deleted`]: `Klíč rozhraní API je odstraněn`,
  [`API Key is successfully created`]: `Klíč rozhraní API byl úspěšně vytvořen`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `Časový limit klíče rozhraní API je **{seconds} s ({time})**`,
  [`API key timeout`]: `Časový limit klíče rozhraní API`,
  [`API Key timeout has been changed`]: `Časový limit klíče rozhraní API byl změněn`,
  [`Application type`]: `Typ aplikace`,
  [`Associated analytics service`]: `Přidružená analytická služba`,
  [`AVAILABILITY`]: `DOSTUPNOST`,
  [`AVAILABILITY ZONE`]: `ZÓNA DOSTUPNOSTI`,
  [`About`]: `Informace`,
  [`About audit setting`]: `O nastavení auditu`,
  [`About Authentication URL user registry`]: `O registru uživatelů ověřovací adresy URL`,
  [`About availability zones`]: `O zónách dostupnosti`,
  [`About catalogs`]: `O katalozích`,
  [`About consumer organization owners`]: `O vlastnících zákaznické organizace`,
  [`About consumer organizations`]: `O zákaznických organizacích`,
  [`About developer portals`]: `O nástrojích developer portal`,
  [`About email servers`]: `O e-mailových serverech`,
  [`About Endpoints`]: `O koncových bodech`,
  [`About gateways`]: `O branách`,
  [`About gateway visibility`]: `O viditelnosti brány`,
  [`About Gateway Processing Status`]: `O stavu zpracování brány`,
  [`About introspection`]: `O introspekci`,
  [`About Keystores`]: `O úložištích klíčů`,
  [`About LDAP`]: `O LDAP`,
  [`About local user registries`]: `O lokálních registrech uživatelů`,
  [`About metadata`]: `O metadatech`,
  [`About Migration Target`]: `O cíli migrace`,
  [`About migration targets`]: `O cílech migrace`,
  [`About native OAuth provider`]: `O nativním poskytovateli OAuth`,
  [`About OpenID Connect`]: `O službě OpenID Connect`,
  [`About replacing a product`]: `O záměně produktu`,
  [`About Role defaults`]: `O výchozích rolích`,
  [`About roles`]: `O rolích`,
  [`About scopes`]: `O rozsazích`,
  [`About Set Migration Targets`]: `O nastavení cílů migrace`,
  [`About spaces`]: `O prostorech`,
  [`About Subscription`]: `O odběru`,
  [`About superseding a product`]: `O nahrazení produktu`,
  [`About TLS server profile`]: `O profilu serveru TLS`,
  [`About TLS client profiles`]: `O profilech klienta TLS`,
  [`About TLS server profiles`]: `O profilech serveru TLS`,
  [`About third party OAuth provider`]: `O poskytovateli OAuth třetí strany`,
  [`About tokens`]: `O tokenech`,
  [`About Transfer Ownership`]: `O převodu vlastnictví`,
  [`About Transferring Ownership`]: `O převodu vlastnictví`,
  [`About Truststores`]: `O úložištích údajů o důvěryhodnosti`,
  [`About adding a member`]: `O přidání člena`,
  [`About adding provider organization member`]: `O přidání člena organizace poskytovatele`,
  [`About adding members to a Space`]: `O přidávání členů do prostoru`,
  [`About adding members to a catalog`]: `O přidání členů do katalogu`,
  [`About changing owners`]: `O změně vlastníků`,
  [`About enabling gateway services`]: `O povolení služeb brány`,
  [`About inviting a member`]: `O pozvání člena`,
  [`About inviting catalog members`]: `O pozvání členů katalogu`,
  [`About inviting provider organization members`]: `O pozvání členů do organizace poskytovatele`,
  [`About inviting space members`]: `O pozvání členů prostoru`,
  [`About keystores`]: `O úložištích klíčů`,
  [`About managing Space membership`]: `O správě členství v prostoru`,
  [`About migrating subscriptions`]: `O migraci odběrů`,
  [`About provider organizations`]: `O organizacích poskytovatele`,
  [`About roles and role defaults`]: `O rolích a výchozích rolích`,
  [`About roles in spaces`]: `O rolích v prostorech`,
  [`About selecting gateway services`]: `O výběru služeb brány`,
  [`About send message`]: `O odeslání zprávy`,
  [`About sending messages`]: `O odesílání zpráv`,
  [`About the analytics service`]: `O analytické službě`,
  [`About the DNS Scheme`]: `O schématu DNS`,
  [`About the Gateway Service`]: `O službě brány`,
  [`About the Portal Service`]: `O portálové službě`,
  [`About the product lifecycle`]: `O životním cyklu produktu`,
  [`About the provider organization owner`]: `O vlastníkovi organizace poskytovatele`,
  [`About this Cloud`]: `O tomto cloudu`,
  [`About transferring ownership`]: `O převodu vlastnictví`,
  [`About truststores`]: `O úložištích údajů o důvěryhodnosti`,
  [`About visibility`]: `O viditelnosti`,
  [`About visibility and subscribability`]: `O viditelnosti a možnosti přihlášení k odběru`,
  [`Accept`]: `Přijmout`,
  [`Access Code`]: `Přístupový kód`,
  [`Access Token`]: `Přístupový token`,
  [`Access URL through Secure Gateway`]: `Přístup k adrese URL skrze zabezpečenou bránu`,
  [`Access code`]: `Přístupový kód`,
  [`Access code is not chosen in supported grant type.`]: `Přístupový kód není vybrán v podporovaném typu udělení.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Přistupte k adrese URL skrze zabezpečenou bránu. Brána se nastaví, když se rozhraní API přesune ze stavu fázovaného do publikovaného stavu.`,
  [`Access token TTL has been changed`]: `Hodnota TTL přístupového tokenu byla změněna.`,
  [`Access token time to live`]: `Doba životnosti přístupového tokenu`,
  [`Access token time-to-live`]: `Doba životnosti přístupového tokenu`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Platnost přístupového tokenu vyprší za **{seconds} s ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Přístupové tokeny jsou uděleny pro aplikaci klienta, které umožňují aplikaci přistupovat k prostředkům jménem uživatele aplikace. Aktualizační tokeny jsou vydávány klientovi pro získání nového přístupového tokenu, když se aktuální přístupový token stane neplatným nebo vyprší, nebo pro získání dalších přístupových tokenů s identickým nebo zúženým rozsahem platnosti. Můžete také uvést, jak dlouho zůstane souhlas daný kombinací libovolného počtu přístupových a aktualizačních tokenů platný.`,
  [`Access tokens time to live (seconds)`]: `Doba životnosti přístupových tokenů (s)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Přístupový token, token ID a dočasný token lze přiřadit k libovolnému z existujících úložišť klíčů. Historie přiřazení úložiště klíčů je zachována.`,
  [`Account`]: `Účet`,
  [`Account has been updated.`]: `Účet byl aktualizován.`,
  [`Account password has been changed.`]: `Heslo účtu bylo změněno.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Akce`,
  [`Activate API`]: `Aktivovat rozhraní API`,
  [`Activate API|button text`]: `Aktivovat rozhraní API`,
  [`Activate API for testing|title`]: `Aktivovat rozhraní API`,
  [`Activation link`]: `Aktivační odkaz`,
  [`Active`]: `Aktivní`,
  [`Activity log`]: `Protokol aktivit`,
  [`Activity log API setting is not available for this API's gateway type.`]: `Nastavení rozhraní API protokolu aktivit není k dispozici pro tento typ brány API.`,
  [`Active loading indicator`]: `Indikátor aktivního načítání`,
  [`Add`]: `Přidat`,
  [`Add allowlist`]: `Přidat seznam povolení`,
  [`Add a new billing integration in Resources`]: `Novou integraci s platebním systémem můžete přidat v části Prostředky.`,
  [`Add API user registries`]: `Přidat registry uživatelů rozhraní API`,
  [`Add APIs to Product`]: `Přidat rozhraní API do produktu`,
  [`Add another cloud`]: `Přidat další cloud`,
  [`Add billing integration`]: `Přidat integraci s platebním systémem`,
  [`Add Burst Limit`]: `Přidat limit dávky`,
  [`Add Ciphers for TLS client profile`]: `Přidat šifry pro profil klienta TLS`,
  [`Add Ciphers for TLS server profile`]: `Přidat šifry pro profil serveru TLS`,
  [`Add Condition`]: `Přidat podmínku`,
  [`Add Consumer Role`]: `Přidat roli odběratele`,
  [`Add Count Limit`]: `Přidat limit počtu`,
  [`Add Credential`]: `Přidat pověření`,
  [`Add domain`]: `Přidat doménu`,
  [`Add credential`]: `Přidat pověření`,
  [`Add Extension`]: `Přidat rozšíření`,
  [`Add GraphQL schema`]: `Přidat schéma GraphQL`,
  [`Add HTTP Endpoint`]: `Přidat koncový bod HTTP`,
  [`Add group`]: `Přidat skupinu`,
  [`Add JSON schema`]: `Přidat schéma JSON`,
  [`Add Keystore to TLS client profile`]: `Přidat úložiště klíčů k profilu klienta TLS`,
  [`Add Keystore to TLS server profile`]: `Přidat úložiště klíčů k profilu serveru TLS`,
  [`Add member`]: `Přidat člena`,
  [`Add New Role for Consumer organization`]: `Přidat novou roli pro zákaznickou organizaci`,
  [`Add operation`]: `Přidat operaci`,
  [`Add Parameters`]: `Přidat parametry`,
  [`Add Policy`]: `Přidat zásadu`,
  [`Add Rate limit`]: `Přidat limit četnosti`,
  [`Add Rate Limit`]: `Přidat limit četnosti`,
  [`Add role`]: `Přidat roli`,
  [`Add Syslog TCP Endpoint`]: `Přidat koncový bod TCP protokolu systému`,
  [`Add Syslog TLS Endpoint`]: `Přidat koncový bod TLS protokolu systému`,
  [`Add Syslog UDP Endpoint`]: `Přidat koncový bod UDP protokolu systému`,
  [`Add XML schema`]: `Přidat schéma XML`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Přidejte koncový bod brány, který chcete zdostupnit k vyvolání API v tomto katalogu.`,
  [`Add a different Cloud Connection.`]: `Přidejte jiný Cloud Connection.`,
  [`Add a member from the user registry`]: `Přidat člena z registru uživatelů`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Přidejte uživatele do administrátorské organizace a přiřaďte mu požadované role.`,
  [`Add a user to the provider organization, and assign the required roles`]: `Přidejte uživatele do organizace poskytovatele a přiřaďte mu požadované role.`,
  [`Add a user to the Catalog, and assign the required roles`]: `Přidejte uživatele do katalogu a přiřaďte požadované role.`,
  [`Add a user to the Space, and assign the required roles`]: `Přidejte uživatele do prostoru a přiřaďte požadované role.`,
  [`Add action`]: `Přidat akci`,
  [`Add additional client ID/client secret pairs`]: `Přidat další pár ID klienta/tajný klíč klienta`,
  [`Add blocklist`]: `Přidat seznam blokování`,
  [`Add case`]: `Přidat případ`,
  [`Add catch`]: `Přidat zachycenou položku`,
  [`Add default catch`]: `Přidat výchozí zachycenou položku`,
  [`Add destination`]: `Přidat cíl`,
  [`Add entry`]: `Přidat položku`,
  [`Add group failed.`]: `Přidání skupiny se nezdařilo.`,
  [`Add input`]: `Přidat vstup`,
  [`Add media type`]: `Přidat typ média`,
  [`Add member failed`]: `Přidání člena se nezdařilo`,
  [`Add object`]: `Přidat objekt`,
  [`Add otherwise`]: `Přidat jinak`,
  [`Add output`]: `Přidat výstup`,
  [`Add outputs for operation...`]: `Přidat výstupy pro operaci...`,
  [`Add parameters for operation...`]: `Přidat parametry pro operaci...`,
  [`Add parameters to this API`]: `Přidat parametry do tohoto rozhraní API`,
  [`Add plans to product`]: `Přidejte plány do produktu.`,
  [`Add plans to this product`]: `Přidejte plány do tohoto produktu.`,
  [`Add schema`]: `Přidat schéma`,
  [`Add scopes for this OAuth provider.`]: `Přidejte rozsahy pro tohoto poskytovatele OAuth.`,
  [`Add scopes to allow access to`]: `Přidat rozsahy pro povolení přístupu`,
  [`Add scopes to allow access to.`]: `Přidejte rozsahy pro povolení přístupu.`,
  [`Add tags and external documentation details for this API`]: `Přidat značky a podrobnosti externí dokumentace pro toto rozhraní API`,
  [`Add to`]: `Přidat do`,
  [`Add to existing product`]: `Přidat do existujícího produktu`,
  [`Add/Remove APIs`]: `Přidat/odebrat rozhraní API`,
  [`AddProperties`]: `Přidat vlastnosti`,
  [`Added APIs`]: `Přidaná rozhraní API`,
  [`Added rate limit`]: `Přidaný limit četnosti`,
  [`Added rate limits`]: `Přidané limity četnosti`,
  [`Adding an API to a product for publishing`]: `Přidání rozhraní API do produktu pro publikování`,
  [`Additional support`]: `Další podpora`,
  [`Additional properties`]: `Další vlastnosti`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Dále zvažte odebrání přidruženého registru uživatelů katalogu {name}, pokud se nepoužívá.`,
  [`Address`]: `Adresa`,
  [`Admin DN`]: `DN administrátora`,
  [`Admin organization invitation timeout`]: `Časový limit pozvánky administrátorské organizace`,
  [`Admin organization invitation timeout has been changed`]: `Časový limit pozvánky administrátorské organizace byl změněn.`,
  [`Admin password`]: `Heslo administrátora`,
  [`Admin request reset password`]: `Požadavek administrátora na resetování hesla`,
  [`Admin reset password`]: `Resetování hesla administrátora`,
  [`Admin Sign In`]: `Přihlášení administrátora`,
  [`Admin add catalog failed`]: `Přidání katalogu administrátorem se nezdařilo`,
  [`Admin add space failed`]: `Přidání prostoru administrátorem se nezdařilo`,
  [`Administer consumer organizations`]: `Spravovat zákaznické organizace`,
  [`Administers the API consumer organization`]: `Spravuje zákaznickou organizaci rozhraní API.`,
  [`Administers the API provider organization`]: `Spravuje organizaci poskytovatele rozhraní API.`,
  [`Administers the admin organization`]: `Spravuje administrátorskou organizaci.`,
  [`Administers the admin topology`]: `Spravuje topologii administrátora.`,
  [`Administers the app developer organization`]: `Spravuje vývojářskou organizaci aplikací`,
  [`Administers the catalog`]: `Spravuje katalog.`,
  [`Administers the cloud topology`]: `Spravuje topologii cloudu.`,
  [`Administers the space`]: `Spravuje prostor.`,
  [`Administration management console`]: `Konzola správy administrace`,
  [`Administrator`]: `Administrátor`,
  [`Advanced analytics configuration`]: `Konfigurace rozšířené analýzy`,
  [`Advanced features`]: `Rozšířené funkce`,
  [`Advanced scope check`]: `Rozšířená kontrola rozsahu`,
  [`Advanced scope check after Token Validation`]: `Rozšířená kontrola rozsahu po ověření tokenu`,
  [`Advanced scope check before Token Generation`]: `Rozšířená kontrola rozsahu před generováním tokenu`,
  [`Aggregation`]: `Agregace`,
  [`Agree`]: `Souhlasit`,
  [`All`]: `Vše`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Tento produkt mohou zobrazit všichni ověření vývojáři v zákaznických organizacích, kteří se přihlásili k nástroji Developer Portal.`,
  [`All consumer organizations will be able to see this product.`]: `Všechny zákaznické organizace budou moci si tento produkt zobrazit.`,
  [`Allowlist`]: `Seznam povolení`,
  [`Allow Chunked Uploads`]: `Povolit odeslání po blocích`,
  [`Allow chunked uploads`]: `Povolit odeslání po blocích`,
  [`Allow "plain" challenge method`]: `Povolit metodu "prostých" výzev`,
  [`Allow renegotiation`]: `Povolit vyjednání`,
  [`Allow case sensitive usernames`]: `Povolit jména uživatelů s rozlišením malých a velkých písmen`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Umožněte klientům vložit rozšíření SNI s požadovaným názvem hostitele ve svém počátečním navázání komunikace se serverem.`,
  [`Allow connection to be renegotiated`]: `Povolit opětovné vyjednání připojení`,
  [`Allow default introspection`]: `Povolit výchozí introspekci`,
  [`Allow insecure server connections`]: `Povolit nezabezpečená připojení serveru`,
  [`Allow null value`]: `Povolit hodnotu null`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Povolit, aby access_token/refresh_token odeslal zprávu 302 pro přesměrování pro odhlášení`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Všechna doporučení byla použita. Pro vaše schéma nejsou k dispozici žádná varování.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Povolit testování operací rozhraní API pomocí testovacích nástrojů v nástroji Developer Portal.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Povolit pro připojení pokračování se slabými nebo nezabezpečenými pověřeními`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Povolit pro připojení pokračování se slabými nebo nezabezpečenými pověřeními.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Povolit externímu poskytovateli OIDC komunikaci s nástrojem Developer Portal, nikoli s nástrojem API Manager`,
  [`Allow to manage this user registry`]: `Povolit správu tohoto registru uživatelů`,
  [`Allow to manage users under this user registry`]: `Povolit správu uživatelů pod tímto registrem uživatelů`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Povolit uživatelům automatické zahrnutí, je-li APIC prezentován s tokenem vydaným vydavatelem`,
  [`Already have an account?`]: `Už máte účet?`,
  [`Also transfer ownership of owned Spaces`]: `Také převést vlastnictví vlastněných prostorů`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Vždy načíst uživatelská data z koncového bodu userinfo, jsou-li povolena`,
  [`Always output the root element`]: `Vždy pro výstup použít kořenový prvek`,
  [`Always output the root element.`]: `Vždy pro výstup použít kořenový prvek.`,
  [`Always required`]: `Vždy nezbytné`,
  [`Always use userinfo endpoint`]: `Vždy použít koncový bod userinfo`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Rozhraní API poskytovatele OAuth obsahuje koncové body autorizace a tokenu toku OAuth. Zde nakonfigurujte své poskytovatele OAuth; pak při vytvoření zabezpečeného rozhraní API OAuth můžete vybrat požadovaného poskytovatele.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Poskytovatel OAuth obsahuje koncové body autorizace a tokenu toku OAuth; vyberte poskytovatele OAuth, kterého chcete použít k zabezpečení rozhraní API s OAuth.`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Aplikace je zde vypsána, když ji vývojář přihlásí k odběru plánu v prostoru, aby mohl volat přidružená rozhraní API. Můžete pozastavit aplikaci vývojáře, abyste ji blokovali v přístupu k vašim rozhraním API, a můžete také vytvořit vaše vlastní aplikace. [Další informace]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Ověřovací adresa URL ukazuje na poskytovatele ověřování třetí strany jako registr uživatelů. Ověřovací adresa URL umožňuje integraci s registrem uživatelů třetí strany než LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Ověřovací adresa URL poskytuje jednoduchý mechanizmus pro ověření uživatelů vůči vlastnímu poskytovateli identit.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Došlo k chybě při komunikaci se subsystémem brány.`,
  [`An organization is required.`]: `Je vyžadována organizace.`,
  [`An unknown error occurred.`]: `Došlo k neznámé chybě.`,
  [`Analytics`]: `Analýza`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Služba Analytics Insights'`,
  [`Analytics Details`]: `Podrobnosti analýzy`,
  [`Analytics destinations`]: `Cíle analýzy`,
  [`Analytics Director keystore`]: `Úložiště klíčů produktu Analytics Director`,
  [`Analytics Service`]: `Analytická služba`,
  [`Analytics Service URL`]: `Adresa URL analytických služeb`,
  [`Analytics Service {arg} has been removed.`]: `Analytická služba {arg} byla odebrána.`,
  [`Analytics details`]: `Podrobnosti analýzy`,
  [`Analytics service`]: `Analytická služba`,
  [`Analytics service details`]: `Podrobnosti služby analýzy`,
  [`Analytics service URL`]: `Adresa URL analytických služeb`,
  [`Analytics service {arg} has been removed.`]: `Analytická služba {arg} byla odebrána.`,
  [`Analytics client TLS client profile`]: `Profil klienta TLS klienta analýz`,
  [`Analytics client keystore`]: `Úložiště klíčů klienta analýz`,
  [`Analytics client truststore`]: `Úložiště údajů o důvěryhodnosti klienta analýz`,
  [`Analytics ingestion TLS client profile`]: `Profil klienta TLS příjmu analýz`,
  [`Analytics ingestion keystore`]: `Úložiště klíčů příjmu analýz`,
  [`Analytics ingestion truststore`]: `Úložiště údajů o důvěryhodnosti příjmu analýz`,
  [`Analytics service {title} has been created.`]: `Analytická služba {title} byla vytvořena.`,
  [`Analytics service {title} has been updated.`]: `Analytická služba {title} byla aktualizována.`,
  [`The selected consumer organization is`]: `Vybraná zákaznická organizace je`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Analytické služby jsou nakonfigurované a analytická data jsou odlehčena externě`,
  [`Analyze API usage and performance`]: `Analyzovat použití a výkon rozhraní API`,
  [`Anonymous bind`]: `Anonymní vazba`,
  [`Another user registry`]: `Jiný registr uživatelů`,
  [`api-administrator`]: `administrátor rozhraní api`,
  [`Api-Analytics`]: `Analytika rozhraní API`,
  [`Api-Drafts`]: `Api-Drafts`,
  [`App`]: `Aplikace`,
  [`App name`]: `Název aplikace`,
  [`App-Analytics`]: `Analytika aplikace`,
  [`App-Approval`]: `Schválení aplikace`,
  [`App-Dev`]: `Vývoj aplikace`,
  [`App-analytics`]: `Analytika aplikace`,
  [`App-dev`]: `Vývoj aplikace`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Aplikace`,
  [`Application Authentication`]: `Ověření aplikace`,
  [`Application Authentication Source`]: `Zdroj ověření aplikace`,
  [`Application lifecycle`]: `Životní cyklus aplikace`,
  [`Application approval task for upgrading application`]: `Úloha schválení aplikace pro upgrade aplikace`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Úloha schválení aplikace pro upgrade aplikace {taskname} na produkci požadované uživatelem {username} ({orgname})`,
  [`Application authentication`]: `Ověření aplikace`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Vývojáři aplikací nejprve odebírají své aplikace do jednoho nebo více plánů pomocí nástroje Developer Portal. Avšak pro vybraný plán můžete migrovat odběry aplikace do plánu v jiném produktu.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Vývojáři aplikací nejprve odebírají své aplikace do jednoho nebo více plánů pomocí nástroje Developer Portal. Avšak pro vybraný plán můžete migrovat odběry aplikace do plánu v jiném produktu.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Vývojáři aplikací odebírají aplikaci k plánu tak, aby mohli v tomto plánu volat rozhraní API.`,
  [`Application is being created. Please Wait`]: `Aplikace se vytváří. Čekejte, prosím.`,
  [`Application scope check`]: `Kontrola rozsahu aplikace`,
  [`Applications`]: `Aplikace`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Aplikace, které existovaly před zapnutím životního cyklu aplikace, zůstanou v produkčním stavu`,
  [`Applied security`]: `Použité zabezpečení`,
  [`Apply`]: `Použít`,
  [`Apply all`]: `Použít vše`,
  [`Apply all suggestions`]: `Použít všechny návrhy`,
  [`Apply analysis configuration`]: `Použít konfiguraci analýzy`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Použijte zásadu vyvolání, aby volala existující službu z vaší operace. Zásadu lze použít s daty JSON nebo XML a lze ji v rámci sestavení použít vícekrát.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Použijte zásadu upgradu webového soketu pro vytvoření připojení webového soketu pro volání existující služby z vaší operace.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Tuto zásadu použijte k vyvolání API serveru proxy v rámci vaší operace, zejména v případě, že potřebujete volat velký informační obsah. Pro každou sestavu je povoleno volat pouze jednu zásadu serveru proxy.`,
  [`Apply to selected`]: `Použít na vybrané`,
  [`Apply type analysis configuration`]: `Použít konfiguraci analýzy typu`,
  [`Approval History`]: `Historie schvalování`,
  [`Approval tasks`]: `Úlohy schválení`,
  [`Approvals`]: `Schválení`,
  [`Approve`]: `Schválit`,
  [`Archive`]: `Archivovat`,
  [`Archive Product`]: `Archivovat produkt`,
  [`Archive|permission`]: `Archivovat`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Opravdu chcete vymazat omezení z vybraných položek?`,
  [`Are you sure you want to delete API?`]: `Opravdu chcete odstranit rozhraní API?`,
  [`Are you sure you want to delete Product?`]: `Opravdu chcete odstranit produkt?`,
  [`Are you sure you want to delete a catalog?`]: `Opravdu chcete odstranit katalog?`,
  [`Are you sure you want to delete a space?`]: `Opravdu chcete odstranit prostor?`,
  [`Are you sure you want to delete this application?`]: `Opravdu chcete odstranit tuto aplikaci?`,
  [`Are you sure you want to delete this billing integration?`]: `Opravdu chcete odstranit tuto integraci s platebním systémem?`,
  [`Are you sure you want to delete this field?`]: `Opravdu chcete odstranit toto pole?`,
  [`Are you sure you want to delete this policy?`]: `Opravdu chcete odstranit tuto zásadu?`,
  [`Are you sure you want to delete this type?`]: `Opravdu chcete odstranit tento typ?`,
  [`Are you sure you want to disable spaces?`]: `Opravdu chcete zakázat prostory?`,
  [`Are you sure you want to disable custom email sender information?`]: `Jste si jisti, že chcete zakázat vlastní informace o odesilateli e-mailu?`,
  [`Are you sure you want to disable custom notification templates?`]: `Opravdu chcete zakázat vlastní šablony oznámení?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Opravdu chcete přestat používat portál IBM Developer Portal? Všechna přizpůsobení portálu pro katalog budou odstraněna`,
  [`Are you sure you want to enable spaces?`]: `Opravdu chcete povolit prostory?`,
  [`Are you sure you want to enable custom email sender information?`]: `Jste si jisti, že chcete povolit vlastní informace o odesilateli e-mailu?`,
  [`Are you sure you want to enable custom notification templates?`]: `Opravdu chcete povolit vlastní šablony oznámení?`,
  [`Are you sure you want to proceed with delete?`]: `Opravdu chcete pokračovat v odstranění?`,
  [`Are you sure you want to publish?`]: `Opravdu chcete publikovat?`,
  [`Are you sure you want to re-stage?`]: `Opravdu chcete znovu fázovat?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Opravdu chcete odebrat produkt z katalogu?`,
  [`Are you sure you want to unassociate?`]: `Opravdu chcete zrušit přidružení?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Opravdu chcete aktualizovat samoobslužné zahrnutí`,
  [`Are you sure?`]: `Jste si jisti?`,
  [`Argument`]: `Argument`,
  [`Array`]: `Pole`,
  [`Assemble`]: `Sestavení`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Sestavte tok zásad nezbytný pro poskytovatele OAuth.`,
  [`Assembly`]: `Sestavení`,
  [`Assembly Execute`]: `Provedení sestavení`,
  [`Assembly saved`]: `Sestavení uloženo`,
  [`Assembly burst limits`]: `Limity dávky sestavení`,
  [`Assembly count limits`]: `Limity počtu sestavení`,
  [`Assign roles`]: `Přiřadit role`,
  [`Assign roles to the current owner`]: `Přiřadit role aktuálnímu vlastníkovi`,
  [`Associate`]: `Přidružit`,
  [`Associate analytics service`]: `Přidružit analytickou službu`,
  [`Associate analytics`]: `Přidružit analýzy`,
  [`Assumed size`]: `Předpokládaná velikost`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Při pokusu o získání rozhraní API z adresy URL {url} došlo k chybě ověření. Zkontrolujte jméno uživatele a heslo.`,
  [`Audience claim`]: `Nárok cílové skupiny`,
  [`Audience Claim`]: `Nárok cílové skupiny`,
  [`Audit setting`]: `Nastavení auditu`,
  [`Audit setting has been changed`]: `Nastavení auditu bylo změněno`,
  [`Auditing is **{mode}**`]: `Auditování je **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `Auditování se používá k monitorování volání API a protokolování informací o volajících uživatelích, času každého volání a aktivity zahrnující každou událost.`,
  [`Authenticate Client Method`]: `Ověřit metodu klienta`,
  [`Authenticate password`]: `Heslo pro ověření`,
  [`Authenticate user`]: `Ověřit uživatele`,
  [`Authenticate user settings`]: `Ověřit nastavení uživatele`,
  [`Authenticate application users using`]: `Ověřit uživatele aplikací pomocí`,
  [`Authenticate using Basic Authentication`]: `Ověřit pomocí základního ověřování`,
  [`Authenticate using OAuth`]: `Ověřit pomocí OAuth`,
  [`Authenticated`]: `Ověřeno`,
  [`Authenticated bind`]: `Ověřená vazba`,
  [`Authenticated User Name`]: `Jméno ověřeného uživatele`,
  [`Authentication`]: `Ověření`,
  [`Authentication Bind`]: `Vazba ověření`,
  [`Authentication error`]: `Chyba ověření`,
  [`Authentication method`]: `Metoda ověření`,
  [`Authentication TLS profile`]: `Profil TLS ověření`,
  [`Authentication URL`]: `Ověřovací adresa URL`,
  [`Authentication URL user registry`]: `Registr uživatelů ověřovací adresy URL`,
  [`Authentication URL user registry has been created.`]: `Registr uživatelů ověřovací adresy URL byl vytvořen.`,
  [`Authentication URL to use for validation.`]: `Ověřovací adresa URL, která se má použít pro ověření.`,
  [`Authentication response header credential`]: `Pověření záhlaví odpovědi ověření`,
  [`Authentication response header pattern`]: `Vzor záhlaví odpovědi ověření`,
  [`Authentication type`]: `Typ ověřování`,
  [`Authentication type to use to validate the UsernameToken.`]: `Typ ověřování, který se má použít k ověření tokenu jména uživatele.`,
  [`Authorization`]: `Autorizace`,
  [`Authorization Code`]: `Autorizační kód`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Adresa URL autorizace musí odpovídat bodu {endpoint}, jak je definováno poskytovatelem OAuth "{providerTitle}"`,
  [`Authorization endpoint`]: `Koncový bod autorizace`,
  [`Authorization Request`]: `Požadavek autorizace`,
  [`Authorization URL`]: `Adresa URL autorizace`,
  [`Authorize`]: `Autorizovat`,
  [`Authorize User Settings`]: `Autorizovat nastavení uživatele`,
  [`Authorize application users using`]: `Autorizovat uživatele aplikace pomocí`,
  [`Authorize path`]: `Cesta k autorizaci`,
  [`Authors API and product definitions`]: `Vytváří definice produktů a rozhraní API.`,
  [`Auto Generate OIDC API Assembly`]: `Automaticky generovat sestavení rozhraní API OIDC`,
  [`Auto onboard`]: `Automatické zahrnutí`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Automaticky generovaný název pro použití v rozhraních API pro správu a příkazech služby API Connect.`,
  [`Automation`]: `Automatizace`,
  [`Automation Management Console`]: `Konzola správy automatizace`,
  [`Automation management console`]: `Konzola správy automatizace`,
  [`Availability Zone`]: `Zóna dostupnosti`,
  [`Availability zone`]: `Zóna dostupnosti`,
  [`Availability zone {arg} has been removed.`]: `Zóna dostupnosti {arg} byla odebrána.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Zóny dostupnosti vám umožňují seskupit služby API Connect tak, aby vyhovovaly vašim obchodním potřebám. Můžete například seskupit služby brány podle regionu nebo datového centra, ve kterém se nacházejí.`,
  [`Average response time: {{value}}ms`]: `Průměrná doba odezvy: {{value}} ms`,
  [`Average time`]: `Průměrná doba`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Vyhněte se chybám CORS pomocí serveru API Designer jako lokálního serveru proxy.`,
  [`Back`]: `Zpět`,
  [`Back to sign in`]: `Zpět na přihlášení`,
  [`Back to test`]: `Zpět na test`,
  [`Backend type`]: `Typ back-end`,
  [`Bad gateway`]: `Chybná brána`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Základní DN`,
  [`Base Path`]: `Základní cesta`,
  [`Base path`]: `Základní cesta`,
  [`Base endpoint`]: `Základní koncový bod`,
  [`Base endpoints`]: `Základní koncové body`,
  [`Base64 encoding`]: `Kódování ve formátu Base64`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `Kódování ve formátu Base64 pro tokeny resetování pozvánek a hesel je **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Nastavení kódování ve formátu Base64 pro dočasný token bylo změněno.`,
  [`Base URL of API invocation endpoint`]: `Základní adresa URL koncového bodu vyvolání rozhraní API`,
  [`Basepath root`]: `Kořen základní cesty`,
  [`Basic`]: `Základní`,
  [`Basic authentication`]: `Základní ověřování`,
  [`Basic authentication password`]: `Heslo pro základní ověření`,
  [`Basic authentication request header name`]: `Název záhlavní žádosti o základní ověření`,
  [`Basic authentication username`]: `Jméno uživatele základního ověření`,
  [`Before you begin`]: `Než začnete`,
  [`Before you begin...`]: `Než začnete...`,
  [`Between 0 and 10 events are waiting to be processed`]: `0 až 10 událostí čeká na zpracování`,
  [`Between 10 and 20 events are waiting to be processed`]: `10 až 20 událostí čeká na zpracování`,
  [`Billing`]: `Platební systém`,
  [`Billing and Payment`]: `Platební systém a platby`,
  [`Billing has been updated`]: `Platební systém byl aktualizován`,
  [`Billing integrations`]: `Integrace s platebním systémem`,
  [`Billing integration`]: `Integrace s platebním systémem`,
  [`Billing Integration`]: `Integrace s platebním systémem`,
  [`Billing {title} successfully added.`]: `Platební systém {title} byl úspěšně přidán.`,
  [`Billing {title} successfully deleted.`]: `Platební systém {title} byl úspěšně odstraněn.`,
  [`Billing {title} successfully updated.`]: `Platební systém {title} byl úspěšně aktualizován.`,
  [`Billing Options`]: `Volby platebního systému`,
  [`Blocklist`]: `Seznam blokování`,
  [`Blocking`]: `Blokování`,
  [`Blocking option`]: `Volba blokování`,
  [`Body`]: `Tělo`,
  [`Boolean`]: `Logický`,
  [`Both`]: `Obojí`,
  [`Browse`]: `Procházet`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Ukládat požadavky a odezvy rozhraní API do vyrovnávací paměti před zpracováním na bráně DataPower API Gateway.`,
  [`Buffering`]: `Ukládání do vyrovnávací paměti`,
  [`Builds and manages apps in the developer organization`]: `Sestavuje a spravuje aplikace ve vývojářské organizaci`,
  [`Burst Limit Name`]: `Název limitu dávky`,
  [`Burst limits`]: `Limity dávky`,
  [`Burst limit value can't be negative`]: `Hodnota limitu dávky nemůže být záporná.`,
  [`Buy`]: `Koupit`,
  [`but unable to remove because of limitation`]: `ale nelze odebrat kvůli omezení`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Konfigurací viditelnosti a možnosti přihlášení k odběru produktu můžete řídit dostupnost svých rozhraní API pro vývojáře aplikací v nástroji Developer Portal. Můžete řídit, kteří vývojáři aplikací mohou produkt zobrazit a kteří vývojáři aplikací se mohou přihlásit k odběru těchto rozhraní API v produktu.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Konfigurací viditelnosti a možnosti přihlášení k odběru produktu můžete řídit dostupnost svých rozhraní API pro vývojáře aplikací v nástroji Developer Portal. Můžete řídit, kteří vývojáři aplikací mohou produkt zobrazit a kteří vývojáři aplikací se mohou přihlásit k odběru těchto rozhraní API v produktu.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Standardně bude výsledkem mapování z více zdrojů (řekněme array1 délky 2 a array2 délky 3) cílové pole s 5 položkami (2 zpracováním array1 a další 3 zpracováním array2). Preferujete-li kombinaci těchto pravidel (vytvoření pole obsahujícího 2 nebo 3 položky s vlastnostmi z daného slučovaného zdrojového pole), potom zaškrtněte tuto volbu.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Tento produkt je standardně publikován do všech příslušných služeb brány. Povolením této volby můžete také publikovat produkt do specifických služeb brány.`,
  [`By payload`]: `Podle informačního obsahu`,
  [`By URL parameter`]: `Podle parametru adresy URL`,
  [`Bytes`]: `Bajtů`,
  [`CALLS`]: `VOLÁNÍ`,
  [`Calls`]: `Volání`,
  [`CATALOG`]: `KATALOG`,
  [`CERTIFICATE NAME`]: `NÁZEV CERTIFIKÁTU`,
  [`CIPHER`]: `ŠIFRA`,
  [`CIPHER SUITES`]: `ŠIFROVACÍ SADY`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Pouze CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + Zpětná smyčka + API Designer`,
  [`Client ID`]: `ID klienta`,
  [`CLOUD ADMINISTRATOR`]: `ADMINISTRÁTOR CLOUDU`,
  [`Consumer organization`]: `Zákaznická organizace`,
  [`CONSUMER ORGANIZATION / GROUP`]: `ZÁKAZNICKÁ ORGANIZACE/SKUPINA`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `Balík CA`,
  [`Cache Key`]: `Klíč mezipaměti`,
  [`Cache Time to Live (second)`]: `Doba životnosti mezipaměti (sekundy)`,
  [`Cache key`]: `Klíč mezipaměti`,
  [`Cache Time-To-Live`]: `Doba životnosti mezipaměti`,
  [`Cache type`]: `Typ mezipaměti`,
  [`Can't find the one you want?`]: `Nemůžete najít ten, který chcete?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Nelze odeslat pozvánku s neplatným poštovním serverem, zkontrolujte konfigurace poštovního serveru a operaci zkuste znovu`,
  [`Cancel`]: `Storno`,
  [`Cannot be below {min}`]: `Nemůže být menší než {min}`,
  [`Cannot be empty`]: `Pole nesmí být prázdné`,
  [`Cannot delete root type.`]: `Nelze odstranit kořenový typ.`,
  [`Cannot exceed {max}`]: `Nemůže překročit {max}`,
  [`Cannot find any APIs for this product`]: `Nelze najít žádná rozhraní API pro tento produkt`,
  [`Cannot find the one you want?`]: `Nemůžete najít to, které chcete?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Nelze použít direktivu "@listSize" pro pole, které nevrací seznam, pokud není také definováno "sizedFields".`,
  [`Cannot remove built-in scalar types`]: `Nelze odebrat vestavěné skalární typy`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Nelze odebrat dotaz, mutaci a typ kořenové operace odběru`,
  [`Cannot remove arguments of built-in directives`]: `Nelze odebrat argumenty vestavěných direktiv`,
  [`Cannot remove non-null arguments of directives`]: `Nelze odebrat argumenty direktiv, které nemají hodnotu null`,
  [`Cannot remove input types of arguments of directives`]: `Nelze odebrat vstupní typy argumentů direktiv`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Nelze odebrat výčtové hodnoty používané jako výchozí hodnoty argumentů direktiv`,
  [`Cannot remove enum values used as default values of input fields`]: `Nelze odebrat výčtové hodnoty používané jako výchozí hodnoty vstupních polí`,
  [`Cannot remove all fields of an object type`]: `Nelze odebrat všechna pole typu objektu`,
  [`Cannot remove all fields of an interface type`]: `Nelze odebrat všechna pole typu rozhraní`,
  [`Cannot remove all slicing arguments`]: `Nelze odebrat všechny argumenty slicing`,
  [`Cannot remove all input fields of an input object type`]: `Nelze odebrat všechna vstupní pole typu vstupního objektu`,
  [`Cannot remove all values of enum type`]: `Nelze odebrat všechny hodnoty výčtového typu`,
  [`Cannot remove fields of interfaces`]: `Nelze odebrat pole rozhraní`,
  [`Cannot remove non-null input fields`]: `Nelze odebrat vstupní pole, která nemají hodnotu null`,
  [`Case`]: `Případ`,
  [`Case sensitive`]: `Rozlišovat malá a velká písmena`,
  [`Catalog`]: `Katalog`,
  [`Catalog defaults`]: `Předvolby katalogu`,
  [`Catalog Invite`]: `Pozvánka katalogu`,
  [`Catalog invite`]: `Pozvánka katalogu`,
  [`Catalog invites`]: `Pozvánky katalogu`,
  [`Catalog name`]: `Název katalogu`,
  [`Catalog owner`]: `Vlastník katalogu`,
  [`Catalog owner invitation`]: `Pozvánka vlastníka katalogu`,
  [`Catalog properties`]: `Vlastnosti katalogu`,
  [`Catalog summary`]: `Souhrn katalogu`,
  [`Catalog title`]: `Název katalogu`,
  [`Catalog user registries`]: `Registry uživatelů katalogu`,
  [`Catalog by Name`]: `Katalog podle názvu`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Priorita katalogu (použití jednoduchých koncových bodů definovaných administrátorem katalogu)`,
  [`Catalog {name} created`]: `Katalog {name} byl vytvořen`,
  [`Catalog selection`]: `Výběr katalogu`,
  [`Catalog settings`]: `Nastavení katalogu`,
  [`Catalog settings have been updated`]: `Nastavení katalogu byla aktualizována`,
  [`Catalog User`]: `Uživatel katalogu`,
  [`Catalog Users`]: `Uživatelé katalogu`,
  [`Catalog user registry`]: `Registr uživatelů katalogu`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `Katalog s povoleným životním cyklem nelze použít v předvolbách testu.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Katalog s povoleným životním cyklem a režimem produkce nelze použít v předvolbách testu.`,
  [`Catalogs`]: `Katalogy`,
  [`Catch`]: `Zachycená položka`,
  [`Catches`]: `Zachycené položky`,
  [`Categories`]: `Kategorie`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Kategorie zřizují hierarchické zobrazení produktů API v nástroji Developer Portal. Použijte následující syntaxi: top_level_element/next_level_element/lower_level_element.`,
  [`Category`]: `Kategorie`,
  [`Certifcate or Shared Secret for Verify`]: `Certifikát nebo sdílený tajný klíč pro ověření`,
  [`Certificate`]: `Certifikát`,
  [`Certificate (Optional)`]: `Certifikát (volitelný)`,
  [`Certificate management`]: `Správa certifikátů`,
  [`Certificate Manager instance`]: `Instance správce certifikátů`,
  [`Certificate (optional)`]: `Certifikát (volitelný)`,
  [`Certificates`]: `Certifikáty`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Certifikáty jsou nezbytné pro zabezpečení spojení mezi bránou a doménami, které zpracovává. Všechny certifikáty musí být uložené ve službě správce certifikátů. Služba poskytuje zabezpečené úložiště pro certifikáty a pomáhá předcházet výpadkům tím, že vám odešle oznámení, až se bude blížit vypršení platnosti certifikátů. Chcete-li začít:`,
  [`Certificates details in this keystore`]: `Podrobnosti o certifikátu v tomto úložišti klíčů.`,
  [`Certificates details in this truststore`]: `Podrobnosti o certifikátu v tomto úložišti údajů o důvěryhodnosti`,
  [`Change`]: `Změnit`,
  [`Change Consumer Organization owner to an existing user`]: `Změnit vlastníka zákaznické organizace na existujícího uživatele`,
  [`Change Ownership to an Existing User`]: `Změnit vlastnictví na existujícího uživatele`,
  [`Change portal service`]: `Změnit portálovou službu`,
  [`Change owner to existing user`]: `Změnit vlastníka na existujícího uživatele`,
  [`Change owner to new user`]: `Změnit vlastníka na nového uživatele`,
  [`Change ownership by inviting a new user`]: `Změnit vlastnictví pozváním nového uživatele`,
  [`Change ownership to`]: `Změnit vlastnictví na`,
  [`Change ownership to a new user via email invitation`]: `Změnit vlastnictví na nového uživatele pomocí e-mailové pozvánky`,
  [`Change ownership to an existing user`]: `Změnit vlastnictví na existujícího uživatele`,
  [`Change password`]: `Změnit heslo`,
  [`Change setup`]: `Změnit nastavení`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Změny provedené přímo v rozhraní API nebo v sestavení, na němž je založen poskytovatel OAuth, mohou poskytovatele OAuth zneplatnit, když současně neaktualizujete všechny odpovídající hodnoty v definici samotného poskytovatele OAuth. Při ukládání takovýchto změn se ujistěte, že jste v definici poskytovatele OAuth opravdu provedli všechny odpovídající aktualizace.`,
  [`Change the following extra constraints in the schema:`]: `Změňte následující další omezení ve schématu:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `Změna správce certifikátů odebere všechna existující přiřazení certifikátu a balíku CA.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Změna schématu DNS změní formát odkazů URL na službu API Connect a nástroj Developer Portal. Budete muset sdělit nové odkazy uživatelům. Pokud nechcete pokračovat, klepněte na tlačítko Storno.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Změna názvu nebo verze rozhraní API má za následek vytvoření nového rozhraní API. Opravdu chcete provedené změny uložit?`,
  [`Check your email`]: `Zkontrolujte si e-mail`,
  [`Child`]: `Podřízený prvek`,
  [`Chinese (Simplified)`]: `Čínština (zjednodušená)`,
  [`Chinese (Traditional)`]: `Čínština (tradiční)`,
  [`Choose`]: `Zvolit`,
  [`Choose a`]: `Zvolit`,
  [`Choose {docTypeLabel}`]: `Zvolit {docTypeLabel}`,
  [`Choose Catalog`]: `Zvolit katalog`,
  [`Choose a Catalog`]: `Zvolte katalog`,
  [`Choose a Consumer Org`]: `Zvolit organizaci spotřebitele`,
  [`Choose Gateway`]: `Zvolit bránu`,
  [`Choose a Gateway`]: `Zvolte bránu`,
  [`Choose Product`]: `Zvolit produkt`,
  [`Choose a Product`]: `Zvolte produkt`,
  [`Choose Rate Limit`]: `Zvolit limit četnosti`,
  [`Choose a Rate Limit`]: `Zvolte limit četnosti`,
  [`Choose Application`]: `Zvolit aplikaci`,
  [`Choose an Application`]: `Zvolit aplikaci`,
  [`Choose a {docTypeLabel}`]: `Zvolit {docTypeLabel}`,
  [`Choose a publish destination`]: `Zvolit cíl publikování`,
  [`Choose a billing integration`]: `Zvolit integraci s platebním systémem`,
  [`Choose a catalog to test within:`]: `Vyberte katalog, který se má testovat v rámci:`,
  [`Choose a plan against which to test:`]: `Vyberte plán, podle kterého se má testovat:`,
  [`Choose a product containing this API, or create a new one:`]: `Vyberte produkt, který obsahuje toto rozhraní API, nebo vytvořte nový produkt:`,
  [`Choose a user registry`]: `Zvolit registr uživatelů`,
  [`Choose an application with which to test, or create a new one:`]: `Vyberte aplikaci, se kterou chcete testovat, nebo vytvořte novou aplikaci:`,
  [`Choose an existing application`]: `Vyberte existující aplikaci`,
  [`Choose an existing catalog`]: `Zvolit existující katalog`,
  [`Choose an existing product published to your Sandbox catalog`]: `Zvolit existující produkt publikovaný v katalogu sandboxu`,
  [`Choose an operation to invoke:`]: `Zvolte operaci, kterou chcete vyvolat:`,
  [`Choose an option`]: `Zvolte volbu`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Vyberte volbu pro určení způsobu, jak budou zásady mapování zacházet s výstupem prázdného pole. Volba Vše (výchozí volba) bude obsahovat výstup všech prázdných polí a prázdných podřízených polí. Volba nadřízené položky bude mít jako výstup pouze nadřízené prázdné pole. Volba Není nebude mít jako výstup prázdné pole.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Výběr blokujícího režimu bude mít negativní vliv na výkon systému`,
  [`Choose existing directory`]: `Zvolit existující adresář`,
  [`Choose one...`]: `Zvolte položku...`,
  [`Choose operations and paths to generate into this API`]: `Zvolte operace a cesty, které se mají generovat do tohoto rozhraní API`,
  [`Choose paths to generate into this API`]: `Zvolte cesty pro generování do tohoto rozhraní API`,
  [`Choose the DNS scheme`]: `Zvolit schéma DNS`,
  [`Choose the permissions for the role.`]: `Zvolte oprávnění pro roli.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Zvolte, zda se mají pro příchozí volání API do služby brány a pro přístup k nástroji Developer Portal používat dynamické nebo statické adresy DNS.`,
  [`Cipher`]: `Šifra`,
  [`Ciphers`]: `Šifry`,
  [`Clear`]: `Vymazat`,
  [`Clear constraints`]: `Vymazat omezení`,
  [`Clear file`]: `Vymazat soubor`,
  [`Clear filters`]: `Vymazat filtry`,
  [`Clear from selected`]: `Vymazat z vybraných`,
  [`Clear operation filter`]: `Vymazat filtr operace`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Klepnutím na certifikát zobrazíte podrobnosti týkající se organizace a otisků prstů.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Klepnutím na níže uvedený odkaz pokračujte v autorizaci rozhraní API na jiné kartě, přičemž získáte autorizační kód pro krok 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Klepnutím na níže uvedený odkaz otevřete server na nové kartě. Pokud prohlížeč zobrazí problém s certifikátem, můžete jej přijmout a vrátit se sem, chcete-li test zopakovat.`,
  [`Click add to add a billing integration.`]: `Po klepnutí na tlačítko Přidat můžete přidat integraci s platebním systémem.`,
  [`Click Add to add a gateway extension.`]: `Chcete-li přidat rozšíření brány, klepněte na tlačítko Přidat.`,
  [`Click **Add domain** to add domains.`]: `Klepněte na volbu **Přidat doménu** pro přidání domén.`,
  [`Click Add to add a new base endpoint.`]: `Po klepnutí na tlačítko Přidat můžete přidat nový základní koncový bod.`,
  [`Click Add to add a new HTTP header.`]: `Chcete-li přidat nové záhlaví HTTP, klepněte na tlačítko Přidat.`,
  [`Click Add to add a new member.`]: `Po klepnutí na tlačítko Přidat můžete přidat nového člena.`,
  [`Click Add to add a new role.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou roli.`,
  [`Click Add to add a new scope.`]: `Po klepnutí na tlačítko Přidat můžete přidat nový rozsah.`,
  [`Click Add to add a new consumer organization group.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou skupinu zákaznických organizací.`,
  [`Click Add to create the API Key.`]: `Klepněte na tlačítko Přidat a vytvořte klíč rozhraní API.`,
  [`Click Create to add the API Key.`]: `Klepnutím na tlačítko Vytvořit přidejte klíč rozhraní API.`,
  [`Click Create to add a new user registry.`]: `Klepnutím na tlačítko Vytvořit přidejte nový registr uživatelů.`,
  [`Click Create user registry to add a new user registry.`]: `Klepnutím na tlačítko Vytvořit registr uživatelů přidejte nový registr uživatelů.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Klepnutím na tlačítko **Vytvořit organizaci poskytovatele** přidáte organizace poskytovatele.`,
  [`Click Edit to add OAuth providers.`]: `Po klepnutí na tlačítko Upravit můžete přidat poskytovatele OAuth.`,
  [`Click Edit to add a user registry.`]: `Po klepnutí na tlačítko Upravit můžete přidat nový registr uživatelů.`,
  [`Click Edit to add an API to the product.`]: `Po klepnutí na tlačítko Upravit můžete přidat nové rozhraní API do produktu.`,
  [`Click Create to add a property.`]: `Klepnutím na tlačítko Vytvořit přidejte vlastnost.`,
  [`Click Edit to add TLS client profiles.`]: `Po klepnutí na tlačítko Upravit můžete přidat profily klienta TLS.`,
  [`Click Edit to add user registries.`]: `Po klepnutí na tlačítko Upravit můžete přidat registry uživatelů.`,
  [`Click Add to add a new consumer organization.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou zákaznickou organizaci.`,
  [`Click Consumer organizations to add one.`]: `Klepnutím na zákaznickou organizaci jednu přidejte.`,
  [`Click Add to add a new application.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou aplikaci.`,
  [`Click Add to add parameters.`]: `Po klepnutí na tlačítko Přidat můžete přidat parametry.`,
  [`Click Add to add response.`]: `Po klepnutí na tlačítko Přidat můžete přidat odezvy.`,
  [`Click Create to add a new TLS client profile.`]: `Klepnutím na tlačítko Vytvořit přidejte nový profil klienta TLS.`,
  [`Click Create to add a new keystore.`]: `Klepnutím na tlačítko Vytvořit přidejte nové úložiště klíčů.`,
  [`Click Create to add a new truststore.`]: `Klepnutím na tlačítko Vytvořit přidejte nové úložiště údajů o důvěryhodnosti.`,
  [`Click **Register remote gateway** to add gateways.`]: `Klepnutím na tlačítko **Registrovat vzdálenou bránu** přidáte brány.`,
  [`Click Register gateways to add new Gateway Services.`]: `Po klepnutí na volbu Registrovat brány můžete přidat nové služby brány.`,
  [`Click Edit to add Gateway Services.`]: `Po klepnutí na tlačítko Upravit můžete přidat služby brány.`,
  [`Click Register gateways to add new gateway services.`]: `Po klepnutí na volbu Registrovat brány můžete přidat nové služby brány.`,
  [`Click Edit to add gateway services.`]: `Po klepnutí na tlačítko Upravit můžete přidat služby brány.`,
  [`Click Edit to add an email server.`]: `Po klepnutí na tlačítko Upravit můžete přidat e-mailový server.`,
  [`Click Create to add a new TLS server profile.`]: `Klepnutím na tlačítko Vytvořit přidejte nový profil serveru TLS.`,
  [`Click Create to add a new email server.`]: `Klepnutím na tlačítko Vytvořit přidejte nový e-mailový server.`,
  [`Click Add to add a new schema definition.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou definici schématu.`,
  [`Click Add to add a new property for this definition.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou vlastnosti pro tuto definici.`,
  [`Click Add to add a new parameter for this API.`]: `Po klepnutí na tlačítko Přidat můžete přidat nový parametr pro toto rozhraní API.`,
  [`Click Add to add tags and external documentation for this API.`]: `Po klepnutí na tlačítko Přidat můžete přidat značky a externí dokumentaci pro toto rozhraní API.`,
  [`Click Add to add a new path.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou cestu.`,
  [`Click Add to add a property for this API.`]: `Po klepnutí na tlačítko Přidat můžete přidat vlastnost pro toto rozhraní API.`,
  [`Click here to create a security definition`]: `Klepnutím zde vytvoříte definici zabezpečení.`,
  [`Click Add to add a new security definition.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou definici zabezpečení.`,
  [`Click Add to add a target service for this API.`]: `Po klepnutí na tlačítko Přidat můžete přidat cílovou službu pro toto rozhraní API.`,
  [`Click Add to add an operation for this path.`]: `Po klepnutí na tlačítko Přidat můžete přidat operaci pro tuto cestu.`,
  [`Click Add to add a path parameter for this path.`]: `Po klepnutí na tlačítko Přidat můžete přidat parametr cesty pro tuto cestu.`,
  [`Click Add to add a new OAuth provider.`]: `Po klepnutí na tlačítko Přidat můžete přidat nového poskytovatele OAuth.`,
  [`Click Add to add a new provider organization.`]: `Po klepnutí na tlačítko Přidat můžete přidat novou organizaci poskytovatele.`,
  [`Click Add to add a new API or product.`]: `Po klepnutí na tlačítko Přidat můžete přidat nové rozhraní API nebo produkt.`,
  [`Click Add to add a new API.`]: `Po klepnutí na tlačítko Přidat můžete přidat nové rozhraní API.`,
  [`Click Add to add a new Extension.`]: `Klepnutím na volbu Přidat přidejte nové rozšíření.`,
  [`Click Add to add new {resource}.`]: `Klepnutím na tlačítko Přidat přidejte nové {resource}.`,
  [`Click to expand`]: `Klepnutím rozbalíte`,
  [`Click to collapse`]: `Klepnutím sbalíte`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Klepnutím zjistíte, jak odeslat a spravovat certifikáty vyžadované bránou.`,
  [`Client Credentials`]: `Pověření klienta`,
  [`Client information`]: `Informace o klientovi`,
  [`Client revocation path`]: `Cesta k odvolání klienta`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Po klepnutí na volbu Registrovat službu můžete zaregistrovat službu. To vám umožní testovat koncové body při konfiguraci jiných služeb.`,
  [`Client secret`]: `Tajný klíč klienta`,
  [`Client security`]: `Zabezpečení klienta`,
  [`Client Type`]: `Typ klienta`,
  [`Client authentication method`]: `Metoda ověření klienta`,
  [`Clone`]: `Klonovat`,
  [`Close`]: `Zavřít`,
  [`Cloud`]: `Shluk`,
  [`Cloud Admin`]: `Administrátor cloudu`,
  [`Cloud Administrator`]: `Administrátor cloudu`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Administrátor cloudu, vedoucí organizace, administrátor topologie. Role člena je automaticky přiřazena všem uživatelům.`,
  [`Cloud administration`]: `Administrace cloudu`,
  [`Cloud Discovery Agent`]: `Agent zjišťování cloudu`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Lokální registr uživatelů nástroje Cloud Manager`,
  [`Cloud-Settings`]: `Nastavení cloudu`,
  [`Cloud settings invitation timeout`]: `Časový limit pozvánky nastavení Cloudu`,
  [`Cloud settings invitation timeout has been changed`]: `Časový limit pozvánky nastavení cloudu byl změněn.`,
  [`Code editor`]: `Editor kódu`,
  [`Collect Metadata`]: `Shromažďovat metadata`,
  [`Collect credentials using`]: `Shromáždit pověření pomocí`,
  [`Collect metadata`]: `Shromažďovat metadata`,
  [`Combo box`]: `Pole se seznamem`,
  [`Community Manager`]: `Správce komunity`,
  [`community-manager`]: `správce komunity`,
  [`Compatibility`]: `Kompatibilita`,
  [`Complex Definitions must be edited in the editor`]: `V editoru musí být upraveny komplexní definice.`,
  [`Compose DN`]: `Složit DN`,
  [`Compose UPN`]: `Složit UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `Sestavit nový server proxy REST definováním cest a operací`,
  [`Compose a new product by adding rate limits and plans`]: `Sestavit nový produkt přidáním limitů četnosti a plánů.`,
  [`Compression`]: `Komprese`,
  [`Condition`]: `Podmínka`,
  [`Condition editor`]: `Editor podmínek`,
  [`Confidential`]: `Důvěrné`,
  [`Configuration`]: `Konfigurace`,
  [`Configurations`]: `Konfigurace`,
  [`Configure API endpoint`]: `Konfigurovat koncový bod rozhraní API`,
  [`Configure API Gateway Service`]: `Konfigurovat službu brány API`,
  [`Configure analytics service`]: `Konfigurovat analytickou službu`,
  [`Configure availability zone`]: `Konfigurovat zónu dostupnosti`,
  [`Configure catalog user registries`]: `Konfigurovat registry uživatelů katalogu`,
  [`Configure cloud`]: `Konfigurovat cloud`,
  [`Configure DataPower API gateway service`]: `Konfigurovat službu brány DataPower API`,
  [`Configure DataPower gateway service`]: `Konfigurovat službu brány DataPower`,
  [`Configure Email Server`]: `Konfigurovat e-mailový server`,
  [`Configure gateway extension`]: `Konfigurovat rozšíření brány`,
  [`Configure portal service`]: `Konfigurovat portálovou službu`,
  [`Configure service`]: `Konfigurovat službu`,
  [`Configure topology`]: `Konfigurovat topologii`,
  [`Configure XML`]: `Konfigurovat XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Nakonfigurujte službu DataPower API Gateway pro zabezpečení a vynucení rozhraní API.`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Nakonfigurujte službu brány DataPower pro zabezpečení a vynucení rozhraní API.`,
  [`Configure a developer portal service for API consumers`]: `Nakonfigurujte službu Developer Portal pro odběratele rozhraní API.`,
  [`Configure advanced features for OIDC settings`]: `Konfigurovat rozšířené funkce pro nastavení OIDC`,
  [`Configure an analytics service to collect API call data`]: `Nakonfigurujte analytickou službu pro shromažďování dat volání API.`,
  [`Configure an email server to send invitations and notifications to users.`]: `Nakonfigurujte e-mailový server pro odesílání pozvánek a oznámení uživatelům.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Nakonfigurujte auditování, chcete-li monitorovat monitorovalo operace uživatelů komponent Admin UI, Manager UI, Toolkit, rozhraní Management REST API a portálu. Protokoly každé uživatelské operace vytvoření, aktualizace a odstranění se odesílají do níže uvedených služeb vzdáleného protokolování.`,
  [`Configure availability zones and services`]: `Konfigurujte zóny dostupnosti a služby.`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Konfigurovat způsob složení adres URL koncového bodu rozhraní API v publikovaných rozhraních API`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Nakonfigurujte způsob zobrazení jednoduchých koncových bodů v nástroji Developer Portal.`,
  [`Configure mapping`]: `Konfigurovat mapování`,
  [`Configure properties of the activity log`]: `Nakonfigurujte vlastnosti protokolu aktivit.`,
  [`Configure settings for token management and revocation.`]: `Nakonfigurujte nastavení pro odvolání a správu tokenů.`,
  [`Configure settings to generate and validate tokens.`]: `Nakonfigurujte nastavení pro vygenerování a ověření tokenů.`,
  [`Configure the API security`]: `Konfigurovat zabezpečení rozhraní API`,
  [`Configure the default set of gateway services configured for each catalog`]: `Nakonfigurujte výchozí sadu služeb brány konfigurovaných pro každý katalog.`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Nakonfigurujte nástroj Developer Portal, který je používán vývojáři aplikací pro přístup k rozhraním API v tomto katalogu.`,
  [`Configure the gateway services used by default in each catalog`]: `Nakonfigurujte použité výchozí služby brány v každém katalogu.`,
  [`Configure the gateway extension for the selected gateway`]: `Konfigurovat rozšíření brány pro vybranou bránu`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Nakonfigurujte bránu DataPower API spravovanou IBM a spravujte své vlastní vzdálené brány. [Další informace]({link})`,
  [`Configure the keystore and upload certificates`]: `Nakonfigurujte úložiště klíčů a odešlete certifikáty.`,
  [`Configure the name and email address to be used in the from field of emails`]: `Nakonfigurujte jméno a e-mailovou adresu, která se použije v poli Od e-mailů.`,
  [`Configure the portal service for the catalog`]: `Nakonfigurujte portálovou službu pro katalog.`,
  [`Configure the role and assign permissions`]: `Konfigurujte roli a přiřaďte oprávnění.`,
  [`Configure the security of this API`]: `Konfigurovat zabezpečení tohoto rozhraní API`,
  [`Configure the sender name and address to use for email notifications`]: `Nakonfigurujte jméno odesílatele a adresu, které se mají použít pro e-mailová oznámení.`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Nakonfigurujte jméno odesílatele, adresu a e-mailový server používaný k odesílání pozvánek a oznámení uživatelům.`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Konfigurujte sadu rolí, která se standardně použije, když je vytvořena zákaznická organizace.`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Konfigurujte sadu rolí, která se standardně použije, když je vytvořena organizace poskytovatele.`,
  [`Configure the templates used to invite and notify users`]: `Nakonfigurujte šablony používané k pozvání a oznámení uživatelům.`,
  [`Configure the truststore and upload certificates`]: `Nakonfigurujte úložiště údajů o důvěryhodnosti a odešlete certifikáty.`,
  [`Configure user authentication using`]: `Konfigurovat ověření uživatele pomocí`,
  [`Configure user authentication using JSON Web Tokens`]: `Nakonfigurovat ověření uživatele pomocí webových tokenů JSON`,
  [`Configure user authentication using a LDAP provider`]: `Nakonfigurovat ověření uživatele pomocí poskytovatele LDAP`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Nakonfigurovat ověření uživatele pomocí lokálního registru uživatelů služby API Connect`,
  [`Configure user authentication using an authentication URL`]: `Nakonfigurovat ověření uživatele pomocí ověřovací adresy URL`,
  [`Configure user authentication using this user registry type`]: `Nakonfigurovat ověření uživatele pomocí tohoto typu registru uživatelů`,
  [`Configure user registries, OAuth providers and TLS`]: `Konfigurovat registry uživatelů, poskytovatele OAuth a TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Konfigurovat registry uživatelů, TLS, poskytovatele OAuth a e-mailové servery`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Konfigurovat úložiště klíčů pro token přístupu, token ID a dočasný token`,
  [`Configured OAuth Provider`]: `Konfigurovaný poskytovatel OAuth`,
  [`Configured OAuth Providers`]: `Konfigurovaní poskytovatelé OAuth`,
  [`Configured OAuth provider`]: `Konfigurovaný poskytovatel OAuth`,
  [`Configured OAuth provider list has been updated`]: `Seznam konfigurovaných poskytovatelů OAuth byl aktualizován`,
  [`Configured OAuth provider list has not been updated`]: `Seznam konfigurovaných poskytovatelů OAuth nebyl aktualizován`,
  [`Confirm`]: `Potvrdit`,
  [`Confirm deletion`]: `Potvrdit odstranění`,
  [`Confirm Deletion of Portal`]: `Potvrdit odstranění portálu`,
  [`Confirm visibility settings`]: `Potvrdit nastavení viditelnosti`,
  [`Confirm new password`]: `Potvrdit nové heslo`,
  [`Confirm password`]: `Potvrdit heslo`,
  [`Confirm password should match password`]: `Potvrzení hesla by mělo odpovídat heslu`,
  [`Confirmation`]: `Potvrzení`,
  [`Congratulations, you are now registered.`]: `Blahopřejeme, jste zaregistrováni.`,
  [`Congratulations, your password has been reset!`]: `Blahopřejeme, vaše heslo bylo resetováno!`,
  [`Connect`]: `Připojit`,
  [`Connect to API Connect`]: `Připojit se ke službě API Connect`,
  [`Connected to API Manager Product version`]: `Připojeno k verzi produktu API Manager`,
  [`Connect to Cloud`]: `Připojit se ke cloudu`,
  [`Connected`]: `Připojeno`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Vytvořte definici schématu přidáním vlastností požadovaných typů. Vlastnosti můžete vnořovat`,
  [`Consider for nesting`]: `Zvážit pro vnoření`,
  [`Consumed Media Types`]: `Spotřebované typy médií`,
  [`Consumer`]: `Spotřebitel`,
  [`Consumer invitation and roles`]: `Pozvánka spotřebitele a role`,
  [`Consumer onboarding`]: `Zahrnutí spotřebitele`,
  [`Consumer organizations`]: `Zákaznické organizace`,
  [`Consumer organizations or groups`]: `Zákaznické organizace nebo skupiny`,
  [`Consumer organization {name} created`]: `Zákaznická organizace {name} byla vytvořena`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Zákaznické organizace jsou vytvářeny vývojáři aplikací v nástroji Developer Portal a zákaznické organizace lze také vytvářet pomocí uživatelského rozhraní nástroje API Manager.`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Zákaznické organizace jsou vytvářeny vývojáři aplikací v nástroji Developer Portal a také zde můžete vytvářet zákaznické organizace.`,
  [`Consumer-Org`]: `Zákaznická organizace`,
  [`Consumers`]: `Spotřebitelé`,
  [`Consumes`]: `Spotřebovává`,
  [`Contact`]: `Kontakt`,
  [`Contact information for the owners of the API.`]: `Kontaktní informace pro vlastníky rozhraní API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Obraťte se na administrátora ohledně konfigurace analytické služby, aby se vám zde zobrazovala příslušná data.`,
  [`Contact your administrator for more information.`]: `Kontaktujte administrátora, který vám poskytne další informace.`,
  [`Content`]: `Obsah`,
  [`Content on error`]: `Obsah při chybě`,
  [`Content type`]: `Typ obsahu`,
  [`Context Variable`]: `Kontextová proměnná`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Kontextová nebo běhová proměnná obsahující JWT, který má být ověřen. Pokud není nastavena, zásada hledá JWT v request.headers.authorization.`,
  [`Context variable`]: `Kontextová proměnná`,
  [`Continue`]: `Pokračovat`,
  [`Continue on error`]: `Pokračovat při chybě`,
  [`Continue with`]: `Pokračovat`,
  [`Continue with:`]: `Pokračovat:`,
  [`Conversion type`]: `Typ převodu`,
  [`Convert to expression`]: `Převést na výraz`,
  [`Cookie`]: `Soubor Cookie`,
  [`Copied`]: `Zkopírováno`,
  [`Copied to clipboard`]: `Zkopírováno do schránky`,
  [`Copy`]: `Kopírovat`,
  [`Copy Id Headers To Message`]: `Kopírovat záhlaví ID do zprávy`,
  [`Copy to clipboard`]: `Kopírovat do schránky`,
  [`Copy file path to clipboard`]: `Kopírovat cestu k souboru do schránky`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Kopírovat odhad nákladů pro dotaz GraphQL na výstup`,
  [`Copy the endpoints below to`]: `Zkopírovat koncové body níže do`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Zkopírujte klíč rozhraní API, který se má použít. Tento klíč API můžete znovu zobrazit pomocí ikony Zobrazit.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Informace o autorských právech`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Opravte chyby ve výše uvedeném editoru nebo znovu odešlete platný soubor`,
  [`Cost`]: `Náklady`,
  [`Count`]: `Počet`,
  [`Count Limit Name`]: `Název limitu počtu`,
  [`Count Limits`]: `Limity počtu`,
  [`Created at`]: `Vytvořeno v`,
  [`Counter`]: `Čítač`,
  [`Create`]: `Vytvořit`,
  [`Create a [Certificate Manager]({link}) service`]: `Vytvořit službu [správce certifikátů]({link})`,
  [`Create API`]: `Vytvořit rozhraní API`,
  [`Create an API from the Develop section.`]: `Vytvořte rozhraní API ze sekce Vývoj.`,
  [`Create API Connect API Key`]: `Vytvořit klíč rozhraní API API Connect`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Vytvořit rozhraní API z existující služby GraphQL (server proxy GraphQL)`,
  [`Create API from existing OpenAPI service`]: `Vytvořit rozhraní API z existující služby OpenAPI`,
  [`Create API from existing WSDL service (REST proxy)`]: `Vytvořit rozhraní API z existující služby WSDL (server proxy SOAP)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Vytvořit rozhraní API z existující služby WSDL (server proxy SOAP)`,
  [`Create API from target service`]: `Vytvořit rozhraní API z cílové služby`,
  [`Create API from existing REST service`]: `Vytvořit rozhraní API z existující služby REST`,
  [`Create API from existing SOAP service`]: `Vytvořit rozhraní API z existující služby SOAP`,
  [`Create API from existing WSDL service`]: `Vytvořit rozhraní API ze stávajícího jazyka WSDL`,
  [`Create API Key failed`]: `Nezdařilo se vytvořit klíč rozhraní API`,
  [`Create application`]: `Vytvořit aplikaci`,
  [`Create authentication URL user registry`]: `Vytvořit registr uživatelů ověřovací adresy URL`,
  [`Create availability zone`]: `Vytvořit zónu dostupnosti`,
  [`Create Billing`]: `Vytvořit fakturaci`,
  [`Create catalog`]: `Vytvořit katalog`,
  [`Create catalog property`]: `Vytvořit vlastnost katalogu`,
  [`Create consumer organization`]: `Vytvořit zákaznickou organizaci`,
  [`Create Credentials`]: `Vytvořit pověření`,
  [`Create definition`]: `Vytvořit definici`,
  [`Create email server`]: `Vytvořit e-mailový server`,
  [`Create IBM Developer Portal`]: `Vytvořit nástroj IBM Developer Portal`,
  [`Create keystore`]: `Vytvořit úložiště klíčů`,
  [`Create LDAP user registry`]: `Vytvořit registr uživatelů LDAP`,
  [`Create LTPA Key`]: `Vytvořit klíč LTPA`,
  [`Create local user registry`]: `Vytvořit lokální registr uživatelů`,
  [`Create native OAuth provider`]: `Vytvořit nativního poskytovatele OAuth`,
  [`Create New GraphQL API`]: `Vytvořit nové rozhraní GraphQL API`,
  [`Create new OpenAPI`]: `Vytvořit nové OpenAPI`,
  [`Create new product`]: `Vytvořit nový produkt`,
  [`Create OIDC user registry`]: `Vytvořit registr uživatelů OIDC`,
  [`Create OpenAPI definition and product definition`]: `Vytvořit definici OpenAPI a definici produktu`,
  [`Create operation`]: `Vytvořit operaci`,
  [`Create organization`]: `Vytvořit organizaci`,
  [`Create path`]: `Vytvořit cestu`,
  [`Create plan`]: `Vytvořit plán`,
  [`Create portal`]: `Vytvořit portál`,
  [`Create Product`]: `Vytvořit produkt`,
  [`Create property`]: `Vytvořit vlastnost`,
  [`Create role`]: `Vytvořit roli`,
  [`Create role default for the Organization`]: `Vytvořit výchozí roli pro organizaci`,
  [`Create sample user registry`]: `Vytvořit ukázku registru uživatelů`,
  [`Create security definition`]: `Vytvořit definici zabezpečení`,
  [`Create space`]: `Vytvořit prostor`,
  [`Create subscription`]: `Vytvořit odběr`,
  [`Create TLS client profile`]: `Vytvořit profil klienta TLS`,
  [`Create TLS server profile`]: `Vytvořit profil serveru TLS`,
  [`Create target service`]: `Vytvořit cílovou službu`,
  [`Create third party OAuth provider`]: `Vytvořit poskytovatele OAuth třetí strany`,
  [`Create truststore`]: `Vytvořit úložiště údajů o důvěryhodnosti`,
  [`Create user registry`]: `Vytvořit registr uživatelů`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Vytvořit server proxy GraphQL na základě služby GraphQL`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Vytvořit server proxy REST na základě popsané cílové služby OpenAPI`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Vytvořit server proxy REST na základě popsané cílové služby WSDL`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Vytvořit server proxy REST na základě popsané cílové služby OpenAPI`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Vytvořit server proxy REST na základě popsané cílové služby WSDL`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Vytvořit server proxy REST, který směruje veškerý provoz na cílové rozhraní API nebo koncový bod služby`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Vytvořit server proxy SOAP na základě popsané cílové služby WSDL`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Vytvořit server proxy SOAP na základě popsané cílové služby WSDL`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Vytvořit nový objekt pomocí šablony úchytů; proměnné jsou převzaty z kontextu`,
  [`Create a portal site for the catalog`]: `Vytvořit server portálu pro katalog`,
  [`Create a product by importing a product definition`]: `Vytvořit produkt importem definice produktu`,
  [`Create a security definition`]: `Vytvořit definici zabezpečení`,
  [`Create a subscription`]: `Vytvořit odběr`,
  [`Create an API by importing an OpenAPI definition`]: `Vytvořit rozhraní API importem definice OpenAPI`,
  [`Create an API that calls an existing SOAP service`]: `Vytvořit rozhraní API, které volá existující službu SOAP`,
  [`Create and manage API provider organizations and owners`]: `Vytvořit a spravovat organizace poskytovatele rozhraní API a vlastníky`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Vytvořit a spravovat prostory v katalogu; prostory vám umožňují rozdělit katalog na oblasti za účelem podpory různých vývojových týmů poskytovatele rozhraní API`,
  [`Create and publish`]: `Vytvořit a publikovat`,
  [`Create and publish APIs and Products`]: `Vytvořit a publikovat rozhraní API a produkty`,
  [`Create and subscribe`]: `Vytvořit a odebírat`,
  [`Create assembly`]: `Vytvořit sestavení`,
  [`Create empty parent object for failed mapping`]: `Vytvořit prázdný nadřízený objekt pro nezdařené mapování`,
  [`Create endpoint`]: `Vytvořit koncový bod`,
  [`Create from`]: `Vytvořit z`,
  [`Create from Existing WSDL service`]: `Vytvořit z existující služby WSDL`,
  [`Create from File or URL`]: `Vytvořit ze souboru nebo z adresy URL`,
  [`Create from existing openAPI service`]: `Vytvořit z existující služby openAPI`,
  [`Create gateway extension`]: `Vytvořit rozšíření brány`,
  [`Create new user`]: `Vytvořit nového uživatele`,
  [`Create new version`]: `Vytvořit novou verzi`,
  [`Create product using a template`]: `Vytvořit produkt pomocí šablony`,
  [`Create provider organization`]: `Vytvořit organizaci poskytovatele`,
  [`Create required child properties for array objects and mapped optional objects`]: `Vytvořit požadované podřízené vlastnosti pro objekty pole a mapované volitelné objekty`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Vytvořeno konfigurací poskytovatele OAuth jako ukázka. Ujistěte se, že jste aktualizovali poskytovatele OAuth pomocí této ukázky s platným registrem uživatelů.`,
  [`Created new product`]: `Vytvořený nový produkt`,
  [`Create|permission`]: `Vytvořit`,
  [`Create|title`]: `Vytvořit`,
  [`Creating Draft Product`]: `Vytváření konceptu produktu`,
  [`Creating a Consumer organization`]: `Vytváření zákaznické organizace`,
  [`Creating a consumer organization`]: `Vytváření zákaznické organizace`,
  [`Creating a new Product`]: `Vytváření nového produktu`,
  [`Creative Commons (CC-BY-4.0) license`]: `Licence k Creative Commons (CC-BY-4.0)`,
  [`Credential Extraction Method`]: `Metoda extrakce pověření`,
  [`Credentials for the Sandbox catalog`]: `Pověření pro katalog sandboxů`,
  [`Credentials for the {catalogName} catalog`]: `Pověření pro katalog {catalogName}`,
  [`Credential Name`]: `Název pověření`,
  [`Credential has been added.`]: `Pověření bylo přidáno.`,
  [`Credential`]: `Pověření`,
  [`Credentials`]: `Pověření`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Pověření jsou nezbytná ke spuštění nástroje API Designer a Toolkit.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Pověření jsou nezbytná ke spuštění sady nástrojů. Umístěte soubory pověření do složky, kde je nainstalována sada nástrojů.`,
  [`Credit Card`]: `Kreditní karta`,
  [`Cryptographic Algorithm`]: `Šifrovací algoritmus`,
  [`Currency`]: `Měna`,
  [`Current password`]: `Aktuální heslo`,
  [`Current password is invalid.`]: `Aktuální heslo je neplatné.`,
  [`Custom`]: `Vlastní`,
  [`Custom API URL`]: `Adresa URL vlastního rozhraní API`,
  [`Custom expression (optional, one per line)`]: `Vlastní výraz (volitelný, jeden na řádek)`,
  [`Custom expression to remove (optional, one per line)`]: `Vlastní výraz, který se má odebrat (volitelný, jeden na řádek)`,
  [`Custom HTML form`]: `Vlastní formulář HTML`,
  [`Custom policies`]: `Vlastní zásady`,
  [`Custom form TLS profile`]: `Vlastní profil protokolu TLS formuláře`,
  [`Custom form content security policy`]: `Vlastní zásada zabezpečení obsahu formuláře`,
  [`Custom form CSP header value`]: `Hodnota záhlaví CSP vlastního formuláře`,
  [`Custom form endpoint`]: `Vlastní koncový bod formuláře`,
  [`Custom header pattern`]: `Vzor vlastního záhlaví`,
  [`Custom subscribability is required if custom visibility is selected`]: `Je-li vybrána vlastní viditelnost, je zapotřebí vlastní možnost přihlášení k odběru`,
  [`Customize`]: `Přizpůsobit`,
  [`Customize email sender information`]: `Přizpůsobit informace o odesilateli e-mailu`,
  [`Customize notification templates`]: `Upravit šablony oznámení`,
  [`Customize the plan API list`]: `Upravit seznam rozhraní API plánů`,
  [`Czech`]: `Čeština`,
  [`DATE MODIFIED`]: `DATUM ZMĚNY`,
  [`DELETE`]: `ODSTRANIT`,
  [`Date created`]: `Datum vytvoření`,
  [`Description`]: `Popis`,
  [`DETAIL`]: `PODROBNOSTI`,
  [`DN Base`]: `Základ DN`,
  [`DNS Schemes`]: `Schémata DNS`,
  [`Dashboard`]: `Řídicí panel`,
  [`Data encoded form body`]: `Text s kódovanými daty`,
  [`Data flow in API Connect`]: `Datový tok ve službě API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (kompatibilní s v5)`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway (kompatibilní s v5)`,
  [`DataPower Gateway policies`]: `Zásady brány DataPower`,
  [`Debug`]: `Ladění`,
  [`Debug rule`]: `Pravidlo ladění`,
  [`Debug XACML policy`]: `Ladit zásad XACML`,
  [`Dec`]: `Prosinec`,
  [`Decline`]: `Odmítnout`,
  [`Decline and send`]: `Odmítnout a odeslat`,
  [`Decode Request Params`]: `Dekódovat parametry požadavku`,
  [`Decrement`]: `Snížit`,
  [`Decrypt`]: `Dešifrovat`,
  [`Decrypt Crypto JWK variable name`]: `Název proměnné k dešifrování JWK`,
  [`Decrypt Crypto Object`]: `Kryptografický objekt k dešifrování`,
  [`Decrypt a buffer using the specified certificate`]: `Dešifrovat vyrovnávací paměť pomocí určeného certifikátu`,
  [`Default`]: `Výchozí`,
  [`Default behavior`]: `Výchozí chování`,
  [`Default gateways`]: `Výchozí brány`,
  [`default gateway services configured`]: `konfigurované výchozí služby brány`,
  [`Default Availability Zone`]: `Výchozí zóna dostupnosti`,
  [`Default Base endpoints`]: `Výchozí základní koncové body`,
  [`Default availability zone`]: `Výchozí zóna dostupnosti`,
  [`Default base endpoints`]: `Výchozí základní koncové body`,
  [`Default HTML form`]: `Výchozí formulář HTML`,
  [`Default limit`]: `Výchozí limit`,
  [`Default OAuth Provider Settings`]: `Výchozí nastavení poskytovatele OAuth`,
  [`Default OAuth Provider Settings Object`]: `Objekt výchozího nastavení poskytovatele OAuth`,
  [`Default Plan`]: `Výchozí plán`,
  [`Default TLS client profile`]: `Výchozí profil klienta TLS`,
  [`Default TLS server keystore`]: `Výchozí úložiště klíčů serveru TLS`,
  [`Default TLS server profile`]: `Výchozí profil serveru TLS`,
  [`Default burst-limit`]: `Výchozí limit dávky`,
  [`Default form`]: `Výchozí formulář`,
  [`Default keystore for access_token signing key`]: `Výchozí úložiště klíčů pro podpisový klíč access_token`,
  [`Default keystore for id_token signing key`]: `Výchozí úložiště klíčů pro podpisový klíč id_token`,
  [`Default keystore for temporary token signing key`]: `Výchozí úložiště klíčů pro dočasný podpisový klíč`,
  [`Default rate-limit`]: `Výchozí limit četnosti`,
  [`Default scopes`]: `Výchozí rozsahy`,
  [`Default table entry`]: `Výchozí položka tabulky`,
  [`Default validator endpoint`]: `Výchozí koncový bod validátora`,
  [`Default validator endpoint set by OAuth provider`]: `Výchozí koncový bod validátoru nastavený poskytovatelem OAuth`,
  [`Default value - no extra constraints apply.`]: `Výchozí hodnota - neplatí žádná další omezení.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Definujte poskytovatele OAuth třetí strany pro vydání a ověření tokenů za účelem ochrany přístupu k rozhraní API.`,
  [`Define catalog specific values for this property`]: `Nadefinujte specifické hodnoty katalogu pro tuto vlastnost.`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Definujte skupiny, které spravují rozhraní API, produkty, katalogy, aplikace a související nastavení.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Definujte sady zásad, které se mají provést pro specifické podmínky. Může být definováno více případů a každý případ může být přidružen k libovolné podmínce. Bude proveden pouze první vyhovující případ.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Definovat zdroj ochrany rozhraní API. Musí být povolena alespoň jedna volba.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `Autorizační server OIDC přesměruje autorizační kód na následující koncové body. Tento koncový bod přesměrování je vyžadován, když zákazník registruje aplikaci na poskytovateli OIDC. Je-li tento registr uživatelů použit například organizací poskytovatele, musí zákazník zaregistrovat koncový bod přesměrování organizace poskytovatele s poskytovatelem OIDC.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Definujte vstupy, které se mají použít v mapě. K mapě můžete také přiřadit název a popis.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Definujte koncové body poskytovatele OpenID, chcete-li odeslat požadavek na ověření poskytovateli OpenID`,
  [`Define the outputs to be used in the map.`]: `Definujte výstupy, které se mají použít v mapě.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Nadefinujte počet bezplatných dnů zkušební verze a stanovení ceny plánu. Uživatelé budou zpoplatněni na základě jejich data odběru.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Definuje, zda bude tok pokračovat, když dojde k chybě během provádění zásady. Není-li tato volba vybrána, spustí se tok catch.`,
  [`Definition`]: `Definice`,
  [`Definition Object`]: `Objekt definice`,
  [`Definitions`]: `Definice`,
  [`Delete`]: `Odstranit`,
  [`Delete Application`]: `Odstranit aplikaci`,
  [`Delete Cloud Connection.`]: `Odstraňte Cloud Connection.`,
  [`Delete Selected APIs`]: `Odstranit vybraná rozhraní API`,
  [`Delete selected client profiles`]: `Odstranit vybrané profily klientů`,
  [`Delete selected keystores`]: `Odstranit vybraná úložiště klíčů`,
  [`Delete selected mail servers`]: `Odstranit vybrané poštovní servery`,
  [`Delete Selected Products`]: `Odstranit vybrané produkty`,
  [`Delete selected truststores`]: `Odstranit vybraná úložiště údajů o důvěryhodnosti`,
  [`Delete selected user registries`]: `Odstranit vybrané registry uživatelů`,
  [`Delete Staged Product`]: `Odstranit fázovaný produkt`,
  [`Delete a space`]: `Odstranit prostor`,
  [`Delete a catalog`]: `Odstranit katalog`,
  [`Delete API Key`]: `Odstranit klíč API`,
  [`Delete API Key failed`]: `Odstranění klíče rozhraní API se nezdařilo`,
  [`Delete availability zone`]: `Odstranit zónu dostupnosti`,
  [`Delete selected requests`]: `Odstranit vybrané požadavky`,
  [`Delete row`]: `Odstranit řádek`,
  [`Delete|title`]: `Odstranit`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `Odstranění katalogu může selhat, pokud máte velký počet zákaznických organizací, aplikací, publikovaných produktů a jiných prostředků. Zvažte vyčištění těchto prostředků předtím, než odstraníte katalog.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `Odstranění organizace poskytovatele může selhat, pokud máte velký počet katalogů, zákaznických organizací, aplikací, publikovaných produktů a jiných prostředků. Zvažte vyčištění těchto prostředků předtím, než odstraníte organizaci poskytovatele.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `Odstranění prostoru může selhat, pokud máte velký počet zákaznických organizací, aplikací, publikovaných produktů a jiných prostředků. Zvažte vyčištění těchto prostředků předtím, než odstraníte prostor.`,
  [`Deleting {{name}}...`]: `Odstraňování {name}...`,
  [`Deprecate`]: `Zamítnout`,
  [`Deprecate product`]: `Zamítnout produkt`,
  [`Deprecated`]: `Zamítnuto`,
  [`Deprecate|permission`]: `Zamítnout`,
  [`Description of gateway`]: `Popis brány`,
  [`Description text for the behaviour of the modal.`]: `Text popisu pro chování modálu.`,
  [`Descriptive name`]: `Popisný název`,
  [`Design`]: `Návrh`,
  [`Designate a current member as owner of a consumer organization.`]: `Určete aktuálního člena jako vlastníka zákaznické organizace.`,
  [`Designate a current member as owner of a provider organization.`]: `Určete aktuálního člena jako vlastníka organizace poskytovatele.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Cíl`,
  [`Determines which credential-extraction method to use.`]: `Určuje, která metoda extrakce pověření se má použít.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Určete, zda chcete do schématu zahrnout tento typ/pole a jakékoli vhodné argumenty.`,
  [`Develop`]: `Vyvíjet`,
  [`Develop APIs`]: `Vyvíjet rozhraní API`,
  [`Develop APIs and products`]: `Vyvíjet rozhraní API a produkty`,
  [`Developer`]: `Vývojář`,
  [`developer`]: `vývojář`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `Komunita vývojářů`,
  [`Developers can request a state upgrade from development to production`]: `Vývojáři mohou požádat o upgrade stavu z vývoje do produkce`,
  [`Development`]: `Vývoj`,
  [`Directive`]: `Direktiva`,
  [`Director Endpoint`]: `Koncový bod vedoucího`,
  [`Director TLS client profile`]: `Profil klienta TLS serveru Director`,
  [`Disable`]: `Zakázat`,
  [`Disable Activity log`]: `Zakázat protokol aktivit`,
  [`Disable Self-Service Onboarding`]: `Zakázat samoobslužné zahrnutí`,
  [`Disable spaces`]: `Zakázat prostory`,
  [`Disable buffering`]: `Vypnout ukládání do vyrovnávací paměti`,
  [`Disable Self-Service Onboarding Approval`]: `Zakázat schválení samoobslužného zahrnutí`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Zakázat přepis časového limitu pozvánky do zákaznické organizace`,
  [`Disabled`]: `Zakázáno`,
  [`disabled`]: `zakázáno`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Zakázání samoobslužného zahrnutí bude vyžadovat, aby všichni vývojáři aplikací byli pozváni poskytovatelem rozhraní API, existujícím vlastníkem nebo administrátorem zákaznické organizace.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Zakázání schválení samoobslužného zahrnutí automaticky schválí všechny požadavky na zahrnutí.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `Zakázání nenastaví časový limit pozvánky do zákaznické organizace na časový limit katalogu.`,
  [`Discover targets`]: `Zjistit cíle`,
  [`Display table checkboxes`]: `Zobrazit zaškrtávací políčka tabulky`,
  [`Display vanity endpoint`]: `Zobrazit jednoduchý koncový bod`,
  [`Do not publish API (continue editting)`]: `Nepublikovat rozhraní API (pokračovat v úpravách)`,
  [`Do not require applications or users to authenticate`]: `Nevyžadovat aplikace nebo uživatele k ověření`,
  [`Do not require authentication`]: `Nevyžadovat ověření`,
  [`Do not share the resource with provider organizations`]: `Nesdílet prostředek s organizacemi poskytovatele`,
  [`Do not share the API gateway with provider organizations`]: `Nesdílet službu API Gateway s organizacemi poskytovatele`,
  [`Do not share the service with provider organizations`]: `Nesdílet službu s organizacemi poskytovatele`,
  [`Do not use Dynamic DNS`]: `Nepoužívat dynamický server DNS`,
  [`Do you want to remove these references?`]: `Chcete odebrat tyto odkazy?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Dokumenty nástroje Docker`,
  [`Docs`]: `Dokumentace`,
  [`Document Editor`]: `Editor dokumentů`,
  [`Documentation and tutorials with step-by-step instructions`]: `Dokumentace a výukové programy s podrobnými pokyny`,
  [`Domain name`]: `Název domény`,
  [`Domain Name`]: `Název domény`,
  [`Domains handled by gateway via SNI`]: `Domény zpracované bránou prostřednictvím rozhraní SNI`,
  [`Don't have an account?`]: `Nemáte účet?`,
  [`Done`]: `Hotovo`,
  [`Download`]: `Stáhnout`,
  [`Download Gateway`]: `Stáhnout bránu`,
  [`Download and install`]: `Stáhnout a nainstalovat`,
  [`Download and set up DataPower API Gateway`]: `Stáhnout a nastavit službu DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Stáhněte a nastavte použití formuláře služby DataPower API Gateway v prostorách nebo v libovolném cloudu.`,
  [`Download for Linux`]: `Stáhnout pro systém Linux`,
  [`Download for Mac`]: `Stáhnout pro systém Mac`,
  [`Download for Windows`]: `Stáhnout pro systém Windows`,
  [`Download gateway`]: `Stáhnout bránu`,
  [`Download schema`]: `Stáhnout schéma`,
  [`Download Toolkit`]: `Stáhnout sadu nástrojů`,
  [`Download toolkit`]: `Stáhnout sadu nástrojů`,
  [`Download toolkit and credentials for various platforms`]: `Stáhnout sadu nástrojů a pověření pro různé platformy`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Stažená pověření nebyla pro toto připojení cloudu nalezena. Při přihlášení se použijí výchozí pověření.`,
  [`Dutch`]: `Holandština`,
  [`Draft {type} creation failed`]: `Vytvoření konceptu {type} se nezdařilo`,
  [`Drafts`]: `Koncepty`,
  [`Drag and drop files here or click to upload`]: `Přetáhněte soubory sem, nebo je přeneste klepnutím`,
  [`Drag & Drop`]: `Přetáhněte a pusťte`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `Dynamický server DNS`,
  [`Dynamic OAuth configuration from a URL`]: `Dynamická konfigurace OAuth z adresy URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Dynamická konfigurace OAuth z literálového řetězce`,
  [`Dynamic group`]: `Dynamická skupina`,
  [`Dynamic table entries`]: `Dynamické položky tabulky`,
  [`ENABLE`]: `POVOLIT`,
  [`Encoded`]: `Zakódováno`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Každý prostor je používán jiným vývojovým týmem poskytovatele rozhraní API a má vlastní sadu funkcí správy souvisejících zejména s rozhraními API, která přidružený tým publikuje do tohoto prostoru a umožňuje každému týmů nezávisle spravovat svá rozhraní API. Když fázujete nebo publikujete rozhraní API do katalogu, který má povolené prostory, uveďte prostor v tomto katalogu, do kterého chcete fázovat nebo publikovat.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Každý organizace poskytovatele vlastní sadu rozhraní API, produktů, plánů a katalogů.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Snadno vytvářejte, zabezpečujte, spravujte, sdílejte a analyzujte rozhraní API umístěná v cloudu i místně.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Každý organizace poskytovatele vlastní sadu rozhraní API, produktů, plánů a katalogů. [Další informace]({link})`,
  [`Edit`]: `Upravit`,
  [`Edit access token TTL`]: `Upravit TTL přístupového tokenu`,
  [`Edit admin organization invitation timeout`]: `Upravit časový limit pozvánky administrátorské organizace`,
  [`Edit API`]: `Upravit rozhraní API`,
  [`Edit API Connect UI`]: `Upravit uživatelské rozhraní služby API Connect`,
  [`Edit API endpoint for unenforced APIs`]: `Upravit koncový bod rozhraní API pro nevynucená rozhraní API`,
  [`Edit API gateway service`]: `Upravit službu brány API Gateway`,
  [`Edit API Path`]: `Upravit cestu k rozhraní API`,
  [`Edit API security definition`]: `Upravit definici zabezpečení rozhraní API`,
  [`Edit API user registries`]: `Upravit registry uživatelů rozhraní API`,
  [`Edit analytics service`]: `Upravit analytickou službu`,
  [`Edit application`]: `Upravit aplikaci`,
  [`Edit Assembly`]: `Upravit sestavení`,
  [`Edit authentication URL user registry`]: `Upravit registr uživatelů ověřovací adresy URL`,
  [`Edit availability zone`]: `Upravit zónu dostupnosti`,
  [`Edit Availablity Zone`]: `Upravit zónu dostupnosti`,
  [`Edit and apply constraints to your selected items.`]: `Upravte a použijte omezení pro vybrané položky.`,
  [`Edit billing integration`]: `Upravit integraci s platebním systémem`,
  [`Edit Catalog Details`]: `Upravit podrobnosti katalogu`,
  [`Edit cloud setting invitation timeout`]: `Upravit časový limit pozvánky nastavení cloudu`,
  [`Edit constraints`]: `Upravit omezení`,
  [`Edit consumer organization`]: `Upravit zákaznickou organizaci`,
  [`Edit DNS Scheme`]: `Upravit schéma DNS`,
  [`Edit DataPower API gateway service`]: `Upravit službu brány DataPower API`,
  [`Edit DataPower gateway service`]: `Upravit službu brány DataPower`,
  [`Edit definition`]: `Upravit definici`,
  [`Edit dynamic DNS`]: `Upravit dynamický server DNS`,
  [`Edit email server`]: `Upravit e-mailový server`,
  [`Edit extensions`]: `Upravit rozšíření`,
  [`Edit Gateway Service`]: `Upravit službu brány`,
  [`Edit gateway`]: `Upravit bránu`,
  [`Edit gateway extension`]: `Upravit rozšíření brány`,
  [`Edit Gateways`]: `Upravit brány`,
  [`Edit IBM-managed gateway`]: `Upravit bránu spravovanou IBM`,
  [`Edit Invitation Timeout`]: `Upravit časový limit pozvánky`,
  [`Edit gateways`]: `Upravit brány`,
  [`Edit invitation timeout`]: `Upravit časový limit pozvánky`,
  [`Edit JSON`]: `Upravit JSON`,
  [`Edit keystore`]: `Upravit úložiště klíčů`,
  [`Edit LDAP user registry`]: `Upravit registr uživatelů LDAP`,
  [`Edit LTPA Token`]: `Upravit token LTPA`,
  [`Edit lifecycle approvals`]: `Upravit schválení životního cyklu`,
  [`Edit local user registry`]: `Upravit lokální registr uživatelů`,
  [`Edit native OAuth provider`]: `Upravit nativního poskytovatele OAuth`,
  [`Edit New Billing System`]: `Upravit nový fakturační systém`,
  [`Edit Notification Server`]: `Upravit server oznámení`,
  [`Edit Notification template`]: `Upravit šablonu oznámení`,
  [`Edit OAuth Provider`]: `Upravit poskytovatele OAuth`,
  [`Edit OAuth provider details`]: `Upravte podrobnosti poskytovatele OAuth.`,
  [`Edit OAuth Provider Visibility`]: `Upravit viditelnost poskytovatele OAuth`,
  [`Edit OIDC User Registry`]: `Upravit registr uživatelů OIDC`,
  [`Edit Onboarding`]: `Upravit zahrnutí`,
  [`Edit Operation`]: `Upravit operaci`,
  [`Edit Organization`]: `Upravit organizaci`,
  [`Edit Path`]: `Upravit cestu`,
  [`Edit Path Parameters`]: `Upravit parametry cesty`,
  [`Edit notification template`]: `Upravit šablonu oznámení`,
  [`Edit OAuth provider`]: `Upravit poskytovatele OAuth`,
  [`Edit OAuth provider visibility`]: `Upravit viditelnost poskytovatele OAuth`,
  [`Edit OIDC user registry`]: `Upravit registr uživatelů OIDC`,
  [`Edit onboarding`]: `Upravit zahrnutí`,
  [`Edit operation`]: `Upravit operaci`,
  [`Edit organization`]: `Upravit organizaci`,
  [`Edit path`]: `Upravit cestu`,
  [`Edit path parameters`]: `Upravit parametry cesty`,
  [`Edit plan`]: `Upravit plán`,
  [`Edit portal`]: `Upravit portál`,
  [`Edit portal service`]: `Upravit portálovou službu`,
  [`Edit Portal Settings`]: `Upravit nastavení portálu`,
  [`Edit product`]: `Upravit produkt`,
  [`Edit product APIs`]: `Upravit rozhraní API produktu`,
  [`Edit Product Subscribability`]: `Upravit možnost přihlášení k odběru produktu`,
  [`Edit Product Visibility`]: `Upravit viditelnost produktu`,
  [`Edit Property`]: `Upravit vlastnost`,
  [`Edit Provider Organization`]: `Upravit organizaci poskytovatele`,
  [`Edit Refresh Token TTL`]: `Upravit TTL aktualizačního tokenu`,
  [`Edit remote gateway`]: `Upravit vzdálenou bránu`,
  [`Edit Reset Password TTL`]: `Upravit TTL pro resetování hesla`,
  [`Edit Role`]: `Upravit roli`,
  [`Edit Role Default for Provider Organizations`]: `Upravit výchozí roli pro organizace poskytovatele`,
  [`Edit Role for Provider Organizations`]: `Upravit roli pro organizace poskytovatele`,
  [`Edit property`]: `Upravit vlastnost`,
  [`Edit provider organization`]: `Upravit organizaci poskytovatele`,
  [`Edit refresh token TTL`]: `Upravit TTL aktualizačního tokenu`,
  [`Edit reset password TTL`]: `Upravit TTL pro resetování hesla`,
  [`Edit role`]: `Upravit roli`,
  [`Edit role default for Provider Organizations`]: `Upravit výchozí roli pro organizace poskytovatele`,
  [`Edit role for Provider Organizations`]: `Upravit roli pro organizace poskytovatele`,
  [`Edit Roles`]: `Upravit role`,
  [`Edit Schema in JSON/XML`]: `Upravit schéma v souboru JSON/XML`,
  [`Edit security definition`]: `Upravit definici zabezpečení`,
  [`Edit sender`]: `Upravit odesílatele`,
  [`Edit sender & email server`]: `Upravit odesílatele a e-mailový server`,
  [`Edit sender Info`]: `Upravit informace o odesílateli`,
  [`Edit service visibility`]: `Upravit viditelnost služby`,
  [`Edit show/hide settings`]: `Upravit zobrazit/skrýt nastavení`,
  [`Edit space details`]: `Upravit podrobnosti prostoru`,
  [`Edit TLS client profile`]: `Upravit profil klienta TLS`,
  [`Edit TLS client profile visibility`]: `Upravit viditelnost profilu klienta TLS`,
  [`Edit TLS server profile`]: `Upravit profil serveru TLS`,
  [`Edit third party OAuth provider`]: `Upravit poskytovatele OAuth třetí strany`,
  [`Edit truststore`]: `Upravit úložiště údajů o důvěryhodnosti`,
  [`Edit unenforced API`]: `Upravit nevynucené rozhraní API`,
  [`Edit user registries`]: `Upravit registry uživatelů`,
  [`Edit user registry`]: `Upravit registr uživatelů`,
  [`Edit user registry visibility`]: `Upravit viditelnost registru uživatelů`,
  [`Edit user registry for API Manager`]: `Upravit registr uživatelů pro API Manager`,
  [`Edit user registry for Cloud Manager`]: `Upravit registr uživatelů pro Cloud Manager`,
  [`Edit vanity API endpoints`]: `Upravit jednoduché koncové body rozhraní API`,
  [`Edit visibility`]: `Upravit viditelnost`,
  [`Edit XML`]: `Upravit XML`,
  [`Edit app lifecycle approved`]: `Upravit schválení životního cyklu aplikace`,
  [`Edit app lifecycle cancelled`]: `Upravit zrušení životního cyklu aplikace`,
  [`Edit app lifecycle denied`]: `Upravit odepření životního cyklu aplikace`,
  [`Edit app lifecycle pending`]: `Upravit nevyřízený životní cyklus aplikace`,
  [`Edit app lifecycle request`]: `Upravit požadavek životního cyklu aplikace`,
  [`Edit app reinstated`]: `Upravit obnovu aplikace`,
  [`Edit app suspended`]: `Upravit pozastavení platnosti aplikace`,
  [`Edit audit setting`]: `Upravit nastavení auditu`,
  [`Edit definitions`]: `Upravit definice`,
  [`Enable external group mapping`]: `Povolit mapování externích skupin`,
  [`Edit extra constraints for the GraphQL schema`]: `Upravit další omezení pro schéma GraphQL`,
  [`Edit GraphQL schema|title`]: `Upravit schéma GraphQL`,
  [`Edit inline schema`]: `Upravit vložené schéma`,
  [`Edit inputs`]: `Upravit vstupy`,
  [`Edit invitation`]: `Upravit pozvání`,
  [`Edit mail server test connection`]: `Upravit test připojení poštovního serveru`,
  [`Edit member invitation`]: `Upravit pozvánku člena`,
  [`Edit notification templates`]: `Upravit šablony oznámení`,
  [`Edit notification templates:`]: `Upravit šablony oznámení:`,
  [`Edit operation list`]: `Upravit seznam operací`,
  [`Edit or upload JSON schema`]: `Upravit nebo odeslat schéma JSON`,
  [`Edit outputs`]: `Upravit výstupy`,
  [`Edit password changed`]: `Upravit změnu hesla`,
  [`Edit password reset`]: `Upravit resetování hesla`,
  [`Edit plan APIs`]: `Upravit rozhraní API plánů`,
  [`Edit schema`]: `Upravit schéma`,
  [`Edit schema|button`]: `Upravit schéma`,
  [`Edit schema|title`]: `Upravit schéma`,
  [`Edit settings for roles, notifications and more.`]: `Upravit nastavení pro role a oznámení atd.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Upravit nastavení pro registry uživatelů, role, koncové body a další.`,
  [`Edit sign up`]: `Upravit registraci`,
  [`Edit task product lifecycle approved`]: `Upravit schválení úlohy životního cyklu produktu`,
  [`Edit task product lifecycle cancelled`]: `Upravit zrušení úlohy životního cyklu produktu`,
  [`Edit task product lifecycle denied`]: `Upravit odepření úlohy životního cyklu produktu`,
  [`Edit task product lifecycle pending`]: `Upravit nevyřízenou úlohu životního cyklu produktu`,
  [`Edit task product lifecycle request`]: `Upravit požadavek úlohy životního cyklu produktu`,
  [`Edit task subscription approved`]: `Upravit schválení odběru úlohy`,
  [`Edit task subscription cancelled`]: `Upravit zrušení odběru úlohy`,
  [`Edit task subscription denied`]: `Upravit odepření odběru úlohy`,
  [`Edit task subscription pending`]: `Upravit nevyřízený odběr úlohy`,
  [`Edit task subscription request`]: `Upravit požadavek odběru úlohy`,
  [`Edit template`]: `Upravit šablonu`,
  [`Edit the TLS client profile.`]: `Upravte profil klienta TLS.`,
  [`Edit the TLS Server profiles`]: `Upravit profily serveru TLS`,
  [`Edit the keystore title.`]: `Upravte název úložiště klíčů.`,
  [`Edit the organization summary details as required`]: `Podle potřeby upravte souhrnné podrobnosti o organizaci.`,
  [`Edit the organization title`]: `Upravte název organizace.`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Upravte parametry ke konfiguraci e-mailového serveru. Pole jsou povinná, pokud nejsou označena jako volitelná.`,
  [`Edit signed token`]: `Upravit podepsaný token`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Upravte text pro šablonu e-mailu. Proměnné jsou obsaženy ve složených závorkách, a nelze je upravovat.`,
  [`Edit the truststore.`]: `Upravte úložiště údajů o důvěryhodnosti.`,
  [`Edit the visibility for each resource.`]: `Upravte viditelnost pro každý prostředek.`,
  [`Edit user details`]: `Upravit podrobnosti o uživateli`,
  [`Edit {notificationTemplate}`]: `Upravit {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `Upravit, sestavit, zabezpečit a testovat rozhraní API`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `Upravit, sestavit, zabezpečit a testovat rozhraní API. Zabalit rozhraní API pomocí produktů pro publikování odběratelům.`,
  [`Edit, upload, or infer XML schema`]: `Upravit, odeslat nebo odvodit schéma XML`,
  [`Editors`]: `Editoři`,
  [`Edit|permission`]: `Upravit`,
  [`Edit API key timeout`]: `Upravit časový limit klíče rozhraní API`,
  [`Edit Base64 encoding for temporary tokens`]: `Upravit kódování ve formátu Base64 pro dočasné tokeny`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Musí být zadán buď "Kryptografický objekt pro podpis tokenu ID", nebo "Podpisový klíč tokenu ID".`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Musí být zadána buď položka "kryptografický objekt ověření JWT", nebo položka "JWK ověření JWT".`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Buď vyberte volbu pro použití koncového bodu, který je definován v rozhraní API, nebo poskytněte výchozí koncový bod validátoru.`,
  [`Element name for JSON array elements`]: `Název prvku pro prvky pole JSON`,
  [`Email`]: `E-mail`,
  [`Email address`]: `E-mailová adresa`,
  [`Email Content`]: `Obsah e-mailu`,
  [`Email endpoint`]: `Koncový bod e-mailu`,
  [`Email required`]: `Je vyžadován e-mail`,
  [`Email sender`]: `Odesilatel e-mailu`,
  [`Email server`]: `E-mailový server`,
  [`Email server configuration`]: `Konfigurace e-mailového serveru`,
  [`Email servers`]: `E-mailové servery`,
  [`Empty case`]: `Prázdný případ`,
  [`Empty catch`]: `Prázdná zachycená položka`,
  [`Empty JSON array handling`]: `Zpracování prázdného pole JSON`,
  [`Empty XML element handling`]: `Zpracování prázdného prvku XML`,
  [`Enable`]: `Povolit`,
  [`Enable API consumers to invite collaborators and assign roles`]: `Povolit zákazníkům rozhraní API pozvat spolupracovníky a přiřadit role.`,
  [`Enable application lifecycle`]: `Povolit životní cyklus aplikace`,
  [`Enable billing`]: `Povolit platební systém`,
  [`Enable gateway services`]: `Povolit služby brány`,
  [`Enable gateway services and policies`]: `Povolit služby brány a zásady`,
  [`Enable GraphiQL`]: `Povolit GraphishQL`,
  [`Enable GraphiQL editor`]: `Povolit editor GrahiQL`,
  [`Enable GraphiQL Editor on browser`]: `Povolit editor GrahiQL v prohlížeči`,
  [`Enable JSON post processing`]: `Povolit následné zpracování JSON`,
  [`Enable lifecycle approvals`]: `Povolit schválení životního cyklu`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Povolit rozšíření NONCE, aby se zabránilo opětovnému použití ohrožených požadavků (přehráno)`,
  [`Enable OAuth providers`]: `Povolit poskytovatele OAuth`,
  [`Enable OIDC`]: `Povolit OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Chcete-li generovat tokeny ID, povolte šablonu OpenID Connect.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Povolit rozšíření PKCE, aby se pro veřejné klienty zmírnila hrozba zachycení autorizačního kódu`,
  [`Enable production mode`]: `Povolit produkční režim`,
  [`Enable Proxy`]: `Povolit server proxy`,
  [`Enable Self-Service Onboarding`]: `Povolit samoobslužné zahrnutí`,
  [`Enable spaces`]: `Povolit prostory`,
  [`Enable token management`]: `Povolit správu tokenů`,
  [`Enable buffering`]: `Povolit ukládání do vyrovnávací paměti`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `Povolte odběratelům rozhraní API vytvářet účty pro vývojový portál, aniž by bylo nutné pozvání.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `Povolit řízení přístupu sdílení CORS (cross-origin resource) pro rozhraní API.`,
  [`Enable debug response headers`]: `Povolit záhlaví odezvy ladění`,
  [`Enable proof key for code exchange`]: `Povolit PKCE (Proof Key for Code Exchange)`,
  [`Enable publishing of this product`]: `Povolit publikování tohoto produktu`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Povolit přesměrování autorizačního kódu třetí strany prostřednictvím koncového bodu OIDC portálu`,
  [`Enable security`]: `Povolit zabezpečení`,
  [`Enable Self-Service Onboarding Approval`]: `Povolit schválení samoobslužného zahrnutí`,
  [`Enable the following gateway services and policies for this catalog`]: `Povolit následující služby brány a zásady pro tento katalog`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Povolte uživatelům konfiguraci události auditu. Událost auditu je podrobný souhrn toho, kdo provedl jakou událost a kdy.`,
  [`Enable/disable custom notification templates`]: `Povolit/zakázat vlastní šablony oznámení`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Povolte nebo zakažte kódování ve formátu Base64 pro tokeny pozvánek a tokeny resetování hesel.`,
  [`Enable overriding consumer organizations invitation timeout`]: `Povolit přepis časového limitu pozvánky do zákaznické organizace`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `Můžete povolit nebo zakázat kódování ve formátu Base64 pro dočasné tokeny. Dočasné tokeny jsou pozvánky a tokeny resetování hesel. Je-li kódování ve formátu Base64 povoleno, je dočasný token zakódován ve formátu Base64. Je-li kódování ve formátu Base64 zakázáno, zůstane dočasný token ve formátu JWT (JSON Web Token) a znamená to, že se délka adresy URL, kterou uživatel obdrží, zkrátí.`,
  [`Enabled`]: `Povoleno`,
  [`enabled`]: `povoleno`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `Povolení životního cyklu aplikace umožní aplikacím být ve vývojovém nebo produkčním stavu a mohou být směrovány na různé brány a koncové body.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Povolení schválení samoobslužného zahrnutí bude vyžadovat schválení všech požadavků na zahrnutí, které mají být schváleny poskytovatelem rozhraní API nebo administrátorem.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Povolení samoobslužného zahrnutí umožňuje vývojáři aplikací přihlásit se bez pozvánky od poskytovatele rozhraní API, existujícího vlastníka nebo administrátora zákaznické organizace.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `Povolení nastaví časový limit všech pozvánek do zákaznické organizace na časový limit pozvánky do katalogu.`,
  [`Encode Query params`]: `Parametry dotazu na kódování`,
  [`Encrypt`]: `Šifrovat`,
  [`Encrypt Crypto Object`]: `Kryptografický objekt pro šifrování`,
  [`Encrypt JWK variable name`]: `Šifrovat název proměnné JWK`,
  [`Encrypt a buffer using the specified certificate`]: `Zašifrovat vyrovnávací paměť pomocí uvedeného certifikátu`,
  [`Encryption Algorithm`]: `Šifrovací algoritmus`,
  [`Endpoint`]: `Koncový bod`,
  [`Endpoint URLs`]: `Adresy URL koncových bodů`,
  [`Endpoint behavior`]: `Chování koncového bodu`,
  [`Endpoint Behavior`]: `Chování koncového bodu`,
  [`Endpoint for unenforced APIs`]: `Koncový bod pro nevynucená rozhraní API`,
  [`Endpoints`]: `Koncové body`,
  [`Enforce Required Params`]: `Vynutit povinné parametry`,
  [`Enforce the API by using API Connect Gateway.`]: `Vynutit rozhraní API s použitím služby API Connect Gateway.`,
  [`Enforced`]: `Vynuceno`,
  [`English`]: `Angličtina`,
  [`Enter Summary`]: `Zadat souhrn`,
  [`Enter URL`]: `Zadat adresu URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Zadejte název registru uživatelů. Je-li prázdný, standardně se použije lokální registr uživatelů nástroje API Manager`,
  [`Enter a catalog name`]: `Zadat název katalogu`,
  [`Enter a Display Name`]: `Zadat zobrazovaný název`,
  [`Enter a space name`]: `Zadat název prostoru`,
  [`Enter a brief description`]: `Zadat stručný popis`,
  [`Enter a display name`]: `Zadat zobrazovaný název`,
  [`Enter a temporary sign in password`]: `Zadejte heslo pro dočasné přihlášení`,
  [`Enter an Organization Name`]: `Zadejte název organizace`,
  [`Enter an Organization Title`]: `Zadejte název organizace`,
  [`Enter catalog title`]: `Zadejte název katalogu`,
  [`Enter details of the consumer organization`]: `Zadejte podrobnosti zákaznické organizace.`,
  [`Enter details of the product`]: `Zadat podrobnosti produktu`,
  [`Enter details of the provider organization`]: `Zadejte podrobnosti organizace poskytovatele.`,
  [`Enter details of this API`]: `Zadejte podrobnosti tohoto rozhraní API.`,
  [`Enter email of the new user`]: `Zadejte e-mail nového uživatele`,
  [`Enter first name of the new user`]: `Zadejte křestní jméno nového uživatele`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Zadejte jednu nebo více e-mailových adres. Více adres oddělujte čárkami.`,
  [`Enter organization name`]: `Zadejte název organizace`,
  [`Enter organization title`]: `Zadejte název organizace`,
  [`Enter password`]: `Zadat heslo`,
  [`Enter space title`]: `Zadejte název prostoru`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Zadejte koncový bod rozhraní API pro bránu zadáním názvu hostitele a čísla portu. Například: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Zadejte souhrnné podrobnosti o rozhraní API.`,
  [`Enter the API summary details. [Learn more]({link})`]: `Zadejte souhrnné podrobnosti o rozhraní API. [Další informace]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Zadejte adresu URL cílové služby požadované pro server proxy.`,
  [`Enter the catalog summary details`]: `Zadat souhrnné podrobnosti o katalogu`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Zadejte souhrnné podrobnosti o katalogu. Po jeho vytvoření  můžete katalog zcela nakonfigurovat.`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Zadejte e-mailovou adresu uživatele, kterého chcete pozvat jako člena administrátorské organizace.`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Zadejte e-mailovou adresu uživatele, kterého chcete pozvat jako člena katalogu.`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Zadejte e-mailovou adresu uživatele, kterého chcete pozvat jako člena organizace poskytovatele.`,
  [`Enter the email address of the user to invite as a member of the space`]: `Zadat e-mailovou adresu uživatele, kterého chcete pozvat jako člena prostoru`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Zadejte e-mailovou adresu uživatele, na kterého chcete převést vlastnictví.`,
  [`Enter the full path to JSON or YAML file`]: `Zadejte úplnou cestu k souboru JSON nebo YAML`,
  [`Enter the full path to WSDL file`]: `Zadejte úplnou cestu k souboru WSDL`,
  [`Enter the full path to YAML file`]: `Zadejte úplnou cestu k souboru YAML`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Zadejte úplný název domény pro koncový bod příjmu analýz, který jste nadefinovali během instalace.`,
  [`Enter the last name of the new user`]: `Zadejte příjmení nového uživatele`,
  [`Enter the owner's email address to send the invitation`]: `Zadejte e-mailovou adresu vlastníka k odeslání pozvánky.`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Zadejte parametry pro konfiguraci služby brány. Pole jsou povinná, pokud nejsou označena jako volitelná. Koncový bod je koncový bod služby API Gateway na zařízení DataPower. Základ koncového bodu rozhraní API je koncový bod služby API Gateway pro příchozí volání API. V případě rozhraní SNI zadejte * pro povolení všech hostitelů (povinné) a dále názvy hostitelů pro povolení specifických hostitelů. Zvolte profil TLS pro podporu každého hostitele.`,
  [`Enter the password for the certificate file if required.`]: `V případě potřeby zadejte heslo pro soubor certifikátů.`,
  [`Enter the provider organization summary details`]: `Zadat souhrnné podrobnosti o organizaci poskytovatele`,
  [`Enter the schema definitiom summary details`]: `Zadejte souhrnné podrobnosti o definici schématu`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Zadejte souhrnné podrobnosti o prostoru. Po jeho vytvoření můžete prostor zcela nakonfigurovat.`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Zadejte souhrnné podrobnosti o prostoru. Po jeho vytvoření můžete prostor zcela nakonfigurovat.`,
  [`Enter the summary details for the new product`]: `Zadejte souhrnné podrobnosti pro nový produkt.`,
  [`Enter the summary details for this role`]: `Zadejte souhrnné podrobnosti pro tuto roli.`,
  [`Enter title of the organization`]: `Zadejte název organizace.`,
  [`Enter user email`]: `Zadejte e-mail uživatele.`,
  [`Enter username of the existing user`]: `Zadejte jméno existujícího uživatele.`,
  [`Enter username of the new user`]: `Zadejte jméno nového uživatele.`,
  [`Error`]: `Chyba`,
  [`Error ID`]: `ID chyby`,
  [`Error:`]: `Chyba:`,
  [`Copy error ID to Clipboard`]: `Kopírovat ID chyby do schránky`,
  [`Error Console`]: `Konzola chyby`,
  [`Error connecting`]: `Chyba při připojování`,
  [`Error connecting to GraphQL server`]: `Chyba při připojení k serveru GraphQL`,
  [`Error getting user info during login. Please login with different user.`]: `Chyba při získávání informací o uživateli během přihlášení. Přihlaste se prosím pomocí jiného uživatele.`,
  [`Error content`]: `Obsah chyby`,
  [`Error message`]: `Chybová zpráva`,
  [`Error name`]: `Název chyby`,
  [`Error status code`]: `Stavový kód chyby`,
  [`Error status code is invalid`]: `Stavový kód chyby je neplatný`,
  [`Error status reason`]: `Příčina chybového stavu`,
  [`Errors`]: `Chyby`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Chyby obvykle pocházejí z chybných informací nebo znaků. Před spuštěním testu se ujistěte, že jste poskytli správné informace o poskytovateli a o klientovi.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Chyba při pokusu o získání rozhraní API z adresy URL {url}. Zkontrolujte adresu URL, jméno uživatele a heslo. Chybová zpráva: {message}`,
  [`Event`]: `Událost`,
  [`Event gateway management client TLS client profile`]: `Profil klienta TLS klienta pro správu brány událostí`,
  [`Event load`]: `Načtení události`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Každá zákaznická organizace má vlastníka. Vlastník má úplná administrativní oprávnění a může spravovat odběry a aplikace.`,
  [`Exchange authorization code for tokens`]: `Vyměnit autorizační kód za tokeny`,
  [`Exchange code for token`]: `Vyměnit kód za token`,
  [`Execute`]: `Provést`,
  [`Execute migration target`]: `Provést cíl migrace`,
  [`Executes any catalog-level post-invoke handlers`]: `Provádí všechny obslužné rutiny po vyvolání na úrovni katalogu`,
  [`Executes any catalog-level pre-invoke handlers`]: `Provádí všechny obslužné rutiny před vyvoláním na úrovni katalogu`,
  [`Existing`]: `Existující uživatel`,
  [`Existing {docType}`]: `Existující {docType}`,
  [`Existing API Connect user`]: `Existující uživatel API Connect`,
  [`Existing OpenAPI`]: `Existující OpenAPI`,
  [`Existing product`]: `Existující produkt`,
  [`Existing SOAP API has been validated`]: `Existující rozhraní SOAP API bylo ověřeno`,
  [`Existing products in the catalog will be moved to a default space.`]: `Existující produkty v katalogu budou přesunuty do výchozího prostoru.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Existující uživatelé jsou aktuálními členy. Můžete také aktivovat nového člena z jiného registru uživatelů.`,
  [`Expires On`]: `Vypršení platnosti`,
  [`Expiry`]: `Vypršení`,
  [`Explorer`]: `Průzkumník`,
  [`Expose`]: `Vystavit`,
  [`Extend the schema to specify the cost of types and fields`]: `Rozšířit schéma o určení nákladů pro typy a pole`,
  [`Extended`]: `Rozšířené`,
  [`Extension Type:`]: `Typ rozšíření:`,
  [`Extensions`]: `Rozšíření`,
  [`Extension has been uploaded.`]: `Rozšíření bylo odesláno.`,
  [`External`]: `Externí`,
  [`External Doc Description`]: `Externí popis dokumentace`,
  [`External doc description`]: `Externí popis dokumentace`,
  [`External Documentation URL`]: `Adresa URL externí dokumentace`,
  [`External documentation URL`]: `Adresa URL externí dokumentace`,
  [`External Role mapping`]: `Mapování externích rolí`,
  [`External URL`]: `Externí adresa URL`,
  [`Extract Identity Settings`]: `Extrahovat nastavení identity`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `ID faktoru`,
  [`Fail`]: `Nezdar`,
  [`Failed to change account password.`]: `Změna hesla účtu se nezdařila.`,
  [`Failed to create analytics service {title}.`]: `Nezdařilo se vytvořit analytickou službu {title}.`,
  [`Failed to create analytics insights service {title}.`]: `Nezdařilo se vytvořit službu Analytics Insights {title}.`,
  [`Analytics insights service {title} has been updated.`]: `Služba Analytics Insights {title} byla aktualizována.`,
  [`About the analytics insights service`]: `O službě Analytics Insights`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Služba Analytics Insights shromažďuje události rozhraní API ze služby brány. Každá služba brány může mít přidruženou službu Analytics Insights.`,
  [`Advanced analytics insights configuration`]: `Rozšířená konfigurace služby Analytics Insights`,
  [`Analytics insights service {title} has been created.`]: `Služba Analytics Insights {title} byla vytvořena.`,
  [`Analytics Insights details`]: `Podrobnosti služby Analytics Insights`,
  [`Management endpoint on the analytics insights service`]: `Koncový bod správy na službě náhledů na analýzy`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Zadejte úplný název domény koncového bodu direktoru služby Analytics Insights, který jste definovali během instalace.`,
  [`TYPE`]: `TYPE`,
  [`Failed to create email server`]: `Nezdařilo se vytvořit e-mailový server`,
  [`Failed to create gateway service`]: `Nezdařilo se vytvořit službu brány`,
  [`Failed to create gateway service {name}.`]: `Nezdařilo se vytvořit službu brány {name}.`,
  [`Failed to create user registry {title}.`]: `Nezdařilo se vytvořit registr uživatelů {title}.`,
  [`Failed to delete a group`]: `Nezdařilo se odstranit skupinu.`,
  [`Failed to get TLS client profiles`]: `Nezdařilo se získat profily klienta TLS`,
  [`Failed to get catalogs`]: `Nezdařilo se získat katalogy`,
  [`Failed to get configured OAuth providers`]: `Nezdařilo se získat nakonfigurované poskytovatele OAuth`,
  [`Failed to get policies of gateways`]: `Nezdařilo se získat zásady bran`,
  [`Failed to get user registries`]: `Nezdařilo se získat registry uživatelů`,
  [`Failed to introspect schema from server:`]: `Nezdařilo se provést introspekci schématu ze serveru:`,
  [`Failed to make API online`]: `Nezdařilo se vytvořit rozhraní API online`,
  [`Failed to register catalog member {user}.`]: `Nezdařilo se zaregistrovat člena {user} katalogu.`,
  [`Failed to remove analytics service: {arg}`]: `Nezdařilo se odebrat analytickou službu: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Nezdařilo se odebrat zónu dostupnosti: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Nezdařilo se odebrat službu brány: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Nezdařilo se odebrat poštovní server: {arg}`,
  [`Failed to remove portal service: {arg}`]: `Nezdařilo se odebrat portálovou službu: {arg}`,
  [`Failed to create {name}.`]: `Nezdařilo se vytvořit {name}.`,
  [`Failed to sign out! Please check the console for details.`]: `Nezdařilo se odhlášení! Podrobnosti naleznete v konzole.`,
  [`Failed to update {name}.`]: `Nezdařilo se aktualizovat {name}.`,
  [`Failed to update OAuth provider`]: `Nezdařilo se aktualizovat poskytovatele OAuth`,
  [`Failed to update account.`]: `Nezdařilo se aktualizovat účet.`,
  [`Failed to update analytics service {title}.`]: `Nezdařilo se aktualizovat analytickou službu {title}.`,
  [`Failed to update gateway service {title}.`]: `Nezdařilo se aktualizovat službu brány {title}.`,
  [`Failed to update task`]: `Aktualizace úlohy se nezdařila`,
  [`Failed to create app {title}`]: `Nezdařilo se vytvořit aplikaci {title}`,
  [`Feature flags`]: `Příznaky funkce`,
  [`Fetch schema`]: `Načíst schéma`,
  [`Fetched endpoints`]: `Načtené koncové body`,
  [`Field`]: `Pole`,
  [`Field label`]: `Popisek pole`,
  [`Field Mapping`]: `Mapování polí`,
  [`Field name`]: `Název pole`,
  [`Field weight`]: `Váha pole`,
  [`File Name`]: `Název souboru`,
  [`File Preview`]: `Náhled souboru`,
  [`File contains validation errors`]: `Soubor obsahuje chyby ověření platnosti`,
  [`File is not a valid OpenAPI definition`]: `Soubor není platnou definicí OpenAPI`,
  [`File is not a valid extension format.`]: `Soubor není platný formát rozšíření.`,
  [`File is not a valid {fileType} format.`]: `Soubor není platný formát {fileType}.`,
  [`File name`]: `Název souboru`,
  [`File upload`]: `Odeslání souboru`,
  [`File uploaded is not a valid file`]: `Odeslaný soubor není platný soubor`,
  [`File uploaded successfully`]: `Soubor byl úspěšně odeslán`,
  [`File was updated and validated!`]: `Soubor byl aktualizován a ověřen!`,
  [`File was uploaded and validated!`]: `Soubor byl odeslán a ověřen!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `Soubor {draftFilePath} existuje. Zkuste změnit kombinaci názvu a verze konceptu`,
  [`Fill out the details of this service`]: `Vyplnit podrobnosti této služby`,
  [`Filter`]: `Filtrovat`,
  [`Filter by operation`]: `Filtrovat podle operace`,
  [`Filter policies`]: `Zásady filtru`,
  [`Final Action`]: `Konečná akce`,
  [`Find existing user`]: `Najít existujícího uživatele`,
  [`Find expert answers in the API Connect community forum`]: `Najít odpovědi odborníků ve fóru komunity služby API Connect`,
  [`Find expert answers in the API Connect community forum.`]: `Najít odpovědi odborníků ve fóru komunity služby API Connect.`,
  [`Find schema`]: `Najít schéma`,
  [`Finger Print`]: `Otisk prstu`,
  [`Fingerprint SHA-1`]: `Otisk prstu SHA-1`,
  [`Fingerprint SHA-256`]: `Otisk prstu SHA-256`,
  [`Finish`]: `Dokončit`,
  [`First name`]: `Křestní jméno`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Nejprve zadejte adresu URL koncového bodu, který brána používá pro provoz rozhraní API. Hodnota musí být veřejná, úplný název domény s dalšími cestami, které jsou specifické pro vaše volání API, například: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Nejprve zadejte adresu URL koncového bodu správy brány. U bran běžících v prostředích Kubernetes se hodnota musí shodovat s koncovým bodem zadaným pomocí sady příkazů pro nastavení brány gwy apic-gw-service. U zařízení brány musí být hodnota ve formě http://<ip-address-for-gateway>:3000. Když pracujete s klastrem brány, uveďte address:port prostředku pro vyrovnávání zátěže.`,
  [`Fit to screen`]: `Přizpůsobit obrazovce`,
  [`Flow`]: `Tok`,
  [`Follow redirects`]: `Sledovat přesměrování`,
  [`Found In`]: `Nachází se v`,
  [`Fonts contained in the following file are available under the`]: `Písma obsažená v následujícím souboru jsou k dispozici v části:`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `Pro definice WSDL odešlete soubor ZIP obsahující jak definici souboru WSDL, tak i definici rozhraní API (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Pro každý plán v původním produktu vyberte plán v nahrazovaném produktu, ke kterému chcete migrovat odběry`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Pro každý plán ve zdrojovém produktu vyberte plán v cílovém produktu, ke kterému chcete migrovat odběry`,
  [`For guidance on the available command set, access the CLI's help`]: `Pokyny k dostupné sadě příkazů naleznete v nápovědě k rozhraní CLI`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Pro organizační jednotky používající nativní poskytovatele OAuth volitelně zadejte sdílený tajný klíč, který budou používat všechna volání rozhraní API.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Pro zapisovatelný protokol LDAP vyberte volbu Spravovaný uživatel a přidejte mapování zdrojových názvů atributů registru LDAP do hodnot atributu API Connect LDAP. Výchozí vlastnosti profilu uživatele, které služba API Connect vyžaduje při registraci uživatele, jsou jméno uživatele, křestní jméno, příjmení, e-mail a heslo. Je poskytnuta ukázková konfigurace mapování - upravte mapování tak, aby odpovídalo vašim požadavkům`,
  [`Forbidden`]: `Zakázáno`,
  [`Forget token`]: `Zapomenout token`,
  [`Forgot password?`]: `Zapomněli jste heslo?`,
  [`Forgot password`]: `Zapomenuté heslo`,
  [`Form`]: `Formulář`,
  [`Forum`]: `Fórum`,
  [`French`]: `Francouzština`,
  [`From File`]: `Ze souboru`,
  [`From Sample JSON`]: `Z ukázkového souboru JSON`,
  [`From Sample XML`]: `Z ukázkového souboru XML`,
  [`From URL`]: `Z adresy URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Z existující služby GraphQL (server proxy GraphQL)`,
  [`From existing OpenAPI service`]: `Z existující služby OpenAPI`,
  [`From existing WSDL service (REST proxy)`]: `Z existující služby WSDL (server proxy REST)`,
  [`From existing WSDL service (SOAP proxy)`]: `Z existující služby WSDL (server proxy SOAP)`,
  [`From target service`]: `Z cílové služby`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `V operacích, které jsou definovány v definici WSDL pro službu SOAP, vyberte ty, které chcete použít ve svém rozhraní API, a uveďte metody a cesty URL.`,
  [`Gateway Extensions`]: `Rozšíření brány`,
  [`Gateway extension`]: `Rozšíření brány`,
  [`Gateway Services`]: `Služby brány`,
  [`Gateway Type`]: `Typ brány`,
  [`Gateway by Name`]: `Brána podle názvu`,
  [`Gateway services`]: `Služby brány`,
  [`Gateway type`]: `Typ brány`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `Licence k GPL v2`,
  [`GROUP`]: `SKUPINA`,
  [`Group name`]: `Název skupiny`,
  [`Gateway`]: `Brána`,
  [`Gateways`]: `Brány`,
  [`Gateway details`]: `Podrobnosti brány`,
  [`Gateway Details`]: `Podrobnosti brány`,
  [`Gateway Error`]: `Chyba brány`,
  [`Gateway service details`]: `Podrobnosti služby brány`,
  [`Gateway management client TLS client profile`]: `Profil klienta TLS klienta správy brány`,
  [`Gateway management client keystore`]: `Úložiště klíčů klienta správy brány`,
  [`Gateway management client truststore`]: `Úložiště údajů o důvěryhodnosti klienta správy brány`,
  [`Gateway processing status`]: `Stav zpracování brány`,
  [`Gateway policies (TODO)`]: `Zásady brány (TODO)`,
  [`Gateway service`]: `Služba brány`,
  [`Gateway Service US East (TODO)`]: `Služba brány, USA - východ (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Služba brány {arg} byla odebrána.`,
  [`Gateway service configuration`]: `Konfigurace služby brány`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `Hostitel služby brány není k dispozici. Test API (Vyzkoušet) v Exploreru nebude povoleno.`,
  [`Gateway service configuration has been updated.`]: `Konfigurace služby brány byla aktualizována.`,
  [`Gateway service {title} has been created.`]: `Služba brány {title} byla vytvořena.`,
  [`Gateway service {title} has been updated.`]: `Služba brány {title} byla aktualizována.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Služby brány jsou spravovány v každém prostoru, jsou-li prostory povoleny. Přejděte do nastavení prostoru pro správu služeb brány.`,
  [`Gateway version`]: `Verze brány`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Komunikační brány na stránku`,
  [`Gather-and-send`]: `Shromáždit a odeslat`,
  [`Gather-only`]: `Pouze shromáždit`,
  [`General configuration`]: `Obecná konfigurace`,
  [`General information about the API.`]: `Obecné informace o rozhraní API.`,
  [`Generate`]: `Generovat`,
  [`Generate an OpenAPI definition for the proxy`]: `Generovat definici OpenAPI pro server proxy`,
  [`Generate JSON Web Token (JWT)`]: `Generovat webový token JSON (JWT)`,
  [`Generate JWT`]: `Generovat JWT`,
  [`Generate LTPA Token`]: `Generovat token LTPA`,
  [`Generate OAuth Access Tokens`]: `Generovat přístupové tokeny OAuth`,
  [`Generate OAuth access tokens`]: `Generovat přístupové tokeny OAuth`,
  [`Generate a default product`]: `Generovat výchozí produkt`,
  [`Generate access token`]: `Generovat přístupový token`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Vygenerujte token LTPA tak, aby vaše rozhraní API mohlo bezpečně ověřit totožnost s webovými servery WebSphere Application Server.`,
  [`Generate an object`]: `Generovat objekt`,
  [`Generate authorization code`]: `Generovat autorizační kód`,
  [`Generate from sample JSON`]: `Generovat z ukázky JSON`,
  [`Generate from sample XML`]: `Generovat z ukázky XML`,
  [`Generate tests automatically to build high quality APIs`]: `Automaticky generovat testy pro sestavení velmi kvalitních rozhraní API`,
  [`Generated OpenAPI 2.0 definition`]: `Generovaná definice rozhraní OpenAPI 2.0`,
  [`Generated default product for the API`]: `Generovaný výchozí produkt pro rozhraní API`,
  [`Generated name for use in commands and API calls`]: `Generovaný název pro použití v příkazech a voláních rozhraní API`,
  [`German`]: `Němčina`,
  [`Get Base DN`]: `Získat základní DN`,
  [`Get Code`]: `Získat kód`,
  [`Get Code Snippet`]: `Získat úsek kódu`,
  [`Get Support`]: `Získat podporu`,
  [`Get Token`]: `Získat token`,
  [`Get activation link`]: `Získat aktivační odkaz`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Začněte registrací služby brány. To vám umožní testovat koncové body při konfiguraci jiných služeb. Jakmile zaregistrujete službu, uvidíte ji zde. [Další informace]({link})`,
  [`Get subscription ID`]: `Získat ID odběru`,
  [`Getting Started`]: `Začínáme`,
  [`Getting started`]: `Začínáme`,
  [`Git information`]: `Informace Git`,
  [`Github`]: `Github`,
  [`Global policies`]: `Globální zásady`,
  [`Go to Spaces`]: `Přejít na prostory`,
  [`Go to the Test tab`]: `Přejít na kartu Test`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `Limity četnosti GraphQL`,
  [`GraphQL service`]: `Služba GraphQL`,
  [`GraphQL schema`]: `Schéma GraphQL`,
  [`GraphQL Schema`]: `Schéma GraphQL`,
  [`GraphQL schema has been successfully validated.`]: `Schéma GraphQL bylo úspěšně ověřeno.`,
  [`GraphQL Schema URL`]: `Adresa URL schématu GraphQL`,
  [`GraphQL send type`]: `Typ odeslání GraphQL`,
  [`GraphQL Websocket Upgrade`]: `Upgrade webového soketu GraphQL`,
  [`Group Based DN`]: `DN založené na skupině`,
  [`Group {title} added`]: `Skupina {title} byla přidána`,
  [`Group {title} updated`]: `Skupina {title} byla aktualizována`,
  [`Group {title} has been deleted`]: `Skupina {title} byla odstraněna`,
  [`Groups`]: `Skupiny`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `NÁZEV HOSTITELE`,
  [`HOST URL`]: `Adresa URL hostitele`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `Formulář HTML`,
  [`HTML form time limit`]: `Časový limit formuláře HTML`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `Vyžadováno HTTP/2`,
  [`HTTP Header`]: `Záhlaví HTTP`,
  [`HTTP Method`]: `Metoda HTTP`,
  [`HTTP Type`]: `Typ HTTP`,
  [`HTTP Version`]: `Verze HTTP`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Úchyty`,
  [`Hard limit`]: `Pevný limit`,
  [`Header`]: `Záhlaví`,
  [`Header control`]: `Ovládací prvek záhlaví`,
  [`Header name payload`]: `Informační obsah názvu záhlaví`,
  [`Header name token`]: `Token názvu záhlaví`,
  [`Headers`]: `Záhlaví`,
  [`Hello`]: `Dobrý den`,
  [`Hello!`]: `Dobrý den,`,
  [`Hello [IBM](url)`]: `Dobrý den, [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Dobrý den, uživateli {name}.`,
  [`Hello {name}!`]: `Dobrý den, uživateli {name}.`,
  [`Hello {name}, [see IBM profile](url)`]: `Dobrý den, uživateli {name}, [viz profil IBM](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nděkujeme, že jste se přihlásil(a) k účtu v nástroji Developer Portal {catalogTitle}.\n\nChcete-li dokončit registraci, použijte tento odkaz:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nadministrátor pro nástroj Developer Portal {consumerOrg} znovu obnovil aplikaci\n{appName}. Volání API z této aplikace již nebude blokováno službou \nAPI Gateway.\n\nMůžete zobrazit podrobnosti o aplikaci pomocí tohoto odkazu: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nadministrátor pro nástroj Developer Portal {consumerOrg} pozastavil aplikaci\n{appName}. Volání API z této aplikace bude blokováno službou API Gateway.\n\nMůžete zobrazit podrobnosti o aplikaci pomocí tohoto odkazu: \n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nuživatel {originator} požádal o schválení přihlášení k odběru plánu {planName}\npro verzi {productVersion} produktu API {productName} v katalogu {catalog}\n.\n\nMůžete otevřít požadavek v seznamu úloh pomocí tohoto odkazu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nuživatel {originator} požádal o schválení přihlášení k odběru aplikace {appName}\npro plán {planName} verze {productVersion} produktu API {productName} \nv katalogu {catalog}.\n\nMůžete otevřít požadavek v seznamu úloh pomocí tohoto odkazu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nuživatel {originator} požádal o schválení akce {action} pro aplikaci {appName} v katalogu\n{catalog}.\n\nMůžete otevřít požadavek v seznamu úloh pomocí tohoto odkazu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nuživatel {originator} požádal o schválení akce {action} pro verzi {version}\nproduktu API {productName} v katalogu {catalog}.\n\nMůžete otevřít požadavek v seznamu úloh pomocí tohoto odkazu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Dobrý den, {{firstName}},\n\npřijali jsme požadavek na resetování hesla pro uživatelský účet\n{{username}} v nástroji Developer Portal {{catalog}}.\n\nPokud jste požadavek nezadali, můžete tuto zprávu ignorovat. Jinak \nmůžete resetovat heslo pomocí tohoto odkazu:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Dobrý den, {firstName},\n\npřijali jsme požadavek na resetování hesla pro uživatelský účet\n{username} ve službě IBM API Connect.\n\nChcete-li resetovat heslo, použijte tento odkaz:\n\n{link}\n\n(Pokud jste požadavek nezadali, můžete tuto zprávu ignorovat.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\npřijali jsme požadavek na resetování hesla pro uživatelský účet\n{username} v nástroji Developer Portal {catalog}.\n\nPokud jste požadavek nezadali, můžete tuto zprávu ignorovat. Jinak \nmůžete resetovat heslo pomocí tohoto odkazu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na vytvoření odběru pro aplikaci {appName} v katalogu \n{catalog} čeká na schválení. Po zpracování vašeho požadavku pošleme další \nupozornění.\n\nZde můžete zkontrolovat stav aplikace:\n\n{link}\n\nPokud budete kdykoli chtít požadavek zrušit, použijte tento odkaz:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Dobrý den, {{firstName}},\n\nváš požadavek na vytvoření odběru pro aplikaci {{appName}} k plánu\n{{planName}} pro produkt API {{productName}} verze\n{{productVersion}} byl schválen.\n\n{{#if comments}}\nSchvalovatel zahrnul následující komentáře:\n\n{{comments}}\n\n{{/if}}\nZde můžete zkontrolovat stav aplikace:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na vytvoření odběru pro aplikaci {appName} k plánu\n{planName} pro produkt API {productName} verze\n{productVersion} byl schválen.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav aplikace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Dobrý den, {{firstName}},\n\nváš požadavek na vytvoření odběru pro aplikaci {{appName}} k plánu\n{{planName}} pro produkt API {{productName}} verze\n{{productVersion}} byl zamítnut.\n\n{{#if comments}}\nSchvalovatel zahrnul následující komentáře:\n\n{{comments}}\n\n{{/if}}\nZde můžete zkontrolovat stav aplikace:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Dobrý den, {{firstName}},\n\nváš požadavek na provedení akce {{action}} pro produkt API {{productName}}:{{version}} v katalogu\n{{catalog}} byl schválen.\n\n{{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{{comments}}\n\n{{/if}}\nZde můžete zkontrolovat stav produktu:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro produkt API {productName}:{version} v katalogu\n{catalog} byl schválen.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav produktu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName}},\n\nváš požadavek na provedení akce {action}} pro produkt API {productName}:{version} v katalogu\n{catalog} byl zamítnut.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav produktu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro produkt API {productName}:{version} v katalogu\n{catalog} byl zamítnut.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav produktu:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro produkt API {productName}:{version} v katalogu\n{catalog} čeká na schválení. Po zpracování vašeho požadavku pošleme další \nupozornění.\n\nZde můžete zkontrolovat stav produktu:\n\n{link}\n\nPokud budete kdykoli chtít požadavek zrušit, použijte tento odkaz:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro aplikaci {appName} v nástroji Developer Portal\n{consumerOrg} byl schválen.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav aplikace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro aplikaci {appName} v nástroji Developer Portal\n{consumerOrg} byl schválen.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav aplikace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro aplikaci {appName} v nástroji Developer Portal\n{consumerOrg} byl zamítnut.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav aplikace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro aplikaci {appName} v nástroji Developer Portal\n{consumerOrg} byl zamítnut.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{/if}\nZde můžete zkontrolovat stav aplikace:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro aplikaci {appName} v nástroji Developer Portal {consumerOrg}\nčeká na schválení. Po zpracování vašeho požadavku pošleme další \nupozornění.\n\nPokud budete kdykoli chtít požadavek zrušit, použijte tento odkaz:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor účtů pro službu IBM API Connect byl vámi přizván k vytvoření \norganizace poskytovatele rozhraní API. Organizace poskytovatele umožňuje vám a vašemu \ntýmu vyvíjet rozhraní API a spravovat katalogy produktů API.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor pro katalog rozhraní API {catalog}, patřící do organizace poskytovatele rozhraní API\n{org} ve službě IBM API Connect, vás pozval\nk vytvoření prostoru v katalogu. Prostor umožňuje vám a vašemu týmu \nspravovat sadu produktů API v katalogu rozhraní API.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor pro katalog rozhraní API {catalog}, patřící do organizace poskytovatele rozhraní API\n{org} ve službě IBM API Connect, byl vámi přizván\ndo katalogu.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor pro nástroj Developer Portal {catalog} byl vámi přizván\nk vytvoření zákaznické organizace rozhraní API. Zákaznická organizace\numožňuje vám a vašemu týmu přistoupit k rozhraní API a zaregistrovat aplikace klienta.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor pro zákaznickou organizace rozhraní API {consumerOrg} v nástroji Developer Portal {catalog}\nvás pozval do organizace.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor pro organizaci poskytovatele rozhraní API {org} ve službě IBM API\nConnect byl vámi přizván k vytvoření katalogu rozhraní API. Katalog umožňuje vám\na vašemu týmu publikovat a spravovat kolekci produktů API.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor pro organizaci poskytovatele rozhraní API {org} ve službě IBM API\nConnect byl vámi přizván do organizace.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor pro prostor {space} v katalogu rozhraní API {catalog},\npatřící do organizace poskytovatele rozhraní API {org} ve službě IBM API Connect,\nbyl vámi přizván do prostoru.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Dobrý den, \n\nheslo pro uživatelský účet {username}\nve službě IBM API Connect bylo úspěšně změněno.\n\nPřihlásit se ke službě API Connect můžete zde:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Dobrý den, \n\nheslo pro uživatelský účet {username}\nv nástroji Developer Portal {portalTitle} bylo změněno.\n\nPřihlásit se do nástroje Developer Portal {portalTitle} můžete zde:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Dobrý den, \n\nadministrátor systému pro službu IBM API Connect vás pozval do organizace \n{org}.\n\nChcete-li pokračovat, použijte odkaz níže:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Dobrý den, \n\ntoto je testovací zpráva ze služby IBM API Connect z nakonfigurovaného poštovního serveru\n{mailServer}.\n\nPokud jste obdrželi tuto zprávu podle očekávání, test byl úspěšný!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Dobrý den, \n\nuživatel {originator} stáhl svůj požadavek na přihlášení k odběru plánu {planName} pro\nverzi {productVersion} produktu API {productName} v katalogu {catalog}.\n\nNení zapotřebí žádné další akce.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Dobrý den, \n\nUživatel {originator} stáhl svůj požadavek na {action} aplikace {appName} v katalogu {catalog}.\n\nNení zapotřebí žádné další akce.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Dobrý den, \n\nUživatel {originator} stáhl svůj požadavek na {action} verze {version} produktu API {productName}\nv katalogu {catalog}.\n\nNení zapotřebí žádné další akce.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Dobrý den, toto je testovací zpráva ze služby IBM API Connect z nakonfigurovaného poštovního serveru {{{mailServer}}}. Pokud jste obdrželi tuto zprávu podle očekávání, test byl úspěšný`,
  [`Hello|header`]: `Dobrý den`,
  [`Help`]: `Nápověda`,
  [`Hi, Steve (todo)`]: `Ahoj, Steve (Todo)`,
  [`Hide`]: `Skrýt`,
  [`Hide field`]: `Skrýt pole`,
  [`Hide in schema`]: `Skrýt ve schématu`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Skrýt nové typy, pole, direktivy nebo volitelné argumenty. Položky, které již nejsou back-endovým systémem podporovány, budou odebrány.`,
  [`High`]: `Vysoké`,
  [`History`]: `Historie`,
  [`Histories`]: `Historie`,
  [`History log`]: `Protokol historie`,
  [`Home`]: `Domovské umístění`,
  [`Host`]: `Hostitel`,
  [`Host context variable`]: `Kontextová proměnná hostitele`,
  [`Host name`]: `Název hostitele`,
  [`Host value (if present)`]: `Hodnota hostitele (pokud existuje)`,
  [`Hostname`]: `Název hostitele`,
  [`Hours`]: `Hodiny`,
  [`Http basic authentication schema`]: `Schéma základního ověření Http`,
  [`Hmm, your search didn't return any results.`]: `Hmm, vaše hledání nevrátilo žádné výsledky.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect Cloud`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `Ověření produktu IBM Cloud`,
  [`IBM Cloud Docs`]: `Dokumentace IBM Cloud`,
  [`IBM Schema`]: `Schéma IBM`,
  [`IBM managed`]: `Spravováno IBM`,
  [`ID Name`]: `Název ID`,
  [`ID token custom claims`]: `Vlastní nároky tokenu ID`,
  [`ID token issuer`]: `Vydavatel tokenu ID`,
  [`ID token signing algorithm`]: `Podpisový algoritmus tokenu ID`,
  [`ID token signing crypto object`]: `Kryptografický objekt pro podpis tokenu ID`,
  [`ID token signing key`]: `Podpisový klíč tokenu ID`,
  [`ID token signing key identifier`]: `Identifikátor podpisového klíče tokenu ID`,
  [`IDENTITY PROVIDER`]: `POSKYTOVATEL IDENTIT`,
  [`IDENTITY PROVIDER SUPPORT`]: `PODPORA POSKYTOVATELE IDENTIT`,
  [`Identification`]: `Identifikace`,
  [`Identity extraction`]: `Extrakce identity`,
  [`If`]: `Pokud`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Je-li produkční režim zakázán, znamená to, že se jedná o vývojový katalog, jsou vynuceny všechny operace publikování a všechny konflikty se vyřeší automaticky, což vám umožní například opakované opětovné publikování stejné verze produktu během testování.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Je-li povolen produkční režim, což znamená, že se jedná o produkční katalog, nebudete moci publikovat produkt do katalogu, pokud dojde ke konfliktům s produkty, které jsou již publikovány; před zopakováním operace publikování musíte vyřešit všechny konflikty.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Pokud jsou v katalogu povoleny prostory, můžete spravovat členy v rámci prostoru. Členství v prostoru spravujete přidáním nových uživatelů do prostoru a přiřazením rolí uživatelům.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Pokud je tato volba povolena, vstupní vlastnosti s hodnotou Null budou mapovány na výstupní dokument. Jinak budou tyto vlastnosti vynechány z výstupu.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Je-li povolen produkční režim, žádné akce fázování a publikování nebudou vynuceny. Pokud jsou nalezeny konflikty, budou systémem automaticky vyřešeny. Akce zrušení publikování proběhnou automaticky.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Pokud příjemci neobdrželi e-mail, zkontrolujte nastavení e-mailového serveru.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Jestliže příjemci neobdrželi e-mail, zkontrolujte nastavení e-mailového serveru pro uživatele {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Je-li hodnota nastavena na true, jsou všechny znaky + v hodnotách parametru dotazu cílové adresy URL zásad vyvolání a serveru proxy kódovány na hodnotu %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Je-li hodnota nastavena na true, jsou všechny parametry požadavku, na které odkazuje definice proměnné při vyvolání cílové adresy URL, dekódovány jako adresy URL.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Je-li hodnota nastavena na true, zásada vyvolání odešle informační obsah prostřednictvím metody HTTP DELETE.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `V případě nastavení na hodnotu true bude vlastnost redigována nebo odebrána z protokolů aktivit.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `V případě nastavení na hodnotu true, vlastnost bude redigována nebo odebrána ze vstupu na začátku provedení.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `V případě nastavení na hodnotu true bude vlastnost redigována nebo odebrána z odpovědi.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Pokud jsou prostory povoleny v katalogu, můžete spravovat přístup, který mají uživatelé v rámci prostoru. Přístup spravujete zadáním oprávnění, která jsou přiřazena k rolím uživatele. Oprávnění, která jsou standardně přiřazena každé roli při vytváření nového prostoru, jsou určena nastavením ve výchozí šabloně oprávnění prostoru.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Je-li políčko zaškrtnuto (výchozí volba), obory názvů XML jsou zděděny z nadřízeného prvku. Vymažte zaškrtávací políčko, pokud chcete, aby zásady mapování používaly explicitní obory názvů.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Je-li políčko zaškrtnuto (výchozí volba), obory názvů XML budou vloženy do dokumentu, kde jsou poprvé použity. Vymažte zaškrtávací políčko, pokud chcete, aby byly obory názvů definovány na kořenovém prvku.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Je-li políčko zaškrtnuto (výchozí volba), prázdné prvky XML se zahrnou do výstupu zásad mapování. Vymažte zaškrtávací políčko, pokud nechcete, aby byly prázdné prvky XML zahrnuty ve výstupu zásad mapování.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Je-li zaškrtávací políčko zaškrtnuto, je povoleno následné zpracování mapovaného výstupu JSON. Následné zpracování výstupu JSON bude používat výstupní schéma, aby se zajistilo, že hodnoty vlastností jsou stejného datového typu, jaký je definován ve schématu. Normalizuje také hodnoty vlastností výstupu, které mají syntaxi Badgerfish JSON kvůli mapování objektu vstupu XML. Zrušte zaškrtnutí zaškrtávacího políčka (výchozí) pro žádné následné zpracování mapovaného výstupu JSON.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Je-li políčko zaškrtnuto (výchozí volba), odkazy na proměnnou služby API Connect v rámci vlastností mapy budou přeloženy. Vymažte zaškrtávací políčko, pokud chcete, aby zásady mapování ignorovaly odkazy na proměnné služby API Connect v zásadách mapování.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Je-li políčko zaškrtnuto, odkazy na proměnnou služby API Connect v rámci vlastností mapy budou přeloženy. Vymažte zaškrtávací políčko (výchozí volba), pokud chcete, aby zásady mapování ignorovaly odkazy na proměnné služby API Connect v zásadách mapování.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Je-li políčko zaškrtnuto (výchozí volba), je-li v průchodu vstupu zjištěno pole, použije se pouze první prvek. Vymažte zaškrtávací políčko, pokud chcete, aby zásady mapování používaly všechny prvky pole.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Je-li zaškrtávací políčko označeno, prvky XML, jejichž schéma je nakonfigurováno jako logický typ nebo číselný typ, budou převedeny na daný datový typ. Není-li označeno (výchozí volba), všechny hodnoty prvků XML budou vráceny jako řetězec.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Je-li políčko zaškrtnuto, vyhodnocení komplexního typu schématu bude zpracovávat cyklické odkazy na typy optimalizovaným způsobem. Vymažte toto zaškrtávací políčko (výchozí volba), aby se vyhodnotily tyto typy schémat standardním způsobem.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Pokud je chyba způsobena nedostatkem podpory CORS, klepněte na odkaz a otevřete server na nové kartě. Pokud prohlížeč zobrazí problém s certifikátem, můžete jej přijmout a vrátit se sem, chcete-li test zopakovat.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Je-li toto zaškrtávací políčko vybráno, jakékoli výstupní pole nebo podřízená vlastnost volitelného objektu uvedeného ve schématu jako povinný bude mít povinné vlastnosti podřízeného objektu inicializované na výchozí hodnotu, pokud není namapování úspěšné. Tím se bude u těchto objektů emulovat způsob mapování prováděný pro povinné vlastnosti ve verzi 4. Zaškrtnutí tohoto zaškrtávacího políčka zrušte (výchozí hodnota), aby výstup pro tyto objekty byl pouze s úspěšně namapovanými daty.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Pokud je toto políčko zaškrtnuto (výchozí) a uživatel vlastníka se změní, vlastnictví všech podřízených prostředků (prostorů) se také převede na nového vlastníka.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Je-li tato volba vybrána, zásada mapování bude zapisovat výstup XML do souboru message.body jako analyzovaný dokument XML. Standardně bude XML výstupem jako řetězec XML. Výstup XML do libovolné jiné proměnné bude vždy zapsán jako řetězec XML.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Pokud povolíte schválení životního cyklu pro akce Fázovat, Publikovat nebo Zaměnit, pak některé operace vytvoření, úprav nebo testování rozhraní API, které provádějí tyto akce automaticky, nemusí fungovat správně. Opravdu chcete povolit některé z těchto schválení?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Máte-li existující službu SOAP, kterou chcete vystavit prostřednictvím definice API Connect, můžete vytvořit rozhraní API a určit cílovou službu SOAP. Služba API Connect potom vytvoří definici rozhraní API, která volá službu SOAP.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Pokud uložíte své změny klepnutím na tlačítko "Ano. Aktualizovat rozhraní API", bude aktualizováno také sestavení rozhraní API, které je základem tohoto poskytovatele OAuth. Pokud jste však upravili základní sestavení rozhraní API a chcete je aktualizovat samostatně, klepněte na tlačítko Ne. Neaktualizovat sestavení rozhraní API ", pak aktualizujte základní sestavení rozhraní API v editoru rozhraní API.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Pokud problémy přetrvávají, odešlete soubor jazyka definice schématu.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Pokud váš soubor obsahuje soukromé i veřejné klíče, odešlete jej v kroku 1`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Pokud potřebujete zaregistrovat bránu, která je umístěna za bránou firewall, prohlédněte si dokumentaci IBM Cloud Docs, kde získáte informace o používání sítě VPN nebo služby Secure Gateway k vytvoření připojení.`,
  [`Ignore`]: `Ignorovat`,
  [`Implement policy`]: `Implementovat zásadu`,
  [`Implementation file is required`]: `Soubor implementace je povinný`,
  [`Implicit`]: `Implicitní`,
  [`Import`]: `Importovat`,
  [`Import API`]: `Importovat rozhraní API`,
  [`Import API|title`]: `Importovat rozhraní API`,
  [`Import OpenAPI definition from asset repository`]: `Importovat definici OpenAPI z úložiště aktiv`,
  [`Import Product`]: `Importovat produkt`,
  [`Import policy`]: `Importovat zásadu`,
  [`Import a product from a service URL`]: `Importovat produkt z adresy URL služby`,
  [`Import a product from the definition file`]: `Importovat produkt z definičního souboru`,
  [`Import an existing product`]: `Importovat existující produkt`,
  [`Import an OpenAPI definition from a URL`]: `Importovat definici OpenAPI z adresy URL`,
  [`Import an OpenAPI definition from a file`]: `Importovat definici OpenAPI ze souboru`,
  [`Import definitions`]: `Importovat definice`,
  [`Import from existing API service`]: `Importovat z existující služby API`,
  [`Import from file`]: `Importovat ze souboru`,
  [`Importing an API definition`]: `Import definice API`,
  [`Import|title`]: `Importovat`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Naimportujte nezbytné certifikáty a balíky CA do služby správce certifikátů.`,
  [`In Cookie Header`]: `V záhlaví souboru cookie`,
  [`In WSSec Header`]: `V záhlaví WSSec`,
  [`In order to invoke the endpoints above, you need to first`]: `Chcete-li vyvolat výše uvedené koncové body, musíte nejprve`,
  [`Inactive`]: `Neaktivní`,
  [`Inc`]: `Zahrnout`,
  [`Include empty`]: `Zahrnout prázdné`,
  [`Include free trial days`]: `Zahrnout bezplatné dny zkušební verze`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Nelze vybrat nekompatibilní rozhraní API s konfigurovanou službou brány.`,
  [`Incorrect username or password`]: `Chybné jméno uživatele nebo heslo`,
  [`Incorrect username, password, or credentials`]: `Chybné jméno uživatele, heslo nebo pověření`,
  [`Indicate if the invoke is the final action`]: `Označit, když je vyvolání konečnou akcí`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Označuje, zda by měl být do JWT přidán nárok JWT ID (jti). Je-li vybráno, hodnota nároku jti bude UUID.`,
  [`Indicates whether the clients secret is required.`]: `Označuje, zda je požadován tajný klíč klienta.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Označuje, zda tento vstupní dokument XSLT používá aktuální informační obsah kontextu, nebo zda neexistuje žádný vstup.`,
  [`Indicates whether to stop processing if client security fails.`]: `Označuje, zda zastavit zpracování, pokud se zabezpečení klienta nezdaří.`,
  [`Infer from JSON schema`]: `Odvodit ze schématu JSON`,
  [`Infer from Sample`]: `Odvodit z ukázky`,
  [`Info`]: `Informace`,
  [`Information context variable`]: `Kontextová proměnná informací`,
  [`Inject proxy headers`]: `Vložit záhlaví serveru proxy`,
  [`Inline schema`]: `Vložené schéma`,
  [`Input`]: `Vstup`,
  [`Input field`]: `Vstupní pole`,
  [`Input object`]: `Vstupní objekt`,
  [`Insecure Server Connections`]: `Nezabezpečená připojení serveru`,
  [`Install API Connect CLI & API Designer|button text`]: `Instalovat nástroje API Connect CLI & API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `Instalovat nástroje API Connect CLI & API Designer`,
  [`Install API Connect Toolkit`]: `Nainstalovat sadu nástrojů API Connect`,
  [`Install the API Connect plugin`]: `Instalovat modul plug-in API Connect`,
  [`Instance ID`]: `ID instance`,
  [`Instance name`]: `Název instance`,
  [`Instance owner`]: `Vlastník instance`,
  [`Interact to expand Tile`]: `Zajistit interakci k rozbalení dlaždice`,
  [`Interact to collapse Tile`]: `Zajistit interakci ke sbalení dlaždice`,
  [`Interval`]: `Interval`,
  [`Interface`]: `Rozhraní`,
  [`Introspect`]: `Introspekce`,
  [`Introspect cache type`]: `Provést introspekci typu mezipaměti`,
  [`Introspect from Server URL`]: `Provést introspekci z adresy URL serveru`,
  [`Introspect from URL`]: `Provést introspekci z adresy URL`,
  [`Introspect URL`]: `Adresa URL introspekce`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Introspekce pomocí jiné adresy URL nezmění adresu URL serveru GraphQL.`,
  [`Introspect token`]: `Provést introspekci tokenu`,
  [`Introspection`]: `Introspekce`,
  [`Introspection Path`]: `Cesta k introspekci`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Provést introspekci schématu GraphQL ze serveru nebo odeslat schéma`,
  [`Introspect the GraphQL schema using the query`]: `Provést introspekci schématu GraphQL pomocí dotazu`,
  [`Introspection URL`]: `Adresa URL introspekce`,
  [`Invalid HOST URL`]: `Neplatná adresa HOST URL`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `Neplatná adresa URL. Zadejte platnou adresu HOST URL nástroje API Manager. Např. https://api-connect-host.com`,
  [`Invalid file URL`]: `Neplatná adresa URL souboru`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Neplatný formát URL, musí začínat na http:// nebo https:// a musí obsahovat alespoň 1 znak`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Neplatný formát adresy URL, je třeba zadat pouze název hostitele. Např. syslog:// lze odebrat`,
  [`Invalid emails.`]: `Neplatné e-maily.`,
  [`Invalid JSONata expression`]: `Neplatný výraz JSONata`,
  [`Invalid remove target`]: `Neplatný cíl odebrání`,
  [`Invalid assumed size`]: `Neplatná předpokládaná velikost`,
  [`Invalid cost target`]: `Neplatný cíl nákladů`,
  [`Invalid list size`]: `Neplatná velikost seznamu`,
  [`Invalid port value, must not be negative`]: `Neplatná hodnota portu, nesmí být záporná`,
  [`Invalid sized fields`]: `Neplatná pole vhodné velikosti`,
  [`Invalid value, length has to be greater than 1`]: `Neplatná hodnota, délka musí být větší než 1`,
  [`Invalid weight value`]: `Neplatná hodnota váhy`,
  [`invitation`]: `invitation`,
  [`Invitation`]: `Pozvánka`,
  [`Invitation timeout`]: `Časový limit pozvánky`,
  [`Invitation link will expire in {time}`]: `Platnost odkazu na pozvánku vyprší za **{time}**.`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Platnost odkazu na pozvánku vyprší za **{seconds} s ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Platnost odkazu na pozvánku vyprší za __{value} {unit}__.`,
  [`Invitation sent to`]: `Pozvánka odeslána pro`,
  [`Invitation sent to {email}`]: `Pozvánka odeslána na {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Pozvánka ke katalogu rozhraní API ve službě IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Pozvánka k zákaznické organizaci rozhraní API v nástroji Developer Portal {catalog}`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Pozvánka k organizaci poskytovatele rozhraní API ve službě IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Pozvánka k prostoru rozhraní API ve službě IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Pozvánka k administrátorské organizaci ve službě IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Pozvánka k vytvoření katalogu rozhraní API ve službě IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Pozvánka k vytvoření zákaznické organizace rozhraní API v nástroji Developer Portal {catalog}`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Pozvánka k vytvoření organizace poskytovatele rozhraní API ve službě IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Pozvánka k vytvoření prostoru rozhraní API ve službě IBM API Connect`,
  [`Invitation ttl has been created.`]: `Bylo vytvořeno pozvání ttl.`,
  [`Invitation ttl has been updated.`]: `Pozvánka ttl byla aktualizována.`,
  [`Invitation ttl has not been created!`]: `Nebylo vytvořeno pozvání ttl!`,
  [`Invitation ttl has not been updated!`]: `Pozvánka ttl nebyla aktualizována!`,
  [`Invite`]: `Pozvat`,
  [`Invite catalog owner`]: `Pozvat vlastníka katalogu`,
  [`Invite consumer organization owner`]: `Pozvat vlastníka zákaznické organizace`,
  [`Invite member`]: `Pozvat člena`,
  [`Invite organization owner`]: `Pozvat vlastníka organizace`,
  [`Invite space owner`]: `Pozvat vlastníka prostoru`,
  [`Invite a new consumer organization owner`]: `Pozvat nového vlastníka zákaznické organizace`,
  [`Invite a new member`]: `Pozvat nového člena`,
  [`Invite a new provider organization owner`]: `Pozvat nového vlastníka organizace poskytovatele`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Pozvěte nového uživatele, aby se registroval jako správce katalogu, zadáním e-mailové adresy příjemce. Uživateli je odeslán e-mail s pozvánkou s odkazem na formulář registrace`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Pozvěte nového uživatele, aby se registroval jako správce prostoru, zadáním e-mailové adresy příjemce. Uživateli je odeslán e-mail s pozvánkou s odkazem na formulář registrace.`,
  [`Invite by email`]: `Pozvat e-mailem`,
  [`Invite from User Registry`]: `Pozvat z registru uživatelů`,
  [`Invite team member`]: `Pozvat člena týmu`,
  [`Inviting members to consumer organizations is enabled`]: `Pozvání členů do organizací zákazníka je povoleno`,
  [`Invocations`]: `Vyvolání`,
  [`Invoke`]: `Vyvolat`,
  [`Issuer Claim`]: `Nárok vydavatele`,
  [`Issuer Origin`]: `Původ vydavatele`,
  [`Issuer common name`]: `Obecné jméno vydavatele`,
  [`It might influence how the API is deployed`]: `Může ovlivnit způsob implementace rozhraní API`,
  [`Italian`]: `Italština`,
  [`Items per page`]: `Počet položek na stránku`,
  [`Items per page:`]: `Počet položek na stránku:`,
  [`items selected`]: `vybraných položek`,
  [`item selected`]: `vybraná položka`,
  [`Iterate on`]: `Iterovat na`,
  [`Iterate?`]: `Iterovat?`,
  [`JSON objects describing re-usable channel parameters.`]: `Objekty JSON popisující znovu použitelné parametry kanálu.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `Objekty JSON popisující zprávy spotřebovávané a vytvářené rozhraním API.`,
  [`JSON Schema URL`]: `Adresa URL schématu JSON`,
  [`JSON Schema preview`]: `Náhled schématu JSON`,
  [`JSON Web Token (JWT)`]: `Webový token JSON (JWT)`,
  [`JSON has been successfully validated`]: `JSON byl úspěšně ověřen`,
  [`JSON schema is not valid. Please correct the error.`]: `Schéma JSON je neplatné. Odstraňte chybu.`,
  [`JSON to XML`]: `JSON na XML`,
  [`JWT ID Claim`]: `Nárok JWT ID`,
  [`JWT verification JWK`]: `JWK ověření JWT`,
  [`JWT verification crypto object`]: `Kryptografický objekt ověření JWT`,
  [`Japanese`]: `Japonština`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Fronta úloh`,
  [`Jump to source`]: `Přejít na zdroj`,
  [`Just now`]: `Právě nyní`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `VÝMĚNA KLÍČŮ/ŠIFROFÁNÍ/MAC`,
  [`KEY SIZE`]: `VELIKOST KLÍČE`,
  [`Keystore`]: `Úložiště klíčů`,
  [`keystore`]: `úložiště klíčů`,
  [`Keep`]: `Zachovat`,
  [`Keep Payload`]: `Uchovat informační obsah`,
  [`Key Encryption Algorithm`]: `Algoritmus šifrování klíče`,
  [`Key Name`]: `Název klíče`,
  [`Key type`]: `Typ klíče`,
  [`Key name`]: `Název klíče`,
  [`Keystore for the token has been updated.`]: `Úložiště klíčů pro token bylo aktualizováno.`,
  [`Keystore Certificates`]: `Certifikáty úložiště klíčů`,
  [`Keystore/truststore`]: `Úložiště klíčů/údajů o důvěryhodnosti`,
  [`Korean`]: `Korejština`,
  [`LABEL`]: `ŠTÍTEK`,
  [`LABELS`]: `ŠTÍTKY`,
  [`LAST MODIFIED`]: `POSLEDNÍ ZMĚNA`,
  [`Last state changed`]: `Poslední změna stavu`,
  [`Large`]: `Velké`,
  [`Lax`]: `Lax`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `Skupiny LDAP`,
  [`LDAP user registry`]: `Registr uživatelů LDAP`,
  [`LDAP registry`]: `Registr LDAP`,
  [`LDAP registry name`]: `Název registru LDAP`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Registr uživatelů LDAP role {{name}} již nepodporuje mapování externích skupin. Před uložením povolte mapování externích skupin pro tento registr uživatelů nebo zakažte mapování externích skupin v roli.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Registr uživatelů LDAP role {{name}} již nepodporuje mapování externích skupin. Před uložením vyberte jiný registr uživatelů s mapováním externích skupin nebo zakažte mapování externích skupin v roli.`,
  [`LDAP search attribute`]: `Vyhledávací atribut LDAP`,
  [`LOCATED IN`]: `UMÍSTĚNO V`,
  [`Located in`]: `Umístěno v`,
  [`LTPA Key`]: `Klíč LTPA`,
  [`LTPA Key Details`]: `Podrobnosti o klíči LTPA`,
  [`LTPA Keys`]: `Klíče LTPA`,
  [`LTPA key file`]: `Soubor s klíči LTPA`,
  [`LTPA key password`]: `Heslo klíče LTPA`,
  [`LUR data version`]: `Verze dat LUR`,
  [`LUR schema update date`]: `Datum aktualizace schématu LUR`,
  [`LUR schema version`]: `Verze schématu LUR`,
  [`LUR target data version`]: `Cílová verze dat LUR`,
  [`LUR target schema version`]: `Cílová verze schématu LUR`,
  [`LUR update date`]: `Datum aktualizace LUR`,
  [`Last name`]: `Příjmení`,
  [`Last modified`]: `Poslední změna`,
  [`Launch portal`]: `Spustit portál`,
  [`Launch IBM Automation management console`]: `Spustit konzolu správy automatizace IBM`,
  [`Launch plan upgrade`]: `Upgrade plánu spuštění`,
  [`Learn more`]: `Další informace`,
  [`Learn More`]: `Další informace`,
  [`Legacy`]: `Starší`,
  [`Length cannot be below {min}`]: `Délka nemůže být nižší než {min}`,
  [`Length cannot exceed {max}`]: `Délka nesmí překročit {max}`,
  [`Let's customize API Connect`]: `Přizpůsobení služby API Connect`,
  [`Let's get started...`]: `Začínáme...`,
  [`Let's get you up and running`]: `Začínáme s používáním`,
  [`License`]: `Licence`,
  [`Licensed Materials - Property of IBM`]: `Licencované materiály - vlastnictví IBM`,
  [`Lifecycle`]: `Životní cyklus`,
  [`Lifecycle approvals`]: `Schválení životního cyklu`,
  [`Lifecycle actions have been updated`]: `Akce životního cyklu byly aktualizovány`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Pro katalog sandboxů jsou povolena schválení životního cyklu. Chcete-li použít automatickou volbu Aktivovat API, musíte nejprve zakázat schválení životního cyklu v nastavení katalogu sandboxů.`,
  [`Limited fields`]: `Omezená pole`,
  [`Limit API calls on a per key basis`]: `Omezit volání API pro jednotlivé klíče`,
  [`Limit fields`]: `Omezit pole`,
  [`Limit renegotiation`]: `Omezit opětovné vyjednání`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Načíst`,
  [`Load query`]: `Načíst dotaz`,
  [`Load filter`]: `Načíst filtr`,
  [`Load schema from WSDL`]: `Načíst schéma z WSDL`,
  [`Loading`]: `Načítání`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Lokální registry uživatelů jsou výchozí registry uživatelů zahrnuté ve službě API Connect a jsou uloženy lokálně.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Lokální registry uživatelů jsou výchozí registry uživatelů zahrnuté ve službě API Connect a jsou uloženy lokálně. Během instalace služby API Connect jsou nainstalovány a konfigurovány dva lokální registry uživatelů. Mohou být konfigurovány další lokální registry uživatelů.`,
  [`Local user registry`]: `Lokální registr uživatelů`,
  [`Local User Registry has been created.`]: `Lokální registr uživatelů byl vytvořen.`,
  [`Location`]: `Umístění`,
  [`Location of ciphertext to decrypt`]: `Umístění šifrovaného textu pro dešifrování`,
  [`Location of plaintext information to encrypt`]: `Umístění informací ve formátu prostého textu, které mají být zašifrovány`,
  [`Log`]: `Protokol`,
  [`Log in`]: `Přihlásit se`,
  [`Log in to:`]: `Přihlásit se k:`,
  [`Log in with a different account`]: `Přihlásit se s jiným účtem`,
  [`Log into IBM Cloud`]: `Přihlásit se k produktu IBM Cloud`,
  [`Log out`]: `Odhlásit se`,
  [`Log in using the {userRegistryType} user registry`]: `Přihlásit se pomocí registru uživatelů {userRegistryType}`,
  [`Logging in with IBM Cloud ...`]: `Přihlášení se k produktu IBM Cloud...`,
  [`Logging out`]: `Odhlášení`,
  [`Logic`]: `Logika`,
  [`Login`]: `Přihlášení`,
  [`Logs`]: `Protokoly`,
  [`Longer, descriptive name for this policy.`]: `Delší, popisný název pro tuto zásadu.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Vypadá to, že během zpracování vašeho požadavku došlo k chybě serveru.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Poštovní server`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `ČLEN`,
  [`MIME Types`]: `Typy MIME`,
  [`Mail server has been deleted.`]: `Poštovní server byl odstraněn.`,
  [`Mail server has not been deleted.`]: `Poštovní server nebyl odstraněn.`,
  [`Mailserver {arg} has been removed.`]: `Poštovní server {arg} byl odebrán.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Ujistěte se, že je server spuštěný a že existuje síťová konektivita.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Příčiny zahrnují nedostatek podpory CORS na cílovém serveru, nedostupný server nebo zjištěný nedůvěryhodný certifikát nebo je požadováno vzájemné ověření SSL.`,
  [`This might be caused by one or more of the following:`]: `Může to být způsobeno minimálně jedním z následujících problémů:`,
  [`Make a call and move on`]: `Proveďte volání a přesun`,
  [`Make a call and wait for response`]: `Proveďte volání a vyčkejte na odezvu`,
  [`Manage`]: `Spravovat`,
  [`Manage API life cycles`]: `Spravovat životní cykly rozhraní API`,
  [`Manage API product lifecycle approval permissions`]: `Spravovat oprávnění ke schválení životního cyklu produktů API`,
  [`Manage API product lifecycle approvals permissions`]: `Spravovat oprávnění ke schválení životního cyklu produktů API`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Spravovat organizace poskytovatele rozhraní API, lokální a vzdálené brány a související nastavení.`,
  [`Manage APIs`]: `Spravovat rozhraní API`,
  [`Manage Catalogs`]: `Spravovat katalogy`,
  [`Manage Organizations`]: `Spravovat organizace`,
  [`Manage Resources`]: `Spravovat prostředky`,
  [`Manage Settings`]: `Spravovat nastavení`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Spravovat cíle pro analýzu rozhraní API, včetně služby API Connect, HTTP, Kafka, Elasticsearch a Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Spravovat  lokální a vzdálené brány zabezpečující rozhraní API.`,
  [`Manage catalogs`]: `Spravovat katalogy`,
  [`Manage organizations`]: `Spravovat organizace`,
  [`Manage resources`]: `Spravovat prostředky`,
  [`Manage settings`]: `Spravovat nastavení`,
  [`Manage users`]: `Spravovat uživatele`,
  [`Manage active APIs and consumers`]: `Spravovat aktivní rozhraní API a odběratele`,
  [`Manage availability zones and services`]: `Spravovat zóny dostupnosti a služby`,
  [`Manage policies`]: `Spravovat zásady`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Spravovat požadavky na změnu životního cyklu produktu a odběry rozhraní API a žádosti o upgradování odběru rozhraní API a aplikace od vývojářů aplikací`,
  [`Manage roles and permissions`]: `Spravovat role a oprávnění`,
  [`Manage the OAuth providers configured for API Manager`]: `Spravovat poskytovatele OAuth nakonfigurované pro nástroj API Manager`,
  [`Manage the user registries configured for API Manager`]: `Spravovat registry uživatelů konfigurované pro nástroj API Manager`,
  [`Manage the user registries configured for Cloud Manager`]: `Spravovat registry uživatelů konfigurované pro nástroj Cloud Manager`,
  [`Management`]: `Správa`,
  [`Management Endpoint`]: `Koncový bod správy`,
  [`Management endpoint`]: `Koncový bod správy`,
  [`Management endpoint on the analytics service`]: `Koncový bod správy v analytické službě`,
  [`Service endpoint configuration`]: `Konfigurace koncového bodu služby`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Koncový bod použitý službou správy k odeslání informací o konfiguraci do služby portálu`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Profil klienta TLS používaný službou správy při komunikaci se službou portálu`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Koncový bod použitý službou správy k odeslání informací o konfiguraci do služby brány`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `Profil klienta TLS používaný službou správy při komunikaci se službou brány`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Použít komunikaci v klastru pro interní provoz mezi bránou a službou správy`,
  [`Use in-cluster communication for both ingestion and queries`]: `Použít komunikaci v klastru pro příjem a dotazy`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Použít komunikaci v klastru pro příjem a externí komunikaci pro dotazy, nebo naopak`,
  [`Use external communication for both ingestion and queries`]: `Použít externí komunikaci pro příjem a dotazy`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Použít komunikaci v klastru pro interní provoz mezi analýzou a službou správy`,
  [`Management endpoint on the gateway service`]: `Koncový bod správy ve službě brány`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Použít komunikaci v klastru pro interní provoz mezi portálem a službou správy`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Chcete-li použít komunikaci v klastru mezi subsystémy správy a portálu, zapněte tento přepínač. Pokud si nejste jisti, ponechte tento přepínač vypnutý (výchozí nastavení).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Chcete-li použít komunikaci v klastru mezi subsystémy správy a brány, zapněte tento přepínač. Pokud si nejste jisti, ponechte tento přepínač vypnutý (výchozí nastavení).`,
  [`Management endpoint on the portal service`]: `Koncový bod správy v portálové službě`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Tato volba je výchozí a je použita pro analytické služby, které jsou upgradovány z verze před verzí 10.0.5.3. V případě implementací OVA, nebo je-li váš analytický subsystém v jiném prostředí, pak externí je jediná volba, kterou lze použít. Brána i služba správy komunikují se službou analýzy pomocí externího koncového bodu, který je vstupem Kubernetes nebo trasou OpenShift, v závislosti na vaší platformě.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `V klastru můžete vybrat, pokud máte implementaci Kubernetes nebo OpenShift, kde jsou všechny subsystémy ve stejném klastru. Je-li tato volba vybrána, komunikují spolu subsystémy správy, brány a analýzy prostřednictvím interních koncových bodů služeb namísto externě přístupných vstupů (Kubernetes) nebo tras (OpenShift).`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Tuto volbu můžete vybrat, pokud máte implementaci Kubernetes nebo OpenShift a máte některé subsystémy nainstalované ve stejném klastru. Je-li tato volba vybrána, můžete pro různé komunikační toky analýzy použít různé komunikační metody. Správa pro komunikaci analýzy může například používat v klastru, a brána pro analýzu může používat externí. Tuto konfiguraci můžete zvolit v případě, že se vaše brána nachází v jiném klastru, než zbytek vašich subsystémů.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Pokud jste při registrování analytické služby uvedli komunikaci v klastru, můžete bráně povolit odeslání dat události rozhraní API do interního koncového bodu analýzy.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Použít interní koncový bod pro příjem analytických dat ze služby brány`,
  [`Endpoint configuration`]: `Použít externí komunikaci pro příjem a dotazy`,
  [`Portal endpoint configuration`]: `Konfigurace koncového bodu portálu`,
  [`URL used by developer portal users to access the portal website`]: `Adresa URL používaná uživateli portálu vývojářů k přístupu na web portálu`,
  [`External ingestion endpoint on the analytics service`]: `Externí koncový bod příjmu na analytické službě`,
  [`TLS client profile for external analytics ingestion endpoint`]: `Profil klienta TLS pro koncový bod příjmu externí analýzy`,
  [`Internal ingestion endpoint on the analytics service`]: `Interní koncový bod příjmu na analytické službě`,
  [`TLS client profile for internal service endpoint`]: `Profil klienta TLS pro interní koncový bod služby`,
  [`Manager`]: `Vedoucí`,
  [`Manager Request Reset Password`]: `Požadavek vedoucího na resetování hesla`,
  [`Manager Reset Password`]: `Resetování hesla vedoucího`,
  [`Manager Sign In`]: `Přihlášení vedoucího`,
  [`Manages API provider organizations`]: `Spravuje organizace poskytovatele rozhraní API.`,
  [`Manages application developer communities`]: `Spravuje komunity vývojářů aplikací.`,
  [`Manages the API product lifecycle`]: `Spravuje životní cyklus produktu API.`,
  [`Manage|link`]: `Spravovat`,
  [`Manage|permission`]: `Spravovat`,
  [`Manage|title`]: `Spravovat: Název`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Spravovat přiřazení úložiště klíčů a historii úložiště klíčů pro tokeny`,
  [`Map`]: `Mapa`,
  [`Map information from OIDC provider to an APIC user`]: `Mapovat informace od poskytovatele OIDC na uživatele APIC`,
  [`Map the migration source plans to the migration target plans`]: `Mapovat plány zdroje migrace na plány cílů migrace`,
  [`Map the roles to groups`]: `Mapovat role na skupiny`,
  [`Mapped from: `]: `Mapovaný z: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Maximalizovat/minimalizovat`,
  [`Maximum Consent Time to Live (seconds)`]: `Maximální doba životnosti souhlasu (sekundy)`,
  [`Maximum consent`]: `Maximální souhlas`,
  [`Menu`]: `Nabídka`,
  [`Member`]: `Člen`,
  [`Member Invitation`]: `Pozvání člena`,
  [`Member Invitation has been deleted.`]: `Pozvánka člena byla odstraněna.`,
  [`Member has been deleted.`]: `Člen byl odstraněn.`,
  [`Member {name} created`]: `Byl vytvořen člen {name}`,
  [`Member {title} has been created.`]: `Byl vytvořen člen {title}.`,
  [`Members`]: `Členové`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Pozvánky člena`,
  [`Member Roles`]: `Role členů`,
  [`Merchant ID`]: `ID obchodníka`,
  [`Merge`]: `Sloučit`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Sloučit toto mapování s ostatními, kteří pracují ve stejném cílovém poli?`,
  [`Message`]: `Zpráva`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Zpráva pro analýzu`,
  [`Message for resulting parsed data`]: `Zpráva pro výsledná analyzovaná data`,
  [`Metadata`]: `Metadata`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Metadata vám umožňují shromáždit vlastní metadata, která mají být zahrnuta během procesu generování přístupového tokenu. Metadata můžete shromáždit pomocí ověřovací adresy URL, nebo vzdáleného serveru, kde jsou uložena vlastní metadata, nebo pomocí obojího.`,
  [`Micro Gateway policies`]: `Mikro zásady brány`,
  [`Migrate`]: `Migrovat`,
  [`Migrate Subscriptions`]: `Migrovat odběry`,
  [`Migrate plans`]: `Migrovat plány`,
  [`Migrate subscription`]: `Migrovat odběr`,
  [`Migrate subscriptions`]: `Migrovat odběry`,
  [`Migration Target`]: `Cíl migrace`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `Byla provedena migrace z "{source}" (Produkt) na "{target}" (Produkt)`,
  [`Migration from "{source}" failed`]: `Migrace z "{source}" se nezdařila`,
  [`Migration source`]: `Zdroj migrace`,
  [`Migration target`]: `Cíl migrace`,
  [`Minimum role`]: `Minimální role`,
  [`Minimum output escaping rule`]: `Minimální výstup pravidla změny významu`,
  [`Minutes`]: `Minuty`,
  [`Mode`]: `Režim`,
  [`Modifications`]: `Úpravy`,
  [`Modified`]: `Upraveno`,
  [`More`]: `Více`,
  [`More than 20 events are waiting to be processed`]: `Více než 20 událostí čeká na zpracování`,
  [`Move down`]: `Přesunout dolů`,
  [`Move up`]: `Přesunout nahoru`,
  [`Must be a number`]: `Musí se jednat o číslo.`,
  [`Must be a valid URI`]: `Musí se jednat o platný identifikátor URI.`,
  [`Must be a valid email`]: `Musí se jednat o platnou e-mailovou adresu.`,
  [`Must be a valid path starting with /`]: `Musí se jednat o platnou cestu začínající znakem /.`,
  [`Must be a valid url`]: `Musí se jednat o platnou adresu URL.`,
  [`Must be an integer`]: `Musí se jednat o celé číslo.`,
  [`Must be a valid url with no protocol`]: `Musí být platná adresa URL bez protokolu`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Musí být použit formát 64 hexadecimálních znaků (s předponou "0x").`,
  [`Must start with '/'`]: `Musí začínat znakem /.`,
  [`Must start with https://`]: `Musí začínat řetězcem https://.`,
  [`Must start with https:// or $(`]: `Musí začínat řetězcem https:// nebo $(`,
  [`Mutual auth`]: `Vzájemné ověření`,
  [`Mutual authentication`]: `Vzájemné ověření`,
  [`My account`]: `Můj účet`,
  [`Name`]: `Název`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NE`,
  [`Name cannot be empty.`]: `Název nesmí být prázdný.`,
  [`Name of Schema in OpenAPI Definitions`]: `Název schématu v definicích OpenAPI`,
  [`Name of the LDAP user password attribute.`]: `Název atributu hesla uživatele LDAP.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Název registru uživatelů LDAP, podle kterého se má provést ověření. Vyberte jej ze seznamu, nebo zadejte ručně.`,
  [`Name of the organization`]: `Název organizace`,
  [`Namespace inheritance`]: `Dědičnost oboru názvů`,
  [`Namespace inlining`]: `Vkládání oborů názvů`,
  [`Native`]: `Nativní`,
  [`Native OAuth provider`]: `Nativní poskytovatel OAuth`,
  [`Native OAuth provider summary`]: `Souhrn nativního poskytovatele OAuth`,
  [`Navigate to Source View`]: `Přejít do pohledu Zdroj`,
  [`New`]: `Nové`,
  [`New API`]: `Nové rozhraní API`,
  [`New API Connect connection`]: `Nové připojení ke službě API Connect`,
  [`New Application`]: `Nová aplikace`,
  [`New Catalog`]: `Nový katalog`,
  [`New Consumer organization`]: `Nová zákaznická organizace`,
  [`New Endpoint`]: `Nový koncový bod`,
  [`New native OAuth provider`]: `Nový nativní poskytovatel OAuth`,
  [`New OpenAPI`]: `Nové OpenAPI`,
  [`New OpenAPI directory`]: `Nový adresář OpenAPI`,
  [`New Organization Title`]: `Nový název organizace`,
  [`New Parameter`]: `Nový parametr`,
  [`New Password`]: `Nové heslo`,
  [`New product`]: `Nový produkt`,
  [`New Role`]: `Nová role`,
  [`New schema`]: `Nové schéma`,
  [`New Test`]: `Nový test`,
  [`New third party OAuth provider`]: `Nový poskytovatel OAuth třetí strany`,
  [`New user`]: `Nový uživatel`,
  [`New applications will be created in the development state`]: `Nové aplikace budou vytvořeny ve vývojovém stavu`,
  [`Next`]: `Další`,
  [`Next page`]: `Další stránka`,
  [`No`]: `Ne`,
  [`No account?`]: `Žádný účet?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `V katalogu sandboxu nejsou nakonfigurovány žádné ověřovací adresy URL nebo registry uživatelů LDAP`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `V katalogu sandboxu nejsou nakonfigurovány žádné ověřovací adresy URL nebo registry uživatelů LDAP, vytvořte adresu nebo registr [zde]({link}).`,
  [`No Billing`]: `Bez fakturace`,
  [`No billing integration has been selected.`]: `Nebyla vybrána žádná integrace s platebním systémem.`,
  [`No billing integration can be shown when offline.`]: `V režimu offline nelze zobrazit žádnou integraci účtování.`,
  [`No gateways`]: `Žádné brány`,
  [`No cache`]: `Žádná mezipaměť`,
  [`No Cache`]: `Žádná mezipaměť`,
  [`No Certificate Manager services available`]: `Nejsou k dispozici žádné služby správce certifikátů`,
  [`No default gateways`]: `Žádné výchozí brány`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Žádná duplicitní e-mailová adresa v registru uživatelů. Tato volba se vztahuje pouze na uživatele s e-mailem.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Nejsou nakonfigurovány žádné kompatibilní brány pro katalog sandboxu. Test API (Vyzkoušet) v Exploreru nebude povoleno.`,
  [`No Errors`]: `Bez chyb`,
  [`No Histories found`]: `Nebyla nalezena žádná historie`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Nebyly nalezeny žádné služby brány. Nakonfigurujte službu brány pro zobrazení podporovaných zásad sestavení`,
  [`No gateways are configured for sandbox catalog`]: `Nejsou nakonfigurovány žádné brány pro katalog sandboxu`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Nejsou nakonfigurovány žádné brány pro katalog sandboxu. Test rozhraní API (Try it) v Průzkumníku nebude povolen`,
  [`No LDAP or authentication URL user registries found.`]: `Nebyly nalezeny žádné registry uživatelů LDAP nebo ověřovací adresy URL.`,
  [`No limit on size of list`]: `Bez omezení velikosti seznamu`,
  [`No OAuth providers configured in the sandbox catalog`]: `V katalogu sandboxu nejsou nakonfigurováni žádní poskytovatelé OAuth`,
  [`No OAuth providers configured in the selected catalog`]: `Ve vybraném katalogu nejsou nakonfigurováni žádní poskytovatelé OAuth`,
  [`No Permission`]: `Bez oprávnění`,
  [`No portal added to this catalog`]: `Do tohoto katalogu nebyl přidán žádný portál`,
  [`No Roles exist`]: `Neexistují žádné role`,
  [`No recent items found.`]: `Nebyly nalezeny žádné nejnovější položky.`,
  [`No TLS profile`]: `Žádný profil TLS`,
  [`No TLS client profile`]: `Žádný profil klienta TLS`,
  [`No Truststore`]: `Bez úložiště údajů o důvěryhodnosti`,
  [`No analytics service is configured`]: `Není nakonfigurována žádná analytická služba`,
  [`No analytics services found. You can create one [here]({link})`]: `Nebyly nalezeny žádné analytické služby. Můžete je vytvořit [zde]({link})`,
  [`No assemblies found.`]: `Nebyla nalezena žádná sestavení.`,
  [`No available extensions`]: `Nejsou k dispozici žádná dostupná rozšíření`,
  [`No compatible flows configured in this OAuth provider`]: `V tomto poskytovateli OAuth nejsou nakonfigurovány žádné kompatibilní toky`,
  [`No consumer organization will be able to see this product.`]: `Žádná zákaznická organizace nebude moci si tento produkt zobrazit.`,
  [`No debug data was found for this API call`]: `Pro toto volání API nebyla nalezena žádná ladicí data`,
  [`No details are available.`]: `Žádné podrobnosti nejsou dostupné.`,
  [`No existing products`]: `Žádné existující produkty`,
  [`No extensions have been configured for the API`]: `Pro rozhraní API nebyla konfigurována žádná rozšíření.`,
  [`No flow configured in this OAuth provider`]: `V tomto poskytovateli OAuth není nakonfigurován žádný tok`,
  [`No items found`]: `Nenalezeny žádné položky`,
  [`No non-member user to add.`]: `Pro přidání není k dispozici žádný uživatel, který není členem.`,
  [`No organization found`]: `Nenalezena žádná organizace`,
  [`No portal service exists:`]: `Neexistuje žádná portálová služba:`,
  [`No product APIs`]: `Žádná rozhraní API produktu`,
  [`No products or plans were found.`]: `Nebyly nalezeny žádné produkty nebo plány.`,
  [`No provider organizations`]: `Žádné organizace poskytovatele`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Nebyla přijata žádná odezva. Příčiny zahrnují nedostatek podpory CORS na cílovém serveru, nedostupný server nebo zjištěný nedůvěryhodný certifikát nebo je požadováno vzájemné ověření SSL.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Nejsou k dispozici žádné skupiny prostředků. Chcete-li začít, vytvořte skupinu prostředků v prostředcích účtu IBM Cloud.`,
  [`No security`]: `Bez zabezpečení`,
  [`No services found`]: `Nebyly nalezeny žádné služby.`,
  [`No warnings`]: `Bez varování`,
  [`No warnings.`]: `Bez varování.`,
  [`No. Do not update API Assembly`]: `Ne. Neaktualizovat sestavení rozhraní API`,
  [`Non-blocking`]: `Neblokující`,
  [`Non int slicing arguments`]: `Argumenty ořezání jiné než Int`,
  [`None`]: `Není`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Žádný z nakonfigurovaných poskytovatelů OAuth neodpovídá typu brány tohoto rozhraní API.`,
  [`Normal`]: `Normální`,
  [`Not Available`]: `Není k dispozici`,
  [`Not applicable for application grant type.`]: `Nelze použít pro typ udělení aplikace.`,
  [`Not valid before`]: `Není platný před`,
  [`Not valid after`]: `Není platný po`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Poznámka: Tato volba je zakázána, pokud je vybrána volba ‚Generovat automatický produkt‘.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Poznámka: Tato volba je zakázána, je-li vybrána volba 'Generovat automatický produkt', a místo ní se použije výchozí plán pro vygenerovaný produkt.`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Pozn.: Předvolba služeb brány se použije jako rozsah pro všechny ostatní předvolby. Zásady a další specifický obsah služby brány budou načteny na základě tohoto nastavení.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Pozn.: Tato volba je určena pouze pro informační účely a je aktualizována na základě výše uvedené volby testovací aplikace.`,
  [`Notices`]: `Oznámení`,
  [`Notification template`]: `Šablona oznámení`,
  [`Notification templates`]: `Šablony oznámení`,
  [`Notification server configured`]: `Server oznámení byl nakonfigurován.`,
  [`Notifications`]: `Oznámení`,
  [`Null Badgerfish`]: `Badgerfish - Null`,
  [`Null`]: `Null`,
  [`Number`]: `Číslo`,
  [`Number of Operations`]: `Počet operací`,
  [`Numeric`]: `Číselné`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `Poskytovatel OAuth`,
  [`OAuth provider`]: `Poskytovatel OAuth`,
  [`OAuth Provider Policy Assembly`]: `Sestavení zásady poskytovatele OAuth`,
  [`OAuth providers`]: `Poskytovatelé OAuth`,
  [`OAuth Redirect URL`]: `Adresa URL pro přesměrování OAuth`,
  [`OAuth shared secret`]: `Sdílený tajný klíč OAuth`,
  [`OAuth provider policy assembly`]: `Sestavení zásady poskytovatele OAuth`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `Poskytovatel OAuth {currentProviderName} nebyl nalezen. Vyberte nakonfigurovaného poskytovatele OAuth.`,
  [`OAuth redirect URL`]: `Adresa URL pro přesměrování OAuth`,
  [`OAuth Shared Secret`]: `Sdílený tajný klíč OAuth`,
  [`OAuth Signature Method`]: `Metoda podpisu OAuth`,
  [`OAuth signature method`]: `Metoda podpisu OAuth`,
  [`OAuth providers can be created and managed in the following list.`]: `Poskytovatelé OAuth mohou být vytvořeni a spravováni v následujícím seznamu.`,
  [`OAuth symmetric key name`]: `Název symetrického klíče OAuth`,
  [`OAuth symmetric key value`]: `Hodnota symetrického klíče OAuth`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `Zabezpečení kódu přístupu OAuth2`,
  [`OAuth2 Application Security`]: `Zabezpečení aplikace OAuth2`,
  [`OAuth2 Implicit Security`]: `Implicitní zabezpečení OAuth2`,
  [`OAuth2 Password Security`]: `Zabezpečení heslem OAuth2`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `ID OPERACE`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `POŘADÍ`,
  [`ORGANIZATION`]: `ORGANIZACE`,
  [`ORGANIZATION MANAGER`]: `VEDOUCÍ ORGANIZACE`,
  [`Organizations`]: `Organizace`,
  [`Owner`]: `Vlastník`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Poskytovatelé Oauth jsou spravováni v každém prostoru, jsou-li prostory povoleny. Přejděte do nastavení prostoru pro správu poskytovatelů oauth.`,
  [`Off`]: `Vypnuto`,
  [`Offline`]: `Offline`,
  [`Old schema`]: `Staré schéma`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch umožňuje vyhledání rozhraní API, produktů, aplikací, předplatných a dalších prostředků v rámci organizace poskytovatele nebo katalogu. Navíc může pomoci i objevovat vztahy mezi těmito prostředky.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch pro všechny podporované stránky seznamu`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch pro koncepty rozhraní API a produktů`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch pro publikované produkty v katalozích a prostorech`,
  [`Omnisearch for consumer organizations`]: `Omnisearch pro zákaznické organizace`,
  [`Omnisearch for applications`]: `Omnisearch pro aplikace`,
  [`Omnisearch for requested approvals`]: `Omnisearch pro vyžádaná schválení`,
  [`Omnisearch for approvals tasks`]: `Omnisearch pro úlohy schválení`,
  [`Omnisearch for product apis`]: `Omnisearch pro rozhraní API produktu`,
  [`Omnisearch for subscriptions`]: `Omnisearch pro odběry`,
  [`Omnisearch for members`]: `Omnisearch pro členy`,
  [`Omnisearch for provider organizations`]: `Omnisearch pro organizace poskytovatelů`,
  [`Disable the new editor for OpenAPI2`]: `Zakázat nový editor pro OpenAPI2`,
  [`On`]: `Zapnuto`,
  [`Onboarding`]: `Zahrnutí`,
  [`Onboarding Edit Admin Org`]: `Zahrnutí volby Upravit administrátorskou organizaci`,
  [`Onboarding Edit Cloud Org`]: `Zahrnutí volby Upravit organizaci cloudu`,
  [`Onboarding Edit Reset Password`]: `Zahrnutí volby Upravit resetování hesla`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Po instalaci použijte rozhraní příkazového řádku produktu IBM Cloud k tomu, abyste zacílili na účet a oblast, kde zajišťujete službu API Connect, a poté vyzkoušejte rozhraní příkazového řádku tak, že vypíšete všechny organizace poskytovatele služby API Connect.`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Jeden nebo více objektů JSON popisujících schémata spotřebovávaná a vytvářená rozhraním API.`,
  [`One or more JSON representations for parameters`]: `Jedno nebo více znázornění JSON pro parametry`,
  [`One time use access token`]: `Jednorázový přístupový prvek`,
  [`One time use refresh token`]: `Jednorázový aktualizační token`,
  [`Online`]: `Online`,
  [`Only one API file should be present in the zip file.`]: `V souboru ZIP by měl být přítomen pouze jeden soubor rozhraní API.`,
  [`OpenAPI Specification Version`]: `Verze specifikace OpenAPI`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Funkce Open ID Connect (OIDC) poskytuje další ověřovací protokol, který je založen na standardu OAuth 2.0. Informace o uživatelích poskytovatelů OIDC jsou zakódovány ve webovém tokenu JSON nebo JWT. Povolíte-li službu OpenID Connect, bude vám poskytnuta šablona pro generování ID tokenů spolu s přístupovými tokeny a automaticky se vytvoří požadované zásady sestavení.`,
  [`Open a Directory`]: `Otevřít adresář`,
  [`Open in external editor`]: `Otevřít v externím editoru`,
  [`Open the server`]: `Otevřít server`,
  [`OpenAPI extensions`]: `Rozšíření OpenAPI`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect dosud není podporováno, je-li verze brány 6000`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `Nastavení protokolu OpenID Connect lze použít pouze v případě, že vybrané typy udělení zahrnují jednu nebo více z následujících možností: Implicitní a Přístupový kód.`,
  [`Operation`]: `Operace`,
  [`Operation Id`]: `ID operace`,
  [`Operation switch`]: `Přepínač operace`,
  [`Operation Switch`]: `Přepínač operace`,
  [`Operations`]: `Operace`,
  [`Operator build date`]: `Datum sestavení operátoru`,
  [`Operator build tag`]: `Značka sestavení operátoru`,
  [`Operator version`]: `Verze operátoru`,
  [`Optionally select a CA bundle`]: `Volitelně vybrat balík CA`,
  [`Optimize schema definition`]: `Optimalizovat definici schématu`,
  [`Optional`]: `Volitelné`,
  [`Or`]: `Nebo`,
  [`Or create a new application and subscribe to the selected plan`]: `Nebo vytvořte novou aplikaci a přihlaste se k odběru vybraného plánu`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Nebo vytvořte nový produkt a publikujte jej v katalogu sandboxu`,
  [`Order`]: `Pořadí`,
  [`Org`]: `Organizace`,
  [`Org owner`]: `Vlastník organizace`,
  [`Organization`]: `Organizace`,
  [`Organization:`]: `Organizace:`,
  [`Organization information`]: `Informace o organizaci`,
  [`Organization Manager`]: `Vedoucí organizace`,
  [`Organization has been updated.`]: `Organizace byla aktualizována.`,
  [`Organizations will be listed here.`]: `Zde budou uvedeny organizace.`,
  [`Otherwise`]: `Jinak`,
  [`Output`]: `Výstup`,
  [`Output Claims`]: `Výstupní nároky`,
  [`Output array`]: `Výstupní pole`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Proveďte výstup kořenového prvku i v případě, že není vyžadován, aby byl dokument XML dobře formován.`,
  [`Output variable`]: `Výstupní proměnná`,
  [`Override API consume types`]: `Potlačit typy spotřebovávané rozhraním API`,
  [`Override API produce types`]: `Potlačit typy produkované rozhraním API`,
  [`Override API security definitions`]: `Potlačit definice zabezpečení rozhraní API`,
  [`Override rate limits`]: `Potlačit limit četnosti`,
  [`Override default "provider" settings with configuration from this URL`]: `Potlačit výchozí nastavení "poskytovatel" s konfigurací z této adresy URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Potlačit výchozí nastavení "poskytovatel" s konfigurací z tohoto literálu`,
  [`Override plan rate limits`]: `Přepsat limity četnosti plánu`,
  [`Override plan rate limits for individual operation`]: `Přepsat limity četnosti plánu pro jednotlivé operace`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Přepsat časový limit pozvánky do zákaznické organizace časovým limitem pozvánky do katalogu`,
  [`Overview`]: `Přehled`,
  [`Owner scope check`]: `Kontrola rozsahu vlastníka`,
  [`Owner's Email`]: `E-mail vlastníka`,
  [`Owner's Name`]: `Jméno vlastníka`,
  [`Owner's email`]: `E-mail vlastníka`,
  [`Owns and administers the API provider organization`]: `Vlastní a spravuje organizaci poskytovatele rozhraní API.`,
  [`Owns and administers the admin organization`]: `Vlastní a spravuje administrátorskou organizaci.`,
  [`Owns and administers the app developer organization`]: `Vlastní a spravuje vývojářskou organizaci aplikací`,
  [`Owns and administrates the app developer organization`]: `Vlastní a spravuje vývojářskou organizaci aplikací`,
  [`PARAMETER NAME`]: `NÁZEV PARAMETRU`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `Výraz PCRE, který se má použít k ověření nároku příjemce (aud).`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `Výraz PCRE, který se má použít k ověření nároku vydavatele (iss).`,
  [`PER`]: `NA`,
  [`Per`]: `Na`,
  [`Plan`]: `Plán`,
  [`Plan:`]: `Plán:`,
  [`pending Consumer organization invitation`]: `nevyřízené pozvání zákaznické organizace`,
  [`PLAN`]: `PLÁN`,
  [`PLANS`]: `PLÁNY`,
  [`Policy`]: `Zásada`,
  [`policy`]: `zásada`,
  [`POLICIES`]: `ZÁSADY`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUKT`,
  [`Product Plan`]: `Plán produktu`,
  [`Product Plans`]: `Plány produktu`,
  [`Product Plans per page`]: `Plány produktu na stránku`,
  [`Property name`]: `Název vlastnosti`,
  [`Property type`]: `Typ vlastnosti`,
  [`Property example`]: `Příklad vlastnosti`,
  [`Property description`]: `Popis vlastnosti`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Stránka nebyla nalezena`,
  [`Parameter name`]: `Název parametru`,
  [`Parameter control`]: `Ovládací prvek parametru`,
  [`Parameters`]: `Parametry`,
  [`Parent`]: `Nadřízený prvek`,
  [`Parse`]: `Analyzovat`,
  [`Parse settings URL reference`]: `Odkaz na adresu URL pro nastavení analýzy`,
  [`Parse settings literal configuration`]: `Nastavení literálu nastavení analýzy`,
  [`Parse settings object reference`]: `Odkaz na objekt nastavení analýzy`,
  [`Parse the GraphQL query`]: `Analyzovat dotaz GraphQL`,
  [`Parse the response from the GraphQL backend server`]: `Analyzovat odezvu ze serveru typu back-end GraphQL`,
  [`Parse XML output`]: `Analyzovat výstup XML`,
  [`Pass-through`]: `Procházet`,
  [`Password`]: `Heslo`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `Bylo změněno heslo pro váš uživatelský účet ve službě IBM API Connect`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `Bylo změněno heslo pro váš uživatelský účet v nástroji Developer Portal {portalTitle}`,
  [`Password context variable`]: `Kontextová proměnná hesla`,
  [`Password is invalid. Re-enter the password.`]: `Heslo je neplatné. Zadejte heslo znovu.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `Požadavek na resetování hesla pro uživatelský účet ve službě IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Požadavek na resetování hesla pro účet nástroj Developer Portal {catalog}`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Hesla musí obsahovat alespoň jeden znak ze dvou následujících kategorií: malá písmena, velká písmena, číslice a interpunkce (např. !, $, #, %).`,
  [`Passwords must contain eight or more characters`]: `Hesla musí obsahovat nejméně osm znaků.`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Hesla musí obsahovat osm nebo více znaků, přičemž musí obsahovat alespoň jeden znak ze dvou následujících kategorií: malá písmena, velká písmena, číslice a interpunkce (např.!, $, #,%). Kromě toho nelze stejný znak použít více než dvakrát za sebou.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Hesla nesmí obsahovat stejný znak více než dvakrát za sebou.`,
  [`Passwords should match`]: `Hesla by se měla shodovat`,
  [`Path`]: `Cesta`,
  [`Path parameters`]: `Parametry cesty`,
  [`Path name`]: `Název cesty`,
  [`Paths`]: `Cesty`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Cesty identifikují prostředky REST vystavené rozhraním API. Operace kombinuje cestu se slovem HTTP, parametry a definicemi požadavků a odezev.`,
  [`Payments Authorization`]: `Autorizace plateb`,
  [`Payment method`]: `Způsob platby`,
  [`Pending`]: `Nevyřízeno`,
  [`Pending State`]: `Nevyřízený stav`,
  [`Pending approval to be published in catalog`]: `Nevyřízené schválení pro publikování v katalogu`,
  [`Pending approval to be staged in catalog`]: `Nevyřízené schválení pro fázování v katalogu`,
  [`Pending consumer organization invitations`]: `Nevyřízená pozvání zákaznických organizací`,
  [`View endpoints`]: `Zobrazit koncové body`,
  [`View base endpoints for this API`]: `Zobrazit základní koncové body pro toto rozhraní API`,
  [`View pending invitations`]: `Zobrazit nevyřízená pozvání`,
  [`Permission Denied`]: `Oprávnění odepřeno`,
  [`Permissions`]: `Oprávnění`,
  [`Persistent Connection`]: `Trvalé připojení`,
  [`Pin menu`]: `Ukotvit nabídku`,
  [`PlainText`]: `Prostý text`,
  [`Plan APIs`]: `Rozhraní API plánů`,
  [`Plan burst limits`]: `Plánovat limity dávky`,
  [`Plan by name`]: `Plánovat podle názvu`,
  [`Plan Default`]: `Výchozí plán`,
  [`Plan Details`]: `Podrobnosti plánu`,
  [`Plan migration targets`]: `Plánovat cíle migrace`,
  [`Plan rate limits`]: `Plánovat limity četnosti`,
  [`Plan name`]: `Název plánu`,
  [`Plan pricing`]: `Ocenění plánu`,
  [`Plan title already exists`]: `Název plánu již existuje`,
  [`Plans`]: `Plány`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Koncový bod rozhraní REST API platformy pro rozhraní API administrátora a poskytovatele`,
  [`Platform REST API endpoint for consumer APIs`]: `Koncový bod rozhraní REST API platformy pro rozhraní API odběratele`,
  [`Please choose a bind method`]: `Vyberte metodu vazby`,
  [`Please choose an authentication method`]: `Vyberte metodu ověření:`,
  [`Please contact the system administrator.`]: `Kontaktujte administrátora systému.`,
  [`Please contact your administrator for support.`]: `Požádejte administrátora o podporu.`,
  [`Please enter the reason.`]: `Zadejte příčinu.`,
  [`Please enter your username`]: `Zadejte jméno uživatele`,
  [`Please go to the space settings to manage API endpoints.`]: `Přejděte do nastavení prostoru pro správu koncových bodů rozhraní API.`,
  [`Please make sure the server is online and try again.`]: `Ujistěte se, že je server online, a operaci zopakujte.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Všimněte si, že při zakázání protokolu aktivit dojde k odebrání vlastností přidružených k protokolování typu obsahu v případě úspěchu nebo chyby. Opravdu chcete provést zakázání?`,
  [`Please review the license for API Connect by accessing`]: `Licenci pro službu API Connect si zkontrolujte prostřednictvím`,
  [`Please select a TLS`]: `Vyberte TLS`,
  [`Please select a TLS client profile`]: `Vyberte profil klienta TLS`,
  [`Please select a group type`]: `Vyberte typ skupiny`,
  [`Please select an LDAP protocol version`]: `Vyberte verzi protokolu LDAP:`,
  [`Please select authentication method`]: `Vyberte metodu ověření:`,
  [`Please select ciphers from the list below:`]: `Vyberte šifry z níže uvedeného seznamu:`,
  [`Please select consumer organization`]: `Vyberte zákaznickou organizaci`,
  [`Please select one Keystore from the list below:`]: `Vyberte jedno úložiště klíčů z níže uvedeného seznamu:`,
  [`Please select one keystore from the list below:`]: `Vyberte jedno úložiště klíčů z níže uvedeného seznamu:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Poznamenejte si ID klienta a tajný údaj pro tuto novou aplikaci. Uschovejte tajný údaj někde v bezpečí, protože jej nelze znovu načíst, ačkoli jej lze resetovat, je-li to nutné:`,
  [`Please try again in a few minutes.`]: `Operaci opakujte za několik minut.`,
  [`Please refresh catalog/space product list page and try again.`]: `Aktualizujte prosím stránku seznamu produktů katalogu/prostoru a zopakujte operaci.`,
  [`Policies`]: `Zásady`,
  [`Policy Assemblies`]: `Sestavení zásad`,
  [`Policy assembly`]: `Sestavení zásad`,
  [`Policy description.`]: `Popis zásady.`,
  [`Policy title`]: `Název zásady`,
  [`Policy has been uploaded.`]: `Zásada byla odeslána.`,
  [`Polish`]: `Polština`,
  [`Port`]: `Port`,
  [`Portal`]: `Portál`,
  [`Portal details`]: `Podrobnosti portálu`,
  [`Portal Director TLS client profile`]: `Profil klienta TLS serveru Portal Director`,
  [`Portal Director keystore`]: `Úložiště klíčů serveru Portal Director`,
  [`Portal Director truststore`]: `Úložiště údajů o důvěryhodnosti serveru Portal Director`,
  [`Portal service`]: `Portálová služba`,
  [`Portal Service`]: `Portálová služba`,
  [`Portal service name`]: `Název portálové služby`,
  [`Portal service details`]: `Podrobnosti portálové služby`,
  [`Portal Service and URL`]: `Portálová služba a adresa URL`,
  [`Portal service {name} was successfully created.`]: `Portálová služba {name} byla úspěšně vytvořena.`,
  [`Portal service {name} was successfully updated.`]: `Portálová služba {name} byla úspěšně aktualizována.`,
  [`Portal Service {arg} has been removed.`]: `Portálová služba {arg} byla odebrána.`,
  [`Portal site`]: `Portálové stránky`,
  [`Portal URL`]: `Adresa URL portálu`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Adresy URL portálových stránek nemohou být delší než 200 znaků`,
  [`Portal website URL`]: `Adresa URL webového serveru portálu`,
  [`Portal and Gateway Settings`]: `Nastavení portálu a brány`,
  [`Portal has been created`]: `Portál byl vytvořen`,
  [`Portal has been created.`]: `Portál byl vytvořen.`,
  [`Portal has been deleted`]: `Portál byl odstraněn`,
  [`Portals`]: `Portály`,
  [`Portuguese`]: `Portugalština`,
  [`Post Response`]: `Následná odezva`,
  [`Post-Invoke Hook`]: `Háček po vyvolání`,
  [`Preserve subscriptions on re-publish`]: `Zachovat odběry při opětovném publikování`,
  [`Preserve subscriptions on re-publish product`]: `Zachovat odběry při opětovném publikování produktu`,
  [`Pre-Invoke Hook`]: `Háček před vyvoláním`,
  [`Pre-Request`]: `Předběžný požadavek`,
  [`Preference`]: `Předvolba`,
  [`Prefix`]: `Předpona`,
  [`Preparing API`]: `Příprava rozhraní API`,
  [`Preserved request header`]: `Vyhrazené záhlaví požadavku`,
  [`preserved request header`]: `vyhrazené záhlaví požadavku`,
  [`Preserved response header`]: `Vyhrazené záhlaví odezvy`,
  [`preserved response header`]: `vyhrazené záhlaví odezvy`,
  [`Preview`]: `Náhled`,
  [`Preview of login screen registry prompt`]: `Náhled výzvy registru přihlašovací obrazovky`,
  [`Previous`]: `Předchozí`,
  [`Previous page`]: `Předchozí stránka`,
  [`Prettify`]: `Předvídat`,
  [`Price per month`]: `Cena za měsíc`,
  [`Private`]: `Soukromé`,
  [`Private Claims`]: `Soukromé nároky`,
  [`Private key & public key`]: `Soukromý klíč & veřejný klíč`,
  [`Private Key or Shared Secret for Decrypt`]: `Soukromý klíč nebo sdílený tajný klíč pro dešifrování`,
  [`Private Key or Shared Secret for Sign`]: `Soukromý klíč nebo sdílený tajný klíč pro podpis`,
  [`Private key detected`]: `Byl zjištěn soukromý klíč`,
  [`Private key password`]: `Heslo k soukromému klíči`,
  [`Processed`]: `Zpracováno`,
  [`Problem`]: `Problém`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Problém při načítání dat trasování. Zkuste operaci znovu. Podrobnosti o chybě viz konzola.`,
  [`Produced Media Types`]: `Produkované typy médií`,
  [`Produces`]: `Produkuje`,
  [`Product`]: `Produkt`,
  [`Product:`]: `Produkt:`,
  [`Product / Plan`]: `Produkt/Plán`,
  [`Product APIs`]: `Rozhraní API produktu`,
  [`Product editor`]: `Editor produktu`,
  [`Product lifecycle`]: `Životní cyklus produktu`,
  [`Product Lifecycle`]: `Životní cyklus produktu`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Název produktu nemůže končit na '-auto-product'. Změňte název a zkuste import znovu.`,
  [`Product setup`]: `Nastavení produktu`,
  [`Product details`]: `Podrobnosti o produktu`,
  [`Product documentation`]: `Dokumentace produktu`,
  [`Product has been created.`]: `Produkt byl vytvořen`,
  [`Product has been deleted`]: `-Produkt byl odstraněn`,
  [`Product has been updated`]: `Produkt byl aktualizován`,
  [`Product has been updated.`]: `Produkt byl aktualizován.`,
  [`Product has not been created!`]: `Produkt nebyl vytvořen!`,
  [`Product has not been published!`]: `Produkt nebyl publikován!`,
  [`Product has not been updated!`]: `Produkt nebyl aktualizován!`,
  [`Product has not been staged!`]: `Produkt nebyl fázován!`,
  [`Product information`]: `Informace o produktu`,
  [`Product summary details`]: `Souhrnné podrobnosti o produktu`,
  [`Product version`]: `Verze produktu`,
  [`Product visibility`]: `Viditelnost produktu`,
  [`Product-Approval`]: `Schválení produktu`,
  [`Product-Drafts`]: `Product-Drafts`,
  [`Product/plan`]: `Produkt/plán`,
  [`Production`]: `Produkce`,
  [`Production mode`]: `Produkční režim`,
  [`Production endpoints`]: `Koncové body produkce`,
  [`Products`]: `Produkty`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Produkty musí být fázovány do katalogu a pak publikovány, aby mohly být k dispozici vývojářům aplikací. Katalog se chová jako logická oblast brány a nástroj Developer Portal. Adresa URL pro volání API a nástroj Developer Portal jsou specifické pro konkrétní katalog. V typické konfiguraci používá organizace poskytovatele rozhraní API vývojový katalog pro testování rozhraní API v rámci vývoje a produkční katalog pro hostování rozhraní API, která jsou připravena k úplnému použití.`,
  [`Profile`]: `Profil`,
  [`Profile rule`]: `Pravidlo profilu`,
  [`Promote to production`]: `Povýšit na produkci`,
  [`Proof Key for Code Exchange`]: `PKCE (Proof Key for Code Exchange)`,
  [`Proof Key for Code Exchange (PKCE)`]: `Proof Key for Code Exchange (PKCE)`,
  [`Properties`]: `Vlastnosti`,
  [`Property`]: `Vlastnost`,
  [`Property Description`]: `Popis vlastnosti`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `Vlastnosti {{target}} může být přiřazena statická hodnota jejím zadáním níže.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `Vlastnost {{target}} je aktuálně mapována na {{from}} - mapování může být odebráno níže.`,
  [`Property Value is Base64 Encoded`]: `Hodnota vlastnosti je kódována pomocí znakové sady Base64`,
  [`Protocol`]: `Protokol`,
  [`Protocols`]: `Protokoly`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protokoly, které lze použít k vyvolání API (podporován je pouze protokol https pro vynucená rozhraní API brány).`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Poskytněte pověření OAuth {oauthVer} pro ověření uživatelů a získání přístupu k rozhraní API poskytovatele OpenID`,
  [`Provide a schema`]: `Poskytnout schéma`,
  [`Provide GraphQL schema`]: `Poskytnout schéma GraphQL`,
  [`Provide the URL to the backend GraphQL server`]: `Poskytněte adresu URL k serveru typu back-end GraphQL`,
  [`Provide your email address to receive password reset instructions`]: `Zadejte e-mailovou adresu, na kterou chcete přijímat pokyny k resetování hesla`,
  [`Provide your new password below`]: `Poskytněte nové heslo níže`,
  [`Provider endpoint`]: `Koncový bod poskytovatele`,
  [`Provider Information`]: `Informace o poskytovateli`,
  [`Provider Organization`]: `Organizace poskytovatele`,
  [`Provider Organizations`]: `Organizace poskytovatele`,
  [`Provider Type`]: `Typ poskytovatele`,
  [`Provider organizations`]: `Organizace poskytovatele`,
  [`Provider type`]: `Typ poskytovatele`,
  [`Provider org invite failed`]: `Pozvánka organizace poskytovatele se nezdařila`,
  [`Provider organization {name} created`]: `Organizace poskytovatele {name} byla vytvořena`,
  [`Provider organizations will be listed here`]: `Zde budou uvedeny organizace poskytovatele`,
  [`Provider-Org`]: `Organizace poskytovatele`,
  [`Provides contact information for the exposed API.`]: `Poskytuje kontaktní informace pro vystavené rozhraní API.`,
  [`Provides license information for the exposed API.`]: `Poskytuje informace o licenci pro vystavené rozhraní API.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Poskytuje metadata o rozhraní API. Metadata lze v případě potřeby použít klienty.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `Bylo zahájeno zajišťování nástroje Developer Portal. Dokončení bude trvat několik minut. Po dokončení obdržíte e-mail obsahující odkaz na nastavení hesla pro účet administrátora portálu.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `Byla zahájena aktualizace adresy URL nástroje Developer Portal. Dokončení procesu bude trvat několik minut.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `Adresa URL serveru proxy`,
  [`Public`]: `Veřejné`,
  [`Public Key or Shared Secret for Encrypt`]: `Veřejný klíč nebo sdílený tajný klič pro šifrování`,
  [`Publish`]: `Publikovat`,
  [`Publish API`]: `Publikovat rozhraní API`,
  [`Publish a product from the Develop section.`]: `Publikujte produkt ze sekce Vývoj.`,
  [`Publish product`]: `Publikovat produkt`,
  [`Publish to`]: `Publikovat do`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Publikujte nějaké produkty do tohoto katalogu, abyste pro ně vytvořili odběry.`,
  [`Publish target`]: `Cíl publikování`,
  [`Publish this API to the Sandbox catalog.`]: `Publikovat toto rozhraní API do katalogu sandboxů.`,
  [`Publish to specific gateway services`]: `Publikovat do specifických služeb brány`,
  [`Publish using a new product`]: `Publikovat pomocí nového produktu`,
  [`Publish using an existing product`]: `Publikovat pomocí existujícího produktu`,
  [`Publishable Key`]: `Publikovatelný klíč`,
  [`Publishable key`]: `Publikovatelný klíč`,
  [`Publishable key tip`]: `Rada k publikovatelnému klíči`,
  [`Published`]: `Publikováno`,
  [`Published product`]: `Publikovaný produkt`,
  [`Publishing`]: `Publikování`,
  [`Publishing New API`]: `Publikování nového rozhraní API`,
  [`Publish (preserve subscriptions)`]: `Publikovat (zachovat odběry)`,
  [`Publish|permission`]: `Publikovat`,
  [`Put online`]: `Vložit online`,
  [`Query`]: `Dotaz`,
  [`Queryparam Encode`]: `Kódování parametru dotazu`,
  [`Queued`]: `Zařazeno do fronty`,
  [`Queued events: **{queued}**`]: `Události ve frontě: **{queued}**`,
  [`Retry blocked jobs`]: `Zopakovat zablokování úloh`,
  [`REQUIRED`]: `POVINNÉ`,
  [`ROLES`]: `ROLE`,
  [`Rate limit`]: `Limit četnosti`,
  [`Rate Limit`]: `Limit četnosti`,
  [`Rate Limit Name`]: `Název limitu četnosti`,
  [`Rate limits`]: `Limity četnosti`,
  [`Rate limit name can't be a duplicate`]: `Název limitu četnosti nesmí být duplicitní`,
  [`Rate limit name can't be empty`]: `Název limitu četnosti nesmí být prázdný`,
  [`Rate limit overrides`]: `Potlačení limitu četnosti`,
  [`Rate limit time interval can't be 0`]: `Časový interval limitu četnosti nemůže být 0`,
  [`Rate limit time interval can't be empty`]: `Časový interval limitu četnosti nemůže být prázdný`,
  [`Rate limit time interval unit must be selected`]: `Časový interval limitu četnosti musí být vybrán`,
  [`Rate limit value can't be empty`]: `Hodnota limitu četnosti nesmí být prázdná`,
  [`Rate limit value can't be negative`]: `Hodnota limitu četnosti nesmí být záporná`,
  [`Re-stage`]: `Přefázovat`,
  [`Re-stage product`]: `Přefázovat produkt`,
  [`Read only`]: `Jen pro čtení`,
  [`Read-Only`]: `Jen pro čtení`,
  [`Realized`]: `Realizováno`,
  [`Reason`]: `Příčina`,
  [`Reason for declining`]: `Důvod odmítnutí`,
  [`Recommendations`]: `Doporučení`,
  [`Recommended configuration`]: `Doporučená konfigurace`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Poznamenejte si ID klienta a tajné údaje zobrazené níže pro pozdější použití. Tajný klíč klienta nelze zobrazit později.`,
  [`Recommendations applied`]: `Uplatněná doporučení`,
  [`Redact`]: `Redigovat`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Redigujte nebo odeberte určená pole z těla požadavku, těla odpovědi a protokolů aktivit. Definujte pole, které se má upravit nebo odebrat, zadáním výrazu XPath.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Redigujte nebo odeberte určená pole z těla požadavku, těla odpovědi a protokolů aktivit. Definujte pole, které se má upravit nebo odebrat, zadáním výrazu XPath nebo JSONata.`,
  [`Redaction`]: `Redakce`,
  [`Redactions`]: `Redakce`,
  [`Redirect`]: `Přesměrovat`,
  [`Redirect URI`]: `Identifikátor URI pro přesměrování`,
  [`Redirect URL`]: `Adresa URL pro přesměrování`,
  [`Redirect through Portal`]: `Přesměrovat přes portál`,
  [`Redirect time limit`]: `Časový limit pro přesměrování`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `Časový limit pro přesměrování musí být mezi 10 a 600 sekundami`,
  [`References`]: `Odkazy`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Další pokyny najdete na webu [IBM Cloud Docs]({link}).`,
  [`Referral`]: `Odkaz`,
  [`Refresh`]: `Aktualizovat`,
  [`Refresh Token`]: `Aktualizační token`,
  [`Refresh button`]: `Tlačítko Aktualizovat`,
  [`Refresh products`]: `Aktualizovat produkty`,
  [`Refresh results from server`]: `Aktualizovat výsledky ze serveru`,
  [`Refresh token`]: `Aktualizační token`,
  [`Refresh token time to live`]: `Doba životnosti aktualizačního tokenu`,
  [`Refresh token time-to-live`]: `Doba životnosti aktualizačního tokenu`,
  [`Refresh token time-to-live (seconds)`]: `Doba životnosti aktualizačního tokenu (sekundy)`,
  [`Refresh token TTL has been changed`]: `Doba životnosti aktualizačního tokenu byla změněna.`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Doba životnosti aktualizačního tokenu je **{seconds} s ({time})**`,
  [`Refresh token time to live is **off**`]: `Doba životnosti aktualizační tokenu je **off**`,
  [`Refresh tokens`]: `Aktualizační tokeny`,
  [`Refreshing`]: `Aktualizace`,
  [`Refreshing...`]: `Aktualizace...`,
  [`Region`]: `oblast`,
  [`Register`]: `Registrovat`,
  [`Register Gateways`]: `Registrujte brány.`,
  [`Register Service`]: `Registrovat službu`,
  [`Register gateways`]: `Registrujte brány.`,
  [`Register service`]: `Registrovat službu`,
  [`Register a service`]: `Registrovat službu`,
  [`Register new services and manage existing services`]: `Registrujte nové služby a spravujte existující služby.`,
  [`Register remote gateway`]: `Registrovat vzdálenou bránu`,
  [`Registered Admin Member`]: `Registrovaný administrativní člen`,
  [`Registered Provider Org Owner or Member`]: `Registrovaný člen nebo vlastník organizace poskytovatele`,
  [`Registered client information on the OpenID provider`]: `Informace o registrovaném klientu u poskytovatele OpenID`,
  [`Registration completed successfully`]: `Registrace byla úspěšně dokončena`,
  [`Registration failed.`]: `Registrace se nezdařila.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Relativní cesty k jednotlivým koncovým bodům. Musí být relativní vzhledem k 'basePath'.`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `Odebrat konfiguraci analýzy`,
  [`Remote API Connect`]: `Vzdálená služba API Connect`,
  [`Remote login`]: `Vzdálené přihlášení`,
  [`Remote publish`]: `Vzdálené publikování`,
  [`Remove`]: `Odebrat`,
  [`Remove Burst Limit`]: `Odebrat limit dávky`,
  [`Remove constraints`]: `Odebrat omezení`,
  [`Remove Count Limit`]: `Odebrat limit počtu`,
  [`Remove gateway extension`]: `Odebrat rozšíření brány`,
  [`Remove product`]: `Odebrat produkt`,
  [`Remove Rate limit`]: `Odebrat limit četnosti`,
  [`Remove the invalid constraints`]: `Odebrat neplatná omezení`,
  [`Remove the invalid constraints from`]: `Odebrat neplatná omezení z`,
  [`Remove action`]: `Odebrat akci`,
  [`Remove case`]: `Odebrat případ`,
  [`Remove catch`]: `Odebrat zachycenou položku`,
  [`Remove filter`]: `Odebrat filtr`,
  [`Remove input`]: `Odebrat vstup`,
  [`Remove mapping`]: `Odebrat mapování`,
  [`Remove output`]: `Odebrat výstup`,
  [`Remove static value`]: `Odebrat statickou hodnotu`,
  [`Removed`]: `Odebráno`,
  [`Removed by user`]: `Odebráno uživatelem`,
  [`Reorder`]: `Znovu uspořádat`,
  [`Repeat`]: `Zopakovat`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Zopakujte vyvolání API v nastaveném počtu opakování, nebo dokud neklepnete na tlačítko Zastavit.`,
  [`Replace`]: `Zaměnit`,
  [`Replace Free Trial with`]: `Nahradit bezplatnou zkušební verzi za`,
  [`Replace Gold with`]: `Nahradit verzi Gold za`,
  [`Replace GraphQL schema`]: `Nahradit schéma GraphQL`,
  [`Replace plans`]: `Zaměnit plány`,
  [`Replace private key & public key`]: `Nahradit soukromý klíč a veřejný klíč`,
  [`Replace Product`]: `Zaměnit produkt`,
  [`Replace product`]: `Zaměnit produkt`,
  [`Replace schema`]: `Nahradit schéma`,
  [`Replace Silver with`]: `Nahradit verzi Silver za`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Nahraďte aktuálního vlastníka výběrem nového vlastníka ze seznamu návrhů. Nahrazeným vlastníkům přiřaďte nová oprávnění, aby mohli pokračovat v práci.`,
  [`Replace|permission`]: `Zaměnit`,
  [`Republish`]: `Znovu publikovat`,
  [`Republish product`]: `Znovu publikovat produkt`,
  [`Request`]: `Požadavek`,
  [`Request catch`]: `Zachycení požadavku`,
  [`Request endpoint`]: `Koncový bod požadavku`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Požadavek na provedení akce {action} pro produkt API v katalogu {catalog} byl schválen`,
  [`Request approved to {{action}} app {{appName}}`]: `Požadavek na provedení akce {action} pro aplikaci {appName} byl schválen`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Požadavek na provedení akce {action} pro produkt API v katalogu {catalog} byl zamítnut`,
  [`Request denied to {{action}} app {{appName}}`]: `Požadavek na provedení akce {action} pro aplikaci {appName} byl zamítnut`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Požadavek na schválení přihlášení k odběru produktu API v katalogu {catalog}`,
  [`Request for approval to {{action}} app {{appName}}`]: `Požadavek na schválení akce {action} pro aplikaci {appName}`,
  [`Request headers`]: `Záhlaví požadavku`,
  [`Request pending to promote application to production`]: `Nevyřízená žádost o povýšení aplikace na produkci`,
  [`Request query`]: `Dotaz požadavku`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Byl přijat požadavek na provedení akce {action} pro produkt API v katalogu {catalog}`,
  [`Request received to {{action}} app {{appName}}`]: `Byl přijat požadavek na provedení akce {action} pro aplikaci {appName}`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Požadavek na přihlášení k odběru produktu API v katalogu {catalog} byl vzat zpět`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Požadavek na provedení akce {action} pro produkt API v katalogu {catalog} byl vzat zpět`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Požadavek na provedení akce {action} aplikace {appName} byl vzat zpět`,
  [`Requested approvals`]: `Požadovaná schválení`,
  [`Requested By`]: `Žadatel`,
  [`Requested To`]: `Požadováno pro`,
  [`Require`]: `Vyžadovat`,
  [`Require applications to provide a client ID`]: `Vyžadovat, aby aplikace poskytovaly ID klienta`,
  [`Require approval for all new self service development accounts`]: `Požadovat schválení pro všechny nové samoobslužné vývojové účty.`,
  [`Required`]: `Povinné`,
  [`Required for rate limiting`]: `Povinné pro omezení četnosti`,
  [`Required must be checked off when location is in path.`]: `Je-li umístění v cestě, nesmí být volba Povinné zaškrtnutá.`,
  [`Required path`]: `Požadovaná cesta`,
  [`Resend Invite`]: `Znovu odeslat pozvánku`,
  [`Resend invitation`]: `Znovu odeslat pozvánku`,
  [`Resend invite`]: `Znovu odeslat pozvánku`,
  [`Reset`]: `Resetovat`,
  [`Reset defaults`]: `Obnovit výchozí hodnoty`,
  [`Reset Notification template`]: `Resetovat šablonu oznámení`,
  [`Reset password`]: `Resetovat heslo`,
  [`Reset password TTL has been changed`]: `Hodnota TTL pro resetování hesla byla změněna.`,
  [`Reset password time to live`]: `Doba životnosti pro resetování hesla`,
  [`Reset password time-to-live`]: `Doba životnosti pro resetování hesla`,
  [`Reset password email has been sent`]: `E-mail s odkazem pro resetování hesla byl odeslán`,
  [`Reset password email has not been sent`]: `E-mail s odkazem pro resetování hesla nebyl odeslán`,
  [`Reset password invitation timeout`]: `Časový limit pozvánky pro resetování hesla`,
  [`Reset to default preferences`]: `Resetovat na výchozí předvolby`,
  [`Resolve API Connect variable references`]: `Přeložit odkazy na proměnné služby API Connect`,
  [`Resolve XML input data type`]: `Interpretovat vstupní datový typ XML`,
  [`resolver`]: `vyhodnocovací modul`,
  [`Resource group`]: `Skupina prostředků`,
  [`Resource Owner`]: `Vlastník prostředku`,
  [`Resource owner password grant`]: `Udělení hesla vlastníka prostředku`,
  [`Resource owner revocation`]: `Odvolání vlastníka prostředku`,
  [`Resource owner revocation path`]: `Cesta k odvolání vlastníka prostředku`,
  [`Resource Owner Security`]: `Zabezpečení vlastníka prostředku`,
  [`Resource owner - JWT`]: `Vlastník prostředku - JWT`,
  [`Resource owner - Password`]: `Vlastník prostředku - Heslo`,
  [`Resource owner password`]: `Heslo vlastníka prostředku`,
  [`Resources`]: `Prostředky`,
  [`Response`]: `Odezva`,
  [`Response catch`]: `Zachycení odezvy`,
  [`Response Definition`]: `Definice odezvy`,
  [`Response Time`]: `Doba odezvy`,
  [`Response type`]: `Typ odezvy`,
  [`Response context variables`]: `Kontextové proměnné odezvy`,
  [`Response headers`]: `Záhlaví odezvy`,
  [`Response must have a description`]: `Odezva musí mít popis`,
  [`Response must have a schema`]: `Odezva musí mít schéma`,
  [`Response must have a status code`]: `Odezva musí mít stavový kód`,
  [`Response object variable`]: `Objektová proměnná odezvy`,
  [`Response time`]: `Doba odezvy`,
  [`Response time: {{value}}ms`]: `Doba odezvy: {{value}} ms`,
  [`Result of removing`]: `Výsledek odebrání`,
  [`Restrict HTTP transactions to version 1.0.`]: `Omezit transakce HTTP na verzi 1.0.`,
  [`Restrict to HTTP 1.0`]: `Omezit na HTTP 1.0`,
  [`Retire`]: `Skončit s používáním`,
  [`Retire product`]: `Skončit s používáním produktu`,
  [`Retired`]: `Odloženo`,
  [`Retire|permission`]: `Skončit s používáním`,
  [`Return V5 Responses`]: `Vrátit odpovědi V5`,
  [`Retry selected requests`]: `Zopakovat vybrané požadavky`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Vrátit výsledky pro standardní dotazy na introspekci GraphQL ze serveru proxy GraphQL`,
  [`Return third-party access_token`]: `Vrátit přístupový token jiného dodavatele`,
  [`Return third-party access_token with the token response`]: `Vrátit přístupový token jiného dodavatele pomocí odezvy tokenu`,
  [`Return third-party id_token`]: `Vrátit ID tokenu třetí strany`,
  [`Return third-party id_token with the token response`]: `Vrátit ID tokenu jiného dodavatele pomocí odezvy tokenu`,
  [`Return to Login`]: `Návrat na přihlášení`,
  [`Reveal in finder`]: `Zobrazit ve vyhledávači`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Přezkoumejte a určete, co se má ve schématu zobrazit nebo skrýt. K tomu, aby schéma zůstalo platné, se mohou vyžadovat další změny.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Přezkoumejte všechna varování. Později vám v editoru pomůžeme vaše schéma vylepšit díky inteligentním doporučením.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Přezkoumejte veškerá varování a využijte naše inteligentní doporučení ke zlepšení svého schématu a k ochraně svého systému back-end.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Zkontrolujte základní adresu URL koncového bodu vyvolání rozhraní API a zadejte domény zpracované bránou.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Zkontrolujte typ brány, titulek, název a souhrn. Při vyvolání příkazů služby API Connect a rozhraní API použijte název brány.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Přezkoumejte souhrn změn, který může zahrnovat další změny, aby schéma zůstalo platné.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Přečtěte si varování, která jsme našli ve vašem schématu. Později vám v editoru pomůžeme vylepšit vaše schéma a chránit systém back-end díky inteligentním doporučením.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Přečtěte si varování, která jsme našli ve vašem schématu. Pomůžeme vám vylepšit své schéma a chránit systém back-end díky inteligentním doporučením.`,
  [`Reviewed schema, paths and operations`]: `Revidované schéma, cesty a operace`,
  [`Revocation protocol`]: `Protokol odvolání`,
  [`Revoke token`]: `Zrušit přístup k tokenu`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `Dokumentace RHEL`,
  [`Role`]: `Role`,
  [`Role default`]: `Výchozí role`,
  [`Role default has been deleted`]: `Výchozí role byla odstraněna`,
  [`Role defaults`]: `Výchozí role`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Výchozí role jsou předkonfigurované šablony rolí použité k určení výchozích rolí, které jsou k dispozici při vytvoření nové organizace.`,
  [`Role has been created`]: `Role byla vytvořena`,
  [`Role has been deleted`]: `Role byla odstraněna`,
  [`Role has been updated`]: `Role byla aktualizována`,
  [`Roles`]: `Role`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Role (a jejich přiřazená oprávnění) řídí přístup k funkcím ve službě API Connect. Role administrátorské organizace se používají pouze pro osoby používající Cloud Manager.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Role a oprávnění řídí přístup k funkcím nástroje API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Role a jejich přidružená oprávnění řídí přístup k funkcím a schopnost provádět úlohy ve službě API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Role a jejich přidružená oprávnění řídí přístup k funkcím a schopnost provádět úlohy v uživatelském rozhraní nástroje API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Role a jejich přidružená oprávnění řídí přístup k funkcím a schopnost provádět úlohy.`,
  [`Root`]: `Kořen`,
  [`Root XML Element Name`]: `Název kořenového prvku XML`,
  [`Run the following command in the CLI to register the credentials:`]: `Spusťte následující příkaz v rozhraní CLI, abyste zaregistrovali pověření:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Spusťte následující příkaz v rozhraní CLI, abyste zaregistrovali pověření sady nástrojů:`,
  [`Running`]: `Spuštěno`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Běhová proměnná obsahující JWK, který se má použít k dešifrování JWT.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Běhová proměnná obsahující JWK, který se má použít k šifrování JWT.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Běhová proměnná obsahující JWK, který se má použít k podepsání JWT.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Běhová proměnná obsahující JWK, který se má použít k ověření podpisu.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Běhová proměnná, ze které lze načíst platnou sadu nároků JSON.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Běhová proměnná, ze které může být načten řetězec nároku příjemce (aud). Vícenásobné proměnné se nastavují pomocí řetězce odděleného čárkami.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Běhová proměnná, ze které může být načten řetězec nároku vydavatele (iss). Tento nárok představuje činitele, který vydal JWT.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Běhová proměnná, ze které může být načten řetězec nároku subjektu (sub).`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Běhová proměnná, do které se má umístit vygenerovaný JWT. Není-li nastavena, je JWT umístěn do záhlaví autorizace jako token držitele.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Běhová proměnná, která obsahuje jméno ověřeného uživatele. Token LTPA je generován s touto vlastností jako uživatel. Např. $(client.app.id) nebo $(oauth.resource-owner) nebo běhová proměnná, která je konfigurována v zásadě nastavení proměnné.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `Běhová proměnná, ke které je přiřazena úplná sada nároků, které jsou v JWT.`,
  [`Russian`]: `Ruština`,
  [`Scalar`]: `Skalár`,
  [`Schema`]: `Schéma`,
  [`SCHEMA`]: `SCHÉMA`,
  [`SCOPE`]: `ROZSAH`,
  [`SCOPES`]: `ROZSAHY`,
  [`SECURITY DEFINITIONS`]: `DEFINICE ZABEZPEČENÍ`,
  [`Service`]: `Služba`,
  [`Service state:`]: `Stav služby:`,
  [`SERVICE TYPE`]: `TYP SLUŽBY`,
  [`SIL-OFL 1.1 license`]: `Licence k SIL-OFL 1.1`,
  [`SOURCE`]: `ZDROJ`,
  [`SSL Profile`]: `Profil SSL`,
  [`State`]: `Stav`,
  [`STATUS`]: `STAV`,
  [`Status Code`]: `Stavový kód`,
  [`STATUS CODE`]: `STAVOVÝ KÓD`,
  [`SUBMITTED`]: `ODESLÁNO`,
  [`SUBSCRIBERS`]: `ODBĚRATELÉ`,
  [`Summary`]: `Souhrn`,
  [`Same as product`]: `Stejné jako produkt`,
  [`Sample ID token signing key (RS256)`]: `Podpisový klíč ukázkového tokenu ID (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Ověřovací klíč ukázkového tokenu ID (RS256)`,
  [`Sample OAuth symmetric key value`]: `Ukázková hodnota symetrického klíče OAuth`,
  [`Sample URL: https://api.catalog.org.host/`]: `Ukázková adresa URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `Ukázková adresa URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Popis ukázky rozsahu 1`,
  [`Sandbox - Overview`]: `Sandbox - Přehled`,
  [`Sandbox Catalog`]: `Katalog sandboxů`,
  [`Sandbox Catalog User Registry`]: `Registr uživatelů katalogu sandboxů`,
  [`Sandbox Default TLS client profile`]: `Výchozí profil klienta TLS sandboxu`,
  [`Sandbox Default TLS Client Profile`]: `Výchozí profil klienta TLS sandboxu`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Katalog sandboxu by měl vždy existovat v organizaci poskytovatele.`,
  [`Save`]: `Uložit`,
  [`Save API as a new version`]: `Uložit rozhraní API jako novou verzi`,
  [`Save Product as a new version`]: `Uložit produkt jako novou verzi`,
  [`Save and Edit`]: `Uložit a upravit`,
  [`Save and republish`]: `Uložit a znovu publikovat`,
  [`Save as a new version`]: `Uložit jako novou verzi`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Uložte tajný klíč klienta (již nebude možné jej načíst pro účely zabezpečení).`,
  [`Save this product as a new version`]: `Uložit tento produkt jako novou verzi`,
  [`Saved`]: `Uloženo`,
  [`Saving assembly...`]: `Ukládání sestavení...`,
  [`Saving...`]: `Ukládání...`,
  [`Schema Type`]: `Typ schématu`,
  [`Schema as JSON`]: `Schéma jako JSON`,
  [`Schema as XML`]: `Schéma jako XML`,
  [`Schema as YAML`]: `Schéma jako YAML`,
  [`Schema change`]: `Změna schématu`,
  [`Schema changes`]: `Změny schématu`,
  [`Schema definition circular reference limit`]: `Limit cyklického odkazu definice schématu`,
  [`Schema name`]: `Název schématu`,
  [`Schema warnings`]: `Varování schématu`,
  [`Schema definition`]: `Definice schématu`,
  [`Scheme`]: `Schéma`,
  [`Schemes`]: `Schémata`,
  [`Scope`]: `Rozsah`,
  [`Scopes`]: `Rozsahy`,
  [`Scopes cannot be empty`]: `Rozsahy nemohou být prázdné`,
  [`Search`]: `Hledat`,
  [`Search DN`]: `Hledat DN`,
  [`Search in the field above above and select to add provider organizations`]: `Hledat v poli uvedeném výše a vybrat pro přidání organizací poskytovatele`,
  [`Search Limit`]: `Limit hledání`,
  [`Search Organization owner from`]: `Hledat vlastníka organizace z`,
  [`Search orgs/groups`]: `Hledat organizace/skupiny`,
  [`Search Owner`]: `Hledat vlastníka`,
  [`Search assemblies`]: `Hledat sestavení`,
  [`Search for`]: `Vyhledat`,
  [`Search for provider organizations`]: `Vyhledat organizace poskytovatele`,
  [`Search for Owner`]: `Vyhledat vlastníka`,
  [`Search for a new member to become the new owner.`]: `Vyhledejte nového člena, který se stane novým vlastníkem.`,
  [`Search for an existing member to become the new owner.`]: `Vyhledejte existujícího člena, který se stane novým vlastníkem.`,
  [`Search for new owner`]: `Vyhledat nového vlastníka`,
  [`Search for owner from:`]: `Vyhledat vlastníka z:`,
  [`Search for the member`]: `Vyhledat člena`,
  [`Search for title or name or version`]: `Vyhledat titulek nebo název nebo verzi`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Vyhledejte uživatele, kterého chcete přidat jako člena, a přiřaďte nezbytné role.`,
  [`Search overflow`]: `Vyhledat přetečení`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Vyhledat registr pro existujícího uživatele, kterého chcete přidat jako člena, a přiřadit nezbytné role`,
  [`Search types/fields`]: `Typy/pole vyhledávání`,
  [`Search user`]: `Hledat uživatele`,
  [`Search schema`]: `Hledat schéma`,
  [`Seconds`]: `Sekundy`,
  [`Secret Key`]: `Tajný klíč`,
  [`Secret Name`]: `Název tajného údaje`,
  [`Secret Required`]: `Povinné tajné údaje`,
  [`Secret key`]: `Tajný klíč`,
  [`Secure`]: `Zabezpečit`,
  [`Secure connection`]: `Zabezpečené připojení`,
  [`Secure using Client ID`]: `Zabezpečit pomocí ID klienta`,
  [`Security`]: `Zabezpečení`,
  [`Security & Product Definitions`]: `Zabezpečení & Definice produktu`,
  [`Security definitions`]: `Definice zabezpečení`,
  [`Security and Publish`]: `Zabezpečení a publikace`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Definice zabezpečení řídí přístup klienta ke koncovým bodům rozhraní API, včetně ověření klíče API, základního ověření prostřednictvím uvedeného registru uživatelů a OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Definice zabezpečení řídí přístup klienta ke koncovým bodům rozhraní API, včetně ověření klíče API, základního ověření prostřednictvím uvedeného registru uživatelů a OAuth. [Další informace]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Zde vybrané definice zabezpečení platí pro celé rozhraní API, ale mohou být přepsány pro jednotlivé operace.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Zde vybrané definice zabezpečení platí pro celé rozhraní API, ale mohou být přepsány pro jednotlivé operace. [Další informace]({link})`,
  [`Security object to use to decode the claim.`]: `Objekt zabezpečení, který se má použít k dekódování nároku.`,
  [`Security object used to encrypt the JWT.`]: `Objekt zabezpečení použitý k zašifrování JWT.`,
  [`Security object used to sign the JWT.`]: `Objekt zabezpečení použitý k podepsání JWT.`,
  [`Security object used to verify the claim.`]: `Objekt zabezpečení použitý k ověření nároku.`,
  [`Select APIs to add to this product`]: `Vyberte rozhraní API, která mají být přidána do tohoto produktu`,
  [`Select API Type`]: `Vybrat typ rozhraní API`,
  [`Select API type`]: `Vybrat typ rozhraní API`,
  [`Select a billing integration in the Product plans.`]: `Vyberte integraci s platebním systémem v plánech produktu.`,
  [`Select a flow...`]: `Vybrat tok...`,
  [`Select a plan from your existing product`]: `Vyberte plán z existujícího produktu.`,
  [`Select a policy file to import to this catalog`]: `Vyberte soubor zásad, který se má importovat do tohoto katalogu`,
  [`Select a policy implementation file to import to this catalog`]: `Vyberte soubor implementace zásady, který se má importovat do tohoto katalogu.`,
  [`Select a product`]: `Vybrat produkt`,
  [`Select Certificate Manager service`]: `Vybrat službu správce certifikátů`,
  [`Select Cloud`]: `Vybrat cloud`,
  [`Select Email Server`]: `Vybrat e-mailový server`,
  [`Select gateway services`]: `Vybrat služby brány`,
  [`Select Import Type`]: `Vybrat typ importu`,
  [`Select product`]: `Vybrat produkt`,
  [`Select product type`]: `Vybrat typ produktu`,
  [`Select Service`]: `Vybrat službu`,
  [`Select Service Type`]: `Vybrat typ služby`,
  [`Select target endpoints`]: `Vybrat cílové koncové body`,
  [`Select target service`]: `Vybrat cílovou službu`,
  [`Select user registry type`]: `Vybrat typ registru uživatelů`,
  [`Select a WSDL service from the imported file`]: `Vyberte službu WSDL z importovaného souboru.`,
  [`Select a certificate`]: `Vybrat certifikát`,
  [`Select a Certificate Manager service`]: `Vybrat službu správce certifikátů`,
  [`Select a cryptographic algorithm.`]: `Vyberte šifrovací algoritmus.`,
  [`Select a default catalog and organization`]: `Vybrat výchozí katalog a organizaci`,
  [`Select a different cloud`]: `Vybrat jiný cloud`,
  [`Select a key encryption algorithm.`]: `Vyberte algoritmus šifrování klíče.`,
  [`Select a plan to migrate`]: `Vybrat plán k migraci`,
  [`Select a plan to replace`]: `Vybrat plán k nahrazení`,
  [`Select a plan to supersede`]: `Vybrat plán k nahrazení`,
  [`Select a product to set as the migration target`]: `Vyberte produkt, který má být nastaven jako cíl migrace.`,
  [`Select a product to supersede`]: `Vyberte produkt, který nahradí produkt`,
  [`Select a product to supersede {sourceProductName}:`]: `Vyberte produkt, který nahradí produkt {sourceProductName}:`,
  [`Select a resource group`]: `Vybrat skupinu prostředků`,
  [`Select a service`]: `Vybrat službu`,
  [`Select a supported file ({type}) and try again.`]: `Vyberte podporovaný soubor ({type}) a operaci opakujte.`,
  [`Select a target SOAP service`]: `Vybrat cílovou službu SOAP`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Vyberte balík sady nástrojů ke stažení. Pokud máte sadu nástrojů nainstalovanou, přeskočte tento krok.`,
  [`Select an encryption algorithm.`]: `Vyberte šifrovací algoritmus.`,
  [`Select an extension file for this gateway`]: `Vyberte soubor rozšíření pro tuto bránu`,
  [`Select an org`]: `Vybrat organizaci`,
  [`Select catalog`]: `Vybrat katalog`,
  [`Select Catalogs to Search`]: `Vybrat katalogy k prohledání`,
  [`Select compatible gateway services`]: `Vybrat kompatibilní služby brány`,
  [`Select one Keystore from the list below:`]: `Vyberte jedno úložiště klíčů z níže uvedeného seznamu:`,
  [`Select one keystore from the list below:`]: `Vyberte jedno úložiště klíčů z níže uvedeného seznamu:`,
  [`Select one keystore from the list.`]: `Vyberte jedno úložiště klíčů ze seznamu:`,
  [`Select operation...`]: `Vybrat operaci...`,
  [`Select operations you want to include`]: `Vyberte operace, které chcete zahrnout.`,
  [`Select organization`]: `Vybrat organizaci`,
  [`Select the API definition or package file to import`]: `Vyberte definici rozhraní API nebo soubor balíku, který má být importován`,
  [`Select the billing integration for this catalog`]: `Vyberte integraci s platebním systémem pro tento katalog`,
  [`Select the billing integration for this product`]: `Vyberte integraci s platebním systémem pro tento produkt.`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Vyberte brány, které zabezpečí rozhraní API v novém katalogu. Chcete-li zaregistrovat novou bránu, přejděte na stránku **Brány**.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Vyberte službu [Certificate Manager]({link}), která spravuje certifikáty pro tuto bránu.`,
  [`Select the APIs to add to this product`]: `Vyberte rozhraní API, která mají být přidána do tohoto produktu.`,
  [`Select the OpenAPI definition file to import`]: `Vyberte definiční soubor OpenAPI, který se má importovat`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Vyberte akce životního cyklu produktu, které vyžadují schválení. Když je provedena jakákoli z vybraných akcí, vygeneruje se žádost o schválení; požadavek musí být, aby mohla být akce dokončena, schválen.`,
  [`Select the TLS protocol versions for this profile.`]: `Vyberte verze protokolu TLS pro tento profil.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Vyberte analytickou službu, kterou chcete přidružit ke službě brány.`,
  [`Select the consumer organization in which you want to create the application`]: `Vyberte zákaznickou organizaci, ve které chcete vytvořit aplikaci.`,
  [`Select the consumer organization that will own the application`]: `Vyberte zákaznickou organizaci, která bude vlastnit aplikaci.`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Vyberte koncové body pro publikování tohoto rozhraní API a definování limit četnosti (v případě potřeby).`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Vyberte, které chyby vyvolané při provádění zásady způsobí zastavení toku sestavení. Jestliže je pro chybu nakonfigurován tok catch, spustí se za účelem řešení vyvolané chyby. Pokud dojde k vyvolání chyby a nejsou vybrány žádné chyby s nastavením na zastavení nebo pokud vyvolaná chyba není z vybraných chyb, je povoleno dokončit provedení zásady, a tok sestavení bude pokračovat.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Vyberte služby brány, ve kterých mají být zpřístupněna rozhraní API v produktu.`,
  [`Select the gateway type for this API`]: `Vyberte typ brány pro toto rozhraní API.`,
  [`Select the gateway type for this OAuth provider`]: `Vyberte typ brány pro tohoto poskytovatele OAuth.`,
  [`Select the keystores and truststores for this profile.`]: `Vyberte úložiště klíčů a úložiště údajů o důvěryhodnosti pro tento profil.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Vyberte organizace nebo skupiny, které chcete zviditelnit pro tento produkt.`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Vyberte organizace nebo skupiny, které chcete přihlásit k odběru tohoto produktu.`,
  [`Select the portal service to use for this catalog`]: `Vyberte portálovou službu k použití pro tento katalog:`,
  [`Select the product and plan for the application subscription`]: `Vyberte produkt a plán pro odběr aplikace.`,
  [`Select the product definition file to import`]: `Vyberte definiční soubor produktu, který chcete importovat.`,
  [`Select the product definition file to import from`]: `Vyberte soubor definice produktu, ze kterého se má importovat.`,
  [`Select the product that will supersede the originally selected product`]: `Vybrat produkt, který nahradí původně vybraný produkt`,
  [`Select the product that you want to add the API to`]: `Vybrat produkt, do kterého chcete přidat rozhraní API`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Vybrat registry, které chcete použít pro řízení přístupu k rozhraním API v tomto katalogu`,
  [`Select the required operations`]: `Vybrat požadované operace`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Vyberte úroveň závažnosti pro zprávy protokolu, které se týkají vstupních dat; možné úrovně jsou chyba, varování a info.`,
  [`Select the target wsdl file to create from`]: `Vyberte cílový soubor WSDL, který má být zdrojem pro vytvoření.`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Vyberte cílový soubor wsdl, xsd nebo zip, ze kterého se má vytvořit definice rozhraní API`,
  [`Select the target WSDL file to import`]: `Vyberte cílový soubor WSDL, který se má importovat`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Vyberte registry uživatelů pro ověření uživatelů produktu API Manager nebo vytvořte nový registr uživatelů.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Vyberte registry uživatelů pro ověření uživatelů produktu API Manager nebo vytvořte nový registr uživatelů.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Vyberte registry uživatelů pro ověření uživatelů nástroje Cloud Manager nebo vytvořte nový registr uživatelů.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Vybrat registry uživatelů, které se používají k ověření přihlášení k nástroji Developer Portal`,
  [`Select the user registry type`]: `Vybrat typ registru uživatelů`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Vyberte, kam by měla ve výstupním zdroji zásada umístit generovaný token LTPA. V záhlaví WSSec by mělo být vybráno pouze v případě, že typ obsahu požadavku je application/xml.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Vyberte, zda má být vlastnost zcela odebrána, nebo má být redigováno pole pomocí "*" pro blokování dat.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Vybraný produkt byl změněn. Obnovte prosím tuto stránku a zkuste to znovu.`,
  [`Selecting operations from WSDL service definition`]: `Výběr operací z definice služby WSDL`,
  [`Self Service Onboarding for Consumer organization`]: `Samoobslužné zahrnutí pro zákaznickou organizaci`,
  [`Self service onboarding`]: `Samoobslužné zahrnutí`,
  [`Self service onboarding approval`]: `Schválení samoobslužného zahrnutí`,
  [`Send`]: `Odeslat`,
  [`Send Message`]: `Odeslat zprávu`,
  [`Send Message to Owners`]: `Odeslat zprávu vlastníkům`,
  [`Send To`]: `Odeslat subjektu`,
  [`Send a registration link to a new user to register as owner.`]: `Odešle registrační odkaz na nového uživatele, který se zaregistruje jako vlastník.`,
  [`Send a test email`]: `Odeslat testovací e-mail`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Odešlete testovací e-mail a ověřte, že je e-mailový server správně nakonfigurovaný.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Odešlete zkušební signál k potvrzení, že je koncový bod správně nakonfigurován.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Odeslat zkušební signál k potvrzení, že je koncový bod správně nakonfigurován`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Odešlete e-mailovou zprávu vlastníkovi administrátorské organizace. Umístit zaškrtnutí vedle jména vlastníka`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Odeslat e-mailovou zprávu vlastníkovi Umístit zaškrtnutí vedle jména vlastníka`,
  [`Send chunked-encoded documents to the target server.`]: `Odeslat dokumenty na cílový server kódovaně po blocích.`,
  [`Send message`]: `Odeslat zprávu`,
  [`Send message to owner`]: `Odeslat zprávu vlastníkovi`,
  [`Send test email`]: `Odeslat testovací e-mail`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Odešlete aktivační odkaz uživateli jako pozvánku k přihlášení nebo registraci.`,
  [`Send-only`]: `Pouze odeslat`,
  [`Sender`]: `Odesílatel`,
  [`Sender & email server`]: `Odesílatel a e-mailový server`,
  [`Sender & email server have been changed`]: `Odesílatel a e-mailový server byl změněn.`,
  [`Sender Info`]: `Informace o odesílateli`,
  [`Sent`]: `Odesláno`,
  [`Sent events: **{sent}**`]: `Odeslané události: **{sent}**`,
  [`Serial`]: `Sériové číslo`,
  [`Serialize output`]: `Serializovat výstup`,
  [`Server connection`]: `Připojení serveru`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `Předávat kód HTML webovým prohlížečům pro povolení klienta GUI GraphikQL`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Předávat kód HTML webovým prohlížečům pro povolení klienta GUI GraphikQL.`,
  [`Server Name Indication (SNI)`]: `Indikace SNI (Server Name Indication)`,
  [`Server URL`]: `Adresa URL serveru`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Server vrátil chybu v hašování místo tokenu v adrese URL přesměrování OIDC. Hašování:`,
  [`Service name`]: `Název služby`,
  [`Services`]: `Služby`,
  [`Set`]: `Nastavit`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Nastavte proměnnou prostředí APIC_DESIGNER_CREDENTIALS OS pro nástroj API Designer. Např. v systému MAC může proměnnou prostředí uživatel předávat při spuštění nástroje API Designer.`,
  [`Set Migration Target`]: `Nastavit cíl migrace`,
  [`Set migration target`]: `Nastavit cíl migrace`,
  [`Set Variable`]: `Nastavit proměnnou`,
  [`Set as default`]: `Nastavit jako výchozí`,
  [`Set default`]: `Nastavit výchozí`,
  [`Set rate and burst limits in an assembly`]: `Nastavit limity četnosti a dávky v sestavení`,
  [`Set the timeout period for invitations`]: `Nastavte časový limit pro pozvánky.`,
  [`Set the trust level for the profile`]: `Nastavte úroveň důvěryhodnosti pro profil.`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Nastavte hodnotu na povolený počet iterací u cyklické definice schématu (výchozí hodnota je 1, maximum 5).`,
  [`Set the visibility for a gateway service`]: `Nastavte viditelnost pro službu brány.`,
  [`Set the visibility for a portal service`]: `Nastavte viditelnost pro portálovou službu.`,
  [`Set the visibility for each service`]: `Nastavit viditelnost pro každou službu`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Nastavte časový rámec pro získání nového přístupového tokenu vydaného ověřovacím serverem.`,
  [`Set visibility`]: `Nastavit viditelnost`,
  [`Set visibility of gateway`]: `Nastavit viditelnost brány`,
  [`Set time interval`]: `Nastavit časový interval`,
  [`Set, Add, or Clear a runtime variable.`]: `Nastavit, přidat nebo vymazat běhovou proměnnou.`,
  [`Set the timeout period for API key`]: `Nastavte období časového limitu pro klíč rozhraní API.`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Nastavuje dobu platnosti dokumentů v mezipaměti (v sekundách). Platí pouze tehdy, je-li typ mezipaměti nastaven na hodnotu "Doba životnosti". Zadejte hodnotu v rozsahu 5-31708800. Výchozí hodnota je 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `Nastavení hodnoty pro "assumedSize" nelze kombinovat s definicí argumentu ořezání, který má výchozí hodnotu, nebo s povolením "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Nastavení tohoto katalogu jako výchozího katalogu umožní přístup k tomuto katalogu pomocí kratší adresy URL při vynechání názvu katalogu. Jako výchozí hodnotu lze nastavit pouze jeden katalog.`,
  [`Settings`]: `Nastavení`,
  [`Setup`]: `Nastavení`,
  [`Severity level for input data log messages`]: `Úroveň závažnosti pro zprávy protokolu vstupních dat`,
  [`Share the resource with a custom list of provider organizations`]: `Sdílet prostředek s vlastním seznamem organizací poskytovatele`,
  [`Share the resource with provider organizations`]: `Sdílet prostředek s organizacemi poskytovatele`,
  [`Share the API gateway with a custom list of provider organizations`]: `Sdílet službu API Gateway s vlastním seznamem organizací poskytovatele`,
  [`Share the service with a custom list of provider organizations`]: `Sdílet službu s vlastním seznamem organizací poskytovatele`,
  [`Share the API gateway with provider organizations`]: `Sdílet službu API Gateway s organizacemi poskytovatele`,
  [`Share the service with provider organizations`]: `Sdílet službu s organizacemi poskytovatele`,
  [`Share`]: `Sdílet`,
  [`Shared`]: `Sdílené`,
  [`Shared by`]: `Sdíleno uživatelem`,
  [`Short but descriptive name for this policy.`]: `Krátký, ale popisný název pro tuto zásadu.`,
  [`Show`]: `Zobrazit`,
  [`Show / hide policy palette`]: `Zobrazit/skrýt paletu zásad`,
  [`Show/hide`]: `Zobrazit/skrýt`,
  [`Show/hide settings`]: `Zobrazit/skrýt nastavení`,
  [`Show and hide types and fields to include in the schema.`]: `Zobrazte a skryjte typy a pole pro zahrnutí do schématu.`,
  [`Show added only`]: `Zobrazit pouze přidané`,
  [`Show all`]: `Zobrazit vše`,
  [`Show all UDP versions`]: `Zobrazit všechny verze UDP`,
  [`Show app identifier`]: `Zobrazit identifikátor aplikace`,
  [`Show breaking changes only`]: `Zobrazit pouze změny způsobující chybu`,
  [`Show catalog identifier`]: `Zobrazit identifikátor katalogu`,
  [`Show catches`]: `Zobrazit zachycené položky`,
  [`Show in schema`]: `Zobrazit ve schématu`,
  [`Show less`]: `Zobrazit méně`,
  [`Show modified only`]: `Zobrazit pouze upravené`,
  [`Show more`]: `Zobrazit více`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Zobrazit nové typy, pole, direktivy nebo volitelné argumenty. Položky, které již nejsou back-endovým systémem podporovány, budou stále odebrány.`,
  [`Show removed only`]: `Zobrazit pouze odebrané`,
  [`Show schema`]: `Zobrazit schéma`,
  [`Show warnings only`]: `Zobrazit pouze varování`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Zobrazuje historii přiřazení úložiště klíčů s výjimkou momentálně přiřazeného úložiště klíčů.`,
  [`Sign Crypto Object`]: `Kryptografický objekt pro podpis`,
  [`Sign in`]: `Přihlásit`,
  [`Sign JWK variable name`]: `Název proměnné JWK pro podpis`,
  [`Sign Out`]: `Odhlásit`,
  [`Sign up`]: `Zaregistrovat`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Přihlásit se pomocí registru uživatelů {userRegistryType}`,
  [`Sign in with`]: `Přihlásit se pomocí`,
  [`Sign out`]: `Odhlásit`,
  [`Sign up with`]: `Registrovat se pomocí`,
  [`Signed token`]: `Podepsaný token`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Protože je použití vyrovnávací paměti nastaveno na vypnuto, je nezbytné, aby byl obsah či chybový obsah snížen z informačního obsahu na záhlaví. Chcete pokračovat s požadovanou změnou, anebo poslední změnu zrušit?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Protože je obsah nebo chybový obsah nastaven na informační obsah, je zapotřebí nastavit použití vyrovnávací paměti na zapnuto. Chcete pokračovat s požadovanou změnou, anebo poslední změnu zrušit?`,
  [`Sized fields`]: `Pole vhodné velikosti`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Argumenty ořezání`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Některé vstupy jsou pole nebo jsou obsaženy v polích. Chcete-li iterovat nad obsahem, vyberte volbu iterace a zvolte vstup, nad kterým se má iterovat.`,
  [`Something went wrong.`]: `Něco se pokazilo.`,
  [`Something went wrong...`]: `Došlo k potížím...`,
  [`Source`]: `Zdroj`,
  [`Source schema`]: `Zdrojové schéma`,
  [`Space`]: `Prostor`,
  [`Space is not allowed in scope name`]: `Prostor není povolen v názvu rozsahu`,
  [`Space details`]: `Podrobnosti prostoru`,
  [`Space Enabled`]: `Prostor povolen`,
  [`Space Name`]: `Název prostoru`,
  [`Space Summary`]: `Souhrn prostoru`,
  [`Space Title`]: `Název prostoru`,
  [`Space owner`]: `Vlastník prostoru`,
  [`Space {name} created`]: `Prostor {name} byl vytvořen`,
  [`Spaces`]: `Prostory`,
  [`Spaces are enabled`]: `Prostory jsou povoleny`,
  [`Spaces are not enabled`]: `Prostory nejsou povoleny`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Nelze povolit prostory pro katalog sandboxu.`,
  [`Spaces cannot be enabled.`]: `Mezery nelze povolit.`,
  [`Spaces dropdown`]: `Rozevírací nabídka prostorů`,
  [`Spaces has been enabled`]: `Prostory byly povoleny`,
  [`Spaces have been enabled.`]: `Prostory byly povoleny.`,
  [`Spaces per page`]: `Prostory na stránku`,
  [`Spanish`]: `Španělština`,
  [`Special characters are not accepted`]: `Speciální znaky nejsou povoleny`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Určuje adresu URL nebo řetězec, který představuje pojmenovaný kontext, ze kterého se mají načíst serializované vlastnosti XML nebo JSON, které se slučují do dynamického objektu. Tyto vlastnosti mají přednost před všemi existujícími literálovými nebo výchozími vlastnostmi.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Určuje literálový řetězec jako serializované vlastnosti XML nebo JSON, které se slučují do dynamického objektu. Tyto vlastnosti mají přednost před všemi existujícími výchozími vlastnostmi.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Uvádí existující platný objekt, ze kterého se mají načíst výchozí hodnoty vlastností pro dynamický objekt. Varování: Pokud změníte výchozí řetězec, zadaný objekt nastavení analýzy musí být k dispozici na zařízení DataPower.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Určuje zdroj dat, který obsahuje obsah k redigování nebo odebrání. Není-li kořen zadán, použije se akce na celý kontext rozhraní API. Můžete použít libovolný podporovaný výraz cesty JSONata.\n\nRedakce dat požadavku a odpovědi může být provedena s kořenovým prvkem "message.body". Je-li akce redigování sestavení použita v sestavení po akci protokolu sestavení, která uvádí režim pouze pro shromažďování, může kořen určit "log.request_body" pro informační obsah zaprotokolovaného požadavku nebo "log.response_body" pro zaznamenaný informační obsah odpovědi.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Určuje název proměnné v kontextu rozhraní API. Obsah pole "text" proměnné je vstupem dané akce. Výchozí název proměnné je "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Určuje název proměnné v kontextu rozhraní API. Obsah pole "tělo" proměnné je výstupem analýzy. Metriky analýzy analyzovaného dokumentu mohou být uloženy v jiné části zprávy. Výchozí název proměnné je jakýkoli vstupní název, takže při výchozím nastavení bude vstupní zpráva přepsána výstupní zprávou.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Uvádí, zda a kde získat vlastní metadata pro přístupový token.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Určuje, zda se má provést pokus o analýzu s určeným typem obsahu. To platí pouze v případě, že očekávaný typ obsahu je buď json, nebo xml. Pokud je tato volba zapnuta, výsledkem akce analýzy bude selhání, pokud typ obsahu neodpovídá nastavení analýzy (tj. Content-type:application/json a nastavení analýzy je nakonfigurováno pro xml). Je-li nastavení analýzy nakonfigurováno pro zjišťování obsahu vstupu, bude vynucena na určený typ obsahu.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Uvádí, zda se má po transformaci serializovat strom výstupu transformace XSL na binární data.`,
  [`Specify default gateways`]: `Určit výchozí brány`,
  [`Specify email`]: `Zadat e-mail`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Určete definici schématu OpenAPI (Swagger), se kterou se má ověřit informační obsah ("požadavek", "odpověď" nebo "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Určete vlastníka zákaznické organizace.`,
  [`Specify owner of the provider organization`]: `Určete vlastníka organizace poskytovatele.`,
  [`Specify settings for the third party OAuth provider.`]: `Určete nastavení pro poskytovatele OAuth třetí strany.`,
  [`Specify the MIME types that your API consumes and produces`]: `Určit typy MIME, které rozhraní API spotřebovává a produkuje`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Zadat vlastníka katalogu; vlastník má všechna oprávnění ke katalogu`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Zadat zákaznické organizace nebo komunity vývojářů, kteří budou schopni zobrazit produkt v nástroji Developer Portal`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Zadejte zákaznické organizace nebo komunity vývojářů, které budou moci odebírat použití rozhraní API v produktu.`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Určit základní adresu URL koncového bodu vyvolání rozhraní API a domény zpracované bránou.`,
  [`Specify the details of the catalog that you want to publish to`]: `Zadat podrobnosti katalogu, do kterého chcete publikovat`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Určit výchozí sadu bran, které zabezpečí rozhraní API v novém katalogu. [Další informace]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Určit koncové body, na které má být toto rozhraní API publikováno, a zda chcete omezit četnost volání API`,
  [`Specify the new owner`]: `Určit nového vlastníka`,
  [`Specify the owner`]: `Zadat vlastníka`,
  [`Specify the owner and title`]: `Zadat vlastníka a název`,
  [`Specify the properties to redact or remove.`]: `Určete vlastnosti, které mají být redigovány nebo odebrány.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Určit požadavky na publikování a omezování četnosti pro toto rozhraní API`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Určete koncový bod kontroly rozsahu, kde se kromě definovaných rozsahů provádí další ověření rozsahu.`,
  [`Specify the security settings for the imported API definition`]: `Určit nastavení zabezpečení pro naimportovanou definici rozhraní API`,
  [`Specify the security settings for your API definition`]: `Určit nastavení zabezpečení pro definici rozhraní API`,
  [`Specify the space owner; the owner has all space permissions`]: `Zadat vlastníka prostoru; vlastník má všechna oprávnění k prostoru`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Zadejte souhrnné podrobnosti o rozhraní API; je-li to nutné, můžete definici rozhraní API po vytvoření dále konfigurovat.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Určit časový limit, po jehož uplynutí vyprší platnost e-mailů s pozvánkou, pokud uživatel neaktivuje svůj účet`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Určete časový limit, po jehož uplynutí vyprší platnost e-mailů s pozvánkou, pokud uživatel neaktivuje svůj účet.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Určete typ brány, název a souhrn popisující bránu.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Určete viditelnost služby API Gateway pro organizaci poskytovatele služby API Connect.`,
  [`Specify the way you want to validate the message.`]: `Určete způsob, jakým chcete zprávu ověřit.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Určete adresu URL koncového bodu správy brány a certifikát pro doménu koncového bodu.`,
  [`Specify what content to log in the event of an error.`]: `Určete, jaký obsah se má protokolovat v případě chyby.`,
  [`Specify what content to log.`]: `Určete, jaký obsah se má protokolovat.`,
  [`Specifying the publish target details`]: `Uvedení podrobností o cíli publikování`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Specificky povolit pravidlo xsi:type=SOAP-ENC:Array`,
  [`Stage`]: `Fázovat`,
  [`Stage API`]: `Fázovat rozhraní API`,
  [`Stage product`]: `Fázovat produkt`,
  [`Stage to`]: `Fázovat do`,
  [`Staged`]: `Fázováno`,
  [`Stage|permission`]: `Fázovat`,
  [`Standard OIDC`]: `Standardní OIDC`,
  [`Static DNS`]: `Statický server DNS`,
  [`Static group`]: `Statická skupina`,
  [`Status`]: `Stav`,
  [`Status code`]: `Stavový kód`,
  [`Step 1`]: `Krok 1`,
  [`Step 1: Download`]: `Krok 1: Stažení`,
  [`Step 1: Download toolkit`]: `Krok 1: Stažení sady nástrojů`,
  [`Step 1: Upload private key`]: `Krok 1: Odeslání soukromého klíče`,
  [`Step 2`]: `Krok 2`,
  [`Step 2: Download credentials`]: `Krok 2: Stažení pověření`,
  [`Step 2: Download toolkit credentials`]: `Krok 2: Stažení pověření sady nástrojů`,
  [`Step 2: Install`]: `Krok 2: Instalace`,
  [`Step 2: Upload public key`]: `Krok 2: Odeslání veřejného klíče`,
  [`Step 3`]: `Krok 3`,
  [`Step 3: Configure`]: `Krok 3: Konfigurace`,
  [`Step 3: Run command`]: `Krok 3: Spuštění příkazu`,
  [`Step 3: Run commands`]: `Krok 3: Spuštění příkazů`,
  [`Step 4: Register`]: `Krok 4: Registrace`,
  [`Stop`]: `Zastavit`,
  [`Stop after:`]: `Zastavit po:`,
  [`Stop all the retries`]: `Zastavit všechna opakování`,
  [`Stop Connection`]: `Zastavit připojení`,
  [`Stop on error`]: `Zastavit při chybě`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Zastavte spuštění tohoto rozhraní API a odeberte je z katalogu sandboxů.`,
  [`Stopped`]: `Zastaveno`,
  [`Stopping Published API`]: `Zastavení publikovaného rozhraní API`,
  [`Strict`]: `Striktní`,
  [`Strict SOAP envelope version`]: `Striktní verze obálky SOAP`,
  [`String Badgerfish`]: `Badgerfish - řetězec`,
  [`String`]: `Řetězec`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `Řetězec zahrnutý do segmentu organizace adresy URL pro volání rozhraní API. Použijte malá písmena abecedy, číslice a pomlčky.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Integrace systému Stripe`,
  [`Streaming rule`]: `Pravidlo streamování`,
  [`Subject`]: `Předmět`,
  [`Subject Claim`]: `Nárok subjektu`,
  [`Subject Origin`]: `Původ subjektu`,
  [`Subject common name`]: `Obecný název subjektu`,
  [`Subject|certificate`]: `Předmět`,
  [`Submit`]: `Odeslat`,
  [`Submitted`]: `Odesláno`,
  [`Subscribability`]: `Možnost přihlášení k odběru`,
  [`Subscribability must be disabled if visibility is disabled`]: `Možnost přihlášení k odběru musí být zakázána, je-li zakázána viditelnost`,
  [`Subscribability options`]: `Volby možnosti přihlášení k odběru`,
  [`Subscribable by`]: `Odebíratelný`,
  [`Subscribe`]: `Odebírat`,
  [`Subscribe Financial Management App`]: `Odebírat aplikaci pro správu financí`,
  [`Subscribe TODO App`]: `Odběr aplikace TODO`,
  [`Subscription`]: `Odběr`,
  [`Subscription approval task for subscription of`]: `Úloha schválení odběru pro odběr`,
  [`Subscription created`]: `Odběr byl vytvořen`,
  [`Subscription has been added`]: `Odběr byl přidán`,
  [`Subscription has not been added`]: `Odběr nebyl přidán`,
  [`Subscription pending approval`]: `Nevyřízené schválení odběru`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `Typ odběru byl odebrán, protože odběry GraphQL nejsou podporovány.`,
  [`Subscription-Approval`]: `Schválení odběru`,
  [`Subscriptions`]: `Odběry`,
  [`Success`]: `Úspěch`,
  [`Suffix`]: `Přípona`,
  [`Suggestion`]: `Návrh`,
  [`Supersede`]: `Nahradit`,
  [`Supersede Product`]: `Nahradit produkt`,
  [`Supersede product`]: `Nahradit produkt`,
  [`Supersede plans`]: `Nahradit plány`,
  [`Supersede {product1} with {product2}`]: `Nahradit {product1} za {product2}`,
  [`Supersede|permission`]: `Nahradit`,
  [`Supersede|supersede with`]: `Nahradit`,
  [`Support`]: `Podpora`,
  [`support default introspection`]: `Podporovat výchozí introspekci`,
  [`Support Server Name Indication (SNI)`]: `Podporovat indikaci SNI (Server Name Indication)`,
  [`Support hybrid response types`]: `Podporovat hybridní typy odezvy`,
  [`Support standard introspection`]: `Podporovat standardní introspekci`,
  [`Supported OAuth components`]: `Podporované komponenty OAuth`,
  [`Supported client types`]: `Podporované typy klientů`,
  [`Supported client types can not be empty.`]: `Podporovaný typ klienta nemůže být prázdný.`,
  [`Supported grant type can not be empty.`]: `Podporovaný typ udělení nemůže být prázdný.`,
  [`Supported grant types`]: `Podporované typy udělení`,
  [`Suspend`]: `Pozastavit`,
  [`Suspend API`]: `Pozastavit rozhraní API`,
  [`Suspend Application`]: `Pozastavit žádost`,
  [`Switch`]: `Přepínač`,
  [`Switch account`]: `Přepnout účet`,
  [`Switch cloud connection`]: `Přepnout produkt Cloud Connection`,
  [`Switch directory`]: `Přepnout adresář`,
  [`TARGET`]: `CÍL`,
  [`Title`]: `Název`,
  [`TlS`]: `Protokol TLS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `Klient TLS`,
  [`TLS Client Profile`]: `Profil klienta TLS`,
  [`TLS Client Profile has been configured.`]: `Profil klienta TLS byl nakonfigurován.`,
  [`TLS client profile is required when endpoint starts with https://`]: `Profil klienta TLS je vyžadován, když koncový bod začíná na https://`,
  [`TLS Client Profiles`]: `Profily klienta TLS`,
  [`TLS client profile has been configured.`]: `Profil klienta TLS byl nakonfigurován.`,
  [`TLS client profiles`]: `Profily klienta TLS`,
  [`TLS Profile`]: `Profil TLS`,
  [`TLS SERVER PROFILE`]: `PROFIL SERVERU TLS`,
  [`TLS server profile`]: `Profil serveru TLS`,
  [`TLS version 1.0`]: `TLS, verze 1.0`,
  [`TLS version 1.1`]: `TLS, verze 1.1`,
  [`TLS version 1.2`]: `TLS, verze 1.2`,
  [`TLS version 1.3`]: `TLS, verze 1.3`,
  [`TLS client profile`]: `Profil klienta TLS`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `Profily klienta TLS jsou spravovány v každém prostoru, jsou-li prostory povoleny. Přejděte do nastavení prostoru pro správu profilů klienta TLS.`,
  [`TLS profile`]: `Profil TLS`,
  [`TLS profile is not visible or not available.`]: `Profil TLS není viditelný nebo není k dispozici.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `Profil TLS, který se má použít pro zabezpečený přenos dat do ověřovací adresy URL.`,
  [`Tabs for code language options`]: `Karty pro volby jazyka kódu`,
  [`Truststore`]: `Úložiště údajů o důvěryhodnosti`,
  [`truststore`]: `úložiště údajů o důvěryhodnosti`,
  [`Type`]: `Typ`,
  [`Type name`]: `Název typu`,
  [`Type/Field`]: `Typ/pole`,
  [`Type weight`]: `Váha typu`,
  [`Types per page:`]: `Typy na stránku:`,
  [`Tags`]: `Značky`,
  [`Tags and external documentation`]: `Značky a externí dokumentace`,
  [`Take offline`]: `Převést do stavu offline`,
  [`Target`]: `Cíl`,
  [`Target API (URL)`]: `Cílové rozhraní API (URL)`,
  [`Target SOAP service`]: `Cílová služba SOAP`,
  [`Target Services`]: `Cílové služby`,
  [`Target service URL`]: `Adresa URL cílové služby`,
  [`Target services`]: `Cílové služby`,
  [`Target URL`]: `Cílová adresa URL`,
  [`Target application`]: `Cílová aplikace`,
  [`Target catalog`]: `Cílový katalog`,
  [`Target gateway service`]: `Cílová služba brány`,
  [`Target gateway services`]: `Služby cílové brány`,
  [`Target plan`]: `Cílový plán`,
  [`Target product`]: `Cílový produkt`,
  [`Target product rate limit`]: `Limit četnosti cílového produktu`,
  [`Target product subscribability`]: `Možnost přihlášení k odběru cílového produktu`,
  [`Target product visibility`]: `Viditelnost cílového produktu`,
  [`Target schema`]: `Cílové schéma`,
  [`Target services have been updated.`]: `Cílová služba byla aktualizována.`,
  [`Task`]: `Úloha`,
  [`Task has been updated`]: `Úloha byla aktualizována`,
  [`Task self approval`]: `Samoschválení úlohy`,
  [`Task self-approval`]: `Vlastní schválení úlohy`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Úlohy`,
  [`Tasks for creating new provider organizations`]: `Úlohy pro vytvoření nových organizací poskytovatele`,
  [`TCP`]: `Protokol TCP`,
  [`Template`]: `Šablona`,
  [`Template: {title}`]: `Šablona: {title}`,
  [`Template: account approved`]: `Šablona: účet schválen`,
  [`Template: account denied`]: `Šablona: účet odepřen`,
  [`Template: account pending approval`]: `Šablona: účet čeká na schválení`,
  [`Template: app lifecycle approved`]: `Šablona: životní cyklus aplikace schválen`,
  [`Template: app lifecycle denied`]: `Šablona: životní cyklus aplikace odepřen`,
  [`Template: app lifecycle pending`]: `Šablona: životní cyklus aplikace čeká na vyřízení`,
  [`Template: app reinstated`]: `Šablona: aplikace obnovena`,
  [`Template: app suspended`]: `Šablona: aplikace pozastavena`,
  [`Template: app lifecycle cancelled`]: `Šablona: životní cyklus aplikace zrušen`,
  [`Template: app lifecycle request`]: `Šablona: požadavek životního cyklu aplikace`,
  [`Template: invitation`]: `Šablona: pozvánka`,
  [`Template: mail server test connection`]: `Šablona: test připojení poštovního serveru`,
  [`Template: member invitation`]: `Šablona: pozvánka člena`,
  [`Template: password changed`]: `Šablona: heslo změněno`,
  [`Template: password reset`]: `Šablona: reset hesla`,
  [`Template: sign up`]: `Šablona: registrace`,
  [`Template: task consumer onboard request`]: `Šablona: úloha požadavku na zahrnutí zákazníka`,
  [`Template: task product lifecycle approved`]: `Šablona: úloha schválení životního cyklu produktu`,
  [`Template: task product lifecycle cancelled`]: `Šablona: úloha zrušení životního cyklu produktu`,
  [`Template: task product lifecycle denied`]: `Šablona: úloha odepření životního cyklu produktu`,
  [`Template: task product lifecycle pending`]: `Šablona: úloha nevyřízeného životního cyklu produktu`,
  [`Template: task product lifecycle request`]: `Šablona: úloha požadavku životního cyklu produktu`,
  [`Template: task subscription cancelled`]: `Šablona: úloha zrušení odběru`,
  [`Template: task subscription request`]: `Šablona: úloha požadavku na odběr`,
  [`Template: task subscription approved`]: `Šablona: úloha schválení odběru`,
  [`Template: task subscription denied`]: `Šablona: úloha odepření odběru`,
  [`Template: task subscription pending`]: `Šablona: úloha nevyřízeného odběru`,
  [`Temporarily disable subscribability`]: `Dočasně zakázat možnost přihlášení k odběru`,
  [`Temporarily disable visibility`]: `Dočasně zakázat viditelnost`,
  [`Terms of service`]: `Podmínky služby`,
  [`Test`]: `Test`,
  [`Test API`]: `Testovat rozhraní API`,
  [`Test APIs`]: `Testovat rozhraní API`,
  [`Test Bind & Get Base DN`]: `Testovat vazbu & získat základní DN`,
  [`Test configuration`]: `Testovat konfiguraci`,
  [`Test connection`]: `Testovat připojení`,
  [`Test connection failed.`]: `Test připojení se nezdařil.`,
  [`Test and production endpoints`]: `Koncové body testování a produkce`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Testovat a monitorovat chybu odezvy: vlastnost redirect_uri je povinná, příkaz got {redirectUri}`,
  [`Test application`]: `Testovací aplikace`,
  [`Test consumer org`]: `Testovací zákaznická organizace`,
  [`Test email`]: `Testovací e-mail`,
  [`Test endpoints`]: `Koncové body testování`,
  [`Test message from IBM API Connect`]: `Testovací zpráva ze služby IBM API Connect`,
  [`Testable`]: `Testovatelné`,
  [`TEXT`]: `TEXT`,
  [`The "Definition" property must have a valid value.`]: `Vlastnost "Definice" musí mít platnou hodnotu.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `Vlastnost "Definice" musí mít hodnotu "požadavek", "odpověď" nebo začínat s "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `Rozhraní CLI služby API Connect je nabízeno jako modul plug-in rozhraní CLI služby IBM Cloud. Chcete-li začít:`,
  [`The API has unsaved changes.`]: `Rozhraní API má neuložené změny.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `Rozhraní API bylo zcela navrženo a předáno jako interní milník, ale ještě není implementováno.`,
  [`The API is in the implementation phase.`]: `Rozhraní API je ve fázi implementace.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `Rozhraní API je v počáteční koncepční fázi a není ani plně navrženo, ani implementováno.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `Rozhraní API může mít nepublikované změny. Chcete znovu publikovat produkt?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `Schéma DNS určuje složení adres URL koncového bodu pro příchozí volání API do služby brány a pro přístup k nástroji Developer Portal.`,
  [`The GatewayScript source code to execute.`]: `Zdrojový kód GatewayScript, který má být proveden.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `Adresa URL schématu GraphQL, se kterou musí být ověřena platnost informačního obsahu protokolu GraphQL.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Metoda HTTP, která má být použita pro vyvolání. Pokud je vynechána nebo nastavena na "Ponechat", použije se metoda z příchozího požadavku.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Metoda HTTP, která se má použít k nahrazení aktuální metody HTTP. Pokud je vynechána nebo nastavena na "Ponechat", použije se metoda z příchozího požadavku.`,
  [`The JavaScript source code to execute.`]: `Zdrojový kód JavaScript, který má být proveden.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `Protokol LDAP (Lightweight Directory Access Protocol) je internetový protokol pro přístup a údržbu distribuovaných informačních služeb adresáře po síti. Máte-li povolenou podnikovou službu LDAP, nakonfigurujte ji jako prostředek, aby bylo možné poskytnout ověření uživatele.`,
  [`The Proxy URL to be used.`]: `Adresa URL serveru proxy, která má být použita.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `Profil SSL, který se má použít pro zabezpečený přenos dat.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `Profil TLS, který se má použít pro zabezpečený přenos dat.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `Adresa URL schématu JSON pro ověření platnosti informačního obsahu JSON.`,
  [`The URL of the XML schema for validating an XML payload.`]: `Adresa URL schématu XML pro ověření platnosti informačního obsahu XML.`,
  [`The URL to be invoked.`]: `Adresa URL, která má být vyvolána.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `Adresa URL schématu WSDL, se kterou musí být ověřena platnost informačního obsahu protokolu SOAP.`,
  [`The XML element name to be used for JSON array elements.`]: `Název prvku XML, který se má použít pro prvky pole JSON.`,
  [`The XSLT source to execute.`]: `Zdroj XSLT, který se má provést.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `Aktivační odkaz může být odeslán uživateli, aby umožnil aktivaci jejich účtu`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `Analytická služba shromažďuje události rozhraní API ze služby brány. Každá brána má přidruženou analytickou službu. Událost rozhraní API s časovým razítkem se zaprotokoluje při každém vyvolání operace rozhraní API.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `Analytická služba shromažďuje události rozhraní API ze služby brány. Každá služba brány může mít přidruženou analytickou službu.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `Adresa URL autorizace je místo, kde aplikace klienta získá udělení autorizace. Adresa URL tokenu je místo, kde aplikace klienta vyměňuje udělení autorizace pro přístupový token. Adresa URL introspekce je místo, kde služba API Gateway ověřuje přístupové tokeny, které jsou vydány poskytovatelem třetí strany.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `Základní cesta je počáteční segment adresy URL rozhraní API a nezahrnuje název hostitele ani žádné další segmenty pro cesty nebo operace.`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `Typ mezipaměti určuje, zda ukládat do mezipaměti dokumenty při dodržení nebo potlačení direktiv řízení mezipaměti HTTP, které byly přijaty z odpovědi serveru. Tato vlastnost se projeví pouze, když je ze serveru přijata odpověď, jinak zásada vždy vrátí odpověď bez vypršené platnosti, která byla předtím uložena do mezipaměti.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Služba správce certifikátů musí spravovat certifikáty pro doménu koncového bodu správy brány a domény, které brána zpracovává. Navíc musíte použít IBM Cloud IAM, abyste autorizovali službu API Connect Reserved Instance pro přístup k službě správce certifikátů.`,
  [`The certificate with which to decrypt the buffer`]: `Certifikát, pomocí kterého se dešifruje vyrovnávací paměť`,
  [`The certificate with which to encrypt the buffer`]: `Certifikát, pomocí kterého se má šifrovat vyrovnávací paměť`,
  [`The change is critical`]: `Změna je kritická`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Aplikace klienta mohou požadovat pouze rozsahy nebo dílčí sadu rozsahů, které zde nadefinujete. Rozsahy jsou zahrnuty v přístupových tokenech, které jsou generovány od poskytovatele. Při vyvolání chráněného rozhraní API OAuth brána kontroluje rozsahy přenášené přístupovými tokeny na seznamu povolených rozsahů v definici zabezpečení, aby se určilo, zda se má udělit přístup. Kromě toho můžete vynutit rozšířenou kontrolu rozsahu. Adresy URL rozšířené kontroly rozsahu jsou vyvolány po ověření aplikace nebo po ověření uživatele podle toho, které adresy URL jsou nakonfigurovány. Konečné oprávnění pro rozsah, které je udělováno přístupovým tokenem, je výsledkem všech kontrol rozsahu.`,
  [`The crypto object to use to decode the claim.`]: `Kryptografický objekt, který se má použít k dekódování nároku.`,
  [`The crypto object to use to encrypt the message.`]: `Kryptografický objekt, který se má použít k šifrování zprávy.`,
  [`The crypto object to use to sign the JWT.`]: `Kryptografický objekt, který se má použít k podepsání JWT.`,
  [`The crypto object to use to verify the signature.`]: `Kryptografický objekt, který se má použít k ověření podpisu.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `Aktuální mapování obsahuje odkazy na nerozpoznané prvky schématu.`,
  [`The current organization does not contain any catalogs.`]: `Aktuální organizace neobsahuje žádné katalogy.`,
  [`The default User registry must be included in the list`]: `Výchozí registr uživatelů musí být zahrnut do seznamu`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `Definice, proti které musí být ověřena platnost informačního obsahu ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Nástroj Developer Portal poskytuje pohled odběratele publikovaných produktů API. Po nakonfigurování nástroje Developer Portal pro váš katalog a publikování jednoho nebo více produktů API mohou vývojáři aplikací procházet a používat rozhraní API.`,
  [`The document is missing draft_api.info.version field.`]: `V dokumentu chybí draft_api.info.version.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `V dokumentu chybí pole draft_api.info.x-ibm-name.`,
  [`The document is missing draft_product.info.name field.`]: `V dokumentu chybí pole draft_product.info.name.`,
  [`The document is missing draft_product.info.version field.`]: `V dokumentu chybí draft_product.info.version.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `Další omezení určují argument ořezání, který není argumentem pro toto pole.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Toto pole má argument s omezením s výchozí hodnotou. Proto se daná předpokládaná velikost nikdy nemůže použít.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Toto pole má argument s omezením s výchozí hodnotou nebo je povinné. Proto se daná předpokládaná velikost nikdy nemůže použít.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Toto pole nemá ani předpokládanou velikost ani žádné argumenty ořezání.`,
  [`This field returns unbound list of values with composite type.`]: `Toto pole vrací nevázaný seznam hodnot se složeným typem.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Toto pole vrací nevázaný seznam hodnot se skalárním typem a váhou typu != 0.0.`,
  [`This test panel is deprecated and will be removed soon.`]: `Tento testovací panel je zamítnutý a brzy bude odebrán.`,
  [`This type has no fields or no matching field.`]: `Tento typ neobsahuje žádná pole nebo žádné odpovídající pole.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Tato zásada GatewayScript by neměla používat modul apim, který je určen pouze pro migraci starých rozhraní API.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Tato zásada XSLT by neměla používat modul apim, který je určen pouze pro migraci starých rozhraní API.`,
  [`This type will be deleted.`]: `Tento typ bude odstraněn.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `Následující diagram ukazuje tok sestavení pro vaše rozhraní API. Chcete-li upravit sestavení, použijte editor sestavení.`,
  [`The following files are available under the`]: `Následující soubory jsou k dispozici v části:`,
  [`The following files contain a private key and cannot be uploaded:`]: `Následující soubory obsahují soukromý klíč a nelze je odeslat:`,
  [`The following is available under the`]: `Následující informace jsou k dispozici v části:`,
  [`The following lifecycle actions require approval`]: `Následující akce životního cyklu vyžadují schválení.`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `Následující seznam zobrazuje rozpracovaná rozhraní API, která byla vytvořena v této organizaci poskytovatele.`,
  [`The following list displays the draft products that have been created in this provider organization`]: `Následující seznam zobrazuje koncepty produktů, které byly vytvořeny v této organizaci poskytovatele.`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `Následující seznam zobrazuje všechny uživatele, kteří byli přidáni jako členové tohoto katalogu, a jejich přiřazené role`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `Následující seznam zobrazuje všechny uživatele, kteří byli přidáni jako členové tohoto prostoru, a jejich přiřazené role`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `Následující parametry se neshodují s hodnotami nakonfigurovanými v poskytovateli OAuth`,
  [`The following roles are available in the Cloud Manager`]: `V nástroji Cloud Manager jsou k dispozici následující role`,
  [`The following types/fields will also be removed:`]: `Budou také odebrány následující typy/pole:`,
  [`The following types/fields will be affected:`]: `Budou ovlivněny následující typy/pole:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `Hodnota vlastnosti brány {gatewayType} není povolena. Přípustné hodnoty jsou datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `Hostitel používaný k vyvolání API.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `Úložiště klíčů obsahuje odpovídající páry veřejných certifikátů a soukromých klíčů, které slouží k potvrzení identity a k šifrování/dešifrování přenosu dat prostřednictvím protokolu HTTPS.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `Úložiště klíčů obsahuje odpovídající páry veřejných certifikátů a soukromých klíčů, které slouží k potvrzení identity a k šifrování/dešifrování přenosu dat prostřednictvím protokolu HTTPS. Profil serveru vyžaduje úložiště klíčů.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `Úložiště klíčů obsahuje veřejné klíče a soukromé certifikáty prezentované serverem nebo klientem k potvrzení identity.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `Doba (v sekundách), která je přidána k aktuálnímu datu a času, ve kterém je klíč LTPA považován za platný.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `Doba (v sekundách), která je přidána k aktuálnímu datu a času, ve kterém je JWT považován za platný.`,
  [`The message to be sent with the error`]: `Zpráva, která má být odeslána s chybou`,
  [`The message to be included within exception.`]: `Zpráva, která má být zahrnuta do výjimky.`,
  [`The HTTP status code to be included within exception.`]: `Stavový kód HTTP, který má být zahrnut do výjimky.`,
  [`The HTTP status reason phrase to be included within exception.`]: `Fráze příčiny stavu HTTP, která má být zahrnuta do výjimky.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Název pro limit dávky definovaný v konfiguraci Datapower.`,
  [`The name of a count limit defined in the datapower configuration.`]: `Název pro limit počtu definovaný v konfiguraci Datapower.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Název pro limit četnosti definovaný v konfiguraci Datapower.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `Název proměnné, která bude použita k uložení dat odpovědi z požadavku. Na tento název lze pak odkazovat v jiných akcích, jako např. "Mapa".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `Název proměnné, která bude použita k uložení výsledku. Ve výchozím nastavení se použije "message".`,
  [`The name of parameter to find the ID.`]: `Název parametru pro nalezení ID.`,
  [`The name of parameter to find the secret.`]: `Název parametru pro nalezení tajných údajů.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `Název klíče LTPA, který chcete použít ke generování tokenu LTPA. Chcete-li použít specifickou verzi klíče, zadejte: <keyname>:<version>. Například my-ltpa-key:1.0.1. Chcete-li, aby zásada automaticky vybrala nejnovější verzi klíče k použití, zadejte <keyname>:latest. Například my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `Název chyby, která se má vyvolat`,
  [`The name of the variable to be added.`]: `Název proměnné, která se má přidat.`,
  [`The name of the variable to be cleared.`]: `Název proměnné, která má být vymazána.`,
  [`The name of the variable to be set.`]: `Název proměnné, která se má nastavit.`,
  [`The nature of the XML validation to be performed.`]: `Povahu ověření XML, které má být provedeno.`,
  [`The operation to apply.`]: `Operace, která má být použita`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `Výstup tohoto mapování se nachází v poli a jeho obsah bude vytvořen iterací nad zvoleným vstupem. Pokud zvolený vstup není pole, bude vytvořeno pole délky 1.`,
  [`The owner must be an existing user in your user registry`]: `Vlastníkem musí být existující uživatel v registru uživatelů.`,
  [`The owner of consumer organization has the following privileges:`]: `Vlastník zákaznické organizace má následující oprávnění:`,
  [`The owner of provider organization has the following privileges:`]: `Vlastník organizace poskytovatele má následující oprávnění:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Povinnosti vlastníka zahrnují: správu životního cyklu produktu API, správu jedné nebo více organizací poskytovatele rozhraní API, správu komunity poskytovatelů aplikací a tvorbu rozhraní API a definice produktů.`,
  [`The page you are looking for might have been moved or does not exist.`]: `Hledaná stránka mohla být přesunuta nebo neexistuje.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `Hledaná stránka mohla být přesunuta nebo neexistuje. Požádejte administrátora o podporu.`,
  [`The parameters needed to send a valid API call.`]: `Parametry potřebné k odeslání platného volání rozhraní API.`,
  [`The password to use for HTTP Basic authentication.`]: `Heslo, které se má použít pro základní ověření HTTP.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `Portálová služba nabízí nástroj Developer Portal, s jehož pomocí mohou vývojáři aplikací zjišťovat rozhraní API a zahrnuté zákazníky.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `Portálová služba nabízí nástroj Developer Portal používaný vývojáři aplikací ke zjišťování a implementaci rozhraní API.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `Stav zpracování poskytuje pohled na stav brány se souhrnem poslední aktivity`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `Příjemce se registruje jako vlastník a vytvoří novou organizaci poskytovatele. Po registraci se uživatel může přihlásit k nástroji API Manager jako vlastník organizace poskytovatele.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `Název kořenového prvku výsledného dokumentu XML. Tato hodnota je vyžadována pouze v případě, že vstupní dokument JSON není hierarchický a má více než jednu vlastnost nejvyšší úrovně.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Zde uvedené rozsahy jsou určeny pro vývojáře, aby věděli, k čemu mají povolen přístup, a neplatí pro kontroly rozsahu.`,
  [`The selected catalog will only be used to search consumer organizations`]: `Vybraný katalog se použije pouze k vyhledávání zákaznických organizací`,
  [`The selected catalog does not have any configured gateways`]: `Vybraný katalog nemá žádné nakonfigurované brány`,
  [`The selected catalog does not have any compatible gateways`]: `Vybraný katalog nemá žádné kompatibilní brány`,
  [`Save Preferences`]: `Uložit předvolby`,
  [`Preferences`]: `Předvolby`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `Vybraný katalog se použije pouze k vyhledávání zákaznických organizací a skupin`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `Vybraný typ brány zneplatní následující zásady v sestavení. Než budete moci spustit toto rozhraní API, budete muset odstranit tyto zásady.`,
  [`The selected product does not contain this API`]: `Vybraný produkt neobsahuje toto rozhraní API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `Vybraný produkt bude přihlášen k odběru specifického seznamu zákaznických organizací nebo skupin.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `Vybrané služby budou k dispozici pro specifický seznam organizací poskytovatele.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `Vybrané služby budou k dispozici pro všechny existující a nové organizace poskytovatele.`,
  [`The selected services will not be available to any provider organizations.`]: `Vybrané služby nebudou k dispozici pro žádné organizace poskytovatele.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `Řetězec, který se má použít jako klíč pro mezipaměť. Je-li opomenut, použije se jako klíč celý řetězec adresy URL.`,
  [`The server responded with a non-JSON body \n{body}`]: `Odpověď serveru není s tělem typu JSON \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `Časový interval musí být delší než doba životnosti přístupového tokenu.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Token nebude uložen v souboru cookie ani v lokálním úložišti. Pokud panel testu zavřete, bude token ztracen, ale přesto může být platný.`,
  [`The transfer protocol of the API.`]: `Přenosový protokol rozhraní API.`,
  [`The type of authentication.`]: `Typ ověřování.`,
  [`The type of authorization header.`]: `Typ záhlaví autorizace.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `Typ hodnoty, která se má nastavit. Možné typy jsou any (libovolný), string (řetězec), number (číslo) nebo boolean (logická hodnota).`,
  [`The type of the value to set. This can be string, number or boolean.`]: `Typ hodnoty, která se má nastavit. Možné typy jsou string (řetězec), number (číslo) nebo boolean (logická hodnota).`,
  [`The username to use for HTTP Basic authentication.`]: `Jméno uživatele, které se má použít pro základní ověření HTTP.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `Uživatel pro toto připojení již není platný. Vytvořte prosím nové připojení.`,
  [`The value that the variable will be set to.`]: `Hodnota, na kterou bude proměnná nastavena.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Hodnota tohoto pole je seznam kompozitního typu, který nemá ani předpokládanou velikost ani žádné argumenty ořezání. Je-li rozpoznána, služba API Gateway nemůže nastavit omezení počtu objektů, které by mohl server GraphQL vrátit, pokud obdrží dotaz.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Hodnota tohoto pole je seznam skalárního typu, který nemá ani předpokládanou velikost ani žádné argumenty ořezání. Je-li rozpoznána, služba API Gateway nemůže nastavit omezení počtu objektů, které by mohl server GraphQL vrátit, pokud obdrží dotaz.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `Verze vyžaduje bezpečnou adresu URL. Verze objektu {version} a slugovaná verze {slugVersion} se neshodují.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `Vlastnost "name" musí být bezpečná pro adresu URL (hodnota názvu objektu "{name}" a hodnota názvu typu slug "{slugName}" se musí shodovat).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `Vlastnost 'draft_api' musí obsahovat definici OpenAPI. OpenAPI verze 3 podporuje pouze verze 3.0.0 - 3.0.3 (specifikovaná verze {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `Pole názvu se automaticky naplní daty na základě názvu, který poskytnete. Použijte výslednou hodnotu v rámci příkazů API Connect a volání API.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `Nastavení viditelnosti určuje, které organizace poskytovatele mohou přistupovat k prostředku. Volby jsou: Veřejný (prostředek může být použit všemi organizacemi poskytovatele); Vlastní (prostředek může být použit pouze organizacemi poskytovatele určenými vámi); a Soukromý (prostředek není viditelný a nemůže jej použít žádná organizace poskytovatele).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `Nastavení viditelnosti určuje, které organizace poskytovatele mohou používat službu. Volby jsou: Veřejná (služba může být použita všemi organizacemi poskytovatele) / Vlastní (služba může být použita pouze organizacemi poskytovatele určenými vámi) / Soukromá (služba není viditelná a nemůže ji použít žádná organizace poskytovatele).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `Nastavení viditelnosti určuje, které organizace poskytovatele mohou používat bránu. Úrovně viditelnosti zahrnují: Veřejná (brána může být použita všemi organizacemi poskytovatele) / Vlastní (brána může být použita pouze organizacemi poskytovatele určenými vámi) / Soukromá (brána není viditelná a nemůže ji použít žádná organizace poskytovatele).`,
  [`The {title} role`]: `Role {title}`,
  [`The {url} resource does not exist`]: `Prostředek {url} neexistuje`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Existují nekompatibilní rozhraní API, protože mají jiný typ brány než tento produkt.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Poté vyberte certifikát pro doménu koncového bodu správy. Také vyberte odpovídající balík CA v případě, že certifikát nebyl přiřazen dobře známou certifikační autoritou. Certifikát a balík musí být spravovány pomocí služby správce certifikátů.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Poté zadejte domény, které budou zpracovány touto bránou. Technika SNI je využívána; v důsledku toho může být více domén obsluhováno přes stejnou adresu IP a port, aniž by byl požadován stejný certifikát. Formát zástupných znaků je podporován. Standardně musí být doména (catch-all) ‘*’ zahrnuta jako finální položka v tabulce. Podle potřeby zadejte další názvy domén, v každém případě zadejte certifikát spravovaný službou správce certifikátů.`,
  [`There should be at least {minSelected} {label} selected.`]: `Měl(o) by být vybrán(o) alespoň {minSelected} {label}.`,
  [`There are no API changes to publish.`]: `Neexistují žádné změny rozhraní API pro publikování.`,
  [`There are no gateway services`]: `Nejsou k dispozici žádné služby brány`,
  [`There are no assemblies that match the chosen filters.`]: `Neexistují žádné sestavy, které by odpovídaly zvoleným filtrům.`,
  [`There are no burst limits yet.`]: `Dosud neexistují žádné limity dávky.`,
  [`There are no catalogs`]: `K dispozici nejsou žádné katalogy`,
  [`There are no consumer organizations.`]: `K dispozici nejsou žádné zákaznické organizace.`,
  [`There are no consumer organizations. You can create one`]: `K dispozici nejsou žádné zákaznické organizace. Můžete jednu vytvořit`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `K dispozici nejsou žádné zákaznické organizace. Můžete je vytvořit [zde]({link})`,
  [`There are no email servers`]: `Nejsou k dispozici žádné e-mailové servery`,
  [`There are no gateway services configured for the sandbox catalog`]: `Pro katalog sandboxu nejsou konfigurovány žádné služby brány.`,
  [`There are no lifecycle approvals enabled`]: `Nejsou povolena žádná schválení životního cyklu`,
  [`There are no new changes.`]: `Nejsou k dispozici žádné nové změny.`,
  [`There are no products to display`]: `Nejsou žádné produkty k zobrazení`,
  [`There are no products to replace`]: `Nejsou žádné produkty k záměně`,
  [`There are no products to set migration target`]: `Nejsou žádné produkty k nastavení cíle migrace`,
  [`There are no products to supersede`]: `Nejsou žádné produkty k nahrazení`,
  [`There are no properties to configure for this policy`]: `Pro tuto zásadu nejsou k dispozici žádné vlastnosti ke konfiguraci`,
  [`There are no rate limits yet.`]: `Dosud neexistují žádné limity četnosti.`,
  [`There are no security definitions`]: `Neexistují žádné definice zabezpečení`,
  [`There are no spaces`]: `K dispozici nejsou žádné prostory`,
  [`There are no target services`]: `K dispozici nejsou žádné cílové služby`,
  [`There are no tasks to be displayed`]: `Neexistují žádné úlohy k zobrazení`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Existují neuložené změny. Opravdu chcete pokračovat?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Existují neuložené změny. Budete-li pokračovat, budou vyřazeny. Jste si jisti?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `Ve vašem souboru YAML je rozhraní API, které neexistuje a které nelze vypsat. Zkontrolujte zdrojovou kartu.`,
  [`There is no item in the list.`]: `V seznamu není žádná položka.`,
  [`There is no space`]: `K dispozici není žádný prostor`,
  [`There is no warning in the schema.`]: `Ve schématu není žádné varování.`,
  [`There was no data found to populate the data table.`]: `Nebyla nalezena žádná data k naplnění datové tabulky.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Tyto koncové body se používají pro všechna rozhraní API v katalogu.`,
  [`Third party OAuth provider`]: `Poskytovatel OAuth třetí strany`,
  [`Third party OAuth provider summary`]: `Souhrn poskytovatelů OAuth třetí strany`,
  [`Third party`]: `Třetí strana`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Toto rozhraní API ještě neobsahuje sestavení. Chcete jej vytvořit?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Tento prostředek již neexistuje, aktualizujte výsledky ze serveru.`,
  [`Please refresh the results from the server`]: `Aktualizujte prosím výsledky ze serveru`,
  [`Exact match`]: `Přesná shoda`,
  [`Closest match`]: `Nejbližší shoda`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Toto rozhraní API je v současné době používáno produktem {productName}. Odstraňte jej z produktu a pak pokračujte v odstraňování rozhraní API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Toto rozhraní API bude k dispozici pro vyvolání, je-li povolena následující volba.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Pro tento katalog není povolen automatický odběr. Chcete-li testovat v tomto katalogu, vyberte produkt, plán a aplikaci níže.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Tento koncový bod se používá jako předvolba pro rozhraní API, která nedefinují základní koncový bod.`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Toto je funkce ve verzi technického náhledu, která se bude v budoucnu vyvíjet a dále zlepšovat.`,
  [`This is the currently configured notification server.`]: `Toto je momentálně nakonfigurovaný server oznámení.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Toto je adresář, kde budou uloženy vaše soubory včetně zásad a rozšíření`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `To je místo, kde se spustí rozhraní API a zásady a přípony jsou přístupné`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Tato zásada oauth provádí všechny kroky protokolu OAuth, které jsou při výchozím nastavení vyžadovány pro uvedenou cestu. Vstupy a výstupy každého z kroků jsou řízeny dokumentovanými kontextovými proměnnými. Podle potřeby přidejte nebo odeberte podporované komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Tato zásada oauth provádí všechny kroky protokolu OAuth/OpenID Connect, které jsou při výchozím nastavení vyžadovány pro ověření protokolu OAuth. Vstupy a výstupy každého z kroků jsou řízeny dokumentovanými kontextovými proměnnými. Podle potřeby přidejte nebo odeberte podporované komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Tato zásada OAuth provádí všechny kroky protokolu OAuth/OpenID Connect, které jsou při výchozím nastavení vyžadovány pro všechny ostatní cesty. Vstupy a výstupy každého z kroků jsou řízeny dokumentovanými kontextovými proměnnými. Podle potřeby přidejte nebo odeberte podporované komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Tato zásada OAuth provádí všechny kroky protokolu OAuth/OpenID Connect, které jsou při výchozím nastavení vyžadovány pro cestu ke kódu az. Vstupy a výstupy každého z kroků jsou řízeny dokumentovanými kontextovými proměnnými. Podle potřeby přidejte nebo odeberte podporované komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Tato zásada OAuth provádí všechny kroky protokolu OAuth/OpenID Connect, které jsou při výchozím nastavení vyžadovány pro cestu k tokenu. Vstupy a výstupy každého z kroků jsou řízeny dokumentovanými kontextovými proměnnými. Podle potřeby přidejte nebo odeberte podporované komponenty OAuth.`,
  [`This operation is secured with access code flow OAuth`]: `Tato operace je zabezpečena tokem přístupového kódu protokolu OAuth`,
  [`This operation is secured with application flow OAuth`]: `Tato operace je zabezpečena tokem aplikace protokolu OAuth`,
  [`This operation is secured with basic authentication`]: `Tato operace je zabezpečena pomocí základního ověření`,
  [`This operation is secured with implicit flow OAuth`]: `Tato operace je zabezpečena implicitním tokem protokolu OAuth`,
  [`This operation is secured with password flow OAuth`]: `Tato operace je zabezpečena autorizačním tokem hesla protokolu OAuth`,
  [`This policy is not available on the selected gateway`]: `Tato zásada není k dispozici na vybrané bráně`,
  [`This policy is used by one other operation`]: `Tuto zásadu používá jedna další operace`,
  [`This policy is used by {{count}} other operations`]: `Tuto zásadu používá {{count}} dalších operací`,
  [`This role cannot be edited`]: `Tuto roli nelze upravit`,
  [`This role cannot be edited.`]: `Tuto roli nelze upravit.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Toto nastavení řídí chování, pokud se mapování nezdaří, protože jeho vstup není přítomen a není nakonfigurováno žádné výchozí mapování. Výchozí chování je nezměnit výstup, ale pokud zaškrtnete toto políčko, vytvoří se pro nadřízený prvek cílového mapování prázdný objekt, který emuluje chování verze 4.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Tato zásada zabezpečení uživatele provádí kontrolu EI(basic) a AU(auth url) pro sestavení oauth. Podle potřeby změňte metodu kontroly zabezpečení`,
  [`This will enable/disable application lifecycle`]: `Tato akce povolí/zakáže životní cyklus aplikace`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Tím se povolí/zakáže vlastní schválení úlohy. Vlastní schválení úlohy umožňuje schválení úloh původcem stejně jako spolupracovníky. Tato volba se může hodit v případě, že jiní schvalovatelé nejsou k dispozici, ale fakticky umožňuje obejití kontroly jiným uživatelem.`,
  [`This will unset the catalog as the default catalog.`]: `Tím se zruší nastavení katalogu jako výchozího katalogu.`,
  [`Throw`]: `Vyvolat`,
  [`Time`]: `Čas`,
  [`Time Interval`]: `Časový interval`,
  [`Time Limit`]: `Časový limit`,
  [`Time to Live`]: `Doba životnosti`,
  [`Time to live`]: `Doba životnosti`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Doba čekání před odpovědí zpět z koncového bodu. Výchozí hodnota je 60 sekund.`,
  [`Timeout`]: `Časový limit`,
  [`To`]: `Komu`,
  [`to`]: `do`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Chcete-li přidat uživatele jako člena administrátorské organizace, vyberte jeho registr uživatelů, zadejte jeho jméno uživatele a vyberte role, které chcete přiřadit. Je-li typ registru uživatelů Lokální registr uživatelů, máte také možnost vytvořit nového uživatele, který je poté přidán jako člen. Členství je okamžitě povoleno a uvedený uživatel se může přihlásit do uživatelského rozhraní nástroje Cloud Manager`,
  [`To add a user as a member of a provider organization`]: `Chcete-li přidat uživatele jako člena organizace poskytovatele`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Chcete-li přidat uživatele jako člena Katalogu, vyberte jeho jméno uživatele a přiřaďte nezbytné role. Do organizace poskytovatele můžete přidat libovolného uživatele, který je již členem jiného katalogu nebo prostoru a v současné době není členem tohoto katalogu ani vlastníkem katalogu.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Chcete-li přidat uživatele jako člena Prostoru, vyberte jeho jméno uživatele a přiřaďte nezbytné role. Do organizace poskytovatele můžete přidat libovolného uživatele, který je již členem jiného katalogu nebo prostoru a v současné době není členem tohoto prostoru ani vlastníkem prostoru.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Chcete-li přidat nebo odebrat členy, použijte službu [identita a přístup](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Chcete-li přidat členy, použijte službu [identita a přístup](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Chcete-li přidat uživatele jako člena organizace, vyberte jeho registr uživatelů, zadejte jméno uživatele a přiřaďte sadu rolí.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Chcete-li přidat uživatele jako člena, vyberte jeho jméno uživatele a přiřaďte mu sadu rolí.`,
  [`To assign default gateways, click **Edit**.`]: `Chcete-li přiřadit výchozí brány, klepněte na tlačítko **Upravit**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Chcete-li pokračovat v používání tohoto softwaru, musíte souhlasit s podmínkami softwarové licenční smlouvy.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Chcete-li pokračovat v používání produktu API Connect, upgradujte na plán Enterprise.`,
  [`To get started, you must first enable spaces.`]: `Chcete-li začít, musíte nejprve povolit prostory.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Chcete-li nainstalovat a spustit sadu nástrojů, postupujte takto. [Další informace]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Chcete-li pozvat nového uživatele jako člena administrátorské organizace, zadejte jeho e-mailovou adresu nebo jméno, nebo vyberte jméno z registru uživatelů. Všem členům je automaticky přiřazena role člena, která poskytuje základní sadu oprávnění, a nelze ji odstranit. Pokud nejsou zapotřebí žádná další oprávnění, nechte ostatní role nezaškrtnuté.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Chcete-li pozvat vlastníka, který dosud není ve vašem registru uživatelů, zadejte jeho e-mailovou adresu`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Chcete-li zaregistrovat novou bránu, přejděte na stránku **Brány**.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Chcete-li nahradit hodnoty jednou z následujících vlastností prostoru, zadejte $() s názvem vlastnosti uvnitř závorek`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Chcete-li odeslat e-mailovou pozvánku novému uživateli v pozici člena administrátorské organizace, zadejte platnou e-mailovou adresu a vyberte role, které chcete uživateli přiřadit. E-mail obsahující aktivační odkaz se odešle na e-mailovou adresu pro pozvání osoby k registraci. Uživateli je přiřazen stav Nevyřízeno a jeho stav se změní na Povoleno, až když vyplní formulář registrace pro aktivaci svého účtu. Pak se může přihlásit do uživatelského rozhraní nástroje Cloud Manager pomocí jména uživatele produktu API Connect, které uvedl při aktivaci účtu.`,
  [`Toggle`]: `Přepnout`,
  [`Toggle application lifecycle?`]: `Přepnout životní cyklus aplikace?`,
  [`Toggle default catalog?`]: `Přepnout výchozí katalog?`,
  [`Toggle info panel between right and bottom`]: `Přepnout informační panel mezi pravým a dolním`,
  [`Toggle profile`]: `Přepnout profil`,
  [`Toggle spaces?`]: `Přepnout prostory?`,
  [`Toggle task self-approval?`]: `Přepnout samoschválení úlohy?`,
  [`Token`]: `Token`,
  [`Token endpoint`]: `Koncový bod tokenu`,
  [`Token Expiry`]: `Vypršení platnosti tokenu`,
  [`Token Management`]: `Správa tokenů`,
  [`Token Output`]: `Výstup tokenu`,
  [`Token URL`]: `Adresa URL tokenu`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Adresa URL tokenu musí odpovídat bodu {endpoint}, jak je definováno poskytovatelem OAuth "{providerTitle}"`,
  [`Token Version`]: `Verze tokenu`,
  [`Token management`]: `Správa tokenů`,
  [`Token management type`]: `Typ správy tokenů`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `Správa tokenů vám umožňuje zabránit útokům přehráním, spravovat tokeny, včetně toho, zda a jak aplikace klienta nebo vlastníci prostředků odvolávají tokeny OAuth. Služba API Connect podporuje správu tokenů s nativní bránou nebo koncovým bodem třetí strany. V případě nativní brány se ke správě tokenů použije vynucení kvóty. V případě koncového bodu třetí strany poskytnete adresu URL na externí službu správy tokenů.`,
  [`Token path`]: `Cesta k tokenu`,
  [`Token revocation`]: `Odvolání tokenu`,
  [`Token secret`]: `Tajné údaje tokenu`,
  [`Token validation`]: `Ověření tokenu`,
  [`Tokens`]: `Tokeny`,
  [`Token relay`]: `Převaděč tokenu`,
  [`Toolkit credentials`]: `Pověření sady nástrojů`,
  [`Token Relay`]: `Převaděč tokenu`,
  [`Topology`]: `Topologie`,
  [`Topology Administrator`]: `Administrátor topologie`,
  [`Total time`]: `Celkový čas`,
  [`Transfer Ownership to a New User`]: `Převést vlastnictví na nového uživatele`,
  [`Transfer Ownership to an Existing User`]: `Převést vlastnictví na existujícího uživatele`,
  [`Transfer ownership`]: `Převést vlastnictví`,
  [`Transfer ownership to`]: `Převést vlastnictví na`,
  [`Transfer ownership to a new user`]: `Převést vlastnictví na nového uživatele`,
  [`Transfer ownership to an existing user`]: `Převést vlastnictví na existujícího uživatele`,
  [`Transforms`]: `Transformace`,
  [`Truststore Certificates`]: `Certifikáty úložiště údajů o důvěryhodnosti`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Certifikáty úložiště údajů o důvěryhodnosti jsou veřejné certifikáty vydané autoritou certifikátu.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Úložiště údajů o důvěryhodnosti obsahují důvěryhodné certifikáty obsahující ověřené veřejné klíče. Certifikáty v úložišti údajů o důvěryhodnosti jsou obvykle získány od certifikační autority (CA) třetí strany.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Úložiště údajů o důvěryhodnosti obsahují důvěryhodné certifikáty s ověřenými veřejnými klíči. Certifikáty v úložišti údajů o důvěryhodnosti jsou obvykle získány od certifikační autority (CA) třetí strany.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Úložiště údajů o důvěryhodnosti ukládají důvěryhodné certifikáty včetně ověřených veřejných klíčů. Certifikáty v úložišti údajů o důvěryhodnosti jsou obvykle získány od certifikační autority (CA) třetí strany.`,
  [`Try again or upload a schema`]: `Operaci opakujte nebo znovu odešlete schéma`,
  [`Try refreshing the page or contacting support.`]: `Pokuste se aktualizovat stránku nebo kontaktujte podporu.`,
  [`Turkish`]: `Turečtina`,
  [`Tutorials`]: `Výukové programy`,
  [`Types`]: `Typy`,
  [`Type of user`]: `Typ uživatele`,
  [`Type or select a catalog`]: `Zadat nebo vybrat katalog`,
  [`Type to add organizations`]: `Zadáním přidejte organizace`,
  [`TypeError: Failed to fetch`]: `TypeError: Nezdařilo se načíst`,
  [`UDP`]: `Protokol UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `Adresa URL koncového bodu správy`,
  [`URLs for all operations in the API begin with this value.`]: `Adresy URL pro všechny operace v rozhraní API začínající touto hodnotou.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `Definovaný uživatelem`,
  [`Userinfo POST`]: `Userinfo POST`,
  [`USER MANAGED`]: `SPRAVOVANÝ UŽIVATELEM`,
  [`USERNAME`]: `JMÉNO UŽIVATELE`,
  [`User can delete the keystore history.`]: `Uživatel může odstranit historii úložiště klíčů.`,
  [`Unable to parse API definition: {msg}`]: `Nelze analyzovat definici rozhraní API: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Nelze pokračovat, protože došlo k chybám při odebírání typů či polí.`,
  [`Unarchive`]: `Odarchivovat`,
  [`Unassociate`]: `Zrušit přidružení`,
  [`Unassociate analytics service`]: `Zrušit přidružení analytické služby`,
  [`Unauthorized`]: `Neautorizované`,
  [`Unauthorized API Request`]: `Neautorizovaný požadavek na rozhraní API`,
  [`Unbound lists`]: `Zrušit vazbu seznamů`,
  [`Unit`]: `Jednotka`,
  [`Union`]: `Sjednocení`,
  [`Union type`]: `Typ sjednocení`,
  [`Unique email address`]: `Jedinečná e-mailová adresa`,
  [`Unlimited`]: `Bez omezení`,
  [`Unnecessary assumed size`]: `Nadbytečná předpokládaná velikost`,
  [`Unnecessary slicing arguments`]: `Nadbytečné argumenty ořezání`,
  [`Unrecognized Schema Element(s) Encountered`]: `Vyskytly se nerozpoznané prvky schématu`,
  [`Unsupported media type ({type})`]: `Nepodporovaný typ média ({type})`,
  [`Untitled`]: `Bez názvu`,
  [`Update`]: `Aktualizovat`,
  [`Update gateway services`]: `Aktualizovat služby brány`,
  [`Update assembly`]: `Aktualizovat sestavení`,
  [`Update group failed.`]: `Aktualizace skupiny se nezdařila.`,
  [`Update schema defaults giving preference to`]: `Aktualizovat předvolby schématu s upřednostněním`,
  [`Update the sender details that are displayed on invitation emails`]: `Aktualizovat podrobnosti o odesílateli, které se zobrazí v e-mailech s pozvánkou`,
  [`Update the summary details for this role`]: `Aktualizovat souhrnné podrobnosti pro tuto roli`,
  [`Updating Draft API`]: `Aktualizace rozpracovaného rozhraní API`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `Aktualizace předvoleb schématu přepíše vaše změny. Opravdu chcete pokračovat?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `Aktualizace rozhraní API typu {existingType} s rozhraní API typu {type} není povolena.`,
  [`Upload`]: `Odeslat`,
  [`Upload a schema definition language file to replace your schema.`]: `Odešlete soubor jazyka definice schématu, abyste nahradili vaše schéma.`,
  [`Upload GraphQL schema`]: `Odeslat schéma GraphQL`,
  [`Upload local`]: `Odeslat lokální`,
  [`Upload policies`]: `Odeslat zásady`,
  [`Upload policy`]: `Odeslat zásadu`,
  [`Upload schema`]: `Odeslat schéma`,
  [`Upload schema definition language (SDL) file`]: `Odeslat soubor jazyka definice schématu (SDL)`,
  [`Upload truststore certificates`]: `Přenést certifikáty do úložiště údajů o důvěryhodnosti`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Přeneste certifikát X509 ve formátu PEM pro vaši aplikaci. Odeslat můžete pouze jeden soubor PEM, ale do jednoho souboru může být zahrnuto více certifikátů.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Přeneste certifikát X509 ve formátu PEM pro vaši aplikaci.`,
  [`Upload your public certificate`]: `Odeslat váš veřejný certifikát`,
  [`Uploaded and validated YAML file`]: `Odeslaný a ověřený soubor YAML`,
  [`Upload extension`]: `Odeslat rozšíření`,
  [`Use all compatible gateway services`]: `Použít všechny kompatibilní služby brány`,
  [`Use Content Type`]: `Použít typ obsahu`,
  [`Use DataPower API Gateway`]: `Použít bránu DataPower API`,
  [`Use DataPower Gateway`]: `Použít bránu DataPower`,
  [`Use Dynamic DNS`]: `Použít dynamický server DNS`,
  [`Use JSONata to specify the property to redact or remove`]: `Použít JSONata k určení vlastnosti pro redakci nebo odebrání`,
  [`Use XPath to specify the property to redact or remove.`]: `Použijte výraz XPath k určení vlastnosti, která má být redigována nebo odebrána.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Použít vybraný katalog jako výchozí katalog, do kterého chcete publikovat`,
  [`Use an existing JSON or YAML definition of the product`]: `Použít existující definici JSON nebo YAML produktu`,
  [`Use an existing definition of a REST proxy`]: `Použít existující definici serveru proxy REST`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Použít existující definici serveru proxy REST nebo rozhraní SOAP API`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Použít existující definici serveru proxy REST, proxy GraphQL nebo SOAP API`,
  [`Use an existing definition of a product`]: `Použijte existující definici produktu.`,
  [`Use context current payload`]: `Použít aktuální informační obsah kontextu`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Použít přizpůsobenou adresu URL koncového bodu katalogu (kompatibilita v5)`,
  [`Use dynamic DNS`]: `Použít dynamický server DNS`,
  [`Use endpoint from API`]: `Použít koncový bod z rozhraní API`,
  [`Use gateway URLs`]: `Použít adresy URL brány`,
  [`Use gateway service configured OAuth shared secret key`]: `Použít sdílený tajný klíč OAuth nakonfigurovaný službou brány`,
  [`Use group authentication`]: `Použít ověření skupiny`,
  [`Use only first array element`]: `Použít pouze první prvek pole`,
  [`Use static DNS`]: `Použít statický server DNS`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Použít portál jako koncový bod pro provoz externího poskytovatele OIDC`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Chcete-li otestovat a prozkoumat rozhraní API, použijte Průzkumník rozhraní API. Průzkumník zobrazuje operace, definice a dokumentaci pro všechna rozhraní API, která jsou obsažena v adresáři projektu. Průzkumník zobrazí seznam parametrů, data instancí modelu a kódy odezvy společně s kódem šablony pro spuštění rozhraní API.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Použijte nabídku akcí ke správě a publikování vašich rozhraní API.`,
  [`Use the actions menu to manage and publish your products`]: `Použijte nabídku akcí ke správě a publikování vašich produktů`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Pomocí zásady protokolu aktivity můžete konfigurovat předvolby protokolování pro aktivitu rozhraní API, která je uložena v produktu Analytics. Předvolby, které určíte, potlačí výchozí nastavení pro shromažďování a ukládání podrobností aktivity rozhraní API.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Použijte zásadu zabezpečení klienta k extrahování a ověření pověření klienta.`,
  [`Use the default built-in Sandbox Catalog`]: `Použít výchozí vestavěný katalog sandboxu`,
  [`Use the editor for manual input or upload a file below.`]: `Použijte editor pro ruční vstup nebo odešlete níže uvedený soubor.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Použijte editor pro ruční vstup nebo odešlete níže uvedený soubor. Aktuální schéma lze upravit pouze v XML, je-li definován soubor XML/XSD.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Použijte vynucené rozšíření k určení, zda se má brána API Connect používat k vynucení rozhraní API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Při práci s rozhraním CLI použijte následující adresu URL koncového bodu správy.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Při vytváření cloudového připojení v nástroji API Designer použijte následující adresu URL koncového bodu správy.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Použijte zásadu gatewayscript k provedení určeného programu DataPower GatewayScript.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Pomocí zásady graphql-introspect můžete provést introspekci schématu GraphQL.`,
  [`Use the host value from Open API Definition`]: `Použít hodnotu hostitele z definice rozhraní Open API`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Použijte zásadu pokud, chcete-li použít sekci sestavení, když je splněna podmínka.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Použijte zásadu javascript k provedení uvedeného programu JavaScript.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Chcete-li převést obsah informačního obsahu z formátu JSON do formátu XML, použijte zásadu json-to-xml.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Pomocí zásady protokolu můžete upravit nebo potlačit výchozí konfiguraci protokolování aktivity pro rozhraní API.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Pomocí zásady mapování můžete použít transformace na svůj tok sestavení a určit vztahy mezi proměnnými.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Pomocí zásady mapování můžete určit vztahy mezi proměnnými v rámci vašeho toku rozhraní API a použít transformace pro tyto proměnné.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Použijte zásadu oauth k vygenerování tokenů OAuth.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Pomocí zásady přepínače operace můžete použít sekci sestavení na specifickou operaci.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Pomocí zásady analýzy můžete analyzovat požadavek nebo zprávu jako XML nebo JSON nebo analyzovat binární data do binárního velkého objektu (BLOB).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Pomocí zásady set-variable můžete nastavit běhovou proměnnou na hodnotu řetězce nebo přidat nebo vymazat běhovou proměnnou.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Pomocí zásady vyvolání můžete vyvolat chybu, když je dosažena během provádění toku sestavení.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Použijte zásadu zabezpečení uživatele k extrakci, ověření a autorizaci identity uživatele.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Použijte zásadu ověření s bránou DataPower, chcete-li ověřit informační obsah v toku sestavení s použitím schématu JSON nebo XML.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Pomocí zásady xml-to-json můžete převést obsah informačního obsahu z formátu XML do formátu JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Použijte zásadu xslt k uplatnění transformace XSLT na informační obsah.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Používá se k povolení/zakázání komprese kódování obsahu při přenosu.`,
  [`User`]: `Uživatel`,
  [`User group prefix`]: `Předpona skupiny uživatelů`,
  [`User group suffix`]: `Přípona skupiny uživatelů`,
  [`User mapping`]: `Mapování uživatele`,
  [`User Registries`]: `Registry uživatelů`,
  [`User registries`]: `Registry uživatelů`,
  [`User Registry Name`]: `Název registru uživatelů`,
  [`User registry name`]: `Název registru uživatelů`,
  [`User Registry cannot be deleted`]: `Registr uživatelů nelze odstranit`,
  [`User Registry "{name}" has been deleted`]: `Registr uživatelů "{name}" byl odstraněn`,
  [`User security`]: `Zabezpečení uživatele`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Registry uživatelů jsou spravovány v každém prostoru, jsou-li prostory povoleny. Přejděte do nastavení prostoru pro správu registrů uživatelů.`,
  [`User registries defined for consumer onboarding`]: `Registry uživatelů definované pro zahrnutí zákazníka`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Registry uživatelů definované pro zahrnutí spotřebitele\n\nKaždý účet v nástroji Developer Portal, včetně různých registrů uživatelů pro stejný web, musí mít jedinečnou e-mailovou adresu, včetně účtu administrátora webu.`,
  [`User registry`]: `Registr uživatelů`,
  [`User registry configuration has been updated.`]: `Konfigurace registru uživatelů byla aktualizována.`,
  [`User registry has been created`]: `Registr uživatelů byl vytvořen`,
  [`User registry {title} deleted.`]: `Registr uživatelů {title} byl odstraněn.`,
  [`User registry {title} has been created.`]: `Registr uživatelů {title} byl vytvořen.`,
  [`User registry is not visible or not available.`]: `Registr uživatelů není viditelný nebo není k dispozici.`,
  [`User registry {title} has been updated.`]: `Registr uživatelů {title} byl aktualizován.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `Nastavení zabezpečení uživatele lze použít pouze v případě, že vybrané typy udělení zahrnují jednu nebo více z následujících možností: Implicitní, Přístupový kód a Vlastník prostředku - Heslo.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `Nastavení zabezpečení uživatele lze použít pouze v případě, že vybrané typy udělení zahrnují jednu nebo více z následujících možností: Implicitní, Přístupový kód a Vlastník prostředku - Heslo. Když na této stránce provedete nějaké změny, měli byste také na stránce Konfigurace vybrat alespoň jeden z těchto typů udělení.`,
  [`UserInfo endpoint`]: `Koncový bod userInfo`,
  [`UserInfo URL`]: `Adresa URL UserInfo`,
  [`Username`]: `Jméno uživatele`,
  [`Username / Email`]: `Jméno uživatele/E-mail`,
  [`Username and password fields are required in order to test configuration`]: `Pole Jméno uživatele a Heslo jsou povinná, aby bylo možné testovat konfiguraci.`,
  [`Username context variable`]: `Kontextová proměnná jména uživatele`,
  [`Username of the new user`]: `Jméno uživatele nového uživatele`,
  [`Username should not contain special characters!`]: `Jméno uživatele by nemělo obsahovat speciální znaky!`,
  [`Username should not include spaces!`]: `Jméno uživatele by nemělo obsahovat mezery!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Uživatelé patřící do zákaznické organizace mají přístup k nástroji Developer Portal a k jeho publikovaným produktům rozhraní API, které jsou určeny jejich viditelností a možnostmi přihlášení k odběru.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Pomocí [IBM Cloud IAM]({link}) autorizujte službu API Connect Reserved Instance pro přístup k službě správce certifikátů.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Pomocí metody HTTP POST při kontaktování koncového bodu Userinfo`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Použít nastavení vypršení platnosti tokenu IBM APIC z cloudu`,
  [`Update API`]: `Aktualizovat rozhraní API`,
  [`VALUE`]: `HODNOTA`,
  [`Version`]: `Verze`,
  [`Visible to`]: `Viditelné pro`,
  [`Validate`]: `Ověřit platnost`,
  [`Validate message body`]: `Ověřit tělo zprávy`,
  [`Validate message headers`]: `Ověřit záhlaví zprávy`,
  [`Validate message fault details`]: `Ověřit podrobnosti o chybě zprávy`,
  [`Validate SOAP 1.1 encoding rule`]: `Ověřit pravidlo kódování SOAP 1.1`,
  [`Validate JSON Web Token (JWT)`]: `Ověřit webový token JSON (JWT)`,
  [`Validate JWT`]: `Ověřit JWT`,
  [`Validate Username Token`]: `Ověřit token jména uživatele`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Ověřte informační obsah tokenu jména uživatele zabezpečení webových služeb pomocí ověřovací adresy URL nebo registru uživatelů LDAP.`,
  [`Validate against`]: `Ověřit proti`,
  [`Validate request`]: `Ověřit požadavek`,
  [`Validate the GraphQL query and analyze the expected cost`]: `Ověřit dotaz GraphQL a analyzovat očekávané náklady`,
  [`Validate the returned JSON and analyze the real cost`]: `Ověřit vrácený JSON a analyzovat skutečné náklady`,
  [`Validating API ...`]: `Ověřování rozhraní API...`,
  [`Validating Draft API`]: `Ověřování rozpracovaného rozhraní API`,
  [`Validity Period`]: `Období platnosti`,
  [`Value`]: `Hodnota`,
  [`Value for located in must be selected.`]: `Hodnota pro Umístěno v musí být vybrána.`,
  [`Value for the variable to be added.`]: `Hodnota pro proměnnou, která se má přidat.`,
  [`Value for type must be selected.`]: `Hodnota pro typ musí být vybrána.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `Hodnota argumentu "weight" v direktivě "@cost" je < 0.`,
  [`Values`]: `Hodnoty`,
  [`Vanity API endpoint`]: `Jednoduchý koncový bod rozhraní API`,
  [`Variable name`]: `Název proměnné`,
  [`Variable value`]: `Hodnota proměnné`,
  [`Vendor Extensions`]: `Rozšíření dodavatele`,
  [`Verb`]: `Sloveso`,
  [`Verify`]: `Ověřit`,
  [`Verify Access Token`]: `Ověřit přístupový prvek`,
  [`Verify Crypto JWK variable name`]: `Název proměnné k ověření JWK`,
  [`Verify Crypto Object`]: `Kryptografický objekt pro ověření`,
  [`Verify Token`]: `Ověřit token`,
  [`Verify authorization code`]: `Ověřit autorizační kód`,
  [`Verify refresh token`]: `Ověřit aktualizační token`,
  [`Version of the LTPA token.`]: `Verze tokenu LTPA.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Zkontrolujte vlastnost 'apis' pod sekcí 'plans' v importovaném souboru produktu. Každý plán by měl mít vlastnost 'apis' ukazující na alespoň jedno rozhraní API z oddílu 'apis'.`,
  [`View`]: `Zobrazit`,
  [`View extensions`]: `Zobrazit rozšíření`,
  [`View Forum`]: `Zobrazit fórum`,
  [`View owners`]: `Zobrazit vlastníky`,
  [`View policies`]: `Zobrazit zásady`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Zobrazte a spravujte šifry dostupné pro vybranou verzi protokolu.`,
  [`View analytics`]: `Zobrazit analýzy`,
  [`View and edit permissions for the roles in this space`]: `Zobrazit a upravit oprávnění pro role v tomto prostoru`,
  [`View and edit the permissions for the roles in the current organization`]: `Zobrazit a upravit oprávnění pro role v aktuální organizaci`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Zobrazit a upravit role obsažené ve výchozích rolích pro zákaznické organizace`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Zobrazit a upravit role obsažené ve výchozích rolích pro organizace poskytovatele`,
  [`View approval history`]: `Zobrazit historii schvalování`,
  [`View Approval History`]: `Zobrazit historii schvalování`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Zobrazit dokumentaci a výukové programy s podrobnými pokyny.`,
  [`View error ID`]: `Zobrazit ID chyby`,
  [`View status`]: `Zobrazit stav`,
  [`View more`]: `Zobrazit více`,
  [`View source`]: `Zobrazit zdroj`,
  [`View subscription`]: `Zobrazit odběr`,
  [`View template in`]: `Zobrazit šablonu v`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Zobrazit koncové body pro přístup k uživatelským rozhraním služby API Connect.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Zobrazit koncové body pro rozhraní REST API administrátora a poskytovatele.`,
  [`View the endpoints for the consumer REST APIs.`]: `Zobrazit koncové body pro rozhraní REST API odběratele.`,
  [`Viewer`]: `Prohlížející`,
  [`Views the API consumer organization`]: `Prohlíží si zákaznickou organizaci rozhraní API.`,
  [`Views the API provider organization`]: `Zobrazuje organizaci poskytovatele rozhraní API.`,
  [`Views the admin organization`]: `Prohlíží si administrátorskou organizaci.`,
  [`Views the app developer organization`]: `Zobrazuje vývojářskou organizaci aplikací`,
  [`Views the catalog`]: `Prohlíží si katalog.`,
  [`Views the space`]: `Prohlíží prostor.`,
  [`View|permission`]: `Zobrazit`,
  [`Visibility`]: `Viditelnost`,
  [`Visibility options`]: `Volby viditelnosti`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `Dokumentace VMware`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `Adresa URL kódu WSDL`,
  [`WSDL has been successfully validated`]: `Jazyk WSDL byl úspěšně ověřen`,
  [`WSDL to REST`]: `WSDL do služby REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `Ověření základního profilu WS-I`,
  [`Warn`]: `Varovat`,
  [`Warning`]: `Varování`,
  [`Warning:`]: `Varování:`,
  [`Warnings`]: `Varování`,
  [`We just emailed recipients a test message:`]: `Právě jsme poslali příjemcům testovací zprávu:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `Provádíme přesun na kartu "Test", kde budete moci testovat a ladit rozhraní API.`,
  [`Weather Product`]: `Produkt Weather`,
  [`Web Endpoint`]: `Webový koncový bod`,
  [`Web service operations`]: `Operace webové služby`,
  [`weight`]: `Váha`,
  [`Weight`]: `Váha`,
  [`Welcome to API Connect Administration`]: `Vítejte v administraci služby API Connect`,
  [`Welcome to API Connect Reserved`]: `Vítejte ve vyhrazené instanci API Connect Reserved`,
  [`Welcome to API Designer`]: `Vítejte v nástroji API Designer`,
  [`Welcome to API Manager`]: `Vítejte v nástroji API Manager`,
  [`Welcome to Cloud Manager`]: `Vítejte v nástroji Cloud Manager`,
  [`Welcome to the API Designer`]: `Vítejte v nástroji API Designer`,
  [`Welcome to the API Manager`]: `Vítejte v nástroji API Manager`,
  [`Welcome to the Cloud Manager`]: `Vítejte v nástroji Cloud Manager`,
  [`What are you looking for today?`]: `Co dnes hledáte?`,
  [`What's new`]: `Novinky`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `Co bude dál? Povolte integraci s platebním systémem v katalogu, a tak umožněte uživatelům přidávat plány do jejich produktů.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `V případě nastavení na hodnotu true vloží zásada vyvolání záhlaví "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" do požadavku, který se odešle na cílovou adresu URL.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Při publikování se produkt API stane viditelným v nástroji Developer Portal, který je přidružený ke katalogu. Koncové body pro rozhraní API v produktu jsou zpřístupněny v branách přidružených k cílenému katalogu nebo prostoru. Fázované produkty jsou v katalogu, ale nejsou aktivní nebo viditelné pro odběratele.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Při publikování se produkt API stane viditelným v nástroji Developer Portal, který je přidružený ke katalogu. Koncové body pro rozhraní API v produktu jsou zpřístupněny v branách přidružených k cílenému katalogu nebo prostoru. Fázované produkty jsou v katalogu, ale nejsou aktivní nebo viditelné pro odběratele. Mějte na paměti, že opětovné fázování / publikace produktu do neprodukčního katalogu odebere existující odběry.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Jsou-li pro katalog povoleny prostory, musí být konfigurovaný poskytovatel OAuth prohlížen z prostoru.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Jsou-li prostory povoleny, existující produkty v katalogu budou přesunuty do výchozího prostoru.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Když uživatel klepne na aktivační odkaz, zobrazí se výzva k dokončení registračního formuláře. Po registraci se uživatel může přihlásit.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Když přidáte roli pro poskytovatelskou nebo zákaznickou organizaci, bude zahrnuta do výchozí role. Výchozí role jsou předkonfigurované šablony rolí použité k určení výchozích rolí, které jsou k dispozici při vytvoření nové organizace.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Když zamítnete produkt, vývojáři aplikací, kteří jsou již přihlášeni k odběru produktu, jej mohou používat i nadále, ale k odběru produktu se nemůže přihlásit žádný nový vývojář.\n\n [Další informace]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Když upravíte roli pro poskytovatelskou nebo zákaznickou organizaci, bude zahrnuta do výchozí role. Výchozí role jsou předkonfigurované šablony rolí použité k určení výchozích rolí, které jsou k dispozici při vytvoření nové organizace.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Povolíte-li službu OpenID Connect, bude vám poskytnuta šablona pro generování ID tokenů spolu s přístupovými tokeny a automaticky se vytvoří požadované zásady sestavení. Můžete si přizpůsobit zásady tak, aby vyhovovaly vašim potřebám. Ukázkový klíč je určen pouze pro testovací účely a používá se k podepisování tokenu JWT.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Povolíte-li introspekci tokenu, lze přístupový prvek prozkoumat prostřednictvím cesty introspekce.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Když povolíte introspekci tokenu, mohou držitelé přístupových tokenů prozkoumat obsah tokenů pomocí cesty introspekce. Přístupový token k introspekci musí být získán prostřednictvím nativního poskytovatele OAuth.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Když pozvete uživatele, aby byl členem organizace poskytovatele, přiřaďte jim jednu nebo více rolí, které řídí jejich úroveň přístupu k informacím, a úlohy, které mohou provádět.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Při správě přesouváte verze produktu přes řadu stavů životního cyklu, od počátečního fázování verze produktu do katalogu, přes publikaci, aby byla verze produktu dostupná vývojářům aplikací, až po případné stažení a archivaci.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Při záměně produktu za jiný produkt se provádějí následující akce: 1) Zaměňovaný produkt je publikován, 2) Nastavení viditelnosti, odběratele a vynucení brány z původního produktu jsou použity v zaměňujícím produktu, 3) Odběratelé původního produktu jsou migrováni na zaměňovaný produkt.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Když nahradíte produkt jiným produktem, provedou se následující akce: 1) Náhradní produkt předpokládá stav původního produktu, 2) Pokud jsou nastavení viditelnosti a možnosti přihlášení k odběru v náhradním produktu taková, že je přístup stejný jako původní produkt, nebo je méně restriktivní než původní produkt, použijí se nastavení v náhradním produktu. Pokud jsou nastavení v náhradním produktu restriktivnější a zabránila by některým zákaznickým organizacím s přístupem k původnímu produktu v přístupu k náhradě, operaci nahrazení nelze dokončit. 3) Předplatitelé původního produktu jsou migrováni na náhradní produkt a (4) Původní produkt je ukončen.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Když skončíte s používáním produktu, jsou všechna přidružená rozhraní API převedena do režimu offline a všechny odběry se stanou neaktivními.\n\n [Další informace]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Při nahrazení produktu jiným produktem se provádějí následující akce: 1) Nahrazující produkt je publikován, 2) Nastavení stejné viditelnosti, odběratele a vynucení brány z původního produktu jsou použity v nahrazujícím produktu, 3) Původní produkt je přesunut do zamítnutého stavu. Když je produkt zamítnut, vývojáři aplikací, kteří jsou již přihlášeni k odběru produktu, jej mohou používat i nadále, ale k odběru produktu se nemůže přihlásit žádný nový vývojář. Zamítnutý produkt může být v případě potřeby znovu publikován.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Při nahrazení produktu jiným produktem se provádějí následující akce: 1) nahrazující produkt je publikován, 2) nastavení viditelnosti a možnosti přihlášení k odběru z původního produktu jsou použity v nahrazujícím produktu a 3) původní produkt je přesunut do zamítnutého stavu. Když je produkt zamítnut, vývojáři aplikací, kteří jsou již přihlášeni k odběru produktu, jej mohou používat i nadále, ale k odběru produktu se nemůže přihlásit žádný nový vývojář. Zamítnutý produkt může být v případě potřeby znovu publikován.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Když pozastavíte aplikaci, jsou přidružená ID klienta blokována od voláním API. Aplikaci můžete kdykoli obnovit.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Je-li tato volba povolena, bude časový limit všech pozvánek do zákaznické organizace nastaven na časový limit pozvánky do katalogu a případná aktualizace časového limitu pozvánky do katalogu aktualizuje časový limit pozvánek do zákaznické organizace.`,
  [`Where did the roles go?`]: `Kam zmizely role?`,
  [`Where to find the named rate limit.`]: `Kde najít uvedený limit četnosti.`,
  [`Where to put the decrypted result`]: `Kam vložit dešifrovaný výsledek`,
  [`Where to put the encrypted result`]: `Kam vložit zašifrovaný výsledek`,
  [`Whether to reuse the connection to the target server.`]: `Zda se má znovu použít připojení k cílovému serveru.`,
  [`Windows live`]: `Windows live`,
  [`With`]: `Za`,
  [`Wildcards ignore xsi:type rule`]: `Zástupné znaky ignorují pravidlo xsi:type`,
  [`Work offline`]: `Práce offline`,
  [`Work with existing definitions, models and/or data sources`]: `Práce s existujícími definicemi, modely či zdroji dat`,
  [`Working...`]: `Zpracování...`,
  [`Wrapper Policies`]: `Zásady obálky`,
  [`Wrong slicing arguments`]: `Chybné argumenty ořezání`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `Soubory WSDL a XSD by měly být uvnitř svého vlastního souboru ZIP v rámci souboru ZIP, který se pokoušíte importovat.`,
  [`XML Schema`]: `Schéma XML`,
  [`XML Schema URL`]: `Adresa URL schématu JSON`,
  [`XML Schema preview`]: `Náhled schématu XML`,
  [`XML Validation Mode`]: `Režim ověření platnosti XML`,
  [`XML schema is not valid. Please correct the error.`]: `Schéma XML není platné. Odstraňte chybu.`,
  [`XML to JSON`]: `XML na JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `Verze XSLT`,
  [`YAML has been successfully validated`]: `Schéma YAML bylo úspěšně ověřeno`,
  [`Yes`]: `Ano`,
  [`Yes, enable it`]: `Ano, povolit`,
  [`Yes, reset`]: `Ano, resetovat`,
  [`Yes. Update API Assembly`]: `Ano. Aktualizovat sestavení rozhraní API`,
  [`You are authenticated!`]: `Jste ověřeni!`,
  [`You do not have permission to use the Test Application`]: `Nemáte oprávnění k použití testovací aplikace`,
  [`You can also copy the link and send it directly to the user.`]: `Odkaz můžete také zkopírovat a odeslat jej přímo uživateli.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `Zvolit můžete nepublikovat rozhraní API v tuto chvíli, nebo můžete okamžitě publikovat na některý z koncových bodů testování nebo produkce, případně na oba. Volitelně můžete určit maximální povolenou četnost volání API podle počtu volání za daný časový interval.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `Zvolit můžete nepublikovat rozhraní API v tuto chvíli, nebo můžete okamžitě publikovat na některý z koncových bodů testování nebo produkce, případně na oba. Volitelně můžete určit maximální povolenou četnost volání API podle počtu volání za daný časový interval.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `Můžete se rozhodnout nyní ponechat rozhraní API nezabezpečené nebo můžete požadovat, aby volající aplikace dodala ID klienta. Po vytvoření definice rozhraní API můžete konfigurovat další volby zabezpečení.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `Můžete zadat název nebo titulek katalogu. Název katalogu bude mít přednost, je-li k dispozici obojí.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `E-maily můžete odeslat vlastníkovi zákaznické organizace. Zpráva je odeslána pomocí e-mailového serveru nakonfigurovaného v oznámeních.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `E-maily můžete odeslat vlastníkovi zákaznické organizace. Zpráva je odeslána pomocí e-mailového serveru nakonfigurovaného v sekci Oznámení.`,
  [`You can send an email to a provider organization owner.`]: `E-maily můžete odeslat vlastníkovi organizace poskytovatele.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Vlastnictví můžete převést na nového uživatele zadáním jeho e-mailové adresy. Obdrží e-mail s aktivačním odkazem k registraci. Jakmile je uživatel zaregistrován, stane se novým vlastníkem. Aktuálnímu vlastníkovi přiřaďte nové role, aby mohl pokračovat v práci v nástroji Cloud Manager.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Vlastnictví můžete převést na registrovaného uživatele zadáním jeho e-mailové adresy. Aktuálnímu vlastníkovi přiřaďte nové role, aby mohl pokračovat v práci v nástroji Cloud Manager.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `K přidání rozhraní REST API do služby IBM API Connect můžete použít definiční soubor OpenAPI. Formát souboru může být JSON nebo YAML. Když je definice rozhraní API importována, je zobrazena v seznamu definic rozhraní API.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `Tento typ nelze odstranit, protože bude odebrán kořenový typ ('Dotaz').`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `Nemůžete vybrat rozhraní API, jehož typ brány se neshoduje s typem brány tohoto produktu.`,
  [`You currently don’t have any gateway extensions`]: `Momentálně nemáte žádná rozšíření brány.`,
  [`You currently don’t have any plans to migrate`]: `Momentálně nemáte žádné plány na migraci`,
  [`You currently don't have any policy.`]: `Momentálně nemáte žádnou zásadu.`,
  [`You do not have permission to any spaces.`]: `Nemáte oprávnění k žádným prostorům.`,
  [`You do not have permission to publish products to any catalog or space.`]: `Nemáte oprávnění pro publikování produktů do žádného katalogu nebo prostoru.`,
  [`You do not have permission to stage products to any catalog or space.`]: `Nemáte oprávnění pro fázování produktů do žádného katalogu nebo prostoru.`,
  [`You don’t currently have any billing integrations.`]: `Momentálně nemáte žádné integrace s platebním systémem.`,
  [`You don't currently have any response.`]: `Momentálně nemáte žádnou odpověď.`,
  [`You don't currently have any user-defined policies.`]: `V současné době nemáte žádné uživatelem definované zásady.`,
  [`You don't have permission to access this page.`]: `Nemáte oprávnění pro přístup k této stránce.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Tento certifikát jste upravili. Uložením této aplikace se aktualizuje konfigurace.`,
  [`You haven't added any APIs or Products`]: `Nepřidali jste žádná rozhraní API nebo produkty`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Členství v katalogu spravujete přidáním nových uživatelů do katalogu a přiřazením rolí uživatelům.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `Členství v prostoru spravujete přidáním nových uživatelů do prostoru a přiřazením rolí uživatelům.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Členství v prostoru spravujete přidáním nových uživatelů do prostoru a přiřazením rolí uživatelům.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `Rozhraní API musíte přidat do produktu připraveného pro publikování. Produkt shromažďuje sadu rozhraní API a plány do jedné nabídky, kterou zpřístupníte vývojářům. Plán zahrnuje nastavení limitu četnosti, který lze použít na plán jako celek nebo který je uveden pro každou operaci v rozhraní API.`,
  [`You must choose a definition`]: `Musíte vybrat definici`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `Musíte povolit alespoň jednu službu brány, aby rozhraní API publikovaná v tomto katalogu byla k dispozici pro volání v koncovém bodu služby brány. Můžete povolit více služeb brány.`,
  [`You must enable spaces`]: `Musíte povolit prostory`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Při vývoji aplikace byste měli zaznamenat ID klienta a tajný údaj, které jsou uvedeny níže. Tajný klíč klienta nelze znovu zobrazit.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Vaše licence pro vyhrazenou instanci API Connect vás opravňuje ke stažení a nastavení jedné nebo více vzdálených služeb DataPower API Gateway. Brány mohou být umístěny v místním prostoru nebo v libovolném cloudu.`,
  [`Your API has been created`]: `Vaše rozhraní API bylo vytvořeno.`,
  [`Your API is live!`]: `Vaše rozhraní API je aktivní!`,
  [`Your API is online!`]: `Vaše rozhraní API je online!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Odstraňuje se katalog. To může chvíli trvat...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Vytváří se zákaznická organizace. To může chvíli trvat...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Vaše pozvání již není platné. Zkontrolujte nastavení časového limitu pozvánky a znovu odešlete pozvánku.`,
  [`Your Provider organization is being created. This may take a while...`]: `Vytváří se organizace poskytovatele. To může chvíli trvat...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Odstraňuje se organizace poskytovatele. To může chvíli trvat...`,
  [`Your member is being deleted. This may take a while...`]: `Váš člen se vymazává. To může chvíli trvat...`,
  [`Your Space is being deleted. This may take a while...`]: `Odstraňuje se prostor. To může chvíli trvat...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `Poskytovatel OAuth má neuložené změny. Chcete-li pokračovat bez uložení, klepněte na tlačítko OK`,
  [`Your credentials have expired.`]: `Platnost vašich pověření vypršela.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Vaše pozvánka byla úspěšně odeslána s následujícím odkazem na pozvánku:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `Váš požadavek je stále zpracováván a trvá déle, než se očekávalo. Proveďte aktualizaci za několik minut, než se znovu pokusíte o požadavek.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Váš produkt bude před dalším pokračováním uložen, jste si jisti?`,
  [`Your trial ends on {date}`]: `Vaše zkušební verze skončí dne {date}`,
  [`Your trial expired on {date}.`]: `Platnost zkušební verze vypršela dne {date}.`,
  [`Zoom in`]: `Přiblížit`,
  [`Zoom in and out`]: `Přiblížit a oddálit`,
  [`Zoom out`]: `Oddálit`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `nedostatek podpory CORS na cílovém serveru (viz návrh níže)`,
  [`a LDAP provider`]: `poskytovatele LDAP.`,
  [`activity`]: `aktivita`,
  [`add item...`]: `přidat položku...`,
  [`add property...`]: `přidat vlastnost...`,
  [`added`]: `přidáno`,
  [`admin`]: `admin`,
  [`administrator`]: `administrátor`,
  [`all`]: `vše`,
  [`an API Connect Local User Registry`]: `lokálního registru uživatelů služby API Connect.`,
  [`an authentication URL`]: `ověřovací adresy URL.`,
  [`an untrusted certificate has been encountered`]: `byl zjištěn nedůvěryhodný certifikát`,
  [`and`]: `a`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Aplikace:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `schválení`,
  [`approval task for product`]: `úloha schválení pro produkt`,
  [`archived`]: `archivováno`,
  [`authenticated`]: `ověřeno`,
  [`authorization code time to live (seconds)`]: `doba životnosti autorizačního kódu (s)`,
  [`Authorization header pass through`]: `průchod záhlaví autorizace`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `blokující`,
  [`body`]: `tělo`,
  [`body-param`]: `body-param`,
  [`cancel`]: `storno`,
  [`case`]: `případ`,
  [`catalog`]: `Katalog`,
  [`catalog:`]: `Katalog:`,
  [`catch`]: `zachycená položka`,
  [`changed`]: `změněno`,
  [`cipher`]: `šifrování`,
  [`code id_token`]: `id_token kódu`,
  [`code id_token token`]: `token id_token kódu`,
  [`code token`]: `token kódu`,
  [`Consume`]: `Přijímat`,
  [`consumer`]: `spotřebitel`,
  [`consumes`]: `spotřebovává`,
  [`composite`]: `kompozitní`,
  [`created`]: `vytvořeno`,
  [`Created API`]: `Vytvořené rozhraní API`,
  [`custom string`]: `vlastní řetězec`,
  [`day`]: `den`,
  [`day(s)`]: `den(dny)`,
  [`default`]: `výchozí`,
  [`default value`]: `Výchozí hodnota`,
  [`definition`]: `definice`,
  [`deprecated`]: `zamítnuto`,
  [`description`]: `popis`,
  [`development`]: `vývoj`,
  [`edit condition`]: `upravit podmínku`,
  [`edited`]: `upraveno`,
  [`environment`]: `Katalog`,
  [`error`]: `chyba`,
  [`false`]: `false`,
  [`file`]: `soubor`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 icons`,
  [`for`]: `pro`,
  [`header`]: `záhlaví`,
  [`hear`]: `poslech`,
  [`hello`]: `Dobrý den`,
  [`helloName`]: `Dobrý den, uživateli {name}.`,
  [`here`]: `zde`,
  [`hit`]: `úspěch`,
  [`hits`]: `úspěchy`,
  [`hour`]: `hodina`,
  [`hour(s)`]: `hod`,
  [`hours`]: `hod`,
  [`identified`]: `identifikováno`,
  [`Increased`]: `Zvýšený`,
  [`Increment`]: `Zvýšit`,
  [`info`]: `info`,
  [`information about external documentation`]: `informace o externí dokumentaci`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `Namísto respektování nastavení exp claim od poskytovatele OIDC bude přístupový/aktualizační token generovaný APIC respektovat nastavení APIC`,
  [`introspect URL`]: `adresa URL introspekce`,
  [`Issue`]: `Problém`,
  [`Issues`]: `Problémy`,
  [`item`]: `položka`,
  [`iterate over`]: `iterovat nad`,
  [`last modified`]: `poslední změna`,
  [`Log Level`]: `Úroveň protokolování`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `protokoly`,
  [`Logout`]: `Odhlášení`,
  [`Logout redirect`]: `Přesměrování při odhlášení`,
  [`Logout redirect URL`]: `Adresa URL pro přesměrování při odhlášení`,
  [`location`]: `location`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `minuta`,
  [`minute(s)`]: `min`,
  [`minutes`]: `min`,
  [`mutation type`]: `typ mutace`,
  [`mutual SSL authentication is required`]: `je vyžadováno vzájemné ověření SSL`,
  [`name`]: `Název`,
  [`Name|extension`]: `Název`,
  [`Name|path parameters`]: `Název`,
  [`Name|security definitions`]: `Název`,
  [`non-blocking`]: `neblokující`,
  [`none`]: `není`,
  [`none selected`]: `bez výběru`,
  [`Object`]: `Objekt`,
  [`off`]: `vyp`,
  [`of {total} pages`]: `z {total} stránek`,
  [`of {total} page`]: `z {total} stránek`,
  [`offline`]: `offline`,
  [`on`]: `zap`,
  [`online`]: `online`,
  [`or`]: `nebo`,
  [`or redact from all`]: `nebo redigovat ze všech`,
  [`open and close list of options`]: `otevřít a zavřít seznam voleb`,
  [`organization saved`]: `organizace uložena`,
  [`organization-manager`]: `vedoucí_organizace`,
  [`otherwise`]: `jinak`,
  [`payload`]: `informační obsah`,
  [`Payload`]: `Informační obsah`,
  [`pending`]: `nevyřízené`,
  [`produces`]: `produkuje`,
  [`production`]: `produkce`,
  [`production and development`]: `produkce a vývoj`,
  [`property`]: `vlastnost`,
  [`provider`]: `poskytovatel`,
  [`publish product`]: `publikovat produkt`,
  [`published`]: `publikováno`,
  [`query type`]: `typ dotazu`,
  [`query-parameters`]: `parametry dotazu`,
  [`realized`]: `realizováno`,
  [`recipients`]: `příjemci`,
  [`removed`]: `odebráno`,
  [`replaced`]: `zaměněno`,
  [`repeatable`]: `opakovatelné`,
  [`Replenish`]: `Doplnit`,
  [`request`]: `požadavek`,
  [`requested by`]: `vyžádáno od`,
  [`Resolver weight`]: `Váha vyhodnocovacího modulu`,
  [`response`]: `odezva`,
  [`response-param`]: `response-param`,
  [`retired`]: `ukončeno používání`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `uloženo`,
  [`scalar`]: `skalární`,
  [`second`]: `sekunda`,
  [`second(s)`]: `s`,
  [`seconds`]: `s`,
  [`select owner user`]: `vyberte vlastníka uživatele`,
  [`select user`]: `vybrat uživatele`,
  [`soap-body`]: `soap-body`,
  [`source`]: `zdroj`,
  [`space`]: `prostor`,
  [`Space settings`]: `Nastavení prostoru`,
  [`Skip`]: `Přeskočit`,
  [`specified`]: `určeno`,
  [`staged`]: `fázováno`,
  [`start generating tests automatically.`]: `automaticky spustilo generování testů.`,
  [`subject`]: `předmět`,
  [`subscription type`]: `typ odběru`,
  [`supersede`]: `nahradit`,
  [`superseded`]: `nahrazeno`,
  [`target`]: `cíl`,
  [`the server is unavailable`]: `server není k dispozici`,
  [`this user registry type`]: `tohoto typu registru uživatelů`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Toto je dočasná stránka publikování, kde opětovné publikování uchová odběry.`,
  [`to production`]: `do produkce`,
  [`topology-administrator`]: `administrátor-topologie`,
  [`Trial days left`]: `Zbývající zkušení dny`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `ttl bylo změněno`,
  [`type`]: `typ`,
  [`unarchived`]: `archivace zrušena`,
  [`union type`]: `typ sjednocení`,
  [`unlimited`]: `bez omezení`,
  [`updated`]: `aktualizováno`,
  [`Updated from new schema`]: `Aktualizováno z nového schématu`,
  [`Upgrade`]: `Upgrade`,
  [`v5 legacy behavior`]: `Starší chování v5`,
  [`version 2`]: `verze 2`,
  [`version 3`]: `verze 3`,
  [`viewer`]: `prohlížející`,
  [`value`]: `hodnota`,
  [`warn`]: `varování`,
  [`warning`]: `varování`,
  [`warnings`]: `varování`,
  [`was not found. Please select a configured OAuth provider.`]: `nebyl nalezen. Vyberte nakonfigurovaného poskytovatele OAuth.`,
  [`week`]: `týden`,
  [`with`]: `s`,
  [`with|supersede with`]: `s`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `stav v sandboxu`,
  [`your file here, or`]: `soubor na toto místo. Další možnost:`,
  [`{count} found!`]: `Nalezeno: {count}!`,
  [`{current} of {total} page`]: `Stránka {current} z {total}`,
  [`{current} of {total} pages`]: `{current} z {total} stránek`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} dnů, {hours} hodin a {minutes} minut`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} dnů a {hours} hodin`,
  [`{docType} is required`]: `{docType} je povinný`,
  [`{docType} title`]: `Název {docType}`,
  [`Product title`]: `Název produktu`,
  [`Application title`]: `Název aplikace`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} hodin a {minutes} minut`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} brána nebo brány skryty kvůli nekompatibilitě s typem brány uvedeným v rozhraní API nebo produktu`,
  [`{min}-{max} of {total} items`]: `{min}-{max} z {total} položek`,
  [`{min}-{max} of {total} item`]: `Položky {min} - {max} z {total}`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} z {total} zásad`,
  [`{min}-{max} of {total} Policy`]: `Zásady {min} - {max} z {total}`,
  [`{min}-{max} of {total} types`]: `{min}-{max} z {total} typů`,
  [`{min}-{max} of {total} type`]: `Typy {min} - {max} z {total}`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} z {total} výsledků hledání`,
  [`{min}-{max} of {total} search result`]: `Výsledky hledání {min} - {max} z {total}`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} z {total} katalogů`,
  [`{min}-{max} of {total} Catalog`]: `Katalogy {min} - {max} z {total}`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} z {total} prostorů`,
  [`{min}-{max} of {total} Space`]: `Prostory {min} - {max} z {total}`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} z {total} rozšíření`,
  [`{min}-{max} of {total} Extension`]: `Rozšíření {min} - {max} z {total}`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} z {total} plánů produktu`,
  [`{min}-{max} of {total} Product Plan`]: `Plány produktů {min} - {max} z {total}`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} z {total} bran`,
  [`{min}-{max} of {total} Gateway`]: `Brány {min} - {max} z {total}`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (Rozhraní API) chybí 'x-ibm-configuration'`,
  [`{name} (Product) has been published!`]: `{name} (Produkt) byl publikován!`,
  [`{name} (Product) has been staged!`]: `{name} (Produkt) byl fázován!`,
  [`{name} (Product) has not been published!`]: `{name} (Produkt) nebyl publikován!`,
  [`{name} (Role) has been created`]: `{name} (Role) byla vytvořena.`,
  [`{name} successfully deleted`]: `{name} byl úspěšně odstraněn.`,
  [`{name} (Role) has been updated`]: `{name} (Role) byla aktualizována.`,
  [`{name} has been assigned the owner`]: `Organizaci {name} byl přiřazen vlastník`,
  [`{number} Error`]: `{number} Chyba`,
  [`{number} Errors`]: `{number} Chyby`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `Úloha schválení operace {operation} pro produkt {prodname}:{version} požadovaná uživatelem {username}`,
  [`{path} does not exist`]: `{path} neexistuje`,
  [`{productName} has no migration target set.`]: `{productName} nemá žádné nastavení cíle migrace.`,
  [`{state} Pending`]: `{state} - Nevyřízeno`,
  [`{status} API error`]: `{status} - chyba rozhraní API`,
  [`{status} Bad Request`]: `{status} - Chybný požadavek`,
  [`{status} Forbidden`]: `{status} - Zakázáno`,
  [`{status} Gateway Error`]: `{status} - Chyba brány`,
  [`{status} Unauthorized`]: `{status} - Neautorizováno`,
  [`({time} after being sent)`]: `({time} po odeslání)`,
  [`({time} after being queued)`]: `({time} po zařazení do fronty)`,
  [`{title} (Product) has been {state}.`]: `{title} (Produkt) byl ve stavu {state}.`,
  [`{title} has been deleted`]: `Položka {title} byla odstraněna.`,
  [`{title} has been {changedName}.`]: `Pro položku {title} byla provedena akce {changedName}.`,
  [`{title} has not been deleted!`]: `Položka {title} nebyla odstraněna!`,
  [`{title} has not been {changedName}!`]: `Pro položku {title} nebyla provedena akce {changedName}!`,
  [`{title} {changedName}.`]: `Pro položku {title} byla provedena akce {changedName}.`,
  [`{type} has been {changedName}.`]: `Pro typ {type} byla provedena akce {changedName}.`,
  [`{type} has not been {changedName}!`]: `Pro typ {type} nebyla provedena akce {changedName}!`,
  [`{type} history log`]: `Protokol historie pro typ {type}`,
  [`{username} does not exist`]: `Uživatel {username} neexistuje.`,
  [`{username} does not exist in the user registry`]: `Uživatel {username} neexistuje v registru uživatelů.`,
  [`{username} is not associated with this consumer org`]: `Uživatel {username} není přidružený k této zákaznické organizaci`,
  [`{username} has been created as a {type}.`]: `Uživatel {username} byl vytvořen jako typ {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `Aplikace {appName} byla znovu obnovena v nástroji Developer Portal {consumerOrg}`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `Aplikace {appName} byla pozastavena v nástroji Developer Portal {consumerOrg}`,
  [`{{catalogTitle}} developer portal account registration`]: `Registrace účtu nástroje Developer Portal {catalogTitle}`,
  [`{{count}} errors need attention`]: `{count} chyb vyžaduje pozornost`,
  // lts only translations
  [`Configure JSON settings`]: `Konfigurovat nastavení JSON`,
  [`Configure XML settings`]: `Konfigurovat nastavení XML`,
  [`Configure options`]: `Konfigurovat volby`,
  [`Detect`]: `Zjistit`,
  [`Document type`]: `Typ dokumentu`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Maximální velikost dokumentu`,
  [`Maximum name length`]: `Maximální délka názvu`,
  [`Maximum nesting depth`]: `Maximální hloubka vnoření`,
  [`Maximum number length`]: `Maximální délka čísla`,
  [`Maximum number of unique names`]: `Maximální počet jedinečných názvů`,
  [`Maximum number of unique namespaces`]: `Maximální počet jedinečných oborů názvů`,
  [`Maximum number of unique prefixes`]: `Maximální počet jedinečných předpon`,
  [`Maximum value length`]: `Maximální délka hodnoty`,
  [`Maximum width`]: `Maximální šířka`,
  [`Maximum stack size`]: `Maximální velikost zásobníku`,
  [`Parse settings`]: `Nastavení analýzy`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Určuje maximální velikost dokumentu v bajtech, kterou akceptuje akce analýzy.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Určuje maximální úroveň hloubky vnořeného prvku ve zprávě XML nebo JSON, kterou akceptuje akce analýzy.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Určuje maximální úroveň hloubky vnořeného prvku ve zprávě XML, JSON nebo GraphQL, kterou akceptuje akce analýzy.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Určuje maximální délku názvu v bajtech, kterou akceptuje akce analýzy.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Určuje maximální délku čísla, která omezuje počet bajtů v části hodnoty u páru popisek-hodnota, když je hodnota číslem.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Určuje maximální počet jedinečných identifikátorů URI oborů názvů XML, který akceptuje akce analýzy. Tento limit započítá všechny obory názvů XML bez ohledu na to, kolik předpon je použito k jejich deklaraci.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Určuje maximální počet jedinečných předpon oborů názvů XML v dokumentu, který akceptuje akce analýzy. Tento limit započítá násobné předpony definované pro stejný obor názvů, nezapočítá ale násobné obory názvů definované v různých částech vstupního dokumentu pod jednou předponou.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Určuje maximální počet jedinečných názvů, který akceptuje akce analýzy.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Určuje maximální hodnotu v bajtech, kterou akceptuje akce analýzy.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Určuje maximální šířku informačního obsahu, kterou akceptuje akce analýzy.`,
  [`Specifies the type of document to parse.`]: `Uvádí typ dokumentu k analýze.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Určuje verzi procesoru XSLT. Výchozí hodnota je XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Určuje, zda má být povolena striktní kontrola chyb XSLT. Nestriktní operace se pokusí o zotavení z určitých chyb, jako např. použití nedeklarovaných proměnných, volání nedeklarovaných šablon atd. Standardně je povolena striktní kontrola chyb XSLT.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Určuje, zda se má povolit profilování seznamu stylů. Tato volba by neměla být použita v produkčních prostředích. Standardně je profilování seznamu stylů vypnuté.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Určuje, zda se má spustit seznam stylů, skript XQuery a skript JSONiq v režimu ladění. Je-li seznam stylů, skript XQuery nebo skript JSONiq spuštěn v režimu ladění, vygeneruje vlastní webovou stránku místo zobrazení jeho normálního výstupu. Webová stránka podrobně popisuje, co se stalo během provádění, včetně hodnot proměnných, a odkud pocházejí konkrétní části výstupu. Tato volba by neměla být použita v produkčních prostředích. Standardně je režim ladění vypnut.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Určuje, zda seznam stylů musí být spuštěn v režimu streamování. Transformace dokumentu začíná před plně analyzovaným vstupem. Ne všechny seznamy stylů mohou být streamované. Pokud seznam stylů nemůže být streamován, vygeneruje se chyba, a vstup se nezpracuje. Standardně je režim streamování zakázán.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Určuje, zda se má pokus o spuštění seznamu stylů v režimu streamování. Transformace dokumentu začíná před plně analyzovaným vstupem. Ne všechny seznamy stylů mohou být streamované. Jestliže seznam stylů nemůže být streamován, je vygenerováno varování během kompilace a seznam stylů se čte v celém vstupu jako normální v době provádění. Standardně je pokus o spuštění seznamu stylů v režimu streamování zakázán.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Určuje, zda se má výstup změny významu během zpracování vytvořit ze seznamu stylů. Minimální změna významu je zvláště užitečná při zpracování neanglických znakových sad. Standardně je minimální změna významu zakázána.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Označuje maximální počet bajtů, které může zásobník použít při provádění seznamu stylů nebo jiného kompilovaného obsahu. Toto nastavení se používá k blokování nekonečné rekurze. Minimální hodnota je 10 kilobajtů nebo 10 240 bajtů. Maximální hodnota je 100 megabajtů nebo 104 857 600 bajtů. Výchozí hodnota je 1 megabajt nebo 1 048 576 bajtů.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Určuje chování ověření platnosti, které se má použít na soubory WSDL, které jsou zkontrolovány kvůli shodě s oddílem 5 základního profilu WS-I (verze 1.0, duben 2004). Výchozí nastavení je Varovat.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Určuje chování ověření pro soap:Body. Výchozí nastavení je Strict.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Určuje chování ověření pro soap:Header. Výchozí nastavení je Lax.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Určuje chování ověření pro podrobnosti o poruchách. Výchozí nastavení je Strict.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Určuje, zda má být vyžadována kompatibilita s moduly wrapper ve stylu RPC. Standardně nejsou moduly wrapper ve stylu RPC vyžadovány.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Určuje, zda se má povolit, aby schéma akceptovalo většinu použití prvků s xsi:type= 'SOAP-ENC:Array' konzistentních s oddílem 5 SOAP 1.1, i když tyto atributy porušují specifikaci schématu XML. Běžně atribut xsi:type musí pojmenovat typ, který je stejný jako skutečný typ prvku nebo je od něj odvozený. Pro schémata kompilovaná pomocí této volby je xsi:type přijat specificky pro komplexní typ 'Array' kódování SOAP 1.1, je-li typ prvku odvozen od SOAP-ENC:Array. Normálně bývá povolen opak. Standardně nejsou prvky s xsi:type='SOAP-ENC:Array' přijaty.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Určuje, zda provést další ověření schématu podle pravidel kódování v oddílu 5 protokolu SOAP 1.1. Je-li tato volba povolena, jsou ověřovány členy polí SOAP, atributy jako @id a @href jsou povoleny i v případě, že nejsou povoleny schématem, a hodnoty @href jsou kontrolovány, aby se zajistilo, že mají odpovídající prvek @id. Standardně není provedeno další ověření.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Určuje, zda mají prvky xs:any ve schématu ověřit pouze podřízené prvky podle názvu. Specifikace schématu XML v situaci, kdy zástupný znak odpovídá prvku, ale tento prvek nemá deklaraci prvku, vyžaduje, aby byl místo toho ověřován podle atributu xsi:type na něm. Tato volba ignoruje atributy xsi:type. Měla by být použita pro případy, jako je ověření obálky SOAP, kde další krok ověření ověří obsah odpovídající zástupnému znaku, případně použití pravidel kódování SOAP 1.1. Standardně nejsou atributy xsi:type ignorovány.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Určuje, zda se má striktně dodržovat vazba SOAP ve WSDL. Je-li tato volba povolena, zobrazují se v obálkách SOAP 1.2 pouze zprávy vázané na SOAP 1.2 a pouze zprávy vázané na SOAP 1.1 se zobrazují v obálkách SOAP 1.1. Standardně je striktní vazba SOAP zakázána.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Uvádí, zda se mají kompilovat zásady XACML s informacemi o ladění. Všimněte si, že zprávy ladění XACML jsou také řízeny protokolovanou událostí v kategorii XACML. Použijte úroveň protokolu ladění k zobrazení úplných zpráv ladění XACML. Standardně nejsou zásady XACML kompilovány s informacemi o ladění.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Určuje, zda schéma nebo dokument WSDL přijímá zprávy, kde binární obsah kódovaný dle base64 byl optimalizován podle specifikací MTOM/XOP. Binární optimalizace XOP nahrazuje binární data kódovaná dle base64 pomocí prvku odkazu xop:Include, který odkazuje na nezakódovaná binární data umístěná v příloze. Standardně jsou optimalizované zprávy MTOM/XOP zakázány.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Skupina zákaznických organizací je kolekce zákaznických organizací a poskytuje efektivní způsob řízení toho, kdo může zobrazovat a odebírat rozhraní API ve vašich produktech. Pomocí skupiny zákaznických organizací můžete definovat tento přístup pro všechny vývojáře v organizacích v této skupině v rámci jedné operace, spíše než abyste museli definovat přístup pro organizace samostatně.`,
  [`API Connect Home`]: `API Connect - domovské umístění`,
  [`Add new group`]: `Přidat novou skupinu`,
  [`Add consumer organizations to the group as required`]: `Podle potřeby přidejte zákaznické organizace do skupiny`,
  [`Add the following configuration`]: `Přidat následující konfiguraci`,
  [`Add the following configuration to`]: `Přidat následující konfiguraci do`,
  [`Additions`]: `Dodatky`,
  [`Additional GraphQL capabilities`]: `Další schopnosti GraphQL`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Další nastavení pro rozhraní graphQL API, která jsou zahrnuta do tohoto plánu`,
  [`An organization or a group is required.`]: `Je vyžadována organizace nebo skupina.`,
  [`An organization/group is required.`]: `Je vyžadována organizace/skupina.`,
  [`Analysis configuration`]: `Konfigurace analýzy`,
  [`Application & Credentials`]: `Pověření & aplikace`,
  [`Are you sure you want to delete this API version?`]: `Opravdu chcete odstranit tuto verzi rozhraní API?`,
  [`Are you sure you want to delete this API with all versions?`]: `Opravdu chcete odstranit toto rozhraní API se všemi verzemi?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Opravdu chcete odebrat rozšíření brány z brány?`,
  [`Are you sure you want to rename all versions of this API?`]: `Opravdu chcete přejmenovat všechny verze tohoto rozhraní API?`,
  [`Are you sure you want to rename this API version?`]: `Opravdu chcete přejmenovat tuto verzi rozhraní API?`,
  [`Associate groups to the organization as required`]: `Přidružit skupiny k organizaci podle potřeby`,
  [`At least one option must be enabled`]: `Musí být povolena alespoň jedna volba`,
  [`Attribute mapping`]: `Mapování atributu`,
  [`Attempt streaming rule`]: `Pokus o pravidlo streamování`,
  [`Auto generated default paths and operations`]: `Automaticky generované výchozí cesty a operace`,
  [`Base API endpoint`]: `Základní koncový bod rozhraní API`,
  [`Consumer orgs`]: `Zákaznické organizace`,
  [`Calculate Query Characteristics`]: `Vypočítat charakteristiky dotazu`,
  [`Calculate Response Characteristics`]: `Vypočítat charakteristiky odezvy`,
  [`Certificate or Shared Secret for Verify`]: `Certifikát nebo sdílený tajný klič pro ověření`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Prozkoumejte naše praktické testovací pracoviště, kde můžete zažádat o vyzkoušení všech schopností správy API po dobu až jednoho týdne!`,
  [`Clone API`]: `Klonovat rozhraní API`,
  [`Consumer organizations and groups`]: `Zákaznické organizace a skupiny`,
  [`Create new API version`]: `Vytvořit novou verzi rozhraní API`,
  [`Create new product version`]: `Vytvořit novou verzi produktu`,
  [`Create a GraphQL API based on a GraphQL service`]: `Vytvořit rozhraní GraphQL API na základě služby GraphQL`,
  [`Custom rate limit`]: `Vlastní limit četnosti`,
  [`Delete API`]: `Odstranit rozhraní API`,
  [`Delete this version`]: `Odstranit tuto verzi`,
  [`Deletion confirmation`]: `Potvrzení odstranění`,
  [`Deletions`]: `Odstranění`,
  [`Display name`]: `Zobrazovaný název`,
  [`Display Name`]: `Zobrazovaný název`,
  [`Details`]: `Podrobnosti`,
  [`Edit GraphQL Rate Limit|title`]: `Upravit název/limit četnosti GraphQL`,
  [`Edit GraphQL rate limits`]: `Upravit limity četnosti GraphQL`,
  [`Edit group`]: `Upravit skupinu`,
  [`Edit condition`]: `Upravit podmínku`,
  [`Edit rate limit|title`]: `Upravit limit četnosti`,
  [`Edit rate limit|menu`]: `Upravit limit četnosti`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Buď neexistují žádné produkty publikované do tohoto katalogu, nebo je tato aplikace již přihlášena k odběru všech plánů ve všech produktech.`,
  [`Enforce Policy`]: `Vynutit zásadu`,
  [`Enter a group summary`]: `Zadat souhrn skupiny`,
  [`Enter a group title`]: `Zadat název skupiny`,
  [`Enter group details below`]: `Níže zadejte podrobnosti skupiny`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Zadejte adresu URL a název schématu pro službu GraphQL požadované pro server proxy`,
  [`Enum value`]: `Výčtová hodnota`,
  [`Go to catalog settings to configure a new billing integration`]: `Přejít na nastavení katalogu pro konfiguraci nové integrace účtování`,
  [`GraphQL introspect`]: `Introspekce GraphQL`,
  [`GraphQL server`]: `Server GraphQL`,
  [`GraphQL Setting`]: `Nastavení GraphQL`,
  [`GraphQL server URL`]: `Adresa URL serveru GraphQL`,
  [`GraphQL server name`]: `Název serveru GraphQL`,
  [`GraphQL type analysis configuration`]: `Konfigurace analýzy typu GraphQL`,
  [`GraphQL resolver analysis configuration`]: `Konfigurace analýzy vyhodnocovacího modulu GraphQL`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Skupiny lze vytvářet a spravovat v následujícím seznamu.`,
  [`Groups will be listed here.`]: `Zde budou uvedeny skupiny.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Dobrý den, {firstName},\n\nváš požadavek na provedení akce {action} pro produkt API {productName}:{version} v katalogu\n{catalog} byl schválen.\n\n{#if comments}\nSchvalovatel zahrnul následující komentáře:\n\n{comments}\n\n{{/if}}\nZde můžete zkontrolovat stav produktu:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Dobrý den,\n\ntoto je testovací zpráva ze služby IBM API Connect z nakonfigurovaného poštovního serveru\n(mailServer.\n\nPokud jste obdrželi tuto zprávu podle očekávání, test byl úspěšný!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Dobrý den,\n\nuživatel {originator} stáhl svůj požadavek na přihlášení k odběru aplikace {appName}\npro plán {planName} verze {productVersion} produktu API {productName} v katalogu\n{catalog}.\n\nNení zapotřebí žádné další akce.\n`,
  [`Invoke GraphQL server`]: `Vyvolat server GraphQL`,
  [`Invoke the backend GraphQL server`]: `Vyvolat server typu back-end GraphQL`,
  [`JWKS endpoint`]: `Koncový bod jWKS`,
  [`LDAP Field Name`]: `Název pole protokolu LDAP`,
  [`Limit maximum nesting per query`]: `Omezit maximální počet vnoření na dotaz`,
  [`Limit resolve complexity rate on a per key basis`]: `Omezit míru složitosti řešení pro jednotlivé klíče`,
  [`Limit type complexity rate on a per key basis`]: `Omezit míru složitosti typu pro jednotlivé klíče`,
  [`Manage APIs included in this product`]: `Spravovat rozhraní API zahrnutá do tohoto produktu`,
  [`Manage groups`]: `Spravovat skupiny`,
  [`Maximum nesting`]: `Maximální vnoření`,
  [`Maximum resolve complexity`]: `Maximální složitost řešení`,
  [`Maximum type complexity`]: `Maximální složitost typu`,
  [`Name of the group`]: `Název skupiny`,
  [`New API version`]: `Nová verze rozhraní API`,
  [`New GraphQL API`]: `Nové rozhraní GraphQL API`,
  [`New product version`]: `Nová verze produktu`,
  [`New Version`]: `Nová verze`,
  [`New version`]: `Nová verze`,
  [`No billing integration exists`]: `Neexistuje žádná integrace s platebním systémem`,
  [`No CA bundles available`]: `Nejsou k dispozici žádné balíky CA`,
  [`No Data`]: `Žádná data`,
  [`No certificates available`]: `Nejsou k dispozici žádné certifikáty`,
  [`No data`]: `Žádná data`,
  [`No group found`]: `Nebyla nalezena žádná skupina`,
  [`OAuth Auth URL`]: `URL autorizace pro OAuth`,
  [`OAuth Token URL`]: `URL tokenu pro OAuth`,
  [`Open Stripe dashboard`]: `Otevřít řídicí panel systému Stripe`,
  [`Operation/Path`]: `Operace/cesta`,
  [`Operation Path`]: `Cesta operace`,
  [`Operations and Paths`]: `Operace a cesty`,
  [`Operator`]: `Operátor`,
  [`Organizations and groups will be listed here.`]: `Zde budou uvedeny organizace a skupiny.`,
  [`Page not found`]: `Stránka nebyla nalezena`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Chcete-li pokračovat v procesu, zkopírujte kód níže do rozhraní CLI. Platnost kódu vyprší za **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Podívejte se na řídicí panel systému Stripe, abyste získali přístup ke klíči rozhraní API a tajným údajům`,
  [`Please upload a schema definition language (SDL) file.`]: `Odešlete soubor jazyka definice schématu (SDL).`,
  [`Please upload a valid GraphQL schema.`]: `Odešlete platné schéma GraphQL.`,
  [`Please upload a valid YAML file.`]: `Odešlete platný soubor YAML.`,
  [`Populate Server Configuration into Context Variables`]: `Naplnit konfiguraci serveru do kontextových proměnných`,
  [`POST/GET a query to be validated and sent to the backend server`]: `Odeslat dotaz, který má být ověřen a odeslán na server typu back-end, metodou POST/GET`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `Odeslat dotaz metodou POST/GET pro získání odhadovaných nákladů na vyvolání tohoto dotazu`,
  [`Preparing`]: `Příprava`,
  [`Rate limit amount cannot be negative`]: `Hodnota limitu četnosti nemůže být záporná`,
  [`Rate limit per cannot be negative`]: `Časový úsek limitu četnosti nemůže být záporný`,
  [`Rename API`]: `Přejmenovat rozhraní API`,
  [`Rename`]: `Přejmenovat`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Požadavek na schválení akce {action} produktu API v katalogu {catalog}`,
  [`Sandbox Sample Application`]: `Ukázková aplikace sandboxu`,
  [`Sandbox sample application credentials`]: `Pověření ukázkové aplikace sandboxu`,
  [`sample application credentials`]: `Pověření ukázkové aplikace`,
  [`Saving`]: `Ukládání`,
  [`Search Orgs`]: `Hledat organizace`,
  [`Search definitions...`]: `Hledat definice...`,
  [`Search errors...`]: `Hledat chyby...`,
  [`Search operations...`]: `Hledat operace...`,
  [`Search organizations and groups`]: `Hledat organizace a skupiny`,
  [`Sorry, This feature is not available in`]: `Litujeme, tato funkce již není k dispozici`,
  [`Stopping`]: `Zastavení`,
  [`The user with username {username} already exists in the user registry.`]: `Uživatel se jménem uživatele {username} již v registru uživatelů existuje.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Existují změny rozhraní API, které nejsou spuštěny. Toto rozhraní API znovu publikujte do katalogu sandboxů.`,
  [`There are no plans available for subscription.`]: `Pro odběr nejsou k dispozici žádné plány.`,
  [`There are no attributes yet.`]: `Dosud neexistují žádné atributy.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Zadáním přidejte zákaznické organizace do skupiny`,
  [`Type to add groups to the organization`]: `Zadáním přidejte skupiny do organizace`,
  [`Type to add organizations from {catalog}`]: `Zadáním přidejte organizace z katalogu {catalog}`,
  [`Type to add organizations or groups`]: `Zadáním přidejte organizace nebo skupiny`,
  [`Type to add organizations or groups from {catalog}`]: `Zadáním přidejte organizace nebo skupiny z katalogu {catalog}`,
  [`Type to add {title} to groups`]: `Zadáním přidejte {title} do skupin`,
  [`Update Rate Limits`]: `Aktualizovat limity četnosti`,
  [`Upload the YAML file with configuration`]: `Odeslat soubor YAML s konfigurací`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Použít ukázku níže jako šablonu pro konfiguraci vašeho serveru GraphQL`,
  [`User managed`]: `Spravovaný uživatel`,
  [`URL {url} does not contain valid OpenAPI.`]: `Adresa URL {url} neobsahuje platné OpenAPI.`,
  [`Validating`]: `Ověření`,
  [`View analytics and performance metrics for your APIs`]: `Zobrazit analytické a výkonnostní metriky pro vaše rozhraní API`,
  [`View and add custom policies to the catalog`]: `Zobrazit a přidat vlastní zásady do katalogu`,
  [`View and add custom exentions to the catalog`]: `Zobrazit a přidat vlastní rozšíření do katalogu`,
  [`View user registries and OAuth providers`]: `Zobrazit registry uživatelů a poskytovatele OAuth`,
  [`View your application and manage API credentials`]: `Zobrazení vaší aplikace a správa pověření k rozhraní API`,
  [`Welcome to the API Connect Trial`]: `Vítejte ve zkušební verzi API Connect Trial`,
  [`You haven't added any APIs`]: `Nemáte přidána žádná rozhraní API`,
  [`You currently don’t have any {resource}.`]: `Momentálně nemáte žádné {resource}.`,
  [`plans to migrate`]: `plány na migraci`,
  [`gateway extensions`]: `rozšíření brány`,
  [`application`]: `aplikace`,
  [`application authentication source`]: `zdroj ověření aplikace`,
  [`API or product`]: `rozhraní API nebo produkt`,
  [`base endpoints`]: `základní koncové body`,
  [`categories`]: `kategorie`,
  [`certificates`]: `certifikáty`,
  [`channels`]: `kanály`,
  [`consumer organizations`]: `zákaznické organizace`,
  [`consumer organization group`]: `skupina zákaznických organizací`,
  [`consumer organizations or groups`]: `zákaznické organizace nebo skupiny`,
  [`correlation ids`]: `ID korelace`,
  [`cors policy`]: `zásada CORS`,
  [`definitions`]: `definice`,
  [`examples`]: `příklady`,
  [`gateway services`]: `služby brány`,
  [`groups`]: `skupiny`,
  [`headers`]: `záhlaví`,
  [`links`]: `odkazy`,
  [`member`]: `člen`,
  [`messages`]: `zprávy`,
  [`message traits`]: `vlastnosti zprávy`,
  [`operation traits`]: `vlastnosti operace`,
  [`parameter definitions`]: `definice parametru`,
  [`parameters`]: `parametry`,
  [`path`]: `path`,
  [`properties`]: `vlastnosti`,
  [`provider organizations`]: `organizace poskytovatele`,
  [`request bodies`]: `těla požadavků`,
  [`response definitions`]: `definice odezvy`,
  [`responses`]: `odezvy`,
  [`role`]: `role`,
  [`scope`]: `rozsah`,
  [`scopes`]: `rozsahy`,
  [`schemas`]: `schémata`,
  [`schema definition`]: `definice schématu`,
  [`schemes list`]: `seznam schémat`,
  [`security definitions`]: `definice zabezpečení`,
  [`security schemes`]: `schémata zabezpečení`,
  [`servers`]: `servery`,
  [`services`]: `služby`,
  [`tags`]: `značky`,
  [`tags and external documentation`]: `značky a externí dokumentace`,
  [`target services`]: `cílové služby`,
  [`tasks`]: `úlohy`,
  [`user registries`]: `registry uživatelů`,
  [`HTTP header`]: `záhlaví HTTP`,
  [`You currently don’t have any application authentication source.`]: `Momentálně nemáte žádný zdroj ověření aplikace.`,
  [`You currently don’t have any API or product.`]: `Momentálně nemáte žádné rozhraní API nebo produkt.`,
  [`You currently don’t have any API user registries`]: `Momentálně nemáte žádné registry uživatelů rozhraní API.`,
  [`You currently don’t have any APIs.`]: `Momentálně nemáte žádná rozhraní API.`,
  [`You currently don’t have any TLS client profiles`]: `Momentálně nemáte žádné profily klienta TLS.`,
  [`You currently don’t have any base endpoints.`]: `Momentálně nemáte žádné základní koncové body.`,
  [`You currently don’t have any catalog properties.`]: `Momentálně nemáte žádné vlastnosti katalogu.`,
  [`You currently don’t have any categories.`]: `Momentálně nemáte žádné kategorie.`,
  [`You currently don’t have any certificates.`]: `Momentálně nemáte žádné certifikáty.`,
  [`You currently don’t have any channels.`]: `Momentálně nemáte žádné kanály.`,
  [`You currently don’t have any consumer organizations.`]: `Momentálně nemáte žádné zákaznické organizace.`,
  [`You currently don’t have any consumer organizations or groups.`]: `Momentálně nemáte žádné zákaznické organizace nebo skupiny.`,
  [`You currently don’t have any consumes.`]: `Momentálně nemáte žádné odběry.`,
  [`You currently don’t have any correlation ids.`]: `Momentálně nemáte žádná ID korelace.`,
  [`You currently don’t have any cors policy.`]: `Momentálně nemáte žádnou zásadu CORS.`,
  [`You currently don’t have any definitions.`]: `Momentálně nemáte žádné definice.`,
  [`You currently don’t have any examples.`]: `Momentálně nemáte žádné příklady.`,
  [`You currently don’t have any gateway services.`]: `Momentálně nemáte žádné služby brány.`,
  [`You currently don’t have any groups.`]: `Momentálně nemáte žádné skupiny.`,
  [`You currently don’t have any headers.`]: `Momentálně nemáte žádná záhlaví.`,
  [`You currently don’t have any keystore.`]: `Momentálně nemáte žádné úložiště klíčů.`,
  [`You currently don’t have any links.`]: `Momentálně nemáte žádné odkazy.`,
  [`You currently don’t have any messages.`]: `Momentálně nemáte žádné zprávy.`,
  [`You currently don’t have any message traits.`]: `Momentálně nemáte žádné vlastnosti zpráv.`,
  [`You currently don’t have any OAuth providers`]: `Momentálně nemáte žádné poskytovatele OAuth.`,
  [`You currently don’t have any operation traits.`]: `Momentálně nemáte žádné vlastnosti operací.`,
  [`You currently don’t have any parameter definitions.`]: `Momentálně nemáte žádné definice parametrů.`,
  [`You currently don’t have any parameters.`]: `Momentálně nemáte žádné parametry.`,
  [`You currently don’t have any preserved request header.`]: `Momentálně nemáte žádné zachované záhlaví požadavku.`,
  [`You currently don’t have any preserved response header.`]: `Momentálně nemáte žádné zachované záhlaví odezvy.`,
  [`You currently don’t have any produces.`]: `Momentálně nemáte žádné produkty.`,
  [`You currently don’t have any properties.`]: `Momentálně nemáte žádné vlastnosti.`,
  [`You currently don’t have any provider organizations.`]: `Momentálně nemáte žádné organizace poskytovatele.`,
  [`You currently don’t have any request bodies.`]: `Momentálně nemáte žádná těla požadavků.`,
  [`You currently don’t have any response definitions.`]: `Momentálně nemáte žádné definice odezvy.`,
  [`You currently don’t have any responses.`]: `Momentálně nemáte žádné odpovědi.`,
  [`You currently don’t have any scopes.`]: `Momentálně nemáte žádné rozsahy.`,
  [`You currently don’t have any schemas.`]: `Momentálně nemáte žádná schémata.`,
  [`You currently don’t have any schemes list.`]: `Momentálně nemáte žádný seznam schémat.`,
  [`You currently don’t have any security definitions.`]: `Momentálně nemáte žádné definice zabezpečení.`,
  [`You currently don’t have any security schemes.`]: `Momentálně nemáte žádná schémata zabezpečení.`,
  [`You currently don’t have any servers.`]: `Momentálně nemáte žádné servery.`,
  [`You currently don’t have any services.`]: `Momentálně nemáte žádné služby.`,
  [`You currently don’t have any tags.`]: `Momentálně nemáte žádné značky.`,
  [`You currently don’t have any tags and external documentation.`]: `Momentálně nemáte žádné značky a externí dokumentaci.`,
  [`You currently don’t have any target services.`]: `Momentálně nemáte žádné cílové služby.`,
  [`You currently don’t have any tasks.`]: `Momentálně nemáte žádné úlohy.`,
  [`You currently don’t have any truststore.`]: `Momentálně nemáte žádné úložiště údajů o důvěryhodnosti.`,
  [`You currently don’t have any user registries.`]: `Momentálně nemáte žádné registry uživatelů.`,
  [`You currently don’t have any extensions.`]: `Momentálně nemáte žádná rozšíření.`,
  [`You currently don’t have any HTTP header.`]: `Momentálně nemáte žádné záhlaví HTTP.`,
  [`Your account is being activated. This may take a while...`]: `Váš účet je aktivován. To může chvíli trvat...`,
  [`all of`]: `vše z`,
  [`any of`]: `cokoli z`,
  [`calls`]: `volání`,
  [`ciphers`]: `šifry`,
  [`extensions`]: `rozšíření`,
  [`one of`]: `jeden z`,
  [`shared`]: `sdíleno`,
  [`stopping`]: `zastavení`,
  [`to:`]: `na:`,
  [`Key`]: `Klíč`,
  [`kind`]: `druh`,
  [`Trace`]: `Trasování`,
  [`Http Status`]: `Stav Http`,
  [`Parsed`]: `Analyzováno`,
  [`Raw`]: `Prvotní`,
  [`Showing Full Trace`]: `Zobrazení úplného trasování`,
  [`Show Entire Trace`]: `Zobrazit celé trasování`,
  [`Show advanced`]: `Zobrazit rozšířené`,
  [`Advanced`]: `Rozšířené`,
  [`Sending Request`]: `Odesílání požadavku`,
  [`Please send a request above`]: `Odešlete požadavek výše`,
  [`Please send a request above.`]: `Odešlete výše požadavek.`,
  [`Authentication Type`]: `Typ ověřování`,
  [`You don't currently have any response`]: `Momentálně nemáte žádnou odezvu`,
  [`You don't currently have any trace.`]: `V současné době nemáte žádné stopy.`,
  [`No response received`]: `Nebyla přijata žádná odezva`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Klepnutím na volbu "Otevřít odkaz k přijetí výjimky" otevřete server na nové kartě. Pokud prohlížeč zobrazí problém s certifikátem, můžete jej přijmout a vrátit se sem, chcete-li test zopakovat.`,
  [`Open link to accept exception`]: `Otevřít odkaz k přijetí výjimky`,
  [`Back to {name}`]: `Zpět na {name}`,
  [`0 seconds`]: `0 sekund`,
  [`close.menu`]: `Zavřít nabídku`,
  [`open.menu`]: `Otevřít nabídku`,
  [`Referenced from "{ref}"`]: `Odkazováno z "{ref}"`,
  [`Go to {definition}`]: `Přejít na {definition}`,
  [`General`]: `Obecné`,
  [`Servers`]: `Servery`,
  [`External Docs`]: `Externí dokumenty`,
  [`Components`]: `Komponenty`,
  [`Schemas`]: `Schémata`,
  [`Responses`]: `Odezvy`,
  [`Examples`]: `Příklady`,
  [`Request Bodies`]: `Těla požadavků`,
  [`Security Schemes`]: `Schémata zabezpečení`,
  [`Links`]: `Odkazy`,
  [`Callbacks`]: `Zpětná volání`,
  [`Terms of Service`]: `Podmínky služby`,
  [`Contact Name`]: `Jméno kontaktu`,
  [`Contact URL`]: `Adresa URL kontaktu`,
  [`Contact Email`]: `E-mail kontaktu`,
  [`Contact sales`]: `Kontaktovat prodejní oddělení`,
  [`License Name`]: `Název licence`,
  [`License URL`]: `Adresa URL licence`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `Přijmout optimalizované zprávy MTOM/XOP`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `token`,
  [`Tag`]: `Značka`,
  [`Request Body`]: `Tělo požadavku`,
  [`Security Requirements`]: `Požadavky na zabezpečení`,
  [`Located In`]: `Umístěno v`,
  [`Example Value`]: `Vzorová hodnota`,
  [`External Value`]: `Externí hodnota`,
  [`Server`]: `Server`,
  [`Server Description`]: `Popis serveru`,
  [`Server Variables`]: `Proměnné serveru`,
  [`Format`]: `Formát`,
  [`Pattern`]: `Vzor`,
  [`Multiple Of`]: `Několik`,
  [`Multiple non null slicing arguments`]: `Více argumentů ořezání jiných než null`,
  [`Missing slicing arguments`]: `Chybějící argumenty ořezání`,
  [`Missing sized fields`]: `Chybějící pole vhodné velikosti`,
  [`Maximum`]: `Maximum`,
  [`Exclusive Maximum`]: `Výlučné maximum`,
  [`Minimum`]: `Minimum`,
  [`Exclusive Minimum`]: `Výlučné minimum`,
  [`Max Length`]: `Maximální délka`,
  [`Min Length`]: `Minimální délka`,
  [`Max Items`]: `Maximum položek`,
  [`Min Items`]: `Minimum položek`,
  [`Unique Items`]: `Jedinečné položky`,
  [`Max Properties`]: `Maximum vlastností`,
  [`Min Properties`]: `Minimum vlastností`,
  [`Required Properties`]: `Povinné vlastnosti`,
  [`Required Property Name`]: `Název povinné vlastnosti`,
  [`Enum`]: `Výčet`,
  [`Enum Value`]: `Výčtová hodnota`,
  [`Nullable`]: `S povolenou hodnotou Null`,
  [`Read Only`]: `Jen pro čtení`,
  [`Write Only`]: `Jen pro zápis`,
  [`api`]: `api`,
  [`version`]: `verze`,
  [`username`]: `jméno uživatele`,
  [`password`]: `heslo`,
  [`service`]: `služba`,
  [`Allows Empty Value`]: `Povoluje prázdnou hodnotu`,
  [`Style`]: `Styl`,
  [`Explode`]: `Explodovat`,
  [`Allow Reserved`]: `Povolit vyhrazené`,
  [`Allow WebSocket Upgrade`]: `Povolit upgrade WebSocket`,
  [`Ref`]: `Ref`,
  [`Operation ID`]: `ID operace`,
  [`Parameter`]: `Parametr`,
  [`Gateway and portal settings`]: `Nastavení brány a portálu`,
  [`Targets`]: `Cíle`,
  [`Catalog Properties`]: `Vlastnosti katalogu`,
  [`catalog properties`]: `vlastnosti katalogu`,
  [`Attachments`]: `Přílohy`,
  [`Activity Log`]: `Protokol aktivit`,
  [`API Type`]: `Typ rozhraní API`,
  [`Phase`]: `Fáze`,
  [`Enable CORS`]: `Povolit sdílení CORS`,
  [`CORS Policy`]: `Zásada CORS`,
  [`Allowed Origins`]: `Povolené původy`,
  [`Allow Credentials`]: `Povolit pověření`,
  [`ID`]: `ID`,
  [`Success Content`]: `Obsah úspěchu`,
  [`Error Content`]: `Obsah chyby`,
  [`Attachment`]: `Příloha`,
  [`Link`]: `Odkaz`,
  [`Operation Ref`]: `Odkaz na operaci`,
  [`TRACE`]: `TRASOVAT`,
  [`No Security Requirements available`]: `Nejsou k dispozici žádné požadavky na zabezpečení`,
  [`Create a security scheme`]: `Vytvořit schéma zabezpečení`,
  [`Create a Security Requirement`]: `Vytvořit požadavek na zabezpečení`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Vyžadovat jeden z následujících požadavků na zabezpečení. Není-li zaškrtnuto, je zabezpečení volitelné.`,
  [`Require wrappers on fault details specified by type`]: `Požadovat obálky u podrobností o poruchách, které jsou uvedeny podle typu`,
  [`There are no "Servers"`]: `Nejsou žádné "servery"`,
  [`Write`]: `Zapsat`,
  [`Server Variable`]: `Proměnná serveru`,
  [`Security Requirement`]: `Požadavek na zabezpečení`,
  [`Security Scheme Type`]: `Typ schématu zabezpečení`,
  [`Security Scheme`]: `Schéma zabezpečení`,
  [`OAuth Flows`]: `Toky OAuth`,
  [`Implicit Flow`]: `Implicitní tok`,
  [`Password Flow`]: `Tok hesla`,
  [`Client Credentials Flow`]: `Tok pověření klienta`,
  [`Authorization Code Flow`]: `Tok autorizačního kódu`,
  [`Refresh URL`]: `Aktualizační adresa URL`,
  [`schema`]: `schéma`,
  [`Allow Empty Value`]: `Povolit prázdnou hodnotu`,
  [`Encoding`]: `Kódování`,
  [`This field is required`]: `Jedná se o požadované pole`,
  [`Temporary token`]: `Dočasný token`,
  [`Access token`]: `Přístupový token`,
  [`ID token`]: `Token ID`,
  [`No Security Schemes available`]: `Nejsou k dispozici žádná schémata zabezpečení`,
  [`Security Scheme (Key)`]: `Schéma zabezpečení (klíč)`,
  [`There are no "{label}"`]: `Nejsou k dispozici žádné štítky "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `Chcete-li definovat požadavek na zabezpečení, musíte vybrat alespoň jedno schéma`,
  [`Select one or more Security Schemes`]: `Vybrat jedno nebo více schémat zabezpečení`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `Pro schéma zabezpečení "{key}" nejsou k dispozici žádné rozsahy OAuth`,
  [`Create a Scope`]: `Vytvořit rozsah`,
  [`Remove this Security Requirement`]: `Odebrat tento požadavek na zabezpečení`,
  [`Edit this Security Requirement`]: `Upravit tento požadavek na zabezpečení`,
  [`Invalid Security Scheme Reference`]: `Neplatný odkaz na schéma zabezpečení`,
  [`The following Security Schemes do not exist!`]: `Následující schémata zabezpečení neexistují!`,
  [`Edit source`]: `Upravit zdroj`,
  [`OR`]: `NEBO`,
  [`Required Security Schemes`]: `Požadovaná schémata zabezpečení`,
  [`Optional Security`]: `Volitelné zabezpečení`,
  [`Optional custom expression (one per line)`]: `Volitelný vlastní výraz (jeden na řádek)`,
  [`Flow Type`]: `Typ toku`,
  [`OAuth2 Security Scheme`]: `Schéma zabezpečení OAuth2`,
  [`Only source editing is supported.`]: `Podporována je pouze úprava zdroje.`,
  [`Unsupported Field.`]: `Nepodporované pole.`,
  [`Select an option`]: `Vybrat volbu`,
  [`Select {labelText}`]: `Vybrat {labelText}`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" se již používá'`,
  [`Referenced from "{originalRef}"`]: `Odkazováno z "{originalRef}"`,
  [`Go to {label}`]: `Přejít na {label}`,
  [`Delete "{label}"`]: `Odstranit "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `Opravdu chcete odstranit "{label}"?`,
  [`No recent items found`]: `Nebyly nalezeny žádné nejnovější položky.`,
  [`Found {numDocs} results ({took} seconds)`]: `Nalezeno {numDocs} výsledků ({took} s)`,
  [`No Consumer Organizations found`]: `Nebyly nalezeny žádné zákaznické organizace.`,
  [`No Catalog Products found`]: `Nebyly nalezeny žádné produkty katalogu.`,
  [`No APIs found`]: `Nebyla nalezena žádná rozhraní API.`,
  [`No Catalogs found`]: `Nebyly nalezeny žádné katalogy`,
  [`No Spaces found`]: `Nebyly nalezeny žádné prostory`,
  [`No results found`]: `Nebyly nalezeny žádné výsledky.`,
  [`No Catalog APIs found`]: `Nebyla nalezena žádná rozhraní API katalogu.`,
  [`No Catalogs found matching query "{query}"`]: `Nebyly nalezeny žádné katalogy vyhovující dotazu "{query}"`,
  [`No APIs found matching query "{query}"`]: `Nebyla nalezena žádná rozhraní API odpovídající dotazu "{query}".`,
  [`No Subscriptions found`]: `Nebyly nalezeny žádné odběry.`,
  [`No Plans found matching query "{query}"`]: `Nebyly nalezeny žádné plány odpovídající dotazu "{query}".`,
  [`No Subscriptions found matching query "{query}"`]: `Nebyly nalezeny žádné odběry odpovídající dotazu "{query}".`,
  [`No Catalog Products found matching query "{query}"`]: `Nebyly nalezeny žádné produkty katalogu odpovídající dotazu "{query}".`,
  [`No Catalog APIs found matching query "{query}"`]: `Nebyla nalezena žádná rozhraní API katalogu odpovídající dotazu "{query}".`,
  [`No Consumer Organizations found matching query "{query}"`]: `Nebyly nalezeny žádné zákaznické organizace odpovídající dotazu "{query}".`,
  [`No Applications found matching query "{query}"`]: `Nebyly nalezeny žádné aplikace odpovídající dotazu "{query}".`,
  [`APIs per page`]: `Rozhraní API na stránku`,
  [`Catalogs per page`]: `Počet katalogů na stránku`,
  [`items per page`]: `Počet položek na stránku`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} z {total} rozhraní API`,
  [`{min}-{max} of {total} API`]: `Rozhraní API {min} - {max} z {total}`,
  [`Products per page`]: `Produktů na stránku`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Produkt by měl být publikován v katalogu, aby bylo možné nakonfigurovat novou integraci účtování`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} z {total} produktů`,
  [`{min}-{max} of {total} Product`]: `Produkty {min} - {max} z {total}`,
  [`Catalog Products per page`]: `Produktů katalogu na stránku`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} z {total} produktů katalogu`,
  [`{min}-{max} of {total} Catalog Product`]: `Katalogy produktů {min} - {max} z {total}`,
  [`Plans per page`]: `Plánů na stránku`,
  [`Policies per page`]: `Zásad na stránku`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} z {total} plánů`,
  [`{min}-{max} of {total} Plan`]: `Plány {min} - {max} z {total}`,
  [`Catalog APIs per page`]: `Rozhraní API katalogu na stránku`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} z {total} rozhraní API katalogu`,
  [`{min}-{max} of {total} Catalog API`]: `Rozhraní API katalogu {min} - {max} z {total}`,
  [`Subscriptions per page`]: `Odběrů na stránku`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} z {total} odběrů`,
  [`{min}-{max} of {total} Subscription`]: `Odběry {min} - {max} z {total}`,
  [`Consumer Organizations per page`]: `Zákaznických organizací na stránku`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} z {total} zákaznických organizací`,
  [`{min}-{max} of {total} Consumer Organization`]: `Organizace zákazníka {min} - {max} z {total}`,
  [`Applications per page`]: `Aplikací na stránku`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} z {total} aplikací`,
  [`{min}-{max} of {total} Application`]: `Aplikace {min} - {max} z {total}`,
  [`Histories per page`]: `Historií na stránku`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} z {total} historií`,
  [`{min}-{max} of {total} History`]: `Historie {min} - {max} z {total}`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Nebyly nalezeny žádné plány, k jejichž odběru je možné se přihlásit. Produkt musí být ve stavu "publikováno", než může být vytvořen odběr. Aplikace se může přihlásit k odběru pouze jednoho plánu v produktu.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Zobrazení publikovaných rozhraní API "{draftApiTitle}:{draftApiVersion}"`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Zobrazení zákaznické organizace pro "{appTitle}"`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Zobrazení odběrů pro "{productTitle}:{productVersion}"`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Zobrazení plánů pro "{productTitle}:{productVersion}"`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `Zobrazení rozhraní API pro "{productTitle}:{productVersion}"`,
  [`Showing Product for "{planTitle}"`]: `Zobrazení produktu pro "{planTitle}"`,
  [`Showing APIs for "{planTitle}"`]: `Zobrazení rozhraní API pro "{planTitle}"`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Zobrazení odběrů pro plán: "{docTitle}" v produktu: "{docFoundIn}"`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Zobrazení plánů pro "{apiTitle}:{apiVersion}"`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Zobrazení konceptu rozhraní API pro "{catalogApiTitle}:{catalogApiVersion}"`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Zobrazení historií pro "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Zobrazení odběrů pro "{appTitle}"`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Zobrazení aplikací pro "{consumerOrgTitle}"`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Zobrazení odběrů pro "{consumerOrgTitle}"`,
  [`Showing Application for selected Subscription`]: `Zobrazení aplikace pro vybraný odběr`,
  [`Showing Plan for selected Subscription`]: `Zobrazení plánu pro vybraný odběr`,
  [`Showing Product for selected Subscription`]: `Zobrazení produktu pro vybraný odběr`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Vybrat koncept rozhraní API pro zahrnutí do produktu "{productTitle}"`,
  [`Product Version`]: `Verze produktu`,
  [`OAuth Providers`]: `Poskytovatelé OAuth`,
  [`Application Type`]: `Typ aplikace`,
  [`Client IDs`]: `ID klienta`,
  [`Save as New Version`]: `Uložit jako novou verzi`,
  [`Update Selected APIs`]: `Aktualizovat vybraná rozhraní API`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Rozhraní API {titleAndVersion} bude trvale odstraněno. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Produkt {titleAndVersion} bude trvale odstraněn. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Produkt katalogu {titleAndVersion} bude trvale odstraněn. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Zákaznická organizace {titleAndVersion} bude trvale odstraněna. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Skupina zákazníků {titleAndVersion} bude trvale odstraněna. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Zásada {titleAndVersion} bude trvale odstraněna. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Odběr {titleAndVersion} bude trvale odstraněn. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Katalog {titleAndVersion} bude trvale odstraněn. Chcete pokračovat?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Prostor {titleAndVersion} bude trvale odstraněn. Chcete pokračovat?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} bude znovu publikován. Chcete pokračovat?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} bude vyřazen. Chcete pokračovat?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} bude zamítnut. Chcete pokračovat?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} bude znovu fázován. Chcete pokračovat?`,
  [`Put Online`]: `Vložit online`,
  [`Take Offline`]: `Převést do stavu Offline`,
  [`View Subscriptions`]: `Zobrazit odběry`,
  [`View Plans`]: `Zobrazit plány`,
  [`No Tasks found`]: `Nebyly nalezeny žádné úlohy.`,
  [`Tasks per page`]: `Úloh na stránce`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} z {total} úloh`,
  [`{min}-{max} of {total} Task`]: `Úlohy {min} - {max} z {total}`,
  [`Decline and Send`]: `Odmítnout a odeslat`,
  [`View Applications`]: `Zobrazit aplikace`,
  [`Publish (Preserve Subscriptions)`]: `Publikovat (zachovat odběry)`,
  [`Update APIs`]: `Aktualizovat rozhraní API`,
  [`View in Catalogs`]: `Zobrazit v katalozích`,
  [`View Consumer Organization`]: `Zobrazit zákaznickou organizaci`,
  [`View Application`]: `Zobrazit aplikaci`,
  [`View Plan`]: `Zobrazit plán`,
  [`View Product`]: `Zobrazit produkt`,
  [`Create Subscription`]: `Vytvořit odběr`,
  [`No Applications found`]: `Nebyly nalezeny žádné aplikace`,
  [`Select a Plan to Create a Subscription to`]: `Vyberte plán pro vytvoření odběru`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Tato akce není vratná. Aplikace {titleAndVersion} bude trvale odstraněna. Chcete pokračovat?`,
  [`Save as new version`]: `Uložit jako novou verzi`,
  [`Filter selected catalogs`]: `Filtrovat vybrané katalogy`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Vybrat koncept rozhraní API pro zahrnutí do produktu "{productTitle}"`,
  [`Update selected APIs`]: `Aktualizovaná vybraná rozhraní API`,
  [`Catalog API`]: `Rozhraní API katalogu`,
  [`Catalog APIs`]: `Rozhraní API katalogu`,
  [`View Products`]: `Zobrazit produkty`,
  [`View Draft APIs`]: `Zobrazit koncept rozhraní API`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Zobrazení produktů odkazujících na "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `Nebyly nalezeny žádné plány`,
  [`View APIs`]: `Zobrazit rozhraní API`,
  [`Document type tabs`]: `Karty typu dokumentu`,
  [`No Products found`]: `Nebyly nalezeny žádné produkty`,
  [`Consumer Organization`]: `Zákaznická organizace`,
  [`Consumer Organizations`]: `Zákaznické organizace`,
  [`Catalog Product`]: `Produkt katalogu`,
  [`Catalog Products`]: `Produkty katalogu`,
  [`Search for a Product and Plan`]: `Vyhledat produkt a plán`,
  [`OpenAPI Version`]: `Verze OpenAPI`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (z REST, GraphQL nebo SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (z tématu Kafka)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `Vytvořit AsyncAPI (z tématu Kafka)`,
  [`An error occurred during file upload`]: `Došlo k chybě během odesílání souboru`,
  [`Delete uploaded file`]: `Odstranit odeslaný soubor`,
  [`Copied!`]: `Zkopírováno!`,
  [`Uploading`]: `Odesílání`,
  [`Validation icon`]: `Ikona ověření platnosti`,
  [`Validation error`]: `Chyba ověření platnosti`,
  [`Uploading image..`]: `Odesílání obrázku...`,
  [`Unsupported media type {providedFileType}`]: `Nepodporovaný typ média {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Vyberte podporovaný soubor {expectedFileTypes} a operaci opakujte`,
  [`Pasted`]: `Vložený`,
  [`Enter the API summary details.`]: `Zadejte souhrnné podrobnosti o rozhraní API.`,
  [`Define your Kafka cluster connection details.`]: `Definujte podrobnosti o připojení klastru Kafka.`,
  [`Channel`]: `Kanál`,
  [`Describe your Kafka topic and its associated schema.`]: `Popište své téma Kafka a jeho přidružené schéma.`,
  [`AsyncAPI specification version`]: `Verze specifikace AsyncAPI`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Název, který popisuje vaše rozhraní API a pomáhá vývojářům jej zjistit v nástroji Developer Portal.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Verze pro definici rozhraní API. Kombinace názvu a verze jedinečně identifikuje definici rozhraní API.`,
  [`Description (optional)`]: `Popis (volitelný)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Představte rozhraní API a poskytněte užitečné informace vývojářům, které ho spotřebovávají.`,
  [`Summary (optional)`]: `Souhrn (volitelný)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Stručný souhrn událostí obsažených v tomto rozhraní API. Maximum 1000 znaků.`,
  [`Bootstrap servers`]: `Zaváděcí servery`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Seznam zprostředkovatelů Kafka ve vašem klastru, oddělených čárkami.`,
  [`Secure the API using an API Key and Secret`]: `Zabezpečit rozhraní API pomocí klíče a tajného klíče rozhraní API`,
  [`The credential provided should be in SASL-PLAIN format`]: `Poskytnuté pověření by mělo být ve formátu SASL-PLAIN`,
  [`Publish this API:`]: `Publikovat toto rozhraní API:`,
  [`Creates a product`]: `Vytvoří produkt`,
  [`Associates this API to the product`]: `Přidruží toto rozhraní API k produktu`,
  [`Publish the product to the Sandbox catalog`]: `Publikuje produkt do katalogu sandboxu`,
  [`Associates the product to the test app`]: `Přidruží produkt k testovací aplikaci`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Schéma ve formátu "{fileType}" bylo úspěšně odesláno a ověřeno.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `Odeslaný soubor není platným schématem Avro, a nebude zahrnut jako součást vašeho rozhraní API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Odezva serveru: "{err}". Klepnutím na tlačítko "Zpět" se vrátíte na stránku definice rozhraní API.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Odezva serveru: "{err}". Klepnutím na tlačítko "Hotovo" ukončete průvodce.`,
  [`Topic name`]: `Název tématu`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `Název tématu, které chcete vystavit jako část tohoto rozhraní API. Téma je pojmenovaný proud zpráv.`,
  [`Schema for message body in .avsc format (optional)`]: `Schéma pro tělo zprávy ve formátu .avsc (volitelné)`,
  [`Drag and drop files here, or click to upload`]: `Přetáhněte soubory sem, nebo je přeneste klepnutím`,
  [`About this step`]: `O tomto kroku`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Události mohou být popsány jako asynchronní rozhraní API, protože spotřebitelé přijímají události, jakmile jsou k dispozici. Synchronní rozhraní API na druhé straně, jako např. REST, přijímají pouze odezvy, když je požadují.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Asynchronní rozhraní API řízená událostmi jsou dokumentována pomocí specifikace AsyncAPI.`,
  [`API secret`]: `Tajný klíč rozhraní API`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Generovaná definice AsyncAPI 2.1.0`,
  [`API creating`]: `Vytvoření rozhraní API`,
  [`An error occurred while creating your document`]: `Došlo k chybě při vytváření dokumentu`,
  [`Publishing your API`]: `Publikování rozhraní API`,
  [`Your API has been published`]: `Vaše rozhraní API bylo publikováno`,
  [`An error occurred while publishing your API`]: `Došlo k chybě při publikování rozhraní API`,
  [`Configure security for this API`]: `Nakonfigurujte zabezpečení pro toto rozhraní API.`,
  [`Cluster connection security`]: `Zabezpečení připojení klastru`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Poskytněte pověření, která by služby brány událostí použily pro připojení k vašemu klastru při zpracování požadavků na toto rozhraní API. Tyto hodnoty můžete později změnit v editoru. Další informace naleznete v dokumentaci k vytváření pověření.`,
  [`SASL Username`]: `Jméno uživatele SASL`,
  [`SASL Password`]: `Heslo SASL`,
  [`Transport CA certificate (.pem file - optional)`]: `Přenést certifikát certifikační autority (soubor .pem - volitelný)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Je-li k dispozici, použije se služba brány událostí k poskytnutí šifrování TLS pro komunikaci mezi bránou a klastrem Kafka.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Popište podrobnosti rozhraní API zdroje událostí Apache Kafka a vygenerujte dokument AsyncAPI.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `Pak můžete publikovat dokument AsyncAPI a vystavit zdroj událostí vývojářům aplikací, kteří mohou nakonfigurovat své aplikace tak, aby se přihlásily k odběru souvisejícího proudu událostí.`,
  [`Set up security for your API if you want to control who can access it.`]: `Chcete-li řídit, kdo má přístup k vašemu rozhraní API, nastavte pro něj zabezpečení.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `Přístup k vašemu rozhraní API bude spravován službou brány událostí, která zkontroluje platnost klíče a tajného klíče rozhraní API.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Brány vyžadují, aby klienti šifrovali svou komunikaci pomocí protokolu TLS.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Po vytvoření rozhraní API můžete klepnutím na volbu 'Upravit rozhraní API' přidat další podrobnosti, jako např. podrobnosti zabezpečení pro připojení ke klastru Kafka ze služby brány událostí.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `Pak můžete rozhraní API publikovat, a tak jej zpřístupnit vývojářům, kteří jej pak mohou zjistit, vyžádat si k němu přístup a použít informace k nastavení svých aplikací tak, aby spotřebovávaly události ze zdroje událostí.`,
  [`Pending backend response`]: `Nevyřízená back-endová odezva`,
  [`Complete`]: `Dokončit`,
  [`Rate limiting is only applied to enforced APIs`]: `Limit četnosti je použit pouze pro vynucená rozhraní API`,
  [`No Plans Available`]: `Nejsou dostupné žádné plány`,
  [`Modal content is not provided`]: `Modální obsah není k dispozici`,
  [`Modal heading is not provided`]: `Modální záhlaví není k dispozici`,
  [`Edit Rate Limit`]: `Upravit limit četnosti`,
  [`External Documentation`]: `Externí dokumentace`,
  [`No Label Available`]: `Nejsou dostupné žádné štítky`,
  [`optional`]: `volitelné`,
  [`Uploading image...`]: `Načítání obrázku...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Připojte soubory jejich přetažením, výběrem nebo vložením.`,
  [`Response name`]: `Název odezvy`,
  [`Click Add to add a new {resource}.`]: `Klepnutím na tlačítko Přidat přidáte nový prostředek {resource}.`,
  [`Schema Name (Key)`]: `Název schématu (klíč)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Obor názvů`,
  [`Attribute`]: `Atribut`,
  [`Wrapped`]: `Zalomené`,
  [`Example`]: `Příklad`,
  [`Example Name (Key)`]: `Příklad názvu (klíč)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" se již používá`,
  [`Name (Key)`]: `Název (klíč)`,
  [`Content Type (Key)`]: `Typ obsahu (klíč)`,
  [`Header name`]: `Název záhlaví`,
  [`Reference`]: `Odkaz`,
  [`Security Scheme Name (Key)`]: `Název schématu zabezpečení (klíč)`,
  [`Choose a Reference`]: `Zvolit odkaz`,
  [`Reference from`]: `Odkaz z`,
  [`Content Type`]: `Typ obsahu`,
  [`array`]: `array`,
  [`boolean`]: `boolean`,
  [`integer`]: `integer`,
  [`number`]: `number`,
  [`object`]: `object`,
  [`string`]: `string`,
  [`query`]: `query`,
  [`cookie`]: `cookie`,
  [`matrix`]: `matrix`,
  [`label`]: `label`,
  [`simple`]: `simple`,
  [`form`]: `form`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `Poskytovatel OAuth (Název)`,
  [`Authenticate using User Registry (name)`]: `Ověřit pomocí registru uživatelů (název)`,
  [`Bearer Format`]: `Formát typu Bearer`,
  [`Server Variable Name`]: `Název proměnné serveru`,
  [`Verb (Key)`]: `Slovo (klíč)`,
  [`Parameter Definitions`]: `Definice parametru`,
  [`Path Parameters`]: `Parametry cesty`,
  [`Media Type List`]: `Seznam typů médií`,
  [`Medium`]: `Střední`,
  [`Schemes List`]: `Seznam schémat`,
  [`Small`]: `Malé`,
  [`Response Definitions`]: `Definice odezvy`,
  [`Terms Of Service`]: `Podmínky služby`,
  [`Url`]: `URL`,
  [`Untitled Parameter`]: `Nepojmenovaný parametr`,
  [`Parameter Name`]: `Název parametru`,
  [`Mime Type`]: `Typ Mime`,
  [`Async API`]: `Asynchronní rozhraní API`,
  [`Channels`]: `Kanály`,
  [`Operation Traits`]: `Vlastnosti operace`,
  [`Messages`]: `Zprávy`,
  [`Correlation IDs`]: `ID korelace`,
  [`Traits`]: `Vlastnosti`,
  [`Message Traits`]: `Vlastnosti zprávy`,
  [`Bindings`]: `Vazby`,
  [`Server Bindings`]: `Vazby serveru`,
  [`Channel Bindings`]: `Vazby kanálu`,
  [`Operation Bindings`]: `Vazby operace`,
  [`Message Bindings`]: `Vazby zpráv`,
  [`Protocol Version`]: `Verze protokolu`,
  [`Define multiple messages`]: `Definovat více zpráv`,
  [`Schema Format`]: `Formát schématu`,
  [`Unsupported Field`]: `Nepodporované pole`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Vlastnost operace`,
  [`Message Trait`]: `Vlastnost zprávy`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Pomocí zásady přepínače operace můžete použít sekci sestavení na specifickou operaci.`,
  [`HTML page`]: `Stránka HTML`,
  [`Rule`]: `Pravidlo`,
  [`Origin`]: `Původ`,
  [`Property Name (Key)`]: `Název vlastnosti (klíč)`,
  [`Catalog Name (Key)`]: `Název katalogu (klíč)`,
  [`Property Overrides`]: `Potlačení vlastnosti`,
  [`Property Name`]: `Název vlastnosti`,
  [`Property Value`]: `Hodnota vlastnosti`,
  [`About subscriptions and plans`]: `O odběrech a plánech`,
  [`Subscription Plan Name`]: `Název plánu odběru`,
  [`Select plan`]: `Vybrat plán`,
  [`Select the plan to migrate application subscriptions from`]: `Vybrat plán, ze kterého se mají migrovat odběry aplikací`,
  [`Select subscriptions`]: `Vybrat odběry`,
  [`Select the application subscriptions to migrate`]: `Vybrat odběry aplikací pro migraci`,
  [`Select the product to migrate application subscriptions to`]: `Vybrat produkt, do kterého se mají migrovat odběry aplikací`,
  [`Select the plan to migrate application subscriptions to`]: `Vybrat plán, do kterého se mají migrovat odběry aplikací`,
  [`Message successfully sent to {sent} owner(s)`]: `Zpráva byla úspěšně odeslána vlastníkovi (vlastníkům) {sent}`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} zpráv se nezdařilo odeslat`,
  [`Failed email addresses`]: `Nezdařené e-mailové adresy`,
  [`Failed to save the API`]: `Nezdařilo se uložit rozhraní API`,
  [`and {numExtra} more...`]: `a {numExtra} další...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Zkopírovat do schránky seznam e-mailových adres, které se nezdařilo odeslat`,
  [`From`]: `Od`,
  [`Message Owner`]: `Vlastník zprávy`,
  [`Message owner`]: `Vlastník zprávy`,
  [`Message owners`]: `Vlastníci zpráv`,
  [`Message group`]: `Skupina zpráv`,
  [`Select consumer organizations`]: `Vybrat zákaznické organizace`,
  [`Message consumer organization owners`]: `Vlastníci zákaznických organizací zpráv`,
  [`Message consumer organization owner`]: `Vlastník organizace odběratele zpráv`,
  [`Message provider organization owner`]: `Vlastník organizace poskytovatele zpráv`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `Identifikátor klastru Kafka související s touto zásadou vyvolání`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Seznam dvojic hostitel:port oddělených čárkami, které mají být použity k navázání spojení s klastrem Kafka`,
  [`The username to use when connecting to the Kafka cluster.`]: `Jméno uživatele, které má být použito při připojení ke klastru Kafka.`,
  [`The password to use when connecting to the Kafka cluster.`]: `Heslo, které se má být použít při připojení ke klastru Kafka.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Služba brány událostí`,
  [`Event gateway management client keystore`]: `Úložiště klíčů klienta správy brány událostí`,
  [`Edit Event gateway Service`]: `Upravit službu brány událostí`,
  [`Configure Event Gateway Service`]: `Konfigurovat službu brány událostí`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Konfigurovat službu brány událostí pro zabezpečení a vynucení asynchronních rozhraní API`,
  [`Catalogs with published product`]: `Katalogy s publikovaným produktem`,
  [`Cluster Config ID`]: `ID konfigurace klastru`,
  [`SASL mechanism`]: `Mechanizmus SASL`,
  [`Security protocol`]: `Protokol zabezpečení`,
  [`Transport CA certificate`]: `Přenos certifikátu CA`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `Maximální velikost souboru je {size}. Podporované typy souborů jsou json, xml, yml, yaml a zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Podporované typy souborů jsou json, xml, yml a yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Podporované typy souborů jsou json, wsdl, xml, xsd, yml, yaml a zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Podporované typy souborů jsou json, wsdl, xml, yml a yaml.`,
  [`Select a file`]: `Vybrat soubor`,
  [`or specify a file URL`]: `nebo zadejte adresu URL souboru`,
  [`Generated OpenAPI {version} definition`]: `Generovaná definice rozhraní OpenAPI {version}`,
  [`User registry type`]: `Typ registru uživatelů`,
  [`User registry types`]: `Typy registru uživatelů`,
  [`User registry type {title} has been created.`]: `Byl vytvořen typ registru uživatelů {title}.`,
  [`User registry type {title} has been updated.`]: `Byl aktualizován typ registru uživatelů {title}.`,
  [`Failed to create user registry type {title}.`]: `Nezdařilo se vytvořit typ registru uživatelů {title}.`,
  [`Failed to update user registry type {title}.`]: `Nezdařilo se aktualizovat typ registru uživatelů {title}.`,
  [`Failed to update user registry {title}.`]: `Nezdařilo se aktualizovat registr uživatelů {title}`,
  [`Custom user registry`]: `Vlastní registr uživatelů`,
  [`Create custom user registry`]: `Vytvořit vlastní registr uživatelů`,
  [`Edit custom user registry`]: `Upravit vlastní registr uživatelů`,
  [`Create user registry type`]: `Vytvořit typ registru uživatelů`,
  [`Edit user registry type`]: `Upravit typ registru uživatelů`,
  [`Configure user authentication using a custom external user registry`]: `Nakonfigurovat ověření uživatele pomocí vlastního externího registru uživatelů`,
  [`Secured Endpoint`]: `Zabezpečený koncový bod`,
  [`Configuration schema`]: `Schéma konfigurace`,
  [`About custom user registries`]: `O vlastních registrech uživatelů`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Vlastní registry uživatelů mohou být použity pro ověření uživatelů v nástroji Developer Portal, ale nelze je použít k zabezpečení rozhraní API. Chcete-li nakonfigurovat vlastní registr uživatelů jako prostředek v nástroji API Manager, musí být vytvořen externí adresář uživatelů a musí být k dispozici pro použití s vaším ekosystémem API Connect.`,
  [`Update Wsdl`]: `Aktualizovat Wsdl`,
  [`Update Existing WSDL service`]: `Aktualizovat existující službu WSDL`,
  [`Format of selected WSDL has been successfully validated`]: `Formát vybraného jazyka WSDL byl úspěšně ověřen`,
  [`Select the target WSDL file to update from`]: `Vybrat cílový soubor WSDL, ze kterého se má aktualizovat`,
  [`Selected WSDL has been successfully uploaded`]: `Vybraný jazyk WSDL byl úspěšně odeslán`,
  [`Services available in selected API`]: `Služby dostupné ve vybraném rozhraní API`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Povolit starší editor Open API Editor pro dokumenty OpenAPI 2.0 (zamítnuto)`,
  [`Unknown`]: `Neznámý`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `Brána(y) {incompatibleCatalogCount} skryta(y) kvůli nekompatibilitě s typem brány uvedeným v rozhraní API nebo produktu`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} prostor(y) skryt(y) kvůli nekompatibilitě s typem brány uvedeným v rozhraní API nebo produktu`,
  [`Keep in mind`]: `Mějte na paměti`,
  [`Only one type of enforced APIs is allowed per product`]: `Pro každý produkt je povolen pouze jeden typ vynucených rozhraní API`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `{incompatibleAPIsCount} rozhraní API nelze vybrat kvůli nekompatibilitě s typem brány uvedeným v produktu`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `{incompatibleProductsCount} produkt(y) nelze vybrat kvůli nekompatibilitě s typem brány uvedeným v rozhraní API`,
  [`Unenforced`]: `Nevynuceno`,
  [`Create a new product by adding APIs and plans.`]: `Vytvořte nový produkt přidáním rozhraní API a plánů.`,
  [`Rate limits are only applied to enforced APIs.`]: `Limity četnosti jsou použity pouze na vynucená rozhraní API.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Přidejte plány a limity rozhraní API k vašemu produktu. Limity plánu se vztahují pouze na vynucená rozhraní API a nevynucená rozhraní API budou neomezená.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Přidejte plány a limity četnosti k vašemu produktu. Plány se vztahují pouze na vynucená rozhraní API a nevynucená rozhraní API budou neomezená.`,
  [`Delete plan`]: `Odstranit plán`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Vyberte službu brány událostí pro rozhraní AsyncAPI. Pro všechny ostatní typy rozhraní API vyberte buď DataPower API Gateway, nebo kompatibilní verzi v5. Chcete-li toto nastavení aktualizovat, odeberte nejprve přidružená rozhraní API.`,
  [`Edit API list`]: `Upravit seznam rozhraní API`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Vynucená rozhraní API v tomto produktu vyžadují více než jeden typ brány.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `Vynucená rozhraní {apiType} v tomto produktu vyžadují typ {gatewayType}.`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API(s)`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API(s)`,
  [`AsyncAPI(s)`]: `AsyncAPI(s)`,
  [`Select the gateway type for this Product`]: `Vyberte typ brány pro tento produkt.`,
  [`Added default plan`]: `Přidaný výchozí plán`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Publikujte produkt do katalogu sandboxu Tím přidružíte produkt k testovací aplikaci.`,
  [`cluster`]: `klastr`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Zpráva z odmítnutí`,
  [`Vendor extensions feature disabled`]: `Funkce rozšíření dodavatele zakázána`,
  [`No configured gateways found`]: `Nebyly nalezeny žádné nakonfigurované brány`,
  [`No OpenAPI extensions associated with the configured gateway`]: `K nakonfigurované bráně nejsou přidružena žádná rozšíření OpenAPI`,
  [`Extension`]: `Rozšíření`,
  [`Select the extensions to add to the API document`]: `Vybrat rozšíření pro přidání do dokumentu API`,
  [`(Operations: {operations})`]: `(Operace: {operations})`,
  [`Failed to get application`]: `Nezdařilo se získat aplikaci`,
  [`Failed to get credentials for application`]: `Nezdařilo se získat pověření pro aplikaci`,
  [`Failed to get published product`]: `Nezdařilo se získat publikovaný produkt`,
  [`Failed to get the published API`]: `Nezdařilo se získat publikované rozhraní API`,
  [`API must be activated to use the Test tool`]: `API musí být aktivováno pro použití testovacího nástroje`,
  [`Import from Asset repository`]: `Importovat z úložiště aktiv`,
  [`Untitled {componentLabel}`]: `Bez názvu {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Metrika licence vyžaduje, aby analytická služba sledovala použití. Chcete-li konfigurovat analytickou službu, klepněte na volbu "Registrovat službu".`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Všechny služby brány DataPower vyžadují, aby analytická služba sledovala využití. Klepněte na volbu „Přidružit analytickou službu“ pro každou bránu, abyste dodrželi licenční podmínky.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Katalog je nekompatibilní s typem brány uvedeným v rozhraní API nebo produktu`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `Prostor je nekompatibilní s typem brány uvedeným v rozhraní API nebo produktu`,
  [`Preserve Subscription`]: `Zachovat odběr`,
  [`Publish validation`]: `Ověření publikování`,
  [`Publish validations`]: `Ověření publikování`,
  [`Edit publish validations`]: `Upravit ověření publikování`,
  [`The following validations will be performed when publishing a product`]: `Při publikování produktu budou provedena následující ověření`,
  [`There are no publish validations enabled`]: `Nejsou povolena žádná ověření publikování`,
  [`Publish validations have been updated`]: `Ověření publikování byla aktualizována`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Poskytněte certifikační autoritu, kterou služba brány událostí používá k důvěřování certifikátu back-endového serveru Kafka. Pokud server používá certifikát vydaný dobře známou autoritou, není třeba certifikační autoritu poskytnout.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `Hodnota zadaná pro servery zaváděcího programu je neplatná. Zadejte hostitele a port každého zprostředkovatele Kafka ve vašem klastru, oddělené čárkami. Např. broker1.com:9092,broker2.com:9092,broker3.com:9092`,
  [`Upload OpenAPI Extension`]: `Odeslat rozšíření OpenAPI`,
  [`Add OpenAPI Extension`]: `Přidat rozšíření OpenAPI`,
  [`Gateway Endpoint`]: `Koncový bod brány`,
  [`API Dashboard`]: `Řídicí panel rozhraní API`,
  [`API call data`]: `Data volání rozhraní API`,
  [`Product Dashboard`]: `Řídicí panel produktu`,
  [`API Product consumption metrics`]: `Metriky spotřeby produktu rozhraní API`,
  [`Monitoring Latency Dashboard`]: `Řídicí panel latence monitorování`,
  [`Data about the time taken to serve API calls`]: `Údaje o době, kterou zabere obsluha volání API`,
  [`Monitoring Status Dashboard`]: `Řídicí panel stavu monitorování`,
  [`API Error and success information`]: `Chyba rozhraní API a informace o úspěchu`,
  [`Usage Dashboard`]: `Řídicí panel využití`,
  [`Usage for gateway {gateway}`]: `Použití pro bránu {gateway}`,
  [`Latency for gateway {gateway}`]: `Latence pro bránu {gateway}`,
  [`Most popular products, APIs and applications`]: `Nejoblíbenější produkty, rozhraní API a aplikace`,
  [`Consumption Dashboard`]: `Panel dashboard spotřeby`,
  [`Total API volume useful for auditing and compliance`]: `Celkový objem rozhraní API užitečný pro auditování a kompatibilitu`,
  [`Monthly Summary by Status Code`]: `Měsíční souhrn podle stavového kódu`,
  [`Monthly Summary Data by Status Code`]: `Měsíční souhrnná data podle stavového kódu`,
  [`Daily Summary by Status Code`]: `Denní souhrn podle stavového kódu`,
  [`Daily Summary Data by Status Code`]: `Denní souhrnná data podle stavového kódu`,
  [`Response time (ms)`]: `Doba odezvy (ms)`,
  [`Datetime`]: `Datetime`,
  [`Transaction ID:`]: `ID transakce:`,
  [`Other`]: `Jiný`,
  [`Date time`]: `Datum a čas`,
  [`Total calls`]: `Celkem volání`,
  [`Date`]: `Datum`,
  [`Month`]: `Měsíc`,
  [`Minimum response time`]: `Minimální doba odezvy`,
  [`Maximum response time`]: `Maximální doba odezvy`,
  [`Average response time`]: `Průměrná doba odezvy`,
  [`Total API calls`]: `Celkem volání rozhraní API`,
  [`Total APIs`]: `Celkový počet rozhraní API`,
  [`APIs called`]: `Volaná rozhraní API`,
  [`Total products`]: `Celkový počet produktů`,
  [`products called`]: `Volané produkty`,
  [`Total errors`]: `Celkem chyb`,
  [`Status codes (detailed)`]: `Stavové kódy (podrobné)`,
  [`Response time percentiles`]: `Percentily doby odezvy`,
  [`API calls per day`]: `Volání rozhraní API za den`,
  [`Time zone:`]: `Časové pásmo:`,
  [`Time zone`]: `Časové pásmo`,
  [`Toggle axes`]: `Přepnout osy`,
  [`Datetime:`]: `Datum a čas:`,
  [`datetime per 30 seconds`]: `datum a čas za 30 sekund`,
  [`Datetime (local time)`]: `Datum a čas (místní čas)`,
  [`Data usage (bytes)`]: `Využití dat (bajty)`,
  [`Time to Serve Request`]: `Čas potřebný k obsluze požadavku`,
  [`API name`]: `Název rozhraní API`,
  [`Product name`]: `Název produktu`,
  [`Data usage (bytes received)`]: `Využití dat (přijaté bajty)`,
  [`Data usage (bytes sent)`]: `Využití dat (odeslané bajty)`,
  [`Response times (>1s)`]: `Doby odezvy (>1s)`,
  [`milliseconds`]: `milisekund(y)`,
  [`errors`]: `chyby`,
  [`Success Rate`]: `Míra úspěchu`,
  [`Errors Data`]: `Data chyb`,
  [`Success Data`]: `Data o úspěchu`,
  [`Total consumer organizations`]: `Celkem zákaznických organizací`,
  [`making API calls`]: `volající(ch) rozhraní API`,
  [`Applications per plan`]: `Aplikace na každý plán`,
  [`Top products by calls`]: `První produkty podle volání`,
  [`Top APIs by calls`]: `První rozhraní API podle volání`,
  [`Top client IP addresses by calls`]: `První adresy IP klientů podle volání`,
  [`Client IP address`]: `Adresa IP klienta`,
  [`Top APIs by request size`]: `První rozhraní API podle velikosti požadavku`,
  [`Top APIs by response size`]: `První rozhraní API podle velikosti odezvy`,
  [`Top APIs by response time`]: `První rozhraní API podle doby odezvy`,
  [`Top applications by calls`]: `První aplikace podle volání`,
  [`Dashboards`]: `Řídicí panely`,
  [`Discover`]: `Zjistit`,
  [`UTC`]: `UTC`,
  [`Local time`]: `Místní čas`,
  [`URI`]: `URI`,
  [`Response code`]: `Kód odezvy`,
  [`Total time elapsed (ms)`]: `Celková uplynulá doba (ms)`,
  [`Timestamp`]: `Časová značka`,
  [`Method`]: `Metoda`,
  [`Gateway IP`]: `IP komunikační brány`,
  [`Gateway Operations Dashboard`]: `Řídicí panel operací brány`,
  [`Usage and latency information per gateway`]: `Informace o využití a latenci na bránu`,
  [`Transaction ID`]: `ID transakce`,
  [`Filters`]: `Filtry`,
  [`Fields`]: `Pole`,
  [`Clear all`]: `Vymazat vše`,
  [`API Event`]: `Událost rozhraní API`,
  [`API Assembly Policy Latencies`]: `Latence zásady sestavení rozhraní API`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `Níže uvedený diagram zobrazuje zvyšující se dobu latence v milisekundách, jak požadavek procházel zásadami sestavení rozhraní API. To může být užitečné při určování pomalých bodů nebo kritických míst v rámci sestavení rozhraní API.`,
  [`Collapse`]: `Sbalit`,
  [`Expand`]: `Rozbalit`,
  [`enter name`]: `zadejte název`,
  [`Enter value`]: `Zadat hodnotu`,
  [`Time range`]: `Časový úsek`,
  [`Select one of the following time range filters`]: `Vyberte jeden z následujících filtrů časových rozsahů:`,
  [`Last 1 hour`]: `Poslední 1 hodina`,
  [`Last {number} minutes`]: `Posledních {number} minut`,
  [`Last {number} hours`]: `Posledních {number} hodin`,
  [`Last {number} days`]: `Posledních {number} dnů`,
  [`Start date`]: `Datum zahájení`,
  [`End date`]: `Datum ukončení`,
  [`Select from the following dropdowns and enter a value`]: `Vyberte z následujících rozevíracích seznamů a zadejte hodnotu`,
  [`Select one of the following type filters`]: `Vyberte jeden z následujících filtrů typu`,
  [`All events`]: `Všechny události`,
  [`Success only (2xx, 3xx)`]: `Pouze úspěch (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Pouze chyby (4xx, 5xx)`,
  [`Import/Export`]: `Importovat/exportovat`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Asi {totalItems} výsledků za ({searchTime} sekund) v {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Asi {totalItems} výsledků ({searchTime} sekund) v {time} ({utcTime})`,
  [`API calls`]: `Volání rozhraní API`,
  [`Export as CSV`]: `Exportovat jako CSV`,
  [`Export as JSON`]: `Exportovat jako JSON`,
  [`Export as PNG`]: `Exportovat jako PNG`,
  [`Export as JPG`]: `Exportovat jako JPG`,
  [`Edit filter`]: `Upravit filtr`,
  [`Edit query`]: `Upravit dotaz`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Jedná se o sdílený dotaz. Jakékoli změny budou provedeny pro všechny uživatele, se kterými je tento dotaz sdílen.`,
  [`This is a shared query. This will delete it for all users.`]: `Jedná se o sdílený dotaz. Tím se vymaže pro všechny uživatele.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `Položka {queryname} byla aktualizována pro všechny uživatele v organizaci poskytovatele {org}`,
  [`{queryname} has been updated for all users in the {space} space`]: `Položka {queryname} byla aktualizována pro všechny uživatele v prostoru {space}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `Položka {queryname} byla aktualizována pro všechny uživatele v katalogu {catalog}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `Položka {queryname} byla aktualizována pro všechny uživatele ve správci cloudu`,
  [`Stop sharing`]: `Zastavit sdílení`,
  [`Share query`]: `Sdílet dotaz`,
  [`Share filter`]: `Sdílet filtr`,
  [`You do not have permissions to delete this filter`]: `Nemáte oprávnění k odstranění tohoto filtru`,
  [`You do not have permissions to delete this query`]: `Nemáte oprávnění k odstranění tohoto dotazu`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Jste si jisti, že chcete sdílet dotaz {queryTitle} v rozsahu organizace poskytovatele {org}?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Jste si jisti, že chcete sdílet dotaz {queryTitle} v rozsahu prostoru {space}?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Jste si jisti, že chcete sdílet dotaz {queryTitle} v rozsahu katalogu {catalog}?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Jste si jisti, že chcete sdílet dotaz {queryTitle} v rozsahu cloudu?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Jste si jisti, že chcete zastavit sdílení dotazu {queryTitle}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Jste si jisti, že chcete zastavit sdílení dotazu {queryTitle} v rozsahu organizace poskytovatele {org}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Jste si jisti, že chcete zastavit sdílení dotazu {queryTitle} v rozsahu prostoru {space}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Jste si jisti, že chcete zastavit sdílení dotazu {queryTitle} v rozsahu katalogu {catalog}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Jste si jisti, že chcete zastavit sdílení dotazu {queryTitle} v rozsahu cloudu?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Jste si jisti, že chcete odstranit dotaz {queryTitle}?`,
  [`At least one query parameter expected`]: `Je očekáván alespoň jeden parametr dotazu`,
  [`A provided query parameter is incomplete`]: `Zadaný parametr dotazu není úplný`,
  [`Could not parse query string`]: `Nelze analyzovat řetězec dotazu`,
  [`An unsupported query parameter was provided: {query}`]: `Byl zadán nepodporovaný parametr dotazu: {query}`,
  [`Invalid timeframe value: {value}`]: `Neplatná hodnota časového rámce: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `Hodnota hodnoty {field} musí odpovídat notaci data-času, jak je definováno v RFC 3339, v části 5.6. Příklady, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `Operátor {operator} není podporován pro pole {field}`,
  [`The value of {field} must be a number`]: `Hodnota {field} musí být číslo`,
  [`Delete query`]: `Odstranit dotaz`,
  [`Delete filter`]: `Odstranit filtr`,
  [`Save query`]: `Uložit dotaz`,
  [`Save filter`]: `Uložit filtr`,
  [`Save filter as`]: `Uložit filtr jako`,
  [`Save query as`]: `Uložit dotaz jako`,
  [`Save as...`]: `Uložit jako...`,
  [`Save as`]: `Uložit jako`,
  [`Paste query string to load the query`]: `Vložit řetězec dotazu pro načtení dotazu`,
  [`Copy or paste filter string to load filters`]: `Kopírovat nebo vložit řetězec filtru pro načtení filtrů`,
  [`Query string`]: `Řetězec dotazu`,
  [`View filter`]: `Zobrazit filtr`,
  [`View filter string`]: `Zobrazit řetězec filtru`,
  [`Date (local time)`]: `Datum (místní čas)`,
  [`Coming soon, a new built in analytics feature`]: `Brzy bude uvedena nová vestavěná funkce analýzy`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `Analýzy vám pomohou shromáždit poznatky o běhovém prostředí API, spravovat úrovně služeb, nastavit kvóty, zavést ovládací prvky, nastavit zásady zabezpečení, spravovat komunity a analyzovat trendy.`,
  [`Documentation`]: `Dokumentace`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Získejte detailní pohled na typy dat běhového prostředí API a na to, jak používat sektory AWS s3 k jejich vizualizaci.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Seznamte se s tím, jaká data jsou dnes k dispozici a jak k nim přistupovat.`,
  [`Next steps`]: `Další kroky`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `Vybraná služba Secrets Manager nespravuje žádné certifikáty.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Certifikát je vyžadován pro zabezpečení připojení mezi službou API Connect a doménou koncového bodu správy brány. Certifikáty jsou také nezbytné pro zabezpečení spojení mezi bránou a doménami, které zpracovává. Všechny certifikáty musí být uloženy ve službě Secrets Manager. Služba poskytuje zabezpečené úložiště pro certifikáty a pomáhá předcházet výpadkům tím, že vám odešle oznámení, když má platnost certifikátů vypršet při integraci se službou Event Notifications. Chcete-li začít:`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Certifikáty jsou nezbytné pro zabezpečení spojení mezi bránou a doménami, které zpracovává. Všechny certifikáty musí být uloženy ve službě Secrets Manager. Služba poskytuje zabezpečené úložiště pro certifikáty a pomáhá předcházet výpadkům tím, že vám odešle oznámení, když má platnost certifikátů vypršet při integraci se službou Event Notifications. Chcete-li začít:`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Změna služby Secrets Manager odstraní všechna existující přiřazení certifikátu a balíku CA.`,
  [`Create a [Secrets Manager]({link}) service`]: `Vytvořit službu [Secrets Manager]({link})`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Importujte požadované certifikáty a balíky CA do služby Secrets Manager.`,
  [`No Secrets Manager services available`]: `Nejsou k dispozici žádné služby Secrets Manager`,
  [`Select Secrets Manager service`]: `Vyberte službu Secrets Manager`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Vyberte službu [Secrets Manager]({link}), která spravuje certifikáty pro tuto bránu.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Služba Secrets Manager musí spravovat certifikáty pro doménu koncového bodu správy brány a domény, které brána zpracovává. Kromě toho musíte použít produkt IBM Cloud IAM a autorizovat službu API Connect Reserved Instance pro přístup ke službě Secrets Manager.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Poté vyberte certifikát pro doménu koncového bodu správy. Také vyberte odpovídající balík CA v případě, že certifikát nebyl přiřazen dobře známou certifikační autoritou. Certifikát a balík musí být spravovány službou Secrets Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Poté zadejte domény, které budou zpracovány touto bránou. Technika SNI je využívána; v důsledku toho může být více domén obsluhováno přes stejnou adresu IP a port, aniž by byl požadován stejný certifikát. Formát zástupných znaků je podporován. Standardně musí být doména (catch-all) ‘*’ zahrnuta jako finální položka v tabulce. Podle potřeby zadejte další názvy domén, v každém případě uveďte certifikát spravovaný službou Secrets Manager.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Pomocí produktu [IBM Cloud IAM]({link}) autorizujte službu API Connect Reserved Instance pro přístup ke službě Secrets Manager.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Aktualizujte existující přiřazení certifikátu a balíku certifikační autority tím, že je načtete z produktu Secrets Manager.`,
  [`Access s3 data`]: `Přistoupit k datům s3`,
  [`Create resource`]: `Vytvořit prostředek`,
  [`Connect analytics data in s3 bucket with AWS`]: `Spojit data analýzy v sektoru s3 s AWS`,
  [`AWS account`]: `Účet AWS`,
  [`AWS account number`]: `Číslo účtu AWS`,
  [`Enter your 12 digit aws account id`]: `Zadejte své ID účtu AWS o délce 12 číslic`,
  [`Account id must be 12 digits`]: `ID účtu musí být 12 číslic`,
  [`Failed to update AWS account id.`]: `Aktualizace ID účtu AWS se nezdařila.`,
  [`You have limited access to the catalogs.`]: `Máte omezený přístup ke katalogům.`,
  [`You have limited access to the spaces.`]: `Máte omezený přístup k prostorům.`,
  [`Contact admin to get more access.`]: `Kontaktujte administrátora, abyste získali další přístup.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `Tento katalog nemůžete vybrat. Potřebujete-li přístup, obraťte se na administrátora.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `Tento prostor nemůžete vybrat. Potřebujete-li přístup, obraťte se na administrátora.`,
  [`You have limited access to the product billing.`]: `Máte omezený přístup k účtování produktu.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `ID účtu AWS byl úspěšně aktualizován. Udělení přístupu k datům S3 může trvat několik sekund.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Ve vybraném katalogu chybí organizace nebo skupina.`,
  [`Secrets Manager instance`]: `Instance Secrets Manager`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Organizace zákazníků s vlastníky, kterým chybí e-mail, nelze vybrat`,
  [`You currently don’t have any data.`]: `Momentálně nemáte žádná data.`,
  [`You currently don’t have any organizations.`]: `Momentálně nemáte žádné organizace.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Požadavek na odběr aplikace {appname} do plánu {plan} v produktu {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Požadavek na produkt {operation}: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Požadavek na upgrade aplikace {appname} na produkční`,
  [`Onboarding request for {email}`]: `Požadavek na zahrnutí pro {email}`,
  [`Product lifecycle request`]: `Požadavek životního cyklu produktu`,
  [`Subscription request`]: `Požadavek na odběr`,
  [`Application lifecycle request`]: `Požadavek životního cyklu aplikace`,
  [`Consumer Onboarding request`]: `Požadavek na zahrnutí spotřebitele`,
  [`Originator`]: `Původce`,
  [`Edit analytics service advanced settings`]: `Upravit rozšířená nastavení analytické služby`,
  [`Advanced settings`]: `Rozšířené nastavení`,
  [`Must be a positive integer`]: `Musí být kladné celé číslo`,
  [`Maximum value is {maxValue}`]: `Maximální hodnota je {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `Maximální hodnota je {maxValueDays}d nebo {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Nakonfigurujte rozšířená nastavení pro implementaci analýzy.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `Hodnota musí končit na 'd' pro dny' nebo na 'h' pro hodiny. Například 30d nebo 12h.`,
  [`Rollover settings`]: `Nastavení přetočení`,
  [`Retention settings`]: `Nastavení uchování`,
  [`Delete an index when it meets the following condition:`]: `Odstranit index, když splňuje následující podmínky:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Přetočit alias zápisu do nového indexu, když spravovaný index splňuje jednu z následujících podmínek:`,
  [`Minimum document count`]: `Minimální počet dokumentů`,
  [`The minimum number of documents required to roll over the index.`]: `Minimální počet dokumentů požadovaných k přetočení indexu.`,
  [`Minimum index age`]: `Minimální stáří indexu`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Minimální stáří indexu požadované pro přetočení indexu. Stáří indexu je čas mezi vytvořením indexu a tímto okamžikem. Použijte 'd' pro dny a' h ' pro hodiny. Například 30d znamená, že index bude přetočen, jakmile jeho stáří dosáhne 30 dnů, zatímco 96h znamená, že index bude přetočen, jakmile jeho stáří dosáhne 96 hodin. Maximální hodnoty jsou {maxValueDays}d nebo {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Minimální stáří indexu potřebné k odstranění indexu. Stáří indexu je čas mezi vytvořením indexu a tímto okamžikem. Použijte 'd' pro dny a' h ' pro hodiny. Například 30d znamená, že index bude odstraněn, jakmile jeho stáří dosáhne 30 dnů, zatímco 96h znamená, že index bude odstraněn, jakmile jeho stáří dosáhne 96 hodin. Maximální hodnoty jsou {maxValueDays}d nebo {maxValueHours}h.`,
  [`Time range:`]: `Časový rozsah:`,
  [`Time range: Before {datetime}`]: `Časový rozsah: Před {datetime}`,
  [`Time range: After {datetime}`]: `Časový rozsah: Po {datetime}`,
  [`Filter before`]: `Filtrovat před`,
  [`Filter after`]: `Filtrovat po`,
  [`Filter exact`]: `Filtrovat přesně`,
  [`Filter gt`]: `Filtrovat gt`,
  [`Filter lt`]: `Filtrovat lt`,
  [`Filter equals`]: `Filtr se rovná`,
  [`Filter by`]: `Filtrovat podle`,
  [`Filter out`]: `Odfiltrovat`,
  [`Delete failed`]: `Odstranění se nezdařilo`,
  [`Delete warning`]: `Odstranit varování`,
  [`Could not delete saved query {queryTitle}.`]: `Uložený dotaz {queryTitle} nelze odstranit.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `Požadavek byl úspěšně zodpovězen, ale {queryTitle} dosud nedokončil odstranění.`,
  [`API governance`]: `Řízení rozhraní API`,
  [`Something's wrong`]: `Něco je špatně`,
  [`We were unable to connect to the service.`]: `Nemohli jsme se připojit ke službě.`,
  [`Invalid date`]: `Neplatné datum`,
  [`Invalid time`]: `Neplatný čas`,
  [`End date must be after the start date`]: `Datum ukončení musí být po datu zahájení`,
  [`Duplicate`]: `Duplikovat`,
  [`Duplicate query`]: `Duplicitní dotaz`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Jste si jisti, že chcete vytvořit duplikát {queryTitle}?`,
  [`No queries saved`]: `Nejsou uloženy žádné dotazy`,
  [`No queries shared`]: `Nejsou sdíleny žádné dotazy`,
  [`To save a query, start by creating one then save.`]: `Chcete-li uložit dotaz, jeden vytvořte a pak jej uložte.`,
  [`Share a saved query and allow others to use it.`]: `Sdílejte uložený dotaz a umožněte jiným uživatelům jeho použití.`,
  [`Query updated`]: `Dotaz aktualizován`,
  [`Query update failed`]: `Aktualizace dotazu se nezdařila`,
  [`Share successful`]: `Sdílení bylo úspěšné`,
  [`Share failed`]: `Sdílení se nezdařilo`,
  [`Delete successful`]: `Odstranění bylo úspěšné`,
  [`Query unshared`]: `Sdílení dotazu zrušeno`,
  [`Unshare {queryTitle} failed`]: `Zrušení sdílení {queryTitle} se nezdařilo`,
  [`Query copied`]: `Dotaz zkopírován`,
  [`Duplicate {queryTitle} failed`]: `Duplikát {queryTitle} se nezdařil`,
  [`The query {queryTitle} has been updated.`]: `Dotaz {queryTitle} byl aktualizován.`,
  [`The query {queryTitle} has been shared.`]: `Dotaz {queryTitle} byl sdílen.`,
  [`The query {queryTitle} has been deleted.`]: `Dotaz {queryTitle} byl odstraněn.`,
  [`The query {queryTitle} is no longer shared.`]: `Dotaz {queryTitle} již není sdílen.`,
  [`A copy of {queryTitle} has been created.`]: `Byla vytvořena kopie {queryTitle}.`,
  [`About queries`]: `O dotazech`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Dotazy jsou prostředky pro finalizaci analytických dat použitých v panelech dashboard a v pohledu Zjistit. Když se dotaz použije, všechny budou všechny panely dashboard aktualizovány, aby zahrnovaly pouze vybraná data.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `Je možné použít jeden z předdefinovaných časových úseků, nebo použít specifický časový rozsah k nejbližšímu druhému pomocí volby Vlastní.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Pole mohou být použita pro uvedení, že by měla být zahrnuta pouze určitá data. Je možné filtrovat téměř každý atribut události rozhraní API.`,
  [`There are then different operators available depending on the field type.`]: `V závislosti na typu pole jsou poté k dispozici různé operátory.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Řetězcová pole umožňují rovná se, nezačíná, končí, regulární výraz.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `Je možné použít obsahuje a neobsahuje a poskytnout seznam hodnot pro jediné pole (například seznam verzí rozhraní API).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `Pole adresy IP umožňují rovná se a ne operátory, umožňují také zadat hodnotu v notaci CIDR, aby bylo možné použít síťové masky k výběru určitých sítí.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Regulární výrazy mohou být velmi výkonné pro výběr specifických hodnot, ale jejich provedení je pomalejší, takže se doporučuje použít jiné operátory, jako například 'začíná' a 'končí', je-li to možné.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Filtry dotazů pro různá pole jsou kombinovány pomocí logického operátoru AND. To znamená, že přidáním dvou filtrů pro stejný název pole budou vráceny pouze výsledky, které vyhovují oběma filtrům.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `Parametr dotazu {type} je jednoduchý způsob, jak určit pouze úspěšné nebo chybové události. Používá pole {statusCode}. Jsou-li zapotřebí pouze specifické stavové kódy, lze použít filtr pole. Hodnota pro pole {statusCodeTwo} je obvykle úplný řetězec, jako například {okStatus} a nikoli pouze číselný návratový kód HTTP.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `Je možné uložit dotazy pro pozdější opětovné použití a také je sdílet s aktuální úrovní rozsahu, aby je také mohli používat ostatní - například pro organizaci nebo katalog poskytovatele.`,
  [`n/a`]: `Není k dispozici`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Pozn.: Pole 'ID organizace poskytovatele' a 'Název organizace poskytovatele' se zobrazí v informačním obsahu události jako {orgId} a {orgName}; pole 'ID organizace spotřebitele' a 'Název organizace spotřebitele' se zobrazí jako {developerOrgId} a {developerOrgName}.`,
  [`No authentication token`]: `Chybí token ověření`,
  [`Contact your administrator for further assistance.`]: `Obraťte se na administrátora se žádostí o další pomoc.`
};
