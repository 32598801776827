// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

function t(original) {
  return original
}

const mod = angular.module('apiconnect-assembly')

mod.directive('apimAssemblyPolicy', function() {
  return {
    restrict: 'E',
    template: require('../../html/policy.html'),
  }
})

mod.directive('apimAssemblyTemplate', function() {
  return {
    restrict: 'E',
    template: require('../../html/template.html'),
  }
})

mod.directive('apimAssembler', function() {
  return {
    restrict: 'E',
    template: require('../../html/assembler.html'),
    scope: {
      assemblerOptions: '<',
      swaggerDocument: '<',
      policyList: '<',
      gateways: '<',
      products: '<',
      draftProducts: '<',
      applications: '<',
      application: '<',
      publishedApis: '<',
      publishTargets: '<',
      tlsProfiles: '<',
      userRegistries: '<',
      catalog: '<',
      orgId: '<',
      consumerOrgs: '<',
      deployedProduct: '<',
      republishStatus: '<',
      apiIsOnline: '<',
      statusIsSet: '<',
      isOffline: '<',
      onSwaggerUpdate: '&',
      onPublishProduct: '&',
      onCreateAndPublishProduct: '&',
      onSetApplication: '&',
      onSetProduct: '&',
      onSetProductName: '&',
      onCreateApplication: '&',
      onChangeCatalog: '&',
      onSubscribeApp: '&',
      onSaveAndRepublishProduct: '&',
      onMakeOnline: '&',
    },
    controller: 'AssemblerController',
    controllerAs: 'AssemblerController',
    bindToController: true,
  }
})

mod.directive('apimNode', [
  'RecursionHelper',
  function(RecursionHelper) {
    return {
      restrict: 'E',
      template: require('../../html/node.html'),
      compile(element) {
        return RecursionHelper.compile(element)
      },
    }
  },
])

mod.directive('assemblyLogicNode', [
  'RecursionHelper',
  function(RecursionHelper) {
    return {
      restrict: 'E',
      template: require('../../html/logic-node.html'),
      compile(element) {
        return RecursionHelper.compile(element)
      },
    }
  },
])

mod.directive('assemblyPolicyNode', [
  'RecursionHelper',
  function(RecursionHelper) {
    return {
      restrict: 'E',
      template: require('../../html/policy-node.html'),
      replace: true,
      controller: 'NodeController',
      controllerAs: 'NodeController',
      compile(element) {
        return RecursionHelper.compile(element)
      },
    }
  },
])

mod.directive('assemblySubflowNode', [
  'RecursionHelper',
  function(RecursionHelper) {
    return {
      restrict: 'E',
      template: require('../../html/subflow-node.html'),
      compile(element) {
        return RecursionHelper.compile(element)
      },
    }
  },
])

mod.directive('assemblyCatch', [
  function() {
    return {
      restrict: 'E',
      template: require('../../html/catch.html'),
    }
  },
])

mod.directive('assemblyClause', [
  function() {
    return {
      restrict: 'E',
      template: require('../../html/clause.html'),
    }
  },
])

mod.directive('assemblyPolicyNav', [
  '$compile',
  'ApimPolicyFactory',
  function($compile, PolicyFactory) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        nodes: '<',
        selectedNode: '<',
        showCatches: '<',
        swaggerDocument: '<',
        swaggerLegacyOperations: '<',
        swaggerOperations: '<',
        tlsProfiles: '<',
        onNodeUpdate: '&',
      },
      template: '<div />',
      link($scope, element) {
        const childScope = $scope.$new()
        const partial = PolicyFactory.getPolicyPartial(
          $scope.selectedNode.$$type,
          $scope.selectedNode.version
        )

        childScope.$watch(
          'selectedNode',
          function(newVal) {
            $scope.onNodeUpdate({node: newVal})
          },
          true
        )

        childScope.nodes = $scope.nodes
        childScope.selectedNode = $scope.selectedNode
        childScope.showCatches = $scope.showCatches
        childScope.swaggerDocument = $scope.swaggerDocument
        childScope.swaggerLegacyOperations = $scope.swaggerLegacyOperations
        childScope.swaggerOperations = $scope.swaggerOperations
        childScope.model = $scope.selectedNode.model || $scope.selectedNode

        // for custom policies
        childScope.formChanged = function(model) {
          $scope.onNodeUpdate({
            node: {
              ...$scope.selectedNode,
              ...model,
            },
          })
        }

        element.append($compile(partial)(childScope))
      },
    }
  },
])

/*
mod.directive('assemblyAppNode', [function() {
  return {
    restrict: 'E',
    template: require('../../app/nodeTemplates/application-node.html')
  }
}])
*/

// }]).directive('apimMapper', function() {
//   return {
//     restrict: 'E',
//     template: require('../../html/mapper.html')
//   };
// }).directive('apimGenerator', function() {
//   return {
//     restrict: 'E',
//     template: require('../../html/generator.html')
//   };

mod
  .directive('apimTestParameter', function() {
    return {
      restrict: 'E',
      template: require('../../html/test-parameter.html'),
    }
  })
  .directive('apimDraftAssembly', function() {
    return {
      restrict: 'E',
      template: require('../../html/draft-assembly.html'),
    }
  })
  .directive('apicConditionBuilder', function() {
    return {
      restrict: 'E',
      controller: 'ConditionController as conditionController',
      template: require('../../html/condition-builder.html'),
      scope: {
        condition: '=',
      },
    }
  })
  .directive('apicConditionExpression', function(RecursionHelper) {
    return {
      restrict: 'E',
      template: require('../../html/condition-expression.html'),
      controller: 'ConditionExpressionController as expressionController',
      scope: true,
      compile(element) {
        return RecursionHelper.compile(element)
      },
    }
  })
  .directive('apicConditionExpressionLeft', function(RecursionHelper) {
    return {
      restrict: 'E',
      template: require('../../html/condition-expression.html'),
      controller: 'ConditionExpressionLeftController as expressionController',
      scope: true,
      compile(element) {
        return RecursionHelper.compile(element)
      },
    }
  })
  .directive('apicConditionExpressionRight', function(RecursionHelper) {
    return {
      restrict: 'E',
      template: require('../../html/condition-expression.html'),
      controller: 'ConditionExpressionRightController as expressionController',
      scope: true,
      compile(element) {
        return RecursionHelper.compile(element)
      },
    }
  })
  .directive('apimNotEmpty', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link(scope, elem, attrs, ctrl) {
        ctrl.$parsers.push(function(viewValue) {
          if (viewValue === '') {
            return null
          }
          return viewValue
        })
      },
    }
    // })
    // .directive('appCTrigger', function () {
    //   return {
    //     controller: 'TriggerController as triggerController',
    //     restrict: 'E',
    //     template: require('../../../app/nodeTemplates/trigger.html')
    //   };
  })
  .directive('apimSidenav', function() {
    return {
      controller: 'SidenavController',
      restrict: 'E',
      template: require('../../html/sidenav.html'),
    }
  })
  .directive('errorDialog', function() {
    return {
      controller: 'ErrorController',
      restrict: 'E',
      template: require('../../html/error-dialog.html'),
    }
  })
  .directive('warningDialog', function() {
    return {
      controller: 'WarningController',
      restrict: 'E',
      template: require('../../html/warning-dialog.html'),
    }
  })
  .directive('policyList', function() {
    return {
      controller: 'PolicyListController',
      restrict: 'E',
      template: require('../../html/policy-list.html'),
    }
  })
  .directive('navigationBar', function() {
    return {
      controller: 'NavigationBarController',
      restrict: 'E',
      template: require('../../html/navigation-bar.html'),
    }
  })
  .directive('assemblerCanvas', function() {
    return {
      restrict: 'E',
      template: require('../../html/assembler-canvas.html'),
    }
  })
  .directive('nodeGroup', function() {
    return {
      restrict: 'E',
      template: require('../../html/node-group.html'),
    }
  })
  .directive('apicMapperAddProperty', function() {
    return {
      restrict: 'E',
      link($scope, $elem, $attrs) {
        // TODO(PETER): Need to find out why $attrs are being converted to lowerCase.
        // ex: isArray is being set as an attribute but is appearing as isarray
        $scope.isArray = $scope.$eval($attrs.isArray || $attrs.isarray)
      },
      template: require('../../html/mapper-schema/add-property.html'),
    }
  })
  .directive('apicMapperXOf', function() {
    return {
      restrict: 'E',
      link($scope, $elem, $attrs) {
        $scope.type = $attrs.type
        $scope.text = $attrs.text
      },
      template: require('../../html/mapper-schema/x_of.html'),
    }
  })
  .directive('apicMapperEnum', function() {
    return {
      restrict: 'E',
      template: require('../../html/mapper-schema/enum.html'),
    }
  })
  .directive('apicMapperDiscriminator', function() {
    return {
      restrict: 'E',
      template: require('../../html/mapper-schema/discriminator.html'),
    }
  })
  .directive('apicMapperDiscriminatorV3', function() {
    return {
      restrict: 'E',
      template: require('../../html/mapper-schema/discriminatorV3.html'),
    }
  })
  .directive('apicTestConfigSummary', function() {
    return {
      restrict: 'E',
      template: require('../../html/test_config_summary.html'),
      scope: {
        catalogName: '<',
        productName: '<',
        planName: '<',
        applicationName: '<',
      },
    }
  })
  .directive('apicTestConfigSummaryEntry', function() {
    return {
      restrict: 'E',
      template: require('../../html/test_config_summary_entry.html'),
      scope: {
        label: '<',
        value: '<',
      },
    }
  })
  .directive('searchGroup', [
    '$timeout',
    'RecursionHelper',
    function($timeout, RecursionHelper) {

      return {
        scope: {
          searchContainer: '=',
          groups: '=',
          groupIndex: '=',
          sourceTypes: '=',
          logicalOperators: '=',
          operations: '=',
        },
        template: require('../../html/search-group.html'),
        controller: [
          '$scope',
          function($scope) {
            $scope.group = $scope.groups[$scope.groupIndex]
            // Source types start
            $scope.isTopGroup = function() {
              return $scope.searchContainer.groups[0] === $scope.group
            }
            function setSelectedSourceType() {
              $scope.selectedSourceType = $scope.sourceTypes[0]
            }
            setSelectedSourceType()

            $scope.groupLogicalOperators = [
              {
                name: 'and',
                displayName: t('and'),
              },
              {
                name: 'or',
                displayName: t('or'),
              },
            ]

            function setSelectedGroupLogicalOperator() {
              if ($scope.group.logicalOperator) {
                $scope.selectedGroupLogicalOperator = _.find(
                  $scope.groupLogicalOperators,
                  function(logicalOperator) {
                    return (
                      logicalOperator.name === $scope.group.logicalOperator.name
                    )
                  }
                )
              }
            }
            setSelectedGroupLogicalOperator()

            $scope.$watch('selectedGroupLogicalOperator', function(newVal) {
              $scope.group.logicalOperator = newVal
            })

            function setIsNotGroup() {
              if ($scope.group.isNotGroup === undefined)
                $scope.group.isNotGroup = false
              $scope.isNotGroup = $scope.group.isNotGroup
            }
            setIsNotGroup()

            $scope.$watch('isNotGroup', function(newVal, oldVal) {
              $scope.group.isNotGroup = newVal
            })

            // Logical operators start
            function setSelectedLogicalOperator() {
              if ($scope.group.logicalOperator) {
                $scope.selectedLogicalOperator = _.find(
                  $scope.logicalOperators,
                  function(logicalOperator) {
                    return (
                      logicalOperator.name === $scope.group.logicalOperator.name
                    )
                  }
                )
              }
            }
            setSelectedLogicalOperator()

            $scope.selectLogicalOperator = function(logicalOperator) {
              $scope.group.logicalOperator = {
                name: logicalOperator.name,
                displayName: logicalOperator.displayName,
              }
              setSelectedLogicalOperator()
            }

            $scope.addGroup = function() {
              const sourceType = $scope.sourceTypes[0]
              const logicalOperator = $scope.groupLogicalOperators[0]
              const newGroup = {
                sourceType: {
                  name: sourceType.name,
                  displayName: sourceType.displayName,
                },
                logicalOperator: {
                  name: 'and',
                  displayName: 'AND',
                },
                conditions: [],
              }
              if ($scope.group.groups === undefined) {
                $scope.group.groups = []
              }
              $scope.group.groups.push(newGroup)
            }

            $scope.removeGroup = function() {
              if ($scope.group !== $scope.searchContainer.groups[0]) {
                const index = $scope.groups.indexOf($scope.group)
                if (index > -1) {
                  $scope.groups.splice(index, 1)
                }
              }
              const curGroup = $scope.searchContainer.groups
              let preGroup
            }

            // Condition functions start
            $scope.addCondition = function() {
              const newCondition = {}
              if (
                $scope.group.conditions.length > 0 &&
                $scope.group.conditions[$scope.group.conditions.length - 1]
                  .sourceField &&
                !$scope.group.conditions[$scope.group.conditions.length - 1]
                  .logicalOperator
              ) {
                $scope.group.conditions.push({
                  logicalOperator: 'and',
                })
                $scope.group.conditions.push(newCondition)
              } else if ($scope.group.conditions.length === 0) {
                $scope.group.conditions.push(newCondition)
              }
            }

            $scope.removeCondition = function(index) {
              $scope.group.conditions.splice(index, 1)
              if (
                index === 0 &&
                !!$scope.group.conditions[0] &&
                !!$scope.group.conditions[0].logicalOperator
              )
                $scope.group.conditions.splice(index, 1)
              if (
                index > 0 &&
                !!$scope.group.conditions[index - 1].logicalOperator
              )
                $scope.group.conditions.splice(index - 1, 1)
            }
          },
        ],
        compile(element) {
          return RecursionHelper.compile(element)
        },
      }
    },
  ])
  .directive('searchCondition', function() {
    return {
      scope: {
        condition: '=',
        conditionIndex: '=',
        sourceType: '=',
        operations: '=',
      },
      template: require('../../html/search-condition.html'),
      controller: [
        '$scope',
        function($scope) {
          $scope.options = []
          $scope.logicalOperators = [
            {
              name: 'and',
              displayName: 'and',
            },
            {
              name: 'or',
              displayName: 'or',
            },
          ]

          $scope.optGroup = $scope.sourceType.sourceFields.reduce(
            (arr, item) => {
              if (arr.indexOf(item.type) === -1) {
                arr.push(item.type)
              }
              return arr
            },
            []
          )

          $scope.isLogicalOperator = function() {
            return !!$scope.condition.logicalOperator
          }

          function setInputOptions() {
            if ($scope.selectedSourceField) {
              switch ($scope.selectedSourceField.displayName) {
                case '$httpVerb()':
                  const opt = []
                  $scope.selectedSourceField.enum.forEach(function(option) {
                    opt.push(option)
                  })
                  $scope.options = opt
                  break
                case '$operationID()':
                  $scope.options =
                    $scope.operations && $scope.operations.operationId
                  break
                case '$operationPath()':
                  $scope.options =
                    $scope.operations && $scope.operations.operationPath
                  break
                default:
                  $scope.options = []
                  break
              }
            }
          }

          function setSelectedSourceField() {
            if ($scope.condition.sourceField) {
              $scope.selectedSourceField = _.find(
                $scope.sourceType.sourceFields,
                function(field) {
                  return (
                    field.displayName ===
                    $scope.condition.sourceField.displayName
                  )
                }
              )
              setInputOptions()
            }
          }
          setSelectedSourceField()

          $scope.$watch('selectedSourceField', function(newVal, oldVal) {
            if (newVal === oldVal) return
            if ($scope.selectedSourceField) {
              $scope.inputItem = {
                parameter: angular.copy($scope.selectedSourceField.parameter),
              }
              $scope.condition.sourceField = {
                name: $scope.selectedSourceField.name,
                displayName: $scope.selectedSourceField.displayName,
              }
              $scope.condition.inputItem = {
                parameter: $scope.selectedSourceField.parameter
                  ? {
                    parameter: angular.copy(
                      $scope.selectedSourceField.parameter
                    ),
                  }
                  : undefined,
              }
              $scope.condition.comparisonOperator = _.find(
                $scope.selectedSourceField.comparisonOperators,
                function(operator) {
                  return operator.name === 'Equals'
                }
              )
              $scope.selectedComparisonOperator =
                $scope.condition.comparisonOperator
              setInputOptions()
            }
          })

          function setSelectedComparisonOperator() {
            if (
              $scope.selectedSourceField &&
              $scope.condition.comparisonOperator
            ) {
              $scope.selectedComparisonOperator = _.find(
                $scope.selectedSourceField.comparisonOperators,
                function(operator) {
                  return (
                    operator.name === $scope.condition.comparisonOperator.name
                  )
                }
              )
            }
          }
          setSelectedComparisonOperator()

          function setSelectedLogicalOperator() {
            if ($scope.condition.logicalOperator) {
              $scope.selectedLogicalOperator = _.find(
                $scope.logicalOperators,
                function(operator) {
                  return operator.name === $scope.condition.logicalOperator
                }
              )
            }
          }
          setSelectedLogicalOperator()

          $scope.$watch(
            'selectedComparisonOperator',
            function(newValue, oldValue) {
              if ($scope.selectedComparisonOperator) {
                $scope.condition.comparisonOperator = {
                  name: $scope.selectedComparisonOperator.name,
                  displayName: $scope.selectedComparisonOperator.displayName,
                }
              }
            }
          )

          $scope.$watch(
            'selectedLogicalOperator',
            function(newValue, oldValue) {
              if ($scope.selectedLogicalOperator) {
                $scope.condition.logicalOperator =
                  $scope.selectedLogicalOperator.displayName
              }
            }
          )

          $scope.inputItem = $scope.condition.inputItem
          $scope.needParameter = function() {
            return (
              $scope.selectedSourceField && $scope.selectedSourceField.parameter
            )
          }
          $scope.isCustom = function() {
            return (
              $scope.selectedSourceField &&
              $scope.selectedSourceField.displayName === 'Custom'
            )
          }
          $scope.hasComparisonOperator = function() {
            return (
              $scope.selectedSourceField &&
              $scope.selectedSourceField.hasComparisonOperator
            )
          }
          $scope.hasInputItem = function() {
            return (
              $scope.selectedSourceField &&
              $scope.selectedSourceField.hasInputItem
            )
          }
          $scope.isLogicalOperators = function() {
            return (
              $scope.selectedSourceField &&
              $scope.selectedSourceField.isLogicalOperators === true
            )
          }
          $scope.$watch('inputItem.displayName', function(newVal) {
            $scope.inputItem = $scope.inputItem || {}
            $scope.condition.inputItem = $scope.condition.inputItem || {}
            $scope.condition.inputItem.displayName = newVal
          })

          $scope.$watch(
            'inputItem.parameter',
            function(newVal) {
              $scope.inputItem = $scope.inputItem || {}
              $scope.condition.inputItem = $scope.condition.inputItem || {}
              $scope.condition.inputItem.parameter = newVal
            },
            true
          )

          $scope.$watch('inputItem.custom', function(newVal) {
            if ($scope.inputItem) {
              $scope.inputItem.custom = newVal
              $scope.condition.inputItem = $scope.inputItem
            }
          })
          $scope.addCondition = $scope.$parent.addCondition
          $scope.removeCondition = $scope.$parent.removeCondition
        },
      ],
    }
  })
  .directive('jsonSchemaForm', [
    'SchemaFormOptions',
    function(SchemaFormOptions) {
      return {
        restrict: 'A',
        replace: false,
        require: '?ngModel',
        scope: {
          schema: '=jsonSchemaForm',
        },
        link(scope, element, attributes, ngModel) {
          if (!angular.isObject(scope.schema)) {
            return
          }
          const formElement = window.document.createElement('div')
          const options = angular.extend({}, SchemaFormOptions.options, {
            schema: scope.schema,
          })
          let jsonEditor = null
          element.prepend(formElement)
          ngModel.$render = render

          if (element && element[0] instanceof HTMLElement) {
            element[0].addEventListener(
              'keyup',
              function(event) {
                event.target.blur()
                event.target.focus()
                setViewValueAndErrors()
              },
              true
            )
          }

          function render() {
            if (jsonEditor) {
              angular.element(formElement).html('')
              jsonEditor.destroy()
            }
            options.schema = scope.schema
            jsonEditor = new JSONEditor(formElement, options)
            jsonEditor.setValue(ngModel.$modelValue)
            jsonEditor.on('change', setViewValueAndErrors)
          }
          function setViewValueAndErrors() {
            scope.$evalAsync(function() {
              const errors = jsonEditor.validate()
              ngModel.$setViewValue(jsonEditor.getValue())
              ngModel.$setValidity('jsonSchemaForm', errors.length === 0)
            })
          }
          scope.$watch('schema', render)
        },
      }
    },
  ])
