// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Il catalogo non è stato aggiornato`,
  [`Catalog has been updated`]: `Il catalogo è stato aggiornato`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `Il draft del prodotto {draftProduct} deve fare riferimento a una o più API`,
  [`Number of Calls`]: `Numero di chiamate`,
  [`Changes will apply to newly published APIs only`]: `Le modifiche verranno applicate solo alle nuove API pubblicate`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Sono presenti {label} non compatibili perché hanno un tipo di gateway diverso per questa API.`,
  [`{label} is required`]: `{label} è obbligatorio`,
  [`Application is required`]: `L'applicazione è obbligatoria`,
  [`Plan is required`]: `Il piano è obbligatorio`,
  [`Gateway is required`]: `Il gateway è obbligatorio`,
  [`Consumer Org is required`]: `L'organizzazione consumer è obbligatoria`,
  [`LDAP ATTRIBUTE NAME`]: `NOME ATTRIBUTO LDAP`,
  [`Use the build in Test Application (sandbox only)`]: `Utilizzare la build nell'applicazione di prova (solo sandbox)`,
  [`Select an Application`]: `Selezionare un'applicazione`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Utilizzare l'applicazione di prova (solo sandbox) integrata - disabilitata quando viene selezionato un catalogo non sandbox`,
  [`Choose an existing Application`]: `Selezionare un'applicazione esistente`,
  [`Use JWT for gateway authentication to analytics service`]: `Utilizza JWT per l'autenticazione gateway per il servizio di analisi`,
  [`Select an application to consume the API`]: `Selezionare un'applicazione per utilizzare l'API`,
  [`Use the default built-in Sandbox Catalog'`]: `Utilizza il catalogo Sandbox integrato predefinito'`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: questo catalogo verrà utilizzato come ambito per tutte le altre preferenze. La modifica di questa impostazione richiederà la regolazione di altre preferenze.`,
  [`Select a Consumer Org`]: `Selezionare un'organizzazione consumer`,
  [`Select the Consumer Org your generated application will belong to`]: `Selezionare l'organizzazione consumer a cui apparterrà l'applicazione generata`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Nota: questa opzione può essere modificata solo quando si utilizza l'applicazione di prova Sandbox integrata perché le applicazioni esistenti già apparterranno ad un'organizzazione consumer`,
  [`Generate auto product`]: `Genera prodotto automaticamente`,
  [`Select a product to associate the current API with`]: `Selezionare un prodotto a cui associare l'API corrente`,
  [`Associate the current API to a selected product`]: `Associa l'API corrente ad un prodotto selezionato`,
  [`API management`]: `Gestione API`,
  [`Select the API management instance you would like this API to be published to.`]: `Selezionare l'istanza di gestione API in cui si desidera pubblicare questa API.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: Questa istanza verrà utilizzata come ambito per tutte le altre preferenze. La modifica di questa impostazione richiederà la regolazione di altre preferenze.`,
  [`Provider organization`]: `Organizzazione provider`,
  [`Select the provider organization you would like this API to be published to.`]: `Selezionare l'organizzazione del provider in cui si desidera pubblicare questa API.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: Questa organizzazione verrà utilizzata come ambito per tutte le altre preferenze. La modifica di questa impostazione richiederà la regolazione di altre preferenze.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: Questo spazio verrà utilizzato come ambito per tutte le altre preferenze. La modifica di questa impostazione richiederà la regolazione di altre preferenze.`,
  [`Selected a draft product`]: `È stato selezionato un prodotto bozza`,
  [`Use any available gateway service`]: `Utilizza qualsiasi servizio gateway disponibile`,
  [`Select a compatible gateway service`]: `Selezionare un servizio gateway compatibile`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Nota: questo servizio gateway verrà utilizzato come ambito per tutte le altre preferenze. Le politiche e gli altri contenuti specifici del servizio gateway verranno caricati in base a questa impostazione.`,
  [`Select a gateway`]: `Selezionare un gateway`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Nota: questa opzione è disabilitata quando l'opzione 'Prodotto esistente' è selezionata e utilizzerà il Piano predefinito per il prodotto generato`,
  [`Select a Plan`]: `Selezionare un piano`,
  [`Generate subscription`]: `Genera sottoscrizione`,
  [`A subscription will be created between the selected Product plan and Application`]: `Verrà creata una sottoscrizione tra il piano di prodotto selezionato e l'applicazione`,
  [`Apply a rate limit to the generated product`]: `Applica un limite di frequenza al prodotto generato`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Applica un limite di frequenza al piano predefinito dei prodotti generati. Questa opzione è disabilitata quando l'opzione 'Prodotto esistente' è selezionata e utilizzerà il limite di frequenza definito nel piano selezionato`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `LDAP (Lightweight Directory Access Protocol) è un protocollo Internet per l'accesso e la gestione dei servizi di informazioni della directory distribuita su una rete. Se è abilitato un servizio LDAP enterprise, configurarlo come risorsa per fornire l'autenticazione utente. Se si desidera anche fornire la procedura di avvio per i nuovi utenti di Developer Portal, è necessario completare la sezione Associazione attributo per abilitare l'LDAP modificabile. In questa sezione, selezionare la casella di spunta Gestito dall'utente e fornire l'associazione dei nomi degli attributi LDAP ai valori API Connect di destinazione.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `L'abilitazione dell'approvazione automatica delle attività consente all'autore e ai collaboratori di approvare le relative attività. Questa opzione può essere utile quando non dono disponibili altri approvatori, ma di fatto consente di ignorare i controlli di un altro utente.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Un'integrazione di fatturazione collega API Connect ad un account in un sistema di fatturazione della sottoscrizione di terze parti che gestisce i clienti, i relativi metodi di pagamento, la fatturazione e le sottoscrizioni attive`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Un oggetto provider OAuth nativo fornisce le impostazioni per le operazioni di elaborazione OAuth quali la generazione e la convalida dei token OAuth. Un oggetto provider OAuth può fungere da riferimento per una definizione di sicurezza OAuth per proteggere un'API. Quando si utilizza un provider OAuth nativo, le operazioni OAuth vengono eseguite in modo nativo da API Connect. Ogni oggetto provider OAuth ha un'API di backup. La configurazione aggiorna automaticamente il documento swagger dell'API. È possibile modificare il documento swagger accedendo alla pagina Editor API.  Quando un'API pubblicata fa riferimento ad un oggetto provider OAuth, l'API di backup viene automaticamente resa disponibile nel gateway.`,
  [`About token management`]: `Informazioni sulla gestione dei token`,
  [`About user security`]: `Informazioni sulla sicurezza dell'utente`,
  [`APIs or products`]: `API o prodotti`,
  [`ATM is not deployed, please contact your administrator`]: `ATM non distribuito, contattare l'amministratore`,
  [`Application state change approvals will be enabled`]: `Le approvazioni di modifica dello stato dell'applicazione saranno abilitate`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Definisce le impostazioni da utilizzare per estrarre le credenziali degli utenti dell'applicazione, per autenticarne le identità e concedere l'autorizzazione.`,
  [`See console for details.`]: `Visualizzare la console per i dettagli.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `Il servizio Certificate Manager selezionato non gestisce alcun certificato.`,
  [`The selected product is not published to the Sandbox catalog`]: `Il prodotto selezionato non è pubblicato nel catalogo sandbox`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `Il prodotto selezionato non è pubblicato nel catalogo sandbox, pertanto non è possibile sottoscrivere l'applicazione.`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `IBM Cloud CLI per la piattaforma (se non è precedentemente installato).`,
  [`(Only supported by TLS 1.3)`]: `(Supportato solo da  TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `La direttiva "@listSize" in questo campo definisce i campi dimensionati che non restituiscono elenchi.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `La direttiva "@listSize" in questo campo definisce i campi dimensionati non definiti nel tipo di restituzione del campo.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `La direttiva "@listSize" in questo campo definisce gli argomenti di suddivisione non definiti nel campo.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `La direttiva "@listSize" in questo campo definisce gli argomenti di suddivisione i cui tipi non sono "Int" o "Int!".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `La direttiva "@listSize" in questo campo definisce più argomenti di suddivisione non null.`,
  [`"{name}" (Role) has been created`]: `"{name}" (Ruolo) è stato creato.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (Ruolo) è stato modificato.`,
  [`API ({api}) has been created.`]: `L'API ({api}) è stata creata.`,
  [`API ({api}) has been renamed.`]: `L'API ({api}) è stata ridenominata.`,
  [`API ({api}) has been updated.`]: `L'API ({api}) è stata aggiornata.`,
  [`API ({api}) has been deleted.`]: `L'API ({api}) è stata eliminata.`,
  [`API ({api}) has been published.`]: `L'API ({api}) è stata pubblicata.`,
  [`API ({api}) has validation error.`]: `L'API ({api}) contiene un errore di convalida`,
  [`API ({api}) has validation errors.`]: `L'API ({api}) contiene errori di convalida`,
  [`API version`]: `Versione API`,
  [`APIM data version`]: `Versione dati APIM`,
  [`APIM schema update date`]: `Data aggiornamento schema APIM`,
  [`APIM schema version`]: `Versione schema APIM`,
  [`APIM target data version`]: `Versione dati di destinazione APIM`,
  [`APIM target schema version`]: `Versione schema di destinazione APIM`,
  [`APIM update date`]: `Data aggiornamento APIM`,
  [`Product ({product}) has been created.`]: `Il prodotto ({product}) è stato creato.`,
  [`Product ({product}) has been updated.`]: `Il prodotto ({product}) è stato aggiornato.`,
  [`Product ({product}) has been delete.`]: `Il prodotto ({product}) è stato eliminato.`,
  [`Product ({product}) has been published.`]: `Il prodotto ({product}) è stato pubblicato.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) è stato {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) non è stato {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} dopo l'accodamento)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} dopo l'invio)`,
  [`(none)`]: `(nessuno)`,
  [`(optional)`]: `(facoltativo)`,
  [`*Base endpoint list empty`]: `*Elenco degli endpoint di base vuoto`,
  [`*Must be a valid url`]: `*Deve essere un URL valido`,
  [`*System will also send an email notification to the requester.`]: `*Il sistema invierà anche una notifica tramite e-mail al richiedente.`,
  [`+ redact from...`]: `+ revisiona da...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. È possibile anche gestire il ciclo di vita di questo prodotto all'interno del catalogo.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x seguito da 64 caratteri esadecimali`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Gestisce e gestisce le organizzazioni consumer API 2) Gestisce le community di sviluppatori delle applicazioni 3) Crea definizioni API e prodotto 4) Gestisce il ciclo di vita del prodotto API`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Possiede e amministra le organizzazioni del provider API 2) Gestisce le comunità di sviluppatori dell'applicazione 3) Crea le definizioni API e prodotto 4) Gestisce il ciclo di vita del prodotto API`,
  [`5 Most Active APIs`]: `5 API più attive`,
  [`5 Most Active Products`]: `5 prodotti più attivi`,
  [`5 most active APIs`]: `le 5 API più attive`,
  [`5 most active Products`]: `i 5 prodotti più attivi`,
  [`503 Service Unavailable`]: `503  Servizio non disponibile`,
  [`A request to the server was made without any credentials.`]: `È stata effettuata una richiesta al server senza credenziali.`,
  [`A server error occurred`]: `Si è verificato un errore del server`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Un profilo client TLS configura la catena di certificati e la suite crittografica utilizzate da API Connect durante la connessione come client ad altri sistemi.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Un profilo server TLS configura la catena di certificati e la suite crittografica utilizzate da API Connect durante la presentazione degli endpoint del server ad altri sistemi.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Un catalogo ospita una raccolta dei prodotti API visibili nel Developer Portal associato quando pubblicati`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Un catalogo ospita una raccolta dei prodotti API visibili nel Developer Portal associato quando pubblicati.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Un catalogo rappresenta una raccolta di prodotti API gestiti. I prodotti pubblicati sono visibili ai consumer nel Developer Portal associato al catalogo.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `È richiesto un certificato per proteggere la connessione tra API Connect ed il dominio dell'endpoint di gestione del gateway. I certificati sono necessari anche per proteggere le connessioni tra il gateway ed i domini da esso gestiti. Tutti i certificati devono essere memorizzati in un servizio Certificate Manager. Il servizio fornisce un repository protetto per i certificati ed i mpedisce le interruzioni inviando notifiche in prossimità della scadenza dei certificati. Per iniziare,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Un valore predefinito che verrà utilizzato se gli input all'associazione non sono definiti.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Un servizio gateway rappresenta un insieme di contenitori o server gateway che ospitano le API pubblicate e forniscono gli endpoint API utilizzati dalle applicazioni client. I gateway eseguono i richiami dell'API Proxy indirizzati ai sistemi di backend ed applicano le politiche API che comprendono l'identificazione del client, la sicurezza e la limitazione di frequenza.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Un host gateway pubblica le API e fornisce gli endpoint API utilizzati dalle applicazioni client. I gateway eseguono i richiami del proxy API sui sistemi di backend ed applicano le politiche API che comprendono l'identificazione del client, la sicurezza e la limitazione di frequenza.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `È possibile impostare una destinazione della migrazione su un prodotto di origine per definire la destinazione della migrazione per le sottoscrizioni.  La destinazione della migrazione comprende un'associazione del piano che descrive l'associazione dei piani nel prodotto di origine ai piani nel prodotto di destinazione.  La destinazione della migrazione è visibile nel Developer Portal per comunicare la destinazione della migrazione ai sottoscrittori del prodotto di origine.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Un kit di politica modulare OAuth. È in grado di eseguire tutti i passaggi del protocollo OAuth/OpenID Connect in un criterio (predefinito) oppure può essere suddiviso in più criteri per eseguire solo uno o “n” numero di passaggi alla volta per un controllo più preciso. Gli input e gli output di ciascuno dei passaggi sono guidati da variabili di contesto documentate. Aggiungere o rimuovere i componenti OAuth supportati come richiesto.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Non è possibile creare un portale senza un servizio portale disponibile. Contattare l'amministratore cloud per assistenza.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Un'organizzazione provider è un team proprietario delle API e dei piani e dei prodotti associati. Un'organizzazione provider richiede un proprietario a cui sono assegnate tutte le autorizzazioni. I proprietari vengono assegnati tra i membri.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Un'organizzazione provider include gli utenti che gestiscono API, prodotti, piani, cataloghi, organizzazioni consumer ed altre risorse correlate. È possibile utilizzare le organizzazioni provider per raggruppare gli utenti e le risorse di gestione API in base ai progetti, ai reparti, alle fasi del ciclo di vita e così via. Per gestire ed assegnare i ruoli che gestiscono i privilegi degli utenti, passare a IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Una semplice espressione JavaScript che viene risolta nel valore dell'output. Questo potrebbe essere una stringa statica ("my static string") o un'espressione che riguarda gli input associati ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `AZIONE`,
  [`ANALYTICS`]: `ANALISI`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `Amministratore API`,
  [`API CONNECT VALUE`]: `VALORE API CONNECT`,
  [`API assemble`]: `Assemblaggio API`,
  [`API base endpoints`]: `Endpoint di base API`,
  [`API base URL`]: `URL di base API`,
  [`API base URLs`]: `URL di base API`,
  [`API Calls`]: `Chiamate API`,
  [`API Connect UI`]: `UI di API Connect`,
  [`API Connect services`]: `Servizi API Connect`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect richiede un server di e-mail per inviare gli inviti ed i link di attivazione ai membri e per altri scopi.`,
  [`API Connect verify token`]: `Token di verifica API Connect`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `Licenza API Designer`,
  [`API Designer credentials`]: `Credenziali API Designer`,
  [`API Designer licence`]: `Licenza API Designer`,
  [`API Designer does not currently support WSDL`]: `API Designer non supporta attualmente WSDL`,
  [`API editor`]: `Editor API`,
  [`API Endpoint`]: `Endpoint API`,
  [`API Endpoint Base`]: `Base endpoint API`,
  [`API Endpoint for Unenforced APIs`]: `Endpoint API per le API non applicate`,
  [`API Endpoint for unenforced APIs`]: `Endpoint API per le API non applicate`,
  [`API Endpoints`]: `Endpoint API`,
  [`API Error`]: `Errore API`,
  [`API gateway`]: `Gateway API`,
  [`API Gateway`]: `Gateway API`,
  [`API gateways`]: `Gateway API`,
  [`API Gateways`]: `Gateway API`,
  [`API Invocation Endpoint`]: `Endpoint di richiamo API`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `API non pubblicata. L'API Test(Try it) in Explorer non sarà abilitata.`,
  [`API Key`]: `Chiave API`,
  [`API key`]: `Chiave API`,
  [`API key secret`]: `Segreto chiave API`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `Registro utente locale API Manager`,
  [`API Manager URL`]: `URL API Manager`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `Parametri percorso API`,
  [`API Security Definition`]: `Definizione di sicurezza API`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `L'API Test (Try it) in Explorer non sarà abilitata per questa API perché non è applicata.`,
  [`API Setup`]: `Impostazione API`,
  [`API Subscription`]: `Sottoscrizione API`,
  [`API endpoint`]: `Endpoint API`,
  [`API endpoint base`]: `Base endpoint API`,
  [`API endpoint for unenforced APIs`]: `Endpoint API per le API non applicate`,
  [`API endpoints`]: `Endpoint API`,
  [`API error`]: `Errore API`,
  [`API invocation endpoint`]: `Endpoint di richiamo API`,
  [`API is not enforced on the API gateway.`]: `API non è applicata sul gateway dell'API.`,
  [`API path parameters`]: `Parametri percorso API`,
  [`API security definition`]: `Definizione di sicurezza API`,
  [`API setup`]: `Impostazione API`,
  [`API subscription`]: `Sottoscrizione API`,
  [`API Update`]: `Aggiornamento API`,
  [`API user registries`]: `Registri utente API`,
  [`API and product generated successfully!`]: `API e prodotto generati correttamente!`,
  [`API deletion failed`]: `Eliminazione API non riuscita`,
  [`API does not exist!`]: `L'API non esiste!`,
  [`API from existing REST service`]: `API da servizio REST esistente`,
  [`API from existing SOAP service`]: `API da servizio SOAP esistente`,
  [`API has been updated.`]: `L'API è stata aggiornata.`,
  [`API has been updated`]: `L'API è stata aggiornata`,
  [`API has not been created!`]: `L'API non è stata creata.`,
  [`API has not been updated!`]: `L'API non è stata aggiornata!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `Priorità API (utilizzare gli endpoint di reindirizzamento a microsito definiti nelle definizioni OpenAPI)`,
  [`API properties`]: `Proprietà API`,
  [`API protection source`]: `Origine protezione API`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `L'API a cui fa riferimento il prodotto non esiste. Creare o importare la prima API.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `Le strutture di payload della richiesta e della risposta API sono composte utilizzando le definizioni di schema OpenAPI.`,
  [`API subscription request approved for app {{appName}}`]: `La richiesta di sottoscrizione all'API è stata approvata per l'app  {appName}`,
  [`API subscription request denied for app {{appName}}`]: `La richiesta di sottoscrizione all'API è stata negata per l'app {appName}`,
  [`API subscription request received for app {{appName}}`]: `La richiesta di sottoscrizione all'API è stata ricevuta per l'app {appName}`,
  [`API type`]: `Tipo di API`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `L'API con chiave ''{key}'' nella proprietà 'apis' non esiste e non può essere elencata. Verificare la scheda Origine.`,
  [`APIs`]: `API`,
  [`APIs and products`]: `API e prodotti`,
  [`APIs included in the plan.`]: `Le API incluse nel piano.`,
  [`API Key is created`]: `La chiave API è stata creata`,
  [`API Key is deleted`]: `La chiave API è stata eliminata`,
  [`API Key is successfully created`]: `La chiave API è stata creata correttamente`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `Il timeout della chiave API è **{seconds} secondi ({time})**`,
  [`API key timeout`]: `Timeout chiave API`,
  [`API Key timeout has been changed`]: `Il timeout della chiave API è stato modificato`,
  [`Application type`]: `Tipo di applicazione`,
  [`Associated analytics service`]: `Servizio di analytics associato`,
  [`AVAILABILITY`]: `DISPONIBILITÀ`,
  [`AVAILABILITY ZONE`]: `ZONA DI DISPONIBILITÀ`,
  [`About`]: `Informazioni`,
  [`About audit setting`]: `Informazioni sulle impostazioni di verifica`,
  [`About Authentication URL user registry`]: `Informazioni sul registro utente URL di autenticazione`,
  [`About availability zones`]: `Informazioni sulle zone di disponibilità`,
  [`About catalogs`]: `Informazioni sui cataloghi`,
  [`About consumer organization owners`]: `Informazioni sui proprietari dell'organizzazione consumer`,
  [`About consumer organizations`]: `Informazioni sulle organizzazioni consumer`,
  [`About developer portals`]: `Informazioni sui Developer Portal`,
  [`About email servers`]: `Informazioni relative ai server di e-mail`,
  [`About Endpoints`]: `Informazioni sugli endpoint`,
  [`About gateways`]: `Informazioni sui gateway`,
  [`About gateway visibility`]: `Informazioni sulla visibilità del gateway`,
  [`About Gateway Processing Status`]: `Informazioni sullo stato elaborazione del gateway`,
  [`About introspection`]: `Informazioni sull'introspezione`,
  [`About Keystores`]: `Informazioni sui keystore`,
  [`About LDAP`]: `Informazioni su LDAP`,
  [`About local user registries`]: `Informazioni sui registri utente locali`,
  [`About metadata`]: `Informazioni sui metadati`,
  [`About Migration Target`]: `Informazioni sulla destinazione della migrazione`,
  [`About migration targets`]: `Informazioni sulle destinazioni della migrazione`,
  [`About native OAuth provider`]: `Informazioni sul provider OAuth nativo`,
  [`About OpenID Connect`]: `Informazioni su OpenID Connect`,
  [`About replacing a product`]: `Informazioni sulla sostituzione di un prodotto`,
  [`About Role defaults`]: `Informazioni sulle impostazioni predefinite del ruolo`,
  [`About roles`]: `Informazioni sui ruoli`,
  [`About scopes`]: `Informazioni sugli ambiti`,
  [`About Set Migration Targets`]: `Informazioni su Imposta destinazione migrazione`,
  [`About spaces`]: `Informazioni sugli spazi`,
  [`About Subscription`]: `Informazioni sulla sottoscrizione`,
  [`About superseding a product`]: `Informazioni sulla sovrascrittura di un prodotto`,
  [`About TLS server profile`]: `Informazioni sul profilo server TLS`,
  [`About TLS client profiles`]: `Informazioni relative ai profili client TLS`,
  [`About TLS server profiles`]: `Informazioni relative ai profili TLS`,
  [`About third party OAuth provider`]: `Informazioni sul provider OAuth di terze parti`,
  [`About tokens`]: `Informazioni sui token`,
  [`About Transfer Ownership`]: `Informazioni sul trasferimento della proprietà`,
  [`About Transferring Ownership`]: `Informazioni sul trasferimento di proprietà`,
  [`About Truststores`]: `Informazioni sui truststore`,
  [`About adding a member`]: `Informazioni relative all'aggiunta di un membro`,
  [`About adding provider organization member`]: `Informazioni relative all'aggiunta di un membro dell'organizzazione`,
  [`About adding members to a Space`]: `Informazioni relative all'aggiunta di membri ad uno spazio`,
  [`About adding members to a catalog`]: `Informazioni sull'aggiunta di membri ad un catalogo`,
  [`About changing owners`]: `Informazioni sulla modificare dei proprietari`,
  [`About enabling gateway services`]: `Informazioni sull'abilitazione dei servizi gateway`,
  [`About inviting a member`]: `Informazioni relative all'invito di un membro`,
  [`About inviting catalog members`]: `Informazioni sull'invito dei membri del catalogo`,
  [`About inviting provider organization members`]: `Informazioni relative all'invito di membri all'organizzazione provider`,
  [`About inviting space members`]: `Informazioni sull'invito dei membri dello spazio`,
  [`About keystores`]: `Informazioni relative ai keystore`,
  [`About managing Space membership`]: `Informazioni relative alla gestione dell'appartenenza allo spazio`,
  [`About migrating subscriptions`]: `Informazioni sulla migrazione delle sottoscrizioni`,
  [`About provider organizations`]: `Informazioni sulle organizzazioni provider`,
  [`About roles and role defaults`]: `Informazioni relative alle impostazioni predefinite del ruolo`,
  [`About roles in spaces`]: `Informazioni relative ai ruoli negli spazi`,
  [`About selecting gateway services`]: `Informazioni sulla selezione dei servizi gateway`,
  [`About send message`]: `Informazioni su Invia messaggio`,
  [`About sending messages`]: `Informazioni sull'invio di messaggi`,
  [`About the analytics service`]: `Informazioni sul servizio analisi`,
  [`About the DNS Scheme`]: `Informazioni relative alo schema DNS`,
  [`About the Gateway Service`]: `Informazioni sul servizio gateway`,
  [`About the Portal Service`]: `Informazioni sul servizio portale`,
  [`About the product lifecycle`]: `Informazioni sul ciclo di vita del prodotto`,
  [`About the provider organization owner`]: `Informazioni sul proprietario dell'organizzazione provider`,
  [`About this Cloud`]: `Informazioni su questo cloud`,
  [`About transferring ownership`]: `Informazioni relative al trasferimento della proprietà`,
  [`About truststores`]: `Informazioni relative ai truststore`,
  [`About visibility`]: `Informazioni sulla visibilità`,
  [`About visibility and subscribability`]: `Informazioni sulla visibilità e sulla sottoscrivibilità`,
  [`Accept`]: `Accetto`,
  [`Access Code`]: `Codice accesso`,
  [`Access Token`]: `Token di accesso`,
  [`Access URL through Secure Gateway`]: `Accedi all'URL mediante Secure Gateway`,
  [`Access code`]: `Codice di accesso`,
  [`Access code is not chosen in supported grant type.`]: `Il codice di accesso non è scelto nel tipo di concessione supportato.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Esegue l'accesso all'URL attraverso un Secure Gateway. Il gateway viene impostato quando l'API passa dallo stato in staging allo stato pubblicato.`,
  [`Access token TTL has been changed`]: `Il TTL del token di accesso è stato modificato`,
  [`Access token time to live`]: `TTL del token di accesso`,
  [`Access token time-to-live`]: `TTL (time-to-live) del token di accesso`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Il token di accesso scade tra **{seconds} secondi ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `I token di accesso sono concessi all'applicazione client per consentire all'applicazione di accedere a risorse per conto dell'utente dell'applicazione.  I token di aggiornamento vengono inviati al client per ottenere un nuovo token di accesso quando quello corrente diventa non valido o scade, o per ottenere ulteriori token di accesso con ambiti identici o limitati. È possibile specificare anche per quanto tempo il consenso dato dalla combinazione di qualsiasi numero di token di accesso e di aggiornamento rimane valido.`,
  [`Access tokens time to live (seconds)`]: `TTL (time to live) token di accesso (secondi)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Il token di accesso, l'ID token e il token temporaneo possono essere assegnati a qualsiasi keystore esistente. La cronologia dell'assegnazione del keystore viene conservata.`,
  [`Account`]: `Account`,
  [`Account has been updated.`]: `L'account è stato aggiornato.`,
  [`Account password has been changed.`]: `La password dell'account è stata modificata.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Azione`,
  [`Activate API`]: `Attiva API`,
  [`Activate API|button text`]: `Attiva API`,
  [`Activate API for testing|title`]: `Attiva API`,
  [`Activation link`]: `Link di attivazione`,
  [`Active`]: `Attivo`,
  [`Activity log`]: `Log delle attività`,
  [`Activity log API setting is not available for this API's gateway type.`]: `L'impostazione Log delle attività API non è disponibile per questo tipo di gateway di API.`,
  [`Active loading indicator`]: `Indicatore di caricamento attivo`,
  [`Add`]: `Aggiungi`,
  [`Add allowlist`]: `Aggiungi ad elenco consentiti`,
  [`Add a new billing integration in Resources`]: `Aggiungi una nuova integrazione di fatturazione nelle Risorse`,
  [`Add API user registries`]: `Aggiungi registri utenti delle API`,
  [`Add APIs to Product`]: `Aggiungi API al prodotto`,
  [`Add another cloud`]: `Aggiungi un altro cloud`,
  [`Add billing integration`]: `Aggiungi integrazione di fatturazione`,
  [`Add Burst Limit`]: `Aggiungi limite di burst`,
  [`Add Ciphers for TLS client profile`]: `Aggiungi crittografie al profilo client TLS`,
  [`Add Ciphers for TLS server profile`]: `Aggiungere crittografie al profilo server TLS`,
  [`Add Condition`]: `Aggiungi condizione`,
  [`Add Consumer Role`]: `Aggiungi ruolo consumer`,
  [`Add Count Limit`]: `Aggiungi limite conteggio`,
  [`Add Credential`]: `Aggiungi credenziale`,
  [`Add domain`]: `Aggiungi dominio`,
  [`Add credential`]: `Aggiungi credenziale`,
  [`Add Extension`]: `Aggiungi estensione`,
  [`Add GraphQL schema`]: `Aggiungi schema GraphQL`,
  [`Add HTTP Endpoint`]: `Aggiungi endpoint HTTP`,
  [`Add group`]: `Aggiungi gruppo`,
  [`Add JSON schema`]: `Aggiungere schema JSON`,
  [`Add Keystore to TLS client profile`]: `Aggiungi keystore a profilo client TLS`,
  [`Add Keystore to TLS server profile`]: `Aggiungi keystore a profilo server TLS`,
  [`Add member`]: `Aggiungi membro`,
  [`Add New Role for Consumer organization`]: `Aggiungi nuovo ruolo per organizzazione consumer`,
  [`Add operation`]: `Aggiungi operazione`,
  [`Add Parameters`]: `Aggiungi parametri`,
  [`Add Policy`]: `Aggiungi politica`,
  [`Add Rate limit`]: `Aggiungi limite di frequenza`,
  [`Add Rate Limit`]: `Aggiungi limite di frequenza`,
  [`Add role`]: `Aggiungi ruolo`,
  [`Add Syslog TCP Endpoint`]: `Aggiungi endpoint Syslog TCP`,
  [`Add Syslog TLS Endpoint`]: `Aggiungi endpoint Syslog TLS`,
  [`Add Syslog UDP Endpoint`]: `Aggiungi endpoint Syslog UDP`,
  [`Add XML schema`]: `Aggiungere schema XML`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Aggiungere un endpoint gateway che si desidera rendere disponibile per le chiamate alle API in questo catalogo.`,
  [`Add a different Cloud Connection.`]: `Aggiungi una diversa connessione cloud.`,
  [`Add a member from the user registry`]: `Aggiungi un membro dal registro utente`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Aggiungere un utente all'organizzazione Admin e assegnare i ruoli assegnati`,
  [`Add a user to the provider organization, and assign the required roles`]: `Aggiungere un utente all'organizzazione provider e assegnare i ruoli richiesti.`,
  [`Add a user to the Catalog, and assign the required roles`]: `Aggiungere un utente al catalogo ed assegnare i ruoli necessari`,
  [`Add a user to the Space, and assign the required roles`]: `Aggiungere un utente allo spazio e assegnare i ruoli richiesti`,
  [`Add action`]: `Aggiungi azione`,
  [`Add additional client ID/client secret pairs`]: `Aggiungi ulteriori coppie ID client/segreto client`,
  [`Add blocklist`]: `Aggiungi elenco di blocco`,
  [`Add case`]: `Aggiungi caso`,
  [`Add catch`]: `Aggiungi cattura`,
  [`Add default catch`]: `Aggiungi cattura predefinita`,
  [`Add destination`]: `Aggiungi destinazione`,
  [`Add entry`]: `Aggiungi voce`,
  [`Add group failed.`]: `Aggiunta del gruppo non riuscita.`,
  [`Add input`]: `Aggiungi input`,
  [`Add media type`]: `Aggiungi tipo di supporto`,
  [`Add member failed`]: `Aggiunta membro non riuscita`,
  [`Add object`]: `Aggiungi oggetto`,
  [`Add otherwise`]: `Aggiungi alternativa`,
  [`Add output`]: `Aggiungi output`,
  [`Add outputs for operation...`]: `Aggiungi output per l'operazione...`,
  [`Add parameters for operation...`]: `Aggiungi parametri per l'operazione...`,
  [`Add parameters to this API`]: `Aggiungi parametri a questa API`,
  [`Add plans to product`]: `Aggiungi piani al prodotto`,
  [`Add plans to this product`]: `Aggiungere i piani a questo prodotto`,
  [`Add schema`]: `Aggiungi schema`,
  [`Add scopes for this OAuth provider.`]: `Aggiungere gli ambiti per questo provider OAuth.`,
  [`Add scopes to allow access to`]: `Aggiunge ambiti a cui consentire l'accesso`,
  [`Add scopes to allow access to.`]: `Aggiungere gli ambiti a cui consentire l'accesso.`,
  [`Add tags and external documentation details for this API`]: `Aggiungi i tag ed i dettagli della documentazione esterna per questa API`,
  [`Add to`]: `Aggiungi a`,
  [`Add to existing product`]: `Aggiungi a prodotto esistente`,
  [`Add/Remove APIs`]: `Aggiungi/Rimuovi API`,
  [`AddProperties`]: `AddProperties`,
  [`Added APIs`]: `API aggiunte`,
  [`Added rate limit`]: `Limite di frequenza aggiunto`,
  [`Added rate limits`]: `Limiti di frequenza aggiunti`,
  [`Adding an API to a product for publishing`]: `Aggiunta di un'API ad un prodotto per la pubblicazione`,
  [`Additional support`]: `Supporto aggiuntivo`,
  [`Additional properties`]: `Proprietà aggiuntive`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Inoltre, considerare la rimozione del registro associato, Registro utente del catalogo {name}, se non utilizzato.`,
  [`Address`]: `Indirizzo`,
  [`Admin DN`]: `DN amministratore`,
  [`Admin organization invitation timeout`]: `Timeout invito organizzazione Admin`,
  [`Admin organization invitation timeout has been changed`]: `Il timeout di invito dell'organizzazione admin è stato modificato`,
  [`Admin password`]: `Password Admin`,
  [`Admin request reset password`]: `Reimpostazione password richiesta admin`,
  [`Admin reset password`]: `Reimpostazione password admin`,
  [`Admin Sign In`]: `Accesso admin`,
  [`Admin add catalog failed`]: `Aggiunta catalogo Admin non riuscita`,
  [`Admin add space failed`]: `Aggiunta spazio Admin non riuscita`,
  [`Administer consumer organizations`]: `Gestire le organizzazioni consumer`,
  [`Administers the API consumer organization`]: `Gestisce l'organizzazione consumer API`,
  [`Administers the API provider organization`]: `Gestisce l'organizzazione provider API`,
  [`Administers the admin organization`]: `Gestisce l'organizzazione admin`,
  [`Administers the admin topology`]: `Gestisce la topologia di admin`,
  [`Administers the app developer organization`]: `Gestisce l'organizzazione dello sviluppatore di app.`,
  [`Administers the catalog`]: `Gestisce il catalogo`,
  [`Administers the cloud topology`]: `Gestisce la topologia cloud`,
  [`Administers the space`]: `Amministra lo spazio`,
  [`Administration management console`]: `Console di gestione di Administration`,
  [`Administrator`]: `Amministratore`,
  [`Advanced analytics configuration`]: `Configurazione di analisi avanzata`,
  [`Advanced features`]: `Funzioni avanzate`,
  [`Advanced scope check`]: `Controllo ambito avanzato`,
  [`Advanced scope check after Token Validation`]: `Verifica avanzata dell'ambito dopo la convalida del token`,
  [`Advanced scope check before Token Generation`]: `Verifica avanzata dell'ambito prima della generazione del token`,
  [`Aggregation`]: `Aggregazione`,
  [`Agree`]: `Accetto`,
  [`All`]: `Tutte`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Tutti gli sviluppatori autenticati nelle organizzazioni consumer che hanno eseguito la registrazione per il Developer Portal possono visualizzare questo prodotto.`,
  [`All consumer organizations will be able to see this product.`]: `Tutte le organizzazioni consumer saranno in grado di visualizzare questo prodotto.`,
  [`Allowlist`]: `Elenco valori consentiti`,
  [`Allow Chunked Uploads`]: `Consenti caricamenti in blocchi`,
  [`Allow chunked uploads`]: `Consenti caricamenti in chunk`,
  [`Allow "plain" challenge method`]: `Consenti metodo riquadro "plain"`,
  [`Allow renegotiation`]: `Consenti rinegoziazione`,
  [`Allow case sensitive usernames`]: `Consenti utilizzo di nomi utente sensibili a maiuscolo/minuscolo`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Consente ai client di inserire un'estensione SNI con il nome host desiderato nel primo handshake con il server.`,
  [`Allow connection to be renegotiated`]: `Consente le rinegoziazione della connessione`,
  [`Allow default introspection`]: `Consenti introspezione predefinita`,
  [`Allow insecure server connections`]: `Consenti connessioni al server non protette`,
  [`Allow null value`]: `Consenti valore null`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Consenti a access_token/refresh_token di inviare il reindirizzamento 302 per la disconnessione`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Tutti i suggerimenti sono stati applicati. Non vi sono avvertenze per lo schema.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Consentire la verifica delle operazioni dell'API utilizzando gli strumenti di test in Developer Portal.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Consente di procedere con la connessione con credenziali deboli o non sicure`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Consente alla connessione di procedere con credenziali deboli o non sicure.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Consentire al provider OIDC di comunicare con Developer Portal, non con API Manager.`,
  [`Allow to manage this user registry`]: `Consenti di gestire questo registro utente`,
  [`Allow to manage users under this user registry`]: `Consenti di gestire gli utenti in questo registro utente`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Consentire agli utenti di eseguire l'avvio automaticamente quando APIC viene presentato con un token emesso dall'emittente.`,
  [`Already have an account?`]: `Si dispone già di un account?`,
  [`Also transfer ownership of owned Spaces`]: `Trasferisci anche la proprietà dello spazio di proprietà`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Recuperare sempre i dati utente da un endpoint userinfo, se abilitato`,
  [`Always output the root element`]: `Elemento root sempre come output`,
  [`Always output the root element.`]: `Elemento root sempre come output.`,
  [`Always required`]: `Sempre obbligatorio`,
  [`Always use userinfo endpoint`]: `Utilizzare sempre l'endpoint userinfo`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Un'API provider OAuth contiene l'autorizzazione e gli endpoint token di un flusso OAuth. Configurare in questo punto i provider OAuth; quindi, quando si crea un'API protetta OAuth è possibile selezionare il provider richiesto.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Un provider OAuth contiene l'autorizzazione e gli endpoint token di un flusso OAuth; selezionare il provider OAuth che si desidera utilizzare per proteggere l'API con OAuth`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Un'applicazione viene elencata in questo punto quando uno sviluppatore la sottoscrive ad un piano nello spazio, in modo da poterne richiamare le API associate; è possibile sospendere un'applicazione dello sviluppatore per bloccarne l'accesso alle API e creare le proprie applicazioni. [Ulteriori informazioni]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Un URL di autenticazione punta ad un provider di autenticazione di terze parti come registro utente. Un URL di autenticazione abilita l'integrazione con un registro utente di terze parti diverso da LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Un URL di autenticazione fornisce un semplice meccanismo per l'autenticazione degli utenti rispetto ad un provider di identità personalizzato.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Si è verificato un errore durante la comunicazione con il sottosistema di gateway.`,
  [`An organization is required.`]: `È necessaria un'organizzazione.`,
  [`An unknown error occurred.`]: `Si è verificato un errore sconosciuto.`,
  [`Analytics`]: `Analisi`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Servizio Analytics Insights'`,
  [`Analytics Details`]: `Dettagli analytics`,
  [`Analytics destinations`]: `Destinazioni analisi`,
  [`Analytics Director keystore`]: `Keystore Analytics Director`,
  [`Analytics Service`]: `Servizio analisi`,
  [`Analytics Service URL`]: `URL servizio di analytics`,
  [`Analytics Service {arg} has been removed.`]: `Servizio di analytics {arg} rimosso.`,
  [`Analytics details`]: `Dettagli analytics`,
  [`Analytics service`]: `Servizio analisi`,
  [`Analytics service details`]: `Dettagli del servizio di analitiche`,
  [`Analytics service URL`]: `URL servizio di analytics`,
  [`Analytics service {arg} has been removed.`]: `Servizio di analytics {arg} rimosso.`,
  [`Analytics client TLS client profile`]: `Profilo client TLS per il client di analytics`,
  [`Analytics client keystore`]: `Keystore per il client di analytics`,
  [`Analytics client truststore`]: `Truststore per il client di analytics`,
  [`Analytics ingestion TLS client profile`]: `Profilo client TLS per l'inserimento di analytics`,
  [`Analytics ingestion keystore`]: `Keystore per l'inserimento di analytics`,
  [`Analytics ingestion truststore`]: `Truststore per l'inserimento di analytics`,
  [`Analytics service {title} has been created.`]: `Il servizio di analytics {title} è stato creato.`,
  [`Analytics service {title} has been updated.`]: `Il servizio di analytics {title} è stato aggiornato.`,
  [`The selected consumer organization is`]: `L'organizzazione consumer selezionata è`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `I servizi Analytics sono configurati e i dati di analisi vengono scaricati all'esterno`,
  [`Analyze API usage and performance`]: `Analizzare l'utilizzo e le prestazioni delle API`,
  [`Anonymous bind`]: `Bind anonimo`,
  [`Another user registry`]: `Un altro registro utente`,
  [`api-administrator`]: `amministratore-api`,
  [`Api-Analytics`]: `Analisi-Api`,
  [`Api-Drafts`]: `Api-Drafts`,
  [`App`]: `App`,
  [`App name`]: `Nome app`,
  [`App-Analytics`]: `Analisi-App`,
  [`App-Approval`]: `Approvazione-Api`,
  [`App-Dev`]: `Svi-App`,
  [`App-analytics`]: `Analisi-App`,
  [`App-dev`]: `Svi-app`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Applicazione`,
  [`Application Authentication`]: `Autenticazione applicazione`,
  [`Application Authentication Source`]: `Origine autenticazione applicazione`,
  [`Application lifecycle`]: `Ciclo di vita dell'applicazione`,
  [`Application approval task for upgrading application`]: `Attività di approvazione dell'applicazione per l'aggiornamento dell'applicazione`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Attività di approvazione dell'applicazione per l'aggiornamento dell'applicazione {taskname} alla produzione richiesta da {username} ({orgname})`,
  [`Application authentication`]: `Autenticazione dell'applicazione`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Inizialmente, gli sviluppatori delle applicazioni effettuano la sottoscrizione delle proprie applicazioni ad uno o più piani utilizzando Developer Portal. Tuttavia, per un piano selezionato, è possibile migrare le sottoscrizioni dell'applicazione ad un piano in un altro prodotto.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Inizialmente, gli sviluppatori delle applicazioni effettuano la sottoscrizione delle proprie applicazioni ad uno o più piani utilizzando Developer Portal. Tuttavia, per un piano selezionato, è possibile migrare le sottoscrizioni dell'applicazione ad un piano in un altro prodotto.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Gli sviluppatori delle applicazioni sottoscrivono un'applicazione ad un piano in modo da poter richiamare le API in tale piano.`,
  [`Application is being created. Please Wait`]: `L'applicazione è in fase di creazione. Attendere.`,
  [`Application scope check`]: `Controllo ambito applicazione`,
  [`Applications`]: `Applicazioni`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Le applicazioni esistenti prima dell'attivazione del ciclo di vita dell'applicazione resteranno nello stato di produzione`,
  [`Applied security`]: `Sicurezza applicata`,
  [`Apply`]: `Applica`,
  [`Apply all`]: `Applica tutto`,
  [`Apply all suggestions`]: `Applica tutti i suggerimenti`,
  [`Apply analysis configuration`]: `Applica configurazione dell'analisi`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Applicare la politica di richiamo per chiamare un servizio esistente dall'interno dell'operazione. La politica può essere utilizzata con dati JSON o XML e può essere applicata più volte all'interno dell'assemblaggio.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Applicare la politica di aggiornamento websocket per stabilire una connessione websocket per chiamare un servizio esistente dall'interno dell'operazione.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Applicare questa politica per richiamare un'API proxy all'interno dell'operazione, in particolare se è necessario richiamare un payload di grandi dimensioni. È consentito richiamare una sola politica proxy per assemblaggio.`,
  [`Apply to selected`]: `Applica a selezionati`,
  [`Apply type analysis configuration`]: `Applica configurazione analisi del tipo`,
  [`Approval History`]: `Cronologia approvazioni`,
  [`Approval tasks`]: `Attività di approvazione`,
  [`Approvals`]: `Approvazioni`,
  [`Approve`]: `Approva`,
  [`Archive`]: `Archivia`,
  [`Archive Product`]: `Archivia prodotto`,
  [`Archive|permission`]: `Archivia`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Si è sicuri di voler eliminare i vincoli dagli elementi selezionati?`,
  [`Are you sure you want to delete API?`]: `Si è sicuri di voler eliminare l'API?`,
  [`Are you sure you want to delete Product?`]: `Si è sicuri di voler eliminare il prodotto?`,
  [`Are you sure you want to delete a catalog?`]: `Si è sicuri di voler eliminare un catalogo?`,
  [`Are you sure you want to delete a space?`]: `Si è sicuri di voler eliminare uno spazio?`,
  [`Are you sure you want to delete this application?`]: `Si è sicuri di voler eliminare questa applicazione?`,
  [`Are you sure you want to delete this billing integration?`]: `Si è sicuri di voler eliminare questa integrazione di fatturazione?`,
  [`Are you sure you want to delete this field?`]: `Si è sicuri di voler eliminare questo campo?`,
  [`Are you sure you want to delete this policy?`]: `Si è sicuri di voler eliminare questa politica?`,
  [`Are you sure you want to delete this type?`]: `Si è sicuri di voler eliminare questo tipo?`,
  [`Are you sure you want to disable spaces?`]: `Si è sicuri di voler disabilitare gli spazi?`,
  [`Are you sure you want to disable custom email sender information?`]: `Si è sicuri di voler disabilitare le informazioni sul mittente email personalizzato?`,
  [`Are you sure you want to disable custom notification templates?`]: `Si è sicuri di voler disabilitare i template di notifica personalizzati?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Si è sicuri di voler interrompere l'utilizzo di IBM Developer Portal? Verranno eliminate tutte le personalizzazioni per il catalogo.`,
  [`Are you sure you want to enable spaces?`]: `Si è sicuri di voler abilitare gli spazi?`,
  [`Are you sure you want to enable custom email sender information?`]: `Si è sicuri di voler abilitare le informazioni sul mittente email personalizzato?`,
  [`Are you sure you want to enable custom notification templates?`]: `Si è sicuri di voler abilitare i template di notifica personalizzati?`,
  [`Are you sure you want to proceed with delete?`]: `Si è sicuri di voler procedere con l'eliminazione?`,
  [`Are you sure you want to publish?`]: `Si è sicuri di voler pubblicare?`,
  [`Are you sure you want to re-stage?`]: `Si è sicuri di voler eseguire un nuovo staging?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Si è sicuri di voler rimuovere il prodotto dal catalogo?`,
  [`Are you sure you want to unassociate?`]: `Si è sicuri di voler annullare l'associazione?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Si è sicuri di voler aggiornare la procedura di avvio autonoma?`,
  [`Are you sure?`]: `Si è sicuri di voler procedere?`,
  [`Argument`]: `Argomento`,
  [`Array`]: `Array`,
  [`Assemble`]: `Assembla`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Assemblare il flusso di politica richiesto per il provider OAuth.`,
  [`Assembly`]: `Assemblaggio`,
  [`Assembly Execute`]: `Esecuzione assemblaggio`,
  [`Assembly saved`]: `Assemblaggio salvato`,
  [`Assembly burst limits`]: `Limiti di burst dell'assemblaggio`,
  [`Assembly count limits`]: `Limiti di conteggio dell'assemblaggio`,
  [`Assign roles`]: `Assegna ruoli`,
  [`Assign roles to the current owner`]: `Assegna ruoli al proprietario corrente`,
  [`Associate`]: `Associa`,
  [`Associate analytics service`]: `Associa servizio di analytics`,
  [`Associate analytics`]: `Associa analisi`,
  [`Assumed size`]: `Dimensione presunta`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Errore di autenticazione durante il tentativo di ottenere l'API dall'URL {url}. Controllare nome utente e password.`,
  [`Audience claim`]: `Richiesta destinatario`,
  [`Audience Claim`]: `Richiesta destinatario`,
  [`Audit setting`]: `Impostazioni di verifica`,
  [`Audit setting has been changed`]: `L'impostazione di verifica è stata modificata`,
  [`Auditing is **{mode}**`]: `Il controllo è **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `La verifica viene utilizzata per monitorare le chiamate API e le informazioni di log sugli utenti chiamanti, sulla durata di ciascuna chiamata e l'attività coinvolta in ciascun evento.`,
  [`Authenticate Client Method`]: `Autentica metodo client`,
  [`Authenticate password`]: `Autentica password`,
  [`Authenticate user`]: `Autentica utente`,
  [`Authenticate user settings`]: `Autentica impostazioni utente`,
  [`Authenticate application users using`]: `Autenticazione degli utenti dell'applicazione che utilizzano`,
  [`Authenticate using Basic Authentication`]: `Autenticazione con autenticazione di base`,
  [`Authenticate using OAuth`]: `Autenticazione con OAuth`,
  [`Authenticated`]: `Autenticato`,
  [`Authenticated bind`]: `Bind autenticato`,
  [`Authenticated User Name`]: `Nome utente autenticato`,
  [`Authentication`]: `Autenticazione`,
  [`Authentication Bind`]: `Bind autenticazione`,
  [`Authentication error`]: `Errore di autenticazione`,
  [`Authentication method`]: `Metodo di autenticazione`,
  [`Authentication TLS profile`]: `Profilo TLS di autenticazione`,
  [`Authentication URL`]: `URL di autenticazione`,
  [`Authentication URL user registry`]: `Registro utente URL di autenticazione`,
  [`Authentication URL user registry has been created.`]: `Il registro utente URL di autenticazione è stato creato.`,
  [`Authentication URL to use for validation.`]: `URL di autenticazione da utilizzare per la convalida`,
  [`Authentication response header credential`]: `Credenziale intestazione risposta di autenticazione`,
  [`Authentication response header pattern`]: `Pattern intestazione risposta di autenticazione`,
  [`Authentication type`]: `Tipo di autenticazione`,
  [`Authentication type to use to validate the UsernameToken.`]: `Tipo di autenticazione da utilizzare per convalidare UsernameToken.`,
  [`Authorization`]: `Autorizzazione`,
  [`Authorization Code`]: `Codice di autorizzazione`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `L'URL di autorizzazione deve corrispondere a {endpoint} come definito dal provider OAuth "{providerTitle}"`,
  [`Authorization endpoint`]: `Endpoint di autorizzazione`,
  [`Authorization Request`]: `Richiesta autorizzazione`,
  [`Authorization URL`]: `URL autorizzazione`,
  [`Authorize`]: `Autorizza`,
  [`Authorize User Settings`]: `Autorizza impostazioni utente`,
  [`Authorize application users using`]: `Autorizza gli utenti dell'applicazione che utilizzano`,
  [`Authorize path`]: `Percorso autorizzazione`,
  [`Authors API and product definitions`]: `Crea le API e le definizioni del prodotto`,
  [`Auto Generate OIDC API Assembly`]: `Generare automaticamente l'assemblaggio API OIDC`,
  [`Auto onboard`]: `Procedura di avvio automatica`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Nome generato automaticamente da utilizzare nei comandi e nelle API di gestione di API Connect.`,
  [`Automation`]: `Automazione`,
  [`Automation Management Console`]: `Console di gestione di Automation`,
  [`Automation management console`]: `Console di gestione di Automation`,
  [`Availability Zone`]: `Zona di disponibilità`,
  [`Availability zone`]: `Zona di disponibilità`,
  [`Availability zone {arg} has been removed.`]: `La zona di disponibilità {arg} è stata rimossa.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Le zone di disponibilità consentono di raggruppare i servizi API Connect per adattarsi alle esigenze di business. Ad esempio, è possibile raggruppare i servizi gateway in base alla regione o al data center in cui si trovano.`,
  [`Average response time: {{value}}ms`]: `Tempo medio di risposta: {{value}}ms`,
  [`Average time`]: `Tempo medio`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Evita errori CORS utilizzando il server API Designer come proxy locale.`,
  [`Back`]: `Indietro`,
  [`Back to sign in`]: `Torna all'accesso`,
  [`Back to test`]: `Torna al test`,
  [`Backend type`]: `Tipo di backend`,
  [`Bad gateway`]: `Gateway errato`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `DN base`,
  [`Base Path`]: `Percorso base`,
  [`Base path`]: `Percorso di base`,
  [`Base endpoint`]: `Endpoint base`,
  [`Base endpoints`]: `Endpoint di base`,
  [`Base64 encoding`]: `Codifica Base64`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `La codifica Base64 per gli inviti e i token di reimpostazione delle password è **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `L'impostazione della codifica Base64 per il token temporaneo è stata modificata`,
  [`Base URL of API invocation endpoint`]: `URL di base dell'endpoint di richiamo API`,
  [`Basepath root`]: `Root percorso di base`,
  [`Basic`]: `Base`,
  [`Basic authentication`]: `Autenticazione di base`,
  [`Basic authentication password`]: `Password di autenticazione di base`,
  [`Basic authentication request header name`]: `Nome intestazione della richiesta di autenticazione di base`,
  [`Basic authentication username`]: `Nome utente autenticazione di base`,
  [`Before you begin`]: `Prima di iniziare`,
  [`Before you begin...`]: `Prima di iniziare...`,
  [`Between 0 and 10 events are waiting to be processed`]: `Un numero di eventi tra gli 0 e i 10 sono in attesa di essere elaborati`,
  [`Between 10 and 20 events are waiting to be processed`]: `Un numero di eventi tra i 10 e i 20 sono in attesa di essere elaborati`,
  [`Billing`]: `Fatturazione`,
  [`Billing and Payment`]: `Fatturazione e pagamento`,
  [`Billing has been updated`]: `La fatturazione è stata aggiornata`,
  [`Billing integrations`]: `Integrazioni di fatturazione`,
  [`Billing integration`]: `Integrazione fatturazione`,
  [`Billing Integration`]: `Integrazione fatturazione`,
  [`Billing {title} successfully added.`]: `Fatturazione {title} aggiunta correttamente.`,
  [`Billing {title} successfully deleted.`]: `Fatturazione {title} eliminata correttamente.`,
  [`Billing {title} successfully updated.`]: `Fatturazione {title} aggiornata correttamente.`,
  [`Billing Options`]: `Opzioni di fatturazione`,
  [`Blocklist`]: `Elenco di blocco`,
  [`Blocking`]: `Blocco`,
  [`Blocking option`]: `Opzione di blocco`,
  [`Body`]: `Corpo`,
  [`Boolean`]: `Booleano`,
  [`Both`]: `Entrambi`,
  [`Browse`]: `Sfoglia`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Memorizza nel buffer le richieste API e le risposte prima dell'elaborazione in DataPower API Gateway.`,
  [`Buffering`]: `Buffering`,
  [`Builds and manages apps in the developer organization`]: `Crea e gestisce app nell'organizzazione dello sviluppatore.`,
  [`Burst Limit Name`]: `Nome limite burst`,
  [`Burst limits`]: `Limiti burst`,
  [`Burst limit value can't be negative`]: `Il valore del limite di burst non può essere negativo`,
  [`Buy`]: `Acquista`,
  [`but unable to remove because of limitation`]: `ma non è possibile rimuoverlo a causa di una limitazione`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Configurando la visibilità e la sottoscrivibilità di un prodotto, è possibile controllare la disponibilità delle relative API per gli sviluppatori delle applicazioni in Developer Portal. È possibile controllare gli sviluppatori delle applicazioni che possono visualizzare il prodotto e gli sviluppatori delle applicazioni che possono effettuare la sottoscrizione per l'utilizzo delle API nel prodotto.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Configurando la visibilità e la sottoscrivibilità di un prodotto, è possibile controllare la disponibilità delle relative API per gli sviluppatori delle applicazioni in Developer Portal. È possibile controllare gli sviluppatori delle applicazioni che possono visualizzare il prodotto e gli sviluppatori delle applicazioni che possono effettuare la sottoscrizione per l'utilizzo delle API nel prodotto.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Per impostazione predefinita, l'associazione da più origini (ad esempio array1 di lunghezza 2 ed array2 di lunghezza 3) avrà come risultato un array di destinazione che contiene 5 elementi (2 mediante l'elaborazione di array1 e 3 mediante l'elaborazione di array2). Se si preferisce combinare tra loro tali regole (mediante la creazione di un array che contiene 2 o 3 elementi con proprietà da ciascun array di origine combinate tra loro), selezionare questa opzione.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Per impostazione predefinita, questo prodotto è pubblicato su tutti i servizi gateway pertinenti. Abilitando questa opzione, è possibile anche pubblicare su servizi gateway specifici.`,
  [`By payload`]: `Per payload`,
  [`By URL parameter`]: `Per parametro URL`,
  [`Bytes`]: `Byte`,
  [`CALLS`]: `CHIAMATE`,
  [`Calls`]: `Chiamate`,
  [`CATALOG`]: `CATALOGO`,
  [`CERTIFICATE NAME`]: `NOME CERTIFICATO`,
  [`CIPHER`]: `CRITTOGRAFIA`,
  [`CIPHER SUITES`]: `SUITE CRITTOGRAFICA`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Solo CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `ID client`,
  [`CLOUD ADMINISTRATOR`]: `AMMINISTRATORE CLOUD`,
  [`Consumer organization`]: `Organizzazione consumer`,
  [`CONSUMER ORGANIZATION / GROUP`]: `GRUPPO/ORGANIZZAZIONE CONSUMER`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `Bundle CA`,
  [`Cache Key`]: `Chiave cache`,
  [`Cache Time to Live (second)`]: `TTL (Time to Live) cache (secondi)`,
  [`Cache key`]: `Chiave cache`,
  [`Cache Time-To-Live`]: `TTL (Time-To-Live) cache`,
  [`Cache type`]: `Tipo di cache`,
  [`Can't find the one you want?`]: `Impossibile trovare ciò che si desidera?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Non è possibile inviare un invito con un server di posta non valido, controllare le configurazioni del server di posta e riprovare`,
  [`Cancel`]: `Annulla`,
  [`Cannot be below {min}`]: `Non può essere inferiore a {min}`,
  [`Cannot be empty`]: `Non può essere vuoto`,
  [`Cannot delete root type.`]: `Impossibile eliminare il tipo root.`,
  [`Cannot exceed {max}`]: `Non può superare {max}`,
  [`Cannot find any APIs for this product`]: `Impossibile trovare API per questo prodotto`,
  [`Cannot find the one you want?`]: `Impossibile trovare quello che si desidera?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Impossibile utilizzare la direttiva "@listSize" su un campo che non restituisce un elenco quando non viene definito anche "sizedFields".`,
  [`Cannot remove built-in scalar types`]: `Impossibile rimuovere i tipi scalari integrati`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `impossibile rimuovere il tipo di operazione root di query, mutazione e sottoscrizione`,
  [`Cannot remove arguments of built-in directives`]: `Impossibile rimuovere gli argomenti delle direttive integrate`,
  [`Cannot remove non-null arguments of directives`]: `Impossibile rimuovere gli argomenti non null delle direttive`,
  [`Cannot remove input types of arguments of directives`]: `Impossibile rimuovere i tipi di input degli argomenti delle direttive`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Impossibile rimuovere i valori di enumerazione utilizzati come valori predefiniti degli argomenti delle direttive`,
  [`Cannot remove enum values used as default values of input fields`]: `Impossibile rimuovere i valori di enumerazione utilizzati come valori predefiniti dei campi di input`,
  [`Cannot remove all fields of an object type`]: `Impossibile rimuovere tutti i campi di un tipo di oggetto`,
  [`Cannot remove all fields of an interface type`]: `Impossibile rimuovere tutti i campi di un tipo di interfaccia`,
  [`Cannot remove all slicing arguments`]: `Impossibile rimuovere tutti gli argomenti di suddivisione`,
  [`Cannot remove all input fields of an input object type`]: `Impossibile rimuovere tutti i campi di input di un tipo di oggetto di input`,
  [`Cannot remove all values of enum type`]: `Impossibile rimuovere tutti i valori di tipo enum`,
  [`Cannot remove fields of interfaces`]: `Impossibile rimuovere i campi delle interfacce`,
  [`Cannot remove non-null input fields`]: `Impossibile rimuovere i campi di input non null`,
  [`Case`]: `Caso`,
  [`Case sensitive`]: `Sensibile al maiuscolo/minuscolo`,
  [`Catalog`]: `Catalogo`,
  [`Catalog defaults`]: `Valori predefiniti del catalogo`,
  [`Catalog Invite`]: `Invito al catalogo`,
  [`Catalog invite`]: `Invito al catalogo`,
  [`Catalog invites`]: `Inviti al catalogo`,
  [`Catalog name`]: `Nome catalogo`,
  [`Catalog owner`]: `Proprietario catalogo`,
  [`Catalog owner invitation`]: `Invito proprietario del catalogo`,
  [`Catalog properties`]: `Proprietà del catalogo`,
  [`Catalog summary`]: `Riepilogo del catalogo`,
  [`Catalog title`]: `Titolo del catalogo`,
  [`Catalog user registries`]: `Registri utente del catalogo`,
  [`Catalog by Name`]: `Catalogo in base al nome`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Priorità del catalogo (utilizzare gli endpoint di reindirizzamento a microsito definiti dall'amministratore del catalogo)`,
  [`Catalog {name} created`]: `Catalogo {name} creato`,
  [`Catalog selection`]: `Selezione catalogo`,
  [`Catalog settings`]: `Impostazioni catalogo`,
  [`Catalog settings have been updated`]: `Le impostazioni del catalogo sono state aggiornate`,
  [`Catalog User`]: `Utente del catalogo`,
  [`Catalog Users`]: `Utenti del catalogo`,
  [`Catalog user registry`]: `Registro utente catalogo`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `Il catalogo con LifeCycle abilitato non può essere utilizzato nelle Preferenze del Test.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Il catalogo con ciclo di vita e modalità di produzione abilitati non può essere utilizzato nelle preferenze di verifica.`,
  [`Catalogs`]: `Cataloghi`,
  [`Catch`]: `Cattura`,
  [`Catches`]: `Catture`,
  [`Categories`]: `Categorie`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Le categorie stabiliscono una visualizzazione gerarchica dei prodotti API in Developer Portal. Utilizzare la seguente sintassi: elemento_livello_superiore/elemento_livello_successivo/elemento_livello_inferiore.`,
  [`Category`]: `Categoria`,
  [`Certifcate or Shared Secret for Verify`]: `Certificato o segreto condiviso per la verifica`,
  [`Certificate`]: `Certificato`,
  [`Certificate (Optional)`]: `Certificato (facoltativo)`,
  [`Certificate management`]: `Gestione certificato`,
  [`Certificate Manager instance`]: `Istanza Certificate Manager`,
  [`Certificate (optional)`]: `Certificato (facoltativo)`,
  [`Certificates`]: `Certificati`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `I certificati sono necessari per proteggere le connessioni tra il gateway ed i domini da esso gestiti. Tutti i certificati devono essere memorizzati in un servizio Certificate Manager. Il servizio fornisce un repository protetto per i certificati ed impedisce le interruzioni inviando notifiche in prossimità della scadenza dei certificati. Per iniziare,`,
  [`Certificates details in this keystore`]: `Dettagli dei certificati in questo keystore`,
  [`Certificates details in this truststore`]: `Dettagli dei certificati in questo truststore`,
  [`Change`]: `Modifica`,
  [`Change Consumer Organization owner to an existing user`]: `Modifica proprietario organizzazione consumer in un utente esistente`,
  [`Change Ownership to an Existing User`]: `Modifica proprietà ad un utente esistente`,
  [`Change portal service`]: `Modifica servizio portale`,
  [`Change owner to existing user`]: `Cambia proprietario con utente esistente`,
  [`Change owner to new user`]: `Cambia proprietario con nuovo utente`,
  [`Change ownership by inviting a new user`]: `Cambia proprietà invitando un nuovo utente`,
  [`Change ownership to`]: `Modifica proprietà in`,
  [`Change ownership to a new user via email invitation`]: `Cambia proprietà ad un nuovo utente tramite invito e-mail`,
  [`Change ownership to an existing user`]: `Cambia proprietà ad un utente esistente`,
  [`Change password`]: `Modifica password`,
  [`Change setup`]: `Imposta modifica`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Le modifiche apportate direttamente all'API o all'assemblaggio che sono soggette a un provider OAuth potrebbero rendere il provider OAuth non valido se non si aggiornano anche i valori corrispondenti nella definizione del provider OAuth stesso. Se si salvano queste modifiche, assicurarsi di apportare anche gli aggiornamenti corrispondenti nella definizione del provider OAuth.`,
  [`Change the following extra constraints in the schema:`]: `Modificare i seguenti vincoli supplementari nello schema:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `Modificando il Certificate Manager verranno rimossi tutte le assegnazioni di bundle CA e certificati esistenti.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `La modifica dello schema DNS modificherà il formato dei link URL per API Connect e Developer Portal. Sarà necessario comunicare i nuovi link agli utenti. Fare clic su Annulla se non si desidera continuare.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `La modifica del nome o della versione di un'API creerà una nuova API. Si è sicuri di voler salvare le modifiche?`,
  [`Check your email`]: `Controlla e-mail`,
  [`Child`]: `Child`,
  [`Chinese (Simplified)`]: `Cinese (Semplificato)`,
  [`Chinese (Traditional)`]: `Cinese (Tradizionale)`,
  [`Choose`]: `Scegli`,
  [`Choose a`]: `Scegli un`,
  [`Choose {docTypeLabel}`]: `Selezionare {docTypeLabel}`,
  [`Choose Catalog`]: `Scegli catalogo`,
  [`Choose a Catalog`]: `Scegli un catalogo`,
  [`Choose a Consumer Org`]: `Scegliere un'organizzazione consumer`,
  [`Choose Gateway`]: `Scegli gateway`,
  [`Choose a Gateway`]: `Scegli un gateway`,
  [`Choose Product`]: `Scegli prodotto`,
  [`Choose a Product`]: `Scegli un prodotto`,
  [`Choose Rate Limit`]: `Scegli limite di velocità`,
  [`Choose a Rate Limit`]: `Scegli un limite di velocità`,
  [`Choose Application`]: `Scegli applicazione`,
  [`Choose an Application`]: `Scegliere un'applicazione`,
  [`Choose a {docTypeLabel}`]: `Selezionare un {docTypeLabel}`,
  [`Choose a publish destination`]: `Selezionare una destinazione di pubblicazione`,
  [`Choose a billing integration`]: `Selezionare un'integrazione di fatturazione`,
  [`Choose a catalog to test within:`]: `Selezionare un catalogo in cui eseguire il test in:`,
  [`Choose a plan against which to test:`]: `Selezionare un piano rispetto al quale eseguire il test:`,
  [`Choose a product containing this API, or create a new one:`]: `Selezionare un prodotto contenente questa API, o crearne uno nuovo:`,
  [`Choose a user registry`]: `Selezionare un registro utente`,
  [`Choose an application with which to test, or create a new one:`]: `Selezionare un'applicazione con cui eseguire il test oppure crearne una nuova:`,
  [`Choose an existing application`]: `Selezionare un'applicazione esistente`,
  [`Choose an existing catalog`]: `Selezionare un catalogo esistente`,
  [`Choose an existing product published to your Sandbox catalog`]: `Selezionare un prodotto esistente pubblicato nel catalogo sandbox`,
  [`Choose an operation to invoke:`]: `Selezionare un'operazione da richiamare:`,
  [`Choose an option`]: `Selezionare un'opzione`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Selezionare un'opzione per determinare in che modo la politica di associazione gestisce l'output di un array vuoto. Scegliendo tutto (scelta predefinita) verranno prodotti tutti array vuoti ed array child vuoti. La scelta parent produrrà solo array parent vuoti. Scegliendo nessuno non verranno prodotti array vuoti.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `La scelta della modalità di blocco avrà effetti negativi sulle prestazioni del sistema`,
  [`Choose existing directory`]: `Seleziona la directory esistente`,
  [`Choose one...`]: `Scegli uno...`,
  [`Choose operations and paths to generate into this API`]: `Selezionare le operazioni ed i percorsi da generare in questa API`,
  [`Choose paths to generate into this API`]: `Selezionare i percorsi da generare in questa API`,
  [`Choose the DNS scheme`]: `Selezionare lo schema DNS`,
  [`Choose the permissions for the role.`]: `Selezionare le autorizzazioni per il ruolo.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Scegliere se utilizzare gli indirizzi DNS statici o dinamici per le chiamate API in entrata al servizio gateway e per l'accesso a Developer Portal.`,
  [`Cipher`]: `Crittografia`,
  [`Ciphers`]: `Crittografie`,
  [`Clear`]: `Ripulisci`,
  [`Clear constraints`]: `Annulla vincoli`,
  [`Clear file`]: `Cancella file`,
  [`Clear filters`]: `Cancella filtri`,
  [`Clear from selected`]: `Elimina dai selezionati`,
  [`Clear operation filter`]: `Cancella filtro operazione`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Fare clic su un certificato per visualizzare i dettagli riguardante la relativa organizzazione emittente e l'impronta digitale.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Fare clic sul link riportato di seguito per continuare ad autorizzare le API in un'altra scheda e si otterrà il codice di autorizzazione per il passo 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Facendo clic sul seguente link, il server verrà aperto in una nuova scheda. Se il browser visualizza un problema di certificato, è possibile scegliere di accettarlo e tornare in questo punto per verificare nuovamente.`,
  [`Click add to add a billing integration.`]: `Fare clic per aggiungere un'integrazione di fatturazione.`,
  [`Click Add to add a gateway extension.`]: `Fare clic su Aggiungi per aggiungere un'estensione del gateway.`,
  [`Click **Add domain** to add domains.`]: `Fare clic su **Aggiungi dominio** per aggiungere i domini.`,
  [`Click Add to add a new base endpoint.`]: `Fare clic su Aggiungi per aggiungere un nuovo endpoint di base.`,
  [`Click Add to add a new HTTP header.`]: `Fare clic su Aggiungi per aggiungere una nuova intestazione HTTP.`,
  [`Click Add to add a new member.`]: `Fare clic su Aggiungi per aggiungere un nuovo membro.`,
  [`Click Add to add a new role.`]: `Fare clic su Aggiungi per aggiungere un nuovo ruolo.`,
  [`Click Add to add a new scope.`]: `Fare clic su Aggiungi per aggiungere un nuovo ambito.`,
  [`Click Add to add a new consumer organization group.`]: `Fare clic su Aggiungi per aggiungere un nuovo gruppo di organizzazioni consumer.`,
  [`Click Add to create the API Key.`]: `Fare clic su Aggiungi per creare la chiave API.`,
  [`Click Create to add the API Key.`]: `Fare clic su Crea per aggiungere la chiave API.`,
  [`Click Create to add a new user registry.`]: `Fare clic su Crea per aggiungere un nuovo registro utente.`,
  [`Click Create user registry to add a new user registry.`]: `Fare clic su Crea registro utente per aggiungere un nuovo registro utente.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Fare clic su **Crea organizzazione provider** per aggiungere le organizzazioni provider.`,
  [`Click Edit to add OAuth providers.`]: `Fare clic su Modifica per aggiungere provider OAuth.`,
  [`Click Edit to add a user registry.`]: `Fare clic su Modifica per aggiungere un registro utente.`,
  [`Click Edit to add an API to the product.`]: `Fare clic su Modifica per aggiungere un'API al prodotto.`,
  [`Click Create to add a property.`]: `Fare clic su Crea per aggiungere una proprietà.`,
  [`Click Edit to add TLS client profiles.`]: `Fare clic su Modifica per aggiungere profili client TLS.`,
  [`Click Edit to add user registries.`]: `Fai clic su Modifica per aggiungere i registri utente.`,
  [`Click Add to add a new consumer organization.`]: `Fare clic su Aggiungi per aggiungere una nuova Organizzazione consumer.`,
  [`Click Consumer organizations to add one.`]: `Fare clic su Organizzazioni consumer per aggiungerne una.`,
  [`Click Add to add a new application.`]: `Fare clic su Aggiungi per aggiungere una nuova applicazione.`,
  [`Click Add to add parameters.`]: `Fare clic su Aggiungi per aggiungere i parametri.`,
  [`Click Add to add response.`]: `Fare clic su Aggiungi per aggiungere la risposta.`,
  [`Click Create to add a new TLS client profile.`]: `Fare clic su Crea per aggiungere un nuovo Profilo client TLS.`,
  [`Click Create to add a new keystore.`]: `Fare clic su Crea per aggiungere un nuovo Keystore.`,
  [`Click Create to add a new truststore.`]: `Fare clic su Crea per aggiungere un nuovo Truststore.`,
  [`Click **Register remote gateway** to add gateways.`]: `Fare clic su **Registra gateway remoto** per aggiungere i gateway.`,
  [`Click Register gateways to add new Gateway Services.`]: `Fare clic su Registra gateway per aggiungere nuovi servizi gateway.`,
  [`Click Edit to add Gateway Services.`]: `Fare clic su Modifica per aggiungere Servizi gateway.`,
  [`Click Register gateways to add new gateway services.`]: `Fare clic su Registra gateway per aggiungere nuovi servizi gateway.`,
  [`Click Edit to add gateway services.`]: `Fare clic su Modifica per aggiungere Servizi gateway.`,
  [`Click Edit to add an email server.`]: `Fare clic su Modifica per aggiungere un Server e-mail.`,
  [`Click Create to add a new TLS server profile.`]: `Fare clic su Crea per aggiungere un nuovo Profilo server TLS.`,
  [`Click Create to add a new email server.`]: `Fare clic su Crea per aggiungere un nuovo Server e-mail.`,
  [`Click Add to add a new schema definition.`]: `Fare clic su Aggiungi per aggiungere una nuova definizione schema.`,
  [`Click Add to add a new property for this definition.`]: `Fare clic su Aggiungi per aggiungere una nuova proprietà per questa definizione.`,
  [`Click Add to add a new parameter for this API.`]: `Fare clic su Aggiungi per aggiungere un nuovo parametro per questa API.`,
  [`Click Add to add tags and external documentation for this API.`]: `Fare clic su Aggiungi per aggiungere tag e documentazione esterna per questa API.`,
  [`Click Add to add a new path.`]: `Fare clic su Aggiungi per aggiungere un nuovo percorso.`,
  [`Click Add to add a property for this API.`]: `Fare clic su Aggiungi per aggiungere una proprietà per questa API.`,
  [`Click here to create a security definition`]: `Fare clic qui per creare una definizione di sicurezza.`,
  [`Click Add to add a new security definition.`]: `Fare clic su Aggiungi per aggiungere una nuova definizione di sicurezza.`,
  [`Click Add to add a target service for this API.`]: `Fare clic su Aggiungi per aggiungere un servizio di destinazione per questa API.`,
  [`Click Add to add an operation for this path.`]: `Fare clic su Aggiungi per aggiungere un'operazione per questo percorso.`,
  [`Click Add to add a path parameter for this path.`]: `Fare clic su Aggiungi per aggiungere un parametro percorso per questo percorso.`,
  [`Click Add to add a new OAuth provider.`]: `Fare clic su Aggiungi per aggiungere un nuovo provider OAuth.`,
  [`Click Add to add a new provider organization.`]: `Fare clic su Aggiungi per aggiungere una nuova Organizzazione provider.`,
  [`Click Add to add a new API or product.`]: `Fare clic su Aggiungi per aggiungere un nuovo prodotto o API.`,
  [`Click Add to add a new API.`]: `Fare clic su Aggiungi per aggiungere una nuova API.`,
  [`Click Add to add a new Extension.`]: `Fare clic su Aggiungi per aggiungere una nuova estensione.`,
  [`Click Add to add new {resource}.`]: `Fare clic su Aggiungi per aggiungere nuovo {resource}.`,
  [`Click to expand`]: `Fare clic per espandere`,
  [`Click to collapse`]: `Fare clic per comprimere`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Fare clic per informazioni sul caricamento e sulla gestione dei certificati richiesti dal gateway.`,
  [`Client Credentials`]: `Credenziali client`,
  [`Client information`]: `Informazioni sul client`,
  [`Client revocation path`]: `Percorso di revoca client`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Fare clic su Registra Servizio per registrare un servizio. Ciò consente di eseguire il test degli endpoint durante la configurazione di altri servizi.`,
  [`Client secret`]: `Segreto client`,
  [`Client security`]: `Sicurezza client`,
  [`Client Type`]: `Tipo di client`,
  [`Client authentication method`]: `Metodo di autenticazione client`,
  [`Clone`]: `Clona`,
  [`Close`]: `Chiudi`,
  [`Cloud`]: `Cloud`,
  [`Cloud Admin`]: `Admin Cloud`,
  [`Cloud Administrator`]: `Amministratore cloud`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Amministratore cloud. Gestore organizzazione, Amministratore topologia. Il ruolo Membro viene assegnato automaticamente a tutti gli utenti.`,
  [`Cloud administration`]: `Amministrazione cloud`,
  [`Cloud Discovery Agent`]: `Agent di rilevamento cloud`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Registro utente locale Cloud Manager`,
  [`Cloud-Settings`]: `Impostazioni-Cloud`,
  [`Cloud settings invitation timeout`]: `Timeout invito impostazioni cloud`,
  [`Cloud settings invitation timeout has been changed`]: `Il timeout di invito delle impostazioni cloud è stato modificato`,
  [`Code editor`]: `Editor di codice`,
  [`Collect Metadata`]: `Raccogli metadati`,
  [`Collect credentials using`]: `Raccolta delle credenziali`,
  [`Collect metadata`]: `Raccogli metadati`,
  [`Combo box`]: `Casella combinata`,
  [`Community Manager`]: `Gestore comunità`,
  [`community-manager`]: `gestore-community`,
  [`Compatibility`]: `Compatibilità`,
  [`Complex Definitions must be edited in the editor`]: `Le definizioni complesse devono essere modificate nell'editor`,
  [`Compose DN`]: `Componi DN`,
  [`Compose UPN`]: `Componi UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `Comporre un nuovo proxy REST definendo percorsi e operazioni`,
  [`Compose a new product by adding rate limits and plans`]: `Comporre un nuovo prodotto aggiungendo i limiti di frequenza e i piani`,
  [`Compression`]: `Compressione`,
  [`Condition`]: `Condizione`,
  [`Condition editor`]: `Editor delle condizioni`,
  [`Confidential`]: `Riservato`,
  [`Configuration`]: `Configurazione`,
  [`Configurations`]: `Configurazioni`,
  [`Configure API endpoint`]: `Configura endpoint API`,
  [`Configure API Gateway Service`]: `Configura servizio API Gateway`,
  [`Configure analytics service`]: `Configura servizio di analytics`,
  [`Configure availability zone`]: `Configura zona di disponibilità`,
  [`Configure catalog user registries`]: `Configura registri utente catalogo`,
  [`Configure cloud`]: `Configura cloud`,
  [`Configure DataPower API gateway service`]: `Configura servizio DataPower API Gateway`,
  [`Configure DataPower gateway service`]: `Configura servizio DataPower Gateway`,
  [`Configure Email Server`]: `Configura server e-mail`,
  [`Configure gateway extension`]: `Configura estensione del gateway`,
  [`Configure portal service`]: `Configura servizio portale`,
  [`Configure service`]: `Configura servizio`,
  [`Configure topology`]: `Configura topologia`,
  [`Configure XML`]: `Configura XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Consente di configurare un servizio DataPower API Gateway per proteggere e applicare le API.`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Configurare un servizio DataPower Gateway per proteggere e applicare le API`,
  [`Configure a developer portal service for API consumers`]: `Configura un servizio Developer Portal per i consumer API`,
  [`Configure advanced features for OIDC settings`]: `Configurare funzioni avanzate per le impostazioni OIDC`,
  [`Configure an analytics service to collect API call data`]: `Configura un servizio analisi per raccogliere i dati della chiamata API`,
  [`Configure an email server to send invitations and notifications to users.`]: `Configura un server e-mail per inviare inviti e notifiche agli utenti.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Configurare la verifica per monitorare le operazioni di: IU di amministrazione, IU del gestore, toolkit, API REST di gestione ed utenti del portale. I log di ciascuna operazione utente per la creazione, l'aggiornamento e l'eliminazione vengono inviati ai servizi di registrazione remota specificati di seguito.`,
  [`Configure availability zones and services`]: `Configura servizi e zone di disponibilità`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Configurare la modalità con cui gli URL endpoint dell'API sono composti nelle API pubblicate`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Configurare la modalità con cui gli endpoint di reindirizzamento a microsito vengono visualizzati in Developer Portal`,
  [`Configure mapping`]: `Configura associazione`,
  [`Configure properties of the activity log`]: `Configurare le proprietà del log delle attività`,
  [`Configure settings for token management and revocation.`]: `Configurare le impostazioni per la gestione e revoca del token`,
  [`Configure settings to generate and validate tokens.`]: `Configura le impostazioni per la generazione e la convalida dei token.`,
  [`Configure the API security`]: `Configurare la sicurezza API`,
  [`Configure the default set of gateway services configured for each catalog`]: `Configura l'insieme predefinito dei servizi gateway configurati per ciascun catalogo`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Configurare il developer portal utilizzato dagli sviluppatori delle applicazioni per accedere alle API in questo catalogo`,
  [`Configure the gateway services used by default in each catalog`]: `Configura i servizi gateway utilizzati per impostazione predefinita in ciascun catalogo`,
  [`Configure the gateway extension for the selected gateway`]: `Configura l'estensione del gateway per il gateway selezionato`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Configurare DataPower API Gateway gestito da IBM e gestire i gateway remoti personali. [Ulteriori informazioni]({link})`,
  [`Configure the keystore and upload certificates`]: `Configura il keystore e carica i certificati`,
  [`Configure the name and email address to be used in the from field of emails`]: `Configura il nome e l'indirizzo e-mail da utilizzare nel campo Da delle e-mail`,
  [`Configure the portal service for the catalog`]: `Configurare il servizio portale per il catalogo`,
  [`Configure the role and assign permissions`]: `Configura il ruolo ed assegna le autorizzazioni`,
  [`Configure the security of this API`]: `Configurare la sicurezza di questa API`,
  [`Configure the sender name and address to use for email notifications`]: `Configura il nome e l'indirizzo del mittente da utilizzare per le notifiche e-mail`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Configurare il nome mittente, l'indirizzo e il server di e-mail utilizzato per inviare gli inviti e le notifiche agli utenti`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Configura l'insieme dei ruoli da utilizzare per impostazione predefinita quando viene creata un'organizzazione consumer`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Configura l'insieme dei ruoli da utilizzare per impostazione predefinita quando viene creata un'organizzazione provider`,
  [`Configure the templates used to invite and notify users`]: `Configura i template utilizzati per invitare ed inviare notifiche agli utenti`,
  [`Configure the truststore and upload certificates`]: `Configura il truststore e carica i certificati`,
  [`Configure user authentication using`]: `Configura autenticazione utente utilizzando`,
  [`Configure user authentication using JSON Web Tokens`]: `Configurare l'autenticazione utente utilizzando JSON Web Token`,
  [`Configure user authentication using a LDAP provider`]: `Configurare l'autenticazione utente utilizzando il provider LDAP`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Configurare l'autenticazione utente utilizzando un registro utente locale API Connect`,
  [`Configure user authentication using an authentication URL`]: `Configurare l'autenticazione utente utilizzando l'URL di autenticazione`,
  [`Configure user authentication using this user registry type`]: `Configurare l'autenticazione utente utilizzando questo tipo di registro utente.`,
  [`Configure user registries, OAuth providers and TLS`]: `Consente di configurare i registri utente, i provider OAuth e TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Configurare registri utente, TLS, provider OAuth e server e-mail`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Configura il keystore per token di accesso, ID token e token temporaneo`,
  [`Configured OAuth Provider`]: `Provider OAuth configurato`,
  [`Configured OAuth Providers`]: `Provider OAuth configurati`,
  [`Configured OAuth provider`]: `Provider OAuth configurato`,
  [`Configured OAuth provider list has been updated`]: `L'elenco dei provider OAuth configurati è stato aggiornato`,
  [`Configured OAuth provider list has not been updated`]: `L'elenco di provider OAuth configurati non è stato aggiornato`,
  [`Confirm`]: `Conferma`,
  [`Confirm deletion`]: `Conferma eliminazione`,
  [`Confirm Deletion of Portal`]: `Conferma eliminazione del portale`,
  [`Confirm visibility settings`]: `Conferma le impostazioni di visibilità`,
  [`Confirm new password`]: `Conferma nuova password`,
  [`Confirm password`]: `Conferma password`,
  [`Confirm password should match password`]: `La password di conferma deve corrispondere alla password`,
  [`Confirmation`]: `Conferma`,
  [`Congratulations, you are now registered.`]: `Congratulazioni, la registrazione è stata eseguita.`,
  [`Congratulations, your password has been reset!`]: `Congratulazioni, la password è stata reimpostata!`,
  [`Connect`]: `Connetti`,
  [`Connect to API Connect`]: `Connettiti a API Connect`,
  [`Connected to API Manager Product version`]: `Connesso alla versione del prodotto API Manager`,
  [`Connect to Cloud`]: `Connetti a Cloud`,
  [`Connected`]: `Connesso`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Creare la definizione dello schema aggiungendo proprietà del tipo richiesto. È possibile nidificare le proprietà`,
  [`Consider for nesting`]: `Considera per la nidificazione`,
  [`Consumed Media Types`]: `Tipi di supporto utilizzati`,
  [`Consumer`]: `Consumer`,
  [`Consumer invitation and roles`]: `Ruoli e inviti ai consumer`,
  [`Consumer onboarding`]: `Procedura di avvio del consumer`,
  [`Consumer organizations`]: `Organizzazione consumer`,
  [`Consumer organizations or groups`]: `Organizzazioni consumer o gruppi`,
  [`Consumer organization {name} created`]: `Organizzazione consumer {name} creata`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Le organizzazioni consumer vengono create dagli sviluppatori delle applicazioni in Developer Portal ed è inoltre possibile creare organizzazioni consumer utilizzando la UI di API Manager`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Le organizzazioni consumer vengono create dagli sviluppatori delle applicazioni in developer portal ed è inoltre possibile creare organizzazioni consumer in questo punto`,
  [`Consumer-Org`]: `Org-Consumer`,
  [`Consumers`]: `Consumer`,
  [`Consumes`]: `Utilizza`,
  [`Contact`]: `Contatti`,
  [`Contact information for the owners of the API.`]: `Informazioni di contatto per i proprietari dell'API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Rivolgersi all'amministratore per informazioni sulla configurazione del servizio di analytics in modo da visualizzare i propri dati in questo punto.`,
  [`Contact your administrator for more information.`]: `Rivolgersi all'amministratore per ulteriori informazioni.`,
  [`Content`]: `Contenuto`,
  [`Content on error`]: `Contenuto in caso di errore`,
  [`Content type`]: `Tipo di contenuto`,
  [`Context Variable`]: `Variabile di contesto`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `La variabile di runtime o di contesto che contiene il JWT da convalidare. Se non è impostata, la politica ricerca JWT in request.headers.authorization.`,
  [`Context variable`]: `Variabile di contesto`,
  [`Continue`]: `Continua`,
  [`Continue on error`]: `Continua in caso di errore`,
  [`Continue with`]: `Continua con`,
  [`Continue with:`]: `Continua con:`,
  [`Conversion type`]: `Tipo di conversione`,
  [`Convert to expression`]: `Converti in espressione`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `Copiato`,
  [`Copied to clipboard`]: `Copiato negli appunti`,
  [`Copy`]: `Copia`,
  [`Copy Id Headers To Message`]: `Copia intestazioni ID in messaggio`,
  [`Copy to clipboard`]: `Copia negli appunti`,
  [`Copy file path to clipboard`]: `Copia percorso file negli appunti`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Copia il costo stimato per la query GraphQL nell'output`,
  [`Copy the endpoints below to`]: `Copiare gli endpoint di seguito per`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Copiare la chiave API da utilizzare. È possibile visualizzare nuovamente questa chiave API con l'icona Visualizza.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Informazioni sul copyright`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Correggere gli errori nell'editor sopra indicato oppure caricare nuovamente un file valido`,
  [`Cost`]: `Costo`,
  [`Count`]: `Conteggio`,
  [`Count Limit Name`]: `Nome limite conteggio`,
  [`Count Limits`]: `Limiti di conteggio`,
  [`Created at`]: `Ora di creazione`,
  [`Counter`]: `Contatore`,
  [`Create`]: `Crea`,
  [`Create a [Certificate Manager]({link}) service`]: `Creare un servizio [Certificate Manager]({link})`,
  [`Create API`]: `Crea API`,
  [`Create an API from the Develop section.`]: `Creare un'API dalla sezione Sviluppa.`,
  [`Create API Connect API Key`]: `Crea chiave API di API Connect`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Crea API dal servizio GraphQL esistente (proxy GraphQL)`,
  [`Create API from existing OpenAPI service`]: `Crea API dal servizio OpenAPI esistente`,
  [`Create API from existing WSDL service (REST proxy)`]: `Crea API dal servizio WSDL esistente (Proxy REST)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Crea API dal servizio WSDL esistente (proxy SOAP)`,
  [`Create API from target service`]: `Crea API dal servizio di destinazione`,
  [`Create API from existing REST service`]: `Crea API da servizio REST esistente`,
  [`Create API from existing SOAP service`]: `Crea API da servizio SOAP esistente`,
  [`Create API from existing WSDL service`]: `Crea API da servizio WSDL esistente`,
  [`Create API Key failed`]: `Creazione chiave API non riuscita`,
  [`Create application`]: `Crea applicazione`,
  [`Create authentication URL user registry`]: `Crea registro utente URL di autenticazione`,
  [`Create availability zone`]: `Crea zona di disponibilità`,
  [`Create Billing`]: `Crea fatturazione`,
  [`Create catalog`]: `Crea catalogo`,
  [`Create catalog property`]: `Crea proprietà catalogo`,
  [`Create consumer organization`]: `Crea organizzazione consumer`,
  [`Create Credentials`]: `Crea credenziali`,
  [`Create definition`]: `Crea definizione`,
  [`Create email server`]: `Crea server e-mail`,
  [`Create IBM Developer Portal`]: `Crea IBM Developer Portal`,
  [`Create keystore`]: `Crea keystore`,
  [`Create LDAP user registry`]: `Crea registro utente LDAP`,
  [`Create LTPA Key`]: `Crea chiave LTPA`,
  [`Create local user registry`]: `Crea registro utente locale`,
  [`Create native OAuth provider`]: `Crea provider OAuth nativo`,
  [`Create New GraphQL API`]: `Crea nuova API GraphQL`,
  [`Create new OpenAPI`]: `Crea nuova OpenAPI`,
  [`Create new product`]: `Crea nuovo prodotto`,
  [`Create OIDC user registry`]: `Crea registro utente OIDC`,
  [`Create OpenAPI definition and product definition`]: `Creare una definizione OpenAPI e una definizione prodotto`,
  [`Create operation`]: `Crea operazione`,
  [`Create organization`]: `Crea organizzazione`,
  [`Create path`]: `Crea percorso`,
  [`Create plan`]: `Crea piano`,
  [`Create portal`]: `Crea portale`,
  [`Create Product`]: `Crea prodotto`,
  [`Create property`]: `Crea proprietà`,
  [`Create role`]: `Crea ruolo`,
  [`Create role default for the Organization`]: `Crea valori predefiniti del ruolo per l'organizzazione`,
  [`Create sample user registry`]: `Crea registro utente di esempio`,
  [`Create security definition`]: `Crea la definizione di sicurezza`,
  [`Create space`]: `Crea spazio`,
  [`Create subscription`]: `Crea sottoscrizione`,
  [`Create TLS client profile`]: `Crea profilo client TLS`,
  [`Create TLS server profile`]: `Crea profilo server TLS`,
  [`Create target service`]: `Crea servizio di destinazione`,
  [`Create third party OAuth provider`]: `Crea provider OAuth di terze parti`,
  [`Create truststore`]: `Crea truststore`,
  [`Create user registry`]: `Crea registro utente`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Creare un proxy GraphQL basato su un servizio GraphQL`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Creare un proxy REST basato su un servizio di destinazione OpenAPI descritto`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Creare un proxy REST basato su un servizio di destinazione WSDL descritto`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Creare un proxy REST basato su un servizio di destinazione OpenAPI descritto`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Creare un proxy REST basato sul servizio di destinazione WSDL descritto`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Creare un proxy REST che instradi tutto il traffico all'endpoint del servizio o API di destinazione`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Creare un proxy REST basato su un servizio di destinazione WSDL descritto`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Creare un proxy SOAP basato sul servizio di destinazione WSDL descritto`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Crea un nuovo oggetto utilizzando il template delle barre di controllo. Le variabili provengono dal contesto.`,
  [`Create a portal site for the catalog`]: `Crea un sito del portale per il catalogo`,
  [`Create a product by importing a product definition`]: `Crea un prodotto importando una definizione di prodotto`,
  [`Create a security definition`]: `Creare una definizione di sicurezza`,
  [`Create a subscription`]: `Crea una sottoscrizione`,
  [`Create an API by importing an OpenAPI definition`]: `Crea un'API importando una definizione OpenAPI`,
  [`Create an API that calls an existing SOAP service`]: `Crea un'API che richiama un servizio SOAP esistente`,
  [`Create and manage API provider organizations and owners`]: `Creare e gestire organizzazioni provider API e proprietari`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Creare e gestire gli spazi nel catalogo; gli spazi consentono di suddividere il catalogo in partizioni per supportare diversi team di sviluppo del provider API`,
  [`Create and publish`]: `Crea e pubblica`,
  [`Create and publish APIs and Products`]: `Creare e pubblicare API e prodotti`,
  [`Create and subscribe`]: `Crea e sottoscrivi`,
  [`Create assembly`]: `Crea assemblaggio`,
  [`Create empty parent object for failed mapping`]: `Crea oggetti parent vuoti per associazione non riuscita`,
  [`Create endpoint`]: `Crea endpoint`,
  [`Create from`]: `Crea da`,
  [`Create from Existing WSDL service`]: `Crea da servizio WSDL esistente`,
  [`Create from File or URL`]: `Crea da file o URL`,
  [`Create from existing openAPI service`]: `Crea da servizio openAPI esistente`,
  [`Create gateway extension`]: `Crea estensione del gateway`,
  [`Create new user`]: `Crea nuovo utente`,
  [`Create new version`]: `Crea nuova versione`,
  [`Create product using a template`]: `Crea prodotto utilizzando un template`,
  [`Create provider organization`]: `Crea organizzazione provider`,
  [`Create required child properties for array objects and mapped optional objects`]: `Crea le proprietà child obbligatorie per gli oggetti array e per gli oggetti facoltativi associati`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Creato dalla configurazione del Provider OAuth come esempio. Assicurarsi di aggiornare i provider OAuth utilizzando questo esempio con un registro utente valido.`,
  [`Created new product`]: `Nuovo prodotto creato`,
  [`Create|permission`]: `Crea`,
  [`Create|title`]: `Crea`,
  [`Creating Draft Product`]: `Creazione prodotto bozza`,
  [`Creating a Consumer organization`]: `Creazione di un'organizzazione consumer`,
  [`Creating a consumer organization`]: `Creazione di un'organizzazione consumer`,
  [`Creating a new Product`]: `Creazione di un nuovo prodotto`,
  [`Creative Commons (CC-BY-4.0) license`]: `Licenza Creative Commons (CC-BY-4.0)`,
  [`Credential Extraction Method`]: `Metodo di estrazione credenziale`,
  [`Credentials for the Sandbox catalog`]: `Credenziali per il catalogo Sandbox`,
  [`Credentials for the {catalogName} catalog`]: `Credenziali per il catalogo {catalogName}`,
  [`Credential Name`]: `Nome credenziale`,
  [`Credential has been added.`]: `Le credenziali sono state aggiunte.`,
  [`Credential`]: `Credenziali`,
  [`Credentials`]: `Credenziali`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Le credenziali sono necessarie per eseguire API Designer e Toolkit.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Le credenziali sono necessarie per eseguire il toolkit. Posizionare i file delle credenziali nella cartella in cui è installato il toolkit.`,
  [`Credit Card`]: `Carta di credito`,
  [`Cryptographic Algorithm`]: `Algoritmo crittografico`,
  [`Currency`]: `Valuta`,
  [`Current password`]: `Password corrente`,
  [`Current password is invalid.`]: `La password corrente non è valida.`,
  [`Custom`]: `Personalizza`,
  [`Custom API URL`]: `URL API personalizzata`,
  [`Custom expression (optional, one per line)`]: `Espressione personalizzata (facoltativa una per riga)`,
  [`Custom expression to remove (optional, one per line)`]: `Espressione personalizzata da rimuovere (facoltativo, una per riga)`,
  [`Custom HTML form`]: `Modulo HTML personalizzato`,
  [`Custom policies`]: `Politiche personalizzate`,
  [`Custom form TLS profile`]: `Profilo TLS modulo personalizzato`,
  [`Custom form content security policy`]: `Politica di sicurezza contenuto del modulo personalizzato`,
  [`Custom form CSP header value`]: `Valore di intestazione CSP modulo personalizzato`,
  [`Custom form endpoint`]: `Endpoint modulo personalizzato`,
  [`Custom header pattern`]: `Pattern intestazione personalizzata`,
  [`Custom subscribability is required if custom visibility is selected`]: `La sottoscrivibilità personalizzata è obbligatoria se è selezionata la visibilità personalizzata`,
  [`Customize`]: `Personalizza`,
  [`Customize email sender information`]: `Personalizza informazioni mittente email`,
  [`Customize notification templates`]: `Personalizza i template di notifica`,
  [`Customize the plan API list`]: `Personalizzare l'elenco di API del piano`,
  [`Czech`]: `Ceco`,
  [`DATE MODIFIED`]: `DATE MODIFICATA`,
  [`DELETE`]: `ELIMINA`,
  [`Date created`]: `Data di creazione`,
  [`Description`]: `Descrizione`,
  [`DETAIL`]: `DETTAGLI`,
  [`DN Base`]: `Base DN`,
  [`DNS Schemes`]: `Schemi DNS`,
  [`Dashboard`]: `Dashboard`,
  [`Data encoded form body`]: `Corpo del formato codificato dei dati`,
  [`Data flow in API Connect`]: `Flusso di dati in API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (compatibile con v5)`,
  [`DataPower API`]: `API DataPower`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway (compatibile con v5)`,
  [`DataPower Gateway policies`]: `Politiche DataPower Gateway`,
  [`Debug`]: `Debug`,
  [`Debug rule`]: `Regola di debug`,
  [`Debug XACML policy`]: `Politica XACML con debug`,
  [`Dec`]: `Dec`,
  [`Decline`]: `Rifiuta`,
  [`Decline and send`]: `Rifiuta ed invia`,
  [`Decode Request Params`]: `Parametri richiesta Decode`,
  [`Decrement`]: `Diminuisci`,
  [`Decrypt`]: `Decodifica`,
  [`Decrypt Crypto JWK variable name`]: `Nome variabile Decodifica JWK codificato`,
  [`Decrypt Crypto Object`]: `Oggetto crittografico di decodifica`,
  [`Decrypt a buffer using the specified certificate`]: `Decodifica un buffer utilizzando il certificato specificato`,
  [`Default`]: `Predefinito`,
  [`Default behavior`]: `Comportamento predefinito`,
  [`Default gateways`]: `Gateway predefiniti`,
  [`default gateway services configured`]: `servizi gateway predefiniti configurati`,
  [`Default Availability Zone`]: `Zona di disponibilità predefinita`,
  [`Default Base endpoints`]: `Endpoint di base predefiniti`,
  [`Default availability zone`]: `Zona di disponibilità predefinita`,
  [`Default base endpoints`]: `Endpoint di base predefiniti`,
  [`Default HTML form`]: `Modulo HTML predefinito`,
  [`Default limit`]: `Limite predefinito`,
  [`Default OAuth Provider Settings`]: `Impostazioni provider OAuth predefinite`,
  [`Default OAuth Provider Settings Object`]: `Oggetto impostazioni provider OAuth predefinito`,
  [`Default Plan`]: `Piano predefinito`,
  [`Default TLS client profile`]: `Profilo del client TLS predefinito`,
  [`Default TLS server keystore`]: `Keystore del server TLS predefinito`,
  [`Default TLS server profile`]: `Profilo del server TLS predefinito`,
  [`Default burst-limit`]: `Limite burst predefinito`,
  [`Default form`]: `Modulo predefinito`,
  [`Default keystore for access_token signing key`]: `Keystore predefinito per la chiave di firma access_token`,
  [`Default keystore for id_token signing key`]: `Keystore predefinito per la chiave di firma id_token`,
  [`Default keystore for temporary token signing key`]: `Keystore predefinito per la chiave di firma del token temporaneo`,
  [`Default rate-limit`]: `Limite di frequenza predefinito`,
  [`Default scopes`]: `Ambiti predefiniti`,
  [`Default table entry`]: `Voce della tabella predefinita`,
  [`Default validator endpoint`]: `Endpoint programma di convalida predefinito`,
  [`Default validator endpoint set by OAuth provider`]: `Endpoint del programma di convalida predefinito impostato dal provider OAuth`,
  [`Default value - no extra constraints apply.`]: `Valore predefinito - non vengono applicati vincoli supplementari.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Definisce un provider OAuth di terze parti per immettere e convalidare i token per la protezione dell'accesso all'API.`,
  [`Define catalog specific values for this property`]: `Definisci valori specifici del catalogo per questa proprietà`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Definire i gruppi che gestiscono API, prodotti, cataloghi, applicazioni ed impostazioni correlate.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Definire delle serie di politiche da eseguire per delle condizioni specifiche. È possibile definire diversi casi, e ciascun caso potrebbe essere associato ad una condizione arbitraria. Solo il primo caso corrispondente sarà eseguito.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Definire l'origine di protezione API. Almeno un'opzione deve essere abilitata.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `Il server di autorizzazione OIDC reindirizzerà il codice di autorizzazione ai seguenti endpoint. Questo endpoint di reindirizzamento è obbligatorio quando un cliente registra l'applicazione sul provider OIDC. Ad esempio, se questo registro utente viene utilizzato da un'organizzazione provider, il cliente deve registrare l'endpoint di reindirizzamento dell'organizzazione provider con il provider OIDC.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Definire gli input da utilizzare nell'associazione. È anche possibile assegnare un titolo ed una descrizione all'associazione.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Definire gli endpoint del provider OpenID per inviare una richiesta di autenticazione al provider OpenID`,
  [`Define the outputs to be used in the map.`]: `Definire gli output da utilizzare nell'associazione.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Definire il numero di giorni della prova gratuita ed il prezzo del piano. Gli utenti riceveranno una fattura basata sulla data di sottoscrizione.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Definisce se il flusso continua quando viene generato un errore durante l'esecuzione della politica. Se non viene selezionato, viene attivato un flusso di cattura.`,
  [`Definition`]: `Definizione`,
  [`Definition Object`]: `Oggetto di definizione`,
  [`Definitions`]: `Definizioni`,
  [`Delete`]: `Elimina`,
  [`Delete Application`]: `Elimina applicazione`,
  [`Delete Cloud Connection.`]: `Elimina connessione cloud.`,
  [`Delete Selected APIs`]: `Elimina API selezionate`,
  [`Delete selected client profiles`]: `Elimina profili client selezionati`,
  [`Delete selected keystores`]: `Elimina Keystore selezionati`,
  [`Delete selected mail servers`]: `Elimina server di posta selezionati`,
  [`Delete Selected Products`]: `Elimina prodotti selezionati`,
  [`Delete selected truststores`]: `Elimina truststore selezionati`,
  [`Delete selected user registries`]: `Elimina registri utente selezionati`,
  [`Delete Staged Product`]: `Elimina prodotto sottoposto a staging`,
  [`Delete a space`]: `Elimina uno spazio`,
  [`Delete a catalog`]: `Elimina un catalogo`,
  [`Delete API Key`]: `Elimina chiave API`,
  [`Delete API Key failed`]: `Eliminazione chiave API non riuscita`,
  [`Delete availability zone`]: `Elimina zona di disponibilità`,
  [`Delete selected requests`]: `Elimina richieste selezionate`,
  [`Delete row`]: `Elimina riga`,
  [`Delete|title`]: `Elimina`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `L'eliminazione di un catalogo può avere esito negativo se si dispone di un numero elevato di organizzazioni consumer, applicazioni, prodotti pubblicati e altre risorse.  Considerare l'eliminazione di tali risorse prima di eliminare il catalogo.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `L'eliminazione di un'organizzazione provider può avere esito negativo se si dispone di un numero elevato di cataloghi, organizzazioni consumer, applicazioni, prodotti pubblicati e altre risorse. Considerare l'eliminazione di tali risorse prima di eliminare l'organizzazione provider.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `L'eliminazione di uno spazio può avere esito negativo se si dispone di un numero elevato di organizzazioni consumer, applicazioni, prodotti pubblicati e altre risorse.  Considerare l'eliminazione di tali risorse prima di eliminare lo spazio.`,
  [`Deleting {{name}}...`]: `Eliminazione di {name}...`,
  [`Deprecate`]: `Rendi obsoleto`,
  [`Deprecate product`]: `Rendi obsoleto prodotto`,
  [`Deprecated`]: `Obsoleto`,
  [`Deprecate|permission`]: `Rendi obsoleto`,
  [`Description of gateway`]: `Descrizione del gateway`,
  [`Description text for the behaviour of the modal.`]: `Testo di descrizione per il comportamento del modale.`,
  [`Descriptive name`]: `Nome descrittivo`,
  [`Design`]: `Progetta`,
  [`Designate a current member as owner of a consumer organization.`]: `Designare un membro corrente come proprietario di un'organizzazione consumer`,
  [`Designate a current member as owner of a provider organization.`]: `Designa un membro corrente come proprietario di un'organizzazione provider.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Destinazione`,
  [`Determines which credential-extraction method to use.`]: `Determina quale metodo di estrazione credenziale utilizzare.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Determinare se includere questo tipo/campo e gli eventuali argomenti applicabili nello schema.`,
  [`Develop`]: `Sviluppa`,
  [`Develop APIs`]: `Sviluppa API`,
  [`Develop APIs and products`]: `Sviluppa API e prodotti`,
  [`Developer`]: `Sviluppatore`,
  [`developer`]: `sviluppatore`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `Comunità di sviluppatori`,
  [`Developers can request a state upgrade from development to production`]: `Gli sviluppatori possono richiedere un aggiornamento dello stato da sviluppo a produzione`,
  [`Development`]: `Sviluppo`,
  [`Directive`]: `Direttiva`,
  [`Director Endpoint`]: `Endpoint Director`,
  [`Director TLS client profile`]: `Profilo client TLS Director`,
  [`Disable`]: `Disattiva`,
  [`Disable Activity log`]: `Disabilitare il log delle attività`,
  [`Disable Self-Service Onboarding`]: `Disabilita procedura di avvio autonoma`,
  [`Disable spaces`]: `Disabilita spazi`,
  [`Disable buffering`]: `Disabilita buffering`,
  [`Disable Self-Service Onboarding Approval`]: `Disabilita approvazione della procedura di avvio autonoma`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Disabilitare la sovrascrittura del timeout di invito delle organizzazioni consumer.`,
  [`Disabled`]: `Disabilitato`,
  [`disabled`]: `disabilitato`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Disabilitando la procedura di avvio autonoma, tutti gli sviluppatori delle applicazioni dovranno essere invitati dal provider di API, da un amministratore o proprietario di un'organizzazione consumer esistente.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Disabilitando la procedura di avvio autonoma, tutte le richieste di procedura di avvio verranno approvate automaticamente.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `La disabilitazione non imposterà il timeout di invito delle organizzazioni consumer sul timeout di catalogo.`,
  [`Discover targets`]: `Rileva destinazioni`,
  [`Display table checkboxes`]: `Visualizza caselle di spunta tabella`,
  [`Display vanity endpoint`]: `Visualizza endpoint di reindirizzamento a microsito`,
  [`Do not publish API (continue editting)`]: `Non pubblicare l'API (continuare la modifica)`,
  [`Do not require applications or users to authenticate`]: `Non richiedere l'autenticazione di utenti o applicazioni`,
  [`Do not require authentication`]: `Non richiedere autenticazione`,
  [`Do not share the resource with provider organizations`]: `Non condivide la risorsa con le organizzazioni provider`,
  [`Do not share the API gateway with provider organizations`]: `Non condivide il gateway API con le organizzazioni provider`,
  [`Do not share the service with provider organizations`]: `Non condivide il servizio con le organizzazioni provider`,
  [`Do not use Dynamic DNS`]: `Non utilizzare DNS dinamico`,
  [`Do you want to remove these references?`]: `Si desidera rimuovere questi riferimenti?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Documentazione Docker`,
  [`Docs`]: `Documentazione`,
  [`Document Editor`]: `Editor di documenti`,
  [`Documentation and tutorials with step-by-step instructions`]: `Documentazione e supporti didattici con istruzioni dettagliate`,
  [`Domain name`]: `Nome dominio`,
  [`Domain Name`]: `Nome dominio`,
  [`Domains handled by gateway via SNI`]: `Domini gestiti dal gateway tramite SNI`,
  [`Don't have an account?`]: `Non si dispone di un account?`,
  [`Done`]: `Fine`,
  [`Download`]: `Scarica`,
  [`Download Gateway`]: `Scarica gateway`,
  [`Download and install`]: `Scarica e installa`,
  [`Download and set up DataPower API Gateway`]: `Scarica e imposta DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Scarica e imposta DataPower API Gateway per l'utilizzo in sede o in qualsiasi cloud.`,
  [`Download for Linux`]: `Download per Linux`,
  [`Download for Mac`]: `Download per Mac`,
  [`Download for Windows`]: `Download per Windows`,
  [`Download gateway`]: `Scarica gateway`,
  [`Download schema`]: `Scarica schema`,
  [`Download Toolkit`]: `Scarica toolkit`,
  [`Download toolkit`]: `Scarica toolkit`,
  [`Download toolkit and credentials for various platforms`]: `Scarica toolkit e credenziali di varie piattaforme`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Credenziali scaricate non trovate per questa connessione cloud. Per l'accesso verranno utilizzate le credenziali predefinite.`,
  [`Dutch`]: `Olandese`,
  [`Draft {type} creation failed`]: `Creazione del {type} di bozza non riuscita`,
  [`Drafts`]: `Bozze`,
  [`Drag and drop files here or click to upload`]: `Trascinare e rilasciare i file in questo punto oppure fare clic per caricare`,
  [`Drag & Drop`]: `Trascina & rilascia`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `DNS dinamico`,
  [`Dynamic OAuth configuration from a URL`]: `Configurazione OAuth dinamico da un URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Configurazione OAuth dinamico da una stringa letterale`,
  [`Dynamic group`]: `Gruppo dinamico`,
  [`Dynamic table entries`]: `Voci tabella dinamica`,
  [`ENABLE`]: `ABILITA`,
  [`Encoded`]: `Codificato`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Ogni spazio viene utilizzato da un differente team di sviluppo del provider API e presenta proprie serie di funzioni di gestione relative specificamente alle API che il team associato pubblica in tale spazio, consentendo ad ogni team di gestire le relative API in modo indipendente. Quando si esegue lo staging o si pubblica un'API in un catalogo che ha degli spazi abilitati, si specifica lo spazio nell'ambito del catalogo in cui si desidera eseguire lo staging o pubblicare.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Ciascuna organizzazione provider è proprietaria di una serie di API, prodotti, piani e cataloghi.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Creare, proteggere, gestire, condividere ed analizzare facilmente le API che si trovano su Cloud ed in sede.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Ciascuna organizzazione provider è proprietaria di una serie di API, prodotti, piani e cataloghi. [Ulteriori informazioni]({link})`,
  [`Edit`]: `Modifica`,
  [`Edit access token TTL`]: `Modifica TTL del token di accesso`,
  [`Edit admin organization invitation timeout`]: `Modifica timeout invito organizzazione Admin`,
  [`Edit API`]: `Modifica API`,
  [`Edit API Connect UI`]: `Modifica UI di API Connect`,
  [`Edit API endpoint for unenforced APIs`]: `Modifica endpoint API per le API non applicate`,
  [`Edit API gateway service`]: `Modifica il servizio gateway API`,
  [`Edit API Path`]: `Modifica percorso API`,
  [`Edit API security definition`]: `Modifica la definizione di sicurezza API`,
  [`Edit API user registries`]: `Modifica registri utente API`,
  [`Edit analytics service`]: `Modifica servizio di analytics`,
  [`Edit application`]: `Modifica applicazione`,
  [`Edit Assembly`]: `Modifica assemblaggio`,
  [`Edit authentication URL user registry`]: `Modifica registro utente URL di autenticazione`,
  [`Edit availability zone`]: `Modifica zona di disponibilità`,
  [`Edit Availablity Zone`]: `Modifica zona di disponibilità`,
  [`Edit and apply constraints to your selected items.`]: `Modifica e applica i vincoli agli elementi selezionati.`,
  [`Edit billing integration`]: `Modifica integrazione di fatturazione`,
  [`Edit Catalog Details`]: `Modifica dettagli catalogo`,
  [`Edit cloud setting invitation timeout`]: `Modifica timeout invito impostazione Cloud`,
  [`Edit constraints`]: `Modifica vincoli`,
  [`Edit consumer organization`]: `Modifica organizzazione consumer`,
  [`Edit DNS Scheme`]: `Modifica schema DNS`,
  [`Edit DataPower API gateway service`]: `Modifica servizio DataPower API Gateway`,
  [`Edit DataPower gateway service`]: `Modifica servizio DataPower Gateway`,
  [`Edit definition`]: `Modifica definizione`,
  [`Edit dynamic DNS`]: `Modifica DNS dinamico`,
  [`Edit email server`]: `Modifica server di e-mail`,
  [`Edit extensions`]: `Modifica estensioni`,
  [`Edit Gateway Service`]: `Modifica servizio gateway`,
  [`Edit gateway`]: `Modifica gateway`,
  [`Edit gateway extension`]: `Modifica estensione del gateway`,
  [`Edit Gateways`]: `Modifica gateway`,
  [`Edit IBM-managed gateway`]: `Modifica gateway gestito da IBM`,
  [`Edit Invitation Timeout`]: `Modifica timeout invito`,
  [`Edit gateways`]: `Modifica gateway`,
  [`Edit invitation timeout`]: `Modifica timeout invito`,
  [`Edit JSON`]: `Modifica JSON`,
  [`Edit keystore`]: `Modifica keystore`,
  [`Edit LDAP user registry`]: `Modifica registro utente LDAP`,
  [`Edit LTPA Token`]: `Modifica token LTPA`,
  [`Edit lifecycle approvals`]: `Modifica approvazioni del ciclo di vita`,
  [`Edit local user registry`]: `Modifica registro utente locale`,
  [`Edit native OAuth provider`]: `Modifica provider OAuth nativo`,
  [`Edit New Billing System`]: `Modifica nuovo sistema di fatturazione`,
  [`Edit Notification Server`]: `Modifica server di notifica`,
  [`Edit Notification template`]: `Modifica template di notifica`,
  [`Edit OAuth Provider`]: `Modifica provider OAuth`,
  [`Edit OAuth provider details`]: `Modifica dettagli provider OAuth`,
  [`Edit OAuth Provider Visibility`]: `Modifica visibilità provider OAuth`,
  [`Edit OIDC User Registry`]: `Modifica registro utente OIDC`,
  [`Edit Onboarding`]: `Modifica procedura di avvio`,
  [`Edit Operation`]: `Modifica operazione`,
  [`Edit Organization`]: `Modifica organizzazione`,
  [`Edit Path`]: `Modifica percorso`,
  [`Edit Path Parameters`]: `Modifica parametri percorso`,
  [`Edit notification template`]: `Modifica template di notifica`,
  [`Edit OAuth provider`]: `Modifica provider OAuth`,
  [`Edit OAuth provider visibility`]: `Modifica visibilità provider OAuth`,
  [`Edit OIDC user registry`]: `Modifica registro utente OIDC`,
  [`Edit onboarding`]: `Modifica procedura di avvio`,
  [`Edit operation`]: `Modifica operazione`,
  [`Edit organization`]: `Modifica organizzazione`,
  [`Edit path`]: `Modifica percorso`,
  [`Edit path parameters`]: `Modifica parametri percorso`,
  [`Edit plan`]: `Modifica piano`,
  [`Edit portal`]: `Modifica portale`,
  [`Edit portal service`]: `Modifica servizio portale`,
  [`Edit Portal Settings`]: `Modifica impostazioni del portale`,
  [`Edit product`]: `Modifica prodotto`,
  [`Edit product APIs`]: `Modifica API prodotto`,
  [`Edit Product Subscribability`]: `Modifica sottoscrivibilità del prodotto`,
  [`Edit Product Visibility`]: `Modifica visibilità del prodotto`,
  [`Edit Property`]: `Modifica proprietà`,
  [`Edit Provider Organization`]: `Modifica organizzazione provider`,
  [`Edit Refresh Token TTL`]: `Modifica TTL del token di aggiornamento`,
  [`Edit remote gateway`]: `Modifica gateway remoto`,
  [`Edit Reset Password TTL`]: `Modifica TTL password di ripristino`,
  [`Edit Role`]: `Modifica ruolo`,
  [`Edit Role Default for Provider Organizations`]: `Modifica valori predefiniti del ruolo per le organizzazioni provider`,
  [`Edit Role for Provider Organizations`]: `Modifica ruolo per Organizzazioni provider`,
  [`Edit property`]: `Modifica proprietà`,
  [`Edit provider organization`]: `Modifica organizzazione provider`,
  [`Edit refresh token TTL`]: `Modifica TTL del token di aggiornamento`,
  [`Edit reset password TTL`]: `Modifica TTL password di ripristino`,
  [`Edit role`]: `Modifica ruolo`,
  [`Edit role default for Provider Organizations`]: `Modifica valori predefiniti del ruolo per le organizzazioni provider`,
  [`Edit role for Provider Organizations`]: `Modifica ruolo per organizzazioni provider`,
  [`Edit Roles`]: `Modifica ruoli`,
  [`Edit Schema in JSON/XML`]: `Modifica schema in JSON/XML`,
  [`Edit security definition`]: `Modifica la definizione di sicurezza`,
  [`Edit sender`]: `Modifica mittente`,
  [`Edit sender & email server`]: `Modifica mittente & server e-mail`,
  [`Edit sender Info`]: `Modifica informazioni mittente`,
  [`Edit service visibility`]: `Modifica visibilità servizio`,
  [`Edit show/hide settings`]: `Modifica impostazioni mostra/nascondi`,
  [`Edit space details`]: `Modifica dettagli spazio`,
  [`Edit TLS client profile`]: `Modifica profilo client TLS`,
  [`Edit TLS client profile visibility`]: `Modifica visibilità profilo client TLS`,
  [`Edit TLS server profile`]: `Modifica profilo server TLS`,
  [`Edit third party OAuth provider`]: `Modifica provider OAuth di terze parti`,
  [`Edit truststore`]: `Modifica truststore`,
  [`Edit unenforced API`]: `Modifica API non applicata`,
  [`Edit user registries`]: `Modifica registri utente`,
  [`Edit user registry`]: `Modifica il registro utente`,
  [`Edit user registry visibility`]: `Modifica visibilità registro utente`,
  [`Edit user registry for API Manager`]: `Modifica registro utente per API Manager`,
  [`Edit user registry for Cloud Manager`]: `Modifica registro utente per Cloud Manager`,
  [`Edit vanity API endpoints`]: `Modifica endpoint API di reindirizzamento a microsito`,
  [`Edit visibility`]: `Modifica visibilità`,
  [`Edit XML`]: `Modifica XML`,
  [`Edit app lifecycle approved`]: `Modifica ciclo di vita dell'app approvata`,
  [`Edit app lifecycle cancelled`]: `Modifica ciclo di vita dell'app annullata`,
  [`Edit app lifecycle denied`]: `Modifica ciclo di vita dell'app negata`,
  [`Edit app lifecycle pending`]: `Modifica ciclo di vita dell'app in sospeso`,
  [`Edit app lifecycle request`]: `Modifica ciclo di vita dell'app richiesta`,
  [`Edit app reinstated`]: `Modifica app reintegrata`,
  [`Edit app suspended`]: `Modifica app sospesa`,
  [`Edit audit setting`]: `Modifica impostazione di verifica`,
  [`Edit definitions`]: `Modifica definizioni`,
  [`Enable external group mapping`]: `Abilita associazione gruppo esterno`,
  [`Edit extra constraints for the GraphQL schema`]: `Modifica vincoli supplementari per lo schema GraphQL`,
  [`Edit GraphQL schema|title`]: `Modifica schema GraphQL`,
  [`Edit inline schema`]: `Modifica schema in linea`,
  [`Edit inputs`]: `Modifica input`,
  [`Edit invitation`]: `Modifica invito`,
  [`Edit mail server test connection`]: `Modifica connessione di verifica del server di posta`,
  [`Edit member invitation`]: `Modifica invito al membro`,
  [`Edit notification templates`]: `Modifica template di notifica`,
  [`Edit notification templates:`]: `Modifica template di notifica:`,
  [`Edit operation list`]: `Modifica elenco operazioni`,
  [`Edit or upload JSON schema`]: `Modifica o carica lo schema JSON`,
  [`Edit outputs`]: `Modifica output`,
  [`Edit password changed`]: `Modifica del cambiamento di password`,
  [`Edit password reset`]: `Impostazione Modifica password`,
  [`Edit plan APIs`]: `Modifica API del piano`,
  [`Edit schema`]: `Modifica schema`,
  [`Edit schema|button`]: `Modifica schema`,
  [`Edit schema|title`]: `Modifica schema`,
  [`Edit settings for roles, notifications and more.`]: `Consente di modificare le impostazioni dei ruoli, delle notifiche ed altro.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Modificare le impostazioni per registri utente, ruoli, endpoint ed altro`,
  [`Edit sign up`]: `Modifica registrazione`,
  [`Edit task product lifecycle approved`]: `Modifica ciclo di vita del prodotto dell'attività approvata`,
  [`Edit task product lifecycle cancelled`]: `Modifica ciclo di vita del prodotto dell'attività annullata`,
  [`Edit task product lifecycle denied`]: `Modifica ciclo di vita del prodotto dell'attività negata`,
  [`Edit task product lifecycle pending`]: `Modifica ciclo di vita del prodotto dell'attività in sospeso`,
  [`Edit task product lifecycle request`]: `Modifica ciclo di vita del prodotto dell'attività richiesta`,
  [`Edit task subscription approved`]: `Modifica sottoscrizione attività approvata`,
  [`Edit task subscription cancelled`]: `Modifica sottoscrizione attività annullata`,
  [`Edit task subscription denied`]: `Modifica sottoscrizione attività negata`,
  [`Edit task subscription pending`]: `Modifica sottoscrizione attività in sospeso`,
  [`Edit task subscription request`]: `Modifica sottoscrizione dell'attività richiesta`,
  [`Edit template`]: `Modifica template`,
  [`Edit the TLS client profile.`]: `Modificare il profilo client TLS.`,
  [`Edit the TLS Server profiles`]: `Modificare i  profili server TLS.`,
  [`Edit the keystore title.`]: `Modificare il titolo keystore.`,
  [`Edit the organization summary details as required`]: `Modificare i dettagli di riepilogo dell'organizzazione come richiesto`,
  [`Edit the organization title`]: `Modifica il titolo dell'organizzazione`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Modificare i parametri per la configurazione del server di e-mail. I campi sono obbligatori a meno che non siano indicati come facoltativi.`,
  [`Edit signed token`]: `Modifica token firmato`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Modificare il testo per il template e-mail. Le variabili sono racchiuse tra parentesi e non possono essere modificate.`,
  [`Edit the truststore.`]: `Modificare il truststore.`,
  [`Edit the visibility for each resource.`]: `Modificare la visibilità per ogni risorsa`,
  [`Edit user details`]: `Modifica dettagli utente`,
  [`Edit {notificationTemplate}`]: `Modifica {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `Consente di modificare, assemblare, proteggere ed eseguire il test delle API`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `Consente di modificare, assemblare, proteggere ed eseguire il test delle API. Consente di creare un package di API utilizzando prodotti per la pubblicazione ai consumer.`,
  [`Edit, upload, or infer XML schema`]: `Modifica, carica o deduci lo schema XML`,
  [`Editors`]: `Editor`,
  [`Edit|permission`]: `Modifica`,
  [`Edit API key timeout`]: `Modifica timeout chiave API`,
  [`Edit Base64 encoding for temporary tokens`]: `Modifica codifica Base64 per i token temporanei`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `È necessario fornire "Oggetto crittografico di firma dell'ID token" oppure "Chiave di firma dell'ID token".`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `È necessario fornire un "oggetto di crittografia di verifica JWT" o "un JWK di verifica JWT".`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Selezionare l'opzione per utilizzare l'endpoint definito nell'API o fornire un endpoint del validatore predefinito.`,
  [`Element name for JSON array elements`]: `Nome elemento per gli elementi dell'array JSON`,
  [`Email`]: `E-mail`,
  [`Email address`]: `Indirizzo e-mail`,
  [`Email Content`]: `Contenuto e-mail`,
  [`Email endpoint`]: `Endpoint e-mail`,
  [`Email required`]: `Indirizzo email obbligatorio`,
  [`Email sender`]: `Mittente email`,
  [`Email server`]: `Server e-mail`,
  [`Email server configuration`]: `Configurazione server e-mail`,
  [`Email servers`]: `Server e-mail`,
  [`Empty case`]: `Caso vuoto`,
  [`Empty catch`]: `Cattura vuota`,
  [`Empty JSON array handling`]: `Gestione array JSON vuoto`,
  [`Empty XML element handling`]: `Gestione elemento XML vuoto`,
  [`Enable`]: `Abilita`,
  [`Enable API consumers to invite collaborators and assign roles`]: `Abilitare i consumer API per invitare i collaboratori e assegnare i ruoli`,
  [`Enable application lifecycle`]: `Abilita ciclo di vita dell'applicazione`,
  [`Enable billing`]: `Abilita fatturazione`,
  [`Enable gateway services`]: `Abilita servizi gateway`,
  [`Enable gateway services and policies`]: `Abilita servizi e politiche del gateway`,
  [`Enable GraphiQL`]: `Abilita GraphiQL`,
  [`Enable GraphiQL editor`]: `Abilita editor GraphiQL`,
  [`Enable GraphiQL Editor on browser`]: `Abilita GraphiQL Editor sul browser`,
  [`Enable JSON post processing`]: `Abilita post-elaborazione JSON`,
  [`Enable lifecycle approvals`]: `Abilita approvazioni del ciclo di vita`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Abilitare l'estensione NONCE per evitare il riutilizzo di richieste compromesse (ripetute).`,
  [`Enable OAuth providers`]: `Abilita provider OAuth`,
  [`Enable OIDC`]: `Abilita OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Abilita il template OpenID Connect per generare l'ID token.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Abilitare l'estensione PKCE per consentire ai client pubblici di mitigare la minaccia di intercettazione del codice di autorizzazione.`,
  [`Enable production mode`]: `Abilita modalità di produzione`,
  [`Enable Proxy`]: `Abilita proxy`,
  [`Enable Self-Service Onboarding`]: `Abilita procedura di avvio autonoma`,
  [`Enable spaces`]: `Abilita spazi`,
  [`Enable token management`]: `Abilita gestione token`,
  [`Enable buffering`]: `Abilita buffering`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `Consentire ai consumer delle API di creare gli account del portale di sviluppo senza richiedere un invito.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `Abilitare il controllo accessi CORS (cross-origin resource sharing) per la propria API.`,
  [`Enable debug response headers`]: `Abilita intestazioni risposta di debug`,
  [`Enable proof key for code exchange`]: `Abilita la chiave di prova per lo scambio di codice`,
  [`Enable publishing of this product`]: `Abilitare la pubblicazione di questo prodotto`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Abilitare il reindirizzamento di codice di autorizzazione di terze parti attraverso l'endpoint OIDC Portal`,
  [`Enable security`]: `Abilita sicurezza`,
  [`Enable Self-Service Onboarding Approval`]: `Abilita approvazione della procedura di avvio autonoma`,
  [`Enable the following gateway services and policies for this catalog`]: `Abilitare i servizi e le politiche gateway riportati di seguito per questo catalogo`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Abilita gli utenti alla configurazione di un evento di verifica. Un evento di verifica è un riepilogo dettagliato degli utenti che hanno eseguito un evento e del momento in cui l'evento è stato eseguito.`,
  [`Enable/disable custom notification templates`]: `Abilita/disabilita template di notifica personalizzati`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Abilitare o disabilitare la codifica Base64 per gli inviti e i token di reimpostazione delle password`,
  [`Enable overriding consumer organizations invitation timeout`]: `Abilitare la sovrascrittura del timeout di invito delle organizzazioni consumer`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `È possibile abilitare o disabilitare la codifica Base64 per i token temporanei. I token temporanei rappresentano gli inviti e i token di reimpostazione delle password. Quando la codifica Base64 è abilitata, il token temporaneo è codificato in formato Base64. Quando la codifica Base64 è disabilitata, il formato JWT (JSON Web Token) del token temporaneo resta invariato. Ciò significa che la lunghezza dell'URL ricevuta dall'utente è ridotta.`,
  [`Enabled`]: `Abilitato`,
  [`enabled`]: `abilitato`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `L'abilitazione del ciclo di vita dell'applicazione consentirà alle applicazioni di avere lo stato di produzione o di sviluppo e di essere instradate verso endpoint e gateway differenti.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Abilitando l'approvazione della procedura di avvio autonoma, tutte le richieste di procedura di avvio dovranno essere approvate dal provider API o da un amministratore.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `L'abilitazione della procedura di avvio autonoma consente a uno sviluppatore delle applicazioni di effettuare la registrazione senza ricevere un invito dal provider API, dall'amministratore o proprietario dell'organizzazione consumer esistente.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `L'abilitazione imposterà tutti i timeout di invito delle organizzazioni consumer sul timeout di invito di catalogo.`,
  [`Encode Query params`]: `Codifica parametri query`,
  [`Encrypt`]: `Codifica`,
  [`Encrypt Crypto Object`]: `Oggetto crittografico di codifica`,
  [`Encrypt JWK variable name`]: `Nome variabile JWK codifica`,
  [`Encrypt a buffer using the specified certificate`]: `Codifica un buffer utilizzando il certificato specificato`,
  [`Encryption Algorithm`]: `Algoritmo di codifica`,
  [`Endpoint`]: `Endpoint`,
  [`Endpoint URLs`]: `URL endpoint`,
  [`Endpoint behavior`]: `Comportamento endpoint`,
  [`Endpoint Behavior`]: `Comportamento endpoint`,
  [`Endpoint for unenforced APIs`]: `Endpoint per API non forzate`,
  [`Endpoints`]: `Endpoint`,
  [`Enforce Required Params`]: `Applica parametri obbligatori`,
  [`Enforce the API by using API Connect Gateway.`]: `Applica l'API utilizzando il gateway API Connect.`,
  [`Enforced`]: `Applicato`,
  [`English`]: `Inglese`,
  [`Enter Summary`]: `Immettere riepilogo`,
  [`Enter URL`]: `Immettere URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Immettere Nome registro utente. Se vuoto, il Registro utente locale di API Manager viene utilizzato per impostazione predefinita`,
  [`Enter a catalog name`]: `Immettere un nome di catalogo`,
  [`Enter a Display Name`]: `Immettere un nome di visualizzazione`,
  [`Enter a space name`]: `Immettere il nome di uno spazio`,
  [`Enter a brief description`]: `Immettere una breve descrizione`,
  [`Enter a display name`]: `Immettere un nome di visualizzazione`,
  [`Enter a temporary sign in password`]: `Immettere una password di accesso temporanea`,
  [`Enter an Organization Name`]: `Immettere un nome per l'organizzazione`,
  [`Enter an Organization Title`]: `Immettere un titolo per l'organizzazione`,
  [`Enter catalog title`]: `Immettere il titolo del catalogo`,
  [`Enter details of the consumer organization`]: `Immettere i dettagli dell'organizzazione consumer`,
  [`Enter details of the product`]: `Immettere i dettagli del prodotto`,
  [`Enter details of the provider organization`]: `Immettere i dettagli dell'organizzazione provider`,
  [`Enter details of this API`]: `Immettere i dettagli di questa API`,
  [`Enter email of the new user`]: `Immettere l'e-mail del nuovo utente`,
  [`Enter first name of the new user`]: `Immettere il nome del nuovo utente`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Immettere uno o più indirizzi e-mail. Utilizzare le virgole per separare più indirizzi.`,
  [`Enter organization name`]: `Immettere il nome dell'organizzazione`,
  [`Enter organization title`]: `Immettere il titolo dell'organizzazione`,
  [`Enter password`]: `Immettere la password`,
  [`Enter space title`]: `Immettere il titolo dello spazio`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Immettere l'endpoint API per il gateway fornendo il nome host e il numero di porta. Ad esempio: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Immettere i dettagli di riepilogo dell'API`,
  [`Enter the API summary details. [Learn more]({link})`]: `Immettere i dettagli di riepilogo dell'API. [Ulteriori informazioni]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Immettere l'URL per il servizio di destinazione che si desidera sottoporre a proxing`,
  [`Enter the catalog summary details`]: `Immettere i dettagli di riepilogo del catalogo`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Immettere i dettagli di riepilogo del catalogo; una volta creato, il catalogo può essere configurato completamente`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Immettere l'indirizzo e-mail dell'utente da invitare come membro dell'organizzazione admin`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Immettere l'indirizzo e-mail dell'utente da invitare come membro del catalogo`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Immettere l'indirizzo e-mail dell'utente da invitare come membro dell'organizzazione provider`,
  [`Enter the email address of the user to invite as a member of the space`]: `Immettere l'indirizzo e-mail dell'utente da invitare come membro dello spazio`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Immettere l'indirizzo e-mail dell'utente a cui si desidera trasferire la proprietà.`,
  [`Enter the full path to JSON or YAML file`]: `Immettere il percorso completo del file JSON o YAML`,
  [`Enter the full path to WSDL file`]: `Immettere il percorso completo del file WSDL`,
  [`Enter the full path to YAML file`]: `Immettere il percorso completo del file YAML`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Inserire il nome di dominio completo per l'endpoint Analytics ingestion definito durante l'installazione.`,
  [`Enter the last name of the new user`]: `Immettere il cognome del nuovo utente`,
  [`Enter the owner's email address to send the invitation`]: `Immettere l'indirizzo e-mail del proprietario per inviare l'invito`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Immettere i parametri per la configurazione del servizio gateway. I campi sono obbligatori a meno che non siano indicati come facoltativi. Endpoint è l'endpoint del servizio API Gateway su DataPower. Base endpoint API è l'endpoint API Gateway per le chiamate API in entrata. Per SNI, immettere * per consentire tutti gli host (obbligatorio) ed immettere i nomi host per consentire host specifici. Scegliere il profilo TLS per supportare ciascun host.`,
  [`Enter the password for the certificate file if required.`]: `Immettere la password per il file di certificato se necessario.`,
  [`Enter the provider organization summary details`]: `Immettere i dettagli di riepilogo dell'organizzazione provider`,
  [`Enter the schema definitiom summary details`]: `Immettere i dettagli di riepilogo della definizione dello schema`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Immettere i dettagli di riepilogo dello spazio; una volta creato, lo spazio può essere configurato completamente`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Immettere i dettagli di riepilogo dello spazio; una volta creato, lo spazio può essere configurato completamente`,
  [`Enter the summary details for the new product`]: `Immettere i dettagli di riepilogo per il nuovo prodotto`,
  [`Enter the summary details for this role`]: `Immettere i dettagli di riepilogo per questo ruolo.`,
  [`Enter title of the organization`]: `Immettere il titolo dell'organizzazione`,
  [`Enter user email`]: `Immettere e-mail utente`,
  [`Enter username of the existing user`]: `Immetti nome utente dell'utente esistente`,
  [`Enter username of the new user`]: `Immetti il nome utente del nuovo utente`,
  [`Error`]: `Errore`,
  [`Error ID`]: `ID errore`,
  [`Error:`]: `Errore:`,
  [`Copy error ID to Clipboard`]: `Copia ID errore negli appunti`,
  [`Error Console`]: `Console degli errori`,
  [`Error connecting`]: `Errore di connessione`,
  [`Error connecting to GraphQL server`]: `Errore durante la connessione al server GraphQL`,
  [`Error getting user info during login. Please login with different user.`]: `Errore di acquisizione delle informazioni sull'utente durante il login. Accedere con un altro utente.`,
  [`Error content`]: `Errore contenuto`,
  [`Error message`]: `Messaggi di errore`,
  [`Error name`]: `Nome errore`,
  [`Error status code`]: `Codice di stato di errore`,
  [`Error status code is invalid`]: `Il codice di stato dell'errore non è valido`,
  [`Error status reason`]: `Motivo di stato errore`,
  [`Errors`]: `Errori`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Gli errori di solito sono dovuti ad informazioni sbagliate e a caratteri errati. Accertarsi di fornire le informazioni corrette sul provider, le informazioni sul client prima del test.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Errore durante il tentativo di ottenere l'API dall'URL {url}. Controllare URL, nome utente e password. Messaggio di errore: {message}`,
  [`Event`]: `Evento`,
  [`Event gateway management client TLS client profile`]: `Profilo client TLS del client di gestione del gateway di eventi`,
  [`Event load`]: `Caricamento evento`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Ciascuna organizzazione consumer ha un proprietario. Il proprietario dispone delle autorizzazioni di gestione complete e può gestire sottoscrizioni ed applicazioni.`,
  [`Exchange authorization code for tokens`]: `Scambia codice di autorizzazione per i token`,
  [`Exchange code for token`]: `Scambia codice di per il token`,
  [`Execute`]: `Esegui`,
  [`Execute migration target`]: `Esegui destinazione di migrazione`,
  [`Executes any catalog-level post-invoke handlers`]: `Esegue qualsiasi handler successivo al richiamo a livello di catalogo`,
  [`Executes any catalog-level pre-invoke handlers`]: `Esegue qualsiasi handler precedente al richiamo a livello di catalogo`,
  [`Existing`]: `Esistente`,
  [`Existing {docType}`]: `{docType} esistenti`,
  [`Existing API Connect user`]: `Utente API Connect esistente`,
  [`Existing OpenAPI`]: `OpenAPI esistente`,
  [`Existing product`]: `Prodotto esistente`,
  [`Existing SOAP API has been validated`]: `L'API SOAP esistente è stata convalidata`,
  [`Existing products in the catalog will be moved to a default space.`]: `I prodotti nel catalogo verranno spostati in uno spazio predefinito.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Gli utenti esistenti sono i membri correnti. È inoltre possibile attivare un nuovo membro da un altro registro utente.`,
  [`Expires On`]: `Scade il`,
  [`Expiry`]: `Scadenza`,
  [`Explorer`]: `Explorer`,
  [`Expose`]: `Esponi`,
  [`Extend the schema to specify the cost of types and fields`]: `Estendi lo schema per specificare il costo di tipi e campi`,
  [`Extended`]: `Esteso`,
  [`Extension Type:`]: `Tipo di estensione:`,
  [`Extensions`]: `Estensioni`,
  [`Extension has been uploaded.`]: `L'estensione è stata caricata.`,
  [`External`]: `Esterno`,
  [`External Doc Description`]: `Descrizione doc esterno`,
  [`External doc description`]: `Descrizione doc esterno`,
  [`External Documentation URL`]: `URL documentazione esterna`,
  [`External documentation URL`]: `URL documentazione esterna`,
  [`External Role mapping`]: `Associazione ruolo esterno`,
  [`External URL`]: `URL esterno`,
  [`Extract Identity Settings`]: `Impostazioni di estrazione identità`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `ID fattore`,
  [`Fail`]: `Errore`,
  [`Failed to change account password.`]: `Impossibile modificare la password dell'account.`,
  [`Failed to create analytics service {title}.`]: `Creazione del servizio di analytics {title} non riuscita.`,
  [`Failed to create analytics insights service {title}.`]: `Impossibile creare il servizio Analytics Insights {title}.`,
  [`Analytics insights service {title} has been updated.`]: `Il servizio Analytics Insights {title} è stato aggiornato.`,
  [`About the analytics insights service`]: `Informazioni sul servizio Analytics Insights`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Il servizio Analytics Insights raccoglie gli eventi API dal servizio gateway. Ogni servizio gateway può avere un servizio Analytics Insights associato.`,
  [`Advanced analytics insights configuration`]: `Configurazione avanzata di Analytics Insights`,
  [`Analytics insights service {title} has been created.`]: `Il servizio Analytics Insights {title} è stato creato.`,
  [`Analytics Insights details`]: `Dettagli di Analytics Insights`,
  [`Management endpoint on the analytics insights service`]: `Endpoint di gestione sul servizio Analytics Insights`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Immettere il nome del dominio completo per l'endpoint di director Analytics Insights definito durante l'installazione.`,
  [`TYPE`]: `TIPO`,
  [`Failed to create email server`]: `Impossibile creare il server e-mail`,
  [`Failed to create gateway service`]: `Creazione del servizio gateway non riuscita`,
  [`Failed to create gateway service {name}.`]: `Creazione del servizio gateway {name} non riuscita.`,
  [`Failed to create user registry {title}.`]: `Creazione del registro utente {title} non riuscita.`,
  [`Failed to delete a group`]: `Impossibile eliminare un gruppo.`,
  [`Failed to get TLS client profiles`]: `Impossibile acquisire i profili client TLS`,
  [`Failed to get catalogs`]: `Impossibile ottenere i cataloghi`,
  [`Failed to get configured OAuth providers`]: `Impossibile acquisire i provider OAuth configurati`,
  [`Failed to get policies of gateways`]: `Impossibile acquisire le politiche di gateway`,
  [`Failed to get user registries`]: `Impossibile acquisire i registri utente`,
  [`Failed to introspect schema from server:`]: `Impossibile esaminare lo schema dal server:`,
  [`Failed to make API online`]: `Impossibile impostare l'API come online`,
  [`Failed to register catalog member {user}.`]: `Impossibile registrare il membro del catalogo {user}.`,
  [`Failed to remove analytics service: {arg}`]: `Impossibile rimuovere il servizio di analytics: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Impossibile rimuovere la zona di disponibilità: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Impossibile rimuovere il servizio gateway: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Impossibile rimuovere il server di posta: {arg}`,
  [`Failed to remove portal service: {arg}`]: `Impossibile rimuovere il servizio portale: {arg}`,
  [`Failed to create {name}.`]: `Impossibile creare {name}.`,
  [`Failed to sign out! Please check the console for details.`]: `Impossibile scollegarsi. Controllare la console per i dettagli.`,
  [`Failed to update {name}.`]: `Impossibile aggiornare {name}.`,
  [`Failed to update OAuth provider`]: `Impossibile aggiornare il provider OAuth`,
  [`Failed to update account.`]: `Aggiornamento dell'account non riuscito`,
  [`Failed to update analytics service {title}.`]: `Aggiornamento del servizio di analytics {title} non riuscito.`,
  [`Failed to update gateway service {title}.`]: `Aggiornamento del servizio gateway {title} non riuscito.`,
  [`Failed to update task`]: `Impossibile aggiornare l'attività`,
  [`Failed to create app {title}`]: `Impossibile creare l'app {title}`,
  [`Feature flags`]: `Indicatori funzione`,
  [`Fetch schema`]: `Recupera schema`,
  [`Fetched endpoints`]: `Endpoint richiamati`,
  [`Field`]: `Campo`,
  [`Field label`]: `Etichetta campo`,
  [`Field Mapping`]: `Associazione campi`,
  [`Field name`]: `Nome campo`,
  [`Field weight`]: `Peso campo`,
  [`File Name`]: `Nome file`,
  [`File Preview`]: `Anteprima file`,
  [`File contains validation errors`]: `Il file contiene errori di convalida`,
  [`File is not a valid OpenAPI definition`]: `Il file non è una definizione OpenAPI valida`,
  [`File is not a valid extension format.`]: `Il file non è in un formato di estensione valido.`,
  [`File is not a valid {fileType} format.`]: `Il file non è un formato {fileType} valido.`,
  [`File name`]: `Nome file`,
  [`File upload`]: `Caricamento file`,
  [`File uploaded is not a valid file`]: `Il file caricato non è un file valido`,
  [`File uploaded successfully`]: `File caricato correttamente`,
  [`File was updated and validated!`]: `Il file è stato aggiornato e convalidato.`,
  [`File was uploaded and validated!`]: `Il file è stato caricato e convalidato.`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `Il file {draftFilePath} esiste. Provare a cambiare la combinazione nome / versione della bozza`,
  [`Fill out the details of this service`]: `Compilare i dettagli di questo servizio`,
  [`Filter`]: `Filtra`,
  [`Filter by operation`]: `Filtra in base ad operazione`,
  [`Filter policies`]: `Politiche filtro`,
  [`Final Action`]: `Azione finale`,
  [`Find existing user`]: `Trova utente esistente`,
  [`Find expert answers in the API Connect community forum`]: `Trova le risposte degli esperti nel forum della comunità API Connect`,
  [`Find expert answers in the API Connect community forum.`]: `Trova le risposte degli esperti nel forum della comunità API Connect.`,
  [`Find schema`]: `Trova schema`,
  [`Finger Print`]: `Impronta digitale`,
  [`Fingerprint SHA-1`]: `Impronta digitale SHA-1`,
  [`Fingerprint SHA-256`]: `Impronta digitale SHA-256`,
  [`Finish`]: `Fine`,
  [`First name`]: `Nome`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Innanzitutto, specificare l'URL dell'endpoint utilizzato dal gateway per il traffico API. Il valore deve essere un nome di dominio completo, pubblico con percorsi aggiuntivi specifici delle proprie chiamate API, ad esempio: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Innanzitutto, specificare l'URL dell'endpoint di gestione del gateway. Per i gateway in esecuzione in ambienti Kubernetes, il valore deve corrispondere all'endpoint specificato tramite il comando di impostazione del gateway set gwy apic-gw-service. Per i dispositivi gateway, il valore deve essere nel formato http://<ip-address-for-gateway>:3000. Quando si utilizza un cluster di gateway, specificare la coppia indirizzo:porta del bilanciatore del carico.`,
  [`Fit to screen`]: `Adatta allo schermo`,
  [`Flow`]: `Flusso`,
  [`Follow redirects`]: `Segui reindirizzamenti`,
  [`Found In`]: `Trovato in`,
  [`Fonts contained in the following file are available under the`]: `I caratteri contenuti nel seguente file sono disponibili in base alla`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `Per le definizioni WSDL, caricare un file zip che contenga il file WSDL e la definizione API (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Per ciascun piano nel prodotto originale, selezionare il piano nel prodotto di sostituzione a cui si desidera effettuare la migrazione delle sottoscrizioni`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Per ciascun piano nel prodotto originale, selezionare il piano nel prodotto in sostituzione a cui si desidera effettuare la migrazione delle sottoscrizioni`,
  [`For guidance on the available command set, access the CLI's help`]: `Per una guida sulla serie di comandi disponibili, accedere alla guida CLI.`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Per i siti che utilizzano i provider OAuth nativi, come opzione specificare il segreto condiviso che verrà utilizzato da tutte le chiamate API.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Per l'LDAP scrivibile, selezionare Utente gestito ed aggiungere l'associazione dei propri nomi di attributo del registro LDAP di origine ai valori di attributo LDAP di API Connect. Le proprietà predefinite del profilo utente richieste da API Connect durante la registrazine utente sono username, first_name, last_name, email e password. Viene fornita una configurazione di associazione di esempio - modificare l'associazione in modo che corrisponda ai propri requisiti`,
  [`Forbidden`]: `Non consentito`,
  [`Forget token`]: `Scorda token`,
  [`Forgot password?`]: `Password dimenticata?`,
  [`Forgot password`]: `Password dimenticata`,
  [`Form`]: `Modulo`,
  [`Forum`]: `Forum`,
  [`French`]: `Francese`,
  [`From File`]: `Da file`,
  [`From Sample JSON`]: `Dal JSON di esempio`,
  [`From Sample XML`]: `Dall'XML di esempio`,
  [`From URL`]: `Da URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Dal servizio GraphQL esistente (proxy GraphQL)`,
  [`From existing OpenAPI service`]: `Dal servizio OpenAPI esistente`,
  [`From existing WSDL service (REST proxy)`]: `Dal servizio WSDL esistente (proxy REST)`,
  [`From existing WSDL service (SOAP proxy)`]: `Dal servizio WSDL esistente (proxy SOAP)`,
  [`From target service`]: `Dal servizio di destinazione`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `Dalle operazioni definite nella definizione WSDL per il servizio SOAP, selezionare quelle che si desidera utilizzare nella propria API e specificare i metodi ed i percorsi URL.`,
  [`Gateway Extensions`]: `Estensioni del gateway`,
  [`Gateway extension`]: `Estensione del gateway`,
  [`Gateway Services`]: `Servizi gateway`,
  [`Gateway Type`]: `Tipo di gateway`,
  [`Gateway by Name`]: `Gateway per nome`,
  [`Gateway services`]: `Servizi gateway`,
  [`Gateway type`]: `Tipo di gateway`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `Licenza GPL v2`,
  [`GROUP`]: `GRUPPO`,
  [`Group name`]: `Nome gruppo`,
  [`Gateway`]: `Gateway`,
  [`Gateways`]: `Gateway`,
  [`Gateway details`]: `Dettagli gateway`,
  [`Gateway Details`]: `Dettagli gateway`,
  [`Gateway Error`]: `Errore gateway`,
  [`Gateway service details`]: `Dettagli del servizio gateway`,
  [`Gateway management client TLS client profile`]: `Profilo client TLS per il client di gestione del gateway`,
  [`Gateway management client keystore`]: `Keystore client di gestione Gateway`,
  [`Gateway management client truststore`]: `Truststore client di gestione Gateway`,
  [`Gateway processing status`]: `Stato di elaborazione del gateway`,
  [`Gateway policies (TODO)`]: `Politiche gateway (TODO)`,
  [`Gateway service`]: `Servizio gateway`,
  [`Gateway Service US East (TODO)`]: `Servizio del gateway Stati Uniti orientali (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Il servizio Gateway {arg} è stato rimosso.`,
  [`Gateway service configuration`]: `Configurazione servizio gateway`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `Host del servizio gateway non disponibile. L'API Test(Try it) in Explorer non sarà abilitata.`,
  [`Gateway service configuration has been updated.`]: `La configurazione del servizio gateway è stata aggiornata.`,
  [`Gateway service {title} has been created.`]: `Servizio gateway {title} creato.`,
  [`Gateway service {title} has been updated.`]: `Il servizio gateway {title} è stato aggiornato.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `I servizi gateway sono gestiti in ciascuno spazio quando gli spazi sono abilitati. Passare alle impostazioni dello spazio per gestire i servizi gateway.`,
  [`Gateway version`]: `Versione gateway`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Gateway per pagina`,
  [`Gather-and-send`]: `Gather-and-send`,
  [`Gather-only`]: `Gather-only`,
  [`General configuration`]: `Configurazione generale`,
  [`General information about the API.`]: `Informazioni generali relative all'API.`,
  [`Generate`]: `Genera`,
  [`Generate an OpenAPI definition for the proxy`]: `Genera una definizione OpenAPI per il proxy`,
  [`Generate JSON Web Token (JWT)`]: `Genera JWT (JSON Web Token)`,
  [`Generate JWT`]: `Genera JWT`,
  [`Generate LTPA Token`]: `Genera token LTPA`,
  [`Generate OAuth Access Tokens`]: `Genera token di accesso OAuth`,
  [`Generate OAuth access tokens`]: `Genera token di accesso OAuth`,
  [`Generate a default product`]: `Genera un prodotto predefinito`,
  [`Generate access token`]: `Genera token di accesso`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Genera un token LTPA in modo che l'API possa eseguire l'autenticazione con i server Web WebSphere Application Server in modo sicuro.`,
  [`Generate an object`]: `Crea un oggetto`,
  [`Generate authorization code`]: `Genera codice di autorizzazione`,
  [`Generate from sample JSON`]: `Genera da JSON di esempio`,
  [`Generate from sample XML`]: `Genera da XML di esempio`,
  [`Generate tests automatically to build high quality APIs`]: `Genera test automaticamente per creare API di alta qualità`,
  [`Generated OpenAPI 2.0 definition`]: `Generata definizione OpenAPI 2.0`,
  [`Generated default product for the API`]: `Prodotto predefinito generato per l'API`,
  [`Generated name for use in commands and API calls`]: `Nome generato da utilizzare nei comandi e nelle chiamate API`,
  [`German`]: `Tedesco`,
  [`Get Base DN`]: `Acquisisci DN di base`,
  [`Get Code`]: `Richiama codice`,
  [`Get Code Snippet`]: `Ottieni frammento di codice`,
  [`Get Support`]: `Richiedi supporto`,
  [`Get Token`]: `Ottieni token`,
  [`Get activation link`]: `Acquisisci link di attivazione`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Iniziare registrando un servizio gateway. Ciò consente di eseguire il test degli endpoint durante la configurazione di altri servizi. Una volta registrato, il servizio verrà visualizzato in questo punto. [Ulteriori informazioni]({link})`,
  [`Get subscription ID`]: `Acquisisci ID sottoscrizione`,
  [`Getting Started`]: `Iniziamo`,
  [`Getting started`]: `Introduzione`,
  [`Git information`]: `Informazioni Git`,
  [`Github`]: `Github`,
  [`Global policies`]: `Politiche globali`,
  [`Go to Spaces`]: `Vai a Spazi`,
  [`Go to the Test tab`]: `Passa alla scheda Test`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `Limiti di frequenza GraphQL`,
  [`GraphQL service`]: `Servizio GraphQL`,
  [`GraphQL schema`]: `Schema GraphQL`,
  [`GraphQL Schema`]: `Schema GraphQL`,
  [`GraphQL schema has been successfully validated.`]: `Lo schema GraphQL è stato convalidato correttamente.`,
  [`GraphQL Schema URL`]: `URL schema GraphQL`,
  [`GraphQL send type`]: `Tipo di invio GraphQL`,
  [`GraphQL Websocket Upgrade`]: `Aggiornamento Websocket GraphQL`,
  [`Group Based DN`]: `DN basato sul gruppo`,
  [`Group {title} added`]: `Gruppo {title} aggiunto`,
  [`Group {title} updated`]: `Gruppo {title} aggiornato`,
  [`Group {title} has been deleted`]: `Il gruppo {title} è stato eliminato`,
  [`Groups`]: `Gruppi`,
  [`HEAD`]: `INTESTAZIONE`,
  [`HOST NAME`]: `NOME HOST`,
  [`HOST URL`]: `URL HOST`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `Modulo HTML`,
  [`HTML form time limit`]: `Limite di tempo modulo HTML`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 obbligatorio`,
  [`HTTP Header`]: `Intestazione HTTP`,
  [`HTTP Method`]: `Metodo HTTP`,
  [`HTTP Type`]: `Tipo HTTP`,
  [`HTTP Version`]: `Versione HTTP`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Barre di controllo`,
  [`Hard limit`]: `Limite rigido`,
  [`Header`]: `Intestazione`,
  [`Header control`]: `Controllo intestazione`,
  [`Header name payload`]: `Payload nome intestazione`,
  [`Header name token`]: `Token nome intestazione`,
  [`Headers`]: `Intestazioni`,
  [`Hello`]: `Salve`,
  [`Hello!`]: `Salve!`,
  [`Hello [IBM](url)`]: `Salve [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Salve {name}`,
  [`Hello {name}!`]: `Ciao {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Salve {name}, [visitare IBM profile](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nGrazie per aver registrato l'account sul Developer Portal {catalogTitle}.\n\nUtilizzare questo link per completare la registrazione:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nL'amministratore per il Developer Portal {consumerOrg} ha reintegrato l'app \n{appName}. Le chiamate API da questa app non saranno più bloccate dal\ngateway API.\n\nÈ possibile visualizzare i dettagli app utilizzando questo link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nl'amministratore del Developer Portal {consumerOrg} ha sospeso l'app\n{appName}. Le chiamate API da questa app saranno bloccate dal gateway API.\n\nÈ possibile visualizzare i dettagli app utilizzando questo link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nl'utente {originator} ha richiesto l'approvazione per iscriversi al piano {planName} \nper la versione {productVersion} del prodotto API {productName} nel catalogo {catalog}\n.\n\nÈ possibile aprire la richiesta nell'elenco di attività utilizzando questo link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nl'utente {originator} ha richiesto l'approvazione per sottoscrivere con l'applicazione {appName}\nil piano {planName} per la versione {productVersion} del prodotto API {productName} \nnel catalogo {catalog}.\n\nÈ possibile aprire la richiesta nell'elenco di attività utilizzando questo link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Ciao {firstName},\n\nl'utente {originator} ha richiesto l'approvazione per {action} l'applicazione {appName} nel catalogo\n{catalog} .\n\nÈ possibile aprire la richiesta nell'elenco di attività utilizzando questo link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nl'utente {originator} ha richiesto l'approvazione a {action} la versione {version}\ndel prodotto API {productName} nel catalogo {catalog}.\n\nÈ possibile aprire la richiesta nell'elenco di attività utilizzando questo link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Salve {{firstName}},\n\nAbbiamo ricevuto una richiesta di reimpostare la password per l'account utente \n{{username}} nel Developer Portal {{catalog}}.\n\nSe la richiesta non è stata effettuata, ignorare questo messaggio. Altrimenti, \nè possibile reimpostare la password utilizzando questo link:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Salve {firstName},\n\nAbbiamo ricevuto una richiesta di reimpostare la password per l'account utente \n{username} in IBM API Connect.\n\nUtilizzare questo link per reimpostare la propria password:\n\n{link}\n\n(Se la richiesta non è stata effettuata dall'utente, questo messaggio può essere ignorato.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nAbbiamo ricevuto una richiesta di reimpostare la password per l'account utente \n{username} nel Developer Portal {catalog}.\n\nSe non è stata effettuata la richiesta, ignora questo messaggio. Altrimenti, \nè possibile reimpostare la password utilizzando questo link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Ciao {firstName},\n\nLa tua richiesta di creare una sottoscrizione per l'applicazione {appName} nel catalogo\n{catalog} è in attesa di approvazione. Invieremo un'altra notifica \nquando la richiesta sarà stata gestita.\n\nÈ possibile controllare lo stato dell'applicazione qui:\n\n{link}\n\nSe in qualsiasi momento si desidera annullare la richiesta, utilizzare questo link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Ciao {{firstName}},\n\nLa tua richiesta di creare una sottoscrizione per l'applicazione {{appName}} sul piano\n{{planName}} per il prodotto API {{productName}} versione\n{{productVersion}} è stata approvata.\n\n{{#if comments}}\nL'approvatore ha incluso i seguenti commenti:\n\n"{{comments}}"\n\n{{/if}}\nÈ possibile verificare lo stato dell'app qui:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Ciao {firstName},\n\nLa tua richiesta di creare una sottoscrizione per l'applicazione {appName} sul piano\n{planName} per il prodotto API {productName} versione\n{productVersion} è stata approvata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n{comments}\n\n{/if}\nÈ  possibile verificare lo stato dell'app qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Ciao {{firstName}},\n\nLa tua richiesta di creare una sottoscrizione per l'applicazione {{appName}} sul piano\n{{planName}} per il prodotto API {{productName}} versione\n{{productVersion}} è stata negata.\n\n{{#if comments}}\nL'approvatore ha incluso i seguenti commenti:\n\n"{{comments}}"\n\n{{/if}}\nÈ possibile verificare lo stato dell'app qui:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Salve {{firstName}},\n\nLa richiesta di {{action}} il prodotto API {{productName}}:{{version}} nel catalogo \n{{catalog}} è stata approvata.\n\n{{#if comments}}\nL'approvatore ha incluso i seguenti commenti:\n\n{{comments}}\n\n{{/if}}\nÈ possibile verificare lo stato del prodotto qui:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} il prodotto API {productName}:{version} nel catalogo \n{catalog} è stata approvata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n{comments}\n\n{/if}\nÈ  possibile verificare lo stato del prodotto qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Salve {firstName}},\n\nla richiesta di {action}} il prodotto API {productName}:{version} nel catalogo \n{catalog} è stata negata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n{comments}\n\n{/if}\nÈ  possibile verificare lo stato del prodotto qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} il prodotto API {productName}:{version} nel catalogo \n{catalog} è stata negata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n{comments}\n\n{/if}\nÈ  possibile verificare lo stato del prodotto qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} il prodotto API {productName}:{version} nel catalogo \n{catalog} è in attesa di approvazione Invieremo un'altra notifica \nquando la richiesta sarà stata gestita.\n\nÈ  possibile verificare lo stato del prodotto qui:\n\n{link}\n\nSe in qualsiasi momento si desidera annullare la richiesta, utilizzare questo link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} l'app {appName} nel Developer Portal \n{consumerOrg} è stata approvata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n"{comments}"\n\n{/if}\nÈ  possibile verificare lo stato dell'app qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} l'app {appName} nel Developer Portal \n{consumerOrg} è stata approvata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n{comments}\n\n{/if}\nÈ  possibile verificare lo stato dell'app qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} l'app {appName} nel Developer Portal\n{consumerOrg} è stata negata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n"{comments}"\n\n{/if}\nÈ  possibile verificare lo stato dell'app qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} l'app {appName} nel Developer Portal\n{consumerOrg} è stata negata.\n\n{#if commenti}\nL'approvatore ha incluso i seguenti commenti:\n\n{comments}\n\n{/if}\nÈ  possibile verificare lo stato dell'app qui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Salve {firstName},\n\nLa richiesta di {action} l'app {appName} nel Developer Portal {consumerOrg}\nè in attesa di approvazione. Invieremo un'altra notifica \nquando la richiesta sarà stata gestita.\n\nSe in qualsiasi momento si desidera annullare la richiesta, utilizzare questo link:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore account per IBM API Connect ha invitato l'utente a creare\nun organizzazione provider API. Un'organizzazione provider consente all'utente e al\nteam di sviluppare le API e di gestire i cataloghi dei prodotti API.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore per il catalogo API {catalog} appartenente all'organizzazione provider API \n{org} in IBM API Connect, ha invitato\na creare uno spazio nel catalogo. Uno spazio consente all'utente e al team di\ngestire una serie di prodotti API in un catalogo API.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore per il catalogo API {catalog} appartenente all'organizzazione provider API\n{org} in IBM API Connect, ha invitato l'utente\nal catalogo.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore per il Developer Portal del {catalog} ha invitato\nl'utente a creare un'organizzazione API consumer. Un'organizzazione consumer\nconsente all'utente e al team di accedere alle API e di registrare le app client.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore per l'organizzazione consumer API {consumerOrg} nel Developer Portal {catalog}\nha invitato l'utente all'organizzazione.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore per l'organizzazione provider API {org} in IBM API\nConnect ha invitato l'utente a creare un catalogo API. Un catalogo consente all'utente\ne al team di pubblicare e gestire una raccolta di prodotti API.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore per l'organizzazione provider API {org} in IBM API\nConnect ha invitato l'utente all'organizzazione.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore per lo spazio {space} nel catalogo API {catalog},\nappartenente all'organizzazione provider API {org} in IBM API Connect,\nha invitato l'utente allo spazio.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Salve,\n\nLa password per l'account utente {username}\nin IBM API Connect è stata modificata correttamente.\n\nÈ possibile accedere a API Connect qui:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Salve,\n\nLa password per l'account utente {username}\nnel Developer Portal  {portalTitle} è stata modificata.\n\nÈ  possibile accedere al Developer Portal {portalTitle} qui:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Salve,\n\nL'amministratore del sistema per IBM API Connect ha invitato l'utente all'organizzazione \n{org}.\n\nPer continuare, utilizzare il link riportato di seguito:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Salve,\n\nquesto è un messaggio di prova da IBM API Connect dal server e-mail configurato\n{mailServer}.\n\nSe si è ricevuto questo messaggio come previsto, il test è riuscito!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Salve,\n\nL'utente {originator} ha annullato la richiesta di sottoscrivere  il piano {planName} per la\nversione {productVersion} del prodotto API {productName} nel catalogo {catalog}.\n\nNon è richiesta nessuna ulteriore azione.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Salve,\n\nL'utente {originator} ha annullato la richiesta di {action} l'app {appName} nel catalogo {catalog}.\n\nNon è richiesta nessuna ulteriore azione.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Salve,\n\nL'utente {originator} ha ritirato la richiesta di {action} versione {version} del prodotto API {productName}\nnel catalogo {catalog}.\n\nNon è richiesta nessuna ulteriore azione.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Salve, questo è un messaggio di prova da IBM API Connect dal server e-mail {{{mailServer}}} configurato. Se si è ricevuto questo messaggio come previsto, il test è riuscito`,
  [`Hello|header`]: `Salve`,
  [`Help`]: `Guida`,
  [`Hi, Steve (todo)`]: `Ciao, Steve (todo)`,
  [`Hide`]: `Nascondi`,
  [`Hide field`]: `Nascondi campo`,
  [`Hide in schema`]: `Nascondi nello schema`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Nascondere i nuovi tipi, i campi, le direttive o gli argomenti facoltativi. Gli elementi non più supportati dal backend verranno rimossi.`,
  [`High`]: `Alto`,
  [`History`]: `Cronologia`,
  [`Histories`]: `Storie`,
  [`History log`]: `Log cronologia`,
  [`Home`]: `Home`,
  [`Host`]: `Host`,
  [`Host context variable`]: `Variabile di contesto host`,
  [`Host name`]: `Nome host`,
  [`Host value (if present)`]: `Valore Host (se presente)`,
  [`Hostname`]: `Nome host`,
  [`Hours`]: `Ore`,
  [`Http basic authentication schema`]: `Schema di autenticazione di base Http`,
  [`Hmm, your search didn't return any results.`]: `La ricerca non ha restituito alcun risultato.`,
  [`HTTP(s)`]: `HTTP (s)`,
  [`IBM API Connect cloud`]: `IBM API Connect cloud`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud Authentication`,
  [`IBM Cloud Docs`]: `Documentazione IBM Cloud`,
  [`IBM Schema`]: `Schema IBM`,
  [`IBM managed`]: `Gestito da IBM`,
  [`ID Name`]: `Nome ID`,
  [`ID token custom claims`]: `Richieste personalizzate ID token`,
  [`ID token issuer`]: `Autorità emittente ID token`,
  [`ID token signing algorithm`]: `Algoritmo di firma ID token`,
  [`ID token signing crypto object`]: `ID token che firma l'oggetto crittografico`,
  [`ID token signing key`]: `Chiave di firma ID token`,
  [`ID token signing key identifier`]: `Identificativo chiave di firma ID token`,
  [`IDENTITY PROVIDER`]: `PROVIDER DI IDENTITÀ`,
  [`IDENTITY PROVIDER SUPPORT`]: `SUPPORTO PROVIDER DI IDENTITÀ`,
  [`Identification`]: `Identificazione`,
  [`Identity extraction`]: `Estrazione identità`,
  [`If`]: `Se`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Se la modalità di produzione è disabilitata, e questo è Catalogo di sviluppo, tutte le operazioni di pubblicazione vengono forzate e gli eventuali conflitti vengono risolti automaticamente, consentendo, ad esempio, di pubblicare ripetutamente la stessa versione del prodotto durante il test.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Se la modalità di produzione è abilitata, e questo è Catalogo di sviluppo, non sarà consentito di pubblicare un prodotto nel catalogo se vi sono conflitti con i prodotti già pubblicati; è necessario risolvere eventuali conflitti prima di riprovare l'operazione di pubblicazione.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Se in un catalogo sono abilitati gli spazi, è possibile gestire i membri all'interno dello spazio. Si gestisce l'appartenenza allo spazio aggiungendo i nuovi utenti allo spazio ed assegnando i ruoli agli utenti.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Se abilitata, le proprietà di input con un valore null verranno associate al documento di output. Altrimenti, tali proprietà verranno omesse dall'output.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Se la modalità di produzione è abilitata, qualsiasi azione di staging e pubblicazione non sarà forzata. Eventuali conflitti verranno risolti automaticamente dal sistema. Le azioni di annullamento della pubblicazione verranno eseguite automaticamente.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Se i destinatari non hanno ricevuto l'e-mail, controllare le impostazioni del server di posta elettronica.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Se i destinatari non hanno ricevuto l'e-mail, controllare le impostazioni del server di posta elettronica di {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Se impostato su un valore true, tutti i caratteri + nei valori del parametro della query dell'url di destinazione delle politiche Invoke e Proxy sono codificati in %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Se si imposta su true, tutti i parametri della richiesta a cui la definizione della variabile fa riferimento in un url di destinazione invoke sono decodificati tramite URL.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Se si imposta su true, la politica invoke invia un payload su un metodo HTTP DELETE.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Se impostata su true, la proprietà verrà revisionata o rimossa dai log attività.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Se impostata su true, la proprietà verrà revisionata o rimossa dall'input all'inizio dell'esecuzione.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Se impostata su true, la proprietà verrà revisionata o rimossa dalla risposta.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Se in un catalogo sono abilitati gli spazi, è possibile gestire l'accesso di cui dispongono gli utenti nell'ambito dello spazio. Si gestisce l'accesso specificando le autorizzazioni che vengono assegnate ai ruoli utente. Le autorizzazioni che vengono assegnate ad ogni ruolo per impostazione predefinita quando si crea un nuovo spazio vengono determinate dalle impostazioni nel template delle autorizzazioni dello spazio predefinito.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Se la casella di spunta è selezionata (l'opzione predefinita), gli spazi dei nomi XML vengono ereditati dall'elemento parent. Deselezionare la casella di spunta se si desidera che la politica di associazione utilizzi gli spazi dei nomi espliciti.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Se la casella di spunta è selezionata (opzione predefinita), gli spazi dei nomi XML verranno inseriti nel documento nel punto in cui vengono utilizzati per la prima volta. Annullare la selezione della casella di spunta se si desidera che tutti gli spazi dei nomi siano definiti nell'elemento root.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Se la casella di spunta è selezionata (l'opzione predefinita), gli elementi XML vuoti sono inclusi nell'output della politica di associazione. Deselezionare la casella di controllo se non si desidera che gli elementi XML vuoti siano inclusi nell'output della politica di associazione.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Se è selezionata la casella di spunta, è abilitata la post-elaborazione dell'output JSON associato. La post-elaborazione dell'output JSON utilizzerà lo schema di output per garantire che i valori della proprietà siano dello stesso tipo di quello definito nello schema. Inoltre normalizzerà i valori della proprietà di output che hanno una sintassi JSON Badgerfish a causa dell'associazione di oggetti di un input XML. Deselezionare la casella di spunta (impostazione predefinita) per non compiere la post-elaborazione dell'output JSON associato.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Se è selezionata la casella di spunta (opzione predefinita) i riferimenti alla variabile API Connect trovati nelle proprietà di associazione verranno risolti. Deselezionare la casella di spunta se si desidera che la politica di associazione ignori i riferimenti alla variabile API Connect nelle politiche di associazione.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Se la casella di spunta è selezionata, i riferimenti alla variabile API Connect trovati nelle proprietà di associazione verranno risolti. Deselezionare la casella di spunta (opzione predefinita) se si desidera che la politica di associazione ignori i riferimenti alla variabile API Connect nelle politiche di associazione.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Se è selezionata la casella di spunta (opzione predefinita) se un array è rilevato nel mezzo di un input, verrà utilizzato solo il primo elemento. Deselezionare la casella di spunta se si desidera che la politica di associazione utilizzi tutti gli elementi dell'array.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Se la casella di spunta è selezionata, gli elementi XML il cui schema è configurato come tipo booleano o numerico verranno convertiti in tale tipo di dati. Se non viene selezionata (impostazione predefinita) tutti i valori dell'elemento XML verranno restituiti come stringa.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Se è selezionata la casella di spunta, la valutazione dei tipi di schema complessi gestirà i riferimenti di tipo circolare in modo ottimizzato. Deselezionare questa casella di spunta (opzione predefinita) per valutare questi tipi di schema in modo standard.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Se l'errore è causato da una mancanza di supporto CORS, fare clic sul link per aprire il server in una nuova scheda. Se il browser visualizza un problema di certificato, è possibile scegliere di accettarlo e tornare in questo punto per verificare nuovamente.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Se è selezionata questa casella di spunta, l'array di output o la proprietà child di un oggetto facoltativo indicato nello schema come obbligatorio dovranno richiedere che le proprietà dell'oggetto child siano inizializzate su un valore predefinito a meno che non sia associato correttamente. Ciò emulerà in che modo è stata eseguita l'associazione della versione 4 alle proprietà obbligatorie per tali oggetti. Deselezionare questa casella di spunta (il valore predefinito) solo per l'output degli oggetti con i dati associati correttamente.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Se questa casella di spunta è selezionata (impostazione predefinita) e l'utente proprietario è stato modificato, anche la proprietà di qualsiasi risorsa child (spazi) verrà trasferita al nuovo proprietario.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Se è selezionata questa opzione, la politica di associazione scriverà l'output XML in message.body come un documento XML analizzato. Per impostazione predefinita, l'XML sarà l'output come una stringa XML. L'output XML per qualsiasi altra variabile sarà sempre scritta come una stringa XML.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Se si abilita l'approvazione del ciclo di vita per le azioni di staging, pubblicazione, o sostituzione, alcune operazioni di creazione, modifica o esecuzione del test che eseguono queste azioni automaticamente potrebbero non funzionare correttamente. Si è sicuri di voler abilitare queste approvazioni?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Se si dispone di un servizio SOAP esistente che si desidera esporre mediante una definizione API API Connect, è possibile creare un'API e specificare il servizio SOAP di destinazione. Quindi, API Connect creerà una definizione API che richiama il servizio SOAP.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Se si salvano le modifiche facendo clic su "Sì. Aggiorna Assemblaggio API", verrà aggiornato anche l'assemblaggio API sottostante rispetto a questo provider OAuth. Tuttavia, se l'assembly API sottostante è stato personalizzato e si desidera aggiornarlo separatamente, fare clic su "No. Non aggiornare l'assembly API", quindi aggiornare l'assembly API sottostante nell'API Editor.`,
  [`If you are still having issues, upload a schema definition language file.`]: `In caso di ulteriori problemi, caricare un file SDL (Schema Definition Language).`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Se il proprio file contiene entrambe le chiavi pubblica e privata, caricarlo nel passo 1`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Se è necessario registrare un gateway protetto da firewall, consultare la documentazione IBM Cloud per informazioni sull'utilizzo di una VPN o del servizio Secure Gateway per stabilire la connessione.`,
  [`Ignore`]: `Ignora`,
  [`Implement policy`]: `Implementa politica`,
  [`Implementation file is required`]: `Il file di implementazione è obbligatorio`,
  [`Implicit`]: `Implicita`,
  [`Import`]: `Importa`,
  [`Import API`]: `Importa API`,
  [`Import API|title`]: `Importa API`,
  [`Import OpenAPI definition from asset repository`]: `Importa definizione OpenAPI dal repository asset`,
  [`Import Product`]: `Importa prodotto`,
  [`Import policy`]: `Importa politica`,
  [`Import a product from a service URL`]: `Importa un prodotto da un URL di servizio`,
  [`Import a product from the definition file`]: `Importa un prodotto dal file di definizione`,
  [`Import an existing product`]: `Importare un prodotto esistente`,
  [`Import an OpenAPI definition from a URL`]: `Importa una definizione OpenAPI da un URL`,
  [`Import an OpenAPI definition from a file`]: `Importa una definizione OpenAPI da un file`,
  [`Import definitions`]: `Importa definizioni`,
  [`Import from existing API service`]: `Importa da un servizio API esistente`,
  [`Import from file`]: `Importa da file`,
  [`Importing an API definition`]: `Importazione di una definizione API`,
  [`Import|title`]: `Importa`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Importare i certificati richiesti ed i bundle CA nel servizio Certificate Manager.`,
  [`In Cookie Header`]: `Nell'intestazione cookie`,
  [`In WSSec Header`]: `Nell'intestazione WSSec`,
  [`In order to invoke the endpoints above, you need to first`]: `Per richiamare gli endpoint indicati sopra, è necessario prima`,
  [`Inactive`]: `Inattivo`,
  [`Inc`]: `Inc`,
  [`Include empty`]: `Includi elemento vuoto`,
  [`Include free trial days`]: `Includi giorni di prova gratuiti`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Non è possibile selezionare api incompatibili con il servizio gateway configurato.`,
  [`Incorrect username or password`]: `Nome unte o password non corretti`,
  [`Incorrect username, password, or credentials`]: `Nome utente, password o credenziali non corretti`,
  [`Indicate if the invoke is the final action`]: `Indica se l'azione di richiamo è l'azione finale`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Indica se aggiungere una richiesta jti (JWT ID) al JWT. Se selezionato, il valore della richiesta jti sarà un UUID.`,
  [`Indicates whether the clients secret is required.`]: `Indica se il segreto dei client è obbligatorio.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Indica se questo documento di input XSLT utilizza il payload corrente del contesto oppure se non è presente alcun input.`,
  [`Indicates whether to stop processing if client security fails.`]: `Indica se arrestare l'elaborazione se esiste un problema di sicurezza client.`,
  [`Infer from JSON schema`]: `Inferire dallo schema JSON`,
  [`Infer from Sample`]: `Inferisci dall'esempio`,
  [`Info`]: `Informazioni`,
  [`Information context variable`]: `Variabile di contesto informazioni`,
  [`Inject proxy headers`]: `Immettere intestazioni proxy`,
  [`Inline schema`]: `Schema in linea`,
  [`Input`]: `Input`,
  [`Input field`]: `Campo di input`,
  [`Input object`]: `Oggetto di input`,
  [`Insecure Server Connections`]: `Connessioni al server non protette`,
  [`Install API Connect CLI & API Designer|button text`]: `Installa CLI API Connect & API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `Installa CLI API Connect & API Designer`,
  [`Install API Connect Toolkit`]: `Installa il Toolkit di API Connect`,
  [`Install the API Connect plugin`]: `Installa il plugin API Connect`,
  [`Instance ID`]: `ID istanza`,
  [`Instance name`]: `Nome istanza`,
  [`Instance owner`]: `Proprietario dell'istanza`,
  [`Interact to expand Tile`]: `Interagire per espandere il riquadro`,
  [`Interact to collapse Tile`]: `Interagire per comprimere il riquadro`,
  [`Interval`]: `Intervallo`,
  [`Interface`]: `Interfaccia`,
  [`Introspect`]: `Esegui introspezione`,
  [`Introspect cache type`]: `Esegui introspezione del tipo di cache`,
  [`Introspect from Server URL`]: `Esegui introspezione dall'URL del server`,
  [`Introspect from URL`]: `Esegui introspezione dall'URL`,
  [`Introspect URL`]: `URL di introspezione`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `L'esecuzione dell'introspezione con un URL diverso non modificherà l'URL del server GraphQL.`,
  [`Introspect token`]: `Token di introspezione`,
  [`Introspection`]: `Introspezione`,
  [`Introspection Path`]: `Percorso di introspezione`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Esegui introspezione dello schema GraphQL dal server o carica lo schema`,
  [`Introspect the GraphQL schema using the query`]: `Esegui introspezione dello schema GraphQL utilizzando la query`,
  [`Introspection URL`]: `URL di introspezione`,
  [`Invalid HOST URL`]: `URL HOST non valido`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `URL non valido. Inserisci l'URL HOST di API Manager valido. ad esempio https://api-connect-host.com`,
  [`Invalid file URL`]: `URL file non valido`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Formato URL non valido. Deve iniziare con http:// o https:// e includere almeno 1 carattere`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Formato URL non valido, è necessario specificare solo il nome host. È possibile rimuovere syslog://`,
  [`Invalid emails.`]: `E-mail non valide.`,
  [`Invalid JSONata expression`]: `Espressione JSONata non valida`,
  [`Invalid remove target`]: `Destinazione rimozione non valida`,
  [`Invalid assumed size`]: `Dimensione presunta non valida`,
  [`Invalid cost target`]: `Destinazione costo non valida`,
  [`Invalid list size`]: `Dimensione elenco non valida`,
  [`Invalid port value, must not be negative`]: `Valore della porta non valido, non deve essere negativo`,
  [`Invalid sized fields`]: `Campi dimensionati non validi`,
  [`Invalid value, length has to be greater than 1`]: `Valore non valido, la lunghezza deve essere maggiore di 1`,
  [`Invalid weight value`]: `Valore peso non valido`,
  [`invitation`]: `invito`,
  [`Invitation`]: `Invito`,
  [`Invitation timeout`]: `Timeout invito`,
  [`Invitation link will expire in {time}`]: `Il link di invito scade tra **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Il link di invito scade tra **{seconds} secondi ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Il link di invito scade tra __{value} {unit}__.`,
  [`Invitation sent to`]: `Invito inviato a`,
  [`Invitation sent to {email}`]: `Invito inviato a {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Invito ad un catalogo API in IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Invito ad un'organizzazione consumer API nel Developer Portal {catalog}`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Invito ad un'organizzazione provider API in IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Invito ad uno spazio API in IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Invito ad un'organizzazione admin in IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Invito a creare un catalogo API in IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Invito a creare un'organizzazione consumer API nel Developer Portal {catalog}`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Invito a creare un'organizzazione provider API in IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Invito a creare uno spazio API in IBM API Connect`,
  [`Invitation ttl has been created.`]: `È stato creato l'invito ttl.`,
  [`Invitation ttl has been updated.`]: `Il TTL di invito è stato aggiornato.`,
  [`Invitation ttl has not been created!`]: `L'invito ttl non è stato creato!`,
  [`Invitation ttl has not been updated!`]: `Il TTL di invito non è stato aggiornato.`,
  [`Invite`]: `Invita`,
  [`Invite catalog owner`]: `Invita proprietario del catalogo`,
  [`Invite consumer organization owner`]: `Invita proprietario organizzazione consumer`,
  [`Invite member`]: `Invita membro`,
  [`Invite organization owner`]: `Invita proprietario dell'organizzazione`,
  [`Invite space owner`]: `Invita proprietario dello spazio`,
  [`Invite a new consumer organization owner`]: `Invita un nuovo proprietario dell'organizzazione consumer`,
  [`Invite a new member`]: `Invita un nuovo membro`,
  [`Invite a new provider organization owner`]: `Invita un nuovo proprietario dell'organizzazione provider`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Invitare un nuovo utente a registrarsi come gestore di un catalogo immettendo l'indirizzo e-mail del destinatario. Viene inviata una e-mail di invito all'utente con un link al modulo di registrazione`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Consente di invitare un nuovo utente a registrarsi come gestore di un catalogo immettendo l'indirizzo e-mail del destinatario. All'utente viene inviata una e-mail di invito con un link al modulo di registrazione.`,
  [`Invite by email`]: `Invita mediante e-mail`,
  [`Invite from User Registry`]: `Invita da registro utente`,
  [`Invite team member`]: `Invita il membro del team`,
  [`Inviting members to consumer organizations is enabled`]: `L'invito dei membri alle organizzazioni consumer è abilitato`,
  [`Invocations`]: `Richiami`,
  [`Invoke`]: `Richiama`,
  [`Issuer Claim`]: `Richiesta emittente`,
  [`Issuer Origin`]: `Origine emittente`,
  [`Issuer common name`]: `Nome comune emittente`,
  [`It might influence how the API is deployed`]: `Potrebbe influire sulla distribuzione dell'API`,
  [`Italian`]: `Italiano`,
  [`Items per page`]: `Elementi per pagina`,
  [`Items per page:`]: `Elementi per pagina:`,
  [`items selected`]: `elementi selezionati`,
  [`item selected`]: `elemento selezionato`,
  [`Iterate on`]: `Esegui iterazione su`,
  [`Iterate?`]: `Iterare?`,
  [`JSON objects describing re-usable channel parameters.`]: `Oggetti JSON che descrivono parametri di canale riutilizzabili.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `Oggetti JSON che descrivono i messaggi utilizzati e prodotti dall'API.`,
  [`JSON Schema URL`]: `URL schema JSON`,
  [`JSON Schema preview`]: `Anteprima schema JSON`,
  [`JSON Web Token (JWT)`]: `JWT (JSON Web Token)`,
  [`JSON has been successfully validated`]: `JSON è stato convalidato correttamente`,
  [`JSON schema is not valid. Please correct the error.`]: `Lo schema JSON non è valido. Correggere l'errore.`,
  [`JSON to XML`]: `JSON a XML`,
  [`JWT ID Claim`]: `Richiesta JWT ID`,
  [`JWT verification JWK`]: `JWK di verifica JWT`,
  [`JWT verification crypto object`]: `Oggetto di crittografia della verifica JWT`,
  [`Japanese`]: `Giapponese`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Coda lavori`,
  [`Jump to source`]: `Passa all'origine`,
  [`Just now`]: `Ora`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `SCAMBIO CHIAVE / CODIFICA / MAC`,
  [`KEY SIZE`]: `DIMENSIONE CHIAVE`,
  [`Keystore`]: `Keystore`,
  [`keystore`]: `keystore`,
  [`Keep`]: `Conserva`,
  [`Keep Payload`]: `Conserva Payload`,
  [`Key Encryption Algorithm`]: `Algoritmo di codifica della chiave`,
  [`Key Name`]: `Nome chiave`,
  [`Key type`]: `Tipo di chiave`,
  [`Key name`]: `Nome chiave`,
  [`Keystore for the token has been updated.`]: `Il keystore per il token è stato aggiornato.`,
  [`Keystore Certificates`]: `Certificati keystore`,
  [`Keystore/truststore`]: `Keystore/truststore`,
  [`Korean`]: `Coreano`,
  [`LABEL`]: `ETICHETTA`,
  [`LABELS`]: `ETICHETTE`,
  [`LAST MODIFIED`]: `ULTIMA MODIFICA`,
  [`Last state changed`]: `Ultima modifica dello stato`,
  [`Large`]: `Grande`,
  [`Lax`]: `Lax`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `Gruppi LDAP`,
  [`LDAP user registry`]: `Registro utente LDAP`,
  [`LDAP registry`]: `Registro LDAP`,
  [`LDAP registry name`]: `Nome registro LDAP`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Il registro utenti LDAP del ruolo {{name}} non supporta più la mappatura del gruppo esterno. Abilitare la mappatura del gruppo esterno per questo registro utenti o disabilitare la mappatura del gruppo esterno nel ruolo prima del salvataggio.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Il registro utenti LDAP del ruolo {{name}} non supporta più la mappatura del gruppo esterno. Selezionare un altro registro utenti con la mappatura del gruppo esterno o disabilitare la mappatura del gruppo esterno nel ruolo prima del salvataggio.`,
  [`LDAP search attribute`]: `Attributo di ricerca LDAP`,
  [`LOCATED IN`]: `UBICATA IN`,
  [`Located in`]: `Ubicato in`,
  [`LTPA Key`]: `Chiave LTPA`,
  [`LTPA Key Details`]: `Dettagli chiave LTPA`,
  [`LTPA Keys`]: `Chiavi LTPA`,
  [`LTPA key file`]: `File della chiave LTPA`,
  [`LTPA key password`]: `Password della chiave LTPA`,
  [`LUR data version`]: `Versione dati LUR`,
  [`LUR schema update date`]: `Data aggiornamento schema LUR`,
  [`LUR schema version`]: `Versione schema LUR`,
  [`LUR target data version`]: `Versione dati di destinazione LUR`,
  [`LUR target schema version`]: `Versione schema di destinazione LUR`,
  [`LUR update date`]: `Data aggiornamento LUR`,
  [`Last name`]: `Cognome`,
  [`Last modified`]: `Ultima modifica`,
  [`Launch portal`]: `Avvia portale`,
  [`Launch IBM Automation management console`]: `Avvia la console di gestione di IBM Automation`,
  [`Launch plan upgrade`]: `Avvia aggiornamento del piano`,
  [`Learn more`]: `Ulteriori informazioni`,
  [`Learn More`]: `Ulteriori informazioni`,
  [`Legacy`]: `Legacy`,
  [`Length cannot be below {min}`]: `La lunghezza non può essere inferiore a {min}`,
  [`Length cannot exceed {max}`]: `La lunghezza non può superare {max}`,
  [`Let's customize API Connect`]: `Personalizzare API Connect`,
  [`Let's get started...`]: `Primi passi...`,
  [`Let's get you up and running`]: `Pronti per iniziare`,
  [`License`]: `Licenza`,
  [`Licensed Materials - Property of IBM`]: `Materiali su licenza - Proprietà di IBM`,
  [`Lifecycle`]: `Ciclo di vita`,
  [`Lifecycle approvals`]: `Approvazioni del ciclo di vita`,
  [`Lifecycle actions have been updated`]: `Le azioni del ciclo di vita sono state aggiornate`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Le approvazioni del ciclo di vita sono abilitate per il catalogo Sandbox. Per utilizzare l'opzione Attivazione automatica API, è necessario prima disabilitare le approvazioni del ciclo di vita nelle impostazioni Catalogo Sandbox.`,
  [`Limited fields`]: `Campi limitati`,
  [`Limit API calls on a per key basis`]: `Limita chiamate API in base alla chiave`,
  [`Limit fields`]: `Limita campi`,
  [`Limit renegotiation`]: `Rinegoziazione limite`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Caricamento`,
  [`Load query`]: `Query di caricamento`,
  [`Load filter`]: `Carica filtro`,
  [`Load schema from WSDL`]: `Carica schema da WSDL`,
  [`Loading`]: `Caricamento`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `I registri utente locali (LUR, Local User Registries) sono i registri utente predefiniti inclusi in API Connect e sono memorizzati in locale.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `I registri utente locali (Local User Registry) sono i registri utente predefiniti inclusi in API Connect ed archiviati in locale. Durante l'installazione di API Connect, vengono installati e configurati due LUR. È possibile configurare ulteriori LUR.`,
  [`Local user registry`]: `Registro utente locale`,
  [`Local User Registry has been created.`]: `Creato Registro utente URL locale`,
  [`Location`]: `Ubicazione`,
  [`Location of ciphertext to decrypt`]: `Posizione del testo crittografato da decodificare`,
  [`Location of plaintext information to encrypt`]: `Posizione delle informazioni in testo normale da codificare`,
  [`Log`]: `Log`,
  [`Log in`]: `Accedi`,
  [`Log in to:`]: `Accedere per:`,
  [`Log in with a different account`]: `Accedere con un differente account`,
  [`Log into IBM Cloud`]: `Accedi a IBM Cloud`,
  [`Log out`]: `Disconnetti`,
  [`Log in using the {userRegistryType} user registry`]: `Accedi utilizzando il registro utente {userRegistryType}`,
  [`Logging in with IBM Cloud ...`]: `Accesso a IBM Cloud in corso ...`,
  [`Logging out`]: `Scollegamento in corso`,
  [`Logic`]: `Logica`,
  [`Login`]: `Login`,
  [`Logs`]: `Log`,
  [`Longer, descriptive name for this policy.`]: `Nome descrittivo più esteso per questa politica.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Si è verificato un errore sul server durante l'elaborazione della richiesta.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Server e-mail`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `MEMBRO`,
  [`MIME Types`]: `Tipi MIME`,
  [`Mail server has been deleted.`]: `Il server di posta è stato eliminato.`,
  [`Mail server has not been deleted.`]: `Il server di posta non è stato eliminato.`,
  [`Mailserver {arg} has been removed.`]: `Il server di posta {arg} è stato rimosso.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Accertarsi che il server sia in esecuzione e che sia disponibile la connettività di rete.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Le cause comprendono una mancanza del supporto CORS sul server di destinazione, il server non è disponibile, è stato rilevato un certificato non attendibile o è obbligatoria l'autenticazione SSL reciproca.`,
  [`This might be caused by one or more of the following:`]: `Questo può essere causato da uno o più dei seguenti motivi:`,
  [`Make a call and move on`]: `Effettua una chiamata e continua`,
  [`Make a call and wait for response`]: `Effettua una chiamata e attendi la risposta`,
  [`Manage`]: `Gestisci`,
  [`Manage API life cycles`]: `Gestire i cicli di vita delle API`,
  [`Manage API product lifecycle approval permissions`]: `Gestisci autorizzazioni di approvazione del ciclo di vita del prodotto dell'API`,
  [`Manage API product lifecycle approvals permissions`]: `Gestisci autorizzazioni di approvazione del ciclo di vita del prodotto dell'API`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Gestisce le organizzazioni provider API, i gateway locali e remoti e le impostazioni correlate.`,
  [`Manage APIs`]: `Gestisci API`,
  [`Manage Catalogs`]: `Gestisci cataloghi`,
  [`Manage Organizations`]: `Gestisci organizzazioni`,
  [`Manage Resources`]: `Gestisci risorse`,
  [`Manage Settings`]: `Gestisci impostazioni`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Gestisce le destinazioni per l'Analisi API, inclusi API Connect, HTTP, Kafka, Elasticsearch e Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Gestire i gateway locali e remoti che proteggono le API.`,
  [`Manage catalogs`]: `Gestisci cataloghi`,
  [`Manage organizations`]: `Gestione organizzazioni`,
  [`Manage resources`]: `Gestisci risorse`,
  [`Manage settings`]: `Gestisci impostazioni`,
  [`Manage users`]: `Gestire gli utenti`,
  [`Manage active APIs and consumers`]: `Consente di gestire API attive e consumer`,
  [`Manage availability zones and services`]: `Gestire i servizi e le zone di disponibilità`,
  [`Manage policies`]: `Gestisci politiche`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Gestisce le richieste di modifica del ciclo di vita del prodotto e le richieste di aggiornamento dell'applicazione e della sottoscrizione API dagli sviluppatori delle applicazioni.`,
  [`Manage roles and permissions`]: `Gestisci ruoli ed autorizzazioni`,
  [`Manage the OAuth providers configured for API Manager`]: `Gestisci i provider OAuth configurati per API Manager`,
  [`Manage the user registries configured for API Manager`]: `Gestisci i registri utente configurati per API Manager`,
  [`Manage the user registries configured for Cloud Manager`]: `Gestisci i registri utente configurati per Cloud Manager`,
  [`Management`]: `Gestione`,
  [`Management Endpoint`]: `Endpoint di gestione`,
  [`Management endpoint`]: `Endpoint di gestione`,
  [`Management endpoint on the analytics service`]: `Endpoint di gestione sul servizio di analytics`,
  [`Service endpoint configuration`]: `Configurazione endpoint di servizio`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Endpoint utilizzato dal servizio di gestione per inviare informazioni sulla configurazione al servizio del portale`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Profilo client TLS utilizzato dal servizio di gestione durante la comunicazione con il servizio del portale`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Endpoint utilizzato dal servizio di gestione per inviare le informazioni sulla configurazione al servizio gateway`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `Profilo client TLS utilizzato dal servizio di gestione durante la comunicazione con il servizio gateway`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Utilizzare la comunicazione in-cluster per il traffico interno tra il gateway e il servizio di gestione`,
  [`Use in-cluster communication for both ingestion and queries`]: `Utilizzare la comunicazione in-cluster sia per l'inserimento che per le query`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Utilizzare la comunicazione in cluster per l'inserimento e la comunicazione esterna per le query o viceversa`,
  [`Use external communication for both ingestion and queries`]: `Utilizzare la comunicazione esterna sia per l'inserimento che per le query`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Utilizzare la comunicazione in-cluster per il traffico interno tra le analitiche e il servizio di gestione`,
  [`Management endpoint on the gateway service`]: `Endpoint di gestione sul servizio gateway`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Utilizzare la comunicazione in-cluster per il traffico interno tra il portale e il servizio di gestione`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Se si desidera utilizzare la comunicazione in-cluster tra i sottosistemi di gestione e del portale, abilitare questo switch. Se non si è sicuri, lasciare questo switch disabilitato (impostazione predefinita).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Se si desidera utilizzare la comunicazione in-cluster tra i sottosistemi di gestione e del gateway, abilitare questo switch. Se non si è sicuri, lasciare questo switch disabilitato (impostazione predefinita).`,
  [`Management endpoint on the portal service`]: `Endpoint di gestione sul servizio portale`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Questa opzione è quella predefinita e viene utilizzata per i servizi di analitiche aggiornati da una release pre-10.0.5.3. Per le distribuzioni OVA o se il sottosistema di analitiche si trova in un ambiente diverso, l'unica opzione che è possibile utilizzare è esterno. Sia il gateway che il servizio di gestione comunicano con il servizio di analitiche utilizzando l'endpoint esterno, che è un ingress Kubernetes o un instradamento OpenShift in base alla piattaforma utilizzata.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `È possibile selezionare in-cluster se si dispone di una distribuzione Kubernetes o OpenShift in cui tutti i sottosistemi si trovano all'interno dello stesso cluster. Quando questa opzione è selezionata, i sottosistemi di gestione, gateway e analitiche comunicano tra loro tramite endpoint di servizio interni invece che tramite ingress (Kubernetes) o instradamenti (OpenShift) accessibili esternamente.`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `È possibile selezionare questa opzione se si dispone di una distribuzione Kubernetes o OpenShift e alcuni dei sottosistemi sono installati nello stesso cluster. Quando questa opzione è selezionata, è possibile utilizzare metodi di comunicazione diversi per i diversi flussi di comunicazione delle analitiche. Ad esempio, la gestione per la comunicazione delle analitiche può utilizzare in-cluster e il gateway per le analitiche può utilizzare esterno. È possibile scegliere questa configurazione se il gateway si trova in un cluster diverso rispetto al resto dei sottosistemi.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Se è stata specificata la comunicazione in-cluster quando è stato registrato il servizio di analitiche, è possibile consentire al gateway di inviare i dati degli eventi API all'endpoint di analitiche interno.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Utilizza endpoint interno per l'inserimento dei dati delle analitiche dal servizio gateway`,
  [`Endpoint configuration`]: `Utilizzare la comunicazione esterna sia per l'inserimento che per le query`,
  [`Portal endpoint configuration`]: `Configurazione dell'endpoint del portale`,
  [`URL used by developer portal users to access the portal website`]: `URL utilizzato dagli utenti del portale dello sviluppatore per accedere al sito Web del portale`,
  [`External ingestion endpoint on the analytics service`]: `Endpoint di inserimento esterno sul servizio di analytics`,
  [`TLS client profile for external analytics ingestion endpoint`]: `Profilo del client TLS per l'endpoint di inserimento di analytics esterno`,
  [`Internal ingestion endpoint on the analytics service`]: `Endpoint di inserimento interno sul servizio di analytics`,
  [`TLS client profile for internal service endpoint`]: `Profilo client TLS per l'endpoint del servizio interno`,
  [`Manager`]: `Gestore`,
  [`Manager Request Reset Password`]: `Reimpostazione password richiesta gestore`,
  [`Manager Reset Password`]: `Reimpostazione password gestore`,
  [`Manager Sign In`]: `Accesso gestore`,
  [`Manages API provider organizations`]: `Gestisce le organizzazioni provider API`,
  [`Manages application developer communities`]: `Gestisce le comunità di sviluppatori dell'applicazione`,
  [`Manages the API product lifecycle`]: `Gestisce il ciclo di vita del prodotto API`,
  [`Manage|link`]: `Gestisci`,
  [`Manage|permission`]: `Gestisci`,
  [`Manage|title`]: `Gestisci:titolo`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Gestisci assegnazione e cronologia del keystore per i token`,
  [`Map`]: `Associazione`,
  [`Map information from OIDC provider to an APIC user`]: `Associare le informazioni del provider OIDC ad un utente APIC`,
  [`Map the migration source plans to the migration target plans`]: `Associa i piani di origine della migrazione ai piani di destinazione della migrazione`,
  [`Map the roles to groups`]: `Associa i ruoli ai gruppi`,
  [`Mapped from: `]: `Associato da: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Ingrandisci al massimo / riduci al minimo`,
  [`Maximum Consent Time to Live (seconds)`]: `TTL (Time to Live) consenso massimo (secondi)`,
  [`Maximum consent`]: `Consenso massimo`,
  [`Menu`]: `Menu`,
  [`Member`]: `Membro`,
  [`Member Invitation`]: `Invito al membro`,
  [`Member Invitation has been deleted.`]: `L'invito del membro è stato eliminato.`,
  [`Member has been deleted.`]: `Il membro è stato eliminato.`,
  [`Member {name} created`]: `Membro {name} creato`,
  [`Member {title} has been created.`]: `Il membro {title} è stato creato.`,
  [`Members`]: `Membri`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Inviti al membro`,
  [`Member Roles`]: `Ruoli membro`,
  [`Merchant ID`]: `ID venditore`,
  [`Merge`]: `Unisci`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Unire questa associazione con altre che operano sullo stesso array di destinazione?`,
  [`Message`]: `Messaggio`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Messaggi per l'analisi`,
  [`Message for resulting parsed data`]: `Messaggio per i dati analizzati risultanti`,
  [`Metadata`]: `Metadati`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `I metadati consentono di raccogliere i metadati personalizzati da includere durante il processo di generazione del token di accesso. È possibile raccogliere i metadati attraverso un URL di autenticazione o un server remoto in cui sono memorizzati i metadati personalizzati, o entrambi.`,
  [`Micro Gateway policies`]: `Politiche Micro Gateway`,
  [`Migrate`]: `Migra`,
  [`Migrate Subscriptions`]: `Migra sottoscrizioni`,
  [`Migrate plans`]: `Migra piani`,
  [`Migrate subscription`]: `Migra sottoscrizione`,
  [`Migrate subscriptions`]: `Migra sottoscrizioni`,
  [`Migration Target`]: `Destinazione della migrazione`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `Eseguita migrazione da "{source}" (Prodotto) a "{target}" (Prodotto)`,
  [`Migration from "{source}" failed`]: `La migrazione "{source}" non è riuscita`,
  [`Migration source`]: `Origine migrazione`,
  [`Migration target`]: `Destinazione migrazione`,
  [`Minimum role`]: `Ruolo minimo`,
  [`Minimum output escaping rule`]: `Regola di escape minimo dell'output`,
  [`Minutes`]: `Minuti`,
  [`Mode`]: `Modalità`,
  [`Modifications`]: `Modifiche`,
  [`Modified`]: `Modificato`,
  [`More`]: `Altro`,
  [`More than 20 events are waiting to be processed`]: `Più di 20 eventi sono in attesa di essere elaborati`,
  [`Move down`]: `Sposta giù`,
  [`Move up`]: `Sposta su`,
  [`Must be a number`]: `Deve essere un numero`,
  [`Must be a valid URI`]: `Deve essere un URI valido`,
  [`Must be a valid email`]: `Deve essere un'e-mail valida`,
  [`Must be a valid path starting with /`]: `Deve essere un path valido che inizi con /`,
  [`Must be a valid url`]: `Deve essere un url valido`,
  [`Must be an integer`]: `Deve essere un numero intero`,
  [`Must be a valid url with no protocol`]: `Deve essere un URL valido senza protocollo`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Deve essere nel formato a 64 caratteri esadecimali (con prefisso "0x")`,
  [`Must start with '/'`]: `Deve iniziare con '/'`,
  [`Must start with https://`]: `Deve iniziare con https://`,
  [`Must start with https:// or $(`]: `Deve iniziare con https:// o $(`,
  [`Mutual auth`]: `Autenticazione reciproca`,
  [`Mutual authentication`]: `Autenticazione reciproca`,
  [`My account`]: `Account personale`,
  [`Name`]: `Nome`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NOT`,
  [`Name cannot be empty.`]: `Il nome non può essere vuoto.`,
  [`Name of Schema in OpenAPI Definitions`]: `Nome dello schema nelle definizioni OpenAPI`,
  [`Name of the LDAP user password attribute.`]: `Nome dell'attributo password utente LDAP.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Nome del registro utente LDAP rispetto al quale eseguire la convalida. Selezionare dall'elenco o immettere manualmente.`,
  [`Name of the organization`]: `Nome dell'organizzazione`,
  [`Namespace inheritance`]: `Ereditarietà spazio dei nomi`,
  [`Namespace inlining`]: `Spazio dei nomi con prefisso inline`,
  [`Native`]: `Nativo`,
  [`Native OAuth provider`]: `Provider OAuth nativo`,
  [`Native OAuth provider summary`]: `Riepilogo provider OAuth nativo`,
  [`Navigate to Source View`]: `Passa alla vista origine`,
  [`New`]: `Nuovo`,
  [`New API`]: `Nuova API`,
  [`New API Connect connection`]: `Nuova connessione API Connect`,
  [`New Application`]: `Nuova applicazione`,
  [`New Catalog`]: `Nuovo catalogo`,
  [`New Consumer organization`]: `Nuova organizzazione consumer`,
  [`New Endpoint`]: `Nuovo endpoint`,
  [`New native OAuth provider`]: `Nuovo provider OAuth nativo`,
  [`New OpenAPI`]: `Nuova OpenAPI`,
  [`New OpenAPI directory`]: `Nuova directory OpenAPI`,
  [`New Organization Title`]: `Nuovo titolo organizzazione`,
  [`New Parameter`]: `Nuovo parametro`,
  [`New Password`]: `Nuova password`,
  [`New product`]: `Nuovo prodotto`,
  [`New Role`]: `Nuovo ruolo`,
  [`New schema`]: `Nuovo schema`,
  [`New Test`]: `Nuovo test`,
  [`New third party OAuth provider`]: `Nuovo provider OAuth di terze parti`,
  [`New user`]: `Nuovo utente`,
  [`New applications will be created in the development state`]: `Le nuove applicazioni saranno create nello stato di sviluppo`,
  [`Next`]: `Avanti`,
  [`Next page`]: `Pagina successiva`,
  [`No`]: `No`,
  [`No account?`]: `Nessun account?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `Nessun URL di autenticazione o registro LDAP configurato nel catalogo sandbox.`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `Nessun URL di autenticazione o registro utente LDAP configurato nel catalogo sandbox, crearne uno [qui]({link})`,
  [`No Billing`]: `Nessuna fatturazione`,
  [`No billing integration has been selected.`]: `Nessuna integrazione di fatturazione selezionata.`,
  [`No billing integration can be shown when offline.`]: `Non può essere visualizzata alcuna integrazione di fatturazione quando si è offline.`,
  [`No gateways`]: `Nessun gateway`,
  [`No cache`]: `Nessuna cache`,
  [`No Cache`]: `Nessuna cache`,
  [`No Certificate Manager services available`]: `Nessun servizio Certificate Manager disponibile`,
  [`No default gateways`]: `Nessun gateway predefinito`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Nessun indirizzo email duplicato presente nel registro utente. Ciò è valido unicamente per gli utenti con email.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Nessun gateway compatibile configurato per il catalogo sandbox. L'API Test(Try it) in Explorer non sarà abilitata.`,
  [`No Errors`]: `Nessun errore`,
  [`No Histories found`]: `Nessuna cronologia trovata`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Nessun servizio gateway trovato. Configurare un servizio gateway per visualizzare le politiche di assemblaggio supportate`,
  [`No gateways are configured for sandbox catalog`]: `Nessun gateway configurato per il catalogo sandbox`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Nessun gateway configurato per il catalogo sandbox. L'API Test(Try it) in Explorer non sarà abilitata`,
  [`No LDAP or authentication URL user registries found.`]: `Nessun registro utente URL di autenticazione o LDAP trovato`,
  [`No limit on size of list`]: `Nessun limite per la dimensione dell'elenco`,
  [`No OAuth providers configured in the sandbox catalog`]: `Nessun provider OAuth configurato nel catalogo sandbox`,
  [`No OAuth providers configured in the selected catalog`]: `Nessun provider OAuth configurato nel catalogo selezionato`,
  [`No Permission`]: `Nessuna autorizzazione`,
  [`No portal added to this catalog`]: `Nessun portale aggiunto a questo catalogo`,
  [`No Roles exist`]: `Nessun ruolo esistente`,
  [`No recent items found.`]: `Nessun elemento recente trovato.`,
  [`No TLS profile`]: `Nessun profilo TLS`,
  [`No TLS client profile`]: `Nessun profilo client TLS`,
  [`No Truststore`]: `Nessun Truststore`,
  [`No analytics service is configured`]: `Nessun servizio analisi configurato`,
  [`No analytics services found. You can create one [here]({link})`]: `Nessun servizio analisi trovato. È possibile crearne uno [qui]({link})`,
  [`No assemblies found.`]: `Nessun assemblaggio trovato.`,
  [`No available extensions`]: `Nessuna estensione disponibile`,
  [`No compatible flows configured in this OAuth provider`]: `Nessun flusso compatibile configurato in questo provider OAuth`,
  [`No consumer organization will be able to see this product.`]: `Nessuna organizzazione consumer sarà in grado di visualizzare questo prodotto.`,
  [`No debug data was found for this API call`]: `Nessun dato di debug è stato trovato per questa chiamata API`,
  [`No details are available.`]: `Nessun dettaglio disponibile.`,
  [`No existing products`]: `Nessun prodotto esistente`,
  [`No extensions have been configured for the API`]: `Nessuna estensione configurata per l'API`,
  [`No flow configured in this OAuth provider`]: `Nessun flusso configurato in questo provider OAuth`,
  [`No items found`]: `Nessun elemento trovato`,
  [`No non-member user to add.`]: `Nessun utente non membro da aggiungere`,
  [`No organization found`]: `Nessuna organizzazione trovata`,
  [`No portal service exists:`]: `Non esiste alcun servizio portale:`,
  [`No product APIs`]: `Nessuna API di prodotto`,
  [`No products or plans were found.`]: `Nessun prodotto o piano trovato.`,
  [`No provider organizations`]: `Nessuna organizzazione provider`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Nessuna risposta ricevuta. Le cause comprendono una mancanza del supporto CORS sul server di destinazione, il server non è disponibile, è stato rilevato un certificato non attendibile o è obbligatoria l'autenticazione SSL reciproca.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Nessun gruppo di risorse è disponibile. Per iniziare, creare un gruppo di risorse nelle risorse dell'account IBM Cloud`,
  [`No security`]: `Nessuna sicurezza`,
  [`No services found`]: `Nessun servizio trovato`,
  [`No warnings`]: `Nessuna avvertenza`,
  [`No warnings.`]: `Nessuna avvertenza`,
  [`No. Do not update API Assembly`]: `No. Non aggiornare l'assemblaggio API`,
  [`Non-blocking`]: `Non di blocco`,
  [`Non int slicing arguments`]: `Argomenti di suddivisione non-int`,
  [`None`]: `Nessuno`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Nessuno dei provider OAuth configurati corrisponde al tipo di gateway di questa API.`,
  [`Normal`]: `Normale`,
  [`Not Available`]: `Non disponibile`,
  [`Not applicable for application grant type.`]: `Non applicabile al tipo di concessione dell'applicazione.`,
  [`Not valid before`]: `Non valido prima del`,
  [`Not valid after`]: `Non valido dopo`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Nota: questa opzione è disabilitata quando viene selezionata l'opzione 'Genera prodotto automaticamente'.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Nota: questa opzione è disabilitata quando l'opzione 'Genera prodotto automaticamente' è selezionata e utilizzerà il Piano predefinito per il prodotto generato`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Nota: la preferenza di Gateway Services verrà utilizzata come ambito per tutte le altre preferenze. Le politiche e gli altri contenuti specifici del servizio gateway verranno caricati in base a questa impostazione.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Nota: questa opzione è solo per scopo informativo e viene aggiornata in base all'opzione di verifica dell'applicazione sopracitata`,
  [`Notices`]: `Informazioni particolari`,
  [`Notification template`]: `Template di notifica`,
  [`Notification templates`]: `Template di notifica`,
  [`Notification server configured`]: `Server di notifica configurato`,
  [`Notifications`]: `Notifiche`,
  [`Null Badgerfish`]: `Badgerfish null`,
  [`Null`]: `Null`,
  [`Number`]: `Numero`,
  [`Number of Operations`]: `Numero di operazioni`,
  [`Numeric`]: `Numerico`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `Provider OAuth`,
  [`OAuth provider`]: `Provider OAuth`,
  [`OAuth Provider Policy Assembly`]: `Assemblaggio politica del Provider OAuth`,
  [`OAuth providers`]: `Provider OAuth`,
  [`OAuth Redirect URL`]: `URL di reindirizzamento OAuth`,
  [`OAuth shared secret`]: `Segreto condiviso OAuth`,
  [`OAuth provider policy assembly`]: `Assemblaggio politica del provider OAuth`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `Provider OAuth {currentProviderName} non trovato. Selezionare un provider OAuth configurato.`,
  [`OAuth redirect URL`]: `URL di reindirizzamento OAuth`,
  [`OAuth Shared Secret`]: `Segreto condiviso OAuth`,
  [`OAuth Signature Method`]: `Metodo di firma OAuth`,
  [`OAuth signature method`]: `Metodo di firma OAuth`,
  [`OAuth providers can be created and managed in the following list.`]: `È possibile creare e gestire i provider OAuth nel seguente elenco.`,
  [`OAuth symmetric key name`]: `Nome chiave simmetrica OAuth`,
  [`OAuth symmetric key value`]: `Valore chiave simmetrica OAuth`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `Sicurezza codice di accesso OAuth2`,
  [`OAuth2 Application Security`]: `Sicurezza applicazione OAuth2`,
  [`OAuth2 Implicit Security`]: `Sicurezza implicita OAuth2`,
  [`OAuth2 Password Security`]: `Sicurezza password OAuth2`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `ID OPERAZIONE`,
  [`OPTIONS`]: `OPZIONI`,
  [`ORDER`]: `ORDINA`,
  [`ORGANIZATION`]: `ORGANIZZAZIONE`,
  [`ORGANIZATION MANAGER`]: `MANAGER ORGANIZZAZIONE`,
  [`Organizations`]: `Organizzazioni`,
  [`Owner`]: `Proprietario`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `I provider Oauth sono gestiti in ciascuno spazio quando gli spazi sono abilitati. Passare alle impostazioni dello spazio per gestire i provider Oauth.`,
  [`Off`]: `Disattiva`,
  [`Offline`]: `Non in linea`,
  [`Old schema`]: `Schema precedente`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch consente di ricercare API, Prodotti, Applicazioni, Sottoscrizioni ed altre risorse all'interno di un catalogo o un'organizzazione provider. Inoltre, può consentire di rilevare le relazioni tra tali risorse.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch per tutte le pagine di elenco supportate`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch per prodotti ed API bozza`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch per prodotti pubblicati in cataloghi e spazi`,
  [`Omnisearch for consumer organizations`]: `Omnisearch per organizzazioni consumer`,
  [`Omnisearch for applications`]: `Omnisearch per applicazioni`,
  [`Omnisearch for requested approvals`]: `Omnisearch per approvazioni richieste`,
  [`Omnisearch for approvals tasks`]: `Omnisearch per attività di approvazione`,
  [`Omnisearch for product apis`]: `Omnisearch per API del prodotto`,
  [`Omnisearch for subscriptions`]: `Omnisearch per sottoscrizioni`,
  [`Omnisearch for members`]: `Omnisearch per membri`,
  [`Omnisearch for provider organizations`]: `Omnisearch per le organizzazioni del provider`,
  [`Disable the new editor for OpenAPI2`]: `Disabilita il nuovo editor per OpenAPI2`,
  [`On`]: `Attiva`,
  [`Onboarding`]: `Procedura di avvio`,
  [`Onboarding Edit Admin Org`]: `Modifica procedura di avvio organizzazione Admin`,
  [`Onboarding Edit Cloud Org`]: `Modifica procedura di avvio organizzazione cloud`,
  [`Onboarding Edit Reset Password`]: `Password reimpostata per la modifica della procedura di avvio`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Una volta installato, utilizzare IBM Cloud CLI per indirizzare l'account e la regione in cui è stato fornito API Connect, quindi tentare di eseguire CLI elencando tutte le organizzazioni provider API Connect`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Uno o più oggetti JSON che descrivono gli schemi utilizzati e prodotti dall'API.`,
  [`One or more JSON representations for parameters`]: `Una o più rappresentazioni JSON per i parametri`,
  [`One time use access token`]: `Token di accesso da utilizzare una volta`,
  [`One time use refresh token`]: `Token di aggiornamento da utilizzare una volta`,
  [`Online`]: `In linea`,
  [`Only one API file should be present in the zip file.`]: `Nel file zip deve essere presente un solo file API.`,
  [`OpenAPI Specification Version`]: `Versione specifica OpenAPI`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `OIDC (Open ID Connect) fornisce una base di protocollo di autenticazione aggiuntivo su OAuth 2.0. OIDC fornisce le informazioni utente codificate in un token web JSON o JWT. Quando si abilita OpenID Connect, viene fornito un template per generare gli ID token insieme ai token di accesso e alle politiche di assemblaggio richieste vengono create automaticamente.`,
  [`Open a Directory`]: `Apri una directory`,
  [`Open in external editor`]: `Apri in editor esterno`,
  [`Open the server`]: `Aprire il server`,
  [`OpenAPI extensions`]: `Estensioni OpenAPI`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect non è ancora supportato quando la versione gateway è 6000`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `Le impostazioni di OpenID Connect sono applicabili solo se i tipi di concessione selezionati contengono uno o più tra: Implicito e Codice di accesso.`,
  [`Operation`]: `Operazione`,
  [`Operation Id`]: `ID operazione`,
  [`Operation switch`]: `Switch operazioni`,
  [`Operation Switch`]: `Switch operazioni`,
  [`Operations`]: `Operazioni`,
  [`Operator build date`]: `Data creazione operatore`,
  [`Operator build tag`]: `Tag di build operatore`,
  [`Operator version`]: `Versione operatore`,
  [`Optionally select a CA bundle`]: `Come opzione, selezionare un bundle CA`,
  [`Optimize schema definition`]: `Ottimizza definizione schema`,
  [`Optional`]: `Facoltativo`,
  [`Or`]: `O`,
  [`Or create a new application and subscribe to the selected plan`]: `Oppure, creare una nuova applicazione ed effettuare la sottoscrizione al piano selezionato`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Oppure creare un nuovo prodotto e pubblicarlo nel catalogo sandbox.`,
  [`Order`]: `Ordinamento`,
  [`Org`]: `Org`,
  [`Org owner`]: `Proprietario organizzazione`,
  [`Organization`]: `Organizzazione`,
  [`Organization:`]: `Organizzazione:`,
  [`Organization information`]: `Informazioni sull'organizzazione`,
  [`Organization Manager`]: `Gestore organizzazione`,
  [`Organization has been updated.`]: `L'organizzazione è stata aggiornata.`,
  [`Organizations will be listed here.`]: `Le organizzazioni verranno elencate qui.`,
  [`Otherwise`]: `Alternativa`,
  [`Output`]: `Output`,
  [`Output Claims`]: `Richieste di output`,
  [`Output array`]: `Array di output`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Invia l'elemento root come output anche se non è necessario creare il documento XML in formato corretto.`,
  [`Output variable`]: `Variabile di output`,
  [`Override API consume types`]: `Sovrascrivi tipi di utilizzo API`,
  [`Override API produce types`]: `Sovrascrivi tipi di prodotto API`,
  [`Override API security definitions`]: `Sovrascrivi definizioni sicurezza API`,
  [`Override rate limits`]: `Sovrascrivi limiti di frequenza`,
  [`Override default "provider" settings with configuration from this URL`]: `Sovrascrivere le impostazioni "provider" predefinite con la configurazione da questo URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Sovrascrivere le impostazioni "provider" predefinite con la configurazione da questo valore letterale.`,
  [`Override plan rate limits`]: `Sovrascrivi i limiti percentuali del piano`,
  [`Override plan rate limits for individual operation`]: `Sovrascrivi i limiti di frequenza del piano per singole operazioni`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Sovrascrivi timeout di invito delle organizzazioni consumer con timeout di invito di catalogo`,
  [`Overview`]: `Panoramica`,
  [`Owner scope check`]: `Controllo ambito proprietario`,
  [`Owner's Email`]: `E-mail del proprietario`,
  [`Owner's Name`]: `Nome proprietario`,
  [`Owner's email`]: `E-mail del proprietario`,
  [`Owns and administers the API provider organization`]: `È il proprietario e gestisce l'organizzazione provider API`,
  [`Owns and administers the admin organization`]: `Possiede e gestisce l'organizzazione admin`,
  [`Owns and administers the app developer organization`]: `Possiede e gestisce l'organizzazione dello sviluppatore di app`,
  [`Owns and administrates the app developer organization`]: `Possiede e gestisce l'organizzazione degli sviluppatori di app`,
  [`PARAMETER NAME`]: `NOME PARAMETRO`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `PCRE da utilizzare per convalidare la richiesta Destinatario (aud).`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `PCRE da utilizzare per convalidare la richiesta Emittente (iss).`,
  [`PER`]: `PER`,
  [`Per`]: `Per`,
  [`Plan`]: `Piano`,
  [`Plan:`]: `Piano:`,
  [`pending Consumer organization invitation`]: `invito organizzazione consumer in sospeso`,
  [`PLAN`]: `PIANO`,
  [`PLANS`]: `PIANI`,
  [`Policy`]: `Politica`,
  [`policy`]: `Politica`,
  [`POLICIES`]: `POLITICHE`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODOTTO`,
  [`Product Plan`]: `Piano del prodotto`,
  [`Product Plans`]: `Piani dei Prodotti`,
  [`Product Plans per page`]: `Piani del prodotto per pagina`,
  [`Property name`]: `Nome proprietà`,
  [`Property type`]: `Tipo di proprietà`,
  [`Property example`]: `Esempio di proprietà`,
  [`Property description`]: `Descrizione proprietà`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Pagina non trovata`,
  [`Parameter name`]: `Nome parametro`,
  [`Parameter control`]: `Controllo parametro`,
  [`Parameters`]: `Parametri`,
  [`Parent`]: `Parent`,
  [`Parse`]: `Analizza`,
  [`Parse settings URL reference`]: `Riferimento URL delle impostazioni di analisi`,
  [`Parse settings literal configuration`]: `Configurazione del valore letterale delle impostazioni di analisi`,
  [`Parse settings object reference`]: `Riferimento oggetto delle impostazioni di analisi`,
  [`Parse the GraphQL query`]: `Analizza la query GraphQL`,
  [`Parse the response from the GraphQL backend server`]: `Analizza la risposta dal server di backend GraphQL`,
  [`Parse XML output`]: `Analizza output XML`,
  [`Pass-through`]: `Pass-through`,
  [`Password`]: `Password`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `La password è stata modificata per l'account utente in IBM API Connect`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `La password è stata modificata per l'account utente nel Developer Portal {portalTitle}`,
  [`Password context variable`]: `Variabile di contesto password`,
  [`Password is invalid. Re-enter the password.`]: `Password non è valida. Immettere di nuovo la password.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `La richiesta di reimpostazione della password per l'account utente in in IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `La richiesta di reimpostare la password per l'account del Developer Portal {catalog}`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Le password devono contenere almeno un carattere tra due delle seguenti categorie: lettere in minuscolo, lettere in maiuscolo, numeri e caratteri di punteggiatura (ad esempio:. !, $, #, %)`,
  [`Passwords must contain eight or more characters`]: `Le password devono contenere otto o più caratteri`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Le password devono contenere otto o più caratteri, con almeno un carattere tra le seguenti categorie: lettere in minuscolo, lettere in maiuscolo, numeri e simboli di punteggiatura (ad esempio !, $, #, %). Inoltre, lo stesso carattere non può essere utilizzato più di due volte consecutivamente.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Le password non devono contenere lo stesso carattere utilizzato più di due volte consecutivamente.`,
  [`Passwords should match`]: `Le password devono corrispondere`,
  [`Path`]: `Percorso`,
  [`Path parameters`]: `Parametri del percorso`,
  [`Path name`]: `Nome percorso`,
  [`Paths`]: `Percorsi`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `I percorsi identificano le risorse REST esposte dall'API. Un'operazione combina un percorso con un'istruzione HTTP, parametri e definizioni per richieste e risposte.`,
  [`Payments Authorization`]: `Autorizzazione ai pagamenti`,
  [`Payment method`]: `Metodo di pagamento`,
  [`Pending`]: `In sospeso`,
  [`Pending State`]: `Stato in sospeso`,
  [`Pending approval to be published in catalog`]: `In attesa di approvazione per essere pubblicato nel catalogo`,
  [`Pending approval to be staged in catalog`]: `In attesa di approvazione per essere sottoposto a staging nel catalogo`,
  [`Pending consumer organization invitations`]: `Inviti organizzazione consumer in sospeso`,
  [`View endpoints`]: `Visualizza endpoint`,
  [`View base endpoints for this API`]: `Visualizza endpoint di base per questa API`,
  [`View pending invitations`]: `Visualizza inviti in sospeso`,
  [`Permission Denied`]: `Autorizzazione negata`,
  [`Permissions`]: `Permessi`,
  [`Persistent Connection`]: `Connessione permanente`,
  [`Pin menu`]: `Fissa menu`,
  [`PlainText`]: `Testo semplice`,
  [`Plan APIs`]: `API del piano`,
  [`Plan burst limits`]: `Limiti di burst del piano`,
  [`Plan by name`]: `Piano per nome`,
  [`Plan Default`]: `Impostazione predefinita del piano`,
  [`Plan Details`]: `Dettagli del piano`,
  [`Plan migration targets`]: `Destinazioni di migrazione del piano`,
  [`Plan rate limits`]: `Limiti di frequenza del piano`,
  [`Plan name`]: `Nome piano`,
  [`Plan pricing`]: `Prezzi del piano`,
  [`Plan title already exists`]: `Il titolo del piano già esiste.`,
  [`Plans`]: `Piani`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Endpoint API REST della piattaforma per le API admin e provider`,
  [`Platform REST API endpoint for consumer APIs`]: `Endpoint API REST della piattaforma per le API consumer`,
  [`Please choose a bind method`]: `Selezionare un metodo di bind`,
  [`Please choose an authentication method`]: `Selezionare un metodo di autenticazione`,
  [`Please contact the system administrator.`]: `Rivolgersi all'amministratore di sistema.`,
  [`Please contact your administrator for support.`]: `Per assistenza, rivolgersi all'amministratore.`,
  [`Please enter the reason.`]: `Immettere il motivo.`,
  [`Please enter your username`]: `Immettere il nome utente`,
  [`Please go to the space settings to manage API endpoints.`]: `Passare alle impostazioni dello spazio per gestire gli endpoint API.`,
  [`Please make sure the server is online and try again.`]: `Accertarsi che il server sia in linea e riprovare.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Tener presente che disabilitando il log delle attività si rimuoveranno le proprietà associate al tipo di contenuto da registrare in caso di successo o di errore. Si è sicuri di voler disabilitare?`,
  [`Please review the license for API Connect by accessing`]: `Rivedere la licenza per API Connect accedendo`,
  [`Please select a TLS`]: `Selezionare un TLS`,
  [`Please select a TLS client profile`]: `Selezionare un profilo client TLS`,
  [`Please select a group type`]: `Selezionare un tipo di gruppo`,
  [`Please select an LDAP protocol version`]: `Selezionare una versione di protocollo LDAP`,
  [`Please select authentication method`]: `Selezionare il metodo di autenticazione`,
  [`Please select ciphers from the list below:`]: `Selezionare le crittografie dal seguente elenco:`,
  [`Please select consumer organization`]: `Selezionare l'organizzazione consumer`,
  [`Please select one Keystore from the list below:`]: `Selezionare un keystore dal seguente elenco:`,
  [`Please select one keystore from the list below:`]: `Selezionare un keystore dal seguente elenco:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Prendere nota dell'ID client e del segreto di questa nuova applicazione. Memorizzare il segreto in un posto sicuro poiché non potrà essere recuperato di nuovo sebbene possa essere reimpostato, se necessario.`,
  [`Please try again in a few minutes.`]: `Riprovare tra qualche minuto.`,
  [`Please refresh catalog/space product list page and try again.`]: `Aggiornare la pagina di elenco dei prodotti del catalogo / spazio e riprovare.`,
  [`Policies`]: `Politiche`,
  [`Policy Assemblies`]: `Assemblaggi politiche`,
  [`Policy assembly`]: `Assemblaggio politica`,
  [`Policy description.`]: `Descrizione della politica.`,
  [`Policy title`]: `Titolo politica`,
  [`Policy has been uploaded.`]: `La politica è stata caricata.`,
  [`Polish`]: `Polacco`,
  [`Port`]: `Porta`,
  [`Portal`]: `Portale`,
  [`Portal details`]: `Dettagli del portale`,
  [`Portal Director TLS client profile`]: `Profilo client TLS per il Portal Director`,
  [`Portal Director keystore`]: `Keystore per il Portal Director`,
  [`Portal Director truststore`]: `Truststore per il Portal Director`,
  [`Portal service`]: `Servizio portale`,
  [`Portal Service`]: `Servizio portale`,
  [`Portal service name`]: `Nome servizio portale`,
  [`Portal service details`]: `Dettagli del servizio del portale`,
  [`Portal Service and URL`]: `Servizio portale e URL`,
  [`Portal service {name} was successfully created.`]: `Servizio portale {name} creato correttamente.`,
  [`Portal service {name} was successfully updated.`]: `Servizio portale {name} aggiornato correttamente.`,
  [`Portal Service {arg} has been removed.`]: `Il servizio portale {arg} è stato rimosso.`,
  [`Portal site`]: `Sito del portale`,
  [`Portal URL`]: `URL portale`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Gli URL del sito del portale non possono superare 200 caratteri`,
  [`Portal website URL`]: `URL sito web portale`,
  [`Portal and Gateway Settings`]: `Impostazioni del portale e del gateway`,
  [`Portal has been created`]: `Il portale è stato creato`,
  [`Portal has been created.`]: `Il portale è stato creato`,
  [`Portal has been deleted`]: `Il portale è stato eliminato`,
  [`Portals`]: `Portali`,
  [`Portuguese`]: `Portoghese`,
  [`Post Response`]: `Post risposta`,
  [`Post-Invoke Hook`]: `Hook successivo al richiamo`,
  [`Preserve subscriptions on re-publish`]: `Conserva sottoscrizione alla nuova pubblicazione`,
  [`Preserve subscriptions on re-publish product`]: `Conserva sottoscrizioni alla nuova pubblicazione del prodotto`,
  [`Pre-Invoke Hook`]: `Hook precedente al richiamo`,
  [`Pre-Request`]: `Pre-richiesta`,
  [`Preference`]: `Preferenze`,
  [`Prefix`]: `Prefisso`,
  [`Preparing API`]: `Preparazione API`,
  [`Preserved request header`]: `Intestazione richiesta conservata`,
  [`preserved request header`]: `Intestazione richiesta protetta`,
  [`Preserved response header`]: `Intestazione risposta conservata`,
  [`preserved response header`]: `Intestazione risposta protetta`,
  [`Preview`]: `Anteprima`,
  [`Preview of login screen registry prompt`]: `Anteprima del prompt del registro schermata di login`,
  [`Previous`]: `Precedente`,
  [`Previous page`]: `Pagina precedente`,
  [`Prettify`]: `Migliora`,
  [`Price per month`]: `Prezzo al mese`,
  [`Private`]: `Privato`,
  [`Private Claims`]: `Richieste private`,
  [`Private key & public key`]: `Chiave privata & chiave pubblica`,
  [`Private Key or Shared Secret for Decrypt`]: `Chiave privata o segreto condiviso per la decodifica`,
  [`Private Key or Shared Secret for Sign`]: `Chiave privata o segreto condiviso per la firma`,
  [`Private key detected`]: `Rilevata chiave privata`,
  [`Private key password`]: `Password chiave privata`,
  [`Processed`]: `Elaborato`,
  [`Problem`]: `Problema`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Problema durante il recupero dei dati di traccia. Riprovare. Consultare la console per i dettagli dell'errore.`,
  [`Produced Media Types`]: `Tipi di supporto prodotti`,
  [`Produces`]: `Produce`,
  [`Product`]: `Prodotto`,
  [`Product:`]: `Prodotto:`,
  [`Product / Plan`]: `Prodotto / Piano`,
  [`Product APIs`]: `API prodotto`,
  [`Product editor`]: `Editor del prodotto`,
  [`Product lifecycle`]: `Ciclo di vita del prodotto`,
  [`Product Lifecycle`]: `Ciclo di vita del prodotto`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Il nome prodotto non può terminare con '-auto-product'. Modificare il nome e riprovare l'importazione.`,
  [`Product setup`]: `Configurazione prodotto`,
  [`Product details`]: `Dettagli sul prodotto`,
  [`Product documentation`]: `Documentazione del prodotto`,
  [`Product has been created.`]: `Il prodotto è stato creato.`,
  [`Product has been deleted`]: `Il prodotto è stato eliminato.`,
  [`Product has been updated`]: `Il prodotto è stato aggiornato`,
  [`Product has been updated.`]: `Il prodotto è stato aggiornato.`,
  [`Product has not been created!`]: `Il prodotto non è stato creato.`,
  [`Product has not been published!`]: `Il prodotto non è stato pubblicato.`,
  [`Product has not been updated!`]: `Il prodotto non è stato aggiornato.`,
  [`Product has not been staged!`]: `Il prodotto non è stato sottoposto a staging.`,
  [`Product information`]: `Informazioni sul prodotto`,
  [`Product summary details`]: `Dettagli di riepilogo del prodotto`,
  [`Product version`]: `Versione prodotto`,
  [`Product visibility`]: `Visibilità prodotto`,
  [`Product-Approval`]: `Approvazione-Prodotto`,
  [`Product-Drafts`]: `Product-Drafts`,
  [`Product/plan`]: `Prodotto/Piano`,
  [`Production`]: `Produzione`,
  [`Production mode`]: `Modalità di produzione`,
  [`Production endpoints`]: `Endpoint di produzione`,
  [`Products`]: `Prodotti`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `I prodotti devono essere sottoposti a staging in un catalogo e quindi pubblicati per divenire disponibili agli sviluppatori dell'applicazione. Un catalogo funziona  come una partizione logica del gateway e Developer Portal. L'URL per le chiamate all'API e il Developer Portal sono specifici per un particolare catalogo. In una normale configurazione, l'organizzazione provider API utilizza il catalogo di sviluppo per eseguire il test delle API in un catalogo di sviluppo e di produzione per ospitare le API che sono pronte per un pieno utilizzo.`,
  [`Profile`]: `Profilo`,
  [`Profile rule`]: `Regola del profilo`,
  [`Promote to production`]: `Promozione alla produzione`,
  [`Proof Key for Code Exchange`]: `Chiave di prova per lo scambio di codice`,
  [`Proof Key for Code Exchange (PKCE)`]: `PKCE (Proof Key for Code Exchange)`,
  [`Properties`]: `Proprietà`,
  [`Property`]: `Proprietà`,
  [`Property Description`]: `Descrizione proprietà`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `È possibile assegnare un valore statico alla proprietà {{target}} immettendolo di seguito.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `La proprietà {{target}} è attualmente associata a {{from}} - l'associazione può essere rimossa di seguito.`,
  [`Property Value is Base64 Encoded`]: `Il valore della proprietà ha una codifica Base64`,
  [`Protocol`]: `Protocollo`,
  [`Protocols`]: `Protocolli`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `I protocolli che possono essere utilizzati per richiamare l'API (solo https è supportato per le API applicate dal gateway)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Fornire le credenziali OAuth {oauthVer} per autenticare gli utenti e ottenere l'accesso alle API del provider OpenID`,
  [`Provide a schema`]: `Fornisci uno schema`,
  [`Provide GraphQL schema`]: `Fornisci schema GraphQL`,
  [`Provide the URL to the backend GraphQL server`]: `Fornisci l'URL al server GraphQL di backend`,
  [`Provide your email address to receive password reset instructions`]: `Fornire il proprio indirizzo e-mail per ricevere le istruzioni per la reimpostazione della password`,
  [`Provide your new password below`]: `Fornire di seguito la nuova password`,
  [`Provider endpoint`]: `Endpoint provider`,
  [`Provider Information`]: `Informazioni sul fornitore`,
  [`Provider Organization`]: `Organizzazione provider`,
  [`Provider Organizations`]: `Organizzazioni provider`,
  [`Provider Type`]: `Tipo di provider`,
  [`Provider organizations`]: `Organizzazioni provider`,
  [`Provider type`]: `Tipo di provider`,
  [`Provider org invite failed`]: `Invito dell'organizzazione del provider fallito`,
  [`Provider organization {name} created`]: `Organizzazione del provider {name} creata`,
  [`Provider organizations will be listed here`]: `Le organizzazioni provider verranno elencate in questo punto`,
  [`Provider-Org`]: `Org-Provider`,
  [`Provides contact information for the exposed API.`]: `Fornisce informazioni di contatto per l'API esposta.`,
  [`Provides license information for the exposed API.`]: `Fornisce informazioni sulla licenza per l'API esposta.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Fornisce i metadati sull'API. I metadati possono essere utilizzati dai client se necessario.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `Il provisioning di developer portal è stato avviato. Occorreranno pochi minuti per completarlo. Una volta completate le operazioni, verrà ricevuta una e-mail per impostare la password per l'account admin portal.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `L'aggiornamento dell'URL di Developer Portal è stato avviato. Per completare il processo, saranno necessari alcuni minuti.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `URL di proxy`,
  [`Public`]: `Pubblico`,
  [`Public Key or Shared Secret for Encrypt`]: `Chiave privata o segreto condiviso per la codifica`,
  [`Publish`]: `Pubblica`,
  [`Publish API`]: `Pubblica API`,
  [`Publish a product from the Develop section.`]: `Pubblicare un prodotto dalla sezione Sviluppa.`,
  [`Publish product`]: `Pubblica prodotto`,
  [`Publish to`]: `Pubblica su`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Pubblicare alcuni prodotti in questo catalogo per creare sottoscrizioni ai prodotti.`,
  [`Publish target`]: `Pubblica destinazione`,
  [`Publish this API to the Sandbox catalog.`]: `Pubblica questa API nel catalogo Sandbox`,
  [`Publish to specific gateway services`]: `Pubblica su specifici servizi gateway`,
  [`Publish using a new product`]: `Pubblica utilizzando un nuovo prodotto`,
  [`Publish using an existing product`]: `Pubblica utilizzando un prodotto esistente`,
  [`Publishable Key`]: `Chiave pubblicabile`,
  [`Publishable key`]: `Chiave pubblicabile`,
  [`Publishable key tip`]: `Suggerimento chiave pubblicabile`,
  [`Published`]: `Pubblicato`,
  [`Published product`]: `Prodotto pubblicato`,
  [`Publishing`]: `Pubblicazione`,
  [`Publishing New API`]: `Pubblicazione di una nuova API`,
  [`Publish (preserve subscriptions)`]: `Pubblica (conserva sottoscrizioni)`,
  [`Publish|permission`]: `Pubblica`,
  [`Put online`]: `Connetti`,
  [`Query`]: `Query`,
  [`Queryparam Encode`]: `Codifica dei parametri della query`,
  [`Queued`]: `Accodato`,
  [`Queued events: **{queued}**`]: `Eventi accodati: **{queued}**`,
  [`Retry blocked jobs`]: `Ritenta lavori bloccati`,
  [`REQUIRED`]: `OBBLIGATORIO`,
  [`ROLES`]: `RUOLI`,
  [`Rate limit`]: `Limite di frequenza`,
  [`Rate Limit`]: `Limite di frequenza`,
  [`Rate Limit Name`]: `Nome limite frequenza`,
  [`Rate limits`]: `Limiti di frequenza`,
  [`Rate limit name can't be a duplicate`]: `Il nome limite frequenza non può essere un duplicato`,
  [`Rate limit name can't be empty`]: `Il nome limite frequenza non può essere vuoto`,
  [`Rate limit overrides`]: `Sostituzioni limite di frequenza`,
  [`Rate limit time interval can't be 0`]: `L'intervallo di tempo limite frequenza non può essere 0`,
  [`Rate limit time interval can't be empty`]: `L'intervallo del tempo limite di frequenza non può essere vuoto`,
  [`Rate limit time interval unit must be selected`]: `È necessario selezionare l'unità dell'intervallo di tempo limite frequenza`,
  [`Rate limit value can't be empty`]: `Il valore limite frequenza non può essere vuoto`,
  [`Rate limit value can't be negative`]: `Il valore limite frequenza non può essere negativo`,
  [`Re-stage`]: `Sottoponi nuovamente a staging`,
  [`Re-stage product`]: `Sottoponi nuovamente a staging il prodotto`,
  [`Read only`]: `Sola lettura`,
  [`Read-Only`]: `Sola lettura`,
  [`Realized`]: `Realizzata`,
  [`Reason`]: `Motivo`,
  [`Reason for declining`]: `Motivo del rifiuto`,
  [`Recommendations`]: `Suggerimenti`,
  [`Recommended configuration`]: `Configurazione consigliata`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Registra il segreto e l'ID client riportato di seguito per un utilizzo successivo. Il segreto del client non potrà essere visualizzato di seguito.`,
  [`Recommendations applied`]: `Consigli applicati`,
  [`Redact`]: `Revisiona`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Revisiona o rimuove i campi specificati dal corpo della richiesta, dal corpo della risposta e dai log dell'attività. Definire il campo da revisionare o rimuovere specificando un'espressione XPath.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Revisiona o rimuove i campi specificati dal corpo della richiesta, dal corpo della risposta e dai log dell'attività. Definire il campo da revisionare o rimuovere specificando un'espressione XPath o JSONata.`,
  [`Redaction`]: `Revisione`,
  [`Redactions`]: `Revisioni`,
  [`Redirect`]: `Reindirizza`,
  [`Redirect URI`]: `Reindirizza URI`,
  [`Redirect URL`]: `URL di reindirizzamento`,
  [`Redirect through Portal`]: `Reindirizza attraverso il portale`,
  [`Redirect time limit`]: `Limite tempo di reindirizzamento`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `Il limite di tempo di reindirizzamento deve essere compreso tra 10 e 600 secondi`,
  [`References`]: `Fa riferimento`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Fare riferimento a [Documentazione IBM Cloud]({link}) per ulteriori informazioni.`,
  [`Referral`]: `Riferimento`,
  [`Refresh`]: `Aggiorna`,
  [`Refresh Token`]: `Aggiorna token`,
  [`Refresh button`]: `Pulsante Aggiorna`,
  [`Refresh products`]: `Aggiorna i prodotti`,
  [`Refresh results from server`]: `Aggiorna risultati dal server`,
  [`Refresh token`]: `Aggiorna token`,
  [`Refresh token time to live`]: `TTL del token di aggiornamento`,
  [`Refresh token time-to-live`]: `Aggiorna TTL token`,
  [`Refresh token time-to-live (seconds)`]: `Aggiorna TTL token (secondi)`,
  [`Refresh token TTL has been changed`]: `Il TTL del token di aggiornamento è stato modificato`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Il TTL del token di aggiornamento è **{seconds} secondi ({time})**`,
  [`Refresh token time to live is **off**`]: `Il TTL del token di aggiornamento è **disattivo**`,
  [`Refresh tokens`]: `Token di aggiornamento`,
  [`Refreshing`]: `Aggiornamento in corso`,
  [`Refreshing...`]: `Aggiornamento in corso...`,
  [`Region`]: `Regione`,
  [`Register`]: `Registra`,
  [`Register Gateways`]: `Registra gateway -`,
  [`Register Service`]: `Registra servizio`,
  [`Register gateways`]: `Registra gateway`,
  [`Register service`]: `Registra servizio`,
  [`Register a service`]: `Registra un servizio`,
  [`Register new services and manage existing services`]: `Registra nuovi servizi e gestisce i servizi esistenti`,
  [`Register remote gateway`]: `Registra gateway remoto`,
  [`Registered Admin Member`]: `Membro admin registrato`,
  [`Registered Provider Org Owner or Member`]: `Membro o proprietario dell'organizzazione del provider registrata`,
  [`Registered client information on the OpenID provider`]: `Le informazioni client registrate sul provider OpenID`,
  [`Registration completed successfully`]: `Registrazione completata correttamente`,
  [`Registration failed.`]: `Registrazione non riuscita.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Percorsi relativi ai singoli endpoint. Devono essere relativi a "basePath".`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `Rimuovi configurazione dell'analisi`,
  [`Remote API Connect`]: `Connessione API remota`,
  [`Remote login`]: `Login remoto`,
  [`Remote publish`]: `Pubblicazione remota`,
  [`Remove`]: `Elimina`,
  [`Remove Burst Limit`]: `Rimuovi limite di burst`,
  [`Remove constraints`]: `Rimuovi vincoli`,
  [`Remove Count Limit`]: `Rimuovi limite conteggio`,
  [`Remove gateway extension`]: `Rimuovi estensione del gateway`,
  [`Remove product`]: `Rimuovi prodotto`,
  [`Remove Rate limit`]: `Rimuovi limite di frequenza`,
  [`Remove the invalid constraints`]: `Rimuovi i vincoli non validi`,
  [`Remove the invalid constraints from`]: `Rimuovi i vincoli non validi da`,
  [`Remove action`]: `Rimuovi azione`,
  [`Remove case`]: `Rimuovi caso`,
  [`Remove catch`]: `Rimuovi cattura`,
  [`Remove filter`]: `Rimuovi filtro`,
  [`Remove input`]: `Rimuovi input`,
  [`Remove mapping`]: `Rimuovi associazione`,
  [`Remove output`]: `Rimuovi output`,
  [`Remove static value`]: `Rimuovi valore statico`,
  [`Removed`]: `Rimosso`,
  [`Removed by user`]: `Rimosso dall'utente`,
  [`Reorder`]: `Riordina`,
  [`Repeat`]: `Ripeti`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Ripete il richiamo dell'API per un numero specifico di volte, o fino a quando non viene selezionato il pulsante di arresto`,
  [`Replace`]: `Sostituisci`,
  [`Replace Free Trial with`]: `Sostituisci Versione gratuita con`,
  [`Replace Gold with`]: `Sostituisci Gold con`,
  [`Replace GraphQL schema`]: `Sostituisci schema GraphQL`,
  [`Replace plans`]: `Sostituisci piani`,
  [`Replace private key & public key`]: `Sostituisci la chiave privata & chiave pubblica`,
  [`Replace Product`]: `Sostituisci prodotto`,
  [`Replace product`]: `Sostituisci prodotto`,
  [`Replace schema`]: `Sostituisci schema`,
  [`Replace Silver with`]: `Sostituisci Silver con`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Sostituisce il proprietario corrente selezionando il nuovo proprietario dall'elenco di suggerimenti. Assegnare al proprietario sostituito nuove autorizzazioni in modo da consentirgli di poter continuare a lavorare.`,
  [`Replace|permission`]: `Sostituisci`,
  [`Republish`]: `Ripubblica`,
  [`Republish product`]: `Ripubblica prodotto`,
  [`Request`]: `Richiesta`,
  [`Request catch`]: `Catch richiesta`,
  [`Request endpoint`]: `Endpoint richiesta`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `La richiesta è stata approvata per {action} il prodotto API nel catalogo {catalog}`,
  [`Request approved to {{action}} app {{appName}}`]: `La richiesta è stata approvata per {action} l'app {appName}`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `La richiesta è stata negata per {action} il prodotto API nel catalogo {catalog}`,
  [`Request denied to {{action}} app {{appName}}`]: `La richiesta è stata negata per {action} l'app {appName}`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Richiesta per l'approvazione della sottoscrizione ad un prodotto API nel catalogo {catalog}`,
  [`Request for approval to {{action}} app {{appName}}`]: `Richiesta di approvazione di {action} l'app {appName}`,
  [`Request headers`]: `Intestazioni richiesta`,
  [`Request pending to promote application to production`]: `Richiesta in sospeso per promuovere l'applicazione alla produzione`,
  [`Request query`]: `Query richiesta`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Ricevuta richiesta di {action} il prodotto API nel catalogo {catalog}`,
  [`Request received to {{action}} app {{appName}}`]: `Ricevuta richiesta di {action} l'app {appName}`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Ritirata la richiesta di sottoscrizione ad un prodotto API nel catalogo {catalog}`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Ritirata la richiesta di {action} un prodotto API nel catalogo {catalog}`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Richiesta di {action} ritirata per l'app {appName}`,
  [`Requested approvals`]: `Approvazioni richieste`,
  [`Requested By`]: `Richiesto da`,
  [`Requested To`]: `Richiesto a`,
  [`Require`]: `Obbliga`,
  [`Require applications to provide a client ID`]: `Richiedi alle applicazioni di fornire un ID client`,
  [`Require approval for all new self service development accounts`]: `Richiedere l'approvazione per tutti i nuovi account di sviluppo self service`,
  [`Required`]: `Obbligatorio`,
  [`Required for rate limiting`]: `Richiesto per la limitazione della velocità`,
  [`Required must be checked off when location is in path.`]: `L'opzione Obbligatorio deve essere disattivata quando l'ubicazione è nel percorso.`,
  [`Required path`]: `Percorso richiesto`,
  [`Resend Invite`]: `Invia nuovamente invito`,
  [`Resend invitation`]: `Invia nuovamente invito`,
  [`Resend invite`]: `Invia nuovamente invito`,
  [`Reset`]: `Ripristina`,
  [`Reset defaults`]: `Ripristina valori predefiniti`,
  [`Reset Notification template`]: `Reimposta template di notifica`,
  [`Reset password`]: `Reimposta password`,
  [`Reset password TTL has been changed`]: `Il TTL della password di ripristino è stato modificato`,
  [`Reset password time to live`]: `TTL della password di ripristino`,
  [`Reset password time-to-live`]: `Reimposta TTL password`,
  [`Reset password email has been sent`]: `L'e-mail di reimpostazione della password è stata inviata`,
  [`Reset password email has not been sent`]: `La e-mail per reimpostare la password non è stata inviata.`,
  [`Reset password invitation timeout`]: `Timeout di invito alla password di ripristino`,
  [`Reset to default preferences`]: `Ripristina preferenze predefinite`,
  [`Resolve API Connect variable references`]: `Risolvi i riferimenti delle variabili API Connect`,
  [`Resolve XML input data type`]: `Risolvi il tipo di dati di input XML`,
  [`resolver`]: `resolver`,
  [`Resource group`]: `Gruppo di risorse`,
  [`Resource Owner`]: `Proprietario delle risorse`,
  [`Resource owner password grant`]: `Autorizzazione password proprietario risorsa`,
  [`Resource owner revocation`]: `Revoca proprietario risorsa`,
  [`Resource owner revocation path`]: `Percorso di revoca proprietario risorsa`,
  [`Resource Owner Security`]: `Sicurezza proprietario risorsa`,
  [`Resource owner - JWT`]: `Proprietario risorsa - JWT`,
  [`Resource owner - Password`]: `Proprietario risorsa - Password`,
  [`Resource owner password`]: `Password proprietario risorsa`,
  [`Resources`]: `Risorse`,
  [`Response`]: `Risposta`,
  [`Response catch`]: `Catch risposta`,
  [`Response Definition`]: `Definizione di risposta`,
  [`Response Time`]: `Tempo di risposta`,
  [`Response type`]: `Tipo di risposta`,
  [`Response context variables`]: `Variabili di contesto della risposta`,
  [`Response headers`]: `Intestazioni risposta`,
  [`Response must have a description`]: `La risposta deve avere una descrizione`,
  [`Response must have a schema`]: `La risposta deve avere uno schema`,
  [`Response must have a status code`]: `La risposta deve avere un codice di stato`,
  [`Response object variable`]: `Variabile oggetto risposta`,
  [`Response time`]: `Tempo di risposta`,
  [`Response time: {{value}}ms`]: `Tempo di risposta: {{value}}ms`,
  [`Result of removing`]: `Risultato della rimozione`,
  [`Restrict HTTP transactions to version 1.0.`]: `Limitare le transazioni HTTP alla versione 1.0.`,
  [`Restrict to HTTP 1.0`]: `Limitare a HTTP 1.0`,
  [`Retire`]: `Ritira`,
  [`Retire product`]: `Ritira prodotto`,
  [`Retired`]: `Ritirato`,
  [`Retire|permission`]: `Ritira`,
  [`Return V5 Responses`]: `Restituisci risposte V5`,
  [`Retry selected requests`]: `Ripetere le richieste selezionate`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Restituisci i risultati delle query di introspezione GraphQL standard dal proxy GraphQL`,
  [`Return third-party access_token`]: `Restituire access_token di terze parti`,
  [`Return third-party access_token with the token response`]: `Restituire access_token di terze parti con la risposta token`,
  [`Return third-party id_token`]: `Restituire id_token di terze parti`,
  [`Return third-party id_token with the token response`]: `Restituire id_token di terze parti con la risposta token`,
  [`Return to Login`]: `Torna al pannello di accesso`,
  [`Reveal in finder`]: `Mostra nella ricerca`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Esaminare e determinare le informazioni da visualizzare o nascondere nello schema. Per mantenere valido lo schema, potrebbero essere necessarie ulteriori modifiche.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Esaminare tutte le avvertenze. Sarà possibile migliorare il proprio schema con utili suggerimenti successivamente nell'editor.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Esaminare eventuali avvertenze e applicare i nostri consigli intelligenti per migliorare lo schema e proteggere il backend.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Esaminare l'URL di base dell'endpoint di richiamo dell'API e specificare i domini gestiti dal gateway.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Esaminare il tipo, il titolo, il nome ed il riepilogo del gateway. Quando vengono richiamati i comandi e le API di API Connect, utilizzare il nome del gateway.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Esaminare il riepilogo delle modifiche, che potrebbe includere ulteriori modifiche per mantenere lo schema valido.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Esaminare le avvertenze rilevate nello schema. Sarà possibile migliorare il proprio schema e proteggere il backend con utili suggerimenti successivamente nell'editor.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Esaminare le avvertenze rilevate nello schema. Sarà possibile migliorare il proprio schema e proteggere il backend con utili suggerimenti.`,
  [`Reviewed schema, paths and operations`]: `Schema, percorsi ed operazioni revisionati`,
  [`Revocation protocol`]: `Protocollo di revoca`,
  [`Revoke token`]: `Revoca token`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `Documentazione RHEL`,
  [`Role`]: `Ruolo`,
  [`Role default`]: `Valori predefiniti del ruolo`,
  [`Role default has been deleted`]: `I valori predefiniti del ruolo sono stati eliminati`,
  [`Role defaults`]: `Valori predefiniti del ruolo`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Le impostazioni predefinite del ruolo rappresentano template di ruolo pre-configurati utilizzati per determinare i ruoli predefiniti disponibili quando viene creata una nuova organizzazione.`,
  [`Role has been created`]: `Il ruolo è stato creato`,
  [`Role has been deleted`]: `Il ruolo è stato eliminato`,
  [`Role has been updated`]: `Il ruolo è stato aggiornato`,
  [`Roles`]: `Ruoli`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `I ruoli (e le relative autorizzazioni assegnate) controllano l'accesso alle funzioni in API Connect. I ruoli dell'organizzazione si riferiscono solo alle persone che utilizzano Cloud Manager.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `I ruoli e le autorizzazioni controllano l'accesso alle funzioni di API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `I ruoli e le relative autorizzazioni associate controllano l'accesso elle funzioni e la possibilità di eseguire attività in API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `I ruoli e le relative autorizzazioni associate controllano l'accesso elle funzioni e la possibilità di eseguire attività nell'interfaccia utente di API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `I ruoli e le relative autorizzazioni associate controllano l'accesso elle funzioni e la possibilità di eseguire attività.`,
  [`Root`]: `Root`,
  [`Root XML Element Name`]: `Nome elemento XML root`,
  [`Run the following command in the CLI to register the credentials:`]: `Eseguire il seguente comando nella CLI per registrare le credenziali:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Eseguire il seguente comando nella CLI per registrare le credenziali del Toolkit:`,
  [`Running`]: `Esecuzione`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `La variabile di runtime che contiene il JWK da utilizzare per decodificare il JWT.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `La variabile di runtime che contiene il JWK da utilizzare per codificare il JWT.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `La variabile di runtime che contiene il JWK da utilizzare per firmare il JWT.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `La variabile di runtime che contiene il JWK da utilizzare per verificare la firma.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `La variabile di runtime da cui è possibile richiamare un insieme valido di richieste JSON.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `La variabile di runtime da cui è possibile richiamare la stringa di richiesta Destinatario (aud). È possibile impostare più variabili mediante una stringa separata da virgole.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `La variabile di runtime da cui è possibile richiamare la stringa di richiesta Emittente (iss). Questa richiesta rappresenta il Principal che ha inviato il JWT.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `La variabile di runtime da cui è possibile richiamare la stringa di richiesta Oggetto (sub).`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `La variabile di runtime in cui inserire il JWT generato. Se non impostato, il JWT viene posizionato nell'intestazione di autorizzazione come token Bearer.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Variabile di runtime che contiene il nome utente autenticato. Il token LTPA viene generato insieme a questa proprietà come utente. Ad esempio, $(client.app.id), oppure $(oauth.resource-owner), oppure una variabile di runtime configurata in una politica Imposta variabile.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `La variabile di runtime a cui è assegnato l'insieme completo di richieste contenute nel JWT.`,
  [`Russian`]: `Russo`,
  [`Scalar`]: `Scalare`,
  [`Schema`]: `Schema`,
  [`SCHEMA`]: `SCHEMA`,
  [`SCOPE`]: `AMBITO`,
  [`SCOPES`]: `AMBITI`,
  [`SECURITY DEFINITIONS`]: `DEFINIZIONI SICUREZZA`,
  [`Service`]: `Servizio`,
  [`Service state:`]: `Stato servizio:`,
  [`SERVICE TYPE`]: `TIPO DI SERVIZIO`,
  [`SIL-OFL 1.1 license`]: `Licenza SIL-OFL 1.1`,
  [`SOURCE`]: `ORIGINE`,
  [`SSL Profile`]: `Profilo SSL`,
  [`State`]: `Stato`,
  [`STATUS`]: `STATO`,
  [`Status Code`]: `Codice stato`,
  [`STATUS CODE`]: `CODICE DI STATO`,
  [`SUBMITTED`]: `INOLTRATO`,
  [`SUBSCRIBERS`]: `SOTTOSCRITTORI`,
  [`Summary`]: `Riepilogo`,
  [`Same as product`]: `Uguale al prodotto`,
  [`Sample ID token signing key (RS256)`]: `Chiave di firma ID token di esempio (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Chiave di convalida ID token di esempio (RS256)`,
  [`Sample OAuth symmetric key value`]: `Valore chiave simmetrica OAuth di esempio`,
  [`Sample URL: https://api.catalog.org.host/`]: `URL di esempio: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `URL di esempio: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Descrizione dell'ambito di esempio 1`,
  [`Sandbox - Overview`]: `Sandbox - Panoramica`,
  [`Sandbox Catalog`]: `Catalogo Sandbox`,
  [`Sandbox Catalog User Registry`]: `Registro utente del catalogo Sandbox`,
  [`Sandbox Default TLS client profile`]: `Profilo client TLS predefinito Sandbox`,
  [`Sandbox Default TLS Client Profile`]: `Profilo client TLS predefinito Sandbox`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Il catalogo sandbox dovrebbe sempre esistere in un'organizzazione provider.`,
  [`Save`]: `Salva`,
  [`Save API as a new version`]: `Salva API come una nuova versione`,
  [`Save Product as a new version`]: `Salva prodotto come una nuova versione`,
  [`Save and Edit`]: `Salva ed esci`,
  [`Save and republish`]: `Salva e ripubblica`,
  [`Save as a new version`]: `Salva come nuova versione`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Salvare il segreto client (non sarà più possibile richiamarlo per scopi di sicurezza)`,
  [`Save this product as a new version`]: `Salva questo prodotto come nuova versione`,
  [`Saved`]: `Salvato`,
  [`Saving assembly...`]: `Salvataggio dell'assemblaggio...`,
  [`Saving...`]: `Salvataggio...`,
  [`Schema Type`]: `Tipo di schema`,
  [`Schema as JSON`]: `Schema come JSON`,
  [`Schema as XML`]: `Schema come XML`,
  [`Schema as YAML`]: `Schema come YAML`,
  [`Schema change`]: `Modifica schema`,
  [`Schema changes`]: `Modifiche di schema`,
  [`Schema definition circular reference limit`]: `Limite di riferimento circolare della definizione schema`,
  [`Schema name`]: `Nome schema`,
  [`Schema warnings`]: `Avvertenze schema`,
  [`Schema definition`]: `Definizione schema`,
  [`Scheme`]: `Schema`,
  [`Schemes`]: `Schemi`,
  [`Scope`]: `Ambito`,
  [`Scopes`]: `Ambiti`,
  [`Scopes cannot be empty`]: `Gli ambiti non possono essere vuoti`,
  [`Search`]: `Cerca`,
  [`Search DN`]: `Ricerca DN`,
  [`Search in the field above above and select to add provider organizations`]: `Eseguire una ricerca nel campo precedente e selezionare per aggiungere le organizzazioni provider`,
  [`Search Limit`]: `Limite ricerca`,
  [`Search Organization owner from`]: `Ricerca proprietario organizzazione da`,
  [`Search orgs/groups`]: `Ricerca org/gruppi`,
  [`Search Owner`]: `Ricerca proprietario`,
  [`Search assemblies`]: `Ricerca assemblaggi`,
  [`Search for`]: `Ricerca`,
  [`Search for provider organizations`]: `Ricerca organizzazioni provider`,
  [`Search for Owner`]: `Ricerca proprietario`,
  [`Search for a new member to become the new owner.`]: `Cercare un nuovo membro perché diventi il nuovo proprietario.`,
  [`Search for an existing member to become the new owner.`]: `Cercare un membro esistente perché diventi il nuovo proprietario.`,
  [`Search for new owner`]: `Cerca nuovo proprietario`,
  [`Search for owner from:`]: `Cerca proprietario da:`,
  [`Search for the member`]: `Ricerca di un membro`,
  [`Search for title or name or version`]: `Ricerca titolo, nome o versione`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Ricercare l'utente che si desidera aggiungere come membro ed assegnare i ruoli richiesti.`,
  [`Search overflow`]: `Overflow ricerca`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Ricercare il registro per l'utente esistente che si desidera aggiungere come membro e assegnare i ruoli necessari.`,
  [`Search types/fields`]: `Ricerca tipi/campi`,
  [`Search user`]: `Ricerca utente`,
  [`Search schema`]: `Ricerca schema`,
  [`Seconds`]: `Secondi`,
  [`Secret Key`]: `Chiave segreta`,
  [`Secret Name`]: `Nome segreto`,
  [`Secret Required`]: `Segreto richiesto`,
  [`Secret key`]: `Chiave segreta`,
  [`Secure`]: `Proteggi`,
  [`Secure connection`]: `Connessione sicura`,
  [`Secure using Client ID`]: `Proteggi mediante ID client`,
  [`Security`]: `Sicurezza`,
  [`Security & Product Definitions`]: `Sicurezza & definizioni del prodotto`,
  [`Security definitions`]: `Definizioni di sicurezza`,
  [`Security and Publish`]: `Sicurezza e pubblicazione`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Le definizioni di sicurezza controllano l'accesso client agli endpoint API, incluso la convalida della chiave API, l'autorizzazione di base attraverso un registro utente specificato e OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Le definizioni di sicurezza controllano l'accesso client agli endpoint API incluso la convalida della chiave API, l'autenticazione di base attraverso un registro utente specificato e OAuth. [Ulteriori informazioni]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Le definizioni di sicurezza selezionate qui vengono applicate all'API, ma possono essere sovrascritte per le singole operazioni.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Le definizioni di sicurezza selezionate qui vengono applicate all'API, ma possono essere sovrascritte per le singole operazioni. [Ulteriori informazioni]({link})`,
  [`Security object to use to decode the claim.`]: `Oggetto di sicurezza utilizzato per decodificare la richiesta.`,
  [`Security object used to encrypt the JWT.`]: `Oggetto di sicurezza utilizzato per la codifica del JWT.`,
  [`Security object used to sign the JWT.`]: `Oggetto di sicurezza utilizzato per firmare il JWT.`,
  [`Security object used to verify the claim.`]: `Oggetto di sicurezza utilizzato per verificare la richiesta.`,
  [`Select APIs to add to this product`]: `Selezionare le API da aggiungere a questo prodotto`,
  [`Select API Type`]: `Seleziona tipo di API`,
  [`Select API type`]: `Seleziona tipo di API`,
  [`Select a billing integration in the Product plans.`]: `Selezionare un'integrazione di fatturazione nei piani del prodotto.`,
  [`Select a flow...`]: `Selezionare un flusso...`,
  [`Select a plan from your existing product`]: `Selezionare un piano dal prodotto esistente`,
  [`Select a policy file to import to this catalog`]: `Selezionare un file della politica da importare in questo catalogo`,
  [`Select a policy implementation file to import to this catalog`]: `Selezionare un file di implementazione della politica da importare in questo catalogo`,
  [`Select a product`]: `Selezionare un prodotto`,
  [`Select Certificate Manager service`]: `Selezionare servizio Certificate Manager`,
  [`Select Cloud`]: `Selezionare Cloud`,
  [`Select Email Server`]: `Selezionare server e-mail`,
  [`Select gateway services`]: `Selezionare servizi gateway`,
  [`Select Import Type`]: `Selezionare tipo di importazione`,
  [`Select product`]: `Selezionare prodotto`,
  [`Select product type`]: `Selezionare tipo di prodotto`,
  [`Select Service`]: `Selezionare servizio`,
  [`Select Service Type`]: `Selezionare tipo di servizio`,
  [`Select target endpoints`]: `Selezionare endpoint di destinazione`,
  [`Select target service`]: `Selezionare servizio di destinazione`,
  [`Select user registry type`]: `Selezionare il tipo di registro utente`,
  [`Select a WSDL service from the imported file`]: `Selezionare un servizio WSDL dal file importato`,
  [`Select a certificate`]: `Selezionare un certificato`,
  [`Select a Certificate Manager service`]: `Selezionare un servizio Certificate Manager`,
  [`Select a cryptographic algorithm.`]: `Selezionare un algoritmo crittografico.`,
  [`Select a default catalog and organization`]: `Selezionare un catalogo predefinito ed un'organizzazione`,
  [`Select a different cloud`]: `Selezionare un cloud diverso`,
  [`Select a key encryption algorithm.`]: `Selezionare un algoritmo di codifica della chiave.`,
  [`Select a plan to migrate`]: `Selezionare un piano da migrare`,
  [`Select a plan to replace`]: `Selezionare un piano da sostituire`,
  [`Select a plan to supersede`]: `Selezionare un piano da sovrascrivere`,
  [`Select a product to set as the migration target`]: `Selezionare un prodotto da impostare come destinazione della migrazione`,
  [`Select a product to supersede`]: `Selezionare un prodotto per sovrascrivere`,
  [`Select a product to supersede {sourceProductName}:`]: `Selezionare un prodotto per sovrascrivere {sourceProductName}:`,
  [`Select a resource group`]: `Selezionare un gruppo di risorse`,
  [`Select a service`]: `Selezionare un servizio`,
  [`Select a supported file ({type}) and try again.`]: `Selezionare un file supportato ({type}) e riprovare.`,
  [`Select a target SOAP service`]: `Selezionare un servizio SOAP di destinazione`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Selezionare un package di toolkit da scaricare. Ignorare questo passo se si dispone del toolkit installato.`,
  [`Select an encryption algorithm.`]: `Selezionare un algoritmo di codifica.`,
  [`Select an extension file for this gateway`]: `Selezionare un file di estensione per questo gateway`,
  [`Select an org`]: `Selezionare un'organizzazione`,
  [`Select catalog`]: `Selezionare catalogo`,
  [`Select Catalogs to Search`]: `Selezionare cataloghi in cui ricercare`,
  [`Select compatible gateway services`]: `Seleziona tutti i servizi gateway compatibili`,
  [`Select one Keystore from the list below:`]: `Selezionare un keystore dall'elenco riportato di seguito:`,
  [`Select one keystore from the list below:`]: `Selezionare un keystore dall'elenco riportato di seguito:`,
  [`Select one keystore from the list.`]: `Selezionare un keystore dall'elenco.`,
  [`Select operation...`]: `Selezionare operazione...`,
  [`Select operations you want to include`]: `Selezionare le operazioni che si desidera includere`,
  [`Select organization`]: `Selezionare organizzazione`,
  [`Select the API definition or package file to import`]: `Selezionare la definizione API o il file del package da importare`,
  [`Select the billing integration for this catalog`]: `Selezionare l'integrazione di fatturazione per questo catalogo`,
  [`Select the billing integration for this product`]: `Selezionare l'integrazione di fatturazione per questo prodotto`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Selezionare i gateway che proteggeranno le API in un nuovo catalogo. Per registrare un nuovo gateway, visitare la pagina **Gateway**.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Selezionare il servizio [Certificate Manager]({link}) che gestisce i certificati per questo gateway.`,
  [`Select the APIs to add to this product`]: `Selezionare le API da aggiungere a questo prodotto`,
  [`Select the OpenAPI definition file to import`]: `Selezionare il file di definizione OpenAPI da importare`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Selezionare le azioni del ciclo di vita del prodotto che richiedono approvazione. Quando viene eseguita una delle azioni selezionate, viene generata una richiesta di approvazione; per completare l'azione, è necessario che la richiesta venga approvata.`,
  [`Select the TLS protocol versions for this profile.`]: `Selezionare le versioni del protocollo TLS per questo profilo.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Selezionare il servizio analisi che si desidera associare al servizio gateway`,
  [`Select the consumer organization in which you want to create the application`]: `Selezionare l'organizzazione consumer in cui si desidera creare l'applicazione`,
  [`Select the consumer organization that will own the application`]: `Selezionare l'organizzazione consumer che sarà proprietaria dell'applicazione`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Selezionare gli endpoint per pubblicare questa API e definire il limite di frequenza in base alle necessità`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Selezionare gli errori che, se generati durante l'esecuzione della politica, causano l'arresto del flusso di assemblaggio. Se è stato configurato un flusso di cattura per l'errore, viene attivato per gestire l'errore generato. Se viene generato un errore e non sono stati selezionati errori per l'impostazione Interrompi in caso di errore o se l'errore generato non è uno degli errori selezionati, l'esecuzione della politica viene completata e il flusso di assemblaggio continua.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Selezionare i servizi gateway in cui devono essere resi disponibili le API nel Prodotto.`,
  [`Select the gateway type for this API`]: `Selezionare il tipo di gateway per questa API`,
  [`Select the gateway type for this OAuth provider`]: `Selezionare il tipo di gateway per questo provider OAuth`,
  [`Select the keystores and truststores for this profile.`]: `Selezionare il keystore ed il truststore per questo profilo.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Selezionare le organizzazioni o i gruppi a  cui si desidera rendere visibile questo prodotto`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Selezionare le organizzazioni o gruppi per cui si desidera eseguire la sottoscrizione a questo prodotto`,
  [`Select the portal service to use for this catalog`]: `Selezionare il servizio portale da utilizzare per questo catalogo`,
  [`Select the product and plan for the application subscription`]: `Selezionare il prodotto ed il piano per la sottoscrizione dell'applicazione`,
  [`Select the product definition file to import`]: `Selezionare il file di definizione del prodotto da importare`,
  [`Select the product definition file to import from`]: `Selezionare il file di definizione prodotto da cui importare`,
  [`Select the product that will supersede the originally selected product`]: `Selezionare il prodotto che sovrascriverà il prodotto originariamente selezionato`,
  [`Select the product that you want to add the API to`]: `Selezionare il prodotto a cui si desidera aggiungere l'API`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Selezionare i registri che si desidera utilizzare per controllare l'accesso alle API in questo catalogo`,
  [`Select the required operations`]: `Selezionare le operazioni richieste`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Selezionare il livello di gravità dei messaggi di log relativi ai dati di input; i possibili livelli sono errore, avvertenza e info.`,
  [`Select the target wsdl file to create from`]: `Selezionare il file wsdl di destinazione da cui creare`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Selezionare il file wsdl, xsd o zip di destinazione da cui creare la definizione API`,
  [`Select the target WSDL file to import`]: `Selezionare il file WSDL di destinazione da importare`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Selezionare i registri utente per autenticare gli utenti di API Manager o creare un nuovo registro utente.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Selezionare i registri utente per autenticare gli utenti di API Manager o creare un nuovo registro utente.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Selezionare i registri utente per autenticare gli utenti di Cloud Manager o creare un nuovo registro utente.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Selezionare i registri utente utilizzati per autenticare l'accesso a Developer Portal`,
  [`Select the user registry type`]: `Selezionare il tipo di registro utente`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Selezionare il punto dell'origine dell'output in cui la politica deve posizionare il token LTPA generato.  In WSSec, selezionare Intestazione solo se content-type nella richiesta è application/xml.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Selezionare se rimuovere o meno la proprietà completamente o revisionare il campo utilizzando asterischi "*"  per bloccare i dati.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Il prodotto selezionato è stato modificato. Si prega di aggiornare questa pagina e riprovare.`,
  [`Selecting operations from WSDL service definition`]: `Selezionere delle operazioni dalla definizione del servizio WSDL`,
  [`Self Service Onboarding for Consumer organization`]: `Procedura di avvio autonoma per l'organizzazione consumer`,
  [`Self service onboarding`]: `Procedura di avvio autonoma`,
  [`Self service onboarding approval`]: `Approvazione della procedura di avvio autonoma`,
  [`Send`]: `Invia`,
  [`Send Message`]: `Invia messaggio`,
  [`Send Message to Owners`]: `Invia messaggio ai proprietari`,
  [`Send To`]: `Invia a`,
  [`Send a registration link to a new user to register as owner.`]: `Invia un link di registrazione ad un nuovo utente perché si registri come proprietario.`,
  [`Send a test email`]: `Invia un'e-mail di prova`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Inviare una e-mail di prova per confermare che il server e-mail è configurato correttamente.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Invia un segnale di test per confermare che l'endpoint sia configurato correttamente.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Invia un segnale di test per confermare che l'endpoint sia configurato correttamente`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Invia un messaggio e-mail al proprietario dell'organizzazione admin. Inserire un segno di spunta accanto al nome del proprietario`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Inviare un messaggio e-mail ad un proprietario. Inserire un segno di spunta accanto al nome del proprietario`,
  [`Send chunked-encoded documents to the target server.`]: `Inviare documenti codificati in chunk al server di destinazione.`,
  [`Send message`]: `Invia messaggio`,
  [`Send message to owner`]: `Invia un messaggio al proprietario`,
  [`Send test email`]: `Invia e-mail di prova`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Invia il link di attivazione ad un utente per invitarlo ad eseguire l'accesso o la registrazione.`,
  [`Send-only`]: `Solo invio`,
  [`Sender`]: `Mittente`,
  [`Sender & email server`]: `Mittente & server di e-mail`,
  [`Sender & email server have been changed`]: `Mittente & server e-mail modificati`,
  [`Sender Info`]: `Informazioni mittente`,
  [`Sent`]: `Inviato`,
  [`Sent events: **{sent}**`]: `Eventi inviati: **{sent}**`,
  [`Serial`]: `Seriale`,
  [`Serialize output`]: `Serializza output`,
  [`Server connection`]: `Connessione server`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `Trasmetti l'HTML ai browser web per abilitare il client GUI GraphQL`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Trasmetti l'HTML ai browser Web per abilitare il client GUI GraphQL.`,
  [`Server Name Indication (SNI)`]: `SNI (Server Name Indication)`,
  [`Server URL`]: `URL server`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Il server ha restituito un errore in hash invece di un token nell'URL di reindirizzamento OIDC. Hash:`,
  [`Service name`]: `Nome servizio`,
  [`Services`]: `Servizi`,
  [`Set`]: `Imposta`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Impostare la variabile di ambiente del sistema operativo APIC_DESIGNER_CREDENTIALS per API Designer. Ad esempio, su MAC, l'utente può trasmettere la variabile di ambiente durante l'avvio di API Designer.`,
  [`Set Migration Target`]: `Imposta destinazione di migrazione`,
  [`Set migration target`]: `Imposta destinazione di migrazione`,
  [`Set Variable`]: `Imposta variabile`,
  [`Set as default`]: `Imposta come predefinito`,
  [`Set default`]: `Imposta come predefinito`,
  [`Set rate and burst limits in an assembly`]: `Imposta limiti di frequenza e burst in un assemblaggio`,
  [`Set the timeout period for invitations`]: `Impostare il periodo di timeout per gli inviti`,
  [`Set the trust level for the profile`]: `Imposta il livello di attendibilità per il profilo`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Imposta il valore sul numero di iterazioni di una definizione schema circolare che sono consentite (valore predefinito 1, massimo 5).`,
  [`Set the visibility for a gateway service`]: `Imposta la visibilità per un servizio gateway`,
  [`Set the visibility for a portal service`]: `Imposta la visibilità per un servizio portale`,
  [`Set the visibility for each service`]: `Imposta la visibilità per ciascun servizio`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Impostare l'intervallo di tempo per ottenere un nuovo token di accesso emesso dal server di autenticazione`,
  [`Set visibility`]: `Imposta visibilità`,
  [`Set visibility of gateway`]: `Imposta visibilità del gateway`,
  [`Set time interval`]: `Imposta intervallo di tempo`,
  [`Set, Add, or Clear a runtime variable.`]: `Imposta, aggiunge o cancella una variabile runtime.`,
  [`Set the timeout period for API key`]: `Impostare il periodo di timeout per la chiave API`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Imposta il periodo di validità in secondi per i documenti nella cache. Si applica solo se il tipo di cache è impostato su "Time to Live". Immettere un valore compreso tra 5 e 31708800. Il valore predefinito è 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `L'impostazione di un valore per "assumedSize" non può essere combinata con la definizione di un argomento di suddivisione che ha un valore predefinito o non può essere combinata con l'abilitazione di "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `L'impostazione di questo catalogo come catalogo predefinito consentirà di eseguire l'accesso utilizzando un URL più breve, omettendo il nome del catalogo. È possibile impostare un solo catalogo come predefinito.`,
  [`Settings`]: `Impostazioni`,
  [`Setup`]: `Configurazione`,
  [`Severity level for input data log messages`]: `Livello di gravità per i messaggi di log dei dati di input`,
  [`Share the resource with a custom list of provider organizations`]: `Condivide la risorsa con un elenco personalizzato di organizzazioni provider`,
  [`Share the resource with provider organizations`]: `Condivide la risorsa con le organizzazioni provider`,
  [`Share the API gateway with a custom list of provider organizations`]: `Condivide il gateway API con un elenco personalizzato di organizzazioni provider`,
  [`Share the service with a custom list of provider organizations`]: `Condivide il servizio con un elenco personalizzato di organizzazioni provider`,
  [`Share the API gateway with provider organizations`]: `Condivide il gateway API con le organizzazioni provider`,
  [`Share the service with provider organizations`]: `Condivide il servizio con le organizzazioni provider`,
  [`Share`]: `Condividi`,
  [`Shared`]: `Condiviso`,
  [`Shared by`]: `Condiviso da`,
  [`Short but descriptive name for this policy.`]: `Nome breve ma descrittivo per questa politica.`,
  [`Show`]: `Mostra`,
  [`Show / hide policy palette`]: `Mostra / nascondi tavolozza politica`,
  [`Show/hide`]: `Mostra/nascondi`,
  [`Show/hide settings`]: `Mostra/nascondi impostazioni`,
  [`Show and hide types and fields to include in the schema.`]: `Mostra e nasconde i tipi e i campi da includere nello schema.`,
  [`Show added only`]: `Mostra solo elementi aggiunti`,
  [`Show all`]: `Mostra tutto`,
  [`Show all UDP versions`]: `Mostra tutte le versioni UDP`,
  [`Show app identifier`]: `Mostra identificativo app`,
  [`Show breaking changes only`]: `Mostra solo modifiche di interruzione`,
  [`Show catalog identifier`]: `Mostra identificativo catalogo`,
  [`Show catches`]: `Mostra catture`,
  [`Show in schema`]: `Mostra nello schema`,
  [`Show less`]: `Mostra meno`,
  [`Show modified only`]: `Mostra solo elementi modificati`,
  [`Show more`]: `Mostra altro`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Mostrare i nuovi tipi, i campi, le direttive o gli argomenti facoltativi. Gli elementi non più supportati dal backend verranno rimossi.`,
  [`Show removed only`]: `Mostra solo elementi rimossi`,
  [`Show schema`]: `Mostra schema`,
  [`Show warnings only`]: `Mostra solo avvertenze`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Mostra la cronologia delle assegnazioni di keystore tranne il keystore assegnato attualmente.`,
  [`Sign Crypto Object`]: `Oggetto crittografico firma`,
  [`Sign in`]: `Accedi`,
  [`Sign JWK variable name`]: `Nome variabile JWK firma`,
  [`Sign Out`]: `Disconnetti`,
  [`Sign up`]: `Registrazione`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Accedi utilizzando il registro utente {userRegistryType}`,
  [`Sign in with`]: `Accesso con`,
  [`Sign out`]: `Scollega`,
  [`Sign up with`]: `Accesso con`,
  [`Signed token`]: `Token firmato`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Siccome è stato disabilitato il buffering, è necessario che il contenuto e/o il contenuto dell'errore venga diminuito dal payload alle intestazioni. Si desidera proseguire con la modifica richiesta o annullare le modifiche apportate?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Siccome il contenuto e/o il contenuto dell'errore è stato impostato su payload, è necessario che il buffering sia abilitato. Si desidera proseguire con la modifica richiesta o annullare le modifiche apportate?`,
  [`Sized fields`]: `Campi dimensionati`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Argomenti di suddivisione`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Alcuni input sono array, o sono contenuti all'interno di array. Per iterare i contenuti, scegliere l'opzione itera e selezionare l'input su cui eseguire l'iterazione.`,
  [`Something went wrong.`]: `Si è verificato un errore.`,
  [`Something went wrong...`]: `Si è verificato un errore...`,
  [`Source`]: `Sorgente`,
  [`Source schema`]: `Schema origine`,
  [`Space`]: `Spazio`,
  [`Space is not allowed in scope name`]: `Lo spazio non è consentito nel nome dell'ambito`,
  [`Space details`]: `Dettagli spazio`,
  [`Space Enabled`]: `Spazio abilitato`,
  [`Space Name`]: `Nome spazio`,
  [`Space Summary`]: `Riepilogo spazi`,
  [`Space Title`]: `Titolo spazio`,
  [`Space owner`]: `Proprietario dello spazio`,
  [`Space {name} created`]: `Spazio {name} creato`,
  [`Spaces`]: `Spazi`,
  [`Spaces are enabled`]: `Gli spazi sono abilitati`,
  [`Spaces are not enabled`]: `Gli spazi non sono consentiti`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Gli spazi non possono essere abilitati per il catalogo sandbox.`,
  [`Spaces cannot be enabled.`]: `Gli spazi non possono essere abilitati.`,
  [`Spaces dropdown`]: `Elenco a discesa spazi`,
  [`Spaces has been enabled`]: `Spazi abilitati`,
  [`Spaces have been enabled.`]: `Gli spazi sono stati abilitati.`,
  [`Spaces per page`]: `Spazi per pagina`,
  [`Spanish`]: `Spagnolo`,
  [`Special characters are not accepted`]: `I caratteri speciali non sono accettati`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Specifica un URL o stringa che rappresenta un contesto denominato da cui recuperare le proprietà serializzate XML o JSON che si uniscono nell'oggetto dinamico. Queste proprietà hanno la precedenza rispetto alle proprietà predefinite o ai valori letterali esistenti.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Specifica una stringa letterale come proprietà serializzate XML o JSON che si uniscono nell'oggetto dinamico. Queste proprietà hanno la precedenza rispetto alle proprietà predefinite esistenti.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Specifica un oggetto esistente valido da cui recuperare i valori della proprietà predefiniti per l'oggetto dinamico. Avvertenza: se si passa da predefinito a stringa, l'oggetto impostazione analisi specificato deve essere disponibile sul dispositivo DataPower.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Specifica l'origine dati con il contenuto da redigere o rimuovere. Se la root non viene specificata, l'azione viene applicata all'intero contesto API. È possibile utilizzare qualsiasi espressione di percorso JSONata supportata.\n\nLa redazione dei dati di richiesta e di risposta può essere eseguita con una root di "message.body". Se l'azione di assemblaggio redazione viene utilizzata in un assemblaggio dopo un'azione log assemblaggio, che specifica la modalità di sola raccolta, la root può specificare "log.request_body" per il payload della richiesta registrata o "log.response_body" per il payload della risposta registrata.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Specifica il nome di una variabile nel contesto API. Il contenuto del campo "corpo" della variabile è l'input per l'azione. Il nome variabile predefinito è "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Specifica il nome di una variabile nel contesto API. Il contenuto del campo "corpo" della variabile è l'output dell'analisi. Le metriche di analisi del documento analizzato possono essere memorizzate in differenti parti del messaggio. Il nome variabile predefinito è qualunque cosa sia il nome di input, pertanto per impostazione predefinita il messaggio di input verrà sovrascritto con il messaggio di output.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Specifica se e dove ottenere i metadati personalizzati per il token di accesso.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Specifica se tentare un'analisi con il tipo di contenuto specificato. Questo è applicabile solo se il tipo di contenuto previsto è json o xml. Con questa opzione abilitata, un'azione di analisi avrà esito negativo se il tipo di contenuto non corrisponde alle impostazioni di analisi (cioè Content-type:application/json e parse-settings è configurato per xml). Se l'analisi è configurata per rilevare il contenuto di input, sarà forzata al tipo di contenuto specificato.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Specifica se serializzare la struttura ad albero dell'output XSLT in dati binari dopo la trasformazione.`,
  [`Specify default gateways`]: `Specifica gateway predefiniti`,
  [`Specify email`]: `Specifica e-mail`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Specificare una definizione di schema OpenAPI (Swagger) con cui convalidare il payload ("request", "response" o "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Specificare il proprietario dell'organizzazione consumer`,
  [`Specify owner of the provider organization`]: `Specificare il proprietario dell'organizzazione provider`,
  [`Specify settings for the third party OAuth provider.`]: `Specifica le impostazioni per il provider OAuth di terze parti.`,
  [`Specify the MIME types that your API consumes and produces`]: `Specificare i tipi MIME che l'API utilizza e produce`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Specificare il proprietario del catalogo; il proprietario dispone di tutte le autorizzazioni del catalogo`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Specificare le organizzazioni consumer o le comunità di sviluppatori che saranno in grado di visualizzare il prodotto nel Developer Portal`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Specificare le organizzazioni consumer o le comunità di sviluppatori che saranno in grado di effettuare la sottoscrizione per l'utilizzo delle API nel prodotto`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Specificare l'URL di base dell'endpoint di richiamo dell'API ed i domini gestiti dal gateway.`,
  [`Specify the details of the catalog that you want to publish to`]: `Specificare i dettagli del catalogo in cui si desidera eseguire la pubblicazione`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Specificare l'insieme predefinito dei gateway che proteggeranno le API in un nuovo catalogo. [Ulteriori informazioni]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Specificare gli endpoint su cui questa API deve essere pubblicata, e se si desidera limitare la frequenza delle chiamate API`,
  [`Specify the new owner`]: `Specificare il nuovo proprietario`,
  [`Specify the owner`]: `Specifica il proprietario`,
  [`Specify the owner and title`]: `Specifica proprietario e titolo`,
  [`Specify the properties to redact or remove.`]: `Specificare le proprietà da revisionare o rimuovere.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Specificare i requisiti di pubblicazione e di limite della frequenza per questa API`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Specificare l'endpoint di controllo dell'ambito in cui viene eseguita un'ulteriore verifica dell'ambito in aggiunta agli ambiti definiti.`,
  [`Specify the security settings for the imported API definition`]: `Specificare le impostazioni di sicurezza per la definizione API importata`,
  [`Specify the security settings for your API definition`]: `Specificare le impostazioni di sicurezza per la propria definizione API`,
  [`Specify the space owner; the owner has all space permissions`]: `Specificare il proprietario dello spazio; il proprietario dispone di tutte le autorizzazioni dello spazio`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Specificare i dettagli di riepilogo dell'API; se necessario, è possibile configurare ulteriormente la definizione dell'API dopo averla creata`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Specificare il periodo di timeout dopo il quale scadono le e-mail di invito se l'utente non attiva il proprio account.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Specificare il periodo di timeout per la scadenza delle e-mail di invito se l'utente non attiva il proprio account.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Specificare il tipo di gateway, un titolo ed un riepilogo che descrive il gateway.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Specificare la visibilità del gateway API per l'organizzazione provider API Connect.`,
  [`Specify the way you want to validate the message.`]: `Specificare come si desidera convalidare il messaggio.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Specificare l'URL dell'endpoint di gestione del gateway ed il certificato per il dominio dell'endpoint.`,
  [`Specify what content to log in the event of an error.`]: `Specifica il contenuto da registrare in caso di errore.`,
  [`Specify what content to log.`]: `Specifica il contenuto da registrare.`,
  [`Specifying the publish target details`]: `Specifica dei dettagli di destinazione della pubblicazione`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Consenti specificamente la regole xsi:type=SOAP-ENC:Array`,
  [`Stage`]: `Esegui staging`,
  [`Stage API`]: `Esegui staging API`,
  [`Stage product`]: `Esegui staging prodotto`,
  [`Stage to`]: `Esegui staging in`,
  [`Staged`]: `Sottoposto a staging`,
  [`Stage|permission`]: `Esegui staging`,
  [`Standard OIDC`]: `OIDC standard`,
  [`Static DNS`]: `DNS statico`,
  [`Static group`]: `Gruppo statico`,
  [`Status`]: `Stato`,
  [`Status code`]: `Codice di stato`,
  [`Step 1`]: `Passo 1`,
  [`Step 1: Download`]: `Passo 1: Scarica`,
  [`Step 1: Download toolkit`]: `Passo 1: Scarica toolkit`,
  [`Step 1: Upload private key`]: `Passo 1: Carica chiave privata`,
  [`Step 2`]: `Passo 2`,
  [`Step 2: Download credentials`]: `Passo 2: Scarica credenziali`,
  [`Step 2: Download toolkit credentials`]: `Passo 2: Scarica le credenziali del toolkit`,
  [`Step 2: Install`]: `Passo 2: Installa`,
  [`Step 2: Upload public key`]: `Passo 2: Carica chiave pubblica`,
  [`Step 3`]: `Passo 3`,
  [`Step 3: Configure`]: `Passo 3: Configura`,
  [`Step 3: Run command`]: `Passo 3: Esegui comando`,
  [`Step 3: Run commands`]: `Passo 3: Esegui comandi`,
  [`Step 4: Register`]: `Passo 4: Registra`,
  [`Stop`]: `Arresta`,
  [`Stop after:`]: `Interrompi dopo:`,
  [`Stop all the retries`]: `Arresta tutti i tentativi`,
  [`Stop Connection`]: `Arresta connessione`,
  [`Stop on error`]: `Interrompi in caso di errore`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Arrestare l'esecuzione di questa API e rimuoverla dal catalogo Sandbox.`,
  [`Stopped`]: `Arrestato`,
  [`Stopping Published API`]: `Arresto API pubblicata`,
  [`Strict`]: `Assoluto`,
  [`Strict SOAP envelope version`]: `Versione busta SOAP assoluto`,
  [`String Badgerfish`]: `Stringa Badgerfish`,
  [`String`]: `Stringa`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `Stringa inclusa nel segmento di organizzazione dell'URL per le chiamate API. Utilizzare lettere minuscole, numeri e trattini.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Stripe integration`,
  [`Streaming rule`]: `Regola di flusso`,
  [`Subject`]: `Oggetto`,
  [`Subject Claim`]: `Richiesta oggetto`,
  [`Subject Origin`]: `Origine oggetto`,
  [`Subject common name`]: `Nome comune oggetto`,
  [`Subject|certificate`]: `Oggetto`,
  [`Submit`]: `Invia`,
  [`Submitted`]: `Inoltrato`,
  [`Subscribability`]: `Sottoscrivibilità`,
  [`Subscribability must be disabled if visibility is disabled`]: `La sottoscrizione deve essere disabilitata se la visibilità è disabilitata`,
  [`Subscribability options`]: `Opzioni di sottoscrizione`,
  [`Subscribable by`]: `Sottoscrivibile da`,
  [`Subscribe`]: `Sottoscrivi`,
  [`Subscribe Financial Management App`]: `Sottoscrivi app di gestione finanziaria`,
  [`Subscribe TODO App`]: `Sottoscrivi app TODO`,
  [`Subscription`]: `Sottoscrizione`,
  [`Subscription approval task for subscription of`]: `Attività di approvazione della sottoscrizione per la sottoscrizione a`,
  [`Subscription created`]: `Sottoscrizione creata`,
  [`Subscription has been added`]: `La sottoscrizione è stata aggiunta`,
  [`Subscription has not been added`]: `La sottoscrizione non è stata aggiunta.`,
  [`Subscription pending approval`]: `Sottoscrizione in attesa di approvazione`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `Il tipo di sottoscrizione è stato rimosso perché le sottoscrizioni GraphQL non sono supportate.`,
  [`Subscription-Approval`]: `Approvazione-Sottoscrizione`,
  [`Subscriptions`]: `Sottoscrizioni`,
  [`Success`]: `Riuscito`,
  [`Suffix`]: `Suffisso`,
  [`Suggestion`]: `Suggerimento`,
  [`Supersede`]: `Sovrascrivi`,
  [`Supersede Product`]: `Sovrascrivi prodotto`,
  [`Supersede product`]: `Sovrascrivi prodotto`,
  [`Supersede plans`]: `Sovrascrivi piani`,
  [`Supersede {product1} with {product2}`]: `Sostituire il prodotto  {product1} con {product2}`,
  [`Supersede|permission`]: `Sovrascrivi`,
  [`Supersede|supersede with`]: `Sovrascrivi`,
  [`Support`]: `Supporto`,
  [`support default introspection`]: `Supporta introspezione predefinita`,
  [`Support Server Name Indication (SNI)`]: `Supporta SNI (Server Name Indication)`,
  [`Support hybrid response types`]: `Supporta i tipi di risposta ibrida`,
  [`Support standard introspection`]: `Supporta introspezione standard`,
  [`Supported OAuth components`]: `Componenti OAuth supportati`,
  [`Supported client types`]: `Tipi di client supportati`,
  [`Supported client types can not be empty.`]: `Il tipo di client supportato non può essere vuoto.`,
  [`Supported grant type can not be empty.`]: `Il tipo di concessione supportato non può essere vuoto.`,
  [`Supported grant types`]: `Tipi di concessione supportati`,
  [`Suspend`]: `Sospendi`,
  [`Suspend API`]: `Sospendi API`,
  [`Suspend Application`]: `Sospendi applicazione`,
  [`Switch`]: `Switch`,
  [`Switch account`]: `Cambia account`,
  [`Switch cloud connection`]: `Cambia connessione cloud`,
  [`Switch directory`]: `Cambia directory`,
  [`TARGET`]: `DESTINAZIONE`,
  [`Title`]: `Titolo`,
  [`TlS`]: `TLS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `Client TLS`,
  [`TLS Client Profile`]: `Profilo client TLS`,
  [`TLS Client Profile has been configured.`]: `Il profilo del client TLS è stato configurato.`,
  [`TLS client profile is required when endpoint starts with https://`]: `Il profilo del client TLS è richiesto quando l'endpoint inizia con https://`,
  [`TLS Client Profiles`]: `Profili client TLS`,
  [`TLS client profile has been configured.`]: `Il profilo del client TLS è stato configurato.`,
  [`TLS client profiles`]: `Profili client TLS`,
  [`TLS Profile`]: `Profilo TLS`,
  [`TLS SERVER PROFILE`]: `PROFILO SERVER TLS`,
  [`TLS server profile`]: `Profilo server TLS`,
  [`TLS version 1.0`]: `TLS versione 1.0`,
  [`TLS version 1.1`]: `TLS versione 1.1`,
  [`TLS version 1.2`]: `TLS versione 1.2`,
  [`TLS version 1.3`]: `TLS versione 1.3`,
  [`TLS client profile`]: `Profilo client TLS`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `I profili client TLS sono gestiti in ciascuno spazio quando gli spazi sono abilitati. Passare alle impostazioni dello spazio per gestire i profili client TLS.`,
  [`TLS profile`]: `Profilo TLS`,
  [`TLS profile is not visible or not available.`]: `Profilo TLS non visibile o non disponibile.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `Il profilo TLS da utilizzare per la trasmissione sicura dei dati all'URL di autenticazione.`,
  [`Tabs for code language options`]: `Schede per le opzioni del linguaggio del codice`,
  [`Truststore`]: `Truststore`,
  [`truststore`]: `truststore`,
  [`Type`]: `Tipo`,
  [`Type name`]: `Nome tipo`,
  [`Type/Field`]: `Tipo/Campo`,
  [`Type weight`]: `Peso tipo`,
  [`Types per page:`]: `Tipi per pagina:`,
  [`Tags`]: `Tag`,
  [`Tags and external documentation`]: `Tag e documentazione esterna`,
  [`Take offline`]: `Disconnetti`,
  [`Target`]: `Destinazione`,
  [`Target API (URL)`]: `API di destinazione (URL)`,
  [`Target SOAP service`]: `Servizio SOAP di destinazione`,
  [`Target Services`]: `Servizi di destinazione`,
  [`Target service URL`]: `URL servizio di destinazione`,
  [`Target services`]: `Servizi di destinazione`,
  [`Target URL`]: `URL destinazione`,
  [`Target application`]: `Applicazione di destinazione`,
  [`Target catalog`]: `Catalogo di destinazione`,
  [`Target gateway service`]: `Servizio gateway di destinazione`,
  [`Target gateway services`]: `Servizi gateway di destinazione`,
  [`Target plan`]: `Piano di destinazione`,
  [`Target product`]: `Prodotto di destinazione`,
  [`Target product rate limit`]: `Limite di frequenza del prodotto di destinazione`,
  [`Target product subscribability`]: `Sottoscrivibilità del prodotto di destinazione`,
  [`Target product visibility`]: `Visibilità del prodotto di destinazione`,
  [`Target schema`]: `Schema di destinazione`,
  [`Target services have been updated.`]: `I servizi di destinazione sono stati aggiornati.`,
  [`Task`]: `Attività`,
  [`Task has been updated`]: `L'attività è stata aggiornata`,
  [`Task self approval`]: `Approvazione autonoma attività`,
  [`Task self-approval`]: `Auto-approvazione attività`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Attività`,
  [`Tasks for creating new provider organizations`]: `Attività per la creazione di nuove organizzazioni provider`,
  [`TCP`]: `TCP`,
  [`Template`]: `Template`,
  [`Template: {title}`]: `Template: {title}`,
  [`Template: account approved`]: `Modello: account approvato`,
  [`Template: account denied`]: `Modello: account rifiutato`,
  [`Template: account pending approval`]: `Modello: account in attesa di approvazione`,
  [`Template: app lifecycle approved`]: `Modello: ciclo di vita app approvato`,
  [`Template: app lifecycle denied`]: `Modello: ciclo di vita app rifiutato`,
  [`Template: app lifecycle pending`]: `Modello: ciclo di vita app in sospeso`,
  [`Template: app reinstated`]: `Modello: nuova istanza app`,
  [`Template: app suspended`]: `Modello: app sospesa`,
  [`Template: app lifecycle cancelled`]: `Modello: ciclo di vita app annullato`,
  [`Template: app lifecycle request`]: `Modello: richiesta ciclo di vita app`,
  [`Template: invitation`]: `Modello: invito`,
  [`Template: mail server test connection`]: `Modello: verifica della connessione del server di posta`,
  [`Template: member invitation`]: `Modello: invito membro`,
  [`Template: password changed`]: `Modello: password modificata`,
  [`Template: password reset`]: `Modello: reimpostazione password`,
  [`Template: sign up`]: `Modello: registrazione`,
  [`Template: task consumer onboard request`]: `Modello: procedura di avvio del consumer dell'attività richiesta`,
  [`Template: task product lifecycle approved`]: `Modello: attività ciclo di vita prodotto approvata`,
  [`Template: task product lifecycle cancelled`]: `Modello: attività ciclo di vita prodotto annullata`,
  [`Template: task product lifecycle denied`]: `Modello: attività ciclo di vita prodotto rifiutata`,
  [`Template: task product lifecycle pending`]: `Modello: attività ciclo di vita prodotto in sospeso`,
  [`Template: task product lifecycle request`]: `Modello: ciclo di vita del prodotto dell'attività richiesta`,
  [`Template: task subscription cancelled`]: `Modello: attività sottoscrizione annullata`,
  [`Template: task subscription request`]: `Modello: sottoscrizione dell'attività richiesta`,
  [`Template: task subscription approved`]: `Modello: attività sottoscrizione approvata`,
  [`Template: task subscription denied`]: `Modello: attività sottoscrizione rifiutata`,
  [`Template: task subscription pending`]: `Modello: attività sottoscrizione in sospeso`,
  [`Temporarily disable subscribability`]: `Disabilita temporaneamente sottoscrivibilità`,
  [`Temporarily disable visibility`]: `Disabilita temporaneamente visibilità`,
  [`Terms of service`]: `Condizioni del servizio`,
  [`Test`]: `Test`,
  [`Test API`]: `Verifica API`,
  [`Test APIs`]: `Verifica API`,
  [`Test Bind & Get Base DN`]: `Verifica bind e acquisisci DN base`,
  [`Test configuration`]: `Verifica configurazione`,
  [`Test connection`]: `Verifica connessione`,
  [`Test connection failed.`]: `Verifica della connessione non riuscita.`,
  [`Test and production endpoints`]: `Endpoint di test e di produzione`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Verifica e controlla errore di risposta: richiesto redirect_uri prop, ricevuto {redirectUri}`,
  [`Test application`]: `Applicazione di test`,
  [`Test consumer org`]: `Organizzazione consumer di test`,
  [`Test email`]: `E-mail di prova`,
  [`Test endpoints`]: `Endpoint di test`,
  [`Test message from IBM API Connect`]: `Messaggio di test da IBM API Connect`,
  [`Testable`]: `Verificabile`,
  [`TEXT`]: `TESTO`,
  [`The "Definition" property must have a valid value.`]: `La proprietà "Definizione" deve avere un valore valido.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `La proprietà "Definizione" deve avere un valore di "request", "response", o iniziare con "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `CLI API Connect è offerto come un plugin IBM Cloud CLI. Per iniziare:`,
  [`The API has unsaved changes.`]: `L'API ha modifiche non salvate`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `L'API è stata interamente progettata e ha superato un punto cardine interno, ma non è stata ancora implementata.`,
  [`The API is in the implementation phase.`]: `L'API è in fase di implementazione.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `L'API è nella fase concettuale iniziale e non è completamente progettata o implementata.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `L'API potrebbe avere modifiche non pubblicate. Si desidera pubblicare nuovamente il prodotto?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `Lo schema DNS determina la composizione degli URL endpoint per le chiamate API in entrata al servizio gateway e per l'accesso a Developer Portal.`,
  [`The GatewayScript source code to execute.`]: `Il codice di origine GatewayScript da eseguire.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `L'URL schema GraphQL con cui il payload GraphQL deve essere convalidato.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Il metodo HTTP da utilizzare per il richiamo. Se omesso o impostato su "Conserva", verrà utilizzato il metodo dalla richiesta in entrata.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Il metodo HTTP da utilizzare per sostituire il metodo HTTP corrente. Se omesso o impostato su "Conserva", verrà utilizzato il metodo dalla richiesta in entrata.`,
  [`The JavaScript source code to execute.`]: `Il codice di origine JavaScript da eseguire.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `LDAP (Lightweight Directory Access Protocol) è un protocollo Internet per l'accesso e la gestione dei servizi di informazioni della directory distribuita su una rete. Se è abilitato un servizio LDAP enterprise, configurarlo come risorsa per fornire l'autenticazione utente.`,
  [`The Proxy URL to be used.`]: `L'URL di proxy da utilizzare.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `Il profilo SSL da utilizzare per la trasmissione sicura dei dati.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `Il profilo TLS da utilizzare per la trasmissione sicura dei dati.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `L'URL dello schema JSON per la convalida di un payload JSON.`,
  [`The URL of the XML schema for validating an XML payload.`]: `L'URL dello schema XML per la convalida di un payload XML.`,
  [`The URL to be invoked.`]: `L'URL da richiamare.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `L'URL dello schema WSDL con cui deve essere convalidato un payload SOAP.`,
  [`The XML element name to be used for JSON array elements.`]: `Il nome dell'elemento XML da utilizzare per gli elementi dell'array JSON.`,
  [`The XSLT source to execute.`]: `L'origine XSLT da eseguire.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `Il link di attivazione può essere inviato agli utenti per consentire loro di attivare il proprio account`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `Il servizio di analytics raccoglie gli eventi API dal servizio gateway. A ciascun gateway è associato un servizio di analytics. Ogni volta che viene richiamata un'operazione API, viene registrato un evento API con data/ora.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `Il servizio di analytics raccoglie gli eventi API dal servizio gateway. A ciascun servizio gateway può essere associato un servizio di analytics.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `Tramite l'URL di autorizzazione l'applicazione client ottiene le autorizzazioni. Tramite l'URL del token l'applicazione client scambia un'autorizzazione per un token di accesso.  L'URL di introspezione è dove l'API del gateway convalida i token di accesso che vengono emessi dal provider di terzi.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `Il percorso di base è il segmento iniziale dell'URL dell'API e non include il nome host o altri segmenti per i percorsi o le operazioni`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `Il tipo di cache determina se memorizzare i documenti nella cache, rispettando o sostituendo le direttive di controllo della cache HTTP ricevute dalla risposta del server. Questa proprietà ha effetto solo quando viene ricevuta una risposta dal server, in caso contrario la politica restituisce sempre la risposta non scaduta precedentemente salvata nella cache.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Il servizio Certificate Manager deve gestire i certificati per il dominio dell'endpoint di gestione del gateway ed i domini gestiti dal gateway. Inoltre, è necessario utilizzare IBM Cloud IAM per autorizzare il servizio API Connect Reserved Instance ad accedere al servizio Certificate Manager.`,
  [`The certificate with which to decrypt the buffer`]: `Il certificato con cui decodificare il buffer`,
  [`The certificate with which to encrypt the buffer`]: `Il certificato con cui codificare il buffer`,
  [`The change is critical`]: `La modifica è critica`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Le applicazioni client possono richiedere solo gli ambiti o un sottoinsieme di ambiti definiti in questo punto. Gli ambiti sono inclusi nei token di accesso generati dal provider. Quando si richiama un'API protetta da OAuth, il gateway controlla gli ambiti presenti nei token di accesso rispetto all'elenco di ambiti consentiti nella definizione di sicurezza per determinare se concedere l'accesso. Inoltre, è possibile applicare un controllo dell'ambito avanzato. Gli URL del controllo dell'ambito avanzato vengono richiamati dopo l'autenticazione dell'applicazione o dopo l'autenticazione utente in agli URL configurati. L'autorizzazione finale dell'ambito, concessa dal token di accesso, è il risultato di tutti i controlli dell'ambito.`,
  [`The crypto object to use to decode the claim.`]: `L'oggetto crittografico da utilizzare per decodificare la richiesta.`,
  [`The crypto object to use to encrypt the message.`]: `L'oggetto crittografico da utilizzare per codificare il messaggio.`,
  [`The crypto object to use to sign the JWT.`]: `L'oggetto crittografico da utilizzare per firmare il JWT.`,
  [`The crypto object to use to verify the signature.`]: `L'oggetto crittografico da utilizzare per verificare la firma.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `L'associazione corrente contiene riferimenti ad elementi dello schema non riconosciuti.`,
  [`The current organization does not contain any catalogs.`]: `L'organizzazione corrente non contiene i cataloghi.`,
  [`The default User registry must be included in the list`]: `Il registro utente predefinito deve essere incluso nell'elenco`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `La definizione rispetto alla quale deve essere convalidato il payload ("#/definizioni/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Il developer portal fornisce la vista consumer dei prodotti API pubblicati. Una volta configurato developer portal per il catalogo e pubblicati uno o più prodotti API, gli sviluppatori delle applicazioni possono ricercare ed utilizzare le API.`,
  [`The document is missing draft_api.info.version field.`]: `Il documento manca del campo draft_api.info.version.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `Il documento manca del campo draft_api.info.x-ibm-name.`,
  [`The document is missing draft_product.info.name field.`]: `Il documento manca del campo draft_product.info.name.`,
  [`The document is missing draft_product.info.version field.`]: `Il documento manca del campo draft_product.info.version.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `I vincoli supplementari specificano un argomento di suddivisione che non è un argomento in questo campo.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Questo campo ha un argomento limite con un valore predefinito. Pertanto, la dimensione presenta non può mai essere applicata.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Questo campo ha un argomento limite con un valore predefinito oppure è obbligatorio. Pertanto, la dimensione presenta non può mai essere applicata.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Questo campo non dispone di una dimensione presunta o di argomenti di suddivisione.`,
  [`This field returns unbound list of values with composite type.`]: `Questo campo restituisce l'elenco di valori non collegati al tipo composto.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Questo campo restituisce l'elenco di valori non collegati al tipo scalare e al peso del tipo != 0.0.`,
  [`This test panel is deprecated and will be removed soon.`]: `Questo pannello di test è obsoleto e verrà rimosso.`,
  [`This type has no fields or no matching field.`]: `Questo campo non dispone di campi o di un campo corrispondente.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Questa politica GatewayScript non deve utilizzare il modulo apim, che è destinato solo alla migrazione delle vecchie API.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Questa politica XSLT non deve utilizzare il modulo apim, che è destinato solo alla migrazione delle vecchie API.`,
  [`This type will be deleted.`]: `Questo tipo verrà eliminato.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `Il seguente diagramma mostra il flusso di assemblaggio per la propria API. Per modificare l'assemblaggio, utilizzare Assembly Editor.`,
  [`The following files are available under the`]: `I seguenti file sono disponibili in base alla`,
  [`The following files contain a private key and cannot be uploaded:`]: `I seguenti file contengono una chiave privata e non possono essere caricati:`,
  [`The following is available under the`]: `Quanto riportato di seguito è disponibile in base alla`,
  [`The following lifecycle actions require approval`]: `Le seguenti azioni del ciclo di vita richiedono approvazione`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `Il seguente elenco visualizza le API bozza create in questa organizzazione provider`,
  [`The following list displays the draft products that have been created in this provider organization`]: `L'elenco riportato di seguito visualizza i prodotti bozza creati in questa organizzazione provider`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `Il seguente elenco mostra tutti gli utenti aggiunti come membri di questo catalogo ed i relativi ruoli assegnati`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `Il seguente elenco mostra tutti gli utenti che sono stati aggiunti come membri di questo spazio e i relativi ruoli assegnati.`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `I seguenti parametri non corrispondono ai valori configurati nel provider OAuth`,
  [`The following roles are available in the Cloud Manager`]: `I seguenti ruoli sono disponibili in Cloud Manager`,
  [`The following types/fields will also be removed:`]: `Verranno rimossi anche i seguenti tipi/campi:`,
  [`The following types/fields will be affected:`]: `Saranno interessati i seguenti tipi/campi:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `Il valore della proprietà del gateway {gatewayType} non è consentito. I valori consentiti sono datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `L'host utilizzato per richiamare l'API`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `Il keystore contiene coppie corrispondenti di certificati pubblici e di chiavi private utilizzati per confermare l'identità e codificare/decodificare la trasmissione dati in HTTPS.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `Il keystore contiene coppie corrispondenti di certificati pubblici e di chiavi private utilizzati per confermare l'identità e codificare/decodificare la trasmissione dati in HTTPS. Il profilo server richiede un keystore.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `Il keystore contiene le chiavi pubbliche ed i certificati privati presentati dal server o dal client per confermare la propria identità.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `L'intervallo di tempo (in secondi) aggiunto alla data ed ora correnti, in cui la chiave LTPA è considerata valida.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `L'intervallo di tempo (in secondi), aggiunto alla data ed ora correnti, in cui il JWT viene considerato valido.`,
  [`The message to be sent with the error`]: `Il messaggio da inviare con l'errore`,
  [`The message to be included within exception.`]: `Il messaggio da includere all'interno dell'eccezione.`,
  [`The HTTP status code to be included within exception.`]: `Il codice di stato HTTP da includere all'interno dell'eccezione.`,
  [`The HTTP status reason phrase to be included within exception.`]: `La frase del motivo dello stato HTTP da includere all'interno dell'eccezione.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Il nome di un limite di burst definito nella configurazione datapower.`,
  [`The name of a count limit defined in the datapower configuration.`]: `Il nome di un limite conteggio definito nella configurazione Datapower`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Il nome di un limite di frequenza definito nella configurazione datapower.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `Il nome di una variabile che verrà utilizzata per archiviare i dati della risposta. Questa variabile potrà essere indicata in altre azioni come ad esempio "Associa".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `Il nome di una variabile che verrà utilizzata per archiviare il risultato. Per impostazione predefinita, verrà utilizzato "message".`,
  [`The name of parameter to find the ID.`]: `Il nome del parametro per trovare l'ID.`,
  [`The name of parameter to find the secret.`]: `Il nome del parametro per trovare il segreto.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `Il nome della chiave LTPA che si desidera utilizzare per generare il token LTPA. Se si desidera che venga utilizzata una versione specifica della chiave, specificare <keyname>:<version>. Ad esempio, my-ltpa-key:1.0.1. Se si desidera che la politica selezioni automaticamente la versione più recente della chiave, specificare <keyname>:latest. Ad esempio, my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `Il nome dell'errore da generare`,
  [`The name of the variable to be added.`]: `Il nome della variabile da aggiungere.`,
  [`The name of the variable to be cleared.`]: `Il nome della variabile da cancellare.`,
  [`The name of the variable to be set.`]: `Il nome della variabile da impostare.`,
  [`The nature of the XML validation to be performed.`]: `La natura della convalida XML da eseguire.`,
  [`The operation to apply.`]: `L'operazione da applicare`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `L'output di questa associazione è nell'ambito di un array e i relativi contenuti verranno creati iterando nel tempo l'input scelto. Se l'input scelto non è un array, verrà prodotto un array di lunghezza pari ad 1.`,
  [`The owner must be an existing user in your user registry`]: `Il proprietario deve essere un utente esistente nel registro utente`,
  [`The owner of consumer organization has the following privileges:`]: `Il proprietario dell'organizzazione consumer dispone dei seguenti privilegi:`,
  [`The owner of provider organization has the following privileges:`]: `Il proprietario dell'organizzazione provider dispone dei seguenti privilegi:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `I doveri del proprietario includono: gestire il ciclo di vita del prodotto API, gestire una o più organizzazioni provider API, gestire le comunità di provider dell'applicazione e creare le API e le definizioni prodotto.`,
  [`The page you are looking for might have been moved or does not exist.`]: `La pagina che si sta cercando potrebbe essere stata spostata o non esiste.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `La pagina che si sta cercando potrebbe essere stata spostata o non esiste. Per assistenza, rivolgersi all'amministratore.`,
  [`The parameters needed to send a valid API call.`]: `I parametri necessari per inviare una chiamata API valida.`,
  [`The password to use for HTTP Basic authentication.`]: `La password da utilizzare per l'autenticazione di base HTTP.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `Il servizio portale fornisce un Developer Portal utilizzato dagli sviluppatori delle applicazioni per rilevare le API ed eseguire la procedura di avvio dei consumer.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `Il servizio portale fornisce un Developer Portal utilizzato dagli sviluppatori delle applicazioni per rilevare e implementare le API.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `Lo stato di elaborazione fornisce una vista sull'integrità di un gateway con un riepilogo delle attività recenti`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `Il destinatario esegue la registrazione come proprietario e crea una nuova organizzazione provider. Una volta eseguita la registrazione, l'utente può accedere ad API Manager come proprietario dell'organizzazione provider.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `Il nome dell'elemento root del documento XML risultante. È richiesto solo se il documento JSON di input non è gerarchico e dispone di più di una proprietà di livello superiore.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Gli ambiti servono agli sviluppatori per comprendere ciò a cui è possibile accedere e non hanno effetto per sulla verifica dell'ambito.`,
  [`The selected catalog will only be used to search consumer organizations`]: `Il catalogo selezionato verrà utilizzato solo per la ricerca nelle organizzazioni consumer`,
  [`The selected catalog does not have any configured gateways`]: `Il catalogo selezionato non dispone di gateway configurati`,
  [`The selected catalog does not have any compatible gateways`]: `Il catalogo selezionato non dispone di gateway compatibili`,
  [`Save Preferences`]: `Salva preferenze`,
  [`Preferences`]: `Preferenze`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `Il catalogo selezionato verrà utilizzato solo per ricercare in organizzazioni consumer e gruppi'`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `Il tipo di gateway selezionato presenterà le seguenti politiche nell'assemblaggio come non valide. Sarà necessario eliminare tali politiche prima di poter eseguire questa API.`,
  [`The selected product does not contain this API`]: `Il prodotto selezionato non contiene questa API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `Il prodotto selezionato sarà sottoscrivibile ad un elenco specifico di gruppi o organizzazioni consumer.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `I servizi selezionati saranno disponibili per un elenco specifico di organizzazioni provider.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `I servizi selezionati saranno disponibili per tutte le organizzazioni provider esistenti e nuove.`,
  [`The selected services will not be available to any provider organizations.`]: `I servizi selezionati non saranno disponibili per nessuna organizzazione provider.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `La stringa da utilizzare come chiave per la cache. Se questo valore non viene specificato, l'intera stringa URL verrà utilizzata come chiave.`,
  [`The server responded with a non-JSON body \n{body}`]: `Il server ha risposto con un corpo non JSON \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `L'intervallo di tempo deve essere superiore al TTL del token di accesso.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Il token non sarà memorizzato in un cookie o nella memoria locale. Se si chiude il pannello di test, il token verrà perduto, ma potrebbe essere ancora valido.`,
  [`The transfer protocol of the API.`]: `Il protocollo di trasferimento dell'API.`,
  [`The type of authentication.`]: `Il tipo di autenticazione.`,
  [`The type of authorization header.`]: `Il tipo di intestazione dell'autorizzazione.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `Il tipo di valore da impostare. Può essere stringa, numero o booleano.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `Il tipo di valore da impostare. Può essere stringa, numero o booleano.`,
  [`The username to use for HTTP Basic authentication.`]: `Il nome utente da utilizzare per l'autenticazione di base HTTP.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `L'utente per questa connessione non è più valido. Creare una nuova connessione.`,
  [`The value that the variable will be set to.`]: `Il valore su cui verrà impostata la variabile.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Il valore di questo campo è un elenco di tipo composito, che non ha né una dimensione presunta né alcun argomento di suddivisione. Se viene rilevato, API Gateway non può impostare un limite per il numero di oggetti che il server GraphQL potrebbe restituire se riceve la query.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Il valore di questo campo è un elenco di tipo scalare, che non ha né una dimensione presunta né alcun argomento di suddivisione. Se viene rilevato, API Gateway non può impostare un limite per il numero di oggetti che il server GraphQL potrebbe restituire se riceve la query.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `La versione deve essere un URL sicuro. La versione dell'oggetto {version} e la versione convertita {slugVersion} non corrispondono.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `La proprietà "name" deve essere un URL sicuro (il valore del nome dell'oggetto "{name}" e il valore del nome "{slugName}" devono corrispondere).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `La proprietà 'draft_api' deve contenere una definizione OpenAPI. OpenAPI versione 3 supporta solo le versioni 3.0.0 - 3.0.3 (versione specificata {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `Il campo del nome viene popolato automaticamente in base al titolo fornito. Utilizzare il valore risultante nei comandi API Connect e nelle chiamate API.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `L'impostazione relativa alla visibilità determina quali organizzazioni provider possono accedere ad una risorsa. Le scelte sono: Pubblico (la risorsa può essere utilizzata da tutte le organizzazioni provider); Personalizzato (la risorsa può essere utilizzata solo dalle organizzazioni provider indicate dall'utente); Privato (la risorsa non è visibile e non può essere utilizzata da alcuna organizzazione provider).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `L'impostazione della visibilità determina quali organizzazioni provider possono utilizzare un servizio. Le possibili scelte sono: Pubblico (il servizio potrà essere utilizzato da tutte le organizzazioni provider); Personalizzato (il servizio può essere utilizzato unicamente dalle organizzazioni provider designate dall'utente); e Privato (il servizio non è visibile e non può essere utilizzato da alcuna organizzazione provider).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `L'impostazione della visibilità determina quali organizzazioni provider possono utilizzare un gateway. I livelli di visibilità sono: Pubblico (il gateway può essere utilizzato da tutte le organizzazioni provider); Personalizzato (il gateway può essere utilizzato solo dalle organizzazioni provider indicate dall'utente; e Privato (il gateway non è visibile e non può essere utilizzato da alcuna organizzazione provider).`,
  [`The {title} role`]: `Il ruolo {title}`,
  [`The {url} resource does not exist`]: `La risorsa {url} non esiste`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Vi sono API non compatibili poiché hanno un tipo di Gateway differente rispetto a questo prodotto.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Quindi, selezionare il certificato per il dominio dell'endpoint di gestione. Inoltre, selezionare il bundle CA corrispondente se il certificato non è stato assegnato da un'autorità di certificazione nota. Il certificato ed il bundle devono essere gestiti dal servizio Certificate Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Quindi specificare i domini che verranno gestiti da questo gateway. Viene utilizzata la tecnica SNI; come risultato, è possibile servire più domini tramite lo stesso indirizzo IP e la stessa porta senza richiedere lo stesso certificato. Il formato con caratteri jolly non è supportato. Il dominio predefinito (catch-all) ‘*’ deve essere incluso come voce finale nella tabella. Immettere altri nomi di dominio in base alle necessità, specificando in ogni caso un certificato gestito dal servizio Certificate Manager.`,
  [`There should be at least {minSelected} {label} selected.`]: `Devono essere selezionati almeno {minSelected} {label}.`,
  [`There are no API changes to publish.`]: `Non sono presenti modifiche all'API da pubblicare.`,
  [`There are no gateway services`]: `Nessun servizio gateway presente`,
  [`There are no assemblies that match the chosen filters.`]: `Non vi sono assemblaggi che corrispondono ai filtri scelti.`,
  [`There are no burst limits yet.`]: `Non esistono ancora limiti di burst.`,
  [`There are no catalogs`]: `Nessun catalogo presente`,
  [`There are no consumer organizations.`]: `Non sono presenti organizzazioni consumer.`,
  [`There are no consumer organizations. You can create one`]: `Non sono presenti organizzazioni consumer. È possibile crearne una`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `Non sono presenti organizzazioni consumer. È possibile crearne uno [qui]({link})`,
  [`There are no email servers`]: `Nessun server e-mail presente`,
  [`There are no gateway services configured for the sandbox catalog`]: `Non esistono servizi gateway configurati per il catalogo sandbox`,
  [`There are no lifecycle approvals enabled`]: `Nessuna approvazione del ciclo di vita abilitata`,
  [`There are no new changes.`]: `Nessuna nuova modifica.`,
  [`There are no products to display`]: `Non ci sono prodotti da visualizzare`,
  [`There are no products to replace`]: `Non vi sono prodotti da sostituire`,
  [`There are no products to set migration target`]: `Non ci sono prodotti per impostare la destinazione della migrazione`,
  [`There are no products to supersede`]: `Non vi sono prodotti da sovrascrivere`,
  [`There are no properties to configure for this policy`]: `Nessuna proprietà da configurare per questa politica`,
  [`There are no rate limits yet.`]: `Non ci sono ancora limiti di frequenza.`,
  [`There are no security definitions`]: `Nessuna definizione di sicurezza presente`,
  [`There are no spaces`]: `Nessuno spazio presente`,
  [`There are no target services`]: `Nessun servizio di destinazione presente`,
  [`There are no tasks to be displayed`]: `Non vi sono attività da visualizzare`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Sono presenti modifiche non salvate. Si desidera continuare?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Sono presenti modifiche non salvate. Se si continua, verranno eliminate. Si è sicuri di voler procedere?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `C'è una API nel tuo yaml che non esiste e non può essere elencata, controlla la scheda sorgente.`,
  [`There is no item in the list.`]: `Non vi è alcun elemento nell'elenco.`,
  [`There is no space`]: `Non vi sono spazi`,
  [`There is no warning in the schema.`]: `Nessuna avvertenza nello schema.`,
  [`There was no data found to populate the data table.`]: `Nessun dato trovato per popolare la tabella dei dati.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Questi endpoint vengono utilizzati per tutte le API nel catalogo`,
  [`Third party OAuth provider`]: `Provider OAuth di terze parti`,
  [`Third party OAuth provider summary`]: `Riepilogo provider OAuth di terze parti`,
  [`Third party`]: `Terze parti`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Questa API non contiene ancora l'assemblaggio. Si desidera crearne uno?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Questa risorsa non esiste più, aggiornare i risultati dal server.`,
  [`Please refresh the results from the server`]: `Aggiornare i risultati dal server`,
  [`Exact match`]: `Corrispondenza esatta`,
  [`Closest match`]: `Corrispondenza più simile`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Questa API è attualmente utilizzata dal prodotto {productName}. Eliminarla dal prodotto, quindi procedere con l'eliminazione dell'API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Questa API sarà disponibile per essere richiamata quando è attivata la seguente opzione.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Per questo catalogo non è abilitata la sottoscrizione automatica. Per verificare questo catalogo, selezionare un prodotto, piano e applicazione di seguito riportato.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Questo endpoint viene utilizzato come endpoint predefinito per le API che non definiscono un endpoint di base`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Questa è una funzione di anteprima tecnica che evolverà e continuerà ad essere migliorata in futuro.`,
  [`This is the currently configured notification server.`]: `Questo è il server di notifica attualmente configurato.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Questa è la directory in cui verranno archiviati i file che includono politiche ed estensioni.`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `Qui è dove le API eseguiranno le politiche e dove è possibile accedere alle estensioni.`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Questa politica oauth esegue tutti i passi del protocollo OAuth necessari per il percorso immesso per impostazione predefinita. Gli input e gli output di ciascuno dei passaggi sono guidati da variabili di contesto documentate. Aggiungere o rimuovere i componenti OAuth supportati come richiesto.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Questa politica oauth esegue tutti i passi del protocollo OAuth/OpenID Connect necessari per la convalida OAuth per impostazione predefinita. Gli input e gli output di ciascuno dei passaggi sono guidati da variabili di contesto documentate. Aggiungere o rimuovere i componenti OAuth supportati come richiesto.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Questa norma oauth esegue tutti i passaggi del protocollo OAuth/OpenID Connect necessari per tutti gli altri percorsi per impostazione predefinita. Gli input e gli output di ciascuno dei passaggi sono guidati da variabili di contesto documentate. Aggiungere o rimuovere i componenti OAuth supportati come richiesto.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Questa norma oauth esegue per impostazione predefinita tutti i passaggi del protocollo OAuth/OpenID Connect necessari per il percorso az code. Gli input e gli output di ciascuno dei passaggi sono guidati da variabili di contesto documentate. Aggiungere o rimuovere i componenti OAuth supportati come richiesto.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Questa norma oauth esegue per impostazione predefinita tutti i passaggi del protocollo OAuth/OpenID Connect necessari per il percorso token. Gli input e gli output di ciascuno dei passaggi sono guidati da variabili di contesto documentate. Aggiungere o rimuovere i componenti OAuth supportati come richiesto.`,
  [`This operation is secured with access code flow OAuth`]: `Questa operazione viene protetta con l'OAuth flusso codice`,
  [`This operation is secured with application flow OAuth`]: `Questa operazione viene protetta con l'OAuth flusso applicazione`,
  [`This operation is secured with basic authentication`]: `Questa operazione è protetta con l'autenticazione di base`,
  [`This operation is secured with implicit flow OAuth`]: `Questa operazione viene protetta con l'OAuth flusso implicito`,
  [`This operation is secured with password flow OAuth`]: `Questa operazione viene protetta con l'OAuth flusso password`,
  [`This policy is not available on the selected gateway`]: `Questa politica non è disponibile nel gateway selezionato`,
  [`This policy is used by one other operation`]: `Questa politica è utilizzata da un'altra operazione`,
  [`This policy is used by {{count}} other operations`]: `Questa politica è utilizzata da altre {{count}} operazioni`,
  [`This role cannot be edited`]: `Questo ruolo non può essere modificato`,
  [`This role cannot be edited.`]: `Questo ruolo non può essere modificato.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Questa impostazione controlla il comportamento nel caso in cui un'associazione non riesce, perché il suo input non è presente, e non è stata configurata alcuna associazione predefinita. Il comportamento predefinito è di non apportare modifiche all'output, ma se si seleziona questa casella di controllo viene creato un oggetto vuoto per il parent del mapping di destinazione, emulando il comportamento della versione 4.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Questa politica di sicurezza utente esegue il controllo EI (di base) e AU (auth url) per l'assemblaggio di oauth. Cambia il metodo di controllo di sicurezza come richiesto`,
  [`This will enable/disable application lifecycle`]: `Questa operazione abiliterà/disabiliterà il ciclo di vita dell'applicazione`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Questo abiliterà/disabiliterà l'auto-approvazione delle attività. L'auto-approvazione delle attività consente l'approvazione delle attività da parte dell'originatore e dei collaboratori. Questa opzione può risultare utile quando altri approvatori non sono disponibili, ma consente di fatto che siano ignorate le verifiche di un altro utente.`,
  [`This will unset the catalog as the default catalog.`]: `L'impostazione del catalogo come catalogo predefinito verrà annullata.`,
  [`Throw`]: `Genera`,
  [`Time`]: `Data e ora`,
  [`Time Interval`]: `Intervallo di tempo`,
  [`Time Limit`]: `Limite di tempo`,
  [`Time to Live`]: `TTL (Time to Live)`,
  [`Time to live`]: `TTL (Time to live)`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Il tempo di attesa prima di una risposta dall'endpoint. Il valore predefinito è 60 secondi.`,
  [`Timeout`]: `Tempo scaduto`,
  [`To`]: `A`,
  [`to`]: `a`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Per aggiungere un utente come membro dell'organizzazione Admin, selezionare il registro utente, immettere il nome utente e selezionare i ruoli che si desidera assegnare. Se il tipo di registro utente è un registro utente locale, è necessario anche avere la possibilità di creare un nuovo utente che viene aggiunto come membro. L'appartenenza viene abilitata immediatamente e l'utente specificato può collegarsi all'interfaccia utente Cloud Manager.`,
  [`To add a user as a member of a provider organization`]: `Aggiungere un utente come membro di un'organizzazione provider`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Per aggiungere un utente come membro del catalogo, selezionare il nome utente e assegnare i ruoli richiesti. È possibile aggiungere nell'organizzazione del provider qualsiasi utente che sia già membro di un altro Catalogo, o di uno Spazio e che attualmente non sia membro di questo Catalogo, né proprietario del Catalogo.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Per aggiungere un utente come membro dello spazio, selezionare il nome utente ed assegnare i ruoli richiesti. È possibile aggiungere nell'organizzazione del provider qualsiasi utente che sia già membro di un altro Catalogo, o di uno Spazio e che attualmente non sia membro di questo Spazio, né proprietario dello Spazio.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Per aggiungere o rimuovere membri, utilizzare il servizio [identity and access](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Per aggiungere membri, utilizzare il servizio [identity and access](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Per aggiungere gli utenti come membri dell'organizzazione, selezionare il relativo registro utente, immettere il relativo nome utente ed assegnare un insieme di ruoli.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Per aggiungere un utente come membro, selezionare il nome utente e assegnarli una serie di ruoli.`,
  [`To assign default gateways, click **Edit**.`]: `Per assegnare i gateway predefiniti, fare clic su **Modifica**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Per continuare ad utilizzare questo software, è necessario accettare i termini dell'accordo di licenza del software.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Per continuare ad utilizzare API Connect, eseguire l'upgrade ad un piano Enterprise.`,
  [`To get started, you must first enable spaces.`]: `Per iniziare, è necessario prima abilitare gli spazi.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Per installare ed eseguire il toolkit, effettuare le operazioni riportate di seguito. [Ulteriori informazioni]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Per invitare un nuovo utente come membro dell'organizzazione Admin, immettere l'indirizzo e-mail dell'utente o il nome o selezionare il nome da un registro utente.  A tutti i membri viene assegnato automaticamente il ruolo Membro, che fornisce un insieme di base di autorizzazioni e non può essere eliminato. Se non sono richieste altre autorizzazioni, lasciare gli altri ruoli non selezionati.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Per invitare un proprietario che non si trovi già nel registro utente, immetterne l'indirizzo e-mail`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Per registrare un nuovo gateway, visitare la pagina **Gateway**.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Per sostituire i valori con una delle seguenti proprietà dello spazio, immettere $() con il nome della proprietà all'interno delle parentesi.`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Per inviare un invito via e-mail ad un nuovo utente per registrarlo come membro dell'organizzazione Admin, immettere un indirizzo e-mail valido e selezionare i ruoli che si desidera assegnare all'utente. Viene inviata all'indirizzo e-mail una e-mail che contiene il link di attivazione che invita la persona a registrarsi. L'utente viene posto nello stato di Sospeso e tale stato cambia in Abilitato dopo aver completato il modulo di registrazione per attivare l'account. L'utente può collegarsi all'interfaccia utente Cloud Manager utilizzando il nome utente di API Connect specificato durante l'attivazione dell'account.`,
  [`Toggle`]: `Attiva/disattiva`,
  [`Toggle application lifecycle?`]: `Attivare/disattivare ciclo di vita dell'applicazione?`,
  [`Toggle default catalog?`]: `Attivare/disattivare il catalogo predefinito?`,
  [`Toggle info panel between right and bottom`]: `Sposta il pannello delle informazioni a destra o in basso`,
  [`Toggle profile`]: `Attiva/Disattiva profilo`,
  [`Toggle spaces?`]: `Attivare/disattivare gli spazi?`,
  [`Toggle task self-approval?`]: `Attivare-disattivare l'approvazione automatica dell'attività?`,
  [`Token`]: `Token`,
  [`Token endpoint`]: `Token endpoint`,
  [`Token Expiry`]: `Scadenza token`,
  [`Token Management`]: `Gestione token`,
  [`Token Output`]: `Output del token`,
  [`Token URL`]: `URL token`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `L'URL del token deve corrispondere a {endpoint} come definito dal provider OAuth "{providerTitle}"`,
  [`Token Version`]: `Versione token`,
  [`Token management`]: `Gestione token`,
  [`Token management type`]: `Tipo di gestione del token`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `La gestione token consente di prevenire gli attacchi replay, di gestire i token, anche come e se le applicazioni client o i proprietari delle risorse revocano i token OAuth. API Connect supporta la gestione dei token con un gateway nativo o con un endpoint di terze parti. Quando si utilizza un gateway nativo, viene utilizzata l'applicazione della quota per gestire i token. Quando si utilizza un endpoint di terze parti, si fornisce un URL ad un servizio esterno per gestire i token.`,
  [`Token path`]: `Percorso token`,
  [`Token revocation`]: `Revoca token`,
  [`Token secret`]: `Segreto token`,
  [`Token validation`]: `Convalida del token`,
  [`Tokens`]: `Token`,
  [`Token relay`]: `Inoltro token`,
  [`Toolkit credentials`]: `Credenziali Toolkit`,
  [`Token Relay`]: `Inoltro token`,
  [`Topology`]: `Topologia`,
  [`Topology Administrator`]: `Amministratore topologia`,
  [`Total time`]: `Tempo totale`,
  [`Transfer Ownership to a New User`]: `Trasferisci proprietà ad un nuovo utente`,
  [`Transfer Ownership to an Existing User`]: `Trasferisci proprietà ad un utente esistente`,
  [`Transfer ownership`]: `Trasferisci proprietà`,
  [`Transfer ownership to`]: `Trasferisci proprietà a`,
  [`Transfer ownership to a new user`]: `Trasferisci proprietà ad un nuovo utente`,
  [`Transfer ownership to an existing user`]: `Trasferisci proprietà ad un utente esistente`,
  [`Transforms`]: `Trasformazioni`,
  [`Truststore Certificates`]: `Certificati truststore`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `I certificati del truststore sono certificati pubblici emessi da un'autorità di certificazione.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `I truststore contengono i certificati attendibili contenenti le chiavi pubbliche verificate. I certificati nel truststore generalmente sono ottenuti da un'autorità di certificazione di terze parti.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `I truststore contengono certificati attendibili con chiavi pubbliche verificate. I certificati nel truststore generalmente sono ottenuti da un'autorità di certificazione di terze parti.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `I truststore archiviano i certificati attendibili, incluse le chiavi pubbliche verificate. I certificati nel truststore generalmente sono ottenuti da un'autorità di certificazione di terze parti.`,
  [`Try again or upload a schema`]: `Riprovare o caricare uno schema`,
  [`Try refreshing the page or contacting support.`]: `Provare ad aggiornare la pagina o a contattare il supporto.`,
  [`Turkish`]: `Turco`,
  [`Tutorials`]: `Supporti didattici`,
  [`Types`]: `Tipi`,
  [`Type of user`]: `Tipo di utente`,
  [`Type or select a catalog`]: `Immettere o selezionare un catalogo`,
  [`Type to add organizations`]: `Immettere per aggiungere le organizzazioni`,
  [`TypeError: Failed to fetch`]: `TypeError: Impossibile recuperare`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `URL dell'endpoint di gestione`,
  [`URLs for all operations in the API begin with this value.`]: `Gli URL per tutte le operazioni nell'API iniziano con questo valore.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `Limitazioni previste per gli utenti del Governo degli Stati Uniti - L'uso, la duplicazione o la divulgazione sono limitati dal GSA ADP Schedule Contract con IBM Corp.`,
  [`User Defined`]: `Definito dall'utente`,
  [`Userinfo POST`]: `POST Userinfo`,
  [`USER MANAGED`]: `GESTITO DALL'UTENTE`,
  [`USERNAME`]: `NOME UTENTE`,
  [`User can delete the keystore history.`]: `L'utente può eliminare la cronologia del keystore.`,
  [`Unable to parse API definition: {msg}`]: `Impossibile analizzare la definizione dell'API: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Impossibile continuare a causa di errori rilevati durante la rimozione dei tipi e/o dei campi.`,
  [`Unarchive`]: `Annulla archiviazione`,
  [`Unassociate`]: `Annulla associazione`,
  [`Unassociate analytics service`]: `Annulla associazione servizio di analytics`,
  [`Unauthorized`]: `Non autorizzato`,
  [`Unauthorized API Request`]: `Richiesta API non autorizzata`,
  [`Unbound lists`]: `Elenchi non collegati`,
  [`Unit`]: `Unità`,
  [`Union`]: `Unione`,
  [`Union type`]: `Tipo di unione`,
  [`Unique email address`]: `Indirizzo e-mail unico`,
  [`Unlimited`]: `Illimitato`,
  [`Unnecessary assumed size`]: `Limite presunto non necessario`,
  [`Unnecessary slicing arguments`]: `Argomenti di suddivisione non necessari`,
  [`Unrecognized Schema Element(s) Encountered`]: `Sono stati rilevati elementi dello schema non riconosciuti`,
  [`Unsupported media type ({type})`]: `Tipo di supporto non supportato ({type})`,
  [`Untitled`]: `Senza titolo`,
  [`Update`]: `Aggiorna`,
  [`Update gateway services`]: `Aggiorna servizi gateway`,
  [`Update assembly`]: `Aggiorna assemblaggio`,
  [`Update group failed.`]: `Aggiornamento del gruppo non riuscito.`,
  [`Update schema defaults giving preference to`]: `Aggiorna valori predefiniti dello schema assegnando la preferenza a`,
  [`Update the sender details that are displayed on invitation emails`]: `Aggiornare i dettagli del mittente visualizzati nelle e-mail di invito`,
  [`Update the summary details for this role`]: `Aggiornare i dettagli di riepilogo per questo ruolo`,
  [`Updating Draft API`]: `Aggiornamento API bozza`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `L'aggiornamento dei valori predefiniti dello schema sovrascriverà le modifiche. Si desidera continuare?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `L'aggiornamento dell'API di tipo {existingType} on l'API di tipo {type} non è consentito.`,
  [`Upload`]: `Carica`,
  [`Upload a schema definition language file to replace your schema.`]: `Caricare un file SDL (Schema Definition Language) per sostituire lo schema.`,
  [`Upload GraphQL schema`]: `Carica schema GraphQL`,
  [`Upload local`]: `Carica locale`,
  [`Upload policies`]: `Carica politiche`,
  [`Upload policy`]: `Carica politica`,
  [`Upload schema`]: `Carica schema`,
  [`Upload schema definition language (SDL) file`]: `Carica file SDL (schema definition language)`,
  [`Upload truststore certificates`]: `Carica certificati truststore`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Caricare il certificato X509 per la propria applicazione in formato PEM. È possibile caricare solo un file PEM ma si possono includere più certificati in un singolo file.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Caricare il certificato X509 per la propria applicazione, in formato PEM`,
  [`Upload your public certificate`]: `Carica il certificato pubblico`,
  [`Uploaded and validated YAML file`]: `File YAML caricato e convalidato`,
  [`Upload extension`]: `Carica estensione`,
  [`Use all compatible gateway services`]: `Utilizza tutti i servizi gateway compatibili`,
  [`Use Content Type`]: `Utilizza tipo di contenuto`,
  [`Use DataPower API Gateway`]: `Utilizza DataPower API Gateway`,
  [`Use DataPower Gateway`]: `Utilizza DataPower Gateway`,
  [`Use Dynamic DNS`]: `Utilizza DNS dinamico`,
  [`Use JSONata to specify the property to redact or remove`]: `Utilizzare JSONata per specificare la proprietà da revisionare o rimuovere`,
  [`Use XPath to specify the property to redact or remove.`]: `Utilizzare XPath per specificare la proprietà da revisionare o rimuovere.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Utilizzare un catalogo selezionato come catalogo predefinito che si desidera pubblicare`,
  [`Use an existing JSON or YAML definition of the product`]: `Utilizzare una definizione JSON o YAML esistente del prodotto`,
  [`Use an existing definition of a REST proxy`]: `Utilizzare una definizione esistente di un proxy REST`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Utilizza una definizione esistente di un proxy REST o API SOAP`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Utilizzare una definizione esistente di un proxy REST, un proxy GraphQL o API SOAP`,
  [`Use an existing definition of a product`]: `Utilizzare una definizione esistente di un prodotto`,
  [`Use context current payload`]: `Utilizza payload corrente del contesto`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Utilizza URL endpoint del catalogo personalizzato (compatibilità con v5)`,
  [`Use dynamic DNS`]: `Utilizza DNS dinamico`,
  [`Use endpoint from API`]: `Utilizza endpoint da API`,
  [`Use gateway URLs`]: `Utilizza URL gateway`,
  [`Use gateway service configured OAuth shared secret key`]: `Utilizza la chiave segreta condivisa OAuth configurata del servizio gateway.`,
  [`Use group authentication`]: `Utilizza autenticazione gruppo`,
  [`Use only first array element`]: `Usa solo primo elemento dell'array`,
  [`Use static DNS`]: `Utilizza DNS statico`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Utilizza il portale come endpoint per il traffico del provider OIDC esterno`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Utilizzare API Explorer per eseguire il test ed esplorare le API. Explorer mostra le operazioni, le definizioni e la documentazione per tutte le API contenute nella directory del progetto. Explorer elenca i parametri, i dati dell'istanza del modello e i codici di risposta insieme al codice template per eseguire l'API`,
  [`Use the actions menu to manage and publish your APIs.`]: `Utilizzare il menu delle azioni per gestire e pubblicare le proprie API.`,
  [`Use the actions menu to manage and publish your products`]: `Utilizzare il menu delle azioni per gestire e pubblicare i propri prodotti`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Utilizzare la politica Log delle attività per configurare le preferenze di registrazione per l'attività API memorizzata in Analytics. Le preferenze specificate sostituiscono le impostazioni predefinite per la raccolta e l'archiviazione dei dettagli dell'attività API.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Utilizzare la politica di sicurezza client per estrarre ed autenticare le credenziali client.`,
  [`Use the default built-in Sandbox Catalog`]: `Utilizza il catalogo Sandbox integrato predefinito`,
  [`Use the editor for manual input or upload a file below.`]: `Utilizzare l'editor per l'input manuale o caricare un file di seguito.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Utilizzare l'editor per l'input manuale o caricare un file di seguito. Lo schema corrente può essere modificato solo in XML se è definito un file XML/XSD.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Utilizzare l'estensione forzata per specificare se il gateway API Connect viene utilizzato per applicare l'API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Utilizzare il seguente URL di endpoint di gestione quando si lavora con la CLI.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Utilizzare il seguente URL di endpoint di gestione durante la creazione di una connessione cloud in API Designer.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Utilizzare la politica gatewayscript per eseguire un programma GatewayScript DataPower specificato.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Utilizzare la politica graphql-introspect per l'introspezione di uno schema  GraphQL.`,
  [`Use the host value from Open API Definition`]: `Utilizzare il valore host dalla definizione OpenAPI`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Utilizzare la politica Se per applicare una sezione dell'assemblaggio quando viene soddisfatta una condizione.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Utilizzare la politica javascript per eseguire un programma JavaScript specificato.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Utilizzare la politica json-to-xml per convertire il contenuto del payload da JSON a XML.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Utilizzare la politica Log per personalizzare o sovrascrivere la configurazione di registrazione dell'attività predefinita per un'API.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Utilizzare la politica di associazione per applicare le trasformazioni al flusso di assemblaggio e specificare le relazioni tra le variabili.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Utilizzare la politica di associazione per specificare le relazioni tra le variabili nel flusso di API e per applicare le trasformazioni a tali variabili.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Utilizzare la politica oauth per generare token OAuth.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Utilizzare la politica Switch operazioni per applicare una sezione dell'assemblaggio ad un'operazione specifica.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Utilizza la politica di analisi per analizzare una richiesta o un messaggio come XML o JSON o analizzare i dati binari in un BLOB (binary large object).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Utilizzare la politica Imposta variabile per impostare una variabile runtime su un valore stringa oppure per aggiungere o cancellare una variabile runtime.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Utilizzare la politica di generazione per generare un errore quando si verifica durante l'esecuzione di un flusso di assemblaggio.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Utilizzare la politica di sicurezza per estrarre, autenticare ed autorizzare l'identità dell'utente.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Utilizzare la politica di convalida con DataPower Gateway per convalidare il payload in un flusso di assemblaggio rispetto ad uno schema JSON o XML.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Utilizzare la politica xml-to-json per convertire il contenuto del payload da XML a JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Utilizzare la politica xslt per applicare una conversione XSLT al payload.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Questa opzione viene utilizzata per abilitare/disabilitare la compressione Content-Encoding durante il caricamento.`,
  [`User`]: `Utente`,
  [`User group prefix`]: `Prefisso del gruppo utente`,
  [`User group suffix`]: `Suffisso del gruppo utente`,
  [`User mapping`]: `Associazione utenti`,
  [`User Registries`]: `Registri utente`,
  [`User registries`]: `Registri utente`,
  [`User Registry Name`]: `Nome registro utente`,
  [`User registry name`]: `Nome registro utente`,
  [`User Registry cannot be deleted`]: `Impossibile eliminare il registro utente`,
  [`User Registry "{name}" has been deleted`]: `Il registro utente "{name}" è stato eliminato`,
  [`User security`]: `Sicurezza utente`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `I registri utente sono gestiti in ciascuno spazio quando gli spazi sono abilitati. Passare alle impostazioni dello spazio per gestire i registri utente.`,
  [`User registries defined for consumer onboarding`]: `I registri utente definiti per la procedura di avvio del consumer`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Registri utente definiti per la procedura di avvio del consumer\n\nOgni account in Developer Portal, inclusi quelli tra registri utenti differenti per lo stesso sito, devono avere un indirizzo email univoco, incluso l'account dell'amministratore del sito.`,
  [`User registry`]: `Registro utente`,
  [`User registry configuration has been updated.`]: `La configurazione del registro utente è stata aggiornata.`,
  [`User registry has been created`]: `Registro utente creato`,
  [`User registry {title} deleted.`]: `Registro utente {title} eliminato.`,
  [`User registry {title} has been created.`]: `Il registro utente {title} è stato creato.`,
  [`User registry is not visible or not available.`]: `Registro utente non visibile o non disponibile.`,
  [`User registry {title} has been updated.`]: `Il registro utente {title} è stato aggiornato.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `Le impostazioni di sicurezza utente sono applicabili solo se i tipi di concessione selezionati includono uno dei seguenti tipi: Implicito, Codice di accesso e proprietario risorsa -password`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `Le impostazioni di sicurezza utente sono applicabili solo se i tipi di concessione selezionati includono uno dei seguenti tipi: Implicito, Codice di accesso e proprietario risorsa -password. Se si apportano modifiche a questa pagina, è necessario selezionare almeno una delle seguenti tipologie di autorizzazione.`,
  [`UserInfo endpoint`]: `Endpoint UserInfo`,
  [`UserInfo URL`]: `URL UserInfo`,
  [`Username`]: `Nome utente`,
  [`Username / Email`]: `Nome utente / E-mail`,
  [`Username and password fields are required in order to test configuration`]: `I campi del nome utente e della password sono richiesti per verificare la configurazione`,
  [`Username context variable`]: `Variabile di contesto nome utente`,
  [`Username of the new user`]: `Nome utente del nuovo utente`,
  [`Username should not contain special characters!`]: `Il nome utente non deve contenere caratteri speciali.`,
  [`Username should not include spaces!`]: `Il nome utente non deve contenere spazi.`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Gli utenti che appartengono ad un'organizzazione consumer dispongono di accesso al Developer Portal ed ai relativi prodotti API pubblicati, come determinato dalla relativa visibilità e sottoscrivibilità.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Utilizzando [IBM Cloud IAM]({link}), autorizzare il servizio API Connect Reserved Instance ad accedere al servizio Certificate Manager.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Utilizzo del metodo HTTP POST quando si contatta l'endpoint Userinfo`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Utilizza impostazioni di scadenza del token IBM APIC dal cloud`,
  [`Update API`]: `Aggiorna APIP`,
  [`VALUE`]: `VALORE`,
  [`Version`]: `Versione`,
  [`Visible to`]: `Visibile a`,
  [`Validate`]: `Convalida`,
  [`Validate message body`]: `Convalida corpo del messaggio`,
  [`Validate message headers`]: `Convalida intestazioni dei messaggi`,
  [`Validate message fault details`]: `Dettagli di errore dei messaggi di convalida`,
  [`Validate SOAP 1.1 encoding rule`]: `Convalida regola di codifica SOAP 1.1`,
  [`Validate JSON Web Token (JWT)`]: `Convalida JWT (JSON Web Token)`,
  [`Validate JWT`]: `Convalida JWT`,
  [`Validate Username Token`]: `Convalida token nome utente`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Convalida un payload Web Services Security UsernameToken utilizzando l'URL di autenticazione o il registro utente LDAP.`,
  [`Validate against`]: `Validare rispetto a`,
  [`Validate request`]: `Convalida richiesta`,
  [`Validate the GraphQL query and analyze the expected cost`]: `Convalida la query GraphQL ed analizza il costo previsto`,
  [`Validate the returned JSON and analyze the real cost`]: `Convalida il JSON restituito ed analizza il costo reale`,
  [`Validating API ...`]: `Convalida API ...`,
  [`Validating Draft API`]: `Convalida API bozza`,
  [`Validity Period`]: `Periodo di validità-`,
  [`Value`]: `Valore`,
  [`Value for located in must be selected.`]: `Il valore per l'ubicazione deve essere selezionato.`,
  [`Value for the variable to be added.`]: `Il valore per la variabile da aggiungere.`,
  [`Value for type must be selected.`]: `Il valore per il tipo deve essere selezionato.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `Il valore dell'argomento "weight" nella direttiva "@cost" è < 0.`,
  [`Values`]: `Valori`,
  [`Vanity API endpoint`]: `Endpoint API di reindirizzamento a microsito`,
  [`Variable name`]: `Nome variabile`,
  [`Variable value`]: `Valore variabile`,
  [`Vendor Extensions`]: `Estensioni fornitore`,
  [`Verb`]: `Verbo`,
  [`Verify`]: `Verifica`,
  [`Verify Access Token`]: `Verifica token di accesso`,
  [`Verify Crypto JWK variable name`]: `Nome variabile Verifica JWK codificato`,
  [`Verify Crypto Object`]: `Oggetto crittografico di verifica`,
  [`Verify Token`]: `Verifica token`,
  [`Verify authorization code`]: `Verifica codice di autorizzazione`,
  [`Verify refresh token`]: `Verifica token di aggiornamento`,
  [`Version of the LTPA token.`]: `Versione del token LTPA.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Controllare la proprietà 'apis' in 'plans' nel file del prodotto importato. Ogni piano deve avere la proprietà 'apis' che punta ad almeno un'api dalla sezione 'apis'.`,
  [`View`]: `Visualizza`,
  [`View extensions`]: `Visualizza estensioni`,
  [`View Forum`]: `Visualizza forum`,
  [`View owners`]: `Visualizza proprietari`,
  [`View policies`]: `Visualizza politiche`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Visualizzare e gestire le crittografie disponibili per la versione protocollo selezionato.`,
  [`View analytics`]: `Visualizza analisi`,
  [`View and edit permissions for the roles in this space`]: `Visualizzare e modificare le autorizzazioni per i ruoli in questo spazio`,
  [`View and edit the permissions for the roles in the current organization`]: `Visualizzare e modificare le autorizzazioni per i ruoli nell'organizzazione corrente`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Visualizzare e modificare i ruoli contenuti nelle impostazioni predefinite del ruolo per le organizzazioni consumer.`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Visualizzare e modificare i ruoli contenuti nelle impostazioni predefinite del ruolo per le organizzazioni provider.`,
  [`View approval history`]: `Visualizza cronologia approvazioni`,
  [`View Approval History`]: `Visualizza cronologia approvazioni`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Visualizzare la documentazione e i supporti didattici con istruzioni dettagliate.`,
  [`View error ID`]: `Visualizza ID errore`,
  [`View status`]: `Visualizza stato`,
  [`View more`]: `Visualizza altro`,
  [`View source`]: `Visualizza origine`,
  [`View subscription`]: `Visualizza abbonamento`,
  [`View template in`]: `Visualizza template in`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Visualizzare gli endpoint per l'accesso alle interfacce utente di API Connect.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Visualizzare gli endpoint per le API REST admin e provider.`,
  [`View the endpoints for the consumer REST APIs.`]: `Visualizzare gli endpoint per le API REST consumer.`,
  [`Viewer`]: `Visualizzatore`,
  [`Views the API consumer organization`]: `Visualizza le organizzazioni consumer API`,
  [`Views the API provider organization`]: `Visualizza l'organizzazione provider API`,
  [`Views the admin organization`]: `Visualizza l'organizzazione Admin`,
  [`Views the app developer organization`]: `Visualizza l'organizzazione dello sviluppatore di app.`,
  [`Views the catalog`]: `Visualizza il catalogo`,
  [`Views the space`]: `Visualizza lo spazio`,
  [`View|permission`]: `Visualizza`,
  [`Visibility`]: `Visibilità`,
  [`Visibility options`]: `Opzioni di visibilità`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `Documetazione VMware`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `URL WSDL`,
  [`WSDL has been successfully validated`]: `WSDL è stato convalidato correttamente`,
  [`WSDL to REST`]: `Da WSDL a REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `Convalida del profilo di base WS-I`,
  [`Warn`]: `Avvertenza`,
  [`Warning`]: `Avvertenza`,
  [`Warning:`]: `Avvertenza:`,
  [`Warnings`]: `Avvertenze`,
  [`We just emailed recipients a test message:`]: `È stato appena inviato via e-mail un messaggio di prova ai destinatari:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `Verrà eseguito lo spostamento nella scheda "Test", in cui sarà possibile eseguire il test ed il debug dell'API.`,
  [`Weather Product`]: `Prodotto Meteo`,
  [`Web Endpoint`]: `Endpoint Web`,
  [`Web service operations`]: `Operazioni servizio web`,
  [`weight`]: `Peso`,
  [`Weight`]: `Peso`,
  [`Welcome to API Connect Administration`]: `Benvenuti in API Connect Administration`,
  [`Welcome to API Connect Reserved`]: `Benvenuti in API Connect Reserved`,
  [`Welcome to API Designer`]: `Benvenuti in API Designer`,
  [`Welcome to API Manager`]: `Benvenuti in API Manager`,
  [`Welcome to Cloud Manager`]: `Benvenuti in Cloud Manager`,
  [`Welcome to the API Designer`]: `Benvenuti in API Designer`,
  [`Welcome to the API Manager`]: `Benvenuti in API Manager`,
  [`Welcome to the Cloud Manager`]: `Benvenuti in Cloud Manager`,
  [`What are you looking for today?`]: `Cosa stai cercando oggi?`,
  [`What's new`]: `Novità`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `Operazioni successive. Abilitare l'integrazione di fatturazione in un catalogo per consentire agli utenti di aggiungere piani ai propri prodotti.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `Quando è impostata su true, la politica di richiamo inserirà le intestazioni "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" all'invio della richiesta all'URL di destinazione`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Una volta pubblicato, un prodotto API diventa visibile nel Developer Portal associato al catalogo. Gli endpoint per le API nel prodotto vengono resi disponibili nei gateway associati allo spazio o al catalogo di destinazione. I prodotti sottoposti a staging sono presenti nel catalogo ma non sono attivi o visibili per i consumer.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Una volta pubblicato, un prodotto API diventa visibile nel Developer Portal associato al catalogo. Gli endpoint per le API nel prodotto vengono resi disponibili nei gateway associati allo spazio o al catalogo di destinazione. I prodotti sottoposti a staging sono presenti nel catalogo ma non sono attivi o visibili per i consumer. Notare che eseguendo nuovamente lo staging / pubblicando nuovamente un prodotto in un catalogo di non produzione, le sottoscrizioni esistenti verranno rimosse.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Quando sono abilitati gli spazi per un catalogo, è necessario visualizzare il provider OAuth configurato da uno spazio.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Quando si abilitano gli spazi, i prodotti presenti nel catalogo vengono spostati in uno spazio predefinito.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Quando l'utente fa clic sul link di attivazione, gli viene richiesto di completare il modulo di registrazione. Una volta effettuata la registrazione l'utente potrà accedere.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Quando si aggiunge un ruolo per le organizzazioni provider o consumer, verrà incluso nell'impostazione predefinita del ruolo. Le impostazioni predefinite del ruolo rappresentano template di ruolo pre-configurati utilizzati per determinare i ruoli predefiniti disponibili quando viene creata una nuova organizzazione.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Quando un prodotto viene reso obsoleto, gli sviluppatori delle applicazioni che hanno già effettuato la sottoscrizione al prodotto possono continuare ad utilizzarlo, ma nessun nuovo sviluppatore può effettuare la sottoscrizione al prodotto.\n\n [Ulteriori informazioni]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Quando si modifica un ruolo per le organizzazioni provider o consumer, verrà incluso nell'impostazione predefinita del ruolo. Le impostazioni predefinite del ruolo rappresentano template di ruolo pre-configurati utilizzati per determinare i ruoli predefiniti disponibili quando viene creata una nuova organizzazione.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Quando si abilita OpenID Connect, viene fornito un template per generare gli ID token insieme ai token di accesso e alle politiche di assemblaggio richieste vengono create automaticamente. È possibile personalizzare le politiche per soddisfare le proprie esigenze. La chiave di esempio è solo per scopi di test e viene utilizzata per firmare il token JWT.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Quando si abilita l'introspezione del token, è possibile esaminare i token di accesso attraverso il percorso di introspezione.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Quando si abilita l'introspezione del token, i titolari di un token di accesso possono esaminare il contenuto dei token utilizzando un percorso di introspezione. Il token di accesso da sottoporre ad introspezione deve essere ottenuto tramite il provider OAuth nativo.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Quando si invita un utente come membro di un'organizzazione provider, si assegnano uno o più ruoli per controllare il livello di accesso alle informazioni e le attività che può eseguire.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Quando vengono gestite, le versioni del prodotto passano attraverso una serie di stati del ciclo di vita, dallo staging iniziale di una versione del prodotto in un catalogo, attraverso la pubblicazione, per rendere la versione del prodotto disponibile per gli sviluppatori delle applicazioni, fino all'eventuale ritiro ed archiviazione.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Quando un prodotto viene sostituito da un altro prodotto, vengono effettuate le seguenti azioni: 1) Il prodotto in sostituzione viene pubblicato, 2) Nel prodotto in sostituzione vengono utilizzate le stesse impostazioni di visibilità, del sottoscrittore e di applicazione del gateway del prodotto originale.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Quando si sostituisce un prodotto con un altro prodotto, vengono eseguite le seguenti azioni: 1) il prodotto di sostituzione assume lo stato del prodotto originale, 2) Se le impostazioni di visibilità e sottoscrivibilità nel prodotto di sostituzione sono tali che l'accesso è uguale, o meno restrittivo, rispetto al prodotto originale, vengono utilizzate le impostazioni nel prodotto di sostituzione. Se le impostazioni nel prodotto di sostituzione sono più restrittive e impediscono ad alcune organizzazioni consumer con accesso al prodotto originale di accedere alla sostituzione, l'operazione di sostituzione non può essere completata. 3) i sottoscrittori del prodotto originale vengono migrati al prodotto di sostituzione e, 4) il prodotto originale viene ritirato.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Quando un prodotto viene ritirato, tutte le API associate passano allo stato non in linea e tutte le sottoscrizioni diventano inattive.\n\n [Ulteriori informazioni]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Quando un prodotto viene sovrascritto da un altro prodotto, vengono effettuate le seguenti azioni: 1) Il prodotto in sovrascrittura viene pubblicato, 2) Nel prodotto in sovrascrittura vengono utilizzate le stesse impostazioni di visibilità, del sottoscrittore e di applicazione del gateway del prodotto originale, 3) Il prodotto originale passa allo stato Obsoleto. Quando un prodotto è obsoleto, gli sviluppatori delle applicazioni che hanno già effettuato la sottoscrizione al prodotto possono continuare ad utilizzarlo, ma nessun nuovo sviluppatore può effettuare la sottoscrizione al prodotto. Se necessario, un prodotto obsoleto può essere pubblicato nuovamente.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Quando un prodotto viene sovrascritto da un altro prodotto, vengono effettuate le seguenti azioni: 1) il prodotto di sovrascrittura viene pubblicato, 2) nel prodotto di sovrascrittura vengono utilizzate le impostazioni di visibilità e sottoscrivibilità del prodotto originale e 3) il prodotto originale passa allo stato obsoleto.  Quando un prodotto è obsoleto, gli sviluppatori delle applicazioni che hanno già effettuato la sottoscrizione al prodotto possono continuare ad utilizzarlo, ma nessun nuovo sviluppatore può effettuare la sottoscrizione al prodotto.  Se necessario, un prodotto obsoleto può essere nuovamente pubblicato.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Quando un'applicazione viene sospesa, gli ID client associati non possono effettuare chiamate API. È possibile ripristinare l'applicazione in qualsiasi momento.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Se abilitato, tutti i timeout di invito delle organizzazioni consumer saranno impostati sul timeout di invito del catalogo e qualsiasi aggiornamento al timeout di invito del catalogo aggiornerà il timeout di invito delle organizzazioni consumer.`,
  [`Where did the roles go?`]: `Dove si trovano i ruoli?`,
  [`Where to find the named rate limit.`]: `Dove trovare il limite di frequenza denominato`,
  [`Where to put the decrypted result`]: `Il punto in cui inserire il risultato decodificato`,
  [`Where to put the encrypted result`]: `Il punto in cui inserire il risultato codificato`,
  [`Whether to reuse the connection to the target server.`]: `Indica se riutilizzare la connessione al server di destinazione.`,
  [`Windows live`]: `Windows live`,
  [`With`]: `Con`,
  [`Wildcards ignore xsi:type rule`]: `I caratteri jolly ignorano la regola xsi:type`,
  [`Work offline`]: `Lavoro non in linea`,
  [`Work with existing definitions, models and/or data sources`]: `Lavorare con le definizioni esistenti, i modelli e/o le  origini dati`,
  [`Working...`]: `Operazione in corso...`,
  [`Wrapper Policies`]: `Politiche wrapper`,
  [`Wrong slicing arguments`]: `Argomenti di suddivisione non corretti`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL e XSD devono essere all'interno del proprio file zip nel file zip che si sta cercando di importare.`,
  [`XML Schema`]: `Schema XML`,
  [`XML Schema URL`]: `URL schema XML`,
  [`XML Schema preview`]: `Anteprima schema XML`,
  [`XML Validation Mode`]: `Modalità di convalida XML`,
  [`XML schema is not valid. Please correct the error.`]: `Lo schema XML non è valido. Correggere l'errore.`,
  [`XML to JSON`]: `XML a JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `Versione XSLT`,
  [`YAML has been successfully validated`]: `YAML è stato convalidato correttamente`,
  [`Yes`]: `Sì`,
  [`Yes, enable it`]: `Sì, abilita`,
  [`Yes, reset`]: `Sì, ripristina`,
  [`Yes. Update API Assembly`]: `Si. Aggiorna Assemblaggio API`,
  [`You are authenticated!`]: `L'utente è autenticato!`,
  [`You do not have permission to use the Test Application`]: `Non si dispone dell'autorizzazione per utilizzare l'applicazione di test`,
  [`You can also copy the link and send it directly to the user.`]: `È anche possibile copiare il link ed inviarlo direttamente all'utente.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `È possibile scegliere di non pubblicare l'API in questo momento, oppure è possibile pubblicarla immediatamente nell'endpoint di test o di produzione o entrambi. Come opzione, è possibile specificare una frequenza massima di chiamate API consentite in base al numero di chiamate in un determinato intervallo di tempo.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `È possibile scegliere di non pubblicare l'API in questo momento, oppure è possibile pubblicarla immediatamente nell'endpoint di test o di produzione o entrambi. È possibile specificare una frequenza massima di chiamate all'API in base al un numero di chiamate per intervallo di tempo specificato.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `In questa fase è possibile scegliere di lasciare l'API non protetta, oppure è possibile richiedere che un'applicazione chiamante fornisca un ID client. È possibile configurare le ulteriori opzioni di sicurezza dopo la creazione della definizione API.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `È possibile immettere il nome o il titolo di un catalogo. Il nome del catalogo avrà la precedenza se entrambi sono disponibili.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `È possibile inviare una e-mail al proprietario dell'organizzazione consumer. Il messaggio viene inviato utilizzando il server e-mail configurato in Notifiche.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `È possibile inviare una e-mail al proprietario dell'organizzazione consumer. Il messaggio viene inviato utilizzando il server e-mail configurato nella sezione Notifiche.`,
  [`You can send an email to a provider organization owner.`]: `È possibile inviare un'e-mail ad un proprietario dell'organizzazione provider.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `È possibile trasferire la proprietà ad un nuovo utente immettendo il relativo indirizzo e-mail. Gli utenti riceveranno una e-mail contenente un link di attivazione per la registrazione. Una volta registrato, l'utente diventerà il nuovo proprietario. Assegnare nuovi ruoli al proprietario corrente in modo che possa continuare a lavorare in Cloud Manager.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `È possibile trasferire la proprietà ad un utente registrato immettendo il relativo indirizzo e-mail. Assegnare nuovi ruoli al proprietario corrente in modo che possa continuare a lavorare in Cloud Manager.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `È possibile utilizzare un file di definizione OpenAPI per aggiungere un'API REST in IBM API Connect. Il formato del file può essere JSON o YAML.  Una volta importata, la definizione API viene visualizzata nell'elenco delle definizioni API.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `Non è possibile eliminare questo tipo perché rimuoverà il tipo root ('Query').`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `Non è possibile selezionare un'API il cui tipo di gateway non corrisponde al tipo di gateway di questo prodotto.`,
  [`You currently don’t have any gateway extensions`]: `Attualmente non si dispone di alcuna estensione gateway`,
  [`You currently don’t have any plans to migrate`]: `Attualmente non si dispone di alcun piano da migrare.`,
  [`You currently don't have any policy.`]: `Al momento non hai nessuna politica.`,
  [`You do not have permission to any spaces.`]: `Non si dispone dell'autorizzazione a nessuno spazio.`,
  [`You do not have permission to publish products to any catalog or space.`]: `Non si dispone dell'autorizzazione per pubblicare prodotti in qualsiasi catalogo o spazio.`,
  [`You do not have permission to stage products to any catalog or space.`]: `Non si dispone dell'autorizzazione ad eseguire lo staging dei prodotti in nessun catalogo o spazio.`,
  [`You don’t currently have any billing integrations.`]: `Attualmente, non si dispone di alcuna integrazione di fatturazione.`,
  [`You don't currently have any response.`]: `Attualmente non ci sono risposte.`,
  [`You don't currently have any user-defined policies.`]: `Attualmente non sono presenti politiche definite dall'utente.`,
  [`You don't have permission to access this page.`]: `Non si dispone dell'autorizzazione per accedere a questa pagina.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Il certificato è stato modificato. Il salvataggio di questa applicazione aggiornerà la relativa configurazione.`,
  [`You haven't added any APIs or Products`]: `Non è stata aggiunta nessuna API o prodotto`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `È possibile gestire l'appartenenza al catalogo aggiungendo nuovi utenti al catalogo ed assegnando i ruoli agli utenti.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `È possibile gestire l'appartenenza dello spazio aggiungendo nuovi utenti allo spazio ed assegnando i ruoli agli utenti.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `È possibile gestire l'appartenenza dello spazio aggiungendo nuovi utenti allo spazio ed assegnando i ruoli agli utenti.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `È necessario aggiungere la propria API ad un prodotto pronto per la pubblicazione. Un prodotto raccoglie un insieme di API e piani in un'offerta che l'utente rende disponibile per i propri sviluppatori. Un piano comprende impostazioni del limite di frequenza che possono essere applicate al piano come insieme o specificate per ciascuna operazione in un'API.`,
  [`You must choose a definition`]: `È necessario scegliere una definizione`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `È necessario abilitare almeno un servizio gateway in modo che le API pubblicate in questo Catalogo siano disponibili per essere richiamate in un endpoint del servizio gateway. È possibile abilitare più servizi gateway.`,
  [`You must enable spaces`]: `È necessario abilitare gli spazi`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `È necessario registrare di seguito il segreto e l'ID del client da utilizzare nello sviluppo dell'applicazione. Il segreto del client non può essere visualizzato di nuovo.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `La licenza API Connect Reserved Instance consente di scaricare ed impostare uno o più DataPower API Gateway remoti. I gateway possono trovarsi on-premise o in qualsiasi cloud.`,
  [`Your API has been created`]: `L'API è stata creata`,
  [`Your API is live!`]: `L'API è attiva!`,
  [`Your API is online!`]: `L'API è in linea`,
  [`Your Catalog is being deleted. This may take a while...`]: `Il catalogo sta per essere eliminato. Questa operazione potrebbe richiedere alcuni minuti...`,
  [`Your Consumer organization is being created. This may take a while...`]: `L'organizzazione consumer è in fase di creazione. Questa operazione potrebbe richiedere alcuni minuti...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `L'invito non è più valido. Controllare l'impostazione di timeout dell'invito e inviare di nuovo l'invito.`,
  [`Your Provider organization is being created. This may take a while...`]: `L'organizzazione provider è in fase di creazione. Questa operazione potrebbe richiedere alcuni minuti...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `L'organizzazione provider sta per essere eliminata. Questa operazione potrebbe richiedere alcuni minuti...`,
  [`Your member is being deleted. This may take a while...`]: `L'eliminazione del membro è in corso. Questa operazione potrebbe richiedere alcuni minuti...`,
  [`Your Space is being deleted. This may take a while...`]: `Lo spazio sta per essere eliminato. Questa operazione potrebbe richiedere alcuni minuti...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `Il provider OAuth presenta modifiche non salvate. Fare clic su OK per continuare senza salvare.`,
  [`Your credentials have expired.`]: `Le credenziali sono scadute.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `L'invito è stato inviato correttamente con il seguente link di invito:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `La richiesta è ancora in fase di elaborazione e impiega più tempo del previsto. Aggiornare tra pochi minuti prima di riprovare la richiesta.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Il prodotto verrà salvato prima di continuare, si è sicuri?`,
  [`Your trial ends on {date}`]: `Il periodo di prova termina il {date}`,
  [`Your trial expired on {date}.`]: `Il periodo di prova è scaduto il {date}.`,
  [`Zoom in`]: `Ingrandisci`,
  [`Zoom in and out`]: `Ingrandisci o riduci`,
  [`Zoom out`]: `Riduci`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `una mancanza di supporto CORS sul server di destinazione (vedi suggerimento di seguito)`,
  [`a LDAP provider`]: `un provider LDAP`,
  [`activity`]: `attività`,
  [`add item...`]: `aggiungi elemento...`,
  [`add property...`]: `aggiungi proprietà...`,
  [`added`]: `aggiunto`,
  [`admin`]: `amministrazione`,
  [`administrator`]: `amministratore`,
  [`all`]: `tutti`,
  [`an API Connect Local User Registry`]: `Un registro utente locale API Connect`,
  [`an authentication URL`]: `un URL di autenticazione`,
  [`an untrusted certificate has been encountered`]: `è stato rilevato un certificato non attendibile`,
  [`and`]: `e`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Applicazione:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `approvazione`,
  [`approval task for product`]: `attività di approvazione per il prodotto`,
  [`archived`]: `archiviato`,
  [`authenticated`]: `autenticato`,
  [`authorization code time to live (seconds)`]: `codice di autorizzazione tempo di vita (secondi)`,
  [`Authorization header pass through`]: `Passthrough intestazione autorizzazione`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `di blocco`,
  [`body`]: `corpo`,
  [`body-param`]: `parametri-corpo`,
  [`cancel`]: `annulla`,
  [`case`]: `caso`,
  [`catalog`]: `Catalogo`,
  [`catalog:`]: `Catalogo:`,
  [`catch`]: `cattura`,
  [`changed`]: `modificato`,
  [`cipher`]: `crittografia`,
  [`code id_token`]: `codice id_token`,
  [`code id_token token`]: `codice token id_token`,
  [`code token`]: `codice token`,
  [`Consume`]: `Utilizza`,
  [`consumer`]: `consumer`,
  [`consumes`]: `utilizza`,
  [`composite`]: `composito`,
  [`created`]: `creato`,
  [`Created API`]: `API creata`,
  [`custom string`]: `stringa personalizzata`,
  [`day`]: `giorno`,
  [`day(s)`]: `giorni`,
  [`default`]: `valore predefinito`,
  [`default value`]: `valore predefinito`,
  [`definition`]: `definizione`,
  [`deprecated`]: `obsoleto`,
  [`description`]: `description`,
  [`development`]: `sviluppo`,
  [`edit condition`]: `modifica condizione`,
  [`edited`]: `modificato`,
  [`environment`]: `Catalogo`,
  [`error`]: `errore`,
  [`false`]: `falso`,
  [`file`]: `file`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `icone font-awesome 4.5.0`,
  [`for`]: `per`,
  [`header`]: `intestazione`,
  [`hear`]: `ascolta`,
  [`hello`]: `Salve`,
  [`helloName`]: `Ciao {name}!`,
  [`here`]: `in questo punto`,
  [`hit`]: `hit`,
  [`hits`]: `hit`,
  [`hour`]: `ora`,
  [`hour(s)`]: `ore)`,
  [`hours`]: `ore`,
  [`identified`]: `identificato`,
  [`Increased`]: `Incrementato`,
  [`Increment`]: `Incrementa`,
  [`info`]: `info`,
  [`information about external documentation`]: `informazioni sulla documentazione esterna`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `Invece di rispettare la richiesta exp del provider OIDC, l'access_token o il refresh_token generato da APIC rispetterà l'impostazione APIC`,
  [`introspect URL`]: `esamina URL`,
  [`Issue`]: `Problema`,
  [`Issues`]: `Problemi`,
  [`item`]: `elemento`,
  [`iterate over`]: `itera`,
  [`last modified`]: `ultima modifica`,
  [`Log Level`]: `Livello di log`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `log`,
  [`Logout`]: `Disconnetti`,
  [`Logout redirect`]: `Reindirizzamento disconnessione`,
  [`Logout redirect URL`]: `URL di reindirizzamento disconnessione`,
  [`location`]: `ubicazione`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `minuto`,
  [`minute(s)`]: `minuto/i`,
  [`minutes`]: `minuti`,
  [`mutation type`]: `tipo di mutazione`,
  [`mutual SSL authentication is required`]: `è richiesta l'autenticazione SSL reciproca`,
  [`name`]: `Nome`,
  [`Name|extension`]: `Nome`,
  [`Name|path parameters`]: `Nome`,
  [`Name|security definitions`]: `Nome`,
  [`non-blocking`]: `non di blocco`,
  [`none`]: `nessuno`,
  [`none selected`]: `nulla selezionato`,
  [`Object`]: `Oggetto`,
  [`off`]: `disattivo`,
  [`of {total} pages`]: `di {total} pagine`,
  [`of {total} page`]: `di {total} pagine`,
  [`offline`]: `non in linea`,
  [`on`]: `attivo`,
  [`online`]: `in linea`,
  [`or`]: `o`,
  [`or redact from all`]: `o revisiona da tutto`,
  [`open and close list of options`]: `apri e chiudi elenco opzioni`,
  [`organization saved`]: `organizzazione salvata`,
  [`organization-manager`]: `gestione-organizzazione`,
  [`otherwise`]: `altrimenti`,
  [`payload`]: `payload`,
  [`Payload`]: `Payload`,
  [`pending`]: `in sospeso`,
  [`produces`]: `produce`,
  [`production`]: `produzione`,
  [`production and development`]: `produzione e sviluppo`,
  [`property`]: `proprietà`,
  [`provider`]: `provider`,
  [`publish product`]: `pubblica prodotto`,
  [`published`]: `pubblicato`,
  [`query type`]: `tipo di query`,
  [`query-parameters`]: `parametri query`,
  [`realized`]: `realizzato`,
  [`recipients`]: `destinatari`,
  [`removed`]: `rimosso`,
  [`replaced`]: `sostituito`,
  [`repeatable`]: `ripetibile`,
  [`Replenish`]: `Completa`,
  [`request`]: `richiesta`,
  [`requested by`]: `richiesto da`,
  [`Resolver weight`]: `Peso resolver`,
  [`response`]: `risposta`,
  [`response-param`]: `parametro-risposta`,
  [`retired`]: `ritirato`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `salvato`,
  [`scalar`]: `scalare`,
  [`second`]: `secondo`,
  [`second(s)`]: `secondo/i`,
  [`seconds`]: `secondi`,
  [`select owner user`]: `seleziona utente proprietario`,
  [`select user`]: `seleziona utente`,
  [`soap-body`]: `soap-body`,
  [`source`]: `origine`,
  [`space`]: `spazio`,
  [`Space settings`]: `Impostazioni spazio`,
  [`Skip`]: `Ignora`,
  [`specified`]: `specificato`,
  [`staged`]: `sottoposto a staging`,
  [`start generating tests automatically.`]: `iniziare a generare le verifiche automaticamente.`,
  [`subject`]: `soggetto`,
  [`subscription type`]: `tipo di sottoscrizione`,
  [`supersede`]: `sovrascrivi`,
  [`superseded`]: `sostituito`,
  [`target`]: `destinazione`,
  [`the server is unavailable`]: `il server non è disponibile`,
  [`this user registry type`]: `questo tipo di registro utente`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Questa è una pagina di pubblicazione temporanea in cui la ripubblicazione conserva le sottoscrizioni.`,
  [`to production`]: `in produzione`,
  [`topology-administrator`]: `topologia-amministratore`,
  [`Trial days left`]: `Giorni di prova rimasti`,
  [`true`]: `vero`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `ttl è stato cambiato`,
  [`type`]: `tipo`,
  [`unarchived`]: `non archiviato`,
  [`union type`]: `tipo di unione`,
  [`unlimited`]: `Illimitato`,
  [`updated`]: `aggiornato`,
  [`Updated from new schema`]: `Aggiornato dal nuovo schema`,
  [`Upgrade`]: `Aggiorna`,
  [`v5 legacy behavior`]: `Funzionamento legacy v5`,
  [`version 2`]: `versione 2`,
  [`version 3`]: `versione 3`,
  [`viewer`]: `visualizzatore`,
  [`value`]: `valore`,
  [`warn`]: `avvertenza`,
  [`warning`]: `Avvertenza`,
  [`warnings`]: `Avvertenze`,
  [`was not found. Please select a configured OAuth provider.`]: `non trovato. Selezionare un provider OAuth configurato.`,
  [`week`]: `settimana`,
  [`with`]: `con`,
  [`with|supersede with`]: `con`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `stato in sandbox`,
  [`your file here, or`]: `il file qui o`,
  [`{count} found!`]: `{count} trovato/i.`,
  [`{current} of {total} page`]: `{current} di {total} pagine`,
  [`{current} of {total} pages`]: `{current} di {total} pagine`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} giorni, {hours} ore, e {minutes} minuti`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} giorni e {hours} ore`,
  [`{docType} is required`]: `{docType} è obbligatorio`,
  [`{docType} title`]: `Titolo {docType}`,
  [`Product title`]: `Titolo del prodotto`,
  [`Application title`]: `Titolo applicazione`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} ore e {minutes} minuti`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} gateway nascosti a causa della incompatibilità con il tipo di gateway specificato nel prodotto o API.`,
  [`{min}-{max} of {total} items`]: `{min}-{max} di {total} elementi`,
  [`{min}-{max} of {total} item`]: `{min}-{max} di {total} elemento`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} di {total} politiche`,
  [`{min}-{max} of {total} Policy`]: `{min}-{max} di {total} Politica`,
  [`{min}-{max} of {total} types`]: `{min}-{max} di {total} tipi`,
  [`{min}-{max} of {total} type`]: `{min}-{max} di {total} tipo`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} di {total} risultati della ricerca`,
  [`{min}-{max} of {total} search result`]: `{min}-{max} del risultato della ricerca {total}`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} di {total} cataloghi`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{max} di {total} Catalogo`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} di {total} spazi`,
  [`{min}-{max} of {total} Space`]: `{min}-{max} di {total} Spazio`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} di {total} estensioni`,
  [`{min}-{max} of {total} Extension`]: `{min}-{max} di {total} Estensione`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} su {total} piani del prodotto`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{max} di {total} Piano prodotto`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} su {total} gateway`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{max} di {total} Gateway`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (API) non contiene 'x-ibm-configuration'`,
  [`{name} (Product) has been published!`]: `{name} (Prodotto) è stato pubblicato!`,
  [`{name} (Product) has been staged!`]: `{name} (Prodotto) è stato sottoposto a staging.`,
  [`{name} (Product) has not been published!`]: `{name} (Prodotto) non è stato pubblicato!`,
  [`{name} (Role) has been created`]: `{name} (Ruolo) è stato creato.`,
  [`{name} successfully deleted`]: `{name} eliminato correttamente.`,
  [`{name} (Role) has been updated`]: `{name} (Ruolo) è stato aggiornato.`,
  [`{name} has been assigned the owner`]: `{name} è stato assegnato al proprietario`,
  [`{number} Error`]: `{number} Errore`,
  [`{number} Errors`]: `{number} Errori`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{operation} attività di approvazione per prodotto {prodname}:{version} richiesta da {username}`,
  [`{path} does not exist`]: `{path} non esiste`,
  [`{productName} has no migration target set.`]: `{productName} non dispone della destinazione di migrazione impostata.`,
  [`{state} Pending`]: `{state} In sospeso`,
  [`{status} API error`]: `Errore API {status}`,
  [`{status} Bad Request`]: `{status} Richiesta errata`,
  [`{status} Forbidden`]: `{status} Vietato`,
  [`{status} Gateway Error`]: `{status} Errore gateway`,
  [`{status} Unauthorized`]: `{status} Non autorizzato`,
  [`({time} after being sent)`]: `({time} dopo che è stato inviato`,
  [`({time} after being queued)`]: `({time} dopo che è stato accodato`,
  [`{title} (Product) has been {state}.`]: `{title} (Prodotto) è stato {state}.`,
  [`{title} has been deleted`]: `Eliminazione di {title} completata`,
  [`{title} has been {changedName}.`]: `{title} è stato {changedName}.`,
  [`{title} has not been deleted!`]: `Eliminazione di {title} non riuscita.`,
  [`{title} has not been {changedName}!`]: `{title} non è stato {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type} è stato {changedName}.`,
  [`{type} has not been {changedName}!`]: `{type} non è stato {changedName}!`,
  [`{type} history log`]: `Log cronologia {type}`,
  [`{username} does not exist`]: `{username} non esiste`,
  [`{username} does not exist in the user registry`]: `{username} non esiste nel registro utente.`,
  [`{username} is not associated with this consumer org`]: `{username} non è associato a questa organizzazione consumer.`,
  [`{username} has been created as a {type}.`]: `{username} è stato creato come un {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `L'app {appName} è stata reintegrata nel Developer Portal {consumerOrg}`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `L'app {appName} è stata sospesa nel Developer Portal {consumerOrg}`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} Registrazione account Developer Portal`,
  [`{{count}} errors need attention`]: `{count} errori che necessitano di attenzione`,
  // lts only translations
  [`Configure JSON settings`]: `Configura impostazioni JSON`,
  [`Configure XML settings`]: `Configura impostazioni XML`,
  [`Configure options`]: `Configura opzioni`,
  [`Detect`]: `Rileva`,
  [`Document type`]: `Tipo di documento`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Dimensione massima del documento`,
  [`Maximum name length`]: `Lunghezza massima del nome`,
  [`Maximum nesting depth`]: `Profondità di nidificazione massima`,
  [`Maximum number length`]: `Lunghezza massima del numero`,
  [`Maximum number of unique names`]: `Numero massimo di nomi univoci`,
  [`Maximum number of unique namespaces`]: `Numero massimo di spazi dei nomi univoci`,
  [`Maximum number of unique prefixes`]: `Numero massimo di prefissi univoci`,
  [`Maximum value length`]: `Lunghezza massima del valore`,
  [`Maximum width`]: `Larghezza massima`,
  [`Maximum stack size`]: `Dimensione massima dello stack`,
  [`Parse settings`]: `Impostazioni di analisi`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Specifica la dimensione massima del documento, in byte, accettata dall'azione di analisi.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Specifica il livello massimo di profondità degli elementi nidificati in un messaggio XML o JSON accettato dall'azione di analisi.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Specifica il livello massimo di profondità degli elementi nidificati in un messaggio XML, JSON o GraphQL che l'operazione di analisi accetta.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Specifica la lunghezza massima del nome in byte accettata dall'azione di analisi.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Specifica che la lunghezza massima del numero limita il numero di byte nella parte relativa al valore di una coppia etichetta-valore quando il valore è un numero.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Specifica il numero massimo di URI dello spazio dei nomi XML univoci accettati dall'azione di analisi. Tale limite conteggia tutti gli spazi dei nomi XML, indipendentemente dal numero di prefissi utilizzati per dichiararli.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Specifica il numero massimo di prefissi dello spazio dei nomi XML univoci in un documento accettati dall'azione di analisi. Tale limite conteggia più prefissi definiti per lo stesso spazio dei nomi, ma non conteggia più spazi dei nomi definiti in parti differenti del documento di input in un singolo prefisso.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Specifica il numero massimo di nomi univoci accettati dall'azione di analisi.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Specificare la lunghezza massima del valore accettata dall'azione di analisi.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Specifica la larghezza massima di un payload accettata dall'azione di analisi.`,
  [`Specifies the type of document to parse.`]: `Specifica il tipo di documento da analizzare.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Specifica la versione del processore XSLT. L'impostazione predefinita è XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Specifica se abilitare la verifica rigorosa degli errori XSLT. Le operazioni non rigorose provano ad eseguire il ripristino da alcuni errori come l'utilizzo di variabili non dichiarate, il richiamo di template non dichiarati e così via. Per impostazione predefinita, il controllo assoluto degli errori XSLT è abilitato.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Specifica se abilitare la creazione di profili del foglio di stile. Questa opzione non deve essere utilizzata negli ambienti di produzione. Per impostazione predefinita, la creazione di profili del foglio di stile è disabilitata.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Specifica se eseguire il foglio di stile, lo script XQuery e lo script JSONiq in modalità di debug. Quando un foglio di stile, uno script XQuery o uno script JSONiq viene eseguito in modalità di debug, genera una pagina web personalizzata invece di visualizzare un output normale. La pagina web illustra esattamente ciò che si è verificato durante l'esecuzione, inclusi i valori delle variabili e da dove sono scaturiti determinate parti dell'output. Questa opzione non deve essere utilizzata negli ambienti di produzione. Per impostazione predefinita, la modalità di debug è disabilitata.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Specifica se il foglio di stile deve essere eseguito in modalità di flusso. La trasformazione del documento viene avviata prima che l'input venga analizzato completamente. Non è possibile utilizzare la modalità di flusso per tutti i fogli di stile. Se il foglio di stile non può essere trasmesso in streaming, viene generato un errore e l'input non viene elaborato. Per impostazione predefinita, la modalità di flusso è disabilitata.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Specifica se tentare di eseguire il foglio di stile in modalità di flusso. La trasformazione del documento viene avviata prima che l'input venga analizzato completamente. Non è possibile utilizzare la modalità di flusso per tutti i fogli di stile. Se il foglio di stile non può essere trasmesso in streaming, viene generata un'avvertenza durante la compilazione e il foglio di stile viene letto nell'intero input come normale al momento dell'esecuzione. Per impostazione predefinita, il tentativo di eseguire il foglio di stile in modalità di flusso è disabilitato.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Specifica se eseguire l'escape dell'output prodotto dal foglio di stile durante l'elaborazione. L'esecuzione dell'escape minimo è particolarmente utile durante la gestione delle serie di caratteri non inglesi. Per impostazione predefinita, l'escape minimo è disabilitato.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Indica il numero massimo di byte che lo stack può utilizzare durante l'esecuzione di un foglio di stile o di altro contenuto compilato. Questa impostazione viene utilizzata per bloccare la ricorsività infinita. Il valore minimo è 10 kilobyte o 10240 byte. Il valore massimo è 100 megabyte o 104.857.600 byte. Il valore predefinito è 1 megabyte o 1.048.576 byte.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Specifica il funzionamento della convalida da applicare ai file WSDL controllati per la conformità alla sezione 5 del profilo di base WS-I (versione 1.0, Aprile 2004). L'impostazione predefinita è Avvertenza.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Specifica il comportamento della convalida per l'elemento soap:Body. L'impostazione predefinita è Assoluto.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Specifica il comportamento della convalida per l'elemento soap:Header. L'impostazione predefinita è Lax.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Specifica il comportamento di convalida del dettaglio dell'errore. L'impostazione predefinita è Assoluto.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Specifica se richiedere la compatibilità con i wrapper in stile RPC. Per impostazione predefinita, i wrapper in stile RPC non sono obbligatori.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Specifica se consentire allo schema di accettare la maggior parte degli utilizzi di elementi con xsi:type='SOAP-ENC:Array' congruenti con SOAP 1.1 Sezione 5, anche se tali attributi violano la specifica dello schema XML. Normalmente, l'attributo xsi:type deve indicare un tipo uguale o derivato dal tipo reale dell'elemento. Per gli schemi compilati con questa opzione, xsi:type viene accettato in modo specifico per il tipo complesso 'Array' della codifica SOAP 1.1 se il tipo dell'elemento è derivato da SOAP-ENC:Array; questo rappresenta il caso opposto rispetto al caso normalmente consentito. Il contrario è il normale caso accettabile. Per impostazione predefinita, gli elementi con xsi:type='SOAP-ENC:Array' non vengono accettati.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Specifica se eseguire una convalida extra dello schema seguendo le regole di codifica in SOAP 1.1 sezione 5. Quando è abilitata, i membri dell'array SOAP vengono convalidati, gli attributi come @id e @href sono consentiti anche se non sono consentiti dallo schema e i valori @href vengono controllati per garantire che abbiano un elemento @id corrispondente. Per impostazione predefinita, la convalida extra non viene eseguita.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Specifica se l'elemento xs:any nello schema convalida solo elementi child per nome. La specifica dello schema XML richiede che, se un carattere jolly corrisponde ad un elemento ma tale elemento non dispone di una dichiarazione, l'elemento viene convalidato in base ad un attributo xsi:type presente su di esso. Questa opzione ignora tali attributi xsi:type. Deve essere utilizzata per casi come la convalida della busta SOAP, quando un'ulteriore fase di convalida convalida il contenuto corrispondente al carattere jolly, possibilmente utilizzando le regole di codifica SOAP 1.1. Per impostazione predefinita, gli attributi xsi:type non vengono ignorati.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Specifica se seguire rigorosamente il binding SOAP nel WSDL. Quando l'opzione è attiva, nelle buste SOAP 1.2 vengono visualizzati solo i messaggi associati a SOAP 1.2 e nelle buste SOAP 1.1 vengono visualizzati solo i messaggi associati a SOAP 1.1. Per impostazione predefinita, il binding SOAP assoluto è disabilitato.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Specifica se compilare le politiche XACML con le informazioni di debug. Tenere presente che i messaggi di debug XACML sono controllati dall'evento del log nella categoria XACML. Utilizzare il livello di log debug per visualizzare i messaggi di debug XACML completi. Per impostazione predefinita, le politiche XACML non vengono compilate con le informazioni di debug.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Specifica se lo schema o il documento WSDL accetta i messaggi in cui il contenuto binario codificato con base64 è stato ottimizzato in base alle specifiche MTOM/XOP. L'ottimizzazione binaria XOP sostituisce i dati binari codificati in base 64 con un elemento di riferimento xop:Include che fa riferimento ai dati binari non codificati contenuti in un allegato. Per impostazione predefinita, i messaggi ottimizzati con MTOM/XOP sono disabilitati.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Un gruppo di organizzazioni consumer è una raccolta di organizzazioni consumer e fornisce un criterio efficace per controllare chi può visualizzare ed effettuare la sottoscrizione alle API nei prodotti. Utilizzando un gruppo di organizzazioni consumer, è possibile definire questo accesso per tutti gli sviluppatori nelle organizzazioni in tale gruppo in una singola operazione piuttosto di dover definire separatamente l'accesso per le organizzazioni.`,
  [`API Connect Home`]: `Home API Connect`,
  [`Add new group`]: `Aggiungi nuovo gruppo`,
  [`Add consumer organizations to the group as required`]: `L'aggiunta di organizzazioni consumer al gruppo è obbligatoria.`,
  [`Add the following configuration`]: `Aggiungi la seguente configurazione`,
  [`Add the following configuration to`]: `Aggiungi la seguente configurazione a`,
  [`Additions`]: `Aggiunte`,
  [`Additional GraphQL capabilities`]: `Ulteriori funzioni di GraphQL`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Impostazioni aggiuntive per le API di graphQL incluse in questo piano`,
  [`An organization or a group is required.`]: `È richiesta un'organizzazione o un gruppo.`,
  [`An organization/group is required.`]: `È necessario un gruppo di organizzazioni`,
  [`Analysis configuration`]: `Configurazione dell'analisi`,
  [`Application & Credentials`]: `Applicazione & Credenziali`,
  [`Are you sure you want to delete this API version?`]: `Si è sicuri di voler eliminare questa versione dell'API?`,
  [`Are you sure you want to delete this API with all versions?`]: `Si è sicuri di voler eliminare questa API e tutte le versioni?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Si è sicuri di voler rimuovere l'estensione del gateway dal gateway?`,
  [`Are you sure you want to rename all versions of this API?`]: `Si è sicuri di voler rinominare tutte le versioni di questa API?`,
  [`Are you sure you want to rename this API version?`]: `Si è sicuri di voler rinominare questa versione API?`,
  [`Associate groups to the organization as required`]: `L'associazione di gruppi all'organizzazione è obbligatoria`,
  [`At least one option must be enabled`]: `È necessario abilitare almeno un'opzione`,
  [`Attribute mapping`]: `Associazione attributo`,
  [`Attempt streaming rule`]: `Regola della prova di flusso`,
  [`Auto generated default paths and operations`]: `Operazioni e percorsi predefiniti generati automaticamente`,
  [`Base API endpoint`]: `Endpoint API base`,
  [`Consumer orgs`]: `Organizzazioni consumer`,
  [`Calculate Query Characteristics`]: `Calcola caratteristiche della query`,
  [`Calculate Response Characteristics`]: `Calcola caratteristiche della risposta`,
  [`Certificate or Shared Secret for Verify`]: `Certificato o segreto condiviso per la verifica`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Dai un'occhiata ai nostri laboratori pratici dove puoi richiedere di provare tutte le funzionalità di gestione delle API per un massimo di una settimana!`,
  [`Clone API`]: `Clona API`,
  [`Consumer organizations and groups`]: `Organizzazioni consumer e gruppi`,
  [`Create new API version`]: `Crea nuova versione API`,
  [`Create new product version`]: `Crea nuova versione del prodotto`,
  [`Create a GraphQL API based on a GraphQL service`]: `Creare un'API GraphQL in base al servizio GraphQL`,
  [`Custom rate limit`]: `Limite di frequenza personalizzato`,
  [`Delete API`]: `Elimina API`,
  [`Delete this version`]: `Elimina questa versione`,
  [`Deletion confirmation`]: `Conferma eliminazione`,
  [`Deletions`]: `Eliminazioni`,
  [`Display name`]: `Nome di visualizzazione`,
  [`Display Name`]: `Nome di visualizzazione`,
  [`Details`]: `Dettagli`,
  [`Edit GraphQL Rate Limit|title`]: `Modifica limite di frequenza GraphQL|titolo`,
  [`Edit GraphQL rate limits`]: `Modifica limiti di frequenza GraphQL`,
  [`Edit group`]: `Modifica gruppo`,
  [`Edit condition`]: `Modifica condizione`,
  [`Edit rate limit|title`]: `Modifica limite di frequenza`,
  [`Edit rate limit|menu`]: `Modifica limite di frequenza`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Non ci sono Prodotti pubblicati in questo Catalogo oppure questa applicazione è già iscritta a tutti i Piani in tutti i prodotti.`,
  [`Enforce Policy`]: `Applica politica`,
  [`Enter a group summary`]: `Immettere un riepilogo di gruppo`,
  [`Enter a group title`]: `Immettere un titolo di gruppo`,
  [`Enter group details below`]: `Immettere i dettagli del gruppo in basso`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Immettere l'URL ed il nome dello schema per il servizio GraphQL che si desidera sottoporre a proxing`,
  [`Enum value`]: `Valore enum`,
  [`Go to catalog settings to configure a new billing integration`]: `Andare alle impostazioni del catalogo per configurare una nuova integrazione di fatturazione`,
  [`GraphQL introspect`]: `Introspezione GraphQL`,
  [`GraphQL server`]: `Server GraphQL`,
  [`GraphQL Setting`]: `Impostazione GraphQL`,
  [`GraphQL server URL`]: `URL server GraphQL`,
  [`GraphQL server name`]: `Nome server GraphQL`,
  [`GraphQL type analysis configuration`]: `Configurazione analisi tipo GraphQL`,
  [`GraphQL resolver analysis configuration`]: `Configurazione analisi resolver GraphQL`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Nel seguente elenco possono essere creati e gestiti gruppi`,
  [`Groups will be listed here.`]: `I gruppi verranno elencati qui`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Salve {firstName},\n\nLa richiesta di {action} il prodotto API {productName}:{version} nel catalogo \n{catalog} è stata approvata.\n\n{#if comments}\nL'approvatore ha incluso i seguenti commenti:\n\n{comments}\n\n{{/if}}\nÈ possibile verificare lo stato del prodotto qui:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Salve,\n\nquesto è un messaggio di prova da IBM API Connect dal server e-mail configurato\n(mailServer.\n\nSe si è ricevuto questo messaggio come previsto, il test è riuscito!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Salve,\n\nl'utente {originator} ha ritirato la propria richiesta di sottoscrizione con l'applicazione {appName}\nal piano {planName} per la versione {productVersion} del prodotto API {productName} nel \ncatalogo {catalog}.\n\nNon è richiesta nessuna ulteriore azione.\n`,
  [`Invoke GraphQL server`]: `Richiama server GraphQL`,
  [`Invoke the backend GraphQL server`]: `Richiama il server GraphQL di backend`,
  [`JWKS endpoint`]: `Endpoint JWKS`,
  [`LDAP Field Name`]: `Nome campo LDAP`,
  [`Limit maximum nesting per query`]: `Limite numero massimo di nidificazioni per query`,
  [`Limit resolve complexity rate on a per key basis`]: `Limita la frequenza di complessità della risoluzione su base per chiave`,
  [`Limit type complexity rate on a per key basis`]: `Limita la frequenza di complessità del tipo su base per chiave`,
  [`Manage APIs included in this product`]: `Gestisci API incluse in questo prodotto`,
  [`Manage groups`]: `Gestisci gruppi`,
  [`Maximum nesting`]: `Numero massimo di nidificazioni`,
  [`Maximum resolve complexity`]: `Massima complessità di risoluzione`,
  [`Maximum type complexity`]: `Massima complessità di tipo`,
  [`Name of the group`]: `Nome del gruppo`,
  [`New API version`]: `Nuova versione API`,
  [`New GraphQL API`]: `Nuova API GraphQL`,
  [`New product version`]: `Nuova versione prodotto`,
  [`New Version`]: `Nuova versione`,
  [`New version`]: `Nuova versione`,
  [`No billing integration exists`]: `Non esiste alcuna integrazione di fatturazione`,
  [`No CA bundles available`]: `Nessun bundle CA disponibile`,
  [`No Data`]: `Nessun dato`,
  [`No certificates available`]: `Nessun certificato disponibile`,
  [`No data`]: `Nessun dato`,
  [`No group found`]: `Nessun gruppo trovato`,
  [`OAuth Auth URL`]: `URL Autorizzazione OAuth`,
  [`OAuth Token URL`]: `URL token OAuth`,
  [`Open Stripe dashboard`]: `Dashboard Apri stripe`,
  [`Operation/Path`]: `Operazione/Percorso`,
  [`Operation Path`]: `Percorso operazione`,
  [`Operations and Paths`]: `Operazioni e percorsi`,
  [`Operator`]: `Operatore`,
  [`Organizations and groups will be listed here.`]: `Le organizzazioni e i gruppi verranno elencati qui`,
  [`Page not found`]: `Pagina non trovata`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Copiare il codice nella CLI per continuare il processo. Il codice scade tra **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Aggiornare il dashboard Stripe per accedere alla chiave e al segreto API`,
  [`Please upload a schema definition language (SDL) file.`]: `Caricare un file SDL (schema definition language)`,
  [`Please upload a valid GraphQL schema.`]: `Caricare uno schema GraphQL valido`,
  [`Please upload a valid YAML file.`]: `Caricare un file YAML valido.`,
  [`Populate Server Configuration into Context Variables`]: `Popola Configurazione server in Variabili di contesto`,
  [`POST/GET a query to be validated and sent to the backend server`]: `Esegui POST/GET di una query da convalidare e inviare al server di backend`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `Esegui POST/GET di una query per ottenere il costo previsto del richiamo di tale query`,
  [`Preparing`]: `Preparazione`,
  [`Rate limit amount cannot be negative`]: `La quantità limite di frequenza non può essere negativa`,
  [`Rate limit per cannot be negative`]: `Il limite di frequenza non può essere negativo`,
  [`Rename API`]: `Rinomina API`,
  [`Rename`]: `Rinomina`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Richiesta di approvazione a {action} un prodotto API nel catalogo {catalog}`,
  [`Sandbox Sample Application`]: `Applicazione di esempio Sandbox`,
  [`Sandbox sample application credentials`]: `Credenziali applicazione di esempio Sandbox`,
  [`sample application credentials`]: `credenziali applicazione di esempio`,
  [`Saving`]: `Salvataggio in corso`,
  [`Search Orgs`]: `Ricerca organizzazioni`,
  [`Search definitions...`]: `Ricerca definizioni...`,
  [`Search errors...`]: `Ricerca errori...`,
  [`Search operations...`]: `Ricerca operazioni...`,
  [`Search organizations and groups`]: `Ricerca organizzazioni e gruppi`,
  [`Sorry, This feature is not available in`]: `Siamo spiacenti, questa funzione non è disponibile in`,
  [`Stopping`]: `Arresto in corso`,
  [`The user with username {username} already exists in the user registry.`]: `L'utente con nome utente {username} già esiste nel registro utente.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Vi sono modifiche API che non sono in esecuzione. Pubblicare nuovamente questa API nel catalogo Sandbox.`,
  [`There are no plans available for subscription.`]: `Non sono disponibili piani per la sottoscrizione.`,
  [`There are no attributes yet.`]: `Non sono ancora presenti attributi`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Immettere testo per aggiungere le organizzazioni consumer al gruppo`,
  [`Type to add groups to the organization`]: `Immettere testo per aggiungere i gruppi all'organizzazione`,
  [`Type to add organizations from {catalog}`]: `Immettere per aggiungere le organizzazioni dal {catalog}`,
  [`Type to add organizations or groups`]: `Immettere testo per aggiungere organizzazioni o gruppi`,
  [`Type to add organizations or groups from {catalog}`]: `Immettere per aggiungere le organizzazioni o i gruppi da {catalog}`,
  [`Type to add {title} to groups`]: `Immettere testo per aggiungere {title} ai gruppi`,
  [`Update Rate Limits`]: `Aggiorna limiti frequenza`,
  [`Upload the YAML file with configuration`]: `Carica il file YAML con configurazione`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Utilizzare l'esempio seguente come template per configurare il server GraphQL`,
  [`User managed`]: `Utente gestito`,
  [`URL {url} does not contain valid OpenAPI.`]: `L'URL {url} non contiene OpenAPI valido.`,
  [`Validating`]: `Convalida in corso`,
  [`View analytics and performance metrics for your APIs`]: `Visualizza le metriche di analisi e prestazioni per le proprie API`,
  [`View and add custom policies to the catalog`]: `Visualizza ed aggiungi politiche personalizzate al catalogo`,
  [`View and add custom exentions to the catalog`]: `Visualizza e aggiungi estensioni personalizzate al catalogo`,
  [`View user registries and OAuth providers`]: `Visualizza registri utente e provider OAuth`,
  [`View your application and manage API credentials`]: `Visualizzare l'applicazione e gestire le credenziali API`,
  [`Welcome to the API Connect Trial`]: `Benvenuti nella versione di prova di API Connect`,
  [`You haven't added any APIs`]: `Non è stata aggiunta alcuna API`,
  [`You currently don’t have any {resource}.`]: `Attualmente non si dispone di alcuna risorsa {resource}.`,
  [`plans to migrate`]: `piani da migrare`,
  [`gateway extensions`]: `estensioni del gateway`,
  [`application`]: `applicazione`,
  [`application authentication source`]: `origine autenticazione applicazione`,
  [`API or product`]: `API o prodotto`,
  [`base endpoints`]: `endpoint di base`,
  [`categories`]: `categorie`,
  [`certificates`]: `certificati`,
  [`channels`]: `canali`,
  [`consumer organizations`]: `organizzazioni consumer`,
  [`consumer organization group`]: `gruppo di organizzazioni consumer`,
  [`consumer organizations or groups`]: `organizzazioni o gruppi consumer`,
  [`correlation ids`]: `ID di correlazione`,
  [`cors policy`]: `politica CORS`,
  [`definitions`]: `definizioni`,
  [`examples`]: `esempi`,
  [`gateway services`]: `servizi gateway`,
  [`groups`]: `gruppi`,
  [`headers`]: `intestazioni`,
  [`links`]: `link`,
  [`member`]: `membro`,
  [`messages`]: `messaggi`,
  [`message traits`]: `caratteristiche messaggio`,
  [`operation traits`]: `caratteristiche operazione`,
  [`parameter definitions`]: `definizioni di parametri`,
  [`parameters`]: `parametri`,
  [`path`]: `percorso`,
  [`properties`]: `proprietà`,
  [`provider organizations`]: `organizzazioni provider`,
  [`request bodies`]: `corpi richiesta`,
  [`response definitions`]: `definizioni risposta`,
  [`responses`]: `risposte`,
  [`role`]: `ruolo`,
  [`scope`]: `ambito`,
  [`scopes`]: `ambiti`,
  [`schemas`]: `schemi`,
  [`schema definition`]: `definizione schema`,
  [`schemes list`]: `elenco schemi`,
  [`security definitions`]: `definizioni di sicurezza`,
  [`security schemes`]: `schemi di sicurezza`,
  [`servers`]: `server`,
  [`services`]: `servizi`,
  [`tags`]: `tag`,
  [`tags and external documentation`]: `tag e documentazione esterna`,
  [`target services`]: `servizi di destinazione`,
  [`tasks`]: `attività`,
  [`user registries`]: `registri utenti`,
  [`HTTP header`]: `Intestazione HTTP`,
  [`You currently don’t have any application authentication source.`]: `Al momento non hai alcuna origine di autenticazione delle applicazioni.`,
  [`You currently don’t have any API or product.`]: `Al momento non è presente alcuna API o prodotto.`,
  [`You currently don’t have any API user registries`]: `Al momento non si dispone di alcun registro utente API`,
  [`You currently don’t have any APIs.`]: `Attualmente non si dispone di nessuna API`,
  [`You currently don’t have any TLS client profiles`]: `Al momento non si dispone di alcun profilo client TLS`,
  [`You currently don’t have any base endpoints.`]: `Attualmente non si dispone di alcun endpoint di base.`,
  [`You currently don’t have any catalog properties.`]: `Al momento non hai la proprietà di catalogo.`,
  [`You currently don’t have any categories.`]: `Al momento non hai categorie.`,
  [`You currently don’t have any certificates.`]: `Al momento non hai alcun certificato.`,
  [`You currently don’t have any channels.`]: `Al momento non hai canali.`,
  [`You currently don’t have any consumer organizations.`]: `Attualmente non si dispone di nessuna organizzazione consumer`,
  [`You currently don’t have any consumer organizations or groups.`]: `Attualmente non si dispone di alcun gruppo o organizzazione consumer.`,
  [`You currently don’t have any consumes.`]: `Al momento non hai consumati.`,
  [`You currently don’t have any correlation ids.`]: `Al momento non hai alcun id di correlazione.`,
  [`You currently don’t have any cors policy.`]: `Al momento non hai nessuna politica cors.`,
  [`You currently don’t have any definitions.`]: `Al momento non hai definizioni.`,
  [`You currently don’t have any examples.`]: `Al momento non hai esempi.`,
  [`You currently don’t have any gateway services.`]: `Attualmente non si dispone di nessun servizio del gateway.`,
  [`You currently don’t have any groups.`]: `Attualmente non si dispone di nessun gruppo`,
  [`You currently don’t have any headers.`]: `Al momento non hai le cuffie.`,
  [`You currently don’t have any keystore.`]: `Attualmente non è presente nessun keystore.`,
  [`You currently don’t have any links.`]: `Al momento non hai alcun link.`,
  [`You currently don’t have any messages.`]: `Al momento non hai alcun messaggio.`,
  [`You currently don’t have any message traits.`]: `Al momento non hai nessun tratto di messaggio.`,
  [`You currently don’t have any OAuth providers`]: `Attualmente non si dispone di alcun provider OAuth`,
  [`You currently don’t have any operation traits.`]: `Al momento non hai alcun tratto di operazione.`,
  [`You currently don’t have any parameter definitions.`]: `Al momento non hai definizioni di parametro.`,
  [`You currently don’t have any parameters.`]: `Al momento non si dispone di alcun parametro.`,
  [`You currently don’t have any preserved request header.`]: `Al momento non hai alcuna intestazione di richiesta protetta.`,
  [`You currently don’t have any preserved response header.`]: `Al momento non hai alcuna intestazione di risposta protetta.`,
  [`You currently don’t have any produces.`]: `Al momento non hai alcun prodotto.`,
  [`You currently don’t have any properties.`]: `Attualmente non si dispone di nessuna proprietà.`,
  [`You currently don’t have any provider organizations.`]: `Attualmente non si dispone di nessuna organizzazione provider`,
  [`You currently don’t have any request bodies.`]: `Al momento non hai alcun corpo di richiesta.`,
  [`You currently don’t have any response definitions.`]: `Al momento non hai definizioni di risposta.`,
  [`You currently don’t have any responses.`]: `Al momento non hai risposte.`,
  [`You currently don’t have any scopes.`]: `Attualmente non si dispone di nessun ambito.`,
  [`You currently don’t have any schemas.`]: `Al momento non hai schemi.`,
  [`You currently don’t have any schemes list.`]: `Al momento non hai nessun elenco di schemi.`,
  [`You currently don’t have any security definitions.`]: `Al momento non si dispone di alcuna definizione di sicurezza.`,
  [`You currently don’t have any security schemes.`]: `Al momento non hai schemi di sicurezza.`,
  [`You currently don’t have any servers.`]: `Al momento non hai server.`,
  [`You currently don’t have any services.`]: `Attualmente non si dispone di nessun servizio.`,
  [`You currently don’t have any tags.`]: `Al momento non hai tag.`,
  [`You currently don’t have any tags and external documentation.`]: `Al momento non si dispone di alcun tag e documentazione esterna.`,
  [`You currently don’t have any target services.`]: `Al momento non hai alcun servizio di destinazione.`,
  [`You currently don’t have any tasks.`]: `Attualmente non si dispone di nessuna attività`,
  [`You currently don’t have any truststore.`]: `Attualmente non è presente nessun truststore.`,
  [`You currently don’t have any user registries.`]: `Attualmente non si dispone di alcun registro utente.`,
  [`You currently don’t have any extensions.`]: `Attualmente non si dispone di alcuna estensione.`,
  [`You currently don’t have any HTTP header.`]: `Attualmente non si dispone di alcuna intestazione HTTP.`,
  [`Your account is being activated. This may take a while...`]: `L'account è in fase di attivazione. Questa operazione potrebbe richiedere alcuni minuti...`,
  [`all of`]: `tutti di`,
  [`any of`]: `uno qualsiasi di`,
  [`calls`]: `chiamate`,
  [`ciphers`]: `cifre`,
  [`extensions`]: `estensioni`,
  [`one of`]: `uno di`,
  [`shared`]: `condiviso`,
  [`stopping`]: `arresto in corso`,
  [`to:`]: `per:`,
  [`Key`]: `Chiave`,
  [`kind`]: `tipo`,
  [`Trace`]: `Traccia`,
  [`Http Status`]: `Stato Http`,
  [`Parsed`]: `Analizzato`,
  [`Raw`]: `Non elaborato`,
  [`Showing Full Trace`]: `Visualizzazione della traccia completa`,
  [`Show Entire Trace`]: `Mostra traccia completa`,
  [`Show advanced`]: `Mostra avanzate`,
  [`Advanced`]: `Avanzate`,
  [`Sending Request`]: `Invio richiesta`,
  [`Please send a request above`]: `Inviare una richiesta`,
  [`Please send a request above.`]: `Inviare una richiesta.`,
  [`Authentication Type`]: `Tipo di autenticazione`,
  [`You don't currently have any response`]: `Al momento non è presente alcuna risposta`,
  [`You don't currently have any trace.`]: `Attualmente non si dispone di alcuna traccia.`,
  [`No response received`]: `Nessuna risposta ricevuta`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Facendo clic su "Apri link per accettare l'eccezione" si aprirà il server in una nuova scheda. Se il browser visualizza un problema di certificato, è possibile scegliere di accettarlo e tornare in questo punto per verificare nuovamente.`,
  [`Open link to accept exception`]: `Apri link per accettare l'eccezione`,
  [`Back to {name}`]: `Torna a {name}`,
  [`0 seconds`]: `0 secondi`,
  [`close.menu`]: `Chiudi menu`,
  [`open.menu`]: `Apri menu`,
  [`Referenced from "{ref}"`]: `Riferimento da {ref}"`,
  [`Go to {definition}`]: `Vai a {definition}`,
  [`General`]: `Generale`,
  [`Servers`]: `Server`,
  [`External Docs`]: `Doc esterni`,
  [`Components`]: `Componenti`,
  [`Schemas`]: `Schemi`,
  [`Responses`]: `Risposte`,
  [`Examples`]: `Esempi`,
  [`Request Bodies`]: `Corpi richiesta`,
  [`Security Schemes`]: `Schemi di sicurezza`,
  [`Links`]: `Link`,
  [`Callbacks`]: `Callback`,
  [`Terms of Service`]: `Condizioni del servizio`,
  [`Contact Name`]: `Nome contatto`,
  [`Contact URL`]: `URL contatto`,
  [`Contact Email`]: `E-mail contatto`,
  [`Contact sales`]: `Contatta il settore Vendite`,
  [`License Name`]: `Nome licenza`,
  [`License URL`]: `URL licenza`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `Accetta messaggi ottimizzati MTOM/XOP`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `token`,
  [`Tag`]: `Tag`,
  [`Request Body`]: `Corpo della richiesta`,
  [`Security Requirements`]: `Requisiti di sicurezza`,
  [`Located In`]: `Ubicato in`,
  [`Example Value`]: `Valore di esempio`,
  [`External Value`]: `Valore esterno`,
  [`Server`]: `Server`,
  [`Server Description`]: `Descrizione server`,
  [`Server Variables`]: `Variabili server`,
  [`Format`]: `Formato`,
  [`Pattern`]: `Modello`,
  [`Multiple Of`]: `Multiplo di`,
  [`Multiple non null slicing arguments`]: `Più argomenti di suddivisione non-null.`,
  [`Missing slicing arguments`]: `Argomenti di suddivisione mancanti`,
  [`Missing sized fields`]: `Campi dimensionati mancanti`,
  [`Maximum`]: `Massimo`,
  [`Exclusive Maximum`]: `Massimo esclusivo`,
  [`Minimum`]: `Minimo`,
  [`Exclusive Minimum`]: `Minimo esclusivo`,
  [`Max Length`]: `Lunghezza massima`,
  [`Min Length`]: `Lunghezza minima`,
  [`Max Items`]: `Numero massimo di elementi`,
  [`Min Items`]: `Numero minimo di elementi`,
  [`Unique Items`]: `Elementi univoci`,
  [`Max Properties`]: `Numero massimo di proprietà`,
  [`Min Properties`]: `Numero minimo di proprietà`,
  [`Required Properties`]: `Proprietà richieste`,
  [`Required Property Name`]: `Nome proprietà richiesta`,
  [`Enum`]: `Enum`,
  [`Enum Value`]: `Valore di enumerazione`,
  [`Nullable`]: `Nullable`,
  [`Read Only`]: `In sola lettura`,
  [`Write Only`]: `Sola scrittura`,
  [`api`]: `api`,
  [`version`]: `versione`,
  [`username`]: `nome utente`,
  [`password`]: `password`,
  [`service`]: `servizio`,
  [`Allows Empty Value`]: `Consente valore vuoto`,
  [`Style`]: `Stile`,
  [`Explode`]: `Esplodi`,
  [`Allow Reserved`]: `Consente riservati`,
  [`Allow WebSocket Upgrade`]: `Consenti aggiornamento WebSocket`,
  [`Ref`]: `Rif`,
  [`Operation ID`]: `ID operazione`,
  [`Parameter`]: `Parametro`,
  [`Gateway and portal settings`]: `Impostazioni gateway e portale`,
  [`Targets`]: `Destinazioni`,
  [`Catalog Properties`]: `Proprietà del catalogo`,
  [`catalog properties`]: `Proprietà del catalogo`,
  [`Attachments`]: `Allegati`,
  [`Activity Log`]: `Log delle attività`,
  [`API Type`]: `Tipo API`,
  [`Phase`]: `Fase`,
  [`Enable CORS`]: `Abilita CORS`,
  [`CORS Policy`]: `Politica CORS`,
  [`Allowed Origins`]: `Origini consentite`,
  [`Allow Credentials`]: `Consenti credenziali`,
  [`ID`]: `ID`,
  [`Success Content`]: `Contenuto esito positivo`,
  [`Error Content`]: `Contenuto errore`,
  [`Attachment`]: `Allegato`,
  [`Link`]: `Link`,
  [`Operation Ref`]: `Rif operazione`,
  [`TRACE`]: `TRACCIA`,
  [`No Security Requirements available`]: `Nessun requisito di sicurezza disponibile`,
  [`Create a security scheme`]: `Crea uno schema di sicurezza`,
  [`Create a Security Requirement`]: `Crea un requisito di sicurezza`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Richiedere uno dei seguenti requisiti di sicurezza. Se non è selezionato, la sicurezza è facoltativa.`,
  [`Require wrappers on fault details specified by type`]: `Richiedi wrapper sui dettagli di errore specificati per tipo`,
  [`There are no "Servers"`]: `Nessun "Server" presente`,
  [`Write`]: `Scrittura`,
  [`Server Variable`]: `Variabile server`,
  [`Security Requirement`]: `Requisito di sicurezza`,
  [`Security Scheme Type`]: `Tipo schema di sicurezza`,
  [`Security Scheme`]: `Schema di sicurezza`,
  [`OAuth Flows`]: `Flussi OAuth`,
  [`Implicit Flow`]: `Flusso implicito`,
  [`Password Flow`]: `Flusso password`,
  [`Client Credentials Flow`]: `Flusso credenziali client`,
  [`Authorization Code Flow`]: `Flusso codice di autorizzazione`,
  [`Refresh URL`]: `Aggiorna URL`,
  [`schema`]: `schema`,
  [`Allow Empty Value`]: `Consenti valore vuoto`,
  [`Encoding`]: `Codifica`,
  [`This field is required`]: `Questo campo è obbligatorio`,
  [`Temporary token`]: `Token temporaneo`,
  [`Access token`]: `Token di accesso`,
  [`ID token`]: `ID token`,
  [`No Security Schemes available`]: `Nessuno schema di sicurezza disponibile`,
  [`Security Scheme (Key)`]: `Schema di sicurezza (chiave)`,
  [`There are no "{label}"`]: `Nessuna "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `È necessario selezionare almeno uno schema per definire un requisito di sicurezza`,
  [`Select one or more Security Schemes`]: `Selezionare uno o più schemi di sicurezza`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `Nessun ambito OAuth disponibile per lo schema di sicurezza "{key}"`,
  [`Create a Scope`]: `Crea un ambito`,
  [`Remove this Security Requirement`]: `Rimuovi questo requisito di sicurezza`,
  [`Edit this Security Requirement`]: `Modifica questo requisito di sicurezza`,
  [`Invalid Security Scheme Reference`]: `Riferimento allo schema di sicurezza non valido`,
  [`The following Security Schemes do not exist!`]: `I seguenti schemi di sicurezza non esistono.`,
  [`Edit source`]: `Modifica origine`,
  [`OR`]: `OR`,
  [`Required Security Schemes`]: `Schemi di sicurezza richiesti`,
  [`Optional Security`]: `Sicurezza facoltativa`,
  [`Optional custom expression (one per line)`]: `Espressione personalizzata facoltativa (una per riga)`,
  [`Flow Type`]: `Tipo di flusso`,
  [`OAuth2 Security Scheme`]: `Schema di sicurezza OAuth2`,
  [`Only source editing is supported.`]: `È supportata solo la modifica dell'origine.`,
  [`Unsupported Field.`]: `Campo non supportato.`,
  [`Select an option`]: `Selezionare un'opzione`,
  [`Select {labelText}`]: `Selezionare {labelText}`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" già utilizzato'`,
  [`Referenced from "{originalRef}"`]: `Riferimento da "{originalRef}"`,
  [`Go to {label}`]: `Vai a {label}`,
  [`Delete "{label}"`]: `Elimina "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `Si è sicuri di voler eliminare "{label}"?`,
  [`No recent items found`]: `Nessun elemento recente trovato`,
  [`Found {numDocs} results ({took} seconds)`]: `Trovati {numDocs} risultati ({took} secondi)`,
  [`No Consumer Organizations found`]: `Nessuna organizzazione consumer trovata`,
  [`No Catalog Products found`]: `Nessun prodotto del catalogo trovato`,
  [`No APIs found`]: `Nessuna API trovata`,
  [`No Catalogs found`]: `Nessun catalogo trovato`,
  [`No Spaces found`]: `Nessuno spazio trovato`,
  [`No results found`]: `Nessun risultato trovato`,
  [`No Catalog APIs found`]: `Nessuna API catalogo trovata`,
  [`No Catalogs found matching query "{query}"`]: `Non è stato trovato alcun catalogo corrispondente alla query "{query}"`,
  [`No APIs found matching query "{query}"`]: `Non è stata trovata alcuna API corrispondente alla query "{query}"`,
  [`No Subscriptions found`]: `Nessuna sottoscrizione trovata`,
  [`No Plans found matching query "{query}"`]: `Non è stato trovato alcun piano corrispondente alla query "{query}"`,
  [`No Subscriptions found matching query "{query}"`]: `Non è stata trovata alcuna sottoscrizione corrispondente alla query "{query}"`,
  [`No Catalog Products found matching query "{query}"`]: `Non è stato trovato alcun prodotto del catalogo corrispondente alla query "{query}"`,
  [`No Catalog APIs found matching query "{query}"`]: `Non è stata trovata alcuna API del catalogo corrispondente alla query "{query}"`,
  [`No Consumer Organizations found matching query "{query}"`]: `Non è stata trovata alcuna organizzazione consumer corrispondente alla query "{query}"`,
  [`No Applications found matching query "{query}"`]: `Non è stata trovata alcuna applicazione corrispondente alla query "{query}"`,
  [`APIs per page`]: `API per pagina`,
  [`Catalogs per page`]: `Cataloghi per pagina`,
  [`items per page`]: `elementi per pagina`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} di {total} API`,
  [`{min}-{max} of {total} API`]: `{min}-{max} di {total} API`,
  [`Products per page`]: `Prodotti per pagina`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Il prodotto deve essere pubblicato nel catalogo per poter configurare una nuova integrazione di fatturazione`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} di {total} prodotti`,
  [`{min}-{max} of {total} Product`]: `{min}-{max} di {total} Prodotto`,
  [`Catalog Products per page`]: `Prodotti del catalogo per pagina`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} di {total} prodotti del catalogo`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{max} di {total} Catalogo prodotto`,
  [`Plans per page`]: `Piani per pagina`,
  [`Policies per page`]: `Politiche per pagina`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} di {total} piani`,
  [`{min}-{max} of {total} Plan`]: `{min}-{max} di {total} Piano`,
  [`Catalog APIs per page`]: `API del catalogo per pagina`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} di {total} API del catalogo`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{max} di {total} API Catalogo`,
  [`Subscriptions per page`]: `Sottoscrizioni per pagina`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} di {total} sottoscrizioni`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{max} di {total} Abbonamento`,
  [`Consumer Organizations per page`]: `Organizzazioni consumer per pagina`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} di {total} organizzazioni consumer`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{max} di {total} Organizzazione consumer`,
  [`Applications per page`]: `Applicazioni per pagina`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} di {total} applicazioni`,
  [`{min}-{max} of {total} Application`]: `{min}-{max} di {total} Applicazione`,
  [`Histories per page`]: `Cronologie per pagina`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} di {total} cronologie`,
  [`{min}-{max} of {total} History`]: `{min}-{max} di {total} Storia`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Non è stato trovato alcun piano a cui è possibile effettuare la sottoscrizione. Un prodotto deve essere nello stato "pubblicato" prima che sia possibile creare una sottoscrizione. Un'applicazione può effettuare la sottoscrizione ad un solo piano in un prodotto.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Visualizzazione delle API "{draftApiTitle}:{draftApiVersion}" pubblicate`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Visualizzazione dell'organizzazione consumer per "{appTitle}"`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Visualizzazione delle sottoscrizioni per "{productTitle}:{productVersion}"`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Visualizzazione dei piani per "{productTitle}:{productVersion}"`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `Visualizzazione delle API per "{productTitle}:{productVersion}"`,
  [`Showing Product for "{planTitle}"`]: `Visualizzazione del prodotto per "{planTitle}"`,
  [`Showing APIs for "{planTitle}"`]: `Visualizzazione delle API per "{planTitle}"`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Visualizzazione delle sottoscrizioni per il piano: "{docTitle}" nel prodotto: "{docFoundIn}"`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Visualizzazione dei piani per "{apiTitle}:{apiVersion}"`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Visualizzazione delle API bozza per "{catalogApiTitle}:{catalogApiVersion}"`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Visualizzazione delle cronologie per "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Visualizzazione delle sottoscrizioni per "{appTitle}"`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Visualizzazione delle applicazioni per "{consumerOrgTitle}"`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Visualizzazione delle sottoscrizioni per "{consumerOrgTitle}"`,
  [`Showing Application for selected Subscription`]: `Visualizzazione dell'applicazione per la sottoscrizione selezionata`,
  [`Showing Plan for selected Subscription`]: `Visualizzazione del piano per la sottoscrizione selezionata`,
  [`Showing Product for selected Subscription`]: `Visualizzazione del prodotto per la sottoscrizione selezionata`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Selezionare le API bozza da includere nel prodotto "{productTitle}"`,
  [`Product Version`]: `Versione prodotto`,
  [`OAuth Providers`]: `Provider OAuth`,
  [`Application Type`]: `Tipo di applicazione`,
  [`Client IDs`]: `ID client`,
  [`Save as New Version`]: `Salva come nuova versione`,
  [`Update Selected APIs`]: `Aggiorna API selezionate`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. L'API {titleAndVersion} verrà eliminata in modo permanente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. Il prodotto {titleAndVersion} verrà eliminato in modo permanente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. Il prodotto del catalogo {titleAndVersion} verrà eliminato in modo permanente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. L'organizzazione consumer {titleAndVersion} verrà eliminata in modo permanente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. Il gruppo consumer {titleAndVersion} verrà eliminato definitivamente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. La politica {titleAndVersion} verrà eliminata in modo permanente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. La sottoscrizione {titleAndVersion} verrà eliminata in modo permanente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. Il catalogo {titleAndVersion} verrà eliminato definitivamente. Si desidera continuare?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. Lo spazio {titleAndVersion} verrà eliminato definitivamente. Si desidera continuare?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `Il prodotto del catalogo {titleAndVersion} verrà ripubblicato. Si desidera continuare?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `Il prodotto del catalogo {titleAndVersion} verrà ritirato. Si desidera continuare?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `Il prodotto del catalogo {titleAndVersion} verrà reso obsoleto. Si desidera continuare?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `Il prodotto del catalogo {titleAndVersion} verrà sottoposto nuovamente a staging. Si desidera continuare?`,
  [`Put Online`]: `Connetti`,
  [`Take Offline`]: `Disconnetti`,
  [`View Subscriptions`]: `Visualizza sottoscrizioni`,
  [`View Plans`]: `Visualizza piani`,
  [`No Tasks found`]: `Nessuna attività trovata`,
  [`Tasks per page`]: `Attività per pagina`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} di {total} attività`,
  [`{min}-{max} of {total} Task`]: `{min}-{max} di {total} Attività`,
  [`Decline and Send`]: `Rifiuta ed Invia`,
  [`View Applications`]: `Visualizza Applicazioni`,
  [`Publish (Preserve Subscriptions)`]: `Pubblica (Conserva Sottoscrizioni)`,
  [`Update APIs`]: `Aggiorna API`,
  [`View in Catalogs`]: `Visualizza nei cataloghi`,
  [`View Consumer Organization`]: `Visualizza organizzazione consumer`,
  [`View Application`]: `Visualizza applicazione`,
  [`View Plan`]: `Visualizza piano`,
  [`View Product`]: `Visualizza prodotto`,
  [`Create Subscription`]: `Crea sottoscrizione`,
  [`No Applications found`]: `Nessuna applicazione trovata`,
  [`Select a Plan to Create a Subscription to`]: `Selezionare un piano per cui creare una sottoscrizione`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Questa azione non può essere annullata. L'applicazione {titleAndVersion} verrà eliminata in modo permanente. Si desidera continuare?`,
  [`Save as new version`]: `Salva come nuova versione`,
  [`Filter selected catalogs`]: `Filtra cataloghi selezionati`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Selezionare le API bozza da includere nel prodotto "{productTitle}"`,
  [`Update selected APIs`]: `Aggiorna API selezionate`,
  [`Catalog API`]: `API del catalogo`,
  [`Catalog APIs`]: `API catalogo`,
  [`View Products`]: `Visualizza prodotti`,
  [`View Draft APIs`]: `Visualizza API bozza`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Visualizzazione di prodotti che fanno riferimento a "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `Nessun piano trovato`,
  [`View APIs`]: `Visualizza API`,
  [`Document type tabs`]: `Schede tipo di documento`,
  [`No Products found`]: `Nessun prodotto trovato`,
  [`Consumer Organization`]: `Organizzazione consumer`,
  [`Consumer Organizations`]: `Organizzazioni consumer`,
  [`Catalog Product`]: `Prodotto del catalogo`,
  [`Catalog Products`]: `Prodotti catalogo`,
  [`Search for a Product and Plan`]: `Cerca un prodotto e un piano`,
  [`OpenAPI Version`]: `Versione OpenAPI`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (da REST, GraphQL o SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (da argomento Kafka)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `Crea AsyncAPI (da argomento Kafka)`,
  [`An error occurred during file upload`]: `Si è verificato un errore durante il caricamento del file`,
  [`Delete uploaded file`]: `Elimina file caricato`,
  [`Copied!`]: `Copiato.`,
  [`Uploading`]: `Caricamento`,
  [`Validation icon`]: `Icona di convalida`,
  [`Validation error`]: `Errore di convalida`,
  [`Uploading image..`]: `Caricamento immagine...`,
  [`Unsupported media type {providedFileType}`]: `Tipo di supporto non supportato {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Selezionare un file supportato {expectedFileTypes} e riprovare`,
  [`Pasted`]: `Incollato`,
  [`Enter the API summary details.`]: `Immettere i dettagli di riepilogo dell'API.`,
  [`Define your Kafka cluster connection details.`]: `Definire i dettagli della connessione del cluster Kafka.`,
  [`Channel`]: `Canale`,
  [`Describe your Kafka topic and its associated schema.`]: `Descrivere l'argomento Kafka e il relativo schema associato.`,
  [`AsyncAPI specification version`]: `Versione specifica AsyncAPI`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Un nome che descrive l'API e consente agli sviluppatori di individuarla in Developer Portal.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Una versione per la definizione API. La combinazione di nome e versione identifica la definizione API in modo univoco.`,
  [`Description (optional)`]: `Descrizione (facoltativa)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Introdurre la propria API e fornire informazioni utili per gli sviluppatori che la utilizzano.`,
  [`Summary (optional)`]: `Riepilogo (facoltativo)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Un breve riepilogo degli eventi contenuti in questa API. Massimo 1000 caratteri.`,
  [`Bootstrap servers`]: `Server bootstrap`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Un elenco dei broker Kafka nel cluster, separati da virgole.`,
  [`Secure the API using an API Key and Secret`]: `Proteggi l'API utilizzando una chiave API ed un segreto`,
  [`The credential provided should be in SASL-PLAIN format`]: `La credenziale fornita deve essere in formato SASL-PLAIN`,
  [`Publish this API:`]: `Pubblica questa API:`,
  [`Creates a product`]: `Crea un prodotto`,
  [`Associates this API to the product`]: `Associa questa API al prodotto`,
  [`Publish the product to the Sandbox catalog`]: `Pubblica il prodotto nel catalogo Sandbox`,
  [`Associates the product to the test app`]: `Associa il prodotto all'app di verifica`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Uno schema in formato "{fileType}" è stato correttamente caricato e convalidato`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `Il file caricato non è un schema Avro valido e non verrà incluso come parte dell'API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Risposta del server: "{err}". Fare clic su "Indietro" per tornare alla pagina della definizione API.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Risposta del server: "{err}". Fare clic su "Fine" per uscire dalla procedura guidata.`,
  [`Topic name`]: `Nome argomento`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `Il nome dell'argomento che si desidera esporre come parte di questa API. Un argomento è un flusso di messaggi denominato.`,
  [`Schema for message body in .avsc format (optional)`]: `Schema per il corpo del messaggio in formato .avsc (facoltativo)`,
  [`Drag and drop files here, or click to upload`]: `Trascinare e rilasciare i file qui oppure fare clic per caricarli`,
  [`About this step`]: `Informazioni su questo passo`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Gli eventi possono essere descritti come API asincrone perché i consumer ricevono gli eventi man mano che diventano disponibili. Le API sincrone, come REST, ricevono le risposte solo quando ne fanno richiesta.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Le API asincrone gestite da eventi sono documentate utilizzando la specifica AsyncAPI.`,
  [`API secret`]: `Segreto API`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Definizione AsyncAPI 2.1.0 generata`,
  [`API creating`]: `Creazione API`,
  [`An error occurred while creating your document`]: `Si è verificato un errore durante la creazione del documento`,
  [`Publishing your API`]: `Pubblicazione dell'API`,
  [`Your API has been published`]: `L'API è stata pubblicata`,
  [`An error occurred while publishing your API`]: `Si è verificato un errore durante la pubblicazione dell'API`,
  [`Configure security for this API`]: `Configura sicurezza per questa API`,
  [`Cluster connection security`]: `Sicurezza connessione cluster`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Fornire le credenziali che i servizi gateway eventi devono utilizzare per connettersi al cluster durante la gestione delle richieste a questa API. È possibile modificare questi valori successivamente nell'editor. Per ulteriori informazioni, consultare la documentazione sulla creazione delle credenziali.`,
  [`SASL Username`]: `Nome utente SASL`,
  [`SASL Password`]: `Password SASL`,
  [`Transport CA certificate (.pem file - optional)`]: `Certificato CA di trasporto (file .pem - facoltativo)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Se fornito, verrà utilizzato dal servizio gateway eventi per fornire la crittografia TLS per le comunicazioni tra il gateway ed il cluster Kafka.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Descrivere i dettagli API dell'origine eventi Apache Kafka per generare un documento AsyncAPI.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `È quindi possibile pubblicare il documento AsyncAPI per esporre l'origine eventi agli sviluppatori delle applicazioni che possono configurare le proprie applicazioni per la sottoscrizione al relativo flusso di eventi.`,
  [`Set up security for your API if you want to control who can access it.`]: `Impostare la sicurezza per l'API se si desidera controllare chi può effettuare l'accesso.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `L'accesso all'API sarà gestito da un servizio gateway eventi che verifica che la chiave API ed il segreto siano validi.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `I gateway richiedono ai client di crittografare le proprie comunicazioni con TLS.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Una volta creata l'API, fare clic su ‘Modifica API’ per aggiungere ulteriori dettagli, come i dettagli relativi alla sicurezza, per la connessione al cluster Kafka dal servizio gateway eventi.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `È possibile pubblicare la propria API, rendendola disponibile per gli sviluppatori che possono quindi rilevare e richiedere l'accesso all'API ed utilizzare le informazioni per impostare le proprie applicazioni in modo da utilizzare i dati dall'origine eventi.`,
  [`Pending backend response`]: `Risposta di backend in sospeso`,
  [`Complete`]: `Completato`,
  [`Rate limiting is only applied to enforced APIs`]: `La limitazione della frequenza è applicata solo alle API applicate`,
  [`No Plans Available`]: `Nessun piano disponibile`,
  [`Modal content is not provided`]: `Contenuto modale non fornito`,
  [`Modal heading is not provided`]: `Intestazione modale non fornita`,
  [`Edit Rate Limit`]: `Modifica limite di frequenza`,
  [`External Documentation`]: `Documentazione esterna`,
  [`No Label Available`]: `Nessuna etichetta disponibile`,
  [`optional`]: `facoltativo`,
  [`Uploading image...`]: `Caricamento immagine...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Allegare i file trascinandoli, rilasciandoli, selezionandoli o incollandoli.`,
  [`Response name`]: `Nome risposta`,
  [`Click Add to add a new {resource}.`]: `Fare clic per aggiungere una nuova risorsa {resource}.`,
  [`Schema Name (Key)`]: `Nome schema (chiave)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Spazio dei nomi`,
  [`Attribute`]: `Attributo`,
  [`Wrapped`]: `Wrapping`,
  [`Example`]: `Esempio`,
  [`Example Name (Key)`]: `Nome esempio (chiave)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" già utilizzato`,
  [`Name (Key)`]: `Nome (chiave)`,
  [`Content Type (Key)`]: `Tipo di contenuto (chiave)`,
  [`Header name`]: `Nome intestazione`,
  [`Reference`]: `Riferimento`,
  [`Security Scheme Name (Key)`]: `Nome schema di sicurezza (chiave)`,
  [`Choose a Reference`]: `Selezionare un riferimento`,
  [`Reference from`]: `Riferimento da`,
  [`Content Type`]: `Tipo di contenuto`,
  [`array`]: `array`,
  [`boolean`]: `booleano`,
  [`integer`]: `intero`,
  [`number`]: `numero`,
  [`object`]: `oggetto`,
  [`string`]: `stringa`,
  [`query`]: `query`,
  [`cookie`]: `cookie`,
  [`matrix`]: `matrice`,
  [`label`]: `etichetta`,
  [`simple`]: `semplice`,
  [`form`]: `modulo`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `Provider OAuth (Nome)`,
  [`Authenticate using User Registry (name)`]: `Esegui autenticazione utilizzando il registro utente (nome)`,
  [`Bearer Format`]: `Formato Bearer`,
  [`Server Variable Name`]: `Nome variabile server`,
  [`Verb (Key)`]: `Verbo (Chiave)`,
  [`Parameter Definitions`]: `Definizioni dei parametri`,
  [`Path Parameters`]: `Parametri percorso`,
  [`Media Type List`]: `Elenco tipo di supporto`,
  [`Medium`]: `Medio`,
  [`Schemes List`]: `Elenco schemi`,
  [`Small`]: `Piccolo`,
  [`Response Definitions`]: `Definizioni di risposta`,
  [`Terms Of Service`]: `Condizioni del Servizio`,
  [`Url`]: `Url`,
  [`Untitled Parameter`]: `Parametro senza titolo`,
  [`Parameter Name`]: `Nome parametro`,
  [`Mime Type`]: `Tipo Mime`,
  [`Async API`]: `API Async`,
  [`Channels`]: `Canali`,
  [`Operation Traits`]: `Caratteristiche operazione`,
  [`Messages`]: `Messaggi`,
  [`Correlation IDs`]: `ID di correlazione`,
  [`Traits`]: `Caratteristiche`,
  [`Message Traits`]: `Caratteristiche messaggio`,
  [`Bindings`]: `Bind`,
  [`Server Bindings`]: `Bind del server`,
  [`Channel Bindings`]: `Bind del canale`,
  [`Operation Bindings`]: `Bind operazione`,
  [`Message Bindings`]: `Bind messaggio`,
  [`Protocol Version`]: `Versione protocollo`,
  [`Define multiple messages`]: `Definisci messaggi multipli`,
  [`Schema Format`]: `Formato schema`,
  [`Unsupported Field`]: `Campo non supportato`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Caratteristica operazione`,
  [`Message Trait`]: `Caratteristica messaggio`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Utilizzare la politica Switch operazioni per applicare una sezione dell'assemblaggio ad un'operazione specifica.`,
  [`HTML page`]: `Pagina HTML`,
  [`Rule`]: `Regola`,
  [`Origin`]: `Origine`,
  [`Property Name (Key)`]: `Nome proprietà (chiave)`,
  [`Catalog Name (Key)`]: `Nome catalogo (chiave)`,
  [`Property Overrides`]: `Sostituzioni proprietà`,
  [`Property Name`]: `Nome proprietà`,
  [`Property Value`]: `Valore proprietà`,
  [`About subscriptions and plans`]: `Informazioni su sottoscrizioni e piani`,
  [`Subscription Plan Name`]: `Nome piano sottoscrizione`,
  [`Select plan`]: `Seleziona piano`,
  [`Select the plan to migrate application subscriptions from`]: `Selezionare il piano da cui migrare le sottoscrizioni dell'applicazione`,
  [`Select subscriptions`]: `Seleziona sottoscrizioni`,
  [`Select the application subscriptions to migrate`]: `Selezionare le sottoscrizioni dell'applicazione da migrare`,
  [`Select the product to migrate application subscriptions to`]: `Selezionare il prodotto in cui migrare le sottoscrizioni dell'applicazione`,
  [`Select the plan to migrate application subscriptions to`]: `Selezionare il piano in cui migrare le sottoscrizioni dell'applicazione`,
  [`Message successfully sent to {sent} owner(s)`]: `Messaggio correttamente inviato al(i) proprietario(i) {sent}`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} messaggi non inviati`,
  [`Failed email addresses`]: `Indirizzi e-mail non riusciti`,
  [`Failed to save the API`]: `Impossibile salvare l'API`,
  [`and {numExtra} more...`]: `e altri {numExtra} ...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Copia negli appunti l'elenco degli indirizzi e-mail per cui l'invio non è riuscito`,
  [`From`]: `Da`,
  [`Message Owner`]: `Proprietario del messaggio`,
  [`Message owner`]: `Proprietario del messaggio`,
  [`Message owners`]: `Proprietari del messaggio`,
  [`Message group`]: `Gruppo di messaggi`,
  [`Select consumer organizations`]: `Seleziona organizzazioni consumer`,
  [`Message consumer organization owners`]: `Proprietari organizzazione consumer messaggio`,
  [`Message consumer organization owner`]: `Proprietario dell'organizzazione consumer di messaggi`,
  [`Message provider organization owner`]: `Proprietario dell'organizzazione del provider di messaggi`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `L'identificativo del cluster Kafka relativo a questa politica di richiamo`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Un elenco di coppie host:port separata da virgole da utilizzare per stabilire connessioni al cluster Kafka`,
  [`The username to use when connecting to the Kafka cluster.`]: `Il nome utente da utilizzare durante la connessione al cluster Kafka.`,
  [`The password to use when connecting to the Kafka cluster.`]: `La password da utilizzare durante la connessione al cluster Kafka.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Servizio gateway eventi`,
  [`Event gateway management client keystore`]: `Keystore client di gestione del gateway di eventi`,
  [`Edit Event gateway Service`]: `Modifica servizio gateway eventi`,
  [`Configure Event Gateway Service`]: `Configura servizio gateway eventi`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Configura un servizio gateway eventi per proteggere ed applicare le API asincrone`,
  [`Catalogs with published product`]: `Cataloghi con prodotto pubblicato`,
  [`Cluster Config ID`]: `ID di configurazione cluster`,
  [`SASL mechanism`]: `Meccanismo SASL`,
  [`Security protocol`]: `Protocollo di sicurezza`,
  [`Transport CA certificate`]: `Certificato CA di trasporto`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `La dimensione massima del file è {size}. I tipi di file supportati sono json, xml, yml, yaml & zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `I tipi di file supportati sono json, xml, yml & yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `I tipi di file supportati sono json, wsdl, xml, xsd, yml, yaml & zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `I tipi di file supportati sono json, wsdl, xml, yml & yaml.`,
  [`Select a file`]: `Selezionare un file`,
  [`or specify a file URL`]: `o specificare un URL di file`,
  [`Generated OpenAPI {version} definition`]: `Definizione OpenAPI {version} generata`,
  [`User registry type`]: `Tipo di registro utenti`,
  [`User registry types`]: `Tipi di registro utente`,
  [`User registry type {title} has been created.`]: `Il tipo di registro utente {title} è stato creato.`,
  [`User registry type {title} has been updated.`]: `Il tipo di registro utente {title} è stato aggiornato.`,
  [`Failed to create user registry type {title}.`]: `Impossibile creare il tipo di registro utente {title}.`,
  [`Failed to update user registry type {title}.`]: `Impossibile aggiornare il tipo di registro utente {title}.`,
  [`Failed to update user registry {title}.`]: `Aggiornamento del registro utente {title} non riuscito.`,
  [`Custom user registry`]: `Registro utente personalizzato`,
  [`Create custom user registry`]: `Crea registro utente personalizzato`,
  [`Edit custom user registry`]: `Modifica registro utente personalizzato`,
  [`Create user registry type`]: `Crea tipo di registro utente`,
  [`Edit user registry type`]: `Modifica tipo di registro utente`,
  [`Configure user authentication using a custom external user registry`]: `Configura l'autenticazione utente utilizzando un registro utente esterno personalizzato`,
  [`Secured Endpoint`]: `Endpoint protetto`,
  [`Configuration schema`]: `Schema di configurazione`,
  [`About custom user registries`]: `Informazioni sui registri utente personalizzati`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `I registri utente personalizzati possono essere utilizzati per l'autenticazione degli utenti su Developer Portal, ma non possono essere utilizzati per proteggere le API. Per configurare un registro utente personalizzato come risorsa in API Manager, la directory utente esterna deve essere creata e disponibile per l'utilizzo con l'ecosistema API Connect.`,
  [`Update Wsdl`]: `Aggiorna Wsdl`,
  [`Update Existing WSDL service`]: `Aggiorna servizio WSDL esistente`,
  [`Format of selected WSDL has been successfully validated`]: `Il formato del WSDL selezionato è stato correttamente convalidato`,
  [`Select the target WSDL file to update from`]: `Selezionare il file WSDL da cui eseguire l'aggiornamento`,
  [`Selected WSDL has been successfully uploaded`]: `Il file WSDL selezionato è stato caricato correttamente`,
  [`Services available in selected API`]: `Servizi disponibili nell'API selezionata`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Abilita editor Open API legacy per documenti OpenAPI 2.0 (obsoleto)`,
  [`Unknown`]: `Sconosciuto`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleCatalogCount} cataloghi nascosti a causa di incompatibilità con il tipo di gateway specificato nell'API o nel prodotto`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} spazi nascosti a causa di incompatibilità con il tipo di gateway specificato nell'API o nel prodotto`,
  [`Keep in mind`]: `Nota`,
  [`Only one type of enforced APIs is allowed per product`]: `È consentito un solo tipo di API applicata per prodotto`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `{incompatibleAPIsCount} API non possono essere selezionate a causa di incompatibilità con il tipo di gateway specificato nel prodotto`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `Non è possibile selezionare {incompatibleProductsCount} prodotto(i) a causa di incompatibilità con il tipo di gateway specificato nell'API`,
  [`Unenforced`]: `Non applicato`,
  [`Create a new product by adding APIs and plans.`]: `Creare un nuovo prodotto aggiungendo API e piani.`,
  [`Rate limits are only applied to enforced APIs.`]: `I limiti di frequenza vengono applicati solo alle API applicate.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Aggiungere limiti di API e piani al prodotto. I limiti del piano si applicano solo alle API applicate e le API non applicate saranno illimitate.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Aggiungere limiti di piani e frequenza al prodotto. I piani si applicano solo alle API applicate e le API non applicate saranno illimitate.`,
  [`Delete plan`]: `Elimina piano`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Selezionare il servizio gateway eventi per AsyncAPIs. Per tutti gli altri tipi di API, selezionare DataPower API Gateway o la versione compatibile v5. Per aggiornare questa impostazione, rimuovere prima le API associate.`,
  [`Edit API list`]: `Modifica elenco API`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Le API applicate in questo prodotto richiedono più di un tipo di gateway.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `{apiType} applicato in questo prodotto richiede {gatewayType}.`,
  [`OpenAPI 2.0 API(s)`]: `API OpenAPI 2.0`,
  [`OpenAPI 3.0 API(s)`]: `API OpenAPI 3.0`,
  [`AsyncAPI(s)`]: `AsyncAPI`,
  [`Select the gateway type for this Product`]: `Seleziona il tipo di gateway per questo prodotto`,
  [`Added default plan`]: `Piano predefinito aggiunto`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Pubblicare il prodotto nel catalogo Sandbox. Questa operazione associa il prodotto all'app di verifica.`,
  [`cluster`]: `cluster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Messaggio dall'utente che ha rifiutato`,
  [`Vendor extensions feature disabled`]: `Funzione Estensioni fornitore disabilitata`,
  [`No configured gateways found`]: `Nessune gateway configurato trovato`,
  [`No OpenAPI extensions associated with the configured gateway`]: `Nessuna estensione OpenAPI associata al gateway configurato`,
  [`Extension`]: `Estensione`,
  [`Select the extensions to add to the API document`]: `Selezionare le estensioni da aggiungere al documento API`,
  [`(Operations: {operations})`]: `(Operazioni: {operations})`,
  [`Failed to get application`]: `Impossibile ottenere l'applicazione`,
  [`Failed to get credentials for application`]: `Impossibile ottenere le credenziali per l'applicazione`,
  [`Failed to get published product`]: `Impossibile ottenere il prodottu pubblicato`,
  [`Failed to get the published API`]: `Impossibile ottenere l'API pubblicata`,
  [`API must be activated to use the Test tool`]: `È necessario attivare l'API per utilizzare lo strumento di test`,
  [`Import from Asset repository`]: `Importa dal repository asset`,
  [`Untitled {componentLabel}`]: `{componentLabel} senza titolo`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `La metrica della licenza richiede un Servizio analisi per tenere traccia dell'utilizzo. Fare clic su "Registra servizio" per configurare un Servizio analisi.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Tutti i servizi di DataPower Gateway richiedono un servizio di analitiche per tracciare l'utilizzo. Fare clic su "Servizio di analitiche associato" per ogni gateway per rispettare i termini della licenza.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Il catalogo è incompatibile con il tipo di gateway specificato nel prodotto o API.`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `La spazio è incompatibile con il tipo di gateway specificato nel prodotto o API.`,
  [`Preserve Subscription`]: `Conserva sottoscrizione`,
  [`Publish validation`]: `Convalida di pubblicazione`,
  [`Publish validations`]: `Convalide di pubblicazione`,
  [`Edit publish validations`]: `Modifica convalide di pubblicazione`,
  [`The following validations will be performed when publishing a product`]: `Le seguenti convalide verranno eseguite durante la pubblicazione di un prodotto`,
  [`There are no publish validations enabled`]: `Non ci sono convalide di pubblicazione abilitate`,
  [`Publish validations have been updated`]: `Le convalide di pubblicazione sono state aggiornate`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Fornire l'autorità di certificazione (CA) che il servizio gateway eventi utilizza per accreditare il certificato server Kafka di backend. Se il server utilizza un certificato rilasciato da un'autorità nota, non è necessario fornire la CA.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `Il valore immesso per i server bootstrap non è valido. Inserire l'host e la porta di ciascun broker Kafka sul proprio cluster, separati da virgole. Ad esempio, broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `Carica estensione OpenAPI`,
  [`Add OpenAPI Extension`]: `Aggiungi estensione OpenAPI`,
  [`Gateway Endpoint`]: `Endpoint gateway`,
  [`API Dashboard`]: `Dashboard API`,
  [`API call data`]: `Dati di chiamata API`,
  [`Product Dashboard`]: `Dashboard del prodotto`,
  [`API Product consumption metrics`]: `Metriche di consumo del prodotto API`,
  [`Monitoring Latency Dashboard`]: `Dashboard di monitoraggio latenza`,
  [`Data about the time taken to serve API calls`]: `I dati relativi al tempo impiegato per rispondere alle chiamate API`,
  [`Monitoring Status Dashboard`]: `Dashboard di monitoraggio stato`,
  [`API Error and success information`]: `Informazioni su errori API e operazioni riuscite`,
  [`Usage Dashboard`]: `Dashboard di utilizzo`,
  [`Usage for gateway {gateway}`]: `Utilizzo del gateway {gateway}`,
  [`Latency for gateway {gateway}`]: `Latenza del gateway {gateway}`,
  [`Most popular products, APIs and applications`]: `Applicazioni, API e prodotti più diffusi`,
  [`Consumption Dashboard`]: `Dashboard consumo`,
  [`Total API volume useful for auditing and compliance`]: `Volume API totale utile per verifica e conformità`,
  [`Monthly Summary by Status Code`]: `Riepilogo mensile per codice stato`,
  [`Monthly Summary Data by Status Code`]: `Dati di riepilogo mensili per codice stato`,
  [`Daily Summary by Status Code`]: `Riepilogo giornaliero per codice stato`,
  [`Daily Summary Data by Status Code`]: `Dati di riepilogo giornalieri per codice stato`,
  [`Response time (ms)`]: `Tempo di risposta (ms)`,
  [`Datetime`]: `Data/ora`,
  [`Transaction ID:`]: `ID transazione:`,
  [`Other`]: `Altro`,
  [`Date time`]: `Data/ora`,
  [`Total calls`]: `Numero totale di chiamate`,
  [`Date`]: `Data`,
  [`Month`]: `Mese`,
  [`Minimum response time`]: `Tempo minimo di risposta`,
  [`Maximum response time`]: `Tempo massimo di risposta`,
  [`Average response time`]: `Tempo medio di risposta`,
  [`Total API calls`]: `Chiamate API totali`,
  [`Total APIs`]: `API totali`,
  [`APIs called`]: `API richiamate`,
  [`Total products`]: `Prodotti totali`,
  [`products called`]: `prodotti richiamati`,
  [`Total errors`]: `Errori totali`,
  [`Status codes (detailed)`]: `Codici di stato (dettagliato)`,
  [`Response time percentiles`]: `Percentili di tempo di risposta`,
  [`API calls per day`]: `Chiamate API al giorno`,
  [`Time zone:`]: `Fuso orario:`,
  [`Time zone`]: `Fuso orario`,
  [`Toggle axes`]: `Attiva/disattiva assi`,
  [`Datetime:`]: `Data/ora:`,
  [`datetime per 30 seconds`]: `data/ora per 30 secondi`,
  [`Datetime (local time)`]: `Data/ora (ora locale)`,
  [`Data usage (bytes)`]: `Utilizzo di dati (byte)`,
  [`Time to Serve Request`]: `Tempo di elaborazione della richiesta`,
  [`API name`]: `Nome API`,
  [`Product name`]: `Nome prodotto`,
  [`Data usage (bytes received)`]: `Utilizzo di dati (byte ricevuti)`,
  [`Data usage (bytes sent)`]: `Utilizzo di dati (byte inviati)`,
  [`Response times (>1s)`]: `Tempi di risposta (>1s)`,
  [`milliseconds`]: `millisecondi`,
  [`errors`]: `errori`,
  [`Success Rate`]: `Frequenza operazioni riuscite`,
  [`Errors Data`]: `Dati degli errori`,
  [`Success Data`]: `Dati delle operazioni riuscite`,
  [`Total consumer organizations`]: `Organizzazioni consumer totali`,
  [`making API calls`]: `Chiamate API effettuate`,
  [`Applications per plan`]: `Applicazioni per piano`,
  [`Top products by calls`]: `Prodotti principali in base alle chiamate`,
  [`Top APIs by calls`]: `API principali in base alle chiamate`,
  [`Top client IP addresses by calls`]: `Principali indirizzi IP client per chiamate`,
  [`Client IP address`]: `Indirizzo IP client`,
  [`Top APIs by request size`]: `API principali in base alla dimensione della richiesta`,
  [`Top APIs by response size`]: `API principali in base alla dimensione della risposta`,
  [`Top APIs by response time`]: `API principali in base al tempo di risposta`,
  [`Top applications by calls`]: `Applicazioni principali in base alle chiamate`,
  [`Dashboards`]: `Dashboard`,
  [`Discover`]: `Rileva`,
  [`UTC`]: `UTC`,
  [`Local time`]: `Ora locale`,
  [`URI`]: `URI`,
  [`Response code`]: `Codice di risposta`,
  [`Total time elapsed (ms)`]: `Tempo totale trascorso (ms)`,
  [`Timestamp`]: `Data/ora`,
  [`Method`]: `Metodo`,
  [`Gateway IP`]: `IP gateway`,
  [`Gateway Operations Dashboard`]: `Dashboard delle operazioni del gateway`,
  [`Usage and latency information per gateway`]: `Informazioni su utilizzo e latenza per gateway`,
  [`Transaction ID`]: `ID transazione`,
  [`Filters`]: `Filtri`,
  [`Fields`]: `Campi`,
  [`Clear all`]: `Elimina tutto`,
  [`API Event`]: `Evento API`,
  [`API Assembly Policy Latencies`]: `Latenze politiche di assembly API`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `Il diagramma sottostante mostra il tempo di latenza crescente in millisecondi, con l'avanzamento della richiesta attraverso le politiche di assembly API. Questo può essere utile per determinare i punti di rallentamento o i colli di bottiglia nell'assembly API.`,
  [`Collapse`]: `Comprimi`,
  [`Expand`]: `Espandi`,
  [`enter name`]: `immettere il nome`,
  [`Enter value`]: `Immettere un valore`,
  [`Time range`]: `Intervallo di tempo`,
  [`Select one of the following time range filters`]: `Selezionare uno dei seguenti filtri di intervallo di tempo`,
  [`Last 1 hour`]: `Ultima ora`,
  [`Last {number} minutes`]: `Ultimi {number} minuti`,
  [`Last {number} hours`]: `Ultime {number} ore`,
  [`Last {number} days`]: `Ultimi {number} giorni`,
  [`Start date`]: `Data di inizio`,
  [`End date`]: `Data di fine`,
  [`Select from the following dropdowns and enter a value`]: `Effettuare una selezione dai seguenti elenchi a discesa e immettere un valore`,
  [`Select one of the following type filters`]: `Selezionare uno dei seguenti filtri di tipo`,
  [`All events`]: `Tutti gli eventi`,
  [`Success only (2xx, 3xx)`]: `Solo esito positivo (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Solo errori (4xx, 5xx)`,
  [`Import/Export`]: `Importa/esporta`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Circa {totalItems} risultati ({searchTime} secondi) alle {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Circa {totalItems} risultati ({searchTime} secondi) alle {time} ({utcTime})`,
  [`API calls`]: `Chiamate API`,
  [`Export as CSV`]: `Esporta come CSV`,
  [`Export as JSON`]: `Esporta come JSON`,
  [`Export as PNG`]: `Esporta come PNG`,
  [`Export as JPG`]: `Esporta come JPG`,
  [`Edit filter`]: `Modifica filtro`,
  [`Edit query`]: `Modifica query`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Questa è una query condivisa. Qualsiasi modifica verrà apportata per tutti gli utenti con cui è condivisa questa query.`,
  [`This is a shared query. This will delete it for all users.`]: `Questa è una query condivisa. Questa operazione la eliminerà per tutti gli utenti.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname} è stata aggiornata per tutti gli utenti nell'organizzazione provider {org}`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname} è stata aggiornata per tutti gli utenti nello spazio {space}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname} è stata aggiornata per tutti gli utenti nel catalogo {catalog}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname} è stata aggiornata per tutti gli utenti nel cloud manager`,
  [`Stop sharing`]: `Interrompi condivisione`,
  [`Share query`]: `Condividi query`,
  [`Share filter`]: `Condividi filtro`,
  [`You do not have permissions to delete this filter`]: `Non si dispone delle autorizzazioni per eliminare questo filtro`,
  [`You do not have permissions to delete this query`]: `Non si dispone delle autorizzazioni per eliminare questa query`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Si è sicuri di voler condividere la query {queryTitle} nell'ambito dell'organizzazione del provider {org}?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Si è sicuri di voler condividere la query {queryTitle} nell'ambito dello spazio {space}?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Si è sicuri di voler condividere la query {queryTitle} nell'ambito del catalogo {catalog}?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Si è sicuri di voler condividere la query {queryTitle} nell'ambito del cloud?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Si è sicuri di voler interrompere la condivisione della query {queryTitle}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Si è sicuri di voler interrompere la condivisione della query {queryTitle} nell'ambito dell'organizzazione del provider {org}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Si è sicuri di voler interrompere la condivisione della query {queryTitle} nell'ambito dello spazio {space}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Si è sicuri di voler interrompere la condivisione della query {queryTitle} nell'ambito del catalogo {catalog}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Si è sicuri di voler interrompere la condivisione della query {queryTitle} nell'ambito del cloud?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Si è sicuri di voler eliminare la query {queryTitle}?`,
  [`At least one query parameter expected`]: `È previsto almeno un parametro di query`,
  [`A provided query parameter is incomplete`]: `Un parametro di query fornito è incompleto`,
  [`Could not parse query string`]: `Impossibile analizzare la stringa di query`,
  [`An unsupported query parameter was provided: {query}`]: `È stato fornito un parametro di query non supportato: {query}`,
  [`Invalid timeframe value: {value}`]: `Valore di intervallo di tempo non valido: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `Il valore di {field} deve seguire la notazione data-ora come definito da RFC 3339, sezione 5.6. Esempi, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `L'operatore {operator} non è supportato per il campo {field}`,
  [`The value of {field} must be a number`]: `Il valore di {field} deve essere un numero`,
  [`Delete query`]: `Elimina query`,
  [`Delete filter`]: `Elimina filtro`,
  [`Save query`]: `Salva query`,
  [`Save filter`]: `Salva filtro`,
  [`Save filter as`]: `Salva filtro con nome`,
  [`Save query as`]: `Salva query con nome`,
  [`Save as...`]: `Salva con nome...`,
  [`Save as`]: `Salva con nome`,
  [`Paste query string to load the query`]: `Incollare la stringa della query per caricare la query`,
  [`Copy or paste filter string to load filters`]: `Copiare o incollare la stringa di filtro per caricare i filtri`,
  [`Query string`]: `Stringa di query`,
  [`View filter`]: `Visualizza filtro`,
  [`View filter string`]: `Visualizza stringa filtro`,
  [`Date (local time)`]: `Data (ora locale)`,
  [`Coming soon, a new built in analytics feature`]: `A breve sarà disponibile un nuovo build nella funzione di analitiche`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `Le analitiche consentiranno di raccogliere insight nel proprio runtime API, gestire i livelli di servizio, impostare le quote, stabilire i controlli, impostare le politiche di sicurezza, gestire le community e analizzare le tendenze.`,
  [`Documentation`]: `Documentazione`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Approfondire i tipi di dati di runtime dell'API e come utilizzare i bucket AWS s3 per visualizzarli.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Scoprire quali dati sono disponibili oggi e come accedervi.`,
  [`Next steps`]: `Passi successivi`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `Il servizio Secrets Manager selezionato non gestisce alcun certificato.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `È richiesto un certificato per proteggere la connessione tra API Connect ed il dominio dell'endpoint di gestione del gateway. I certificati sono necessari anche per proteggere le connessioni tra il gateway ed i domini da esso gestiti. Tutti i certificati devono essere memorizzati in un servizio Secrets Manager. Il servizio fornisce un repository sicuro per i certificati e aiuta a prevenire le interruzioni di servizio inviando notifiche quando i certificati stanno per scadere nell'integrazione con il servizio Event Notifications. Per iniziare,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `I certificati sono necessari per proteggere le connessioni tra il gateway ed i domini da esso gestiti. Tutti i certificati devono essere memorizzati in un servizio Secrets Manager. Il servizio fornisce un repository sicuro per i certificati e aiuta a prevenire le interruzioni di servizio inviando notifiche quando i certificati stanno per scadere nell'integrazione con il servizio Event Notifications. Per iniziare,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `La modifica di Secrets Manager rimuoverà tutte le assegnazioni di bundle di certificati e CA esistenti.`,
  [`Create a [Secrets Manager]({link}) service`]: `Crea un servizio [Secrets Manager]({link})`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Importare i certificati e i bundle di CA richiesti nel servizio Secrets Manager.`,
  [`No Secrets Manager services available`]: `Nessun servizio Secrets Manager disponibile`,
  [`Select Secrets Manager service`]: `Seleziona servizio Secrets Manager`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Selezionare il servizio [Secrets Manager]({link}) che gestisce i certificati per questo gateway.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Il servizio Secrets Manager deve gestire i certificati per il dominio dell'endpoint di gestione del gateway e i domini gestiti dal gateway. Inoltre, è necessario utilizzare IBM Cloud IAM per autorizzare il servizio dell'istanza API Connect Reserved ad accedere al servizio Secrets Manager.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Quindi, selezionare il certificato per il dominio dell'endpoint di gestione. Inoltre, selezionare il bundle CA corrispondente se il certificato non è stato assegnato da un'autorità di certificazione nota. Il certificato e il bundle devono essere gestiti dal servizio Secrets Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Quindi specificare i domini che verranno gestiti da questo gateway. Viene utilizzata la tecnica SNI; come risultato, è possibile servire più domini tramite lo stesso indirizzo IP e la stessa porta senza richiedere lo stesso certificato. Il formato con caratteri jolly non è supportato. Il dominio predefinito (catch-all) ‘*’ deve essere incluso come voce finale nella tabella. Immettere altri nomi di dominio come necessario, in ogni caso specificando un certificato gestito dal servizio Secrets Manager.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Utilizzando [IBM Cloud IAM]({link}), autorizzare il servizio dell'istanza API Connect Reserved per accedere al servizio Secrets Manager.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Aggiornare le assegnazioni di bundle di certificati e CA esistenti caricandole da Secrets Manager.`,
  [`Access s3 data`]: `Accedi ai dati s3`,
  [`Create resource`]: `Crea risorsa`,
  [`Connect analytics data in s3 bucket with AWS`]: `Connetti i dati di analitiche nel bucket s3 con AWS`,
  [`AWS account`]: `Account AWS`,
  [`AWS account number`]: `Numero account AWS`,
  [`Enter your 12 digit aws account id`]: `Immettere il proprio id account aws di 12 cifre`,
  [`Account id must be 12 digits`]: `L'id account deve essere di 12 cifre`,
  [`Failed to update AWS account id.`]: `Impossibile aggiornare l'id account AWS.`,
  [`You have limited access to the catalogs.`]: `Si dispone di accesso limitato ai cataloghi.`,
  [`You have limited access to the spaces.`]: `Si dispone di accesso limitato agli spazi.`,
  [`Contact admin to get more access.`]: `Contattare l'amministratore per avere ulteriore accesso.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `Non è possibile selezionare questo catalogo. Contattare un amministratore se è richiesto l'accesso.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `Non è possibile selezionare questo spazio. Contattare un amministratore se è richiesto l'accesso.`,
  [`You have limited access to the product billing.`]: `Si dispone di accesso limitato alla fatturazione del prodotto.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `Id account AWS aggiornato correttamente. Potrebbero essere necessari alcuni secondi per concedere l'accesso ai dati S3.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Nel catalogo scelto manca un'organizzazione o un gruppo.`,
  [`Secrets Manager instance`]: `Istanza Secrets Manager`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Le organizzazioni consumer con proprietari a cui manca un'email non possono essere selezionate`,
  [`You currently don’t have any data.`]: `Al momento non si dispone di dati.`,
  [`You currently don’t have any organizations.`]: `Al momento non si dispone di alcuna organizzazione.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Richiesta di sottoscrizione dell'app {appname} al piano {plan} nel prodotto {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Richiesta di {operation} prodotto: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Richiesta di aggiornamento dell'applicazione {appname} in produzione`,
  [`Onboarding request for {email}`]: `Richiesta di onboarding per {email}`,
  [`Product lifecycle request`]: `Richiesta del ciclo di vita del prodotto`,
  [`Subscription request`]: `Richiesta di sottoscrizione`,
  [`Application lifecycle request`]: `Richiesta del ciclo di vita dell'applicazione`,
  [`Consumer Onboarding request`]: `Richiesta di onboarding del consumer`,
  [`Originator`]: `Originatore`,
  [`Edit analytics service advanced settings`]: `Modifica impostazioni avanzate del servizio di analytics`,
  [`Advanced settings`]: `Impostazioni avanzate`,
  [`Must be a positive integer`]: `Deve essere un numero intero positivo`,
  [`Maximum value is {maxValue}`]: `Il valore massimo è {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `Il valore massimo è {maxValueDays}g o {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Configurare le impostazioni avanzate per la distribuzione di analytics.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `Il valore deve terminare con 'g' per giorni o 'h' per ore. Ad esempio 30g o 12h.`,
  [`Rollover settings`]: `Impostazioni di rollover`,
  [`Retention settings`]: `Impostazioni di conservazione`,
  [`Delete an index when it meets the following condition:`]: `Eliminare un indice quando soddisfa la condizione seguente:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Eseguire il rollover dell'alias di scrittura in un nuovo indice quando l'indice gestito soddisfa una delle seguenti condizioni:`,
  [`Minimum document count`]: `Conteggio minimo documenti`,
  [`The minimum number of documents required to roll over the index.`]: `Il numero minimo di documenti richiesti per eseguire il rollover dell'indice.`,
  [`Minimum index age`]: `Durata minima dell'indice`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `La durata minima dell'indice richiesta per eseguire il rollover dell'indice. L'età dell'indice è il tempo che intercorre tra la creazione dell'indice e questo momento. Utilizzare 'g' per giorni e 'h' per ore. Ad esempio, 30g indica che l'indice verrà sottoposto a rollover quando la sua età raggiunge i 30 giorni, mentre 96h indica che l'indice verrà sottoposto a rollover quando la sua età raggiunge le 96 ore. I valori massimi sono {maxValueDays}g o {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `La durata minima dell'indice richiesta per eliminare l'indice. L'età dell'indice è il tempo che intercorre tra la creazione dell'indice e questo momento. Utilizzare 'g' per giorni e 'h' per ore. Ad esempio, 30g indica che l'indice verrà eliminato quando la sua età raggiunge i 30 giorni, mentre 96h indica che l'indice verrà eliminato quando la sua età raggiunge le 96 ore. I valori massimi sono {maxValueDays}g o {maxValueHours}h.`,
  [`Time range:`]: `Intervallo di tempo:`,
  [`Time range: Before {datetime}`]: `Intervallo di tempo: prima di {datetime}`,
  [`Time range: After {datetime}`]: `Intervallo di tempo: dopo {datetime}`,
  [`Filter before`]: `Filtra prima`,
  [`Filter after`]: `Filtra dopo`,
  [`Filter exact`]: `Filtra esattamente`,
  [`Filter gt`]: `Filtra maggiore di`,
  [`Filter lt`]: `Filtra minore di`,
  [`Filter equals`]: `Filtra uguale a`,
  [`Filter by`]: `Filtra per`,
  [`Filter out`]: `Escludi`,
  [`Delete failed`]: `Eliminazione non riuscita`,
  [`Delete warning`]: `Elimina avvertenza`,
  [`Could not delete saved query {queryTitle}.`]: `Non è stato possibile eliminare la query salvata {queryTitle}.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `Alla richiesta è stato risposto correttamente, ma {queryTitle} non ha ancora terminato l'eliminazione.`,
  [`API governance`]: `Governance API`,
  [`Something's wrong`]: `Si è verificato un problema`,
  [`We were unable to connect to the service.`]: `Impossibile connettersi al servizio.`,
  [`Invalid date`]: `Data non valida`,
  [`Invalid time`]: `Orario non valido`,
  [`End date must be after the start date`]: `La data di fine deve essere successiva alla data di inizio`,
  [`Duplicate`]: `duplicato`,
  [`Duplicate query`]: `Duplica query`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Si è sicuri di voler creare un duplicato di {queryTitle}?`,
  [`No queries saved`]: `Nessuna query salvata`,
  [`No queries shared`]: `Nessuna query condivisa`,
  [`To save a query, start by creating one then save.`]: `Per salvare una query, crearne una e salvarla.`,
  [`Share a saved query and allow others to use it.`]: `Condividere una query salvata e consentire ad altri di utilizzarla.`,
  [`Query updated`]: `Query aggiornata`,
  [`Query update failed`]: `Aggiornamento della query non riuscito`,
  [`Share successful`]: `Condivisione riuscita`,
  [`Share failed`]: `Condivisione non riuscita`,
  [`Delete successful`]: `Eliminazione riuscita`,
  [`Query unshared`]: `Query non condivisa`,
  [`Unshare {queryTitle} failed`]: `Annullamento della condivisione di {queryTitle} non riuscito`,
  [`Query copied`]: `Query copiata`,
  [`Duplicate {queryTitle} failed`]: `Duplicazione di {queryTitle} non riuscita`,
  [`The query {queryTitle} has been updated.`]: `La query {queryTitle} è stata aggiornata.`,
  [`The query {queryTitle} has been shared.`]: `La query {queryTitle} è stata condivisa.`,
  [`The query {queryTitle} has been deleted.`]: `La query {queryTitle} è stata eliminata.`,
  [`The query {queryTitle} is no longer shared.`]: `La query {queryTitle} non è più condivisa.`,
  [`A copy of {queryTitle} has been created.`]: `È stata creata una copia di {queryTitle}.`,
  [`About queries`]: `Informazioni sulle query`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Le query sono un mezzo per ottimizzare i dati delle analitiche utilizzati nei dashboard e nella vista Rileva. Quando viene applicata una query, tutti i dashboard verranno aggiornati per includere solo i dati selezionati.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `È possibile utilizzare uno degli intervalli di tempo predefiniti o un intervallo di tempo specifico al secondo più vicino utilizzando l'opzione Personalizzato.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `I campi possono essere utilizzati per specificare che devono essere inclusi solo determinati dati. È possibile filtrare in base a quasi ogni attributo di un evento API.`,
  [`There are then different operators available depending on the field type.`]: `Esistono quindi diversi operatori disponibili in base al tipo di campo.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `I campi stringa consentono uguale, non, inizia con, termina con, regex.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `È possibile utilizzare contiene e non contiene per fornire un elenco di valori per un singolo campo (ad esempio un elenco di versioni API).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `I campi dell'indirizzo IP consentono gli operatori uguale e non, consentono anche di specificare il valore nella notazione CIDR per consentire l'uso di maschere di rete per selezionare specifiche reti.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Le espressioni regolari possono essere molto potenti per selezionare valori specifici, ma sono più lente da eseguire, pertanto si consiglia di utilizzare altri operatori come 'inizia con ' e 'termina con', se possibile.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `I filtri di query per campi diversi vengono combinati utilizzando l'operatore logico AND. Ciò significa che l'aggiunta di due filtri per lo stesso nome di campo restituirà solo i risultati che soddisfano entrambi i filtri.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `Il parametro di query {type} è un metodo semplice per specificare solo gli eventi con esito positivo o errore. Questo utilizza il campo {statusCode}. Se sono necessari solo codici di stato specifici, è possibile utilizzare un filtro di campo. Il valore per i campi {statusCodeTwo} è generalmente una stringa completa come {okStatus} e non solo il codice di ritorno HTTP numerico.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `È possibile salvare le query per un successivo riutilizzo e anche condividerle per l'utilizzo con il livello di ambito corrente per altri utenti, ad esempio l'organizzazione provider o il catalogo.`,
  [`n/a`]: `n/d`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Nota: i campi 'ID organizzazione provider' e 'Nome organizzazione provider' verranno mostrati nel payload di evento rispettivamente come {orgId} e {orgName}; i campi 'ID organizzazione consumer' e 'Nome organizzazione consumer' verranno mostrati come {developerOrgId} e {developerOrgName}.`,
  [`No authentication token`]: `Nessun token di autenticazione`,
  [`Contact your administrator for further assistance.`]: `Contattare l'amministratore per ulteriore assistenza.`
};
