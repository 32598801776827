// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2021
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apim-ui

'use strict'

/* Services */

const apis = angular.module('apiconnect-assembly')

apis.factory('TestCallGenerator', [
  '$http',
  'translateFilter',
  function($http, translateFilter) {
    let scope

    function formatResponseBody(bodyString) {
      if (bodyString.startsWith('<'))
        return window.vkbeautify
          ? window.vkbeautify.xml(bodyString)
          : bodyString
      if (bodyString.startsWith('{'))
        return JSON.stringify(JSON.parse(bodyString), null, 2)
      if (bodyString.startsWith('['))
        return JSON.stringify(JSON.parse(bodyString), null, 2)
      return bodyString
    }

    function invokeHelperResponseHandler(
      response,
      startTime,
      callCount,
      repeatCount,
      totalTime,
      request,
      isError
    ) {
      const responseTime = new Date().getTime()
      callCount++
      repeatCount--
      const callAgain =
        repeatCount > 0 &&
        (!isError || (isError && scope.repeater.stopOnError == false))

      if (response instanceof Error) {
        scope.response.statusCode = translateFilter('assembly_test_parse_error')
        scope.response.responseData = response
        scope.response.responseDataString = response.toString()
        if (!callAgain) scope.invokeRunning = false
        return
      }

      scope.response.statusCode = `${response.status} ${response.statusText}`
      if (response.status == -1) {
        scope.response.statusInfo = translateFilter(
          'No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.'
        )
      }
      const elapsedTime = responseTime - startTime
      scope.response.responseTime = elapsedTime
      totalTime += elapsedTime
      if (callCount + repeatCount > 1) {
        scope.response.avgResponseTime = Math.round(totalTime / callCount)
        scope.response.callCounter = callCount
        scope.response.totalTime = totalTime
      }

      if (response.data) {
        scope.response.responseData = response.data
        if (typeof response.data !== 'string') {
          scope.response.responseDataString = JSON.stringify(
            response.data,
            null,
            2
          )
        } else {
          if (response.data.startsWith('<')) {
            scope.response.responseDataString = window.vkbeautify
              ? window.vkbeautify.xml(response.data)
              : response.data
          } else {
            scope.response.responseDataString = response.data
          }
        }
      }

      if (scope.stopInvoke) {
        scope.stopInvoke = false
        scope.invokeRunning = false
        return
      }
      scope.response.headers = response.headers()
      if (Object.keys(scope.response.headers).length > 0)
        scope.response.containsHeaders = true
      let headersAsString = ''
      Object.keys(scope.response.headers).forEach(function(headerName) {
        headersAsString += `${headerName}: ${scope.response.headers[headerName]}\n`
      })
      scope.response.transactionId =
        scope.response.headers['apim-debug-trans-id']
      scope.response.headersAsString = headersAsString

      // provide details of the request too
      let requestHeadersAsString = ''

      // Use the correct content-type if it is SOAP
      if (
        request.headers.SOAPAction &&
        (!request.headers['Content-Type'] ||
          !request.headers['Content-Type'].includes('text/xml'))
      ) {
        request.headers['Content-Type'] = 'text/xml'
      }
      Object.keys(request.headers).forEach(function(headerName) {
        requestHeadersAsString += `${headerName}: ${request.headers[headerName]}\n`
      })
      scope.response.request = {
        url: request.url,
        method: request.method,
        headers: request.headers,
        headersAsString: requestHeadersAsString,
      }

      if (callAgain) {
        setTimeout(function() {
          invokeHelper(callCount, repeatCount, totalTime)
        }, 5)
      } else {
        scope.invokeRunning = false
      }
    }

    function invokeHelper(callCount, repeatCount, totalTime) {
      if (repeatCount <= 0) return
      const startTime = new Date().getTime()
      const verb = scope.operationRef ? scope.operationRef.verb : scope.verb
      let req
      const requestInfo = {
        method: verb,
        url: scope.targetUrl,
        headers: scope.headers,
        skipErrorCheck: true,
      }
      if (scope.body) {
        requestInfo.data = scope.body
      }
      if (scope.proxy) {
        req = {
          method: 'POST',
          url: `${window.location.protocol}//${window.location.host}/proxy/proxyService`,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/xml',
          },
        }
        req.data = JSON.stringify(requestInfo)
      } else {
        req = requestInfo
      }
      // Use the correct content-type if it is SOAP
      if (
        req.headers.SOAPAction &&
        (!req.headers['Content-Type'] ||
          !req.headers['Content-Type'].includes('text/xml'))
      ) {
        req.headers['Content-Type'] = 'text/xml'
      }
      $http(req).then(
        function(response) {
          invokeHelperResponseHandler(
            response,
            startTime,
            callCount,
            repeatCount,
            totalTime,
            req,
            false
          )
        },
        function(response) {
          invokeHelperResponseHandler(
            response,
            startTime,
            callCount,
            repeatCount,
            totalTime,
            req,
            true
          )
        }
      )
    }

    function invoke($scope, config) {
      scope = $scope

      let repeatCount = 1
      if ($scope.repeater && $scope.repeater.repeat == true)
        repeatCount = $scope.repeater.count

      if (!$scope.targetUrlTemplate) return

      // clear out any old response
      $scope.response = {}

      let targetUrl = $scope.targetUrlTemplate

      if (!targetUrl.startsWith('http')) {
        if ($scope.scheme) {
          targetUrl = `${$scope.scheme}://${targetUrl}`
        } else {
          targetUrl = `https://${targetUrl}`
        }
      }

      // set up headers
      const headers = {}
      if (config.sendDebugHeader) headers['APIm-Debug'] = true
      if ($scope.contentTypeHeader) {
        headers['Content-Type'] = $scope.contentTypeHeader
      }
      if ($scope.acceptHeader) {
        headers.Accept = $scope.acceptHeader
      }
      if (config.requiresBasicAuth) {
        headers.Authorization = `Basic ${btoa(
          `${$scope.username}:${$scope.password}`
        )}`
      }
      if (
        config.requiresOauth &&
        $scope.authToken &&
        $scope.authToken.access_token
      ) {
        headers.Authorization = `Bearer ${$scope.authToken.access_token}`
      }
      if (config.requiresClientId && config.clientIdLocation == 'header') {
        headers[config.clientIdName] = $scope.clientId
      }
      if (
        config.requiresClientSecret &&
        config.clientSecretLocation == 'header'
      ) {
        headers[config.clientSecretName] = $scope.clientSecret
      }
      if (config.soapAction !== undefined) {
        headers.SOAPAction = config.soapAction
      }

      let parameters = config.parametersArray
      if (!parameters) parameters = []
      // path parameters
      const pathParameters = parameters.filter(function(parameter) {
        return parameter.in == 'path'
      })
      if (pathParameters.length > 0) {
        pathParameters.forEach(function(parameter) {
          if (targetUrl.match(`{${parameter.name}}`)) {
            targetUrl = targetUrl.replace(
              `{${parameter.name}}`,
              $scope.parameterValues[parameter.name]
            )
          } else {
            targetUrl += `/${$scope.parameterValues[parameter.name]}`
          }
        })
      }
      // query parameters
      const queryParameters = parameters.filter(function(parameter) {
        return (
          parameter.in == 'query' &&
          $scope.parameterValues[parameter.name] !== undefined
        )
      })
      let queryParametersAdded = targetUrl.indexOf('?') >= 0
      if (queryParameters.length > 0) {
        targetUrl += queryParametersAdded ? '&' : '?'
        queryParametersAdded = true
        queryParameters.forEach(function(parameter) {
          // exclude 'omit' value optional booleans
          if (
            parameter.type === 'boolean' &&
            $scope.parameterValues[parameter.name] === 'omit'
          )
            return
          targetUrl += `${parameter.name}=${
            $scope.parameterValues[parameter.name]
          }&`
        })
        targetUrl = targetUrl.substring(0, targetUrl.length - 1)
      }
      if (config.requiresClientId && config.clientIdLocation == 'query') {
        if (queryParametersAdded) {
          targetUrl += `&client_id=${$scope.clientId}`
        } else {
          targetUrl += `?client_id=${$scope.clientId}`
          queryParametersAdded = true
        }
      }
      if (
        config.requiresClientSecret &&
        config.clientSecretLocation == 'query'
      ) {
        if (queryParametersAdded) {
          targetUrl += `&client_secret=${$scope.clientSecret}`
        } else {
          targetUrl += `?client_secret=${$scope.clientSecret}`
          queryParametersAdded = true
        }
      }

      // headers
      const headerParameters = parameters.filter(function(parameter) {
        return (
          parameter.in == 'header' &&
          $scope.parameterValues[parameter.name] !== undefined
        )
      })
      if (headerParameters.length > 0) {
        headerParameters.forEach(function(parameter) {
          // exclude 'omit' value optional booleans
          if (
            parameter.type === 'boolean' &&
            $scope.parameterValues[parameter.name] === 'omit'
          )
            return
          headers[parameter.name] = $scope.parameterValues[parameter.name]
        })
      }

      // body
      const bodyParameters = parameters.filter(function(parameter) {
        return parameter.in == 'body'
      })
      if (bodyParameters.length > 0) {
        // use the first one only
        $scope.body = $scope.parameterValues[bodyParameters[0].name]
      } else {
        delete $scope.body
      }
      $scope.targetUrl = targetUrl
      $scope.headers = headers
      $scope.stopInvoke = false
      delete $scope.responseData
      delete $scope.responseTime
      delete $scope.avgResponseTime
      delete $scope.callCounter
      $scope.invokeRunning = true
      // scrollResponseIntoView();
      invokeHelper(0, repeatCount, 0)
    }

    function updateConfigurationForOauth(config, oauthDef) {
      if (oauthDef.type !== 'oauth2') return config
      config.requiresOauth = true
      config.oauthFlow = oauthDef.flow
      config.requiresClientId = true
      if (
        oauthDef.flow == 'application' ||
        oauthDef.flow == 'accessCode' ||
        oauthDef.flow == 'password'
      ) {
        config.requiresClientSecret = true
      }
      if (oauthDef.authorizationUrl) {
        config.oauthAuthUrl = oauthDef.authorizationUrl
      }
      if (oauthDef.tokenUrl) {
        config.oauthTokenUrl = oauthDef.tokenUrl
      }
      if (oauthDef.scopes && Object.keys(oauthDef.scopes).length !== 0) {
        config.oauthScopes = Object.keys(oauthDef.scopes)
      }
      return config
    }

    /**
     * look through the operation and api and determine the various configuration options required for the operation
     **/
    function getConfigurationForOperation(
      operation,
      path,
      api,
      publishedApis,
      securityFlow,
      referenceLibrary
    ) {
      const config = {}

      // check security requirements
      config.requiresClientId = false
      delete config.clientIdLocation
      config.requiresClientSecret = false
      delete config.clientSecretLocation
      config.requiresBasicAuth = false
      config.requiresOauth = false
      config.securityFlows = []

      config.requiresSecuritySection = false
      config.requiresIdentificationSection = false
      config.requiresAuthorizationSection = false
      config.requiresRefreshToken = false
      config.requiresUserCredentials = false
      config.requiresRedirectUri = false

      delete config.oauthFlow
      delete config.oauthAuthUrl
      delete config.oauthTokenUrl
      delete config.oauthScopes

      const security = {}
      let securityDefinitions = operation.security
      if (!securityDefinitions) securityDefinitions = api.security
      if (securityDefinitions) {
        securityDefinitions.forEach(function(securityDefs) {
          const label = Object.keys(securityDefs)
            .filter(function(key) {
              return key.indexOf('$$') !== 0
            })
            .join(', ')
          securityDefs.$$label = label
          config.securityFlows.push(securityDefs)

          if (!securityFlow) {
            securityFlow = securityDefs
          }

          // if a security flow has been selected, switch on all
          // the necessary security flags for the given flow
          if (securityFlow && securityFlow.$$label === securityDefs.$$label) {
            Object.keys(securityDefs).forEach(function(securityDef) {
              const thisDef = api.securityDefinitions[securityDef]
              if (!thisDef) return
              security[securityDef] = thisDef
              if (
                thisDef.type == 'apiKey' &&
                (thisDef.name == 'client_id' ||
                  thisDef.name == 'X-IBM-Client-Id' ||
                  thisDef['x-key-type'] == 'client_id')
              ) {
                config.requiresClientId = true
                config.clientIdLocation = thisDef.in
                config.clientIdName = thisDef.name
              }
              if (
                thisDef.type == 'apiKey' &&
                (thisDef.name == 'client_secret' ||
                  thisDef.name == 'X-IBM-Client-Secret' ||
                  thisDef['x-key-type'] == 'client_secret')
              ) {
                config.requiresClientSecret = true
                config.clientSecretLocation = thisDef.in
                config.clientSecretName = thisDef.name
              }
              if (thisDef.type == 'basic') {
                config.requiresBasicAuth = true
              }
              if (thisDef.type == 'oauth2') {
                config.requiresOauth = true
                config.requiresClientId = true
                config.oauthFlow = thisDef.flow
                if (
                  thisDef.flow == 'application' ||
                  thisDef.flow == 'accessCode' ||
                  thisDef.flow == 'password'
                ) {
                  config.requiresClientSecret = true
                }
                if (
                  thisDef.flow == 'implicit' ||
                  thisDef.flow == 'accessCode'
                ) {
                  config.requiresRedirectUri = true
                }
                if (localStorage.getItem('IS_DESIGNER') === 'true') {
                  //Designer only
                  if (thisDef.authorizationUrl) {
                    config.oauthAuthUrl = thisDef.authorizationUrl
                  }
                  if (thisDef.tokenUrl) {
                    config.oauthTokenUrl = thisDef.tokenUrl
                  }
                } else {
                  if (publishedApis) {
                    var consumerApi = publishedApis.find(conApi => {
                      const consumer = conApi.consumer_api
                      return (
                        consumer.info['x-ibm-name'] ===
                          api.info['x-ibm-name'] &&
                        consumer.info.version === api.info.version
                      )
                    })
                  }

                  if (consumerApi) {
                    const oauthProvider = thisDef['x-ibm-oauth-provider']
                    var secDefs = consumerApi.consumer_api.securityDefinitions

                    // Return valid security definition based on oauth provider
                    var secDef = Object.keys(secDefs).filter(def => {
                      if (
                        secDefs[def]['x-ibm-oauth-provider'] === oauthProvider
                      )
                        return secDefs[def]
                    })
                  }
                  const securityDefinition = secDef[0]

                  const fullBasePath =
                    consumerApi.consumer_api.host +
                    consumerApi.consumer_api.basePath
                  const basePathUrl = fullBasePath.substring(
                    0,
                    fullBasePath.lastIndexOf('/')
                  )

                  if (thisDef.authorizationUrl) {
                    const authUrl = secDefs[securityDefinition].authorizationUrl
                    if (authUrl.includes('https://')) {
                      if (authUrl.includes('${catalog.url}')) {
                        config.oauthAuthUrl = authUrl.replace(
                          '${catalog.url}',
                          basePathUrl
                        )
                      } else {
                        config.oauthAuthUrl = authUrl
                      }
                    } else {
                      config.oauthAuthUrl = `${consumerApi.consumer_api.schemes[0]}://${basePathUrl}${authUrl}`
                    }
                  }
                  if (thisDef.tokenUrl) {
                    const tokenUrl = secDefs[securityDefinition].tokenUrl
                    if (tokenUrl.includes('https://')) {
                      if (tokenUrl.includes('${catalog.url}')) {
                        config.oauthTokenUrl = tokenUrl.replace(
                          '${catalog.url}',
                          basePathUrl
                        )
                      } else {
                        config.oauthTokenUrl = tokenUrl
                      }
                    } else {
                      config.oauthTokenUrl = `${consumerApi.consumer_api.schemes[0]}://${basePathUrl}${tokenUrl}`
                    }
                  }

                  config.requiresAuthorizationSection = true
                }

                if (
                  thisDef.scopes &&
                  Object.keys(thisDef.scopes).length !== 0
                ) {
                  config.oauthScopes = Object.keys(thisDef.scopes)
                }
              }
            })
          }
        })
      }
      if (!_.isEmpty(security)) config.security = security

      // any security requirements at all?
      if (
        config.requiresClientId ||
        config.requiresClientSecret ||
        config.requiresBasicAuth ||
        config.requiresOauth
      ) {
        config.requiresSecuritySection = true
      }

      // any requirement for identification?
      if (config.requiresClientId || config.requiresClientSecret) {
        config.requiresIdentificationSection = true
      }

      // any requirement for authorization?
      if (config.requiresBasicAuth || config.oauthFlow === 'password') {
        config.requiresAuthorizationSection = true
        config.requiresUserCredentials = true
      }

      // any requirement for token refresh?
      if (config.oauthFlow === 'accessCode') {
        config.requiresRefreshToken = true
      }

      // figure out which parameters apply here
      let parameters = []
      const dereferencedParameters = {}
      if (operation.parameters)
        parameters = parameters.concat(operation.parameters)
      if (path.parameters) parameters = parameters.concat(path.parameters)

      for (let i = 0; i < parameters.length; i++) {
        parameters[i] = JSON.parse(JSON.stringify(parameters[i]))
      }

      // dereference parameters
      parameters.forEach(function(parameter) {
        if (parameter.$ref) {
          // dereference $ref
          let parameterName = parameter.$ref.split('/').pop()
          if (referenceLibrary && referenceLibrary[parameter.$ref]) {
            dereferencedParameters[parameterName] =
              referenceLibrary[parameter.$ref]
          } else {
            parameterName = parameter.$ref.replace('#/parameters/', '')
            if (api.parameters && api.parameters[parameterName]) {
              dereferencedParameters[parameterName] =
                api.parameters[parameterName]
            } else {
              console.warn(`Missing reference: ${parameter.$ref}`)
              dereferencedParameters[parameterName] = {
                name: parameterName,
                description: `${translateFilter(
                  'explorer_missing_parameter'
                )}: ${parameter.$ref}`,
                schema: {
                  type: 'unknown',
                },
              }
            }
          }
          if (dereferencedParameters[parameterName])
            dereferencedParameters[parameterName].$$tmpId = Math.random()
        } else {
          dereferencedParameters[parameter.name] = parameter
          dereferencedParameters[parameter.name].$$tmpId = Math.random()
        }
      })
      if (!_.isEmpty(dereferencedParameters)) {
        config.parameters = dereferencedParameters
        const asArray = []
        Object.keys(dereferencedParameters).forEach(function(parameterName) {
          asArray.push(dereferencedParameters[parameterName])
        })
        config.parametersArray = asArray
        config.requiresParametersSection = true
      }

      // any SOAP specific content?
      if (operation['x-ibm-soap']) {
        if (operation['x-ibm-soap']['soap-action'] !== undefined) {
          config.soapAction = operation['x-ibm-soap']['soap-action']
        }
      }

      // content types
      if (operation.consumes) {
        config.contentTypes = operation.consumes
      } else if (api.consumes) {
        config.contentTypes = api.consumes
      } else {
        config.contentTypes = ['application/json']
      }
      if (operation.produces) {
        config.accepts = operation.produces
      } else if (api.produces) {
        config.accepts = api.produces
      } else {
        config.accepts = ['application/json']
      }

      return config
    }

    return {
      invoke,
      getConfigurationForOperation,
      updateConfigurationForOauth,
    }
  },
])

apis.factory('OAuthPopup', [
  '$q',
  '$interval',
  '$window',
  function($q, $interval, $window) {
    const parseQueryString = function(keyValue) {
      const obj = {}
      let key
      let value
      angular.forEach((keyValue || '').split('&'), function(keyValue) {
        if (keyValue) {
          value = keyValue.split('=')
          key = decodeURIComponent(value[0])
          obj[key] = angular.isDefined(value[1])
            ? decodeURIComponent(value[1])
            : true
        }
      })
      return obj
    }

    const Popup = {}

    Popup.url = ''
    Popup.popupWindow = null

    Popup.open = function(url, name, options) {
      Popup.url = url

      console.log(url)

      const stringifiedOptions = Popup.stringifyOptions(
        Popup.prepareOptions(options)
      )
      const UA = $window.navigator.userAgent
      const windowName = name

      Popup.popupWindow = $window.open(url, windowName, stringifiedOptions)

      $window.popup = Popup.popupWindow

      if (Popup.popupWindow && Popup.popupWindow.focus) {
        Popup.popupWindow.focus()
      }

      return Popup
    }

    Popup.pollPopup = function() {
      const deferred = $q.defer()

      var polling = $interval(function() {
        try {
          const documentOrigin = document.location.host
          const popupWindowOrigin = Popup.popupWindow.location.host

          if (
            popupWindowOrigin === documentOrigin &&
            (Popup.popupWindow.location.search ||
              Popup.popupWindow.location.hash)
          ) {
            const queryParams = Popup.popupWindow.location.search
              .substring(1)
              .replace(/\/$/, '')
            const hashParams = Popup.popupWindow.location.hash
              .substring(1)
              .replace(/[\/$]/, '')
            const hash = parseQueryString(hashParams)
            const qs = parseQueryString(queryParams)

            angular.extend(qs, hash)

            if (qs.error) {
              deferred.reject(qs)
            } else {
              deferred.resolve(qs)
            }

            $interval.cancel(polling)

            Popup.popupWindow.close()
          }
        } catch (error) {
          // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        }

        if (
          !Popup.popupWindow ||
          Popup.popupWindow.closed ||
          Popup.popupWindow.closed === undefined
        ) {
          $interval.cancel(polling)
        }
      }, 50)

      return deferred.promise
    }

    Popup.prepareOptions = function(options) {
      options = options || {}
      const width = options.width || 500
      const height = options.height || 500

      return angular.extend(
        {
          width,
          height,
          left: $window.screenX + ($window.outerWidth - width) / 2,
          top: $window.screenY + ($window.outerHeight - height) / 2.5,
        },
        options
      )
    }

    Popup.stringifyOptions = function(options) {
      const parts = []
      angular.forEach(options, function(value, key) {
        parts.push(`${key}=${value}`)
      })
      return parts.join(',')
    }

    return Popup
  },
])

apis.factory('SwaggerOAuth', [
  '$http',
  '$q',
  'OAuthPopup',
  function($http, $q, OAuthPopup) {
    const self = this

    let deferred

    function handleAuthError(error) {
      deferred.reject(error)
    }

    function handleTokenSuccess(data) {
      deferred.resolve(data)
    }

    self.authorize = function(
      config,
      clientId,
      clientSecret,
      scopes,
      username,
      password,
      redirectUri
    ) {
      deferred = $q.defer()
      let openPopup
      let req
      if (!config) return
      if (config.oauthFlow == 'accessCode') {
        openPopup = OAuthPopup.open(
          `${
            config.oauthAuthUrl
          }?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
            ' '
          )}`,
          'Authorize',
          {},
          document.location.host
        ).pollPopup()
        openPopup.then(function(data) {
          self.getToken(
            config,
            data,
            clientId,
            clientSecret,
            scopes,
            redirectUri
          )
        }, handleAuthError)
      } else if (config.oauthFlow == 'implicit') {
        openPopup = OAuthPopup.open(
          `${
            config.oauthAuthUrl
          }?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
            ' '
          )}`,
          'Authorize',
          {},
          document.location.host
        ).pollPopup()
        openPopup.then(handleTokenSuccess, handleAuthError)
      } else if (config.oauthFlow == 'password') {
        req = {
          method: 'POST',
          data: `grant_type=password&username=${username}&password=${password}&client_id=${clientId}&client_secret=${clientSecret}&scope=${scopes.join(
            ' '
          )}`,
          url: config.oauthTokenUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          skipErrorCheck: true,
        }
        $http(req).then(handleTokenSuccess, handleAuthError)
      } else if (config.oauthFlow == 'application') {
        req = {
          method: 'POST',
          data: `grant_type=client_credentials&scope=${scopes.join(' ')}`,
          url: config.oauthTokenUrl,
          headers: {
            Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          skipErrorCheck: true,
        }
        $http(req).then(handleTokenSuccess, handleAuthError)
      }
      return deferred.promise
    }

    self.getToken = function(
      config,
      authToken,
      clientId,
      clientSecret,
      scopes,
      redirectUri
    ) {
      deferred = $q.defer()
      const req = {
        method: 'POST',
        url: config.oauthTokenUrl,
        headers: {
          Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: `grant_type=authorization_code&code=${encodeURIComponent(
          authToken.code
        )}&redirect_uri=${redirectUri}&scope=${scopes.join(' ')}`,
        skipErrorCheck: true,
      }
      $http(req).then(handleTokenSuccess, handleAuthError)
      return deferred.promise
    }

    self.refreshToken = function(config, authToken, clientId, clientSecret) {
      deferred = $q.defer()
      const req = {
        method: 'POST',
        url: `${config.oauthTokenUrl}?grant_type=refresh_token&refresh_token=${authToken['refresh-token']}`,
        headers: {
          Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        skipErrorCheck: true,
      }
      $http(req).then(handleTokenSuccess, handleAuthError)
      return deferred.promise
    }

    return self
  },
])
