// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2019, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

function t(original) {
  return original
}

function MainController(
  $scope,
  $mdDialog,
  outputParser,
  $sce,
  expression,
  swaggerDocument
) {
  $scope.jsonOutput = {}
  $scope.swaggerDocument = swaggerDocument
  $scope.operations = {
    operationPath: [],
    operationId: [],
  }
  $scope.output = $scope.output || {}
  $scope.res = $scope.res || {}
  $scope.search = function(form, $event) {
    $scope.jsonOutput = JSON.stringify($scope.searchContainer, null, 4)
  }
  $scope.$watch(
    'searchContainer',
    function() {
      $scope.search()
      $scope.jsonOutput = $scope.jsonOutput || {}
      const groups = $scope.searchContainer.groups
      if (!!groups && groups instanceof Array && groups.length > 0) {
        $scope.res = outputParser.parse(groups[0], $scope.sourceTypes)
        $scope.output = $sce.trustAsHtml($scope.res)
      }
    },
    true
  )

  // get operationPath and operationId from swagger
  function getOperation() {
    const paths = $scope.swaggerDocument && $scope.swaggerDocument.paths
    Object.keys(paths).forEach(path => {
      Object.keys(paths[path]).forEach(verb => {
        if (paths[path][verb].operationId) {
          const operationId = `'${paths[path][verb].operationId}'`
          $scope.operations.operationId.push({
            displayName: operationId,
            name: operationId,
          })
        }
      })
      $scope.operations.operationPath.push({
        displayName: path,
        name: path,
      })
    })
  }
  getOperation()

  // conditionParser
  function isFunc(str, index) {
    if (!str || index === undefined) return false
    try {
      if (str.charAt(index) !== '(') return false
      let res = false
      $scope.sourceTypes[0].sourceFields.forEach(obj => {
        const name = obj.name
        const temp = str.slice(index - name.length, index)
        if (temp === name) {
          res = obj
        }
      })
      // support JSONata functions
      let startIndex = index - 1
      while (startIndex > -1 && !res) {
        if (
          str[startIndex] === '$' ||
          str[startIndex] === ' ' ||
          startIndex === 0 ||
          str[startIndex] === '('
        ) {
          if (startIndex === index - 1) break
          const funcName = str.substr(startIndex, index - startIndex)
          res = funcName !== '$not' ? funcName : false
          break
        }
        startIndex--
      }
      return res
    } catch (e) {
      return false
    }
  }

  function isGroup(str, idx) {
    let count = 0
    let index = idx + 1
    while (idx > -1) {
      if (str[idx] !== '(') {
        if (isFunc(str, idx + 1)) return false
        break
      }
      idx--
    }
    while (index < str.length) {
      const char = str[index]
      if (char === '(') {
        count++
      }
      if (char === ')') {
        if (count === 0) {
          const strTailing = str[index + 1]
          if (
            typeof strTailing !== 'undefined' &&
            strTailing !== ')' &&
            strTailing !== ' '
          ) {
            return false
          }
          break
        }
        count--
      }
      index++
    }
    return true
  }

  function parseGroups(string) {
    // find all parenthese group
    let str = string
    const groups = []
    const notGroups = []
    for (let i = 0; i < str.length; i++) {
      const group = {}
      if (str.charAt(i) === '(') {
        let isGroupVar = isGroup(str, i)
        group.start = i
        let count = 0
        let secIndex = i + 1
        while (secIndex < str.length) {
          if (str.charAt(secIndex) === '(') {
            if (
              isGroupVar &&
              isGroup(str, secIndex) &&
              group.innerGroupStartIndex === undefined
            )
              group.innerGroupStartIndex = secIndex
            count++
          }
          if (str.charAt(secIndex) === ')') {
            if (count === 0) {
              const strTailing = str.charAt(secIndex + 1)
              if (
                typeof str[secIndex + 1] !== 'undefined' &&
                strTailing !== ')' &&
                strTailing !== ' '
              ) {
                isGroupVar = false
              }
              group.end = secIndex
              break
            } else {
              count--
            }
          }
          secIndex++
        }
        isGroupVar ? groups.push(group) : notGroups.push(group)
      }
    }
    // add top level parenthese if it's missing
    if (
      !groups[0] ||
      (groups[0].start !== 0 &&
        groups.end !== str.length - 1 &&
        groups[0].start !== 4 &&
        groups[0].isNotGroup)
    ) {
      groups.unshift({
        start: 0,
        end: str.length + 1,
        logicalOperator: {
          displayName: 'and',
          name: 'and',
        },
        isNotGroup: false,
        conditions: [],
        innerGroupStartIndex: groups[0] && groups[0].start + 1,
      })
      str = `(${str})`
      for (let i = 1; i < groups.length; i++) {
        groups[i].start++
        typeof groups.innerGroupStartIndex !== 'undefined' &&
          groups.innerGroupStartIndex++
        groups[i].end++
      }
    }
    return {
      str,
      groups,
      notGroups,
    }
  }

  function inGroup(index, notGroups) {
    for (const group of notGroups) {
      if (index > group.start && index < group.end) return false
    }
    return true
  }

  function conditionParser(string) {
    const {str, groups, notGroups} = parseGroups(string)
    // start parsing conditions in each group
    groups.forEach(function(group, groupIndex) {
      // is this group a NOT group?
      const tryNot = str.slice(0, group.start).trim()
      if (tryNot.length > 3 && tryNot.substr(tryNot.length - 4) === '$not')
        group.isNotGroup = true
      // get upper group logical operator
      const beforeStr = group.isNotGroup
        ? str.slice(0, group.start - 4).trim()
        : str.slice(0, group.start).trim()
      if (
        beforeStr.length > 2 &&
        beforeStr.substr(beforeStr.length - 3) === 'and'
      )
        group.upperGroupLogicalOperator = {
          name: 'and',
          displayName: 'and',
        }
      if (
        beforeStr.length > 1 &&
        beforeStr.substr(beforeStr.length - 2) === 'or'
      )
        group.upperGroupLogicalOperator = {
          name: 'or',
          displayName: 'or',
        }
      const conditions = []
      let substr = str.slice(
        group.start + 1,
        group.innerGroupStartIndex || group.end
      )
      let remainingStrIndex = group.start + 1
      while (
        substr.length > 0 &&
        (substr.indexOf(' and ') > 0 || substr.indexOf(' or ') > 0)
      ) {
        let firstLogicalOperatorIndex
        let firstLogicalOperatorLength
        if (substr.indexOf(' and ') < 0) {
          firstLogicalOperatorIndex = substr.indexOf(' or ')
          firstLogicalOperatorLength = 4
        } else if (substr.indexOf(' or ') < 0) {
          firstLogicalOperatorIndex = substr.indexOf(' and ')
          firstLogicalOperatorLength = 5
        } else {
          firstLogicalOperatorIndex =
            substr.indexOf(' and ') < substr.indexOf(' or ')
              ? substr.indexOf(' and ')
              : substr.indexOf(' or ')
          firstLogicalOperatorLength =
            substr.indexOf(' and ') < substr.indexOf(' or ') ? 5 : 4
        }
        const condition = substr.slice(0, firstLogicalOperatorIndex)
        const logicalOperatorEnd =
          firstLogicalOperatorIndex + firstLogicalOperatorLength
        conditions.push({
          condition,
          startIdx: remainingStrIndex,
        })
        const logicalOperator = substr
          .slice(firstLogicalOperatorIndex, logicalOperatorEnd)
          .trim()
        conditions.push({
          condition: logicalOperator,
        })
        remainingStrIndex = logicalOperatorEnd
        substr = substr.slice(logicalOperatorEnd)
      }
      if (substr.length > 0)
        conditions.push({
          condition: substr.trim(),
          startIdx: remainingStrIndex,
        })
      // is last condition a group logical operator?
      let lastCondition = conditions[conditions.length - 1]
      if (lastCondition.condition === '$not') {
        conditions.pop()
        lastCondition = conditions[conditions.length - 1]
      }
      if (
        group.innerGroupStartIndex !== undefined &&
        (lastCondition.condition === 'and' || lastCondition.condition === 'or')
      ) {
        group.logicalOperator = {
          name: lastCondition,
          displayName: lastCondition,
        }
        conditions.pop()
      } else if (!group.logicalOperator) {
        group.logicalOperator = {
          name: 'and',
          displayName: 'and',
        }
      }
      // parse every single condition
      const groupConditions = []
      conditions.forEach(function(conditionObj, conIndex) {
        const {condition, startIdx} = conditionObj
        const finalCondition = {}
        let target
        $scope.sourceTypes[0].sourceFields.forEach(function(type) {
          if (condition.indexOf(`${type.name}(`) === 0) {
            target = type
            finalCondition.sourceField = {
              name: type.name,
              displayName: type.displayName,
            }
          }
        })
        // not a type, then see if it's logical operator
        !target &&
          $scope.logicalOperators.forEach(function(logical) {
            if (logical.displayName === condition) {
              target = logical
              finalCondition.logicalOperator = logical.displayName
            }
          })
        if (!target) {
          // we couldn't find any match type so this is custom input
          target = $scope.sourceTypes[0].sourceFields.find(function(type) {
            return type.displayName === 'Custom'
          })
          finalCondition.sourceField = {
            name: target.name,
            displayName: target.displayName,
          }
          finalCondition.inputItem = {
            custom: condition,
          }
        }
        let restPart = condition
        if (target.hasComparisonOperator) {
          let operator
          for (
            let comIndex = target.comparisonOperators.length - 1;
            comIndex > -1;
            comIndex--
          ) {
            operator = target.comparisonOperators[comIndex]
            const operatorIndex = condition.indexOf(operator.displayName)
            if (
              condition.indexOf(operator.displayName) > 0 &&
              inGroup(operatorIndex + startIdx, notGroups)
            ) {
              finalCondition.comparisonOperator = operator
              restPart = condition.split(operator.displayName)
              if (restPart.length > 1) {
                const inputItem = target.quoteInput
                  ? restPart[1].trim().replace(/['"](.+)['"]$/, '$1')
                  : restPart[1].trim()
                finalCondition.inputItem = {
                  displayName: inputItem,
                  data: inputItem,
                }
              }
              break
            }
          }
          if (!finalCondition.comparisonOperator && !target.canIgnoreInput) {
            // can't find needed comparisonOperator, make it custom
            finalCondition.sourceField = {
              name: 'Custom',
              displayName: 'Custom',
            }
            finalCondition.inputItem = {
              custom: condition,
            }
          }
        }
        // if this function needs parameters
        if (target.parameter) {
          const firstPart = Array.isArray(restPart)
            ? restPart[0].trim()
            : condition
          const firstHalfParenthese = firstPart.indexOf('(')
          const secHalfParenthese = firstPart.lastIndexOf(')')
          if (
            firstHalfParenthese > -1 &&
            secHalfParenthese > -1 &&
            firstHalfParenthese < secHalfParenthese
          ) {
            const parameters = firstPart.slice(
              firstHalfParenthese + 1,
              secHalfParenthese
            )
            const paramArray = outputParser.splitParameters(parameters)
            const paramInputArray = []
            for (const paramIdx in target.parameter) {
              const parameter = paramArray[paramIdx]
                ? paramArray[paramIdx].trim()
                : ''
              paramInputArray.push({
                ...target.parameter[paramIdx],
                value: target.parameter[paramIdx].quoteParameter
                  ? parameter.replace(/\'|\"/g, '')
                  : parameter,
              })
            }
            finalCondition.inputItem = finalCondition.inputItem || {}
            finalCondition.inputItem.parameter = paramInputArray
          }
        }
        groupConditions.push(finalCondition)
      })
      group.conditions = groupConditions
    })
    // start nesting groups
    for (let index = groups.length - 1; index > 0; index--) {
      for (
        let upperGroupIndex = index - 1;
        upperGroupIndex > -1;
        upperGroupIndex--
      ) {
        if (
          groups[upperGroupIndex].start < groups[index].start &&
          groups[upperGroupIndex].end > groups[index].end
        ) {
          groups[upperGroupIndex].groups = groups[upperGroupIndex].groups || []
          groups[upperGroupIndex].groups.unshift(groups[index])
          if (groups[index].upperGroupLogicalOperator)
            groups[upperGroupIndex].logicalOperator =
              groups[index].upperGroupLogicalOperator
          break
        }
      }
    }
    return {
      groups: [groups[0]],
    }
  }
  $scope.logicalOperators = [
    {
      name: 'and',
      displayName: 'and',
    },
    {
      name: 'or',
      displayName: 'or',
    },
  ]

  $scope.sourceTypes = [
    {
      name: 'Objects',
      displayName: 'Objects',
      sourceFields: [
        {
          name: 'Custom',
          displayName: 'Custom',
          type: t('Custom'),
        },
        {
          name: '$statusCode',
          displayName: '$statusCode()',
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('Extended'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
            {
              name: 'greater',
              displayName: '>',
            },
            {
              name: 'less',
              displayName: '<',
            },
            {
              name: 'greaterEquals',
              displayName: '>=',
            },
            {
              name: 'lessEquals',
              displayName: '<=',
            },
          ],
        },
        {
          name: '$httpVerb',
          displayName: '$httpVerb()',
          hasComparisonOperator: true,
          hasInputItem: true,
          quoteInput: true,
          type: t('Extended'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          enum: [
            {
              name: 'get',
              displayName: 'GET',
            },
            {
              name: 'put',
              displayName: 'PUT',
            },
            {
              name: 'post',
              displayName: 'POST',
            },
            {
              name: 'delete',
              displayName: 'DELETE',
            },
            {
              name: 'options',
              displayName: 'OPTIONS',
            },
            {
              name: 'head',
              displayName: 'HEAD',
            },
            {
              name: 'patch',
              displayName: 'PATCH',
            },
          ],
        },
        {
          name: '$operationPath',
          displayName: '$operationPath()',
          hasComparisonOperator: true,
          hasInputItem: true,
          quoteInput: true,
          type: t('Extended'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$operationID',
          displayName: '$operationID()',
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('Extended'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$header',
          displayName: '$header()',
          parameter: [
            {
              name: '',
              placeholder: '',
              quoteParameter: true,
            },
          ],
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('Extended'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$storageType',
          displayName: '$storageType()',
          parameter: [
            {
              name: '',
              placeholder: '',
            },
          ],
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('Extended'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$number',
          displayName: '$number()',
          parameter: [
            {
              name: 'number',
              type: 'string',
              placeholder: 'Enter the number',
            },
          ],
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('Numeric'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
            {
              name: 'greaterThan',
              displayName: '>',
            },
            {
              name: 'greaterThanOrEqual',
              displayName: '>=',
            },
            {
              name: 'lessThan',
              displayName: '<',
            },
            {
              name: 'lessThanOrEqual',
              displayName: '<=',
            },
          ],
        },
        {
          name: '$xpath',
          displayName: '$xpath()',
          parameter: [
            {
              name: 'JSONata path to payload',
              type: 'string',
              placeholder: 'JSONata path',
            },
            {
              name: 'XPath string',
              type: 'string',
              placeholder: 'XPath string',
            },
          ],
          hasComparisonOperator: true,
          canIgnoreInput: true,
          hasInputItem: true,
          type: t('Extended'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$string',
          displayName: '$string()',
          parameter: [
            {
              name: '',
              placeholder: '',
            },
          ],
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$length',
          displayName: '$length()',
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
          ],
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$contains',
          displayName: '$contains()',
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Pattern',
            },
          ],
          type: t('String'),
        },
        {
          name: '$split',
          displayName: '$split()',
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Separator',
            },
          ],
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$join',
          displayName: '$join()',
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Separator',
            },
          ],
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$match',
          displayName: '$match()',
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Pattern',
            },
            {
              name: '',
              placeholder: 'Limit',
            },
          ],
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$replace',
          displayName: '$replace()',
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Pattern',
            },
            {
              name: '',
              placeholder: 'Replacement',
            },
            {
              name: '',
              placeholder: 'Limit',
            },
          ],
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$trim',
          displayName: '$trim()',
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
          ],
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$substringBefore',
          displayName: '$substringBefore()',
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Chars',
            },
          ],
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$substringAfter',
          displayName: '$substringAfter()',
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Chars',
            },
          ],
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$substring',
          displayName: '$substring()',
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Start',
            },
            {
              name: '',
              placeholder: 'Length',
            },
          ],
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$pad',
          displayName: '$pad()',
          parameter: [
            {
              name: '',
              placeholder: 'String',
            },
            {
              name: '',
              placeholder: 'Width',
            },
            {
              name: '',
              placeholder: 'Char',
            },
          ],
          canIgnoreInput: true,
          hasComparisonOperator: true,
          hasInputItem: true,
          type: t('String'),
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
        },
        {
          name: '$uppercase',
          displayName: '$uppercase()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: '',
            },
          ],
          type: t('String'),
        },
        {
          name: '$lowercase',
          displayName: '$lowercase()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: '',
            },
          ],
          type: t('String'),
        },
        {
          name: '$sort',
          displayName: '$sort()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: t('Array'),
            },
          ],
          type: t('Array'),
        },
        {
          name: '$append',
          displayName: '$append()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: `${t('Array')}1`,
            },
            {
              name: '',
              placeholder: `${t('Array')}2`,
            },
          ],
          type: t('Array'),
        },
        {
          name: '$count',
          displayName: '$count()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: t('Array'),
            },
          ],
          type: t('Array'),
        },
        {
          name: '$reverse',
          displayName: '$reverse()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: t('Array'),
            },
          ],
          type: t('Array'),
        },
        {
          name: '$zip',
          displayName: '$zip()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: `${t('Array')}1, ${t('Array')}2, ...`,
            },
          ],
          type: t('Array'),
        },
        {
          name: '$sum',
          displayName: '$sum()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: t('Array'),
            },
          ],
          type: t('Aggregation'),
        },
        {
          name: '$max',
          displayName: '$max()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: t('Array'),
            },
          ],
          type: t('Aggregation'),
        },
        {
          name: '$min',
          displayName: '$min()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: t('Array'),
            },
          ],
          type: t('Aggregation'),
        },
        {
          name: '$average',
          displayName: '$average()',
          hasComparisonOperator: true,
          hasInputItem: true,
          comparisonOperators: [
            {
              name: 'Equals',
              displayName: '=',
            },
            {
              name: 'notEquals',
              displayName: '!=',
            },
          ],
          parameter: [
            {
              name: '',
              placeholder: t('Array'),
            },
          ],
          type: t('Aggregation'),
        },
        {
          name: '$boolean',
          displayName: '$boolean()',
          parameter: [
            {
              name: '',
              placeholder: '',
            },
          ],
          type: t('Boolean'),
        },
        {
          name: '$exists',
          displayName: '$exists()',
          parameter: [
            {
              name: '',
              placeholder: '',
            },
          ],
          type: t('Boolean'),
        },
      ],
    },
  ]

  const defaultSearchContainer = {
    groups: [
      {
        logicalOperator: {
          name: 'and',
          displayName: 'and',
        },
        conditions: [{}],
      },
    ],
  }

  $scope.commit = function() {
    $mdDialog.hide($scope.res)
  }

  $scope.cancel = function() {
    $mdDialog.cancel()
  }
  let initialContainer
  if (expression) {
    try {
      initialContainer = conditionParser(expression)
    } catch (e) {
      initialContainer = {
        groups: [
          {
            logicalOperator: {
              name: 'and',
              displayName: 'and',
            },
            conditions: [
              {
                sourceField: {
                  name: 'Custom',
                  displayName: 'Custom',
                },
                inputItem: {
                  custom: expression,
                },
              },
            ],
          },
        ],
      }
    }
  } else {
    initialContainer = defaultSearchContainer
  }
  $scope.searchContainer = initialContainer
}

angular
  .module('apiconnect-assembly')
  .controller('MainController', [
    '$scope',
    '$mdDialog',
    'outputParser',
    '$sce',
    'expression',
    'swaggerDocument',
    MainController,
  ])
