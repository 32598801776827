// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2020, 2022
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

import configOptions from 'Apps/configOptions'

/**
 * Generate docs link
 * @example
 * docsService()
 * returns 'https://www.ibm.com/docs/SSMNED_10.0.5'
 * @example
 * docsService('gateways/learn_more')
 * returns 'https://www.ibm.com/docs/SSMNED_10.0.5/gateways/learn_more'
 * @example
 * formFactor = 'ibm-cloud'
 * docsService('gateways/learn_more')
 * returns 'https://www.ibm.com/docs/SSMNED_v10cloud/gateways/learn_more'
 * @example
 * docsService('gateways/learn_more', 'SSMNED_v20')
 * returns 'https://www.ibm.com/docs/SSMNED_v20/gateways/learn_more'
 * @param {String} [relativePath] relative path to documentation, after source
 * @param {String} [src] Docs source id
 * @returns {String} Returns absolute path to specific doc
 */
const docsService = (relativePath = '', src) => {
  const {formFactor, docsSrcUrl = ''} = configOptions()
  let formFactorSrc = ''

  if (docsSrcUrl.length) {
    return `${docsSrcUrl}/${relativePath}`
  }

  switch (formFactor) {
    case 'ibm-cloud':
      formFactorSrc = 'SSMNED_v10cloud'
      break
    case 'aws':
      formFactorSrc = 'SSMNED_10eaas'
      break
    default:
      formFactorSrc = 'SSMNED_10.0.5'
  }

  return `https://www.ibm.com/docs/${src || formFactorSrc}/${relativePath}`
}

export default docsService
