// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Le catalogue n'a pas été mis à jour`,
  [`Catalog has been updated`]: `Le catalogue a été mis à jour`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `Le produit brouillon {draftProduct} doit faire référence à une ou plusieurs API`,
  [`Number of Calls`]: `Nombre d'appels`,
  [`Changes will apply to newly published APIs only`]: `Les modifications s'appliqueront uniquement aux API nouvellement publiées`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Des {label} sont incompatibles en raison de leur type de passerelle différent vers cette API.`,
  [`{label} is required`]: `{label} est obligatoire`,
  [`Application is required`]: `L'application est obligatoire`,
  [`Plan is required`]: `Le plan est obligatoire`,
  [`Gateway is required`]: `La passerelle est obligatoire`,
  [`Consumer Org is required`]: `L'organisation de type consommateur est obligatoire`,
  [`LDAP ATTRIBUTE NAME`]: `NOM D'ATTRIBUT LDAP`,
  [`Use the build in Test Application (sandbox only)`]: `Utiliser la génération dans l'application de test (bac à sable uniquement)`,
  [`Select an Application`]: `Sélectionnez une application`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Utiliser l'application de test intégrée (bac à sable uniquement) - désactivée lorsqu'un catalogue autre que bac à sable est choisi`,
  [`Choose an existing Application`]: `Choisir une application existante`,
  [`Use JWT for gateway authentication to analytics service`]: `Utiliser le jeton JWT pour l'authentification de passerelle auprès du service d'analyse`,
  [`Select an application to consume the API`]: `Sélectionner une application pour utiliser l'API`,
  [`Use the default built-in Sandbox Catalog'`]: `Utiliser le catalogue de bac à sable intégré par défaut'`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Remarque : Ce catalogue sera utilisé comme portée pour toutes les autres préférences. Si vous modifiez ce paramètre, vous devrez ajuster d'autres préférences.`,
  [`Select a Consumer Org`]: `Sélectionner une organisation de type consommateur`,
  [`Select the Consumer Org your generated application will belong to`]: `Sélectionner l'organisation de type consommateur à laquelle appartient votre application générée`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Remarque : Cette option ne peut être modifiée que lors de l'utilisation de l'application de test de bac à sable intégrée car les applications existantes appartiennent déjà à une organisation de type consommateur.`,
  [`Generate auto product`]: `Générer un produit automatiquement`,
  [`Select a product to associate the current API with`]: `Sélectionner un produit auquel associer l'API en cours`,
  [`Associate the current API to a selected product`]: `Associer l'API en cours à un produit sélectionné`,
  [`API management`]: `Gestion des API`,
  [`Select the API management instance you would like this API to be published to.`]: `Sélectionnez l'instance de gestion de l'API dans laquelle vous souhaitez publier cette API.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Remarque : Cette instance sera utilisée comme portée pour toutes les autres préférences. Si vous modifiez ce paramètre, vous devrez ajuster d'autres préférences.`,
  [`Provider organization`]: `Organisation de type fournisseur`,
  [`Select the provider organization you would like this API to be published to.`]: `Sélectionnez l'organisation de fournisseur dans laquelle vous souhaitez publier cette API.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Remarque : Cette organisation sera utilisée comme portée pour toutes les autres préférences. Si vous modifiez ce paramètre, vous devrez ajuster d'autres préférences.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Remarque : Cet espace sera utilisé comme portée pour toutes les autres préférences. Si vous modifiez ce paramètre, vous devrez ajuster d'autres préférences.`,
  [`Selected a draft product`]: `Sélection d'un produit brouillon`,
  [`Use any available gateway service`]: `Utiliser un service de passerelle disponible`,
  [`Select a compatible gateway service`]: `Sélectionner un service de passerelle compatible`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Remarque : Ce service de passerelle sera utilisé comme portée pour toutes les autres préférences. Les stratégies et d'autres contenus spécifiques au service de passerelle seront chargés en fonction de ce paramètre.`,
  [`Select a gateway`]: `Sélectionner une passerelle`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Remarque : cette option est désactivée lorsque l'option 'Produit existant' est sélectionnée et utilise à la place le plan par défaut pour le produit généré`,
  [`Select a Plan`]: `Sélectionner un plan`,
  [`Generate subscription`]: `Générer un abonnement`,
  [`A subscription will be created between the selected Product plan and Application`]: `Un abonnement est créé entre le plan de produit et l'application sélectionnés`,
  [`Apply a rate limit to the generated product`]: `Appliquer une limite de débit au produit généré`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Appliquez une limite de débit au plan par défaut des produits générés. Cette option est désactivée lorsque l'option 'Produit existant' est sélectionnée et utilise à la place la limite de débit définie dans le plan sélectionné.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `Le protocole LDAP (Lightweight Directory Access Protocol) est un protocole Internet permettant d'accéder à des services d'informations de répertoire distribué et d'assurer leur maintenance. Si un service LDAP d'entreprise est activé, configurez-le comme ressource fournissant l'authentification d'utilisateur. Si vous souhaitez également proposer l'intégration d'utilisateurs pour les nouveaux utilisateurs du portail de développeur, vous devez remplir la section Mappage d'attribut pour activer un service LDAP inscriptible. Dans cette section, cochez la case Géré par l'utilisateur et indiquez le mappage de vos noms d'attributs LDAP source aux valeurs API Connect cible.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `L'activation de l'approbation automatique des tâches permet à des tâches d'être approuvées par leur émetteur ainsi que par des collaborateurs. Cette option peut être pratique lorsque d'autres valideurs ne sont pas disponibles, mais permet en réalité d'ignorer les vérifications effectuées par un autre utilisateur.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `L'intégration de la facturation permet de connecter API Connect à un compte dans un système de facturation d'abonnement tiers qui gère les clients, leurs modes de règlement, la facturation et les abonnements actifs.`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Un objet Fournisseur OAuth natif indique les paramètres pour les opérations de traitement OAuth telles que la génération et la validation de jetons OAuth. Un objet Fournisseur OAuth peut être référencé par une définition de sécurité OAuth pour protéger une API. Lorsqu'un fournisseur OAuth natif est utilisé, les opérations OAuth sont effectuées en mode natif par API Connect. Chaque objet Fournisseur OAuth comporte une API de sauvegarde. Ici, votre configuration met automatiquement à jour le document Swagger de l'API. Vous pouvez éditer le document Swagger en accédant à la page Editeur d'API. Lorsqu'une API publiée fait référence à un objet Fournisseur OAuth, l'API de support est automatiquement mise à disposition dans la passerelle.`,
  [`About token management`]: `A propos de la gestion des jetons`,
  [`About user security`]: `A propos de la sécurité relative aux utilisateurs`,
  [`APIs or products`]: `API ou produits`,
  [`ATM is not deployed, please contact your administrator`]: `ATM n'est pas déployé, veuillez contacter votre administrateur.`,
  [`Application state change approvals will be enabled`]: `Les approbations de changement d'état des applications seront activées`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Définissez les paramètres à utiliser pour extraire les données d'identification des utilisateurs de l'application, authentifier leur identité et accorder une autorisation.`,
  [`See console for details.`]: `Consultez la console pour obtenir des détails.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `Le service de gestionnaire de certificat sélectionné ne gère aucun certificat.`,
  [`The selected product is not published to the Sandbox catalog`]: `Le produit sélectionné n'est pas publié dans le catalogue pour bac à sable`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `Le produit sélectionné n'étant pas publié dans le catalogue pour bac à sable, il n'est pas possible d'abonner l'application.`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `l'interface de ligne de commande IBM Cloud pour votre plateforme (le cas échéant).`,
  [`(Only supported by TLS 1.3)`]: `(Pris en charge uniquement par TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `L'instruction "@listSize" sur cette zone définit les zones dimensionnées qui ne renvoient pas de listes.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `L'instruction "@listSize" sur cette zone définit les zones dimensionnées non définies sur le type de retour de la zone.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `L'instruction "@listSize" sur cette zone définit les arguments de tranche non définis sur la zone.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `L'instruction "@listSize" sur cette zone définit les arguments de tranche dont les types ne sont pas "Int" ou "Int!".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `L'instruction "@listSize" sur cette zone définit plusieurs arguments de tranche non nuls.`,
  [`"{name}" (Role) has been created`]: `"{name}" (rôle) a été créé.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (rôle) a été mis à jour.`,
  [`API ({api}) has been created.`]: `L'API ({api}) a été créée.`,
  [`API ({api}) has been renamed.`]: `L'API ({api}) a été renommée.`,
  [`API ({api}) has been updated.`]: `L'API ({api}) a été mise à jour.`,
  [`API ({api}) has been deleted.`]: `L'API ({api}) a été supprimée.`,
  [`API ({api}) has been published.`]: `L'API ({api}) a été publiée.`,
  [`API ({api}) has validation error.`]: `L'API ({api}) comporte une erreur de validation.`,
  [`API ({api}) has validation errors.`]: `L'API ({api}) comporte des erreurs de validation.`,
  [`API version`]: `Version d'API`,
  [`APIM data version`]: `Version des données APIM`,
  [`APIM schema update date`]: `Date de mise à jour du schéma APIM`,
  [`APIM schema version`]: `Version du schéma APIM`,
  [`APIM target data version`]: `Version des données cible APIM`,
  [`APIM target schema version`]: `Version du schéma cible APIM`,
  [`APIM update date`]: `Date de mise à jour APIM`,
  [`Product ({product}) has been created.`]: `Le produit ({product}) a été créé.`,
  [`Product ({product}) has been updated.`]: `Le produit ({product}) a été mis à jour.`,
  [`Product ({product}) has been delete.`]: `Le produit ({product}) a été supprimé.`,
  [`Product ({product}) has been published.`]: `Le produit ({product}) a été publié.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) a été {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) n'a pas été {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} après sa mise en file d'attente)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} après son envoi)`,
  [`(none)`]: `(aucun)`,
  [`(optional)`]: `(facultatif)`,
  [`*Base endpoint list empty`]: `*Liste de noeuds finaux de base vide`,
  [`*Must be a valid url`]: `*Doit être une adresse URL valide`,
  [`*System will also send an email notification to the requester.`]: `*Le système enverra également une notification par courrier électronique au demandeur.`,
  [`+ redact from...`]: `+ occulter de...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. Vous pouvez également gérer le cycle de vie de ce produit à l'intérieur du catalogue.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x suivi de 64 caractères hexadécimaux`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Possède et administre les organisations de type consommateur d'API 2) Gère les communautés de développeurs d'applications 3) Est le créateur des définitions d'API et de produit 4) Gère le cycle de vie des produits d'API`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Possède et administre les organisations de type fournisseur d'API 2) Gère les communautés de développeurs d'applications 3) Est le créateur des définitions d'API et de produit 4) Gère le cycle de vie des produits d'API`,
  [`5 Most Active APIs`]: `Les 5 API les plus actives`,
  [`5 Most Active Products`]: `Les 5 produits les plus actifs`,
  [`5 most active APIs`]: `Les 5 API les plus actives`,
  [`5 most active Products`]: `Les 5 produits les plus actifs`,
  [`503 Service Unavailable`]: `503  Service Unavailable (Service non disponible)`,
  [`A request to the server was made without any credentials.`]: `Une demande auprès du serveur a été établie sans données d'identification.`,
  [`A server error occurred`]: `Une erreur serveur s'est produite`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Un profil de client TLS configure la chaîne de certificats et la suite de chiffrement utilisées par API Connect lors de la connexion en tant que client à d'autres systèmes.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Un profil de serveur TLS configure la chaîne de certificats et la suite de chiffrement utilisées par API Connect lors de la présentation des noeuds finaux du serveur à d'autres systèmes.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Un catalogue héberge une collection de produits d'API qui, une fois publiés, sont visibles sur le portail de développeur associé`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Un catalogue héberge une collection de produits d'API qui, une fois publiés, sont visibles sur le portail de développeur associé.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Un catalogue représente une collection de produits d'API gérés. Les produits qui sont publiés sont visibles des consommateurs sur le portail de développeur associé au catalogue.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Un certificat est requis pour sécuriser la connexion entre API Connect et le domaine du noeud final de gestion de passerelle. Des certificats sont également requis pour sécuriser les connexions entre la passerelle et les domaines qu'elle gère. Tous les certificats doivent être stockés dans un service de gestionnaire de certificat. Le service fournit un référentiel sécurisé pour les certificats et aide à prévenir les pannes en vous envoyant des notifications lorsque les certificats sont sur le point d'expirer. Pour commencer,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Valeur par défaut qui sera utilisée si les entrées de la mappe ne sont pas définies.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Un service de passerelle représente un ensemble de serveurs de passerelle ou de conteneurs qui hébergent des API publiées et fournissent les noeuds finaux d'API utilisés par les applications client. Les passerelles exécutent des appels de proxy d'API vers des systèmes de back end et appliquent des stratégies d'API, notamment l'identification client, la sécurité et la limitation de débit.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Un hôte passerelle publie des API et fournit les noeuds finaux d'API utilisés par les applications client. Les passerelles exécutent des appels de proxy d'API vers des systèmes de back end et appliquent des stratégies d'API, notamment l'identification client, la sécurité et la limitation de débit.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Une cible de migration peut être définie sur un produit source afin de déterminer la cible de migration pour les abonnements.  La cible de migration inclut le mappage des plans du produit source aux plans du produit cible.  La cible de migration est visible dans le portail de développeur pour qu'elle soit communiquée aux abonnés du produit source.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Ensemble de stratégies OAuth modulaires. Permet d'effectuer toutes les étapes du protocole OAuth/OpenID Connect en une seule stratégie (par défaut) ou en plusieurs stratégies pour n'effectuer qu'une ou un nombre “n” d'étapes à la fois pour un contrôle plus précis. Les entrées et sorties de chaque étape reposent sur des variables de contexte documentées. Ajoutez ou supprimez les composants OAuth pris en charge selon les besoins.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Un portail ne peut pas être créé si aucun service de portail n'est disponible. Prenez contact avec votre administrateur de cloud pour de l'aide.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Une organisation de type fournisseur est une équipe qui possède des API et les plans et produits associés. Elle requiert un propriétaire qui dispose de tous les droits. Les propriétaires sont choisis parmi les membres.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Une organisation de type fournisseur inclut des utilisateurs qui gèrent des API, des produits, des plans, des catalogues, des organisations de type consommateur et d'autres ressources connexes. Vous pouvez utiliser des organisations de type fournisseur pour regrouper des utilisateurs et des ressources de gestion d'API par projet, département, étape de cycle de vie, etc. Pour gérer et affecter les rôles qui gouvernent les privilèges utilisateur, accédez à IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Expression JavaScript simple dont la résolution est la valeur de sortie. Il peut s'agir d'une chaîne statique ("ma chaîne statique") ou d'une expression impliquant des entrées mappées ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `ACTION`,
  [`ANALYTICS`]: `ANALYSE`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `Administrateur d'API`,
  [`API CONNECT VALUE`]: `VALEUR API CONNECT`,
  [`API assemble`]: `Assemblage d'API`,
  [`API base endpoints`]: `Noeuds finaux de base de l'API`,
  [`API base URL`]: `URL de base de l'API`,
  [`API base URLs`]: `URL de base de l'API`,
  [`API Calls`]: `Appels API`,
  [`API Connect UI`]: `Interface utilisateur API Connect`,
  [`API Connect services`]: `Services API Connect`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect requiert un serveur de messagerie pour l'envoi d'invitations et de liens d'activation à des membres, et à d'autres fins.`,
  [`API Connect verify token`]: `Jeton de vérification API Connect`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `Licence API Designer`,
  [`API Designer credentials`]: `Données d'identification d'API Designer`,
  [`API Designer licence`]: `Licence API Designer`,
  [`API Designer does not currently support WSDL`]: `API Designer ne prend pas en charge WSDL actuellement`,
  [`API editor`]: `Editeur d'API`,
  [`API Endpoint`]: `Noeud final d'API`,
  [`API Endpoint Base`]: `Base du noeud final d'API`,
  [`API Endpoint for Unenforced APIs`]: `Noeud final d'API pour les API non appliquées`,
  [`API Endpoint for unenforced APIs`]: `Noeud final d'API pour les API non appliquées`,
  [`API Endpoints`]: `Noeuds finaux d'API`,
  [`API Error`]: `Erreur d'API`,
  [`API gateway`]: `passerelle d'API`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `passerelles d'API`,
  [`API Gateways`]: `Passerelles d'API`,
  [`API Invocation Endpoint`]: `Noeud final d'appel API`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `L'API n'est pas publiée. Le test d'API (Essayer) dans l'explorateur ne sera pas activé.`,
  [`API Key`]: `Clé d'API`,
  [`API key`]: `Clé d'API`,
  [`API key secret`]: `Secret de clé d'API`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `Registre d'utilisateurs local d'API Manager`,
  [`API Manager URL`]: `URL d'API Manager`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `Paramètres de chemin d'API`,
  [`API Security Definition`]: `Définition de sécurité d'API`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `Le test d'API (Essayer) dans l'explorateur ne sera pas activé pour cette API car elle n'est pas appliquée.`,
  [`API Setup`]: `Configuration d'API`,
  [`API Subscription`]: `Abonnement d'API`,
  [`API endpoint`]: `Noeud final d'API`,
  [`API endpoint base`]: `Base du noeud final d'API`,
  [`API endpoint for unenforced APIs`]: `Noeud final d'API pour les API non appliquées`,
  [`API endpoints`]: `Noeuds finaux d'API`,
  [`API error`]: `Erreur d'API`,
  [`API invocation endpoint`]: `Noeud final d'appel API`,
  [`API is not enforced on the API gateway.`]: `L'API n'est pas appliquée sur la passerelle d'API.`,
  [`API path parameters`]: `Paramètres de chemin d'API`,
  [`API security definition`]: `Définition de sécurité d'API`,
  [`API setup`]: `Configuration d'API`,
  [`API subscription`]: `Abonnement d'API`,
  [`API Update`]: `Mise à jour d'API`,
  [`API user registries`]: `Registres d'utilisateurs d'API`,
  [`API and product generated successfully!`]: `L'API et le produit ont été générés !`,
  [`API deletion failed`]: `Echec de suppression de l'API`,
  [`API does not exist!`]: `L'API n'existe pas.`,
  [`API from existing REST service`]: `API du service REST existant`,
  [`API from existing SOAP service`]: `API du service SOAP existant`,
  [`API has been updated.`]: `L'API a été mise à jour.`,
  [`API has been updated`]: `L'API a été mise à jour`,
  [`API has not been created!`]: `L'API n'a pas été créée.`,
  [`API has not been updated!`]: `L'API n'a pas été mise à jour.`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `Priorité de l'API (utilisez les noeuds finaux personnalisés déterminés dans les définitions OpenAPI)`,
  [`API properties`]: `Propriétés de l'API`,
  [`API protection source`]: `Source de protection d'API`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `L'API référencée depuis le produit n'existe pas. Commencez par créer ou importer l'API.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `Les structures de charge de réponse et de demande d'API sont composées à l'aide de définitions de schéma OpenAPI.`,
  [`API subscription request approved for app {{appName}}`]: `La demande d'abonnement à l'API a été approuvée pour l'application {appName}`,
  [`API subscription request denied for app {{appName}}`]: `La demande d'abonnement à l'API a été refusée pour l'application {appName}`,
  [`API subscription request received for app {{appName}}`]: `La demande d'abonnement à l'API a été reçue pour l'application {appName}`,
  [`API type`]: `Type d'API`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `L'API avec la clé ''{key}'' sous la propriété 'apis' n'existe pas et ne peut pas être répertoriée. Vérifiez l'onglet Source.`,
  [`APIs`]: `API`,
  [`APIs and products`]: `API et produits`,
  [`APIs included in the plan.`]: `API incluses dans le plan.`,
  [`API Key is created`]: `La clé d'API est créée`,
  [`API Key is deleted`]: `La clé d'API est supprimée`,
  [`API Key is successfully created`]: `La clé d'API a été créée`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `Le délai d'attente de la clé d'API est de **{seconds} secondes ({time})**`,
  [`API key timeout`]: `Délai d'attente de la clé d'API`,
  [`API Key timeout has been changed`]: `Le délai d'attente de la clé d'API a été modifié`,
  [`Application type`]: `Type d'application`,
  [`Associated analytics service`]: `Service d'analyse associé`,
  [`AVAILABILITY`]: `DISPONIBILITE`,
  [`AVAILABILITY ZONE`]: `ZONE DE DISPONIBILITE`,
  [`About`]: `A propos de`,
  [`About audit setting`]: `A propos du paramètre d'audit`,
  [`About Authentication URL user registry`]: `A propos du registre d'utilisateurs de l'URL d'authentification`,
  [`About availability zones`]: `A propos des zones de disponibilité`,
  [`About catalogs`]: `A propos des catalogues`,
  [`About consumer organization owners`]: `A propos des propriétaires d'organisation de type consommateur`,
  [`About consumer organizations`]: `A propos des organisations de type consommateur`,
  [`About developer portals`]: `A propos des portails de développeur`,
  [`About email servers`]: `A propos des serveurs de messagerie`,
  [`About Endpoints`]: `A propos des noeuds finaux`,
  [`About gateways`]: `A propos des passerelles`,
  [`About gateway visibility`]: `A propos de la visibilité de la passerelle`,
  [`About Gateway Processing Status`]: `A propos du statut de traitement de la passerelle`,
  [`About introspection`]: `A propos de l'introspection`,
  [`About Keystores`]: `A propos des magasins de clés`,
  [`About LDAP`]: `A propos de LDAP`,
  [`About local user registries`]: `A propos des registres d'utilisateurs locaux`,
  [`About metadata`]: `A propos des métadonnées`,
  [`About Migration Target`]: `A propos de la cible de migration`,
  [`About migration targets`]: `A propos des cibles de migration`,
  [`About native OAuth provider`]: `A propos du fournisseur OAuth natif`,
  [`About OpenID Connect`]: `A propos d'OpenID Connect`,
  [`About replacing a product`]: `A propos du remplacement d'un produit`,
  [`About Role defaults`]: `A propos des rôles par défaut`,
  [`About roles`]: `A propos des rôles`,
  [`About scopes`]: `A propos des portées`,
  [`About Set Migration Targets`]: `A propos des cibles de migration définies`,
  [`About spaces`]: `A propos des espaces`,
  [`About Subscription`]: `A propos de l'abonnement`,
  [`About superseding a product`]: `A propos de la substitution d'un produit`,
  [`About TLS server profile`]: `A propos du profil de serveur TLS`,
  [`About TLS client profiles`]: `A propos des profils de client TLS`,
  [`About TLS server profiles`]: `A propos des profils de serveur TLS`,
  [`About third party OAuth provider`]: `A propos du fournisseur OAuth tiers`,
  [`About tokens`]: `A propos des jetons`,
  [`About Transfer Ownership`]: `A propos du transfert de propriété`,
  [`About Transferring Ownership`]: `A propos du transfert de propriété`,
  [`About Truststores`]: `A propos des magasins de clés de confiance`,
  [`About adding a member`]: `A propos de l'ajout d'un membre`,
  [`About adding provider organization member`]: `A propos de l'ajout d'un membre de l'organisation de type fournisseur`,
  [`About adding members to a Space`]: `A propos de l'ajout de membres à un espace`,
  [`About adding members to a catalog`]: `A propos de l'ajout de membres à un catalogue`,
  [`About changing owners`]: `A propos du changement de propriétaire`,
  [`About enabling gateway services`]: `A propos de l'activation des services de passerelle`,
  [`About inviting a member`]: `A propos de l'invitation d'un membre`,
  [`About inviting catalog members`]: `A propos de l'invitation de membres de catalogue`,
  [`About inviting provider organization members`]: `A propos de l'invitation de membres d'organisation de type fournisseur`,
  [`About inviting space members`]: `A propos de l'invitation de membres de l'espace`,
  [`About keystores`]: `A propos des magasins de clés`,
  [`About managing Space membership`]: `A propos de la gestion de l'appartenance à un espace`,
  [`About migrating subscriptions`]: `A propos de la migration des abonnements`,
  [`About provider organizations`]: `A propos des organisations de type fournisseur`,
  [`About roles and role defaults`]: `A propos des rôles et des rôles par défaut`,
  [`About roles in spaces`]: `A propos des rôles dans les espaces`,
  [`About selecting gateway services`]: `A propos de la sélection de services de passerelle`,
  [`About send message`]: `A propos de l'envoi de message`,
  [`About sending messages`]: `A propos de l'envoi de messages`,
  [`About the analytics service`]: `A propos du service d'analyse`,
  [`About the DNS Scheme`]: `A propos du schéma DNS`,
  [`About the Gateway Service`]: `A propos du service de passerelle`,
  [`About the Portal Service`]: `A propos du service de portail`,
  [`About the product lifecycle`]: `A propos du cycle de vie des produits`,
  [`About the provider organization owner`]: `A propos du propriétaire d'organisation de type fournisseur`,
  [`About this Cloud`]: `A propos de ce cloud`,
  [`About transferring ownership`]: `A propos du transfert de propriété`,
  [`About truststores`]: `A propos des magasins de clés de confiance`,
  [`About visibility`]: `A propos de la visibilité`,
  [`About visibility and subscribability`]: `A propos de la visibilité et de la possibilité d'abonnement`,
  [`Accept`]: `Accept`,
  [`Access Code`]: `Code d'accès`,
  [`Access Token`]: `Jeton d'accès`,
  [`Access URL through Secure Gateway`]: `Accéder à l'URL via la passerelle sécurisée`,
  [`Access code`]: `Code d'accès`,
  [`Access code is not chosen in supported grant type.`]: `Le code d'accès n'a pas été choisi parmi les types d'octroi pris en charge.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Accédez à l'URL via une passerelle sécurisée. La passerelle est définie lorsque l'API passe de l'état mise en préproduction à l'étape publiée.`,
  [`Access token TTL has been changed`]: `La durée de vie du jeton d'accès a été modifiée`,
  [`Access token time to live`]: `Durée de vie du jeton d'accès`,
  [`Access token time-to-live`]: `Durée de vie du jeton d'accès`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Le jeton d'accès expire dans **{seconds} secondes ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Des jetons d'accès sont accordés à l'application client pour l'autoriser à accéder à des ressources pour le compte de l'utilisateur de l'application.  Des jetons d'actualisation sont envoyés au client afin qu'il obtienne un nouveau jeton d'accès lorsque celui en cours arrive à expiration ou n'est plus valide, ou qu'il obtienne des jetons d'accès supplémentaires de portée identique ou moindre. Vous pouvez également indiquer la durée de validité du consentement fourni par la combinaison des accès et des jetons d'actualisation.`,
  [`Access tokens time to live (seconds)`]: `Durée de vie des jetons d'accès (en secondes)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Un jeton d'accès, un jeton d'ID et un jeton temporaire peuvent être affectés à l'un des magasins de clés existants. L'historique de l'affectation du magasin de clés est conservé.`,
  [`Account`]: `Compte`,
  [`Account has been updated.`]: `Le compte a été mis à jour.`,
  [`Account password has been changed.`]: `Le mot de passe du compte a été modifié.`,
  [`account-approved`]: `compte-approuvé`,
  [`account-denied`]: `compte-refusé`,
  [`account-pending-approval`]: `approbation-compte-en-attente`,
  [`Action`]: `Action`,
  [`Activate API`]: `Activer l'API`,
  [`Activate API|button text`]: `Activer l'API`,
  [`Activate API for testing|title`]: `Activer l'API`,
  [`Activation link`]: `Lien d'activation`,
  [`Active`]: `Actif`,
  [`Activity log`]: `Journal d'activité`,
  [`Activity log API setting is not available for this API's gateway type.`]: `Le paramètre API du journal d'activité n'est pas disponible pour ce type de passerelle d'API.`,
  [`Active loading indicator`]: `Indicateur de chargement actif`,
  [`Add`]: `Ajouter`,
  [`Add allowlist`]: `Ajouter une liste autorisée`,
  [`Add a new billing integration in Resources`]: `Ajouter une nouvelle intégration de facturation dans Ressources`,
  [`Add API user registries`]: `Ajouter des registres d'utilisateurs d'API`,
  [`Add APIs to Product`]: `Ajout d'API à un produit`,
  [`Add another cloud`]: `Ajouter un autre cloud`,
  [`Add billing integration`]: `Ajouter une intégration de facturation`,
  [`Add Burst Limit`]: `Ajouter une limite de diffusion en rafale`,
  [`Add Ciphers for TLS client profile`]: `Ajouter des chiffrements pour le profil de client TLS`,
  [`Add Ciphers for TLS server profile`]: `Ajouter des chiffrements pour le profil de serveur TLS`,
  [`Add Condition`]: `Ajouter une condition`,
  [`Add Consumer Role`]: `Ajouter un rôle de consommateur`,
  [`Add Count Limit`]: `Ajouter un nombre limite`,
  [`Add Credential`]: `Ajouter des données d'identification`,
  [`Add domain`]: `Ajouter un domaine`,
  [`Add credential`]: `Ajouter des données d'identification`,
  [`Add Extension`]: `Ajouter une extension`,
  [`Add GraphQL schema`]: `Ajouter un schéma GraphQL`,
  [`Add HTTP Endpoint`]: `Ajouter un noeud final HTTP`,
  [`Add group`]: `Ajouter un groupe`,
  [`Add JSON schema`]: `Ajouter un schéma JSON`,
  [`Add Keystore to TLS client profile`]: `Ajouter un magasin de clés au profil de client TLS`,
  [`Add Keystore to TLS server profile`]: `Ajouter un magasin de clés au profil de serveur TLS`,
  [`Add member`]: `Ajouter un membre`,
  [`Add New Role for Consumer organization`]: `Ajouter un nouveau rôle pour l'organisation de type consommateur`,
  [`Add operation`]: `Ajouter une opération`,
  [`Add Parameters`]: `Ajouter des paramètres`,
  [`Add Policy`]: `Ajouter une stratégie`,
  [`Add Rate limit`]: `Ajouter une limite de débit`,
  [`Add Rate Limit`]: `Ajouter une limite de débit`,
  [`Add role`]: `Ajouter un rôle`,
  [`Add Syslog TCP Endpoint`]: `Ajouter un noeud final syslog TCP`,
  [`Add Syslog TLS Endpoint`]: `Ajouter un noeud final syslog TLS`,
  [`Add Syslog UDP Endpoint`]: `Ajouter un noeud final syslog UDP`,
  [`Add XML schema`]: `Ajouter un schéma XML`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Ajoutez un noeud final de passerelle que vous souhaitez rendre disponible pour les appels vers les API dans ce catalogue.`,
  [`Add a different Cloud Connection.`]: `Ajouter une autre connexion au cloud.`,
  [`Add a member from the user registry`]: `Ajouter un membre à partir du registre d'utilisateurs`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Ajouter un utilisateur à l'organisation d'administration et lui affecter les rôles requis`,
  [`Add a user to the provider organization, and assign the required roles`]: `Ajouter un utilisateur à l'organisation de type fournisseur et lui affecter les rôles requis`,
  [`Add a user to the Catalog, and assign the required roles`]: `Ajouter un utilisateur au catalogue et lui affecter les rôles requis`,
  [`Add a user to the Space, and assign the required roles`]: `Ajouter un utilisateur à l'espace et lui affecter les rôles requis`,
  [`Add action`]: `Ajouter une action`,
  [`Add additional client ID/client secret pairs`]: `Ajouter des paires ID client/Valeur confidentielle de client supplémentaires`,
  [`Add blocklist`]: `Ajouter une liste rouge`,
  [`Add case`]: `Ajouter un cas`,
  [`Add catch`]: `Ajouter une accroche`,
  [`Add default catch`]: `Ajouter une accroche par défaut`,
  [`Add destination`]: `Ajouter une destination`,
  [`Add entry`]: `Ajouter une entrée`,
  [`Add group failed.`]: `Echec de l'ajout de groupe.`,
  [`Add input`]: `Ajouter une entrée`,
  [`Add media type`]: `Ajouter un type de support`,
  [`Add member failed`]: `L'ajout de membre a échoué`,
  [`Add object`]: `Ajouter un objet`,
  [`Add otherwise`]: `Ajouter une condition Sinon`,
  [`Add output`]: `Ajouter une sortie`,
  [`Add outputs for operation...`]: `Ajouter des sorties pour l'opération...`,
  [`Add parameters for operation...`]: `Ajouter des paramètres pour l'opération...`,
  [`Add parameters to this API`]: `Ajout de paramètres à cette API`,
  [`Add plans to product`]: `Ajoutez des plans au produit`,
  [`Add plans to this product`]: `Ajoutez des plans à ce produit`,
  [`Add schema`]: `Ajouter un schéma`,
  [`Add scopes for this OAuth provider.`]: `Ajoutez des portées pour ce fournisseur OAuth.`,
  [`Add scopes to allow access to`]: `Ajoutez des portées pour autoriser l'accès à`,
  [`Add scopes to allow access to.`]: `Ajoutez les portées auxquelles autoriser l'accès.`,
  [`Add tags and external documentation details for this API`]: `Ajout de balises et de détails sur la documentation externe pour cette API`,
  [`Add to`]: `Ajouter à`,
  [`Add to existing product`]: `Ajouter à un produit existant`,
  [`Add/Remove APIs`]: `Ajouter/Retirer des API`,
  [`AddProperties`]: `AjouterPropriétés`,
  [`Added APIs`]: `API ajoutées`,
  [`Added rate limit`]: `Limite de débit ajoutée`,
  [`Added rate limits`]: `Limites de débit ajoutées`,
  [`Adding an API to a product for publishing`]: `Ajout d'une API à un produit en vue de sa publication`,
  [`Additional support`]: `Support supplémentaire`,
  [`Additional properties`]: `Propriétés supplémentaires`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `En outre, envisagez de supprimer le registre associé, le registre d'utilisateurs du catalogue {name}, s'il n'est pas utilisé.`,
  [`Address`]: `Adresse`,
  [`Admin DN`]: `Nom distinctif d'administrateur`,
  [`Admin organization invitation timeout`]: `Délai d'expiration d'invitation relative à l'organisation d'administration`,
  [`Admin organization invitation timeout has been changed`]: `Le délai d'expiration d'invitation relative à l'organisation d'administration a été modifié`,
  [`Admin password`]: `Mot de passe d'administrateur`,
  [`Admin request reset password`]: `Mot de passe de réinitialisation de la demande administrateur`,
  [`Admin reset password`]: `Mot de passe de réinitialisation administrateur`,
  [`Admin Sign In`]: `Connexion de l'administrateur`,
  [`Admin add catalog failed`]: `Echec d'ajout du catalogue par l'administrateur`,
  [`Admin add space failed`]: `Echec d'ajout de l'espace par l'administrateur`,
  [`Administer consumer organizations`]: `Administrer les organisations de type consommateur`,
  [`Administers the API consumer organization`]: `Administre l'organisation de type consommateur d'API`,
  [`Administers the API provider organization`]: `Administre l'organisation de type fournisseur d'API`,
  [`Administers the admin organization`]: `Administre l'organisation d'administration.`,
  [`Administers the admin topology`]: `Administre la topologie d'administration`,
  [`Administers the app developer organization`]: `Administre l'organisation de développeurs d'applications`,
  [`Administers the catalog`]: `Administre le catalogue`,
  [`Administers the cloud topology`]: `Administre la topologie de cloud`,
  [`Administers the space`]: `Administre l'espace`,
  [`Administration management console`]: `Console de gestion d'administration`,
  [`Administrator`]: `Administrateur`,
  [`Advanced analytics configuration`]: `Configuration de l'analyse avancée`,
  [`Advanced features`]: `Fonctions avancées`,
  [`Advanced scope check`]: `Vérification de portée avancée`,
  [`Advanced scope check after Token Validation`]: `Vérification de portée avancée après la validation de jeton`,
  [`Advanced scope check before Token Generation`]: `Vérification de portée avancée avant la génération de jeton`,
  [`Aggregation`]: `Agrégation`,
  [`Agree`]: `J'accepte`,
  [`All`]: `Tout`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Tous les développeurs authentifiés dans les organisations de type consommateur qui se sont inscrits sur le portail de développeur pourront voir ce produit.`,
  [`All consumer organizations will be able to see this product.`]: `Toutes les organisations de type consommateur pourront voir ce produit.`,
  [`Allowlist`]: `Liste autorisée`,
  [`Allow Chunked Uploads`]: `Autoriser les téléchargements par bloc`,
  [`Allow chunked uploads`]: `Autoriser les téléchargements par bloc`,
  [`Allow "plain" challenge method`]: `Autoriser la méthode de défi "en clair"`,
  [`Allow renegotiation`]: `Autorisation de renégociation`,
  [`Allow case sensitive usernames`]: `Autoriser les noms d'utilisateur sensibles à la casse`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Autorisez les clients à injecter une extension SNI avec le nom d'hôte souhaité dans l'établissement de liaison initial avec le serveur.`,
  [`Allow connection to be renegotiated`]: `Autorisez la renégociation de la connexion`,
  [`Allow default introspection`]: `Autoriser l'introspection par défaut`,
  [`Allow insecure server connections`]: `Autorisation des connexions serveur non sécurisées`,
  [`Allow null value`]: `Autoriser la valeur NULL`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Autoriser access_token/refresh_token à envoyer une redirection 302 pour la déconnexion`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Toutes les recommandations ont été appliquées. Il n'existe pas d'avertissement pour votre schéma.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Autorisez le test des opérations de l'API à l'aide des outils de test dans le portail de développeur.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Autorisez la connexion avec des données d'identification non sécurisées ou dont la sécurité est faible`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Autorisez la connexion avec des données d'identification non sécurisées ou dont la sécurité est faible.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Autoriser le fournisseur OIDC externe à communiquer avec le portail de développeur, non avec le gestionnaire d'API.`,
  [`Allow to manage this user registry`]: `Autoriser la gestion de ce registre d'utilisateurs`,
  [`Allow to manage users under this user registry`]: `Autoriser la gestion des utilisateurs sous ce registre d'utilisateurs`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Permet d'intégrer automatiquement des utilisateurs quand APIC est présenté avec un jeton généré par l'émetteur`,
  [`Already have an account?`]: `Vous avez déjà un compte ?`,
  [`Also transfer ownership of owned Spaces`]: `Transférer également la propriété des espaces détenus`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Toujours extraire les données utilisateur du noeud final userinfo s'il est activé`,
  [`Always output the root element`]: `Toujours générer l'élément racine`,
  [`Always output the root element.`]: `Toujours générer l'élément racine`,
  [`Always required`]: `Toujours obligatoire`,
  [`Always use userinfo endpoint`]: `Toujours utiliser le noeud final userinfo`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Une API de fournisseur OAuth contient les noeuds finaux d'autorisation et de jeton d'un flux OAuth. Configurez vos fournisseurs OAuth ici ; ensuite, lorsque vous créerez une API sécurisée par OAuth, vous pourrez sélectionner le fournisseur requis.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Un fournisseur OAuth contient les noeuds finaux d'autorisation et de jeton d'un flux OAuth ; sélectionnez le fournisseur OAuth à utiliser pour sécuriser votre API avec OAuth.`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Une application est répertoriée ici lorsqu'un développeur l'abonne à un plan dans l'espace afin de pouvoir appeler les API associées ; vous pouvez suspendre une application de développeur pour l'empêcher d'accéder à vos API, et vous pouvez également créer vos propres applications. [En savoir plus]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Une URL d'authentification pointe vers un fournisseur d'authentification tiers qui sert de registre d'utilisateurs. Elle permet l'intégration à un registre d'utilisateurs tiers autre que LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Une URL d'authentification fournit un mécanisme simple pour authentifier des utilisateurs sur un fournisseur d'identité personnalisé.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Une erreur s'est produite lors de la communication avec le sous-système de passerelles.`,
  [`An organization is required.`]: `Une organisation est obligatoire.`,
  [`An unknown error occurred.`]: `Une erreur inconnue s'est produite.`,
  [`Analytics`]: `Analyse`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Service Analytics Insights'`,
  [`Analytics Details`]: `Détails de l'analyse`,
  [`Analytics destinations`]: `Destinations de l'analyse`,
  [`Analytics Director keystore`]: `Fichier de clés d'Analytics Director`,
  [`Analytics Service`]: `Service d'analyse`,
  [`Analytics Service URL`]: `URL du service d'analyse`,
  [`Analytics Service {arg} has been removed.`]: `Le service d'analyse {arg} a été retiré.`,
  [`Analytics details`]: `Détails de l'analyse`,
  [`Analytics service`]: `Service d'analyse`,
  [`Analytics service details`]: `Détails du service d'analyse`,
  [`Analytics service URL`]: `URL du service d'analyse`,
  [`Analytics service {arg} has been removed.`]: `Le service d'analyse {arg} a été retiré.`,
  [`Analytics client TLS client profile`]: `Profil de client TLS du client d'analyse`,
  [`Analytics client keystore`]: `Magasin de clés du client d'analyse`,
  [`Analytics client truststore`]: `Magasin de clés de confiance du client d'analyse`,
  [`Analytics ingestion TLS client profile`]: `Profil de client TLS d'ingestion d'analyse`,
  [`Analytics ingestion keystore`]: `Magasin de clés d'ingestion d'analyse`,
  [`Analytics ingestion truststore`]: `Magasin de clés de confiance d'ingestion d'analyse`,
  [`Analytics service {title} has been created.`]: `Le service d'analyse {title} a été créé`,
  [`Analytics service {title} has been updated.`]: `Le service d'analyse {title} a été mis à jour`,
  [`The selected consumer organization is`]: `L'organisation de type consommateur sélectionnée est`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Les services d'analyse sont configurés et les données d'analyse sont déchargées en externe`,
  [`Analyze API usage and performance`]: `Analyser l'utilisation et les performances d'API`,
  [`Anonymous bind`]: `Liaison anonyme`,
  [`Another user registry`]: `Un autre registre d'utilisateurs`,
  [`api-administrator`]: `administrateur d'API`,
  [`Api-Analytics`]: `Analyse-API`,
  [`Api-Drafts`]: `Brouillons-API`,
  [`App`]: `Application`,
  [`App name`]: `Nom de l'application`,
  [`App-Analytics`]: `Analyse-API`,
  [`App-Approval`]: `Approbation-application`,
  [`App-Dev`]: `Développement-application`,
  [`App-analytics`]: `Analyse-API`,
  [`App-dev`]: `Développement-API`,
  [`app-lifecycle-approved`]: `cycle-de-vie-application-approuvé`,
  [`app-lifecycle-cancelled`]: `cycle-de-vie-application-annulé`,
  [`app-lifecycle-denied`]: `cycle-de-vie-application-refusé`,
  [`app-lifecycle-pending`]: `cycle-de-vie-application-en-attente`,
  [`app-lifecycle-request`]: `demande-cycle-de-vie-application`,
  [`app-reinstated`]: `application-rétablie`,
  [`app-suspended`]: `application-suspendue`,
  [`Application`]: `Application`,
  [`Application Authentication`]: `Authentification de l'application`,
  [`Application Authentication Source`]: `Source d'authentification de l'application`,
  [`Application lifecycle`]: `Cycle de vie des applications`,
  [`Application approval task for upgrading application`]: `Tâche d'approbation d'application pour la mise à niveau de l'application`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Tâche d'approbation d'application pour la mise à niveau de l'application {taskname} vers la production demandée par {username} ({orgname})`,
  [`Application authentication`]: `Authentification des applications`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Les développeurs d'applications abonnent initialement leurs applications à un ou plusieurs Plans à l'aide du portail de développeur. Toutefois, pour un plan sélectionné, vous pouvez migrer les abonnements à une application vers un plan dans un autre produit.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Les développeurs d'applications abonnent initialement leurs applications à un ou plusieurs plans à l'aide du portail de développeur. Toutefois, pour un plan sélectionné, vous pouvez migrer les abonnements à une application vers un plan dans un autre produit.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Les développeurs d'applications abonnent une application à un plan pour pouvoir appeler les API de ce plan.`,
  [`Application is being created. Please Wait`]: `Application en cours de création. Veuillez patienter`,
  [`Application scope check`]: `Vérification de portée d'application`,
  [`Applications`]: `Applications`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Les applications qui existaient avant l'activation du cycle de vie des applications resteront à l'état de production`,
  [`Applied security`]: `Sécurité appliquée`,
  [`Apply`]: `Appliquer`,
  [`Apply all`]: `Appliquer tout`,
  [`Apply all suggestions`]: `Appliquer toutes les suggestions`,
  [`Apply analysis configuration`]: `Appliquer la configuration d'analyse`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Appliquez la stratégie invoke pour appeler un service existant depuis votre opération. La stratégie peut être utilisée avec des données JSON ou XML et peut être appliquée plusieurs fois dans votre assemblage.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Appliquez la stratégie de mise à niveau de socket Web pour établir une connexion de socket Web afin d'appeler un service existant depuis votre opération.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Appliquez cette stratégie pour appeler une API proxy dans votre opération, surtout si vous devez appeler un contenu volumineux. Une seule stratégie de proxy par assemblage est autorisée à être appelée.`,
  [`Apply to selected`]: `Appliquer à la sélection`,
  [`Apply type analysis configuration`]: `Appliquer la configuration d'analyse de type`,
  [`Approval History`]: `Historique des approbations`,
  [`Approval tasks`]: `Tâches d'approbation`,
  [`Approvals`]: `Approbations`,
  [`Approve`]: `Approuver`,
  [`Archive`]: `Archiver`,
  [`Archive Product`]: `Archivage du produit`,
  [`Archive|permission`]: `Archiver`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Voulez-vous vraiment effacer les contraintes des éléments que vous avez sélectionnés ?`,
  [`Are you sure you want to delete API?`]: `Voulez-vous vraiment supprimer l'API ?`,
  [`Are you sure you want to delete Product?`]: `Voulez-vous vraiment supprimer le produit ?`,
  [`Are you sure you want to delete a catalog?`]: `Voulez-vous vraiment supprimer un catalogue ?`,
  [`Are you sure you want to delete a space?`]: `Voulez-vous vraiment supprimer un espace ?`,
  [`Are you sure you want to delete this application?`]: `Voulez-vous vraiment supprimer cette application ?`,
  [`Are you sure you want to delete this billing integration?`]: `Voulez-vous vraiment supprimer cette intégration de facturation ?`,
  [`Are you sure you want to delete this field?`]: `Voulez-vous vraiment supprimer cette zone ?`,
  [`Are you sure you want to delete this policy?`]: `Voulez-vous vraiment supprimer cette règle ?`,
  [`Are you sure you want to delete this type?`]: `Voulez-vous vraiment supprimer ce type ?`,
  [`Are you sure you want to disable spaces?`]: `Confirmez-vous la désactivation des espaces ?`,
  [`Are you sure you want to disable custom email sender information?`]: `Voulez-vous vraiment désactiver les informations personnalisées sur l'expéditeur de courrier électronique ?`,
  [`Are you sure you want to disable custom notification templates?`]: `Voulez-vous vraiment désactiver les modèles de notification personnalisés ?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Voulez-vous vraiment cesser d'utiliser le portail de développeur IBM ? Toutes les personnalisations de portail pour le catalogue seront supprimées.`,
  [`Are you sure you want to enable spaces?`]: `Confirmez-vous l'activation des espaces ?`,
  [`Are you sure you want to enable custom email sender information?`]: `Voulez-vous vraiment activer les informations personnalisées sur l'expéditeur de courrier électronique ?`,
  [`Are you sure you want to enable custom notification templates?`]: `Voulez-vous vraiment activer les modèles de notification personnalisés ?`,
  [`Are you sure you want to proceed with delete?`]: `Voulez-vous vraiment procéder à la suppression ?`,
  [`Are you sure you want to publish?`]: `Confirmez-vous la publication ?`,
  [`Are you sure you want to re-stage?`]: `Confirmez-vous la reconstitution en préproduction ?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Voulez-vous vraiment supprimer le produit du catalogue ?`,
  [`Are you sure you want to unassociate?`]: `Confirmez-vous la dissociation ?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Voulez-vous vraiment mettre à jour l'intégration libre-service ?`,
  [`Are you sure?`]: `Confirmez-vous ?`,
  [`Argument`]: `Argument`,
  [`Array`]: `Tableau`,
  [`Assemble`]: `Assemblage`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Assemblez le flux de stratégies requis pour le fournisseur OAuth.`,
  [`Assembly`]: `Assemblage`,
  [`Assembly Execute`]: `Exécution de l'assemblage`,
  [`Assembly saved`]: `Assemblage sauvegardé`,
  [`Assembly burst limits`]: `Limites de diffusion de l'assemblage`,
  [`Assembly count limits`]: `Nombres limite de l'assemblage`,
  [`Assign roles`]: `Affecter des rôles`,
  [`Assign roles to the current owner`]: `Affecter des rôles au propriétaire actuel`,
  [`Associate`]: `Associer`,
  [`Associate analytics service`]: `Association d'un service d'analyse`,
  [`Associate analytics`]: `Associer une analyse`,
  [`Assumed size`]: `Taille supposée`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Erreur d'authentification lors de la tentative d'obtention de l'API à partir de l'URL {url}. Vérifiez votre nom d'utilisateur et votre mot de passe.`,
  [`Audience claim`]: `Réclamation du public`,
  [`Audience Claim`]: `Réclamation du public`,
  [`Audit setting`]: `Paramètre d'audit`,
  [`Audit setting has been changed`]: `Le paramètre d'audit a été modifié`,
  [`Auditing is **{mode}**`]: `L'audit est **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `L'audit est utilisé pour surveiller les appels API et les informations de journal sur les utilisateurs appelants, la durée de chaque appel et l'activité associée à chaque événement.`,
  [`Authenticate Client Method`]: `Authentifier la méthode du client`,
  [`Authenticate password`]: `Authentifier le mot de passe`,
  [`Authenticate user`]: `Authentifier l'utilisateur`,
  [`Authenticate user settings`]: `Authentifier les paramètres utilisateur`,
  [`Authenticate application users using`]: `Authentifier les utilisateurs d'application avec`,
  [`Authenticate using Basic Authentication`]: `Authentification à l'aide de l'authentification de base`,
  [`Authenticate using OAuth`]: `Authentification à l'aide d'OAuth`,
  [`Authenticated`]: `Authentifié`,
  [`Authenticated bind`]: `Liaison authentifiée`,
  [`Authenticated User Name`]: `Nom d'utilisateur authentifié`,
  [`Authentication`]: `Authentification`,
  [`Authentication Bind`]: `Liaison d'authentification`,
  [`Authentication error`]: `Erreur d'authentification`,
  [`Authentication method`]: `Méthode d'authentification`,
  [`Authentication TLS profile`]: `Profil TLS d'authentification`,
  [`Authentication URL`]: `URL d'authentification`,
  [`Authentication URL user registry`]: `Registre d'utilisateurs de l'URL d'authentification`,
  [`Authentication URL user registry has been created.`]: `Le registre d'utilisateurs de l'URL d'authentification a été créé.`,
  [`Authentication URL to use for validation.`]: `URL d'authentification à utiliser pour la validation.`,
  [`Authentication response header credential`]: `Données d'identification d'en-tête de réponse d'authentification`,
  [`Authentication response header pattern`]: `Modèle d'en-tête de réponse d'authentification`,
  [`Authentication type`]: `Type d'authentification`,
  [`Authentication type to use to validate the UsernameToken.`]: `Type d'authentification à utiliser pour valider le jeton de nom d'utilisateur.`,
  [`Authorization`]: `Autorisation`,
  [`Authorization Code`]: `Code d'autorisation`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `L'URL d'autorisation doit correspondre à {endpoint} comme défini par le fournisseur OAuth "{providerTitle}"`,
  [`Authorization endpoint`]: `Noeud final d'autorisation`,
  [`Authorization Request`]: `Demande d'autorisation`,
  [`Authorization URL`]: `URL d'autorisation`,
  [`Authorize`]: `Autoriser`,
  [`Authorize User Settings`]: `Autoriser les paramètres utilisateur`,
  [`Authorize application users using`]: `Autoriser les utilisateurs d'application avec`,
  [`Authorize path`]: `Autoriser le chemin`,
  [`Authors API and product definitions`]: `Est le créateur des définitions d'API et de produit`,
  [`Auto Generate OIDC API Assembly`]: `Auto-générer l'assemblage d'API OIDC`,
  [`Auto onboard`]: `Intégration automatique`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Nom généré automatiquement à utiliser dans les API et les commandes de gestion API Connect.`,
  [`Automation`]: `Automatisation`,
  [`Automation Management Console`]: `Console de gestion de l'automatisation`,
  [`Automation management console`]: `Console de gestion de l'automatisation`,
  [`Availability Zone`]: `Zone de disponibilité`,
  [`Availability zone`]: `Zone de disponibilité`,
  [`Availability zone {arg} has been removed.`]: `La zone de disponibilité {arg} a été retirée.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Les zones de disponibilité vous permettent de regrouper des services API Connect en fonction de vos besoins métier. Par exemple, vous pouvez regrouper des services de passerelle en fonction de la région ou du centre de données dans lequel ils se trouvent.`,
  [`Average response time: {{value}}ms`]: `Temps de réponse moyen : {{value}} ms`,
  [`Average time`]: `Temps moyen`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Evitez les erreurs CORS en utilisant le serveur API Designer comme proxy local.`,
  [`Back`]: `Précédent`,
  [`Back to sign in`]: `Retour à la connexion`,
  [`Back to test`]: `Retour au test`,
  [`Backend type`]: `Type de système de back end`,
  [`Bad gateway`]: `Passerelle incorrecte`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Nom distinctif de base`,
  [`Base Path`]: `Chemin de base`,
  [`Base path`]: `Chemin de base`,
  [`Base endpoint`]: `Noeud final de base`,
  [`Base endpoints`]: `Noeuds finaux de base`,
  [`Base64 encoding`]: `Codage Base64`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `Le codage Base64 pour les invitations et les jetons de réinitialisation de mot de passe est **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Le paramètre de codage Base64 pour le jeton temporaire a été modifié`,
  [`Base URL of API invocation endpoint`]: `URL de base du noeud final d'appel API`,
  [`Basepath root`]: `Racine du chemin de base`,
  [`Basic`]: `De base`,
  [`Basic authentication`]: `Authentification de base`,
  [`Basic authentication password`]: `Mot de passe d'authentification de base`,
  [`Basic authentication request header name`]: `Nom d'en-tête de demande d'authentification de base`,
  [`Basic authentication username`]: `Nom d'utilisateur d'authentification de base`,
  [`Before you begin`]: `Avant de commencer...`,
  [`Before you begin...`]: `Avant de commencer...`,
  [`Between 0 and 10 events are waiting to be processed`]: `De 0 à 10 événement(s) sont en attente de traitement`,
  [`Between 10 and 20 events are waiting to be processed`]: `De 10 à 20 événements sont en attente de traitement`,
  [`Billing`]: `Facturation`,
  [`Billing and Payment`]: `Facturation et règlement`,
  [`Billing has been updated`]: `La facturation a été mise à jour`,
  [`Billing integrations`]: `Intégrations de facturation`,
  [`Billing integration`]: `Intégration de facturation`,
  [`Billing Integration`]: `Intégration de la facturation`,
  [`Billing {title} successfully added.`]: `La facturation {title} a été ajoutée.`,
  [`Billing {title} successfully deleted.`]: `La facturation {title} a été supprimée.`,
  [`Billing {title} successfully updated.`]: `La facturation {title} a été mise à jour.`,
  [`Billing Options`]: `Options de facturation`,
  [`Blocklist`]: `Liste rouge`,
  [`Blocking`]: `Blocage`,
  [`Blocking option`]: `Option de blocage`,
  [`Body`]: `Corps du message`,
  [`Boolean`]: `Booléen`,
  [`Both`]: `Les deux`,
  [`Browse`]: `Parcourir`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Demandes et réponses de l'API de mémoire tampon avant leur traitement sur la passerelle DataPower API Gateway.`,
  [`Buffering`]: `Mise en mémoire tampon`,
  [`Builds and manages apps in the developer organization`]: `Génère et gère des applications dans l'organisation de développeurs`,
  [`Burst Limit Name`]: `Nom limite de diffusion en rafale`,
  [`Burst limits`]: `Limites de diffusion en rafale`,
  [`Burst limit value can't be negative`]: `La valeur de la limite de diffusion en rafale ne peut pas être négative`,
  [`Buy`]: `Acheter`,
  [`but unable to remove because of limitation`]: `mais ne peut pas être supprimée en raison de la limitation`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `En configurant la visibilité et la possibilité d'abonnement d'un produit, vous contrôlez la disponibilité de ses API pour les développeurs d'applications sur le portail de développeur. Vous pouvez contrôler quels développeurs d'applications peuvent voir le produit et quels développeurs d'applications peuvent s'abonner afin d'utiliser les API dans le produit.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `En configurant la visibilité et la possibilité d'abonnement d'un produit, vous contrôlez la disponibilité de ses API pour les développeurs d'applications sur le portail de développeur. Vous pouvez contrôler quels développeurs d'applications peuvent voir le produit et quels développeurs d'applications peuvent s'abonner afin d'utiliser les API dans le produit.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Par défaut, le mappage de plusieurs sources (disons array1 de longueur 2 et array2 de longueur 3) génère un tableau cible contenant cinq éléments (deux en traitant array1 et trois en traitant array2). Si vous préférez que ces règles soient combinées (création d'un tableau contenant deux ou trois éléments avec les propriétés de chaque tableau source combinées), sélectionnez cette option.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Par défaut, ce produit est publié sur tous les services de passerelle concernés. Vous pouvez également publier sur des services de passerelle spécifiques en activant cette option.`,
  [`By payload`]: `Par charge`,
  [`By URL parameter`]: `Par paramètre d"URL`,
  [`Bytes`]: `Octets`,
  [`CALLS`]: `APPELS`,
  [`Calls`]: `Appels`,
  [`CATALOG`]: `CATALOGUE`,
  [`CERTIFICATE NAME`]: `NOM DU CERTIFICAT`,
  [`CIPHER`]: `CHIFFREMENT`,
  [`CIPHER SUITES`]: `SUITES DE CHIFFREMENT`,
  [`CLI`]: `Interface CLI`,
  [`CLI only`]: `Interface CLI uniquement`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `ID client`,
  [`CLOUD ADMINISTRATOR`]: `ADMINISTRATEUR DE CLOUD`,
  [`Consumer organization`]: `Organisation de type consommateur`,
  [`CONSUMER ORGANIZATION / GROUP`]: `ORGANISATION DE TYPE CONSOMMATEUR/GROUPE`,
  [`Consumer-Onboard-Approval`]: `Approbation-intégration-consommateur`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `Bundle d'autorité de certification`,
  [`Cache Key`]: `Clé de cache`,
  [`Cache Time to Live (second)`]: `Durée de vie du cache (seconde)`,
  [`Cache key`]: `Clé de cache`,
  [`Cache Time-To-Live`]: `Durée de vie du cache`,
  [`Cache type`]: `Type de cache`,
  [`Can't find the one you want?`]: `Vous ne trouvez pas celui que vous recherchez ?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Impossible d'envoyer une invitation avec un serveur de messagerie non valide ; vérifiez vos configurations de serveur de messagerie et réessayez`,
  [`Cancel`]: `Annuler`,
  [`Cannot be below {min}`]: `Ne peut pas être inférieur à {min}`,
  [`Cannot be empty`]: `Ne peut pas être vide`,
  [`Cannot delete root type.`]: `Impossible de supprimer le type de racine.`,
  [`Cannot exceed {max}`]: `Ne peut pas être supérieur à {max}`,
  [`Cannot find any APIs for this product`]: `Impossible de trouver des API pour ce produit`,
  [`Cannot find the one you want?`]: `Vous ne trouvez pas celui que vous recherchez ?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Impossible d'utiliser l'instruction "@listSize" sur une zone qui ne renvoie pas de liste lorsqu'elle ne définit pas non plus de zones dimensionnées "sizedFields".`,
  [`Cannot remove built-in scalar types`]: `Impossible de supprimer les types scalaires intégrés`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Impossible de supprimer la requête, la mutation et le type d'opération racine d'abonnement`,
  [`Cannot remove arguments of built-in directives`]: `Impossible de supprimer les arguments des directives intégrées`,
  [`Cannot remove non-null arguments of directives`]: `Impossible de supprimer les arguments non nuls des directives`,
  [`Cannot remove input types of arguments of directives`]: `Impossible de supprimer les types d'entrée des arguments des directives`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Impossible de supprimer les valeurs Enum utilisées par défaut des arguments des directives`,
  [`Cannot remove enum values used as default values of input fields`]: `Impossible de supprimer les valeurs Enum utilisées par défaut des zones d'entrée`,
  [`Cannot remove all fields of an object type`]: `Impossible de supprimer toutes les zones d'un type d'objet`,
  [`Cannot remove all fields of an interface type`]: `Impossible de supprimer toutes les zones d'un type d'interface`,
  [`Cannot remove all slicing arguments`]: `Impossible de supprimer tous les arguments de tranche`,
  [`Cannot remove all input fields of an input object type`]: `Impossible de supprimer toutes les zones d'entrée d'un type d'objet d'entrée`,
  [`Cannot remove all values of enum type`]: `Impossible de supprimer toutes les valeurs de type Enum`,
  [`Cannot remove fields of interfaces`]: `Impossible de supprimer des zones des interfaces`,
  [`Cannot remove non-null input fields`]: `Impossible de supprimer les zones d'entrée non nulles`,
  [`Case`]: `Cas`,
  [`Case sensitive`]: `Sensible à la casse`,
  [`Catalog`]: `Catalogue`,
  [`Catalog defaults`]: `Valeurs par défaut du catalogue`,
  [`Catalog Invite`]: `Invitation pour le catalogue`,
  [`Catalog invite`]: `Invitation de catalogue`,
  [`Catalog invites`]: `Invitations de catalogue`,
  [`Catalog name`]: `Nom de catalogue`,
  [`Catalog owner`]: `Propriétaire de catalogue`,
  [`Catalog owner invitation`]: `Invitation de propriétaire de catalogue`,
  [`Catalog properties`]: `Propriétés de catalogue`,
  [`Catalog summary`]: `Récapitulatif des catalogues`,
  [`Catalog title`]: `Titre de catalogue`,
  [`Catalog user registries`]: `Registres d'utilisateurs de catalogue`,
  [`Catalog by Name`]: `Catalogue par nom`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Priorité du catalogue (utilisez les noeuds finaux personnalisés définis par l'administrateur de catalogue)`,
  [`Catalog {name} created`]: `Le catalogue {name} a été créé`,
  [`Catalog selection`]: `Sélection de catalogue`,
  [`Catalog settings`]: `Paramètres de catalogue`,
  [`Catalog settings have been updated`]: `Les paramètres de catalogue ont été mis à jour`,
  [`Catalog User`]: `Utilisateur de catalogue`,
  [`Catalog Users`]: `Utilisateurs de catalogue`,
  [`Catalog user registry`]: `Registre d'utilisateurs du catalogue`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `Le catalogue avec cycle de vie activé ne peut pas être utilisé dans les préférences de test.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Le catalogue avec cycle de vie et mode de production activé ne peut pas être utilisé dans les préférences de test.`,
  [`Catalogs`]: `Catalogues`,
  [`Catch`]: `Catch`,
  [`Catches`]: `Accroches`,
  [`Categories`]: `Catégories`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Les catégories établissent un affichage hiérarchique des produits d'API sur le portail de développeur. Utilisez la syntaxe suivante : élément_de_niveau_supérieur/élément_de_niveau_suivant/élément_de_niveau_inférieur.`,
  [`Category`]: `Catégorie`,
  [`Certifcate or Shared Secret for Verify`]: `Certificat ou valeur confidentielle partagée pour la vérification`,
  [`Certificate`]: `Certificat`,
  [`Certificate (Optional)`]: `Certificat (facultatif)`,
  [`Certificate management`]: `Gestion des certificats`,
  [`Certificate Manager instance`]: `Instance du gestionnaire de certificat`,
  [`Certificate (optional)`]: `Certificat (facultatif)`,
  [`Certificates`]: `Certificats`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Des certificats sont requis pour sécuriser les connexions entre la passerelle et les domaines qu'elle gère. Tous les certificats doivent être stockés dans un service de gestionnaire de certificat. Le service fournit un référentiel sécurisé pour les certificats et aide à prévenir les pannes en vous envoyant des notifications lorsque les certificats sont sur le point d'expirer. Pour commencer,`,
  [`Certificates details in this keystore`]: `Détails des certificats dans ce magasin de clés`,
  [`Certificates details in this truststore`]: `Détails des certificats dans ce magasin de clés de confiance`,
  [`Change`]: `Modifier`,
  [`Change Consumer Organization owner to an existing user`]: `Remplacement du propriétaire de l'organisation de type consommateur par un utilisateur existant`,
  [`Change Ownership to an Existing User`]: `Changement de la propriété et attribution à un utilisateur existant`,
  [`Change portal service`]: `Changement du service de portail`,
  [`Change owner to existing user`]: `Remplacement du propriétaire par un propriétaire existant`,
  [`Change owner to new user`]: `Remplacement du propriétaire par un nouvel utilisateur`,
  [`Change ownership by inviting a new user`]: `Changer la propriété en invitant un nouvel utilisateur`,
  [`Change ownership to`]: `Changer la propriété`,
  [`Change ownership to a new user via email invitation`]: `Changer la propriété et l'attribuer à un nouvel utilisateur via une invitation par courrier électronique`,
  [`Change ownership to an existing user`]: `Changer la propriété et l'attribuer à un utilisateur existant`,
  [`Change password`]: `Changer le mot de passe`,
  [`Change setup`]: `Changer la configuration`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Les modifications que vous apportez directement à l'API ou à l'assemblage sous-jacent d'un fournisseur OAuth peuvent rendre celui-ci non valide si vous ne mettez pas également à jour les valeurs correspondantes dans la définition du fournisseur OAuth lui-même. Si vous sauvegardez ces modifications, veillez à procéder également aux mises à jour correspondantes dans la définition du fournisseur OAuth.`,
  [`Change the following extra constraints in the schema:`]: `Modifiez les contraintes supplémentaires suivantes dans le schéma :`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `La modification du gestionnaire de certificat supprimera toutes les affectations de bundle d'autorité de certification et de certificat existantes.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Si vous changez le schéma DNS, le format des liens d'URL vers API Connect et le portail de développeur sera également changé. Vous devrez communiquer les nouveaux liens aux utilisateurs. Cliquez sur Annuler si vous ne voulez pas continuer.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `La modification du nom ou de la version d'une API va créer une nouvelle API. Voulez-vous vraiment sauvegarder vos modifications ?`,
  [`Check your email`]: `Consultez vos courriers électroniques`,
  [`Child`]: `Enfant`,
  [`Chinese (Simplified)`]: `Chinois (Simplifié)`,
  [`Chinese (Traditional)`]: `Chinois (Traditionnel)`,
  [`Choose`]: `Sélectionner`,
  [`Choose a`]: `Sélectionner un`,
  [`Choose {docTypeLabel}`]: `Choix de {docTypeLabel}`,
  [`Choose Catalog`]: `Choisir un catalogue`,
  [`Choose a Catalog`]: `Choisir un catalogue`,
  [`Choose a Consumer Org`]: `Choisir une organisation de type consommateur`,
  [`Choose Gateway`]: `Choisir une passerelle`,
  [`Choose a Gateway`]: `Choisir une passerelle`,
  [`Choose Product`]: `Choisir un produit`,
  [`Choose a Product`]: `Choisir un produit`,
  [`Choose Rate Limit`]: `Choisir la limite de débit`,
  [`Choose a Rate Limit`]: `Choisir une limite de débit`,
  [`Choose Application`]: `Choisir l'application`,
  [`Choose an Application`]: `Choisir une application`,
  [`Choose a {docTypeLabel}`]: `Choisissez un {docTypeLabel}`,
  [`Choose a publish destination`]: `Choix d'une destination de publication`,
  [`Choose a billing integration`]: `Choisissez une intégration de facturation`,
  [`Choose a catalog to test within:`]: `Choisissez un catalogue à tester dans :`,
  [`Choose a plan against which to test:`]: `Choisissez un plan pour le test :`,
  [`Choose a product containing this API, or create a new one:`]: `Choisissez un produit contenant cette API ou créez-en un :`,
  [`Choose a user registry`]: `Choisissez un registre d'utilisateurs`,
  [`Choose an application with which to test, or create a new one:`]: `Choisissez une application avec laquelle procéder au test ou créez-en une :`,
  [`Choose an existing application`]: `Choisissez une application existante`,
  [`Choose an existing catalog`]: `Choisissez un catalogue existant`,
  [`Choose an existing product published to your Sandbox catalog`]: `Choisissez un produit existant publié dans votre catalogue pour bac à sable`,
  [`Choose an operation to invoke:`]: `Choisissez une opération à appeler :`,
  [`Choose an option`]: `Choisissez une option`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Choisissez une option afin de déterminer la façon dont la stratégie map traite une sortie de tableau vide. Le choix de l'option Tout (valeur par défaut) générera tous les tableaux vides et les tableaux enfant vides. Le choix de l'option Parent générera uniquement le tableau parent vide. Le choix de l'option Aucun ne générera pas de tableau vide.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Le choix du mode de blocage aura des effets négatifs sur les performances du système`,
  [`Choose existing directory`]: `Choisir un répertoire existant`,
  [`Choose one...`]: `Faire un choix...`,
  [`Choose operations and paths to generate into this API`]: `Choisir les opérations et les chemins d'accès à générer dans cette API`,
  [`Choose paths to generate into this API`]: `Choisissez les chemins à générer dans cette API`,
  [`Choose the DNS scheme`]: `Choix du schéma DNS`,
  [`Choose the permissions for the role.`]: `Choisissez les droits pour le rôle.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Indiquez si vous voulez utiliser des adresses DNS dynamiques ou statiques pour les appels API entrants sur le service de passerelle et pour l'accès au portail de développeur.`,
  [`Cipher`]: `Chiffrement`,
  [`Ciphers`]: `Chiffrements`,
  [`Clear`]: `Effacer`,
  [`Clear constraints`]: `Effacer les contraintes`,
  [`Clear file`]: `Effacer le fichier`,
  [`Clear filters`]: `Effacer les filtres`,
  [`Clear from selected`]: `Effacer la sélection`,
  [`Clear operation filter`]: `Effacer le filtre des opérations`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Cliquez sur un certificat pour afficher les détails concernant l'organisation qui l'a émis et son empreinte digitale.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Cliquez sur le lien ci-dessous pour poursuivre l'autorisation d'API et vous obtiendrez le code d'autorisation pour l'étape 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Si vous cliquez sur le lien ci-dessous, le serveur s'ouvre dans un nouvel onglet. Si le navigateur affiche un problème lié au certificat, vous pouvez l'accepter et revenir ici pour procéder à de nouveaux tests.`,
  [`Click add to add a billing integration.`]: `Cliquez sur Ajouter pour ajouter une intégration de facturation.`,
  [`Click Add to add a gateway extension.`]: `Cliquez sur Ajouter pour ajouter une extension de passerelle.`,
  [`Click **Add domain** to add domains.`]: `Cliquez sur **Ajouter un domaine** pour ajouter des domaines.`,
  [`Click Add to add a new base endpoint.`]: `Cliquez sur Ajouter pour ajouter un nouveau noeud final de base.`,
  [`Click Add to add a new HTTP header.`]: `Cliquez sur Ajouter pour ajouter un nouvel en-tête HTTP.`,
  [`Click Add to add a new member.`]: `Cliquez sur Ajouter pour ajouter un nouveau membre.`,
  [`Click Add to add a new role.`]: `Cliquez sur Ajouter pour ajouter un nouveau rôle.`,
  [`Click Add to add a new scope.`]: `Cliquez sur Ajouter pour ajouter une nouvelle portée.`,
  [`Click Add to add a new consumer organization group.`]: `Cliquez sur Ajouter pour ajouter un nouveau groupe d'organisations de type consommateur.`,
  [`Click Add to create the API Key.`]: `Cliquez sur Ajouter pour créer la clé d'API.`,
  [`Click Create to add the API Key.`]: `Cliquez sur Créer pour ajouter la clé d'API.`,
  [`Click Create to add a new user registry.`]: `Cliquez sur Créer pour ajouter un nouveau registre d'utilisateurs.`,
  [`Click Create user registry to add a new user registry.`]: `Cliquez sur Création d'un registre d'utilisateurs pour ajouter un nouveau registre d'utilisateurs.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Cliquez sur **Créer une organisation de type fournisseur** pour ajouter des organisations de type fournisseur.`,
  [`Click Edit to add OAuth providers.`]: `Cliquez sur Editer pour ajouter des fournisseurs OAuth.`,
  [`Click Edit to add a user registry.`]: `Cliquez sur Editer pour ajouter un registre d'utilisateurs.`,
  [`Click Edit to add an API to the product.`]: `Cliquez sur Editer pour ajouter une API au produit.`,
  [`Click Create to add a property.`]: `Cliquez sur Créer pour ajouter une propriété.`,
  [`Click Edit to add TLS client profiles.`]: `Cliquez sur Editer pour ajouter des profils de client TLS.`,
  [`Click Edit to add user registries.`]: `Cliquez sur Editer pour ajouter des registres d'utilisateurs.`,
  [`Click Add to add a new consumer organization.`]: `Cliquez sur Ajouter pour ajouter une nouvelle organisation de type consommateur.`,
  [`Click Consumer organizations to add one.`]: `Cliquez sur Organisations de type consommateur pour ajouter une organisation de ce type.`,
  [`Click Add to add a new application.`]: `Cliquez sur Ajouter pour ajouter une nouvelle application.`,
  [`Click Add to add parameters.`]: `Cliquez sur Ajouter pour ajouter des paramètres.`,
  [`Click Add to add response.`]: `Cliquez sur Ajouter pour ajouter une réponse.`,
  [`Click Create to add a new TLS client profile.`]: `Cliquez sur Créer pour ajouter un nouveau profil de client TLS.`,
  [`Click Create to add a new keystore.`]: `Cliquez sur Créer pour ajouter un nouveau magasin de clés.`,
  [`Click Create to add a new truststore.`]: `Cliquez sur Créer pour ajouter un nouveau magasin de clés de confiance.`,
  [`Click **Register remote gateway** to add gateways.`]: `Cliquez sur **Enregistrer une passerelle éloignée** pour ajouter des passerelles.`,
  [`Click Register gateways to add new Gateway Services.`]: `Cliquez sur Enregistrement de passerelles pour ajouter de nouveaux services de passerelle.`,
  [`Click Edit to add Gateway Services.`]: `Cliquez sur Editer pour ajouter des services de passerelle.`,
  [`Click Register gateways to add new gateway services.`]: `Cliquez sur Enregistrement de passerelles pour ajouter de nouveaux services de passerelle.`,
  [`Click Edit to add gateway services.`]: `Cliquez sur Editer pour ajouter des services de passerelle.`,
  [`Click Edit to add an email server.`]: `Cliquez sur Editer pour ajouter un serveur de messagerie.`,
  [`Click Create to add a new TLS server profile.`]: `Cliquez sur Créer pour ajouter un nouveau profil de serveur TLS.`,
  [`Click Create to add a new email server.`]: `Cliquez sur Créer pour ajouter un nouveau serveur de messagerie.`,
  [`Click Add to add a new schema definition.`]: `Cliquez sur Ajouter pour ajouter une nouvelle définition de schéma.`,
  [`Click Add to add a new property for this definition.`]: `Cliquez sur Ajouter pour ajouter une nouvelle propriété pour cette définition.`,
  [`Click Add to add a new parameter for this API.`]: `Cliquez sur Ajouter pour ajouter un nouveau paramètre pour cette API.`,
  [`Click Add to add tags and external documentation for this API.`]: `Cliquez sur Ajouter pour ajouter des balises et de la documentation externe pour cette API.`,
  [`Click Add to add a new path.`]: `Cliquez sur Ajouter pour ajouter un nouveau chemin.`,
  [`Click Add to add a property for this API.`]: `Cliquez sur Ajouter pour ajouter une propriété pour cette API.`,
  [`Click here to create a security definition`]: `Cliquez ici pour créer une définition de sécurité.`,
  [`Click Add to add a new security definition.`]: `Cliquez sur Ajouter pour ajouter une nouvelle définition de sécurité.`,
  [`Click Add to add a target service for this API.`]: `Cliquez sur Ajouter pour ajouter un service cible pour cette API.`,
  [`Click Add to add an operation for this path.`]: `Cliquez sur Ajouter pour ajouter une opération pour ce chemin.`,
  [`Click Add to add a path parameter for this path.`]: `Cliquez sur Ajouter pour ajouter un paramètre de chemin pour ce chemin.`,
  [`Click Add to add a new OAuth provider.`]: `Cliquez sur Ajouter pour ajouter un nouveau fournisseur OAuth.`,
  [`Click Add to add a new provider organization.`]: `Cliquez sur Ajouter pour ajouter une nouvelle organisation de type fournisseur.`,
  [`Click Add to add a new API or product.`]: `Cliquez sur Ajouter pour ajouter une nouvelle API ou un nouveau produit.`,
  [`Click Add to add a new API.`]: `Cliquez sur Ajouter pour ajouter une nouvelle API.`,
  [`Click Add to add a new Extension.`]: `Cliquez sur Ajouter pour ajouter une nouvelle extension.`,
  [`Click Add to add new {resource}.`]: `Cliquez sur Ajouter pour ajouter des {resource}.`,
  [`Click to expand`]: `Cliquer pour développer`,
  [`Click to collapse`]: `Cliquer pour réduire`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Cliquez pour apprendre à télécharger et gérer les certificats requis par la passerelle.`,
  [`Client Credentials`]: `Données d'identification de client`,
  [`Client information`]: `Informations relatives au client`,
  [`Client revocation path`]: `Chemin de révocation du client`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Cliquez sur Enregistrement de service pour enregistrer un service. Vous pourrez ainsi tester les noeuds finaux lors de la configuration des autres services.`,
  [`Client secret`]: `Valeur confidentielle du client`,
  [`Client security`]: `Sécurité du client`,
  [`Client Type`]: `Type de client`,
  [`Client authentication method`]: `Méthode d'authentification client`,
  [`Clone`]: `Cloner`,
  [`Close`]: `Fermer`,
  [`Cloud`]: `Cloud`,
  [`Cloud Admin`]: `Administration de cloud`,
  [`Cloud Administrator`]: `Administrateur de cloud`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Administrateur de cloud, Responsable d'organisation, Administrateur de topologie. Le rôle Membre est automatiquement affecté à tous les utilisateurs.`,
  [`Cloud administration`]: `Administration de cloud`,
  [`Cloud Discovery Agent`]: `Agent de reconnaissance de cloud`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Registre d'utilisateurs local du gestionnaire de cloud`,
  [`Cloud-Settings`]: `Paramètres-cloud`,
  [`Cloud settings invitation timeout`]: `Délai d'expiration d'invitation relative aux paramètres cloud`,
  [`Cloud settings invitation timeout has been changed`]: `Le délai d'expiration d'invitation relative aux paramètres cloud a été modifié`,
  [`Code editor`]: `Editeur de code`,
  [`Collect Metadata`]: `Collecter les métadonnées`,
  [`Collect credentials using`]: `Collecter les données d'identification avec`,
  [`Collect metadata`]: `Collecter les métadonnées`,
  [`Combo box`]: `Zone de liste déroulante`,
  [`Community Manager`]: `Gestionnaire de communauté`,
  [`community-manager`]: `gestionnaire de communauté`,
  [`Compatibility`]: `Compatibilité`,
  [`Complex Definitions must be edited in the editor`]: `Les définitions complexes doivent être modifiées dans l'éditeur`,
  [`Compose DN`]: `Composer un nom distinctif`,
  [`Compose UPN`]: `Composer un nom principal d'utilisateur`,
  [`Compose a new REST proxy by defining paths and operations`]: `Ecrivez un nouveau proxy REST en définissant des chemins et des opérations`,
  [`Compose a new product by adding rate limits and plans`]: `Ecrivez un nouveau produit en ajoutant des limites de débit et des plans`,
  [`Compression`]: `Compression`,
  [`Condition`]: `Condition`,
  [`Condition editor`]: `Editeur de conditions`,
  [`Confidential`]: `Confidentiel`,
  [`Configuration`]: `Configuration`,
  [`Configurations`]: `Configurations`,
  [`Configure API endpoint`]: `Configuration du noeud final d'API`,
  [`Configure API Gateway Service`]: `Configuration du service de passerelle d'API`,
  [`Configure analytics service`]: `Configuration du service d'analyse`,
  [`Configure availability zone`]: `Configurer la zone de disponibilité`,
  [`Configure catalog user registries`]: `Configuration de registres d'utilisateurs de catalogue`,
  [`Configure cloud`]: `Configuration du cloud`,
  [`Configure DataPower API gateway service`]: `Configuration du service DataPower API Gateway`,
  [`Configure DataPower gateway service`]: `Configuration du service DataPower Gateway`,
  [`Configure Email Server`]: `Configuration du serveur de messagerie`,
  [`Configure gateway extension`]: `Configuration de l'extension de passerelle`,
  [`Configure portal service`]: `Configuration du service de portail`,
  [`Configure service`]: `Configuration du service`,
  [`Configure topology`]: `Configuration de la topologie`,
  [`Configure XML`]: `Configurer XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Configurez un service DataPower API Gateway pour la sécurisation et l'application des API`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Configurez un service de passerelle DataPower pour la sécurisation et l'application des API`,
  [`Configure a developer portal service for API consumers`]: `Configurez un service de portail de développeur pour les consommateurs d'API`,
  [`Configure advanced features for OIDC settings`]: `Configurer les fonctions avancées pour les paramètres OIDC`,
  [`Configure an analytics service to collect API call data`]: `Configurez un service d'analyse pour collecter les données d'appel API`,
  [`Configure an email server to send invitations and notifications to users.`]: `Configurez un serveur de messagerie pour envoyer des invitations et des notifications aux utilisateurs.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Configurez l'audit pour surveiller les opérations de l'interface utilisateur d'administration, de l'interface utilisateur du gestionnaire, du kit d'outils, de l'API REST de gestion et des utilisateurs du portail. Les journaux de chaque opération d'utilisateur de création, de mise à jour et de suppression sont envoyés aux services de journalisation distants spécifiés ci-dessous.`,
  [`Configure availability zones and services`]: `Configurez les zones de disponibilité et les services`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Configurez la façon dont les URL de noeud final d'API sont composées dans les API publiées`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Configurez le mode d'affichage des noeuds finaux personnalisés dans le portail de développeur`,
  [`Configure mapping`]: `Configurer le mappage`,
  [`Configure properties of the activity log`]: `Configurez les propriétés du journal d'activité`,
  [`Configure settings for token management and revocation.`]: `Configurez les paramètres de gestion et de révocation des jetons.`,
  [`Configure settings to generate and validate tokens.`]: `Configurez les paramètres de génération et de validation des jetons.`,
  [`Configure the API security`]: `Configurez la sécurité d'API`,
  [`Configure the default set of gateway services configured for each catalog`]: `Configurez l'ensemble de valeurs par défaut des services de passerelle défini pour chaque catalogue`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Configurez le portail de développeur qui est utilisé par les développeurs d'applications pour accéder aux API de ce catalogue`,
  [`Configure the gateway services used by default in each catalog`]: `Configurez les services de passerelle utilisés par défaut dans chaque catalogue`,
  [`Configure the gateway extension for the selected gateway`]: `Configurez l'extension de passerelle pour la passerelle sélectionnée`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Configurez la passerelle DataPower API Gateway gérée par IBM et gérez vos propres passerelles éloignées. [En savoir plus]({link})`,
  [`Configure the keystore and upload certificates`]: `Configurez le magasin de clés et téléchargez des certificats`,
  [`Configure the name and email address to be used in the from field of emails`]: `Configurez le nom et l'adresse électronique à utiliser dans la zone De des courriers électroniques`,
  [`Configure the portal service for the catalog`]: `Configurez le service de portail pour le catalogue`,
  [`Configure the role and assign permissions`]: `Configurez le rôle et affectez des droits`,
  [`Configure the security of this API`]: `Configurez la sécurité de cette API`,
  [`Configure the sender name and address to use for email notifications`]: `Configurez le nom et l'adresse de l'expéditeur à utiliser pour les notifications par courrier électronique`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Configurez le nom de l'expéditeur, l'adresse et le serveur de messagerie utilisés pour envoyer des invitations et des notifications aux utilisateurs`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Configurez l'ensemble de rôles à utiliser par défaut lorsqu'une organisation de type consommateur est créée`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Configurez l'ensemble de rôles à utiliser par défaut lorsqu'une organisation de type fournisseur est créée`,
  [`Configure the templates used to invite and notify users`]: `Configurez les modèles utilisés pour inviter et notifier les utilisateurs`,
  [`Configure the truststore and upload certificates`]: `Configurez le magasin de clés de confiance et téléchargez des certificats`,
  [`Configure user authentication using`]: `Configurer l'authentification d'utilisateur à l'aide`,
  [`Configure user authentication using JSON Web Tokens`]: `Configurer l'authentification d'utilisateur à l'aide de jetons Web JSON`,
  [`Configure user authentication using a LDAP provider`]: `Configurer l'authentification d'utilisateur à l'aide d'un fournisseur LDAP`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Configurer l'authentification d'utilisateur à l'aide d'un registre d'utilisateurs local API Connect`,
  [`Configure user authentication using an authentication URL`]: `Configurer l'authentification d'utilisateur à l'aide d'une URL d'authentification`,
  [`Configure user authentication using this user registry type`]: `Configurer l'authentification d'utilisateur à l'aide de ce type de registre d'utilisateurs`,
  [`Configure user registries, OAuth providers and TLS`]: `Configurez des registres d'utilisateurs, des fournisseurs OAuth et TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Configurez des registres d'utilisateurs, le protocole TLS, des fournisseurs OAuth et des serveurs de messagerie`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Configurer le magasin de clés pour le jeton d'accès, le jeton d'ID et le jeton temporaire`,
  [`Configured OAuth Provider`]: `Fournisseur OAuth configuré`,
  [`Configured OAuth Providers`]: `Fournisseurs OAuth configurés`,
  [`Configured OAuth provider`]: `Fournisseur OAuth configuré`,
  [`Configured OAuth provider list has been updated`]: `La liste des fournisseurs OAuth configurés a été mise à jour`,
  [`Configured OAuth provider list has not been updated`]: `La liste des fournisseurs OAuth configurés n'a pas été mise à jour`,
  [`Confirm`]: `Confirmer`,
  [`Confirm deletion`]: `Confirmer la suppression`,
  [`Confirm Deletion of Portal`]: `Confirmer la suppression du portail`,
  [`Confirm visibility settings`]: `Confirmer les paramètres de visibilité`,
  [`Confirm new password`]: `Confirmez le nouveau mot de passe.`,
  [`Confirm password`]: `Confirmer le mot de passe`,
  [`Confirm password should match password`]: `Confirmer la concordance obligatoire des mots de passe`,
  [`Confirmation`]: `Confirmation`,
  [`Congratulations, you are now registered.`]: `Félicitations, vous êtes désormais enregistré.`,
  [`Congratulations, your password has been reset!`]: `Félicitations, votre mot de passe a été réinitialisé !`,
  [`Connect`]: `Connexion`,
  [`Connect to API Connect`]: `Connexion à API Connect`,
  [`Connected to API Manager Product version`]: `Connecté à la version du produit API Manager`,
  [`Connect to Cloud`]: `Se connecter au cloud`,
  [`Connected`]: `Connecté`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Construisez votre définition de schéma en ajoutant des propriétés des types requis. Vous pouvez imbriquer les propriétés.`,
  [`Consider for nesting`]: `A prendre en compte pour l'imbrication`,
  [`Consumed Media Types`]: `Types de support utilisés`,
  [`Consumer`]: `Consommateur`,
  [`Consumer invitation and roles`]: `Invitation des consommateurs et rôles`,
  [`Consumer onboarding`]: `Intégration du consommateur`,
  [`Consumer organizations`]: `Organisations de type consommateur`,
  [`Consumer organizations or groups`]: `Groupes ou organisations de type consommateur`,
  [`Consumer organization {name} created`]: `L'organisation de type consommateur {name} a été créée`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Les organisations de type consommateur sont créées par des développeurs d'applications sur le portail de développeur, mais vous pouvez aussi en créer à l'aide de l'interface utilisateur d'API Manager.`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Les organisations de type consommateur sont créées par des développeurs d'applications sur le portail de développeur, et vous pouvez aussi en créer ici`,
  [`Consumer-Org`]: `Organisation-consommateur`,
  [`Consumers`]: `Consommateurs`,
  [`Consumes`]: `Consomme`,
  [`Contact`]: `Contact`,
  [`Contact information for the owners of the API.`]: `Coordonnées des propriétaires de l'API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Contactez votre administrateur au sujet de la configuration du service d'analyse pour pouvoir afficher vos données ici.`,
  [`Contact your administrator for more information.`]: `Contactez votre administrateur pour plus d'informations.`,
  [`Content`]: `Contenu`,
  [`Content on error`]: `Contenu en cas d'erreur`,
  [`Content type`]: `Type de contenu`,
  [`Context Variable`]: `Variable contextuelle`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Variable d'exécution ou de contexte contenant le jeton Web JSON à valider. Si elle n'est pas définie, la stratégie recherche le jeton Web JSON (JWT) dans request.headers.authorization.`,
  [`Context variable`]: `Variable contextuelle`,
  [`Continue`]: `Continuer`,
  [`Continue on error`]: `Continuer en cas d'erreur`,
  [`Continue with`]: `Continuer avec`,
  [`Continue with:`]: `Continuer avec :`,
  [`Conversion type`]: `Type de conversion`,
  [`Convert to expression`]: `Convertir en expression`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `Copié`,
  [`Copied to clipboard`]: `Copié dans le presse-papiers`,
  [`Copy`]: `Copier`,
  [`Copy Id Headers To Message`]: `Copier les en-têtes d'ID vers le message`,
  [`Copy to clipboard`]: `Copier dans le presse-papiers`,
  [`Copy file path to clipboard`]: `Copier le chemin d'accès au fichier dans le presse-papiers`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Copier dans la sortie l'estimation de coût pour la requête GraphQL`,
  [`Copy the endpoints below to`]: `Copiez les noeuds finaux ci-dessous pour`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Copiez la clé d'API à utiliser. Vous pouvez voir à nouveau cette clé d'API à l'aide de l'icône Afficher.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Informations sur les droits d'auteur`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Corrigez les erreurs dans l'éditeur ci-dessus ou téléchargez à nouveau un fichier valide`,
  [`Cost`]: `Coût`,
  [`Count`]: `Nombre`,
  [`Count Limit Name`]: `Nom du nombre limite`,
  [`Count Limits`]: `Nombres limite`,
  [`Created at`]: `Création`,
  [`Counter`]: `Compteur`,
  [`Create`]: `Créer`,
  [`Create a [Certificate Manager]({link}) service`]: `Créez un service [Gestionnaire de certificat].({link})`,
  [`Create API`]: `Créer une API`,
  [`Create an API from the Develop section.`]: `Créer une API à partir de la section Développement.`,
  [`Create API Connect API Key`]: `Créer une clé d'API API Connect`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Création d'API à partir d'un service GraphQL existant (proxy GraphQL)`,
  [`Create API from existing OpenAPI service`]: `Création d'API à partir d'un service OpenAPI existant`,
  [`Create API from existing WSDL service (REST proxy)`]: `Création d'API à partir d'un service WSDL existant (proxy REST)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Création d'API à partir d'un service WSDL existant (proxy SOAP)`,
  [`Create API from target service`]: `Création d'API à partir d'un service cible`,
  [`Create API from existing REST service`]: `Création d'API à partir d'un service REST existant`,
  [`Create API from existing SOAP service`]: `Création d'API à partir d'un service SOAP existant`,
  [`Create API from existing WSDL service`]: `Création d'API à partir d'un service WSDL existant`,
  [`Create API Key failed`]: `Echec de la création de la clé d'API`,
  [`Create application`]: `Créer une application`,
  [`Create authentication URL user registry`]: `Création du registre d'utilisateurs de l'URL d'authentification`,
  [`Create availability zone`]: `Créer une zone de disponibilité`,
  [`Create Billing`]: `Création de facturation`,
  [`Create catalog`]: `Créer un catalogue`,
  [`Create catalog property`]: `Création d'une propriété de catalogue`,
  [`Create consumer organization`]: `Création d'une organisation de type consommateur`,
  [`Create Credentials`]: `Création de données d'identification`,
  [`Create definition`]: `Créer une définition`,
  [`Create email server`]: `Création d'un serveur de messagerie`,
  [`Create IBM Developer Portal`]: `Créer un portail de développeur IBM`,
  [`Create keystore`]: `Création d'un magasin de clés`,
  [`Create LDAP user registry`]: `Création du registre d'utilisateurs LDAP`,
  [`Create LTPA Key`]: `Création d'une clé LTPA`,
  [`Create local user registry`]: `Création du registre d'utilisateurs local`,
  [`Create native OAuth provider`]: `Création d'un fournisseur OAuth natif`,
  [`Create New GraphQL API`]: `Créer une API GraphQL`,
  [`Create new OpenAPI`]: `Création d'une OpenAPI`,
  [`Create new product`]: `Création d'un produit`,
  [`Create OIDC user registry`]: `Créer un registre d'utilisateurs OIDC`,
  [`Create OpenAPI definition and product definition`]: `Créez une définition OpenAPI et une définition de produit`,
  [`Create operation`]: `Créer une opération`,
  [`Create organization`]: `Créer une organisation`,
  [`Create path`]: `Créer un chemin`,
  [`Create plan`]: `Création d'un plan`,
  [`Create portal`]: `Création d'un portail`,
  [`Create Product`]: `Création d'un produit`,
  [`Create property`]: `Création de propriété`,
  [`Create role`]: `Création d'un rôle`,
  [`Create role default for the Organization`]: `Création d'un rôle par défaut pour l'organisation`,
  [`Create sample user registry`]: `Créer un exemple de registre d'utilisateurs`,
  [`Create security definition`]: `Créer une définition de sécurité`,
  [`Create space`]: `Créer un espace`,
  [`Create subscription`]: `Créer un abonnement`,
  [`Create TLS client profile`]: `Création d'un profil de client TLS`,
  [`Create TLS server profile`]: `Création d'un profil de serveur TLS`,
  [`Create target service`]: `Créer un service cible`,
  [`Create third party OAuth provider`]: `Création d'un fournisseur OAuth tiers`,
  [`Create truststore`]: `Création d'un magasin de clés de confiance`,
  [`Create user registry`]: `Création d'un registre d'utilisateurs`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Créez un proxy GraphQL en fonction d'un service GraphQL`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Créez un proxy REST à partir d'un service cible décrit par OpenAPI`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Créez un proxy REST à partir d'un service cible décrit par WSDL`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Créez un proxy REST à partir d'un service cible décrit par OpenAPI`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Créez un proxy REST à partir du service cible décrit par WSDL`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Créez un proxy REST qui achemine tout le trafic vers un noeud final de service ou une API cible`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Créez un proxy SOAP à partir d'un service cible décrit par WSDL`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Créez un proxy SOAP à partir du service cible décrit par WSDL`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Créez un objet à l'aide du modèle de poignées. Les variables proviennent du contexte.`,
  [`Create a portal site for the catalog`]: `Créez un site de portail pour le catalogue`,
  [`Create a product by importing a product definition`]: `Créez un produit en important une définition de produit`,
  [`Create a security definition`]: `Créez une définition de sécurité`,
  [`Create a subscription`]: `créer un abonnement`,
  [`Create an API by importing an OpenAPI definition`]: `Créez une API en important une définition OpenAPI`,
  [`Create an API that calls an existing SOAP service`]: `Création d'une API qui appelle un service SOAP existant`,
  [`Create and manage API provider organizations and owners`]: `Créez et gérez des propriétaires et organisations de type fournisseur d'API`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Créez et gérez les espaces de votre catalogue. Les espaces permettent de partitionner le catalogue pour prendre en charge différentes équipes de développement de fournisseur d'API.`,
  [`Create and publish`]: `Créer et publier`,
  [`Create and publish APIs and Products`]: `Créer et publier des API et des produits`,
  [`Create and subscribe`]: `Créer et s'abonner`,
  [`Create assembly`]: `Créer un assemblage`,
  [`Create empty parent object for failed mapping`]: `Créer un objet parent vide pour les échec de mappage`,
  [`Create endpoint`]: `Créer un noeud final`,
  [`Create from`]: `Création à partir de`,
  [`Create from Existing WSDL service`]: `Création à partir d'un service WSDL existant`,
  [`Create from File or URL`]: `Création à partir d'un fichier ou d'une URL`,
  [`Create from existing openAPI service`]: `Création à partir d'un service openAPI existant`,
  [`Create gateway extension`]: `Créer une extension de passerelle`,
  [`Create new user`]: `Créer un utilisateur`,
  [`Create new version`]: `Création d'une version`,
  [`Create product using a template`]: `Créer un produit à partir d'un modèle`,
  [`Create provider organization`]: `Créer une organisation de type fournisseur`,
  [`Create required child properties for array objects and mapped optional objects`]: `Créer les propriétés enfant requises pour les objets tableau et les objets facultatifs mappés`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Créé par la configuration du fournisseur OAuth en tant qu'exemple. Assurez-vous de mettre à jour les fournisseurs OAuth utilisant cet exemple avec un registre d'utilisateurs valide.`,
  [`Created new product`]: `Produit créé`,
  [`Create|permission`]: `Créer`,
  [`Create|title`]: `Créer`,
  [`Creating Draft Product`]: `Création d'un produit brouillon`,
  [`Creating a Consumer organization`]: `Création d'une organisation de type consommateur`,
  [`Creating a consumer organization`]: `Création d'une organisation de type consommateur`,
  [`Creating a new Product`]: `Création d'un produit`,
  [`Creative Commons (CC-BY-4.0) license`]: `Licence Creative Commons (CC-BY-4.0)`,
  [`Credential Extraction Method`]: `Méthode d'extraction des données d'identification`,
  [`Credentials for the Sandbox catalog`]: `Données d'identification pour le catalogue pour bac à sable`,
  [`Credentials for the {catalogName} catalog`]: `Données d'identification du catalogue {catalogName}`,
  [`Credential Name`]: `Nom d'identification`,
  [`Credential has been added.`]: `Les donnée d'identification ont été ajoutées.`,
  [`Credential`]: `Données d'identification`,
  [`Credentials`]: `Données d'identification`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Des données d'identification sont requises pour exécuter API Designer et le kit d'outils.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Des données d'identification sont requises pour exécuter le kit d'outils. Placez les fichiers de données d'identification dans le dossier où votre kit d'outils est installé.`,
  [`Credit Card`]: `Carte de crédit`,
  [`Cryptographic Algorithm`]: `Algorithme de cryptographie`,
  [`Currency`]: `Devise`,
  [`Current password`]: `Mot de passe en cours`,
  [`Current password is invalid.`]: `Le mot de passe en cours n'est pas valide.`,
  [`Custom`]: `Personnalisé`,
  [`Custom API URL`]: `URL de l'API personnalisée`,
  [`Custom expression (optional, one per line)`]: `Expression personnalisée (facultative, une par ligne)`,
  [`Custom expression to remove (optional, one per line)`]: `Expression personnalisée à supprimer (facultative, une par ligne)`,
  [`Custom HTML form`]: `Formulaire HTML personnalisé`,
  [`Custom policies`]: `Stratégies personnalisées`,
  [`Custom form TLS profile`]: `Profil TLS de formulaire personnalisé`,
  [`Custom form content security policy`]: `Stratégie de sécurité du contenu du formulaire personnalisé`,
  [`Custom form CSP header value`]: `Valeur d'en-tête CSP de formulaire personnalisé`,
  [`Custom form endpoint`]: `Noeud final de formulaire personnalisé`,
  [`Custom header pattern`]: `Masque d'en-tête personnalisé`,
  [`Custom subscribability is required if custom visibility is selected`]: `Un abonnement personnalisé est requis si la visibilité personnalisée est sélectionnée`,
  [`Customize`]: `Personnaliser`,
  [`Customize email sender information`]: `Personnaliser les informations sur l'expéditeur de courrier électronique`,
  [`Customize notification templates`]: `Personnalisation des modèles de notification`,
  [`Customize the plan API list`]: `Personnaliser la liste des API de plan`,
  [`Czech`]: `Tchèque`,
  [`DATE MODIFIED`]: `DATE DE MODIFICATION`,
  [`DELETE`]: `SUPPRIMER`,
  [`Date created`]: `Date de création`,
  [`Description`]: `Description`,
  [`DETAIL`]: `DETAIL`,
  [`DN Base`]: `Base du nom distinctif`,
  [`DNS Schemes`]: `Schémas DNS`,
  [`Dashboard`]: `Tableau de bord`,
  [`Data encoded form body`]: `Corps de formulaire codé à l'aide de données`,
  [`Data flow in API Connect`]: `Flux de données dans API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (compatible avec la version 5)`,
  [`DataPower API`]: `API DataPower`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway (compatible avec la version 5)`,
  [`DataPower Gateway policies`]: `Stratégies DataPower Gateway`,
  [`Debug`]: `Déboguer`,
  [`Debug rule`]: `Règle de débogage`,
  [`Debug XACML policy`]: `Déboguer la stratégie XACML`,
  [`Dec`]: `Déc`,
  [`Decline`]: `Rejeter`,
  [`Decline and send`]: `Rejeter et envoyer`,
  [`Decode Request Params`]: `Décoder les paramètres de la demande`,
  [`Decrement`]: `Décrémenter`,
  [`Decrypt`]: `Déchiffrement`,
  [`Decrypt Crypto JWK variable name`]: `Déchiffrer le nom de variable JWK crypto`,
  [`Decrypt Crypto Object`]: `Déchiffrer l'objet crypto`,
  [`Decrypt a buffer using the specified certificate`]: `Déchiffrez une mémoire tampon à l'aide du certificat spécifié`,
  [`Default`]: `Par défaut`,
  [`Default behavior`]: `Comportement par défaut`,
  [`Default gateways`]: `Passerelles par défaut`,
  [`default gateway services configured`]: `services de passerelle par défaut configurés`,
  [`Default Availability Zone`]: `Zone de disponibilité par défaut`,
  [`Default Base endpoints`]: `Noeuds finaux de base par défaut`,
  [`Default availability zone`]: `Zone de disponibilité par défaut`,
  [`Default base endpoints`]: `Noeuds finaux de base par défaut`,
  [`Default HTML form`]: `Formulaire HTML par défaut`,
  [`Default limit`]: `Limite par défaut`,
  [`Default OAuth Provider Settings`]: `Paramètres par défaut du fournisseur OAuth`,
  [`Default OAuth Provider Settings Object`]: `Objet de paramètres par défaut du fournisseur OAuth`,
  [`Default Plan`]: `Plan par défaut`,
  [`Default TLS client profile`]: `Profil de client TLS par défaut`,
  [`Default TLS server keystore`]: `Magasin de clés du serveur TLS par défaut`,
  [`Default TLS server profile`]: `Profil de serveur TLS par défaut`,
  [`Default burst-limit`]: `Limite de diffusion en rafale par défaut`,
  [`Default form`]: `Formulaire par défaut`,
  [`Default keystore for access_token signing key`]: `Magasin de clés par défaut pour la clé de signature access_token`,
  [`Default keystore for id_token signing key`]: `Magasin de clés par défaut pour la clé de signature id_token`,
  [`Default keystore for temporary token signing key`]: `Magasin de clés par défaut pour la clé de signature du jeton temporaire`,
  [`Default rate-limit`]: `Limite de débit par défaut`,
  [`Default scopes`]: `Portées par défaut`,
  [`Default table entry`]: `Entrée de table par défaut`,
  [`Default validator endpoint`]: `Noeud final du valideur par défaut`,
  [`Default validator endpoint set by OAuth provider`]: `Noeud final du valideur par défaut défini par le fournisseur OAuth`,
  [`Default value - no extra constraints apply.`]: `Valeur par défaut - aucune contrainte supplémentaire ne s'applique.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Définissez un fournisseur OAuth tiers pour émettre et valider des jetons afin de protéger l'accès à l'API.`,
  [`Define catalog specific values for this property`]: `Définissez des valeurs propres au catalogue pour cette propriété`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Définissez les groupes qui gèrent les API, les produits, les catalogues, les applications et les paramètres associés.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Définit des ensembles de stratégies à exécuter pour des conditions spécifiques. Plusieurs cas peuvent être définis, chacun d'eux pouvant être associé à une condition arbitraire. Seul le premier cas correspondant est exécuté.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Définissez la source de protection de l'API. Au moins une option doit être activée.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `Le serveur d'autorisations OIDC redirigera le code d'autorisation vers les noeuds finaux suivants. Ce noeud final de redirection est requis lorsqu'un client enregistre l'application sur le fournisseur OIDC. Par exemple, si ce registre d'utilisateurs est utilisé par une organisation de type fournisseur, le client doit enregistrer le noeud final de redirection de l'organisation de type fournisseur auprès du fournisseur OIDC.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Définissez les entrées à utiliser dans la mappe. Vous pouvez aussi affecter un titre et une description à la mappe.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Définissez les noeuds finaux de fournisseur OpenID pour envoyer une demande d'authentification à votre fournisseur OpenID`,
  [`Define the outputs to be used in the map.`]: `Définissez les sorties à utiliser dans la mappe.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Définissez le nombre de jours d'essai gratuit et la tarification de plan. Les utilisateurs seront facturés en fonction de leur date d'abonnement.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Indique si le flux continue lorsqu'une erreur spécifique est lancée pendant l'exécution de la stratégie. Si cette option n'est pas sélectionnée, un flux d'interceptions est déclenché.`,
  [`Definition`]: `Définition`,
  [`Definition Object`]: `Objet de définition`,
  [`Definitions`]: `Définitions`,
  [`Delete`]: `Supprimer`,
  [`Delete Application`]: `Suppression de l'application`,
  [`Delete Cloud Connection.`]: `Supprimez la connexion au cloud.`,
  [`Delete Selected APIs`]: `Supprimer les API sélectionnées`,
  [`Delete selected client profiles`]: `Supprimer les profils de client sélectionnés`,
  [`Delete selected keystores`]: `Supprimer les magasins de clés sélectionnés`,
  [`Delete selected mail servers`]: `Supprimer les serveurs de messagerie sélectionnés`,
  [`Delete Selected Products`]: `Supprimer les produits sélectionnés`,
  [`Delete selected truststores`]: `Supprimer les magasins de clés de confiance sélectionnés`,
  [`Delete selected user registries`]: `Supprimer les registres d'utilisateurs sélectionnés`,
  [`Delete Staged Product`]: `Suppression du produit mis en préproduction`,
  [`Delete a space`]: `Supprimer un espace`,
  [`Delete a catalog`]: `Supprimer un catalogue`,
  [`Delete API Key`]: `Supprimer la clé d'API`,
  [`Delete API Key failed`]: `Echec de la suppression de la clé d'API`,
  [`Delete availability zone`]: `Supprimer la zone de disponibilité`,
  [`Delete selected requests`]: `Supprimer les demandes sélectionnées`,
  [`Delete row`]: `Supprimer la ligne`,
  [`Delete|title`]: `Supprimer`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `La suppression d'un catalogue peut échouer s'il existe un grand nombre d'organisations de type consommateur, d'applications, de produits publiés et d'autres ressources.  Pensez à nettoyer ces ressources avant de supprimer le catalogue.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `La suppression d'une organisation de type fournisseur peut échouer s'il existe un grand nombre de catalogues, d'organisations de type consommateur, d'applications, de produits publiés et d'autres ressources. Pensez à nettoyer ces ressources avant de supprimer l'organisation de type fournisseur.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `La suppression d'un espace peut échouer s'il existe un grand nombre d'organisations de type consommateur, d'applications, de produits publiés et d'autres ressources.  Pensez à nettoyer ces ressources avant de supprimer l'espace.`,
  [`Deleting {{name}}...`]: `Suppression de {name}...`,
  [`Deprecate`]: `Déprécier`,
  [`Deprecate product`]: `Dépréciation du produit`,
  [`Deprecated`]: `Déprécié`,
  [`Deprecate|permission`]: `Déprécier`,
  [`Description of gateway`]: `Description de la passerelle`,
  [`Description text for the behaviour of the modal.`]: `Texte descriptif du comportement du modal.`,
  [`Descriptive name`]: `Nom descriptif`,
  [`Design`]: `Conception`,
  [`Designate a current member as owner of a consumer organization.`]: `Désignez un membre en cours comme propriétaire d'une organisation de type consommateur.`,
  [`Designate a current member as owner of a provider organization.`]: `Désignez un membre en cours comme propriétaire d'une organisation de type fournisseur.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Destination`,
  [`Determines which credential-extraction method to use.`]: `Détermine la méthode d'extraction des données d'identification à utiliser.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Déterminez s'il faut inclure ce type ou cette zone ainsi que tous les arguments applicables dans le schéma.`,
  [`Develop`]: `Développement`,
  [`Develop APIs`]: `Développement d'API`,
  [`Develop APIs and products`]: `Développement d'API et de produits`,
  [`Developer`]: `Développeur`,
  [`developer`]: `développeur`,
  [`Developer Portal`]: `Portail de développeur`,
  [`Developer community`]: `Communauté de développeurs`,
  [`Developers can request a state upgrade from development to production`]: `Les développeurs peuvent demander une mise à niveau de l'état de développement vers l'état de production`,
  [`Development`]: `Développement`,
  [`Directive`]: `Directive`,
  [`Director Endpoint`]: `Noeud final directeur`,
  [`Director TLS client profile`]: `Profil client TLS Director`,
  [`Disable`]: `Désactiver`,
  [`Disable Activity log`]: `Désactiver le journal d'activité`,
  [`Disable Self-Service Onboarding`]: `Désactiver l'intégration libre-service`,
  [`Disable spaces`]: `Désactiver les espaces`,
  [`Disable buffering`]: `Désactiver la mise en mémoire tampon`,
  [`Disable Self-Service Onboarding Approval`]: `Désactiver l'approbation d'intégration en libre-service`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Désactiver le remplacement du délai d'attente d'invitation des organisations de type consommateur`,
  [`Disabled`]: `Désactivé`,
  [`disabled`]: `désactivé`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Si vous désactivez l'intégration libre-service, tous les développeurs d'applications doivent être invités par le fournisseur d'API, le propriétaire d'une organisation de type consommateur existante ou un administrateur.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Si vous désactivez l'approbation d'intégration en libre-service, toutes les demandes d'intégration seront approuvées automatiquement.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `La désactivation ne permet pas de définir le délai d'attente du catalogue comme délai d'attente d'invitation des organisations de type consommateur.`,
  [`Discover targets`]: `Rechercher les cibles`,
  [`Display table checkboxes`]: `Afficher les cases à cocher de la table`,
  [`Display vanity endpoint`]: `Afficher le noeud final personnalisé`,
  [`Do not publish API (continue editting)`]: `Ne pas publier l'API (continuer l'édition)`,
  [`Do not require applications or users to authenticate`]: `Ne requiert pas l'authentification des applications ou des utilisateurs`,
  [`Do not require authentication`]: `Ne pas exiger d'authentification`,
  [`Do not share the resource with provider organizations`]: `Ne partagez pas la ressource avec des organisations de type fournisseur`,
  [`Do not share the API gateway with provider organizations`]: `Ne partagez pas la passerelle d'API avec les organisations de type fournisseur`,
  [`Do not share the service with provider organizations`]: `Ne partagez pas le service avec des organisations de type fournisseur`,
  [`Do not use Dynamic DNS`]: `Ne pas utiliser de DDNS (Dynamic Domain Name System)`,
  [`Do you want to remove these references?`]: `Voulez-vous supprimer ces références ?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Documentation Docker`,
  [`Docs`]: `Documentation`,
  [`Document Editor`]: `Editeur de documents`,
  [`Documentation and tutorials with step-by-step instructions`]: `Documentation et tutoriels avec instructions détaillées`,
  [`Domain name`]: `Nom du domaine`,
  [`Domain Name`]: `Nom de domaine`,
  [`Domains handled by gateway via SNI`]: `Domaines traités par la passerelle via SNI`,
  [`Don't have an account?`]: `Vous n'avez pas de compte ?`,
  [`Done`]: `Terminé`,
  [`Download`]: `Télécharger`,
  [`Download Gateway`]: `Télécharger la passerelle`,
  [`Download and install`]: `Télécharger et installer`,
  [`Download and set up DataPower API Gateway`]: `Télécharger et configurer DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Téléchargez et configurez la passerelle DataPower API Gateway à utiliser sur site ou sur un cloud.`,
  [`Download for Linux`]: `Télécharger pour Linux`,
  [`Download for Mac`]: `Télécharger pour Mac`,
  [`Download for Windows`]: `Télécharger pour Windows`,
  [`Download gateway`]: `Télécharger la passerelle`,
  [`Download schema`]: `Télécharger le schéma`,
  [`Download Toolkit`]: `Téléchargement du kit d'outils`,
  [`Download toolkit`]: `Téléchargement du kit d'outils`,
  [`Download toolkit and credentials for various platforms`]: `Téléchargez le kit d'outils et les données d'identification pour différentes plateformes`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Données d'identification téléchargées introuvables pour cette connexion de cloud. La connexion utilisera les données d'identification par défaut.`,
  [`Dutch`]: `Néerlandais`,
  [`Draft {type} creation failed`]: `Echec de la création du brouillon {type}`,
  [`Drafts`]: `Brouillons`,
  [`Drag and drop files here or click to upload`]: `Faire glisser et déposer les fichiers ici ou cliquer pour les transférer`,
  [`Drag & Drop`]: `Faire glisser et déposer`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `DDNS (Dynamic Domain Name System)`,
  [`Dynamic OAuth configuration from a URL`]: `Configuration OAuth dynamique à partir d'une URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Configuration OAuth dynamique à partir d'une chaîne littérale`,
  [`Dynamic group`]: `Groupe dynamique`,
  [`Dynamic table entries`]: `Entrées de table dynamique`,
  [`ENABLE`]: `ACTIVER`,
  [`Encoded`]: `Codé`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Chaque espace est utilisé par une équipe de développement de fournisseur d'API différente et possède son propre ensemble de fonctions de gestion concernant spécifiquement les API que l'équipe associée publie dans l'espace ; ainsi, chaque équipe peut gérer ses API de façon indépendante. Lorsque vous mettez en préproduction ou publiez une API dans un catalogue dans lequel des espaces sont activés, vous spécifiez l'espace de ce catalogue dans lequel procéder à la mise en préproduction ou à la publication.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Chaque organisation de type fournisseur est propriétaire d'un ensemble d'API, de produits, de plans et de catalogues.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Créez, sécurisez, gérez, partagez et analysez facilement les API situées sur un cloud ou sur site.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Chaque organisation de type fournisseur est propriétaire d'un ensemble d'API, de produits, de plans et de catalogues. [En savoir plus]({link})`,
  [`Edit`]: `Editer`,
  [`Edit access token TTL`]: `Editer la durée de vie du jeton d'accès`,
  [`Edit admin organization invitation timeout`]: `Editer le délai d'expiration d'invitation relative à l'organisation d'administration`,
  [`Edit API`]: `Editer l'API`,
  [`Edit API Connect UI`]: `Edition de l'interface utilisateur API Connect`,
  [`Edit API endpoint for unenforced APIs`]: `Edition du noeud final d'API pour les API non appliquées`,
  [`Edit API gateway service`]: `Editer le service de passerelle d'API`,
  [`Edit API Path`]: `Edition du chemin d'API`,
  [`Edit API security definition`]: `Edition de la définition de sécurité d'API`,
  [`Edit API user registries`]: `Edition des registres d'utilisateurs d'API`,
  [`Edit analytics service`]: `Edition du service d'analyse`,
  [`Edit application`]: `Edition de l'application`,
  [`Edit Assembly`]: `Editer l'assemblage`,
  [`Edit authentication URL user registry`]: `Edition du registre d'utilisateurs de l'URL d'authentification`,
  [`Edit availability zone`]: `Editer la zone de disponibilité`,
  [`Edit Availablity Zone`]: `Edition de la zone de disponibilité`,
  [`Edit and apply constraints to your selected items.`]: `Editez les contraintes et appliquez-les aux éléments que vous avez sélectionnés.`,
  [`Edit billing integration`]: `Editer l'intégration de facturation`,
  [`Edit Catalog Details`]: `Edition des détails du catalogue`,
  [`Edit cloud setting invitation timeout`]: `Editer le délai d'expiration d'invitation relative aux paramètres cloud`,
  [`Edit constraints`]: `Editer les contraintes`,
  [`Edit consumer organization`]: `Edition de l'organisation de type consommateur`,
  [`Edit DNS Scheme`]: `Edition du schéma DNS`,
  [`Edit DataPower API gateway service`]: `Edition du service DataPower API Gateway`,
  [`Edit DataPower gateway service`]: `Edition du service DataPower Gateway`,
  [`Edit definition`]: `Editer la définition`,
  [`Edit dynamic DNS`]: `Edition de DDNS (Dynamic Domain Name System)`,
  [`Edit email server`]: `Edition du serveur de messagerie`,
  [`Edit extensions`]: `Edition des extensions`,
  [`Edit Gateway Service`]: `Edition du service de passerelle`,
  [`Edit gateway`]: `Editer la passerelle`,
  [`Edit gateway extension`]: `Edition de l'extension de passerelle`,
  [`Edit Gateways`]: `Edition de passerelles`,
  [`Edit IBM-managed gateway`]: `Edition de la passerelle gérée par IBM`,
  [`Edit Invitation Timeout`]: `Edition du délai d'expiration d'invitation`,
  [`Edit gateways`]: `Edition de passerelles`,
  [`Edit invitation timeout`]: `Edition du délai d'expiration d'invitation`,
  [`Edit JSON`]: `Editer le code JSON`,
  [`Edit keystore`]: `Edition du magasin de clés`,
  [`Edit LDAP user registry`]: `Editer le registre d'utilisateurs LDAP`,
  [`Edit LTPA Token`]: `Edition du jeton LTPA`,
  [`Edit lifecycle approvals`]: `Editer les approbations de cycle de vie`,
  [`Edit local user registry`]: `Edition du registre d'utilisateurs local`,
  [`Edit native OAuth provider`]: `Edition du fournisseur OAuth natif`,
  [`Edit New Billing System`]: `Edition du nouveau système de facturation`,
  [`Edit Notification Server`]: `Edition du serveur de notification`,
  [`Edit Notification template`]: `Edition du modèle de notification`,
  [`Edit OAuth Provider`]: `Editer le fournisseur OAuth`,
  [`Edit OAuth provider details`]: `Editez les détails du fournisseur OAuth`,
  [`Edit OAuth Provider Visibility`]: `Edition de la visibilité du fournisseur OAuth`,
  [`Edit OIDC User Registry`]: `Editer le registre d'utilisateurs OIDC`,
  [`Edit Onboarding`]: `Edition de l'intégration`,
  [`Edit Operation`]: `Editer l'opération`,
  [`Edit Organization`]: `Editer l'organisation`,
  [`Edit Path`]: `Editer le chemin`,
  [`Edit Path Parameters`]: `Edition des paramètres de chemin`,
  [`Edit notification template`]: `Edition du modèle de notification`,
  [`Edit OAuth provider`]: `Editer le fournisseur OAuth`,
  [`Edit OAuth provider visibility`]: `Edition de la visibilité du fournisseur OAuth`,
  [`Edit OIDC user registry`]: `Editer le registre d'utilisateurs OIDC`,
  [`Edit onboarding`]: `Edition de l'intégration`,
  [`Edit operation`]: `Editer l'opération`,
  [`Edit organization`]: `Editer l'organisation`,
  [`Edit path`]: `Editer le chemin`,
  [`Edit path parameters`]: `Edition des paramètres de chemin`,
  [`Edit plan`]: `Edition du plan`,
  [`Edit portal`]: `Edition du portail`,
  [`Edit portal service`]: `Edition du service de portail`,
  [`Edit Portal Settings`]: `Edition des paramètres de portail`,
  [`Edit product`]: `Editer le produit`,
  [`Edit product APIs`]: `Editer les API du produit`,
  [`Edit Product Subscribability`]: `Edition de la possibilité d'abonnement au produit`,
  [`Edit Product Visibility`]: `Edition de la visibilité du produit`,
  [`Edit Property`]: `Edition de la propriété`,
  [`Edit Provider Organization`]: `Edition de l'organisation de type fournisseur`,
  [`Edit Refresh Token TTL`]: `Editer la durée de vie du jeton d'actualisation`,
  [`Edit remote gateway`]: `Editer la passerelle éloignée`,
  [`Edit Reset Password TTL`]: `Editer la durée de vie pour la réinitialisation de mot de passe`,
  [`Edit Role`]: `Edition de rôle`,
  [`Edit Role Default for Provider Organizations`]: `Edition du rôle par défaut pour les organisations de type fournisseur`,
  [`Edit Role for Provider Organizations`]: `Edition du rôle pour les organisations de type fournisseur`,
  [`Edit property`]: `Edition de la propriété`,
  [`Edit provider organization`]: `Editer une organisation de type fournisseur`,
  [`Edit refresh token TTL`]: `Editer la durée de vie du jeton d'actualisation`,
  [`Edit reset password TTL`]: `Editer la durée de vie pour la réinitialisation de mot de passe`,
  [`Edit role`]: `Edition de rôle`,
  [`Edit role default for Provider Organizations`]: `Edition du rôle par défaut pour les organisations de type fournisseur`,
  [`Edit role for Provider Organizations`]: `Edition du rôle pour les organisations de type fournisseur`,
  [`Edit Roles`]: `Edition des rôles`,
  [`Edit Schema in JSON/XML`]: `Editer le schéma dans JSON/XML`,
  [`Edit security definition`]: `Editer la définition de sécurité`,
  [`Edit sender`]: `Edition de l'expéditeur`,
  [`Edit sender & email server`]: `Edition de l'expéditeur et du serveur de messagerie`,
  [`Edit sender Info`]: `Edition des informations sur l'expéditeur`,
  [`Edit service visibility`]: `Edition de la visibilité du service`,
  [`Edit show/hide settings`]: `Edition des paramètres afficher/masquer`,
  [`Edit space details`]: `Edition des détails de l'espace`,
  [`Edit TLS client profile`]: `Edition du profil de client TLS`,
  [`Edit TLS client profile visibility`]: `Edition de la visibilité du profil de client TLS`,
  [`Edit TLS server profile`]: `Edition du profil de serveur TLS`,
  [`Edit third party OAuth provider`]: `Edition du fournisseur OAuth tiers`,
  [`Edit truststore`]: `Edition du magasin de clés de confiance`,
  [`Edit unenforced API`]: `Editer l'API non appliquée`,
  [`Edit user registries`]: `Edition des registres d'utilisateurs`,
  [`Edit user registry`]: `Editer le registre d'utilisateurs`,
  [`Edit user registry visibility`]: `Edition de la visibilité du registre d'utilisateurs`,
  [`Edit user registry for API Manager`]: `Edition du registre d'utilisateurs pour API Manager`,
  [`Edit user registry for Cloud Manager`]: `Edition du registre d'utilisateurs pour le gestionnaire de cloud`,
  [`Edit vanity API endpoints`]: `Editer les noeuds finaux d'API personnalisés`,
  [`Edit visibility`]: `Editer la visibilité`,
  [`Edit XML`]: `Editer le code XML`,
  [`Edit app lifecycle approved`]: `Edition de cycle de vie d'application approuvée`,
  [`Edit app lifecycle cancelled`]: `Edition de cycle de vie d'application annulée`,
  [`Edit app lifecycle denied`]: `Edition de cycle de vie d'application refusée`,
  [`Edit app lifecycle pending`]: `Edition de cycle de vie d'application en attente`,
  [`Edit app lifecycle request`]: `Edition de la demande de cycle de vie d'application`,
  [`Edit app reinstated`]: `Edition de l'application rétablie`,
  [`Edit app suspended`]: `Edition de l'application suspendue`,
  [`Edit audit setting`]: `Editer le paramètre d'audit`,
  [`Edit definitions`]: `Editer des définitions`,
  [`Enable external group mapping`]: `Activer le mappage de groupe externe`,
  [`Edit extra constraints for the GraphQL schema`]: `Editer des contraintes supplémentaires pour le schéma GraphQL`,
  [`Edit GraphQL schema|title`]: `Editer le schéma GraphQL`,
  [`Edit inline schema`]: `Editer le schéma intégré`,
  [`Edit inputs`]: `Editer des entrées`,
  [`Edit invitation`]: `Editer l'invitation`,
  [`Edit mail server test connection`]: `Edition de la connexion de test au serveur de messagerie`,
  [`Edit member invitation`]: `Edition de l'invitation de membre`,
  [`Edit notification templates`]: `Edition des modèles de notification`,
  [`Edit notification templates:`]: `Edition des modèles de notification :`,
  [`Edit operation list`]: `Editer la liste des opérations`,
  [`Edit or upload JSON schema`]: `Editer ou télécharger un schéma JSON`,
  [`Edit outputs`]: `Editer les sorties`,
  [`Edit password changed`]: `Edition du mot de passe changé`,
  [`Edit password reset`]: `Edition de la réinitialisation de mot de passe`,
  [`Edit plan APIs`]: `Editer les API de plan`,
  [`Edit schema`]: `Editer un schéma`,
  [`Edit schema|button`]: `Editer le schéma`,
  [`Edit schema|title`]: `Editer un schéma`,
  [`Edit settings for roles, notifications and more.`]: `Editez les paramètres des rôles, notifications, etc.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Editez les paramètres pour les registres d'utilisateurs, les rôles, les noeuds finaux, etc.`,
  [`Edit sign up`]: `Edition de l'inscription`,
  [`Edit task product lifecycle approved`]: `Edition de la tâche de cycle de vie de produit approuvée`,
  [`Edit task product lifecycle cancelled`]: `Edition de la tâche de cycle de vie de produit annulée`,
  [`Edit task product lifecycle denied`]: `Edition de la tâche de cycle de vie de produit refusée`,
  [`Edit task product lifecycle pending`]: `Edition de la tâche de cycle de vie de produit en attente`,
  [`Edit task product lifecycle request`]: `Edition de la demande de tâche de cycle de vie de produit`,
  [`Edit task subscription approved`]: `Edition de la tâche d'abonnement approuvée`,
  [`Edit task subscription cancelled`]: `Edition de la tâche d'abonnement annulée`,
  [`Edit task subscription denied`]: `Edition de la tâche d'abonnement refusée`,
  [`Edit task subscription pending`]: `Edition de la tâche d'abonnement en attente`,
  [`Edit task subscription request`]: `Edition de la tâche de demande d'abonnement`,
  [`Edit template`]: `Editer le modèle`,
  [`Edit the TLS client profile.`]: `Editez le profil de client TLS.`,
  [`Edit the TLS Server profiles`]: `Editez les profils de serveur TLS`,
  [`Edit the keystore title.`]: `Editez le titre du magasin de clés.`,
  [`Edit the organization summary details as required`]: `Editez les détails récapitulatifs de l'organisation selon les besoins`,
  [`Edit the organization title`]: `Editez le titre de l'organisation`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Editez les paramètres de configuration d'un serveur de messagerie. Les zones sont requises sauf si elles sont indiquées comme étant facultatives.`,
  [`Edit signed token`]: `Editer le jeton signé`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Editez le texte du modèle de courrier électronique. Les variables sont placées entre accolades et ne peuvent pas être éditées.`,
  [`Edit the truststore.`]: `Editez le magasin de clés de confiance.`,
  [`Edit the visibility for each resource.`]: `Editez la visibilité pour chaque ressource.`,
  [`Edit user details`]: `Editez les détails de l'utilisateur`,
  [`Edit {notificationTemplate}`]: `Editer {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `Editer, assembler, sécuriser et tester des API`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `Editez, assemblez, sécurisez et testez des API. Conditionnez des API à l'aide de produits de publication pour les consommateurs.`,
  [`Edit, upload, or infer XML schema`]: `Editer, télécharger ou induire un schéma XML`,
  [`Editors`]: `Editeurs`,
  [`Edit|permission`]: `Editer`,
  [`Edit API key timeout`]: `Editer le délai d'attente de la clé d'API`,
  [`Edit Base64 encoding for temporary tokens`]: `Editer le codage Base64 pour les jetons temporaires`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `"Objet cryptographique de signature du jeton d'ID" ou "Clé de signature du jeton d'ID" doit être fourni.`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Un "objet cryptographique de vérification JWT" ou un "JWK de vérification JWT" doit être indiqué.`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Sélectionnez l'option pour utiliser le noeud final défini dans l'API ou indiquez un noeud final de valideur par défaut.`,
  [`Element name for JSON array elements`]: `Nom d'élément pour les éléments de tableau JSON`,
  [`Email`]: `Adresse électronique`,
  [`Email address`]: `Adresse électronique`,
  [`Email Content`]: `Contenu du courrier électronique`,
  [`Email endpoint`]: `Noeud final de courrier électronique`,
  [`Email required`]: `Courrier électronique requis`,
  [`Email sender`]: `Expéditeur de courrier électronique`,
  [`Email server`]: `Serveur de messagerie`,
  [`Email server configuration`]: `Configuration du serveur de messagerie`,
  [`Email servers`]: `Serveurs de messagerie`,
  [`Empty case`]: `Cas vide`,
  [`Empty catch`]: `Interception vide`,
  [`Empty JSON array handling`]: `Traitement des tableaux JSON vides`,
  [`Empty XML element handling`]: `Gestion des éléments XML vides`,
  [`Enable`]: `Activer`,
  [`Enable API consumers to invite collaborators and assign roles`]: `Autorisez les consommateurs d'API à inviter des collaborateurs et à affecter des rôles`,
  [`Enable application lifecycle`]: `Activation du cycle de vie des applications`,
  [`Enable billing`]: `Activer la facturation`,
  [`Enable gateway services`]: `Activer les services de passerelle`,
  [`Enable gateway services and policies`]: `Activation des stratégies et des services de passerelle`,
  [`Enable GraphiQL`]: `Activer GraphiQL`,
  [`Enable GraphiQL editor`]: `Activer l'éditeur GraphiQL`,
  [`Enable GraphiQL Editor on browser`]: `Activer l'éditeur GraphiQL dans le navigateur`,
  [`Enable JSON post processing`]: `Activer le post-traitement JSON`,
  [`Enable lifecycle approvals`]: `Edition des approbations du cycle de vie`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Activer l'extension NONCE pour empêcher une nouvelle utilisation des demandes compromises (réexécution)`,
  [`Enable OAuth providers`]: `Activation des fournisseurs OAuth`,
  [`Enable OIDC`]: `Activer l'OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Activez le modèle OpenID Connect pour générer des jetons d'ID.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Activer l'extension PKCE afin de permettre aux clients publics d'atténuer la menace d'interception du code d'autorisation`,
  [`Enable production mode`]: `Activer le mode production`,
  [`Enable Proxy`]: `Activer le proxy`,
  [`Enable Self-Service Onboarding`]: `Activer l'intégration libre-service`,
  [`Enable spaces`]: `Activer les espaces`,
  [`Enable token management`]: `Activer la gestion des jetons`,
  [`Enable buffering`]: `Activer la mise en mémoire tampon`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `Autorisez les consommateurs d'API à créer des comptes de portail de développement sans demander d'invitation.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `Activez le contrôle d'accès de partage de ressources d'origine croisée pour votre API.`,
  [`Enable debug response headers`]: `Activer les en-têtes de réponse de débogage`,
  [`Enable proof key for code exchange`]: `Activer la clé de preuve pour l'échange de code`,
  [`Enable publishing of this product`]: `Activer la publication de ce produit`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Activer la redirection du code d'autorisation tiers via le noeud final OIDC du portail`,
  [`Enable security`]: `Activez la sécurité`,
  [`Enable Self-Service Onboarding Approval`]: `Activer l'approbation d'intégration en libre-service`,
  [`Enable the following gateway services and policies for this catalog`]: `Activez les stratégies et les services de passerelle suivants pour ce catalogue`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Autoriser les utilisateurs à configurer un événement d'audit. Un événement d'audit est un récapitulatif détaillé de qui a effectué quel événement, et quand.`,
  [`Enable/disable custom notification templates`]: `Activer/Désactiver les modèles de notification personnalisés`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Activez ou désactivez le codage Base64 pour les invitations et les jetons de réinitialisation de mot de passe`,
  [`Enable overriding consumer organizations invitation timeout`]: `Activer le remplacement du dépassement du délai d'attente des organisations de type consommateur`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `Vous pouvez activer ou désactiver le codage Base64 pour les jetons temporaires. Les jetons temporaires sont des invitations et des jetons de réinitialisation de mot de passe. Lorsque le codage Base64 est activé, le jeton temporaire est codé au format Base64. Lorsque le codage Base64 est désactivé, le jeton temporaire reste au format JWT (JSON Web Token), ce qui signifie que la longueur de l'URL reçue par l'utilisateur est réduite.`,
  [`Enabled`]: `Activé`,
  [`enabled`]: `activé`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `L'activation du cycle de vie des applications permettra aux applications de passer à l'état de développement ou de production et d'être acheminées vers différents noeuds finaux et passerelles.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Si vous activez l'approbation d'intégration en libre-service, toutes les demandes d'intégration devront être approuvées par le fournisseur de l'API ou par un administrateur.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Si vous activez l'intégration libre-service, un développeur d'applications peut s'inscrire sans l'invitation du fournisseur d'API, du propriétaire d'une organisation de type consommateur existante ou de l'administrateur.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `L'activation permet de définir le délai d'attente d'invitation du catalogue comme  délai d'attente d'invitation des organisations de type consommateur.`,
  [`Encode Query params`]: `Coder les paramètres de requête`,
  [`Encrypt`]: `Chiffrement`,
  [`Encrypt Crypto Object`]: `Chiffrer l'objet crypto`,
  [`Encrypt JWK variable name`]: `Nom de variable JWK de chiffrement`,
  [`Encrypt a buffer using the specified certificate`]: `Chiffrez une mémoire tampon à l'aide du certificat spécifié`,
  [`Encryption Algorithm`]: `Algorithme de chiffrement`,
  [`Endpoint`]: `Noeud final`,
  [`Endpoint URLs`]: `URL de noeud final`,
  [`Endpoint behavior`]: `Comportement des noeuds finaux`,
  [`Endpoint Behavior`]: `Comportement des noeuds finaux`,
  [`Endpoint for unenforced APIs`]: `Noeud final pour les API non appliquées`,
  [`Endpoints`]: `Noeuds finaux`,
  [`Enforce Required Params`]: `Appliquer les paramètres requis`,
  [`Enforce the API by using API Connect Gateway.`]: `Appliquez l'API à l'aide de la passerelle API Connect Gateway.`,
  [`Enforced`]: `Appliqué`,
  [`English`]: `Anglais`,
  [`Enter Summary`]: `Entrez un récapitulatif`,
  [`Enter URL`]: `Entrez l'URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Entrez le nom du registre d'utilisateurs. Si cette zone est vide, Le registre d'utilisateurs local d'API Manager est utilisé par défaut.`,
  [`Enter a catalog name`]: `Entrez un nom de catalogue`,
  [`Enter a Display Name`]: `Entrez un nom d'affichage`,
  [`Enter a space name`]: `Entrez un nom d'espace`,
  [`Enter a brief description`]: `Entrez une courte description`,
  [`Enter a display name`]: `Entrez un nom d'affichage`,
  [`Enter a temporary sign in password`]: `Entrez un mot de passe de connexion temporaire`,
  [`Enter an Organization Name`]: `Entrez un nom d'organisation`,
  [`Enter an Organization Title`]: `Entrez un titre d'organisation`,
  [`Enter catalog title`]: `Entrez le titre du catalogue`,
  [`Enter details of the consumer organization`]: `Entrez les détails de l'organisation de type consommateur`,
  [`Enter details of the product`]: `Entrez les détails du produit`,
  [`Enter details of the provider organization`]: `Entrez les détails de l'organisation de type fournisseur`,
  [`Enter details of this API`]: `Entrez les détails de cette API`,
  [`Enter email of the new user`]: `Entrez l'adresse électronique du nouvel utilisateur`,
  [`Enter first name of the new user`]: `Entrez le prénom du nouvel utilisateur`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Saisissez une ou plusieurs adresses électroniques. Utilisez des virgules pour séparer les adresses.`,
  [`Enter organization name`]: `Entrez le nom de l'organisation`,
  [`Enter organization title`]: `Entrez le titre de l'organisation`,
  [`Enter password`]: `Entrez le mot de passe`,
  [`Enter space title`]: `Entrez le titre de l'espace`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Entrez le noeud final d'API de la passerelle en fournissant le nom d'hôte et le numéro de port. Exemple : api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Entrez les détails récapitulatifs de l'API`,
  [`Enter the API summary details. [Learn more]({link})`]: `Entrez les détails récapitulatifs de l'API. [En savoir plus]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Entrez l'URL du service cible qui doit passer par un proxy`,
  [`Enter the catalog summary details`]: `Entrez les détails récapitulatifs du catalogue`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Entrez les détails récapitulatifs du catalogue. Une fois celui-ci créé, vous pouvez le configurer entièrement.`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Entrez l'adresse électronique de l'utilisateur à inviter comme membre de l'organisation d'administration`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Entrez l'adresse électronique de l'utilisateur à inviter comme membre du catalogue`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Entrez l'adresse électronique de l'utilisateur à inviter comme membre de l'organisation de type fournisseur`,
  [`Enter the email address of the user to invite as a member of the space`]: `Entrez l'adresse électronique de l'utilisateur à inviter comme membre de l'espace`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Entrez l'adresse électronique de l'utilisateur auquel transférer la propriété.`,
  [`Enter the full path to JSON or YAML file`]: `Entrez le chemin d'accès complet au fichier JSON ou YAML`,
  [`Enter the full path to WSDL file`]: `Entrez le chemin d'accès complet au fichier WSDL`,
  [`Enter the full path to YAML file`]: `Entrez le chemin d'accès complet au fichier YAML`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Entrez le nom de domaine qualifié complet du nœud final d'ingestion Analytics que vous avez défini lors de l'installation.`,
  [`Enter the last name of the new user`]: `Entrez le nom du nouvel utilisateur`,
  [`Enter the owner's email address to send the invitation`]: `Entrez l'adresse électronique du propriétaire pour envoyer l'invitation`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Entrez les paramètres pour configurer le service de passerelle. Les zones sont requises sauf si elles sont indiquées comme étant facultatives. Le noeud final est un noeud final de service de passerelle d'API sur DataPower. La base du noeud final d'API est le noeud final de passerelle d'API pour les appels API entrants. Pour SNI, entrez * pour autoriser tous les hôtes (obligatoire) ainsi que des noms d'hôte pour autoriser des hôtes spécifiques. Choisissez le profil TLS pour prendre en charge chaque hôte.`,
  [`Enter the password for the certificate file if required.`]: `Entrez le mot de passe pour le fichier certificat, si nécessaire.`,
  [`Enter the provider organization summary details`]: `Entrez les détails récapitulatif de l'organisation de type fournisseur`,
  [`Enter the schema definitiom summary details`]: `Entrez les détails récapitulatifs de la définition de schéma`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Entrez les détails récapitulatifs de l'espace. Une fois l'espace créé, vous pouvez entièrement le configurer.`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Entrez les détails récapitulatifs de l'espace. Une fois l'espace créé, vous pouvez entièrement le configurer.`,
  [`Enter the summary details for the new product`]: `Entrez les détails récapitulatifs pour le nouveau produit`,
  [`Enter the summary details for this role`]: `Entrez les détails récapitulatifs pour ce rôle`,
  [`Enter title of the organization`]: `Entrez le titre de l'organisation`,
  [`Enter user email`]: `Entrez l'adresse électronique de l'utilisateur`,
  [`Enter username of the existing user`]: `Entrez le nom de l'utilisateur existant`,
  [`Enter username of the new user`]: `Entrez le nom du nouvel utilisateur`,
  [`Error`]: `Erreur`,
  [`Error ID`]: `ID erreur`,
  [`Error:`]: `Erreur :`,
  [`Copy error ID to Clipboard`]: `Copier l'identificateur d'erreur dans le presse-papiers`,
  [`Error Console`]: `Console d'erreur`,
  [`Error connecting`]: `Erreur lors de la connexion`,
  [`Error connecting to GraphQL server`]: `Erreur lors de la connexion au serveur GraphQL`,
  [`Error getting user info during login. Please login with different user.`]: `Erreur lors de l'obtention des informations utilisateur lors de la connexion. Connectez-vous avec un autre utilisateur.`,
  [`Error content`]: `Contenu de l'erreur`,
  [`Error message`]: `Message d'erreur`,
  [`Error name`]: `Nom de l'erreur`,
  [`Error status code`]: `Code de statut d'erreur`,
  [`Error status code is invalid`]: `Le code de statut d'erreur n'est pas valide`,
  [`Error status reason`]: `Motif de statut d'erreur`,
  [`Errors`]: `Erreurs`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Les erreurs proviennent généralement d'informations erronées ou de fautes de frappe. Veillez à fournir les informations fournisseur et client correctes avant le test.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Erreur lors de la tentative d'obtention de l'API à partir de l'URL {url}. Vérifiez l'URL, votre nom d'utilisateur et votre mot de passe. Message d'erreur : {message}`,
  [`Event`]: `Evénement`,
  [`Event gateway management client TLS client profile`]: `Profil client TLS du client de gestion de passerelle d'événements`,
  [`Event load`]: `Charge d'événements`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Chaque organisation de type consommateur possède un propriétaire. Le propriétaire dispose des droits complets d'administration et peut gérer les abonnements et les applications.`,
  [`Exchange authorization code for tokens`]: `Echange du code d'autorisation pour les jetons`,
  [`Exchange code for token`]: `Echanger le code pour le jeton`,
  [`Execute`]: `Exécuter`,
  [`Execute migration target`]: `Exécuter la cible de migration`,
  [`Executes any catalog-level post-invoke handlers`]: `Exécute des gestionnaires de post-appel au niveau du catalogue`,
  [`Executes any catalog-level pre-invoke handlers`]: `Exécute des gestionnaires de pré-appel au niveau du catalogue`,
  [`Existing`]: `Existant`,
  [`Existing {docType}`]: `{docType} existant`,
  [`Existing API Connect user`]: `Utilisateur API Connect existant`,
  [`Existing OpenAPI`]: `OpenAPI existant`,
  [`Existing product`]: `Produit existant`,
  [`Existing SOAP API has been validated`]: `L'API SOAP existante a été validée`,
  [`Existing products in the catalog will be moved to a default space.`]: `Les produits existants dans le catalogue seront déplacés vers un espace par défaut.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Les utilisateurs existants sont les membres actuels. Vous pouvez aussi activer un nouveau membre depuis un autre registre d'utilisateurs.`,
  [`Expires On`]: `Expire le`,
  [`Expiry`]: `Délai d'expiration`,
  [`Explorer`]: `Explorateur`,
  [`Expose`]: `Exposer`,
  [`Extend the schema to specify the cost of types and fields`]: `Etendre le schéma pour spécifier le coût des types et des zones`,
  [`Extended`]: `Extension`,
  [`Extension Type:`]: `Type d'extension :`,
  [`Extensions`]: `Extensions`,
  [`Extension has been uploaded.`]: `L'extension a été téléchargée.`,
  [`External`]: `Externe`,
  [`External Doc Description`]: `Description de la documentation externe`,
  [`External doc description`]: `Description de la documentation externe`,
  [`External Documentation URL`]: `URL de la documentation externe`,
  [`External documentation URL`]: `URL de la documentation externe`,
  [`External Role mapping`]: `Mappage de rôle externe`,
  [`External URL`]: `URL externe`,
  [`Extract Identity Settings`]: `Extraire les paramètres d'identité`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `ID facteur`,
  [`Fail`]: `Echec`,
  [`Failed to change account password.`]: `Impossible de changer le mot de passe de compte.`,
  [`Failed to create analytics service {title}.`]: `Echec de création du service d'analyse {title}.`,
  [`Failed to create analytics insights service {title}.`]: `Échec de la création du service Analytics Insights {title}.`,
  [`Analytics insights service {title} has been updated.`]: `Le service Analytics Insights {title} a été mis à jour.`,
  [`About the analytics insights service`]: `A propos du service Analytics Insights`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Le service Analytics Insights collecte des événements d'API à partir du service de passerelle. Chaque service de passerelle peut disposer d'un service Analytics Insights associé.`,
  [`Advanced analytics insights configuration`]: `Configuration avancée d'Analytics Insights`,
  [`Analytics insights service {title} has been created.`]: `Le service Analytics Insights {title} a été créé.`,
  [`Analytics Insights details`]: `Détails d'Analytics Insights`,
  [`Management endpoint on the analytics insights service`]: `Noeud final de gestion sur le service Analytics Insights`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Entrez le nom de domaine qualifié complet du noeud final du directeur Analytics Insights que vous avez défini lors de l'installation.`,
  [`TYPE`]: `TYPE`,
  [`Failed to create email server`]: `Echec de création du serveur de messagerie`,
  [`Failed to create gateway service`]: `Echec de la création du service de passerelle`,
  [`Failed to create gateway service {name}.`]: `Echec de création du service de passerelle {name}.`,
  [`Failed to create user registry {title}.`]: `Echec de création du registre d'utilisateurs {title}.`,
  [`Failed to delete a group`]: `Echec de la suppression d'un groupe.`,
  [`Failed to get TLS client profiles`]: `Echec de l'obtention des profils de client TLS`,
  [`Failed to get catalogs`]: `Echec de l'obtention des catalogues`,
  [`Failed to get configured OAuth providers`]: `Impossible d'obtenir les fournisseurs OAuth configurés`,
  [`Failed to get policies of gateways`]: `Echec de l'obtention des stratégies de passerelle`,
  [`Failed to get user registries`]: `Echec de l'obtention des registres d'utilisateurs`,
  [`Failed to introspect schema from server:`]: `Echec de l'introspection du schéma depuis le serveur`,
  [`Failed to make API online`]: `Échec de la mise en ligne de l'API`,
  [`Failed to register catalog member {user}.`]: `Echec de l'enregistrement du membre de catalogue {user}.`,
  [`Failed to remove analytics service: {arg}`]: `Echec de retrait du service d'analyse : {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Echec de retrait de la zone de disponibilité : {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Echec de retrait du service de passerelle : {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Echec de retrait du serveur de messagerie : {arg}`,
  [`Failed to remove portal service: {arg}`]: `Echec de retrait du service de portail : {arg}`,
  [`Failed to create {name}.`]: `Echec de la création de {name}.`,
  [`Failed to sign out! Please check the console for details.`]: `Echec de la déconnexion. Reportez-vous à la console pour obtenir plus de détails.`,
  [`Failed to update {name}.`]: `Echec de la mise à jour de {name}.`,
  [`Failed to update OAuth provider`]: `Echec de la mise à jour du fournisseur OAuth`,
  [`Failed to update account.`]: `Echec de la mise à jour du compte.`,
  [`Failed to update analytics service {title}.`]: `Echec de mise à jour du service d'analyse {title}.`,
  [`Failed to update gateway service {title}.`]: `Echec de la mise à jour du service de passerelle {title}.`,
  [`Failed to update task`]: `Echec de la mise à jour de la tâche`,
  [`Failed to create app {title}`]: `Echec de la création de l'application {title}`,
  [`Feature flags`]: `Indicateurs de fonction`,
  [`Fetch schema`]: `Extraire le schéma`,
  [`Fetched endpoints`]: `Noeuds finaux extraits`,
  [`Field`]: `Zone`,
  [`Field label`]: `Libellé de zone`,
  [`Field Mapping`]: `Mappage de zones`,
  [`Field name`]: `Nom de zone`,
  [`Field weight`]: `Poids de la zone`,
  [`File Name`]: `Nom de fichier`,
  [`File Preview`]: `Aperçu du fichier`,
  [`File contains validation errors`]: `Le fichier contient des erreurs de validation`,
  [`File is not a valid OpenAPI definition`]: `Le fichier n'est pas une définition OpenAPI valide`,
  [`File is not a valid extension format.`]: `Le fichier ne possède pas un format d'extension valide.`,
  [`File is not a valid {fileType} format.`]: `Le fichier ne possède pas un format {fileType} valide.`,
  [`File name`]: `Nom du fichier`,
  [`File upload`]: `Téléchargement de fichier`,
  [`File uploaded is not a valid file`]: `Le fichier téléchargé n'est pas un fichier valide`,
  [`File uploaded successfully`]: `Fichier téléchargé correctement`,
  [`File was updated and validated!`]: `Le fichier a été mis à jour et validé.`,
  [`File was uploaded and validated!`]: `Le fichier a été téléchargé et validé.`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `Le fichier {draftFilePath} existe. Essayez de changer la combinaison nom/version du brouillon`,
  [`Fill out the details of this service`]: `Remplissez les détails pour ce service`,
  [`Filter`]: `Filtre`,
  [`Filter by operation`]: `Filtrer par opération`,
  [`Filter policies`]: `Règles du filtre`,
  [`Final Action`]: `Action finale`,
  [`Find existing user`]: `Rechercher un utilisateur existant`,
  [`Find expert answers in the API Connect community forum`]: `Trouvez des réponses d'experts dans le forum de la communauté API Connect`,
  [`Find expert answers in the API Connect community forum.`]: `Trouvez des réponses d'experts dans le forum de la communauté API Connect.`,
  [`Find schema`]: `Trouver le schéma`,
  [`Finger Print`]: `Empreinte digitale`,
  [`Fingerprint SHA-1`]: `Empreinte digitale SHA-1`,
  [`Fingerprint SHA-256`]: `Empreinte digitale SHA-256`,
  [`Finish`]: `Terminer`,
  [`First name`]: `Prénom`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Indiquez d'abord l'URL du noeud final utilisé par la passerelle pour la circulation des API. La valeur doit être un nom de domaine public complet avec des chemins supplémentaires spécifiques à vos appels API, par exemple : https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Indiquez d'abord l'URL du noeud final de gestion de passerelle. Pour les passerelles qui s'exécutent dans des environnements Kubernetes, la valeur doit correspondre au noeud final indiqué via la commande de configuration de passerelle set gwy apic-gw-service. Pour les dispositifs de passerelle, la valeur doit être indiquée au format http://<ip-address-for-gateway>:3000. Lorsque vous utilisez un cluster de passerelle, spécifiez l'adresse:port de l'équilibreur de charge.`,
  [`Fit to screen`]: `Adapter à l'écran`,
  [`Flow`]: `Flux`,
  [`Follow redirects`]: `Suivre les redirections`,
  [`Found In`]: `Trouvé dans`,
  [`Fonts contained in the following file are available under the`]: `Les polices contenues dans le fichier suivant sont disponibles sous le`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `Pour les définitions WSDL, téléchargez un fichier zip contenant à la fois le fichier WSDL et la définition d'API (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Pour chaque plan dans le produit original, sélectionnez le plan dans le produit de remplacement vers lequel migrer les abonnements`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Pour chaque plan dans le produit source, sélectionnez le plan dans le produit cible vers lequel migrer les abonnements`,
  [`For guidance on the available command set, access the CLI's help`]: `Pour obtenir des conseils sur l'ensemble de commandes disponible, accédez à l'aide de l'interface de ligne de commande`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Pour les sites utilisant des fournisseurs OAuth natifs, spécifiez éventuellement la valeur confidentielle partagée qui sera utilisée par tous les appels API.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Pour les LDAP en écriture, sélectionnez Géré par l'utilisateur et ajoutez le mappage des noms d'attributs de votre registre LDAP source aux valeurs d'attributs de l'API Connect LDAP. Les propriétés du profil utilisateur par défaut dont API Connect a besoin lors de l'enregistrement de l'utilisateur sont le nom d'utilisateur, le prénom, le nom_de_famille, l'adresse électronique et le mot de passe. Un exemple de configuration de mappage est fourni - modifiez le mappage pour qu'il corresponde à vos besoins`,
  [`Forbidden`]: `Interdit`,
  [`Forget token`]: `Oublier le jeton`,
  [`Forgot password?`]: `Mot de passe oublié ?`,
  [`Forgot password`]: `Mot de passe oublié`,
  [`Form`]: `Formulaire`,
  [`Forum`]: `Forum`,
  [`French`]: `Français`,
  [`From File`]: `Depuis un fichier`,
  [`From Sample JSON`]: `A partir de l'exemple JSON`,
  [`From Sample XML`]: `A partir de l'exemple XML`,
  [`From URL`]: `Depuis une URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `A partir d'un service GraphQL existant (proxy GraphQL)`,
  [`From existing OpenAPI service`]: `A partir d'un service OpenAPI existant`,
  [`From existing WSDL service (REST proxy)`]: `A partir d'un service WSDL existant (proxy REST)`,
  [`From existing WSDL service (SOAP proxy)`]: `A partir d'un service WSDL existant (proxy SOAP)`,
  [`From target service`]: `A partir d'un service cible`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `Parmi les opérations qui sont définies dans la définition WSDL pour le service SOAP, sélectionnez celles que vous voulez utiliser dans votre API et spécifiez les méthodes et les chemins d'URL.`,
  [`Gateway Extensions`]: `Extensions de passerelle`,
  [`Gateway extension`]: `Extension de passerelle`,
  [`Gateway Services`]: `Services de passerelle`,
  [`Gateway Type`]: `Type de passerelle`,
  [`Gateway by Name`]: `Passerelle par nom`,
  [`Gateway services`]: `Services de passerelle`,
  [`Gateway type`]: `Type de passerelle`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `Licence GPL v2`,
  [`GROUP`]: `GROUPE`,
  [`Group name`]: `Nom du groupe`,
  [`Gateway`]: `Passerelle`,
  [`Gateways`]: `Passerelles`,
  [`Gateway details`]: `Détails de la passerelle`,
  [`Gateway Details`]: `Détails de la passerelle`,
  [`Gateway Error`]: `Erreur de passerelle`,
  [`Gateway service details`]: `Détails du service de passerelle`,
  [`Gateway management client TLS client profile`]: `Profil de client TLS du client de gestion de passerelle`,
  [`Gateway management client keystore`]: `Magasin de clés du client de gestion de passerelle`,
  [`Gateway management client truststore`]: `Magasin de clés de confiance du client de gestion de passerelle`,
  [`Gateway processing status`]: `Statut de traitement de la passerelle`,
  [`Gateway policies (TODO)`]: `Stratégies de passerelle (TODO)`,
  [`Gateway service`]: `service de passerelle`,
  [`Gateway Service US East (TODO)`]: `Service de passerelle - Est des Etats-Unis (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Le service de passerelle {arg} a été retiré.`,
  [`Gateway service configuration`]: `Configuration du service de passerelle`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `L'hôte du service de passerelle n'est pas disponible. Le test d'API (Essayer) dans l'explorateur ne sera pas activé.`,
  [`Gateway service configuration has been updated.`]: `La configuration du service de passerelle a été mise à jour.`,
  [`Gateway service {title} has been created.`]: `Le service de passerelle {title} a été créé.`,
  [`Gateway service {title} has been updated.`]: `Le service de passerelle {title} a été mis à jour.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Les services de passerelle sont gérés dans chaque espace lorsque les espaces sont activés. Accédez aux paramètres d'espace pour gérer les services de passerelle.`,
  [`Gateway version`]: `Version de la passerelle`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Passerelles par page`,
  [`Gather-and-send`]: `Regrouper et envoyer`,
  [`Gather-only`]: `Regrouper uniquement`,
  [`General configuration`]: `Configuration générale`,
  [`General information about the API.`]: `Informations générales sur l'API.`,
  [`Generate`]: `Générer`,
  [`Generate an OpenAPI definition for the proxy`]: `Générer une définition OpenAPI pour le proxy`,
  [`Generate JSON Web Token (JWT)`]: `Générer un jeton Web JSON (JWT)`,
  [`Generate JWT`]: `Générer JWT`,
  [`Generate LTPA Token`]: `Générer un jeton LTPA`,
  [`Generate OAuth Access Tokens`]: `Générer des jetons d'accès OAuth`,
  [`Generate OAuth access tokens`]: `Générer des jetons d'accès OAuth`,
  [`Generate a default product`]: `Générer un produit par défaut`,
  [`Generate access token`]: `Générer un jeton d'accès`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Générez un jeton LTPA de sorte que votre API puisse s'authentifier en toute sécurité avec les serveurs Web WebSphere Application Server.`,
  [`Generate an object`]: `Générer un objet`,
  [`Generate authorization code`]: `Générer un code d'autorisation`,
  [`Generate from sample JSON`]: `Générer à partir de l'exemple de code JSON`,
  [`Generate from sample XML`]: `Générer à partir de l'exemple de code XML`,
  [`Generate tests automatically to build high quality APIs`]: `Générer des tests automatiquement pour générer des API de haute qualité`,
  [`Generated OpenAPI 2.0 definition`]: `Définition OpenAPI 2.0 générée`,
  [`Generated default product for the API`]: `Produit par défaut généré pour l'API`,
  [`Generated name for use in commands and API calls`]: `Nom généré à utiliser dans les commandes et les appels API`,
  [`German`]: `Allemand`,
  [`Get Base DN`]: `Extraire le nom distinctif de base`,
  [`Get Code`]: `Obtenir le code`,
  [`Get Code Snippet`]: `Obtenir le fragment de code`,
  [`Get Support`]: `Obtenir de l'aide`,
  [`Get Token`]: `Extraire le jeton`,
  [`Get activation link`]: `Obtenir le lien d'activation`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Commencez par l'enregistrement d'un service de passerelle. Vous pourrez ainsi tester les noeuds finaux lors de la configuration des autres services. Une fois qu'un service a été enregistré, il s'affiche ici. [En savoir plus]({link})`,
  [`Get subscription ID`]: `Obtenir un ID d'abonnement`,
  [`Getting Started`]: `Mise en route`,
  [`Getting started`]: `Mise en route`,
  [`Git information`]: `Informations Git`,
  [`Github`]: `Github`,
  [`Global policies`]: `stratégies globales`,
  [`Go to Spaces`]: `Accéder aux espaces`,
  [`Go to the Test tab`]: `Accéder à l'onglet Test`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `Limites de débit de GraphQL`,
  [`GraphQL service`]: `Service GraphQL`,
  [`GraphQL schema`]: `Schéma GraphQL`,
  [`GraphQL Schema`]: `Schéma GraphQL`,
  [`GraphQL schema has been successfully validated.`]: `Le schéma GraphQL a été validé.`,
  [`GraphQL Schema URL`]: `URL du schéma GraphQL`,
  [`GraphQL send type`]: `Type d'envoi GraphQL`,
  [`GraphQL Websocket Upgrade`]: `Mise à niveau de socket Web GraphQL`,
  [`Group Based DN`]: `Nom distinctif de base du groupe`,
  [`Group {title} added`]: `Groupe {title} ajouté`,
  [`Group {title} updated`]: `Groupe {title} mis à jour`,
  [`Group {title} has been deleted`]: `Le groupe {title} a été supprimé`,
  [`Groups`]: `Groupes`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `NOM D'HOTE`,
  [`HOST URL`]: `URL de l'hôte`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `Formulaire HTML`,
  [`HTML form time limit`]: `Limite de temps de formulaire HTML`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 requis`,
  [`HTTP Header`]: `En-tête HTTP`,
  [`HTTP Method`]: `Méthode HTTP`,
  [`HTTP Type`]: `Type HTTP`,
  [`HTTP Version`]: `Version HTTP`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Poignées`,
  [`Hard limit`]: `Limite absolue`,
  [`Header`]: `En-tête`,
  [`Header control`]: `Contrôle d'en-tête`,
  [`Header name payload`]: `Contenu du nom d'en-tête`,
  [`Header name token`]: `Jeton de nom d'en-tête`,
  [`Headers`]: `En-têtes`,
  [`Hello`]: `Bonjour`,
  [`Hello!`]: `Bonjour !`,
  [`Hello [IBM](url)`]: `Bonjour [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Bonjour {name}`,
  [`Hello {name}!`]: `Bonjour {name} !`,
  [`Hello {name}, [see IBM profile](url)`]: `Bonjour {name}, [voir profil IBM](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nMerci d'avoir souscrit à un compte sur le portail de développeur {catalogTitle}.\n\nUtilisez ce lien pour finaliser votre enregistrement :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nL'administrateur du portail de développeur {consumerOrg} a rétabli\nl'application {appName}. Les appels API provenant de cette application ne seront plus bloqués par la\npasserelle d'API.\n\nUtilisez ce lien pour afficher les détails de l'application :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nL'administrateur du portail de développeur {consumerOrg} a suspendu \nl'application {appName}. Les appels API depuis cette application seront bloqués par la passerelle d'API.\n\nUtilisez ce lien pour afficher les détails de l'application :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nL'utilisateur {originator} a demandé l'autorisation de souscrire au plan {planName}\npour la version {productVersion} du produit d'API {productName} dans le catalogue {catalog}.\n\n\nUtilisez le lien suivant pour ouvrir la demande dans votre liste de tâches :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nL'utilisateur {originator} a demandé l'autorisation de s'abonner avec l'application {appName}\nau plan {planName} pour la version {productVersion} du produit d'API {productName} \ndans le catalogue {catalog}.\n\nUtilisez le lien suivant pour ouvrir la demande dans votre liste de tâches :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nL'utilisateur {originator} a demandé l'autorisation de {action} l'application {appName} \ndans le catalogue {catalog}.\n\nUtilisez le lien suivant pour ouvrir la demande dans votre liste de tâches :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nL'utilisateur {originator} a demandé l'autorisation de {action} la version {version}\ndu produit d'API {productName} dans le catalogue {catalog}.\n\nUtilisez le lien suivant pour ouvrir la demande dans votre liste de tâches :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Bonjour {{firstName}},\n\nNous avons reçu une demande de réinitialisation du mot de passe de votre compte utilisateur \n{{username}} sur le portail de développeur {{catalog}}.\n\nSi vous n'êtes pas à l'origine de la demande, vous pouvez ignorer ce message. Sinon, \nvous pouvez réinitialiser votre mot de passe à l'aide de ce lien :\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Bonjour {firstName},\n\nNous avons reçu une demande de réinitialisation du mot de passe de votre compte utilisateur\n{username} dans IBM API Connect.\n\nUtilisez le lien suivant pour réinitialiser votre mot de passe :\n\n{link}\n\n(Si vous n'êtes pas à l'origine de la demande, vous pouvez ignorer ce message.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nNous avons reçu une demande de réinitialisation du mot de passe de votre compte utilisateur\n{username} dans le portail de développeur {catalog}.\n\nSi vous n'êtes pas à l'origine de la demande, vous pouvez ignorer ce message. Sinon, \nvous pouvez utiliser le lien suivant pour réinitialiser votre mot de passe :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Bonjour {firstName},\n\nVotre demande de création d'un abonnement pour l'application {appName} \ndans le catalogue {catalog} est en attente d'approbation. Nous vous enverrons une nouvelle notification\nlorsque la demande aura été traitée.\n\nVous pouvez vérifier le statut de l'application ici :\n\n{link}\n\nPour annuler à tout moment la demande, utilisez le lien suivant :\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Bonjour {{firstName}},\n\nVotre demande de création d'un abonnement pour l'application {{appName}} au plan\n{{planName}} pour le produit d'API {{productName}} version\n{{productVersion}} a été approuvée.\n\n{{#if comments}}\nLe valideur a inclus les commentaires suivants :\n\n"{{comments}}"\n\n{{/if}}\nVous pouvez vérifier le statut de l'application ici :\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de création d'un abonnement pour l'application {appName} au plan\n{planName} pour le produit d'API {productName} version\n{productVersion} a été acceptée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n{comments}\n\n{/if}\nVous pouvez vérifier le statut de l'application ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Bonjour {{firstName}},\n\nVotre demande de création d'un abonnement pour l'application {{appName}} au plan\n{{planName}} pour le produit d'API {{productName}} version\n{{productVersion}} a été refusée.\n\n{{#if comments}}\nLe valideur a inclus les commentaires suivants :\n\n"{{comments}}"\n\n{{/if}}\nVous pouvez vérifier le statut de l'application ici :\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Bonjour {{firstName}},\n\nVotre demande pour {{action}} le produit d'API {{productName}}:{{version}} dans le \ncatalogue {{catalog}} a été approuvée.\n\n{{#if comments}}\nLe valideur a inclus les commentaires suivants :\n\n{{comments}}\n\n{{/if}}\nVous pouvez vérifier le statut du produit ici :\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} du produit d'API {productName}:{version} dans le catalogue\n{catalog} a été approuvée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n{comments}\n\n{/if}\nVous pouvez vérifier le statut du produit ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Bonjour {firstName}},\n\nVotre demande de {action}} du produit d'API {productName}:{version} dans le catalogue\n{catalog} a été refusée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n{comments}\n\n{/if}\nVous pouvez vérifier le statut du produit ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} du produit d'API {productName}:{version} dans le catalogue\n{catalog} a été refusée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n{comments}\n\n{/if}\nVous pouvez vérifier le statut du produit ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} du produit d'API {productName}:{version} dans le catalogue\n{catalog} est en attente d'approbation. Nous vous enverrons une nouvelle notification\nlorsque la demande aura été traitée.\n\nVous pouvez vérifier le statut du produit ici :\n\n{link}\n\nPour annuler à tout moment la demande, utilisez le lien suivant :\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} de l'application {appName} dans\nle portail de développeur {consumerOrg} a été approuvée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n"{comments}"\n\n{/if}\nVous pouvez vérifier le statut de l'application ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} de l'application {appName} dans\nle portail de développeur {consumerOrg} a été approuvée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n{comments}\n\n{/if}\nVous pouvez vérifier le statut de l'application ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} de l'application {appName} dans\nle portail de développeur {consumerOrg} a été refusée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n"{comments}"\n\n{/if}\nVous pouvez vérifier le statut de l'application ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} de l'application {appName} dans\nle portail de développeur {consumerOrg} a été refusée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n{comments}\n\n{/if}\nVous pouvez vérifier le statut de l'application ici :\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} de l'application {appName} dans le portail de développeur {consumerOrg}\nest en attente d'approbation. Nous vous enverrons une nouvelle notification\nlorsque la demande aura été traitée.\n\nPour annuler à tout moment la demande, utilisez le lien suivant :\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur de compte d'IBM API Connect vous a invité à créer\nune organisation de type fournisseur d'API. Une organisation de type fournisseur vous permet, à vous et à votre\néquipe, de développer des API et de gérer des catalogues de produits d'API.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur du catalogue d'API {catalog}, appartenant à l'organisation de type fournisseur d'API\n{org} dans IBM API Connect, vous a invité\nà créer un espace dans le catalogue. Un espace vous permet, à vous et à votre équipe, de\ngérer un ensemble de produits d'API dans un catalogue d'API.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur du catalogue d'API {catalog}, appartenant à l'organisation de type fournisseur d'API\n{org} dans IBM API Connect, vous a invité à\nrejoindre le catalogue.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur du portail de développeur {catalog} vous a invité\nà créer une organisation de type consommateur d'API. Une organisation de type consommateur vous permet,\nà vous et à votre équipe, d'accéder aux API et d'enregistrer des applications client.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur de l'organisation de type consommateur d'API {consumerOrg} du portail de développeur {catalog}\nvous a invité à rejoindre l'organisation.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur de l'organisation de type fournisseur d'API {org} dans IBM API\nConnect vous a invité à créer un catalogue d'API. Un catalogue vous permet,\nà vous et à votre équipe de publier et de gérer un collection de produits d'API.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur de l'organisation de type fournisseur d'API {org} dans IBM API\nConnect vous a invité à rejoindre l'organisation.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur de l'espace {space} dans le catalogue d'API {catalog},\nappartenant à l'organisation de type fournisseur d'API {org} dans IBM API Connect,\nvous a invité à rejoindre l'espace.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Bonjour,\n\nLe mot de passe du compte utilisateur {username}\ndans IBM API Connect a été correctement modifié.\n\nVous pouvez vous connecter à API Connect ici :\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Bonjour,\n\nLe mot de passe de votre compte utilisateur {username}\ndans le portail de développeur {portalTitle} a été modifié.\n\nVous pouvez vous connecter au portail de développeur {portalTitle} ici :\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Bonjour,\n\nL'administrateur système d'IBM API Connect vous a invité à\nrejoindre l'organisation {org}.\n\nUtilisez le lien ci-dessous pour continuer :\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Bonjour,\n\nCeci est un message test envoyé par IBM API Connect depuis le serveur de messagerie configuré \n{mailServer}.\n\nSi vous recevez ce message, le test a fonctionné !\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Bonjour,\n\nL'utilisateur {originator} a retiré sa demande d'enregistrement au plan {planName} pour la\nversion {productVersion} du produit d'API {productName} dans le catalogue {catalog}.\n\nAucune autre action n'est requise.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Bonjour,\n\nL'utilisateur {originator} a retiré sa demande de {action} l'application {appName} dans le catalogue {catalog}.\n\nAucune autre action n'est requise.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Bonjour,\n\nL'utilisateur {originator} a retiré sa demande de {action} la version {version} du produit d'API {productName}\n dans le catalogue {catalog}.\n\nAucune autre action n'est requise.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Bonjour, Ceci est un message test envoyé par IBM API Connect depuis le serveur de messagerie configuré {{{mailServer}}}. Si vous recevez ce message, le test a fonctionné.`,
  [`Hello|header`]: `Bonjour`,
  [`Help`]: `Aide`,
  [`Hi, Steve (todo)`]: `Bonjour Stéphane (todo)`,
  [`Hide`]: `Masquer`,
  [`Hide field`]: `Masquer la zone`,
  [`Hide in schema`]: `Masquer dans schéma`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Masquez de nouveaux types, zones, directives ou arguments facultatifs. Les éléments qui ne sont plus pris en charge par le système dorsal seront supprimés.`,
  [`High`]: `Elevé`,
  [`History`]: `Historique`,
  [`Histories`]: `Historiques`,
  [`History log`]: `Journal de l'historique`,
  [`Home`]: `Accueil`,
  [`Host`]: `Hôte`,
  [`Host context variable`]: `Variable contextuelle de l'hôte`,
  [`Host name`]: `Nom d'hôte`,
  [`Host value (if present)`]: `Valeur d'hôte (le cas échéant)`,
  [`Hostname`]: `Nom d'hôte`,
  [`Hours`]: `Heures`,
  [`Http basic authentication schema`]: `Schéma d'authentification de base HTTP`,
  [`Hmm, your search didn't return any results.`]: `Votre recherche n'a renvoyé aucun résultat.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `Cloud IBM API Connect`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `Authentification d'IBM Cloud`,
  [`IBM Cloud Docs`]: `Documentation IBM Cloud`,
  [`IBM Schema`]: `Schéma IBM`,
  [`IBM managed`]: `Géré par IBM`,
  [`ID Name`]: `Nom de l'ID`,
  [`ID token custom claims`]: `Réclamations personnalisées de jeton d'ID`,
  [`ID token issuer`]: `Emetteur du jeton d'ID`,
  [`ID token signing algorithm`]: `Algorithme de signature du jeton d'ID`,
  [`ID token signing crypto object`]: `Objet cryptographique de signature du jeton d'ID`,
  [`ID token signing key`]: `Clé de signature du jeton d'ID`,
  [`ID token signing key identifier`]: `Identificateur de la clé de signature du jeton d'ID`,
  [`IDENTITY PROVIDER`]: `FOURNISSEUR D'IDENTITE`,
  [`IDENTITY PROVIDER SUPPORT`]: `PRISE EN CHARGE DU FOURNISSEUR D'IDENTITE`,
  [`Identification`]: `Identification`,
  [`Identity extraction`]: `Extraction d'identité`,
  [`If`]: `Si`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Si le mode production est désactivé, ce qui signifie qu'il s'agit d'un catalogue de développement, toutes les opérations de publication sont appliquées, et tout conflit est résolu automatiquement, ce qui vous permet, par exemple, de republier de manière répétée la même version de produit durant le test.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Si le mode production est activé, ce qui signifie qu'il s'agit d'un catalogue de production, vous ne pourrez pas publier un produit dans le catalogue s'il existe des conflits avec des produits déjà publiés. Vous devrez résoudre les éventuels conflits avant de relancer l'opération de publication.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Si les espaces sont activés dans un catalogue, vous pouvez gérer les membres au sein de l'espace. Vous gérez l'appartenance à l'espace en ajoutant de nouveaux membres à l'espace et en affectant des rôles aux utilisateurs.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Si cette option est activée, les propriétés d'entrée de valeur null seront mappées au document de sortie. Sinon, ces propriétés seront omises dans la sortie.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Si le mode production est activé, les actions de mise en préproduction et de publication ne seront pas forcées. Si des conflits sont détectés, ils sont résolus automatiquement par le système. Les actions d'annulation de la publication sont effectuées automatiquement.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Si les destinataires n'ont pas reçu le courrier électronique, vérifiez les paramètres du serveur de messagerie.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Si les destinataires n'ont pas reçu le courrier électronique, vérifiez les paramètres de serveur de messagerie pour {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Si cette propriété est définie sur true, tous les caractères + inclus dans les valeurs de paramètre de requête de l'URL cible des stratégies Invoke et Proxy sont codés en %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Si cette propriété est définie sur true, tout paramètre de demande référencé par une définition de variable sur une URL cible invoke est décodé par l'URL.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Si cette propriété est définie sur true, la stratégie invoke envoie du contenu dans une méthode HTTP DELETE.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Si définie sur true, la propriété est occultée ou supprimée des journaux d'activité.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Si définie sur true, la propriété est occultée ou supprimée de l'entrée au début de l'exécution.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Si définie sur true, la propriété est occultée ou supprimée de la réponse.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Si des espaces sont activés dans un catalogue, vous pouvez gérer l'accès des utilisateurs dans l'espace. Pour ce faire, vous spécifiez les droits d'accès à affecter aux rôles utilisateur. Les droits qui sont affectés à chaque rôle par défaut lorsque vous créez un espace sont déterminés par les paramètres définis dans le modèle des droits d'espace par défaut.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Si la case est cochée (option par défaut), les espaces de nom XML sont hérités de l'élément parent. Décochez cette case si vous souhaitez que la stratégie map utilise des espaces de nom explicites.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Si cette case est cochée (option par défaut), les espaces de nom XML seront insérés dans le document où ils sont utilisés pour la première fois. Décochez cette case si vous souhaitez que tous les espaces de nom soient définis sur l'élément racine.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Si la case est cochée (option par défaut), les éléments XML vides sont inclus dans la sortie de la stratégie map. Décochez cette case si vous ne souhaitez pas que les éléments XML vides soient inclus dans la sortie de la stratégie map.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Si la case à cocher est sélectionnée, le post-traitement de la sortie JSON mappée est activé. Le post-traitement de la sortie JSON utilise le schéma de sortie pour s'assurer que les valeurs de propriété sont du même type que celui défini dans le schéma. Cette option normalise également les valeurs de propriété de sortie ayant une syntaxe JSON Badgerfish résultant d'un mappage d'objet d'une entrée XML. Désactivez la case à cocher (valeur par défaut) pour que la sortie JSON mappée ne soit pas post-traitée.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Si la case est cochée (option par défaut), les références à la variable API Connect trouvées dans les propriétés de mappe seront résolues. Décochez cette case si vous souhaitez que la stratégie map ignore les références à la variable API Connect dans les stratégies map.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Si la case est cochée, les références à la variable API Connect trouvées dans les propriétés de mappe seront résolues. Désélectionnez cette case (option par défaut) si vous souhaitez que la stratégie de mappe ignore les références à la variable API Connect dans les stratégies de mappe.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Si la case est cochée (option par défaut), seul le premier élément sera utilisé si un tableau est rencontré dans l'entrée. Décochez cette case si vous souhaitez que la stratégie map utilise tous les éléments de tableau.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Si la case est cochée, les éléments XML dont le schéma est configuré en tant que type booléen ou numérique seront convertis en données de ce type. Si elle n'est pas cochée (option par défaut), toutes les valeurs d'élément XML sont renvoyées sous forme de chaîne.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Si cette case est cochée, l'évaluation de types de schéma complexes traitera les références de type circulaire de façon optimisée. Décochez cette case (option par défaut) pour évaluer ces types de schéma de façon standard.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Si l'erreur est due à une absence de support CORS, cliquez sur le lien pour ouvrir le serveur dans un nouvel onglet. Si le navigateur affiche un problème lié au certificat, vous pouvez l'accepter et revenir ici pour procéder à de nouveaux tests.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Si cette case est cochée, dans un tableau de sortie ou une propriété enfant d'un objet facultatif noté dans le schéma, le cas échéant, les propriétés d'objet enfant requises sont initialisées sur une valeur par défaut sauf si elles sont mappées correctement. Cela permettra d'émuler la façon dont le mappage de la version 4 a été effectué pour les propriétés requises pour ces objets. Désélectionnez cette case (valeur par défaut) pour générer uniquement ces objets avec des données mappées correctement.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Si cette case est cochée (par défaut) et que l'utilisateur propriétaire est modifié, la propriété des ressources enfant (Espaces) est également transférée au nouveau propriétaire.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Si cette option est sélectionnée, la stratégie de mappage inscrit la sortie XML dans le corps du message en tant que document XML analysé. Par défaut, l'élément XML aura la forme d'une chaîne XML. La sortie XML de toute autre variable aura toujours la forme d'une chaîne XML.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Si vous activez l'approbation du cycle de vie pour les actions de mise en préproduction, de publication ou de remplacement, certaines opérations de création, d'édition ou de test d'API qui exécutent automatiquement ces actions risquent de ne pas fonctionner correctement. Voulez-vous vraiment activer ces approbations ?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Si vous voulez exposer un service SOAP existant via une définition d'API API Connect, vous pouvez créer une API et spécifier le service SOAP cible. API Connect créera une définition d'API qui appelle ce service SOAP.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Si vous sauvegardez vos modifications en cliquant sur "Oui. Mettre à jour l'assemblage d'API", l'assemblage d'API sous-jacent de ce fournisseur OAuth sera également mis à jour. Cependant, si vous avez personnalisé l'assemblage d'API sous-jacent et que vous souhaitez le mettre à jour séparément, cliquez sur "Non. Ne pas mettre à jour l'assemblage d'API". Ensuite, mettez à jour l'assemblage d'API sous-jacent dans l'éditeur.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Si vous rencontrez toujours des problèmes, téléchargez un fichier de langage de définition de schéma.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Si votre fichier contient la clé privée ainsi que la clé publique, téléchargez-le à l'étape 1`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Si vous devez enregistrer une passerelle située derrière un pare-feu, consultez les documents IBM Cloud pour plus d'informations sur l'utilisation d'un réseau privé virtuel ou du service de passerelle sécurisée pour établir la connexion.`,
  [`Ignore`]: `Ignorer`,
  [`Implement policy`]: `Implémenter la stratégie`,
  [`Implementation file is required`]: `Le fichier d'implémentation est requis`,
  [`Implicit`]: `Implicite`,
  [`Import`]: `Importer`,
  [`Import API`]: `Importation d'API`,
  [`Import API|title`]: `Importation d'API`,
  [`Import OpenAPI definition from asset repository`]: `Importer la définition OpenAPI depuis le référentiel d'actifs`,
  [`Import Product`]: `Importation de produit`,
  [`Import policy`]: `Importer une stratégie`,
  [`Import a product from a service URL`]: `Importez un produit depuis une URL de service`,
  [`Import a product from the definition file`]: `Importez un produit depuis un fichier de définition`,
  [`Import an existing product`]: `Importation d'un produit existant`,
  [`Import an OpenAPI definition from a URL`]: `Importez une définition OpenAPI depuis une URL`,
  [`Import an OpenAPI definition from a file`]: `Importez une définition OpenAPI depuis un fichier`,
  [`Import definitions`]: `Importer des définitions`,
  [`Import from existing API service`]: `Importer du service d'API existant`,
  [`Import from file`]: `Importation à partir d'un fichier`,
  [`Importing an API definition`]: `Importation d'une définition d'API`,
  [`Import|title`]: `Importer`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Importez les certificats et les bundles d'autorité de certification requis dans le service de gestionnaire de certificat.`,
  [`In Cookie Header`]: `In Cookie Header`,
  [`In WSSec Header`]: `In WSSec Header`,
  [`In order to invoke the endpoints above, you need to first`]: `Pour appeler les noeuds finaux ci-dessus, vous devez au préalable`,
  [`Inactive`]: `Inactif`,
  [`Inc`]: `Inclure`,
  [`Include empty`]: `Inclure un élément vide`,
  [`Include free trial days`]: `Inclure les jours d'essai gratuit`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Les API incompatibles avec le service de passerelle configuré ne peuvent pas être sélectionnées.`,
  [`Incorrect username or password`]: `Nom d'utilisateur ou mot de passe incorrect`,
  [`Incorrect username, password, or credentials`]: `Nom d'utilisateur, mot de passe ou données d'identification incorrects`,
  [`Indicate if the invoke is the final action`]: `Indiquez si l'appel est l'action finale`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Indique si une réclamation d'ID de jeton Web JSON (jti) doit être ajoutée au jeton Web JSON. Si cette option est sélectionnée, la valeur de la réclamation jti est un UUID.`,
  [`Indicates whether the clients secret is required.`]: `Indique si les valeurs confidentielles des clients sont requises.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Indique si ce document d'entrée XSLT utilise le contenu en cours du contexte ou s'il n'y a pas d'entrée.`,
  [`Indicates whether to stop processing if client security fails.`]: `Indique si le traitement doit s'arrêter en cas d'échec de la sécurité du client.`,
  [`Infer from JSON schema`]: `Induire du schéma JSON`,
  [`Infer from Sample`]: `Induire de l'exemple`,
  [`Info`]: `Informations`,
  [`Information context variable`]: `Variable contextuelle d'informations`,
  [`Inject proxy headers`]: `Injecter des en-têtes de proxy`,
  [`Inline schema`]: `Schéma intégré`,
  [`Input`]: `Entrée`,
  [`Input field`]: `Zone d'entrée`,
  [`Input object`]: `Objet d'entrée`,
  [`Insecure Server Connections`]: `Connexions serveur non sécurisées`,
  [`Install API Connect CLI & API Designer|button text`]: `Installer l'interface CLI d'API Connect et API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `Installer l'interface CLI d'API Connect et API Designer`,
  [`Install API Connect Toolkit`]: `Installer le kit d'outils API Connect`,
  [`Install the API Connect plugin`]: `Installer le plug-in API Connect`,
  [`Instance ID`]: `ID d'instance`,
  [`Instance name`]: `Nom d'instance`,
  [`Instance owner`]: `Propriétaire d'instance`,
  [`Interact to expand Tile`]: `Interagir pour développer la vignette`,
  [`Interact to collapse Tile`]: `Interagir pour réduire la vignette`,
  [`Interval`]: `Intervalle`,
  [`Interface`]: `Interface`,
  [`Introspect`]: `Procéder à l'introspection`,
  [`Introspect cache type`]: `Type de cache d'introspection`,
  [`Introspect from Server URL`]: `Procéder à l'introspection à partir de l'URL de serveur`,
  [`Introspect from URL`]: `Procéder à l'introspection depuis l'URL`,
  [`Introspect URL`]: `URL d'introspection`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `L'introspection à l'aide d'une adresse URL différente ne change pas l'URL du serveur GraphQL.`,
  [`Introspect token`]: `Procéder à l'introspection du jeton`,
  [`Introspection`]: `Introspection`,
  [`Introspection Path`]: `Chemin d'introspection`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Procéder à l'introspection du schéma GraphQL à partir du serveur ou télécharger le schéma`,
  [`Introspect the GraphQL schema using the query`]: `Procéder à l'introspection du schéma GraphQL à l'aide de la requête`,
  [`Introspection URL`]: `URL de l'introspection`,
  [`Invalid HOST URL`]: `URL HOST non valide`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `URL non valide. Veuillez entrer une URL HOST API Manager valide, par exemple : https://api-connect-host.com`,
  [`Invalid file URL`]: `URL de fichier non valide`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Le format d'URL n'est pas valide ; il doit commencer par http:// ou https:// et contenir au moins 1 caractère`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Format d'URL non valide, seul le nom d'hôte doit être spécifié. Par exemple, syslog:// peut être retiré`,
  [`Invalid emails.`]: `Adresses électroniques non valides.`,
  [`Invalid JSONata expression`]: `Expression JSONata non valide`,
  [`Invalid remove target`]: `Retrait de cible non valide`,
  [`Invalid assumed size`]: `Taille supposée non valide`,
  [`Invalid cost target`]: `Cible de coût non valide`,
  [`Invalid list size`]: `Taille de liste non valide`,
  [`Invalid port value, must not be negative`]: `Valeur de port non valide, ne doit pas être négative`,
  [`Invalid sized fields`]: `Zones dimensionnées non valides`,
  [`Invalid value, length has to be greater than 1`]: `Valeur non valide, la longueur doit être supérieure à 1`,
  [`Invalid weight value`]: `Valeur de pondération non valide`,
  [`invitation`]: `invitation`,
  [`Invitation`]: `Invitation`,
  [`Invitation timeout`]: `Délai d'expiration d'invitation`,
  [`Invitation link will expire in {time}`]: `Le lien d'invitation expire dans **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Le lien d'invitation expire dans **{seconds} secondes ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Le lien d'invitation expire dans __{value} {unit}__.`,
  [`Invitation sent to`]: `Invitation envoyée à`,
  [`Invitation sent to {email}`]: `Invitation envoyée à {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Invitation à un catalogue d'API dans IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Invitation à une organisation de type consommateur dans le portail de développeur {catalog}`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Invitation à une organisation de type fournisseur d'API dans IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Invitation à un espace d'API dans IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Invitation à une organisation admin dans IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Invitation à créer un catalogue d'API dans IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Invitation à créer une organisation de type consommateur d'API dans le portail de développeur {catalog}`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Invitation à créer une organisation de type fournisseur d'API dans IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Invitation à créer un espace d'API dans IBM API Connect`,
  [`Invitation ttl has been created.`]: `La durée de vie de l'invitation a été créée.`,
  [`Invitation ttl has been updated.`]: `La durée de vie de l'invitation a été mise à jour.`,
  [`Invitation ttl has not been created!`]: `La durée de vie de l'invitation n'a pas été créée.`,
  [`Invitation ttl has not been updated!`]: `La durée de vie de l'invitation n'a pas été mise à jour !`,
  [`Invite`]: `Inviter`,
  [`Invite catalog owner`]: `Inviter un propriétaire de catalogue`,
  [`Invite consumer organization owner`]: `Invitation du propriétaire d'organisation de type consommateur`,
  [`Invite member`]: `Inviter un membre`,
  [`Invite organization owner`]: `Inviter un propriétaire d'organisation`,
  [`Invite space owner`]: `Inviter un propriétaire d'espace`,
  [`Invite a new consumer organization owner`]: `Invitation d'un nouveau propriétaire d'organisation de type consommateur`,
  [`Invite a new member`]: `Invitation d'un nouveau membre`,
  [`Invite a new provider organization owner`]: `Invitation d'un nouveau propriétaire d'organisation de type fournisseur`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Invitez un nouvel utilisateur à s'enregistrer en tant que gestionnaire de catalogue en entrant l'adresse électronique du destinataire. Un courrier électronique d'invitation comportant un lien vers le formulaire d'enregistrement est envoyé à l'utilisateur.`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Invitez un nouvel utilisateur à s'enregistrer en tant que gestionnaire d'un espace en entrant l'adresse électronique du destinataire. Un courrier électronique d'invitation comportant un lien vers le formulaire d'enregistrement est envoyé à l'utilisateur.`,
  [`Invite by email`]: `Inviter par courrier électronique`,
  [`Invite from User Registry`]: `Inviter depuis le registre d'utilisateurs`,
  [`Invite team member`]: `Inviter un membre de l'équipe`,
  [`Inviting members to consumer organizations is enabled`]: `L'option d'invitation de membres à rejoindre des organisations de type consommateur est activée`,
  [`Invocations`]: `Appels`,
  [`Invoke`]: `Appeler`,
  [`Issuer Claim`]: `Réclamation de l'émetteur`,
  [`Issuer Origin`]: `Origine de l'émetteur`,
  [`Issuer common name`]: `Nom usuel de l'émetteur`,
  [`It might influence how the API is deployed`]: `Il peut influencer le déploiement de l'API`,
  [`Italian`]: `Italien`,
  [`Items per page`]: `Eléments par page`,
  [`Items per page:`]: `Eléments par page :`,
  [`items selected`]: `éléments sélectionnés`,
  [`item selected`]: `élément sélectionné`,
  [`Iterate on`]: `Itérer sur`,
  [`Iterate?`]: `Itérer ?`,
  [`JSON objects describing re-usable channel parameters.`]: `Objets JSON décrivant les paramètres de canal réutilisables.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `Objets JSON décrivant les messages utilisés et générés par l'API.`,
  [`JSON Schema URL`]: `URL du schéma JSON`,
  [`JSON Schema preview`]: `Aperçu du schéma JSON`,
  [`JSON Web Token (JWT)`]: `Jeton Web JSON (JWT)`,
  [`JSON has been successfully validated`]: `Le service JSON a été validé`,
  [`JSON schema is not valid. Please correct the error.`]: `Le schéma JSON n'est pas valide. Corrigez l'erreur.`,
  [`JSON to XML`]: `JSON en XML`,
  [`JWT ID Claim`]: `Réclamation de l'ID JWT`,
  [`JWT verification JWK`]: `JWK de vérification JWT`,
  [`JWT verification crypto object`]: `Objet cryptographique de vérification JWT`,
  [`Japanese`]: `Japonais`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `File d'attente de travaux`,
  [`Jump to source`]: `Saut à la source`,
  [`Just now`]: `A l'instant`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `ECHANGE DE CLE/CHIFFREMENT/MAC`,
  [`KEY SIZE`]: `TAILLE DE LA CLE`,
  [`Keystore`]: `Magasin de clés`,
  [`keystore`]: `magasin de clés`,
  [`Keep`]: `Conserver`,
  [`Keep Payload`]: `Conserver le contenu`,
  [`Key Encryption Algorithm`]: `Algorithme de chiffrement de clé`,
  [`Key Name`]: `Nom de clé`,
  [`Key type`]: `Type de clé`,
  [`Key name`]: `Nom de clé`,
  [`Keystore for the token has been updated.`]: `Le magasin de clés du jeton a été mis à jour.`,
  [`Keystore Certificates`]: `Certificats de magasin de clés`,
  [`Keystore/truststore`]: `Magasin de clés/Magasin de clés de confiance`,
  [`Korean`]: `Coréen`,
  [`LABEL`]: `LIBELLE`,
  [`LABELS`]: `LIBELLES`,
  [`LAST MODIFIED`]: `DERNIERE MODIFICATION`,
  [`Last state changed`]: `Dernière modification de l'état`,
  [`Large`]: `Grand`,
  [`Lax`]: `Souple`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `Groupes LDAP`,
  [`LDAP user registry`]: `Registre d'utilisateurs LDAP`,
  [`LDAP registry`]: `Registre LDAP`,
  [`LDAP registry name`]: `Nom de registre LDAP`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Le registre d'utilisateurs LDAP du rôle {{name}} ne prend plus en charge le mappage de groupe externe. Activer le mappage de groupe externe pour ce registre d'utilisateurs ou désactivez le mappage de groupe externe dans le rôle avant l'enregistrement.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Le registre d'utilisateurs LDAP du rôle {{name}} ne prend plus en charge le mappage de groupe externe. Sélectionnez un autre registre d'utilisateurs avec le mappage de groupe externe ou désactivez le mappage de groupe externe dans le rôle avant d'enregistrer.`,
  [`LDAP search attribute`]: `Attribut de recherche LDAP`,
  [`LOCATED IN`]: `SITUE DANS`,
  [`Located in`]: `Situé dans`,
  [`LTPA Key`]: `Clé LTPA`,
  [`LTPA Key Details`]: `Détails de la clé LTPA`,
  [`LTPA Keys`]: `Clés LTPA`,
  [`LTPA key file`]: `Fichier de clés LTPA`,
  [`LTPA key password`]: `Mot de passe de clé LTPA`,
  [`LUR data version`]: `Version de données LUR`,
  [`LUR schema update date`]: `Date de mise à jour du schéma LUR`,
  [`LUR schema version`]: `Version du schéma LUR`,
  [`LUR target data version`]: `Version de données cible LUR`,
  [`LUR target schema version`]: `Version du schéma cible LUR`,
  [`LUR update date`]: `Date de mise à jour LUR`,
  [`Last name`]: `Nom`,
  [`Last modified`]: `Dernière modification`,
  [`Launch portal`]: `Lancer le portail`,
  [`Launch IBM Automation management console`]: `Lancer la console de gestion IBM Automation`,
  [`Launch plan upgrade`]: `Lancer la mise à niveau du plan`,
  [`Learn more`]: `En savoir plus`,
  [`Learn More`]: `En savoir plus`,
  [`Legacy`]: `Existant`,
  [`Length cannot be below {min}`]: `La longueur ne peut pas être inférieure à {min}`,
  [`Length cannot exceed {max}`]: `La longueur ne peut pas être supérieure à {max}`,
  [`Let's customize API Connect`]: `Personnalisation d'API Connect`,
  [`Let's get started...`]: `Commençons...`,
  [`Let's get you up and running`]: `Commençons`,
  [`License`]: `Licence`,
  [`Licensed Materials - Property of IBM`]: `Eléments sous licence - Propriété d'IBM`,
  [`Lifecycle`]: `Cycle de vie`,
  [`Lifecycle approvals`]: `Approbations du cycle de vie`,
  [`Lifecycle actions have been updated`]: `Les actions de cycle de vie ont été mises à jour`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Les approbations de cycle de vie sont activées pour le catalogue pour bac à sable. Pour utiliser l'option d'activation d'API automatique, vous devez d'abord désactiver les approbations du cycle de vie dans les paramètres de catalogue pour bac à sable.`,
  [`Limited fields`]: `Zones limitées`,
  [`Limit API calls on a per key basis`]: `Limiter les appels API clé par clé`,
  [`Limit fields`]: `Limiter les zones`,
  [`Limit renegotiation`]: `Limiter la renégociation`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Charger`,
  [`Load query`]: `Charger la requête`,
  [`Load filter`]: `Charger le filtre`,
  [`Load schema from WSDL`]: `Charger le schéma à partir de WSDL`,
  [`Loading`]: `Chargement`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Les registres d'utilisateurs locaux sont les registres d'utilisateurs par défaut inclus dans API Connect et sont stockés localement.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Les registres d'utilisateurs locaux sont les registres d'utilisateurs par défaut inclus dans API Connect et stockés localement. Deux registres d'utilisateurs locaux sont configurés au cours de l'installation d'API Connect. Vous pouvez en configurer d'autres.`,
  [`Local user registry`]: `Registre d'utilisateurs local`,
  [`Local User Registry has been created.`]: `Le registre d'utilisateurs local a été créé.`,
  [`Location`]: `Emplacement`,
  [`Location of ciphertext to decrypt`]: `Emplacement du texte chiffré à déchiffrer`,
  [`Location of plaintext information to encrypt`]: `Emplacement des informations en texte en clair à chiffrer`,
  [`Log`]: `Journal`,
  [`Log in`]: `Connexion`,
  [`Log in to:`]: `Connexion à :`,
  [`Log in with a different account`]: `Se connecter avec un autre compte`,
  [`Log into IBM Cloud`]: `Connexion à IBM Cloud`,
  [`Log out`]: `Déconnexion`,
  [`Log in using the {userRegistryType} user registry`]: `Connectez-vous à l'aide du registre d'utilisateurs {userRegistryType}`,
  [`Logging in with IBM Cloud ...`]: `Connexion à IBM Cloud en cours...`,
  [`Logging out`]: `Déconnexion`,
  [`Logic`]: `Logique`,
  [`Login`]: `Connexion`,
  [`Logs`]: `Journaux`,
  [`Longer, descriptive name for this policy.`]: `Nom plus long et descriptif de cette stratégie`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Il semble que le serveur ait rencontré une erreur lors du traitement de votre demande.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Serveur de messagerie`,
  [`mail-server-test-connection`]: `connexion-test-serveur-messagerie`,
  [`MEMBER`]: `MEMBRE`,
  [`MIME Types`]: `Types MIME`,
  [`Mail server has been deleted.`]: `Le serveur de messagerie a été supprimé.`,
  [`Mail server has not been deleted.`]: `Le serveur de messagerie n'a pas été supprimé.`,
  [`Mailserver {arg} has been removed.`]: `Le serveur de messagerie {arg} a été retiré.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Vérifiez que le serveur est en cours d'exécution et que vous disposez d'une connectivité réseau.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Il se peut que CORS ne soit pas pris en charge sur le serveur cible, que le serveur ne soit pas disponible, qu'un certificat non digne de confiance ait été rencontré ou qu'une authentification SSL mutuelle soit requise.`,
  [`This might be caused by one or more of the following:`]: `Cela peut être dû à un ou plusieurs des éléments suivants :`,
  [`Make a call and move on`]: `Appeler et poursuivre`,
  [`Make a call and wait for response`]: `Appeler et attendre la réponse`,
  [`Manage`]: `Gérer`,
  [`Manage API life cycles`]: `Gérer les cycles de vie d'API`,
  [`Manage API product lifecycle approval permissions`]: `Gestion des droits pour les approbations du cycle de vie des produits d'API`,
  [`Manage API product lifecycle approvals permissions`]: `Gérer les droits pour les approbations du cycle de vie des produits d'API`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Gérez les organisations de type fournisseur d'API, les passerelles locales et éloignées, ainsi que les paramètres associés.`,
  [`Manage APIs`]: `Gérer les API`,
  [`Manage Catalogs`]: `Gestion de catalogues`,
  [`Manage Organizations`]: `Gestion d'organisations`,
  [`Manage Resources`]: `Gestion de ressources`,
  [`Manage Settings`]: `Gestion de paramètres`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Gérez les destinations pour l'analyse des API, notamment API Connect, HTTP, Kafka, Elasticsearch et Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Gérez les passerelles locales et éloignées qui sécurisent vos API.`,
  [`Manage catalogs`]: `Gestion de catalogues`,
  [`Manage organizations`]: `Gestion d'organisations`,
  [`Manage resources`]: `Gestion de ressources`,
  [`Manage settings`]: `Gestion de paramètres`,
  [`Manage users`]: `Gérer les utilisateurs`,
  [`Manage active APIs and consumers`]: `Gérez API et consommateurs actifs`,
  [`Manage availability zones and services`]: `Gérez les zones de disponibilité et les services`,
  [`Manage policies`]: `Gérer les stratégies`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Gérez les demandes de changement du cycle de vie du produit ainsi que les demandes de mise à niveau d'application et d'abonnement d'API émises par des développeurs d'applications`,
  [`Manage roles and permissions`]: `Gérez les rôles et les droits`,
  [`Manage the OAuth providers configured for API Manager`]: `Gérez les fournisseurs OAuth configurés pour API Manager`,
  [`Manage the user registries configured for API Manager`]: `Gérez les registres d'utilisateurs configurés pour API Manager`,
  [`Manage the user registries configured for Cloud Manager`]: `Gérez les registres d'utilisateurs configurés pour le gestionnaire de cloud`,
  [`Management`]: `Gestion`,
  [`Management Endpoint`]: `Noeud final de gestion`,
  [`Management endpoint`]: `Noeud final de gestion`,
  [`Management endpoint on the analytics service`]: `Noeud final de gestion sur le service d'analyse`,
  [`Service endpoint configuration`]: `Configuration du noeud final du service`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Noeud final utilisé par le service de gestion pour envoyer les informations de configuration au service de portail`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Profil de client TLS utilisé par le service de gestion lors de la communication avec le service de portail`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Noeud final utilisé par le service de gestion pour envoyer les informations de configuration au service de passerelle`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `Profil de client TLS utilisé par le service de gestion lors de la communication avec le service de passerelle`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Utiliser la communication intra-cluster pour le trafic interne entre la passerelle et le service de gestion`,
  [`Use in-cluster communication for both ingestion and queries`]: `Utiliser la communication intra-cluster à la fois pour l'ingestion et les requêtes`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Utiliser l'intra-cluster pour l'ingestion et l'externe pour les requêtes, ou inversement`,
  [`Use external communication for both ingestion and queries`]: `Utiliser la communication externe à la fois pour l'ingestion et les requêtes`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Utiliser la communication intra-cluster pour le trafic interne entre le service d'analyse et le service de gestion`,
  [`Management endpoint on the gateway service`]: `Noeud final de gestion sur le service de passerelle`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Utiliser la communication intra-cluster pour le trafic interne entre le portail et le service de gestion`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Si vous souhaitez utiliser la communication intra-cluster entre les sous-systèmes de gestion et de portail, activez ce commutateur. Si vous n'êtes pas sûr, laissez ce commutateur désactivé (option par défaut).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Si vous souhaitez utiliser la communication intra-cluster entre les sous-systèmes de gestion et de passerelle, activez ce commutateur. Si vous n'êtes pas sûr, laissez ce commutateur désactivé (option par défaut).`,
  [`Management endpoint on the portal service`]: `Noeud final de gestion sur le service de portail`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Il s'agit de l'option par défaut qui est utilisée pour les services d'analyse mis à niveau à partir d'une édition antérieure à la version 10.0.5.3. Pour les déploiements OVA, ou si votre sous-système d'analyse se trouve dans un environnement différent, "externe" est la seule option que vous pouvez utiliser. La passerelle et le service de gestion communiquent avec le service d'analyse à l'aide du noeud final externe, qui est une entrée Kubernetes ou une route OpenShift en fonction de votre plateforme.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `Vous pouvez sélectionner "intra-cluster" si vous disposez d'un déploiement Kubernetes ou OpenShift dans lequel tous les sous-systèmes se trouvent dans le même cluster. Lorsque cette option est sélectionnée, les sous-systèmes de gestion, de passerelle et d'analyse communiquent entre eux via des noeuds finaux de service internes plutôt que par des entrées (Kubernetes) ou des routes (OpenShift) accessibles en externe.`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Vous pouvez sélectionner cette option si vous disposez d'un déploiement Kubernetes ou OpenShift et que certains de vos sous-systèmes sont installés dans le même cluster. Lorsque cette option est sélectionnée, vous pouvez utiliser différentes méthodes de communication pour les différents flux de communication d'analyse. Par exemple, la communication entre le service de gestion et le service d'analyse peut utiliser la communication intra-cluster et celle entre la passerelle et le service d'analyse peut utiliser la communication externe. Vous pouvez choisir cette configuration si votre passerelle se trouve dans un cluster différent du reste de vos sous-systèmes.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Si vous avez spécifié une communication intra-cluster lors de l'enregistrement du service d'analyse, vous pouvez activer la passerelle pour envoyer des données d'événement d'API au noeud final d'analyse interne.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Utiliser un noeud final interne pour l'ingestion de données d'analyse à partir du service de passerelle`,
  [`Endpoint configuration`]: `Utiliser la communication externe à la fois pour l'ingestion et les requêtes`,
  [`Portal endpoint configuration`]: `Configuration du noeud final de portail`,
  [`URL used by developer portal users to access the portal website`]: `URL utilisée par les utilisateurs du portail de développement pour accéder au site Web du portail`,
  [`External ingestion endpoint on the analytics service`]: `Noeud final d'ingestion externe sur le service d'analyse`,
  [`TLS client profile for external analytics ingestion endpoint`]: `Profil de client TLS pour le noeud final d'ingestion d'analyse externe`,
  [`Internal ingestion endpoint on the analytics service`]: `Noeud final d'ingestion interne sur le service d'analyse`,
  [`TLS client profile for internal service endpoint`]: `Profil de client TLS pour le noeud final de service interne`,
  [`Manager`]: `Responsable`,
  [`Manager Request Reset Password`]: `Mot de passe de réinitialisation de la demande du responsable`,
  [`Manager Reset Password`]: `Mot de passe de réinitialisation du responsable`,
  [`Manager Sign In`]: `Connexion du responsable`,
  [`Manages API provider organizations`]: `Gère des organisations de type fournisseur d'API`,
  [`Manages application developer communities`]: `Gère des communautés de développeurs d'applications`,
  [`Manages the API product lifecycle`]: `Gère le cycle de vie des produits d'API`,
  [`Manage|link`]: `Gérer`,
  [`Manage|permission`]: `Gérer`,
  [`Manage|title`]: `Manage:title`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Gérer l'affectation et l'historique du magasin de clés pour les jetons`,
  [`Map`]: `Mappe`,
  [`Map information from OIDC provider to an APIC user`]: `Mapper les informations du fournisseur OIDC à un utilisateur APIC`,
  [`Map the migration source plans to the migration target plans`]: `Mappez les plans de la source de migration sur les plans de la cible de migration`,
  [`Map the roles to groups`]: `Mapper les rôles aux groupes`,
  [`Mapped from: `]: `Mappé depuis : `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Agrandir/Réduire`,
  [`Maximum Consent Time to Live (seconds)`]: `Durée de vie du consentement maximal (en secondes)`,
  [`Maximum consent`]: `Consentement maximal`,
  [`Menu`]: `Menu`,
  [`Member`]: `Membre`,
  [`Member Invitation`]: `Invitation de membre`,
  [`Member Invitation has been deleted.`]: `L'invitation de membre a été supprimée.`,
  [`Member has been deleted.`]: `Le membre a été supprimé.`,
  [`Member {name} created`]: `Le membre {name} a été créé`,
  [`Member {title} has been created.`]: `Le membre {title} a été créé.`,
  [`Members`]: `Membres`,
  [`member-invitation`]: `invitation-membre`,
  [`Member Invitations`]: `Invitations de membre`,
  [`Member Roles`]: `Rôles de membre`,
  [`Merchant ID`]: `ID de vendeur`,
  [`Merge`]: `Fusion`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Fusionner ce mappage avec les autres qui agissent sur le même tableau cible ?`,
  [`Message`]: `Message`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Message pour l'analyse`,
  [`Message for resulting parsed data`]: `Message pour les données analysées générées`,
  [`Metadata`]: `Métadonnées`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Les métadonnées vous permettent de collecter des métadonnées personnalisées à inclure au cours du processus de génération des jetons d'accès. Vous pouvez collecter les métadonnées via une URL d'authentification et/ou un serveur distant sur lequel les métadonnées personnalisées sont stockées.`,
  [`Micro Gateway policies`]: `Stratégies Micro Gateway`,
  [`Migrate`]: `Migrer`,
  [`Migrate Subscriptions`]: `Migration des abonnements`,
  [`Migrate plans`]: `Migration des plans`,
  [`Migrate subscription`]: `Migrer l'abonnement`,
  [`Migrate subscriptions`]: `Migrer les abonnements`,
  [`Migration Target`]: `Cible de migration`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `La migration à partir de "{source}" (produit) vers "{target}" (produit) a été exécutée`,
  [`Migration from "{source}" failed`]: `Echec de la migration à partir de "{source}"`,
  [`Migration source`]: `Source de migration`,
  [`Migration target`]: `Cible de migration`,
  [`Minimum role`]: `Rôle minimum`,
  [`Minimum output escaping rule`]: `Règle d'échappement de sortie minimale`,
  [`Minutes`]: `Minutes`,
  [`Mode`]: `Mode`,
  [`Modifications`]: `Modifications`,
  [`Modified`]: `Modifié`,
  [`More`]: `Plus`,
  [`More than 20 events are waiting to be processed`]: `Plus de 20 événements sont en attente de traitement`,
  [`Move down`]: `Déplacer vers le bas`,
  [`Move up`]: `Déplacer vers le haut`,
  [`Must be a number`]: `Doit être un nombre`,
  [`Must be a valid URI`]: `Doit être un identificateur URI valide`,
  [`Must be a valid email`]: `Doit être une adresse électronique valide`,
  [`Must be a valid path starting with /`]: `Doit être un chemin valide commençant par /`,
  [`Must be a valid url`]: `Doit être une adresse URL valide`,
  [`Must be an integer`]: `Doit être un nombre entier`,
  [`Must be a valid url with no protocol`]: `Doit être une URL valide sans protocole`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Doit être au format 64 caractères hexadécimaux (avec le préfixe "0x")`,
  [`Must start with '/'`]: `Doit commencer par '/'`,
  [`Must start with https://`]: `Doit commencer par https://`,
  [`Must start with https:// or $(`]: `Doit commencer par https:// ou $(`,
  [`Mutual auth`]: `Authentification mutuelle`,
  [`Mutual authentication`]: `authentification mutuelle`,
  [`My account`]: `Mon compte`,
  [`Name`]: `Nom`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NON`,
  [`Name cannot be empty.`]: `Le nom ne doit pas être vide.`,
  [`Name of Schema in OpenAPI Definitions`]: `Nom du schéma dans les définitions OpenAPI`,
  [`Name of the LDAP user password attribute.`]: `Nom de l'attribut de mot de passe utilisateur LDAP.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Nom du registre d'utilisateurs LDAP utilisé pour la validation. Sélectionnez-le dans la liste ou entrez-le manuellement.`,
  [`Name of the organization`]: `Nom de l'organisation`,
  [`Namespace inheritance`]: `Héritage d'espace de nom`,
  [`Namespace inlining`]: `Mise en ligne d'espace de nom`,
  [`Native`]: `Natif`,
  [`Native OAuth provider`]: `Fournisseur OAuth natif`,
  [`Native OAuth provider summary`]: `Récapitulatif du fournisseur OAuth natif`,
  [`Navigate to Source View`]: `Accéder à la vue source`,
  [`New`]: `Nouveau`,
  [`New API`]: `Nouvelle API`,
  [`New API Connect connection`]: `Nouvelle connexion API Connect`,
  [`New Application`]: `Nouvelle application`,
  [`New Catalog`]: `Nouveau catalogue`,
  [`New Consumer organization`]: `Nouvelle organisation de type consommateur`,
  [`New Endpoint`]: `Nouveau noeud final`,
  [`New native OAuth provider`]: `Nouveau fournisseur OAuth natif`,
  [`New OpenAPI`]: `Nouvel OpenAPI`,
  [`New OpenAPI directory`]: `Nouveau répertoire OpenAPI`,
  [`New Organization Title`]: `Nouveau titre d'organisation`,
  [`New Parameter`]: `Nouveau paramètre`,
  [`New Password`]: `Nouveau mot de passe`,
  [`New product`]: `Nouveau produit`,
  [`New Role`]: `Nouveau rôle`,
  [`New schema`]: `Nouveau schéma`,
  [`New Test`]: `Nouveau test`,
  [`New third party OAuth provider`]: `Nouveau fournisseur OAuth tiers`,
  [`New user`]: `Nouvel utilisateur`,
  [`New applications will be created in the development state`]: `Les nouvelles applications seront créées à l'état de développement`,
  [`Next`]: `Suivant`,
  [`Next page`]: `Page suivante`,
  [`No`]: `Aucun`,
  [`No account?`]: `Vous n'avez pas de compte ?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `Aucune URL d'authentification ou aucun registre d'utilisateurs LDAP configuré(e) dans le catalogue pour bac à sable`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `Aucune URL d'authentification ou aucun registre d'utilisateurs LDAP configuré(e) dans le catalogue pour bac à sable. Créez-en une ou un [ici]({link})`,
  [`No Billing`]: `Aucune facturation`,
  [`No billing integration has been selected.`]: `Aucune intégration de facturation n'a été sélectionnée.`,
  [`No billing integration can be shown when offline.`]: `Aucune intégration de facturation ne peut être affichée hors ligne.`,
  [`No gateways`]: `Aucune passerelle`,
  [`No cache`]: `Pas de cache`,
  [`No Cache`]: `Pas de cache`,
  [`No Certificate Manager services available`]: `Aucun service de gestionnaire de certificat disponible`,
  [`No default gateways`]: `Aucune passerelle par défaut`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Aucune adresse électronique en double dans le registre d'utilisateurs. Ceci s'applique uniquement aux utilisateurs disposant d'une adresse électronique.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Aucune passerelle compatible n'est configurée pour le catalogue de bac à sable. Le test d'API (Essayer) dans l'explorateur ne sera pas activé.`,
  [`No Errors`]: `Aucune erreur`,
  [`No Histories found`]: `Aucun historique trouvé`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Aucun service de passerelle trouvé. Configurez un service de passerelle pour afficher les stratégies d'assemblage prises en charge`,
  [`No gateways are configured for sandbox catalog`]: `Aucune passerelle n'est configurée pour le catalogue de bac à sable`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Aucune passerelle n'est configurée pour le catalogue de bac à sable. Le test d'API (Essayer) dans l'explorateur ne sera pas activé`,
  [`No LDAP or authentication URL user registries found.`]: `Aucun registre d'utilisateurs d'URL d'authentification ou LDAP trouvé.`,
  [`No limit on size of list`]: `Aucune limite pour la taille de la liste`,
  [`No OAuth providers configured in the sandbox catalog`]: `Aucun fournisseur OAuth configuré dans le catalogue pour bac à sable`,
  [`No OAuth providers configured in the selected catalog`]: `Aucun fournisseur OAuth configuré dans le catalogue sélectionné`,
  [`No Permission`]: `Aucun droit`,
  [`No portal added to this catalog`]: `Aucun portail n'a été ajouté à ce catalogue`,
  [`No Roles exist`]: `Il n'existe aucun rôle`,
  [`No recent items found.`]: `Aucun élément récent trouvé.`,
  [`No TLS profile`]: `Aucun profil TLS`,
  [`No TLS client profile`]: `Aucun profil de client TLS`,
  [`No Truststore`]: `Pas de magasin de clés de confiance`,
  [`No analytics service is configured`]: `Aucun service d'analyse n'est configuré`,
  [`No analytics services found. You can create one [here]({link})`]: `Aucun service d'analyse trouvé. Vous pouvez en créer un [ici]({link})`,
  [`No assemblies found.`]: `Aucun assemblage trouvé.`,
  [`No available extensions`]: `Aucune extension disponible`,
  [`No compatible flows configured in this OAuth provider`]: `Aucun flux compatible configuré dans ce fournisseur OAuth`,
  [`No consumer organization will be able to see this product.`]: `Aucune organisation de type consommateur ne pourra voir ce produit.`,
  [`No debug data was found for this API call`]: `Aucune donnée de débogage n'a été trouvée pour cet appel API`,
  [`No details are available.`]: `Aucun détail n'est disponible.`,
  [`No existing products`]: `Aucun produit existant`,
  [`No extensions have been configured for the API`]: `Aucune extension n'a été configurée pour l'API`,
  [`No flow configured in this OAuth provider`]: `Aucun flux configuré dans ce fournisseur OAuth`,
  [`No items found`]: `Aucun élément trouvé`,
  [`No non-member user to add.`]: `Aucun utilisateur non membre à ajouter.`,
  [`No organization found`]: `Aucune organisation trouvée`,
  [`No portal service exists:`]: `Aucun service de portail n'existe :`,
  [`No product APIs`]: `Aucune API de produit`,
  [`No products or plans were found.`]: `Aucun produit ou plan trouvé.`,
  [`No provider organizations`]: `Aucune organisation de type fournisseur`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Aucune réponse reçue. Il se peut que CORS ne soit pas pris en charge sur le serveur cible, que le serveur ne soit pas disponible, qu'un certificat non digne de confiance ait été rencontré ou qu'une authentification SSL mutuelle soit requise.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Aucun groupe de ressources disponible. Pour commencer, créez un groupe de ressources dans les ressources du compte IBM Cloud.`,
  [`No security`]: `Pas de sécurité`,
  [`No services found`]: `Aucun service trouvé`,
  [`No warnings`]: `Aucun avertissement`,
  [`No warnings.`]: `Aucun avertissement.`,
  [`No. Do not update API Assembly`]: `Non. Ne pas mettre à jour l'assemblage d'API`,
  [`Non-blocking`]: `Non bloquant`,
  [`Non int slicing arguments`]: `Arguments de tranche non entiers`,
  [`None`]: `Aucun`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Aucun des fournisseurs OAuth configurés ne correspond au type de passerelle de cette API`,
  [`Normal`]: `Normal`,
  [`Not Available`]: `Non disponible`,
  [`Not applicable for application grant type.`]: `Non applicable pour le type d'octroi d'application.`,
  [`Not valid before`]: `Début de validité`,
  [`Not valid after`]: `Fin de validité`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Remarque : cette option est désactivée lorsque l'option 'Générer un produit automatiquement' est sélectionnée.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Remarque : cette option est désactivée lorsque l'option 'Générer un produit automatiquement' est sélectionnée et utilise à la place le plan par défaut pour le produit généré`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Remarque : la préférence en matière de services de passerelle sera utilisée comme portée pour toutes les autres préférences. Les stratégies et d'autres contenus spécifiques au service de passerelle seront chargés en fonction de ce paramètre.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Remarque : cette option est fournie uniquement à des fins d'information et est mise à jour en fonction de l'option d'application de test ci-dessus`,
  [`Notices`]: `Mentions légales`,
  [`Notification template`]: `Modèle de notification`,
  [`Notification templates`]: `Modèles de notification`,
  [`Notification server configured`]: `Serveur de notification configuré`,
  [`Notifications`]: `Notifications`,
  [`Null Badgerfish`]: `Badgerfish NULL`,
  [`Null`]: `NULL`,
  [`Number`]: `Nombre`,
  [`Number of Operations`]: `Nombre d'opérations`,
  [`Numeric`]: `Numérique`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `Fournisseur OAuth`,
  [`OAuth provider`]: `Fournisseur OAuth`,
  [`OAuth Provider Policy Assembly`]: `Assemblage des stratégies du fournisseur OAuth`,
  [`OAuth providers`]: `Fournisseurs OAuth`,
  [`OAuth Redirect URL`]: `URL de réacheminement OAuth`,
  [`OAuth shared secret`]: `Valeur confidentielle partagée OAuth`,
  [`OAuth provider policy assembly`]: `Assemblage des stratégies du fournisseur OAuth`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `Le fournisseur OAuth {currentProviderName} est introuvable. Sélectionnez un fournisseur OAuth configuré.`,
  [`OAuth redirect URL`]: `URL de réacheminement OAuth`,
  [`OAuth Shared Secret`]: `Valeur confidentielle partagée OAuth`,
  [`OAuth Signature Method`]: `Méthode de signature OAuth`,
  [`OAuth signature method`]: `Méthode de signature OAuth`,
  [`OAuth providers can be created and managed in the following list.`]: `Les fournisseurs OAuth peuvent être créés et gérés dans la liste ci-après.`,
  [`OAuth symmetric key name`]: `Nom de clé symétrique OAuth`,
  [`OAuth symmetric key value`]: `Valeur de clé symétrique OAuth`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `Sécurité du code d'accès OAuth2`,
  [`OAuth2 Application Security`]: `Sécurité d'application OAuth2`,
  [`OAuth2 Implicit Security`]: `Sécurité implicite OAuth2`,
  [`OAuth2 Password Security`]: `Sécurité du mot de passe OAuth2`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `ID OPERATION`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `ORDONNER`,
  [`ORGANIZATION`]: `ORGANISATION`,
  [`ORGANIZATION MANAGER`]: `RESPONSABLE DE L'ORGANISATION`,
  [`Organizations`]: `Organisations`,
  [`Owner`]: `Propriétaire`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Les fournisseurs OAuth sont gérés dans chaque espace lorsque les espaces sont activés. Accédez aux paramètres d'espace pour gérer les fournisseurs OAuth.`,
  [`Off`]: `Désactivé`,
  [`Offline`]: `Hors ligne`,
  [`Old schema`]: `Ancien schéma`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch permet d'effectuer la recherche d'API, de produits, d'applications, d'abonnements et d'autres ressources au sein d'une organisation de type fournisseur ou d'un catalogue. En outre, il peut également aider à découvrir les relations entre des ressources.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch pour toutes les pages de liste prises en charge`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch pour les API et les produits brouillons`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch pour les produits publiés dans les catalogues et les espaces`,
  [`Omnisearch for consumer organizations`]: `Omnisearch pour les organisations de type consommateur`,
  [`Omnisearch for applications`]: `Omnisearch pour les applications`,
  [`Omnisearch for requested approvals`]: `Omnisearch pour les approbations demandées`,
  [`Omnisearch for approvals tasks`]: `Omnisearch pour les tâches d'approbation`,
  [`Omnisearch for product apis`]: `Omnisearch pour les API du produit`,
  [`Omnisearch for subscriptions`]: `Omnisearch pour les abonnements`,
  [`Omnisearch for members`]: `Omnisearch pour les membres`,
  [`Omnisearch for provider organizations`]: `Omnisearch pour les organisations de fournisseurs`,
  [`Disable the new editor for OpenAPI2`]: `Désactiver le nouvel éditeur pour OpenAPI2`,
  [`On`]: `Activé`,
  [`Onboarding`]: `Intégration`,
  [`Onboarding Edit Admin Org`]: `Organisation Admin de l'édition d'intégration`,
  [`Onboarding Edit Cloud Org`]: `Organisation Cloud de l'édition d'intégration`,
  [`Onboarding Edit Reset Password`]: `Mot de passe de réinitialisation de l'édition d'intégration`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Une fois qu'il est installé, utilisez l'interface de ligne de commande IBM Cloud pour cibler le compte et la région où vous avez mis à disposition API Connect, puis essayez l'interface de ligne de commande en répertoriant toutes vos organisations de type fournisseur API Connect`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Un ou plusieurs objets JSON décrivant les schémas en cours de consommation et produits par l'API.`,
  [`One or more JSON representations for parameters`]: `Une ou plusieurs représentations JSON pour les paramètres`,
  [`One time use access token`]: `Jeton d'accès à usage unique`,
  [`One time use refresh token`]: `Jeton d'actualisation à usage unique`,
  [`Online`]: `En ligne`,
  [`Only one API file should be present in the zip file.`]: `Un seul fichier API doit être présent dans le fichier zip.`,
  [`OpenAPI Specification Version`]: `Version de spécification OpenAPI`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) fournit un protocole d'authentification supplémentaire s'appuyant sur OAuth 2.0. Les fournisseurs OIDC utilisent des informations codées dans un jeton JWT (JSON Web Token). Lorsque vous activez OpenID Connect, un modèle est fourni pour générer des jetons d'ID ainsi que des jetons d'accès, et les stratégies d'assemblage requises sont automatiquement créées.`,
  [`Open a Directory`]: `Ouvrir un répertoire`,
  [`Open in external editor`]: `Ouvrir dans un éditeur externe`,
  [`Open the server`]: `Ouvrir le serveur`,
  [`OpenAPI extensions`]: `Extensions OpenAPI`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect n'est pas encore pris en charge lorsque la version de Gateway est 6000`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `Les paramètres OpenID Connect s'appliquent uniquement si vous disposez d'un ou de plusieurs types d'octroi parmi les suivants : Implicite et Code d'accès.`,
  [`Operation`]: `Opération`,
  [`Operation Id`]: `ID opération`,
  [`Operation switch`]: `Permutation d'opération`,
  [`Operation Switch`]: `Permutation d'opération`,
  [`Operations`]: `Opérations`,
  [`Operator build date`]: `Date de génération de l'opérateur`,
  [`Operator build tag`]: `Balise de génération de l'opérateur`,
  [`Operator version`]: `Version de l'opérateur`,
  [`Optionally select a CA bundle`]: `Sélectionnez éventuellement un bundle d'autorité de certification`,
  [`Optimize schema definition`]: `Optimiser la définition de schéma`,
  [`Optional`]: `Facultatif`,
  [`Or`]: `Ou`,
  [`Or create a new application and subscribe to the selected plan`]: `Ou créez une application et abonnez-vous au plan sélectionné`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Ou créez un produit et publiez-le dans votre catalogue pour bac à sable`,
  [`Order`]: `Ordonner`,
  [`Org`]: `Org`,
  [`Org owner`]: `Propriétaire de l'organisation`,
  [`Organization`]: `Organisation`,
  [`Organization:`]: `Organisation :`,
  [`Organization information`]: `Informations sur l'organisation`,
  [`Organization Manager`]: `Responsable d'organisation`,
  [`Organization has been updated.`]: `L'organisation a été mise à jour.`,
  [`Organizations will be listed here.`]: `Les organisations seront répertoriées ici.`,
  [`Otherwise`]: `Sinon`,
  [`Output`]: `Sortie`,
  [`Output Claims`]: `Réclamations de sortie`,
  [`Output array`]: `Tableau de sortie`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Générer l'élément racine même s'il n'est pas obligatoire pour que le document XML soit correctement formaté.`,
  [`Output variable`]: `Variable de sortie`,
  [`Override API consume types`]: `Remplacer les types de consommation d'API`,
  [`Override API produce types`]: `Remplacer les types de génération d'API`,
  [`Override API security definitions`]: `Remplacer les définitions de sécurité d'API`,
  [`Override rate limits`]: `Remplacer les limites de débit`,
  [`Override default "provider" settings with configuration from this URL`]: `Remplacer les paramètres "provider" par défaut par la configuration de cette URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Remplacer les paramètres "provider" par défaut par la configuration de ce littéral`,
  [`Override plan rate limits`]: `Remplacer les limites de débit de plan`,
  [`Override plan rate limits for individual operation`]: `Remplacer les limites de débit de plan pour une opération individuelle`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Remplacer le délai d'attente d'invitation des organisations de type consommateur par le délai d'attente d'invitation du catalogue`,
  [`Overview`]: `Présentation`,
  [`Owner scope check`]: `Vérification de portée de propriétaire`,
  [`Owner's Email`]: `Adresse électronique du propriétaire`,
  [`Owner's Name`]: `Nom du propriétaire`,
  [`Owner's email`]: `Adresse électronique du propriétaire`,
  [`Owns and administers the API provider organization`]: `Est le propriétaire et l'administrateur de l'organisation de type fournisseur d'API`,
  [`Owns and administers the admin organization`]: `Est le propriétaire et l'administrateur de l'organisation d'administration`,
  [`Owns and administers the app developer organization`]: `Est le propriétaire et l'administrateur de l'organisation de développeurs d'applications`,
  [`Owns and administrates the app developer organization`]: `Est le propriétaire et l'administrateur de l'organisation de développeurs d'applications`,
  [`PARAMETER NAME`]: `NOM DU PARAMETRE`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `PCRE à utiliser pour valider la réclamation du public (aud).`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `PCRE à utiliser pour valider la réclamation de l'émetteur (iss).`,
  [`PER`]: `POUR`,
  [`Per`]: `Par`,
  [`Plan`]: `Plan`,
  [`Plan:`]: `Plan :`,
  [`pending Consumer organization invitation`]: `invitation d'organisation de type consommateur en attente`,
  [`PLAN`]: `PLAN`,
  [`PLANS`]: `PLANS`,
  [`Policy`]: `R�gle`,
  [`policy`]: `r�gle`,
  [`POLICIES`]: `STRATEGIES`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUIT`,
  [`Product Plan`]: `Plan de produit`,
  [`Product Plans`]: `Plans de produit`,
  [`Product Plans per page`]: `Plans de produit par page`,
  [`Property name`]: `Nom de la propriété`,
  [`Property type`]: `Type de la propriété`,
  [`Property example`]: `Exemple de propriété`,
  [`Property description`]: `Description de la propriété`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Page introuvable`,
  [`Parameter name`]: `Nom du paramètre`,
  [`Parameter control`]: `Contrôle de paramètre`,
  [`Parameters`]: `Paramètres`,
  [`Parent`]: `Parent`,
  [`Parse`]: `Analyse`,
  [`Parse settings URL reference`]: `Référence à l'URL des paramètres d'analyse`,
  [`Parse settings literal configuration`]: `Configuration du littéral des paramètres d'analyse`,
  [`Parse settings object reference`]: `Référence d'objet des paramètres d'analyse`,
  [`Parse the GraphQL query`]: `Analyser la requête GraphQL`,
  [`Parse the response from the GraphQL backend server`]: `Analyser la réponse du serveur de back end GraphQL`,
  [`Parse XML output`]: `Analyser la sortie XML`,
  [`Pass-through`]: `Passe-système`,
  [`Password`]: `Mot de passe`,
  [`password-changed`]: `mot-de-passe-modifié`,
  [`Password changed for your user account in IBM API Connect`]: `Le mot de passe de votre compte utilisateur a été changé dans IBM API Connect`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `Le mot de passe de votre compte utilisateur a été modifié dans le portail de développeur {portalTitle}`,
  [`Password context variable`]: `Variable contextuelle du mot de passe`,
  [`Password is invalid. Re-enter the password.`]: `Le mot de passe n'est pas valide. Entrez-le à nouveau.`,
  [`password-reset`]: `mot-de-passe-réinitialisé`,
  [`Password reset request for your user account in IBM API Connect`]: `Demande de réinitialisation du mot de passe de votre compte utilisateur dans IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Demande de réinitialisation du mot de passe de votre portail de développeur {catalog}`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Les mots de passe doivent contenir au moins un caractère de deux des catégories suivantes : minuscules, majuscules, chiffres et signes de ponctuation (par exemple !, $, #, %).`,
  [`Passwords must contain eight or more characters`]: `Les mots de passe doivent comporter au moins huit caractères`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Les mots de passe doivent être composés de huit caractères ou plus, avec au moins un caractère de deux des catégories suivantes : minuscule, majuscule, chiffre et signe de ponctuation (par exemple !, $, #, %). De plus, un même caractère ne peut pas être utilisé plus de deux fois consécutivement.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Les mots de passe ne doivent pas contenir le même caractère utilisé plus de deux fois consécutivement.`,
  [`Passwords should match`]: `Les mots de passe doivent correspondre`,
  [`Path`]: `Chemin`,
  [`Path parameters`]: `Paramètres de chemin`,
  [`Path name`]: `Nom de chemin`,
  [`Paths`]: `Chemins`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Les chemins identifient les ressources REST exposées par l'API. Une opération combine un chemin avec une instruction HTTP, des paramètres et des définitions pour les demandes et les réponses.`,
  [`Payments Authorization`]: `Autorisation de paiement`,
  [`Payment method`]: `Mode de règlement`,
  [`Pending`]: `En attente`,
  [`Pending State`]: `Etat en attente`,
  [`Pending approval to be published in catalog`]: `En attente d'approbation pour une publication dans le catalogue`,
  [`Pending approval to be staged in catalog`]: `En attente d'approbation pour une mise en préproduction dans le catalogue`,
  [`Pending consumer organization invitations`]: `Invitations d'organisation de type consommateur en attente`,
  [`View endpoints`]: `Afficher les noeuds finaux`,
  [`View base endpoints for this API`]: `Afficher les noeuds finaux de base de cette API`,
  [`View pending invitations`]: `Afficher les invitations en attente`,
  [`Permission Denied`]: `Accès refusé`,
  [`Permissions`]: `Droits`,
  [`Persistent Connection`]: `Connexion permanente`,
  [`Pin menu`]: `Epingler le menu`,
  [`PlainText`]: `Texte en clair`,
  [`Plan APIs`]: `API de plan`,
  [`Plan burst limits`]: `Limites de diffusion de plan`,
  [`Plan by name`]: `Plan par nom`,
  [`Plan Default`]: `Valeur par défaut du plan`,
  [`Plan Details`]: `Détails du plan`,
  [`Plan migration targets`]: `Planifier les cibles de migration`,
  [`Plan rate limits`]: `Limites de débit de plan`,
  [`Plan name`]: `Nom du plan`,
  [`Plan pricing`]: `Tarification du plan`,
  [`Plan title already exists`]: `Le titre du plan existe déjà`,
  [`Plans`]: `Plans`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Noeud final d'API REST de plateforme pour les API d'administration et de fournisseur`,
  [`Platform REST API endpoint for consumer APIs`]: `Noeud final d'API REST de plateforme pour les API de consommateur`,
  [`Please choose a bind method`]: `Choisissez une méthode de liaison`,
  [`Please choose an authentication method`]: `Choisissez une méthode d'authentification`,
  [`Please contact the system administrator.`]: `Prenez contact avec l'administrateur système.`,
  [`Please contact your administrator for support.`]: `Contactez votre administrateur pour de l'assistance.`,
  [`Please enter the reason.`]: `Entrez le motif.`,
  [`Please enter your username`]: `Entrez votre nom d'utilisateur`,
  [`Please go to the space settings to manage API endpoints.`]: `Accédez aux paramètres d'espace pour gérer les noeuds finaux d'API.`,
  [`Please make sure the server is online and try again.`]: `Assurez-vous que le serveur est en ligne puis faites une nouvelle tentative.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Veuillez noter que la désactivation du journal d'activité supprimera les propriétés associées au type de contenu à consigner en cas de succès ou d'erreur. Voulez-vous vraiment le désactiver ?`,
  [`Please review the license for API Connect by accessing`]: `Révisez la licence pour API Connect en accédant à`,
  [`Please select a TLS`]: `Sélectionnez un profil TLS`,
  [`Please select a TLS client profile`]: `Sélectionnez un profil de client TLS`,
  [`Please select a group type`]: `Sélectionnez un type de groupe`,
  [`Please select an LDAP protocol version`]: `Sélectionnez une version de protocole LDAP`,
  [`Please select authentication method`]: `Sélectionnez une méthode d'authentification`,
  [`Please select ciphers from the list below:`]: `Sélectionnez des chiffrements dans la liste suivante :`,
  [`Please select consumer organization`]: `Sélectionnez une organisation de type consommateur`,
  [`Please select one Keystore from the list below:`]: `Sélectionnez un magasin de clés dans la liste suivante :`,
  [`Please select one keystore from the list below:`]: `Sélectionnez un magasin de clés dans la liste suivante :`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Prenez note de l'ID et de la valeur confidentielle de client pour cette nouvelle application. Stockez la valeur confidentielle dans un endroit sûr car vous ne pourrez pas l'extraire à nouveau, bien qu'elle puisse être réinitialisée si nécessaire :`,
  [`Please try again in a few minutes.`]: `Réessayez dans quelques minutes.`,
  [`Please refresh catalog/space product list page and try again.`]: `Actualisez la page de liste de produits de catalogue/d'espace et renouvelez l'opération.`,
  [`Policies`]: `Stratégies`,
  [`Policy Assemblies`]: `Assemblages de stratégies`,
  [`Policy assembly`]: `Assemblage de stratégies`,
  [`Policy description.`]: `Description de la stratégie`,
  [`Policy title`]: `Titre de la stratégie`,
  [`Policy has been uploaded.`]: `La stratégie a été chargée.`,
  [`Polish`]: `Polonais`,
  [`Port`]: `Port`,
  [`Portal`]: `Portail`,
  [`Portal details`]: `Détails du portail`,
  [`Portal Director TLS client profile`]: `Profil de client TLS du directeur de portail`,
  [`Portal Director keystore`]: `Magasin de clés du directeur de portail`,
  [`Portal Director truststore`]: `Magasin de clés de confiance du directeur de portail`,
  [`Portal service`]: `Service de portail`,
  [`Portal Service`]: `Service de portail`,
  [`Portal service name`]: `Nom du service de portail`,
  [`Portal service details`]: `Détails du service de portail`,
  [`Portal Service and URL`]: `Service de portail et URL`,
  [`Portal service {name} was successfully created.`]: `Le service de portail {name} a été créé.`,
  [`Portal service {name} was successfully updated.`]: `Le service de portail {name} a été mis à jour.`,
  [`Portal Service {arg} has been removed.`]: `Le service de portail {arg} a été retiré.`,
  [`Portal site`]: `Site du portail`,
  [`Portal URL`]: `URL du portail`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Les URL de site de portail ne peuvent pas comporter plus de 200 caractères`,
  [`Portal website URL`]: `URL du site Web du portail`,
  [`Portal and Gateway Settings`]: `Paramètres de portail et de passerelle`,
  [`Portal has been created`]: `Le portail a été créé`,
  [`Portal has been created.`]: `Le portail a été créé.`,
  [`Portal has been deleted`]: `Le portail a été supprimé.`,
  [`Portals`]: `Portails`,
  [`Portuguese`]: `Portugais`,
  [`Post Response`]: `Réponse postérieure`,
  [`Post-Invoke Hook`]: `Point d'ancrage de post-appel`,
  [`Preserve subscriptions on re-publish`]: `Conserver les abonnements lors de la republication`,
  [`Preserve subscriptions on re-publish product`]: `Conserver les abonnements lors de la republication du produit`,
  [`Pre-Invoke Hook`]: `Point d'ancrage de pré-appel`,
  [`Pre-Request`]: `Demande préalable`,
  [`Preference`]: `Préférence`,
  [`Prefix`]: `Préfixe`,
  [`Preparing API`]: `Préparation d'API`,
  [`Preserved request header`]: `En-tête de requête réservé`,
  [`preserved request header`]: `en-tête de demande conservé`,
  [`Preserved response header`]: `En-tête de réponse réservé`,
  [`preserved response header`]: `en-tête de réponse conservé`,
  [`Preview`]: `Aperçu`,
  [`Preview of login screen registry prompt`]: `Aperçu de l'invite de registre de l'écran de connexion`,
  [`Previous`]: `Précédent`,
  [`Previous page`]: `Page précédente`,
  [`Prettify`]: `Prettify`,
  [`Price per month`]: `Tarif par mois`,
  [`Private`]: `Privé`,
  [`Private Claims`]: `Réclamations privées`,
  [`Private key & public key`]: `Clé privée et clé publique`,
  [`Private Key or Shared Secret for Decrypt`]: `Clé privée ou valeur confidentielle partagée pour le déchiffrement`,
  [`Private Key or Shared Secret for Sign`]: `Clé privée ou valeur confidentielle partagée pour la signature`,
  [`Private key detected`]: `Clé privée détectée`,
  [`Private key password`]: `Mot de passe de clé privée`,
  [`Processed`]: `Traité`,
  [`Problem`]: `Problème`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Problème lors de l'extraction des données de trace. Faites une nouvelle tentative. Voir la console pour plus de détails sur les erreurs.`,
  [`Produced Media Types`]: `Types de support générés`,
  [`Produces`]: `Produit`,
  [`Product`]: `Produit`,
  [`Product:`]: `Produit :`,
  [`Product / Plan`]: `Produit/Plan`,
  [`Product APIs`]: `API du produit`,
  [`Product editor`]: `Editeur de produit`,
  [`Product lifecycle`]: `Cycle de vie du produit`,
  [`Product Lifecycle`]: `Cycle de vie du produit`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Le nom de produit ne peut pas se terminer par '-auto-product'. Changez le nom et relancez l'importation.`,
  [`Product setup`]: `Configuration du produit`,
  [`Product details`]: `Détails du produit`,
  [`Product documentation`]: `Documentation du produit`,
  [`Product has been created.`]: `Le produit a été créé`,
  [`Product has been deleted`]: `Le produit a été supprimé.`,
  [`Product has been updated`]: `Le portail a été mis à jour`,
  [`Product has been updated.`]: `Le produit a été mis à jour.`,
  [`Product has not been created!`]: `Le produit n'a pas été créé.`,
  [`Product has not been published!`]: `Le produit n'a pas été publié.`,
  [`Product has not been updated!`]: `Le produit n'a pas été mis à jour !`,
  [`Product has not been staged!`]: `Le produit n'a pas été mis en préproduction.`,
  [`Product information`]: `Informations sur le produit`,
  [`Product summary details`]: `Détails récapitulatifs du produit`,
  [`Product version`]: `Version de produit`,
  [`Product visibility`]: `Visibilité du produit`,
  [`Product-Approval`]: `Approbation-produit`,
  [`Product-Drafts`]: `Brouillons-produit`,
  [`Product/plan`]: `Produit/plan`,
  [`Production`]: `Production`,
  [`Production mode`]: `Mode de production`,
  [`Production endpoints`]: `Les noeuds finaux de production`,
  [`Products`]: `Produits`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Les produits doivent être mis en préproduction dans un catalogue, puis publiés pour être mis à la disposition des développeurs d'applications. Un catalogue agit comme une partition logique de la passerelle et du portail de développeur. Les adresses URL des appels API et du portail de développeur sont propres à un catalogue particulier. Dans une configuration standard, une organisation de type fournisseur d'API utilise un catalogue de développement pour tester les API en développement et un catalogue de production pour héberger les API prêtes à l'utilisation.`,
  [`Profile`]: `Profil`,
  [`Profile rule`]: `Règle de profil`,
  [`Promote to production`]: `Promouvoir vers la production`,
  [`Proof Key for Code Exchange`]: `Clé de preuve pour l'échange de code`,
  [`Proof Key for Code Exchange (PKCE)`]: `Clé de preuve pour l'échange de code (PKCE)`,
  [`Properties`]: `Propriétés`,
  [`Property`]: `Propriété`,
  [`Property Description`]: `Description de propriété`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `Vous pouvez affecter une valeur statique à la propriété {{target}} en l'entrant ci-dessous.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `La propriété {{target}} est actuellement mappée à {{from}} - le mappage peut être retiré ci-dessous.`,
  [`Property Value is Base64 Encoded`]: `La valeur de la propriété est encodée en Base64`,
  [`Protocol`]: `Protocole`,
  [`Protocols`]: `Protocoles`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protocoles utilisables pour appeler l'API (seul HTTPS est pris en charge pour les API appliquées par passerelle)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Fournissez les données d'identification OAuth {oauthVer} pour authentifier les utilisateurs et accéder aux API de votre fournisseur OpenID`,
  [`Provide a schema`]: `Indiquez un schéma`,
  [`Provide GraphQL schema`]: `Indiquez un schéma GraphQL`,
  [`Provide the URL to the backend GraphQL server`]: `Indiquez l'URL du serveur de back end GraphQL`,
  [`Provide your email address to receive password reset instructions`]: `Indiquez l'adresse électronique où recevoir les instructions de réinitialisation du mot de passe`,
  [`Provide your new password below`]: `Indiquez votre nouveau mot de passe ci-dessous`,
  [`Provider endpoint`]: `Noeud final de fournisseur`,
  [`Provider Information`]: `Information fournisseur`,
  [`Provider Organization`]: `Organisation de type fournisseur`,
  [`Provider Organizations`]: `Organisations de type fournisseur`,
  [`Provider Type`]: `Type de fournisseur`,
  [`Provider organizations`]: `Organisations de type fournisseur`,
  [`Provider type`]: `Type de fournisseur`,
  [`Provider org invite failed`]: `L'invitation de l'organisation de type fournisseur a échoué`,
  [`Provider organization {name} created`]: `L'organisation de type fournisseur {name} a été créée`,
  [`Provider organizations will be listed here`]: `Les organisations de type fournisseur seront répertoriées ici`,
  [`Provider-Org`]: `Organisation-fournisseur`,
  [`Provides contact information for the exposed API.`]: `Fournit les informations de contact liées à l'API exposée.`,
  [`Provides license information for the exposed API.`]: `Fournit les informations de licence liées à l'API exposée.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Fournit des métadonnées sur l'API. Celles-ci peuvent être utilisées par les clients si nécessaire.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `La mise à disposition du portail de développeur a été lancée. L'opération va prendre quelques minutes. Une fois qu'elle sera terminée, vous recevrez un courrier électronique contenant un lien pour définir le mot de passe pour le compte d'administration du portail.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `La mise à jour de l'URL du portail de développeur a été lancée. Le processus va prendre quelques minutes.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `URL du proxy`,
  [`Public`]: `Public`,
  [`Public Key or Shared Secret for Encrypt`]: `Clé publique ou valeur confidentielle partagée pour le chiffrement`,
  [`Publish`]: `Publier`,
  [`Publish API`]: `Publication d'API`,
  [`Publish a product from the Develop section.`]: `Publiez un produit à partir de la section Développement.`,
  [`Publish product`]: `Publier le produit`,
  [`Publish to`]: `Publier dans`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Publiez certains produits dans ce catalogue pour créer des abonnements à ces produits.`,
  [`Publish target`]: `Cible de publication`,
  [`Publish this API to the Sandbox catalog.`]: `Publiez cette API dans le catalogue pour bac à sable.`,
  [`Publish to specific gateway services`]: `Publier dans des services de passerelle spécifiques`,
  [`Publish using a new product`]: `Publier à l'aide d'un nouveau produit`,
  [`Publish using an existing product`]: `Publier à l'aide d'un produit existant`,
  [`Publishable Key`]: `Clé publiable`,
  [`Publishable key`]: `Clé publiable`,
  [`Publishable key tip`]: `Infobulle de clé publiable`,
  [`Published`]: `Publié`,
  [`Published product`]: `Produit publié`,
  [`Publishing`]: `Publication`,
  [`Publishing New API`]: `Publication d'une nouvelle API`,
  [`Publish (preserve subscriptions)`]: `Publier (conserver les abonnements)`,
  [`Publish|permission`]: `Publier`,
  [`Put online`]: `Mettre en ligne`,
  [`Query`]: `Requête`,
  [`Queryparam Encode`]: `Coder les paramètres de requête`,
  [`Queued`]: `En file d'attente`,
  [`Queued events: **{queued}**`]: `Evénements en file d'attente : **{queued}**`,
  [`Retry blocked jobs`]: `Essayer à nouveau les travaux bloqués`,
  [`REQUIRED`]: `OBLIGATOIRE`,
  [`ROLES`]: `ROLES`,
  [`Rate limit`]: `Limite de débit`,
  [`Rate Limit`]: `Limite de débit`,
  [`Rate Limit Name`]: `Nom de la limite de débit`,
  [`Rate limits`]: `Limites de débit`,
  [`Rate limit name can't be a duplicate`]: `Le nom de la limite de débit ne peut pas être un doublon`,
  [`Rate limit name can't be empty`]: `Le nom de la limite de débit ne peut pas être vide`,
  [`Rate limit overrides`]: `Remplacements de limite de débit`,
  [`Rate limit time interval can't be 0`]: `L'intervalle de temps de la limite de débit ne peut pas être 0`,
  [`Rate limit time interval can't be empty`]: `L'intervalle de temps de la limite de débit ne peut pas être vide`,
  [`Rate limit time interval unit must be selected`]: `L'unité d'intervalle de temps de la limite de débit doit être sélectionnée`,
  [`Rate limit value can't be empty`]: `La valeur de la limite de débit ne peut pas être vide`,
  [`Rate limit value can't be negative`]: `La valeur de la limite de débit ne peut pas être négative`,
  [`Re-stage`]: `Reconstituer en préproduction`,
  [`Re-stage product`]: `Reconstitution en préproduction`,
  [`Read only`]: `En lecture seule`,
  [`Read-Only`]: `En lecture seule`,
  [`Realized`]: `Réalisé`,
  [`Reason`]: `Motif`,
  [`Reason for declining`]: `Motif du refus`,
  [`Recommendations`]: `Recommandations`,
  [`Recommended configuration`]: `Configuration recommandée`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Enregistrez l'ID et la valeur confidentielle du client affichés ci-dessous en vue d'une utilisation ultérieure. La valeur confidentielle du client ne peut plus être réaffichée.`,
  [`Recommendations applied`]: `Recommandations appliquées`,
  [`Redact`]: `Occulter`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Occultez ou retirez les zones indiquées du corps de la demande, du corps de la réponse et des journaux d'activité. Définissez la zone à occulter ou retirer en indiquant une expression XPath.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Occultez ou retirez les zones indiquées du corps de la demande, du corps de la réponse et des journaux d'activité. Définissez la zone à occulter ou retirer en indiquant une expression XPath ou JSONata.`,
  [`Redaction`]: `Occultation`,
  [`Redactions`]: `Occultations`,
  [`Redirect`]: `Réacheminement`,
  [`Redirect URI`]: `URI de redirection`,
  [`Redirect URL`]: `URL de réacheminement`,
  [`Redirect through Portal`]: `Rediriger via le portail`,
  [`Redirect time limit`]: `Limite de temps de redirection`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `La limite de temps de redirection doit être comprise entre 10 et 600 secondes`,
  [`References`]: `Références`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Pour des instructions supplémentaires, consultez la [Documentation IBM Cloud]({link}).`,
  [`Referral`]: `Référence`,
  [`Refresh`]: `Actualiser`,
  [`Refresh Token`]: `Jeton d'actualisation`,
  [`Refresh button`]: `Bouton d'actualisation`,
  [`Refresh products`]: `Actualiser les produits`,
  [`Refresh results from server`]: `Actualiser les résultats du serveur`,
  [`Refresh token`]: `Actualiser le jeton`,
  [`Refresh token time to live`]: `Durée de vie du jeton d'actualisation`,
  [`Refresh token time-to-live`]: `Durée de vie du jeton d'actualisation`,
  [`Refresh token time-to-live (seconds)`]: `Durée de vie des jetons d'actualisation (en secondes)`,
  [`Refresh token TTL has been changed`]: `La durée de vie du jeton d'actualisation a été modifiée`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Durée de vie du jeton d'actualisation : **{seconds} secondes ({time})**`,
  [`Refresh token time to live is **off**`]: `Durée de vie du jeton d'actualisation : **off**`,
  [`Refresh tokens`]: `Actualiser les jetons`,
  [`Refreshing`]: `Actualisation`,
  [`Refreshing...`]: `Actualisation...`,
  [`Region`]: `Région`,
  [`Register`]: `Enregistrer`,
  [`Register Gateways`]: `Enregistrement de passerelles`,
  [`Register Service`]: `Enregistrement de service`,
  [`Register gateways`]: `Enregistrement de passerelles`,
  [`Register service`]: `Enregistrement de service`,
  [`Register a service`]: `Enregistrer un service`,
  [`Register new services and manage existing services`]: `Enregistrez de nouveaux services et gérez les services existants`,
  [`Register remote gateway`]: `Enregistrer la passerelle éloignée`,
  [`Registered Admin Member`]: `Membre administrateur enregistré`,
  [`Registered Provider Org Owner or Member`]: `Membre ou propriétaire d'organisation de type fournisseur enregistré`,
  [`Registered client information on the OpenID provider`]: `Informations client enregistrées sur le fournisseur OpenID`,
  [`Registration completed successfully`]: `Enregistrement terminé`,
  [`Registration failed.`]: `Echec de l'enregistrement.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Chemins relatifs des noeuds finaux individuels. Ils doivent être relatifs au 'basePath'.`,
  [`Relaxed Badgerfish`]: `Badgerfish souple`,
  [`Remove analysis configuration`]: `Retirer la configuration d'analyse`,
  [`Remote API Connect`]: `API Connect distant`,
  [`Remote login`]: `Connexion à distance`,
  [`Remote publish`]: `Publication à distance`,
  [`Remove`]: `Retirer`,
  [`Remove Burst Limit`]: `Retirer une limite de diffusion en rafale`,
  [`Remove constraints`]: `Retirer les contraintes`,
  [`Remove Count Limit`]: `Retirer le nombre limite`,
  [`Remove gateway extension`]: `Retirer l'extension de passerelle`,
  [`Remove product`]: `Retirer le produit`,
  [`Remove Rate limit`]: `Retirer une limite de débit`,
  [`Remove the invalid constraints`]: `Retirer les contraintes non valides`,
  [`Remove the invalid constraints from`]: `Retirer les contraintes non valides de`,
  [`Remove action`]: `Retirer une action`,
  [`Remove case`]: `Retirer le cas`,
  [`Remove catch`]: `Retirer une accroche`,
  [`Remove filter`]: `Retirer un filtre`,
  [`Remove input`]: `Retirer une entrée`,
  [`Remove mapping`]: `Retirer un mappage`,
  [`Remove output`]: `Retirer une sortie`,
  [`Remove static value`]: `Retirer une valeur statique`,
  [`Removed`]: `Supprimé`,
  [`Removed by user`]: `Supprimé par l'utilisateur`,
  [`Reorder`]: `Réorganiser`,
  [`Repeat`]: `Répéter`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Répétez l'appel API un certain nombre de fois ou jusqu'à ce que l'utilisateur clique sur le bouton d'arrêt`,
  [`Replace`]: `Remplacer`,
  [`Replace Free Trial with`]: `Remplacer l'essai gratuit par`,
  [`Replace Gold with`]: `Remplacer le plan Gold par`,
  [`Replace GraphQL schema`]: `Remplacer le schéma GraphQL`,
  [`Replace plans`]: `Remplacement de plans`,
  [`Replace private key & public key`]: `Remplacer la clé privée et la clé publique`,
  [`Replace Product`]: `Remplacement du produit`,
  [`Replace product`]: `Remplacer le produit`,
  [`Replace schema`]: `Remplacer le schéma`,
  [`Replace Silver with`]: `Remplacer le plan Silver par`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Remplacez le propriétaire actuel en choisissant le nouveau propriétaire dans la liste des suggestions. Affectez au propriétaire remplacé de nouveaux droits pour qu'il puisse continuer à travailler.`,
  [`Replace|permission`]: `Remplacer`,
  [`Republish`]: `Republier`,
  [`Republish product`]: `Republier le produit`,
  [`Request`]: `Demande`,
  [`Request catch`]: `Demande de capture`,
  [`Request endpoint`]: `Noeud final de demande`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Votre demande de {action} du produit d'API dans le catalogue {catalog} a été approuvée`,
  [`Request approved to {{action}} app {{appName}}`]: `Votre demande de {action} de l'application {appName} a été approuvée`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Votre demande de {action} du produit d'API dans le catalogue {catalog} a été refusée`,
  [`Request denied to {{action}} app {{appName}}`]: `Votre demande de {action} de l'application {appName} a été refusée`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Demande d'approbation pour l'abonnement à un produit d'API du catalogue {catalog}`,
  [`Request for approval to {{action}} app {{appName}}`]: `Demande d'approbation pour {action} l'application {appName}`,
  [`Request headers`]: `En-têtes de demande`,
  [`Request pending to promote application to production`]: `Demande en attente de promotion de l'application vers la production`,
  [`Request query`]: `Interrogation de requête`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `La demande pour {action} le produit d'API dans le catalogue {catalog} a été reçue`,
  [`Request received to {{action}} app {{appName}}`]: `La demande pour {action} l'application {appName} a été reçue`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Retrait de demande d'abonnement à un produit d'API dans le catalogue {catalog}`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Votre demande de {action} d'un produit d'API dans le catalogue {catalog} a été retirée`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Demande de retrait pour {action} l'application {appName}`,
  [`Requested approvals`]: `Approbations demandées`,
  [`Requested By`]: `Demandé par`,
  [`Requested To`]: `Demandé pour`,
  [`Require`]: `Exiger`,
  [`Require applications to provide a client ID`]: `Requiert la spécification d'un ID client par les applications`,
  [`Require approval for all new self service development accounts`]: `Exigez l'approbation de tous les nouveaux comptes de développement en libre-service`,
  [`Required`]: `Obligatoire`,
  [`Required for rate limiting`]: `Requis pour la limitation du débit`,
  [`Required must be checked off when location is in path.`]: `Obligatoire doit être désélectionné lorsque l'emplacement est inclus dans le chemin.`,
  [`Required path`]: `Chemin requis`,
  [`Resend Invite`]: `Renvoyer l'invitation`,
  [`Resend invitation`]: `Renvoyer l'invitation`,
  [`Resend invite`]: `Renvoyer l'invitation`,
  [`Reset`]: `Réinitialiser`,
  [`Reset defaults`]: `Réinitialiser les valeurs par défaut`,
  [`Reset Notification template`]: `Réinitialiser le modèle de notification`,
  [`Reset password`]: `Réinitialiser le mot de passe`,
  [`Reset password TTL has been changed`]: `La durée de vie pour la réinitialisation de mot de passe a été modifiée`,
  [`Reset password time to live`]: `Durée de vie pour la réinitialisation de mot de passe`,
  [`Reset password time-to-live`]: `Durée de vie pour la réinitialisation de mot de passe`,
  [`Reset password email has been sent`]: `Le courrier électronique de réinitialisation de mot de passe a été envoyé`,
  [`Reset password email has not been sent`]: `Le courrier électronique de réinitialisation de mot de passe n'a pas été envoyée`,
  [`Reset password invitation timeout`]: `Délai d'expiration d'invitation relative à la réinitialisation de mot de passe`,
  [`Reset to default preferences`]: `Réinitialiser aux préférences par défaut`,
  [`Resolve API Connect variable references`]: `Résoudre les références à la variable API Connect`,
  [`Resolve XML input data type`]: `Résoudre le type de données d'entrée XML`,
  [`resolver`]: `programme de résolution`,
  [`Resource group`]: `Groupe de ressources`,
  [`Resource Owner`]: `Propriétaire de la ressource`,
  [`Resource owner password grant`]: `Octroi du mot de passe du propriétaire de la ressource`,
  [`Resource owner revocation`]: `Révocation du propriétaire de la ressource`,
  [`Resource owner revocation path`]: `Chemin de révocation du propriétaire de la ressource`,
  [`Resource Owner Security`]: `Sécurité du propriétaire de la ressource`,
  [`Resource owner - JWT`]: `Propriétaire de la ressource - JWT`,
  [`Resource owner - Password`]: `Propriétaire de la ressource - Mot de passe`,
  [`Resource owner password`]: `Mot de passe du propriétaire de la ressource`,
  [`Resources`]: `Ressources`,
  [`Response`]: `Réponse`,
  [`Response catch`]: `Capture de réponse`,
  [`Response Definition`]: `Définition de la réponse`,
  [`Response Time`]: `Temps de réponse`,
  [`Response type`]: `Type de réponse`,
  [`Response context variables`]: `Variables contextuelles de réponse`,
  [`Response headers`]: `En-têtes de réponse`,
  [`Response must have a description`]: `La réponse doit comporter une description`,
  [`Response must have a schema`]: `La réponse doit comporter un schéma`,
  [`Response must have a status code`]: `La réponse doit comporter un code de statut`,
  [`Response object variable`]: `Variable d'objet réponse`,
  [`Response time`]: `Temps de réponse`,
  [`Response time: {{value}}ms`]: `Temps de réponse : {{value}} ms`,
  [`Result of removing`]: `Résultat de la suppression`,
  [`Restrict HTTP transactions to version 1.0.`]: `Restreindre les transactions HTTP à la version 1.0.`,
  [`Restrict to HTTP 1.0`]: `Restreindre à HTTP 1.0`,
  [`Retire`]: `Retirer`,
  [`Retire product`]: `Retrait du produit`,
  [`Retired`]: `Retiré`,
  [`Retire|permission`]: `Retirer`,
  [`Return V5 Responses`]: `Renvoyer les réponses V5`,
  [`Retry selected requests`]: `Retenter les demandes sélectionnées`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Renvoyer les résultats des requêtes d'introspection GraphQL standard à partir du proxy GraphQL`,
  [`Return third-party access_token`]: `Renvoyer l'élément access_token tiers`,
  [`Return third-party access_token with the token response`]: `Renvoyer l'élément access_token tiers avec la réponse de jeton`,
  [`Return third-party id_token`]: `Renvoyer l'élément id_token tiers`,
  [`Return third-party id_token with the token response`]: `Renvoyer l'élément id_token tiers avec la réponse de jeton`,
  [`Return to Login`]: `Revenir à la connexion`,
  [`Reveal in finder`]: `Révéler dans l'outil de recherche`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Passez en revue les éléments et déterminez ceux à afficher ou masquer dans le schéma. Des modifications supplémentaires peuvent être nécessaires pour que le schéma reste valide.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Examinez les avertissements. Nous vous aiderons à améliorer ultérieurement votre schéma grâce à des recommandations intelligentes dans l'éditeur.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Passez en revue les avertissements et appliquez nos recommandations intelligentes pour améliorer votre schéma et protéger votre serveur de back end.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Vérifiez l'URL de base du noeud final d'appel API et spécifiez les domaines traités par la passerelle.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Vérifiez le type de passerelle, le titre, le nom et le récapitulatif. Lorsque vous appelez des API et des commandes API Connect, utilisez le nom de la passerelle.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Consultez le récapitulatif des modifications, qui peut inclure des modifications supplémentaires pour que le schéma reste valide.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Examinez les avertissements détectés dans votre schéma. Nous vous aiderons à améliorer ultérieurement votre schéma et à protéger votre serveur de back end grâce à des recommandations intelligentes dans l'éditeur.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Examinez les avertissements détectés dans votre schéma. Nous vous aiderons à améliorer votre schéma et à protéger votre serveur de back end grâce à des recommandations intelligentes.`,
  [`Reviewed schema, paths and operations`]: `Opérations, chemins et schéma examinés`,
  [`Revocation protocol`]: `Protocole de révocation`,
  [`Revoke token`]: `Révoquer un jeton`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `Documentation RHEL`,
  [`Role`]: `Rôle`,
  [`Role default`]: `Rôle par défaut`,
  [`Role default has been deleted`]: `Le rôle par défaut a été supprimé`,
  [`Role defaults`]: `Rôles par défaut`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Les rôles par défaut sont des modèles de rôle préconfigurés utilisés pour déterminer les rôles par défaut qui sont disponibles lorsqu'une organisation est créée.`,
  [`Role has been created`]: `Le rôle a été créé`,
  [`Role has been deleted`]: `Le rôle a été supprimé`,
  [`Role has been updated`]: `Le rôle a été mis à jour`,
  [`Roles`]: `Rôles`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Les rôles (et les droits qui leur sont affectés) contrôlent l'accès aux fonctions dans API Connect. Les rôles d'organisation d'administration ne s'appliquent qu'aux personnes qui utilisent le gestionnaire de cloud.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Les rôles et les droits contrôlent l'accès aux fonctionnalités d'API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Les rôles et leurs droits associés contrôlent l'accès aux fonctions et la capacité à effectuer des tâches dans API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Les rôles et leurs droits associés contrôlent l'accès aux fonctions et la capacité à effectuer des tâches dans l'interface utilisateur d'API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Les rôles et leurs droits associés contrôlent l'accès aux fonctions et la capacité à effectuer des tâches.`,
  [`Root`]: `Racine`,
  [`Root XML Element Name`]: `Nom de l'élément XML racine`,
  [`Run the following command in the CLI to register the credentials:`]: `Entrez la commande suivante dans l'interface CLI pour enregistrer les données d'identification :`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Entrez la commande suivante dans l'interface CLI pour enregistrer les données d'identification du kit d'outils :`,
  [`Running`]: `En cours d'exécution`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Variable d'exécution contenant le JWK à utiliser pour déchiffrer le jeton Web JSON.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Variable d'exécution contenant le JWK à utiliser pour chiffrer le jeton Web JSON.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Variable d'exécution contenant le JWK à utiliser pour signer le jeton Web JSON.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Variable d'exécution contenant le JWK à utiliser pour vérifier la signature.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Variable d'exécution à partir de laquelle un ensemble de réclamations JSON peut être extrait.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Variable d'exécution à partir de laquelle la chaîne de réclamation du public (aud) peut être extraite. Vous pouvez définir plusieurs variables en les séparant par des virgules.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Variable d'exécution à partir de laquelle la chaîne de réclamation de l'émetteur (iss) peut être extraite. Cette réclamation représente le principal ayant émis le jeton Web JSON.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Variable d'exécution à partir de laquelle la chaîne de réclamation du sujet (sub) peut être extraite.`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Variable d'exécution dans laquelle placer le jeton Web JSON généré. Si cette variable n'est pas définie, le jeton Web JSON est placé dans l'en-tête d'autorisation sous forme de jeton bearer.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Variable d'exécution contenant le nom d'utilisateur authentifié. Le jeton LTPA est généré avec cette propriété en tant qu'utilisateur. Par exemple, $(client.app.id) ou $(oauth.resource-owner) ou une variable d'exécution configurée dans une stratégie set-variable.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `Variable d'exécution à laquelle est affectée la totalité des réclamations figurant dans le JWT.`,
  [`Russian`]: `Russe`,
  [`Scalar`]: `Scalaire`,
  [`Schema`]: `Schéma`,
  [`SCHEMA`]: `SCHEMA`,
  [`SCOPE`]: `PORTEE`,
  [`SCOPES`]: `PORTEES`,
  [`SECURITY DEFINITIONS`]: `DEFINITIONS DE SECURITE`,
  [`Service`]: `Maintenance`,
  [`Service state:`]: `Etat du service :`,
  [`SERVICE TYPE`]: `TYPE DE SERVICE`,
  [`SIL-OFL 1.1 license`]: `Licence SIL-OFL 1.1`,
  [`SOURCE`]: `SOURCE`,
  [`SSL Profile`]: `Profil SSL`,
  [`State`]: `Etat`,
  [`STATUS`]: `STATUT`,
  [`Status Code`]: `Code de statut`,
  [`STATUS CODE`]: `CODE DE STATUT`,
  [`SUBMITTED`]: `SOUMIS`,
  [`SUBSCRIBERS`]: `ABONNES`,
  [`Summary`]: `Récapitulatif`,
  [`Same as product`]: `Identique au produit`,
  [`Sample ID token signing key (RS256)`]: `Exemple de clé de signature de jeton d'ID (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Exemple de clé de validation de jeton d'ID (RS256)`,
  [`Sample OAuth symmetric key value`]: `Exemple de valeur de clé symétrique OAuth`,
  [`Sample URL: https://api.catalog.org.host/`]: `Exemple d'URL : https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `Exemple d'URL : https://host/org/catalog/`,
  [`Sample scope description 1`]: `Exemple de description de portée 1`,
  [`Sandbox - Overview`]: `Bac à sable - Présentation`,
  [`Sandbox Catalog`]: `Catalogue pour bac à sable`,
  [`Sandbox Catalog User Registry`]: `Registre d’utilisateurs du catalogue pour bac à sable`,
  [`Sandbox Default TLS client profile`]: `Profil de client TLS par défaut du bac à sable`,
  [`Sandbox Default TLS Client Profile`]: `Profil de client TLS par défaut du bac à sable`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Le catalogue pour bac à sable doit toujours exister dans une organisation de type fournisseur.`,
  [`Save`]: `Sauvegarder`,
  [`Save API as a new version`]: `Sauvegarde de l'API sous une nouvelle version`,
  [`Save Product as a new version`]: `Sauvegarde du produit sous une nouvelle version`,
  [`Save and Edit`]: `Sauvegarder et éditer`,
  [`Save and republish`]: `Sauvegarder et republier`,
  [`Save as a new version`]: `Sauvegarder en tant que nouvelle version`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Sauvegardez la valeur confidentielle du client (elle ne pourra plus être extraite pour des raisons de sécurité)`,
  [`Save this product as a new version`]: `Sauvegarder ce produit sous une nouvelle version`,
  [`Saved`]: `Sauvegardé`,
  [`Saving assembly...`]: `Sauvegarde de l'assemblage...`,
  [`Saving...`]: `Sauvegarde en cours...`,
  [`Schema Type`]: `Type de schéma`,
  [`Schema as JSON`]: `Schéma en tant que JSON`,
  [`Schema as XML`]: `Schéma en tant que XML`,
  [`Schema as YAML`]: `Schéma en tant que YAML`,
  [`Schema change`]: `Modification du schéma`,
  [`Schema changes`]: `Modifications de schéma`,
  [`Schema definition circular reference limit`]: `Limite de référence circulaire de définition de schéma`,
  [`Schema name`]: `Nom du schéma`,
  [`Schema warnings`]: `Avertissements relatifs au schéma`,
  [`Schema definition`]: `Définition du schéma`,
  [`Scheme`]: `Schéma`,
  [`Schemes`]: `Schémas`,
  [`Scope`]: `Portée`,
  [`Scopes`]: `Portées`,
  [`Scopes cannot be empty`]: `Les portées ne peuvent pas être vides`,
  [`Search`]: `Rechercher`,
  [`Search DN`]: `Rechercher le nom distinctif`,
  [`Search in the field above above and select to add provider organizations`]: `Lancez une recherche dans la zone ci-dessus et choisissez d'ajouter des organisations de type fournisseur`,
  [`Search Limit`]: `Limite de la recherche`,
  [`Search Organization owner from`]: `Recherche d'un propriétaire d'organisation dans`,
  [`Search orgs/groups`]: `Rechercher dans les organisations/groupes`,
  [`Search Owner`]: `Recherche du propriétaire`,
  [`Search assemblies`]: `Rechercher dans les assemblages`,
  [`Search for`]: `Rechercher`,
  [`Search for provider organizations`]: `Rechercher des organisations de type fournisseur`,
  [`Search for Owner`]: `Recherche du propriétaire`,
  [`Search for a new member to become the new owner.`]: `Recherchez un nouveau membre qui deviendra le nouveau propriétaire.`,
  [`Search for an existing member to become the new owner.`]: `Recherchez un membre existant qui deviendra le nouveau propriétaire.`,
  [`Search for new owner`]: `Rechercher un nouveau propriétaire`,
  [`Search for owner from:`]: `Rechercher un propriétaire dans :`,
  [`Search for the member`]: `Rechercher le membre`,
  [`Search for title or name or version`]: `Rechercher un titre, un nom ou une version`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Recherchez l'utilisateur à ajouter comme membre et affectez les rôles requis.`,
  [`Search overflow`]: `Rechercher dans Overflow`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Recherchez dans le registre l'utilisateur existant à ajouter comme membre, et affectez les rôles requis`,
  [`Search types/fields`]: `Types/zones de recherche`,
  [`Search user`]: `Rechercher un utilisateur`,
  [`Search schema`]: `Rechercher un schéma`,
  [`Seconds`]: `Secondes`,
  [`Secret Key`]: `Clé secrète`,
  [`Secret Name`]: `Nom de la valeur confidentielle`,
  [`Secret Required`]: `Valeur confidentielle requise`,
  [`Secret key`]: `Clé secrète`,
  [`Secure`]: `Sécurisation`,
  [`Secure connection`]: `Connexion sécurisée`,
  [`Secure using Client ID`]: `Sécuriser à l'aide de l'ID client`,
  [`Security`]: `Sécurité`,
  [`Security & Product Definitions`]: `Sécurité et définitions de produit`,
  [`Security definitions`]: `Définitions de sécurité`,
  [`Security and Publish`]: `Sécurité et publication`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Les définitions de sécurité contrôlent l'accès du client aux noeuds finaux d'API, notamment la validation de clé API, l'authentification de base via un registre d'utilisateurs spécifié et OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Les définitions de sécurité contrôlent l'accès du client aux noeuds finaux d'API, notamment la validation de clé API, l'authentification de base via un registre d'utilisateurs spécifié et OAuth. [En savoir plus]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Les définitions de sécurité sélectionnées ici s'appliquent dans l'API, mais peuvent être remplacées pour des opérations individuelles.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Les définitions de sécurité sélectionnées ici s'appliquent dans l'API, mais peuvent être remplacées pour des opérations individuelles. [En savoir plus]({link})`,
  [`Security object to use to decode the claim.`]: `Objet de sécurité à utiliser pour décoder la réclamation.`,
  [`Security object used to encrypt the JWT.`]: `Objet de sécurité utilisé pour chiffrer le jeton JWT.`,
  [`Security object used to sign the JWT.`]: `Objet de sécurité utilisé pour signer le jeton JWT.`,
  [`Security object used to verify the claim.`]: `Objet de sécurité utilisé pour vérifier la réclamation.`,
  [`Select APIs to add to this product`]: `Sélectionnez les API à ajouter à ce produit`,
  [`Select API Type`]: `Sélectionner le type d'API`,
  [`Select API type`]: `Sélectionner le type d'API`,
  [`Select a billing integration in the Product plans.`]: `Sélectionnez une intégration de facturation dans les plans de produit.`,
  [`Select a flow...`]: `Sélectionner un flux...`,
  [`Select a plan from your existing product`]: `Sélectionner un plan à partir de votre produit existant`,
  [`Select a policy file to import to this catalog`]: `Sélectionnez un fichier de stratégies à importer dans ce catalogue :`,
  [`Select a policy implementation file to import to this catalog`]: `Sélectionnez un fichier d'implémentation de stratégies à importer dans ce catalogue`,
  [`Select a product`]: `Sélectionner un produit`,
  [`Select Certificate Manager service`]: `Sélectionnez le service de gestionnaire de certificat`,
  [`Select Cloud`]: `Sélection d'un cloud`,
  [`Select Email Server`]: `Sélection du serveur de messagerie`,
  [`Select gateway services`]: `Sélection des services de passerelle`,
  [`Select Import Type`]: `Sélection du type d'importation`,
  [`Select product`]: `Sélection du produit`,
  [`Select product type`]: `Sélectionner le type de produit`,
  [`Select Service`]: `Sélection d'un service`,
  [`Select Service Type`]: `Sélection du type de service`,
  [`Select target endpoints`]: `Sélection de noeuds finaux cible`,
  [`Select target service`]: `Sélection d'un service cible`,
  [`Select user registry type`]: `Sélection du type de registre d'utilisateurs`,
  [`Select a WSDL service from the imported file`]: `Sélectionnez un service WSDL dans le fichier importé`,
  [`Select a certificate`]: `Sélectionnez un certificat`,
  [`Select a Certificate Manager service`]: `Sélectionnez un service de gestionnaire de certificat`,
  [`Select a cryptographic algorithm.`]: `Sélectionnez un algorithme de cryptographie.`,
  [`Select a default catalog and organization`]: `Sélection d'un catalogue par défaut et d'une organisation`,
  [`Select a different cloud`]: `Sélectionner un autre cloud`,
  [`Select a key encryption algorithm.`]: `Sélectionnez un algorithme de chiffrement de clé.`,
  [`Select a plan to migrate`]: `Sélectionner un plan à migrer`,
  [`Select a plan to replace`]: `Sélectionner un plan à remplacer`,
  [`Select a plan to supersede`]: `Sélectionner un plan à remplacer`,
  [`Select a product to set as the migration target`]: `Sélectionnez un produit à définir comme cible de migration`,
  [`Select a product to supersede`]: `Sélectionnez un produit à substituer`,
  [`Select a product to supersede {sourceProductName}:`]: `Sélectionnez un produit à substituer à {sourceProductName} :`,
  [`Select a resource group`]: `Sélectionnez un groupe de ressources`,
  [`Select a service`]: `Sélectionnez un service`,
  [`Select a supported file ({type}) and try again.`]: `Sélectionnez un fichier pris en charge ({type}) et renouvelez l'opération.`,
  [`Select a target SOAP service`]: `Sélectionnez un service SOAP cible`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Sélectionnez un package de kit d'outils à télécharger. Si le kit d'outils est déjà installé, ignorez cette étape.`,
  [`Select an encryption algorithm.`]: `Sélectionnez un algorithme de chiffrement.`,
  [`Select an extension file for this gateway`]: `Sélectionnez un fichier d'extension pour cette passerelle`,
  [`Select an org`]: `Sélectionner une organisation`,
  [`Select catalog`]: `Sélectionner un catalogue`,
  [`Select Catalogs to Search`]: `Sélectionner les catalogues à rechercher`,
  [`Select compatible gateway services`]: `Sélectionner tous les services de passerelle compatibles`,
  [`Select one Keystore from the list below:`]: `Sélectionnez un magasin de clés dans la liste suivante :`,
  [`Select one keystore from the list below:`]: `Sélectionnez un magasin de clés dans la liste suivante :`,
  [`Select one keystore from the list.`]: `Sélectionnez un magasin de clés dans la liste.`,
  [`Select operation...`]: `Sélectionner une opération...`,
  [`Select operations you want to include`]: `Sélectionner les opérations à inclure`,
  [`Select organization`]: `Sélectionner une organisation`,
  [`Select the API definition or package file to import`]: `Sélectionnez la définition d'API ou le fichier de package à importer`,
  [`Select the billing integration for this catalog`]: `Sélectionner l'intégration de la facturation pour ce catalogue`,
  [`Select the billing integration for this product`]: `Sélectionner l'intégration de facturation pour ce produit`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Sélectionnez les passerelles qui sécuriseront les API dans un nouveau catalogue. Pour enregistrer une nouvelle passerelle, visitez la page **Passerelles**.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Sélectionnez le service [Certificate Manager]({link}) qui gère les certificats pour cette passerelle.`,
  [`Select the APIs to add to this product`]: `Sélectionnez les API à ajouter à ce produit`,
  [`Select the OpenAPI definition file to import`]: `Sélectionnez le fichier de définition OpenAPI à importer`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Sélectionnez les actions du cycle de vie des produits nécessitant une approbation. Lorsque l'une des actions sélectionnées est effectuée, une demande d'approbation est générée ; la demande doit être approuvée pour que l'action aboutisse.`,
  [`Select the TLS protocol versions for this profile.`]: `Sélectionnez les versions de protocole TLS pour ce profil.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Sélectionnez le service d'analyse à associer au service de passerelle`,
  [`Select the consumer organization in which you want to create the application`]: `Sélectionnez l'organisation de type consommateur dans laquelle créer l'application`,
  [`Select the consumer organization that will own the application`]: `Sélectionnez l'organisation de type consommateur qui sera propriétaire de l'application`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Sélectionnez les noeuds finaux pour publier cette API et définissez la limite de débit si nécessaire`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Sélectionnez les erreurs qui, si elles sont générées lors de l'exécution de la stratégie, entraînent l'arrêt du flux d'assemblage. Si un flux d'interceptions est configuré pour l'erreur, il est déclenché pour traiter l'erreur générée. Si une erreur est générée et qu'aucune erreur n'est sélectionnée pour le paramètre Arrêter en cas d'erreur ou que l'erreur générée ne correspond pas à l'une des erreurs sélectionnées, l'exécution de la stratégie peut se poursuivre et le flux d'assemblage continue.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Sélectionnez les services de passerelle pour lesquels les API du produit doivent être rendues disponibles.`,
  [`Select the gateway type for this API`]: `Sélectionnez le type de passerelle pour cette API`,
  [`Select the gateway type for this OAuth provider`]: `Sélectionnez le type de passerelle pour ce fournisseur OAuth`,
  [`Select the keystores and truststores for this profile.`]: `Sélectionnez les magasins de clés et les magasins de clés de confiance pour ce profil.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Sélectionnez les organisations ou les groupes pour lesquels vous souhaitez rendre ce produit visible`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Sélectionnez les organisations ou les groupes que vous aimeriez voir s'abonner à ce produit`,
  [`Select the portal service to use for this catalog`]: `Sélectionnez le service de portail à utiliser pour ce catalogue`,
  [`Select the product and plan for the application subscription`]: `Sélectionnez le produit et le plan pour l'abonnement à l'application`,
  [`Select the product definition file to import`]: `Sélectionner le fichier de définition de produit à importer`,
  [`Select the product definition file to import from`]: `Sélectionnez le fichier de définition de produit à partir duquel l'importation doit être effectuée`,
  [`Select the product that will supersede the originally selected product`]: `Sélectionnez le produit devant se substituer au produit sélectionné initialement`,
  [`Select the product that you want to add the API to`]: `Sélectionnez le produit auquel ajouter l'API`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Sélectionnez les registres à utiliser pour contrôler l'accès aux API dans ce catalogue`,
  [`Select the required operations`]: `Sélectionnez les opérations requises`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Sélectionnez le niveau de gravité pour les messages de journal associés aux données d'entrée. Les niveaux possibles sont Erreur, Avertissement et Info.`,
  [`Select the target wsdl file to create from`]: `Sélectionnez le fichier WSDL cible à partir duquel effectuer la création`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Sélectionnez le fichier wsdl, xsd ou zip cible à partir duquel effectuer la création`,
  [`Select the target WSDL file to import`]: `Sélectionnez le fichier WSDL cible à importer`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Sélectionnez les registres d'utilisateurs à employer pour authentifier les utilisateurs d'API Manager, ou créez un registre d'utilisateurs.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Sélectionnez les registres d'utilisateurs à employer pour authentifier les utilisateurs d'API Manager, ou créez un registre d'utilisateurs.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Sélectionnez les registres d'utilisateurs à employer pour authentifier les utilisateurs du gestionnaire de cloud, ou créez un registre d'utilisateurs.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Sélectionnez les registres d'utilisateurs qui servent à authentifier la connexion au portail de développeur`,
  [`Select the user registry type`]: `Sélection du type de registre d'utilisateurs`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Sélectionnez l'emplacement de la source en sortie dans lequel la stratégie doit placer le jeton LTPA généré.  L'option Dans l'en-tête WSSec ne doit être sélectionnée que si le type de contenu sur la demande est application/xml.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Indiquez si la propriété doit être retirée complètement ou si la zone doit être occultée à l'aide de "*"s pour bloquer les données.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Le produit sélectionné a été modifié. Actualisez cette page et renouvelez l'opération.`,
  [`Selecting operations from WSDL service definition`]: `Sélection d'opérations à partir de la définition de service WSDL`,
  [`Self Service Onboarding for Consumer organization`]: `Intégration en libre-service pour l'organisation de type consommateur`,
  [`Self service onboarding`]: `Intégration en libre-service`,
  [`Self service onboarding approval`]: `Approbation d'intégration en libre-service`,
  [`Send`]: `Envoyer`,
  [`Send Message`]: `Envoyer un message`,
  [`Send Message to Owners`]: `Envoyer un message aux propriétaires`,
  [`Send To`]: `Envoyer à`,
  [`Send a registration link to a new user to register as owner.`]: `Envoyez un lien d'enregistrement à un nouvel utilisateur pour qu'il s'enregistre en tant que propriétaire.`,
  [`Send a test email`]: `Envoyer un courrier électronique de test`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Envoyer un courrier électronique de test pour confirmer que le serveur de messagerie est correctement configuré.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Envoyer un signal de test pour vérifier que le noeud final est correctement configuré.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Envoyer un signal de test pour vérifier que le noeud final est correctement configuré`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Envoyez un message électronique au propriétaire de l'organisation d'administration. Cochez le nom du propriétaire.`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Envoyez un message électronique à un propriétaire. Cochez le nom du propriétaire.`,
  [`Send chunked-encoded documents to the target server.`]: `Envoyer des documents codés par blocs au serveur cible.`,
  [`Send message`]: `Envoyer un message`,
  [`Send message to owner`]: `Envoyer un message au propriétaire`,
  [`Send test email`]: `Envoyer un courrier électronique de test`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Envoyez le lien d'activation à un utilisateur pour l'inviter à se connecter ou à s'enregistrer.`,
  [`Send-only`]: `Envoyer uniquement`,
  [`Sender`]: `Expéditeur`,
  [`Sender & email server`]: `Expéditeur et serveur de messagerie`,
  [`Sender & email server have been changed`]: `L'expéditeur et le serveur de messagerie ont été changés`,
  [`Sender Info`]: `Informations sur l'expéditeur`,
  [`Sent`]: `Envoyé`,
  [`Sent events: **{sent}**`]: `Evénements envoyés : **{sent}**`,
  [`Serial`]: `N° série`,
  [`Serialize output`]: `Sérialiser la sortie`,
  [`Server connection`]: `Connexion serveur`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `Fournir le HTML aux navigateurs Web pour activer le client GraphQL de l'interface graphique`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Fournissez le HTML aux navigateurs Web pour activer le client GraphQL de l'interface graphique.`,
  [`Server Name Indication (SNI)`]: `SNI (Server Name Indication)`,
  [`Server URL`]: `URL de serveur`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Le serveur a renvoyé une erreur dans le hachage au lieu du jeton dans l'URL de redirection OIDC. Hachage :`,
  [`Service name`]: `Nom du service`,
  [`Services`]: `Services`,
  [`Set`]: `Définir`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Définissez la variable d'environnement du système d'exploitation APIC_DESIGNER_CREDENTIALS pour API Designer. Sur MAC, par exemple, l'utilisateur peut transmettre la variable d'environnement lors du lancement d'API Designer.`,
  [`Set Migration Target`]: `Définir la cible de migration`,
  [`Set migration target`]: `Définir la cible de migration`,
  [`Set Variable`]: `Définir une variable`,
  [`Set as default`]: `Définir comme valeur par défaut`,
  [`Set default`]: `Définir par défaut`,
  [`Set rate and burst limits in an assembly`]: `Définissez les limites de débit et de diffusion en rafale dans un assemblage`,
  [`Set the timeout period for invitations`]: `Définissez le délai d'expiration des invitations`,
  [`Set the trust level for the profile`]: `Définissez le niveau de confiance pour le profil`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Définissez la valeur sur le nombre d'itérations d'une définition de schéma circulaire autorisées (valeur par défaut 1, maximum 5)`,
  [`Set the visibility for a gateway service`]: `Définissez la visibilité pour un service de passerelle`,
  [`Set the visibility for a portal service`]: `Définissez la visibilité pour un service de portail`,
  [`Set the visibility for each service`]: `Définissez la visibilité pour chaque service`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Configurez le délai d'obtention d'un nouveau jeton d'accès émis par le serveur d'authentification`,
  [`Set visibility`]: `Définir la visibilité`,
  [`Set visibility of gateway`]: `Définir la visibilité de la passerelle`,
  [`Set time interval`]: `Définir l'intervalle de temps`,
  [`Set, Add, or Clear a runtime variable.`]: `Définissez, ajoutez ou effacez une variable d'exécution.`,
  [`Set the timeout period for API key`]: `Définissez la période d'attente pour la clé d'API`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Définit la période de validité en secondes pour les documents dans le cache. S'applique uniquement si le type de cache est défini sur "Durée de vie". Entrez une valeur comprise entre 5 et 31708800. La valeur par défaut est 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `La définition d'une valeur pour "assumedSize" ne peut pas être combinée avec la définition d'un argument de tranche comportant une valeur par défaut ou ne peut pas être combinée avec l'activation de "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `La définition de ce catalogue comme catalogue par défaut permettra d'accéder à ce catalogue à l'aide d'une URL plus courte en omettant le nom du catalogue. Vous ne pouvez définir qu'un seul catalogue comme catalogue par défaut.`,
  [`Settings`]: `Paramètres`,
  [`Setup`]: `Configuration`,
  [`Severity level for input data log messages`]: `Niveau de gravité pour les messages du journal des données d'entrée`,
  [`Share the resource with a custom list of provider organizations`]: `Partagez la ressource avec une liste personnalisée d'organisations de type fournisseur`,
  [`Share the resource with provider organizations`]: `Partagez la ressource avec des organisations de type fournisseur`,
  [`Share the API gateway with a custom list of provider organizations`]: `Partagez la passerelle d'API avec une liste personnalisée d'organisations de type fournisseur`,
  [`Share the service with a custom list of provider organizations`]: `Partagez le service avec une liste personnalisée d'organisations de type fournisseur`,
  [`Share the API gateway with provider organizations`]: `Partagez la passerelle d'API avec des organisations de type fournisseur`,
  [`Share the service with provider organizations`]: `Partagez le service avec des organisations de type fournisseur`,
  [`Share`]: `Partager`,
  [`Shared`]: `Partagé`,
  [`Shared by`]: `Partagé par`,
  [`Short but descriptive name for this policy.`]: `Nom abrégé mais descriptif de cette stratégie`,
  [`Show`]: `Afficher`,
  [`Show / hide policy palette`]: `Afficher/Masquer la palette des stratégies`,
  [`Show/hide`]: `Afficher/masquer`,
  [`Show/hide settings`]: `Afficher/masquer les paramètres`,
  [`Show and hide types and fields to include in the schema.`]: `Afficher et masquer les types et les zones à inclure dans le schéma.`,
  [`Show added only`]: `Afficher les ajouts uniquement`,
  [`Show all`]: `Tout afficher`,
  [`Show all UDP versions`]: `Afficher toutes les versions UDP`,
  [`Show app identifier`]: `Afficher l'identificateur de l'application`,
  [`Show breaking changes only`]: `Afficher les modifications avec rupture uniquement`,
  [`Show catalog identifier`]: `Afficher l'identificateur du catalogue`,
  [`Show catches`]: `Afficher les accroches`,
  [`Show in schema`]: `Afficher dans schéma`,
  [`Show less`]: `Moins de détails`,
  [`Show modified only`]: `Afficher les modifications uniquement`,
  [`Show more`]: `Plus de détails`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Affichez de nouveaux types, zones, directives ou arguments facultatifs. Les éléments qui ne sont plus pris en charge par le système dorsal seront toujours supprimés.`,
  [`Show removed only`]: `Afficher les suppressions uniquement`,
  [`Show schema`]: `Afficher le schéma`,
  [`Show warnings only`]: `Afficher les avertissements uniquement`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Affiche l'historique des affectations de magasin de clés à l'exception du magasin de clés actuellement affecté.`,
  [`Sign Crypto Object`]: `Objet crypto de signature`,
  [`Sign in`]: `Connexion`,
  [`Sign JWK variable name`]: `Nom de variable de signature JWK`,
  [`Sign Out`]: `Se déconnecter`,
  [`Sign up`]: `S'inscrire`,
  [`sign-up`]: `connexion`,
  [`Sign in using the {userRegistryType} User Registry`]: `Connectez-vous à l'aide du registre d'utilisateurs {userRegistryType}`,
  [`Sign in with`]: `Se connecter avec`,
  [`Sign out`]: `Se déconnecter`,
  [`Sign up with`]: `S'inscrire avec`,
  [`Signed token`]: `Jeton signé`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Depuis que la mise en mémoire tampon a été désactivée, le contenu et/ou le contenu des erreurs doit être réduit du contenu aux en-têtes. Voulez-vous continuer la modification requise ou annuler votre modification récente ?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Depuis que le contenu et/ou le contenu des erreurs a été défini sur le contenu, la mise en mémoire tampon doit être activée. Voulez-vous continuer la modification requise ou annuler votre modification récente ?`,
  [`Sized fields`]: `Zones dimensionnées`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Arguments de tranche`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Certaines entrées sont des tableaux ou sont contenues dans des tableaux. Pour itérer sur le contenu, choisissez l'option d'itération, puis l'entrée sur laquelle itérer.`,
  [`Something went wrong.`]: `Il y a eu un problème.`,
  [`Something went wrong...`]: `Il y a eu un problème...`,
  [`Source`]: `Source`,
  [`Source schema`]: `Schéma source`,
  [`Space`]: `Espace`,
  [`Space is not allowed in scope name`]: `L'espace n'est pas autorisé dans le nom de portée`,
  [`Space details`]: `Détails de l'espace`,
  [`Space Enabled`]: `Espace activé`,
  [`Space Name`]: `Nom d'espace`,
  [`Space Summary`]: `Récapitulatif de l'espace`,
  [`Space Title`]: `Titre d'espace`,
  [`Space owner`]: `Propriétaire de l'espace`,
  [`Space {name} created`]: `L'espace {name} a été créé`,
  [`Spaces`]: `Espaces`,
  [`Spaces are enabled`]: `Les espaces sont activés`,
  [`Spaces are not enabled`]: `Les espaces ne sont pas activés`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Les espaces ne peuvent pas être activés pour le catalogue pour bac à sable.`,
  [`Spaces cannot be enabled.`]: `Les espaces ne peuvent pas être activés.`,
  [`Spaces dropdown`]: `Menu déroulant des espaces`,
  [`Spaces has been enabled`]: `Les espaces ont été activés`,
  [`Spaces have been enabled.`]: `Les espaces ont été activés.`,
  [`Spaces per page`]: `Espaces par page`,
  [`Spanish`]: `Espagnol`,
  [`Special characters are not accepted`]: `Les caractères spéciaux ne sont pas acceptés`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Indique une URL ou une chaîne qui représente un contexte nommé à partir duquel extraire les propriétés XML ou JSON sérialisées qui fusionnent dans l'objet dynamique. Ces propriétés sont prioritaires par rapport aux propriétés de littéral ou par défaut existantes.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Indique une chaîne littérale telle que des propriétés XML ou JSON qui fusionnent dans l'objet dynamique. Ces propriétés sont prioritaires par rapport aux propriétés par défaut existantes.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Indique un objet valide existant à partir duquel extraire les valeurs de propriété par défaut pour l'objet dynamique. Avertissement : Si vous changez la chaîne par défaut, l'objet de paramètre d'analyse indiqué doit être disponible sur le dispositif DataPower.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Indique la source de données qui contient le contenu à occulter ou retirer. Si la racine n'est pas spécifiée, l'action est appliquée à l'ensemble du contexte d'API. Vous pouvez utiliser toute expression de chemin JSONata prise en charge.\n\nL'occultation des données de demande et de réponse peut être effectuée avec une racine "message.body". Si l'action d'occultation d'assemblage est utilisée dans un assemblage après une action de consignation d'assemblage indiquant le mode de "collecte uniquement", la racine peut indiquer "log.request_body" pour le contenu de demande consigné ou "log.response_body" pour le contenu de réponse consigné.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Indique le nom d'une variable dans le contexte d'API. Le contenu de la zone "body" de la variable représente l'entrée de l'action. Le nom de variable par défaut est "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Indique le nom d'une variable dans le contexte d'API. Le contenu de la zone "body" de la variable représente la sortie de l'analyse. Les métriques d'analyse du document analysé peuvent être stockées dans une partie différente du message. Le nom de variable par défaut étant le nom de l'entrée, le message d'entrée sera remplacé par défaut par le message de sortie.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Indique comment et où obtenir des métadonnées personnalisées pour le jeton d'accès.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Indique s'il faut tenter une analyse avec le type de contenu spécifié. Cette option n'est applicable que si le type de contenu attendu est json ou xml. Lorsque cette option est activée, toute opération d'analyse échouera si le type de contenu ne correspond pas aux paramètres d'analyse (par exemple, Content-type:application/json et paramètre d'analyse configuré pour xml). Si le paramètre d'analyse est configuré pour détecter le contenu en entrée, sa valeur sera forcée pour être celle du type de contenu spécifié.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Indique si l'arborescence de sortie XSLT doit être sérialisée en données binaires après la transformation.`,
  [`Specify default gateways`]: `Spécification des passerelles par défaut`,
  [`Specify email`]: `Spécification de l'adresse électronique`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Spécifiez une définition de schéma OpenAPI (Swagger) avec laquelle valider le contenu ("request", "response" ou "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Indiquez le propriétaire de l'organisation de type consommateur`,
  [`Specify owner of the provider organization`]: `Indiquez le propriétaire de l'organisation de type fournisseur`,
  [`Specify settings for the third party OAuth provider.`]: `Spécifiez les paramètres pour le fournisseur OAuth tiers.`,
  [`Specify the MIME types that your API consumes and produces`]: `Spécifiez les types MIME que votre API consomme et génère`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Spécifiez le propriétaire du catalogue ; celui-ci dispose de tous les droits sur le catalogue`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Spécifiez les organisations de type consommateur ou les communautés de développeurs qui pourront voir le produit sur le portail de développeur`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Spécifiez les organisations de type consommateur ou les communautés de développeurs qui pourront s'abonner afin d'utiliser les API du produit`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Spécifiez l'URL de base du noeud final d'appel API et les domaines traités par la passerelle.`,
  [`Specify the details of the catalog that you want to publish to`]: `Spécifiez les détails du catalogue dans lequel procéder à la publication`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Spécifiez l'ensemble de passerelles par défaut qui sécurisera les API dans un nouveau catalogue. [En savoir plus]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Spécifiez les noeuds finaux sur lesquels cette API doit être publiée et indiquez si vous voulez limiter le débit des appels API`,
  [`Specify the new owner`]: `Spécification du nouveau propriétaire`,
  [`Specify the owner`]: `Spécification du propriétaire`,
  [`Specify the owner and title`]: `Spécification du propriétaire et du titre`,
  [`Specify the properties to redact or remove.`]: `Indiquez les propriétés à occulter ou retirer.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Spécifiez les exigences relatives aux limites de publication et de débit pour cette API`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Spécifiez le noeud final de vérification de portée sur lequel la vérification de la portée supplémentaire est effectuée, en plus des portées définies.`,
  [`Specify the security settings for the imported API definition`]: `Spécification des paramètres de sécurité pour la définition d'API importée`,
  [`Specify the security settings for your API definition`]: `Spécification des paramètres de sécurité pour votre définition d'API`,
  [`Specify the space owner; the owner has all space permissions`]: `Spécifiez le propriétaire de l'espace ; celui-ci dispose de tous les droits sur l'espace`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Spécifiez le service SOAP cible et les détails récapitulatifs de l'API ; si nécessaire, vous pouvez configurer la définition d'API plus en détail après sa création`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Spécifiez le délai d'expiration des courriers électroniques d'invitation lorsque l'utilisateur n'active pas son compte`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Spécifiez le délai d'expiration des courriers électroniques d'invitation lorsque l'utilisateur n'active pas son compte.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Indiquez le type de passerelle, un titre et un récapitulatif qui décrit la passerelle.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Indiquez la visibilité de la passerelle d'API sur l'organisation de type fournisseur API Connect.`,
  [`Specify the way you want to validate the message.`]: `Indiquez le mode de validation du message.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Indiquez l'URL du noeud final de gestion de passerelle et le certificat pour le domaine du noeud final.`,
  [`Specify what content to log in the event of an error.`]: `Indiquez le contenu à consigner en cas d'erreur.`,
  [`Specify what content to log.`]: `Indiquez le contenu à consigner.`,
  [`Specifying the publish target details`]: `Spécification des détails de la cible de publication`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Autoriser spécifiquement la règle xsi:type=SOAP-ENC:Array`,
  [`Stage`]: `Mettre en préproduction`,
  [`Stage API`]: `API de mise en préproduction`,
  [`Stage product`]: `Mise en préproduction du produit`,
  [`Stage to`]: `Mettre en préproduction sur`,
  [`Staged`]: `Mis en préproduction`,
  [`Stage|permission`]: `Mettre en préproduction`,
  [`Standard OIDC`]: `OIDC standard`,
  [`Static DNS`]: `DNS statique`,
  [`Static group`]: `Groupe statique`,
  [`Status`]: `Statut`,
  [`Status code`]: `Code de statut`,
  [`Step 1`]: `Etape 1`,
  [`Step 1: Download`]: `Etape 1 : Téléchargement`,
  [`Step 1: Download toolkit`]: `Etape 1 : Télécharger le kit d'outils`,
  [`Step 1: Upload private key`]: `Etape 1 : Télécharger la clé privée`,
  [`Step 2`]: `Etape 2`,
  [`Step 2: Download credentials`]: `Etape 2 : Télécharger les données d'identification`,
  [`Step 2: Download toolkit credentials`]: `Etape 2: Télécharger les données d'identification du kit d'outils`,
  [`Step 2: Install`]: `Etape 2 : Installation`,
  [`Step 2: Upload public key`]: `Etape 2 : Télécharger la clé publique`,
  [`Step 3`]: `Etape 3`,
  [`Step 3: Configure`]: `Etape 3 : Configuration`,
  [`Step 3: Run command`]: `Etape 3 : Exécuter la commande`,
  [`Step 3: Run commands`]: `Etape 3 : Exécuter les commandes`,
  [`Step 4: Register`]: `Etape 4 : Enregistrement`,
  [`Stop`]: `Arrêter`,
  [`Stop after:`]: `Arrêter après :`,
  [`Stop all the retries`]: `Arrêter toutes les nouvelles tentatives`,
  [`Stop Connection`]: `Arrêter une connexion`,
  [`Stop on error`]: `Arrêter en cas d'erreur`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Arrêtez l'exécution de cette API et supprimez-la du catalogue pour bac à sable.`,
  [`Stopped`]: `Arrêté`,
  [`Stopping Published API`]: `Arrêt de l'API publiée`,
  [`Strict`]: `Strict`,
  [`Strict SOAP envelope version`]: `Version d'enveloppe SOAP stricte`,
  [`String Badgerfish`]: `Chaîne Badgerfish`,
  [`String`]: `Chaîne`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `Chaîne incluse dans le segment d'organisation de l'URL pour les appels API. Utilisez des lettres minuscules, des chiffres et des traits d'union.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Intégration de segment`,
  [`Streaming rule`]: `Règle de diffusion en continu`,
  [`Subject`]: `Objet`,
  [`Subject Claim`]: `Réclamation de sujet`,
  [`Subject Origin`]: `Origine de l'objet`,
  [`Subject common name`]: `Nom usuel de l'objet`,
  [`Subject|certificate`]: `Objet`,
  [`Submit`]: `Soumettre`,
  [`Submitted`]: `Soumis`,
  [`Subscribability`]: `Possibilité d'abonnement`,
  [`Subscribability must be disabled if visibility is disabled`]: `La possibilité d'abonnement doit être désactivée si la visibilité est désactivée`,
  [`Subscribability options`]: `Options d'abonnement`,
  [`Subscribable by`]: `Ouvert à l'abonnement pour`,
  [`Subscribe`]: `S'abonner`,
  [`Subscribe Financial Management App`]: `Abonnement à l'application de gestion financière`,
  [`Subscribe TODO App`]: `Abonnement à l'application TODO`,
  [`Subscription`]: `Abonnement`,
  [`Subscription approval task for subscription of`]: `Tâche d'approbation pour l'abonnement de`,
  [`Subscription created`]: `Abonnement créé`,
  [`Subscription has been added`]: `L'abonnement a été ajouté`,
  [`Subscription has not been added`]: `L'abonnement n'a pas été ajouté`,
  [`Subscription pending approval`]: `Abonnement en attente d'approbation`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `Le type d'abonnement a été retiré car les abonnements GraphQL ne sont pas pris en charge.`,
  [`Subscription-Approval`]: `Approbation-abonnement`,
  [`Subscriptions`]: `Abonnements`,
  [`Success`]: `Opération réussie`,
  [`Suffix`]: `Suffixe`,
  [`Suggestion`]: `Suggestion`,
  [`Supersede`]: `Substituer`,
  [`Supersede Product`]: `Substitution du produit`,
  [`Supersede product`]: `Substitution de produit`,
  [`Supersede plans`]: `Substitution de plans`,
  [`Supersede {product1} with {product2}`]: `Remplacer {product1} par {product2}`,
  [`Supersede|permission`]: `Substituer`,
  [`Supersede|supersede with`]: `Substituer`,
  [`Support`]: `Support`,
  [`support default introspection`]: `prendre en charge l'introspection par défaut`,
  [`Support Server Name Indication (SNI)`]: `Prise en charge de SNI (Server Name Indication)`,
  [`Support hybrid response types`]: `Prise en charge des types de réponses hybrides`,
  [`Support standard introspection`]: `Prendre en charge l'introspection standard`,
  [`Supported OAuth components`]: `Composants OAuth pris en charge`,
  [`Supported client types`]: `Types de client pris en charge`,
  [`Supported client types can not be empty.`]: `Le type de client pris en charge ne peut pas être vide.`,
  [`Supported grant type can not be empty.`]: `Le type d'octroi pris en charge ne peut pas être vide.`,
  [`Supported grant types`]: `Types d'octroi pris en charge`,
  [`Suspend`]: `Suspendre`,
  [`Suspend API`]: `Suspendre l'API`,
  [`Suspend Application`]: `Suspension de l'application`,
  [`Switch`]: `Permutation`,
  [`Switch account`]: `Changer de compte`,
  [`Switch cloud connection`]: `Changer de connexion au cloud`,
  [`Switch directory`]: `Changer de répertoire`,
  [`TARGET`]: `CIBLE`,
  [`Title`]: `Titre`,
  [`TlS`]: `TlS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `Client TLS`,
  [`TLS Client Profile`]: `Profil de client TLS`,
  [`TLS Client Profile has been configured.`]: `Le profil de client TLS a été configuré.`,
  [`TLS client profile is required when endpoint starts with https://`]: `Le profil de client TLS est requis lorsque le noeud final commence par https://`,
  [`TLS Client Profiles`]: `Profils de client TLS`,
  [`TLS client profile has been configured.`]: `Le profil de client TLS a été configuré.`,
  [`TLS client profiles`]: `Profils de client TLS`,
  [`TLS Profile`]: `Profil TLS`,
  [`TLS SERVER PROFILE`]: `PROFIL DE SERVEUR TLS`,
  [`TLS server profile`]: `Profil de serveur TLS`,
  [`TLS version 1.0`]: `TLS version 1.0`,
  [`TLS version 1.1`]: `TLS version 1.1`,
  [`TLS version 1.2`]: `TLS version 1.2`,
  [`TLS version 1.3`]: `TLS version 1.3`,
  [`TLS client profile`]: `Profil de client TLS`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `Les profils de client TLS sont gérés dans chaque espace lorsque les espaces sont activés. Accédez aux paramètres d'espace pour gérer les profils de client TLS.`,
  [`TLS profile`]: `Profil TLS`,
  [`TLS profile is not visible or not available.`]: `Le profil TLS n'est pas visible ou n'est pas disponible.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `Profil TLS à utiliser pour la transmission sécurisée des données à l'URL d'authentification.`,
  [`Tabs for code language options`]: `Onglets des options de langage de code`,
  [`Truststore`]: `Magasin de clés de confiance`,
  [`truststore`]: `magasin de clés de confiance`,
  [`Type`]: `Type`,
  [`Type name`]: `Nom du type`,
  [`Type/Field`]: `Type/zone`,
  [`Type weight`]: `Poids du type`,
  [`Types per page:`]: `Types par page :`,
  [`Tags`]: `Balises`,
  [`Tags and external documentation`]: `Balises et documentation externe`,
  [`Take offline`]: `Mettre hors ligne`,
  [`Target`]: `Cible`,
  [`Target API (URL)`]: `API cible (URL)`,
  [`Target SOAP service`]: `Service SOAP cible`,
  [`Target Services`]: `Services cible`,
  [`Target service URL`]: `URL du service cible`,
  [`Target services`]: `Services cible`,
  [`Target URL`]: `Adresse URL cible`,
  [`Target application`]: `Application cible`,
  [`Target catalog`]: `Catalogue cible`,
  [`Target gateway service`]: `Service de passerelle cible`,
  [`Target gateway services`]: `Services de la passerelle cible`,
  [`Target plan`]: `Plan cible`,
  [`Target product`]: `Produit cible`,
  [`Target product rate limit`]: `Limite de débit du produit cible`,
  [`Target product subscribability`]: `Possibilité d'abonnement au produit cible`,
  [`Target product visibility`]: `Visibilité du produit cible`,
  [`Target schema`]: `Schéma cible`,
  [`Target services have been updated.`]: `Les services cible ont été mis à jour.`,
  [`Task`]: `Tâche`,
  [`Task has been updated`]: `La tâche a été mise à jour`,
  [`Task self approval`]: `Auto-approbation de tâche`,
  [`Task self-approval`]: `Auto-approbation des tâches`,
  [`task-consumer-onboard-request`]: `demande-intégration-consommateur-tâche`,
  [`task-product-lifecycle-approved`]: `tâche-cycle-de-vie-produit-approuvée`,
  [`task-product-lifecycle-cancelled`]: `tâche-cycle-de-vie-produit-annulée`,
  [`task-product-lifecycle-denied`]: `tâche-cycle-de-vie-produit-refusée`,
  [`task-product-lifecycle-pending`]: `tâche-cycle-de-vie-produit-en-attente`,
  [`task-product-lifecycle-request`]: `demande-tâche-cycle-de-vie-produit`,
  [`task-subscription-approved`]: `abonnement-tâche-approuvé`,
  [`task-subscription-cancelled`]: `abonnement-tâche-annulé`,
  [`task-subscription-denied`]: `abonnement-tâche-refusé`,
  [`task-subscription-pending`]: `abonnement-tâche-en-attente`,
  [`task-subscription-request`]: `demande-abonnement-tâche`,
  [`Tasks`]: `Tâches`,
  [`Tasks for creating new provider organizations`]: `Tâches de création d'organisations de fournisseur`,
  [`TCP`]: `TCP`,
  [`Template`]: `Modèle`,
  [`Template: {title}`]: `Modèle : {title}`,
  [`Template: account approved`]: `Modèle : compte approuvé`,
  [`Template: account denied`]: `Modèle : compte refusé`,
  [`Template: account pending approval`]: `Modèle : compte en attente d'approbation`,
  [`Template: app lifecycle approved`]: `Modèle : cycle de vie de l'application approuvé`,
  [`Template: app lifecycle denied`]: `Modèle : cycle de vie de l'application refusé`,
  [`Template: app lifecycle pending`]: `Modèle : cycle de vie de l'application en attente`,
  [`Template: app reinstated`]: `Modèle : application rétablie`,
  [`Template: app suspended`]: `Modèle : app suspendue`,
  [`Template: app lifecycle cancelled`]: `Modèle : cycle de vie de l'application annulé`,
  [`Template: app lifecycle request`]: `Modèle : demande de cycle de vie d'application`,
  [`Template: invitation`]: `Modèle : invitation`,
  [`Template: mail server test connection`]: `Modèle : test de connexion au serveur de messagerie`,
  [`Template: member invitation`]: `Modèle : invitation de membre`,
  [`Template: password changed`]: `Modèle : mot de passe modifié`,
  [`Template: password reset`]: `Modèle : réinitialisation du mot de passe`,
  [`Template: sign up`]: `Modèle : inscription`,
  [`Template: task consumer onboard request`]: `Modèle : demande d'intégration de consommateur de tâche`,
  [`Template: task product lifecycle approved`]: `Modèle : tâche de cycle de vie de produit approuvée`,
  [`Template: task product lifecycle cancelled`]: `Modèle : tâche de cycle de vie de produit annulée`,
  [`Template: task product lifecycle denied`]: `Modèle : tâche de cycle de vie de produit refusée`,
  [`Template: task product lifecycle pending`]: `Modèle : tâche de cycle de vie de produit en attente`,
  [`Template: task product lifecycle request`]: `Modèle : demande de tâche de cycle de vie de produit`,
  [`Template: task subscription cancelled`]: `Modèle : abonnement de tâche annulé`,
  [`Template: task subscription request`]: `Modèle : demande d'abonnement de tâche`,
  [`Template: task subscription approved`]: `Modèle : abonnement de tâche approuvé`,
  [`Template: task subscription denied`]: `Modèle : abonnement de tâche refusé`,
  [`Template: task subscription pending`]: `Modèle : abonnement de tâche en attente`,
  [`Temporarily disable subscribability`]: `Désactiver temporairement la possibilité d'abonnement`,
  [`Temporarily disable visibility`]: `Désactiver temporairement la visibilité`,
  [`Terms of service`]: `Conditions d'utilisation`,
  [`Test`]: `Test`,
  [`Test API`]: `API de test`,
  [`Test APIs`]: `API de test`,
  [`Test Bind & Get Base DN`]: `Tester la liaison et extraire le nom distinctif de base`,
  [`Test configuration`]: `Configuration de test`,
  [`Test connection`]: `Test de connexion`,
  [`Test connection failed.`]: `Le test de la connexion a échoué.`,
  [`Test and production endpoints`]: `Noeuds finaux de test et de production`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Erreur de réponse de la fonction de test et de surveillance : la propriété redirect_uri est requise, reçu : {redirectUri}`,
  [`Test application`]: `Application de test`,
  [`Test consumer org`]: `Organisation de type consommateur de test`,
  [`Test email`]: `Courrier électronique de test`,
  [`Test endpoints`]: `Noeuds finaux de test`,
  [`Test message from IBM API Connect`]: `Message test d'IBM API Connect`,
  [`Testable`]: `Testable`,
  [`TEXT`]: `TEXTE`,
  [`The "Definition" property must have a valid value.`]: `La propriété "Definition" doit être associée à une valeur valide.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `La propriété "Definition" doit avoir une valeur "request", ou "response", ou bien commencer par "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `L'interface de ligne de commande d'API Connect est proposée sous la forme d'un plug-in de l'interface de ligne de commande IBM Cloud. Pour commencer :`,
  [`The API has unsaved changes.`]: `L'API comporte des modifications non sauvegardées.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `La conception de l'API est terminée et cette dernière a franchi avec succès un jalon interne mais n'a pas encore été implémentée.`,
  [`The API is in the implementation phase.`]: `L'API est en phase d'implémentation.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `L'API est en début de phase conceptuelle et n'est ni totalement conçue ni implémentée.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `Il se peut que l'API comporte des modifications non publiées. Voulez-vous republier le produit ?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `Le schéma DNS détermine la composition des URL de noeud final pour les appels API entrants sur le service de passerelle et pour l'accès au portail de développeur.`,
  [`The GatewayScript source code to execute.`]: `Code source GatewayScript à exécuter.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `L'URL du schéma GraphQL permettant de valider un contenu GraphQL.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Méthode HTTP à utiliser pour l'appel. Si vous l'omettez, ou si elle prend la valeur "Conserver", la méthode de la demande entrante est employée.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Méthode HTTP à utiliser pour remplacer la méthode HTTP en cours. Si vous l'omettez, ou si elle prend la valeur "Conserver", la méthode de la demande entrante est employée.`,
  [`The JavaScript source code to execute.`]: `Code source JavaScript à exécuter.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `LDAP (Lightweight Directory Access Protocol) est un protocole Internet permettant d'accéder à des services d'informations de répertoire distribué sur un réseau et de les gérer. Si un service LDAP d'entreprise est activé, configurez-le comme ressource fournissant l'authentification d'utilisateur.`,
  [`The Proxy URL to be used.`]: `Adresse URL du proxy à utiliser.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `Profil SSL à utiliser pour la transmission sécurisée des données.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `Profil TLS à utiliser pour la transmission sécurisée des données.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `URL du schéma JSON permettant de valider un contenu JSON.`,
  [`The URL of the XML schema for validating an XML payload.`]: `URL du schéma XML permettant de valider un contenu XML.`,
  [`The URL to be invoked.`]: `URL à appeler`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `URL du schéma WSDL permettant de valider un contenu SOAP.`,
  [`The XML element name to be used for JSON array elements.`]: `Nom d'élément XML à utiliser pour les éléments de tableau JSON.`,
  [`The XSLT source to execute.`]: `Source XSLT à exécuter`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `Le lien d'activation peut être envoyé à un utilisateur afin qu'il puisse activer son compte`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `Le service d'analyse collecte les événements d'API depuis le service de passerelle. Chaque passerelle est associée à un service d'analyse. Un événement d'API associé à un horodatage est consigné à chaque fois qu'une opération d'API est appelée.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `Le service d'analyse collecte les événements d'API depuis le service de passerelle. Chaque service de passerelle peut être associé à un service d'analyse.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `L'URL d'autorisation est l'endroit où l'application client obtient un octroi d'autorisation. L'URL de jeton est l'endroit où l'application client échange un octroi d'autorisation contre un jeton d'accès.  L'URL d'introspection est l'endroit où la passerelle d'API valide les jetons d'accès émis par le fournisseur tiers.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `Le chemin de base est le segment d'URL initial de l'API ; il n'inclut pas le nom d'hôte ni aucun autre segment pour les chemins ou les opérations`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `Le type de cache détermine si les documents doivent être mis en cache, en respectant ou en ignorant les instructions de contrôle de cache HTTP reçues de la réponse du serveur. Cette propriété n'est appliquée que si une réponse est reçue du serveur ; sinon, la stratégie renvoie toujours la réponse non arrivée à expiration précédemment sauvegardée dans le cache.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Le service de gestionnaire de certificat doit gérer les certificats pour le domaine du noeud final de gestion de passerelle et les domaines que gère la passerelle. En outre, vous devez utiliser IBM Cloud IAM pour autoriser le service API Connect Reserved Instance à accéder au service de gestionnaire de certificat.`,
  [`The certificate with which to decrypt the buffer`]: `Certificat permettant de déchiffrer la mémoire tampon`,
  [`The certificate with which to encrypt the buffer`]: `Certificat permettant de chiffrer la mémoire tampon`,
  [`The change is critical`]: `Le changement est essentiel`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Les applications client peuvent demander uniquement les portées ou un sous-ensemble des portées que vous définissez ici. Les portées sont incluses dans les jetons d'accès générés par le fournisseur. Lorsqu'une API protégée par OAuth est appelée, la passerelle vérifie les portées transportées dans les jetons d'accès en fonction de la liste des portées autorisées dans la définition de sécurité, ce afin de déterminer si l'accès doit être accordé. Vous pouvez en outre appliquer une vérification de portée avancée. Les URL de vérification de portée avancée sont appelées après l'authentification de l'application ou après l'authentification d'utilisateur en fonction des URL configurées. Les droits d'accès finaux à la portée qui sont octroyés par le jeton d'accès sont le résultat de toutes les vérifications de portée.`,
  [`The crypto object to use to decode the claim.`]: `Objet cryptographique à utiliser pour décoder la réclamation.`,
  [`The crypto object to use to encrypt the message.`]: `Objet cryptographique à utiliser pour chiffrer le message.`,
  [`The crypto object to use to sign the JWT.`]: `Objet cryptographique à utiliser pour signer le jeton Web JSON.`,
  [`The crypto object to use to verify the signature.`]: `Objet cryptographique à utiliser pour vérifier la signature.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `Le mappage actuel contient des références à des éléments de schéma non reconnus.`,
  [`The current organization does not contain any catalogs.`]: `L'organisation actuelle ne contient aucun catalogue.`,
  [`The default User registry must be included in the list`]: `Le registre d'utilisateurs par défaut doit être inclus dans la liste`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `Définition de référence permettant de valider le contenu ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Le portail de développeur fournit la vue consommateur des produits d'API publiés. Une fois que vous avez configuré le portail de développeur pour votre catalogue et qu'un ou plusieurs produits d'API ont été publiés, les développeurs d'applications peuvent parcourir et utiliser les API.`,
  [`The document is missing draft_api.info.version field.`]: `La zone draft_api.info.version manque dans le document.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `La zone draft_api.info.x-ibm-name manque dans le document.`,
  [`The document is missing draft_product.info.name field.`]: `La zone draft_product.info.name manque dans le document.`,
  [`The document is missing draft_product.info.version field.`]: `La zone draft_product.info.version manque dans le document.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `Les contraintes supplémentaires spécifient un argument de tranche qui n'est pas un argument dans cette zone.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Cette zone inclut une limite d'argument avec une valeur par défaut. C'est pourquoi la taille supposée indiquée ne peut jamais s'appliquer.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Cette zone inclut une limite d'argument avec une valeur par défaut ou est requise. C'est pourquoi la taille supposée indiquée ne peut jamais s'appliquer.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Cette zone n'a pas de taille supposée ni d'arguments de tranche.`,
  [`This field returns unbound list of values with composite type.`]: `Cette zone renvoie la liste non liée des valeurs avec le type composé.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Cette zone renvoie la liste non liée des valeur avec le type scalaire et la pondération de type != 0.0.`,
  [`This test panel is deprecated and will be removed soon.`]: `Ce panneau de test est obsolète et sera bientôt retiré.`,
  [`This type has no fields or no matching field.`]: `Ce type n'a aucune zone ou aucune zone correspondante.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Cette règle GatewayScript ne doit pas utiliser le module apim, qui est uniquement destiné à la migration des anciennes API.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Cette règle XSLT ne doit pas utiliser le module apim, qui est uniquement destiné à la migration des anciennes API.`,
  [`This type will be deleted.`]: `Ce type sera supprimé.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `Le diagramme ci-après représente le flux d'assemblage pour votre API. Pour modifier l'assemblage, utilisez l'éditeur d'assemblage.`,
  [`The following files are available under the`]: `Les fichiers suivants sont disponibles sous le`,
  [`The following files contain a private key and cannot be uploaded:`]: `Les fichiers suivants contiennent une clé privée et ne peuvent pas être téléchargés :`,
  [`The following is available under the`]: `L'élément suivant est disponible sous le`,
  [`The following lifecycle actions require approval`]: `Les actions du cycle de vie suivantes requièrent une approbation`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `La liste suivante répertorie les API brouillons qui ont été créées dans cette organisation de type fournisseur`,
  [`The following list displays the draft products that have been created in this provider organization`]: `La liste suivante répertorie les produits brouillons qui ont été créés dans cette organisation de type fournisseur`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `La liste suivante répertorie tous les utilisateurs qui ont été ajoutés en tant que membres de catalogue, ainsi que les rôles qui leur sont affectés`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `La liste suivante répertorie tous les utilisateurs qui ont été ajoutés en tant que membres de cet espace, ainsi que les rôles qui leur sont affectés`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `Les paramètres suivants ne correspondent pas aux valeurs configurées dans le fournisseur OAuth`,
  [`The following roles are available in the Cloud Manager`]: `Les rôles suivants sont disponibles dans le gestionnaire de cloud`,
  [`The following types/fields will also be removed:`]: `Les types/zones suivants seront également retirés :`,
  [`The following types/fields will be affected:`]: `Les types/zones suivants seront affectés :`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `La valeur de propriété de passerelle {gatewayType} n'est pas autorisée. Les valeurs admises sont datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `Hôte utilisé pour appeler l'API`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `Le magasin de clés contient les paires de certificats publics et de clés privées utilisés pour confirmer l'identité et chiffrer/déchiffrer les données transmises via HTTPS.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `Le magasin de clés contient les paires de certificats publics et de clés privées utilisés pour confirmer l'identité et chiffrer/déchiffrer les données transmises via HTTPS. Le profil de serveur requiert un magasin de clés.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `Le magasin de clés contient les clés publiques et les certificats privés présentés par le serveur ou le client afin de confirmer son identité.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `Durée (en secondes), ajoutée à la date et à l'heure en cours, pendant laquelle la clé LTPA est considérée comme valide.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `Durée (en secondes), ajoutée à la date et à l'heure en cours, pendant laquelle le jeton Web JSON est considéré comme valide.`,
  [`The message to be sent with the error`]: `Message à envoyer avec l'erreur`,
  [`The message to be included within exception.`]: `Message à inclure dans l'exception.`,
  [`The HTTP status code to be included within exception.`]: `Code de statut HTTP à inclure dans l'exception.`,
  [`The HTTP status reason phrase to be included within exception.`]: `Phrase du motif de statut HTTP à inclure dans l'exception.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Nom de la limite de diffusion en rafale définie dans la configuration DataPower.`,
  [`The name of a count limit defined in the datapower configuration.`]: `Nom du nombre limite défini dans la configuration DataPower.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Nom de la limite de débit définie dans la configuration DataPower.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `Nom d'une variable qui sera utilisée pour stocker les données de réponse de la demande. Vous pouvez y faire référence dans d'autres actions, comme "Map".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `Nom d'une variable qui sera utilisée pour stocker le résultat. Par défaut, "message" est utilisé.`,
  [`The name of parameter to find the ID.`]: `Nom du paramètre pour rechercher l'ID.`,
  [`The name of parameter to find the secret.`]: `Nom du paramètre pour rechercher la valeur confidentielle.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `Nom de la clé LTPA à utiliser pour générer le jeton LTPA. Si vous souhaitez qu'une version spécifique de la clé soit utilisée, indiquez <keyname>:<version>. Par exemple, my-ltpa-key:1.0.1. Si vous souhaitez que la stratégie sélectionne automatiquement la dernière version de la clé à utiliser, indiquez <keyname>:latest. Par exemple, my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `Nom de l'erreur à émettre`,
  [`The name of the variable to be added.`]: `Nom de la variable à ajouter.`,
  [`The name of the variable to be cleared.`]: `Nom de la variable à effacer.`,
  [`The name of the variable to be set.`]: `Nom de la variable à définir`,
  [`The nature of the XML validation to be performed.`]: `Type de validation XML à effectuer.`,
  [`The operation to apply.`]: `Opération à appliquer`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `La sortie de ce mappage se trouve dans un tableau et son contenu est créé par une itération sur l'entrée choisie. Si cette dernière n'est pas un tableau, un tableau de longueur 1 est généré.`,
  [`The owner must be an existing user in your user registry`]: `Le propriétaire doit être un utilisateur qui existe dans votre registre d'utilisateurs`,
  [`The owner of consumer organization has the following privileges:`]: `Le propriétaire de l'organisation de type consommateur dispose des privilèges suivants :`,
  [`The owner of provider organization has the following privileges:`]: `Le propriétaire de l'organisation de type fournisseur dispose des privilèges suivants :`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Les tâches du propriétaire sont les suivantes : gérer le cycle de vie du produit d'API, gérer une ou plusieurs organisations de type fournisseur d'API, gérer des communautés de fournisseurs d'applications, et créer des API et des définitions de produit.`,
  [`The page you are looking for might have been moved or does not exist.`]: `La page que vous recherchez a peut-être été déplacée ou n'existe pas.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `La page que vous recherchez a peut-être été déplacée ou n'existe pas. Contactez votre administrateur pour de l'assistance.`,
  [`The parameters needed to send a valid API call.`]: `Paramètres requis pour envoyer un appel API valide.`,
  [`The password to use for HTTP Basic authentication.`]: `Mot de passe à utiliser pour l'authentification HTTP de base.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `Le service de portail fournit un portail de développeur utilisé par les développeurs d'applications pour découvrir des API et intégrer des consommateurs.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `Le service de portail fournit un portail de développeur utilisé par les développeurs d'applications pour découvrir et implémenter des API.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `Le statut de traitement fournit une vue de la santé de la passerelle avec un récapitulatif de l'activité récente`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `Le destinataire s'enregistre en tant que propriétaire et crée une organisation de type fournisseur. Une fois enregistré, l'utilisateur peut se connecter à API Manager en tant que propriétaire d'organisation de type fournisseur.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `Nom de l'élément racine du document XML résultant. Obligatoire uniquement si le document JSON en entrée n'est pas hiérarchique et s'il comporte plusieurs propriétés de niveau supérieur.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Ici, les portées permettent aux développeurs de savoir à quels éléments ils peuvent accéder et quels éléments n'ont pas d'impact sur la vérification de portée.`,
  [`The selected catalog will only be used to search consumer organizations`]: `Le catalogue sélectionné ne sera utilisé que pour rechercher des organisations de type consommateur`,
  [`The selected catalog does not have any configured gateways`]: `Le catalogue sélectionné n'a pas de passerelles configurées`,
  [`The selected catalog does not have any compatible gateways`]: `Le catalogue sélectionné n'a pas de passerelles compatibles`,
  [`Save Preferences`]: `Sauvegarder les préférences`,
  [`Preferences`]: `Préférences`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `Le catalogue sélectionné ne sera utilisé que pour rechercher des organisations et des groupes de type consommateur`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `Le type de passerelle sélectionné affichera les stratégies suivantes dans votre assemblage comme non valides. Vous devrez supprimer ces stratégies pour exécuter cette API.`,
  [`The selected product does not contain this API`]: `Le produit sélectionné ne contient pas cette API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `Les groupes ou les organisations de type consommateur d'une liste spécifique pourront s'abonner au produit sélectionné.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `Les services sélectionnés seront disponibles pour une liste spécifique d'organisations de type fournisseur.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `Les services sélectionnés seront disponibles pour toutes les organisations de type fournisseur nouvelles et existantes.`,
  [`The selected services will not be available to any provider organizations.`]: `Les services sélectionnés ne seront pas disponibles pour les organisations de type fournisseur.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `Chaîne à utiliser comme clé de la mémoire cache. Si elle est omise, la totalité de la chaîne d'URL est utilisée comme clé.`,
  [`The server responded with a non-JSON body \n{body}`]: `Le serveur a répondu avec un corps non JSON \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `L'intervalle de temps doit être supérieur à la durée de vie du jeton d'accès.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Le jeton n'est pas stocké dans un cookie ou un emplacement de stockage local. Si vous fermez le panneau de test, il est perdu mais peut rester valide.`,
  [`The transfer protocol of the API.`]: `Le protocole de transfert de l'API.`,
  [`The type of authentication.`]: `Type d'authentification.`,
  [`The type of authorization header.`]: `Type d'en-tête d'autorisation.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `Type de la valeur à définir. Il peut s'agir d'une chaîne, d'un nombre ou d'un booléen.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `Type de la valeur à définir. Il peut s'agir d'une chaîne, d'un nombre ou d'un booléen.`,
  [`The username to use for HTTP Basic authentication.`]: `Nom d'utilisateur à utiliser pour l'authentification HTTP de base.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `L'utilisateur de cette connexion n'est plus valide. Créez une nouvelle connexion.`,
  [`The value that the variable will be set to.`]: `Valeur affectée à la variable`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `La valeur de cette zone est une liste de type composite, qui n'a pas de taille supposée ni d'arguments de tranche. Si elle est détectée, la passerelle d'API ne peut pas définir de limite pour le nombre d'objets que le serveur GraphQL peut renvoyer s'il reçoit la requête.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `La valeur de cette zone est une liste de type scalaire, qui n'a pas de taille supposée ni d'arguments de tranche. Si elle est détectée, la passerelle d'API ne peut pas définir de limite pour le nombre d'objets que le serveur GraphQL peut renvoyer s'il reçoit la requête.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `La version doit être sécurisée par une URL. La version de l'objet {version} et la version dans laquelle les caractères interdits ont été supprimés {slugVersion} ne correspondent pas.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `La propriété "name" doit avoir une URL sécurisée (la valeur de nom "{name}" de l'objet et la valeur de nom "{slugName}" dans laquelle les caractères interdits ont été supprimés doivent correspondre).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `La propriété 'draft_api' doit contenir une définition OpenAPI. OpenAPI version 3 prend en charge uniquement les versions 3.0.0 à 3.0.3 (version spécifiée {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `La zone de nom est automatiquement renseignée en fonction du titre que vous indiquez. Utilisez la valeur obtenue dans les commandes API Connect et les appels API.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `Le paramètre de visibilité détermine quelles organisations de type fournisseur peuvent accéder à une ressource. Les choix sont les suivants : Public (la ressource peut être utilisée par toutes les organisations de type fournisseur), Personnalisé (la ressource peut être utilisée uniquement par les organisations de type fournisseur que vous désignez) et Privé (la ressource n'est pas visible et ne peut être utilisée par aucune organisation de type fournisseur).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `Le paramètre de visibilité détermine quelles organisations de type fournisseur peuvent utiliser un service. Les choix sont les suivants : Public (le service peut être utilisé par toutes les organisations de type fournisseur), Personnalisé (le service peut être utilisé uniquement par les organisations de type fournisseur que vous désignez) et Privé (le service n'est pas visible et ne peut être utilisé par aucune organisation de type fournisseur).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `Le paramètre de visibilité détermine quelles organisations de type fournisseur peuvent utiliser une passerelle. Les niveaux de visibilité sont les suivants : Public (la passerelle peut être utilisée par toutes les organisations de type fournisseur), Personnalisé (la passerelle peut être utilisée uniquement par les organisations de type fournisseur que vous désignez) et Privé (la passerelle n'est pas visible et ne peut être utilisée par aucune organisation de type fournisseur).`,
  [`The {title} role`]: `Rôle {title}`,
  [`The {url} resource does not exist`]: `La ressource {url} n'existe pas`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Des API sont incompatibles car elles ont un type de passerelle différent de celui de ce produit.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Sélectionnez ensuite le certificat pour le domaine du noeud final de gestion. Sélectionnez également le bundle d'autorité de certification correspondant si le certificat n'a pas été affecté par une autorité de certification reconnue. Le certificat et le bundle doivent être gérés par le service de gestionnaire de certificat.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Indiquez ensuite les domaines qui seront traités par cette passerelle. La technique SNI est utilisée ; par conséquent, plusieurs domaines peuvent être servis via la même adresse IP et le même port sans nécessiter le même certificat. Le format des caractères génériques est pris en charge. Le domaine par défaut (catch-all) de ‘*’ doit être inclus en tant qu'entrée finale dans la table. Entrez d'autres noms de domaine si nécessaire, en spécifiant dans chaque cas un certificat géré par le service de gestionnaire de certificat.`,
  [`There should be at least {minSelected} {label} selected.`]: `Au moins {minSelected} {label} doivent être sélectionnés.`,
  [`There are no API changes to publish.`]: `Il n'existe pas de modification d'API à publier.`,
  [`There are no gateway services`]: `Aucun service de passerelle n'est disponible`,
  [`There are no assemblies that match the chosen filters.`]: `Aucun assemblage ne correspond aux filtres choisis.`,
  [`There are no burst limits yet.`]: `Il n'existe pas encore de limite de diffusion en rafale.`,
  [`There are no catalogs`]: `Aucun catalogue n'est disponible`,
  [`There are no consumer organizations.`]: `Aucune organisation de type consommateur n'est disponible.`,
  [`There are no consumer organizations. You can create one`]: `Aucune organisation de type consommateur n'est disponible. Vous pouvez en créer un.`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `Aucune organisation de type consommateur n'est disponible. Vous pouvez en créer un [ici]({link})`,
  [`There are no email servers`]: `Aucun serveur de messagerie n'est disponible`,
  [`There are no gateway services configured for the sandbox catalog`]: `Aucun service de passerelle n'est configuré pour le catalogue pour bac à sable`,
  [`There are no lifecycle approvals enabled`]: `Aucune approbation du cycle de vie n'est activée`,
  [`There are no new changes.`]: `Aucune nouvelle modification n'a été apportée.`,
  [`There are no products to display`]: `Aucun produit à afficher`,
  [`There are no products to replace`]: `Aucun produit à remplacer`,
  [`There are no products to set migration target`]: `Aucun produit pour définir la cible de migration`,
  [`There are no products to supersede`]: `Aucun produit à substituer`,
  [`There are no properties to configure for this policy`]: `Il n'existe pas de propriété à configurer pour cette stratégie`,
  [`There are no rate limits yet.`]: `Il n'existe pas encore de limite de débit.`,
  [`There are no security definitions`]: `Aucune définition de sécurité n'est disponible`,
  [`There are no spaces`]: `Aucun espace n'est disponible`,
  [`There are no target services`]: `Aucun service cible n'est disponible`,
  [`There are no tasks to be displayed`]: `Aucune tâche à afficher.`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Des modifications n'ont pas été sauvegardées. Voulez-vous vraiment continuer ?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Des modifications n'ont pas été sauvegardées. Elles seront supprimées si vous continuez. Voulez-vous continuer ?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `Une API dans votre fichier YAML n'existe pas et ne peut pas être répertoriée. Vérifiez l'onglet source.`,
  [`There is no item in the list.`]: `La liste ne contient pas d'éléments.`,
  [`There is no space`]: `Il n'existe pas d'espace`,
  [`There is no warning in the schema.`]: `Il n'y a pas d'avertissement dans le schéma.`,
  [`There was no data found to populate the data table.`]: `Aucune donnée n'a été trouvée pour remplir la table de données.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Ces noeuds finaux sont utilisés pour toutes les API du catalogue`,
  [`Third party OAuth provider`]: `Fournisseur OAuth tiers`,
  [`Third party OAuth provider summary`]: `Récapitulatif du fournisseur OAuth tiers`,
  [`Third party`]: `Tiers`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Cette API ne contient pas encore d'assemblage. Voulez-vous en créer un ?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Cette ressource n'existe plus. Veuillez actualiser les résultats du serveur.`,
  [`Please refresh the results from the server`]: `Actualiser les résultats du serveur`,
  [`Exact match`]: `Correspondance exacte`,
  [`Closest match`]: `Correspondance la plus proche`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Cette API est actuellement utilisée par un produit {productName}. Retirez-la du produit, puis supprimez l'API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Cette API pourra être appelée une fois l'option suivante activée.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `L'abonnement automatique n'est pas activé pour ce catalogue. Pour tester ce catalogue, sélectionnez un produit, un plan et une application ci-dessous.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Ce noeud final est utilisé comme valeur par défaut pour les API qui ne définissent pas de noeud final de base`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Il s'agit d'une fonction de prévisualisation technique qui évoluera et continuera à être améliorée dans le futur.`,
  [`This is the currently configured notification server.`]: `Il s'agit du serveur de notification actuellement configuré.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Il s'agit du répertoire où seront stockés vos fichiers, y compris les stratégies et les extensions`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `Il s'agit de l'emplacement où les API s'exécuteront et où les stratégies et les extensions sont accessibles`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Cette stratégie OAuth effectue toutes les étapes du protocole OAuth nécessaires pour le chemin émis par défaut. Les entrées et sorties de chaque étape reposent sur des variables de contexte documentées. Ajoutez ou supprimez les composants OAuth pris en charge selon les besoins.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Cette stratégie OAuth effectue toutes les étapes du protocole OAuth/OpenID Connect nécessaires pour la validation OAuth par défaut. Les entrées et sorties de chaque étape reposent sur des variables de contexte documentées. Ajoutez ou supprimez les composants OAuth pris en charge selon les besoins.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Cette stratégie OAuth effectue toutes les étapes du protocole OAuth/OpenID Connect nécessaires pour tous les autres chemins par défaut. Les entrées et sorties de chaque étape reposent sur des variables de contexte documentées. Ajoutez ou supprimez les composants OAuth pris en charge selon les besoins.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Cette stratégie OAuth effectue toutes les étapes du protocole OAuth/OpenID Connect nécessaires pour le chemin de code az par défaut. Les entrées et sorties de chaque étape reposent sur des variables de contexte documentées. Ajoutez ou supprimez les composants OAuth pris en charge selon les besoins.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Cette stratégie OAuth effectue toutes les étapes du protocole OAuth/OpenID Connect nécessaires pour le chemin de jeton par défaut. Les entrées et sorties de chaque étape reposent sur des variables de contexte documentées. Ajoutez ou supprimez les composants OAuth pris en charge selon les besoins.`,
  [`This operation is secured with access code flow OAuth`]: `Cette opération est sécurisée avec le protocole d'autorisation OAuth de flux de code d'accès`,
  [`This operation is secured with application flow OAuth`]: `Cette opération est sécurisée avec le protocole d'autorisation OAuth de flux d'application`,
  [`This operation is secured with basic authentication`]: `Cette opération est sécurisée avec l'authentification de base`,
  [`This operation is secured with implicit flow OAuth`]: `Cette opération est sécurisée avec le protocole d'autorisation OAuth de flux implicite`,
  [`This operation is secured with password flow OAuth`]: `Cette opération est sécurisée avec le protocole d'autorisation OAuth de flux de mot de passe`,
  [`This policy is not available on the selected gateway`]: `Cette stratégie n'est pas disponible sur la passerelle sélectionnée`,
  [`This policy is used by one other operation`]: `Cette stratégie est utilisée par une autre opération`,
  [`This policy is used by {{count}} other operations`]: `Cette politique est utilisée par {{count}} autres opérations`,
  [`This role cannot be edited`]: `Ce rôle ne peut pas être édité`,
  [`This role cannot be edited.`]: `Ce rôle ne peut pas être édité.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Ce paramètre contrôle le comportement en cas d'échec d'un mappage en raison de l'absence de son entrée et du fait qu'aucun mappage par défaut n'a été configuré. Le comportement par défaut consiste à ne pas modifier la sortie, mais si vous sélectionnez cette case, un objet vide est créé pour le parent du mappage cible, ce qui émule le comportement de la version 4.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Cette stratégie de sécurité utilisateur effectue des contrôles EI(de base) et AU(URL auto) de l'assemblage OAuth. Changez de méthode de contrôle de sécurité si besoin.`,
  [`This will enable/disable application lifecycle`]: `Cette opération active/désactive le cycle de vie de l'application`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Cette opération va activer/désactiver l'auto-approbation des tâches. L'auto-approbation des tâches permet l'approbation des tâches par leur émetteur ainsi que par les collaborateurs. Cette option peut être pratique lorsque d'autres valideurs ne sont pas disponibles, mais permet en réalité d'ignorer les vérifications effectuées par un autre utilisateur.`,
  [`This will unset the catalog as the default catalog.`]: `Le catalogue ne sera plus défini comme catalogue par défaut.`,
  [`Throw`]: `Emission`,
  [`Time`]: `Date/Heure`,
  [`Time Interval`]: `Intervalle de temps`,
  [`Time Limit`]: `Limite de temps`,
  [`Time to Live`]: `Durée de vie`,
  [`Time to live`]: `Durée de vie`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Temps d'attente avant l'obtention d'une réponse du noeud final. La valeur par défaut est 60 secondes.`,
  [`Timeout`]: `Délai d'attente`,
  [`To`]: `A`,
  [`to`]: `à`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Pour ajouter un utilisateur comme membre de l'organisation d'administration, sélectionnez son registre d'utilisateurs, saisissez son nom d'utilisateur et sélectionnez les rôles que vous souhaitez lui affecter. Si le type du registre d'utilisateurs est Registre d'utilisateurs local, vous pouvez également créer un utilisateur, qui est ensuite ajouté en tant que membre. L'appartenance est activée immédiatement et l'utilisateur spécifié peut se connecter à l'interface utilisateur du gestionnaire de cloud`,
  [`To add a user as a member of a provider organization`]: `Pour ajouter un utilisateur comme membre d'une organisation de type fournisseur`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Pour ajouter un utilisateur comme membre du catalogue, sélectionnez son nom d'utilisateur et affectez-lui les rôles requis. Vous pouvez ajouter tout utilisateur qui est déjà membre d'un autre catalogue ou d'un espace, dans l'organisation de type fournisseur, et qui n'est actuellement pas membre de ce catalogue ni propriétaire du catalogue.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Pour ajouter un utilisateur comme membre de l'espace, sélectionnez son nom d'utilisateur et affectez-lui les rôles requis. Vous pouvez ajouter tout utilisateur qui est déjà membre d'un autre espace ou d'un catalogue, dans l'organisation de type fournisseur, et qui n'est actuellement pas membre de cet espace ni propriétaire de l'espace.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Pour ajouter ou supprimer des membres, utilisez le service [identité et accès](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Pour ajouter des membres, utilisez le service [identité et accès](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Pour ajouter un utilisateur comme membre de l'organisation, sélectionnez son registre d'utilisateurs, saisissez son nom d'utilisateur et affectez-lui un ensemble de rôles.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Pour ajouter un utilisateur comme membre, sélectionnez son nom d'utilisateur et affectez-lui un ensemble de rôles.`,
  [`To assign default gateways, click **Edit**.`]: `Pour affecter des passerelles par défaut, cliquez sur **Editer**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Pour pouvoir continuer à utiliser ce logiciel, vous devez accepter les dispositions du contrat de licence du logiciel.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Pour continuer à utiliser API Connect, effectuez une mise à niveau vers un plan Enterprise.`,
  [`To get started, you must first enable spaces.`]: `Pour démarrer, vous devez d'abord activer les espaces.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Pour installer et exécuter le kit d'outils, procédez comme suit. [En savoir plus]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Pour inviter un nouvel utilisateur en tant que membre de l'organisation d'administration, entrez l'adresse électronique ou le nom de l'utilisateur, ou sélectionnez son nom dans un registre d'utilisateurs.  Tous les membre bénéficient automatiquement au rôle Membre, qui fournit un ensemble basique de droits et qui ne peut pas être supprimé. Si aucun autre droit n'est requis, laissez les autres rôles désélectionnés.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Pour inviter un propriétaire qui ne figure pas encore dans votre registre d'utilisateurs, entrez son adresse électronique`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Pour enregistrer une nouvelle passerelle, visitez la page **Passerelles**.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Pour remplacer des valeurs par l'une des propriétés d'espace suivantes, tapez $() avec le nom de la propriété entre parenthèses`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Pour envoyer une invitation par courrier électronique à un nouvel utilisateur afin de l'enregistrer en tant que membre de l'organisation d'administration, saisissez une adresse électronique valide et sélectionnez les rôles que vous voulez affecter à l'utilisateur. Un courrier électronique contenant un lien d'activation est envoyé à l'adresse électronique pour inviter la personne à s'enregistrer. L'utilisateur est placé à l'état En attente et passe à l'état Activé une fois qu'il a rempli le formulaire d'enregistrement pour activer son compte. Il peut ensuite se connecter à l'interface utilisateur du gestionnaire de cloud à l'aide du nom d'utilisateur API Connect spécifié lors de l'activation du compte.`,
  [`Toggle`]: `Basculer`,
  [`Toggle application lifecycle?`]: `Afficher/Masquer le cycle de vie de l'application`,
  [`Toggle default catalog?`]: `Activer/désactiver le catalogue par défaut ?`,
  [`Toggle info panel between right and bottom`]: `Basculer le panneau d'information entre la droite et le bas`,
  [`Toggle profile`]: `Activer/désactiver le profil`,
  [`Toggle spaces?`]: `Activer/désactiver les espaces ?`,
  [`Toggle task self-approval?`]: `Activer/désactiver l'auto-approbation de tâche ?`,
  [`Token`]: `Jeton`,
  [`Token endpoint`]: `Noeud final de jeton`,
  [`Token Expiry`]: `Délai d'expiration du jeton`,
  [`Token Management`]: `Gestion des jetons`,
  [`Token Output`]: `Sortie du jeton`,
  [`Token URL`]: `URL de jeton`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `L'URL du jeton doit correspondre à {endpoint} comme défini par le fournisseur OAuth "{providerTitle}"`,
  [`Token Version`]: `Version de jeton`,
  [`Token management`]: `Gestion des jetons`,
  [`Token management type`]: `Type de gestion des jetons`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `La gestion des jetons permet d'empêcher les attaques par réinsertion et de déterminer notamment si et comment les applications client ou les propriétaires de ressources révoquent les jetons OAuth. API Connect prend en charge la gestion des jetons avec une passerelle native ou un noeud final tiers. En cas d'utilisation d'une passerelle native, des quotas sont appliqués pour gérer les jetons. En cas d'utilisation d'un noeud final tiers, vous devez fournir une URL à un service externe pour gérer les jetons.`,
  [`Token path`]: `Chemin de jeton`,
  [`Token revocation`]: `Révocation de jeton`,
  [`Token secret`]: `Valeur confidentielle du jeton`,
  [`Token validation`]: `Validation de jeton`,
  [`Tokens`]: `Jetons`,
  [`Token relay`]: `Relais de jeton`,
  [`Toolkit credentials`]: `Données d'identification du kit d'outils`,
  [`Token Relay`]: `Relais de jeton`,
  [`Topology`]: `Topologie`,
  [`Topology Administrator`]: `Administrateur de topologie`,
  [`Total time`]: `Temps total`,
  [`Transfer Ownership to a New User`]: `Transfert de la propriété à un nouvel utilisateur`,
  [`Transfer Ownership to an Existing User`]: `Transfert de la propriété à un utilisateur existant`,
  [`Transfer ownership`]: `Transférer la propriété`,
  [`Transfer ownership to`]: `Transférer la propriété à`,
  [`Transfer ownership to a new user`]: `Transférer la propriété à un nouvel utilisateur`,
  [`Transfer ownership to an existing user`]: `Transférer la propriété à un utilisateur existant`,
  [`Transforms`]: `Transformations`,
  [`Truststore Certificates`]: `Certificats de magasin de clés de confiance`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Les certificats de magasin de clés de confiance sont des certificats publics émis par une autorité de certification.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Les magasins de clés de confiance contiennent les certificats sécurisés comportant des clés publiques vérifiées. Les certificats qui se trouvent dans le magasin de clés de confiance proviennent généralement d'une autorité de certification tierce.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Les magasins de clés de confiance contiennent des certificats sécurisés avec des clés publiques vérifiées. Les certificats qui se trouvent dans le magasin de clés de confiance proviennent généralement d'une autorité de certification tierce.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Les magasins de clés de confiance stockent les certificats de confiance, notamment les clés publiques vérifiées. Les certificats qui se trouvent dans le magasin de clés de confiance proviennent généralement d'une autorité de certification tierce.`,
  [`Try again or upload a schema`]: `Réessayez ou téléchargez un schéma`,
  [`Try refreshing the page or contacting support.`]: `Essayez d'actualiser la page ou de contacter le support.`,
  [`Turkish`]: `Turc`,
  [`Tutorials`]: `Tutoriels`,
  [`Types`]: `Types`,
  [`Type of user`]: `Type d'utilisateur`,
  [`Type or select a catalog`]: `Entrez ou sélectionnez un catalogue`,
  [`Type to add organizations`]: `Entrez du texte pour ajouter des organisations`,
  [`TypeError: Failed to fetch`]: `TypeError : Echec de l'extraction`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `URL du noeud final de gestion`,
  [`URLs for all operations in the API begin with this value.`]: `Les URL pour toutes les opérations de l'API commencent par cette valeur.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `Défini par l'utilisateur`,
  [`Userinfo POST`]: `POST Userinfo`,
  [`USER MANAGED`]: `GERE PAR L'UTILISATEUR`,
  [`USERNAME`]: `NOM D'UTILISATEUR`,
  [`User can delete the keystore history.`]: `L'utilisateur peut supprimer l'historique de magasin de clés.`,
  [`Unable to parse API definition: {msg}`]: `Impossible d'analyser la définition d'API : {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Impossible de poursuivre en raison d'erreurs détectées lors de la suppression des types et/ou zones.`,
  [`Unarchive`]: `Désarchiver`,
  [`Unassociate`]: `Dissocier`,
  [`Unassociate analytics service`]: `Dissocier le service d'analyse`,
  [`Unauthorized`]: `Non autorisé`,
  [`Unauthorized API Request`]: `Demande d'API non autorisée`,
  [`Unbound lists`]: `Listes non liées`,
  [`Unit`]: `Unité`,
  [`Union`]: `Union`,
  [`Union type`]: `Type d'union`,
  [`Unique email address`]: `Adresse électronique unique`,
  [`Unlimited`]: `Illimité`,
  [`Unnecessary assumed size`]: `Taille supposée inutile`,
  [`Unnecessary slicing arguments`]: `Arguments de tranche inutiles`,
  [`Unrecognized Schema Element(s) Encountered`]: `Elément(s) de schéma non reconnu(s) rencontré(s)`,
  [`Unsupported media type ({type})`]: `Type de support non pris en charge ({type})`,
  [`Untitled`]: `Sans titre`,
  [`Update`]: `Mettre à jour`,
  [`Update gateway services`]: `Mettre à jour les services de passerelle`,
  [`Update assembly`]: `Mettre à jour l'assemblage`,
  [`Update group failed.`]: `Echec de la mise à jour de groupe`,
  [`Update schema defaults giving preference to`]: `Mettre à jour les valeurs par défaut du schéma en donnant la préférence à`,
  [`Update the sender details that are displayed on invitation emails`]: `Mettez à jour les détails de l'expéditeur qui sont affichés dans les courriers électroniques d'invitation`,
  [`Update the summary details for this role`]: `Mettez à jour les détails récapitulatifs pour ce rôle`,
  [`Updating Draft API`]: `Mise à jour d'une API brouillon`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `La mise à jour des valeurs par défaut du schéma remplacera vos modifications. Voulez-vous vraiment continuer ?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `La mise à jour de l'API de type {existingType} avec l'API de type {type} n'est pas autorisée.`,
  [`Upload`]: `Télécharger`,
  [`Upload a schema definition language file to replace your schema.`]: `Téléchargez un fichier de langage de définition de schéma (SDL) pour remplacer votre schéma.`,
  [`Upload GraphQL schema`]: `Télécharger un schéma GraphQL`,
  [`Upload local`]: `Télécharger en local`,
  [`Upload policies`]: `Télécharger des stratégies`,
  [`Upload policy`]: `Télécharger une stratégie`,
  [`Upload schema`]: `Télécharger un schéma`,
  [`Upload schema definition language (SDL) file`]: `Télécharger le fichier de langue de définition de schéma (SDL)`,
  [`Upload truststore certificates`]: `Télécharger les certificats de magasin de clés de confiance`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Téléchargez le certificat X509 pour votre application, au format PEM. Vous ne pouvez télécharger qu'un fichier PEM, mais vous pouvez inclure plusieurs certificats dans un seul fichier.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Téléchargez le certificat X509 pour votre application, au format PEM`,
  [`Upload your public certificate`]: `Télécharger votre certificat public`,
  [`Uploaded and validated YAML file`]: `Fichier YAML téléchargé et validé`,
  [`Upload extension`]: `Télécharger une extension`,
  [`Use all compatible gateway services`]: `Utiliser tous les services de passerelle compatibles`,
  [`Use Content Type`]: `Utiliser le type de contenu`,
  [`Use DataPower API Gateway`]: `Utiliser DataPower API Gateway`,
  [`Use DataPower Gateway`]: `Utiliser DataPower Gateway`,
  [`Use Dynamic DNS`]: `Utiliser un DDNS (Dynamic Domain Name System)`,
  [`Use JSONata to specify the property to redact or remove`]: `Utiliser JSONata pour indiquer la propriété à occulter ou à retirer`,
  [`Use XPath to specify the property to redact or remove.`]: `Utilisez XPath pour indiquer la propriété à occulter ou retirer.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Utilisez un catalogue sélectionné comme catalogue par défaut dans lequel procéder à la publication`,
  [`Use an existing JSON or YAML definition of the product`]: `Utilisez une définition JSON ou YAML existante du produit`,
  [`Use an existing definition of a REST proxy`]: `Utilisez une définition existante d'un proxy REST`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Utilisez une définition existante d'un proxy REST ou d'une API SOAP`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Utilisez une définition existante d'un proxy REST, d'un proxy GraphQL ou de l'API SOAP`,
  [`Use an existing definition of a product`]: `Utilisez une définition existante de produit`,
  [`Use context current payload`]: `Utiliser le contenu en cours du contexte`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Utiliser l'URL de noeud final de catalogue personnalisée (compatibilité avec la version 5)`,
  [`Use dynamic DNS`]: `Utiliser des adresses DNS dynamiques`,
  [`Use endpoint from API`]: `Utiliser le noeud final de l'API`,
  [`Use gateway URLs`]: `Utiliser les URL de passerelle`,
  [`Use gateway service configured OAuth shared secret key`]: `Utiliser la clé secrète partagée OAuth configurée du service de passerelle`,
  [`Use group authentication`]: `Utiliser l'authentification de groupe`,
  [`Use only first array element`]: `Utiliser uniquement le premier élément de tableau`,
  [`Use static DNS`]: `Utiliser des adresses DNS statiques`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Utiliser le portail comme noeud final pour le trafic de fournisseur OIDC externe`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Utilisez API Explorer pour tester et explorer vos API. Explorer affiche les opérations, les définitions et la documentation de toutes les API contenues dans votre répertoire de projet. Explorer répertorie les paramètres, données d'instance de modèle et codes de réponse avec un modèle de code pour exécuter vos API.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Utilisez le menu d'actions pour gérer et publier vos API.`,
  [`Use the actions menu to manage and publish your products`]: `Utilisez le menu d'actions pour gérer et publier vos produits`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Utilisez la stratégie activity-log pour configurer vos préférences de journalisation pour l'activité d'API stockée dans l'analyse. Les préférences que vous définissez remplacent les paramètres par défaut de collecte et de stockage des détails de l'activité de l'API.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Utilisez la stratégie de sécurité du client pour extraire et authentifier les données d'identification des clients.`,
  [`Use the default built-in Sandbox Catalog`]: `Utiliser le catalogue de bac à sable intégré par défaut`,
  [`Use the editor for manual input or upload a file below.`]: `Utilisez l'éditeur pour la saisie manuelle ou téléchargez un fichier ci-dessous.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Utilisez l'éditeur pour la saisie manuelle ou téléchargez un fichier ci-dessous. Le schéma en cours ne peut être modifié que dans XML si un fichier XML/XSD est défini.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Utilisez l'extension appliquée pour indiquer si la passerelle API Connect est utilisée pour appliquer l'API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Utilisez l'URL de noeud final de gestion suivante lorsque vous utilisez l'interface de ligne de commande.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Utilisez l'URL de noeud final de gestion suivante lorsque vous créez une connexion au cloud dans API Designer.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Utilisez la stratégie gatewayscript pour exécuter un programme GatewayScript DataPower donné.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Utilisez la stratégie graphql-introspect pour procéder à l'introspection d'un schéma GraphQL.`,
  [`Use the host value from Open API Definition`]: `Utilisez la valeur hôte de la définition OpenAPI`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Utilisez la stratégie if pour appliquer une section de l'assemblage lorsqu'une condition est remplie.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Utilisez la stratégie javascript pour exécuter un programme JavaScript.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Utilisez la stratégie json-to-xml pour convertir le contenu de JSON en XML.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Utilisez la stratégie de journal pour personnaliser ou remplacer la configuration de journalisation d'activité par défaut d'une API.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Utilisez la stratégie de mappe pour appliquer des transformations à votre flux d'assemblage et spécifier des relations entre les variables.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Utilisez la stratégie map pour indiquer les relations entre les variables dans votre flux d'API et pour appliquer des transformations à ces variables.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Utilisez la stratégie OAuth pour générer des jetons OAuth.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `La stratégie de permutation d'opération permet d'appliquer une section de l'assemblage à une opération spécifique.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Utilisez la stratégie d'analyse pour analyser une demande ou un message au format XML ou JSON ou pour analyser des données binaires dans un objet binaire de grande taille (BLOB).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Utilisez la stratégie set-variable pour définir une variable d'exécution pour une valeur de chaîne, ou pour ajouter ou supprimer une variable d'exécution.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Utilisez la stratégie throw pour générer une erreur lorsqu'elle est atteinte au cours de l'exécution d'un flux d'assemblage.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Utilisez la stratégie de sécurité utilisateur pour extraire, authentifier et autoriser l'identité de l'utilisateur.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Utilisez la stratégie de validation avec DataPower Gateway pour valider le contenu dans un flux d'assemblage par rapport à un schéma JSON ou XML.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Utilisez la stratégie xml-to-json pour convertir le contenu de XML en JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Utilisez la stratégie xslt pour appliquer une transformation XSLT au contenu.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Option utilisée pour activer/désactiver la compression Content-Encoding à l'importation.`,
  [`User`]: `Utilisateur`,
  [`User group prefix`]: `Préfixe du groupe d'utilisateurs`,
  [`User group suffix`]: `Suffixe du groupe d'utilisateurs`,
  [`User mapping`]: `Mappage d'utilisateur`,
  [`User Registries`]: `Registres d'utilisateurs`,
  [`User registries`]: `Registres d'utilisateurs`,
  [`User Registry Name`]: `Nom du registre d'utilisateurs`,
  [`User registry name`]: `Nom du registre d'utilisateurs`,
  [`User Registry cannot be deleted`]: `Le registre d'utilisateurs ne peut pas être supprimé`,
  [`User Registry "{name}" has been deleted`]: `Le registre d'utilisateurs "{name}" a été supprimé`,
  [`User security`]: `Sécurité utilisateur`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Les registres d'utilisateurs sont gérés dans chaque espace lorsque les espaces sont activés. Accédez aux paramètres d'espace pour gérer les registres d'utilisateurs.`,
  [`User registries defined for consumer onboarding`]: `Registres d'utilisateurs définis pour l'intégration de consommateurs`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Registres d'utilisateurs définis pour l'intégration des consommateurs\n\nChaque compte de Developer Portal, même dans différents registres d'utilisateurs du même site, doit posséder une adresse e-mail unique, y compris le compte Admin du site.`,
  [`User registry`]: `Registre d'utilisateurs`,
  [`User registry configuration has been updated.`]: `La configuration du registre d'utilisateurs a été mise à jour.`,
  [`User registry has been created`]: `Le registre d'utilisateurs a été créé`,
  [`User registry {title} deleted.`]: `Le registre d'utilisateurs {title} a été supprimé.`,
  [`User registry {title} has been created.`]: `Le registre d'utilisateurs {title} a été créé.`,
  [`User registry is not visible or not available.`]: `Le registre d'utilisateurs n'est pas visible ou n'est pas disponible.`,
  [`User registry {title} has been updated.`]: `Le registre d'utilisateurs {title} a été mis à jour.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `Les paramètres de sécurité utilisateur s'appliquent uniquement si vous disposez d'un ou de plusieurs types de droit parmi les suivants : Implicite, Code d'accès et Propriétaire de la ressource - Mot de passe.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `Les paramètres de sécurité utilisateur s'appliquent uniquement si vous disposez d'un ou de plusieurs types de droit parmi les suivants : Implicite, Code d'accès et Propriétaire de la ressource - Mot de passe. Si vous effectuez des modifications sur cette page, vous devez également sélectionner, sur la page Configuration, au moins un de ces types de droit.`,
  [`UserInfo endpoint`]: `Noeud final UserInfo`,
  [`UserInfo URL`]: `URL UserInfo`,
  [`Username`]: `Nom d'utilisateur`,
  [`Username / Email`]: `Nom d'utilisateur/Adresse électronique`,
  [`Username and password fields are required in order to test configuration`]: `Les zones de nom d'utilisateur et de mot de passe sont obligatoires pour tester la configuration`,
  [`Username context variable`]: `Variable contextuelle du nom d'utilisateur`,
  [`Username of the new user`]: `Nom du nouvel utilisateur`,
  [`Username should not contain special characters!`]: `Le nom d'utilisateur ne doit pas contenir de caractères spéciaux`,
  [`Username should not include spaces!`]: `Le nom d'utilisateur ne doit pas inclure d'espaces !`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Les utilisateurs qui appartiennent à une organisation de type consommateur ont accès au portail de développeur et à ses produits d'API publiés, comme déterminé par leur visibilité et leur possibilité d'abonnement.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `A l'aide d'[IBM Cloud IAM]({link}), autorisez le service API Connect Reserved Instance à accéder au service de gestionnaire de certificat.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Utilisation de la méthode HTTP POST lors du contact du noeud final Userinfo`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Utiliser le paramètre d'expiration de jeton IBM APIC à partir du cloud`,
  [`Update API`]: `Mise à jour de l'API`,
  [`VALUE`]: `VALEUR`,
  [`Version`]: `Version`,
  [`Visible to`]: `Visible pour`,
  [`Validate`]: `Valider`,
  [`Validate message body`]: `Valider le corps du message`,
  [`Validate message headers`]: `Valider les en-têtes de message`,
  [`Validate message fault details`]: `Valider les détails de l'erreur de message`,
  [`Validate SOAP 1.1 encoding rule`]: `Valider la règle de codage SOAP 1.1`,
  [`Validate JSON Web Token (JWT)`]: `Validez le jeton Web JSON (JWT)`,
  [`Validate JWT`]: `Valider JWT`,
  [`Validate Username Token`]: `Valider le jeton de nom d'utilisateur`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Valider le contenu d'un jeton de nom d'utilisateur de sécurité de services Web par rapport à une URL d'authentification ou un registre d'utilisateurs LDAP.`,
  [`Validate against`]: `Mode de validation`,
  [`Validate request`]: `Valider la demande`,
  [`Validate the GraphQL query and analyze the expected cost`]: `Valider la requête GraphQL et analyser le coût attendu`,
  [`Validate the returned JSON and analyze the real cost`]: `Valider l'élément JSON et analyser le coût réel`,
  [`Validating API ...`]: `Validation de l'API...`,
  [`Validating Draft API`]: `Validation d'une API brouillon`,
  [`Validity Period`]: `Période de validité`,
  [`Value`]: `Valeur`,
  [`Value for located in must be selected.`]: `La valeur de localisation doit être sélectionnée.`,
  [`Value for the variable to be added.`]: `Valeur de la variable à ajouter.`,
  [`Value for type must be selected.`]: `La valeur du type doit être sélectionnée.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `La valeur de l'argument "weight" dans l'instruction "@cost" est < 0.`,
  [`Values`]: `valeurs`,
  [`Vanity API endpoint`]: `Noeud final d'API personnalisé`,
  [`Variable name`]: `Nom de la variable`,
  [`Variable value`]: `Valeur de la variable`,
  [`Vendor Extensions`]: `Extension de fournisseur`,
  [`Verb`]: `Instruction`,
  [`Verify`]: `Vérifier`,
  [`Verify Access Token`]: `Vérifier le jeton d'accès`,
  [`Verify Crypto JWK variable name`]: `Vérifier le nom de variable JWK crypto`,
  [`Verify Crypto Object`]: `Vérifier l'objet crypto`,
  [`Verify Token`]: `Vérifier le jeton`,
  [`Verify authorization code`]: `Vérifier le code d'autorisation`,
  [`Verify refresh token`]: `Vérifier le jeton d'actualisation`,
  [`Version of the LTPA token.`]: `Version du jeton LTPA.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Vérifiez la propriété 'apis' sous 'plans' dans le fichier produit importé. Chaque plan doit posséder une propriété 'apis' pointant vers au moins une API de la section 'apis'.`,
  [`View`]: `Afficher`,
  [`View extensions`]: `Afficher les extensions`,
  [`View Forum`]: `Accéder au forum`,
  [`View owners`]: `Afficher les propriétaires`,
  [`View policies`]: `Afficher les stratégies`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Affichez et gérez les chiffrements disponibles pour la version de protocole sélectionnée.`,
  [`View analytics`]: `Afficher l'analyse`,
  [`View and edit permissions for the roles in this space`]: `Affichez et éditez les droits pour les rôles dans cet espace`,
  [`View and edit the permissions for the roles in the current organization`]: `Affichez et éditez les droits pour les rôles dans l'organisation actuelle`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Affichez et éditez les rôles contenus dans le rôle par défaut pour les organisations de type consommateur`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Affichez et éditez les rôles contenus dans le rôle par défaut pour les organisations de type fournisseur`,
  [`View approval history`]: `Afficher l'historique des approbations`,
  [`View Approval History`]: `Afficher l'historique des approbations`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Affichez la documentation et les tutoriels avec des instructions détaillées.`,
  [`View error ID`]: `Afficher l'ID erreur`,
  [`View status`]: `Afficher le statut`,
  [`View more`]: `Afficher plus`,
  [`View source`]: `Afficher la source`,
  [`View subscription`]: `Afficher l'abonnement`,
  [`View template in`]: `Afficher le modèle dans`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Affichez les noeuds finaux permettant d'accéder aux interfaces utilisateur d'API Connect.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Affichez les noeuds finaux pour les API REST d'administration et de fournisseur.`,
  [`View the endpoints for the consumer REST APIs.`]: `Affichez les noeuds finaux pour les API REST de consommateur.`,
  [`Viewer`]: `Visualiseur`,
  [`Views the API consumer organization`]: `Affiche l'organisation de type consommateur d'API`,
  [`Views the API provider organization`]: `Affiche l'organisation de type fournisseur d'API`,
  [`Views the admin organization`]: `Affiche l'organisation d'administration`,
  [`Views the app developer organization`]: `Affiche l'organisation de développeurs d'applications`,
  [`Views the catalog`]: `Affiche le catalogue`,
  [`Views the space`]: `Affiche l'espace`,
  [`View|permission`]: `Afficher`,
  [`Visibility`]: `Visibilité`,
  [`Visibility options`]: `Options de visibilité`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `Documentation VMware`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `URL WSDL`,
  [`WSDL has been successfully validated`]: `Le service WSDL a été validé`,
  [`WSDL to REST`]: `WSDL en REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `Validation du profil de base WS-I`,
  [`Warn`]: `Avertir`,
  [`Warning`]: `Avertissement`,
  [`Warning:`]: `Avertissement :`,
  [`Warnings`]: `Avertissements`,
  [`We just emailed recipients a test message:`]: `Nous venons d'envoyer aux destinataire de courrier électronique un message test :`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `Nous l'avons placé dans l'onglet "Test", où vous pourrez tester et déboguer votre API.`,
  [`Weather Product`]: `Produit Météo`,
  [`Web Endpoint`]: `Noeud final Web`,
  [`Web service operations`]: `Opérations du service Web`,
  [`weight`]: `Poids`,
  [`Weight`]: `Poids`,
  [`Welcome to API Connect Administration`]: `Bienvenue dans API Connect Administration`,
  [`Welcome to API Connect Reserved`]: `Bienvenue dans API Connect Reserved`,
  [`Welcome to API Designer`]: `Bienvenue dans API Designer`,
  [`Welcome to API Manager`]: `Bienvenue dans API Manager`,
  [`Welcome to Cloud Manager`]: `Bienvenue dans Cloud Manager`,
  [`Welcome to the API Designer`]: `Bienvenue dans API Designer`,
  [`Welcome to the API Manager`]: `Bienvenue dans API Manager`,
  [`Welcome to the Cloud Manager`]: `Bienvenue dans le gestionnaire de cloud`,
  [`What are you looking for today?`]: `Que cherchez-vous aujourd'hui ?`,
  [`What's new`]: `Nouveautés`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `Etape suivante ? Activez votre intégration de facturation dans un catalogue pour permettre aux utilisateurs d'ajouter des plans à leurs produits.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `Si la valeur est définie sur vrai, la stratégie invoke injecte les en-têtes "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host" et "X-Forwarded-Proto" dans la requête envoyée à l'URL cible.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Une fois publié, un produit d'API devient visible dans le portail de développeur associé au catalogue. Les noeuds finaux pour les API dans le produit sont mis à disposition sur les passerelles associées à l'espace ou au catalogue ciblé. Les produits mis en préproduction sont présents dans le catalogue, mais ne sont pas opérationnels ni visibles des consommateurs.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Une fois publié, un produit d'API devient visible dans le portail de développeur associé au catalogue. Les noeuds finaux pour les API dans le produit sont mis à disposition sur les passerelles associées à l'espace ou au catalogue ciblé. Les produits mis en préproduction sont présents dans le catalogue, mais ne sont pas opérationnels ni visibles des consommateurs. Notez que la remise en préproduction/republication d'un produit dans un catalogue hors production supprimera les abonnements existants.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Lorsque des espaces sont activés pour un catalogue, le fournisseur OAuth configuré doit être affiché depuis un espace.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Lorsque les espaces sont activés, les produits existants du catalogue sont déplacés vers un espace par défaut.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Lorsque l'utilisateur clique sur le lien d'activation, il est invité à remplir le formulaire d'enregistrement. Une fois enregistré, il peut se connecter.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Lorsque vous ajoutez un rôle pour les organisations de type fournisseur ou consommateur, celui-ci est inclus dans le rôle par défaut. Les rôles par défaut sont des modèles de rôle préconfigurés utilisés pour déterminer les rôles par défaut qui sont disponibles lorsqu'une organisation est créée.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Lorsque vous dépréciez un produit, les développeurs d'applications qui se sont déjà abonnés au produit peuvent continuer à l'utiliser mais aucun nouveau développeur ne peut plus s'y abonner.\n\n [En savoir plus]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Lorsque vous éditez un rôle pour les organisations de type fournisseur ou consommateur, celui-ci est inclus dans le rôle par défaut. Les rôles par défaut sont des modèles de rôle préconfigurés utilisés pour déterminer les rôles par défaut qui sont disponibles lorsqu'une organisation est créée.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Lorsque vous activez OpenID Connect, un modèle est fourni pour générer des jetons d'ID ainsi que des jetons d'accès, et les stratégies d'assemblage requises sont automatiquement créées. Vous pouvez personnaliser les stratégies en fonction de vos besoins. L'exemple de clé est fourni à des fins de test uniquement et est utilisé pour signer le jeton JWT.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Lorsque vous activez l'introspection de jeton, le jeton d'accès peut être examiné via le chemin d'introspection.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Lorsque vous activez l'introspection de jeton, les détenteurs de jetons d'accès peuvent examiner le contenu des jetons à l'aide d'un chemin d'introspection. Le jeton d'accès dont vous souhaitez effectuer l'introspection doit être obtenu par le biais du fournisseur OAuth natif.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Lorsque vous invitez un utilisateur à devenir membre d'une organisation de type fournisseur, vous lui affectez un ou plusieurs rôles afin de contrôler son niveau d'accès aux informations et les tâches qu'il peut effectuer.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Lorsque vous gérez vos versions de produit, vous les faites transiter par plusieurs états de cycle de vie : de la mise en préproduction initiale d'une version de produit dans un catalogue à sa publication pour la mettre à la disposition de vos développeurs d'applications, jusqu'à son retrait et à son archivage.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Lorsque vous remplacez un produit par un autre produit, les actions suivantes sont effectuées : 1) Le produit de remplacement est publié, 2) Les paramètres d'application de visibilité, d'abonné et de passerelle du produit original sont utilisés dans le produit de remplacement, 3) Les abonnés au produit original sont migrés vers le produit de remplacement.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Lorsque vous remplacez un produit par un autre produit, les actions suivantes sont effectuées : 1) Le produit de remplacement prend l'état du produit d'origine. 2) Si les paramètres de visibilité et d'abonnement du produit de remplacement sont tels que l'accès est identique ou moins restrictif que celui du produit d'origine, les paramètres du produit de remplacement sont utilisés. Si les paramètres du produit de remplacement sont plus restrictifs et empêchent certaines organisations de type consommateur ayant accès au produit d'origine d'accéder au produit de remplacement, l'opération de remplacement ne peut pas être effectuée. 3) Les abonnés au produit d'origine sont migrés vers le produit de remplacement. (4) Le produit d'origine est retiré.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Lorsque vous retirez un produit, toutes les API associées sont placées hors ligne et les abonnements deviennent inactifs.\n\n [En savoir plus]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Lorsque vous substituez un produit à un autre, les actions suivantes sont effectuées : 1) Le produit de substitution est publié, 2) Les paramètres d'application de visibilité, d'abonné et de passerelle du produit original sont utilisés pour le produit de substitution, 3) Le produit d'origine est déprécié. Lorsqu'un produit est déprécié, les développeurs d'applications qui se sont déjà abonnés au produit peuvent continuer à l'utiliser mais aucun nouveau développeur ne peut plus s'y abonner. Un produit déprécié peut être à nouveau publié, si nécessaire.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Lorsque vous substituez un produit à un autre, les actions suivantes sont effectuées : 1) le produit de substitution est publié, 2) les propriétés de visibilité et de possibilité d'abonnement du produit d'origine sont utilisées dans le produit de substitution, et 3) le produit d'origine passe à l'état Déprécié.  Lorsqu'un produit est déprécié, les développeurs d'applications qui se sont déjà abonnés au produit peuvent continuer à l'utiliser mais aucun nouveau développeur ne peut plus s'y abonner.  Un produit déprécié peut être republié, si nécessaire.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Lorsque vous suspendez une application, les ID client associés ne peuvent plus effectuer d'appels API. Vous pouvez réactiver l'application à tout moment.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Lorsque cette option est activée, le délai d'attente d'invitation de toutes les organisations de type consommateur est le délai d'attente d'invitation du catalogue. Toute mise à jour du délai d'attente d'invitation du catalogue met à jour le délai d'attente d'invitation des organisations de type consommateur.`,
  [`Where did the roles go?`]: `Destination des rôles ?`,
  [`Where to find the named rate limit.`]: `Emplacement de la limite de débit nommée`,
  [`Where to put the decrypted result`]: `Emplacement du résultat déchiffré`,
  [`Where to put the encrypted result`]: `Emplacement du résultat chiffré`,
  [`Whether to reuse the connection to the target server.`]: `Indique s'il faut réutiliser la connexion au serveur cible.`,
  [`Windows live`]: `Windows Live`,
  [`With`]: `Par`,
  [`Wildcards ignore xsi:type rule`]: `Règle Les caractères génériques ignorent xsi:type`,
  [`Work offline`]: `Travailler hors ligne`,
  [`Work with existing definitions, models and/or data sources`]: `Utilisez les définitions, les modèles et/ou les sources de données existants`,
  [`Working...`]: `Traitement en cours...`,
  [`Wrapper Policies`]: `Politiques pour l'encapsuleur`,
  [`Wrong slicing arguments`]: `Arguments de tranche erronés`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL et XSDs doivent se trouver dans son propre fichier zip à l'intérieur du fichier zip que vous tentez d'importer.`,
  [`XML Schema`]: `Schéma XML`,
  [`XML Schema URL`]: `URL du schéma XML`,
  [`XML Schema preview`]: `Aperçu du schéma XML`,
  [`XML Validation Mode`]: `Mode de validation XML`,
  [`XML schema is not valid. Please correct the error.`]: `Le schéma XML n'est pas valide. Corrigez l'erreur.`,
  [`XML to JSON`]: `XML en JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `Version XSLT`,
  [`YAML has been successfully validated`]: `YAML a été validé`,
  [`Yes`]: `Oui`,
  [`Yes, enable it`]: `Oui, l'activer`,
  [`Yes, reset`]: `Oui, réinitialiser`,
  [`Yes. Update API Assembly`]: `Oui. Mettre à jour l'assemblage d'API`,
  [`You are authenticated!`]: `Vous êtes authentifié !`,
  [`You do not have permission to use the Test Application`]: `Vous n'êtes pas autorisé à utiliser l'application de test`,
  [`You can also copy the link and send it directly to the user.`]: `Vous pouvez aussi copier le lien et l'envoyer directement à l'utilisateur.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `Vous pouvez choisir de ne pas publier l'API maintenant ou de la publier immédiatement sur vos noeuds finaux de test et/ou de production. Si vous le souhaitez, vous pouvez spécifier le débit maximal autorisé pour les appels API en fonction d'un nombre d'appels sur un intervalle de temps donné.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `Vous pouvez choisir de ne pas publier l'API maintenant ou de la publier immédiatement sur vos noeuds finaux de test et/ou de production. Si vous le souhaitez, vous pouvez spécifier le débit maximal autorisé pour les appels API en fonction d'un nombre d'appels par intervalle de temps spécifié.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `Vous pouvez choisir de laisser l'API non sécurisée pour le moment, ou exiger qu'une application appelante fournisse un ID client. Vous pouvez configurer plus en détail les options de sécurité une fois la définition d'API créée.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `Vous pouvez entrer un nom ou un titre de catalogue. Le nom du catalogue est prioritaire si les deux sont disponibles.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `Vous pouvez envoyer un courrier électronique à un propriétaire d'organisation de type consommateur. Le message est envoyé via le serveur de messagerie configuré dans Notifications.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `Vous pouvez envoyer un courrier électronique à un propriétaire d'organisation de type consommateur. Le message est envoyé via le serveur de messagerie configuré dans la section Notifications.`,
  [`You can send an email to a provider organization owner.`]: `Vous pouvez envoyer un courrier électronique à un propriétaire d'organisation de type fournisseur.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Vous pouvez transférer la propriété à un nouvel utilisateur en entrant son adresse électronique. L'utilisateur recevra un courrier électronique comportant un lien d'activation. Une fois enregistré, l'utilisateur sera le nouveau propriétaire. Affectez de nouveaux rôles au propriétaire en cours pour qu'il puisse continuer d'utiliser le gestionnaire de cloud.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Vous pouvez transférer la propriété à un utilisateur enregistré en entrant son adresse électronique. Affectez de nouveaux rôles au propriétaire en cours pour qu'il puisse continuer d'utiliser le gestionnaire de cloud.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `Vous pouvez utiliser un fichier de définition OpenAPI pour ajouter une API REST dans IBM API Connect. Le format du fichier peut être JSON ou YAML.  Une fois la définition d'API importée, elle figure dans la liste des définitions d'API.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `Vous ne pouvez pas supprimer ce type car cette opération retire également le type de racine ('Requête').`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `Vous ne pouvez pas sélectionner une API dont le type de passerelle ne correspond pas à celui de ce produit.`,
  [`You currently don’t have any gateway extensions`]: `Vous ne disposez actuellement d'aucune extension de passerelle.`,
  [`You currently don’t have any plans to migrate`]: `Vous ne disposez actuellement d'aucun plan à migrer`,
  [`You currently don't have any policy.`]: `Actuellement, vous ne disposez d'aucune règle.`,
  [`You do not have permission to any spaces.`]: `Vous ne disposez d'aucune autorisation d'accès aux espaces.`,
  [`You do not have permission to publish products to any catalog or space.`]: `Vous ne disposez pas du droit permettant de publier des produits dans un catalogue ou un espace.`,
  [`You do not have permission to stage products to any catalog or space.`]: `Vous ne disposez pas du droit permettant de mettre des produits en préproduction dans un catalogue ou un espace.`,
  [`You don’t currently have any billing integrations.`]: `Vous ne disposez actuellement d'aucune intégration de facturation.`,
  [`You don't currently have any response.`]: `Vous n'avez actuellement aucune réponse.`,
  [`You don't currently have any user-defined policies.`]: `Vous ne disposez actuellement d'aucune stratégie définie par l'utilisateur.`,
  [`You don't have permission to access this page.`]: `Vous n'avez pas le droit d'accéder à cette page.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Vous avez modifié ce certificat. La sauvegarde de cette application mettra à jour sa configuration.`,
  [`You haven't added any APIs or Products`]: `Vous n'avez pas ajouté d'API ou de produit`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Vous gérez l'appartenance au catalogue en ajoutant de nouveaux membres au catalogue et en affectant des rôles aux utilisateurs.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `Vous gérez l'appartenance à l'espace en ajoutant de nouveaux membres à l'espace et en affectant des rôles aux utilisateurs.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Vous gérez l'appartenance à l'espace en ajoutant de nouveaux membres à l'espace et en affectant des rôles aux utilisateurs.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `Vous devez ajouter votre API à un produit prêt à être publié. Un produit regroupe un ensemble d'API et de plans dans une offre que vous mettez à la disposition de vos développeurs. Un plan inclut des paramètres de limite de débit qui peuvent être appliqués au plan dans son ensemble ou spécifiés pour chaque opération dans une API.`,
  [`You must choose a definition`]: `Vous devez sélectionner une définition`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `Vous devez activer au moins un service de passerelle pour que les API publiées dans ce catalogue puissent être appelées sur un noeud final de service de passerelle. Vous pouvez activer plusieurs services de passerelle.`,
  [`You must enable spaces`]: `Vous devez activer les espaces`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Vous devez enregistrer l'ID et la valeur confidentielle du client ci-dessous pour les utiliser lors du développement de votre application. La valeur confidentielle du client ne peut plus être réaffichée.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Votre licence API Connect Reserved Instance vous autorise à télécharger et configurer une ou plusieurs passerelles éloignées DataPower API Gateway. Les passerelles peuvent se trouver sur site ou sur un cloud.`,
  [`Your API has been created`]: `Votre API a été créée`,
  [`Your API is live!`]: `Votre API est opérationnelle.`,
  [`Your API is online!`]: `Votre API est en ligne.`,
  [`Your Catalog is being deleted. This may take a while...`]: `Votre catalogue est en cours de suppression. Cette opération peut prendre un certain temps...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Votre organisation de type consommateur est en cours de création. Cette opération peut prendre un certain temps...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Votre invitation n'est plus valide. Vérifiez le paramètre de délai de validité de l'invitation et renvoyez l'invitation.`,
  [`Your Provider organization is being created. This may take a while...`]: `Votre organisation de type fournisseur est en cours de création. Cette opération peut prendre un certain temps...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Votre organisation de type fournisseur est en cours de suppression. Cette opération peut prendre un certain temps...`,
  [`Your member is being deleted. This may take a while...`]: `Suppression du membre en cours. Cette opération peut prendre un certain temps...`,
  [`Your Space is being deleted. This may take a while...`]: `Votre espace est en cours de suppression. Cette opération peut prendre un certain temps...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `Votre fournisseur OAuth n'a pas sauvegardé certaines modifications. Cliquez sur OK pour continuer sans sauvegarder.`,
  [`Your credentials have expired.`]: `Vos informations d'identification ont expiré.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Votre invitation a été envoyée avec le lien d'invitation suivant :`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `Votre demande est toujours en cours de traitement et prend plus de temps que prévu. Actualisez l'affichage dans quelques minutes avant de réessayer la demande.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Votre produit sera sauvegardé avant de poursuivre, êtes-vous sûr ?`,
  [`Your trial ends on {date}`]: `Votre version d'évaluation se termine le {date}`,
  [`Your trial expired on {date}.`]: `Votre version d'évaluation a expiré le {date}.`,
  [`Zoom in`]: `Zoom avant`,
  [`Zoom in and out`]: `Zoom avant et arrière`,
  [`Zoom out`]: `Zoom arrière`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `CORS n'est pas pris en charge sur le serveur cible (voir la suggestion ci-dessous)`,
  [`a LDAP provider`]: `d'un fournisseur LDAP`,
  [`activity`]: `activité`,
  [`add item...`]: `ajouter un élément...`,
  [`add property...`]: `ajouter une propriété...`,
  [`added`]: `ajouté`,
  [`admin`]: `administrateur`,
  [`administrator`]: `administrateur`,
  [`all`]: `tout`,
  [`an API Connect Local User Registry`]: `d'un registre d'utilisateurs local API Connect`,
  [`an authentication URL`]: `d'une URL d'authentification`,
  [`an untrusted certificate has been encountered`]: `un certificat non digne de confiance a été détecté`,
  [`and`]: `et`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Application :`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `approbation`,
  [`approval task for product`]: `tâche d'approbation pour le produit`,
  [`archived`]: `archivé`,
  [`authenticated`]: `authentifié`,
  [`authorization code time to live (seconds)`]: `Durée de vie du code d'autorisation (en secondes)`,
  [`Authorization header pass through`]: `Passe-système de l'en-tête d'autorisation`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `bloquant`,
  [`body`]: `corps`,
  [`body-param`]: `body-param`,
  [`cancel`]: `annuler`,
  [`case`]: `cas`,
  [`catalog`]: `Catalogue`,
  [`catalog:`]: `Catalogue :`,
  [`catch`]: `accroche`,
  [`changed`]: `modifié`,
  [`cipher`]: `chiffrement`,
  [`code id_token`]: `id_token de code`,
  [`code id_token token`]: `jeton id_token de code`,
  [`code token`]: `jeton de code`,
  [`Consume`]: `Consommer`,
  [`consumer`]: `consommateur`,
  [`consumes`]: `consomme`,
  [`composite`]: `composite`,
  [`created`]: `créé`,
  [`Created API`]: `API créée`,
  [`custom string`]: `chaîne personnalisée`,
  [`day`]: `jour`,
  [`day(s)`]: `jour(s)`,
  [`default`]: `par défaut`,
  [`default value`]: `valeur par défaut`,
  [`definition`]: `définition`,
  [`deprecated`]: `obsolète`,
  [`description`]: `description`,
  [`development`]: `développement`,
  [`edit condition`]: `éditer la condition`,
  [`edited`]: `édité`,
  [`environment`]: `Catalogue`,
  [`error`]: `Erreur`,
  [`false`]: `faux`,
  [`file`]: `fichier`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `icônes font-awesome 4.5.0`,
  [`for`]: `pour`,
  [`header`]: `en-tête`,
  [`hear`]: `entendre`,
  [`hello`]: `Bonjour`,
  [`helloName`]: `Bonjour {name} !`,
  [`here`]: `ici`,
  [`hit`]: `réussite`,
  [`hits`]: `réussites`,
  [`hour`]: `heure`,
  [`hour(s)`]: `heure(s)`,
  [`hours`]: `heures`,
  [`identified`]: `identifié`,
  [`Increased`]: `Augmenté`,
  [`Increment`]: `Incrémenter`,
  [`info`]: `Info`,
  [`information about external documentation`]: `Informations sur la documentation externe`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `Au lieu d'honorer la réclamation exp du fournisseur OIDC, le paramètre access_token/refresh_token généré par APIC honorera le paramètre APIC`,
  [`introspect URL`]: `URL d'introspection`,
  [`Issue`]: `Problème`,
  [`Issues`]: `Problèmes`,
  [`item`]: `élément`,
  [`iterate over`]: `itérer sur`,
  [`last modified`]: `dernière modification`,
  [`Log Level`]: `Niveau de journalisation`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `journaux`,
  [`Logout`]: `Déconnexion`,
  [`Logout redirect`]: `Redirection de déconnexion`,
  [`Logout redirect URL`]: `URL de redirection de déconnexion`,
  [`location`]: `location`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `minute`,
  [`minute(s)`]: `minute(s)`,
  [`minutes`]: `minutes`,
  [`mutation type`]: `type de mutation`,
  [`mutual SSL authentication is required`]: `l'authentification SSL mutuelle est requise`,
  [`name`]: `Nom`,
  [`Name|extension`]: `Nom`,
  [`Name|path parameters`]: `Nom`,
  [`Name|security definitions`]: `Nom`,
  [`non-blocking`]: `non bloquant`,
  [`none`]: `aucun`,
  [`none selected`]: `aucune sélection`,
  [`Object`]: `Objet`,
  [`off`]: `désactivé`,
  [`of {total} pages`]: `sur {total} page(s)`,
  [`of {total} page`]: `sur {total} page`,
  [`offline`]: `hors ligne`,
  [`on`]: `activé`,
  [`online`]: `en ligne`,
  [`or`]: `ou`,
  [`or redact from all`]: `ou occulter de tout`,
  [`open and close list of options`]: `ouvrir et fermer la liste d'options`,
  [`organization saved`]: `organisation sauvegardée`,
  [`organization-manager`]: `organization-manager`,
  [`otherwise`]: `sinon`,
  [`payload`]: `contenu`,
  [`Payload`]: `Contenu`,
  [`pending`]: `en attente`,
  [`produces`]: `produit`,
  [`production`]: `production`,
  [`production and development`]: `production et développement`,
  [`property`]: `propriété`,
  [`provider`]: `fournisseur`,
  [`publish product`]: `publier le produit`,
  [`published`]: `publié`,
  [`query type`]: `type de requête`,
  [`query-parameters`]: `query-parameters`,
  [`realized`]: `réalisée`,
  [`recipients`]: `destinataires`,
  [`removed`]: `supprimé`,
  [`replaced`]: `remplacé`,
  [`repeatable`]: `reproductible`,
  [`Replenish`]: `Réapprovisionner`,
  [`request`]: `demande`,
  [`requested by`]: `demandé par`,
  [`Resolver weight`]: `Poids du programme de résolution`,
  [`response`]: `réponse`,
  [`response-param`]: `response-param`,
  [`retired`]: `retiré`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `sauvegardé`,
  [`scalar`]: `scalaire`,
  [`second`]: `seconde`,
  [`second(s)`]: `seconde(s)`,
  [`seconds`]: `secondes`,
  [`select owner user`]: `sélectionner un utilisateur propriétaire`,
  [`select user`]: `sélectionner un utilisateur`,
  [`soap-body`]: `soap-body`,
  [`source`]: `source`,
  [`space`]: `espace`,
  [`Space settings`]: `Paramètres d'espace`,
  [`Skip`]: `Ignorer`,
  [`specified`]: `spécifié`,
  [`staged`]: `mis en préproduction`,
  [`start generating tests automatically.`]: `commencer à générer des tests automatiquement.`,
  [`subject`]: `objet`,
  [`subscription type`]: `type d'abonnement`,
  [`supersede`]: `substituer`,
  [`superseded`]: `substitué`,
  [`target`]: `cible`,
  [`the server is unavailable`]: `le serveur n'est pas disponible`,
  [`this user registry type`]: `de ce type de registre d'utilisateurs`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Il s'agit d'une page de publication temporaire dans laquelle la republication conserve les abonnements.`,
  [`to production`]: `vers la production`,
  [`topology-administrator`]: `topology-administrator`,
  [`Trial days left`]: `Jour(s) d'essai restant(s)`,
  [`true`]: `vrai`,
  [`ttl`]: `durée de vie`,
  [`ttl has been changed`]: `La durée de vie a été changée`,
  [`type`]: `type`,
  [`unarchived`]: `désarchivé`,
  [`union type`]: `type d'union`,
  [`unlimited`]: `illimité`,
  [`updated`]: `mis à jour`,
  [`Updated from new schema`]: `Mis à jour à partir du nouveau schéma`,
  [`Upgrade`]: `Mettre à niveau`,
  [`v5 legacy behavior`]: `Comportement existant de la version 5`,
  [`version 2`]: `version 2`,
  [`version 3`]: `version 3`,
  [`viewer`]: `visualiseur`,
  [`value`]: `valeur`,
  [`warn`]: `Avertissement`,
  [`warning`]: `avertissement`,
  [`warnings`]: `avertissements`,
  [`was not found. Please select a configured OAuth provider.`]: `introuvable. Sélectionnez un fournisseur OAuth configuré.`,
  [`week`]: `semaine`,
  [`with`]: `avec`,
  [`with|supersede with`]: `avec`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `statut dans le bac à sable`,
  [`your file here, or`]: `votre fichier ici, ou`,
  [`{count} found!`]: `{count} trouvé !`,
  [`{current} of {total} page`]: `{current} page sur {total}`,
  [`{current} of {total} pages`]: `{current} page(s) sur {total}`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} jour(s), {hours} heure(s),et {minutes} minute(s)`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} jour(s) et {hours} heure(s)`,
  [`{docType} is required`]: `{docType} est obligatoire`,
  [`{docType} title`]: `Titre {docType}`,
  [`Product title`]: `Titre du produit`,
  [`Application title`]: `Titre de l'application`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} heure(s) et {minutes} minute(s)`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} passerelle(s) masquée(s) en raison d'une incompatibilité avec le type de passerelle spécifié dans l'API ou le produit`,
  [`{min}-{max} of {total} items`]: `{min} à {max} éléments sur {total}`,
  [`{min}-{max} of {total} item`]: `{min} à {max} élément sur {total}`,
  [`{min}-{max} of {total} Policies`]: `{min} à {max} stratégies sur {total}`,
  [`{min}-{max} of {total} Policy`]: `{min} à {max} stratégie sur {total}`,
  [`{min}-{max} of {total} types`]: `{min} à {max} types sur {total}`,
  [`{min}-{max} of {total} type`]: `{min} à {max} type sur {total}`,
  [`{min}-{max} of {total} search results`]: `{min} à {max} résultats de recherche sur {total}`,
  [`{min}-{max} of {total} search result`]: `{min} à {max} résultat de recherche sur {total}`,
  [`{min}-{max} of {total} Catalogs`]: `{min} à {max} catalogues sur {total}`,
  [`{min}-{max} of {total} Catalog`]: `{min} à {max} catalogue sur {total}`,
  [`{min}-{max} of {total} Spaces`]: `{min} à {max} espaces sur {total}`,
  [`{min}-{max} of {total} Space`]: `{min} à {max} espace sur {total}`,
  [`{min}-{max} of {total} Extensions`]: `{min} à {max} extensions sur {total}`,
  [`{min}-{max} of {total} Extension`]: `{min} à {max} extension sur {total}`,
  [`{min}-{max} of {total} Product Plans`]: `{min} à {max} plans de produit sur {total}`,
  [`{min}-{max} of {total} Product Plan`]: `{min} à {max} plan de produit sur {total}`,
  [`{min}-{max} of {total} Gateways`]: `{min} à {max} passerelles sur {total}`,
  [`{min}-{max} of {total} Gateway`]: `{min} à {max} passerelle sur {total}`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `Il manque 'x-ibm-configuration' dans {name} (API)`,
  [`{name} (Product) has been published!`]: `{name} (produit) a été publié`,
  [`{name} (Product) has been staged!`]: `{name} (produit) a été mis en préproduction`,
  [`{name} (Product) has not been published!`]: `{name} (produit) n'a pas été publié`,
  [`{name} (Role) has been created`]: `{name} (rôle) a été créé.`,
  [`{name} successfully deleted`]: `{name} a été supprimé.`,
  [`{name} (Role) has been updated`]: `{name} (rôle) a été mis à jour.`,
  [`{name} has been assigned the owner`]: `Le rôle de propriétaire a été affecté à {name}`,
  [`{number} Error`]: `{number} Erreur`,
  [`{number} Errors`]: `{number} Erreurs`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `Tâche d'approbation {operation} pour le produit {prodname}:{version} demandée par {username}`,
  [`{path} does not exist`]: `{path} n'existe pas`,
  [`{productName} has no migration target set.`]: `Aucune cible de migration n'est définie pour {productName}.`,
  [`{state} Pending`]: `{state} En attente`,
  [`{status} API error`]: `{status} Erreur d'API`,
  [`{status} Bad Request`]: `{status} Demande incorrecte`,
  [`{status} Forbidden`]: `{status} Interdit`,
  [`{status} Gateway Error`]: `{status} Erreur de passerelle`,
  [`{status} Unauthorized`]: `{status} Non autorisé`,
  [`({time} after being sent)`]: `({time} après son envoi)`,
  [`({time} after being queued)`]: `({time} après sa mise en file d'attente)`,
  [`{title} (Product) has been {state}.`]: `{title} (produit) a été {state}.`,
  [`{title} has been deleted`]: `{title} - Suppression effectuée`,
  [`{title} has been {changedName}.`]: `{title} a été {changedName}.`,
  [`{title} has not been deleted!`]: `{title} n'a pas été supprimé !`,
  [`{title} has not been {changedName}!`]: `{title} n'a pas été {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type} {changedName}.`,
  [`{type} has not been {changedName}!`]: `{type} ne pas {changedName} !`,
  [`{type} history log`]: `Journal historique {type}`,
  [`{username} does not exist`]: `{username} n'existe pas`,
  [`{username} does not exist in the user registry`]: `{username} n'existe pas dans le registre d'utilisateurs`,
  [`{username} is not associated with this consumer org`]: `{username} n'est pas associé à cette organisation de type consommateur`,
  [`{username} has been created as a {type}.`]: `{username} a été créé en tant que {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `L'application {appName} a été rétablie dans le portail de développeur {consumerOrg}`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `L'application {appName} a été suspendue dans le portail de développeur {consumerOrg}`,
  [`{{catalogTitle}} developer portal account registration`]: `Enregistrement du compte du portail de développeur {catalogTitle}`,
  [`{{count}} errors need attention`]: `{count} erreurs nécessitant votre attention`,
  // lts only translations
  [`Configure JSON settings`]: `Configurer les paramètres JSON`,
  [`Configure XML settings`]: `Configurer les paramètres XML`,
  [`Configure options`]: `Options de configuration`,
  [`Detect`]: `Détecter`,
  [`Document type`]: `Type de document`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Taille maximale du document`,
  [`Maximum name length`]: `Longueur maximale du nom`,
  [`Maximum nesting depth`]: `Profondeur maximale d'imbrication`,
  [`Maximum number length`]: `Longueur maximale du nombre`,
  [`Maximum number of unique names`]: `Nombre maximal de noms uniques`,
  [`Maximum number of unique namespaces`]: `Nombre maximal d'espaces de nom uniques`,
  [`Maximum number of unique prefixes`]: `Nombre maximal de préfixes uniques`,
  [`Maximum value length`]: `Longueur maximale de la valeur`,
  [`Maximum width`]: `Largeur maximale`,
  [`Maximum stack size`]: `Taille maximale de la pile`,
  [`Parse settings`]: `Paramètres d'analyse`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Indique la taille de document maximale, en octets, acceptée par l'action d'analyse.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Indique le niveau maximal de la profondeur d'élément imbriqué dans un message XML ou JSON acceptée par l'action parse.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Indique le niveau maximal de la profondeur d'élément imbriqué dans un message XML, JSON ou GraphQL acceptée par l'action parse.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Indique la longueur de nom maximale, en octets, acceptée par l'action d'analyse.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Indique que la longueur maximale de nombre limite le nombre d'octets dans la partie valeur d'une paire libellé-valeur lorsque la valeur est un nombre.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Indique le nombre maximal d'URI d'espace de nom XML uniques accepté par l'action d'analyse. Cette limite comptabilise tous les espaces de nom XML, quel que soit le nombre de préfixes utilisés pour les déclarer.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Indique le nombre maximal de préfixes d'espace de nom XML uniques dans un document accepté par l'action d'analyse. Cette limite comptabilise plusieurs préfixes définis pour le même espace de nom, mais ne comptabilise pas plusieurs espaces de nom définis dans différentes parties du document d'entrée sous un préfixe unique.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Indique le nombre maximal de noms uniques accepté par l'action d'analyse.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Indique la longueur de valeur maximale, en octets, acceptée par l'action d'analyse.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Indique la largeur de contenu maximale acceptée par l'action d'analyse.`,
  [`Specifies the type of document to parse.`]: `Indique le type de document à analyser.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Indique la version du processeur XSLT. La valeur par défaut est XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Indique s'il convient d'activer le contrôle d'erreur XSLT strict. Les opérations non strictes tentent de récupérer après certaines erreurs, telles que l'utilisation de variables non déclarées, l'appel de modèles non déclarés, etc. Par défaut, la vérification stricte des erreurs XSLT est activée.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Indique si le profilage de feuille de style doit être activé. Cette option ne doit pas être utilisée dans les environnements de production. Par défaut, le profilage de feuille de style est désactivé.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Indique s'il convient d'exécuter le script de feuille de style, le script XQuery et le script JSONiq en mode débogage. Lorsqu'une feuille de style, un script XQuery ou un script JSONiq est exécuté en mode débogage, une page Web personnalisée est générée au lieu d'afficher sa sortie standard. La page Web détaille exactement ce qu'il s'est produit lors de l'exécution, y compris les valeurs des variables et la provenance des parties spécifiques de la sortie. Cette option ne doit pas être utilisée dans les environnements de production. Par défaut, le mode de débogage est désactivé.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Indique si la feuille de style doit être exécutée en mode diffusion en continu. La transformation du document commence avant que la saisie ne soit entièrement analysée. Toutes les feuilles de style ne peuvent pas être diffusées en continu. Si la feuille de style ne peut pas être diffusée en continu, une erreur est générée et l'entrée n'est pas traitée. Par défaut, le mode de diffusion en continu est désactivé.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Indique s'il faut tenter d'exécuter la feuille de style en mode diffusion en continu. La transformation du document commence avant que la saisie ne soit entièrement analysée. Toutes les feuilles de style ne peuvent pas être diffusées en continu. Si la feuille de style ne peut pas être diffusée en continu, un avertissement est généré lors de la compilation et la feuille de style est lue dans la totalité de l'entrée en mode normal au moment de l'exécution. Par défaut, la tentative d'exécution de la feuille de style en mode diffusion en continu est désactivée.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Indique si la sortie produite à partir de la feuille de style doit être mise en échappement lors du traitement. L'échappement minimal est particulièrement utile lorsque vous manipulez des jeux de caractères non anglais. Par défaut, l'échappement minimal est désactivé.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Indique le nombre maximal d'octets que la pile est autorisée à utiliser lors de l'exécution d'une feuille de style ou d'un autre contenu compilé. Ce paramètre permet de bloquer la récursivité infinie. La valeur minimale est 10 kilooctets, ou 10 240 octets. La valeur maximale est 100 mégaoctets ou 104 857 600 octets. La valeur par défaut est 1 mégaoctet ou 1 048 576 octets.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Indique le comportement de validation à appliquer aux fichiers WSDL qui sont vérifiés pour leur conformité à la section 5 du profil de base WS-I (version 1.0, avril 2004). La valeur par défaut est Avertir.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Indique le comportement de validation de soap:Body. Le paramètre par défaut est Strict.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Indique le comportement de validation de soap:Header. Le paramètre par défaut est Souple.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Indique le comportement de validation des détails de l'erreur. Le paramètre par défaut est Strict.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Indique si la compatibilité doit être nécessaire avec les encapsuleurs de style RPC. Par défaut, les encapsuleurs de style RPC ne sont pas obligatoires.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Indique s'il convient d'autoriser le schéma à accepter la plupart des utilisations des éléments avec xsi:type='SOAP-ENC:Array' compatibles avec SOAP 1.1. Section 5, même lorsque ces attributs violent la spécification de schéma XML. Normalement, l'attribut xsi:type doit désigner un type égal à ou dérivé du type réel de l'élément. Pour les schémas compilés avec cette option, xsi:type est accepté spécifiquement pour le type complexe SOAP 1.1 Encoding 'Array' si le type d'élément est dérivé de SOAP-ENC:Array. Il s'agit de l'inverse du cas autorisé normal. Par défaut, les éléments avec xsi:type='SOAP-ENC:Array' ne sont pas acceptés.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Indique si la validation de schéma supplémentaire doit être effectuée conformément aux règles de codage de SOAP 1.1 Section 5. Lorsque cette option est activée, les membres des tableaux SOAP sont validés, les attributs tels que @id et @href sont autorisés même s'ils ne le sont pas par le schéma, et les valeurs @href sont vérifiées pour s'assurer qu'elles ont un élément @id correspondant. Par défaut, la validation supplémentaire n'est pas effectuée.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Indique si les éléments xs:any du schéma valident uniquement les éléments enfant par leur nom. La spécification de schéma XML requiert que, si un caractère générique correspond à un élément, mais si cet élément ne comporte pas de déclaration d'élément, l'élément est alors validé en fonction d'un attribut xsi:type sur celui-ci. Cette option ne tient pas compte de ces attributs xsi:type. Elle doit être utilisée pour les cas tels que la validation de l'enveloppe SOAP où une étape de validation supplémentaire valide le contenu correspondant au caractère générique, éventuellement à l'aide des règles de codage SOAP 1.1. Par défaut, les attributs xsi:type ne sont pas ignorés.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Indique si la liaison SOAP doit ou non être strictement respectée dans le WSDL. Si cette option est activée, seuls les messages liés à SOAP 1.2 apparaissent dans les enveloppes SOAP 1.2 et seuls les messages liés à SOAP 1.1 apparaissent dans les enveloppes SOAP 1.1. Par défaut, la liaison SOAP stricte est désactivée.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Indique si les stratégies XACML doivent être compilées avec des informations de débogage. Notez que les messages de débogage XACML sont également contrôlés par l'événement du journal dans la catégorie XACML. Utilisez le niveau de journalisation 'debug' pour afficher les messages de débogage XACML complets. Par défaut, les stratégies XACML ne sont pas compilées avec les informations de débogage.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Indique si le schéma ou le document WSDL accepte les messages dans lesquels le contenu binaire codé en base64 a été optimisé conformément aux spécifications MTOM/XOP. L'optimisation binaire XOP remplace les données binaires codées en base 64 par un élément de référence xop:Include qui fait référence aux données de fichier binaire non codé se trouvant dans une pièce jointe. Par défaut, les messages optimisés MTOM/XOP sont désactivés.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Un groupe d'organisations de type consommateur permet de contrôler de manière efficace qui peut voir les API de vos produits et s'abonner à ces dernières. En utilisant un groupe d'organisations de type consommateur, vous pouvez définir cet accès pour tous les développeurs des organisations de ce groupe en une seule opération au lieu d'avoir à définir l'accès pour les organisations séparément.`,
  [`API Connect Home`]: `Accueil API Connect`,
  [`Add new group`]: `Ajouter un nouveau groupe`,
  [`Add consumer organizations to the group as required`]: `Ajoutez des organisations de type consommateur au groupe, selon les besoins`,
  [`Add the following configuration`]: `Ajouter la configuration suivante`,
  [`Add the following configuration to`]: `Ajouter la configuration suivante à`,
  [`Additions`]: `Ajouts`,
  [`Additional GraphQL capabilities`]: `Fonctions GraphQL supplémentaires`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Paramètres supplémentaires inclus dans ce plan pour les API graphQL`,
  [`An organization or a group is required.`]: `Une organisation ou un groupe est requis.`,
  [`An organization/group is required.`]: `Une organisation/un groupe est requis.`,
  [`Analysis configuration`]: `Configuration de l'analyse`,
  [`Application & Credentials`]: `Application et données d'identification`,
  [`Are you sure you want to delete this API version?`]: `Voulez-vous vraiment supprimer cette version d'API ?`,
  [`Are you sure you want to delete this API with all versions?`]: `Voulez-vous vraiment supprimer cette API avec toutes les versions ?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Voulez-vous vraiment supprimer l'extension de passerelle de la passerelle ?`,
  [`Are you sure you want to rename all versions of this API?`]: `Voulez-vous vraiment renommer toutes les versions de cette API ?`,
  [`Are you sure you want to rename this API version?`]: `Voulez-vous vraiment renommer cette version d'API ?`,
  [`Associate groups to the organization as required`]: `Associez des groupes à l'organisation, selon les besoins`,
  [`At least one option must be enabled`]: `Au moins une option doit être activée`,
  [`Attribute mapping`]: `Mappage d'attribut`,
  [`Attempt streaming rule`]: `Règle de tentative de diffusion en continu`,
  [`Auto generated default paths and operations`]: `Chemins d'accès et opérations par défaut générés automatiquement`,
  [`Base API endpoint`]: `Noeud final d'API de base`,
  [`Consumer orgs`]: `Organisations de type consommateur`,
  [`Calculate Query Characteristics`]: `Calculer les caractéristiques de la requête`,
  [`Calculate Response Characteristics`]: `Calculer les caractéristiques de la réponse`,
  [`Certificate or Shared Secret for Verify`]: `Certificat ou valeur confidentielle partagée pour la vérification`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Consultez nos ateliers pratiques pour demander à essayer les fonctions complètes de gestion d'API pendant une semaine.`,
  [`Clone API`]: `Cloner l'API`,
  [`Consumer organizations and groups`]: `Groupes et organisations de type consommateur`,
  [`Create new API version`]: `Créer une version d'API`,
  [`Create new product version`]: `Créer une version de produit`,
  [`Create a GraphQL API based on a GraphQL service`]: `Créer une API GraphQL en fonction d'un service GraphQL`,
  [`Custom rate limit`]: `Limite de débit personnalisée`,
  [`Delete API`]: `Supprimer l'API`,
  [`Delete this version`]: `Supprimer cette version`,
  [`Deletion confirmation`]: `Confirmation de la suppression`,
  [`Deletions`]: `Suppressions`,
  [`Display name`]: `Nom d'affichage`,
  [`Display Name`]: `Nom d'affichage`,
  [`Details`]: `Détails`,
  [`Edit GraphQL Rate Limit|title`]: `Editer la limite de débit de GraphQL|titre`,
  [`Edit GraphQL rate limits`]: `Editer les limites de débit de GraphQL`,
  [`Edit group`]: `Editer le groupe`,
  [`Edit condition`]: `Editer la condition`,
  [`Edit rate limit|title`]: `Editer la limite de débit`,
  [`Edit rate limit|menu`]: `Editer la limite de débit`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Aucun produit n'est publié dans ce catalogue, ou cette application est déjà abonnée à tous les plans dans tous les produits.`,
  [`Enforce Policy`]: `Imposer la stratégie`,
  [`Enter a group summary`]: `Entrez un récapitulatif de groupe`,
  [`Enter a group title`]: `Entrez un titre de groupe`,
  [`Enter group details below`]: `Entrez ci-dessous des détails de groupe`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Entrez le nom de schéma et l'URL du service GraphQL qui doit passer par un proxy`,
  [`Enum value`]: `Valeur Enum`,
  [`Go to catalog settings to configure a new billing integration`]: `Accédez aux paramètres de catalogue pour configurer une nouvelle intégration de facturation`,
  [`GraphQL introspect`]: `Introspection GraphQL`,
  [`GraphQL server`]: `Serveur GraphQL`,
  [`GraphQL Setting`]: `Paramètre GraphQL`,
  [`GraphQL server URL`]: `URL du serveur GraphQL`,
  [`GraphQL server name`]: `Nom du serveur GraphQL`,
  [`GraphQL type analysis configuration`]: `Configuration d'analyse de type GraphQL`,
  [`GraphQL resolver analysis configuration`]: `Configuration d'analyse de programme de résolution GraphQL`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Les groupes peuvent être créés et gérés dans la liste suivante.`,
  [`Groups will be listed here.`]: `Les groupes sont répertoriés ici.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Bonjour {firstName},\n\nVotre demande de {action} du produit d'API {productName}:{version} dans le catalogue\n{catalog} a été approuvée.\n\n{#if comments}\nLe valideur a inclus les commentaires suivants :\n\n{comments}\n\n{{/if}}\nVous pouvez vérifier le statut du produit ici :\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Bonjour,\n\nCeci est un message test envoyé par IBM API Connect depuis le serveur de messagerie configuré \n{mailServer}.\n\nSi vous recevez ce message, le test a fonctionné !\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Bonjour,\n\nL'utilisateur {originator} a retiré sa demande d'abonnement avec l'application {appName}\nau plan {planName} pour la version {productVersion} du produit d'API {productName} \ndans le catalogue {catalog}.\n\nAucune autre action n'est requise.\n`,
  [`Invoke GraphQL server`]: `Appeler le serveur GraphQL`,
  [`Invoke the backend GraphQL server`]: `Appeler le serveur GraphQL de back end`,
  [`JWKS endpoint`]: `Noeud final JWKS`,
  [`LDAP Field Name`]: `Nom de la zone LDAP`,
  [`Limit maximum nesting per query`]: `Limiter l'imbrication maximale par requête`,
  [`Limit resolve complexity rate on a per key basis`]: `Limiter le taux de complexité de résolution clé par clé`,
  [`Limit type complexity rate on a per key basis`]: `Limiter le taux de complexité de type clé par clé`,
  [`Manage APIs included in this product`]: `Gérer les API incluses dans ce produit`,
  [`Manage groups`]: `Gère les groupes.`,
  [`Maximum nesting`]: `Imbrication maximale`,
  [`Maximum resolve complexity`]: `Complexité de résolution maximale`,
  [`Maximum type complexity`]: `Complexité de type maximale`,
  [`Name of the group`]: `Nom de groupe`,
  [`New API version`]: `Nouvelle version d'API`,
  [`New GraphQL API`]: `Nouvelle API GraphQL`,
  [`New product version`]: `Nouvelle version de produit`,
  [`New Version`]: `Nouvelle version`,
  [`New version`]: `Nouvelle version`,
  [`No billing integration exists`]: `Il n'existe pas d'intégration de facturation`,
  [`No CA bundles available`]: `Aucun bundle d'autorité de certification disponible`,
  [`No Data`]: `Aucune donnée`,
  [`No certificates available`]: `Aucun certificat disponible`,
  [`No data`]: `Aucune donnée`,
  [`No group found`]: `Aucun groupe trouvé`,
  [`OAuth Auth URL`]: `URL d'authentification OAuth`,
  [`OAuth Token URL`]: `URL de jeton OAuth`,
  [`Open Stripe dashboard`]: `Ouvrir le tableau de bord Stripe`,
  [`Operation/Path`]: `Opération/Chemin d'accès`,
  [`Operation Path`]: `Chemin d'opération`,
  [`Operations and Paths`]: `Opérations et chemins d'accès`,
  [`Operator`]: `Opérateur`,
  [`Organizations and groups will be listed here.`]: `Les organisations et les groupes sont répertoriés ici`,
  [`Page not found`]: `Page introuvable`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Veuillez copier le code ci-dessous dans l'interface de ligne de commande pour poursuivre le processus. Le code expirera dans **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Veuillez référencer votre tableau de bord Stripe pour accéder à la clé d'API et à la valeur confidentielle`,
  [`Please upload a schema definition language (SDL) file.`]: `Téléchargez un fichier de langage de définition de schéma (SDL).`,
  [`Please upload a valid GraphQL schema.`]: `Téléchargez un schéma GraphQL valide.`,
  [`Please upload a valid YAML file.`]: `Veuillez télécharger un fichier YAML valide.`,
  [`Populate Server Configuration into Context Variables`]: `Appliquer la configuration du serveur aux variables contextuelles`,
  [`POST/GET a query to be validated and sent to the backend server`]: `Publier/extraire une requête à valider et à envoyer au serveur de back end`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `Publier/extraire une requête pour obtenir le coût estimé de l'appel de cette requête`,
  [`Preparing`]: `Préparation`,
  [`Rate limit amount cannot be negative`]: `La quantité limite de débit ne peut pas être négative`,
  [`Rate limit per cannot be negative`]: `La limite de débit par ne peut pas être négative`,
  [`Rename API`]: `Renommer l'API`,
  [`Rename`]: `Renommer`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Demande d'approbation de {action} d'un produit API dans le catalogue {catalog}`,
  [`Sandbox Sample Application`]: `Modèle d'application de bac à sable`,
  [`Sandbox sample application credentials`]: `Données d'identification du modèle d'application de bac à sable`,
  [`sample application credentials`]: `Données d'identification du modèle d'application`,
  [`Saving`]: `Sauvegarde`,
  [`Search Orgs`]: `Rechercher dans les organisations`,
  [`Search definitions...`]: `Rechercher dans les définitions...`,
  [`Search errors...`]: `Rechercher dans les erreurs...`,
  [`Search operations...`]: `Rechercher dans les opérations...`,
  [`Search organizations and groups`]: `Rechercher dans les organisations et groupes`,
  [`Sorry, This feature is not available in`]: `Désolé, cette fonction n'est pas disponible dans`,
  [`Stopping`]: `Arrêt`,
  [`The user with username {username} already exists in the user registry.`]: `L'utilisateur portant le nom {username} existe déjà dans le registre d'utilisateurs.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Certaines modifications d'API ne sont pas en cours d'exécution. Republiez cette API dans le catalogue pour bac à sable.`,
  [`There are no plans available for subscription.`]: `Aucun plan n'est disponible pour abonnement.`,
  [`There are no attributes yet.`]: `Il n'existe actuellement aucun attribut.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Entrez du texte pour ajouter des organisations de type consommateur au groupe`,
  [`Type to add groups to the organization`]: `Entrez du texte pour ajouter des groupes à l'organisation`,
  [`Type to add organizations from {catalog}`]: `Entrez du texte pour ajouter des organisations à partir de {catalog}`,
  [`Type to add organizations or groups`]: `Entrez du texte pour ajouter des organisations ou des groupes`,
  [`Type to add organizations or groups from {catalog}`]: `Entrez du texte pour ajouter des organisations ou des groupes à partir de {catalog}`,
  [`Type to add {title} to groups`]: `Entrez du texte pour ajouter {title} à des groupes`,
  [`Update Rate Limits`]: `Mettre à jour les limites de débit`,
  [`Upload the YAML file with configuration`]: `Télécharger le fichier YAML avec la configuration`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Utilisez l'exemple ci-dessous en tant que modèle pour configurer votre serveur GraphQL`,
  [`User managed`]: `Géré par l'utilisateur`,
  [`URL {url} does not contain valid OpenAPI.`]: `L'URL {url} ne contient pas une valeur OpenAPI valide.`,
  [`Validating`]: `Validation`,
  [`View analytics and performance metrics for your APIs`]: `Afficher les métriques d'analyse et de performance pour vos API`,
  [`View and add custom policies to the catalog`]: `Afficher et ajouter des stratégies personnalisées au catalogue`,
  [`View and add custom exentions to the catalog`]: `Afficher et ajouter des extensions personnalisées au catalogue`,
  [`View user registries and OAuth providers`]: `Afficher les registres d'utilisateurs et les fournisseurs OAuth`,
  [`View your application and manage API credentials`]: `Afficher votre application et gérer les données d'identification d'API`,
  [`Welcome to the API Connect Trial`]: `Bienvenue dans la version d'essai d'API Connect`,
  [`You haven't added any APIs`]: `Vous n'avez pas encore ajouté d'API`,
  [`You currently don’t have any {resource}.`]: `Actuellement, vous ne disposez pas de {resource}.`,
  [`plans to migrate`]: `plans de migration`,
  [`gateway extensions`]: `extensions de passerelle`,
  [`application`]: `application`,
  [`application authentication source`]: `source d'authentification de l'application`,
  [`API or product`]: `API ou produit`,
  [`base endpoints`]: `noeuds finaux de base`,
  [`categories`]: `catégories`,
  [`certificates`]: `certificats`,
  [`channels`]: `Canaux`,
  [`consumer organizations`]: `organisations de type consommateur`,
  [`consumer organization group`]: `groupe d'organisations de type consommateur`,
  [`consumer organizations or groups`]: `groupes ou organisations de type consommateur`,
  [`correlation ids`]: `ID de corrélation`,
  [`cors policy`]: `règle CORS`,
  [`definitions`]: `definitions`,
  [`examples`]: `exemples`,
  [`gateway services`]: `services de passerelle`,
  [`groups`]: `groupes`,
  [`headers`]: `en-têtes`,
  [`links`]: `liens`,
  [`member`]: `membre`,
  [`messages`]: `messages`,
  [`message traits`]: `caractéristiques de message`,
  [`operation traits`]: `caractéristiques d'opération`,
  [`parameter definitions`]: `définitions de paramètre`,
  [`parameters`]: `paramètres`,
  [`path`]: `chemin`,
  [`properties`]: `propriétés`,
  [`provider organizations`]: `organisations de type fournisseur`,
  [`request bodies`]: `corps de demande`,
  [`response definitions`]: `définitions de réponse`,
  [`responses`]: `réponses`,
  [`role`]: `rôle`,
  [`scope`]: `portée`,
  [`scopes`]: `portées`,
  [`schemas`]: `schémas`,
  [`schema definition`]: `définition de schéma`,
  [`schemes list`]: `liste des schémas`,
  [`security definitions`]: `Définitions de sécurité`,
  [`security schemes`]: `schémas de sécurité`,
  [`servers`]: `Serveurs`,
  [`services`]: `services`,
  [`tags`]: `étiquettes`,
  [`tags and external documentation`]: `balises et documentation externe`,
  [`target services`]: `services cible`,
  [`tasks`]: `tasks`,
  [`user registries`]: `registres d'utilisateurs`,
  [`HTTP header`]: `En-tête HTTP`,
  [`You currently don’t have any application authentication source.`]: `Actuellement, nous ne disposez d'aucune source d'authentification d'application.`,
  [`You currently don’t have any API or product.`]: `Vous ne disposez actuellement d'aucune API ni d'aucun produit.`,
  [`You currently don’t have any API user registries`]: `Vous ne disposez actuellement d'aucun registre d'utilisateurs d'API`,
  [`You currently don’t have any APIs.`]: `Vous ne disposez actuellement d'aucune API.`,
  [`You currently don’t have any TLS client profiles`]: `Vous ne disposez actuellement d'aucun profil de client TLS`,
  [`You currently don’t have any base endpoints.`]: `Vous ne disposez actuellement d'aucun noeud final de base.`,
  [`You currently don’t have any catalog properties.`]: `Actuellement, vous ne disposez d'aucune propriété de catalogue.`,
  [`You currently don’t have any categories.`]: `Actuellement, vous ne disposez d'aucune catégorie.`,
  [`You currently don’t have any certificates.`]: `Actuellement, vous ne disposez d'aucun certificat.`,
  [`You currently don’t have any channels.`]: `Actuellement, vous ne disposez d'aucun canal.`,
  [`You currently don’t have any consumer organizations.`]: `Vous ne disposez actuellement d'aucune organisation de type consommateur.`,
  [`You currently don’t have any consumer organizations or groups.`]: `Vous ne disposez actuellement d'aucun(e) groupe ou organisation de type consommateur.`,
  [`You currently don’t have any consumes.`]: `Actuellement, vous ne disposez d'aucune consommation.`,
  [`You currently don’t have any correlation ids.`]: `Actuellement, vous ne disposez d'aucun ID de corrélation.`,
  [`You currently don’t have any cors policy.`]: `Actuellement, vous ne disposez d'aucune règle CORS.`,
  [`You currently don’t have any definitions.`]: `Actuellement, vous ne disposez d'aucune définition.`,
  [`You currently don’t have any examples.`]: `Actuellement, vous ne disposez d'aucun exemple.`,
  [`You currently don’t have any gateway services.`]: `Vous ne disposez actuellement d'aucun service de passerelle.`,
  [`You currently don’t have any groups.`]: `Vous ne disposez actuellement d'aucun groupe.`,
  [`You currently don’t have any headers.`]: `Actuellement, vous ne disposez d'aucun en-tête.`,
  [`You currently don’t have any keystore.`]: `Actuellement, vous ne disposez d'aucun fichier de clés.`,
  [`You currently don’t have any links.`]: `Actuellement, vous ne disposez d'aucun lien.`,
  [`You currently don’t have any messages.`]: `Vous ne disposez actuellement d'aucun message.`,
  [`You currently don’t have any message traits.`]: `Actuellement, vous ne disposez d'aucune caractéristique de message.`,
  [`You currently don’t have any OAuth providers`]: `Vous ne disposez actuellement d'aucun fournisseur OAuth`,
  [`You currently don’t have any operation traits.`]: `Actuellement, vous ne disposez d'aucune caractéristique d'opération.`,
  [`You currently don’t have any parameter definitions.`]: `Actuellement, vous ne disposez d'aucune définition de paramètre.`,
  [`You currently don’t have any parameters.`]: `Vous ne disposez actuellement d'aucun paramètre.`,
  [`You currently don’t have any preserved request header.`]: `Actuellement, vous ne disposez d'aucun en-tête de demande conservé.`,
  [`You currently don’t have any preserved response header.`]: `Actuellement, vous ne disposez d'aucun en-tête de réponse conservé.`,
  [`You currently don’t have any produces.`]: `Actuellement, vous ne disposez d'aucune production.`,
  [`You currently don’t have any properties.`]: `Vous ne disposez actuellement d'aucune propriété.`,
  [`You currently don’t have any provider organizations.`]: `Vous ne disposez actuellement d'aucune organisation de type fournisseur.`,
  [`You currently don’t have any request bodies.`]: `Actuellement, vous ne disposez d'aucun corps de demande.`,
  [`You currently don’t have any response definitions.`]: `Actuellement, vous ne disposez d'aucune définition de réponse.`,
  [`You currently don’t have any responses.`]: `Actuellement, vous ne disposez d'aucune réponse.`,
  [`You currently don’t have any scopes.`]: `Vous ne disposez actuellement d'aucune portée.`,
  [`You currently don’t have any schemas.`]: `Actuellement, vous ne disposez d'aucun schéma.`,
  [`You currently don’t have any schemes list.`]: `Actuellement, vous ne disposez d'aucune liste de schémas.`,
  [`You currently don’t have any security definitions.`]: `Vous ne disposez actuellement d'aucune définition de sécurité.`,
  [`You currently don’t have any security schemes.`]: `Actuellement, vous ne disposez d'aucun schéma de sécurité.`,
  [`You currently don’t have any servers.`]: `Actuellement, vous ne disposez d'aucun serveur.`,
  [`You currently don’t have any services.`]: `Vous ne disposez actuellement d'aucun service.`,
  [`You currently don’t have any tags.`]: `Actuellement, vous ne disposez d'aucune balise.`,
  [`You currently don’t have any tags and external documentation.`]: `Vous ne disposez actuellement d'aucune balise ou documentation externe.`,
  [`You currently don’t have any target services.`]: `Actuellement, vous ne disposez d'aucun service cible.`,
  [`You currently don’t have any tasks.`]: `Vous ne disposez actuellement d'aucune tâche.`,
  [`You currently don’t have any truststore.`]: `Actuellement, vous ne disposez d'aucun fichier de clés de certifiées.`,
  [`You currently don’t have any user registries.`]: `Vous n'avez pas de registre d'utilisateurs actuellement.`,
  [`You currently don’t have any extensions.`]: `Vous ne disposez actuellement d'aucune extension.`,
  [`You currently don’t have any HTTP header.`]: `Vous ne disposez actuellement d'aucun en-tête HTTP.`,
  [`Your account is being activated. This may take a while...`]: `Votre compte est en cours d'activation. Cette opération peut prendre un certain temps...`,
  [`all of`]: `tous les`,
  [`any of`]: `l'un des`,
  [`calls`]: `appels`,
  [`ciphers`]: `chiffrements`,
  [`extensions`]: `extensions`,
  [`one of`]: `un parmi`,
  [`shared`]: `partagé`,
  [`stopping`]: `arrêt`,
  [`to:`]: `pour :`,
  [`Key`]: `Clé`,
  [`kind`]: `type`,
  [`Trace`]: `Trace`,
  [`Http Status`]: `Statut HTTP`,
  [`Parsed`]: `Analysé`,
  [`Raw`]: `Brut`,
  [`Showing Full Trace`]: `Affichage de la trace complète`,
  [`Show Entire Trace`]: `Afficher la totalité de la trace`,
  [`Show advanced`]: `Afficher les paramètres avancés`,
  [`Advanced`]: `Avancé`,
  [`Sending Request`]: `Envoi de la demande`,
  [`Please send a request above`]: `Envoyez une demande ci-dessus`,
  [`Please send a request above.`]: `Envoyez une demande ci-dessus.`,
  [`Authentication Type`]: `Type d'authentification`,
  [`You don't currently have any response`]: `Vous ne disposez actuellement d'aucune réponse`,
  [`You don't currently have any trace.`]: `Vous ne disposez actuellement d'aucune trace.`,
  [`No response received`]: `Aucune réponse reçue`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Si vous cliquez sur "Ouvrir le lien pour accepter l'exception", le serveur s'ouvrira dans un nouvel onglet. Si le navigateur affiche un problème lié au certificat, vous pouvez l'accepter et revenir ici pour procéder à de nouveaux tests.`,
  [`Open link to accept exception`]: `Ouvrir le lien pour accepter l'exception`,
  [`Back to {name}`]: `Retour à {name}`,
  [`0 seconds`]: `0 seconde`,
  [`close.menu`]: `Menu Fermer`,
  [`open.menu`]: `Menu Ouvrir`,
  [`Referenced from "{ref}"`]: `Référencé depuis "{ref}"`,
  [`Go to {definition}`]: `Aller à {definition}`,
  [`General`]: `Général`,
  [`Servers`]: `Serveurs`,
  [`External Docs`]: `Documentation externe`,
  [`Components`]: `Composants`,
  [`Schemas`]: `Schémas`,
  [`Responses`]: `Réponses`,
  [`Examples`]: `Exemples`,
  [`Request Bodies`]: `Corps de demande`,
  [`Security Schemes`]: `Schémas de sécurité`,
  [`Links`]: `Liens`,
  [`Callbacks`]: `Rappels`,
  [`Terms of Service`]: `Conditions du service`,
  [`Contact Name`]: `Nom du contact`,
  [`Contact URL`]: `Adresse URL du contact`,
  [`Contact Email`]: `Adresse électronique du contact`,
  [`Contact sales`]: `Contacter le service commercial`,
  [`License Name`]: `Nom de la licence`,
  [`License URL`]: `Adresse URL de la licence`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Langage d'acceptation`,
  [`Accept MTOM/XOP optimized messages`]: `Accepter les messages optimisés MTOM/XOP`,
  [`User-Agent`]: `Agent d'utilisateur`,
  [`token`]: `jeton`,
  [`Tag`]: `Balise`,
  [`Request Body`]: `Corps de demande`,
  [`Security Requirements`]: `Exigences liées à la sécurité`,
  [`Located In`]: `Situé dans`,
  [`Example Value`]: `Exemple de valeur`,
  [`External Value`]: `Valeur externe`,
  [`Server`]: `Serveur`,
  [`Server Description`]: `Description du serveur`,
  [`Server Variables`]: `Variables du serveur`,
  [`Format`]: `Format`,
  [`Pattern`]: `Masque`,
  [`Multiple Of`]: `Multiple de`,
  [`Multiple non null slicing arguments`]: `Plusieurs arguments de tranche non null`,
  [`Missing slicing arguments`]: `Arguments de tranche manquants`,
  [`Missing sized fields`]: `Zones dimensionnées manquantes`,
  [`Maximum`]: `Maximum`,
  [`Exclusive Maximum`]: `Maximum exclusif`,
  [`Minimum`]: `Minimum`,
  [`Exclusive Minimum`]: `Minimum exclusif`,
  [`Max Length`]: `Longueur max.`,
  [`Min Length`]: `Longueur min.`,
  [`Max Items`]: `Nb max. d'éléments`,
  [`Min Items`]: `Nb min. d'éléments`,
  [`Unique Items`]: `Eléments uniques`,
  [`Max Properties`]: `Nb max. de propriétés`,
  [`Min Properties`]: `Nb min. de propriétés`,
  [`Required Properties`]: `Propriétés requises`,
  [`Required Property Name`]: `Nom de la propriété requise`,
  [`Enum`]: `Enum`,
  [`Enum Value`]: `Valeur Enum`,
  [`Nullable`]: `NULL admis`,
  [`Read Only`]: `En lecture seule`,
  [`Write Only`]: `Ecriture seule`,
  [`api`]: `api`,
  [`version`]: `version`,
  [`username`]: `nom d'utilisateur`,
  [`password`]: `mot de passe`,
  [`service`]: `service`,
  [`Allows Empty Value`]: `Autorise une valeur vide`,
  [`Style`]: `Style`,
  [`Explode`]: `Exploser`,
  [`Allow Reserved`]: `Autoriser la réservation`,
  [`Allow WebSocket Upgrade`]: `Autoriser la mise à niveau de socket Web`,
  [`Ref`]: `Réf.`,
  [`Operation ID`]: `ID de l'opération`,
  [`Parameter`]: `Paramètre`,
  [`Gateway and portal settings`]: `Paramètres de passerelle et de portail`,
  [`Targets`]: `Cibles`,
  [`Catalog Properties`]: `Propriétés de catalogue`,
  [`catalog properties`]: `propriétés de catalogue`,
  [`Attachments`]: `Pièces jointes`,
  [`Activity Log`]: `Journal d'activité`,
  [`API Type`]: `Type d'API`,
  [`Phase`]: `Phase`,
  [`Enable CORS`]: `Activer CORS`,
  [`CORS Policy`]: `Stratégie CORS`,
  [`Allowed Origins`]: `Origines autorisées`,
  [`Allow Credentials`]: `Autoriser les données d'identification`,
  [`ID`]: `ID`,
  [`Success Content`]: `Contenu de la réussite`,
  [`Error Content`]: `Contenu de l'erreur`,
  [`Attachment`]: `Pièce jointe`,
  [`Link`]: `Lien`,
  [`Operation Ref`]: `Référence de l'opération`,
  [`TRACE`]: `TRACE`,
  [`No Security Requirements available`]: `Aucune exigence de sécurité disponible`,
  [`Create a security scheme`]: `Créer un schéma de sécurité`,
  [`Create a Security Requirement`]: `Créer une exigence de sécurité`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Exiger l'une des exigences de sécurité suivantes. Si la case n'est pas cochée, la sécurité est facultative.`,
  [`Require wrappers on fault details specified by type`]: `Exiger des encapsuleurs sur les détails d'erreur spécifiés par type`,
  [`There are no "Servers"`]: `Aucun Serveur`,
  [`Write`]: `Ecrire`,
  [`Server Variable`]: `Variable de serveur`,
  [`Security Requirement`]: `Exigences de sécurité`,
  [`Security Scheme Type`]: `Type de schéma de sécurité`,
  [`Security Scheme`]: `Schéma de sécurité`,
  [`OAuth Flows`]: `Flux OAuth`,
  [`Implicit Flow`]: `Flux implicite`,
  [`Password Flow`]: `Flux de mot de passe`,
  [`Client Credentials Flow`]: `Flux de données d'identification client`,
  [`Authorization Code Flow`]: `Flux de codes d'autorisation`,
  [`Refresh URL`]: `URL d'actualisation`,
  [`schema`]: `schéma`,
  [`Allow Empty Value`]: `Autoriser une valeur vide`,
  [`Encoding`]: `Codage`,
  [`This field is required`]: `Cette zone est obligatoire`,
  [`Temporary token`]: `Jeton temporaire`,
  [`Access token`]: `Jeton d'accès`,
  [`ID token`]: `Jeton d'identification`,
  [`No Security Schemes available`]: `Aucun schéma de sécurité disponible`,
  [`Security Scheme (Key)`]: `Schéma de sécurité (clé)`,
  [`There are no "{label}"`]: `Aucun(e) "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `Vous devez sélectionner au moins un schéma pour définir une exigence de sécurité`,
  [`Select one or more Security Schemes`]: `Sélectionnez un ou plusieurs schémas de sécurité`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `Aucune portée OAuth n'est disponible pour le schéma de sécurité "{key}"`,
  [`Create a Scope`]: `Créer une portée`,
  [`Remove this Security Requirement`]: `Retirer cette exigence de sécurité`,
  [`Edit this Security Requirement`]: `Editer cette exigence de sécurité`,
  [`Invalid Security Scheme Reference`]: `Référence de schéma de sécurité non valide`,
  [`The following Security Schemes do not exist!`]: `Les schémas de sécurité suivants n'existent pas.`,
  [`Edit source`]: `Editer la source`,
  [`OR`]: `OU`,
  [`Required Security Schemes`]: `Schémas de sécurité requis`,
  [`Optional Security`]: `Sécurité facultative`,
  [`Optional custom expression (one per line)`]: `Expression personnalisée facultative (une par ligne)`,
  [`Flow Type`]: `Type de flux`,
  [`OAuth2 Security Scheme`]: `Schéma de sécurité OAuth2`,
  [`Only source editing is supported.`]: `Seule l'édition de la source est prise en charge.`,
  [`Unsupported Field.`]: `Zone non prise en charge.`,
  [`Select an option`]: `Sélectionner une option`,
  [`Select {labelText}`]: `Sélectionner {labelText}`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" est déjà utilisé`,
  [`Referenced from "{originalRef}"`]: `Référencé depuis "{originalRef}"`,
  [`Go to {label}`]: `Accéder à {label}`,
  [`Delete "{label}"`]: `Supprimer "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `Voulez-vous vraiment supprimer "{label}" ?`,
  [`No recent items found`]: `Aucun élément récent trouvé`,
  [`Found {numDocs} results ({took} seconds)`]: `{numDocs} résultats trouvés (en {took} secondes)`,
  [`No Consumer Organizations found`]: `Aucune organisation de type consommateur trouvée`,
  [`No Catalog Products found`]: `Aucun produit de catalogue trouvé`,
  [`No APIs found`]: `Aucune API trouvée`,
  [`No Catalogs found`]: `Aucun catalogue trouvé`,
  [`No Spaces found`]: `Aucun espace trouvé`,
  [`No results found`]: `Aucun résultat trouvé`,
  [`No Catalog APIs found`]: `Aucune API de catalogue trouvée`,
  [`No Catalogs found matching query "{query}"`]: `Aucun catalogue trouvé correspondant à la requête "{query}"`,
  [`No APIs found matching query "{query}"`]: `Aucune API correspondant à la requête "{query}" trouvée`,
  [`No Subscriptions found`]: `Aucun abonnement trouvé`,
  [`No Plans found matching query "{query}"`]: `Aucun plan correspondant à la requête "{query}" trouvé`,
  [`No Subscriptions found matching query "{query}"`]: `Aucun abonnement correspondant à la requête "{query}" trouvé`,
  [`No Catalog Products found matching query "{query}"`]: `Aucun produit de catalogue correspondant à la requête "{query}" trouvé`,
  [`No Catalog APIs found matching query "{query}"`]: `Aucune API de catalogue correspondant à la requête "{query}" trouvée`,
  [`No Consumer Organizations found matching query "{query}"`]: `Aucune organisation de type consommateur correspondant à la requête "{query}" trouvée`,
  [`No Applications found matching query "{query}"`]: `Aucune application correspondant à la requête "{query}" trouvée`,
  [`APIs per page`]: `API par page`,
  [`Catalogs per page`]: `Catalogues par page`,
  [`items per page`]: `Elément(s) par page`,
  [`{min}-{max} of {total} APIs`]: `{min} à {max} API sur {total}`,
  [`{min}-{max} of {total} API`]: `{min} à {max} API sur {total}`,
  [`Products per page`]: `Produits par page`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Le produit doit être publié dans le catalogue pour qu'une nouvelle intégration de facturation puisse être configurée`,
  [`{min}-{max} of {total} Products`]: `{min} à {max} produits sur {total}`,
  [`{min}-{max} of {total} Product`]: `{min} à {max} produit sur {total}`,
  [`Catalog Products per page`]: `Produits de catalogue par page`,
  [`{min}-{max} of {total} Catalog Products`]: `{min} à {max} produits de catalogue sur {total}`,
  [`{min}-{max} of {total} Catalog Product`]: `{min} à {max} produit de catalogue sur {total}`,
  [`Plans per page`]: `Plans par page`,
  [`Policies per page`]: `Stratégies par page`,
  [`{min}-{max} of {total} Plans`]: `{min} à {max} plans sur {total}`,
  [`{min}-{max} of {total} Plan`]: `{min} à {max} plan sur {total}`,
  [`Catalog APIs per page`]: `API de catalogue par page`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min} à {max} API de catalogue sur {total}`,
  [`{min}-{max} of {total} Catalog API`]: `{min} à {max} API de catalogue sur {total}`,
  [`Subscriptions per page`]: `Abonnements par page`,
  [`{min}-{max} of {total} Subscriptions`]: `{min} à {max} abonnements sur {total}`,
  [`{min}-{max} of {total} Subscription`]: `{min} à {max} abonnement sur {total}`,
  [`Consumer Organizations per page`]: `Organisations de type consommateur par page`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min} à {max} organisations de type consommateur sur {total}`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min} à {max} organisation de type consommateur sur {total}`,
  [`Applications per page`]: `Applications par page`,
  [`{min}-{max} of {total} Applications`]: `{min} à {max} applications sur {total}`,
  [`{min}-{max} of {total} Application`]: `{min} à {max} application sur {total}`,
  [`Histories per page`]: `Historique par page`,
  [`{min}-{max} of {total} Histories`]: `{min} à {max} historiques sur {total}`,
  [`{min}-{max} of {total} History`]: `{min} à {max} historique sur {total}`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Aucun plan auquel vous pouvez vous abonner n'a été trouvé. Un produit doit être à l'état "publié" pour qu'un abonnement puisse être créé. Une application ne peut s'abonner qu'à un seul plan dans un produit.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Affichage des API "{draftApiTitle}:{draftApiVersion}" publiées`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Affichage de l'organisation de type consommateur pour "{appTitle}"`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Affichage des abonnements pour "{productTitle}:{productVersion}"`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Affichage des plans pour "{productTitle}:{productVersion}"`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `Affichage des API pour "{productTitle}:{productVersion}"`,
  [`Showing Product for "{planTitle}"`]: `Affichage du produit pour "{planTitle}"`,
  [`Showing APIs for "{planTitle}"`]: `Affichage des API pour "{planTitle}"`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Affichage des abonnements pour le plan "{docTitle}" dans le produit "{docFoundIn}"`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Affichage des plans pour "{apiTitle}:{apiVersion}"`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Affichage des API brouillons pour "{catalogApiTitle}:{catalogApiVersion}"`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Affichage des historiques pour "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Affichage des abonnements pour "{appTitle}"`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Affichage des applications pour "{consumerOrgTitle}"`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Affichage des abonnements pour "{consumerOrgTitle}"`,
  [`Showing Application for selected Subscription`]: `Affichage de l'application pour l'abonnement sélectionné`,
  [`Showing Plan for selected Subscription`]: `Affichage du plan pour l'abonnement sélectionné`,
  [`Showing Product for selected Subscription`]: `Affichage du produit pour l'abonnement sélectionné`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Sélectionner les API brouillons à inclure dans le produit "{productTitle}"`,
  [`Product Version`]: `Version du produit`,
  [`OAuth Providers`]: `Fournisseurs OAuth`,
  [`Application Type`]: `Type d'application`,
  [`Client IDs`]: `ID client`,
  [`Save as New Version`]: `Sauvegarder en tant que nouvelle version`,
  [`Update Selected APIs`]: `Mettre à jour les API sélectionnées`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. L'API {titleAndVersion} sera supprimée définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. Le produit {titleAndVersion} sera supprimé définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. Le produit de catalogue {titleAndVersion} sera supprimé définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. L'organisation de type consommateur {titleAndVersion} sera supprimée définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. Le groupe de consommateurs {titleAndVersion} sera supprimé définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. La stratégie {titleAndVersion} sera supprimée définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. L'abonnement {titleAndVersion} sera supprimé définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. Le catalogue {titleAndVersion} sera supprimé définitivement. Voulez-vous continuer ?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. L'espace {titleAndVersion} sera supprimé définitivement. Voulez-vous continuer ?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `Le produit de catalogue {titleAndVersion} sera republié. Voulez-vous continuer ?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `Le produit de catalogue {titleAndVersion} sera retiré. Voulez-vous continuer ?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `Le produit de catalogue {titleAndVersion} sera déprécié. Voulez-vous continuer ?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `Le produit de catalogue {titleAndVersion} sera reconstitué en préproduction. Voulez-vous continuer ?`,
  [`Put Online`]: `Mettre en ligne`,
  [`Take Offline`]: `Mettre hors ligne`,
  [`View Subscriptions`]: `Afficher les abonnements`,
  [`View Plans`]: `Afficher les plans`,
  [`No Tasks found`]: `Aucune tâche trouvée`,
  [`Tasks per page`]: `Tâches par page`,
  [`{min}-{max} of {total} Tasks`]: `{min} à {max} tâches sur {total}`,
  [`{min}-{max} of {total} Task`]: `{min} à {max} tâche sur {total}`,
  [`Decline and Send`]: `Rejeter et envoyer`,
  [`View Applications`]: `Afficher les applications`,
  [`Publish (Preserve Subscriptions)`]: `Publier (conserver les abonnements)`,
  [`Update APIs`]: `Mettre à jour les API`,
  [`View in Catalogs`]: `Afficher dans les catalogues`,
  [`View Consumer Organization`]: `Afficher l'organisation de type consommateur`,
  [`View Application`]: `Afficher l'application`,
  [`View Plan`]: `Afficher le plan`,
  [`View Product`]: `Afficher le produit`,
  [`Create Subscription`]: `Créer un abonnement`,
  [`No Applications found`]: `Aucune application trouvée`,
  [`Select a Plan to Create a Subscription to`]: `Sélectionnez un plan pour créer un abonnement à`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Cette action est irréversible. L'application {titleAndVersion} sera supprimée définitivement. Voulez-vous continuer ?`,
  [`Save as new version`]: `Enregistrer en tant que nouvelle version`,
  [`Filter selected catalogs`]: `Filtrer les catalogues sélectionnés`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Sélectionner les API brouillons à inclure dans le produit "{productTitle}"`,
  [`Update selected APIs`]: `Mettre à jour les API sélectionnées`,
  [`Catalog API`]: `API de catalogue`,
  [`Catalog APIs`]: `API de catalogue`,
  [`View Products`]: `Afficher les produits`,
  [`View Draft APIs`]: `Afficher les API brouillons`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Affichage des produits faisant référence à "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `Aucun plan trouvé`,
  [`View APIs`]: `Afficher les API`,
  [`Document type tabs`]: `Onglets du type de document`,
  [`No Products found`]: `Aucun produit trouvé`,
  [`Consumer Organization`]: `Organisation de type consommateur`,
  [`Consumer Organizations`]: `Organisations de type consommateur`,
  [`Catalog Product`]: `Produit de catalogue`,
  [`Catalog Products`]: `Produits de catalogue`,
  [`Search for a Product and Plan`]: `Rechercher un produit et un plan`,
  [`OpenAPI Version`]: `Version OpenAPI`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (à partir de REST, GraphQL ou SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (à partir d'une rubrique Kafka)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `Créer AsyncAPI (à partir d'une rubrique Kafka)`,
  [`An error occurred during file upload`]: `Une erreur s'est produite lors du téléchargement de fichier`,
  [`Delete uploaded file`]: `Supprimer le fichier chargé`,
  [`Copied!`]: `Copié`,
  [`Uploading`]: `Téléchargement`,
  [`Validation icon`]: `Icône de validation`,
  [`Validation error`]: `Erreur de validation`,
  [`Uploading image..`]: `Téléchargement d'une image...`,
  [`Unsupported media type {providedFileType}`]: `Type de support non pris en charge {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Sélectionnez un fichier pris en charge {expectedFileTypes} et faites une nouvelle tentative`,
  [`Pasted`]: `Collé`,
  [`Enter the API summary details.`]: `Entrez les détails récapitulatifs de l'API.`,
  [`Define your Kafka cluster connection details.`]: `Définissez les détails de connexion du cluster Kafka.`,
  [`Channel`]: `Canal`,
  [`Describe your Kafka topic and its associated schema.`]: `Décrivez votre rubrique Kafka et le schéma associé.`,
  [`AsyncAPI specification version`]: `Version de la spécification AsyncAPI`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Nom qui décrit votre API et qui aide les développeurs à la reconnaître dans le portail de développeur.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Version de votre définition d'API. La combinaison du nom et de la version identifie de manière unique la définition d'API.`,
  [`Description (optional)`]: `Description (facultatif)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Présentez votre API et indiquez des informations utiles aux développeurs.`,
  [`Summary (optional)`]: `Récapitulatif (facultatif)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Bref récapitulatif des événements contenus dans cette API. 1000 caractères maximum.`,
  [`Bootstrap servers`]: `Serveurs d'amorce`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Liste des courtiers Kafka sur votre cluster, séparés par des virgules.`,
  [`Secure the API using an API Key and Secret`]: `Sécuriser l'API à l'aide d'une clé d'API et d'une valeur confidentielle`,
  [`The credential provided should be in SASL-PLAIN format`]: `Les données d'identification fournies doivent être au format SASL-PLAIN`,
  [`Publish this API:`]: `Publier cette API :`,
  [`Creates a product`]: `Crée un produit`,
  [`Associates this API to the product`]: `Associe cette API au produit`,
  [`Publish the product to the Sandbox catalog`]: `Publier le produit dans le catalogue Sandbox`,
  [`Associates the product to the test app`]: `Associe le produit à l'application de test`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Un schéma au format "{fileType}" a été téléchargé et validé.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `Le fichier téléchargé n'est pas un schéma Avro valide et ne sera pas inclus dans votre API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Réponse du serveur : "{err}". Cliquez sur "Précédent" pour revenir à la page de définition de l'API.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Réponse du serveur : "{err}". Cliquez sur "Terminé" pour quitter l'assistant.`,
  [`Topic name`]: `Nom de rubrique`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `Nom de la rubrique à exposer dans le cadre de cette API. Une rubrique est un flux de messages nommé.`,
  [`Schema for message body in .avsc format (optional)`]: `Schéma du corps du message au format .avsc (facultatif)`,
  [`Drag and drop files here, or click to upload`]: `Glissez-déposez les fichiers ici ou cliquez pour télécharger`,
  [`About this step`]: `A propos de cette étape`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Les événements peuvent être décrits comme des API asynchrones car les consommateurs reçoivent des événements dès qu'ils sont disponibles. Les API synchrones, telles que REST, ne reçoivent des réponses que lorsqu'elles en font la demande.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Les API asynchrones gérées par des événements sont documentées à l'aide de la spécification AsyncAPI.`,
  [`API secret`]: `Valeur confidentielle de l'API`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Définition AsyncAPI 2.1.0 générée`,
  [`API creating`]: `API créant`,
  [`An error occurred while creating your document`]: `Une erreur s'est produite lors de la création de votre document`,
  [`Publishing your API`]: `Publication de votre API`,
  [`Your API has been published`]: `Votre API a été publiée`,
  [`An error occurred while publishing your API`]: `Une erreur s'est produite lors de la publication de votre API`,
  [`Configure security for this API`]: `Configurer la sécurité pour cette API`,
  [`Cluster connection security`]: `Sécurité de connexion au cluster`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Spécifiez les données d'identification que les services de passerelle d'événements doivent utiliser pour se connecter à votre cluster lors du traitement des demandes adressées à cette API. Vous pouvez modifier ces valeurs ultérieurement dans l'éditeur. Pour plus d'informations, voir la documentation sur la création des données d'identification.`,
  [`SASL Username`]: `Nom d'utilisateur SASL`,
  [`SASL Password`]: `Mot de passe SASL`,
  [`Transport CA certificate (.pem file - optional)`]: `Certificat CA de transport (fichier .pem-facultatif)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `S'il est fourni, il est utilisé par le service de passerelle d'événements pour fournir un chiffrement TLS pour les communications entre la passerelle et le cluster Kafka.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Décrivez les détails de l'API de votre source d'événements Apache Kafka pour générer un document AsyncAPI.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `Vous pouvez ensuite publier le document AsyncAPI pour exposer la source d'événements aux développeurs d'applications, qui peuvent configurer leurs applications pour s'abonner au flux d'événements lié.`,
  [`Set up security for your API if you want to control who can access it.`]: `Configurez la sécurité de votre API si vous souhaitez contrôler qui peut y accéder.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `L'accès à votre API est géré par un service de passerelle d'événements qui vérifie une clé d'API et un secret valides.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Les passerelles exigent que les clients chiffrent leurs communications avec TLS.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Une fois l'API créée, cliquez sur 'Editer l'API' pour ajouter plus de détails, tels que les détails de sécurité pour vous connecter à votre cluster Kafka à partir de votre service de passerelle d'événements.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `Vous pouvez ensuite publier votre API, la mettre à la disposition des développeurs qui pourront découvrir et demander l'accès à l'API. Puis, à l'aide de ces informations, ils pourront paramétrer leurs applications pour qu'elles consomment depuis la source d'événements.`,
  [`Pending backend response`]: `Réponse de système backend en attente`,
  [`Complete`]: `Terminé`,
  [`Rate limiting is only applied to enforced APIs`]: `La limitation de débit concerne uniquement les API appliquées`,
  [`No Plans Available`]: `Aucun plan disponible`,
  [`Modal content is not provided`]: `Le contenu modal n'est pas fourni`,
  [`Modal heading is not provided`]: `L'en-tête modal n'est pas fourni`,
  [`Edit Rate Limit`]: `Editer la limite de débit`,
  [`External Documentation`]: `Documentation externe`,
  [`No Label Available`]: `Aucun libellé disponible`,
  [`optional`]: `facultatif`,
  [`Uploading image...`]: `Téléchargement d'une image...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Joindre des fichiers en les faisant glisser, en les déposant, en les sélectionnant ou en les collant.`,
  [`Response name`]: `Nom de réponse`,
  [`Click Add to add a new {resource}.`]: `Cliquez sur Ajouter pour ajouter une nouvelle/de nouveaux {resource}.`,
  [`Schema Name (Key)`]: `Nom de schéma (clé)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Espace de nom`,
  [`Attribute`]: `Attribut`,
  [`Wrapped`]: `Encapsulé`,
  [`Example`]: `Exemple`,
  [`Example Name (Key)`]: `Nom d'exemple (clé)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" est déjà utilisé`,
  [`Name (Key)`]: `Nom (clé)`,
  [`Content Type (Key)`]: `Type de contenu (clé)`,
  [`Header name`]: `Nom de l'en-tête`,
  [`Reference`]: `Référence`,
  [`Security Scheme Name (Key)`]: `Nom du schéma de sécurité (clé)`,
  [`Choose a Reference`]: `Choisissez une référence`,
  [`Reference from`]: `Référence de`,
  [`Content Type`]: `Type de contenu`,
  [`array`]: `tableau`,
  [`boolean`]: `boolean`,
  [`integer`]: `entier`,
  [`number`]: `nombre`,
  [`object`]: `objet`,
  [`string`]: `chaîne`,
  [`query`]: `requête`,
  [`cookie`]: `cookie`,
  [`matrix`]: `matrice`,
  [`label`]: `libellé`,
  [`simple`]: `simple`,
  [`form`]: `formulaire`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `Fournisseur OAuth (nom)`,
  [`Authenticate using User Registry (name)`]: `Authentification à l'aide du registre d'utilisateurs (nom)`,
  [`Bearer Format`]: `Format de support`,
  [`Server Variable Name`]: `Nom de la variable de serveur`,
  [`Verb (Key)`]: `Instruction (clé)`,
  [`Parameter Definitions`]: `Définitions des paramètres`,
  [`Path Parameters`]: `Paramètres de chemin`,
  [`Media Type List`]: `Liste des types de support`,
  [`Medium`]: `Moyen`,
  [`Schemes List`]: `Liste des schémas`,
  [`Small`]: `Petit`,
  [`Response Definitions`]: `Définitions de réponse`,
  [`Terms Of Service`]: `Conditions du service`,
  [`Url`]: `Url`,
  [`Untitled Parameter`]: `Paramètre sans titre`,
  [`Parameter Name`]: `Nom du paramètre`,
  [`Mime Type`]: `Type Mime`,
  [`Async API`]: `API asynchrone`,
  [`Channels`]: `Canaux`,
  [`Operation Traits`]: `Caractéristiques d'opération`,
  [`Messages`]: `Messages`,
  [`Correlation IDs`]: `ID de corrélation`,
  [`Traits`]: `Caractéristiques`,
  [`Message Traits`]: `Caractéristiques de message`,
  [`Bindings`]: `Liaisons`,
  [`Server Bindings`]: `Liaisons serveur`,
  [`Channel Bindings`]: `Liaisons canal`,
  [`Operation Bindings`]: `Liaisons opération`,
  [`Message Bindings`]: `Liaisons message`,
  [`Protocol Version`]: `Version de protocole`,
  [`Define multiple messages`]: `Définition de plusieurs messages`,
  [`Schema Format`]: `Format de schéma`,
  [`Unsupported Field`]: `Zone non prise en charge`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Caractéristique opération`,
  [`Message Trait`]: `Caractéristique message`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `La stratégie de permutation d'opération permet d'appliquer une section de l'assemblage à une opération spécifique.`,
  [`HTML page`]: `Page HTML`,
  [`Rule`]: `Règle`,
  [`Origin`]: `Origine`,
  [`Property Name (Key)`]: `Nom de propriété (clé)`,
  [`Catalog Name (Key)`]: `Nom de catalogue (clé)`,
  [`Property Overrides`]: `Remplacements de propriété`,
  [`Property Name`]: `Nom de la propriété`,
  [`Property Value`]: `Valeur de propriété`,
  [`About subscriptions and plans`]: `A propos des abonnements et des plans`,
  [`Subscription Plan Name`]: `Nom du plan d'abonnement`,
  [`Select plan`]: `Sélectionner un plan`,
  [`Select the plan to migrate application subscriptions from`]: `Sélectionnez le plan pour la migration des abonnements à une application à partir de`,
  [`Select subscriptions`]: `Sélectionner des abonnements`,
  [`Select the application subscriptions to migrate`]: `Sélectionnez les abonnements d'application à migrer`,
  [`Select the product to migrate application subscriptions to`]: `Sélectionner le produit pour la migration des abonnements à une application vers`,
  [`Select the plan to migrate application subscriptions to`]: `Sélectionner le plan pour la migration des abonnements à une application vers`,
  [`Message successfully sent to {sent} owner(s)`]: `Message envoyé à {sent} propriétaire(s)`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} messages n'a pas pu être envoyés`,
  [`Failed email addresses`]: `Adresses e-mail en échec`,
  [`Failed to save the API`]: `Echec de la sauvegarde de l'API`,
  [`and {numExtra} more...`]: `et {numExtra} de plus...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Copier dans le presse-papiers la liste des adresses électroniques faisant l'objet d'un échec d'envoi`,
  [`From`]: `Depuis`,
  [`Message Owner`]: `Propriétaire de message`,
  [`Message owner`]: `Propriétaire de message`,
  [`Message owners`]: `Propriétaires de message`,
  [`Message group`]: `Groupe de messages`,
  [`Select consumer organizations`]: `Sélectionner des organisations de type consommateur`,
  [`Message consumer organization owners`]: `Propriétaires d'organisations de type consommateur de message`,
  [`Message consumer organization owner`]: `Propriétaire de l'organisation consommatrice de messages`,
  [`Message provider organization owner`]: `Propriétaire d'organisation de type fournisseur de message`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `Identificateur du cluster Kafka associé à cette règle d'appel`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Liste d'hôtes séparés par des virgules : paires de ports à utiliser pour établir des connexions au cluster Kafka`,
  [`The username to use when connecting to the Kafka cluster.`]: `Nom d'utilisateur à utiliser lors de la connexion au cluster Kafka.`,
  [`The password to use when connecting to the Kafka cluster.`]: `Mot de passe à utiliser lors de la connexion au cluster Kafka.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Service de passerelle d'événements`,
  [`Event gateway management client keystore`]: `Fichier de clés client de gestion de passerelle d'événements`,
  [`Edit Event gateway Service`]: `Editer le service de passerelle d'événements`,
  [`Configure Event Gateway Service`]: `Configurer le service de passerelle d'événements`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Configurer un service de passerelle d'événements pour la sécurisation et l'application des API asynchrones`,
  [`Catalogs with published product`]: `Catalogues avec produit publié`,
  [`Cluster Config ID`]: `ID configuration du cluster`,
  [`SASL mechanism`]: `Mécanisme SASL`,
  [`Security protocol`]: `Protocole de sécurité`,
  [`Transport CA certificate`]: `Certificat CA de transport`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `La taille maximale du fichier est de {size}. Les types de fichier pris en charge sont json, xml, yml, yaml et zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Les types de fichier pris en charge sont json, xml, yml et yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Les types de fichier pris en charge sont json, wsdl, xml, xsd, yml, yaml et zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Les types de fichier pris en charge sont json, wsdl, xml, yml et yaml.`,
  [`Select a file`]: `Sélectionner un fichier`,
  [`or specify a file URL`]: `ou spécifier une URL de fichier`,
  [`Generated OpenAPI {version} definition`]: `Définition OpenAPI {version} générée`,
  [`User registry type`]: `Type de registre d'utilisateurs`,
  [`User registry types`]: `Types de registre d'utilisateurs`,
  [`User registry type {title} has been created.`]: `Le type de registre d'utilisateurs {title} a été crée.`,
  [`User registry type {title} has been updated.`]: `Le type de registre d'utilisateurs {title} a été mis à jour.`,
  [`Failed to create user registry type {title}.`]: `Echec de la création du type de registre d'utilisateurs {title}.`,
  [`Failed to update user registry type {title}.`]: `Echec de la mise à jour du type de registre d'utilisateurs {title}.`,
  [`Failed to update user registry {title}.`]: `Echec de la mise à jour du registre d'utilisateurs {title}.`,
  [`Custom user registry`]: `Registre d'utilisateurs personnalisé`,
  [`Create custom user registry`]: `Créer un registre d'utilisateurs personnalisé`,
  [`Edit custom user registry`]: `Editer le registre d'utilisateurs personnalisé`,
  [`Create user registry type`]: `Créer un type de registre d'utilisateurs`,
  [`Edit user registry type`]: `Editer le type de registre d'utilisateurs`,
  [`Configure user authentication using a custom external user registry`]: `Configurer l'authentification d'utilisateur à l'aide d'un registre d'utilisateurs externe personnalisé`,
  [`Secured Endpoint`]: `Noeud final sécurisé`,
  [`Configuration schema`]: `Schéma de configuration`,
  [`About custom user registries`]: `A propos des registres d'utilisateurs personnalisés`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Les registres d'utilisateurs personnalisés peuvent être utilisés pour authentifier les utilisateurs dans le portail de développeur, mais ne peuvent pas être utilisés pour sécuriser les API. Pour configurer un registre d'utilisateurs personnalisé en tant que ressource dans API Manager, le répertoire utilisateur externe doit être créé et disponible afin d'être utilisé avec votre écosystème API Connect.`,
  [`Update Wsdl`]: `Mettre à jour Wsdl`,
  [`Update Existing WSDL service`]: `Mettre à jour le service WSDL existant`,
  [`Format of selected WSDL has been successfully validated`]: `Le format du WSDL sélectionné a été validé`,
  [`Select the target WSDL file to update from`]: `Sélectionner le fichier WSDL cible à mettre à jour depuis`,
  [`Selected WSDL has been successfully uploaded`]: `Le fichier WSDL sélectionné a été téléchargé`,
  [`Services available in selected API`]: `Services disponibles dans l'API sélectionnée`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Activer l'éditeur Open API existant pour les documents OpenAPI 2.0 (déprécié)`,
  [`Unknown`]: `Inconnu`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleCatalogCount} catalogue(s) masqué(s) en raison d'une incompatibilité avec le type de passerelle spécifié dans l'API ou le produit`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} espace(s) masqué(s) en raison d'une incompatibilité avec le type de passerelle spécifié dans l'API ou le produit`,
  [`Keep in mind`]: `Remarque`,
  [`Only one type of enforced APIs is allowed per product`]: `Un seul type d'API appliquée est autorisé par produit`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `Il n'est pas possible de sélectionner {incompatibleAPIsCount} API en raison d'une incompatibilité avec le type de passerelle spécifié dans le produit`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `Il n'est pas possible de sélectionner {incompatibleProductsCount} produit(s) en raison d'une incompatibilité avec le type de passerelle spécifié dans le produit`,
  [`Unenforced`]: `Non appliquée`,
  [`Create a new product by adding APIs and plans.`]: `Créez un produit en ajoutant des API et des plans.`,
  [`Rate limits are only applied to enforced APIs.`]: `Les limites de débit sont appliquées uniquement aux API appliquées.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Ajoutez des plans et des limites d'API à votre produit. Les limites de plan s'appliquent uniquement aux API appliquées ; les API non appliquées ne seront pas limitées.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Ajoutez des plans et des limites de débit à votre produit. Les plans ne s'appliquent qu'aux API appliquées ; les API non appliquées ne seront pas limitées.`,
  [`Delete plan`]: `Supprimer le plan`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Sélectionnez le service de passerelle d'événements des API asynchrones. Pour tous les autres types d'API, sélectionnez DataPower API Gateway ou la version compatible v5. Pour mettre à jour ce paramètre, supprimez d'abord les API associées.`,
  [`Edit API list`]: `Editer la liste des API`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Les API appliquées de ce produit exigent plusieurs types de passerelle.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `Le type {apiType} appliqué dans ce produit exige {gatewayType}.`,
  [`OpenAPI 2.0 API(s)`]: `API OpenAPI 2.0`,
  [`OpenAPI 3.0 API(s)`]: `API OpenAPI 3.0`,
  [`AsyncAPI(s)`]: `AsyncAPI`,
  [`Select the gateway type for this Product`]: `Sélectionnez le type de passerelle pour ce produit`,
  [`Added default plan`]: `Plan par défaut ajouté`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Publiez le produit dans le catalogue de bac à sable. Cela associe le produit à l'application de test.`,
  [`cluster`]: `cluster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Message du rejecteur`,
  [`Vendor extensions feature disabled`]: `La fonctionnalité des extensions de fournisseur est désactivée`,
  [`No configured gateways found`]: `Aucune passerelle configurée détectée`,
  [`No OpenAPI extensions associated with the configured gateway`]: `Aucune extension OpenAPI n'est associée à la passerelle configurée`,
  [`Extension`]: `Extension`,
  [`Select the extensions to add to the API document`]: `Sélectionnez les extensions à ajouter au document d'API`,
  [`(Operations: {operations})`]: `(Opérations : {operations})`,
  [`Failed to get application`]: `Echec de l'obtention de l'application`,
  [`Failed to get credentials for application`]: `Echec de l'obtention des données d'identification de l'application`,
  [`Failed to get published product`]: `Echec de l'obtention du produit publié`,
  [`Failed to get the published API`]: `Echec de l'obtention de l'API publiée`,
  [`API must be activated to use the Test tool`]: `L'API doit être activée pour utiliser l'outil de test`,
  [`Import from Asset repository`]: `Importer à partir du référentiel d'actifs`,
  [`Untitled {componentLabel}`]: `Sans titre {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Votre métrique de licence nécessite un service d'analyse pour suivre l'utilisation. Cliquez sur "Enregistrement de service" pour configurer un service d'analyse.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Tous les services de passerelle DataPower nécessitent un service d'analyse pour suivre l'utilisation. Cliquez sur "Association d'un service d'analyse" pour que chaque passerelle respecte les dispositions de la licence.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Le catalogue est incompatible avec le type de passerelle spécifié dans l'API ou le produit`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `L'espace est incompatible avec le type de passerelle spécifié dans l'API ou le produit`,
  [`Preserve Subscription`]: `Conserver l'abonnement`,
  [`Publish validation`]: `Publier la validation`,
  [`Publish validations`]: `Validations de publication`,
  [`Edit publish validations`]: `Editer les validations de publication`,
  [`The following validations will be performed when publishing a product`]: `Les validations suivantes seront effectuées lors de la publication d'un produit`,
  [`There are no publish validations enabled`]: `Aucune validation de publication activée`,
  [`Publish validations have been updated`]: `Les validations de publication ont été mises à jour`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Fournissez l'autorité de certification (CA) que le service de passerelle d'événements utilise pour faire confiance au certificat du serveur Kafka. Si le serveur utilise un certificat émis par une autorité bien connue, vous n'avez pas besoin de fournir l'autorité de certification.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `La valeur entrée pour les serveurs d'amorçage n'est pas valide. Entrez l'hôte et le port de chaque courtier Kafka de votre cluster, séparés par des virgules. Par exemple, broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `Télécharger l'extension OpenAPI`,
  [`Add OpenAPI Extension`]: `Ajouter l'extension OpenAPI`,
  [`Gateway Endpoint`]: `Noeud final de passerelle`,
  [`API Dashboard`]: `Tableau de bord des API`,
  [`API call data`]: `Données d'appel API`,
  [`Product Dashboard`]: `Tableau de bord produit`,
  [`API Product consumption metrics`]: `Métriques de consommation de produit API`,
  [`Monitoring Latency Dashboard`]: `Tableau de bord de temps de surveillance des temps d'attente`,
  [`Data about the time taken to serve API calls`]: `Données sur le temps nécessaire au traitement des appels API`,
  [`Monitoring Status Dashboard`]: `Tableau de bord de surveillance du statut`,
  [`API Error and success information`]: `Informations sur les réussites et les erreurs des API`,
  [`Usage Dashboard`]: `Tableau de bord de l'utilisation`,
  [`Usage for gateway {gateway}`]: `Utilisation pour la passerelle {gateway}`,
  [`Latency for gateway {gateway}`]: `Temps d'attente pour la passerelle {gateway}`,
  [`Most popular products, APIs and applications`]: `Produits, API et applications les plus courants`,
  [`Consumption Dashboard`]: `Tableau de bord de consommation`,
  [`Total API volume useful for auditing and compliance`]: `Volume d'API total utile pour l'audit et la conformité`,
  [`Monthly Summary by Status Code`]: `Récapitulatif mensuel par code de statut`,
  [`Monthly Summary Data by Status Code`]: `Données récapitulatives mensuelles par code de statut`,
  [`Daily Summary by Status Code`]: `Récapitulatif quotidien par code de statut`,
  [`Daily Summary Data by Status Code`]: `Données récapitulatives quotidiennes par code de statut`,
  [`Response time (ms)`]: `Temps de réponse (ms)`,
  [`Datetime`]: `Datetime`,
  [`Transaction ID:`]: `ID de transaction :`,
  [`Other`]: `Autre`,
  [`Date time`]: `Date et heure`,
  [`Total calls`]: `Nombre total d'appels`,
  [`Date`]: `Date`,
  [`Month`]: `Mois`,
  [`Minimum response time`]: `Temps de réponse minimal`,
  [`Maximum response time`]: `Temps de réponse maximal`,
  [`Average response time`]: `Temps de réponse moyen`,
  [`Total API calls`]: `Nombre total d'appels API`,
  [`Total APIs`]: `Nombre total d'API`,
  [`APIs called`]: `API appelées`,
  [`Total products`]: `Nombre total de produits`,
  [`products called`]: `produits appelés`,
  [`Total errors`]: `Nombre total d'erreurs`,
  [`Status codes (detailed)`]: `Codes de statut (détaillés)`,
  [`Response time percentiles`]: `Percentiles des temps de réponse`,
  [`API calls per day`]: `Appels API par jour`,
  [`Time zone:`]: `Fuseau horaire :`,
  [`Time zone`]: `Fuseau horaire`,
  [`Toggle axes`]: `Basculer les axes`,
  [`Datetime:`]: `Date/heure :`,
  [`datetime per 30 seconds`]: `Date-heure par 30 secondes`,
  [`Datetime (local time)`]: `Date/heure (heure locale)`,
  [`Data usage (bytes)`]: `Utilisation des données (octets)`,
  [`Time to Serve Request`]: `Délai de traitement de la demande`,
  [`API name`]: `Nom de l'API`,
  [`Product name`]: `Nom du produit`,
  [`Data usage (bytes received)`]: `Utilisation des données (octets reçus)`,
  [`Data usage (bytes sent)`]: `Utilisation des données (octets envoyés)`,
  [`Response times (>1s)`]: `Temps de réponse (> 1s)`,
  [`milliseconds`]: `millisecondes`,
  [`errors`]: `erreurs`,
  [`Success Rate`]: `Taux de réussite`,
  [`Errors Data`]: `Données d'erreurs`,
  [`Success Data`]: `Données de réussite`,
  [`Total consumer organizations`]: `Nombre total d'organisations de type consommateur`,
  [`making API calls`]: `Création d'appels API`,
  [`Applications per plan`]: `Applications par plan`,
  [`Top products by calls`]: `Principaux produits par appels`,
  [`Top APIs by calls`]: `Principales API par appels`,
  [`Top client IP addresses by calls`]: `Principales adresses IP client par appels`,
  [`Client IP address`]: `Adresse IP du client`,
  [`Top APIs by request size`]: `Principales API par taille de demande`,
  [`Top APIs by response size`]: `Principales API par taille de réponse`,
  [`Top APIs by response time`]: `Principales API par temps de réponse`,
  [`Top applications by calls`]: `Principales applications par appels`,
  [`Dashboards`]: `Tableaux de bord`,
  [`Discover`]: `Détection`,
  [`UTC`]: `Temps Universel Coordonné`,
  [`Local time`]: `Heure locale`,
  [`URI`]: `URI`,
  [`Response code`]: `Code de réponse`,
  [`Total time elapsed (ms)`]: `Temps total écoulé (ms)`,
  [`Timestamp`]: `Timestamp`,
  [`Method`]: `Méthode`,
  [`Gateway IP`]: `Adresse IP de passerelle`,
  [`Gateway Operations Dashboard`]: `Tableau de bord des opérations de passerelle`,
  [`Usage and latency information per gateway`]: `Informations d'utilisation et de temps d'attente par passerelle`,
  [`Transaction ID`]: `ID de transaction`,
  [`Filters`]: `Filtres`,
  [`Fields`]: `Zones`,
  [`Clear all`]: `Tout effacer`,
  [`API Event`]: `événement d'API`,
  [`API Assembly Policy Latencies`]: `Temps d'attente de règles d'assemblage d'API`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `Le diagramme ci-dessous montre le temps d'attente croissant, en millisecondes, lorsque la demande a progressé dans les règles d'assemblage de l'API. Cela peut être utile pour déterminer les points de ralentissement ou les goulots d'étranglement dans l'assemblage API.`,
  [`Collapse`]: `Condenser`,
  [`Expand`]: `Développer`,
  [`enter name`]: `entrez un nom`,
  [`Enter value`]: `Entrez une valeur`,
  [`Time range`]: `Intervalle de temps`,
  [`Select one of the following time range filters`]: `Sélectionnez l'un des filtres de plage de temps suivants`,
  [`Last 1 hour`]: `Dernière heure`,
  [`Last {number} minutes`]: `{number} dernières minutes`,
  [`Last {number} hours`]: `{number} dernières heures`,
  [`Last {number} days`]: `{number} derniers jours`,
  [`Start date`]: `Date de début`,
  [`End date`]: `Date de fin`,
  [`Select from the following dropdowns and enter a value`]: `Sélectionnez parmi les listes déroulantes suivantes et entrez une valeur`,
  [`Select one of the following type filters`]: `Sélectionnez l'un des filtres de type suivants`,
  [`All events`]: `Tous les événements`,
  [`Success only (2xx, 3xx)`]: `Réussite uniquement (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Erreurs uniquement (4xx, 5xx)`,
  [`Import/Export`]: `Importer/Exporter`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Environ {totalItems} résultats ({searchTime} secondes) à {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Environ {totalItems} résultats ({searchTime} secondes) à {time} ({utcTime})`,
  [`API calls`]: `Appels API`,
  [`Export as CSV`]: `Exporter en CSV`,
  [`Export as JSON`]: `Exporter en tant que JSON`,
  [`Export as PNG`]: `Exporter au format PNG`,
  [`Export as JPG`]: `Exporter au format JPG`,
  [`Edit filter`]: `Modifier le filtre`,
  [`Edit query`]: `Éditer la requête`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Cette requête est partagée. Toutes les modifications seront effectuées pour tous les utilisateurs avec lesquels elle est partagée.`,
  [`This is a shared query. This will delete it for all users.`]: `Cette requête est partagée. Cette opération la supprimera pour tous les utilisateurs.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname} a été mise à jour pour tous les utilisateurs de l'organisation fournisseur {org}`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname} a été mise à jour pour tous les utilisateurs de l'espace {space}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname} a été mise à jour pour tous les utilisateurs du catalogue {catalog}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname} a été mise à jour pour tous les utilisateurs du gestionnaire de cloud`,
  [`Stop sharing`]: `Arrêter le partage`,
  [`Share query`]: `Partager la requête`,
  [`Share filter`]: `Partager le filtre`,
  [`You do not have permissions to delete this filter`]: `Vous n'êtes pas autorisé à supprimer ce filtre`,
  [`You do not have permissions to delete this query`]: `Vous ne disposez pas des droits permettant de supprimer cette requête`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Voulez-vous vraiment partager la requête {queryTitle} au niveau de l'organisation fournisseur {org} ?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Voulez-vous vraiment partager la requête {queryTitle} au niveau de l'espace {space} ?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Voulez-vous vraiment partager la requête {queryTitle} au niveau du catalogue {catalog} ?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Voulez-vous vraiment partager la requête {queryTitle} au niveau du cloud ?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Voulez-vous vraiment arrêter de partager la requête {queryTitle} ?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Voulez-vous vraiment arrêter de partager la requête {queryTitle} au niveau de l'organisation fournisseur {org} ?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Voulez-vous vraiment arrêter de partager la requête {queryTitle} au niveau de l'espace {space} ?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Voulez-vous vraiment arrêter de partager la requête {queryTitle} au niveau du catalogue {catalog} ?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Voulez-vous vraiment arrêter de partager la requête {queryTitle} au niveau du cloud ?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Voulez-vous vraiment supprimer la requête {queryTitle} ?`,
  [`At least one query parameter expected`]: `Au moins un paramètre de requête attendu`,
  [`A provided query parameter is incomplete`]: `Un paramètre de requête fourni est incomplet`,
  [`Could not parse query string`]: `Impossible d'analyser la chaîne de requête`,
  [`An unsupported query parameter was provided: {query}`]: `Un paramètre de requête non pris en charge a été fourni : {query}`,
  [`Invalid timeframe value: {value}`]: `Valeur de délai non valide : {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `La valeur de {field} doit respecter la notation de date et d'heure définie dans la section 5.6 de la spécification RFC 3339. Exemples : 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `L'opérateur {operator} n'est pas pris en charge pour la zone {field}`,
  [`The value of {field} must be a number`]: `La valeur de {field} doit être un nombre`,
  [`Delete query`]: `Supprimer la requête`,
  [`Delete filter`]: `Supprimer le filtre`,
  [`Save query`]: `Sauvegarder la requête`,
  [`Save filter`]: `Enregistrer le filtre`,
  [`Save filter as`]: `Enregistrer le filtre sous`,
  [`Save query as`]: `Sauvegarder la requête sous`,
  [`Save as...`]: `Sauvegarder sous...`,
  [`Save as`]: `Sauvegarder sous`,
  [`Paste query string to load the query`]: `Coller la chaîne de requête pour charger la requête`,
  [`Copy or paste filter string to load filters`]: `Copier ou coller une chaîne de filtre pour charger des filtres`,
  [`Query string`]: `Chaîne de requête`,
  [`View filter`]: `Afficher le filtre`,
  [`View filter string`]: `Afficher la chaîne de filtrage`,
  [`Date (local time)`]: `Date (heure locale)`,
  [`Coming soon, a new built in analytics feature`]: `Prochainement, une nouvelle fonction d'analyse intégrée`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `L'analyse aidera à recueillir des informations sur votre environnement d'exécution de l'API, à gérer les niveaux de service, à définir des quotas, à établir des contrôles, à configurer des stratégies de sécurité, à gérer les communautés et à analyser les tendances.`,
  [`Documentation`]: `Documentation`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Ayez une analyse approfondie des types de données d'exécution de l'API et sur la façon d'utiliser les compartiments AWS s3 pour les visualiser.`,
  [`Blog`]: `Blogue`,
  [`Learn about what data is available today and how to access it.`]: `Découvrez quelles données sont disponibles aujourd'hui et comment y accéder.`,
  [`Next steps`]: `Etapes suivantes`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `Le service Secrets Manager sélectionné ne gère aucun certificat.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Un certificat est requis pour sécuriser la connexion entre API Connect et le domaine du noeud final de gestion de passerelle. Des certificats sont également requis pour sécuriser les connexions entre la passerelle et les domaines qu'elle gère. Tous les certificats doivent être stockés dans un service Secrets Manager. Le service fournit un référentiel sécurisé pour les certificats et aide à prévenir les indisponibilités en vous envoyant des notifications lorsque les certificats sont sur le point d'expirer lors de l'intégration au service Event Notifications. Pour commencer,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Des certificats sont requis pour sécuriser les connexions entre la passerelle et les domaines qu'elle gère. Tous les certificats doivent être stockés dans un service Secrets Manager. Le service fournit un référentiel sécurisé pour les certificats et aide à prévenir les indisponibilités en vous envoyant des notifications lorsque les certificats sont sur le point d'expirer lors de l'intégration au service Event Notifications. Pour commencer,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `La modification de Secrets Manager supprimera toutes les affectations de certificats et d'offre groupée de CA existants.`,
  [`Create a [Secrets Manager]({link}) service`]: `Création d'un service [Secrets Manager]({link})`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Importez les certificats requis et les offres groupées CA dans le service Secrets Manager.`,
  [`No Secrets Manager services available`]: `Aucun service Secrets Manager disponible`,
  [`Select Secrets Manager service`]: `Sélectionnez le service Secrets Manager`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Sélectionnez le service [Secrets Manager]({link}) qui gère les certificats pour cette passerelle.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Le service Secrets Manager doit gérer les certificats pour le domaine du nœud final de gestion de passerelle et les domaines que la passerelle gère. En outre, vous devez utiliser IBM Cloud IAM pour autoriser le service d'instance réservé API Connect à accéder au service Secrets Manager.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Sélectionnez ensuite le certificat pour le domaine du noeud final de gestion. Sélectionnez également le bundle d'autorité de certification correspondant si le certificat n'a pas été affecté par une autorité de certification reconnue. Le certificat et l'offre groupée doivent être gérés par le service Secrets Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Indiquez ensuite les domaines qui seront traités par cette passerelle. La technique SNI est utilisée ; par conséquent, plusieurs domaines peuvent être servis via la même adresse IP et le même port sans nécessiter le même certificat. Le format des caractères génériques est pris en charge. Le domaine par défaut (catch-all) de ‘*’ doit être inclus en tant qu'entrée finale dans la table. Entrez d'autres noms de domaine si nécessaire, dans chaque cas, en indiquant un certificat géré par le service Secrets Manager.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `À l'aide de [IBM Cloud IAM ]({link}), autorisez le service d'instance réservé API Connect à accéder au service Secrets Manager.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Mettez à jour les affectations existantes de regroupements de certificats et d'autorités de certifications en les chargeant depuis Secrets Manager.`,
  [`Access s3 data`]: `Accès aux données s3`,
  [`Create resource`]: `Créer une ressource`,
  [`Connect analytics data in s3 bucket with AWS`]: `Connecter les données d'analyse dans un compartiment s3 avec AWS`,
  [`AWS account`]: `Compte AWS`,
  [`AWS account number`]: `Numéro de compte AWS`,
  [`Enter your 12 digit aws account id`]: `Entrez votre ID de compte à 12 chiffres`,
  [`Account id must be 12 digits`]: `L'ID de compte doit être à 12 chiffres`,
  [`Failed to update AWS account id.`]: `Échec de la mise à jour de l'ID de compte AWS.`,
  [`You have limited access to the catalogs.`]: `Vous avez un accès limité aux catalogues.`,
  [`You have limited access to the spaces.`]: `Vous avez un accès limité aux espaces.`,
  [`Contact admin to get more access.`]: `Contactez l'administrateur pour obtenir plus d'accès.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `Vous ne pouvez pas sélectionner ce catalogue. Veuillez contacter un administrateur si vous avez besoin d'un accès.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `Vous ne pouvez pas sélectionner cet espace. Veuillez contacter un administrateur si vous avez besoin d'un accès.`,
  [`You have limited access to the product billing.`]: `Vous avez un accès limité à la facturation des produits.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `L'ID de compte AWS a été mis à jour. Il peut se passer quelques secondes pour accorder l'accès aux données S3.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Une organisation ou un groupe est manquant dans le catalogue choisi.`,
  [`Secrets Manager instance`]: `Instance de Secrets Manager`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Les organisations de consommateurs dont les propriétaires ne possèdent pas d'e-mail ne peuvent pas être sélectionnées`,
  [`You currently don’t have any data.`]: `Vous n'avez pas de données actuellement.`,
  [`You currently don’t have any organizations.`]: `Vous n'avez actuellement aucune organisation.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Demande d'abonnement de l'application {appname} au plan {plan} dans le produit {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Demande de {operation} du produit : {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Demande de mise à niveau de l'application {appname} vers la production`,
  [`Onboarding request for {email}`]: `Demande d'intégration pour {email}`,
  [`Product lifecycle request`]: `Demande de cycle de vie du produit`,
  [`Subscription request`]: `Demande d'abonnement`,
  [`Application lifecycle request`]: `Demande de cycle de vie de l'application`,
  [`Consumer Onboarding request`]: `Demande d'intégration de consommateur`,
  [`Originator`]: `Emetteur`,
  [`Edit analytics service advanced settings`]: `Editer les paramètres avancés du service d'analyse`,
  [`Advanced settings`]: `Paramètres avancés`,
  [`Must be a positive integer`]: `Doit être un entier positif`,
  [`Maximum value is {maxValue}`]: `La valeur maximale est {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `La valeur maximale est {maxValueDays}d ou {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Configurez les paramètres avancés pour le déploiement d'analyse.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `La valeur doit se terminer par 'd' pour les jours et 'h' pour les heures. Exemple : 30d ou 12h.`,
  [`Rollover settings`]: `Paramètres de remise à zéro`,
  [`Retention settings`]: `Paramètres de conservation`,
  [`Delete an index when it meets the following condition:`]: `Supprimer un index lorsqu'il remplit la condition suivante :`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Remise à zéro de l'alias d'écriture avec un nouvel index lorsque l'index géré remplit l'une des conditions suivantes :`,
  [`Minimum document count`]: `Nombre minimal de documents`,
  [`The minimum number of documents required to roll over the index.`]: `Nombre minimal de documents requis pour le report de l'index.`,
  [`Minimum index age`]: `Age minimal de l'index`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Âge minimal requis pour le report de l'index. L'ancienneté de l'index correspond au temps écoulé entre la création de l'index et maintenant. Utilisez 'd' pour les jours et 'h' pour les heures. Par exemple, 30d signifie que l'index sera remis à zéro une fois que son ancienneté aura atteint 30 jours, alors que 96h signifie que l'index sera remis à zéro une fois que son ancienneté aura atteint 96 heures. Les valeurs maximales sont {maxValueDays}d ou {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Age minimal requis pour la suppression de l'index. L'ancienneté de l'index correspond au temps écoulé entre la création de l'index et maintenant. Utilisez 'd' pour les jours et 'h' pour les heures. Par exemple, 30d signifie que l'index sera supprimé une fois que son ancienneté aura atteint 30 jours, alors que 96h signifie que l'index sera supprimé une fois que son ancienneté aura atteint 96 heures. Les valeurs maximales sont {maxValueDays}d ou {maxValueHours}h.`,
  [`Time range:`]: `Intervalle :`,
  [`Time range: Before {datetime}`]: `Période : avant {datetime}`,
  [`Time range: After {datetime}`]: `Période : après {datetime}`,
  [`Filter before`]: `Filtrer avant`,
  [`Filter after`]: `Filtrer après`,
  [`Filter exact`]: `Filtrer exactement`,
  [`Filter gt`]: `Filtrer supérieur`,
  [`Filter lt`]: `Filtrer inférieur`,
  [`Filter equals`]: `Filtrer égal`,
  [`Filter by`]: `Filtrer par`,
  [`Filter out`]: `Retirer`,
  [`Delete failed`]: `Échec de la suppression`,
  [`Delete warning`]: `Avertissement de suppression`,
  [`Could not delete saved query {queryTitle}.`]: `Impossible de supprimer la requête sauvegardée {queryTitle}.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `La demande a répondu avec succès mais {queryTitle} n'a pas encore terminé la suppression.`,
  [`API governance`]: `Gouvernance d'API`,
  [`Something's wrong`]: `Quelque chose ne va pas`,
  [`We were unable to connect to the service.`]: `Nous n'avons pas pu nous connecter au service.`,
  [`Invalid date`]: `Date non valide`,
  [`Invalid time`]: `Heure ou temps non valide`,
  [`End date must be after the start date`]: `La date de fin doit être postérieure à la date de début`,
  [`Duplicate`]: `Dupliquer`,
  [`Duplicate query`]: `Dupliquer la requête`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Voulez-vous vraiment dupliquer {queryTitle} ?`,
  [`No queries saved`]: `Aucune requête sauvegardée`,
  [`No queries shared`]: `Aucune requête partagée`,
  [`To save a query, start by creating one then save.`]: `Pour sauvegarder une requête, commencez par en créer une, puis sauvegardez-la.`,
  [`Share a saved query and allow others to use it.`]: `Partager une requête sauvegardée et autoriser les autres à l'utiliser.`,
  [`Query updated`]: `Requête mise à jour`,
  [`Query update failed`]: `La mise à jour de la requête a échoué`,
  [`Share successful`]: `Partage réussi`,
  [`Share failed`]: `Le partage a échoué`,
  [`Delete successful`]: `Déploiement réussi`,
  [`Query unshared`]: `Partage de la requête révoqué`,
  [`Unshare {queryTitle} failed`]: `La révocation du partage de {queryTitle} a échoué`,
  [`Query copied`]: `Requête copiée`,
  [`Duplicate {queryTitle} failed`]: `La duplication de {queryTitle} a échoué`,
  [`The query {queryTitle} has been updated.`]: `La requête {queryTitle} a été mise à jour.`,
  [`The query {queryTitle} has been shared.`]: `La requête {queryTitle} a été partagée.`,
  [`The query {queryTitle} has been deleted.`]: `La requête {queryTitle} a été supprimée.`,
  [`The query {queryTitle} is no longer shared.`]: `La requête {queryTitle} n'est plus partagée.`,
  [`A copy of {queryTitle} has been created.`]: `Une copie de {queryTitle} a été créée.`,
  [`About queries`]: `A propos des requêtes`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Les requêtes permettent d'optimiser les données d'analyse utilisées dans les tableaux de bord et la vue Discover. Lorsqu'une requête est appliquée, tous les tableaux de bord sont mis à jour pour inclure uniquement les données sélectionnées.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `Il est possible d'utiliser l'un des intervalles de temps prédéfinis ou d'utiliser un intervalle de temps spécifique à la seconde près à l'aide de l'option Personnaliser.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Les zones permettent de spécifier uniquement certaines données à inclure. Il est possible de filtrer en fonction de presque tous les attributs d'un événement d'API.`,
  [`There are then different operators available depending on the field type.`]: `Différents opérateurs sont alors disponibles en fonction du type de zone.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Les zones de type chaîne acceptent les opérateurs equals, not, starts with, ends with et regex.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `Il est possible d'utiliser contains et not contains pour fournir une liste de valeurs pour une zone unique (par exemple, une liste de versions d'API).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `Les zones d'adresse IP acceptent les opérateurs equals et not. Elles autorisent également que la valeur soit spécifiée en notation CIDR afin de permettre l'utilisation de masques de réseau pour sélectionner des réseaux spécifiques.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Les expressions régulières peuvent être très puissantes pour sélectionner des valeurs spécifiques, mais leur exécution est plus lente. Il est donc recommandé d'utiliser d'autres opérateurs tels que 'starts with' et 'ends with' si possible.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Les filtres de requête des différentes zones sont combinés à l'aide de l'opérateur logique AND. Cela signifie que l'ajout de deux filtres pour le même nom de zone ne renvoie que les résultats qui satisfont aux deux filtres.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `Le paramètre de requête {type} est un moyen simple pour spécifier uniquement les événements de réussite ou d'erreur. La zone {statusCode} est utilisée. Si seuls des codes de statut spécifiques sont nécessaires, un filtre de zone peut être utilisé. La valeur des zones {statusCodeTwo} est généralement une chaîne complète telle que {okStatus} et pas seulement le code retour HTTP numérique.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `Il est possible d'enregistrer des requêtes pour les réutiliser ultérieurement et de les partager avec votre niveau de portée en cours pour que d'autres puissent également les utiliser (par exemple, une organisation de type fournisseur ou un catalogue).`,
  [`n/a`]: `N/A`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Remarque : les zones 'ID d'organisation de type fournisseur' et 'Nom d'organisation de type fournisseur' s'affichent dans le contenu de l'événement en tant que {{orgId}} et {{orgName}} respectivement ; les zones 'ID d'organisation de type consommateur' et 'Nom d'organisation de type consommateur' s'affichent en tant que {{developerOrgId}} et {{developerOrgName}}.`,
  [`No authentication token`]: `Aucun jeton d'authentification`,
  [`Contact your administrator for further assistance.`]: `Contactez votre administrateur pour obtenir de l'aide.`
};
