// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2018, 2022
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

import configOptions from 'Uif/apps/configOptions'

const config = configOptions()
if (config.basePath) {
  __webpack_public_path__ = `${config.basePath}/`
}

// vendor dependencies
require('angular'),
require('angular-native-dragdrop'),
require('angular-ui'),
require('angular-ui-bootstrap'),
require('angular-material'),
require('angular-animate'),
(global.markdownit = require('markdown-it')),
require('angular-markdown-it'),
require('material-design-icons-iconfont'),
require('angular-json-schema-form'),
require('./json-editor/dist/jsoneditor'),
require('angular-resource'),
require('angular-sanitize'),
require('ng-resize'),
require('ns-popover'),
require('angular-translate'),
require('ace-builds/src-noconflict/ace'),
require('ace-builds/webpack-resolver'),
require('ace-builds/src-noconflict/ext-searchbox'),
require('ace-builds/src-noconflict/ext-language_tools'),
require('ace-builds/src-noconflict/mode-javascript'),
require('ace-builds/src-noconflict/mode-json'),
require('ace-builds/src-noconflict/mode-xml'),
require('ace-builds/src-noconflict/mode-yaml'),
require('angular-ui-ace'),
require('jquery'),
require('d3'),
require('generate-schema')
require('angular-highlightjs')
require('vkbeautify')

// make some libraries globally available
window.$ = window.jQuery = require('jquery')
window.d3 = require('d3')
window.X2JS = require('x2js')
window.generateSchema = require('generate-schema')
window.exampleGenerator = require('apiconnect-example-generator').exampleGenerator
window.vkbeautify = require('vkbeautify')
window.semver = require('semver')
window.jsep = require('jsep')
window.jseb = require('jseb')

// styles for angular material
require('angular-material/angular-material.scss')
require('bootstrap-css')

// TODO(jtary) - right now this will throw an error due to try
//               to import es5-shim
//require('ace-builds/src-noconflict/worker-javascript')

// dark theme
require('./ace/theme-atom_dark')

// local dependencies
require('./nls/localization.js')

const ngMod = angular.module('apiconnect-assembly', [
  // mod list
  'ang-drag-drop',
  // 'hc.marked',
  'mohsen1.schema-form',
  'apiconnect-assembly.mapper-schema',
  'apiconnect-assembly.recursion-helper',
  'mdMarkdownIt',
  'ngMaterial',
  'ngResource',
  'ngSanitize',
  'ngResize',
  'pascalprecht.translate',
  'apiconnect-assembly-localization',
  'nsPopover',
  'ui.bootstrap',
  'ui.ace',
  'hljs',
])

// application modules
require('./js/assembly/RecursionHelper')
require('./js/assembly/AssemblyServices.js')
require('./js/assembly/AssemblerController.js')
require('./js/assembly/AssemblerDirectives.js')
require('./js/assembly/AssemblerFilters.js')
require('./js/assembly/AssemblerInfoController.js')
require('./js/assembly/SchemaServices.js')
require('./js/assembly/MapperController.js')
require('./js/assembly/ConditionController.js')
require('./js/assembly/ExpressionServices.js')
require('./js/assembly/PolicyFactory.js')
require('./js/assembly/RecursionHelper.js')
require('./js/assembly/TestCallGenerator.js')
require('./js/assembly/AssemblerTestController.js')
require('./js/assembly/conditionBuilder.js')
require('./js/assembly/AssemblerTestComponent.js')
require('./js/filters/TranslateFilters.js')
require('./js/mapper-schema/json-schema-view.js')

// application styles
require('./sass/assembly.scss')
require('./sass/constants.scss')
require('./sass/general.scss')
require('./sass/mapper.scss')

export default ngMod
