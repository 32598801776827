// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

angular.module('apiconnect-assembly').filter('translateMultiple', [
  'translateFilter',
  function(translateFilter) {
    return function(property, values) {
      return translateFilter(property, values)
    }
  },
])

angular.module('apiconnect-assembly').filter('translatePlurality', [
  'translateFilter',
  function(translateFilter) {
    return function(value, property, substituteVar) {
      const substitutions = {}
      if (!substituteVar) {
        substituteVar = '0'
      }
      substitutions[substituteVar] = value
      if (value === 1) {
        return translateFilter(`${property}Singular`, substitutions)
      }
      return translateFilter(`${property}Plural`, substitutions)
    }
  },
])
