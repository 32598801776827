// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `尚未更新目录`,
  [`Catalog has been updated`]: `已更新目录`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `草稿产品 {draftProduct} 必须引用一个或多个 API`,
  [`Number of Calls`]: `调用数`,
  [`Changes will apply to newly published APIs only`]: `更改将仅应用于新发布的 API`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `存在不兼容的 {label}，因为其网关类型不同于此 API。`,
  [`{label} is required`]: `“{label}”是必需的`,
  [`Application is required`]: `“应用程序”是必需的`,
  [`Plan is required`]: `“计划”是必需的`,
  [`Gateway is required`]: `“网关”是必需的`,
  [`Consumer Org is required`]: `“使用者组织”是必需的`,
  [`LDAP ATTRIBUTE NAME`]: `LDAP 属性名称`,
  [`Use the build in Test Application (sandbox only)`]: `使用内置测试应用程序（仅限沙箱）`,
  [`Select an Application`]: `选择应用程序`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `使用内置测试应用程序（仅限沙箱） - 在选择非沙箱目录时会禁用此选项`,
  [`Choose an existing Application`]: `选择现有应用程序`,
  [`Use JWT for gateway authentication to analytics service`]: `使用 JWT 对分析服务进行网关认证`,
  [`Select an application to consume the API`]: `选择要使用该 API 的应用程序`,
  [`Use the default built-in Sandbox Catalog'`]: `使用缺省内置沙箱目录`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注：此目录将用作所有其他首选项的作用域。更改此设置将需要调整其他首选项。`,
  [`Select a Consumer Org`]: `选择使用者组织`,
  [`Select the Consumer Org your generated application will belong to`]: `选择已生成的应用程序所属的使用者组织`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `注：仅当使用内置沙箱测试应用程序时才能更改此选项，因为现有应用程序已属于某个使用者组织`,
  [`Generate auto product`]: `生成自动产品`,
  [`Select a product to associate the current API with`]: `选择要与当前 API 相关联的产品`,
  [`Associate the current API to a selected product`]: `将当前 API 与所选产品相关联`,
  [`API management`]: `API management`,
  [`Select the API management instance you would like this API to be published to.`]: `选择您要将此 API 发布至的 API 管理实例。`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注：所有其他首选项的使用范围将为此实例。 更改此设置将需要调整其他首选项。`,
  [`Provider organization`]: `提供者组织`,
  [`Select the provider organization you would like this API to be published to.`]: `请选择要将此 API 发布至的提供者组织。`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注：所有其他首选项的使用范围将为此组织。 更改此设置将需要调整其他首选项。`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `注：所有其他首选项的使用范围将为此空间。 更改此设置将需要调整其他首选项。`,
  [`Selected a draft product`]: `已选择草稿产品`,
  [`Use any available gateway service`]: `使用任何可用网关服务`,
  [`Select a compatible gateway service`]: `选择兼容的网关服务`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `注：此网关服务将用作所有其他首选项的作用域。将根据此设置来加载策略以及其他特定于网关服务的内容。`,
  [`Select a gateway`]: `选择网关`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `注：当选择“现有产品”选项时会禁用此选项，并对生成的产品改用缺省计划`,
  [`Select a Plan`]: `选择计划`,
  [`Generate subscription`]: `生成预订`,
  [`A subscription will be created between the selected Product plan and Application`]: `将在所选的产品计划和应用程序之间创建预订`,
  [`Apply a rate limit to the generated product`]: `对生成的产品应用速率限制`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `对所生成产品的缺省计划应用速率限制。当选择“现有产品”选项时会禁用此选项，并且改用所选计划中定义的速率限制`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `轻量级目录访问协议 (LDAP) 是一种因特网协议，用于通过网络访问和维护分布式目录信息服务。 如果启用了企业 LDAP 服务，请将其配置为用于提供用户认证的资源。 如果您还希望为新的 Developer Portal 用户提供用户入门，那么必须完成“属性映射”部分才能启用可写 LDAP。 在此部分中，选中“用户管理”复选框，然后提供从源 LDAP 属性名称到目标 API Connect 值的映射。`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `启用任务自核准将允许任务的发起方和协调人员核准任务。在其他核准者不可用时，此选项非常方便，但允许有效绕过其他用户的检查。`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `计费集成将 API Connect 连接到第三方预订计费系统中的帐户，该系统用于管理客户、其付款方式、发票开具和活动预订`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `本机 OAuth 提供者对象提供用于 OAuth 处理操作（例如，生成和验证 OAuth 令牌）的设置。 OAuth 安全性定义可引用 OAuth 提供者对象来保护 API。 在使用本机 OAuth 提供者时，API Connect 会以本机方式执行 OAuth 操作。 每个 OAuth 提供者对象都有一个支持 API。 此处的配置将自动更新 API 的 Swagger 文档。 您可以通过浏览至“API 编辑器”页面来编辑 Swagger 文档。 在已发布的 API 引用 OAuth 提供者对象时，将自动在网关中提供支持 API。`,
  [`About token management`]: `关于令牌管理`,
  [`About user security`]: `关于用户安全性`,
  [`APIs or products`]: `API 或产品`,
  [`ATM is not deployed, please contact your administrator`]: `ATM 尚未部署，请联系您的管理员`,
  [`Application state change approvals will be enabled`]: `将启用应用程序状态变更核准`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `定义要用于抽取应用程序用户凭证、认证其身份和授予权限的设置。`,
  [`See console for details.`]: `请参阅控制台以获取详细信息。`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `所选证书管理器服务不管理任何证书。`,
  [`The selected product is not published to the Sandbox catalog`]: `所选产品未发布至沙箱目录`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `所选产品未发布至沙箱目录，因此无法预订该应用程序`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `针对您平台的 IBM Cloud CLI（如果先前未安装）。`,
  [`(Only supported by TLS 1.3)`]: `（仅受 TLS 1.3 支持）`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `此字段上的“@listSize”伪指令定义了不返回列表的大小字段。`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `此字段上的“@listSize”伪指令定义了未在该字段的返回类型上定义的大小字段。`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `此字段上的“@listSize”伪指令定义了未在该字段上定义的切割自变量。`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `此字段上的“@listSize”伪指令定义了类型不是“Int”或“Int!”的切割自变量。.`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `此字段上的“@listSize”伪指令定义了多个非空切割自变量。`,
  [`"{name}" (Role) has been created`]: `已创建“{name}”（角色）。`,
  [`"{name}" (Role) has been updated`]: `已更新“{name}”（角色）。`,
  [`API ({api}) has been created.`]: `已创建 API ({api})。`,
  [`API ({api}) has been renamed.`]: `已重命名 API ({api})。`,
  [`API ({api}) has been updated.`]: `已更新 API ({api})。`,
  [`API ({api}) has been deleted.`]: `已删除 API ({api})。`,
  [`API ({api}) has been published.`]: `已发布 API ({api})。`,
  [`API ({api}) has validation error.`]: `API ({api}) 存在验证错误`,
  [`API ({api}) has validation errors.`]: `API ({api}) 存在验证错误`,
  [`API version`]: `API 版本`,
  [`APIM data version`]: `APIM 数据版本`,
  [`APIM schema update date`]: `APIM 模式更新日期`,
  [`APIM schema version`]: `APIM 模式版本`,
  [`APIM target data version`]: `APIM 目标数据版本`,
  [`APIM target schema version`]: `APIM 目标模式版本`,
  [`APIM update date`]: `APIM 更新日期`,
  [`Product ({product}) has been created.`]: `已创建产品 ({product})。`,
  [`Product ({product}) has been updated.`]: `已更新产品 ({product})。`,
  [`Product ({product}) has been delete.`]: `已删除产品 ({product})。`,
  [`Product ({product}) has been published.`]: `已发布产品 ({product})。`,
  [`"{title}" ({type}) has been {changedName}.`]: `“{title}”（{type}）已{changedName}。`,
  [`"{title}" ({type}) has not been {changedName}!`]: `“{title}”（{type}）尚未{changedName}！`,
  [`({units} {timeSuffix} after being queued)`]: `（排队后 {units} {timeSuffix}）`,
  [`({units} {timeSuffix} after being sent)`]: `（发送后 {units} {timeSuffix}）`,
  [`(none)`]: `（无）`,
  [`(optional)`]: `（可选）`,
  [`*Base endpoint list empty`]: `*基本端点列表为空`,
  [`*Must be a valid url`]: `*必须是有效的 URL`,
  [`*System will also send an email notification to the requester.`]: `*系统还将向请求者发送电子邮件通知。`,
  [`+ redact from...`]: `+ 编辑位置...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `。还可以在目录中管理此产品的生命周期。`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x 后跟 64 个十六进制字符`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) 拥有并管理 API 使用者组织 2) 管理应用程序开发人员社区 3) 编写 API 和定义产品 4) 管理 API 产品生命周期`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) 拥有并管理 API 提供者组织 2) 管理应用程序开发人员社区 3) 编写 API 和定义产品 4) 管理 API 产品生命周期`,
  [`5 Most Active APIs`]: `前 5 大热门 API`,
  [`5 Most Active Products`]: `前 5 大热门产品`,
  [`5 most active APIs`]: `前 5 大热门 API`,
  [`5 most active Products`]: `前 5 大热门产品`,
  [`503 Service Unavailable`]: `503 服务不可用`,
  [`A request to the server was made without any credentials.`]: `在未使用任何凭证的情况下向服务器发出了一个请求。`,
  [`A server error occurred`]: `发生服务器错误`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `在作为客户机连接到其他系统时，TLS 客户机概要文件会配置 API Connect 使用的证书链和密码套件。`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `在向其他系统提供服务器端点时，TLS 服务器概要文件会配置 API Connect 使用的证书链和密码套件。`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `目录会托管 API 产品的集合，其在发布后在关联的 Developer Portal 中可见`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `目录会托管 API 产品的集合，其在发布后在关联的 Developer Portal 中可见`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `目录表示受管 API 产品的集合。在与目录相关联的 Developer Portal 上，已发布的产品对使用者可见。`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `需要证书来保护 API Connect 与网关管理端点的域之间的连接。此外，还需要证书来保护网关与其处理的域之间的连接。所有证书都必须存储在证书管理器服务中。该服务为证书提供安全存储库，并通过在证书即将到期时向您发送通知来帮助防止停运。要开始使用此产品，请执行下列操作：`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `在未定义映射的输入时将使用的缺省值。`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `网关服务表示一组网关服务器或容器，它们托管已发布的 API 并提供客户机应用程序使用的 API 端点。网关会执行对后端系统的 API 代理调用并实施 API 策略，包括客户机识别、安全性和速率限制。`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `网关主机发布 API 并提供客户机应用程序所使用的 API 端点。网关会执行对后端系统的 API 代理调用并实施 API 策略，包括客户机识别、安全性和速率限制。`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `可以在源产品上设置迁移目标以定义预订的迁移目标。迁移目标包含一个计划映射，用于描述源产品上的计划到目标产品上的计划的映射。迁移目标在 Developer Portal 中可见，这样便可以将迁移目标传达给源产品的订户。`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `模块化 OAuth 策略套件。它可在一个策略中执行所有 OAuth/OpenID Connect 协议步骤（缺省情况）；也可拆分为多个策略，一次仅执行一个或“n”个步骤，以便进行更精细的控制。每个步骤的输入和输出由记录的上下文变量驱动。请根据需要添加或移除受支持的 OAuth 组件。`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `无可用门户网站服务，无法创建门户网站。请与云管理员联系以获取支持。`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `提供者组织是拥有 API 及关联计划和产品的团队。提供者组织需要有一个已获得全部许可权的所有者。从成员中指定所有者。`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `提供者组织包含负责管理 API、产品、计划、目录、使用者组织和其他相关资源的用户。您可以使用提供者组织按项目、部门、生命周期阶段等对用户和 API 管理资源进行分组。要管理和分配用于监管用户特权的角色，请转至 IBM Cloud Identity and Access Management。`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `解析为输出值的简单 JavaScript 表达式。它可以是静态字符串（“my static string”），或涉及任何映射的输入的表达式 ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `操作`,
  [`ANALYTICS`]: `分析`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `API 管理员`,
  [`API CONNECT VALUE`]: `API Connect 值`,
  [`API assemble`]: `API 组合`,
  [`API base endpoints`]: `基本 API 端点`,
  [`API base URL`]: `API 基本 URL`,
  [`API base URLs`]: `API 基本 URL`,
  [`API Calls`]: `API 调用`,
  [`API Connect UI`]: `API Connect UI`,
  [`API Connect services`]: `API Connect 服务`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect 需要使用电子邮件服务器来向成员发送邀请和激活链接以及实现其他目的。`,
  [`API Connect verify token`]: `API Connect 验证令牌`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `API Designer 许可证`,
  [`API Designer credentials`]: `API Designer 凭证`,
  [`API Designer licence`]: `API Designer 许可证`,
  [`API Designer does not currently support WSDL`]: `API Designer 当前不支持 WSDL`,
  [`API editor`]: `API 编辑器`,
  [`API Endpoint`]: `API 端点`,
  [`API Endpoint Base`]: `基本 API 端点`,
  [`API Endpoint for Unenforced APIs`]: `未实施的 API 的 API 端点`,
  [`API Endpoint for unenforced APIs`]: `未实施的 API 的 API 端点`,
  [`API Endpoints`]: `API 端点`,
  [`API Error`]: `API 错误`,
  [`API gateway`]: `API Gateway`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API Gateway`,
  [`API Gateways`]: `API Gateway`,
  [`API Invocation Endpoint`]: `API 调用端点`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `未发布 API。不会启用 Explorer 中的 API 测试（试用）。`,
  [`API Key`]: `API 密钥`,
  [`API key`]: `API 密钥`,
  [`API key secret`]: `API 密钥私钥`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `API Manager 本地用户注册表`,
  [`API Manager URL`]: `API Manager URL`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `API 路径参数`,
  [`API Security Definition`]: `API 安全性定义`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `由于未实施此 API，因此将不会为其启用 Explorer 中的 API 测试（试用）。`,
  [`API Setup`]: `API 设置`,
  [`API Subscription`]: `API 预订`,
  [`API endpoint`]: `API 端点`,
  [`API endpoint base`]: `基本 API 端点`,
  [`API endpoint for unenforced APIs`]: `未实施的 API 的 API 端点`,
  [`API endpoints`]: `API 端点`,
  [`API error`]: `API 错误`,
  [`API invocation endpoint`]: `API 调用端点`,
  [`API is not enforced on the API gateway.`]: `未在 API 网关上实施 API。`,
  [`API path parameters`]: `API 路径参数`,
  [`API security definition`]: `API 安全性定义`,
  [`API setup`]: `API 设置`,
  [`API subscription`]: `API 预订`,
  [`API Update`]: `API 更新`,
  [`API user registries`]: `API 用户注册表`,
  [`API and product generated successfully!`]: `已成功生成 API 和产品！`,
  [`API deletion failed`]: `API 删除失败`,
  [`API does not exist!`]: `API 不存在！`,
  [`API from existing REST service`]: `来自现有 REST 服务的 API`,
  [`API from existing SOAP service`]: `来自现有 SOAP 服务的 API`,
  [`API has been updated.`]: `已更新 API。`,
  [`API has been updated`]: `API 已更新`,
  [`API has not been created!`]: `尚未创建 API！`,
  [`API has not been updated!`]: `尚未更新 API！`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `API 优先级（使用 OpenAPI 定义所定义的虚拟端点）`,
  [`API properties`]: `API 属性`,
  [`API protection source`]: `API 保护源`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `从产品引用的 API 不存在。请首先创建或导入该 API。`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `使用 OpenAPI 模式定义编写 API 请求和响应有效内容结构。`,
  [`API subscription request approved for app {{appName}}`]: `针对应用程序 {appName} 核准了 API 预订请求`,
  [`API subscription request denied for app {{appName}}`]: `针对应用程序 {appName} 拒绝了 API 预订请求`,
  [`API subscription request received for app {{appName}}`]: `针对应用程序 {appName} 收到了 API 预订请求`,
  [`API type`]: `API 类型`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `在“apis”属性下不存在密钥为“{key}”的 API，因此无法列出该 API。请选中“源”选项卡。`,
  [`APIs`]: `API`,
  [`APIs and products`]: `API 和产品`,
  [`APIs included in the plan.`]: `此计划中包含的 API。`,
  [`API Key is created`]: `已创建 API 密钥`,
  [`API Key is deleted`]: `已删除 API 密钥`,
  [`API Key is successfully created`]: `已成功创建 API 密钥`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `API 密钥超时为 **{seconds} 秒 ({time})**`,
  [`API key timeout`]: `API 密钥超时`,
  [`API Key timeout has been changed`]: `API 密钥超时已更改`,
  [`Application type`]: `应用程序类型`,
  [`Associated analytics service`]: `关联的分析服务`,
  [`AVAILABILITY`]: `可用性`,
  [`AVAILABILITY ZONE`]: `可用性区域`,
  [`About`]: `关于`,
  [`About audit setting`]: `关于审计设置`,
  [`About Authentication URL user registry`]: `关于认证 URL 用户注册表`,
  [`About availability zones`]: `关于可用性区域`,
  [`About catalogs`]: `关于目录`,
  [`About consumer organization owners`]: `关于使用者组织所有者`,
  [`About consumer organizations`]: `关于使用者组织`,
  [`About developer portals`]: `关于 Developer Portal`,
  [`About email servers`]: `关于电子邮件服务器`,
  [`About Endpoints`]: `关于端点`,
  [`About gateways`]: `关于网关`,
  [`About gateway visibility`]: `关于网关可视性`,
  [`About Gateway Processing Status`]: `关于网关处理状态`,
  [`About introspection`]: `关于自省`,
  [`About Keystores`]: `关于密钥库`,
  [`About LDAP`]: `关于 LDAP`,
  [`About local user registries`]: `关于本地用户注册表`,
  [`About metadata`]: `关于元数据`,
  [`About Migration Target`]: `关于迁移目标`,
  [`About migration targets`]: `关于迁移目标`,
  [`About native OAuth provider`]: `关于本机 OAuth 提供者`,
  [`About OpenID Connect`]: `关于 OpenID Connect`,
  [`About replacing a product`]: `关于替换产品`,
  [`About Role defaults`]: `关于角色缺省值`,
  [`About roles`]: `关于角色`,
  [`About scopes`]: `关于作用域`,
  [`About Set Migration Targets`]: `关于设置迁移目标`,
  [`About spaces`]: `关于空间`,
  [`About Subscription`]: `关于预订`,
  [`About superseding a product`]: `关于取代产品`,
  [`About TLS server profile`]: `关于 TLS 服务器概要文件`,
  [`About TLS client profiles`]: `关于 TLS 客户机概要文件`,
  [`About TLS server profiles`]: `关于 TLS 服务器概要文件`,
  [`About third party OAuth provider`]: `关于第三方 OAuth 提供者`,
  [`About tokens`]: `关于令牌`,
  [`About Transfer Ownership`]: `关于移交所有权`,
  [`About Transferring Ownership`]: `关于移交所有权`,
  [`About Truststores`]: `关于信任库`,
  [`About adding a member`]: `关于添加成员`,
  [`About adding provider organization member`]: `关于添加提供者组织成员`,
  [`About adding members to a Space`]: `关于向空间添加成员`,
  [`About adding members to a catalog`]: `关于向目录添加成员`,
  [`About changing owners`]: `关于更改所有者`,
  [`About enabling gateway services`]: `关于启用网关服务`,
  [`About inviting a member`]: `关于邀请成员`,
  [`About inviting catalog members`]: `关于邀请目录成员`,
  [`About inviting provider organization members`]: `关于邀请提供者组织成员`,
  [`About inviting space members`]: `关于邀请空间成员`,
  [`About keystores`]: `关于密钥库`,
  [`About managing Space membership`]: `关于管理空间成员资格`,
  [`About migrating subscriptions`]: `关于迁移预订`,
  [`About provider organizations`]: `关于提供者组织`,
  [`About roles and role defaults`]: `关于角色和角色缺省值`,
  [`About roles in spaces`]: `关于空间中的角色`,
  [`About selecting gateway services`]: `关于选择网关服务`,
  [`About send message`]: `关于发送消息`,
  [`About sending messages`]: `关于发送消息`,
  [`About the analytics service`]: `关于分析服务`,
  [`About the DNS Scheme`]: `关于 DNS 方案`,
  [`About the Gateway Service`]: `关于网关服务`,
  [`About the Portal Service`]: `关于门户网站服务`,
  [`About the product lifecycle`]: `关于产品生命周期`,
  [`About the provider organization owner`]: `关于提供者组织所有者`,
  [`About this Cloud`]: `关于本云`,
  [`About transferring ownership`]: `关于移交所有权`,
  [`About truststores`]: `关于信任库`,
  [`About visibility`]: `关于可视性`,
  [`About visibility and subscribability`]: `关于可视性和可预订性`,
  [`Accept`]: `接受`,
  [`Access Code`]: `访问代码`,
  [`Access Token`]: `访问令牌`,
  [`Access URL through Secure Gateway`]: `通过 Secure Gateway 访问 URL`,
  [`Access code`]: `访问代码`,
  [`Access code is not chosen in supported grant type.`]: `未在受支持的授权类型中选择访问代码。`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `通过 Secure Gateway 访问 URL。API 由“已登台”状态变为“已发布”状态时将设置此网关。`,
  [`Access token TTL has been changed`]: `访问令牌 TTL 已更改`,
  [`Access token time to live`]: `访问令牌生存时间`,
  [`Access token time-to-live`]: `访问令牌生存时间`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `访问令牌将于 **{seconds} 秒 ({time})** 后到期`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `向客户机应用程序授予访问令牌，以允许此应用程序代表应用程序用户访问资源。在当前访问令牌变为无效或到期时，或者要获取具有相同或更小作用域的其他访问令牌，可以向客户机发布刷新令牌以获取新的访问令牌。而且，对于由任意数量的访问令牌和刷新令牌组合形成的许可范围，您都可以指定有效时间。`,
  [`Access tokens time to live (seconds)`]: `访问令牌生存时间（秒）`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `访问令牌、标识令牌和临时令牌可以分配给任何现有密钥库。将保留密钥库分配的历史记录。`,
  [`Account`]: `帐户`,
  [`Account has been updated.`]: `已更新帐户。`,
  [`Account password has been changed.`]: `已更改帐户密码。`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `操作`,
  [`Activate API`]: `激活 API`,
  [`Activate API|button text`]: `激活 API`,
  [`Activate API for testing|title`]: `激活 API`,
  [`Activation link`]: `激活链接`,
  [`Active`]: `活动`,
  [`Activity log`]: `活动日志`,
  [`Activity log API setting is not available for this API's gateway type.`]: `活动日志 API 设置不适用于此 API 的网关类型。`,
  [`Active loading indicator`]: `活动装入指示符`,
  [`Add`]: `添加`,
  [`Add allowlist`]: `添加允许列表`,
  [`Add a new billing integration in Resources`]: `在资源中添加新的计费集成`,
  [`Add API user registries`]: `添加 API 用户注册表`,
  [`Add APIs to Product`]: `将 API 添加到产品`,
  [`Add another cloud`]: `添加另一个云`,
  [`Add billing integration`]: `添加计费集成`,
  [`Add Burst Limit`]: `添加突发流量限制`,
  [`Add Ciphers for TLS client profile`]: `为 TLS 客户机概要文件添加密码`,
  [`Add Ciphers for TLS server profile`]: `为 TLS 服务器概要文件添加密码`,
  [`Add Condition`]: `添加条件`,
  [`Add Consumer Role`]: `添加使用者角色`,
  [`Add Count Limit`]: `添加计数限制`,
  [`Add Credential`]: `添加凭证`,
  [`Add domain`]: `添加域`,
  [`Add credential`]: `添加凭证`,
  [`Add Extension`]: `添加扩展`,
  [`Add GraphQL schema`]: `添加 GraphQL 模式`,
  [`Add HTTP Endpoint`]: `添加 HTTP 端点`,
  [`Add group`]: `添加组`,
  [`Add JSON schema`]: `添加 JSON 模式`,
  [`Add Keystore to TLS client profile`]: `将密钥库添加到 TLS 客户机概要文件`,
  [`Add Keystore to TLS server profile`]: `将密钥库添加到 TLS 服务器概要文件`,
  [`Add member`]: `添加成员`,
  [`Add New Role for Consumer organization`]: `为使用者组织添加新角色`,
  [`Add operation`]: `添加操作`,
  [`Add Parameters`]: `添加参数`,
  [`Add Policy`]: `添加策略`,
  [`Add Rate limit`]: `添加速率限制`,
  [`Add Rate Limit`]: `添加速率限制`,
  [`Add role`]: `添加角色`,
  [`Add Syslog TCP Endpoint`]: `添加 Syslog TCP 端点`,
  [`Add Syslog TLS Endpoint`]: `添加 Syslog TLS 端点`,
  [`Add Syslog UDP Endpoint`]: `添加 Syslog UDP 端点`,
  [`Add XML schema`]: `添加 XML 模式`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `添加要对此目录中的 API 调用可用的网关端点。`,
  [`Add a different Cloud Connection.`]: `添加另一个云连接。`,
  [`Add a member from the user registry`]: `从用户注册表添加成员`,
  [`Add a user to the Admin organization, and assign the required roles`]: `将用户添加到管理员组织，然后分配所需的角色`,
  [`Add a user to the provider organization, and assign the required roles`]: `将用户添加到提供者组织，然后分配所需的角色`,
  [`Add a user to the Catalog, and assign the required roles`]: `将用户添加到目录，然后分配所需的角色`,
  [`Add a user to the Space, and assign the required roles`]: `将用户添加到空间，然后分配所需的角色`,
  [`Add action`]: `添加操作`,
  [`Add additional client ID/client secret pairs`]: `添加其他客户机标识/客户机密钥对`,
  [`Add blocklist`]: `添加阻止列表`,
  [`Add case`]: `添加案例`,
  [`Add catch`]: `添加捕获`,
  [`Add default catch`]: `添加缺省值捕获`,
  [`Add destination`]: `添加目标`,
  [`Add entry`]: `添加条目`,
  [`Add group failed.`]: `添加组失败。`,
  [`Add input`]: `添加输入`,
  [`Add media type`]: `添加介质类型`,
  [`Add member failed`]: `添加成员失败`,
  [`Add object`]: `添加对象`,
  [`Add otherwise`]: `添加“否则”条件`,
  [`Add output`]: `添加输出`,
  [`Add outputs for operation...`]: `添加操作输出...`,
  [`Add parameters for operation...`]: `添加操作参数...`,
  [`Add parameters to this API`]: `向此 API 添加参数`,
  [`Add plans to product`]: `向产品添加计划`,
  [`Add plans to this product`]: `向此产品添加计划`,
  [`Add schema`]: `添加模式`,
  [`Add scopes for this OAuth provider.`]: `为此 OAuth 提供者添加作用域。`,
  [`Add scopes to allow access to`]: `添加作用域以允许访问`,
  [`Add scopes to allow access to.`]: `添加作用域以允许访问。`,
  [`Add tags and external documentation details for this API`]: `添加此 API 的标记和外部文档详细信息`,
  [`Add to`]: `添加到`,
  [`Add to existing product`]: `添加到现有产品`,
  [`Add/Remove APIs`]: `添加/移除 API`,
  [`AddProperties`]: `添加属性`,
  [`Added APIs`]: `已添加 API`,
  [`Added rate limit`]: `已添加速率限制`,
  [`Added rate limits`]: `已添加速率限制`,
  [`Adding an API to a product for publishing`]: `正在将 API 添加到产品以进行发布`,
  [`Additional support`]: `其他支持`,
  [`Additional properties`]: `其他属性`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `另外，如果关联的注册表（即 {name} 目录用户注册表）未使用，请考虑移除该注册表。`,
  [`Address`]: `地址`,
  [`Admin DN`]: `管理 DN`,
  [`Admin organization invitation timeout`]: `管理组织邀请超时`,
  [`Admin organization invitation timeout has been changed`]: `管理组织邀请超时已更改`,
  [`Admin password`]: `管理员密码`,
  [`Admin request reset password`]: `管理员请求重置密码`,
  [`Admin reset password`]: `管理员重置密码`,
  [`Admin Sign In`]: `管理登录`,
  [`Admin add catalog failed`]: `管理员添加目录失败`,
  [`Admin add space failed`]: `管理员添加空间失败`,
  [`Administer consumer organizations`]: `管理使用者组织`,
  [`Administers the API consumer organization`]: `管理 API 使用者组织`,
  [`Administers the API provider organization`]: `管理 API 提供者组织`,
  [`Administers the admin organization`]: `管理“管理员组织”`,
  [`Administers the admin topology`]: `管理管理员拓扑`,
  [`Administers the app developer organization`]: `管理应用程序开发人员组织`,
  [`Administers the catalog`]: `管理目录`,
  [`Administers the cloud topology`]: `管理云拓扑`,
  [`Administers the space`]: `管理空间`,
  [`Administration management console`]: `管理管理控制台`,
  [`Administrator`]: `管理员`,
  [`Advanced analytics configuration`]: `高级 Analytics 配置`,
  [`Advanced features`]: `高级功能`,
  [`Advanced scope check`]: `高级作用域检查`,
  [`Advanced scope check after Token Validation`]: `令牌验证后进行高级作用域检查`,
  [`Advanced scope check before Token Generation`]: `令牌生成前进行高级作用域检查`,
  [`Aggregation`]: `汇总`,
  [`Agree`]: `同意`,
  [`All`]: `全部`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `注册 Developer Portal 的使用者组织中所有已认证的开发人员都可以查看此产品。`,
  [`All consumer organizations will be able to see this product.`]: `所有使用者组织都能够查看此产品。`,
  [`Allowlist`]: `允许列表`,
  [`Allow Chunked Uploads`]: `允许分块上载`,
  [`Allow chunked uploads`]: `允许分块上载`,
  [`Allow "plain" challenge method`]: `允许“纯文本”提问方法`,
  [`Allow renegotiation`]: `允许重新协商`,
  [`Allow case sensitive usernames`]: `允许区分大小写的用户名`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `允许客户机在与服务器的初始握手期间注入具有期望主机名的 SNI 扩展。`,
  [`Allow connection to be renegotiated`]: `允许重新协商连接`,
  [`Allow default introspection`]: `允许缺省自省`,
  [`Allow insecure server connections`]: `允许不安全的服务器连接`,
  [`Allow null value`]: `允许空值`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `允许访问令牌/刷新令牌在用于注销的 302 重定向中发送`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `所有建议都已采纳。您的模式不存在任何警告。`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `允许使用 Developer Portal 中的测试工具测试 API 的操作。`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `允许连接继续使用较弱或不安全的凭证`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `允许连接继续使用较弱或不安全的凭证。`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `允许外部 OIDC 提供者与 Developer Portal（而非 API Manager）通信。`,
  [`Allow to manage this user registry`]: `允许管理此用户注册表`,
  [`Allow to manage users under this user registry`]: `允许管理此用户注册表下的用户`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `APIC 带有颁发者发放的令牌时，允许用户自动引导`,
  [`Already have an account?`]: `已有帐户？`,
  [`Also transfer ownership of owned Spaces`]: `还将移交所拥有空间的所有权`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `始终从用户信息端点访存用户数据（如果已启用）`,
  [`Always output the root element`]: `始终输出根元素`,
  [`Always output the root element.`]: `始终输出根元素。`,
  [`Always required`]: `始终需要`,
  [`Always use userinfo endpoint`]: `始终使用用户信息端点`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `OAuth 提供者 API 包含 OAuth 流的授权和令牌端点。在此处配置 OAuth 提供者；然后，在创建受 OAuth 保护的 API 时，可以选择所需的提供者。`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `OAuth 提供者包含 OAuth 流的授权和令牌端点；选择要用于通过 OAuth 保护 API 的 OAuth 提供者`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `当开发人员使应用程序预订空间中的计划时，在此处将列出该应用程序，这样就可以调用关联的 API；您可以暂挂开发人员应用程序以阻止其访问 API，也可以创建自己的应用程序。[了解更多信息]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `认证 URL 将指向作为用户注册表的第三方认证提供者。 认证 URL 支持与 LDAP 以外的第三方用户注册表集成。`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `认证 URL 提供一种用于针对定制身份提供者认证用户的简单机制。`,
  [`An error occurred communicating with the gateways subsystem.`]: `与网关子系统通信时发生错误。`,
  [`An organization is required.`]: `组织是必需的。`,
  [`An unknown error occurred.`]: `发生未知错误。`,
  [`Analytics`]: `分析`,
  [`Analytics Insights`]: `分析洞察`,
  [`Analytics Insights Service'`]: `分析洞察服务的`,
  [`Analytics Details`]: `分析详细信息`,
  [`Analytics destinations`]: `分析目标`,
  [`Analytics Director keystore`]: `Analytics Director 密钥库`,
  [`Analytics Service`]: `分析服务`,
  [`Analytics Service URL`]: `分析服务 URL`,
  [`Analytics Service {arg} has been removed.`]: `已移除分析服务 {arg}。`,
  [`Analytics details`]: `分析详细信息`,
  [`Analytics service`]: `分析服务`,
  [`Analytics service details`]: `分析服务详细信息`,
  [`Analytics service URL`]: `分析服务 URL`,
  [`Analytics service {arg} has been removed.`]: `已移除分析服务 {arg}。`,
  [`Analytics client TLS client profile`]: `分析客户机 TLS 客户机概要文件`,
  [`Analytics client keystore`]: `分析客户机密钥库`,
  [`Analytics client truststore`]: `分析客户机信任库`,
  [`Analytics ingestion TLS client profile`]: `分析采集 TLS 客户机概要文件`,
  [`Analytics ingestion keystore`]: `分析采集密钥库`,
  [`Analytics ingestion truststore`]: `分析采集信任库`,
  [`Analytics service {title} has been created.`]: `已创建分析服务 {title}。`,
  [`Analytics service {title} has been updated.`]: `已更新分析服务 {title}。`,
  [`The selected consumer organization is`]: `所选使用者组织为`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `已配置分析服务并且已在外部卸载分析数据`,
  [`Analyze API usage and performance`]: `分析 API 用法和性能`,
  [`Anonymous bind`]: `匿名绑定`,
  [`Another user registry`]: `其他用户注册表`,
  [`api-administrator`]: `API 管理员`,
  [`Api-Analytics`]: `API 分析`,
  [`Api-Drafts`]: `草稿 API`,
  [`App`]: `应用程序`,
  [`App name`]: `应用程序名称`,
  [`App-Analytics`]: `应用程序-分析`,
  [`App-Approval`]: `应用程序核准`,
  [`App-Dev`]: `应用程序开发`,
  [`App-analytics`]: `应用程序-分析`,
  [`App-dev`]: `应用程序开发`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `应用程序`,
  [`Application Authentication`]: `应用程序认证`,
  [`Application Authentication Source`]: `应用程序认证源`,
  [`Application lifecycle`]: `应用程序生命周期`,
  [`Application approval task for upgrading application`]: `应用程序审批任务，用于升级应用程序`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `用于将应用程序 {taskname} 升级到 {username} ({orgname}) 请求的生产环境级别的应用程序核准任务`,
  [`Application authentication`]: `应用程序认证`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `应用程序开发人员最初会通过使用 Developer Portal，使其应用程序预订一个或多个计划。但是，对于所选计划，您可以将应用程序预订迁移到另一个产品中的计划。`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `应用程序开发人员最初会通过使用 Developer Portal，使其应用程序预订一个或多个计划。但是，对于所选计划，您可以将应用程序预订迁移到另一个产品中的计划。`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `应用程序开发人员使应用程序预订某个计划，以便他们可以调用该计划中的 API。`,
  [`Application is being created. Please Wait`]: `正在创建应用程序。请稍候`,
  [`Application scope check`]: `应用程序作用域检查`,
  [`Applications`]: `应用程序`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `在开启应用程序生命周期前存在的应用程序将保持为生产状态`,
  [`Applied security`]: `已应用安全性`,
  [`Apply`]: `应用`,
  [`Apply all`]: `全部应用`,
  [`Apply all suggestions`]: `应用所有建议`,
  [`Apply analysis configuration`]: `应用分析配置`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `应用“invoke”策略以在操作中调用现有服务。该策略可用于 JSON 或 XML 数据，并且可在组合件中应用多次。`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `应用“websocket”升级策略来建立 websocket 连接，以便从操作中调用现有服务。`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `应用此策略以在操作中调用代理 API，尤其是在需要调用大型有效内容时。每个组合件只允许调用一个“proxy”策略。`,
  [`Apply to selected`]: `应用到所选项`,
  [`Apply type analysis configuration`]: `应用类型分析配置`,
  [`Approval History`]: `核准历史记录`,
  [`Approval tasks`]: `核准任务`,
  [`Approvals`]: `核准`,
  [`Approve`]: `核准`,
  [`Archive`]: `归档`,
  [`Archive Product`]: `归档产品`,
  [`Archive|permission`]: `归档`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `确定要从所选项清除约束？`,
  [`Are you sure you want to delete API?`]: `确定要删除 API？`,
  [`Are you sure you want to delete Product?`]: `确定要删除产品？`,
  [`Are you sure you want to delete a catalog?`]: `确定要删除目录？`,
  [`Are you sure you want to delete a space?`]: `确定要删除空间？`,
  [`Are you sure you want to delete this application?`]: `确定要删除此应用程序？`,
  [`Are you sure you want to delete this billing integration?`]: `确定要删除此计费集成？`,
  [`Are you sure you want to delete this field?`]: `确定要删除此字段？`,
  [`Are you sure you want to delete this policy?`]: `确定要删除该策略？`,
  [`Are you sure you want to delete this type?`]: `确定要删除此类型？`,
  [`Are you sure you want to disable spaces?`]: `确定要禁用空间？`,
  [`Are you sure you want to disable custom email sender information?`]: `是否确定要禁用定制电子邮件发件人信息？`,
  [`Are you sure you want to disable custom notification templates?`]: `确定要禁用定制通知模板？`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `确定要停止使用 IBM Developer Portal？将删除目录的所有门户网站定制`,
  [`Are you sure you want to enable spaces?`]: `确定要启用空间？`,
  [`Are you sure you want to enable custom email sender information?`]: `是否确定要启用定制电子邮件发件人信息？`,
  [`Are you sure you want to enable custom notification templates?`]: `确定要启用定制通知模板？`,
  [`Are you sure you want to proceed with delete?`]: `确定要继续删除？`,
  [`Are you sure you want to publish?`]: `确定要发布？`,
  [`Are you sure you want to re-stage?`]: `确定要重新登台？`,
  [`Are you sure you want to remove the product from the catalog?`]: `确定要从目录中移除产品？`,
  [`Are you sure you want to unassociate?`]: `确定要取消关联？`,
  [`Are you sure you want to update Self Service Onboarding`]: `确定要更新“自助服务上线”`,
  [`Are you sure?`]: `确定要这样吗？`,
  [`Argument`]: `自变量`,
  [`Array`]: `数组`,
  [`Assemble`]: `组合`,
  [`Assemble the policy flow required for the OAuth provider.`]: `组合 OAuth 提供者所需的策略流。`,
  [`Assembly`]: `组合件`,
  [`Assembly Execute`]: `组合件执行`,
  [`Assembly saved`]: `组合件已保存`,
  [`Assembly burst limits`]: `组合件突发流量限制`,
  [`Assembly count limits`]: `组合件计数限制`,
  [`Assign roles`]: `分配角色`,
  [`Assign roles to the current owner`]: `向当前所有者分配角色`,
  [`Associate`]: `关联`,
  [`Associate analytics service`]: `关联分析服务`,
  [`Associate analytics`]: `关联分析`,
  [`Assumed size`]: `假定大小`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `尝试从 URL {url} 获取 API 时发生认证错误。 请检查用户名和密码。`,
  [`Audience claim`]: `受众声明`,
  [`Audience Claim`]: `受众声明`,
  [`Audit setting`]: `审计设置`,
  [`Audit setting has been changed`]: `已更改审计设置`,
  [`Auditing is **{mode}**`]: `审计为**{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `审计用于监视有关调用方用户的 API 调用和日志信息、每次调用的时间以及每个事件中涉及的活动。`,
  [`Authenticate Client Method`]: `认证客户机方法`,
  [`Authenticate password`]: `认证密码`,
  [`Authenticate user`]: `认证用户`,
  [`Authenticate user settings`]: `认证用户设置`,
  [`Authenticate application users using`]: `认证应用程序用户的方式`,
  [`Authenticate using Basic Authentication`]: `使用基本认证进行认证`,
  [`Authenticate using OAuth`]: `使用 OAuth 进行认证`,
  [`Authenticated`]: `已认证`,
  [`Authenticated bind`]: `认证绑定`,
  [`Authenticated User Name`]: `已认证的用户名`,
  [`Authentication`]: `认证`,
  [`Authentication Bind`]: `认证绑定`,
  [`Authentication error`]: `认证错误`,
  [`Authentication method`]: `认证方法`,
  [`Authentication TLS profile`]: `认证 TLS 概要文件`,
  [`Authentication URL`]: `认证 URL`,
  [`Authentication URL user registry`]: `认证 URL 用户注册表`,
  [`Authentication URL user registry has been created.`]: `已创建认证 URL 用户注册表。`,
  [`Authentication URL to use for validation.`]: `用于验证的认证 URL。`,
  [`Authentication response header credential`]: `认证响应头凭证`,
  [`Authentication response header pattern`]: `认证响应头模式`,
  [`Authentication type`]: `认证类型`,
  [`Authentication type to use to validate the UsernameToken.`]: `用于验证用户名令牌的认证类型。`,
  [`Authorization`]: `授权`,
  [`Authorization Code`]: `授权代码`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `授权 URL 必须与 OAuth 提供者“{providerTitle}”定义的 {endpoint} 匹配`,
  [`Authorization endpoint`]: `授权端点`,
  [`Authorization Request`]: `授权请求`,
  [`Authorization URL`]: `授权 URL`,
  [`Authorize`]: `授权`,
  [`Authorize User Settings`]: `授权用户设置`,
  [`Authorize application users using`]: `授权应用程序用户的方式`,
  [`Authorize path`]: `授权路径`,
  [`Authors API and product definitions`]: `编写 API 和产品定义`,
  [`Auto Generate OIDC API Assembly`]: `自动生成 OIDC API 组合件`,
  [`Auto onboard`]: `自动引导`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `用于 API Connect 管理 API 和命令中的自动生成的名称。`,
  [`Automation`]: `自动化`,
  [`Automation Management Console`]: `自动化管理控制台`,
  [`Automation management console`]: `自动化管理控制台`,
  [`Availability Zone`]: `可用性区域`,
  [`Availability zone`]: `可用性区域`,
  [`Availability zone {arg} has been removed.`]: `已移除可用性区域 {arg}。`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `通过可用性区域，可以对 API Connect 服务进行分组，以满足业务需求。 例如，可以根据网关服务所在的区域或数据中心来对网关服务进行分组。`,
  [`Average response time: {{value}}ms`]: `平均响应时间：{{value}}ms`,
  [`Average time`]: `平均时间`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `使用 API Designer 服务器作为本地代理以避免 CORS 错误。`,
  [`Back`]: `上一步`,
  [`Back to sign in`]: `返回到登录`,
  [`Back to test`]: `返回测试`,
  [`Backend type`]: `后端类型`,
  [`Bad gateway`]: `错误网关`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `基本 DN`,
  [`Base Path`]: `基本路径`,
  [`Base path`]: `基本路径`,
  [`Base endpoint`]: `基本端点`,
  [`Base endpoints`]: `基本端点`,
  [`Base64 encoding`]: `Base64 编码`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `用于邀请和密码重置令牌的 Base64 编码**{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `临时令牌的 Base64 编码设置已更改`,
  [`Base URL of API invocation endpoint`]: `API 调用端点的基本 URL`,
  [`Basepath root`]: `基本路径根目录`,
  [`Basic`]: `基本`,
  [`Basic authentication`]: `基本认证`,
  [`Basic authentication password`]: `基本认证密码`,
  [`Basic authentication request header name`]: `基本认证请求头名称`,
  [`Basic authentication username`]: `基本认证用户名`,
  [`Before you begin`]: `开始之前`,
  [`Before you begin...`]: `开始之前...`,
  [`Between 0 and 10 events are waiting to be processed`]: `0 到 10 个事件正在等待处理`,
  [`Between 10 and 20 events are waiting to be processed`]: `10 到 20 个事件正在等待处理`,
  [`Billing`]: `计费`,
  [`Billing and Payment`]: `计费与付款`,
  [`Billing has been updated`]: `已更新计费`,
  [`Billing integrations`]: `计费集成`,
  [`Billing integration`]: `计费集成`,
  [`Billing Integration`]: `计费集成`,
  [`Billing {title} successfully added.`]: `已成功添加计费 {title}。`,
  [`Billing {title} successfully deleted.`]: `已成功删除计费 {title}。`,
  [`Billing {title} successfully updated.`]: `已成功更新计费 {title}。`,
  [`Billing Options`]: `计费选项`,
  [`Blocklist`]: `阻止列表`,
  [`Blocking`]: `阻止`,
  [`Blocking option`]: `阻止选项`,
  [`Body`]: `正文`,
  [`Boolean`]: `布尔`,
  [`Both`]: `两者`,
  [`Browse`]: `浏览`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `在 DataPower API Gateway 上处理 API 请求和响应之前对其进行缓冲。`,
  [`Buffering`]: `缓冲`,
  [`Builds and manages apps in the developer organization`]: `在开发人员组织中构建和管理应用程序`,
  [`Burst Limit Name`]: `突发流量限制`,
  [`Burst limits`]: `突发流量限制`,
  [`Burst limit value can't be negative`]: `突发流量限制值不能为负值`,
  [`Buy`]: `购买`,
  [`but unable to remove because of limitation`]: `但由于限制而无法移除`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `通过配置产品的可视性和可预订性，可以控制其 API 对 Developer Portal 中应用程序开发人员的可用性。您可以控制哪些应用程序开发人员可以查看产品，以及哪些应用程序开发人员可以预订使用产品中的 API。`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `通过配置产品的可视性和可预订性，可以控制其 API 对 Developer Portal 中应用程序开发人员的可用性。您可以控制哪些应用程序开发人员可以查看产品，以及哪些应用程序开发人员可以预订使用产品中的 API。`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `缺省情况下，来自多个源（比如长度为 2 的 array1 和长度为 3 的 array2）的映射会生成一个包含 5 个项（处理 array1 时得到 2 项，接着处理 array2 时得到 3 项）的目标数组。如果希望合并这些规则（创建一个包含 2 个或 3 个项的数组且合并了每个源数组的属性），那么选中此选项。`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `缺省情况下，会将此产品发布到所有相关的网关服务。您也可以通过启用此选项来发布至特定的网关服务。`,
  [`By payload`]: `按有效内容`,
  [`By URL parameter`]: `按 URL 参数`,
  [`Bytes`]: `字节数`,
  [`CALLS`]: `调用`,
  [`Calls`]: `调用`,
  [`CATALOG`]: `目录`,
  [`CERTIFICATE NAME`]: `证书名称`,
  [`CIPHER`]: `密码`,
  [`CIPHER SUITES`]: `密码套件`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `仅 CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `客户机标识`,
  [`CLOUD ADMINISTRATOR`]: `云管理员`,
  [`Consumer organization`]: `使用者组织`,
  [`CONSUMER ORGANIZATION / GROUP`]: `使用者组织/组`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA 捆绑包`,
  [`Cache Key`]: `高速缓存键`,
  [`Cache Time to Live (second)`]: `高速缓存生存时间（秒）`,
  [`Cache key`]: `高速缓存键`,
  [`Cache Time-To-Live`]: `高速缓存生存时间`,
  [`Cache type`]: `高速缓存类型`,
  [`Can't find the one you want?`]: `找不到所需项吗？`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `邮件服务器无效，无法发送邀请，请检查您的邮件服务器配置，然后重试`,
  [`Cancel`]: `取消`,
  [`Cannot be below {min}`]: `不能低于 {min}`,
  [`Cannot be empty`]: `不能为空`,
  [`Cannot delete root type.`]: `无法删除根类型。`,
  [`Cannot exceed {max}`]: `不能超过 {max}`,
  [`Cannot find any APIs for this product`]: `找不到此产品的任何 API`,
  [`Cannot find the one you want?`]: `找不到所需项吗？`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `不能对不返回列表且未定义“sizedFields”的字段使用“@listSize”伪指令。`,
  [`Cannot remove built-in scalar types`]: `无法移除内置标量类型`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `无法移除查询、突变和预订根操作类型`,
  [`Cannot remove arguments of built-in directives`]: `无法移除内置伪指令的自变量`,
  [`Cannot remove non-null arguments of directives`]: `无法移除伪指令的非 null 自变量`,
  [`Cannot remove input types of arguments of directives`]: `无法移除伪指令的自变量的输入类型`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `无法移除用作伪指令自变量的缺省值的枚举值`,
  [`Cannot remove enum values used as default values of input fields`]: `无法移除用作输入字段的缺省值的枚举值`,
  [`Cannot remove all fields of an object type`]: `无法移除对象类型的所有字段`,
  [`Cannot remove all fields of an interface type`]: `无法移除接口类型的所有字段`,
  [`Cannot remove all slicing arguments`]: `无法移除所有切割自变量`,
  [`Cannot remove all input fields of an input object type`]: `无法移除输入对象类型的所有输入字段`,
  [`Cannot remove all values of enum type`]: `无法移除枚举类型的所有值`,
  [`Cannot remove fields of interfaces`]: `无法移除接口的字段`,
  [`Cannot remove non-null input fields`]: `无法移除非 null 输入字段`,
  [`Case`]: `大小写`,
  [`Case sensitive`]: `区分大小写`,
  [`Catalog`]: `目录`,
  [`Catalog defaults`]: `目录缺省值`,
  [`Catalog Invite`]: `目录邀请`,
  [`Catalog invite`]: `目录邀请`,
  [`Catalog invites`]: `目录邀请`,
  [`Catalog name`]: `目录名称`,
  [`Catalog owner`]: `目录所有者`,
  [`Catalog owner invitation`]: `目录所有者邀请`,
  [`Catalog properties`]: `目录属性`,
  [`Catalog summary`]: `目录摘要`,
  [`Catalog title`]: `目录标题`,
  [`Catalog user registries`]: `目录用户注册表`,
  [`Catalog by Name`]: `按名称编目`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `目录优先级（使用目录管理员定义的虚拟端点）`,
  [`Catalog {name} created`]: `已创建目录 {name}`,
  [`Catalog selection`]: `目录选择`,
  [`Catalog settings`]: `目录设置`,
  [`Catalog settings have been updated`]: `已更新目录设置`,
  [`Catalog User`]: `目录用户`,
  [`Catalog Users`]: `目录用户`,
  [`Catalog user registry`]: `目录用户注册表`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `无法在“测试首选项”中使用启用了生命周期的目录。`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `无法在“测试首选项”中使用已启用生命周期和生产方式的目录。`,
  [`Catalogs`]: `目录`,
  [`Catch`]: `捕获`,
  [`Catches`]: `捕获`,
  [`Categories`]: `类别`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `类别在 Developer Portal 中建立 API 产品的分层显示。请使用以下语法：top_level_element/next_level_element/lower_level_element。`,
  [`Category`]: `类别`,
  [`Certifcate or Shared Secret for Verify`]: `用于验证的证书或共享私钥`,
  [`Certificate`]: `证书`,
  [`Certificate (Optional)`]: `证书（可选）`,
  [`Certificate management`]: `证书管理`,
  [`Certificate Manager instance`]: `证书管理器实例`,
  [`Certificate (optional)`]: `证书（可选）`,
  [`Certificates`]: `证书`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `需要证书来保护网关与其处理的域之间的连接。所有证书都必须存储在证书管理器服务中。该服务为证书提供安全存储库，并通过在证书即将到期时向您发送通知来帮助防止停运。要开始使用此产品，请执行下列操作：`,
  [`Certificates details in this keystore`]: `此密钥库中的证书详细信息`,
  [`Certificates details in this truststore`]: `此信任库中的证书详细信息`,
  [`Change`]: `更改`,
  [`Change Consumer Organization owner to an existing user`]: `将使用者组织所有者更改为现有用户`,
  [`Change Ownership to an Existing User`]: `将所有权更改为现有用户`,
  [`Change portal service`]: `更改门户网站服务`,
  [`Change owner to existing user`]: `将所有者更改为现有用户`,
  [`Change owner to new user`]: `将所有者更改为新用户`,
  [`Change ownership by inviting a new user`]: `通过邀请新用户来更改所有权`,
  [`Change ownership to`]: `将所有权更改为`,
  [`Change ownership to a new user via email invitation`]: `通过电子邮件邀请将所有权更改为新用户`,
  [`Change ownership to an existing user`]: `将所有权更改为现有用户`,
  [`Change password`]: `更改密码`,
  [`Change setup`]: `更改设置`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `如果同时未在 OAuth 提供者自身的定义中更新任何相应的值，那么直接对 OAuth 提供者底层的 API 或组合件执行的更改可能使 OAuth 提供者无效。如果保存这些更改，那么请确保也在 OAuth 提供者定义中进行了所有相应的更新。`,
  [`Change the following extra constraints in the schema:`]: `更改模式中的以下额外约束：`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `更改证书管理器将移除所有现有证书和 CA 捆绑包分配。`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `更改 DNS 方案将更改指向 API Connect 和 Developer Portal 的 URL 链接的格式。您需要将新链接传达给用户。如果不想继续，请单击“取消”。`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `更改 API 的名称或版本将创建新 API。确定要保存更改？`,
  [`Check your email`]: `检查您的电子邮件`,
  [`Child`]: `子代`,
  [`Chinese (Simplified)`]: `简体中文`,
  [`Chinese (Traditional)`]: `繁体中文`,
  [`Choose`]: `选择`,
  [`Choose a`]: `选择一个`,
  [`Choose {docTypeLabel}`]: `选择 {docTypeLabel}`,
  [`Choose Catalog`]: `选择目录`,
  [`Choose a Catalog`]: `选择一个目录`,
  [`Choose a Consumer Org`]: `选择使用者组织`,
  [`Choose Gateway`]: `选择网关`,
  [`Choose a Gateway`]: `选择一个网关`,
  [`Choose Product`]: `选择产品`,
  [`Choose a Product`]: `选择一个产品`,
  [`Choose Rate Limit`]: `选择速率限制`,
  [`Choose a Rate Limit`]: `选择一个速率限制`,
  [`Choose Application`]: `选择应用程序`,
  [`Choose an Application`]: `选择应用程序`,
  [`Choose a {docTypeLabel}`]: `选择 {docTypeLabel}`,
  [`Choose a publish destination`]: `选择发布目标`,
  [`Choose a billing integration`]: `选择计费集成`,
  [`Choose a catalog to test within:`]: `选择要在其中进行测试的目录：`,
  [`Choose a plan against which to test:`]: `选择要进行测试的计划：`,
  [`Choose a product containing this API, or create a new one:`]: `选择包含此 API 的产品，或者创建新产品：`,
  [`Choose a user registry`]: `选择用户注册表`,
  [`Choose an application with which to test, or create a new one:`]: `选择要用于进行测试的应用程序，或者创建新的应用程序：`,
  [`Choose an existing application`]: `选择现有应用程序`,
  [`Choose an existing catalog`]: `选择现有目录`,
  [`Choose an existing product published to your Sandbox catalog`]: `选择发布至您的沙箱目录的现有产品`,
  [`Choose an operation to invoke:`]: `选择要调用的操作：`,
  [`Choose an option`]: `选择选项`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `选择一个选项以确定映射策略如何处理空数组输出。选择“所有”（缺省选项）将输出所有空数组和空的子数组。选择“父级”将仅输出父级空数组。选择“无”将不输出空数组。`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `选择阻止方式将对系统性能造成不利影响`,
  [`Choose existing directory`]: `选择现有目录`,
  [`Choose one...`]: `选择一个...`,
  [`Choose operations and paths to generate into this API`]: `选择要在此 API 中生成的操作和路径`,
  [`Choose paths to generate into this API`]: `选择要在此 API 中生成的路径`,
  [`Choose the DNS scheme`]: `选择 DNS 方案`,
  [`Choose the permissions for the role.`]: `选择角色的许可权。`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `选择是将动态 DNS 地址还是静态 DNS 地址用于对网关服务进行入站 API 调用以及访问 Developer Portal。`,
  [`Cipher`]: `密码`,
  [`Ciphers`]: `密码`,
  [`Clear`]: `清除`,
  [`Clear constraints`]: `清除约束`,
  [`Clear file`]: `清除文件`,
  [`Clear filters`]: `清除过滤器`,
  [`Clear from selected`]: `从所选项清除`,
  [`Clear operation filter`]: `清除操作过滤器`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `单击证书可查看有关其颁发组织和指纹的详细信息。`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `单击以下链接以继续在另一个选项卡中授权 API，这样将获取步骤 2 的授权代码。`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `单击以下链接将在新选项卡中打开服务器。如果浏览器显示证书问题，那么您可以选择接受该问题并返回此处以重新测试。`,
  [`Click add to add a billing integration.`]: `单击“添加”可添加计费集成。`,
  [`Click Add to add a gateway extension.`]: `单击“添加”可添加网关扩展。`,
  [`Click **Add domain** to add domains.`]: `单击**添加域**可添加域。`,
  [`Click Add to add a new base endpoint.`]: `单击“添加”可添加新的基本端点。`,
  [`Click Add to add a new HTTP header.`]: `单击“添加”可添加新的 HTTP 头。`,
  [`Click Add to add a new member.`]: `单击“添加”可添加新的成员。`,
  [`Click Add to add a new role.`]: `单击“添加”可添加新的角色。`,
  [`Click Add to add a new scope.`]: `单击“添加”可添加新的作用域。`,
  [`Click Add to add a new consumer organization group.`]: `单击“添加”可添加新的使用者组织组。`,
  [`Click Add to create the API Key.`]: `单击“添加”以创建 API 密钥。`,
  [`Click Create to add the API Key.`]: `单击“创建”以添加 API 密钥。`,
  [`Click Create to add a new user registry.`]: `单击“创建”可添加新的用户注册表。`,
  [`Click Create user registry to add a new user registry.`]: `单击“创建用户注册表”可添加新的用户注册表。`,
  [`Click **Create provider organization** to add provider organizations.`]: `单击**创建提供者值**可添加提供者组织。`,
  [`Click Edit to add OAuth providers.`]: `单击“编辑”可添加 OAuth 提供者。`,
  [`Click Edit to add a user registry.`]: `单击“编辑”可添加用户注册表。`,
  [`Click Edit to add an API to the product.`]: `单击“编辑”可向产品添加 API。`,
  [`Click Create to add a property.`]: `单击“创建”可添加属性。`,
  [`Click Edit to add TLS client profiles.`]: `单击“编辑”可添加 TLS 客户机概要文件。`,
  [`Click Edit to add user registries.`]: `单击“编辑”可添加用户注册表。`,
  [`Click Add to add a new consumer organization.`]: `单击“添加”可添加新的使用者组织。`,
  [`Click Consumer organizations to add one.`]: `单击“使用者组织”可添加新的使用者组织。`,
  [`Click Add to add a new application.`]: `单击“添加”可添加新的应用程序。`,
  [`Click Add to add parameters.`]: `单击“添加”可添加参数。`,
  [`Click Add to add response.`]: `单击“添加”可添加响应。`,
  [`Click Create to add a new TLS client profile.`]: `单击“创建”可添加新的 TLS 客户机概要文件。`,
  [`Click Create to add a new keystore.`]: `单击“创建”可添加新的密钥库。`,
  [`Click Create to add a new truststore.`]: `单击“创建”可添加新的信任库。`,
  [`Click **Register remote gateway** to add gateways.`]: `单击**注册远程网关**可添加网关。`,
  [`Click Register gateways to add new Gateway Services.`]: `单击“注册网关”可添加新的网关服务。`,
  [`Click Edit to add Gateway Services.`]: `单击“编辑”可添加网关服务。`,
  [`Click Register gateways to add new gateway services.`]: `单击“注册网关”可添加新的网关服务。`,
  [`Click Edit to add gateway services.`]: `单击“编辑”可添加网关服务。`,
  [`Click Edit to add an email server.`]: `单击“编辑”可添加电子邮件服务器。`,
  [`Click Create to add a new TLS server profile.`]: `单击“创建”可添加新的 TLS 服务器概要文件。`,
  [`Click Create to add a new email server.`]: `单击“创建”可添加新的电子邮件服务器。`,
  [`Click Add to add a new schema definition.`]: `单击“添加”可添加新的模式定义。`,
  [`Click Add to add a new property for this definition.`]: `单击“添加”可为此定义添加新属性。`,
  [`Click Add to add a new parameter for this API.`]: `单击“添加”可为此 API 添加新参数。`,
  [`Click Add to add tags and external documentation for this API.`]: `单击“添加”可为此 API 添加标记和外部文档。`,
  [`Click Add to add a new path.`]: `单击“添加”可添加新的路径。`,
  [`Click Add to add a property for this API.`]: `单击“添加”可为此 API 添加属性。`,
  [`Click here to create a security definition`]: `单击此处可创建安全性定义。`,
  [`Click Add to add a new security definition.`]: `单击“添加”可添加新的安全性定义。`,
  [`Click Add to add a target service for this API.`]: `单击“添加”可为此 API 添加目标服务。`,
  [`Click Add to add an operation for this path.`]: `单击“添加”可为此路径添加操作。`,
  [`Click Add to add a path parameter for this path.`]: `单击“添加”可为此路径添加路径参数。`,
  [`Click Add to add a new OAuth provider.`]: `单击“添加”可添加新的 OAuth 提供者。`,
  [`Click Add to add a new provider organization.`]: `单击“添加”可添加新的提供者组织。`,
  [`Click Add to add a new API or product.`]: `单击“添加”可添加新的 API 或产品。`,
  [`Click Add to add a new API.`]: `单击“添加”可添加新的 API。`,
  [`Click Add to add a new Extension.`]: `单击“添加”可添加新的扩展。`,
  [`Click Add to add new {resource}.`]: `单击“添加”以添加新的{resource}。`,
  [`Click to expand`]: `单击以展开`,
  [`Click to collapse`]: `单击以折叠`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `单击以了解如何上载和管理网关所需的证书。`,
  [`Client Credentials`]: `客户机凭证`,
  [`Client information`]: `客户机信息`,
  [`Client revocation path`]: `客户机撤销路径`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `单击“注册服务”可注册某个服务。这允许您在配置其他服务时测试端点。`,
  [`Client secret`]: `客户机密钥`,
  [`Client security`]: `客户机安全性`,
  [`Client Type`]: `客户机类型`,
  [`Client authentication method`]: `客户机认证方法`,
  [`Clone`]: `克隆`,
  [`Close`]: `关闭`,
  [`Cloud`]: `云`,
  [`Cloud Admin`]: `云管理`,
  [`Cloud Administrator`]: `云管理员`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `云管理员、组织管理员和拓扑管理员。系统自动将“成员”角色分配给所有用户。`,
  [`Cloud administration`]: `云管理`,
  [`Cloud Discovery Agent`]: `云发现代理程序`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Cloud Manager 本地用户注册表`,
  [`Cloud-Settings`]: `Cloud-Settings`,
  [`Cloud settings invitation timeout`]: `云设置邀请超时`,
  [`Cloud settings invitation timeout has been changed`]: `云设置邀请超时已更改`,
  [`Code editor`]: `代码编辑器`,
  [`Collect Metadata`]: `收集元数据`,
  [`Collect credentials using`]: `收集凭证方式`,
  [`Collect metadata`]: `收集元数据`,
  [`Combo box`]: `组合框`,
  [`Community Manager`]: `社区管理员`,
  [`community-manager`]: `社区管理者`,
  [`Compatibility`]: `兼容性`,
  [`Complex Definitions must be edited in the editor`]: `必须在编辑器中编辑复杂定义`,
  [`Compose DN`]: `编写 DN`,
  [`Compose UPN`]: `编写 UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `通过定义路径和操作，编写新的 REST 代理`,
  [`Compose a new product by adding rate limits and plans`]: `通过添加费率限制和计划，编写新产品`,
  [`Compression`]: `压缩`,
  [`Condition`]: `条件`,
  [`Condition editor`]: `条件编辑器`,
  [`Confidential`]: `保密`,
  [`Configuration`]: `配置`,
  [`Configurations`]: `配置`,
  [`Configure API endpoint`]: `配置 API 端点`,
  [`Configure API Gateway Service`]: `配置 API Gateway 服务`,
  [`Configure analytics service`]: `配置分析服务`,
  [`Configure availability zone`]: `配置可用性区域`,
  [`Configure catalog user registries`]: `配置目录用户注册表`,
  [`Configure cloud`]: `配置云`,
  [`Configure DataPower API gateway service`]: `配置 DataPower API Gateway 服务`,
  [`Configure DataPower gateway service`]: `配置 DataPower Gateway 服务`,
  [`Configure Email Server`]: `配置电子邮件服务器`,
  [`Configure gateway extension`]: `配置网关扩展`,
  [`Configure portal service`]: `配置门户网站服务`,
  [`Configure service`]: `配置服务`,
  [`Configure topology`]: `配置拓扑`,
  [`Configure XML`]: `配置 XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `配置 DataPower API Gateway 服务以保护和实施 API`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `配置 DataPower Gateway 服务以保护和实施 API`,
  [`Configure a developer portal service for API consumers`]: `为 API 使用者配置 Developer Portal 服务`,
  [`Configure advanced features for OIDC settings`]: `为 OIDC 设置配置高级功能`,
  [`Configure an analytics service to collect API call data`]: `配置分析服务以收集 API 调用数据`,
  [`Configure an email server to send invitations and notifications to users.`]: `配置用于向用户发送邀请和通知的电子邮件服务器。`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `配置审计以监视管理 UI、Manager UI、Toolkit、管理 REST API 和门户网站用户的操作。 每个创建、更新和删除用户操作的日志将发送到下面指定的远程日志记录服务。`,
  [`Configure availability zones and services`]: `配置可用性区域和服务`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `配置如何在已发布的 API 中编写 API 端点 URL`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `配置如何在 Developer Portal 中显示虚拟端点`,
  [`Configure mapping`]: `配置映射`,
  [`Configure properties of the activity log`]: `配置活动日志的属性`,
  [`Configure settings for token management and revocation.`]: `配置用于令牌管理和撤销的设置。`,
  [`Configure settings to generate and validate tokens.`]: `配置设置以生成和验证令牌。`,
  [`Configure the API security`]: `配置 API 安全性`,
  [`Configure the default set of gateway services configured for each catalog`]: `配置针对每个目录配置的缺省网关服务集`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `配置应用程序开发人员访问此目录中的 API 所使用的 Developer Portal`,
  [`Configure the gateway services used by default in each catalog`]: `配置缺省情况下在每个目录中使用的网关服务`,
  [`Configure the gateway extension for the selected gateway`]: `为所选网关配置网关扩展`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `配置 IBM 管理的 DataPower API Gateway 并管理您自己的远程网关。[了解更多信息]({link})`,
  [`Configure the keystore and upload certificates`]: `配置密钥库和上载证书`,
  [`Configure the name and email address to be used in the from field of emails`]: `配置要在电子邮件的“发件人”字段中使用的名称和电子邮件地址`,
  [`Configure the portal service for the catalog`]: `为目录配置门户网站服务`,
  [`Configure the role and assign permissions`]: `配置角色和分配许可权`,
  [`Configure the security of this API`]: `配置此 API 的安全性`,
  [`Configure the sender name and address to use for email notifications`]: `配置要用于电子邮件通知的发件人姓名和地址`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `配置用于向用户发送邀请和通知的发件人姓名、地址和电子邮件服务器`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `配置在创建使用者组织时缺省情况下要使用的角色集`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `配置在创建提供者组织时缺省情况下要使用的角色集`,
  [`Configure the templates used to invite and notify users`]: `配置用于邀请和通知用户的模板`,
  [`Configure the truststore and upload certificates`]: `配置信任库和上载证书`,
  [`Configure user authentication using`]: `使用以下项配置用户认证：`,
  [`Configure user authentication using JSON Web Tokens`]: `使用 JSON Web 令牌配置用户认证`,
  [`Configure user authentication using a LDAP provider`]: `使用 LDAP 提供者配置用户认证`,
  [`Configure user authentication using an API Connect Local User Registry`]: `使用 API Connect 本地用户注册表配置用户认证`,
  [`Configure user authentication using an authentication URL`]: `使用认证 URL 配置用户认证`,
  [`Configure user authentication using this user registry type`]: `使用此用户注册表类型配置用户认证`,
  [`Configure user registries, OAuth providers and TLS`]: `配置用户注册表、OAuth 提供者和 TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `配置用户注册表、TLS、OAuth 提供者和电子邮件服务器`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `配置访问令牌、标识令牌和临时令牌的密钥库`,
  [`Configured OAuth Provider`]: `配置的 OAuth 提供者`,
  [`Configured OAuth Providers`]: `配置的 OAuth 提供者`,
  [`Configured OAuth provider`]: `配置的 OAuth 提供者`,
  [`Configured OAuth provider list has been updated`]: `已更新配置的 OAuth 提供者列表`,
  [`Configured OAuth provider list has not been updated`]: `未更新所配置的 OAuth 提供者列表`,
  [`Confirm`]: `确认`,
  [`Confirm deletion`]: `确认删除`,
  [`Confirm Deletion of Portal`]: `确认删除门户网站`,
  [`Confirm visibility settings`]: `确认可视性设置`,
  [`Confirm new password`]: `确认新密码`,
  [`Confirm password`]: `确认密码`,
  [`Confirm password should match password`]: `确认密码应与密码相匹配`,
  [`Confirmation`]: `确认`,
  [`Congratulations, you are now registered.`]: `恭喜，您已成功注册。`,
  [`Congratulations, your password has been reset!`]: `恭喜，您的密码已重置！`,
  [`Connect`]: `连接`,
  [`Connect to API Connect`]: `连接到 API Connect`,
  [`Connected to API Manager Product version`]: `已连接到 API Manager 产品版本`,
  [`Connect to Cloud`]: `连接到云`,
  [`Connected`]: `已连接`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `通过添加所需类型的属性来构造模式定义。您可以嵌套属性`,
  [`Consider for nesting`]: `考虑使用嵌套`,
  [`Consumed Media Types`]: `使用的介质类型`,
  [`Consumer`]: `使用者`,
  [`Consumer invitation and roles`]: `使用者邀请和角色`,
  [`Consumer onboarding`]: `使用者引导`,
  [`Consumer organizations`]: `使用者组织`,
  [`Consumer organizations or groups`]: `使用者组织或组`,
  [`Consumer organization {name} created`]: `已创建使用者组织 {name}`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `使用者组织由 Developer Portal 中的应用程序开发人员创建，您还可以使用 API Manager UI 创建使用者组织`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `使用者组织由应用程序开发人员在 Developer Portal 中创建，您还可以在此处创建使用者组织`,
  [`Consumer-Org`]: `使用者组织`,
  [`Consumers`]: `使用者`,
  [`Consumes`]: `使用`,
  [`Contact`]: `联系人`,
  [`Contact information for the owners of the API.`]: `API 所有者的联系信息。`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `请联系管理员以协商配置分析服务，以便可在此处查看数据。`,
  [`Contact your administrator for more information.`]: `请联系管理员以获取更多信息。`,
  [`Content`]: `内容`,
  [`Content on error`]: `出错时的内容`,
  [`Content type`]: `内容类型`,
  [`Context Variable`]: `上下文变量`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `包含要验证的 JWT 的上下文或运行时变量。如果未设置此选项，那么该策略将在 request.headers.authorization 中查找 JWT。`,
  [`Context variable`]: `上下文变量`,
  [`Continue`]: `继续`,
  [`Continue on error`]: `出错时继续`,
  [`Continue with`]: `继续`,
  [`Continue with:`]: `继续：`,
  [`Conversion type`]: `转换类型`,
  [`Convert to expression`]: `转换为表达式`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `已复制`,
  [`Copied to clipboard`]: `已复制到剪贴板`,
  [`Copy`]: `复制`,
  [`Copy Id Headers To Message`]: `将标识头复制到消息`,
  [`Copy to clipboard`]: `复制到剪贴板`,
  [`Copy file path to clipboard`]: `将文件路径复制到剪贴板`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `将 GraphQL 查询的成本估算复制到输出`,
  [`Copy the endpoints below to`]: `复制以下端点以`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `复制要使用的 API 密钥。 您可以使用“视图”图标再次查看此 API 密钥。`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `版权信息`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `请纠正以上编辑器中的错误，或者重新上载有效文件`,
  [`Cost`]: `耗用`,
  [`Count`]: `计数`,
  [`Count Limit Name`]: `计数限制名称`,
  [`Count Limits`]: `计数限制`,
  [`Created at`]: `创建时间`,
  [`Counter`]: `计数器`,
  [`Create`]: `创建`,
  [`Create a [Certificate Manager]({link}) service`]: `创建[证书管理器]({link})服务`,
  [`Create API`]: `创建 API`,
  [`Create an API from the Develop section.`]: `从“开发”部分中创建 API。`,
  [`Create API Connect API Key`]: `创建 API Connect API 密钥`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `从现有 GraphQL 服务（GraphQL 代理）创建 API`,
  [`Create API from existing OpenAPI service`]: `从现有 OpenAPI 服务创建 API`,
  [`Create API from existing WSDL service (REST proxy)`]: `从现有 WSDL 服务创建 API（REST 代理）`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `从现有 WSDL 服务创建 API（SOAP 代理）`,
  [`Create API from target service`]: `从目标服务创建 API`,
  [`Create API from existing REST service`]: `从现有 REST 服务创建 API`,
  [`Create API from existing SOAP service`]: `从现有 SOAP 服务创建 API`,
  [`Create API from existing WSDL service`]: `从现有 WSDL 服务创建 API`,
  [`Create API Key failed`]: `创建 API 密钥失败`,
  [`Create application`]: `创建应用程序`,
  [`Create authentication URL user registry`]: `创建认证 URL 用户注册表`,
  [`Create availability zone`]: `创建可用性区域`,
  [`Create Billing`]: `创建计费`,
  [`Create catalog`]: `创建目录`,
  [`Create catalog property`]: `创建目录属性`,
  [`Create consumer organization`]: `创建使用者组织`,
  [`Create Credentials`]: `创建凭证`,
  [`Create definition`]: `创建定义`,
  [`Create email server`]: `创建电子邮件服务器`,
  [`Create IBM Developer Portal`]: `创建 IBM Developer Portal`,
  [`Create keystore`]: `创建密钥库`,
  [`Create LDAP user registry`]: `创建 LDAP 用户注册表`,
  [`Create LTPA Key`]: `创建 LTPA 密钥`,
  [`Create local user registry`]: `创建本地用户注册表`,
  [`Create native OAuth provider`]: `创建本机 OAuth 提供者`,
  [`Create New GraphQL API`]: `创建新 GraphQL API`,
  [`Create new OpenAPI`]: `创建新的 OpenAPI`,
  [`Create new product`]: `创建新产品`,
  [`Create OIDC user registry`]: `创建 OIDC 用户注册表`,
  [`Create OpenAPI definition and product definition`]: `创建 OpenAPI 定义和产品定义`,
  [`Create operation`]: `创建操作`,
  [`Create organization`]: `创建组织`,
  [`Create path`]: `创建路径`,
  [`Create plan`]: `创建计划`,
  [`Create portal`]: `创建门户网站`,
  [`Create Product`]: `创建产品`,
  [`Create property`]: `创建属性`,
  [`Create role`]: `创建角色`,
  [`Create role default for the Organization`]: `为组织创建角色缺省值`,
  [`Create sample user registry`]: `创建样本用户注册表`,
  [`Create security definition`]: `创建安全性定义`,
  [`Create space`]: `创建空间`,
  [`Create subscription`]: `创建预订`,
  [`Create TLS client profile`]: `创建 TLS 客户机概要文件`,
  [`Create TLS server profile`]: `创建 TLS 服务器概要文件`,
  [`Create target service`]: `创建目标服务`,
  [`Create third party OAuth provider`]: `创建第三方 OAuth 提供者`,
  [`Create truststore`]: `创建信任库`,
  [`Create user registry`]: `创建用户注册表`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `根据 GraphQL 服务创建 GraphQL 代理`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `基于 OpenAPI 描述的目标服务创建 REST 代理`,
  [`Create a REST proxy based upon a WSDL described target service`]: `基于 WSDL 描述的目标服务创建 REST 代理`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `基于 OpenAPI 描述的目标服务创建 REST 代理`,
  [`Create a REST proxy based upon the WSDL described target service`]: `根据 WSDL 描述的目标服务，创建 REST 代理`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `创建将所有流量路由至目标 API 或服务端点的 REST 代理`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `基于 WSDL 描述的目标服务创建 SOAP 代理`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `根据 WSDL 描述的目标服务，创建 SOAP 代理`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `使用 Handlebars 模板创建新的对象。从上下文中获取变量。`,
  [`Create a portal site for the catalog`]: `为目录配置门户网站站点`,
  [`Create a product by importing a product definition`]: `通过导入产品定义来创建产品`,
  [`Create a security definition`]: `创建安全性定义`,
  [`Create a subscription`]: `创建预订`,
  [`Create an API by importing an OpenAPI definition`]: `通过导入 OpenAPI 定义来创建 API`,
  [`Create an API that calls an existing SOAP service`]: `创建用于调用现有 SOAP 服务的 API`,
  [`Create and manage API provider organizations and owners`]: `创建和管理 API 提供者组织及所有者`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `在目录中创建和管理空间；空间允许对目录进行分区以支持不同的 API 提供者开发团队`,
  [`Create and publish`]: `创建和发布`,
  [`Create and publish APIs and Products`]: `创建并发布 API 和产品`,
  [`Create and subscribe`]: `创建和预订`,
  [`Create assembly`]: `创建组合件`,
  [`Create empty parent object for failed mapping`]: `为失败的映射创建空的父对象`,
  [`Create endpoint`]: `创建端点`,
  [`Create from`]: `创建基础`,
  [`Create from Existing WSDL service`]: `从现有 WSDL 服务创建`,
  [`Create from File or URL`]: `从文件或 URL 创建`,
  [`Create from existing openAPI service`]: `从现有 openAPI 服务创建`,
  [`Create gateway extension`]: `创建网关扩展`,
  [`Create new user`]: `创建新用户`,
  [`Create new version`]: `创建新版本`,
  [`Create product using a template`]: `使用模板创建产品`,
  [`Create provider organization`]: `创建提供者组织`,
  [`Create required child properties for array objects and mapped optional objects`]: `为数组对象和映射的可选对象创建必需的子属性`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `由 OAuth 提供者配置作为样本创建。请确保将此样本与有效的用户注册表结合使用来更新 OAuth 提供者。`,
  [`Created new product`]: `已创建的新产品`,
  [`Create|permission`]: `创建`,
  [`Create|title`]: `创建`,
  [`Creating Draft Product`]: `正在创建草稿产品`,
  [`Creating a Consumer organization`]: `创建使用者组织`,
  [`Creating a consumer organization`]: `创建使用者组织`,
  [`Creating a new Product`]: `创建新产品`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons (CC-BY-4.0) 许可证`,
  [`Credential Extraction Method`]: `凭证抽取方法`,
  [`Credentials for the Sandbox catalog`]: `沙箱目录的凭证`,
  [`Credentials for the {catalogName} catalog`]: `{catalogName} 目录的凭证`,
  [`Credential Name`]: `凭证名称`,
  [`Credential has been added.`]: `已添加凭证。`,
  [`Credential`]: `凭证`,
  [`Credentials`]: `凭证`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `需要凭证才能运行 API Designer 和工具箱。`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `需要凭证才能运行该工具箱。请将凭证文件放入用于安装该工具箱的文件夹中。`,
  [`Credit Card`]: `信用卡`,
  [`Cryptographic Algorithm`]: `密码算法`,
  [`Currency`]: `货币`,
  [`Current password`]: `当前密码`,
  [`Current password is invalid.`]: `当前密码无效。`,
  [`Custom`]: `定制`,
  [`Custom API URL`]: `定制 API URL`,
  [`Custom expression (optional, one per line)`]: `定制表达式（可选，每行一个）`,
  [`Custom expression to remove (optional, one per line)`]: `要移除的定制表达式（可选，每行一个）`,
  [`Custom HTML form`]: `定制 HTML 表单`,
  [`Custom policies`]: `定制策略`,
  [`Custom form TLS profile`]: `定制表单 TLS 概要文件`,
  [`Custom form content security policy`]: `定制表单内容安全策略`,
  [`Custom form CSP header value`]: `定制表单 CSP 头值`,
  [`Custom form endpoint`]: `定制表单端点`,
  [`Custom header pattern`]: `定制头模式`,
  [`Custom subscribability is required if custom visibility is selected`]: `如果选择定制可视性，定制可预订性是必需的`,
  [`Customize`]: `定制`,
  [`Customize email sender information`]: `定制电子邮件发件人信息`,
  [`Customize notification templates`]: `定制通知模板`,
  [`Customize the plan API list`]: `定制计划 API 列表`,
  [`Czech`]: `捷克语`,
  [`DATE MODIFIED`]: `修改日期`,
  [`DELETE`]: `删除`,
  [`Date created`]: `创建日期`,
  [`Description`]: `描述`,
  [`DETAIL`]: `详细信息`,
  [`DN Base`]: `DN 库`,
  [`DNS Schemes`]: `DNS 方案`,
  [`Dashboard`]: `仪表板`,
  [`Data encoded form body`]: `数据编码表单主体`,
  [`Data flow in API Connect`]: `API Connect 中的数据流`,
  [`DataPower (v5 compatible)`]: `DataPower（与 V5 兼容）`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway（与 V5 兼容）`,
  [`DataPower Gateway policies`]: `DataPower Gateway 策略`,
  [`Debug`]: `调试`,
  [`Debug rule`]: `调试规则`,
  [`Debug XACML policy`]: `调试 XACML 策略`,
  [`Dec`]: `十二月`,
  [`Decline`]: `拒绝`,
  [`Decline and send`]: `拒绝并发送`,
  [`Decode Request Params`]: `解码请求参数`,
  [`Decrement`]: `减量`,
  [`Decrypt`]: `解密`,
  [`Decrypt Crypto JWK variable name`]: `解密加密 JWK 变量名称`,
  [`Decrypt Crypto Object`]: `解密加密对象`,
  [`Decrypt a buffer using the specified certificate`]: `使用指定的证书解密缓冲区`,
  [`Default`]: `缺省值`,
  [`Default behavior`]: `缺省行为`,
  [`Default gateways`]: `缺省网关`,
  [`default gateway services configured`]: `已配置缺省网关服务`,
  [`Default Availability Zone`]: `缺省可用性区域`,
  [`Default Base endpoints`]: `缺省基本端点`,
  [`Default availability zone`]: `缺省可用性区域`,
  [`Default base endpoints`]: `缺省基本端点`,
  [`Default HTML form`]: `缺省 HTML 表单`,
  [`Default limit`]: `缺省限制`,
  [`Default OAuth Provider Settings`]: `缺省 OAuth 提供者设置`,
  [`Default OAuth Provider Settings Object`]: `缺省 OAuth 提供者设置对象`,
  [`Default Plan`]: `缺省计划`,
  [`Default TLS client profile`]: `缺省 TLS 客户机概要文件`,
  [`Default TLS server keystore`]: `缺省 TLS 服务器密钥库`,
  [`Default TLS server profile`]: `缺省 TLS 服务器概要文件`,
  [`Default burst-limit`]: `缺省突发流量限制`,
  [`Default form`]: `缺省表单`,
  [`Default keystore for access_token signing key`]: `访问令牌签名密钥的缺省密钥库`,
  [`Default keystore for id_token signing key`]: `标识令牌签名密钥的缺省密钥库`,
  [`Default keystore for temporary token signing key`]: `临时令牌签名密钥的缺省密钥库`,
  [`Default rate-limit`]: `缺省速率限制`,
  [`Default scopes`]: `缺省作用域`,
  [`Default table entry`]: `缺省表条目`,
  [`Default validator endpoint`]: `缺省验证程序端点`,
  [`Default validator endpoint set by OAuth provider`]: `OAuth 提供者设置的缺省验证程序端点`,
  [`Default value - no extra constraints apply.`]: `缺省值 - 未应用额外约束。`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `定义第三方 OAuth 提供者以发布和验证令牌来保护对 API 的访问。`,
  [`Define catalog specific values for this property`]: `为此属性定义特定于目录的值`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `定义用于管理 API、产品、目录、应用程序和相关设置的组。`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `定义要对特定条件执行的策略集。可以定义多个案例，每个案例可与一个任意条件关联。仅会执行第一个匹配案例。`,
  [`Define the API protection source. At least one option must be enabled.`]: `定义 API 保护源。必须至少启用一个选项。`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `OIDC 授权服务器会将授权代码重定向到以下端点。当客户在 OIDC 提供者上注册应用程序时，需要此重定向端点。例如，如果此用户注册表由提供者组织使用，那么客户必须向 OIDC 提供者注册该提供者组织的重定向端点。`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `定义要在映射中使用的输入。还可以向映射分配标题和描述。`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `定义 OpenID 提供者端点以向 OpenID 提供者发送认证请求。`,
  [`Define the outputs to be used in the map.`]: `定义要在映射中使用的输出。`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `定义免费试用天数和套餐定价。用户将基于其预订日期进行计费。`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `定义在策略执行期间抛出错误时流是否继续。如果未选中此选项，那么将触发捕获流。`,
  [`Definition`]: `定义`,
  [`Definition Object`]: `定义对象`,
  [`Definitions`]: `定义`,
  [`Delete`]: `删除`,
  [`Delete Application`]: `删除应用程序`,
  [`Delete Cloud Connection.`]: `删除云连接。`,
  [`Delete Selected APIs`]: `删除所选的 API`,
  [`Delete selected client profiles`]: `删除所选的客户机概要文件`,
  [`Delete selected keystores`]: `删除所选的密钥库`,
  [`Delete selected mail servers`]: `删除所选的邮件服务器`,
  [`Delete Selected Products`]: `删除所选的产品`,
  [`Delete selected truststores`]: `删除所选的信任库`,
  [`Delete selected user registries`]: `删除所选的用户注册表`,
  [`Delete Staged Product`]: `删除已登台的产品`,
  [`Delete a space`]: `删除空间`,
  [`Delete a catalog`]: `删除目录`,
  [`Delete API Key`]: `删除 API 密钥`,
  [`Delete API Key failed`]: `删除 API 密钥失败`,
  [`Delete availability zone`]: `删除可用性区域`,
  [`Delete selected requests`]: `删除所选请求`,
  [`Delete row`]: `删除行`,
  [`Delete|title`]: `删除`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `如果您拥有大量的使用者组织、应用程序、已发布的产品和其他资源，那么删除目录可能会失败。在删除目录之前，请考虑清除这些资源。`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `如果您拥有大量目录、使用者组织、应用程序、已发布的产品和其他资源，那么删除提供者组织可能会失败。在删除提供者组织之前，请考虑清除这些资源。`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `如果您拥有大量的使用者组织、应用程序、已发布的产品和其他资源，那么删除空间可能会失败。在删除空间之前，请考虑清除这些资源。`,
  [`Deleting {{name}}...`]: `正在删除 {name}...`,
  [`Deprecate`]: `弃用`,
  [`Deprecate product`]: `弃用产品`,
  [`Deprecated`]: `已弃用`,
  [`Deprecate|permission`]: `弃用`,
  [`Description of gateway`]: `网关描述`,
  [`Description text for the behaviour of the modal.`]: `模态行为的描述文本。`,
  [`Descriptive name`]: `描述性名称`,
  [`Design`]: `设计`,
  [`Designate a current member as owner of a consumer organization.`]: `将当前成员指定为使用者组织的所有者。`,
  [`Designate a current member as owner of a provider organization.`]: `将当前成员指定为提供者组织的所有者。`,
  [`Designer`]: `Designer`,
  [`Destination`]: `目标`,
  [`Determines which credential-extraction method to use.`]: `确定要使用的凭证抽取方法。`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `确定是否在模式中包含此类型/字段和任何适用的自变量。`,
  [`Develop`]: `开发`,
  [`Develop APIs`]: `开发 API`,
  [`Develop APIs and products`]: `开发 API 和产品`,
  [`Developer`]: `开发人员`,
  [`developer`]: `开发人员`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `开发人员社区`,
  [`Developers can request a state upgrade from development to production`]: `开发人员可请求从开发到生产的状态升级`,
  [`Development`]: `开发`,
  [`Directive`]: `伪指令`,
  [`Director Endpoint`]: `Director 端点`,
  [`Director TLS client profile`]: `导向器 TLS 客户机概要文件`,
  [`Disable`]: `禁用`,
  [`Disable Activity log`]: `禁用活动日志`,
  [`Disable Self-Service Onboarding`]: `禁用自助服务引导`,
  [`Disable spaces`]: `禁用空间`,
  [`Disable buffering`]: `禁用缓冲`,
  [`Disable Self-Service Onboarding Approval`]: `禁用自助服务上线审批`,
  [`Disable overriding consumer organizations invitation timeout.`]: `禁用覆盖使用者组织邀请超时。`,
  [`Disabled`]: `已禁用`,
  [`disabled`]: `已禁用`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `禁用自助服务引导将需要 API 提供者、现有使用者组织所有者或管理员邀请所有应用程序开发人员。`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `禁用自助服务上线审批将自动审批所有上线请求。`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `禁用不会将使用者组织邀请超时设置为目录超时。`,
  [`Discover targets`]: `发现目标`,
  [`Display table checkboxes`]: `显示表复选框`,
  [`Display vanity endpoint`]: `显示虚拟端点`,
  [`Do not publish API (continue editting)`]: `不发布 API（继续编辑）`,
  [`Do not require applications or users to authenticate`]: `不要求应用程序或用户进行认证`,
  [`Do not require authentication`]: `不需要认证`,
  [`Do not share the resource with provider organizations`]: `不与提供者组织共享资源`,
  [`Do not share the API gateway with provider organizations`]: `不与提供者组织共享 API Gateway`,
  [`Do not share the service with provider organizations`]: `不与提供者组织共享服务`,
  [`Do not use Dynamic DNS`]: `不使用动态 DNS`,
  [`Do you want to remove these references?`]: `是否要移除这些引用？`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker 文档`,
  [`Docs`]: `文档`,
  [`Document Editor`]: `文档编辑器`,
  [`Documentation and tutorials with step-by-step instructions`]: `逐步详解文档和教程`,
  [`Domain name`]: `域名`,
  [`Domain Name`]: `域名`,
  [`Domains handled by gateway via SNI`]: `网关通过 SNI 处理的域`,
  [`Don't have an account?`]: `还没有帐户？`,
  [`Done`]: `完成`,
  [`Download`]: `下载`,
  [`Download Gateway`]: `下载网关`,
  [`Download and install`]: `下载并安装`,
  [`Download and set up DataPower API Gateway`]: `下载并设置 DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `下载并设置 DataPower API Gateway，以便在本地或任何云上使用。`,
  [`Download for Linux`]: `适用于 Linux 的下载`,
  [`Download for Mac`]: `适用于 Mac 的下载`,
  [`Download for Windows`]: `适用于 Windows 的下载`,
  [`Download gateway`]: `下载网关`,
  [`Download schema`]: `下载模式`,
  [`Download Toolkit`]: `下载工具箱`,
  [`Download toolkit`]: `下载工具箱`,
  [`Download toolkit and credentials for various platforms`]: `下载适用于各个平台的工具箱和凭证`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `找不到为此云连接所下载的凭证。登录将使用缺省凭证。`,
  [`Dutch`]: `荷兰语`,
  [`Draft {type} creation failed`]: `草稿 {type} 创建失败`,
  [`Drafts`]: `草稿`,
  [`Drag and drop files here or click to upload`]: `将文件拖放到此处或单击以上载`,
  [`Drag & Drop`]: `拖放`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `动态 DNS`,
  [`Dynamic OAuth configuration from a URL`]: `来自 URL 的动态 OAuth 配置`,
  [`Dynamic OAuth configuration from a literal string`]: `来自文字串的动态 OAuth 配置`,
  [`Dynamic group`]: `动态组`,
  [`Dynamic table entries`]: `动态表条目`,
  [`ENABLE`]: `启用`,
  [`Encoded`]: `已编码`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `每一个空间都由不同的 API 提供者开发团队使用，并且具有自己的一组管理功能，这些功能专门与由关联团队发布至该空间的 API 相关，使得每一个团队都可以独立地管理自己的 API。当您将 API 登台或发布至已启用空间的目录时，需要在要登台或发布 API 的目录中指定空间。`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `每个提供者组织都拥有一组 API、产品、计划和目录。`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `轻松创建、保护、管理、共享和分析云上和本地的 API。`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `每个提供者组织都拥有一组 API、产品、计划和目录。[了解更多信息]({link})`,
  [`Edit`]: `编辑`,
  [`Edit access token TTL`]: `编辑访问令牌 TTL`,
  [`Edit admin organization invitation timeout`]: `编辑管理组织邀请超时`,
  [`Edit API`]: `编辑 API`,
  [`Edit API Connect UI`]: `编辑 API Connect UI`,
  [`Edit API endpoint for unenforced APIs`]: `编辑未实施的 API 的 API 端点`,
  [`Edit API gateway service`]: `编辑 API Gateway 服务`,
  [`Edit API Path`]: `编辑 API 路径`,
  [`Edit API security definition`]: `编辑 API 安全性定义`,
  [`Edit API user registries`]: `编辑 API 用户注册表`,
  [`Edit analytics service`]: `编辑分析服务`,
  [`Edit application`]: `编辑应用程序`,
  [`Edit Assembly`]: `编辑组合件`,
  [`Edit authentication URL user registry`]: `编辑认证 URL 用户注册表`,
  [`Edit availability zone`]: `编辑可用性区域`,
  [`Edit Availablity Zone`]: `编辑可用性区域`,
  [`Edit and apply constraints to your selected items.`]: `编辑约束并将其应用于所选项。`,
  [`Edit billing integration`]: `编辑计费集成`,
  [`Edit Catalog Details`]: `编辑目录详细信息`,
  [`Edit cloud setting invitation timeout`]: `编辑云设置邀请超时`,
  [`Edit constraints`]: `编辑约束`,
  [`Edit consumer organization`]: `编辑使用者组织`,
  [`Edit DNS Scheme`]: `编辑 DNS 方案`,
  [`Edit DataPower API gateway service`]: `编辑 DataPower API Gateway 服务`,
  [`Edit DataPower gateway service`]: `编辑 DataPower Gateway 服务`,
  [`Edit definition`]: `编辑定义`,
  [`Edit dynamic DNS`]: `编辑动态 DNS`,
  [`Edit email server`]: `编辑电子邮件服务器`,
  [`Edit extensions`]: `编辑扩展`,
  [`Edit Gateway Service`]: `编辑网关服务`,
  [`Edit gateway`]: `编辑网关`,
  [`Edit gateway extension`]: `编辑网关扩展`,
  [`Edit Gateways`]: `编辑网关`,
  [`Edit IBM-managed gateway`]: `编辑 IBM 管理的网关`,
  [`Edit Invitation Timeout`]: `编辑邀请超时`,
  [`Edit gateways`]: `编辑网关`,
  [`Edit invitation timeout`]: `编辑邀请超时`,
  [`Edit JSON`]: `编辑 JSON`,
  [`Edit keystore`]: `编辑密钥库`,
  [`Edit LDAP user registry`]: `编辑 LDAP 用户注册表`,
  [`Edit LTPA Token`]: `编辑 LTPA 令牌`,
  [`Edit lifecycle approvals`]: `编辑生命周期核准`,
  [`Edit local user registry`]: `编辑本地用户注册表`,
  [`Edit native OAuth provider`]: `编辑本机 OAuth 提供者`,
  [`Edit New Billing System`]: `编辑新的计费系统`,
  [`Edit Notification Server`]: `编辑通知服务器`,
  [`Edit Notification template`]: `编辑通知模板`,
  [`Edit OAuth Provider`]: `编辑 OAuth 提供者`,
  [`Edit OAuth provider details`]: `编辑 OAuth 提供者详细信息`,
  [`Edit OAuth Provider Visibility`]: `编辑 OAuth 提供者可视性`,
  [`Edit OIDC User Registry`]: `编辑 OIDC 用户注册表`,
  [`Edit Onboarding`]: `编辑引导`,
  [`Edit Operation`]: `编辑操作`,
  [`Edit Organization`]: `编辑组织`,
  [`Edit Path`]: `编辑路径`,
  [`Edit Path Parameters`]: `编辑路径参数`,
  [`Edit notification template`]: `编辑通知模板`,
  [`Edit OAuth provider`]: `编辑 OAuth 提供者`,
  [`Edit OAuth provider visibility`]: `编辑 OAuth 提供者可视性`,
  [`Edit OIDC user registry`]: `编辑 OIDC 用户注册表`,
  [`Edit onboarding`]: `编辑引导`,
  [`Edit operation`]: `编辑操作`,
  [`Edit organization`]: `编辑组织`,
  [`Edit path`]: `编辑路径`,
  [`Edit path parameters`]: `编辑路径参数`,
  [`Edit plan`]: `编辑计划`,
  [`Edit portal`]: `编辑门户网站`,
  [`Edit portal service`]: `编辑门户网站服务`,
  [`Edit Portal Settings`]: `编辑门户网站设置`,
  [`Edit product`]: `编辑产品`,
  [`Edit product APIs`]: `编辑产品 API`,
  [`Edit Product Subscribability`]: `编辑产品可预订性`,
  [`Edit Product Visibility`]: `编辑产品可视性`,
  [`Edit Property`]: `编辑属性`,
  [`Edit Provider Organization`]: `编辑提供者组织`,
  [`Edit Refresh Token TTL`]: `编辑刷新令牌 TTL`,
  [`Edit remote gateway`]: `编辑远程网关`,
  [`Edit Reset Password TTL`]: `编辑重置密码 TTL`,
  [`Edit Role`]: `编辑角色`,
  [`Edit Role Default for Provider Organizations`]: `编辑提供者组织的角色缺省值`,
  [`Edit Role for Provider Organizations`]: `编辑提供者组织的角色`,
  [`Edit property`]: `编辑属性`,
  [`Edit provider organization`]: `编辑提供者组织`,
  [`Edit refresh token TTL`]: `编辑刷新令牌 TTL`,
  [`Edit reset password TTL`]: `编辑重置密码 TTL`,
  [`Edit role`]: `编辑角色`,
  [`Edit role default for Provider Organizations`]: `编辑提供者组织的角色缺省值`,
  [`Edit role for Provider Organizations`]: `编辑提供者组织的角色`,
  [`Edit Roles`]: `编辑角色`,
  [`Edit Schema in JSON/XML`]: `在 JSON/XML 中编辑模式`,
  [`Edit security definition`]: `编辑安全性定义`,
  [`Edit sender`]: `编辑发件人`,
  [`Edit sender & email server`]: `编辑发件人和电子邮件服务器`,
  [`Edit sender Info`]: `编辑发件人信息`,
  [`Edit service visibility`]: `编辑服务可视性`,
  [`Edit show/hide settings`]: `编辑显示/隐藏设置`,
  [`Edit space details`]: `编辑空间详细信息`,
  [`Edit TLS client profile`]: `编辑 TLS 客户机概要文件`,
  [`Edit TLS client profile visibility`]: `编辑 TLS 客户机概要文件可视性`,
  [`Edit TLS server profile`]: `编辑 TLS 服务器概要文件`,
  [`Edit third party OAuth provider`]: `编辑第三方 OAuth 提供者`,
  [`Edit truststore`]: `编辑信任库`,
  [`Edit unenforced API`]: `编辑未实施的 API`,
  [`Edit user registries`]: `编辑用户注册表`,
  [`Edit user registry`]: `编辑用户注册表`,
  [`Edit user registry visibility`]: `编辑用户注册表可视性`,
  [`Edit user registry for API Manager`]: `编辑 API Manager 的用户注册表`,
  [`Edit user registry for Cloud Manager`]: `编辑 Cloud Manager 的用户注册表`,
  [`Edit vanity API endpoints`]: `编辑虚拟 API 端点`,
  [`Edit visibility`]: `编辑可视性`,
  [`Edit XML`]: `编辑 XML`,
  [`Edit app lifecycle approved`]: `编辑应用程序生命周期已核准`,
  [`Edit app lifecycle cancelled`]: `编辑应用程序生命周期已取消`,
  [`Edit app lifecycle denied`]: `编辑应用程序生命周期已被拒绝`,
  [`Edit app lifecycle pending`]: `编辑应用程序生命周期暂挂中`,
  [`Edit app lifecycle request`]: `编辑应用程序生命周期请求`,
  [`Edit app reinstated`]: `编辑应用程序已恢复`,
  [`Edit app suspended`]: `编辑应用程序已暂挂`,
  [`Edit audit setting`]: `编辑审计设置`,
  [`Edit definitions`]: `编辑定义`,
  [`Enable external group mapping`]: `启用外部组映射`,
  [`Edit extra constraints for the GraphQL schema`]: `编辑 GraphQL 模式的额外约束`,
  [`Edit GraphQL schema|title`]: `编辑 GraphQL 模式`,
  [`Edit inline schema`]: `编辑内联模式`,
  [`Edit inputs`]: `编辑输入`,
  [`Edit invitation`]: `编辑邀请`,
  [`Edit mail server test connection`]: `编辑邮件服务器测试连接`,
  [`Edit member invitation`]: `编辑成员邀请`,
  [`Edit notification templates`]: `编辑通知模板`,
  [`Edit notification templates:`]: `编辑通知模板：`,
  [`Edit operation list`]: `编辑操作列表`,
  [`Edit or upload JSON schema`]: `编辑或上载 JSON 模式`,
  [`Edit outputs`]: `编辑输出`,
  [`Edit password changed`]: `编辑密码已更改`,
  [`Edit password reset`]: `编辑密码已重置`,
  [`Edit plan APIs`]: `编辑计划 API`,
  [`Edit schema`]: `编辑模式`,
  [`Edit schema|button`]: `编辑模式`,
  [`Edit schema|title`]: `编辑模式`,
  [`Edit settings for roles, notifications and more.`]: `编辑角色、通知等的设置。`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `编辑用户注册表、角色、端点等的设置`,
  [`Edit sign up`]: `编辑注册`,
  [`Edit task product lifecycle approved`]: `编辑任务产品生命周期已核准`,
  [`Edit task product lifecycle cancelled`]: `编辑任务产品生命周期已取消`,
  [`Edit task product lifecycle denied`]: `编辑任务产品生命周期已被拒绝`,
  [`Edit task product lifecycle pending`]: `编辑任务产品生命周期暂挂中`,
  [`Edit task product lifecycle request`]: `编辑任务产品生命周期请求`,
  [`Edit task subscription approved`]: `编辑任务预订已核准`,
  [`Edit task subscription cancelled`]: `编辑任务预订已取消`,
  [`Edit task subscription denied`]: `编辑任务预订已被拒绝`,
  [`Edit task subscription pending`]: `编辑任务预订暂挂中`,
  [`Edit task subscription request`]: `编辑任务预订请求`,
  [`Edit template`]: `编辑模板`,
  [`Edit the TLS client profile.`]: `编辑 TLS 客户机概要文件。`,
  [`Edit the TLS Server profiles`]: `编辑 TLS 服务器概要文件`,
  [`Edit the keystore title.`]: `编辑密钥库标题。`,
  [`Edit the organization summary details as required`]: `根据需要编辑组织摘要详细信息`,
  [`Edit the organization title`]: `编辑组织标题`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `编辑用于配置电子邮件服务器的参数。除非指定为可选字段，否则字段是必填字段。`,
  [`Edit signed token`]: `编辑签名的令牌`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `编辑电子邮件模板的文本。变量包含在花括号中，且不可编辑。`,
  [`Edit the truststore.`]: `编辑信任库。`,
  [`Edit the visibility for each resource.`]: `编辑每个资源的可视性。`,
  [`Edit user details`]: `编辑用户详细信息`,
  [`Edit {notificationTemplate}`]: `编辑 {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `编辑、组合、保护和测试 API`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `编辑、组合、保护和测试 API。使用产品打包 API 以发布给使用者。`,
  [`Edit, upload, or infer XML schema`]: `编辑、上载或推断 XML 模式`,
  [`Editors`]: `编辑器`,
  [`Edit|permission`]: `编辑`,
  [`Edit API key timeout`]: `编辑 API 密钥超时`,
  [`Edit Base64 encoding for temporary tokens`]: `编辑用于临时令牌的 Base64 编码`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `必须提供“标识令牌签名加密对象”或“标识令牌签名密钥”。`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `必须提供“JWT 验证加密对象”或“JWT 验证 JWK”。`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `选择相应选项以使用在 API 中定义的端点，或者提供缺省验证程序端点。`,
  [`Element name for JSON array elements`]: `JSON 数组元素的元素名称`,
  [`Email`]: `电子邮件`,
  [`Email address`]: `电子邮件地址`,
  [`Email Content`]: `电子邮件内容`,
  [`Email endpoint`]: `电子邮件端点`,
  [`Email required`]: `需要电子邮件`,
  [`Email sender`]: `电子邮件发件人`,
  [`Email server`]: `电子邮件服务器`,
  [`Email server configuration`]: `电子邮件服务器配置`,
  [`Email servers`]: `电子邮件服务器`,
  [`Empty case`]: `空案例`,
  [`Empty catch`]: `空捕获`,
  [`Empty JSON array handling`]: `空 JSON 数组处理`,
  [`Empty XML element handling`]: `空 XML 元素处理`,
  [`Enable`]: `启用`,
  [`Enable API consumers to invite collaborators and assign roles`]: `使 API 使用者能够邀请合作者并分配角色`,
  [`Enable application lifecycle`]: `启用应用程序生命周期`,
  [`Enable billing`]: `启用计费`,
  [`Enable gateway services`]: `启用网关服务`,
  [`Enable gateway services and policies`]: `启用网关服务和策略`,
  [`Enable GraphiQL`]: `启用 GraphiQL`,
  [`Enable GraphiQL editor`]: `启用 GraphiQL 编辑器`,
  [`Enable GraphiQL Editor on browser`]: `在浏览器上启用 GraphiQL 编辑器`,
  [`Enable JSON post processing`]: `启用 JSON 后处理`,
  [`Enable lifecycle approvals`]: `启用生命周期核准`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `启用 NONCE 扩展以阻止再次使用受感染的请求（重新播放）`,
  [`Enable OAuth providers`]: `启用 OAuth 提供者`,
  [`Enable OIDC`]: `启用 OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `启用 OpenID Connect 模板以生成标识令牌。`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `启用 PKCE 扩展以允许公用客户机减轻拦截授权代码的威胁`,
  [`Enable production mode`]: `启用生产方式`,
  [`Enable Proxy`]: `启用代理`,
  [`Enable Self-Service Onboarding`]: `启用自助服务引导`,
  [`Enable spaces`]: `启用空间`,
  [`Enable token management`]: `启用令牌管理`,
  [`Enable buffering`]: `启用缓冲`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `无需邀请，即可让 API 使用者创建开发门户网站帐户。`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `对 API 启用跨域资源共享 (CORS) 访问控制。`,
  [`Enable debug response headers`]: `启用调试响应头`,
  [`Enable proof key for code exchange`]: `启用用于代码交换的证明密钥`,
  [`Enable publishing of this product`]: `启用此产品的发布`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `通过门户网站 OIDC 端点启用第三方授权代码的重定向`,
  [`Enable security`]: `启用安全性`,
  [`Enable Self-Service Onboarding Approval`]: `启用自助服务上线审批`,
  [`Enable the following gateway services and policies for this catalog`]: `对此目录启用下列网关服务和策略`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `支持用户配置审计事件。审计事件是有关谁在何时执行什么事件的详细汇总。`,
  [`Enable/disable custom notification templates`]: `启用/禁用定制通知模板`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `启用或禁用用于邀请和密码重置令牌的 Base64 编码`,
  [`Enable overriding consumer organizations invitation timeout`]: `启用覆盖使用者组织邀请超时`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `可以启用或禁用用于临时令牌的 Base64 编码。 临时令牌是邀请和密码重置令牌。 启用 Base64 编码后，临时令牌将以 Base64 格式进行编码。 禁用 Base64 编码后，临时令牌将保留为 JSON Web 令牌 (JWT) 格式，并且意味着用户接收到的 URL 长度将变短。`,
  [`Enabled`]: `已启用`,
  [`enabled`]: `已启用`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `通过启用应用程序生命周期，可使应用程序处于开发或生产状态，并且可将其路由至不同网关和端点。`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `启用自助服务上线审批将需要 API 提供者或管理员审批所有上线请求。`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `启用自助服务引导将允许应用程序开发人员进行注册，而无需 API 提供者、现有使用者组织所有者或管理员发出邀请。`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `启用会将所有使用者组织邀请超时设置为目录邀请超时。`,
  [`Encode Query params`]: `编码查询参数`,
  [`Encrypt`]: `加密`,
  [`Encrypt Crypto Object`]: `“加密”加密对象`,
  [`Encrypt JWK variable name`]: `加密 JWK 变量名称`,
  [`Encrypt a buffer using the specified certificate`]: `使用指定的证书加密缓冲区`,
  [`Encryption Algorithm`]: `加密算法`,
  [`Endpoint`]: `端点`,
  [`Endpoint URLs`]: `端点 URL`,
  [`Endpoint behavior`]: `端点行为`,
  [`Endpoint Behavior`]: `端点行为`,
  [`Endpoint for unenforced APIs`]: `未实施的 API 的端点`,
  [`Endpoints`]: `端点`,
  [`Enforce Required Params`]: `实施必需参数`,
  [`Enforce the API by using API Connect Gateway.`]: `使用 API Connect Gateway 实施 API。`,
  [`Enforced`]: `已实施`,
  [`English`]: `英语`,
  [`Enter Summary`]: `输入摘要`,
  [`Enter URL`]: `输入 URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `输入用户注册表名称。如果此项为空，那么缺省情况下将使用 API Manager 本地用户注册表`,
  [`Enter a catalog name`]: `输入目录名称`,
  [`Enter a Display Name`]: `输入显示名称`,
  [`Enter a space name`]: `输入空间名称`,
  [`Enter a brief description`]: `输入简短描述`,
  [`Enter a display name`]: `输入显示名称`,
  [`Enter a temporary sign in password`]: `输入临时登录密码`,
  [`Enter an Organization Name`]: `输入组织名称`,
  [`Enter an Organization Title`]: `输入组织标题`,
  [`Enter catalog title`]: `输入目录标题`,
  [`Enter details of the consumer organization`]: `输入使用者组织的详细信息`,
  [`Enter details of the product`]: `输入产品的详细信息`,
  [`Enter details of the provider organization`]: `输入提供者组织的详细信息`,
  [`Enter details of this API`]: `输入此 API 的详细信息`,
  [`Enter email of the new user`]: `输入新用户的电子邮件`,
  [`Enter first name of the new user`]: `输入新用户的名字`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `输入一个或多个电子邮件地址。使用逗号分隔多个地址。`,
  [`Enter organization name`]: `输入组织名称`,
  [`Enter organization title`]: `输入组织标题`,
  [`Enter password`]: `输入密码`,
  [`Enter space title`]: `输入空间标题`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `通过提供主机名和端口号，输入网关的 API 端点。 例如：api.mycompany.com:8090`,
  [`Enter the API summary details`]: `输入 API 摘要详细信息`,
  [`Enter the API summary details. [Learn more]({link})`]: `输入 API 摘要详细信息。[了解更多信息]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `输入要代理的目标服务的 URL`,
  [`Enter the catalog summary details`]: `输入目录摘要详细信息`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `输入目录摘要详细信息；您可以在创建目录后对其进行完整配置`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `输入要邀请成为管理员组织成员的用户的电子邮件地址`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `输入要邀请成为目录成员的用户的电子邮件地址`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `输入要邀请成为提供者组织成员的用户的电子邮件地址`,
  [`Enter the email address of the user to invite as a member of the space`]: `输入要邀请成为空间成员的用户的电子邮件地址`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `输入要将所有权移交给的用户的电子邮件地址。`,
  [`Enter the full path to JSON or YAML file`]: `输入到 JSON 或 YAML 文件的完整路径`,
  [`Enter the full path to WSDL file`]: `输入到 WSDL 文件的完整路径`,
  [`Enter the full path to YAML file`]: `输入到 YAML 文件的完整路径`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `输入在安装期间定义的分析数据获取端点的标准域名。`,
  [`Enter the last name of the new user`]: `输入新用户的姓氏`,
  [`Enter the owner's email address to send the invitation`]: `输入用于发送邀请的所有者电子邮件地址`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `输入用于配置网关服务的参数。除非指定为可选字段，否则字段是必填字段。端点是 DataPower 上的 API Gateway 服务端点。基本 API 端点是用于入局 API 调用的 API Gateway 端点。对于 SNI，输入 * 以允许所有主机（必需），同时输入主机名以允许特定主机。选择 TLS 概要文件以支持每个主机。`,
  [`Enter the password for the certificate file if required.`]: `输入证书文件的密码（如果需要）。`,
  [`Enter the provider organization summary details`]: `输入提供者组织摘要详细信息`,
  [`Enter the schema definitiom summary details`]: `输入模式定义摘要详细信息`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `输入空间摘要详细信息；您可以在创建空间后对其进行完整配置`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `输入空间摘要详细信息；您可以在创建空间后对其进行完整配置`,
  [`Enter the summary details for the new product`]: `输入新产品的摘要详细信息`,
  [`Enter the summary details for this role`]: `输入此角色的摘要详细信息`,
  [`Enter title of the organization`]: `输入组织的标题`,
  [`Enter user email`]: `输入用户电子邮件`,
  [`Enter username of the existing user`]: `输入现有用户的用户名`,
  [`Enter username of the new user`]: `输入新用户的用户名`,
  [`Error`]: `错误`,
  [`Error ID`]: `错误标识`,
  [`Error:`]: `错误：`,
  [`Copy error ID to Clipboard`]: `将错误标识复制到剪贴板`,
  [`Error Console`]: `错误控制台`,
  [`Error connecting`]: `连接错误`,
  [`Error connecting to GraphQL server`]: `连接到 GraphQL 服务器时出错`,
  [`Error getting user info during login. Please login with different user.`]: `登录期间获取用户信息时出错。 请以其他用户身份登录。`,
  [`Error content`]: `错误内容`,
  [`Error message`]: `错误消息`,
  [`Error name`]: `错误名称`,
  [`Error status code`]: `错误状态码`,
  [`Error status code is invalid`]: `错误状态码无效`,
  [`Error status reason`]: `错误状态原因`,
  [`Errors`]: `错误`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `错误通常来自于不正确的消息或字符。执行测试前，请确保提供正确的提供者信息和客户机信息。`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `尝试从 URL {url} 获取 API 时出错。 请检查 URL、用户名和密码。 错误消息：{message}`,
  [`Event`]: `事件`,
  [`Event gateway management client TLS client profile`]: `事件网关管理客户机 TLS 客户机概要文件`,
  [`Event load`]: `事件负载`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `每个使用者组织都有一个所有者。所有者拥有全部管理许可权，并且可以管理预订和应用程序。`,
  [`Exchange authorization code for tokens`]: `用授权代码换取令牌`,
  [`Exchange code for token`]: `用代码换取令牌`,
  [`Execute`]: `执行`,
  [`Execute migration target`]: `执行迁移目标`,
  [`Executes any catalog-level post-invoke handlers`]: `执行任何目录级调用后处理程序`,
  [`Executes any catalog-level pre-invoke handlers`]: `执行任何目录级调用前处理程序`,
  [`Existing`]: `现有`,
  [`Existing {docType}`]: `现有 {docType}`,
  [`Existing API Connect user`]: `现有 API Connect 用户`,
  [`Existing OpenAPI`]: `现有 OpenAPI`,
  [`Existing product`]: `现有产品`,
  [`Existing SOAP API has been validated`]: `已验证现有 SOAP API`,
  [`Existing products in the catalog will be moved to a default space.`]: `将目录中的现有产品移至缺省空间中。`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `现有用户是当前成员。您还可以从其他用户注册表中激活新成员。`,
  [`Expires On`]: `到期时间`,
  [`Expiry`]: `截止期`,
  [`Explorer`]: `Explorer`,
  [`Expose`]: `公开`,
  [`Extend the schema to specify the cost of types and fields`]: `扩展模式以指定类型和字段的开销`,
  [`Extended`]: `已扩展`,
  [`Extension Type:`]: `扩展类型：`,
  [`Extensions`]: `扩展`,
  [`Extension has been uploaded.`]: `扩展已上载。`,
  [`External`]: `外部`,
  [`External Doc Description`]: `外部文档描述`,
  [`External doc description`]: `外部文档描述`,
  [`External Documentation URL`]: `外部文档 URL`,
  [`External documentation URL`]: `外部文档 URL`,
  [`External Role mapping`]: `外部角色映射`,
  [`External URL`]: `外部 URL`,
  [`Extract Identity Settings`]: `抽取身份设置`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `因子标识`,
  [`Fail`]: `失败`,
  [`Failed to change account password.`]: `无法更改帐户密码。`,
  [`Failed to create analytics service {title}.`]: `无法创建分析服务 {title}。`,
  [`Failed to create analytics insights service {title}.`]: `无法创建分析洞察服务 {title}。`,
  [`Analytics insights service {title} has been updated.`]: `已更新分析洞察服务 {title}。`,
  [`About the analytics insights service`]: `关于分析洞察服务`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `分析洞察服务从网关服务收集 API 事件。 每个网关服务都可以具有关联的分析洞察服务。`,
  [`Advanced analytics insights configuration`]: `高级分析洞察配置`,
  [`Analytics insights service {title} has been created.`]: `已创建分析洞察服务 {title}。`,
  [`Analytics Insights details`]: `分析洞察详细信息`,
  [`Management endpoint on the analytics insights service`]: `分析洞察服务上的管理端点`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `输入安装期间定义的 Analytics Insights 导向器端点的标准域名。`,
  [`TYPE`]: `类型`,
  [`Failed to create email server`]: `无法创建电子邮件服务器`,
  [`Failed to create gateway service`]: `无法创建网关服务`,
  [`Failed to create gateway service {name}.`]: `无法创建网关服务 {name}。`,
  [`Failed to create user registry {title}.`]: `无法创建用户注册表 {title}。`,
  [`Failed to delete a group`]: `无法删除组。`,
  [`Failed to get TLS client profiles`]: `无法获取 TLS 客户机概要文件`,
  [`Failed to get catalogs`]: `无法获取目录`,
  [`Failed to get configured OAuth providers`]: `无法获取配置的 OAuth 提供者`,
  [`Failed to get policies of gateways`]: `无法获取网关策略`,
  [`Failed to get user registries`]: `无法获取用户注册表`,
  [`Failed to introspect schema from server:`]: `无法从服务器自省模式：`,
  [`Failed to make API online`]: `未能使 API 联机`,
  [`Failed to register catalog member {user}.`]: `无法注册目录成员 {user}。`,
  [`Failed to remove analytics service: {arg}`]: `无法移除分析服务：{arg}`,
  [`Failed to remove availability zone: {arg}`]: `无法移除可用性区域：{arg}`,
  [`Failed to remove gateway service: {arg}`]: `无法移除网关服务：{arg}`,
  [`Failed to remove mailserver: {arg}`]: `无法移除邮件服务器：{arg}`,
  [`Failed to remove portal service: {arg}`]: `无法移除门户网站服务：{arg}`,
  [`Failed to create {name}.`]: `无法创建 {name}。`,
  [`Failed to sign out! Please check the console for details.`]: `无法注销！请检查控制台以获取详细信息。`,
  [`Failed to update {name}.`]: `无法更新 {name}。`,
  [`Failed to update OAuth provider`]: `无法更新 OAuth 提供者`,
  [`Failed to update account.`]: `无法更新帐户。`,
  [`Failed to update analytics service {title}.`]: `无法更新分析服务 {title}。`,
  [`Failed to update gateway service {title}.`]: `无法更新网关服务 {title}。`,
  [`Failed to update task`]: `未能更新任务`,
  [`Failed to create app {title}`]: `未能创建应用程序 {title}`,
  [`Feature flags`]: `功能部件标志`,
  [`Fetch schema`]: `访存模式`,
  [`Fetched endpoints`]: `已访存的端点`,
  [`Field`]: `字段`,
  [`Field label`]: `字段标签`,
  [`Field Mapping`]: `字段映射`,
  [`Field name`]: `字段名称`,
  [`Field weight`]: `字段权重`,
  [`File Name`]: `文件名`,
  [`File Preview`]: `文件预览`,
  [`File contains validation errors`]: `文件包含验证错误`,
  [`File is not a valid OpenAPI definition`]: `文件不是有效的 OpenAPI 定义`,
  [`File is not a valid extension format.`]: `文件不是有效的扩展格式。`,
  [`File is not a valid {fileType} format.`]: `文件不是有效的 {fileType} 格式。`,
  [`File name`]: `文件名`,
  [`File upload`]: `文件上载`,
  [`File uploaded is not a valid file`]: `上载的文件不是有效的文件`,
  [`File uploaded successfully`]: `已成功上载文件`,
  [`File was updated and validated!`]: `文件已更新并进行验证！`,
  [`File was uploaded and validated!`]: `文件已上载并进行验证！`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `文件 {draftFilePath} 已存在。尝试更改草稿的名称/版本组合`,
  [`Fill out the details of this service`]: `填写此服务的详细信息`,
  [`Filter`]: `过滤器`,
  [`Filter by operation`]: `按操作进行过滤`,
  [`Filter policies`]: `过滤器策略`,
  [`Final Action`]: `最终操作`,
  [`Find existing user`]: `查找现有用户`,
  [`Find expert answers in the API Connect community forum`]: `在 API Connect 社区论坛中查找专家答案`,
  [`Find expert answers in the API Connect community forum.`]: `在 API Connect 社区论坛中查找专家答案。`,
  [`Find schema`]: `查找模式`,
  [`Finger Print`]: `指纹`,
  [`Fingerprint SHA-1`]: `指纹 SHA-1`,
  [`Fingerprint SHA-256`]: `指纹 SHA-256`,
  [`Finish`]: `完成`,
  [`First name`]: `名字`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `首先指定网关用于 API 流量的端点的 URL。值必须是具有特定于 API 调用的其他路径的公共标准域名，例如：https://api.mycompany.com。`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `首先指定网关管理端点的 URL。对于在 Kubernetes 环境中运行的网关，值必须与通过网关设置命令 set gwy apic-gw-service 指定的端点匹配。对于网关设备，值的格式必须为 http://<ip-address-for-gateway>:3000。使用网关集群时，请指定负载均衡器的 address:port。`,
  [`Fit to screen`]: `适合屏幕`,
  [`Flow`]: `流`,
  [`Follow redirects`]: `遵循重定向`,
  [`Found In`]: `查找范围`,
  [`Fonts contained in the following file are available under the`]: `以下文件中包含的字体位于：`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `对于 WSDL 定义，请上载包含 WSDL 文件和 API 定义 (YAML) 的 zip 文件`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `对于原始产品中的每个计划，选择要将预订迁移到的替换产品中的计划`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `对于源产品中的每个计划，选择要将预订迁移到的目标产品中的计划`,
  [`For guidance on the available command set, access the CLI's help`]: `要获取有关可用命令集的指导，请访问 CLI 帮助`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `对于使用本机 OAuth 提供者的站点，可选择指定将由所有 API 调用使用的共享私钥。`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `对于可写的 LDAP，选择“用户管理”，然后添加从源 LDAP 注册表属性名称到 API Connect LDAP 属性值的映射。API Connect 在用户注册期间需要的缺省用户概要文件属性包括 username、first_name、last_name、email 和 password。提供了示例映射配置 - 可根据自己的需求来编辑该映射`,
  [`Forbidden`]: `已禁止`,
  [`Forget token`]: `忘记令牌`,
  [`Forgot password?`]: `忘记密码？`,
  [`Forgot password`]: `忘记密码`,
  [`Form`]: `表单`,
  [`Forum`]: `论坛`,
  [`French`]: `法语`,
  [`From File`]: `来自文件`,
  [`From Sample JSON`]: `来自样本 JSON`,
  [`From Sample XML`]: `来自样本 XML`,
  [`From URL`]: `来自 URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `从现有 GraphQL 服务（GraphQL 代理）`,
  [`From existing OpenAPI service`]: `从现有 OpenAPI 服务`,
  [`From existing WSDL service (REST proxy)`]: `从现有 WSDL 服务（REST 代理）`,
  [`From existing WSDL service (SOAP proxy)`]: `从现有 WSDL 服务（SOAP 代理）`,
  [`From target service`]: `从目标服务`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `从 SOAP 服务 WSDL 定义所定义的操作中，选择要在 API 中使用的操作，并指定方法和 URL 路径。`,
  [`Gateway Extensions`]: `网关扩展`,
  [`Gateway extension`]: `网关扩展`,
  [`Gateway Services`]: `网关服务`,
  [`Gateway Type`]: `网关类型`,
  [`Gateway by Name`]: `网关（按名称划分）`,
  [`Gateway services`]: `网关服务`,
  [`Gateway type`]: `网关类型`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL V2 许可证`,
  [`GROUP`]: `组`,
  [`Group name`]: `组名`,
  [`Gateway`]: `网关`,
  [`Gateways`]: `网关`,
  [`Gateway details`]: `网关详细信息`,
  [`Gateway Details`]: `网关详细信息`,
  [`Gateway Error`]: `网关错误`,
  [`Gateway service details`]: `网关服务详细信息`,
  [`Gateway management client TLS client profile`]: `网关管理客户机 TLS 客户机概要文件`,
  [`Gateway management client keystore`]: `网关管理客户机密钥库`,
  [`Gateway management client truststore`]: `网关管理客户机信任库`,
  [`Gateway processing status`]: `网关处理状态`,
  [`Gateway policies (TODO)`]: `网关策略 (TODO)`,
  [`Gateway service`]: `网关服务`,
  [`Gateway Service US East (TODO)`]: `美国东部网关服务 (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `已移除网关服务 {arg}。`,
  [`Gateway service configuration`]: `网关服务配置`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `网关服务主机不可用。不会启用 Explorer 中的 API 测试（试用）。`,
  [`Gateway service configuration has been updated.`]: `已更新网关服务配置。`,
  [`Gateway service {title} has been created.`]: `已创建网关服务 {title}。`,
  [`Gateway service {title} has been updated.`]: `已更新网关服务 {title}。`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `启用空间后，会在每个空间中管理网关服务。请转至“空间设置”以管理网关服务。`,
  [`Gateway version`]: `网关版本`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `每页网关数`,
  [`Gather-and-send`]: `收集并发送`,
  [`Gather-only`]: `仅收集`,
  [`General configuration`]: `常规配置`,
  [`General information about the API.`]: `有关 API 的常规信息。`,
  [`Generate`]: `生成`,
  [`Generate an OpenAPI definition for the proxy`]: `为代理生成 OpenAPI 定义`,
  [`Generate JSON Web Token (JWT)`]: `生成 JSON Web 令牌 (JWT)`,
  [`Generate JWT`]: `生成 JWT`,
  [`Generate LTPA Token`]: `生成 LTPA 令牌`,
  [`Generate OAuth Access Tokens`]: `生成 OAuth 访问令牌`,
  [`Generate OAuth access tokens`]: `生成 OAuth 访问令牌`,
  [`Generate a default product`]: `生成缺省产品`,
  [`Generate access token`]: `生成访问令牌`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `生成 LTPA 令牌，使您的 API 可以安全通过 WebSphere Application Server Web 服务器认证。`,
  [`Generate an object`]: `生成对象`,
  [`Generate authorization code`]: `生成授权代码`,
  [`Generate from sample JSON`]: `从样本 JSON 生成`,
  [`Generate from sample XML`]: `从样本 XML 生成`,
  [`Generate tests automatically to build high quality APIs`]: `自动生成测试以构建高质量 API`,
  [`Generated OpenAPI 2.0 definition`]: `已生成 OpenAPI 2.0 定义`,
  [`Generated default product for the API`]: `已生成 API 缺省产品`,
  [`Generated name for use in commands and API calls`]: `已生成用于命令和 API 调用的名称`,
  [`German`]: `德语`,
  [`Get Base DN`]: `获取基本 DN`,
  [`Get Code`]: `获取代码`,
  [`Get Code Snippet`]: `获取代码片段`,
  [`Get Support`]: `获取支持`,
  [`Get Token`]: `获取令牌`,
  [`Get activation link`]: `获取激活链接`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `从注册网关服务开始。这允许您在配置其他服务时测试端点。注册某个服务后，您将在此处看到该服务。[了解更多信息]({link})`,
  [`Get subscription ID`]: `获取预订标识`,
  [`Getting Started`]: `入门`,
  [`Getting started`]: `入门`,
  [`Git information`]: `Git 信息`,
  [`Github`]: `Github`,
  [`Global policies`]: `全局策略`,
  [`Go to Spaces`]: `转至空间`,
  [`Go to the Test tab`]: `转至“测试”选项卡`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL 速率限制`,
  [`GraphQL service`]: `GraphQL 服务`,
  [`GraphQL schema`]: `GraphQL 模式`,
  [`GraphQL Schema`]: `GraphQL 模式`,
  [`GraphQL schema has been successfully validated.`]: `已成功验证 GraphQL 模式。`,
  [`GraphQL Schema URL`]: `GraphQL 模式 URL`,
  [`GraphQL send type`]: `GraphQL 发送类型`,
  [`GraphQL Websocket Upgrade`]: `GraphQL Websocket 升级`,
  [`Group Based DN`]: `基于组的 DN`,
  [`Group {title} added`]: `已添加组 {title}`,
  [`Group {title} updated`]: `已更新组 {title}`,
  [`Group {title} has been deleted`]: `已删除组 {title}`,
  [`Groups`]: `组`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `主机名`,
  [`HOST URL`]: `主机 URL`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML 表单`,
  [`HTML form time limit`]: `HTML 表单时间限制`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2，必需`,
  [`HTTP Header`]: `HTTP 头`,
  [`HTTP Method`]: `HTTP 方法`,
  [`HTTP Type`]: `HTTP 类型`,
  [`HTTP Version`]: `HTTP 版本`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Handlebars`,
  [`Hard limit`]: `硬限制`,
  [`Header`]: `头`,
  [`Header control`]: `头控件`,
  [`Header name payload`]: `头名称有效内容`,
  [`Header name token`]: `头名称令牌`,
  [`Headers`]: `头`,
  [`Hello`]: `您好`,
  [`Hello!`]: `您好！`,
  [`Hello [IBM](url)`]: `您好 [IBM](http://ibm.com)`,
  [`Hello {name}`]: `{name}，您好！`,
  [`Hello {name}!`]: `{name}，您好！`,
  [`Hello {name}, [see IBM profile](url)`]: `{name} 您好，[请参阅 IBM 概要文件](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n感谢您在 {catalogTitle} Developer Portal 注册帐户。\n\n要完成注册，请使用此链接：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{consumerOrg} Developer Portal 管理员已恢复 \n{appName} 应用程序。API Gateway 将不再\n锁定来自此应用程序的 API 调用。\n\n您可以使用此链接查看应用程序详细信息：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{consumerOrg} Developer Portal 管理员已暂挂 \n{appName} 应用程序。API Gateway 将阻止来自此应用程序的 API 调用。\n\n您可以使用此链接查看应用程序详细信息：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n用户 {originator} 已请求核准针对 {catalog} 目录中 {productName} API 产品 V{productVersion}\n的 {planName} 计划预订。\n\n\n您可以使用此链接打开任务列表中的请求：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n用户 {originator} 已请求核准应用程序 {appName}\n针对 {catalog} 目录中 {productName} API 产品 V{productVersion} 的 {planName} 计划预订。\n\n您可以使用此链接打开任务列表中的请求：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n用户 {originator} 已请求核准{action} {catalog} 目录中的应用程序 {appName}\n。\n\n您可以使用此链接打开任务列表中的请求：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n用户 {originator} 已请求核准{action} {catalog} 目录中 {productName} API 产品 V{version}\n。\n\n您可以使用此链接打开任务列表中的请求：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{{firstName}}，您好！\n\n我们已收到重置 {{catalog}} Developer Portal 中用户帐户\n{{username}} 的密码的请求。\n\n如果您未提出过该请求，那么请忽略此消息。 否则，\n您可以使用此链接来重置密码：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `{firstName}，您好！\n\n我们已收到重置 IBM API Connect 中用户帐户\n{username} 的密码的请求。\n\n要重置密码，请使用此链接：\n\n{link}\n\n（如果您未提出过该请求，那么可忽略此消息。）\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n我们已收到重置 {catalog} Developer Portal 中用户帐户\n{username} 的密码的请求。\n\n如果您未提出过该请求，那么请忽略此消息。 否则，\n您可以使用此链接来重置密码：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName}，您好！\n\n在 {catalog} 目录中创建 {appName} 应用程序的预订的请求\n正在等待核准。在处理完您的请求后，\n我们将发送另一个通知。\n\n您可以在此处检查应用程序状态：\n\n{link}\n\n如果在任何时候想要取消请求，那么可使用此链接：\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}} 您好，\n\n针对 API 产品 {{productName}} V{{productVersion}} 的\n{{planName}} 套餐创建 {{appName}} 应用程序预订的\n请求已获得核准。\n\n{{#if comments}}\n核准者包含了以下评论：\n\n"{{comments}}"\n\n{{/if}}\n您可以在此处检查应用程序的状态：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName} 您好，\n\n针对 API 产品 {productName} V{productVersion} 的\n{planName} 套餐创建 {appName} 应用程序预订的\n请求已获得核准。\n\n{#if comments}\n核准者包含了以下评论：\n\n{comments}\n\n{/if}\n您可以在此处检查应用程序的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{{firstName}} 您好，\n\n针对 API 产品 {{productName}} V{{productVersion}} 的\n{{planName}} 套餐创建 {{appName}} 应用程序预订的\n请求已被拒绝。\n\n{{#if comments}}\n核准者包含了以下评论：\n\n"{{comments}}"\n\n{{/if}}\n您可以在此处检查应用程序的状态：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{{firstName}}，您好！\n\n{{action}} {{catalog}} 目录中 API 产品 {{productName}}:{{version}} \n的请求已获得核准。\n\n{{#if comments}}\n核准者包含了以下评论：\n\n{{comments}}\n\n{{/if}}\n您可以在此处检查产品的状态：\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{action} {catalog} 目录中 API 产品 {productName}：{version} 的\n请求已获得核准。\n\n{#if comments}\n核准者包含了以下评论：\n\n{comments}\n\n{/if}\n您可以在此处检查产品的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}}，您好！\n\n{action}} {catalog} 目录中 API 产品 {productName}:{version} \n的请求已被拒绝。\n\n{#if comments}\n核准者包含了以下评论：\n\n{comments}\n\n{/if}\n您可以在此处检查产品的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{action} {catalog} 目录中 API 产品 {productName}:{version} \n的请求已被拒绝。\n\n{#if comments}\n核准者包含了以下评论：\n\n{comments}\n\n{/if}\n您可以在此处检查产品的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `{firstName}，您好！\n\n{action} {catalog} 目录中 API 产品 {productName}：{version} 的\n请求正在等待核准。在处理完您的请求后，\n我们将发送另一个通知。\n\n您可以在此处检查产品状态：\n\n{link}\n\n如果在任何时候想要取消请求，那么可使用此链接：\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{action} {consumerOrg} Developer Portal 中应用程序 {appName} 的\n请求已获得核准。\n\n{#if comments}\n核准者包含了以下评论：\n\n"{comments}"\n\n{/if}\n您可以在此处检查应用程序的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{action} {consumerOrg} Developer Portal 中应用程序 {appName} 的\n请求已获得核准。\n\n{#if comments}\n核准者包含了以下评论：\n\n{comments}\n\n{/if}\n您可以在此处检查应用程序的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{action} {consumerOrg} Developer Portal 中应用程序 {appName} 的\n请求已被拒绝。\n\n{#if comments}\n核准者包含了以下评论：\n\n"{comments}"\n\n{/if}\n您可以在此处检查应用程序的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{action} {consumerOrg} Developer Portal 中应用程序 {appName} 的\n请求已被拒绝。\n\n{#if comments}\n核准者包含了以下评论：\n\n{comments}\n\n{/if}\n您可以在此处检查应用程序的状态：\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `{firstName}，您好！\n\n{action} {consumerOrg} Developer Portal 中 {appName} 应用程序的请求\n正在等待核准。在处理完您的请求后，\n我们将发送另一个通知。\n\n如果在任何时候想要取消请求，那么可使用此链接：\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\nIBM API Connect 的帐户管理员已邀请您创建\nAPI 提供者组织。提供者组织允许您和您的\n团队开发 API 以及管理 API 产品的目录。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\n{catalog} API 目录（属于 IBM API Connect 中的 \n{org} API 提供者组织）的管理员已邀请您\n在该目录中创建空间。空间允许您以及您的团队\n管理 API 目录中的一组 API 产品。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\n{catalog} API 目录（属于 IBM API Connect 中的 \n{org} API 提供者组织）的管理员已邀请您\n加入目录。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\n{catalog} Developer Portal 管理员已邀请\n您创建 API 使用者组织。使用者组织\n允许您以及您的团队访问 API 和注册客户机应用程序。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\n{catalog} Developer Portal 中 {consumerOrg} API 使用者组织的管理员\n已邀请您加入组织。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\nIBM API Connect 中 {org} API 提供者组织的管理员\n已邀请您创建 API 目录。目录允许您以及\n您的团队发布和管理 API 产品集合。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\nIBM API Connect 中 {org} API 提供者组织的管理员\n已邀请您加入组织。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\n{catalog} API 目录（属于 IBM API Connect 中的 {org} API 提供者组织）中\n{space} 空间的管理员\n已邀请您加入空间。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `您好，\n\n已成功更改 IBM API Connect 中用户帐户\n{username} 的密码。\n\n您可以从此处登录到 API Connect：\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `您好，\n\n已更改 {portalTitle} Developer Portal 中用户帐户\n{username} 的密码。\n\n您可以从此处登录到 {portalTitle} Developer Portal：\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `您好，\n\nIBM API Connect 系统管理员已邀请您加入\n{org} 组织。\n\n要继续，请使用以下链接：\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `您好，\n\n这是来自配置的邮件服务器 {mailServer} 的 IBM API Connect 的\n测试消息。\n\n如果如期收到此消息，那么测试成功！\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好，\n\n用户 {originator} 已撤销其针对 {catalog} 目录中\n{productName} API 产品 V{productVersion} 预订 {planName} 计划的请求。\n\n无需进一步操作。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好，\n\n用户 {originator} 已撤销其{action} {catalog} 目录中 {appName} 应用程序的请求。\n\n无需进一步操作。\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好，\n\n用户 {originator} 已撤销其{action} {catalog} 目录中 {productName}\nAPI 产品 V{version} 的请求。\n\n无需进一步操作。\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `您好，这是来自配置的邮件服务器 {{{mailServer}}} 的 IBM API Connect 的测试消息。如果如期收到此消息，那么测试成功`,
  [`Hello|header`]: `您好`,
  [`Help`]: `帮助`,
  [`Hi, Steve (todo)`]: `Steve (todo)，您好`,
  [`Hide`]: `隐藏`,
  [`Hide field`]: `隐藏字段`,
  [`Hide in schema`]: `在模式中隐藏`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `隐藏新的类型、字段、伪指令或可选的自变量。将移除不再受后端支持的项。`,
  [`High`]: `高`,
  [`History`]: `历史记录`,
  [`Histories`]: `历史记录`,
  [`History log`]: `历史记录日志`,
  [`Home`]: `主页`,
  [`Host`]: `主机`,
  [`Host context variable`]: `主机上下文变量`,
  [`Host name`]: `主机名`,
  [`Host value (if present)`]: `主机值（如果存在）`,
  [`Hostname`]: `主机名`,
  [`Hours`]: `小时`,
  [`Http basic authentication schema`]: `HTTP 基本认证模式`,
  [`Hmm, your search didn't return any results.`]: `呃，您的搜索未返回任何结果。`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect 云`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud 认证`,
  [`IBM Cloud Docs`]: `IBM Cloud 文档`,
  [`IBM Schema`]: `IBM 模式`,
  [`IBM managed`]: `IBM 管理`,
  [`ID Name`]: `标识名称`,
  [`ID token custom claims`]: `标识令牌定制声明`,
  [`ID token issuer`]: `标识令牌发布者`,
  [`ID token signing algorithm`]: `标识令牌签名算法`,
  [`ID token signing crypto object`]: `标识令牌签名加密对象`,
  [`ID token signing key`]: `标识令牌签名密钥`,
  [`ID token signing key identifier`]: `标识令牌签名密钥标识`,
  [`IDENTITY PROVIDER`]: `身份提供者`,
  [`IDENTITY PROVIDER SUPPORT`]: `身份提供者支持`,
  [`Identification`]: `标识`,
  [`Identity extraction`]: `身份抽取`,
  [`If`]: `If`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `如果禁用生产方式，那么表示这是开发目录，将强制执行所有发布操作，并且自动解决任何冲突，例如，允许您在测试期间反复重新发布相同产品版本。`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `如果启用生产方式，那么表示这是生产目录，如果与已发布的任何产品相冲突，那么将阻止您将产品发布至目录；您必须先解决任何冲突，然后再重试发布操作。`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `如果在目录中启用了空间，那么您可以管理空间中的成员。通过向空间添加新用户并为这些用户分配角色来管理空间成员资格。`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `如果已启用，那么会将具有空值的输入属性映射到输出文档。否则，输出将省略这些属性。`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `如果启用生产方式，那么将不会强制执行任何登台和发布操作。如果发现冲突，系统将自动解决这些冲突。取消发布的操作是自动执行的。`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `如果收件人未收到电子邮件，请检查电子邮件服务器设置。`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `如果收件人未收到电子邮件，请检查 {username} 的电子邮件服务器设置。`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `如果设置为值 true，那么“invoke”策略和“proxy”策略的目标 url 的查询参数值中的所有 + 字符都会编码为 %2B。`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `如果设置为值 true，那么调用目标 url 的变量定义所引用的任何请求参数将进行 URL 解码。`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `如果设置为值 true，那么“invoke”策略会发送 HTTP DELETE 方法的有效内容。`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `如果此选项设置为 true，那么将从活动日志中编辑或移除属性。`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `如果此选项设置为 true，那么将在执行开始时从输入中编辑或移除属性。`,
  [`If set to true, the property will be redacted or removed from the response.`]: `如果此选项设置为 true，那么将从响应中编辑或移除属性。`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `如果在目录中启用了空间，那么您可以管理用户在空间中具有的访问权。可通过指定分配给用户角色的许可权来管理访问权。缺省情况下，在创建新空间时，分配给每个角色的许可权由缺省空间许可权模板中的设置来确定。`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `如果选中此复选框（缺省选项），那么将继承父元素的 XML 名称空间。如果想要映射策略使用显式名称空间，请清除此复选框。`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `如果选中此复选框（缺省选项），那么会将 XML 名称空间插入到第一个使用这些名称空间的文档中。如果希望在根元素上定义所有名称空间，请取消选中此复选框。`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `如果选中此复选框（缺省选项），那么将在映射策略的输出中包含空的 XML 元素。如果不想在映射策略的输出中包含空的 XML 元素，那么请清除此复选框。`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `如果此复选框被选中，那么系统会启用已映射的 JSON 输出的后处理。JSON 输出的后处理将使用输出模式以确保属性值的数据类型与模式中定义的数据类型相同。它还将规范化具有 Badgerfish JSON 语法的输出属性值（这些值是 XML 输入的对象映射的结果）。取消选中此复选框（缺省情况）表示不对映射的 JSON 输出进行后处理。`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `如果选中此复选框（缺省选项），那么将解析映射属性中的 API Connect 变量引用。如果想要映射策略忽略映射策略中的 API Connect 变量引用，请清除此复选框。`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `如果选中此复选框，那么将解析映射属性中的 API Connect 变量引用。如果想要映射策略以忽略映射策略中的 API Connect 变量引用，请清除此复选框（缺省选项）。`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `如果选中此复选框（缺省选项），那么当在遍历输入的过程中遇到数组时，将仅使用第一个元素。如果想要映射策略使用所有数组元素，请清除此复选框。`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `如果选中该复选框，会将其模式配置为类型布尔或数字的 XML 元素转换为该数据类型。如果未选择（缺省选项），那么所有 XML 元素值将以字符串形式返回。`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `如果选中此复选框，那么复杂模式类型评估将以优化方式处理循环类型引用。清除此复选框（缺省选项）以标准方式评估这些模式类型。`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `如果错误是由于缺少 CORS 支持而导致的，请单击该链接以在新选项卡中打开服务器。 如果浏览器显示证书问题，那么您可以选择接受该问题并返回此处以重新测试。`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `如果选中此复选框，那么在模式中标注为必需的可选对象的任何输出数组或子属性会将必需的子对象属性初始化为缺省值，除非已成功映射。这将模拟如何为这些对象的必需属性完成版本 4 映射。清除此复选框（缺省值）以仅输出具有成功映射数据的这些对象。`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `如果选中此复选框（缺省值）并且所有者用户已更改，那么也会将任何子资源（空间）的所有权移交给新所有者。`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `如果选择此选项，那么映射策略会采用已解析的 XML 文档形式将 XML 输出写入 message.body 中。缺省情况下，将采用 XML 字符串形式写入 XML 输出。任何其他变量的 XML 输出都始终采用 XML 字符串形式写入。`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `如果针对“登台”、“发布”或“替换”操作启用生命周期核准，那么自动执行这些操作的一些 API 创建、编辑或测试操作可能无法正常运行。确定要启用任何这些核准？`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `如果您具有要通过 API Connect API 定义公开的现有 SOAP 服务，那么可以创建 API 并指定目标 SOAP 服务。然后，API Connect 将创建用于调用该 SOAP 服务的 API 定义。`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `如果通过单击“是，更新 API 组合件”保存了更改，那么也将更新此 OAuth 提供者底层的 API 组合件。但是，如果定制了底层 API 组合件并且想要单独进行更新，请单击“否。不更新 API 组合件”，然后在 API 编辑器中更新底层 API 组合件。`,
  [`If you are still having issues, upload a schema definition language file.`]: `如果仍存在问题，请上载模式定义语言文件。`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `如果文件同时包含专用密钥和公用密钥，请在步骤 1 中上载该文件`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `如果需要注册位于防火墙后的网关，请参阅 IBM Cloud 文档以获取有关使用 VPN 或 Secure Gateway 服务建立连接的信息。`,
  [`Ignore`]: `忽略`,
  [`Implement policy`]: `实施策略`,
  [`Implementation file is required`]: `实施文件是必需的`,
  [`Implicit`]: `暗示`,
  [`Import`]: `导入`,
  [`Import API`]: `导入 API`,
  [`Import API|title`]: `导入 API`,
  [`Import OpenAPI definition from asset repository`]: `从资产存储库导入 OpenAPI 定义`,
  [`Import Product`]: `导入产品`,
  [`Import policy`]: `导入策略`,
  [`Import a product from a service URL`]: `从服务 URL 导入产品`,
  [`Import a product from the definition file`]: `从定义文件导入产品`,
  [`Import an existing product`]: `导入现有产品`,
  [`Import an OpenAPI definition from a URL`]: `从 URL 导入 OpenAPI 定义`,
  [`Import an OpenAPI definition from a file`]: `从文件导入 OpenAPI 定义`,
  [`Import definitions`]: `导入定义`,
  [`Import from existing API service`]: `从现有 API 服务导入`,
  [`Import from file`]: `从文件导入`,
  [`Importing an API definition`]: `导入 API 定义`,
  [`Import|title`]: `导入`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `将所需证书和 CA 捆绑包导入到证书管理器服务中。`,
  [`In Cookie Header`]: `在 Cookie 头中`,
  [`In WSSec Header`]: `在 WSSec 头中`,
  [`In order to invoke the endpoints above, you need to first`]: `为调用以上端点，您首先需要`,
  [`Inactive`]: `不活动`,
  [`Inc`]: `Inc`,
  [`Include empty`]: `包含空元素`,
  [`Include free trial days`]: `包含免费试用天数`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `无法选择与所配置的网关服务不兼容的 API。`,
  [`Incorrect username or password`]: `用户名或密码不正确`,
  [`Incorrect username, password, or credentials`]: `用户名、密码或凭证不正确`,
  [`Indicate if the invoke is the final action`]: `指示此调用是否为最终操作`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `指示是否应将 JWT 标识 (jti) 声明添加到 JWT。如果选中此选项，那么 jti 声明值将为 UUID。`,
  [`Indicates whether the clients secret is required.`]: `指示是否需要客户机密钥。`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `指示此 XSLT 输入文档是使用上下文当前有效内容还是无输入。`,
  [`Indicates whether to stop processing if client security fails.`]: `指示如果客户机安全性失败，是否停止处理。`,
  [`Infer from JSON schema`]: `从 JSON 模式推断`,
  [`Infer from Sample`]: `从样本推断`,
  [`Info`]: `信息`,
  [`Information context variable`]: `信息上下文变量`,
  [`Inject proxy headers`]: `插入代理头`,
  [`Inline schema`]: `内联模式`,
  [`Input`]: `输入`,
  [`Input field`]: `输入字段`,
  [`Input object`]: `输入对象`,
  [`Insecure Server Connections`]: `不安全的服务器连接`,
  [`Install API Connect CLI & API Designer|button text`]: `安装 API Connect CLI 和 API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `安装 API Connect CLI 和 API Designer`,
  [`Install API Connect Toolkit`]: `安装 API Connect 工具箱`,
  [`Install the API Connect plugin`]: `安装 API Connect 插件`,
  [`Instance ID`]: `实例标识`,
  [`Instance name`]: `实例名称`,
  [`Instance owner`]: `实例所有者`,
  [`Interact to expand Tile`]: `交互以展开磁贴`,
  [`Interact to collapse Tile`]: `交互以折叠磁贴`,
  [`Interval`]: `时间间隔`,
  [`Interface`]: `接口`,
  [`Introspect`]: `自省`,
  [`Introspect cache type`]: `自省高速缓存类型`,
  [`Introspect from Server URL`]: `从服务器 URL 自省`,
  [`Introspect from URL`]: `从 URL 自省`,
  [`Introspect URL`]: `自省 URL`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `使用其他 URL 进行自省时不会更改 GraphQL 服务器 URL。`,
  [`Introspect token`]: `自省令牌`,
  [`Introspection`]: `自省`,
  [`Introspection Path`]: `自省路径`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `从服务器自省 GraphQL 模式或上载模式`,
  [`Introspect the GraphQL schema using the query`]: `使用查询自省 GraphQL 模式`,
  [`Introspection URL`]: `自省 URL`,
  [`Invalid HOST URL`]: `主机 URL 无效`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `URL 无效。请输入有效的 API Manager 主机 URL。例如，https://api-connect-host.com`,
  [`Invalid file URL`]: `文件 URL 无效`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `URL 格式无效，必须以 http:// 或 https:// 开头，并且至少包含 1 个字符`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `URL 格式无效，只需要指定主机名。例如，可移除 syslog://`,
  [`Invalid emails.`]: `电子邮件无效。`,
  [`Invalid JSONata expression`]: `JSONata 表达式无效`,
  [`Invalid remove target`]: `移除目标无效`,
  [`Invalid assumed size`]: `设定的大小无效`,
  [`Invalid cost target`]: `成本目标无效`,
  [`Invalid list size`]: `列表大小无效`,
  [`Invalid port value, must not be negative`]: `端口值无效，该值不得为负`,
  [`Invalid sized fields`]: `限制大小的字段无效`,
  [`Invalid value, length has to be greater than 1`]: `值无效，其长度必须大于 1`,
  [`Invalid weight value`]: `权重值无效`,
  [`invitation`]: `邀请`,
  [`Invitation`]: `邀请`,
  [`Invitation timeout`]: `邀请超时`,
  [`Invitation link will expire in {time}`]: `邀请链接将于 **{time}**后到期`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `邀请链接将于 **{seconds} 秒 ({time})** 后到期`,
  [`Invitation link will expire in __{value} {unit}__.`]: `邀请链接将于 {value} {unit}后过期。`,
  [`Invitation sent to`]: `邀请已发送至`,
  [`Invitation sent to {email}`]: `邀请已发送至 {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `IBM API Connect 中 API 目录的邀请`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `{catalog} Developer Portal 中 API 使用者组织的邀请`,
  [`Invitation to an API provider organization in IBM API Connect`]: `IBM API Connect 中 API 提供者组织的邀请`,
  [`Invitation to an API space in IBM API Connect`]: `IBM API Connect 中 API 空间的邀请`,
  [`Invitation to an admin organization in IBM API Connect`]: `IBM API Connect 中管理员组织的邀请`,
  [`Invitation to create an API catalog in IBM API Connect`]: `在 IBM API Connect 中创建 API 目录的邀请`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `在 {catalog} Developer Portal 中创建 API 使用者组织的邀请`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `在 IBM API Connect 中创建 API 提供者组织的邀请`,
  [`Invitation to create an API space in IBM API Connect`]: `在 IBM API Connect 中创建 API 空间的邀请`,
  [`Invitation ttl has been created.`]: `已创建邀请 ttl。`,
  [`Invitation ttl has been updated.`]: `已更新邀请 ttl。`,
  [`Invitation ttl has not been created!`]: `尚未创建邀请 ttl！`,
  [`Invitation ttl has not been updated!`]: `尚未更新邀请 ttl!`,
  [`Invite`]: `邀请`,
  [`Invite catalog owner`]: `邀请目录所有者`,
  [`Invite consumer organization owner`]: `邀请使用者组织所有者`,
  [`Invite member`]: `邀请成员`,
  [`Invite organization owner`]: `邀请组织所有者`,
  [`Invite space owner`]: `邀请空间所有者`,
  [`Invite a new consumer organization owner`]: `邀请新的使用者组织所有者`,
  [`Invite a new member`]: `邀请新成员`,
  [`Invite a new provider organization owner`]: `邀请新的提供者组织所有者`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `通过输入收件人电子邮件地址来邀请新用户注册成为目录管理员。将向用户发送一封包含注册表单链接的邀请电子邮件`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `通过输入收件人电子邮件地址来邀请新用户注册成为空间管理员。将向用户发送一封包含注册表单链接的邀请电子邮件。`,
  [`Invite by email`]: `通过电子邮件邀请`,
  [`Invite from User Registry`]: `从用户注册表中邀请`,
  [`Invite team member`]: `邀请团队成员`,
  [`Inviting members to consumer organizations is enabled`]: `已启用“邀请成员加入使用者组织”`,
  [`Invocations`]: `调用`,
  [`Invoke`]: `调用`,
  [`Issuer Claim`]: `发出者声明`,
  [`Issuer Origin`]: `发布者源`,
  [`Issuer common name`]: `发布者通用名称`,
  [`It might influence how the API is deployed`]: `可能会影响 API 的部署方式`,
  [`Italian`]: `意大利语`,
  [`Items per page`]: `每页项数`,
  [`Items per page:`]: `每页项数：`,
  [`items selected`]: `项已选`,
  [`item selected`]: `项已选`,
  [`Iterate on`]: `迭代依据`,
  [`Iterate?`]: `是否迭代？`,
  [`JSON objects describing re-usable channel parameters.`]: `用于描述可复用通道参数的 JSON 对象。`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `用于描述 API 所使用和生成的消息的 JSON 对象。`,
  [`JSON Schema URL`]: `JSON 模式 URL`,
  [`JSON Schema preview`]: `JSON 模式预览`,
  [`JSON Web Token (JWT)`]: `JSON Web 令牌 (JWT)`,
  [`JSON has been successfully validated`]: `已成功验证 JSON`,
  [`JSON schema is not valid. Please correct the error.`]: `JSON 模式无效。请更正错误。`,
  [`JSON to XML`]: `JSON 到 XML`,
  [`JWT ID Claim`]: `JWT 标识声明`,
  [`JWT verification JWK`]: `JWT 验证 JWK`,
  [`JWT verification crypto object`]: `JWT 验证加密对象`,
  [`Japanese`]: `日语`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `作业队列`,
  [`Jump to source`]: `跳至源`,
  [`Just now`]: `刚刚`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `密钥交换/加密/MAC`,
  [`KEY SIZE`]: `密钥大小`,
  [`Keystore`]: `密钥库`,
  [`keystore`]: `密钥库`,
  [`Keep`]: `保留`,
  [`Keep Payload`]: `保留有效内容`,
  [`Key Encryption Algorithm`]: `密钥加密算法`,
  [`Key Name`]: `密钥名称`,
  [`Key type`]: `密钥类型`,
  [`Key name`]: `密钥名称`,
  [`Keystore for the token has been updated.`]: `已更新令牌的密钥库。`,
  [`Keystore Certificates`]: `密钥库证书`,
  [`Keystore/truststore`]: `密钥库/信任库`,
  [`Korean`]: `韩语`,
  [`LABEL`]: `标签`,
  [`LABELS`]: `标签`,
  [`LAST MODIFIED`]: `上次修改时间`,
  [`Last state changed`]: `上次状态更改时间`,
  [`Large`]: `大`,
  [`Lax`]: `宽松`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAP 组`,
  [`LDAP user registry`]: `LDAP 用户注册表`,
  [`LDAP registry`]: `LDAP 注册表`,
  [`LDAP registry name`]: `LDAP 注册表名称`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `角色的 LDAP 用户注册表 {{name}} 不再支持外部组映射。 启用此用户注册表的外部组映射，或在保存前禁用角色的外部组映射。`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `角色的 LDAP 用户注册表 {{name}} 不再支持外部组映射。 选择具有外部组映射的其他用户注册表，或在保存前禁用角色的外部组映射。`,
  [`LDAP search attribute`]: `LDAP 搜索属性`,
  [`LOCATED IN`]: `位置`,
  [`Located in`]: `位置`,
  [`LTPA Key`]: `LTPA 密钥`,
  [`LTPA Key Details`]: `LTPA 密钥详细信息`,
  [`LTPA Keys`]: `LTPA 密钥`,
  [`LTPA key file`]: `LTPA 密钥文件`,
  [`LTPA key password`]: `LTPA 密钥密码`,
  [`LUR data version`]: `LUR 数据版本`,
  [`LUR schema update date`]: `LUR 模式更新日期`,
  [`LUR schema version`]: `LUR 模式版本`,
  [`LUR target data version`]: `LUR 目标数据版本`,
  [`LUR target schema version`]: `LUR 目标模式版本`,
  [`LUR update date`]: `LUR 更新日期`,
  [`Last name`]: `姓氏`,
  [`Last modified`]: `上次修改时间`,
  [`Launch portal`]: `启动门户网站`,
  [`Launch IBM Automation management console`]: `启动 IBM 自动化管理控制台`,
  [`Launch plan upgrade`]: `启动计划升级`,
  [`Learn more`]: `了解更多信息`,
  [`Learn More`]: `了解更多`,
  [`Legacy`]: `传统`,
  [`Length cannot be below {min}`]: `长度不能低于 {min}`,
  [`Length cannot exceed {max}`]: `长度不能超过 {max}`,
  [`Let's customize API Connect`]: `定制 API Connect`,
  [`Let's get started...`]: `我们开始吧...`,
  [`Let's get you up and running`]: `我们来帮助您启动并运行`,
  [`License`]: `许可证`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `生命周期`,
  [`Lifecycle approvals`]: `生命周期核准`,
  [`Lifecycle actions have been updated`]: `生命周期操作已更新`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `已针对沙箱目录启用生命周期核准。要使用自动“激活 API”选项，首先必须禁用“沙箱目录”设置中的生命周期核准。`,
  [`Limited fields`]: `受限字段`,
  [`Limit API calls on a per key basis`]: `根据密钥限制 API 调用数`,
  [`Limit fields`]: `限制字段`,
  [`Limit renegotiation`]: `限制重新协商`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `装入`,
  [`Load query`]: `装入查询`,
  [`Load filter`]: `装入过滤器`,
  [`Load schema from WSDL`]: `从 WSDL 装入模式`,
  [`Loading`]: `正在装入`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `本地用户注册表 (LUR) 是 API Connect 中包含的缺省用户注册表，并存储在本地。`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `本地用户注册表是 API Connect 中包含的缺省用户注册表，并存储在本地。在安装 API Connect 期间，安装并配置了两个 LUR。可以配置更多 LUR。`,
  [`Local user registry`]: `本地用户注册表`,
  [`Local User Registry has been created.`]: `已创建本地用户注册表。`,
  [`Location`]: `位置`,
  [`Location of ciphertext to decrypt`]: `要解密的密文的位置`,
  [`Location of plaintext information to encrypt`]: `要加密的纯文本信息的位置`,
  [`Log`]: `日志`,
  [`Log in`]: `登录`,
  [`Log in to:`]: `登录：`,
  [`Log in with a different account`]: `使用其他帐户登录`,
  [`Log into IBM Cloud`]: `登录到 IBM Cloud`,
  [`Log out`]: `注销`,
  [`Log in using the {userRegistryType} user registry`]: `使用 {userRegistryType} 用户注册表登录`,
  [`Logging in with IBM Cloud ...`]: `正在进行 IBM Cloud 登录...`,
  [`Logging out`]: `注销`,
  [`Logic`]: `逻辑`,
  [`Login`]: `登录`,
  [`Logs`]: `日志`,
  [`Longer, descriptive name for this policy.`]: `该策略的详细描述性名称。`,
  [`Looks like the server is experiencing an error while processing your request.`]: `似乎服务器在处理您的请求时遇到错误。`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `邮件服务器`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `成员`,
  [`MIME Types`]: `MIME 类型`,
  [`Mail server has been deleted.`]: `已删除邮件服务器。`,
  [`Mail server has not been deleted.`]: `尚未删除邮件服务器。`,
  [`Mailserver {arg} has been removed.`]: `已移除邮件服务器 {arg}。`,
  [`Make sure that the server is running and that there is network connectivity.`]: `确保服务器正在运行并存在网络连接。`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `可能的原因包括目标服务器上缺少 CORS 支持、服务器不可用、遇到不可信证书或者需要相互 SSL 认证。`,
  [`This might be caused by one or more of the following:`]: `这可能是由以下一个或多个原因造成的：`,
  [`Make a call and move on`]: `进行调用并继续`,
  [`Make a call and wait for response`]: `进行调用并等待响应`,
  [`Manage`]: `管理`,
  [`Manage API life cycles`]: `管理 API 生命周期`,
  [`Manage API product lifecycle approval permissions`]: `管理 API 产品生命周期核准许可权`,
  [`Manage API product lifecycle approvals permissions`]: `管理 API 产品生命周期核准许可权`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `管理 API 提供者组织、本地和远程网关以及相关设置。`,
  [`Manage APIs`]: `管理 API`,
  [`Manage Catalogs`]: `管理目录`,
  [`Manage Organizations`]: `管理组织`,
  [`Manage Resources`]: `管理资源`,
  [`Manage Settings`]: `管理设置`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `管理 API 分析的目标，包括 API Connect、HTTP、Kafka、Elasticsearch 和 Syslog。`,
  [`Manage local and remote gateways that secure your APIs.`]: `管理用于保护 API 的本地和远程网关。`,
  [`Manage catalogs`]: `管理目录`,
  [`Manage organizations`]: `管理组织`,
  [`Manage resources`]: `管理资源`,
  [`Manage settings`]: `管理设置`,
  [`Manage users`]: `管理用户`,
  [`Manage active APIs and consumers`]: `管理活动 API 和使用者`,
  [`Manage availability zones and services`]: `管理可用性区域及服务`,
  [`Manage policies`]: `管理策略`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `管理来自应用程序开发人员的产品生命周期变更请求以及 API 预订和应用程序升级请求`,
  [`Manage roles and permissions`]: `管理角色和许可权`,
  [`Manage the OAuth providers configured for API Manager`]: `管理针对 API Manager 配置的 OAuth 提供者`,
  [`Manage the user registries configured for API Manager`]: `管理针对 API Manager 配置的用户注册表`,
  [`Manage the user registries configured for Cloud Manager`]: `管理针对 Cloud Manager 配置的用户注册表`,
  [`Management`]: `管理`,
  [`Management Endpoint`]: `管理端点`,
  [`Management endpoint`]: `管理端点`,
  [`Management endpoint on the analytics service`]: `分析服务上的管理端点`,
  [`Service endpoint configuration`]: `服务端点配置`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `管理服务用于将配置信息发送到门户网站服务的端点`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `管理服务在与门户网站服务通信时使用的 TLS 客户机概要文件`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `管理服务用于将配置信息发送到网关服务的端点`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `管理服务在与网关服务通信时使用的 TLS 客户机概要文件`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `在网关与管理服务之间使用“集群内”通信传输内部流量`,
  [`Use in-cluster communication for both ingestion and queries`]: `对数据采集和查询都使用“集群内”通信`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `对数据获取使用集群内通信，对查询使用外部通信，或者反之亦然`,
  [`Use external communication for both ingestion and queries`]: `对数据采集和查询都使用“外部”通信`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `在分析服务与管理服务之间使用“集群内”通信传输内部流量`,
  [`Management endpoint on the gateway service`]: `网关服务上的管理端点`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `在门户网站服务与管理服务之间使用“集群内”通信传输内部流量`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `如果要在管理子系统与门户网站子系统之间使用“集群内”通信，请启用此开关。 如果您不确定，请将此开关保持为禁用状态（缺省值）。`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `如果要在管理子系统与网关子系统之间使用“集群内”通信，请启用此开关。 如果您不确定，请将此开关保持为禁用状态（缺省值）。`,
  [`Management endpoint on the portal service`]: `门户网站服务上的管理端点`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `此选项为缺省值，用于从 10.0.5.3 之前发行版升级的分析服务。 如果是 OVA 部署，或者如果分析子系统位于不同的环境中，那么只能使用“外部”选项。 网关和管理服务都使用外部端点（这是 Kubernetes 入口或 OpenShift 路径，具体取决于您的平台）与分析服务进行通信。`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `如果您具有 Kubernetes 或 OpenShift 部署（其中所有子系统都位于同一个集群中），那么可以选择“集群内”选项。 选择此选项后，管理子系统、网关子系统和分析子系统都通过内部服务端点而不是外部可访问入口（Kubernetes）或路径 (OpenShift) 来相互通信。`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `如果您具有 Kubernetes 或 OpenShift 部署，并且已将某些子系统安装在同一个集群中，那么可以选择此选项。 选择此选项后，可以对不同的分析通信流使用不同的通信方法。 例如，管理子系统与分析子系统的通信可以使用“集群内”选项，而网关子系统与分析子系统的通信则可以使用“外部”选项。 如果网关子系统与其他子系统位于不同的集群中，那么可以选择此配置。`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `如果在注册分析服务时指定了“集群内”通信，那么网关可以将 API 事件数据发送到内部分析端点。`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `使用内部端点从网关服务中采集分析数据`,
  [`Endpoint configuration`]: `对数据采集和查询都使用“外部”通信`,
  [`Portal endpoint configuration`]: `门户网站端点配置`,
  [`URL used by developer portal users to access the portal website`]: `开发者门户网站用户用于访问门户网站 Web 站点的 URL`,
  [`External ingestion endpoint on the analytics service`]: `分析服务上的外部数据获取端点`,
  [`TLS client profile for external analytics ingestion endpoint`]: `用于外部分析数据获取端点的 TLS 客户机概要文件`,
  [`Internal ingestion endpoint on the analytics service`]: `分析服务上的内部数据获取端点`,
  [`TLS client profile for internal service endpoint`]: `内部服务端点的 TLS 客户机概要文件`,
  [`Manager`]: `Manager`,
  [`Manager Request Reset Password`]: `Manager 请求重置密码`,
  [`Manager Reset Password`]: `Manager 重置密码`,
  [`Manager Sign In`]: `Manager 登录`,
  [`Manages API provider organizations`]: `管理 API 提供者组织`,
  [`Manages application developer communities`]: `管理应用程序开发人员社区`,
  [`Manages the API product lifecycle`]: `管理 API 产品生命周期`,
  [`Manage|link`]: `管理`,
  [`Manage|permission`]: `管理`,
  [`Manage|title`]: `管理：标题`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `管理令牌的密钥库分配和密钥库历史记录`,
  [`Map`]: `映射`,
  [`Map information from OIDC provider to an APIC user`]: `将来自 OIDC 提供者的信息映射至 APIC 用户`,
  [`Map the migration source plans to the migration target plans`]: `将迁移源计划映射到迁移目标计划`,
  [`Map the roles to groups`]: `将角色映射到组`,
  [`Mapped from: `]: `映射自： `,
  [`Marketing`]: `市场营销`,
  [`Maximize / minimize`]: `最大化/最小化`,
  [`Maximum Consent Time to Live (seconds)`]: `最大许可范围生存时间（秒）`,
  [`Maximum consent`]: `最大许可范围`,
  [`Menu`]: `菜单`,
  [`Member`]: `成员`,
  [`Member Invitation`]: `成员邀请`,
  [`Member Invitation has been deleted.`]: `已删除成员邀请。`,
  [`Member has been deleted.`]: `已删除成员。`,
  [`Member {name} created`]: `已创建成员 {name}`,
  [`Member {title} has been created.`]: `已创建成员 {title}。`,
  [`Members`]: `成员`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `成员邀请`,
  [`Member Roles`]: `成员角色`,
  [`Merchant ID`]: `商家标识`,
  [`Merge`]: `合并`,
  [`Merge this mapping with any others that operate on the same target array?`]: `是否将此映射与在同一目标数组上操作的任何其他映射合并？`,
  [`Message`]: `消息`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `要解析的消息`,
  [`Message for resulting parsed data`]: `要生成解析数据的消息`,
  [`Metadata`]: `元数据`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `元数据允许收集在访问令牌生成过程中要包含的定制元数据。 您可以通过认证 URL 和/或远程服务器（存储定制元数据的位置）来收集元数据。`,
  [`Micro Gateway policies`]: `Micro Gateway 策略`,
  [`Migrate`]: `迁移`,
  [`Migrate Subscriptions`]: `迁移预订`,
  [`Migrate plans`]: `迁移计划`,
  [`Migrate subscription`]: `迁移预订`,
  [`Migrate subscriptions`]: `迁移预订`,
  [`Migration Target`]: `迁移目标`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `已执行从“{source}”（产品）到“{target}”（产品）的迁移`,
  [`Migration from "{source}" failed`]: `从“{source}”迁移失败`,
  [`Migration source`]: `迁移源`,
  [`Migration target`]: `迁移目标`,
  [`Minimum role`]: `最低角色`,
  [`Minimum output escaping rule`]: `最小输出转义规则`,
  [`Minutes`]: `分钟`,
  [`Mode`]: `方式`,
  [`Modifications`]: `修改`,
  [`Modified`]: `修改时间`,
  [`More`]: `更多`,
  [`More than 20 events are waiting to be processed`]: `20 个以上的事件正在等待处理`,
  [`Move down`]: `下移`,
  [`Move up`]: `上移`,
  [`Must be a number`]: `必须是数字`,
  [`Must be a valid URI`]: `必须是有效 URI`,
  [`Must be a valid email`]: `必须是有效的电子邮件`,
  [`Must be a valid path starting with /`]: `必须是以 / 开头的有效路径`,
  [`Must be a valid url`]: `必须是有效的 URL`,
  [`Must be an integer`]: `必须是整数`,
  [`Must be a valid url with no protocol`]: `必须是不含协议的有效 URL`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `必须为 64 个十六进制字符格式（前缀为“0x”）`,
  [`Must start with '/'`]: `必须以“/”开头`,
  [`Must start with https://`]: `必须以 https:// 开始`,
  [`Must start with https:// or $(`]: `必须以 https:// 或 $( 开头`,
  [`Mutual auth`]: `相互认证`,
  [`Mutual authentication`]: `相互认证`,
  [`My account`]: `我的帐户`,
  [`Name`]: `名称`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NOT`,
  [`Name cannot be empty.`]: `名称不得为空。`,
  [`Name of Schema in OpenAPI Definitions`]: `OpenAPI 定义中模式的名称`,
  [`Name of the LDAP user password attribute.`]: `LDAP 用户密码属性的名称。`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `作为验证依据的 LDAP 用户注册表的名称。请从列表中进行选择，或手动输入。`,
  [`Name of the organization`]: `组织名称`,
  [`Namespace inheritance`]: `名称空间继承`,
  [`Namespace inlining`]: `名称空间内联`,
  [`Native`]: `本机`,
  [`Native OAuth provider`]: `本机 OAuth 提供者`,
  [`Native OAuth provider summary`]: `本机 OAuth 提供者摘要`,
  [`Navigate to Source View`]: `浏览至“源代码”视图`,
  [`New`]: `新`,
  [`New API`]: `新建 API`,
  [`New API Connect connection`]: `新建 API Connect 连接`,
  [`New Application`]: `新建应用程序`,
  [`New Catalog`]: `新建目录`,
  [`New Consumer organization`]: `新建使用者组织`,
  [`New Endpoint`]: `新建端点`,
  [`New native OAuth provider`]: `新建本机 OAuth 提供者`,
  [`New OpenAPI`]: `新建 OpenAPI`,
  [`New OpenAPI directory`]: `新建 OpenAPI 目录`,
  [`New Organization Title`]: `新组织标题`,
  [`New Parameter`]: `新建参数`,
  [`New Password`]: `新密码`,
  [`New product`]: `新产品`,
  [`New Role`]: `新建角色`,
  [`New schema`]: `新模式`,
  [`New Test`]: `新建测试`,
  [`New third party OAuth provider`]: `新建第三方 OAuth 提供者`,
  [`New user`]: `新建用户`,
  [`New applications will be created in the development state`]: `将创建处于开发状态的新应用程序`,
  [`Next`]: `下一步`,
  [`Next page`]: `下一页`,
  [`No`]: `否`,
  [`No account?`]: `没有帐户？`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `未在沙箱目录中配置任何认证 URL 或 LDAP 用户注册表`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `未在沙箱目录中配置任何认证 URL 或 LDAP 用户注册表，请在[此处]({link}) 创建一个`,
  [`No Billing`]: `无计费`,
  [`No billing integration has been selected.`]: `未选择任何计费集成。`,
  [`No billing integration can be shown when offline.`]: `脱机时无法显示计费集成。`,
  [`No gateways`]: `无网关`,
  [`No cache`]: `无高速缓存`,
  [`No Cache`]: `无高速缓存`,
  [`No Certificate Manager services available`]: `无可用证书管理器服务`,
  [`No default gateways`]: `无缺省网关`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `用户注册表中没有重复的电子邮件地址。这仅适用于具有电子邮件的用户。`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `没有为沙箱目录配置任何兼容网关。不会启用 Explorer 中的 API 测试（试用）。`,
  [`No Errors`]: `无错误`,
  [`No Histories found`]: `找不到历史记录`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `找不到网关服务。请配置网关服务以显示受支持的组合件策略`,
  [`No gateways are configured for sandbox catalog`]: `没有为沙箱目录配置任何网关`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `没有为沙箱目录配置任何网关。不会启用 Explorer 中的 API 测试（试用）`,
  [`No LDAP or authentication URL user registries found.`]: `找不到任何 LDAP 或认证 URL 用户注册表。`,
  [`No limit on size of list`]: `列表大小无限制`,
  [`No OAuth providers configured in the sandbox catalog`]: `未在沙箱目录中配置任何 OAuth 提供者`,
  [`No OAuth providers configured in the selected catalog`]: `未在所选目录中配置任何 OAuth 提供者`,
  [`No Permission`]: `无许可权`,
  [`No portal added to this catalog`]: `未向此目录添加任何门户网站`,
  [`No Roles exist`]: `不存在角色`,
  [`No recent items found.`]: `找不到最新项。`,
  [`No TLS profile`]: `无 TLS 概要文件`,
  [`No TLS client profile`]: `无 TLS 客户机概要文件`,
  [`No Truststore`]: `无信任库`,
  [`No analytics service is configured`]: `未配置任何分析服务`,
  [`No analytics services found. You can create one [here]({link})`]: `找不到分析服务。您可以在[此处]({link}) 创建一个分析服务`,
  [`No assemblies found.`]: `找不到组合件。`,
  [`No available extensions`]: `无可用扩展`,
  [`No compatible flows configured in this OAuth provider`]: `未在此 OAuth 提供者中配置任何兼容流`,
  [`No consumer organization will be able to see this product.`]: `所有使用者组织都无法查看此产品。`,
  [`No debug data was found for this API call`]: `找不到此 API 调用的调试数据`,
  [`No details are available.`]: `没有可用的详细信息。`,
  [`No existing products`]: `无现有产品`,
  [`No extensions have been configured for the API`]: `未针对 API 配置任何扩展`,
  [`No flow configured in this OAuth provider`]: `未在此 OAuth 提供者中配置任何流`,
  [`No items found`]: `找不到任何项`,
  [`No non-member user to add.`]: `没有要添加的非成员用户。`,
  [`No organization found`]: `找不到组织`,
  [`No portal service exists:`]: `不存在门户网站服务：`,
  [`No product APIs`]: `没有产品 API`,
  [`No products or plans were found.`]: `找不到任何产品或计划。`,
  [`No provider organizations`]: `无提供者组织`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `未收到任何响应。原因包括目标服务器上缺少 CORS 支持、服务器不可用、遇到不可信证书或者需要相互 SSL认证。`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `没有可用的资源组。要开始操作，请在 IBM Cloud 帐户资源中创建一个资源组。`,
  [`No security`]: `无安全性`,
  [`No services found`]: `找不到任何服务`,
  [`No warnings`]: `无警告`,
  [`No warnings.`]: `无警告。`,
  [`No. Do not update API Assembly`]: `否。不更新 API 组合件`,
  [`Non-blocking`]: `非阻止`,
  [`Non int slicing arguments`]: `非整数切割自变量`,
  [`None`]: `无`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `配置的 OAuth 提供者与此 API 的网关类型都不匹配`,
  [`Normal`]: `常规`,
  [`Not Available`]: `不可用`,
  [`Not applicable for application grant type.`]: `不适用于应用程序授权类型。`,
  [`Not valid before`]: `生效时间`,
  [`Not valid after`]: `失效日期`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `注：如果选择了“生成自动产品”选项，那么将禁用此选项。`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `注：当选择“生成自动产品”选项时会禁用此选项，并对生成的产品改用缺省计划`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `注：网关服务首选项将用作所有其他首选项的范围。 将根据此设置来加载策略以及其他特定于网关服务的内容。`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `注意：此选项仅供参考，并根据上面的测试应用程序选项进行更新`,
  [`Notices`]: `声明`,
  [`Notification template`]: `通知模板`,
  [`Notification templates`]: `通知模板`,
  [`Notification server configured`]: `已配置通知服务器`,
  [`Notifications`]: `通知`,
  [`Null Badgerfish`]: `空 Badgerfish`,
  [`Null`]: `空`,
  [`Number`]: `数字`,
  [`Number of Operations`]: `操作数`,
  [`Numeric`]: `数字`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth 提供者`,
  [`OAuth provider`]: `OAuth 提供者`,
  [`OAuth Provider Policy Assembly`]: `OAuth 提供者策略组合件`,
  [`OAuth providers`]: `OAuth 提供者`,
  [`OAuth Redirect URL`]: `OAuth 重定向 URL`,
  [`OAuth shared secret`]: `OAuth 共享私钥`,
  [`OAuth provider policy assembly`]: `OAuth 提供者策略组合件`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `找不到 OAuth 提供者 {currentProviderName}。请选择已配置的 OAuth 提供者。`,
  [`OAuth redirect URL`]: `OAuth 重定向 URL`,
  [`OAuth Shared Secret`]: `OAuth 共享私钥`,
  [`OAuth Signature Method`]: `OAuth 签名方法`,
  [`OAuth signature method`]: `OAuth 签名方法`,
  [`OAuth providers can be created and managed in the following list.`]: `可以在以下列表中创建和管理 OAuth 提供者。`,
  [`OAuth symmetric key name`]: `OAuth 对称密钥名称`,
  [`OAuth symmetric key value`]: `OAuth 对称密钥值`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2 访问代码安全性`,
  [`OAuth2 Application Security`]: `OAuth2 应用程序安全性`,
  [`OAuth2 Implicit Security`]: `OAuth2 隐式安全性`,
  [`OAuth2 Password Security`]: `OAuth2 密码安全性`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `确定`,
  [`OPERATION ID`]: `操作标识`,
  [`OPTIONS`]: `选项`,
  [`ORDER`]: `排序`,
  [`ORGANIZATION`]: `组织`,
  [`ORGANIZATION MANAGER`]: `组织管理员`,
  [`Organizations`]: `组织`,
  [`Owner`]: `所有者`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `启用空间后，会在每个空间中管理 Oauth 提供者。请转至“空间设置”以管理 Oauth 提供者。`,
  [`Off`]: `关闭`,
  [`Offline`]: `脱机`,
  [`Old schema`]: `旧模式`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch 允许搜索提供者组织或目录中的 API、产品、应用程序、预订和其他资源。此外，它还可以帮助发现这些资源之间的关系。`,
  [`Omnisearch for all supported list pages`]: `针对所有受支持列表页面的 Omnisearch`,
  [`Omnisearch for draft APIs and products`]: `针对草稿 API 和产品的 Omnisearch`,
  [`Omnisearch for published products in catalogs and spaces`]: `针对目录和空间中已发布产品的 Omnisearch`,
  [`Omnisearch for consumer organizations`]: `针对使用者组织的 Omnisearch`,
  [`Omnisearch for applications`]: `针对应用程序的 Omnisearch`,
  [`Omnisearch for requested approvals`]: `针对所请求的核准的 Omnisearch`,
  [`Omnisearch for approvals tasks`]: `针对核准任务的 Omnisearch`,
  [`Omnisearch for product apis`]: `针对产品 API 的 Omnisearch`,
  [`Omnisearch for subscriptions`]: `针对预订的 Omnisearch`,
  [`Omnisearch for members`]: `针对成员的 Omnisearch`,
  [`Omnisearch for provider organizations`]: `用于提供者组织的 Omnisearch`,
  [`Disable the new editor for OpenAPI2`]: `禁用 OpenAPI2 的新编辑器`,
  [`On`]: `开启`,
  [`Onboarding`]: `引导`,
  [`Onboarding Edit Admin Org`]: `引导编辑管理组织`,
  [`Onboarding Edit Cloud Org`]: `引导编辑云组织`,
  [`Onboarding Edit Reset Password`]: `引导编辑重置密码`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `安装后，请使用 IBM Cloud CLI 定位到用于供应 API Connect 的帐户和区域，然后通过列出所有 API Connect 提供者组织来试用该 CLI`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `描述 API 正在使用和生成的模式的一个或多个 JSON 对象。`,
  [`One or more JSON representations for parameters`]: `参数的一个或多个 JSON 表示`,
  [`One time use access token`]: `一次性使用访问令牌`,
  [`One time use refresh token`]: `一次性使用刷新令牌`,
  [`Online`]: `联机`,
  [`Only one API file should be present in the zip file.`]: `在 zip 文件中只应该存在一个 API 文件。`,
  [`OpenAPI Specification Version`]: `OpenAPI 规范版本`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) 提供基于 OAuth 2.0 的附加认证协议。OIDC 提供使用 JSON Web 令牌或 JWT 编码的用户信息。启用 OpenID Connect 时，系统将提供一个模板以用于生成标识令牌和访问令牌，并将自动创建所需的组合件策略。`,
  [`Open a Directory`]: `打开目录`,
  [`Open in external editor`]: `在外部编辑器中打开`,
  [`Open the server`]: `打开服务器`,
  [`OpenAPI extensions`]: `OpenAPI 扩展`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `如果网关版本为 6000，那么尚不支持 OpenID Connect`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `仅当您选择的授权类型包括以下一项或多项时，OpenID Connect 设置才适用：“隐式”和“访问代码”。`,
  [`Operation`]: `操作`,
  [`Operation Id`]: `操作标识`,
  [`Operation switch`]: `操作切换`,
  [`Operation Switch`]: `操作切换`,
  [`Operations`]: `操作`,
  [`Operator build date`]: `操作程序构建日期`,
  [`Operator build tag`]: `操作程序构建标记`,
  [`Operator version`]: `操作程序版本`,
  [`Optionally select a CA bundle`]: `（可选）选择 CA 捆绑包`,
  [`Optimize schema definition`]: `优化模式定义`,
  [`Optional`]: `可选`,
  [`Or`]: `或者`,
  [`Or create a new application and subscribe to the selected plan`]: `或者创建新应用程序并预订所选计划`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `或者创建新产品并将其发布至沙箱目录`,
  [`Order`]: `排序`,
  [`Org`]: `组织`,
  [`Org owner`]: `组织所有者`,
  [`Organization`]: `组织`,
  [`Organization:`]: `组织：`,
  [`Organization information`]: `组织信息`,
  [`Organization Manager`]: `组织管理员`,
  [`Organization has been updated.`]: `已更新组织。`,
  [`Organizations will be listed here.`]: `此处将列出组织。`,
  [`Otherwise`]: `否则`,
  [`Output`]: `输出`,
  [`Output Claims`]: `输出声明`,
  [`Output array`]: `输出数组`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `输出根元素，即使不需要生成格式正确的 XML 文档时也如此。`,
  [`Output variable`]: `输出变量`,
  [`Override API consume types`]: `覆盖 API 使用类型`,
  [`Override API produce types`]: `覆盖 API 生产类型`,
  [`Override API security definitions`]: `覆盖 API 安全性定义`,
  [`Override rate limits`]: `覆盖速率限制`,
  [`Override default "provider" settings with configuration from this URL`]: `使用来自该 URL 的配置覆盖缺省“提供者”设置`,
  [`Override default "provider" settings with configuration from this literal`]: `使用来自该字面值的配置覆盖缺省“提供者”设置`,
  [`Override plan rate limits`]: `覆盖计划速率限制`,
  [`Override plan rate limits for individual operation`]: `覆盖个别操作的计划速率限制`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `使用目录邀请超时覆盖使用者组织邀请超时`,
  [`Overview`]: `概述`,
  [`Owner scope check`]: `所有者作用域检查`,
  [`Owner's Email`]: `所有者电子邮件`,
  [`Owner's Name`]: `所有者名称`,
  [`Owner's email`]: `所有者电子邮件`,
  [`Owns and administers the API provider organization`]: `拥有和管理 API 提供者组织`,
  [`Owns and administers the admin organization`]: `拥有并管理“管理员组织”`,
  [`Owns and administers the app developer organization`]: `拥有并管理应用程序开发人员组织`,
  [`Owns and administrates the app developer organization`]: `拥有并管理应用程序开发人员组织`,
  [`PARAMETER NAME`]: `参数名称`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `要用于验证受众 (aud) 声明的 PCRE。`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `要用于验证发出者 (iss) 声明的 PCRE。`,
  [`PER`]: `按`,
  [`Per`]: `按`,
  [`Plan`]: `计划`,
  [`Plan:`]: `计划：`,
  [`pending Consumer organization invitation`]: `暂挂使用者组织邀请`,
  [`PLAN`]: `计划`,
  [`PLANS`]: `计划`,
  [`Policy`]: `策略`,
  [`policy`]: `策略`,
  [`POLICIES`]: `策略`,
  [`POST`]: `发布`,
  [`PRODUCT`]: `产品`,
  [`Product Plan`]: `产品计划`,
  [`Product Plans`]: `产品计划`,
  [`Product Plans per page`]: `每页的产品计划数`,
  [`Property name`]: `属性名称`,
  [`Property type`]: `属性类型`,
  [`Property example`]: `属性示例`,
  [`Property description`]: `属性描述`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `找不到页面`,
  [`Parameter name`]: `参数名称`,
  [`Parameter control`]: `参数控件`,
  [`Parameters`]: `参数`,
  [`Parent`]: `父级`,
  [`Parse`]: `解析`,
  [`Parse settings URL reference`]: `解析设置 URL 引用`,
  [`Parse settings literal configuration`]: `解析设置字面值配置`,
  [`Parse settings object reference`]: `解析设置对象引用`,
  [`Parse the GraphQL query`]: `解析 GraphQL 查询`,
  [`Parse the response from the GraphQL backend server`]: `解析来自 GraphQL 后端服务器的响应`,
  [`Parse XML output`]: `解析 XML 输出`,
  [`Pass-through`]: `传递`,
  [`Password`]: `密码`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `已更改 IBM API Connect 中用户帐户的密码`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `已更改 {portalTitle} Developer Portal 中用户帐户的密码`,
  [`Password context variable`]: `密码上下文变量`,
  [`Password is invalid. Re-enter the password.`]: `密码无效。请重新输入密码。`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `针对 IBM API Connect 中用户帐户的密码重置请求`,
  [`Password reset request for your {{catalog}} developer portal account`]: `针对 {catalog} Developer Portal 帐户的密码重置请求`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `密码必须至少包含以下类别中的两种字符：小写、大写、数字和标点（例如，!、$、#、%）`,
  [`Passwords must contain eight or more characters`]: `密码必须包含 8 个或更多字符`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `密码必须包含八个或更多个字符，且必须至少包含以下类别中的两种字符：小写、大写、数字和标点（例如，!、$、#、%）。此外，相同字符不得连续使用两次以上。`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `密码不能包含连续使用两次以上的相同字符。`,
  [`Passwords should match`]: `密码应匹配`,
  [`Path`]: `路径`,
  [`Path parameters`]: `路径参数`,
  [`Path name`]: `路径名`,
  [`Paths`]: `路径`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `路径标识了 API 所公开的 REST 资源。操作将路径与请求和响应的 HTTP 动词、参数和定义组合在一起。`,
  [`Payments Authorization`]: `支付授权`,
  [`Payment method`]: `支付方法`,
  [`Pending`]: `正在暂挂`,
  [`Pending State`]: `暂挂状态`,
  [`Pending approval to be published in catalog`]: `暂挂要在目录中发布的核准`,
  [`Pending approval to be staged in catalog`]: `暂挂要在目录中登台的核准`,
  [`Pending consumer organization invitations`]: `暂挂使用者组织邀请`,
  [`View endpoints`]: `查看端点`,
  [`View base endpoints for this API`]: `查看此 API 的基本端点`,
  [`View pending invitations`]: `查看暂挂邀请`,
  [`Permission Denied`]: `许可权被拒绝`,
  [`Permissions`]: `许可权`,
  [`Persistent Connection`]: `持续连接`,
  [`Pin menu`]: `置顶菜单`,
  [`PlainText`]: `明文`,
  [`Plan APIs`]: `计划 API`,
  [`Plan burst limits`]: `计划突发流量限制`,
  [`Plan by name`]: `按名称计划`,
  [`Plan Default`]: `缺省计划`,
  [`Plan Details`]: `计划详细信息`,
  [`Plan migration targets`]: `计划迁移目标`,
  [`Plan rate limits`]: `计划速率限制`,
  [`Plan name`]: `计划名称`,
  [`Plan pricing`]: `套餐定价`,
  [`Plan title already exists`]: `计划标题已存在`,
  [`Plans`]: `计划`,
  [`Platform REST API endpoint for admin and provider APIs`]: `管理员和提供者 API 的 Platform REST API 端点`,
  [`Platform REST API endpoint for consumer APIs`]: `使用者 API 的 Platform REST API 端点`,
  [`Please choose a bind method`]: `请选择绑定方法`,
  [`Please choose an authentication method`]: `请选择认证方法`,
  [`Please contact the system administrator.`]: `请与系统管理员联系。`,
  [`Please contact your administrator for support.`]: `请与管理员联系以获取支持。`,
  [`Please enter the reason.`]: `请输入原因。`,
  [`Please enter your username`]: `请输入用户名`,
  [`Please go to the space settings to manage API endpoints.`]: `请转至“空间设置”以管理 API 端点。`,
  [`Please make sure the server is online and try again.`]: `请确保服务器已联机，然后重试。`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `请注意，禁用活动日志将移除与在成功或发生错误的情况下要记录的内容类型相关联的属性。确定要将其禁用？`,
  [`Please review the license for API Connect by accessing`]: `请通过访问以下内容查看 API Connect 的许可证：`,
  [`Please select a TLS`]: `请选择 TLS`,
  [`Please select a TLS client profile`]: `请选择 TLS 客户机概要文件`,
  [`Please select a group type`]: `请选择组类型`,
  [`Please select an LDAP protocol version`]: `请选择 LDAP 协议版本`,
  [`Please select authentication method`]: `请选择认证方法`,
  [`Please select ciphers from the list below:`]: `请从以下列表中选择密码：`,
  [`Please select consumer organization`]: `请选择使用者组织`,
  [`Please select one Keystore from the list below:`]: `请从以下列表中选择一个密钥库：`,
  [`Please select one keystore from the list below:`]: `请从以下列表中选择一个密钥库：`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `请记录此新应用程序的客户机标识和私钥。将私钥存储在某个安全位置，因为虽然在需要时可重置，但是无法重新检索：`,
  [`Please try again in a few minutes.`]: `请过几分钟后重试。`,
  [`Please refresh catalog/space product list page and try again.`]: `请刷新目录/空间产品列表页面，然后重试。`,
  [`Policies`]: `策略`,
  [`Policy Assemblies`]: `策略组合件`,
  [`Policy assembly`]: `策略组合件`,
  [`Policy description.`]: `策略描述。`,
  [`Policy title`]: `策略标题`,
  [`Policy has been uploaded.`]: `策略已上载。`,
  [`Polish`]: `波兰语`,
  [`Port`]: `端口`,
  [`Portal`]: `门户网站`,
  [`Portal details`]: `门户网站详细信息`,
  [`Portal Director TLS client profile`]: `门户网站导向器 TLS 客户机概要文件`,
  [`Portal Director keystore`]: `门户网站导向器密钥库`,
  [`Portal Director truststore`]: `门户网站导向器信任库`,
  [`Portal service`]: `门户网站服务`,
  [`Portal Service`]: `门户网站服务`,
  [`Portal service name`]: `门户网站服务名称`,
  [`Portal service details`]: `门户网站服务详细信息`,
  [`Portal Service and URL`]: `门户网站服务和 URL`,
  [`Portal service {name} was successfully created.`]: `已成功创建门户网站服务 {name}。`,
  [`Portal service {name} was successfully updated.`]: `已成功更新门户网站服务 {name}。`,
  [`Portal Service {arg} has been removed.`]: `已移除门户网站服务 {arg}。`,
  [`Portal site`]: `门户网站站点`,
  [`Portal URL`]: `门户网站 URL`,
  [`Portal site URLs cannot be greater than 200 characters`]: `门户网站站点 URL 不能超过 200 个字符`,
  [`Portal website URL`]: `门户网站 Web 站点 URL`,
  [`Portal and Gateway Settings`]: `门户网站和网关设置`,
  [`Portal has been created`]: `已创建门户网站`,
  [`Portal has been created.`]: `已创建门户网站。`,
  [`Portal has been deleted`]: `已删除门户网站`,
  [`Portals`]: `门户网站`,
  [`Portuguese`]: `葡萄牙语`,
  [`Post Response`]: `响应后`,
  [`Post-Invoke Hook`]: `Post-Invoke Hook`,
  [`Preserve subscriptions on re-publish`]: `重新发布时保留预订`,
  [`Preserve subscriptions on re-publish product`]: `重新发布产品时保留预订`,
  [`Pre-Invoke Hook`]: `Pre-Invoke Hook`,
  [`Pre-Request`]: `请求前`,
  [`Preference`]: `首选项`,
  [`Prefix`]: `前缀`,
  [`Preparing API`]: `正在准备 API`,
  [`Preserved request header`]: `保留的请求头`,
  [`preserved request header`]: `保留请求头`,
  [`Preserved response header`]: `保留的响应头`,
  [`preserved response header`]: `保留响应头`,
  [`Preview`]: `预览`,
  [`Preview of login screen registry prompt`]: `登录屏幕注册表提示预览`,
  [`Previous`]: `上一个`,
  [`Previous page`]: `上一页`,
  [`Prettify`]: `美化`,
  [`Price per month`]: `每月价格`,
  [`Private`]: `专用`,
  [`Private Claims`]: `专用声明`,
  [`Private key & public key`]: `专用密钥和公用密钥`,
  [`Private Key or Shared Secret for Decrypt`]: `用于解密的专用密钥或共享私钥`,
  [`Private Key or Shared Secret for Sign`]: `用于签名的专用密钥或共享私钥`,
  [`Private key detected`]: `检测到专用密钥`,
  [`Private key password`]: `专用密钥密码`,
  [`Processed`]: `已处理`,
  [`Problem`]: `问题`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `访存跟踪数据时出现问题。请重试。请查看控制台以获取错误详细信息。`,
  [`Produced Media Types`]: `生成的介质类型`,
  [`Produces`]: `生成`,
  [`Product`]: `产品`,
  [`Product:`]: `产品：`,
  [`Product / Plan`]: `产品/计划`,
  [`Product APIs`]: `产品 API`,
  [`Product editor`]: `产品编辑器`,
  [`Product lifecycle`]: `产品生命周期`,
  [`Product Lifecycle`]: `产品生命周期`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `产品名称不能以“-auto-product”结尾。 请更改名称，然后重试导入。`,
  [`Product setup`]: `产品设置`,
  [`Product details`]: `产品详细信息`,
  [`Product documentation`]: `产品文档`,
  [`Product has been created.`]: `已创建产品`,
  [`Product has been deleted`]: `已删除产品`,
  [`Product has been updated`]: `已更新产品`,
  [`Product has been updated.`]: `已更新产品。`,
  [`Product has not been created!`]: `尚未创建产品！`,
  [`Product has not been published!`]: `尚未发布产品！`,
  [`Product has not been updated!`]: `尚未更新产品！`,
  [`Product has not been staged!`]: `尚未登台产品！`,
  [`Product information`]: `产品信息`,
  [`Product summary details`]: `产品摘要详细信息`,
  [`Product version`]: `产品版本`,
  [`Product visibility`]: `产品可视性`,
  [`Product-Approval`]: `产品核准`,
  [`Product-Drafts`]: `草稿产品`,
  [`Product/plan`]: `产品/计划`,
  [`Production`]: `生产`,
  [`Production mode`]: `生产方式`,
  [`Production endpoints`]: `生产端点`,
  [`Products`]: `产品`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `必须将产品登台到目录并进行发布，以供应用程序开发人员使用。目录表现为网关和 Developer Portal 的逻辑分区。用于 API 调用和 Developer Portal 的 URL 特定于特定的目录。在典型配置中，API 提供者组织在测试正在开发的 API 时使用开发目录，而在托管可供完全使用的 API 时则使用生产目录。`,
  [`Profile`]: `概要文件`,
  [`Profile rule`]: `概要文件规则`,
  [`Promote to production`]: `升级到生产级别`,
  [`Proof Key for Code Exchange`]: `用于代码交换的证明密钥`,
  [`Proof Key for Code Exchange (PKCE)`]: `代码交换证明密钥 (PKCE)`,
  [`Properties`]: `属性`,
  [`Property`]: `属性`,
  [`Property Description`]: `属性描述`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `通过在下方输入静态值，可以将其分配给属性 {{target}}。`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `属性 {{target}} 当前映射至 {{from}} - 可以在下方移除该映射。`,
  [`Property Value is Base64 Encoded`]: `属性值为 Base64 编码`,
  [`Protocol`]: `协议`,
  [`Protocols`]: `协议`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `可用于调用 API 的协议（已实施网关的 API 仅支持 https）`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `提供 OAuth {oauthVer} 凭证以认证用户并获取对 OpenID 提供者的 API 的访问权`,
  [`Provide a schema`]: `提供模式`,
  [`Provide GraphQL schema`]: `提供 GraphQL 模式`,
  [`Provide the URL to the backend GraphQL server`]: `向后端 GraphQL 服务器提供 URL`,
  [`Provide your email address to receive password reset instructions`]: `提供用于接收密码重置指示信息的电子邮件地址`,
  [`Provide your new password below`]: `在下面提供新密码`,
  [`Provider endpoint`]: `提供者端点`,
  [`Provider Information`]: `提供者信息`,
  [`Provider Organization`]: `提供者组织`,
  [`Provider Organizations`]: `提供者组织`,
  [`Provider Type`]: `提供者类型`,
  [`Provider organizations`]: `提供者组织`,
  [`Provider type`]: `提供者类型`,
  [`Provider org invite failed`]: `提供者组织邀请失败`,
  [`Provider organization {name} created`]: `已创建提供者组织 {name}`,
  [`Provider organizations will be listed here`]: `将在此处列出提供者组织`,
  [`Provider-Org`]: `提供者-组织`,
  [`Provides contact information for the exposed API.`]: `请提供公开的 API 的联系人信息。`,
  [`Provides license information for the exposed API.`]: `请提供公开的 API 的许可证信息。`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `请提供有关 API 的元数据。客户机可在需要时使用元数据。`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `已启动 Developer Portal 供应。完成此操作需要几分钟时间。完成后，您将收到一封电子邮件，其中包含用于设置门户网站管理员帐户密码的链接。`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `已启动 Developer Portal URL 的更新。完成此处理需要几分钟时间。`,
  [`Proxy`]: `代理`,
  [`Proxy URL`]: `代理 URL`,
  [`Public`]: `公共`,
  [`Public Key or Shared Secret for Encrypt`]: `用于加密的公用密钥或共享私钥`,
  [`Publish`]: `发布`,
  [`Publish API`]: `发布 API`,
  [`Publish a product from the Develop section.`]: `从“开发”部分发布产品。`,
  [`Publish product`]: `发布产品`,
  [`Publish to`]: `发布至`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `将一些产品发布至此目录以创建其预订。`,
  [`Publish target`]: `发布目标`,
  [`Publish this API to the Sandbox catalog.`]: `将此 API 发布至沙箱目录。`,
  [`Publish to specific gateway services`]: `发布至特定网关服务`,
  [`Publish using a new product`]: `使用新产品发布`,
  [`Publish using an existing product`]: `使用现有产品发布`,
  [`Publishable Key`]: `可发布的密钥`,
  [`Publishable key`]: `可发布的密钥`,
  [`Publishable key tip`]: `可发布的密钥提示`,
  [`Published`]: `已发布`,
  [`Published product`]: `已发布的产品`,
  [`Publishing`]: `正在发布`,
  [`Publishing New API`]: `正在发布新 API`,
  [`Publish (preserve subscriptions)`]: `发布（保留预订）`,
  [`Publish|permission`]: `发布`,
  [`Put online`]: `联机`,
  [`Query`]: `查询`,
  [`Queryparam Encode`]: `查询参数编码`,
  [`Queued`]: `已排入队列`,
  [`Queued events: **{queued}**`]: `排队的事件：**{queued}**`,
  [`Retry blocked jobs`]: `重试已阻止的作业`,
  [`REQUIRED`]: `必需`,
  [`ROLES`]: `角色`,
  [`Rate limit`]: `速率限制`,
  [`Rate Limit`]: `速率限制`,
  [`Rate Limit Name`]: `速率限制名称`,
  [`Rate limits`]: `速率限制`,
  [`Rate limit name can't be a duplicate`]: `速率限制名称不能重复`,
  [`Rate limit name can't be empty`]: `速率限制名称不能为空`,
  [`Rate limit overrides`]: `速率限制覆盖`,
  [`Rate limit time interval can't be 0`]: `速率限制时间间隔不能为 0`,
  [`Rate limit time interval can't be empty`]: `速率限制时间间隔不能为空`,
  [`Rate limit time interval unit must be selected`]: `必须选中速率限制时间间隔单位`,
  [`Rate limit value can't be empty`]: `速率限制值不能为空`,
  [`Rate limit value can't be negative`]: `速率限制值不能为负值`,
  [`Re-stage`]: `重新登台`,
  [`Re-stage product`]: `使产品重新登台`,
  [`Read only`]: `只读`,
  [`Read-Only`]: `只读`,
  [`Realized`]: `已实施`,
  [`Reason`]: `原因`,
  [`Reason for declining`]: `拒绝原因`,
  [`Recommendations`]: `建议`,
  [`Recommended configuration`]: `建议配置`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `记录下面显示的客户机标识和私钥，以供以后使用。以后无法显示客户机密钥。`,
  [`Recommendations applied`]: `已采纳建议`,
  [`Redact`]: `编辑`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `从请求主体、响应主体和活动日志中编辑或移除指定的字段。通过指定 XPath 表达式，定义要编辑或移除的字段。`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `从请求主体、响应主体和活动日志中编辑或移除指定的字段。通过指定 XPath 或 JSONata 表达式，定义要编辑或移除的字段。`,
  [`Redaction`]: `编辑`,
  [`Redactions`]: `编辑`,
  [`Redirect`]: `重定向`,
  [`Redirect URI`]: `重定向 URI`,
  [`Redirect URL`]: `重定向 URL`,
  [`Redirect through Portal`]: `通过门户网站重定向`,
  [`Redirect time limit`]: `重定向时间限制`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `重定向时间限制必须在 10 到 600 秒之间`,
  [`References`]: `引用`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `请参阅 [IBM Cloud 文档]({link})以获取更多指导信息。`,
  [`Referral`]: `引荐`,
  [`Refresh`]: `刷新`,
  [`Refresh Token`]: `刷新令牌`,
  [`Refresh button`]: `“刷新”按钮`,
  [`Refresh products`]: `刷新产品`,
  [`Refresh results from server`]: `从服务器刷新结果`,
  [`Refresh token`]: `刷新令牌`,
  [`Refresh token time to live`]: `刷新令牌生存时间`,
  [`Refresh token time-to-live`]: `刷新令牌生存时间`,
  [`Refresh token time-to-live (seconds)`]: `刷新令牌生存时间（秒）`,
  [`Refresh token TTL has been changed`]: `刷新令牌生存时间已更改`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `刷新令牌生存时间为：**{seconds} 秒 ({time})**`,
  [`Refresh token time to live is **off**`]: `刷新令牌生存时间为：**off**`,
  [`Refresh tokens`]: `刷新令牌`,
  [`Refreshing`]: `正在刷新`,
  [`Refreshing...`]: `正在刷新...`,
  [`Region`]: `区域`,
  [`Register`]: `注册`,
  [`Register Gateways`]: `注册网关`,
  [`Register Service`]: `注册服务`,
  [`Register gateways`]: `注册网关`,
  [`Register service`]: `注册服务`,
  [`Register a service`]: `注册服务`,
  [`Register new services and manage existing services`]: `注册新服务和管理现有服务`,
  [`Register remote gateway`]: `注册远程网关`,
  [`Registered Admin Member`]: `已注册管理成员`,
  [`Registered Provider Org Owner or Member`]: `已注册提供者组织所有者或成员`,
  [`Registered client information on the OpenID provider`]: `有关 OpenID 提供者的注册客户机信息`,
  [`Registration completed successfully`]: `已成功完成注册`,
  [`Registration failed.`]: `注册失败。`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `各个端点的相对路径。 它们必须相对于“basePath”。`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `移除分析配置`,
  [`Remote API Connect`]: `远程 API Connect`,
  [`Remote login`]: `远程登录`,
  [`Remote publish`]: `远程发布`,
  [`Remove`]: `移除`,
  [`Remove Burst Limit`]: `移除突发流量限制`,
  [`Remove constraints`]: `移除约束`,
  [`Remove Count Limit`]: `移除计数限制`,
  [`Remove gateway extension`]: `移除网关扩展`,
  [`Remove product`]: `移除产品`,
  [`Remove Rate limit`]: `移除速率限制`,
  [`Remove the invalid constraints`]: `移除无效约束`,
  [`Remove the invalid constraints from`]: `移除以下目标的无效约束`,
  [`Remove action`]: `移除操作`,
  [`Remove case`]: `移除案例`,
  [`Remove catch`]: `移除捕获`,
  [`Remove filter`]: `移除过滤器`,
  [`Remove input`]: `移除输入`,
  [`Remove mapping`]: `移除映射`,
  [`Remove output`]: `移除输出`,
  [`Remove static value`]: `移除静态值`,
  [`Removed`]: `已移除`,
  [`Removed by user`]: `已由用户移除`,
  [`Reorder`]: `重新排序`,
  [`Repeat`]: `重复`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `重复 API 调用一定的次数，或者直至单击停止按钮`,
  [`Replace`]: `替换`,
  [`Replace Free Trial with`]: `将免费试用版替换为`,
  [`Replace Gold with`]: `将 Gold 版本替换为`,
  [`Replace GraphQL schema`]: `替换 GraphQL 模式`,
  [`Replace plans`]: `替换计划`,
  [`Replace private key & public key`]: `替换专用密钥和公用密钥`,
  [`Replace Product`]: `替换产品`,
  [`Replace product`]: `替换产品`,
  [`Replace schema`]: `替换模式`,
  [`Replace Silver with`]: `将 Silver 版本替换为`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `通过从建议列表中选择新所有者来替换当前所有者。为替换的所有者分配新许可权，以便他们可以继续工作。`,
  [`Replace|permission`]: `替换`,
  [`Republish`]: `重新发布`,
  [`Republish product`]: `重新发布产品`,
  [`Request`]: `请求`,
  [`Request catch`]: `请求捕获`,
  [`Request endpoint`]: `请求端点`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `已核准{action}目录 {catalog} 中 API 产品的请求`,
  [`Request approved to {{action}} app {{appName}}`]: `已核准{action}应用程序 {appName} 的请求`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `已拒绝{action}目录 {catalog} 中 API 产品的请求`,
  [`Request denied to {{action}} app {{appName}}`]: `已拒绝{action}应用程序 {appName} 的请求`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `用于核准预订 {catalog} 目录中 API 产品的请求`,
  [`Request for approval to {{action}} app {{appName}}`]: `用于核准{action}应用程序 {appName} 的请求`,
  [`Request headers`]: `请求头`,
  [`Request pending to promote application to production`]: `请求暂停将应用程序升级到生产级别`,
  [`Request query`]: `请求查询`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `已收到{action}目录 {catalog} 中 API 产品的请求`,
  [`Request received to {{action}} app {{appName}}`]: `已收到{action}应用程序 {appName} 的请求`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `已撤销预订 {catalog} 目录中 API 产品的请求`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `已撤销{action} {catalog} 目录中 API 产品的请求`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `{action}应用程序 {appName} 的请求已撤销`,
  [`Requested approvals`]: `请求的核准`,
  [`Requested By`]: `请求者`,
  [`Requested To`]: `请求对象`,
  [`Require`]: `需要`,
  [`Require applications to provide a client ID`]: `需要应用程序提供客户机标识`,
  [`Require approval for all new self service development accounts`]: `需要审批所有新的自助开发帐户`,
  [`Required`]: `必需`,
  [`Required for rate limiting`]: `对于速率限制是必需的`,
  [`Required must be checked off when location is in path.`]: `如果位置是路径，必须取消选中“必需”。`,
  [`Required path`]: `必需路径`,
  [`Resend Invite`]: `重新发送邀请`,
  [`Resend invitation`]: `重新发送邀请`,
  [`Resend invite`]: `重新发送邀请`,
  [`Reset`]: `重置`,
  [`Reset defaults`]: `重置缺省值`,
  [`Reset Notification template`]: `重置通知模板`,
  [`Reset password`]: `重置密码`,
  [`Reset password TTL has been changed`]: `重置密码 TTL 已更改`,
  [`Reset password time to live`]: `重置密码生存时间`,
  [`Reset password time-to-live`]: `重置密码生存时间`,
  [`Reset password email has been sent`]: `已发送重置密码电子邮件`,
  [`Reset password email has not been sent`]: `尚未发送重置密码电子邮件`,
  [`Reset password invitation timeout`]: `重置密码邀请超时`,
  [`Reset to default preferences`]: `重置为缺省首选项`,
  [`Resolve API Connect variable references`]: `解析 API Connect 变量引用`,
  [`Resolve XML input data type`]: `解析 XML 输入数据类型`,
  [`resolver`]: `解析器`,
  [`Resource group`]: `资源组`,
  [`Resource Owner`]: `资源所有者`,
  [`Resource owner password grant`]: `资源所有者密码授权`,
  [`Resource owner revocation`]: `资源所有者撤销`,
  [`Resource owner revocation path`]: `资源所有者撤销路径`,
  [`Resource Owner Security`]: `资源所有者安全性`,
  [`Resource owner - JWT`]: `资源所有者 - JWT`,
  [`Resource owner - Password`]: `资源所有者 - 密码`,
  [`Resource owner password`]: `资源所有者密码`,
  [`Resources`]: `资源`,
  [`Response`]: `响应`,
  [`Response catch`]: `响应捕获`,
  [`Response Definition`]: `响应定义`,
  [`Response Time`]: `响应时间`,
  [`Response type`]: `响应类型`,
  [`Response context variables`]: `响应上下文变量`,
  [`Response headers`]: `响应头`,
  [`Response must have a description`]: `响应必须包含描述`,
  [`Response must have a schema`]: `响应必须包含模式`,
  [`Response must have a status code`]: `响应必须包含状态码`,
  [`Response object variable`]: `响应对象变量`,
  [`Response time`]: `响应时间`,
  [`Response time: {{value}}ms`]: `响应时间：{{value}}ms`,
  [`Result of removing`]: `移除结果`,
  [`Restrict HTTP transactions to version 1.0.`]: `将 HTTP 事务限制为 V1.0。`,
  [`Restrict to HTTP 1.0`]: `限制为 HTTP 1.0`,
  [`Retire`]: `撤销`,
  [`Retire product`]: `撤销产品`,
  [`Retired`]: `已撤销`,
  [`Retire|permission`]: `撤销`,
  [`Return V5 Responses`]: `返回 V5 响应`,
  [`Retry selected requests`]: `重试所选请求`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `从 GraphQL 代理返回标准 GraphQL 自省查询的结果`,
  [`Return third-party access_token`]: `返回第三方访问令牌`,
  [`Return third-party access_token with the token response`]: `返回第三方访问令牌及令牌响应`,
  [`Return third-party id_token`]: `返回第三方标识令牌`,
  [`Return third-party id_token with the token response`]: `返回第三方标识令牌及令牌响应`,
  [`Return to Login`]: `返回到登录`,
  [`Reveal in finder`]: `在查找程序中显示`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `检查并确定要在模式中显示或隐藏的内容。可能需要额外更改以使模式保持有效。`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `请查看任何警告。稍后，我们会在编辑器中通过智能建议帮助您改进模式。`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `请查看任何警告，采纳我们的智能建议以帮助您改进模式并保护后端。`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `检查 API 调用端点的基本 URL 并指定网关所处理的域。`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `检查网关类型、标题、名称和摘要。调用 API Connect 命令和 API 时，请使用网关名称。`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `检查更改的摘要，其中可能包含使模式保持有效所需的额外更改。`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `请查看我们在模式中发现的警告。稍后，我们会在编辑器中通过智能建议帮助您改进模式并保护后端。`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `请查看我们在模式中发现的警告。我们会通过智能建议帮助您改进模式并保护后端。`,
  [`Reviewed schema, paths and operations`]: `已查看模式、路径和操作`,
  [`Revocation protocol`]: `撤销协议`,
  [`Revoke token`]: `撤销令牌`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL 文档`,
  [`Role`]: `角色`,
  [`Role default`]: `角色缺省值`,
  [`Role default has been deleted`]: `已删除角色缺省值`,
  [`Role defaults`]: `角色缺省值`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `角色缺省值是预先配置的角色模板，用于确定在创建新组织时可用的缺省角色。`,
  [`Role has been created`]: `已创建角色`,
  [`Role has been deleted`]: `已删除角色`,
  [`Role has been updated`]: `已更新角色`,
  [`Roles`]: `角色`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `角色（及其获得的许可权）控制对 API Connect 中功能的访问。管理员组织角色仅应用于使用 Cloud Manager 的人员。`,
  [`Roles and permissions control access to API Manager capabilities.`]: `角色和许可权可控制对 API Manager 功能的访问。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `角色及关联的许可权可控制对功能的访问以及在 API Connect 中执行任务的能力。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `角色及关联的许可权可控制对功能的访问以及在 API Manager UI 中执行任务的能力。`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `角色及关联的许可权可控制对功能的访问以及执行任务的能力。`,
  [`Root`]: `根`,
  [`Root XML Element Name`]: `根 XML 元素名称`,
  [`Run the following command in the CLI to register the credentials:`]: `在 CLI 中运行以下命令以注册凭证：`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `在 CLI 中运行以下命令以注册工具箱凭证：`,
  [`Running`]: `正在运行`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `包含用于解密 JWT 的 JWK 的运行时变量。`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `包含用于加密 JWT 的 JWK 的运行时变量。`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `包含用于签名 JWT 的 JWK 的运行时变量。`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `包含用于验证签名的 JWK 的运行时变量。`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `可从中检索 JSON 声明的有效集合的运行时变量。`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `可从中检索受众 (aud) 声明字符串的运行时变量。可通过逗号分隔的字符串设置多个变量。`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `可从中检索发出者 (iss) 声明字符串的运行时变量。此声明表示发出 JWT 的主体。`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `可从中检索主体 (sub) 声明字符串的运行时变量。`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `要在其中放置所生成的 JWT 的运行时变量。如果未设置此选项，那么 JWT 将作为不记名令牌放置在 Authorization 头中。`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `包含已认证的用户名的运行时变量。LTPA 令牌是将此属性作为用户生成的。例如，$(client.app.id)、$(oauth.resource-owner) 或在“set-variable”策略中配置的运行时变量。`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `要向其分配 JWT 中的完整声明集的运行时变量。`,
  [`Russian`]: `俄罗斯语`,
  [`Scalar`]: `标量`,
  [`Schema`]: `模式`,
  [`SCHEMA`]: `模式`,
  [`SCOPE`]: `作用域`,
  [`SCOPES`]: `作用域`,
  [`SECURITY DEFINITIONS`]: `安全性定义`,
  [`Service`]: `服务`,
  [`Service state:`]: `服务状态：`,
  [`SERVICE TYPE`]: `服务类型`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1 许可证`,
  [`SOURCE`]: `源`,
  [`SSL Profile`]: `SSL 概要文件`,
  [`State`]: `状态`,
  [`STATUS`]: `状态`,
  [`Status Code`]: `状态码`,
  [`STATUS CODE`]: `状态码`,
  [`SUBMITTED`]: `已提交`,
  [`SUBSCRIBERS`]: `订户`,
  [`Summary`]: `摘要`,
  [`Same as product`]: `与产品相同`,
  [`Sample ID token signing key (RS256)`]: `样本标识令牌签名密钥 (RS256)`,
  [`Sample ID token validation key (RS256)`]: `样本标识令牌验证密钥 (RS256)`,
  [`Sample OAuth symmetric key value`]: `样本 OAuth 对称密钥值`,
  [`Sample URL: https://api.catalog.org.host/`]: `样本 URL：https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `样本 URL：https://host/org/catalog/`,
  [`Sample scope description 1`]: `样本作用域描述 1`,
  [`Sandbox - Overview`]: `沙箱 - 概述`,
  [`Sandbox Catalog`]: `沙箱目录`,
  [`Sandbox Catalog User Registry`]: `沙箱目录用户注册表`,
  [`Sandbox Default TLS client profile`]: `沙箱缺省 TLS 客户机概要文件`,
  [`Sandbox Default TLS Client Profile`]: `沙箱缺省 TLS 客户机概要文件`,
  [`Sandbox catalog should always exist in a provider organization.`]: `沙箱目录应始终存在于提供者组织中。`,
  [`Save`]: `保存`,
  [`Save API as a new version`]: `将 API 另存为新版本`,
  [`Save Product as a new version`]: `将产品另存为新版本`,
  [`Save and Edit`]: `保存并编辑`,
  [`Save and republish`]: `保存并重新发布`,
  [`Save as a new version`]: `另存为新版本`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `保存客户机密钥（由于安全原因，将无法再检索此私钥）`,
  [`Save this product as a new version`]: `将此产品另存为新版本`,
  [`Saved`]: `已保存`,
  [`Saving assembly...`]: `正在保存组合件...`,
  [`Saving...`]: `正在保存...`,
  [`Schema Type`]: `模式类型`,
  [`Schema as JSON`]: `模式为 JSON`,
  [`Schema as XML`]: `模式为 XML`,
  [`Schema as YAML`]: `模式为 YAML`,
  [`Schema change`]: `模式更改`,
  [`Schema changes`]: `模式更改`,
  [`Schema definition circular reference limit`]: `模式定义循环引用限制`,
  [`Schema name`]: `模式名称`,
  [`Schema warnings`]: `模式警告`,
  [`Schema definition`]: `模式定义`,
  [`Scheme`]: `方案`,
  [`Schemes`]: `方案`,
  [`Scope`]: `作用域`,
  [`Scopes`]: `作用域`,
  [`Scopes cannot be empty`]: `作用域不能为空`,
  [`Search`]: `搜索`,
  [`Search DN`]: `搜索 DN`,
  [`Search in the field above above and select to add provider organizations`]: `在以上字段中搜索并选择添加提供者组织`,
  [`Search Limit`]: `搜索限制`,
  [`Search Organization owner from`]: `从以下位置搜索组织所有者：`,
  [`Search orgs/groups`]: `搜索组织/组`,
  [`Search Owner`]: `搜索所有者`,
  [`Search assemblies`]: `搜索组合件`,
  [`Search for`]: `搜索`,
  [`Search for provider organizations`]: `搜索提供者组织`,
  [`Search for Owner`]: `搜索所有者`,
  [`Search for a new member to become the new owner.`]: `搜索新成员以使其成为新所有者。`,
  [`Search for an existing member to become the new owner.`]: `搜索现有成员以使其成为新所有者。`,
  [`Search for new owner`]: `搜索新所有者`,
  [`Search for owner from:`]: `从以下位置搜索所有者：`,
  [`Search for the member`]: `搜索成员`,
  [`Search for title or name or version`]: `搜索标题、名称或版本`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `搜索要添加为成员的用户，并分配所需的角色。`,
  [`Search overflow`]: `搜索溢出`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `在注册表中搜索要添加为成员的现有用户，并分配所需的角色`,
  [`Search types/fields`]: `搜索类型/字段`,
  [`Search user`]: `搜索用户`,
  [`Search schema`]: `搜索模式`,
  [`Seconds`]: `秒`,
  [`Secret Key`]: `密钥`,
  [`Secret Name`]: `私钥名称`,
  [`Secret Required`]: `必需私钥`,
  [`Secret key`]: `密钥`,
  [`Secure`]: `安全`,
  [`Secure connection`]: `安全连接`,
  [`Secure using Client ID`]: `使用客户机标识进行保护`,
  [`Security`]: `安全性`,
  [`Security & Product Definitions`]: `安全性和产品定义`,
  [`Security definitions`]: `安全性定义`,
  [`Security and Publish`]: `安全与发布`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `安全性定义可控制客户机对 API 端点的访问，包括 API 密钥验证、通过指定用户注册表进行的基本认证以及 OAuth。`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `安全性定义可控制客户机对 API 端点的访问，包括 API 密钥验证、通过指定用户注册表进行的基本认证以及 OAuth。[了解更多信息]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `此处选择的安全性定义将应用于整个 API，但是可针对个别操作进行覆盖。`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `此处选择的安全性定义将应用于整个 API，但是可针对个别操作进行覆盖。[了解更多信息]({link})`,
  [`Security object to use to decode the claim.`]: `要用于解码声明的安全对象。`,
  [`Security object used to encrypt the JWT.`]: `用于加密 JWT 的安全对象。`,
  [`Security object used to sign the JWT.`]: `用于签名 JWT 的安全对象。`,
  [`Security object used to verify the claim.`]: `用于验证声明的安全对象。`,
  [`Select APIs to add to this product`]: `选择要添加到此产品的 API`,
  [`Select API Type`]: `选择 API 类型`,
  [`Select API type`]: `选择 API 类型`,
  [`Select a billing integration in the Product plans.`]: `从产品计划中选择一个计费集成。`,
  [`Select a flow...`]: `选择流...`,
  [`Select a plan from your existing product`]: `从现有产品中选择计划`,
  [`Select a policy file to import to this catalog`]: `选择策略文件以导入至此目录`,
  [`Select a policy implementation file to import to this catalog`]: `选择策略实施文件以导入至此目录`,
  [`Select a product`]: `选择产品`,
  [`Select Certificate Manager service`]: `选择证书管理器服务`,
  [`Select Cloud`]: `选择云`,
  [`Select Email Server`]: `选择电子邮件服务器`,
  [`Select gateway services`]: `选择网关服务`,
  [`Select Import Type`]: `选择导入类型`,
  [`Select product`]: `选择产品`,
  [`Select product type`]: `选择产品类型`,
  [`Select Service`]: `选择服务`,
  [`Select Service Type`]: `选择服务类型`,
  [`Select target endpoints`]: `选择目标端点`,
  [`Select target service`]: `选择目标服务`,
  [`Select user registry type`]: `选择用户注册表类型`,
  [`Select a WSDL service from the imported file`]: `从导入的文件中选择 WSDL 服务`,
  [`Select a certificate`]: `选择证书`,
  [`Select a Certificate Manager service`]: `选择证书管理器服务`,
  [`Select a cryptographic algorithm.`]: `选择密码算法。`,
  [`Select a default catalog and organization`]: `选择缺省目录和组织`,
  [`Select a different cloud`]: `选择其他云`,
  [`Select a key encryption algorithm.`]: `选择密钥加密算法。`,
  [`Select a plan to migrate`]: `选择要迁移的计划`,
  [`Select a plan to replace`]: `选择要替换的计划`,
  [`Select a plan to supersede`]: `选择计划来取代`,
  [`Select a product to set as the migration target`]: `选择要设置为迁移目标的产品`,
  [`Select a product to supersede`]: `选择产品来取代`,
  [`Select a product to supersede {sourceProductName}:`]: `选择产品以取代 {sourceProductName}：`,
  [`Select a resource group`]: `选择资源组`,
  [`Select a service`]: `选择服务`,
  [`Select a supported file ({type}) and try again.`]: `选择受支持的文件 ({type})，然后重试。`,
  [`Select a target SOAP service`]: `选择目标 SOAP 服务`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `选择要下载的工具箱。如果已安装该工具箱，请跳过此步骤。`,
  [`Select an encryption algorithm.`]: `选择加密算法。`,
  [`Select an extension file for this gateway`]: `选择此网关的扩展文件`,
  [`Select an org`]: `选择组织`,
  [`Select catalog`]: `选择目录`,
  [`Select Catalogs to Search`]: `选择要搜索的目录`,
  [`Select compatible gateway services`]: `使用兼容网关服务`,
  [`Select one Keystore from the list below:`]: `从以下列表中选择一个密钥库：`,
  [`Select one keystore from the list below:`]: `从以下列表中选择一个密钥库：`,
  [`Select one keystore from the list.`]: `从列表中选择一个密钥库。`,
  [`Select operation...`]: `选择操作...`,
  [`Select operations you want to include`]: `选择要包含的操作`,
  [`Select organization`]: `选择组织`,
  [`Select the API definition or package file to import`]: `选择要导入的 API 定义或包文件`,
  [`Select the billing integration for this catalog`]: `选择此目录的计费集成`,
  [`Select the billing integration for this product`]: `选择此产品的计费集成`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `选择将保护新目录中的 API 的网关。要注册新网关，请访问**网关**页面。`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `选择用于管理此网关的证书的[证书管理器]({link})服务。`,
  [`Select the APIs to add to this product`]: `选择要添加到此产品的 API`,
  [`Select the OpenAPI definition file to import`]: `选择要导入的 OpenAPI 定义文件`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `选择需要核准的产品生命周期操作。在执行任何选中的操作时，都将生成核准请求；必须核准该请求才能完成该操作。`,
  [`Select the TLS protocol versions for this profile.`]: `为此概要文件选择 TLS 协议版本。`,
  [`Select the analytics service you would like to associate with the gateway service`]: `选择要与网关服务相关联的分析服务`,
  [`Select the consumer organization in which you want to create the application`]: `选择要在其中创建应用程序的使用者组织`,
  [`Select the consumer organization that will own the application`]: `选择将拥有应用程序的使用者组织`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `选择端点以发布此 API 并根据需要定义速率限制`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `选择会导致组合件流停止的错误（如果在策略执行期间抛出）。如果针对错误配置了捕获流，那么将触发该流以处理抛出的错误。如果抛出错误并且未针对“出错时停止”设置选择任何错误，或者，如果抛出的错误不是选中的错误之一，那么将允许策略执行完成，并且组合件流也会继续执行。`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `选择要在其中提供产品中的 API 的网关服务。`,
  [`Select the gateway type for this API`]: `为此 API 选择网关类型`,
  [`Select the gateway type for this OAuth provider`]: `为此 OAuth 提供者选择网关类型`,
  [`Select the keystores and truststores for this profile.`]: `为此概要文件选择密钥库和信任库。`,
  [`Select the organizations or groups you would like to make this product visible to`]: `选择可了解有关此产品的相关信息的组织或群组`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `选择可预订此产品的组织或群组`,
  [`Select the portal service to use for this catalog`]: `选择要用于此目录的门户网站服务`,
  [`Select the product and plan for the application subscription`]: `选择应用程序预订的产品和计划`,
  [`Select the product definition file to import`]: `选择要导入的产品定义文件`,
  [`Select the product definition file to import from`]: `选择要从中导入的产品定义文件`,
  [`Select the product that will supersede the originally selected product`]: `选择将取代最初所选产品的产品`,
  [`Select the product that you want to add the API to`]: `选择要向其添加 API 的产品`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `选择要用于控制对此目录中 API 的访问的注册表`,
  [`Select the required operations`]: `选择必需的操作`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `选择与输入数据相关的日志消息的严重性级别；可能的级别包括错误、警告和参考。`,
  [`Select the target wsdl file to create from`]: `选择作为创建基础的目标 WSDL 文件`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `选择作为 API 定义创建基础的目标 WSDL、xsd 或 zip 文件`,
  [`Select the target WSDL file to import`]: `选择要导入的目标 WSDL 文件`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `选择用于认证 API Manager 用户的用户注册表，或者创建新的用户注册表。`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `选择用于认证 API Manager 用户的用户注册表，或创建新的用户注册表。`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `选择用于认证 Cloud Manager 用户的用户注册表，或创建新的用户注册表。`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `选择用于向 Developer Portal 认证登录的用户注册表`,
  [`Select the user registry type`]: `选择用户注册表类型`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `选择策略应当将生成的 LTPA 令牌放置在输出源中的什么位置。仅当请求中的内容类型为 application/xml 时，才选择“在 WSSec 标头中”。`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `选择是完全移除属性，还是使用“*”阻止数据以编辑字段。`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `已修改所选产品。 请刷新页面并重试。`,
  [`Selecting operations from WSDL service definition`]: `从 WSDL 服务定义选择操作`,
  [`Self Service Onboarding for Consumer organization`]: `使用者组织的自助服务上线`,
  [`Self service onboarding`]: `自助服务上线`,
  [`Self service onboarding approval`]: `自助服务上线审批`,
  [`Send`]: `发送`,
  [`Send Message`]: `发送消息`,
  [`Send Message to Owners`]: `向所有者发送消息`,
  [`Send To`]: `发送至`,
  [`Send a registration link to a new user to register as owner.`]: `向新用户发送注册链接以便其注册成为所有者。`,
  [`Send a test email`]: `发送测试电子邮件`,
  [`Send a test email to confirm that the email server is properly configured.`]: `发送测试电子邮件以确认电子邮件服务器已正确配置。`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `发送测试信号以确认端点配置正确。`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `发送测试信号以确认端点配置正确`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `向管理员组织所有者发送电子邮件消息。将复选标记置于所有者名称旁边`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `向所有者发送电子邮件消息。将复选标记置于所有者名称旁边`,
  [`Send chunked-encoded documents to the target server.`]: `将分块编码的文档发送到目标服务器。`,
  [`Send message`]: `发送消息`,
  [`Send message to owner`]: `向所有者发送消息`,
  [`Send test email`]: `发送测试电子邮件`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `将激活链接发送给用户以邀请他们登录或注册。`,
  [`Send-only`]: `仅发送`,
  [`Sender`]: `发件人`,
  [`Sender & email server`]: `发件人和电子邮件服务器`,
  [`Sender & email server have been changed`]: `已更改发件人和电子邮件服务器`,
  [`Sender Info`]: `发件人信息`,
  [`Sent`]: `发送`,
  [`Sent events: **{sent}**`]: `已发送的事件：**{sent}**`,
  [`Serial`]: `序列`,
  [`Serialize output`]: `序列化输出`,
  [`Server connection`]: `服务器连接`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `向 Web 浏览器提供 HTML 以启用 GUI GraphQL 客户机`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `向 Web 浏览器提供 HTML 以启用 GUI GraphQL 客户机。`,
  [`Server Name Indication (SNI)`]: `服务器名称指示 (SNI)`,
  [`Server URL`]: `服务器 URL`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `服务器在散列（而非 OIDC 重定向 URL 的令牌）中返回错误。 散列：`,
  [`Service name`]: `服务名称`,
  [`Services`]: `服务`,
  [`Set`]: `设置`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `为 API Designer 设置 APIC_DESIGNER_CREDENTIALS 操作系统环境变量。例如，在 MAC 上，用户可以在启动 API Designer 时传入环境变量。`,
  [`Set Migration Target`]: `设置迁移目标`,
  [`Set migration target`]: `设置迁移目标`,
  [`Set Variable`]: `设置变量`,
  [`Set as default`]: `设为缺省值`,
  [`Set default`]: `设置缺省值`,
  [`Set rate and burst limits in an assembly`]: `在组合件中设置速率限制和突发流量限制`,
  [`Set the timeout period for invitations`]: `设置邀请的超时期限`,
  [`Set the trust level for the profile`]: `设置概要文件的信任级别`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `将值设置为允许的循环模式定义的迭代次数（缺省值为 1，最大值为 5）`,
  [`Set the visibility for a gateway service`]: `设置网关服务的可视性`,
  [`Set the visibility for a portal service`]: `设置门户网站服务的可视性`,
  [`Set the visibility for each service`]: `设置每个服务的可视性`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `设置获取认证服务器发出的新访问令牌的时间范围`,
  [`Set visibility`]: `设置可视性`,
  [`Set visibility of gateway`]: `设置网关的可视性`,
  [`Set time interval`]: `设置时间间隔`,
  [`Set, Add, or Clear a runtime variable.`]: `设置、添加或清除运行时变量。`,
  [`Set the timeout period for API key`]: `设置 API 密钥的超时期限`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `设置文档在高速缓存中的有效期（以秒计）。仅当“高速缓存类型”设置为“生存时间”时才适用。请输入 5 - 31708800 范围内的值。缺省值为 900。`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `设置“assumedSize”的值时，不能同时定义具有缺省值的切割自变量，也不能同时启用“requireOneSlicingArgument”。`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `将此目录设置为缺省目录将允许使用短 URL 进行访问，省略目录名称。您只能将一个目录设置为缺省目录。`,
  [`Settings`]: `设置`,
  [`Setup`]: `设置`,
  [`Severity level for input data log messages`]: `输入数据日志消息的严重性级别`,
  [`Share the resource with a custom list of provider organizations`]: `与定制提供者组织列表共享资源`,
  [`Share the resource with provider organizations`]: `与提供者组织共享资源`,
  [`Share the API gateway with a custom list of provider organizations`]: `与定制列表中的提供者组织共享 API Gateway`,
  [`Share the service with a custom list of provider organizations`]: `与定制列表中的提供者组织共享服务`,
  [`Share the API gateway with provider organizations`]: `与提供者组织共享 API Gateway`,
  [`Share the service with provider organizations`]: `与提供者组织共享服务`,
  [`Share`]: `共享`,
  [`Shared`]: `共享`,
  [`Shared by`]: `共享者`,
  [`Short but descriptive name for this policy.`]: `该策略的简短描述性名称。`,
  [`Show`]: `显示`,
  [`Show / hide policy palette`]: `显示/隐藏策略选用板`,
  [`Show/hide`]: `显示/隐藏`,
  [`Show/hide settings`]: `显示/隐藏设置`,
  [`Show and hide types and fields to include in the schema.`]: `显示和隐藏模式中包含的类型和字段。`,
  [`Show added only`]: `仅显示已添加的项`,
  [`Show all`]: `全部显示`,
  [`Show all UDP versions`]: `显示所有 UDP 版本`,
  [`Show app identifier`]: `显示应用程序标识`,
  [`Show breaking changes only`]: `仅显示重大更改`,
  [`Show catalog identifier`]: `显示目录标识`,
  [`Show catches`]: `显示捕获`,
  [`Show in schema`]: `在模式中显示`,
  [`Show less`]: `显示更少内容`,
  [`Show modified only`]: `仅显示已修改的项`,
  [`Show more`]: `显示更多内容`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `显示新的类型、字段、伪指令或可选的自变量。仍将移除不再受后端支持的项。`,
  [`Show removed only`]: `仅显示已移除的项`,
  [`Show schema`]: `显示模式`,
  [`Show warnings only`]: `仅显示警告`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `显示密钥库分配的历史记录（不含当前分配的密钥库）。`,
  [`Sign Crypto Object`]: `签名加密对象`,
  [`Sign in`]: `登录`,
  [`Sign JWK variable name`]: `签名 JWK 变量名称`,
  [`Sign Out`]: `注销`,
  [`Sign up`]: `注册`,
  [`sign-up`]: `注册`,
  [`Sign in using the {userRegistryType} User Registry`]: `使用 {userRegistryType} 用户注册信息来登录`,
  [`Sign in with`]: `登录方式：`,
  [`Sign out`]: `注销`,
  [`Sign up with`]: `注册方式`,
  [`Signed token`]: `签名的令牌`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `由于已将 buffering 设置为 off，因此需要将 content 和/或 error-content 从 payload 减少到 headers。要继续所需更改还是取消最近更改？`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `由于已将 content 和/或 error-content 设置为 payload，因此需要将 buffering 设置为 on。要继续所需更改还是取消最近更改？`,
  [`Sized fields`]: `限制大小的字段`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `切割自变量`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `某些输入是数组或者包含在数组中。要对内容进行迭代，请选择迭代选项并选择要迭代的输入。`,
  [`Something went wrong.`]: `出现问题。`,
  [`Something went wrong...`]: `出现问题...`,
  [`Source`]: `源`,
  [`Source schema`]: `源模式`,
  [`Space`]: `空间`,
  [`Space is not allowed in scope name`]: `作用域名称中不允许使用空格`,
  [`Space details`]: `空间详细信息`,
  [`Space Enabled`]: `空间已启用`,
  [`Space Name`]: `空间名称`,
  [`Space Summary`]: `空间摘要`,
  [`Space Title`]: `空间标题`,
  [`Space owner`]: `空间所有者`,
  [`Space {name} created`]: `已创建空间 {name}`,
  [`Spaces`]: `空间`,
  [`Spaces are enabled`]: `已启用空间`,
  [`Spaces are not enabled`]: `未启用空间`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `无法针对沙箱目录启用空间。`,
  [`Spaces cannot be enabled.`]: `无法启用空间。`,
  [`Spaces dropdown`]: `“空间”下拉列表`,
  [`Spaces has been enabled`]: `已启用空间`,
  [`Spaces have been enabled.`]: `已启用空间。`,
  [`Spaces per page`]: `每页空间数`,
  [`Spanish`]: `西班牙语`,
  [`Special characters are not accepted`]: `不接受特殊字符`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `指定表示指定上下文的 URL 或字符串，将从该上下文中检索要合并到动态对象中的序列化 XML 或 JSON 属性。这些属性优先于任何现有字面值或缺省属性。`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `指定作为要合并到动态对象的序列化 XML 或 JSON 属性的文字串。这些属性优先于任何现有缺省属性。`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `指定要从中检索动态对象的缺省属性值的现有有效对象。警告：如果对缺省字符串进行更改，那么指定的解析设置对象必须在 DataPower 设备上可用。`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `指定包含要编辑或移除的内容的数据源。如果未指定根，那么此操作将应用于整个 API 上下文。您可以使用任何受支持的 JSONata 路径表达式。\n\n可使用根“message.body”来编辑请求和响应数据。 如果在完成指定仅收集方式的组合件记录操作后在组合件中使用组合件编辑操作，那么根可针对记录的请求有效内容指定“log.request_body”或者针对记录的响应有效内容指定“log.response_body”。`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `指定 API 上下文中变量的名称。变量的“body”字段的内容是操作的输入。缺省变量名称是“message”。`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `指定 API 上下文中变量的名称。变量的“body”字段的内容是解析的输出。已解析文档的解析度量值可以存储在消息的另一个部分中。缺省变量名称是输入名称，因此，在缺省情况下，输入消息将被输出消息覆盖。`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `指定是否获取访问令牌的定制元数据以及在哪里获取这些元数据。`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `指定是否尝试使用指定的内容类型进行解析。只有当期望的内容类型为 json 或 xml 时才适用。启用此选项后，如果内容类型与解析设置不匹配（即内容类型为 application/json 而针对 xml 配置了解析设置），那么解析操作将失败。如果将解析设置配置为检测输入内容，那么会将其强制设置为指定的内容类型。`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `指定在转换后是否将 XSLT 输出树序列化为二进制数据。`,
  [`Specify default gateways`]: `指定缺省网关`,
  [`Specify email`]: `指定电子邮件`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `指定用于验证有效内容的 OpenAPI (Swagger) 模式定义（“request”、“response”或“#/definitions/XXXX”）。`,
  [`Specify owner of the consumer organization`]: `指定使用者组织的所有者`,
  [`Specify owner of the provider organization`]: `指定提供者组织的所有者`,
  [`Specify settings for the third party OAuth provider.`]: `指定用于第三方 OAuth 提供者的设置。`,
  [`Specify the MIME types that your API consumes and produces`]: `指定 API 使用和生成的 MIME 类型`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `指定目录所有者；所有者拥有所有目录许可权`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `指定能够在 Developer Portal 中查看产品的使用者组织或开发人员社区`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `指定能够预订使用产品中 API 的使用者组织或开发人员社区`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `指定 API 调用端点的基本 URL 和网关所处理的域。`,
  [`Specify the details of the catalog that you want to publish to`]: `指定要发布至的目录的详细信息`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `指定将保护新目录中的 API 的网关的缺省集合。[了解更多信息]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `指定要将此 API 发布至的端点，以及是否要限制 API 调用的比率`,
  [`Specify the new owner`]: `指定新所有者`,
  [`Specify the owner`]: `指定所有者`,
  [`Specify the owner and title`]: `指定所有者和标题`,
  [`Specify the properties to redact or remove.`]: `指定要编辑或移除的属性。`,
  [`Specify the publish and rate limiting requirements for this API`]: `指定此 API 的发布和速率限制需求`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `指定除定义的作用域外要执行额外作用域验证的作用域检查端点。`,
  [`Specify the security settings for the imported API definition`]: `为导入的 API 定义指定安全设置`,
  [`Specify the security settings for your API definition`]: `为 API 定义指定安全设置`,
  [`Specify the space owner; the owner has all space permissions`]: `指定空间所有者；所有者拥有所有空间许可权`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `指定目标 SOAP 服务和 API 摘要详细信息；如果需要，您可以在创建 API 定义之后进一步对其进行配置`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `指定在用户未激活其帐户的情况下邀请电子邮件到期的超时期限`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `指定在用户未激活其帐户的情况下邀请电子邮件到期的超时期限。`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `指定网关类型、标题和用于描述网关的摘要。`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `指定 API Gateway 对于 API Connect 提供者组织的可视性。`,
  [`Specify the way you want to validate the message.`]: `指定验证消息的方式。`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `指定网关管理端点的 URL 和端点的域的证书。`,
  [`Specify what content to log in the event of an error.`]: `指定发生错误时要记录的内容。`,
  [`Specify what content to log.`]: `指定要记录的内容。`,
  [`Specifying the publish target details`]: `指定发布目标详细信息`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `明确允许 xsi:type=SOAP-ENC:Array 规则`,
  [`Stage`]: `登台`,
  [`Stage API`]: `登台 API`,
  [`Stage product`]: `登台产品`,
  [`Stage to`]: `登台到`,
  [`Staged`]: `已登台`,
  [`Stage|permission`]: `登台`,
  [`Standard OIDC`]: `标准 OIDC`,
  [`Static DNS`]: `静态 DNS`,
  [`Static group`]: `静态组`,
  [`Status`]: `状态`,
  [`Status code`]: `状态码`,
  [`Step 1`]: `步骤 1`,
  [`Step 1: Download`]: `步骤 1：下载`,
  [`Step 1: Download toolkit`]: `步骤 1：下载工具箱`,
  [`Step 1: Upload private key`]: `步骤 1：上载专用密钥`,
  [`Step 2`]: `步骤 2`,
  [`Step 2: Download credentials`]: `步骤 2：下载凭证`,
  [`Step 2: Download toolkit credentials`]: `步骤 2：下载工具箱凭证`,
  [`Step 2: Install`]: `步骤 2：安装`,
  [`Step 2: Upload public key`]: `步骤 2：上载公用密钥`,
  [`Step 3`]: `步骤 3`,
  [`Step 3: Configure`]: `步骤 3：配置`,
  [`Step 3: Run command`]: `步骤 3：运行命令`,
  [`Step 3: Run commands`]: `步骤 3：运行命令`,
  [`Step 4: Register`]: `步骤 4：注册`,
  [`Stop`]: `停止`,
  [`Stop after:`]: `此后停止：`,
  [`Stop all the retries`]: `停止所有重试`,
  [`Stop Connection`]: `停止连接`,
  [`Stop on error`]: `出错时停止`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `停止运行此 API，然后将其从沙箱目录中除去。`,
  [`Stopped`]: `已停止`,
  [`Stopping Published API`]: `正在停止发布的 API`,
  [`Strict`]: `严格`,
  [`Strict SOAP envelope version`]: `严格 SOAP 包络版本`,
  [`String Badgerfish`]: `字符串 Badgerfish`,
  [`String`]: `字符串`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `API 调用的 URL 的组织段中包含的字符串。使用小写字母、数字和连字符。`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Stripe 集成`,
  [`Streaming rule`]: `流规则`,
  [`Subject`]: `主题`,
  [`Subject Claim`]: `主体声明`,
  [`Subject Origin`]: `主题源`,
  [`Subject common name`]: `主题通用名称`,
  [`Subject|certificate`]: `主题`,
  [`Submit`]: `提交`,
  [`Submitted`]: `已提交`,
  [`Subscribability`]: `可预订性`,
  [`Subscribability must be disabled if visibility is disabled`]: `如果禁用可视性，那么必须禁用可预订性`,
  [`Subscribability options`]: `可预订性选项`,
  [`Subscribable by`]: `可预订者`,
  [`Subscribe`]: `预订`,
  [`Subscribe Financial Management App`]: `预订财务管理应用程序`,
  [`Subscribe TODO App`]: `预订 TODO 应用程序`,
  [`Subscription`]: `预订`,
  [`Subscription approval task for subscription of`]: `预订审批任务，用于预订`,
  [`Subscription created`]: `已创建预订`,
  [`Subscription has been added`]: `已添加预订`,
  [`Subscription has not been added`]: `尚未添加预订`,
  [`Subscription pending approval`]: `预订等待核准`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `由于不支持 GraphQL 预订，因此移除了预订类型。`,
  [`Subscription-Approval`]: `预订-核准`,
  [`Subscriptions`]: `预订`,
  [`Success`]: `成功`,
  [`Suffix`]: `后缀`,
  [`Suggestion`]: `建议`,
  [`Supersede`]: `取代`,
  [`Supersede Product`]: `取代产品`,
  [`Supersede product`]: `取代产品`,
  [`Supersede plans`]: `取代计划`,
  [`Supersede {product1} with {product2}`]: `用 {product2} 取代 {product1}`,
  [`Supersede|permission`]: `取代`,
  [`Supersede|supersede with`]: `取代`,
  [`Support`]: `支持`,
  [`support default introspection`]: `支持缺省自省`,
  [`Support Server Name Indication (SNI)`]: `支持服务器名称指示 (SNI)`,
  [`Support hybrid response types`]: `支持混合响应类型`,
  [`Support standard introspection`]: `支持标准自省`,
  [`Supported OAuth components`]: `受支持的 OAuth 组件`,
  [`Supported client types`]: `受支持的客户机类型`,
  [`Supported client types can not be empty.`]: `受支持的客户机类型不能为空。`,
  [`Supported grant type can not be empty.`]: `受支持的授权类型不能为空。`,
  [`Supported grant types`]: `受支持的授权类型`,
  [`Suspend`]: `暂挂`,
  [`Suspend API`]: `暂挂 API`,
  [`Suspend Application`]: `暂挂应用程序`,
  [`Switch`]: `切换`,
  [`Switch account`]: `切换帐户`,
  [`Switch cloud connection`]: `切换云连接`,
  [`Switch directory`]: `切换目录`,
  [`TARGET`]: `目标`,
  [`Title`]: `标题`,
  [`TlS`]: `TlS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS 客户机`,
  [`TLS Client Profile`]: `TLS 客户机概要文件`,
  [`TLS Client Profile has been configured.`]: `已配置 TLS 客户机概要文件。`,
  [`TLS client profile is required when endpoint starts with https://`]: `当端点以 https:// 开头时，需要 TLS 客户机概要文件`,
  [`TLS Client Profiles`]: `TLS 客户机概要文件`,
  [`TLS client profile has been configured.`]: `已配置 TLS 客户机概要文件。`,
  [`TLS client profiles`]: `TLS 客户机概要文件`,
  [`TLS Profile`]: `TLS 概要文件`,
  [`TLS SERVER PROFILE`]: `TLS 服务器概要文件`,
  [`TLS server profile`]: `TLS 服务器概要文件`,
  [`TLS version 1.0`]: `TLS V1.0`,
  [`TLS version 1.1`]: `TLS V1.1`,
  [`TLS version 1.2`]: `TLS V1.2`,
  [`TLS version 1.3`]: `TLS V1.3`,
  [`TLS client profile`]: `TLS 客户机概要文件`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `启用空间后，会在每个空间中管理 TLS 客户机概要文件。请转至“空间设置”以管理 TLS 客户机概要文件。`,
  [`TLS profile`]: `TLS 概要文件`,
  [`TLS profile is not visible or not available.`]: `TLS 概要文件不可见或不可用。`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `用于将数据安全传输到认证 URL 的 TLS 概要文件。`,
  [`Tabs for code language options`]: `代码语言选项的选项卡`,
  [`Truststore`]: `信任库`,
  [`truststore`]: `信任库`,
  [`Type`]: `类型`,
  [`Type name`]: `类型名称`,
  [`Type/Field`]: `类型/字段`,
  [`Type weight`]: `类型权重`,
  [`Types per page:`]: `每页的类型数：`,
  [`Tags`]: `标记`,
  [`Tags and external documentation`]: `标记和外部文档`,
  [`Take offline`]: `脱机`,
  [`Target`]: `目标`,
  [`Target API (URL)`]: `目标 API (URL)`,
  [`Target SOAP service`]: `目标 SOAP 服务`,
  [`Target Services`]: `目标服务`,
  [`Target service URL`]: `目标服务 URL`,
  [`Target services`]: `目标服务`,
  [`Target URL`]: `目标 URL`,
  [`Target application`]: `目标应用程序`,
  [`Target catalog`]: `目标目录`,
  [`Target gateway service`]: `目标网关服务`,
  [`Target gateway services`]: `目标网关服务`,
  [`Target plan`]: `目标计划`,
  [`Target product`]: `目标产品`,
  [`Target product rate limit`]: `目标产品速率限制`,
  [`Target product subscribability`]: `目标产品可预订性`,
  [`Target product visibility`]: `目标产品可视性`,
  [`Target schema`]: `目标模式`,
  [`Target services have been updated.`]: `已更新目标服务。`,
  [`Task`]: `任务`,
  [`Task has been updated`]: `已更新任务`,
  [`Task self approval`]: `任务自核准`,
  [`Task self-approval`]: `任务自核准`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `任务`,
  [`Tasks for creating new provider organizations`]: `用于创建新提供者组织的任务`,
  [`TCP`]: `TCP`,
  [`Template`]: `模板`,
  [`Template: {title}`]: `模板：{title}`,
  [`Template: account approved`]: `模板：帐户被批准`,
  [`Template: account denied`]: `模板：帐户被拒绝`,
  [`Template: account pending approval`]: `模板：帐户暂挂批准`,
  [`Template: app lifecycle approved`]: `模板：应用程序生命周期被批准`,
  [`Template: app lifecycle denied`]: `模板：应用程序生命周期被拒绝`,
  [`Template: app lifecycle pending`]: `模板：应用程序生命周期暂挂`,
  [`Template: app reinstated`]: `模板：应用程序已恢复`,
  [`Template: app suspended`]: `模板：应用程序已暂挂`,
  [`Template: app lifecycle cancelled`]: `模板：应用程序生命周期已取消`,
  [`Template: app lifecycle request`]: `模板：应用程序生命周期请求`,
  [`Template: invitation`]: `模板：邀请`,
  [`Template: mail server test connection`]: `模板：邮件服务器测试连接`,
  [`Template: member invitation`]: `模板：成员邀请`,
  [`Template: password changed`]: `模板：密码已更改`,
  [`Template: password reset`]: `模板：密码重置`,
  [`Template: sign up`]: `模板：注册`,
  [`Template: task consumer onboard request`]: `模板：任务使用者上线请求`,
  [`Template: task product lifecycle approved`]: `模板：任务产品生命周期已批准`,
  [`Template: task product lifecycle cancelled`]: `模板：任务产品生命周期已取消`,
  [`Template: task product lifecycle denied`]: `模板：任务产品生命周期被拒绝`,
  [`Template: task product lifecycle pending`]: `模板：任务产品生命周期暂挂`,
  [`Template: task product lifecycle request`]: `模板：任务产品生命周期请求`,
  [`Template: task subscription cancelled`]: `模板：任务预订已取消`,
  [`Template: task subscription request`]: `模板：任务预订请求`,
  [`Template: task subscription approved`]: `模板：任务预订被批准`,
  [`Template: task subscription denied`]: `模板：任务预订被拒绝`,
  [`Template: task subscription pending`]: `模板：任务预订暂挂`,
  [`Temporarily disable subscribability`]: `暂时禁用可预订性`,
  [`Temporarily disable visibility`]: `暂时禁用可视性`,
  [`Terms of service`]: `服务条款`,
  [`Test`]: `测试`,
  [`Test API`]: `测试 API`,
  [`Test APIs`]: `测试 API`,
  [`Test Bind & Get Base DN`]: `测试绑定并获取基本 DN`,
  [`Test configuration`]: `测试配置`,
  [`Test connection`]: `测试连接`,
  [`Test connection failed.`]: `测试连接发生故障。`,
  [`Test and production endpoints`]: `测试和生产端点`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `测试并监视响应错误：redirect_uri 属性是必需的，但收到的是 {redirectUri}`,
  [`Test application`]: `测试应用程序`,
  [`Test consumer org`]: `测试使用者组织`,
  [`Test email`]: `测试电子邮件`,
  [`Test endpoints`]: `测试端点`,
  [`Test message from IBM API Connect`]: `测试来自 IBM API Connect 的消息`,
  [`Testable`]: `可测试`,
  [`TEXT`]: `文本`,
  [`The "Definition" property must have a valid value.`]: `“Definition”属性必须具有有效值。`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `“Definition”属性必须具有值“request”或“response”，或者以“#/definitions/”开头。`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `API Connect CLI 作为 IBM Cloud CLI 插件提供。要开始使用此产品，请执行下列操作：`,
  [`The API has unsaved changes.`]: `API 具有未保存的更改。`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `该 API 已完全设计并通过内部流程，但尚未实施。`,
  [`The API is in the implementation phase.`]: `该 API 处于实施阶段。`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `该 API 处于早期概念阶段，且既未完全设计，也未实施。`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `API 可能具有未发布的更改。要重新发布产品吗？`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `DNS 方案可确定用于对网关服务进行入站 API 调用以及用于访问 Developer Portal 的端点 URL 的组成。`,
  [`The GatewayScript source code to execute.`]: `要执行的 GatewayScript 源代码。`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `必须用其验证 GraphQL 有效内容的 GraphQL 模式 URL。`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `要用于调用的 HTTP 方法。如果省略了此选项或将其设置为“保留”，那么将使用入局请求中的方法。`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `要用于替换当前 HTTP 方法的 HTTP 方法。如果省略了此选项或将其设置为“保留”，那么将使用入局请求中的方法。`,
  [`The JavaScript source code to execute.`]: `要执行的 JavaScript 源代码。`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `轻量级目录访问协议 (LDAP) 是一种因特网协议，用于通过网络访问和维护分布式目录信息服务。如果启用了企业 LDAP 服务，请将其配置为用于提供用户认证的资源。`,
  [`The Proxy URL to be used.`]: `要使用的代理 URL。`,
  [`The SSL Profile to use for the secure transmission of data.`]: `要用于安全数据传输的 SSL 概要文件。`,
  [`The TLS Profile to use for the secure transmission of data.`]: `要用于安全数据传输的 TLS 概要文件。`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `用于验证 JSON 有效内容的 JSON 模式的 URL。`,
  [`The URL of the XML schema for validating an XML payload.`]: `用于验证 XML 有效内容的 XML 模式的 URL。`,
  [`The URL to be invoked.`]: `要调用的 URL。`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `必须用其验证 SOAP 有效内容的 WSDL 模式 URL。`,
  [`The XML element name to be used for JSON array elements.`]: `要用于 JSON 数组元素的 XML 元素名称。`,
  [`The XSLT source to execute.`]: `要执行的 XSLT 源。`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `可将激活链接发送给用户以使其能够激活帐户。`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `分析服务从网关服务收集 API 事件。每个网关都有一个关联的分析服务。每当调用 API 操作时，都会记录带有时间戳记的 API 事件。`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `分析服务从网关服务收集 API 事件。每个网关服务都可以有一个关联的分析服务。`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `授权 URL 是客户机应用程序获得授权的位置。令牌 URL 是客户机应用程序交换访问令牌授权的位置。自省 URL 是 API Gateway 验证第三方提供者所发布的访问令牌的位置。`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `基本路径是 API 的起始 URL 段，不包含主机名或者路径/操作的任何其他段`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `高速缓存类型将确定在高速缓存文档时是支持还是重写从服务器响应中收到的 HTTP 高速缓存控制伪指令。此属性仅在从服务器收到响应时有效，否则，策略始终返回高速缓存中先前保存的未到期响应。`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `证书管理器服务必须管理网关管理端点的域的证书以及网关所处理的域。此外，您还必须使用 IBM Cloud IAM 授权 API Connect 保留实例服务访问证书管理器服务。`,
  [`The certificate with which to decrypt the buffer`]: `用于对缓冲区进行解密的证书`,
  [`The certificate with which to encrypt the buffer`]: `用于对缓冲区进行加密的证书`,
  [`The change is critical`]: `此更改至关重要`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `客户机应用程序只能请求您在此处定义的作用域或其子集。作用域包含在从提供者生成的访问令牌中。在调用受 OAuth 保护的 API 时，网关会根据安全性定义中允许的作用域列表来检查访问令牌中携带的作用域，以确定是否授予访问权。此外，您可以执行高级作用域检查。根据所配置的 URL，在应用程序认证后或在用户认证后调用高级作用域检查 URL。访问令牌授予的最终作用域许可权是所有作用域检查的结果。`,
  [`The crypto object to use to decode the claim.`]: `要用于解码声明的加密对象。`,
  [`The crypto object to use to encrypt the message.`]: `要用于加密消息的加密对象。`,
  [`The crypto object to use to sign the JWT.`]: `要用于签名 JWT 的加密对象。`,
  [`The crypto object to use to verify the signature.`]: `要用于验证签名的加密对象。`,
  [`The current mapping contains references to unrecognized schema elements.`]: `当前映射包含对无法识别的模式元素的引用。`,
  [`The current organization does not contain any catalogs.`]: `当前组织不包含任何目录。`,
  [`The default User registry must be included in the list`]: `缺省用户注册表必须包含在列表中`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `必须依据其验证有效内容的定义（“#/definitions/XXXX”）`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Developer Portal 提供了已发布的 API 产品的使用者视图。在针对目录配置了 Developer Portal 并发布了一个或多个 API 产品后，应用程序开发人员可以浏览并使用这些 API。`,
  [`The document is missing draft_api.info.version field.`]: `文档缺少 draft_api.info.version 字段。`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `文档缺少 draft_api.info.x-ibm-name 字段。`,
  [`The document is missing draft_product.info.name field.`]: `文档缺少 draft_product.info.name 字段。`,
  [`The document is missing draft_product.info.version field.`]: `文档缺少 draft_product.info.version 字段。`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `额外约束指定了一个切割自变量，且该自变量不是此字段上的自变量。`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `此字段具有一个带缺省值的限制自变量。因此，给出的设定大小永远不会适用。`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `此字段具有一个带缺省值的限制自变量，或者它是必需的。因此，给出的设定大小永远不会适用。`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `此字段没有设定的大小，也没有任何切割自变量。`,
  [`This field returns unbound list of values with composite type.`]: `此字段返回组合类型的值的未绑定列表。`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `该字段返回类型为标量且字型磅值不为 0.0 的值的未绑定列表。`,
  [`This test panel is deprecated and will be removed soon.`]: `此测试面板已弃用并且很快将移除。`,
  [`This type has no fields or no matching field.`]: `此类型没有任何字段，或者没有任何匹配字段。`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `此 GatewayScript 策略不应使用 apim 模块，该模块仅用于迁移旧 API。`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `此 XSLT 策略不应使用 apim 模块，该模块仅用于迁移旧 API。`,
  [`This type will be deleted.`]: `将删除此类型。`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `下图显示了 API 的组合件流。要修改组合件，请使用组合件编辑器。`,
  [`The following files are available under the`]: `以下文件位于：`,
  [`The following files contain a private key and cannot be uploaded:`]: `以下文件包含专用密钥且无法上载：`,
  [`The following is available under the`]: `以下内容位于：`,
  [`The following lifecycle actions require approval`]: `以下生命周期操作需要核准`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `以下列表显示已在此提供者组织中创建的草稿 API`,
  [`The following list displays the draft products that have been created in this provider organization`]: `以下列表显示已在此提供者组织中创建的草稿产品`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `以下列表显示了已添加为此目录成员的所有用户及其获得的角色`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `以下列表显示了已添加为此空间成员的所有用户及其获得的角色`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `以下参数与 OAuth 提供者中配置的值不匹配`,
  [`The following roles are available in the Cloud Manager`]: `在 Cloud Manager 中提供了以下角色`,
  [`The following types/fields will also be removed:`]: `另外还会移除以下类型/字段：`,
  [`The following types/fields will be affected:`]: `将影响以下类型/字段：`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `不允许使用网关属性值 {gatewayType}。允许的值为 datapower-gateway、datapower-api-gateway`,
  [`The host used to invoke the API`]: `用于调用 API 的主机`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `密钥库包含匹配的公用证书和专用密钥对，用于确认身份以及加密/解密通过 HTTPS 完成的数据传输。`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `密钥库包含匹配的公用证书和专用密钥对，用于确认身份以及加密/解密通过 HTTPS 完成的数据传输。服务器概要文件需要密钥库。`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `密钥库包含服务器或客户机为确认其身份而提供的公用密钥和专用证书。`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `添加到当前日期和时间上的时间长度（以秒为单位），LTPA 密钥在这段时间内被视为有效。`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `加到当前日期和时间上的时间长度（以秒为单位），JWT 在这段时间内视为有效。`,
  [`The message to be sent with the error`]: `要随错误一起发送的消息`,
  [`The message to be included within exception.`]: `要包含在异常中的消息。`,
  [`The HTTP status code to be included within exception.`]: `要包含在异常中的 HTTP 状态码。`,
  [`The HTTP status reason phrase to be included within exception.`]: `要包含在异常中的 HTTP 状态原因短语。`,
  [`The name of a burst limit defined in the datapower configuration.`]: `DataPower 配置中定义的突发流量限制的名称。`,
  [`The name of a count limit defined in the datapower configuration.`]: `DataPower 配置中定义的计数限制的名称。`,
  [`The name of a rate limit defined in the datapower configuration.`]: `DataPower 配置中定义的速率限制的名称。`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `要用于存储请求的响应数据的变量的名称。然后，可以在其他操作（例如“映射”）中引用此名称。`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `将用于存储结果的变量的名称。缺省情况下，将使用“message”。`,
  [`The name of parameter to find the ID.`]: `用于查找标识的参数的名称。`,
  [`The name of parameter to find the secret.`]: `用于查找私钥的参数的名称。`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `要用于生成 LTPA 令牌的 LTPA 密钥的名称。如果要使用特定的密钥版本，请指定 <keyname>:<version>。例如，my-ltpa-key:1.0.1。如果希望策略自动选择使用最新的密钥版本，请指定 <keyname>:latest。例如，my-ltpa-key:latest。`,
  [`The name of the error to be thrown`]: `要抛出的错误的名称`,
  [`The name of the variable to be added.`]: `要添加的变量的名称。`,
  [`The name of the variable to be cleared.`]: `要清除的变量的名称。`,
  [`The name of the variable to be set.`]: `要设置的变量的名称。`,
  [`The nature of the XML validation to be performed.`]: `要执行的 XML 验证的性质。`,
  [`The operation to apply.`]: `要应用的操作`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `此映射的输出位于数组中，并通过迭代所选输入来创建其内容。如果所选输入不是数组，那么会生成长度为 1 的数组。`,
  [`The owner must be an existing user in your user registry`]: `所有者必须是用户注册表中的现有用户`,
  [`The owner of consumer organization has the following privileges:`]: `使用者组织的所有者具有以下特权：`,
  [`The owner of provider organization has the following privileges:`]: `提供者组织的所有者具有以下特权：`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `所有者的职责包括：管理 API 产品生命周期、管理一个或多个 API 提供者组织、管理应用程序提供者社区，以及编写 API 和产品定义。`,
  [`The page you are looking for might have been moved or does not exist.`]: `您正在寻找的页面可能已迁移或者不存在。`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `您正在寻找的页面可能已迁移或者不存在。请与管理员联系以获取支持。`,
  [`The parameters needed to send a valid API call.`]: `发送有效 API 调用所需的参数。`,
  [`The password to use for HTTP Basic authentication.`]: `用于 HTTP 基本认证的密码。`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `门户网站服务提供一个 Developer Portal，供应用程序开发人员用来发现 API 和引导使用者。`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `门户网站服务提供一个 Developer Portal，应用程序开发人员使用该门户网站来发现和实施 API。`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `处理状态提供了网关运行状况的视图以及最近活动的摘要。`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `收件人将注册为所有者，并创建新的提供者组织。一旦注册，用户就可以作为提供者组织所有者登录到 API Manager。`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `生成的 XML 文档的根元素名称。仅在输入 JSON 文档不分层并且具有多个顶级属性时，这才是必需项。`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `开发人员可使用此处的作用域来了解允许访问的内容，这些作用域不适用于作用域检查。`,
  [`The selected catalog will only be used to search consumer organizations`]: `选中的目录将只用于搜索使用者组织`,
  [`The selected catalog does not have any configured gateways`]: `所选目录不包含任何已配置网关`,
  [`The selected catalog does not have any compatible gateways`]: `所选目录不包含任何兼容网关`,
  [`Save Preferences`]: `保存首选项`,
  [`Preferences`]: `首选项`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `选中的目录将只用于搜索使用者组织和组`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `选定的网关类型将使组合件中的以下策略呈现为无效。您需要先删除这些策略，然后才能运行此 API。`,
  [`The selected product does not contain this API`]: `所选产品不包含此 API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `所选产品可预订特定使用者组织或组的列表。`,
  [`The selected services will be available to a specific list of provider organizations.`]: `所选服务将可供一系列特定提供者组织使用。`,
  [`The selected services will be available to all existing and new provider organizations.`]: `所选服务将可供所有现有和新的提供者组织使用。`,
  [`The selected services will not be available to any provider organizations.`]: `所选服务将不可供任何提供者组织使用。`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `要用作高速缓存键的字符串。如果省略了此选项，那么整个 URL 字符串将用作键。`,
  [`The server responded with a non-JSON body \n{body}`]: `通过非 JSON 主体 \n{body} 响应了服务器`,
  [`The time interval needs to be higher than the access token time to live.`]: `时间间隔需要长于访问令牌生存时间。`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `不会在 cookie 或本地存储器中存储令牌。如果关闭测试面板，那么令牌将丢失，但是可能仍有效。`,
  [`The transfer protocol of the API.`]: `API 的传输协议。`,
  [`The type of authentication.`]: `认证的类型。`,
  [`The type of authorization header.`]: `授权头的类型。`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `要设置的值类型。这可以是任意字符、字符串、数字或布尔值。`,
  [`The type of the value to set. This can be string, number or boolean.`]: `要设置的值类型。这可以是字符串、数字或布尔值。`,
  [`The username to use for HTTP Basic authentication.`]: `用于 HTTP 基本认证的用户名。`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `此连接的用户已不再有效。 请创建新连接。`,
  [`The value that the variable will be set to.`]: `要向该变量赋予的值。`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `此字段的值为组合类型的列表，它没有设定的大小，也没有任何切割自变量。如果遇到此字段，那么 API Gateway 在收到查询后无法对 GraphQL 服务器可能返回的对象数设定限制。`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `此字段的值为标量类型的列表，它没有设定的大小，也没有任何切割自变量。如果遇到此字段，那么 API Gateway 在收到查询后无法对 GraphQL 服务器可能返回的对象数设定限制。`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `版本需要是安全 URL。对象的版本 {version} 和 slug 化版本 {slugVersion} 不匹配。`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `"name" 属性必须是 URL 安全的（对象名称值 "{name}" 和缩略名称值 "{slugName}" 必须匹配）。`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `“draft_api”属性必须包含 OpenAPI 定义。OpenAPI V3 仅支持 V3.0.0 - V3.0.3（指定的版本 {version}）`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `名称字段根据提供的标题自动进行填充。请使用 API Connect 命令和 API 调用所产生的值。`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `可视性设置可确定哪些提供者组织可以访问资源。选项包括：公共（资源可以由所有提供者组织使用）、定制（资源只能由您指定的提供者组织使用）以及专用（资源不可见，且不能由任何提供者组织使用）。`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `可视性设置确定哪些提供者组织可以使用服务。选项包括：公共（服务可以由所有提供者组织使用）、定制（服务只能由您指定的提供者组织使用）以及专用（服务不可见，且不能由任何提供者组织使用）。`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `可视性设置确定哪些提供者组织可以使用网关。可视性级别包括：公共（网关可由所有提供者组织使用）、定制（网关只能由您指定的提供者组织使用）和专用（网关不可视且无法由任何提供者组织使用）。`,
  [`The {title} role`]: `{title} 角色`,
  [`The {url} resource does not exist`]: `{url} 资源不存在`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `由于 API 的网关类型与该产品的网关类型不同，因此存在不兼容的 API。`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `然后，选择管理端点的域的证书。此外，如果证书并非由著名的认证中心分配，还需选择对应的 CA 捆绑包。证书和捆绑包必须由证书管理器服务管理。`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `然后，指定将由此网关处理的域。我们使用 SNI 方法；因此，可通过同一地址和端口为多个域提供服务，而无需相同证书。支持通配符格式。“*”的缺省 (catch-all) 域必须作为表中的最后一个条目。根据需要输入其他域名，在任何情况下都要指定由证书管理器服务管理的证书。`,
  [`There should be at least {minSelected} {label} selected.`]: `应至少选择 {minSelected} 个{label}。`,
  [`There are no API changes to publish.`]: `没有任何要发布的 API 更改。`,
  [`There are no gateway services`]: `不存在网关服务`,
  [`There are no assemblies that match the chosen filters.`]: `没有与所选过滤器相匹配的组合件。`,
  [`There are no burst limits yet.`]: `尚不存在任何突发流量限制。`,
  [`There are no catalogs`]: `不存在目录`,
  [`There are no consumer organizations.`]: `不存在使用者组织。`,
  [`There are no consumer organizations. You can create one`]: `不存在使用者组织。您可以创建一个`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `不存在使用者组织。您可以在[此处]({link}) 创建一个分析服务`,
  [`There are no email servers`]: `不存在电子邮件服务器`,
  [`There are no gateway services configured for the sandbox catalog`]: `未针对沙箱目录配置任何网关服务`,
  [`There are no lifecycle approvals enabled`]: `未启用任何生命周期核准`,
  [`There are no new changes.`]: `没有新更改。`,
  [`There are no products to display`]: `没有可显示的产品`,
  [`There are no products to replace`]: `没有可替换的产品`,
  [`There are no products to set migration target`]: `没有可设置迁移目标的产品`,
  [`There are no products to supersede`]: `没有要取代的产品`,
  [`There are no properties to configure for this policy`]: `此策略没有要配置的属性`,
  [`There are no rate limits yet.`]: `尚不存在任何速率限制。`,
  [`There are no security definitions`]: `不存在安全性定义`,
  [`There are no spaces`]: `不存在空间`,
  [`There are no target services`]: `不存在目标服务`,
  [`There are no tasks to be displayed`]: `没有可显示的任务`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `存在未保存的更改。确定要继续？`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `存在未保存的更改。如果继续，将废弃这些更改。确定要这样吗？`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `您的 YAML 中有一个不存在且无法列出的 API，请选中“源”选项卡。`,
  [`There is no item in the list.`]: `此列表中没有任何项。`,
  [`There is no space`]: `无空间`,
  [`There is no warning in the schema.`]: `模式中无警告。`,
  [`There was no data found to populate the data table.`]: `未找到任何数据来填充数据表。`,
  [`These endpoints are used for all the APIs in the catalog`]: `这些端点用于目录中的所有 API`,
  [`Third party OAuth provider`]: `第三方 OAuth 提供者`,
  [`Third party OAuth provider summary`]: `第三方 OAuth 提供者摘要`,
  [`Third party`]: `第三方`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `此 API 尚未包含组合件。要创建一个吗？`,
  [`This resource no longer exists, please refresh the results from the server.`]: `此资源不再存在，请进行刷新以显示来自服务器的结果。`,
  [`Please refresh the results from the server`]: `请进行刷新以显示来自服务器的结果`,
  [`Exact match`]: `完全匹配项`,
  [`Closest match`]: `最接近的匹配项`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `此 API 目前正由产品 {productName} 使用。请将其从产品中删除，然后继续删除 API。`,
  [`This API will be available to be invoked when the following option is enabled.`]: `如果已启用以下选项，那么此 API 将可供调用。`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `此目录未启用自动预订。要在此目录中测试，请在下面选择产品、计划和应用程序。`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `此端点用作未定义基本端点的 API 的缺省值`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `这是一项技术预览功能，将来会不断改进。`,
  [`This is the currently configured notification server.`]: `这是当前配置的通知服务器。`,
  [`This is the directory where your files including policies and extensions will be stored`]: `这是用于存储包含策略和扩展的文件的目录`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `API 在其中运行，并可以在其中访问策略和扩展`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `缺省情况下，此 OAuth 策略执行已发布路径所需的所有 OAuth 协议步骤。每个步骤的输入和输出由记录的上下文变量驱动。请根据需要添加或移除受支持的 OAuth 组件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `缺省情况下，此 OAuth 策略执行 OAuth 验证所需的所有 OAuth/OpenID Connect 协议步骤。每个步骤的输入和输出由记录的上下文变量驱动。请根据需要添加或移除受支持的 OAuth 组件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `缺省情况下，此 OAuth 策略执行所有其他路径所需的全部 OAuth/OpenID Connect 协议步骤。每个步骤的输入和输出由记录的上下文变量驱动。请根据需要添加或移除受支持的 OAuth 组件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `缺省情况下，此 OAuth 策略执行 az 代码路径所需的所有 OAuth/OpenID Connect 协议步骤。每个步骤的输入和输出由记录的上下文变量驱动。请根据需要添加或移除受支持的 OAuth 组件。`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `缺省情况下，此 OAuth 策略执行令牌路径所需的所有 OAuth/OpenID Connect 协议步骤。每个步骤的输入和输出由记录的上下文变量驱动。请根据需要添加或移除受支持的 OAuth 组件。`,
  [`This operation is secured with access code flow OAuth`]: `将使用访问代码流 OAuth 保护此操作`,
  [`This operation is secured with application flow OAuth`]: `将使用应用程序流 OAuth 保护此操作`,
  [`This operation is secured with basic authentication`]: `将使用基本认证保护此操作`,
  [`This operation is secured with implicit flow OAuth`]: `将使用隐式流 OAuth 保护此操作`,
  [`This operation is secured with password flow OAuth`]: `将使用密码流 OAuth 保护此操作`,
  [`This policy is not available on the selected gateway`]: `此策略在所选网关上不可用`,
  [`This policy is used by one other operation`]: `另一个操作使用此策略`,
  [`This policy is used by {{count}} other operations`]: `此策略由另外 {{count}} 项操作使用`,
  [`This role cannot be edited`]: `无法编辑此角色`,
  [`This role cannot be edited.`]: `此角色无法编辑。`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `此设置控制由于映射输入不存在且没有配置缺省映射而导致映射失败时的行为。缺省行为是不更改输出，但如果选中此复选框，将为目标映射的父项创建一个空对象，以模拟版本 4 的行为。`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `此用户安全策略针对 OAuth 组合件执行 EI(basic) 和 AU(auth url) 检查。请根据需要更改安全检查方法`,
  [`This will enable/disable application lifecycle`]: `这将启用/禁用应用程序生命周期`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `这将启用/禁用任务自核准。 任务自核准允许任务由其发起方以及合作者核准。 在其他核准者不可用时，此选项非常方便，但允许有效绕过其他用户的检查。`,
  [`This will unset the catalog as the default catalog.`]: `这会取消将目录设置为缺省目录。`,
  [`Throw`]: `抛出`,
  [`Time`]: `时间`,
  [`Time Interval`]: `时间间隔`,
  [`Time Limit`]: `时间限制`,
  [`Time to Live`]: `生存时间`,
  [`Time to live`]: `生存时间`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `从端点回复前等待的时间。缺省值为 60 秒。`,
  [`Timeout`]: `超时`,
  [`To`]: `收件人`,
  [`to`]: `收件人`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `要将用户添加为管理员组织的成员，请选择其用户注册表，输入其用户名，然后选择要分配的角色。如果用户注册表类型是“本地用户注册表”，那么还可以选择创建新用户，然后将该用户添加为成员。成员资格将立即启用，指定的用户可以登录至 Cloud Manager 用户界面`,
  [`To add a user as a member of a provider organization`]: `要将用户添加为提供者组织的成员`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `要将用户添加为目录的成员，请选择其用户名，然后为其分配所需的角色。您可以添加已经是提供者组织中另一目录或空间的成员，且目前既不是该目录的成员也不是目录所有者的任何用户。`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `要将用户添加为空间的成员，请选择其用户名，然后为其分配所需的角色。您可以添加已经是提供者组织中另一目录或空间的成员，且目前既不是该空间的成员也不是空间所有者的任何用户。`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `要添加或移除成员，请使用 [身份和访问](/iam) 服务。`,
  [`To add members, use the [identity and access](/iam) service.`]: `要添加成员，请使用[身份和访问](/iam)服务。`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `要将用户添加为组织成员，请选择其用户注册表，输入其用户名，然后分配一组角色。`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `要将用户添加为成员，请选择其用户名，然后为其分配一组角色。`,
  [`To assign default gateways, click **Edit**.`]: `要分配缺省网关，请单击**编辑**。`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `要继续使用此软件，您必须同意软件许可协议的条款。`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `要继续使用 API Connect，请升级到企业计划。`,
  [`To get started, you must first enable spaces.`]: `要开始使用空间，必须先启用空间。`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `要安装和运行该工具箱，请完成以下步骤。[了解更多信息]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `要邀请新用户成为管理员组织的成员，请输入该用户的电子邮件地址或姓名，或者从用户注册表中选择其姓名。所有成员都自动分配有“成员”角色，该角色提供基本许可权设置，且无法删除。如果不需要其他许可权，可取消选中其他角色。`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `要邀请尚不在用户注册表中的所有者，请输入其电子邮件地址`,
  [`To register a new gateway, visit the **Gateways** page.`]: `要注册新网关，请访问**网关**页面。`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `要将值替换为以下空间属性之一，请输入 $()，其中括号内为属性名称`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `要向新用户发送电子邮件邀请以注册为管理员组织的成员，请输入有效的电子邮件地址，然后选择要分配给用户的角色。这会向该电子邮件地址发送一封包含激活链接的电子邮件，邀请该人员注册。该用户处于“暂挂”状态，当该用户完成注册表单以激活自己的帐户后即会更改为“已启用”状态。然后，他们可以使用在帐户激活期间指定的 API Connect 用户名登录至 Cloud Manager 用户界面。`,
  [`Toggle`]: `切换`,
  [`Toggle application lifecycle?`]: `切换应用程序生命周期？`,
  [`Toggle default catalog?`]: `切换缺省目录？`,
  [`Toggle info panel between right and bottom`]: `切换右侧和底部的信息面板`,
  [`Toggle profile`]: `切换概要文件`,
  [`Toggle spaces?`]: `是否切换空间？`,
  [`Toggle task self-approval?`]: `切换任务自审批？`,
  [`Token`]: `令牌`,
  [`Token endpoint`]: `令牌端点`,
  [`Token Expiry`]: `令牌到期`,
  [`Token Management`]: `令牌管理`,
  [`Token Output`]: `令牌输出`,
  [`Token URL`]: `令牌 URL`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `令牌 URL 必须与 OAuth 提供者“{providerTitle}”定义的 {endpoint} 匹配`,
  [`Token Version`]: `令牌版本`,
  [`Token management`]: `令牌管理`,
  [`Token management type`]: `令牌管理类型`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `通过令牌管理，您可以阻止重放攻击并管理令牌（包括客户机应用程序或资源所有者能否撤销 OAuth 令牌以及如何撤销）。API Connect 支持通过本机网关或第三方端点进行令牌管理。如果是本机网关，那么将使用配额实施来管理令牌。如果是第三方端点，那么将提供外部服务 URL 来管理令牌。`,
  [`Token path`]: `令牌路径`,
  [`Token revocation`]: `令牌撤销`,
  [`Token secret`]: `令牌私钥`,
  [`Token validation`]: `令牌验证`,
  [`Tokens`]: `令牌`,
  [`Token relay`]: `令牌中继`,
  [`Toolkit credentials`]: `工具箱凭证`,
  [`Token Relay`]: `令牌中继`,
  [`Topology`]: `拓扑`,
  [`Topology Administrator`]: `拓扑管理员`,
  [`Total time`]: `总时间`,
  [`Transfer Ownership to a New User`]: `将所有权移交给新用户`,
  [`Transfer Ownership to an Existing User`]: `将所有权移交给现有用户`,
  [`Transfer ownership`]: `移交所有权`,
  [`Transfer ownership to`]: `将所有权移交给`,
  [`Transfer ownership to a new user`]: `将所有权移交给新用户`,
  [`Transfer ownership to an existing user`]: `将所有权移交给现有用户`,
  [`Transforms`]: `转换`,
  [`Truststore Certificates`]: `信任库证书`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `信任库证书是认证中心颁发的公用证书。`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `信任库中包含可信证书，而可信证书包含已经过验证的公用密钥。信任库中的证书通常是从第三方认证中心 (CA) 获取的。`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `信任库包含具有已验证的公用密钥的可信证书。信任库中的证书通常是从第三方认证中心 (CA) 获取的。`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `信任库存储可信证书，包括已验证的公用密钥。信任库中的证书通常是从第三方认证中心 (CA) 获取的。`,
  [`Try again or upload a schema`]: `请重试或者上载模式`,
  [`Try refreshing the page or contacting support.`]: `请尝试刷新页面或联系支持人员。`,
  [`Turkish`]: `土耳其语`,
  [`Tutorials`]: `教程`,
  [`Types`]: `类型`,
  [`Type of user`]: `用户类型`,
  [`Type or select a catalog`]: `输入或选择目录`,
  [`Type to add organizations`]: `输入以添加组织`,
  [`TypeError: Failed to fetch`]: `TypeError：访存失败`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `管理端点的 URL`,
  [`URLs for all operations in the API begin with this value.`]: `API 中所有操作的 URL 以此值开头。`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `用户定义`,
  [`Userinfo POST`]: `用户信息 POST`,
  [`USER MANAGED`]: `用户管理`,
  [`USERNAME`]: `用户名`,
  [`User can delete the keystore history.`]: `用户可以删除密钥库历史记录。`,
  [`Unable to parse API definition: {msg}`]: `无法解析 API 定义：{msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `无法继续，因为在移除类型和/或字段时发现错误。`,
  [`Unarchive`]: `取消归档`,
  [`Unassociate`]: `取消关联`,
  [`Unassociate analytics service`]: `取消关联分析服务`,
  [`Unauthorized`]: `未授权`,
  [`Unauthorized API Request`]: `未授权的 API 请求`,
  [`Unbound lists`]: `解除列表绑定`,
  [`Unit`]: `单位`,
  [`Union`]: `Union`,
  [`Union type`]: `Union 类型`,
  [`Unique email address`]: `唯一电子邮件地址`,
  [`Unlimited`]: `无限制`,
  [`Unnecessary assumed size`]: `设定的大小不必要`,
  [`Unnecessary slicing arguments`]: `切割自变量不必要`,
  [`Unrecognized Schema Element(s) Encountered`]: `遇到无法识别的模式元素`,
  [`Unsupported media type ({type})`]: `不受支持的介质类型 ({type})`,
  [`Untitled`]: `无标题`,
  [`Update`]: `更新`,
  [`Update gateway services`]: `更新网关服务`,
  [`Update assembly`]: `更新组合件`,
  [`Update group failed.`]: `更新组失败`,
  [`Update schema defaults giving preference to`]: `更新模式缺省值，优先考虑`,
  [`Update the sender details that are displayed on invitation emails`]: `更新在邀请电子邮件上显示的发件人详细信息`,
  [`Update the summary details for this role`]: `更新此角色的摘要详细信息`,
  [`Updating Draft API`]: `正在更新草稿 API`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `更新模式缺省值将覆盖您的更改。确定要继续？`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `不允许使用类型为 {type} 的 API 来更新类型为 {existingType} 的 API。`,
  [`Upload`]: `上载`,
  [`Upload a schema definition language file to replace your schema.`]: `上载模式定义语言文件以替换您的模式。`,
  [`Upload GraphQL schema`]: `上载 GraphQL 模式`,
  [`Upload local`]: `上载本地`,
  [`Upload policies`]: `上载策略`,
  [`Upload policy`]: `上载策略`,
  [`Upload schema`]: `上载模式`,
  [`Upload schema definition language (SDL) file`]: `上载模式定义语言 (SDL) 文件`,
  [`Upload truststore certificates`]: `上载信任库证书`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `采用 PEM 格式上载应用程序的 X509 证书。您只能上载一个 PEM 文件，但是可在单个文件中包含多个证书。`,
  [`Upload the X509 certificate for your application, in PEM format`]: `采用 PEM 格式上载应用程序的 X509 证书`,
  [`Upload your public certificate`]: `上载公用证书`,
  [`Uploaded and validated YAML file`]: `已上传并验证 YAML 文件`,
  [`Upload extension`]: `上载扩展`,
  [`Use all compatible gateway services`]: `使用所有兼容的网关服务`,
  [`Use Content Type`]: `使用内容类型`,
  [`Use DataPower API Gateway`]: `使用 DataPower API Gateway`,
  [`Use DataPower Gateway`]: `使用 DataPower Gateway`,
  [`Use Dynamic DNS`]: `使用动态 DNS`,
  [`Use JSONata to specify the property to redact or remove`]: `使用 JSONata 指定要编辑或移除的属性`,
  [`Use XPath to specify the property to redact or remove.`]: `使用 XPath 指定要编辑或移除的属性。`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `使用所选目录作为要在其中发布内容的缺省目录`,
  [`Use an existing JSON or YAML definition of the product`]: `使用产品的现有 JSON 或 YAML 定义`,
  [`Use an existing definition of a REST proxy`]: `使用 REST 代理的现有定义`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `使用 REST 代理或 SOAP API 的现有定义`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `使用 REST 代理、GraphQL 代理或 SOAP API 的现有定义`,
  [`Use an existing definition of a product`]: `使用产品的现有定义`,
  [`Use context current payload`]: `使用上下文当前有效内容`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `使用定制目录端点 URL（V5 兼容性）`,
  [`Use dynamic DNS`]: `使用动态 DNS`,
  [`Use endpoint from API`]: `从 API 使用端点`,
  [`Use gateway URLs`]: `使用网关 URL`,
  [`Use gateway service configured OAuth shared secret key`]: `使用网关服务配置的 OAuth 共享私钥`,
  [`Use group authentication`]: `使用组认证`,
  [`Use only first array element`]: `仅使用第一个数组元素`,
  [`Use static DNS`]: `使用静态 DNS`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `将门户网站用作外部 OIDC 提供者流量的端点`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `使用 API Explorer 测试和探索 API。Explorer 会显示针对项目目录中包含的所有 API 的操作、定义和文档。Explorer 会列出参数、模型实例数据和响应代码以及用于运行 API 的模板代码。`,
  [`Use the actions menu to manage and publish your APIs.`]: `使用“操作”菜单可管理和发布 API。`,
  [`Use the actions menu to manage and publish your products`]: `使用“操作”菜单可管理和发布产品`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `使用 activity-log 策略以配置在“分析”中存储的 API 活动的日志记录首选项。您指定的首选项将覆盖有关收集和存储 API 活动详细信息的缺省设置。`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `使用客户机安全策略抽取和认证客户机凭证。`,
  [`Use the default built-in Sandbox Catalog`]: `使用缺省内置沙箱目录`,
  [`Use the editor for manual input or upload a file below.`]: `使用编辑器以手动输入或上载以下文件。`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `使用编辑器以手动输入或上载以下文件。如果定义 XML/XSD 文件，那么只能在 XML 中编辑当前模式。`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `使用实施的扩展来指定 API Connect 网关是否用于实施 API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `使用 CLI 时，请使用以下管理端点 URL。`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `在 API Designer 中创建云连接时，请使用以下管理端点 URL。`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `gatewayscript 策略用于执行指定的 DataPower GatewayScript 程序。`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `graphql-introspect 策略用于自省 GraphQL 模式。`,
  [`Use the host value from Open API Definition`]: `使用来自 OpenAPI 定义的主机值`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `使用 if 策略以在满足某个条件时应用组合件的一部分。`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `javascript 策略用于执行指定的 JavaScript 程序。`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `“json-to-xml”策略用于将有效内容从 JSON 转换为 XML。`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `日志策略用于定制或覆盖 API 的缺省活动日志记录配置。`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `映射策略用于将转换应用于组合件流并指定变量之间的关系。`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `映射策略用于指定 API 流中变量之间的关系，并对这些变量应用转换。`,
  [`Use the oauth policy to generate OAuth tokens.`]: `使用 OAuth 策略生成 OAuth 令牌。`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `“operation-switch”策略用于将组合件的一部分应用于特定操作。`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `使用“parse”策略来将请求或消息解析为 XML 或 JSON，或者将二进制数据解析为二进制大对象 (BLOB)。`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `“set-variable”策略用于将运行时变量设置为字符串值，或者添加/清除运行时变量。`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `使用“throw”策略以在组合件流执行期间到达该策略时抛出错误。`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `使用用户安全策略抽取、认证和授权用户的身份。`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `将“validate”策略与 DataPower Gateway 结合使用，以针对 JSON 或 XML 模式验证组合件流中的有效内容。`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `“xml-to-json”策略用于将有效内容从 XML 转换为 JSON。`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `xslt 策略用于将 XSLT 转换应用于有效内容。`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `用于在上载时启用/禁用内容编码压缩。`,
  [`User`]: `用户`,
  [`User group prefix`]: `用户组前缀`,
  [`User group suffix`]: `用户组后缀`,
  [`User mapping`]: `用户映射`,
  [`User Registries`]: `用户注册表`,
  [`User registries`]: `用户注册表`,
  [`User Registry Name`]: `用户注册表名称`,
  [`User registry name`]: `用户注册表名称`,
  [`User Registry cannot be deleted`]: `无法删除用户注册表`,
  [`User Registry "{name}" has been deleted`]: `已删除用户注册表“{name}”`,
  [`User security`]: `用户安全性`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `启用空间后，会在每个空间中管理用户注册表。请转至“空间设置”以管理用户注册表。`,
  [`User registries defined for consumer onboarding`]: `针对使用者引导定义的用户注册表`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `针对使用者引导定义的用户注册表\n\nDeveloper Portal 中的每个帐户（包括同一站点上不同用户注册表中的帐户）必须具有唯一电子邮件地址，包括站点管理员帐户。`,
  [`User registry`]: `用户注册表`,
  [`User registry configuration has been updated.`]: `已更新用户注册表配置。`,
  [`User registry has been created`]: `已创建用户注册表`,
  [`User registry {title} deleted.`]: `用户注册表 {title} 已删除。`,
  [`User registry {title} has been created.`]: `用户注册表 {title} 已创建。`,
  [`User registry is not visible or not available.`]: `用户注册表不可见或不可用。`,
  [`User registry {title} has been updated.`]: `用户注册表 {title} 已更新。`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `仅当您选择的授权类型包括以下一项或多项时，用户安全设置才适用：“隐式”、“访问代码”和“资源所有者 - 密码”。`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `仅当您选择的授权类型包括以下一项或多项时，用户安全设置才适用：“隐式”、“访问代码”和“资源所有者 - 密码”。如果您在此页面上进行了任何更改，那么还应该在“配置”页面上至少选择其中一种授权类型。`,
  [`UserInfo endpoint`]: `用户信息端点`,
  [`UserInfo URL`]: `用户信息 URL`,
  [`Username`]: `用户名`,
  [`Username / Email`]: `用户名/电子邮件`,
  [`Username and password fields are required in order to test configuration`]: `需要用户名和密码字段才能测试配置`,
  [`Username context variable`]: `用户名上下文变量`,
  [`Username of the new user`]: `新用户的用户名`,
  [`Username should not contain special characters!`]: `用户名不应包含特殊字符！`,
  [`Username should not include spaces!`]: `用户名不应包含空格！`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `属于使用者组织的用户有权访问 Developer Portal 及其发布的 API 产品（由其可视性和可预订性来确定）。`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `使用 [IBM Cloud IAM]({link})，授权 API Connect 保留实例服务访问证书管理器服务。`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `在联系用户信息端点时使用 HTTP POST 方法`,
  [`Use IBM APIC token expiration setting from the cloud`]: `使用来自云的 IBM APIC 令牌过期设置`,
  [`Update API`]: `更新 API`,
  [`VALUE`]: `值`,
  [`Version`]: `版本`,
  [`Visible to`]: `可查看权限`,
  [`Validate`]: `验证`,
  [`Validate message body`]: `验证消息体`,
  [`Validate message headers`]: `验证消息头`,
  [`Validate message fault details`]: `验证消息故障详细信息`,
  [`Validate SOAP 1.1 encoding rule`]: `验证 SOAP 1.1 编码规则`,
  [`Validate JSON Web Token (JWT)`]: `验证 JSON Web 令牌 (JWT)`,
  [`Validate JWT`]: `验证 JWT`,
  [`Validate Username Token`]: `验证用户名令牌`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `使用认证 URL 或 LDAP 用户注册表验证 Web Service 安全用户名令牌有效内容。`,
  [`Validate against`]: `验证依据`,
  [`Validate request`]: `验证请求`,
  [`Validate the GraphQL query and analyze the expected cost`]: `验证 GraphQL 查询并分析预计开销`,
  [`Validate the returned JSON and analyze the real cost`]: `验证返回的 JSON 并分析实际开销`,
  [`Validating API ...`]: `正在验证 API...`,
  [`Validating Draft API`]: `正在验证草稿 API`,
  [`Validity Period`]: `有效期`,
  [`Value`]: `值`,
  [`Value for located in must be selected.`]: `必须选择位置的值。`,
  [`Value for the variable to be added.`]: `要添加的变量的值。`,
  [`Value for type must be selected.`]: `必须选择类型的值。`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `“@cost”伪指令中的“weight”自变量的值小于 0。`,
  [`Values`]: `值`,
  [`Vanity API endpoint`]: `虚拟 API 端点`,
  [`Variable name`]: `变量名称`,
  [`Variable value`]: `变量值`,
  [`Vendor Extensions`]: `供应商扩展`,
  [`Verb`]: `动词`,
  [`Verify`]: `验证`,
  [`Verify Access Token`]: `验证访问令牌`,
  [`Verify Crypto JWK variable name`]: `验证加密 JWK 变量名称`,
  [`Verify Crypto Object`]: `验证加密对象`,
  [`Verify Token`]: `验证令牌`,
  [`Verify authorization code`]: `验证授权代码`,
  [`Verify refresh token`]: `验证刷新令牌`,
  [`Version of the LTPA token.`]: `LTPA 令牌版本。`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `在导入的产品文件中，选中“套餐”下的“apis”属性。 每个套餐都应该具有“apis”属性，此属性指向“API”部分中的至少一个 API。`,
  [`View`]: `查看`,
  [`View extensions`]: `查看扩展`,
  [`View Forum`]: `查看论坛`,
  [`View owners`]: `查看所有者`,
  [`View policies`]: `查看策略`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `查看和管理可用于所选协议版本的密码。`,
  [`View analytics`]: `查看分析`,
  [`View and edit permissions for the roles in this space`]: `查看和编辑此空间中角色的许可权`,
  [`View and edit the permissions for the roles in the current organization`]: `查看和编辑当前组织中角色的许可权`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `查看和编辑使用者组织的角色缺省值中包含的角色`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `查看和编辑提供者组织的角色缺省值中包含的角色`,
  [`View approval history`]: `查看核准历史记录`,
  [`View Approval History`]: `查看审批历史记录`,
  [`View documentation and tutorials with step-by-step instructions.`]: `查看具有逐步指示信息的文档和教程。`,
  [`View error ID`]: `查看错误标识`,
  [`View status`]: `查看状态`,
  [`View more`]: `查看更多`,
  [`View source`]: `查看源`,
  [`View subscription`]: `查看预订`,
  [`View template in`]: `查看以下位置中的模板`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `查看用于访问 API Connect 用户界面的端点。`,
  [`View the endpoints for the admin and provider REST APIs.`]: `查看管理员和提供者 REST API 的端点。`,
  [`View the endpoints for the consumer REST APIs.`]: `查看使用者 REST API 的端点。`,
  [`Viewer`]: `查看者`,
  [`Views the API consumer organization`]: `查看 API 使用者组织`,
  [`Views the API provider organization`]: `查看 API 提供者组织`,
  [`Views the admin organization`]: `查看管理员组织`,
  [`Views the app developer organization`]: `查看应用程序开发人员组织`,
  [`Views the catalog`]: `查看目录`,
  [`Views the space`]: `查看空间`,
  [`View|permission`]: `查看`,
  [`Visibility`]: `可视性`,
  [`Visibility options`]: `可视性选项`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware 文档`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL URL`,
  [`WSDL has been successfully validated`]: `已成功验证 WSDL`,
  [`WSDL to REST`]: `WSDL 到 REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `WS-I Basic Profile 验证`,
  [`Warn`]: `警告`,
  [`Warning`]: `警告`,
  [`Warning:`]: `警告：`,
  [`Warnings`]: `警告`,
  [`We just emailed recipients a test message:`]: `我们仅向收件人发送一封测试电子邮件：`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `我们正在将其移至“测试”选项卡，您将能够在其中测试和调试 API。`,
  [`Weather Product`]: `天气产品`,
  [`Web Endpoint`]: `Web 端点`,
  [`Web service operations`]: `Web Service 操作`,
  [`weight`]: `权重`,
  [`Weight`]: `权重`,
  [`Welcome to API Connect Administration`]: `欢迎使用 API Connect 管理`,
  [`Welcome to API Connect Reserved`]: `欢迎使用 API Connect 保留实例`,
  [`Welcome to API Designer`]: `欢迎使用 API Designer`,
  [`Welcome to API Manager`]: `欢迎使用 API Manager`,
  [`Welcome to Cloud Manager`]: `欢迎使用 Cloud Manager`,
  [`Welcome to the API Designer`]: `欢迎使用 API Designer`,
  [`Welcome to the API Manager`]: `欢迎使用 API Manager`,
  [`Welcome to the Cloud Manager`]: `欢迎使用 Cloud Manager`,
  [`What are you looking for today?`]: `您今天要查找什么？`,
  [`What's new`]: `新增内容`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `下一步？在目录中启用计费集成以允许用户向其产品添加套餐。`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `如果设置为 true，“invoke”策略会将“X-Forwarded-For”、“X-Forwarded-To”、“X-Forwarded-Host”、“X-Forwarded-Proto”头插入发送到目标 URL 的请求。`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `在与目录相关联的 Developer Portal 中，已发布的 API 产品会显示出来。这样，产品中的 API 端点可以在与目标目录或空间相关联的网关上使用。已登台的产品位于目录中，但是对于使用者无效或不可见。`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `在与目录相关联的 Developer Portal 中，已发布的 API 产品会显示出来。这样，产品中的 API 端点可以在与目标目录或空间相关联的网关上使用。已登台的产品位于目录中，但是对于使用者无效或不可见。请注意，在非生产目录中重新登台/重新发布产品将移除现有预订。`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `如果针对目录启用了空间，那么必须从空间中查看已配置的 OAuth 提供者。`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `启用空间时，会将目录中的现有产品移至缺省空间中。`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `当用户单击激活链接时，系统会要求他们填写注册表单。在注册后，用户即可登录。`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `在为提供者或使用者组织添加角色后，该角色将包含在角色缺省值中。角色缺省值是预先配置的角色模板，用于确定在创建新组织时可用的缺省角色。`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `弃用某个产品时，已预订该产品的应用程序开发人员可继续使用该产品，但是新开发人员无法预订该产品。\n\n [了解更多信息]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `在编辑提供者或使用者组织的角色后，该角色将包含在角色缺省值中。角色缺省值是预先配置的角色模板，用于确定在创建新组织时可用的缺省角色。`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `启用 OpenID Connect 时，系统将提供一个模板以用于生成标识令牌和访问令牌，并将自动创建所需的组合件策略。 您可以根据自己的需求来定制这些策略。 样本密钥仅用于测试目的和用于签署 JWT 令牌。`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `在启用令牌自省时，可通过自省路径来检查访问令牌。`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `在启用令牌自省时，访问令牌的持有者可使用自省路径来检查令牌的内容。 必须通过本机 OAuth 提供者获取要自省的访问令牌。`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `在邀请用户成为提供者组织的成员时，将为他们分配一个或多个角色以用于控制其对信息的访问权级别以及其可执行的任务。`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `在管理产品版本时，会使它们经过一系列生命周期状态，从最初将产品版本登台到目录，到发布产品版本以使其可供应用程序开发人员使用，一直到最终撤销和归档。`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `将一个产品替换为另一个产品时，将执行以下操作：1) 发布替换产品，2) 在替换产品中使用来自原始产品的相同可视性、订户和网关实施设置，3) 将原始产品的订户迁移到替换产品。`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `将产品替换为其他产品时，将执行以下操作：1) 替换产品将采用原始产品的状态，2) 如果替换产品中的可视性和可预订性设置使访问权与原始产品相同或比原始产品限制更少，那么将使用替换产品中的设置。 如果替换产品中的设置更具限制性，并且会阻止某些有权访问原始产品的使用者组织访问替换，那么无法完成替换操作。 3) 将原始产品的订户迁移到替换产品，并且 (4) 将原始产品作废。`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `撤销某个产品时，所有相关 API 将转为脱机，并且所有预订将变为不活动状态。\n\n [了解更多信息]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `将一个产品取代为另一个产品时，将执行以下操作：1) 发布取代产品，2) 在取代产品中使用来自原始产品的相同可视性、订户和网关实施设置，3) 将原始产品移至“已弃用”状态。弃用某个产品时，已预订该产品的应用程序开发人员可继续使用该产品，但是新开发人员无法预订该产品。如果需要，可以重新发布已弃用的产品。`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `将一个产品取代为另一个产品时，将执行以下操作：1) 发布取代产品，2) 在取代产品中使用来自原始产品的可视性和可预订性设置，以及 3) 将原始产品移至“已弃用”状态。弃用某个产品时，已预订该产品的应用程序开发人员可继续使用该产品，但是新开发人员无法预订该产品。如果需要，可以重新发布已弃用的产品。`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `暂挂应用程序时，将阻止关联的客户机标识进行 API 调用。您可以随时恢复该应用程序。`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `启用后，所有使用者组织邀请超时将设置为目录邀请超时，并且对目录邀请超时的任何更新都将对使用者组织邀请超时进行更新。`,
  [`Where did the roles go?`]: `角色位置？`,
  [`Where to find the named rate limit.`]: `放置所指定速率限制的位置`,
  [`Where to put the decrypted result`]: `放置已解密结果的位置`,
  [`Where to put the encrypted result`]: `放置已加密结果的位置`,
  [`Whether to reuse the connection to the target server.`]: `是否复用到目标服务器的连接。`,
  [`Windows live`]: `Windows live`,
  [`With`]: `使用`,
  [`Wildcards ignore xsi:type rule`]: `通配符忽略 xsi:type 规则`,
  [`Work offline`]: `脱机工作`,
  [`Work with existing definitions, models and/or data sources`]: `使用现有的定义、模型和/或数据源`,
  [`Working...`]: `正在处理...`,
  [`Wrapper Policies`]: `包装器策略`,
  [`Wrong slicing arguments`]: `错误的切割自变量`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL 和 XSD 应该位于您正尝试导入的 zip 文件中自己的 zip 文件内。`,
  [`XML Schema`]: `XML 模式`,
  [`XML Schema URL`]: `XML 模式 URL`,
  [`XML Schema preview`]: `XML 模式预览`,
  [`XML Validation Mode`]: `XML 验证方式`,
  [`XML schema is not valid. Please correct the error.`]: `XML 模式无效。请更正错误。`,
  [`XML to JSON`]: `XML 到 JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT 版本`,
  [`YAML has been successfully validated`]: `已成功验证 YAML`,
  [`Yes`]: `是`,
  [`Yes, enable it`]: `是，将其启用`,
  [`Yes, reset`]: `是，重置`,
  [`Yes. Update API Assembly`]: `是。更新 API 组合件`,
  [`You are authenticated!`]: `您已经过认证！`,
  [`You do not have permission to use the Test Application`]: `您没有使用测试应用程序的许可权`,
  [`You can also copy the link and send it directly to the user.`]: `您也可以复制此链接，并将其直接发送给用户。`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `您可以选择此时不发布 API，也可以立即发布至测试端点和/或生产端点。（可选）您可以根据指定时间间隔内的调用数来指定允许的最大 API 调用比率。`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `您可以选择此时不发布 API，也可以立即发布至测试端点和/或生产端点。（可选）您可以根据每个指定时间间隔内的调用数来指定允许的最大 API 调用比率。`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `此时，可以选择保留不安全的 API，也可以要求调用应用程序必须提供客户机标识。您可以在创建 API 定义后配置其他安全选项。`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `您可以输入目录名称或标题。如果两者都可用，那么将优先使用目录名称。`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `您可以向使用者组织所有者发送电子邮件。可使用“通知”中配置的电子邮件服务器来发送消息。`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `您可以向使用者组织所有者发送电子邮件。可使用“通知”部分中配置的电子邮件服务器来发送消息。`,
  [`You can send an email to a provider organization owner.`]: `您可以向提供者组织所有者发送电子邮件。`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `您可通过输入新用户的电子邮件地址来将所有权移交给此用户。他们将收到包含注册激活链接的电子邮件。一旦注册，用户就会成为新的所有者。为当前所有者分配新角色，使其能够继续在 Cloud Manager 中工作。`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `您可通过输入某个注册用户的电子邮件地址来将所有权移交给此用户。为当前所有者分配新角色，使其能够继续在 Cloud Manager 中工作。`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `您可以使用 OpenAPI 定义文件在 IBM API Connect 中添加 REST API。该文件的格式可以是 JSON 或 YAML。导入 API 定义后，该定义将显示在 API 定义列表中。`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `无法删除此类型，因为此操作将移除根类型（“Query”）。`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `您不能选择其网关类型与此产品的网关类型不匹配的 API。`,
  [`You currently don’t have any gateway extensions`]: `您目前没有任何网关扩展。`,
  [`You currently don’t have any plans to migrate`]: `您目前没有任何迁移计划`,
  [`You currently don't have any policy.`]: `您当前没有任何策略。`,
  [`You do not have permission to any spaces.`]: `您没有任何空间的许可权。`,
  [`You do not have permission to publish products to any catalog or space.`]: `您无权将产品发布至任何目录或空间。`,
  [`You do not have permission to stage products to any catalog or space.`]: `您无权将产品登台到任何目录或空间。`,
  [`You don’t currently have any billing integrations.`]: `您目前没有任何计费集成。`,
  [`You don't currently have any response.`]: `您当前没有任何响应。`,
  [`You don't currently have any user-defined policies.`]: `您目前没有任何用户定义的策略。`,
  [`You don't have permission to access this page.`]: `您无权访问此页面。`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `您已修改此证书。保存此应用程序将更新其配置。`,
  [`You haven't added any APIs or Products`]: `您尚未添加任何 API 或产品`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `通过向目录添加新用户并为这些用户分配角色来管理目录成员资格。`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `通过向空间添加新用户并为这些用户分配角色来管理空间成员资格。`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `通过向空间添加新用户并为这些用户分配角色来管理空间成员资格。`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `必须将 API 添加到可供发布的产品。产品将一组 API 和计划收集到向开发人员提供的一个产品服务中。计划包含速率限制设置，这些设置可作为整体应用于计划，也可为 API 中每个操作单独指定。`,
  [`You must choose a definition`]: `您必须选择一个定义`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `您必须启用至少一项网关服务，以便可以在网关服务端点上调用已发布至此目录的 API。您可启用多项网关服务。`,
  [`You must enable spaces`]: `必须启用空间`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `您应该记录以下客户机标识和私钥，以供在开发应用程序时使用。无法再次显示客户机密钥。`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `API Connect 保留实例许可证授权您下载并设置一个或多个远程 DataPower API Gateway。网关可位于本地或任何云上。`,
  [`Your API has been created`]: `已创建您的 API`,
  [`Your API is live!`]: `您的 API 已生效！`,
  [`Your API is online!`]: `您的 API 已联机！`,
  [`Your Catalog is being deleted. This may take a while...`]: `正在删除您的目录。这可能需要一些时间...`,
  [`Your Consumer organization is being created. This may take a while...`]: `正在创建您的使用者组织。这可能需要一些时间...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `您的邀请不再有效。 请检查您的邀请超时设置并重新发送邀请。`,
  [`Your Provider organization is being created. This may take a while...`]: `正在创建您的提供者组织。这可能需要一些时间...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `正在删除您的提供者组织。这可能需要一些时间...`,
  [`Your member is being deleted. This may take a while...`]: `正在删除您的成员。 这可能需要一些时间...`,
  [`Your Space is being deleted. This may take a while...`]: `正在删除您的空间。这可能需要一些时间...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `您的 OAuth 提供者具有未保存的更改。单击“确定”以继续而不保存。`,
  [`Your credentials have expired.`]: `您的凭证已到期。`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `已成功发送邀请，邀请链接如下：`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `您的请求仍在进行处理，所用时间超过预期。请在几分钟后刷新，然后重试请求。`,
  [`Your product will be saved before proceeding, are you sure?`]: `将在继续前保存您的产品，确定要这样吗？`,
  [`Your trial ends on {date}`]: `试用将于 {date} 结束`,
  [`Your trial expired on {date}.`]: `试用已于 {date} 到期。`,
  [`Zoom in`]: `放大`,
  [`Zoom in and out`]: `放大和缩小`,
  [`Zoom out`]: `缩小`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `目标服务器上缺少 CORS 支持（请参阅下面的建议）`,
  [`a LDAP provider`]: `LDAP 提供者`,
  [`activity`]: `活动`,
  [`add item...`]: `添加项...`,
  [`add property...`]: `添加属性...`,
  [`added`]: `已添加`,
  [`admin`]: `管理员`,
  [`administrator`]: `管理员`,
  [`all`]: `全部`,
  [`an API Connect Local User Registry`]: `API Connect 本地用户注册表`,
  [`an authentication URL`]: `认证 URL`,
  [`an untrusted certificate has been encountered`]: `遇到了不可信证书`,
  [`and`]: `和`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `应用程序：`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `核准`,
  [`approval task for product`]: `产品的审批任务`,
  [`archived`]: `已归档`,
  [`authenticated`]: `已认证`,
  [`authorization code time to live (seconds)`]: `授权代码生存时间（秒）`,
  [`Authorization header pass through`]: `授权头传递`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `阻止`,
  [`body`]: `正文`,
  [`body-param`]: `body-param`,
  [`cancel`]: `取消`,
  [`case`]: `案例`,
  [`catalog`]: `目录`,
  [`catalog:`]: `目录：`,
  [`catch`]: `捕获`,
  [`changed`]: `已更改`,
  [`cipher`]: `密码`,
  [`code id_token`]: `代码 id_token`,
  [`code id_token token`]: `代码 id_token 令牌`,
  [`code token`]: `代码令牌`,
  [`Consume`]: `使用`,
  [`consumer`]: `使用者`,
  [`consumes`]: `使用`,
  [`composite`]: `组合`,
  [`created`]: `创建`,
  [`Created API`]: `已创建 API`,
  [`custom string`]: `定制字符串`,
  [`day`]: `天`,
  [`day(s)`]: `天`,
  [`default`]: `缺省值`,
  [`default value`]: `缺省值`,
  [`definition`]: `定义`,
  [`deprecated`]: `已弃用`,
  [`description`]: `描述`,
  [`development`]: `开发`,
  [`edit condition`]: `编辑条件`,
  [`edited`]: `已编辑`,
  [`environment`]: `目录`,
  [`error`]: `错误`,
  [`false`]: `false`,
  [`file`]: `文件`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 图标`,
  [`for`]: `持续`,
  [`header`]: `头`,
  [`hear`]: `听`,
  [`hello`]: `您好`,
  [`helloName`]: `{name}，您好！`,
  [`here`]: `此处`,
  [`hit`]: `匹配项`,
  [`hits`]: `匹配项`,
  [`hour`]: `小时`,
  [`hour(s)`]: `小时`,
  [`hours`]: `小时`,
  [`identified`]: `已识别`,
  [`Increased`]: `已增加`,
  [`Increment`]: `增量`,
  [`info`]: `信息`,
  [`information about external documentation`]: `有关外部文档的信息`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `通过 APIC 生成的 access_token/refresh_token 将采用 APIC 设置，而不采用来自 OIDC 提供者的到期声明`,
  [`introspect URL`]: `自省 URL`,
  [`Issue`]: `问题`,
  [`Issues`]: `问题`,
  [`item`]: `项`,
  [`iterate over`]: `迭代`,
  [`last modified`]: `上次修改时间`,
  [`Log Level`]: `日志级别`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `日志`,
  [`Logout`]: `注销`,
  [`Logout redirect`]: `注销重定向`,
  [`Logout redirect URL`]: `注销重定向 URL`,
  [`location`]: `位置`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `分钟`,
  [`minute(s)`]: `分钟`,
  [`minutes`]: `分钟`,
  [`mutation type`]: `突变类型`,
  [`mutual SSL authentication is required`]: `需要相互 SSL 认证`,
  [`name`]: `名称`,
  [`Name|extension`]: `名称`,
  [`Name|path parameters`]: `名称`,
  [`Name|security definitions`]: `名称`,
  [`non-blocking`]: `非阻止`,
  [`none`]: `无`,
  [`none selected`]: `未选择`,
  [`Object`]: `对象`,
  [`off`]: `关闭`,
  [`of {total} pages`]: `（共 {total} 页）`,
  [`of {total} page`]: `（共 {total} 页）`,
  [`offline`]: `脱机`,
  [`on`]: `开启`,
  [`online`]: `联机`,
  [`or`]: `或者`,
  [`or redact from all`]: `或全部编辑`,
  [`open and close list of options`]: `打开和关闭选项列表`,
  [`organization saved`]: `已保存组织`,
  [`organization-manager`]: `组织管理员`,
  [`otherwise`]: `否则`,
  [`payload`]: `有效内容`,
  [`Payload`]: `有效内容`,
  [`pending`]: `暂挂`,
  [`produces`]: `生成`,
  [`production`]: `生产`,
  [`production and development`]: `生产和开发`,
  [`property`]: `属性`,
  [`provider`]: `提供者`,
  [`publish product`]: `发布产品`,
  [`published`]: `已发布`,
  [`query type`]: `查询类型`,
  [`query-parameters`]: `查询参数`,
  [`realized`]: `已实施`,
  [`recipients`]: `收件人`,
  [`removed`]: `已移除`,
  [`replaced`]: `已替换`,
  [`repeatable`]: `可重复`,
  [`Replenish`]: `补充`,
  [`request`]: `请求`,
  [`requested by`]: `请求者`,
  [`Resolver weight`]: `解析器权重`,
  [`response`]: `响应`,
  [`response-param`]: `response-param`,
  [`retired`]: `已撤销`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `已保存`,
  [`scalar`]: `标量`,
  [`second`]: `秒`,
  [`second(s)`]: `秒`,
  [`seconds`]: `秒`,
  [`select owner user`]: `选择所有者用户`,
  [`select user`]: `选择用户`,
  [`soap-body`]: `soap-body`,
  [`source`]: `源`,
  [`space`]: `空间`,
  [`Space settings`]: `空间设置`,
  [`Skip`]: `跳过`,
  [`specified`]: `已指定`,
  [`staged`]: `已登台`,
  [`start generating tests automatically.`]: `自动开始生成测试。`,
  [`subject`]: `主题`,
  [`subscription type`]: `预订类型`,
  [`supersede`]: `取代`,
  [`superseded`]: `已取代`,
  [`target`]: `目标`,
  [`the server is unavailable`]: `服务器不可用`,
  [`this user registry type`]: `此用户注册表类型`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `这是执行重新发布保留预订的临时发布页面。`,
  [`to production`]: `到生产环境`,
  [`topology-administrator`]: `拓扑管理员`,
  [`Trial days left`]: `剩余试用天数`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `已更改 ttl`,
  [`type`]: `类型`,
  [`unarchived`]: `已取消归档`,
  [`union type`]: `union 类型`,
  [`unlimited`]: `非受限`,
  [`updated`]: `更新`,
  [`Updated from new schema`]: `已从新模式更新`,
  [`Upgrade`]: `升级`,
  [`v5 legacy behavior`]: `V5 旧行为`,
  [`version 2`]: `V2`,
  [`version 3`]: `V3`,
  [`viewer`]: `查看者`,
  [`value`]: `值`,
  [`warn`]: `警告`,
  [`warning`]: `个警告`,
  [`warnings`]: `个警告`,
  [`was not found. Please select a configured OAuth provider.`]: `未找到。请选择已配置的 OAuth 提供者。`,
  [`week`]: `周`,
  [`with`]: `带`,
  [`with|supersede with`]: `带`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `沙箱中的状态`,
  [`your file here, or`]: `您的文件到此处，或者`,
  [`{count} found!`]: `找到 {count} 个！`,
  [`{current} of {total} page`]: `{current} 页，共 {total} 页`,
  [`{current} of {total} pages`]: `{current}（共 {total} 页）`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} 天，{hours} 小时和 {minutes} 分钟`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} 天和 {hours} 小时`,
  [`{docType} is required`]: `“{docType}”是必需的`,
  [`{docType} title`]: `{docType} 标题`,
  [`Product title`]: `产品标题`,
  [`Application title`]: `应用程序标题`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} 小时和 {minutes} 分钟`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `因为与 API 或产品中指定的网关类型不兼容，隐藏了 {incompatibleGatewayCount} 个网关`,
  [`{min}-{max} of {total} items`]: `{min}-{max}（共 {total} 项）`,
  [`{min}-{max} of {total} item`]: `{min}-{max} 项，共 {total} 项`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} 个（共 {total} 个）策略`,
  [`{min}-{max} of {total} Policy`]: `{min}-{max} 个策略，共 {total} 个策略`,
  [`{min}-{max} of {total} types`]: `{min}-{max}（共 {total} 种类型）`,
  [`{min}-{max} of {total} type`]: `{min}-{max} 个类型，共 {total} 个类型`,
  [`{min}-{max} of {total} search results`]: `{min}-{max}（共 {total} 条搜索结果）`,
  [`{min}-{max} of {total} search result`]: `{min}-{max} 个搜索结果，共 {total} 个搜索结果`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} 个（共 {total} 个）目录`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{max} 个目录，共 {total} 个目录`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} 个（共 {total} 个）空间`,
  [`{min}-{max} of {total} Space`]: `{min}-{max} 个空间，共 {total} 个空间`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} 个（共 {total} 个）扩展`,
  [`{min}-{max} of {total} Extension`]: `{min}-{max} 个扩展，共 {total} 个扩展`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} 个（共 {total} 个）产品计划`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{max} 个产品套餐，共 {total} 个产品套餐`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} 个（共 {total} 个）网关`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{max} 个网关，共 {total} 个网关`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (API) 缺少“x-ibm-configuration”`,
  [`{name} (Product) has been published!`]: `已发布 {name}（产品）！`,
  [`{name} (Product) has been staged!`]: `已登台 {name}（产品）！`,
  [`{name} (Product) has not been published!`]: `尚未发布 {name}（产品）！`,
  [`{name} (Role) has been created`]: `已创建 {name}（角色）。`,
  [`{name} successfully deleted`]: `已成功删除 {name}。`,
  [`{name} (Role) has been updated`]: `已更新 {name}（角色）。`,
  [`{name} has been assigned the owner`]: `已为 {name} 分配所有者`,
  [`{number} Error`]: `{number} 个错误`,
  [`{number} Errors`]: `{number} 个错误`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{operation} {username} 请求的产品 {prodname}:{version} 的核准任务`,
  [`{path} does not exist`]: `{path} 不存在`,
  [`{productName} has no migration target set.`]: `{productName} 未设置迁移目标。`,
  [`{state} Pending`]: `{state} 暂挂`,
  [`{status} API error`]: `{status} API 错误`,
  [`{status} Bad Request`]: `{status} 错误请求`,
  [`{status} Forbidden`]: `{status} 已禁止`,
  [`{status} Gateway Error`]: `{status} 网关错误`,
  [`{status} Unauthorized`]: `{status} 未授权`,
  [`({time} after being sent)`]: `（发送后 {time}）`,
  [`({time} after being queued)`]: `（排队后 {time}）`,
  [`{title} (Product) has been {state}.`]: `{title}（产品）已{state}。`,
  [`{title} has been deleted`]: `已删除“{title}”`,
  [`{title} has been {changedName}.`]: `{title}已{changedName}。`,
  [`{title} has not been deleted!`]: `{title} 尚未删除！`,
  [`{title} has not been {changedName}!`]: `{title} 尚未{changedName}！`,
  [`{title} {changedName}.`]: `{title}{changedName}。`,
  [`{type} has been {changedName}.`]: `{type}已{changedName}。`,
  [`{type} has not been {changedName}!`]: `{type}尚未{changedName}！`,
  [`{type} history log`]: `{type}历史记录日志`,
  [`{username} does not exist`]: `{username} 不存在`,
  [`{username} does not exist in the user registry`]: `{username} 在用户注册表中不存在`,
  [`{username} is not associated with this consumer org`]: `{username} 未与此使用者组织关联`,
  [`{username} has been created as a {type}.`]: `已作为 {type} 创建 {username}。`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `已在 {consumerOrg} Developer Portal 中恢复 {appName} 应用程序`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `已在 {consumerOrg} Developer Portal 中暂挂 {appName} 应用程序`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} Developer Portal 帐户注册`,
  [`{{count}} errors need attention`]: `{count} 个错误需要注意`,
  // lts only translations
  [`Configure JSON settings`]: `配置 JSON 设置`,
  [`Configure XML settings`]: `配置 XML 设置`,
  [`Configure options`]: `配置选项`,
  [`Detect`]: `检测`,
  [`Document type`]: `文档类型`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `最大文档大小`,
  [`Maximum name length`]: `最大名称长度`,
  [`Maximum nesting depth`]: `最大嵌套深度`,
  [`Maximum number length`]: `最大数字长度`,
  [`Maximum number of unique names`]: `唯一名称的最大数量`,
  [`Maximum number of unique namespaces`]: `唯一名称空间的最大数量`,
  [`Maximum number of unique prefixes`]: `唯一前缀的最大数量`,
  [`Maximum value length`]: `最大值长度`,
  [`Maximum width`]: `最大宽度`,
  [`Maximum stack size`]: `最大堆栈大小`,
  [`Parse settings`]: `解析设置`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `指定解析操作可接受的最大文档大小（字节）。`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `指定解析操作接受的 XML 或 JSON 消息中嵌套元素深度的最大级别。`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `指定解析操作接受的 XML、JSON 或 GraphQL 消息中嵌套元素深度的最大级别。`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `指定解析操作可接受的最大名称长度。`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `指定最大数字长度，其限制了当值为数字时标签/值对的值部分中的字节数量。`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `解析操作可接受的唯一 XML 名称空间 URI 的最大数量。此限制将统计所有 XML 名称空间，而不考虑使用了多少前缀来声明这些名称空间。`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `指定解析操作接受的文档中唯一 XML 名称空间前缀的最大数量。此限制将统计为同一名称空间定义的多个前缀，但不统计在输入文档的不同部分中以一个前缀定义的多个名称空间。`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `指定解析操作可接受的唯一名称的最大数量。`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `指定解析操作可接受的最大值长度（字节）。`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `指定解析操作接受的最大有效内容宽度。`,
  [`Specifies the type of document to parse.`]: `指定要解析的文档的类型。`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `指定 XSLT 处理器版本。缺省值为 XSLT10。`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `指定是否启用严格 XSLT 错误检查。非严格操作会试图从某些错误（例如，使用未声明的变量、调用未声明的模板等）中恢复。缺省情况下，已启用严格 XSLT 错误检查。`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `指定是否启用样式表概要分析。不应在生产环境中使用此选项。缺省情况下，已禁用样式表概要分析。`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `指定是否以调试方式运行样式表、XQuery 脚本和 JSONiq 脚本。 当以调试方式运行样式表、XQuery 脚本或 JSONiq 脚本时，它将生成定制 Web 页面，而不是显示其正常输出。 Web 页面详细描述执行期间发生的情况，包括变量的值以及特定部分输出的来源。 此选项不应在生产环境中使用。 缺省情况下，将禁用调试方式。`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `指定是否必须在流方式下运行样式表。文档的转换在完全解析输入之前开始。并非所有样式表都可以流式传输。如果样式表无法流式传输，那么将生成错误并且不处理输入。缺省情况下，已禁用流方式。`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `指定是否会尝试在流方式下运行样式表。文档的转换在完全解析输入之前开始。并非所有样式表都可以流式传输。如果样式表无法流式传输，那么会在编译期间生成警告，并且在执行时正常读取整个输入中的样式表。缺省情况下，已禁止尝试在流方式下运行样式表。`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `指定在处理期间是否转义从样式表产生的输出。最小转义在处理非英语字符集时特别有用。缺省情况下，已禁用最小转义。`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `指示在执行样式表或其他编译内容时允许堆栈使用的最大字节数。此设置用于阻止无限递归。最小值为 10 千字节或 10,240 字节。最大值为 100 兆字节或 104,857,600 字节。缺省值为 1 兆字节或 1,048,576 字节。`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `指定要应用于 WSDL 文件的验证行为，以检查这些文件是否符合 WS-I Basic Profile（V1.0，2004 年 4 月）第 5 节的要求。缺省设置是“警告”。`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `指定 soap:Body 的验证行为。缺省设置是“严格”。`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `指定 soap:Header 的验证行为。缺省设置是“宽松”。`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `指定故障详细信息的验证行为。缺省设置是“严格”。`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `指定是否要求与 RPC 样式的包装程序兼容。缺省情况下，不需要 RPC 样式的包装程序。`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `指定是否允许模式接受 xsi:type='SOAP-ENC:Array' 且符合 SOAP 1.1 第 5 节要求的大多数元素，即使这些属性违反了 XML 模式规范。通常，xsi:type 属性指定的类型必须是元素的实际类型或从元素的实际类型派生。对于使用此选项编译的模式，如果元素类型派生自 SOAP-ENC:Array，那么明确接受使用 xsi:type 指定 SOAP 1.1 编码“Array”复杂类型。与之相对的是正常允许的情况。缺省情况下，不接受 xsi:type='SOAP-ENC:Array' 的元素。`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `指定是否按照 SOAP 1.1 第 5 节中的编码规则执行额外的模式验证。启用此选项后，将验证 SOAP 数组的成员，允许使用 @id 和 @href 等属性（即使模式不允许使用这些属性），并且会检查 @href 值以确保它们具有相应的 @id 元素。缺省情况下，将不执行额外的验证。`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `指定模式中的 xs:any 元素是否仅按名称验证子元素。XML 模式规范的要求为：如果通配符与某个元素匹配，但是该元素没有元素声明，那么该元素将改为根据它的 xsi:type 属性进行验证。此选项会忽略这些 xsi:type 属性。此选项应在诸如 SOAP 包络验证之类的情况下使用，在此情况下，进一步验证步骤可能会使用 SOAP 1.1 编码规则验证与通配符匹配的内容。缺省情况下，不会忽略 xsi:type 属性。`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `指定是否严格遵循 WSDL 中的 SOAP 绑定。启用此选项后，只有绑定到 SOAP 1.2 的消息才会出现在 SOAP 1.2 包络中，并且只有绑定到 SOAP 1.1 的消息才会出现在 SOAP 1.1 包络中。 缺省情况下，已禁用严格 SOAP 绑定。`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `指定是否使用调试信息编译 XACML 策略。请注意，XACML 调试消息也受 XACML 类别中的日志事件控制。请使用调试日志级别来查看完整的 XACML 调试消息。缺省情况下，不使用调试信息编译 XACML 策略。`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `指定模式或 WSDL 文档是否接受其基本 64 位编码的二进制内容已根据 MTOM/XOP 规范进行优化的消息。XOP 二进制优化将使用 xop:Include 引用元素替换基本 64 位编码的二进制数据，该引用元素引用位于附件中的未编码二进制数据。缺省情况下，已禁用 MTOM/XOP 优化消息。`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `使用者组织组是使用者组织的集合，它提供有效方法来控制哪些人员可查看及预订您的产品中的 API。通过使用一个使用者组织组，您可通过单个操作对该组中的组织内的所有开发人员定义此访问权，而不必单独对各个组织定义访问权。`,
  [`API Connect Home`]: `API Connect 主页`,
  [`Add new group`]: `添加新组`,
  [`Add consumer organizations to the group as required`]: `根据需要向该组添加使用者组织`,
  [`Add the following configuration`]: `添加以下配置`,
  [`Add the following configuration to`]: `将以下配置添加到`,
  [`Additions`]: `添加`,
  [`Additional GraphQL capabilities`]: `其他 GraphQL 功能`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `此计划中包含的其他 graphQL API 设置`,
  [`An organization or a group is required.`]: `需要组织或组。`,
  [`An organization/group is required.`]: `需要组织/组。`,
  [`Analysis configuration`]: `分析配置`,
  [`Application & Credentials`]: `应用程序&amp;amp;凭证`,
  [`Are you sure you want to delete this API version?`]: `确定要删除此 API 版本？`,
  [`Are you sure you want to delete this API with all versions?`]: `确定要删除此 API 的所有版本？`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `确定要从网关中移除网关扩展？`,
  [`Are you sure you want to rename all versions of this API?`]: `确定要重命名此 API 的所有版本？`,
  [`Are you sure you want to rename this API version?`]: `确定要重命名此 API 版本？`,
  [`Associate groups to the organization as required`]: `根据需要将组关联至组织`,
  [`At least one option must be enabled`]: `必须至少启用一个选项`,
  [`Attribute mapping`]: `属性映射`,
  [`Attempt streaming rule`]: `尝试流规则`,
  [`Auto generated default paths and operations`]: `已自动生成缺省路径和操作`,
  [`Base API endpoint`]: `基本 API 端点`,
  [`Consumer orgs`]: `使用者组织`,
  [`Calculate Query Characteristics`]: `计算查询特征`,
  [`Calculate Response Characteristics`]: `计算响应特征`,
  [`Certificate or Shared Secret for Verify`]: `用于验证的证书或共享私钥`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `请了解我们的动手实验室，您可以在那里请求试用完整的 API 管理功能，试用期长达一周！`,
  [`Clone API`]: `克隆 API`,
  [`Consumer organizations and groups`]: `使用者组织和组`,
  [`Create new API version`]: `创建新的 API 版本`,
  [`Create new product version`]: `创建新的产品版本`,
  [`Create a GraphQL API based on a GraphQL service`]: `根据 GraphQL 服务创建 GraphQL API`,
  [`Custom rate limit`]: `定制速率限制`,
  [`Delete API`]: `删除 API`,
  [`Delete this version`]: `删除此版本`,
  [`Deletion confirmation`]: `删除确认`,
  [`Deletions`]: `删除`,
  [`Display name`]: `显示名称`,
  [`Display Name`]: `显示名称`,
  [`Details`]: `详细信息`,
  [`Edit GraphQL Rate Limit|title`]: `编辑 GraphQL 速率限制|标题`,
  [`Edit GraphQL rate limits`]: `编辑 GraphQL 速率限制`,
  [`Edit group`]: `编辑组`,
  [`Edit condition`]: `编辑条件`,
  [`Edit rate limit|title`]: `编辑速率限制`,
  [`Edit rate limit|menu`]: `编辑速率限制`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `未将任何产品发布至此目录，或者此应用程序已预订所有产品中的所有计划。`,
  [`Enforce Policy`]: `实施策略`,
  [`Enter a group summary`]: `输入组摘要`,
  [`Enter a group title`]: `输入组标题`,
  [`Enter group details below`]: `在下面输入组详细信息`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `输入要代理的 GraphQL 服务的 URL 和模式名称`,
  [`Enum value`]: `枚举值`,
  [`Go to catalog settings to configure a new billing integration`]: `转至“目录设置”以配置新的计费集成`,
  [`GraphQL introspect`]: `GraphQL 自省`,
  [`GraphQL server`]: `GraphQL 服务器`,
  [`GraphQL Setting`]: `GraphQL 设置`,
  [`GraphQL server URL`]: `GraphQL 服务器 URL`,
  [`GraphQL server name`]: `GraphQL 服务器名称`,
  [`GraphQL type analysis configuration`]: `GraphQL 类型分析配置`,
  [`GraphQL resolver analysis configuration`]: `GraphQL 解析器分析配置`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `可在以下列表中创建和管理组。`,
  [`Groups will be listed here.`]: `此处将列出组。`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `{firstName}，您好！\n\n{action} {catalog} 目录中 API 产品 {productName}：{version} 的\n请求已获得核准。\n\n{#if comments}\n核准者包含了以下评论：\n\n{comments}\n\n{{/if}}\n您可以在此处检查产品的状态：\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `您好，\n\n这是来自配置的邮件服务器 (mailServer) 的 IBM API Connect 的\n测试消息。\n\n如果如期收到此消息，那么测试成功！\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `您好！\n\n用户 {originator} 已撤销其应用程序 {appName}\n针对 {catalog} 目录中 {productName} API 产品 V{productVersion} 的 {planName} 计划的预订请求。\n\n无需进一步操作。\n`,
  [`Invoke GraphQL server`]: `调用 GraphQL 服务器`,
  [`Invoke the backend GraphQL server`]: `调用后端 GraphQL 服务器`,
  [`JWKS endpoint`]: `JWKS 端点`,
  [`LDAP Field Name`]: `LDAP 字段名称`,
  [`Limit maximum nesting per query`]: `限制每个查询的最大嵌套数`,
  [`Limit resolve complexity rate on a per key basis`]: `根据密钥限制解析复杂率`,
  [`Limit type complexity rate on a per key basis`]: `根据密钥限制类型复杂率`,
  [`Manage APIs included in this product`]: `管理本产品中包含的 API`,
  [`Manage groups`]: `管理组`,
  [`Maximum nesting`]: `最大嵌套数`,
  [`Maximum resolve complexity`]: `最大解析复杂度`,
  [`Maximum type complexity`]: `最大类型复杂度`,
  [`Name of the group`]: `组的名称`,
  [`New API version`]: `新建 API 版本`,
  [`New GraphQL API`]: `新建 GraphQL API`,
  [`New product version`]: `新建产品版本`,
  [`New Version`]: `新版本`,
  [`New version`]: `新版本`,
  [`No billing integration exists`]: `不存在任何计费集成`,
  [`No CA bundles available`]: `无可用 CA 捆绑包`,
  [`No Data`]: `无数据`,
  [`No certificates available`]: `无可用证书`,
  [`No data`]: `无数据`,
  [`No group found`]: `找不到任何组`,
  [`OAuth Auth URL`]: `OAuth 认证 URL`,
  [`OAuth Token URL`]: `OAuth 令牌 URL`,
  [`Open Stripe dashboard`]: `打开 Stripe 仪表板`,
  [`Operation/Path`]: `操作/路径`,
  [`Operation Path`]: `操作路径`,
  [`Operations and Paths`]: `操作和路径`,
  [`Operator`]: `运算符`,
  [`Organizations and groups will be listed here.`]: `此处将列出组织和组。`,
  [`Page not found`]: `找不到页面`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `请将以下代码复制到 CLI 以继续该过程。代码将在 **{ttl}** 内到期。`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `请引用 Stripe 仪表板以访问 API 密钥和私钥`,
  [`Please upload a schema definition language (SDL) file.`]: `请上载模式定义语言 (SDL) 文件。`,
  [`Please upload a valid GraphQL schema.`]: `请上载有效的 GraphQL 模式。`,
  [`Please upload a valid YAML file.`]: `请上载有效的 YAML 文件。`,
  [`Populate Server Configuration into Context Variables`]: `将服务器配置填充到上下文变量中`,
  [`POST/GET a query to be validated and sent to the backend server`]: `发布/获取要验证并发送到后端服务器的查询`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `发布/获取查询以获得调用该查询的估计开销`,
  [`Preparing`]: `正在准备`,
  [`Rate limit amount cannot be negative`]: `速率限制量不能为负值`,
  [`Rate limit per cannot be negative`]: `速率限制频率值不能为负值`,
  [`Rename API`]: `重命名 API`,
  [`Rename`]: `重命名`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `用于核准{action} {catalog} 目录中 API 产品的请求`,
  [`Sandbox Sample Application`]: `沙箱样本应用程序`,
  [`Sandbox sample application credentials`]: `沙箱样本应用程序凭证`,
  [`sample application credentials`]: `样本应用程序凭证`,
  [`Saving`]: `正在保存`,
  [`Search Orgs`]: `搜索组织`,
  [`Search definitions...`]: `搜索定义...`,
  [`Search errors...`]: `搜索错误...`,
  [`Search operations...`]: `搜索操作...`,
  [`Search organizations and groups`]: `搜索组织和组`,
  [`Sorry, This feature is not available in`]: `抱歉，此功能不可用`,
  [`Stopping`]: `正在停止`,
  [`The user with username {username} already exists in the user registry.`]: `用户注册表中已存在用户名为 {username} 的用户。`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `存在未在运行的 API 更改。将此 API 重新发布至沙箱目录。`,
  [`There are no plans available for subscription.`]: `没有可供预订的计划。`,
  [`There are no attributes yet.`]: `尚不存在任何属性。`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `输入以向组添加使用者组织`,
  [`Type to add groups to the organization`]: `输入以向组织添加组`,
  [`Type to add organizations from {catalog}`]: `输入以从 {catalog} 添加组织`,
  [`Type to add organizations or groups`]: `输入以添加组织或组`,
  [`Type to add organizations or groups from {catalog}`]: `输入以从 {catalog} 添加组织或组`,
  [`Type to add {title} to groups`]: `输入以向组添加 {title}`,
  [`Update Rate Limits`]: `更新速率限制`,
  [`Upload the YAML file with configuration`]: `上载含配置的 YAML 文件`,
  [`Use the sample below as a template to configure your GraphQL server`]: `使用以下样本作为模板来配置 GraphQL 服务器`,
  [`User managed`]: `用户管理`,
  [`URL {url} does not contain valid OpenAPI.`]: `URL {url} 不包含有效的 OpenAPI。`,
  [`Validating`]: `正在验证`,
  [`View analytics and performance metrics for your APIs`]: `查看 API 的分析和性能度量`,
  [`View and add custom policies to the catalog`]: `查看定制策略并将其添加至目录`,
  [`View and add custom exentions to the catalog`]: `查看定制扩展并将其添加至目录`,
  [`View user registries and OAuth providers`]: `查看用户注册表和 OAuth 提供者`,
  [`View your application and manage API credentials`]: `查看您的应用程序并管理 API 凭证`,
  [`Welcome to the API Connect Trial`]: `欢迎使用 API Connect Trial`,
  [`You haven't added any APIs`]: `您尚未添加任何 API`,
  [`You currently don’t have any {resource}.`]: `您目前没有任何{resource}。`,
  [`plans to migrate`]: `要迁移的套餐`,
  [`gateway extensions`]: `网关扩展`,
  [`application`]: `应用程序`,
  [`application authentication source`]: `应用程序认证源`,
  [`API or product`]: `API 或产品`,
  [`base endpoints`]: `基本端点`,
  [`categories`]: `类别`,
  [`certificates`]: `证书`,
  [`channels`]: `通道`,
  [`consumer organizations`]: `使用者组织`,
  [`consumer organization group`]: `使用者组织组`,
  [`consumer organizations or groups`]: `使用者组织或组`,
  [`correlation ids`]: `相关标识`,
  [`cors policy`]: `cors 策略`,
  [`definitions`]: `定义`,
  [`examples`]: `示例`,
  [`gateway services`]: `网关服务`,
  [`groups`]: `组`,
  [`headers`]: `头`,
  [`links`]: `链接`,
  [`member`]: `成员`,
  [`messages`]: `消息`,
  [`message traits`]: `消息特征`,
  [`operation traits`]: `操作特征`,
  [`parameter definitions`]: `参数定义`,
  [`parameters`]: `参数`,
  [`path`]: `路径`,
  [`properties`]: `属性`,
  [`provider organizations`]: `提供者组织`,
  [`request bodies`]: `请求主体`,
  [`response definitions`]: `响应定义`,
  [`responses`]: `响应`,
  [`role`]: `角色`,
  [`scope`]: `作用域`,
  [`scopes`]: `范围`,
  [`schemas`]: `模式`,
  [`schema definition`]: `模式定义`,
  [`schemes list`]: `模式列表`,
  [`security definitions`]: `安全性定义`,
  [`security schemes`]: `安全方案`,
  [`servers`]: `服务器`,
  [`services`]: `服务`,
  [`tags`]: `tags`,
  [`tags and external documentation`]: `标记和外部文档`,
  [`target services`]: `目标服务`,
  [`tasks`]: `任务`,
  [`user registries`]: `用户注册表`,
  [`HTTP header`]: `HTTP 标头`,
  [`You currently don’t have any application authentication source.`]: `您当前没有任何应用程序认证源。`,
  [`You currently don’t have any API or product.`]: `您目前没有任何 API 或产品。`,
  [`You currently don’t have any API user registries`]: `您目前没有任何 API 用户注册表。`,
  [`You currently don’t have any APIs.`]: `您目前没有任何 API。`,
  [`You currently don’t have any TLS client profiles`]: `您目前没有任何 TLS 客户机概要文件。`,
  [`You currently don’t have any base endpoints.`]: `您目前没有任何基本端点。`,
  [`You currently don’t have any catalog properties.`]: `您当前没有任何目录属性。`,
  [`You currently don’t have any categories.`]: `您当前没有任何类别。`,
  [`You currently don’t have any certificates.`]: `您当前没有任何证书。`,
  [`You currently don’t have any channels.`]: `您当前没有任何渠道。`,
  [`You currently don’t have any consumer organizations.`]: `您目前没有任何使用者组织。`,
  [`You currently don’t have any consumer organizations or groups.`]: `您目前没有任何使用者组织或组。`,
  [`You currently don’t have any consumes.`]: `您当前没有任何消费。`,
  [`You currently don’t have any correlation ids.`]: `您当前没有任何相关标识。`,
  [`You currently don’t have any cors policy.`]: `您当前没有任何 cors 策略。`,
  [`You currently don’t have any definitions.`]: `您当前没有任何定义。`,
  [`You currently don’t have any examples.`]: `你目前没有任何示例。`,
  [`You currently don’t have any gateway services.`]: `您目前没有任何网关服务。`,
  [`You currently don’t have any groups.`]: `您目前没有任何组。`,
  [`You currently don’t have any headers.`]: `您当前没有任何头。`,
  [`You currently don’t have any keystore.`]: `您当前没有任何密钥库。`,
  [`You currently don’t have any links.`]: `您当前没有任何链接。`,
  [`You currently don’t have any messages.`]: `您当前没有任何消息。`,
  [`You currently don’t have any message traits.`]: `您当前没有任何消息特征。`,
  [`You currently don’t have any OAuth providers`]: `您目前没有任何 OAuth 提供者`,
  [`You currently don’t have any operation traits.`]: `您目前没有任何操作特征。`,
  [`You currently don’t have any parameter definitions.`]: `您当前没有任何参数定义。`,
  [`You currently don’t have any parameters.`]: `您目前没有任何参数。`,
  [`You currently don’t have any preserved request header.`]: `您当前没有任何保留请求头。`,
  [`You currently don’t have any preserved response header.`]: `您当前没有任何保留响应头。`,
  [`You currently don’t have any produces.`]: `您当前没有任何生产。`,
  [`You currently don’t have any properties.`]: `您目前没有任何属性。`,
  [`You currently don’t have any provider organizations.`]: `您目前没有任何提供者组织。`,
  [`You currently don’t have any request bodies.`]: `您当前没有任何请求主体。`,
  [`You currently don’t have any response definitions.`]: `您当前没有任何响应定义。`,
  [`You currently don’t have any responses.`]: `您当前没有任何响应。`,
  [`You currently don’t have any scopes.`]: `您目前没有任何作用域。`,
  [`You currently don’t have any schemas.`]: `您当前没有任何模式。`,
  [`You currently don’t have any schemes list.`]: `您当前没有任何方案列表。`,
  [`You currently don’t have any security definitions.`]: `您目前没有任何安全性定义。`,
  [`You currently don’t have any security schemes.`]: `您当前没有任何安全方案。`,
  [`You currently don’t have any servers.`]: `您当前没有任何服务器。`,
  [`You currently don’t have any services.`]: `您目前没有任何服务。`,
  [`You currently don’t have any tags.`]: `您当前没有任何标记。`,
  [`You currently don’t have any tags and external documentation.`]: `您目前没有任何标记和外部文档。`,
  [`You currently don’t have any target services.`]: `您当前没有任何目标服务。`,
  [`You currently don’t have any tasks.`]: `您目前没有任何任务。`,
  [`You currently don’t have any truststore.`]: `您当前没有任何信任密钥库。`,
  [`You currently don’t have any user registries.`]: `您目前没有任何用户注册表。`,
  [`You currently don’t have any extensions.`]: `您目前没有任何扩展。`,
  [`You currently don’t have any HTTP header.`]: `您当前没有任何 HTTP 头。`,
  [`Your account is being activated. This may take a while...`]: `已激活您的帐户。这可能需要一些时间...`,
  [`all of`]: `全部`,
  [`any of`]: `任何`,
  [`calls`]: `调用`,
  [`ciphers`]: `密码`,
  [`extensions`]: `扩展`,
  [`one of`]: `之一`,
  [`shared`]: `共享`,
  [`stopping`]: `正在停止`,
  [`to:`]: `即可执行以下操作：`,
  [`Key`]: `密钥`,
  [`kind`]: `种类`,
  [`Trace`]: `跟踪`,
  [`Http Status`]: `Http 状态`,
  [`Parsed`]: `已解析`,
  [`Raw`]: `原始`,
  [`Showing Full Trace`]: `显示完全跟踪`,
  [`Show Entire Trace`]: `显示完整跟踪`,
  [`Show advanced`]: `显示高级`,
  [`Advanced`]: `高级`,
  [`Sending Request`]: `正在发送请求`,
  [`Please send a request above`]: `请发送以上请求`,
  [`Please send a request above.`]: `请发送以上请求。`,
  [`Authentication Type`]: `认证类型`,
  [`You don't currently have any response`]: `您目前未收到任何响应`,
  [`You don't currently have any trace.`]: `当前没有任何跟踪。`,
  [`No response received`]: `未收到任何响应`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `单击“打开链接以接受异常”将在新选项卡中打开服务器。如果浏览器显示证书问题，那么您可以选择接受该问题并返回此处以重新测试。`,
  [`Open link to accept exception`]: `打开链接以接受异常`,
  [`Back to {name}`]: `返回到 {name}`,
  [`0 seconds`]: `0 秒`,
  [`close.menu`]: `“关闭”菜单`,
  [`open.menu`]: `“打开”菜单`,
  [`Referenced from "{ref}"`]: `已从“{ref}”引用`,
  [`Go to {definition}`]: `转至 {definition}`,
  [`General`]: `常规`,
  [`Servers`]: `服务器`,
  [`External Docs`]: `外部文档`,
  [`Components`]: `组件`,
  [`Schemas`]: `模式`,
  [`Responses`]: `响应`,
  [`Examples`]: `示例`,
  [`Request Bodies`]: `请求主体`,
  [`Security Schemes`]: `安全方案`,
  [`Links`]: `链接`,
  [`Callbacks`]: `回调`,
  [`Terms of Service`]: `服务条款`,
  [`Contact Name`]: `联系人姓名`,
  [`Contact URL`]: `联系人 URL`,
  [`Contact Email`]: `联系人电子邮件`,
  [`Contact sales`]: `与销售人员联系`,
  [`License Name`]: `许可证名称`,
  [`License URL`]: `许可证 URL`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `接受 MTOM/XOP 优化消息`,
  [`User-Agent`]: `用户代理`,
  [`token`]: `令牌`,
  [`Tag`]: `标记`,
  [`Request Body`]: `请求主体`,
  [`Security Requirements`]: `安全需求`,
  [`Located In`]: `位置`,
  [`Example Value`]: `示例值`,
  [`External Value`]: `外部值`,
  [`Server`]: `服务器`,
  [`Server Description`]: `服务器描述`,
  [`Server Variables`]: `服务器变量`,
  [`Format`]: `格式`,
  [`Pattern`]: `模式`,
  [`Multiple Of`]: `多个`,
  [`Multiple non null slicing arguments`]: `多个非空切割自变量`,
  [`Missing slicing arguments`]: `缺少切割自变量`,
  [`Missing sized fields`]: `缺少限制大小的字段`,
  [`Maximum`]: `最大值`,
  [`Exclusive Maximum`]: `最大值（不含）`,
  [`Minimum`]: `最小值`,
  [`Exclusive Minimum`]: `最小值（不含）`,
  [`Max Length`]: `最大长度`,
  [`Min Length`]: `最小长度`,
  [`Max Items`]: `最大项数`,
  [`Min Items`]: `最小项数`,
  [`Unique Items`]: `唯一项数`,
  [`Max Properties`]: `最大属性数`,
  [`Min Properties`]: `最小属性数`,
  [`Required Properties`]: `必需属性`,
  [`Required Property Name`]: `必需属性名称`,
  [`Enum`]: `枚举`,
  [`Enum Value`]: `枚举值`,
  [`Nullable`]: `可空`,
  [`Read Only`]: `只读`,
  [`Write Only`]: `只写`,
  [`api`]: `API`,
  [`version`]: `版本`,
  [`username`]: `用户名`,
  [`password`]: `密码`,
  [`service`]: `服务`,
  [`Allows Empty Value`]: `允许空值`,
  [`Style`]: `样式`,
  [`Explode`]: `分解`,
  [`Allow Reserved`]: `允许保留`,
  [`Allow WebSocket Upgrade`]: `允许 WebSocket 升级`,
  [`Ref`]: `引用`,
  [`Operation ID`]: `操作标识`,
  [`Parameter`]: `参数`,
  [`Gateway and portal settings`]: `网关和门户网站设置`,
  [`Targets`]: `目标`,
  [`Catalog Properties`]: `目录属性`,
  [`catalog properties`]: `目录属性`,
  [`Attachments`]: `附件`,
  [`Activity Log`]: `活动日志`,
  [`API Type`]: `API 类型`,
  [`Phase`]: `阶段`,
  [`Enable CORS`]: `启用 CORS`,
  [`CORS Policy`]: `CORS 策略`,
  [`Allowed Origins`]: `允许的源`,
  [`Allow Credentials`]: `允许凭证`,
  [`ID`]: `标识`,
  [`Success Content`]: `成功内容`,
  [`Error Content`]: `错误内容`,
  [`Attachment`]: `附件`,
  [`Link`]: `链接`,
  [`Operation Ref`]: `操作引用`,
  [`TRACE`]: `跟踪`,
  [`No Security Requirements available`]: `没有安全需求可用`,
  [`Create a security scheme`]: `创建安全方案`,
  [`Create a Security Requirement`]: `创建安全需求`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `需要以下某个安全需求。如果未选中，安全性是可选的。`,
  [`Require wrappers on fault details specified by type`]: `需要按类型指定的故障详细信息包装程序`,
  [`There are no "Servers"`]: `没有任何“服务器”`,
  [`Write`]: `写`,
  [`Server Variable`]: `服务器变量`,
  [`Security Requirement`]: `安全需求`,
  [`Security Scheme Type`]: `安全方案类型`,
  [`Security Scheme`]: `安全方案`,
  [`OAuth Flows`]: `OAuth 流`,
  [`Implicit Flow`]: `隐式流程`,
  [`Password Flow`]: `密码流`,
  [`Client Credentials Flow`]: `客户机凭证流`,
  [`Authorization Code Flow`]: `授权代码流程`,
  [`Refresh URL`]: `刷新 URL`,
  [`schema`]: `模式`,
  [`Allow Empty Value`]: `允许空值`,
  [`Encoding`]: `编码`,
  [`This field is required`]: `该字段为必填`,
  [`Temporary token`]: `临时令牌`,
  [`Access token`]: `访问令牌`,
  [`ID token`]: `标识令牌`,
  [`No Security Schemes available`]: `没有安全方案可用`,
  [`Security Scheme (Key)`]: `安全方案（密钥）`,
  [`There are no "{label}"`]: `没有任何“{label}”`,
  [`You must select at least one scheme to define a Security Requirement`]: `必须至少选择一个方案才能定义安全需求`,
  [`Select one or more Security Schemes`]: `选择一个或多个安全方案`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `没有 OAuth 作用域可用于“{key}”安全方案`,
  [`Create a Scope`]: `创建作用域`,
  [`Remove this Security Requirement`]: `移除此安全需求`,
  [`Edit this Security Requirement`]: `编辑此安全需求`,
  [`Invalid Security Scheme Reference`]: `安全方案引用无效`,
  [`The following Security Schemes do not exist!`]: `以下安全方案不存在！`,
  [`Edit source`]: `编辑源`,
  [`OR`]: `或者`,
  [`Required Security Schemes`]: `必需的安全方案`,
  [`Optional Security`]: `可选安全性`,
  [`Optional custom expression (one per line)`]: `可选定制表达式（每行一个）`,
  [`Flow Type`]: `流类型`,
  [`OAuth2 Security Scheme`]: `OAuth2 安全方案`,
  [`Only source editing is supported.`]: `仅支持源编辑。`,
  [`Unsupported Field.`]: `不受支持的字段。`,
  [`Select an option`]: `选择一个选项`,
  [`Select {labelText}`]: `选择 {labelText}`,
  [`'"{changedValue}" is already used'`]: `已使用“{changedValue}”`,
  [`Referenced from "{originalRef}"`]: `已从“{originalRef}”引用`,
  [`Go to {label}`]: `转至 {label}`,
  [`Delete "{label}"`]: `删除“{label}”`,
  [`Are you sure you want to delete "{label}"?`]: `确定要删除“{label}”？`,
  [`No recent items found`]: `找不到最新项`,
  [`Found {numDocs} results ({took} seconds)`]: `已找到 {numDocs} 个结果（耗用 {took} 秒）`,
  [`No Consumer Organizations found`]: `未找到任何使用者组织`,
  [`No Catalog Products found`]: `未找到任何目录产品`,
  [`No APIs found`]: `未找到任何 API`,
  [`No Catalogs found`]: `未找到任何目录`,
  [`No Spaces found`]: `未找到任何空间`,
  [`No results found`]: `未找到任何结果`,
  [`No Catalog APIs found`]: `未找到任何目录 API`,
  [`No Catalogs found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的目录`,
  [`No APIs found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的 API`,
  [`No Subscriptions found`]: `未找到任何预订`,
  [`No Plans found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的计划`,
  [`No Subscriptions found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的预订`,
  [`No Catalog Products found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的目录产品`,
  [`No Catalog APIs found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的目录 API`,
  [`No Consumer Organizations found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的使用者组织`,
  [`No Applications found matching query "{query}"`]: `未找到任何与查询“{query}”匹配的应用程序`,
  [`APIs per page`]: `每页 API 数`,
  [`Catalogs per page`]: `每页目录数`,
  [`items per page`]: `每页项数`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} 个（共 {total} 个）API`,
  [`{min}-{max} of {total} API`]: `{min}-{max} 个 API，共 {total} 个 API`,
  [`Products per page`]: `每页产品数`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `应在目录上发布产品，以便能够配置新的计费集成`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} 个（共 {total} 个）产品`,
  [`{min}-{max} of {total} Product`]: `{min}-{max} 个产品，共 {total} 个产品`,
  [`Catalog Products per page`]: `每页目录产品数`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} 个（共 {total} 个）目录产品`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{max} 个产品，共 {total} 个目录产品`,
  [`Plans per page`]: `每页计划数`,
  [`Policies per page`]: `每页策略数`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} 个（共 {total} 个）计划`,
  [`{min}-{max} of {total} Plan`]: `{min}-{max} 个套餐，共 {total} 个套餐`,
  [`Catalog APIs per page`]: `每页目录 API 数`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} 个（共 {total} 个）目录 API`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{max} 个目录 API，共 {total} 个目录 API`,
  [`Subscriptions per page`]: `每页预订数`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} 个（共 {total} 个）预订`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{max} 个预订，共 {total} 个预订`,
  [`Consumer Organizations per page`]: `每页使用者组织数`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} 个（共 {total} 个）使用者组织`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{max} 个预订，共 {total} 个使用者组织`,
  [`Applications per page`]: `每页应用程序数`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} 个（共 {total} 个）应用程序`,
  [`{min}-{max} of {total} Application`]: `{min}-{max} 个应用程序，共 {total} 个应用程序`,
  [`Histories per page`]: `每页历史记录数`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} 条（共 {total} 条）历史记录`,
  [`{min}-{max} of {total} History`]: `{min}-{max} 个历史记录，共 {total} 个历史记录`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `未找到任何可预订的计划。产品必须处于“已发布”状态，然后才可以创建预订。应用程序只能预订产品中的一个计划。`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `正在显示已发布的“{draftApiTitle}：{draftApiVersion}”API`,
  [`Showing Consumer Organization for "{appTitle}"`]: `正在显示“{appTitle}”的使用者组织`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `正在显示“{productTitle}：{productVersion}”的预订`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `正在显示“{productTitle}：{productVersion}”的计划`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `正在显示“{productTitle}：{productVersion}”的 API`,
  [`Showing Product for "{planTitle}"`]: `正在显示“{planTitle}”的产品`,
  [`Showing APIs for "{planTitle}"`]: `正在显示“{planTitle}”的 API`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `正在显示产品“{docFoundIn}”中计划“{docTitle}”的预订`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `正在显示“{apiTitle}：{apiVersion}”的计划`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `正在显示“{catalogApiTitle}：{catalogApiVersion}”的草稿 API`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `正在显示“{productTitle}:{productVersion}”的历史记录`,
  [`Showing Subscriptions for "{appTitle}"`]: `正在显示“{appTitle}”的预订`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `正在显示“{consumerOrgTitle}”的应用程序`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `正在显示“{consumerOrgTitle}”的预订`,
  [`Showing Application for selected Subscription`]: `正在显示所选预订的应用程序`,
  [`Showing Plan for selected Subscription`]: `正在显示所选预订的计划`,
  [`Showing Product for selected Subscription`]: `正在显示所选预订的产品`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `选择要包含在“{productTitle}”产品中的草稿 API`,
  [`Product Version`]: `产品版本`,
  [`OAuth Providers`]: `OAuth 提供者`,
  [`Application Type`]: `应用程序类型`,
  [`Client IDs`]: `客户机标识`,
  [`Save as New Version`]: `另存为新版本`,
  [`Update Selected APIs`]: `更新所选的 API`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `此操作不可逆。将永久删除{titleAndVersion}API。是否要继续？`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `此操作不可逆。将永久删除{titleAndVersion}产品。是否要继续？`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `此操作不可逆。将永久删除{titleAndVersion}目录产品。是否要继续？`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `此操作不可逆。将永久删除{titleAndVersion}使用者组织。是否要继续？`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `此操作不可逆。 {titleAndVersion}使用者组将被永久删除。 要继续操作吗？`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `此操作不可逆。将永久删除{titleAndVersion}策略。是否要继续？`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `此操作不可逆。将永久删除{titleAndVersion}预订。是否要继续？`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `此操作不可逆。 {titleAndVersion}目录将被永久删除。 要继续操作吗？`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `此操作不可逆。 {titleAndVersion}空间将被永久删除。 要继续操作吗？`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `将重新发布 {titleAndVersion} 目录产品。是否要继续？`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `将撤销 {titleAndVersion} 目录产品。是否要继续？`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `将弃用 {titleAndVersion} 目录产品。是否要继续？`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `将重新登台 {titleAndVersion} 目录产品。是否要继续？`,
  [`Put Online`]: `联机`,
  [`Take Offline`]: `脱机`,
  [`View Subscriptions`]: `查看预订`,
  [`View Plans`]: `查看计划`,
  [`No Tasks found`]: `未找到任何任务`,
  [`Tasks per page`]: `每页任务数`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} 个（共 {total} 个）任务`,
  [`{min}-{max} of {total} Task`]: `{min}-{max} 个任务，共 {total} 个任务`,
  [`Decline and Send`]: `拒绝并发送`,
  [`View Applications`]: `查看应用程序`,
  [`Publish (Preserve Subscriptions)`]: `发布（保留预订）`,
  [`Update APIs`]: `更新 API`,
  [`View in Catalogs`]: `在目录中查看`,
  [`View Consumer Organization`]: `查看使用者组织`,
  [`View Application`]: `查看应用程序`,
  [`View Plan`]: `查看计划`,
  [`View Product`]: `查看产品`,
  [`Create Subscription`]: `创建预订`,
  [`No Applications found`]: `找不到应用程序`,
  [`Select a Plan to Create a Subscription to`]: `选择计划以创建预订`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `此操作不可逆。将永久删除{titleAndVersion}应用程序。是否要继续？`,
  [`Save as new version`]: `另存为新版本`,
  [`Filter selected catalogs`]: `过滤所选的目录`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `选择要包含在“{productTitle}”产品中的草稿 API`,
  [`Update selected APIs`]: `更新所选的 API`,
  [`Catalog API`]: `目录 API`,
  [`Catalog APIs`]: `目录 API`,
  [`View Products`]: `查看产品`,
  [`View Draft APIs`]: `查看草稿 API`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `显示引用“{apiTitle}：{apiVersion}”的产品`,
  [`No Plans found`]: `找不到计划`,
  [`View APIs`]: `查看 API`,
  [`Document type tabs`]: `文档类型选项卡`,
  [`No Products found`]: `找不到产品`,
  [`Consumer Organization`]: `使用者组织`,
  [`Consumer Organizations`]: `使用者组织`,
  [`Catalog Product`]: `目录产品`,
  [`Catalog Products`]: `目录产品`,
  [`Search for a Product and Plan`]: `搜索产品和计划`,
  [`OpenAPI Version`]: `OpenAPI 版本`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API（从 REST、GraphQL 或 SOAP）`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI（从 Kafka 主题）`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `创建 AsyncAPI（从 Kafka 主题）`,
  [`An error occurred during file upload`]: `文件上载期间发生错误`,
  [`Delete uploaded file`]: `删除上载的文件`,
  [`Copied!`]: `已复制！`,
  [`Uploading`]: `正在上载`,
  [`Validation icon`]: `“验证”图标`,
  [`Validation error`]: `验证错误`,
  [`Uploading image..`]: `正在上载图像..`,
  [`Unsupported media type {providedFileType}`]: `不受支持的介质类型 {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `选择受支持的文件 {expectedFileTypes}，然后重试`,
  [`Pasted`]: `已粘贴`,
  [`Enter the API summary details.`]: `输入 API 摘要详细信息。`,
  [`Define your Kafka cluster connection details.`]: `定义 Kafka 集群连接详细信息。`,
  [`Channel`]: `通道`,
  [`Describe your Kafka topic and its associated schema.`]: `描述 Kafka 主题及其关联的模式。`,
  [`AsyncAPI specification version`]: `AsyncAPI 规范版本`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `描述 API 并帮助开发人员在 Developer Portal 中发现 API 的名称。`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `API 定义的版本。名称和版本组合唯一标识 API 定义。`,
  [`Description (optional)`]: `描述（可选）`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `向使用 API 的开发人员介绍 API 并提供有用的信息。`,
  [`Summary (optional)`]: `摘要（可选）`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `此 API 中所包含事件的简短摘要。不超过 1000 个字符。`,
  [`Bootstrap servers`]: `引导程序服务器`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `集群中 Kafka 代理程序的列表，以逗号分隔。`,
  [`Secure the API using an API Key and Secret`]: `使用 API 密钥和私钥保护 API`,
  [`The credential provided should be in SASL-PLAIN format`]: `提供的凭证应采用 SASL-PLAIN 格式`,
  [`Publish this API:`]: `发布此 API：`,
  [`Creates a product`]: `创建产品`,
  [`Associates this API to the product`]: `将此 API 关联到产品`,
  [`Publish the product to the Sandbox catalog`]: `将产品发布至沙箱目录`,
  [`Associates the product to the test app`]: `将产品关联到测试应用程序`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `已成功上载并验证“{fileType}”格式的模式。`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `上载的文件不是有效的 Avro 模式，并且不会作为 API 的一部分包含在内。`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `服务器响应：“{err}”。单击“后退”可返回至 API 定义页面。`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `服务器响应：“{err}”。单击“完成”可退出向导。`,
  [`Topic name`]: `主题名称`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `想要作为此 API 的一部分公开的主题的名称。主题是已命名的消息流。`,
  [`Schema for message body in .avsc format (optional)`]: `.avsc 格式的消息主体的模式（可选）`,
  [`Drag and drop files here, or click to upload`]: `将文件拖放到此处，或单击以上载`,
  [`About this step`]: `关于此步骤`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `可将事件描述为异步 API，因为使用者在事件变为可用时接收事件。另一方面，同步 API（例如，REST）仅在请求时接收响应。`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `使用 AsyncAPI 规范记录事件驱动的异步 API。`,
  [`API secret`]: `API 私钥`,
  [`Generated AsyncAPI 2.1.0 definition`]: `已生成 AsyncAPI 2.1.0 定义`,
  [`API creating`]: `正在创建 API`,
  [`An error occurred while creating your document`]: `创建文档时发生错误`,
  [`Publishing your API`]: `发布 API`,
  [`Your API has been published`]: `您的 API 已发布`,
  [`An error occurred while publishing your API`]: `发布 API 时发生错误`,
  [`Configure security for this API`]: `配置此 API 的安全性`,
  [`Cluster connection security`]: `集群连接安全性`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `提供事件网关服务在处理对此 API 的请求时将用于连接集群的凭证。 您可以稍后在编辑器中更改这些值。 有关其他信息，请参阅关于创建凭证的文档。`,
  [`SASL Username`]: `SASL 用户名`,
  [`SASL Password`]: `SASL 密码`,
  [`Transport CA certificate (.pem file - optional)`]: `传输 CA 证书（.pem 文件 - 可选）`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `如果提供了此证书，那么事件网关服务将使用此证书来为网关与 Kafka 集群之间的通信提供 TLS 加密。`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `描述 Apache Kafka 事件源的 API 详细信息以生成 AsyncAPI 文档。`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `然后，您可以发布 AsyncAPI 文档以向应用程序开发人员公开事件源，以便他们可以将其应用程序配置为预订相关事件流。`,
  [`Set up security for your API if you want to control who can access it.`]: `如果要控制哪些人可以访问 API，请设置该 API 的安全性。`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `对 API 的访问将由事件网关服务进行管理，该服务将检查是否存在有效的 API 密钥和私钥。`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `网关要求客户机使用 TLS 来加密通信。`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `创建 API 后，单击“编辑 API”以添加更多详细信息，例如，用于从事件网关服务连接到 Kafka 集群的安全性详细信息。`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `然后，您可以发布 API 以供开发人员使用，这样，开发人员就能发现该 API 并请求对其进行访问，然后使用此信息将其应用程序设置为从事件源使用。`,
  [`Pending backend response`]: `暂挂后端响应`,
  [`Complete`]: `完成`,
  [`Rate limiting is only applied to enforced APIs`]: `速率限制仅应用于已实施的 API`,
  [`No Plans Available`]: `无可用计划`,
  [`Modal content is not provided`]: `未提供模态内容`,
  [`Modal heading is not provided`]: `未提供模态标题`,
  [`Edit Rate Limit`]: `编辑速率限制`,
  [`External Documentation`]: `外部文档`,
  [`No Label Available`]: `无可用标签`,
  [`optional`]: `可选`,
  [`Uploading image...`]: `正在上载图像...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `通过拖放、选择或粘贴来附加文件。`,
  [`Response name`]: `响应名称`,
  [`Click Add to add a new {resource}.`]: `单击“添加”可添加新的{resource}。`,
  [`Schema Name (Key)`]: `模式名称（键）`,
  [`Xml`]: `XML`,
  [`Namespace`]: `名称空间`,
  [`Attribute`]: `属性`,
  [`Wrapped`]: `已合并`,
  [`Example`]: `示例`,
  [`Example Name (Key)`]: `示例名称（键）`,
  [`"{changedValue}" is already used`]: `已使用“{changedValue}”`,
  [`Name (Key)`]: `名称（键）`,
  [`Content Type (Key)`]: `内容类型（键）`,
  [`Header name`]: `头名称`,
  [`Reference`]: `参考`,
  [`Security Scheme Name (Key)`]: `安全方案名称（键）`,
  [`Choose a Reference`]: `选择引用`,
  [`Reference from`]: `引用来源`,
  [`Content Type`]: `内容类型`,
  [`array`]: `数组`,
  [`boolean`]: `布尔`,
  [`integer`]: `整数`,
  [`number`]: `数字`,
  [`object`]: `对象`,
  [`string`]: `字符串`,
  [`query`]: `查询`,
  [`cookie`]: `cookie`,
  [`matrix`]: `矩阵`,
  [`label`]: `标签`,
  [`simple`]: `简单`,
  [`form`]: `表单`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth 提供者（名称）`,
  [`Authenticate using User Registry (name)`]: `使用用户注册表进行认证（名称）`,
  [`Bearer Format`]: `不记名格式`,
  [`Server Variable Name`]: `服务器变量名称`,
  [`Verb (Key)`]: `动词（键）`,
  [`Parameter Definitions`]: `参数定义`,
  [`Path Parameters`]: `路径参数`,
  [`Media Type List`]: `介质类型列表`,
  [`Medium`]: `中`,
  [`Schemes List`]: `模式列表`,
  [`Small`]: `小`,
  [`Response Definitions`]: `响应定义`,
  [`Terms Of Service`]: `服务条款`,
  [`Url`]: `URL`,
  [`Untitled Parameter`]: `无标题参数`,
  [`Parameter Name`]: `参数名称`,
  [`Mime Type`]: `MIME 类型`,
  [`Async API`]: `异步 API`,
  [`Channels`]: `通道`,
  [`Operation Traits`]: `操作特征`,
  [`Messages`]: `消息`,
  [`Correlation IDs`]: `相关标识`,
  [`Traits`]: `特征`,
  [`Message Traits`]: `消息特征`,
  [`Bindings`]: `绑定`,
  [`Server Bindings`]: `服务器绑定`,
  [`Channel Bindings`]: `通道绑定`,
  [`Operation Bindings`]: `操作绑定`,
  [`Message Bindings`]: `消息绑定`,
  [`Protocol Version`]: `协议版本`,
  [`Define multiple messages`]: `定义多条消息`,
  [`Schema Format`]: `模式格式`,
  [`Unsupported Field`]: `不受支持的字段`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `操作特征`,
  [`Message Trait`]: `消息特征`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `使用“操作切换”策略可将组合件的一部分应用于特定操作。`,
  [`HTML page`]: `HTML 页面`,
  [`Rule`]: `规则`,
  [`Origin`]: `源`,
  [`Property Name (Key)`]: `属性名称（键）`,
  [`Catalog Name (Key)`]: `目录名称（键）`,
  [`Property Overrides`]: `属性覆盖`,
  [`Property Name`]: `属性名`,
  [`Property Value`]: `属性值`,
  [`About subscriptions and plans`]: `关于预订和套餐`,
  [`Subscription Plan Name`]: `预订套餐名称`,
  [`Select plan`]: `选择套餐`,
  [`Select the plan to migrate application subscriptions from`]: `选择要从中迁移应用程序预订的套餐`,
  [`Select subscriptions`]: `选择预订`,
  [`Select the application subscriptions to migrate`]: `选择要迁移的应用程序预订`,
  [`Select the product to migrate application subscriptions to`]: `选择要将应用程序预订迁移至的产品`,
  [`Select the plan to migrate application subscriptions to`]: `选择要将应用程序预订迁移至的套餐`,
  [`Message successfully sent to {sent} owner(s)`]: `已成功将消息发送给 {sent} 个所有者`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} 条消息未能发送`,
  [`Failed email addresses`]: `失败的电子邮件地址`,
  [`Failed to save the API`]: `无法保存该 API`,
  [`and {numExtra} more...`]: `和另外 {numExtra} 个...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `将发送失败的电子邮件地址列表复制到剪贴板`,
  [`From`]: `来自`,
  [`Message Owner`]: `消息所有者`,
  [`Message owner`]: `消息所有者`,
  [`Message owners`]: `消息所有者`,
  [`Message group`]: `消息组`,
  [`Select consumer organizations`]: `选择使用者组织`,
  [`Message consumer organization owners`]: `消息使用者组织所有者`,
  [`Message consumer organization owner`]: `消息使用者组织所有者`,
  [`Message provider organization owner`]: `消息提供者组织所有者`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `与此调用策略相关的 Kafka 集群的标识`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `用于与 Kafka 集群建立连接的 host:port 对的逗号分隔列表`,
  [`The username to use when connecting to the Kafka cluster.`]: `连接到 Kafka 集群时要使用的用户名。`,
  [`The password to use when connecting to the Kafka cluster.`]: `连接到 Kafka 集群时要使用的密码。`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `事件网关服务`,
  [`Event gateway management client keystore`]: `事件网关管理客户机密钥库`,
  [`Edit Event gateway Service`]: `编辑事件网关服务`,
  [`Configure Event Gateway Service`]: `配置事件网关服务`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `配置事件网关服务以保护和实施异步 API`,
  [`Catalogs with published product`]: `包含已发布产品的目录`,
  [`Cluster Config ID`]: `集群配置标识`,
  [`SASL mechanism`]: `SASL 机制`,
  [`Security protocol`]: `安全协议`,
  [`Transport CA certificate`]: `传输 CA 证书`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `最大文件大小为 {size}。 支持的文件类型有 json、xml、yml、yaml 和 zip。`,
  [`Supported file types are json, xml, yml & yaml.`]: `支持的文件类型有 json、xml、yml 和 yaml。`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `支持的文件类型有 json、wsdl、xml、xsd、yml、yaml 和 zip。`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `支持的文件类型有 json、wsdl、xml、yml 和 yaml。`,
  [`Select a file`]: `选择文件`,
  [`or specify a file URL`]: `或指定文件 URL`,
  [`Generated OpenAPI {version} definition`]: `已生成 OpenAPI {version} 定义`,
  [`User registry type`]: `用户注册表类型`,
  [`User registry types`]: `用户注册表类型`,
  [`User registry type {title} has been created.`]: `已创建用户注册表类型 {title}。`,
  [`User registry type {title} has been updated.`]: `已更新用户注册表类型 {title}.`,
  [`Failed to create user registry type {title}.`]: `无法创建用户注册表类型 {title}。`,
  [`Failed to update user registry type {title}.`]: `无法更新用户注册表类型 {title}。`,
  [`Failed to update user registry {title}.`]: `无法更新用户注册表 {title}。`,
  [`Custom user registry`]: `定制用户注册表`,
  [`Create custom user registry`]: `创建定制用户注册表`,
  [`Edit custom user registry`]: `编辑定制用户注册表`,
  [`Create user registry type`]: `创建用户注册表类型`,
  [`Edit user registry type`]: `编辑用户注册表类型`,
  [`Configure user authentication using a custom external user registry`]: `使用定制外部用户注册表来配置用户认证`,
  [`Secured Endpoint`]: `受保护端点`,
  [`Configuration schema`]: `配置模式`,
  [`About custom user registries`]: `关于定制用户注册表`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `定制用户注册表可用于向 Developer Portal 认证用户，但不能用于保护 API。要在 API Manager 中将定制用户注册表配置为资源，必须创建外部用户目录并将其用于 API Connect 生态系统。`,
  [`Update Wsdl`]: `更新 Wsdl`,
  [`Update Existing WSDL service`]: `更新现有 WSDL 服务`,
  [`Format of selected WSDL has been successfully validated`]: `已成功验证所选 WSDL 的格式`,
  [`Select the target WSDL file to update from`]: `从以下位置选择要更新的目标 WSDL 文件：`,
  [`Selected WSDL has been successfully uploaded`]: `已成功上载所选的 WSDL`,
  [`Services available in selected API`]: `服务在所选 API 中可用`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `启用旧的 Open API Editor for OpenAPI 2.0 文档（不推荐）`,
  [`Unknown`]: `未知`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `由于与 API 或产品中指定的网关类型不兼容，因此隐藏了 {incompatibleCatalogCount} 个目录`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `由于与 API 或产品中指定的网关类型不兼容，因此隐藏了 {incompatibleSpacesCount} 个空间`,
  [`Keep in mind`]: `谨记`,
  [`Only one type of enforced APIs is allowed per product`]: `每个产品只允许实施一种类型的 API`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `由于与产品中指定的网关类型不兼容，因此无法选择 {incompatibleAPIsCount} 个 API`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `由于与 API 中指定的网关类型不兼容，因此无法选择 {incompatibleProductsCount} 个产品。`,
  [`Unenforced`]: `未实施`,
  [`Create a new product by adding APIs and plans.`]: `通过添加 API 和计划来创建新产品。`,
  [`Rate limits are only applied to enforced APIs.`]: `仅对已实施的 API 应用速率限制。`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `向产品添加计划限制和 API 限制。仅对已实施的 API 应用计划限制，未实施的 API 将不受限制。`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `向产品添加计划限制和速率限制。仅对已实施的 API 应用计划限制，未实施的 API 将不受限制。`,
  [`Delete plan`]: `删除计划`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `为 AsyncAPI 选择事件网关服务。对于所有其他类型的 API，请选择 DataPower API Gateway 或 V5 兼容版本。要更新此设置，请先移除相关的 API。`,
  [`Edit API list`]: `编辑 API 列表`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `此产品中已实施的 API 需要多种网关类型。`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `此产品中已实施的 {apiType} 需要 {gatewayType}。`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API`,
  [`AsyncAPI(s)`]: `AsyncAPI`,
  [`Select the gateway type for this Product`]: `为此产品选择网关类型`,
  [`Added default plan`]: `已添加缺省计划`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `将产品发布到沙箱目录。这会将该产品与测试应用程序相关联。`,
  [`cluster`]: `集群`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `来自拒绝者的消息`,
  [`Vendor extensions feature disabled`]: `供应商扩展功能已禁用`,
  [`No configured gateways found`]: `找不到配置的网关`,
  [`No OpenAPI extensions associated with the configured gateway`]: `没有与配置的网关关联的 OpenAPI 扩展`,
  [`Extension`]: `扩展`,
  [`Select the extensions to add to the API document`]: `选择要添加到 API 文档的扩展`,
  [`(Operations: {operations})`]: `（操作：{operations}）`,
  [`Failed to get application`]: `无法获取应用程序`,
  [`Failed to get credentials for application`]: `无法获取应用程序的凭证`,
  [`Failed to get published product`]: `无法获取已发布的产品`,
  [`Failed to get the published API`]: `无法获取已发布的 API`,
  [`API must be activated to use the Test tool`]: `API 必须激活才能使用测试工具`,
  [`Import from Asset repository`]: `从资产存储库导入`,
  [`Untitled {componentLabel}`]: `无标题 {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `您的许可证度量需要使用分析服务来跟踪使用情况。单击“注册服务”来配置一个分析服务。`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `所有 DataPower 网关服务都需要分析服务来跟踪使用情况。 单击每个网关的“关联分析服务”以遵守许可证条款。`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `目录与 API 或产品中指定的网关类型不兼容`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `空间与 API 或产品中指定的网关类型不兼容`,
  [`Preserve Subscription`]: `保留预订`,
  [`Publish validation`]: `发布验证`,
  [`Publish validations`]: `发布验证`,
  [`Edit publish validations`]: `编辑发布验证`,
  [`The following validations will be performed when publishing a product`]: `发布产品时将执行以下验证`,
  [`There are no publish validations enabled`]: `未启用任何发布验证`,
  [`Publish validations have been updated`]: `发布验证已更新`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `提供事件网关服务用于信任后端 Kafka 服务器证书的认证中心 (CA)。 如果服务器正在使用由著名的认证中心签发的证书，那么不需要提供 CA。`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `为引导程序服务器输入的值无效。 输入集群上每个 Kafka 代理的主机和端口，用逗号分隔。 例如，broker1.com:9092,broker2.com:9092,broker3.com:9092。`,
  [`Upload OpenAPI Extension`]: `上载 OpenAPI 扩展`,
  [`Add OpenAPI Extension`]: `添加 OpenAPI 扩展`,
  [`Gateway Endpoint`]: `网关端点`,
  [`API Dashboard`]: `API 仪表板`,
  [`API call data`]: `API 调用数据`,
  [`Product Dashboard`]: `产品仪表板`,
  [`API Product consumption metrics`]: `API 产品使用情况指标`,
  [`Monitoring Latency Dashboard`]: `监视等待时间仪表板`,
  [`Data about the time taken to serve API calls`]: `有关为 API 调用提供服务所用时间的数据`,
  [`Monitoring Status Dashboard`]: `监视状态仪表板`,
  [`API Error and success information`]: `API 错误和成功信息`,
  [`Usage Dashboard`]: `使用情况仪表板`,
  [`Usage for gateway {gateway}`]: `网关 {gateway} 的用法`,
  [`Latency for gateway {gateway}`]: `网关 {gateway} 的等待时间`,
  [`Most popular products, APIs and applications`]: `最受欢迎的产品、API 和应用程序`,
  [`Consumption Dashboard`]: `“使用”仪表板`,
  [`Total API volume useful for auditing and compliance`]: `可用于审计和合规性的 API 总量`,
  [`Monthly Summary by Status Code`]: `每月摘要（按状态码划分）`,
  [`Monthly Summary Data by Status Code`]: `每月摘要数据（按状态码划分）`,
  [`Daily Summary by Status Code`]: `每日摘要（按状态码划分）`,
  [`Daily Summary Data by Status Code`]: `每日摘要数据（按状态码划分）`,
  [`Response time (ms)`]: `响应时间（毫秒）`,
  [`Datetime`]: `日期时间`,
  [`Transaction ID:`]: `事务标识：`,
  [`Other`]: `其他`,
  [`Date time`]: `日期时间`,
  [`Total calls`]: `总调用数`,
  [`Date`]: `日期`,
  [`Month`]: `月`,
  [`Minimum response time`]: `最短响应时间`,
  [`Maximum response time`]: `最长响应时间`,
  [`Average response time`]: `平均响应时间`,
  [`Total API calls`]: `总 API 调用`,
  [`Total APIs`]: `API 总数`,
  [`APIs called`]: `已调用 API`,
  [`Total products`]: `产品总数`,
  [`products called`]: `产品名称`,
  [`Total errors`]: `总错误数`,
  [`Status codes (detailed)`]: `状态码（详细）`,
  [`Response time percentiles`]: `响应时间百分位数`,
  [`API calls per day`]: `每天的 API 调用次数`,
  [`Time zone:`]: `时区：`,
  [`Time zone`]: `时区`,
  [`Toggle axes`]: `切换轴`,
  [`Datetime:`]: `日期时间：`,
  [`datetime per 30 seconds`]: `每 30 秒的日期时间`,
  [`Datetime (local time)`]: `日期时间（本地时间）`,
  [`Data usage (bytes)`]: `数据使用（字节）`,
  [`Time to Serve Request`]: `向请求提供服务的时间`,
  [`API name`]: `API 名称`,
  [`Product name`]: `产品名称`,
  [`Data usage (bytes received)`]: `数据使用量（已接收的字节数）`,
  [`Data usage (bytes sent)`]: `数据使用量（已发送的字节数）`,
  [`Response times (>1s)`]: `响应时间（>1s）`,
  [`milliseconds`]: `毫秒`,
  [`errors`]: `错误`,
  [`Success Rate`]: `成功率`,
  [`Errors Data`]: `错误数据`,
  [`Success Data`]: `成功数据`,
  [`Total consumer organizations`]: `使用者组织总数`,
  [`making API calls`]: `发出 API 调用`,
  [`Applications per plan`]: `每个套餐的应用程序数`,
  [`Top products by calls`]: `按调用排名靠前的产品`,
  [`Top APIs by calls`]: `按调用排名靠前的 API`,
  [`Top client IP addresses by calls`]: `排名靠前的客户机 IP 地址（按调用）`,
  [`Client IP address`]: `客户机 IP 地址`,
  [`Top APIs by request size`]: `按请求大小排名靠前的 API`,
  [`Top APIs by response size`]: `按响应大小排名靠前的 API`,
  [`Top APIs by response time`]: `按响应时间排名靠前的 API`,
  [`Top applications by calls`]: `按调用排名靠前的应用程序`,
  [`Dashboards`]: `仪表板`,
  [`Discover`]: `发现`,
  [`UTC`]: `UTC`,
  [`Local time`]: `本地时间`,
  [`URI`]: `URI`,
  [`Response code`]: `响应代码`,
  [`Total time elapsed (ms)`]: `耗用的总时间（毫秒）`,
  [`Timestamp`]: `时间戳记`,
  [`Method`]: `方法`,
  [`Gateway IP`]: `网关 IP`,
  [`Gateway Operations Dashboard`]: `网关操作仪表板`,
  [`Usage and latency information per gateway`]: `每个网关的使用情况和等待时间信息`,
  [`Transaction ID`]: `事务标识`,
  [`Filters`]: `过滤器`,
  [`Fields`]: `字段`,
  [`Clear all`]: `清除所有`,
  [`API Event`]: `API 事件 (API Event)`,
  [`API Assembly Policy Latencies`]: `API 组合件策略等待时间`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `下图显示随请求通过 API 组合件策略的进展而增加的延迟时间（以毫秒为单位）。 这在确定 API 组合件中的堵点或瓶颈时很有帮助。`,
  [`Collapse`]: `折叠`,
  [`Expand`]: `扩展`,
  [`enter name`]: `输入名称`,
  [`Enter value`]: `输入值`,
  [`Time range`]: `时间范围`,
  [`Select one of the following time range filters`]: `选择下列其中一个时间范围过滤器`,
  [`Last 1 hour`]: `最近 1 小时`,
  [`Last {number} minutes`]: `最近 {number} 分钟`,
  [`Last {number} hours`]: `最近 {number} 小时`,
  [`Last {number} days`]: `最近 {number} 天`,
  [`Start date`]: `开始日期`,
  [`End date`]: `结束日期`,
  [`Select from the following dropdowns and enter a value`]: `从以下下拉列表中进行选择并输入值`,
  [`Select one of the following type filters`]: `选择下列其中一个类型过滤器`,
  [`All events`]: `所有事件`,
  [`Success only (2xx, 3xx)`]: `仅成功 (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `仅错误 (4xx, 5xx)`,
  [`Import/Export`]: `导入/导出`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `关于在 {time} 时生成的 {totalItems} 个结果（{searchTime} 秒）`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `关于 {time} ({utcTime}) 的 {totalItems} 个结果（{searchTime} 秒）`,
  [`API calls`]: `API 调用`,
  [`Export as CSV`]: `导出为 CSV`,
  [`Export as JSON`]: `导出为 JSON`,
  [`Export as PNG`]: `导出为 PNG`,
  [`Export as JPG`]: `导出为 JPG`,
  [`Edit filter`]: `编辑过滤器`,
  [`Edit query`]: `编辑查询`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `这是共享查询。 将对与其共享此查询的所有用户进行任何修改。`,
  [`This is a shared query. This will delete it for all users.`]: `这是共享查询。 这将针对所有用户删除此查询。`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `已为 {org} 提供者组织中的所有用户更新 {queryname}`,
  [`{queryname} has been updated for all users in the {space} space`]: `已为 {space} 空间中的所有用户更新 {queryname}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `已为 {catalog} 目录中的所有用户更新 {queryname}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `已为云管理器中的所有用户更新 {queryname}`,
  [`Stop sharing`]: `停止共享`,
  [`Share query`]: `共享查询`,
  [`Share filter`]: `共享过滤器`,
  [`You do not have permissions to delete this filter`]: `您无权删除此过滤器`,
  [`You do not have permissions to delete this query`]: `您无权删除此查询`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `确定要在 {org} 提供者组织作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `确定要在 {space} 空间作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `确定要在 {catalog} 目录作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `确定要在云作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `确定要停止共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `确定要停止在 {org} 提供者组织作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `确定要停止在 {space} 空间作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `确定要停止在 {catalog} 目录作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `确定要停止在云作用域共享查询 {queryTitle} 吗？`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `确定要删除查询 {queryTitle} 吗？`,
  [`At least one query parameter expected`]: `至少需要一个查询参数`,
  [`A provided query parameter is incomplete`]: `提供的查询参数不完整`,
  [`Could not parse query string`]: `无法解析查询字符串`,
  [`An unsupported query parameter was provided: {query}`]: `提供了不受支持的查询参数：{query}`,
  [`Invalid timeframe value: {value}`]: `无效时间范围值：{value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `{field} 值必须遵循 RFC 3339 第 5.6 节所定义的日期/时间表示法。 例如 2023、2023-01、2023-01-02、2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `字段 {field} 不支持运算符 {operator}`,
  [`The value of {field} must be a number`]: `{field} 的值必须是数字`,
  [`Delete query`]: `删除查询`,
  [`Delete filter`]: `删除过滤器`,
  [`Save query`]: `保存查询`,
  [`Save filter`]: `保存过滤器`,
  [`Save filter as`]: `将过滤器另存为`,
  [`Save query as`]: `将查询另存为`,
  [`Save as...`]: `另存为...`,
  [`Save as`]: `另存为`,
  [`Paste query string to load the query`]: `粘贴查询字符串以装入查询`,
  [`Copy or paste filter string to load filters`]: `复制或粘贴过滤器字符串以装入过滤器`,
  [`Query string`]: `查询字符串`,
  [`View filter`]: `查看过滤器`,
  [`View filter string`]: `查看过滤器字符串`,
  [`Date (local time)`]: `日期（本地时间）`,
  [`Coming soon, a new built in analytics feature`]: `即将推出全新的内置分析功能`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `分析将帮助收集对 API 运行时的洞察、管理服务级别、设置配额、建立控制、设置安全策略、管理社区和分析趋势。`,
  [`Documentation`]: `文档`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `深入了解 API 运行时数据类型，以及如何使用 AWS s3 存储区使之可视化。`,
  [`Blog`]: `博客`,
  [`Learn about what data is available today and how to access it.`]: `了解今天提供的数据以及如何访问这些数据。`,
  [`Next steps`]: `后续步骤`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `选定的 Secrets Manager 服务不管理任何证书。`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `需要证书来保护 API Connect 与网关管理端点的域之间的连接。 此外，还需要证书来保护网关与其处理的域之间的连接。 所有证书都必须存储在 Secrets Manager 服务中。 该服务为证书提供了一个安全的存储库，在与事件通知服务集成的情况下，通过在证书即将到期时向您发送通知来帮助防止中断。 要开始，`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `需要证书来保护网关与其处理的域之间的连接。 所有证书都必须存储在 Secrets Manager 服务中。 该服务为证书提供了一个安全的存储库，在与事件通知服务集成的情况下，通过在证书即将到期时向您发送通知来帮助防止中断。 要开始，`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `更改 Secrets Manager 将会除去所有现有证书和 CA 捆绑软件分配。`,
  [`Create a [Secrets Manager]({link}) service`]: `创建 [Secrets Manager]({link}) 服务`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `将必需的证书和 CA 捆绑软件导入到 Secrets Manager 服务中。`,
  [`No Secrets Manager services available`]: `没有可用的 Secrets Manager 服务`,
  [`Select Secrets Manager service`]: `选择 Secrets Manager 服务`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `选择用于管理此网关证书的 [Secrets Manager]({link}) 服务。`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Secrets Manager 服务必须管理网关管理端点的域以及网关所处理域的证书。 此外，您必须使用 IBM Cloud IAM 来授权 API Connect 保留实例服务访问 Secrets Manager 服务。`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `然后，选择管理端点的域的证书。 此外，如果证书并非由众所周知的认证中心未分配，还需选择对应的 CA 捆绑包。 该证书和捆绑软件必须由 Secrets Manager 服务进行管理。`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `然后，指定将由此网关处理的域。 我们使用 SNI 方法；因此，可通过同一地址和端口为多个域提供服务，而无需相同证书。 支持通配符格式。 “*”的缺省 (catch-all) 域必须作为表中的最后一个条目。 根据需要输入其他域名，在每种情况下，都指定由 Secrets Manager 服务管理的证书。`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `通过使用 [IBM Cloud IAM]({link})，授权 API Connect 保留实例服务访问 Secrets Manager 服务。`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `通过从 Secrets Manager 装入现有证书和 CA 捆绑软件分配，对其进行更新。`,
  [`Access s3 data`]: `访问 s3 数据`,
  [`Create resource`]: `创建资源`,
  [`Connect analytics data in s3 bucket with AWS`]: `使用 AWS 连接 s3 存储区中的分析数据`,
  [`AWS account`]: `AWS 帐户`,
  [`AWS account number`]: `AWS 帐号`,
  [`Enter your 12 digit aws account id`]: `输入 12 位数字的 aws 帐户标识`,
  [`Account id must be 12 digits`]: `帐户标识必须为 12 位数字`,
  [`Failed to update AWS account id.`]: `未能更新 AWS 帐户标识。`,
  [`You have limited access to the catalogs.`]: `您对目录具有有限的访问权。`,
  [`You have limited access to the spaces.`]: `对这些空间具有有限访问权。`,
  [`Contact admin to get more access.`]: `请联系管理员以获取更多访问权。`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `您无法选择此目录。 如果您需要访问权，请联系管理员。`,
  [`You can't select this space. Please contact an admin if you require access.`]: `您无法选择此空间。 如果您需要访问权，请联系管理员。`,
  [`You have limited access to the product billing.`]: `对产品计费的访问权有限。`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `已成功更新 AWS 帐户标识。 S3 数据访问授权可能需要几秒钟时间。`,
  [`An organization or a group is missing in the catalog chosen.`]: `所选目录中缺少组织或群组。`,
  [`Secrets Manager instance`]: `Secrets Manager 实例`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `无法选择缺少电子邮件所有者的消费者组织`,
  [`You currently don’t have any data.`]: `您当前没有任何数据。`,
  [`You currently don’t have any organizations.`]: `您当前没有任何组织。`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `请求预订应用程序 {appname} 以在产品 {prodname} 中规划 {plan}：{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `请求 {operation} 产品：{prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `请求将应用程序 {appname} 升级到生产环境`,
  [`Onboarding request for {email}`]: `{email} 的加入请求`,
  [`Product lifecycle request`]: `产品生命周期请求`,
  [`Subscription request`]: `预订请求`,
  [`Application lifecycle request`]: `应用程序生命周期请求`,
  [`Consumer Onboarding request`]: `使用者加入请求`,
  [`Originator`]: `发起方`,
  [`Edit analytics service advanced settings`]: `编辑分析服务高级设置`,
  [`Advanced settings`]: `高级设置`,
  [`Must be a positive integer`]: `必须是正整数`,
  [`Maximum value is {maxValue}`]: `最大值为 {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `最大值为 {maxValueDays}d 或 {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `配置分析部署的高级设置。`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `值必须以“d”（表示天）或“h”（表示小时）结尾。 例如，30d 或 12h。`,
  [`Rollover settings`]: `回滚设置`,
  [`Retention settings`]: `保留时间设置`,
  [`Delete an index when it meets the following condition:`]: `当索引满足以下条件时，请将其删除：`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `当受管索引满足下列其中一个条件时，将写别名回滚到新索引：`,
  [`Minimum document count`]: `最小文档计数`,
  [`The minimum number of documents required to roll over the index.`]: `滚动索引所需的最小文档数。`,
  [`Minimum index age`]: `最小索引寿命`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `滚动索引所需的最小索引寿命。 索引存在时间是创建索引到现在之间的时间。 使用“d”表示天，使用“h”表示小时。 例如，30d 表示一旦索引的寿命达到 30 天，就会对其进行回滚，而 96h 表示一旦索引的寿命达到 96 小时，就会对其进行回滚。 最大值为 {maxValueDays}d 或 {maxValueHours}h。`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `删除索引所需的最短索引寿命。 索引存在时间是创建索引到现在之间的时间。 使用“d”表示天，使用“h”表示小时。 例如，30d 表示一旦索引的寿命达到 30 天，就会将其删除，而 96h 表示一旦索引的寿命达到 96 小时，就会将其删除。 最大值为 {maxValueDays}d 或 {maxValueHours}h。`,
  [`Time range:`]: `时间范围：`,
  [`Time range: Before {datetime}`]: `时间范围：{datetime} 之前`,
  [`Time range: After {datetime}`]: `时间范围：{datetime} 之后`,
  [`Filter before`]: `过滤前`,
  [`Filter after`]: `过滤后`,
  [`Filter exact`]: `精确过滤`,
  [`Filter gt`]: `过滤 gt`,
  [`Filter lt`]: `过滤 lt`,
  [`Filter equals`]: `过滤器等于`,
  [`Filter by`]: `过滤依据`,
  [`Filter out`]: `滤出`,
  [`Delete failed`]: `删除失败`,
  [`Delete warning`]: `删除警告`,
  [`Could not delete saved query {queryTitle}.`]: `无法删除已保存的查询 {queryTitle}。`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `请求已成功响应，但 {queryTitle} 尚未完成删除。`,
  [`API governance`]: `API 监管`,
  [`Something's wrong`]: `发生错误`,
  [`We were unable to connect to the service.`]: `我们无法连接到服务。`,
  [`Invalid date`]: `失效日期`,
  [`Invalid time`]: `名称无效`,
  [`End date must be after the start date`]: `结束日期必须晚于开始日期`,
  [`Duplicate`]: `复制`,
  [`Duplicate query`]: `复制查询`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `确定要复制 {queryTitle} 吗？`,
  [`No queries saved`]: `未保存任何查询`,
  [`No queries shared`]: `未共享任何查询`,
  [`To save a query, start by creating one then save.`]: `要保存查询，请先创建一个查询，然后保存。`,
  [`Share a saved query and allow others to use it.`]: `共享已保存的查询并允许其他人使用该查询。`,
  [`Query updated`]: `已更新查询`,
  [`Query update failed`]: `查询更新失败`,
  [`Share successful`]: `共享成功`,
  [`Share failed`]: `共享失败`,
  [`Delete successful`]: `删除成功`,
  [`Query unshared`]: `已取消共享查询`,
  [`Unshare {queryTitle} failed`]: `取消共享 {queryTitle} 失败`,
  [`Query copied`]: `已复制查询`,
  [`Duplicate {queryTitle} failed`]: `复制 {queryTitle} 失败`,
  [`The query {queryTitle} has been updated.`]: `已更新查询 {queryTitle}。`,
  [`The query {queryTitle} has been shared.`]: `已共享查询 {queryTitle}。`,
  [`The query {queryTitle} has been deleted.`]: `已删除查询 {queryTitle}。`,
  [`The query {queryTitle} is no longer shared.`]: `不再共享查询 {queryTitle}。`,
  [`A copy of {queryTitle} has been created.`]: `已创建 {queryTitle} 的副本。`,
  [`About queries`]: `关于查询`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `查询是对仪表板和“发现”视图中使用的分析数据进行微调的一种方法。 应用查询时，将更新所有仪表板以仅包含所选数据。`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `可以使用某个预定义的时间范围，也可以通过“定制”选项来使用精确到最接近秒的特定时间范围。`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `可以使用字段指定仅包含特定数据。 几乎可以按 API 事件的任何属性进行过滤。`,
  [`There are then different operators available depending on the field type.`]: `根据字段类型，可以使用不同的运算符。`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `字符串字段允许使用“等于”、“不等于”、“开头为”、“结尾为”运算符以及正则表达式。`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `可以使用“包含”和“不包含”运算符来提供单个字段的值列表（例如 API 版本列表）。`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP 地址字段允许使用“等于”和“不等于”运算符，它们还允许以 CIDR 表示法指定值，以便使用网络掩码来选择特定网络。`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `正则表达式在选择特定值方面可能非常有用，但执行速度较慢，因此建议尽量使用其他运算符，例如“开头为”和“结尾为”。`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `可以使用逻辑 AND 运算符组合不同字段的查询过滤器。 这意味着，如果为同一字段名称添加两个过滤器，那么将仅返回同时满足这两个过滤器的结果。`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `{type} 查询参数是用于仅指定成功或错误事件的简单方法。 这将使用 {statusCode} 字段。 如果只需要特定的状态码，那么可以使用字段过滤器。 {statusCodeTwo} 字段的值通常是一个完整字符串（例如 {okStatus}），而不仅仅是数字 HTTP 返回码。`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `可以保存查询以供以后复用，也可以与当前作用域级别（例如，提供者组织或目录作用域）共享这些查询以供他人使用。`,
  [`n/a`]: `不适用`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `注：“提供者组织标识”和“提供者组织名称”字段将在事件有效内容中分别显示为 {{orgId}} 和 {{orgName}}；“使用者组织标识”和“使用者组织名称”字段将分别显示为 {{developerOrgId}} 和 {{developerOrgName}}。`,
  [`No authentication token`]: `无认证令牌`,
  [`Contact your administrator for further assistance.`]: `请与管理员联系以获取进一步帮助。`
};
