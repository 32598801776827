// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `O catálogo não foi atualizado`,
  [`Catalog has been updated`]: `O catálogo foi atualizado`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `O produto rascunho {draftProduct} deve referenciar uma ou mais APIs`,
  [`Number of Calls`]: `Número de chamadas`,
  [`Changes will apply to newly published APIs only`]: `As mudanças se aplicarão a APIs recém-publicadas apenas`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Existem {label} incompatíveis devido a eles terem um tipo de Gateway diferente para esta API.`,
  [`{label} is required`]: `{label} é obrigatório`,
  [`Application is required`]: `O aplicativo é necessário`,
  [`Plan is required`]: `O plano é necessário`,
  [`Gateway is required`]: `O gateway é necessário`,
  [`Consumer Org is required`]: `A organização do consumidor é necessária`,
  [`LDAP ATTRIBUTE NAME`]: `NOME DO ATRIBUTO LDAP`,
  [`Use the build in Test Application (sandbox only)`]: `Use a construção no Aplicativo de teste (somente ambiente de simulação)`,
  [`Select an Application`]: `Selecionar um Aplicativo`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Usar o Aplicativo de teste integrado (somente ambiente de simulação) - desativado quando um catálogo não relacionado ao ambiente de simulação é escolhido`,
  [`Choose an existing Application`]: `Escolha um Aplicativo existente`,
  [`Use JWT for gateway authentication to analytics service`]: `Use o JWT para autenticação de gateway para o serviço de análise de dados`,
  [`Select an application to consume the API`]: `Selecione um aplicativo para consumir a API`,
  [`Use the default built-in Sandbox Catalog'`]: `Use o Catálogo do ambiente de simulação integrado padrão'`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: este catálogo será usado como o escopo para todas as outras preferências. A mudança dessa configuração exigirá o ajuste de outras preferências.`,
  [`Select a Consumer Org`]: `Selecione uma organização do consumidor`,
  [`Select the Consumer Org your generated application will belong to`]: `Selecione a organização do consumidor à qual seu aplicativo gerado pertencerá`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Nota: esta opção pode ser alterada apenas ao usar o Aplicativo de teste do ambiente de simulação integrado, já que os aplicativos existentes já pertencerão a uma organização do consumidor`,
  [`Generate auto product`]: `Gerar produto automático`,
  [`Select a product to associate the current API with`]: `Selecione um produto ao qual associar a API atual`,
  [`Associate the current API to a selected product`]: `Associe a API atual a um produto selecionado`,
  [`API management`]: `Gerenciamento de API`,
  [`Select the API management instance you would like this API to be published to.`]: `Selecione a instância de gerenciamento de API na qual você gostaria que esta API fosse publicada.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: esta instância será usada como o escopo para todas as outras preferências. A mudança dessa configuração exigirá o ajuste de outras preferências.`,
  [`Provider organization`]: `Organização do Provedor`,
  [`Select the provider organization you would like this API to be published to.`]: `Selecione a organização do provedor na qual você gostaria que esta API fosse publicada.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: esta organização será usada como o escopo para todas as outras preferências. A mudança dessa configuração exigirá o ajuste de outras preferências.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Nota: este espaço será usado como o escopo para todas as outras preferências. A mudança dessa configuração exigirá o ajuste de outras preferências.`,
  [`Selected a draft product`]: `Um produto de rascunho foi selecionado`,
  [`Use any available gateway service`]: `Use qualquer serviço de gateway disponível`,
  [`Select a compatible gateway service`]: `Selecione um serviço de gateway compatível`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Nota: este serviço de gateway será usado como o escopo para todas as outras preferências. As políticas e outros conteúdos específicos do serviço de gateway serão carregados com base nessa configuração.`,
  [`Select a gateway`]: `Selecione um gateway`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Nota: esta opção é desativada quando a opção 'Produto existente' é selecionada e irá, em vez disso, usar o Plano Padrão para o produto gerado`,
  [`Select a Plan`]: `Selecione um plano`,
  [`Generate subscription`]: `Gerar assinatura`,
  [`A subscription will be created between the selected Product plan and Application`]: `Uma assinatura será criada entre o Plano do produto e o Aplicativo selecionados`,
  [`Apply a rate limit to the generated product`]: `Aplique um limite de taxa ao produto gerado`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Aplicar um limite de taxa ao Plano Padrão dos produtos gerados. Esta opção é desativada quando a opção 'Produto existente' é selecionada e irá, em vez disso, usar o limite de taxa definido no plano selecionado`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `O Lightweight Directory Access Protocol (LDAP) é um protocolo de Internet para acessar e manter serviços de informações de diretório distribuído em uma rede. Se possuir um serviço LDAP ativado, configure-o como um recurso para fornecer autenticação do usuário. Se você também deseja fornecer integração de usuário para novos usuários do Portal do Desenvolvedor, deve-se concluir a seção Mapeamento de atributo para ativar o LDAP gravável. Nesta seção, marque a caixa de seleção Gerenciado pelo usuário e forneça o mapeamento dos nomes do atributo do LDAP de origem para os valores do API Connect de destino.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `A ativação da autoaprovação de tarefas permite que tarefas sejam aprovadas por seu originador e também por colaboradores. Essa opção pode ser conveniente quando outros aprovadores não estão disponíveis, mas efetivamente permite que verificações por outro usuário sejam ignoradas.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Uma integração de faturamento conecta o API Connect a uma conta em um sistema de faturamento de assinatura de terceiros que gerencia os clientes e os métodos de pagamento, o faturamento e as assinaturas ativas deles`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Um objeto do provedor OAuth nativo fornece configurações para operações de processamento OAuth, como a geração e validação de tokens OAuth. Um objeto do provedor OAuth pode ser referenciado por uma definição de segurança OAuth para proteger uma API. Quando um provedor OAuth nativo é usado, as operações OAuth são executadas de forma nativa pelo API Connect. Cada objeto do provedor OAuth possui uma API de apoio. Sua configuração aqui atualiza automaticamente o documento Swagger da API. É possível editar o documento Swagger navegando para a página Editor da API.  Quando uma API publicada faz referência a um objeto do provedor OAuth, a API de apoio é automaticamente disponibilizada no gateway.`,
  [`About token management`]: `Sobre o gerenciamento de tokens`,
  [`About user security`]: `Sobre a segurança do usuário`,
  [`APIs or products`]: `APIs ou produtos`,
  [`ATM is not deployed, please contact your administrator`]: `O ATM não está implementado. Entre em contato com o seu administrador`,
  [`Application state change approvals will be enabled`]: `As aprovações de mudança no estado do aplicativo serão ativadas`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Defina as configurações a serem usadas para extrair as credenciais dos usuários do aplicativo, autentique as suas identidades e conceda autorização.`,
  [`See console for details.`]: `Consulte o console para obter detalhes.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `O serviço Certificate Manager selecionado não gerencia certificados.`,
  [`The selected product is not published to the Sandbox catalog`]: `O produto selecionado não está publicado no catálogo do Ambiente de Simulação`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `O produto selecionado não está publicado no catálogo do Ambiente de Simulação, portanto, não é possível assinar o aplicativo`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `A CLI do IBM Cloud para sua plataforma (se não estiver instalada anteriormente).`,
  [`(Only supported by TLS 1.3)`]: `(Suportado apenas pelo TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `A diretiva "@listSize" neste campo define os campos dimensionados que não retornam listas.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `A diretiva "@listSize" neste campo define os campos dimensionados não definidos no tipo de retorno do campo.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `A diretiva "@listSize" neste campo define argumentos de fatiamento não definidos no campo.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `A diretiva "@listSize" neste campo define argumentos de fatiamento cujos tipos não são "Int" ou "Int!".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `A diretiva "@listSize" neste campo define vários argumentos de fatiamento não nulos.`,
  [`"{name}" (Role) has been created`]: `"{name}" (função) foi criado.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (Função) foi atualizado.`,
  [`API ({api}) has been created.`]: `A API ({api}) foi criada.`,
  [`API ({api}) has been renamed.`]: `A API ({api}) foi renomeada.`,
  [`API ({api}) has been updated.`]: `A API ({api}) foi atualizada.`,
  [`API ({api}) has been deleted.`]: `A API ({api}) foi excluída.`,
  [`API ({api}) has been published.`]: `A API ({api}) foi publicada.`,
  [`API ({api}) has validation error.`]: `A API ({api}) tem um erro de validação`,
  [`API ({api}) has validation errors.`]: `A API ({api}) tem erros de validação`,
  [`API version`]: `Versão da API`,
  [`APIM data version`]: `Versão de dados de APIM`,
  [`APIM schema update date`]: `Data de atualização do esquema do APIM`,
  [`APIM schema version`]: `Versão de esquema do APIM`,
  [`APIM target data version`]: `Versão de dados de destino do APIM`,
  [`APIM target schema version`]: `Versão de esquema de destino do APIM`,
  [`APIM update date`]: `Data de atualização do APIM`,
  [`Product ({product}) has been created.`]: `O produto ({product}) foi criado.`,
  [`Product ({product}) has been updated.`]: `O produto ({product}) foi atualizado.`,
  [`Product ({product}) has been delete.`]: `O produto ({product}) foi excluído.`,
  [`Product ({product}) has been published.`]: `O produto ({product}) foi publicado.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) foi {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) não foi {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} após ser enfileirado)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} após ser enviado)`,
  [`(none)`]: `(nenhum)`,
  [`(optional)`]: `(opcional)`,
  [`*Base endpoint list empty`]: `*Lista de terminais de base vazia`,
  [`*Must be a valid url`]: `*Deve ser uma URL válida`,
  [`*System will also send an email notification to the requester.`]: `* O sistema também enviará uma notificação por e-mail para o solicitante.`,
  [`+ redact from...`]: `+ editar de...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. Também é possível gerenciar o ciclo de vida desse produto dentro do catálogo.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x seguido por 64 caracteres hexadecimais`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Possui e administra as organizações de consumidores da API 2) Gerencia as comunidades de desenvolvedores de aplicativos 3) Cria definições de API e de produto 4) Gerencia o ciclo de vida do produto de API`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Possui e administra organizações do provedor de API 2) Gerencia comunidades de desenvolvedores de aplicativos 3) Cria API e definições de produto 4) Gerencia o ciclo de vida do produto de API`,
  [`5 Most Active APIs`]: `Cinco APIs mais ativas`,
  [`5 Most Active Products`]: `Cinco produtos mais ativos`,
  [`5 most active APIs`]: `Cinco APIs mais ativas`,
  [`5 most active Products`]: `Cinco produtos mais ativos`,
  [`503 Service Unavailable`]: `503 Serviço indisponível`,
  [`A request to the server was made without any credentials.`]: `Uma solicitação para o servidor foi feita sem nenhuma credencial.`,
  [`A server error occurred`]: `Ocorreu um erro de servidor`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Um perfil de cliente TLS configura a cadeia de certificados e o conjunto de cifras usados pelo API Connect ao conectar-se como um cliente a outros sistemas.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Um perfil de servidor TLS configura a cadeia de certificados e o conjunto de cifras usados pelo API Connect ao apresentar terminais de servidor a outros sistemas.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Um catálogo hospeda uma coleção dos produtos de API que estão visíveis no Portal do Desenvolvedor associado no momento da publicação`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Quando publicados, um catálogo hospeda uma coleção dos produtos de API visíveis no portal associado do desenvolvedor.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Um catálogo representa uma coleção de produtos de API gerenciados. Os produtos publicados são visíveis para os consumidores no Portal do Desenvolvedor associado ao catálogo.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Um certificado é necessário para proteger a conexão entre o API Connect e o domínio do terminal de gerenciamento de gateway. Os certificados também são necessários para proteger as conexões entre o gateway e os domínios que ele manipula. Todos os certificados devem ser armazenados em um serviço Certificate Manager. O serviço fornece um repositório seguro para os certificados e ajuda a evitar indisponibilidades ao enviar notificações quando os certificados estão prestes a expirar. Para começar,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Um valor padrão que será usado se as entradas para o mapa não estiverem definidas.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Um serviço de gateway representa um conjunto de contêineres ou servidores gateway que hospedam APIs publicadas e fornecem os terminais de API usados pelos aplicativos cliente. Gateways executam chamadas de proxy de API para sistemas back-end e cumprem políticas de API, incluindo a identificação do cliente, a segurança e a limitação de taxa.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Um host gateway publica APIs e fornece os terminais de API usados por aplicativos clientes. Os gateways executam chamadas de proxy de API para sistemas back-end e impõem políticas de API, incluindo identificação de cliente, segurança e limitação de taxa.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Um destino de migração pode ser configurado em um produto de origem para definir o destino de migração para assinaturas.  O destino de migração inclui um mapeamento de planos que descreve o mapeamento dos planos no produto de origem para os planos no produto de destino.  O destino de migração fica visível no Portal do Desenvolvedor para comunicar o destino de migração para os assinantes do produto de origem.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Um kit de política do OAuth modular. Ele pode executar todas as etapas de protocolo do OAuth/OpenID Connect em uma política (padrão) ou pode ser dividido em múltiplas políticas para executar apenas um ou "n" números de etapas por vez para um controle mais fino. As entradas e saídas de cada uma das etapas são orientadas por variáveis de contexto documentadas. Inclua ou remova os Componentes do OAuth suportados conforme necessário.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Um portal não pode ser criado sem um serviço de portal disponível. Entre em contato com o administrador em nuvem para obter suporte.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Uma organização do provedor é uma equipe que possui APIs e os planos e produtos associados. Uma organização do provedor requer um proprietário que tenha todas as permissões. Os proprietários são designados dos membros.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Uma organização do provedor inclui usuários que gerenciam APIs, produtos, planos, catálogos, organizações do consumidor e outros recursos relacionados. É possível usar organizações do provedor para agrupar usuários e recursos de gerenciamento de API por projeto, departamento, estágio de ciclo de vida e assim por diante. Para gerenciar e designar funções que controlam privilégios de usuário, acesse o IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Uma expressão JavaScript simples que é resolvida com o valor da saída. Pode ser uma sequência estática ("minha sequência estática") ou uma expressão que envolve alguma entrada mapeada ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `AÇÃO`,
  [`ANALYTICS`]: `ANALÍTICA`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `Administrador de API`,
  [`API CONNECT VALUE`]: `VALOR DO API CONNECT`,
  [`API assemble`]: `Montagem da API`,
  [`API base endpoints`]: `Terminais de base de API`,
  [`API base URL`]: `URL base de API`,
  [`API base URLs`]: `URLs base de API`,
  [`API Calls`]: `Chamadas de API`,
  [`API Connect UI`]: `UI do API Connect`,
  [`API Connect services`]: `Serviços do API Connect`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `O API Connect requer um servidor de e-mail para enviar convites e links de ativação para membros e para outros propósitos.`,
  [`API Connect verify token`]: `Token de Verificação do API Connect`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `Licença do API Designer`,
  [`API Designer credentials`]: `Credenciais do API Designer`,
  [`API Designer licence`]: `Licença do API Designer`,
  [`API Designer does not currently support WSDL`]: `A API Designer não suporta WSDL no momento`,
  [`API editor`]: `Editor de API`,
  [`API Endpoint`]: `Terminal da API`,
  [`API Endpoint Base`]: `Base do Terminal da API`,
  [`API Endpoint for Unenforced APIs`]: `Terminal da API para APIs não aplicadas`,
  [`API Endpoint for unenforced APIs`]: `Terminal de API para APIs não aplicadas`,
  [`API Endpoints`]: `Terminais de API`,
  [`API Error`]: `Erro de API`,
  [`API gateway`]: `API Gateway`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API Gateways`,
  [`API Gateways`]: `API Gateways`,
  [`API Invocation Endpoint`]: `Terminal de Chamada de API`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `A API não foi publicada. O teste de API (Experimente) no Explorer não será ativado.`,
  [`API Key`]: `Chave de API`,
  [`API key`]: `Chave de API`,
  [`API key secret`]: `Segredo da chave de API`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `Registro do usuário local do gerente de API`,
  [`API Manager URL`]: `URL do API Manager`,
  [`API Management`]: `Gerenciamento de API`,
  [`API Path Parameters`]: `Parâmetros de caminho da API`,
  [`API Security Definition`]: `Definição de Segurança da API`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `O teste de API (Experimente) no Explorer não será ativado para esta API porque não é obrigatório.`,
  [`API Setup`]: `Configuração da API`,
  [`API Subscription`]: `Assinatura da API`,
  [`API endpoint`]: `Terminal da API`,
  [`API endpoint base`]: `Base do terminal de API`,
  [`API endpoint for unenforced APIs`]: `Terminal de API para APIs não aplicadas`,
  [`API endpoints`]: `Terminais de API`,
  [`API error`]: `erro de API`,
  [`API invocation endpoint`]: `Terminal de chamada de API`,
  [`API is not enforced on the API gateway.`]: `A API não foi aplicada ao gateway de API.`,
  [`API path parameters`]: `Parâmetros de caminho da API`,
  [`API security definition`]: `Definição de segurança da API`,
  [`API setup`]: `Configuração da API`,
  [`API subscription`]: `Assinatura da API`,
  [`API Update`]: `Atualização da API`,
  [`API user registries`]: `Registros do usuário da API`,
  [`API and product generated successfully!`]: `API e produto gerados com sucesso!`,
  [`API deletion failed`]: `Falha na exclusão da API`,
  [`API does not exist!`]: `A API não existe!`,
  [`API from existing REST service`]: `API do serviço REST existente`,
  [`API from existing SOAP service`]: `API do serviço SOAP existente`,
  [`API has been updated.`]: `A API foi atualizada.`,
  [`API has been updated`]: `A API foi atualizada`,
  [`API has not been created!`]: `A API não foi criada!`,
  [`API has not been updated!`]: `A API não foi atualizada!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `Prioridade de API (usar terminal(is) personalizado(s) definido(s) nas definições de OpenAPI)`,
  [`API properties`]: `Propriedades da API`,
  [`API protection source`]: `Origem da proteção da API`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `A API referenciada a partir do produto não existe. Crie ou importe a API primeiro.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `As estruturas de carga útil de solicitação e resposta da API são compostas utilizando definições de esquema OpenAPI.`,
  [`API subscription request approved for app {{appName}}`]: `Solicitação de assinatura da API aprovada para o aplicativo  {appName}`,
  [`API subscription request denied for app {{appName}}`]: `A solicitação de assinatura da API foi negada para o aplicativo {appName}`,
  [`API subscription request received for app {{appName}}`]: `Solicitação de assinatura de API recebida para o aplicativo {appName}`,
  [`API type`]: `Tipo de API`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `A API com a chave ''{key}'' na propriedade 'apis' não existe e não pode ser listada. Verifique a guia Origem.`,
  [`APIs`]: `APIs`,
  [`APIs and products`]: `APIs e produtos`,
  [`APIs included in the plan.`]: `APIs incluídas no plano.`,
  [`API Key is created`]: `A chave de API é criada`,
  [`API Key is deleted`]: `A chave de API é excluída`,
  [`API Key is successfully created`]: `A chave de API é criada com sucesso`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `O tempo limite da chave de API é **{seconds} segundos ({time})**`,
  [`API key timeout`]: `Tempo limite da chave de API`,
  [`API Key timeout has been changed`]: `O tempo limite da chave de API foi mudado`,
  [`Application type`]: `Tipo de Aplicativo`,
  [`Associated analytics service`]: `Serviço de Analítica associado`,
  [`AVAILABILITY`]: `DISPONIBILIDADE`,
  [`AVAILABILITY ZONE`]: `ZONA DE DISPONIBILIDADE`,
  [`About`]: `Sobre`,
  [`About audit setting`]: `Sobre a configuração de auditoria`,
  [`About Authentication URL user registry`]: `Sobre o registro do usuário da URL de autenticação`,
  [`About availability zones`]: `Sobre zonas de disponibilidade`,
  [`About catalogs`]: `Sobre os catálogos`,
  [`About consumer organization owners`]: `Sobre os proprietários da organização do consumidor`,
  [`About consumer organizations`]: `Sobre as organizações do consumidor`,
  [`About developer portals`]: `Sobre os Portais do Desenvolvedor`,
  [`About email servers`]: `Sobre os servidores de e-mail`,
  [`About Endpoints`]: `Sobre terminais`,
  [`About gateways`]: `Sobre gateways`,
  [`About gateway visibility`]: `Sobre a visibilidade do gateway`,
  [`About Gateway Processing Status`]: `Sobre o status de processamento do gateway`,
  [`About introspection`]: `Sobre a introspecção`,
  [`About Keystores`]: `Sobre Armazenamentos de chaves`,
  [`About LDAP`]: `Sobre o LDAP`,
  [`About local user registries`]: `Sobre os registros de usuários locais`,
  [`About metadata`]: `Sobre os Metadados`,
  [`About Migration Target`]: `Sobre o destino de migração`,
  [`About migration targets`]: `Sobre os destinos de migração`,
  [`About native OAuth provider`]: `Sobre o provedor OAuth nativo`,
  [`About OpenID Connect`]: `Sobre a conexão OpenID`,
  [`About replacing a product`]: `Sobre a substituição de um produto`,
  [`About Role defaults`]: `Sobre os padrões de função`,
  [`About roles`]: `Sobre as Funções`,
  [`About scopes`]: `Sobre os escopos`,
  [`About Set Migration Targets`]: `Sobre a configuração de destinos de migração`,
  [`About spaces`]: `Sobre os espaços`,
  [`About Subscription`]: `Sobre a assinatura`,
  [`About superseding a product`]: `Sobre a substituição de um produto`,
  [`About TLS server profile`]: `Sobre o perfil do servidor TLS`,
  [`About TLS client profiles`]: `Sobre Perfis do Cliente TLS`,
  [`About TLS server profiles`]: `Sobre perfis de servidor TLS`,
  [`About third party OAuth provider`]: `Sobre o provedor OAuth de terceiros`,
  [`About tokens`]: `Sobre os tokens`,
  [`About Transfer Ownership`]: `Sobre a Propriedade de Transferência`,
  [`About Transferring Ownership`]: `Sobre a transferência de propriedade`,
  [`About Truststores`]: `Sobre os armazenamentos confiáveis`,
  [`About adding a member`]: `Sobre incluir um membro`,
  [`About adding provider organization member`]: `Sobre inclusão de membro da organização do provedor`,
  [`About adding members to a Space`]: `Sobre incluir membros em um Espaço`,
  [`About adding members to a catalog`]: `Sobre a inclusão de membros em um catálogo`,
  [`About changing owners`]: `Sobre mudanças de proprietário`,
  [`About enabling gateway services`]: `Sobre a ativação de serviços de gateway`,
  [`About inviting a member`]: `Sobre convidar um membro`,
  [`About inviting catalog members`]: `Sobre o convite de membros para o catálogo`,
  [`About inviting provider organization members`]: `Sobre convidar membros da organização do provedor`,
  [`About inviting space members`]: `Sobre o convite a membros do espaço`,
  [`About keystores`]: `Sobre Armazenamentos de chaves`,
  [`About managing Space membership`]: `Sobre gerenciar a associação a espaços`,
  [`About migrating subscriptions`]: `Sobre a migração de assinaturas`,
  [`About provider organizations`]: `Sobre as organizações do provedor`,
  [`About roles and role defaults`]: `Sobre funções e padrões de função`,
  [`About roles in spaces`]: `Sobre funções em espaços`,
  [`About selecting gateway services`]: `Sobre a seleção de serviços de gateway`,
  [`About send message`]: `Sobre o envio de mensagem`,
  [`About sending messages`]: `Sobre o envio de mensagens`,
  [`About the analytics service`]: `Sobre o serviço de Analítica`,
  [`About the DNS Scheme`]: `Sobre o Esquema de DNS`,
  [`About the Gateway Service`]: `Sobre o Serviço de Gateway`,
  [`About the Portal Service`]: `Sobre o Serviço de Portal`,
  [`About the product lifecycle`]: `Sobre o ciclo de vida do produto`,
  [`About the provider organization owner`]: `Sobre o proprietário da organização do provedor`,
  [`About this Cloud`]: `Sobre esta nuvem`,
  [`About transferring ownership`]: `Sobre a transferência de propriedade`,
  [`About truststores`]: `Sobre armazenamentos confiáveis`,
  [`About visibility`]: `Sobre a visibilidade`,
  [`About visibility and subscribability`]: `Sobre a visibilidade e a capacidade de assinatura`,
  [`Accept`]: `Aceitar`,
  [`Access Code`]: `Código de acesso`,
  [`Access Token`]: `Token de acesso`,
  [`Access URL through Secure Gateway`]: `Acessar URL por meio do Secure Gateway`,
  [`Access code`]: `Código de acesso`,
  [`Access code is not chosen in supported grant type.`]: `O código de acesso não é escolhido no tipo de concessão suportado.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Acesse a URL por meio de um Secure Gateway. O gateway é configurado quando a API é movida do estado montado para o estado publicado.`,
  [`Access token TTL has been changed`]: `O TTL do token de acesso foi mudado`,
  [`Access token time to live`]: `Tempo de vida do token de acesso`,
  [`Access token time-to-live`]: `Tempo de vida do token de acesso`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `O token de acesso expirará em **{seconds} segundos ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Os tokens de acesso são concedidos ao aplicativo cliente para permitir que o aplicativo acesse recursos em nome do usuário do aplicativo.  Os tokens de atualização são emitidos para o cliente para obter um novo token de acesso quando o token de acesso atual torna-se inválido ou expira, ou para obter tokens de acesso adicionais com escopo idêntico ou menor. Também é possível especificar por quanto tempo o consentimento obtido pela combinação de qualquer número de acessos e do token de atualização permanece válido.`,
  [`Access tokens time to live (seconds)`]: `Tempo de vida dos tokens de acesso (segundos)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `O token de acesso, o token de ID e o token Temporário podem ser atribuídos a qualquer um dos Keystores existentes. O histórico de atribuição de Keystore é preservado.`,
  [`Account`]: `Conta`,
  [`Account has been updated.`]: `A conta foi atualizada.`,
  [`Account password has been changed.`]: `A senha da conta foi mudada.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Ação`,
  [`Activate API`]: `Ativar API`,
  [`Activate API|button text`]: `Ativar API`,
  [`Activate API for testing|title`]: `Ativar API`,
  [`Activation link`]: `Link de ativação`,
  [`Active`]: `Ativo`,
  [`Activity log`]: `Log de atividades`,
  [`Activity log API setting is not available for this API's gateway type.`]: `A configuração da API do log de atividades não está disponível para o tipo de gateway desta API.`,
  [`Active loading indicator`]: `Indicador de carregamento ativo`,
  [`Add`]: `Incluir`,
  [`Add allowlist`]: `Incluir lista de permissões`,
  [`Add a new billing integration in Resources`]: `Inclua uma nova integração de faturamento em Recursos`,
  [`Add API user registries`]: `Incluir registros de usuários da API`,
  [`Add APIs to Product`]: `Incluir APIs no produto`,
  [`Add another cloud`]: `Incluir outra nuvem`,
  [`Add billing integration`]: `Incluir integração de faturamento`,
  [`Add Burst Limit`]: `Incluir limite de distribuição`,
  [`Add Ciphers for TLS client profile`]: `Incluir cifras no perfil do cliente TLS`,
  [`Add Ciphers for TLS server profile`]: `Incluir cifras no perfil do servidor TLS`,
  [`Add Condition`]: `Adicionar condição`,
  [`Add Consumer Role`]: `Incluir Função de Consumidor`,
  [`Add Count Limit`]: `Incluir limite de contagem`,
  [`Add Credential`]: `Incluir Credencial`,
  [`Add domain`]: `Incluir domínio`,
  [`Add credential`]: `Incluir credencial`,
  [`Add Extension`]: `Adicionar Extensão`,
  [`Add GraphQL schema`]: `Incluir o esquema GraphQL`,
  [`Add HTTP Endpoint`]: `Incluir terminal HTTP`,
  [`Add group`]: `Incluir Grupo`,
  [`Add JSON schema`]: `Incluir esquema JSON`,
  [`Add Keystore to TLS client profile`]: `Incluir keystore no perfil do cliente TLS`,
  [`Add Keystore to TLS server profile`]: `Incluir keystore no perfil do servidor TLS`,
  [`Add member`]: `Incluir membro`,
  [`Add New Role for Consumer organization`]: `Incluir nova função para a organização do consumidor`,
  [`Add operation`]: `Operação Incluir`,
  [`Add Parameters`]: `Incluir Parâmetros`,
  [`Add Policy`]: `Política de Inclusão`,
  [`Add Rate limit`]: `Incluir limite de taxa`,
  [`Add Rate Limit`]: `Incluir limite de taxa`,
  [`Add role`]: `Incluir função`,
  [`Add Syslog TCP Endpoint`]: `Incluir terminal TCP Syslog`,
  [`Add Syslog TLS Endpoint`]: `Incluir terminal TLS Syslog`,
  [`Add Syslog UDP Endpoint`]: `Incluir terminal UDP Syslog`,
  [`Add XML schema`]: `Incluir esquema XML`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Inclua um terminal de Gateway que você deseja disponibilizar para chamadas para APIs nesse Catálogo.`,
  [`Add a different Cloud Connection.`]: `Inclua uma conexão em nuvem diferente.`,
  [`Add a member from the user registry`]: `Inclua um membro do registro do usuário`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Inclua um usuário na organização do Administrador e designe as funções necessárias`,
  [`Add a user to the provider organization, and assign the required roles`]: `Inclua um usuário na organização do provedor e designe as funções necessárias`,
  [`Add a user to the Catalog, and assign the required roles`]: `Inclua um usuário no Catálogo e designe as funções necessárias`,
  [`Add a user to the Space, and assign the required roles`]: `Inclua um usuário no Espaço e designe as funções necessárias`,
  [`Add action`]: `Incluir ação`,
  [`Add additional client ID/client secret pairs`]: `Incluir pares adicionais de ID do cliente/segredo do cliente`,
  [`Add blocklist`]: `Incluir lista de bloqueios`,
  [`Add case`]: `Incluir caso`,
  [`Add catch`]: `Incluir captura`,
  [`Add default catch`]: `Incluir captura padrão`,
  [`Add destination`]: `Incluir destino`,
  [`Add entry`]: `Adicionar Entrada`,
  [`Add group failed.`]: `Inclusão do grupo com falha.`,
  [`Add input`]: `Incluir entrada`,
  [`Add media type`]: `Incluir tipo de mídia`,
  [`Add member failed`]: `Falha na inclusão do membro`,
  [`Add object`]: `Incluir objeto`,
  [`Add otherwise`]: `Incluir em caso contrário`,
  [`Add output`]: `Incluir saída`,
  [`Add outputs for operation...`]: `Incluir saídas para a operação...`,
  [`Add parameters for operation...`]: `Incluir parâmetros para a operação...`,
  [`Add parameters to this API`]: `Inclua parâmetros para esta API`,
  [`Add plans to product`]: `Incluir planos no produto`,
  [`Add plans to this product`]: `Incluir planos para este produto`,
  [`Add schema`]: `Incluir esquema`,
  [`Add scopes for this OAuth provider.`]: `Inclua escopos para esse provedor OAuth.`,
  [`Add scopes to allow access to`]: `Incluir escopos para permitir o acesso a`,
  [`Add scopes to allow access to.`]: `Inclua escopos para os quais permitir o acesso.`,
  [`Add tags and external documentation details for this API`]: `Incluir tags e detalhes da documentação externa para esta API`,
  [`Add to`]: `Incluir em`,
  [`Add to existing product`]: `Incluir no produto existente`,
  [`Add/Remove APIs`]: `Incluir/Remover APIs`,
  [`AddProperties`]: `AddProperties`,
  [`Added APIs`]: `APIs incluídas`,
  [`Added rate limit`]: `Limite de taxa incluído`,
  [`Added rate limits`]: `Limites de taxa incluídos`,
  [`Adding an API to a product for publishing`]: `Incluindo uma API para um produto para publicação`,
  [`Additional support`]: `Suporte adicional`,
  [`Additional properties`]: `Propriedades adicionais`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Além disso, considere a remoção do registro associado, Registro do usuário do catálogo do {name}, se ele não estiver em uso.`,
  [`Address`]: `Endereço`,
  [`Admin DN`]: `DN Admin`,
  [`Admin organization invitation timeout`]: `Tempo limite do convite da organização do administrador`,
  [`Admin organization invitation timeout has been changed`]: `O tempo limite do convite da organização do administrador foi mudado`,
  [`Admin password`]: `Senha do Administrador`,
  [`Admin request reset password`]: `Solicitação de reconfiguração de senha do administrador`,
  [`Admin reset password`]: `Reconfiguração de senha do administrador`,
  [`Admin Sign In`]: `Conexão do administrador`,
  [`Admin add catalog failed`]: `Falha na inclusão do catálogo do administrador`,
  [`Admin add space failed`]: `Falha ao adicionar espaço do administrador`,
  [`Administer consumer organizations`]: `Administrar organizações de consumidores`,
  [`Administers the API consumer organization`]: `Administra a organização do consumidor de API`,
  [`Administers the API provider organization`]: `Administra a organização do provedor de API`,
  [`Administers the admin organization`]: `Administra a organização do administrador`,
  [`Administers the admin topology`]: `Administra a topologia do administrador`,
  [`Administers the app developer organization`]: `Administra a organização do desenvolvedor de aplicativo`,
  [`Administers the catalog`]: `Administra o catálogo`,
  [`Administers the cloud topology`]: `Administra a topologia de nuvem`,
  [`Administers the space`]: `Administra o espaço`,
  [`Administration management console`]: `Console de gerenciamento de administração`,
  [`Administrator`]: `Administrador`,
  [`Advanced analytics configuration`]: `Configuração de analítica avançada`,
  [`Advanced features`]: `Recursos Avançados`,
  [`Advanced scope check`]: `Verificação avançada de escopo`,
  [`Advanced scope check after Token Validation`]: `Verificação avançada de escopo após a validação do token`,
  [`Advanced scope check before Token Generation`]: `Verificação avançada de escopo antes da geração de token`,
  [`Aggregation`]: `Agregado`,
  [`Agree`]: `Concorda`,
  [`All`]: `Todas`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Todos os desenvolvedores autenticados em organizações de consumidores que se inscreveram para o Portal do Desenvolvedor podem ver este produto.`,
  [`All consumer organizations will be able to see this product.`]: `Todas as organizações de consumidores poderão ver este produto.`,
  [`Allowlist`]: `Lista de permissões`,
  [`Allow Chunked Uploads`]: `Permitir uploads em partes`,
  [`Allow chunked uploads`]: `Permitir uploads em partes`,
  [`Allow "plain" challenge method`]: `Permitir método de desafio "simples"`,
  [`Allow renegotiation`]: `Permitir a renegociação`,
  [`Allow case sensitive usernames`]: `Permitir nomes de usuário com distinção entre maiúsculas e minúsculas`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Permita que clientes injetem uma extensão SNI com o nome do host desejado em seu handshake inicial com o servidor.`,
  [`Allow connection to be renegotiated`]: `Permitir que a conexão seja renegociada`,
  [`Allow default introspection`]: `Permitir introspecção padrão`,
  [`Allow insecure server connections`]: `Permitir conexões inseguras de servidor`,
  [`Allow null value`]: `Permitir valor nulo`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Permita que o access_token/refresh_token envie redirecionamentos 302 para logout`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Todas as recomendações foram aplicadas. Não há avisos para o seu esquema.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Permita que as operações da API sejam testadas usando as ferramentas de teste no Portal do Desenvolvedor.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Permitir que a conexão continue com credenciais inseguras ou fracas`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Permita que a conexão continue com credenciais fracas ou não seguras.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Permita que o provedor OIDC externo se comunique com o Portal do Desenvolvedor, não com o API Manager.`,
  [`Allow to manage this user registry`]: `Permitir o gerenciamento deste registro do usuário`,
  [`Allow to manage users under this user registry`]: `Permitir o gerenciamento de usuários sob este registro do usuário`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Permita que os usuários sejam integrados automaticamente quando o APIC for apresentado com um token emitido pelo emissor`,
  [`Already have an account?`]: `Já possui uma conta?`,
  [`Also transfer ownership of owned Spaces`]: `Transferir também a propriedade de espaços proprietários`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Sempre busque dados do usuário do terminal de userinfo, quando habilitado`,
  [`Always output the root element`]: `Sempre exibir elemento raiz`,
  [`Always output the root element.`]: `Sempre exibir o elemento raiz.`,
  [`Always required`]: `Sempre necessário`,
  [`Always use userinfo endpoint`]: `Sempre usar o terminal de userinfo`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Uma API do provedor OAuth contém os terminais de autorização e token de um fluxo OAuth. Configure seus provedores OAuth aqui; em seguida, quando você cria uma API protegida pelo OAuth, é possível selecionar o provedor necessário.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Um provedor OAuth contém os terminais de autorização e de token de um fluxo OAuth; selecione o provedor OAuth que você deseja usar para proteger sua API com OAuth`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Um aplicativo é listado aqui quando um desenvolvedor o inscreve em um plano no espaço para que ele possa chamar as APIs associadas; é possível suspender um aplicativo do desenvolvedor para bloquear seu acesso às APIs e também é possível criar seus próprios aplicativos. [Saiba mais]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Uma URL de autenticação aponta para um provedor de autenticação de terceiros como o registro do usuário. Uma URL de autenticação permite a integração com um registro do usuário de terceiro além do LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Uma URL de autenticação fornece um mecanismo simples para a autenticação de usuários com relação a um provedor de identidade customizado.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Ocorreu um erro ao estabelecer comunicação com o subsistema de gateways.`,
  [`An organization is required.`]: `Uma organização é necessária.`,
  [`An unknown error occurred.`]: `Ocorreu um erro desconhecido.`,
  [`Analytics`]: `Análise de dados`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Serviço Analytics Insights`,
  [`Analytics Details`]: `Detalhes de analítica`,
  [`Analytics destinations`]: `Destinos de analítica`,
  [`Analytics Director keystore`]: `Keystore do Analytics Director`,
  [`Analytics Service`]: `Serviço de analítica`,
  [`Analytics Service URL`]: `URL do Serviço de analítica`,
  [`Analytics Service {arg} has been removed.`]: `O serviço de analítica {arg} foi removido.`,
  [`Analytics details`]: `Detalhes de analítica`,
  [`Analytics service`]: `Serviço de Analítica`,
  [`Analytics service details`]: `Detalhes do serviço de análise`,
  [`Analytics service URL`]: `URL do serviço de Analítica`,
  [`Analytics service {arg} has been removed.`]: `O serviço de Analítica {arg} foi removido.`,
  [`Analytics client TLS client profile`]: `Perfil do cliente TLS de cliente de analítica`,
  [`Analytics client keystore`]: `Keystore de cliente de analítica`,
  [`Analytics client truststore`]: `Armazenamento confiável de cliente de analítica`,
  [`Analytics ingestion TLS client profile`]: `Perfil do cliente TLS de ingestão de analítica`,
  [`Analytics ingestion keystore`]: `Keystore de ingestão de analítica`,
  [`Analytics ingestion truststore`]: `Armazenamento confiável de ingestão de analítica`,
  [`Analytics service {title} has been created.`]: `O serviço de Analítica {title} foi criado.`,
  [`Analytics service {title} has been updated.`]: `O serviço de Analítica {title} foi atualizado.`,
  [`The selected consumer organization is`]: `A organização do consumidor selecionada é`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Os serviços de analítica são configurados e os dados de analítica são transferidos externamente`,
  [`Analyze API usage and performance`]: `Analisar o uso e o desempenho da API`,
  [`Anonymous bind`]: `Conexão anônima`,
  [`Another user registry`]: `Outro registro do usuário`,
  [`api-administrator`]: `api-administrator`,
  [`Api-Analytics`]: `Análise de API`,
  [`Api-Drafts`]: `Api-Drafts`,
  [`App`]: `Aplicativo`,
  [`App name`]: `Nome do aplicativo`,
  [`App-Analytics`]: `App-Analytics`,
  [`App-Approval`]: `Aprovação de aplicativo`,
  [`App-Dev`]: `Desenvolvimento de aplicativo`,
  [`App-analytics`]: `Analítica de aplicativos`,
  [`App-dev`]: `App-dev`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Aplicativo`,
  [`Application Authentication`]: `Autenticação do aplicativo`,
  [`Application Authentication Source`]: `Origem de autenticação do aplicativo`,
  [`Application lifecycle`]: `Ciclo de vida de aplicativos`,
  [`Application approval task for upgrading application`]: `Tarefa de aprovação de aplicativo para o aplicativo de upgrade`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Tarefa de aprovação de aplicativo para fazer upgrade do aplicativo {taskname} para a produção solicitada por {username} ({orgname})`,
  [`Application authentication`]: `Autenticação do aplicativo`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Inicialmente, os desenvolvedores de aplicativos inscrevem seus aplicativos em um ou mais Planos utilizando o Portal do Desenvolvedor. No entanto, é possível migrar assinaturas de aplicativo de um plano selecionado para um plano em outro produto.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Inicialmente, os desenvolvedores de aplicativos inscrevem seus aplicativos em um ou mais planos utilizando o Portal do Desenvolvedor. No entanto, é possível migrar assinaturas de aplicativo de um plano selecionado para um plano em outro produto.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Os desenvolvedores de aplicativos inscrevem um aplicativo em um plano para que possam chamar as APIs nesse plano.`,
  [`Application is being created. Please Wait`]: `O aplicativo está sendo criado. Aguarde`,
  [`Application scope check`]: `Verificação do escopo do aplicativo`,
  [`Applications`]: `Aplicativos`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Os aplicativos que existiam antes da ativação do ciclo de vida do aplicativo continuarão no estado de produção`,
  [`Applied security`]: `Segurança aplicada`,
  [`Apply`]: `Aplicar`,
  [`Apply all`]: `Aplicar todos`,
  [`Apply all suggestions`]: `Aplicar todas as sugestões`,
  [`Apply analysis configuration`]: `Aplicar a configuração de análise`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Aplique a política de chamada para chamar um serviço existente a partir de sua operação. A política pode ser usada com dados JSON ou XML e pode ser aplicada diversas vezes dentro de seu conjunto.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Aplique a política de upgrade do websocket para estabelecer uma conexão de websocket para chamar um serviço existente de dentro de sua operação.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Aplique esta política para chamar uma API de proxy em sua operação, especialmente se for necessário chamar uma carga útil grande. É permitido chamar somente uma política de proxy por conjunto.`,
  [`Apply to selected`]: `Aplicar ao que foi selecionado`,
  [`Apply type analysis configuration`]: `Aplicar a configuração de análise de tipo`,
  [`Approval History`]: `Histórico de Aprovação`,
  [`Approval tasks`]: `Tarefas de Aprovação`,
  [`Approvals`]: `Aprovações`,
  [`Approve`]: `Aprovar`,
  [`Archive`]: `Arquivar`,
  [`Archive Product`]: `Arquivar produto`,
  [`Archive|permission`]: `Arquivar`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Tem certeza de que deseja limpar as restrições de seus itens selecionados?`,
  [`Are you sure you want to delete API?`]: `Tem certeza de que deseja excluir a API?`,
  [`Are you sure you want to delete Product?`]: `Tem certeza de que deseja excluir o produto?`,
  [`Are you sure you want to delete a catalog?`]: `Tem certeza de que deseja excluir um catálogo?`,
  [`Are you sure you want to delete a space?`]: `Tem certeza de que deseja excluir um espaço?`,
  [`Are you sure you want to delete this application?`]: `Tem certeza de que deseja excluir este aplicativo?`,
  [`Are you sure you want to delete this billing integration?`]: `Tem certeza de que deseja excluir esta integração de faturamento?`,
  [`Are you sure you want to delete this field?`]: `Tem certeza de que deseja excluir este campo?`,
  [`Are you sure you want to delete this policy?`]: `Tem certeza de que deseja excluir esta política?`,
  [`Are you sure you want to delete this type?`]: `Tem certeza de que deseja excluir este tipo?`,
  [`Are you sure you want to disable spaces?`]: `Tem certeza de que deseja desativar espaços?`,
  [`Are you sure you want to disable custom email sender information?`]: `Tem certeza de que deseja desativar as informações customizadas do emissor de e-mail?`,
  [`Are you sure you want to disable custom notification templates?`]: `Tem certeza de que deseja desativar os modelos de notificação customizados?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Tem certeza de que deseja descontinuar usando o IBM Developer Portal? Todas as customizações do portal para catálogo serão excluídas`,
  [`Are you sure you want to enable spaces?`]: `Tem certeza de que deseja ativar espaços?`,
  [`Are you sure you want to enable custom email sender information?`]: `Tem certeza de que deseja ativar as informações customizadas do emissor de e-mail?`,
  [`Are you sure you want to enable custom notification templates?`]: `Tem certeza de que deseja ativar os modelos de notificação customizados?`,
  [`Are you sure you want to proceed with delete?`]: `Tem certeza de que deseja continuar com a exclusão?`,
  [`Are you sure you want to publish?`]: `Tem certeza de que deseja publicar?`,
  [`Are you sure you want to re-stage?`]: `Tem certeza de que deseja remontar?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Tem certeza de que deseja remover o produto do catálogo?`,
  [`Are you sure you want to unassociate?`]: `Tem certeza de que deseja desassociar?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Tem certeza de que deseja atualizar a integração de autoatendimento`,
  [`Are you sure?`]: `Tem certeza?`,
  [`Argument`]: `Argumento`,
  [`Array`]: `Matriz`,
  [`Assemble`]: `Montar`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Monte o fluxo de políticas necessário para o provedor OAuth.`,
  [`Assembly`]: `Conjunto`,
  [`Assembly Execute`]: `Execução do conjunto`,
  [`Assembly saved`]: `Conjunto salvo`,
  [`Assembly burst limits`]: `Limites de distribuição do conjunto`,
  [`Assembly count limits`]: `Limites de contagem de conjuntos`,
  [`Assign roles`]: `Designar Funções`,
  [`Assign roles to the current owner`]: `Designar funções para o proprietário atual`,
  [`Associate`]: `Associar`,
  [`Associate analytics service`]: `Associar serviço de Analítica`,
  [`Associate analytics`]: `Associar analítica`,
  [`Assumed size`]: `Tamanho assumido`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Erro de autenticação ao tentar obter a API da URL {url}. Verifique o nome do usuário e a senha.`,
  [`Audience claim`]: `Solicitação de público`,
  [`Audience Claim`]: `Solicitação do público`,
  [`Audit setting`]: `Configurações de auditoria`,
  [`Audit setting has been changed`]: `A configuração de auditoria foi mudada`,
  [`Auditing is **{mode}**`]: `A auditoria está **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `A auditoria é usada para monitorar as chamadas de API e registrar informações sobre os usuários de chamada, o horário de cada chamada e a atividade envolvida em cada evento.`,
  [`Authenticate Client Method`]: `Método de autenticação do cliente`,
  [`Authenticate password`]: `Autenticar senha`,
  [`Authenticate user`]: `Autenticar usuário`,
  [`Authenticate user settings`]: `Autenticar configurações do usuário`,
  [`Authenticate application users using`]: `Autenticar usuários do aplicativo usando`,
  [`Authenticate using Basic Authentication`]: `Autenticar usando Autenticação básica`,
  [`Authenticate using OAuth`]: `Autenticar usando OAuth`,
  [`Authenticated`]: `Autenticado`,
  [`Authenticated bind`]: `Conexão autenticada`,
  [`Authenticated User Name`]: `Nome do usuário autenticado`,
  [`Authentication`]: `Autenticação`,
  [`Authentication Bind`]: `Ligação de Autenticação`,
  [`Authentication error`]: `Erro de autenticação`,
  [`Authentication method`]: `Método de Autenticação`,
  [`Authentication TLS profile`]: `Perfil TLS de autenticação`,
  [`Authentication URL`]: `URL de autenticação`,
  [`Authentication URL user registry`]: `Registro do usuário da URL de autenticação`,
  [`Authentication URL user registry has been created.`]: `O registro do usuário da URL de autenticação foi criado.`,
  [`Authentication URL to use for validation.`]: `URL de autenticação para usar para validação.`,
  [`Authentication response header credential`]: `Credencial do cabeçalho de resposta de autenticação`,
  [`Authentication response header pattern`]: `Padrão de cabeçalho de resposta de autenticação`,
  [`Authentication type`]: `Tipo de Autenticação`,
  [`Authentication type to use to validate the UsernameToken.`]: `Tipo de autenticação a ser usado para validar o UsernameToken.`,
  [`Authorization`]: `Autorização`,
  [`Authorization Code`]: `Código de Autorização`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `A URL de autorização deve corresponder a {endpoint}, conforme definido pelo provedor OAuth "{providerTitle}"`,
  [`Authorization endpoint`]: `Terminal de autorização`,
  [`Authorization Request`]: `Solicitação de Autorização`,
  [`Authorization URL`]: `URL de autorização`,
  [`Authorize`]: `Autorizar`,
  [`Authorize User Settings`]: `Configurações de autorização do usuário`,
  [`Authorize application users using`]: `Autorizar usuários do aplicativo usando`,
  [`Authorize path`]: `Caminho de autorização`,
  [`Authors API and product definitions`]: `Cria definições de API e de produto`,
  [`Auto Generate OIDC API Assembly`]: `Gerar automaticamente a montagem da API OIDC`,
  [`Auto onboard`]: `Integração automática`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Nome gerado automaticamente para uso nas APIs e comandos de gerenciamento do API Connect.`,
  [`Automation`]: `Automação`,
  [`Automation Management Console`]: `Console de gerenciamento de automação`,
  [`Automation management console`]: `Console de gerenciamento de automação`,
  [`Availability Zone`]: `Zona de disponibilidade`,
  [`Availability zone`]: `Zona de disponibilidade`,
  [`Availability zone {arg} has been removed.`]: `A zona de disponibilidade {arg} foi removida.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `As zonas de disponibilidade permitem que você agrupe os serviços do API Connect para atender às suas necessidades de negócios. Por exemplo, é possível agrupar serviços de gateway de acordo com a região ou data center em que estão localizados.`,
  [`Average response time: {{value}}ms`]: `Tempo médio de resposta: {{value}} ms`,
  [`Average time`]: `Tempo Médio`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Evite erros de CORS usando o servidor do API Designer como um proxy local.`,
  [`Back`]: `Voltar`,
  [`Back to sign in`]: `Voltar para conectar-se`,
  [`Back to test`]: `Voltar para teste`,
  [`Backend type`]: `Tipo de back-end`,
  [`Bad gateway`]: `Gateway inválido`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `DN Base`,
  [`Base Path`]: `Caminho base`,
  [`Base path`]: `Caminho base`,
  [`Base endpoint`]: `Terminal base`,
  [`Base endpoints`]: `Terminais de base`,
  [`Base64 encoding`]: `Codificação em Base64`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `A codificação em Base64 para convites e tokens de reconfiguração de senha está **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `A configuração de codificação em Base64 para token temporário mudou`,
  [`Base URL of API invocation endpoint`]: `URL base do terminal de chamada de API`,
  [`Basepath root`]: `Raiz do caminho base`,
  [`Basic`]: `Configuração Básica`,
  [`Basic authentication`]: `Autenticação básica`,
  [`Basic authentication password`]: `Senha de Autenticação Básica`,
  [`Basic authentication request header name`]: `Nome do cabeçalho da solicitação de autenticação básica`,
  [`Basic authentication username`]: `Nome de usuário de autenticação básica`,
  [`Before you begin`]: `Antes de Começar`,
  [`Before you begin...`]: `Antes de iniciar...`,
  [`Between 0 and 10 events are waiting to be processed`]: `Entre 0 e 10 eventos estão esperando para serem processados`,
  [`Between 10 and 20 events are waiting to be processed`]: `Entre 10 e 20 eventos estão esperando para serem processados`,
  [`Billing`]: `Faturamento`,
  [`Billing and Payment`]: `Faturamento e Pagamento`,
  [`Billing has been updated`]: `O faturamento foi atualizado`,
  [`Billing integrations`]: `Integrações de faturamento`,
  [`Billing integration`]: `Integração de faturamento`,
  [`Billing Integration`]: `Integração de faturamento`,
  [`Billing {title} successfully added.`]: `O faturamento {title} foi incluído com sucesso.`,
  [`Billing {title} successfully deleted.`]: `O faturamento {title} foi excluído com sucesso.`,
  [`Billing {title} successfully updated.`]: `O faturamento {title} foi atualizado com sucesso.`,
  [`Billing Options`]: `Opções de Faturamento`,
  [`Blocklist`]: `Lista de bloqueios`,
  [`Blocking`]: `Bloqueio`,
  [`Blocking option`]: `Opção de bloqueio`,
  [`Body`]: `Corpo`,
  [`Boolean`]: `Booleano`,
  [`Both`]: `Ambos`,
  [`Browse`]: `Procurar`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Armazene em buffer solicitações e respostas da API antes de serem processadas no DataPower API Gateway.`,
  [`Buffering`]: `Armazenamento em Buffer`,
  [`Builds and manages apps in the developer organization`]: `Constrói e gerencia aplicativos na organização do desenvolvedor`,
  [`Burst Limit Name`]: `Nome do Limite de Distribuição`,
  [`Burst limits`]: `Limites de burst`,
  [`Burst limit value can't be negative`]: `O valor do limite de distribuição não pode ser negativo`,
  [`Buy`]: `Comprar`,
  [`but unable to remove because of limitation`]: `mas não é possível removê-lo por causa da limitação`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Ao configurar a visibilidade e a capacidade de assinatura de um produto, você controla a disponibilidade de suas APIs para desenvolvedores de aplicativos no Portal do Desenvolvedor. É possível controlar quais desenvolvedores de aplicativos podem ver o produto e quais desenvolvedores de aplicativos podem assinar para usar as APIs no produto.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Ao configurar a visibilidade e a capacidade de assinatura de um produto, é possível controlar a disponibilidade de suas APIs para os desenvolvedores de aplicativos no Portal do Desenvolvedor. É possível controlar quais desenvolvedores de aplicativos podem ver o produto e quais desenvolvedores de aplicativos podem assinar para usar as APIs no produto.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Por padrão, o mapeamento de várias origens (quer dizer, matriz 1 de comprimento 2 e matriz 2 de comprimento 3) resultará em uma matriz de destino contendo 5 itens (2 por processamento da matriz 1 e 3 por processamento da matriz 2). Se preferir que essas regras sejam combinadas (criando uma matriz contendo 2 ou 3 itens com propriedades de cada matriz de origem combinada), marque essa opção.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Por padrão, este produto é publicado em todos os serviços de gateway relevantes. Também é possível publicar em serviços de gateway específicos ativando esta opção.`,
  [`By payload`]: `Por carga útil`,
  [`By URL parameter`]: `Por parâmetro de URL`,
  [`Bytes`]: `Bytes`,
  [`CALLS`]: `CHAMADAS`,
  [`Calls`]: `Chamadas`,
  [`CATALOG`]: `CATÁLOGO`,
  [`CERTIFICATE NAME`]: `NOME DO CERTIFICADO`,
  [`CIPHER`]: `CIFRA`,
  [`CIPHER SUITES`]: `CONJUNTOS DE CIFRAS`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Apenas CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + Designer de API`,
  [`Client ID`]: `ID do Cliente`,
  [`CLOUD ADMINISTRATOR`]: `ADMINISTRADOR DE NUVEM`,
  [`Consumer organization`]: `Organização do consumidor`,
  [`CONSUMER ORGANIZATION / GROUP`]: `ORGANIZAÇÃO/GRUPO DO CONSUMIDOR`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `Pacote configurável de CA`,
  [`Cache Key`]: `Chave do Cache`,
  [`Cache Time to Live (second)`]: `Tempo de vida do cache (segundo)`,
  [`Cache key`]: `Chave de cache`,
  [`Cache Time-To-Live`]: `Tempo de vida do cache`,
  [`Cache type`]: `Tipo de cache`,
  [`Can't find the one you want?`]: `Não encontrou o que procurava?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Impossível enviar um convite com o servidor de correio inválido. Verifique as suas configurações do servidor de correio e tente novamente`,
  [`Cancel`]: `Cancelar`,
  [`Cannot be below {min}`]: `Não pode ser abaixo de {min}`,
  [`Cannot be empty`]: `Não pode estar vazio`,
  [`Cannot delete root type.`]: `Não é possível excluir o tipo raiz.`,
  [`Cannot exceed {max}`]: `Não é possível exceder  {max}`,
  [`Cannot find any APIs for this product`]: `Não é possível localizar nenhuma API para este produto`,
  [`Cannot find the one you want?`]: `Não localiza o que deseja?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Não é possível usar a diretiva "@listSize" no campo que não retorna uma lista quando também não define "sizedFields".`,
  [`Cannot remove built-in scalar types`]: `Não é possível remover tipos escalares integrados`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Não é possível remover a consulta, a mutação e o tipo de operação raiz de assinatura`,
  [`Cannot remove arguments of built-in directives`]: `Não é possível remover argumentos de diretivas integradas`,
  [`Cannot remove non-null arguments of directives`]: `Não é possível remover argumentos não nulos de diretivas`,
  [`Cannot remove input types of arguments of directives`]: `Não é possível remover tipos de entrada de argumentos de diretivas`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Não é possível remover valores numéricos usados como valores padrão de argumentos de diretivas`,
  [`Cannot remove enum values used as default values of input fields`]: `Não é possível remover valores numéricos usados como valores padrão de campos de entrada`,
  [`Cannot remove all fields of an object type`]: `Não é possível remover todos os campos de um tipo de objeto`,
  [`Cannot remove all fields of an interface type`]: `Não é possível remover todos os campos de um tipo de interface`,
  [`Cannot remove all slicing arguments`]: `Não é possível remover todos os argumentos de fatiamento`,
  [`Cannot remove all input fields of an input object type`]: `Não é possível remover todos os campos de entrada de um tipo de objeto de entrada`,
  [`Cannot remove all values of enum type`]: `Não é possível remover todos os valores do tipo de enumeração`,
  [`Cannot remove fields of interfaces`]: `Não é possível remover os campos de interfaces`,
  [`Cannot remove non-null input fields`]: `Não é possível remover os campos de entrada não nulos`,
  [`Case`]: `Maiúsculas e Minúsculas`,
  [`Case sensitive`]: `Distinção entre maiúsculas e minúsculas`,
  [`Catalog`]: `Catálogo`,
  [`Catalog defaults`]: `Padrões do catálogo`,
  [`Catalog Invite`]: `Convite do catálogo`,
  [`Catalog invite`]: `Convite do catálogo`,
  [`Catalog invites`]: `Convites do catálogo`,
  [`Catalog name`]: `Nome do Catálogo`,
  [`Catalog owner`]: `Proprietário do catálogo`,
  [`Catalog owner invitation`]: `Convite do proprietário do catálogo`,
  [`Catalog properties`]: `Propriedades do Catálogo`,
  [`Catalog summary`]: `Resumo do Catálogo`,
  [`Catalog title`]: `Título do Catálogo`,
  [`Catalog user registries`]: `Registros de usuários do catálogo`,
  [`Catalog by Name`]: `Catálogo por nome`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Prioridade do catálogo (utilizar terminal(is) personalizado(s) definido(s) pelo administrador do catálogo)`,
  [`Catalog {name} created`]: `O catálogo {name} foi criado`,
  [`Catalog selection`]: `Seleção do catálogo`,
  [`Catalog settings`]: `Configurações do catálogo`,
  [`Catalog settings have been updated`]: `As configurações do catálogo foram atualizadas`,
  [`Catalog User`]: `Usuário de catálogo`,
  [`Catalog Users`]: `Usuários de catálogo`,
  [`Catalog user registry`]: `Registro do usuário do catálogo`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `Não é possível usar o catálogo com o ciclo de vida ativado em Preferências de teste.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `O catálogo com o ciclo de vida e o modo de produção ativados não pode ser usado nas Preferências de teste.`,
  [`Catalogs`]: `Catálogos`,
  [`Catch`]: `Capturar`,
  [`Catches`]: `Capturas`,
  [`Categories`]: `Categorias`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Categorias estabelecem uma exibição hierárquica de produtos de API no Portal do Desenvolvedor. Use a sintaxe a seguir: top_level_element/next_level_element/lower_level_element.`,
  [`Category`]: `Categoria`,
  [`Certifcate or Shared Secret for Verify`]: `Certificado ou segredo compartilhado para verificação`,
  [`Certificate`]: `Certificado`,
  [`Certificate (Optional)`]: `Certificado (opcional)`,
  [`Certificate management`]: `Gerenciamento de certificado`,
  [`Certificate Manager instance`]: `Instância do gerenciador de certificados`,
  [`Certificate (optional)`]: `Certificado (opcional)`,
  [`Certificates`]: `Certificados`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Os certificados são necessários para proteger as conexões entre o gateway e os domínios que ele manipula. Todos os certificados devem ser armazenados em um serviço Certificate Manager. O serviço fornece um repositório seguro para os certificados e ajuda a evitar indisponibilidades ao enviar notificações quando os certificados estão prestes a expirar. Para começar,`,
  [`Certificates details in this keystore`]: `Detalhes de certificados neste Armazenamento de chaves`,
  [`Certificates details in this truststore`]: `Detalhes de certificados neste armazenamento confiável`,
  [`Change`]: `Mudar`,
  [`Change Consumer Organization owner to an existing user`]: `Mudar o proprietário da Organização do Consumidor para um usuário existente`,
  [`Change Ownership to an Existing User`]: `Mudança de Propriedade para um Usuário Existente`,
  [`Change portal service`]: `Mudar serviço do portal`,
  [`Change owner to existing user`]: `Mudar proprietário para o usuário existente`,
  [`Change owner to new user`]: `Muda proprietário para um novo usuário`,
  [`Change ownership by inviting a new user`]: `Mudar a propriedade convidando um novo usuário`,
  [`Change ownership to`]: `Mudar a propriedade para`,
  [`Change ownership to a new user via email invitation`]: `Mudar a propriedade para um novo usuário por meio de convite de e-mail`,
  [`Change ownership to an existing user`]: `Mudar a propriedade para um usuário existente`,
  [`Change password`]: `Mudar senha`,
  [`Change setup`]: `Mudar configuração`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `As mudanças feitas diretamente na API ou no Conjunto que está subjacente a um provedor OAuth podem tornar o provedor OAuth inválido se você também não atualizar nenhum valor correspondente na definição do próprio provedor OAuth. Se você salvar essas mudanças, assegure-se de que também faça quaisquer atualizações correspondentes na definição do provedor OAuth.`,
  [`Change the following extra constraints in the schema:`]: `Mude as restrições extras a seguir no esquema:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `A mudança do Certificate Manager removerá todos os certificados e as designações de pacote configurável de CA existentes.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Mudar o esquema de DNS mudará o formato dos links de URL para o API Connect e o Portal do Desenvolvedor. Será necessário comunicar os novos links aos usuários. Clique em Cancelar se você não deseja continuar.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Mudar o nome ou a versão de uma API criará uma nova API. Tem certeza de que deseja salvar suas mudanças?`,
  [`Check your email`]: `Verifique seu e-mail`,
  [`Child`]: `Filho`,
  [`Chinese (Simplified)`]: `Chinês (Simplificado)`,
  [`Chinese (Traditional)`]: `Chinês (Tradicional)`,
  [`Choose`]: `Escolher`,
  [`Choose a`]: `Escolher um`,
  [`Choose {docTypeLabel}`]: `Escolher {docTypeLabel}`,
  [`Choose Catalog`]: `Escolher Catálogo`,
  [`Choose a Catalog`]: `Escolha um Catálogo`,
  [`Choose a Consumer Org`]: `Escolher uma Organização do consumidor`,
  [`Choose Gateway`]: `Escolher Gateway`,
  [`Choose a Gateway`]: `Escolha um Gateway`,
  [`Choose Product`]: `Escolher produto`,
  [`Choose a Product`]: `Escolher um produto`,
  [`Choose Rate Limit`]: `Escolher limite de taxa`,
  [`Choose a Rate Limit`]: `Escolha um limite de taxa`,
  [`Choose Application`]: `Escolher aplicativo`,
  [`Choose an Application`]: `Escolha um aplicativo`,
  [`Choose a {docTypeLabel}`]: `Escolher um {docTypeLabel}`,
  [`Choose a publish destination`]: `Escolha um destino de publicação`,
  [`Choose a billing integration`]: `Escolher uma integração de faturamento`,
  [`Choose a catalog to test within:`]: `Escolha um catálogo para testar em:`,
  [`Choose a plan against which to test:`]: `Escolha um plano no qual testar:`,
  [`Choose a product containing this API, or create a new one:`]: `Escolha um produto que contenha esta API ou crie uma nova:`,
  [`Choose a user registry`]: `Escolha um registro do usuário`,
  [`Choose an application with which to test, or create a new one:`]: `Escolha um aplicativo com o qual testar ou crie um novo:`,
  [`Choose an existing application`]: `Escolher um aplicativo existente`,
  [`Choose an existing catalog`]: `Escolha um catálogo existente`,
  [`Choose an existing product published to your Sandbox catalog`]: `Escolha um produto existente publicado em seu catálogo do Ambiente de Simulação`,
  [`Choose an operation to invoke:`]: `Escolha uma operação para chamar:`,
  [`Choose an option`]: `Escolha uma opção`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Escolha uma opção para determinar como a política de mapa manipula a saída da matriz vazia. A opção de tudo (a opção padrão) gerará todas as matrizes vazias e matrizes filhas vazias. A opção de pai gerará somente a matriz vazia pai. A opção de nenhum não gerará a matriz vazia.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Escolher o modo de bloqueio terá efeitos adversos no desempenho do sistema`,
  [`Choose existing directory`]: `Escolher diretório existente`,
  [`Choose one...`]: `Escolha um...`,
  [`Choose operations and paths to generate into this API`]: `Escolha as operações e os caminhos para gerar nessa API`,
  [`Choose paths to generate into this API`]: `Escolha os caminhos que serão gerados nesta API`,
  [`Choose the DNS scheme`]: `Escolha o esquema de DNS`,
  [`Choose the permissions for the role.`]: `Escolha as permissões para a função.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Escolha se deseja usar endereços DNS dinâmicos ou estáticos para as chamadas de API recebidas pelo serviço de gateway e para acessar o Portal do Desenvolvedor.`,
  [`Cipher`]: `Cifra`,
  [`Ciphers`]: `Cifras`,
  [`Clear`]: `Limpar`,
  [`Clear constraints`]: `Limpar restrições`,
  [`Clear file`]: `Limpar arquivo`,
  [`Clear filters`]: `Limpar filtros`,
  [`Clear from selected`]: `Limpar em selecionados`,
  [`Clear operation filter`]: `Limpar filtro de operação`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Clique em um certificado para visualizar detalhes sobre a organização e impressão digital da emissão.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Clique no link abaixo para continuar autorizando APIs em outra guia e você obterá o código de autorização para a etapa 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Clicar no link abaixo abrirá o servidor em uma nova guia. Se o navegador exibe uma questão certificada, é possível escolher aceitá-la e voltar aqui para testar novamente.`,
  [`Click add to add a billing integration.`]: `Clique em incluir para incluir uma integração de faturamento.`,
  [`Click Add to add a gateway extension.`]: `Clique em Incluir para incluir uma extensão de gateway.`,
  [`Click **Add domain** to add domains.`]: `Clique em **Incluir domínio** para incluir domínios.`,
  [`Click Add to add a new base endpoint.`]: `Clique em Incluir para incluir um novo terminal de base.`,
  [`Click Add to add a new HTTP header.`]: `Clique em Incluir para incluir um novo cabeçalho de HTTP.`,
  [`Click Add to add a new member.`]: `Clique em Incluir para incluir um novo membro.`,
  [`Click Add to add a new role.`]: `Clique em Incluir para incluir uma nova função.`,
  [`Click Add to add a new scope.`]: `Clique em Incluir para incluir um novo escopo.`,
  [`Click Add to add a new consumer organization group.`]: `Clique em Incluir para incluir um novo grupo de organizações do consumidor.`,
  [`Click Add to create the API Key.`]: `Clique em Incluir para criar a Chave de API.`,
  [`Click Create to add the API Key.`]: `Clique em Criar para incluir a Chave de API.`,
  [`Click Create to add a new user registry.`]: `Clique em Criar para incluir um novo registro do usuário.`,
  [`Click Create user registry to add a new user registry.`]: `Clique em Criar registro do usuário para incluir um novo registro do usuário.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Clique em **Criar organização do provedor** para incluir organizações do provedor.`,
  [`Click Edit to add OAuth providers.`]: `Clique em Editar para incluir provedores OAuth.`,
  [`Click Edit to add a user registry.`]: `Clique em Editar para incluir um registro do usuário.`,
  [`Click Edit to add an API to the product.`]: `Clique em Editar para incluir uma API no produto.`,
  [`Click Create to add a property.`]: `Clique em Criar para incluir uma propriedade.`,
  [`Click Edit to add TLS client profiles.`]: `Clique em Editar para incluir perfis do cliente TLS.`,
  [`Click Edit to add user registries.`]: `Clique em Editar para incluir registros do usuário.`,
  [`Click Add to add a new consumer organization.`]: `Clique em Incluir para incluir uma nova organização do consumidor.`,
  [`Click Consumer organizations to add one.`]: `Clique em Organizações do consumidor para incluir uma.`,
  [`Click Add to add a new application.`]: `Clique em Incluir para incluir um novo aplicativo.`,
  [`Click Add to add parameters.`]: `Clique em Incluir para incluir parâmetros.`,
  [`Click Add to add response.`]: `Clique em Incluir para incluir uma resposta.`,
  [`Click Create to add a new TLS client profile.`]: `Clique em Criar para incluir um novo perfil do cliente TLS.`,
  [`Click Create to add a new keystore.`]: `Clique em Criar para incluir um novo keystore.`,
  [`Click Create to add a new truststore.`]: `Clique em Criar para incluir um novo armazenamento confiável.`,
  [`Click **Register remote gateway** to add gateways.`]: `Clique em **Registrar gateway remoto** para incluir gateways.`,
  [`Click Register gateways to add new Gateway Services.`]: `Clique em Registrar gateways para incluir novos serviços de gateway.`,
  [`Click Edit to add Gateway Services.`]: `Clique em Editar para incluir serviços de gateway.`,
  [`Click Register gateways to add new gateway services.`]: `Clique em Registrar gateways para incluir novos serviços de gateway.`,
  [`Click Edit to add gateway services.`]: `Clique em Editar para incluir serviços de gateway.`,
  [`Click Edit to add an email server.`]: `Clique em Editar para incluir um servidor de e-mail.`,
  [`Click Create to add a new TLS server profile.`]: `Clique em Criar para incluir um novo perfil do servidor TLS.`,
  [`Click Create to add a new email server.`]: `Clique em Criar para incluir um novo servidor de e-mail.`,
  [`Click Add to add a new schema definition.`]: `Clique em Incluir para incluir uma nova definição de esquema.`,
  [`Click Add to add a new property for this definition.`]: `Clique em Incluir para incluir uma nova propriedade para esta definição.`,
  [`Click Add to add a new parameter for this API.`]: `Clique em Incluir para incluir um novo parâmetro para esta API.`,
  [`Click Add to add tags and external documentation for this API.`]: `Clique em Incluir para incluir tags e a documentação externa para esta API.`,
  [`Click Add to add a new path.`]: `Clique em Incluir para incluir um novo caminho.`,
  [`Click Add to add a property for this API.`]: `Clique em Incluir para incluir uma propriedade para esta API.`,
  [`Click here to create a security definition`]: `Clique aqui para criar uma definição de segurança`,
  [`Click Add to add a new security definition.`]: `Clique em Incluir para incluir uma nova definição de segurança.`,
  [`Click Add to add a target service for this API.`]: `Clique em Incluir para incluir um serviço de destino para esta API.`,
  [`Click Add to add an operation for this path.`]: `Clique em Incluir para incluir uma operação para este caminho.`,
  [`Click Add to add a path parameter for this path.`]: `Clique em Incluir para incluir um parâmetro de caminho para este caminho.`,
  [`Click Add to add a new OAuth provider.`]: `Clique em Incluir para incluir um novo provedor OAuth.`,
  [`Click Add to add a new provider organization.`]: `Clique em Incluir para incluir uma nova organização do provedor.`,
  [`Click Add to add a new API or product.`]: `Clique em Incluir para incluir uma nova API ou produto.`,
  [`Click Add to add a new API.`]: `Clique em Incluir para incluir uma nova API.`,
  [`Click Add to add a new Extension.`]: `Clique em Incluir para incluir uma nova extensão.`,
  [`Click Add to add new {resource}.`]: `Clique em Incluir para incluir {resource}.`,
  [`Click to expand`]: `Clicar para Expandir`,
  [`Click to collapse`]: `clique para reduzir`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Clique para saber como fazer upload e gerenciar os certificados requeridos pelo gateway.`,
  [`Client Credentials`]: `Credenciais de Cliente`,
  [`Client information`]: `Informações do Cliente`,
  [`Client revocation path`]: `Caminho de revogação do cliente`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Clique em Registrar serviço para registrar um serviço. Isso permitirá testar os terminais ao configurar outros serviços.`,
  [`Client secret`]: `Segredo do cliente`,
  [`Client security`]: `Segurança do Cliente`,
  [`Client Type`]: `Tipo de Cliente`,
  [`Client authentication method`]: `Método de autenticação de cliente`,
  [`Clone`]: `Clonar`,
  [`Close`]: `Fechar`,
  [`Cloud`]: `Nuvem`,
  [`Cloud Admin`]: `Administrador de Nuvem`,
  [`Cloud Administrator`]: `Administrador de Nuvem`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Administrador em nuvem, Gerente de organização, Administrador de topologia. A função Membro é automaticamente designada a todos os usuários.`,
  [`Cloud administration`]: `Administração da nuvem`,
  [`Cloud Discovery Agent`]: `Cloud Discovery Agent`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Registro do usuário local do gerenciador de nuvem`,
  [`Cloud-Settings`]: `Configurações de nuvem`,
  [`Cloud settings invitation timeout`]: `Tempo limite do convite de configurações de nuvem`,
  [`Cloud settings invitation timeout has been changed`]: `O tempo limite do convite de configurações de nuvem foi mudado`,
  [`Code editor`]: `Editor de Código`,
  [`Collect Metadata`]: `Coletar Metadados`,
  [`Collect credentials using`]: `Coletar credenciais usando`,
  [`Collect metadata`]: `Coletar metadados`,
  [`Combo box`]: `Caixa de combinação`,
  [`Community Manager`]: `Gerente da comunidade`,
  [`community-manager`]: `community-manager`,
  [`Compatibility`]: `Compatibilidade`,
  [`Complex Definitions must be edited in the editor`]: `As Definições complexas devem ser editadas no editor`,
  [`Compose DN`]: `Editar DN`,
  [`Compose UPN`]: `Editar UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `Edite um novo proxy REST definindo caminhos e operações`,
  [`Compose a new product by adding rate limits and plans`]: `Crie um novo produto incluindo limites de taxa e planos`,
  [`Compression`]: `Compactação`,
  [`Condition`]: `Condição`,
  [`Condition editor`]: `Editor de condição`,
  [`Confidential`]: `Confidencial`,
  [`Configuration`]: `Configuração`,
  [`Configurations`]: `Configurações`,
  [`Configure API endpoint`]: `Configurar terminal de API`,
  [`Configure API Gateway Service`]: `Configurar o Serviço de Gateway da API`,
  [`Configure analytics service`]: `Configurar serviço de Analítica`,
  [`Configure availability zone`]: `Configurar a zona de disponibilidade`,
  [`Configure catalog user registries`]: `Configurar registros de usuários do catálogo`,
  [`Configure cloud`]: `Configurar a nuvem`,
  [`Configure DataPower API gateway service`]: `Configurar o serviço DataPower API Gateway`,
  [`Configure DataPower gateway service`]: `Configurar o serviço DataPower Gateway`,
  [`Configure Email Server`]: `Configure o Servidor de E-mail`,
  [`Configure gateway extension`]: `Configurar a extensão de gateway`,
  [`Configure portal service`]: `Configurar serviço do portal`,
  [`Configure service`]: `Configurar serviço`,
  [`Configure topology`]: `Configurar topologia`,
  [`Configure XML`]: `Configurar XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Configure um serviço de gateway de API do DataPower para proteger e utilizar APIs`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Configurar um serviço de DataPower Gateway para proteger e aplicar APIs`,
  [`Configure a developer portal service for API consumers`]: `Configure um serviço de Portal do Desenvolvedor para os consumidores de API`,
  [`Configure advanced features for OIDC settings`]: `Defina os recursos avançados das configurações do OIDC`,
  [`Configure an analytics service to collect API call data`]: `Configure um serviço de Analítica para coletar dados de chamada da API`,
  [`Configure an email server to send invitations and notifications to users.`]: `Configure um servidor de e-mail para enviar convites e notificações para usuários.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Configure a auditoria para monitorar as operações de usuários da IU do Administrador, da IU do Gerenciador, do Kit de ferramentas, da API de REST de gerenciamento e do Portal. Os logs de cada operação do usuário de criação, atualização e exclusão são enviados para os serviços de criação de log remotos especificados abaixo.`,
  [`Configure availability zones and services`]: `Configurar zonas de disponibilidade e serviços`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Configure como as URLs de terminal da API são compostas em APIs publicadas`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Configure como terminais personalizados são exibidos no Portal do Desenvolvedor`,
  [`Configure mapping`]: `Configurar Mapeamento`,
  [`Configure properties of the activity log`]: `Configurar propriedades do log de atividades`,
  [`Configure settings for token management and revocation.`]: `Defina as configurações para o gerenciamento e a revogação de token.`,
  [`Configure settings to generate and validate tokens.`]: `Defina as configurações para gerar e validar tokens.`,
  [`Configure the API security`]: `Configurar a segurança da API`,
  [`Configure the default set of gateway services configured for each catalog`]: `Configure o conjunto padrão de serviços de gateway configurados para cada catálogo`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Configure o Portal do Desenvolvedor que é usado pelos desenvolvedores de aplicativos para acessar as APIs neste catálogo`,
  [`Configure the gateway services used by default in each catalog`]: `Configure os serviços de gateway usados por padrão em cada catálogo`,
  [`Configure the gateway extension for the selected gateway`]: `Configurar a extensão de gateway para o gateway selecionado`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Configure o DataPower API Gateway gerenciado pela IBM e gerencie seus próprios gateways remotos. [Saiba mais]({link})`,
  [`Configure the keystore and upload certificates`]: `Configure os certificados de Armazenamento de chaves e de upload`,
  [`Configure the name and email address to be used in the from field of emails`]: `Configure o nome e o endereço de e-mail a serem usados no campo de remetente dos e-mails`,
  [`Configure the portal service for the catalog`]: `Configure o serviço de portal para o catálogo`,
  [`Configure the role and assign permissions`]: `Configure a função e designe permissões`,
  [`Configure the security of this API`]: `Configure a segurança desta API`,
  [`Configure the sender name and address to use for email notifications`]: `Configure o nome do remetente e o endereço a serem usados para notificações por e-mail`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Configure o nome do remetente, o endereço e o servidor de e-mail usados para enviar convites e notificações para usuários`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Configure o conjunto de funções a ser usado por padrão quando uma organização do consumidor for criada`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Configure o conjunto de funções a ser usado por padrão quando uma organização do provedor for criada`,
  [`Configure the templates used to invite and notify users`]: `Configure os modelos usados para convidar e notificar usuários`,
  [`Configure the truststore and upload certificates`]: `Configure o armazenamento confiável e faça upload de certificados`,
  [`Configure user authentication using`]: `Configure a autenticação do usuário usando`,
  [`Configure user authentication using JSON Web Tokens`]: `Configure a autenticação do usuário usando o JSON Web Tokens`,
  [`Configure user authentication using a LDAP provider`]: `Configurar a autenticação do usuário usando um provedor LDAP`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Configure a autenticação do usuário usando um Registro de Usuário Local do API Connect`,
  [`Configure user authentication using an authentication URL`]: `Configure a autenticação do usuário usando uma URL de autenticação`,
  [`Configure user authentication using this user registry type`]: `Configure a autenticação do usuário usando esse tipo de registro de usuário`,
  [`Configure user registries, OAuth providers and TLS`]: `Configurar registros do usuário, provedores OAuth e TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Configure registros do usuário, TLS, provedores do OAuth e servidores de e-mail`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Configure o Keystore para token de Acesso, token de ID e token Temporário`,
  [`Configured OAuth Provider`]: `Provedor OAuth configurado`,
  [`Configured OAuth Providers`]: `Provedores OAuth configurados`,
  [`Configured OAuth provider`]: `Provedor OAuth configurado`,
  [`Configured OAuth provider list has been updated`]: `A lista de provedores OAuth configurada foi atualizada`,
  [`Configured OAuth provider list has not been updated`]: `A lista de provedores OAuth configurada não foi atualizada`,
  [`Confirm`]: `Confirmar`,
  [`Confirm deletion`]: `Confirmar Exclusão`,
  [`Confirm Deletion of Portal`]: `Confirmar exclusão do Portal`,
  [`Confirm visibility settings`]: `Confirmar configurações de visibilidade`,
  [`Confirm new password`]: `Confirmar nova senha`,
  [`Confirm password`]: `Confirmar senha`,
  [`Confirm password should match password`]: `Confirmar senha deve corresponder à senha`,
  [`Confirmation`]: `Confirmação`,
  [`Congratulations, you are now registered.`]: `Parabéns, você agora está registrado.`,
  [`Congratulations, your password has been reset!`]: `Parabéns, sua senha foi reconfigurada!`,
  [`Connect`]: `Conectar`,
  [`Connect to API Connect`]: `Conectar à API Connect`,
  [`Connected to API Manager Product version`]: `Conectado à versão do produto API Manager`,
  [`Connect to Cloud`]: `Conectar-se ao Cloud`,
  [`Connected`]: `Conectada(s)`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Construa sua definição de esquema incluindo propriedades dos tipos necessários. É possível aninhar propriedades`,
  [`Consider for nesting`]: `Considere para aninhamento`,
  [`Consumed Media Types`]: `Tipos de mídia consumidos`,
  [`Consumer`]: `Consumidor`,
  [`Consumer invitation and roles`]: `Convite e funções do consumidor`,
  [`Consumer onboarding`]: `Integração do consumidor`,
  [`Consumer organizations`]: `Organizações de consumidores`,
  [`Consumer organizations or groups`]: `Organizações ou grupos de consumidores`,
  [`Consumer organization {name} created`]: `Organização do consumidor  {name} criada`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `As organizações de consumidores são criadas por desenvolvedores de aplicativos no Portal do Desenvolvedor e também é possível criar organizações de consumidores usando a IU do API Manager`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `As organizações do cliente são criadas por desenvolvedores de aplicativos no Portal do Desenvolvedor e também pode-se criar organizações de consumidores aqui`,
  [`Consumer-Org`]: `Organização do consumidor`,
  [`Consumers`]: `Consumidores`,
  [`Consumes`]: `Utiliza`,
  [`Contact`]: `Contato`,
  [`Contact information for the owners of the API.`]: `Informações de contato para os proprietários da API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Entre em contato com o administrador sobre a configuração do serviço de analítica para que você possa visualizar seus dados aqui.`,
  [`Contact your administrator for more information.`]: `Entre em contato com o administrador para obter informações adicionais.`,
  [`Content`]: `Conteúdo`,
  [`Content on error`]: `Conteúdo do erro`,
  [`Content type`]: `Tipo do conteúdo`,
  [`Context Variable`]: `Variável de Contexto`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `A variável de contexto ou de tempo de execução que contém o JWT a ser validado. Se não estiver configurada, a política irá procurar pelo JWT em request.headers.authorization.`,
  [`Context variable`]: `Variável de contexto`,
  [`Continue`]: `Continuar`,
  [`Continue on error`]: `Continuar com Erro`,
  [`Continue with`]: `Continuar com`,
  [`Continue with:`]: `Continuar com:`,
  [`Conversion type`]: `Tipo de Conversão`,
  [`Convert to expression`]: `Converter em expressão`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `Copiado`,
  [`Copied to clipboard`]: `Copiado para área de transferência`,
  [`Copy`]: `Copiar`,
  [`Copy Id Headers To Message`]: `Copiar cabeçalhos de ID para mensagem`,
  [`Copy to clipboard`]: `Copiar para Área de Transferência`,
  [`Copy file path to clipboard`]: `Copiar caminho de arquivo para a área de transferência`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Copie a estimativa de custo para a consulta do GraphQL para a saída`,
  [`Copy the endpoints below to`]: `Copie os terminais abaixo para`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Copiar a Chave de API para usar. Você é capaz de ver esta Chave de API novamente com o ícone Visualizar.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Informações sobre Copyright`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Corrija os erros no editor acima ou faça upload novamente de um arquivo válido`,
  [`Cost`]: `Custo`,
  [`Count`]: `Contagem`,
  [`Count Limit Name`]: `Nome do limite de contagem`,
  [`Count Limits`]: `Limites de contagem`,
  [`Created at`]: `Criado em`,
  [`Counter`]: `Contador`,
  [`Create`]: `Criar`,
  [`Create a [Certificate Manager]({link}) service`]: `Criar um serviço [Certificate Manager]({link})`,
  [`Create API`]: `Criar API`,
  [`Create an API from the Develop section.`]: `Crie uma API na seção Desenvolver.`,
  [`Create API Connect API Key`]: `Criar a Chave de API do API Connect`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Criar a API no serviço GraphQL existente (proxy do GraphQL)`,
  [`Create API from existing OpenAPI service`]: `Criar API usando o serviço OpenAPI existente`,
  [`Create API from existing WSDL service (REST proxy)`]: `Criar uma API usando o serviço WSDL existente (proxy REST)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Criar API usando o serviço WSDL existente (proxy SOAP)`,
  [`Create API from target service`]: `Criar API usando o serviço de destino`,
  [`Create API from existing REST service`]: `Crie uma API a partir do serviço REST existente`,
  [`Create API from existing SOAP service`]: `Criar API a partir de um serviço SOAP existente`,
  [`Create API from existing WSDL service`]: `Crie uma API a partir do serviço WSDL existente`,
  [`Create API Key failed`]: `Criação de Chave de API com falha`,
  [`Create application`]: `Criar aplicativo`,
  [`Create authentication URL user registry`]: `Criar registro do usuário da URL de autenticação`,
  [`Create availability zone`]: `Criar zona de disponibilidade`,
  [`Create Billing`]: `Crie um faturamento`,
  [`Create catalog`]: `Criar catálogo`,
  [`Create catalog property`]: `Criar propriedade do catálogo`,
  [`Create consumer organization`]: `Criar organização do consumidor`,
  [`Create Credentials`]: `Criar Credenciais`,
  [`Create definition`]: `Criar definição`,
  [`Create email server`]: `Criar servidor de e-mail`,
  [`Create IBM Developer Portal`]: `Criar um IBM Developer Portal`,
  [`Create keystore`]: `Criar keystore`,
  [`Create LDAP user registry`]: `Criar registro do usuário LDAP`,
  [`Create LTPA Key`]: `Criar chave LTPA`,
  [`Create local user registry`]: `Criar registro do usuário local`,
  [`Create native OAuth provider`]: `Criar provedor OAuth nativo`,
  [`Create New GraphQL API`]: `Crie uma nova API do GraphQL`,
  [`Create new OpenAPI`]: `Criar novo OpenAPI`,
  [`Create new product`]: `Criar produto`,
  [`Create OIDC user registry`]: `Criar registro do usuário OIDC`,
  [`Create OpenAPI definition and product definition`]: `Criar as definições de OpenAPI e de produto`,
  [`Create operation`]: `Criar operação`,
  [`Create organization`]: `Criar organização`,
  [`Create path`]: `Criar caminho`,
  [`Create plan`]: `Criar Plano`,
  [`Create portal`]: `Criar portal`,
  [`Create Product`]: `Criar produto`,
  [`Create property`]: `Criar propriedade`,
  [`Create role`]: `Criar função`,
  [`Create role default for the Organization`]: `Criar padrão de função para a organização`,
  [`Create sample user registry`]: `Criar registro do usuário de amostra`,
  [`Create security definition`]: `Criar definição de segurança`,
  [`Create space`]: `Criar espaço`,
  [`Create subscription`]: `Criar assinatura`,
  [`Create TLS client profile`]: `Criar perfil do cliente TLS`,
  [`Create TLS server profile`]: `Criar perfil do servidor TLS`,
  [`Create target service`]: `Criar serviço de destino`,
  [`Create third party OAuth provider`]: `Criar provedor OAuth de terceiros`,
  [`Create truststore`]: `Criar armazenamento confiável`,
  [`Create user registry`]: `Criar registro do usuário`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Crie um proxy GraphQL com base em um serviço do GraphQL`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Crie um proxy REST com base no serviço de destino OpenAPI descrito`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Crie um proxy REST com base em um serviço de destino WSDL descrito`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Crie um proxy REST com base em um serviço de destino OpenAPI descrito`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Crie um proxy REST com base no serviço de destino WSDL descrito`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Crie um proxy REST que encaminhe todo o tráfego para uma API de destino ou um terminal de serviço`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Crie um proxy SOAP com base em um serviço de destino WSDL descrito`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Crie um proxy SOAP com base no serviço de destino WSDL descrito`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Crie um novo objeto usando um modelo de barras de direção. As variáveis são tomadas do contexto.`,
  [`Create a portal site for the catalog`]: `Crie um site do portal para o catálogo`,
  [`Create a product by importing a product definition`]: `Crie um produto importando uma definição de produto`,
  [`Create a security definition`]: `Crie uma definição de segurança`,
  [`Create a subscription`]: `Criar uma assinatura`,
  [`Create an API by importing an OpenAPI definition`]: `Crie uma API importando uma definição OpenAPI`,
  [`Create an API that calls an existing SOAP service`]: `Criar uma API que chama um serviço SOAP existente`,
  [`Create and manage API provider organizations and owners`]: `Crie e gerencie organizações e proprietários do provedor de API`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Crie e gerencie espaços no catálogo; os espaços permitem particionar o catálogo para suportar diferentes equipes de desenvolvimento do provedor da API`,
  [`Create and publish`]: `Criar e publicar`,
  [`Create and publish APIs and Products`]: `Criar e publicar APIs e produtos`,
  [`Create and subscribe`]: `Criar e assinar`,
  [`Create assembly`]: `Criar conjunto`,
  [`Create empty parent object for failed mapping`]: `Criar um objeto pai vazio para o mapeamento com falha`,
  [`Create endpoint`]: `Criar terminal`,
  [`Create from`]: `Criar a partir de`,
  [`Create from Existing WSDL service`]: `Criar a partir do serviço WSDL Existente`,
  [`Create from File or URL`]: `Criar a partir de Arquivo ou URL`,
  [`Create from existing openAPI service`]: `Criar a partir do serviço openAPI existente`,
  [`Create gateway extension`]: `Criar a extensão de gateway`,
  [`Create new user`]: `Crie um novo usuário`,
  [`Create new version`]: `Criar nova versão`,
  [`Create product using a template`]: `Criar produto usando um modelo`,
  [`Create provider organization`]: `Criar organização do provedor`,
  [`Create required child properties for array objects and mapped optional objects`]: `Criar propriedades-filhas necessárias para objetos de matriz e objetos opcionais mapeados`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Criado pela configuração do Provedor OAuth como uma amostra. Certifique-se de atualizar os provedores OAuth usando essa amostra com um registro de usuário válido.`,
  [`Created new product`]: `Criado novo produto`,
  [`Create|permission`]: `Criar`,
  [`Create|title`]: `Criar`,
  [`Creating Draft Product`]: `Criando produto de rascunho`,
  [`Creating a Consumer organization`]: `Criando uma organização do consumidor`,
  [`Creating a consumer organization`]: `Criando uma organização do consumidor`,
  [`Creating a new Product`]: `Criando um novo produto`,
  [`Creative Commons (CC-BY-4.0) license`]: `Licença do Creative Commons (CC-BY-4.0)`,
  [`Credential Extraction Method`]: `Método de extração de credencial`,
  [`Credentials for the Sandbox catalog`]: `Credenciais para o catálogo do ambiente de simulação`,
  [`Credentials for the {catalogName} catalog`]: `Credenciais para o catálogo {catalogName}`,
  [`Credential Name`]: `Nome da Credencial`,
  [`Credential has been added.`]: `A credencial foi incluída.`,
  [`Credential`]: `Credencial`,
  [`Credentials`]: `Credenciais`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `As credenciais são necessárias para executar o API Designer e o kit de ferramentas.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `As credenciais são necessárias para executar o kit de ferramentas. Coloque os arquivos de credencial na pasta na qual o seu kit de ferramentas está instalado.`,
  [`Credit Card`]: `Cartão de crédito`,
  [`Cryptographic Algorithm`]: `Algoritmo Criptográfico`,
  [`Currency`]: `Moeda`,
  [`Current password`]: `Senha atual`,
  [`Current password is invalid.`]: `A senha atual é inválida.`,
  [`Custom`]: `Customizado`,
  [`Custom API URL`]: `URL da API customizada`,
  [`Custom expression (optional, one per line)`]: `Expressão customizada (opcional, uma por linha)`,
  [`Custom expression to remove (optional, one per line)`]: `Expressão customizada para remover (opcional, uma por linha)`,
  [`Custom HTML form`]: `Formulário HTML customizado`,
  [`Custom policies`]: `Políticas Customizadas`,
  [`Custom form TLS profile`]: `Perfil TLS de formulário customizado`,
  [`Custom form content security policy`]: `Política de segurança de conteúdo de formulário customizado`,
  [`Custom form CSP header value`]: `Valor do cabeçalho de provedor de serviço de nuvem de formulário customizado`,
  [`Custom form endpoint`]: `Terminal de formulário customizado`,
  [`Custom header pattern`]: `Padrão de cabeçalho customizado`,
  [`Custom subscribability is required if custom visibility is selected`]: `A capacidade de assinatura customizada será necessária se a visibilidade for selecionada`,
  [`Customize`]: `Customizar`,
  [`Customize email sender information`]: `Customizar informações do emissor de e-mail`,
  [`Customize notification templates`]: `Customizar modelos de notificação`,
  [`Customize the plan API list`]: `Customizar a lista de API do plano`,
  [`Czech`]: `Checo`,
  [`DATE MODIFIED`]: `DATA DA MODIFICAÇÃO`,
  [`DELETE`]: `DELETE`,
  [`Date created`]: `Data de criação`,
  [`Description`]: `Descrição`,
  [`DETAIL`]: `DETALHE`,
  [`DN Base`]: `DN Base`,
  [`DNS Schemes`]: `Esquemas de DNS`,
  [`Dashboard`]: `Painel`,
  [`Data encoded form body`]: `Corpo do formulário codificado por dados`,
  [`Data flow in API Connect`]: `Fluxo de dados no API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (compatível com v5)`,
  [`DataPower API`]: `API do DataPower`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway (compatível com v5)`,
  [`DataPower Gateway policies`]: `Políticas de DataPower Gateway`,
  [`Debug`]: `Depurar`,
  [`Debug rule`]: `Regra de depuração`,
  [`Debug XACML policy`]: `Política XACML de depuração`,
  [`Dec`]: `Dez`,
  [`Decline`]: `Recusar`,
  [`Decline and send`]: `Recusar e enviar`,
  [`Decode Request Params`]: `Decodificar parâmetros de solicitação`,
  [`Decrement`]: `Decremento`,
  [`Decrypt`]: `Decriptografar`,
  [`Decrypt Crypto JWK variable name`]: `Decriptografar o nome de variável do JWK criptográfico`,
  [`Decrypt Crypto Object`]: `Decriptografar objeto criptográfico`,
  [`Decrypt a buffer using the specified certificate`]: `Decriptografe um buffer usando o certificado especificado`,
  [`Default`]: `Padrão`,
  [`Default behavior`]: `Comportamento padrão`,
  [`Default gateways`]: `Gateways padrão`,
  [`default gateway services configured`]: `serviços de gateway padrão configurados`,
  [`Default Availability Zone`]: `Zona de Disponibilidade Padrão`,
  [`Default Base endpoints`]: `Terminais de Base padrão`,
  [`Default availability zone`]: `Zona de disponibilidade padrão`,
  [`Default base endpoints`]: `Terminais de base padrão`,
  [`Default HTML form`]: `Formulário HTML padrão`,
  [`Default limit`]: `Limite padrão`,
  [`Default OAuth Provider Settings`]: `Configurações padrão do Provedor OAuth`,
  [`Default OAuth Provider Settings Object`]: `Objeto de configurações do provedor OAuth padrão`,
  [`Default Plan`]: `Plano Padrão`,
  [`Default TLS client profile`]: `Perfil do cliente TLS padrão`,
  [`Default TLS server keystore`]: `Keystore do servidor do TLS padrão`,
  [`Default TLS server profile`]: `Perfil do servidor TLS padrão`,
  [`Default burst-limit`]: `Limite de distribuição padrão`,
  [`Default form`]: `Formulário padrão`,
  [`Default keystore for access_token signing key`]: `Keystore padrão para a chave de assinatura access_token`,
  [`Default keystore for id_token signing key`]: `Keystore padrão para a chave de assinatura id_token`,
  [`Default keystore for temporary token signing key`]: `Keystore padrão para a chave de assinatura do token temporário`,
  [`Default rate-limit`]: `Limite de taxa padrão`,
  [`Default scopes`]: `Escopos padrão`,
  [`Default table entry`]: `Entrada de tabela padrão`,
  [`Default validator endpoint`]: `Terminal do validador padrão`,
  [`Default validator endpoint set by OAuth provider`]: `Terminal do validador padrão configurado pelo provedor OAuth`,
  [`Default value - no extra constraints apply.`]: `Valor padrão - nenhuma restrição extra se aplica.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Defina um provedor OAuth de terceiros para emitir e validar tokens para proteger o acesso à API.`,
  [`Define catalog specific values for this property`]: `Defina valores específicos de catálogo para essa propriedade`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Definir grupos que gerenciam APIs, produtos, catálogos, aplicativos e configurações relacionadas.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Defina os conjuntos de políticas a serem executados para condições específicas. Múltiplos casos podem ser definidos e cada caso pode ser associado a uma condição arbitrária. Somente o primeiro caso de correspondência será executado.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Definir a origem da proteção da API. Pelo menos uma opção deve ser ativada.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `O servidor de autorização do OIDC redirecionará o código de autorização para os terminais a seguir. Este terminal de redirecionamento é necessário quando um cliente registra o aplicativo no provedor OIDC. Por exemplo, se esse registro do usuário for usado por uma organização do provedor, o cliente deverá registrar o terminal de redirecionamento da organização do provedor com o provedor OIDC.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Definir as entradas a serem usadas no mapa. Também é possível designar um título e uma descrição para o mapa.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Defina os terminais do provedor OpenID para enviar uma solicitação de autenticação para o provedor OpenID`,
  [`Define the outputs to be used in the map.`]: `Definir as saídas a serem usadas no mapa.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Defina o número de dias de avaliação grátis e a precificação do plano. Os usuários serão faturados com base na data de assinatura.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Define se o fluxo continua quando um erro é lançado durante uma execução de política. Se não selecionado, um fluxo de captura é acionado.`,
  [`Definition`]: `Definição`,
  [`Definition Object`]: `Objeto de Definição`,
  [`Definitions`]: `Definições`,
  [`Delete`]: `Excluir`,
  [`Delete Application`]: `Excluir Aplicativo`,
  [`Delete Cloud Connection.`]: `Excluir conexão em nuvem.`,
  [`Delete Selected APIs`]: `Excluir APIs selecionadas`,
  [`Delete selected client profiles`]: `Excluir perfis de clientes selecionados`,
  [`Delete selected keystores`]: `Excluir os keystores selecionados`,
  [`Delete selected mail servers`]: `Excluir os servidores de e-mail selecionados`,
  [`Delete Selected Products`]: `Excluir produtos selecionados`,
  [`Delete selected truststores`]: `Excluir os armazenamentos confiáveis selecionados`,
  [`Delete selected user registries`]: `Excluir os registros de usuários selecionados`,
  [`Delete Staged Product`]: `Excluir estágio do produto`,
  [`Delete a space`]: `Excluir um espaço`,
  [`Delete a catalog`]: `Excluir um catálogo`,
  [`Delete API Key`]: `Excluir chave de API`,
  [`Delete API Key failed`]: `Falha ao excluir Chave de API`,
  [`Delete availability zone`]: `Excluir zona de disponibilidade`,
  [`Delete selected requests`]: `Excluir solicitações selecionadas`,
  [`Delete row`]: `Excluir linha`,
  [`Delete|title`]: `Excluir`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `A exclusão de um catálogo poderá falhar se você tiver um grande número de organizações de consumidores, aplicativos, produtos publicados e outros recursos.  Considere a limpeza desses recursos antes de excluir o catálogo.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `A exclusão de uma organização do provedor poderá falhar se você tiver um grande número de catálogos, organizações de consumidores, aplicativos, produtos publicados e outros recursos. Considere a limpeza desses recursos antes de excluir a organização do provedor.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `A exclusão de um espaço poderá falhar se você tiver um grande número de organizações de consumidores, aplicativos, produtos publicados e outros recursos.  Considere a limpeza desses recursos antes de excluir o espaço.`,
  [`Deleting {{name}}...`]: `Excluindo {name}...`,
  [`Deprecate`]: `Descontinuar`,
  [`Deprecate product`]: `Descontinuar produto`,
  [`Deprecated`]: `Descontinuado`,
  [`Deprecate|permission`]: `Descontinuar`,
  [`Description of gateway`]: `Descrição do gateway`,
  [`Description text for the behaviour of the modal.`]: `Texto de descrição do comportamento do modal.`,
  [`Descriptive name`]: `Nome descritivo`,
  [`Design`]: `Design`,
  [`Designate a current member as owner of a consumer organization.`]: `Designe um membro atual como proprietário de uma organização de consumidores.`,
  [`Designate a current member as owner of a provider organization.`]: `Designe um membro atual como o proprietário de uma organização do provedor.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Destino`,
  [`Determines which credential-extraction method to use.`]: `Determina qual método de extração de credencial usar.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Determine se deve incluir este tipo/campo e todos os argumentos aplicáveis no esquema.`,
  [`Develop`]: `Desenvolver`,
  [`Develop APIs`]: `Desenvolver APIs`,
  [`Develop APIs and products`]: `Desenvolver APIs e produtos`,
  [`Developer`]: `Desenvolvedor`,
  [`developer`]: `desenvolvedor`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `Comunidade de desenvolvedores`,
  [`Developers can request a state upgrade from development to production`]: `Os desenvolvedores podem solicitar um upgrade de estado de desenvolvimento para produção`,
  [`Development`]: `Desenvolvimento`,
  [`Directive`]: `Diretiva`,
  [`Director Endpoint`]: `Terminal do diretor`,
  [`Director TLS client profile`]: `Perfil do cliente TLS do diretor`,
  [`Disable`]: `Desativar`,
  [`Disable Activity log`]: `Desativar o log de atividades`,
  [`Disable Self-Service Onboarding`]: `Desativar a integração de autoatendimento`,
  [`Disable spaces`]: `Desativar os espaços`,
  [`Disable buffering`]: `Desativar armazenamento em buffer`,
  [`Disable Self-Service Onboarding Approval`]: `Desativar aprovação de integração de autoatendimento`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Desativar a substituição de tempo limite do convite das organizações de consumidores.`,
  [`Disabled`]: `Desativado`,
  [`disabled`]: `desativada`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `A desativação de integração de autoatendimento requer que todos os desenvolvedores de aplicativos sejam convidados pelo provedor de API, um proprietário da organização do consumidor existente ou um administrador.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `A desativação da aprovação de integração de autoatendimento aprovará automaticamente todas as solicitações de integração.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `A desativação não irá configurar o tempo limite de convite das organizações de consumidores com o tempo limite do catálogo.`,
  [`Discover targets`]: `Descobrir os destinos`,
  [`Display table checkboxes`]: `Exibir caixas de opções da tabela`,
  [`Display vanity endpoint`]: `Exibir terminal personalizado`,
  [`Do not publish API (continue editting)`]: `Não publicar API (continuar edição)`,
  [`Do not require applications or users to authenticate`]: `Não requerer aplicativos ou usuários para a autenticação`,
  [`Do not require authentication`]: `Não requerer autenticação`,
  [`Do not share the resource with provider organizations`]: `Não compartilhe o recurso com organizações do provedor`,
  [`Do not share the API gateway with provider organizations`]: `Não compartilhe o API Gateway com organizações do provedor`,
  [`Do not share the service with provider organizations`]: `Não compartilhe o serviço com organizações do provedor`,
  [`Do not use Dynamic DNS`]: `Não usar DNS Dinâmico`,
  [`Do you want to remove these references?`]: `Deseja remover essas referências?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docs do Docker`,
  [`Docs`]: `Documentos`,
  [`Document Editor`]: `Editor de documentos`,
  [`Documentation and tutorials with step-by-step instructions`]: `Documentação e tutoriais com instruções passo a passo`,
  [`Domain name`]: `Nome de domínio`,
  [`Domain Name`]: `Nome de Domínio`,
  [`Domains handled by gateway via SNI`]: `Domínios manipulados por gateway via SNI`,
  [`Don't have an account?`]: `Não tem uma conta?`,
  [`Done`]: `Pronto`,
  [`Download`]: `Fazer download`,
  [`Download Gateway`]: `Fazer download do Gateway`,
  [`Download and install`]: `Fazer download e instalar`,
  [`Download and set up DataPower API Gateway`]: `Fazer download e configurar o DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Faça download e configure o DataPower API Gateway para uso no local ou em qualquer nuvem.`,
  [`Download for Linux`]: `Download para Linux`,
  [`Download for Mac`]: `Download para Mac`,
  [`Download for Windows`]: `Download para Windows`,
  [`Download gateway`]: `Fazer download do gateway`,
  [`Download schema`]: `Fazer download do esquema`,
  [`Download Toolkit`]: `Fazer download do kit de ferramentas`,
  [`Download toolkit`]: `Fazer download do kit de ferramentas`,
  [`Download toolkit and credentials for various platforms`]: `Faça download do kit de ferramentas e de credenciais para várias plataformas`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Credenciais transferidas por download não localizadas para esta conexão em nuvem. O login usará as credenciais padrão.`,
  [`Dutch`]: `Holandês`,
  [`Draft {type} creation failed`]: `A {type} criação do rascunho falhou`,
  [`Drafts`]: `Rascunhos`,
  [`Drag and drop files here or click to upload`]: `Arraste e solte arquivos aqui ou clique para fazer upload`,
  [`Drag & Drop`]: `Arrastar e soltar`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `DNS Dinâmico`,
  [`Dynamic OAuth configuration from a URL`]: `Configuração de OAuth dinâmica a partir de uma URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Configuração de OAuth dinâmica a partir de uma sequência de caracteres literal`,
  [`Dynamic group`]: `Grupo dinâmico`,
  [`Dynamic table entries`]: `Entradas de tabela dinâmicas`,
  [`ENABLE`]: `ATIVAR`,
  [`Encoded`]: `Codificado`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Cada Espaço é usado por uma equipe de desenvolvimento do provedor de API diferente e tem seu próprio conjunto de recursos de gerenciamento relacionados especificamente às APIs que a equipe associada publica nesse Espaço, permitindo que cada equipe gerencie suas APIs independentemente. Quando você monta ou publica uma API em um Catálogo que tem Espaços ativados, você especifica o Espaço dentro desse Catálogo no qual deseja montar ou publicar.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Cada organização do provedor possui um conjunto de APIs, produtos, planos e catálogos.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Crie, proteja, gerencie, compartilhe e analise facilmente APIs localizadas na nuvem e no local.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Cada organização do provedor possui um conjunto de APIs, produtos, planos e catálogos. [Saiba mais]({link})`,
  [`Edit`]: `Editar`,
  [`Edit access token TTL`]: `Editar o TTL do token de acesso`,
  [`Edit admin organization invitation timeout`]: `Editar o tempo limite do convite da organização do administrador`,
  [`Edit API`]: `Editar a API`,
  [`Edit API Connect UI`]: `Editar IU do API Connect`,
  [`Edit API endpoint for unenforced APIs`]: `Editar o terminal de API para APIs não aplicadas`,
  [`Edit API gateway service`]: `Editar o serviço de gateway da API`,
  [`Edit API Path`]: `Editar Caminho da API`,
  [`Edit API security definition`]: `Editar a definição de segurança da API`,
  [`Edit API user registries`]: `Editar os registros do usuário da API`,
  [`Edit analytics service`]: `Editar o serviço de Analítica`,
  [`Edit application`]: `Editar aplicativo`,
  [`Edit Assembly`]: `Editar conjunto`,
  [`Edit authentication URL user registry`]: `Editar o registro do usuário da URL de autenticação`,
  [`Edit availability zone`]: `Editar zona de disponibilidade`,
  [`Edit Availablity Zone`]: `Editar zona de disponibilidade`,
  [`Edit and apply constraints to your selected items.`]: `Edite e aplique restrições em seus itens selecionados.`,
  [`Edit billing integration`]: `Editar integração de faturamento`,
  [`Edit Catalog Details`]: `Editar detalhes do catálogo`,
  [`Edit cloud setting invitation timeout`]: `Editar o tempo limite do convite de configuração de nuvem`,
  [`Edit constraints`]: `Editar restrições`,
  [`Edit consumer organization`]: `Editar a organização do consumidor`,
  [`Edit DNS Scheme`]: `Editar esquema de DNS`,
  [`Edit DataPower API gateway service`]: `Editar o serviço DataPower API Gateway`,
  [`Edit DataPower gateway service`]: `Editar o serviço DataPower Gateway`,
  [`Edit definition`]: `Editar definição`,
  [`Edit dynamic DNS`]: `Editar Sistema Dinâmico de Nomes de Domínio`,
  [`Edit email server`]: `Editar servidor de e-mail`,
  [`Edit extensions`]: `Editar extensões`,
  [`Edit Gateway Service`]: `Editar serviço de gateway`,
  [`Edit gateway`]: `Editar gateway`,
  [`Edit gateway extension`]: `Editar a extensão de gateway`,
  [`Edit Gateways`]: `Editar gateways`,
  [`Edit IBM-managed gateway`]: `Editar gateway gerenciado pela IBM`,
  [`Edit Invitation Timeout`]: `Editar tempo limite de convite`,
  [`Edit gateways`]: `Editar gateways`,
  [`Edit invitation timeout`]: `Editar o tempo limite do convite`,
  [`Edit JSON`]: `Editar JSON`,
  [`Edit keystore`]: `Editar o keystore`,
  [`Edit LDAP user registry`]: `Editar o registro do usuário LDAP`,
  [`Edit LTPA Token`]: `Editar token LTPA`,
  [`Edit lifecycle approvals`]: `Editar aprovações de ciclo de vida`,
  [`Edit local user registry`]: `Editar o registro do usuário local`,
  [`Edit native OAuth provider`]: `Editar o provedor OAuth nativo`,
  [`Edit New Billing System`]: `Editar novo sistema de faturamento`,
  [`Edit Notification Server`]: `Editar servidor de notificação`,
  [`Edit Notification template`]: `Editar o modelo de notificação`,
  [`Edit OAuth Provider`]: `Editar o Provedor OAuth`,
  [`Edit OAuth provider details`]: `Editar os detalhes do provedor OAuth`,
  [`Edit OAuth Provider Visibility`]: `Editar Visibilidade do Provedor OAuth`,
  [`Edit OIDC User Registry`]: `Editar o registro do usuário OIDC`,
  [`Edit Onboarding`]: `Editar a integração`,
  [`Edit Operation`]: `Editar Operação`,
  [`Edit Organization`]: `Editar organização`,
  [`Edit Path`]: `Editar Caminho`,
  [`Edit Path Parameters`]: `Editar parâmetros de caminho`,
  [`Edit notification template`]: `Editar o modelo de notificação`,
  [`Edit OAuth provider`]: `Editar provedor OAuth`,
  [`Edit OAuth provider visibility`]: `Editar a visibilidade do provedor OAuth`,
  [`Edit OIDC user registry`]: `Editar o registro do usuário OIDC`,
  [`Edit onboarding`]: `Editar a integração`,
  [`Edit operation`]: `Editar Operação`,
  [`Edit organization`]: `Editar a organização`,
  [`Edit path`]: `Editar Caminho`,
  [`Edit path parameters`]: `Editar os parâmetros do caminho`,
  [`Edit plan`]: `Editar plano`,
  [`Edit portal`]: `Editar o portal`,
  [`Edit portal service`]: `Editar o serviço do portal`,
  [`Edit Portal Settings`]: `Editar Configurações do Portal`,
  [`Edit product`]: `Editar produto`,
  [`Edit product APIs`]: `Editar APIs do produto`,
  [`Edit Product Subscribability`]: `Editar a capacidade de assinatura do produto`,
  [`Edit Product Visibility`]: `Editar visibilidade do produto`,
  [`Edit Property`]: `Editar propriedade`,
  [`Edit Provider Organization`]: `Editar organização do provedor`,
  [`Edit Refresh Token TTL`]: `Editar TTL do token de atualização`,
  [`Edit remote gateway`]: `Editar gateway remoto`,
  [`Edit Reset Password TTL`]: `Editar TTL da reconfiguração de senha`,
  [`Edit Role`]: `Editar Função`,
  [`Edit Role Default for Provider Organizations`]: `Editar função padrão para organizações do provedor`,
  [`Edit Role for Provider Organizations`]: `Editar função para organizações do provedor`,
  [`Edit property`]: `Editar propriedade`,
  [`Edit provider organization`]: `Editar organização do provedor`,
  [`Edit refresh token TTL`]: `Editar o TTL do token de atualização`,
  [`Edit reset password TTL`]: `Editar o TTL da reconfiguração de senha`,
  [`Edit role`]: `Editar função`,
  [`Edit role default for Provider Organizations`]: `Editar o padrão de função para as Organizações do provedor`,
  [`Edit role for Provider Organizations`]: `Editar a função padrão para as Organizações do provedor`,
  [`Edit Roles`]: `Editar funções`,
  [`Edit Schema in JSON/XML`]: `Editar esquema em JSON/XML`,
  [`Edit security definition`]: `Editar a definição de segurança`,
  [`Edit sender`]: `Editar o remetente`,
  [`Edit sender & email server`]: `Editar o remetente e o servidor de e-mail`,
  [`Edit sender Info`]: `Editar as informações do remetente`,
  [`Edit service visibility`]: `Editar a visibilidade do serviço`,
  [`Edit show/hide settings`]: `Editar configurações de mostrar/ocultar`,
  [`Edit space details`]: `Editar os detalhes do espaço`,
  [`Edit TLS client profile`]: `Editar o perfil do cliente TLS`,
  [`Edit TLS client profile visibility`]: `Editar a visibilidade do perfil do cliente TLS`,
  [`Edit TLS server profile`]: `Editar o perfil do servidor TLS`,
  [`Edit third party OAuth provider`]: `Editar o provedor OAuth de terceiros`,
  [`Edit truststore`]: `Editar o armazenamento confiável`,
  [`Edit unenforced API`]: `Editar a API não aplicada`,
  [`Edit user registries`]: `Editar os registros do usuário`,
  [`Edit user registry`]: `Editar registro do usuário`,
  [`Edit user registry visibility`]: `Editar a visibilidade do registro do usuário`,
  [`Edit user registry for API Manager`]: `Editar o registro do usuário para o API Manager`,
  [`Edit user registry for Cloud Manager`]: `Editar o registro do usuário para o Cloud Manager`,
  [`Edit vanity API endpoints`]: `Editar terminais API personalizados`,
  [`Edit visibility`]: `Editar visibilidade`,
  [`Edit XML`]: `Editar XML`,
  [`Edit app lifecycle approved`]: `Editar ciclo de vida do aplicativo aprovado`,
  [`Edit app lifecycle cancelled`]: `Editar ciclo de vida do aplicativo cancelado`,
  [`Edit app lifecycle denied`]: `Editar ciclo de vida do aplicativo negado`,
  [`Edit app lifecycle pending`]: `Edite o ciclo de vida pendente do aplicativo`,
  [`Edit app lifecycle request`]: `Editar solicitação de ciclo de vida do aplicativo`,
  [`Edit app reinstated`]: `Editar aplicativo restabelecido`,
  [`Edit app suspended`]: `Editar aplicativo suspenso`,
  [`Edit audit setting`]: `Editar a configuração de auditoria`,
  [`Edit definitions`]: `Editar definições`,
  [`Enable external group mapping`]: `Ativar mapeamento de grupos externos`,
  [`Edit extra constraints for the GraphQL schema`]: `Editar as restrições extras para o esquema GraphQL`,
  [`Edit GraphQL schema|title`]: `Editar o esquema GraphQL`,
  [`Edit inline schema`]: `Editar esquema sequencial`,
  [`Edit inputs`]: `Editar entradas`,
  [`Edit invitation`]: `Editar convite`,
  [`Edit mail server test connection`]: `Editar conexão de teste do servidor de correio`,
  [`Edit member invitation`]: `Editar convite do membro`,
  [`Edit notification templates`]: `Editar modelos de notificação`,
  [`Edit notification templates:`]: `Editar modelos de notificação:`,
  [`Edit operation list`]: `Editar lista de operação`,
  [`Edit or upload JSON schema`]: `Editar ou fazer upload do esquema JSON`,
  [`Edit outputs`]: `Editar saídas`,
  [`Edit password changed`]: `Editar senha alterada`,
  [`Edit password reset`]: `Editar reconfiguração de senha`,
  [`Edit plan APIs`]: `Editar APIs do plano`,
  [`Edit schema`]: `Editar esquema`,
  [`Edit schema|button`]: `Editar esquema`,
  [`Edit schema|title`]: `Editar esquema`,
  [`Edit settings for roles, notifications and more.`]: `Edite as configurações para funções, notificações e muito mais.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Edite configurações para registros do usuário, funções, terminais e mais`,
  [`Edit sign up`]: `Editar conexão`,
  [`Edit task product lifecycle approved`]: `Edite o ciclo de vida do produto de tarefa`,
  [`Edit task product lifecycle cancelled`]: `Editar ciclo de vida do produto de tarefa cancelado`,
  [`Edit task product lifecycle denied`]: `Edite o ciclo de vida negado do produto da tarefa`,
  [`Edit task product lifecycle pending`]: `Editar o ciclo de vida do produto de tarefa pendente`,
  [`Edit task product lifecycle request`]: `Editar solicitação de ciclo de vida do produto de tarefa`,
  [`Edit task subscription approved`]: `Editar assinatura de tarefa aprovada`,
  [`Edit task subscription cancelled`]: `Edite o cancelamento da assinatura da tarefa`,
  [`Edit task subscription denied`]: `Editar assinatura de tarefa negada`,
  [`Edit task subscription pending`]: `Editar assinatura de tarefa pendente`,
  [`Edit task subscription request`]: `Editar solicitação de assinatura de tarefa`,
  [`Edit template`]: `Editar modelo`,
  [`Edit the TLS client profile.`]: `Editar o perfil do cliente TLS.`,
  [`Edit the TLS Server profiles`]: `Edite os perfis do Servidor TLS`,
  [`Edit the keystore title.`]: `Edite o título do Armazenamento de chaves.`,
  [`Edit the organization summary details as required`]: `Edite os detalhes de resumo da organização conforme necessário`,
  [`Edit the organization title`]: `Edite o título da organização`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Edite os parâmetros para configurar um servidor de e-mail. Os campos são obrigatórios, a não ser que sejam designados como opcionais.`,
  [`Edit signed token`]: `Editar token assinado`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Edite o texto para o modelo de e-mail. As variáveis estão contidas em chaves e não podem ser editadas.`,
  [`Edit the truststore.`]: `Edite o armazenamento confiável.`,
  [`Edit the visibility for each resource.`]: `Edite a visibilidade para cada recurso.`,
  [`Edit user details`]: `Edita os detalhes do usuário`,
  [`Edit {notificationTemplate}`]: `Editar {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `Edite, monte, proteja e teste APIs`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `Edite, monte, proteja e teste APIs. Empacote APIs que usam produtos para a publicação para consumidores.`,
  [`Edit, upload, or infer XML schema`]: `Editar, fazer upload ou inferir esquema XML`,
  [`Editors`]: `Editores`,
  [`Edit|permission`]: `Editar`,
  [`Edit API key timeout`]: `Editar tempo limite da chave de API`,
  [`Edit Base64 encoding for temporary tokens`]: `Editar codificação em Base64 para tokens provisórios`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Deve ser fornecido o "Objeto criptográfico de assinatura de token de ID" ou a "Chave de assinatura de token de ID".`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Deve ser fornecido o "objeto de criptografia de verificação JWT" ou o "JWK de verificação JWT".`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Selecione a opção para usar o terminal que está definido na API ou forneça um terminal de validador padrão.`,
  [`Element name for JSON array elements`]: `O nome do elemento para elementos de matriz JSON`,
  [`Email`]: `E-mail`,
  [`Email address`]: `Endereço de e-mail`,
  [`Email Content`]: `Conteúdo do E-mail`,
  [`Email endpoint`]: `Terminal de e-mail`,
  [`Email required`]: `E-mail necessário`,
  [`Email sender`]: `Emissor de e-mail`,
  [`Email server`]: `Servidor de e-mail`,
  [`Email server configuration`]: `Configuração do servidor de e-mail`,
  [`Email servers`]: `Servidores de e-mail`,
  [`Empty case`]: `Caso vazio`,
  [`Empty catch`]: `Captura vazia`,
  [`Empty JSON array handling`]: `Manipulação de matriz JSON vazia`,
  [`Empty XML element handling`]: `Manipulação de elemento XML vazio`,
  [`Enable`]: `Ativar`,
  [`Enable API consumers to invite collaborators and assign roles`]: `Ativar os consumidores da API para convidar colaboradores e designar funções`,
  [`Enable application lifecycle`]: `Ativar o ciclo de vida do aplicativo`,
  [`Enable billing`]: `Ativar faturamento`,
  [`Enable gateway services`]: `Ativar os serviços de gateway`,
  [`Enable gateway services and policies`]: `Ativar os serviços e as políticas de gateway`,
  [`Enable GraphiQL`]: `Ativar GraphiQL`,
  [`Enable GraphiQL editor`]: `Ativar o editor do GraphiQL`,
  [`Enable GraphiQL Editor on browser`]: `Ativar editor do GraphiQL no navegador`,
  [`Enable JSON post processing`]: `Ativar o pós-processamento de JSON`,
  [`Enable lifecycle approvals`]: `Ativar as aprovações do ciclo de vida`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Habilite a extensão NONCE para evitar que as solicitações comprometidas sejam usadas novamente (reproduzidas)`,
  [`Enable OAuth providers`]: `Ativar os provedores OAuth`,
  [`Enable OIDC`]: `Ativar o OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Ativar o modelo de conexão OpenID para gerar tokens de ID.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Ative a extensão PKCE para permitir que os clientes públicos minimizem a ameaça de que o código de autorização seja interceptado`,
  [`Enable production mode`]: `Ativar o modo de produção`,
  [`Enable Proxy`]: `Ativar Proxy`,
  [`Enable Self-Service Onboarding`]: `Ativar integração de autoatendimento`,
  [`Enable spaces`]: `Ativar espaços`,
  [`Enable token management`]: `Ativar gerenciamento de token`,
  [`Enable buffering`]: `Ativar Armazenamento de Buffer`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `Permitir que os consumidores de API criem contas do portal de desenvolvimento sem requerer um convite.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `Ative o controle de acesso de compartilhamento de recurso de origem cruzada (CORS) para a sua API.`,
  [`Enable debug response headers`]: `Ativar cabeçalhos de resposta de depuração`,
  [`Enable proof key for code exchange`]: `Ativar a chave de prova de troca de código`,
  [`Enable publishing of this product`]: `Ativar publicação deste produto`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Habilite o redirecionamento do código de autorização de terceiros por meio do terminal do OIDC do Portal`,
  [`Enable security`]: `Ativar Segurança`,
  [`Enable Self-Service Onboarding Approval`]: `Ativar aprovação de integração de autoatendimento`,
  [`Enable the following gateway services and policies for this catalog`]: `Ative os seguintes serviços e políticas de gateway para este catálogo`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Permitir que os usuários configurem um evento de auditoria. Um evento de auditoria é um resumo detalhado de quem executou o evento e quando.`,
  [`Enable/disable custom notification templates`]: `Ativar/desativar modelos de notificação customizados`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Ative ou desative codificação em Base64 para convites e tokens de reconfiguração de senha`,
  [`Enable overriding consumer organizations invitation timeout`]: `Ativar a substituição do tempo limite de convite das organizações de consumidores`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `É possível ativar ou desativar a codificação em Base64 para tokens temporários. Os tokens temporários são convites e tokens de reconfiguração de senha. Quando a codificação em Base64 é ativada, o token temporário é codificado no formato Base64. Quando a codificação em Base64 é desativada, o token temporário permanece no formato JSON Web Token (JWT) e significa que o comprimento da URL recebido pelo usuário é reduzido.`,
  [`Enabled`]: `Ativado`,
  [`enabled`]: `ativada`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `A ativação do ciclo de vida do aplicativo permitirá que aplicativos em estado de desenvolvimento ou produção sejam encaminhados para diferentes gateways e terminais.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `A ativação da aprovação de integração de autoatendimento requererá que todas as solicitações de integração sejam aprovadas pelo provedor de API ou por um administrador.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `A ativação de integração de autoatendimento permite que um desenvolvedor de aplicativos se inscreva sem um convite do provedor de API, um proprietário da organização do consumidor existente ou um administrador.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `A ativação irá configurar o tempo limite de convite de todas as organizações de consumidores com o tempo limite de convite do catálogo.`,
  [`Encode Query params`]: `Codificar parâmetros de consulta`,
  [`Encrypt`]: `Criptografar`,
  [`Encrypt Crypto Object`]: `Criptografar objeto criptográfico`,
  [`Encrypt JWK variable name`]: `Criptografar o nome da variável do JWK`,
  [`Encrypt a buffer using the specified certificate`]: `Criptografe um buffer usando o certificado especificado`,
  [`Encryption Algorithm`]: `Algoritmo de Criptografia`,
  [`Endpoint`]: `Terminal`,
  [`Endpoint URLs`]: `URLs de terminal`,
  [`Endpoint behavior`]: `Comportamento do terminal`,
  [`Endpoint Behavior`]: `Comportamento do terminal`,
  [`Endpoint for unenforced APIs`]: `Terminal para APIs não aplicadas`,
  [`Endpoints`]: `Terminais`,
  [`Enforce Required Params`]: `Cumprir parâmetros obrigatórios`,
  [`Enforce the API by using API Connect Gateway.`]: `Aplicar a API usando o API Connect Gateway.`,
  [`Enforced`]: `Aplicado`,
  [`English`]: `Inglês`,
  [`Enter Summary`]: `Inserir resumo`,
  [`Enter URL`]: `Insira a URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Insira o nome do registro do usuário. Se estiver vazio, o registro do usuário local do gerenciador de API será usado por padrão.`,
  [`Enter a catalog name`]: `Insira um nome de catálogo`,
  [`Enter a Display Name`]: `Insira um nome de exibição`,
  [`Enter a space name`]: `Inserir um nome de espaço`,
  [`Enter a brief description`]: `Digite uma breve descrição`,
  [`Enter a display name`]: `Insira um nome de exibição`,
  [`Enter a temporary sign in password`]: `Insira uma senha de conexão provisória`,
  [`Enter an Organization Name`]: `Insira um Nome da Organização`,
  [`Enter an Organization Title`]: `Insira um Título da Organização`,
  [`Enter catalog title`]: `Insira o título do catálogo`,
  [`Enter details of the consumer organization`]: `Insira detalhes da organização do consumidor`,
  [`Enter details of the product`]: `Insira detalhes do produto`,
  [`Enter details of the provider organization`]: `Insira detalhes da organização do provedor`,
  [`Enter details of this API`]: `Insira detalhes desta API`,
  [`Enter email of the new user`]: `Insira o e-mail do novo usuário`,
  [`Enter first name of the new user`]: `Insira o nome do novo usuário`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Insira um ou mais endereços de e-mail. Use vírgulas para separar diversos endereços.`,
  [`Enter organization name`]: `Insira o nome da organização`,
  [`Enter organization title`]: `Insira o título da organização`,
  [`Enter password`]: `Digitar Senha`,
  [`Enter space title`]: `Insira o título do espaço`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Insira o terminal de API para o gateway fornecendo o nome do host e o número da porta. Por exemplo: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Insira os detalhes de resumo da API`,
  [`Enter the API summary details. [Learn more]({link})`]: `Insira os detalhes de resumo da API. [Saiba mais]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Insira a URL para o serviço de destino no qual deseja incluir um proxy`,
  [`Enter the catalog summary details`]: `Insira os detalhes de resumo do catálogo`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Insira os detalhes de resumo do catálogo; após sua criação, será possível configurá-lo completamente`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Insira o endereço de e-mail do usuário a ser convidado como um membro da organização do administrador`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Insira o endereço de e-mail do usuário a ser convidado como um membro do catálogo`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Insira o endereço de e-mail do usuário a ser convidado como um membro da organização do provedor`,
  [`Enter the email address of the user to invite as a member of the space`]: `Insira o endereço de e-mail do usuário convidado a ser um membro do espaço`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Insira o endereço de e-mail do usuário para o qual deseja transferir a propriedade`,
  [`Enter the full path to JSON or YAML file`]: `Insira o caminho completo para o arquivo JSON ou YAML`,
  [`Enter the full path to WSDL file`]: `Insira o caminho completo para o arquivo WSDL`,
  [`Enter the full path to YAML file`]: `Insira o caminho completo para o arquivo YAML`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Insira o nome completo de domínio para o terminal de ingestão de Analytics definido durante a instalação.`,
  [`Enter the last name of the new user`]: `Insira o sobrenome do novo usuário`,
  [`Enter the owner's email address to send the invitation`]: `Insira o endereço de e-mail do proprietário para enviar o convite`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Insira os parâmetros para configurar o serviço de gateway. Os campos são obrigatórios, a não ser que sejam designados como opcionais. O terminal é o terminal do serviço de gateway da API no DataPower. A base do terminal de API é o terminal do gateway da API para chamadas da API recebidas. Para SNI, insira * para permitir todos os hosts (necessário) e insira os nomes de hosts para permitir hosts específicos. Escolha o perfil TLS para suportar cada host.`,
  [`Enter the password for the certificate file if required.`]: `Insira a senha para o arquivo de certificado, se necessário.`,
  [`Enter the provider organization summary details`]: `Insira os detalhes de resumo da organização do provedor`,
  [`Enter the schema definitiom summary details`]: `Insira os detalhes de resumo de definição de esquema`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Insira os detalhes de resumo espaço; pode-se configurar totalmente o Espaço depois de criá-lo`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Insira os detalhes de resumo do espaço; após sua criação, será possível configurá-lo completamente`,
  [`Enter the summary details for the new product`]: `Insira os detalhes de resumo para o novo produto`,
  [`Enter the summary details for this role`]: `Insira os detalhes de resumo para esta função`,
  [`Enter title of the organization`]: `Insira o título da organização`,
  [`Enter user email`]: `Insira o e-mail do usuário`,
  [`Enter username of the existing user`]: `Insira o nome do usuário existente`,
  [`Enter username of the new user`]: `Insira o nome do novo usuário`,
  [`Error`]: `Erro`,
  [`Error ID`]: `ID do erro`,
  [`Error:`]: `Erro:`,
  [`Copy error ID to Clipboard`]: `Copiar ID de erro na área de transferência`,
  [`Error Console`]: `Console de Erro`,
  [`Error connecting`]: `Erro ao conectar`,
  [`Error connecting to GraphQL server`]: `Erro ao se conectar ao servidor GraphQL`,
  [`Error getting user info during login. Please login with different user.`]: `Erro ao obter informações do usuário durante o login. Faça login com um usuário diferente.`,
  [`Error content`]: `Conteúdo do erro`,
  [`Error message`]: `Mensagem de erro`,
  [`Error name`]: `Nome do erro`,
  [`Error status code`]: `Código de status do erro`,
  [`Error status code is invalid`]: `O código de status do erro é inválido`,
  [`Error status reason`]: `Motivo do status do erro`,
  [`Errors`]: `Erros`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Erros geralmente resultam de informações erradas e de erros de caracteres. Certifique-se de fornecer informações de provedor e de cliente corretas antes do teste.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Erro ao tentar obter a API da URL {url}. Verifique a URL, o nome do usuário e a senha. Mensagem de erro: {message}`,
  [`Event`]: `Evento`,
  [`Event gateway management client TLS client profile`]: `Perfil do cliente TLS do cliente de gerenciamento de gateway de eventos`,
  [`Event load`]: `Carregamento do Evento`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Cada organização de consumidor possui um proprietário. O proprietário possui permissões de administração completas e pode gerenciar assinaturas e aplicativos.`,
  [`Exchange authorization code for tokens`]: `Código de autorização do Exchange para tokens`,
  [`Exchange code for token`]: `Código de troca para token`,
  [`Execute`]: `Executar`,
  [`Execute migration target`]: `Executar o destino de migração`,
  [`Executes any catalog-level post-invoke handlers`]: `Executa quaisquer manipuladores de pós-chamada de nível de catálogo`,
  [`Executes any catalog-level pre-invoke handlers`]: `Executa quaisquer manipuladores de pré-chamada de nível de catálogo`,
  [`Existing`]: `Existente`,
  [`Existing {docType}`]: `{docType} existente`,
  [`Existing API Connect user`]: `Usuário do API Connect existente`,
  [`Existing OpenAPI`]: `OpenAPI Existente`,
  [`Existing product`]: `Produto existente`,
  [`Existing SOAP API has been validated`]: `A API SOAP existente foi validada`,
  [`Existing products in the catalog will be moved to a default space.`]: `Os produtos existentes no catálogo serão movidos para um espaço padrão.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Os usuários existentes são membros atuais. Também é possível ativar um novo membro a partir de outro registro do usuário.`,
  [`Expires On`]: `Expira em`,
  [`Expiry`]: `Validação`,
  [`Explorer`]: `Explorer`,
  [`Expose`]: `Expor`,
  [`Extend the schema to specify the cost of types and fields`]: `Estenda o esquema para especificar o custo de tipos e campos`,
  [`Extended`]: `Estendido`,
  [`Extension Type:`]: `Tipo de Extensão:`,
  [`Extensions`]: `Extensões`,
  [`Extension has been uploaded.`]: `A extensão foi transferida por upload.`,
  [`External`]: `Externa`,
  [`External Doc Description`]: `Descrição do documento externo`,
  [`External doc description`]: `Descrição do documento externo`,
  [`External Documentation URL`]: `URL da documentação externa`,
  [`External documentation URL`]: `URL da documentação externa`,
  [`External Role mapping`]: `Mapeamento de função externa`,
  [`External URL`]: `URL externo`,
  [`Extract Identity Settings`]: `Configurações de extração de identidade`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `ID do Fator`,
  [`Fail`]: `Falha`,
  [`Failed to change account password.`]: `Falha ao mudar a senha da conta.`,
  [`Failed to create analytics service {title}.`]: `Falha ao criar o serviço de Analítica {title}.`,
  [`Failed to create analytics insights service {title}.`]: `Falha ao criar o serviço Analytics Insights {title}.`,
  [`Analytics insights service {title} has been updated.`]: `O serviço Analytics Insights {title} foi atualizado.`,
  [`About the analytics insights service`]: `Sobre o serviço Analytics Insights`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `O serviço Analytics Insights coleta eventos de API do serviço de gateway. Cada serviço de gateway pode ter um serviço Analytics Insights associado.`,
  [`Advanced analytics insights configuration`]: `Configuração avançada do Analytics Insights`,
  [`Analytics insights service {title} has been created.`]: `O serviço Analytics Insights {title} foi criado.`,
  [`Analytics Insights details`]: `Detalhes do Analytics Insights`,
  [`Management endpoint on the analytics insights service`]: `Terminal de gerenciamento no serviço Analytics Insights`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Insira o nome de domínio completo do terminal diretor do Analytics Insights, definido durante a instalação.`,
  [`TYPE`]: `TYPE`,
  [`Failed to create email server`]: `Falha ao criar servidor de e-mail`,
  [`Failed to create gateway service`]: `Falha ao criar o serviço de gateway`,
  [`Failed to create gateway service {name}.`]: `Falha ao criar o serviço de gateway  {name}.`,
  [`Failed to create user registry {title}.`]: `Falha ao criar o registro do usuário {title}.`,
  [`Failed to delete a group`]: `Falha ao excluir um grupo.`,
  [`Failed to get TLS client profiles`]: `Falha ao obter perfis do cliente TLS`,
  [`Failed to get catalogs`]: `Falha ao obter catálogos`,
  [`Failed to get configured OAuth providers`]: `Falha ao obter provedores OAuth configurados`,
  [`Failed to get policies of gateways`]: `Falha ao obter políticas de gateways`,
  [`Failed to get user registries`]: `Falha ao obter registros do usuário`,
  [`Failed to introspect schema from server:`]: `Falha na introspecção do esquema do servidor:`,
  [`Failed to make API online`]: `Falha ao deixar a API on-line`,
  [`Failed to register catalog member {user}.`]: `Falha ao registrar o membro do catálogo {user}.`,
  [`Failed to remove analytics service: {arg}`]: `Falha ao remover o serviço de analítica: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Falha ao remover a zona de disponibilidade: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Falha ao remover o serviço do gateway: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Falha ao remover o servidor de e-mail: {arg}`,
  [`Failed to remove portal service: {arg}`]: `Falha ao remover o serviço do portal: {arg}`,
  [`Failed to create {name}.`]: `Criação de {name} com falha.`,
  [`Failed to sign out! Please check the console for details.`]: `Falha ao sair. Verifique o console para obter detalhes.`,
  [`Failed to update {name}.`]: `Atualização de {name} com falha.`,
  [`Failed to update OAuth provider`]: `Atualização do provedor OAuth com falha`,
  [`Failed to update account.`]: `Falha ao atualizar a conta.`,
  [`Failed to update analytics service {title}.`]: `Falha ao atualizar o serviço de Analítica {title}.`,
  [`Failed to update gateway service {title}.`]: `Falha ao atualizar o serviço de gateway {title}.`,
  [`Failed to update task`]: `Falha ao atualizar tarefa`,
  [`Failed to create app {title}`]: `Falha ao criar o app {title}`,
  [`Feature flags`]: `Sinalizações de recurso`,
  [`Fetch schema`]: `Buscar esquema`,
  [`Fetched endpoints`]: `Terminais buscados`,
  [`Field`]: `Campo`,
  [`Field label`]: `Rótulo do campo`,
  [`Field Mapping`]: `Mapeamento de Campo`,
  [`Field name`]: `Nome do Campo`,
  [`Field weight`]: `Peso do campo`,
  [`File Name`]: `Nome do Arquivo`,
  [`File Preview`]: `Visualização de arquivo`,
  [`File contains validation errors`]: `O arquivo contém erros de validação`,
  [`File is not a valid OpenAPI definition`]: `O arquivo não é uma definição válida do OpenAPI`,
  [`File is not a valid extension format.`]: `O arquivo não é um formato de extensão válido.`,
  [`File is not a valid {fileType} format.`]: `O arquivo não é um formato {fileType} válido.`,
  [`File name`]: `Nome do Arquivo`,
  [`File upload`]: `Upload de Arquivo`,
  [`File uploaded is not a valid file`]: `O arquivo transferido por upload não é um arquivo válido`,
  [`File uploaded successfully`]: `Arquivo Transferido por Upload com Sucesso`,
  [`File was updated and validated!`]: `O arquivo foi atualizado e validado!`,
  [`File was uploaded and validated!`]: `O arquivo foi transferido por upload e validado!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `O arquivo {draftFilePath} existe. Tentar mudar a combinação de nome/versão do rascunho`,
  [`Fill out the details of this service`]: `Preencha os detalhes deste serviço`,
  [`Filter`]: `Filtrar`,
  [`Filter by operation`]: `Filtrar por operação`,
  [`Filter policies`]: `Políticas de filtro`,
  [`Final Action`]: `Ação Final`,
  [`Find existing user`]: `Localize o usuário existente`,
  [`Find expert answers in the API Connect community forum`]: `Encontre respostas de especialistas no fórum da comunidade do API Connect`,
  [`Find expert answers in the API Connect community forum.`]: `Localize respostas de especialistas no fórum da comunidade do API Connect`,
  [`Find schema`]: `Localizar esquema`,
  [`Finger Print`]: `Impressão Digital`,
  [`Fingerprint SHA-1`]: `SHA-1 de impressão digital`,
  [`Fingerprint SHA-256`]: `SHA-256 de impressão digital`,
  [`Finish`]: `Concluir`,
  [`First name`]: `Nome`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Primeiramente, especifique a URL do terminal que o gateway usa para o tráfego da API. O valor deve ser um nome de domínio completo e público com caminhos adicionais que são específicos para suas chamadas API, por exemplo: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Primeiramente, especifique a URL do terminal de gerenciamento de gateway. Para gateways em execução em ambientes do Kubernetes, o valor deve corresponder ao terminal especificado por meio do comando de configuração do gateway set gwy apic-gw-service. Para dispositivos de gateway, o valor deve estar no formato http://<ip-address-for-gateway>:3000. Ao trabalhar com um cluster de gateway, especifique o address:port do balanceador de carga.`,
  [`Fit to screen`]: `Ajustar à tela`,
  [`Flow`]: `Fluxo`,
  [`Follow redirects`]: `Seguir redirecionamentos`,
  [`Found In`]: `Localizado em`,
  [`Fonts contained in the following file are available under the`]: `As fontes contidas no arquivo a seguir estão disponíveis em`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `Para definições de WSDL, faça upload de um arquivo zip contendo tanto o arquivo WSDL quanto a definição de API (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Para cada plano no produto original, selecione o plano no produto de substituição para o qual você deseja migrar as assinaturas`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Para cada plano no produto de origem, selecione o plano no produto de destino para o qual você deseja migrar as assinaturas`,
  [`For guidance on the available command set, access the CLI's help`]: `Para obter orientação sobre o conjunto de comandos disponível, acesse a ajuda da CLI`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Para sites usando provedores OAuth nativos, opcionalmente, especifique o segredo compartilhado que será usado por todas as chamadas de API.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Para o LDAP gravável, selecione Gerenciado pelo usuário e inclua o mapeamento de seus nomes do atributo de registro LDAP de origem para os valores de atributo LDAP do API Connect. As propriedades de perfil do usuário padrão que o API Connect requer durante o registro do usuário são username, first_name, last_name, email e password. Um exemplo de configuração de mapeamento é fornecido. Edite o mapeamento para atender aos seus requisitos.`,
  [`Forbidden`]: `Proibido`,
  [`Forget token`]: `Esquecer token`,
  [`Forgot password?`]: `Esqueceu a senha?`,
  [`Forgot password`]: `Senha esquecida`,
  [`Form`]: `Formulário`,
  [`Forum`]: `Fórum`,
  [`French`]: `Francês`,
  [`From File`]: `Do Arquivo`,
  [`From Sample JSON`]: `Da JSON de amostra`,
  [`From Sample XML`]: `Da XML de amostra`,
  [`From URL`]: `Da URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Por meio do serviço GraphQL existente (proxy GraphQL)`,
  [`From existing OpenAPI service`]: `A partir do serviço OpenAPI existente`,
  [`From existing WSDL service (REST proxy)`]: `A partir do serviço WSDL existente (proxy REST)`,
  [`From existing WSDL service (SOAP proxy)`]: `A partir do serviço WSDL existente (proxy SOAP)`,
  [`From target service`]: `A partir do serviço de destino`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `A partir das operações definidas na definição WSDL do serviço SOAP, selecione aquelas que você deseja usar em sua API e especifique os métodos e caminhos de URL.`,
  [`Gateway Extensions`]: `Extensões de gateway`,
  [`Gateway extension`]: `Extensão de gateway`,
  [`Gateway Services`]: `Serviços de gateway`,
  [`Gateway Type`]: `Tipo de Gateway`,
  [`Gateway by Name`]: `Gateway por nome`,
  [`Gateway services`]: `Serviços de Gateway`,
  [`Gateway type`]: `Tipo de gateway`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `Licença do GPL v2`,
  [`GROUP`]: `GRUPO`,
  [`Group name`]: `Nome do grupo`,
  [`Gateway`]: `Gateway`,
  [`Gateways`]: `Gateways`,
  [`Gateway details`]: `Detalhes do gateway`,
  [`Gateway Details`]: `Detalhes de Gateway`,
  [`Gateway Error`]: `Erro de gateway`,
  [`Gateway service details`]: `Detalhes do serviço de gateway,`,
  [`Gateway management client TLS client profile`]: `Perfil do cliente TLS do cliente de gerenciamento de gateway`,
  [`Gateway management client keystore`]: `Keystore do cliente de gerenciamento de gateways`,
  [`Gateway management client truststore`]: `Armazenamento confiável do cliente de gerenciamento de gateways`,
  [`Gateway processing status`]: `Status de processamento de gateway`,
  [`Gateway policies (TODO)`]: `Políticas de gateway (PENDÊNCIA)`,
  [`Gateway service`]: `Serviço de Gateway`,
  [`Gateway Service US East (TODO)`]: `Serviço de gateway do leste dos EUA (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `O serviço do gateway {arg} foi removido.`,
  [`Gateway service configuration`]: `Configuração de serviço de gateway`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `O host do serviço de gateway não está disponível. O teste de API (Experimente) no Explorer não será ativado.`,
  [`Gateway service configuration has been updated.`]: `A configuração do serviço de gateway foi atualizada.`,
  [`Gateway service {title} has been created.`]: `O serviço de gateway {title} foi criado.`,
  [`Gateway service {title} has been updated.`]: `O serviço de gateway  {title} foi atualizado.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Os serviços de gateway são gerenciados em cada espaço quando há espaços ativados. Acesse as configurações de espaço para gerenciar os serviços de gateway.`,
  [`Gateway version`]: `Versão do gateway`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Gateways por página`,
  [`Gather-and-send`]: `Reunir e enviar`,
  [`Gather-only`]: `Somente reunir`,
  [`General configuration`]: `Configuração Geral`,
  [`General information about the API.`]: `Informações gerais sobre a API.`,
  [`Generate`]: `Gerar`,
  [`Generate an OpenAPI definition for the proxy`]: `Gerar uma definição de OpenAPI para o proxy`,
  [`Generate JSON Web Token (JWT)`]: `Gerar JSON Web Token (JWT)`,
  [`Generate JWT`]: `Gerar JWT`,
  [`Generate LTPA Token`]: `Gerar token LTPA`,
  [`Generate OAuth Access Tokens`]: `Gerar tokens de acesso OAuth`,
  [`Generate OAuth access tokens`]: `Gerar tokens de acesso OAuth`,
  [`Generate a default product`]: `Gerar um produto padrão`,
  [`Generate access token`]: `Gerar token de acesso`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Gere um token LTPA para que sua API possa se autenticar seguramente com os servidores da web WebSphere Application Server.`,
  [`Generate an object`]: `Gerar um objeto`,
  [`Generate authorization code`]: `Gerar código de autorização`,
  [`Generate from sample JSON`]: `Gerar a partir do JSON de amostra`,
  [`Generate from sample XML`]: `Gerar a partir do XML de amostra`,
  [`Generate tests automatically to build high quality APIs`]: `Gerar testes automaticamente para construir APIs de alta qualidade`,
  [`Generated OpenAPI 2.0 definition`]: `Definição do OpenAPI 2.0 gerada`,
  [`Generated default product for the API`]: `Produto padrão gerado para a API`,
  [`Generated name for use in commands and API calls`]: `Nome gerado para uso em comandos e chamadas de API`,
  [`German`]: `Alemão`,
  [`Get Base DN`]: `Obter DN base`,
  [`Get Code`]: `Obter Código`,
  [`Get Code Snippet`]: `Obter Fragmento de Código`,
  [`Get Support`]: `Obter Suporte`,
  [`Get Token`]: `Obter token`,
  [`Get activation link`]: `Obter link de ativação`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Comece registrando um serviço de gateway. Isso permitirá testar os terminais ao configurar outros serviços. Ao registrar um serviço, você o verá aqui. [Saiba mais]({link})`,
  [`Get subscription ID`]: `Obter ID da assinatura`,
  [`Getting Started`]: `Introdução`,
  [`Getting started`]: `Iniciar`,
  [`Git information`]: `Informações do Git`,
  [`Github`]: `Github`,
  [`Global policies`]: `Políticas Globais`,
  [`Go to Spaces`]: `Acessar Espaços`,
  [`Go to the Test tab`]: `Acesse a guia Teste`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `Limites de taxa do GraphQL`,
  [`GraphQL service`]: `Serviço GraphQL`,
  [`GraphQL schema`]: `Esquema do GraphQL`,
  [`GraphQL Schema`]: `Esquema GraphQL`,
  [`GraphQL schema has been successfully validated.`]: `O esquema do GrgraphQL foi validado com sucesso.`,
  [`GraphQL Schema URL`]: `URL do esquema do GraphQL`,
  [`GraphQL send type`]: `Tipo de envio de GraphQL`,
  [`GraphQL Websocket Upgrade`]: `Upgrade do WebSocket do GraphQL`,
  [`Group Based DN`]: `DN baseado em grupo`,
  [`Group {title} added`]: `Grupo {title} incluído`,
  [`Group {title} updated`]: `Grupo {title} atualizado`,
  [`Group {title} has been deleted`]: `O grupo {title} foi excluído`,
  [`Groups`]: `Grupos`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `NOME DO HOST`,
  [`HOST URL`]: `URL do HOST`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `Formulário HTML`,
  [`HTML form time limit`]: `Limite de tempo do formulário HTML`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 necessário`,
  [`HTTP Header`]: `Cabeçalho de HTTP`,
  [`HTTP Method`]: `Método de HTTP`,
  [`HTTP Type`]: `Tipo de HTTP`,
  [`HTTP Version`]: `Versão HTTP`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Barras de direção`,
  [`Hard limit`]: `Limite máximo`,
  [`Header`]: `Cabeçalho`,
  [`Header control`]: `Controle de cabeçalho`,
  [`Header name payload`]: `Carga útil de nome do cabeçalho`,
  [`Header name token`]: `Token de nome do cabeçalho`,
  [`Headers`]: `Cabeçalhos`,
  [`Hello`]: `Olá`,
  [`Hello!`]: `Olá!`,
  [`Hello [IBM](url)`]: `Olá [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Olá {name}`,
  [`Hello {name}!`]: `Olá {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Olá  {name}, [consulte o perfil IBM](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nAgradecemos por se inscrever para uma conta no Portal do Desenvolvedor do {catalogTitle}.\n\nPara concluir seu registro, use este link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nO administrador do Portal do Desenvolvedor da {consumerOrg} restringiu o aplicativo\n{appName}. As chamadas de API a partir desse aplicativo não serão mais bloqueadas pelo gateway da\nAPI.\n\nÉ possível visualizar os detalhes do aplicativo usando este link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Olá, {firstName}.\n\nO administrador do Portal do Desenvolvedor do {consumerOrg} suspendeu o aplicativo\n{appName}. As chamadas de API desse aplicativo serão bloqueadas pelo gateway de API.\n\nÉ possível visualizar os detalhes do aplicativo usando este link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Olá, {firstName}.\n\nO usuário {originator} solicitou aprovação para assinar o plano {planName}\npara a versão {productVersion} do produto de API {productName} do catálogo {catalog}\n.\n\nÉ possível abrir a solicitação em sua lista de tarefas usando este link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Olá, {firstName}.\n\nO usuário {originator} solicitou aprovação para assinar o aplicativo {appName}\nno plano {planName} para a versão {productVersion} do produto de API {productName}\nno catálogo {catalog}.\n\nÉ possível abrir a solicitação em sua lista de tarefas usando este link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nO usuário {originator} solicitou aprovação para {action} o aplicativo {appName} no catálogo \n{catalog}.\n\nÉ possível abrir a solicitação em sua lista de tarefas usando este link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Olá, {firstName}.\n\nO usuário {originator} solicitou aprovação para {action} versão {version}\ndo produto de API {productName} no catálogo {catalog}.\n\nÉ possível abrir a solicitação em sua lista de tarefas usando este link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Olá, {{firstName}},\n\nNós recebemos uma solicitação de reconfiguração da senha para a sua conta do usuário\n{{username}} no Portal do Desenvolvedor do {{catalog}}.\n\nSe você não fez essa solicitação, ignore essa mensagem. Caso contrário, é\npossível reconfigurar sua senha usando esse link:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Olá {firstName},\n\nRecebemos uma solicitação de reconfiguração de senha para sua conta de usuário\n{username} no IBM API Connect.\n\nPara reconfigurar sua senha, use este link:\n\n{link}\n\n(se você não fez a solicitação, ignore esta mensagem.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nRecebemos uma solicitação de reconfiguração de senha para sua conta de usuário\n{username} no Portal do Desenvolvedor do {catalog}.\n\nSe você não fez essa solicitação, ignore essa mensagem. Caso contrário é\n possível reconfigurar sua senha usando esse link \n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Olá {firstName},\n\nSua solicitação de criação de uma assinatura para o aplicativo {appName} no catálogo\n{catalog} está aguardando aprovação. Enviaremos outra notificação\nquando tivermos lidado com sua solicitação.\n\nÉ possível verificar o status do aplicativo aqui:\n\n{link}\n\nSe desejar cancelar a solicitação a qualquer momento, use este link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Olá, {{firstName}},\n\nSua solicitação de criação de uma assinatura para o aplicativo {{appName}} no plano \n{{planName}} para o produto de API {{productName}} versão\n{{productVersion}} foi aprovada.\n\n{{#if comments}}\nO aprovador incluiu os comentários a seguir:\n\n"{{{comments}}"\n\n{{/if}}\nÉ possível verificar o status do aplicativo aqui:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de criação de uma assinatura para o aplicativo {appName} para o plano\n{planName} para o produto de API {productName} versão \n{productVersion} foi aprovada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do app aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Olá, {{firstName}},\n\nSua solicitação de criação de uma assinatura para o aplicativo {{appName}} para o plano\n{{planName}} para o produto de API {{productName}} versão\n{{productVersion}} foi negada.\n\n{{#if comments}}\nO aprovador incluiu os comentários a seguir:\n\n"{{{comments}}"\n\n{{/if}}\nÉ possível verificar o status do aplicativo aqui:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Olá, {{firstName}}.\n\nSua solicitação para {{action}} o produto de API {{productName}}:{{version}} no catálogo \n{{catalog}} foi aprovada.\n\n{{#if comments}}\nO aprovador incluiu os comentários a seguir:\n\n{{comments}}\n\n{{/if}}\nÉ possível verificar o status do produto aqui:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o produto de API {productName}:{version} no catálogo \n{catalog} foi aprovada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do produto aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o produto de API {productName}:{version} no catálogo \n{catalog} foi negada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do produto aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o produto de API {productName}:{version} no catálogo \n{catalog} foi negada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do produto aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o produto de API {productName}:{version} no catálogo \n{catalog} está aguardando aprovação. Enviaremos outra notificação\nquando tivermos lidado com sua solicitação.\n\nÉ possível verificar o status do produto aqui:\n\n{link}\n\nSe desejar cancelar a solicitação a qualquer momento, use este link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o aplicativo {appName} no Portal do Desenvolvedor \n{consumerOrg} foi aprovada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do aplicativo aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o aplicativo {appName} no Portal do Desenvolvedor \n{consumerOrg} foi aprovada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do app aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o aplicativo {appName} no Portal do Desenvolvedor \n{consumerOrg} foi negada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do aplicativo aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o aplicativo {appName} no Portal do Desenvolvedor \n{consumerOrg} foi negada.\n\n{#if comments}\nO aprovador incluiu os comentários a seguir:\n\n{comments}\n\n{/if}\nÉ possível verificar o status do app aqui:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o aplicativo {appName} no Portal do Desenvolvedor {consumerOrg}\nestá aguardando aprovação. Enviaremos outra notificação\nquando tivermos lidado com sua solicitação.\n\nSe desejar cancelar a solicitação a qualquer momento, use este link:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador da conta do IBM API Connect convidou você para criar\numa organização do provedor de API. Uma organização do provedor permite que você e sua\nequipe desenvolvam APIs e gerenciem catálogos de produtos de API.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador do catálogo de API {catalog}, pertencente à organização do provedor\n{org} de API no IBM API Connect, convidou você\npara criar um espaço no catálogo. Um espaço permite que você e sua equipe\ngerenciem um conjunto de produtos de API em um catálogo de API.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador do catálogo de API {catalog}, pertencente à organização do provedor de API\n{org} no IBM API Connect, convidou você\npara o catálogo.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador do Portal do Desenvolvedor do {catalog} convidou\nvocê para criar uma organização do consumidor de API. Uma organização do consumidor\npermite que você e sua equipe acessem APIs e registrem aplicativos de cliente.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador da organização do consumidor de API {consumerOrg} no Portal do Desenvolvedor do {catalog}\nconvidou você para a organização.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador da organização do provedor de API {org} no IBM API\nConnect convidou você para criar um catálogo de API. Um catálogo permite que você\ne sua equipe publiquem e gerenciem uma coleção de produtos de API.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador da organização do provedor de API {org} no IBM API\nConnect convidou você para a organização.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador do espaço {space} no catálogo de API {catalog},\npertencente à organização do provedor de API {org} no IBM API Connect,\nconvidou você para o espaço.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Olá,\n\nA senha de sua conta de usuário {username}\nno IBM API Connect foi mudada com sucesso.\n\nÉ possível efetuar login no API Connect aqui:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Olá,\n\nA senha de sua conta de usuário {username}\nno Portal do Desenvolvedor {portalTitle} foi mudada.\n\nÉ possível efetuar login no Portal do Desenvolvedor do {portalTitle} aqui:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Olá,\n\nO administrador do sistema do IBM API Connect convidou você para a\norganização {org}.\n\nPara continuar, use o link abaixo:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Olá,\n\nEsta é uma mensagem de teste do IBM API Connect do servidor de e-mail \n{mailServer} configurado.\n\nSe recebeu essa mensagem conforme o esperado, o teste foi bem-sucedido!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Olá,\n\nO usuário {originator} retirou sua solicitação de assinatura do plano {planName} para a\nversão {productVersion} do produto da API {productName} no catálogo {catalog}.\n \nNenhuma ação adicional é necessária.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Olá,\n\nO usuário {originator} retirou sua solicitação para {action} o aplicativo {appName} no catálogo {catalog}.\n \nNenhuma ação adicional é necessária.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Olá,\n\nO usuário {originator} retirou sua solicitação para {action} a versão {version} do produto de API {productName}\nno catálogo {catalog}.\n \nNenhuma ação adicional é necessária.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Olá, esta é uma mensagem de teste do IBM API Connect do servidor de e-mail {{{mailServer}}} configurado. Se você recebeu esta mensagem como esperado, o teste foi bem-sucedido`,
  [`Hello|header`]: `Olá`,
  [`Help`]: `Ajuda`,
  [`Hi, Steve (todo)`]: `Olá, Steve (pendência)`,
  [`Hide`]: `Ocultar`,
  [`Hide field`]: `Ocultar campo`,
  [`Hide in schema`]: `Ocultar no esquema`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Ocultar novos tipos, campos, diretivas ou argumentos opcionais. Os itens que não forem mais suportados pelo back-end serão removidos.`,
  [`High`]: `Alto`,
  [`History`]: `Histórico`,
  [`Histories`]: `Históricos`,
  [`History log`]: `Log do histórico`,
  [`Home`]: `Início`,
  [`Host`]: `Host`,
  [`Host context variable`]: `Variável de contexto do host`,
  [`Host name`]: `Nome do host`,
  [`Host value (if present)`]: `Valor do host (se presente)`,
  [`Hostname`]: `Nome do host`,
  [`Hours`]: `Horas`,
  [`Http basic authentication schema`]: `Esquema de autenticação básica http`,
  [`Hmm, your search didn't return any results.`]: `A sua procura não retornou nenhum resultado.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect Cloud`,
  [`IBM Cloud`]: `Nuvem IBM`,
  [`IBM Cloud Authentication`]: `IBM Cloud Authentication`,
  [`IBM Cloud Docs`]: `IBM Cloud Docs`,
  [`IBM Schema`]: `Esquema IBM`,
  [`IBM managed`]: `Gerenciado pela IBM`,
  [`ID Name`]: `Nome do ID`,
  [`ID token custom claims`]: `Solicitações de customização do token de ID`,
  [`ID token issuer`]: `Emissor do token de ID`,
  [`ID token signing algorithm`]: `Algoritmo de assinatura do token de ID`,
  [`ID token signing crypto object`]: `Objeto criptográfico de assinatura de token de ID`,
  [`ID token signing key`]: `Chave de assinatura do token de ID`,
  [`ID token signing key identifier`]: `Identificador de chave de assinatura de token de ID`,
  [`IDENTITY PROVIDER`]: `PROVEDOR DE IDENTIDADE`,
  [`IDENTITY PROVIDER SUPPORT`]: `SUPORTE DO PROVEDOR DE IDENTIDADE`,
  [`Identification`]: `Identificação`,
  [`Identity extraction`]: `Extração de identidade`,
  [`If`]: `Se`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Se o modo de Produção estiver desativado, o que significa que este é um Catálogo de desenvolvimento, todas as operações de publicação serão forçadas e quaisquer conflitos serão resolvidos automaticamente, permitindo, por exemplo, publicar novamente a mesma versão de produto repetidamente durante o teste.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Se o modo de Produção estiver ativado, o que significa que este é um Catálogo de produção, você será impedido de publicar um produto no Catálogo se houver conflitos com qualquer Produto que já foi publicado. Deve-se resolver quaisquer conflitos antes de tentar novamente a operação de publicação.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Se espaços estiverem ativados em um catálogo, será possível gerenciar o acesso dos usuários no espaço. A associação ao Espaço é gerenciada incluindo novos usuários no Espaço e designando funções para eles.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Se esta opção estiver ativada, as propriedades de entrada com um valor nulo serão mapeadas para o documento de saída. Caso contrário, essas propriedades serão omitidas da saída.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Se o modo de produção estiver ativado, quaisquer ações de preparação e publicação não serão forçadas. Se conflitos forem localizados, eles serão resolvidos automaticamente pelo sistema. Ações de cancelamentos de publicações acontecerão automaticamente.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Se os destinatários não receberem o e-mail, verifique as configurações do servidor de e-mail.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Se os destinatários não receberem o e-mail, verifique as configurações do servidor de e-mail para o {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Se configurado com um valor true, todos os caracteres + nos valores de parâmetro de consulta da URL de destino das políticas de Chamada e Proxy serão codificados para %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Se configurado com um valor igual a true, quaisquer parâmetros de solicitação que forem referenciados por uma definição de variável em uma URL de destino de chamada serão decodificados por URL.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Se configurado com um valor igual a true, a política de chamada enviará uma carga útil em um método HTTP DELETE.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Se configurada como true, a propriedade será editada ou removida dos logs de atividades.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Se configurada como true, a propriedade será editada ou removida da entrada no início da execução.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Se configurado como true, a propriedade será editada ou removida da resposta.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Se espaços estiverem ativados em um catálogo, será possível gerenciar o acesso de usuários no espaço. O acesso é gerenciado ao especificar as permissões designadas para funções de usuário. As permissões designadas a cada função por padrão ao criar um novo espaço são determinadas pelas configurações no modelo padrão de permissões de espaço.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Se a caixa de seleção estiver marcada (a opção padrão), namespaces XML serão herdados do elemento-pai. Desmarque a caixa de seleção se desejar que a política de mapa use namespaces explícitos.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Se a caixa de seleção estiver marcada (a opção padrão), namespaces XML serão inseridos no documento onde são usados primeiro. Desmarque a caixa de seleção se desejar que todos os namespaces sejam definidos no elemento-raiz.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Se a caixa de seleção estiver marcada (a opção padrão), elementos XML vazios serão incluídos na saída da política de mapa. Desmarque a caixa de seleção se não desejar que elementos XML vazios sejam incluídos na saída da política de mapa.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Se a caixa de seleção estiver marcada, o pós-processamento da saída JSON mapeada estará ativado. O pós-processamento da saída JSON usará o esquema de saída para garantir que os valores da propriedade sejam do mesmo tipo de dados que o definido no esquema. Ele também normalizará os valores de propriedade de saída que têm uma sintaxe JSON de Badgerfish devido ao mapeamento de objetos de uma entrada XML. Limpe a caixa de seleção (o padrão) para nenhum pós-processamento de saída JSON mapeada.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Se a caixa de seleção estiver marcada (a opção padrão), as referências de variável do API Connect localizadas nas propriedades do mapa serão resolvidas. Desmarque a caixa de seleção se desejar que a política de mapa ignore as referências de variável do API Connect nas políticas do mapa.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Se a caixa de seleção estiver marcada, as referências de variáveis do API Connect localizadas nas propriedades do mapa serão resolvidas. Desmarque a caixa de seleção (a opção padrão) se você desejar que a política do mapa ignore as referências de variáveis do API Connect nas políticas do mapa.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Se a caixa de seleção estiver marcada (a opção padrão), se uma matriz for encontrada na passagem da entrada, somente o primeiro elemento será usado. Desmarque a caixa de seleção se você desejar que a política do mapa use todos os elementos de matriz.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Se a caixa de seleção estiver marcada, os elementos XML cujo esquema está configurado como tipo booleano ou numérico serão convertidos para esse tipo de dados. Se não selecionado (a opção padrão), todos os valores de elemento XML serão retornados como uma sequência.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Se a caixa de seleção estiver marcada, a avaliação de tipos de esquema complexos manipulará referências de tipo circular de maneira otimizada. Desmarque essa caixa de seleção (a opção padrão) para avaliar esses tipos de esquema de maneira padrão.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Se o erro for causado por uma falta de suporte do CORS, clique no link para abrir o servidor em uma nova guia. Se o navegador exibe uma questão certificada, é possível escolher aceitá-la e voltar aqui para testar novamente.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Se essa caixa de seleção estiver marcada, qualquer matriz de saída ou propriedade-filha de um objeto opcional anotado no esquema, conforme necessário, terá propriedades de objeto-filho necessárias inicializadas para um valor padrão, a menos que sejam mapeadas com sucesso. Isso emulará como o mapeamento da versão 4 foi feito para as propriedades necessárias para esses objetos. Desmarque essa caixa de seleção (o valor padrão) para gerar saída desses objetos somente com dados mapeados com sucesso.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Se esta caixa de seleção for marcada (padrão) e o usuário proprietário for mudado, a propriedade de quaisquer recursos-filhos (espaços) também será transferida para o novo proprietário.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Se esta opção for selecionada, a política de mapas gravará a saída XML em message.body como um documento XML analisado. Por padrão, o XML será gerado como uma sequência XML. A saída XML para qualquer outra variável sempre será escrita como uma sequência XML.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Ao ativar a aprovação do ciclo de vida para as ações Preparar, Publicar ou Substituir, algumas operações de criação, edição ou teste de API que executam essas ações automaticamente podem não funcionar corretamente. Tem certeza de que deseja permitir alguma dessas aprovações?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Caso haja um serviço SOAP existente que se deseja expor por meio de uma definição de API do API Connect, é possível criar uma API e especificar o serviço SOAP de destino. O API Connect criará então uma definição de API que chama esse serviço SOAP.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Se você salvar as suas mudanças clicando em "Sim. Atualizar o conjunto de API", o conjunto de API que estiver subjacente a este provedor do OAuth também será atualizado. No entanto, se você customizou o conjunto de API subjacente e desejar atualizá-lo separadamente, clique em "Não. Não atualizar o conjunto de API", em seguida, atualize o conjunto de API subjacente no Editor de API.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Se você ainda estiver tendo problemas, faça upload de um arquivo de linguagem de definição de esquema.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Se o seu arquivo contiver as chaves privada e pública, faça upload dele na Etapa 1`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Se for necessário registrar um gateway que está atrás de um firewall, consulte o IBM Cloud Docs para obter informações sobre o uso de uma VPN ou o serviço Secure Gateway para estabelecer a conexão.`,
  [`Ignore`]: `Ignorar`,
  [`Implement policy`]: `Implementar política`,
  [`Implementation file is required`]: `O arquivo de implementação é necessário`,
  [`Implicit`]: `Implícito`,
  [`Import`]: `Importar`,
  [`Import API`]: `Importar API`,
  [`Import API|title`]: `Importar API`,
  [`Import OpenAPI definition from asset repository`]: `Importe a definição do OpenAPI do repositório de ativos`,
  [`Import Product`]: `Importar produto`,
  [`Import policy`]: `Importar política`,
  [`Import a product from a service URL`]: `Importar um produto de uma URL de serviço`,
  [`Import a product from the definition file`]: `Importar um produto do arquivo de definição`,
  [`Import an existing product`]: `Importar um produto existente`,
  [`Import an OpenAPI definition from a URL`]: `Importar uma definição do OpenAPI de uma URL`,
  [`Import an OpenAPI definition from a file`]: `Importar uma definição do OpenAPI de um arquivo`,
  [`Import definitions`]: `Importar definições`,
  [`Import from existing API service`]: `Importar do serviço de API existente`,
  [`Import from file`]: `Importar do arquivo`,
  [`Importing an API definition`]: `Importando uma definição de API`,
  [`Import|title`]: `Importar`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Importe os certificados e os pacotes configuráveis de autoridade de certificação requeridos no serviço Certificate Manager.`,
  [`In Cookie Header`]: `No cabeçalho de cookie`,
  [`In WSSec Header`]: `No cabeçalho do WSSec`,
  [`In order to invoke the endpoints above, you need to first`]: `Para chamar os terminais acima, primeiro é necessário`,
  [`Inactive`]: `Inativo`,
  [`Inc`]: `Inc`,
  [`Include empty`]: `Incluir vazios`,
  [`Include free trial days`]: `Incluir dias de avaliação grátis`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `As APIs incompatíveis com o serviço de gateway configurado não podem ser selecionadas.`,
  [`Incorrect username or password`]: `Nome de usuário ou senha incorreta`,
  [`Incorrect username, password, or credentials`]: `Nome do usuário, senha ou credenciais incorretos`,
  [`Indicate if the invoke is the final action`]: `Indique se a chamada é a ação final`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Indica se uma solicitação de JWT ID (jti) deve ser incluída no JWT. Se selecionado, o valor da solicitação de jti será um UUID.`,
  [`Indicates whether the clients secret is required.`]: `Indica se o segredo dos clientes é necessário.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Indica se esse documento de entrada XSLT usa a carga útil atual do contexto, ou se não há nenhuma entrada.`,
  [`Indicates whether to stop processing if client security fails.`]: `Indica se o processamento será parado se a segurança do cliente falhar.`,
  [`Infer from JSON schema`]: `Infer do esquema JSON`,
  [`Infer from Sample`]: `Inferir por meio da amostra`,
  [`Info`]: `Informações`,
  [`Information context variable`]: `Variável de contexto de informações`,
  [`Inject proxy headers`]: `Injetar cabeçalhos de proxy`,
  [`Inline schema`]: `Esquema sequencial`,
  [`Input`]: `Entrada`,
  [`Input field`]: `Campo de entrada`,
  [`Input object`]: `Objeto de entrada`,
  [`Insecure Server Connections`]: `Conexões do servidor não seguras`,
  [`Install API Connect CLI & API Designer|button text`]: `Instalar o API Connect CLI e API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `Instalar o API Connect CLI e API Designer`,
  [`Install API Connect Toolkit`]: `Instale o kit de ferramentas do API Connect`,
  [`Install the API Connect plugin`]: `Instale o plug-in do API Connect`,
  [`Instance ID`]: `ID da instância`,
  [`Instance name`]: `Nome da instância`,
  [`Instance owner`]: `Proprietário da instância`,
  [`Interact to expand Tile`]: `Interagir para expandir o bloco`,
  [`Interact to collapse Tile`]: `Interagir para reduzir o bloco`,
  [`Interval`]: `Intervalo`,
  [`Interface`]: `Interface`,
  [`Introspect`]: `Introspectar`,
  [`Introspect cache type`]: `Tipo de cache de introspecção`,
  [`Introspect from Server URL`]: `Introspectar a partir da URL do servidor`,
  [`Introspect from URL`]: `Introspectar a partir da URL`,
  [`Introspect URL`]: `URL de introspecção`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Introspectar usando uma URL diferente não mudará a URL do servidor GraphQL.`,
  [`Introspect token`]: `Token Introspect`,
  [`Introspection`]: `Introspecção`,
  [`Introspection Path`]: `Caminho de introspecção`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Faça uma introspecção no esquema GraphQL do servidor ou faça upload do esquema`,
  [`Introspect the GraphQL schema using the query`]: `Fazer a introspecção do esquema do GraphQL usando a consulta`,
  [`Introspection URL`]: `URL de introspecção`,
  [`Invalid HOST URL`]: `URL do HOST inválida`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `URL inválida. Insira uma URL do HOST de Gerenciador de API válida. por ex. https://api-connect-host.com`,
  [`Invalid file URL`]: `URL de arquivo inválida`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Formato de URL inválido, deve iniciar com http:// ou https:// e conter pelo menos um caractere`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Formato de URL inválido. Apenas o nome do host precisa ser especificado, ou seja, syslog:// pode ser removido`,
  [`Invalid emails.`]: `E-mails inválidos.`,
  [`Invalid JSONata expression`]: `Expressão JSONata inválida`,
  [`Invalid remove target`]: `Destino de remoção inválido`,
  [`Invalid assumed size`]: `Tamanho assumido inválido`,
  [`Invalid cost target`]: `Destino de custo inválido`,
  [`Invalid list size`]: `Tamanho da lista inválido`,
  [`Invalid port value, must not be negative`]: `Valor de porta inválido. Não deve ser negativo`,
  [`Invalid sized fields`]: `Campos dimensionados inválidos`,
  [`Invalid value, length has to be greater than 1`]: `Valor inválido. O comprimento tem que ser maior que 1`,
  [`Invalid weight value`]: `Valor de peso inválido`,
  [`invitation`]: `convite`,
  [`Invitation`]: `Solicitação`,
  [`Invitation timeout`]: `Tempo limite do convite`,
  [`Invitation link will expire in {time}`]: `O link do convite expirará em **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `O link de convite expirará em **{seconds} segundos ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `O link de convite expirará em __{value} {unit}__.`,
  [`Invitation sent to`]: `Convite enviado para`,
  [`Invitation sent to {email}`]: `Convite enviado para {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Convite para um catálogo de API no IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Convite para uma organização do consumidor de API no Portal do Desenvolvedor do {catalog}`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Convite para uma organização do provedor de API no IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Convite para um espaço de API no IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Convite para uma organização de administrador no IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Convite para criar um catálogo de API no IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Convite para criar uma organização do consumidor de API no Portal do Desenvolvedor do {catalog}`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Convite para criar uma organização do provedor de API no IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Convite para criar um espaço de API no IBM API Connect`,
  [`Invitation ttl has been created.`]: `O ttl de convite foi criado.`,
  [`Invitation ttl has been updated.`]: `O convite ttl foi atualizado.`,
  [`Invitation ttl has not been created!`]: `O invite ttl não foi criado!`,
  [`Invitation ttl has not been updated!`]: `O convite ttl não foi atualizado!`,
  [`Invite`]: `Convidar`,
  [`Invite catalog owner`]: `Convidar proprietário do catálogo`,
  [`Invite consumer organization owner`]: `Convidar o proprietário da organização do consumidor`,
  [`Invite member`]: `Convidar membro`,
  [`Invite organization owner`]: `Convidar proprietário da organização`,
  [`Invite space owner`]: `Convidar proprietário do espaço`,
  [`Invite a new consumer organization owner`]: `Convide um novo proprietário da organização do consumidor`,
  [`Invite a new member`]: `Convide um novo membro`,
  [`Invite a new provider organization owner`]: `Convide um novo proprietário da organização do provedor`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Convide um novo usuário para registrá-lo como gerente de um catálogo inserindo o endereço de e-mail do destinatário. Um e-mail de convite é enviado para o usuário com um link para o formulário de registro`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Convide um novo usuário para registrá-lo como gerente de um espaço inserindo o endereço de e-mail do destinatário. Um e-mail de convite é enviado ao usuário com um link para o formulário de registro.`,
  [`Invite by email`]: `Convidar por e-mail`,
  [`Invite from User Registry`]: `Convidar do Registro do usuário`,
  [`Invite team member`]: `Convidar membro da equipe`,
  [`Inviting members to consumer organizations is enabled`]: `Está ativado o convite de membros para consumidores`,
  [`Invocations`]: `Chamadas`,
  [`Invoke`]: `Chamar`,
  [`Issuer Claim`]: `Solicitação do emissor`,
  [`Issuer Origin`]: `Origem do emissor`,
  [`Issuer common name`]: `Nome comum do emissor`,
  [`It might influence how the API is deployed`]: `Pode influenciar a implementação da API`,
  [`Italian`]: `Italiano`,
  [`Items per page`]: `Itens por página`,
  [`Items per page:`]: `Itens por página:`,
  [`items selected`]: `itens selecionados`,
  [`item selected`]: `item selecionado`,
  [`Iterate on`]: `Iterar em`,
  [`Iterate?`]: `Iterar?`,
  [`JSON objects describing re-usable channel parameters.`]: `Objetos JSON descrevendo parâmetros de canais reutilizáveis.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `Objetos JSON descrevendo as mensagens sendo consumidas e produzidas pela API.`,
  [`JSON Schema URL`]: `URL do Esquema JSON`,
  [`JSON Schema preview`]: `Visualização do esquema JSON`,
  [`JSON Web Token (JWT)`]: `JSON Web Token (JWT)`,
  [`JSON has been successfully validated`]: `O JSON foi validado com sucesso`,
  [`JSON schema is not valid. Please correct the error.`]: `O esquema JSON não é válido. Corrija o erro.`,
  [`JSON to XML`]: `JSON para XML`,
  [`JWT ID Claim`]: `Solicitação ID do JWT`,
  [`JWT verification JWK`]: `JWK de verificação JWT`,
  [`JWT verification crypto object`]: `Objeto criptográfico de verificação do JWT`,
  [`Japanese`]: `Japonês`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Fila de tarefas`,
  [`Jump to source`]: `Ir para origem`,
  [`Just now`]: `Agora mesmo`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `TROCA DE CHAVE/CRIPTOGRAFIA/MAC`,
  [`KEY SIZE`]: `TAMANHO DA CHAVE`,
  [`Keystore`]: `Keystore`,
  [`keystore`]: `keystore`,
  [`Keep`]: `Manter`,
  [`Keep Payload`]: `Manter carga útil`,
  [`Key Encryption Algorithm`]: `Algoritmo de Criptografia de Chave`,
  [`Key Name`]: `Nome da Chave`,
  [`Key type`]: `Tipo de Chave`,
  [`Key name`]: `Nome da chave`,
  [`Keystore for the token has been updated.`]: `O keystore do token foi atualizado.`,
  [`Keystore Certificates`]: `Certificados keystore`,
  [`Keystore/truststore`]: `Keystore/armazenamento confiável`,
  [`Korean`]: `Coreano`,
  [`LABEL`]: `RÓTULO`,
  [`LABELS`]: `RÓTULOS`,
  [`LAST MODIFIED`]: `ÚLTIMA MODIFICAÇÃO`,
  [`Last state changed`]: `Última mudança de estado`,
  [`Large`]: `Grande`,
  [`Lax`]: `Lax`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `Grupos LDAP`,
  [`LDAP user registry`]: `Registro do Usuário LDAP`,
  [`LDAP registry`]: `Registro LDAP`,
  [`LDAP registry name`]: `Nome do registro LDAP`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `O registro do usuário LDAP da função {{name}} não suporta mais o mapeamento do grupo externo. Ative o mapeamento de grupo externo para este registro do usuário ou desative o mapeamento de grupo externo na função antes de salvar.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `O registro do usuário LDAP da função {{name}} não suporta mais o mapeamento do grupo externo. Selecione outro registro do usuário com mapeamento de grupo externo ou desative o mapeamento de grupo externo na função antes de salvar.`,
  [`LDAP search attribute`]: `Atributo de procura LDAP`,
  [`LOCATED IN`]: `LOCALIZADO EM`,
  [`Located in`]: `Localizado em`,
  [`LTPA Key`]: `Chave LTPA`,
  [`LTPA Key Details`]: `Detalhes da chave LTPA`,
  [`LTPA Keys`]: `Chaves LTPA`,
  [`LTPA key file`]: `Arquivo chave LTPA`,
  [`LTPA key password`]: `Senha da chave LTPA`,
  [`LUR data version`]: `Versão de dados do LUR`,
  [`LUR schema update date`]: `Data de atualização do esquema do LUR`,
  [`LUR schema version`]: `Versão de esquema do LUR`,
  [`LUR target data version`]: `Versão de dados de destino do LUR`,
  [`LUR target schema version`]: `Versão de esquema de destino do LUR`,
  [`LUR update date`]: `Data de atualização do LUR`,
  [`Last name`]: `Sobrenome`,
  [`Last modified`]: `Última modificação`,
  [`Launch portal`]: `Ativar portal`,
  [`Launch IBM Automation management console`]: `Ativar o console de gerenciamento do IBM Automation`,
  [`Launch plan upgrade`]: `Ativar upgrade do plano`,
  [`Learn more`]: `Saiba mais`,
  [`Learn More`]: `Saiba mais`,
  [`Legacy`]: `Anterior`,
  [`Length cannot be below {min}`]: `O comprimento não pode ser inferior a  {min}`,
  [`Length cannot exceed {max}`]: `O comprimento não pode exceder  {max}`,
  [`Let's customize API Connect`]: `Vamos customizar o API Connect`,
  [`Let's get started...`]: `Vamos iniciar...`,
  [`Let's get you up and running`]: `Vamos ajudá-lo a começar`,
  [`License`]: `Licença`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `Ciclo de vida`,
  [`Lifecycle approvals`]: `Aprovações do ciclo de vida`,
  [`Lifecycle actions have been updated`]: `As ações de ciclo de vida foram atualizadas`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `As aprovações de ciclo de vida estão ativadas para o Catálogo do Ambiente de Simulação. Para usar a opção automática Ativar API, deve-se primeiro desativar as aprovações de ciclo de vida nas configurações do Catálogo do Ambiente de Simulação.`,
  [`Limited fields`]: `Campos limitados`,
  [`Limit API calls on a per key basis`]: `Limitar chamadas da API por chave`,
  [`Limit fields`]: `Limitar campos`,
  [`Limit renegotiation`]: `Limitar a renegociação`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Carregamento`,
  [`Load query`]: `Carregar consulta`,
  [`Load filter`]: `Carregar filtro`,
  [`Load schema from WSDL`]: `Carregar esquema do WSDL`,
  [`Loading`]: `Carregando`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Registros do usuário local (LURs) são os registros do usuário padrão incluídos no API Connect e são armazenados localmente.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Os Registros do usuário local são os registros do usuário padrão incluídos no API Connect e armazenados localmente. Dois LURs são instalados e configurados durante a instalação do API Connect. LURs adicionais podem ser configurados.`,
  [`Local user registry`]: `Registro de usuário local`,
  [`Local User Registry has been created.`]: `O registro do usuário local foi criado.`,
  [`Location`]: `Localização`,
  [`Location of ciphertext to decrypt`]: `Local do texto cifrado para decriptografar`,
  [`Location of plaintext information to encrypt`]: `Local das informações de texto sem formatação para criptografar`,
  [`Log`]: `Log`,
  [`Log in`]: `Efetuar Login`,
  [`Log in to:`]: `Efetuar login em:`,
  [`Log in with a different account`]: `Efetuar login com uma conta diferente`,
  [`Log into IBM Cloud`]: `Efetue login no IBM Cloud`,
  [`Log out`]: `Efetuar Logout`,
  [`Log in using the {userRegistryType} user registry`]: `Efetue login usando o registro do usuário {userRegistryType}`,
  [`Logging in with IBM Cloud ...`]: `Efetuando login com o IBM Cloud...`,
  [`Logging out`]: `Efetuando logout`,
  [`Logic`]: `Lógica`,
  [`Login`]: `Login`,
  [`Logs`]: `Logs`,
  [`Longer, descriptive name for this policy.`]: `Nome longo e descritivo para esta política.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Parece que o servidor está experimentando um erro ao processar sua solicitação.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Servidor de correio`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `MEMBRO`,
  [`MIME Types`]: `Tipos MIME`,
  [`Mail server has been deleted.`]: `O servidor de correio foi excluído.`,
  [`Mail server has not been deleted.`]: `O servidor de correio não foi excluído.`,
  [`Mailserver {arg} has been removed.`]: `O servidor de e-mail {arg} foi removido.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Certifique-se de que o servidor esteja em execução e que haja conectividade de rede.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Isso pode ser causado por uma falta de suporte do CORS no servidor de destino, pela indisponibilidade do servidor, pela descoberta de um certificado não confiável ou pela necessidade da autenticação SSL mútua.`,
  [`This might be caused by one or more of the following:`]: `Isso pode ser causado por um ou mais dos seguintes:`,
  [`Make a call and move on`]: `Fazer uma chamada e continuar`,
  [`Make a call and wait for response`]: `Fazer uma chamada e aguardar uma resposta`,
  [`Manage`]: `Gerenciar`,
  [`Manage API life cycles`]: `Gerenciar ciclos de vida da API`,
  [`Manage API product lifecycle approval permissions`]: `Gerenciar permissões de aprovação de ciclo de vida do produto de API`,
  [`Manage API product lifecycle approvals permissions`]: `Gerenciar permissões de aprovação do ciclo de vida do produto de API`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Gerencie organizações do provedor de API, gateways locais e remotos e configurações relacionadas.`,
  [`Manage APIs`]: `Gerenciar APIs`,
  [`Manage Catalogs`]: `Gerenciar Catálogos`,
  [`Manage Organizations`]: `Gerenciar Organizações`,
  [`Manage Resources`]: `Gerenciar Recursos`,
  [`Manage Settings`]: `Gerenciar Configurações`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Gerencie destinos para a análise de API, incluindo o API Connect, HTTP, o Kafka, o Elasticsearch e o Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Gerencie os gateways locais e remotos que protegem as suas APIs.`,
  [`Manage catalogs`]: `Gerenciar catálogos`,
  [`Manage organizations`]: `Gerenciar organizações`,
  [`Manage resources`]: `Gerenciar recursos`,
  [`Manage settings`]: `Gerenciar configurações`,
  [`Manage users`]: `Gerenciar usuários`,
  [`Manage active APIs and consumers`]: `Gerencie APIs e consumidores ativos`,
  [`Manage availability zones and services`]: `Gerencie zonas de disponibilidade e serviços`,
  [`Manage policies`]: `Gerenciar políticas`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Gerencie as solicitações de mudança do ciclo de vida do produto e as solicitações de assinatura de API e de upgrade do aplicativo dos desenvolvedores de aplicativos`,
  [`Manage roles and permissions`]: `Gerenciar funções e permissões`,
  [`Manage the OAuth providers configured for API Manager`]: `Gerencie os provedores OAuth configurados para o API Manager`,
  [`Manage the user registries configured for API Manager`]: `Gerencie os registros do usuário configurado para o API Manager`,
  [`Manage the user registries configured for Cloud Manager`]: `Gerencie os registros do usuário configurados para o Cloud Manager`,
  [`Management`]: `Gerenciamento`,
  [`Management Endpoint`]: `Terminal de Gerenciamento`,
  [`Management endpoint`]: `Terminal de gerenciamento`,
  [`Management endpoint on the analytics service`]: `Terminal de gerenciamento no serviço de analítica`,
  [`Service endpoint configuration`]: `Configuração de terminal em serviço`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Terminal usado pelo serviço de gerenciamento para enviar informações de configuração para o serviço do portal`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Perfil do cliente TLS usado pelo serviço de gerenciamento ao se comunicar com o serviço do portal`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Terminal usado pelo serviço de gerenciamento para enviar informações de configuração para o serviço de gateway`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `Perfil do cliente TLS usado pelo serviço de gerenciamento ao se comunicar com o serviço de gateway`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Use a comunicação no cluster para o tráfego interno entre o gateway e o serviço de gerenciamento`,
  [`Use in-cluster communication for both ingestion and queries`]: `Use a comunicação no cluster para ingestão e consultas`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Use no cluster para ingestão e externo para consultas ou vice-versa`,
  [`Use external communication for both ingestion and queries`]: `Use a comunicação externa para ingestão e consultas`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Use a comunicação no cluster para o tráfego interno entre a análise e o serviço de gerenciamento`,
  [`Management endpoint on the gateway service`]: `Terminal de gerenciamento no serviço de gateway`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Use a comunicação no cluster para o tráfego interno entre o portal e o serviço de gerenciamento`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Se desejar usar a comunicação em cluster entre os subsistemas de gerenciamento e de portais, ative esse comutador. Se não tiver certeza, deixe esse comutador desativado (o padrão).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Se desejar usar a comunicação em cluster entre os subsistemas de gerenciamento e de gateway, ative esse comutador. Se não tiver certeza, deixe esse comutador desativado (o padrão).`,
  [`Management endpoint on the portal service`]: `Terminal de gerenciamento no serviço do portal`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Essa opção é a padrão e é o que é usado para serviços de análise que são submetidos a upgrade a partir de uma liberação pre-10.0.5.3. Para implementações OVA, ou se seu subsistema de análise estiver em um ambiente diferente, então externa é a única opção que você poderá usar. O gateway e o serviço de gerenciamento se comunicam com o serviço de análise usando o terminal externo, que é um ingresso do Kubernetes ou uma rota do OpenShift dependendo de sua plataforma.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `Será possível selecionar dentro do cluster se você tiver uma implementação do Kubernetes ou do OpenShift em que todos os subsistemas estão dentro do mesmo cluster. Quando essa opção for selecionada, os subsistemas de gerenciamento, gateway e analítica se comunicarão entre si por meio de terminais de serviço internos em vez de ingressos acessíveis externamente (Kubernetes) ou rotas (OpenShift).`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `É possível selecionar essa opção se você tiver uma implementação Kubernetes ou OpenShift e tiver alguns de seus subsistemas instalados no mesmo cluster. Quando essa opção for selecionada, é possível usar diferentes métodos de comunicação para os diferentes fluxos de comunicação de análise. Por exemplo, a comunicação de gerenciamento para análise pode usar no cluster e o gateway para análise pode usar externo. Pode-se escolher essa configuração se o seu gateway estiver em um cluster diferente do restante de seus subsistemas.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Se você especificou a comunicação no cluster quando registrou o serviço de análise, será possível ativar o gateway para enviar dados do evento de API para o terminal de análise interno.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Use o terminal interno para ingestão de dados de análise do serviço de gateway`,
  [`Endpoint configuration`]: `Use a comunicação externa para ingestão e consultas`,
  [`Portal endpoint configuration`]: `Configuração de terminal do portal`,
  [`URL used by developer portal users to access the portal website`]: `URL usada por usuários do portal do desenvolvedor para acessar o website do portal`,
  [`External ingestion endpoint on the analytics service`]: `Terminal de ingestão externo no serviço de Analítica`,
  [`TLS client profile for external analytics ingestion endpoint`]: `Perfil do cliente TLS para o terminal de ingestão de analítica externa`,
  [`Internal ingestion endpoint on the analytics service`]: `Terminal de ingestão interno no serviço de Analítica`,
  [`TLS client profile for internal service endpoint`]: `Perfil do cliente TLS para o terminal em serviço interno`,
  [`Manager`]: `de Catálogo`,
  [`Manager Request Reset Password`]: `Reconfiguração de senha de solicitação do gerente`,
  [`Manager Reset Password`]: `Reconfiguração de senha do gerente`,
  [`Manager Sign In`]: `Conexão do gerente`,
  [`Manages API provider organizations`]: `Gerencia as organizações do provedor de API`,
  [`Manages application developer communities`]: `Gerencia as comunidades do desenvolvedor de aplicativos`,
  [`Manages the API product lifecycle`]: `Gerencia o ciclo de vida do produto de API`,
  [`Manage|link`]: `Gerenciar`,
  [`Manage|permission`]: `Gerenciar`,
  [`Manage|title`]: `Gerenciar:título`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Gerenciar a atribuição e o histórico do Keystore para tokens`,
  [`Map`]: `Mapa`,
  [`Map information from OIDC provider to an APIC user`]: `Mapear as informações do provedor do OIDC para um usuário do APIC`,
  [`Map the migration source plans to the migration target plans`]: `Mapeie os planos de origem de migração para os planos de destino de migração`,
  [`Map the roles to groups`]: `Mapear as funções para grupos`,
  [`Mapped from: `]: `Mapeado de: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Maximizar/minimizar`,
  [`Maximum Consent Time to Live (seconds)`]: `Tempo de vida do consentimento máximo (segundos)`,
  [`Maximum consent`]: `Consentimento máximo`,
  [`Menu`]: `Menu`,
  [`Member`]: `Membro`,
  [`Member Invitation`]: `Convite do membro`,
  [`Member Invitation has been deleted.`]: `O convite do membro foi excluído.`,
  [`Member has been deleted.`]: `O membro foi excluído.`,
  [`Member {name} created`]: `Membro  {name} criado`,
  [`Member {title} has been created.`]: `O membro {title} foi criado.`,
  [`Members`]: `Membros`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Convites de membro`,
  [`Member Roles`]: `Funções de membros`,
  [`Merchant ID`]: `ID do Comerciante`,
  [`Merge`]: `Mesclar`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Mesclar este mapeamento com quaisquer outros que operam na mesma matriz de destino?`,
  [`Message`]: `Mensagem`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Mensagem para análise sintática`,
  [`Message for resulting parsed data`]: `Mensagem para dados analisados resultantes`,
  [`Metadata`]: `Metadados`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Os metadados permitem coletar metadados customizados a serem incluídos durante o processo de geração de token de acesso. É possível coletar os metadados por meio de uma URL de autenticação ou de um servidor remoto no qual os metadados customizados são armazenados, ou ambos.`,
  [`Micro Gateway policies`]: `Políticas de Microgateway`,
  [`Migrate`]: `Migrar`,
  [`Migrate Subscriptions`]: `Migrar assinaturas`,
  [`Migrate plans`]: `Migrar planos`,
  [`Migrate subscription`]: `Migrar assinatura`,
  [`Migrate subscriptions`]: `Migrar assinaturas`,
  [`Migration Target`]: `Destino de Migração`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `A migração de "{source}" (Produto) para "{target}" (Produto) foi executada`,
  [`Migration from "{source}" failed`]: `A migração de "{source}" falhou`,
  [`Migration source`]: `Origem de migração`,
  [`Migration target`]: `Destino de migração`,
  [`Minimum role`]: `Função mínima`,
  [`Minimum output escaping rule`]: `Regra mínima de escape de saída`,
  [`Minutes`]: `Minutos`,
  [`Mode`]: `Modo`,
  [`Modifications`]: `Modificações`,
  [`Modified`]: `Modificado`,
  [`More`]: `Mais`,
  [`More than 20 events are waiting to be processed`]: `Mais de 20 eventos estão esperando para serem processados`,
  [`Move down`]: `Mover para Baixo`,
  [`Move up`]: `Mover para Cima`,
  [`Must be a number`]: `Deve ser um número`,
  [`Must be a valid URI`]: `Deve ser um URI válido`,
  [`Must be a valid email`]: `Deve ser um e-mail válido`,
  [`Must be a valid path starting with /`]: `Deve ser um caminho válido iniciado com /`,
  [`Must be a valid url`]: `Deve ser uma URL válida`,
  [`Must be an integer`]: `Deve ser um número inteiro`,
  [`Must be a valid url with no protocol`]: `Deve ser uma URL válida sem protocolo`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Deve estar no formato hexadecimal de 64 caracteres (prefixado com "0x")`,
  [`Must start with '/'`]: `Deve iniciar com ' /'`,
  [`Must start with https://`]: `Deve iniciar com https://`,
  [`Must start with https:// or $(`]: `Deve iniciar com https:// or $(`,
  [`Mutual auth`]: `Autenticação mútua`,
  [`Mutual authentication`]: `Autenticação mútua`,
  [`My account`]: `Minha conta`,
  [`Name`]: `Nome`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NÃO`,
  [`Name cannot be empty.`]: `O Nome não pode estar vazio.`,
  [`Name of Schema in OpenAPI Definitions`]: `Nome de esquema em definições de OpenAPI`,
  [`Name of the LDAP user password attribute.`]: `Nome do atributo de senha de usuário LDAP.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Nome do registro do usuário LDAP com relação ao qual validar. Selecione na lista ou digite manualmente.`,
  [`Name of the organization`]: `Nome da organização`,
  [`Namespace inheritance`]: `Herança de espaço de nomes`,
  [`Namespace inlining`]: `Inlining de namespaces`,
  [`Native`]: `Nativo`,
  [`Native OAuth provider`]: `Provedor OAuth nativo`,
  [`Native OAuth provider summary`]: `Resumo do provedor OAuth nativo`,
  [`Navigate to Source View`]: `Navegue para Visualização de origem`,
  [`New`]: `Novo`,
  [`New API`]: `Nova API`,
  [`New API Connect connection`]: `Nova conexão do API Connect`,
  [`New Application`]: `Novo Aplicativo`,
  [`New Catalog`]: `Novo Catálogo`,
  [`New Consumer organization`]: `Nova organização do consumidor`,
  [`New Endpoint`]: `Novo terminal`,
  [`New native OAuth provider`]: `Novo provedor OAuth nativo`,
  [`New OpenAPI`]: `Nova OpenAPI`,
  [`New OpenAPI directory`]: `Novo diretório do OpenAPI`,
  [`New Organization Title`]: `Novo Título da Organização`,
  [`New Parameter`]: `Novo parâmetro`,
  [`New Password`]: `Nova senha`,
  [`New product`]: `Novo produto`,
  [`New Role`]: `Nova função`,
  [`New schema`]: `Novo esquema`,
  [`New Test`]: `Novo Teste`,
  [`New third party OAuth provider`]: `Novo provedor OAuth de terceiros`,
  [`New user`]: `Novo usuário`,
  [`New applications will be created in the development state`]: `Novos aplicativos serão criados no estado de desenvolvimento`,
  [`Next`]: `Avançar`,
  [`Next page`]: `Página Seguinte`,
  [`No`]: `Não`,
  [`No account?`]: `Não tem uma conta?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `Nenhuma URL de autenticação ou registro de usuário LDAP foi configurado no catálogo do ambiente de simulação`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `Nenhuma URL de autenticação ou Registros do usuário LDAP configurados no catálogo de ambiente de simulação. Crie uma [aqui]({link})`,
  [`No Billing`]: `Sem Faturamento`,
  [`No billing integration has been selected.`]: `Nenhuma integração de faturamento foi selecionada.`,
  [`No billing integration can be shown when offline.`]: `Não é possível mostrar nenhuma integração de faturamento quando off-line.`,
  [`No gateways`]: `Sem gateways`,
  [`No cache`]: `Sem cache`,
  [`No Cache`]: `Sem Cache`,
  [`No Certificate Manager services available`]: `Nenhum serviço Certificate Manager disponível`,
  [`No default gateways`]: `Nenhum gateway padrão`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Nenhum endereço de e-mail duplicado no registro do usuário. Isso aplica-se apenas aos usuários com e-mail.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Nenhum gateway compatível está configurado para o catálogo do ambiente de simulação. O teste de API (Experimente) no Explorer não será ativado.`,
  [`No Errors`]: `Sem erros`,
  [`No Histories found`]: `Nenhum histórico localizado`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Nenhum serviço de gateway localizado. Configure um Serviço de gateway para exibir políticas de conjunto suportadas`,
  [`No gateways are configured for sandbox catalog`]: `Nenhum gateway está configurado para o catálogo do ambiente de simulação`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Nenhum gateway está configurado para o catálogo do ambiente de simulação. O teste de API (Experimente) no Explorer não será ativado`,
  [`No LDAP or authentication URL user registries found.`]: `Nenhum registro de usuário de LDAP ou de URL de autenticação localizado.`,
  [`No limit on size of list`]: `Sem limite no tamanho da lista`,
  [`No OAuth providers configured in the sandbox catalog`]: `Nenhum provedor OAuth configurado no catálogo do ambiente de simulação`,
  [`No OAuth providers configured in the selected catalog`]: `Nenhum provedor OAuth configurado no catálogo selecionado`,
  [`No Permission`]: `Sem permissão`,
  [`No portal added to this catalog`]: `Nenhum portal incluído neste catálogo`,
  [`No Roles exist`]: `Nenhuma função existe`,
  [`No recent items found.`]: `Nenhum item recente localizado.`,
  [`No TLS profile`]: `Nenhum perfil TLS`,
  [`No TLS client profile`]: `Nenhum perfil de cliente TLS`,
  [`No Truststore`]: `Sem armazenamento confiável`,
  [`No analytics service is configured`]: `Nenhum serviço de analítica está configurado`,
  [`No analytics services found. You can create one [here]({link})`]: `Nenhum serviço de Analítica localizado. É possível criar um [aqui]({link})`,
  [`No assemblies found.`]: `Nenhum conjunto localizado.`,
  [`No available extensions`]: `Não há extensões disponíveis`,
  [`No compatible flows configured in this OAuth provider`]: `Nenhum fluxo compatível está configurado neste provedor OAuth`,
  [`No consumer organization will be able to see this product.`]: `Nenhuma organização de consumidores poderá ver este produto.`,
  [`No debug data was found for this API call`]: `Não foram localizados dados de depuração para esta chamada de API`,
  [`No details are available.`]: `Nenhum detalhe está disponível.`,
  [`No existing products`]: `Nenhum produto existente`,
  [`No extensions have been configured for the API`]: `Nenhuma extensão foi configurada para a API`,
  [`No flow configured in this OAuth provider`]: `Nenhum fluxo configurado neste provedor OAuth`,
  [`No items found`]: `Nenhum item encontrado`,
  [`No non-member user to add.`]: `Nenhum usuário não membro a ser incluído.`,
  [`No organization found`]: `Nenhuma organização localizada`,
  [`No portal service exists:`]: `Não existe nenhum serviço de portal:`,
  [`No product APIs`]: `Nenhuma API do produto`,
  [`No products or plans were found.`]: `Nenhum produto ou plano foi localizado.`,
  [`No provider organizations`]: `Nenhuma organização do provedor`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Nenhuma resposta recebida. As causas incluem uma falta de suporte CORS no servidor de destino, a indisponibilidade do servidor, a localização de um certificado não confiável ou a autenticação SSL mútua ser necessária.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Nenhum grupo de recursos está disponível. Para começar, crie um grupo de recursos nos recursos da Conta da IBM Cloud.`,
  [`No security`]: `Sem segurança`,
  [`No services found`]: `Nenhum serviço encontrado`,
  [`No warnings`]: `Nenhum aviso`,
  [`No warnings.`]: `Nenhum aviso.`,
  [`No. Do not update API Assembly`]: `Não. Não atualizar conjunto de API`,
  [`Non-blocking`]: `Sem bloqueio`,
  [`Non int slicing arguments`]: `Argumentos de fatiamento não int`,
  [`None`]: `Nenhum`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Nenhum dos provedores OAuth configurados corresponde ao tipo de gateway desta API`,
  [`Normal`]: `Normal`,
  [`Not Available`]: `Indisponível`,
  [`Not applicable for application grant type.`]: `Não aplicável para o tipo de concessão de aplicativos.`,
  [`Not valid before`]: `Não válido antes de`,
  [`Not valid after`]: `Inválido após`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Nota: esta opção é desativada quando a opção 'Gerar produto automático' é selecionada.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Nota: esta opção será desativada quando a opção 'Gerar produto automático' estiver selecionada e utilizará o plano padrão como alternativa para o produto gerado`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Nota: A preferência dos serviços de gateway será usada como escopo para todas as outras preferências. As políticas e outros conteúdos específicos do serviço de gateway serão carregados com base nessa configuração.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Nota: Esta opção é apenas para fins informativos e é atualizada com base na opção de aplicação de teste acima`,
  [`Notices`]: `Avisos`,
  [`Notification template`]: `Gabarito de notificação`,
  [`Notification templates`]: `Modelos de Notificações`,
  [`Notification server configured`]: `Servidor de notificação configurado`,
  [`Notifications`]: `Notificações`,
  [`Null Badgerfish`]: `Badgerfish nulo`,
  [`Null`]: `Valores nulos`,
  [`Number`]: `Número`,
  [`Number of Operations`]: `Número de operações`,
  [`Numeric`]: `Numérico`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `Provedor OAuth`,
  [`OAuth provider`]: `Provedor OAuth`,
  [`OAuth Provider Policy Assembly`]: `Conjunto de políticas do provedor OAuth`,
  [`OAuth providers`]: `Provedores OAuth`,
  [`OAuth Redirect URL`]: `URL de redirecionamento de OAuth`,
  [`OAuth shared secret`]: `Segredo compartilhado do OAuth`,
  [`OAuth provider policy assembly`]: `Conjunto de políticas do provedor OAuth`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `O provedor OAuth {currentProviderName} não foi localizado. Selecione um provedor do OAuth configurado.`,
  [`OAuth redirect URL`]: `URL de redirecionamento OAuth`,
  [`OAuth Shared Secret`]: `Segredo compartilhado do OAuth`,
  [`OAuth Signature Method`]: `Método de assinatura OAuth`,
  [`OAuth signature method`]: `Método de assinatura OAuth`,
  [`OAuth providers can be created and managed in the following list.`]: `Provedores OAuth podem ser criados e gerenciados na lista a seguir.`,
  [`OAuth symmetric key name`]: `Nome da chave simétrica OAuth`,
  [`OAuth symmetric key value`]: `OAuth valor de chave simétrica`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `Segurança do código de acesso do OAuth2`,
  [`OAuth2 Application Security`]: `Segurança do aplicativo OAuth2`,
  [`OAuth2 Implicit Security`]: `Segurança implícita do OAuth2`,
  [`OAuth2 Password Security`]: `Segurança de senha do OAuth2`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `ID DA OPERAÇÃO`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `ORDEM`,
  [`ORGANIZATION`]: `ORGANIZAÇÃO`,
  [`ORGANIZATION MANAGER`]: `GERENTE DE ORGANIZAÇÃO`,
  [`Organizations`]: `Organizações`,
  [`Owner`]: `Proprietário`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Os provedores OAuth são gerenciados em cada espaço quando há espaços ativados. Acesse as configurações de espaço para gerenciar provedores OAuth.`,
  [`Off`]: `Desativado`,
  [`Offline`]: `Offline`,
  [`Old schema`]: `Esquema antigo`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `O Omnisearch permite procurar APIs, produtos, aplicativos, assinaturas e outros recursos em uma organização do provedor ou em um catálogo. Além disso, ele também pode ajudar na descoberta de relacionamentos entre esses recursos.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch para todas as páginas de lista suportadas`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch para APIs e produtos de rascunho`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch para produtos publicados em catálogos e espaços`,
  [`Omnisearch for consumer organizations`]: `Omnisearch para organizações do consumidor`,
  [`Omnisearch for applications`]: `Omnisearch para aplicativos`,
  [`Omnisearch for requested approvals`]: `Omnisearch para aprovações solicitadas`,
  [`Omnisearch for approvals tasks`]: `Omnisearch para tarefas de aprovações`,
  [`Omnisearch for product apis`]: `Omnisearch para APIs de produto`,
  [`Omnisearch for subscriptions`]: `Omnisearch para assinaturas`,
  [`Omnisearch for members`]: `Omnisearch para membros`,
  [`Omnisearch for provider organizations`]: `Omnisearch para organizações do provedor`,
  [`Disable the new editor for OpenAPI2`]: `Desative o novo editor para o OpenAPI2`,
  [`On`]: `Ativado`,
  [`Onboarding`]: `Integração`,
  [`Onboarding Edit Admin Org`]: `Organização de administração de edição de integração`,
  [`Onboarding Edit Cloud Org`]: `Organização de edição de nuvem de integração`,
  [`Onboarding Edit Reset Password`]: `Senha de reconfiguração de edição de integração`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Depois de instalado, use a CLI do IBM Cloud para destinar a conta e a região na qual você provisionou o API Connect e, em seguida, tente a CLI listando todas as organizações do provedor do API Connect`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Um ou mais objetos JSON descrevendo os esquemas sendo consumidos e produzidos pela API.`,
  [`One or more JSON representations for parameters`]: `Uma ou mais representações JSON para parâmetros`,
  [`One time use access token`]: `Um token de acesso de uso único`,
  [`One time use refresh token`]: `Um token de atualização de uso único`,
  [`Online`]: `Online`,
  [`Only one API file should be present in the zip file.`]: `Apenas um arquivo de API deve estar presente no arquivo zip.`,
  [`OpenAPI Specification Version`]: `Versão da especificação da OpenAPI`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `O Open ID Connect (OIDC) fornece uma base adicional de protocolo de autenticação no OAuth 2.0. O OIDC fornece informações sobre o usuário codificadas em JSON Web Token ou em JWT. Ao ativar o OpenID Connect, um modelo é fornecido para gerar tokens de ID juntamente com tokens de acesso e, além disso, as políticas de conjunto necessárias são criadas automaticamente.`,
  [`Open a Directory`]: `Abra um Diretório`,
  [`Open in external editor`]: `Abrir em editor externo`,
  [`Open the server`]: `Abra o servidor`,
  [`OpenAPI extensions`]: `Extensões OpenAPI`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect ainda não suportado quando a versão do Gateway é 6000`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `As configurações de conexão OpenID serão aplicáveis somente se os tipos de concessão selecionados incluírem um ou mais dos seguintes: código Implícito e de Acesso.`,
  [`Operation`]: `Operação`,
  [`Operation Id`]: `ID da Operação`,
  [`Operation switch`]: `Comutador de operação`,
  [`Operation Switch`]: `Comutador de operação`,
  [`Operations`]: `Operações`,
  [`Operator build date`]: `Data de construção do operador`,
  [`Operator build tag`]: `Tag de construção do operador`,
  [`Operator version`]: `Versão do operador`,
  [`Optionally select a CA bundle`]: `Opcionalmente, selecione um pacote configurável de CA`,
  [`Optimize schema definition`]: `Otimizar a definição de esquema`,
  [`Optional`]: `Opcional`,
  [`Or`]: `Or`,
  [`Or create a new application and subscribe to the selected plan`]: `Ou crie um novo aplicativo e assine o plano selecionado`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Ou crie um novo produto e publique-o em seu catálogo do Ambiente de Simulação`,
  [`Order`]: `Classificar`,
  [`Org`]: `Organização`,
  [`Org owner`]: `Proprietário da organização`,
  [`Organization`]: `Organização`,
  [`Organization:`]: `Organização:`,
  [`Organization information`]: `Informações sobre a organização`,
  [`Organization Manager`]: `Gerente de organização`,
  [`Organization has been updated.`]: `A organização foi atualizada.`,
  [`Organizations will be listed here.`]: `As organizações serão listadas aqui.`,
  [`Otherwise`]: `Caso contrário`,
  [`Output`]: `Saída`,
  [`Output Claims`]: `Solicitações de saída`,
  [`Output array`]: `Matriz de saída`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Exiba o elemento raiz mesmo que ele não seja necessário para que o documento XML esteja no formato adequado.`,
  [`Output variable`]: `Variável de saída`,
  [`Override API consume types`]: `Substituir os tipos de consumo da API`,
  [`Override API produce types`]: `Substituir os tipos de produção da API`,
  [`Override API security definitions`]: `Substituir as definições de segurança da API`,
  [`Override rate limits`]: `Substituir os limites de taxa`,
  [`Override default "provider" settings with configuration from this URL`]: `Substituir as configurações padrão de "provedor" pela configuração a partir desta URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Substituir as configurações padrão de "provedor" pela configuração a partir deste literal`,
  [`Override plan rate limits`]: `Substituir os limites de taxa de plano`,
  [`Override plan rate limits for individual operation`]: `Substituir limites de taxa de plano para operação individual`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Substitua o tempo limite de convite de organizações do consumidor pelo tempo limite de convite do catálogo`,
  [`Overview`]: `Visão Geral`,
  [`Owner scope check`]: `Verificação do escopo do proprietário`,
  [`Owner's Email`]: `E-mail do proprietário`,
  [`Owner's Name`]: `Nome do Proprietário`,
  [`Owner's email`]: `E-mail do proprietário`,
  [`Owns and administers the API provider organization`]: `Possui e administra a organização do provedor de API`,
  [`Owns and administers the admin organization`]: `Possui e administra a organização do administrador`,
  [`Owns and administers the app developer organization`]: `Possui e administra a organização do provedor de app`,
  [`Owns and administrates the app developer organization`]: `Possui e administra a organização do desenvolvedor de aplicativo`,
  [`PARAMETER NAME`]: `NOME DO PARÂMETRO`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `PCRE a ser usado para validar a solicitação de público (aud).`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `PCRE a ser usado para validar a solicitação do emissor (iss).`,
  [`PER`]: `POR`,
  [`Per`]: `Por`,
  [`Plan`]: `Plano`,
  [`Plan:`]: `Plano:`,
  [`pending Consumer organization invitation`]: `Convite da organização do consumidor pendente`,
  [`PLAN`]: `PLANO`,
  [`PLANS`]: `PLANOS`,
  [`Policy`]: `Política`,
  [`policy`]: `política`,
  [`POLICIES`]: `POLÍTICAS`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUTO`,
  [`Product Plan`]: `Plano de produto`,
  [`Product Plans`]: `Planos de produto`,
  [`Product Plans per page`]: `Planos de produtos por página`,
  [`Property name`]: `Nome da Propriedade`,
  [`Property type`]: `Tipo de Propriedade`,
  [`Property example`]: `Exemplo de propriedade`,
  [`Property description`]: `Descrição da propriedade`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Página não localizada`,
  [`Parameter name`]: `Nome do Parâmetro`,
  [`Parameter control`]: `Controle de Parâmetro`,
  [`Parameters`]: `Parâmetros`,
  [`Parent`]: `Pai`,
  [`Parse`]: `Analisar`,
  [`Parse settings URL reference`]: `Analisar referência de URL de configurações`,
  [`Parse settings literal configuration`]: `Analisar configuração de literal de definições`,
  [`Parse settings object reference`]: `Analisar referência do objeto de configurações`,
  [`Parse the GraphQL query`]: `Analise a consulta do GraphQL`,
  [`Parse the response from the GraphQL backend server`]: `Analise a resposta do servidor de back-end do GraphQL`,
  [`Parse XML output`]: `Analisar a saída XML`,
  [`Pass-through`]: `Passagem`,
  [`Password`]: `Senha`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `Senha mudada para sua conta de usuário no IBM API Connect`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `Senha mudada para sua conta de usuário no Portal do Desenvolvedor {portalTitle}`,
  [`Password context variable`]: `Variável de contexto de senha`,
  [`Password is invalid. Re-enter the password.`]: `A senha é inválida. Digite a senha novamente.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `Solicitação de reconfiguração de senha para sua conta de usuário no IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Solicitação de reconfiguração de senha para sua conta do Portal do Desenvolvedor do {catalog}`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `As senhas devem conter pelo menos um caractere de duas das categorias a seguir: minúsculas, maiúsculas, numerais e pontuação (exemplo, !, $, #, %)`,
  [`Passwords must contain eight or more characters`]: `As senhas devem conter oito ou mais caracteres`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `As senhas devem conter oito ou mais caracteres, com pelo menos um caractere de duas das categorias a seguir: minúsculo, maiúsculo, numerais e pontuação (por exemplo !, $, #,%). Além disso, o mesmo caractere não pode ser usado mais que duas vezes consecutivas.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `As senhas não devem conter o mesmo caractere usado mais de duas vezes consecutivamente.`,
  [`Passwords should match`]: `As senhas devem corresponder`,
  [`Path`]: `Caminho`,
  [`Path parameters`]: `Parâmetros de caminho`,
  [`Path name`]: `O nome do caminho`,
  [`Paths`]: `Caminhos`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Os caminhos identificam os recursos REST expostos pela API. Uma operação combina um caminho com um verbo HTTP, parâmetros e definições para solicitações e respostas.`,
  [`Payments Authorization`]: `Autorização de pagamentos`,
  [`Payment method`]: `Método de pagamento`,
  [`Pending`]: `Pendente`,
  [`Pending State`]: `Estado Pendente`,
  [`Pending approval to be published in catalog`]: `Aprovação pendente a ser publicada no catálogo`,
  [`Pending approval to be staged in catalog`]: `Aprovação pendente a ser montada no catálogo`,
  [`Pending consumer organization invitations`]: `Convites da organização do consumidor pendentes`,
  [`View endpoints`]: `Visualizar terminais`,
  [`View base endpoints for this API`]: `Visualizar terminais de base para esta API`,
  [`View pending invitations`]: `Visualizar convites pendentes`,
  [`Permission Denied`]: `Permissão Negada`,
  [`Permissions`]: `Permissões`,
  [`Persistent Connection`]: `Conexão persistente`,
  [`Pin menu`]: `Menu Pin`,
  [`PlainText`]: `Texto sem formatação`,
  [`Plan APIs`]: `APIs do Plano`,
  [`Plan burst limits`]: `Planejar limites de distribuição`,
  [`Plan by name`]: `Plano por nome`,
  [`Plan Default`]: `Padrão de plano`,
  [`Plan Details`]: `Detalhes do Plano`,
  [`Plan migration targets`]: `Planejar destinos de migração`,
  [`Plan rate limits`]: `Planejar limites de taxa`,
  [`Plan name`]: `Nome do plano`,
  [`Plan pricing`]: `Precificação do plano`,
  [`Plan title already exists`]: `O título do plano já existe`,
  [`Plans`]: `Planos`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Terminal da API de REST da plataforma para APIs do administrador e do provedor`,
  [`Platform REST API endpoint for consumer APIs`]: `Terminal da API de REST da plataforma para APIs do consumidor`,
  [`Please choose a bind method`]: `Escolha um método de ligação`,
  [`Please choose an authentication method`]: `Escolha um método de autenticação`,
  [`Please contact the system administrator.`]: `Entre em contato com o administrador do sistema.`,
  [`Please contact your administrator for support.`]: `Entre em contato com o administrador para obter suporte.`,
  [`Please enter the reason.`]: `Insira a razão.`,
  [`Please enter your username`]: `Insira o seu nome de usuário`,
  [`Please go to the space settings to manage API endpoints.`]: `Acesse as configurações de espaço para gerenciar os terminais de API.`,
  [`Please make sure the server is online and try again.`]: `Certifique-se de que o servidor esteja on-line e tente novamente.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Observe que a desativação do log de atividades removerá as propriedades associadas ao tipo de conteúdo a ser registrado no caso de sucesso ou erro. Tem certeza de que deseja desativá-la?`,
  [`Please review the license for API Connect by accessing`]: `Revise a licença para o API Connect acessando`,
  [`Please select a TLS`]: `Selecione um TLS`,
  [`Please select a TLS client profile`]: `Selecione um perfil do cliente TLS`,
  [`Please select a group type`]: `Selecione um tipo de grupo`,
  [`Please select an LDAP protocol version`]: `Selecione uma versão de protocolo LDAP`,
  [`Please select authentication method`]: `Selecione o método de autenticação`,
  [`Please select ciphers from the list below:`]: `Selecione cifras na lista abaixo:`,
  [`Please select consumer organization`]: `Selecione a organização do consumidor`,
  [`Please select one Keystore from the list below:`]: `Selecione um keystore na lista abaixo:`,
  [`Please select one keystore from the list below:`]: `Selecione um Armazenamento de chaves na lista abaixo:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Anote o identificador e o segredo do cliente para este novo aplicativo. Armazene o segredo em algum lugar seguro, pois ele não poderá ser recuperado novamente, embora seja possível reconfigurá-lo, se necessário:`,
  [`Please try again in a few minutes.`]: `Tente novamente em alguns minutos.`,
  [`Please refresh catalog/space product list page and try again.`]: `Atualize a página de lista de produtos do catálogo / espaço e tente novamente.`,
  [`Policies`]: `Políticas`,
  [`Policy Assemblies`]: `Conjuntos de políticas`,
  [`Policy assembly`]: `Conjunto de política`,
  [`Policy description.`]: `Descrição da política.`,
  [`Policy title`]: `Título da política`,
  [`Policy has been uploaded.`]: `A política foi transferida por upload.`,
  [`Polish`]: `Polonês`,
  [`Port`]: `Porta`,
  [`Portal`]: `Portal`,
  [`Portal details`]: `Detalhes do portal`,
  [`Portal Director TLS client profile`]: `Perfil do cliente TLS de diretor do portal`,
  [`Portal Director keystore`]: `Keystore de diretor do portal`,
  [`Portal Director truststore`]: `Armazenamento confiável de diretor do portal`,
  [`Portal service`]: `Serviço do portal`,
  [`Portal Service`]: `Serviço de portal`,
  [`Portal service name`]: `Nome do serviço do portal`,
  [`Portal service details`]: `Detalhes do serviço do portal`,
  [`Portal Service and URL`]: `Serviço de portal e URL`,
  [`Portal service {name} was successfully created.`]: `O serviço de portal {name} foi criado com sucesso.`,
  [`Portal service {name} was successfully updated.`]: `O serviço de portal {name} foi atualizado com sucesso.`,
  [`Portal Service {arg} has been removed.`]: `O serviço do portal {arg} foi removido.`,
  [`Portal site`]: `Site do portal`,
  [`Portal URL`]: `URL do portal`,
  [`Portal site URLs cannot be greater than 200 characters`]: `As URLs do site do portal não podem ser maiores que 200 caracteres`,
  [`Portal website URL`]: `URL do website do portal`,
  [`Portal and Gateway Settings`]: `Configurações do Portal e do Gateway`,
  [`Portal has been created`]: `O portal foi criado`,
  [`Portal has been created.`]: `O portal foi criado.`,
  [`Portal has been deleted`]: `O portal foi excluído`,
  [`Portals`]: `Portais`,
  [`Portuguese`]: `Português`,
  [`Post Response`]: `Postar Resposta`,
  [`Post-Invoke Hook`]: `Hook de pós-chamada`,
  [`Preserve subscriptions on re-publish`]: `Preservar assinaturas ao publicar novamente`,
  [`Preserve subscriptions on re-publish product`]: `Preservar assinaturas ao publicar novamente o produto`,
  [`Pre-Invoke Hook`]: `Hook de pré-chamada`,
  [`Pre-Request`]: `Pré-solicitação`,
  [`Preference`]: `Preferências`,
  [`Prefix`]: `Prefixo`,
  [`Preparing API`]: `Preparando API`,
  [`Preserved request header`]: `Cabeçalho de solicitação preservado`,
  [`preserved request header`]: `Cabeçalho de solicitação preservado`,
  [`Preserved response header`]: `Cabeçalho de resposta preservado`,
  [`preserved response header`]: `Cabeçalho de resposta preservado`,
  [`Preview`]: `Visualizar`,
  [`Preview of login screen registry prompt`]: `Visualização do prompt do registro da tela de login`,
  [`Previous`]: `Anterior`,
  [`Previous page`]: `Página anterior`,
  [`Prettify`]: `Prettify`,
  [`Price per month`]: `Preço por mês`,
  [`Private`]: `Privado`,
  [`Private Claims`]: `Solicitações privadas`,
  [`Private key & public key`]: `Chave privada e chave pública`,
  [`Private Key or Shared Secret for Decrypt`]: `Chave privada ou segredo compartilhado para decriptografia`,
  [`Private Key or Shared Secret for Sign`]: `Chave privada ou segredo compartilhado para sinal`,
  [`Private key detected`]: `Chave privada detectada`,
  [`Private key password`]: `Senha da chave privada`,
  [`Processed`]: `Processado`,
  [`Problem`]: `Problem`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Problema ao buscar dados de rastreio. Tente novamente. Consulte o console para obter detalhes do erro.`,
  [`Produced Media Types`]: `Tipos de mídia produzidos`,
  [`Produces`]: `Produz`,
  [`Product`]: `Produto`,
  [`Product:`]: `Produto:`,
  [`Product / Plan`]: `Produto/plano`,
  [`Product APIs`]: `APIs do produto`,
  [`Product editor`]: `Editor do produto`,
  [`Product lifecycle`]: `Ciclo de vida do produto`,
  [`Product Lifecycle`]: `Ciclo de vida do produto`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `O nome do produto não pode terminar com '-auto-product'. Mude o nome e tente importar novamente.`,
  [`Product setup`]: `Configuração do produto`,
  [`Product details`]: `Detalhes do produto`,
  [`Product documentation`]: `Documentação do produto`,
  [`Product has been created.`]: `O produto foi criado`,
  [`Product has been deleted`]: `O produto foi excluído`,
  [`Product has been updated`]: `O produto foi atualizado`,
  [`Product has been updated.`]: `O produto foi atualizado.`,
  [`Product has not been created!`]: `O produto não foi criado!`,
  [`Product has not been published!`]: `O produto não foi publicado!`,
  [`Product has not been updated!`]: `O produto não foi atualizado.`,
  [`Product has not been staged!`]: `O produto não foi montado!`,
  [`Product information`]: `Informações do produto`,
  [`Product summary details`]: `Detalhes de resumo do produto`,
  [`Product version`]: `Versão de produto`,
  [`Product visibility`]: `Visibilidade do produto`,
  [`Product-Approval`]: `Aprovação do produto`,
  [`Product-Drafts`]: `Product-Drafts`,
  [`Product/plan`]: `Produto/plano`,
  [`Production`]: `Produção`,
  [`Production mode`]: `Modo de produção`,
  [`Production endpoints`]: `Terminais de produção`,
  [`Products`]: `Produtos`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Os produtos devem ser preparados em um catálogo e, em seguida, publicados para a disponibilização para os desenvolvedores de aplicativos. Um catálogo se comporta como uma partição lógica do gateway e do Portal do Desenvolvedor. A URL para chamadas de API e o Portal do Desenvolvedor são específicos de um determinado Catálogo. Em uma configuração típica, uma organização do provedor da API usa um Catálogo de desenvolvimento para testar APIs em desenvolvimento e um Catálogo de produção para hospedar APIs que estão prontos para uso total.`,
  [`Profile`]: `Perfil`,
  [`Profile rule`]: `Regra de perfil`,
  [`Promote to production`]: `Promover para produção`,
  [`Proof Key for Code Exchange`]: `Chave de Produto para Troca de Código`,
  [`Proof Key for Code Exchange (PKCE)`]: `Proof Key for Code Exchange (PKCE)`,
  [`Properties`]: `Propriedades`,
  [`Property`]: `Propriedade`,
  [`Property Description`]: `Descrição da propriedade`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `A propriedade {{target}} pode ser designada a um valor estático inserindo-a abaixo.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `A propriedade {{target}} está atualmente mapeada para {{from}}, o mapeamento pode ser removido abaixo.`,
  [`Property Value is Base64 Encoded`]: `O valor da propriedade é codificado por Base64`,
  [`Protocol`]: `Protocolo`,
  [`Protocols`]: `Protocolos`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protocolos que podem ser usados para chamar a API (somente https é suportado para as APIs aplicadas pelo gateway)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Forneça credenciais OAuth {oauthVer} para autenticar usuários e obter acesso às APIs do seu provedor OpenID`,
  [`Provide a schema`]: `Fornecer um esquema`,
  [`Provide GraphQL schema`]: `Fornecer o esquema GraphQL`,
  [`Provide the URL to the backend GraphQL server`]: `Forneça a URL para o servidor do GraphQL de back-end`,
  [`Provide your email address to receive password reset instructions`]: `Forneça seu endereço de e-mail para receber instruções de reconfiguração de senha`,
  [`Provide your new password below`]: `Forneça sua nova senha abaixo`,
  [`Provider endpoint`]: `Terminal do provedor`,
  [`Provider Information`]: `Informações do Provedor`,
  [`Provider Organization`]: `Organização do provedor`,
  [`Provider Organizations`]: `Organizações do Provedor`,
  [`Provider Type`]: `Tipo de Provedor`,
  [`Provider organizations`]: `Organizações do provedor`,
  [`Provider type`]: `Tipo de Provedor`,
  [`Provider org invite failed`]: `O convite da organização do provedor falhou`,
  [`Provider organization {name} created`]: `Organização do provedor  {name} criada`,
  [`Provider organizations will be listed here`]: `As organizações de provedor estarão listadas aqui`,
  [`Provider-Org`]: `Organização do provedor`,
  [`Provides contact information for the exposed API.`]: `Fornece informações de contato para a API exposta.`,
  [`Provides license information for the exposed API.`]: `Fornece informações sobre licença para a API exposta.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Fornece metadados sobre a API. Os metadados podem ser usados pelos clientes, se necessário.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `O fornecimento do portal do desenvolvedor foi iniciado. Isso levará alguns minutos para ser concluído. Após a conclusão, você receberá um e-mail contendo um link para configurar a senha para a conta do administrador do portal.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `A atualização da URL do Portal do Desenvolvedor foi iniciada. O processo levará alguns minutos para ser concluído.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `URL de Proxy`,
  [`Public`]: `Público`,
  [`Public Key or Shared Secret for Encrypt`]: `Chave pública ou segredo compartilhado para criptografia`,
  [`Publish`]: `Publicar`,
  [`Publish API`]: `Publicar API`,
  [`Publish a product from the Develop section.`]: `Publicar um produto na seção Desenvolver.`,
  [`Publish product`]: `Publicar produto`,
  [`Publish to`]: `Publicar em`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Publicar alguns produtos para este catálogo para criar assinaturas para eles.`,
  [`Publish target`]: `Publicar destino`,
  [`Publish this API to the Sandbox catalog.`]: `Publique essa API no catálogo do Ambiente de Simulação.`,
  [`Publish to specific gateway services`]: `Publicar serviços de gateway específicos`,
  [`Publish using a new product`]: `Publicar usando um novo produto`,
  [`Publish using an existing product`]: `Publicar usando um produto existente`,
  [`Publishable Key`]: `Chave Publicável`,
  [`Publishable key`]: `Chave publicável`,
  [`Publishable key tip`]: `Dica de chave publicável`,
  [`Published`]: `Publicado`,
  [`Published product`]: `Produto publicado`,
  [`Publishing`]: `Publicação`,
  [`Publishing New API`]: `Publicando a nova API`,
  [`Publish (preserve subscriptions)`]: `Publicar (preservar assinaturas)`,
  [`Publish|permission`]: `Publicar`,
  [`Put online`]: `Colocar on-line`,
  [`Query`]: `Consulta`,
  [`Queryparam Encode`]: `Queryparam Encode`,
  [`Queued`]: `Enfileirado`,
  [`Queued events: **{queued}**`]: `Eventos enfileirados: **{queued}**`,
  [`Retry blocked jobs`]: `Tentar novamente as tarefas bloqueadas`,
  [`REQUIRED`]: `NECESSÁRIO`,
  [`ROLES`]: `FUNÇÕES`,
  [`Rate limit`]: `Limite de taxa`,
  [`Rate Limit`]: `Limite de taxa`,
  [`Rate Limit Name`]: `Nome de Limite de Taxa`,
  [`Rate limits`]: `Limites de taxa`,
  [`Rate limit name can't be a duplicate`]: `O nome do limite de taxa não pode ser uma duplicata`,
  [`Rate limit name can't be empty`]: `O nome do limite de taxa não pode estar vazio`,
  [`Rate limit overrides`]: `Substituições de limite de taxa`,
  [`Rate limit time interval can't be 0`]: `O intervalo de tempo limite de taxa não pode ser 0`,
  [`Rate limit time interval can't be empty`]: `O intervalo de tempo limite de taxa não pode ficar vazio`,
  [`Rate limit time interval unit must be selected`]: `A unidade de intervalo de tempo limite de taxa deve ser selecionada`,
  [`Rate limit value can't be empty`]: `O valor limite de taxa não pode estar vazio`,
  [`Rate limit value can't be negative`]: `O valor limite de taxa não pode ser negativo`,
  [`Re-stage`]: `Remontar`,
  [`Re-stage product`]: `Remontar o produto`,
  [`Read only`]: `Apenas para Leitura`,
  [`Read-Only`]: `Apenas para Leitura`,
  [`Realized`]: `Realizada`,
  [`Reason`]: `Razão`,
  [`Reason for declining`]: `Motivo da recusa`,
  [`Recommendations`]: `Recomendações`,
  [`Recommended configuration`]: `Configuração recomendada`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Registre o ID de Cliente e o segredo mostrados abaixo para uso posterior. O segredo do cliente não pode ser exibido mais tarde.`,
  [`Recommendations applied`]: `Recomendações aplicadas`,
  [`Redact`]: `Editar`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Edite ou remova os campos especificados do corpo da solicitação, corpo de resposta e logs de atividade. Defina o campo para editar ou remover ao especificar uma expressão XPath.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Edite ou remova os campos especificados do corpo da solicitação, corpo de resposta e logs de atividade. Defina o campo para editar ou remover especificando uma expressão XPath ou JSONata.`,
  [`Redaction`]: `Edição de Dados`,
  [`Redactions`]: `Edições de Dados`,
  [`Redirect`]: `Redirecionamento`,
  [`Redirect URI`]: `URI de Redirecionamento`,
  [`Redirect URL`]: `Redirecionar URL`,
  [`Redirect through Portal`]: `Redirecionar por meio do Portal`,
  [`Redirect time limit`]: `Redirecionar limite de tempo`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `O limite de tempo de redirecionamento deve ser entre 10 e 600 segundos`,
  [`References`]: `Referências`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Consulte o [IBM Cloud Docs]({link}) para obter orientação adicional.`,
  [`Referral`]: `Referência`,
  [`Refresh`]: `Atualizar`,
  [`Refresh Token`]: `Token de Atualização`,
  [`Refresh button`]: `Botão Atualizar`,
  [`Refresh products`]: `Atualizar produtos`,
  [`Refresh results from server`]: `Atualizar resultados do servidor`,
  [`Refresh token`]: `Token de atualização`,
  [`Refresh token time to live`]: `Tempo de vida do token de atualização`,
  [`Refresh token time-to-live`]: `Tempo de vida do token de atualização`,
  [`Refresh token time-to-live (seconds)`]: `Tempo de vida do token de atualização (segundos)`,
  [`Refresh token TTL has been changed`]: `O tempo de vida do token de atualização foi mudado`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `O tempo de vida do token de atualização é **{seconds} segundos ({time})**`,
  [`Refresh token time to live is **off**`]: `O tempo de vida do token de atualização está **desligado**`,
  [`Refresh tokens`]: `Atualizar tokens`,
  [`Refreshing`]: `Atualizando`,
  [`Refreshing...`]: `Atualizando...`,
  [`Region`]: `Região`,
  [`Register`]: `Registrar`,
  [`Register Gateways`]: `Registrar Gateways`,
  [`Register Service`]: `Registrar serviço`,
  [`Register gateways`]: `Registrar gateways`,
  [`Register service`]: `Registrar serviço`,
  [`Register a service`]: `Registrar um serviço`,
  [`Register new services and manage existing services`]: `Registrar novos serviços e gerenciar serviços existentes`,
  [`Register remote gateway`]: `Registrar gateway remoto`,
  [`Registered Admin Member`]: `Membro de administrador registrado`,
  [`Registered Provider Org Owner or Member`]: `Proprietário ou membro da organização do provedor registrado`,
  [`Registered client information on the OpenID provider`]: `As informações do cliente foram registradas no provedor OpenID`,
  [`Registration completed successfully`]: `Registro concluído com sucesso`,
  [`Registration failed.`]: `Falha no registro.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Caminhos relativos para os terminais individuais. Eles devem ser relativos ao 'basePath'.`,
  [`Relaxed Badgerfish`]: `Badgerfish Afrouxado`,
  [`Remove analysis configuration`]: `Remover a configuração de análise`,
  [`Remote API Connect`]: `API Connect remota`,
  [`Remote login`]: `Login remoto`,
  [`Remote publish`]: `Publicação remota`,
  [`Remove`]: `Remover`,
  [`Remove Burst Limit`]: `Remover limite de distribuição`,
  [`Remove constraints`]: `Remover restrições`,
  [`Remove Count Limit`]: `Remover o limite de contagem`,
  [`Remove gateway extension`]: `Remover a extensão de gateway`,
  [`Remove product`]: `Remover produto`,
  [`Remove Rate limit`]: `Remover o limite de taxa`,
  [`Remove the invalid constraints`]: `Remover as restrições inválidas`,
  [`Remove the invalid constraints from`]: `Remover as restrições inválidas de`,
  [`Remove action`]: `Remover ação`,
  [`Remove case`]: `Remover caso`,
  [`Remove catch`]: `Remover captura`,
  [`Remove filter`]: `Remover filtro`,
  [`Remove input`]: `Remover entrada`,
  [`Remove mapping`]: `Remover mapeamento`,
  [`Remove output`]: `Remover saída`,
  [`Remove static value`]: `Remover valor estático`,
  [`Removed`]: `Removido`,
  [`Removed by user`]: `Removido pelo usuário`,
  [`Reorder`]: `Nova solicitação`,
  [`Repeat`]: `Repetir`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Repita a chamada de API um número determinado de vezes ou até que o botão de parada seja clicado`,
  [`Replace`]: `Substituir`,
  [`Replace Free Trial with`]: `Substituir Avaliação Grátis por`,
  [`Replace Gold with`]: `Substituir Ouro por`,
  [`Replace GraphQL schema`]: `Substituir o esquema GraphQL`,
  [`Replace plans`]: `Substituir planos`,
  [`Replace private key & public key`]: `Substituir a chave privada e a chave pública`,
  [`Replace Product`]: `Substituir produto`,
  [`Replace product`]: `Substituir produto`,
  [`Replace schema`]: `Substituir esquema`,
  [`Replace Silver with`]: `Substituir Prata por`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Substitua o proprietário atual escolhendo o novo proprietário na lista de sugestões. Designe ao proprietário substituído novas permissões para que ele possa continuar a trabalhar.`,
  [`Replace|permission`]: `Substituir`,
  [`Republish`]: `Republicar`,
  [`Republish product`]: `Republicar produtos`,
  [`Request`]: `Solicitação`,
  [`Request catch`]: `Captura da solicitação`,
  [`Request endpoint`]: `Terminal de solicitação`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Solicitação aprovada para {action} o produto de API no catálogo {catalog}`,
  [`Request approved to {{action}} app {{appName}}`]: `Solicitação aprovada para o  {action} aplicativo {appName}`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Solicitação negada para {action} o produto de API no catálogo {catalog}`,
  [`Request denied to {{action}} app {{appName}}`]: `Solicitação negada para o  {action} aplicativo {appName}`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Solicitação de aprovação de assinatura de um produto de API no catálogo {catalog}`,
  [`Request for approval to {{action}} app {{appName}}`]: `Solicitação de aprovação para  {action} app {appName}`,
  [`Request headers`]: `Cabeçalhos da solicitação`,
  [`Request pending to promote application to production`]: `Solicitação pendente para promover o aplicativo para produção`,
  [`Request query`]: `Solicitação de consulta`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Solicitação recebida para {action} o produto de API no catálogo {catalog}`,
  [`Request received to {{action}} app {{appName}}`]: `Solicitação recebida para o {action} aplicativo {appName}`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Solicitação retirada de assinatura de um produto de API no catálogo {catalog}`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Solicitação retirada de {action} um produto de API no catálogo {catalog}`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Solicitação retirada para o  {action} aplicativo {appName}`,
  [`Requested approvals`]: `Aprovações solicitadas`,
  [`Requested By`]: `Solicitado por`,
  [`Requested To`]: `Solicitado para`,
  [`Require`]: `Requerer`,
  [`Require applications to provide a client ID`]: `Requerer que os aplicativos forneçam um ID de cliente`,
  [`Require approval for all new self service development accounts`]: `Requerer aprovação para todas as novas contas de desenvolvimento de autoatendimento`,
  [`Required`]: `Necessário`,
  [`Required for rate limiting`]: `Necessário para a limitação de taxa`,
  [`Required must be checked off when location is in path.`]: `Necessário. Deve ser retirado quando o local estiver no caminho.`,
  [`Required path`]: `Caminho necessário`,
  [`Resend Invite`]: `Reenviar Convite`,
  [`Resend invitation`]: `Reenviar convite`,
  [`Resend invite`]: `Reenviar convite`,
  [`Reset`]: `Reconfigurar`,
  [`Reset defaults`]: `Reconfigurar padrões`,
  [`Reset Notification template`]: `Reconfigurar o modelo de notificação`,
  [`Reset password`]: `Reconfigurar senha`,
  [`Reset password TTL has been changed`]: `O TTL de reconfiguração de senha foi mudado`,
  [`Reset password time to live`]: `Tempo de vida da reconfiguração de senha`,
  [`Reset password time-to-live`]: `Tempo de vida da reconfiguração de senha`,
  [`Reset password email has been sent`]: `O e-mail de reconfiguração de senha foi enviado`,
  [`Reset password email has not been sent`]: `O e-mail de reconfiguração de senha não foi enviado`,
  [`Reset password invitation timeout`]: `Tempo limite do convite de reconfiguração de senha`,
  [`Reset to default preferences`]: `Reconfigurar para as preferências padrão`,
  [`Resolve API Connect variable references`]: `Resolver referências de variável do API Connect`,
  [`Resolve XML input data type`]: `Resolver o tipo de dados de entrada XML`,
  [`resolver`]: `resolução`,
  [`Resource group`]: `Grupo de recursos`,
  [`Resource Owner`]: `Proprietário do recurso`,
  [`Resource owner password grant`]: `Concessão de senha do proprietário do recurso`,
  [`Resource owner revocation`]: `Revocação do proprietário do recurso`,
  [`Resource owner revocation path`]: `Caminho de revogação do proprietário do recurso`,
  [`Resource Owner Security`]: `Segurança do proprietário do recurso`,
  [`Resource owner - JWT`]: `Proprietário do recurso - JWT`,
  [`Resource owner - Password`]: `Proprietário do recurso - Senha`,
  [`Resource owner password`]: `Senha do proprietário do recurso`,
  [`Resources`]: `Recursos`,
  [`Response`]: `Resposta`,
  [`Response catch`]: `Captura de resposta`,
  [`Response Definition`]: `Definição de resposta`,
  [`Response Time`]: `Tempo de resposta`,
  [`Response type`]: `Tipo de Resposta`,
  [`Response context variables`]: `Variáveis de contexto de resposta`,
  [`Response headers`]: `Cabeçalhos de resposta`,
  [`Response must have a description`]: `A resposta deve ter uma descrição`,
  [`Response must have a schema`]: `A resposta deve ter um esquema`,
  [`Response must have a status code`]: `A resposta deve ter um código de status`,
  [`Response object variable`]: `Variável do objeto de resposta`,
  [`Response time`]: `Tempo de Resposta`,
  [`Response time: {{value}}ms`]: `Tempo de resposta: {{value}} ms`,
  [`Result of removing`]: `Resultado da remoção`,
  [`Restrict HTTP transactions to version 1.0.`]: `Restringir as transações HTTP à versão 1.0.`,
  [`Restrict to HTTP 1.0`]: `Restringir ao HTTP 1.0`,
  [`Retire`]: `Desativar`,
  [`Retire product`]: `Desativar o produto`,
  [`Retired`]: `Desativado`,
  [`Retire|permission`]: `Desativar`,
  [`Return V5 Responses`]: `Retornar respostas da V5`,
  [`Retry selected requests`]: `Tentar novamente solicitações selecionadas`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Retornar resultados para consultas de introspecção do GraphQL padrão do proxy do GraphQL`,
  [`Return third-party access_token`]: `Retorne o access_token de terceiros`,
  [`Return third-party access_token with the token response`]: `Retorne o access_token de terceiros com a resposta do token`,
  [`Return third-party id_token`]: `Retorne o id_token de terceiros`,
  [`Return third-party id_token with the token response`]: `Retorne o id_token de terceiros com a resposta do token`,
  [`Return to Login`]: `Retornar ao login`,
  [`Reveal in finder`]: `Mostrar no localizador`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Revise e determine o que mostrar ou ocultar no esquema. Mudanças adicionais podem ser necessárias para manter o esquema válido.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Revise todos os avisos. Nós ajudaremos você a melhorar o seu esquema com recomendações inteligentes posteriormente no editor.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Revise todos os avisos e aplique as nossas recomendações inteligentes para melhorar o seu esquema e proteger o seu back-end.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Revise a URL base do terminal de chamada de API e especifique os domínios manipulados pelo gateway.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Revise o tipo, o título, o nome e o resumo do gateway. Ao chamar os comandos e as APIs do API Connect, use o nome do gateway.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Revise o resumo das mudanças, que pode incluir mudanças adicionais para manter o esquema válido.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Revise os avisos que localizamos em seu esquema. Ajudaremos você a melhorar o seu esquema e proteger seu back-end com recomendações inteligentes posteriormente no editor.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Revise os avisos que localizamos em seu esquema. Nós ajudaremos você a melhorar o seu esquema e proteger o seu back-end com recomendações inteligentes.`,
  [`Reviewed schema, paths and operations`]: `Esquema, caminhos e operações revisados`,
  [`Revocation protocol`]: `Protocolo de revogação`,
  [`Revoke token`]: `Revogar token`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `Docs do RHEL`,
  [`Role`]: `Função`,
  [`Role default`]: `Padrão de função`,
  [`Role default has been deleted`]: `O padrão de função foi excluído`,
  [`Role defaults`]: `Padrões de função`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Os padrões de função são modelos de função pré-configurada usados para determinar as funções padrão disponíveis quando uma nova organização é criada.`,
  [`Role has been created`]: `A função foi criada`,
  [`Role has been deleted`]: `A função foi excluída`,
  [`Role has been updated`]: `A função foi atualizada`,
  [`Roles`]: `Funções`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `As funções (e suas permissões designadas) controlam o acesso aos recursos no API Connect. As funções de organização do administrador aplicam-se apenas às pessoas que usam o Cloud Manager.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `As funções e permissões controlam o acesso aos recursos do API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `As funções e suas permissões associadas controlam o acesso aos recursos e a capacidade de executar tarefas no API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `As funções e suas permissões associadas controlam o acesso aos recursos e a capacidade de executar tarefas na IU do API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `As funções e suas permissões associadas controlam o acesso aos recursos e a capacidade de executar tarefas.`,
  [`Root`]: `Raiz`,
  [`Root XML Element Name`]: `Nome do elemento XML raiz`,
  [`Run the following command in the CLI to register the credentials:`]: `Execute o comando a seguir na CLI para registrar as credenciais:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Execute o comando a seguir na CLI para registrar as credenciais do kit de ferramentas:`,
  [`Running`]: `Executando`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Variável de tempo de execução que contém o JWK a ser usado para decriptografar o JWT.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Variável de tempo de execução contendo o JWK a ser usado para criptografar o JWT.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Variável de tempo de execução contendo o JWK a ser usado para assinar o JWT.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Variável de tempo de execução que contém o JWK a ser usado para verificar a assinatura.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Variável de tempo de execução a partir da qual um conjunto válido de solicitações JSON pode ser recuperado.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Variável de tempo de execução a partir da qual a sequência de solicitação do público (aud) pode ser recuperada. Variáveis múltiplas são configuradas por meio de uma sequência separada por vírgula.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Variável de tempo de execução a partir da qual a sequência de solicitação do emissor (iss) pode ser recuperada. Esta solicitação representa o diretor que emitiu o JWT.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Variável de tempo de execução a partir da qual a sequência de solicitação do assunto (sub) pode ser recuperada.`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Variável de tempo de execução na qual colocar o JWT gerado. Se não configurado, o JWT é colocado no cabeçalho de autorização como um token de acesso.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Variável de tempo de execução que contém o nome do usuário autenticado. O token LTPA é gerado com essa propriedade como o usuário. Por exemplo, $(client.app.id), ou $(oauth.resource-owner) ou uma variável de tempo de execução configurada em uma política set-variable.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `A variável de tempo de execução para a qual o conjunto integral de solicitações que estão no JWT está designado.`,
  [`Russian`]: `Russo`,
  [`Scalar`]: `Escalar`,
  [`Schema`]: `Esquema`,
  [`SCHEMA`]: `ESQUEMA`,
  [`SCOPE`]: `SCOPE`,
  [`SCOPES`]: `ESCOPOS`,
  [`SECURITY DEFINITIONS`]: `DEFINIÇÕES DE SEGURANÇA`,
  [`Service`]: `Serviço`,
  [`Service state:`]: `Estado de serviço:`,
  [`SERVICE TYPE`]: `TIPO DE SERVIÇO`,
  [`SIL-OFL 1.1 license`]: `Licença do SIL-OFL 1.1`,
  [`SOURCE`]: `ORIGEM`,
  [`SSL Profile`]: `Perfil SSL`,
  [`State`]: `Estado`,
  [`STATUS`]: `STATUS`,
  [`Status Code`]: `Código de status`,
  [`STATUS CODE`]: `CÓDIGO DE STATUS`,
  [`SUBMITTED`]: `ENVIADO`,
  [`SUBSCRIBERS`]: `ASSINANTES`,
  [`Summary`]: `Resumo`,
  [`Same as product`]: `O mesmo que o produto`,
  [`Sample ID token signing key (RS256)`]: `Chave de assinatura do token de ID de amostra (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Chave de validação do token de ID de amostra (RS256)`,
  [`Sample OAuth symmetric key value`]: `Amostra OAuth valor de chave simétrica`,
  [`Sample URL: https://api.catalog.org.host/`]: `URL de Amostra: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `URL de amostra: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Descrição do escopo de amostra 1`,
  [`Sandbox - Overview`]: `Ambiente de simulação - Visão geral`,
  [`Sandbox Catalog`]: `Catálogo do ambiente de simulação`,
  [`Sandbox Catalog User Registry`]: `Registro do usuário do catálogo de ambiente de simulação`,
  [`Sandbox Default TLS client profile`]: `Perfil do cliente TLS padrão do ambiente de simulação`,
  [`Sandbox Default TLS Client Profile`]: `Perfil do cliente TLS padrão do ambiente de simulação`,
  [`Sandbox catalog should always exist in a provider organization.`]: `O catálogo do ambiente de simulação deve sempre existir em uma organização do provedor.`,
  [`Save`]: `Salvar`,
  [`Save API as a new version`]: `Salvar API como um nova versão`,
  [`Save Product as a new version`]: `Salvar produto como um nova versão`,
  [`Save and Edit`]: `Salvar e Editar`,
  [`Save and republish`]: `Salve e publicar novamente`,
  [`Save as a new version`]: `Salvar como Nova versão`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Salve o segredo do cliente (ele não será mais recuperável por motivos de segurança)`,
  [`Save this product as a new version`]: `Salvar este produto como uma nova versão`,
  [`Saved`]: `Salvo`,
  [`Saving assembly...`]: `Salvando conjunto...`,
  [`Saving...`]: `Salvando...`,
  [`Schema Type`]: `Tipo de Esquema`,
  [`Schema as JSON`]: `Esquema como JSON`,
  [`Schema as XML`]: `Esquema como XML`,
  [`Schema as YAML`]: `Esquema como YAML`,
  [`Schema change`]: `Mudança de esquema`,
  [`Schema changes`]: `Mudanças de esquema`,
  [`Schema definition circular reference limit`]: `Limite de referência circular de definição de esquema`,
  [`Schema name`]: `Nome do Esquema`,
  [`Schema warnings`]: `Avisos de esquema`,
  [`Schema definition`]: `Definição de esquema`,
  [`Scheme`]: `Esquema`,
  [`Schemes`]: `Esquemas`,
  [`Scope`]: `Escopo`,
  [`Scopes`]: `Escopos`,
  [`Scopes cannot be empty`]: `Não é possível manter os escopos vazios`,
  [`Search`]: `Procurar`,
  [`Search DN`]: `Procurar DN`,
  [`Search in the field above above and select to add provider organizations`]: `Procure no campo acima e selecione para incluir organizações do provedor`,
  [`Search Limit`]: `Limite de procura`,
  [`Search Organization owner from`]: `Procurar proprietário da organização de`,
  [`Search orgs/groups`]: `Procurar organizações/grupos`,
  [`Search Owner`]: `Procurar Proprietário`,
  [`Search assemblies`]: `Procurar conjuntos`,
  [`Search for`]: `Pesquisar por`,
  [`Search for provider organizations`]: `Procurar organizações do provedor`,
  [`Search for Owner`]: `Procurar por proprietário`,
  [`Search for a new member to become the new owner.`]: `Procure por um novo membro para torná-lo o novo proprietário.`,
  [`Search for an existing member to become the new owner.`]: `Procure por um membro existente para torná-lo o novo proprietário.`,
  [`Search for new owner`]: `Procurar por novo proprietário`,
  [`Search for owner from:`]: `Procurar pelo proprietário de:`,
  [`Search for the member`]: `Procurar o membro`,
  [`Search for title or name or version`]: `Procure por título, nome ou versão`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Procure o usuário que você deseja incluir como um membro e designe as funções necessárias.`,
  [`Search overflow`]: `Estouro de procura`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Procure no registro pelo usuário existente que deseja incluir como um membro e designe as funções necessárias`,
  [`Search types/fields`]: `Tipos/campos de procura`,
  [`Search user`]: `Usuário de procura`,
  [`Search schema`]: `Procurar esquema`,
  [`Seconds`]: `Segundos`,
  [`Secret Key`]: `Chave Secreta`,
  [`Secret Name`]: `Nome de segredo`,
  [`Secret Required`]: `Segredo necessário`,
  [`Secret key`]: `Chave secreta`,
  [`Secure`]: `Proteger`,
  [`Secure connection`]: `Conexão Segura`,
  [`Secure using Client ID`]: `Proteger usando o ID de Cliente`,
  [`Security`]: `Segurança`,
  [`Security & Product Definitions`]: `Segurança e definições de produto`,
  [`Security definitions`]: `Definições de Segurança`,
  [`Security and Publish`]: `Segurança e Publicar`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `As definições de segurança controlam o acesso do cliente a terminais de API, incluindo a validação de chave de API, a autenticação básica por meio de um registro do usuário especificado e o OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `As definições de segurança controlam o acesso do cliente a terminais de API, incluindo a validação de chave de API, a autenticação básica por meio de um registro do usuário especificado e o OAuth. [Saiba mais]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `As definições de segurança selecionadas aqui se aplicam em toda a API, mas podem ser substituídas para operações individuais.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `As definições de segurança selecionadas aqui se aplicam em toda a API, mas podem ser substituídas para operações individuais. [Saiba mais]({link})`,
  [`Security object to use to decode the claim.`]: `Objeto de segurança a ser usado para decodificar a solicitação.`,
  [`Security object used to encrypt the JWT.`]: `Objeto de segurança usado para criptografar o JWT.`,
  [`Security object used to sign the JWT.`]: `Objeto de segurança usado para assinar o JWT.`,
  [`Security object used to verify the claim.`]: `Objeto de segurança usado para verificar a solicitação.`,
  [`Select APIs to add to this product`]: `Selecione as APIs para incluir nesse produto`,
  [`Select API Type`]: `Selecionar tipo de API`,
  [`Select API type`]: `Selecionar tipo de API`,
  [`Select a billing integration in the Product plans.`]: `Selecione uma integração de faturamento nos planos do Produto.`,
  [`Select a flow...`]: `Selecione um fluxo...`,
  [`Select a plan from your existing product`]: `Selecione um plano em seu produto existente`,
  [`Select a policy file to import to this catalog`]: `Selecione um arquivo de política para importar para este catálogo`,
  [`Select a policy implementation file to import to this catalog`]: `Selecione um arquivo de implementação de política para importar para este catálogo`,
  [`Select a product`]: `Selecionar um produto`,
  [`Select Certificate Manager service`]: `Selecione o serviço Certificate Manager`,
  [`Select Cloud`]: `Selecione Cloud`,
  [`Select Email Server`]: `Selecionar servidor de e-mail`,
  [`Select gateway services`]: `Selecionar os serviços de gateway`,
  [`Select Import Type`]: `Selecionar tipo de importação`,
  [`Select product`]: `Selecione o produto`,
  [`Select product type`]: `Selecionar tipo de produto`,
  [`Select Service`]: `Selecionar Serviço`,
  [`Select Service Type`]: `Selecionar Tipo de Serviço`,
  [`Select target endpoints`]: `Selecionar os terminais de destino`,
  [`Select target service`]: `Selecionar o serviço de destino`,
  [`Select user registry type`]: `Selecione o tipo de registro do usuário`,
  [`Select a WSDL service from the imported file`]: `Selecione um serviço WSDL do arquivo importado`,
  [`Select a certificate`]: `Selecione um certificado`,
  [`Select a Certificate Manager service`]: `Selecione um serviço Certificate Manager`,
  [`Select a cryptographic algorithm.`]: `Selecione um algoritmo criptográfico.`,
  [`Select a default catalog and organization`]: `Selecione um catálogo e uma organização padrão`,
  [`Select a different cloud`]: `Selecionar uma nuvem diferente`,
  [`Select a key encryption algorithm.`]: `Selecione um algoritmo de criptografia de chave.`,
  [`Select a plan to migrate`]: `Selecionar um plano a ser migrado`,
  [`Select a plan to replace`]: `Selecione um plano para substituir`,
  [`Select a plan to supersede`]: `Selecione um plano para suplantar`,
  [`Select a product to set as the migration target`]: `Selecionar um produto a ser configurado como o destino de migração`,
  [`Select a product to supersede`]: `Selecione um produto para suplantar`,
  [`Select a product to supersede {sourceProductName}:`]: `Selecione um produto para suplantar {sourceProductName}:`,
  [`Select a resource group`]: `Selecione um grupo de recursos`,
  [`Select a service`]: `Selecione um serviço`,
  [`Select a supported file ({type}) and try again.`]: `Selecione um arquivo suportado ({type}) e tente novamente.`,
  [`Select a target SOAP service`]: `Selecione um serviço SOAP de destino`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Selecione um pacote de kit de ferramentas para fazer download. Ignore esta etapa se você tiver o kit de ferramentas instalado.`,
  [`Select an encryption algorithm.`]: `Selecione um algoritmo de criptografia.`,
  [`Select an extension file for this gateway`]: `Selecione um arquivo de extensão para este gateway`,
  [`Select an org`]: `Selecione uma organização`,
  [`Select catalog`]: `Selecionar Catálogo`,
  [`Select Catalogs to Search`]: `Selecionar catálogos para procura`,
  [`Select compatible gateway services`]: `Selecionar serviços de gateway compatíveis`,
  [`Select one Keystore from the list below:`]: `Selecione um keystore da lista abaixo:`,
  [`Select one keystore from the list below:`]: `Selecione um Armazenamento de chaves na lista abaixo:`,
  [`Select one keystore from the list.`]: `Selecione um Armazenamento de chaves na lista.`,
  [`Select operation...`]: `Selecionar operação...`,
  [`Select operations you want to include`]: `Selecione as operações que deseja incluir`,
  [`Select organization`]: `Selecionar organização`,
  [`Select the API definition or package file to import`]: `Selecione o arquivo de definição ou de pacote de API a ser importado`,
  [`Select the billing integration for this catalog`]: `Selecione a integração de faturamento para este catálogo`,
  [`Select the billing integration for this product`]: `Selecione a integração de faturamento para este produto`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Selecione os gateways que protegerão as APIs em um novo catálogo. Para registrar um novo gateway, visite a página **Gateways**.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Selecione o serviço [Certificate Manager]({link}) que gerencia os certificados para este gateway.`,
  [`Select the APIs to add to this product`]: `Selecione as APIs a serem incluídas nesse produto`,
  [`Select the OpenAPI definition file to import`]: `Selecione o arquivo de definição OpenAPI a ser importado`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Selecione as ações de ciclo de vida do Produto que requerem aprovação. Quando qualquer uma das ações selecionadas é executada, uma solicitação de aprovação é gerada; a solicitação deve ser aprovada para que a ação seja concluída.`,
  [`Select the TLS protocol versions for this profile.`]: `Selecione as versões de protocolo TLS para este perfil.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Selecione o serviço de Analítica que você gostaria de associar ao serviço de gateway`,
  [`Select the consumer organization in which you want to create the application`]: `Selecione a organização do consumidor no qual você deseja criar o aplicativo`,
  [`Select the consumer organization that will own the application`]: `Selecione a organização do consumidor que será a proprietária do aplicativo`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Selecione os terminais para publicar esta API e definir o limite de taxa conforme necessário`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Selecione os erros que, se lançados durante a execução da política, farão o fluxo de conjuntos parar. Se houver um fluxo de captura configurado para o erro, ele será acionado para lidar com o erro gerado. Se um erro for lançado e não houver erros selecionados para a configuração Parar em caso de erro ou se o erro lançado não for um dos erros selecionados, a execução da política poderá ser concluída e o fluxo de conjuntos continuará.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Selecione os serviços de gateway nos quais as APIs do Produto devem ser disponibilizadas.`,
  [`Select the gateway type for this API`]: `Selecione o tipo de gateway para esta API`,
  [`Select the gateway type for this OAuth provider`]: `Selecione o tipo de gateway para este provedor OAuth`,
  [`Select the keystores and truststores for this profile.`]: `Selecione os Armazenamentos de chaves e os armazenamentos confiáveis para este perfil.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Selecione as organizações ou grupos para os quais deseja tornar esse produto visível`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Selecione as organizações ou grupos que deseja inscrever para esse produto`,
  [`Select the portal service to use for this catalog`]: `Selecione o serviço de portal a ser usado para esse catálogo`,
  [`Select the product and plan for the application subscription`]: `Selecione o produto e planeje a assinatura do aplicativo`,
  [`Select the product definition file to import`]: `Selecione o arquivo de definição de produto a ser importado`,
  [`Select the product definition file to import from`]: `Selecione o arquivo de definição de produto a ser importado`,
  [`Select the product that will supersede the originally selected product`]: `Selecione o produto que substituirá o produto originalmente selecionado`,
  [`Select the product that you want to add the API to`]: `Selecione o produto no qual deseja incluir a API`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Selecione os registros que você deseja usar para controlar o acesso às APIs neste catálogo`,
  [`Select the required operations`]: `Selecione as operações necessárias`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Selecione o nível de severidade das mensagens de log que se relacionam aos dados de entrada. Os níveis possíveis são erro, aviso e informações.`,
  [`Select the target wsdl file to create from`]: `Selecione o arquivo WSDL de destino a ser criado`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Selecione o arquivo wsdl, xsd ou zip de destino do qual criar a definição de API`,
  [`Select the target WSDL file to import`]: `Selecione o arquivo WSDL de destino a ser importado`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Selecione os registros do usuário para a autenticação de usuários do API Manager ou para criar um novo registro do usuário.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Selecione os registros do usuário para autenticar usuários do API Manager ou crie um novo registro do usuário.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Selecione os registros do usuário para autenticar usuários do Cloud Manager ou crie um novo registro do usuário.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Selecione os registros do usuário que são usados para autenticar o login no Portal do Desenvolvedor`,
  [`Select the user registry type`]: `Selecionar o tipo de registro do usuário`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Selecione onde na origem de saída a política deve colocar o token LTPA gerado.  Em WSSec, o Cabeçalho deve ser selecionado somente se o tipo de conteúdo na solicitação for aplicativo/xml.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Selecione se a propriedade será removida completamente ou se o campo será editado usando "*"s para bloquear os dados.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Produto selecionado foi modificado. Atualize a página e tente novamente.`,
  [`Selecting operations from WSDL service definition`]: `Selecionando operações a partir da definição de serviço WSDL`,
  [`Self Service Onboarding for Consumer organization`]: `Integração de autoatendimento para a organização do consumidor`,
  [`Self service onboarding`]: `Integração de autoatendimento`,
  [`Self service onboarding approval`]: `Aprovação de integração de autoatendimento`,
  [`Send`]: `Enviar`,
  [`Send Message`]: `Enviar Mensagem`,
  [`Send Message to Owners`]: `Enviar mensagem para proprietários`,
  [`Send To`]: `Enviar para`,
  [`Send a registration link to a new user to register as owner.`]: `Envie um link de registro para um novo usuário para registrá-lo como proprietário.`,
  [`Send a test email`]: `Enviar um e-mail de teste`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Envie um e-mail de teste para confirmar se o servidor de e-mail está configurado corretamente.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Envie um sinal de teste para confirmar se o terminal está configurado corretamente.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Envie um sinal de teste para confirmar se o terminal está configurado corretamente`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Envie uma mensagem de e-mail para o proprietário da organização do administrador. Coloque um visto ao lado do nome do proprietário`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Envie uma mensagem por e-mail a um proprietário. Coloque um visto ao lado do nome do proprietário`,
  [`Send chunked-encoded documents to the target server.`]: `Enviar documentos codificados em partes para o servidor de destino.`,
  [`Send message`]: `Enviar mensagem`,
  [`Send message to owner`]: `Enviar mensagem para o proprietário`,
  [`Send test email`]: `Enviar e-mail de teste`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Envie o link de ativação a um usuário para convidá-lo a conectar-se ou registrar-se`,
  [`Send-only`]: `Somente Envio`,
  [`Sender`]: `Emissor`,
  [`Sender & email server`]: `Remetente e servidor de e-mail`,
  [`Sender & email server have been changed`]: `O remetente e o servidor de e-mail foram mudados`,
  [`Sender Info`]: `Informações do Remetente`,
  [`Sent`]: `Enviado`,
  [`Sent events: **{sent}**`]: `Eventos enviados: **{sent}**`,
  [`Serial`]: `Número de Série`,
  [`Serialize output`]: `Serializar saída`,
  [`Server connection`]: `Conexão do Servidor`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `Entregar HTML para os navegadores da web a fim de permitir o cliente do GraphQL da GUI`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Entregue HTML para navegadores da web para permitir o cliente do GraphQL da GUI.`,
  [`Server Name Indication (SNI)`]: `Indicação do Nome do Servidor (SNI)`,
  [`Server URL`]: `URL do Servidor`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `O servidor retornou um erro em hash, em vez de token na URL de redirecionamento do OIDC. Hash:`,
  [`Service name`]: `Nome do Serviço`,
  [`Services`]: `Serviços`,
  [`Set`]: `Configurar`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Configure a variável de ambiente de sistema operacional APIC_DESIGNER_CREDENTIALS para o API Designer. Por exemplo, no MAC, o usuário pode submeter a variável de ambiente enquanto ativa o API Designer.`,
  [`Set Migration Target`]: `Configurar destino de migração`,
  [`Set migration target`]: `Configurar destino de migração`,
  [`Set Variable`]: `Configurar variável`,
  [`Set as default`]: `Definir como Padrão`,
  [`Set default`]: `Definir como padrão`,
  [`Set rate and burst limits in an assembly`]: `Configurar limites de taxa e de distribuição em um conjunto`,
  [`Set the timeout period for invitations`]: `Configure o período de tempo limite para convites`,
  [`Set the trust level for the profile`]: `Configure o nível de confiança para o perfil`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Configure o valor para o número de iterações de uma definição de esquema circular que são permitidas (padrão de 1, máximo de 5)`,
  [`Set the visibility for a gateway service`]: `Configurar a visibilidade para um serviço de gateway`,
  [`Set the visibility for a portal service`]: `Configurar a visibilidade para um serviço de portal`,
  [`Set the visibility for each service`]: `Configure a visibilidade para cada serviço`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Configure o prazo para obter um novo token de acesso emitido pelo servidor de autenticação`,
  [`Set visibility`]: `Configurar Visibilidade`,
  [`Set visibility of gateway`]: `Configure a visibilidade do gateway`,
  [`Set time interval`]: `Configurar intervalo de tempo`,
  [`Set, Add, or Clear a runtime variable.`]: `Configurar, incluir ou limpar uma variável de tempo de execução.`,
  [`Set the timeout period for API key`]: `Configure o período de tempo limite para a chave de API`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Configura o período de validade em segundos para documentos no cache. Será aplicado somente se o tipo de cache estiver configurado para "Tempo de Vida". Insira um valor no intervalo de 5 a 31708800. O valor padrão é 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `A configuração de um valor para "assumedSize" não pode ser combinada com a definição do argumento de fatiamento que tem um valor padrão ou não pode ser combinada com a ativação de "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Configurar este catálogo como o catálogo padrão permitirá que ele seja acessado usando uma URL mais curta, omitindo o nome do catálogo. É possível configurar apenas um catálogo como padrão.`,
  [`Settings`]: `Configurações`,
  [`Setup`]: `Configuração`,
  [`Severity level for input data log messages`]: `Nível de severidade para mensagens de log de dados de entrada`,
  [`Share the resource with a custom list of provider organizations`]: `Compartilhe o recurso com uma lista customizada de organizações do provedor`,
  [`Share the resource with provider organizations`]: `Compartilhe o recurso com as organizações do provedor`,
  [`Share the API gateway with a custom list of provider organizations`]: `Compartilhe o API Gateway com uma lista customizada de organizações do provedor`,
  [`Share the service with a custom list of provider organizations`]: `Compartilhe o serviço com uma lista customizada de organizações do provedor`,
  [`Share the API gateway with provider organizations`]: `Compartilhe o API Gateway com organizações do provedor`,
  [`Share the service with provider organizations`]: `Compartilhe o serviço com organizações do provedor`,
  [`Share`]: `Compartilhar`,
  [`Shared`]: `Compartilhado`,
  [`Shared by`]: `Compartilhado por`,
  [`Short but descriptive name for this policy.`]: `Nome curto, mas descritivo para esta política.`,
  [`Show`]: `Mostrar`,
  [`Show / hide policy palette`]: `Mostrar/ocultar paleta de política`,
  [`Show/hide`]: `Mostrar/ocultar`,
  [`Show/hide settings`]: `Mostrar/ocultar configurações`,
  [`Show and hide types and fields to include in the schema.`]: `Mostrar e ocultar tipos e campos para incluir no esquema.`,
  [`Show added only`]: `Mostrar somente incluído`,
  [`Show all`]: `Mostrar Tudo`,
  [`Show all UDP versions`]: `Mostrar todas as versões do UDP`,
  [`Show app identifier`]: `Mostrar identificador de aplicativo`,
  [`Show breaking changes only`]: `Mostrar somente alterações que afetam o processamento da mensagem`,
  [`Show catalog identifier`]: `Mostrar identificador de catálogo`,
  [`Show catches`]: `Mostrar capturas`,
  [`Show in schema`]: `Mostrar no esquema`,
  [`Show less`]: `Mostrar menos`,
  [`Show modified only`]: `Mostrar somente modificado`,
  [`Show more`]: `Mostrar mais`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Mostrar novos tipos, campos, diretivas ou argumentos opcionais. Os itens que não forem mais suportados pelo back-end ainda serão removidos.`,
  [`Show removed only`]: `Mostrar somente removido`,
  [`Show schema`]: `Mostrar Esquema`,
  [`Show warnings only`]: `Mostrar apenas os avisos`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Mostra o histórico das designações de keystore, exceto o keystore designado atualmente.`,
  [`Sign Crypto Object`]: `Assine o objeto criptográfico`,
  [`Sign in`]: `Conectar`,
  [`Sign JWK variable name`]: `Assine o nome da variável JWK`,
  [`Sign Out`]: `Sair`,
  [`Sign up`]: `Inscrever`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Conecte-se usando o registro do usuário do {userRegistryType}`,
  [`Sign in with`]: `Conectar com`,
  [`Sign out`]: `Sair`,
  [`Sign up with`]: `Inscreva-se com`,
  [`Signed token`]: `Token assinado`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Como o armazenamento em buffer foi desativado, é necessário que o conteúdo e/ou o conteúdo de erro sejam reduzidos da carga útil para os cabeçalhos. Deseja continuar com a mudança necessária ou cancelar sua mudança recente?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Como o conteúdo e/ou o conteúdo do erro foi configurado para carga útil, é necessário que o armazenamento em buffer seja ativado. Deseja continuar com a mudança necessária ou cancelar sua mudança recente?`,
  [`Sized fields`]: `Campos dimensionados`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Argumentos de fatiamento`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Algumas entradas são matrizes ou contidas dentro de raízes. Para iterar sobre o conteúdo, escolha a opção iterar e escolha a entrada sobre a qual iterar.`,
  [`Something went wrong.`]: `Algo errado.`,
  [`Something went wrong...`]: `Algo deu errado...`,
  [`Source`]: `Origem`,
  [`Source schema`]: `Esquema de Origem`,
  [`Space`]: `Espaço`,
  [`Space is not allowed in scope name`]: `Espaço não é permitido no nome do escopo`,
  [`Space details`]: `Detalhes do Espaço`,
  [`Space Enabled`]: `Espaço Ativado`,
  [`Space Name`]: `Nome do Espaço`,
  [`Space Summary`]: `Resumo do Espaço`,
  [`Space Title`]: `Título do espaço`,
  [`Space owner`]: `Proprietário do espaço`,
  [`Space {name} created`]: `Espaço {name} criado`,
  [`Spaces`]: `Espaços`,
  [`Spaces are enabled`]: `Os espaços estão ativados`,
  [`Spaces are not enabled`]: `Os espaços não estão ativados`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Espaços não podem ser ativados para o catálogo do ambiente de simulação.`,
  [`Spaces cannot be enabled.`]: `Espaços não podem ser ativados.`,
  [`Spaces dropdown`]: `Menu suspenso Espaços`,
  [`Spaces has been enabled`]: `Espaços foram ativados`,
  [`Spaces have been enabled.`]: `Os espaços foram ativados.`,
  [`Spaces per page`]: `Espaços por página`,
  [`Spanish`]: `Espanhol`,
  [`Special characters are not accepted`]: `Caracteres especiais não são aceitos`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Especifica uma URL ou sequência que representa um contexto nomeado a partir do qual recuperar propriedades XML ou JSON serializadas que estão sendo mescladas no objeto dinâmico. Essas propriedades têm precedência sobre qualquer propriedade literal ou padrão existente.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Especifica uma sequência de caracteres literal como propriedades XML ou JSON serializadas que estão sendo mescladas no objeto dinâmico. Essas propriedades têm precedência sobre qualquer propriedade padrão existente.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Especifica um objeto válido existente do qual recuperar valores de propriedade padrão para o objeto dinâmico. Aviso: se você mudar a partir da sequência padrão, o objeto de configuração de análise especificado deverá estar disponível no dispositivo DataPower.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Especifica a origem de dados que contém o conteúdo a ser editado ou removido. Se a raiz não for especificada, a ação será aplicada ao contexto da API inteiro. É possível usar qualquer expressão de caminho JSONata suportada.\n\nA edição de dados de solicitação e resposta pode ser executada com uma raiz de "message.body". Se a ação de edição do conjunto for usada em um conjunto após uma ação de log do conjunto que especifica o modo somente de reunião (gather-only), a raiz poderá especificar "log.request_body" para a carga útil de solicitação registrada ou "log.response_body" para a carga útil de resposta registrada.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Especifica o nome de uma variável no contexto da API. O conteúdo do campo "corpo" da variável é a entrada para a ação. O nome da variável padrão é "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Especifica o nome de uma variável no contexto da API. O conteúdo do campo "corpo" da variável é a saída da análise. As métricas de análise do documento analisado podem ser armazenadas em uma parte diferente da mensagem. O nome da variável padrão é o que o nome de Entrada é, portanto, por padrão, a mensagem de Entrada será sobrescrita com a mensagem de Saída.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Especifica se e onde obter metadados customizados para o token de acesso.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Especifica se deve-se tentar uma análise com o tipo de conteúdo especificado. Isso será aplicável apenas se o tipo de conteúdo esperado for json ou xml. Com essa opção ativada, uma ação de análise resultará em falha se o tipo de conteúdo não corresponder às configurações de análise (ou seja, Tipo de conteúdo:aplicativo/json e configurações de análise são configuradas para xml). Se a configuração de análise estiver configurada para detectar conteúdo de entrada, ela será forçada para o tipo de conteúdo especificado.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Especifica se a árvore de saída XSLT será serializada para dados binários após a transformação.`,
  [`Specify default gateways`]: `Especifique os gateways padrão`,
  [`Specify email`]: `Especificar e-mail`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Especifique uma definição de esquema OpenAPI (Swagger) com a qual validar a carga útil ("request", "response" ou "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Especifique o proprietário da organização do consumidor`,
  [`Specify owner of the provider organization`]: `Especifique o proprietário da organização do provedor`,
  [`Specify settings for the third party OAuth provider.`]: `Especifique as configurações para o provedor OAuth de terceiros.`,
  [`Specify the MIME types that your API consumes and produces`]: `Especifique os tipos MIME que sua API utiliza e produz`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Especifique o proprietário do catálogo; o proprietário possui todas as permissões de catálogo`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Especifique as organizações de consumidores ou as comunidades de desenvolvedores que poderão ver o produto no portal do desenvolvedor`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Especifique as organizações de consumidores ou as comunidades de desenvolvedores que poderão se inscrever para usar as APIs no produto`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Especifique a URL base do terminal de chamada de API e os domínios manipulados pelo gateway.`,
  [`Specify the details of the catalog that you want to publish to`]: `Especifique os detalhes do catálogo no qual deseja publicar`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Especifique o conjunto padrão de gateways que protegerá as APIs em um novo catálogo. [Saiba mais]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Especifique os terminais nos quais esta API deve ser publicada, e se deseja limitar a taxa de chamadas API`,
  [`Specify the new owner`]: `Especifique o novo proprietário`,
  [`Specify the owner`]: `Especifique o proprietário`,
  [`Specify the owner and title`]: `Especifique o proprietário e o título`,
  [`Specify the properties to redact or remove.`]: `Especifique as propriedades para editar ou remover.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Especifique os requisitos de limitação de taxa e de publicação para esta API`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Especifique o terminal de verificação de escopo no qual a verificação de escopo adicional é executada, além dos escopos definidos.`,
  [`Specify the security settings for the imported API definition`]: `Especifique as configurações de segurança para a definição de API importada`,
  [`Specify the security settings for your API definition`]: `Especifique as configurações de segurança para a definição de sua API`,
  [`Specify the space owner; the owner has all space permissions`]: `Especifique o proprietário do espaço; ele terá todas as permissões`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Especifique o serviço SOAP de destino e os detalhes de resumo da API; se necessário, é possível configurar ainda mais a definição de API depois de criá-la`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Especifique o período de tempo limite após o qual os e-mails de convite expirarão se o usuário não ativar sua conta`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Especifique o período de tempo limite após o qual os e-mails de convite expirarão se o usuário não ativar sua conta.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Especifique o tipo de gateway, um título e um resumo que descreva o gateway.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Especifique a visibilidade do API Gateway para a organização do provedor do API Connect.`,
  [`Specify the way you want to validate the message.`]: `Especifique a maneira como você deseja validar a mensagem.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Especifique a URL do terminal de gerenciamento de gateway e o certificado para o domínio do terminal.`,
  [`Specify what content to log in the event of an error.`]: `Especifique qual conteúdo registrar no caso de um erro.`,
  [`Specify what content to log.`]: `Especifique qual conteúdo registrar.`,
  [`Specifying the publish target details`]: `Especificando os detalhes de destino da publicação`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Permitir especificamente a regra xsi:type=SOAP-ENC:Array`,
  [`Stage`]: `Montar`,
  [`Stage API`]: `Montar API`,
  [`Stage product`]: `Montar produto`,
  [`Stage to`]: `Montar para`,
  [`Staged`]: `Montado`,
  [`Stage|permission`]: `Montar`,
  [`Standard OIDC`]: `OIDC padrão`,
  [`Static DNS`]: `DNS estático`,
  [`Static group`]: `Grupo estático`,
  [`Status`]: `Status`,
  [`Status code`]: `Código do status`,
  [`Step 1`]: `Etapa 1`,
  [`Step 1: Download`]: `Etapa 1: fazer download`,
  [`Step 1: Download toolkit`]: `Etapa 1: fazer download do kit de ferramentas`,
  [`Step 1: Upload private key`]: `Etapa 1: faça o upload da chave privada`,
  [`Step 2`]: `Etapa 2`,
  [`Step 2: Download credentials`]: `Etapa 2: fazer download das credenciais`,
  [`Step 2: Download toolkit credentials`]: `Etapa 2: fazer download das credenciais do kit de ferramentas`,
  [`Step 2: Install`]: `Etapa 2: instalar`,
  [`Step 2: Upload public key`]: `Etapa 2: faça o upload da chave pública`,
  [`Step 3`]: `Etapa 3`,
  [`Step 3: Configure`]: `Etapa 3: configurar`,
  [`Step 3: Run command`]: `Etapa 3: executar comando`,
  [`Step 3: Run commands`]: `Etapa 3: executar comandos`,
  [`Step 4: Register`]: `Etapa 4: registrar`,
  [`Stop`]: `Parar`,
  [`Stop after:`]: `Parar depois de:`,
  [`Stop all the retries`]: `Parar todas as novas tentativas`,
  [`Stop Connection`]: `Parar a conexão`,
  [`Stop on error`]: `Parar em caso de erro`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Pare a execução dessa API e remova-a do catálogo do Ambiente de Simulação.`,
  [`Stopped`]: `Parado`,
  [`Stopping Published API`]: `Parando a API publicada`,
  [`Strict`]: `Severo`,
  [`Strict SOAP envelope version`]: `Versão de envelope SOAP estrita`,
  [`String Badgerfish`]: `Sequência do Badgerfish`,
  [`String`]: `Sequência`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `A sequência incluída no segmento de organização da URL para chamadas de API. Use caracteres alfabéticos minúsculos, números e hifens.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Integração de Stripe`,
  [`Streaming rule`]: `Regra de fluxo`,
  [`Subject`]: `Assunto`,
  [`Subject Claim`]: `Solicitação do assunto`,
  [`Subject Origin`]: `Origem do assunto`,
  [`Subject common name`]: `Nome comum do assunto`,
  [`Subject|certificate`]: `Assunto`,
  [`Submit`]: `Submeter`,
  [`Submitted`]: `Enviado`,
  [`Subscribability`]: `Capacidade de assinatura`,
  [`Subscribability must be disabled if visibility is disabled`]: `Ao desativar a visibilidade, deve-se desativar também a capacidade de assinatura`,
  [`Subscribability options`]: `Opções de capacidade de assinatura`,
  [`Subscribable by`]: `Permite assinatura por`,
  [`Subscribe`]: `Assinar`,
  [`Subscribe Financial Management App`]: `Assinar aplicativo de Gerenciamento Financeiro`,
  [`Subscribe TODO App`]: `Assinar aplicativo TODO`,
  [`Subscription`]: `Assinatura`,
  [`Subscription approval task for subscription of`]: `Tarefa de aprovação de assinatura para a assinatura de`,
  [`Subscription created`]: `Assinatura Criada`,
  [`Subscription has been added`]: `A assinatura foi incluída`,
  [`Subscription has not been added`]: `A assinatura não foi incluída`,
  [`Subscription pending approval`]: `Aprovação pendente de assinatura`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `O tipo de assinatura foi removido pois as Assinaturas do GraphQL não são suportadas.`,
  [`Subscription-Approval`]: `Aprovação de assinatura`,
  [`Subscriptions`]: `Assinaturas`,
  [`Success`]: `Sucesso`,
  [`Suffix`]: `Sufixo`,
  [`Suggestion`]: `Sugestão`,
  [`Supersede`]: `Suplantar`,
  [`Supersede Product`]: `Suplantar Produto`,
  [`Supersede product`]: `Suplantar produto`,
  [`Supersede plans`]: `Planos de suplantação`,
  [`Supersede {product1} with {product2}`]: `Substituir {product1} por {product2}`,
  [`Supersede|permission`]: `Suplantar`,
  [`Supersede|supersede with`]: `Suplantar`,
  [`Support`]: `Suporte`,
  [`support default introspection`]: `suportar a introspecção padrão`,
  [`Support Server Name Indication (SNI)`]: `Suporte do Server Name Indication (SNI)`,
  [`Support hybrid response types`]: `Suportar tipos de resposta híbridos`,
  [`Support standard introspection`]: `Suportar a introspecção padrão`,
  [`Supported OAuth components`]: `Componentes OAuth suportados`,
  [`Supported client types`]: `Tipos de clientes suportados`,
  [`Supported client types can not be empty.`]: `O tipo de cliente suportado não pode estar vazio.`,
  [`Supported grant type can not be empty.`]: `O tipo de concessão suportada não pode estar vazio.`,
  [`Supported grant types`]: `Tipos de concessão suportados`,
  [`Suspend`]: `Suspender`,
  [`Suspend API`]: `Suspender API`,
  [`Suspend Application`]: `Suspender Aplicativo`,
  [`Switch`]: `Alternar`,
  [`Switch account`]: `Alternar conta`,
  [`Switch cloud connection`]: `Alternar conexão em nuvem`,
  [`Switch directory`]: `Alternar diretório`,
  [`TARGET`]: `DESTINO`,
  [`Title`]: `Título`,
  [`TlS`]: `TLS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `Cliente TLS`,
  [`TLS Client Profile`]: `Perfil do Cliente TLS`,
  [`TLS Client Profile has been configured.`]: `O perfil do cliente do TLS foi configurado.`,
  [`TLS client profile is required when endpoint starts with https://`]: `O perfil do cliente TLS é necessário quando o terminal começa com https://`,
  [`TLS Client Profiles`]: `Perfis do cliente TLS`,
  [`TLS client profile has been configured.`]: `O perfil do cliente TLS foi configurado.`,
  [`TLS client profiles`]: `Perfis do cliente TLS`,
  [`TLS Profile`]: `Perfil TLS`,
  [`TLS SERVER PROFILE`]: `PERFIL DO SERVIDOR TLS`,
  [`TLS server profile`]: `Perfil do Servidor TLS`,
  [`TLS version 1.0`]: `TLS versão 1.0`,
  [`TLS version 1.1`]: `TLS versão 1.1`,
  [`TLS version 1.2`]: `TLS versão 1.2`,
  [`TLS version 1.3`]: `TLS versão 1.3`,
  [`TLS client profile`]: `Perfil do cliente TLS`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `Os perfis do cliente TLS são gerenciados em cada espaço quando os espaços são ativados. Acesse as configurações de espaço para gerenciar os perfis de cliente TLS.`,
  [`TLS profile`]: `Perfil TLS`,
  [`TLS profile is not visible or not available.`]: `O perfil TLS não está visível ou disponível.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `Perfil TLS para usar para a transmissão segura de dados para a URL de autenticação.`,
  [`Tabs for code language options`]: `Guias para opções de linguagem de código`,
  [`Truststore`]: `Armazenamento confiável`,
  [`truststore`]: `armazenamento de confiança`,
  [`Type`]: `Tipo`,
  [`Type name`]: `Nome tipo`,
  [`Type/Field`]: `Tipo/campo`,
  [`Type weight`]: `Peso do tipo`,
  [`Types per page:`]: `Tipos por página:`,
  [`Tags`]: `Tags`,
  [`Tags and external documentation`]: `Tags e documentação externa`,
  [`Take offline`]: `Colocar off-line`,
  [`Target`]: `Destino`,
  [`Target API (URL)`]: `API de destino (URL)`,
  [`Target SOAP service`]: `Serviço SOAP de destino`,
  [`Target Services`]: `Serviços de destino`,
  [`Target service URL`]: `URL do serviço de destino`,
  [`Target services`]: `Serviços de Destino`,
  [`Target URL`]: `URL de Destino`,
  [`Target application`]: `Aplicativo de destino`,
  [`Target catalog`]: `Catálogo de destino`,
  [`Target gateway service`]: `Serviço de gateway de destino`,
  [`Target gateway services`]: `Serviços de gateway de destino`,
  [`Target plan`]: `Plano de destino`,
  [`Target product`]: `Produto de destino`,
  [`Target product rate limit`]: `Limite de taxa do produto de destino`,
  [`Target product subscribability`]: `Capacidade de assinatura do produto alvo`,
  [`Target product visibility`]: `Visibilidade do produto alvo`,
  [`Target schema`]: `Esquema de destino`,
  [`Target services have been updated.`]: `Os serviços de destino foram atualizados.`,
  [`Task`]: `Tarefa`,
  [`Task has been updated`]: `A tarefa foi atualizada`,
  [`Task self approval`]: `Autoaprovação de tarefas`,
  [`Task self-approval`]: `Aprovação automática de tarefa`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Tarefas`,
  [`Tasks for creating new provider organizations`]: `Tarefas para criação de novas organizações do provedor`,
  [`TCP`]: `TCP`,
  [`Template`]: `Modelo`,
  [`Template: {title}`]: `Modelo: {title}`,
  [`Template: account approved`]: `Modelo: conta aprovada`,
  [`Template: account denied`]: `Modelo: conta negada`,
  [`Template: account pending approval`]: `Modelo: conta pendente de aprovação`,
  [`Template: app lifecycle approved`]: `Modelo: ciclo de vida do app aprovado`,
  [`Template: app lifecycle denied`]: `Modelo: ciclo de vida do app negado`,
  [`Template: app lifecycle pending`]: `Modelo: ciclo de vida do app pendente`,
  [`Template: app reinstated`]: `Modelo: app restabelecido`,
  [`Template: app suspended`]: `Modelo: app suspenso`,
  [`Template: app lifecycle cancelled`]: `Modelo: ciclo de vida do app cancelado`,
  [`Template: app lifecycle request`]: `Modelo: solicitação de ciclo de vida do app`,
  [`Template: invitation`]: `Modelo: convite`,
  [`Template: mail server test connection`]: `Modelo: conexão de teste do servidor de correio`,
  [`Template: member invitation`]: `Modelo: convite de membro`,
  [`Template: password changed`]: `Modelo: senha alterada`,
  [`Template: password reset`]: `Modelo: reconfiguração de senha`,
  [`Template: sign up`]: `Modelo: inscrição`,
  [`Template: task consumer onboard request`]: `Modelo: solicitação de integração do consumidor da tarefa`,
  [`Template: task product lifecycle approved`]: `Modelo: ciclo de vida do produto de tarefa aprovado`,
  [`Template: task product lifecycle cancelled`]: `Modelo: ciclo de vida do produto de tarefa cancelado`,
  [`Template: task product lifecycle denied`]: `Modelo: ciclo de vida do produto de tarefa negado`,
  [`Template: task product lifecycle pending`]: `Modelo: ciclo de vida do produto de tarefa pendente`,
  [`Template: task product lifecycle request`]: `Modelo: solicitação de ciclo de vida do produto de tarefa`,
  [`Template: task subscription cancelled`]: `Modelo: assinatura de tarefa cancelada`,
  [`Template: task subscription request`]: `Modelo: solicitação de assinatura de tarefa`,
  [`Template: task subscription approved`]: `Modelo: assinatura de tarefa aprovada`,
  [`Template: task subscription denied`]: `Modelo: assinatura de tarefa negada`,
  [`Template: task subscription pending`]: `Modelo: assinatura de tarefa pendente`,
  [`Temporarily disable subscribability`]: `Desativar capacidade de assinatura temporariamente`,
  [`Temporarily disable visibility`]: `Desativar visibilidade temporariamente`,
  [`Terms of service`]: `Termos de serviço`,
  [`Test`]: `Testar`,
  [`Test API`]: `Testar API`,
  [`Test APIs`]: `Testar APIs`,
  [`Test Bind & Get Base DN`]: `Testar ligação e Obter DN base`,
  [`Test configuration`]: `Testar configuração`,
  [`Test connection`]: `Conexão de Teste`,
  [`Test connection failed.`]: `A conexão de teste falhou.`,
  [`Test and production endpoints`]: `Terminais de teste e de produção`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Erro de resposta de teste e monitoramento: a propriedade redirect_uri é necessária, no entanto, {redirectUri} foi obtido`,
  [`Test application`]: `Aplicativo de teste`,
  [`Test consumer org`]: `Organização do consumidor de teste`,
  [`Test email`]: `E-mail de teste`,
  [`Test endpoints`]: `Terminais de teste`,
  [`Test message from IBM API Connect`]: `Mensagem de teste do IBM API Connect`,
  [`Testable`]: `Testável`,
  [`TEXT`]: `TEXTO`,
  [`The "Definition" property must have a valid value.`]: `A propriedade "Definição" deve possuir um valor válido.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `A propriedade "Definição" deve possuir um valor de "request" ou "response" ou começar com "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `A CLI do API Connect é oferecida como um plug-in da CLI do IBM Cloud. Para começar:`,
  [`The API has unsaved changes.`]: `A API tem mudanças não salvas.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `A API foi totalmente projetada e passou por um marco interno, mas ainda não foi implementada.`,
  [`The API is in the implementation phase.`]: `A API está na fase de implementação.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `A API está na fase conceitual inicial e não foi totalmente projetada ou implementada.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `A API pode ter mudanças não publicadas. Gostaria de publicar novamente o produto?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `O esquema DNS determina a composição das URLs de terminal para as chamadas de API recebidas pelo serviço de gateway e para acessar o portal do desenvolvedor.`,
  [`The GatewayScript source code to execute.`]: `O código-fonte GatewayScript a ser executado.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `A URL do esquema do GraphQL com a qual uma carga útil do GraphQL deve ser validada.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `O método de HTTP a ser usado para a chamada. Se omitido ou configurado para "Manter", o método da solicitação recebida será usado.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `O método de HTTP a ser usado para substituir o método de HTTP atual. Se omitido ou configurado para "Manter", o método da solicitação recebida será usado.`,
  [`The JavaScript source code to execute.`]: `O código-fonte JavaScript a ser executado.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `O Lightweight Directory Access Protocol (LDAP) é um protocolo de Internet para acessar e manter serviços de informações de diretório distribuído em uma rede. Se possuir um serviço LDAP ativado, configure-o como um recurso para fornecer autenticação do usuário.`,
  [`The Proxy URL to be used.`]: `A URL de proxy a ser usada.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `O Perfil SSL para usar para a transmissão segura de dados.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `O Perfil TLS a ser usado para a transmissão segura de dados.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `A URL do esquema JSON para validar uma carga útil de JSON.`,
  [`The URL of the XML schema for validating an XML payload.`]: `A URL do esquema XML para validar uma carga útil de XML.`,
  [`The URL to be invoked.`]: `A URL a ser chamada.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `A URL do esquema WSDL com a qual uma carga útil de SOAP deve ser validada.`,
  [`The XML element name to be used for JSON array elements.`]: `O nome do elemento XML a ser usado para elementos de matriz JSON.`,
  [`The XSLT source to execute.`]: `A fonte XSLT para executar.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `O link de ativação pode ser enviado a um usuário para permitir que ele ative sua conta`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `O serviço de Analítica coleta eventos de API do serviço de gateway. Cada gateway possui um serviço de Analítica associado. Um evento de API com um registro de data e hora é registrado quando uma operação de API é chamada.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `O serviço de Analítica coleta eventos de API do serviço de gateway. Cada serviço de gateway pode ter um serviço de Analítica associado.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `A URL de autorização é onde o aplicativo cliente obtém a concessão de autorização. A URL de token é onde o aplicativo cliente troca a concessão de autorização por um token de acesso.  A URL de introspecção é onde o gateway de API valida os tokens de acesso que são emitidos pelo provedor de terceiros.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `O caminho base é o segmento de URL inicial da API e não inclui o nome do host ou quaisquer segmentos adicionais para caminhos ou operações`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `O tipo de cache determina se os documentos serão armazenados em cache, considerando ou substituindo as diretivas de Controle de Cache HTTP recebidas da resposta do Servidor. Essa propriedade entra em vigor apenas quando uma resposta é recebida do Servidor; caso contrário, a política sempre retornará a resposta não expirada que foi salva anteriormente no cache.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `O serviço Certificate Manager deve gerenciar os certificados para o domínio do terminal de gerenciamento de gateway e os domínios manipulados pelo gateway . Além disso, deve-se usar o IBM Cloud IAM para autorizar o serviço API Connect Reserved Instance para acessar o serviço Certificate Manager.`,
  [`The certificate with which to decrypt the buffer`]: `O certificado com o qual decriptografar o buffer`,
  [`The certificate with which to encrypt the buffer`]: `O certificado com o qual criptografar o buffer`,
  [`The change is critical`]: `A mudança é crítica`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Os aplicativos cliente podem solicitar somente os escopos ou um subconjunto dos escopos definidos aqui. Os escopos são incluídos nos tokens de acesso que são gerados a partir do provedor. Quando uma API protegida do OAuth é chamada, o gateway verifica os escopos transmitidos nos tokens de acesso e os compara à lista de escopos permitidos na definição de segurança para determinar se o acesso será concedido. Além disso, é possível aplicar a verificação de escopo avançada. As URLs de verificação de escopo avançadas são chamadas após a autenticação do aplicativo ou após a autenticação do usuário com base nas URLs que estão configuradas. A permissão de escopo final que é concedida pelo token de acesso é o resultado de todas as verificações de escopo.`,
  [`The crypto object to use to decode the claim.`]: `O objeto criptográfico a ser usado para decodificar a solicitação.`,
  [`The crypto object to use to encrypt the message.`]: `O objeto criptográfico a ser usado para criptografar a mensagem.`,
  [`The crypto object to use to sign the JWT.`]: `O objeto criptográfico a ser usado para assinar o JWT.`,
  [`The crypto object to use to verify the signature.`]: `O objeto criptográfico a ser usado para verificar a assinatura.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `O mapeamento atual contém referências a elementos do esquema não reconhecidos.`,
  [`The current organization does not contain any catalogs.`]: `A organização atual não contém catálogos.`,
  [`The default User registry must be included in the list`]: `O registro do Usuário padrão deve ser incluído na lista`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `A definição com relação à qual a carga útil deve ser validada ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `O portal do desenvolvedor fornece a visualização do consumidor dos produtos de API publicados. Após a configuração do portal do desenvolvedor para o catálogo e da publicação de um ou mais produtos de API, os desenvolvedores de aplicativos podem procurar e usar as APIs.`,
  [`The document is missing draft_api.info.version field.`]: `O documento não possui o campo draft_api.info.version.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `O documento não possui o campo draft_api.info.x-ibm-name.`,
  [`The document is missing draft_product.info.name field.`]: `O documento não possui o campo draft_product.info.name.`,
  [`The document is missing draft_product.info.version field.`]: `O documento não possui o campo draft_product.info.version.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `As restrições adicionais especificam um argumento de fatiamento que não é um argumento neste campo.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Este campo tem um argumento limite com um valor padrão. Portanto, o tamanho assumido que foi especificado nunca poderá ser aplicado.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Este campo tem um argumento de limite com um valor padrão ou é necessário. Portanto, o tamanho assumido que foi especificado nunca poderá ser aplicado.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Este campo não tem nem um tamanho assumido nem um argumento de fatiamento.`,
  [`This field returns unbound list of values with composite type.`]: `Este campo retorna uma lista ilimitada de valores com tipo composto.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Este campo retorna lista ilimitada de valores com tipo escalar e peso de tipo != 0,0.`,
  [`This test panel is deprecated and will be removed soon.`]: `Este painel de teste está descontinuado e será removido em breve.`,
  [`This type has no fields or no matching field.`]: `Este tipo não possui campos nem nenhum campo correspondente.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Esta política GatewayScript não deve usar o módulo apim, que é apenas para migrar APIs antigas.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Esta política XSLT não deve usar o módulo apim, que é apenas para migrar APIs antigas.`,
  [`This type will be deleted.`]: `Este tipo será excluído.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `O diagrama a seguir mostra o fluxo de montagem para a API. Para modificar a montagem, use o editor de montagem.`,
  [`The following files are available under the`]: `Os arquivos a seguir estão disponíveis em`,
  [`The following files contain a private key and cannot be uploaded:`]: `Os seguintes arquivos contêm uma chave privada e não podem ser transferidos por upload:`,
  [`The following is available under the`]: `Os itens a seguir estão disponíveis em`,
  [`The following lifecycle actions require approval`]: `As ações do ciclo de vida a seguir requerem aprovação`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `A lista a seguir exibe as APIs de rascunho que foram criadas nesta organização do provedor`,
  [`The following list displays the draft products that have been created in this provider organization`]: `A lista a seguir exibe os produtos de rascunho que foram criados nesta organização do provedor`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `A lista a seguir mostra todos os usuários que foram incluídos como membros deste catálogo e suas funções designadas`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `A lista a seguir mostra todos os usuários que foram incluídos como membros deste espaço, e suas funções designadas`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `Os parâmetros a seguir não correspondem aos valores configurados no provedor do OAuth`,
  [`The following roles are available in the Cloud Manager`]: `As funções a seguir estão disponíveis no Cloud Manager`,
  [`The following types/fields will also be removed:`]: `Os tipos/campos a seguir também serão removidos:`,
  [`The following types/fields will be affected:`]: `Os tipos/campos a seguir serão afetados:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `O valor da propriedade de gateway {gatewayType} não é permitido. Os valores permitidos são datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `O host utilizado para chamar a API`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `O Armazenamento de chaves contém pares correspondentes de certificados públicos e chaves privadas usados para confirmar a identidade e a transmissão de dados criptografados/não criptografados por HTTPS.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `O Armazenamento de chaves contém pares correspondentes de certificados públicos e chaves privadas usados para confirmar a identidade e a transmissão de dados criptografados/não criptografados por HTTPS. O Perfil do Servidor requer um Armazenamento de chaves.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `O Armazenamento de chaves contém as chaves públicas e os certificados privados apresentados pelo servidor ou cliente para confirmar sua identidade.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `A duração (em segundos) que é incluída na data e hora atuais em que a chave LTPA é considerada válida.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `A duração (em segundos) que é incluída na data e hora atuais em que o JWT é considerado válido.`,
  [`The message to be sent with the error`]: `A mensagem a ser enviada com o erro`,
  [`The message to be included within exception.`]: `A mensagem a ser incluída dentro da exceção.`,
  [`The HTTP status code to be included within exception.`]: `O código de status de HTTP a ser incluído dentro da exceção.`,
  [`The HTTP status reason phrase to be included within exception.`]: `A frase de razão de status de HTTP a ser incluída dentro da exceção.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `O nome de um limite de distribuição definido na configuração de datapower.`,
  [`The name of a count limit defined in the datapower configuration.`]: `O nome de um limite de contagem definido na configuração de datapower.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `O nome de um limite de taxa definido na configuração de datapower.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `O nome de uma variável que será usada para armazenar os dados de resposta da solicitação. Ele poderá ser referenciado em outras ações, como "Mapear".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `O nome de uma variável que será usada para armazenar o resultado. Por padrão, "message" será usado.`,
  [`The name of parameter to find the ID.`]: `O nome do parâmetro para localizar o ID.`,
  [`The name of parameter to find the secret.`]: `O nome do parâmetro para localizar o segredo.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `O nome da chave LTPA que deseja usar para gerar o token LTPA. Se desejar que uma versão específica da chave seja usada, especifique <keyname>:<version>. Por exemplo, my-ltpa-key:1.0.1. Se desejar que a política selecione automaticamente a versão mais recente da chave a ser usada, especifique <keyname>:latest. Por exemplo, my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `O nome do erro a ser lançado`,
  [`The name of the variable to be added.`]: `O nome da variável a ser incluída.`,
  [`The name of the variable to be cleared.`]: `O nome da variável a ser limpa.`,
  [`The name of the variable to be set.`]: `O nome da variável a ser configurada.`,
  [`The nature of the XML validation to be performed.`]: `A natureza da validação de XML a ser executada.`,
  [`The operation to apply.`]: `A operação a ser aplicada`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `A saída desse mapeamento está dentro de uma matriz e seu conteúdo será criado por meio de iteração sobre a entrada escolhida. Se a entrada escolhida não for uma matriz, uma matriz de comprimento igual a 1 será produzida.`,
  [`The owner must be an existing user in your user registry`]: `O proprietário deve ser um usuário existente em seu registro do usuário`,
  [`The owner of consumer organization has the following privileges:`]: `O proprietário da organização do consumidor tem os seguintes privilégios:`,
  [`The owner of provider organization has the following privileges:`]: `O proprietário da organização do provedor tem os seguintes privilégios:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Os deveres do proprietário incluem: gerenciar o ciclo de vida do produto da API, gerenciar uma ou mais organizações de provedores de API, gerenciar comunidades de provedores de aplicativos e criar APIs e definições de produtos.`,
  [`The page you are looking for might have been moved or does not exist.`]: `A página que você está procurando pode ter sido movida ou não existe.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `A página que você está procurando pode ter sido movida ou não existe. Entre em contato com o administrador para obter suporte.`,
  [`The parameters needed to send a valid API call.`]: `Os parâmetros necessários para enviar uma chamada de API válida.`,
  [`The password to use for HTTP Basic authentication.`]: `A senha a ser usada para autenticação básica HTTP.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `O serviço de portal fornece um portal do desenvolvedor usado por desenvolvedores de aplicativos para descobrir as APIs e os consumidores integrados.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `O serviço de portal fornece um portal do desenvolvedor usado por desenvolvedores de aplicativos para descobrir e implementar APIs.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `O status de processamento fornece uma visualização do funcionamento de um gateway com um resumo da atividade recente`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `O destinatário é registrado como um proprietário e cria uma nova organização do provedor. Depois de registrado, o usuário pode efetuar login no API Manager como o proprietário da organização do provedor.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `O nome do elemento raiz do documento XML resultante. Isso será necessário apenas se o documento JSON de entrada não for hierárquico e tiver mais de uma propriedade de nível superior.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Aqui os escopos servem para que os desenvolvedores entendam o que têm permissão para acessar e não afetam a verificação do escopo.`,
  [`The selected catalog will only be used to search consumer organizations`]: `O catálogo selecionado será usado apenas para procurar organizações de consumidores`,
  [`The selected catalog does not have any configured gateways`]: `O catálogo selecionado não possui nenhum gateway configurado`,
  [`The selected catalog does not have any compatible gateways`]: `O catálogo selecionado não possui nenhum gateway compatível`,
  [`Save Preferences`]: `Salvar preferências`,
  [`Preferences`]: `Preferências`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `O catálogo selecionado será usado apenas para procurar organizações e grupos de consumidores '`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `O tipo de gateway selecionado renderizará as políticas a seguir em seu conjunto como inválidas. Será necessário excluir essas políticas antes de poder executar esta API.`,
  [`The selected product does not contain this API`]: `O produto selecionado não contém essa API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `O produto selecionado será assinável para uma lista específica de organizações ou grupos de consumidores.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `Os serviços selecionados estarão disponíveis para uma lista específica de organizações do provedor.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `Os serviços selecionados estarão disponíveis para todas organizações do provedor, existentes e novas.`,
  [`The selected services will not be available to any provider organizations.`]: `Os serviços selecionados não estarão disponíveis para nenhuma organização do provedor.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `A sequência a ser usada como uma chave para o cache. Se omitida, a sequência URL inteira será usada como a chave.`,
  [`The server responded with a non-JSON body \n{body}`]: `O servidor respondeu com um corpo não JSON \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `O intervalo de tempo precisa ser maior do que o tempo de vida do token de acesso.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `O token não será armazenado em um cookie ou em um armazenamento local. Se você fechar o painel de teste, o token será perdido, mas ainda poderá ser válido.`,
  [`The transfer protocol of the API.`]: `O protocolo de transferência da API.`,
  [`The type of authentication.`]: `O tipo de autenticação.`,
  [`The type of authorization header.`]: `O tipo de cabeçalho de autorização.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `O tipo do valor a ser configurado. Isso pode ser qualquer um, sequência, número ou booleano.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `O tipo do valor a ser configurado. Ele pode ser sequência, número ou booleano.`,
  [`The username to use for HTTP Basic authentication.`]: `O nome de usuário a ser usado para autenticação básica HTTP.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `O usuário desta conexão não é mais válido. Crie uma nova conexão.`,
  [`The value that the variable will be set to.`]: `O valor para o qual a variável será configurada.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `O valor deste campo é uma lista do tipo composto, que não tem nem um tamanho assumido nem um argumento de fatiamento. Se ele for encontrado, o API Gateway não poderá configurar um limite no número de objetos que o servidor GraphQL poderá retornar se ele receber a consulta.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `O valor deste campo é uma lista do tipo escalar, que não tem nem um tamanho assumido nem argumentos de fatiamento. Se ele for encontrado, o API Gateway não poderá configurar um limite no número de objetos que o servidor GraphQL poderá retornar se ele receber a consulta.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `A versão precisa ser uma url segura. A versão {version} do objeto e a versão slugified {slugVersion} não correspondem.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `A propriedade "name" deve ser segura URL (o valor do nome do objeto "{name}" e o valor do nome colocado em slug "{slugName}" deve corresponder).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `A propriedade 'draft_api' deve conter uma definição da OpenAPI. O OpenAPI versão 3 suporta apenas as versões 3.0.0 a 3.0.3 (versão especificada {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `O campo de nome é preenchido automaticamente com base no título fornecido. Use o valor resultante nos comandos e nas chamadas de API do API Connect.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `A configuração de visibilidade determina quais organizações do provedor podem acessar um recurso. As opções são: Público (o recurso pode ser usado por todas as organizações do provedor), Customizado (o recurso pode ser usado somente por organizações do provedor designadas por você) e Privado (o recurso não está visível e não pode ser usado por nenhuma organização do provedor).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `A configuração de visibilidade determina quais organizações do provedor podem usar um serviço. As opções são: Público (o serviço pode ser usado por todas as organizações do provedor), Customizado (o serviço pode ser usado somente por organizações do provedor designadas por você) e Privado (o serviço não está visível e não pode ser usado por nenhuma organização do provedor).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `A configuração de visibilidade determina quais organizações do provedor podem usar um gateway. Os níveis de visibilidade incluem: Público (o gateway pode ser usado por todas as organizações do provedor), Customizado (o gateway pode ser usado somente por organizações do provedor designadas por você) e Privado (o gateway não está visível e não pode ser usado por nenhuma organização do provedor)`,
  [`The {title} role`]: `A {title} função`,
  [`The {url} resource does not exist`]: `O recurso {url} não existe`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Há APIs incompatíveis devido a elas terem um tipo de gateway diferente para esse produto.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Em seguida, selecione o certificado para o domínio do terminal de gerenciamento. Selecione também o pacote configurável de CA correspondente se o certificado não tiver sido designado por uma autoridade de certificação conhecida. O certificado e o pacote configurável devem ser gerenciados pelo serviço Certificate Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Em seguida, especifique os domínios que serão manipulados por esse gateway. A técnica SNI é usada. Como resultado, diversos domínios podem ser entregues por meio do mesmo endereço IP e porta sem a necessidade do mesmo certificado. O formato de curinga é suportado. O domínio padrão (catch-all) de ‘*’ deve ser incluído como a entrada final na tabela. Insira outros nomes de domínio, conforme necessário, especificando em cada caso um certificado gerenciado pelo serviço Certificate Manager.`,
  [`There should be at least {minSelected} {label} selected.`]: `Deve haver pelo menos {minSelected} {label} selecionado.`,
  [`There are no API changes to publish.`]: `Não há mudanças na API a serem publicadas.`,
  [`There are no gateway services`]: `Não há serviços de gateway`,
  [`There are no assemblies that match the chosen filters.`]: `Não há conjuntos que correspondam aos filtros escolhidos.`,
  [`There are no burst limits yet.`]: `Não há limites de distribuição ainda.`,
  [`There are no catalogs`]: `Não há catálogos`,
  [`There are no consumer organizations.`]: `Não há organizações de consumidores.`,
  [`There are no consumer organizations. You can create one`]: `Não há organizações de consumidores. É possível criar um`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `Não há organizações de consumidores. É possível criar um [aqui]({link})`,
  [`There are no email servers`]: `Não há servidores de e-mail`,
  [`There are no gateway services configured for the sandbox catalog`]: `Não há serviços de gateway configurados para o catálogo do ambiente de simulação`,
  [`There are no lifecycle approvals enabled`]: `Não há aprovações de ciclo de vida ativadas`,
  [`There are no new changes.`]: `Não há novas mudanças.`,
  [`There are no products to display`]: `Não há nenhum produto para exibir`,
  [`There are no products to replace`]: `Não há produtos para substituir`,
  [`There are no products to set migration target`]: `Não há nenhum produto para configurar o destino de migração`,
  [`There are no products to supersede`]: `Não há produtos para suplantar`,
  [`There are no properties to configure for this policy`]: `Não há propriedades a configurar para essa política`,
  [`There are no rate limits yet.`]: `Não há limites de taxa ainda.`,
  [`There are no security definitions`]: `Não há definições de segurança`,
  [`There are no spaces`]: `Não há espaços`,
  [`There are no target services`]: `Não há serviços de destino`,
  [`There are no tasks to be displayed`]: `Não há tarefas a serem exibidas`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Há alterações não salvas. Tem certeza que deseja continuar?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Há alterações não salvas. Elas serão descartadas se você continuar. Tem certeza?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `Há uma API no yaml que não existe e não pode ser listada, verifique a guia de origem.`,
  [`There is no item in the list.`]: `Não há nenhum item na lista.`,
  [`There is no space`]: `Não há espaço`,
  [`There is no warning in the schema.`]: `Não há nenhum aviso no esquema.`,
  [`There was no data found to populate the data table.`]: `Nenhum dado foi localizado para preencher a tabela de dados.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Esses terminais são usados para todas as APIs no catálogo`,
  [`Third party OAuth provider`]: `Provedor OAuth de terceiro`,
  [`Third party OAuth provider summary`]: `Resumo do provedor OAuth de terceiros`,
  [`Third party`]: `Terceiros`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Esta API ainda não contêm um conjunto. Deseja criar um?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Este recurso não existe mais; atualize os resultados do servidor.`,
  [`Please refresh the results from the server`]: `Atualize os resultados do servidor`,
  [`Exact match`]: `Correspondência exata`,
  [`Closest match`]: `Correspondência mais próxima`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Esta API está atualmente sendo usada pelo produto {productName}. Exclua-a do produto, em seguida, prossiga para excluir a API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Esta API estará disponível para ser chamada quando a opção a seguir estiver ativada.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Este catálogo não tem uma assinatura automática ativada. Para testar neste catálogo, selecione um produto, plano e aplicativo abaixo.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Este terminal é usado como o padrão para APIs que não definem um terminal de base`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Este é um recurso de visualização técnica que será desenvolvido e continuará a ser melhorado no futuro.`,
  [`This is the currently configured notification server.`]: `Este é o servidor de notificação atualmente configurado.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Este é o diretório onde os arquivos que incluem políticas e extensões serão armazenados.`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `Este é o local onde as APIs serão executadas e as políticas e extensões podem ser acessadas`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Essa política do OAuth executa todas as etapas de protocolo do OAuth que são necessárias para o caminho emitido por padrão. As entradas e saídas de cada uma das etapas são orientadas por variáveis de contexto documentadas. Inclua ou remova os Componentes do OAuth suportados conforme necessário.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Essa política do OAuth executa todas as etapas de protocolo do OAuth/OpenID Connect que são necessárias para o OAuth Validation por padrão. As entradas e saídas de cada uma das etapas são orientadas por variáveis de contexto documentadas. Inclua ou remova os Componentes do OAuth suportados conforme necessário.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Essa política do oauth executa todas as etapas de protocolo do OAuth/OpenID Connect que são necessárias para todos os outros caminhos por padrão. As entradas e saídas de cada uma das etapas são orientadas por variáveis de contexto documentadas. Inclua ou remova os Componentes do OAuth suportados conforme necessário.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Essa política do oauth executa todas as etapas de protocolo do OAuth/OpenID Connect que são necessárias para o caminho de código az por padrão. As entradas e saídas de cada uma das etapas são orientadas por variáveis de contexto documentadas. Inclua ou remova os Componentes do OAuth suportados conforme necessário.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Essa política do oauth executa todas as etapas de protocolo do OAuth/OpenID Connect que são necessárias para o caminho do token por padrão. As entradas e saídas de cada uma das etapas são orientadas por variáveis de contexto documentadas. Inclua ou remova os Componentes do OAuth suportados conforme necessário.`,
  [`This operation is secured with access code flow OAuth`]: `Esta operação está assegurada com OAuth de fluxo de código de acesso`,
  [`This operation is secured with application flow OAuth`]: `Esta operação está assegurada com OAuth de fluxo de aplicativo`,
  [`This operation is secured with basic authentication`]: `Esta operação está assegurada com autenticação básica`,
  [`This operation is secured with implicit flow OAuth`]: `Esta operação está assegurada com OAuth de fluxo implícito`,
  [`This operation is secured with password flow OAuth`]: `Esta operação está assegurada com OAuth de fluxo de senha`,
  [`This policy is not available on the selected gateway`]: `Essa política não está disponível no gateway selecionado`,
  [`This policy is used by one other operation`]: `Esta política é usada por uma outra operação`,
  [`This policy is used by {{count}} other operations`]: `Esta política é usada por {{count}} outras operações`,
  [`This role cannot be edited`]: `Essa função não pode ser editada`,
  [`This role cannot be edited.`]: `Esta função não pode ser editada.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Essa configuração controlará o comportamento se um mapeamento falhar devido à ausência de sua entrada e da configuração do mapeamento padrão. O comportamento padrão é não mudar a saída, mas se você marcar esta caixa de seleção, um objeto vazio será criado para o pai do mapeamento de destino, emulando o comportamento da versão 4.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Essa política de segurança do usuário executa a verificação de EI (básico) e AU (url de autorização.) para a montagem do oauth. Mude o método de verificação de segurança conforme necessário`,
  [`This will enable/disable application lifecycle`]: `Isso ativará/desativará o ciclo de vida do aplicativo`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Isso ativará/desativará a aprovação automática de tarefa. A aprovação automática de tarefa permite que as tarefas sejam aprovadas pelos seus originadores e colaboradores. Essa opção pode ser conveniente quando outros aprovadores não estão disponíveis, mas efetivamente permite que verificações por outro usuário sejam ignoradas.`,
  [`This will unset the catalog as the default catalog.`]: `Isso irá desconfigurar o catálogo como o catálogo padrão.`,
  [`Throw`]: `Lançar`,
  [`Time`]: `Hora`,
  [`Time Interval`]: `Intervalo de Tempo`,
  [`Time Limit`]: `Limite de Tempo`,
  [`Time to Live`]: `Tempo de Vida`,
  [`Time to live`]: `Tempo de vida`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `O tempo que se deve esperar antes de uma resposta voltar do terminal. O padrão é 60 segundos.`,
  [`Timeout`]: `Tempo limite`,
  [`To`]: `Para`,
  [`to`]: `para`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Para incluir um usuário como um membro da organização do Administrador, selecione o registro do usuário, insira o nome de usuário e selecione as funções que você deseja designar. Se o tipo de registro do usuário for Registro do Usuário Local, também haverá a opção de criar um novo usuário que será, então, incluído como um membro. A associação é ativada imediatamente e o usuário especificado pode efetuar login na interface com o usuário do Cloud Manager`,
  [`To add a user as a member of a provider organization`]: `Para incluir um usuário como um membro de uma organização do provedor`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Para incluir um usuário como membro do Catálogo, selecione o nome do usuário e designe a ele as funções necessárias. É possível incluir na organização do provedor qualquer usuário que já seja membro de outro Catálogo ou Espaço e que atualmente não seja membro desse Catálogo nem proprietário do Catálogo.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Para incluir um usuário como membro do Espaço, selecione o nome do usuário e designe a ele as funções necessárias. É possível incluir na organização do provedor qualquer usuário que já seja membro de outro Catálogo ou Espaço e que atualmente não seja membro desse Espaço nem proprietário do Espaço.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Para incluir ou remover membros, use o serviço [identidade e acesso](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Para incluir membros, use o serviço [identity and access](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Para incluir o usuário como um membro da organização, selecione o registro do usuário, insira seu nome de usuário e designe um conjunto de funções.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Para incluir o usuário como um membro, selecione seu nome de usuário e designe-lhe um conjunto de funções.`,
  [`To assign default gateways, click **Edit**.`]: `Para designar gateways padrão, clique em **Editar**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Para continuar usando este software, deve-se concordar com os termos do contrato de licença de software.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Para continuar usando API Connect, faça o upgrade para um plano Corporativo.`,
  [`To get started, you must first enable spaces.`]: `Para iniciar, deve-se primeiramente ativar espaços.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Para instalar e executar o kit de ferramentas, conclua as etapas a seguir. [Saiba mais]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Para convidar um novo usuário como um membro da organização do administrador, insira o endereço de e-mail ou o nome do usuário ou selecione o nome de um registro do usuário.  A função Membro, que está designada automaticamente para todos os membros, fornece um conjunto básico de permissões e não pode ser excluída. Se não forem necessárias outras permissões, desmarque as outras funções.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Para convidar um proprietário que ainda não esteja em seu registro do usuário, insira seu endereço de e-mail`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Para registrar um novo gateway, visite a página **Gateways**.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Para substituir valores por uma das propriedades de espaço a seguir, digite $() com o nome da propriedade dentro dos parênteses`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Para enviar um convite de e-mail para um novo usuário para que ele seja registrado como um membro da organização do Administrador, insira um endereço de e-mail válido e selecione as funções que você deseja designar ao usuário. Um e-mail contendo um link de ativação é enviado para o endereço de e-mail, convidando a pessoa a se registrar. O usuário é colocado no status Pendente e esse status é mudado para Ativado após a conclusão do formulário de registro, a fim de ativar a conta. Ele pode, então, efetuar login na interface com o usuário do Cloud Manager com o nome de usuário do API Connect especificado durante a ativação da conta.`,
  [`Toggle`]: `Alternador`,
  [`Toggle application lifecycle?`]: `Alternar o ciclo de vida do aplicativo?`,
  [`Toggle default catalog?`]: `Alternar o catálogo padrão?`,
  [`Toggle info panel between right and bottom`]: `Alterne o painel de informações entre a direita e a parte inferior`,
  [`Toggle profile`]: `Alternar perfil`,
  [`Toggle spaces?`]: `Alternar espaços?`,
  [`Toggle task self-approval?`]: `Alternar para a aprovação automática de tarefa?`,
  [`Token`]: `Token`,
  [`Token endpoint`]: `Terminal do token`,
  [`Token Expiry`]: `Expiração do token`,
  [`Token Management`]: `Gerenciamento de Token`,
  [`Token Output`]: `Saída do Token`,
  [`Token URL`]: `URL de token`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `A URL de token deve corresponder a {endpoint}, conforme definido pelo provedor OAuth "{providerTitle}"`,
  [`Token Version`]: `Versão do token`,
  [`Token management`]: `Gerenciamento de Token`,
  [`Token management type`]: `Tipo de gerenciamento de token`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `O gerenciamento de tokens permite evitar ataques de reprodução, gerenciar tokens, incluindo se e como os aplicativos clientes ou proprietários de recursos revogarão os tokens do OAuth. O API Connect suporta o gerenciamento de tokens com um gateway nativo ou um terminal de terceiros. No caso de um gateway nativo, utiliza-se o cumprimento de cota para gerenciar tokens. Ao usar um terminal de terceiros, forneça uma URL para um serviço externo para gerenciar tokens.`,
  [`Token path`]: `Caminho do token`,
  [`Token revocation`]: `Revogação de token`,
  [`Token secret`]: `Segredo do token`,
  [`Token validation`]: `Validação de Tokens`,
  [`Tokens`]: `Tokens`,
  [`Token relay`]: `Retransmissão do token`,
  [`Toolkit credentials`]: `Credenciais do kit de ferramentas`,
  [`Token Relay`]: `Retransmissão do token`,
  [`Topology`]: `Topologia`,
  [`Topology Administrator`]: `Administrador de topologia`,
  [`Total time`]: `Tempo total`,
  [`Transfer Ownership to a New User`]: `Transferir propriedade para um novo usuário`,
  [`Transfer Ownership to an Existing User`]: `Transferir propriedade para um usuário existente`,
  [`Transfer ownership`]: `Transferir propriedade`,
  [`Transfer ownership to`]: `Transferir a propriedade para`,
  [`Transfer ownership to a new user`]: `Transferir propriedade para um novo usuário`,
  [`Transfer ownership to an existing user`]: `Transferir propriedade para um usuário existente`,
  [`Transforms`]: `Transformações`,
  [`Truststore Certificates`]: `Certificados de Armazenamento confiável`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Certificados de armazenamento confiável são certificados públicos emitidos por uma autoridade de certificação.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Armazenamentos confiáveis contêm certificados confiáveis que contêm chaves públicas verificadas. Os certificados no armazenamento confiável são normalmente obtidos de uma autoridade de certificação (CA) terceirizada.`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Os armazenamentos confiáveis contêm certificados confiáveis com chaves públicas verificadas. Os certificados no armazenamento confiável são normalmente obtidos de uma autoridade de certificação (CA) terceirizada.`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Os armazenamentos confiáveis armazenam certificados confiáveis, incluindo chaves públicas verificadas. Os certificados no armazenamento confiável são normalmente obtidos de uma autoridade de certificação (CA) terceirizada.`,
  [`Try again or upload a schema`]: `Tente novamente ou faça upload de um esquema`,
  [`Try refreshing the page or contacting support.`]: `Tente atualizar a página ou entrar em contato com o suporte.`,
  [`Turkish`]: `Turco`,
  [`Tutorials`]: `Tutoriais`,
  [`Types`]: `Tipos`,
  [`Type of user`]: `Tipo de usuário`,
  [`Type or select a catalog`]: `Digite ou selecione um catálogo`,
  [`Type to add organizations`]: `Digite para incluir organizações`,
  [`TypeError: Failed to fetch`]: `TypeError: falha ao buscar`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `URL de terminal de gerenciamento`,
  [`URLs for all operations in the API begin with this value.`]: `As URLs para todas as operações na API começam com esse valor.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `Direitos Restritos para Usuários do Governo dos Estados Unidos - Uso, duplicação e divulgação restritos pelo documento GSA ADP Schedule Contract com a IBM Corporation.`,
  [`User Defined`]: `Def. pelo usuário`,
  [`Userinfo POST`]: `POST de informações do usuário`,
  [`USER MANAGED`]: `USUÁRIO GERENCIADO`,
  [`USERNAME`]: `NOME DO USUÁRIO`,
  [`User can delete the keystore history.`]: `O usuário pode excluir o histórico do keystore.`,
  [`Unable to parse API definition: {msg}`]: `Não é possível analisar a definição de API: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Não é possível continuar devido a erros encontrados ao remover tipos e/ou campos.`,
  [`Unarchive`]: `Restauração de Archive`,
  [`Unassociate`]: `Desassociar`,
  [`Unassociate analytics service`]: `Desassociar o serviço de Analítica`,
  [`Unauthorized`]: `Desautorizado`,
  [`Unauthorized API Request`]: `Solicitação de API desautorizada`,
  [`Unbound lists`]: `Listas desvinculadas`,
  [`Unit`]: `Unidade`,
  [`Union`]: `União`,
  [`Union type`]: `Tipo de União`,
  [`Unique email address`]: `Endereço de e-mail exclusivo`,
  [`Unlimited`]: `Ilimitado`,
  [`Unnecessary assumed size`]: `Tamanho assumido desnecessário`,
  [`Unnecessary slicing arguments`]: `Argumentos de fatiamento desnecessários`,
  [`Unrecognized Schema Element(s) Encountered`]: `Encontrados elementos do esquema não reconhecidos`,
  [`Unsupported media type ({type})`]: `Tipo de mídia não suportado ({type})`,
  [`Untitled`]: `Sem Título`,
  [`Update`]: `Atualizar`,
  [`Update gateway services`]: `Atualizar serviços de gateway`,
  [`Update assembly`]: `Atualizar conjunto`,
  [`Update group failed.`]: `Grupo de atualização com falha.`,
  [`Update schema defaults giving preference to`]: `Atualize os padrões de esquema dando preferência a`,
  [`Update the sender details that are displayed on invitation emails`]: `Atualize os detalhes do remetente que são exibidos em e-mails de convite`,
  [`Update the summary details for this role`]: `Atualize os detalhes de resumo para essa função`,
  [`Updating Draft API`]: `Atualizando a API de rascunho`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `A atualização de padrões de esquema substituirá as suas mudanças. Tem certeza que deseja continuar?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `A atualização da API do tipo {existingType} com a API do tipo {type} não é permitida.`,
  [`Upload`]: `Fazer Upload`,
  [`Upload a schema definition language file to replace your schema.`]: `Faça upload de um arquivo de linguagem de definição de esquema para substituir o seu esquema.`,
  [`Upload GraphQL schema`]: `Fazer upload do esquema GraphQL`,
  [`Upload local`]: `Upload local`,
  [`Upload policies`]: `Fazer upload de políticas`,
  [`Upload policy`]: `Fazer upload de política`,
  [`Upload schema`]: `Fazer upload do esquema`,
  [`Upload schema definition language (SDL) file`]: `Fazer upload de arquivo de linguagem de definição de esquema (SDL)`,
  [`Upload truststore certificates`]: `Fazer upload de certificados de armazenamento confiável`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Faça upload do certificado X509 em seu aplicativo no formato PEM. É possível fazer upload de somente um arquivo PEM, mas é possível incluir diversos certificados em um único arquivo.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Faça upload do certificado X509 para seu aplicativo, em formato PEM`,
  [`Upload your public certificate`]: `Faça upload de seu certificado público`,
  [`Uploaded and validated YAML file`]: `Arquivo YAML carregado e validado`,
  [`Upload extension`]: `Fazer upload de extensão`,
  [`Use all compatible gateway services`]: `Usar todos os serviços de gateway compatíveis`,
  [`Use Content Type`]: `Usar tipo de conteúdo`,
  [`Use DataPower API Gateway`]: `Use o gateway de API do DataPower`,
  [`Use DataPower Gateway`]: `Usar o DataPower Gateway`,
  [`Use Dynamic DNS`]: `Usar DNS Dinâmico`,
  [`Use JSONata to specify the property to redact or remove`]: `Use JSONata para especificar a propriedade a ser editada ou removida`,
  [`Use XPath to specify the property to redact or remove.`]: `Use XPath para especificar a propriedade a ser editada ou removida.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Use um catálogo selecionado como um catálogo padrão no qual você deseja publicar`,
  [`Use an existing JSON or YAML definition of the product`]: `Use uma definição JSON ou YAML existente do produto`,
  [`Use an existing definition of a REST proxy`]: `Use uma definição existente de um proxy REST`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Use uma definição existente de um proxy REST ou de uma API SOAP`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Use uma definição existente de um proxy REST, proxy GraphQL ou API SOAP`,
  [`Use an existing definition of a product`]: `Use uma definição existente de um produto`,
  [`Use context current payload`]: `Usar carga útil atual do contexto`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Usar URL de terminal de catálogo customizado (compatibilidade com v5)`,
  [`Use dynamic DNS`]: `Usar DNS dinâmico`,
  [`Use endpoint from API`]: `Use o terminal da API`,
  [`Use gateway URLs`]: `Usar URLs de gateway`,
  [`Use gateway service configured OAuth shared secret key`]: `Usar chave secreta compartilhada OAuth configurada pelo serviço de gateway`,
  [`Use group authentication`]: `Usar autenticação do grupo`,
  [`Use only first array element`]: `Usar apenas o primeiro elemento de matriz`,
  [`Use static DNS`]: `Usar DNS estático`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Use o portal como terminal para o tráfego do provedor OIDC externo`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Use o API Explorer para testar e explorar suas APIs. O Explorer mostra as operações, as definições e a documentação de todas as APIs contidas no diretório de seu projeto. O Explorer lista os parâmetros, os dados da instância do modelo e os códigos de resposta, juntamente com o código do modelo para executar sua API.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Use o menu Ações para gerenciar e publicar suas APIs.`,
  [`Use the actions menu to manage and publish your products`]: `Use o menu Ações para gerenciar e publicar seus produtos`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Use a política log de atividades para configurar as preferências de criação de log para a sua atividade de API que é armazenada em Analítica. As preferências que você especificar substituirão as configurações padrão para coletar e armazenar detalhes da atividade de API.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Use a política de segurança do cliente para extrair e autenticar as credenciais dos clientes.`,
  [`Use the default built-in Sandbox Catalog`]: `Use o Catálogo de ambiente de simulação integrado padrão`,
  [`Use the editor for manual input or upload a file below.`]: `Use o editor para entrada manual ou faça upload de um arquivo abaixo.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Use o editor para entrada manual ou faça upload de um arquivo abaixo. O esquema atual poderá ser editado apenas em XML se um arquivo XML/XSD for definido.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Use a extensão aplicada para especificar se o gateway do API Connect é usado para aplicar a API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Use a URL de terminal de gerenciamento a seguir ao trabalhar com a CLI.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Use a URL de terminal de gerenciamento a seguir ao criar uma conexão em nuvem no API Designer.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Use a política gatewayscript para executar um programa GatewayScript do DataPower especificado.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Use a política graphql-introspect para introspecção de um esquema do GraphQL.`,
  [`Use the host value from Open API Definition`]: `Use o valor do host da Definição do Open API`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Use a política Se para aplicar uma seção do conjunto quando uma condição é atendida.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Use a política javascript para executar um programa JavaScript especificado.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Use a política json-to-xml para converter o conteúdo de sua carga útil de JSON para XML.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Use a política de log para customizar ou substituir a configuração de criação de log de atividade padrão para uma API.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Use a política do mapa para aplicar transformações em seu fluxo de conjuntos e especificar relacionamentos entre as variáveis.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Use a política de mapa para especificar os relacionamentos entre variáveis dentro de seu fluxo de API e aplicar transformações para essas variáveis.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Use a política OAuth para gerar tokens OAuth.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Use a política de alternância da operação para aplicar uma seção do conjunto em uma operação específica.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Use a política de análise para analisar uma solicitação ou uma mensagem como XML ou JSON ou para analisar dados binários em um objeto binário grande (BLOB).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Use a política set-variable para configurar uma variável de tempo de execução para um valor de sequência, ou para incluir ou limpar uma variável de tempo de execução.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Use a política de lançamento para lançar um erro quando ele é atingido durante a execução de um fluxo de conjuntos.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Use a política de segurança do usuário para extrair, autenticar e autorizar a identidade do usuário.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Use a política de validação com um Gateway DataPower para validar a carga útil em um fluxo de conjuntos com relação a um esquema JSON ou XML.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Use a política xml-to-json para converter o conteúdo de sua carga útil de XML para JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Use a política xslt para aplicar uma transformação XSLT para a carga útil.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Usado para ativar/desativar a compactação de Codificação de conteúdo no upload.`,
  [`User`]: `Usuário`,
  [`User group prefix`]: `Prefixo do grupo de usuários`,
  [`User group suffix`]: `Sufixo do grupo de usuários`,
  [`User mapping`]: `Mapeamento de Usuário`,
  [`User Registries`]: `Registros do usuário`,
  [`User registries`]: `Registros de usuários`,
  [`User Registry Name`]: `Nome do registro do usuário`,
  [`User registry name`]: `Nome do registro do usuário`,
  [`User Registry cannot be deleted`]: `O Registro do Usuário não pode ser excluído`,
  [`User Registry "{name}" has been deleted`]: `O registro do usuário "{name}" foi excluído`,
  [`User security`]: `Segurança do Usuário`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Os registros do usuário são gerenciados em cada espaço, quando há espaços ativados. Acesse as configurações de espaço para gerenciar os registros do usuário.`,
  [`User registries defined for consumer onboarding`]: `Registros do usuário definidos para a integração do consumidor`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Os registros do usuário definidos para a integração do consumidor\n\nTodas as contas no Portal do Desenvolvedor, inclusive em diferentes registros do usuário para o mesmo site, devem ter um endereço de e-mail exclusivo, incluindo a conta Administrador do site.`,
  [`User registry`]: `Registro do usuário`,
  [`User registry configuration has been updated.`]: `A configuração do registro do usuário foi atualizada.`,
  [`User registry has been created`]: `O registro do usuário foi criado`,
  [`User registry {title} deleted.`]: `Registro do usuário {title} excluído.`,
  [`User registry {title} has been created.`]: `O registro do usuário {title} foi criado.`,
  [`User registry is not visible or not available.`]: `O registro do usuário não está visível ou disponível.`,
  [`User registry {title} has been updated.`]: `O registro do usuário {title} foi atualizado.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `As configurações de segurança do usuário serão aplicáveis apenas se os tipos de concessão selecionados incluírem um ou mais dos seguintes: Implícito, Código de acesso e Proprietário do recurso - Senha.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `As configurações de segurança do usuário serão aplicáveis apenas se os tipos de concessão selecionados incluírem um ou mais dos seguintes: Implícito, Código de acesso e Proprietário do recurso - Senha. Se fizer alguma alteração nesta página, você também deverá selecionar, na página Configuração, pelo menos um desses tipos de concessão.`,
  [`UserInfo endpoint`]: `Terminal de informações do usuário`,
  [`UserInfo URL`]: `URL de informações do usuário`,
  [`Username`]: `Nome de usuário`,
  [`Username / Email`]: `Nome/e-mail do usuário`,
  [`Username and password fields are required in order to test configuration`]: `Os campos de nome de usuário e senha são necessários para testar a configuração`,
  [`Username context variable`]: `Variável de contexto de nome de usuário`,
  [`Username of the new user`]: `Nome do novo usuário`,
  [`Username should not contain special characters!`]: `O nome do usuário não deve conter caracteres especiais!`,
  [`Username should not include spaces!`]: `O nome do usuário não deve incluir espaços!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Os usuários pertencentes a uma organização de consumidor possuem acesso ao portal do desenvolvedor e aos produtos de API publicados nele, conforme determinado pela sua visibilidade e capacidade de assinatura.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Usando o [IBM Cloud IAM]({link}), autorize o serviço API Connect Reserved Instance para acessar o serviço Certificate Manager.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Uso do método HTTP POST ao entrar em contato com o terminal de informações do usuário`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Usar a configuração de expiração de token IBM APIC da nuvem`,
  [`Update API`]: `Atualizar API`,
  [`VALUE`]: `VALOR`,
  [`Version`]: `Versão`,
  [`Visible to`]: `Visível para`,
  [`Validate`]: `Validar`,
  [`Validate message body`]: `Validar corpo da mensagem`,
  [`Validate message headers`]: `Validar cabeçalhos da mensagem`,
  [`Validate message fault details`]: `Validar detalhes de falha da mensagem`,
  [`Validate SOAP 1.1 encoding rule`]: `Validar regra de codificação SOAP 1.1`,
  [`Validate JSON Web Token (JWT)`]: `Validar JSON Web Token (JWT)`,
  [`Validate JWT`]: `Validar JWT`,
  [`Validate Username Token`]: `Validar token do nome de usuário`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Valide uma carga útil UsernameToken do Web Services Security usando URL de autenticação ou registro do usuário LDAP.`,
  [`Validate against`]: `Validar com relação a`,
  [`Validate request`]: `Validar solicitação`,
  [`Validate the GraphQL query and analyze the expected cost`]: `Valide a consulta do GraphQL e analise o custo esperado`,
  [`Validate the returned JSON and analyze the real cost`]: `Valide o JSON retornado e analise o custo real`,
  [`Validating API ...`]: `Validando a API...`,
  [`Validating Draft API`]: `Validando a API de rascunho`,
  [`Validity Period`]: `Período de validade`,
  [`Value`]: `Valor`,
  [`Value for located in must be selected.`]: `O valor de Localizado em deve ser selecionado.`,
  [`Value for the variable to be added.`]: `Valor da variável a ser incluída.`,
  [`Value for type must be selected.`]: `O valor de Tipo deve ser selecionado.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `O valor do argumento "peso" na diretiva "@cost" é < 0.`,
  [`Values`]: `Valores`,
  [`Vanity API endpoint`]: `Terminal de API personalizado`,
  [`Variable name`]: `Nome da Variável`,
  [`Variable value`]: `Valor de Variável`,
  [`Vendor Extensions`]: `Extensões do fornecedor`,
  [`Verb`]: `Verbo`,
  [`Verify`]: `Verificar`,
  [`Verify Access Token`]: `Verificar Token de Acesso`,
  [`Verify Crypto JWK variable name`]: `Verificar o nome de variável do JWK criptográfico`,
  [`Verify Crypto Object`]: `Verificar o objeto criptográfico`,
  [`Verify Token`]: `Verificar Token`,
  [`Verify authorization code`]: `Verificar código de autorização`,
  [`Verify refresh token`]: `Verifique se o token de atualização`,
  [`Version of the LTPA token.`]: `Versão do token LTPA.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Verifique a propriedade 'apis' em 'plans' no arquivo do produto importado. Cada plano deve ter propriedade 'apis' apontando para pelo menos uma API da seção 'apis'.`,
  [`View`]: `Visualizar`,
  [`View extensions`]: `Visualizar extensões`,
  [`View Forum`]: `Visualizar fórum`,
  [`View owners`]: `Visualizar proprietários`,
  [`View policies`]: `Visualizar políticas`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Visualize e gerencie as cifras disponíveis para a versão de protocolo selecionada.`,
  [`View analytics`]: `Visualizar analítica`,
  [`View and edit permissions for the roles in this space`]: `Visualize e edite as permissões para as funções neste espaço`,
  [`View and edit the permissions for the roles in the current organization`]: `Visualize e edite as permissões para as funções na organização atual`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Visualize e edite as funções contidas nos padrões de função para organizações de consumidores`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Visualize e edite as funções contidas nos padrões de função para organizações de provedor`,
  [`View approval history`]: `Visualizar histórico de aprovações`,
  [`View Approval History`]: `Visualizar histórico de aprovações`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Visualize a documentação e os tutoriais com instruções passo a passo.`,
  [`View error ID`]: `Visualizar o ID do erro`,
  [`View status`]: `Visualizar status`,
  [`View more`]: `Visualizar mais`,
  [`View source`]: `Visualizar origem`,
  [`View subscription`]: `Visualizar assinatura`,
  [`View template in`]: `Visualizar modelo em`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Visualize os terminais para acessar as interfaces com o usuário do API Connect.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Visualize os terminais para as APIs de REST do administrador e do provedor`,
  [`View the endpoints for the consumer REST APIs.`]: `Visualize os terminais para as APIs de REST do consumidor.`,
  [`Viewer`]: `Visualizador`,
  [`Views the API consumer organization`]: `Visualiza a organização do consumidor de API`,
  [`Views the API provider organization`]: `Visualiza a organização do provedor de API`,
  [`Views the admin organization`]: `Visualiza a organização do administrador`,
  [`Views the app developer organization`]: `Visualiza a organização do desenvolvedor de aplicativo`,
  [`Views the catalog`]: `Visualiza o catálogo`,
  [`Views the space`]: `Visualiza o espaço`,
  [`View|permission`]: `Visualizar`,
  [`Visibility`]: `Visibilidade`,
  [`Visibility options`]: `Opções de visibilidade`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `Docs do VMware`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `URL de WSDL`,
  [`WSDL has been successfully validated`]: `O WSDL foi validado com sucesso`,
  [`WSDL to REST`]: `WSDL para REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `Validação do WS-I Basic Profile`,
  [`Warn`]: `Avisar`,
  [`Warning`]: `Aviso`,
  [`Warning:`]: `Aviso:`,
  [`Warnings`]: `Avisos`,
  [`We just emailed recipients a test message:`]: `Acabamos de enviar aos destinatários uma mensagem de teste por e-mail:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `Estamos movendo-a para a guia "Testar", onde você será capaz de testar e depurar sua API.`,
  [`Weather Product`]: `Produto de clima`,
  [`Web Endpoint`]: `Terminal da web`,
  [`Web service operations`]: `Operações de Serviço da Web`,
  [`weight`]: `Weight`,
  [`Weight`]: `Weight`,
  [`Welcome to API Connect Administration`]: `Bem-vindo à Administração do API Connect`,
  [`Welcome to API Connect Reserved`]: `Bem-vindo ao API Connect Reserved`,
  [`Welcome to API Designer`]: `Bem-vindo ao API Designer`,
  [`Welcome to API Manager`]: `Bem-vindo ao API Manager`,
  [`Welcome to Cloud Manager`]: `Bem-vindo ao Cloud Manager`,
  [`Welcome to the API Designer`]: `Bem-vindo ao API Designer`,
  [`Welcome to the API Manager`]: `Bem-vindo ao API Manager`,
  [`Welcome to the Cloud Manager`]: `Bem-vindo ao Cloud Manager`,
  [`What are you looking for today?`]: `O que você está procurando hoje?`,
  [`What's new`]: `O que há de novo`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `O que vem a seguir? Ative a sua integração de faturamento em um catálogo para permitir que os usuários incluam planos aos produtos.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `Quando configurada para true, a política de chamada irá inserir os cabeçalhos "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" na solicitação enviada à URL de destino.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Quando publicado, um produto de API torna-se visível no portal do desenvolvedor associado ao catálogo. Os terminais para APIs no produto são disponibilizados em gateways associados ao espaço ou catálogo de destino. Os produtos preparados são apresentados no catálogo, mas não estão ativos ou visíveis para consumidores.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Quando publicado, um produto de API torna-se visível no portal do desenvolvedor associado ao catálogo. Os terminais para APIs no produto são disponibilizados em gateways associados ao espaço ou catálogo de destino. Os produtos preparados são apresentados no catálogo, mas não estão ativos ou visíveis para consumidores. Observe que a nova preparação/republicação de um produto em um catálogo de não produção removerá as assinaturas existentes.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Quando os espaços forem ativados para um catálogo, o provedor OAuth configurado deve ser visualizado a partir de um espaço.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Quando espaços são ativados, produtos existentes no catálogo serão movidos para um espaço padrão.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Quando o usuário clica no link de ativação, ele é solicitado a preencher o formulário de registro. Após o registro, o usuário pode efetuar login.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Quando você inclui uma função para organizações do provedor ou do consumidor, ela será incluída na função padrão. Os padrões de função são modelos de função pré-configurada usados para determinar as funções padrão disponíveis quando uma nova organização é criada.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Ao descontinuar um produto, os desenvolvedores de aplicativos já inscritos nele podem continuar usando o produto, mas nenhum novo desenvolvedor pode inscrever-se no produto.\n\n [Saiba mais]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Quando você edita uma função para as organizações do provedor ou do consumidor, ela será incluída na função padrão. Os padrões de função são modelos de função pré-configurada usados para determinar as funções padrão disponíveis quando uma nova organização é criada.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Ao ativar o OpenID Connect, um modelo é fornecido para gerar tokens de ID juntamente com tokens de acesso e, além disso, as políticas de conjunto necessárias são criadas automaticamente. É possível customizar as políticas para atender às suas necessidades. A chave de amostra é apenas para fins de teste e é usada para assinar o token JWT.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Quando você ativar a introspecção de token, o token de acesso pode ser examinado por meio do caminho de introspecção.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Ao ativar a introspecção de token, os titulares de tokens de acesso podem examinar o conteúdo dos tokens usando um caminho de introspecção. O token de acesso para introspecção deve ser obtido por meio do provedor OAuth nativo.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Quando você convida um usuário para ser um membro de uma organização do provedor, você designa a ele uma ou mais funções para controlar o nível de acesso às informações e as tarefas que ele pode executar.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Ao gerenciar as versões do produto, elas passam por uma série de estados de ciclo de vida, da preparação inicial de uma versão do produto para um catálogo, para a publicação e disponibilização da versão do produto para os desenvolvedores de aplicativo, até uma eventual desativação e o arquivamento.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Ao substituir um produto por outro produto, as seguintes ações são executadas: 1) o produto de substituição é publicado, 2) as mesmas configurações de cumprimento de visibilidade, assinante e gateway do produto original são utilizadas no produto de substituição, 3) os assinantes do produto original são migrados para o produto de substituição.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Ao substituir um produto por um outro produto, as ações a seguir serão tomadas: 1) O produto de substituição assume o estado do produto original, 2) Se as configurações de visibilidade e capacidade de assinatura no produto de substituição forem de modo que o acesso seja igual ou menos restritivo que o produto original, serão usadas as configurações no produto de substituição. Se as configurações no produto de substituição forem mais restritivas e impedirem algumas organizações de consumidores com acesso ao produto original de acessar a substituição, a operação de substituição não poderá ser concluída. 3) Os assinantes do produto original são migrados para o produto de substituição e (4) o produto original está descontinuado.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Ao desativar um produto, todas as APIs associadas ficam off-line e qualquer assinatura torna-se inativa.\n\n [Saiba mais]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Ao substituir um produto por outro produto, as seguintes ações são executadas: 1) o produto de substituição é publicado, 2) as mesmas configurações de cumprimento de visibilidade, assinante e gateway do Produto original são usadas para o produto de substituição, 3) o produto original é movido para o estado Descontinuado. Quando um produto é descontinuado, os desenvolvedores de aplicativos já inscritos nele podem continuar usando o produto, mas nenhum novo desenvolvedor pode inscrever-se no produto. Um produto descontinuado pode ser publicado novamente, se necessário.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Ao suplantar um produto por outro produto, as seguintes ações são executadas: 1) o produto de substituição é publicado, 2) as configurações de visibilidade e de capacidade de assinatura do produto original são usadas no produto de substituição e 3) o produto original é movido para o estado Descontinuado.  Quando um produto é descontinuado, os desenvolvedores de aplicativos já inscritos nele podem continuar usando o produto, mas nenhum novo desenvolvedor pode inscrever-se no produto.  Um produto descontinuado pode ser publicado novamente se necessário.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Quando você suspende um aplicativo, os IDs de cliente associados são impedidos de fazer chamadas API. É possível continuar o aplicativo a qualquer momento.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Quando ativado, o tempo limite de convite de todas as organizações de consumidores será configurado com o tempo limite de convite do catálogo e qualquer atualização no tempo limite de convite do catálogo atualizará o tempo limite de convite das organizações de consumidores.`,
  [`Where did the roles go?`]: `Para onde foram as funções?`,
  [`Where to find the named rate limit.`]: `Onde encontrar o limite de taxa nomeado.`,
  [`Where to put the decrypted result`]: `Onde colocar o resultado decriptografado`,
  [`Where to put the encrypted result`]: `Onde colocar o resultado criptografado`,
  [`Whether to reuse the connection to the target server.`]: `Reutilizar ou não a conexão com o servidor de destino.`,
  [`Windows live`]: `Windows Live`,
  [`With`]: `Com`,
  [`Wildcards ignore xsi:type rule`]: `Os curingas ignoram a regra xsi:type`,
  [`Work offline`]: `Trabalhar off-line`,
  [`Work with existing definitions, models and/or data sources`]: `Trabalhar com definições, modelos e/ou origens de dados existentes`,
  [`Working...`]: `Processando...`,
  [`Wrapper Policies`]: `Políticas de wrapper`,
  [`Wrong slicing arguments`]: `Argumentos de fatiamento errados`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL e XSDs devem estar dentro de seu próprio arquivo zip dentro do arquivo zip que você está tentando importar.`,
  [`XML Schema`]: `Esquema XML`,
  [`XML Schema URL`]: `URL do Esquema XML`,
  [`XML Schema preview`]: `Visualização do esquema XML`,
  [`XML Validation Mode`]: `Modo de validação de XML`,
  [`XML schema is not valid. Please correct the error.`]: `O esquema XML não é válido. Corrija o erro.`,
  [`XML to JSON`]: `XML para JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `Versão do XSLT`,
  [`YAML has been successfully validated`]: `YAML foi validado com sucesso`,
  [`Yes`]: `Sim`,
  [`Yes, enable it`]: `Sim, ativar isso`,
  [`Yes, reset`]: `Sim, reconfigurar`,
  [`Yes. Update API Assembly`]: `Sim. Atualizar conjunto de API`,
  [`You are authenticated!`]: `Você está autenticado.`,
  [`You do not have permission to use the Test Application`]: `Você não tem permissão para usar o aplicativo de teste`,
  [`You can also copy the link and send it directly to the user.`]: `Também é possível copiar o link e enviá-lo diretamente para o usuário.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `É possível optar por não publicar a API neste momento ou publicar imediatamente nos terminais de teste ou de produção ou em ambos. Opcionalmente, será possível especificar uma taxa máxima permitida de chamadas API com base em um número de chamadas em um determinado intervalo de tempo.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `É possível optar por não publicar a API neste momento ou publicar imediatamente nos terminais de teste ou de produção ou em ambos. Opcionalmente, é possível especificar uma taxa máxima de chamada API permitida com base em um número de chamadas por intervalo de tempo especificado.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `É possível optar por deixar a API não assegurada neste momento ou requerer que um aplicativo de chamada deva fornecer um ID de Cliente. É possível configurar opções de segurança adicionais após ter criado a definição de API.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `É possível inserir um nome ou título do catálogo. O nome do catálogo terá precedência se ambos estiverem disponíveis.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `É possível enviar um e-mail para o proprietário da organização de um consumidor. A mensagem é enviada usando o servidor de e-mail configurado em Notificações.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `É possível enviar um e-mail para o proprietário da organização de um consumidor. A mensagem é enviada utilizando o servidor de e-mail configurado na seção Notificações.`,
  [`You can send an email to a provider organization owner.`]: `É possível enviar um e-mail a um proprietário da organização do provedor.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `É possível transferir a propriedade para um novo usuário inserindo seu endereço de e-mail. Ele receberá um e-mail com um link de ativação para registrar. Quando registrado, o usuário se tornará o novo proprietário. Designe novas funções para o proprietário atual, para que ele continue trabalhando no Cloud Manager.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `É possível transferir a propriedade para um usuário registrado inserindo seu endereço de e-mail. Designe novas funções para o proprietário atual, para que ele continue trabalhando no Cloud Manager.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `É possível usar um arquivo de definição do OpenAPI para incluir uma API de REST no IBM API Connect. O formato do arquivo pode ser JSON ou YAML.  Quando a definição de API tiver sido importada, ela será mostrada na lista de definições de API.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `Não é possível excluir este tipo porque ele removerá o tipo raiz ('Consulta').`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `Não é possível selecionar uma API cujo tipo de gateway não corresponde ao tipo de gateway desse Produto.`,
  [`You currently don’t have any gateway extensions`]: `Atualmente, você não tem nenhuma extensão de gateway`,
  [`You currently don’t have any plans to migrate`]: `Atualmente, você não tem nenhum plano para migrar`,
  [`You currently don't have any policy.`]: `Atualmente você não tem nenhuma política.`,
  [`You do not have permission to any spaces.`]: `Você não tem permissão para nenhum espaço.`,
  [`You do not have permission to publish products to any catalog or space.`]: `Você não tem permissão para publicar produtos em nenhum catálogo ou espaço.`,
  [`You do not have permission to stage products to any catalog or space.`]: `Você não tem permissão para preparar produtos em nenhum catálogo ou espaço.`,
  [`You don’t currently have any billing integrations.`]: `No momento, você não tem nenhuma integração de faturamento.`,
  [`You don't currently have any response.`]: `No momento você não tem nenhuma resposta.`,
  [`You don't currently have any user-defined policies.`]: `Você não tem nenhuma política definida pelo usuário no momento.`,
  [`You don't have permission to access this page.`]: `Você não tem permissão para acessar esta página.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Você modificou esse certificado. Salvar esse aplicativo atualizará sua configuração.`,
  [`You haven't added any APIs or Products`]: `Não foram incluídas APIs ou Produtos`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Gerencie a participação no catálogo incluindo novos usuários e designando funções a eles.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `É possível gerenciar a associação do espaço incluindo novos usuários no Espaço e designando funções aos usuários.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Gerencie a participação no espaço incluindo novos usuários e designando funções a eles.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `Será preciso incluir sua API em um produto pronto para publicação. Um produto coleta um conjunto de APIs e planos em uma oferta disponibilizada aos seus desenvolvedores. Um plano inclui configurações de limite de taxa que podem ser aplicadas ao plano como um todo ou especificadas para cada operação em uma API.`,
  [`You must choose a definition`]: `Deve-se escolher uma definição`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `Deve-se ativar pelo menos um serviço de gateway para que as APIs publicadas neste Catálogo estejam disponíveis para serem chamadas em um terminal em serviço de gateway. É possível ativar múltiplos serviços de gateway.`,
  [`You must enable spaces`]: `Deve-se ativar espaços`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Você deve registrar o ID de Cliente e o segredo abaixo para uso ao desenvolver seu aplicativo. O segredo do cliente não pode ser exibido novamente.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Sua licença do API Connect Reserved Instance autoriza você a fazer download e configurar um ou mais DataPower API Gateways remotos. Os gateways podem estar no local ou em qualquer nuvem.`,
  [`Your API has been created`]: `Sua API foi criada`,
  [`Your API is live!`]: `Sua API está ativa!`,
  [`Your API is online!`]: `Sua API está online!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Seu catálogo está sendo excluído. Isso pode demorar um pouco...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Sua organização do consumidor está sendo criada. Isso pode demorar um pouco...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Seu convite não é mais válido. Verifique sua configuração de tempo limite de convite e reenvie o convite.`,
  [`Your Provider organization is being created. This may take a while...`]: `Sua Organização de provedor está sendo criada. Isso pode demorar um pouco...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Sua Organização do provedor está sendo excluída. Isso pode demorar um pouco...`,
  [`Your member is being deleted. This may take a while...`]: `O membro está sendo excluído. Isso pode demorar um pouco...`,
  [`Your Space is being deleted. This may take a while...`]: `Seu espaço está sendo excluído. Isso pode demorar um pouco...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `Seu provedor OAuth possui mudanças não salvas. Clique em OK para continuar sem salvar.`,
  [`Your credentials have expired.`]: `Suas credenciais expiraram.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Seu convite foi enviado com sucesso com o link de convite a seguir:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `Sua solicitação ainda está sendo processada e está demorando mais tempo que o esperado. Atualize em alguns minutos antes de tentar novamente a solicitação.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Seu produto será salvo antes de prosseguir. Tem certeza?`,
  [`Your trial ends on {date}`]: `Sua versão de teste termina em {date}`,
  [`Your trial expired on {date}.`]: `Sua versão de teste expirou em {date}.`,
  [`Zoom in`]: `Ampliar`,
  [`Zoom in and out`]: `aumentar e diminuir zoom`,
  [`Zoom out`]: `Reduzir`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `uma falta de suporte do CORS no servidor de destino (veja a sugestão abaixo)`,
  [`a LDAP provider`]: `Um provedor LDAP`,
  [`activity`]: `Atividade`,
  [`add item...`]: `incluir item...`,
  [`add property...`]: `incluir propriedade...`,
  [`added`]: `incluído`,
  [`admin`]: `administrador`,
  [`administrator`]: `administrador`,
  [`all`]: `tudo`,
  [`an API Connect Local User Registry`]: `Um API Connect Registro do Usuário Local`,
  [`an authentication URL`]: `Uma URL de autenticação`,
  [`an untrusted certificate has been encountered`]: `um certificado não confiável foi encontrado`,
  [`and`]: `e`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Aplicativo:`,
  [`application/json`]: `aplicativo/json`,
  [`application/xml`]: `aplicativo/xml`,
  [`approval`]: `aprovação`,
  [`approval task for product`]: `tarefa de aprovação para o produto`,
  [`archived`]: `arquivado`,
  [`authenticated`]: `autenticado`,
  [`authorization code time to live (seconds)`]: `tempo de vida de código de autorização (segundos)`,
  [`Authorization header pass through`]: `Passagem de cabeçalho de autorização`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `bloqueio`,
  [`body`]: `corpo`,
  [`body-param`]: `corpo-parâmetro`,
  [`cancel`]: `cancelar`,
  [`case`]: `caso`,
  [`catalog`]: `Catálogo`,
  [`catalog:`]: `Catálogo:`,
  [`catch`]: `capturar`,
  [`changed`]: `mudado`,
  [`cipher`]: `Cifra`,
  [`code id_token`]: `code id_token`,
  [`code id_token token`]: `code id_token token`,
  [`code token`]: `token de código`,
  [`Consume`]: `Consumir`,
  [`consumer`]: `consumidor`,
  [`consumes`]: `utiliza`,
  [`composite`]: `composto`,
  [`created`]: `Criado`,
  [`Created API`]: `API criada`,
  [`custom string`]: `sequência customizada`,
  [`day`]: `dia`,
  [`day(s)`]: `dias`,
  [`default`]: `padrão`,
  [`default value`]: `valor padrão`,
  [`definition`]: `definição`,
  [`deprecated`]: `Descontinuado`,
  [`description`]: `descrição`,
  [`development`]: `desenvolvimento`,
  [`edit condition`]: `editar condição`,
  [`edited`]: `editado`,
  [`environment`]: `Catálogo`,
  [`error`]: `erro`,
  [`false`]: `false`,
  [`file`]: `file`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `Ícones do font-awesome 4.5.0`,
  [`for`]: `para`,
  [`header`]: `cabeçalho`,
  [`hear`]: `ouvir`,
  [`hello`]: `Olá`,
  [`helloName`]: `Olá {name}!`,
  [`here`]: `aqui`,
  [`hit`]: `ocorrência`,
  [`hits`]: `ocorrências`,
  [`hour`]: `hora`,
  [`hour(s)`]: `horas`,
  [`hours`]: `horas`,
  [`identified`]: `identificado`,
  [`Increased`]: `Aumentou`,
  [`Increment`]: `Incrementar`,
  [`info`]: `informações`,
  [`information about external documentation`]: `informações sobre documentação externa`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `Em vez de honrar a solicitação exp do provedor OIDC, o access_token/refresh_token gerado pelo APIC honrará a configuração do APIC`,
  [`introspect URL`]: `URL de introspecção`,
  [`Issue`]: `Emissão`,
  [`Issues`]: `Problemas`,
  [`item`]: `item`,
  [`iterate over`]: `iterar`,
  [`last modified`]: `última modificação`,
  [`Log Level`]: `Nível de log`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `Registros`,
  [`Logout`]: `Efetuar logout`,
  [`Logout redirect`]: `Redirecionamento de logout`,
  [`Logout redirect URL`]: `URL de redirecionamento de logout`,
  [`location`]: `local`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `minuto`,
  [`minute(s)`]: `minuto(s)`,
  [`minutes`]: `minutos`,
  [`mutation type`]: `tipo de mutação`,
  [`mutual SSL authentication is required`]: `a autenticação SSL mútua é necessária`,
  [`name`]: `Nome`,
  [`Name|extension`]: `Nome`,
  [`Name|path parameters`]: `Nome`,
  [`Name|security definitions`]: `Nome`,
  [`non-blocking`]: `sem bloqueio`,
  [`none`]: `nenhum`,
  [`none selected`]: `nenhum (a)`,
  [`Object`]: `Objeto`,
  [`off`]: `Desligado`,
  [`of {total} pages`]: `de {total} páginas`,
  [`of {total} page`]: `de {total} página`,
  [`offline`]: `offline`,
  [`on`]: `Ligado`,
  [`online`]: `on-line`,
  [`or`]: `ou`,
  [`or redact from all`]: `ou editar de todos`,
  [`open and close list of options`]: `abrir e fechar a lista de opções`,
  [`organization saved`]: `Organização salva`,
  [`organization-manager`]: `organization-manager`,
  [`otherwise`]: `caso contrário`,
  [`payload`]: `carga útil`,
  [`Payload`]: `Carga útil`,
  [`pending`]: `suspenso`,
  [`produces`]: `produz`,
  [`production`]: `produção`,
  [`production and development`]: `produção e desenvolvimento`,
  [`property`]: `Propriedade`,
  [`provider`]: `provedor`,
  [`publish product`]: `publicar produto`,
  [`published`]: `publicado`,
  [`query type`]: `tipo de consulta`,
  [`query-parameters`]: `parâmetros de consulta`,
  [`realized`]: `realizado`,
  [`recipients`]: `destinatários`,
  [`removed`]: `removido`,
  [`replaced`]: `substituído`,
  [`repeatable`]: `repetido`,
  [`Replenish`]: `Reabastecer`,
  [`request`]: `solicitação`,
  [`requested by`]: `solicitado por`,
  [`Resolver weight`]: `Peso do resolvedor`,
  [`response`]: `response`,
  [`response-param`]: `parâmetro de resposta`,
  [`retired`]: `ultrapassado`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `salvo`,
  [`scalar`]: `escalar`,
  [`second`]: `segundo`,
  [`second(s)`]: `segundo(s)`,
  [`seconds`]: `segundos`,
  [`select owner user`]: `selecionar o usuário proprietário`,
  [`select user`]: `selecionar usuário`,
  [`soap-body`]: `sabão-corpo`,
  [`source`]: `origem`,
  [`space`]: `espaço`,
  [`Space settings`]: `Configurações de espaço`,
  [`Skip`]: `Ignorar`,
  [`specified`]: `especificado`,
  [`staged`]: `por etapas`,
  [`start generating tests automatically.`]: `iniciar a geração de testes automaticamente.`,
  [`subject`]: `assunto`,
  [`subscription type`]: `tipo de assinatura`,
  [`supersede`]: `suplantar`,
  [`superseded`]: `substituir`,
  [`target`]: `destino`,
  [`the server is unavailable`]: `o servidor está indisponível`,
  [`this user registry type`]: `Este tipo de registro do usuário`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Essa é uma página de publicação temporária na qual publicar novamente preserva as assinaturas.`,
  [`to production`]: `para produção`,
  [`topology-administrator`]: `topologia-administrador`,
  [`Trial days left`]: `Dias de avaliação restantes`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `ttl foi mudado`,
  [`type`]: `Tipo da Origem`,
  [`unarchived`]: `não arquivado`,
  [`union type`]: `tipo de união`,
  [`unlimited`]: `ilimitado`,
  [`updated`]: `atualizado`,
  [`Updated from new schema`]: `Atualizado com o novo esquema`,
  [`Upgrade`]: `Upgrade`,
  [`v5 legacy behavior`]: `comportamento anterior da v5`,
  [`version 2`]: `versão 2`,
  [`version 3`]: `versão 3`,
  [`viewer`]: `visualizador`,
  [`value`]: `Valor`,
  [`warn`]: `aviso`,
  [`warning`]: `aviso`,
  [`warnings`]: `avisos`,
  [`was not found. Please select a configured OAuth provider.`]: `não foi encontrada. Selecione um provedor do OAuth configurado.`,
  [`week`]: `semana`,
  [`with`]: `por`,
  [`with|supersede with`]: `por`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `status no ambiente de simulação`,
  [`your file here, or`]: `seu arquivo aqui ou`,
  [`{count} found!`]: `{count} localizados`,
  [`{current} of {total} page`]: `{current} da página {total}`,
  [`{current} of {total} pages`]: `{current} de {total} página(s)`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} dias, {hours} horas e {minutes} minutos`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} dias e {hours} horas`,
  [`{docType} is required`]: `{docType} é obrigatório`,
  [`{docType} title`]: `Título do {docType}`,
  [`Product title`]: `Título do Produto`,
  [`Application title`]: `Título do aplicativo`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} horas e {minutes} minutos`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} gateways ocultos devido à incompatibilidade com o tipo de gateway especificado na API ou no produto`,
  [`{min}-{max} of {total} items`]: `{min}-{max} de {total} itens`,
  [`{min}-{max} of {total} item`]: `{min} - {max} de itens {total}`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} de {total} políticas`,
  [`{min}-{max} of {total} Policy`]: `{min}-{max} de política {total}`,
  [`{min}-{max} of {total} types`]: `{min}-{max} de {total} tipos`,
  [`{min}-{max} of {total} type`]: `{min} - {max} de itens {total}`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} de {total} resultados da procura`,
  [`{min}-{max} of {total} search result`]: `{min}-{max} de resultado da procura {total}`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} de {total} Catálogos`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{max} of catálogo {total}`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} de {total} Espaços`,
  [`{min}-{max} of {total} Space`]: `{min}-{max} de espaço {total}`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} de {total} extensões`,
  [`{min}-{max} of {total} Extension`]: `{min}-{max} de extensão {total}`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} de {total} planos de produto`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{max} do plano de produto {total}`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} de {total} gateways`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{max} de gateway {total}`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `'x-ibm-configuration' está ausente de {name} (API)`,
  [`{name} (Product) has been published!`]: `{name} (Produto) foi publicado!`,
  [`{name} (Product) has been staged!`]: `{name} (Produto) foi estagiado!`,
  [`{name} (Product) has not been published!`]: `{name} (Produto) não foi publicado!`,
  [`{name} (Role) has been created`]: `{name} (Função) foi criado.`,
  [`{name} successfully deleted`]: `{name} excluído com sucesso.`,
  [`{name} (Role) has been updated`]: `{name} (Função) foi atualizado.`,
  [`{name} has been assigned the owner`]: `{name} foi designado ao proprietário`,
  [`{number} Error`]: `{number} Erro`,
  [`{number} Errors`]: `{number} Erros`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `Tarefa de aprovação de {operation} para o produto {prodname}:{version} solicitada por {username}`,
  [`{path} does not exist`]: `{path} não existe`,
  [`{productName} has no migration target set.`]: `O {productName} não tem nenhum destino de migração configurado.`,
  [`{state} Pending`]: `{state} Pendente`,
  [`{status} API error`]: `Erro de API {status}`,
  [`{status} Bad Request`]: `{status} Solicitação inválida`,
  [`{status} Forbidden`]: `{status} Proibido`,
  [`{status} Gateway Error`]: `Erro de gateway {status}`,
  [`{status} Unauthorized`]: `{status} desautorizado`,
  [`({time} after being sent)`]: `({time} após ser enviado)`,
  [`({time} after being queued)`]: `({time} após ser enfileirado)`,
  [`{title} (Product) has been {state}.`]: `{title} (Produto) foi {state}.`,
  [`{title} has been deleted`]: `OA) {title} foi excluído(a)`,
  [`{title} has been {changedName}.`]: `{title} foi {changedName}.`,
  [`{title} has not been deleted!`]: `O(A) {title} não foi excluído(a)!`,
  [`{title} has not been {changedName}!`]: `{title} não foi {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type} foi {changedName}.`,
  [`{type} has not been {changedName}!`]: `{type} não foi {changedName}!`,
  [`{type} history log`]: `Log do histórico de {type}`,
  [`{username} does not exist`]: `{username} não existe`,
  [`{username} does not exist in the user registry`]: `{username} não existe no registro do usuário`,
  [`{username} is not associated with this consumer org`]: `{username} não está associado a esta organização do consumidor`,
  [`{username} has been created as a {type}.`]: `{username} foi criado como um {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `{appName} app restabelecido no portal do {consumerOrg} desenvolvedor`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `{appName} Aplicativo suspenso no portal do  {consumerOrg} desenvolvedor`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} Registro da conta do portal do desenvolvedor`,
  [`{{count}} errors need attention`]: `{count} erros precisam de atenção`,
  // lts only translations
  [`Configure JSON settings`]: `Definir configurações JSON`,
  [`Configure XML settings`]: `Definir configurações de XML`,
  [`Configure options`]: `Configurar opções`,
  [`Detect`]: `Detectar`,
  [`Document type`]: `Tipo de documento`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Tamanho máximo do documento`,
  [`Maximum name length`]: `Comprimento de nome máximo`,
  [`Maximum nesting depth`]: `Profundidade máxima de aninhamento`,
  [`Maximum number length`]: `Comprimento máximo do número`,
  [`Maximum number of unique names`]: `Número máximo de nomes exclusivos`,
  [`Maximum number of unique namespaces`]: `Número máximo de namespaces exclusivos`,
  [`Maximum number of unique prefixes`]: `Número máximo de prefixos exclusivos`,
  [`Maximum value length`]: `Comprimento Máximo do Valor`,
  [`Maximum width`]: `Largura máxima`,
  [`Maximum stack size`]: `Tamanho máximo de pilha`,
  [`Parse settings`]: `Configurações da Análise`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Especifica o tamanho máximo do documento em bytes que a ação de análise aceita.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Especifica o nível máximo de profundidade do elemento aninhado em uma mensagem XML ou JSON que a ação de análise aceita.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Especifica o nível máximo de profundidade de elemento aninhado em uma mensagem XML, JSON ou GraphQL que a ação de análise aceita.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Especifica o comprimento máximo do nome em bytes que a ação de análise aceita.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Especifica o comprimento de número máximo que limita o número de bytes na parte de valor de um par de valor de rótulos quando o valor for um número.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Especifica o número máximo de URIs exclusivos de namespace XML que a ação de análise aceita. Este limite conta todos os namespaces XML, independentemente de quantos prefixos são usados para declará-los.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Especifica o número máximo de prefixos exclusivos de namespace XML em um documento que a ação de análise aceita. Este limite conta vários prefixos definidos para o mesmo namespace, mas não conta vários namespaces definidos em diferentes partes do documento de entrada em um único prefixo.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Especifica o número máximo de nomes exclusivos aceitos pela ação de análise.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Especifique o comprimento máximo do valor em bytes que a ação de análise aceita.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Especifica a largura máxima de uma carga útil aceita pela ação de análise.`,
  [`Specifies the type of document to parse.`]: `Especifica o tipo de documento a ser analisado.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Especifica a versão do processador XSLT. O valor padrão é XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Especifica se a verificação estrita de erro do XSLT deve ser ativada. As operações não estritas tentam se recuperar de determinados erros, como o uso de variáveis não declaradas, a chamada de modelos não declarados e assim por diante. Por padrão, a verificação estrita de erro XSLT está ativada.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Especifica se a criação de perfil da folha de estilo deve ser ativada. Essa opção não deve ser usada em ambientes de produção. Por padrão, a criação de perfil da folha de estilo está desativada.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Especifica se a folha de estilo, o script XQuery e o script JSONiq devem ser executados no modo de depuração. Quando uma folha de estilo, um script XQuery ou um script JSONiq é executado no modo de depuração, ele gera uma página da web customizada em vez de exibir a sua saída normal. A página da web detalha exatamente o que ocorreu durante a execução, incluindo os valores de variáveis e de onde vieram as partes específicas da saída. Essa opção não deve ser usada em ambientes de produção. Por padrão, o modo de depuração está desativado.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Especifica se a folha de estilo deve ser executada no modo de fluxo. A transformação do documento começa antes da análise completa da entrada. Nem todas as folhas de estilo podem ser transmitidas. Se não for possível transmitir a folha de estilo, um erro será gerado e a entrada não será processada. Por padrão, o modo de fluxo está desativado.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Especifica se deve ocorrer uma tentativa de execução da folha de estilo no modo de fluxo. A transformação do documento começa antes da análise completa da entrada. Nem todas as folhas de estilo podem ser transmitidas. Se não for possível transmitir a folha de estilo, um aviso será gerado durante a compilação e a folha de estilo será lida normalmente em toda a entrada no tempo de execução. Por padrão, a tentativa de executar a folha de estilo no modo de fluxo está desativada.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Especifica se a saída produzida pela folha de estilo deve ser escapada durante o processamento. O escape mínimo é útil, principalmente ao manipular conjuntos de caracteres que não estão em inglês. Por padrão, o escape mínimo está desativado.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Indica o número máximo de bytes que a pilha tem permissão para usar ao executar uma folha de estilo ou outro conteúdo compilado. Essa configuração é usada para bloquear a recursão infinita. O valor mínimo é 10 kilobytes ou 10.240 bytes. O valor máximo é 100 megabytes ou 104.857.600 bytes. O valor padrão é 1 megabyte ou 1.048.576 bytes.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Especifica o comportamento de validação a ser aplicado a arquivos WSDL verificados quanto à conformidade com a seção 5 do WS-I Basic Profile (versão 1.0, abril de 2004). A configuração padrão é Avisar.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Especifica o comportamento de validação para o soap:Body. A configuração padrão é Estrita.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Especifica o comportamento de validação para o soap:Header. A configuração padrão é Lax.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Especifica o comportamento de validação do detalhe de falha. A configuração padrão é Estrita.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Especifica se a compatibilidade com wrappers do estilo RPC deve ser solicitada. Por padrão, os wrappers do estilo RPC não são necessários.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Especifica se o esquema para aceitar a maioria dos usos de elementos com xsi:type='SOAP-ENC:Array' consistente com SOAP 1.1, Seção 5, deve ser permitido, mesmo quando esses atributos violam a especificação de Esquema XML. Normalmente, o atributo xsi:type deve nomear um tipo igual ou derivado do tipo real do elemento. Para os esquemas compilados com essa opção, xsi:type será aceito especificamente para o tipo complexo 'Array' da Codificação SOAP 1.1 se o tipo de elemento for derivado de SOAP-ENC:Array. O oposto é o caso normal permitido. Por padrão, os elementos com xsi:type='SOAP-ENC:Array' não são aceitos.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Especifica se a validação de esquema adicional deve ser executada seguindo as regras de codificação na Seção 5 de SOAP 1.1. Quando ativada, membros de matrizes SOAP são validados, atributos como @id e @href são permitidos, mesmo que não sejam permitidos pelo esquema, e valores @href são verificados para assegurar que tenham um elemento @id correspondente. Por padrão, a validação adicional não é executada.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Especifica se os elementos xs:any no esquema validam apenas os elementos-filhos por nome. A especificação de Esquema XML requer que, se um curinga corresponder a um elemento que não contém uma declaração de elemento, o elemento seja validado de acordo com um atributo xsi:type contido nele. Esta opção ignora os atributos xsi:type. Ela deve ser usada para casos como a validação do envelope SOAP, em que uma etapa de validação adicional validará o conteúdo correspondente ao curinga, possivelmente usando as regras de codificação SOAP 1.1. Por padrão, os atributos xsi:type não são ignorados.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Especifica se a ligação SOAP no WSDL deve ser seguida estritamente. Quando ativada, apenas as mensagens ligadas ao SOAP 1.2 aparecem em envelopes SOAP 1.2 e apenas as mensagens ligadas ao SOAP 1.1 aparecem em envelopes SOAP 1.1. Por padrão, a ligação SOAP estrita está desativada.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Especifica se as políticas XACML com informações sobre depuração devem ser compiladas. Observe que as mensagens de depuração XACML também são controladas pelo evento de log na categoria XACML. Use o nível de log de depuração para visualizar as mensagens de depuração XACML. Por padrão, as políticas XACML não são compiladas com informações sobre depuração.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Especifica se o esquema ou o documento WSDL aceita mensagens nas quais o conteúdo binário codificado em base64 foi otimizado de acordo com as especificações de MTOM/XOP. A otimização binária XOP substitui os dados binários codificados em base64 por um elemento de referência xop:Include que faz referência aos dados binários não codificados que estão presentes em um anexo. Por padrão, as mensagens otimizadas por MTOM/XOP estão desativadas.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Um grupo de organizações de consumidores é uma coleção de organizações de consumidores que fornece uma forma eficiente de controlar quem pode ver e assinar as APIs nos Produtos. Ao usar um grupo de organizações de consumidores, é possível definir esse acesso para todos os desenvolvedores nas organizações desse grupo em uma única operação, em vez de precisar definir o acesso para as organizações separadamente.`,
  [`API Connect Home`]: `Página inicial do API Connect`,
  [`Add new group`]: `Incluir novo grupo`,
  [`Add consumer organizations to the group as required`]: `Incluir organizações de consumidores ao grupo conforme o necessário`,
  [`Add the following configuration`]: `Inclua a configuração a seguir`,
  [`Add the following configuration to`]: `Inclua a configuração a seguir em`,
  [`Additions`]: `Inclusões`,
  [`Additional GraphQL capabilities`]: `Recursos GraphQL adicionais`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Configurações adicionais para APIs de graphQL que estão incluídas neste plano`,
  [`An organization or a group is required.`]: `É necessária uma organização ou um grupo.`,
  [`An organization/group is required.`]: `É necessária uma organização ou um grupo.`,
  [`Analysis configuration`]: `Configuração de análise`,
  [`Application & Credentials`]: `Aplicativo e credenciais`,
  [`Are you sure you want to delete this API version?`]: `Tem certeza de que deseja excluir esta versão de API?`,
  [`Are you sure you want to delete this API with all versions?`]: `Tem certeza de que deseja excluir esta API com todas as versões?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Tem certeza de que deseja remover a extensão de gateway do gateway?`,
  [`Are you sure you want to rename all versions of this API?`]: `Tem certeza de que deseja renomear todas as versões desta API?`,
  [`Are you sure you want to rename this API version?`]: `Tem certeza de que deseja renomear esta versão de API?`,
  [`Associate groups to the organization as required`]: `Associar grupos à organização, conforme o necessário`,
  [`At least one option must be enabled`]: `Pelo menos uma opção deve ser ativada`,
  [`Attribute mapping`]: `Mapeamento de atributo`,
  [`Attempt streaming rule`]: `Tentar aplicar a regra de fluxo`,
  [`Auto generated default paths and operations`]: `Caminhos e operações padrão gerados automaticamente`,
  [`Base API endpoint`]: `Terminal de API de base`,
  [`Consumer orgs`]: `Organizações do consumidor`,
  [`Calculate Query Characteristics`]: `Calcular características de consulta`,
  [`Calculate Response Characteristics`]: `Calcular características de resposta`,
  [`Certificate or Shared Secret for Verify`]: `Certificado ou segredo compartilhado para verificação`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Confira nossos laboratórios práticos, nos quais é possível solicitar os recursos completos do gerenciamento de API por até uma semana.`,
  [`Clone API`]: `Clonar API`,
  [`Consumer organizations and groups`]: `Organizações e grupos de consumidores`,
  [`Create new API version`]: `Criar nova versão de API`,
  [`Create new product version`]: `Criar nova versão de produto`,
  [`Create a GraphQL API based on a GraphQL service`]: `Criar uma API do GraphQL com base em um serviço GraphQL`,
  [`Custom rate limit`]: `Limite de taxa customizado`,
  [`Delete API`]: `Excluir API`,
  [`Delete this version`]: `Excluir esta versão`,
  [`Deletion confirmation`]: `Confirmação de exclusão`,
  [`Deletions`]: `Exclusões`,
  [`Display name`]: `Nome de exibição`,
  [`Display Name`]: `Nome de Exibição`,
  [`Details`]: `Detalhes`,
  [`Edit GraphQL Rate Limit|title`]: `Editar Limite|título de taxa do GraphQL`,
  [`Edit GraphQL rate limits`]: `Editar limites de taxa do GraphQL`,
  [`Edit group`]: `Editar grupo`,
  [`Edit condition`]: `Editar condição`,
  [`Edit rate limit|title`]: `Editar limite de taxa`,
  [`Edit rate limit|menu`]: `Editar limite de taxa`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Não há produtos publicados neste Catálogo ou este aplicativo já está inscrito em todos os Planos em todos os Produtos.`,
  [`Enforce Policy`]: `Cumprir a Política`,
  [`Enter a group summary`]: `Insira um resumo do grupo`,
  [`Enter a group title`]: `Insira um título do grupo`,
  [`Enter group details below`]: `Inserir os detalhes do grupo abaixo`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Insira a URL e o nome do esquema para o serviço GraphQL em que você gostaria de efetuar proxy`,
  [`Enum value`]: `Valor numérico`,
  [`Go to catalog settings to configure a new billing integration`]: `Acesse as configurações do catálogo para configurar uma nova integração de cobrança`,
  [`GraphQL introspect`]: `Introspecção do GraphQL`,
  [`GraphQL server`]: `Servidor GraphQL`,
  [`GraphQL Setting`]: `Configuração de GraphQL`,
  [`GraphQL server URL`]: `URL do servidor GraphQL`,
  [`GraphQL server name`]: `Nome do servidor GraphQL`,
  [`GraphQL type analysis configuration`]: `Configuração de análise do tipo GraphQL`,
  [`GraphQL resolver analysis configuration`]: `Configuração de análise do resolvedor GraphQL`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Os grupos podem ser criados e gerenciados na lista a seguir.`,
  [`Groups will be listed here.`]: `Os grupos serão listados aqui.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Olá {firstName},\n\nSua solicitação de {action} o produto de API {productName}:{version} no catálogo \n{catalog} foi aprovada.\n\n{#if comments}\nO aprovador inclui os comentários a seguir:\n\n{comments}\n\n{{/if}}\nÉ possível verificar o status do produto aqui:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Olá,\n\nEsta é uma mensagem de teste do IBM API Connect do servidor de e-mail configurado\n(mailServer.\n\nSe recebeu essa mensagem conforme o esperado, o teste foi bem-sucedido!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Olá,\n\nO usuário {originator} retirou a solicitação de assinatura do aplicativo {appName}\nno plano {planName} para a versão {productVersion} do produto de API {productName} no\ncatálogo {catalog}.\n \nNenhuma ação adicional é necessária.\n`,
  [`Invoke GraphQL server`]: `Chamar o servidor GraphQL`,
  [`Invoke the backend GraphQL server`]: `Chamar o servidor GraphQL de back-end`,
  [`JWKS endpoint`]: `Terminal do JWKS`,
  [`LDAP Field Name`]: `Nome do campo LDAP`,
  [`Limit maximum nesting per query`]: `Limitar aninhamento máximo por consulta`,
  [`Limit resolve complexity rate on a per key basis`]: `Limitar a taxa de complexidade de resolução por chave`,
  [`Limit type complexity rate on a per key basis`]: `Limitar taxa de complexidade do tipo por chave`,
  [`Manage APIs included in this product`]: `Gerenciar APIs incluídas neste produto`,
  [`Manage groups`]: `Gerenciar grupos`,
  [`Maximum nesting`]: `Aninhamento máximo`,
  [`Maximum resolve complexity`]: `Complexidade máxima de resolução`,
  [`Maximum type complexity`]: `Complexidade máxima de tipo`,
  [`Name of the group`]: `Nome do grupo`,
  [`New API version`]: `Nova versão de API`,
  [`New GraphQL API`]: `Nova API do GraphQL`,
  [`New product version`]: `Nova versão de produto`,
  [`New Version`]: `Nova versão`,
  [`New version`]: `Nova Versão`,
  [`No billing integration exists`]: `Não existe nenhuma integração de faturamento`,
  [`No CA bundles available`]: `Nenhum pacote configurável de CA disponível`,
  [`No Data`]: `Nenhum dado`,
  [`No certificates available`]: `Nenhum certificado disponível`,
  [`No data`]: `Nenhum dado`,
  [`No group found`]: `Nenhum grupo localizado`,
  [`OAuth Auth URL`]: `URL de autorização OAuth`,
  [`OAuth Token URL`]: `URL de token OAuth`,
  [`Open Stripe dashboard`]: `Abrir painel do Stripe`,
  [`Operation/Path`]: `Operação/Caminho`,
  [`Operation Path`]: `Caminho da operação`,
  [`Operations and Paths`]: `Operações e Caminhos`,
  [`Operator`]: `Operador`,
  [`Organizations and groups will be listed here.`]: `As organizações e os grupos serão listados aqui.`,
  [`Page not found`]: `Página não localizada`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Copie o código abaixo para a CLI para continuar o processo. O código expirará em **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Consulte o painel do Stripe para acessar a chave de API e o segredo`,
  [`Please upload a schema definition language (SDL) file.`]: `Faça upload de um arquivo de linguagem de definição de esquema (SDL).`,
  [`Please upload a valid GraphQL schema.`]: `Faça upload de um esquema GraphQL válido.`,
  [`Please upload a valid YAML file.`]: `Faça upload de um arquivo YAML válido.`,
  [`Populate Server Configuration into Context Variables`]: `Preencher a Configuração do Servidor nas Variáveis de Contexto`,
  [`POST/GET a query to be validated and sent to the backend server`]: `POST/GET uma consulta a ser validada e enviada para o servidor de back-end`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `POST/GET uma consulta para obter o custo estimado de chamada dessa consulta`,
  [`Preparing`]: `Preparando`,
  [`Rate limit amount cannot be negative`]: `A quantia do limite de taxa não pode ser negativa`,
  [`Rate limit per cannot be negative`]: `O limite de taxa por não pode ser negativo`,
  [`Rename API`]: `Renomear API`,
  [`Rename`]: `Renomear`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Solicitação de aprovação de {action} de um produto de API no catálogo {catalog}`,
  [`Sandbox Sample Application`]: `Aplicativo de amostra do ambiente de simulação`,
  [`Sandbox sample application credentials`]: `Credenciais do aplicativo de amostra do ambiente de simulação`,
  [`sample application credentials`]: `Credenciais do aplicativo de amostra`,
  [`Saving`]: `Salvando`,
  [`Search Orgs`]: `Procurar orgs`,
  [`Search definitions...`]: `Procurar definições...`,
  [`Search errors...`]: `Procurar erros...`,
  [`Search operations...`]: `Procurar operações...`,
  [`Search organizations and groups`]: `Procurar organizações e grupos`,
  [`Sorry, This feature is not available in`]: `Este recurso não está disponível em`,
  [`Stopping`]: `Parando`,
  [`The user with username {username} already exists in the user registry.`]: `Já existe um nome do usuário {username} no registro do usuário.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Há mudanças na API que não estão em execução. Publique novamente esta API no catálogo do Ambiente de simulação.`,
  [`There are no plans available for subscription.`]: `Não há planos disponíveis para assinatura.`,
  [`There are no attributes yet.`]: `Ainda não há atributos.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Digite para incluir organizações de consumidores ao grupo`,
  [`Type to add groups to the organization`]: `Digite para incluir grupos à organização`,
  [`Type to add organizations from {catalog}`]: `Digite para incluir organizações no {catalog}`,
  [`Type to add organizations or groups`]: `Digite para incluir organizações ou grupos`,
  [`Type to add organizations or groups from {catalog}`]: `Digite para incluir organizações ou grupos no {catalog}`,
  [`Type to add {title} to groups`]: `Digite para incluir {title} aos grupos`,
  [`Update Rate Limits`]: `Atualizar Limites de Taxa`,
  [`Upload the YAML file with configuration`]: `Faça upload do arquivo YAML com a configuração`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Use a amostra abaixo como um modelo para configurar o servidor GraphQL`,
  [`User managed`]: `Gerenciado pelo usuário`,
  [`URL {url} does not contain valid OpenAPI.`]: `A URL {url} não contém uma OpenAPI válida.`,
  [`Validating`]: `Validando`,
  [`View analytics and performance metrics for your APIs`]: `Visualize métricas de análise e de desempenho para as APIs`,
  [`View and add custom policies to the catalog`]: `Visualizar e incluir políticas customizadas no catálogo`,
  [`View and add custom exentions to the catalog`]: `Visualizar e incluir extensões customizadas no catálogo`,
  [`View user registries and OAuth providers`]: `Visualize registros do usuário e provedores OAuth`,
  [`View your application and manage API credentials`]: `Visualize o aplicativo e gerencie as credenciais da API`,
  [`Welcome to the API Connect Trial`]: `Bem-vindo à avaliação do API Connect`,
  [`You haven't added any APIs`]: `Você não incluiu nenhuma API`,
  [`You currently don’t have any {resource}.`]: `Atualmente, você não possui {resource}.`,
  [`plans to migrate`]: `planos para migrar`,
  [`gateway extensions`]: `extensões de gateway`,
  [`application`]: `aplicativo`,
  [`application authentication source`]: `origem de autenticação do aplicativo`,
  [`API or product`]: `API ou produto`,
  [`base endpoints`]: `terminais de base`,
  [`categories`]: `categorias`,
  [`certificates`]: `certificados`,
  [`channels`]: `canais`,
  [`consumer organizations`]: `organizações de consumidores`,
  [`consumer organization group`]: `grupo de organizações de consumidores`,
  [`consumer organizations or groups`]: `organizações ou grupos de consumidores`,
  [`correlation ids`]: `IDs de correlação`,
  [`cors policy`]: `política do CORS`,
  [`definitions`]: `definições`,
  [`examples`]: `exemplos`,
  [`gateway services`]: `serviços de gateway`,
  [`groups`]: `grupos`,
  [`headers`]: `headers`,
  [`links`]: `links`,
  [`member`]: `Membro`,
  [`messages`]: `mensagens`,
  [`message traits`]: `traços de mensagens`,
  [`operation traits`]: `traços de operações`,
  [`parameter definitions`]: `definições de parâmetro`,
  [`parameters`]: `Parâmetros de Monitoramento`,
  [`path`]: `caminho`,
  [`properties`]: `propriedades`,
  [`provider organizations`]: `organizações do provedor`,
  [`request bodies`]: `corpos da solicitação`,
  [`response definitions`]: `definições de resposta`,
  [`responses`]: `Respostas de`,
  [`role`]: `função`,
  [`scope`]: `Escopo`,
  [`scopes`]: `escopos`,
  [`schemas`]: `esquemas`,
  [`schema definition`]: `definição de esquema`,
  [`schemes list`]: `lista de esquemas`,
  [`security definitions`]: `definições de segurança`,
  [`security schemes`]: `esquemas de segurança`,
  [`servers`]: `servidores`,
  [`services`]: `Serviços do`,
  [`tags`]: `marcações`,
  [`tags and external documentation`]: `tags e documentação externa`,
  [`target services`]: `serviços de destino`,
  [`tasks`]: `tarefas`,
  [`user registries`]: `registros do usuário`,
  [`HTTP header`]: `cabeçalho HTTP`,
  [`You currently don’t have any application authentication source.`]: `Atualmente, você não tem nenhuma fonte de autenticação de aplicativos.`,
  [`You currently don’t have any API or product.`]: `Atualmente, você não tem nenhuma API ou produto.`,
  [`You currently don’t have any API user registries`]: `Atualmente, você não tem registros do usuário da API`,
  [`You currently don’t have any APIs.`]: `Atualmente, você não tem nenhuma API.`,
  [`You currently don’t have any TLS client profiles`]: `Atualmente, você não tem perfis de cliente TLS`,
  [`You currently don’t have any base endpoints.`]: `Atualmente, você não tem nenhum terminal de base.`,
  [`You currently don’t have any catalog properties.`]: `Atualmente, você não tem nenhuma propriedade de catálogo.`,
  [`You currently don’t have any categories.`]: `Atualmente, você não tem nenhuma categoria.`,
  [`You currently don’t have any certificates.`]: `Atualmente, você não tem nenhum certificado.`,
  [`You currently don’t have any channels.`]: `Atualmente, você não tem nenhum canal.`,
  [`You currently don’t have any consumer organizations.`]: `Atualmente, você não tem nenhuma organização de consumidores.`,
  [`You currently don’t have any consumer organizations or groups.`]: `Atualmente, você não tem nenhuma organização ou grupo de consumidores.`,
  [`You currently don’t have any consumes.`]: `Atualmente, você não tem nenhum consumo.`,
  [`You currently don’t have any correlation ids.`]: `Atualmente você não tem nenhuma ids de correlação.`,
  [`You currently don’t have any cors policy.`]: `Atualmente você não tem nenhuma política de coros.`,
  [`You currently don’t have any definitions.`]: `Atualmente, você não tem nenhuma definição.`,
  [`You currently don’t have any examples.`]: `Atualmente, você não tem nenhum exemplo.`,
  [`You currently don’t have any gateway services.`]: `Atualmente, você não tem nenhum serviço de gateway.`,
  [`You currently don’t have any groups.`]: `Atualmente, você não tem nenhum grupo.`,
  [`You currently don’t have any headers.`]: `Atualmente, você não tem nenhum cabeçalho.`,
  [`You currently don’t have any keystore.`]: `Atualmente você não tem nenhum keystore.`,
  [`You currently don’t have any links.`]: `Atualmente, você não tem nenhum link.`,
  [`You currently don’t have any messages.`]: `Atualmente, você não tem nenhuma mensagem.`,
  [`You currently don’t have any message traits.`]: `Atualmente, você não tem nenhum traço de mensagem.`,
  [`You currently don’t have any OAuth providers`]: `Atualmente, você não tem nenhum provedor OAuth`,
  [`You currently don’t have any operation traits.`]: `Atualmente você não tem nenhum traço de operação.`,
  [`You currently don’t have any parameter definitions.`]: `Atualmente você não tem nenhuma definição de parâmetro.`,
  [`You currently don’t have any parameters.`]: `Atualmente, você não tem parâmetros.`,
  [`You currently don’t have any preserved request header.`]: `Atualmente você não tem nenhum cabeçalho de solicitação preservado.`,
  [`You currently don’t have any preserved response header.`]: `Atualmente você não tem nenhum cabeçalho de resposta preservado.`,
  [`You currently don’t have any produces.`]: `Atualmente, você não tem nenhuma produção.`,
  [`You currently don’t have any properties.`]: `Atualmente, você não tem nenhuma propriedade.`,
  [`You currently don’t have any provider organizations.`]: `Atualmente, você não tem nenhuma organização do provedor.`,
  [`You currently don’t have any request bodies.`]: `Atualmente, você não tem nenhum corpo de solicitação.`,
  [`You currently don’t have any response definitions.`]: `Atualmente você não tem nenhuma definições de resposta.`,
  [`You currently don’t have any responses.`]: `Atualmente, você não tem nenhuma resposta.`,
  [`You currently don’t have any scopes.`]: `Atualmente, você não tem nenhum escopo.`,
  [`You currently don’t have any schemas.`]: `Atualmente, você não tem nenhum esquema.`,
  [`You currently don’t have any schemes list.`]: `Atualmente você não tem nenhuma lista de esquemas.`,
  [`You currently don’t have any security definitions.`]: `Atualmente, você não tem definições de segurança.`,
  [`You currently don’t have any security schemes.`]: `Atualmente você não tem nenhum esquema de segurança.`,
  [`You currently don’t have any servers.`]: `Atualmente, você não tem nenhum servidor.`,
  [`You currently don’t have any services.`]: `Atualmente, você não tem nenhum serviço.`,
  [`You currently don’t have any tags.`]: `Atualmente, você não tem nenhuma tag.`,
  [`You currently don’t have any tags and external documentation.`]: `Atualmente, você não tem tags e documentação externa.`,
  [`You currently don’t have any target services.`]: `Atualmente você não tem nenhum serviço de destino.`,
  [`You currently don’t have any tasks.`]: `Atualmente, você não tem nenhuma tarefa.`,
  [`You currently don’t have any truststore.`]: `Atualmente você não tem nenhum armazenamento confiável.`,
  [`You currently don’t have any user registries.`]: `Atualmente, você não tem nenhum registro do usuário.`,
  [`You currently don’t have any extensions.`]: `Atualmente, você não tem nenhuma extensão.`,
  [`You currently don’t have any HTTP header.`]: `Atualmente, você não tem nenhum cabeçalho de HTTP.`,
  [`Your account is being activated. This may take a while...`]: `Sua conta está sendo ativada. Isso pode demorar um pouco...`,
  [`all of`]: `todos de`,
  [`any of`]: `qualquer um de`,
  [`calls`]: `chamadas`,
  [`ciphers`]: `cifras`,
  [`extensions`]: `Extensões`,
  [`one of`]: `um de`,
  [`shared`]: `compartilhada`,
  [`stopping`]: `encerrando`,
  [`to:`]: `para:`,
  [`Key`]: `Chave`,
  [`kind`]: `tipo`,
  [`Trace`]: `Rastreio`,
  [`Http Status`]: `Status Http`,
  [`Parsed`]: `Analisado`,
  [`Raw`]: `Linha`,
  [`Showing Full Trace`]: `Mostrando o rastreio completo`,
  [`Show Entire Trace`]: `Mostrar o rastreio inteiro`,
  [`Show advanced`]: `Mostrar avançado`,
  [`Advanced`]: `Avançado`,
  [`Sending Request`]: `Enviando solicitação`,
  [`Please send a request above`]: `Envie uma solicitação acima`,
  [`Please send a request above.`]: `Envie uma solicitação acima.`,
  [`Authentication Type`]: `Tipo de autenticação`,
  [`You don't currently have any response`]: `Atualmente, você não tem nenhuma resposta`,
  [`You don't currently have any trace.`]: `Atualmente, você não tem nenhum rastreio.`,
  [`No response received`]: `Nenhuma resposta recebida`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Clicar em “Abrir link para aceitar exceção” abrirá o servidor em uma nova guia. Se o navegador exibe uma questão certificada, é possível escolher aceitá-la e voltar aqui para testar novamente.`,
  [`Open link to accept exception`]: `Abrir link para aceitar exceção`,
  [`Back to {name}`]: `Voltar para {name}`,
  [`0 seconds`]: `0 segundos`,
  [`close.menu`]: `Fechar menu`,
  [`open.menu`]: `Abrir o Menu`,
  [`Referenced from "{ref}"`]: `Referenciado de "{ref}"`,
  [`Go to {definition}`]: `Acessar {definition}`,
  [`General`]: `Geral`,
  [`Servers`]: `Servidores`,
  [`External Docs`]: `Docs externos`,
  [`Components`]: `Componentes`,
  [`Schemas`]: `Esquemas`,
  [`Responses`]: `Respostas`,
  [`Examples`]: `Exemplos`,
  [`Request Bodies`]: `Corpos da solicitação`,
  [`Security Schemes`]: `Esquemas de segurança`,
  [`Links`]: `Links`,
  [`Callbacks`]: `Retornos de chamada`,
  [`Terms of Service`]: `Termos de serviço`,
  [`Contact Name`]: `Nome do Contato`,
  [`Contact URL`]: `URL do Contato`,
  [`Contact Email`]: `e-mail do Contato`,
  [`Contact sales`]: `Entrar em contato com Vendas`,
  [`License Name`]: `Nome da Licença`,
  [`License URL`]: `URL da Licença`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `Aceitar as mensagens otimizadas por MTOM/XOP`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `token`,
  [`Tag`]: `Identificação`,
  [`Request Body`]: `Corpo da Solicitação`,
  [`Security Requirements`]: `Requisitos de segurança`,
  [`Located In`]: `Localizado em`,
  [`Example Value`]: `Valor de exemplo`,
  [`External Value`]: `Valor externo`,
  [`Server`]: `Servidor`,
  [`Server Description`]: `Descrição do servidor`,
  [`Server Variables`]: `Variáveis do servidor`,
  [`Format`]: `Formatar`,
  [`Pattern`]: `Padrão`,
  [`Multiple Of`]: `Múltiplo de`,
  [`Multiple non null slicing arguments`]: `Vários argumentos de fatiamento não nulo`,
  [`Missing slicing arguments`]: `Argumentos de fatiamento ausentes`,
  [`Missing sized fields`]: `Campos dimensionados ausentes`,
  [`Maximum`]: `Máximo`,
  [`Exclusive Maximum`]: `Máximo exclusivo`,
  [`Minimum`]: `Mínimo`,
  [`Exclusive Minimum`]: `Mínimo exclusivo`,
  [`Max Length`]: `Comprimento máximo`,
  [`Min Length`]: `Comprimento mínimo`,
  [`Max Items`]: `Máximo de itens`,
  [`Min Items`]: `Mínimo de itens`,
  [`Unique Items`]: `Itens exclusivos`,
  [`Max Properties`]: `Propriedades máximas`,
  [`Min Properties`]: `Propriedades mínimas`,
  [`Required Properties`]: `Propriedades obrigatórias`,
  [`Required Property Name`]: `Nome da propriedade obrigatória`,
  [`Enum`]: `Enumeração`,
  [`Enum Value`]: `Valor numérico`,
  [`Nullable`]: `Anulável`,
  [`Read Only`]: `Somente Leitura`,
  [`Write Only`]: `Somente gravação`,
  [`api`]: `api`,
  [`version`]: `versão`,
  [`username`]: `Nome de usuário`,
  [`password`]: `Senha`,
  [`service`]: `serviço`,
  [`Allows Empty Value`]: `Permite valor vazio`,
  [`Style`]: `Estilo`,
  [`Explode`]: `Explodir`,
  [`Allow Reserved`]: `Permitir reservado`,
  [`Allow WebSocket Upgrade`]: `Permitir upgrade do WebSocket`,
  [`Ref`]: `Referência`,
  [`Operation ID`]: `ID da Operação`,
  [`Parameter`]: `Parâmetro`,
  [`Gateway and portal settings`]: `Configurações de gateway e de portal`,
  [`Targets`]: `Destinos`,
  [`Catalog Properties`]: `Propriedades do Catálogo`,
  [`catalog properties`]: `Propriedades do catálogo`,
  [`Attachments`]: `Conexões`,
  [`Activity Log`]: `Log de atividades`,
  [`API Type`]: `Tipo de API`,
  [`Phase`]: `Fase`,
  [`Enable CORS`]: `Ativar CORS`,
  [`CORS Policy`]: `Política do CORS`,
  [`Allowed Origins`]: `Origens permitidas`,
  [`Allow Credentials`]: `Permitir credenciais`,
  [`ID`]: `ID`,
  [`Success Content`]: `Conteúdo de sucesso`,
  [`Error Content`]: `Conteúdo de erro`,
  [`Attachment`]: `Anexo`,
  [`Link`]: `Link`,
  [`Operation Ref`]: `Referência da operação`,
  [`TRACE`]: `RASTREIO`,
  [`No Security Requirements available`]: `Nenhum Requisito de segurança disponível`,
  [`Create a security scheme`]: `Criar um esquema de segurança`,
  [`Create a Security Requirement`]: `Criar um requisito de segurança`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Solicitar um dos Requisitos de Segurança a seguir. Se estiver desmarcado, a segurança será opcional.`,
  [`Require wrappers on fault details specified by type`]: `Requerer wrappers em detalhes de falha especificados por tipo`,
  [`There are no "Servers"`]: `Não há "Servidores"`,
  [`Write`]: `Escrever`,
  [`Server Variable`]: `Variável do servidor`,
  [`Security Requirement`]: `Requisito de segurança`,
  [`Security Scheme Type`]: `Tipo de esquema de segurança`,
  [`Security Scheme`]: `Esquema de segurança`,
  [`OAuth Flows`]: `Fluxos OAuth`,
  [`Implicit Flow`]: `Fluxo implícito`,
  [`Password Flow`]: `Fluxo de senha`,
  [`Client Credentials Flow`]: `Fluxo das credenciais do cliente`,
  [`Authorization Code Flow`]: `Fluxo de códigos de autorização`,
  [`Refresh URL`]: `URL de Atualização`,
  [`schema`]: `esquema`,
  [`Allow Empty Value`]: `Permitir valor vazio`,
  [`Encoding`]: `Codificação`,
  [`This field is required`]: `Este campo é obrigatório`,
  [`Temporary token`]: `Token temporário`,
  [`Access token`]: `Token de acesso`,
  [`ID token`]: `Token de ID`,
  [`No Security Schemes available`]: `Nenhum Esquema de segurança disponível`,
  [`Security Scheme (Key)`]: `Esquema de segurança (chave)`,
  [`There are no "{label}"`]: `Não há nenhum "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `Deve-se selecionar pelo menos um esquema para definir um Requisito de segurança`,
  [`Select one or more Security Schemes`]: `Selecione um ou mais Esquemas de segurança`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `Nenhum Escopo do OAuth disponível para o Esquema de segurança "{key}"`,
  [`Create a Scope`]: `Criar um Escopo`,
  [`Remove this Security Requirement`]: `Remover este Requisito de segurança`,
  [`Edit this Security Requirement`]: `Editar este Requisito de segurança`,
  [`Invalid Security Scheme Reference`]: `Referência inválida do Esquema de segurança`,
  [`The following Security Schemes do not exist!`]: `Os Esquemas de segurança a seguir não existem.`,
  [`Edit source`]: `Editar origem`,
  [`OR`]: `OU`,
  [`Required Security Schemes`]: `Esquemas de segurança necessários`,
  [`Optional Security`]: `Segurança opcional`,
  [`Optional custom expression (one per line)`]: `Expressão customizada opcional (uma por linha)`,
  [`Flow Type`]: `Tipo de fluxo`,
  [`OAuth2 Security Scheme`]: `Esquema de segurança do OAuth2`,
  [`Only source editing is supported.`]: `Apenas a edição da origem é suportada.`,
  [`Unsupported Field.`]: `Campo não suportado.`,
  [`Select an option`]: `Selecione uma opção`,
  [`Select {labelText}`]: `Selecione {labelText}`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" já é usado'`,
  [`Referenced from "{originalRef}"`]: `Referenciado por meio de "{originalRef}"`,
  [`Go to {label}`]: `Acesse {label}`,
  [`Delete "{label}"`]: `Exclua "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `Tem certeza de que deseja excluir "{label}"?`,
  [`No recent items found`]: `Nenhum item recente localizado`,
  [`Found {numDocs} results ({took} seconds)`]: `{numDocs} resultados localizados ({took} segundos)`,
  [`No Consumer Organizations found`]: `Nenhuma organização do consumidor localizada`,
  [`No Catalog Products found`]: `Nenhum produto de catálogo localizado`,
  [`No APIs found`]: `Nenhuma API localizada`,
  [`No Catalogs found`]: `Nenhum catálogo localizado`,
  [`No Spaces found`]: `Nenhum espaço localizado`,
  [`No results found`]: `Nenhum resultado localizado`,
  [`No Catalog APIs found`]: `Nenhuma API de Catálogo localizada`,
  [`No Catalogs found matching query "{query}"`]: `Nenhum Catálogo localizado que corresponda à consulta "{query}"`,
  [`No APIs found matching query "{query}"`]: `Nenhuma API localizada correspondente à consulta "{query}"`,
  [`No Subscriptions found`]: `Nenhuma assinatura localizada`,
  [`No Plans found matching query "{query}"`]: `Nenhum plano localizado correspondente à consulta "{query}"`,
  [`No Subscriptions found matching query "{query}"`]: `Nenhuma assinatura localizada correspondente à consulta "{query}"`,
  [`No Catalog Products found matching query "{query}"`]: `Nenhum Produto de Catálogo localizado correspondente à consulta "{query}"`,
  [`No Catalog APIs found matching query "{query}"`]: `Nenhuma API de Catálogo localizada correspondente à consulta "{query}"`,
  [`No Consumer Organizations found matching query "{query}"`]: `Nenhuma organização do consumidor localizada correspondente à consulta "{query}"`,
  [`No Applications found matching query "{query}"`]: `Nenhum aplicativo localizado correspondente à consulta "{query}"`,
  [`APIs per page`]: `APIs por página`,
  [`Catalogs per page`]: `Catálogos por página`,
  [`items per page`]: `Itens por página`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} de {total} APIs`,
  [`{min}-{max} of {total} API`]: `{min}-{max} de {total} API`,
  [`Products per page`]: `Produtos por página`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `O produto deve ser publicado no catálogo para poder configurar uma nova integração de cobrança`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} de {total} produtos`,
  [`{min}-{max} of {total} Product`]: `{min}-{max} de produto {total}`,
  [`Catalog Products per page`]: `Produtos do catálogo por página`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} de {total} produtos do catálogo`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{max} de produto catálogo {total}`,
  [`Plans per page`]: `Planos por página`,
  [`Policies per page`]: `Políticas por página`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} de {total} planos`,
  [`{min}-{max} of {total} Plan`]: `{min}-{max} de plano {total}`,
  [`Catalog APIs per page`]: `APIs de catálogo por página`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} de {total} APIs de catálogo`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{max} de API do catálogo {total}`,
  [`Subscriptions per page`]: `Assinaturas por página`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} de {total} assinaturas`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{max} de assinatura {total}`,
  [`Consumer Organizations per page`]: `Organizações do consumidor por página`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} de {total} organizações do consumidor`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{max} de organização do consumidor {total}`,
  [`Applications per page`]: `Aplicativos por página`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} de {total} aplicativos`,
  [`{min}-{max} of {total} Application`]: `{min}-{max} de aplicação {total}`,
  [`Histories per page`]: `Históricos por página`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} de {total} históricos`,
  [`{min}-{max} of {total} History`]: `{min}-{max} de histórico {total}`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Nenhum plano localizado no qual possa ser inscrito. Um Produto deve estar no estado "publicado" antes que uma assinatura possa ser criada. Um aplicativo pode se inscrever apenas em um plano em um produto.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Mostrando APIs "{draftApiTitle}:{draftApiVersion}" publicadas`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Mostrando a organização do consumidor para "{appTitle}"`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Mostrando assinaturas para "{productTitle}:{productVersion}"`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Mostrando planos para "{productTitle}:{productVersion}"`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `Mostrando APIs para "{productTitle}:{productVersion}"`,
  [`Showing Product for "{planTitle}"`]: `Mostrando produto para "{planTitle}"`,
  [`Showing APIs for "{planTitle}"`]: `Mostrando APIs para "{planTitle}"`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Mostrando assinaturas para o Plano: "{docTitle}" no Produto: "{docFoundIn}"`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Mostrando planos para "{apiTitle}:{apiVersion}"`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Mostrando APIs de rascunho para "{catalogApiTitle}:{catalogApiVersion}"`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Mostrando históricos para "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Mostrando assinaturas para "{appTitle}"`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Mostrando aplicativos para "{consumerOrgTitle}"`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Mostrando assinaturas para "{consumerOrgTitle}"`,
  [`Showing Application for selected Subscription`]: `Mostrando o aplicativo para a assinatura selecionada`,
  [`Showing Plan for selected Subscription`]: `Mostrando o plano para a assinatura selecionada`,
  [`Showing Product for selected Subscription`]: `Mostrando o produto para a assinatura selecionada`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Selecione APIs de Rascunho para incluir no Produto "{productTitle}"`,
  [`Product Version`]: `Versão de produto`,
  [`OAuth Providers`]: `Provedores OAuth`,
  [`Application Type`]: `Tipo de aplicativo`,
  [`Client IDs`]: `IDs de cliente`,
  [`Save as New Version`]: `Salvar como Nova versão`,
  [`Update Selected APIs`]: `Atualizar APIs selecionadas`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. A API{titleAndVersion}será excluída permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. O Produto {titleAndVersion} será excluído permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. O Produto de catálogo {titleAndVersion} será excluído permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. A organização do consumidor{titleAndVersion}será excluída permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. O grupo de consumidores {titleAndVersion} será excluído permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. A política{titleAndVersion}será excluída permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. A assinatura{titleAndVersion}será excluída permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. O Catálogo{titleAndVersion} será excluído permanentemente. Deseja continuar?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. O espaço {titleAndVersion} será excluído permanentemente. Deseja continuar?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `O produto de catálogo {titleAndVersion} será republicado. Deseja continuar?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `O produto de catálogo {titleAndVersion} será desativado. Deseja continuar?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `O produto de catálogo {titleAndVersion} será descontinuado. Deseja continuar?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `O produto de catálogo {titleAndVersion} será remontado. Deseja continuar?`,
  [`Put Online`]: `Colocar on-line`,
  [`Take Offline`]: `Colocar Offline`,
  [`View Subscriptions`]: `Visualizar assinaturas`,
  [`View Plans`]: `Visualizar planos`,
  [`No Tasks found`]: `Nenhuma tarefa localizada`,
  [`Tasks per page`]: `Tarefas por página`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} de {total} tarefas`,
  [`{min}-{max} of {total} Task`]: `{min}-{max} de tarefa {total}`,
  [`Decline and Send`]: `Recusar e enviar`,
  [`View Applications`]: `Exibir aplicativos`,
  [`Publish (Preserve Subscriptions)`]: `Publicar (preservar assinaturas)`,
  [`Update APIs`]: `Atualizar APIs`,
  [`View in Catalogs`]: `Visualizar em catálogos`,
  [`View Consumer Organization`]: `Visualizar a organização do consumidor`,
  [`View Application`]: `Visualizar a Requisição`,
  [`View Plan`]: `Visualizar Plano`,
  [`View Product`]: `Visualizar produto`,
  [`Create Subscription`]: `Criar assinatura`,
  [`No Applications found`]: `Nenhum aplicativo localizado`,
  [`Select a Plan to Create a Subscription to`]: `Selecione um Plano para o qual criar uma assinatura`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Esta ação não é reversível. O Aplicativo {titleAndVersion} será excluído permanentemente. Deseja continuar?`,
  [`Save as new version`]: `Salvar como Nova versão`,
  [`Filter selected catalogs`]: `Filtrar catálogos selecionados`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Selecionar APIs de rascunho para incluir no produto "{productTitle}"`,
  [`Update selected APIs`]: `Atualizar APIs selecionadas`,
  [`Catalog API`]: `API de catálogo`,
  [`Catalog APIs`]: `APIs de catálogo`,
  [`View Products`]: `Visualizar produtos`,
  [`View Draft APIs`]: `Visualizar as APIs de rascunho`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Mostrando referência de Produtos "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `Nenhum plano localizado`,
  [`View APIs`]: `Visualizar APIs`,
  [`Document type tabs`]: `Guias de tipo de documento`,
  [`No Products found`]: `Nenhum Produto localizado`,
  [`Consumer Organization`]: `Organização do consumidor`,
  [`Consumer Organizations`]: `organizações de consumidores`,
  [`Catalog Product`]: `Produto de catálogo`,
  [`Catalog Products`]: `Produtos do catálogo`,
  [`Search for a Product and Plan`]: `Procurar um Produto e um Plano`,
  [`OpenAPI Version`]: `Versão do OpenAPI`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (de REST, GraphQL ou SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (do tópico do Kafka)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `Criar AsyncAPI (do tópico do Kafka)`,
  [`An error occurred during file upload`]: `Ocorreu um erro durante o upload de arquivo`,
  [`Delete uploaded file`]: `Excluir arquivo transferido por upload`,
  [`Copied!`]: `Copiado!`,
  [`Uploading`]: `Fazendo upload`,
  [`Validation icon`]: `Ícone de validação`,
  [`Validation error`]: `Erro de validação`,
  [`Uploading image..`]: `Fazendo upload da imagem.`,
  [`Unsupported media type {providedFileType}`]: `Tipo de mídia não suportado {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Selecione um arquivo suportado {expectedFileTypes} e tente novamente`,
  [`Pasted`]: `Colado`,
  [`Enter the API summary details.`]: `Insira os detalhes de resumo da API.`,
  [`Define your Kafka cluster connection details.`]: `Defina os seus detalhes de conexão de cluster do Kafka.`,
  [`Channel`]: `Canal`,
  [`Describe your Kafka topic and its associated schema.`]: `Descreva o seu tópico do Kafka e o seu esquema associado.`,
  [`AsyncAPI specification version`]: `Versão de especificação da AsyncAPI`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Um nome que descreve a API e ajuda os desenvolvedores a descobri-la no Portal do Desenvolvedor.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Uma versão para a sua definição de API. A combinação de Nome e Versão identifica exclusivamente a sua definição de API.`,
  [`Description (optional)`]: `Descrição (opcional)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Apresente a sua API e forneça informações úteis aos desenvolvedores que a consomem.`,
  [`Summary (optional)`]: `Resumo (opcional)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Um breve resumo dos eventos contidos nesta API. Máximo de 1.000 caracteres.`,
  [`Bootstrap servers`]: `Servidores de autoinicialização`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Uma lista dos corretores do Kafka em seu cluster, separados por vírgulas.`,
  [`Secure the API using an API Key and Secret`]: `Proteger a API usando uma chave de API e segredo`,
  [`The credential provided should be in SASL-PLAIN format`]: `A credencial fornecida deve estar no formato SASL-PLAIN`,
  [`Publish this API:`]: `Publicar esta API:`,
  [`Creates a product`]: `Cria um produto`,
  [`Associates this API to the product`]: `Associa esta API ao produto`,
  [`Publish the product to the Sandbox catalog`]: `Publique o produto no catálogo do Ambiente de simulação`,
  [`Associates the product to the test app`]: `Associa o produto ao app de teste`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Um esquema no formato "{fileType}" foi transferido por upload e validado com êxito.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `O arquivo transferido por upload não é um esquema Avro válido e não será incluído como parte de sua API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Resposta do servidor: "{err}". Clique em "Voltar" para retornar para a página de definição da API.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Resposta do servidor: "{err}". Clique em "Pronto" para sair do assistente.`,
  [`Topic name`]: `Nome do tópico`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `O nome do tópico que você deseja expor como uma parte desta API. Um tópico é um fluxo nomeado de mensagens.`,
  [`Schema for message body in .avsc format (optional)`]: `Esquema para o corpo da mensagem em formato .avsc (opcional)`,
  [`Drag and drop files here, or click to upload`]: `Arraste e solte arquivos aqui ou clique para fazer upload`,
  [`About this step`]: `Sobre esta etapa`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Os eventos podem ser descritos como APIs assíncronas porque os consumidores recebem eventos à medida que se tornam disponíveis. APIs síncronas, por outro lado, como REST, só recebem respostas quando as solicitam.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `APIs assíncronas acionadas por evento são documentadas usando a especificação da AsyncAPI.`,
  [`API secret`]: `Segredo da API`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Definição de AsyncAPI 2.1.0 gerada`,
  [`API creating`]: `Criação de API`,
  [`An error occurred while creating your document`]: `Ocorreu um erro ao criar o seu documento`,
  [`Publishing your API`]: `Publicando sua API`,
  [`Your API has been published`]: `Sua API foi publicada`,
  [`An error occurred while publishing your API`]: `Ocorreu um erro ao publicar sua API`,
  [`Configure security for this API`]: `Configurar segurança para esta API`,
  [`Cluster connection security`]: `Segurança de conexão do cluster`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Forneça as credenciais que os Serviços de Gateway de Evento usariam para se conectar ao seu cluster ao manipular solicitações para esta API. É possível mudar esses valores posteriormente no editor. Para obter informações adicionais, consulte a documentação sobre criação de credenciais.`,
  [`SASL Username`]: `Nome do usuário de SASL`,
  [`SASL Password`]: `Senha de SASL`,
  [`Transport CA certificate (.pem file - optional)`]: `Certificado de autoridade de certificação de transporte (arquivo .pem - opcional)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Se fornecido, ele será usado pelo Serviço de Gateway de Evento para fornecer criptografia TLS para a comunicação entre o gateway e o cluster do Kafka.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Descreva os detalhes da API de sua origem de eventos do Apache Kafka para gerar um documento de AsyncAPI.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `Em seguida, é possível publicar o documento de AsyncAPI para expor a origem de eventos aos desenvolvedores de aplicativos, que podem configurar seus aplicativos para assinar o fluxo relacionado de eventos.`,
  [`Set up security for your API if you want to control who can access it.`]: `Configure a segurança para a sua API se você deseja controlar quem pode acessá-la.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `O acesso à sua API será gerenciado por um Serviço de Gateway de Evento que verifica uma chave de API e um segredo válidos.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Os gateways requerem que os clientes criptografem sua comunicação com o TLS.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Depois que sua API for criada, clique em 'Editar API' para incluir mais detalhes, como detalhes de segurança, para se conectar ao seu cluster do Kafka por meio de seu Serviço de Gateway de Evento.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `Em seguida, é possível publicar a sua API, disponibilizando-a para desenvolvedores que podem, então, descobrir e solicitar o acesso à API e usar as informações para configurar seus aplicativos para consumir na origem de eventos.`,
  [`Pending backend response`]: `Resposta de back-end pendente`,
  [`Complete`]: `Complete`,
  [`Rate limiting is only applied to enforced APIs`]: `A limitação de taxa é aplicada apenas às APIs impostas`,
  [`No Plans Available`]: `Nenhum plano disponível`,
  [`Modal content is not provided`]: `O conteúdo do modal não foi fornecido`,
  [`Modal heading is not provided`]: `O título do modal não foi fornecido`,
  [`Edit Rate Limit`]: `Editar limite de taxa`,
  [`External Documentation`]: `Documentação externa`,
  [`No Label Available`]: `Nenhum rótulo disponível`,
  [`optional`]: `Opcional`,
  [`Uploading image...`]: `Fazendo upload de imagem...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Anexar arquivos arrastando-os, soltando-os, selecionando-os ou colando-os.`,
  [`Response name`]: `Nome da resposta`,
  [`Click Add to add a new {resource}.`]: `Clique em Incluir para incluir um novo {resource}.`,
  [`Schema Name (Key)`]: `Nome do esquema (chave)`,
  [`Xml`]: `XML`,
  [`Namespace`]: `Espaço de Nomes`,
  [`Attribute`]: `Atributo`,
  [`Wrapped`]: `Agrupado`,
  [`Example`]: `Exemplo:`,
  [`Example Name (Key)`]: `Nome do exemplo (chave)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" já é usado`,
  [`Name (Key)`]: `Nome (chave)`,
  [`Content Type (Key)`]: `Tipo de conteúdo (chave)`,
  [`Header name`]: `Nome do cabeçalho`,
  [`Reference`]: `Referência`,
  [`Security Scheme Name (Key)`]: `Nome do esquema de segurança (chave)`,
  [`Choose a Reference`]: `Escolha uma referência`,
  [`Reference from`]: `Referência de`,
  [`Content Type`]: `Tipo de conteúdo`,
  [`array`]: `matriz`,
  [`boolean`]: `booleano`,
  [`integer`]: `número inteiro`,
  [`number`]: `número`,
  [`object`]: `objeto`,
  [`string`]: `cadeia`,
  [`query`]: `consulta`,
  [`cookie`]: `cookie`,
  [`matrix`]: `matriz`,
  [`label`]: `rótulo`,
  [`simple`]: `simples`,
  [`form`]: `formulário`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `Provedor OAuth (nome)`,
  [`Authenticate using User Registry (name)`]: `Autenticar usando registro do usuário (nome)`,
  [`Bearer Format`]: `Formato do portador`,
  [`Server Variable Name`]: `Nome da variável do servidor`,
  [`Verb (Key)`]: `Verbo (chave)`,
  [`Parameter Definitions`]: `Definições de parâmetros`,
  [`Path Parameters`]: `Parâmetros de caminho`,
  [`Media Type List`]: `Lista de tipos de mídia`,
  [`Medium`]: `Média`,
  [`Schemes List`]: `Lista de esquemas`,
  [`Small`]: `&amp;amp;Pequeno`,
  [`Response Definitions`]: `Definições de resposta`,
  [`Terms Of Service`]: `Termos de serviço`,
  [`Url`]: `URL`,
  [`Untitled Parameter`]: `Parâmetro sem título`,
  [`Parameter Name`]: `Nome do Parâmetro`,
  [`Mime Type`]: `Tipo MIME`,
  [`Async API`]: `API assíncrona`,
  [`Channels`]: `Canais`,
  [`Operation Traits`]: `Traços de operações`,
  [`Messages`]: `Mensagens`,
  [`Correlation IDs`]: `IDs de correlação`,
  [`Traits`]: `Traços`,
  [`Message Traits`]: `Traços de mensagens`,
  [`Bindings`]: `Ligações`,
  [`Server Bindings`]: `Ligações de servidores`,
  [`Channel Bindings`]: `Ligações de canais`,
  [`Operation Bindings`]: `Ligações de operações`,
  [`Message Bindings`]: `Ligações de mensagens`,
  [`Protocol Version`]: `Versão de protocolo`,
  [`Define multiple messages`]: `Definir várias mensagens`,
  [`Schema Format`]: `Formato de esquema`,
  [`Unsupported Field`]: `Campo não suportado`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Traço de operação`,
  [`Message Trait`]: `Traço de mensagem`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Use a política Alternância de operação para aplicar uma seção do conjunto a uma operação específica.`,
  [`HTML page`]: `Página HTML`,
  [`Rule`]: `Regra`,
  [`Origin`]: `Origem`,
  [`Property Name (Key)`]: `Nome da propriedade (chave)`,
  [`Catalog Name (Key)`]: `Nome do catálogo (chave)`,
  [`Property Overrides`]: `Substituições de propriedades`,
  [`Property Name`]: `Nome da Propriedade`,
  [`Property Value`]: `Valor da propriedade`,
  [`About subscriptions and plans`]: `Sobre assinaturas e planos`,
  [`Subscription Plan Name`]: `Nome do plano de assinatura`,
  [`Select plan`]: `Selecionar plano`,
  [`Select the plan to migrate application subscriptions from`]: `Selecione o plano do qual migrar assinaturas do aplicativo de`,
  [`Select subscriptions`]: `Selecionar assinaturas`,
  [`Select the application subscriptions to migrate`]: `Selecione as assinaturas do aplicativo para migrar`,
  [`Select the product to migrate application subscriptions to`]: `Selecione o produto para o qual migrar assinaturas do aplicativo`,
  [`Select the plan to migrate application subscriptions to`]: `Selecione o plano para o qual migrar assinaturas do aplicativo`,
  [`Message successfully sent to {sent} owner(s)`]: `Mensagem enviada com sucesso a {sent} proprietários`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} mensagens falharam ao enviar`,
  [`Failed email addresses`]: `Endereços de e-mail com falha`,
  [`Failed to save the API`]: `Falha ao salvar a API`,
  [`and {numExtra} more...`]: `e {numExtra} mais...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Copie para a área de transferência a lista de endereços de e-mail que falharam ao enviar`,
  [`From`]: `De`,
  [`Message Owner`]: `Proprietário da mensagem`,
  [`Message owner`]: `Proprietário da mensagem`,
  [`Message owners`]: `Proprietários da mensagem`,
  [`Message group`]: `Grupo de mensagens`,
  [`Select consumer organizations`]: `Selecionar organizações do consumidor`,
  [`Message consumer organization owners`]: `Proprietários da organização do consumidor de mensagens`,
  [`Message consumer organization owner`]: `Proprietário da organização do consumidor de mensagens`,
  [`Message provider organization owner`]: `Proprietário da organização do provedor de mensagem`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `O identificador do cluster do Kafka relacionado a esta política Chamar`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Uma lista separada por vírgula de pares de host/porta a serem usados para estabelecer conexões com o cluster do Kafka`,
  [`The username to use when connecting to the Kafka cluster.`]: `O nome de usuário a ser usado ao conectar-se ao cluster Kafka.`,
  [`The password to use when connecting to the Kafka cluster.`]: `A senha a ser usada ao conectar-se ao cluster Kafka.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Serviço de Gateway de Evento`,
  [`Event gateway management client keystore`]: `Keystore do cliente de gerenciamento de gateway de evento`,
  [`Edit Event gateway Service`]: `Editar o Serviço de Gateway de Evento`,
  [`Configure Event Gateway Service`]: `Configurar o Serviço de Gateway de Evento`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Configure um Serviço de Gateway de Evento para proteger e cumprir APIs assíncronas`,
  [`Catalogs with published product`]: `Catálogos com produto publicado`,
  [`Cluster Config ID`]: `ID de configuração de cluster`,
  [`SASL mechanism`]: `Mecanismo de SASL`,
  [`Security protocol`]: `Protocolo de segurança`,
  [`Transport CA certificate`]: `Certificado de autoridade de certificação de transporte`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `O tamanho máximo do arquivo é {size}. Os tipos de arquivo suportados são json, xml, yml, yaml & zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Os tipos de arquivo suportados são json, xml, yml & yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Os tipos de arquivo suportados são json, wsdl, xml, xsd, yml, yaml & zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Os tipos de arquivo suportados são json, wsdl, xml, yml & yaml.`,
  [`Select a file`]: `Selecione um arquivo`,
  [`or specify a file URL`]: `ou especifique uma URL de arquivo`,
  [`Generated OpenAPI {version} definition`]: `Definição de OpenAPI {version} gerada`,
  [`User registry type`]: `Tipo de registro do usuário`,
  [`User registry types`]: `Tipos de registro do usuário`,
  [`User registry type {title} has been created.`]: `O tipo de registro do usuário {title} foi criado.`,
  [`User registry type {title} has been updated.`]: `O tipo de registro do usuário {title} foi atualizado.`,
  [`Failed to create user registry type {title}.`]: `Falha ao criar o tipo de registro do usuário {title}.`,
  [`Failed to update user registry type {title}.`]: `Falha ao atualizar o tipo de registro do usuário {title}.`,
  [`Failed to update user registry {title}.`]: `Falha ao atualizar o registro do usuário {title}.`,
  [`Custom user registry`]: `Registro do usuário customizado`,
  [`Create custom user registry`]: `Criar registro do usuário customizado`,
  [`Edit custom user registry`]: `Editar registro do usuário customizado`,
  [`Create user registry type`]: `Criar tipo de registro do usuário`,
  [`Edit user registry type`]: `Editar tipo de registro do usuário`,
  [`Configure user authentication using a custom external user registry`]: `Configurar a autenticação do usuário usando um registro do usuário externo customizado`,
  [`Secured Endpoint`]: `Terminal protegido`,
  [`Configuration schema`]: `Esquema de configuração`,
  [`About custom user registries`]: `Sobre os registros de usuários customizados`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Os registros de usuários customizados podem ser usados para autenticar usuários no Portal do Desenvolvedor, mas não podem ser usados para proteger APIs. Para configurar um registro do usuário customizado como um recurso no API Manager, o diretório do usuário externo deve ser criado e estar disponível para uso com o seu ecossistema do API Connect.`,
  [`Update Wsdl`]: `Atualizar WSDL`,
  [`Update Existing WSDL service`]: `Atualizar serviço WSDL existente`,
  [`Format of selected WSDL has been successfully validated`]: `O formato do WSDL selecionado foi validado com sucesso`,
  [`Select the target WSDL file to update from`]: `Selecione o arquivo WSDL de destino por meio do qual atualizar`,
  [`Selected WSDL has been successfully uploaded`]: `O WSDL selecionado foi transferido por upload com sucesso`,
  [`Services available in selected API`]: `Serviços disponíveis na API selecionada`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Ativar os documentos legados do Open API Editor for OpenAPI 2.0 (descontinuado)`,
  [`Unknown`]: `Desconhecido`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleCatalogCount} catálogos ocultos devido à incompatibilidade com o tipo de gateway especificado na API ou no produto`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} espaços ocultos devido à incompatibilidade com o tipo de gateway especificado na API ou no produto`,
  [`Keep in mind`]: `Lembre-se`,
  [`Only one type of enforced APIs is allowed per product`]: `É permitido apenas um tipo de APIs impingidas por produto`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `{incompatibleAPIsCount} APIs não podem ser selecionadas devido à incompatibilidade com o tipo de gateway especificado no produto`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `Não foi possível selecionar {incompatibleProductsCount} produto(s) devido à incompatibilidade com o tipo de gateway especificado na API`,
  [`Unenforced`]: `Não impingidos`,
  [`Create a new product by adding APIs and plans.`]: `Crie um novo produto incluindo APIs e planos.`,
  [`Rate limits are only applied to enforced APIs.`]: `Os limites de taxa são aplicados apenas às APIs impingidas.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Inclua limites de plano e de API em seu produto. Os limites de plano se aplicam apenas às APIs impingidas e as APIs não impingidas serão ilimitadas.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Inclua limites de plano e de taxa em seu produto. Os planos se aplicam apenas às APIs impingidas e as APIs não impingidas serão ilimitadas.`,
  [`Delete plan`]: `Excluir plano`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Selecione o Serviço de Gateway de Evento para AsyncAPIs. Para todos os outros tipos de APIs, selecione o DataPower API Gateway ou a versão compatível com v5. Para atualizar essa configuração, primeiro remova as APIs associadas.`,
  [`Edit API list`]: `Editar lista de APIs`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `As APIs impingidas neste produto requerem mais de um tipo de gateway.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `O {apiType} impingido neste produto requer o {gatewayType}.`,
  [`OpenAPI 2.0 API(s)`]: `API(s) do OpenAPI 2.0`,
  [`OpenAPI 3.0 API(s)`]: `API(s) do OpenAPI 3.0`,
  [`AsyncAPI(s)`]: `AsyncAPI(s)`,
  [`Select the gateway type for this Product`]: `Selecione o tipo de gateway para este Produto`,
  [`Added default plan`]: `Plano padrão incluído`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Publique o produto no catálogo do ambiente de simulação. Isso associa o produto ao aplicativo de teste.`,
  [`cluster`]: `cluster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Mensagem do rejeitador`,
  [`Vendor extensions feature disabled`]: `Recurso de extensões do fornecedor desativado`,
  [`No configured gateways found`]: `Nenhum gateway configurado localizado`,
  [`No OpenAPI extensions associated with the configured gateway`]: `Nenhuma extensão do OpenAPI associada ao gateway configurado`,
  [`Extension`]: `Ramal`,
  [`Select the extensions to add to the API document`]: `Selecione as extensões a serem incluídas no documento da API`,
  [`(Operations: {operations})`]: `(Operações: {operations})`,
  [`Failed to get application`]: `Falha ao obter o aplicativo`,
  [`Failed to get credentials for application`]: `Falha ao obter as credenciais para o aplicativo`,
  [`Failed to get published product`]: `Falha ao obter o produto publicado`,
  [`Failed to get the published API`]: `Falha ao obter a API publicada`,
  [`API must be activated to use the Test tool`]: `A API deve ser ativada para usar a ferramenta de teste`,
  [`Import from Asset repository`]: `Importar do repositório de ativos`,
  [`Untitled {componentLabel}`]: `{componentLabel} sem título`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Sua métrica de licença requer um serviço de analítica para rastrear o uso. Clique em "Registrar serviço" para configurar um serviço de analítica.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Todos os serviços do DataPower Gateway requerem um serviço de Analítica para rastrear o uso. Clique em "Associar serviço de analítica" para cada gateway para cumprir os termos de licença.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `O catálogo é incompatível com o tipo de gateway especificado na API ou no produto`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `O espaço é incompatível com o tipo de gateway especificado na API ou no produto`,
  [`Preserve Subscription`]: `Preservar assinatura`,
  [`Publish validation`]: `Validação de publicação`,
  [`Publish validations`]: `Validações de publicação`,
  [`Edit publish validations`]: `Editar validações de publicação`,
  [`The following validations will be performed when publishing a product`]: `As validações a seguir serão executadas ao publicar um produto`,
  [`There are no publish validations enabled`]: `Não há validações de publicação ativadas`,
  [`Publish validations have been updated`]: `As validações de publicação foram atualizadas`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Forneça a autoridade de certificação (CA) que o Serviço de Gateway de Eventos usa para confiar no certificado do servidor do back-end Kafka . Se o servidor estiver usando um certificado emitido por uma autoridade bem conhecida, você não precisa fornecer a autoridade de certificação.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `O valor inserido para os servidores de autoinicialização não é válido. Insira o host e a porta de cada corretor Kafka em seu cluster, separados por vírgulas. Por exemplo, broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `Fazer upload de extensão do OpenAPI`,
  [`Add OpenAPI Extension`]: `Incluir extensão do OpenAPI`,
  [`Gateway Endpoint`]: `Terminal de gateway`,
  [`API Dashboard`]: `Painel da API`,
  [`API call data`]: `Dados de chamada da API`,
  [`Product Dashboard`]: `Painel do produto`,
  [`API Product consumption metrics`]: `Métricas de consumo de produtos da API`,
  [`Monitoring Latency Dashboard`]: `Painel de latência de monitoramento`,
  [`Data about the time taken to serve API calls`]: `Dados sobre o tempo gasto para atender a chamadas de API`,
  [`Monitoring Status Dashboard`]: `Painel de status de monitoramento`,
  [`API Error and success information`]: `Informações de erro e de sucesso da API`,
  [`Usage Dashboard`]: `Painel de uso`,
  [`Usage for gateway {gateway}`]: `Uso do gateway {gateway}`,
  [`Latency for gateway {gateway}`]: `Latência do gateway {gateway}`,
  [`Most popular products, APIs and applications`]: `Produtos, APIs e aplicativos mais populares`,
  [`Consumption Dashboard`]: `Painel de consumo`,
  [`Total API volume useful for auditing and compliance`]: `Volume total de API útil para auditoria e conformidade`,
  [`Monthly Summary by Status Code`]: `Resumo mensal por Código de status`,
  [`Monthly Summary Data by Status Code`]: `Dados de resumo mensal por Código de status`,
  [`Daily Summary by Status Code`]: `Resumo diário por Código de status`,
  [`Daily Summary Data by Status Code`]: `Dados de resumo diário por Código de status`,
  [`Response time (ms)`]: `Tempo de resposta (ms)`,
  [`Datetime`]: `Data/hora`,
  [`Transaction ID:`]: `ID da transação:`,
  [`Other`]: `Outro`,
  [`Date time`]: `Data e hora`,
  [`Total calls`]: `Total de Chamadas`,
  [`Date`]: `Data`,
  [`Month`]: `Mês`,
  [`Minimum response time`]: `Tempo mínimo de resposta`,
  [`Maximum response time`]: `Tempo máximo de resposta`,
  [`Average response time`]: `Tempo médio de resposta`,
  [`Total API calls`]: `Total de chamadas de API`,
  [`Total APIs`]: `Total de APIs`,
  [`APIs called`]: `APIs chamadas`,
  [`Total products`]: `Total de produtos`,
  [`products called`]: `produtos chamados`,
  [`Total errors`]: `Total de erros`,
  [`Status codes (detailed)`]: `Códigos de status (detalhado)`,
  [`Response time percentiles`]: `Percentis de tempo de resposta`,
  [`API calls per day`]: `Chamadas de API por dia`,
  [`Time zone:`]: `Fuso horário:`,
  [`Time zone`]: `Fuso horário`,
  [`Toggle axes`]: `Alternar eixos`,
  [`Datetime:`]: `Data e hora:`,
  [`datetime per 30 seconds`]: `data/hora por 30 segundos`,
  [`Datetime (local time)`]: `Data e hora (horário local)`,
  [`Data usage (bytes)`]: `Uso de dados (bytes)`,
  [`Time to Serve Request`]: `Tempo para atender à solicitação`,
  [`API name`]: `Nome da API`,
  [`Product name`]: `Nome do produto`,
  [`Data usage (bytes received)`]: `Uso de dados (bytes recebidos)`,
  [`Data usage (bytes sent)`]: `Uso de dados (bytes enviados)`,
  [`Response times (>1s)`]: `Tempos de resposta (>1s)`,
  [`milliseconds`]: `milissegundos`,
  [`errors`]: `erros`,
  [`Success Rate`]: `Taxa de sucesso`,
  [`Errors Data`]: `Dados de erros`,
  [`Success Data`]: `Dados de sucesso`,
  [`Total consumer organizations`]: `Total de organizações de consumidores`,
  [`making API calls`]: `fazendo chamadas de API`,
  [`Applications per plan`]: `Aplicativos por plano`,
  [`Top products by calls`]: `Principais produtos por chamadas`,
  [`Top APIs by calls`]: `Principais APIs por chamadas`,
  [`Top client IP addresses by calls`]: `Principais endereços IP do cliente por chamadas`,
  [`Client IP address`]: `Endereço IP do cliente`,
  [`Top APIs by request size`]: `Principais APIs por tamanho de solicitação`,
  [`Top APIs by response size`]: `Principais APIs por tamanho de resposta`,
  [`Top APIs by response time`]: `Principais APIs por tempo de resposta`,
  [`Top applications by calls`]: `Principais aplicações por chamadas`,
  [`Dashboards`]: `Painéis`,
  [`Discover`]: `Descobrir`,
  [`UTC`]: `UTC (Hora Universal Coordenada)`,
  [`Local time`]: `Horário local`,
  [`URI`]: `URI`,
  [`Response code`]: `Código de resposta`,
  [`Total time elapsed (ms)`]: `Tempo total decorrido (ms)`,
  [`Timestamp`]: `Registro de data e hora`,
  [`Method`]: `Método`,
  [`Gateway IP`]: `IP do gateway`,
  [`Gateway Operations Dashboard`]: `Painel de operações de gateway`,
  [`Usage and latency information per gateway`]: `Informações de uso e latência por gateway`,
  [`Transaction ID`]: `ID de transação`,
  [`Filters`]: `Filtros`,
  [`Fields`]: `Campos`,
  [`Clear all`]: `Limpe todos`,
  [`API Event`]: `Evento da API`,
  [`API Assembly Policy Latencies`]: `Latências de política do conjunto de API`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `O diagrama abaixo mostra o tempo de latência crescente em milissegundos conforme a solicitação progrediu através das políticas de conjunto de API. Isso pode ser útil na determinação de pontos lentos ou gargalos no conjunto da API.`,
  [`Collapse`]: `Reduzir`,
  [`Expand`]: `Expandir`,
  [`enter name`]: `inserir nome`,
  [`Enter value`]: `Inserir valor`,
  [`Time range`]: `Intervalo de tempo`,
  [`Select one of the following time range filters`]: `Selecione um dos filtros de intervalo de tempo a seguir`,
  [`Last 1 hour`]: `Última hora`,
  [`Last {number} minutes`]: `Últimos {number} Minutos`,
  [`Last {number} hours`]: `Últimas {number} horas`,
  [`Last {number} days`]: `Últimos {number} dias`,
  [`Start date`]: `Data de Início`,
  [`End date`]: `Data de encerramento`,
  [`Select from the following dropdowns and enter a value`]: `Selecione nos menus suspensos a seguir e insira um valor`,
  [`Select one of the following type filters`]: `Selecione um dos filtros de tipo a seguir`,
  [`All events`]: `Todos os eventos`,
  [`Success only (2xx, 3xx)`]: `Sucesso apenas (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Erros apenas (4xx, 5xx)`,
  [`Import/Export`]: `Importar/Exportar`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Cerca de {totalItems} resultados ({searchTime} segundos) às {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Cerca de {totalItems} resultados ({searchTime} segundos) em {time} ({utcTime})`,
  [`API calls`]: `Chamadas API`,
  [`Export as CSV`]: `Exportar como CSV`,
  [`Export as JSON`]: `Exportar como JSON`,
  [`Export as PNG`]: `Exportar como PNG`,
  [`Export as JPG`]: `Exportar como JPG`,
  [`Edit filter`]: `Editar filtro`,
  [`Edit query`]: `Editar consulta`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Esta é uma consulta compartilhada. Todas as modificações serão feitas para todos os usuários com os quais esta consulta for compartilhada.`,
  [`This is a shared query. This will delete it for all users.`]: `Esta é uma consulta compartilhada. Isso o excluirá para todos os usuários.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname} foi atualizado para todos os usuários na organização do provedor {org}`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname} foi atualizado para todos os usuários no espaço {space}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname} foi atualizado para todos os usuários no catálogo {catalog}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname} foi atualizado para todos os usuários no gerenciador de nuvem`,
  [`Stop sharing`]: `Parar o compartilhamento`,
  [`Share query`]: `Compartilhar consulta`,
  [`Share filter`]: `Compartilhar filtro`,
  [`You do not have permissions to delete this filter`]: `Você não tem permissões para excluir este filtro`,
  [`You do not have permissions to delete this query`]: `Você não tem permissões para excluir esta consulta`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Tem certeza de que deseja compartilhar a consulta {queryTitle} no escopo da organização do provedor {org}?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Tem certeza de que deseja compartilhar a consulta {queryTitle} no escopo do espaço {space}?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Tem certeza de que deseja compartilhar a consulta {queryTitle} no escopo do catálogo {catalog}?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Tem certeza de que deseja compartilhar a consulta {queryTitle} no escopo da nuvem?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Tem certeza de que deseja parar o compartilhamento da consulta {queryTitle}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Tem certeza de que deseja parar o compartilhamento da consulta {queryTitle} no escopo da organização do provedor {org}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Tem certeza de que deseja parar o compartilhamento da consulta {queryTitle} no escopo do espaço {space}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Tem certeza de que deseja parar o compartilhamento da consulta {queryTitle} no escopo do catálogo {catalog}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Tem certeza de que deseja parar o compartilhamento da consulta {queryTitle} no escopo da nuvem?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Tem certeza de que deseja excluir a consulta {queryTitle}?`,
  [`At least one query parameter expected`]: `Pelo menos um parâmetro de consulta esperado`,
  [`A provided query parameter is incomplete`]: `Um parâmetro de consulta fornecido está incompleto`,
  [`Could not parse query string`]: `Não foi possível analisar a sequência de consultas`,
  [`An unsupported query parameter was provided: {query}`]: `Um parâmetro de consulta não suportado foi fornecido: {query}`,
  [`Invalid timeframe value: {value}`]: `Valor de intervalo de tempo inválido: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `O valor de {field} deve seguir a notação de data/hora conforme definido pela RFC 3339, seção 5.6. Exemplos, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `O operador {operator} não é suportado para o campo {field}`,
  [`The value of {field} must be a number`]: `O valor de {field} deve ser um número`,
  [`Delete query`]: `Excluir consulta`,
  [`Delete filter`]: `Excluir filtro`,
  [`Save query`]: `Salvar consulta`,
  [`Save filter`]: `Salvar filtro`,
  [`Save filter as`]: `Salvar filtro como`,
  [`Save query as`]: `Salvar consulta como`,
  [`Save as...`]: `Salvar Como...`,
  [`Save as`]: `Salvar como`,
  [`Paste query string to load the query`]: `Colar sequência de consultas para carregar a consulta`,
  [`Copy or paste filter string to load filters`]: `Copiar ou colar sequência de filtros para carregar filtros`,
  [`Query string`]: `Sequência de consulta`,
  [`View filter`]: `Visualizar filtro`,
  [`View filter string`]: `Visualizar sequência de filtros`,
  [`Date (local time)`]: `Data (horário local)`,
  [`Coming soon, a new built in analytics feature`]: `Em breve, um novo recurso de análise integrado`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `O Analytics ajudará a coletar informações sobre o tempo de execução da API, gerenciar níveis de serviço, definir cotas, estabelecer controles, configurar políticas de segurança, gerenciar comunidades e analisar tendências.`,
  [`Documentation`]: `Documentação`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Aprofunde-se nos tipos de dados de tempo de execução da API e como usar os buckets do AWS s3 para visualizá-los.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Saiba quais dados estão disponíveis hoje e como acessá-los.`,
  [`Next steps`]: `Próximas etapas`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `O serviço Secrets Manager selecionado não gerencia nenhum certificado.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Um certificado é necessário para proteger a conexão entre o API Connect e o domínio do terminal de gerenciamento de gateway. Os certificados também são necessários para proteger as conexões entre o gateway e os domínios que ele manipula. Todos os certificados devem ser armazenados em um serviço Secrets Manager. O serviço fornece um repositório seguro para os certificados e ajuda a evitar interrupções enviando notificações quando os certificados estão prestes a expirar na integração com o serviço de Notificações de Eventos. Para começar,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Os certificados são necessários para proteger as conexões entre o gateway e os domínios que ele manipula. Todos os certificados devem ser armazenados em um serviço Secrets Manager. O serviço fornece um repositório seguro para os certificados e ajuda a evitar interrupções enviando notificações quando os certificados estão prestes a expirar na integração com o serviço de Notificações de Eventos. Para começar,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Mudar o Secrets Manager removerá todas as atribuições de certificados e pacotes de CA existentes.`,
  [`Create a [Secrets Manager]({link}) service`]: `Crie um serviço de [Secrets Manager]({link})`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Importe os certificados necessários e os pacotes de CA para o serviço Secrets Manager.`,
  [`No Secrets Manager services available`]: `Nenhum serviço de Secrets Manager disponível`,
  [`Select Secrets Manager service`]: `Selecionar o serviço de Secrets Manager`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Selecione o serviço de [Secrets Manager]({link}) que gerencia os certificados para este gateway.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `O serviço Secrets Manager deve gerenciar os certificados para o domínio do endpoint de gerenciamento do gateway e os domínios que o gateway manipula. Além disso, deve-se usar o IBM Cloud IAM para autorizar o serviço de instância reservada do API Connect para acessar o serviço de Secrets Manager.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Em seguida, selecione o certificado para o domínio do terminal de gerenciamento. Selecione também o pacote configurável de CA correspondente se o certificado não tiver sido designado por uma autoridade de certificação conhecida. O certificado e o pacote devem ser gerenciados pelo serviço Secrets Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Em seguida, especifique os domínios que serão manipulados por esse gateway. A técnica SNI é usada. Como resultado, diversos domínios podem ser entregues por meio do mesmo endereço IP e porta sem a necessidade do mesmo certificado. O formato de curinga é suportado. O domínio padrão (catch-all) de ‘*’ deve ser incluído como a entrada final na tabela. Insira outros nomes de domínio conforme necessário, especificando em cada caso um certificado gerenciado pelo serviço Secrets Manager.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Usando o [IBM Cloud IAM]({link}), autorize o serviço de de instância reservada do API Connect para acessar o serviço de Secrets Manager.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Atualize o certificado e as designações do pacote configurável CA existentes, carregando-o por meio do Secrets Manager.`,
  [`Access s3 data`]: `Acessar dados s3`,
  [`Create resource`]: `Criar recurso`,
  [`Connect analytics data in s3 bucket with AWS`]: `Conectar dados de analítica no bucket s3 com o AWS`,
  [`AWS account`]: `Conta do AWS`,
  [`AWS account number`]: `Número da conta do AWS`,
  [`Enter your 12 digit aws account id`]: `Insira seu ID de conta do AWS de 12 dígitos`,
  [`Account id must be 12 digits`]: `O ID da conta deve ter 12 dígitos`,
  [`Failed to update AWS account id.`]: `Falha ao atualizar o ID da conta do AWS.`,
  [`You have limited access to the catalogs.`]: `Você tem acesso limitado aos catálogos.`,
  [`You have limited access to the spaces.`]: `Você tem acesso limitado aos espaços.`,
  [`Contact admin to get more access.`]: `Entre em contato com o administrador para obter mais acesso.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `Não é possível selecionar este catálogo. Entre em contato com um administrador se precisar de acesso.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `Não é possível selecionar este espaço. Entre em contato com um administrador se precisar de acesso.`,
  [`You have limited access to the product billing.`]: `Você tem acesso limitado ao faturamento do produto.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `ID de conta do AWS atualizado com sucesso. Pode levar alguns segundos para conceder acesso aos dados do S3.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Uma organização ou um grupo está ausente no catálogo escolhido.`,
  [`Secrets Manager instance`]: `Instância do Secrets Manager`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Organizações de consumidores com proprietários que não possuem um e-mail não podem ser selecionadas`,
  [`You currently don’t have any data.`]: `Atualmente, você não tem nenhum dado.`,
  [`You currently don’t have any organizations.`]: `Atualmente, você não tem nenhuma organização.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Solicitação para assinar o aplicativo {appname} para o plano {plan} no produto {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Solicitação para {operation} o produto: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Solicitação para fazer upgrade do aplicativo {appname} para produção`,
  [`Onboarding request for {email}`]: `Solicitação de integração para {email}`,
  [`Product lifecycle request`]: `Solicitação de ciclo de vida do produto`,
  [`Subscription request`]: `Solicitação de assinatura`,
  [`Application lifecycle request`]: `Solicitação de ciclo de vida do aplicativo`,
  [`Consumer Onboarding request`]: `Solicitação de integração do consumidor`,
  [`Originator`]: `Originador`,
  [`Edit analytics service advanced settings`]: `Editar as configurações avançadas do serviço de analítica`,
  [`Advanced settings`]: `Configurações avançadas`,
  [`Must be a positive integer`]: `Deve ser um número inteiro positivo`,
  [`Maximum value is {maxValue}`]: `O valor máximo é {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `O valor máximo é {maxValueDays}d ou {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Definir configurações avançadas para a implementação de analítica.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `O valor deve terminar em 'd' para dias ou em 'h' para horas. Por exemplo, 30d ou 12h`,
  [`Rollover settings`]: `Configurações de rolagem`,
  [`Retention settings`]: `Configurações de retenção`,
  [`Delete an index when it meets the following condition:`]: `Excluir um índice quando ele atender à condição a seguir:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Rolar o alias de gravação para um novo índice quando o índice gerenciado atender a uma das condições a seguir:`,
  [`Minimum document count`]: `Contagem mínima de documentos`,
  [`The minimum number of documents required to roll over the index.`]: `O número mínimo de documentos necessários para substituir o índice.`,
  [`Minimum index age`]: `Idade mínima do índice`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `A idade mínima do índice necessária para substituir o índice. A idade do índice é o tempo entre a criação do índice e agora. Use 'd' para dias e 'h' para horas. Por exemplo, 30d significa que o índice será movimentado quando sua idade atingir 30 dias, enquanto 96h significa que o índice será movimentado quando sua idade atingir 96 horas. Os valores máximos são {maxValueDays}d ou {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `A idade mínima do índice necessária para excluir o índice. A idade do índice é o tempo entre a criação do índice e agora. Use 'd' para dias e 'h' para horas. Por exemplo, 30d significa que o índice será excluído quando sua idade atingir 30 dias, enquanto 96h significa que o índice será excluído quando sua idade atingir 96 horas. Os valores máximos são {maxValueDays}d ou {maxValueHours}h.`,
  [`Time range:`]: `Intervalo de tempo:`,
  [`Time range: Before {datetime}`]: `Intervalo de tempo: antes de {datetime}`,
  [`Time range: After {datetime}`]: `Intervalo de tempo: depois de {datetime}`,
  [`Filter before`]: `Filtro antes`,
  [`Filter after`]: `Filtro após`,
  [`Filter exact`]: `Filtro exato`,
  [`Filter gt`]: `Filtro gt`,
  [`Filter lt`]: `Filtro It`,
  [`Filter equals`]: `Filtro é igual`,
  [`Filter by`]: `Filtrar por`,
  [`Filter out`]: `Filtrar para fora`,
  [`Delete failed`]: `Exclusão com falha`,
  [`Delete warning`]: `Excluir aviso`,
  [`Could not delete saved query {queryTitle}.`]: `Não foi possível excluir a consulta {queryTitle} salva.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `A solicitação respondeu com sucesso, mas {queryTitle} ainda não concluiu a exclusão.`,
  [`API governance`]: `Controle de API`,
  [`Something's wrong`]: `Algo está errado`,
  [`We were unable to connect to the service.`]: `Não foi possível conectar-se ao serviço.`,
  [`Invalid date`]: `Dada inválida`,
  [`Invalid time`]: `Horário inválido`,
  [`End date must be after the start date`]: `A data de fim deve ser posterior à data de início`,
  [`Duplicate`]: `Duplicar`,
  [`Duplicate query`]: `Consulta duplicada`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Tem certeza de que deseja fazer uma duplicata de {queryTitle}?`,
  [`No queries saved`]: `Nenhuma consulta salva`,
  [`No queries shared`]: `Nenhuma consulta compartilhada`,
  [`To save a query, start by creating one then save.`]: `Para salvar uma consulta, comece criando uma e, em seguida, salve.`,
  [`Share a saved query and allow others to use it.`]: `Compartilhar uma consulta salva e permitir que outros a usem.`,
  [`Query updated`]: `Consulta atualizada`,
  [`Query update failed`]: `Falha na atualização da consulta`,
  [`Share successful`]: `Compartilhamento bem-sucedido`,
  [`Share failed`]: `Falha no compartilhamento`,
  [`Delete successful`]: `Exclusão bem-sucedida`,
  [`Query unshared`]: `Consulta não compartilhada`,
  [`Unshare {queryTitle} failed`]: `Falha ao cancelar compartilhamento de {queryTitle}`,
  [`Query copied`]: `Consulta copiada`,
  [`Duplicate {queryTitle} failed`]: `Falha na duplicação de {queryTitle}`,
  [`The query {queryTitle} has been updated.`]: `A consulta {queryTitle} foi atualizada.`,
  [`The query {queryTitle} has been shared.`]: `A consulta {queryTitle} foi compartilhada.`,
  [`The query {queryTitle} has been deleted.`]: `A consulta {queryTitle} foi excluída.`,
  [`The query {queryTitle} is no longer shared.`]: `A consulta {queryTitle} não está mais compartilhada.`,
  [`A copy of {queryTitle} has been created.`]: `Uma cópia de {queryTitle} foi criada.`,
  [`About queries`]: `Sobre consultas`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `As consultas são um meio de ajustar os dados de análise usados nos painéis e na visualização Descobrir. Quando uma consulta for aplicada, todos os painéis serão atualizados para incluir apenas os dados selecionados.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `É possível usar um dos intervalos de tempo predefinidos ou usar um intervalo de tempo específico para o segundo mais próximo usando a opção Customizado.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Os campos podem ser usados para especificar apenas determinados dados que devem ser incluídos. É possível filtrar por quase todos atributos de um evento de API.`,
  [`There are then different operators available depending on the field type.`]: `Há, então, diferentes operadores disponíveis, dependendo do tipo de campo.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Os campos de sequência permitem igual, não, começa com, termina com e regex.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `É possível usar contém e não contém, para fornecer uma lista de valores para um único campo (por exemplo, uma lista de versões de API).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `Os campos de endereço IP permitem iguais e não operadores, eles também permitem que o valor seja especificado na notação CIDR para permitir o uso de máscaras de rede para selecionar redes específicas.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `As expressões regulares (regex) podem ser muito potentes para selecionar valores específicos, mas são mais lentas ao executar, portanto, recomenda-se usar outros operadores, como 'começa com' e 'termina com', se for possível.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Os filtros de consultas para diferentes campos são combinados usando o operador AND lógico. Isso significa que a inclusão de dois filtros para o mesmo nome de campo retornará apenas resultados que satisfaçam ambos os filtros.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `O parâmetro de consulta {type} é uma maneira simples de especificar apenas eventos de sucesso ou de erro. Ele usa o campo {statusCode}. Se forem necessários apenas os códigos de status específicos, um filtro de campo poderá ser utilizado. O valor para os campos {statusCodeTwo} é normalmente uma sequência completa como {okStatus} e não apenas o código de retorno HTTP numérico.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `É possível salvar consultas para reutilização posterior e também compartilhá-las com seu nível de escopo atual para outras pessoas usarem também, por exemplo, a organização do provedor ou o catálogo.`,
  [`n/a`]: `n/d`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Nota: Os campos 'ID da organização do provedor' e 'nome da organização do provedor' serão mostrados na carga útil do evento como {orgId} e {orgName} respectivamente. Os campos 'ID da organização do consumidor' e 'nome da organização do consumidor' serão mostrados como {developerOrgId} e {developerOrgName}.`,
  [`No authentication token`]: `Nenhum token de autenticação`,
  [`Contact your administrator for further assistance.`]: `Entre em contato com o seu administrador para obter assistência adicional.`
};
