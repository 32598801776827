// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Catalog has not been updated`,
  [`Catalog has been updated`]: `Catalog has been updated`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `The draft product {draftProduct} must reference one or more APIs`,
  [`Number of Calls`]: `Number of Calls`,
  [`Changes will apply to newly published APIs only`]: `Changes will apply to newly published APIs only`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `There are incompatible {label} due to them having a different Gateway type to this API.`,
  [`{label} is required`]: `{label} is required`,
  [`Application is required`]: `Application is required`,
  [`Plan is required`]: `Plan is required`,
  [`Gateway is required`]: `Gateway is required`,
  [`Consumer Org is required`]: `Consumer Org is required`,
  [`LDAP ATTRIBUTE NAME`]: `LDAP ATTRIBUTE NAME`,
  [`Use the build in Test Application (sandbox only)`]: `Use the build in Test Application (sandbox only)`,
  [`Select an Application`]: `Select an Application`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`,
  [`Choose an existing Application`]: `Choose an existing Application`,
  [`Use JWT for gateway authentication to analytics service`]: `Use JWT for gateway authentication to analytics service`,
  [`Select an application to consume the API`]: `Select an application to consume the API`,
  [`Use the default built-in Sandbox Catalog'`]: `Use the default built-in Sandbox Catalog'`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`,
  [`Select a Consumer Org`]: `Select a Consumer Org`,
  [`Select the Consumer Org your generated application will belong to`]: `Select the Consumer Org your generated application will belong to`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`,
  [`Generate auto product`]: `Generate auto product`,
  [`Select a product to associate the current API with`]: `Select a product to associate the current API with`,
  [`Associate the current API to a selected product`]: `Associate the current API to a selected product`,
  [`API management`]: `API management`,
  [`Select the API management instance you would like this API to be published to.`]: `Select the API management instance you would like this API to be published to.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`,
  [`Provider organization`]: `Provider organization`,
  [`Select the provider organization you would like this API to be published to.`]: `Select the provider organization you would like this API to be published to.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`,
  [`Selected a draft product`]: `Selected a draft product`,
  [`Use any available gateway service`]: `Use any available gateway service`,
  [`Select a compatible gateway service`]: `Select a compatible gateway service`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`,
  [`Select a gateway`]: `Select a gateway`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`,
  [`Select a Plan`]: `Select a Plan`,
  [`Generate subscription`]: `Generate subscription`,
  [`A subscription will be created between the selected Product plan and Application`]: `A subscription will be created between the selected Product plan and Application`,
  [`Apply a rate limit to the generated product`]: `Apply a rate limit to the generated product`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`,
  [`About token management`]: `About token management`,
  [`About user security`]: `About user security`,
  [`APIs or products`]: `APIs or products`,
  [`ATM is not deployed, please contact your administrator`]: `ATM is not deployed, please contact your administrator`,
  [`Application state change approvals will be enabled`]: `Application state change approvals will be enabled`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`,
  [`See console for details.`]: `See console for details.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `The selected Certificate Manager service does not manage any certificates.`,
  [`The selected product is not published to the Sandbox catalog`]: `The selected product is not published to the Sandbox catalog`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `the IBM Cloud CLI for your platform (if not previously installed).`,
  [`(Only supported by TLS 1.3)`]: `(Only supported by TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `"@listSize" directive on this field defines sized fields that do not return lists.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `"@listSize" directive on this field defines sized fields not defined on the return type of the field.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `"@listSize" directive on this field defines slicing arguments not defined on the field.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `"@listSize" directive on this field defines multiple non-null slicing arguments.`,
  [`"{name}" (Role) has been created`]: `"{name}" (Role) has been created.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (Role) has been updated.`,
  [`API ({api}) has been created.`]: `API ({api}) has been created.`,
  [`API ({api}) has been renamed.`]: `API ({api}) has been renamed.`,
  [`API ({api}) has been updated.`]: `API ({api}) has been updated.`,
  [`API ({api}) has been deleted.`]: `API ({api}) has been deleted.`,
  [`API ({api}) has been published.`]: `API ({api}) has been published.`,
  [`API ({api}) has validation error.`]: `API ({api}) has validation error`,
  [`API ({api}) has validation errors.`]: `API ({api}) has validation errors`,
  [`API version`]: `API version`,
  [`APIM data version`]: `APIM data version`,
  [`APIM schema update date`]: `APIM schema update date`,
  [`APIM schema version`]: `APIM schema version`,
  [`APIM target data version`]: `APIM target data version`,
  [`APIM target schema version`]: `APIM target schema version`,
  [`APIM update date`]: `APIM update date`,
  [`Product ({product}) has been created.`]: `Product ({product}) has been created.`,
  [`Product ({product}) has been updated.`]: `Product ({product}) has been updated.`,
  [`Product ({product}) has been delete.`]: `Product ({product}) has been delete.`,
  [`Product ({product}) has been published.`]: `Product ({product}) has been published.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) has been {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) has not been {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} after being queued)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} after being sent)`,
  [`(none)`]: `(none)`,
  [`(optional)`]: `(optional)`,
  [`*Base endpoint list empty`]: `*Base endpoint list empty`,
  [`*Must be a valid url`]: `*Must be a valid url`,
  [`*System will also send an email notification to the requester.`]: `*System will also send an email notification to the requester.`,
  [`+ redact from...`]: `+ redact from...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. You can also manage the lifecycle of this product inside the catalog.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x followed by 64 hex characters`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`,
  [`5 Most Active APIs`]: `5 Most Active APIs`,
  [`5 Most Active Products`]: `5 Most Active Products`,
  [`5 most active APIs`]: `5 most active APIs`,
  [`5 most active Products`]: `5 most active Products`,
  [`503 Service Unavailable`]: `503 Service Unavailable`,
  [`A request to the server was made without any credentials.`]: `A request to the server was made without any credentials.`,
  [`A server error occurred`]: `A server error occurred`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `A catalog hosts a collection of API products that are visible in the associated developer portal when published`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `A catalog hosts a collection of API products that are visible in the associated developer portal when published.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `A default value which will be used if the inputs to the map are not defined.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `ACTION`,
  [`ANALYTICS`]: `ANALYTICS`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `API Administrator`,
  [`API CONNECT VALUE`]: `API CONNECT VALUE`,
  [`API assemble`]: `API assemble`,
  [`API base endpoints`]: `API base endpoints`,
  [`API base URL`]: `API base URL`,
  [`API base URLs`]: `API base URLs`,
  [`API Calls`]: `API Calls`,
  [`API Connect UI`]: `API Connect UI`,
  [`API Connect services`]: `API Connect services`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect requires an email server to send invitations and activation links to members, and for other purposes.`,
  [`API Connect verify token`]: `API Connect verify token`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `API Designer Licence`,
  [`API Designer credentials`]: `API Designer credentials`,
  [`API Designer licence`]: `API Designer licence`,
  [`API Designer does not currently support WSDL`]: `API Designer does not currently support WSDL`,
  [`API editor`]: `API editor`,
  [`API Endpoint`]: `API Endpoint`,
  [`API Endpoint Base`]: `API Endpoint Base`,
  [`API Endpoint for Unenforced APIs`]: `API Endpoint for Unenforced APIs`,
  [`API Endpoint for unenforced APIs`]: `API Endpoint for unenforced APIs`,
  [`API Endpoints`]: `API Endpoints`,
  [`API Error`]: `API Error`,
  [`API gateway`]: `API gateway`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API gateways`,
  [`API Gateways`]: `API Gateways`,
  [`API Invocation Endpoint`]: `API Invocation Endpoint`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `API is not published. API Test(Try it) in Explorer will not be enabled.`,
  [`API Key`]: `API Key`,
  [`API key`]: `API key`,
  [`API key secret`]: `API key secret`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `API Manager Local User Registry`,
  [`API Manager URL`]: `API Manager URL`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `API Path Parameters`,
  [`API Security Definition`]: `API Security Definition`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`,
  [`API Setup`]: `API Setup`,
  [`API Subscription`]: `API Subscription`,
  [`API endpoint`]: `API endpoint`,
  [`API endpoint base`]: `API endpoint base`,
  [`API endpoint for unenforced APIs`]: `API endpoint for unenforced APIs`,
  [`API endpoints`]: `API endpoints`,
  [`API error`]: `API error`,
  [`API invocation endpoint`]: `API invocation endpoint`,
  [`API is not enforced on the API gateway.`]: `API is not enforced on the API gateway.`,
  [`API path parameters`]: `API path parameters`,
  [`API security definition`]: `API security definition`,
  [`API setup`]: `API setup`,
  [`API subscription`]: `API subscription`,
  [`API Update`]: `API Update`,
  [`API user registries`]: `API user registries`,
  [`API and product generated successfully!`]: `API and product generated successfully!`,
  [`API deletion failed`]: `API deletion failed`,
  [`API does not exist!`]: `API does not exist!`,
  [`API from existing REST service`]: `API from existing REST service`,
  [`API from existing SOAP service`]: `API from existing SOAP service`,
  [`API has been updated.`]: `API has been updated.`,
  [`API has been updated`]: `API has been updated`,
  [`API has not been created!`]: `API has not been created!`,
  [`API has not been updated!`]: `API has not been updated!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`,
  [`API properties`]: `API properties`,
  [`API protection source`]: `API protection source`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `API referenced from the product doesn't exist. Create or import the API first.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `API request and response payload structures are composed using OpenAPI schema definitions.`,
  [`API subscription request approved for app {{appName}}`]: `API subscription request approved for app {appName}`,
  [`API subscription request denied for app {{appName}}`]: `API subscription request denied for app {appName}`,
  [`API subscription request received for app {{appName}}`]: `API subscription request received for app {appName}`,
  [`API type`]: `API type`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`,
  [`APIs`]: `APIs`,
  [`APIs and products`]: `APIs and products`,
  [`APIs included in the plan.`]: `APIs included in the plan.`,
  [`API Key is created`]: `API Key is created`,
  [`API Key is deleted`]: `API Key is deleted`,
  [`API Key is successfully created`]: `API key is successfully created`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `API key timeout is **{seconds} seconds ({time})**`,
  [`API key timeout`]: `API key timeout`,
  [`API Key timeout has been changed`]: `API Key timeout has been changed`,
  [`Application type`]: `Application type`,
  [`Associated analytics service`]: `Associated analytics service`,
  [`AVAILABILITY`]: `AVAILABILITY`,
  [`AVAILABILITY ZONE`]: `AVAILABILITY ZONE`,
  [`About`]: `About`,
  [`About audit setting`]: `About audit setting`,
  [`About Authentication URL user registry`]: `About Authentication URL user registry`,
  [`About availability zones`]: `About availability zones`,
  [`About catalogs`]: `About catalogs`,
  [`About consumer organization owners`]: `About consumer organization owners`,
  [`About consumer organizations`]: `About consumer organizations`,
  [`About developer portals`]: `About developer portals`,
  [`About email servers`]: `About email servers`,
  [`About Endpoints`]: `About Endpoints`,
  [`About gateways`]: `About gateways`,
  [`About gateway visibility`]: `About gateway visibility`,
  [`About Gateway Processing Status`]: `About Gateway Processing Status`,
  [`About introspection`]: `About introspection`,
  [`About Keystores`]: `About Keystores`,
  [`About LDAP`]: `About LDAP`,
  [`About local user registries`]: `About local user registries`,
  [`About metadata`]: `About metadata`,
  [`About Migration Target`]: `About Migration Target`,
  [`About migration targets`]: `About migration targets`,
  [`About native OAuth provider`]: `About native OAuth provider`,
  [`About OpenID Connect`]: `About OpenID Connect`,
  [`About replacing a product`]: `About replacing a product`,
  [`About Role defaults`]: `About Role defaults`,
  [`About roles`]: `About roles`,
  [`About scopes`]: `About scopes`,
  [`About Set Migration Targets`]: `About Set Migration Targets`,
  [`About spaces`]: `About spaces`,
  [`About Subscription`]: `About Subscription`,
  [`About superseding a product`]: `About superseding a product`,
  [`About TLS server profile`]: `About TLS server profile`,
  [`About TLS client profiles`]: `About TLS client profiles`,
  [`About TLS server profiles`]: `About TLS server profiles`,
  [`About third party OAuth provider`]: `About third party OAuth provider`,
  [`About tokens`]: `About tokens`,
  [`About Transfer Ownership`]: `About Transfer Ownership`,
  [`About Transferring Ownership`]: `About Transferring Ownership`,
  [`About Truststores`]: `About Truststores`,
  [`About adding a member`]: `About adding a member`,
  [`About adding provider organization member`]: `About adding provider organization member`,
  [`About adding members to a Space`]: `About adding members to a Space`,
  [`About adding members to a catalog`]: `About adding members to a catalog`,
  [`About changing owners`]: `About changing owners`,
  [`About enabling gateway services`]: `About enabling gateway services`,
  [`About inviting a member`]: `About inviting a member`,
  [`About inviting catalog members`]: `About inviting catalog members`,
  [`About inviting provider organization members`]: `About inviting provider organization members`,
  [`About inviting space members`]: `About inviting space members`,
  [`About keystores`]: `About keystores`,
  [`About managing Space membership`]: `About managing Space membership`,
  [`About migrating subscriptions`]: `About migrating subscriptions`,
  [`About provider organizations`]: `About provider organizations`,
  [`About roles and role defaults`]: `About roles and role defaults`,
  [`About roles in spaces`]: `About roles in spaces`,
  [`About selecting gateway services`]: `About selecting gateway services`,
  [`About send message`]: `About send message`,
  [`About sending messages`]: `About sending messages`,
  [`About the analytics service`]: `About the analytics service`,
  [`About the DNS Scheme`]: `About the DNS Scheme`,
  [`About the Gateway Service`]: `About the Gateway Service`,
  [`About the Portal Service`]: `About the Portal Service`,
  [`About the product lifecycle`]: `About the product lifecycle`,
  [`About the provider organization owner`]: `About the provider organization owner`,
  [`About this Cloud`]: `About this Cloud`,
  [`About transferring ownership`]: `About transferring ownership`,
  [`About truststores`]: `About truststores`,
  [`About visibility`]: `About visibility`,
  [`About visibility and subscribability`]: `About visibility and subscribability`,
  [`Accept`]: `Accept`,
  [`Access Code`]: `Access Code`,
  [`Access Token`]: `Access Token`,
  [`Access URL through Secure Gateway`]: `Access URL through Secure Gateway`,
  [`Access code`]: `Access code`,
  [`Access code is not chosen in supported grant type.`]: `Access code is not chosen in supported grant type.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`,
  [`Access token TTL has been changed`]: `Access token TTL has been changed`,
  [`Access token time to live`]: `Access token time to live`,
  [`Access token time-to-live`]: `Access token time-to-live`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Access token will expire in **{seconds} seconds ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`,
  [`Access tokens time to live (seconds)`]: `Access tokens time to live (seconds)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`,
  [`Account`]: `Account`,
  [`Account has been updated.`]: `Account has been updated.`,
  [`Account password has been changed.`]: `Account password has been changed.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Action`,
  [`Activate API`]: `Activate API`,
  [`Activate API|button text`]: `Activate API`,
  [`Activate API for testing|title`]: `Activate API`,
  [`Activation link`]: `Activation link`,
  [`Active`]: `Active`,
  [`Activity log`]: `Activity log`,
  [`Activity log API setting is not available for this API's gateway type.`]: `Activity log API setting is not available for this API's gateway type.`,
  [`Active loading indicator`]: `Active loading indicator`,
  [`Add`]: `Add`,
  [`Add allowlist`]: `Add allowlist`,
  [`Add a new billing integration in Resources`]: `Add a new billing integration in Resources`,
  [`Add API user registries`]: `Add API user registries`,
  [`Add APIs to Product`]: `Add APIs to Product`,
  [`Add another cloud`]: `Add another cloud`,
  [`Add billing integration`]: `Add billing integration`,
  [`Add Burst Limit`]: `Add Burst Limit`,
  [`Add Ciphers for TLS client profile`]: `Add Ciphers for TLS client profile`,
  [`Add Ciphers for TLS server profile`]: `Add Ciphers for TLS server profile`,
  [`Add Condition`]: `Add Condition`,
  [`Add Consumer Role`]: `Add Consumer Role`,
  [`Add Count Limit`]: `Add Count Limit`,
  [`Add Credential`]: `Add Credential`,
  [`Add domain`]: `Add domain`,
  [`Add credential`]: `Add credential`,
  [`Add Extension`]: `Add Extension`,
  [`Add GraphQL schema`]: `Add GraphQL schema`,
  [`Add HTTP Endpoint`]: `Add HTTP Endpoint`,
  [`Add group`]: `Add group`,
  [`Add JSON schema`]: `Add JSON schema`,
  [`Add Keystore to TLS client profile`]: `Add Keystore to TLS client profile`,
  [`Add Keystore to TLS server profile`]: `Add Keystore to TLS server profile`,
  [`Add member`]: `Add member`,
  [`Add New Role for Consumer organization`]: `Add New Role for Consumer organization`,
  [`Add operation`]: `Add operation`,
  [`Add Parameters`]: `Add Parameters`,
  [`Add Policy`]: `Add Policy`,
  [`Add Rate limit`]: `Add Rate limit`,
  [`Add Rate Limit`]: `Add Rate Limit`,
  [`Add role`]: `Add role`,
  [`Add Syslog TCP Endpoint`]: `Add Syslog TCP Endpoint`,
  [`Add Syslog TLS Endpoint`]: `Add Syslog TLS Endpoint`,
  [`Add Syslog UDP Endpoint`]: `Add Syslog UDP Endpoint`,
  [`Add XML schema`]: `Add XML schema`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`,
  [`Add a different Cloud Connection.`]: `Add a different Cloud Connection.`,
  [`Add a member from the user registry`]: `Add a member from the user registry`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Add a user to the Admin organization, and assign the required roles`,
  [`Add a user to the provider organization, and assign the required roles`]: `Add a user to the provider organization, and assign the required roles`,
  [`Add a user to the Catalog, and assign the required roles`]: `Add a user to the Catalog, and assign the required roles`,
  [`Add a user to the Space, and assign the required roles`]: `Add a user to the Space, and assign the required roles`,
  [`Add action`]: `Add action`,
  [`Add additional client ID/client secret pairs`]: `Add additional client ID/client secret pairs`,
  [`Add blocklist`]: `Add blocklist`,
  [`Add case`]: `Add case`,
  [`Add catch`]: `Add catch`,
  [`Add default catch`]: `Add default catch`,
  [`Add destination`]: `Add destination`,
  [`Add entry`]: `Add entry`,
  [`Add group failed.`]: `Add group failed.`,
  [`Add input`]: `Add input`,
  [`Add media type`]: `Add media type`,
  [`Add member failed`]: `Add member failed`,
  [`Add object`]: `Add object`,
  [`Add otherwise`]: `Add otherwise`,
  [`Add output`]: `Add output`,
  [`Add outputs for operation...`]: `Add outputs for operation...`,
  [`Add parameters for operation...`]: `Add parameters for operation...`,
  [`Add parameters to this API`]: `Add parameters to this API`,
  [`Add plans to product`]: `Add plans to product`,
  [`Add plans to this product`]: `Add plans to this product`,
  [`Add schema`]: `Add schema`,
  [`Add scopes for this OAuth provider.`]: `Add scopes for this OAuth provider.`,
  [`Add scopes to allow access to`]: `Add scopes to allow access to`,
  [`Add scopes to allow access to.`]: `Add scopes to allow access to.`,
  [`Add tags and external documentation details for this API`]: `Add tags and external documentation details for this API`,
  [`Add to`]: `Add to`,
  [`Add to existing product`]: `Add to existing product`,
  [`Add/Remove APIs`]: `Add/Remove APIs`,
  [`AddProperties`]: `AddProperties`,
  [`Added APIs`]: `Added APIs`,
  [`Added rate limit`]: `Added rate limit`,
  [`Added rate limits`]: `Added rate limits`,
  [`Adding an API to a product for publishing`]: `Adding an API to a product for publishing`,
  [`Additional support`]: `Additional support`,
  [`Additional properties`]: `Additional properties`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`,
  [`Address`]: `Address`,
  [`Admin DN`]: `Admin DN`,
  [`Admin organization invitation timeout`]: `Admin organization invitation timeout`,
  [`Admin organization invitation timeout has been changed`]: `Admin organization invitation timeout has been changed`,
  [`Admin password`]: `Admin password`,
  [`Admin request reset password`]: `Admin request reset password`,
  [`Admin reset password`]: `Admin reset password`,
  [`Admin Sign In`]: `Admin Sign In`,
  [`Admin add catalog failed`]: `Admin add catalog failed`,
  [`Admin add space failed`]: `Admin add space failed`,
  [`Administer consumer organizations`]: `Administer consumer organizations`,
  [`Administers the API consumer organization`]: `Administers the API consumer organization`,
  [`Administers the API provider organization`]: `Administers the API provider organization`,
  [`Administers the admin organization`]: `Administers the admin organization`,
  [`Administers the admin topology`]: `Administers the admin topology`,
  [`Administers the app developer organization`]: `Administers the app developer organization`,
  [`Administers the catalog`]: `Administers the catalog`,
  [`Administers the cloud topology`]: `Administers the cloud topology`,
  [`Administers the space`]: `Administers the space`,
  [`Administration management console`]: `Administration management console`,
  [`Administrator`]: `Administrator`,
  [`Advanced analytics configuration`]: `Advanced analytics configuration`,
  [`Advanced features`]: `Advanced features`,
  [`Advanced scope check`]: `Advanced scope check`,
  [`Advanced scope check after Token Validation`]: `Advanced scope check after Token Validation`,
  [`Advanced scope check before Token Generation`]: `Advanced scope check before Token Generation`,
  [`Aggregation`]: `Aggregation`,
  [`Agree`]: `Agree`,
  [`All`]: `All`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`,
  [`All consumer organizations will be able to see this product.`]: `All consumer organizations will be able to see this product.`,
  [`Allowlist`]: `Allowlist`,
  [`Allow Chunked Uploads`]: `Allow Chunked Uploads`,
  [`Allow chunked uploads`]: `Allow chunked uploads`,
  [`Allow "plain" challenge method`]: `Allow "plain" challenge method`,
  [`Allow renegotiation`]: `Allow renegotiation`,
  [`Allow case sensitive usernames`]: `Allow case sensitive usernames`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`,
  [`Allow connection to be renegotiated`]: `Allow connection to be renegotiated`,
  [`Allow default introspection`]: `Allow default introspection`,
  [`Allow insecure server connections`]: `Allow insecure server connections`,
  [`Allow null value`]: `Allow null value`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout.`]: `Allow access_token/refresh_token to send in 302 redirect for logout.`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `All recommendations have been applied. There are no warnings for your schema.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Allow the API's operations to be tested using the test tools in the Developer Portal.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Allow the connection to proceed with weak or insecure credentials`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Allow the connection to proceed with weak or insecure credentials.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`,
  [`Allow to manage this user registry`]: `Allow to manage this user registry`,
  [`Allow to manage users under this user registry`]: `Allow to manage users under this user registry`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer.`]: `Allow users to automatically onboard when APIC is presented with a token issued by the issuer.`,
  [`Already have an account?`]: `Already have an account?`,
  [`Also transfer ownership of owned Spaces`]: `Also transfer ownership of owned Spaces`,
  [`Always fetch user data from userinfo endpoint if enabled.`]: `Always fetch user data from userinfo endpoint if enabled.`,
  [`Always output the root element`]: `Always output the root element`,
  [`Always output the root element.`]: `Always output the root element.`,
  [`Always required`]: `Always required`,
  [`Always use userinfo endpoint`]: `Always use userinfo endpoint`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`,
  [`An error occurred communicating with the gateways subsystem.`]: `An error occurred communicating with the gateways subsystem.`,
  [`An organization is required.`]: `An organization is required.`,
  [`An unknown error occurred.`]: `An unknown error occurred.`,
  [`Analytics`]: `Analytics`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Analytics Insights Service'`,
  [`Analytics Details`]: `Analytics Details`,
  [`Analytics destinations`]: `Analytics destinations`,
  [`Analytics Director keystore`]: `Analytics Director keystore`,
  [`Analytics Service`]: `Analytics Service`,
  [`Analytics Service URL`]: `Analytics Service URL`,
  [`Analytics Service {arg} has been removed.`]: `Analytics Service {arg} has been removed.`,
  [`Analytics details`]: `Analytics details`,
  [`Analytics service`]: `Analytics service`,
  [`Analytics service details`]: `Analytics service details`,
  [`Analytics service URL`]: `Analytics service URL`,
  [`Analytics service {arg} has been removed.`]: `Analytics service {arg} has been removed.`,
  [`Analytics client TLS client profile`]: `Analytics client TLS client profile`,
  [`Analytics client keystore`]: `Analytics client keystore`,
  [`Analytics client truststore`]: `Analytics client truststore`,
  [`Analytics ingestion TLS client profile`]: `Analytics ingestion TLS client profile`,
  [`Analytics ingestion keystore`]: `Analytics ingestion keystore`,
  [`Analytics ingestion truststore`]: `Analytics ingestion truststore`,
  [`Analytics service {title} has been created.`]: `Analytics service {title} has been created.`,
  [`Analytics service {title} has been updated.`]: `Analytics service {title} has been updated.`,
  [`The selected consumer organization is`]: `The selected consumer organization is`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Analytics services are configured and analytics data is offloaded externally`,
  [`Analyze API usage and performance`]: `Analyze API usage and performance`,
  [`Anonymous bind`]: `Anonymous bind`,
  [`Another user registry`]: `Another user registry`,
  [`api-administrator`]: `api-administrator`,
  [`Api-Analytics`]: `Api-Analytics`,
  [`Api-Drafts`]: `Api-Drafts`,
  [`App`]: `App`,
  [`App name`]: `App name`,
  [`App-Analytics`]: `App-Analytics`,
  [`App-Approval`]: `App-Approval`,
  [`App-Dev`]: `App-Dev`,
  [`App-analytics`]: `App-analytics`,
  [`App-dev`]: `App-dev`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Application`,
  [`Application Authentication`]: `Application Authentication`,
  [`Application Authentication Source`]: `Application Authentication Source`,
  [`Application lifecycle`]: `Application lifecycle`,
  [`Application approval task for upgrading application`]: `Application approval task for upgrading application`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`,
  [`Application authentication`]: `Application authentication`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`,
  [`Application is being created. Please Wait`]: `Application is being created. Please Wait`,
  [`Application scope check`]: `Application scope check`,
  [`Applications`]: `Applications`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Applications that existed before turning on application lifecycle will remain in the production state`,
  [`Applied security`]: `Applied security`,
  [`Apply`]: `Apply`,
  [`Apply all`]: `Apply all`,
  [`Apply all suggestions`]: `Apply all suggestions`,
  [`Apply analysis configuration`]: `Apply analysis configuration`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`,
  [`Apply to selected`]: `Apply to selected`,
  [`Apply type analysis configuration`]: `Apply type analysis configuration`,
  [`Approval History`]: `Approval History`,
  [`Approval tasks`]: `Approval tasks`,
  [`Approvals`]: `Approvals`,
  [`Approve`]: `Approve`,
  [`Archive`]: `Archive`,
  [`Archive Product`]: `Archive Product`,
  [`Archive|permission`]: `Archive`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Are you sure you want to clear the constraints from your selected items?`,
  [`Are you sure you want to delete API?`]: `Are you sure you want to delete API?`,
  [`Are you sure you want to delete Product?`]: `Are you sure you want to delete Product?`,
  [`Are you sure you want to delete a catalog?`]: `Are you sure you want to delete a catalog?`,
  [`Are you sure you want to delete a space?`]: `Are you sure you want to delete a space?`,
  [`Are you sure you want to delete this application?`]: `Are you sure you want to delete this application?`,
  [`Are you sure you want to delete this billing integration?`]: `Are you sure you want to delete this billing integration?`,
  [`Are you sure you want to delete this field?`]: `Are you sure you want to delete this field?`,
  [`Are you sure you want to delete this policy?`]: `Are you sure you want to delete this policy?`,
  [`Are you sure you want to delete this type?`]: `Are you sure you want to delete this type?`,
  [`Are you sure you want to disable spaces?`]: `Are you sure you want to disable spaces?`,
  [`Are you sure you want to disable custom email sender information?`]: `Are you sure you want to disable custom email sender information?`,
  [`Are you sure you want to disable custom notification templates?`]: `Are you sure you want to disable custom notification templates?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`,
  [`Are you sure you want to enable spaces?`]: `Are you sure you want to enable spaces?`,
  [`Are you sure you want to enable custom email sender information?`]: `Are you sure you want to enable custom email sender information?`,
  [`Are you sure you want to enable custom notification templates?`]: `Are you sure you want to enable custom notification templates?`,
  [`Are you sure you want to proceed with delete?`]: `Are you sure you want to proceed with delete?`,
  [`Are you sure you want to publish?`]: `Are you sure you want to publish?`,
  [`Are you sure you want to re-stage?`]: `Are you sure you want to re-stage?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Are you sure you want to remove the product from the catalog?`,
  [`Are you sure you want to unassociate?`]: `Are you sure you want to unassociate?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Are you sure you want to update Self Service Onboarding`,
  [`Are you sure?`]: `Are you sure?`,
  [`Argument`]: `Argument`,
  [`Array`]: `Array`,
  [`Assemble`]: `Assemble`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Assemble the policy flow required for the OAuth provider.`,
  [`Assembly`]: `Assembly`,
  [`Assembly Execute`]: `Assembly Execute`,
  [`Assembly saved`]: `Assembly saved`,
  [`Assembly burst limits`]: `Assembly burst limits`,
  [`Assembly count limits`]: `Assembly count limits`,
  [`Assign roles`]: `Assign roles`,
  [`Assign roles to the current owner`]: `Assign roles to the current owner`,
  [`Associate`]: `Associate`,
  [`Associate analytics service`]: `Associate analytics service`,
  [`Associate analytics`]: `Associate analytics`,
  [`Assumed size`]: `Assumed size`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Authetication error trying to get API from URL {url}. Check username and password.`,
  [`Audience claim`]: `Audience claim`,
  [`Audience Claim`]: `Audience Claim`,
  [`Audit setting`]: `Audit setting`,
  [`Audit setting has been changed`]: `Audit setting has been changed`,
  [`Auditing is **{mode}**`]: `Auditing is **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`,
  [`Authenticate Client Method`]: `Authenticate Client Method`,
  [`Authenticate password`]: `Authenticate password`,
  [`Authenticate user`]: `Authenticate user`,
  [`Authenticate user settings`]: `Authenticate user settings`,
  [`Authenticate application users using`]: `Authenticate application users using`,
  [`Authenticate using Basic Authentication`]: `Authenticate using Basic Authentication`,
  [`Authenticate using OAuth`]: `Authenticate using OAuth`,
  [`Authenticated`]: `Authenticated`,
  [`Authenticated bind`]: `Authenticated bind`,
  [`Authenticated User Name`]: `Authenticated User Name`,
  [`Authentication`]: `Authentication`,
  [`Authentication Bind`]: `Authentication Bind`,
  [`Authentication error`]: `Authentication error`,
  [`Authentication method`]: `Authentication method`,
  [`Authentication TLS profile`]: `Authentication TLS profile`,
  [`Authentication URL`]: `Authentication URL`,
  [`Authentication URL user registry`]: `Authentication URL user registry`,
  [`Authentication URL user registry has been created.`]: `Authentication URL user registry has been created.`,
  [`Authentication URL to use for validation.`]: `Authentication URL to use for validation.`,
  [`Authentication response header credential`]: `Authentication response header credential`,
  [`Authentication response header pattern`]: `Authentication response header pattern`,
  [`Authentication type`]: `Authentication type`,
  [`Authentication type to use to validate the UsernameToken.`]: `Authentication type to use to validate the UsernameToken.`,
  [`Authorization`]: `Authorization`,
  [`Authorization Code`]: `Authorization Code`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`,
  [`Authorization endpoint`]: `Authorization endpoint`,
  [`Authorization Request`]: `Authorization Request`,
  [`Authorization URL`]: `Authorization URL`,
  [`Authorize`]: `Authorize`,
  [`Authorize User Settings`]: `Authorize User Settings`,
  [`Authorize application users using`]: `Authorize application users using`,
  [`Authorize path`]: `Authorize path`,
  [`Authors API and product definitions`]: `Authors API and product definitions`,
  [`Auto Generate OIDC API Assembly`]: `Auto Generate OIDC API Assembly`,
  [`Auto onboard`]: `Auto onboard`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Automatically-generated name for use in the API Connect management APIs and commands.`,
  [`Automation`]: `Automation`,
  [`Automation Management Console`]: `Automation Management Console`,
  [`Automation management console`]: `Automation management console`,
  [`Availability Zone`]: `Availability Zone`,
  [`Availability zone`]: `Availability zone`,
  [`Availability zone {arg} has been removed.`]: `Availability zone {arg} has been removed.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`,
  [`Average response time: {{value}}ms`]: `Average response time: {{value}}ms`,
  [`Average time`]: `Average time`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Avoid CORS errors by using the API Designer server as a local proxy.`,
  [`Back`]: `Back`,
  [`Back to sign in`]: `Back to sign in`,
  [`Back to test`]: `Back to test`,
  [`Backend type`]: `Backend type`,
  [`Bad gateway`]: `Bad gateway`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Base DN`,
  [`Base Path`]: `Base Path`,
  [`Base path`]: `Base path`,
  [`Base endpoint`]: `Base endpoint`,
  [`Base endpoints`]: `Base endpoints`,
  [`Base object`]: `Base object`,
  [`Base64 encoding`]: `Base64 encoding`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `Base64 encoding for invitations and password reset tokens is **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Base64 encoding setting for temporary token has been changed`,
  [`Base URL of API invocation endpoint`]: `Base URL of API invocation endpoint`,
  [`Basepath root`]: `Basepath root`,
  [`Basic`]: `Basic`,
  [`Basic authentication`]: `Basic authentication`,
  [`Basic authentication password`]: `Basic authentication password`,
  [`Basic authentication request header name`]: `Basic authentication request header name`,
  [`Basic authentication username`]: `Basic authentication username`,
  [`Before you begin`]: `Before you begin`,
  [`Before you begin...`]: `Before you begin...`,
  [`Between 0 and 10 events are waiting to be processed`]: `Between 0 and 10 events are waiting to be processed`,
  [`Between 10 and 20 events are waiting to be processed`]: `Between 10 and 20 events are waiting to be processed`,
  [`Billing`]: `Billing`,
  [`Billing and Payment`]: `Billing and Payment`,
  [`Billing has been updated`]: `Billing has been updated`,
  [`Billing integrations`]: `Billing integrations`,
  [`Billing integration`]: `Billing integration`,
  [`Billing Integration`]: `Billing Integration`,
  [`Billing {title} successfully added.`]: `Billing {title} successfully added.`,
  [`Billing {title} successfully deleted.`]: `Billing {title} successfully deleted.`,
  [`Billing {title} successfully updated.`]: `Billing {title} successfully updated.`,
  [`Billing Options`]: `Billing Options`,
  [`Blocklist`]: `Blocklist`,
  [`Blocking`]: `Blocking`,
  [`Blocking option`]: `Blocking option`,
  [`Body`]: `Body`,
  [`Boolean`]: `Boolean`,
  [`Both`]: `Both`,
  [`Browse`]: `Browse`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Buffer API requests and responses before being processed on the DataPower API Gateway.`,
  [`Buffering`]: `Buffering`,
  [`Builds and manages apps in the developer organization`]: `Builds and manages apps in the developer organization`,
  [`Burst Limit Name`]: `Burst Limit Name`,
  [`Burst limits`]: `Burst limits`,
  [`Burst limit value can't be negative`]: `Burst limit value can't be negative`,
  [`Buy`]: `Buy`,
  [`but unable to remove because of limitation`]: `but unable to remove because of limitation`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`,
  [`By payload`]: `By payload`,
  [`By URL parameter`]: `By URL parameter`,
  [`Bytes`]: `Bytes`,
  [`CALLS`]: `CALLS`,
  [`Calls`]: `Calls`,
  [`CATALOG`]: `CATALOG`,
  [`CERTIFICATE NAME`]: `CERTIFICATE NAME`,
  [`CIPHER`]: `CIPHER`,
  [`CIPHER SUITES`]: `CIPHER SUITES`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `CLI only`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `Client ID`,
  [`CLOUD ADMINISTRATOR`]: `CLOUD ADMINISTRATOR`,
  [`Consumer organization`]: `Consumer organization`,
  [`CONSUMER ORGANIZATION / GROUP`]: `CONSUMER ORGANIZATION / GROUP`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA bundle`,
  [`Cache Key`]: `Cache Key`,
  [`Cache Time to Live (second)`]: `Cache Time to Live (second)`,
  [`Cache key`]: `Cache key`,
  [`Cache Time-To-Live`]: `Cache Time-To-Live`,
  [`Cache type`]: `Cache type`,
  [`Can't find the one you want?`]: `Can't find the one you want?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Can't send an invite with invalid mail server, please check your mail server configurations and try again`,
  [`Cancel`]: `Cancel`,
  [`Cannot be below {min}`]: `Cannot be below {min}`,
  [`Cannot be empty`]: `Cannot be empty`,
  [`Cannot delete root type.`]: `Cannot delete root type.`,
  [`Cannot exceed {max}`]: `Cannot exceed {max}`,
  [`Cannot find any APIs for this product`]: `Cannot find any APIs for this product`,
  [`Cannot find the one you want?`]: `Cannot find the one you want?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`,
  [`Cannot remove built-in scalar types`]: `Cannot remove built-in scalar types`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Cannot remove the query, mutation, and subscription root operation type`,
  [`Cannot remove arguments of built-in directives`]: `Cannot remove arguments of built-in directives`,
  [`Cannot remove non-null arguments of directives`]: `Cannot remove non-null arguments of directives`,
  [`Cannot remove input types of arguments of directives`]: `Cannot remove input types of arguments of directives`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Cannot remove enum values used as default values of arguments of directives`,
  [`Cannot remove enum values used as default values of input fields`]: `Cannot remove enum values used as default values of input fields`,
  [`Cannot remove all fields of an object type`]: `Cannot remove all fields of an object type`,
  [`Cannot remove all fields of an interface type`]: `Cannot remove all fields of an interface type`,
  [`Cannot remove all slicing arguments`]: `Cannot remove all slicing arguments`,
  [`Cannot remove all input fields of an input object type`]: `Cannot remove all input fields of an input object type`,
  [`Cannot remove all values of enum type`]: `Cannot remove all values of enum type`,
  [`Cannot remove fields of interfaces`]: `Cannot remove fields of interfaces`,
  [`Cannot remove non-null input fields`]: `Cannot remove non-null input fields`,
  [`Case`]: `Case`,
  [`Case sensitive`]: `Case sensitive`,
  [`Catalog`]: `Catalog`,
  [`Catalog defaults`]: `Catalog defaults`,
  [`Catalog Invite`]: `Catalog Invite`,
  [`Catalog invite`]: `Catalog invite`,
  [`Catalog invites`]: `Catalog invites`,
  [`Catalog name`]: `Catalog name`,
  [`Catalog owner`]: `Catalog owner`,
  [`Catalog owner invitation`]: `Catalog owner invitation`,
  [`Catalog properties`]: `Catalog properties`,
  [`Catalog summary`]: `Catalog summary`,
  [`Catalog title`]: `Catalog title`,
  [`Catalog user registries`]: `Catalog user registries`,
  [`Catalog by Name`]: `Catalog by Name`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`,
  [`Catalog {name} created`]: `Catalog {name} created`,
  [`Catalog selection`]: `Catalog selection`,
  [`Catalog settings`]: `Catalog settings`,
  [`Catalog settings have been updated`]: `Catalog settings have been updated`,
  [`Catalog User`]: `Catalog User`,
  [`Catalog Users`]: `Catalog Users`,
  [`Catalog user registry`]: `Catalog user registry`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `Catalog with LifeCycle enabled cannot be used in Test Preferences.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`,
  [`Catalogs`]: `Catalogs`,
  [`Catch`]: `Catch`,
  [`Catches`]: `Catches`,
  [`Categories`]: `Categories`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`,
  [`Category`]: `Category`,
  [`Certifcate or Shared Secret for Verify`]: `Certifcate or Shared Secret for Verify`,
  [`Certificate`]: `Certificate`,
  [`Certificate (Optional)`]: `Certificate (Optional)`,
  [`Certificate management`]: `Certificate management`,
  [`Certificate Manager instance`]: `Certificate Manager instance`,
  [`Certificate (optional)`]: `Certificate (optional)`,
  [`Certificates`]: `Certificates`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`,
  [`Certificates details in this keystore`]: `Certificates details in this keystore`,
  [`Certificates details in this truststore`]: `Certificates details in this truststore`,
  [`Change`]: `Change`,
  [`Change Consumer Organization owner to an existing user`]: `Change Consumer Organization owner to an existing user`,
  [`Change Ownership to an Existing User`]: `Change Ownership to an Existing User`,
  [`Change portal service`]: `Change portal service`,
  [`Change owner to existing user`]: `Change owner to existing user`,
  [`Change owner to new user`]: `Change owner to new user`,
  [`Change ownership by inviting a new user`]: `Change ownership by inviting a new user`,
  [`Change ownership to`]: `Change ownership to`,
  [`Change ownership to a new user via email invitation`]: `Change ownership to a new user via email invitation`,
  [`Change ownership to an existing user`]: `Change ownership to an existing user`,
  [`Change password`]: `Change password`,
  [`Change setup`]: `Change setup`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`,
  [`Change the following extra constraints in the schema:`]: `Change the following extra constraints in the schema:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`,
  [`Check your email`]: `Check your email`,
  [`Child`]: `Child`,
  [`Chinese (Simplified)`]: `Chinese (Simplified)`,
  [`Chinese (Traditional)`]: `Chinese (Traditional)`,
  [`Choose`]: `Choose`,
  [`Choose a`]: `Choose a`,
  [`Choose {docTypeLabel}`]: `Choose {docTypeLabel}`,
  [`Choose Catalog`]: `Choose Catalog`,
  [`Choose a Catalog`]: `Choose a Catalog`,
  [`Choose a Consumer Org`]: `Choose a Consumer Org`,
  [`Choose Gateway`]: `Choose Gateway`,
  [`Choose a Gateway`]: `Choose a Gateway`,
  [`Choose Product`]: `Choose Product`,
  [`Choose a Product`]: `Choose a Product`,
  [`Choose Rate Limit`]: `Choose Rate Limit`,
  [`Choose a Rate Limit`]: `Choose a Rate Limit`,
  [`Choose Application`]: `Choose Application`,
  [`Choose an Application`]: `Choose an Application`,
  [`Choose a {docTypeLabel}`]: `Choose a {docTypeLabel}`,
  [`Choose a publish destination`]: `Choose a publish destination`,
  [`Choose a billing integration`]: `Choose a billing integration`,
  [`Choose a catalog to test within:`]: `Choose a catalog to test within:`,
  [`Choose a plan against which to test:`]: `Choose a plan against which to test:`,
  [`Choose a product containing this API, or create a new one:`]: `Choose a product containing this API, or create a new one:`,
  [`Choose a user registry`]: `Choose a user registry`,
  [`Choose an application with which to test, or create a new one:`]: `Choose an application with which to test, or create a new one:`,
  [`Choose an existing application`]: `Choose an existing application`,
  [`Choose an existing catalog`]: `Choose an existing catalog`,
  [`Choose an existing product published to your Sandbox catalog`]: `Choose an existing product published to your Sandbox catalog`,
  [`Choose an operation to invoke:`]: `Choose an operation to invoke:`,
  [`Choose an option`]: `Choose an option`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Choosing blocking mode will have adverse effects on the system performance`,
  [`Choose existing directory`]: `Choose existing directory`,
  [`Choose one...`]: `Choose one...`,
  [`Choose operations and paths to generate into this API`]: `Choose operations and paths to generate into this API`,
  [`Choose paths to generate into this API`]: `Choose paths to generate into this API`,
  [`Choose the DNS scheme`]: `Choose the DNS scheme`,
  [`Choose the permissions for the role.`]: `Choose the permissions for the role.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`,
  [`Cipher`]: `Cipher`,
  [`Ciphers`]: `Ciphers`,
  [`Clear`]: `Clear`,
  [`Clear constraints`]: `Clear constraints`,
  [`Clear file`]: `Clear file`,
  [`Clear filters`]: `Clear filters`,
  [`Clear from selected`]: `Clear from selected`,
  [`Clear operation filter`]: `Clear operation filter`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Click a certificate to view details regarding its issuing organization and fingerprint.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`,
  [`Click add to add a billing integration.`]: `Click add to add a billing integration.`,
  [`Click Add to add a gateway extension.`]: `Click Add to add a gateway extension.`,
  [`Click **Add domain** to add domains.`]: `Click **Add domain** to add domains.`,
  [`Click Add to add a new base endpoint.`]: `Click Add to add a new base endpoint.`,
  [`Click Add to add a new HTTP header.`]: `Click Add to add a new HTTP header.`,
  [`Click Add to add a new member.`]: `Click Add to add a new member.`,
  [`Click Add to add a new role.`]: `Click Add to add a new role.`,
  [`Click Add to add a new scope.`]: `Click Add to add a new scope.`,
  [`Click Add to add a new consumer organization group.`]: `Click Add to add a new consumer organization group.`,
  [`Click Add to create the API Key.`]: `Click Add to create the API Key.`,
  [`Click Create to add the API Key.`]: `Click Create to add the API Key.`,
  [`Click Create to add a new user registry.`]: `Click Create to add a new user registry.`,
  [`Click Create user registry to add a new user registry.`]: `Click Create user registry to add a new user registry.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Click **Create provider organization** to add provider organizations.`,
  [`Click Edit to add OAuth providers.`]: `Click Edit to add OAuth providers.`,
  [`Click Edit to add a user registry.`]: `Click Edit to add a user registry.`,
  [`Click Edit to add an API to the product.`]: `Click Edit to add an API to the product.`,
  [`Click Create to add a property.`]: `Click Create to add a property.`,
  [`Click Edit to add TLS client profiles.`]: `Click Edit to add TLS client profiles.`,
  [`Click Edit to add user registries.`]: `Click Edit to add user registries.`,
  [`Click Add to add a new consumer organization.`]: `Click Add to add a new consumer organization.`,
  [`Click Consumer organizations to add one.`]: `Click Consumer organizations to add one.`,
  [`Click Add to add a new application.`]: `Click Add to add a new application.`,
  [`Click Add to add parameters.`]: `Click Add to add parameters.`,
  [`Click Add to add response.`]: `Click Add to add response.`,
  [`Click Create to add a new TLS client profile.`]: `Click Create to add a new TLS client profile.`,
  [`Click Create to add a new keystore.`]: `Click Create to add a new keystore.`,
  [`Click Create to add a new truststore.`]: `Click Create to add a new truststore.`,
  [`Click **Register remote gateway** to add gateways.`]: `Click **Register remote gateway** to add gateways.`,
  [`Click Register gateways to add new Gateway Services.`]: `Click Register gateways to add new Gateway Services.`,
  [`Click Edit to add Gateway Services.`]: `Click Edit to add Gateway Services.`,
  [`Click Register gateways to add new gateway services.`]: `Click Register gateways to add new gateway services.`,
  [`Click Edit to add gateway services.`]: `Click Edit to add gateway services.`,
  [`Click Edit to add an email server.`]: `Click Edit to add an email server.`,
  [`Click Create to add a new TLS server profile.`]: `Click Create to add a new TLS server profile.`,
  [`Click Create to add a new email server.`]: `Click Create to add a new email server.`,
  [`Click Add to add a new schema definition.`]: `Click Add to add a new schema definition.`,
  [`Click Add to add a new property for this definition.`]: `Click Add to add a new property for this definition.`,
  [`Click Add to add a new parameter for this API.`]: `Click Add to add a new parameter for this API.`,
  [`Click Add to add tags and external documentation for this API.`]: `Click Add to add tags and external documentation for this API.`,
  [`Click Add to add a new path.`]: `Click Add to add a new path.`,
  [`Click Add to add a property for this API.`]: `Click Add to add a property for this API.`,
  [`Click here to create a security definition`]: `Click here to create a security definition`,
  [`Click Add to add a new security definition.`]: `Click Add to add a new security definition.`,
  [`Click Add to add a target service for this API.`]: `Click Add to add a target service for this API.`,
  [`Click Add to add an operation for this path.`]: `Click Add to add an operation for this path.`,
  [`Click Add to add a path parameter for this path.`]: `Click Add to add a path parameter for this path.`,
  [`Click Add to add a new OAuth provider.`]: `Click Add to add a new OAuth provider.`,
  [`Click Add to add a new provider organization.`]: `Click Add to add a new provider organization.`,
  [`Click Add to add a new API or product.`]: `Click Add to add a new API or product.`,
  [`Click Add to add a new API.`]: `Click Add to add a new API.`,
  [`Click Add to add a new Extension.`]: `Click Add to add a new Extension.`,
  [`Click Add to add new {resource}.`]: `Click Add to add new {resource}.`,
  [`Click to expand`]: `Click to expand`,
  [`Click to collapse`]: `Click to collapse`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Click to learn how to upload and manage the certificates required by the gateway.`,
  [`Client Credentials`]: `Client Credentials`,
  [`Client information`]: `Client information`,
  [`Client revocation path`]: `Client revocation path`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Click Register service to register a service. This will allow you to test endpoints when configuring other services.`,
  [`Client secret`]: `Client secret`,
  [`Client security`]: `Client security`,
  [`Client Type`]: `Client Type`,
  [`Client authentication method`]: `Client authentication method`,
  [`Clone`]: `Clone`,
  [`Close`]: `Close`,
  [`Cloud`]: `Cloud`,
  [`Cloud Admin`]: `Cloud Admin`,
  [`Cloud Administrator`]: `Cloud Administrator`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`,
  [`Cloud administration`]: `Cloud administration`,
  [`Cloud Discovery Agent`]: `Cloud Discovery Agent`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Cloud Manager Local User Registry`,
  [`Cloud-Settings`]: `Cloud-Settings`,
  [`Cloud settings invitation timeout`]: `Cloud settings invitation timeout`,
  [`Cloud settings invitation timeout has been changed`]: `Cloud settings invitation timeout has been changed`,
  [`Code editor`]: `Code editor`,
  [`Collect Metadata`]: `Collect Metadata`,
  [`Collect credentials using`]: `Collect credentials using`,
  [`Collect metadata`]: `Collect metadata`,
  [`Combo box`]: `Combo box`,
  [`Community Manager`]: `Community Manager`,
  [`community-manager`]: `community-manager`,
  [`Compatibility`]: `Compatibility`,
  [`Complex Definitions must be edited in the editor`]: `Complex Definitions must be edited in the editor`,
  [`Compose DN`]: `Compose DN`,
  [`Compose UPN`]: `Compose UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `Compose a new REST proxy by defining paths and operations`,
  [`Compose a new product by adding rate limits and plans`]: `Compose a new product by adding rate limits and plans`,
  [`Compression`]: `Compression`,
  [`Condition`]: `Condition`,
  [`Condition editor`]: `Condition editor`,
  [`- Conditional content: \{\{#if context-variable-name\}\}...\{\{/if\}\}`]: `- Conditional content: \{\{#if context-variable-name\}\}...\{\{/if\}\}`,
  [`Confidential`]: `Confidential`,
  [`Configuration`]: `Configuration`,
  [`Configurations`]: `Configurations`,
  [`Configure API endpoint`]: `Configure API endpoint`,
  [`Configure API Gateway Service`]: `Configure API Gateway Service`,
  [`Configure analytics service`]: `Configure analytics service`,
  [`Configure availability zone`]: `Configure availability zone`,
  [`Configure catalog user registries`]: `Configure catalog user registries`,
  [`Configure cloud`]: `Configure cloud`,
  [`Configure DataPower API gateway service`]: `Configure DataPower API gateway service`,
  [`Configure DataPower gateway service`]: `Configure DataPower gateway service`,
  [`Configure Email Server`]: `Configure Email Server`,
  [`Configure gateway extension`]: `Configure gateway extension`,
  [`Configure portal service`]: `Configure portal service`,
  [`Configure service`]: `Configure service`,
  [`Configure topology`]: `Configure topology`,
  [`Configure XML`]: `Configure XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Configure a DataPower API gateway service for securing and enforcing APIs`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Configure a DataPower gateway service for securing and enforcing APIs`,
  [`Configure a developer portal service for API consumers`]: `Configure a developer portal service for API consumers`,
  [`Configure advanced features for OIDC settings.`]: `Configure advanced features for OIDC settings.`,
  [`Configure an analytics service to collect API call data`]: `Configure an analytics service to collect API call data`,
  [`Configure an email server to send invitations and notifications to users.`]: `Configure an email server to send invitations and notifications to users.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`,
  [`Configure availability zones and services`]: `Configure availability zones and services`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Configure how API endpoint URLs are composed in published APIs`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Configure how vanity endpoints are displayed in the developer portal`,
  [`Configure mapping`]: `Configure mapping`,
  [`Configure properties of the activity log`]: `Configure properties of the activity log`,
  [`Configure settings for token management and revocation.`]: `Configure settings for token management and revocation.`,
  [`Configure settings to generate and validate tokens.`]: `Configure settings to generate and validate tokens.`,
  [`Configure the API security`]: `Configure the API security`,
  [`Configure the default set of gateway services configured for each catalog`]: `Configure the default set of gateway services configured for each catalog`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Configure the developer portal that is used by application developers to access the APIs in this catalog`,
  [`Configure the gateway services used by default in each catalog`]: `Configure the gateway services used by default in each catalog`,
  [`Configure the gateway extension for the selected gateway`]: `Configure the gateway extension for the selected gateway`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`,
  [`Configure the keystore and upload certificates`]: `Configure the keystore and upload certificates`,
  [`Configure the name and email address to be used in the from field of emails`]: `Configure the name and email address to be used in the from field of emails`,
  [`Configure the portal service for the catalog`]: `Configure the portal service for the catalog`,
  [`Configure the role and assign permissions`]: `Configure the role and assign permissions`,
  [`Configure the security of this API`]: `Configure the security of this API`,
  [`Configure the sender name and address to use for email notifications`]: `Configure the sender name and address to use for email notifications`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Configure the sender name, address and the email server used to send invitations and notifications to users`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Configure the set of roles to use by default when a consumer organization is created`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Configure the set of roles to use by default when a provider organization is created`,
  [`Configure the templates used to invite and notify users`]: `Configure the templates used to invite and notify users`,
  [`Configure the truststore and upload certificates`]: `Configure the truststore and upload certificates`,
  [`Configure user authentication using`]: `Configure user authentication using`,
  [`Configure user authentication using JSON Web Tokens`]: `Configure user authentication using JSON Web Tokens`,
  [`Configure user authentication using a LDAP provider`]: `Configure user authentication using a LDAP provider`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Configure user authentication using an API Connect Local User Registry`,
  [`Configure user authentication using an authentication URL`]: `Configure user authentication using an authentication URL`,
  [`Configure user authentication using this user registry type`]: `Configure user authentication using this user registry type`,
  [`Configure user registries, OAuth providers and TLS`]: `Configure user registries, OAuth providers and TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Configure user registries, TLS, OAuth providers and email servers`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Configure Keystore for Access token, ID token and Temporary token`,
  [`Configured OAuth Provider`]: `Configured OAuth Provider`,
  [`Configured OAuth Providers`]: `Configured OAuth Providers`,
  [`Configured OAuth provider`]: `Configured OAuth provider`,
  [`Configured OAuth provider list has been updated`]: `Configured OAuth provider list has been updated`,
  [`Configured OAuth provider list has not been updated`]: `Configured OAuth provider list has not been updated`,
  [`Confirm`]: `Confirm`,
  [`Confirm deletion`]: `Confirm deletion`,
  [`Confirm Deletion of Portal`]: `Confirm Deletion of Portal`,
  [`Confirm visibility settings`]: `Confirm visibility settings`,
  [`Confirm new password`]: `Confirm new password`,
  [`Confirm password`]: `Confirm password`,
  [`Confirm password should match password`]: `Confirm password should match password`,
  [`Confirmation`]: `Confirmation`,
  [`Congratulations, you are now registered.`]: `Congratulations, you are now registered.`,
  [`Congratulations, your password has been reset!`]: `Congratulations, your password has been reset!`,
  [`Connect`]: `Connect`,
  [`Connect to API Connect`]: `Connect to API Connect`,
  [`Connected to API Manager Product version`]: `Connected to API Manager Product version`,
  [`Connect to Cloud`]: `Connect to Cloud`,
  [`Connected`]: `Connected`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Construct your schema definition by adding properties of the required types. You can nest properties`,
  [`Consider for nesting`]: `Consider for nesting`,
  [`Consumed Media Types`]: `Consumed Media Types`,
  [`Consumer`]: `Consumer`,
  [`Consumer invitation and roles`]: `Consumer invitation and roles`,
  [`Consumer onboarding`]: `Consumer onboarding`,
  [`Consumer organizations`]: `Consumer organizations`,
  [`Consumer organizations or groups`]: `Consumer organizations or groups`,
  [`Consumer organization {name} created`]: `Consumer organization {name} created`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`,
  [`Consumer-Org`]: `Consumer-Org`,
  [`Consumers`]: `Consumers`,
  [`Consumes`]: `Consumes`,
  [`Contact`]: `Contact`,
  [`Contact information for the owners of the API.`]: `Contact information for the owners of the API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Contact your administrator about configuring the analytics service so you can view your data here.`,
  [`Contact your administrator for more information.`]: `Contact your administrator for more information.`,
  [`Content`]: `Content`,
  [`Content on error`]: `Content on error`,
  [`Content type`]: `Content type`,
  [`Context Variable`]: `Context Variable`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`,
  [`Context variable`]: `Context variable`,
  [`Continue`]: `Continue`,
  [`Continue on error`]: `Continue on error`,
  [`Continue with`]: `Continue with`,
  [`Continue with:`]: `Continue with:`,
  [`Conversion type`]: `Conversion type`,
  [`Convert to expression`]: `Convert to expression`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `Copied`,
  [`Copied to clipboard`]: `Copied to clipboard`,
  [`Copy`]: `Copy`,
  [`Copy Id Headers To Message`]: `Copy Id Headers To Message`,
  [`Copy to clipboard`]: `Copy to clipboard`,
  [`Copy file path to clipboard`]: `Copy file path to clipboard`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Copy the cost estimate for the GraphQL query to the output`,
  [`Copy the endpoints below to`]: `Copy the endpoints below to`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Copy the API Key to use. You are able to see this API Key again with the View icon.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Copyright Information`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Correct the errors in the editor above or re-upload a valid file`,
  [`Cost`]: `Cost`,
  [`Count`]: `Count`,
  [`Count Limit Name`]: `Count Limit Name`,
  [`Count Limits`]: `Count Limits`,
  [`Created at`]: `Created at`,
  [`Counter`]: `Counter`,
  [`Create`]: `Create`,
  [`Create a [Certificate Manager]({link}) service`]: `Create a [Certificate Manager]({link}) service`,
  [`Create API`]: `Create API`,
  [`Create an API from the Develop section.`]: `Create an API from the Develop section.`,
  [`Create API Connect API Key`]: `Create API Connect API Key`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Create API from existing GraphQL service (GraphQL proxy)`,
  [`Create API from existing OpenAPI service`]: `Create API from existing OpenAPI service`,
  [`Create API from existing WSDL service (REST proxy)`]: `Create API from existing WSDL service (REST proxy)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Create API from existing WSDL service (SOAP proxy)`,
  [`Create API from target service`]: `Create API from target service`,
  [`Create API from existing REST service`]: `Create API from existing REST service`,
  [`Create API from existing SOAP service`]: `Create API from existing SOAP service`,
  [`Create API from existing WSDL service`]: `Create API from existing WSDL service`,
  [`Create API Key failed`]: `Create API Key failed`,
  [`Create application`]: `Create application`,
  [`Create authentication URL user registry`]: `Create authentication URL user registry`,
  [`Create availability zone`]: `Create availability zone`,
  [`Create Billing`]: `Create Billing`,
  [`Create catalog`]: `Create catalog`,
  [`Create catalog property`]: `Create catalog property`,
  [`Create consumer organization`]: `Create consumer organization`,
  [`Create Credentials`]: `Create Credentials`,
  [`Create definition`]: `Create definition`,
  [`Create email server`]: `Create email server`,
  [`Create IBM Developer Portal`]: `Create IBM Developer Portal`,
  [`Create keystore`]: `Create keystore`,
  [`Create LDAP user registry`]: `Create LDAP user registry`,
  [`Create LTPA Key`]: `Create LTPA Key`,
  [`Create local user registry`]: `Create local user registry`,
  [`Create native OAuth provider`]: `Create native OAuth provider`,
  [`Create New GraphQL API`]: `Create New GraphQL API`,
  [`Create new OpenAPI`]: `Create new OpenAPI`,
  [`Create new product`]: `Create new product`,
  [`Create OIDC user registry`]: `Create OIDC user registry`,
  [`Create OpenAPI definition and product definition`]: `Create OpenAPI definition and product definition`,
  [`Create operation`]: `Create operation`,
  [`Create organization`]: `Create organization`,
  [`Create path`]: `Create path`,
  [`Create plan`]: `Create plan`,
  [`Create portal`]: `Create portal`,
  [`Create Product`]: `Create Product`,
  [`Create property`]: `Create property`,
  [`Create role`]: `Create role`,
  [`Create role default for the Organization`]: `Create role default for the Organization`,
  [`Create sample user registry`]: `Create sample user registry`,
  [`Create security definition`]: `Create security definition`,
  [`Create space`]: `Create space`,
  [`Create subscription`]: `Create subscription`,
  [`Create TLS client profile`]: `Create TLS client profile`,
  [`Create TLS server profile`]: `Create TLS server profile`,
  [`Create target service`]: `Create target service`,
  [`Create third party OAuth provider`]: `Create third party OAuth provider`,
  [`Create truststore`]: `Create truststore`,
  [`Create user registry`]: `Create user registry`,
  [`Confirm Navigation`]: `Confirm Navigation`,
  [`You are about to redirect to the user registry page. Are you sure you want to exit the current flow?`]: `You are about to redirect to the user registry page. Are you sure you want to exit the current flow?`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Create a GraphQL proxy based on a GraphQL service`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Create a REST proxy based on an OpenAPI described target service`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Create a REST proxy based upon a WSDL described target service`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Create a REST proxy based upon an OpenAPI described target service`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Create a REST proxy based upon the WSDL described target service`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Create a REST proxy that routes all traffic to a target API or service endpoint`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Create a SOAP proxy based upon a WSDL described target service`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Create a SOAP proxy based upon the WSDL described target service`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Create a new object using a handlebars template. Variables are taken from the context.`,
  [`Create a portal site for the catalog`]: `Create a portal site for the catalog`,
  [`Create a product by importing a product definition`]: `Create a product by importing a product definition`,
  [`Create a security definition`]: `Create a security definition`,
  [`Create a subscription`]: `Create a subscription`,
  [`Create an API by importing an OpenAPI definition`]: `Create an API by importing an OpenAPI definition`,
  [`Create an API that calls an existing SOAP service`]: `Create an API that calls an existing SOAP service`,
  [`Create and manage API provider organizations and owners`]: `Create and manage API provider organizations and owners`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`,
  [`Create and publish`]: `Create and publish`,
  [`Create and publish APIs and Products`]: `Create and publish APIs and Products`,
  [`Create and subscribe`]: `Create and subscribe`,
  [`Create assembly`]: `Create assembly`,
  [`Create empty parent object for failed mapping`]: `Create empty parent object for failed mapping`,
  [`Create endpoint`]: `Create endpoint`,
  [`Create from`]: `Create from`,
  [`Create from Existing WSDL service`]: `Create from Existing WSDL service`,
  [`Create from File or URL`]: `Create from File or URL`,
  [`Create from existing openAPI service`]: `Create from existing openAPI service`,
  [`Create gateway extension`]: `Create gateway extension`,
  [`Create new user`]: `Create new user`,
  [`Create new version`]: `Create new version`,
  [`Create product using a template`]: `Create product using a template`,
  [`Create provider organization`]: `Create provider organization`,
  [`Create required child properties for array objects and mapped optional objects`]: `Create required child properties for array objects and mapped optional objects`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`,
  [`Created new product`]: `Created new product`,
  [`Create|permission`]: `Create`,
  [`Create|title`]: `Create`,
  [`Creating Draft Product`]: `Creating Draft Product`,
  [`Creating a Consumer organization`]: `Creating a Consumer organization`,
  [`Creating a consumer organization`]: `Creating a consumer organization`,
  [`Creating a new Product`]: `Creating a new Product`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons (CC-BY-4.0) license`,
  [`Credential Extraction Method`]: `Credential Extraction Method`,
  [`Credentials for the Sandbox catalog`]: `Credentials for the Sandbox catalog`,
  [`Credentials for the {catalogName} catalog`]: `Credentials for the {catalogName} catalog`,
  [`Credential Name`]: `Credential Name`,
  [`Credential has been added.`]: `Credential has been added.`,
  [`Credential`]: `Credential`,
  [`Credentials`]: `Credentials`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Credentials are required to run the API Designer and Toolkit.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`,
  [`Credit Card`]: `Credit Card`,
  [`Cryptographic Algorithm`]: `Cryptographic Algorithm`,
  [`Currency`]: `Currency`,
  [`Current password`]: `Current password`,
  [`Current password is invalid.`]: `Current password is invalid.`,
  [`Custom`]: `Custom`,
  [`Custom API URL`]: `Custom API URL`,
  [`Custom expression (optional, one per line)`]: `Custom expression (optional, one per line)`,
  [`Custom expression to remove (optional, one per line)`]: `Custom expression to remove (optional, one per line)`,
  [`Custom HTML form`]: `Custom HTML form`,
  [`Custom policies`]: `Custom policies`,
  [`Custom form TLS profile`]: `Custom form TLS profile`,
  [`Custom form content security policy`]: `Custom form content security policy`,
  [`Custom form CSP header value`]: `Custom form CSP header value`,
  [`Custom form endpoint`]: `Custom form endpoint`,
  [`Custom header pattern`]: `Custom header pattern`,
  [`Custom subscribability is required if custom visibility is selected`]: `Custom subscribability is required if custom visibility is selected`,
  [`Customize`]: `Customize`,
  [`Customize email sender information`]: `Customize email sender information`,
  [`Customize notification templates`]: `Customize notification templates`,
  [`Customize the plan API list`]: `Customize the plan API list`,
  [`Czech`]: `Czech`,
  [`DATE MODIFIED`]: `DATE MODIFIED`,
  [`DELETE`]: `DELETE`,
  [`Date created`]: `Date created`,
  [`Description`]: `Description`,
  [`DETAIL`]: `DETAIL`,
  [`DN Base`]: `DN Base`,
  [`DNS Schemes`]: `DNS Schemes`,
  [`Dashboard`]: `Dashboard`,
  [`Data encoded form body`]: `Data encoded form body`,
  [`Data flow in API Connect`]: `Data flow in API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (v5 compatible)`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway (v5 compatible)`,
  [`DataPower Gateway policies`]: `DataPower Gateway policies`,
  [`Debug`]: `Debug`,
  [`Debug rule`]: `Debug rule`,
  [`Debug XACML policy`]: `Debug XACML policy`,
  [`Dec`]: `Dec`,
  [`Decline`]: `Decline`,
  [`Decline and send`]: `Decline and send`,
  [`Decode Request Params`]: `Decode Request Params`,
  [`Decrement`]: `Decrement`,
  [`Decrypt`]: `Decrypt`,
  [`Decrypt Crypto JWK variable name`]: `Decrypt Crypto JWK variable name`,
  [`Decrypt Crypto Object`]: `Decrypt Crypto Object`,
  [`Decrypt a buffer using the specified certificate`]: `Decrypt a buffer using the specified certificate`,
  [`Default`]: `Default`,
  [`Default behavior`]: `Default behavior`,
  [`Default gateways`]: `Default gateways`,
  [`default gateway services configured`]: `default gateway services configured`,
  [`Default Availability Zone`]: `Default Availability Zone`,
  [`Default Base endpoints`]: `Default Base endpoints`,
  [`Default availability zone`]: `Default availability zone`,
  [`Default base endpoints`]: `Default base endpoints`,
  [`Default HTML form`]: `Default HTML form`,
  [`Default limit`]: `Default limit`,
  [`Default OAuth Provider Settings`]: `Default OAuth Provider Settings`,
  [`Default OAuth Provider Settings Object`]: `Default OAuth Provider Settings Object`,
  [`Default Plan`]: `Default Plan`,
  [`Default TLS client profile`]: `Default TLS client profile`,
  [`Default TLS server keystore`]: `Default TLS server keystore`,
  [`Default TLS server profile`]: `Default TLS server profile`,
  [`Default burst-limit`]: `Default burst-limit`,
  [`Default form`]: `Default form`,
  [`Default keystore for access_token signing key`]: `Default keystore for access_token signing key`,
  [`Default keystore for id_token signing key`]: `Default keystore for id_token signing key`,
  [`Default keystore for temporary token signing key`]: `Default keystore for temporary token signing key`,
  [`Default rate-limit`]: `Default rate-limit`,
  [`Default scopes`]: `Default scopes`,
  [`Default table entry`]: `Default table entry`,
  [`Default validator endpoint`]: `Default validator endpoint`,
  [`Default validator endpoint set by OAuth provider`]: `Default validator endpoint set by OAuth provider`,
  [`Default value - no extra constraints apply.`]: `Default value - no extra constraints apply.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`,
  [`Define catalog specific values for this property`]: `Define catalog specific values for this property`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Define groups that manage APIs, products, catalogs, applications, and related settings.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Define the API protection source. At least one option must be enabled.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Define the inputs to be used in the map. You can also assign a title and description to the map.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider.`]: `Define the OpenID provider endpoints to send an authentication request to your OpenID provider.`,
  [`Define the outputs to be used in the map.`]: `Define the outputs to be used in the map.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`,
  [`Definition`]: `Definition`,
  [`Definition Object`]: `Definition Object`,
  [`Definitions`]: `Definitions`,
  [`Delete`]: `Delete`,
  [`Delete Application`]: `Delete Application`,
  [`Delete Cloud Connection.`]: `Delete Cloud Connection.`,
  [`Delete Selected APIs`]: `Delete Selected APIs`,
  [`Delete selected client profiles`]: `Delete selected client profiles`,
  [`Delete selected keystores`]: `Delete selected keystores`,
  [`Delete selected mail servers`]: `Delete selected mail servers`,
  [`Delete Selected Products`]: `Delete Selected Products`,
  [`Delete selected truststores`]: `Delete selected truststores`,
  [`Delete selected user registries`]: `Delete selected user registries`,
  [`Delete Staged Product`]: `Delete Staged Product`,
  [`Delete a space`]: `Delete a space`,
  [`Delete a catalog`]: `Delete a catalog`,
  [`Delete API Key`]: `Delete API Key`,
  [`Delete API Key failed`]: `Delete API Key failed`,
  [`Delete availability zone`]: `Delete availability zone`,
  [`Delete selected requests`]: `Delete selected requests`,
  [`Delete row`]: `Delete row`,
  [`Delete|title`]: `Delete`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`,
  [`Deleting {{name}}...`]: `Deleting {name}...`,
  [`Deprecate`]: `Deprecate`,
  [`Deprecate product`]: `Deprecate product`,
  [`Deprecated`]: `Deprecated`,
  [`Deprecate|permission`]: `Deprecate`,
  [`Description of gateway`]: `Description of gateway`,
  [`Description text for the behaviour of the modal.`]: `Description text for the behaviour of the modal.`,
  [`Descriptive name`]: `Descriptive name`,
  [`Design`]: `Design`,
  [`Designate a current member as owner of a consumer organization.`]: `Designate a current member as owner of a consumer organization.`,
  [`Designate a current member as owner of a provider organization.`]: `Designate a current member as owner of a provider organization.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Destination`,
  [`Determines which credential-extraction method to use.`]: `Determines which credential-extraction method to use.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Determine whether to include this type/field and any applicable arguments in the schema.`,
  [`Develop`]: `Develop`,
  [`Develop APIs`]: `Develop APIs`,
  [`Develop APIs and products`]: `Develop APIs and products`,
  [`Developer`]: `Developer`,
  [`developer`]: `developer`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `Developer community`,
  [`Developers can request a state upgrade from development to production`]: `Developers can request a state upgrade from development to production`,
  [`Development`]: `Development`,
  [`Directive`]: `Directive`,
  [`Director Endpoint`]: `Director Endpoint`,
  [`Director TLS client profile`]: `Director TLS client profile`,
  [`Disable`]: `Disable`,
  [`Disable Activity log`]: `Disable Activity log`,
  [`Disable Self-Service Onboarding`]: `Disable Self-Service Onboarding`,
  [`Disable spaces`]: `Disable spaces`,
  [`Disable buffering`]: `Disable buffering`,
  [`Disable Self-Service Onboarding Approval`]: `Disable Self-Service Onboarding Approval`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Disable overriding consumer organizations invitation timeout.`,
  [`Disabled`]: `Disabled`,
  [`disabled`]: `disabled`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Disabling self-service onboarding approval will automatically approve all onboarding requests.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`,
  [`Discover targets`]: `Discover targets`,
  [`Display table checkboxes`]: `Display table checkboxes`,
  [`Display vanity endpoint`]: `Display vanity endpoint`,
  [`Do not publish API (continue editting)`]: `Do not publish API (continue editting)`,
  [`Do not require applications or users to authenticate`]: `Do not require applications or users to authenticate`,
  [`Do not require authentication`]: `Do not require authentication`,
  [`Do not share the resource with provider organizations`]: `Do not share the resource with provider organizations`,
  [`Do not share the API gateway with provider organizations`]: `Do not share the API gateway with provider organizations`,
  [`Do not share the service with provider organizations`]: `Do not share the service with provider organizations`,
  [`Do not use Dynamic DNS`]: `Do not use Dynamic DNS`,
  [`Do you want to remove these references?`]: `Do you want to remove these references?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker docs`,
  [`Docs`]: `Docs`,
  [`Document Editor`]: `Document Editor`,
  [`Documentation and tutorials with step-by-step instructions`]: `Documentation and tutorials with step-by-step instructions`,
  [`Domain name`]: `Domain name`,
  [`Domain Name`]: `Domain Name`,
  [`Domains handled by gateway via SNI`]: `Domains handled by gateway via SNI`,
  [`Don't have an account?`]: `Don't have an account?`,
  [`Done`]: `Done`,
  [`Download`]: `Download`,
  [`Download Gateway`]: `Download Gateway`,
  [`Download and install`]: `Download and install`,
  [`Download and set up DataPower API Gateway`]: `Download and set up DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Download and set up DataPower API Gateway for use on premises or on any cloud.`,
  [`Download for Linux`]: `Download for Linux`,
  [`Download for Mac`]: `Download for Mac`,
  [`Download for Windows`]: `Download for Windows`,
  [`Download gateway`]: `Download gateway`,
  [`Download schema`]: `Download schema`,
  [`Download Toolkit`]: `Download Toolkit`,
  [`Download toolkit`]: `Download toolkit`,
  [`Download toolkit and credentials for various platforms`]: `Download toolkit and credentials for various platforms`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Downloaded Credentials not found for this cloud connection. Login will use default credentials.`,
  [`Dutch`]: `Dutch`,
  [`Draft {type} creation failed`]: `Draft {type} creation failed`,
  [`Drafts`]: `Drafts`,
  [`Drag and drop files here or click to upload`]: `Drag and drop files here or click to upload`,
  [`Drag & Drop`]: `Drag & Drop`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `Dynamic DNS`,
  [`Dynamic OAuth configuration from a URL`]: `Dynamic OAuth configuration from a URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Dynamic OAuth configuration from a literal string`,
  [`Dynamic group`]: `Dynamic group`,
  [`Dynamic table entries`]: `Dynamic table entries`,
  [`- Dynamic value: \{\{?context-variable-name\}\}`]: `- Dynamic value: \{\{?context-variable-name\}\}`,
  [`ENABLE`]: `ENABLE`,
  [`Encoded`]: `Encoded`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Each provider organization owns a set of APIs, products, plans, and catalogs.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`,
  [`Edit`]: `Edit`,
  [`Edit access token TTL`]: `Edit access token TTL`,
  [`Edit admin organization invitation timeout`]: `Edit admin organization invitation timeout`,
  [`Edit API`]: `Edit API`,
  [`Edit API Connect UI`]: `Edit API Connect UI`,
  [`Edit API endpoint for unenforced APIs`]: `Edit API endpoint for unenforced APIs`,
  [`Edit API gateway service`]: `Edit API gateway service`,
  [`Edit API Path`]: `Edit API Path`,
  [`Edit API security definition`]: `Edit API security definition`,
  [`Edit API user registries`]: `Edit API user registries`,
  [`Edit analytics service`]: `Edit analytics service`,
  [`Edit application`]: `Edit application`,
  [`Edit Assembly`]: `Edit Assembly`,
  [`Edit authentication URL user registry`]: `Edit authentication URL user registry`,
  [`Edit availability zone`]: `Edit availability zone`,
  [`Edit Availablity Zone`]: `Edit Availablity Zone`,
  [`Edit and apply constraints to your selected items.`]: `Edit and apply constraints to your selected items.`,
  [`Edit billing integration`]: `Edit billing integration`,
  [`Edit Catalog Details`]: `Edit Catalog Details`,
  [`Edit cloud setting invitation timeout`]: `Edit cloud setting invitation timeout`,
  [`Edit constraints`]: `Edit constraints`,
  [`Edit consumer organization`]: `Edit consumer organization`,
  [`Edit DNS Scheme`]: `Edit DNS Scheme`,
  [`Edit DataPower API gateway service`]: `Edit DataPower API gateway service`,
  [`Edit DataPower gateway service`]: `Edit DataPower gateway service`,
  [`Edit definition`]: `Edit definition`,
  [`Edit dynamic DNS`]: `Edit dynamic DNS`,
  [`Edit email server`]: `Edit email server`,
  [`Edit extensions`]: `Edit extensions`,
  [`Edit Gateway Service`]: `Edit Gateway Service`,
  [`Edit gateway`]: `Edit gateway`,
  [`Edit gateway extension`]: `Edit gateway extension`,
  [`Edit Gateways`]: `Edit Gateways`,
  [`Edit IBM-managed gateway`]: `Edit IBM-managed gateway`,
  [`Edit Invitation Timeout`]: `Edit Invitation Timeout`,
  [`Edit gateways`]: `Edit gateways`,
  [`Edit invitation timeout`]: `Edit invitation timeout`,
  [`Edit JSON`]: `Edit JSON`,
  [`Edit keystore`]: `Edit keystore`,
  [`Edit LDAP user registry`]: `Edit LDAP user registry`,
  [`Edit LTPA Token`]: `Edit LTPA Token`,
  [`Edit lifecycle approvals`]: `Edit lifecycle approvals`,
  [`Edit local user registry`]: `Edit local user registry`,
  [`Edit native OAuth provider`]: `Edit native OAuth provider`,
  [`Edit New Billing System`]: `Edit New Billing System`,
  [`Edit Notification Server`]: `Edit Notification Server`,
  [`Edit Notification template`]: `Edit Notification template`,
  [`Edit OAuth Provider`]: `Edit OAuth Provider`,
  [`Edit OAuth provider details`]: `Edit OAuth provider details`,
  [`Edit OAuth Provider Visibility`]: `Edit OAuth Provider Visibility`,
  [`Edit OIDC User Registry`]: `Edit OIDC User Registry`,
  [`Edit notification template`]: `Edit notification template`,
  [`Edit OAuth provider`]: `Edit OAuth provider`,
  [`Edit OAuth provider visibility`]: `Edit OAuth provider visibility`,
  [`Edit OIDC user registry`]: `Edit OIDC user registry`,
  [`Edit onboarding`]: `Edit onboarding`,
  [`Edit operation`]: `Edit operation`,
  [`Edit organization`]: `Edit organization`,
  [`Edit path`]: `Edit path`,
  [`Edit path parameters`]: `Edit path parameters`,
  [`Edit plan`]: `Edit plan`,
  [`Edit portal`]: `Edit portal`,
  [`Edit portal service`]: `Edit portal service`,
  [`Edit Portal Settings`]: `Edit Portal Settings`,
  [`Edit product`]: `Edit product`,
  [`Edit product APIs`]: `Edit product APIs`,
  [`Edit Product Subscribability`]: `Edit Product Subscribability`,
  [`Edit Product Visibility`]: `Edit Product Visibility`,
  [`Edit Property`]: `Edit Property`,
  [`Edit Provider Organization`]: `Edit Provider Organization`,
  [`Edit Refresh Token TTL`]: `Edit Refresh Token TTL`,
  [`Edit remote gateway`]: `Edit remote gateway`,
  [`Edit Reset Password TTL`]: `Edit Reset Password TTL`,
  [`Edit Role`]: `Edit Role`,
  [`Edit Role Default for Provider Organizations`]: `Edit Role Default for Provider Organizations`,
  [`Edit Role for Provider Organizations`]: `Edit Role for Provider Organizations`,
  [`Edit property`]: `Edit property`,
  [`Edit provider organization`]: `Edit provider organization`,
  [`Edit refresh token TTL`]: `Edit refresh token TTL`,
  [`Edit reset password TTL`]: `Edit reset password TTL`,
  [`Edit role`]: `Edit role`,
  [`Edit role default for Provider Organizations`]: `Edit role default for Provider Organizations`,
  [`Edit role for Provider Organizations`]: `Edit role for Provider Organizations`,
  [`Edit Roles`]: `Edit Roles`,
  [`Edit Schema in JSON/XML`]: `Edit Schema in JSON/XML`,
  [`Edit security definition`]: `Edit security definition`,
  [`Edit sender`]: `Edit sender`,
  [`Edit sender & email server`]: `Edit sender & email server`,
  [`Edit sender Info`]: `Edit sender Info`,
  [`Edit service visibility`]: `Edit service visibility`,
  [`Edit show/hide settings`]: `Edit show/hide settings`,
  [`Edit space details`]: `Edit space details`,
  [`Edit TLS client profile`]: `Edit TLS client profile`,
  [`Edit TLS client profile visibility`]: `Edit TLS client profile visibility`,
  [`Edit TLS server profile`]: `Edit TLS server profile`,
  [`Edit third party OAuth provider`]: `Edit third party OAuth provider`,
  [`Edit truststore`]: `Edit truststore`,
  [`Edit unenforced API`]: `Edit unenforced API`,
  [`Edit user registries`]: `Edit user registries`,
  [`Edit user registry`]: `Edit user registry`,
  [`Edit user registry visibility`]: `Edit user registry visibility`,
  [`Edit user registry for API Manager`]: `Edit user registry for API Manager`,
  [`Edit user registry for Cloud Manager`]: `Edit user registry for Cloud Manager`,
  [`Edit vanity API endpoints`]: `Edit vanity API endpoints`,
  [`Edit visibility`]: `Edit visibility`,
  [`Edit XML`]: `Edit XML`,
  [`Edit app lifecycle approved`]: `Edit app lifecycle approved`,
  [`Edit app lifecycle cancelled`]: `Edit app lifecycle cancelled`,
  [`Edit app lifecycle denied`]: `Edit app lifecycle denied`,
  [`Edit app lifecycle pending`]: `Edit app lifecycle pending`,
  [`Edit app lifecycle request`]: `Edit app lifecycle request`,
  [`Edit app reinstated`]: `Edit app reinstated`,
  [`Edit app suspended`]: `Edit app suspended`,
  [`Edit audit setting`]: `Edit audit setting`,
  [`Edit definitions`]: `Edit definitions`,
  [`Enable external group mapping`]: `Enable external group mapping`,
  [`Edit extra constraints for the GraphQL schema`]: `Edit extra constraints for the GraphQL schema`,
  [`Edit GraphQL schema|title`]: `Edit GraphQL schema`,
  [`Edit inline schema`]: `Edit inline schema`,
  [`Edit inputs`]: `Edit inputs`,
  [`Edit invitation`]: `Edit invitation`,
  [`Edit mail server test connection`]: `Edit mail server test connection`,
  [`Edit member invitation`]: `Edit member invitation`,
  [`Edit notification templates`]: `Edit notification templates`,
  [`Edit notification templates:`]: `Edit notification templates:`,
  [`Edit operation list`]: `Edit operation list`,
  [`Edit or upload JSON schema`]: `Edit or upload JSON schema`,
  [`Edit outputs`]: `Edit outputs`,
  [`Edit password changed`]: `Edit password changed`,
  [`Edit password reset`]: `Edit password reset`,
  [`Edit plan APIs`]: `Edit plan APIs`,
  [`Edit schema`]: `Edit schema`,
  [`Edit schema|button`]: `Edit schema`,
  [`Edit schema|title`]: `Edit schema`,
  [`Edit settings for roles, notifications and more.`]: `Edit settings for roles, notifications and more.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Edit settings for user registries, roles, endpoints, and more`,
  [`Edit sign up`]: `Edit sign up`,
  [`Edit task product lifecycle approved`]: `Edit task product lifecycle approved`,
  [`Edit task product lifecycle cancelled`]: `Edit task product lifecycle cancelled`,
  [`Edit task product lifecycle denied`]: `Edit task product lifecycle denied`,
  [`Edit task product lifecycle pending`]: `Edit task product lifecycle pending`,
  [`Edit task product lifecycle request`]: `Edit task product lifecycle request`,
  [`Edit task subscription approved`]: `Edit task subscription approved`,
  [`Edit task subscription cancelled`]: `Edit task subscription cancelled`,
  [`Edit task subscription denied`]: `Edit task subscription denied`,
  [`Edit task subscription pending`]: `Edit task subscription pending`,
  [`Edit task subscription request`]: `Edit task subscription request`,
  [`Edit template`]: `Edit template`,
  [`Edit the TLS client profile.`]: `Edit the TLS client profile.`,
  [`Edit the TLS Server profiles`]: `Edit the TLS Server profiles`,
  [`Edit the keystore title.`]: `Edit the keystore title.`,
  [`Edit the organization summary details as required`]: `Edit the organization summary details as required`,
  [`Edit the organization title`]: `Edit the organization title`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Edit the parameters to configure an email server. Fields are required unless designated as optional.`,
  [`Edit signed token`]: `Edit signed token`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Edit the text for the email template. Variables are contained in braces and cannot be edited.`,
  [`Edit the truststore.`]: `Edit the truststore.`,
  [`Edit the visibility for each resource.`]: `Edit the visibility for each resource.`,
  [`Edit user details`]: `Edit user details`,
  [`Edit {notificationTemplate}`]: `Edit {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `Edit, assemble, secure and test APIs`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`,
  [`Edit, upload, or infer XML schema`]: `Edit, upload, or infer XML schema`,
  [`Editors`]: `Editors`,
  [`Edit|permission`]: `Edit`,
  [`Edit API key timeout`]: `Edit API key timeout`,
  [`Edit Base64 encoding for temporary tokens`]: `Edit Base64 encoding for temporary tokens`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Either "ID token signing crypto object" or "ID token signing key" must be provided.`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`,
  [`Element name for JSON array elements`]: `Element name for JSON array elements`,
  [`Email`]: `Email`,
  [`Email address`]: `Email address`,
  [`Email Content`]: `Email Content`,
  [`Email endpoint`]: `Email endpoint`,
  [`Email endpoint is required when the application uses the legacy (non-OIDC compliant) protocol for LinkedIn.`]: `Email endpoint is required when the application uses the legacy (non-OIDC compliant) protocol for LinkedIn.`,
  [`Email required`]: `Email required`,
  [`Email sender`]: `Email sender`,
  [`Email server`]: `Email server`,
  [`Email server configuration`]: `Email server configuration`,
  [`Email servers`]: `Email servers`,
  [`Empty case`]: `Empty case`,
  [`Empty catch`]: `Empty catch`,
  [`Empty JSON array handling`]: `Empty JSON array handling`,
  [`Empty XML element handling`]: `Empty XML element handling`,
  [`Enable`]: `Enable`,
  [`Enable API consumers to invite collaborators and assign roles`]: `Enable API consumers to invite collaborators and assign roles`,
  [`Enable application lifecycle`]: `Enable application lifecycle`,
  [`Enable billing`]: `Enable billing`,
  [`Enable gateway services`]: `Enable gateway services`,
  [`Enable gateway services and policies`]: `Enable gateway services and policies`,
  [`Enable GraphiQL`]: `Enable GraphiQL`,
  [`Enable GraphiQL editor`]: `Enable GraphiQL editor`,
  [`Enable GraphiQL Editor on browser`]: `Enable GraphiQL Editor on browser`,
  [`Enable JSON post processing`]: `Enable JSON post processing`,
  [`Enable lifecycle approvals`]: `Enable lifecycle approvals`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed).`]: `Enable NONCE extension to prevent compromised requests from being used again (replayed).`,
  [`Enable OAuth providers`]: `Enable OAuth providers`,
  [`Enable OIDC`]: `Enable OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Enable OpenID Connect template to generate ID tokens.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted.`]: `Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted.`,
  [`Enable production mode`]: `Enable production mode`,
  [`Enable Proxy`]: `Enable Proxy`,
  [`Enable Self-Service Onboarding`]: `Enable Self-Service Onboarding`,
  [`Enable spaces`]: `Enable spaces`,
  [`Enable token management`]: `Enable token management`,
  [`Enable buffering`]: `Enable buffering`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `Enable API consumers to create development portal accounts without requiring an invitation.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `Enable cross-origin resource sharing (CORS) access control for your API.`,
  [`Enable debug response headers`]: `Enable debug response headers`,
  [`Enable proof key for code exchange`]: `Enable proof key for code exchange`,
  [`Enable publishing of this product`]: `Enable publishing of this product`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Enable redirect of third-party authorization code through Portal OIDC endpoint`,
  [`Enable security`]: `Enable security`,
  [`Enable Self-Service Onboarding Approval`]: `Enable Self-Service Onboarding Approval`,
  [`Enable the following gateway services and policies for this catalog`]: `Enable the following gateway services and policies for this catalog`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`,
  [`Enable/disable custom notification templates`]: `Enable/disable custom notification templates`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Enable or disable Base64 encoding for invitations and password reset tokens`,
  [`Enable overriding consumer organizations invitation timeout`]: `Enable overriding consumer organizations invitation timeout`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`,
  [`Enabled`]: `Enabled`,
  [`enabled`]: `enabled`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`,
  [`Encode Query params`]: `Encode Query params`,
  [`Encrypt`]: `Encrypt`,
  [`Encrypt Crypto Object`]: `Encrypt Crypto Object`,
  [`Encrypt JWK variable name`]: `Encrypt JWK variable name`,
  [`Encrypt a buffer using the specified certificate`]: `Encrypt a buffer using the specified certificate`,
  [`Encryption Algorithm`]: `Encryption Algorithm`,
  [`Endpoint`]: `Endpoint`,
  [`Endpoint URLs`]: `Endpoint URLs`,
  [`Endpoint behavior`]: `Endpoint behavior`,
  [`Endpoint Behavior`]: `Endpoint Behavior`,
  [`Endpoint for unenforced APIs`]: `Endpoint for unenforced APIs`,
  [`Endpoints`]: `Endpoints`,
  [`Enforce Required Params`]: `Enforce Required Params`,
  [`Enforce the API by using API Connect Gateway.`]: `Enforce the API by using API Connect Gateway.`,
  [`Enforced`]: `Enforced`,
  [`English`]: `English`,
  [`Enter Summary`]: `Enter Summary`,
  [`Enter URL`]: `Enter URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Enter user registry name. If empty, API Manager Local User Registry is used by default`,
  [`Enter a catalog name`]: `Enter a catalog name`,
  [`Enter a Display Name`]: `Enter a Display Name`,
  [`Enter a space name`]: `Enter a space name`,
  [`Enter a brief description`]: `Enter a brief description`,
  [`Enter a display name`]: `Enter a display name`,
  [`Enter a temporary sign in password`]: `Enter a temporary sign in password`,
  [`Enter an Organization Name`]: `Enter an Organization Name`,
  [`Enter an Organization Title`]: `Enter an Organization Title`,
  [`Enter catalog title`]: `Enter catalog title`,
  [`Enter details of the consumer organization`]: `Enter details of the consumer organization`,
  [`Enter details of the product`]: `Enter details of the product`,
  [`Enter details of the provider organization`]: `Enter details of the provider organization`,
  [`Enter details of this API`]: `Enter details of this API`,
  [`Enter email of the new user`]: `Enter email of the new user`,
  [`Enter first name of the new user`]: `Enter first name of the new user`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Enter one or more email addresses. Use commas to separate multiple addresses.`,
  [`Enter organization name`]: `Enter organization name`,
  [`Enter organization title`]: `Enter organization title`,
  [`Enter password`]: `Enter password`,
  [`Enter space title`]: `Enter space title`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Enter the API summary details`,
  [`Enter the API summary details. [Learn more]({link})`]: `Enter the API summary details. [Learn more]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Enter the URL for the target service you would like to proxy`,
  [`Enter the catalog summary details`]: `Enter the catalog summary details`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Enter the catalog summary details; you can fully configure the catalog after you create it`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Enter the email address of the user to invite as a member of the admin organization`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Enter the email address of the user to invite as a member of the catalog`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Enter the email address of the user to invite as a member of the provider organization`,
  [`Enter the email address of the user to invite as a member of the space`]: `Enter the email address of the user to invite as a member of the space`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Enter the email address of the user to whom you want to transfer ownership.`,
  [`Enter the full path to JSON or YAML file`]: `Enter the full path to JSON or YAML file`,
  [`Enter the full path to WSDL file`]: `Enter the full path to WSDL file`,
  [`Enter the full path to YAML file`]: `Enter the full path to YAML file`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`,
  [`Enter the last name of the new user`]: `Enter the last name of the new user`,
  [`Enter the owner's email address to send the invitation`]: `Enter the owner's email address to send the invitation`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`,
  [`Enter the password for the certificate file if required.`]: `Enter the password for the certificate file if required.`,
  [`Enter the provider organization summary details`]: `Enter the provider organization summary details`,
  [`Enter the schema definitiom summary details`]: `Enter the schema definitiom summary details`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Enter the space summary details; you can fully configure the Space after you create it`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Enter the space summary details; you can fully configure the space after you create it`,
  [`Enter the summary details for the new product`]: `Enter the summary details for the new product`,
  [`Enter the summary details for this role`]: `Enter the summary details for this role`,
  [`Enter title of the organization`]: `Enter title of the organization`,
  [`Enter user email`]: `Enter user email`,
  [`Enter username of the existing user`]: `Enter username of the existing user`,
  [`Enter username of the new user`]: `Enter username of the new user`,
  [`Error`]: `Error`,
  [`Error ID`]: `Error ID`,
  [`Error:`]: `Error:`,
  [`Copy error ID to Clipboard`]: `Copy error ID to Clipboard`,
  [`Error Console`]: `Error Console`,
  [`Error connecting`]: `Error connecting`,
  [`Error connecting to GraphQL server`]: `Error connecting to GraphQL server`,
  [`Error getting user info during login. Please login with different user.`]: `Error getting user info during login. Please login with different user.`,
  [`Error content`]: `Error content`,
  [`Error message`]: `Error message`,
  [`Error name`]: `Error name`,
  [`Error status code`]: `Error status code`,
  [`Error status code is invalid`]: `Error status code is invalid`,
  [`Error status reason`]: `Error status reason`,
  [`Errors`]: `Errors`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`,
  [`Event`]: `Event`,
  [`Event gateway management client TLS client profile`]: `Event gateway management client TLS client profile`,
  [`Event load`]: `Event load`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`,
  [`Exchange authorization code for tokens`]: `Exchange authorization code for tokens`,
  [`Exchange code for token`]: `Exchange code for token`,
  [`Execute`]: `Execute`,
  [`Execute migration target`]: `Execute migration target`,
  [`Executes any catalog-level post-invoke handlers`]: `Executes any catalog-level post-invoke handlers`,
  [`Executes any catalog-level pre-invoke handlers`]: `Executes any catalog-level pre-invoke handlers`,
  [`Existing`]: `Existing`,
  [`Existing {docType}`]: `Existing {docType}`,
  [`Existing API Connect user`]: `Existing API Connect user`,
  [`Existing OpenAPI`]: `Existing OpenAPI`,
  [`Existing product`]: `Existing product`,
  [`Existing SOAP API has been validated`]: `Existing SOAP API has been validated`,
  [`Existing products in the catalog will be moved to a default space.`]: `Existing products in the catalog will be moved to a default space.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Existing users are current members. You can also activate a new member from another user registry.`,
  [`Expires On`]: `Expires On`,
  [`Expiry`]: `Expiry`,
  [`Explorer`]: `Explorer`,
  [`Expose`]: `Expose`,
  [`Extend the schema to specify the cost of types and fields`]: `Extend the schema to specify the cost of types and fields`,
  [`Extended`]: `Extended`,
  [`Extension Type:`]: `Extension Type:`,
  [`Extensions`]: `Extensions`,
  [`Extension has been uploaded.`]: `Extension has been uploaded.`,
  [`External`]: `External`,
  [`External Doc Description`]: `External Doc Description`,
  [`External doc description`]: `External Doc Description`,
  [`External Documentation URL`]: `External Documentation URL`,
  [`External documentation URL`]: `External Documentation URL`,
  [`External Role mapping`]: `External Role mapping`,
  [`External URL`]: `External URL`,
  [`Extract Identity Settings`]: `Extract Identity Settings`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `Factor ID`,
  [`Fail`]: `Fail`,
  [`Failed to change account password.`]: `Failed to change account password.`,
  [`Failed to create analytics service {title}.`]: `Failed to create analytics service {title}.`,
  [`Failed to create analytics insights service {title}.`]: `Failed to create analytics insights service {title}.`,
  [`Analytics insights service {title} has been updated.`]: `Analytics insights service {title} has been updated.`,
  [`About the analytics insights service`]: `About the analytics insights service`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`,
  [`Advanced analytics insights configuration`]: `Advanced analytics insights configuration`,
  [`Analytics insights service {title} has been created.`]: `Analytics insights service {title} has been created.`,
  [`Analytics Insights details`]: `Analytics Insights details`,
  [`Management endpoint on the analytics insights service`]: `Management endpoint on the analytics insights service`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`,
  [`TYPE`]: `TYPE`,
  [`Failed to create email server`]: `Failed to create email server`,
  [`Failed to create gateway service`]: `Failed to create gateway service`,
  [`Failed to create gateway service {name}.`]: `Failed to create gateway service {name}.`,
  [`Failed to create user registry {title}.`]: `Failed to create user registry {title}.`,
  [`Failed to delete a group`]: `Failed to delete a group.`,
  [`Failed to get TLS client profiles`]: `Failed to get TLS client profiles`,
  [`Failed to get catalogs`]: `Failed to get catalogs`,
  [`Failed to get configured OAuth providers`]: `Failed to get configured OAuth providers`,
  [`Failed to get policies of gateways`]: `Failed to get policies of gateways`,
  [`Failed to get user registries`]: `Failed to get user registries`,
  [`Failed to introspect schema from server:`]: `Failed to introspect schema from server:`,
  [`Failed to make API online`]: `Failed to make API online`,
  [`Failed to register catalog member {user}.`]: `Failed to register catalog member {user}.`,
  [`Failed to remove analytics service: {arg}`]: `Failed to remove analytics service: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Failed to remove availability zone: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Failed to remove gateway service: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Failed to remove mailserver: {arg}`,
  [`Failed to remove portal service: {arg}`]: `Failed to remove portal service: {arg}`,
  [`Failed to create {name}.`]: `Failed to create {name}.`,
  [`Failed to sign out! Please check the console for details.`]: `Failed to sign out! Please check the console for details.`,
  [`Failed to update {name}.`]: `Failed to update {name}.`,
  [`Failed to update OAuth provider`]: `Failed to update OAuth provider`,
  [`Failed to update account.`]: `Failed to update account.`,
  [`Failed to update analytics service {title}.`]: `Failed to update analytics service {title}.`,
  [`Failed to update gateway service {title}.`]: `Failed to update gateway service {title}.`,
  [`Failed to update task`]: `Failed to update task`,
  [`Failed to create app {title}`]: `Failed to create app {title}`,
  [`Feature flags`]: `Feature flags`,
  [`Fetch schema`]: `Fetch schema`,
  [`Fetched endpoints`]: `Fetched endpoints`,
  [`Field`]: `Field`,
  [`Field label`]: `Field label`,
  [`Field Mapping`]: `Field Mapping`,
  [`Field name`]: `Field name`,
  [`Field weight`]: `Field weight`,
  [`File Name`]: `File Name`,
  [`File Preview`]: `File Preview`,
  [`File contains validation errors`]: `File contains validation errors`,
  [`File is not a valid OpenAPI definition`]: `File is not a valid OpenAPI definition`,
  [`File is not a valid extension format.`]: `File is not a valid extension format.`,
  [`File is not a valid {fileType} format.`]: `File is not a valid {fileType} format.`,
  [`File name`]: `File name`,
  [`File upload`]: `File upload`,
  [`File uploaded is not a valid file`]: `File uploaded is not a valid file`,
  [`File uploaded successfully`]: `File uploaded successfully`,
  [`File was updated and validated!`]: `File was updated and validated!`,
  [`File was uploaded and validated!`]: `File was uploaded and validated!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `File {draftFilePath} exists. Try changing name/version combination of the draft`,
  [`Fill out the details of this service`]: `Fill out the details of this service`,
  [`Filter`]: `Filter`,
  [`Filter by operation`]: `Filter by operation`,
  [`Filter policies`]: `Filter policies`,
  [`Final Action`]: `Final Action`,
  [`Find existing user`]: `Find existing user`,
  [`Find expert answers in the API Connect community forum`]: `Find expert answers in the API Connect community forum`,
  [`Find expert answers in the API Connect community forum.`]: `Find expert answers in the API Connect community forum.`,
  [`Find schema`]: `Find schema`,
  [`Finger Print`]: `Finger Print`,
  [`Fingerprint SHA-1`]: `Fingerprint SHA-1`,
  [`Fingerprint SHA-256`]: `Fingerprint SHA-256`,
  [`Finish`]: `Finish`,
  [`First name`]: `First name`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`,
  [`Fit to screen`]: `Fit to screen`,
  [`Flow`]: `Flow`,
  [`Follow redirects`]: `Follow redirects`,
  [`Found In`]: `Found In`,
  [`Fonts contained in the following file are available under the`]: `Fonts contained in the following file are available under the`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`,
  [`For guidance on the available command set, access the CLI's help`]: `For guidance on the available command set, access the CLI's help`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`,
  [`Forbidden`]: `Forbidden`,
  [`Forget token`]: `Forget token`,
  [`Forgot password?`]: `Forgot password?`,
  [`Forgot password`]: `Forgot password`,
  [`Form`]: `Form`,
  [`Forum`]: `Forum`,
  [`French`]: `French`,
  [`From File`]: `From File`,
  [`From Sample JSON`]: `From Sample JSON`,
  [`From Sample XML`]: `From Sample XML`,
  [`From URL`]: `From URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `From existing GraphQL service (GraphQL proxy)`,
  [`From existing OpenAPI service`]: `From existing OpenAPI service`,
  [`From existing WSDL service (REST proxy)`]: `From existing WSDL service (REST proxy)`,
  [`From existing WSDL service (SOAP proxy)`]: `From existing WSDL service (SOAP proxy)`,
  [`From target service`]: `From target service`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`,
  [`Gateway Extensions`]: `Gateway Extensions`,
  [`Gateway extension`]: `Gateway extension`,
  [`Gateway Services`]: `Gateway Services`,
  [`Gateway Type`]: `Gateway Type`,
  [`Gateway by Name`]: `Gateway by Name`,
  [`Gateway services`]: `Gateway services`,
  [`Gateway type`]: `Gateway type`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL v2 license`,
  [`GROUP`]: `GROUP`,
  [`Group name`]: `Group name`,
  [`Group Name`]: `Group Name`,
  [`Gateway`]: `Gateway`,
  [`Gateways`]: `Gateways`,
  [`Gateway details`]: `Gateway details`,
  [`Gateway Details`]: `Gateway Details`,
  [`Gateway Error`]: `Gateway Error`,
  [`Gateway service details`]: `Gateway service details`,
  [`Gateway management client TLS client profile`]: `Gateway management client TLS client profile`,
  [`Gateway management client keystore`]: `Gateway management client keystore`,
  [`Gateway management client truststore`]: `Gateway management client truststore`,
  [`Gateway processing status`]: `Gateway processing status`,
  [`Gateway policies (TODO)`]: `Gateway policies (TODO)`,
  [`Gateway service`]: `Gateway service`,
  [`Gateway Service US East (TODO)`]: `Gateway Service US East (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Gateway Service {arg} has been removed.`,
  [`Gateway service configuration`]: `Gateway service configuration`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`,
  [`Gateway service configuration has been updated.`]: `Gateway service configuration has been updated.`,
  [`Gateway service {title} has been created.`]: `Gateway service {title} has been created.`,
  [`Gateway service {title} has been updated.`]: `Gateway service {title} has been updated.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`,
  [`Gateway version`]: `Gateway version`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Gateways per page`,
  [`Gather-and-send`]: `Gather-and-send`,
  [`Gather-only`]: `Gather-only`,
  [`General configuration`]: `General configuration`,
  [`General information about the API.`]: `General information about the API.`,
  [`Generate`]: `Generate`,
  [`Generate an OpenAPI definition for the proxy`]: `Generate an OpenAPI definition for the proxy`,
  [`Generate JSON Web Token (JWT)`]: `Generate JSON Web Token (JWT)`,
  [`Generate JWT`]: `Generate JWT`,
  [`Generate LTPA Token`]: `Generate LTPA Token`,
  [`Generate OAuth Access Tokens`]: `Generate OAuth Access Tokens`,
  [`Generate OAuth access tokens`]: `Generate OAuth access tokens`,
  [`Generate a default product`]: `Generate a default product`,
  [`Generate access token`]: `Generate access token`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`,
  [`Generate an object`]: `Generate an object`,
  [`Generate authorization code`]: `Generate authorization code`,
  [`Generate from sample JSON`]: `Generate from sample JSON`,
  [`Generate from sample XML`]: `Generate from sample XML`,
  [`Generate tests automatically to build high quality APIs`]: `Generate tests automatically to build high quality APIs`,
  [`Generated OpenAPI 2.0 definition`]: `Generated OpenAPI 2.0 definition`,
  [`Generated default product for the API`]: `Generated default product for the API`,
  [`Generated name for use in commands and API calls`]: `Generated name for use in commands and API calls`,
  [`German`]: `German`,
  [`Get Base DN`]: `Get Base DN`,
  [`Get Code`]: `Get Code`,
  [`Get Code Snippet`]: `Get Code Snippet`,
  [`Get Support`]: `Get Support`,
  [`Get Token`]: `Get Token`,
  [`Get activation link`]: `Get activation link`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`,
  [`Get subscription ID`]: `Get subscription ID`,
  [`Getting Started`]: `Getting Started`,
  [`Getting started`]: `Getting started`,
  [`Git information`]: `Git information`,
  [`Github`]: `Github`,
  [`Global policies`]: `Global policies`,
  [`Go to Spaces`]: `Go to Spaces`,
  [`Go to the Test tab`]: `Go to the Test tab`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL rate limits`,
  [`GraphQL service`]: `GraphQL service`,
  [`GraphQL schema`]: `GraphQL schema`,
  [`GraphQL Schema`]: `GraphQL Schema`,
  [`GraphQL schema has been successfully validated.`]: `GraphQL schema has been successfully validated.`,
  [`GraphQL Schema URL`]: `GraphQL Schema URL`,
  [`GraphQL send type`]: `GraphQL send type`,
  [`GraphQL Websocket Upgrade`]: `GraphQL Websocket Upgrade`,
  [`Group Based DN`]: `Group Based DN`,
  [`Group {title} added`]: `Group {title} added`,
  [`Group {title} updated`]: `Group {title} updated`,
  [`Group {title} has been deleted`]: `Group {title} has been deleted`,
  [`Groups`]: `Groups`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `HOST NAME`,
  [`HOST URL`]: `HOST URL`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML Form`,
  [`HTML form time limit`]: `HTML form time limit`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 Required`,
  [`HTTP Header`]: `HTTP Header`,
  [`HTTP Method`]: `HTTP Method`,
  [`HTTP Type`]: `HTTP Type`,
  [`HTTP Version`]: `HTTP Version`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Handlebars`,
  [`Hard limit`]: `Hard limit`,
  [`Header`]: `Header`,
  [`Header control`]: `Header control`,
  [`Header name payload`]: `Header name payload`,
  [`Header name token`]: `Header name token`,
  [`Headers`]: `Headers`,
  [`Hello`]: `Hello`,
  [`Hello!`]: `Hello!`,
  [`Hello [IBM](url)`]: `Hello [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Hello {name}`,
  [`Hello {name}!`]: `Hello {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Hello {name}, [see IBM profile](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nThank you for signing up for an account on the {catalogTitle} developer portal.\n\nTo complete your registration, use this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nThe administrator for the {consumerOrg} developer portal has reinstated the\n{appName} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nThe administrator for the {consumerOrg} developer portal has suspended the\n{appName} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nUser {originator} has requested approval to subscribe to the {planName} plan\nfor version {productVersion} of the {productName} API product in the {catalog}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nUser {originator} has requested approval to subscribe with the application {appName}\nto the {planName} plan for version {productVersion} of the {productName} API product\nin the {catalog} catalog.\n\nYou can open the request in your task list using this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nUser {originator} has requested approval to {action} the app {appName} in the\n{catalog} catalog.\n\nYou can open the request in your task list using this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nUser {originator} has requested approval to {action} version {version}\nof the {productName} API product in the {catalog} catalog.\n\nYou can open the request in your task list using this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Hello {firstName},\n\nWe’ve received a request to reset the password for your user account\n{username} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{link}\n\n(If you didn’t make the request, you can ignore this message.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nWe’ve received a request to reset the password for your user account\n{username} in the {catalog} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hello {firstName},\n\nYour request to create a subscription for the {appName} app in the\n{catalog} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{link}\n\nIf at any time you want to cancel the request, use this link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to create a subscription for the {appName} app to the\n{planName} plan for the API product {productName} version\n{productVersion} has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{/if}\nYou can check the status of the app here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{/if}\nYou can check the status of the product here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hello {firstName}},\n\nYour request to {action}} the API product {productName}:{version} in the\n{catalog} catalog has been denied.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{/if}\nYou can check the status of the product here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been denied.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{/if}\nYou can check the status of the product here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{link}\n\nIf at any time you want to cancel the request, use this link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to {action} the app {appName} in the\n{consumerOrg} developer portal has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n"{comments}"\n\n{/if}\nYou can check the status of the app here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to {action} the app {appName} in the\n{consumerOrg} developer portal has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{/if}\nYou can check the status of the app here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to {action} the app {appName} in the\n{consumerOrg} developer portal has been denied.\n\n{#if comments}\nThe approver included the following comments:\n\n"{comments}"\n\n{/if}\nYou can check the status of the app here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to {action} the app {appName} in the\n{consumerOrg} developer portal has been denied.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{/if}\nYou can check the status of the app here:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Hello {firstName},\n\nYour request to {action} the {appName} app in the {consumerOrg}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe administrator for the {catalog} API catalog, belonging to the\n{org} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe administrator for the {catalog} API catalog, belonging to the\n{org} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe administrator for the {catalog} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe administrator for the {consumerOrg} API consumer organization in the {catalog}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe administrator for the {org} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe administrator for the {org} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe administrator for the {space} space in the {catalog} API catalog,\nbelonging to the {org} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Hello,\n\nThe password for your user account {username}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Hello,\n\nThe password for your user account {username}\nin the {portalTitle} developer portal has been changed.\n\nYou can log in to the {portalTitle} developer portal here:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{org} organization.\n\nTo continue, please use the link below:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{mailServer}.\n\nIf you received this message as expected, the test was successful!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hello,\n\nUser {originator} has withdrawn their request to subscribe to the {planName} plan for\nversion {productVersion} of the {productName} API product in the {catalog} catalog.\n\nNo further action is required.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hello,\n\nUser {originator} has withdrawn their request to {action} the app {appName} in the {catalog} catalog.\n\nNo further action is required.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hello,\n\nUser {originator} has withdrawn their request to {action} version {version} of the {productName}\nAPI product in the {catalog} catalog.\n\nNo further action is required.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`,
  [`Hello|header`]: `Hello`,
  [`Help`]: `Help`,
  [`Hi, Steve (todo)`]: `Hi, Steve (todo)`,
  [`Hide`]: `Hide`,
  [`Hide field`]: `Hide field`,
  [`Hide in schema`]: `Hide in schema`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`,
  [`High`]: `High`,
  [`History`]: `History`,
  [`Histories`]: `Histories`,
  [`History log`]: `History log`,
  [`Home`]: `Home`,
  [`Host`]: `Host`,
  [`Host context variable`]: `Host context variable`,
  [`Host name`]: `Host name`,
  [`Host value (if present)`]: `Host value (if present)`,
  [`Hostname`]: `Hostname`,
  [`Hours`]: `Hours`,
  [`Http basic authentication schema`]: `Http basic authentication schema`,
  [`Hmm, your search didn't return any results.`]: `Hmm, your search didn't return any results.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect cloud`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud Authentication`,
  [`IBM Cloud Docs`]: `IBM Cloud Docs`,
  [`IBM Schema`]: `IBM Schema`,
  [`IBM managed`]: `IBM managed`,
  [`ID Name`]: `ID Name`,
  [`ID token custom claims`]: `ID token custom claims`,
  [`ID token issuer`]: `ID token issuer`,
  [`ID token signing algorithm`]: `ID token signing algorithm`,
  [`ID token signing crypto object`]: `ID token signing crypto object`,
  [`ID token signing key`]: `ID token signing key`,
  [`ID token signing key identifier`]: `ID token signing key identifier`,
  [`IDENTITY PROVIDER`]: `IDENTITY PROVIDER`,
  [`IDENTITY PROVIDER SUPPORT`]: `IDENTITY PROVIDER SUPPORT`,
  [`Identification`]: `Identification`,
  [`Identity extraction`]: `Identity extraction`,
  [`If`]: `If`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `If recipients didn't receive the email, please check the email server settings.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `If recipients didn't receive the email, please check the email server settings for {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `If set to true, the property will be redacted or removed from the activity logs.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `If set to true, the property will be redacted or removed from the input at the beginning of the execution.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `If set to true, the property will be redacted or removed from the response.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`,
  [`If you are still having issues, upload a schema definition language file.`]: `If you are still having issues, upload a schema definition language file.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `If your file contains both the private and public keys, upload it in step 1`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`,
  [`Ignore`]: `Ignore`,
  [`Implement policy`]: `Implement policy`,
  [`Implementation file is required`]: `Implementation file is required`,
  [`Implicit`]: `Implicit`,
  [`Import`]: `Import`,
  [`Import API`]: `Import API`,
  [`Import API|title`]: `Import API`,
  [`Import OpenAPI definition from asset repository`]: `Import OpenAPI definition from asset repository`,
  [`Import Product`]: `Import Product`,
  [`Import policy`]: `Import policy`,
  [`Import a product from a service URL`]: `Import a product from a service URL`,
  [`Import a product from the definition file`]: `Import a product from the definition file`,
  [`Import an existing product`]: `Import an existing product`,
  [`Import an OpenAPI definition from a URL`]: `Import an OpenAPI definition from a URL`,
  [`Import an OpenAPI definition from a file`]: `Import an OpenAPI definition from a file`,
  [`Import definitions`]: `Import definitions`,
  [`Import from existing API service`]: `Import from existing API service`,
  [`Import from file`]: `Import from file`,
  [`Importing an API definition`]: `Importing an API definition`,
  [`Import|title`]: `Import`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Import the required certificates and CA bundles into the Certificate Manager service.`,
  [`In Cookie Header`]: `In Cookie Header`,
  [`In WSSec Header`]: `In WSSec Header`,
  [`In order to invoke the endpoints above, you need to first`]: `In order to invoke the endpoints above, you need to first`,
  [`Inactive`]: `Inactive`,
  [`Inc`]: `Inc`,
  [`Include empty`]: `Include empty`,
  [`Include free trial days`]: `Include free trial days`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Incompatible apis with the configured gateway service can't be selected.`,
  [`Incorrect username or password`]: `Incorrect username or password`,
  [`Incorrect username, password, or credentials`]: `Incorrect username, password, or credentials`,
  [`Indicate if the invoke is the final action`]: `Indicate if the invoke is the final action`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`,
  [`Indicates whether the clients secret is required.`]: `Indicates whether the clients secret is required.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Indicates whether this XSLT input document uses the context current payload, or if there is no input.`,
  [`Indicates whether to stop processing if client security fails.`]: `Indicates whether to stop processing if client security fails.`,
  [`Infer from JSON schema`]: `Infer from JSON schema`,
  [`Infer from Sample`]: `Infer from Sample`,
  [`Info`]: `Info`,
  [`Information context variable`]: `Information context variable`,
  [`Inject proxy headers`]: `Inject proxy headers`,
  [`Inline schema`]: `Inline schema`,
  [`Input`]: `Input`,
  [`Input field`]: `Input field`,
  [`Input object`]: `Input object`,
  [`Insecure Server Connections`]: `Insecure Server Connections`,
  [`Install API Connect CLI & API Designer|button text`]: `Install API Connect CLI & API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `Install API Connect CLI & API Designer`,
  [`Install API Connect Toolkit`]: `Install API Connect Toolkit`,
  [`Install the API Connect plugin`]: `Install the API Connect plugin`,
  [`Instance ID`]: `Instance ID`,
  [`Instance name`]: `Instance name`,
  [`Instance owner`]: `Instance owner`,
  [`Interact to expand Tile`]: `Interact to expand Tile`,
  [`Interact to collapse Tile`]: `Interact to collapse Tile`,
  [`Interval`]: `Interval`,
  [`Interface`]: `Interface`,
  [`Introspect`]: `Introspect`,
  [`Introspect cache type`]: `Introspect cache type`,
  [`Introspect from Server URL`]: `Introspect from Server URL`,
  [`Introspect from URL`]: `Introspect from URL`,
  [`Introspect URL`]: `Introspect URL`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Introspecting using a different URL will not change the GraphQL server URL.`,
  [`Introspect token`]: `Introspect token`,
  [`Introspection`]: `Introspection`,
  [`Introspection Path`]: `Introspection Path`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Introspect the GraphQL schema from the server or upload the schema`,
  [`Introspect the GraphQL schema using the query`]: `Introspect the GraphQL schema using the query`,
  [`Introspection URL`]: `Introspection URL`,
  [`Invalid HOST URL`]: `Invalid HOST URL`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`,
  [`Invalid file URL`]: `Invalid file URL`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Invalid URL format, must start with http:// or https:// and contain at least 1 character`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`,
  [`Invalid emails.`]: `Invalid emails.`,
  [`Invalid JSONata expression`]: `Invalid JSONata expression`,
  [`Invalid remove target`]: `Invalid remove target`,
  [`Invalid assumed size`]: `Invalid assumed size`,
  [`Invalid cost target`]: `Invalid cost target`,
  [`Invalid list size`]: `Invalid list size`,
  [`Invalid port value, must not be negative`]: `Invalid port value, must not be negative`,
  [`Invalid sized fields`]: `Invalid sized fields`,
  [`Invalid value, length has to be greater than 1`]: `Invalid value, length has to be greater than 1`,
  [`Invalid weight value`]: `Invalid weight value`,
  [`invitation`]: `invitation`,
  [`Invitation`]: `Invitation`,
  [`Invitation timeout`]: `Invitation timeout`,
  [`Invitation link will expire in {time}`]: `Invitation link will expire in **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Invitation link will expire in **{seconds} seconds ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Invitation link will expire in __{value} {unit}__.`,
  [`Invitation sent to`]: `Invitation sent to`,
  [`Invitation sent to {email}`]: `Invitation sent to {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Invitation to an API catalog in IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Invitation to an API consumer organization in the {catalog} developer portal`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Invitation to an API provider organization in IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Invitation to an API space in IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Invitation to an admin organization in IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Invitation to create an API catalog in IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Invitation to create an API consumer organization in the {catalog} developer portal`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Invitation to create an API provider organization in IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Invitation to create an API space in IBM API Connect`,
  [`Invitation ttl has been created.`]: `Invitation ttl has been created.`,
  [`Invitation ttl has been updated.`]: `Invitation ttl has been updated.`,
  [`Invitation ttl has not been created!`]: `Invitation ttl has not been created!`,
  [`Invitation ttl has not been updated!`]: `Invitation ttl has not been updated!`,
  [`Invite`]: `Invite`,
  [`Invite catalog owner`]: `Invite catalog owner`,
  [`Invite consumer organization owner`]: `Invite consumer organization owner`,
  [`Invite member`]: `Invite member`,
  [`Invite organization owner`]: `Invite organization owner`,
  [`Invite space owner`]: `Invite space owner`,
  [`Invite a new consumer organization owner`]: `Invite a new consumer organization owner`,
  [`Invite a new member`]: `Invite a new member`,
  [`Invite a new provider organization owner`]: `Invite a new provider organization owner`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`,
  [`Invite by email`]: `Invite by email`,
  [`Invite from User Registry`]: `Invite from User Registry`,
  [`Invite team member`]: `Invite team member`,
  [`Inviting members to consumer organizations is enabled`]: `Inviting members to consumer organizations is enabled`,
  [`Invocations`]: `Invocations`,
  [`Invoke`]: `Invoke`,
  [`Issuer Claim`]: `Issuer Claim`,
  [`Issuer Origin`]: `Issuer Origin`,
  [`Issuer common name`]: `Issuer common name`,
  [`It might influence how the API is deployed`]: `It might influence how the API is deployed`,
  [`Italian`]: `Italian`,
  [`Items per page`]: `Items per page`,
  [`Items per page:`]: `Items per page:`,
  [`items selected`]: `items selected`,
  [`item selected`]: `item selected`,
  [`Iterate on`]: `Iterate on`,
  [`Iterate?`]: `Iterate?`,
  [`- Iterator: \{\{#each context-variable-name\}\}...\{\{/each\}\}`]: `- Iterator: \{\{#each context-variable-name\}\}...\{\{/each\}\}`,
  [`JSON objects describing re-usable channel parameters.`]: `JSON objects describing re-usable channel parameters.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `JSON objects describing the messages being consumed and produced by the API.`,
  [`JSON Schema URL`]: `JSON Schema URL`,
  [`JSON Schema preview`]: `JSON Schema preview`,
  [`JSON Web Token (JWT)`]: `JSON Web Token (JWT)`,
  [`JSON has been successfully validated`]: `JSON has been successfully validated`,
  [`JSON schema is not valid. Please correct the error.`]: `JSON schema is not valid. Please correct the error.`,
  [`JSON to XML`]: `JSON to XML`,
  [`JWT ID Claim`]: `JWT ID Claim`,
  [`JWT verification JWK`]: `JWT verification JWK`,
  [`JWT verification crypto object`]: `JWT verification crypto object`,
  [`Japanese`]: `Japanese`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Jobs queue`,
  [`Jump to source`]: `Jump to source`,
  [`Just now`]: `Just now`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `KEY EXCHANGE / ENCRYPTION / MAC`,
  [`KEY SIZE`]: `KEY SIZE`,
  [`Keystore`]: `Keystore`,
  [`keystore`]: `keystore`,
  [`Keep`]: `Keep`,
  [`Keep Payload`]: `Keep Payload`,
  [`Key Encryption Algorithm`]: `Key Encryption Algorithm`,
  [`Key Name`]: `Key Name`,
  [`Key type`]: `Key type`,
  [`Key name`]: `Key name`,
  [`Keystore for the token has been updated.`]: `Keystore for the token has been updated.`,
  [`Keystore Certificates`]: `Keystore Certificates`,
  [`Keystore/truststore`]: `Keystore/truststore`,
  [`Korean`]: `Korean`,
  [`LABEL`]: `LABEL`,
  [`LABELS`]: `LABELS`,
  [`LAST MODIFIED`]: `LAST MODIFIED`,
  [`Last state changed`]: `Last state changed`,
  [`Large`]: `Large`,
  [`Lax`]: `Lax`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAP groups`,
  [`LDAP user registry`]: `LDAP user registry`,
  [`LDAP registry`]: `LDAP registry`,
  [`LDAP registry name`]: `LDAP registry name`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`,
  [`LDAP search attribute`]: `LDAP search attribute`,
  [`LOCATED IN`]: `LOCATED IN`,
  [`Located in`]: `Located in`,
  [`LTPA Key`]: `LTPA Key`,
  [`LTPA Key Details`]: `LTPA Key Details`,
  [`LTPA Keys`]: `LTPA Keys`,
  [`LTPA key file`]: `LTPA key file`,
  [`LTPA key password`]: `LTPA key password`,
  [`LUR data version`]: `LUR data version`,
  [`LUR schema update date`]: `LUR schema update date`,
  [`LUR schema version`]: `LUR schema version`,
  [`LUR target data version`]: `LUR target data version`,
  [`LUR target schema version`]: `LUR target schema version`,
  [`LUR update date`]: `LUR update date`,
  [`Last name`]: `Last name`,
  [`Last modified`]: `Last modified`,
  [`Launch portal`]: `Launch portal`,
  [`Launch IBM Automation management console`]: `Launch IBM Automation management console`,
  [`Launch plan upgrade`]: `Launch plan upgrade`,
  [`Learn more`]: `Learn more`,
  [`Learn More`]: `Learn More`,
  [`Legacy`]: `Legacy`,
  [`Length cannot be below {min}`]: `Length cannot be below {min}`,
  [`Length cannot exceed {max}`]: `Length cannot exceed {max}`,
  [`Let's customize API Connect`]: `Let's customize API Connect`,
  [`Let's get started...`]: `Let's get started...`,
  [`Let's get you up and running`]: `Let's get you up and running`,
  [`License`]: `License`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `Lifecycle`,
  [`Lifecycle approvals`]: `Lifecycle approvals`,
  [`Lifecycle actions have been updated`]: `Lifecycle actions have been updated`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`,
  [`Limited fields`]: `Limited fields`,
  [`Limit API calls on a per key basis`]: `Limit API calls on a per key basis`,
  [`Limit fields`]: `Limit fields`,
  [`Limit renegotiation`]: `Limit renegotiation`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Load`,
  [`Load query`]: `Load query`,
  [`Load filter`]: `Load filter`,
  [`Load schema from WSDL`]: `Load schema from WSDL`,
  [`Loading`]: `Loading`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`,
  [`Local user registry`]: `Local user registry`,
  [`Local User Registry has been created.`]: `Local User Registry has been created.`,
  [`Location`]: `Location`,
  [`Location of ciphertext to decrypt`]: `Location of ciphertext to decrypt`,
  [`Location of plaintext information to encrypt`]: `Location of plaintext information to encrypt`,
  [`Log`]: `Log`,
  [`Log in`]: `Log in`,
  [`Log in to:`]: `Log in to:`,
  [`Log in with a different account`]: `Log in with a different account`,
  [`Log into IBM Cloud`]: `Log into IBM Cloud`,
  [`Log out`]: `Log out`,
  [`Log in using the {userRegistryType} user registry`]: `Log in using the {userRegistryType} user registry`,
  [`Logging in with IBM Cloud ...`]: `Logging in with IBM Cloud ...`,
  [`Logging out`]: `Logging out`,
  [`Logic`]: `Logic`,
  [`Login`]: `Login`,
  [`Logs`]: `Logs`,
  [`Longer, descriptive name for this policy.`]: `Longer, descriptive name for this policy.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Looks like the server is experiencing an error while processing your request.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Mail server`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `MEMBER`,
  [`MIME Types`]: `MIME Types`,
  [`Mail server has been deleted.`]: `Mail server has been deleted.`,
  [`Mail server has not been deleted.`]: `Mail server has not been deleted.`,
  [`Mailserver {arg} has been removed.`]: `Mailserver {arg} has been removed.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Make sure that the server is running and that there is network connectivity.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`,
  [`This might be caused by one or more of the following:`]: `This might be caused by one or more of the following:`,
  [`Make a call and move on`]: `Make a call and move on`,
  [`Make a call and wait for response`]: `Make a call and wait for response`,
  [`Manage`]: `Manage`,
  [`Manage API life cycles`]: `Manage API life cycles`,
  [`Manage API product lifecycle approval permissions`]: `Manage API product lifecycle approval permissions`,
  [`Manage API product lifecycle approvals permissions`]: `Manage API product lifecycle approvals permissions`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Manage API provider organizations, local and remote gateways, and related settings.`,
  [`Manage APIs`]: `Manage APIs`,
  [`Manage Catalogs`]: `Manage Catalogs`,
  [`Manage Organizations`]: `Manage Organizations`,
  [`Manage Resources`]: `Manage Resources`,
  [`Manage Settings`]: `Manage Settings`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Manage local and remote gateways that secure your APIs.`,
  [`Manage catalogs`]: `Manage catalogs`,
  [`Manage organizations`]: `Manage organizations`,
  [`Manage resources`]: `Manage resources`,
  [`Manage settings`]: `Manage settings`,
  [`Manage users`]: `Manage users`,
  [`Manage active APIs and consumers`]: `Manage active APIs and consumers`,
  [`Manage availability zones and services`]: `Manage availability zones and services`,
  [`Manage policies`]: `Manage policies`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`,
  [`Manage roles and permissions`]: `Manage roles and permissions`,
  [`Manage the OAuth providers configured for API Manager`]: `Manage the OAuth providers configured for API Manager`,
  [`Manage the user registries configured for API Manager`]: `Manage the user registries configured for API Manager`,
  [`Manage the user registries configured for Cloud Manager`]: `Manage the user registries configured for Cloud Manager`,
  [`Management`]: `Management`,
  [`Management Endpoint`]: `Management Endpoint`,
  [`Management endpoint`]: `Management endpoint`,
  [`Management endpoint on the analytics service`]: `Management endpoint on the analytics service`,
  [`Service endpoint configuration`]: `Service endpoint configuration`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Endpoint used by the management service to send configuration information to the portal service`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `TLS client profile used by the management service when communicating with the portal service`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Endpoint used by the management service to send configuration information to the gateway service`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `TLS client profile used by the management service when communicating with the gateway service`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Use in-cluster communication for internal traffic between the gateway and management service`,
  [`Use in-cluster communication for both ingestion and queries`]: `Use in-cluster communication for both ingestion and queries`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Use in-cluster for ingestion and external for queries, or vice versa`,
  [`Use external communication for both ingestion and queries`]: `Use external communication for both ingestion and queries`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Use in-cluster communication for internal traffic between analytics and the management service`,
  [`Management endpoint on the gateway service`]: `Management endpoint on the gateway service`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Use in-cluster communication for internal traffic between the portal and management service`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`,
  [`Management endpoint on the portal service`]: `Management endpoint on the portal service`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Use internal endpoint for ingestion of analytics data from the gateway service`,
  [`Endpoint configuration`]: `Use external communication for both ingestion and queries`,
  [`Portal endpoint configuration`]: `Portal endpoint configuration`,
  [`URL used by developer portal users to access the portal website`]: `URL used by developer portal users to access the portal website`,
  [`External ingestion endpoint on the analytics service`]: `External ingestion endpoint on the analytics service`,
  [`TLS client profile for external analytics ingestion endpoint`]: `TLS client profile for external analytics ingestion endpoint`,
  [`Internal ingestion endpoint on the analytics service`]: `Internal ingestion endpoint on the analytics service`,
  [`TLS client profile for internal service endpoint`]: `TLS client profile for internal service endpoint`,
  [`Manager`]: `Manager`,
  [`Manager Request Reset Password`]: `Manager Request Reset Password`,
  [`Manager Reset Password`]: `Manager Reset Password`,
  [`Manager Sign In`]: `Manager Sign In`,
  [`Manages API provider organizations`]: `Manages API provider organizations`,
  [`Manages application developer communities`]: `Manages application developer communities`,
  [`Manages the API product lifecycle`]: `Manages the API product lifecycle`,
  [`Manage|link`]: `Manage`,
  [`Manage|permission`]: `Manage`,
  [`Manage|title`]: `Manage:title`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Manage Keystore assignment and Keystore history for tokens`,
  [`Map`]: `Map`,
  [`Map information from OIDC provider to an APIC user.`]: `Map information from OIDC provider to an APIC user.`,
  [`Map the migration source plans to the migration target plans`]: `Map the migration source plans to the migration target plans`,
  [`Map the roles to groups`]: `Map the roles to groups`,
  [`Mapped from: `]: `Mapped from: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Maximize / minimize`,
  [`Maximum Consent Time to Live (seconds)`]: `Maximum Consent Time to Live (seconds)`,
  [`Maximum consent`]: `Maximum consent`,
  [`Menu`]: `Menu`,
  [`Member`]: `Member`,
  [`Member Invitation`]: `Member Invitation`,
  [`Member Invitation has been deleted.`]: `Member Invitation has been deleted.`,
  [`Member has been deleted.`]: `Member has been deleted.`,
  [`Member {name} created`]: `Member {name} created`,
  [`Member {title} has been created.`]: `Member {title} has been created.`,
  [`Members`]: `Members`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Member Invitations`,
  [`Member Roles`]: `Member Roles`,
  [`Merchant ID`]: `Merchant ID`,
  [`Merge`]: `Merge`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Merge this mapping with any others that operate on the same target array?`,
  [`Message`]: `Message`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Message for parsing`,
  [`Message for resulting parsed data`]: `Message for resulting parsed data`,
  [`Metadata`]: `Metadata`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`,
  [`Micro Gateway policies`]: `Micro Gateway policies`,
  [`Migrate`]: `Migrate`,
  [`Migrate Subscriptions`]: `Migrate Subscriptions`,
  [`Migrate plans`]: `Migrate plans`,
  [`Migrate subscription`]: `Migrate subscription`,
  [`Migrate subscriptions`]: `Migrate subscriptions`,
  [`Migration Target`]: `Migration Target`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `Migration from "{source}" (Product) to "{target}" (Product) has been executed`,
  [`Migration from "{source}" failed`]: `Migration from "{source}" failed`,
  [`Migration source`]: `Migration source`,
  [`Migration target`]: `Migration target`,
  [`Minimum role`]: `Minimum role`,
  [`Minimum output escaping rule`]: `Minimum output escaping rule`,
  [`Minutes`]: `Minutes`,
  [`Mode`]: `Mode`,
  [`Modifications`]: `Modifications`,
  [`Modified`]: `Modified`,
  [`More`]: `More`,
  [`More than 20 events are waiting to be processed`]: `More than 20 events are waiting to be processed`,
  [`Move down`]: `Move down`,
  [`Move up`]: `Move up`,
  [`Must be a number`]: `Must be a number`,
  [`Must be a valid URI`]: `Must be a valid URI`,
  [`Must be a valid email`]: `Must be a valid email`,
  [`Must be a valid path starting with /`]: `Must be a valid path starting with /`,
  [`Must be a valid url`]: `Must be a valid url`,
  [`Must be an integer`]: `Must be an integer`,
  [`Must be a valid url with no protocol`]: `Must be a valid url with no protocol`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Must be of the format 64 hex characters (prefixed with "0x")`,
  [`Must start with '/'`]: `Must start with '/'`,
  [`Must start with https://`]: `Must start with https://`,
  [`Must start with https:// or $(`]: `Must start with https:// or $(`,
  [`Mutual auth`]: `Mutual auth`,
  [`Mutual authentication`]: `Mutual authentication`,
  [`My account`]: `My account`,
  [`Name`]: `Name`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NOT`,
  [`Name cannot be empty.`]: `Name cannot be empty.`,
  [`Name of Schema in OpenAPI Definitions`]: `Name of Schema in OpenAPI Definitions`,
  [`Name of the LDAP user password attribute.`]: `Name of the LDAP user password attribute.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Name of the LDAP user registry to validate against. Select from the list, or type manually.`,
  [`Name of the organization`]: `Name of the organization`,
  [`Namespace inheritance`]: `Namespace inheritance`,
  [`Namespace inlining`]: `Namespace inlining`,
  [`Native`]: `Native`,
  [`Native OAuth provider`]: `Native OAuth provider`,
  [`Native OAuth provider summary`]: `Native OAuth provider summary`,
  [`Navigate to Source View`]: `Navigate to Source View`,
  [`New`]: `New`,
  [`New API`]: `New API`,
  [`New API Connect connection`]: `New API Connect connection`,
  [`New Application`]: `New Application`,
  [`New Catalog`]: `New Catalog`,
  [`New Consumer organization`]: `New Consumer organization`,
  [`New Endpoint`]: `New Endpoint`,
  [`New native OAuth provider`]: `New native OAuth provider`,
  [`New OpenAPI`]: `New OpenAPI`,
  [`New OpenAPI directory`]: `New OpenAPI directory`,
  [`New Organization Title`]: `New Organization Title`,
  [`New Parameter`]: `New Parameter`,
  [`New Password`]: `New Password`,
  [`New product`]: `New product`,
  [`New Role`]: `New Role`,
  [`New schema`]: `New schema`,
  [`New Test`]: `New Test`,
  [`New third party OAuth provider`]: `New third party OAuth provider`,
  [`New user`]: `New user`,
  [`New applications will be created in the development state`]: `New applications will be created in the development state`,
  [`Next`]: `Next`,
  [`Next page`]: `Next page`,
  [`No`]: `No`,
  [`No account?`]: `No account?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `No Authentication URL or LDAP User Registries configured in the sandbox catalog`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`,
  [`No Billing`]: `No Billing`,
  [`No billing integration has been selected.`]: `No billing integration has been selected.`,
  [`No billing integration can be shown when offline.`]: `No billing integration can be shown when offline.`,
  [`No billing integrations configured.`]: `No billing integrations configured.`,
  [`No gateways`]: `No gateways`,
  [`No cache`]: `No cache`,
  [`No Cache`]: `No Cache`,
  [`No Certificate Manager services available`]: `No Certificate Manager services available`,
  [`No default gateways`]: `No default gateways`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `No duplicate email address within the user registry. This only applies to the users with email.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`,
  [`No Errors`]: `No Errors`,
  [`No Histories found`]: `No Histories found`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `No Gateway services found. Please configure a Gateway Service to display supported assembly policies`,
  [`No gateways are configured for sandbox catalog`]: `No gateways are configured for sandbox catalog`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`,
  [`No LDAP or authentication URL user registries found.`]: `No LDAP or authentication URL user registries found.`,
  [`No limit on size of list`]: `No limit on size of list`,
  [`No OAuth providers configured in the sandbox catalog`]: `No OAuth providers configured in the sandbox catalog`,
  [`No OAuth providers configured in the selected catalog`]: `No OAuth providers configured in the selected catalog`,
  [`No Permission`]: `No Permission`,
  [`No portal added to this catalog`]: `No portal added to this catalog`,
  [`No Roles exist`]: `No Roles exist`,
  [`No recent items found.`]: `No recent items found.`,
  [`No TLS profile`]: `No TLS profile`,
  [`No TLS client profile`]: `No TLS client profile`,
  [`No Truststore`]: `No Truststore`,
  [`No analytics service is configured`]: `No analytics service is configured`,
  [`No analytics services found. You can create one [here]({link})`]: `No analytics services found. You can create one [here]({link})`,
  [`No assemblies found.`]: `No assemblies found.`,
  [`No available extensions`]: `No available extensions`,
  [`No compatible flows configured in this OAuth provider`]: `No compatible flows configured in this OAuth provider`,
  [`No consumer organization will be able to see this product.`]: `No consumer organization will be able to see this product.`,
  [`No debug data was found for this API call`]: `No debug data was found for this API call`,
  [`No details are available.`]: `No details are available.`,
  [`No existing products`]: `No existing products`,
  [`No extensions have been configured for the API`]: `No extensions have been configured for the API`,
  [`No flow configured in this OAuth provider`]: `No flow configured in this OAuth provider`,
  [`No items found`]: `No items found`,
  [`No non-member user to add.`]: `No non-member user to add.`,
  [`No organization found`]: `No organization found`,
  [`No portal service exists:`]: `No portal service exists:`,
  [`No product APIs`]: `No product APIs`,
  [`No products or plans were found.`]: `No products or plans were found.`,
  [`No provider organizations`]: `No provider organizations`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`,
  [`No security`]: `No security`,
  [`No services found`]: `No services found`,
  [`No warnings`]: `No warnings`,
  [`No warnings.`]: `No warnings.`,
  [`No. Do not update API Assembly`]: `No. Do not update API Assembly`,
  [`Non-blocking`]: `Non-blocking`,
  [`Non int slicing arguments`]: `Non-int slicing arguments`,
  [`None`]: `None`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `None of the configured OAuth providers match the gateway type of this API`,
  [`Normal`]: `Normal`,
  [`Not Available`]: `Not Available`,
  [`Not applicable for application grant type.`]: `Not applicable for application grant type.`,
  [`Not valid before`]: `Not valid before`,
  [`Not valid after`]: `Not valid after`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Note: This option is disabled when the 'Generate auto product' option is selected.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Note: This option is only for informational purpose and is updated based on the test application option above`,
  [`Notices`]: `Notices`,
  [`Notification template`]: `Notification template`,
  [`Notification templates`]: `Notification templates`,
  [`Notification server configured`]: `Notification server configured`,
  [`Notifications`]: `Notifications`,
  [`Null Badgerfish`]: `Null Badgerfish`,
  [`Null`]: `Null`,
  [`Number`]: `Number`,
  [`Number of Operations`]: `Number of Operations`,
  [`Numeric`]: `Numeric`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth Provider`,
  [`OAuth provider`]: `OAuth provider`,
  [`OAuth Provider Policy Assembly`]: `OAuth Provider Policy Assembly`,
  [`OAuth providers`]: `OAuth providers`,
  [`OAuth Redirect URL`]: `OAuth Redirect URL`,
  [`OAuth shared secret`]: `OAuth shared secret`,
  [`OAuth redirect URLs (optional)`]: `OAuth redirect URLs (optional)`,
  [`Add redirect URL`]: `Add redirect URL`,
  [`OAuth provider policy assembly`]: `OAuth provider policy assembly`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`,
  [`OAuth redirect URL`]: `OAuth redirect URL`,
  [`OAuth Shared Secret`]: `OAuth Shared Secret`,
  [`OAuth Signature Method`]: `OAuth Signature Method`,
  [`OAuth signature method`]: `OAuth signature method`,
  [`OAuth providers can be created and managed in the following list.`]: `OAuth providers can be created and managed in the following list.`,
  [`OAuth symmetric key name`]: `OAuth symmetric key name`,
  [`OAuth symmetric key value`]: `OAuth symmetric key value`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2 Access Code Security`,
  [`OAuth2 Application Security`]: `OAuth2 Application Security`,
  [`OAuth2 Implicit Security`]: `OAuth2 Implicit Security`,
  [`OAuth2 Password Security`]: `OAuth2 Password Security`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `OPERATION ID`,
  [`OPTIONS`]: `OPTIONS`,
  [`TRACE`]: `TRACE`,
  [`ORDER`]: `ORDER`,
  [`ORGANIZATION`]: `ORGANIZATION`,
  [`ORGANIZATION MANAGER`]: `ORGANIZATION MANAGER`,
  [`Organizations`]: `Organizations`,
  [`Owner`]: `Owner`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`,
  [`Off`]: `Off`,
  [`Offline`]: `Offline`,
  [`Old schema`]: `Old schema`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch for all supported list pages`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch for draft APIs and products`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch for published products in catalogs and spaces`,
  [`Omnisearch for consumer organizations`]: `Omnisearch for consumer organizations`,
  [`Omnisearch for applications`]: `Omnisearch for applications`,
  [`Omnisearch for requested approvals`]: `Omnisearch for requested approvals`,
  [`Omnisearch for approvals tasks`]: `Omnisearch for approvals tasks`,
  [`Omnisearch for product apis`]: `Omnisearch for product apis`,
  [`Omnisearch for subscriptions`]: `Omnisearch for subscriptions`,
  [`Omnisearch for members`]: `Omnisearch for members`,
  [`Omnisearch for provider organizations`]: `Omnisearch for provider organizations`,
  [`Disable the new editor for OpenAPI2`]: `Disable the new editor for OpenAPI2`,
  [`On`]: `On`,
  [`Onboarding`]: `Onboarding`,
  [`Onboarding Edit Admin Org`]: `Onboarding Edit Admin Org`,
  [`Onboarding Edit Cloud Org`]: `Onboarding Edit Cloud Org`,
  [`Onboarding Edit Reset Password`]: `Onboarding Edit Reset Password`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `One or more JSON objects describing the schemas being consumed and produced by the API.`,
  [`One or more JSON representations for parameters`]: `One or more JSON representations for parameters`,
  [`One time use access token`]: `One time use access token`,
  [`One time use refresh token`]: `One time use refresh token`,
  [`Online`]: `Online`,
  [`Only one API file should be present in the zip file.`]: `Only one API file should be present in the zip file.`,
  [`OpenAPI Specification Version`]: `OpenAPI Specification Version`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`,
  [`Open a Directory`]: `Open a Directory`,
  [`Open in external editor`]: `Open in external editor`,
  [`Open the server`]: `Open the server`,
  [`OpenAPI extensions`]: `OpenAPI extensions`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect not yet supported when Gateway version is 6000`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`,
  [`Operation`]: `Operation`,
  [`Operation Id`]: `Operation Id`,
  [`Operation switch`]: `Operation switch`,
  [`Operation Switch`]: `Operation Switch`,
  [`Operations`]: `Operations`,
  [`Operator build date`]: `Operator build date`,
  [`Operator build tag`]: `Operator build tag`,
  [`Operator version`]: `Operator version`,
  [`Optionally select a CA bundle`]: `Optionally select a CA bundle`,
  [`Optimize schema definition`]: `Optimize schema definition`,
  [`Optional`]: `Optional`,
  [`Or`]: `Or`,
  [`Or create a new application and subscribe to the selected plan`]: `Or create a new application and subscribe to the selected plan`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Or create a new product and publish it to your Sandbox catalog`,
  [`Order`]: `Order`,
  [`Org`]: `Org`,
  [`Org owner`]: `Org owner`,
  [`Organization`]: `Organization`,
  [`Organization:`]: `Organization:`,
  [`Organization information`]: `Organization information`,
  [`Organization Manager`]: `Organization Manager`,
  [`Organization has been updated.`]: `Organization has been updated.`,
  [`Organizations will be listed here.`]: `Organizations will be listed here.`,
  [`Otherwise`]: `Otherwise`,
  [`Output`]: `Output`,
  [`Output Claims`]: `Output Claims`,
  [`Output array`]: `Output array`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Output the root element even if it is not required to make the XML document well formed.`,
  [`Output variable`]: `Output variable`,
  [`Override API consume types`]: `Override API consume types`,
  [`Override API produce types`]: `Override API produce types`,
  [`Override API security definitions`]: `Override API security definitions`,
  [`Override rate limits`]: `Override rate limits`,
  [`Override default "provider" settings with configuration from this URL`]: `Override default "provider" settings with configuration from this URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Override default "provider" settings with configuration from this literal`,
  [`Override plan rate limits`]: `Override plan rate limits`,
  [`Override plan rate limits for individual operation`]: `Override plan rate limits for individual operation`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Override consumer organizations invitation timeout with catalog invitation timeout`,
  [`Overview`]: `Overview`,
  [`Owner scope check`]: `Owner scope check`,
  [`Owner's Email`]: `Owner's Email`,
  [`Owner's Name`]: `Owner's Name`,
  [`Owner's email`]: `Owner's email`,
  [`Owns and administers the API provider organization`]: `Owns and administers the API provider organization`,
  [`Owns and administers the admin organization`]: `Owns and administers the admin organization`,
  [`Owns and administers the app developer organization`]: `Owns and administers the app developer organization`,
  [`Owns and administrates the app developer organization`]: `Owns and administrates the app developer organization`,
  [`PARAMETER NAME`]: `PARAMETER NAME`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `PCRE to use to validate the Audience (aud) claim.`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `PCRE to use to validate the Issuer (iss) claim.`,
  [`PER`]: `PER`,
  [`Per`]: `Per`,
  [`Plan`]: `Plan`,
  [`Plan:`]: `Plan:`,
  [`pending Consumer organization invitation`]: `pending Consumer organization invitation`,
  [`PLAN`]: `PLAN`,
  [`PLANS`]: `PLANS`,
  [`Policy`]: `Policy`,
  [`policy`]: `policy`,
  [`POLICIES`]: `POLICIES`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUCT`,
  [`Product Plan`]: `Product Plan`,
  [`Product Plans`]: `Product Plans`,
  [`Product Plans per page`]: `Product Plans per page`,
  [`Property name`]: `Property name`,
  [`Property type`]: `Property type`,
  [`Property example`]: `Property example`,
  [`Property description`]: `Property description`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Page Not Found`,
  [`Parameter name`]: `Parameter name`,
  [`Parameter control`]: `Parameter control`,
  [`Parameters`]: `Parameters`,
  [`Parent`]: `Parent`,
  [`Parse`]: `Parse`,
  [`Parse settings URL reference`]: `Parse settings URL reference`,
  [`Parse settings literal configuration`]: `Parse settings literal configuration`,
  [`Parse settings object reference`]: `Parse settings object reference`,
  [`Parse the GraphQL query`]: `Parse the GraphQL query`,
  [`Parse the response from the GraphQL backend server`]: `Parse the response from the GraphQL backend server`,
  [`Parse XML output`]: `Parse XML output`,
  [`Pass-through`]: `Pass-through`,
  [`Password`]: `Password`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `Password changed for your user account in IBM API Connect`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `Password changed for your user account in {portalTitle} developer portal`,
  [`Password context variable`]: `Password context variable`,
  [`Password is invalid. Re-enter the password.`]: `Password is invalid. Re-enter the password.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `Password reset request for your user account in IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Password reset request for your {catalog} developer portal account`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`,
  [`Passwords must contain eight or more characters`]: `Passwords must contain eight or more characters`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Passwords must not contain the same character used more than twice consecutively.`,
  [`Passwords should match`]: `Passwords should match`,
  [`Path`]: `Path`,
  [`Path parameters`]: `Path parameters`,
  [`Path name`]: `Path name`,
  [`Paths`]: `Paths`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`,
  [`Payments Authorization`]: `Payments Authorization`,
  [`Payment method`]: `Payment method`,
  [`Pending`]: `Pending`,
  [`Pending State`]: `Pending State`,
  [`Pending approval to be published in catalog`]: `Pending approval to be published in catalog`,
  [`Pending approval to be staged in catalog`]: `Pending approval to be staged in catalog`,
  [`Pending consumer organization invitations`]: `Pending consumer organization invitations`,
  [`View endpoints`]: `View endpoints`,
  [`View base endpoints for this API`]: `View base endpoints for this API`,
  [`View pending invitations`]: `View pending invitations`,
  [`Permission Denied`]: `Permission Denied`,
  [`Permissions`]: `Permissions`,
  [`Persistent Connection`]: `Persistent Connection`,
  [`Pin menu`]: `Pin menu`,
  [`PlainText`]: `PlainText`,
  [`Plan APIs`]: `Plan APIs`,
  [`Plan burst limits`]: `Plan burst limits`,
  [`Plan by name`]: `Plan by Name`,
  [`Plan Default`]: `Plan Default`,
  [`Group`]: `Group`,
  [`Plan Details`]: `Plan Details`,
  [`Plan migration targets`]: `Plan migration targets`,
  [`Plan rate limits`]: `Plan rate limits`,
  [`Plan name`]: `Plan name`,
  [`Plan pricing`]: `Plan pricing`,
  [`Plan title already exists`]: `Plan title already exists`,
  [`Plans`]: `Plans`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Platform REST API endpoint for admin and provider APIs`,
  [`Platform REST API endpoint for consumer APIs`]: `Platform REST API endpoint for consumer APIs`,
  [`Please choose a bind method`]: `Please choose a bind method`,
  [`Please choose a scope`]: `Please choose a scope`,
  [`Please choose an authentication method`]: `Please choose an authentication method`,
  [`Please contact the system administrator.`]: `Please contact the system administrator.`,
  [`Please contact your administrator for support.`]: `Please contact your administrator for support.`,
  [`Please enter the reason.`]: `Please enter the reason.`,
  [`Please enter your username`]: `Please enter your username`,
  [`Please go to the space settings to manage API endpoints.`]: `Please go to the space settings to manage API endpoints.`,
  [`Please make sure the server is online and try again.`]: `Please make sure the server is online and try again.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`,
  [`Please review the license for API Connect by accessing`]: `Please review the license for API Connect by accessing`,
  [`Please select a TLS`]: `Please select a TLS`,
  [`Please select a TLS client profile`]: `Please select a TLS client profile`,
  [`Please select a group type`]: `Please select a group type`,
  [`Please select an LDAP protocol version`]: `Please select an LDAP protocol version`,
  [`Please select authentication method`]: `Please select authentication method`,
  [`Please select ciphers from the list below:`]: `Please select ciphers from the list below:`,
  [`Please select consumer organization`]: `Please select consumer organization`,
  [`Please select one Keystore from the list below:`]: `Please select one Keystore from the list below:`,
  [`Please select one keystore from the list below:`]: `Please select one keystore from the list below:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`,
  [`Please try again in a few minutes.`]: `Please try again in a few minutes.`,
  [`Please refresh catalog/space product list page and try again.`]: `Please refresh catalog/space product list page and try again.`,
  [`Policies`]: `Policies`,
  [`Policy Assemblies`]: `Policy Assemblies`,
  [`Policy assembly`]: `Policy assembly`,
  [`Policy description.`]: `Policy description.`,
  [`Policy title`]: `Policy title`,
  [`Policy has been uploaded.`]: `Policy has been uploaded.`,
  [`Polish`]: `Polish`,
  [`Port`]: `Port`,
  [`Portal`]: `Portal`,
  [`Portal details`]: `Portal details`,
  [`Portal Director TLS client profile`]: `Portal Director TLS client profile`,
  [`Portal Director keystore`]: `Portal Director keystore`,
  [`Portal Director truststore`]: `Portal Director truststore`,
  [`Portal service`]: `Portal service`,
  [`Portal Service`]: `Portal Service`,
  [`Portal service name`]: `Portal service name`,
  [`Portal service details`]: `Portal service details`,
  [`Portal Service and URL`]: `Portal Service and URL`,
  [`Portal service {name} was successfully created.`]: `Portal service {name} was successfully created.`,
  [`Portal service {name} was successfully updated.`]: `Portal service {name} was successfully updated.`,
  [`Portal Service {arg} has been removed.`]: `Portal Service {arg} has been removed.`,
  [`Portal site`]: `Portal site`,
  [`Portal URL`]: `Portal URL`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Portal site URLs cannot be greater than 200 characters`,
  [`Portal website URL`]: `Portal website URL`,
  [`Portal and Gateway Settings`]: `Portal and Gateway Settings`,
  [`Portal has been created`]: `Portal has been created`,
  [`Portal has been created.`]: `Portal has been created.`,
  [`Portal has been deleted`]: `Portal has been deleted`,
  [`Portals`]: `Portals`,
  [`Portuguese`]: `Portuguese`,
  [`Post Response`]: `Post Response`,
  [`Post-Invoke Hook`]: `Post-Invoke Hook`,
  [`Preserve subscriptions on re-publish`]: `Preserve subscriptions on re-publish`,
  [`Preserve subscriptions on re-publish product`]: `Preserve subscriptions on re-publish product`,
  [`Pre-Invoke Hook`]: `Pre-Invoke Hook`,
  [`Pre-Request`]: `Pre-Request`,
  [`Preference`]: `Preference`,
  [`Prefix`]: `Prefix`,
  [`Preparing API`]: `Preparing API`,
  [`Preserved request header`]: `Preserved request header`,
  [`preserved request header`]: `preserved request header`,
  [`Preserved response header`]: `Preserved response header`,
  [`preserved response header`]: `preserved response header`,
  [`Preview`]: `Preview`,
  [`Preview of login screen registry prompt`]: `Preview of login screen registry prompt`,
  [`Previous`]: `Previous`,
  [`Previous page`]: `Previous page`,
  [`Prettify`]: `Prettify`,
  [`Price per month`]: `Price per month`,
  [`Private`]: `Private`,
  [`Private Claims`]: `Private Claims`,
  [`Private key & public key`]: `Private key & public key`,
  [`Private Key or Shared Secret for Decrypt`]: `Private Key or Shared Secret for Decrypt`,
  [`Private Key or Shared Secret for Sign`]: `Private Key or Shared Secret for Sign`,
  [`Private key detected`]: `Private key detected`,
  [`Private key password`]: `Private key password`,
  [`Processed`]: `Processed`,
  [`Problem`]: `Problem`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Problem fetching Trace data. Please try again. See console for error details.`,
  [`Produced Media Types`]: `Produced Media Types`,
  [`Produces`]: `Produces`,
  [`Product`]: `Product`,
  [`Product:`]: `Product:`,
  [`Product / Plan`]: `Product / Plan`,
  [`Product APIs`]: `Product APIs`,
  [`Product editor`]: `Product editor`,
  [`Product lifecycle`]: `Product lifecycle`,
  [`Product Lifecycle`]: `Product Lifecycle`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Product name can not end with '-auto-product'. Please change the name and try importing again.`,
  [`Product setup`]: `Product setup`,
  [`Product details`]: `Product details`,
  [`Product documentation`]: `Product documentation`,
  [`Product has been created.`]: `Product has been created`,
  [`Product has been deleted`]: `Product has been deleted`,
  [`Product has been updated`]: `Product has been updated`,
  [`Product has been updated.`]: `Product has been updated.`,
  [`Product has not been created!`]: `Product has not been created!`,
  [`Product has not been published!`]: `Product has not been published!`,
  [`Product has not been updated!`]: `Product has not been updated!`,
  [`Product has not been staged!`]: `Product has not been staged!`,
  [`Product information`]: `Product information`,
  [`Product summary details`]: `Product summary details`,
  [`Product version`]: `Product version`,
  [`Product visibility`]: `Product visibility`,
  [`Product-Approval`]: `Product-Approval`,
  [`Product-Drafts`]: `Product-Drafts`,
  [`Product/plan`]: `Product/plan`,
  [`Production`]: `Production`,
  [`Production mode`]: `Production mode`,
  [`Production endpoints`]: `Production endpoints`,
  [`Products`]: `Products`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`,
  [`Profile`]: `Profile`,
  [`Profile rule`]: `Profile rule`,
  [`Promote to production`]: `Promote to production`,
  [`Proof Key for Code Exchange`]: `Proof Key for Code Exchange`,
  [`Proof Key for Code Exchange (PKCE)`]: `Proof Key for Code Exchange (PKCE)`,
  [`Properties`]: `Properties`,
  [`Property`]: `Property`,
  [`Property Description`]: `Property Description`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `Property {{target}} can be assigned a static value by entering it below.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`,
  [`Property Value is Base64 Encoded`]: `Property Value is Base64 Encoded`,
  [`Protocol`]: `Protocol`,
  [`Protocols`]: `Protocols`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs.`]: `Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs.`,
  [`Provide a schema`]: `Provide a schema`,
  [`Provide GraphQL schema`]: `Provide GraphQL schema`,
  [`Provide the URL to the backend GraphQL server`]: `Provide the URL to the backend GraphQL server`,
  [`Provide your email address to receive password reset instructions`]: `Provide your email address to receive password reset instructions`,
  [`Provide your new password below`]: `Provide your new password below`,
  [`Provider endpoint`]: `Provider endpoint`,
  [`Provider Information`]: `Provider Information`,
  [`Provider Organization`]: `Provider Organization`,
  [`Provider Organizations`]: `Provider Organizations`,
  [`Provider Type`]: `Provider Type`,
  [`Provider organizations`]: `Provider organizations`,
  [`Provider type`]: `Provider type`,
  [`Provider org invite failed`]: `Provider org invite failed`,
  [`Provider organization {name} created`]: `Provider organization {name} created`,
  [`Provider organizations will be listed here`]: `Provider organizations will be listed here`,
  [`Provider-Org`]: `Provider-Org`,
  [`Provides contact information for the exposed API.`]: `Provides contact information for the exposed API.`,
  [`Provides license information for the exposed API.`]: `Provides license information for the exposed API.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Provides metadata about the API. The metadata can be used by the clients if needed.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `Proxy URL`,
  [`Public`]: `Public`,
  [`Public Key or Shared Secret for Encrypt`]: `Public Key or Shared Secret for Encrypt`,
  [`Publish`]: `Publish`,
  [`Publish API`]: `Publish API`,
  [`Publish a product from the Develop section.`]: `Publish a product from the Develop section.`,
  [`Publish product`]: `Publish product`,
  [`Publish to`]: `Publish to`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Publish some products to this catalog in order to create subscriptions to them.`,
  [`Publish target`]: `Publish target`,
  [`Publish this API to the Sandbox catalog.`]: `Publish this API to the Sandbox catalog.`,
  [`Publish to specific gateway services`]: `Publish to specific gateway services`,
  [`Publish using a new product`]: `Publish using a new product`,
  [`Publish using an existing product`]: `Publish using an existing product`,
  [`Publishable Key`]: `Publishable Key`,
  [`Publishable key`]: `Publishable key`,
  [`Publishable key tip`]: `Publishable key tip`,
  [`Published`]: `Published`,
  [`Published product`]: `Published product`,
  [`Publishing`]: `Publishing`,
  [`Publishing New API`]: `Publishing New API`,
  [`Publish (preserve subscriptions)`]: `Publish (preserve subscriptions)`,
  [`Publish|permission`]: `Publish`,
  [`Put online`]: `Put online`,
  [`Query`]: `Query`,
  [`Queryparam Encode`]: `Queryparam Encode`,
  [`Queued`]: `Queued`,
  [`Queued events: **{queued}**`]: `Queued events: **{queued}**`,
  [`Retry blocked jobs`]: `Retry blocked jobs`,
  [`REQUIRED`]: `REQUIRED`,
  [`ROLES`]: `ROLES`,
  [`Rate limit`]: `Rate limit`,
  [`Rate Limit`]: `Rate Limit`,
  [`Rate Limit Name`]: `Rate Limit Name`,
  [`Rate limits`]: `Rate limits`,
  [`Rate limit name can't be a duplicate`]: `Rate limit name can't be a duplicate`,
  [`Rate limit name can't be empty`]: `Rate limit name can't be empty`,
  [`Rate limit overrides`]: `Rate limit overrides`,
  [`Rate limit time interval can't be 0`]: `Rate limit time interval can't be 0`,
  [`Rate limit time interval can't be empty`]: `Rate limit time interval can't be empty`,
  [`Rate limit time interval unit must be selected`]: `Rate limit time interval unit must be selected`,
  [`Rate limit value can't be empty`]: `Rate limit value can't be empty`,
  [`Rate limit value can't be negative`]: `Rate limit value can't be negative`,
  [`Re-stage`]: `Re-stage`,
  [`Re-stage product`]: `Re-stage product`,
  [`Read only`]: `Read only`,
  [`Read-Only`]: `Read-Only`,
  [`Realized`]: `Realized`,
  [`Reason`]: `Reason`,
  [`Reason for declining`]: `Reason for declining`,
  [`Recommendations`]: `Recommendations`,
  [`Recommended configuration`]: `Recommended configuration`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`,
  [`Recommendations applied`]: `Recommendations applied`,
  [`Redact`]: `Redact`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`,
  [`Redaction`]: `Redaction`,
  [`Redactions`]: `Redactions`,
  [`Redirect`]: `Redirect`,
  [`Redirect URI`]: `Redirect URI`,
  [`Redirect URL`]: `Redirect URL`,
  [`Redirect through Portal`]: `Redirect through Portal`,
  [`Redirect time limit`]: `Redirect time limit`,
  [`Redirect time limit must be between 10 and 6000 seconds`]: `Redirect time limit must be between 10 and 6000 seconds`,
  [`References`]: `References`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Refer to the [IBM Cloud Docs]({link}) for additional guidance.`,
  [`Referral`]: `Referral`,
  [`Refresh`]: `Refresh`,
  [`Refresh Token`]: `Refresh Token`,
  [`Refresh button`]: `Refresh button`,
  [`Refresh products`]: `Refresh products`,
  [`Refresh results from server`]: `Refresh results from server`,
  [`Refresh token`]: `Refresh token`,
  [`Refresh token time to live`]: `Refresh token time to live`,
  [`Refresh token time-to-live`]: `Refresh token time-to-live`,
  [`Refresh token time-to-live (seconds)`]: `Refresh token time-to-live (seconds)`,
  [`Refresh token TTL has been changed`]: `Refresh token time to live has been changed`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Refresh token time to live is **{seconds} seconds ({time})**`,
  [`Refresh token time to live is **off**`]: `Refresh token time to live is **off**`,
  [`Refresh tokens`]: `Refresh tokens`,
  [`Refreshing`]: `Refreshing`,
  [`Refreshing...`]: `Refreshing...`,
  [`Region`]: `Region`,
  [`Register`]: `Register`,
  [`Register Gateways`]: `Register Gateways`,
  [`Register Service`]: `Register Service`,
  [`Register gateways`]: `Register gateways`,
  [`Register service`]: `Register service`,
  [`Register a service`]: `Register a service`,
  [`Register new services and manage existing services`]: `Register new services and manage existing services`,
  [`Register remote gateway`]: `Register remote gateway`,
  [`Registered Admin Member`]: `Registered Admin Member`,
  [`Registered Provider Org Owner or Member`]: `Registered Provider Org Owner or Member`,
  [`Registered client information on the OpenID provider.`]: `Registered client information on the OpenID provider.`,
  [`Registration completed successfully`]: `Registration completed successfully`,
  [`Registration failed.`]: `Registration failed.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Relative paths to the individual endpoints. They must be relative to the 'basePath'.`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `Remove analysis configuration`,
  [`Remote API Connect`]: `Remote API Connect`,
  [`Remote login`]: `Remote login`,
  [`Remote publish`]: `Remote publish`,
  [`Remove`]: `Remove`,
  [`Remove Burst Limit`]: `Remove Burst Limit`,
  [`Remove constraints`]: `Remove constraints`,
  [`Remove Count Limit`]: `Remove Count Limit`,
  [`Remove gateway extension`]: `Remove gateway extension`,
  [`Remove product`]: `Remove product`,
  [`Remove Rate limit`]: `Remove Rate limit`,
  [`Remove the invalid constraints`]: `Remove the invalid constraints`,
  [`Remove the invalid constraints from`]: `Remove the invalid constraints from`,
  [`Remove action`]: `Remove action`,
  [`Remove case`]: `Remove case`,
  [`Remove catch`]: `Remove catch`,
  [`Remove filter`]: `Remove filter`,
  [`Remove input`]: `Remove input`,
  [`Remove mapping`]: `Remove mapping`,
  [`Remove output`]: `Remove output`,
  [`Remove static value`]: `Remove static value`,
  [`Removed`]: `Removed`,
  [`Removed by user`]: `Removed by user`,
  [`Reorder`]: `Reorder`,
  [`Repeat`]: `Repeat`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Repeat the API invocation a set number of times, or until the stop button is clicked`,
  [`Replace`]: `Replace`,
  [`Replace Free Trial with`]: `Replace Free Trial with`,
  [`Replace Gold with`]: `Replace Gold with`,
  [`Replace GraphQL schema`]: `Replace GraphQL schema`,
  [`Replace plans`]: `Replace plans`,
  [`Replace private key & public key`]: `Replace private key & public key`,
  [`Replace Product`]: `Replace Product`,
  [`Replace product`]: `Replace product`,
  [`Replace schema`]: `Replace schema`,
  [`Replace Silver with`]: `Replace Silver with`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`,
  [`Replace|permission`]: `Replace`,
  [`Republish`]: `Republish`,
  [`Republish product`]: `Republish product`,
  [`Request`]: `Request`,
  [`Request catch`]: `Request catch`,
  [`Request endpoint`]: `Request endpoint`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Request approved to {action} API product in catalog {catalog}`,
  [`Request approved to {{action}} app {{appName}}`]: `Request approved to {action} app {appName}`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Request denied to {action} API product in catalog {catalog}`,
  [`Request denied to {{action}} app {{appName}}`]: `Request denied to {action} app {appName}`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Request for approval to subscribe to an API product in the {catalog} catalog`,
  [`Request for approval to {{action}} app {{appName}}`]: `Request for approval to {action} app {appName}`,
  [`Request headers`]: `Request headers`,
  [`Request pending to promote application to production`]: `Request pending to promote application to production`,
  [`Request query`]: `Request query`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Request received to {action} API product in catalog {catalog}`,
  [`Request received to {{action}} app {{appName}}`]: `Request received to {action} app {appName}`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Request withdrawn to subscribe to an API product in the {catalog} catalog`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Request withdrawn to {action} an API product in the {catalog} catalog`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Request withdrawn to {action} app {appName}`,
  [`Requested approvals`]: `Requested approvals`,
  [`Requested By`]: `Requested By`,
  [`Requested To`]: `Requested To`,
  [`Require`]: `Require`,
  [`Require applications to provide a client ID`]: `Require applications to provide a client ID`,
  [`Require approval for all new self service development accounts`]: `Require approval for all new self service development accounts`,
  [`Required`]: `Required`,
  [`Required for rate limiting`]: `Required for rate limiting`,
  [`Required must be checked off when location is in path.`]: `Required must be checked off when location is in path.`,
  [`Required path`]: `Required path`,
  [`Resend Invite`]: `Resend Invite`,
  [`Resend invitation`]: `Resend invitation`,
  [`Resend invite`]: `Resend invite`,
  [`Reset`]: `Reset`,
  [`Reset defaults`]: `Reset defaults`,
  [`Reset Notification template`]: `Reset Notification template`,
  [`Reset password`]: `Reset password`,
  [`Reset password TTL has been changed`]: `Reset password TTL has been changed`,
  [`Reset password time to live`]: `Reset password time to live`,
  [`Reset password time-to-live`]: `Reset password time-to-live`,
  [`Reset password email has been sent`]: `Reset password email has been sent`,
  [`Reset password email has not been sent`]: `Reset password email has not been sent`,
  [`Reset password invitation timeout`]: `Reset password invitation timeout`,
  [`Reset to default preferences`]: `Reset to default preferences`,
  [`Resolve API Connect variable references`]: `Resolve API Connect variable references`,
  [`Resolve XML input data type`]: `Resolve XML input data type`,
  [`resolver`]: `resolver`,
  [`Resource group`]: `Resource group`,
  [`Resource Owner`]: `Resource Owner`,
  [`Resource owner password grant`]: `Resource owner password grant`,
  [`Resource owner revocation`]: `Resource owner revocation`,
  [`Resource owner revocation path`]: `Resource owner revocation path`,
  [`Resource Owner Security`]: `Resource Owner Security`,
  [`Resource owner - JWT`]: `Resource owner - JWT`,
  [`Resource owner - Password`]: `Resource owner - Password`,
  [`Resource owner password`]: `Resource owner password`,
  [`Resources`]: `Resources`,
  [`Response`]: `Response`,
  [`Response catch`]: `Response catch`,
  [`Response Definition`]: `Response Definition`,
  [`Response Time`]: `Response Time`,
  [`Response type`]: `Response type`,
  [`Response context variables`]: `Response context variables`,
  [`Response headers`]: `Response headers`,
  [`Response must have a description`]: `Response must have a description`,
  [`Response must have a schema`]: `Response must have a schema`,
  [`Response must have a status code`]: `Response must have a status code`,
  [`Response object variable`]: `Response object variable`,
  [`Response time`]: `Response time`,
  [`Response time: {{value}}ms`]: `Response time: {{value}}ms`,
  [`Result of removing`]: `Result of removing`,
  [`Restrict HTTP transactions to version 1.0.`]: `Restrict HTTP transactions to version 1.0.`,
  [`Restrict to HTTP 1.0`]: `Restrict to HTTP 1.0`,
  [`Retire`]: `Retire`,
  [`Retire product`]: `Retire product`,
  [`Retired`]: `Retired`,
  [`Retire|permission`]: `Retire`,
  [`Return V5 Responses`]: `Return V5 Responses`,
  [`Retry selected requests`]: `Retry selected requests`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Return results for standard GraphQL introspection queries from GraphQL proxy`,
  [`Return third-party access_token`]: `Return third-party access_token`,
  [`Return third-party access_token with the token response.`]: `Return third-party access_token with the token response.`,
  [`Return third-party id_token`]: `Return third-party id_token`,
  [`Return third-party id_token with the token response.`]: `Return third-party id_token with the token response.`,
  [`Return to Login`]: `Return to Login`,
  [`Reveal in finder`]: `Reveal in finder`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Review the summary of changes, which may include additional changes to keep the schema valid.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`,
  [`Reviewed schema, paths and operations`]: `Reviewed schema, paths and operations`,
  [`Revocation protocol`]: `Revocation protocol`,
  [`Revoke token`]: `Revoke token`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL docs`,
  [`Role`]: `Role`,
  [`Role default`]: `Role default`,
  [`Role default has been deleted`]: `Role default has been deleted`,
  [`Role defaults`]: `Role defaults`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`,
  [`Role has been created`]: `Role has been created`,
  [`Role has been deleted`]: `Role has been deleted`,
  [`Role has been updated`]: `Role has been updated`,
  [`Roles`]: `Roles`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Roles and permissions control access to API Manager capabilities.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Roles and their associated permissions control access to features and the ability to perform tasks.`,
  [`Root`]: `Root`,
  [`Root XML Element Name`]: `Root XML Element Name`,
  [`Run the following command in the CLI to register the credentials:`]: `Run the following command in the CLI to register the credentials:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Run the following command in the CLI to register the Toolkit credentials:`,
  [`Running`]: `Running`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Runtime variable containing the JWK to use to decrypt the JWT.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Runtime variable containing the JWK to use to encrypt the JWT.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Runtime variable containing the JWK to use to sign the JWT.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Runtime variable containing the JWK to use to verify the signature.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Runtime variable from which a valid set of JSON claims can be retrieved.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Runtime variable from which the Subject (sub) claim string can be retrieved.`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `Runtime variable to which the full set of claims that are in the JWT is assigned.`,
  [`Russian`]: `Russian`,
  [`Scalar`]: `Scalar`,
  [`Schema`]: `Schema`,
  [`SCHEMA`]: `SCHEMA`,
  [`SCOPE`]: `SCOPE`,
  [`SCOPES`]: `SCOPES`,
  [`SECURITY DEFINITIONS`]: `SECURITY DEFINITIONS`,
  [`Service`]: `Service`,
  [`Service state:`]: `Service state:`,
  [`SERVICE TYPE`]: `SERVICE TYPE`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1 license`,
  [`SOURCE`]: `SOURCE`,
  [`SSL Profile`]: `SSL Profile`,
  [`State`]: `State`,
  [`STATUS`]: `STATUS`,
  [`Status Code`]: `Status Code`,
  [`STATUS CODE`]: `STATUS CODE`,
  [`SUBMITTED`]: `SUBMITTED`,
  [`SUBSCRIBERS`]: `SUBSCRIBERS`,
  [`Summary`]: `Summary`,
  [`Same as product`]: `Same as product`,
  [`Sample ID token signing key (RS256)`]: `Sample ID token signing key (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Sample ID token validation key (RS256)`,
  [`Sample OAuth symmetric key value`]: `Sample OAuth symmetric key value`,
  [`Sample URL: https://api.catalog.org.host/`]: `Sample URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `Sample URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Sample scope description 1`,
  [`Sandbox - Overview`]: `Sandbox - Overview`,
  [`Sandbox Catalog`]: `Sandbox Catalog`,
  [`Sandbox Catalog User Registry`]: `Sandbox Catalog User Registry`,
  [`Sandbox Default TLS client profile`]: `Sandbox Default TLS client profile`,
  [`Sandbox Default TLS Client Profile`]: `Sandbox Default TLS Client Profile`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Sandbox catalog should always exist in a provider organization.`,
  [`Save`]: `Save`,
  [`Save API as a new version`]: `Save API as a new version`,
  [`Save Product as a new version`]: `Save Product as a new version`,
  [`Save and Edit`]: `Save and Edit`,
  [`Save and republish`]: `Save and republish`,
  [`Save as a new version`]: `Save as a new version`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Save the client secret (it will no longer be retrievable for security purposes)`,
  [`Save this product as a new version`]: `Save this product as a new version`,
  [`Saved`]: `Saved`,
  [`Saving assembly...`]: `Saving assembly...`,
  [`Saving...`]: `Saving...`,
  [`Schema Type`]: `Schema Type`,
  [`Schema as JSON`]: `Schema as JSON`,
  [`Schema as XML`]: `Schema as XML`,
  [`Schema as YAML`]: `Schema as YAML`,
  [`Schema change`]: `Schema change`,
  [`Schema changes`]: `Schema changes`,
  [`Schema definition circular reference limit`]: `Schema definition circular reference limit`,
  [`Schema name`]: `Schema name`,
  [`Schema warnings`]: `Schema warnings`,
  [`Schema definition`]: `Schema definition`,
  [`Scheme`]: `Scheme`,
  [`Schemes`]: `Schemes`,
  [`Scope`]: `Scope`,
  [`Scopes`]: `Scopes`,
  [`Scopes cannot be empty`]: `Scopes cannot be empty`,
  [`Search`]: `Search`,
  [`Search DN`]: `Search DN`,
  [`Search in the field above above and select to add provider organizations`]: `Search in the field above above and select to add provider organizations`,
  [`Search Limit`]: `Search Limit`,
  [`Search Organization owner from`]: `Search Organization owner from`,
  [`Search orgs/groups`]: `Search orgs/groups`,
  [`Search Owner`]: `Search Owner`,
  [`Search assemblies`]: `Search assemblies`,
  [`Search for`]: `Search for`,
  [`Search for provider organizations`]: `Search for provider organizations`,
  [`Search for Owner`]: `Search for Owner`,
  [`Search for a new member to become the new owner.`]: `Search for a new member to become the new owner.`,
  [`Search for an existing member to become the new owner.`]: `Search for an existing member to become the new owner.`,
  [`Search for new owner`]: `Search for new owner`,
  [`Search for owner from:`]: `Search for owner from:`,
  [`Search for the member`]: `Search for the member`,
  [`Search for title or name or version`]: `Search for title or name or version`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Search for the user that you want to add as a member, and assign the required roles.`,
  [`Search overflow`]: `Search overflow`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Search the registry for the existing user that you want to add as a member, and assign the required roles`,
  [`Search types/fields`]: `Search types/fields`,
  [`Search user`]: `Search user`,
  [`Search schema`]: `Search schema`,
  [`Seconds`]: `Seconds`,
  [`Secret Key`]: `Secret Key`,
  [`Secret Name`]: `Secret Name`,
  [`Secret Required`]: `Secret Required`,
  [`Secret key`]: `Secret key`,
  [`Secure`]: `Secure`,
  [`Secure connection`]: `Secure connection`,
  [`Secure using Client ID`]: `Secure using Client ID`,
  [`Security`]: `Security`,
  [`Security & Product Definitions`]: `Security & Product Definitions`,
  [`Security definitions`]: `Security definitions`,
  [`Security and Publish`]: `Security and Publish`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Security definitions selected here apply across the API, but can be overridden for individual operations.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`,
  [`Security object to use to decode the claim.`]: `Security object to use to decode the claim.`,
  [`Security object used to encrypt the JWT.`]: `Security object used to encrypt the JWT.`,
  [`Security object used to sign the JWT.`]: `Security object used to sign the JWT.`,
  [`Security object used to verify the claim.`]: `Security object used to verify the claim.`,
  [`Select APIs to add to this product`]: `Select APIs to add to this product`,
  [`Select API Type`]: `Select API Type`,
  [`Select API type`]: `Select API type`,
  [`Select a billing integration in the Product plans.`]: `Select a billing integration in the Product plans.`,
  [`Select a flow...`]: `Select a flow...`,
  [`Select a plan from your existing product`]: `Select a plan from your existing product`,
  [`Select a policy file to import to this catalog`]: `Select a policy file to import to this catalog`,
  [`Select a policy implementation file to import to this catalog`]: `Select a policy implementation file to import to this catalog`,
  [`Select a product`]: `Select a product`,
  [`Select Certificate Manager service`]: `Select Certificate Manager service`,
  [`Select Cloud`]: `Select Cloud`,
  [`Select Email Server`]: `Select Email Server`,
  [`Select gateway services`]: `Select gateway services`,
  [`Select Import Type`]: `Select Import Type`,
  [`Select product`]: `Select product`,
  [`Select product type`]: `Select product type`,
  [`Select Service`]: `Select Service`,
  [`Select Service Type`]: `Select Service Type`,
  [`Select target endpoints`]: `Select target endpoints`,
  [`Select target service`]: `Select target service`,
  [`Select user registry type`]: `Select user registry type`,
  [`Select a WSDL service from the imported file`]: `Select a WSDL service from the imported file`,
  [`Select a certificate`]: `Select a certificate`,
  [`Select a Certificate Manager service`]: `Select a Certificate Manager service`,
  [`Select a cryptographic algorithm.`]: `Select a cryptographic algorithm.`,
  [`Select a default catalog and organization`]: `Select a default catalog and organization`,
  [`Select a different cloud`]: `Select a different cloud`,
  [`Select a key encryption algorithm.`]: `Select a key encryption algorithm.`,
  [`Select a plan to migrate`]: `Select a plan to migrate`,
  [`Select a plan to replace`]: `Select a plan to replace`,
  [`Select a plan to supersede`]: `Select a plan to supersede`,
  [`Select a product to set as the migration target`]: `Select a product to set as the migration target`,
  [`Select a product to supersede`]: `Select a product to supersede`,
  [`Select a product to supersede {sourceProductName}:`]: `Select a product to supersede {sourceProductName}:`,
  [`Select a resource group`]: `Select a resource group`,
  [`Select a service`]: `Select a service`,
  [`Select a supported file ({type}) and try again.`]: `Select a supported file ({type}) and try again.`,
  [`Select a target SOAP service`]: `Select a target SOAP service`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Select a toolkit package to download. Skip this step if you have the toolkit installed.`,
  [`Select an encryption algorithm.`]: `Select an encryption algorithm.`,
  [`Select an extension file for this gateway`]: `Select an extension file for this gateway`,
  [`Select an org`]: `Select an org`,
  [`Select catalog`]: `Select catalog`,
  [`Select Catalogs to Search`]: `Select Catalogs to Search`,
  [`Select compatible gateway services`]: `Select compatible gateway services`,
  [`Select one Keystore from the list below:`]: `Select one Keystore from the list below:`,
  [`Select one keystore from the list below:`]: `Select one keystore from the list below:`,
  [`Select one keystore from the list.`]: `Select one keystore from the list.`,
  [`Select operation...`]: `Select operation...`,
  [`Select operations you want to include`]: `Select operations you want to include`,
  [`Select organization`]: `Select organization`,
  [`Select the API definition or package file to import`]: `Select the API definition or package file to import`,
  [`Select the billing integration for this catalog`]: `Select the billing integration for this catalog`,
  [`Select the billing integration for this product`]: `Select the billing integration for this product`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`,
  [`Select the APIs to add to this product`]: `Select the APIs to add to this product`,
  [`Select the OpenAPI definition file to import`]: `Select the OpenAPI definition file to import`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`,
  [`Select the TLS protocol versions for this profile.`]: `Select the TLS protocol versions for this profile.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Select the analytics service you would like to associate with the gateway service`,
  [`Select the consumer organization in which you want to create the application`]: `Select the consumer organization in which you want to create the application`,
  [`Select the consumer organization that will own the application`]: `Select the consumer organization that will own the application`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Select the endpoints to publish this API and define the rate limit as needed`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Select the gateway services at which the APIs in the Product are to be made available.`,
  [`Select the gateway type for this API`]: `Select the gateway type for this API`,
  [`Select the gateway type for this OAuth provider`]: `Select the gateway type for this OAuth provider`,
  [`Select the keystores and truststores for this profile.`]: `Select the keystores and truststores for this profile.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Select the organizations or groups you would like to make this product visible to`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Select the organizations or groups you would like to subscribe to this product`,
  [`Select the portal service to use for this catalog`]: `Select the portal service to use for this catalog`,
  [`Select the product and plan for the application subscription`]: `Select the product and plan for the application subscription`,
  [`Select the product definition file to import`]: `Select the product definition file to import`,
  [`Select the product definition file to import from`]: `Select the product definition file to import from`,
  [`Select the product that will supersede the originally selected product`]: `Select the product that will supersede the originally selected product`,
  [`Select the product that you want to add the API to`]: `Select the product that you want to add the API to`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Select the registries that you want to use to control access to the APIs in this catalog`,
  [`Select the required operations`]: `Select the required operations`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`,
  [`Select the target wsdl file to create from`]: `Select the target wsdl file to create from`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Select the target wsdl, xsd or zip file to create the API definition from`,
  [`Select the target WSDL file to import`]: `Select the target WSDL file to import`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Select the user registries for authenticating API Manager users, or create a new use registry.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Select the user registries for authenticating users of API Manager, or create a new user registry.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Select the user registries that are used to authenticate login to the Developer Portal`,
  [`Select the user registry type`]: `Select the user registry type`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Selected product has been modified. Please refresh this page and try again.`,
  [`Selecting operations from WSDL service definition`]: `Selecting operations from WSDL service definition`,
  [`Self Service Onboarding for Consumer organization`]: `Self Service Onboarding for Consumer organization`,
  [`Self service onboarding`]: `Self service onboarding`,
  [`Self service onboarding approval`]: `Self service onboarding approval`,
  [`Send`]: `Send`,
  [`Send Message`]: `Send Message`,
  [`Send Message to Owners`]: `Send Message to Owners`,
  [`Send To`]: `Send To`,
  [`Send a registration link to a new user to register as owner.`]: `Send a registration link to a new user to register as owner.`,
  [`Send a test email`]: `Send a test email`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Send a test email to confirm that the email server is properly configured.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Send a test signal to confirm the endpoint is properly configured.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Send a test signal to confirm the endpoint is properly configured`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Send an email message to Admin organization owner. Place a checkmark next to the owner's name`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Send an email message to an owner. Place a checkmark next to the owner's name`,
  [`Send chunked-encoded documents to the target server.`]: `Send chunked-encoded documents to the target server.`,
  [`Send message`]: `Send message`,
  [`Send message to owner`]: `Send message to owner`,
  [`Send test email`]: `Send test email`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Send the activation link to a user to invite them to sign in or register.`,
  [`Send-only`]: `Send-only`,
  [`Sender`]: `Sender`,
  [`Sender & email server`]: `Sender & email server`,
  [`Sender & email server have been changed`]: `Sender & email server have been changed`,
  [`Sender Info`]: `Sender Info`,
  [`Sent`]: `Sent`,
  [`Sent events: **{sent}**`]: `Sent events: **{sent}**`,
  [`Serial`]: `Serial`,
  [`Serialize output`]: `Serialize output`,
  [`Server connection`]: `Server connection`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `Serve HTML to web browsers to enable GUI GraphQL client`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Serve HTML to web browsers to enable GUI GraphQL client.`,
  [`Server Name Indication (SNI)`]: `Server Name Indication (SNI)`,
  [`Server URL`]: `Server URL`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Server returned an error in hash instead of token in OIDC redirect URL. Hash:`,
  [`Service name`]: `Service name`,
  [`Services`]: `Services`,
  [`Set`]: `Set`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`,
  [`Set Migration Target`]: `Set Migration Target`,
  [`Set migration target`]: `Set migration target`,
  [`Set Variable`]: `Set Variable`,
  [`Set as default`]: `Set as default`,
  [`Set default`]: `Set default`,
  [`Set rate and burst limits in an assembly`]: `Set rate and burst limits in an assembly`,
  [`Set the timeout period for invitations`]: `Set the timeout period for invitations`,
  [`Set the trust level for the profile`]: `Set the trust level for the profile`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`,
  [`Set the visibility for a gateway service`]: `Set the visibility for a gateway service`,
  [`Set the visibility for a portal service`]: `Set the visibility for a portal service`,
  [`Set the visibility for each service`]: `Set the visibility for each service`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Set up time frame to get a new access token issued by the authentication server`,
  [`Set visibility`]: `Set visibility`,
  [`Set visibility of gateway`]: `Set visibility of gateway`,
  [`Set time interval`]: `Set time interval`,
  [`Set, Add, or Clear a runtime variable.`]: `Set, Add, or Clear a runtime variable.`,
  [`Set the timeout period for API key`]: `Set the timeout period for API key`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`,
  [`Settings`]: `Settings`,
  [`Setup`]: `Setup`,
  [`Severity level for input data log messages`]: `Severity level for input data log messages`,
  [`Share the resource with a custom list of provider organizations`]: `Share the resource with a custom list of provider organizations`,
  [`Share the resource with provider organizations`]: `Share the resource with provider organizations`,
  [`Share the API gateway with a custom list of provider organizations`]: `Share the API gateway with a custom list of provider organizations`,
  [`Share the service with a custom list of provider organizations`]: `Share the service with a custom list of provider organizations`,
  [`Share the API gateway with provider organizations`]: `Share the API gateway with provider organizations`,
  [`Share the service with provider organizations`]: `Share the service with provider organizations`,
  [`Share`]: `Share`,
  [`Shared`]: `Shared`,
  [`Shared by`]: `Shared by`,
  [`Short but descriptive name for this policy.`]: `Short but descriptive name for this policy.`,
  [`Show`]: `Show`,
  [`Show / hide policy palette`]: `Show / hide policy palette`,
  [`Show/hide`]: `Show/hide`,
  [`Show/hide settings`]: `Show/hide settings`,
  [`Show and hide types and fields to include in the schema.`]: `Show and hide types and fields to include in the schema.`,
  [`Show added only`]: `Show added only`,
  [`Show all`]: `Show all`,
  [`Show all UDP versions`]: `Show all UDP versions`,
  [`Show app identifier`]: `Show app identifier`,
  [`Show breaking changes only`]: `Show breaking changes only`,
  [`Show catalog identifier`]: `Show catalog identifier`,
  [`Show catches`]: `Show catches`,
  [`Show in schema`]: `Show in schema`,
  [`Show less`]: `Show less`,
  [`Show modified only`]: `Show modified only`,
  [`Show more`]: `Show more`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`,
  [`Show removed only`]: `Show removed only`,
  [`Show schema`]: `Show schema`,
  [`Show warnings only`]: `Show warnings only`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Shows history of keystore assigments except currently assigned keystore.`,
  [`Sign Crypto Object`]: `Sign Crypto Object`,
  [`Sign in`]: `Sign in`,
  [`Sign JWK variable name`]: `Sign JWK variable name`,
  [`Sign Out`]: `Sign Out`,
  [`Sign up`]: `Sign up`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Sign in using the {userRegistryType} User Registry`,
  [`Sign in with`]: `Sign in with`,
  [`Sign out`]: `Sign out`,
  [`Sign up with`]: `Sign up with`,
  [`Signed token`]: `Signed token`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`,
  [`Single level`]: `Single level`,
  [`Sized fields`]: `Sized fields`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Slicing arguments`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`,
  [`Something went wrong.`]: `Something went wrong.`,
  [`Something went wrong...`]: `Something went wrong...`,
  [`Source`]: `Source`,
  [`Source schema`]: `Source schema`,
  [`Space`]: `Space`,
  [`Space is not allowed in scope name`]: `Space is not allowed in scope name`,
  [`Space details`]: `Space details`,
  [`Space Enabled`]: `Space Enabled`,
  [`Space Name`]: `Space Name`,
  [`Space Summary`]: `Space Summary`,
  [`Space Title`]: `Space Title`,
  [`Space owner`]: `Space owner`,
  [`Space {name} created`]: `Space {name} created`,
  [`Spaces`]: `Spaces`,
  [`Spaces are enabled`]: `Spaces are enabled`,
  [`Spaces are not enabled`]: `Spaces are not enabled`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Spaces cannot be enabled for the sandbox catalog.`,
  [`Spaces cannot be enabled.`]: `Spaces cannot be enabled.`,
  [`Spaces dropdown`]: `Spaces dropdown`,
  [`Spaces has been enabled`]: `Spaces has been enabled`,
  [`Spaces have been enabled.`]: `Spaces have been enabled.`,
  [`Spaces per page`]: `Spaces per page`,
  [`Spanish`]: `Spanish`,
  [`Special characters are not accepted`]: `Special characters are not accepted`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Specifies whether and where to obtain custom metadata for the access token.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Specifies whether to serialize the XSLT output tree into binary data after the transformation.`,
  [`Specify default gateways`]: `Specify default gateways`,
  [`Specify email`]: `Specify email`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Specify owner of the consumer organization`,
  [`Specify owner of the provider organization`]: `Specify owner of the provider organization`,
  [`Specify settings for the third party OAuth provider.`]: `Specify settings for the third party OAuth provider.`,
  [`Specify the MIME types that your API consumes and produces`]: `Specify the MIME types that your API consumes and produces`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Specify the catalog owner; the owner has all catalog permissions`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`,
  [`Specify the details of the catalog that you want to publish to`]: `Specify the details of the catalog that you want to publish to`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`,
  [`Specify the new owner`]: `Specify the new owner`,
  [`Specify the owner`]: `Specify the owner`,
  [`Specify the owner and title`]: `Specify the owner and title`,
  [`Specify the properties to redact or remove.`]: `Specify the properties to redact or remove.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Specify the publish and rate limiting requirements for this API`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`,
  [`Specify the security settings for the imported API definition`]: `Specify the security settings for the imported API definition`,
  [`Specify the security settings for your API definition`]: `Specify the security settings for your API definition`,
  [`Specify the space owner; the owner has all space permissions`]: `Specify the space owner; the owner has all space permissions`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Specify the timeout period after which invitation emails expire if the user does not activate their account`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Specify the timeout period after which invitation emails expire if the user does not activate their account.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Specify the type of gateway, a title, and a summary that describes the gateway.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Specify the visibility of the API gateway to API Connect provider organization.`,
  [`Specify the way you want to validate the message.`]: `Specify the way you want to validate the message.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`,
  [`Specify what content to log in the event of an error.`]: `Specify what content to log in the event of an error.`,
  [`Specify what content to log.`]: `Specify what content to log.`,
  [`Specifying the publish target details`]: `Specifying the publish target details`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Specifically allow xsi:type=SOAP-ENC:Array rule`,
  [`Stage`]: `Stage`,
  [`Stage API`]: `Stage API`,
  [`Stage product`]: `Stage product`,
  [`Stage to`]: `Stage to`,
  [`Staged`]: `Staged`,
  [`Stage|permission`]: `Stage`,
  [`Standard OIDC`]: `Standard OIDC`,
  [`Static DNS`]: `Static DNS`,
  [`Static group`]: `Static group`,
  [`Status`]: `Status`,
  [`Status code`]: `Status code`,
  [`Step 1`]: `Step 1`,
  [`Step 1: Download`]: `Step 1: Download`,
  [`Step 1: Download toolkit`]: `Step 1: Download toolkit`,
  [`Step 1: Upload private key`]: `Step 1: Upload private key`,
  [`Step 2`]: `Step 2`,
  [`Step 2: Download credentials`]: `Step 2: Download credentials`,
  [`Step 2: Download toolkit credentials`]: `Step 2: Download toolkit credentials`,
  [`Step 2: Install`]: `Step 2: Install`,
  [`Step 2: Upload public key`]: `Step 2: Upload public key`,
  [`Step 3`]: `Step 3`,
  [`Step 3: Configure`]: `Step 3: Configure`,
  [`Step 3: Run command`]: `Step 3: Run command`,
  [`Step 3: Run commands`]: `Step 3: Run commands`,
  [`Step 4: Register`]: `Step 4: Register`,
  [`Stop`]: `Stop`,
  [`Stop after:`]: `Stop after:`,
  [`Stop all the retries`]: `Stop all the retries`,
  [`Stop Connection`]: `Stop Connection`,
  [`Stop on error`]: `Stop on error`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Stop running this API and remove it from the Sandbox catalog.`,
  [`Stopped`]: `Stopped`,
  [`Stopping Published API`]: `Stopping Published API`,
  [`Strict`]: `Strict`,
  [`Strict SOAP envelope version`]: `Strict SOAP envelope version`,
  [`String Badgerfish`]: `String Badgerfish`,
  [`String`]: `String`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Stripe integration`,
  [`Streaming rule`]: `Streaming rule`,
  [`Subject`]: `Subject`,
  [`Subject Claim`]: `Subject Claim`,
  [`Subject Origin`]: `Subject Origin`,
  [`Subject common name`]: `Subject common name`,
  [`Subject|certificate`]: `Subject`,
  [`Submit`]: `Submit`,
  [`Submitted`]: `Submitted`,
  [`Subscribability`]: `Subscribability`,
  [`Subscribability must be disabled if visibility is disabled`]: `Subscribability must be disabled if visibility is disabled`,
  [`Subscribability options`]: `Subscribability options`,
  [`Subscribable by`]: `Subscribable by`,
  [`Subscribe`]: `Subscribe`,
  [`Subscribe Financial Management App`]: `Subscribe Financial Management App`,
  [`Subscribe TODO App`]: `Subscribe TODO App`,
  [`Subscription`]: `Subscription`,
  [`Subscription approval task for subscription of`]: `Subscription approval task for subscription of`,
  [`Subscription created`]: `Subscription created`,
  [`Subscription has been added`]: `Subscription has been added`,
  [`Subscription has not been added`]: `Subscription has not been added`,
  [`Subscription pending approval`]: `Subscription pending approval`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `Subscription type has been removed since GraphQL Subscriptions are not supported.`,
  [`Subscription-Approval`]: `Subscription-Approval`,
  [`Subscriptions`]: `Subscriptions`,
  [`Success`]: `Success`,
  [`Suffix`]: `Suffix`,
  [`Suggestion`]: `Suggestion`,
  [`Supersede`]: `Supersede`,
  [`Supersede Product`]: `Supersede Product`,
  [`Supersede product`]: `Supersede product`,
  [`Supersede plans`]: `Supersede plans`,
  [`Supersede {product1} with {product2}`]: `Supersede {product1} with {product2}`,
  [`Supersede|permission`]: `Supersede`,
  [`Supersede|supersede with`]: `Supersede`,
  [`Support`]: `Support`,
  [`support default introspection`]: `support default introspection`,
  [`Support Server Name Indication (SNI)`]: `Support Server Name Indication (SNI)`,
  [`Support hybrid response types`]: `Support hybrid response types`,
  [`Support standard introspection`]: `Support standard introspection`,
  [`Supported OAuth components`]: `Supported OAuth components`,
  [`Supported client types`]: `Supported client types`,
  [`Supported client types can not be empty.`]: `Supported client type can not be empty.`,
  [`Supported grant type can not be empty.`]: `Supported grant type can not be empty.`,
  [`Supported grant types`]: `Supported grant types`,
  [`Suspend`]: `Suspend`,
  [`Suspend API`]: `Suspend API`,
  [`Suspend Application`]: `Suspend Application`,
  [`Switch`]: `Switch`,
  [`Switch account`]: `Switch account`,
  [`Switch cloud connection`]: `Switch cloud connection`,
  [`Switch directory`]: `Switch directory`,
  [`TARGET`]: `TARGET`,
  [`Title`]: `Title`,
  [`TlS`]: `TlS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS Client`,
  [`TLS Client Profile`]: `TLS Client Profile`,
  [`TLS Client Profile has been configured.`]: `TLS Client Profile has been configured.`,
  [`TLS client profile is required when endpoint starts with https://`]: `TLS client profile is required when endpoint starts with https://`,
  [`TLS Client Profiles`]: `TLS Client Profiles`,
  [`TLS client profile has been configured.`]: `TLS client profile has been configured.`,
  [`TLS client profiles`]: `TLS client profiles`,
  [`TLS Profile`]: `TLS Profile`,
  [`TLS SERVER PROFILE`]: `TLS SERVER PROFILE`,
  [`TLS server profile`]: `TLS server profile`,
  [`TLS version 1.0`]: `TLS version 1.0`,
  [`TLS version 1.1`]: `TLS version 1.1`,
  [`TLS version 1.2`]: `TLS version 1.2`,
  [`TLS version 1.3`]: `TLS version 1.3`,
  [`TLS client profile`]: `TLS client profile`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`,
  [`TLS profile`]: `TLS profile`,
  [`TLS profile is not visible or not available.`]: `TLS profile is not visible or not available.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `TLS profile to use for the secure transmission of data to the Authentication URL.`,
  [`Tabs for code language options`]: `Tabs for code language options`,
  [`Truststore`]: `Truststore`,
  [`truststore`]: `truststore`,
  [`Type`]: `Type`,
  [`Type name`]: `Type name`,
  [`Type/Field`]: `Type/Field`,
  [`Type weight`]: `Type weight`,
  [`Types per page:`]: `Types per page:`,
  [`Tags`]: `Tags`,
  [`Tags and external documentation`]: `Tags and external documentation`,
  [`Take offline`]: `Take offline`,
  [`Target`]: `Target`,
  [`Target API (URL)`]: `Target API (URL)`,
  [`Target SOAP service`]: `Target SOAP service`,
  [`Target Services`]: `Target Services`,
  [`Target service URL`]: `Target service URL`,
  [`Target services`]: `Target services`,
  [`Target URL`]: `Target URL`,
  [`Target application`]: `Target application`,
  [`Target catalog`]: `Target catalog`,
  [`Target gateway service`]: `Target gateway service`,
  [`Target gateway services`]: `Target gateway services`,
  [`Target plan`]: `Target plan`,
  [`Target product`]: `Target product`,
  [`Target product rate limit`]: `Target product rate limit`,
  [`Target product subscribability`]: `Target product subscribability`,
  [`Target product visibility`]: `Target product visibility`,
  [`Target schema`]: `Target schema`,
  [`Target services have been updated.`]: `Target services have been updated.`,
  [`Task`]: `Task`,
  [`Task has been updated`]: `Task has been updated`,
  [`Task self approval`]: `Task self approval`,
  [`Task self-approval`]: `Task self-approval`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Tasks`,
  [`Tasks for creating new provider organizations`]: `Tasks for creating new provider organizations`,
  [`TCP`]: `TCP`,
  [`Template`]: `Template`,
  [`Template: {title}`]: `Template: {title}`,
  [`Template: account approved`]: `Template: account approved`,
  [`Template: account denied`]: `Template: account denied`,
  [`Template: account pending approval`]: `Template: account pending approval`,
  [`Template: app lifecycle approved`]: `Template: app lifecycle approved`,
  [`Template: app lifecycle denied`]: `Template: app lifecycle denied`,
  [`Template: app lifecycle pending`]: `Template: app lifecycle pending`,
  [`Template: app reinstated`]: `Template: app reinstated`,
  [`Template: app suspended`]: `Template: app suspended`,
  [`Template: app lifecycle cancelled`]: `Template: app lifecycle cancelled`,
  [`Template: app lifecycle request`]: `Template: app lifecycle request`,
  [`Template: invitation`]: `Template: invitation`,
  [`Template: mail server test connection`]: `Template: mail server test connection`,
  [`Template: member invitation`]: `Template: member invitation`,
  [`Template: password changed`]: `Template: password changed`,
  [`Template: password reset`]: `Template: password reset`,
  [`Template: sign up`]: `Template: sign up`,
  [`Template: task consumer onboard request`]: `Template: task consumer onboard request`,
  [`Template: task product lifecycle approved`]: `Template: task product lifecycle approved`,
  [`Template: task product lifecycle cancelled`]: `Template: task product lifecycle cancelled`,
  [`Template: task product lifecycle denied`]: `Template: task product lifecycle denied`,
  [`Template: task product lifecycle pending`]: `Template: task product lifecycle pending`,
  [`Template: task product lifecycle request`]: `Template: task product lifecycle request`,
  [`Template: task subscription cancelled`]: `Template: task subscription cancelled`,
  [`Template: task subscription request`]: `Template: task subscription request`,
  [`Template: task subscription approved`]: `Template: task subscription approved`,
  [`Template: task subscription denied`]: `Template: task subscription denied`,
  [`Template: task subscription pending`]: `Template: task subscription pending`,
  [`Temporarily disable subscribability`]: `Temporarily disable subscribability`,
  [`Temporarily disable visibility`]: `Temporarily disable visibility`,
  [`Terms of service`]: `Terms of service`,
  [`Test`]: `Test`,
  [`Test API`]: `Test API`,
  [`Test APIs`]: `Test APIs`,
  [`Test Bind & Get Base DN`]: `Test Bind & Get Base DN`,
  [`Test configuration`]: `Test configuration`,
  [`Test connection`]: `Test connection`,
  [`Test connection failed.`]: `Test connection failed.`,
  [`Test and production endpoints`]: `Test and production endpoints`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Test and monitor response error: redirect_uri prop is required, got {redirectUri}`,
  [`Test application`]: `Test application`,
  [`Test consumer org`]: `Test consumer org`,
  [`Test email`]: `Test email`,
  [`Test endpoints`]: `Test endpoints`,
  [`Test message from IBM API Connect`]: `Test message from IBM API Connect`,
  [`Testable`]: `Testable`,
  [`TEXT`]: `TEXT`,
  [`The "Definition" property must have a valid value.`]: `The "Definition" property must have a valid value.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`,
  [`The API has unsaved changes.`]: `The API has unsaved changes.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `The API has been fully designed and passed an internal milestone but has not yet implemented.`,
  [`The API is in the implementation phase.`]: `The API is in the implementation phase.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `The API is in the early conceptual phase and is neither fully designed or implemented.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `The API may have unpublished changes. Would you like to republish the product?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`,
  [`The GatewayScript source code to execute.`]: `The GatewayScript source code to execute.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `The GraphQL schema URL with which a GraphQL payload must be validated.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`,
  [`The JavaScript source code to execute.`]: `The JavaScript source code to execute.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`,
  [`The Proxy URL to be used.`]: `The Proxy URL to be used.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `The SSL Profile to use for the secure transmission of data.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `The TLS Profile to use for the secure transmission of data.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `The URL of the JSON schema for validating a JSON payload.`,
  [`The URL of the XML schema for validating an XML payload.`]: `The URL of the XML schema for validating an XML payload.`,
  [`The URL to be invoked.`]: `The URL to be invoked.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `The WSDL schema URL with which a SOAP payload must be validated.`,
  [`The XML element name to be used for JSON array elements.`]: `The XML element name to be used for JSON array elements.`,
  [`The XSLT source to execute.`]: `The XSLT source to execute.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `The activation link can be sent to a user to enable them to activate their account`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`,
  [`The certificate with which to decrypt the buffer`]: `The certificate with which to decrypt the buffer`,
  [`The certificate with which to encrypt the buffer`]: `The certificate with which to encrypt the buffer`,
  [`The change is critical`]: `The change is critical`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`,
  [`The crypto object to use to decode the claim.`]: `The crypto object to use to decode the claim.`,
  [`The crypto object to use to encrypt the message.`]: `The crypto object to use to encrypt the message.`,
  [`The crypto object to use to sign the JWT.`]: `The crypto object to use to sign the JWT.`,
  [`The crypto object to use to verify the signature.`]: `The crypto object to use to verify the signature.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `The current mapping contains references to unrecognized schema elements.`,
  [`The current organization does not contain any catalogs.`]: `The current organization does not contain any catalogs.`,
  [`The default User registry must be included in the list`]: `The default User registry must be included in the list`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `The definition against which the payload must be validated ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`,
  [`The document is missing draft_api.info.version field.`]: `The document is missing draft_api.info.version field.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `The document is missing draft_api.info.x-ibm-name field.`,
  [`The document is missing draft_product.info.name field.`]: `The document is missing draft_product.info.name field.`,
  [`The document is missing draft_product.info.version field.`]: `The document is missing draft_product.info.version field.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `The extra constraints specify a slicing argument that is not an argument on this field.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `This field has neither an assumed size nor any slicing arguments.`,
  [`This field returns unbound list of values with composite type.`]: `This field returns unbound list of values with composite type.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `This field returns unbound list of values with scalar type and type weight != 0.0.`,
  [`This test panel is deprecated and will be removed soon.`]: `This test panel is deprecated and will be removed soon.`,
  [`This type has no fields or no matching field.`]: `This type has no fields or no matching field.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `This XSLT policy should not use the apim module, which is only for migrating old APIs.`,
  [`This type will be deleted.`]: `This type will be deleted.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`,
  [`The following files are available under the`]: `The following files are available under the`,
  [`The following files contain a private key and cannot be uploaded:`]: `The following files contain a private key and cannot be uploaded:`,
  [`The following is available under the`]: `The following is available under the`,
  [`The following lifecycle actions require approval`]: `The following lifecycle actions require approval`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `The following list displays the draft APIs that have been created in this provider organization`,
  [`The following list displays the draft products that have been created in this provider organization`]: `The following list displays the draft products that have been created in this provider organization`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `The following list shows all the users that have been added as members of this catalog, and their assigned roles`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `The following list shows all the users that have been added as members of this space, and their assigned roles`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `The following parameters do not match with the values configured in the OAuth provider`,
  [`The following roles are available in the Cloud Manager`]: `The following roles are available in the Cloud Manager`,
  [`The following types/fields will also be removed:`]: `The following types/fields will also be removed:`,
  [`The following types/fields will be affected:`]: `The following types/fields will be affected:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `The host used to invoke the API`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`,
  [`The message to be sent with the error`]: `The message to be sent with the error`,
  [`The message to be included within exception.`]: `The message to be included within exception.`,
  [`The HTTP status code to be included within exception.`]: `The HTTP status code to be included within exception.`,
  [`The HTTP status reason phrase to be included within exception.`]: `The HTTP status reason phrase to be included within exception.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `The name of a burst limit defined in the datapower configuration.`,
  [`The name of a count limit defined in the datapower configuration.`]: `The name of a count limit defined in the datapower configuration.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `The name of a rate limit defined in the datapower configuration.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `The name of a variable that will be used to store the result. By default, "message" will be used.`,
  [`The name of parameter to find the ID.`]: `The name of parameter to find the ID.`,
  [`The name of parameter to find the secret.`]: `The name of parameter to find the secret.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`,
  [`The name of the error to be thrown`]: `The name of the error to be thrown`,
  [`The name of the variable to be added.`]: `The name of the variable to be added.`,
  [`The name of the variable to be cleared.`]: `The name of the variable to be cleared.`,
  [`The name of the variable to be set.`]: `The name of the variable to be set.`,
  [`The nature of the XML validation to be performed.`]: `The nature of the XML validation to be performed.`,
  [`The operation to apply.`]: `The operation to apply`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`,
  [`The owner must be an existing user in your user registry`]: `The owner must be an existing user in your user registry`,
  [`The owner of consumer organization has the following privileges:`]: `The owner of consumer organization has the following privileges:`,
  [`The owner of provider organization has the following privileges:`]: `The owner of provider organization has the following privileges:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`,
  [`The page you are looking for might have been moved or does not exist.`]: `The page you are looking for might have been moved or does not exist.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`,
  [`The parameters needed to send a valid API call.`]: `The parameters needed to send a valid API call.`,
  [`The password to use for HTTP Basic authentication.`]: `The password to use for HTTP Basic authentication.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `The portal service provides a developer portal used by application developers to discover and implement APIs.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `The processing status provides a view of a gateway’s health with a summary of recent activity`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`,
  [`The selected catalog will only be used to search consumer organizations`]: `The selected catalog will only be used to search consumer organizations`,
  [`The selected catalog does not have any configured gateways`]: `The selected catalog does not have any configured gateways`,
  [`The selected catalog does not have any compatible gateways`]: `The selected catalog does not have any compatible gateways`,
  [`Save Preferences`]: `Save Preferences`,
  [`Preferences`]: `Preferences`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `The selected catalog will only be used to search consumer organizations and groups'`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`,
  [`The selected product does not contain this API`]: `The selected product does not contain this API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `The selected product will be subscribable to a specific list of consumer organizations or groups.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `The selected services will be available to a specific list of provider organizations.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `The selected services will be available to all existing and new provider organizations.`,
  [`The selected services will not be available to any provider organizations.`]: `The selected services will not be available to any provider organizations.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`,
  [`The server responded with a non-JSON body \n{body}`]: `The server responded with a non-JSON body \n{body}`,
  [`The template produces a response message or mock API response. You can use expressions to include dynamic content from context variables.`]: `The template produces a response message or mock API response. You can use expressions to include dynamic content from context variables.`,
  [`The time interval needs to be higher than the access token time to live.`]: `The time interval needs to be higher than the access token time to live.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`,
  [`The transfer protocol of the API.`]: `The transfer protocol of the API.`,
  [`The type of authentication.`]: `The type of authentication.`,
  [`The type of authorization header.`]: `The type of authorization header.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `The type of the value to set. This can be any, string, number or boolean.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `The type of the value to set. This can be string, number or boolean.`,
  [`The username to use for HTTP Basic authentication.`]: `The username to use for HTTP Basic authentication.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `The user for this connection is no longer valid. Please create a new connection.`,
  [`The value that the variable will be set to.`]: `The value that the variable will be set to.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`,
  [`The {title} role`]: `The {title} role`,
  [`The {url} resource does not exist`]: `The {url} resource does not exist`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `There are incompatible APIs due to them having a different Gateway type to this product.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`,
  [`There should be at least {minSelected} {label} selected.`]: `There should be at least {minSelected} {label} selected.`,
  [`There are no API changes to publish.`]: `There are no API changes to publish.`,
  [`There are no gateway services`]: `There are no gateway services`,
  [`There are no assemblies that match the chosen filters.`]: `There are no assemblies that match the chosen filters.`,
  [`There are no burst limits yet.`]: `There are no burst limits yet.`,
  [`There are no catalogs`]: `There are no catalogs`,
  [`There are no consumer organizations.`]: `There are no consumer organizations.`,
  [`There are no consumer organizations. You can create one`]: `There are no consumer organizations. You can create one`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `There are no consumer organizations. You can create one [here]({link})`,
  [`There are no email servers`]: `There are no email servers`,
  [`There are no gateway services configured for the sandbox catalog`]: `There are no gateway services configured for the sandbox catalog`,
  [`There are no lifecycle approvals enabled`]: `There are no lifecycle approvals enabled`,
  [`There are no new changes.`]: `There are no new changes.`,
  [`There are no products to display`]: `There are no products to display`,
  [`There are no products to replace`]: `There are no products to replace`,
  [`There are no products to set migration target`]: `There are no products to set migration target`,
  [`There are no products to supersede`]: `There are no products to supersede`,
  [`There are no properties to configure for this policy`]: `There are no properties to configure for this policy`,
  [`There are no rate limits yet.`]: `There are no rate limits yet.`,
  [`There are no security definitions`]: `There are no security definitions`,
  [`There are no spaces`]: `There are no spaces`,
  [`There are no target services`]: `There are no target services`,
  [`There are no tasks to be displayed`]: `There are no tasks to be displayed`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `There are unsaved changes. Are you sure you want to continue?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `There are unsaved changes. They will be discarded if you continue. Are you sure?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`,
  [`There is no item in the list.`]: `There is no item in the list.`,
  [`There is no space`]: `There is no space`,
  [`There is no warning in the schema.`]: `There is no warning in the schema.`,
  [`There was no data found to populate the data table.`]: `There was no data found to populate the data table.`,
  [`These endpoints are used for all the APIs in the catalog`]: `These endpoints are used for all the APIs in the catalog`,
  [`Third party OAuth provider`]: `Third party OAuth provider`,
  [`Third party OAuth provider summary`]: `Third party OAuth provider summary`,
  [`Third party`]: `Third party`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `This API does not yet contain an assembly. Would you like to create one?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `This resource no longer exists, please refresh the results from the server.`,
  [`Please refresh the results from the server`]: `Please refresh the results from the server`,
  [`Exact match`]: `Exact match`,
  [`Closest match`]: `Closest match`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `This API will be available to be invoked when the following option is enabled.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `This endpoint is used as the default for APIs that do not define a base endpoint`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `This is a technical preview feature which will evolve and continue to be improved in the future.`,
  [`This is the currently configured notification server.`]: `This is the currently configured notification server.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `This is the directory where your files including policies and extensions will be stored`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `This is where APIs will run and policies and extensions can be accessed`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`,
  [`This operation is secured with access code flow OAuth`]: `This operation is secured with access code flow OAuth`,
  [`This operation is secured with application flow OAuth`]: `This operation is secured with application flow OAuth`,
  [`This operation is secured with basic authentication`]: `This operation is secured with basic authentication`,
  [`This operation is secured with implicit flow OAuth`]: `This operation is secured with implicit flow OAuth`,
  [`This operation is secured with password flow OAuth`]: `This operation is secured with password flow OAuth`,
  [`This policy is not available on the selected gateway`]: `This policy is not available on the selected gateway`,
  [`This policy is used by one other operation`]: `This policy is used by one other operation`,
  [`This policy is used by {{count}} other operations`]: `This policy is used by {{count}} other operations`,
  [`This role cannot be edited`]: `This role cannot be edited`,
  [`This role cannot be edited.`]: `This role cannot be edited.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`,
  [`This will enable/disable application lifecycle`]: `This will enable/disable application lifecycle`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`,
  [`This will unset the catalog as the default catalog.`]: `This will unset the catalog as the default catalog.`,
  [`Throw`]: `Throw`,
  [`Time`]: `Time`,
  [`Time Interval`]: `Time Interval`,
  [`Time Limit`]: `Time Limit`,
  [`Time to Live`]: `Time to Live`,
  [`Time to live`]: `Time to live`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Time to wait before a reply back from the endpoint. Default is 60 seconds.`,
  [`Timeout`]: `Timeout`,
  [`To`]: `To`,
  [`to`]: `to`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`,
  [`To add a user as a member of a provider organization`]: `To add a user as a member of a provider organization`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `To add or remove members, use the [identity and access](/iam) service.`,
  [`To add members, use the [identity and access](/iam) service.`]: `To add members, use the [identity and access](/iam) service.`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `To add user as a member, select their username and assign it a set of roles.`,
  [`To assign default gateways, click **Edit**.`]: `To assign default gateways, click **Edit**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `To continue using this software, you must agree to the terms of the software license agreement.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `To continue using API Connect, upgrade to an Enterprise plan.`,
  [`To get started, you must first enable spaces.`]: `To get started, you must first enable spaces.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `To install and run the toolkit, complete the following steps. [Learn more]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `To invite an owner who is not already in your user registry, enter their email address`,
  [`To register a new gateway, visit the **Gateways** page.`]: `To register a new gateway, visit the **Gateways** page.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`,
  [`Toggle`]: `Toggle`,
  [`Toggle application lifecycle?`]: `Toggle application lifecycle?`,
  [`Toggle default catalog?`]: `Toggle default catalog?`,
  [`Toggle info panel between right and bottom`]: `Toggle info panel between right and bottom`,
  [`Toggle profile`]: `Toggle profile`,
  [`Toggle spaces?`]: `Toggle spaces?`,
  [`Toggle task self-approval?`]: `Toggle task self-approval?`,
  [`Token`]: `Token`,
  [`Token endpoint`]: `Token endpoint`,
  [`Token Expiry`]: `Token Expiry`,
  [`Token Management`]: `Token Management`,
  [`Token Output`]: `Token Output`,
  [`Token URL`]: `Token URL`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`,
  [`Token Version`]: `Token Version`,
  [`Token management`]: `Token management`,
  [`Token management type`]: `Token management type`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`,
  [`Token path`]: `Token path`,
  [`Token revocation`]: `Token revocation`,
  [`Token secret`]: `Token secret`,
  [`Token validation`]: `Token validation`,
  [`Tokens`]: `Tokens`,
  [`Token relay`]: `Token relay`,
  [`Toolkit credentials`]: `Toolkit credentials`,
  [`Token Relay`]: `Token Relay`,
  [`Topology`]: `Topology`,
  [`Topology Administrator`]: `Topology Administrator`,
  [`Total time`]: `Total time`,
  [`Transfer Ownership to a New User`]: `Transfer Ownership to a New User`,
  [`Transfer Ownership to an Existing User`]: `Transfer Ownership to an Existing User`,
  [`Transfer ownership`]: `Transfer ownership`,
  [`Transfer ownership to`]: `Transfer ownership to`,
  [`Transfer ownership to a new user`]: `Transfer ownership to a new user`,
  [`Transfer ownership to an existing user`]: `Transfer ownership to an existing user`,
  [`Transforms`]: `Transforms`,
  [`Truststore Certificates`]: `Truststore Certificates`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Truststore certificates are public certifcates issued by a certifcate authority.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`,
  [`Try again or upload a schema`]: `Try again or upload a schema`,
  [`Try refreshing the page or contacting support.`]: `Try refreshing the page or contacting support.`,
  [`Turkish`]: `Turkish`,
  [`Tutorials`]: `Tutorials`,
  [`Types`]: `Types`,
  [`Type of user`]: `Type of user`,
  [`Type or select a catalog`]: `Type or select a catalog`,
  [`Type to add organizations`]: `Type to add organizations`,
  [`TypeError: Failed to fetch`]: `TypeError: Failed to fetch`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `URL of management endpoint`,
  [`URLs for all operations in the API begin with this value.`]: `URLs for all operations in the API begin with this value.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`,
  [`User Defined`]: `User Defined`,
  [`Userinfo POST`]: `Userinfo POST`,
  [`USER MANAGED`]: `USER MANAGED`,
  [`USERNAME`]: `USERNAME`,
  [`User can delete the keystore history.`]: `User can delete the keystore history.`,
  [`Unable to parse API definition: {msg}`]: `Unable to parse API definition: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Unable to proceed because of errors found when removing types and/or fields.`,
  [`Unarchive`]: `Unarchive`,
  [`Unassociate`]: `Unassociate`,
  [`Unassociate analytics service`]: `Unassociate analytics service`,
  [`Unauthorized`]: `Unauthorized`,
  [`Unauthorized API Request`]: `Unauthorized API Request`,
  [`Unbound lists`]: `Unbound lists`,
  [`Unit`]: `Unit`,
  [`Union`]: `Union`,
  [`Union type`]: `Union type`,
  [`Unique email address`]: `Unique email address`,
  [`Unlimited`]: `Unlimited`,
  [`Unnecessary assumed size`]: `Unnecessary assumed size`,
  [`Unnecessary slicing arguments`]: `Unnecessary slicing arguments`,
  [`Unrecognized Schema Element(s) Encountered`]: `Unrecognized Schema Element(s) Encountered`,
  [`Unsupported media type ({type})`]: `Unsupported media type ({type})`,
  [`Untitled`]: `Untitled`,
  [`Update`]: `Update`,
  [`Update gateway services`]: `Update gateway services`,
  [`Update assembly`]: `Update assembly`,
  [`Update group failed.`]: `Update group failed.`,
  [`Update schema defaults giving preference to`]: `Update schema defaults giving preference to`,
  [`Update the sender details that are displayed on invitation emails`]: `Update the sender details that are displayed on invitation emails`,
  [`Update the summary details for this role`]: `Update the summary details for this role`,
  [`Updating Draft API`]: `Updating Draft API`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `Updating schema defaults will override your changes. Are you sure you want to continue?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `Updating API of type {existingType} with API of type {type} is not allowed.`,
  [`Upload`]: `Upload`,
  [`Upload a schema definition language file to replace your schema.`]: `Upload a schema definition language file to replace your schema.`,
  [`Upload GraphQL schema`]: `Upload GraphQL schema`,
  [`Upload local`]: `Upload local`,
  [`Upload policies`]: `Upload policies`,
  [`Upload policy`]: `Upload policy`,
  [`Upload schema`]: `Upload schema`,
  [`Upload schema definition language (SDL) file`]: `Upload schema definition language (SDL) file`,
  [`Upload truststore certificates`]: `Upload truststore certificates`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Upload the X509 certificate for your application, in PEM format`,
  [`Upload your public certificate`]: `Upload your public certificate`,
  [`Uploaded and validated YAML file`]: `Uploaded and validated YAML file`,
  [`Upload extension`]: `Upload extension`,
  [`Use all compatible gateway services`]: `Use all compatible gateway services`,
  [`Use Content Type`]: `Use Content Type`,
  [`Use DataPower API Gateway`]: `Use DataPower API Gateway`,
  [`Use DataPower Gateway`]: `Use DataPower Gateway`,
  [`Use Dynamic DNS`]: `Use Dynamic DNS`,
  [`Use JSONata to specify the property to redact or remove`]: `Use JSONata to specify the property to redact or remove`,
  [`Use XPath to specify the property to redact or remove.`]: `Use XPath to specify the property to redact or remove.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Use a selected catalog as a default catalog you want to publish to`,
  [`Use an existing JSON or YAML definition of the product`]: `Use an existing JSON or YAML definition of the product`,
  [`Use an existing definition of a REST proxy`]: `Use an existing definition of a REST proxy`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Use an existing definition of a REST proxy or SOAP API`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`,
  [`Use an existing definition of a product`]: `Use an existing definition of a product`,
  [`Use context current payload`]: `Use context current payload`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Use customized catalog endpoint URL (v5 compatibility)`,
  [`Use dynamic DNS`]: `Use dynamic DNS`,
  [`Use endpoint from API`]: `Use endpoint from API`,
  [`Use gateway URLs`]: `Use gateway URLs`,
  [`Use gateway service configured OAuth shared secret key`]: `Use gateway service configured OAuth shared secret key`,
  [`Use group authentication`]: `Use group authentication`,
  [`Use only first array element`]: `Use only first array element`,
  [`Use static DNS`]: `Use static DNS`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Use portal as endpoint for external OIDC provider traffic`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Use the actions menu to manage and publish your APIs.`,
  [`Use the actions menu to manage and publish your products`]: `Use the actions menu to manage and publish your products`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Use the client security policy to extract and authenticate the clients credentials.`,
  [`Use the default built-in Sandbox Catalog`]: `Use the default built-in Sandbox Catalog`,
  [`Use the editor for manual input or upload a file below.`]: `Use the editor for manual input or upload a file below.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Use the enforced extension to specify if the API Connect gateway is used to enforce the API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Use the following Management Endpoint URL when working with the CLI.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Use the following Management Endpoint URL when creating a cloud connection in API Designer.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Use the graphql-introspect policy to introspect a GraphQL schema.`,
  [`Use the host value from Open API Definition`]: `Use the host value from Open API Definition`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Use the if policy to apply a section of the assembly when a condition is fulfilled.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Use the javascript policy to execute a specified JavaScript program.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Use the json-to-xml policy to convert the content of your payload from JSON to XML.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Use the Log policy to customize or override the default activity logging configuration for an API.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Use the oauth policy to generate OAuth tokens.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Use the Operation switch policy to apply a section of the assembly to a specific operation.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Use the user security policy to extract, authenticate, and authorize the user's identity.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Use the xml-to-json policy to convert the content of your payload from XML to JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Use the xslt policy to apply an XSLT transform to the payload.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Used to enable/disable Content-Encoding compression on upload.`,
  [`User`]: `User`,
  [`User group prefix`]: `User group prefix`,
  [`User group suffix`]: `User group suffix`,
  [`User mapping`]: `User mapping`,
  [`User Registries`]: `User Registries`,
  [`User registries`]: `User registries`,
  [`User Registry Name`]: `User Registry Name`,
  [`User registry name`]: `User registry name`,
  [`User Registry cannot be deleted`]: `User Registry cannot be deleted`,
  [`User Registry "{name}" has been deleted`]: `User Registry "{name}" has been deleted`,
  [`User security`]: `User security`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`,
  [`User registries defined for consumer onboarding`]: `User registries defined for consumer onboarding`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`,
  [`User registry`]: `User registry`,
  [`User registry configuration has been updated.`]: `User registry configuration has been updated.`,
  [`User registry has been created`]: `User registry has been created`,
  [`User registry {title} deleted.`]: `User registry {title} deleted.`,
  [`User registry {title} has been created.`]: `User registry {title} has been created.`,
  [`User registry is not visible or not available.`]: `User registry is not visible or not available.`,
  [`User registry {title} has been updated.`]: `User registry {title} has been updated.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`,
  [`UserInfo endpoint`]: `UserInfo endpoint`,
  [`UserInfo URL`]: `UserInfo URL`,
  [`Username`]: `Username`,
  [`Username / Email`]: `Username / Email`,
  [`Username and password fields are required in order to test configuration`]: `Username and password fields are required in order to test configuration`,
  [`Username context variable`]: `Username context variable`,
  [`Username of the new user`]: `Username of the new user`,
  [`Username should not contain special characters!`]: `Username should not contain special characters!`,
  [`Username should not include spaces!`]: `Username should not include spaces!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`,
  [`Using HTTP POST method when contacting Userinfo endpoint.`]: `Using HTTP POST method when contacting Userinfo endpoint.`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Use IBM APIC token expiration setting from the cloud`,
  [`Update API`]: `Update API`,
  [`VALUE`]: `VALUE`,
  [`Version`]: `Version`,
  [`Visible to`]: `Visible to`,
  [`Validate`]: `Validate`,
  [`Validate message body`]: `Validate message body`,
  [`Validate message headers`]: `Validate message headers`,
  [`Validate message fault details`]: `Validate message fault details`,
  [`Validate SOAP 1.1 encoding rule`]: `Validate SOAP 1.1 encoding rule`,
  [`Validate JSON Web Token (JWT)`]: `Validate JSON Web Token (JWT)`,
  [`Validate JWT`]: `Validate JWT`,
  [`Validate Username Token`]: `Validate Username Token`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`,
  [`Validate against`]: `Validate against`,
  [`Validate request`]: `Validate request`,
  [`Validate the GraphQL query and analyze the expected cost`]: `Validate the GraphQL query and analyze the expected cost`,
  [`Validate the returned JSON and analyze the real cost`]: `Validate the returned JSON and analyze the real cost`,
  [`Validating API ...`]: `Validating API ...`,
  [`Validating Draft API`]: `Validating Draft API`,
  [`Validity Period`]: `Validity Period`,
  [`Value`]: `Value`,
  [`Value for located in must be selected.`]: `Value for located in must be selected.`,
  [`Value for the variable to be added.`]: `Value for the variable to be added.`,
  [`Value for type must be selected.`]: `Value for type must be selected.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `Value of "weight" argument in "@cost" directive is < 0.`,
  [`Values`]: `Values`,
  [`Vanity API endpoint`]: `Vanity API endpoint`,
  [`Variable name`]: `Variable name`,
  [`Variable value`]: `Variable value`,
  [`Vendor Extensions`]: `Vendor Extensions`,
  [`Verb`]: `Verb`,
  [`Verify`]: `Verify`,
  [`Verify Access Token`]: `Verify Access Token`,
  [`Verify Crypto JWK variable name`]: `Verify Crypto JWK variable name`,
  [`Verify Crypto Object`]: `Verify Crypto Object`,
  [`Verify Token`]: `Verify Token`,
  [`Verify authorization code`]: `Verify authorization code`,
  [`Verify refresh token`]: `Verify refresh token`,
  [`Version of the LTPA token.`]: `Version of the LTPA token.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`,
  [`View`]: `View`,
  [`View extensions`]: `View extensions`,
  [`View Forum`]: `View Forum`,
  [`View owners`]: `View owners`,
  [`View policies`]: `View policies`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `View amd manage the ciphers available for the selected protocol version.`,
  [`View analytics`]: `View analytics`,
  [`View and edit permissions for the roles in this space`]: `View and edit permissions for the roles in this space`,
  [`View and edit the permissions for the roles in the current organization`]: `View and edit the permissions for the roles in the current organization`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `View and edit the roles contained in the role defaults for consumer organizations`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `View and edit the roles contained in the role defaults for provider organizations`,
  [`View approval history`]: `View approval history`,
  [`View Approval History`]: `View Approval History`,
  [`View documentation and tutorials with step-by-step instructions.`]: `View documentation and tutorials with step-by-step instructions.`,
  [`View error ID`]: `View error ID`,
  [`View status`]: `View status`,
  [`View more`]: `View more`,
  [`View source`]: `View source`,
  [`View subscription`]: `View subscription`,
  [`View template in`]: `View template in`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `View the endpoints for accessing API Connect user interfaces.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `View the endpoints for the admin and provider REST APIs.`,
  [`View the endpoints for the consumer REST APIs.`]: `View the endpoints for the consumer REST APIs.`,
  [`Viewer`]: `Viewer`,
  [`Views the API consumer organization`]: `Views the API consumer organization`,
  [`Views the API provider organization`]: `Views the API provider organization`,
  [`Views the admin organization`]: `Views the admin organization`,
  [`Views the app developer organization`]: `Views the app developer organization`,
  [`Views the catalog`]: `Views the catalog`,
  [`Views the space`]: `Views the space`,
  [`View|permission`]: `View`,
  [`Visibility`]: `Visibility`,
  [`Visibility options`]: `Visibility options`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware docs`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL URL`,
  [`WSDL has been successfully validated`]: `WSDL has been successfully validated`,
  [`WSDL to REST`]: `WSDL to REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `WS-I Basic Profile validation`,
  [`Warn`]: `Warn`,
  [`Warning`]: `Warning`,
  [`Warning:`]: `Warning:`,
  [`Warnings`]: `Warnings`,
  [`We just emailed recipients a test message:`]: `We just emailed recipients a test message:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `We're moving it to the "Test" tab, where you'll be able to test and debug your API.`,
  [`Weather Product`]: `Weather Product`,
  [`Web Endpoint`]: `Web Endpoint`,
  [`Web service operations`]: `Web service operations`,
  [`weight`]: `Weight`,
  [`Weight`]: `Weight`,
  [`Welcome to API Connect Administration`]: `Welcome to API Connect Administration`,
  [`Welcome to API Connect Reserved`]: `Welcome to API Connect Reserved`,
  [`Welcome to API Designer`]: `Welcome to API Designer`,
  [`Welcome to API Manager`]: `Welcome to API Manager`,
  [`Welcome to Cloud Manager`]: `Welcome to Cloud Manager`,
  [`Welcome to the API Designer`]: `Welcome to the API Designer`,
  [`Welcome to the API Manager`]: `Welcome to the API Manager`,
  [`Welcome to the Cloud Manager`]: `Welcome to the Cloud Manager`,
  [`What are you looking for today?`]: `What are you looking for today?`,
  [`What's new`]: `What's new`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `When spaces are enabled, existing products in the catalog will be moved to a default space.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `When you enable token introspection, access token can be examined through the introspection path.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`,
  [`Where did the roles go?`]: `Where did the roles go?`,
  [`Where to find the named rate limit.`]: `Where to find the named rate limit.`,
  [`Where to put the decrypted result`]: `Where to put the decrypted result`,
  [`Where to put the encrypted result`]: `Where to put the encrypted result`,
  [`Whether to reuse the connection to the target server.`]: `Whether to reuse the connection to the target server.`,
  [`Whole subtree`]: `Whole subtree`,
  [`Windows live`]: `Windows live`,
  [`With`]: `With`,
  [`Wildcards ignore xsi:type rule`]: `Wildcards ignore xsi:type rule`,
  [`Work offline`]: `Work offline`,
  [`Work with existing definitions, models and/or data sources`]: `Work with existing definitions, models and/or data sources`,
  [`Working...`]: `Working...`,
  [`Wrapper Policies`]: `Wrapper Policies`,
  [`Wrong slicing arguments`]: `Wrong slicing arguments`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`,
  [`XML Schema`]: `XML Schema`,
  [`XML Schema URL`]: `XML Schema URL`,
  [`XML Schema preview`]: `XML Schema preview`,
  [`XML Validation Mode`]: `XML Validation Mode`,
  [`XML schema is not valid. Please correct the error.`]: `XML schema is not valid. Please correct the error.`,
  [`XML to JSON`]: `XML to JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT version`,
  [`YAML has been successfully validated`]: `YAML has been successfully validated`,
  [`Yes`]: `Yes`,
  [`Yes, enable it`]: `Yes, enable it`,
  [`Yes, reset`]: `Yes, reset`,
  [`Yes. Update API Assembly`]: `Yes. Update API Assembly`,
  [`You are authenticated!`]: `You are authenticated!`,
  [`You do not have permission to use the Test Application`]: `You do not have permission to use the Test Application`,
  [`You can also copy the link and send it directly to the user.`]: `You can also copy the link and send it directly to the user.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `You can input a catalog name or title. The catalog name will take precedence if both are available.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`,
  [`You can send an email to a provider organization owner.`]: `You can send an email to a provider organization owner.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `You cannot delete this type because it will remove the root type ('Query').`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `You cannot select an API whose gateway type does not match the gateway type of this Product.`,
  [`You currently don’t have any gateway extensions`]: `You currently don’t have any gateway extensions`,
  [`You currently don’t have any plans to migrate`]: `You currently don’t have any plans to migrate`,
  [`You currently don't have any policy.`]: `You currently don't have any policy.`,
  [`You do not have permission to any spaces.`]: `You do not have permission to any spaces.`,
  [`You do not have permission to publish products to any catalog or space.`]: `You do not have permission to publish products to any catalog or space.`,
  [`You do not have permission to stage products to any catalog or space.`]: `You do not have permission to stage products to any catalog or space.`,
  [`You currently don't have any billings.`]: `You currently don't have any billings.`,
  [`You don’t currently have any billing integrations.`]: `You don’t currently have any billing integrations.`,
  [`You don't currently have any response.`]: `You don't currently have any response.`,
  [`You don't currently have any user-defined policies.`]: `You don't currently have any user-defined policies.`,
  [`You don't have permission to access this page.`]: `You don't have permission to access this page.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `You have modified this certificate. Saving this application will update its configuration.`,
  [`You haven't added any APIs or Products`]: `You haven't added any APIs or Products`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `You manage catalog membership by adding new users to the catalog and assigning roles to the users.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `You manage space membership by adding new users to the Space and assigning roles to the users.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `You manage space membership by adding new users to the space and assigning roles to the users.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`,
  [`You must choose a definition`]: `You must choose a definition`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`,
  [`You must enable spaces`]: `You must enable spaces`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`,
  [`Your API has been created`]: `Your API has been created`,
  [`Your API is live!`]: `Your API is live!`,
  [`Your API is online!`]: `Your API is online!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Your Catalog is being deleted. This may take a while...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Your Consumer organization is being created. This may take a while...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`,
  [`Your Provider organization is being created. This may take a while...`]: `Your Provider organization is being created. This may take a while...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Your Provider organization is being deleted. This may take a while...`,
  [`Your member is being deleted. This may take a while...`]: `Your member is being deleted. This may take a while...`,
  [`Your Space is being deleted. This may take a while...`]: `Your Space is being deleted. This may take a while...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `Your OAuth provider has unsaved changes. Click OK to continue without saving.`,
  [`Your credentials have expired.`]: `Your credentials have expired.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Your invitation has been sent successfully with the following invitation link:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Your product will be saved before proceeding, are you sure?`,
  [`Your trial ends on {date}`]: `Your trial ends on {date}`,
  [`Your trial expired on {date}.`]: `Your trial expired on {date}.`,
  [`Zoom in`]: `Zoom in`,
  [`Zoom in and out`]: `Zoom in and out`,
  [`Zoom out`]: `Zoom out`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `a lack of CORS support on the target server(see suggestion below)`,
  [`a LDAP provider`]: `a LDAP provider`,
  [`activity`]: `activity`,
  [`add item...`]: `add item...`,
  [`add property...`]: `add property...`,
  [`added`]: `added`,
  [`admin`]: `admin`,
  [`administrator`]: `administrator`,
  [`all`]: `all`,
  [`an API Connect Local User Registry`]: `an API Connect Local User Registry`,
  [`an authentication URL`]: `an authentication URL`,
  [`an untrusted certificate has been encountered`]: `an untrusted certificate has been encountered`,
  [`and`]: `and`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Application:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `approval`,
  [`approval task for product`]: `approval task for product`,
  [`archived`]: `archived`,
  [`authenticated`]: `authenticated`,
  [`authorization code time to live (seconds)`]: `authorization code time to live (seconds)`,
  [`Authorization header pass through`]: `Authorization header pass through`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `blocking`,
  [`body`]: `body`,
  [`body-param`]: `body-param`,
  [`cancel`]: `cancel`,
  [`case`]: `case`,
  [`catalog`]: `Catalog`,
  [`catalog:`]: `Catalog:`,
  [`catch`]: `catch`,
  [`changed`]: `changed`,
  [`cipher`]: `cipher`,
  [`code id_token`]: `code id_token`,
  [`code id_token token`]: `code id_token token`,
  [`code token`]: `code token`,
  [`Consume`]: `Consume`,
  [`consumer`]: `consumer`,
  [`consumes`]: `consumes`,
  [`composite`]: `composite`,
  [`created`]: `created`,
  [`Created API`]: `Created API`,
  [`custom string`]: `custom string`,
  [`day`]: `day`,
  [`day(s)`]: `day(s)`,
  [`default`]: `default`,
  [`default value`]: `default value`,
  [`definition`]: `definition`,
  [`deprecated`]: `deprecated`,
  [`description`]: `description`,
  [`development`]: `development`,
  [`edit condition`]: `edit condition`,
  [`edited`]: `edited`,
  [`environment`]: `Catalog`,
  [`error`]: `error`,
  [`false`]: `false`,
  [`file`]: `file`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `font-awesome 4.5.0 icons`,
  [`for`]: `for`,
  [`header`]: `header`,
  [`hear`]: `hear`,
  [`hello`]: `Hello`,
  [`helloName`]: `Hello {name}!`,
  [`here`]: `here`,
  [`hit`]: `hit`,
  [`hits`]: `hits`,
  [`hour`]: `hour`,
  [`hour(s)`]: `hour(s)`,
  [`hours`]: `hours`,
  [`identified`]: `identified`,
  [`Increased`]: `Increased`,
  [`Increment`]: `Increment`,
  [`info`]: `info`,
  [`information about external documentation`]: `information about external documentation`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting.`]: `Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting.`,
  [`introspect URL`]: `introspect URL`,
  [`Issue`]: `Issue`,
  [`Issues`]: `Issues`,
  [`item`]: `item`,
  [`iterate over`]: `iterate over`,
  [`last modified`]: `last modified`,
  [`Log Level`]: `Log Level`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `logs`,
  [`Logout`]: `Logout`,
  [`Logout redirect`]: `Logout redirect`,
  [`Logout redirect URL`]: `Logout redirect URL`,
  [`location`]: `location`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `minute`,
  [`minute(s)`]: `minute(s)`,
  [`minutes`]: `minutes`,
  [`mutation type`]: `mutation type`,
  [`mutual SSL authentication is required`]: `mutual SSL authentication is required`,
  [`name`]: `Name`,
  [`Name|extension`]: `Name`,
  [`Name|path parameters`]: `Name`,
  [`Name|security definitions`]: `Name`,
  [`non-blocking`]: `non-blocking`,
  [`none`]: `none`,
  [`none selected`]: `none selected`,
  [`Object`]: `Object`,
  [`off`]: `off`,
  [`of {total} pages`]: `of {total} pages`,
  [`of {total} page`]: `of {total} page`,
  [`offline`]: `offline`,
  [`on`]: `on`,
  [`online`]: `online`,
  [`or`]: `or`,
  [`or redact from all`]: `or redact from all`,
  [`open and close list of options`]: `open and close list of options`,
  [`organization saved`]: `organization saved`,
  [`organization-manager`]: `organization-manager`,
  [`otherwise`]: `otherwise`,
  [`payload`]: `payload`,
  [`Payload`]: `Payload`,
  [`pending`]: `pending`,
  [`produces`]: `produces`,
  [`production`]: `production`,
  [`production and development`]: `production and development`,
  [`property`]: `property`,
  [`provider`]: `provider`,
  [`publish product`]: `publish product`,
  [`published`]: `published`,
  [`query type`]: `query type`,
  [`query-parameters`]: `query-parameters`,
  [`realized`]: `realized`,
  [`recipients`]: `recipients`,
  [`removed`]: `removed`,
  [`replaced`]: `replaced`,
  [`repeatable`]: `repeatable`,
  [`Replenish`]: `Replenish`,
  [`request`]: `request`,
  [`requested by`]: `requested by`,
  [`Resolver weight`]: `Resolver weight`,
  [`response`]: `response`,
  [`response-param`]: `response-param`,
  [`retired`]: `retired`,
  [`sample_scope_1`]: `sample_scope_1`,
  [`saved`]: `saved`,
  [`scalar`]: `scalar`,
  [`second`]: `second`,
  [`second(s)`]: `second(s)`,
  [`seconds`]: `seconds`,
  [`select owner user`]: `select owner user`,
  [`select user`]: `select user`,
  [`soap-body`]: `soap-body`,
  [`source`]: `source`,
  [`space`]: `space`,
  [`Space settings`]: `Space settings`,
  [`Skip`]: `Skip`,
  [`specified`]: `specified`,
  [`staged`]: `staged`,
  [`start generating tests automatically.`]: `start generating tests automatically.`,
  [`subject`]: `subject`,
  [`subscription type`]: `subscription type`,
  [`supersede`]: `supersede`,
  [`superseded`]: `superseded`,
  [`target`]: `target`,
  [`the server is unavailable`]: `the server is unavailable`,
  [`this user registry type`]: `this user registry type`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `This is a temporary publish page where doing re-publish preserves subscriptions.`,
  [`to production`]: `to production`,
  [`topology-administrator`]: `topology-administrator`,
  [`Trial days left`]: `Trial days left`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `ttl has been changed`,
  [`type`]: `type`,
  [`unarchived`]: `unarchived`,
  [`union type`]: `union type`,
  [`unlimited`]: `unlimited`,
  [`updated`]: `updated`,
  [`Updated from new schema`]: `Updated from new schema`,
  [`Upgrade`]: `Upgrade`,
  [`v5 legacy behavior`]: `v5 legacy behavior`,
  [`version 2`]: `version 2`,
  [`version 3`]: `version 3`,
  [`viewer`]: `viewer`,
  [`value`]: `value`,
  [`warn`]: `warn`,
  [`warning`]: `warning`,
  [`warnings`]: `warnings`,
  [`was not found. Please select a configured OAuth provider.`]: `was not found. Please select a configured OAuth provider.`,
  [`week`]: `week`,
  [`with`]: `with`,
  [`with|supersede with`]: `with`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `status in sandbox`,
  [`your file here, or`]: `your file here, or`,
  [`{count} found!`]: `{count} found!`,
  [`{current} of {total} page`]: `{current} of {total} page`,
  [`{current} of {total} pages`]: `{current} of {total} pages`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} day(s), {hours} hour(s), and {minutes} minute(s)`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} day(s) and {hours} hour(s)`,
  [`{docType} is required`]: `{docType} is required`,
  [`{docType} title`]: `{docType} title`,
  [`Product title`]: `Product title`,
  [`Application title`]: `Application title`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} hour(s) and {minutes} minute(s)`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`,
  [`{min}-{max} of {total} items`]: `{min}-{max} of {total} items`,
  [`{min}-{max} of {total} item`]: `{min}-{max} of {total} item`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} of {total} Policies`,
  [`{min}-{max} of {total} Policy`]: `{min}-{max} of {total} Policy`,
  [`{min}-{max} of {total} types`]: `{min}-{max} of {total} types`,
  [`{min}-{max} of {total} type`]: `{min}-{max} of {total} type`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} of {total} search results`,
  [`{min}-{max} of {total} search result`]: `{min}-{max} of {total} search result`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} of {total} Catalogs`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{max} of {total} Catalog`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} of {total} Spaces`,
  [`{min}-{max} of {total} Space`]: `{min}-{max} of {total} Space`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} of {total} Extensions`,
  [`{min}-{max} of {total} Extension`]: `{min}-{max} of {total} Extension`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} of {total} Product Plans`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{max} of {total} Product Plan`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} of {total} Gateways`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{max} of {total} Gateway`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `{name} (API) is missing 'x-ibm-configuration'`,
  [`{name} (Product) has been published!`]: `{name} (Product) has been published!`,
  [`{name} (Product) has been staged!`]: `{name} (Product) has been staged!`,
  [`{name} (Product) has not been published!`]: `{name} (Product) has not been published!`,
  [`{name} (Role) has been created`]: `{name} (Role) has been created.`,
  [`{name} successfully deleted`]: `{name} successfully deleted.`,
  [`{name} (Role) has been updated`]: `{name} (Role) has been updated.`,
  [`{name} has been assigned the owner`]: `{name} has been assigned the owner`,
  [`{number} Error`]: `{number} Error`,
  [`{number} Errors`]: `{number} Errors`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `{operation} approval task for product {prodname}:{version} requested by {username}`,
  [`{path} does not exist`]: `{path} does not exist`,
  [`{productName} has no migration target set.`]: `{productName} has no migration target set.`,
  [`{state} Pending`]: `{state} Pending`,
  [`{status} API error`]: `{status} API error`,
  [`{status} Bad Request`]: `{status} Bad Request`,
  [`{status} Forbidden`]: `{status} Forbidden`,
  [`{status} Gateway Error`]: `{status} Gateway Error`,
  [`{status} Unauthorized`]: `{status} Unauthorized`,
  [`({time} after being sent)`]: `({time} after being sent)`,
  [`({time} after being queued)`]: `({time} after being queued)`,
  [`{title} (Product) has been {state}.`]: `{title} (Product) has been {state}.`,
  [`{title} has been deleted`]: `{title} has been deleted`,
  [`{title} has been {changedName}.`]: `{title} has been {changedName}.`,
  [`{title} has not been deleted!`]: `{title} has not been deleted!`,
  [`{title} has not been {changedName}!`]: `{title} has not been {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type} has been {changedName}.`,
  [`{type} has not been {changedName}!`]: `{type} has not been {changedName}!`,
  [`{type} history log`]: `{type} history log`,
  [`{username} does not exist`]: `{username} does not exist`,
  [`{username} does not exist in the user registry`]: `{username} does not exist in the user registry`,
  [`{username} is not associated with this consumer org`]: `{username} is not associated with this consumer org`,
  [`{username} has been created as a {type}.`]: `{username} has been created as a {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `{appName} app reinstated in the {consumerOrg} developer portal`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `{appName} app suspended in the {consumerOrg} developer portal`,
  [`{{catalogTitle}} developer portal account registration`]: `{catalogTitle} developer portal account registration`,
  [`{{count}} errors need attention`]: `{count} errors need attention`,
  // lts only translations
  [`Configure JSON settings`]: `Configure JSON settings`,
  [`Configure XML settings`]: `Configure XML settings`,
  [`Configure options`]: `Configure options`,
  [`Detect`]: `Detect`,
  [`Document type`]: `Document type`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Maximum document size`,
  [`Maximum name length`]: `Maximum name length`,
  [`Maximum nesting depth`]: `Maximum nesting depth`,
  [`Maximum number length`]: `Maximum number length`,
  [`Maximum number of unique names`]: `Maximum number of unique names`,
  [`Maximum number of unique namespaces`]: `Maximum number of unique namespaces`,
  [`Maximum number of unique prefixes`]: `Maximum number of unique prefixes`,
  [`Maximum value length`]: `Maximum value length`,
  [`Maximum width`]: `Maximum width`,
  [`Maximum stack size`]: `Maximum stack size`,
  [`Parse settings`]: `Parse settings`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Specifies the maximum document size in bytes that the parse action accepts.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Specifies the maximum name length in bytes that the parse action accepts.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Specifies the maximum number of unique names that the parse action accepts.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Specify the maximum value length in bytes that the parse action accepts.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Specifies the maximum width of a payload that the parse action accepts.`,
  [`Specifies the type of document to parse.`]: `Specifies the type of document to parse.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Specifies the XSLT processor version. The default value is XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.,`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Specifies the validation behavior for the soap:Body. The default setting is Strict.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Specifies the validation behavior for the soap:Header. The default setting is Lax.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Specifies the validation behavior for the fault detail. The default setting is Strict.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`,
  [`API Connect Home`]: `API Connect Home`,
  [`Add new group`]: `Add new group`,
  [`Add consumer organizations to the group as required`]: `Add consumer organizations to the group as required`,
  [`Add the following configuration`]: `Add the following configuration`,
  [`Add the following configuration to`]: `Add the following configuration to`,
  [`Additions`]: `Additions`,
  [`Additional GraphQL capabilities`]: `Additional GraphQL capabilities`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Additional settings for graphQL APIs that are included in this plan`,
  [`An organization or a group is required.`]: `An organization or a group is required.`,
  [`An organization/group is required.`]: `An organization/group is required.`,
  [`Analysis configuration`]: `Analysis configuration`,
  [`Application & Credentials`]: `Application & Credentials`,
  [`Are you sure you want to delete this API version?`]: `Are you sure you want to delete this API version?`,
  [`Are you sure you want to delete this API with all versions?`]: `Are you sure you want to delete this API with all versions?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Are you sure you want to remove the gateway extension from the gateway?`,
  [`Are you sure you want to rename all versions of this API?`]: `Are you sure you want to rename all versions of this API?`,
  [`Are you sure you want to rename this API version?`]: `Are you sure you want to rename this API version?`,
  [`Associate groups to the organization as required`]: `Associate groups to the organization as required`,
  [`At least one option must be enabled`]: `At least one option must be enabled`,
  [`Attribute mapping`]: `Attribute mapping`,
  [`Attempt streaming rule`]: `Attempt streaming rule`,
  [`Auto generated default paths and operations`]: `Auto generated default paths and operations`,
  [`Base API endpoint`]: `Base API endpoint`,
  [`Consumer orgs`]: `Consumer orgs`,
  [`Calculate Query Characteristics`]: `Calculate Query Characteristics`,
  [`Calculate Response Characteristics`]: `Calculate Response Characteristics`,
  [`Certificate or Shared Secret for Verify`]: `Certificate or Shared Secret for Verify`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`,
  [`Clone API`]: `Clone API`,
  [`Consumer organizations and groups`]: `Consumer organizations and groups`,
  [`Create new API version`]: `Create new API version`,
  [`Create new product version`]: `Create new product version`,
  [`Create a GraphQL API based on a GraphQL service`]: `Create a GraphQL API based on a GraphQL service`,
  [`Custom rate limit`]: `Custom rate limit`,
  [`Delete API`]: `Delete API`,
  [`Delete this version`]: `Delete this version`,
  [`Deletion confirmation`]: `Deletion confirmation`,
  [`Deletions`]: `Deletions`,
  [`Display name`]: `Display name`,
  [`Display Name`]: `Display Name`,
  [`Details`]: `Details`,
  [`Edit GraphQL Rate Limit|title`]: `Edit GraphQL Rate Limit|title`,
  [`Edit GraphQL rate limits`]: `Edit GraphQL rate limits`,
  [`Edit group`]: `Edit group`,
  [`Edit condition`]: `Edit condition`,
  [`Edit rate limit|title`]: `Edit rate limit`,
  [`Edit rate limit|menu`]: `Edit rate limit`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`,
  [`Enforce Policy`]: `Enforce Policy`,
  [`Enter a group summary`]: `Enter a group summary`,
  [`Enter a group title`]: `Enter a group title`,
  [`Enter group details below`]: `Enter group details below`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Enter the URL and schema name for the GraphQL service you would like to proxy`,
  [`Enum value`]: `Enum value`,
  [`Go to catalog settings to configure a new billing integration`]: `Go to catalog settings to configure a new billing integration`,
  [`GraphQL introspect`]: `GraphQL introspect`,
  [`GraphQL server`]: `GraphQL server`,
  [`GraphQL Setting`]: `GraphQL Setting`,
  [`GraphQL server URL`]: `GraphQL server URL`,
  [`GraphQL server name`]: `GraphQL server name`,
  [`GraphQL type analysis configuration`]: `GraphQL type analysis configuration`,
  [`GraphQL resolver analysis configuration`]: `GraphQL resolver analysis configuration`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Groups can be created and managed in the following list.`,
  [`Groups will be listed here.`]: `Groups will be listed here.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n(mailServer.\n\nIf you received this message as expected, the test was successful!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hello,\n\nUser {originator} has withdrawn their request to subscribe with the application {appName}\nto the {planName} plan for version {productVersion} of the {productName} API product in\nthe {catalog} catalog.\n\nNo further action is required.\n`,
  [`Invoke GraphQL server`]: `Invoke GraphQL server`,
  [`Invoke the backend GraphQL server`]: `Invoke the backend GraphQL server`,
  [`JWKS endpoint`]: `JWKS endpoint`,
  [`LDAP Field Name`]: `LDAP Field Name`,
  [`Limit maximum nesting per query`]: `Limit maximum nesting per query`,
  [`Limit resolve complexity rate on a per key basis`]: `Limit resolve complexity rate on a per key basis`,
  [`Limit type complexity rate on a per key basis`]: `Limit type complexity rate on a per key basis`,
  [`Manage APIs included in this product`]: `Manage APIs included in this product`,
  [`Manage groups`]: `Manage groups`,
  [`Maximum nesting`]: `Maximum nesting`,
  [`Maximum resolve complexity`]: `Maximum resolve complexity`,
  [`Maximum type complexity`]: `Maximum type complexity`,
  [`Name of the group`]: `Name of the group`,
  [`New API version`]: `New API version`,
  [`New GraphQL API`]: `New GraphQL API`,
  [`New product version`]: `New product version`,
  [`New Version`]: `New Version`,
  [`New version`]: `New version`,
  [`No billing integration exists`]: `No billing integration exists`,
  [`No CA bundles available`]: `No CA bundles available`,
  [`No Data`]: `No Data`,
  [`No certificates available`]: `No certificates available`,
  [`No data`]: `No data`,
  [`No group found`]: `No group found`,
  [`OAuth Auth URL`]: `OAuth Auth URL`,
  [`OAuth Token URL`]: `OAuth Token URL`,
  [`Open Stripe dashboard`]: `Open Stripe dashboard`,
  [`Operation/Path`]: `Operation/Path`,
  [`Operation Path`]: `Operation Path`,
  [`Operations and Paths`]: `Operations and Paths`,
  [`Operator`]: `Operator`,
  [`Organizations and groups will be listed here.`]: `Organizations and groups will be listed here.`,
  [`Page not found`]: `Page not found`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Please reference your Stripe dashboard to access the API key and secret`,
  [`Please upload a schema definition language (SDL) file.`]: `Please upload a schema definition language (SDL) file.`,
  [`Please upload a valid GraphQL schema.`]: `Please upload a valid GraphQL schema.`,
  [`Please upload a valid YAML file.`]: `Please upload a valid YAML file.`,
  [`Populate Server Configuration into Context Variables`]: `Populate Server Configuration into Context Variables`,
  [`POST/GET a query to be validated and sent to the backend server`]: `POST/GET a query to be validated and sent to the backend server`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `POST/GET a query to get the estimated cost of invoking that query`,
  [`Preparing`]: `Preparing`,
  [`Rate limit amount cannot be negative`]: `Rate limit amount cannot be negative`,
  [`Rate limit per cannot be negative`]: `Rate limit per cannot be negative`,
  [`Rename API`]: `Rename API`,
  [`Rename`]: `Rename`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Request for approval to {action} an API product in the {catalog} catalog`,
  [`Sandbox Sample Application`]: `Sandbox Sample Application`,
  [`Sandbox sample application credentials`]: `Sandbox sample application credentials`,
  [`sample application credentials`]: `sample application credentials`,
  [`Saving`]: `Saving`,
  [`Search Orgs`]: `Search Orgs`,
  [`Search definitions...`]: `Search definitions...`,
  [`Search errors...`]: `Search errors...`,
  [`Search operations...`]: `Search operations...`,
  [`Search organizations and groups`]: `Search organizations and groups`,
  [`Sorry, This feature is not available in`]: `Sorry, This feature is not available in`,
  [`Stopping`]: `Stopping`,
  [`The user with username {username} already exists in the user registry.`]: `The user with username {username} already exists in the user registry.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `There are API changes that are not running. Republish this API to the Sandbox catalog.`,
  [`There are no plans available for subscription.`]: `There are no plans available for subscription.`,
  [`There are no attributes yet.`]: `There are no attributes yet.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Type to add consumer organizations to the group`,
  [`Type to add groups to the organization`]: `Type to add groups to the organization`,
  [`Type to add organizations from {catalog}`]: `Type to add organizations from {catalog}`,
  [`Type to add organizations or groups`]: `Type to add organizations or groups`,
  [`Type to add organizations or groups from {catalog}`]: `Type to add organizations or groups from {catalog}`,
  [`Type to add {title} to groups`]: `Type to add {title} to groups`,
  [`Update Rate Limits`]: `Update Rate Limits`,
  [`Upload the YAML file with configuration`]: `Upload the YAML file with configuration`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Use the sample below as a template to configure your GraphQL server`,
  [`User managed`]: `User managed`,
  [`URL {url} does not contain valid OpenAPI.`]: `URL {url} does not contain valid OpenAPI.`,
  [`Validating`]: `Validating`,
  [`View analytics and performance metrics for your APIs`]: `View analytics and performance metrics for your APIs`,
  [`View and add custom policies to the catalog`]: `View and add custom policies to the catalog`,
  [`View and add custom exentions to the catalog`]: `View and add custom exentions to the catalog`,
  [`View user registries and OAuth providers`]: `View user registries and OAuth providers`,
  [`View your application and manage API credentials`]: `View your application and manage API credentials`,
  [`Welcome to the API Connect Trial`]: `Welcome to the API Connect Trial`,
  [`You haven't added any APIs`]: `You haven't added any APIs`,
  [`You currently don’t have any {resource}.`]: `You currently don’t have any {resource}.`,
  [`plans to migrate`]: `plans to migrate`,
  [`gateway extensions`]: `gateway extensions`,
  [`application`]: `application`,
  [`application authentication source`]: `application authentication source`,
  [`API or product`]: `API or product`,
  [`base endpoints`]: `base endpoints`,
  [`categories`]: `categories`,
  [`certificates`]: `certificates`,
  [`channels`]: `channels`,
  [`consumer organizations`]: `consumer organizations`,
  [`consumer organization group`]: `consumer organization group`,
  [`consumer organizations or groups`]: `consumer organizations or groups`,
  [`correlation ids`]: `correlation ids`,
  [`cors policy`]: `cors policy`,
  [`definitions`]: `definitions`,
  [`examples`]: `examples`,
  [`gateway services`]: `gateway services`,
  [`groups`]: `groups`,
  [`headers`]: `headers`,
  [`links`]: `links`,
  [`member`]: `member`,
  [`messages`]: `messages`,
  [`message traits`]: `message traits`,
  [`operation traits`]: `operation traits`,
  [`parameter definitions`]: `parameter definitions`,
  [`parameters`]: `parameters`,
  [`path`]: `path`,
  [`properties`]: `properties`,
  [`provider organizations`]: `provider organizations`,
  [`request bodies`]: `request bodies`,
  [`response definitions`]: `response definitions`,
  [`responses`]: `responses`,
  [`role`]: `role`,
  [`scope`]: `scope`,
  [`scopes`]: `scopes`,
  [`schemas`]: `schemas`,
  [`schema definition`]: `schema definition`,
  [`schemes list`]: `schemes list`,
  [`security definitions`]: `security definitions`,
  [`security schemes`]: `security schemes`,
  [`servers`]: `servers`,
  [`services`]: `services`,
  [`tags`]: `tags`,
  [`tags and external documentation`]: `tags and external documentation`,
  [`target services`]: `target services`,
  [`tasks`]: `tasks`,
  [`user registries`]: `user registries`,
  [`HTTP header`]: `HTTP header`,
  [`You currently don’t have any application authentication source.`]: `You currently don’t have any application authentication source.`,
  [`You currently don’t have any API or product.`]: `You currently don’t have any API or product.`,
  [`You currently don’t have any API user registries`]: `You currently don’t have any API user registries`,
  [`You currently don’t have any APIs.`]: `You currently don’t have any APIs.`,
  [`You currently don’t have any TLS client profiles`]: `You currently don’t have any TLS client profiles`,
  [`You currently don’t have any base endpoints.`]: `You currently don’t have any base endpoints.`,
  [`You currently don’t have any catalog properties.`]: `You currently don’t have any catalog properties.`,
  [`You currently don’t have any categories.`]: `You currently don’t have any categories.`,
  [`You currently don’t have any certificates.`]: `You currently don’t have any certificates.`,
  [`You currently don’t have any channels.`]: `You currently don’t have any channels.`,
  [`You currently don’t have any consumer organizations.`]: `You currently don’t have any consumer organizations.`,
  [`You currently don’t have any consumer organizations or groups.`]: `You currently don’t have any consumer organizations or groups.`,
  [`You currently don’t have any consumes.`]: `You currently don’t have any consumes.`,
  [`You currently don’t have any correlation ids.`]: `You currently don’t have any correlation ids.`,
  [`You currently don’t have any cors policy.`]: `You currently don’t have any cors policy.`,
  [`You currently don’t have any definitions.`]: `You currently don’t have any definitions.`,
  [`You currently don’t have any examples.`]: `You currently don’t have any examples.`,
  [`You currently don’t have any gateway services.`]: `You currently don’t have any gateway services.`,
  [`You currently don’t have any groups.`]: `You currently don’t have any groups.`,
  [`You currently don’t have any headers.`]: `You currently don’t have any headers.`,
  [`You currently don’t have any keystore.`]: `You currently don’t have any keystore.`,
  [`You currently don’t have any links.`]: `You currently don’t have any links.`,
  [`You currently don’t have any messages.`]: `You currently don’t have any messages.`,
  [`You currently don’t have any message traits.`]: `You currently don’t have any message traits.`,
  [`You currently don’t have any OAuth providers`]: `You currently don’t have any OAuth providers`,
  [`You currently don’t have any operation traits.`]: `You currently don’t have any operation traits.`,
  [`You currently don’t have any parameter definitions.`]: `You currently don’t have any parameter definitions.`,
  [`You currently don’t have any parameters.`]: `You currently don’t have any parameters.`,
  [`You currently don’t have any preserved request header.`]: `You currently don’t have any preserved request header.`,
  [`You currently don’t have any preserved response header.`]: `You currently don’t have any preserved response header.`,
  [`You currently don’t have any produces.`]: `You currently don’t have any produces.`,
  [`You currently don’t have any properties.`]: `You currently don’t have any properties.`,
  [`You currently don’t have any provider organizations.`]: `You currently don’t have any provider organizations.`,
  [`You currently don’t have any request bodies.`]: `You currently don’t have any request bodies.`,
  [`You currently don’t have any response definitions.`]: `You currently don’t have any response definitions.`,
  [`You currently don’t have any responses.`]: `You currently don’t have any responses.`,
  [`You currently don’t have any scopes.`]: `You currently don’t have any scopes.`,
  [`You currently don’t have any schemas.`]: `You currently don’t have any schemas.`,
  [`You currently don’t have any schemes list.`]: `You currently don’t have any schemes list.`,
  [`You currently don’t have any security definitions.`]: `You currently don’t have any security definitions.`,
  [`You currently don’t have any security schemes.`]: `You currently don’t have any security schemes.`,
  [`You currently don’t have any servers.`]: `You currently don’t have any servers.`,
  [`You currently don’t have any services.`]: `You currently don’t have any services.`,
  [`You currently don’t have any tags.`]: `You currently don’t have any tags.`,
  [`You currently don’t have any tags and external documentation.`]: `You currently don’t have any tags and external documentation.`,
  [`You currently don’t have any target services.`]: `You currently don’t have any target services.`,
  [`You currently don’t have any tasks.`]: `You currently don’t have any tasks.`,
  [`You currently don’t have any truststore.`]: `You currently don’t have any truststore.`,
  [`You currently don’t have any user registries.`]: `You currently don’t have any user registries.`,
  [`You currently don’t have any extensions.`]: `You currently don’t have any extensions.`,
  [`You currently don’t have any HTTP header.`]: `You currently don’t have any HTTP header.`,
  [`Your account is being activated. This may take a while...`]: `Your account is being activated. This may take a while...`,
  [`all of`]: `all of`,
  [`any of`]: `any of`,
  [`calls`]: `calls`,
  [`ciphers`]: `ciphers`,
  [`extensions`]: `extensions`,
  [`one of`]: `one of`,
  [`shared`]: `shared`,
  [`stopping`]: `stopping`,
  [`to:`]: `to:`,
  [`Key`]: `Key`,
  [`kind`]: `kind`,
  [`Trace`]: `Trace`,
  [`Http Status`]: `Http Status`,
  [`Parsed`]: `Parsed`,
  [`Raw`]: `Raw`,
  [`Showing Full Trace`]: `Showing Full Trace`,
  [`Show Entire Trace`]: `Show Entire Trace`,
  [`Show advanced`]: `Show advanced`,
  [`Advanced`]: `Advanced`,
  [`Sending Request`]: `Sending Request`,
  [`Please send a request above`]: `Please send a request above`,
  [`Please send a request above.`]: `Please send a request above.`,
  [`Authentication Type`]: `Authentication Type`,
  [`You don't currently have any response`]: `You don't currently have any response`,
  [`You don't currently have any trace.`]: `You don't currently have any trace.`,
  [`No response received`]: `No response received`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`,
  [`Open link to accept exception`]: `Open link to accept exception`,
  [`Back to {name}`]: `Back to {name}`,
  [`0 seconds`]: `0 seconds`,
  [`close.menu`]: `Close menu`,
  [`open.menu`]: `Open menu`,
  [`Referenced from "{ref}"`]: `Referenced from "{ref}"`,
  [`Go to {definition}`]: `Go to {definition}`,
  [`General`]: `General`,
  [`Servers`]: `Servers`,
  [`External Docs`]: `External Docs`,
  [`Components`]: `Components`,
  [`Schemas`]: `Schemas`,
  [`Responses`]: `Responses`,
  [`Examples`]: `Examples`,
  [`Request Bodies`]: `Request Bodies`,
  [`Security Schemes`]: `Security Schemes`,
  [`Links`]: `Links`,
  [`Callbacks`]: `Callbacks`,
  [`Terms of Service`]: `Terms of Service`,
  [`Contact Name`]: `Contact Name`,
  [`Contact URL`]: `Contact URL`,
  [`Contact Email`]: `Contact Email`,
  [`Contact sales`]: `Contact sales`,
  [`License Name`]: `License Name`,
  [`License URL`]: `License URL`,
  [`Content-Type`]: `Content-Type`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `Accept MTOM/XOP optimized messages`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `token`,
  [`Tag`]: `Tag`,
  [`Request Body`]: `Request Body`,
  [`Security Requirements`]: `Security Requirements`,
  [`Located In`]: `Located In`,
  [`Example Value`]: `Example Value`,
  [`External Value`]: `External Value`,
  [`Server`]: `Server`,
  [`Server Description`]: `Server Description`,
  [`Server Variables`]: `Server Variables`,
  [`Format`]: `Format`,
  [`Pattern`]: `Pattern`,
  [`Multiple Of`]: `Multiple Of`,
  [`Multiple non null slicing arguments`]: `Multiple non-null slicing arguments`,
  [`Missing slicing arguments`]: `Missing slicing arguments`,
  [`Missing sized fields`]: `Missing sized fields`,
  [`Maximum`]: `Maximum`,
  [`Exclusive Maximum`]: `Exclusive Maximum`,
  [`Minimum`]: `Minimum`,
  [`Exclusive Minimum`]: `Exclusive Minimum`,
  [`Max Length`]: `Max Length`,
  [`Min Length`]: `Min Length`,
  [`Max Items`]: `Max Items`,
  [`Min Items`]: `Min Items`,
  [`Unique Items`]: `Unique Items`,
  [`Max Properties`]: `Max Properties`,
  [`Min Properties`]: `Min Properties`,
  [`Required Properties`]: `Required Properties`,
  [`Required Property Name`]: `Required Property Name`,
  [`Enum`]: `Enum`,
  [`Enum Value`]: `Enum Value`,
  [`Nullable`]: `Nullable`,
  [`Read Only`]: `Read Only`,
  [`Write Only`]: `Write Only`,
  [`api`]: `api`,
  [`version`]: `version`,
  [`username`]: `username`,
  [`password`]: `password`,
  [`service`]: `service`,
  [`Allows Empty Value`]: `Allows Empty Value`,
  [`Style`]: `Style`,
  [`Explode`]: `Explode`,
  [`Allow Reserved`]: `Allow Reserved`,
  [`Allow WebSocket Upgrade`]: `Allow WebSocket Upgrade`,
  [`Ref`]: `Ref`,
  [`Operation ID`]: `Operation ID`,
  [`Parameter`]: `Parameter`,
  [`Gateway and portal settings`]: `Gateway and portal settings`,
  [`Targets`]: `Targets`,
  [`Catalog Properties`]: `Catalog Properties`,
  [`catalog properties`]: `catalog properties`,
  [`Attachments`]: `Attachments`,
  [`Activity Log`]: `Activity Log`,
  [`API Type`]: `API Type`,
  [`Phase`]: `Phase`,
  [`Enable CORS`]: `Enable CORS`,
  [`CORS Policy`]: `CORS Policy`,
  [`Allowed Origins`]: `Allowed Origins`,
  [`Allow Credentials`]: `Allow Credentials`,
  [`ID`]: `ID`,
  [`Success Content`]: `Success Content`,
  [`Error Content`]: `Error Content`,
  [`Attachment`]: `Attachment`,
  [`Link`]: `Link`,
  [`Operation Ref`]: `Operation Ref`,
  [`No Security Requirements available`]: `No Security Requirements available`,
  [`Create a security scheme`]: `Create a security scheme`,
  [`Create a Security Requirement`]: `Create a Security Requirement`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Require one of the following Security Requirements. If unchecked, security is optional.`,
  [`Require wrappers on fault details specified by type`]: `Require wrappers on fault details specified by type`,
  [`There are no "Servers"`]: `There are no "Servers"`,
  [`Write`]: `Write`,
  [`Server Variable`]: `Server Variable`,
  [`Security Requirement`]: `Security Requirement`,
  [`Security Scheme Type`]: `Security Scheme Type`,
  [`Security Scheme`]: `Security Scheme`,
  [`OAuth Flows`]: `OAuth Flows`,
  [`Implicit Flow`]: `Implicit Flow`,
  [`Password Flow`]: `Password Flow`,
  [`Client Credentials Flow`]: `Client Credentials Flow`,
  [`Authorization Code Flow`]: `Authorization Code Flow`,
  [`Refresh URL`]: `Refresh URL`,
  [`schema`]: `schema`,
  [`Allow Empty Value`]: `Allow Empty Value`,
  [`Encoding`]: `Encoding`,
  [`This field is required`]: `This field is required`,
  [`This field is required. You have no API User Registries set up for the selected catalog`]: [`This field is required. You have no API User Registries set up for the selected catalog`],
  [`Temporary token`]: `Temporary token`,
  [`Access token`]: `Access token`,
  [`ID token`]: `ID token`,
  [`No Security Schemes available`]: `No Security Schemes available`,
  [`Security Scheme (Key)`]: `Security Scheme (Key)`,
  [`There are no "{label}"`]: `There are no "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `You must select at least one scheme to define a Security Requirement`,
  [`Select one or more Security Schemes`]: `Select one or more Security Schemes`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `No OAuth Scopes available for the "{key}" Security Scheme`,
  [`Create a Scope`]: `Create a Scope`,
  [`Remove this Security Requirement`]: `Remove this Security Requirement`,
  [`Edit this Security Requirement`]: `Edit this Security Requirement`,
  [`Invalid Security Scheme Reference`]: `Invalid Security Scheme Reference`,
  [`The following Security Schemes do not exist!`]: `The following Security Schemes do not exist!`,
  [`Edit source`]: `Edit source`,
  [`OR`]: `OR`,
  [`Required Security Schemes`]: `Required Security Schemes`,
  [`Optional Security`]: `Optional Security`,
  [`Optional custom expression (one per line)`]: `Optional custom expression (one per line)`,
  [`Flow Type`]: `Flow Type`,
  [`OAuth2 Security Scheme`]: `OAuth2 Security Scheme`,
  [`Only source editing is supported.`]: `Only source editing is supported.`,
  [`Unsupported Field.`]: `Unsupported Field.`,
  [`Select an option`]: `Select an option`,
  [`Select {labelText}`]: `Select {labelText}`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" is already used'`,
  [`Referenced from "{originalRef}"`]: `Referenced from "{originalRef}"`,
  [`Go to {label}`]: `Go to {label}`,
  [`Delete "{label}"`]: `Delete "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `Are you sure you want to delete "{label}"?`,
  [`No recent items found`]: `No recent items found`,
  [`Found {numDocs} results ({took} seconds)`]: `Found {numDocs} results ({took} seconds)`,
  [`No Consumer Organizations found`]: `No Consumer Organizations found`,
  [`No Catalog Products found`]: `No Catalog Products found`,
  [`No APIs found`]: `No APIs found`,
  [`No Catalogs found`]: `No Catalogs found`,
  [`No Spaces found`]: `No Spaces found`,
  [`No results found`]: `No results found`,
  [`No Catalog APIs found`]: `No Catalog APIs found`,
  [`No Catalogs found matching query "{query}"`]: `No Catalogs found matching query "{query}"`,
  [`No APIs found matching query "{query}"`]: `No APIs found matching query "{query}"`,
  [`No Subscriptions found`]: `No Subscriptions found`,
  [`No Plans found matching query "{query}"`]: `No Plans found matching query "{query}"`,
  [`No Subscriptions found matching query "{query}"`]: `No Subscriptions found matching query "{query}"`,
  [`No Catalog Products found matching query "{query}"`]: `No Catalog Products found matching query "{query}"`,
  [`No Catalog APIs found matching query "{query}"`]: `No Catalog APIs found matching query "{query}"`,
  [`No Consumer Organizations found matching query "{query}"`]: `No Consumer Organizations found matching query "{query}"`,
  [`No Applications found matching query "{query}"`]: `No Applications found matching query "{query}"`,
  [`APIs per page`]: `APIs per page`,
  [`Catalogs per page`]: `Catalogs per page`,
  [`items per page`]: `items per page`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} of {total} APIs`,
  [`{min}-{max} of {total} API`]: `{min}-{max} of {total} API`,
  [`Products per page`]: `Products per page`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Product should be published on the catalog to be able to configure a new billing integration`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} of {total} Products`,
  [`{min}-{max} of {total} Product`]: `{min}-{max} of {total} Product`,
  [`Catalog Products per page`]: `Catalog Products per page`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} of {total} Catalog Products`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{max} of {total} Catalog Product`,
  [`Plans per page`]: `Plans per page`,
  [`Policies per page`]: `Policies per page`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} of {total} Plans`,
  [`{min}-{max} of {total} Plan`]: `{min}-{max} of {total} Plan`,
  [`Catalog APIs per page`]: `Catalog APIs per page`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} of {total} Catalog APIs`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{max} of {total} Catalog API`,
  [`Subscriptions per page`]: `Subscriptions per page`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} of {total} Subscriptions`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{max} of {total} Subscription`,
  [`Consumer Organizations per page`]: `Consumer Organizations per page`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} of {total} Consumer Organizations`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{max} of {total} Consumer Organization`,
  [`Applications per page`]: `Applications per page`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} of {total} Applications`,
  [`{min}-{max} of {total} Application`]: `{min}-{max} of {total} Application`,
  [`Histories per page`]: `Histories per page`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} of {total} Histories`,
  [`{min}-{max} of {total} History`]: `{min}-{max} of {total} History`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Showing published "{draftApiTitle}:{draftApiVersion}" APIs`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Showing Consumer Organization for "{appTitle}"`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Showing Subscriptions for "{productTitle}:{productVersion}"`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Showing Plans for "{productTitle}:{productVersion}"`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `Showing APIs for "{productTitle}:{productVersion}"`,
  [`Showing Product for "{planTitle}"`]: `Showing Product for "{planTitle}"`,
  [`Showing APIs for "{planTitle}"`]: `Showing APIs for "{planTitle}"`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Showing Plans for "{apiTitle}:{apiVersion}"`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Showing Histories for "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Showing Subscriptions for "{appTitle}"`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Showing Applications for "{consumerOrgTitle}"`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Showing Subscriptions for "{consumerOrgTitle}"`,
  [`Showing Application for selected Subscription`]: `Showing Application for selected Subscription`,
  [`Showing Plan for selected Subscription`]: `Showing Plan for selected Subscription`,
  [`Showing Product for selected Subscription`]: `Showing Product for selected Subscription`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Select Draft APIs to include in the "{productTitle}" Product`,
  [`Product Version`]: `Product Version`,
  [`OAuth Providers`]: `OAuth Providers`,
  [`Application Type`]: `Application Type`,
  [`Client IDs`]: `Client IDs`,
  [`Save as New Version`]: `Save as New Version`,
  [`Update Selected APIs`]: `Update Selected APIs`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`,
  [`Put Online`]: `Put Online`,
  [`Take Offline`]: `Take Offline`,
  [`View Subscriptions`]: `View Subscriptions`,
  [`View Plans`]: `View Plans`,
  [`No Tasks found`]: `No Tasks found`,
  [`Tasks per page`]: `Tasks per page`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} of {total} Tasks`,
  [`{min}-{max} of {total} Task`]: `{min}-{max} of {total} Task`,
  [`Decline and Send`]: `Decline and Send`,
  [`View Applications`]: `View Applications`,
  [`Publish (Preserve Subscriptions)`]: `Publish (Preserve Subscriptions)`,
  [`Update APIs`]: `Update APIs`,
  [`View in Catalogs`]: `View in Catalogs`,
  [`View Consumer Organization`]: `View Consumer Organization`,
  [`View Application`]: `View Application`,
  [`View Plan`]: `View Plan`,
  [`View Product`]: `View Product`,
  [`Create Subscription`]: `Create Subscription`,
  [`No Applications found`]: `No Applications found`,
  [`Select a Plan to Create a Subscription to`]: `Select a Plan to Create a Subscription to`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`,
  [`Save as new version`]: `Save as new version`,
  [`Filter selected catalogs`]: `Filter selected catalogs`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Select draft APIs to include in the "{productTitle}" product`,
  [`Update selected APIs`]: `Update selected APIs`,
  [`Catalog API`]: `Catalog API`,
  [`Catalog APIs`]: `Catalog APIs`,
  [`View Products`]: `View Products`,
  [`View Draft APIs`]: `View Draft APIs`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Showing Products referencing "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `No Plans found`,
  [`View APIs`]: `View APIs`,
  [`Document type tabs`]: `Document type tabs`,
  [`No Products found`]: `No Products found`,
  [`Consumer Organization`]: `Consumer Organization`,
  [`Consumer Organizations`]: `Consumer Organizations`,
  [`Catalog Product`]: `Catalog Product`,
  [`Catalog Products`]: `Catalog Products`,
  [`Search for a Product and Plan`]: `Search for a Product and Plan`,
  [`OpenAPI Version`]: `OpenAPI Version`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (from REST, GraphQL or SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (from Kafka topic)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `Create AsyncAPI (from Kafka topic)`,
  [`An error occurred during file upload`]: `An error occurred during file upload`,
  [`Delete uploaded file`]: `Delete uploaded file`,
  [`Copied!`]: `Copied!`,
  [`Uploading`]: `Uploading`,
  [`Validation icon`]: `Validation icon`,
  [`Validation error`]: `Validation error`,
  [`Uploading image..`]: `Uploading image..`,
  [`Unsupported media type {providedFileType}`]: `Unsupported media type {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Select a supported file {expectedFileTypes} and try again`,
  [`Pasted`]: `Pasted`,
  [`Enter the API summary details.`]: `Enter the API summary details.`,
  [`Define your Kafka cluster connection details.`]: `Define your Kafka cluster connection details.`,
  [`Channel`]: `Channel`,
  [`Describe your Kafka topic and its associated schema.`]: `Describe your Kafka topic and its associated schema.`,
  [`AsyncAPI specification version`]: `AsyncAPI specification version`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `A name that describes your API and helps developers discover it in the Developer Portal.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`,
  [`Description (optional)`]: `Description (optional)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Introduce your API and provide helpful information to developers consuming it.`,
  [`Summary (optional)`]: `Summary (optional)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `A brief summary of the events contained in this API. Maximum 1000 characters.`,
  [`Bootstrap servers`]: `Bootstrap servers`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `A list of the Kafka brokers in your cluster, separated by commas.`,
  [`Secure the API using an API Key and Secret`]: `Secure the API using an API Key and Secret`,
  [`The credential provided should be in SASL-PLAIN format`]: `The credential provided should be in SASL-PLAIN format`,
  [`Publish this API:`]: `Publish this API:`,
  [`Creates a product`]: `Creates a product`,
  [`Associates this API to the product`]: `Associates this API to the product`,
  [`Publish the product to the Sandbox catalog`]: `Publish the product to the Sandbox catalog`,
  [`Associates the product to the test app`]: `Associates the product to the test app`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `A schema in "{fileType}" format has been successfully uploaded and validated.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Server response: "{err}". Click "Back" to return to the API definition page.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Server response: "{err}". Click "Done" to exit the wizard.`,
  [`Topic name`]: `Topic name`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`,
  [`Schema for message body in .avsc format (optional)`]: `Schema for message body in .avsc format (optional)`,
  [`Drag and drop files here, or click to upload`]: `Drag and drop files here, or click to upload`,
  [`About this step`]: `About this step`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`,
  [`API secret`]: `API secret`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Generated AsyncAPI 2.1.0 definition`,
  [`API creating`]: `API creating`,
  [`An error occurred while creating your document`]: `An error occurred while creating your document`,
  [`Publishing your API`]: `Publishing your API`,
  [`Your API has been published`]: `Your API has been published`,
  [`An error occurred while publishing your API`]: `An error occurred while publishing your API`,
  [`Configure security for this API`]: `Configure security for this API`,
  [`Cluster connection security`]: `Cluster connection security`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`,
  [`SASL Username`]: `SASL Username`,
  [`SASL Password`]: `SASL Password`,
  [`Transport CA certificate (.pem file - optional)`]: `Transport CA certificate (.pem file - optional)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`,
  [`Set up security for your API if you want to control who can access it.`]: `Set up security for your API if you want to control who can access it.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Gateways require clients to encrypt their communication with TLS.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`,
  [`Pending backend response`]: `Pending backend response`,
  [`Complete`]: `Complete`,
  [`Rate limiting is only applied to enforced APIs`]: `Rate limiting is only applied to enforced APIs`,
  [`No Plans Available`]: `No Plans Available`,
  [`Modal content is not provided`]: `Modal content is not provided`,
  [`Modal heading is not provided`]: `Modal heading is not provided`,
  [`Edit Rate Limit`]: `Edit Rate Limit`,
  [`External Documentation`]: `External Documentation`,
  [`No Label Available`]: `No Label Available`,
  [`optional`]: `optional`,
  [`Uploading image...`]: `Uploading image...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Attach files by dragging, dropping, selecting or pasting them.`,
  [`Response name`]: `Response name`,
  [`Click Add to add a new {resource}.`]: `Click Add to add a new {resource}.`,
  [`Schema Name (Key)`]: `Schema Name (Key)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Namespace`,
  [`Attribute`]: `Attribute`,
  [`Wrapped`]: `Wrapped`,
  [`Example`]: `Example`,
  [`Example Name (Key)`]: `Example Name (Key)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" is already used`,
  [`Name (Key)`]: `Name (Key)`,
  [`Content Type (Key)`]: `Content Type (Key)`,
  [`Header name`]: `Header name`,
  [`Reference`]: `Reference`,
  [`Security Scheme Name (Key)`]: `Security Scheme Name (Key)`,
  [`Choose a Reference`]: `Choose a Reference`,
  [`Reference from`]: `Reference from`,
  [`Content Type`]: `Content Type`,
  [`array`]: `array`,
  [`boolean`]: `boolean`,
  [`integer`]: `integer`,
  [`number`]: `number`,
  [`object`]: `object`,
  [`string`]: `string`,
  [`query`]: `query`,
  [`cookie`]: `cookie`,
  [`matrix`]: `matrix`,
  [`label`]: `label`,
  [`simple`]: `simple`,
  [`form`]: `form`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth Provider (Name)`,
  [`Authenticate using User Registry (name)`]: `Authenticate using User Registry (name)`,
  [`Bearer Format`]: `Bearer Format`,
  [`Server Variable Name`]: `Server Variable Name`,
  [`Verb (Key)`]: `Verb (Key)`,
  [`Parameter Definitions`]: `Parameter Definitions`,
  [`Path Parameters`]: `Path Parameters`,
  [`Media Type List`]: `Media Type List`,
  [`Medium`]: `Medium`,
  [`Schemes List`]: `Schemes List`,
  [`Small`]: `Small`,
  [`Response Definitions`]: `Response Definitions`,
  [`Terms Of Service`]: `Terms Of Service`,
  [`Url`]: `Url`,
  [`Untitled Parameter`]: `Untitled Parameter`,
  [`Parameter Name`]: `Parameter Name`,
  [`Mime Type`]: `Mime Type`,
  [`Async API`]: `Async API`,
  [`Channels`]: `Channels`,
  [`Operation Traits`]: `Operation Traits`,
  [`Messages`]: `Messages`,
  [`Correlation IDs`]: `Correlation IDs`,
  [`Traits`]: `Traits`,
  [`Message Traits`]: `Message Traits`,
  [`Bindings`]: `Bindings`,
  [`Server Bindings`]: `Server Bindings`,
  [`Channel Bindings`]: `Channel Bindings`,
  [`Operation Bindings`]: `Operation Bindings`,
  [`Message Bindings`]: `Message Bindings`,
  [`Protocol Version`]: `Protocol Version`,
  [`Define multiple messages`]: `Define multiple messages`,
  [`Schema Format`]: `Schema Format`,
  [`Unsupported Field`]: `Unsupported Field`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Operation Trait`,
  [`Message Trait`]: `Message Trait`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Use the Operation Switch policy to apply a section of the assembly to a specific operation.`,
  [`HTML page`]: `HTML page`,
  [`Rule`]: `Rule`,
  [`Origin`]: `Origin`,
  [`Property Name (Key)`]: `Property Name (Key)`,
  [`Catalog Name (Key)`]: `Catalog Name (Key)`,
  [`Property Overrides`]: `Property Overrides`,
  [`Property Name`]: `Property Name`,
  [`Property Value`]: `Property Value`,
  [`About subscriptions and plans`]: `About subscriptions and plans`,
  [`Subscription Plan Name`]: `Subscription Plan Name`,
  [`Select plan`]: `Select plan`,
  [`Select the plan to migrate application subscriptions from`]: `Select the plan to migrate application subscriptions from`,
  [`Select subscriptions`]: `Select subscriptions`,
  [`Select the application subscriptions to migrate`]: `Select the application subscriptions to migrate`,
  [`Select the product to migrate application subscriptions to`]: `Select the product to migrate application subscriptions to`,
  [`Select the plan to migrate application subscriptions to`]: `Select the plan to migrate application subscriptions to`,
  [`Message successfully sent to {sent} owner(s)`]: `Message successfully sent to {sent} owner(s)`,
  [`{failed}/{sent} messages failed to send`]: `{failed}/{sent} messages failed to send`,
  [`Failed email addresses`]: `Failed email addresses`,
  [`Failed to save the API`]: `Failed to save the API`,
  [`and {numExtra} more...`]: `and {numExtra} more...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Copy to clipboard the list of email addresses that failed to send`,
  [`From`]: `From`,
  [`Message Owner`]: `Message Owner`,
  [`Message owner`]: `Message owner`,
  [`Message owners`]: `Message owners`,
  [`Message group`]: `Message group`,
  [`Select consumer organizations`]: `Select consumer organizations`,
  [`Message consumer organization owners`]: `Message consumer organization owners`,
  [`Message consumer organization owner`]: `Message consumer organization owner`,
  [`Message provider organization owner`]: `Message provider organization owner`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `The identifier for the Kafka cluster related to this Invoke policy`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`,
  [`The username to use when connecting to the Kafka cluster.`]: `The username to use when connecting to the Kafka cluster.`,
  [`The password to use when connecting to the Kafka cluster.`]: `The password to use when connecting to the Kafka cluster.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Event Gateway Service`,
  [`Event gateway management client keystore`]: `Event gateway management client keystore`,
  [`Edit Event gateway Service`]: `Edit Event gateway Service`,
  [`Configure Event Gateway Service`]: `Configure Event Gateway Service`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Configure an Event Gateway Service for securing and enforcing asynchronous APIs`,
  [`Catalogs with published product`]: `Catalogs with published product`,
  [`Cluster Config ID`]: `Cluster Config ID`,
  [`SASL mechanism`]: `SASL mechanism`,
  [`Security protocol`]: `Security protocol`,
  [`Transport CA certificate`]: `Transport CA certificate`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Supported file types are json, xml, yml & yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Supported file types are json, wsdl, xml, yml & yaml.`,
  [`Select a file`]: `Select a file`,
  [`or specify a file URL`]: `or specify a file URL`,
  [`Generated OpenAPI {version} definition`]: `Generated OpenAPI {version} definition`,
  [`User registry type`]: `User registry type`,
  [`User registry types`]: `User registry types`,
  [`User registry type {title} has been created.`]: `User registry type {title} has been created.`,
  [`User registry type {title} has been updated.`]: `User registry type {title} has been updated.`,
  [`Failed to create user registry type {title}.`]: `Failed to create user registry type {title}.`,
  [`Failed to update user registry type {title}.`]: `Failed to update user registry type {title}.`,
  [`Failed to update user registry {title}.`]: `Failed to update user registry {title}.`,
  [`Custom user registry`]: `Custom user registry`,
  [`Create custom user registry`]: `Create custom user registry`,
  [`Edit custom user registry`]: `Edit custom user registry`,
  [`Create user registry type`]: `Create user registry type`,
  [`Edit user registry type`]: `Edit user registry type`,
  [`Configure user authentication using a custom external user registry`]: `Configure user authentication using a custom external user registry`,
  [`Secured Endpoint`]: `Secured Endpoint`,
  [`Configuration schema`]: `Configuration schema`,
  [`About custom user registries`]: `About custom user registries`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`,
  [`Update Wsdl`]: `Update Wsdl`,
  [`Update Existing WSDL service`]: `Update Existing WSDL service`,
  [`Format of selected WSDL has been successfully validated`]: `Format of selected WSDL has been successfully validated`,
  [`Select the target WSDL file to update from`]: `Select the target WSDL file to update from`,
  [`Selected WSDL has been successfully uploaded`]: `Selected WSDL has been successfully uploaded`,
  [`Services available in selected API`]: `Services available in selected API`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`,
  [`Unknown`]: `Unknown`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`,
  [`Keep in mind`]: `Keep in mind`,
  [`Only one type of enforced APIs is allowed per product`]: `Only one type of enforced APIs is allowed per product`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`,
  [`Unenforced`]: `Unenforced`,
  [`Create a new product by adding APIs and plans.`]: `Create a new product by adding APIs and plans.`,
  [`Rate limits are only applied to enforced APIs.`]: `Rate limits are only applied to enforced APIs.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`,
  [`Delete plan`]: `Delete plan`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`,
  [`Edit API list`]: `Edit API list`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Enforced API(s) in this product require more than one gateway type.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `Enforced {apiType} in this product require the {gatewayType}.`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API(s)`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API(s)`,
  [`AsyncAPI(s)`]: `AsyncAPI(s)`,
  [`Select the gateway type for this Product`]: `Select the gateway type for this Product`,
  [`Added default plan`]: `Added default plan`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Publish the product to the Sandbox catalog. This associates the product to the test app.`,
  [`cluster`]: `cluster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Message from the rejector`,
  [`Vendor extensions feature disabled`]: `Vendor extensions feature disabled`,
  [`No configured gateways found`]: `No configured gateways found`,
  [`No OpenAPI extensions associated with the configured gateway`]: `No OpenAPI extensions associated with the configured gateway`,
  [`Extension`]: `Extension`,
  [`Select the extensions to add to the API document`]: `Select the extensions to add to the API document`,
  [`(Operations: {operations})`]: `(Operations: {operations})`,
  [`Failed to get application`]: `Failed to get application`,
  [`Failed to get credentials for application`]: `Failed to get credentials for application`,
  [`Failed to get published product`]: `Failed to get published product`,
  [`Failed to get the published API`]: `Failed to get the published API`,
  [`API must be activated to use the Test tool`]: `API must be activated to use the Test tool`,
  [`Import from Asset repository`]: `Import from Asset repository`,
  [`Untitled {componentLabel}`]: `Untitled {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Catalog is incompatibile with the gateway type specified in the API or product`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `Space is incompatibile with the gateway type specified in the API or product`,
  [`Preserve Subscription`]: `Preserve Subscription`,
  [`Publish validation`]: `Publish validation`,
  [`Publish validations`]: `Publish validations`,
  [`Edit publish validations`]: `Edit publish validations`,
  [`The following validations will be performed when publishing a product`]: `The following validations will be performed when publishing a product`,
  [`There are no publish validations enabled`]: `There are no publish validations enabled`,
  [`Publish validations have been updated`]: `Publish validations have been updated`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `Upload OpenAPI Extension`,
  [`Add OpenAPI Extension`]: `Add OpenAPI Extension`,
  [`Gateway Endpoint`]: `Gateway Endpoint`,
  [`API Dashboard`]: `API Dashboard`,
  [`API call data`]: `API call data`,
  [`Product Dashboard`]: `Product Dashboard`,
  [`API Product consumption metrics`]: `API Product consumption metrics`,
  [`Monitoring Latency Dashboard`]: `Monitoring Latency Dashboard`,
  [`Data about the time taken to serve API calls`]: `Data about the time taken to serve API calls`,
  [`Monitoring Status Dashboard`]: `Monitoring Status Dashboard`,
  [`API Error and success information`]: `API Error and success information`,
  [`Usage Dashboard`]: `Usage Dashboard`,
  [`Usage for gateway {gateway}`]: `Usage for gateway {gateway}`,
  [`Latency for gateway {gateway}`]: `Latency for gateway {gateway}`,
  [`Most popular products, APIs and applications`]: `Most popular products, APIs and applications`,
  [`Consumption Dashboard`]: `Consumption Dashboard`,
  [`Total API volume useful for auditing and compliance`]: `Total API volume useful for auditing and compliance`,
  [`Monthly Summary by Status Code`]: `Monthly Summary by Status Code`,
  [`Monthly Summary Data by Status Code`]: `Monthly Summary Data by Status Code`,
  [`Daily Summary by Status Code`]: `Daily Summary by Status Code`,
  [`Daily Summary Data by Status Code`]: `Daily Summary Data by Status Code`,
  [`Response time (ms)`]: `Response time (ms)`,
  [`Datetime`]: `Datetime`,
  [`Transaction ID:`]: `Transaction ID:`,
  [`Other`]: `Other`,
  [`Date time`]: `Date time`,
  [`Total calls`]: `Total calls`,
  [`Date`]: `Date`,
  [`Month`]: `Month`,
  [`Minimum response time`]: `Minimum response time`,
  [`Maximum response time`]: `Maximum response time`,
  [`Average response time`]: `Average response time`,
  [`Total API calls`]: `Total API calls`,
  [`Total APIs`]: `Total APIs`,
  [`APIs called`]: `APIs called`,
  [`Total products`]: `Total products`,
  [`products called`]: `products called`,
  [`Total errors`]: `Total errors`,
  [`Status codes (detailed)`]: `Status codes (detailed)`,
  [`Response time percentiles`]: `Response time percentiles`,
  [`API calls per day`]: `API calls per day`,
  [`Time zone:`]: `Time zone:`,
  [`Time zone`]: `Time zone`,
  [`Toggle axes`]: `Toggle axes`,
  [`Datetime:`]: `Datetime:`,
  [`datetime per 30 seconds`]: `datetime per 30 seconds`,
  [`Datetime (local time)`]: `Datetime (local time)`,
  [`Data usage (bytes)`]: `Data usage (bytes)`,
  [`Time to Serve Request`]: `Time to Serve Request`,
  [`API name`]: `API name`,
  [`Product name`]: `Product name`,
  [`Data usage (bytes received)`]: `Data usage (bytes received)`,
  [`Data usage (bytes sent)`]: `Data usage (bytes sent)`,
  [`Response times (>1s)`]: `Response times (>1s)`,
  [`milliseconds`]: `milliseconds`,
  [`errors`]: `errors`,
  [`Success Rate`]: `Success Rate`,
  [`Errors Data`]: `Errors Data`,
  [`Success Data`]: `Success Data`,
  [`Total consumer organizations`]: `Total consumer organizations`,
  [`making API calls`]: `making API calls`,
  [`Applications per plan`]: `Applications per plan`,
  [`Top products by calls`]: `Top products by calls`,
  [`Top APIs by calls`]: `Top APIs by calls`,
  [`Top client IP addresses by calls`]: `Top client IP addresses by calls`,
  [`Client IP address`]: `Client IP address`,
  [`Top APIs by request size`]: `Top APIs by request size`,
  [`Top APIs by response size`]: `Top APIs by response size`,
  [`Top APIs by response time`]: `Top APIs by response time`,
  [`Top applications by calls`]: `Top applications by calls`,
  [`Dashboards`]: `Dashboards`,
  [`Discover`]: `Discover`,
  [`UTC`]: `UTC`,
  [`Local time`]: `Local time`,
  [`URI`]: `URI`,
  [`Response code`]: `Response code`,
  [`Total time elapsed (ms)`]: `Total time elapsed (ms)`,
  [`Timestamp`]: `Timestamp`,
  [`Method`]: `Method`,
  [`Gateway IP`]: `Gateway IP`,
  [`Gateway Operations Dashboard`]: `Gateway Operations Dashboard`,
  [`Usage and latency information per gateway`]: `Usage and latency information per gateway`,
  [`Transaction ID`]: `Transaction ID`,
  [`Filters`]: `Filters`,
  [`Fields`]: `Fields`,
  [`Clear all`]: `Clear all`,
  [`API Event`]: `API Event`,
  [`API Assembly Policy Latencies`]: `API Assembly Policy Latencies`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`,
  [`Collapse`]: `Collapse`,
  [`Expand`]: `Expand`,
  [`enter name`]: `enter name`,
  [`Enter value`]: `Enter value`,
  [`Time range`]: `Time range`,
  [`Select one of the following time range filters`]: `Select one of the following time range filters`,
  [`Last 1 hour`]: `Last 1 hour`,
  [`Last {number} minutes`]: `Last {number} minutes`,
  [`Last {number} hours`]: `Last {number} hours`,
  [`Last {number} days`]: `Last {number} days`,
  [`Start date`]: `Start date`,
  [`End date`]: `End date`,
  [`Select from the following dropdowns and enter a value`]: `Select from the following dropdowns and enter a value`,
  [`Select one of the following type filters`]: `Select one of the following type filters`,
  [`All events`]: `All events`,
  [`Success only (2xx, 3xx)`]: `Success only (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Errors only (4xx, 5xx)`,
  [`Import/Export`]: `Import/Export`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `About {totalItems} results ({searchTime} seconds) at {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`,
  [`API calls`]: `API calls`,
  [`Export as CSV`]: `Export as CSV`,
  [`Export as JSON`]: `Export as JSON`,
  [`Export as PNG`]: `Export as PNG`,
  [`Export as JPG`]: `Export as JPG`,
  [`Edit filter`]: `Edit filter`,
  [`Edit query`]: `Edit query`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `This is a shared query. Any modifications will be made for all users this query is shared with.`,
  [`This is a shared query. This will delete it for all users.`]: `This is a shared query. This will delete it for all users.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname} has been updated for all users in {org} provider organization`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname} has been updated for all users in the {space} space`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname} has been updated for all users in the {catalog} catalog`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname} has been updated for all users in the cloud manager`,
  [`Stop sharing`]: `Stop sharing`,
  [`Share query`]: `Share query`,
  [`Share filter`]: `Share filter`,
  [`You do not have permissions to delete this filter`]: `You do not have permissions to delete this filter`,
  [`You do not have permissions to delete this query`]: `You do not have permissions to delete this query`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Are you sure you want to share the query {queryTitle} at the {space} space scope?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Are you sure you want to share the query {queryTitle} at the cloud scope?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Are you sure you want to stop sharing the query {queryTitle}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Are you sure you want to delete the query {queryTitle}?`,
  [`At least one query parameter expected`]: `At least one query parameter expected`,
  [`A provided query parameter is incomplete`]: `A provided query parameter is incomplete`,
  [`Could not parse query string`]: `Could not parse query string`,
  [`An unsupported query parameter was provided: {query}`]: `An unsupported query parameter was provided: {query}`,
  [`Invalid timeframe value: {value}`]: `Invalid timeframe value: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `Operator {operator} is not supported for field {field}`,
  [`The value of {field} must be a number`]: `The value of {field} must be a number`,
  [`Delete query`]: `Delete query`,
  [`Delete filter`]: `Delete filter`,
  [`Save query`]: `Save query`,
  [`Save filter`]: `Save filter`,
  [`Save filter as`]: `Save filter as`,
  [`Save query as`]: `Save query as`,
  [`Save as...`]: `Save as...`,
  [`Save as`]: `Save as`,
  [`Paste query string to load the query`]: `Paste query string to load the query`,
  [`Copy or paste filter string to load filters`]: `Copy or paste filter string to load filters`,
  [`Query string`]: `Query string`,
  [`View filter`]: `View filter`,
  [`View filter string`]: `View filter string`,
  [`Date (local time)`]: `Date (local time)`,
  [`Coming soon, a new built in analytics feature`]: `Coming soon, a new built in analytics feature`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`,
  [`Documentation`]: `Documentation`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Learn about what data is available today and how to access it.`,
  [`Next steps`]: `Next steps`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `The selected Secrets Manager service does not manage any certificates.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`,
  [`Create a [Secrets Manager]({link}) service`]: `Create a [Secrets Manager]({link}) service`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Import the required certificates and CA bundles into the Secrets Manager service.`,
  [`No Secrets Manager services available`]: `No Secrets Manager services available`,
  [`Select Secrets Manager service`]: `Select Secrets Manager service`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`,
  [`Access s3 data`]: `Access s3 data`,
  [`Create resource`]: `Create resource`,
  [`Connect analytics data in s3 bucket with AWS`]: `Connect analytics data in s3 bucket with AWS`,
  [`AWS account`]: `AWS account`,
  [`AWS account number`]: `AWS account number`,
  [`Enter your 12 digit aws account id`]: `Enter your 12 digit aws account id`,
  [`Account id must be 12 digits`]: `Account id must be 12 digits`,
  [`Failed to update AWS account id.`]: `Failed to update AWS account id.`,
  [`You have limited access to the catalogs.`]: `You have limited access to the catalogs.`,
  [`You have limited access to the spaces.`]: `You have limited access to the spaces.`,
  [`Contact admin to get more access.`]: `Contact admin to get more access.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `You can't select this catalog. Please contact an admin if you require access.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `You can't select this space. Please contact an admin if you require access.`,
  [`You have limited access to the product billing.`]: `You have limited access to the product billing.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`,
  [`An organization or a group is missing in the catalog chosen.`]: `An organization or a group is missing in the catalog chosen.`,
  [`Secrets Manager instance`]: `Secrets Manager instance`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Consumer organizations with owners that are missing an email cannot be selected`,
  [`You currently don’t have any data.`]: `You currently don’t have any data.`,
  [`You currently don’t have any organizations.`]: `You currently don’t have any organizations.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Request to {operation} product: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Request to upgrade application {appname} to production`,
  [`Onboarding request for {email}`]: `Onboarding request for {email}`,
  [`Product lifecycle request`]: `Product lifecycle request`,
  [`Subscription request`]: `Subscription request`,
  [`Application lifecycle request`]: `Application lifecycle request`,
  [`Consumer Onboarding request`]: `Consumer Onboarding request`,
  [`Originator`]: `Originator`,
  [`Edit analytics service advanced settings`]: `Edit analytics service advanced settings`,
  [`Advanced settings`]: `Advanced settings`,
  [`Must be a positive integer`]: `Must be a positive integer`,
  [`Maximum value is {maxValue}`]: `Maximum value is {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `Maximum value is {maxValueDays}d or {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Configure advanced settings for the analytics deployment.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`,
  [`Rollover settings`]: `Rollover settings`,
  [`Retention settings`]: `Retention settings`,
  [`Delete an index when it meets the following condition:`]: `Delete an index when it meets the following condition:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Rollover the write alias to a new index when the managed index meets one of the following conditions:`,
  [`Minimum document count`]: `Minimum document count`,
  [`The minimum number of documents required to roll over the index.`]: `The minimum number of documents required to roll over the index.`,
  [`Minimum index age`]: `Minimum index age`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`,
  [`Time range:`]: `Time range:`,
  [`Time range: Before {datetime}`]: `Time range: Before {datetime}`,
  [`Time range: After {datetime}`]: `Time range: After {datetime}`,
  [`Filter before`]: `Filter before`,
  [`Filter after`]: `Filter after`,
  [`Filter exact`]: `Filter exact`,
  [`Filter gt`]: `Filter gt`,
  [`Filter lt`]: `Filter lt`,
  [`Filter equals`]: `Filter equals`,
  [`Filter by`]: `Filter by`,
  [`Filter out`]: `Filter out`,
  [`Delete failed`]: `Delete failed`,
  [`Delete warning`]: `Delete warning`,
  [`Could not delete saved query {queryTitle}.`]: `Could not delete saved query {queryTitle}.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `Request responded successfully but {queryTitle} has not finished deleting yet.`,
  [`API governance`]: `API governance`,
  [`Something's wrong`]: `Something's wrong`,
  [`We were unable to connect to the service.`]: `We were unable to connect to the service.`,
  [`Invalid date`]: `Invalid date`,
  [`Invalid time`]: `Invalid time`,
  [`End date must be after the start date`]: `End date must be after the start date`,
  [`Duplicate`]: `Duplicate`,
  [`Duplicate query`]: `Duplicate query`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Are you sure you want to make a duplicate of {queryTitle}?`,
  [`No queries saved`]: `No queries saved`,
  [`No queries shared`]: `No queries shared`,
  [`To save a query, start by creating one then save.`]: `To save a query, start by creating one then save.`,
  [`Share a saved query and allow others to use it.`]: `Share a saved query and allow others to use it.`,
  [`Query updated`]: `Query updated`,
  [`Query update failed`]: `Query update failed`,
  [`Share successful`]: `Share successful`,
  [`Share failed`]: `Share failed`,
  [`Delete successful`]: `Delete successful`,
  [`Query unshared`]: `Query unshared`,
  [`Unshare {queryTitle} failed`]: `Unshare {queryTitle} failed`,
  [`Query copied`]: `Query copied`,
  [`Duplicate {queryTitle} failed`]: `Duplicate {queryTitle} failed`,
  [`The query {queryTitle} has been updated.`]: `The query {queryTitle} has been updated.`,
  [`The query {queryTitle} has been shared.`]: `The query {queryTitle} has been shared.`,
  [`The query {queryTitle} has been deleted.`]: `The query {queryTitle} has been deleted.`,
  [`The query {queryTitle} is no longer shared.`]: `The query {queryTitle} is no longer shared.`,
  [`A copy of {queryTitle} has been created.`]: `A copy of {queryTitle} has been created.`,
  [`About queries`]: `About queries`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`,
  [`There are then different operators available depending on the field type.`]: `There are then different operators available depending on the field type.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `String fields allow equals, not, starts with, ends with, regex.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`,
  [`n/a`]: `n/a`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`,
  [`No authentication token`]: `No authentication token`,
  [`Contact your administrator for further assistance.`]: `Contact your administrator for further assistance.`,
  [`Limit`]: `Limit`,
  [`The name of one or more rate limit definitions`]: `The name of one or more rate limit definitions`,
  [`Rate Limit name`]: `Rate Limit name`,
  [`Rate Limit Operation`]: `Rate Limit Operation`,
  [`Check`]: `Check`,
  [`Add Limit`]: `Add Limit`,
  [`Remove Limit`]: `Remove Limit`,
  [`The operation to apply: consume to indicate increased usage, replenish to indicate decreased usage, check to determine if a limit has been exceeded, or update to indicate increased usage without throwing an exception.`]: `The operation to apply: consume to indicate increased usage, replenish to indicate decreased usage, check to determine if a limit has been exceeded, or update to indicate increased usage without throwing an exception.`,
  [`Domain by Name`]: `Domain by Name`,
  [`The name of the group containing the limits to apply`]: `The name of the group containing the limits to apply`,
  [`Group Operation`]: `Group Operation`,
  [`The operation to apply: consume/replenish to remove/add tokens from the bucket for rate limits. The consume property will be cofigured as increment for definitions configured as count limits. The replenish property will be cofigured as decrement for definitions configured as count limits.`]: `The operation to apply: consume/replenish to remove/add tokens from the bucket for rate limits. The consume property will be cofigured as increment for definitions configured as count limits. The replenish property will be cofigured as decrement for definitions configured as count limits.`,
  [`The source of the limits. Use plan-default to apply the limits defined by the plan. Use catalog-named to apply the limits from the catalog that are named in the Burst Limit, Rate Limit, and Count Limit fields. Use plan-named to apply the limits from the plan that are named in the Burst Limit, Rate Limit, and Count Limit fields. Use gateway-named to apply the limits from the API Gateway that are named in the Burst Limit, Rate Limit, and Count Limit fields.`]: `The source of the limits. Use plan-default to apply the limits defined by the plan. Use catalog-named to apply the limits from the catalog that are named in the Burst Limit, Rate Limit, and Count Limit fields. Use plan-named to apply the limits from the plan that are named in the Burst Limit, Rate Limit, and Count Limit fields. Use gateway-named to apply the limits from the API Gateway that are named in the Burst Limit, Rate Limit, and Count Limit fields.`,
  [`The name of one or more burst limits defined in the catalog`]: `The name of one or more burst limits defined in the catalog`,
  [`The name of the burst limit to apply`]: `The name of the burst limit to apply`,
  [`Burst Limit operation`]: `Burst Limit operation`,
  [`The operation to apply: consume to remove tokens from the bucket.`]: `The operation to apply: consume to remove tokens from the bucket.`,
  [`The name of one or more rate limits defined in the catalog`]: `The name of one or more rate limits defined in the catalog`,
  [`The name of the rate limit to apply.`]: `The name of the rate limit to apply.`,
  [`Rate Limit operation`]: `Rate Limit operation`,
  [`The operation to apply: consume to remove tokens from the bucket, or replenish to add tokens to the bucket.`]: `The operation to apply: consume to remove tokens from the bucket, or replenish to add tokens to the bucket.`,
  [`The name of the count limit to apply.`]: `The name of the count limit to apply.`,
  [`Count Limit operation`]: `Count Limit operation`,
  [`The operation to apply: inc to increment the count, or dec to decrement the count.`]: `The operation to apply: inc to increment the count, or dec to decrement the count.`,
  [`Set rate limits, burst limits, and count limits in an assembly`]: `Set rate limits, burst limits, and count limits in an assembly`,
  [`Strict UTF-8 Encoding`]: `Strict UTF-8 Encoding`,
  [`Specify whether to enforce strict UTF-8 encoding throughout the entire document`]: `Specify whether to enforce strict UTF-8 encoding throughout the entire document`,
  [`Settle down the api execution results`]: `Settle down the api execution results`,
  [`Result`]: `Result`,
  [`WSDL`]: `WSDL`,
  [`Handle WSDL routing based on the root element name inside the SOAPBody, and to return the WSDL file.`]: `Handle WSDL routing based on the root element name inside the SOAPBody, and to return the WSDL file.`,
  [`Parse Settings Reference`]: `Parse Settings Reference`,
  [`Specifies the parse settings configuration for the map action`]: `Specifies the parse settings configuration for the map action`,
  [`Parse Settings Default Reference`]: `Parse Settings Default Reference`,
  [`Specifies an existing object with default property values for the dynamic object`]: `Specifies an existing object with default property values for the dynamic object`,
  [`Parse Settings URL Reference`]: `Parse Settings URL Reference`,
  [`Specifies the URL of a resource with one or more properties for the dynamic object`]: `Specifies the URL of a resource with one or more properties for the dynamic object`,
  [`Parse Settings Literal Reference`]: `Parse Settings Literal Reference`,
  [`Specifies a literal configuration string with one or more properties for the dynamic object`]: `Specifies a literal configuration string with one or more properties for the dynamic object`,
  [`Parse Settings`]: `Parse Settings`,
  [`Specifies the parse settings configuration for the map action inlined.`]: `Specifies the parse settings configuration for the map action inlined.`,
  [`Type of document to parse`]: `Type of document to parse`,
  [`Maximum document size that the map action accepts`]: `Maximum document size that the map action accepts`,
  [`Maximum nesting depth of the XML, JSON, or GraphQL message that the map action accepts`]: `Maximum nesting depth of the XML, JSON, or GraphQL message that the map action accepts`,
  [`Maximum width of a payload that the map action accepts`]: `Maximum width of a payload that the map action accepts`,
  [`Maximum name length that the map action accepts`]: `Maximum name length that the map action accepts`,
  [`Maximum value length that the map action accepts`]: `Maximum value length that the map action accepts`,
  [`Maximum number of unique prefixes that the map action accepts`]: `Maximum number of unique prefixes that the map action accepts`,
  [`Maximum Unique Prefixes`]: `Maximum Unique Prefixes`,
  [`Maximum Unique Names`]: `Maximum Unique Names`,
  [`Maximum Unique Namespaces`]: `Maximum Unique Namespaces`,
  [`Maximum Number Length`]: `Maximum Number Length`,
  [`Maximum number length that the map action accepts`]: `Maximum number length that the map action accepts`,
};
