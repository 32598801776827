// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Catalogus is niet bijgewerkt`,
  [`Catalog has been updated`]: `Catalogus is bijgewerkt`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `Het conceptproduct {draftProduct} moet verwijzen naar een of meer API's`,
  [`Number of Calls`]: `Aantal aanroepen`,
  [`Changes will apply to newly published APIs only`]: `Wijzigingen zijn alleen van toepassing op nieuwe API's`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Er zijn incompatibele {label} omdat ze een ander gatewaytype hebben met deze API.`,
  [`{label} is required`]: `{label} is verplicht`,
  [`Application is required`]: `Toepassing is verplicht`,
  [`Plan is required`]: `Plan is verplicht`,
  [`Gateway is required`]: `Gateway is vereist`,
  [`Consumer Org is required`]: `Consumentorganisatie is verplicht`,
  [`LDAP ATTRIBUTE NAME`]: `LDAP ATTRIBUTE NAME`,
  [`Use the build in Test Application (sandbox only)`]: `De build in testtoepassing gebruiken (alleen sandbox)`,
  [`Select an Application`]: `Selecteer een toepassing`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `De ingebouwde testtoepassing gebruiken (alleen sandbox) - uitgeschakeld als er een niet-sandboxcatalogus is gekozen`,
  [`Choose an existing Application`]: `Kies een bestaande toepassing`,
  [`Use JWT for gateway authentication to analytics service`]: `JWT gebruiken voor gateway-verificatie voor analyseservice`,
  [`Select an application to consume the API`]: `Selecteer een toepassing om de API te consumeren`,
  [`Use the default built-in Sandbox Catalog'`]: `De standaard ingebouwde sandboxcatalogus gebruiken`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Opmerking: Deze catalogus wordt gebruikt als bereik voor alle andere voorkeuren. Als u deze instelling wijzigt, moet u andere voorkeuren aanpassen.`,
  [`Select a Consumer Org`]: `Selecteer een consumentorganisatie`,
  [`Select the Consumer Org your generated application will belong to`]: `Selecteer de consumentorganisatie waartoe uw gegenereerde toepassing behoort`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Opmerking: Deze optie kan alleen worden gewijzigd als u de ingebouwde sandboxtesttoepassing gebruikt, omdat bestaande toepassingen al deel uitmaken van een consumentorganisatie`,
  [`Generate auto product`]: `Automatisch product genereren`,
  [`Select a product to associate the current API with`]: `Selecteer een product waaraan de huidige API gekoppeld wordt`,
  [`Associate the current API to a selected product`]: `De huidige API koppelen aan een geselecteerd product`,
  [`API management`]: `API-beheer`,
  [`Select the API management instance you would like this API to be published to.`]: `Selecteer de API-beheerinstance waarvoor u deze API wilt publiceren.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Opmerking: Deze instance wordt gebruikt als het bereik voor alle andere voorkeuren. Als u deze instelling wijzigt, moet u andere voorkeuren aanpassen.`,
  [`Provider organization`]: `Providerorganisatie`,
  [`Select the provider organization you would like this API to be published to.`]: `Selecteer de providerorganisatie waarvoor u deze API wilt publiceren.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Opmerking: Deze organisatie wordt gebruikt als het bereik voor alle andere voorkeuren. Als u deze instelling wijzigt, moet u andere voorkeuren aanpassen.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Opmerking: Deze ruimte wordt gebruikt als het bereik voor alle andere voorkeuren. Als u deze instelling wijzigt, moet u andere voorkeuren aanpassen.`,
  [`Selected a draft product`]: `Een conceptproduct selecteren`,
  [`Use any available gateway service`]: `Een willekeurige beschikbare gatewayservice gebruiken`,
  [`Select a compatible gateway service`]: `Selecteer een compatibele gatewayservice`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Opmerking: Deze gatewayservice wordt gebruikt als het bereik voor alle andere voorkeuren. Beleidsdefinities en andere gatewayservice-specifieke content worden op basis van deze instelling geladen.`,
  [`Select a gateway`]: `Selecteer een gateway`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Opmerking: Deze optie is uitgeschakeld als de optie 'Bestaand product' is geselecteerd. In plaats daarvan wordt het standaardplan voor het gegenereerde product gebruikt.`,
  [`Select a Plan`]: `Selecteer een plan`,
  [`Generate subscription`]: `Abonnement genereren`,
  [`A subscription will be created between the selected Product plan and Application`]: `Er wordt een abonnement gemaakt tussen het geselecteerde productplan en de toepassing`,
  [`Apply a rate limit to the generated product`]: `Een aanroeplimiet toepassen op het gegenereerde product`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Pas een aanroeplimiet toe op het standaardplan van de gegenereerde producten. Deze optie is uitgeschakeld als de optie 'Bestaand product' is geselecteerd en in plaats daarvan wordt de in het geselecteerde plan gedefinieerde aanroeplimiet gebruikt.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `Het Lightweight Directory Access Protocol (LDAP) is een internetprotocol voor het benaderen en onderhouden van informatieservices voor gedistribueerde directory's via een netwerk. Als er bij u een grootschalige LDAP-service werkzaam is, configureer die dan als bron die kan worden gebruikt voor gebruikersverificatie. Als u ook gebruik wilt maken van het onboarden van nieuwe gebruikers van Developer Portal, moet u het gedeelte Toewijzing van kenmerken invullen om schrijfbare LDAP in te schakelen. Selecteer in dit gedeelte het vakje Beheerd door gebruiker en verstrek de toewijzing van de kenmerknamen van uw bron-LDAP naar de doelAPI Connectwaarden voor API Connect.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Als u zelfgoedkeuring voor taken inschakelt, kunnen taken door de maker en door medewerkers worden goedgekeurd. Deze optie kan handig zijn als er geen andere goedkeurders beschikbaar zijn, maar zorgt er in feite voor dat controles door andere gebruikers overgeslagen worden.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Een factureringsintegratie verbindt API Connect met een account in een factureringssysteem voor abonnementen van derden dat klanten, hun betalingsmethoden, facturering en actieve abonnementen beheert`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Een native OAuth-providerobject bevat instellingen voor OAuth-bewerkingen, zoals het genereren en valideren van OAuth-tokens. Om een API te beschermen, kan er door een OAuth-beveiligingsdefinitie worden verwezen naar een OAuth-providerobject. Als er een native OAuth-provider wordt gebruikt, worden OAuth-bewerkingen in native vorm uitgevoerd door API Connect. Elk OAuth-providerobject heeft een backup-API. Uw configuratie werkt het Swagger-document van de API automatisch bij. U kunt het Swagger-document bewerken door naar de pagina API-editor te gaan.  Als een gepubliceerde API verwijst naar een OAuth-providerobject, wordt de backup-API automatisch beschikbaar gemaakt in de gateway.`,
  [`About token management`]: `Over tokenbeheer`,
  [`About user security`]: `Over gebruikersbeveiliging`,
  [`APIs or products`]: `API's of producten`,
  [`ATM is not deployed, please contact your administrator`]: `ATM is niet geïmplementeerd, neem contact op met de beheerder`,
  [`Application state change approvals will be enabled`]: `Goedkeuringen voor statuswijziging van toepassingen worden ingeschakeld`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Definieer de instellingen die moeten worden gebruikt voor het extraheren van de legitimatiegegevens van de gebruikers, het verifiëren van hun identiteit en het verlenen van autorisaties.`,
  [`See console for details.`]: `Zie de console voor meer informatie.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `De geselecteerde certificaatbeheerservice beheert geen certificaten.`,
  [`The selected product is not published to the Sandbox catalog`]: `Het geselecteerde product wordt niet gepubliceerd naar de sandboxcatalogus.`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `Het geselecteerde product is niet gepubliceerd naar de sandboxcatalogus. Daarom is het niet mogelijk om u te abonneren op de toepassing.`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `de IBM Cloud CLI voor uw platform (als deze nog niet is geïnstalleerd).`,
  [`(Only supported by TLS 1.3)`]: `(Alleen ondersteund door TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `"@listSize"-instructie voor dit veld definieert velden met grootte die geen lijsten retourneren.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `"@listSize"-instructie voor dit veld definieert velden met grootte die niet zijn gedefinieerd voor het retourtype van het veld.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `"@listSize"-instructie voor dit veld definieert segmenteringsargumenten die niet voor het veld zijn gedefinieerd.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `"@listSize"-instructie voor dit veld definieert segmenteringsargumenten waarvan de typen niet "Int" of "Int!" zijn..`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `"@listSize"-instructie voor dit veld definieert meerdere niet-null segmenteringsargumenten.`,
  [`"{name}" (Role) has been created`]: `"{name}" (Rol) is gemaakt.`,
  [`"{name}" (Role) has been updated`]: `"{name}" (Rol) is bijgewerkt.`,
  [`API ({api}) has been created.`]: `API ({api}) is gemaakt.`,
  [`API ({api}) has been renamed.`]: `Naam van API ({api}) is gewijzigd.`,
  [`API ({api}) has been updated.`]: `API ({api}) is bijgewerkt.`,
  [`API ({api}) has been deleted.`]: `API ({api}) is gewist.`,
  [`API ({api}) has been published.`]: `API ({api}) is gepubliceerd.`,
  [`API ({api}) has validation error.`]: `API ({api}) bevat validatiefout`,
  [`API ({api}) has validation errors.`]: `API ({api}) bevat validatiefouten`,
  [`API version`]: `API-versie`,
  [`APIM data version`]: `APIM-gegevensversie`,
  [`APIM schema update date`]: `Wijzigingsdatum APIM-schema`,
  [`APIM schema version`]: `APIM-schemaversie`,
  [`APIM target data version`]: `Versie APIM-doelgegevens`,
  [`APIM target schema version`]: `Versie APIM-doelschema`,
  [`APIM update date`]: `APIM-updatedatum`,
  [`Product ({product}) has been created.`]: `Product ({product}) is gemaakt.`,
  [`Product ({product}) has been updated.`]: `Product ({product}) is bijgewerkt.`,
  [`Product ({product}) has been delete.`]: `Product ({product}) is gewist.`,
  [`Product ({product}) has been published.`]: `Product ({product}) is gepubliceerd.`,
  [`"{title}" ({type}) has been {changedName}.`]: `"{title}" ({type}) is {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `"{title}" ({type}) is niet {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} na in de wachtrij zetten)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} na verzending)`,
  [`(none)`]: `(geen)`,
  [`(optional)`]: `(optioneel)`,
  [`*Base endpoint list empty`]: `*Lijst van basiseindpunten is leeg`,
  [`*Must be a valid url`]: `*Moet een geldige URL zijn`,
  [`*System will also send an email notification to the requester.`]: `*Het systeem stuurt ook een e-mailmelding naar de aanvrager.`,
  [`+ redact from...`]: `+ afschermen uit...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. U kunt de levenscyclus van dit product ook beheren binnen de catalogus.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x gevolgd door 64 hex-tekens`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Is eigenaar van API-consumentorganisaties en beheert deze 2) Beheert community's van toepassingsontwikkelaars 3) Schrijft API- en productdefinities 4) Beheert de levenscyclus van API-producten`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Is eigenaar van API-providerorganisaties en beheert deze 2) Beheert community's van toepassingsontwikkelaars 3) Schrijft API- en productdefinities 4) Beheert de levenscyclus van API-producten`,
  [`5 Most Active APIs`]: `5 actiefste API's`,
  [`5 Most Active Products`]: `5 actiefste producten`,
  [`5 most active APIs`]: `5 meest actieve API's`,
  [`5 most active Products`]: `5 meest actieve producten`,
  [`503 Service Unavailable`]: `503 Service Unavailable`,
  [`A request to the server was made without any credentials.`]: `Er is een opdracht op de server uitgevoerd zonder legitimatiegegevens.`,
  [`A server error occurred`]: `Er is een serverfout opgetreden`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Een TLS-clientprofiel configureert de certificaatketen en de cipher-suite die door API Connect worden gebruikt wanneer er als client verbinding wordt gemaakt met andere systemen.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Een TLS-serverprofiel configureert de certificaatketen en de cipher-suite die door API Connect worden gebruikt wanneer er server-eindpunten worden gepresenteerd aan andere systemen.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Een catalogus bevat een verzameling API-producten die na publicatie zichtbaar zijn in de bijbehorende ontwikkelaarsportal`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Een catalogus bevat een verzameling API-producten die na publicatie zichtbaar zijn in de bijbehorende ontwikkelaarsportal.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Een catalogus vertegenwoordigt een verzameling beheerde API-producten. Producten die gepubliceerd zijn, worden voor consumenten zichtbaar in de ontwikkelaarsportal die bij de catalogus hoort.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Voor het beveiligen van de verbinding tussen API Connect en het domein van het gatewaybeheereindpunt is een certificaat vereist. Er zijn ook certificaten vereist om de verbindingen tussen de gateway en de domeinen te beveiligen. Alle certificaten moeten worden opgeslagen in een certificaatbeheerservice. De service biedt een beveiligde repository voor de certificaten en helpt storingen te voorkomen door u meldingen te sturen wanneer de certificaten op het punt staan te vervallen. U gaat als volgt aan de slag,`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Een standaardschema dat wordt gebruikt als de invoer die moet worden toegewezen (mapped) niet gedefinieerd is.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Een gatewayservice vertegenwoordigt een set gatewayservers of containers waarin gepubliceerde API's worden gehost, en geeft aan welke API-eindpunten er door clienttoepassingen worden gebruikt. Gateways voeren aanroepen van API Proxy's aan back-end systemen uit en dwingen het API-beleid af, inclusief clientidentificatie, beveiliging en aanroeplimieten.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Een gatewayhost publiceert API's en biedt de API-eindpunten die door clienttoepassingen worden gebruikt. Gateways voeren aanroepen van API Proxy's aan back-end systemen uit en dwingen het API-beleid af, inclusief clientidentificatie, beveiliging en aanroeplimieten.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Voor een bronproduct kan er een migratiedoel worden ingesteld om het doel voor de migratie van abonnementen te definiëren.  Het migratiedoel bevat een plantoewijzing waarmee de toewijzing van plannen uit het bronproduct naar plannen in het doelproduct wordt beschreven.  Het migratiedoel is in de ontwikkelaarsportal zichtbaar om het migratiedoel te laten communiceren met de abonnees van het bronproduct.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Een modulaire OAuth-beleidskit. Deze kan alle stappen in het OAuth/OpenID Connect-protocol uitvoeren in één beleid (standaard), of kan worden opgesplitst in meerdere beleidsdefinities die slechts één of een “n”-aantal stappen per keer uitvoeren, zodat u meer controle hebt. De invoer en uitvoer van elk van de stappen worden aangestuurd door gedocumenteerde contextvariabelen. De Ondersteunde OAuth-componenten kunt u toevoegen of verwijderen zoals nodig is.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Het is niet mogelijk een portal te maken zonder een beschikbare portalservice. Neem voor hulp contact op met de cloudbeheerder.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Een providerorganisatie is een team dat eigenaar is van API's en van de bijbehorende plannen en producten. Een providerorganisatie moet een eigenaar hebben aan wie alle machtigingen zijn toegewezen. De eigenaar is een van de leden.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Een providerorganisatie omvat gebruikers die API's, producten, plannen, catalogi, consumentorganisaties en andere aanverwante resources beheren. U kunt de providorganisaties gebruiken voor het groeperen van gebruikers en API-beheerresources per project, afdeling, levenscyclusfase, enzovoort. Om rollen voor gebruikersmachtigingen te beheren en toe te wijzen, gaat u naar IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Een eenvoudige JavaScript-expressie die wordt omgezet naar de waarde van de uitvoer. Dit kan een statische reeks zijn ("mijn statische reeks"), of een expressie waarbij eventuele toegewezen invoeritems betrokken zijn ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `ACTIE`,
  [`ANALYTICS`]: `ANALYSE`,
  [`API`]: `API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `API-beheerder`,
  [`API CONNECT VALUE`]: `API CONNECT VALUE`,
  [`API assemble`]: `API-assemblage`,
  [`API base endpoints`]: `API-basiseindpunten`,
  [`API base URL`]: `Basis-URL van API`,
  [`API base URLs`]: `Basis-URL's van API`,
  [`API Calls`]: `API-aanroepen`,
  [`API Connect UI`]: `Gebruikersinterface van API Connect`,
  [`API Connect services`]: `API Connect-services`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `API Connect heeft een e-mailserver nodig om uitnodigingen en activeringslinks naar leden te sturen, en voor andere doeleinden.`,
  [`API Connect verify token`]: `API Connect verificatietoken`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `Licentie voor API Designer`,
  [`API Designer credentials`]: `Legitimatiegegevens van API Designer`,
  [`API Designer licence`]: `Licentie voor API Designer`,
  [`API Designer does not currently support WSDL`]: `API Designer ondersteunt momenteel geen WSDL`,
  [`API editor`]: `API-editor`,
  [`API Endpoint`]: `API-eindpunt`,
  [`API Endpoint Base`]: `Basis API-eindpunt`,
  [`API Endpoint for Unenforced APIs`]: `API-eindpunt voor niet-afgedwongen API's`,
  [`API Endpoint for unenforced APIs`]: `API-eindpunt voor niet-afgedwongen API's`,
  [`API Endpoints`]: `API-eindpunten`,
  [`API Error`]: `API-fout`,
  [`API gateway`]: `API-gateway`,
  [`API Gateway`]: `API Gateway`,
  [`API gateways`]: `API-gateways`,
  [`API Gateways`]: `API-gateways`,
  [`API Invocation Endpoint`]: `Eindpunt API-oproep`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `API is niet gepubliceerd. API-test (Uitproberen) in Explorer wordt niet ingeschakeld.`,
  [`API Key`]: `API-sleutel`,
  [`API key`]: `API-sleutel`,
  [`API key secret`]: `API-sleutelgeheim`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `Lokaal gebruikersregister van API Manager`,
  [`API Manager URL`]: `URL van API Manager`,
  [`API Management`]: `API-beheer`,
  [`API Path Parameters`]: `API-padparameters`,
  [`API Security Definition`]: `API-beveiligingsdefinitie`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `API-test (uitproberen) in Explorer wordt niet ingeschakeld voor deze API omdat deze niet afgedwongen is.`,
  [`API Setup`]: `API-setup`,
  [`API Subscription`]: `API-abonnement`,
  [`API endpoint`]: `API-eindpunt`,
  [`API endpoint base`]: `Basis API-eindpunt`,
  [`API endpoint for unenforced APIs`]: `API-eindpunt voor niet-afgedwongen API's`,
  [`API endpoints`]: `API-eindpunten`,
  [`API error`]: `API-fout`,
  [`API invocation endpoint`]: `Eindpunt API-oproep`,
  [`API is not enforced on the API gateway.`]: `API is niet afgedwongen op de API-gateway.`,
  [`API path parameters`]: `Parameters van API-pad`,
  [`API security definition`]: `API-beveiligingsdefinitie`,
  [`API setup`]: `API-instelling`,
  [`API subscription`]: `API-abonnement`,
  [`API Update`]: `API-update`,
  [`API user registries`]: `API-gebruikersregisters`,
  [`API and product generated successfully!`]: `API en product zijn gegenereerd.`,
  [`API deletion failed`]: `Wissen van API is mislukt`,
  [`API does not exist!`]: `API bestaat niet!`,
  [`API from existing REST service`]: `API op basis van bestaande REST-service`,
  [`API from existing SOAP service`]: `API op basis van bestaande SOAP-service`,
  [`API has been updated.`]: `API is bijgewerkt.`,
  [`API has been updated`]: `API is bijgewerkt`,
  [`API has not been created!`]: `API is niet gemaakt!`,
  [`API has not been updated!`]: `API is niet bijgewerkt!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `Prioriteit voor API (de in de OpenAPI-definities gedefinieerde Vanity-eindpunt(en) gebruiken)`,
  [`API properties`]: `API-eigenschappen`,
  [`API protection source`]: `API-beveiligingsbron`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `De API waarnaar vanuit het product wordt verwezen, bestaat niet. U moet de API eerst maken of importeren.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `De payloadstructuren van de API-aanvraag en -respons zijn gemaakt op basis van OpenAPI-schemadefinities.`,
  [`API subscription request approved for app {{appName}}`]: `Aanvraag van API-abonnement is goedgekeurd voor app {appName}`,
  [`API subscription request denied for app {{appName}}`]: `Aanvraag van API-abonnement is afgewezen voor app {appName}`,
  [`API subscription request received for app {{appName}}`]: `Aanvraag van API-abonnement is ontvangen voor app {appName}`,
  [`API type`]: `API-type`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `API met sleutel ''{key}'' onder eigenschap 'apis' bestaat niet en kan niet worden afgebeeld. Controleer de brontab.`,
  [`APIs`]: `API's`,
  [`APIs and products`]: `API's en producten`,
  [`APIs included in the plan.`]: `API's opgenomen in het plan.`,
  [`API Key is created`]: `API-sleutel is gemaakt`,
  [`API Key is deleted`]: `API-sleutel is gewist`,
  [`API Key is successfully created`]: `API-sleutel is gemaakt`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `Timeout voor API-sleutel is **{seconds} seconden ({time})**`,
  [`API key timeout`]: `Timeout voor API-sleutel`,
  [`API Key timeout has been changed`]: `Timeout voor API-sleutel is gewijzigd`,
  [`Application type`]: `Type toepassing`,
  [`Associated analytics service`]: `Bijbehorende analyseservice`,
  [`AVAILABILITY`]: `BESCHIKBAARHEID`,
  [`AVAILABILITY ZONE`]: `BESCHIKBAARHEIDSZONE`,
  [`About`]: `Info`,
  [`About audit setting`]: `Info over auditinstelling`,
  [`About Authentication URL user registry`]: `Info over gebruikersregister voor verificatie-URL`,
  [`About availability zones`]: `Info over beschikbaarheidszones`,
  [`About catalogs`]: `Info over catalogi`,
  [`About consumer organization owners`]: `Over eigenaren van consumentorganisaties`,
  [`About consumer organizations`]: `Info over consumentenorganisaties`,
  [`About developer portals`]: `Info over ontwikkelaarsportals`,
  [`About email servers`]: `Over e-mailservers`,
  [`About Endpoints`]: `Over eindpunten`,
  [`About gateways`]: `Over gateways`,
  [`About gateway visibility`]: `Over zichtbaarheid van gateways`,
  [`About Gateway Processing Status`]: `Info over status gatewayverwerking`,
  [`About introspection`]: `Over introspectie`,
  [`About Keystores`]: `Over keystores`,
  [`About LDAP`]: `Over LDAP`,
  [`About local user registries`]: `Over lokale gebruikersregisters`,
  [`About metadata`]: `Over metagegevens`,
  [`About Migration Target`]: `Over migratiedoelen`,
  [`About migration targets`]: `Over migratiedoelen`,
  [`About native OAuth provider`]: `Over native OAuth-provider`,
  [`About OpenID Connect`]: `Over OpenID Connect`,
  [`About replacing a product`]: `Over het vervangen van een product`,
  [`About Role defaults`]: `Over standaardwaarden voor rollen`,
  [`About roles`]: `Over rollen`,
  [`About scopes`]: `Over bereiken`,
  [`About Set Migration Targets`]: `Over het instellen van migratiedoelen`,
  [`About spaces`]: `Over ruimten`,
  [`About Subscription`]: `Over abonnementen`,
  [`About superseding a product`]: `Over het verdringen van een product`,
  [`About TLS server profile`]: `Over TLS-serverprofiel`,
  [`About TLS client profiles`]: `Over TLS-clientprofielen`,
  [`About TLS server profiles`]: `Over TLS-serverprofielen`,
  [`About third party OAuth provider`]: `Over externe OAuth-provider`,
  [`About tokens`]: `Over tokens`,
  [`About Transfer Ownership`]: `Over de overdracht van eigendom`,
  [`About Transferring Ownership`]: `Over de overdracht van eigendom`,
  [`About Truststores`]: `Over truststores`,
  [`About adding a member`]: `Over het toevoegen van leden`,
  [`About adding provider organization member`]: `Over het toevoegen van leden van providerorganisaties`,
  [`About adding members to a Space`]: `Over het toevoegen van leden aan een ruimte`,
  [`About adding members to a catalog`]: `Over het toevoegen van leden aan een catalogus`,
  [`About changing owners`]: `Over het veranderen van eigenaar`,
  [`About enabling gateway services`]: `Info over het inschakelen van gatewayservices`,
  [`About inviting a member`]: `Over het uitnodigen van leden`,
  [`About inviting catalog members`]: `Info over het uitnodigen van leden voor de catalogus`,
  [`About inviting provider organization members`]: `Over het uitnodigen van gebruikers voor een providerorganisatie`,
  [`About inviting space members`]: `Info over het uitnodigen van leden voor de ruimte`,
  [`About keystores`]: `Over keystores`,
  [`About managing Space membership`]: `Info over het beheer van ruimtelidmaatschap`,
  [`About migrating subscriptions`]: `Over het migreren van abonnementen`,
  [`About provider organizations`]: `Over providerorganisaties`,
  [`About roles and role defaults`]: `Over rollen en standaardwaarden voor rollen`,
  [`About roles in spaces`]: `Over rollen in ruimten`,
  [`About selecting gateway services`]: `Info over het selecteren van gatewayservices`,
  [`About send message`]: `Over het verzenden van berichten`,
  [`About sending messages`]: `Over het verzenden van berichten`,
  [`About the analytics service`]: `Over de analyseservice`,
  [`About the DNS Scheme`]: `Over de DNS-schema`,
  [`About the Gateway Service`]: `Over de gatewayservice`,
  [`About the Portal Service`]: `Over de portalservice`,
  [`About the product lifecycle`]: `Over de levenscyclus van producten`,
  [`About the provider organization owner`]: `Over de eigenaar van een providerorganisatie`,
  [`About this Cloud`]: `Over deze Cloud`,
  [`About transferring ownership`]: `Over het overdragen van eigendom`,
  [`About truststores`]: `Over truststores`,
  [`About visibility`]: `Over zichtbaarheid`,
  [`About visibility and subscribability`]: `Over zichtbaarheid en abonnementsmogelijkheden`,
  [`Accept`]: `Accepteren`,
  [`Access Code`]: `Toegangscode`,
  [`Access Token`]: `Toegangstoken`,
  [`Access URL through Secure Gateway`]: `Toegang tot URL via Secure Gateway`,
  [`Access code`]: `Toegangscode`,
  [`Access code is not chosen in supported grant type.`]: `Toegangscode is niet gekozen in ondersteund grant-type.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Toegang tot de URL verkrijgen via een Secure Gateway. De gateway wordt ingesteld wanneer de API vanuit de status Klaargezet overgaat naar de status Gepubliceerd.`,
  [`Access token TTL has been changed`]: `TTL van toegangstoken is gewijzigd`,
  [`Access token time to live`]: `Levensduur toegangstoken`,
  [`Access token time-to-live`]: `Time-to-live van toegangstoken`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Toegangstoken vervalt na **{seconds} seconden ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Er worden toegangstokens aan clienttoepassingen verstrekt om die toepassingen namens de gebruiker van de toepassing toegang te verlenen tot resources.  Aan de client worden vernieuwingstokens verstrekt om een nieuw toegangstoken te kunnen ontvangen wanneer het huidige toegangstoken ongeldig wordt of vervalt, of om aanvullende toegangstokens met hetzelfde of een kleiner bereik te ontvangen. Het is ook mogelijk om op te geven hoe lang de toestemming die door de combinatie van een willekeurig aantal toegangs- en vernieuwingstokens wordt verleend, geldig blijft.`,
  [`Access tokens time to live (seconds)`]: `Levensduur van toegangstokens (seconden)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Toegangstoken, ID-token en tijdelijk token kunnen aan een van de bestaande keystores worden toegewezen. Historie van toewijzing van keystore blijft behouden.`,
  [`Account`]: `Account`,
  [`Account has been updated.`]: `Account is bijgewerkt.`,
  [`Account password has been changed.`]: `Accountwachtwoord is gewijzigd.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Actie`,
  [`Activate API`]: `API activeren`,
  [`Activate API|button text`]: `API activeren`,
  [`Activate API for testing|title`]: `API activeren`,
  [`Activation link`]: `Activeringslink`,
  [`Active`]: `Actief`,
  [`Activity log`]: `Activiteitenlogboek`,
  [`Activity log API setting is not available for this API's gateway type.`]: `API-instelling voor activiteitenlogboek is niet beschikbaar voor het type gateway van deze API.`,
  [`Active loading indicator`]: `Indicator voor actief laden`,
  [`Add`]: `Toevoegen`,
  [`Add allowlist`]: `Toegestane lijst toevoegen`,
  [`Add a new billing integration in Resources`]: `Voeg een nieuwe factureringsintegratie toe in Resources`,
  [`Add API user registries`]: `API-gebruikersregisters toevoegen`,
  [`Add APIs to Product`]: `API's toevoegen aan product`,
  [`Add another cloud`]: `Een andere cloud toevoegen`,
  [`Add billing integration`]: `Factureringsintegratie toevoegen`,
  [`Add Burst Limit`]: `Burstlimiet toevoegen`,
  [`Add Ciphers for TLS client profile`]: `Ciphers voor TLS-clientprofiel toevoegen`,
  [`Add Ciphers for TLS server profile`]: `Ciphers voor TLS-serverprofiel toevoegen`,
  [`Add Condition`]: `Voorwaarde toevoegen`,
  [`Add Consumer Role`]: `Consumentrol toevoegen`,
  [`Add Count Limit`]: `Aantallimiet toevoegen`,
  [`Add Credential`]: `Legitimatiegegeven toevoegen`,
  [`Add domain`]: `Domein toevoegen`,
  [`Add credential`]: `Legitimatie toevoegen`,
  [`Add Extension`]: `Extensie toevoegen`,
  [`Add GraphQL schema`]: `GraphQL-schema toevoegen`,
  [`Add HTTP Endpoint`]: `HTTP-eindpunt toevoegen`,
  [`Add group`]: `Groep toevoegen`,
  [`Add JSON schema`]: `JSON-schema toevoegen`,
  [`Add Keystore to TLS client profile`]: `Keystore toevoegen aan TLS-clientprofiel`,
  [`Add Keystore to TLS server profile`]: `Keystore toevoegen aan TLS-serverprofiel`,
  [`Add member`]: `Lid toevoegen`,
  [`Add New Role for Consumer organization`]: `Nieuwe rol voor consumentorganisatie toevoegen`,
  [`Add operation`]: `Bewerking toevoegen`,
  [`Add Parameters`]: `Parameters toevoegen`,
  [`Add Policy`]: `Beleid toevoegen`,
  [`Add Rate limit`]: `Aanvraaglimiet toevoegen`,
  [`Add Rate Limit`]: `Aanroeplimiet toevoegen`,
  [`Add role`]: `Rol toevoegen`,
  [`Add Syslog TCP Endpoint`]: `Syslog TCP-eindpunt toevoegen`,
  [`Add Syslog TLS Endpoint`]: `Syslog TLS-eindpunt toevoegen`,
  [`Add Syslog UDP Endpoint`]: `Syslog UDP-eindpunt toevoegen`,
  [`Add XML schema`]: `XML-schema toevoegen`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Voeg een Gateway-eindpunt toe dat beschikbaar moet worden gesteld voor aanroepen van API's in deze catalogus.`,
  [`Add a different Cloud Connection.`]: `Een andere cloudverbinding toevoegen.`,
  [`Add a member from the user registry`]: `Een lid toevoegen vanuit het gebruikersregister`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Voeg een gebruiker toe aan de beheerdersorganisatie en wijs de vereiste rollen toe`,
  [`Add a user to the provider organization, and assign the required roles`]: `Voeg een gebruiker toe aan de providerorganisatie en wijs de vereiste rollen toe`,
  [`Add a user to the Catalog, and assign the required roles`]: `Voeg een gebruiker toe aan de catalogus en wijs de vereiste rollen toe`,
  [`Add a user to the Space, and assign the required roles`]: `Voeg een gebruiker toe aan de ruimte en wijs de vereiste rollen toe`,
  [`Add action`]: `Actie toevoegen`,
  [`Add additional client ID/client secret pairs`]: `Extra combinaties van client-ID en clientgeheim toevoegen`,
  [`Add blocklist`]: `Blokkeerlijst toevoegen`,
  [`Add case`]: `Voorval toevoegen`,
  [`Add catch`]: `Vangst toevoegen`,
  [`Add default catch`]: `Standaardvangst toevoegen`,
  [`Add destination`]: `Bestemming toevoegen`,
  [`Add entry`]: `Item toevoegen`,
  [`Add group failed.`]: `Toevoegen van groep is mislukt.`,
  [`Add input`]: `Invoer toevoegen`,
  [`Add media type`]: `Mediatype toevoegen`,
  [`Add member failed`]: `Toevoegen van lid is mislukt`,
  [`Add object`]: `Object toevoegen`,
  [`Add otherwise`]: `Anders toevoegen`,
  [`Add output`]: `Uitvoer toevoegen`,
  [`Add outputs for operation...`]: `Uitvoer voor bewerking toevoegen...`,
  [`Add parameters for operation...`]: `Parameters voor bewerking toevoegen...`,
  [`Add parameters to this API`]: `Voeg parameters toe aan deze API`,
  [`Add plans to product`]: `Plannen toevoegen aan een product`,
  [`Add plans to this product`]: `Plannen toevoegen aan dit product`,
  [`Add schema`]: `Schema toevoegen`,
  [`Add scopes for this OAuth provider.`]: `Bereiken voor deze OAuth-provider toevoegen.`,
  [`Add scopes to allow access to`]: `Voeg bereiken toe waartoe toegang moet worden verleend`,
  [`Add scopes to allow access to.`]: `Bereiken toevoegen waartoe toegang toegestaan wordt.`,
  [`Add tags and external documentation details for this API`]: `Voeg tags en externe documentatiegegevens voor deze API toe`,
  [`Add to`]: `Toevoegen aan`,
  [`Add to existing product`]: `Toevoegen aan bestaand product`,
  [`Add/Remove APIs`]: `API's toevoegen/verwijderen`,
  [`AddProperties`]: `Eigenschappen toevoegen`,
  [`Added APIs`]: `API's toegevoegd`,
  [`Added rate limit`]: `Aanvraaglimiet toegevoegd`,
  [`Added rate limits`]: `Aanvraaglimieten toegevoegd`,
  [`Adding an API to a product for publishing`]: `Een API toevoegen aan een product voor publicatie`,
  [`Additional support`]: `Aanvullende ondersteuning`,
  [`Additional properties`]: `Aanvullende eigenschappen`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Bovendien kunt u overwegen om het bijbehorende register, catalogusgebruikersregister {name}, te verwijderen als het niet wordt gebruikt.`,
  [`Address`]: `Adres`,
  [`Admin DN`]: `Beheer-DN`,
  [`Admin organization invitation timeout`]: `Timeout voor uitnodiging voor beheerorganisatie`,
  [`Admin organization invitation timeout has been changed`]: `Timeout voor uitnodiging voor beheerorganisatie is gewijzigd`,
  [`Admin password`]: `Beheerderswachtwoord`,
  [`Admin request reset password`]: `Aanvraag reset wachtwoord beheerder`,
  [`Admin reset password`]: `Reset wachtwoord beheerder`,
  [`Admin Sign In`]: `Beheerdersaanmelding`,
  [`Admin add catalog failed`]: `Toevoegen van catalogus door beheerder is mislukt`,
  [`Admin add space failed`]: `Toevoegen van ruimte door beheerder is mislukt`,
  [`Administer consumer organizations`]: `Consumentorganisaties beheren`,
  [`Administers the API consumer organization`]: `Kan de API-consumentorganisatie beheren`,
  [`Administers the API provider organization`]: `Beheert de API-providerorganisatie`,
  [`Administers the admin organization`]: `Beheert de beheerorganisatie`,
  [`Administers the admin topology`]: `Beheert de beheertopologie`,
  [`Administers the app developer organization`]: `Beheert de app-ontwikkelaarsorganisaties`,
  [`Administers the catalog`]: `Kan de catalogus beheren`,
  [`Administers the cloud topology`]: `Beheert de cloudtopologie`,
  [`Administers the space`]: `Beheert de ruimte`,
  [`Administration management console`]: `Administratiebeheerconsole`,
  [`Administrator`]: `Beheerder`,
  [`Advanced analytics configuration`]: `Geavanceerde analyseconfiguratie`,
  [`Advanced features`]: `Geavanceerde functies`,
  [`Advanced scope check`]: `Geavanceerde bereikcontrole`,
  [`Advanced scope check after Token Validation`]: `Geavanceerde bereikcontrole na afloop van tokenvalidatie`,
  [`Advanced scope check before Token Generation`]: `Geavanceerde bereikcontrole voordat er tokens worden gegenereerd`,
  [`Aggregation`]: `Aggregatie`,
  [`Agree`]: `Akkoord`,
  [`All`]: `Alle`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Alle geverifieerde ontwikkelaars in consumentorganisaties die zich hebben ingeschreven voor de ontwikkelaarsportal kunnen dit product zien.`,
  [`All consumer organizations will be able to see this product.`]: `Alle consumentorganisaties kunnen dit product zien.`,
  [`Allowlist`]: `Toegestane lijst`,
  [`Allow Chunked Uploads`]: `Bloksgewijze uploads toestaan`,
  [`Allow chunked uploads`]: `Uploads in chunks toestaan`,
  [`Allow "plain" challenge method`]: `"Eenvoudige" methode voor controlevraag toestaan`,
  [`Allow renegotiation`]: `Heronderhandeling toestaan`,
  [`Allow case sensitive usernames`]: `Hoofdlettergevoelige gebruikersnamen toestaan`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Clients toestaan een SNI-extensie met de gewenste hostnaam te injecteren in hun eerste handshake met de server.`,
  [`Allow connection to be renegotiated`]: `Toestaan dat er opnieuw over de verbinding wordt onderhandeld`,
  [`Allow default introspection`]: `Standaard introspectie toestaan`,
  [`Allow insecure server connections`]: `Onveilige serververbindingen toestaan`,
  [`Allow null value`]: `Nullwaarde toestaan`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Toestaan dat toegangstoken/vernieuwingstoken in 302-omleiding voor afmelding wordt verzonden`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Alle aanbeveling zijn toegepast. Er zijn geen waarschuwingen voor het schema.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Toestaan dat de API-bewerkingen worden getest met behulp van de testtools in de Developer Portal.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `De verbinding kan ook tot stand worden gebracht als de legitimatiegegevens zwak of onveilig zijn`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `De verbinding kan ook tot stand worden gebracht als de legitimatiegegevens zwak of onveilig zijn.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `De externe OIDC-provider toestaan om te communiceren met de Developer Portal, niet met API Manager.`,
  [`Allow to manage this user registry`]: `Beheer van dit gebruikersregister toestaan`,
  [`Allow to manage users under this user registry`]: `Beheer van gebruikers onder dit gebruikersregister toestaan`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Gebruikers automatisch laten onboarden wanneer aan APIC een door de verstrekkende instantie uitgegeven token gepresenteerd wordt`,
  [`Already have an account?`]: `Hebt u al een account?`,
  [`Also transfer ownership of owned Spaces`]: `Eigendom van ruimten in eigendom ook overdragen`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Gebruikersgegevens altijd ophalen uit userinfo-eindpunt, indien ingeschakeld`,
  [`Always output the root element`]: `Hoofdelement altijd uitvoeren`,
  [`Always output the root element.`]: `Hoofdelement altijd uitvoeren.`,
  [`Always required`]: `Altijd vereist`,
  [`Always use userinfo endpoint`]: `Altijd userinfo-eindpunt gebruiken`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Een API van een OAuth-provider bevat de autorisatie- en tokeneindpunten van een OAuth-stroom. Hier kunt u uw OAuth-providers configureren; vervolgens kunt u, als u een beveiligde OAuth-API maakt, de vereiste provider selecteren.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Een OAuth-provider bevat de autorisatie- en tokeneindpunten van een OAuth-stroom; selecteer de OAuth-provider die u wilt gebruiken voor het beveiligen van uw API met OAuth`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Er wordt hier een toepassing genoemd wanneer een ontwikkelaar die toepassing abonneert op een plan in de ruimte, zodat de bijbehorende API's kunnen worden aangeroepen. U kunt een ontwikkelaarsorganisatie onderbreken. Op die manier blokkeert u de toegang van die organisatie tot uw API's. Bovendien kunt u uw eigen toepassingen maken. [Meer informatie]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Een Verificatie-URL wijst een externe (derde partij) verificatieprovider aan als het gebruikersregister. Dankzij een Verificatie-URL is integratie met andere externe gebruikersregisters dan LDAP mogelijk.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Een verificatie-URL vormt een eenvoudig mechanisme om de identiteit van gebruikers te verifiëren bij een aangepaste ID-provider.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Er is een fout opgetreden bij het communiceren met het gatewaysubsysteem.`,
  [`An organization is required.`]: `Er is een organisatie vereist.`,
  [`An unknown error occurred.`]: `Er is een onbekende fout opgetreden.`,
  [`Analytics`]: `Analytics`,
  [`Analytics Insights`]: `Analyse-inzichten`,
  [`Analytics Insights Service'`]: `Service voor analyse-inzichten'`,
  [`Analytics Details`]: `Analysegegevens`,
  [`Analytics destinations`]: `Analysebestemmingen`,
  [`Analytics Director keystore`]: `Keystore van Analytics Director`,
  [`Analytics Service`]: `Analyseservice`,
  [`Analytics Service URL`]: `URL van analyseservice`,
  [`Analytics Service {arg} has been removed.`]: `Analyseservice {arg} is verwijderd.`,
  [`Analytics details`]: `Analysegegevens`,
  [`Analytics service`]: `Analyseservice`,
  [`Analytics service details`]: `Details van analyseservice`,
  [`Analytics service URL`]: `URL van analyseservice`,
  [`Analytics service {arg} has been removed.`]: `Analyseservice {arg} is verwijderd.`,
  [`Analytics client TLS client profile`]: `TLS-clientprofiel voor Analytics client`,
  [`Analytics client keystore`]: `Keystore van Analytics client`,
  [`Analytics client truststore`]: `Trutstore van Analytics client`,
  [`Analytics ingestion TLS client profile`]: `TLS-clientprofiel voor inname van analyse`,
  [`Analytics ingestion keystore`]: `Keystore voor inname van analyse`,
  [`Analytics ingestion truststore`]: `Truststore voor inname van analyse`,
  [`Analytics service {title} has been created.`]: `Analyticsservice {title} is gemaakt.`,
  [`Analytics service {title} has been updated.`]: `Analyticsservice {title} is bijgewerkt.`,
  [`The selected consumer organization is`]: `De geselecteerde consumentorganisatie is`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Analyseservices zijn geconfigureerd en analysegegevens zijn naar extern doel verplaatst`,
  [`Analyze API usage and performance`]: `API-gebruik en -prestaties analyseren`,
  [`Anonymous bind`]: `Anonieme bind`,
  [`Another user registry`]: `Nog een gebruikersregister`,
  [`api-administrator`]: `api-beheerder`,
  [`Api-Analytics`]: `Api-analyse`,
  [`Api-Drafts`]: `Api-concepten`,
  [`App`]: `App`,
  [`App name`]: `Appnaam`,
  [`App-Analytics`]: `App-Analyse`,
  [`App-Approval`]: `App-goedkeuring`,
  [`App-Dev`]: `App-ontwikkeling`,
  [`App-analytics`]: `App-analyse`,
  [`App-dev`]: `App-dev`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-levenscyclus-geannuleerd`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Toepassing`,
  [`Application Authentication`]: `Toepassingsverificatie`,
  [`Application Authentication Source`]: `Bron voor toepassingsverificatie`,
  [`Application lifecycle`]: `Toepassingslevenscyclus`,
  [`Application approval task for upgrading application`]: `Toepassingsgoedkeuringstaak voor upgraden van toepassing`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Toepassingsgoedkeuringstaak voor het upgraden van toepassing {taskname} naar productie aangevraagd door {username} ({orgname})`,
  [`Application authentication`]: `Toepassingsverificatie`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Toepassingsontwikkelaars abonneren hun toepassingen aanvankelijk op een of meer plannen; daarvoor gebruiken ze de ontwikkelaarsportal. Voor een geselecteerd plan is het echter mogelijk om de abonnementen op toepassingen te migreren naar een plan in een ander product.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Toepassingsontwikkelaars abonneren hun toepassingen aanvankelijk op een of meer plannen; daarvoor gebruiken ze de ontwikkelaarsportal. Voor een geselecteerd plan is het echter mogelijk om de abonnementen op toepassingen te migreren naar een plan in een ander product.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Toepassingsontwikkelaars abonneren een toepassing op een plan, zodat ze de API's in dat plan vervolgens kunnen aanroepen.`,
  [`Application is being created. Please Wait`]: `Toepassing wordt gemaakt. Even geduld a.u.b.`,
  [`Application scope check`]: `Controle op toepassingsbereik`,
  [`Applications`]: `Toepassingen`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Toepassingen die al bestonden voordat de toepassingslevenscyclus werd ingeschakeld, behouden de productiestatus`,
  [`Applied security`]: `Beveiliging toegepast`,
  [`Apply`]: `Toepassen`,
  [`Apply all`]: `Alles toepassen`,
  [`Apply all suggestions`]: `Alle suggesties toepassen`,
  [`Apply analysis configuration`]: `Analyseconfiguratie toepassen`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Pas het oproepbeleid toe op het aanroepen van een bestaande service vanuit uw operationele activiteiten. Het beleid kan worden gebruikt met JSON- of XML-gegevens, en kan meerdere keren binnen uw assemblage worden toegepast.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Pas het upgradebeleid voor websockets toe om een websocketverbinding tot stand te brengen waarmee een bestaande service vanuit uw bedrijf aangeroepen kan worden.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Pas dit beleid toe om een proxy-API op te roepen in uw bewerking, met name als u een grote payload dient aan te roepen. Er kan slechts één proxybeleid per assemblage worden aangeroepen.`,
  [`Apply to selected`]: `Toepassen op geselecteerde items`,
  [`Apply type analysis configuration`]: `Configuratie van typeanalyse toepassen`,
  [`Approval History`]: `Goedkeuringshistorie`,
  [`Approval tasks`]: `Goedkeuringstaken`,
  [`Approvals`]: `Goedkeuringen`,
  [`Approve`]: `Goedkeuren`,
  [`Archive`]: `Archiveren`,
  [`Archive Product`]: `Product archiveren`,
  [`Archive|permission`]: `Archiveren`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Weet u zeker dat u de voorwaarden van uw geselecteerde items wilt verwijderen?`,
  [`Are you sure you want to delete API?`]: `Weet u zeker dat u de API  wilt wissen?`,
  [`Are you sure you want to delete Product?`]: `Weet u zeker dat u het product wilt wissen?`,
  [`Are you sure you want to delete a catalog?`]: `Weet u zeker dat u een catalogus wilt wissen?`,
  [`Are you sure you want to delete a space?`]: `Weet u zeker dat u een ruimte wilt wissen?`,
  [`Are you sure you want to delete this application?`]: `Weet u zeker dat u deze toepassing wilt wissen?`,
  [`Are you sure you want to delete this billing integration?`]: `Weet u zeker dat u deze factureringsintegratie wilt wissen?`,
  [`Are you sure you want to delete this field?`]: `Weet u zeker dat u dit veld wilt wissen?`,
  [`Are you sure you want to delete this policy?`]: `Weet u zeker dat u dit beleid wilt wissen?`,
  [`Are you sure you want to delete this type?`]: `Weet u zeker dat u dit type wilt wissen?`,
  [`Are you sure you want to disable spaces?`]: `Weet u zeker dat u het gebruik van ruimten wilt uitschakelen?`,
  [`Are you sure you want to disable custom email sender information?`]: `Weet u zeker dat u de gegevens voor de afzender van de aangepaste e-mail wilt uitschakelen?`,
  [`Are you sure you want to disable custom notification templates?`]: `Weet u zeker dat u aangepaste meldingssjablonen wilt uitschakelen?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Weet u zeker dat u het gebruik van de IBM Developer Portal wilt staken? Alle aanpassingen voor de portal van de catalogus worden gewist`,
  [`Are you sure you want to enable spaces?`]: `Weet u zeker dat u het gebruik van ruimten wilt inschakelen?`,
  [`Are you sure you want to enable custom email sender information?`]: `Weet u zeker dat u de gegevens van de afzender op maat wilt inschakelen?`,
  [`Are you sure you want to enable custom notification templates?`]: `Weet u zeker dat u aangepaste meldingssjablonen wilt inschakelen?`,
  [`Are you sure you want to proceed with delete?`]: `Weet u zeker dat u het wissen wilt doorzetten?`,
  [`Are you sure you want to publish?`]: `Weet u zeker dat u dit wilt publiceren?`,
  [`Are you sure you want to re-stage?`]: `Bent u er zeker van dat u het item opnieuw wilt klaarzetten?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Weet u zeker dat u dit product wilt verwijderen uit de catalogus?`,
  [`Are you sure you want to unassociate?`]: `Weet u zeker dat u de koppeling ongedaan wilt maken?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Weet u zeker dat u Zelfservice Onboarding wilt bijwerken`,
  [`Are you sure?`]: `Weet u het zeker?`,
  [`Argument`]: `Argument`,
  [`Array`]: `Array`,
  [`Assemble`]: `Assembleren`,
  [`Assemble the policy flow required for the OAuth provider.`]: `De voor de OAuth-provider vereiste beleidsstroom samenstellen.`,
  [`Assembly`]: `Assemblage`,
  [`Assembly Execute`]: `Assemblageuitvoering`,
  [`Assembly saved`]: `Assemblage opgeslagen`,
  [`Assembly burst limits`]: `Burstlimieten voor assemblage`,
  [`Assembly count limits`]: `Limieten voor aantal assemblages`,
  [`Assign roles`]: `Rollen toewijzen`,
  [`Assign roles to the current owner`]: `Rollen toewijzen aan de huidige eigenaar`,
  [`Associate`]: `Koppelen`,
  [`Associate analytics service`]: `Analyseservice koppelen`,
  [`Associate analytics`]: `Analyse koppelen`,
  [`Assumed size`]: `Aangenomen grootte`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Autheticatiefout bij het ophalen van API van URL {url}. Controleer gebruikersnaam en wachtwoord.`,
  [`Audience claim`]: `Claim doelgroep`,
  [`Audience Claim`]: `Audience-claim`,
  [`Audit setting`]: `Auditinstelling`,
  [`Audit setting has been changed`]: `Auditinstelling is gewijzigd`,
  [`Auditing is **{mode}**`]: `Auditing is **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `Auditing wordt gebruikt voor het bewaken van API-aanroepen en het vastleggen van informatie over de aanroepende gebruikers, het tijdstip van elke aanroep en de activiteit die bij elke event betrokken is.`,
  [`Authenticate Client Method`]: `Methode voor verifiëren client`,
  [`Authenticate password`]: `Wachtwoord verifiëren`,
  [`Authenticate user`]: `Gebruiker verifiëren`,
  [`Authenticate user settings`]: `Gebruikersinstellingen verifiëren`,
  [`Authenticate application users using`]: `Gebruikers van toepassing verifiëren met behulp van`,
  [`Authenticate using Basic Authentication`]: `Verifiëren met behulp van standaardverificatie`,
  [`Authenticate using OAuth`]: `Verifiëren met behulp van OAuth`,
  [`Authenticated`]: `Geverifieerd`,
  [`Authenticated bind`]: `Geverifieerde bind`,
  [`Authenticated User Name`]: `Geverifieerde gebruikersnaam`,
  [`Authentication`]: `Verificatie`,
  [`Authentication Bind`]: `Verificatiebind`,
  [`Authentication error`]: `Verificatiefout`,
  [`Authentication method`]: `Verificatiemethode`,
  [`Authentication TLS profile`]: `Verificatie van TLS-profiel`,
  [`Authentication URL`]: `Verificatie-URL`,
  [`Authentication URL user registry`]: `Gebruikersregister verificatie-URL`,
  [`Authentication URL user registry has been created.`]: `Gebruikersregister voor verificatie-URL is gemaakt.`,
  [`Authentication URL to use for validation.`]: `Voor validatie te gebruiken verificatie-URL.`,
  [`Authentication response header credential`]: `Legitimatiegegevens voor verificatie van responsheader`,
  [`Authentication response header pattern`]: `Patroon voor verificatie van responsheader`,
  [`Authentication type`]: `Type verificatie`,
  [`Authentication type to use to validate the UsernameToken.`]: `Te gebruiken verificatietype voor validatie van het gebruikersnaamtoken.`,
  [`Authorization`]: `Autorisatie`,
  [`Authorization Code`]: `Autorisatiecode`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `URL voor autorisatie moet overeenkomen met {endpoint} zoals gedefinieerd door OAuth-provider "{providerTitle}"`,
  [`Authorization endpoint`]: `Eindpunt voor autorisatie`,
  [`Authorization Request`]: `Autorisatieaanvraag`,
  [`Authorization URL`]: `Autorisatie-URL`,
  [`Authorize`]: `Machtigen`,
  [`Authorize User Settings`]: `Gebruikersinstellingen autoriseren`,
  [`Authorize application users using`]: `Gebruikers van toepassing autoriseren met behulp van`,
  [`Authorize path`]: `Autorisatiepad`,
  [`Authors API and product definitions`]: `Schrijft API- en productdefinities`,
  [`Auto Generate OIDC API Assembly`]: `OIDC API-assemblage automatisch genereren`,
  [`Auto onboard`]: `Automatisch onboarden`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Automatisch gegenereerde naam voor gebruik in de API Connect-API's en opdrachten.`,
  [`Automation`]: `Automatisering`,
  [`Automation Management Console`]: `Automatiseringsbeheerconsole`,
  [`Automation management console`]: `Automatiseringsbeheerconsole`,
  [`Availability Zone`]: `Beschikbaarheidszone`,
  [`Availability zone`]: `Beschikbaarheidszone`,
  [`Availability zone {arg} has been removed.`]: `Beschikbaarheidszone {arg} is verwijderd.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Met beschikbaarheidszones kunt u API Connect-services groeperen op uw bedrijfsbehoeften. U kunt bijvoorbeeld gatewayservices groeperen aan de hand van de regio of het datacenter waarin ze zich bevinden.`,
  [`Average response time: {{value}}ms`]: `Gemiddelde responstijd: {{value}}ms`,
  [`Average time`]: `Gemiddelde tijd`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `CORS-fouten vermijden door de API Designer-server te gebruiken als een lokale proxy.`,
  [`Back`]: `Terug`,
  [`Back to sign in`]: `Terug naar Aanmelden`,
  [`Back to test`]: `Terug naar test`,
  [`Backend type`]: `Type backend`,
  [`Bad gateway`]: `Foutieve gateway`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Basis-DN`,
  [`Base Path`]: `Basispad`,
  [`Base path`]: `Basispad`,
  [`Base endpoint`]: `Basiseindpunt`,
  [`Base endpoints`]: `Basiseindpunten`,
  [`Base64 encoding`]: `Base64-codering`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `Base64 -codering voor de resettokens voor uitnodigingen en wachtwoorden is **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Base64-coderingsinstelling voor tijdelijk token is gewijzigd`,
  [`Base URL of API invocation endpoint`]: `Basis-URL van eindpunt van API-aanroep`,
  [`Basepath root`]: `Root van basispad`,
  [`Basic`]: `Standaard`,
  [`Basic authentication`]: `Standaardverificatie`,
  [`Basic authentication password`]: `Wachtwoord voor standaardverificatie`,
  [`Basic authentication request header name`]: `Naam aanvraagheader voor standaardverificatie`,
  [`Basic authentication username`]: `Gebruikersnaam voor standaardverificatie`,
  [`Before you begin`]: `Voordat u begint`,
  [`Before you begin...`]: `Voordat u begint ...`,
  [`Between 0 and 10 events are waiting to be processed`]: `Er wachten 0 tot 10 events op verwerking`,
  [`Between 10 and 20 events are waiting to be processed`]: `Er wachten 10 tot 20 events op verwerking`,
  [`Billing`]: `Facturering`,
  [`Billing and Payment`]: `Facturering en betaling`,
  [`Billing has been updated`]: `Facturering is bijgewerkt`,
  [`Billing integrations`]: `Factureringsintegraties`,
  [`Billing integration`]: `Integratie van facturering`,
  [`Billing Integration`]: `Integratie van facturering`,
  [`Billing {title} successfully added.`]: `Facturering {title} is toegevoegd.`,
  [`Billing {title} successfully deleted.`]: `Facturering {title} is gewist.`,
  [`Billing {title} successfully updated.`]: `Facturering {title} is bijgewerkt.`,
  [`Billing Options`]: `Factureringsopties`,
  [`Blocklist`]: `Blokkeerlijst`,
  [`Blocking`]: `Blokkering`,
  [`Blocking option`]: `Blokkeringsoptie`,
  [`Body`]: `Tekst`,
  [`Boolean`]: `Booleaans`,
  [`Both`]: `Beide`,
  [`Browse`]: `Bladeren`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Bufferen van API-aanvragen en -antwoorden voordat ze worden verwerkt op de DataPower API Gateway.`,
  [`Buffering`]: `Bufferen`,
  [`Builds and manages apps in the developer organization`]: `Bouwt en beheert apps in de ontwikkelaarsorganisatie`,
  [`Burst Limit Name`]: `Naam burstlimiet`,
  [`Burst limits`]: `Burstlimieten`,
  [`Burst limit value can't be negative`]: `Waarde van burstlimiet kan niet negatief zijn`,
  [`Buy`]: `Kopen`,
  [`but unable to remove because of limitation`]: `maar kan niet worden verwijderd vanwege beperking`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Door de zichtbaarheid en de abonnementsmogelijkheden van een product te configureren, bepaalt u in hoeverre de API's van dat product beschikbaar zijn voor toepassingenontwikkelaars in de ontwikkelaarsportal. U kunt bepalen welke toepassingsontwikkelaars het product kunnen zien en welke ontwikkelaars zich kunnen abonneren op het gebruik van de API's in het product.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Door de zichtbaarheid en de abonnementsmogelijkheden van een product te configureren, bepaalt u in hoeverre de API's van dat product beschikbaar zijn voor toepassingenontwikkelaars in de ontwikkelaarsportal. U kunt bepalen welke toepassingsontwikkelaars het product kunnen zien en welke ontwikkelaars zich kunnen abonneren op het gebruik van de API's in het product.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Toewijzing vanuit meerdere bronnen (bijv. array1 met lengte 2 en array2 met lengte 3) leidt standaard tot een doelarray met 5 items (2 door verwerking van array1 en 3 door verwerking van array2). Als u liever wilt dat deze regels worden gecombineerd (waardoor er een array ontstaat met 2 of 3 items, met een combinatie van eigenschappen uit elke bronarray), selecteer dan deze optie.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Standaard wordt dit product naar alle relevante gatewayservices gepubliceerd. Met deze optie kunt u ervoor zorgen dat het product alleen naar bepaalde gatewayservices wordt gepubliceerd.`,
  [`By payload`]: `Per nettolading`,
  [`By URL parameter`]: `Per URL-parameter`,
  [`Bytes`]: `Bytes`,
  [`CALLS`]: `AANROEPEN`,
  [`Calls`]: `Aanroepen`,
  [`CATALOG`]: `CATALOGUS`,
  [`CERTIFICATE NAME`]: `CERTIFICAATNAAM`,
  [`CIPHER`]: `CIPHER`,
  [`CIPHER SUITES`]: `CIPHER-SUITES`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Alleen CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `Client-ID`,
  [`CLOUD ADMINISTRATOR`]: `CLOUDBEHEERDER`,
  [`Consumer organization`]: `Consumentorganisatie`,
  [`CONSUMER ORGANIZATION / GROUP`]: `CONSUMENTORGANISATIE / GROEP`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `CA-bundel`,
  [`Cache Key`]: `Cachesleutel`,
  [`Cache Time to Live (second)`]: `Cachetijd naar Live (seconden)`,
  [`Cache key`]: `Cachesleutel`,
  [`Cache Time-To-Live`]: `Levensduur cache`,
  [`Cache type`]: `Type cache`,
  [`Can't find the one you want?`]: `Kunt u niet vinden wat u zoekt?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Uitnodiging kan niet worden verzonden met ongeldige mailserver. Controleer de mailserverconfiguraties en probeer het opnieuw`,
  [`Cancel`]: `Annuleren`,
  [`Cannot be below {min}`]: `Mag niet minder zijn dan {min}`,
  [`Cannot be empty`]: `Mag niet leeg zijn`,
  [`Cannot delete root type.`]: `Root-type kan niet worden gewist.`,
  [`Cannot exceed {max}`]: `Mag niet meer zijn dan {max}`,
  [`Cannot find any APIs for this product`]: `Geen API's gevonden voor dit product`,
  [`Cannot find the one you want?`]: `Kunt u niet vinden wat u zoekt?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Instructie "@listSize" kan niet worden gebruikt voor velden die geen lijst retourneren wanneer "sizedFields" niet ook gedefinieerd is.`,
  [`Cannot remove built-in scalar types`]: `Ingebouwde scalaire typen kunnen niet worden verwijderd`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Rootbewerkingstype voor query, mutatie en abonnement kan niet worden verwijderd`,
  [`Cannot remove arguments of built-in directives`]: `Argumenten van ingebouwde instructies kunnen niet worden verwijderd`,
  [`Cannot remove non-null arguments of directives`]: `Niet-null argumenten van instructies kunnen niet worden verwijderd`,
  [`Cannot remove input types of arguments of directives`]: `Invoertypen van argumenten van instructies kunnen niet worden verwijderd`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Enumwaarden die worden gebruikt als standaardwaarden voor argumenten van instructies kunnen niet worden verwijderd`,
  [`Cannot remove enum values used as default values of input fields`]: `Enumwaarden die worden gebruikt als standaardwaarden voor invoervelden kunnen niet worden verwijderd`,
  [`Cannot remove all fields of an object type`]: `Alle velden van een objecttype kunnen niet worden verwijderd`,
  [`Cannot remove all fields of an interface type`]: `Alle velden van een interfacetype kunnen niet worden verwijderd`,
  [`Cannot remove all slicing arguments`]: `Alle segmenteringsargumenten kunnen niet worden verwijderd`,
  [`Cannot remove all input fields of an input object type`]: `Alle invoervelden van een invoerobjecttype kunnen niet worden verwijderd`,
  [`Cannot remove all values of enum type`]: `Alle waarden van enumtype kunnen niet worden verwijderd`,
  [`Cannot remove fields of interfaces`]: `Velden van interfaces kunnen niet worden verwijderd`,
  [`Cannot remove non-null input fields`]: `Niet-null invoervelden kunnen niet worden verwijderd`,
  [`Case`]: `Hoofdlettergebruik`,
  [`Case sensitive`]: `Hoofdlettergevoelig`,
  [`Catalog`]: `Catalogus`,
  [`Catalog defaults`]: `Standaardwaarden voor catalogus`,
  [`Catalog Invite`]: `Catalogusuitnodiging`,
  [`Catalog invite`]: `Uitnodiging voor catalogus`,
  [`Catalog invites`]: `Catalogusuitnodigingen`,
  [`Catalog name`]: `Catalogusnaam`,
  [`Catalog owner`]: `Eigenaar van catalogus`,
  [`Catalog owner invitation`]: `Uitnodiging voor cataloguseigenaar`,
  [`Catalog properties`]: `Eigenschappen van catalogus`,
  [`Catalog summary`]: `Catalogusoverzicht`,
  [`Catalog title`]: `Catalogustitel`,
  [`Catalog user registries`]: `Catalogusgebruikersregisters`,
  [`Catalog by Name`]: `Catalogus op naam`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Prioriteit voor catalogus (de door de catalogusbeheerder gedefinieerde Vanity-eindpunt(en) gebruiken)`,
  [`Catalog {name} created`]: `Catalogus {name} is gemaakt`,
  [`Catalog selection`]: `Catalogusselectie`,
  [`Catalog settings`]: `Catalogusinstellingen`,
  [`Catalog settings have been updated`]: `Instellingen voor catalogus zijn bijgewerkt`,
  [`Catalog User`]: `Catalogusgebruiker`,
  [`Catalog Users`]: `Catalogusgebruikers`,
  [`Catalog user registry`]: `Catalogusgebruikersregister`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `Catalogus met LifeCycle ingeschakeld kan niet worden gebruikt in testvoorkeuren.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Catalogus met levenscyclus en productiewerkstand ingeschakeld kan niet worden gebruikt in Testvoorkeuren.`,
  [`Catalogs`]: `Catalogi`,
  [`Catch`]: `Catch`,
  [`Catches`]: `Catches`,
  [`Categories`]: `Categorieën`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Categorieën zorgen ervoor dat de API-producten in de ontwikkelaarsportal op een hiërarchische manier worden afgebeeld. Gebruik de volgende syntaxis: element_van_hoogste_niveau/element_van_volgend_niveau/element_van_lager_niveau`,
  [`Category`]: `Categorie`,
  [`Certifcate or Shared Secret for Verify`]: `Certificaat of gemeenschappelijk geheim voor controleren`,
  [`Certificate`]: `Certificaat`,
  [`Certificate (Optional)`]: `Certificaat (optioneel)`,
  [`Certificate management`]: `Certificaatbeheer`,
  [`Certificate Manager instance`]: `Instance van certificaatbeheer`,
  [`Certificate (optional)`]: `Certificaat (optioneel)`,
  [`Certificates`]: `Certificaten`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Certificaten zijn vereist om de verbindingen tussen de gateway en de domeinen te beveiligen. Alle certificaten moeten worden opgeslagen in een certificaatbeheerservice. De service biedt een beveiligde repository voor de certificaten en helpt storingen te voorkomen door u meldingen te sturen wanneer de certificaten op het punt staan te vervallen. U gaat als volgt aan de slag,`,
  [`Certificates details in this keystore`]: `Details van de certificaten in deze keystore`,
  [`Certificates details in this truststore`]: `Details van de certificaten in deze truststore`,
  [`Change`]: `Wijzigen`,
  [`Change Consumer Organization owner to an existing user`]: `Eigenaar van consumentorganisatie wijzigen in een bestaande gebruiker`,
  [`Change Ownership to an Existing User`]: `Eigendom overdragen op een bestaande gebruiker`,
  [`Change portal service`]: `Portalservice wijzigen`,
  [`Change owner to existing user`]: `Eigenaar wijzigen in bestaande gebruiker`,
  [`Change owner to new user`]: `Eigenaar wijzigen in nieuwe gebruiker`,
  [`Change ownership by inviting a new user`]: `Eigendom wijzigen door een nieuwe gebruiker uit te nodigen`,
  [`Change ownership to`]: `Eigendom wijzigen in`,
  [`Change ownership to a new user via email invitation`]: `Eigendom via uitnodigingsmail wijzigen in een nieuwe gebruiker`,
  [`Change ownership to an existing user`]: `Eigendom wijzigen in een bestaande gebruiker`,
  [`Change password`]: `Wachtwoord wijzigen`,
  [`Change setup`]: `Instelling wijzigen`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Wijzigingen die u rechtstreeks aanbrengt in een API of assemblage die ten grondslag ligt aan een OAuth-provider kunnen ervoor zorgen dat die OAuth-provider ongeldig wordt als u niet ook de overeenkomstige waarden in de definitie van de OAuth-provider zelf wijzigt. Als u deze wijzigingen opslaat, vergeet dan niet om de overeenkomstige updates aan te brengen in de definitie van de OAuth-provider.`,
  [`Change the following extra constraints in the schema:`]: `Wijzig de volgende extra beperkende voorwaarden in het schema:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `Als u de Certificate Manager wijzigt, worden alle bestaande certificaten en CA-bundeltoewijzingen verwijderd.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Als u het DNS-schema wijzigt, wordt de notatie van de URL-links naar API Connect en de Developer Portal gewijzigd. U moet de nieuwe links doorgeven aan de gebruikers. Klik op Annuleren als u niet verder wilt gaan.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Als u de naam of versie van een API wijzigt, wordt er een nieuwe API gemaakt. Weet u zeker dat u uw wijzigingen wilt opslaan?`,
  [`Check your email`]: `Controleer uw e-mail`,
  [`Child`]: `Onderliggend item`,
  [`Chinese (Simplified)`]: `Chinees (vereenvoudigd)`,
  [`Chinese (Traditional)`]: `Chinees (traditioneel)`,
  [`Choose`]: `Kiezen`,
  [`Choose a`]: `Kies een`,
  [`Choose {docTypeLabel}`]: `Kies {docTypeLabel}`,
  [`Choose Catalog`]: `Catalogus kiezen`,
  [`Choose a Catalog`]: `Kies een catalogus`,
  [`Choose a Consumer Org`]: `Kies een Consumer Org`,
  [`Choose Gateway`]: `Gateway kiezen`,
  [`Choose a Gateway`]: `Kies een gateway`,
  [`Choose Product`]: `Product kiezen`,
  [`Choose a Product`]: `Kies een product`,
  [`Choose Rate Limit`]: `Gegevenssnelheidslimiet kiezen`,
  [`Choose a Rate Limit`]: `Kies een gegevenssnelheidslimiet`,
  [`Choose Application`]: `Toepassing kiezen`,
  [`Choose an Application`]: `Toepassing kiezen`,
  [`Choose a {docTypeLabel}`]: `Kies een {docTypeLabel}`,
  [`Choose a publish destination`]: `Kies een publicatiebestemming`,
  [`Choose a billing integration`]: `Kies een factureringsintegratie`,
  [`Choose a catalog to test within:`]: `Kies een catalogus waarin u wilt testen:`,
  [`Choose a plan against which to test:`]: `Kies het plan op basis waarvan u wilt testen:`,
  [`Choose a product containing this API, or create a new one:`]: `Kies een product met deze API, of maak een nieuwe:`,
  [`Choose a user registry`]: `Gebruikersregister kiezen`,
  [`Choose an application with which to test, or create a new one:`]: `Kies een toepassing waarmee getest wordt, of maak een nieuwe:`,
  [`Choose an existing application`]: `Kies een bestaande toepassing`,
  [`Choose an existing catalog`]: `Kies een bestaande catalogus`,
  [`Choose an existing product published to your Sandbox catalog`]: `Kies een bestaand product dat naar uw sandboxcatalogus is gepubliceerd`,
  [`Choose an operation to invoke:`]: `Kies een bewerking om op te roepen:`,
  [`Choose an option`]: `Kies een optie`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Kies een optie om te bepalen hoe het toewijzingsbeleid omgaat met uitvoer van lege arrays. Als de optie Alle wordt gekozen (de standaardkeuze), worden alle lege arrays en lege onderliggende arrays uitgevoerd. Als Bovenliggend item wordt gekozen, wordt alleen de bovenliggende lege array uitgevoerd. Als Geen wordt gekozen, wordt de lege array niet uitgevoerd.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Het kiezen van de blokkeringsmodus heeft negatieve gevolgen voor de systeemperformance`,
  [`Choose existing directory`]: `Bestaande directory kiezen`,
  [`Choose one...`]: `Kies een item...`,
  [`Choose operations and paths to generate into this API`]: `Kies de bewerkingen en paden die in deze API moeten worden gegenereerd`,
  [`Choose paths to generate into this API`]: `Kies de paden die in deze API moeten worden gegenereerd`,
  [`Choose the DNS scheme`]: `Kies het DNS-schema`,
  [`Choose the permissions for the role.`]: `Kies de machtigingen voor de rol.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Geef aan of u dynamische of statische DNS-adressen wilt gebruiken voor inkomende API-aanroepen van de Gatewayservice en voor het verkrijgen van toegang tot de ontwikkelaarsportal.`,
  [`Cipher`]: `Cipher`,
  [`Ciphers`]: `Ciphers`,
  [`Clear`]: `Leegmaken`,
  [`Clear constraints`]: `Beperkende voorwaarden verwijderen`,
  [`Clear file`]: `Bestand leegmaken`,
  [`Clear filters`]: `Filters wissen`,
  [`Clear from selected`]: `Verwijderen uit geselecteerd`,
  [`Clear operation filter`]: `Bewerkingsfilter wissen`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Klik op een certificaat om de vingerafdruk en details te bekijken met betrekking tot de organisatie die het certificaat heeft uitgegeven.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Klik op de onderstaande link om door te gaan met het autoriseren van API's op een ander tabblad. U krijgt dan de autorisatiecode voor stap 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Als u op de onderstaande link klikt, wordt de server op een nieuw tabblad geopend. Als de browser aangeeft dat er een certificaatprobleem is, kunt u dit accepteren en hier terugkomen om nogmaals te testen.`,
  [`Click add to add a billing integration.`]: `Klik op Toevoegen om een factureringsintegratie toe te voegen.`,
  [`Click Add to add a gateway extension.`]: `Klik op Toevoegen om een gatewayextensie toe te voegen.`,
  [`Click **Add domain** to add domains.`]: `Klik op **Domein toevoegen** om domeinen toe te voegen.`,
  [`Click Add to add a new base endpoint.`]: `Klik op Toevoegen om een nieuw basiseindpunt toe te voegen.`,
  [`Click Add to add a new HTTP header.`]: `Klik op Toevoegen om een nieuwe HTTP-header toe te voegen.`,
  [`Click Add to add a new member.`]: `Klik op Toevoegen om een nieuw lid toe te voegen.`,
  [`Click Add to add a new role.`]: `Klik op Toevoegen om een nieuwe rol toe te voegen.`,
  [`Click Add to add a new scope.`]: `Klik op Toevoegen om een nieuw bereik toe te voegen.`,
  [`Click Add to add a new consumer organization group.`]: `Klik op Toevoegen om een nieuwe groep voor consumentenorganisaties toe te voegen.`,
  [`Click Add to create the API Key.`]: `Klik op Toevoegen om de API-sleutel te maken.`,
  [`Click Create to add the API Key.`]: `Klik op Maken om de API-sleutel toe te voegen.`,
  [`Click Create to add a new user registry.`]: `Klik op Maken om een nieuw gebruikersregister toe te voegen.`,
  [`Click Create user registry to add a new user registry.`]: `Klik op Gebruikersregister maken om een nieuw gebruikersregister toe te voegen.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Klik op **Provider organisatie maken** om providerorganisaties toe te voegen.`,
  [`Click Edit to add OAuth providers.`]: `Klik op Bewerken om OAuth-providers toe te voegen.`,
  [`Click Edit to add a user registry.`]: `Klik op Bewerken om een gebruikersregister toe te voegen.`,
  [`Click Edit to add an API to the product.`]: `Klik op Bewerken om een API aan het product toe te voegen.`,
  [`Click Create to add a property.`]: `Klik op Maken om een eigenschap toe te voegen.`,
  [`Click Edit to add TLS client profiles.`]: `Klik op Bewerken om TLS-clientprofielen toe te voegen.`,
  [`Click Edit to add user registries.`]: `Klik op Bewerken om gebruikersregisters toe te voegen.`,
  [`Click Add to add a new consumer organization.`]: `Klik op Toevoegen om een nieuwe consumentorganisatie toe te voegen.`,
  [`Click Consumer organizations to add one.`]: `Klik op Consumentorganisaties om er een toe te voegen.`,
  [`Click Add to add a new application.`]: `Klik op Toevoegen om een nieuwe toepassing toe te voegen.`,
  [`Click Add to add parameters.`]: `Klik op Toevoegen om parameters toe te voegen.`,
  [`Click Add to add response.`]: `Klik op Toevoegen om een respons toe te voegen.`,
  [`Click Create to add a new TLS client profile.`]: `Klik op Maken om een nieuw TLS-clientprofiel toe te voegen.`,
  [`Click Create to add a new keystore.`]: `Klik op Maken om een nieuwe keystore toe te voegen.`,
  [`Click Create to add a new truststore.`]: `Klik op Maken om een nieuwe truststore toe te voegen.`,
  [`Click **Register remote gateway** to add gateways.`]: `Klik op **Remote gateway registreren** om gateways toe te voegen.`,
  [`Click Register gateways to add new Gateway Services.`]: `Klik op Gateways registreren om nieuwe gatewayservices toe te voegen.`,
  [`Click Edit to add Gateway Services.`]: `Klik op Bewerken om gatewayservices toe te voegen.`,
  [`Click Register gateways to add new gateway services.`]: `Klik op Gateways registreren om nieuwe gatewayservices toe te voegen.`,
  [`Click Edit to add gateway services.`]: `Klik op Bewerken om gatewayservices toe te voegen.`,
  [`Click Edit to add an email server.`]: `Klik op Bewerken om een e-mailserver toe te voegen.`,
  [`Click Create to add a new TLS server profile.`]: `Klik op Maken om een nieuw TLS-serverprofiel toe te voegen.`,
  [`Click Create to add a new email server.`]: `Klik op Maken om een nieuwe e-mailserver toe te voegen.`,
  [`Click Add to add a new schema definition.`]: `Klik op Toevoegen om een nieuwe schemadefinitie toe te voegen.`,
  [`Click Add to add a new property for this definition.`]: `Klik op Toevoegen om een nieuwe eigenschap voor deze definitie toe te voegen.`,
  [`Click Add to add a new parameter for this API.`]: `Klik op Toevoegen om een nieuwe parameter voor deze API toe te voegen.`,
  [`Click Add to add tags and external documentation for this API.`]: `Klik op Toevoegen om tags en externe documentatie voor deze API toe te voegen.`,
  [`Click Add to add a new path.`]: `Klik op Toevoegen om een nieuw pad toe te voegen.`,
  [`Click Add to add a property for this API.`]: `Klik op Toevoegen om een eigenschap voor deze API toe te voegen.`,
  [`Click here to create a security definition`]: `Klik hier om een beveiligingsdefinitie te maken`,
  [`Click Add to add a new security definition.`]: `Klik op Toevoegen om een nieuwe beveiligingsdefinitie toe te voegen.`,
  [`Click Add to add a target service for this API.`]: `Klik op Toevoegen om een doelservice voor deze API toe te voegen.`,
  [`Click Add to add an operation for this path.`]: `Klik op Toevoegen om een bewerking voor dit pad toe te voegen.`,
  [`Click Add to add a path parameter for this path.`]: `Klik op Toevoegen om een padparameter voor dit pad toe te voegen.`,
  [`Click Add to add a new OAuth provider.`]: `Klik op Toevoegen om een nieuwe OAuth-provider toe te voegen.`,
  [`Click Add to add a new provider organization.`]: `Klik op Toevoegen om een nieuwe provideorganisatie toe te voegen.`,
  [`Click Add to add a new API or product.`]: `Klik op Toevoegen om een nieuwe API of een nieuw product toe te voegen.`,
  [`Click Add to add a new API.`]: `Klik op Toevoegen om een nieuwe API toe te voegen.`,
  [`Click Add to add a new Extension.`]: `Klik op Toevoegen om een nieuwe extensie toe te voegen.`,
  [`Click Add to add new {resource}.`]: `Klik op Toevoegen om nieuw(e) {resource} toe te voegen.`,
  [`Click to expand`]: `Klik om uit te vouwen`,
  [`Click to collapse`]: `Klik om samen te vouwen`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Klik om informatie te krijgen over het uploaden en beheren van de certificaten die door de gateway vereist worden.`,
  [`Client Credentials`]: `Legitimatiegegevens van klant`,
  [`Client information`]: `Clientinformatie`,
  [`Client revocation path`]: `Intrekkingspad client`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Klik op Service registreren om een service te registreren. Het wordt dan mogelijk om de eindpunten te testen wanneer u andere services configureert.`,
  [`Client secret`]: `Clientgeheim`,
  [`Client security`]: `Clientbeveiliging`,
  [`Client Type`]: `Clienttype`,
  [`Client authentication method`]: `Clientverificatiemethode`,
  [`Clone`]: `Klonen`,
  [`Close`]: `Sluiten`,
  [`Cloud`]: `Cloud`,
  [`Cloud Admin`]: `Cloudbeheerder`,
  [`Cloud Administrator`]: `Cloudbeheerder`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Cloudbeheerder, Organisatiemanager, Topologiebeheerder. De rol van Lid wordt automatisch toegewezen aan alle gebruikers.`,
  [`Cloud administration`]: `Cloudbeheer`,
  [`Cloud Discovery Agent`]: `Cloud Discovery Agent`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Lokaal gebruikersregister van Cloud Manager`,
  [`Cloud-Settings`]: `Cloud-instellingen`,
  [`Cloud settings invitation timeout`]: `Timeout voor uitnodiging voor cloudinstellingen`,
  [`Cloud settings invitation timeout has been changed`]: `Timeout voor uitnodiging voor cloudinstellingen is gewijzigd`,
  [`Code editor`]: `Code-editor`,
  [`Collect Metadata`]: `Metagegevens verzamelen`,
  [`Collect credentials using`]: `Legitimatiegegevens verzamelen met behulp van`,
  [`Collect metadata`]: `Metagegevens verzamelen`,
  [`Combo box`]: `Keuzelijst met invoerveld`,
  [`Community Manager`]: `Communitymanager`,
  [`community-manager`]: `community-manager`,
  [`Compatibility`]: `Compatibiliteit`,
  [`Complex Definitions must be edited in the editor`]: `Complexe definities moeten in de editor worden bewerkt`,
  [`Compose DN`]: `DN samenstellen`,
  [`Compose UPN`]: `UPN samenstellen`,
  [`Compose a new REST proxy by defining paths and operations`]: `Nieuwe REST-proxy samenstellen door paden en bewerkingen te definiëren`,
  [`Compose a new product by adding rate limits and plans`]: `Nieuw product samenstellen door het toevoegen van aanvraaglimieten en plannen`,
  [`Compression`]: `Compressie`,
  [`Condition`]: `Voorwaarde`,
  [`Condition editor`]: `Voorwaarde-editor`,
  [`Confidential`]: `Vertrouwelijk`,
  [`Configuration`]: `Configuratie`,
  [`Configurations`]: `Configuraties`,
  [`Configure API endpoint`]: `API-eindpunt configureren`,
  [`Configure API Gateway Service`]: `API-gatewayservice configureren`,
  [`Configure analytics service`]: `Analyseservice configureren`,
  [`Configure availability zone`]: `Beschikbaarheidszone configureren`,
  [`Configure catalog user registries`]: `Catalogusgebruikersregisters configureren`,
  [`Configure cloud`]: `Cloud configureren`,
  [`Configure DataPower API gateway service`]: `DataPower API-gatewayservice configureren`,
  [`Configure DataPower gateway service`]: `DataPower-gatewayservice configureren`,
  [`Configure Email Server`]: `E-mailserver configureren`,
  [`Configure gateway extension`]: `Gatewayextensie configureren`,
  [`Configure portal service`]: `Portalservice configureren`,
  [`Configure service`]: `Service configureren`,
  [`Configure topology`]: `Topologie configureren`,
  [`Configure XML`]: `XML configureren`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Configureer een DataPower API-gatewayservice voor het beveiligen en afdwingen van API's`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Configureer een DataPower Gateway-service voor het beveiligen en afdwingen van API's`,
  [`Configure a developer portal service for API consumers`]: `Configureer een Developer Portal-service voor API-consumenten`,
  [`Configure advanced features for OIDC settings`]: `Geavanceerde functies voor OIDC-instellingen configureren`,
  [`Configure an analytics service to collect API call data`]: `Configureer een analyseservice voor het verzamelen van gegevens over API-aanroepen`,
  [`Configure an email server to send invitations and notifications to users.`]: `Configureer een e-mailserver voor het verzenden van uitnodigingen en meldingen aan gebruikers.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Auditing configureren voor het bewaken van de bewerkingen van Admin UI, Manager UI, Toolkit, Management REST API en Portal-gebruikers. Logboeken van elke gebruikersbewerking voor maken, bijwerken en wissen worden verzonden naar de hieronder opgegeven services voor logboekregistratie op afstand.`,
  [`Configure availability zones and services`]: `Configureer beschikbaarheidszones en services`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Configureer hoe de URL's van API-eindpunten worden samengesteld in gepubliceerde API's`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Geef op hoe Vanity-eindpunten moeten worden afgebeeld in de ontwikkelaarsportal`,
  [`Configure mapping`]: `Toewijzing configureren`,
  [`Configure properties of the activity log`]: `Eigenschappen van het activiteitenlogboek configureren`,
  [`Configure settings for token management and revocation.`]: `Instellingen configureren voor het beheren en intrekken van tokens.`,
  [`Configure settings to generate and validate tokens.`]: `Configureer instellingen voor het genereren en valideren van tokens.`,
  [`Configure the API security`]: `Configureer de API-beveiliging`,
  [`Configure the default set of gateway services configured for each catalog`]: `Configureer de standaardset van gatewayservices die voor elke catalogus geconfigureerd zijn`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Configureer de ontwikkelaarsportal die door toepassingsontwikkelaars wordt gebruikt voor het verkrijgen van toegang tot de API's in deze catalogus`,
  [`Configure the gateway services used by default in each catalog`]: `Configureer de standaardservices die standaard in elke catalogus worden gebruikt`,
  [`Configure the gateway extension for the selected gateway`]: `Configureer de gatewayextensie voor de geselecteerde gateway`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Configureer de door IBM beheerde DataPower API-gateway en beheer uw eigen gateways op afstand. [Meer informatie]({link})`,
  [`Configure the keystore and upload certificates`]: `Configureer de keystore en upload certificaten`,
  [`Configure the name and email address to be used in the from field of emails`]: `Configureer de naam en het e-mailadres. Deze gegevens worden gebruikt in het Van-veld in e-mails.`,
  [`Configure the portal service for the catalog`]: `Configureer de portalservice voor de catalogus`,
  [`Configure the role and assign permissions`]: `De rol configureren en machtigingen toewijzen`,
  [`Configure the security of this API`]: `Beveiliging van deze API configureren`,
  [`Configure the sender name and address to use for email notifications`]: `Configureer de naam en het adres van de afzender. Deze gegevens worden gebruikt voor e-mailmeldingen`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Configureer de naam van de afzender, het adres en de e-mailserver die wordt gebruikt voor het verzenden van uitnodigingen en meldingen aan gebruikers`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Configureer de set rollen die standaard moet worden gebruikt als er een consumentorganisatie wordt gemaakt`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Configureer de set rollen die standaard moet worden gebruikt als er een providerorganisatie wordt gemaakt`,
  [`Configure the templates used to invite and notify users`]: `Configureer de sjablonen die worden gebruikt om gebruikers uit te nodigen en te waarschuwen`,
  [`Configure the truststore and upload certificates`]: `Configureer de truststore en upload certificaten`,
  [`Configure user authentication using`]: `Gebruikersverificatie configureren met behulp van`,
  [`Configure user authentication using JSON Web Tokens`]: `Gebruikersverificatie configureren met behulp van JSON-webtokens`,
  [`Configure user authentication using a LDAP provider`]: `Gebruikersverificatie met behulp van een LDAP-provider configureren`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Gebruikersverificatie met behulp van een lokaal gebruikersregister van API Connect configureren`,
  [`Configure user authentication using an authentication URL`]: `Gebruikersverificatie met behulp van een verificatie-URL configureren`,
  [`Configure user authentication using this user registry type`]: `Gebruikersverificatie met behulp van dit type gebruikersregister configureren`,
  [`Configure user registries, OAuth providers and TLS`]: `Gebruikersregisters, OAuth-providers en TLS configureren`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Gebruikersregisters, TLS, OAuth-providers en e-mailservers beheren`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Keystore configureren voor toegangstoken, ID-token en tijdelijk token`,
  [`Configured OAuth Provider`]: `Geconfigureerde OAuth-provider`,
  [`Configured OAuth Providers`]: `Geconfigureerde OAuth-providers`,
  [`Configured OAuth provider`]: `Geconfigureerde OAuth-provider`,
  [`Configured OAuth provider list has been updated`]: `Lijst van geconfigureerde OAuth-providers is bijgewerkt`,
  [`Configured OAuth provider list has not been updated`]: `De geconfigureerde OAuth-providerlijst is niet bijgewerkt`,
  [`Confirm`]: `Bevestigen`,
  [`Confirm deletion`]: `Wissen bevestigen`,
  [`Confirm Deletion of Portal`]: `Wissen van portal bevestigen`,
  [`Confirm visibility settings`]: `Zichtbaarheidsinstellingen bevestigen`,
  [`Confirm new password`]: `Nieuw wachtwoord bevestigen`,
  [`Confirm password`]: `Wachtwoord bevestigen`,
  [`Confirm password should match password`]: `Wachtwoord ter bevestiging moet gelijk zijn aan wachtwoord`,
  [`Confirmation`]: `Bevestiging`,
  [`Congratulations, you are now registered.`]: `Gefeliciteerd! U bent nu geregistreerd.`,
  [`Congratulations, your password has been reset!`]: `Het resetten van uw wachtwoord is gelukt!`,
  [`Connect`]: `Verbinding maken`,
  [`Connect to API Connect`]: `Verbinding maken met API Connect`,
  [`Connected to API Manager Product version`]: `Verbonden met API Manager-productversie`,
  [`Connect to Cloud`]: `Verbinden met Cloud`,
  [`Connected`]: `Verbonden`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Bouw uw schemadefinitie door eigenschappen van het juiste type toe te voegen. Het is mogelijk om eigenschappen te nesten`,
  [`Consider for nesting`]: `Overwegen voor nesten`,
  [`Consumed Media Types`]: `Geconsumeerde mediatypen`,
  [`Consumer`]: `Consument`,
  [`Consumer invitation and roles`]: `Uitnodiging en rollen voor consumenten`,
  [`Consumer onboarding`]: `Onboarding consument`,
  [`Consumer organizations`]: `Consumentorganisaties`,
  [`Consumer organizations or groups`]: `Consumentorganisaties of groepen`,
  [`Consumer organization {name} created`]: `Consumentorganisatie {name} is gemaakt`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Consumentorganisaties worden door toepassingsontwikkelaars gemaakt in de Developer Portal. Daarnaast kunt u consumentorganisaties maken met behulp van de gebruikersinterface van API Manager.`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Consumentorganisaties worden door toepassingsontwikkelaars gemaakt in de ontwikkelaarsportal. Daarnaast kunt u hier een consumentorganisatie maken`,
  [`Consumer-Org`]: `Consument-org`,
  [`Consumers`]: `Consumenten`,
  [`Consumes`]: `Consumeert`,
  [`Contact`]: `Contact`,
  [`Contact information for the owners of the API.`]: `Contactgegevens van de eigenaren van de API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Neem contact op met de beheerder voor het configureren van de analyseservice, zodat u uw gegevens hier kunt bekijken.`,
  [`Contact your administrator for more information.`]: `Neem voor meer informatie contact op met de beheerder.`,
  [`Content`]: `Inhoud`,
  [`Content on error`]: `Content bij fouten`,
  [`Content type`]: `Contenttype`,
  [`Context Variable`]: `Contextvariabele`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Context of runtime variabele die de te valideren JWT bevat. Als deze optie niet is ingesteld, zoekt het beleid naar de JWT in request.headers.authorization.`,
  [`Context variable`]: `Contextvariabele`,
  [`Continue`]: `Doorgaan`,
  [`Continue on error`]: `Doorgaan bij fouten`,
  [`Continue with`]: `Ga verder met`,
  [`Continue with:`]: `Doorgaan met:`,
  [`Conversion type`]: `Type conversie`,
  [`Convert to expression`]: `Converteren naar expressie`,
  [`Cookie`]: `Cookie`,
  [`Copied`]: `Gekopieerd`,
  [`Copied to clipboard`]: `Gekopieerd naar klembord`,
  [`Copy`]: `Kopiëren`,
  [`Copy Id Headers To Message`]: `ID-headers kopiëren naar bericht`,
  [`Copy to clipboard`]: `Kopiëren naar klembord`,
  [`Copy file path to clipboard`]: `Pad kopiëren naar klembord`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `De kostenschatting voor de GraphQL-query naar de uitvoer kopiëren`,
  [`Copy the endpoints below to`]: `Kopieer de onderstaande eindpunten om`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Kopieer de API-sleutel die u wilt gebruiken. U kunt deze API-sleutel opnieuw bekijken met het pictogram View.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Copyrightinformatie`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Corrigeer de fouten in de editor hierboven of upload een geldig bestand`,
  [`Cost`]: `Kosten`,
  [`Count`]: `Aantal`,
  [`Count Limit Name`]: `Naam aantallimiet`,
  [`Count Limits`]: `Aantallimieten`,
  [`Created at`]: `Gemaakt op`,
  [`Counter`]: `Teller`,
  [`Create`]: `Maken`,
  [`Create a [Certificate Manager]({link}) service`]: `Een [Certificate Manager]({link})-service maken`,
  [`Create API`]: `API maken`,
  [`Create an API from the Develop section.`]: `Een API maken in de sectie voor ontwikkeling.`,
  [`Create API Connect API Key`]: `API-sleutel voor API Connect maken`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `API maken op basis van bestaande GraphQL-service (GraphQL-proxy)`,
  [`Create API from existing OpenAPI service`]: `API maken op basis van bestaande OpenAPI-service`,
  [`Create API from existing WSDL service (REST proxy)`]: `API maken op basis van bestaande WSDL-service (REST-proxy)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `API maken op basis van bestaande WSDL-service (SOAP-proxy)`,
  [`Create API from target service`]: `API maken op basis van doelservice`,
  [`Create API from existing REST service`]: `API maken op basis van bestaande REST-service`,
  [`Create API from existing SOAP service`]: `API maken op basis van bestaande SOAP-service`,
  [`Create API from existing WSDL service`]: `API maken op basis van bestaande WSDL-service`,
  [`Create API Key failed`]: `Maken van API-sleutel mislukt`,
  [`Create application`]: `Toepassing maken`,
  [`Create authentication URL user registry`]: `Gebruikersregister voor verificatie-URL maken`,
  [`Create availability zone`]: `Beschikbaarheidszone maken`,
  [`Create Billing`]: `Facturering maken`,
  [`Create catalog`]: `Catalogus maken`,
  [`Create catalog property`]: `Cataloguseigenschap maken`,
  [`Create consumer organization`]: `Consumentorganisatie maken`,
  [`Create Credentials`]: `Legitimatiegegevens maken`,
  [`Create definition`]: `Definitie maken`,
  [`Create email server`]: `E-mailserver maken`,
  [`Create IBM Developer Portal`]: `IBM Developer Portal maken`,
  [`Create keystore`]: `Keystore maken`,
  [`Create LDAP user registry`]: `LDAP-gebruikersregister maken`,
  [`Create LTPA Key`]: `LTPA-sleutel maken`,
  [`Create local user registry`]: `Lokaal gebruikersregister maken`,
  [`Create native OAuth provider`]: `Native OAuth-provider maken`,
  [`Create New GraphQL API`]: `Nieuwe GraphQL API maken`,
  [`Create new OpenAPI`]: `Nieuwe OpenAPI maken`,
  [`Create new product`]: `Nieuw product maken`,
  [`Create OIDC user registry`]: `OIDC-gebruikersregister maken`,
  [`Create OpenAPI definition and product definition`]: `OpenAPI-definitie en productdefinitie maken`,
  [`Create operation`]: `Bewerking maken`,
  [`Create organization`]: `Organisatie maken`,
  [`Create path`]: `Pad maken`,
  [`Create plan`]: `Plan maken`,
  [`Create portal`]: `Portal maken`,
  [`Create Product`]: `Product maken`,
  [`Create property`]: `Eigenschap maken`,
  [`Create role`]: `Rol maken`,
  [`Create role default for the Organization`]: `Standaardwaarde voor rol maken voor organisatie`,
  [`Create sample user registry`]: `Voorbeeld van gebruikersregister maken`,
  [`Create security definition`]: `Beveiligingsdefinitie maken`,
  [`Create space`]: `Ruimte maken`,
  [`Create subscription`]: `Abonnement maken`,
  [`Create TLS client profile`]: `TLS-clientprofiel maken`,
  [`Create TLS server profile`]: `TLS-serverprofiel maken`,
  [`Create target service`]: `Doelservice maken`,
  [`Create third party OAuth provider`]: `Externe OAuth-provider maken`,
  [`Create truststore`]: `Truststore maken`,
  [`Create user registry`]: `Gebruikersregister maken`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Een GraphQL-proxy maken op basis van een GraphQL-service`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `REST-proxy maken op basis van een door OpenAPI beschreven doelservice`,
  [`Create a REST proxy based upon a WSDL described target service`]: `REST-proxy maken op basis van een met WSDL beschreven doelservice`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `REST-proxy maken op basis van een door OpenAPI beschreven doelservice`,
  [`Create a REST proxy based upon the WSDL described target service`]: `REST-proxy maken op basis van de door WSDL beschreven doelservice`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `REST-proxy maken die alle verkeer doorstuurt naar een doel-API of service-eindpunt`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `SOAP-proxy maken op basis van een met WSDL beschreven doelservice`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `SOAP-proxy maken op basis van de door WSDL beschreven doelservice`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Een nieuw object maken met behulp van een handvatsjabloon. Variabelen worden uit de context opgenomen.`,
  [`Create a portal site for the catalog`]: `Portalsite voor de catalogus maken`,
  [`Create a product by importing a product definition`]: `Een product maken door een productdefinitie te importeren`,
  [`Create a security definition`]: `Een beveiligingsdefinitie maken`,
  [`Create a subscription`]: `Een abonnement maken`,
  [`Create an API by importing an OpenAPI definition`]: `Een API maken door een OpenAPI-definitie te importeren`,
  [`Create an API that calls an existing SOAP service`]: `API maken die een bestaande SOAP-service aanroept`,
  [`Create and manage API provider organizations and owners`]: `API-providerorganisaties en -eigenaren maken en beheren`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Maak ruimten in uw catalogus en beheer ze. Ruimten maken het mogelijk om uw catalogus te verdelen in partities, zodat er verschillende ontwikkelteams van API-providers kunnen worden ondersteund`,
  [`Create and publish`]: `Maken en publiceren`,
  [`Create and publish APIs and Products`]: `API's en producten maken en publiceren`,
  [`Create and subscribe`]: `Maken en abonneren`,
  [`Create assembly`]: `Assemblage maken`,
  [`Create empty parent object for failed mapping`]: `Leeg bovenliggend object maken voor mislukte toewijzing`,
  [`Create endpoint`]: `Eindpunt maken`,
  [`Create from`]: `Maken op basis van`,
  [`Create from Existing WSDL service`]: `Maken op basis van bestaande WSDL-service`,
  [`Create from File or URL`]: `Maken op basis van bestand of URL`,
  [`Create from existing openAPI service`]: `Maken op basis van bestaande openAPI-service`,
  [`Create gateway extension`]: `Gatewayextensie maken`,
  [`Create new user`]: `Nieuwe gebruiker aanmaken`,
  [`Create new version`]: `Nieuwe versie maken`,
  [`Create product using a template`]: `Product maken met behulp van een sjabloon`,
  [`Create provider organization`]: `Providerorganisatie maken`,
  [`Create required child properties for array objects and mapped optional objects`]: `Vereiste onderliggende eigenschappen maken voor arrayobjecten en toegewezen optionele objecten`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Gemaakt door OAuth Providerconfiguratie als voorbeeld. Zorg dat de OAuth-providers worden bijgewerkt met behulp van dit voorbeeld met een geldig gebruikersregister.`,
  [`Created new product`]: `Nieuw product gemaakt`,
  [`Create|permission`]: `Maken`,
  [`Create|title`]: `Maken`,
  [`Creating Draft Product`]: `Conceptproduct maken`,
  [`Creating a Consumer organization`]: `Een consumentorganisatie maken`,
  [`Creating a consumer organization`]: `Een consumentorganisatie maken`,
  [`Creating a new Product`]: `Een nieuw product maken`,
  [`Creative Commons (CC-BY-4.0) license`]: `Creative Commons (CC-BY-4.0) licentie`,
  [`Credential Extraction Method`]: `Extractiemethode voor legitimatiegegevens`,
  [`Credentials for the Sandbox catalog`]: `Legitimatiegegevens voor de sandboxcatalogus`,
  [`Credentials for the {catalogName} catalog`]: `Legitimatiegegevens voor de catalogus {catalogName}`,
  [`Credential Name`]: `Naam legitimatiegegeven`,
  [`Credential has been added.`]: `Legitimatiegegeven is toegevoegd.`,
  [`Credential`]: `Legitimatiegegeven`,
  [`Credentials`]: `Legitimatiegegevens`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Er zijn legitimatiegegevens vereist voor het uitvoeren van de API Designer en Toolkit.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Er zijn legitimatiegegevens vereist om de toolkit uit te voeren. Plaats de bestanden met legitimatiegegevens in de map waarin uw toolkit geïnstalleerd is.`,
  [`Credit Card`]: `Creditcard`,
  [`Cryptographic Algorithm`]: `Cryptografisch algoritme`,
  [`Currency`]: `Valuta`,
  [`Current password`]: `Huidig wachtwoord`,
  [`Current password is invalid.`]: `Het huidige wachtwoord is ongeldig.`,
  [`Custom`]: `Aangepast`,
  [`Custom API URL`]: `Aangepaste API-URL`,
  [`Custom expression (optional, one per line)`]: `Aangepaste expressie (optioneel, één per regel)`,
  [`Custom expression to remove (optional, one per line)`]: `Te verwijderen aangepaste expressie (optioneel, één per regel)`,
  [`Custom HTML form`]: `Aangepast HTML-formulier`,
  [`Custom policies`]: `Aangepaste beleidsdefinities`,
  [`Custom form TLS profile`]: `TLS-profiel aangepast formulier`,
  [`Custom form content security policy`]: `Contentbeveiligingsbeleid aangepast formulier`,
  [`Custom form CSP header value`]: `Aangepaste CSP-headerwaarde`,
  [`Custom form endpoint`]: `Eindpunt aangepast formulier`,
  [`Custom header pattern`]: `Aangepast headerpatroon`,
  [`Custom subscribability is required if custom visibility is selected`]: `Aangepaste abonneerbaarheid is vereist als aangepaste zichtbaarheid geselecteerd is`,
  [`Customize`]: `Aanpassen`,
  [`Customize email sender information`]: `Informatie over afzender van e-mail aanpassen`,
  [`Customize notification templates`]: `Meldingssjablonen aanpassen`,
  [`Customize the plan API list`]: `De plan-API-lijst aanpassen`,
  [`Czech`]: `Tsjechisch`,
  [`DATE MODIFIED`]: `WIJZIGINGSDATUM`,
  [`DELETE`]: `DELETE`,
  [`Date created`]: `Datum aangemaakt`,
  [`Description`]: `Beschrijving`,
  [`DETAIL`]: `DETAIL`,
  [`DN Base`]: `DN-basis`,
  [`DNS Schemes`]: `DNS-schema's`,
  [`Dashboard`]: `Dashboard`,
  [`Data encoded form body`]: `Door gegevens gecodeerd formuliercorpus`,
  [`Data flow in API Connect`]: `Gegevensstroom in API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (v5-compatibel)`,
  [`DataPower API`]: `DataPower API`,
  [`DataPower API Gateway`]: `DataPower API Gateway`,
  [`DataPower Gateway (v5 compatible)`]: `DataPower Gateway (v5-compatibel)`,
  [`DataPower Gateway policies`]: `DataPower Gateway-beleidsdefinities`,
  [`Debug`]: `Fouten opsporen`,
  [`Debug rule`]: `Foutopsporingsregel`,
  [`Debug XACML policy`]: `Foutopsporing XACML-beleid`,
  [`Dec`]: `Dec`,
  [`Decline`]: `Afwijzen`,
  [`Decline and send`]: `Afwijzen en verzenden`,
  [`Decode Request Params`]: `Aanvraagparameters decoderen`,
  [`Decrement`]: `Verlagen`,
  [`Decrypt`]: `Decoderen`,
  [`Decrypt Crypto JWK variable name`]: `Naam cryptografische JWK-variabele voor decodering`,
  [`Decrypt Crypto Object`]: `Cryptografisch object voor decodering`,
  [`Decrypt a buffer using the specified certificate`]: `Een buffer decoderen (decrypt) met behulp van het opgegeven certificaat`,
  [`Default`]: `Standaard`,
  [`Default behavior`]: `Standaardgedrag`,
  [`Default gateways`]: `Standaardgateways`,
  [`default gateway services configured`]: `standaardgateway-services geconfigureerd`,
  [`Default Availability Zone`]: `Standaard beschikbaarheidszone`,
  [`Default Base endpoints`]: `Standaard basiseindpunten`,
  [`Default availability zone`]: `Standaard beschikbaarheidszone`,
  [`Default base endpoints`]: `Standaard basiseindpunten`,
  [`Default HTML form`]: `Standaard HTML-formulier`,
  [`Default limit`]: `Standaardlimiet`,
  [`Default OAuth Provider Settings`]: `Standaardinstellingen OAuth-provider`,
  [`Default OAuth Provider Settings Object`]: `Standaard instellingsobject OAuth-provider`,
  [`Default Plan`]: `Standaardplan`,
  [`Default TLS client profile`]: `Standaard TLS-clientprofiel`,
  [`Default TLS server keystore`]: `Standaardkeystore van TLS-server`,
  [`Default TLS server profile`]: `Standaard TLS-serverprofiel`,
  [`Default burst-limit`]: `Standaard burstlimiet`,
  [`Default form`]: `Standaardformulier`,
  [`Default keystore for access_token signing key`]: `Standaard keystore voor ondertekeningssleutel access_token`,
  [`Default keystore for id_token signing key`]: `Standaard keystore voor ondertekeningssleutel id_token`,
  [`Default keystore for temporary token signing key`]: `Standaard keystore voor ondertekeningssleutel tijdelijk token`,
  [`Default rate-limit`]: `Standaard aanroeplimiet`,
  [`Default scopes`]: `Standaardbereiken`,
  [`Default table entry`]: `Standaard tabelitem`,
  [`Default validator endpoint`]: `Standaard validatoreindpunt`,
  [`Default validator endpoint set by OAuth provider`]: `Standaard validatoreindpunt ingesteld door OAuth-provider`,
  [`Default value - no extra constraints apply.`]: `Standaardwaarde - geen extra beperkende voorwaarden van toepassing.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Definieer een externe OAuth-provider voor het verstrekken en valideren van tokens om de toegang tot de API te beschermen.`,
  [`Define catalog specific values for this property`]: `Definieer catalogus-specifieke waarden voor deze eigenschap`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Definieer groepen die API's, producten, catalogi, toepassingen en aanverwante instellingen beheren.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Definieer sets van beleidsdefinities om uit te voeren onder specifieke voorwaarden. Er kunnen meerdere voorvallen worden gedefinieerd en elk voorval kan worden gekoppeld aan een willekeurige voorwaarde. Alleen het eerste overeenkomende voorval wordt uitgevoerd.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Definieer de API-beveiligingsbron. Er moet ten minste één optie zijn ingeschakeld.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `De OIDC-autorisatieserver stuurt de machtigingscode door naar de volgende eindpunten. Dit omleidingseindpunt is vereist als een klant de toepassing bij de OIDC-provider registreert. Als dit gebruikersregister bijvoorbeeld wordt gebruikt door een providerorganisatie, moet de klant het omleidingseindpunt van de providerorganisatie bij de OIDC-provider registreren.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Definieer de invoeritems die in de toewijzing gebruikt worden. U kunt ook een titel en een beschrijving aan de toewijzing geven.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Definieer de eindpunten van de OpenID-provider om een verificatieaanvraag te verzenden naar uw OpenID-provider`,
  [`Define the outputs to be used in the map.`]: `Definieer de uitvoeritems die in de toewijzing gebruikt worden.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Definieer het aantal gratis proefdagen en de prijsgegevens voor het plan. Gebruikers worden gefactureerd op basis van hun abonnementsdatum.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Geeft aan of de werkstroom wordt voortgezet wanneer er een fout optreedt tijdens de uitvoering van het beleid. Als deze optie niet is geselecteerd, wordt er een afvangwerkstroom (catch flow) geactiveerd.`,
  [`Definition`]: `Definitie`,
  [`Definition Object`]: `Definitieobject`,
  [`Definitions`]: `Definities`,
  [`Delete`]: `Wissen`,
  [`Delete Application`]: `Toepassing wissen`,
  [`Delete Cloud Connection.`]: `Cloud-verbinding wissen.`,
  [`Delete Selected APIs`]: `Geselecteerde API's wissen`,
  [`Delete selected client profiles`]: `Geselecteerde clientprofielen wissen`,
  [`Delete selected keystores`]: `Geselecteerde keystores wissen`,
  [`Delete selected mail servers`]: `Geselecteerde mailservers wissen`,
  [`Delete Selected Products`]: `Geselecteerde producten wissen`,
  [`Delete selected truststores`]: `Geselecteerde truststores wissen`,
  [`Delete selected user registries`]: `Geselecteerde gebruikersregisters wissen`,
  [`Delete Staged Product`]: `Klaargezet product wissen`,
  [`Delete a space`]: `Een ruimte wissen`,
  [`Delete a catalog`]: `Catalogus wissen`,
  [`Delete API Key`]: `API-sleutel wissen`,
  [`Delete API Key failed`]: `Wissen API-sleutel is mislukt`,
  [`Delete availability zone`]: `Beschikbaarheidszone wissen`,
  [`Delete selected requests`]: `Geselecteerde aanvragen wissen`,
  [`Delete row`]: `Rij wissen`,
  [`Delete|title`]: `Wissen`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `Het wissen van een catalogus kan mislukken als er grote aantallen consumentorganisaties, toepassingen, gepubliceerde producten en andere resources aanwezig zijn.  Het kan verstandig zijn om deze resources op te schonen voordat u de catalogus wist.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `Het wissen van een providerorganisatie kan mislukken als er grote aantallen catalogi, consumentorganisaties, toepassingen, gepubliceerde producten en andere resources aanwezig zijn. Het kan verstandig zijn om deze resources op te schonen voordat u de providerorganisatie wist.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `Het wissen van een ruimte kan mislukken als er grote aantallen consumentorganisaties, toepassingen, gepubliceerde producten en andere resources aanwezig zijn.  Het kan verstandig zijn om deze resources op te schonen voordat u de ruimte wist.`,
  [`Deleting {{name}}...`]: `{name} wordt gewist...`,
  [`Deprecate`]: `Depreciëren`,
  [`Deprecate product`]: `Product depreciëren`,
  [`Deprecated`]: `Gedeprecieerd`,
  [`Deprecate|permission`]: `Depreciëren`,
  [`Description of gateway`]: `Beschrijving van gateway`,
  [`Description text for the behaviour of the modal.`]: `Beschrijvende tekst voor het gedrag van de modal.`,
  [`Descriptive name`]: `Beschrijvende naam`,
  [`Design`]: `Ontwerpen`,
  [`Designate a current member as owner of a consumer organization.`]: `Wijs een actueel lid aan als eigenaar van een consumentorganisatie.`,
  [`Designate a current member as owner of a provider organization.`]: `Wijs een bestaand lid aan als eigenaar van een providerorganisatie.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Bestemming`,
  [`Determines which credential-extraction method to use.`]: `Hiermee bepaalt u welke methode voor het extraheren van legitimatiegegevens wordt gebruikt.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Bepaal of dit type/veld en alle toepasselijke argumenten in het schema moeten worden opgenomen.`,
  [`Develop`]: `Ontwikkelen`,
  [`Develop APIs`]: `API's ontwikkelen`,
  [`Develop APIs and products`]: `API's en producten ontwikkelen`,
  [`Developer`]: `Ontwikkelaar`,
  [`developer`]: `ontwikkelaar`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `Developer community`,
  [`Developers can request a state upgrade from development to production`]: `Ontwikkelaars kunnen een statusupgrade van ontwikkeling naar productie aanvragen`,
  [`Development`]: `Ontwikkeling`,
  [`Directive`]: `Instructie`,
  [`Director Endpoint`]: `Director-eindpunt`,
  [`Director TLS client profile`]: `TLS-clientprofiel van Director`,
  [`Disable`]: `Uitschakelen`,
  [`Disable Activity log`]: `Activiteitenlogboek uitschakelen`,
  [`Disable Self-Service Onboarding`]: `Zelfservice onboarding uitschakelen`,
  [`Disable spaces`]: `Ruimten uitschakelen`,
  [`Disable buffering`]: `Buffering uitschakelen`,
  [`Disable Self-Service Onboarding Approval`]: `Goedkeuring van Zelfservice onboarding uitschakelen`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Overschrijving van timeout uitnodiging voor consumentenorganisaties uitschakelen.`,
  [`Disabled`]: `Uitgeschakeld`,
  [`disabled`]: `uitgeschakeld`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Als u zelfservice onboarding uitschakelt, moeten alle toepassingsontwikkelaars worden uitgenodigd door de API-provider of door de eigenaar of beheerder van een bestaande consumentorganisatie.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Als u de goedkeuring voor zelfserviceonboarding uitschakelt, worden alle onboardingaanvragen automatisch goedgekeurd.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `Als u deze optie uitschakelt, wordt de timeout voor de uitnodiging voor de consumentenorganisaties niet ingesteld volgens de catalogus-timeout.`,
  [`Discover targets`]: `Doelen opsporen`,
  [`Display table checkboxes`]: `Tabelselectievakjes afbeelden`,
  [`Display vanity endpoint`]: `Vanity-eindpunt afbeelden`,
  [`Do not publish API (continue editting)`]: `API niet publiceren (doorgaan met bewerken)`,
  [`Do not require applications or users to authenticate`]: `Toepassingen en gebruikers hoeven hun identiteit niet te laten verifiëren`,
  [`Do not require authentication`]: `Verificatie niet verplicht stellen`,
  [`Do not share the resource with provider organizations`]: `De resource niet delen met providerorganisaties`,
  [`Do not share the API gateway with provider organizations`]: `De API-gateway niet delen met provideorganisaties`,
  [`Do not share the service with provider organizations`]: `De service niet delen met providerorganisaties`,
  [`Do not use Dynamic DNS`]: `Geen dynamische DNS gebruiken`,
  [`Do you want to remove these references?`]: `Wilt u deze verwijzingen verwijderen?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Docker-documenten`,
  [`Docs`]: `Docs`,
  [`Document Editor`]: `Documenteditor`,
  [`Documentation and tutorials with step-by-step instructions`]: `Documentatie en zelfstudiemateriaal met stapsgewijze instructies`,
  [`Domain name`]: `Domeinnaam`,
  [`Domain Name`]: `Domeinnaam`,
  [`Domains handled by gateway via SNI`]: `Domeinen die door gateway via SNI worden afgehandeld`,
  [`Don't have an account?`]: `Hebt u geen account?`,
  [`Done`]: `Gereed`,
  [`Download`]: `Downloaden`,
  [`Download Gateway`]: `Gateway downloaden`,
  [`Download and install`]: `Downloaden en installeren`,
  [`Download and set up DataPower API Gateway`]: `DataPower API Gateway downloaden en installeren`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `DataPower API Gateway downloaden en installeren voor gebruik op locatie of op een willekeurige cloud.`,
  [`Download for Linux`]: `Downloaden voor Linux`,
  [`Download for Mac`]: `Downloaden voor Mac`,
  [`Download for Windows`]: `Downloaden voor Windows`,
  [`Download gateway`]: `Gateway downloaden`,
  [`Download schema`]: `Schema downloaden`,
  [`Download Toolkit`]: `Toolkit downloaden`,
  [`Download toolkit`]: `Toolkit downloaden`,
  [`Download toolkit and credentials for various platforms`]: `Toolkit en legitimatiegegevens voor diverse platforms downloaden`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Gedownloade legitimatiegegevens niet gevonden voor deze cloudverbinding. Bij aanmelden worden standaard legitimatiegegevens gebruikt.`,
  [`Dutch`]: `Nederlands`,
  [`Draft {type} creation failed`]: `Maken van concept {type} is mislukt`,
  [`Drafts`]: `Concepten`,
  [`Drag and drop files here or click to upload`]: `Sleep bestanden hiernaartoe of klik om te uploaden`,
  [`Drag & Drop`]: `Sleep`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `Dynamische DNS`,
  [`Dynamic OAuth configuration from a URL`]: `Dynamische OAuth-configuratie via een URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Dynamische OAuth-configuratie via een literaalreeks`,
  [`Dynamic group`]: `Dynamische groep`,
  [`Dynamic table entries`]: `Dynamische tabelitems`,
  [`ENABLE`]: `INSCHAKELEN`,
  [`Encoded`]: `Gecodeerd`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Elke ruimte wordt door een ander ontwikkelteam van een API-provider gebruikt en heeft zijn eigen set van beheerfunctionaliteit die specifiek betrekking heeft op de API's die het bijbehorende team publiceert naar die ruimte, waardoor elk team zijn API's onafhankelijk kan ontwikkelen. Als u een API klaarzet of publiceert in een catalogus waarvoor het gebruik van ruimten ingeschakeld is, moet u opgeven welke ruimte binnen die catalogus u wilt gebruiken voor het klaarzetten of publiceren.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Elke providerorganisatie heeft een reeks API's, producten, plannen en catalogi.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Lokale API's en API's in de cloud eenvoudig maken, beveiligen, beheren, delen en analyseren.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Elke providerorganisatie heeft een reeks API's, producten, plannen en catalogi. [Meer informatie]({link})`,
  [`Edit`]: `Bewerken`,
  [`Edit access token TTL`]: `Toegangstoken-TTL bewerken`,
  [`Edit admin organization invitation timeout`]: `Timeout voor uitnodiging voor beheerorganisatie bewerken`,
  [`Edit API`]: `API bewerken`,
  [`Edit API Connect UI`]: `Gebruikersinterface van API Connect bewerken`,
  [`Edit API endpoint for unenforced APIs`]: `API-eindpunt voor niet-afgedwongen API's bewerken`,
  [`Edit API gateway service`]: `API-gatewayservice bewerken`,
  [`Edit API Path`]: `API-pad bewerken`,
  [`Edit API security definition`]: `Edit API security definition`,
  [`Edit API user registries`]: `API-gebruikersregisters bewerken`,
  [`Edit analytics service`]: `Analyseservice bewerken`,
  [`Edit application`]: `Toepassing bewerken`,
  [`Edit Assembly`]: `Assemblage bewerken`,
  [`Edit authentication URL user registry`]: `Gebruikersregister voor verificatie-URL bewerken`,
  [`Edit availability zone`]: `Beschikbaarheidszone bewerken`,
  [`Edit Availablity Zone`]: `Beschikbaarheidszone bewerken`,
  [`Edit and apply constraints to your selected items.`]: `Voorwaarden voor de geselecteerde items bewerken en toepassen.`,
  [`Edit billing integration`]: `Factureringsintegratie bewerken`,
  [`Edit Catalog Details`]: `Catalogusgegevens bewerken`,
  [`Edit cloud setting invitation timeout`]: `Timeout van uitnodiging voor cloud-instelling bewerken`,
  [`Edit constraints`]: `Voorwaarden bewerken`,
  [`Edit consumer organization`]: `Consumentorganisatie bewerken`,
  [`Edit DNS Scheme`]: `DNS-schema bewerken`,
  [`Edit DataPower API gateway service`]: `DataPower API-gatewayservice bewerken`,
  [`Edit DataPower gateway service`]: `DataPower-gatewayservice bewerken`,
  [`Edit definition`]: `Definitie bewerken`,
  [`Edit dynamic DNS`]: `Dynamische DNS bewerken`,
  [`Edit email server`]: `E-mailserver bewerken`,
  [`Edit extensions`]: `Uitbreidingen bewerken`,
  [`Edit Gateway Service`]: `Gatewayservice bewerken`,
  [`Edit gateway`]: `Gateway bewerken`,
  [`Edit gateway extension`]: `Gatewayextensie bewerken`,
  [`Edit Gateways`]: `Gateways bewerken`,
  [`Edit IBM-managed gateway`]: `Door IBM beheerde gateway bewerken`,
  [`Edit Invitation Timeout`]: `Timeout voor uitnodigingen bewerken`,
  [`Edit gateways`]: `Gateways bewerken`,
  [`Edit invitation timeout`]: `Timeout voor uitnodiging bewerken`,
  [`Edit JSON`]: `JSON bewerken`,
  [`Edit keystore`]: `Keystore bewerken`,
  [`Edit LDAP user registry`]: `LDAP-gebruikersregister bewerken`,
  [`Edit LTPA Token`]: `LTPA-token bewerken`,
  [`Edit lifecycle approvals`]: `Goedkeuringen voor levenscyclus bewerken`,
  [`Edit local user registry`]: `Lokaal gebruikersregister bewerken`,
  [`Edit native OAuth provider`]: `Native OAuth-provider bewerken`,
  [`Edit New Billing System`]: `Nieuw factureringssysteem bewerken`,
  [`Edit Notification Server`]: `Berichtenserver bewerken`,
  [`Edit Notification template`]: `Meldingssjabloon bewerken`,
  [`Edit OAuth Provider`]: `OAuth-provider bewerken`,
  [`Edit OAuth provider details`]: `Details van OAuth-provider bewerken`,
  [`Edit OAuth Provider Visibility`]: `Zichtbaarheid OAuth-provider bewerken`,
  [`Edit OIDC User Registry`]: `OIDC-gebruikersregister bewerken`,
  [`Edit Onboarding`]: `Onboarding bewerken`,
  [`Edit Operation`]: `Bewerking bewerken`,
  [`Edit Organization`]: `Organisatie bewerken`,
  [`Edit Path`]: `Pad bewerken`,
  [`Edit Path Parameters`]: `Padparameters bewerken`,
  [`Edit notification template`]: `Meldingssjabloon bewerken`,
  [`Edit OAuth provider`]: `OAuth-provider bewerken`,
  [`Edit OAuth provider visibility`]: `Zichtbaarheid van OAuth-provider bewerken`,
  [`Edit OIDC user registry`]: `OIDC-gebruikersregister bewerken`,
  [`Edit onboarding`]: `Onboarding bewerken`,
  [`Edit operation`]: `Bewerking bewerken`,
  [`Edit organization`]: `Organisatie bewerken`,
  [`Edit path`]: `Pad bewerken`,
  [`Edit path parameters`]: `Padparameters bewerken`,
  [`Edit plan`]: `Plan bewerken`,
  [`Edit portal`]: `Portal bewerken`,
  [`Edit portal service`]: `Portalservice bewerken`,
  [`Edit Portal Settings`]: `Portalinstellingen bewerken`,
  [`Edit product`]: `Product bewerken`,
  [`Edit product APIs`]: `Product-API's bewerken`,
  [`Edit Product Subscribability`]: `Abonnementsmogelijkheden voor product bewerken`,
  [`Edit Product Visibility`]: `Productzichtbaarheid bewerken`,
  [`Edit Property`]: `Eigenschap bewerken`,
  [`Edit Provider Organization`]: `Providerorganisatie bewerken`,
  [`Edit Refresh Token TTL`]: `Levensduur vernieuwingstoken bewerken`,
  [`Edit remote gateway`]: `Gateway op afstand bewerken`,
  [`Edit Reset Password TTL`]: `Levensduur wachtwoordreset bewerken`,
  [`Edit Role`]: `Rol bewerken`,
  [`Edit Role Default for Provider Organizations`]: `Standaardwaarde voor rol bewerken voor providerorganisatie`,
  [`Edit Role for Provider Organizations`]: `Rol voor providerorganisaties bewerken`,
  [`Edit property`]: `Eigenschap bewerken`,
  [`Edit provider organization`]: `Provideorganisatie bewerken`,
  [`Edit refresh token TTL`]: `TTL van vernieuwingstoken vernieuwen`,
  [`Edit reset password TTL`]: `Reset van wachtwoord-TTL bewerken`,
  [`Edit role`]: `Rol bewerken`,
  [`Edit role default for Provider Organizations`]: `Standaardwaarde voor rol bewerken voor providerorganisatie`,
  [`Edit role for Provider Organizations`]: `Rol voor providerorganisaties bewerken`,
  [`Edit Roles`]: `Rollen bewerken`,
  [`Edit Schema in JSON/XML`]: `Schema bewerken in JSON/XML`,
  [`Edit security definition`]: `Beveiligingsdefinitie bewerken`,
  [`Edit sender`]: `Afzender bewerken`,
  [`Edit sender & email server`]: `Afzender &amp; e-mailserver bewerken`,
  [`Edit sender Info`]: `Info afzender bewerken`,
  [`Edit service visibility`]: `Zichtbaarheid service bewerken`,
  [`Edit show/hide settings`]: `Instellingen voor afbeelden/verbergen bewerken`,
  [`Edit space details`]: `Details van ruimte bewerken`,
  [`Edit TLS client profile`]: `TLS-clientprofiel bewerken`,
  [`Edit TLS client profile visibility`]: `Zichtbaarheid van TLS-clientprofiel bewerken`,
  [`Edit TLS server profile`]: `TLS-serverprofiel bewerken`,
  [`Edit third party OAuth provider`]: `Externe OAuth-provider bewerken`,
  [`Edit truststore`]: `Truststore bewerken`,
  [`Edit unenforced API`]: `Niet-afgedwongen API bewerken`,
  [`Edit user registries`]: `Gebruikersregisters bewerken`,
  [`Edit user registry`]: `Gebruikersregister bewerken`,
  [`Edit user registry visibility`]: `Zichtbaarheid gebruikersregister bewerken`,
  [`Edit user registry for API Manager`]: `Gebruikersregister voor API Manager bewerken`,
  [`Edit user registry for Cloud Manager`]: `Gebruikersregister voor Cloud Manager bewerken`,
  [`Edit vanity API endpoints`]: `Eindpunten van vanity-API's bewerken`,
  [`Edit visibility`]: `Zichtbaarheid bewerken`,
  [`Edit XML`]: `XML bewerken`,
  [`Edit app lifecycle approved`]: `Bewerken van app-levenscyclus is goedgekeurd`,
  [`Edit app lifecycle cancelled`]: `Bewerken van app-levenscyclus is geannuleerd`,
  [`Edit app lifecycle denied`]: `Bewerken van app-levenscyclus is geweigerd`,
  [`Edit app lifecycle pending`]: `Bewerken van app-levenscyclus is in behandeling`,
  [`Edit app lifecycle request`]: `Aanvraag voor bewerken van app-levenscyclus`,
  [`Edit app reinstated`]: `Bewerken van app is hersteld`,
  [`Edit app suspended`]: `Bewerken van app is onderbroken`,
  [`Edit audit setting`]: `Auditinstelling bewerken`,
  [`Edit definitions`]: `Definities bewerken`,
  [`Enable external group mapping`]: `Toewijzing externe groep inschakelen`,
  [`Edit extra constraints for the GraphQL schema`]: `Extra beperkende voorwaarden voor het GraphQL-schema bewerken`,
  [`Edit GraphQL schema|title`]: `GraphQL-schema bewerken`,
  [`Edit inline schema`]: `Inline schema bewerken`,
  [`Edit inputs`]: `Invoer bewerken`,
  [`Edit invitation`]: `Uitnodiging bewerken`,
  [`Edit mail server test connection`]: `Testverbinding van mailserver bewerken`,
  [`Edit member invitation`]: `Uitnodiging aan leden bewerken`,
  [`Edit notification templates`]: `Meldingssjablonen bewerken`,
  [`Edit notification templates:`]: `Meldingssjablonen bewerken:`,
  [`Edit operation list`]: `Bewerkingslijst bewerken`,
  [`Edit or upload JSON schema`]: `JSON-schema bewerken of uploaden`,
  [`Edit outputs`]: `Uitvoer bewerken`,
  [`Edit password changed`]: `Wachtwoordwijziging bewerken`,
  [`Edit password reset`]: `Wachtwoordreset bewerken`,
  [`Edit plan APIs`]: `Plan-API's bewerken`,
  [`Edit schema`]: `Schema bewerken`,
  [`Edit schema|button`]: `Schema bewerken`,
  [`Edit schema|title`]: `Schema bewerken`,
  [`Edit settings for roles, notifications and more.`]: `Instellingen voor rollen, meldingen en meer bewerken.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Instellingen voor onder meer gebruikersregisters, rollen en eindpunten bewerken.`,
  [`Edit sign up`]: `Registratie bewerken`,
  [`Edit task product lifecycle approved`]: `Bewerken van taaklevenscyclus is goedgekeurd`,
  [`Edit task product lifecycle cancelled`]: `Bewerken van taaklevenscyclus is geannuleerd`,
  [`Edit task product lifecycle denied`]: `Bewerken van taaklevenscyclus is geweigerd`,
  [`Edit task product lifecycle pending`]: `Bewerken van taaklevenscyclus is in behandeling`,
  [`Edit task product lifecycle request`]: `Bewerken van taaklevenscyclus is goedgekeurd`,
  [`Edit task subscription approved`]: `Bewerken van taakabonnement is goedgekeurd`,
  [`Edit task subscription cancelled`]: `Bewerken van taakabonnement is geannuleerd`,
  [`Edit task subscription denied`]: `Bewerken van taakabonnement is geweigerd`,
  [`Edit task subscription pending`]: `Bewerken van taakabonnement is in behandeling`,
  [`Edit task subscription request`]: `Aanvraag voor bewerken van taakabonnement`,
  [`Edit template`]: `Sjabloon bewerken`,
  [`Edit the TLS client profile.`]: `Het TLS-clientprofiel bewerken.`,
  [`Edit the TLS Server profiles`]: `TLS-serverprofielen bewerken`,
  [`Edit the keystore title.`]: `Titel van keystore bewerken.`,
  [`Edit the organization summary details as required`]: `Breng de noodzakelijke wijzigingen aan in de samenvatting van de organisatie`,
  [`Edit the organization title`]: `De titel van de organisatie bewerken`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Bewerk de parameters voor het configureren van een e-mailserver. Alle velden zijn verplicht, behalve die welke zijn aangegeven als optioneel.`,
  [`Edit signed token`]: `Ondertekend token bewerken`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Wijzig de tekst voor de e-mailsjabloon. Variabelen staan tussen accolades en kunnen niet worden gewijzigd.`,
  [`Edit the truststore.`]: `De truststore bewerken.`,
  [`Edit the visibility for each resource.`]: `Wijzig de zichtbaarheid voor elke resource.`,
  [`Edit user details`]: `Gebruikersgegevens bewerken`,
  [`Edit {notificationTemplate}`]: `{notificationTemplate} bewerken`,
  [`Edit, assemble, secure and test APIs`]: `API's bewerken, samenstellen, beveiligen en testen`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `API's bewerken, samenstellen, beveiligen en testen. API's inpakken met behulp van producten voor publicatie aan consumenten`,
  [`Edit, upload, or infer XML schema`]: `XML-schema bewerken, uploaden of afleiden`,
  [`Editors`]: `Editors`,
  [`Edit|permission`]: `Bewerken`,
  [`Edit API key timeout`]: `Timeout voor API-sleutel bewerken`,
  [`Edit Base64 encoding for temporary tokens`]: `Base64-codering voor tijdelijke tokens bewerken`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Een van beide moet worden opgegeven: "Crypto-object voor ondertekening ID-token" of "Ondertekeningssleutel-ID voor ID-token".`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Er moet "Cryptografisch object voor JWT-verificatie" of "JWK voor JWT-verificatie" worden opgegeven.`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Selecteer de optie om het in de API gedefinieerde eindpunt te gebruiken, of geef een standaard validatoreindpunt op.`,
  [`Element name for JSON array elements`]: `Elementnaam voor JSON-arrayelementen`,
  [`Email`]: `E-mail`,
  [`Email address`]: `E-mailadres`,
  [`Email Content`]: `E-mailcontent`,
  [`Email endpoint`]: `Eindpunt van e-mail`,
  [`Email required`]: `E-mailadres vereist`,
  [`Email sender`]: `Afzender van e-mail`,
  [`Email server`]: `E-mailserver`,
  [`Email server configuration`]: `Configuratie e-mailserver`,
  [`Email servers`]: `E-mailservers`,
  [`Empty case`]: `Lege zaak`,
  [`Empty catch`]: `Lege catch`,
  [`Empty JSON array handling`]: `Verwerking van lege JSON-arrays`,
  [`Empty XML element handling`]: `Afhandeling van lege XML-elementen`,
  [`Enable`]: `Inschakelen`,
  [`Enable API consumers to invite collaborators and assign roles`]: `API-gebruikers inschakelen om medewerkers uit te nodigen en rollen toe te wijzen`,
  [`Enable application lifecycle`]: `Levenscyclus toepassing inschakelen`,
  [`Enable billing`]: `Facturering inschakelen`,
  [`Enable gateway services`]: `Gatewayservices inschakelen`,
  [`Enable gateway services and policies`]: `Gatewayservices en beleidsdefinities inschakelen`,
  [`Enable GraphiQL`]: `GraphiQL inschakelen`,
  [`Enable GraphiQL editor`]: `GraphiQL-editor inschakelen`,
  [`Enable GraphiQL Editor on browser`]: `GraphiQL Editor inschakelen in browser`,
  [`Enable JSON post processing`]: `Naverwerking van JSON inschakelen`,
  [`Enable lifecycle approvals`]: `Goedkeuringen voor levenscyclus inschakelen`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `NONCE-extensie inschakelen om te voorkomen dat onveilige aanvragen opnieuw worden gebruikt (opnieuw worden afgespeeld)`,
  [`Enable OAuth providers`]: `OAuth-providers inschakelen`,
  [`Enable OIDC`]: `OIDC inschakelen`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `OpenID Connect-sjabloon inschakelen voor het genereren van ID-tokens.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Schakel de PKCE-extensie in om openbare clients in staat te stellen de dreiging van het onderscheppen van de machtigingscode te reduceren`,
  [`Enable production mode`]: `Productiemodus inschakelen`,
  [`Enable Proxy`]: `Proxy inschakelen`,
  [`Enable Self-Service Onboarding`]: `Zelfservice onboarding inschakelen`,
  [`Enable spaces`]: `Ruimten inschakelen`,
  [`Enable token management`]: `Tokenbeheer inschakelen`,
  [`Enable buffering`]: `Buffering inschakelen`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `API-consumenten in staat stellen accounts bij de ontwikkelingsportal te maken zonder dat er een uitnodiging vereist is.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `CORS (Cross-Origin Resource Sharing)-toegangsbeheer inschakelen voor uw API.`,
  [`Enable debug response headers`]: `Debug-responsheaders inschakelen`,
  [`Enable proof key for code exchange`]: `Bewijssleutel voor code-uitwisseling inschakelen`,
  [`Enable publishing of this product`]: `Publicering van dit product inschakelen`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Doorsturen van machtigingscode van derden via Portal OIDC-eindpunt inschakelen`,
  [`Enable security`]: `Beveiliging inschakelen`,
  [`Enable Self-Service Onboarding Approval`]: `Goedkeuring van Zelfservice onboarding inschakelen`,
  [`Enable the following gateway services and policies for this catalog`]: `U kunt de volgende services en beleidsdefinities inschakelen voor deze catalogus`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Gebruikers toestaan om een auditevent te configureren. Een auditevent is een gedetailleerde samenvatting van wie welke event wanneer heeft uitgevoerd.`,
  [`Enable/disable custom notification templates`]: `Aangepaste meldingssjablonen inschakelen/uitschakelen`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Base64-codering in- of uitschakelen voor uitnodigingen en tokens voor resetten wachtwoord`,
  [`Enable overriding consumer organizations invitation timeout`]: `Overschrijving van timeout uitnodiging voor consumentenorganisaties inschakelen`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `U kunt Base64 -codering in-of uitschakelen voor tijdelijke tokens. Tijdelijke tokens zijn uitnodigingen, en wachtwoord reset tokens. Wanneer Base64 -codering is ingeschakeld, wordt het tijdelijke token gecodeerd in Base64-indeling. Wanneer Base64-codering uitstaat, blijft het tijdelijke token in de JSON Web Token (JWT)-indeling en betekent dit dat de lengte van de URL die de gebruiker ontvangt, wordt verkleind.`,
  [`Enabled`]: `Ingeschakeld`,
  [`enabled`]: `ingeschakeld`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `Als u de toepassingslevenscyclus inschakelt, kunnen toepassingen de ontwikkelings- of productiestatus hebben en kunnen ze worden doorgestuurd naar verschillende gateways en eindpunten.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Als de goedkeuring voor zelfserviceonboarding ingeschakeld is, moeten alle onboarding-aanvragen door de API-provider of een beheerder worden goedgekeurd.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Als u zelfservice onboarding inschakelt, kunnen toepassingsontwikkelaars zich registreren zonder uitnodiging van de API-provider of van de eigenaar of beheerder van een bestaande consumentorganisatie.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `Als u deze optie inschakelt, worden alle timeouts voor uitnodigingen voor consumentenorganisaties ingesteld op de catalogus-timeout voor uitnodigingen.`,
  [`Encode Query params`]: `Queryparameters coderen`,
  [`Encrypt`]: `Versleutelen`,
  [`Encrypt Crypto Object`]: `Cryptografisch object voor versleuteling`,
  [`Encrypt JWK variable name`]: `Naam van JWK-variabele voor versleuteling`,
  [`Encrypt a buffer using the specified certificate`]: `Een buffer versleutelen (encrypt) met behulp van het opgegeven certificaat`,
  [`Encryption Algorithm`]: `Versleutelingsalgoritme`,
  [`Endpoint`]: `Eindpunt`,
  [`Endpoint URLs`]: `Eindpunt-URL's`,
  [`Endpoint behavior`]: `Gedrag van eindpunt`,
  [`Endpoint Behavior`]: `Gedrag van eindpunt`,
  [`Endpoint for unenforced APIs`]: `Eindpunt voor niet-afgedwongen API's`,
  [`Endpoints`]: `Eindpunten`,
  [`Enforce Required Params`]: `Vereiste parameters afdwingen`,
  [`Enforce the API by using API Connect Gateway.`]: `De API afdwingen met behulp van API Connect Gateway.`,
  [`Enforced`]: `Afgedwongen`,
  [`English`]: `Engels`,
  [`Enter Summary`]: `Samenvatting opgeven`,
  [`Enter URL`]: `Geef een URL op`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Geef de naam van het gebruikersregister op. Als u dit leeg laat, wordt standaard het lokale gebruikersregister van API Manager gebruikt`,
  [`Enter a catalog name`]: `Voer een catalogusnaam in`,
  [`Enter a Display Name`]: `Voer een weergavenaam in`,
  [`Enter a space name`]: `Geef een ruimtenaam op`,
  [`Enter a brief description`]: `Voer een korte beschrijving in`,
  [`Enter a display name`]: `Voer een weergavenaam in`,
  [`Enter a temporary sign in password`]: `Geef een tijdelijk wachtwoord voor aanmelding op`,
  [`Enter an Organization Name`]: `Geef een naam voor de organisatie op`,
  [`Enter an Organization Title`]: `Geef een titel voor de organisatie op`,
  [`Enter catalog title`]: `Geef de catalogustitel op`,
  [`Enter details of the consumer organization`]: `Geef de details van de consumentorganisatie op`,
  [`Enter details of the product`]: `Geef de gegevens van het product op`,
  [`Enter details of the provider organization`]: `Geef de details van de providerorganisatie op`,
  [`Enter details of this API`]: `Geef de details van deze API op`,
  [`Enter email of the new user`]: `Geef het e-mailadres van de nieuwe gebruiker op`,
  [`Enter first name of the new user`]: `Geef de voornaam op van de nieuwe gebruiker`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Voer een of meer e-mailadressen in. U kunt meerdere adressen van elkaar scheiden met komma's.`,
  [`Enter organization name`]: `Geef naam van organisatie op`,
  [`Enter organization title`]: `Geef titel van organisatie op`,
  [`Enter password`]: `Voer uw wachtwoord in`,
  [`Enter space title`]: `Geef de titel van de ruimte op`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Geef het API-eindpunt voor de gateway op door de hostnaam en het poortnummer op te geven. Bijvoorbeeld: api.mycompany.com:8090`,
  [`Enter the API summary details`]: `Geef een samenvatting van de API op`,
  [`Enter the API summary details. [Learn more]({link})`]: `Geef de samenvatting van de API op. [Meer informatie]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Geef de URL op van de doelservice waarvoor u een proxy wilt instellen`,
  [`Enter the catalog summary details`]: `Geef een samenvatting van de catalogus op`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Geef een samenvatting voor de catalogus op. Nadat de catalogus is gemaakt, kunt u hem volledig configureren`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Geef het e-mailadres op van de gebruiker die wordt uitgenodigd om lid te worden van de beheerorganisatie`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Geef het e-mailadres op van de gebruiker die wordt uitgenodigd om lid te worden van de catalogus`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Geef het e-mailadres op van de gebruiker die wordt uitgenodigd om lid te worden van de providerorganisatie`,
  [`Enter the email address of the user to invite as a member of the space`]: `Geef het e-mailadres op van de gebruiker die wordt uitgenodigd om lid te worden van de ruimte`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Geef het e-mailadres op van de gebruiker aan wie u de eigendom wilt overdragen.`,
  [`Enter the full path to JSON or YAML file`]: `Voer het volledige pad naar het JSON- of YAML-bestand in`,
  [`Enter the full path to WSDL file`]: `Voer het volledige pad naar het WSDL-bestand in`,
  [`Enter the full path to YAML file`]: `Voer het volledige pad naar het YAML-bestand in`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Geef de volledige domeinnaam op voor het door u gedefinieerde eindpunt voor analyse tijdens de installatie.`,
  [`Enter the last name of the new user`]: `Geef de achternaam op van de nieuwe gebruiker`,
  [`Enter the owner's email address to send the invitation`]: `Geef het e-mailadres van de eigenaar op. Naar dit adres wordt de uitnodiging gestuurd`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Voer de parameters in om de gatewayservice te configureren. Alle velden zijn verplicht, behalve die welke zijn aangegeven als optioneel. Eindpunt is het eindpunt van API-gatewayservice op DataPower. Basis API-eindpunt is het eindpunt van API-gateway voor inkomende API-aanroepen. Voor SNI typt u * om alle hosts toe te staan (verplicht) en voert u bovendien hostnamen in om specifieke hosts toe te staan. Kies het TLS-profiel dat elke host moet ondersteunen.`,
  [`Enter the password for the certificate file if required.`]: `Geef indien nodig het wachtwoord voor het certificaatbestand op.`,
  [`Enter the provider organization summary details`]: `Geef de overzichtsgegevens voor de providerorganisatie op`,
  [`Enter the schema definitiom summary details`]: `Geef de samenvatting van de schemadefinitie op`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Geef de overzichtsgegevens voor de ruimte op; na het maken kunt u de ruimte volledig configureren`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Geef de overzichtsgegevens voor de ruimte op; na het maken kunt u de ruimte volledig configureren.`,
  [`Enter the summary details for the new product`]: `Geef de samenvatting voor het nieuwe product op`,
  [`Enter the summary details for this role`]: `Geef de overzichtsgegevens voor deze rol op`,
  [`Enter title of the organization`]: `Geef de titel van de organisatie op`,
  [`Enter user email`]: `E-mailadres van gebruiker opgeven`,
  [`Enter username of the existing user`]: `Geef de gebruikersnaam van de bestaande gebruiker op`,
  [`Enter username of the new user`]: `Geef de gebruikersnaam van de nieuwe gebruiker op`,
  [`Error`]: `Fout`,
  [`Error ID`]: `Fout-ID`,
  [`Error:`]: `Fout:`,
  [`Copy error ID to Clipboard`]: `Fout-ID kopiëren naar klembord`,
  [`Error Console`]: `Foutconsole`,
  [`Error connecting`]: `Fout bij maken van verbinding`,
  [`Error connecting to GraphQL server`]: `Fout bij het maken van verbinding met GraphQL-server`,
  [`Error getting user info during login. Please login with different user.`]: `Fout bij ophalen van gebruikersgegevens tijdens aanmelding. Meld u aan bij een andere gebruiker.`,
  [`Error content`]: `Foutcontent`,
  [`Error message`]: `Foutbericht`,
  [`Error name`]: `Foutnaam`,
  [`Error status code`]: `Foutstatuscode`,
  [`Error status code is invalid`]: `Foutstatuscode is ongeldig`,
  [`Error status reason`]: `Reden voor foutstatus`,
  [`Errors`]: `Fouten`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Fouten zijn meestal het gevolg van onjuiste informatie of verkeerde tekens. Controleer vóór de test of u de juiste providerinformatie en clientinformatie opgeeft.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Fout bij het ophalen van API uit URL {url}. Controleer de URL, de gebruikersnaam en het wachtwoord. Foutbericht: {message}`,
  [`Event`]: `Event`,
  [`Event gateway management client TLS client profile`]: `TLS-clientprofiel van Event Gateway-client`,
  [`Event load`]: `Event laden`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Elke consumentorganisatie heeft een eigenaar. De eigenaar heeft volledige beheermachtiging en kan de abonnementen en toepassingen beheren.`,
  [`Exchange authorization code for tokens`]: `Autorisatiecode inruilen voor tokens`,
  [`Exchange code for token`]: `Code inruilen voor token`,
  [`Execute`]: `Uitvoeren`,
  [`Execute migration target`]: `Migratiedoel uitvoeren`,
  [`Executes any catalog-level post-invoke handlers`]: `Hiermee voert u eventuele afhandelingsroutines voor achteraf oproepen op catalogusniveau uit`,
  [`Executes any catalog-level pre-invoke handlers`]: `Hiermee voert u eventuele afhandelingsroutines voor vooraf oproepen op catalogusniveau uit`,
  [`Existing`]: `Bestaande`,
  [`Existing {docType}`]: `Bestaand {docType}`,
  [`Existing API Connect user`]: `Bestaande API Connect-gebruiker`,
  [`Existing OpenAPI`]: `Bestaande OpenAPI`,
  [`Existing product`]: `Bestaand product`,
  [`Existing SOAP API has been validated`]: `Bestaande SOAP-API is gevalideerd`,
  [`Existing products in the catalog will be moved to a default space.`]: `Bestaande producten in de catalogus worden naar een standaardruimte verplaatst.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Bestaande gebruikers zijn actieve leden. Het is ook mogelijk om een nieuw lid uit een ander gebruikersregister te activeren.`,
  [`Expires On`]: `Vervalt op`,
  [`Expiry`]: `Vervaldatum`,
  [`Explorer`]: `Explorer`,
  [`Expose`]: `Zichtbaar maken`,
  [`Extend the schema to specify the cost of types and fields`]: `Het schema uitbreiden om de de kosten van typen en velden op te geven`,
  [`Extended`]: `Uitgebreid`,
  [`Extension Type:`]: `Type extensie:`,
  [`Extensions`]: `Extensies`,
  [`Extension has been uploaded.`]: `Extensie is geüpload.`,
  [`External`]: `Extern`,
  [`External Doc Description`]: `Beschrijving extern document`,
  [`External doc description`]: `Beschrijving extern document`,
  [`External Documentation URL`]: `URL van externe documentatie`,
  [`External documentation URL`]: `URL van externe documentatie`,
  [`External Role mapping`]: `Externe roltoewijzing`,
  [`External URL`]: `Externe URL`,
  [`Extract Identity Settings`]: `ID-instellingen extraheren`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `Factor-ID`,
  [`Fail`]: `Mislukt`,
  [`Failed to change account password.`]: `Wijzigen van accountwachtwoord is mislukt.`,
  [`Failed to create analytics service {title}.`]: `Maken van analyticsservice {title} is mislukt.`,
  [`Failed to create analytics insights service {title}.`]: `Het aanmaken van de service voor analyse-inzichten {title} is mislukt.`,
  [`Analytics insights service {title} has been updated.`]: `Service voor analyse-inzichten {title} is bijgewerkt.`,
  [`About the analytics insights service`]: `Informatie over de service voor analyse-inzichten`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `De service voor analyse-inzichten verzamelt API-events van de gatewayservice. Elke gatewayservice kan een bijbehorende service voor analyse-inzichten hebben.`,
  [`Advanced analytics insights configuration`]: `Geavanceerde configuratie van analyse-inzichten`,
  [`Analytics insights service {title} has been created.`]: `De service voor analyse-inzichten {title} is aangemaakt.`,
  [`Analytics Insights details`]: `Details van de analyse-inzichten`,
  [`Management endpoint on the analytics insights service`]: `Beheerseindpunt in de service voor analyse-inzichten`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Geef de volledige domeinnaam op voor het director-eindpunt van Analytics Insights die u tijdens de installatie hebt gedefinieerd.`,
  [`TYPE`]: `TYPE`,
  [`Failed to create email server`]: `E-mailserver kan niet worden gemaakt`,
  [`Failed to create gateway service`]: `Maken van gatewayservice is mislukt`,
  [`Failed to create gateway service {name}.`]: `Maken van gatewayservice {name} is mislukt.`,
  [`Failed to create user registry {title}.`]: `Maken van gebruikersregister {title} is mislukt.`,
  [`Failed to delete a group`]: `Wissen van een groep is mislukt.`,
  [`Failed to get TLS client profiles`]: `Ophalen van TLS-clientprofielen is mislukt`,
  [`Failed to get catalogs`]: `Ophalen van catalogi is mislukt`,
  [`Failed to get configured OAuth providers`]: `Ophalen van geconfigureerde OAuth-providers is mislukt`,
  [`Failed to get policies of gateways`]: `Ophalen van beleidsdefinities voor gateways is mislukt`,
  [`Failed to get user registries`]: `Ophalen van gebruikersregisters is mislukt`,
  [`Failed to introspect schema from server:`]: `Introspectie van schema vanaf server is mislukt:`,
  [`Failed to make API online`]: `Online aanmaken van API is mislukt`,
  [`Failed to register catalog member {user}.`]: `Registreren van cataloguslid {user} is mislukt.`,
  [`Failed to remove analytics service: {arg}`]: `Verwijderen van analyseservice {arg} is mislukt`,
  [`Failed to remove availability zone: {arg}`]: `Verwijderen van beschikbaarheidszone {arg} is mislukt`,
  [`Failed to remove gateway service: {arg}`]: `Verwijderen van gatewayservice {arg} is mislukt`,
  [`Failed to remove mailserver: {arg}`]: `Verwijderen van mailserver {arg} is mislukt`,
  [`Failed to remove portal service: {arg}`]: `Verwijderen van portalservice {arg} is mislukt`,
  [`Failed to create {name}.`]: `Maken van {name} is mislukt.`,
  [`Failed to sign out! Please check the console for details.`]: `Afmelden is mislukt! Controleer de console voor meer informatie.`,
  [`Failed to update {name}.`]: `Bijwerken van {name} is mislukt.`,
  [`Failed to update OAuth provider`]: `Bijwerken van OAuth-provider is mislukt.`,
  [`Failed to update account.`]: `Bijwerken van account is mislukt.`,
  [`Failed to update analytics service {title}.`]: `Bijwerken van analyticsservice {title} is mislukt.`,
  [`Failed to update gateway service {title}.`]: `Bijwerken van gatewayservice {title} is mislukt.`,
  [`Failed to update task`]: `Bijwerken van taak is mislukt`,
  [`Failed to create app {title}`]: `Maken van app {title}is mislukt`,
  [`Feature flags`]: `Feature-vlaggen`,
  [`Fetch schema`]: `Schema ophalen`,
  [`Fetched endpoints`]: `Eindpunten opgehaald`,
  [`Field`]: `Veld`,
  [`Field label`]: `Veldlabel`,
  [`Field Mapping`]: `Veldtoewijzing`,
  [`Field name`]: `Veldnaam`,
  [`Field weight`]: `Veldgewicht`,
  [`File Name`]: `Bestandsnaam`,
  [`File Preview`]: `Preview van bestand`,
  [`File contains validation errors`]: `Bestand bevat validatiefouten`,
  [`File is not a valid OpenAPI definition`]: `Bestand is geen geldige OpenAPI-definitie`,
  [`File is not a valid extension format.`]: `Bestand heeft geen geldige extensie-indeling.`,
  [`File is not a valid {fileType} format.`]: `Bestand heeft geen geldige {fileType}-indeling.`,
  [`File name`]: `Bestandsnaam`,
  [`File upload`]: `Bestandsupload`,
  [`File uploaded is not a valid file`]: `Geüploade bestand is geen geldig bestand`,
  [`File uploaded successfully`]: `Uploaden van bestand is gelukt`,
  [`File was updated and validated!`]: `Bestand is bijgewerkt en gevalideerd!`,
  [`File was uploaded and validated!`]: `Bestand is geüpload en gevalideerd!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `Bestand {draftFilePath} bestaat al. Probeer of het helpt als u de naam/versie-combinatie van het concept wijzigt`,
  [`Fill out the details of this service`]: `Vul de details van deze service in`,
  [`Filter`]: `Filter`,
  [`Filter by operation`]: `Filteren op bewerking`,
  [`Filter policies`]: `Beleidsdefinities filteren`,
  [`Final Action`]: `Definitieve actie`,
  [`Find existing user`]: `Bestaande gebruiker opzoeken`,
  [`Find expert answers in the API Connect community forum`]: `Voor deskundige antwoorden kunt u terecht op het communityforum van API Connect`,
  [`Find expert answers in the API Connect community forum.`]: `Voor deskundige antwoorden kunt u terecht op het communityforum van API Connect`,
  [`Find schema`]: `Schema zoeken`,
  [`Finger Print`]: `Vingerafdruk`,
  [`Fingerprint SHA-1`]: `Vingerafdruk SHA-1`,
  [`Fingerprint SHA-256`]: `Vingerafdruk SHA-256`,
  [`Finish`]: `Voltooien`,
  [`First name`]: `Voornaam`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Geef eerst de URL op van het eindpunt dat de gateway gebruikt voor API-verkeer. De waarde moet een openbare, volledige domeinnaam zijn met extra paden die specifiek zijn voor uw API-aanroepen, bijvoorbeeld: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Geef eerst de URL van het gatewaybeheereindpunt op. Voor gateways die worden gebruikt in Kubernetes-omgevingen, moet de waarde overeenkomen met het eindpunt dat is opgegeven via de gatewayinstallatieopdracht set gwy apic-gw-service. Voor gatewayappliances, moet de waarde in de indeling http://<ip-address-for-gateway>:3000 worden opgegeven. Als u werkt met een gatewaycluster geeft u de address:port op van de load balancer.`,
  [`Fit to screen`]: `Vensterformaat`,
  [`Flow`]: `Stroom`,
  [`Follow redirects`]: `Omleidingen volgen`,
  [`Found In`]: `Gevonden in`,
  [`Fonts contained in the following file are available under the`]: `De lettertypen die aanwezig zijn in het volgende bestand zijn beschikbaar onder de`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `Upload voor WSDL-definities een ZIP-bestand met zowel het WSDL-bestand als de API-definitie (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Voor elk plan in het oorspronkelijke product selecteert u in het vervangende product een plan waar u de abonnementen naar toe wilt migreren`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Voor elk plan in het bronproduct selecteert u in het doelproduct een plan waar u de abonnementen naar toe wilt migreren`,
  [`For guidance on the available command set, access the CLI's help`]: `Richtlijnen voor de beschikbare opdrachtenset vindt u in de Help-informatie van de CLI`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `Voor sites die native OAuth-providers gebruiken, kunt u het gemeenschappelijke geheim opgeven dat door alle API-aanroepen wordt gebruikt.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `Voor schrijfbare LDAP selecteert u de beheerde gebruiker en voegt u de toewijzing van de kenmerknamen van uw LDAP-bronregister toe aan de LDAP-kenmerkwaarden van API Connect. De standaard eigenschappen voor gebruikerprofiel die API Connect tijdens gebruikersregistratie vereist, zijn gebruikersnaam, voornaam, achternaam, e-mailadres en wachtwoord. Er is een voorbeeldconfiguratie van de toewijzing meegeleverd - bewerk de toewijzing om aan uw vereisten te voldoen`,
  [`Forbidden`]: `Niet toegestaan`,
  [`Forget token`]: `Token vergeten`,
  [`Forgot password?`]: `Wachtwoord vergeten?`,
  [`Forgot password`]: `Wachtwoord vergeten`,
  [`Form`]: `Formulier`,
  [`Forum`]: `Forum`,
  [`French`]: `Frans`,
  [`From File`]: `Uit bestand`,
  [`From Sample JSON`]: `Uit voorbeeld-JSON`,
  [`From Sample XML`]: `Uit voorbeeld-XML`,
  [`From URL`]: `Vanaf URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Uit  bestaande GraphQL-service (GraphQL-proxy)`,
  [`From existing OpenAPI service`]: `Van bestaande OpenAPI-service`,
  [`From existing WSDL service (REST proxy)`]: `Van bestaande WSDL-service (REST-proxy)`,
  [`From existing WSDL service (SOAP proxy)`]: `Van bestaande WSDL-service (SOAP-proxy)`,
  [`From target service`]: `Van doelservice`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `Er zijn bewerkingen gedefinieerd in de WSDL-definitie voor de SOAP-service. Selecteer daaruit de definities die u in uw API wilt gebruiken en geef de methoden en de URL-paden op.`,
  [`Gateway Extensions`]: `Gatewayextensies`,
  [`Gateway extension`]: `Gatewayextensie`,
  [`Gateway Services`]: `Gatewayservices`,
  [`Gateway Type`]: `Type gateway`,
  [`Gateway by Name`]: `Gateway op naam`,
  [`Gateway services`]: `Gatewayservices`,
  [`Gateway type`]: `Type gateway`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `GPL v2 licentie`,
  [`GROUP`]: `GROEP`,
  [`Group name`]: `Groepsnaam`,
  [`Gateway`]: `Gateway`,
  [`Gateways`]: `Gateways`,
  [`Gateway details`]: `Gatewaygegevens`,
  [`Gateway Details`]: `Gatewaygegevens`,
  [`Gateway Error`]: `Gatewayfout`,
  [`Gateway service details`]: `Gegevens gatewayservice`,
  [`Gateway management client TLS client profile`]: `TLS-clientprofiel voor gatewaybeheerclient`,
  [`Gateway management client keystore`]: `Keystore gatewaybeheerclient`,
  [`Gateway management client truststore`]: `Truststore gatewaybeheerclient`,
  [`Gateway processing status`]: `Verwerkingsstatus gateway`,
  [`Gateway policies (TODO)`]: `Gatewaybeleid (TODO)`,
  [`Gateway service`]: `Gatewayservice`,
  [`Gateway Service US East (TODO)`]: `Gatewayservice US East (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Gatewayservice {arg} is verwijderd.`,
  [`Gateway service configuration`]: `Configuratie van gatewayservice`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `De host van de gatewayservice is niet beschikbaar. API-test (Uitproberen) in Explorer wordt niet ingeschakeld.`,
  [`Gateway service configuration has been updated.`]: `Gatewayserviceconfiguratie is bijgewerkt.`,
  [`Gateway service {title} has been created.`]: `Gatewayservice {title} is gemaakt.`,
  [`Gateway service {title} has been updated.`]: `Gatewayservice {title} is bijgewerkt.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Als het gebruik van ruimten ingeschakeld is, worden gatewayservices in elke ruimte beheerd. Ga naar de ruimte-instellingen om de gatewayservices te beheren.`,
  [`Gateway version`]: `Gatewayversie`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Gateways per pagina`,
  [`Gather-and-send`]: `Verzamelen en verzenden`,
  [`Gather-only`]: `Alleen verzamelen`,
  [`General configuration`]: `Algemene configuratie`,
  [`General information about the API.`]: `Algemene informatie over de API.`,
  [`Generate`]: `Genereren`,
  [`Generate an OpenAPI definition for the proxy`]: `Een OpenAPI-definitie voor de proxy genereren`,
  [`Generate JSON Web Token (JWT)`]: `JWT (JSON Web Token) genereren`,
  [`Generate JWT`]: `JWT genereren`,
  [`Generate LTPA Token`]: `LTPA-token genereren`,
  [`Generate OAuth Access Tokens`]: `OAuth-toegangstoken genereren`,
  [`Generate OAuth access tokens`]: `OAuth-toegangstoken genereren`,
  [`Generate a default product`]: `Standaardproduct genereren`,
  [`Generate access token`]: `Toegangstoken genereren`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Genereer een LTPA-token, zodat uw API zijn identiteit veilig kan laten verifiëren bij de webservers van WebSphere Application Server.`,
  [`Generate an object`]: `Een object genereren`,
  [`Generate authorization code`]: `Autorisatiecode genereren`,
  [`Generate from sample JSON`]: `Genereren uit voorbeeld-JSON`,
  [`Generate from sample XML`]: `Genereren uit voorbeeld-XXL`,
  [`Generate tests automatically to build high quality APIs`]: `Tests automatisch genereren voor het bouwen van API's van hoge kwaliteit`,
  [`Generated OpenAPI 2.0 definition`]: `OpenAPI 2.0-definitie gegenereerd`,
  [`Generated default product for the API`]: `Standaardproduct voor de API gegenereerd`,
  [`Generated name for use in commands and API calls`]: `Gegenereerde naam voor gebruik in opdrachten en API-aanroepen`,
  [`German`]: `Duits`,
  [`Get Base DN`]: `Basis-DN ophalen`,
  [`Get Code`]: `Code ophalen`,
  [`Get Code Snippet`]: `Codesnippet ophalen`,
  [`Get Support`]: `Ondersteuning krijgen`,
  [`Get Token`]: `Token ophalen`,
  [`Get activation link`]: `Activeringslink ophalen`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Begin met het registreren van een gatewayservice. Het wordt dan mogelijk om de eindpunten te testen wanneer u andere services configureert. Nadat u een service hebt geregistreerd, wordt die service hier afgebeeld. [Meer informatie]({link})`,
  [`Get subscription ID`]: `Abonnements-ID ophalen`,
  [`Getting Started`]: `Aan de slag`,
  [`Getting started`]: `Aan de slag`,
  [`Git information`]: `Git-informatie`,
  [`Github`]: `Github`,
  [`Global policies`]: `Algemene beleidsdefinities`,
  [`Go to Spaces`]: `Naar Ruimten gaan`,
  [`Go to the Test tab`]: `Naar het tabblad Testen gaan`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `GraphQL-aanroeplimieten`,
  [`GraphQL service`]: `GraphQL-service`,
  [`GraphQL schema`]: `GraphQL-schema`,
  [`GraphQL Schema`]: `GraphQL-schema`,
  [`GraphQL schema has been successfully validated.`]: `Het GraphQL-schema is gevalideerd.`,
  [`GraphQL Schema URL`]: `URL van GraphQL-schema`,
  [`GraphQL send type`]: `GraphQL-verzendtype`,
  [`GraphQL Websocket Upgrade`]: `GraphQL-websocketupgrade`,
  [`Group Based DN`]: `Groepsgebaseerde DN`,
  [`Group {title} added`]: `Groep {title} toegevoegd`,
  [`Group {title} updated`]: `Groep {title} bijgewerkt`,
  [`Group {title} has been deleted`]: `Groep {title} is gewist`,
  [`Groups`]: `Groepen`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `HOSTNAAM`,
  [`HOST URL`]: `HOST-URL`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `HTML-formulier`,
  [`HTML form time limit`]: `Tijdslimiet voor HTML-formulier`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `HTTP/2 vereist`,
  [`HTTP Header`]: `HTTP-header`,
  [`HTTP Method`]: `HTTP-methode`,
  [`HTTP Type`]: `HTTP-type`,
  [`HTTP Version`]: `HTTP-versie`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Handvatten`,
  [`Hard limit`]: `Vaste limiet`,
  [`Header`]: `Header`,
  [`Header control`]: `Headerbesturing`,
  [`Header name payload`]: `Payload voor headernaam`,
  [`Header name token`]: `Token voor headernaam`,
  [`Headers`]: `Headers`,
  [`Hello`]: `Hallo`,
  [`Hello!`]: `Hallo`,
  [`Hello [IBM](url)`]: `Hallo [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Hallo {name}`,
  [`Hello {name}!`]: `Hallo {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Hallo {name}, [zie IBM-profiel](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nHartelijk dank voor uw inschrijving voor een account op de ontwikkelaarsportal {catalogTitle}.\n\nVolg deze link om uw registratie te voltooien:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nDe beheerder voor de ontwikkelaarsportal{consumerOrg} heeft de app\n{appName} opnieuw geïnstalleerd. API-aanroepen vanuit deze app\nworden niet meer geblokkeerd door de API-gateway.\n\nWilt u de details van de app bekijken, volg dan deze link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nDe beheerder voor de ontwikkelaarsportal {consumerOrg} heeft de app\n{appName} onderbroken. API-aanroepen vanuit deze app zullen worden geblokkeerd door de API-gateway.\n\nWilt u de details van de app bekijken, volg dan deze link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nDe gebruiker {originator} heeft om toestemming gevraagd om zich te abonneren op het plan {planName}\nvoor versie {productVersion} van het API-product {productName}in de catalogus\n{catalog}.\n\nOm de aanvraag te openen in uw takenlijst, volgt u deze link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nGebruiker {originator} heeft om toestemming gevraagd om zich met toepassing {appName}\nte abonneren op plan {planName} voor versie {productVersion} van het API-product {productName}\nin de catalogus {catalog}.\n\nOm de aanvraag te openen in uw takenlijst, volgt u deze link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nDe gebruiker {originator} heeft om toestemming gevraagd voor het {action} van de app {appName} in de\ncatalogus {catalog}.\n\nOm de aanvraag te openen in uw takenlijst, volgt u deze link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nDe gebruiker {originator} heeft om toestemming gevraagd voor het {action} van versie {version}\nvan het API-product {productName} in de catalogus {catalog}.\n\nOm de aanvraag te openen in uw takenlijst, volgt u deze link:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hallo {{firstName}},\n\nWe hebben een verzoek ontvangen om het wachtwoord van uw gebruikersaccount\n{{username}} in de ontwikkelaarsportal {{catalog}} te resetten.\n\nHebt u geen verzoek ingediend, dan kunt u dit bericht negeren. Anders\nkunt u uw wachtwoord resetten door link te volgen:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Hallo {firstName},\n\nWe hebben een verzoek ontvangen om het wachtwoord van uw gebruikersaccount\n{username} in IBM API Connect te resetten.\n\nVoor het resetten van uw wachtwoord kunt u deze link gebruiken:\n\n{link}\n\n(Hebt u geen verzoek ingediend, dan kunt u dit bericht negeren.)\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nWe hebben een verzoek ontvangen om het wachtwoord van uw gebruikersaccount\n{username} in de ontwikkelaarsportal {catalog} te resetten.\n\nHebt u geen verzoek ingediend, dan kunt u dit bericht negeren. Anders\nkunt u uw wachtwoord resetten door deze link te volgen:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hallo {firstName},\n\nUw aanvraag voor het maken van een abonnement voor de app {appName} in de\ncatalogus {catalog} wacht op goedkeuring. Zodra uw aanvraag is behandeld,\nsturen we u nogmaals een bericht.\n\nDe status van de app kunt u hier checken:\n\n{link}\n\nWilt u de aanvraag op enig moment annuleren, volg dan deze link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {{firstName}},\n\nUw verzoek om voor de app {{appName}} een abonnement op het plan \n{{planName}} voor het API-product {{productName}} versie\n{{productVersion}} te maken, is goedgekeurd.\n\n{{#if comments}}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n"{{comments}}"\n\n{{/if}}\nDe status van de app kun u hier checken:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw verzoek om voor de app {appName} een abonnement op het plan \n{planName} voor het API-product {productName} versie\n{productVersion} te maken, is goedgekeurd.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{comments}\n\n{/if}\nDe status van de app kunt u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {{firstName},\n\nUw verzoek om voor de app {{appName}} een abonnement op het plan \n{{planName}} voor het API-product {{productName}} versie\n{{productVersion}} te maken, is afgewezen.\n\n{{#if comments}}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n"{{comments}}"\n\n{{/if}}\nDe status van de app kun u hier checken:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {{firstName}},\n\nUw verzoek voor het {{action}} van het API-product {{productName}}:{{version}} in de\ncatalogus {{catalog}} is goedgekeurd.\n\n{{#if comments}}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{{comments}}\n\n{{/if}}\nDe status van het product kun u hier checken:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw verzoek voor het {action} van het API-product {productName}:{version} in de\ncatalogus {catalog} is goedgekeurd.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{comments}\n\n{/if}\nDe status van het product kun u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {firstName}},\n\nUw verzoek voor het {action}} van het API-product {productName}:{version} in de\ncatalogus {catalog} is afgewezen.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{comments}\n\n{/if}\nDe status van het product kun u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw verzoek voor het {action} van API-product {productName}:{version} in de\ncatalogus {catalog} is afgewezen.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{comments}\n\n{/if}\nDe status van het product kun u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Hallo {firstName},\n\nUw verzoek voor het {action} van het API-product {productName}:{version} in de\ncatalogus {catalog} wacht op goedkeuring. Zodra uw aanvraag is behandeld,\nsturen we u nogmaals een bericht.\n\nDe status van het product kunt u hier checken:\n\n{link}\n\nWilt u uw aanvraag op enig moment annuleren, volg dan deze link:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw aanvraag voor het {action} van de app {appName} in de\nontwikkelaarsportal {consumerOrg} is goedgekeurd.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n"{comments}"\n\n{/if}\nDe status van de app kun u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw aanvraag voor het {action} van de app {appName} in de\nontwikkelaarsportal {consumerOrg} is goedgekeurd.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{comments}\n\n{/if}\nDe status van de app kunt u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw aanvraag voor het {action} van de app {appName} in de\nontwikkelaarsportal {consumerOrg} developer is afgewezen.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n"{comments}"\n\n{/if}\nDe status van de app kun u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw aanvraag voor het {action} van de app {appName} in de\nontwikkelaarsportal {consumerOrg} developer is afgewezen.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{comments}\n\n{/if}\nDe status van de app kunt u hier checken:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Hallo {firstName},\n\nUw aanvraag voor het {action} van de app {appName} app in het ontwikkelaarsportal\n{consumerOrg} wacht op goedkeuring. Zodra uw aanvraag is behandeld,\nsturen we u nogmaals een bericht.\n\nWilt u uw aanvraag op enig moment annuleren, volg dan deze link:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe accountbeheerder voor IBM API Connect heeft u uitgenodigd om een\nAPI-providerorganisatie te maken. Een providerorganisatie maakt het voor u en uw\nteam mogelijk om API's te ontwikkelen en om catalogi met API-producten te beheren.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe beheerder van de API-catalogus {catalog}, die eigendom is van de\nAPI-providerorganisatie {org}  in IBM API Connect, heeft u uitgenodigd\neen ruimte aan te maken in de catalogus. Een ruimte maakt het voor\nu en uw team mogelijk om een set API-producten in een API-catalogus te beheren.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe beheerder van de API-catalogus {catalog}, die eigendom is van de\nAPI-providerorganisatie {org} in IBM API Connect, heeft u uitgenodigd\nvoor de catalogus.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe beheerder van de ontwikkelaarsportal {catalog} heeft u uitgenodigd\nom een API-consumentorganisatie te maken. Met een consumentorganisatie\nkunnen u en uw team client-apps registreren en toegang krijgen tot API's.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe beheerder van de API-consumentorganisatie {consumerOrg} in de\nontwikkelaarsportal {catalog} heeft u uitgenodigd voor de organisatie.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe beheerder voor de API-providerorganisatie {org} in IBM API\nConnect heeft u uitgenodigd om een API-catalogus te maken. Een catalogus maakt het\nvoor u en uw team mogelijk om een verzameling API-producten te\npubliceren en te beheren.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe beheerder voor de API-providerorganisatie {org} in IBM API\nConnect heeft u uitgenodigd om toe te treden tot de organisatie.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe beheerder van de ruimte {space} in de API-catalogus {catalog},\ndie eigendom is van de API-providerorganisatie {org} in IBM API Connect,\nheeft u uitgenodigd om een ruimte aan te maken in die catalogus.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Hallo,\n\nHet wachtwoord voor uw gebruikersaccount {username}\nin IBM API Connect is gewijzigd.\n\nU kunt zich hier aanmelden bij API Connect:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Hallo,\n\nHet wachtwoord voor uw gebruikersaccount {username}\nin de ontwikkelaarsportal {portalTitle} is gewijzigd.\n\nU kunt zich hier aanmelden bij de ontwikkelaarsportal {portalTitle}:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Hallo,\n\nDe systeembeheerder voor IBM API Connect heeft u uitgenodigd toe\nte treden tot de organisatie {org}.\n\nKlik op onderstaande link om verder te gaan:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Hallo,\n\nDit is een testbericht, afkomstig van de mailserver die geconfigureerd is voor IBM API Connect:\n{mailServer}.\n\nAls u dit bericht hebt ontvangen zoals verwacht, is de test geslaagd!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo,\n\nDe gebruiker {originator} heeft zijn of haar aanvraag voor een abonnement op het plan {planName} voor\nversie {productVersion} van het API-product {productName} in de catalogus {catalog} ingetrokken.\n\nU hoeft verder niets te doen.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo,\n\nDe gebruiker {originator} heeft zijn of haar aanvraag voor het {action} van de app {appName} in de  catalogus {catalog} ingetrokken.\n\nU hoeft verder niets te doen.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo,\n\nDe gebruiker {originator} heeft zijn of haar aanvraag voor het {action} van versie {version} van het\nAPI-product {productName} in de catalogus {catalog} ingetrokken.\n\nU hoeft verder niets te doen.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Hallo, dit is een testbericht, afkomstig van de mailserver die geconfigureerd is voor IBM API Connect {{{mailServer}}}. Als u dit bericht hebt ontvangen zoals verwacht, is de test geslaagd`,
  [`Hello|header`]: `Hallo`,
  [`Help`]: `Help`,
  [`Hi, Steve (todo)`]: `Hallo, Steve (todo)`,
  [`Hide`]: `Verbergen`,
  [`Hide field`]: `Veld verbergen`,
  [`Hide in schema`]: `Verbergen in schema`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Nieuwe typen, velden, instructies of optionele argumenten verbergen. Items die niet meer worden ondersteund door de back-end worden verwijderd.`,
  [`High`]: `Hoog`,
  [`History`]: `Historie`,
  [`Histories`]: `Geschiedenissen`,
  [`History log`]: `Historielogboek`,
  [`Home`]: `Home`,
  [`Host`]: `Host`,
  [`Host context variable`]: `Contextvariabele host`,
  [`Host name`]: `Hostnaam`,
  [`Host value (if present)`]: `Hostwaarde (indien aanwezig)`,
  [`Hostname`]: `Hostnaam`,
  [`Hours`]: `Uur`,
  [`Http basic authentication schema`]: `HTTP-basisverificatieschema`,
  [`Hmm, your search didn't return any results.`]: `Uw zoekopdracht heeft geen resultaten opgeleverd.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `IBM API Connect-cloud`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `IBM Cloud-verificatie`,
  [`IBM Cloud Docs`]: `IBM Cloud-documentatie`,
  [`IBM Schema`]: `IBM-schema`,
  [`IBM managed`]: `Door IBM beheerd`,
  [`ID Name`]: `ID-naam`,
  [`ID token custom claims`]: `Aangepaste claims voor ID-token`,
  [`ID token issuer`]: `Verstrekkende instantie van ID-token`,
  [`ID token signing algorithm`]: `Ondertekeningsalgoritme voor ID-token`,
  [`ID token signing crypto object`]: `Crypto-object voor ondertekening ID-token`,
  [`ID token signing key`]: `Ondertekeningssleutel voor ID-token`,
  [`ID token signing key identifier`]: `Ondertekeningssleutel-ID voor ID-token`,
  [`IDENTITY PROVIDER`]: `ID-PROVIDER`,
  [`IDENTITY PROVIDER SUPPORT`]: `ONDERSTEUNING ID-PROVIDER`,
  [`Identification`]: `Identificatie`,
  [`Identity extraction`]: `ID-extractie`,
  [`If`]: `Als`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Als Productiemodus is uitgeschakeld, wat betekent dat dit een ontwikkelcatalogus is, worden alle publicatiebewerkingen geforceerd en worden eventuele conflicten automatisch opgelost, zodat u bijvoorbeeld tijdens het testen herhaaldelijk dezelfde productversie opnieuw kunt publiceren.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Als Productiemodus is ingeschakeld, wat betekent dat dit een productiecatalogus is, kunt u een product niet naar de catalogus publiceren als er conflicten zijn met producten die al zijn gepubliceerd. U moet eventuele conflicten oplossen voordat u de publicatiebewerking opnieuw probeert.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Als het gebruik van ruimten ingeschakeld is in een catalogus, kunt u de leden binnen de ruimte beheren. Bij het beheren van het lidmaatschap van een ruimte gaat het om het toevoegen van nieuwe gebruikers aan die ruimte en het toewijzen van rollen aan de gebruikers.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Als deze optie ingeschakeld is, worden invoereigenschappen met een nullwaarde toegewezen aan het uitvoerdocument. Anders worden deze eigenschappen weggelaten uit de uitvoer.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Als de productiemodus ingeschakeld is, worden het klaarzetten en publiceren niet afgedwongen. Worden er conflicten gevonden, dan worden die automatisch opgelost door het systeem. Het ongedaan maken van publicaties vindt automatisch plaats.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Als de ontvangers de e-mail niet hebben ontvangen, controleer dan de instellingen van de e-mailserver.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Als de ontvangers de e-mail niet hebben ontvangen, controleer dan de instellingen van de e-mailserver voor  {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Als de waarde is ingesteld op true, worden alle + tekens in de queryparameterwaarden van de doel-URL van de beleidsdefinities Invoke en Proxy gecodeerd met %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Als de waarde 'true' is ingesteld, worden aanvraagparameters waarnaar wordt verwezen door een variabeledefinitie op een URL voor het oproepen van een doel gedecodeerd op basis van een URL.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Als de waarde is ingesteld op true, stuurt het oproepbeleid een payload via een HTTP DELETE-methode.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Als dit aan staat (true), wordt de eigenschap afgeschermd of verwijderd uit de activiteitenlogboeken.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Als dit aan staat (true), wordt de eigenschap aan het begin van de uitvoering afgeschermd of verwijderd uit de invoer.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Als dit aan staat (true), wordt de eigenschap afgeschermd of verwijderd uit de respons.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Als het gebruik van ruimten ingeschakeld is in een catalogus, kunt u de toegang van gebruikers binnen de ruimte beheren. U beheert de toegang door op te geven welke machtigingen er aan gebruikersrollen worden toegewezen. Welke machtigingen er standaard aan een rol worden toegewezen wanneer u een nieuwe ruimte maakt, hangt af van de instellingen in de sjabloon Standaardmachtigingen ruimte.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Als dit selectievakje is ingeschakeld (de standaardoptie), worden XML-naamruimten overgenomen van het bovenliggende element. Schakel het selectievakje uit als u wilt dat er expliciete naamruimten worden gebruikt voor het toewijzingsbeleid.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Als dit vakje is geselecteerd (de standaardoptie), worden XML-naamruimten in een document ingevoegd op de plaats waar ze voor het eerst worden gebruikt. Hef de selectie van dit vakje op als u wilt dat alle naamruimten worden gedefinieerd in het hoofd- (root-) element.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Als dit selectievakje is ingeschakeld (de standaardoptie), worden lege XML-elementen opgenomen in de uitvoer van het toewijzingsbeleid. Schakel het selectievakje uit als u niet wilt dat lege XML-elementen worden opgenomen in de uitvoer van het toewijzingsbeleid.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Als het selectievakje is ingeschakeld, is de naverwerking van toegewezen JSON-uitvoer ingeschakeld. Bij de naverwerking van JSON-uitvoer wordt het uitvoerschema gebruikt om ervoor te zorgen dat de eigenschapswaarden van hetzelfde gegevenstype zijn als de waarden die in het schema zijn gedefinieerd. Ook worden eigenschapswaarden van de uitvoer met een Badgerfish JSON-syntaxis genormaliseerd, die veroorzaakt is door objecttoewijzing van XML-invoer. Wis het selectievakje (de standaardwaarde) als u geen naverwerking van toegewezen JSON-uitvoer wilt.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Als het selectievakje ingeschakeld is (de standaardoptie), worden verwijzingen naar API Connect-variabelen in de toewijzingseigenschappen omgezet. Schakel het selectievakje uit als u wilt dat verwijzingen naar API Connect-variabelen in het toewijzingsbeleid worden genegeerd.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Als het selectievakje ingeschakeld is, worden verwijzingen naar API Connect-variabelen in de toewijzingseigenschappen omgezet. Schakel het selectievakje uit (de standaardoptie) als u wilt dat verwijzingen naar API Connect-variabelen in het toewijzingsbeleid worden genegeerd.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Als het selectievakje ingeschakeld is (de standaardoptie), wordt alleen het eerste element gebruikt als er een array wordt aangetroffen bij het doorlopen van de invoer. Schakel het selectievakje uit als u wilt dat alle arrayelementen worden gebruikt voor het toewijzingsbeleid.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Als het vakje geselecteerd is, worden XML-elementen waarvan het schema geconfigureerd is als het type Booleaans of Numeriek, geconverteerd naar het desbetreffende gegevenstype. Is dit vakje niet geselecteerd (de standaardoptie), dan worden de waarden van alle XML-elementen geretourneerd in een string.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Als dit selectievakje ingeschakeld is, worden cirkelverwijzingen op een geoptimaliseerde manier verwerkt bij evaluatie van complexe schematypen. Schakel dit selectievakje uit (de standaardoptie) om deze schematypen op een standaardmanier te evalueren.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Als de fout wordt veroorzaakt door een gebrek aan CORS-ondersteuning, klikt u op de link om de server in een nieuwe tabblad te openen. Als de browser aangeeft dat er een certificaatprobleem is, kunt u dit accepteren en hier terugkomen om nogmaals te testen.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Als dit aankruisvakje is ingeschakeld, worden voor alle eigenschappen van uitvoerarrays of onderliggende eigenschappen van een optioneel object dat in het schema is aangegeven, vereiste eigenschappen geïnitialiseerd naar een standaardwaarde, tenzij ze correct toegewezen zijn. Hiermee emuleert u hoe de toewijzing van versie 4 wordt uitgevoerd voor de vereiste eigenschappen van deze objecten. Hef de selectie van dit vakje op (de standaardwaarde) om deze objecten alleen uit te voeren met correct toegewezen gegevens.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Als dit selectievakje is ingeschakeld (standaard) en de eigenaar wordt gewijzigd, wordt het eigendom van onderliggende resources (ruimten) ook overgedragen aan de nieuwe eigenaar.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Als deze optie is geselecteerd, schrijft het toewijzingsbeleid XML-uitvoer naar message.body in de vorm van een ontleed XML-document. Standaard wordt de XML uitgevoerd als een XML-reeks. XML-uitvoer naar andere variabelen wordt altijd geschreven als een XML-reeks.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Als u de levenscyclusgoedkeuring voor de acties Klaarzetten, Publiceren of Vervangen inschakelt, kan het zijn dat bepaalde bewerkingen voor het maken, bewerken of testen van API's (waarbij deze acties automatisch worden uitgevoerd) niet meer correct werken. Weet u zeker dat u een of meer van deze goedkeuringen wilt inschakelen?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Als u een bestaande SOAP-service hebt die u zichtbaar wilt maken via een API-definitie van API Connect, kunt u een API maken en de SOAP-doelservice opgeven. API Connect maakt dan een API-definitie waarmee die SOAP-service wordt aangeroepen.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Als u uw wijzigingen opslaat door te klikken op "Ja. API-assemblage bijwerken" te klikken, wordt de API-assemblage die ten grondslag ligt aan deze OAuth-provider eveneens bijgewerkt. Hebt u de onderliggende API-assemblage echter aangepast en wilt u deze afzonderlijk bijwerken, klik dan op "Nee. API-assemblage niet bijwerken" en werk de onderliggende API-assemblage bij in de API-editor.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Als u nog steeds problemen hebt, uploadt u een taalbestand voor schemadefinities.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Als uw bestand zowel de persoonlijke als de openbare sleutel bevat, uploadt u het in stap 1`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Als u een gateway moet registreren die zich achter een firewall bevindt, raadpleegt u de IBM Cloud Docs voor informatie over het gebruik van een VPN of de Secure Gateway-service om de verbinding tot stand te brengen.`,
  [`Ignore`]: `Negeren`,
  [`Implement policy`]: `Beleid implementeren`,
  [`Implementation file is required`]: `Implementatiebestand is vereist`,
  [`Implicit`]: `Impliciet`,
  [`Import`]: `Importeren`,
  [`Import API`]: `API importeren`,
  [`Import API|title`]: `API importeren`,
  [`Import OpenAPI definition from asset repository`]: `OpenAPI-definitie importeren uit assetrepository`,
  [`Import Product`]: `Product importeren`,
  [`Import policy`]: `Beleid importeren`,
  [`Import a product from a service URL`]: `Een product importeren vanaf een service-URL`,
  [`Import a product from the definition file`]: `Een product importeren vanuit het definitiebestand`,
  [`Import an existing product`]: `Bestaand product importeren`,
  [`Import an OpenAPI definition from a URL`]: `OpenAPI-definitie definitie vanaf een URL`,
  [`Import an OpenAPI definition from a file`]: `OpenAPI-definitie definitie vanuit een bestand`,
  [`Import definitions`]: `Definities importeren`,
  [`Import from existing API service`]: `Importeren uit bestaande API-service`,
  [`Import from file`]: `Importeren uit bestand`,
  [`Importing an API definition`]: `Een API-definitie importeren`,
  [`Import|title`]: `Importeren`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Importeer de vereiste certificaten en CA-bundels in de certificaatbeheerservice.`,
  [`In Cookie Header`]: `In Cookie-header`,
  [`In WSSec Header`]: `In WSSec-header`,
  [`In order to invoke the endpoints above, you need to first`]: `Om de bovenstaande eindpunten te kunnen oproepen, moet u eerst`,
  [`Inactive`]: `Inactief`,
  [`Inc`]: `Inc`,
  [`Include empty`]: `Lege items opnemen`,
  [`Include free trial days`]: `Gratis proefdagen opnemen`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Incompatibele API's met de geconfigureerde gatewayservice kunnen niet worden geselecteerd.`,
  [`Incorrect username or password`]: `Gebruikersnaam of wachtwoord is onjuist`,
  [`Incorrect username, password, or credentials`]: `Gebruikersnaam, wachtwoord of legitimatiegegevens zijn onjuist`,
  [`Indicate if the invoke is the final action`]: `Geef aan of de aanroep de definitieve actie is`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Geeft aan of er een jti-claim (JWT-ID) moet worden toegevoegd aan de JWT. Als deze optie is geselecteerd, is de waarde van de jti-claim een UUID.`,
  [`Indicates whether the clients secret is required.`]: `Geeft aan of clientgeheimen vereist zijn.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Geeft aan of dit XSLT-invoerdocument de huidige payload voor de context gebruikt, of dat er geen invoer is.`,
  [`Indicates whether to stop processing if client security fails.`]: `Geeft aan of de verwerking moet worden gestopt als de beveiliging van de client mislukt.`,
  [`Infer from JSON schema`]: `Afleiden uit JSON-schema`,
  [`Infer from Sample`]: `Afleiden uit voorbeeld`,
  [`Info`]: `Info`,
  [`Information context variable`]: `Contextvariabele informatie`,
  [`Inject proxy headers`]: `Proxyheaders invoegen`,
  [`Inline schema`]: `Inline schema`,
  [`Input`]: `Invoer`,
  [`Input field`]: `Invoerveld`,
  [`Input object`]: `Invoerobject`,
  [`Insecure Server Connections`]: `Onbeveiligde serververbindingen`,
  [`Install API Connect CLI & API Designer|button text`]: `API Connect CLI &amp; API Designer installeren`,
  [`Install API Connect CLI & API Designer|title`]: `API Connect CLI &amp; API Designer installeren`,
  [`Install API Connect Toolkit`]: `API Connect-toolkit installeren`,
  [`Install the API Connect plugin`]: `Installeer de API Connect-plugin`,
  [`Instance ID`]: `Instantie-ID`,
  [`Instance name`]: `Instantienaam`,
  [`Instance owner`]: `Instance-eigenaar`,
  [`Interact to expand Tile`]: `Interactie voor uitvouwen van tegel`,
  [`Interact to collapse Tile`]: `Interactie voor samenvouwen van tegel`,
  [`Interval`]: `Interval`,
  [`Interface`]: `Interface`,
  [`Introspect`]: `Introspectie uitvoeren`,
  [`Introspect cache type`]: `Introspectie cachetype`,
  [`Introspect from Server URL`]: `Introspectie vanuit server-URL`,
  [`Introspect from URL`]: `Introspectie vanuit URL`,
  [`Introspect URL`]: `Introspectie-URL`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Introspectie met behulp van een andere URL wijzigt de URL van de GraphQL-server niet.`,
  [`Introspect token`]: `Introspectietoken`,
  [`Introspection`]: `Introspectie`,
  [`Introspection Path`]: `Introspectiepad`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Voer introspectie uit van het GraphQL-schema vanuit de server of upload het schema`,
  [`Introspect the GraphQL schema using the query`]: `Voer introspectie van het GraphQL-schema uit met behulp van de query`,
  [`Introspection URL`]: `Introspectie-URL`,
  [`Invalid HOST URL`]: `Ongeldige HOST-URL`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `Ongeldige URL. Geef een geldige HOST-URL voor API Manager op. Bijv. https://api-connect-host.com`,
  [`Invalid file URL`]: `Ongeldige bestands-URL`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Ongeldige URL-indeling - moet beginnen met http:// of https:// en moet minstens één teken bevatten`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Ongeldige URL-indeling, alleen de hostnaam moet worden opgegeven. dwz. syslog:// kan verwijderd worden`,
  [`Invalid emails.`]: `Ongeldige e-mails.`,
  [`Invalid JSONata expression`]: `Ongeldige JSONata-expressie`,
  [`Invalid remove target`]: `Ongeldig doel voor verwijderen`,
  [`Invalid assumed size`]: `Ongeldige aangenomen grootte`,
  [`Invalid cost target`]: `Ongeldig kostendoel`,
  [`Invalid list size`]: `Ongeldige lijstgrootte`,
  [`Invalid port value, must not be negative`]: `Ongeldige poortwaarde, mag niet negatief zijn`,
  [`Invalid sized fields`]: `Velden met ongeldige grootte`,
  [`Invalid value, length has to be greater than 1`]: `Ongeldige waarde, lengte moet groter zijn dan 1`,
  [`Invalid weight value`]: `Ongeldige waarde voor gewicht`,
  [`invitation`]: `uitnodiging`,
  [`Invitation`]: `Uitnodiging`,
  [`Invitation timeout`]: `Timeout van uitnodiging`,
  [`Invitation link will expire in {time}`]: `Uitnodigingslink vervalt na **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Uitnodigingslink vervalt na **{seconds} seconden ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Uitnodigingslink vervalt na __{value} {unit}__.`,
  [`Invitation sent to`]: `Uitnodiging verzonden aan`,
  [`Invitation sent to {email}`]: `Uitnodiging verzonden aan {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Uitnodiging voor een API-catalogus in IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Uitnodiging voor een API-consumentorganisatie in de ontwikkelaarsportal {catalog}`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Uitnodiging voor een API-providerorganisatie in IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Uitnodiging voor een API-ruimte in IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Uitnodiging voor een beheerorganisatie in IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Uitnodiging voor het maken van een API-catalogus in IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Uitnodiging voor het maken van een API-consumentorganisatie in de ontwikkelaarsportal {catalog}`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Uitnodiging voor het maken van een API-providerorganisatie in IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Uitnodiging voor het maken van een API-ruimte in IBM API Connect`,
  [`Invitation ttl has been created.`]: `Uitnodigings-TTL is gemaakt.`,
  [`Invitation ttl has been updated.`]: `Uitnodiging is bijgewerkt.`,
  [`Invitation ttl has not been created!`]: `Uitnodigings-TTL is niet gemaakt!`,
  [`Invitation ttl has not been updated!`]: `Uitnodiging is niet bijgewerkt!`,
  [`Invite`]: `Uitnodigen`,
  [`Invite catalog owner`]: `Eigenaar van catalogus uitnodigen`,
  [`Invite consumer organization owner`]: `Eigenaar van consumentorganisatie uitnodigen`,
  [`Invite member`]: `Lid uitnodigen`,
  [`Invite organization owner`]: `Eigenaar van organisatie uitnodigen`,
  [`Invite space owner`]: `Eigenaar van ruimte uitnodigen`,
  [`Invite a new consumer organization owner`]: `Nieuwe eigenaar van een consumentorganisatie uitnodigen`,
  [`Invite a new member`]: `Nieuw lid uitnodigen`,
  [`Invite a new provider organization owner`]: `Nieuwe eigenaar van een providerorganisatie uitnodigen`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Nodig een nieuwe gebruiker uit om zich als manager van een catalogus te registreren. Dit doet u door het e-mailadres van de ontvanger op te geven. Er wordt een uitnodigingsmail naar de gebruiker gestuurd met een link naar het registratieformulier`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Nodig een nieuwe gebruiker uit om zich als manager van een ruimte te registreren. Dit doet u door het e-mailadres van de ontvanger op te geven. Er wordt een uitnodigingsmail naar de gebruiker gestuurd met een link naar het registratieformulier.`,
  [`Invite by email`]: `Uitnodigen per e-mail`,
  [`Invite from User Registry`]: `Uitnodigen vanuit gebruikersregister`,
  [`Invite team member`]: `Teamlid uitnodigen`,
  [`Inviting members to consumer organizations is enabled`]: `Leden uitnodigen voor consumentenorganisaties is ingeschakeld`,
  [`Invocations`]: `Oproepen`,
  [`Invoke`]: `Oproepen`,
  [`Issuer Claim`]: `Issuer-claim`,
  [`Issuer Origin`]: `Oorsprong verstrekkende instantie`,
  [`Issuer common name`]: `Algemene naam verstrekkende instantie`,
  [`It might influence how the API is deployed`]: `Dit kan invloed hebben op de manier waarop de API wordt geïmplementeerd`,
  [`Italian`]: `Italiaans`,
  [`Items per page`]: `Items per pagina`,
  [`Items per page:`]: `Items per pagina:`,
  [`items selected`]: `items geselecteerd`,
  [`item selected`]: `item geselecteerd`,
  [`Iterate on`]: `Herhalen op`,
  [`Iterate?`]: `Herhalen?`,
  [`JSON objects describing re-usable channel parameters.`]: `JSON-objecten die herbruikbare kanaalparameters beschrijven.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `JSON-objecten die de berichten beschrijven die door de API worden geconsumeerd en geproduceerd.`,
  [`JSON Schema URL`]: `URL van JSON-schema`,
  [`JSON Schema preview`]: `Preview van JSON-schema`,
  [`JSON Web Token (JWT)`]: `JWT (JSON Web Token)`,
  [`JSON has been successfully validated`]: `JSON is gevalideerd`,
  [`JSON schema is not valid. Please correct the error.`]: `JSON-schema is ongeldig. Corrigeer de fout.`,
  [`JSON to XML`]: `JSON naar XML`,
  [`JWT ID Claim`]: `JWT-ID-claim`,
  [`JWT verification JWK`]: `JWK voor JWT-verificatie`,
  [`JWT verification crypto object`]: `Cryptografisch object voor JWT-verificatie`,
  [`Japanese`]: `Japans`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Wachtrij voor taken`,
  [`Jump to source`]: `Naar bron springen`,
  [`Just now`]: `Zojuist`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `SLEUTELUITWISSELING / VERSLEUTELING / MAC`,
  [`KEY SIZE`]: `SLEUTELGROOTTE`,
  [`Keystore`]: `Keystore`,
  [`keystore`]: `keystore`,
  [`Keep`]: `Behouden`,
  [`Keep Payload`]: `Payload behouden`,
  [`Key Encryption Algorithm`]: `Sleutelcoderingsalgoritme`,
  [`Key Name`]: `Sleutelnaam`,
  [`Key type`]: `Type sleutel`,
  [`Key name`]: `Sleutelnaam`,
  [`Keystore for the token has been updated.`]: `Keystore voor het token is bijgewerkt.`,
  [`Keystore Certificates`]: `Keystore-certificaten`,
  [`Keystore/truststore`]: `Keystore/truststore`,
  [`Korean`]: `Koreaans`,
  [`LABEL`]: `LABEL`,
  [`LABELS`]: `LABELS`,
  [`LAST MODIFIED`]: `LAATST GEWIJZIGD`,
  [`Last state changed`]: `Laatste status gewijzigd`,
  [`Large`]: `Groot`,
  [`Lax`]: `Lax`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `LDAP-groepen`,
  [`LDAP user registry`]: `LDAP-gebruikersregister`,
  [`LDAP registry`]: `LDAP-register`,
  [`LDAP registry name`]: `LDAP-register`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Het LDAP-gebruikersregister van de rol {{name}} ondersteunt geen toewijzing van externe groepen meer. Schakel de toewijzing van externe groepen in voor dit gebruikersregister of schakel de toewijzing van externe groepen uit in de rol voordat u opslaat.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Het LDAP-gebruikersregister van de rol {{name}} ondersteunt geen toewijzing van externe groepen meer. Selecteer een ander gebruikersregister met externe groepstoewijzing of schakel de toewijzing van externe groepen uit in de rol voordat u opslaat.`,
  [`LDAP search attribute`]: `LDAP-zoekkenmerk`,
  [`LOCATED IN`]: `AANWEZIG IN`,
  [`Located in`]: `Aanwezig in`,
  [`LTPA Key`]: `LTPA-sleutel`,
  [`LTPA Key Details`]: `Details LTPA-sleutel`,
  [`LTPA Keys`]: `LTPA-sleutels`,
  [`LTPA key file`]: `LTPA-sleutelbestand`,
  [`LTPA key password`]: `Wachtwoord LTPA-sleutel`,
  [`LUR data version`]: `LUR-gegevensversie`,
  [`LUR schema update date`]: `Wijzigingsdatum LUR-schema`,
  [`LUR schema version`]: `LUR-schemaversie`,
  [`LUR target data version`]: `Versie van LUR-doelgegevens`,
  [`LUR target schema version`]: `Versie van LUR-doelschema`,
  [`LUR update date`]: `LUR-updatedatum`,
  [`Last name`]: `Achternaam`,
  [`Last modified`]: `Laatst gewijzigd`,
  [`Launch portal`]: `Portal starten`,
  [`Launch IBM Automation management console`]: `IBM Automation Management-console starten`,
  [`Launch plan upgrade`]: `Upgrade van plan starten`,
  [`Learn more`]: `Meer informatie`,
  [`Learn More`]: `Meer informatie`,
  [`Legacy`]: `Legacy`,
  [`Length cannot be below {min}`]: `Lengte mag niet kleiner zijn dan {min}`,
  [`Length cannot exceed {max}`]: `Lengte mag niet groter zijn dan {max}`,
  [`Let's customize API Connect`]: `Laten we API Connect aanpassen`,
  [`Let's get started...`]: `Laten we beginnen...`,
  [`Let's get you up and running`]: `Aan de slag`,
  [`License`]: `Licentie`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `Levenscyclus`,
  [`Lifecycle approvals`]: `Goedkeuringen voor levenscyclus`,
  [`Lifecycle actions have been updated`]: `De levenscyclusacties zijn bijgewerkt`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Levenscyclusgoedkeuringen zijn ingeschakeld voor de sandboxcatalogus. Om de automatische optie API activeren te kunnen gebruiken, moet u eerst naar de instellingen van de sandboxcatalogus gaan en daar de levenscyclusgoedkeuringen uitschakelen.`,
  [`Limited fields`]: `Gelimiteerde velden`,
  [`Limit API calls on a per key basis`]: `Aantal API-aanroepen per sleutel beperken`,
  [`Limit fields`]: `Velden beperken`,
  [`Limit renegotiation`]: `Heronderhandeling beperken`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Laden`,
  [`Load query`]: `Query laden`,
  [`Load filter`]: `Filter voor laden`,
  [`Load schema from WSDL`]: `Schema laden vanuit WSDL`,
  [`Loading`]: `Bezig met laden`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Lokale gebruikersregisters (Local User Registries, LUR's) zijn de standaard gebruikersregisters die bij API Connect worden geleverd. Ze worden lokaal opgeslagen.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Lokale gebruikersregisters zijn de standaard gebruikersregisters die bij API Connect worden geleverd. Ze worden lokaal opgeslagen. Tijdens de installatie worden er twee LUR's geïnstalleerd en geconfigureerd. Daarnaast kunnen er aanvullende LUR's worden geconfigureerd.`,
  [`Local user registry`]: `Lokaal gebruikersregister`,
  [`Local User Registry has been created.`]: `Lokaal gebruikersregister is gemaakt.`,
  [`Location`]: `Locatie`,
  [`Location of ciphertext to decrypt`]: `De locatie van de versleutelde tekst die moet worden gedecodeerd`,
  [`Location of plaintext information to encrypt`]: `Locatie van de platte tekst die moet worden versleuteld`,
  [`Log`]: `Logboek`,
  [`Log in`]: `Aanmelden`,
  [`Log in to:`]: `Aanmelden bij:`,
  [`Log in with a different account`]: `Meld u aan met een ander account`,
  [`Log into IBM Cloud`]: `Aanmelden bij IBM Cloud`,
  [`Log out`]: `Afmelden`,
  [`Log in using the {userRegistryType} user registry`]: `Aanmelden met het {userRegistryType}-gebruikersregister`,
  [`Logging in with IBM Cloud ...`]: `Bezig met aanmelden bij IBM Cloud...`,
  [`Logging out`]: `Afmelden`,
  [`Logic`]: `Logica`,
  [`Login`]: `Aanmelden`,
  [`Logs`]: `Logboeken`,
  [`Longer, descriptive name for this policy.`]: `Langere beschrijvende naam voor dit beleid.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Het lijkt erop dat er een fout op de server is opgetreden tijdens de verwerking van uw opdracht.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Mailserver`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `LID`,
  [`MIME Types`]: `MIME-typen`,
  [`Mail server has been deleted.`]: `Mailserver is gewist.`,
  [`Mail server has not been deleted.`]: `Mailserver is niet gewist.`,
  [`Mailserver {arg} has been removed.`]: `Mailserver {arg} is verwijderd.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Controleer of de server actief is en of er netwerkconnectiviteit is.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Mogelijk wordt dit veroorzaakt doordat de CORS-ondersteuning op de doelserver ontbreekt, doordat de server niet beschikbaar is, doordat er een niet-vertrouwd certificaat is aangetroffen of omdat wederzijdse SSL-verificatie vereist is.`,
  [`This might be caused by one or more of the following:`]: `Dit kan een of meer van de volgende oorzaken hebben:`,
  [`Make a call and move on`]: `Aanroepen en verdergaan`,
  [`Make a call and wait for response`]: `Aanroepen en wachten op respons`,
  [`Manage`]: `Beheren`,
  [`Manage API life cycles`]: `API-levenscycli beheren`,
  [`Manage API product lifecycle approval permissions`]: `Machtigingen voor goedkeuring van API-productlevenscyclus beheren`,
  [`Manage API product lifecycle approvals permissions`]: `Machtigingen voor de levenscyclus van API-producten beheren`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `API-providerorganisaties, lokale en niet-lokale gateways en aanverwante instellingen beheren.`,
  [`Manage APIs`]: `API's beheren`,
  [`Manage Catalogs`]: `Catalogi beheren`,
  [`Manage Organizations`]: `Organisaties beheren`,
  [`Manage Resources`]: `Resources beheren`,
  [`Manage Settings`]: `Instellingen beheren`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Bestemmingen voor API-analyse beheren, waaronder API Connect, HTTP, Kafka, Elasticsearch en Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Lokale en niet-lokale gateways beheren die uw API's beveiligen.`,
  [`Manage catalogs`]: `Catalogi beheren`,
  [`Manage organizations`]: `Organisaties beheren`,
  [`Manage resources`]: `Resources beheren`,
  [`Manage settings`]: `Instellingen beheren`,
  [`Manage users`]: `Gebruikers beheren`,
  [`Manage active APIs and consumers`]: `Actieve API's en consumenten beheren`,
  [`Manage availability zones and services`]: `Beschikbaarheidszones en services beheren`,
  [`Manage policies`]: `Beleid beheren`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Wijzigingsverzoeken voor de levenscyclus van een product kunt u beheren, en datzelfde geldt voor aanvragen van toepassingsontwikkelaars voor API-abonnementen en toepassingsupgrades`,
  [`Manage roles and permissions`]: `Rollen en machtigingen beheren`,
  [`Manage the OAuth providers configured for API Manager`]: `De voor API Manager geconfigureerde OAuth-providers beheren`,
  [`Manage the user registries configured for API Manager`]: `De voor API Manager geconfigureerde gebruikersregisters beheren`,
  [`Manage the user registries configured for Cloud Manager`]: `De voor Cloud Manager geconfigureerde gebruikersregisters beheren`,
  [`Management`]: `Beheer`,
  [`Management Endpoint`]: `Beheereindpunt`,
  [`Management endpoint`]: `Beheereindpunt`,
  [`Management endpoint on the analytics service`]: `Beheereindpunt in de analyticsservice`,
  [`Service endpoint configuration`]: `Configuratie van service-eindpunt`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Eindpunt dat door de beheerservice wordt gebruikt om configuratiegegevens te verzenden naar de portalservice`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `TLS-clientprofiel dat door de beheerservice wordt gebruikt bij het communiceren met de portalservice`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Eindpunt dat door de beheerservice wordt gebruikt om configuratiegegevens te verzenden naar de gatewayservice`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `TLS-clientprofiel dat door de beheerservice wordt gebruikt bij het communiceren met de gatewayservice`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Gebruik in-cluster communicatie voor intern verkeer tussen de gateway en de beheerservice`,
  [`Use in-cluster communication for both ingestion and queries`]: `In-cluster communicatie gebruiken voor zowel ingestie als query's`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Gebruik in-cluster voor ingestie en extern voor query's, of vice versa`,
  [`Use external communication for both ingestion and queries`]: `Externe communicatie gebruiken voor zowel ingestie als query's`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Gebruik in-cluster communicatie voor intern verkeer tussen analytics en de beheerservice`,
  [`Management endpoint on the gateway service`]: `Beheereindpunt bij de gatewayservice`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Gebruik in-cluster communicatie voor intern verkeer tussen de portal en de beheerservice`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Als u in-cluster communicatie wilt gebruiken tussen de beheer-en portalsubsystemen, schakelt u deze switch in. Als u het niet zeker weet, laat deze schakelaar dan uitgeschakeld (de standaardwaarde).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Als u in-cluster communicatie wilt gebruiken tussen de beheer-en gatewaysubsystemen, schakelt u deze switch in. Als u het niet zeker weet, laat deze schakelaar dan uitgeschakeld (de standaardwaarde).`,
  [`Management endpoint on the portal service`]: `Beheereindpunt in de portalservice`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Deze optie is de standaardwaarde en wordt gebruikt voor analyseservices die worden geüpgraded vanaf een release van pre-10.0.5.3. Voor OVA-implementaties of als het analysesubsysteem zich in een andere omgeving bevindt dan is extern de enige optie die u kunt gebruiken. Zowel de gateway als de beheerservice communiceren met de analyseservice via het externe eindpunt. Dit is een Kubernetes-ingress of OpenShift-route, afhankelijk van uw platform.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `U kunt in-cluster selecteren als u een Kubernetes of OpenShift-implementatie hebt waarbij alle subsystemen zich binnen hetzelfde cluster bevinden. Wanneer deze optie is geselecteerd, communiceren de subsystemen beheer, gateway en analyse met elkaar via interne serviceeindpunten in plaats van extern toegankelijke ingitters (Kubernetes) of routes (OpenShift).`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `U kunt deze optie selecteren als u een Kubernetes of OpenShift-implementatie hebt en sommige van uw subsystemen in hetzelfde cluster zijn geïnstalleerd. Als deze optie is geselecteerd, kunt u verschillende communicatiemethoden gebruiken voor de verschillende communicatiestromen voor analysedoeleinden. De communicatie voor analysecommunicatie kan bijvoorbeeld in-cluster worden gebruikt, en de gateway voor analytics kan gebruikmaken van extern. U kunt deze configuratie kiezen als uw gateway zich in een andere cluster bevindt dan de rest van uw subsystemen.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Als u in de clustercommunicatie hebt opgegeven dat u de analyseservice hebt geregistreerd, kunt u de gateway inschakelen om API-eventgegevens te verzenden naar het interne analyseeindpunt.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Intern eindpunt gebruiken voor het inslikken van analysegegevens van de gatewayservice`,
  [`Endpoint configuration`]: `Externe communicatie gebruiken voor zowel ingestie als query's`,
  [`Portal endpoint configuration`]: `Configuratie van portal-eindpunt`,
  [`URL used by developer portal users to access the portal website`]: `URL die door gebruikers van de ontwikkelaar wordt gebruikt voor toegang tot de portalwebsite`,
  [`External ingestion endpoint on the analytics service`]: `Extern ingestie-eindpunt voor de analyseservice`,
  [`TLS client profile for external analytics ingestion endpoint`]: `TLS-clientprofiel voor het inslikken van eindpunt voor externe analyse`,
  [`Internal ingestion endpoint on the analytics service`]: `Intern inslikken-eindpunt voor de analyseservice`,
  [`TLS client profile for internal service endpoint`]: `TLS-clientprofiel voor interne service-eindpunt`,
  [`Manager`]: `Manager`,
  [`Manager Request Reset Password`]: `Verzoek aan manager om wachtwoord te resetten`,
  [`Manager Reset Password`]: `Wachtwoordreset door manager`,
  [`Manager Sign In`]: `Managersaanmelding`,
  [`Manages API provider organizations`]: `Beheert API-providerorganisaties`,
  [`Manages application developer communities`]: `Beheert community's van toepassingsontwikkelaars`,
  [`Manages the API product lifecycle`]: `Beheert de levenscyclus van API-producten`,
  [`Manage|link`]: `Beheren`,
  [`Manage|permission`]: `Beheren`,
  [`Manage|title`]: `Beheren:titel`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Keystoretoewijzing en keystorehistorie beheren voor tokens`,
  [`Map`]: `Toewijzing`,
  [`Map information from OIDC provider to an APIC user`]: `Informatie van OIDC-provider toewijzen aan een APIC-gebruiker`,
  [`Map the migration source plans to the migration target plans`]: `Koppel de bronplannen aan de doelplannen voor migratie`,
  [`Map the roles to groups`]: `De rollen toewijzen aan groepen`,
  [`Mapped from: `]: `Toegewezen uit: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Maximaliseren/minimaliseren`,
  [`Maximum Consent Time to Live (seconds)`]: `Levensduur maximale toestemming (seconden)`,
  [`Maximum consent`]: `Maximale toestemming`,
  [`Menu`]: `Menu`,
  [`Member`]: `Lid`,
  [`Member Invitation`]: `Uitnodiging van lid`,
  [`Member Invitation has been deleted.`]: `Uitnodiging van leden is gewist.`,
  [`Member has been deleted.`]: `Lid is gewist.`,
  [`Member {name} created`]: `Lid {name} is gemaakt`,
  [`Member {title} has been created.`]: `Lid {title} is gemaakt.`,
  [`Members`]: `Leden`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Ledenuitnodigingen`,
  [`Member Roles`]: `Lidrollen`,
  [`Merchant ID`]: `Merchant-ID`,
  [`Merge`]: `Samenvoegen`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Deze toewijzing samenvoegen met andere die in dezelfde doelarray werken?`,
  [`Message`]: `Bericht`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Bericht voor ontleden`,
  [`Message for resulting parsed data`]: `Bericht voor resulterende ontlede gegevens`,
  [`Metadata`]: `Metagegevens`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Metagegevens maken het mogelijk om bij het genereren van toegangstokens aangepaste metagegevens op te nemen. De metagegevens kunnen worden verzameld via een verificatie-URL, via een server op afstand waarop de metagegevens zijn opgeslagen, of beide.`,
  [`Micro Gateway policies`]: `Micro Gateway-beleidsdefinities`,
  [`Migrate`]: `Migreren`,
  [`Migrate Subscriptions`]: `Abonnementen migreren`,
  [`Migrate plans`]: `Plannen migreren`,
  [`Migrate subscription`]: `Abonnement migreren`,
  [`Migrate subscriptions`]: `Abonnementen migreren`,
  [`Migration Target`]: `Migratiedoel`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `Migratie van "{source}" (Product) naar "{target}" (Product) is uitgevoerd`,
  [`Migration from "{source}" failed`]: `Migratie vanuit "{source}" is mislukt`,
  [`Migration source`]: `Migratiebron`,
  [`Migration target`]: `Migratiedoel`,
  [`Minimum role`]: `Minimumrol`,
  [`Minimum output escaping rule`]: `Ontsnappingsregel minimale uitvoer`,
  [`Minutes`]: `Minuten`,
  [`Mode`]: `Modus`,
  [`Modifications`]: `Wijzigingen`,
  [`Modified`]: `Gewijzigd`,
  [`More`]: `Meer`,
  [`More than 20 events are waiting to be processed`]: `Er wachten meer dan 20 events op verwerking`,
  [`Move down`]: `Omlaag`,
  [`Move up`]: `Omhoog`,
  [`Must be a number`]: `Moet een getal zijn`,
  [`Must be a valid URI`]: `Moet een geldige URI zijn`,
  [`Must be a valid email`]: `Moet een geldige e-mail zijn`,
  [`Must be a valid path starting with /`]: `Moet een geldig pad zijn dat begint met /`,
  [`Must be a valid url`]: `Moet een geldige URL zijn`,
  [`Must be an integer`]: `Moet een geheel getal zijn`,
  [`Must be a valid url with no protocol`]: `Moet een geldige URL zijn zonder protocol`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Moet bestaan uit 64 hexadecimale tekens (voorafgegaan door "0x")`,
  [`Must start with '/'`]: `Moet beginnen met '/'`,
  [`Must start with https://`]: `Moet beginnen met https://`,
  [`Must start with https:// or $(`]: `Moet beginnen met https:// of $(`,
  [`Mutual auth`]: `Wederzijdse verif`,
  [`Mutual authentication`]: `Wederzijdse verificatie`,
  [`My account`]: `Mijn account`,
  [`Name`]: `Naam`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NIET`,
  [`Name cannot be empty.`]: `Naam mag niet leeg zijn.`,
  [`Name of Schema in OpenAPI Definitions`]: `Naam van schema in OpenAPI-definities`,
  [`Name of the LDAP user password attribute.`]: `Naam van het wachtwoordkenmerk voor de LDAP-gebruiker.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Naam van het LDAP-gebruikersregister voor de validatie. Selecteer een waarde in de lijst of typ zelf een naam.`,
  [`Name of the organization`]: `Naam van de organisatie`,
  [`Namespace inheritance`]: `Overname van naamruimte`,
  [`Namespace inlining`]: `Integratie van naamruimte`,
  [`Native`]: `Native`,
  [`Native OAuth provider`]: `Native OAuth-provider`,
  [`Native OAuth provider summary`]: `Samenvatting native OAuth-provider`,
  [`Navigate to Source View`]: `Naar bronview navigeren`,
  [`New`]: `Nieuw`,
  [`New API`]: `Nieuwe API`,
  [`New API Connect connection`]: `Nieuwe API Connect-verbinding`,
  [`New Application`]: `Nieuwe toepassing`,
  [`New Catalog`]: `Nieuwe catalogus`,
  [`New Consumer organization`]: `Nieuwe consumentorganisatie`,
  [`New Endpoint`]: `Nieuw eindpunt`,
  [`New native OAuth provider`]: `Nieuwe native OAuth-provider`,
  [`New OpenAPI`]: `Nieuwe Open API`,
  [`New OpenAPI directory`]: `Nieuwe OpenAPI-directory`,
  [`New Organization Title`]: `Titel nieuwe organisatie`,
  [`New Parameter`]: `Nieuwe parameter`,
  [`New Password`]: `Nieuw wachtwoord`,
  [`New product`]: `Nieuw product`,
  [`New Role`]: `Nieuwe rol`,
  [`New schema`]: `Nieuw schema`,
  [`New Test`]: `Nieuwe test`,
  [`New third party OAuth provider`]: `Nieuwe externe OAuth-provider`,
  [`New user`]: `Nieuwe gebruiker`,
  [`New applications will be created in the development state`]: `Nieuwe toepassingen die worden gemaakt, hebben de ontwikkelingsstatus`,
  [`Next`]: `Volgende`,
  [`Next page`]: `Volgende pagina`,
  [`No`]: `Nee`,
  [`No account?`]: `Geen account?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `Geen verificatie-URL of LDAP-gebruikersregisters geconfigureerd in de sandboxcatalogus`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `Geen verificatie-URL of LDAP-gebruikersregisters geconfigureerd in de sandboxcatalogus; maak er een [hier]({link})`,
  [`No Billing`]: `Geen facturering`,
  [`No billing integration has been selected.`]: `Er is geen factureringsintegratie geselecteerd.`,
  [`No billing integration can be shown when offline.`]: `Er kan geen factuurintegratie worden afgebeeld als u offline bent.`,
  [`No gateways`]: `Geen gateways`,
  [`No cache`]: `Geen cache`,
  [`No Cache`]: `Geen cache`,
  [`No Certificate Manager services available`]: `Geen Certificate Manager-services beschikbaar`,
  [`No default gateways`]: `Geen standaardgateways`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Geen duplicaat e-mailadres binnen het gebruikersregister. Dit geldt alleen voor de gebruikers met e-mail.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Er zijn geen compatibele gateways geconfigureerd voor de sandboxcatalogus. API-test (Uitproberen) in Explorer wordt niet ingeschakeld.`,
  [`No Errors`]: `Geen fouten`,
  [`No Histories found`]: `Geen histories gevonden`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Geen gatewayservices gevonden. Configureer een gatewayservice om de ondersteunde assemblagebeleidsdefinities af te beelden`,
  [`No gateways are configured for sandbox catalog`]: `Er zijn geen gateways geconfigureerd voor de sandboxcatalogus`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Er zijn geen gateways geconfigureerd voor de sandboxcatalogus. API-test (Uitproberen) in Explorer wordt niet ingeschakeld`,
  [`No LDAP or authentication URL user registries found.`]: `Er zijn geen gebruikersregisters van LDAP of verificatie-URL's gevonden.`,
  [`No limit on size of list`]: `Geen limiet voor grootte van lijst`,
  [`No OAuth providers configured in the sandbox catalog`]: `Geen enkele OAuth-provider geconfigureerd in de sandboxcatalogus`,
  [`No OAuth providers configured in the selected catalog`]: `Geen OAuth-providers geconfigureerd in de geselecteerde catalogus`,
  [`No Permission`]: `Geen toestemming`,
  [`No portal added to this catalog`]: `Geen portal aan deze catalogus toegevoegd`,
  [`No Roles exist`]: `Er zijn geen rollen`,
  [`No recent items found.`]: `Geen recente items gevonden.`,
  [`No TLS profile`]: `Geen TLS-profiel`,
  [`No TLS client profile`]: `Geen TLS-clientprofiel`,
  [`No Truststore`]: `Geen truststore`,
  [`No analytics service is configured`]: `Geen analyseservice geconfigureerd`,
  [`No analytics services found. You can create one [here]({link})`]: `Geen analyseservices gevonden. Om er een te maken, klikt u [hier]({link})`,
  [`No assemblies found.`]: `Geen assemblages gevonden.`,
  [`No available extensions`]: `Geen extensies beschikbaar`,
  [`No compatible flows configured in this OAuth provider`]: `Geen compatibele stromen geconfigureerd in deze OAuth-provider`,
  [`No consumer organization will be able to see this product.`]: `Geen enkele consumentorganisatie kan dit product zien.`,
  [`No debug data was found for this API call`]: `Er zijn geen foutopsporingsgegevens gevonden voor deze API-aanroep`,
  [`No details are available.`]: `Er zijn geen details beschikbaar.`,
  [`No existing products`]: `Geen bestaande producten`,
  [`No extensions have been configured for the API`]: `Er zijn geen extensies geconfigureerd voor de API dgecenter/SSMNED_2018/com.ibm.apic.toolkit.doc/tapim_sec_api_config_scheme_create.html)`,
  [`No flow configured in this OAuth provider`]: `Geen enkele stroom geconfigureerd in deze OAuth-provider`,
  [`No items found`]: `Geen items gevonden`,
  [`No non-member user to add.`]: `Geen niet-lid gebruiker om toe te voegen.`,
  [`No organization found`]: `Geen enkele organisatie gevonden`,
  [`No portal service exists:`]: `Er bestaat geen portalservice:`,
  [`No product APIs`]: `Geen product-API's`,
  [`No products or plans were found.`]: `Geen enkel product of plan gevonden.`,
  [`No provider organizations`]: `Geen provideorganisaties`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Geen respons ontvangen. Mogelijk wordt dit veroorzaakt doordat de CORS-ondersteuning op de doelserver ontbreekt, doordat de server niet beschikbaar is, doordat er een niet-vertrouwd certificaat is aangetroffen of omdat wederzijdse SSL-verificatie vereist is.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Geen resourcegroepen beschikbaar. Om aan de slag te gaan, maakt u een resourcegroep in IBM Cloud Account-resources.`,
  [`No security`]: `Geen beveiliging`,
  [`No services found`]: `Geen services gevonden`,
  [`No warnings`]: `Geen waarschuwingen`,
  [`No warnings.`]: `Geen waarschuwingen.`,
  [`No. Do not update API Assembly`]: `Nee. API-assemblage niet bijwerken`,
  [`Non-blocking`]: `Niet-blokkerend`,
  [`Non int slicing arguments`]: `Niet-int segmenteringsargumenten`,
  [`None`]: `Geen`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Geen van de geconfigureerde OAuth-providers komt overeen met het gatewaytype van deze API`,
  [`Normal`]: `Normaal`,
  [`Not Available`]: `Niet beschikbaar`,
  [`Not applicable for application grant type.`]: `Niet van toepassing op type toegangsverlening voor toepassing.`,
  [`Not valid before`]: `Niet geldig vóór`,
  [`Not valid after`]: ``,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Opmerking: Deze optie is uitgeschakeld wanneer de optie 'Automatisch product genereren' is geselecteerd.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Opmerking: Deze optie is uitgeschakeld als de optie 'Automatisch product genereren' is geselecteerd; in plaats daarvan wordt het standaardplan voor het gegenereerde product gebruikt.`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Opmerking: De voorkeur voor gatewayservices wordt gebruikt als het bereik voor alle andere voorkeuren. Beleidsdefinities en andere gatewayservice-specifieke content worden op basis van deze instelling geladen.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Opmerking: Deze optie is alleen bedoeld voor informatiedoeleinden en wordt bijgewerkt op basis van de bovenstaande optie voor testtoepassingen.`,
  [`Notices`]: `Kennisgevingen`,
  [`Notification template`]: `Meldingssjabloon`,
  [`Notification templates`]: `Meldingssjablonen`,
  [`Notification server configured`]: `Meldingsserver geconfigureerd`,
  [`Notifications`]: `Meldingen`,
  [`Null Badgerfish`]: `Null Badgerfish`,
  [`Null`]: `Null`,
  [`Number`]: `Aantal`,
  [`Number of Operations`]: `Aantal bewerkingen`,
  [`Numeric`]: `Numeriek`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `OAuth-provider`,
  [`OAuth provider`]: `OAuth-provider`,
  [`OAuth Provider Policy Assembly`]: `Beleidsassemblage OAuth-provider`,
  [`OAuth providers`]: `OAuth-providers`,
  [`OAuth Redirect URL`]: `OAuth omleidings-URL`,
  [`OAuth shared secret`]: `Gemeenschappelijk OAuth-geheim`,
  [`OAuth provider policy assembly`]: `Beleidsassemblage OAuth-provider`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `OAuth-provider {currentProviderName} is niet gevonden. Selecteer een geconfigureerde OAuth-provider.`,
  [`OAuth redirect URL`]: `OAuth omleidings-URL`,
  [`OAuth Shared Secret`]: `Gedeeld OAuth-geheim`,
  [`OAuth Signature Method`]: `OAuth-handtekeningmethode`,
  [`OAuth signature method`]: `OAuth-handtekeningmethode`,
  [`OAuth providers can be created and managed in the following list.`]: `In de volgende lijst kunnen er OAuth-providers worden gemaakt en beheerd.`,
  [`OAuth symmetric key name`]: `Naam symmetrisch OAuth-sleutel`,
  [`OAuth symmetric key value`]: `Waarde symmetrisch OAuth-sleutel`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2-toegangscodebeveiliging`,
  [`OAuth2 Application Security`]: `OAuth2-toepassingsbeveiliging`,
  [`OAuth2 Implicit Security`]: `Impliciete OAuth2-beveiliging`,
  [`OAuth2 Password Security`]: `OAuth2-wachtwoordbeveiliging`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `BEWERKINGS-ID`,
  [`OPTIONS`]: `OPTIES`,
  [`ORDER`]: `SORTEREN`,
  [`ORGANIZATION`]: `ORGANISATIE`,
  [`ORGANIZATION MANAGER`]: `ORGANISATIEMANAGER`,
  [`Organizations`]: `Organisaties`,
  [`Owner`]: `Eigenaar`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Als het gebruik van ruimten ingeschakeld is, worden Oauth-providers in elke ruimte beheerd. Ga naar de ruimte-instellingen om de Oauth-providers te beheren.`,
  [`Off`]: `Uit`,
  [`Offline`]: `Offline`,
  [`Old schema`]: `Oud schema`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch maakt het mogelijk om te zoeken naar API's, producten, toepassingen, abonnementen en andere resources binnen een providerorganisatie of catalogus. Dit kan u daarnaast helpen bij het ontdekken van de relaties tussen deze resources.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch voor alle ondersteunde lijstpagina's`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch voor concept-API's en producten`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch voor gepubliceerde producten in catalogi en ruimten`,
  [`Omnisearch for consumer organizations`]: `Omnisearch voor consumentorganisaties`,
  [`Omnisearch for applications`]: `Omnisearch voor toepassingen`,
  [`Omnisearch for requested approvals`]: `Omnisearch voor aangevraagde goedkeuringen`,
  [`Omnisearch for approvals tasks`]: `Omnisearch voor goedkeuringstaken`,
  [`Omnisearch for product apis`]: `Omnisearch voor product-API's`,
  [`Omnisearch for subscriptions`]: `Omnisearch voor abonnementen`,
  [`Omnisearch for members`]: `Omnisearch voor leden`,
  [`Omnisearch for provider organizations`]: `Omnisearch voor proviand organisaties`,
  [`Disable the new editor for OpenAPI2`]: `De nieuwe editor voor OpenAPI2 uitschakelen`,
  [`On`]: `Aan`,
  [`Onboarding`]: `Onboarding`,
  [`Onboarding Edit Admin Org`]: `Onboarding bewerken - beheerorganisatie`,
  [`Onboarding Edit Cloud Org`]: `Onboarding bewerken - cloudorganisatie`,
  [`Onboarding Edit Reset Password`]: `Onboarding bewerken - wachtwoord resetten`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Wanneer deze geïnstalleerd is, gebruikt u de IBM Cloud CLI om het doel op te geven voor het account en de regio waarin u API Connect beschikbaar hebt gesteld. Vervolgens probeert u de CLI uit door al uw API Connect-providerorganisaties te vermelden.`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Een of meer JSON-objecten die de door de API gebruikte en geproduceerde schema's beschrijven.`,
  [`One or more JSON representations for parameters`]: `Een of meer JSON-representaties voor parameters`,
  [`One time use access token`]: `Toegangstoken voor eenmalig gebruik`,
  [`One time use refresh token`]: `Vernieuwingstoken voor eenmalig gebruik`,
  [`Online`]: `Online`,
  [`Only one API file should be present in the zip file.`]: `Er moet slechts één API-bestand aanwezig zijn in het ZIP-bestand.`,
  [`OpenAPI Specification Version`]: `Versie OpenAPI-specificatie`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `OpenID Connect (OIDC) levert een extra verificatieprotocol op basis van OAuth 2.0. OIDC maakt gebruik van gebruikersgegevens die zijn gecodeerd in een JSON-webtoken of JWT. Als u OpenID Connect inschakelt, wordt er een sjabloon voor het genereren van ID-tokens verstrekt, samen met toegangstokens, en wordt het vereiste assemblagebeleid automatisch gemaakt.`,
  [`Open a Directory`]: `Een directory openen`,
  [`Open in external editor`]: `Openen in externe editor`,
  [`Open the server`]: `De server openen`,
  [`OpenAPI extensions`]: `OpenAPI-extensies`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `OpenID Connect wordt nog niet ondersteund als gatewayversie 6000 is`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `Open ID Connect-instellingen van de gebruiker zijn alleen van toepassing als de geselecteerde typen verlening een of meer van de volgende typen zijn: Impliciet en Toegangscode.`,
  [`Operation`]: `Bewerking`,
  [`Operation Id`]: `Bewerkings-ID`,
  [`Operation switch`]: `Bewerkingschakelaar`,
  [`Operation Switch`]: `Wisselen van bewerking`,
  [`Operations`]: `Bewerkingen`,
  [`Operator build date`]: `Builddatum operator`,
  [`Operator build tag`]: `Buildtag operator`,
  [`Operator version`]: `Operatorversie`,
  [`Optionally select a CA bundle`]: `Kies desgewenst een CA-bundel`,
  [`Optimize schema definition`]: `Schemadefinitie optimaliseren`,
  [`Optional`]: `Optioneel`,
  [`Or`]: `Of`,
  [`Or create a new application and subscribe to the selected plan`]: `Of maak een nieuwe toepassing en abonneer u op het geselecteerde plan`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Of maak een nieuw product en publiceer het in naar uw sandboxcatalogus`,
  [`Order`]: `Volgorde`,
  [`Org`]: `Org`,
  [`Org owner`]: `Org-eigenaar`,
  [`Organization`]: `Organisatie`,
  [`Organization:`]: `Organisatie:`,
  [`Organization information`]: `Organisatiegegevens`,
  [`Organization Manager`]: `Organisatiemanager`,
  [`Organization has been updated.`]: `Organisatie is bijgewerkt.`,
  [`Organizations will be listed here.`]: `Organisaties worden hier opgesomd.`,
  [`Otherwise`]: `Overig`,
  [`Output`]: `Uitvoer`,
  [`Output Claims`]: `Uitvoerclaims`,
  [`Output array`]: `Uitvoerarray`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Hoofdelement uitvoeren zelfs als dit niet vereist is voor een correct ingedeeld XML-document.`,
  [`Output variable`]: `Uitvoervariabele`,
  [`Override API consume types`]: `API-verbruikstypen vervangen`,
  [`Override API produce types`]: `API-productietypen vervangen`,
  [`Override API security definitions`]: `API-definities voor beveiliging vervangen`,
  [`Override rate limits`]: `Aanroeplimieten overschrijven`,
  [`Override default "provider" settings with configuration from this URL`]: `Standaardinstellingen voor "provider" vervangen door configuratie afkomstig van deze URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Standaardinstellingen voor "provider" vervangen door configuratie afkomstig van deze literaal`,
  [`Override plan rate limits`]: `Aanroeplimieten van plan overschrijven`,
  [`Override plan rate limits for individual operation`]: `Aanroeplimieten van plan overschrijven voor afzonderlijke bewerking`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Timeout voor uitnodiging van consumentenorganisaties vervangen met catalogus-timeout voor uitnodigingen`,
  [`Overview`]: `Overzicht`,
  [`Owner scope check`]: `Controle op eigenaarsbereik`,
  [`Owner's Email`]: `E-mailadres van eigenaar`,
  [`Owner's Name`]: `Naam eigenaar`,
  [`Owner's email`]: `E-mail eigenaar`,
  [`Owns and administers the API provider organization`]: `Is eigenaar van de API-providerorganisatie en beheert deze`,
  [`Owns and administers the admin organization`]: `Is eigenaar van de beheerorganisatie en beheert deze`,
  [`Owns and administers the app developer organization`]: `Is eigenaar en beheerder van een organisatie die apps ontwikkelt`,
  [`Owns and administrates the app developer organization`]: `Is eigenaar en beheerder van een organisatie die apps ontwikkelt`,
  [`PARAMETER NAME`]: `NAAM PARAMETER`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `PCRE die wordt gebruikt om de aud-claim (Audience) te valideren.`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `PCRE die wordt gebruikt om de iss-claim (Issuer) te valideren.`,
  [`PER`]: `PER`,
  [`Per`]: `Per`,
  [`Plan`]: `Plan`,
  [`Plan:`]: `Plan:`,
  [`pending Consumer organization invitation`]: `uitnodiging voor consumentorganisatie in behandeling`,
  [`PLAN`]: `PLAN`,
  [`PLANS`]: `PLANNEN`,
  [`Policy`]: `Beleid`,
  [`policy`]: `beleid`,
  [`POLICIES`]: `BELEID`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUCT`,
  [`Product Plan`]: `Productplan`,
  [`Product Plans`]: `Productplannen`,
  [`Product Plans per page`]: `Productplannen per pagina`,
  [`Property name`]: `Naam eigenschap`,
  [`Property type`]: `Type eigenschap`,
  [`Property example`]: `Voorbeeld van eigenschap`,
  [`Property description`]: `Beschrijving eigenschap`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Pagina niet gevonden`,
  [`Parameter name`]: `Parameternaam`,
  [`Parameter control`]: `Parameterbesturing`,
  [`Parameters`]: `Parameters`,
  [`Parent`]: `Bovenliggend item`,
  [`Parse`]: `Ontleden`,
  [`Parse settings URL reference`]: `Ontledingsinstellingen URL-verwijzing`,
  [`Parse settings literal configuration`]: `Ontledingsinstellingen literaalconfiguratie`,
  [`Parse settings object reference`]: `Ontledingsinstellingen objectverwijzing`,
  [`Parse the GraphQL query`]: `De GraphQL-query ontleden`,
  [`Parse the response from the GraphQL backend server`]: `De respons ontleden van de GraphQL-backendserver`,
  [`Parse XML output`]: `XML-uitvoer ontleden`,
  [`Pass-through`]: `Pass-through`,
  [`Password`]: `Wachtwoord`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `Wachtwoord is gewijzigd voor uw gebruikersaccount in IBM API Connect`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `Wachtwoord is gewijzigd voor uw gebruikersaccount in ontwikkelaarsportal {portalTitle}`,
  [`Password context variable`]: `Contextvariabele wachtwoord`,
  [`Password is invalid. Re-enter the password.`]: `Wachtwoord is ongeldig. Voer het wachtwoord opnieuw in.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `Aanvraag voor reset van wachtwoord voor uw gebruikersaccount in IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Aanvraag voor reset van wachtwoord voor uw account in ontwikkelaarsportal {catalog}`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Wachtwoorden moeten ten minste één teken uit twee van de volgende categorieën bevatten: kleine letters, hoofdletters, cijfers en interpunctie (b.v. !, $, #, %).`,
  [`Passwords must contain eight or more characters`]: `Wachtwoorden moeten acht of meer tekens lang zijn`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Wachtwoorden moeten acht of meer tekens lang zijn, met ten minste één teken uit twee van de volgende categorieën: kleine letters, hoofdletters, cijfers en interpunctie (b.v. !, $, #, %). Bovendien mag hetzelfde teken niet vaker dan twee keer achter elkaar staan.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `In wachtwoorden mag hetzelfde teken niet vaker dan twee keer achter elkaar staan.`,
  [`Passwords should match`]: `Wachtwoorden moeten gelijk zijn`,
  [`Path`]: `Pad`,
  [`Path parameters`]: `Padparameters`,
  [`Path name`]: `Pad`,
  [`Paths`]: `Paden`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Paden geven aan welke REST-resources er zichtbaar worden gemaakt door de API. Een bewerking koppelt een pad aan een HTTP-verb, parameters en definities voor aanvraag en respons.`,
  [`Payments Authorization`]: `Betalingsautorisatie`,
  [`Payment method`]: `Betalingsmethode`,
  [`Pending`]: `In behandeling`,
  [`Pending State`]: `Status In behandeling`,
  [`Pending approval to be published in catalog`]: `Wacht op goedkeuring om te worden gepubliceerd in catalogus`,
  [`Pending approval to be staged in catalog`]: `Wacht op goedkeuring om te worden klaargezet in catalogus`,
  [`Pending consumer organization invitations`]: `Uitnodigingen voor consumentorganisaties in behandeling`,
  [`View endpoints`]: `Eindpunten bekijken`,
  [`View base endpoints for this API`]: `Basiseindpunten voor deze API bekijken`,
  [`View pending invitations`]: `Uitnodigingen in behandeling bekijken`,
  [`Permission Denied`]: `Toestemming geweigerd`,
  [`Permissions`]: `Machtigingen`,
  [`Persistent Connection`]: `Persistente verbinding`,
  [`Pin menu`]: `Menu vastzetten`,
  [`PlainText`]: `PlainText`,
  [`Plan APIs`]: `Plan-APIs`,
  [`Plan burst limits`]: `Burstlimieten van plan`,
  [`Plan by name`]: `Plan op naam`,
  [`Plan Default`]: `Standaardplan`,
  [`Plan Details`]: `Plangegevens`,
  [`Plan migration targets`]: `Migratiedoelen plannen`,
  [`Plan rate limits`]: `Aanroeplimieten van plan`,
  [`Plan name`]: `Plannaam`,
  [`Plan pricing`]: `Prijsgegevens uit plan`,
  [`Plan title already exists`]: `Titel van plan bestaat al`,
  [`Plans`]: `Plannen`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Platform REST API-eindpunt voor beheer- en provider-API's`,
  [`Platform REST API endpoint for consumer APIs`]: `Platform REST API-eindpunt voor consument-API's`,
  [`Please choose a bind method`]: `Kies een bindmethode`,
  [`Please choose an authentication method`]: `Kies een verificatiemethode`,
  [`Please contact the system administrator.`]: `Neem contact op met de systeembeheerder.`,
  [`Please contact your administrator for support.`]: `Neem voor hulp contact op met de systeembeheerder.`,
  [`Please enter the reason.`]: `Geef de reden op.`,
  [`Please enter your username`]: `Voer uw gebruikersnaam in`,
  [`Please go to the space settings to manage API endpoints.`]: `Ga naar de ruimte-instellingen om API-eindpunten te beheren.`,
  [`Please make sure the server is online and try again.`]: `Controleer of de server online is en probeer het opnieuw.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Let op: Als u het activiteitenlogboek uitschakelt, verwijdert u daarmee de eigenschappen die zijn gekoppeld aan het type content dat in geval van slagen of mislukken moet worden vastgelegd. Weet u zeker dat u het wilt uitschakelen?`,
  [`Please review the license for API Connect by accessing`]: `Lees de licentie voor API Connect. Dat kan in`,
  [`Please select a TLS`]: `Selecteer een TLS`,
  [`Please select a TLS client profile`]: `Selecteer een TLS-clientprofiel`,
  [`Please select a group type`]: `Selecteer een groeperen`,
  [`Please select an LDAP protocol version`]: `Selecteer een versie van het LDAP-protocol`,
  [`Please select authentication method`]: `Selecteer een verificatiemethode`,
  [`Please select ciphers from the list below:`]: `Selecteer ciphers in de onderstaande lijst:`,
  [`Please select consumer organization`]: `Selecteer een consumentorganisatie`,
  [`Please select one Keystore from the list below:`]: `Selecteer één keystore in de onderstaande lijst:`,
  [`Please select one keystore from the list below:`]: `Selecteer één keystore in de onderstaande lijst:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Noteer het client-ID en -geheim voor deze nieuwe toepassing. Berg het geheim ergens veilig op; het kan namelijk niet opnieuw worden opgehaald, alhoewel het wel opnieuw kan worden ingesteld indien nodig:`,
  [`Please try again in a few minutes.`]: `Probeer het over enkele minuten opnieuw.`,
  [`Please refresh catalog/space product list page and try again.`]: `Vernieuw de pagina met de lijst van catalogus/ruimteproducten en probeer het opnieuw.`,
  [`Policies`]: `Beleidsdefinities`,
  [`Policy Assemblies`]: `Beleidsassemblages`,
  [`Policy assembly`]: `Beleidsassemblage`,
  [`Policy description.`]: `Beschrijving van beleid.`,
  [`Policy title`]: `Titel beleid`,
  [`Policy has been uploaded.`]: `Beleid is geüpload.`,
  [`Polish`]: `Pools`,
  [`Port`]: `Poort`,
  [`Portal`]: `Portal`,
  [`Portal details`]: `Portalgegevens`,
  [`Portal Director TLS client profile`]: `TLS-clientprofiel van Portal Director`,
  [`Portal Director keystore`]: `Keystore van Portal Director`,
  [`Portal Director truststore`]: `Truststore van Portal Director`,
  [`Portal service`]: `Portalservice`,
  [`Portal Service`]: `Portalservice`,
  [`Portal service name`]: `Naam portalservice`,
  [`Portal service details`]: `Details van de portalservice`,
  [`Portal Service and URL`]: `Portalservice en URL`,
  [`Portal service {name} was successfully created.`]: `Portalservice {name} is gemaakt.`,
  [`Portal service {name} was successfully updated.`]: `Portalservice {name} is bijgewerkt.`,
  [`Portal Service {arg} has been removed.`]: `Portalservice {arg} is verwijderd.`,
  [`Portal site`]: `Portalsite`,
  [`Portal URL`]: `Portal-URL`,
  [`Portal site URLs cannot be greater than 200 characters`]: `URL's van portalsites mogen niet langer zijn dan 200 tekens`,
  [`Portal website URL`]: `URL van portalwebsite`,
  [`Portal and Gateway Settings`]: `Portal- en gateway-instellingen`,
  [`Portal has been created`]: `Portal is gemaakt`,
  [`Portal has been created.`]: `Portal is gemaakt.`,
  [`Portal has been deleted`]: `Portal is gewist`,
  [`Portals`]: `Portals`,
  [`Portuguese`]: `Portugees`,
  [`Post Response`]: `Postrespons`,
  [`Post-Invoke Hook`]: `Hook achteraf oproepen`,
  [`Preserve subscriptions on re-publish`]: `Abonnement behouden bij opnieuw publiceren`,
  [`Preserve subscriptions on re-publish product`]: `Abonnement behouden bij opnieuw publiceren van product`,
  [`Pre-Invoke Hook`]: `Hook vooraf oproepen`,
  [`Pre-Request`]: `Vooraanvraag`,
  [`Preference`]: `Voorkeur`,
  [`Prefix`]: `Prefix`,
  [`Preparing API`]: `API wordt voorbereid`,
  [`Preserved request header`]: `Behouden verzoekheader`,
  [`preserved request header`]: `behouden verzoekheader`,
  [`Preserved response header`]: `Behouden antwoordheader`,
  [`preserved response header`]: `behouden antwoordheader`,
  [`Preview`]: `Preview bekijken`,
  [`Preview of login screen registry prompt`]: `Preview van registerprompt op aanmeldingsscherm`,
  [`Previous`]: `Vorige`,
  [`Previous page`]: `Vorige pagina`,
  [`Prettify`]: `Mooier maken`,
  [`Price per month`]: `Prijs per maand`,
  [`Private`]: `Besloten`,
  [`Private Claims`]: `Persoonlijke claims`,
  [`Private key & public key`]: `Persoonlijke sleutel &amp; openbare sleutel`,
  [`Private Key or Shared Secret for Decrypt`]: `Persoonlijke sleutel of gemeenschappelijk geheim voor decoderen`,
  [`Private Key or Shared Secret for Sign`]: `Persoonlijke sleutel of gemeenschappelijk geheim voor ondertekenen`,
  [`Private key detected`]: `Persoonlijke sleutel gevonden`,
  [`Private key password`]: `Wachtwoord persoonlijke sleutel`,
  [`Processed`]: `Verwerkt`,
  [`Problem`]: `Probleem`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Probleem bij het ophalen van traceergegevens. Probeer het opnieuw. Zie de console voor foutgegevens.`,
  [`Produced Media Types`]: `Geproduceerde mediatypen`,
  [`Produces`]: `Produceert`,
  [`Product`]: `Product`,
  [`Product:`]: `Product:`,
  [`Product / Plan`]: `Product / Plan`,
  [`Product APIs`]: `Product-API's`,
  [`Product editor`]: `Producteditor`,
  [`Product lifecycle`]: `Levenscyclus product`,
  [`Product Lifecycle`]: `Productlevenscyclus`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Productnaam kan niet eindigen met '-auto-product'. Wijzig de naam en probeer opnieuw te importeren.`,
  [`Product setup`]: `Productconfiguratie`,
  [`Product details`]: `Productgegevens`,
  [`Product documentation`]: `Productdocumentatie`,
  [`Product has been created.`]: `Product is gemaakt`,
  [`Product has been deleted`]: `Product is gewist`,
  [`Product has been updated`]: `Product is bijgewerkt`,
  [`Product has been updated.`]: `Product  is bijgewerkt.`,
  [`Product has not been created!`]: `Product is niet gemaakt!`,
  [`Product has not been published!`]: `Product is niet gepubliceerd!`,
  [`Product has not been updated!`]: `Product is niet bijgewerkt!`,
  [`Product has not been staged!`]: `Product is niet klaargezet!`,
  [`Product information`]: `Productinformatie`,
  [`Product summary details`]: `Samenvatting product`,
  [`Product version`]: `Productversie`,
  [`Product visibility`]: `Zichtbaarheid van product`,
  [`Product-Approval`]: `Product-goedkeuring`,
  [`Product-Drafts`]: `Product-Concepten`,
  [`Product/plan`]: `Product/plan`,
  [`Production`]: `Productie`,
  [`Production mode`]: `Productiemodus`,
  [`Production endpoints`]: `Productie-eindpunten`,
  [`Products`]: `Producten`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Producten moeten worden klaargezet in een catalogus en moeten vervolgens worden gepubliceerd. Pas dan zijn ze beschikbaar voor toepassingsontwikkelaars. Een catalogus gedraagt zich als een logische partitie van de gateway en de ontwikkelaarsportal. De URL voor API-aanroepen en de ontwikkelaarsportal zijn specifiek voor een bepaalde catalogus. In een gebruikelijke configuratie maken leveranciers van API's gebruik van een ontwikkelcatalogus, ten behoeve van het testen van API's die in ontwikkeling zijn, en een productiecatalogus, voor het hosten van API's die klaar zijn voor volledig gebruik.`,
  [`Profile`]: `Profiel`,
  [`Profile rule`]: `Profielregel`,
  [`Promote to production`]: `Promoveren naar productie`,
  [`Proof Key for Code Exchange`]: `Bewijssleutel voor code-uitwisseling`,
  [`Proof Key for Code Exchange (PKCE)`]: `Proof Key for Code Exchange (PKCE)`,
  [`Properties`]: `Eigenschappen`,
  [`Property`]: `Eigenschap`,
  [`Property Description`]: `Beschrijving eigenschap`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `Aan eigenschap {{target}} kan een statische waarde worden toegewezen door deze hieronder in te voeren.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `Eigenschap {{target}} is momenteel toegewezen aan {{from}} - de toewijzing kan hieronder verwijderd worden.`,
  [`Property Value is Base64 Encoded`]: `Eigenschapswaarde is Base64-gecodeerd`,
  [`Protocol`]: `Protocol`,
  [`Protocols`]: `Protocollen`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protocollen die gebruikt kunnen worden voor het aanroepen van de API (voor API's die via een gateway worden afgedwongen, wordt alleen https ondersteund)`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Geef OAuth {oauthVer}-legitimatiegegevens op om gebruikers te verifiëren en toegang te krijgen tot de API's van uw OpenID-provider`,
  [`Provide a schema`]: `Geef een schema op`,
  [`Provide GraphQL schema`]: `GraphQL-schema opgeven`,
  [`Provide the URL to the backend GraphQL server`]: `Geef de URL op naar de backend GraphQL-server`,
  [`Provide your email address to receive password reset instructions`]: `Geef uw e-mailadres op om de instructies voor het resetten van het wachtwoord te ontvangen`,
  [`Provide your new password below`]: `Geef hieronder uw nieuwe wachtwoord op`,
  [`Provider endpoint`]: `Eindpunt provider`,
  [`Provider Information`]: `Providerinormatie`,
  [`Provider Organization`]: `Providerorganisatie`,
  [`Provider Organizations`]: `Providerorganisaties`,
  [`Provider Type`]: `Type provider`,
  [`Provider organizations`]: `Providerorganisaties`,
  [`Provider type`]: `Providertype`,
  [`Provider org invite failed`]: `Uitnodiging van providerorg. is mislukt`,
  [`Provider organization {name} created`]: `Providerorganisatie {name} is gemaakt`,
  [`Provider organizations will be listed here`]: `De providerorganisaties worden hier opgesomd`,
  [`Provider-Org`]: `Provider-org`,
  [`Provides contact information for the exposed API.`]: `Levert contactgegevens voor de zichtbaar gemaakte API.`,
  [`Provides license information for the exposed API.`]: `Levert licentiegegevens voor de zichtbaar gemaakte API.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Levert metagegevens over de API. Indien nodig kunnen de metagegevens worden gebruikt door de clients.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `Provisioning van de ontwikkelaarsportal is gestart. Dit proces kan enkele minuten duren. Als het gereed is, ontvangt u een e-mail met een link waarmee u een wachtwoord voor het account van de portalbeheerder kunt instellen.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `De update van de URL ontwikkelaarsportal is gestart. Dit proces kan enkele minuten duren.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `Proxy-URL`,
  [`Public`]: `Openbaar`,
  [`Public Key or Shared Secret for Encrypt`]: `Openbare sleutel of gemeenschappelijk geheim voor versleutelen`,
  [`Publish`]: `Publiceren`,
  [`Publish API`]: `API publiceren`,
  [`Publish a product from the Develop section.`]: `Publiceer een product vanuit de sectie Ontwikkeling.`,
  [`Publish product`]: `Product publiceren`,
  [`Publish to`]: `Publiceren naar`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Publiceer enkele producten naar deze catalogus om het maken van abonnementen mogelijk te maken.`,
  [`Publish target`]: `Publicatiedoel`,
  [`Publish this API to the Sandbox catalog.`]: `Deze API publiceren naar de sandboxcatalogus.`,
  [`Publish to specific gateway services`]: `Publiceren naar specifieke gatewayservices`,
  [`Publish using a new product`]: `Publiceren met behulp van nieuw product`,
  [`Publish using an existing product`]: `Publiceren met behulp van bestaand product`,
  [`Publishable Key`]: `Publiceerbare sleutel`,
  [`Publishable key`]: `Publiceerbare sleutel`,
  [`Publishable key tip`]: `Publiceerbare sleuteltip`,
  [`Published`]: `Gepubliceerd`,
  [`Published product`]: `Product gepubliceerd`,
  [`Publishing`]: `Publiceren`,
  [`Publishing New API`]: `Nieuwe API publiceren`,
  [`Publish (preserve subscriptions)`]: `Publiceren (abonnementen behouden)`,
  [`Publish|permission`]: `Publiceren`,
  [`Put online`]: `Online zetten`,
  [`Query`]: `Query`,
  [`Queryparam Encode`]: `Queryparam coderen`,
  [`Queued`]: `In wachtrij`,
  [`Queued events: **{queued}**`]: `Events in wachtrij: **{queued}**`,
  [`Retry blocked jobs`]: `Geblokkeerde taken opnieuw proberen`,
  [`REQUIRED`]: `VERPLICHT`,
  [`ROLES`]: `ROLLEN`,
  [`Rate limit`]: `Aanroeplimiet`,
  [`Rate Limit`]: `Aanvraaglimiet`,
  [`Rate Limit Name`]: `Naam aanroeplimiet`,
  [`Rate limits`]: `Gegevenssnelheidslimieten`,
  [`Rate limit name can't be a duplicate`]: `Naam van aanroeplimiet kan geen duplicaat zijn`,
  [`Rate limit name can't be empty`]: `Naam van aanroeplimiet kan niet leeg zijn`,
  [`Rate limit overrides`]: `Vervangende waarden aanroeplimiet`,
  [`Rate limit time interval can't be 0`]: `Tijdsinterval van aanroeplimiet mag niet 0 zijn`,
  [`Rate limit time interval can't be empty`]: `Tijdsinterval van aanroeplimiet mag niet leeg zijn`,
  [`Rate limit time interval unit must be selected`]: `Er moet een eenheid voor het tijdsinterval van de aanroeplimiet worden geselecteerd.`,
  [`Rate limit value can't be empty`]: `Waarde van aanroeplimiet kan niet leeg zijn`,
  [`Rate limit value can't be negative`]: `Waarde van aanroeplimiet kan niet negatief zijn`,
  [`Re-stage`]: `Opnieuw klaarzetten`,
  [`Re-stage product`]: `Product opnieuw klaarzetten`,
  [`Read only`]: `Alleen lezen`,
  [`Read-Only`]: `Alleen-lezen`,
  [`Realized`]: `Gerealiseerd`,
  [`Reason`]: `Reden`,
  [`Reason for declining`]: `Reden voor afwijzing`,
  [`Recommendations`]: `Aanbevelingen`,
  [`Recommended configuration`]: `Aanbevolen configuratie`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Noteer het hieronder vermelde client-ID en -geheim, zodat u deze gegevens later kunt gebruiken. Het clientgeheim kan nooit meer worden afgebeeld.`,
  [`Recommendations applied`]: `Toegepaste aanbevelingen`,
  [`Redact`]: `Afschermen`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `U kunt bepaalde velden uit de lopende tekst van de aanvraag, de lopende tekst van de respons en de activiteitenlogboeken afschermen of verwijderen. Definieer het af te schermen of te verwijderen veld door middel van een XPath-expressie.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `U kunt bepaalde velden uit de lopende tekst van de aanvraag, de lopende tekst van de respons en de activiteitenlogboeken afschermen of verwijderen. Definieer het af te schermen of te verwijderen veld door middel van een XPath- of JSON-expressie.`,
  [`Redaction`]: `Afscherming`,
  [`Redactions`]: `Afscherming`,
  [`Redirect`]: `Doorsturen`,
  [`Redirect URI`]: `Omleidings-URI`,
  [`Redirect URL`]: `URL doorsturen`,
  [`Redirect through Portal`]: `Doorsturen via Portal`,
  [`Redirect time limit`]: `Tijdslimiet voor doorsturen`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `Tijdslimiet voor doorverwijzing moet tussen de 10 en 600 seconden liggen`,
  [`References`]: `Verwijzingen`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Raadpleeg de [IBM Cloud Docs]({link}) voor aanvullende richtlijnen.`,
  [`Referral`]: `Verwijzing (referral)`,
  [`Refresh`]: `Vernieuwen`,
  [`Refresh Token`]: `Token vernieuwen`,
  [`Refresh button`]: `Knop Vernieuwen`,
  [`Refresh products`]: `Producten vernieuwen`,
  [`Refresh results from server`]: `Resultaten van server vernieuwen`,
  [`Refresh token`]: `Token vernieuwen`,
  [`Refresh token time to live`]: `Levensduur vernieuwingstoken`,
  [`Refresh token time-to-live`]: `Levensduur vernieuwingstoken`,
  [`Refresh token time-to-live (seconds)`]: `Levensduur vernieuwingstoken (seconden)`,
  [`Refresh token TTL has been changed`]: `Levensduur vernieuwingstoken is gewijzigd`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Levensduur vernieuwingstoken is **{seconds} seconden ({time})**`,
  [`Refresh token time to live is **off**`]: `Levensduur vernieuwingstoken is **off**`,
  [`Refresh tokens`]: `Vernieuwingstokens`,
  [`Refreshing`]: `Bezig met vernieuwen`,
  [`Refreshing...`]: `Vernieuwen...`,
  [`Region`]: `Regio`,
  [`Register`]: `Registreren`,
  [`Register Gateways`]: `Gateways registreren`,
  [`Register Service`]: `Service registreren`,
  [`Register gateways`]: `Gateways registreren`,
  [`Register service`]: `Service registreren`,
  [`Register a service`]: `Service registreren`,
  [`Register new services and manage existing services`]: `Registreer nieuwe services en beheer bestaande services`,
  [`Register remote gateway`]: `Gateway op afstand registreren`,
  [`Registered Admin Member`]: `Geregistreerde beheerder (lid)`,
  [`Registered Provider Org Owner or Member`]: `Geregistreerde eigenaar of lid van providerorg.`,
  [`Registered client information on the OpenID provider`]: `Informatie over geregistreerde clients op de OpenID-provider`,
  [`Registration completed successfully`]: `Registratie is voltooid`,
  [`Registration failed.`]: `Registratie is mislukt.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Relatieve paden naar de afzonderlijke eindpunten. Ze moeten relatief zijn ten opzichte van het 'basispad'.`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `Analyseconfiguratie verwijderen`,
  [`Remote API Connect`]: `Remote API Connect`,
  [`Remote login`]: `Aanmelden op afstand`,
  [`Remote publish`]: `Publiceren op afstand`,
  [`Remove`]: `Verwijderen`,
  [`Remove Burst Limit`]: `Burstlimiet verwijderen`,
  [`Remove constraints`]: `Voorwaarden verwijderen`,
  [`Remove Count Limit`]: `Aantallimiet verwijderen`,
  [`Remove gateway extension`]: `Gatewayextensie verwijderen`,
  [`Remove product`]: `Product verwijderen`,
  [`Remove Rate limit`]: `Aanroeplimiet verwijderen`,
  [`Remove the invalid constraints`]: `De ongeldige voorwaarden verwijderen`,
  [`Remove the invalid constraints from`]: `Verwijder de ongeldige voorwaarden van`,
  [`Remove action`]: `Actie verwijderen`,
  [`Remove case`]: `Voorval verwijderen`,
  [`Remove catch`]: `Vangst verwijderen`,
  [`Remove filter`]: `Filter verwijderen`,
  [`Remove input`]: `Invoer verwijderen`,
  [`Remove mapping`]: `Toewijzing verwijderen`,
  [`Remove output`]: `Uitvoer verwijderen`,
  [`Remove static value`]: `Statische waarde verwijderen`,
  [`Removed`]: `Verwijderd`,
  [`Removed by user`]: `Verwijderd door gebruiker`,
  [`Reorder`]: `Opnieuw rangschikken`,
  [`Repeat`]: `Herhalen`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `De API-oproep een bepaald aantal keren herhalen, of totdat er op de stopknop wordt geklikt`,
  [`Replace`]: `Vervangen`,
  [`Replace Free Trial with`]: `Gratis proefversie vervangen door`,
  [`Replace Gold with`]: `Gold vervangen door`,
  [`Replace GraphQL schema`]: `GraphQL-schema vervangen`,
  [`Replace plans`]: `Plannen vervangen`,
  [`Replace private key & public key`]: `Persoonlijke sleutel &amp; openbare sleutel vervangen`,
  [`Replace Product`]: `Product vervangen`,
  [`Replace product`]: `Product vervangen`,
  [`Replace schema`]: `Schema vervangen`,
  [`Replace Silver with`]: `Silver vervangen door`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Vervang de huidige eigenaar door de nieuwe eigenaar te selecteren in de lijst met suggesties. Wijs nieuwe machtigingen toe aan de oude gebruiker, zodat deze kan blijven doorwerken.`,
  [`Replace|permission`]: `Vervangen`,
  [`Republish`]: `Opnieuw publiceren`,
  [`Republish product`]: `Product opnieuw publiceren`,
  [`Request`]: `Aanvragen`,
  [`Request catch`]: `Catch van aanvraag`,
  [`Request endpoint`]: `Eindpunt van aanvraag`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Aanvraag voor het {action} van API-product in catalogus {catalog} is goedgekeurd`,
  [`Request approved to {{action}} app {{appName}}`]: `Aanvraag voor het {action} van app {appName} is goedgekeurd`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Aanvraag voor het {action} van API-product in catalogus {catalog} is afgewezen`,
  [`Request denied to {{action}} app {{appName}}`]: `Aanvraag voor het {action} van app {appName} is afgewezen`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Verzoek om goedkeuring van abonnement op een API-product in de catalogus {catalog}`,
  [`Request for approval to {{action}} app {{appName}}`]: `Verzoek om het {action} van app {appName} goed te keuren`,
  [`Request headers`]: `Aanvraagheaders`,
  [`Request pending to promote application to production`]: `Aanvraag voor het promoveren van de toepassing naar productie is in behandeling`,
  [`Request query`]: `Aanvraagquery`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Aanvraag voor het {action} van API-product in catalogus {catalog} is ontvangen`,
  [`Request received to {{action}} app {{appName}}`]: `Aanvraag voor het {action} van app {appName} is ontvangen`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Aanvraag van abonnement op een API-product in de catalogus {catalog} is ingetrokken`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Aanvraag voor het {action} van een API-product in de catalogus {catalog} is ingetrokken`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Aanvraag voor het {action} van app {appName} is ingetrokken`,
  [`Requested approvals`]: `Aangevraagde goedkeuringen`,
  [`Requested By`]: `Aangevraagd door`,
  [`Requested To`]: `Aangevraagd voor`,
  [`Require`]: `Verplicht stellen`,
  [`Require applications to provide a client ID`]: `Toepassingen moeten een client-ID aanleveren`,
  [`Require approval for all new self service development accounts`]: `Goedkeuring vereist voor alle nieuwe zelfserviceontwikkelingaccounts`,
  [`Required`]: `Verplicht`,
  [`Required for rate limiting`]: `Vereist voor limitering snelheid`,
  [`Required must be checked off when location is in path.`]: `Verplicht mag niet geselecteerd zijn als de locatie zich in het pad bevindt.`,
  [`Required path`]: `Vereist pad`,
  [`Resend Invite`]: `Uitnodiging opnieuw verzenden`,
  [`Resend invitation`]: `Uitnodiging opnieuw verzenden`,
  [`Resend invite`]: `Uitnodiging opnieuw verzenden`,
  [`Reset`]: `Resetten`,
  [`Reset defaults`]: `Standaardwaarden opnieuw instellen`,
  [`Reset Notification template`]: `Meldingssjabloon resetten`,
  [`Reset password`]: `Wachtwoord resetten`,
  [`Reset password TTL has been changed`]: `Levensduur reset wachtwoord is gewijzigd`,
  [`Reset password time to live`]: `Levensduur wachtwoord resetten`,
  [`Reset password time-to-live`]: `Levensduur wachtwoord resetten`,
  [`Reset password email has been sent`]: `E-mail voor resetten van wachtwoord is verzonden`,
  [`Reset password email has not been sent`]: `E-mail voor resetten van wachtwoord is niet verzonden`,
  [`Reset password invitation timeout`]: `Timeout voor uitnodiging voor reset van wachtwoord`,
  [`Reset to default preferences`]: `Resetten naar standaardvoorkeuren`,
  [`Resolve API Connect variable references`]: `Verwijzingen naar API Connect-variabelen omzetten`,
  [`Resolve XML input data type`]: `XML-invoergegevenstype omzetten`,
  [`resolver`]: `resolver`,
  [`Resource group`]: `Resourcegroep`,
  [`Resource Owner`]: `Resource-eigenaar`,
  [`Resource owner password grant`]: `Verlening wachtwoord resource-eigenaar`,
  [`Resource owner revocation`]: `Intrekking van resource-eigenaar`,
  [`Resource owner revocation path`]: `Intrekkingspad resource-eigenaar`,
  [`Resource Owner Security`]: `Beveiliging resource-eigenaar`,
  [`Resource owner - JWT`]: `Resource-eigenaar - JWT`,
  [`Resource owner - Password`]: `Resource-eigenaar - Wachtwoord`,
  [`Resource owner password`]: `Wachtwoord resource-eigenaar`,
  [`Resources`]: `Resources`,
  [`Response`]: `Respons`,
  [`Response catch`]: `Responscatch`,
  [`Response Definition`]: `Antwoorddefinitie`,
  [`Response Time`]: `Responstijd`,
  [`Response type`]: `Type respons`,
  [`Response context variables`]: `Contextvariabelen voor respons`,
  [`Response headers`]: `Responsheaders`,
  [`Response must have a description`]: `Respons moet een beschrijving hebben`,
  [`Response must have a schema`]: `Respons moet een schema hebben`,
  [`Response must have a status code`]: `Respons moet een statuscode hebben`,
  [`Response object variable`]: `Responsobjectvariabele`,
  [`Response time`]: `Responstijd`,
  [`Response time: {{value}}ms`]: `Responstijd: {{value}}ms`,
  [`Result of removing`]: `Resultaat van verwijdering`,
  [`Restrict HTTP transactions to version 1.0.`]: `HTTP-transacties beperken tot versie 1.0.`,
  [`Restrict to HTTP 1.0`]: `Beperken tot HTTP 1.0`,
  [`Retire`]: `Uit gebruik nemen`,
  [`Retire product`]: `Product intrekken`,
  [`Retired`]: `Uit gebruik genomen`,
  [`Retire|permission`]: `Uit gebruik nemen`,
  [`Return V5 Responses`]: `V5-antwoorden retourneren`,
  [`Retry selected requests`]: `Geselecteerde aanvragen opnieuw proberen`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Resultaten voor standaard GraphQL-introspectiequery's uit GraphQL-proxy retourneren`,
  [`Return third-party access_token`]: `Access_token van derden retourneren`,
  [`Return third-party access_token with the token response`]: `Access_token van derden bij de tokenrespons retourneren`,
  [`Return third-party id_token`]: `id_token van derden retourneren`,
  [`Return third-party id_token with the token response`]: `id_token van derden bij de tokenrespons retourneren`,
  [`Return to Login`]: `Terug naar aanmelding`,
  [`Reveal in finder`]: `Zichtbaar maken in zoeker`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Preview en bepalen wat er moet worden afgebeeld of verborgen in het schema. Aanvullende wijzigingen kunnen nodig zijn om de shema geldig te houden.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Controleer eventuele waarschuwingen. We helpen u later uw schema te verbeteren met slimme aanbevelingen in de editor.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Controleer op waarschuwingen en pas de slimme aanbevelingen toe om uw schema te verbeteren en uw backend te beschermen.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Controleer de basis-URL van het API-aanroepeindpunt en geef de domeinen op die door de gateway worden afgehandeld.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Controleer het type gateway, titel, naam en samenvatting. Bij het aanroepen van API Connect-opdrachten en API's gebruikt u de naam van de gateway.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Controleer de samenvatting van de wijzigingen, die aanvullende wijzigingen kan bevatten om het schema geldig te houden.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Controleer de waarschuwingen die in uw schema zijn gevonden. We helpen u later uw schema te verbeteren en uw backend te beschermen met slimme aanbevelingen in de editor.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Controleer de waarschuwingen die in uw schema zijn gevonden. We helpen u uw schema te verbeteren en uw backend te beschermen met slimme aanbevelingen in de editor.`,
  [`Reviewed schema, paths and operations`]: `Schema, paden en bewerkingen zijn gecontroleerd`,
  [`Revocation protocol`]: `Intrekkingsprotocol`,
  [`Revoke token`]: `Token intrekken`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `RHEL-docs`,
  [`Role`]: `Rol`,
  [`Role default`]: `Standaardwaarde rol`,
  [`Role default has been deleted`]: `Standaardwaarde rol is gewist`,
  [`Role defaults`]: `Standaardwaarden voor rollen`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Standaardwaarden voor rollen zijn vooraf geconfigureerde rolsjablonen die worden gebruikt om te bepalen welke standaardrollen er beschikbaar zijn wanneer er een nieuwe organisatie wordt gemaakt.`,
  [`Role has been created`]: `Rol is gemaakt`,
  [`Role has been deleted`]: `Rol is gewist`,
  [`Role has been updated`]: `Rol is bijgewerkt`,
  [`Roles`]: `Rollen`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Rollen (en de bijbehorende toegewezen machtigingen) besturen de toegang tot de functies in API Connect. De rollen in de beheerorganisatie gelden alleen voor gebruikers die met Cloud Manager werken.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Rollen en machtigingen besturen de toegang tot de functies van API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Rollen en de bijbehorende toegewezen machtigingen bepalen welke functies er toegankelijk zijn en welke taken er kunnen worden ingevoerd in API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Rollen en de bijbehorende machtigingen bepalen welke functies er toegankelijk zijn en welke taken er kunnen worden uitgevoerd in de gebruikersinterface van API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Rollen en de bijbehorende toegewezen machtigingen bepalen welke functies er toegankelijk zijn en welke taken er kunnen worden ingevoerd.`,
  [`Root`]: `Root`,
  [`Root XML Element Name`]: `Naam XML-hoofdelement`,
  [`Run the following command in the CLI to register the credentials:`]: `Voer de volgende opdracht uit in de CLI om de legitimatiegegevens te registreren:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Voer de volgende opdracht uit in de CLI om de legitimatiegegevens voor de toolkit te registreren:`,
  [`Running`]: `Actief`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Runtime variabele die de JWK voor decodering van de JWT bevat.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Runtime variabele die de JWK voor versleuteling van de JWT bevat.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Runtime variabele die de JWK voor ondertekening van de JWT bevat.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Runtime variabele die de JWK voor controle van de handtekening bevat.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Runtime variabele waarvandaan een geldige set JSON-claims kan worden opgehaald.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Runtime variabele waarvandaan de aud-claim (Audience) kan worden opgehaald. Er kunnen meerdere variabelen worden ingesteld via een door komma's gescheiden reeks.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Runtime variabele waarvandaan de iss-claim (Issuer) kan worden opgehaald. Deze claim staat voor de principal die de JWT heeft uitgegeven.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Runtime variabele waarvandaan de sub-claim (Subject) kan worden opgehaald.`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Runtimevariabele waar het gegenereerde JWT in moet worden geplaatst. Als deze waarde niet is ingesteld, wordt de JWT als bearertoken in de autorisatieheader geplaatst.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Runtimevariabele die de geverifieerde gebruikersnaam bevat. Het LTPA-token wordt gegenereerd met deze eigenschap als de gebruiker. Bijvoorbeeld: $(client.app.id), of $(oauth.resource-owner), of een runtimevariabele die is geconfigureerd in een beleid voor benoemde variabelen (set-variable policy).`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `Runtime variabele waaraan de volledige set claims in de JWT wordt toegewezen.`,
  [`Russian`]: `Russisch`,
  [`Scalar`]: `Scalair`,
  [`Schema`]: `Schema`,
  [`SCHEMA`]: `SCHEMA`,
  [`SCOPE`]: `BEREIK`,
  [`SCOPES`]: `BEREIKEN`,
  [`SECURITY DEFINITIONS`]: `BEVEILIGINGSDEFINITIES`,
  [`Service`]: `Service`,
  [`Service state:`]: `Servicestatus:`,
  [`SERVICE TYPE`]: `TYPE SERVICE`,
  [`SIL-OFL 1.1 license`]: `SIL-OFL 1.1 licentie`,
  [`SOURCE`]: `BRON`,
  [`SSL Profile`]: `SSL-profiel`,
  [`State`]: `Status`,
  [`STATUS`]: `STATUS`,
  [`Status Code`]: `Statuscode`,
  [`STATUS CODE`]: `STATUSCODE`,
  [`SUBMITTED`]: `INGEDIEND`,
  [`SUBSCRIBERS`]: `ABONNEES`,
  [`Summary`]: `Samenvatting`,
  [`Same as product`]: `Zelfde als product`,
  [`Sample ID token signing key (RS256)`]: `Voorbeeld van ondertekeningssleutel ID-token (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Voorbeeld van validatiesleutel ID-token (RS256)`,
  [`Sample OAuth symmetric key value`]: `Voorbeeldwaarde symmetrisch OAuth-sleutel`,
  [`Sample URL: https://api.catalog.org.host/`]: `Voorbeeld van een URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `Voorbeeld van een URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Beschrijving van voorbeeldbereik 1`,
  [`Sandbox - Overview`]: `Sandbox - Overzicht`,
  [`Sandbox Catalog`]: `Sandboxcatalogus`,
  [`Sandbox Catalog User Registry`]: `Gebruikersregister sandboxcatalogus`,
  [`Sandbox Default TLS client profile`]: `Standaard TLS-clientprofiel sandbox`,
  [`Sandbox Default TLS Client Profile`]: `Standaard TLS-clientprofiel Sandbox`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Er moet altijd een sandboxcatalogus aanwezig zijn in een providerorganisatie.`,
  [`Save`]: `Opslaan`,
  [`Save API as a new version`]: `API opslaan als nieuwe versie`,
  [`Save Product as a new version`]: `Product opslaan als nieuwe versie`,
  [`Save and Edit`]: `Opslaan en bewerken`,
  [`Save and republish`]: `Opslaan en opnieuw publiceren`,
  [`Save as a new version`]: `Opslaan als een nieuwe versie`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Het clientgeheim opslaan (uit veiligheidsoverwegingen kan het later niet meer worden opgehaald)`,
  [`Save this product as a new version`]: `Dit product opslaan als een nieuwe versie`,
  [`Saved`]: `Opgeslagen`,
  [`Saving assembly...`]: `Assemblage wordt opgeslagen...`,
  [`Saving...`]: `Bezig met opslaan...`,
  [`Schema Type`]: `Type schema`,
  [`Schema as JSON`]: `Schema als JSON`,
  [`Schema as XML`]: `Schema als XML`,
  [`Schema as YAML`]: `Schema als YAML`,
  [`Schema change`]: `Schemawijziging`,
  [`Schema changes`]: `Schemawijzigingen`,
  [`Schema definition circular reference limit`]: `Limiet kringverwijzingen in schemadefinitie`,
  [`Schema name`]: `Schemanaam`,
  [`Schema warnings`]: `Schemawaarschuwingen`,
  [`Schema definition`]: `Schemadefinitie`,
  [`Scheme`]: `Schema`,
  [`Schemes`]: `Schema's`,
  [`Scope`]: `Bereik`,
  [`Scopes`]: `Bereiken`,
  [`Scopes cannot be empty`]: `Bereiken mogen niet leeg zijn`,
  [`Search`]: `Zoeken`,
  [`Search DN`]: `DN zoeken`,
  [`Search in the field above above and select to add provider organizations`]: `Zoek in het bovenstaande veld en selecteer om providerorganisaties toe te voegen`,
  [`Search Limit`]: `Limiet opzoeken`,
  [`Search Organization owner from`]: `Zoeken naar eigenaar van organisatie in`,
  [`Search orgs/groups`]: `Zoeken naar organisaties/groepen`,
  [`Search Owner`]: `Eigenaar zoeken`,
  [`Search assemblies`]: `Assemblages zoeken`,
  [`Search for`]: `Zoeken naar`,
  [`Search for provider organizations`]: `Zoeken naar provideorganisaties`,
  [`Search for Owner`]: `Zoeken naar eigenaar`,
  [`Search for a new member to become the new owner.`]: `Zoek naar een nieuw lid dat de nieuwe eigenaar kan worden.`,
  [`Search for an existing member to become the new owner.`]: `Zoek naar een bestaand lid dat de nieuwe eigenaar kan worden.`,
  [`Search for new owner`]: `Zoeken naar nieuwe eigenaar`,
  [`Search for owner from:`]: `Naar eigenaar zoeken in:`,
  [`Search for the member`]: `Zoeken naar het lid`,
  [`Search for title or name or version`]: `Zoeken naar titel of naam of versie`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Zoek naar de gebruiker die u wilt toevoegen als lid, en wijs de vereiste rollen toe.`,
  [`Search overflow`]: `Zoekoverloop`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Zoek in het register naar de bestaande gebruiker die u wilt toevoegen als lid, en wijs de vereiste rollen toe`,
  [`Search types/fields`]: `Zoektypen/velden`,
  [`Search user`]: `Gebruiker zoeken`,
  [`Search schema`]: `Zoekschema`,
  [`Seconds`]: `Seconden`,
  [`Secret Key`]: `Geheime sleutel`,
  [`Secret Name`]: `Naam geheim`,
  [`Secret Required`]: `Geheim vereist`,
  [`Secret key`]: `Geheime sleutel`,
  [`Secure`]: `Beveiligen`,
  [`Secure connection`]: `Beveiligde verbinding`,
  [`Secure using Client ID`]: `Beveiligen met behulp van client-ID`,
  [`Security`]: `Beveiliging`,
  [`Security & Product Definitions`]: `Beveiliging &amp; Productdefinities`,
  [`Security definitions`]: `Beveiligingsdefinities`,
  [`Security and Publish`]: `Beveiliging en publicatie`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Beveiligingsdefinities besturen clienttoegang tot API-eindpunten, waaronder API-sleutelvalidatie, standaardverificatie via een opgegeven gebruikersregister en OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Beveiligingsdefinities besturen clienttoegang tot API-eindpunten, waaronder API-sleutelvalidatie, standaardverificatie via een opgegeven gebruikersregister en OAuth. [Meer informatie]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `De hier geselecteerde beveiligingsdefinities gelden voor de gehele API, maar kunnen voor afzonderlijke bewerkingen worden overschreven.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `De hier geselecteerde beveiligingsdefinities gelden voor de gehele API, maar kunnen voor afzonderlijke bewerkingen worden overschreven. [Meer informatie]({link})`,
  [`Security object to use to decode the claim.`]: `Het beveiligingsobject voor decodering van het bericht.`,
  [`Security object used to encrypt the JWT.`]: `Beveiligingsobject dat gebruikt wordt voor het versleutelen van de JWT.`,
  [`Security object used to sign the JWT.`]: `Beveiligingsobject dat gebruikt wordt voor het ondertekenen van de JWT.`,
  [`Security object used to verify the claim.`]: `Beveiligingsobject dat gebruikt wordt voor verificatie van de claim.`,
  [`Select APIs to add to this product`]: `Selecteer de API's die u aan dit product wilt toevoegen`,
  [`Select API Type`]: `API-type selecteren`,
  [`Select API type`]: `API-type selecteren`,
  [`Select a billing integration in the Product plans.`]: `Selecteer een factureringsintegratie in de productplannen.`,
  [`Select a flow...`]: `Selecteer een stroom...`,
  [`Select a plan from your existing product`]: `Selecteer een plan van uw bestaande product`,
  [`Select a policy file to import to this catalog`]: `Selecteer een beleidsbestand om naar deze catalogus te importeren`,
  [`Select a policy implementation file to import to this catalog`]: `Selecteer een beleidsimplementatiebestand om naar deze catalogus te importeren`,
  [`Select a product`]: `Selecteer een product`,
  [`Select Certificate Manager service`]: `Certificate Manager-service selecteren`,
  [`Select Cloud`]: `Cloud selecteren`,
  [`Select Email Server`]: `E-mailserver selecteren`,
  [`Select gateway services`]: `Gatewayservices selecteren`,
  [`Select Import Type`]: `Importtype selecteren`,
  [`Select product`]: `Product selecteren`,
  [`Select product type`]: `Producttype selecteren`,
  [`Select Service`]: `Service selecteren`,
  [`Select Service Type`]: `Servicetype selecteren`,
  [`Select target endpoints`]: `Doeleindpunten selecteren`,
  [`Select target service`]: `Doelservice selecteren`,
  [`Select user registry type`]: `Type gebruikersregister selecteren`,
  [`Select a WSDL service from the imported file`]: `Selecteer een WSDL-service uit het geïmporteerde bestand`,
  [`Select a certificate`]: `Selecteer een certificaat`,
  [`Select a Certificate Manager service`]: `Selecteer een Certificate Manager-service`,
  [`Select a cryptographic algorithm.`]: `Selecteer een cryptografisch algoritme.`,
  [`Select a default catalog and organization`]: `Selecteer een standaardcatalogus en -organisatie`,
  [`Select a different cloud`]: `Selecteer een andere cloud`,
  [`Select a key encryption algorithm.`]: `Selecteer een sleutelcoderingsalgoritme.`,
  [`Select a plan to migrate`]: `Selecteer het plan dat gemigreerd moet worden`,
  [`Select a plan to replace`]: `Selecteer het plan dat moet worden vervangen`,
  [`Select a plan to supersede`]: `Selecteer het plan voor verdringing van`,
  [`Select a product to set as the migration target`]: `Selecteer het product dat u wilt instellen als het migratiedoel`,
  [`Select a product to supersede`]: `Selecteer het product ter verdringing van`,
  [`Select a product to supersede {sourceProductName}:`]: `Selecteer een product ter verdringing van {sourceProductName}`,
  [`Select a resource group`]: `Selecteer een resourcegroep`,
  [`Select a service`]: `Selecteer een service`,
  [`Select a supported file ({type}) and try again.`]: `Selecteer een ondersteund bestand ({type}) en probeer het opnieuw.`,
  [`Select a target SOAP service`]: `Selecteer een SOAP-doelservice`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Selecteer een toolkitpakket om te downloaden. Sla deze stap over als de toolkit al geïnstalleerd is.`,
  [`Select an encryption algorithm.`]: `Selecteer een versleutelingsalgoritme.`,
  [`Select an extension file for this gateway`]: `Selecteer een extensiebestand voor deze gateway`,
  [`Select an org`]: `Selecteer een org`,
  [`Select catalog`]: `Catalogus selecteren`,
  [`Select Catalogs to Search`]: `Selecteer catalogi om te zoeken`,
  [`Select compatible gateway services`]: `Compatibele gatewayservices selecteren`,
  [`Select one Keystore from the list below:`]: `Selecteer in de onderstaande lijst één keystore:`,
  [`Select one keystore from the list below:`]: `Selecteer in de onderstaande lijst één keystore:`,
  [`Select one keystore from the list.`]: `Selecteer één keystore in de lijst.`,
  [`Select operation...`]: `Bewerking selecteren...`,
  [`Select operations you want to include`]: `Selecteer de bewerkingen die u wilt opnemen`,
  [`Select organization`]: `Organisatie selecteren`,
  [`Select the API definition or package file to import`]: `Selecteer de API-definitie of het te importeren pakketbestand`,
  [`Select the billing integration for this catalog`]: `Selecteer de factureringsintegratie voor deze catalogus`,
  [`Select the billing integration for this product`]: `Selecteer de factureringsintegratie voor dit product`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Selecteer de gateways waarmee de API's in een nieuwe catalogus worden beveiligd. Om een nieuwe gateway te registreren, gaat u naar de pagina **Gateways**.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Selecteer de [Certificate Manager]({link}) service die de certificaten voor deze gateway beheert.`,
  [`Select the APIs to add to this product`]: `Selecteer de API's die u aan dit product wilt toevoegen`,
  [`Select the OpenAPI definition file to import`]: `Selecteer het definitiebestand OpenAPI om te importeren`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Selecteer de acties voor de levenscyclus van producten waarvoor goedkeuring vereist is. Als een van de geselecteerde acties wordt ondernomen, wordt er een goedkeuringsaanvraag gegenereerd; die aanvraag moet worden goedgekeurd om de actie te kunnen voltooien.`,
  [`Select the TLS protocol versions for this profile.`]: `Selecteer de versies van het TLS-protocol voor dit profiel.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Selecteer de analyseservice die u aan de gatewayservice wilt koppelen`,
  [`Select the consumer organization in which you want to create the application`]: `Selecteer de consumentorganisatie waarbinnen u de toepassing wilt maken`,
  [`Select the consumer organization that will own the application`]: `Selecteer de consumentorganisatie die eigenaar wordt van de toepassing`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Selecteer de eindpunten om deze API te publiceren en definieer indien nodig de aanroeplimiet`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Selecteer de fouten die, als ze optreden tijdens de uitvoering van het beleid, ervoor zorgen dat de assemblagestroom wordt gestopt. Als er een afvangstroom voor de fout is geconfigureerd, wordt deze geactiveerd om de opgetreden fout af te handelen. Als er een fout optreedt en er geen fouten zijn geselecteerd voor de instelling Stoppen bij fouten, of als de opgetreden fout niet een van de geselecteerde fouten is, kan de uitvoering van het beleid worden voltooid en wordt de assemblagestroom voortgezet.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Selecteer de gatewayservices waarop de API's in het product beschikbaar moeten worden gemaakt.`,
  [`Select the gateway type for this API`]: `Selecteer het type gateway voor deze API`,
  [`Select the gateway type for this OAuth provider`]: `Selecteer het type gateway voor deze OAuth-provider`,
  [`Select the keystores and truststores for this profile.`]: `Selecteer de keystores en truststores voor dit profiel.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Selecteer de organisaties of groepen waarvoor u dit product zichtbaar wilt maken`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Selecteer de organisaties of groepen die u op dit product wilt abonneren`,
  [`Select the portal service to use for this catalog`]: `Selecteer de portalservice die voor deze catalogus moet worden gebruikt`,
  [`Select the product and plan for the application subscription`]: `Selecteer het product en het plan voor het abonnement op de toepassing`,
  [`Select the product definition file to import`]: `Selecteer het productdefinitiebestand dat u wilt importeren`,
  [`Select the product definition file to import from`]: `Selecteer het productdefinitiebestand waaruit u wilt importeren`,
  [`Select the product that will supersede the originally selected product`]: `Selecteer het product dat het oorspronkelijk geselecteerde product gaat verdringen`,
  [`Select the product that you want to add the API to`]: `Selecteer het product waaraan u de API wilt toevoegen`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Selecteer de registers die u wilt gebruiken om de toegang tot de API's in deze catalogus te besturen`,
  [`Select the required operations`]: `Selecteer de vereiste bewerkingen`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Selecteer het severityniveau voor logboekberichten die gerelateerd zijn aan invoergegevens; de mogelijke niveaus zijn fout, waarschuwing en info.`,
  [`Select the target wsdl file to create from`]: `Selecteer het beoogde WSDL-bestand op basis waarvan u het item wilt samenstellen`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Selecteer het beoogde WSDL-, XSD- of ZIP-bestand op basis waarvan de API-definitie gemaakt wordt`,
  [`Select the target WSDL file to import`]: `Selecteer het te importeren WSDL-bestand`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Selecteer de gebruikersregisters voor het verifiëren van gebruikers van API Manager, of maak een nieuw gebruikersregister.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Selecteer de gebruikersregisters voor het verifiëren van gebruikers van API Manager, of maak een nieuw gebruikersregister.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Selecteer de gebruikersregisters voor het verifiëren van gebruikers van Cloud Manager, of maak een nieuw gebruikersregister.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Selecteer de gebruikersregisters die worden gebruikt voor het verifiëren van aanmeldingen bij de Developer Portal`,
  [`Select the user registry type`]: `Selecteer het type gebruikersregister`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Selecteer de plek in de uitvoerbron waar het gegenereerde LTPA-token door het beleid moet worden neergezet.  In WSSec mag Header alleen worden geselecteerd als het contenttype in de aanvraag application/xml is.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Geef aan of u de eigenschap volledig wilt verwijderen, of dat u het veld wilt afschermen met behulp van sterretjes (*) om de weergave van de gegevens te verbergen.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Het geselecteerde product is gewijzigd. Vernieuw deze pagina en probeer het opnieuw.`,
  [`Selecting operations from WSDL service definition`]: `Bewerkingen selecteren uit WSDL-servicedefinitie`,
  [`Self Service Onboarding for Consumer organization`]: `Zelfservice onboarding voor consumentorganisatie`,
  [`Self service onboarding`]: `Zelfservice onboarding`,
  [`Self service onboarding approval`]: `Goedkeuring voor zelfservice onboarding`,
  [`Send`]: `Verzenden`,
  [`Send Message`]: `Bericht verzenden`,
  [`Send Message to Owners`]: `Bericht verzenden aan eigenaren`,
  [`Send To`]: `Verzenden naar`,
  [`Send a registration link to a new user to register as owner.`]: `Stuur een nieuwe gebruiker een registratielink waarmee hij of zij zich kan registreren als eigenaar.`,
  [`Send a test email`]: `Een testmail verzenden`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Een testmail verzenden om te bevestigen dat de e-mailserver correct is geconfigureerd.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Een testsignaal sturen om te bevestigen dat het eindpunt correct is geconfigureerd.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Een testsignaal sturen om te bevestigen dat het eindpunt correct is geconfigureerd`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Stuur een e-mailadres aan de eigenaar van de beheerorganisatie. Plaats een vinkje naast de naam van de eigenaar`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Stuur een e-mailadres aan een eigenaar. Plaats een vinkje naast de naam van de eigenaar`,
  [`Send chunked-encoded documents to the target server.`]: `Chunked-encoded documenten naar de doelserver verzenden.`,
  [`Send message`]: `Bericht verzenden`,
  [`Send message to owner`]: `Bericht verzenden aan eigenaar`,
  [`Send test email`]: `Testmail verzenden`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Verstuur de activeringslink naar een gebruiker om hem of haar uit te nodigen om zich te registreren of om zich aan te melden.`,
  [`Send-only`]: `Alleen verzenden`,
  [`Sender`]: `Afzender`,
  [`Sender & email server`]: `Afzender &amp; e-mailserver`,
  [`Sender & email server have been changed`]: `Afzender &amp; e-mailserver zijn gewijzigd`,
  [`Sender Info`]: `Gegevens afzender`,
  [`Sent`]: `Verzonden`,
  [`Sent events: **{sent}**`]: `Verzonden events: **{sent}**`,
  [`Serial`]: `Serienummer`,
  [`Serialize output`]: `Uitvoer serialiseren`,
  [`Server connection`]: `Serververbinding`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `HTML in webbrowsers zetten om GUI GraphQL-client in te schakelen`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Plaats HTML in webbrowsers om GraphQL-client voor GUI in te schakelen.`,
  [`Server Name Indication (SNI)`]: `Server Naam Indicatie (SNI)`,
  [`Server URL`]: `Server-URL`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Server heeft een fout in hash geretourneerd in plaats van token in OIDC redirect URL. Hash:`,
  [`Service name`]: `Servicenaam`,
  [`Services`]: `Services`,
  [`Set`]: `Instellen`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Stel de OS-omgevingsvariabele APIC_DESIGNER_CREDENTIALS in voor API Designer. Op MAC kan de gebruiker bijvoorbeeld de omgevingsvariabele doorgeven tijdens het starten van de API Designer.`,
  [`Set Migration Target`]: `Migratiedoel instellen`,
  [`Set migration target`]: `Doellocatie voor migratie instellen`,
  [`Set Variable`]: `Variabele instellen`,
  [`Set as default`]: `Als standaard instellen`,
  [`Set default`]: `Standaard instellen`,
  [`Set rate and burst limits in an assembly`]: `Aanroep- en burstlimieten in een assemblage instellen`,
  [`Set the timeout period for invitations`]: `Stel de timeoutperiode voor uitnodigingen in`,
  [`Set the trust level for the profile`]: `Stel het vertrouwensniveau voor het profiel in`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Stel de waarde in op het aantal herhalingen van een circulaire schemadefinitie die toegestaan zijn (standaard 1, maximaal 5)`,
  [`Set the visibility for a gateway service`]: `Stel de zichtbaarheid voor een gatewayservice in`,
  [`Set the visibility for a portal service`]: `Stel de zichtbaarheid voor een portalservice in`,
  [`Set the visibility for each service`]: `Stel de zichtbaarheid voor elke service in`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Stel de tijdsperiode in voor het ophalen van een nieuw toegangstoken dat door de verificatieserver wordt uitgegeven`,
  [`Set visibility`]: `Zichtbaarheid instellen`,
  [`Set visibility of gateway`]: `Zichtbaarheid van gateway instellen`,
  [`Set time interval`]: `Tijdsinterval instellen`,
  [`Set, Add, or Clear a runtime variable.`]: `Een runtimevariabele instellen, toevoegen of wissen.`,
  [`Set the timeout period for API key`]: `De timeoutperiode voor de API-sleutel instellen`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Geeft de geldigheidsduur voor documenten in cache aan in seconden. Geldt alleen als het cachetype is ingesteld op "Levensduur". Geef een waarde tussen 5 - 31708800 op. De standaardwaarde is 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `Het instellen van een waarde voor "assumedSize" kan niet worden gecombineerd met het definiëren van een segmenteringsargument met een standaardwaarde of kan niet worden gecombineerd met het inschakelen van "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Als u deze catalogus instelt als de standaardcatalogus, kan deze worden geopend met behulp van een kortere URL, waarbij de catalogusnaam wordt weggelaten. U kunt slechts één catalogus als standaard instellen.`,
  [`Settings`]: `Instellingen`,
  [`Setup`]: `Configuratie`,
  [`Severity level for input data log messages`]: `Severityniveau voor logboekberichten over invoergegevens`,
  [`Share the resource with a custom list of provider organizations`]: `De resource delen met een aangepaste lijst van providerorganisaties`,
  [`Share the resource with provider organizations`]: `De resource delen met providerorganisaties`,
  [`Share the API gateway with a custom list of provider organizations`]: `De API-gateway delen met een aangepaste lijst van provideorganisaties`,
  [`Share the service with a custom list of provider organizations`]: `De service delen met een aangepaste lijst van providerorganisaties`,
  [`Share the API gateway with provider organizations`]: `De API-gateway delen met provideorganisaties`,
  [`Share the service with provider organizations`]: `De service delen met providerorganisaties`,
  [`Share`]: `Delen`,
  [`Shared`]: `Gedeeld`,
  [`Shared by`]: `Gedeeld door`,
  [`Short but descriptive name for this policy.`]: `Korte beschrijvende naam voor dit beleid.`,
  [`Show`]: `Weergeven`,
  [`Show / hide policy palette`]: `Palet voor beleid afbeelden/verbergen`,
  [`Show/hide`]: `Afbeelden/verbergen`,
  [`Show/hide settings`]: `Instellingen afbeelden/verbergen`,
  [`Show and hide types and fields to include in the schema.`]: `Typen en velden die in het schema opgenomen moeten worden afbeelden en verbergen.`,
  [`Show added only`]: `Alleen toegevoegde items afbeelden`,
  [`Show all`]: `Alle afbeelden`,
  [`Show all UDP versions`]: `Alle UDP-versies afbeelden`,
  [`Show app identifier`]: `App-ID afbeelden`,
  [`Show breaking changes only`]: `Alleen belangrijke wijzigingen afbeelden`,
  [`Show catalog identifier`]: `Catalogus-ID afbeelden`,
  [`Show catches`]: `Vangsten afbeelden`,
  [`Show in schema`]: `Afbeelden in schema`,
  [`Show less`]: `Minder afbeelden`,
  [`Show modified only`]: `Alleen wijzigingen afbeelden`,
  [`Show more`]: `Meer afbeelden`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Nieuwe typen, velden, instructies of optionele argumenten afbeelden. Items die niet meer worden ondersteund door de back-end worden nog steeds verwijderd.`,
  [`Show removed only`]: `Alleen verwijderingen afbeelden`,
  [`Show schema`]: `Schema afbeelden`,
  [`Show warnings only`]: `Alleen waarschuwingen afbeelden`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Hier ziet u de historie van keystoretoewijzingen, met uitzondering van de momenteel toegewezen keystore.`,
  [`Sign Crypto Object`]: `Cryptografisch object voor ondertekening`,
  [`Sign in`]: `Aanmelden`,
  [`Sign JWK variable name`]: `Naam JWK-variabele voor ondertekening`,
  [`Sign Out`]: `Afmelden`,
  [`Sign up`]: `Registreren`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Aanmelden met het gebruikersregister van {userRegistryType}`,
  [`Sign in with`]: `Aanmelden met`,
  [`Sign out`]: `Afmelden`,
  [`Sign up with`]: `Registreren met`,
  [`Signed token`]: `Ondertekend token`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Aangezien buffering is uitgeschakeld, moet de inhoud en/of foutcontent van de payload naar headers worden verlaagd. Wilt u doorgaan met de vereiste wijziging of de recente wijziging annuleren?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Omdat content en/of foutcontent is ingesteld op payload, is het vereist dat de buffer wordt ingesteld op aan. Wilt u doorgaan met de vereiste wijziging of de recente wijziging annuleren?`,
  [`Sized fields`]: `Velden met grootte`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Slicingargumenten`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Bepaalde invoer bestaat uit arrays of is opgenomen in arrays. Om de volledige inhoud aan te spreken, selecteert u de optie Herhalen en kiest u de invoer waarop die herhaling betrekking moet hebben.`,
  [`Something went wrong.`]: `Er is iets misgegaan.`,
  [`Something went wrong...`]: `Er is iets fout gegaan...`,
  [`Source`]: `Bron`,
  [`Source schema`]: `Bronschema`,
  [`Space`]: `Ruimte`,
  [`Space is not allowed in scope name`]: `Spaties zijn niet toegestaan in bereiknaam`,
  [`Space details`]: `Ruimtegegevens`,
  [`Space Enabled`]: `Gebruik van ruimten ingeschakeld`,
  [`Space Name`]: `Naam ruimte`,
  [`Space Summary`]: `Ruimteoverzicht`,
  [`Space Title`]: `Titel ruimte`,
  [`Space owner`]: `Eigenaar van ruimte`,
  [`Space {name} created`]: `Ruimte {name} is gemaakt`,
  [`Spaces`]: `Ruimten`,
  [`Spaces are enabled`]: `Het gebruik van ruimten is ingeschakeld`,
  [`Spaces are not enabled`]: `Het gebruik van ruimten is niet ingeschakeld`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Het gebruik van ruimten kan niet worden ingeschakeld voor de sandboxcatalogus.`,
  [`Spaces cannot be enabled.`]: `Het gebruik van ruimten kan niet worden ingeschakeld.`,
  [`Spaces dropdown`]: `Menu voor ruimten`,
  [`Spaces has been enabled`]: `Het gebruik van de ruimte is ingeschakeld`,
  [`Spaces have been enabled.`]: `Het gebruik van ruimten is ingeschakeld.`,
  [`Spaces per page`]: `Spaties per pagina`,
  [`Spanish`]: `Spaans`,
  [`Special characters are not accepted`]: `Speciale tekens worden niet geaccepteerd`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Geeft een URL of tekenreeks aan die een benoemde context vertegenwoordigt waaruit de geserialiseerde XML- of JSON-eigenschappen worden opgehaald die in het dynamische object worden samengevoegd. Deze eigenschappen hebben voorrang op een bestaande literaal of standaardeigenschappen.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Geeft een literaalreeks aan als geserialiseerde XML- of JSON-eigenschappen die in het dynamische object worden samengevoegd. Deze eigenschappen hebben voorrang op bestaande standaardeigenschappen.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Geeft een bestaand geldig object aan waaruit de standaard eigenschapswaarden voor het dynamische object moeten worden opgehaald. Waarschuwing: Als u de standaardreeks wijzigt, moet het opgegeven instellingsobject voor ontleden beschikbaar zijn in de DataPower-appliance.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Geeft de gegevensbron aan die de content bevat die moet worden geredigeerd of verwijderd. Als de root niet wordt opgegeven, wordt de actie toegepast op de gehele API-context. U kunt elke ondersteunde JSONata-padexpressie gebruiken.\n \nRedactie van aanvraag- en responsgegevens kan worden uitgevoerd met de root "message.body". Als de redigeeractie voor de assemblage wordt gebruikt in een assemblage na een logactie van de assemblage waarbij de modus gather-only is opgegeven, kan de root "log.request_body" opgeven voor de payload van de gelogde opdracht of "log.response_body" voor de payload van de gelogde respons.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Geeft de naam aan van een variabele in de API-context. De inhoud van het veld "body " van de variabele is de invoer voor de actie. De naam van de standaardvariabele is "message".`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Geeft de naam aan van een variabele in de API-context. De inhoud van het veld "body" van de variabele is de uitvoer van de ontleding. De ontledingsgegevens van het geanalyseerde document kunnen in een ander deel van het bericht worden opgeslagen. De naam van de standaardvariabele is wat de invoernaam is, dus standaard wordt het invoerbericht overschreven door het uitvoerbericht.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Bepaalt of er aangepaste metagegevens voor het toegangstoken moeten worden opgehaald, en zo ja: hoe.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Geeft aan of er moet worden geprobeerd om te ontleden met het opgegeven contenttype. Dit geldt uitsluitend als het verwachte contenttype JSON of XML is. Als deze optie ingeschakeld is, mislukt het ontleden als het contenttype niet overeenkomt met de ontleedinstellingen (d.w.z. Content-type:application/json en ontleedinstellingen geconfigureerd voor XML). Als de ontleedinstelling is geconfigureerd voor de detectie van invoercontent, wordt het opgegeven contenttype afgedwongen.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Geeft aan of de boomstructuur met XSLT-uitvoer na afloop van de transformatie moet worden geserialiseerd naar binaire gegevens.`,
  [`Specify default gateways`]: `Standaardgateways opgeven`,
  [`Specify email`]: `E-mailadres opgeven`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Geef een OpenAPI (Swagger) schemadefinitie op waarmee de payload (de standaardkeuze) ("request", "response" of "#/definitions/XXXX") kan worden gevalideerd.`,
  [`Specify owner of the consumer organization`]: `Geef de eigenaar van de consumentorganisatie op`,
  [`Specify owner of the provider organization`]: `Geef de eigenaar van de providerorganisatie op`,
  [`Specify settings for the third party OAuth provider.`]: `Geef instellingen voor de externe OAuth-provider op.`,
  [`Specify the MIME types that your API consumes and produces`]: `Geef op welke MIME-typen er door uw API worden geconsumeerd en geproduceerd`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Geef de eigenaar van de catalogus op. De eigenaar heeft alle machtigingen voor de catalogus`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Geef aan voor welke consumentorganisatie of ontwikkelaarsorganisatie het product zichtbaar moet zijn in de ontwikkelaarsportal`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Geef aan welke consumentorganisatie of ontwikkelaarsorganisatie zich moeten kunnen abonneren op het gebruik van de API's in het product`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Geef de basis-URL op van het API-oproepeindpunt en de domeinen die door de gateway worden afgehandeld.`,
  [`Specify the details of the catalog that you want to publish to`]: `Geef de gegevens op van de catalogus waarheen u wilt publiceren`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Selecteer de standaardset van gateways waarmee de API's in een nieuwe catalogus worden beveiligd. [Meer informatie]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Geef op naar welke eindpunten deze API moet worden gepubliceerd en geef aan of u het aantal API-aanroepen wilt beperken`,
  [`Specify the new owner`]: `Geef de nieuwe eigenaar op`,
  [`Specify the owner`]: `Geef de eigenaar op`,
  [`Specify the owner and title`]: `Geef de eigenaar en de titel op`,
  [`Specify the properties to redact or remove.`]: `Opgeven welke eigenschappen er moeten worden afgeschermd of verwijderd.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Geef de vereisten voor het publiceren en het beperken van de aanroepen voor deze API op`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Geef het bereikcontrole-eindpunt op waarop aanvullende bereikverificatie wordt uitgevoerd (in aanvulling op de gedefinieerde bereiken).`,
  [`Specify the security settings for the imported API definition`]: `Geef de beveiligingsinstellingen voor de geïmporteerde API-definitie op`,
  [`Specify the security settings for your API definition`]: `Beveiligingsinstelling voor uw API-definitie opgeven`,
  [`Specify the space owner; the owner has all space permissions`]: `Geef de eigenaar van de ruimte op. De eigenaar heeft alle machtigingen voor de ruimte`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Geef een SOAP-doelservice en een samenvatting van de API op. Desgewenst kunt u de API-definitie nog verder configureren nadat deze is gemaakt`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Geef de timeoutperiode op waarna uitnodigingsmails vervallen als de gebruiker zijn of haar account niet activeert.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Geef de timeoutperiode op waarna uitnodigingsmails vervallen als de gebruiker zijn of haar account niet activeert.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Geef het type gateway, een titel en een samenvatting op waarmee de gateway wordt beschreven.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Geef de zichtbaarheid van de API-gateway op naar de API Connect-provideorganisatie.`,
  [`Specify the way you want to validate the message.`]: `Geef aan op welke manier u het bericht wilt valideren.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Geef de URL op van het gatewaybeheereindpunt en het certificaat voor het domein van het eindpunt.`,
  [`Specify what content to log in the event of an error.`]: `Geef op welke content er vastgelegd moet worden in geval van een fout.`,
  [`Specify what content to log.`]: `Geef op welke content er vastgelegd moet worden.`,
  [`Specifying the publish target details`]: `Geef de details van het publicatiedoel op`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Sta in het bijzonder de regel xsi:type=SOAP-ENC:Array toe`,
  [`Stage`]: `Klaarzetten`,
  [`Stage API`]: `API klaarzetten`,
  [`Stage product`]: `Product klaarzetten`,
  [`Stage to`]: `Klaarzetten naar`,
  [`Staged`]: `Klaargezet`,
  [`Stage|permission`]: `Klaarzetten`,
  [`Standard OIDC`]: `Standaard OIDC`,
  [`Static DNS`]: `Statische DNS`,
  [`Static group`]: `Statische groep`,
  [`Status`]: `Status`,
  [`Status code`]: `Statuscode`,
  [`Step 1`]: `Stap 1`,
  [`Step 1: Download`]: `Stap 1: Downloaden`,
  [`Step 1: Download toolkit`]: `Stap 1: Toolkit downloaden`,
  [`Step 1: Upload private key`]: `Stap 1: Persoonlijke sleutel uploaden`,
  [`Step 2`]: `Stap 2`,
  [`Step 2: Download credentials`]: `Stap 2: Legitimatiegegevens downloaden`,
  [`Step 2: Download toolkit credentials`]: `Stap 2: Legitimatiegegevens voor toolkit downloaden`,
  [`Step 2: Install`]: `Stap 2: Installeren`,
  [`Step 2: Upload public key`]: `Stap 2: Openbare sleutel uploaden`,
  [`Step 3`]: `Stap 3`,
  [`Step 3: Configure`]: `Stap 3: Configureren`,
  [`Step 3: Run command`]: `Stap 3: Opdracht uitvoeren`,
  [`Step 3: Run commands`]: `Stap 3: Opdrachten uitvoeren`,
  [`Step 4: Register`]: `Stap 4: Registreren`,
  [`Stop`]: `Stoppen`,
  [`Stop after:`]: `Stoppen na:`,
  [`Stop all the retries`]: `Alle herhaalde pogingen stoppen`,
  [`Stop Connection`]: `Verbinding stoppen`,
  [`Stop on error`]: `Stoppen bij fouten`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Stoppen met het uitvoeren van deze API en deze uit de sandboxcatalogus verwijderen.`,
  [`Stopped`]: `Gestopt`,
  [`Stopping Published API`]: `Gepubliceerde API stoppen`,
  [`Strict`]: `Strikt`,
  [`Strict SOAP envelope version`]: `Strikte versie van SOAP-envelop`,
  [`String Badgerfish`]: `Tekenreeks Badgerfish`,
  [`String`]: `Tekenreeks`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `Reeks opgenomen in het organisatiesegment van de URL voor API-aanroepen. Gebruik kleine letters, cijfers en koppeltekens.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Stripe-integratie`,
  [`Streaming rule`]: `Streamingregel`,
  [`Subject`]: `Onderwerp`,
  [`Subject Claim`]: `Subject-claim`,
  [`Subject Origin`]: `Oorsprong onderwerp`,
  [`Subject common name`]: `Algemene naam onderwerp`,
  [`Subject|certificate`]: `Onderwerp`,
  [`Submit`]: `Indienen`,
  [`Submitted`]: `Ingediend`,
  [`Subscribability`]: `Abonnementsmogelijkheden`,
  [`Subscribability must be disabled if visibility is disabled`]: `De abonneerbaarheid moet worden uitgeschakeld als zichtbaarheid is uitgeschakeld`,
  [`Subscribability options`]: `Abonnementsopties`,
  [`Subscribable by`]: `Abonneerbaar voor`,
  [`Subscribe`]: `Abonneren`,
  [`Subscribe Financial Management App`]: `App Financieel Beheer abonneren`,
  [`Subscribe TODO App`]: `Abonneren op TODO App`,
  [`Subscription`]: `Abonnement`,
  [`Subscription approval task for subscription of`]: `Goedkeuringstaak voor abonnement op`,
  [`Subscription created`]: `Abonnement aangemaakt`,
  [`Subscription has been added`]: `Abonnement is toegevoegd`,
  [`Subscription has not been added`]: `Abonnement is niet toegevoegd`,
  [`Subscription pending approval`]: `Abonnement wacht op goedkeuring`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `Abonnementstype is verwijderd omdat GraphQL-abonnementen niet worden ondersteund.`,
  [`Subscription-Approval`]: `Abonnement-goedkeuring`,
  [`Subscriptions`]: `Abonnementen`,
  [`Success`]: `Gelukt`,
  [`Suffix`]: `Suffix`,
  [`Suggestion`]: `Suggestie`,
  [`Supersede`]: `Verdringen`,
  [`Supersede Product`]: `Product verdringen`,
  [`Supersede product`]: `Product verdringen`,
  [`Supersede plans`]: `Plannen verdringen`,
  [`Supersede {product1} with {product2}`]: `{product1} laten verdringen door {product2}`,
  [`Supersede|permission`]: `Verdringen`,
  [`Supersede|supersede with`]: `Verdringen`,
  [`Support`]: `Ondersteuning`,
  [`support default introspection`]: `standaardintrospectie ondersteunen`,
  [`Support Server Name Indication (SNI)`]: `Ondersteuning bieden aan Server Name Indication (SNI)`,
  [`Support hybrid response types`]: `Ondersteuning bieden aan hybride responstypen`,
  [`Support standard introspection`]: `Standaardintrospectie ondersteunen`,
  [`Supported OAuth components`]: `Ondersteunde OAuth-componenten`,
  [`Supported client types`]: `Ondersteunde typen clients`,
  [`Supported client types can not be empty.`]: `Ondersteund clienttype mag niet leeg zijn.`,
  [`Supported grant type can not be empty.`]: `Het ondersteunde type rechtenverlening mag niet leeg zijn.`,
  [`Supported grant types`]: `Ondersteunde typen rechtenverlening`,
  [`Suspend`]: `Onderbreken`,
  [`Suspend API`]: `API onderbreken`,
  [`Suspend Application`]: `Toepassing onderbreken`,
  [`Switch`]: `Wisselen`,
  [`Switch account`]: `Account wisselen`,
  [`Switch cloud connection`]: `Cloudverbinding wisselen`,
  [`Switch directory`]: `Switchdirectory`,
  [`TARGET`]: `DOEL`,
  [`Title`]: `Titel`,
  [`TlS`]: `TLS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `TLS-client`,
  [`TLS Client Profile`]: `TLS-clientprofiel`,
  [`TLS Client Profile has been configured.`]: `TLS-clientprofiel is geconfigureerd.`,
  [`TLS client profile is required when endpoint starts with https://`]: `TLS-clientprofiel is vereist als het eindpunt begint met https://`,
  [`TLS Client Profiles`]: `TLS-clientprofielen`,
  [`TLS client profile has been configured.`]: `TLS-clientprofiel is geconfigureerd.`,
  [`TLS client profiles`]: `TLS-clientprofielen`,
  [`TLS Profile`]: `TLS-profiel`,
  [`TLS SERVER PROFILE`]: `TLS-SERVERPROFIEL`,
  [`TLS server profile`]: `TLS-serverprofiel`,
  [`TLS version 1.0`]: `TLS-versie 1.0`,
  [`TLS version 1.1`]: `TLS-versie 1.1`,
  [`TLS version 1.2`]: `TLS-versie 1.2`,
  [`TLS version 1.3`]: `TLS-versie 1.3`,
  [`TLS client profile`]: `TLS-clientprofiel`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `Als het gebruik van ruimten ingeschakeld is, worden TLS-clientprofielen in elke ruimte beheerd. Ga naar de ruimte-instellingen om de TLS-clientprofielen te beheren.`,
  [`TLS profile`]: `TLS-profiel`,
  [`TLS profile is not visible or not available.`]: `TLS-profiel is niet zichtbaar of niet beschikbaar.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `TLS-profiel voor de veilige transmissie van gegevens naar de verificatie-URL.`,
  [`Tabs for code language options`]: `Tabs voor codetaalopties`,
  [`Truststore`]: `Truststore`,
  [`truststore`]: `truststore`,
  [`Type`]: `Type`,
  [`Type name`]: `Typenaam`,
  [`Type/Field`]: `Type/veld`,
  [`Type weight`]: `Typegewicht`,
  [`Types per page:`]: `Typen per pagina:`,
  [`Tags`]: `Labels`,
  [`Tags and external documentation`]: `Tags en externe documentatie`,
  [`Take offline`]: `Offline zetten`,
  [`Target`]: `Doel`,
  [`Target API (URL)`]: `Doel-API (URL)`,
  [`Target SOAP service`]: `SOAP-doelservice`,
  [`Target Services`]: `Doelservices`,
  [`Target service URL`]: `URL van doelservice`,
  [`Target services`]: `Doelservices`,
  [`Target URL`]: `Doel-URL`,
  [`Target application`]: `Doeltoepassing`,
  [`Target catalog`]: `Doelcatalogus`,
  [`Target gateway service`]: `Doelgatewayservice`,
  [`Target gateway services`]: `Doelgatewayservices`,
  [`Target plan`]: `Doelplan`,
  [`Target product`]: `Doelproduct`,
  [`Target product rate limit`]: `Aanroeplimiet voor doelproduct`,
  [`Target product subscribability`]: `Abonneerbaarheid van doelproduct`,
  [`Target product visibility`]: `Zichtbaarheid doelproduct`,
  [`Target schema`]: `Doelschema`,
  [`Target services have been updated.`]: `Doelservices zijn bijgewerkt.`,
  [`Task`]: `Taak`,
  [`Task has been updated`]: `Taak is bijgewerkt`,
  [`Task self approval`]: `Zelfgoedkeuring taak`,
  [`Task self-approval`]: `Zelfgoedkeuring van taken`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Taken`,
  [`Tasks for creating new provider organizations`]: `Taken voor het maken van nieuwe providerorganisaties`,
  [`TCP`]: `TCP`,
  [`Template`]: `Sjabloon`,
  [`Template: {title}`]: `Sjabloon: {title}`,
  [`Template: account approved`]: `Sjabloon: account goedgekeurd`,
  [`Template: account denied`]: `Sjabloon: account geweigerd`,
  [`Template: account pending approval`]: `Sjabloon: Account in behandeling zijnde goedkeuring`,
  [`Template: app lifecycle approved`]: `Sjabloon: app-levenscyclus goedgekeurd`,
  [`Template: app lifecycle denied`]: `Sjabloon: app-levenscyclus geweigerd`,
  [`Template: app lifecycle pending`]: `Sjabloon: App-levenscyclus in behandeling`,
  [`Template: app reinstated`]: `Sjabloon: app hersteld`,
  [`Template: app suspended`]: `Sjabloon: app onderbroken`,
  [`Template: app lifecycle cancelled`]: `Sjabloon: app-levenscyclus geannuleerd`,
  [`Template: app lifecycle request`]: `Sjabloon: aanvraag app-levenscyclus`,
  [`Template: invitation`]: `Sjabloon: uitnodiging`,
  [`Template: mail server test connection`]: `Sjabloon: testverbinding mailserver`,
  [`Template: member invitation`]: `Sjabloon: uitnodiging van lid`,
  [`Template: password changed`]: `Sjabloon: wachtwoord gewijzigd`,
  [`Template: password reset`]: `Sjabloon: wachtwoord opnieuw instellen`,
  [`Template: sign up`]: `Sjabloon: aanmelden`,
  [`Template: task consumer onboard request`]: `Sjabloon: aanvraag onboardingstaak voor consument`,
  [`Template: task product lifecycle approved`]: `Sjabloon: taak levenscyclus van product is goedgekeurd`,
  [`Template: task product lifecycle cancelled`]: `Sjabloon: taak levenscyclus van product is geannuleerd`,
  [`Template: task product lifecycle denied`]: `Sjabloon: taak levenscyclus van product is geweigerd`,
  [`Template: task product lifecycle pending`]: `Sjabloon: taak levenscyclus van product in behandeling`,
  [`Template: task product lifecycle request`]: `Sjabloon: aanvraag voor taak levenscyclus van product`,
  [`Template: task subscription cancelled`]: `Sjabloon: taak abonnement geannuleerd`,
  [`Template: task subscription request`]: `Sjabloon: taak abonnementsaanvraag`,
  [`Template: task subscription approved`]: `Sjabloon: taak abonnement goedgekeurd`,
  [`Template: task subscription denied`]: `Sjabloon: taak abonnement geweigerd`,
  [`Template: task subscription pending`]: `Sjabloon: taak abonnement in behandeling`,
  [`Temporarily disable subscribability`]: `Abonnementsmogelijkheden tijdelijk uitschakelen`,
  [`Temporarily disable visibility`]: `Zichtbaarheid tijdelijk uitschakelen`,
  [`Terms of service`]: `Servicevoorwaarden`,
  [`Test`]: `Testen`,
  [`Test API`]: `API testen`,
  [`Test APIs`]: `API's testen`,
  [`Test Bind & Get Base DN`]: `Bind testen &amp; basis-DN ophalen`,
  [`Test configuration`]: `Configuratie testen`,
  [`Test connection`]: `Verbinding testen`,
  [`Test connection failed.`]: `Testen van verbinding is mislukt.`,
  [`Test and production endpoints`]: `Test- en productie-eindpunten`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Responsfout test en monitor: eigenschap voor redirect_uri is vereist, opgehaald is {redirectUri}`,
  [`Test application`]: `Testtoepassing`,
  [`Test consumer org`]: `Consumentorganisatie testen`,
  [`Test email`]: `E-mail testen`,
  [`Test endpoints`]: `Testeindpunten`,
  [`Test message from IBM API Connect`]: `Testbericht van IBM API Connect`,
  [`Testable`]: `Testbaar`,
  [`TEXT`]: `TEKST`,
  [`The "Definition" property must have a valid value.`]: `De eigenschap "Definitie" moet een geldige waarde hebben.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `De eigenschap "Definitie" moet de waarde "request" of "response" hebben of beginnen met "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `De API Connect-CLI wordt aangeboden als IBM Cloud CLI-plugin. U gaat als volgt aan de slag:`,
  [`The API has unsaved changes.`]: `De API bevat wijzigingen die nog niet zijn opgeslagen.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `De API is volledig ontworpen en heeft een interne mijlpaal doorstaan, maar is nog niet geïmplementeerd.`,
  [`The API is in the implementation phase.`]: `De API bevindt zich in de implementatiefase.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `De API bevindt zich nog in een vroege ontwikkelingsfase; zowel het ontwerp als de implementatie is nog niet voltooid.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `De API heeft mogelijk niet-gepubliceerde wijzigingen. Wilt u het product opnieuw publiceren?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `Het DNS-schema bepaalt de samenstelling van eindpunt-URL's voor de inkomende API-aanroepen naar de gatewayservice en voor het verkrijgen van toegang tot de ontwikkelaarsportal.`,
  [`The GatewayScript source code to execute.`]: `De GatewayScript-broncode die uitgevoerd wordt.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `De URL van het GraphQL-schema waarmee een GraphQL-payload moet worden gevalideerd.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `De HTTP-methode die wordt gebruikt voor de oproep. Als deze waarde wordt weggelaten of is ingesteld op "Behouden", wordt de methode van de inkomende aanvraag gebruikt.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `De HTTP-methode die wordt gebruikt ter vervanging van de huidige HTTP-methode. Als deze waarde wordt weggelaten of is ingesteld op "Behouden", wordt de methode van de inkomende aanvraag gebruikt.`,
  [`The JavaScript source code to execute.`]: `De JavaScript-broncode die wordt uitgevoerd.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `Het Lightweight Directory Access Protocol (LDAP) is een internetprotocol voor het benaderen en onderhouden van informatieservices voor gedistribueerde directory's via een netwerk. Als er bij u een grootschalige LDAP-service werkzaam is, configureer die dan als bron die kan worden gebruikt voor gebruikersverificatie.`,
  [`The Proxy URL to be used.`]: `De proxy-URL die gebruikt wordt.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `Het SSL-profiel dat wordt gebruikt voor de veilige transmissie van gegevens.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `Het TLS-profiel dat wordt gebruikt voor de veilige transmissie van gegevens.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `De URL van het JSON-schema voor het valideren van een JSON-payload.`,
  [`The URL of the XML schema for validating an XML payload.`]: `De URL van het XML-schema waarmee een XML-payload moet worden gevalideerd.`,
  [`The URL to be invoked.`]: `De URL die wordt opgeroepen.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `De URL van het WSDL-schema waarmee een WSDL-payload moet worden gevalideerd.`,
  [`The XML element name to be used for JSON array elements.`]: `De naam van het XML-element die moet worden gebruikt voor JSON-arrayelementen.`,
  [`The XSLT source to execute.`]: `De XSLT-bron die wordt uitgevoerd.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `De activeringslink kan naar een gebruiker worden gestuurd om die gebruiker in staat te stellen zijn of haar account te activeren`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `De analyseservice verzamelt API-events van de gatewayservice. Bij elke gateway hoort een analyseservice. Elke keer dat er een API-bewerking wordt aangeroepen, wordt er een API-event geregistreerd, compleet met tijdsaanduiding.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `De analyseservice verzamelt API-events van de gatewayservice. Elke gatewayservice kan een bijbehorende analyseservice hebben.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `De autorisatie-URL is de plaats waar er autorisaties worden verleend aan de clienttoepassing. De token-URL is de plaats waar de clienttoepassing een verleende autorisatie voor een toegangstoken uitwisselt.  De introspectie-URL is de plaats waar de API-gateway de door de externe provider uitgegeven toegangstokens valideert.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `Het basispad is het eerste URL-segment van de API. Dit is dus exclusief de hostnaam of eventuele andere segmenten voor paden of bewerkingen`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `Het cachetype bepaalt of documenten in cache geplaatst worden, waarbij de HTTP Cache Control-instructies die zijn ontvangen via de respons van de server worden gehonoreerd of overschreven. Deze eigenschap is alleen van kracht wanneer er een respons wordt ontvangen van de server. Anders resulteert het beleid altijd in de niet-vervallen respons die eerder in cache is opgeslagen.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `De Certificate Manager-service moet de certificaten beheren voor het domein van het gatewaybeheereindpunt en de domeinen die door de gateway worden verwerkt. Bovendien moet u IBM Cloud IAM gebruiken voor het autoriseren van de API Connect Reserved Instance-service  voor toegang tot de Certificate Manager-service.`,
  [`The certificate with which to decrypt the buffer`]: `Het certificaat waarmee de buffer moet worden gedecodeerd`,
  [`The certificate with which to encrypt the buffer`]: `Het certificaat waarmee de buffer moet worden versleuteld`,
  [`The change is critical`]: `De wijziging is essentieel`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Alleen de bereiken (of subsets daarvan) die u hier definieert, kunnen door de clienttoepassingen worden aangevraagd. De bereiken worden opgenomen in de toegangstokens die vanuit de provider worden gegenereerd. Als er een met OAuth beschermde API wordt aangeroepen, vergelijkt de gateway de bereiken die in de toegangstokens aanwezig zijn met de lijst van bereiken die in de beveiligingsdefinitie toegestaan zijn. Op basis daarvan bepaalt de gateway of er toegang wordt verleend. Daarnaast kunt u geavanceerde bereikcontrole afdwingen. De URL's voor de geavanceerde bereikcontrole worden aangeroepen, hetzij na afloop van de toepassingsverificatie, hetzij na afloop van de gebruikersverificatie; dit hangt af van de vraag welke URL's er geconfigureerd zijn. De uiteindelijke bereiktoestemming die door het toegangstoken wordt verleend, is het resultaat van alle bereikcontroles.`,
  [`The crypto object to use to decode the claim.`]: `Het cryptografisch object voor decodering van het bericht.`,
  [`The crypto object to use to encrypt the message.`]: `Het cryptografisch object voor versleuteling van het bericht.`,
  [`The crypto object to use to sign the JWT.`]: `Het cryptografische object voor ondertekening van de JWT.`,
  [`The crypto object to use to verify the signature.`]: `Het cryptografische object voor controle van de handtekening.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `De huidige toewijzing bevat verwijzingen naar niet-herkende schema-elementen.`,
  [`The current organization does not contain any catalogs.`]: `De huidige organisatie bevat geen enkele catalogus.`,
  [`The default User registry must be included in the list`]: `Het standaard gebruikersregister moet worden opgenomen in de lijst`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `De definitie ten opzichte waarvan de payload moet worden gevalideerd ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `De ontwikkelaarsportal bevat de consumentenview van gepubliceerde API-producten. Nadat u de ontwikkelaarsportal voor uw catalogus hebt geconfigureerd en nadat er een of meer API-producten zijn gepubliceerd, zijn die API's zichtbaar en bruikbaar voor toepassingsontwikkelaars.`,
  [`The document is missing draft_api.info.version field.`]: `Het veld draft_api.info.version ontbreekt in het document.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `Het veld draft_api.info.x-ibm-name ontbreekt in het document.`,
  [`The document is missing draft_product.info.name field.`]: `Het veld draft_product.info.name ontbreekt in het document.`,
  [`The document is missing draft_product.info.version field.`]: `Het veld draft_product.info.version ontbreekt in het document.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `De extra voorwaarden geven een slicingargument aan dat geen argument voor dit veld is.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `Dit veld heeft een limietargument met een standaardwaarde. Daarom kan de aangegeven veronderstelde grootte nooit worden toegepast.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `Dit veld heeft een limietargument met een standaardwaarde of is vereist. Daarom kan de aangegeven veronderstelde grootte nooit worden toegepast.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `Dit veld heeft noch een veronderstelde grootte, noch enige slicingargumenten.`,
  [`This field returns unbound list of values with composite type.`]: `Dit veld retourneert een niet-begrensde lijst van waarden met een samengesteld type.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `Dit veld retourneert een niet-begrensde lijst van waarden met een scalair type en het type gewicht != 0.0.`,
  [`This test panel is deprecated and will be removed soon.`]: `Dit testvenster is gedeprecieerd en wordt binnenkort verwijderd.`,
  [`This type has no fields or no matching field.`]: `Dit type heeft geen velden of geen overeenkomend veld.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Dit beleid van GatewayScript mag de apim-module niet gebruiken. Deze module is alleen bedoeld voor het migreren van oude API's.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Dit XSLT-beleid mag de apim-module niet gebruiken. Deze module is alleen voor het migreren van oude API's.`,
  [`This type will be deleted.`]: `Dit type wordt gewist.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `In het volgende diagram ziet u de assemblagestroom voor uw API. Als u de assemblage wilt wijzigen, dan kan dat met de assemblage-editor.`,
  [`The following files are available under the`]: `De volgende bestanden zijn beschikbaar onder de`,
  [`The following files contain a private key and cannot be uploaded:`]: `De volgende bestanden bevatten een persoonlijke sleutel en kunnen daarom niet worden geüpload:`,
  [`The following is available under the`]: `Het volgende is beschikbaar onder de`,
  [`The following lifecycle actions require approval`]: `Voor de volgende acties in de levenscyclus is goedkeuring vereist`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `In de volgende lijst ziet u de concept-API's die binnen deze providerorganisatie zijn gemaakt.`,
  [`The following list displays the draft products that have been created in this provider organization`]: `In de volgende lijst ziet u de concept-producten die binnen deze providerorganisatie zijn gemaakt`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `De volgende lijst geeft een overzicht van alle gebruikers die zijn toegevoegd als lid van deze catalogus, inclusief de rollen die aan hen zijn toegewezen`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `De volgende lijst geeft een overzicht van alle gebruikers die zijn toegevoegd als lid van deze ruimte, inclusief de rollen die aan hen zijn toegewezen`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `De volgende parameters komen niet overeen met de waarden die zijn geconfigureerd in de OAuth-provider`,
  [`The following roles are available in the Cloud Manager`]: `De volgende rollen zijn beschikbaar in Cloud Manager`,
  [`The following types/fields will also be removed:`]: `De volgende typen/velden worden ook verwijderd:`,
  [`The following types/fields will be affected:`]: `De volgende typen/velden worden beïnvloed:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `De waarde {gatewayType} is niet toegestaan voor de gateway-eigenschap. Toegestane waarden zijn datapower-gateway en datapower-api-gateway`,
  [`The host used to invoke the API`]: `De host die wordt gebruikt voor het aanroepen van de API`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `De keystore bevat bij elkaar horende paren van openbare certificaten en persoonlijke sleutels. Deze worden gebruikt om de identiteit te controleren en om de datatransmissie via HTTPS te versleutelen en te decoderen.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `De keystore bevat bij elkaar horende paren van openbare certificaten en persoonlijke sleutels. Deze worden gebruikt om de identiteit te controleren en om de datatransmissie via HTTPS te versleutelen en te decoderen. Voor het serverprofiel is een keystore vereist.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `De keystore bevat de openbare sleutels en de persoonlijke (besloten) certificaten die door de server of client worden aangeboden om hun identiteit te laten verifiëren.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `De tijdsduur (in seconden) die wordt toegevoegd aan de actuele datum en tijd, gedurende welke de LTPA als geldig wordt beschouwd.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `De tijdsduur (in seconden), die wordt toegevoegd aan de huidige datum en tijd, gedurende welke de JWT als geldig wordt beschouwd.`,
  [`The message to be sent with the error`]: `Het bericht dat wordt meegezonden met de fout`,
  [`The message to be included within exception.`]: `Het bericht dat binnen een uitzondering moet worden opgenomen.`,
  [`The HTTP status code to be included within exception.`]: `De HTTP-statuscode die binnen de uitzondering moet worden opgenomen.`,
  [`The HTTP status reason phrase to be included within exception.`]: `De HTTP-statusoorzaak die binnen de uitzondering moet worden opgenomen.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `De naam van een burstlimiet die is gedefinieerd in de configuratie van datapower.`,
  [`The name of a count limit defined in the datapower configuration.`]: `De naam van een aantallimiet die is gedefinieerd in de configuratie van datapower.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `De naam van een aanroeplimiet die is gedefinieerd in de configuratie van datapower.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `De naam van een variabele die wordt gebruikt voor het opslaan van de responsgegevens uit de aanvraag. Vervolgens kan hiernaar worden verwezen in andere acties, zoals "Toewijzen".`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `De naam van een variabele die wordt gebruikt voor het opslaan van het resultaat. Standaard wordt "message" gebruikt.`,
  [`The name of parameter to find the ID.`]: `De naam van de parameter voor het opzoeken van het ID.`,
  [`The name of parameter to find the secret.`]: `De naam van de parameter voor het opzoeken van het geheim.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `De naam van de LTPA-sleutel die u wilt gebruiken voor het genereren van het LTPA-token. Als u wilt dat er een specifieke versie van de sleutel wordt gebruikt, gebruikt u <keyname>:<version>. Bijvoorbeeld: mijn-ltpa-sleutel:1.0.1. Als u wilt dat het beleid automatisch de nieuwste versie van de sleutel kiest, gebruikt u <keyname>:latest. Bijvoorbeeld: mijn-ltpa-sleutel:latest.`,
  [`The name of the error to be thrown`]: `De naam van de fout die wordt gegenereerd`,
  [`The name of the variable to be added.`]: `De naam van de variabele die moet worden toegevoegd.`,
  [`The name of the variable to be cleared.`]: `De naam van de variabele die moet worden gewist.`,
  [`The name of the variable to be set.`]: `De naam van de variabele die moet worden ingesteld.`,
  [`The nature of the XML validation to be performed.`]: `De aard van de XML-validatie die moet worden uitgevoerd.`,
  [`The operation to apply.`]: `De bewerking die moet worden toegepast`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `De uitvoer van deze toewijzing bevindt zich binnen een array en de inhoud wordt gegenereerd door iteratie over de gekozen invoer. Als de gekozen invoer geen array is, wordt een array met lengte 1 gegenereerd.`,
  [`The owner must be an existing user in your user registry`]: `De eigenaar moet een gebruiker zijn die al aanwezig is in uw gebruikersregister`,
  [`The owner of consumer organization has the following privileges:`]: `De eigenaar van de consumentorganisatie heeft de volgende bevoegdheden:`,
  [`The owner of provider organization has the following privileges:`]: `De eigenaar van de providerorganisatie heeft de volgende bevoegdheden:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Tot de taken van de eigenaar behoren: het beheren van de levenscyclus van API-producten, het beheren van een of meer API-providerorganisaties, het beheren van community's van toepassingsproviders en het schrijven van API's en productdefinities.`,
  [`The page you are looking for might have been moved or does not exist.`]: `De pagina die u zoekt, is mogelijk verplaatst of bestaat niet.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `De pagina die u zoekt, is mogelijk verplaatst of bestaat niet. Neem voor hulp contact op met de systeembeheerder.`,
  [`The parameters needed to send a valid API call.`]: `De parameters die nodig zijn voor het verzenden van een geldige API-aanroep.`,
  [`The password to use for HTTP Basic authentication.`]: `Het wachtwoord dat voor HTTP Basic-verificatie gebruikt wordt.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `De portalservice stelt een Developer portal beschikbaar, die door ontwikkelaars van toepassingen kan worden gebruikt voor de opsporing van API's en de onboarding van consumenten.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `De portalservice stelt een ontwikkelaarsportal beschikbaar, die door ontwikkelaars van toepassingen kan worden gebruikt om API's op te sporen en te implementeren.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `De verwerkingsstatus levert een view van de health van de gateway met een overzicht van recente activiteit`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `De ontvanger registreert zich als eigenaar en maakt een nieuwe providerorganisatie. Na zich te hebben geregistreerd, kan de gebruiker zich bij API Manager aanmelden als de eigenaar van de providerorganisatie.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `De naam van het hoofdelement van het resulterende XML-document. Deze is alleen vereist als het JSON-invoerdocument niet hiërarchisch is en meer dan één eigenschap op basisniveau heeft.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `De hier genoemde bereiken zijn bedoeld om ontwikkelaars een beeld te geven van de bereiken waartoe toegang wordt verleend. Ze worden niet gebruikt voor bereikcontrole.`,
  [`The selected catalog will only be used to search consumer organizations`]: `De geselecteerde catalogus wordt alleen gebruikt voor het zoeken naar consumentorganisaties`,
  [`The selected catalog does not have any configured gateways`]: `De geselecteerde catalogus bevat geen geconfigureerde gateways`,
  [`The selected catalog does not have any compatible gateways`]: `De geselecteerde catalogus heeft geen compatibele gateways`,
  [`Save Preferences`]: `Voorkeuren opslaan`,
  [`Preferences`]: `Voorkeuren`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `De geselecteerde catalogus wordt alleen gebruikt voor het zoeken naar consumentorganisaties en groepen`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `Door het geselecteerde type gateway worden de volgende beleidsdefinities in uw assemblage ongeldig. Deze beleidsdefinities moet u wissen om deze API te kunnen uitvoeren.`,
  [`The selected product does not contain this API`]: `Deze API maakt geen deel uit van het geselecteerde product`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `Consumentorganisaties of groepen die op een specifieke lijst staan, kunnen zich abonneren op het geselecteerde product.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `De geselecteerde services zijn beschikbaar voor een specifieke lijst van providerorganisaties.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `De geselecteerde services zijn beschikbaar voor alle bestaande en nieuwe providerorganisaties.`,
  [`The selected services will not be available to any provider organizations.`]: `De geselecteerde services zijn voor geen enkele providerorganisatie beschikbaar.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `De tekenreeks die gebruikt wordt als sleutel voor de cache. Als deze waarde wordt weggelaten, wordt de gehele URL-reeks gebruikt als sleutel.`,
  [`The server responded with a non-JSON body \n{body}`]: `De server heeft gereageerd met een niet-JSON body \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `Het tijdsinterval moet hoger zijn dan de levensduur van het toegangstoken.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Het token wordt niet opgeslagen in een cookie of in lokale opslag. Als u het testvenster sluit, gaat het token verloren maar kan het nog steeds geldig zijn.`,
  [`The transfer protocol of the API.`]: `Het overdrachtsprotocol van de API.`,
  [`The type of authentication.`]: `Het type verificatie.`,
  [`The type of authorization header.`]: `Het type autorisatiekop.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `Het type van de waarde die wordt ingesteld. Dit kan zijn: willekeurig, tekenreeks, getal of booleaanse waarde.`,
  [`The type of the value to set. This can be string, number or boolean.`]: `Het type van de waarde die wordt ingesteld. Dit kan een tekenreeks, getal of booleaanse waarde zijn.`,
  [`The username to use for HTTP Basic authentication.`]: `De gebruikersnaam die voor HTTP Basic-verificatie gebruikt wordt.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `De gebruiker voor deze verbinding is niet meer geldig. Maak een nieuwe verbinding.`,
  [`The value that the variable will be set to.`]: `De waarde waarop de variabele ingesteld wordt.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `De waarde van dit veld is een lijst van het samengestelde type, dat noch een veronderstelde grootte, noch argumenten voor slicing heeft. Als dit wordt aangetroffen, kan de API-gateway geen limiet instellen voor het aantal objecten dat de GraphQL-server kan retourneren als deze de query ontvangt.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `De waarde van dit veld is een lijst van het scalaire type, dat noch een veronderstelde grootte, noch argumenten voor slicing heeft. Als dit wordt aangetroffen, kan de API-gateway geen limiet instellen voor het aantal objecten dat de GraphQL-server kan retourneren als deze de query ontvangt.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `De versie moet URL-safe zijn. De versie van het object, {version}, en de slugified versie, {slugVersion}, komen niet overeen.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `De eigenschap "name" moet URL-veilig zijn (de naamwaarde "{name}" van het object en de sluierde naamwaarde "{slugName}" moet overeenkomen).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `De eigenschap 'draft_api' moet een OpenAPI-definitie bevatten. OpenAPI versie 3 ondersteunt alleen versies 3.0.0-3.0.3 (opgegeven versie {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `Het veld Naam wordt automatisch ingevuld op basis van de titel die u opgeeft. Gebruik de resulterende waarde binnen API Connect-opdrachten en API-aanroepen.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `De zichtbaarheidsinstelling bepaalt welke organisaties er toegang kunnen krijgen tot een resource. De keuzen zijn: Openbaar (de resource kan door alle providerorganisaties worden gebruikt); Aangepast (de resource kan alleen worden gebruikt door providerorganisaties die door u zijn aangewezen); en Besloten (de resource is niet zichtbaar en kan door geen enkele providerorganisatie worden gebruikt).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `De zichtbaarheidsinstelling bepaalt welke providerorganisaties er gebruik kunnen maken van een service. De keuzen zijn: Openbaar (de service kan door alle providerorganisaties worden gebruikt); Aangepast (de service kan alleen worden gebruikt door providerorganisaties die door u zijn aangewezen); en Besloten (de service is niet zichtbaar en kan door geen enkele providerorganisatie worden gebruikt).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `De zichtbaarheidsinstelling bepaalt welke provideorganisaties gebruik kunnen maken van een gateway. Zichtbaarheidsniveaus zijn: Openbaar (de gateway kan door alle provideorganisaties worden gebruikt); Aangepast (de gateway kan alleen worden gebruikt door provideorganisaties die door u zijn aangewezen); en Besloten (de gateway is niet zichtbaar en kan door geen enkele provideorganisatie worden gebruikt).`,
  [`The {title} role`]: `De rol van {title}`,
  [`The {url} resource does not exist`]: `De resource {url} bestaat niet`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Er zijn incompatibele APIs omdat ze een ander gatewaytype naar dit product hebben.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Selecteer vervolgens het certificaat voor het domein van het beheereindpunt. Selecteer ook de bijbehorende CA-bundel als het certificaat niet is toegewezen door een bekende certificaatgever. Het certificaat en de bundel moeten worden beheerd door de Certificate Manager-service.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Vervolgens geeft u de domeinen op die door deze gateway worden afgehandeld. De SNI-techniek wordt gebruikt. Als gevolg hiervan kunnen meerdere domeinen worden bediend via hetzelfde IP-adres en dezelfde poort zonder dat hetzelfde certificaat is vereist. Jokertekens zijn toegestaan. Het standaard (catch-all)-domein ‘*’ moet worden opgenomen als laatste item in de tabel. Geef indien nodig andere domeinnamen op, waarbij u in elk geval een door de Certificate Manager-service beheerd certificaat opgeeft.`,
  [`There should be at least {minSelected} {label} selected.`]: `Er moeten ten minste {minSelected} {label} zijn geselecteerd.`,
  [`There are no API changes to publish.`]: `Er zijn geen API-wijzigingen om te publiceren.`,
  [`There are no gateway services`]: `Er zijn geen gatewayservices`,
  [`There are no assemblies that match the chosen filters.`]: `Geen enkele assemblage voldoet aan de gekozen filters.`,
  [`There are no burst limits yet.`]: `Er zijn nog geen burstlimieten.`,
  [`There are no catalogs`]: `Er zijn geen catalogi`,
  [`There are no consumer organizations.`]: `Er zijn geen consumentorganisaties.`,
  [`There are no consumer organizations. You can create one`]: `Er zijn geen consumentorganisaties. U kunt er een maken`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `Er zijn geen consumentorganisaties. Om er een te maken, klikt u [hier]({link})`,
  [`There are no email servers`]: `Er zijn geen e-mailservers`,
  [`There are no gateway services configured for the sandbox catalog`]: `Er zijn geen gatewayservices geconfigureerd voor de sandboxcatalogus`,
  [`There are no lifecycle approvals enabled`]: `Er zijn geen goedkeuringen voor levenscyclus ingeschakeld`,
  [`There are no new changes.`]: `Er zijn geen nieuwe wijzigingen.`,
  [`There are no products to display`]: `Er zijn geen producten om af te beelden`,
  [`There are no products to replace`]: `Er zijn geen producten om te vervangen`,
  [`There are no products to set migration target`]: `Er zijn geen producten om het migratiedoel in te stellen`,
  [`There are no products to supersede`]: `Er zijn geen producten om te verdringen`,
  [`There are no properties to configure for this policy`]: `Er zijn voor dit beleid geen eigenschappen die geconfigureerd kunnen worden`,
  [`There are no rate limits yet.`]: `Er zijn nog geen aanroeplimieten.`,
  [`There are no security definitions`]: `Er zijn geen beveiligingsdefinities`,
  [`There are no spaces`]: `Er zijn geen ruimten`,
  [`There are no target services`]: `Er zijn geen doelservices`,
  [`There are no tasks to be displayed`]: `Er zijn geen taken om weer te geven.`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Er zijn wijzigingen die nog niet zijn opgeslagen. Weet u zeker dat u wilt doorgaan?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Er zijn wijzigingen die nog niet zijn opgeslagen. Ze worden verwijderd als u doorgaat. Weet u het zeker?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `In uw YAML is er een API die niet bestaat en die niet kan worden vermeld. Kijk op het tabblad Bron.`,
  [`There is no item in the list.`]: `De lijst bevat geen item.`,
  [`There is no space`]: `Er is geen ruimte`,
  [`There is no warning in the schema.`]: `Het schema bevat geen waarschuwing.`,
  [`There was no data found to populate the data table.`]: `Er zijn geen gegevens gevonden om de gegevenstabel te vullen.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Deze eindpunten worden gebruikt voor alle API's in de catalogus`,
  [`Third party OAuth provider`]: `Externe OAuth-provider`,
  [`Third party OAuth provider summary`]: `Samenvatting van externe OAuth-provider`,
  [`Third party`]: `Extern`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Deze API bevat nog geen assemblage. Wilt u er een maken?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Deze resource bestaat niet meer, vernieuw de resultaten van de server.`,
  [`Please refresh the results from the server`]: `Vernieuw de resultaten van de server`,
  [`Exact match`]: `Exacte match`,
  [`Closest match`]: `Beste match`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Deze API wordt momenteel gebruikt door het product {productName}. Verwijder de API eerst uit het product. Daarna kunt u de API zelf wissen.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Deze API is beschikbaar om te worden opgeroepen wanneer de volgende optie ingeschakeld is.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Voor deze catalogus is geen automatische abonnering ingeschakeld. Als u in deze catalogus wilt testen, moet u hieronder een catalogus, plan en toepassing selecteren.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Dit eindpunt wordt gebruikt als de standaardwaarde voor API's waarvoor er geen basiseindpunt is gedefinieerd`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Dit is een technical preview-functie die verder ontwikkeld blijft worden`,
  [`This is the currently configured notification server.`]: `Dit is de momenteel geconfigureerde berichtenserver.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `Dit is de directory waarin uw bestanden worden opgeslagen, inclusief beleidsdefinities en extensies.`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `Dit is waar API's worden uitgevoerd en beleid en uitbreidingen toegankelijk zijn`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Dit Oauth-beleid voert standaard alle OAuth-protocolstappen uit die nodig zijn voor het opgegeven pad. De invoer en uitvoer van elk van de stappen worden aangestuurd door gedocumenteerde contextvariabelen. De Ondersteunde OAuth-componenten kunt u toevoegen of verwijderen zoals nodig is.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Dit Oauth-beleid voert standaard alle OAuth/OpenID Connect-protocolstappen uit die nodig zijn voor Oauth-validatie. De invoer en uitvoer van elk van de stappen worden aangestuurd door gedocumenteerde contextvariabelen. De Ondersteunde OAuth-componenten kunt u toevoegen of verwijderen zoals nodig is.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Dit Oauth-beleid voert standaard alle OAuth/OpenID Connect-protocolstappen uit die nodig zijn voor alle andere paden. De invoer en uitvoer van elk van de stappen worden aangestuurd door gedocumenteerde contextvariabelen. De Ondersteunde OAuth-componenten kunt u toevoegen of verwijderen zoals nodig is.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Dit Oauth-beleid voert standaard alle OAuth/OpenID Connect-protocolstappen uit die nodig zijn voor het az-codepad. De invoer en uitvoer van elk van de stappen worden aangestuurd door gedocumenteerde contextvariabelen. De Ondersteunde OAuth-componenten kunt u toevoegen of verwijderen zoals nodig is.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Dit Oauth-beleid voert standaard alle OAuth/OpenID Connect-protocolstappen uit die nodig zijn voor het tokenpad. De invoer en uitvoer van elk van de stappen worden aangestuurd door gedocumenteerde contextvariabelen. De Ondersteunde OAuth-componenten kunt u toevoegen of verwijderen zoals nodig is.`,
  [`This operation is secured with access code flow OAuth`]: `Deze bewerking is beveiligd met OAuth via stroom van toegangscodes`,
  [`This operation is secured with application flow OAuth`]: `Deze bewerking is beveiligd met OAuth via toepassingsstroom`,
  [`This operation is secured with basic authentication`]: `Deze bewerking is beveiligd met standaardverificatie`,
  [`This operation is secured with implicit flow OAuth`]: `Deze bewerking is beveiligd met OAuth via impliciete stroom`,
  [`This operation is secured with password flow OAuth`]: `Deze bewerking is beveiligd met OAuth via wachtwoordstroom`,
  [`This policy is not available on the selected gateway`]: `Dit beleid is niet beschikbaar op de geselecteerde gateway`,
  [`This policy is used by one other operation`]: `Dit beleid wordt gebruikt door één andere bewerking`,
  [`This policy is used by {{count}} other operations`]: `Dit beleid wordt gebruikt door {{count}} andere bewerkingen`,
  [`This role cannot be edited`]: `Deze rol kan niet worden gewijzigd`,
  [`This role cannot be edited.`]: `Deze rol kan niet worden gewijzigd.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `Deze instelling bepaalt wat er gebeurt als een toewijzing mislukt omdat de invoer ervan niet aanwezig is en er geen standaardtoewijzing is geconfigureerd. Het standaardgedrag is dat de uitvoer niet wordt gewijzigd, maar als u dit vakje selecteert, wordt er een leeg object gemaakt voor het bovenliggende item van de doeltoewijzing, waarmee het gedrag van versie 4 wordt geëmuleerd.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Dit gebruikersbeveiligingsbeleid voert EI- (basic) en AU-controles (auth url) uit voor Oauth-assemblage. Wijzig indien nodig de methode voor de beveiligingscontrole`,
  [`This will enable/disable application lifecycle`]: `Hiermee wordt de toepassingslevenscyclus in- of uitgeschakeld`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Hiermee schakelt u de zelfgoedkeuring van de taak in/uit. Zelfgoedkeuring van taken maakt het mogelijk dat taken worden goedgekeurd door zowel de afzender als de medewerkers. Deze optie kan handig zijn als er geen andere goedkeurders beschikbaar zijn, maar zorgt er in feite voor dat controles door andere gebruikers overgeslagen worden.`,
  [`This will unset the catalog as the default catalog.`]: `Hiermee wordt de catalogus niet meer ingesteld als standaardcatalogus.`,
  [`Throw`]: `Genereren`,
  [`Time`]: `Tijd`,
  [`Time Interval`]: `Tijdsinterval`,
  [`Time Limit`]: `Tijdslimiet`,
  [`Time to Live`]: `Levensduur`,
  [`Time to live`]: `Levensduur`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Tijd die wordt gewacht voordat er een antwoord van het eindpunt terugkomt. De standaardwaarde is 60 seconden.`,
  [`Timeout`]: `Timeout`,
  [`To`]: `Naar`,
  [`to`]: `tot`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Om een gebruiker toe te voegen als lid van de beheerorganisatie, selecteert u het gebruikersregister, voert u de gebruikersnaam in en selecteert u de rollen die u wilt toewijzen. Als het type gebruikersregister Lokaal gebruikersregister is, hebt u ook de mogelijkheid om een nieuwe gebruiker te maken die vervolgens als lid wordt toegevoegd. Het lidmaatschap wordt onmiddellijk ingeschakeld en de opgegeven gebruiker kan zich aanmelden bij de gebruikersinterface van Cloud Manager.`,
  [`To add a user as a member of a provider organization`]: `Een gebruiker toevoegen als lid van een providerorganisatie`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Om een gebruiker toe te voegen als lid van de catalogus, selecteert u de desbetreffende gebruikersnaam en wijst u de vereiste rollen toe. U kunt elke gebruiker toevoegen die al een lid van een andere catalogus of ruimte in de providerorganisatie is en momenteel geen lid van de catalogus noch de cataloguseigenaar is.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Om een gebruiker toe te voegen als lid van de ruimte, selecteert u de desbetreffende gebruikersnaam en wijst u de vereiste rollen toe. U kunt elke gebruiker toevoegen die al een lid van een andere catalogus of ruimte in de providerorganisatie is en momenteel geen lid van de ruimte noch de eigenaar ervan is.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Om leden toe te voegen of te verwijderen, gebruikt u de service [identity and access](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Om leden toe te voegen, gebruikt u de service [identity and access](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Om een gebruiker tot te voegen als lid van de organisatie, selecteert u het gebruikersregister en de gebruikersnaam van die gebruiker en wijst u een set rollen toe.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Om een gebruiker toe te voegen als lid, selecteert u de desbetreffende gebruikersnaam en wijst u een set rollen toe.`,
  [`To assign default gateways, click **Edit**.`]: `Als u standaardgateways wilt toewijzen, klikt u op **Bewerken**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Om deze software te kunnen blijven gebruiken, moet u akkoord gaan met de softwarelicentieovereenkomst.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Om door te gaan met het gebruik van API Connect, moet u een upgrade naar een Enterprise`,
  [`To get started, you must first enable spaces.`]: `Om aan de slag te gaan, moet u eerst ruimten inschakelen.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Voer de volgende stappen uit om de toolkit te installeren en uit te voeren. [Meer informatie]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Om een bestaande gebruiker uit te nodigen om lid te worden van de beheerorganisatie, geeft u het e-mailadres of de naam van de gebruiker op, of selecteert u de naam in een gebruikersregister.  Aan alle leden wordt automatisch de rol Lid toegewezen. Deze rol verleent de leden een set basismachtigingen en kan niet worden gewist. Als er geen andere machtigingen nodig zijn, laat u de andere rollen onaangekruist.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Als u een eigenaar wilt uitnodigen die nog niet aanwezig is in uw gebruikersregister, geeft u zijn of haar e-mailadres op`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Om een nieuwe gateway te registreren, gaat u naar de pagina **Gateways**.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Om waarden bij een van de volgende eigenschappen voor ruimten te vervangen, typt u $() en plaatst u de naam van de eigenschap tussen de haakjes.`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Als u een e-mailbericht wilt verzenden naar een nieuwe gebruiker om zich te registreren als lid van de beheerorganisatie, geeft u een geldig e-mailadres op en selecteert u de rollen die u aan de gebruiker wilt toewijzen. Er wordt een e-mail met een uitnodiging en een activeringslink naar de persoon in kwestie gestuurd. De gebruiker krijgt de status In Behandeling. Nadat het registratieformulier voor het activeren van het account  is ingevuld, krijgt de gebruiker de status Ingeschakeld. Hij/zij kan zich vervolgens aanmelden bij de gebruikersinterface van Cloud Manager met de API Connect-gebruikersnaam die is opgegeven tijdens het activeren van de account.`,
  [`Toggle`]: `Omschakelen`,
  [`Toggle application lifecycle?`]: `Toepassingslevenscyclus in-/uitschakelen?`,
  [`Toggle default catalog?`]: `Standaardcatalogus wisselen?`,
  [`Toggle info panel between right and bottom`]: `Infovenster wisselen van rechts naar onder en vice versa`,
  [`Toggle profile`]: `Profiel afwisselend activeren`,
  [`Toggle spaces?`]: `Spaties aan/uit?`,
  [`Toggle task self-approval?`]: `Zelfgoedkeuring van taak aan/uit?`,
  [`Token`]: `Token`,
  [`Token endpoint`]: `Eindpunt voor token`,
  [`Token Expiry`]: `Tokenverval`,
  [`Token Management`]: `Tokenbeheer`,
  [`Token Output`]: `Tokenuitvoer`,
  [`Token URL`]: `Token-URL`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Token-URL moet overeenkomen met {endpoint} zoals gedefinieerd door OAuth-provider "{providerTitle}"`,
  [`Token Version`]: `Tokenversie`,
  [`Token management`]: `Tokenbeheer`,
  [`Token management type`]: `Type tokenbeheer`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `Tokenbeheer stelt u in staat om replay-aanvallen te voorkomen en om tokens te beheren, bijvoorbeeld door aan te geven of en hoe clienttoepassingen of resource-eigenaren OAuth-tokens kunnen intrekken. API Connect ondersteunt tokenbeheer met een native gateway of een extern eindpunt. Bij gebruik van een native gateway worden er voor het beheer van tokens quota opgelegd. Bij gebruik van een extern eindpunt moet u de URL opgeven van de externe service die de tokens beheert.`,
  [`Token path`]: `Tokenpad`,
  [`Token revocation`]: `Tokenintrekking`,
  [`Token secret`]: `Tokengeheim`,
  [`Token validation`]: `Tokenvalidatie`,
  [`Tokens`]: `Tokens`,
  [`Token relay`]: `Token-relay`,
  [`Toolkit credentials`]: `Legitimatiegegevens voor toolkit`,
  [`Token Relay`]: `Token-relay`,
  [`Topology`]: `Topologie`,
  [`Topology Administrator`]: `Topologiebeheerder`,
  [`Total time`]: `Totale tijd`,
  [`Transfer Ownership to a New User`]: `Eigendom overdragen op een nieuwe gebruiker`,
  [`Transfer Ownership to an Existing User`]: `Eigendom overdragen op een bestaande gebruiker`,
  [`Transfer ownership`]: `Eigendom overdragen`,
  [`Transfer ownership to`]: `Eigendom overdragen aan`,
  [`Transfer ownership to a new user`]: `Eigendom overdragen op een nieuwe gebruiker`,
  [`Transfer ownership to an existing user`]: `Eigendom overdragen op een bestaande gebruiker`,
  [`Transforms`]: `Transformaties`,
  [`Truststore Certificates`]: `Truststore-certificaten`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Truststorecertificaten die zijn uitgegeven door een certifcaatautoriteit.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores bevatten betrouwbare certificaten die geverifieerde openbare sleutels bevatten. De certificaten in de truststore worden meestal verkregen van een externe certificaatautoriteit (CA).`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `Truststores bevatten betrouwbare certificaten met geverifieerde openbare sleutels. De certificaten in de truststore worden meestal verkregen van een externe certificaatautoriteit (CA).`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `In Truststore worden betrouwbare certificaten opgeslagen, waaronder geverifieerde openbare sleutels. De certificaten in de truststore worden meestal verkregen van een externe certificaatautoriteit (CA).`,
  [`Try again or upload a schema`]: `Probeer het opnieuw of upload een schema`,
  [`Try refreshing the page or contacting support.`]: `Probeer de pagina te vernieuwen of neem contact op met support.`,
  [`Turkish`]: `Turks`,
  [`Tutorials`]: `Zelfstudieprogramma's`,
  [`Types`]: `Soorten`,
  [`Type of user`]: `Type gebruiker`,
  [`Type or select a catalog`]: `Typ of selecteer een catalogus`,
  [`Type to add organizations`]: `Typ informatie om organisaties toe te voegen`,
  [`TypeError: Failed to fetch`]: `TypeError: Ophalen is mislukt`,
  [`UDP`]: `UDP`,
  [`URL`]: `URL`,
  [`URL of management endpoint`]: `URL van eindpunt van beheer`,
  [`URLs for all operations in the API begin with this value.`]: `URL's voor alle bewerkingen in de API beginnen met deze waarde.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `Beperkte rechten voor gebruikers in Amerikaanse overheidsdienst - Gebruik, vermenigvuldiging en openbaarmaking worden beperkt door het GSA ADP Schedule Contract met IBM Corp.`,
  [`User Defined`]: `Door gebruiker gedefinieerd`,
  [`Userinfo POST`]: `Userinfo POST`,
  [`USER MANAGED`]: `DOOR GEBRUIKER BEHEERD`,
  [`USERNAME`]: `GEBRUIKERSNAAM`,
  [`User can delete the keystore history.`]: `De gebruiker kan de keystorehistorie wissen.`,
  [`Unable to parse API definition: {msg}`]: `Probleem met ontleden van API-definitie: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Doorgaan niet mogelijk vanwege fouten die zijn gevonden bij het verwijderen van typen en/of velden.`,
  [`Unarchive`]: `Uit archief halen`,
  [`Unassociate`]: `Ontkoppelen`,
  [`Unassociate analytics service`]: `Analyseservice ontkoppelen`,
  [`Unauthorized`]: `Niet geautoriseerd`,
  [`Unauthorized API Request`]: `Niet-geautoriseerde API-aanvraag`,
  [`Unbound lists`]: `Niet-gebonden lijsten`,
  [`Unit`]: `Eenheid`,
  [`Union`]: `Vereniging`,
  [`Union type`]: `Union-type`,
  [`Unique email address`]: `Uniek e-mailadres`,
  [`Unlimited`]: `Onbeperkt`,
  [`Unnecessary assumed size`]: `Onnodige aangenomen grootte`,
  [`Unnecessary slicing arguments`]: `Onnodige slicingargumenten`,
  [`Unrecognized Schema Element(s) Encountered`]: `Minimaal één niet-herkend schema-element aangetroffen`,
  [`Unsupported media type ({type})`]: `Niet ondersteund type medium ({type})`,
  [`Untitled`]: `Naamloos`,
  [`Update`]: `Bijwerken`,
  [`Update gateway services`]: `Gatewayservices bijwerken`,
  [`Update assembly`]: `Assemblage bijwerken`,
  [`Update group failed.`]: `Bijwerken van groep is mislukt.`,
  [`Update schema defaults giving preference to`]: `Standaardwaarden voor schema bijwerken met voorkeur voor`,
  [`Update the sender details that are displayed on invitation emails`]: `De details van de afzender bewerken die op uitnodigingsmails worden afgebeeld`,
  [`Update the summary details for this role`]: `De overzichtsgegevens voor deze rol bewerken`,
  [`Updating Draft API`]: `Concept-API wordt bijgewerkt`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `Het bijwerken van schemastandaardwaarden overschrijft uw wijzigingen. Weet u zeker dat u wilt doorgaan?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `Bijwerken van API van type {existingType} met API van type {type} is niet toegestaan.`,
  [`Upload`]: `Uploaden`,
  [`Upload a schema definition language file to replace your schema.`]: `Upload een taalbestand van een schemadefinitie ter vervanging van uw schema.`,
  [`Upload GraphQL schema`]: `GraphQL-schema uploaden`,
  [`Upload local`]: `Lokaal uploaden`,
  [`Upload policies`]: `Beleidsdefinities uploaden`,
  [`Upload policy`]: `Beleid uploaden`,
  [`Upload schema`]: `Schema uploaden`,
  [`Upload schema definition language (SDL) file`]: `SDL-bestand (schema definition language) uploaden`,
  [`Upload truststore certificates`]: `Truststore-certificaten uploaden`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Upload het X509-certificaat voor uw toepassing in PEM-indeling. U kunt slechts één PEM-bestand uploaden, maar u kunt meerdere certificaten in één bestand opnemen.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Upload het X509-certificaat voor uw toepassing in PEM-indeling`,
  [`Upload your public certificate`]: `Upload uw openbare certificaat`,
  [`Uploaded and validated YAML file`]: `YAML-bestand is geüpload en gevalideerd`,
  [`Upload extension`]: `Extensie uploaden`,
  [`Use all compatible gateway services`]: `Alle compatibele gatewayservices gebruiken`,
  [`Use Content Type`]: `Contenttype gebruiken`,
  [`Use DataPower API Gateway`]: `DataPower API Gateway gebruiken`,
  [`Use DataPower Gateway`]: `DataPower Gateway gebruiken`,
  [`Use Dynamic DNS`]: `Dynamische DNS gebruiken`,
  [`Use JSONata to specify the property to redact or remove`]: `Gebruik JSONata om op te geven welke eigenschap er moet worden afgeschermd of verwijderd`,
  [`Use XPath to specify the property to redact or remove.`]: `Met behulp van XPath opgeven welke eigenschap er moet worden afgeschermd of verwijderd.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Gebruik een geselecteerde catalogus als standaardcatalogus waarin u wilt publiceren`,
  [`Use an existing JSON or YAML definition of the product`]: `Gebruik een bestaande JSON- of YAML-definitie van het product`,
  [`Use an existing definition of a REST proxy`]: `Bestaande definitie van een REST-proxy gebruiken`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Bestaande definitie van een REST-proxy of SOAP-API gebruiken`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Gebruik een bestaande definitie van een REST-proxy, GraphQL-proxy of SOAP API`,
  [`Use an existing definition of a product`]: `Bestaande definitie van een product gebruiken`,
  [`Use context current payload`]: `Actuele payload van context gebruiken`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Aangepaste eindpunt-URL voor catalogus gebruiken (v5-compatibiliteit)`,
  [`Use dynamic DNS`]: `Dynamische DNS gebruiken`,
  [`Use endpoint from API`]: `Eindpunt gebruiken vanuit API`,
  [`Use gateway URLs`]: `Gateway-URL's gebruiken`,
  [`Use gateway service configured OAuth shared secret key`]: `Door gatewayservice geconfigureerde, gedeelde geheime OAuth-sleutel gebruiken`,
  [`Use group authentication`]: `Groepsverificatie gebruiken`,
  [`Use only first array element`]: `Alleen eerste arrayelement gebruiken`,
  [`Use static DNS`]: `Statische DNS gebruiken`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Portal als eindpunt gebruiken voor verkeer van externe OIDC-provider`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Gebruik de API Explorer om uw API's te testen en te verkennen. In Explorer worden de bewerkingen, definities en documentatie voor alle API's in uw projectdirectory afgebeeld. Explorer bevat de parameters, instancegegevens van modellen en responscodes, samen met de sjablooncode voor het uitvoeren van uw API.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Met behulp van het actiemenu kunt u uw API's beheren en publiceren.`,
  [`Use the actions menu to manage and publish your products`]: `Met behulp van het actiemenu kunt u uw producten beheren en publiceren`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Gebruik het beleid voor het activiteitenlogboek om uw aanmeldingsvoorkeuren voor de API-activiteit die is opgeslagen in Analyse te configureren. De door u opgegeven voorkeuren vervangen de standaardinstellingen voor het verzamelen en opslaan van details met betrekking tot de API-activiteit.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Gebruik het beveiligingsbeleid van de client om de legitimatiegegevens van de client te extraheren en te verifiëren.`,
  [`Use the default built-in Sandbox Catalog`]: `De standaard ingebouwde Sandbox-catalogus gebruiken`,
  [`Use the editor for manual input or upload a file below.`]: `Gebruik de editor voor handmatige invoer of upload een bestand hieronder.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Gebruik de editor voor handmatige invoer of upload een bestand hieronder. Het huidige schema kan alleen in XML worden gewijzigd als er een XML/XSD-bestand is gedefinieerd.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Gebruik de afgedwongen extensie om op te geven of de API Connect-gateway wordt gebruikt om de API af te dwingen`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Gebruik de volgende URL voor beheereindpunt wanneer u met de opdrachtregelinterface werkt.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Gebruik de volgende URL voor beheereindpunt bij het maken van een cloudverbinding in API Designer.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Gebruik het GatewayScript-beleid om een opgegeven DataPower GatewayScript-programma uit te voeren.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Gebruik het beleid graphql-introspect om een GraphQL-schema te introspecten.`,
  [`Use the host value from Open API Definition`]: `Hostwaarde uit Open API-definitie gebruiken`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Gebruik het if-beleid om een sectie van de assemblage toe te passen wanneer aan een voorwaarde is voldaan.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Gebruik het JavaScript-beleid om een opgegeven JavaScript-programma uit te voeren.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Gebruik het json-naar-xml beleid om de content van uw payload te converteren van JSON naar XML.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Gebruik het logboekbeleid om de standaardconfiguratie voor logboekregistratie van activiteiten aan te passen of te overschrijven.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Gebruik het toewijzingsbeleid om transformaties op uw assemblagestroom toe te passen en om relaties tussen variabelen op te geven.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Gebruik het toewijzingsbeleid om de relatie op te geven tussen de variabelen in een API-stroom, en om conversies op deze variabelen toe te passen.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Gebruik het oauth-beleid voor het genereren van OAuth-tokens.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Gebruik het beleid voor het wisselen van bewerking om een sectie van de assemblage toe te passen op een bepaalde bewerking.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Gebruik het ontleedbeleid om een aanvraag of bericht te ontleden als XML of JSON, of ontleed binaire gegevens in een BLOB (binary large object).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Gebruik het beleid voor het instellen van variabelen om een runtime variabele in te stellen op een reekswaarde, of om een runtime variabele toe te voegen of te wissen.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Gebruik het throwbeleid om een fout te genereren wanneer er een wordt aangetroffen tijdens de uitvoering van een assemblagestroom.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Gebruik het gebruikersbeveiligingsbeleid voor het extraheren, verifiëren en autoriseren van de identiteit van de gebruiker.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Gebruik het validatiebeleid met een DataPower Gateway om de payload in een assemblagestroom te valideren op basis van een JSON- of XML-schema.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Gebruik het xml-naar-json beleid om de content van uw payload te converteren van XML naar JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Gebruik het xslt-beleid om een XSLT-conversie toe te passen op de payload.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Wordt gebruikt voor het in-/uitschakelen van compressie en codering van content bij uploaden.`,
  [`User`]: `Gebruiker`,
  [`User group prefix`]: `Prefix gebruikersgroep`,
  [`User group suffix`]: `Suffix van gebruikersgroep`,
  [`User mapping`]: `Gebruikerstoewijzing`,
  [`User Registries`]: `Gebruikersregisters`,
  [`User registries`]: `Gebruikersregisters`,
  [`User Registry Name`]: `Naam gebruikersregister`,
  [`User registry name`]: `Naam gebruikersregister`,
  [`User Registry cannot be deleted`]: `Gebruikersregister kan niet worden gewist`,
  [`User Registry "{name}" has been deleted`]: `Gebruikersregister "{name}" is gewist`,
  [`User security`]: `Gebruikersbeveiliging`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Als het gebruik van ruimten ingeschakeld is, worden gebruikersregisters in elke ruimte beheerd. Ga naar de ruimte-instellingen om de gebruikersregisters te beheren.`,
  [`User registries defined for consumer onboarding`]: `Gebruikersregisters die gedefinieerd zijn voor onboarding van consumenten`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Gebruikersregisters die zijn gedefinieerd voor onboarding van consument \n\nAlle accounts in de Developer Portal, met inbegrip van de accounts over de verschillende gebruikersregisters voor dezelfde site, moeten een uniek e-mailadres hebben, inclusief het sitebeheerdersaccount.`,
  [`User registry`]: `Gebruikersregister`,
  [`User registry configuration has been updated.`]: `Configuratie van gebruikersregister is bijgewerkt.`,
  [`User registry has been created`]: `Gebruikersregister is gemaakt`,
  [`User registry {title} deleted.`]: `Gebruikersregister {title} gewist.`,
  [`User registry {title} has been created.`]: `Gebruikersregister {title} is gemaakt.`,
  [`User registry is not visible or not available.`]: `Gebruikersregister is niet zichtbaar of niet beschikbaar.`,
  [`User registry {title} has been updated.`]: `Gebruikersregister {title} is bijgewerkt.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `De beveiligingsinstellingen van de gebruiker zijn alleen van toepassing als de geselecteerde typen verlening een of meer van de volgende typen zijn: Impliciet, Toegangscode en Resource-eigenaar - Wachtwoord.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `De beveiligingsinstellingen van de gebruiker zijn alleen van toepassing als de geselecteerde typen verlening een of meer van de volgende typen zijn: Impliciet, Toegangscode en Resource-eigenaar - Wachtwoord. Als u op deze pagina wijzigingen aanbrengt, moet u op de pagina Configuratie ten minste één van deze typen verlening selecteren.`,
  [`UserInfo endpoint`]: `Eindpunt voor gebruikersgegevens`,
  [`UserInfo URL`]: `Gebruikersinfo-URL`,
  [`Username`]: `Gebruikersnaam`,
  [`Username / Email`]: `Gebruikersnaam / e-mailadres`,
  [`Username and password fields are required in order to test configuration`]: `Gebruikersnaam en wachtwoord zijn vereist om de configuratie te kunnen testen`,
  [`Username context variable`]: `Contextvariabele gebruikersnaam`,
  [`Username of the new user`]: `Gebruikersnaam van de nieuwe gebruiker`,
  [`Username should not contain special characters!`]: `Gebruikersnaam mag geen speciale tekens bevatten!`,
  [`Username should not include spaces!`]: `Gebruikersnaam mag geen spaties bevatten!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Gebruikers die tot een consumentorganisatie behoren, hebben toegang tot de ontwikkelaarsportal en de daarin gepubliceerde API-producten, zoals bepaald door de zichtbaarheid en de abonnementsmogelijkheden ervan.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Gebruik [IBM Cloud IAM]({link}) om de API Connect Reserved Instance-service te autoriseren voor toegang tot de Certificate Manager-service.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `HTTP POST-methode gebruiken bij het maken van contact met Userinfo-eindpunt`,
  [`Use IBM APIC token expiration setting from the cloud`]: `De instelling voor verval van IBM APIc-tokens uit de cloud gebruiken`,
  [`Update API`]: `API bijwerken`,
  [`VALUE`]: `WAARDE`,
  [`Version`]: `Versie`,
  [`Visible to`]: `Zichtbaar voor`,
  [`Validate`]: `Valideren`,
  [`Validate message body`]: `Berichttekst valideren`,
  [`Validate message headers`]: `Berichtkoppen valideren`,
  [`Validate message fault details`]: `Details van berichtfout valideren`,
  [`Validate SOAP 1.1 encoding rule`]: `SOAP 1.1-coderingsregel valideren`,
  [`Validate JSON Web Token (JWT)`]: `JWT (JSON Web Token) valideren`,
  [`Validate JWT`]: `JWT valideren`,
  [`Validate Username Token`]: `Token voor gebruikersnaam valideren`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Valideer een Web Services Security UsernameToken-payload met behulp van de verificatie-URL of het LDAP-gebruikersregister.`,
  [`Validate against`]: `Valideren ten opzichte van`,
  [`Validate request`]: `Aanvraag valideren`,
  [`Validate the GraphQL query and analyze the expected cost`]: `De GraphQL-query valideren en de verwachte kosten analyseren`,
  [`Validate the returned JSON and analyze the real cost`]: `De geretourneerde JSON valideren en de werkelijke kosten analyseren`,
  [`Validating API ...`]: `API wordt gevalideerd ...`,
  [`Validating Draft API`]: `Concept-API wordt gevalideerd`,
  [`Validity Period`]: `Geldigheidsperiode`,
  [`Value`]: `Waarde`,
  [`Value for located in must be selected.`]: `Er moet een waarde worden geselecteerd voor Aanwezig in.`,
  [`Value for the variable to be added.`]: `De waarde van de variabele die moet worden toegevoegd.`,
  [`Value for type must be selected.`]: `Er moet een waarde worden geselecteerd voor Type.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `Waarde van argument "weight" in instructie "@cost" is < 0.`,
  [`Values`]: `Waarden`,
  [`Vanity API endpoint`]: `Vanity API-eindpunt`,
  [`Variable name`]: `Variabelenaam`,
  [`Variable value`]: `Waarde van de variabele`,
  [`Vendor Extensions`]: `Leveranciersextensies`,
  [`Verb`]: `Verb`,
  [`Verify`]: `Controleren`,
  [`Verify Access Token`]: `Toegangstoken controleren`,
  [`Verify Crypto JWK variable name`]: `Naam cryptografische JWK-variabele voor controle`,
  [`Verify Crypto Object`]: `Cryptografisch object voor controle`,
  [`Verify Token`]: `Token controleren`,
  [`Verify authorization code`]: `Autorisatiecode controleren`,
  [`Verify refresh token`]: `Vernieuwingstoken controleren`,
  [`Version of the LTPA token.`]: `Versie van het LTPA-token.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Controleer de eigenschap 'apis' onder 'plannen' in het geïmporteerde productbestand. Elk plan moet eigenschap 'api's' hebben die verwijst naar ten minste één api uit de sectie 'api's'.`,
  [`View`]: `Bekijken`,
  [`View extensions`]: `Extensies bekijken`,
  [`View Forum`]: `Forum bekijken`,
  [`View owners`]: `Eigenaren bekijken`,
  [`View policies`]: `Beleidsdefinities bekijken`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Bekijk en beheer de ciphers die beschikbaar zijn voor de geselecteerde versie van het protocol.`,
  [`View analytics`]: `Analyse bekijken`,
  [`View and edit permissions for the roles in this space`]: `Machtigingen voor de rollen in deze ruimte bekijken en bewerken`,
  [`View and edit the permissions for the roles in the current organization`]: `Bekijk en bewerk de machtigingen voor de rollen in de huidige organisatie.`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Bekijk en bewerk de rollen die standaard gelden voor consumentorganisaties`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Bekijk en bewerk de rollen die standaard gelden voor providerorganisaties`,
  [`View approval history`]: `Goedkeuringshistorie bekijken`,
  [`View Approval History`]: `Goedkeuringshistorie bekijken`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Documentatie en zelfstudies bekijken met stapsgewijze instructies.`,
  [`View error ID`]: `Fout-ID bekijken`,
  [`View status`]: `Status bekijken`,
  [`View more`]: `Meer weergeven`,
  [`View source`]: `Bron afbeelden`,
  [`View subscription`]: `Abonnement bekijken`,
  [`View template in`]: `Sjabloon bekijken in`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Kijk met welke eindpunten u toegang kunt krijgen tot gebruikersinterfaces van API Connect.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Bekijk de eindpunten voor de beheer- en provider-REST-API's.`,
  [`View the endpoints for the consumer REST APIs.`]: `Bekijk de eindpunten voor de consument-REST-API's.`,
  [`Viewer`]: `Kijker`,
  [`Views the API consumer organization`]: `Kan de API-consumentorganisatie bekijken`,
  [`Views the API provider organization`]: `Kan de API-providerorganisatie bekijken`,
  [`Views the admin organization`]: `Kan de beheerorganisatie bekijken`,
  [`Views the app developer organization`]: `Kan de app-ontwikkelaarsorganisatie bekijken`,
  [`Views the catalog`]: `Kan de catalogus bekijken`,
  [`Views the space`]: `Kan de ruimte bekijken`,
  [`View|permission`]: `Bekijken`,
  [`Visibility`]: `Zichtbaarheid`,
  [`Visibility options`]: `Zichtbaarheidsopties`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `VMware-docs`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `WSDL-URL`,
  [`WSDL has been successfully validated`]: `WSDL is gevalideerd`,
  [`WSDL to REST`]: `WSDL naar REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `Validatie WS-I Basic Profile`,
  [`Warn`]: `Waarschuwen`,
  [`Warning`]: `Waarschuwing`,
  [`Warning:`]: `Waarschuwing:`,
  [`Warnings`]: `Waarschuwingen`,
  [`We just emailed recipients a test message:`]: `We hebben net een testbericht gestuurd:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `We verplaatsen dit naar de tab "Testen", waar u uw API kunt testen en debuggen.`,
  [`Weather Product`]: `Weerproduct`,
  [`Web Endpoint`]: `Webeindpunt`,
  [`Web service operations`]: `Webservicebewerkingen`,
  [`weight`]: `Gewicht`,
  [`Weight`]: `Gewicht`,
  [`Welcome to API Connect Administration`]: `Welkom bij API Connect-beheer`,
  [`Welcome to API Connect Reserved`]: `Welkom bij API Connect Reserved`,
  [`Welcome to API Designer`]: `Welkom bij API Designer`,
  [`Welcome to API Manager`]: `Welkom bij API Manager`,
  [`Welcome to Cloud Manager`]: `Welkom bij Cloud Manager`,
  [`Welcome to the API Designer`]: `Welkom bij API Designer`,
  [`Welcome to the API Manager`]: `Welkom bij API Manager`,
  [`Welcome to the Cloud Manager`]: `Welkom bij Cloud Manager`,
  [`What are you looking for today?`]: `Wat zoekt u vandaag?`,
  [`What's new`]: `Nieuw`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `Wat nu? Schakel uw factureringsintegratie in een catalogus in om gebruikers in staat te stellen plannen toe te voegen aan hun producten.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `Wanneer deze optie is ingesteld op true, injecteert het oproepbeleid de headers "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" naar de aanvraag die is verzonden naar de doel-URL.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Na publicatie wordt een API-product zichtbaar in de ontwikkelaarsportal die bij de catalogus hoort. Eindpunten voor API's in het product worden beschikbaar gesteld op gateways die bij de de doelcatalogus of -ruimte horen. Klaargezette (staged) producten zijn wel aanwezig in de catalogus, maar zijn niet live en zijn niet zichtbaar voor consumenten.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Na publicatie wordt een API-product zichtbaar in de ontwikkelaarsportal die bij de catalogus hoort. Eindpunten voor API's in het product worden beschikbaar gesteld op gateways die bij de de doelcatalogus of -ruimte horen. Klaargezette (staged) producten zijn wel aanwezig in de catalogus, maar zijn niet live en zijn niet zichtbaar voor consumenten. Let op: Als u een product opnieuw klaarzet of opnieuw publiceert in een niet-productiecatalogus, worden alle bestaande abonnementen verwijderd.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Als het gebruik van ruimten voor een catalogus ingeschakeld is, moet de geconfigureerde Oauth-provider vanuit een ruimte worden bekeken.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Als het gebruik van ruimten ingeschakeld wordt, worden de bestaande producten in de catalogus verplaatst naar een standaardruimte.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Als de gebruiker op de activeringslink klikt, krijgt hij of zij het verzoek om het registratieformulier in te vullen. Na zich geregistreerd te hebben, kan de gebruiker zich aanmelden.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Wanneer u een rol voor provider- of consumentorganisaties toevoegt, wordt deze opgenomen in de standaardwaarden voor rollen. Standaardwaarden voor rollen zijn vooraf geconfigureerde rolsjablonen die worden gebruikt om te bepalen welke standaardrollen er beschikbaar zijn wanneer er een nieuwe organisatie wordt gemaakt.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Als u een product deprecieert, kunnen de toepassingsontwikkelaars die al een abonnement op dat product hadden, het product blijven gebruiken, maar kunnen nieuwe ontwikkelaars er geen abonnement meer op nemen.\n\n [Meer informatie]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Wanneer u een rol voor provider- of consumentorganisaties wijzigt, wordt deze opgenomen in de standaardwaarden voor rollen. Standaardwaarden voor rollen zijn vooraf geconfigureerde rolsjablonen die worden gebruikt om te bepalen welke standaardrollen er beschikbaar zijn wanneer er een nieuwe organisatie wordt gemaakt.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Als u OpenID Connect inschakelt, wordt er een sjabloon voor het genereren van ID-tokens verstrekt, samen met toegangstokens, en wordt het vereiste assemblagebeleid automatisch gemaakt. Dat beleid kunt u aanpassen aan uw eisen. De voorbeeldsleutel is alleen bedoeld voor testdoeleinden en wordt gebruikt voor het ondertekenen van het JWT-token.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Als u tokenintrospectie inschakelt, kan de inhoud van toegangstokens worden onderzocht via het introspectiepad.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Als u tokenintrospectie inschakelt, kunnen de houders van toegangstokens de inhoud van het toegangstoken onderzoeken met behulp van een introspectiepad. Het toegangstoken waarop de introspectie wordt uitgevoerd, moet zijn ontvangen via de native OAuth-provider.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Als u een gebruiker uitnodigt om lid te worden van een providerorganisatie, wijst u een of meer rollen aan die gebruiker toe om aan te geven wat het toegangsniveau van die gebruiker tot de informatie is en welke taken die gebruiker kan uitvoeren.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Bij het beheren van de versies van een product leidt u die versies door een reeks stadia in de levenscyclus. Eerst wordt er een productversie klaargezet voor een catalogus, vervolgens wordt het product gepubliceerd om het beschikbaar te maken voor uw toepassingsontwikkelaars, en ten slotte wordt het product uit gebruik genomen en gearchiveerd.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Als u een product vervangt door een ander product, worden de volgende acties ondernomen: 1) Het vervangende product wordt gepubliceerd. 2) De eigenschappen voor zichtbaarheid, abonnees en gatewayhandhaving van het oorspronkelijke product worden gebruikt in het vervangende product. 3) De abonnees van het oorspronkelijke product worden gemigreerd naar het vervangende product.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `Als u een product vervangt door een ander product, worden de volgende acties uitgevoerd: 1) het vervangende product neemt de status van het originele product aan, 2) als de instellingen voor zichtbaarheid en abonnementsmogelijkheden in het vervangende product zodanig zijn dat de toegang hetzelfde is als, of minder beperkend is dan, het originele product, worden de instellingen in het vervangende product gebruikt. Als de instellingen van het vervangende product beperkter zijn en zouden verhinderen dat sommige consumentenorganisaties met toegang tot het originele product toegang krijgen tot het vervangende product, kan de vervangingsbewerking niet worden voltooid. de abonnees van het oorspronkelijke product worden gemigreerd naar het vervangende product, en (4) het oorspronkelijke product wordt buiten gebruik gesteld.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Als een product uit gebruik wordt genomen, worden alle bijbehorende API's offline gehaald en worden alle abonnementen inactief.\n\n [Meer informatie]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Als u een product verdringt door een ander product, worden de volgende acties genomen: 1) Het verdringende product wordt gepubliceerd. 2) De instellingen voor zichtbaarheid, abonnees en gatewayhandhaving van het oorspronkelijke product worden gebruikt voor het verdringende product. 3) Het oorspronkelijke product krijgt de status Gedeprecieerd. Als een product wordt gedeprecieerd, kunnen de toepassingsontwikkelaars die al een abonnement op het product hadden, het product blijven gebruiken, maar kunnen nieuwe ontwikkelaars er geen abonnement meer op nemen. Indien nodig kan een gedeprecieerd product opnieuw worden gepubliceerd.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Wanneer u een product verdringt door een ander product, worden de volgende acties uitgevoerd: 1) Het verdringende product wordt gepubliceerd. 2) De eigenschappen voor zichtbaarheid en abonnementsmogelijkheden van het oorspronkelijke product worden gebruikt in het verdringende product. 3) Het oorspronkelijke product krijgt de status Gedeprecieerd.  Als een product wordt gedeprecieerd, kunnen de toepassingsontwikkelaars die al een abonnement op het product hadden, het product blijven gebruiken, maar kunnen nieuwe ontwikkelaars er geen abonnement meer op nemen.  Indien nodig kan een gedeprecieerd product opnieuw worden gepubliceerd.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Als u een toepassing onderbreekt, worden het maken van API-aanroepen door de bijbehorende client-ID's geblokkeerd. U kunt de toepassing op elk gewenst moment weer hervatten.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Als deze optie is ingeschakeld, wordt alle timeouts voor uitnodigingen voor consumentenorganisaties ingesteld op de timeout van de catalogusuitnodiging en worden de timeouts voor de uitnodiging van de consumentenorganisaties bij elke update van de timeout voor de catalogusuitnodiging bijgewerkt.`,
  [`Where did the roles go?`]: `Waar zijn de rollen gebleven?`,
  [`Where to find the named rate limit.`]: `Waar de benoemde aanroeplimiet te vinden is.`,
  [`Where to put the decrypted result`]: `De plaats waar het gedecodeerde resultaat moet worden bewaard`,
  [`Where to put the encrypted result`]: `De plaats waar het versleutelde resultaat moet worden bewaard`,
  [`Whether to reuse the connection to the target server.`]: `Of de verbinding met de doelserver moet worden hergebruikt.`,
  [`Windows live`]: `Windows live`,
  [`With`]: `Door`,
  [`Wildcards ignore xsi:type rule`]: `Jokertekens negeren regel xsi:type`,
  [`Work offline`]: `Offline werken`,
  [`Work with existing definitions, models and/or data sources`]: `Werken met bestaande definities, modellen en/of gegevensbronnen`,
  [`Working...`]: `Bezig...`,
  [`Wrapper Policies`]: `Wrapperbeleid`,
  [`Wrong slicing arguments`]: `Onjuiste slicingargumenten`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `WSDL en XSDs moeten in het eigen ZIP-bestand staan in het ZIP-bestand dat u probeert te importeren.`,
  [`XML Schema`]: `XML-schema`,
  [`XML Schema URL`]: `URL van XML-schema`,
  [`XML Schema preview`]: `Preview van XML-schema`,
  [`XML Validation Mode`]: `XML-validatiemodus`,
  [`XML schema is not valid. Please correct the error.`]: `XML-schema is ongeldig. Corrigeer de fout.`,
  [`XML to JSON`]: `XML naar JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `XSLT-versie`,
  [`YAML has been successfully validated`]: `YAML is gevalideerd`,
  [`Yes`]: `Ja`,
  [`Yes, enable it`]: `Ja, inschakelen`,
  [`Yes, reset`]: `Ja, resetten`,
  [`Yes. Update API Assembly`]: `Ja. API-assemblage bijwerken`,
  [`You are authenticated!`]: `U bent geverifieerd!`,
  [`You do not have permission to use the Test Application`]: `U bent niet gemachtigd om de testtoepassing te gebruiken`,
  [`You can also copy the link and send it directly to the user.`]: `Het is ook mogelijk om de link te kopiëren en deze rechtstreeks naar de gebruiker te sturen.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `U kunt ervoor kiezen om de API nog niet meteen te publiceren, of u kunt de API wél onmiddellijk publiceren, op uw testeindpunten, uw productie-eindpunten of beide. Desgewenst kunt u ook een maximum aantal toegestane API-aanroepen aangeven, op basis van een bepaald aantal aanroepen gedurende een bepaald tijdsinterval.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `U kunt ervoor kiezen om de API nog niet meteen te publiceren, of u kunt de API wél onmiddellijk publiceren, op uw testeindpunten, uw productie-eindpunten of beide. Desgewenst kunt u ook een maximum aantal toegestane API-aanroepen aangeven, op basis van een bepaald aantal aanroepen per opgegeven tijdsinterval.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `U kunt ervoor kiezen om de API vooralsnog onbeveiligd te laten, of u kunt aanroepende toepassingen verplichten om een client-ID aan te leveren. Nadat u de API-definitie hebt gemaakt, kunt u nog meer beveiligingsopties configureren.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `U kunt een catalogusnaam of titel invoeren. De catalogusnaam heeft voorrang als beide beschikbaar zijn.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `U kunt een e-mail verzenden naar de eigenaar van een consumentorganisatie. Het bericht wordt verzonden met behulp van de e-mailserver die geconfigureerd is in Meldingen.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `U kunt een e-mail verzenden naar de eigenaar van een consumentorganisatie. Het bericht wordt verzonden met behulp van de e-mailserver die geconfigureerd is in de sectie Meldingen.`,
  [`You can send an email to a provider organization owner.`]: `U kunt een e-mail verzenden naar de eigenaar van een providerorganisatie.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `U kunt de eigendom overdragen aan een nieuwe gebruiker. Dit doet u door het e-mailadres van die gebruiker op te geven. De gebruiker ontvangt een e-mail met een activeringslink om zich te registreren. Na zich geregistreerd te hebben, wordt de gebruiker de nieuwe eigenaar. Wijs nieuwe rollen toe aan de huidige gebruiker, zodat deze kan blijven doorwerken in Cloud Manager.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `U kunt de eigendom overdragen aan een geregistreerde gebruiker. Dit doet u door het e-mailadres van die gebruiker op te geven. Wijs nieuwe rollen toe aan de huidige gebruiker, zodat deze kan blijven doorwerken in Cloud Manager.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `U kunt een OpenAPI-definitiebestand gebruiken om een REST-API in IBM API Connect toe te voegen. Het bestand moet de indeling JSON of YAML hebben.  Nadat de API-definitie is geïmporteerd, wordt deze afgebeeld in de lijst van API-definities.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `U kunt dit type niet wissen omdat daarmee het roottype ('Query ') wordt verwijderd.`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `U kunt geen API selecteren waarvan het gatewaytype niet overeenkomt met het gatewaytype van dit product.`,
  [`You currently don’t have any gateway extensions`]: `U hebt momenteel geen gateway-extensies`,
  [`You currently don’t have any plans to migrate`]: `U hebt momenteel geen plannen om te migreren`,
  [`You currently don't have any policy.`]: `U hebt momenteel geen beleid.`,
  [`You do not have permission to any spaces.`]: `U bent voor geen enkele ruimte gemachtigd.`,
  [`You do not have permission to publish products to any catalog or space.`]: `U bent niet gemachtigd om producten te publiceren in een catalogus of ruimte.`,
  [`You do not have permission to stage products to any catalog or space.`]: `U bent niet gemachtigd om producten klaar te zetten in een catalogus of ruimte.`,
  [`You don’t currently have any billing integrations.`]: `U hebt momenteel geen factureringsintegraties.`,
  [`You don't currently have any response.`]: `Je hebt momenteel geen antwoord.`,
  [`You don't currently have any user-defined policies.`]: `U hebt momenteel geen door de gebruiker gedefinieerde beleidsdefinities.`,
  [`You don't have permission to access this page.`]: `U hebt geen toestemming om deze pagina te openen.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `U hebt dit certificaat gewijzigd. Als u deze toepassing opslaat, wordt de configuratie bijgewerkt.`,
  [`You haven't added any APIs or Products`]: `U hebt geen API's of producten toegevoegd`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Bij het beheren van het lidmaatschap van een catalogus gaat het om het toevoegen van nieuwe gebruikers aan die catalogus en het toewijzen van rollen aan de gebruikers.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `Bij het beheren van het lidmaatschap van een ruimte gaat het om het toevoegen van nieuwe gebruikers aan die ruimte en het toewijzen van rollen aan de gebruikers.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Bij het beheren van het lidmaatschap van een ruimte gaat het om het toevoegen van nieuwe gebruikers aan die ruimte en het toewijzen van rollen aan de gebruikers.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `U moet uw API toevoegen aan een product dat gereed is voor publicatie. Een product brengt een set API's en plannen samen in één aanbieding die u beschikbaar kunt stellen aan uw ontwikkelaars. Een plan bevat instellingen voor de aanroeplimiet. Deze kunnen gelden voor het plan als geheel, of ze kunnen apart worden opgegeven voor elke bewerking in een API.`,
  [`You must choose a definition`]: `U moet een definitie kiezen`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `U moet ten minste één gatewayservice inschakelen, zodat API's die naar deze catalogus zijn gepubliceerd, beschikbaar zijn om te worden aangeroepen op een service-eindpunt van een gateway. U kunt meerdere gatewayservices inschakelen.`,
  [`You must enable spaces`]: `U moet het gebruik van ruimten inschakelen`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Hieronder moet u het client-ID en -geheim opgeven, zodat deze gegevens bij het ontwikkelen van uw toepassing kunnen worden gebruikt. Het clientgeheim kan nooit meer worden afgebeeld.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Met uw licentie voor API Connect Reserved Instance kunt u een of meer DataPower API Gateways op afstand downloaden en instellen. De gateways kunnen lokaal zijn of zich in een cloud bevinden.`,
  [`Your API has been created`]: `Uw API is gemaakt`,
  [`Your API is live!`]: `Uw API is live!`,
  [`Your API is online!`]: `Uw API is online!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Uw catalogus wordt gewist. Dit kan even duren...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Uw consumentorganisatie wordt gemaakt. Dit kan even duren...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Uw uitnodiging is niet meer geldig. Controleer de timeout van uw uitnodiging en verzend de uitnodiging opnieuw op.`,
  [`Your Provider organization is being created. This may take a while...`]: `Uw providerorganisatie wordt gemaakt. Dit kan even duren...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Uw providerorganisatie wordt gewist. Dit kan even duren...`,
  [`Your member is being deleted. This may take a while...`]: `Uw lid wordt gewist. Dit kan even duren...`,
  [`Your Space is being deleted. This may take a while...`]: `Uw ruimte wordt gewist. Dit kan even duren...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `De OAuth-provider heeft niet-opgeslagen wijzigingen. Klik op OK om door te gaan zonder opslaan.`,
  [`Your credentials have expired.`]: `Uw legitimatiegegevens zijn vervallen.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Uw uitnodiging is verstuurd en de volgende uitnodigingslink is meegestuurd:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `Uw aanvraag wordt nog steeds verwerkt. Dit duurt langer dan verwacht. Vernieuw het scherm over een paar minuten, voordat u de aanvraag opnieuw probeert.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Uw product wordt opgeslagen voordat u verder gaat, weet u het zeker?`,
  [`Your trial ends on {date}`]: `Uw proefperiode eindigt op {date}`,
  [`Your trial expired on {date}.`]: `Uw proefperiode is verlopen op {date}.`,
  [`Zoom in`]: `Inzoomen`,
  [`Zoom in and out`]: `In- en uitzoomen`,
  [`Zoom out`]: `Uitzoomen`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `het ontbreken van ondersteuning van CORS op de doelserver (zie onderstaande suggestie)`,
  [`a LDAP provider`]: `een LDAP-provider`,
  [`activity`]: `activiteit`,
  [`add item...`]: `item toevoegen...`,
  [`add property...`]: `eigenschap toevoegen...`,
  [`added`]: `toegevoegd`,
  [`admin`]: `beheerder`,
  [`administrator`]: `beheerder`,
  [`all`]: `alle`,
  [`an API Connect Local User Registry`]: `een lokaal gebruikersregister van API Connect`,
  [`an authentication URL`]: `een verificatie-URL`,
  [`an untrusted certificate has been encountered`]: `er is een niet-vertrouwd certificaat aangetroffen`,
  [`and`]: `en`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Toepassing:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `goedkeuring`,
  [`approval task for product`]: `goedkeuringstaak voor product`,
  [`archived`]: `gearchiveerd`,
  [`authenticated`]: `geverifieerd`,
  [`authorization code time to live (seconds)`]: `levensduur van autorisatiecode (seconden)`,
  [`Authorization header pass through`]: `Autorisatieheader doorgeven`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `blokkerend`,
  [`body`]: `lopende tekst`,
  [`body-param`]: `body-param`,
  [`cancel`]: `annuleren`,
  [`case`]: `voorval`,
  [`catalog`]: `Catalogus`,
  [`catalog:`]: `Catalogus:`,
  [`catch`]: `vangst`,
  [`changed`]: `gewijzigd`,
  [`cipher`]: `cipher`,
  [`code id_token`]: `code id_token`,
  [`code id_token token`]: `code id_token token`,
  [`code token`]: `code token`,
  [`Consume`]: `Consumeren`,
  [`consumer`]: `consument`,
  [`consumes`]: `consumeert`,
  [`composite`]: `samengesteld`,
  [`created`]: `gemaakt`,
  [`Created API`]: `API is gemaakt`,
  [`custom string`]: `aangepaste tekenreeks`,
  [`day`]: `dag`,
  [`day(s)`]: `dag(en)`,
  [`default`]: `standaard`,
  [`default value`]: `standaardwaarde`,
  [`definition`]: `definitie`,
  [`deprecated`]: `gedeprecieerd`,
  [`description`]: `beschrijving`,
  [`development`]: `ontwikkeling`,
  [`edit condition`]: `voorwaarde bewerken`,
  [`edited`]: `bewerkt`,
  [`environment`]: `Catalogus`,
  [`error`]: `fout`,
  [`false`]: `false`,
  [`file`]: `bestand`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `pictogrammen met font-awesome 4.5.0`,
  [`for`]: `voor`,
  [`header`]: `header`,
  [`hear`]: `luisteren`,
  [`hello`]: `Hallo`,
  [`helloName`]: `Hallo {name}!`,
  [`here`]: `hier`,
  [`hit`]: `treffer`,
  [`hits`]: `treffers`,
  [`hour`]: `uur`,
  [`hour(s)`]: `uur/uren`,
  [`hours`]: `uur`,
  [`identified`]: `geïdentificeerd`,
  [`Increased`]: `Verhoogd`,
  [`Increment`]: `Verhogen`,
  [`info`]: `info`,
  [`information about external documentation`]: `informatie over externe documentatie`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `In plaats van de exp-claim van de OIDC-provider na te komen, zal het access_token/refresh_token gegenereerd door APIc de APIc-instelling nakomen`,
  [`introspect URL`]: `introspectie-URL`,
  [`Issue`]: `Probleem`,
  [`Issues`]: `Problemen`,
  [`item`]: `item`,
  [`iterate over`]: `itereren over`,
  [`last modified`]: `laatst gewijzigd`,
  [`Log Level`]: `Logniveau`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `logboeken`,
  [`Logout`]: `Afmelden`,
  [`Logout redirect`]: `Omleiding voor afmelden`,
  [`Logout redirect URL`]: `Omleidings-URL voor afmelden`,
  [`location`]: `location`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `minuut`,
  [`minute(s)`]: `minu(u)t(en)`,
  [`minutes`]: `minuten`,
  [`mutation type`]: `mutatietype`,
  [`mutual SSL authentication is required`]: `wederzijdse SSL-verificatie is vereist`,
  [`name`]: `Naam`,
  [`Name|extension`]: `Naam`,
  [`Name|path parameters`]: `Naam`,
  [`Name|security definitions`]: `Naam`,
  [`non-blocking`]: `niet-blokkerend`,
  [`none`]: `geen`,
  [`none selected`]: `geen geselecteerd`,
  [`Object`]: `Object`,
  [`off`]: `uit`,
  [`of {total} pages`]: `van {total} pagina's`,
  [`of {total} page`]: `van {total} pagina`,
  [`offline`]: `offline`,
  [`on`]: `op`,
  [`online`]: `online`,
  [`or`]: `of`,
  [`or redact from all`]: `of afschermen uit alles`,
  [`open and close list of options`]: `lijst van opties openen en sluiten`,
  [`organization saved`]: `organisatie is opgeslagen`,
  [`organization-manager`]: `organisatiemanager`,
  [`otherwise`]: `anders`,
  [`payload`]: `payload`,
  [`Payload`]: `Payload`,
  [`pending`]: `in behandeling`,
  [`produces`]: `produceert`,
  [`production`]: `productie`,
  [`production and development`]: `productie en ontwikkeling`,
  [`property`]: `eigenschap`,
  [`provider`]: `provider`,
  [`publish product`]: `product publiceren`,
  [`published`]: `gepubliceerd`,
  [`query type`]: `querytype`,
  [`query-parameters`]: `query-parameters`,
  [`realized`]: `gerealiseerd`,
  [`recipients`]: `ontvangers`,
  [`removed`]: `verwijderd`,
  [`replaced`]: `vervangen`,
  [`repeatable`]: `repeatable`,
  [`Replenish`]: `Aanvullen`,
  [`request`]: `aanvraag`,
  [`requested by`]: `aangevraagd door`,
  [`Resolver weight`]: `Resolver-gewicht`,
  [`response`]: `respons`,
  [`response-param`]: `response-param`,
  [`retired`]: `uit gebruik`,
  [`sample_scope_1`]: `voorbeeldbereik_1`,
  [`saved`]: `opgeslagen`,
  [`scalar`]: `scalair`,
  [`second`]: `seconde`,
  [`second(s)`]: `seconde(n)`,
  [`seconds`]: `seconden`,
  [`select owner user`]: `gebruiker eigenaar selecteren`,
  [`select user`]: `gebruiker selecteren`,
  [`soap-body`]: `soap-body`,
  [`source`]: `bron`,
  [`space`]: `ruimte`,
  [`Space settings`]: `Instellingen ruimte`,
  [`Skip`]: `Overslaan`,
  [`specified`]: `opgegeven`,
  [`staged`]: `klaargezet`,
  [`start generating tests automatically.`]: `automatisch genereren van tests te starten.`,
  [`subject`]: `onderwerp`,
  [`subscription type`]: `abonnementstype`,
  [`supersede`]: `substitueren`,
  [`superseded`]: `verdrongen`,
  [`target`]: `doel`,
  [`the server is unavailable`]: `de server is niet beschikbaar`,
  [`this user registry type`]: `dit type gebruikersregister`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `Dit is een tijdelijke publicatiepagina waar abonnementen bij het uitvoeren van opnieuw publiceren behouden blijven.`,
  [`to production`]: `naar productie`,
  [`topology-administrator`]: `topologiebeheerder`,
  [`Trial days left`]: `Proefdagen links`,
  [`true`]: `true`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `TTL is gewijzigd`,
  [`type`]: `type`,
  [`unarchived`]: `uit archief gehaald`,
  [`union type`]: `union-type`,
  [`unlimited`]: `onbeperkt`,
  [`updated`]: `bijgewerkt`,
  [`Updated from new schema`]: `Bijgewerkt vanuit nieuw schema`,
  [`Upgrade`]: `Upgraden`,
  [`v5 legacy behavior`]: `V5 legacygedrag`,
  [`version 2`]: `versie 2`,
  [`version 3`]: `versie 3`,
  [`viewer`]: `kijker`,
  [`value`]: `waarde`,
  [`warn`]: `waarschuwing`,
  [`warning`]: `waarschuwing`,
  [`warnings`]: `waarschuwingen`,
  [`was not found. Please select a configured OAuth provider.`]: `is niet gevonden. Selecteer een geconfigureerde OAuth-provider.`,
  [`week`]: `week`,
  [`with`]: `door`,
  [`with|supersede with`]: `door`,
  [`wsdl`]: `WSDL`,
  [`xsd`]: `XSD`,
  [`status in sandbox`]: `status in sandbox`,
  [`your file here, or`]: `uw bestand hiernaartoe of`,
  [`{count} found!`]: `{count} gevonden!`,
  [`{current} of {total} page`]: `{current} van de pagina {total}`,
  [`{current} of {total} pages`]: `{current} van {total} pagina's`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} dag(en), {hours} uur en {minutes} minuut/minuten`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} dag(en) en {hours} uur`,
  [`{docType} is required`]: `{docType} is verplicht`,
  [`{docType} title`]: `{docType} titel`,
  [`Product title`]: `Producttitel`,
  [`Application title`]: `Titel van toepassing`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} uur en {minutes} minuut/minuten`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleGatewayCount} Gateway(s) verborgen wegens incompatibiliteit met het in de API of het product opgegeven gatewaytype`,
  [`{min}-{max} of {total} items`]: `{min}-{max} van {total} items`,
  [`{min}-{max} of {total} item`]: `{min}-{max} van {total}-item`,
  [`{min}-{max} of {total} Policies`]: `{min}-{max} van {total} beleidsdefinities`,
  [`{min}-{max} of {total} Policy`]: `{min}-{max} of {total}-beleid`,
  [`{min}-{max} of {total} types`]: `{min}-{max} van {total} typen`,
  [`{min}-{max} of {total} type`]: `{min}-{max} van {total}-type`,
  [`{min}-{max} of {total} search results`]: `{min}-{max} van {total} zoekresultaten`,
  [`{min}-{max} of {total} search result`]: `{min}-{max} of {total}-zoekresultaat`,
  [`{min}-{max} of {total} Catalogs`]: `{min}-{max} van {total} catalogi`,
  [`{min}-{max} of {total} Catalog`]: `{min}-{max} van {total}-catalogus`,
  [`{min}-{max} of {total} Spaces`]: `{min}-{max} van {total} ruimten`,
  [`{min}-{max} of {total} Space`]: `{min}-{max} van {total}-ruimte`,
  [`{min}-{max} of {total} Extensions`]: `{min}-{max} van {total} extensies`,
  [`{min}-{max} of {total} Extension`]: `{min}-{max} van {total}-extensie`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} van {total} productplannen`,
  [`{min}-{max} of {total} Product Plan`]: `{min}-{max} van {total}-productplan`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} van {total} gateways`,
  [`{min}-{max} of {total} Gateway`]: `{min}-{max} van {total}-gateway`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `In {name} (API) ontbreekt 'x-ibm-configuration'`,
  [`{name} (Product) has been published!`]: `{name} (Product) is gepubliceerd!`,
  [`{name} (Product) has been staged!`]: `{name} (Product) is klaargezet!`,
  [`{name} (Product) has not been published!`]: `{name} (Product) is niet gepubliceerd!`,
  [`{name} (Role) has been created`]: `{name} (Rol) is gemaakt.`,
  [`{name} successfully deleted`]: `{name} is gewist.`,
  [`{name} (Role) has been updated`]: `{name} (Rol) is bijgewerkt.`,
  [`{name} has been assigned the owner`]: `{name} is aangewezen als eigenaar`,
  [`{number} Error`]: `{number} Fout`,
  [`{number} Errors`]: `{number} Fouten`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `Goedkeuring voor {operation} van product {prodname}:{version} aangevraagd door {username}`,
  [`{path} does not exist`]: `{path} bestaat niet`,
  [`{productName} has no migration target set.`]: `{productName} heeft geen ingesteld migratiedoel.`,
  [`{state} Pending`]: `{state} in behandeling`,
  [`{status} API error`]: `{status} API-fout`,
  [`{status} Bad Request`]: `{status} Foutieve opdracht`,
  [`{status} Forbidden`]: `{status} Niet toegestaan`,
  [`{status} Gateway Error`]: `{status} Gatewayfout`,
  [`{status} Unauthorized`]: `{status} Niet geautoriseerd`,
  [`({time} after being sent)`]: `({time} na verzending)`,
  [`({time} after being queued)`]: `({time} na plaatsing in de wachtrij)`,
  [`{title} (Product) has been {state}.`]: `{title} (Product) is {state}.`,
  [`{title} has been deleted`]: `{title} is gewist`,
  [`{title} has been {changedName}.`]: `{title} is {changedName}.`,
  [`{title} has not been deleted!`]: `{title} is niet gewist`,
  [`{title} has not been {changedName}!`]: `{title} is niet {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `{type} is {changedName}.`,
  [`{type} has not been {changedName}!`]: `{type} is niet {changedName}!`,
  [`{type} history log`]: `{type} - historielogboek`,
  [`{username} does not exist`]: `{username} bestaat niet`,
  [`{username} does not exist in the user registry`]: `{username} is niet aanwezig in het gebruikersregister`,
  [`{username} is not associated with this consumer org`]: `{username} is niet gekoppeld aan deze consumentorganisatie`,
  [`{username} has been created as a {type}.`]: `{username} is gemaakt als een {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `App {appName} is hersteld in de ontwikkelaarsportal {consumerOrg}`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `App {appName} is onderbroken in de ontwikkelaarsportal {consumerOrg}`,
  [`{{catalogTitle}} developer portal account registration`]: `Accountregistratie voor ontwikkelaarsportal {catalogTitle}`,
  [`{{count}} errors need attention`]: `Aandacht vereist voor {count} fouten`,
  // lts only translations
  [`Configure JSON settings`]: `JSON-instellingen configureren`,
  [`Configure XML settings`]: `XML-instellingen configureren`,
  [`Configure options`]: `Opties configureren`,
  [`Detect`]: `Detecteren`,
  [`Document type`]: `Documenttype`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Maximale documentgrootte`,
  [`Maximum name length`]: `Maximale naamlengte`,
  [`Maximum nesting depth`]: `Maximale nestdiepte`,
  [`Maximum number length`]: `Maximale getallengte`,
  [`Maximum number of unique names`]: `Maximumaantal unieke namen`,
  [`Maximum number of unique namespaces`]: `Maximumaantal unieke naamruimten`,
  [`Maximum number of unique prefixes`]: `Maximumaantal unieke prefixen`,
  [`Maximum value length`]: `Maximale waardelengte`,
  [`Maximum width`]: `Maximumbreedte`,
  [`Maximum stack size`]: `Maximale stackgrootte`,
  [`Parse settings`]: `Ontledingsinstellingen`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `De maximale documentgrootte in bytes die door de ontledingsactie wordt geaccepteerd.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `De maximale diepte van geneste elementen in een XML- of JSON-bericht die door de ontledingsactie wordt geaccepteerd.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Geeft het maximale niveau van geneste elementdiepte in een XML-, JSON- of GraphQL-bericht aan die door de ontleedactie wordt geaccepteerd.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Geeft de maximale lengte van de naam in bytes aan die door de ontledingsactie wordt geaccepteerd.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Geeft aan dat de maximumlengte van het getal het aantal bytes in het waardegedeelte van een label-waardepaar beperkt als de waarde een getal is.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Geeft het maximumaantal unieke URI's van XML-naamruimten aan dat door de ontledingsactie wordt geaccepteerd. Deze limiet telt alle XML-naamruimten, ongeacht hoeveel prefixen worden gebruikt om deze te declareren.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Geeft het maximumaantal unieke XML-naamruimteprefixen in een document aan dat door de ontledingsactie wordt geaccepteerd. Voor deze limiet tellen meerdere prefixen die zijn gedefinieerd voor dezelfde naamruimte, maar niet meerdere naamruimten die zijn gedefinieerd in verschillende delen van het invoerdocument onder één prefix.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Het maximumaantal unieke namen dat door de ontledingsactie wordt geaccepteerd.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Geef de maximumlengte van de waarde in bytes op die door de ontledingsactie wordt geaccepteerd.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Geeft de maximumbreedte aan van een payload die door de ontledingsactie wordt geaccepteerd.`,
  [`Specifies the type of document to parse.`]: `Geeft het type document aan dat moet worden ontleed.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Geeft de XSLT-processorversie aan. De standaardwaarde is XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Geeft aan of strikte XSLT-foutcontrole moet worden ingeschakeld. Bij niet-strikte bewerkingen wordt geprobeerd herstel van bepaalde fouten uit te voeren, zoals het gebruik van niet-gedeclareerde variabelen, het aanroepen van niet-gedeclareerde sjablonen, enzovoort. Standaard is strikte XSLT-foutcontrole ingeschakeld.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Geeft aan of stijlbladprofilering moet worden ingeschakeld. Deze optie mag niet worden gebruikt in productieomgevingen. Standaard is stijlbladprofilering uitgeschakeld.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Geeft aan of het stijlblad, het XQuery-script en het JSONiq-script moeten worden uitgevoerd in foutoplossingsmodus. Als een stijlblad, XQuery-script of JSONiq-script wordt uitgevoerd in de foutoplossingsmodus, wordt er een aangepaste webpagina gegenereerd in plaats van het weergeven van de normale uitvoer. De webpagina geeft details over wat er precies tijdens de uitvoering is gebeurd, met inbegrip van de waarden van variabelen en waar bepaalde stukken van de uitvoer vandaan kwam. Deze optie mag niet worden gebruikt in productieomgevingen. Standaard is de foutoplossingsmodus uitgeschakeld.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Geeft aan of het stijlblad moet worden uitgevoerd in de streamingmodus. De transformatie van het document begint voordat de invoer volledig is ontleed. Niet alle stijlbladen kunnen worden gestreamd. Als het stijlblad niet kan worden gestreamd, wordt er een fout gegenereerd en wordt de invoer niet verwerkt. Standaard is de streamingmodus uitgeschakeld.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Geeft aan of het stijlblad moet worden uitgevoerd in de streamingmodus. De transformatie van het document begint voordat de invoer volledig is ontleed. Niet alle stijlbladen kunnen worden gestreamd. Als het stijlblad niet kan worden gestreamd, wordt er een waarschuwing gegenereerd tijdens de compilatie en wordt het stijlblad in de volledige invoer op normale wijze gelezen tijdens de uitvoeringstijd. Standaard wordt het uitvoeren van het stijlblad in de streamingmodus uitgeschakeld.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Geeft aan of de uitvoer van het stijlblad tijdens de verwerking ongedaan gemaakt moet worden. Minimale escaping is vooral handig bij het afhandelen van niet-Engelse tekensets. Standaard is minimale escaping uitgeschakeld.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Geeft het maximumaantal bytes aan dat de stack mag gebruiken tijdens het uitvoeren van een stijlblad of andere gecompileerde content. Deze instelling wordt gebruikt om oneindige recursie te blokkeren. De minimumwaarde is 10 kilobytes of 10.240 bytes. De maximumwaarde is 100 megabytes, of 104.857.600 bytes. De standaardwaarde is 1 megabyte of 1.048.576 bytes.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Geeft het validatiegedrag aan dat moet worden toegepast op WSDL-bestanden die zijn gecontroleerd op conformiteit met sectie 5 van WS-I Basic Profile (versie 1.0, april 2004). De standaardinstelling is warschuwen.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Geeft het validatiegedrag aan voor de soap:Body. De standaardinstelling is Strict.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Geeft het validatiegedrag aan voor de soap:Header. De standaardinstelling is Lax.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Geeft het validatiegedrag aan voor de foutgegevens. De standaardinstelling is Strict.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Geeft aan of compatibiliteit met RPC-stijlwrappers vereist is. Standaard zijn RPC-stijlwrappers niet vereist.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Geeft aan of het schema het meeste gebruik van elementen met xsi:type= 'SOAP-ENC:Array' in overeenstemming met SOAP 1.1 Sectie 5 accepteert, zelfs als deze kenmerken de XML-schemaspecificatie schenden. Normaal gesproken moet het kenmerk xsi:type een type naam noemen dat gelijk is aan of afgeleid is van het feitelijke type van het element. Voor schema's die met deze optie zijn gecompileerd, wordt xsi:type specifiek geaccepteerd voor het complexe type SOAP 1.1 Encoding 'Array' als het elementtype is afgeleid van SOAP-ENC:Array. Het tegenovergestelde is meestal toegestaan. Standaard worden elementen met xsi:type= 'SOAP-ENC:Array' niet geaccepteerd.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Geeft aan of er extra schemavalidatie moet worden uitgevoerd volgens de codeerregels in SOAP 1.1, sectie 5. Als deze optie is ingeschakeld, worden leden van SOAP-arrays gevalideerd, zijn kenmerken zoals @id en @href toegestaan, zelfs als ze niet zijn toegestaan door het schema. @href-waarden worden gecontroleerd om ervoor te zorgen dat ze een overeenkomend @id-element hebben. Standaard wordt de extra validatie niet uitgevoerd.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Geeft aan of xs:any-elementen in het schema onderliggende elementen alleen valideren op naam. De XML-schemaspecificatie vereist dat als een jokerteken overeenkomt met een element, maar dat element geen elementdeclaratie heeft, het element wordt gevalideerd volgens een xsi:type-kenmerk erop. Met deze optie negeert u de kenmerken xsi:type. Dit moet worden gebruikt voor bijvoorbeeld SOAP-envelopvalidatie, waarbij een verdere validatiestap de met het jokerteken overeenkomende content valideert, mogelijk met behulp van de regels van de SOAP 1.1-codering. Standaard worden xsi:type-kenmerken niet genegeerd.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Geeft aan of de SOAP-binding strikt moet worden gevolgd in de WSDL. Als dit ingeschakeld is, worden alleen berichten die zijn gekoppeld aan SOAP 1.2 opgenomen in SOAP 1.1-enveloppen en worden alleen berichten die zijn gekoppeld aan SOAP 1.1 opgenomen in SOAP 1.1-enveloppen. Standaard is strikte SOAP-binding uitgeschakeld.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Geeft aan of XACML-beleid moet worden gecompileerd met foutopsporingsinformatie. Houd er rekening mee dat de XACML-foutopsporingsberichten ook worden gecontroleerd door de logboekevent in de XACML-categorie. Gebruik het foutopsporingslogboekniveau om de volledige XACML-foutopsporingsberichten te bekijken. Standaard worden XACML-beleidsdefinities niet gecompileerd met foutopsporingsinformatie.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Geeft aan of het schema of WSDL-document berichten accepteert waarin base64-gecodeerde binaire content is geoptimaliseerd volgens de MTOM/XOP-specificaties. XOP binary-optimization vervangt base64-gecodeerde binaire gegevens door een xop:Include-verwijzingselement dat verwijst naar de niet-gecodeerde binaire gegevens die zich in een bijlage bevinden. Standaard zijn voor MTOM/XOP geoptimaliseerde berichten uitgeschakeld.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Een consumentorganisatiegroep is een verzameling consumentorganisaties en biedt een efficiënte manier om te bepalen wie de API's in uw producten kan zien en wie zich erop kan abonneren. Met behulp van een consumentorganisatiegroep kunt u deze toegang voor alle ontwikkelaars in de organisaties van die groep in een enkele bewerking definiëren, in plaats van dat u de toegang voor de organisaties afzonderlijk moet definiëren.`,
  [`API Connect Home`]: `API Connect Home`,
  [`Add new group`]: `Nieuwe groep toevoegen`,
  [`Add consumer organizations to the group as required`]: `Voeg zo nodig consumentorganisaties aan de groep toe`,
  [`Add the following configuration`]: `Voeg de volgende configuratie toe`,
  [`Add the following configuration to`]: `Voeg de volgende configuratie toe aan`,
  [`Additions`]: `Toevoegingen`,
  [`Additional GraphQL capabilities`]: `Aanvullende mogelijkheden van GraphQL`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Aanvullende instellingen voor API's van graphQL die in dit plan zijn opgenomen`,
  [`An organization or a group is required.`]: `Een organisatie of groep is vereist.`,
  [`An organization/group is required.`]: `Er is geen organisatie/groep vereist.`,
  [`Analysis configuration`]: `Analyseconfiguratie`,
  [`Application & Credentials`]: `Toepassing &amp; legitimatiegegevens`,
  [`Are you sure you want to delete this API version?`]: `Weet u zeker dat u deze API-versie wilt wissen?`,
  [`Are you sure you want to delete this API with all versions?`]: `Weet u zeker dat u deze API met alle versies wilt wissen?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Weet u zeker dat u de gatewayextensie van de gateway wilt verwijderen?`,
  [`Are you sure you want to rename all versions of this API?`]: `Weet u zeker dat u alle versies van deze API wilt hernoemen?`,
  [`Are you sure you want to rename this API version?`]: `Weet u zeker dat u deze API-versie wilt hernoemen?`,
  [`Associate groups to the organization as required`]: `Koppel zo nodig groepen aan de organisatie`,
  [`At least one option must be enabled`]: `Er moet ten minste één optie zijn ingeschakeld`,
  [`Attribute mapping`]: `Kenmerktoewijzing`,
  [`Attempt streaming rule`]: `Streamingregel proberen`,
  [`Auto generated default paths and operations`]: `Automatisch gegenereerde standaardpaden en bewerkingen`,
  [`Base API endpoint`]: `API-basiseindpunt`,
  [`Consumer orgs`]: `Consumentorganisaties`,
  [`Calculate Query Characteristics`]: `Querykenmerken berekenen`,
  [`Calculate Response Characteristics`]: `Responskenmerken berekenen`,
  [`Certificate or Shared Secret for Verify`]: `Certificaat of gemeenschappelijk geheim voor controleren`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Kijk bij onze hands-on labs, waar u kunt vragen of u de volledige API-managementfuncties een week lang mag uitproberen!`,
  [`Clone API`]: `API klonen`,
  [`Consumer organizations and groups`]: `Consumentorganisaties en groepen`,
  [`Create new API version`]: `Nieuwe API-versie maken`,
  [`Create new product version`]: `Nieuwe productversie maken`,
  [`Create a GraphQL API based on a GraphQL service`]: `Een GraphQL-API maken op basis van een GraphQL-service`,
  [`Custom rate limit`]: `Aangepaste aanroeplimiet`,
  [`Delete API`]: `API wissen`,
  [`Delete this version`]: `Deze versie wissen`,
  [`Deletion confirmation`]: `Bevestiging van wissen`,
  [`Deletions`]: `Verwijderingen`,
  [`Display name`]: `Weergavenaam`,
  [`Display Name`]: `Weergavenaam`,
  [`Details`]: `Details`,
  [`Edit GraphQL Rate Limit|title`]: `Aanroeplimiet|titel van GraphQL bewerken`,
  [`Edit GraphQL rate limits`]: `GraphQL-aanroeplimieten bewerken`,
  [`Edit group`]: `Groep bewerken`,
  [`Edit condition`]: `Voorwaarde bewerken`,
  [`Edit rate limit|title`]: `Aanvraaglimiet wijzigen`,
  [`Edit rate limit|menu`]: `Aanvraaglimiet wijzigen`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Er zijn geen producten gepubliceerd in deze catalogus, of deze toepassing is al geabonneerd op alle plannen in alle producten.`,
  [`Enforce Policy`]: `Beleid afdwingen`,
  [`Enter a group summary`]: `Geef een groepsoverzicht op`,
  [`Enter a group title`]: `Geef een groepstitel op`,
  [`Enter group details below`]: `Geef hieronder de groepsgegevens op`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Geef de URL en de schemanaam op voor de GraphQL-service waarvoor u een proxy wilt gebruiken`,
  [`Enum value`]: `Enum-waarde`,
  [`Go to catalog settings to configure a new billing integration`]: `Ga naar de catalogusinstellingen om een nieuwe factureringsintegratie te configureren`,
  [`GraphQL introspect`]: `GraphQL-introspect`,
  [`GraphQL server`]: `GraphQL-server`,
  [`GraphQL Setting`]: `GraphQL-instelling`,
  [`GraphQL server URL`]: `URL van GraphQL-server`,
  [`GraphQL server name`]: `Naam van GraphQL-server`,
  [`GraphQL type analysis configuration`]: `Configuratie van analyse van graphQL-type`,
  [`GraphQL resolver analysis configuration`]: `Configuratie van analyse van graphQL-resolver`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `In de volgende lijst kunnen groepen worden gemaakt en beheerd.`,
  [`Groups will be listed here.`]: `Groepen worden hier opgesomd.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Hallo {firstName},\n\nUw verzoek voor het {action} van het API-product {productName}:{version} in de\ncatalogus {catalog} is goedgekeurd.\n\n{#if comments}\nDegene die belast was met het goedkeuren of afwijzen, heeft het volgende commentaar toegevoegd:\n\n{comments}\n\n{{/if}}\nDe status van het product kun u hier checken:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Hallo,\n\nDit is een testbericht, afkomstig van de mailserver die geconfigureerd is voor IBM API Connect:\n(mailServer.\n\nAls u dit bericht hebt ontvangen zoals verwacht, is de test geslaagd!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Hallo,\n\nGebruiker {originator} heeft zijn of haar aanvraag voor een abonnement met de toepassing {appName}\nop plan {planName} voor versie {productVersion} van API-product {productName}\nin de catalogus {catalog} ingetrokken.\n\nU hoeft verder niets te doen.\n`,
  [`Invoke GraphQL server`]: `GraphQL-server oproepen`,
  [`Invoke the backend GraphQL server`]: `De backend GraphQL-server oproepen`,
  [`JWKS endpoint`]: `JWKS-eindpunt`,
  [`LDAP Field Name`]: `LDAP-veldnaam`,
  [`Limit maximum nesting per query`]: `Maximale nesting per query beperken`,
  [`Limit resolve complexity rate on a per key basis`]: `Complexiteitverhouding van oplossing per sleutel beperken`,
  [`Limit type complexity rate on a per key basis`]: `Complexiteitverhouding van type per sleutel beperken`,
  [`Manage APIs included in this product`]: `API's beheren die zijn opgenomen in dit product`,
  [`Manage groups`]: `Groepen beheren`,
  [`Maximum nesting`]: `Maximale nesting`,
  [`Maximum resolve complexity`]: `Maximale complexiteit van de oplossing`,
  [`Maximum type complexity`]: `Maximale complexiteit van het type`,
  [`Name of the group`]: `Naam van de groep`,
  [`New API version`]: `Nieuwe API-versie`,
  [`New GraphQL API`]: `Nieuwe GraphQL-API`,
  [`New product version`]: `Nieuwe productversie`,
  [`New Version`]: `Nieuwe versie`,
  [`New version`]: `Nieuwe versie`,
  [`No billing integration exists`]: `Er is geen factureringsintegratie`,
  [`No CA bundles available`]: `Geen CA-bundels beschikbaar`,
  [`No Data`]: `Geen gegevens`,
  [`No certificates available`]: `Geen certificaten beschikbaar`,
  [`No data`]: `Geen gegevens`,
  [`No group found`]: `Geen groep gevonden`,
  [`OAuth Auth URL`]: `OAuth autorisatie-URL`,
  [`OAuth Token URL`]: `OAuth token-URL`,
  [`Open Stripe dashboard`]: `Stripe-dashboard openen`,
  [`Operation/Path`]: `Bewerking/pad`,
  [`Operation Path`]: `Pad van bewerking`,
  [`Operations and Paths`]: `Bewerkingen en paden`,
  [`Operator`]: `Operator`,
  [`Organizations and groups will be listed here.`]: `Organisaties en groepen worden hier opgesomd.`,
  [`Page not found`]: `Pagina niet gevonden`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Kopieer de onderstaande code naar de CLI om het proces voort te zetten. De code vervalt over **{ttl}**.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Kijk in uw Stripe-dashboard voor toegang tot de API-sleutel en het geheim`,
  [`Please upload a schema definition language (SDL) file.`]: `Upload een SDL-bestand (schema definition language).`,
  [`Please upload a valid GraphQL schema.`]: `Upload een geldig GraphQL-schema.`,
  [`Please upload a valid YAML file.`]: `Upload een geldig YAML-bestand.`,
  [`Populate Server Configuration into Context Variables`]: `Serverconfiguratie vullen in contextvariabelen`,
  [`POST/GET a query to be validated and sent to the backend server`]: `POST/GET een query die wordt gecontroleerd en verzonden naar de back-end server`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `POST/GET een query om de geschatte kosten van de aanroep van die query te krijgen`,
  [`Preparing`]: `Voorbereiden`,
  [`Rate limit amount cannot be negative`]: `Aantal voor aanroeplimiet mag niet negatief zijn`,
  [`Rate limit per cannot be negative`]: `Aantal voor aanroeplimiet per tijdseenheid mag niet negatief zijn`,
  [`Rename API`]: `API hernoemen`,
  [`Rename`]: `Hernoemen`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Aanvraag voor het {action} van een API-product in de catalogus {catalog}`,
  [`Sandbox Sample Application`]: `Sandboxvoorbeeldtoepassing`,
  [`Sandbox sample application credentials`]: `Legitimatiegegevens van sandbox-voorbeeldtoepassing`,
  [`sample application credentials`]: `Legitimatiegegevens van voorbeeldtoepassing`,
  [`Saving`]: `Opslaan`,
  [`Search Orgs`]: `Orgs zoeken`,
  [`Search definitions...`]: `Definities zoeken ...`,
  [`Search errors...`]: `Fouten zoeken ...`,
  [`Search operations...`]: `Bewerkingen zoeken...`,
  [`Search organizations and groups`]: `Organisaties en groepen zoeken`,
  [`Sorry, This feature is not available in`]: `Sorry, deze functie is niet beschikbaar in`,
  [`Stopping`]: `Bezig met stoppen`,
  [`The user with username {username} already exists in the user registry.`]: `De gebruiker met gebruikersnaam {username} is al aanwezig in het gebruikersregister.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Er zijn API-wijzigingen die niet actief zijn. Publiceer deze API opnieuw naar de sandboxcatalogus.`,
  [`There are no plans available for subscription.`]: `Er zijn geen plannen beschikbaar voor abonnementen.`,
  [`There are no attributes yet.`]: `Er zijn nog geen kenmerken.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Typ om consumentorganisaties aan de groep toe te voegen`,
  [`Type to add groups to the organization`]: `Typ om groepen aan de organisatie toe te voegen`,
  [`Type to add organizations from {catalog}`]: `Typ om organisaties uit {catalog} toe te voegen`,
  [`Type to add organizations or groups`]: `Typ informatie om organisaties of groepen toe te voegen`,
  [`Type to add organizations or groups from {catalog}`]: `Typ om organisaties of groepen uit {catalog} toe te voegen`,
  [`Type to add {title} to groups`]: `Typ om {title} aan groepen toe te voegen`,
  [`Update Rate Limits`]: `Aanroeplimieten bijwerken`,
  [`Upload the YAML file with configuration`]: `Upload het YAML-bestand met de configuratie`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Gebruik het onderstaande voorbeeld als sjabloon voor het configureren van uw GraphQL-server`,
  [`User managed`]: `Beheerd door gebruiker`,
  [`URL {url} does not contain valid OpenAPI.`]: `URL {url} bevat geen geldige OpenAPI.`,
  [`Validating`]: `Valideren`,
  [`View analytics and performance metrics for your APIs`]: `Analyse- en performancecijfers voor uw API's bekijken`,
  [`View and add custom policies to the catalog`]: `Aangepaste beleidsdefinities bekijken en toevoegen aan de catalogus`,
  [`View and add custom exentions to the catalog`]: `Aangepaste extensies bekijken en toevoegen aan de catalogus`,
  [`View user registries and OAuth providers`]: `Gebruikersregisters en OAuth-providers bekijken`,
  [`View your application and manage API credentials`]: `Uw toepassing bekijken en API-legitimatiegegevens beheren`,
  [`Welcome to the API Connect Trial`]: `Welkom bij de proefversie van API Connect`,
  [`You haven't added any APIs`]: `U hebt geen API's toegevoegd`,
  [`You currently don’t have any {resource}.`]: `U hebt momenteel geen {resource}.`,
  [`plans to migrate`]: `plannen om te migreren`,
  [`gateway extensions`]: `gatewayextensies`,
  [`application`]: `toepassing`,
  [`application authentication source`]: `bron voor toepassingsverificatie`,
  [`API or product`]: `API of product`,
  [`base endpoints`]: `Basiseindpunten`,
  [`categories`]: `categorieën`,
  [`certificates`]: `certificaten`,
  [`channels`]: `kanalen`,
  [`consumer organizations`]: `consumentorganisaties`,
  [`consumer organization group`]: `consumentenorganisatiegroep`,
  [`consumer organizations or groups`]: `consumentorganisaties of groepen`,
  [`correlation ids`]: `correlatie-ID's`,
  [`cors policy`]: `beleid inzake Cross-Origin Resource Sharing`,
  [`definitions`]: `definitions`,
  [`examples`]: `voorbeelden`,
  [`gateway services`]: `gatewayservices`,
  [`groups`]: `groepen`,
  [`headers`]: `headers`,
  [`links`]: `links`,
  [`member`]: `lid`,
  [`messages`]: `-berichten`,
  [`message traits`]: `berichttraits`,
  [`operation traits`]: `bewerkingstraits`,
  [`parameter definitions`]: `parameterdefinities`,
  [`parameters`]: `parameters`,
  [`path`]: `pad`,
  [`properties`]: `eigenschappen`,
  [`provider organizations`]: `providerorganisaties`,
  [`request bodies`]: `Lopende tekst van aanvraag`,
  [`response definitions`]: `responsdefinities`,
  [`responses`]: `responsen`,
  [`role`]: `rol`,
  [`scope`]: `bereik`,
  [`scopes`]: `bereiken`,
  [`schemas`]: `schema's`,
  [`schema definition`]: `schemadefinitie`,
  [`schemes list`]: `lijst van schema's`,
  [`security definitions`]: `beveiligingsdefinities`,
  [`security schemes`]: `beveiligingsschema's`,
  [`servers`]: `servers`,
  [`services`]: `services`,
  [`tags`]: `tags`,
  [`tags and external documentation`]: `tags en externe documentatie`,
  [`target services`]: `doelservices`,
  [`tasks`]: `tasks`,
  [`user registries`]: `gebruikersregisters`,
  [`HTTP header`]: `HTTP-header`,
  [`You currently don’t have any application authentication source.`]: `U hebt momenteel geen bron voor toepassingsverificatie.`,
  [`You currently don’t have any API or product.`]: `U hebt momenteel geen API of product.`,
  [`You currently don’t have any API user registries`]: `U hebt momenteel geen API-gebruikersregisters`,
  [`You currently don’t have any APIs.`]: `U hebt momenteel geen API's.`,
  [`You currently don’t have any TLS client profiles`]: `U hebt momenteel geen TLS-clientprofielen`,
  [`You currently don’t have any base endpoints.`]: `U hebt momenteel geen basiseindpunten.`,
  [`You currently don’t have any catalog properties.`]: `U hebt momenteel geen cataloguseigenschappen.`,
  [`You currently don’t have any categories.`]: `U hebt momenteel geen categorieën.`,
  [`You currently don’t have any certificates.`]: `U hebt momenteel geen certificaten.`,
  [`You currently don’t have any channels.`]: `U hebt momenteel geen kanalen.`,
  [`You currently don’t have any consumer organizations.`]: `U hebt momenteel geen consumentorganisaties.`,
  [`You currently don’t have any consumer organizations or groups.`]: `U hebt momenteel geen consumentorganisaties of groepen.`,
  [`You currently don’t have any consumes.`]: `U hebt momenteel geen enkel gebruiksvoorwerpen.`,
  [`You currently don’t have any correlation ids.`]: `U hebt momenteel geen correlatie-ID's.`,
  [`You currently don’t have any cors policy.`]: `U hebt momenteel geen cors-beleid.`,
  [`You currently don’t have any definitions.`]: `U hebt momenteel geen definities.`,
  [`You currently don’t have any examples.`]: `U hebt momenteel geen voorbeelden.`,
  [`You currently don’t have any gateway services.`]: `U hebt momenteel geen gatewayservices.`,
  [`You currently don’t have any groups.`]: `U hebt momenteel geen groepen.`,
  [`You currently don’t have any headers.`]: `U hebt momenteel geen headers.`,
  [`You currently don’t have any keystore.`]: `U hebt momenteel geen sleutelruimte.`,
  [`You currently don’t have any links.`]: `U hebt momenteel geen links.`,
  [`You currently don’t have any messages.`]: `U hebt momenteel geen berichten.`,
  [`You currently don’t have any message traits.`]: `U hebt momenteel geen berichtkenmerken.`,
  [`You currently don’t have any OAuth providers`]: `U hebt momenteel geen OAuth-providers`,
  [`You currently don’t have any operation traits.`]: `U hebt momenteel geen bewerkingskenmerken.`,
  [`You currently don’t have any parameter definitions.`]: `U hebt momenteel geen parameterdefinities.`,
  [`You currently don’t have any parameters.`]: `U hebt momenteel geen parameters.`,
  [`You currently don’t have any preserved request header.`]: `U hebt momenteel geen bewaarde verzoekheader.`,
  [`You currently don’t have any preserved response header.`]: `U hebt momenteel geen behouden responsheader.`,
  [`You currently don’t have any produces.`]: `U hebt momenteel geen producten.`,
  [`You currently don’t have any properties.`]: `U hebt momenteel geen eigenschappen.`,
  [`You currently don’t have any provider organizations.`]: `Er zijn momenteel geen providerorganisaties.`,
  [`You currently don’t have any request bodies.`]: `U hebt momenteel geen opdrachtorganen.`,
  [`You currently don’t have any response definitions.`]: `U hebt momenteel geen responsdefinities.`,
  [`You currently don’t have any responses.`]: `U hebt momenteel geen reacties.`,
  [`You currently don’t have any scopes.`]: `U hebt momenteel geen bereiken.`,
  [`You currently don’t have any schemas.`]: `U hebt momenteel geen schema's.`,
  [`You currently don’t have any schemes list.`]: `U hebt momenteel geen lijst met schema's.`,
  [`You currently don’t have any security definitions.`]: `U hebt momenteel geen beveiligingsdefinities.`,
  [`You currently don’t have any security schemes.`]: `U hebt momenteel geen beveiligingsschema's.`,
  [`You currently don’t have any servers.`]: `U hebt momenteel geen servers.`,
  [`You currently don’t have any services.`]: `U hebt momenteel geen services.`,
  [`You currently don’t have any tags.`]: `U hebt momenteel geen tags.`,
  [`You currently don’t have any tags and external documentation.`]: `U hebt momenteel geen tags en externe documentatie.`,
  [`You currently don’t have any target services.`]: `U hebt momenteel geen doelservices.`,
  [`You currently don’t have any tasks.`]: `U hebt momenteel geen taken.`,
  [`You currently don’t have any truststore.`]: `U hebt momenteel geen truststore.`,
  [`You currently don’t have any user registries.`]: `U hebt momenteel geen gebruikersregisters.`,
  [`You currently don’t have any extensions.`]: `U hebt momenteel geen extensies.`,
  [`You currently don’t have any HTTP header.`]: `U hebt momenteel geen HTTP-header.`,
  [`Your account is being activated. This may take a while...`]: `Uw account wordt geactiveerd. Dit kan even duren...`,
  [`all of`]: `alle`,
  [`any of`]: `een van de`,
  [`calls`]: `aanroepen`,
  [`ciphers`]: `ciphers`,
  [`extensions`]: `extensies`,
  [`one of`]: `een van`,
  [`shared`]: `gedeeld`,
  [`stopping`]: `bezig met stoppen`,
  [`to:`]: `aan:`,
  [`Key`]: `Sleutel`,
  [`kind`]: `soort`,
  [`Trace`]: `Traceren`,
  [`Http Status`]: `HTTP-status`,
  [`Parsed`]: `Ontleed`,
  [`Raw`]: `Onbewerkt`,
  [`Showing Full Trace`]: `Volledige tracering afbeelden`,
  [`Show Entire Trace`]: `Gehele tracering afbeelden`,
  [`Show advanced`]: `Geavanceerde opties afbeelden`,
  [`Advanced`]: `Geavanceerd`,
  [`Sending Request`]: `Opdracht verzenden`,
  [`Please send a request above`]: `Verzend een aanvraag hierboven`,
  [`Please send a request above.`]: `Stuur een aanvraag hierboven.`,
  [`Authentication Type`]: `Verificatietype`,
  [`You don't currently have any response`]: `U hebt momenteel geen respons`,
  [`You don't currently have any trace.`]: `U hebt momenteel geen trace.`,
  [`No response received`]: `Geen respons ontvangen`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Als u op "Link openen om uitzondering te accepteren" klikt, wordt de server op een nieuw tabblad geopend. Als de browser aangeeft dat er een certificaatprobleem is, kunt u dit accepteren en hier terugkomen om nogmaals te testen.`,
  [`Open link to accept exception`]: `Link openen om uitzondering te accepteren`,
  [`Back to {name}`]: `Terug naar {name}`,
  [`0 seconds`]: `0 seconden`,
  [`close.menu`]: `Menu sluiten`,
  [`open.menu`]: `Menu openen`,
  [`Referenced from "{ref}"`]: `Wordt naar verwezen vanuit "{ref}"`,
  [`Go to {definition}`]: `Ga naar {definition}`,
  [`General`]: `Algemeen`,
  [`Servers`]: `Servers`,
  [`External Docs`]: `Externe docs`,
  [`Components`]: `Componenten`,
  [`Schemas`]: `Schema's`,
  [`Responses`]: `Responsen`,
  [`Examples`]: `Voorbeelden`,
  [`Request Bodies`]: `Lopende tekst van aanvraag`,
  [`Security Schemes`]: `Beveiligingsschema's`,
  [`Links`]: `Links`,
  [`Callbacks`]: `Callbacks`,
  [`Terms of Service`]: `Servicevoorwaarden`,
  [`Contact Name`]: `Naam contactpersoon`,
  [`Contact URL`]: `Contact-URL`,
  [`Contact Email`]: `E-mailadres contactpersoon`,
  [`Contact sales`]: `Contact opnemen met sales`,
  [`License Name`]: `Naam licentie`,
  [`License URL`]: `URL licentie`,
  [`Content-Type`]: `Contenttype`,
  [`Accept-Language`]: `Accepteer-Taal`,
  [`Accept MTOM/XOP optimized messages`]: `Voor MTOM/XOP geoptimaliseerde berichten accepteren`,
  [`User-Agent`]: `Gebruikers-Agent`,
  [`token`]: `token`,
  [`Tag`]: `Tag`,
  [`Request Body`]: `Lopende tekst van aanvraag`,
  [`Security Requirements`]: `Beveiligingsvereisten`,
  [`Located In`]: `Aanwezig in`,
  [`Example Value`]: `Voorbeeldwaarde`,
  [`External Value`]: `Externe waarde`,
  [`Server`]: `Server`,
  [`Server Description`]: `Serverbeschrijving`,
  [`Server Variables`]: `Servervariabelen`,
  [`Format`]: `Indeling`,
  [`Pattern`]: `Patroon`,
  [`Multiple Of`]: `Meerdere van`,
  [`Multiple non null slicing arguments`]: `Meerdere niet-null segmenteringsargumenten`,
  [`Missing slicing arguments`]: `Ontbrekende segmenteringsargumenten`,
  [`Missing sized fields`]: `Ontbrekende velden met grootte`,
  [`Maximum`]: `Maximum`,
  [`Exclusive Maximum`]: `Exclusief maximum`,
  [`Minimum`]: `Minimum`,
  [`Exclusive Minimum`]: `Exclusief minimum`,
  [`Max Length`]: `Max. lengte`,
  [`Min Length`]: `Min. lengte`,
  [`Max Items`]: `Max. items`,
  [`Min Items`]: `Min. items`,
  [`Unique Items`]: `Unieke items`,
  [`Max Properties`]: `Max. eigenschappen`,
  [`Min Properties`]: `Min. eigenschappen`,
  [`Required Properties`]: `Vereiste eigenschappen`,
  [`Required Property Name`]: `Vereiste eigenschapnaam`,
  [`Enum`]: `Enum`,
  [`Enum Value`]: `Enum-waarde`,
  [`Nullable`]: `Nullable`,
  [`Read Only`]: `Alleen lezen`,
  [`Write Only`]: `Alleen schrijven`,
  [`api`]: `API`,
  [`version`]: `versie`,
  [`username`]: `username`,
  [`password`]: `password`,
  [`service`]: `service`,
  [`Allows Empty Value`]: `Lege waarde toestaan`,
  [`Style`]: `Stijl`,
  [`Explode`]: `Opblazen`,
  [`Allow Reserved`]: `Gereserveerd toestaan`,
  [`Allow WebSocket Upgrade`]: `Upgrade van websocket toestaan`,
  [`Ref`]: `Verw`,
  [`Operation ID`]: `Bewerkings-ID`,
  [`Parameter`]: `Parameter`,
  [`Gateway and portal settings`]: `Gateway- en portalinstellingen`,
  [`Targets`]: `Doelen`,
  [`Catalog Properties`]: `Cataloguseigenschappen`,
  [`catalog properties`]: `cataloguseigenschappen`,
  [`Attachments`]: `Bijlagen`,
  [`Activity Log`]: `Activiteitenlogboek`,
  [`API Type`]: `Type API`,
  [`Phase`]: `Fase`,
  [`Enable CORS`]: `CORS inschakelen`,
  [`CORS Policy`]: `Beleid inzake Cross-Origin Resource Sharing`,
  [`Allowed Origins`]: `Toegestane oorsprong`,
  [`Allow Credentials`]: `Legitimatiegegevens toestaan`,
  [`ID`]: `ID`,
  [`Success Content`]: `Succescontent`,
  [`Error Content`]: `Foutcontent`,
  [`Attachment`]: `Bijlage`,
  [`Link`]: `Link`,
  [`Operation Ref`]: `Bewerkingsverw.`,
  [`TRACE`]: `TRACE`,
  [`No Security Requirements available`]: `Geen beveiligingsvereisten beschikbaar`,
  [`Create a security scheme`]: `Beveiligingsschema maken`,
  [`Create a Security Requirement`]: `Beveiligingsvereiste maken`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Een van de volgende beveiligingsvereisten verplicht stellen. Indien niet geselecteerd, is beveiliging optioneel.`,
  [`Require wrappers on fault details specified by type`]: `Wrappers verplicht stellen voor foutgegevens opgegeven per type`,
  [`There are no "Servers"`]: `Er zijn geen "Servers"`,
  [`Write`]: `Schrijven`,
  [`Server Variable`]: `Servervariabele`,
  [`Security Requirement`]: `Beveiligingsvereiste`,
  [`Security Scheme Type`]: `Type beveiligingsschema`,
  [`Security Scheme`]: `Beveiligingsschema`,
  [`OAuth Flows`]: `OAuth-stromen`,
  [`Implicit Flow`]: `Impliciete stroom`,
  [`Password Flow`]: `Wachtwoordstroom`,
  [`Client Credentials Flow`]: `Stroom clientlegitimatiegegevens`,
  [`Authorization Code Flow`]: `Machtigingscodestroom`,
  [`Refresh URL`]: `URL vernieuwen`,
  [`schema`]: `schema`,
  [`Allow Empty Value`]: `Lege waarde toestaan`,
  [`Encoding`]: `Codering`,
  [`This field is required`]: `Dit veld is verplicht`,
  [`Temporary token`]: `Tijdelijk token`,
  [`Access token`]: `Toegangstoken`,
  [`ID token`]: `ID-token`,
  [`No Security Schemes available`]: `Geen beveiligingsschema's beschikbaar`,
  [`Security Scheme (Key)`]: `Beveiligingsschema (Sleutel)`,
  [`There are no "{label}"`]: `Er zijn geen "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `U moet minimaal één schema selecteren om een beveiligingsvereiste te definiëren`,
  [`Select one or more Security Schemes`]: `Selecteer een of meer beveiligingsschema's`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `Geen OAuth-bereiken beschikbaar voor het beveiligingsschema "{key}"`,
  [`Create a Scope`]: `Een bereik maken`,
  [`Remove this Security Requirement`]: `Deze beveiligingsvereiste verwijderen`,
  [`Edit this Security Requirement`]: `Deze beveiligingsvereiste bewerken`,
  [`Invalid Security Scheme Reference`]: `Ongeldige verwijzing naar beveiligingsschema`,
  [`The following Security Schemes do not exist!`]: `De volgende beveiligingsschema's bestaan niet!`,
  [`Edit source`]: `Bron bewerken`,
  [`OR`]: `OF`,
  [`Required Security Schemes`]: `Vereiste beveiligingsschema's`,
  [`Optional Security`]: `Optionele beveiliging`,
  [`Optional custom expression (one per line)`]: `Optionele aangepaste expressie (één per regel)`,
  [`Flow Type`]: `Stroomtype`,
  [`OAuth2 Security Scheme`]: `OAuth2-beveiligingsschema`,
  [`Only source editing is supported.`]: `Alleen bronbewerking wordt ondersteund.`,
  [`Unsupported Field.`]: `Niet-ondersteund veld.`,
  [`Select an option`]: `Optie selecteren`,
  [`Select {labelText}`]: `{labelText} selecteren`,
  [`'"{changedValue}" is already used'`]: `'"{changedValue}" wordt al gebruikt'`,
  [`Referenced from "{originalRef}"`]: `Waarnaar wordt verwezen vanuit "{originalRef}"`,
  [`Go to {label}`]: `Naar {label}`,
  [`Delete "{label}"`]: `"{label}" wissen`,
  [`Are you sure you want to delete "{label}"?`]: `Weet u zeker dat u "{label}" wilt wissen?`,
  [`No recent items found`]: `Geen recente items gevonden`,
  [`Found {numDocs} results ({took} seconds)`]: `{numDocs} resultaten gevonden ({took} seconden)`,
  [`No Consumer Organizations found`]: `Geen consumentorganisaties gevonden`,
  [`No Catalog Products found`]: `Geen catalogusproducten gevonden`,
  [`No APIs found`]: `Geen API's gevonden`,
  [`No Catalogs found`]: `Geen catalogi gevonden`,
  [`No Spaces found`]: `Geen ruimten gevonden`,
  [`No results found`]: `Geen resultaten gevonden`,
  [`No Catalog APIs found`]: `Geen catalogus-API's gevonden`,
  [`No Catalogs found matching query "{query}"`]: `Geen catalogi gevonden die overeenkomen met query "{query}"`,
  [`No APIs found matching query "{query}"`]: `Geen API's gevonden die overeenkomen met query "{query}"`,
  [`No Subscriptions found`]: `Geen abonnementen gevonden`,
  [`No Plans found matching query "{query}"`]: `Geen plannen gevonden die overeenkomen met query "{query}"`,
  [`No Subscriptions found matching query "{query}"`]: `Geen abonnementen gevonden die overeenkomen met query "{query}"`,
  [`No Catalog Products found matching query "{query}"`]: `Geen catalogusproducten gevonden die overeenkomen met query "{query}"`,
  [`No Catalog APIs found matching query "{query}"`]: `Geen catalogus-API's gevonden die overeenkomen met query "{query}"`,
  [`No Consumer Organizations found matching query "{query}"`]: `Geen consumentorganisaties gevonden die overeenkomen met query "{query}"`,
  [`No Applications found matching query "{query}"`]: `Geen toepassingeb gevonden die overeenkomen met query "{query}"`,
  [`APIs per page`]: `API's per pagina`,
  [`Catalogs per page`]: `Catalogi per pagina`,
  [`items per page`]: `items per pagina`,
  [`{min}-{max} of {total} APIs`]: `{min}-{max} van {total} API's`,
  [`{min}-{max} of {total} API`]: `{min}-{max} van {total}-API`,
  [`Products per page`]: `Producten per pagina`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Product moet op de catalogus worden gepubliceerd om een nieuwe factureringsintegratie te kunnen configureren`,
  [`{min}-{max} of {total} Products`]: `{min}-{max} van {total} producten`,
  [`{min}-{max} of {total} Product`]: `{min}-{max} van het {total}-product`,
  [`Catalog Products per page`]: `Catalogusproducten per pagina`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}-{max} van {total} catalogusproducten`,
  [`{min}-{max} of {total} Catalog Product`]: `{min}-{max} van {total}-catalogusproduct`,
  [`Plans per page`]: `Plannen per pagina`,
  [`Policies per page`]: `Beleidsdefinities per pagina`,
  [`{min}-{max} of {total} Plans`]: `{min}-{max} van {total} plannen`,
  [`{min}-{max} of {total} Plan`]: `{min}-{max} van het {total}-plan`,
  [`Catalog APIs per page`]: `Catalogus-API's per pagina`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}-{max} van {total} catalogus-API's`,
  [`{min}-{max} of {total} Catalog API`]: `{min}-{max} van {total}-catalogus-API`,
  [`Subscriptions per page`]: `Abonnementen per pagina`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}-{max} van {total} abonnementen`,
  [`{min}-{max} of {total} Subscription`]: `{min}-{max} van {total}-abonnement`,
  [`Consumer Organizations per page`]: `Consumentorganisaties per pagina`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}-{max} van {total} consumentorganisaties`,
  [`{min}-{max} of {total} Consumer Organization`]: `{min}-{max} van {total}-consumentenorganisatie`,
  [`Applications per page`]: `Toepassingen per pagina`,
  [`{min}-{max} of {total} Applications`]: `{min}-{max} van {total} toepassingen`,
  [`{min}-{max} of {total} Application`]: `{min}-{max} van {total} toepassing`,
  [`Histories per page`]: `Histories per pagina`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} van {total} histories`,
  [`{min}-{max} of {total} History`]: `{min}-{max} van {total} geschiedenis`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Geen plannen gevonden waarvoor een abonnement kan worden genomen. Een product moet de status "Gepubliceerd" hebben voordat er een abonnement kan worden gemaakt. Een toepassing kan alleen op één plan in een product geabonneerd worden.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Gepubliceerde "{draftApiTitle}:{draftApiVersion}" API's worden afgebeeld`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Consumentorganisatie voor "{appTitle}" wordt afgebeeld`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Abonnementen voor "{productTitle}:{productVersion}" worden afgebeeld`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Plannen voor "{productTitle}:{productVersion}" worden afgebeeld`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `API's voor "{productTitle}:{productVersion}" worden afgebeeld`,
  [`Showing Product for "{planTitle}"`]: `Product voor "{planTitle}" wordt afgebeeld`,
  [`Showing APIs for "{planTitle}"`]: `API's voor "{planTitle}" worden afgebeeld`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Abonnementen voor plan: "{docTitle}" in product: "{docFoundIn}" worden afgebeeld`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Plannen voor "{apiTitle}:{apiVersion}" worden afgebeeld`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Concept-API's voor "{catalogApiTitle}:{catalogApiVersion}" worden afgebeeld`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Histories worden afgebeeld voor "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Abonnementen voor "{appTitle}" worden afgebeeld`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Toepassingen voor "{consumerOrgTitle}" worden afgebeeld`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Abonnementen voor "{consumerOrgTitle}" worden afgebeeld`,
  [`Showing Application for selected Subscription`]: `Toepassing voor geselecteerd abonnement wordt afgebeeld`,
  [`Showing Plan for selected Subscription`]: `Plan voor geselecteerd abonnement wordt afgebeeld`,
  [`Showing Product for selected Subscription`]: `Product voor geselecteerd abonnement wordt afgebeeld`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Selecteer de concept-API's die moeten worden opgenomen in het product "{productTitle}"`,
  [`Product Version`]: `Productversie`,
  [`OAuth Providers`]: `OAuth-providers`,
  [`Application Type`]: `Type toepassing`,
  [`Client IDs`]: `Client-ID's`,
  [`Save as New Version`]: `Opslaan als nieuwe versie`,
  [`Update Selected APIs`]: `Geselecteerde API's bijwerken`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. De API {titleAndVersion} wordt permanent verwijderd. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. Het product {titleAndVersion} wordt permanent verwijderd. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. Het catalogusproduct {titleAndVersion} wordt permanent verwijderd. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. De consumentorganisatie {titleAndVersion} wordt permanent verwijderd. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. De {titleAndVersion}-consumentengroep wordt permanent verwijderd. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. Het beleid {titleAndVersion} wordt permanent verwijderd. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. Het abonnement {titleAndVersion} wordt permanent verwijderd. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. De {titleAndVersion}-catalogus wordt permanent gewist. Wilt u doorgaan?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. De{titleAndVersion}-ruimte wordt permanent gewist. Wilt u doorgaan?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `Het catalogusproduct {titleAndVersion} wordt opnieuw gepubliceerd. Wilt u doorgaan?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `Het catalogusproduct {titleAndVersion} wordt ingetrokken. Wilt u doorgaan?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `Het catalogusproduct {titleAndVersion} wordt gedeprecieerd. Wilt u doorgaan?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `Het catalogusproduct {titleAndVersion} wordt opnieuw klaargezet. Wilt u doorgaan?`,
  [`Put Online`]: `Online zetten`,
  [`Take Offline`]: `Offline zetten`,
  [`View Subscriptions`]: `Abonnementen bekijken`,
  [`View Plans`]: `Plannen bekijken`,
  [`No Tasks found`]: `Geen taken gevonden`,
  [`Tasks per page`]: `Taken per pagina`,
  [`{min}-{max} of {total} Tasks`]: `{min}-{max} van {total} taken`,
  [`{min}-{max} of {total} Task`]: `{min}-{max} van {total} taak`,
  [`Decline and Send`]: `Afwijzen en verzenden`,
  [`View Applications`]: `Toepassingen bekijken`,
  [`Publish (Preserve Subscriptions)`]: `Publiceren (abonnementen behouden)`,
  [`Update APIs`]: `API's bijwerken`,
  [`View in Catalogs`]: `In catalogi bekijken`,
  [`View Consumer Organization`]: `Consumentorganisatie bekijken`,
  [`View Application`]: `Toepassing bekijken`,
  [`View Plan`]: `Plan bekijken`,
  [`View Product`]: `Product bekijken`,
  [`Create Subscription`]: `Abonnement maken`,
  [`No Applications found`]: `Geen toepassingen gevonden`,
  [`Select a Plan to Create a Subscription to`]: `Selecteer een plan voor het maken van een abonnement`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Deze actie kan niet ongedaan worden gemaakt. De toepassing {titleAndVersion} wordt permanent verwijderd. Wilt u doorgaan?`,
  [`Save as new version`]: `Opslaan als nieuwe versie`,
  [`Filter selected catalogs`]: `Geselecteerde catalogi filteren`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Selecteer de concept-API's die moeten worden opgenomen in het product "{productTitle}"`,
  [`Update selected APIs`]: `Geselecteerde API's bijwerken`,
  [`Catalog API`]: `Catalogus-API`,
  [`Catalog APIs`]: `Catalogus-API's`,
  [`View Products`]: `Producten bekijken`,
  [`View Draft APIs`]: `Concept-API's bekijken`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Producten die verwijzen naar "{apiTitle}:{apiVersion}" worden afgebeeld`,
  [`No Plans found`]: `Geen enkel plan gevonden`,
  [`View APIs`]: `API's bekijken`,
  [`Document type tabs`]: `Tabs voor documenttype`,
  [`No Products found`]: `Geen producten gevonden`,
  [`Consumer Organization`]: `Consumentorganisatie`,
  [`Consumer Organizations`]: `Consumentorganisaties`,
  [`Catalog Product`]: `Catalogusproduct`,
  [`Catalog Products`]: `Catalogusproducten`,
  [`Search for a Product and Plan`]: `Zoeken naar een product en plan`,
  [`OpenAPI Version`]: `OpenAPI-versie`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `API (uit REST, GraphQL of SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (uit Kafka-onderwerp)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `AsyncAPI aanmaken (vanuit Kafka-onderwerp)`,
  [`An error occurred during file upload`]: `Er is een fout opgetreden tijdens het uploaden van bestanden`,
  [`Delete uploaded file`]: `Geüpload bestand wissen`,
  [`Copied!`]: `Gekopieerd!`,
  [`Uploading`]: `Bezig met uploaden`,
  [`Validation icon`]: `Pictogram voor validatie`,
  [`Validation error`]: `Validatiefout`,
  [`Uploading image..`]: `Afbeelding uploaden ..`,
  [`Unsupported media type {providedFileType}`]: `Niet-ondersteund mediatype {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Selecteer een ondersteund bestand {expectedFileTypes} en probeer het opnieuw`,
  [`Pasted`]: `Geplakt`,
  [`Enter the API summary details.`]: `Geef de samenvatting van de API op.`,
  [`Define your Kafka cluster connection details.`]: `Definieer de verbindingsgegevens voor de Kafka-cluster.`,
  [`Channel`]: `Kanaal`,
  [`Describe your Kafka topic and its associated schema.`]: `Beschrijf uw Kafka-onderwerp en het bijbehorende schema.`,
  [`AsyncAPI specification version`]: `Versie AsyncAPI-specificatie`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Een naam die uw API beschrijft en ontwikkelaars helpt deze in de Developer Portal op te sporen.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Een versie voor uw API-definitie. De combinatie van Naam en Versie geeft een unieke identificatie van uw API-definitie.`,
  [`Description (optional)`]: `Beschrijving (optioneel)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Stel uw API voor en geef nuttige informatie aan ontwikkelaars die hem gebruiken.`,
  [`Summary (optional)`]: `Samenvatting (optioneel)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Een korte samenvatting van de events in deze API. Maximaal 1000 tekens.`,
  [`Bootstrap servers`]: `Bootstrapservers`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Een lijst van Kafka-brokers in uw cluster, gescheiden door komma's.`,
  [`Secure the API using an API Key and Secret`]: `De API beveiligen met behulp van een API-sleutel en geheim`,
  [`The credential provided should be in SASL-PLAIN format`]: `De opgegeven legitimatiegegevens moeten worden opgegeven in de notatie SASL-PLAIN`,
  [`Publish this API:`]: `Deze API publiceren:`,
  [`Creates a product`]: `Een product aanmaken`,
  [`Associates this API to the product`]: `Koppelt deze API aan het product`,
  [`Publish the product to the Sandbox catalog`]: `Het product publiceren in de Sandbox-catalogus`,
  [`Associates the product to the test app`]: `Koppelt het product aan de test-app`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Er is een schema met indeling "{fileType}" geüpload en gevalideerd.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `Het geüploade bestand is geen geldig Avro-schema en wordt niet opgenomen als onderdeel van uw API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Serverrespons: "{err}". Klik op "Terug" om terug te gaan naar de API-definitiepagina.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Serverrespons: "{err}". Klik op "Klaar" om de wizard af te sluiten.`,
  [`Topic name`]: `Naam onderwerp`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `De naam van het onderwerp dat u wilt blootstellen als onderdeel van deze API. Een onderwerp is een benoemde berichtenstroom.`,
  [`Schema for message body in .avsc format (optional)`]: `Schema voor berichttekst in .avsc-indeling (optioneel)`,
  [`Drag and drop files here, or click to upload`]: `Sleep bestanden hiernaartoe of klik om te uploaden`,
  [`About this step`]: `Over deze stap`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Events kunnen worden beschreven als asynchrone API's, omdat consumenten events ontvangen wanneer ze beschikbaar komen. In tegenstelling hiermee ontvangen synchrone API'alleen responsen als die worden aangevraagd.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Eventgestuurde asynchrone API's worden gedocumenteerd met behulp van de AsyncAPI-specificatie.`,
  [`API secret`]: `API-geheim`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Gegenereerde asyncAPI 2.1.0-definitie`,
  [`API creating`]: `API maken`,
  [`An error occurred while creating your document`]: `Er is een fout opgetreden bij het maken van uw document`,
  [`Publishing your API`]: `Uw API's worden gepubliceerd`,
  [`Your API has been published`]: `Uw API is gepubliceerd`,
  [`An error occurred while publishing your API`]: `Er is een fout opgetreden bij het publiceren van de API`,
  [`Configure security for this API`]: `Beveiliging voor deze API configureren`,
  [`Cluster connection security`]: `Beveiliging clusterverbinding`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Geef de aanmeldgegevens op die Event Gateway Services zou gebruiken om verbinding te maken met uw cluster bij het afhandelen van verzoeken aan deze API. U kunt deze waarden later in de editor wijzigen. Raadpleeg voor meer informatie de documentatie over het aanmaken van aanmeldgegevens.`,
  [`SASL Username`]: `SASL-gebruikersnaam`,
  [`SASL Password`]: `SASL-wachtwoord`,
  [`Transport CA certificate (.pem file - optional)`]: `Transport CA-certificaat (.pem-bestand - optioneel)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Als dit is opgegeven, wordt het item door de eventgatewayservice gebruikt voor het leveren van TLS-versleuteling voor de communicatie tussen de gateway en de Kafka-cluster.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Beschrijf de API-gegevens van uw Apache Kafka-eventbron om een AsyncAPI-document te genereren.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `Vervolgens kunt u het AsyncAPI-document publiceren om de eventbron zichtbaar te maken voor toepassingsontwikkelaars, die hun toepassingen kunnen configureren om ze te abonneren op de gerelateerde gegevensstroom.`,
  [`Set up security for your API if you want to control who can access it.`]: `Stel de beveiliging voor uw API in als u wilt bepalen wie er toegang heeft.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `De toegang tot uw API wordt beheerd door een eventgatewayservice die controleert op een geldige API-sleutel en -geheim.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Gateways vereisen dat clients hun communicatie met TLS versleutelen.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Nadat uw API is gemaakt, klikt u op 'API bewerken' om meer informatie toe te voegen (zoals beveiligingsgegevens) om verbinding te maken met uw Kafka-cluster vanuit uw eventgatewayservice.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `Vervolgens kunt u uw API publiceren, zodat deze beschikbaar is voor ontwikkelaars; zij kunnen de toegang tot de API opsporen en aanvragen, en de informatie gebruiken om hun toepassingen in te stellen voor het consumeren van de eventbron.`,
  [`Pending backend response`]: `Wachten op respons van back-end`,
  [`Complete`]: `Voltooid`,
  [`Rate limiting is only applied to enforced APIs`]: `Aanroeplimieten gelden alleen voor afgedwongen API's`,
  [`No Plans Available`]: `Geen plannen beschikbaar`,
  [`Modal content is not provided`]: `Modalcontent is niet beschikbaar`,
  [`Modal heading is not provided`]: `Modalheading is niet beschikbaar`,
  [`Edit Rate Limit`]: `Aanroeplimiet bewerken`,
  [`External Documentation`]: `Externe documentatie`,
  [`No Label Available`]: `Geen label beschikbaar`,
  [`optional`]: `optioneel`,
  [`Uploading image...`]: `Afbeelding uploaden...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Voeg bestanden toe door ze te verslepen, te verwijderen, te selecteren of te plakken.`,
  [`Response name`]: `Responsnaam`,
  [`Click Add to add a new {resource}.`]: `Klik op Toevoegen om een nieuwe {resource} toe te voegen.`,
  [`Schema Name (Key)`]: `Schemanaam (sleutel)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Namespace`,
  [`Attribute`]: `Kenmerk`,
  [`Wrapped`]: `Gewrapt`,
  [`Example`]: `Voorbeeld`,
  [`Example Name (Key)`]: `Voorbeeldnaam (sleutel)`,
  [`"{changedValue}" is already used`]: `"{changedValue}" wordt al gebruikt`,
  [`Name (Key)`]: `Naam (sleutel)`,
  [`Content Type (Key)`]: `Contenttype (sleutel)`,
  [`Header name`]: `Headernaam`,
  [`Reference`]: `Referentie`,
  [`Security Scheme Name (Key)`]: `Naam beveiligingsschema (sleutel)`,
  [`Choose a Reference`]: `Kies een verwijzing`,
  [`Reference from`]: `Verwijzing van`,
  [`Content Type`]: `Contenttype`,
  [`array`]: `array`,
  [`boolean`]: `boolean`,
  [`integer`]: `integer`,
  [`number`]: `getal`,
  [`object`]: `object`,
  [`string`]: `string`,
  [`query`]: `query`,
  [`cookie`]: `cookie`,
  [`matrix`]: `matrix`,
  [`label`]: `label`,
  [`simple`]: `eenvoudig`,
  [`form`]: `formulier`,
  [`spaceDelimited`]: `spaceDelimited`,
  [`pipeDelimited`]: `pipeDelimited`,
  [`deepObject`]: `deepObject`,
  [`OAuth Provider (Name)`]: `OAuth-provider (naam)`,
  [`Authenticate using User Registry (name)`]: `Verifiëren met gebruikersregister (naam)`,
  [`Bearer Format`]: `Bearer-indeling`,
  [`Server Variable Name`]: `Naam servervariabele`,
  [`Verb (Key)`]: `Verb (sleutel)`,
  [`Parameter Definitions`]: `Parameterdefinities`,
  [`Path Parameters`]: `Padparameters`,
  [`Media Type List`]: `Lijst van mediatypen`,
  [`Medium`]: `Medium`,
  [`Schemes List`]: `Lijst van schema's`,
  [`Small`]: `Klein`,
  [`Response Definitions`]: `Responsdefinities`,
  [`Terms Of Service`]: `Servicevoorwaarden`,
  [`Url`]: `URL`,
  [`Untitled Parameter`]: `Naamloze parameter`,
  [`Parameter Name`]: `Parameternaam`,
  [`Mime Type`]: `MIME-type`,
  [`Async API`]: `Asynchrone API`,
  [`Channels`]: `Kanalen`,
  [`Operation Traits`]: `Bewerkingstraits`,
  [`Messages`]: `Berichten`,
  [`Correlation IDs`]: `Correlatie-ID's`,
  [`Traits`]: `Trait`,
  [`Message Traits`]: `Berichttraits`,
  [`Bindings`]: `Bindings`,
  [`Server Bindings`]: `Serverbindings`,
  [`Channel Bindings`]: `Kanaalbindings`,
  [`Operation Bindings`]: `Bewerkingsbindings`,
  [`Message Bindings`]: `Berichtbindings`,
  [`Protocol Version`]: `Protocolversie`,
  [`Define multiple messages`]: `Meerdere berichten definiëren`,
  [`Schema Format`]: `Indeling van schema`,
  [`Unsupported Field`]: `Niet-ondersteund veld`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Bewerkingstrait`,
  [`Message Trait`]: `Berichttrait`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Gebruik het beleid voor het wisselen van bewerking om een sectie van de assemblage toe te passen op een bepaalde bewerking.`,
  [`HTML page`]: `HTML-pagina`,
  [`Rule`]: `Regel`,
  [`Origin`]: `Oorsprong`,
  [`Property Name (Key)`]: `Naam eigenschap (sleutel)`,
  [`Catalog Name (Key)`]: `Catalogusnaam (sleutel)`,
  [`Property Overrides`]: `Overrides van eigenschappen`,
  [`Property Name`]: `Eigenschapsnaam`,
  [`Property Value`]: `Waarde eigenschap`,
  [`About subscriptions and plans`]: `Over abonnementen en plannen`,
  [`Subscription Plan Name`]: `Naam abonnementsplan`,
  [`Select plan`]: `Plan selecteren`,
  [`Select the plan to migrate application subscriptions from`]: `Selecteer het plan waaruit abonnementen van toepassingen gemigreerd moeten worden`,
  [`Select subscriptions`]: `Abonnementen selecteren`,
  [`Select the application subscriptions to migrate`]: `Selecteer de toepassingsabonnementen om te migreren`,
  [`Select the product to migrate application subscriptions to`]: `Selecteer het product om abonnementen op toepassingen naar te migreren`,
  [`Select the plan to migrate application subscriptions to`]: `Selecteer het plan om abonnementen op toepassingen naar te migreren`,
  [`Message successfully sent to {sent} owner(s)`]: `Bericht is verzonden naar {sent} eigenaars`,
  [`{failed}/{sent} messages failed to send`]: `Verzenden van {failed}/{sent} is mislukt`,
  [`Failed email addresses`]: `Mislukte e-mailadressen`,
  [`Failed to save the API`]: `Opslaan van de API is mislukt`,
  [`and {numExtra} more...`]: `en nog {numExtra}...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Kopiëren naar het klembord van de lijst van e-mailadressen die niet verzonden zijn`,
  [`From`]: `Van`,
  [`Message Owner`]: `Eigenaar bericht`,
  [`Message owner`]: `Eigenaar bericht`,
  [`Message owners`]: `Eigenaren van berichten`,
  [`Message group`]: `Berichtgroep`,
  [`Select consumer organizations`]: `Consumentorganisaties selecteren`,
  [`Message consumer organization owners`]: `Eigenaren van berichtconsumentorganisatie`,
  [`Message consumer organization owner`]: `Eigenaar van berichtconsumer-organisatie`,
  [`Message provider organization owner`]: `Eigenaar van berichtproviderorganisatie`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `Het ID voor de Kafka-cluster die gerelateerd is aan dit aanroepbeleid`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Een door komma's gescheiden lijst van host:poort-paren voor het tot stand brengen van verbindingen met de Kafka-cluster`,
  [`The username to use when connecting to the Kafka cluster.`]: `De gebruikersnaam die moet worden gebruikt bij het maken van verbinding met de Kafka-cluster.`,
  [`The password to use when connecting to the Kafka cluster.`]: `Het wachtwoord voor het maken van een verbinding met de Kafka-cluster.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Eventgatewayservice`,
  [`Event gateway management client keystore`]: `Keystore van beheerclient voor eventgateway`,
  [`Edit Event gateway Service`]: `Eventgatewayservice bewerken`,
  [`Configure Event Gateway Service`]: `Eventgatewayservice configureren`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Een eventgatewayservice configureren voor het beveiligen en afdwingen van asynchrone API's`,
  [`Catalogs with published product`]: `Catalogi met gepubliceerde product`,
  [`Cluster Config ID`]: `Configuratie-ID cluster`,
  [`SASL mechanism`]: `SASL-mechanisme`,
  [`Security protocol`]: `Beveiligingsprotocol`,
  [`Transport CA certificate`]: `Transport CA-certificaat`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `Maximale bestandsgrootte {size}. Ondersteunde bestandstypen zijn json, xml, yml, yaml en zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Ondersteunde bestandstypen zijn json, xml, yml en yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Ondersteunde bestandstypen zijn json, wsdl, xml, xsd, yml, yaml en zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Ondersteunde bestandstypen zijn json, wsdl, xml, yml en yaml.`,
  [`Select a file`]: `Selecteer een bestand`,
  [`or specify a file URL`]: `of geef een bestands-URL op`,
  [`Generated OpenAPI {version} definition`]: `OpenAPI {version}-definitie is gegenereerd`,
  [`User registry type`]: `Type gebruikersregister`,
  [`User registry types`]: `Typen gebruikersregisters`,
  [`User registry type {title} has been created.`]: `Type gebruikersregister {title} is gemaakt.`,
  [`User registry type {title} has been updated.`]: `Type gebruikersregister {title} is gewijzigd.`,
  [`Failed to create user registry type {title}.`]: `Maken van type gebruikersregister {title} is mislukt.`,
  [`Failed to update user registry type {title}.`]: `Bijwerken van type gebruikersregister {title} is mislukt.`,
  [`Failed to update user registry {title}.`]: `Bijwerken van gebruikersregister {title} is mislukt.`,
  [`Custom user registry`]: `Aangepast gebruikersregister`,
  [`Create custom user registry`]: `Aangepast gebruikersregister maken`,
  [`Edit custom user registry`]: `Aangepast gebruikersregister bewerken`,
  [`Create user registry type`]: `Type gebruikersregister maken`,
  [`Edit user registry type`]: `Type gebruikersregister bewerken`,
  [`Configure user authentication using a custom external user registry`]: `Gebruikersverificatie configureren met behulp van een aangepast extern gebruikersregister`,
  [`Secured Endpoint`]: `Beveiligd eindpunt`,
  [`Configuration schema`]: `Configuratieschema`,
  [`About custom user registries`]: `Over aangepaste gebruikersregisters`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Aangepaste gebruikersregisters kunnen worden gebruikt voor het verifiëren van gebruikers voor de Developer Portal, maar kunnen niet worden gebruikt voor het beveiligen van API's. Om een aangepast gebruikersregister te configureren als resource in API Manager, moet de externe gebruikersdirectory worden gemaakt en beschikbaar worden gesteld voor gebruik met uw API Connect-ecosysteem.`,
  [`Update Wsdl`]: `Wsdl bijwerken`,
  [`Update Existing WSDL service`]: `Bestaande WSDL-service bijwerken`,
  [`Format of selected WSDL has been successfully validated`]: `Indeling van geselecteerde WSDL is gevalideerd`,
  [`Select the target WSDL file to update from`]: `Selecteer het WSDL-doelbestand van waaruit u wilt bijwerken`,
  [`Selected WSDL has been successfully uploaded`]: `Geselecteerde WSDL is geüpload`,
  [`Services available in selected API`]: `Services beschikbaar in geselecteerde API`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Legacy Open API Editor inschakelen voor OpenAPI 2.0-documenten (gedeprecieerd)`,
  [`Unknown`]: `Onbekend`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleCatalogCount} catalogi verborgen vanwege incompatibiliteit met het type gateway dat is opgegeven in de API of het product`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `{incompatibleSpacesCount} ruimten verborgen vanwege incompatibiliteit met het type gateway dat is opgegeven in de API of het product`,
  [`Keep in mind`]: `Houd hier rekening mee`,
  [`Only one type of enforced APIs is allowed per product`]: `Per product is er slechts één type afgedwongen API toegestaan`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `{incompatibleAPIsCount} API('s) kunnen niet worden geselecteerd vanwege incompatibiliteit met het type gateway dat is opgegeven in het product`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `{incompatibleProductsCount} product(en) kunnen niet worden geselecteerd vanwege incompatibiliteit met het type gateway dat is opgegeven in de API`,
  [`Unenforced`]: `Niet-afgedwongen`,
  [`Create a new product by adding APIs and plans.`]: `Maak een nieuw product door het toevoegen van API's en plannen.`,
  [`Rate limits are only applied to enforced APIs.`]: `Aanroeplimieten worden alleen toegepast op afgedwongen API's.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Plannen en API-limieten toevoegen aan uw product. Planlimieten zijn alleen van toepassing op afgedwongen API's en niet-afgedwongen API's blijven ongelimiteerd.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Plannen en aanroeplimieten toevoegen aan uw product. Plannen zijn alleen van toepassing op afgedwongen API's en niet-afgedwongen API's blijven ongelimiteerd.`,
  [`Delete plan`]: `Plan wissen`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Selecteer de eventgatewayservice voor AsyncAPI's. Voor alle andere typen API's selecteert u DataPower API Gateway of de v5-compatibele versie. Als u deze instelling wilt bijwerken, verwijdert u eerst de bijbehorende API's.`,
  [`Edit API list`]: `API-lijst bewerken`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Afgedwongen API's in dit product vereisen meer dan één gatewaytype.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `Voor afgedwongen {apiType} in dit product is {gatewayType} vereist.`,
  [`OpenAPI 2.0 API(s)`]: `OpenAPI 2.0 API's`,
  [`OpenAPI 3.0 API(s)`]: `OpenAPI 3.0 API's`,
  [`AsyncAPI(s)`]: `AsyncAPI's`,
  [`Select the gateway type for this Product`]: `Selecteer het type gateway voor dit product`,
  [`Added default plan`]: `Standaardplan toegevoegd`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Het product naar de sandboxcatalogus publiceren. Hiermee koppelt u het product aan de testapp.`,
  [`cluster`]: `cluster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Bericht van de afwijzing`,
  [`Vendor extensions feature disabled`]: `Functie voor leveranciersextensies uitgeschakeld`,
  [`No configured gateways found`]: `Geen geconfigureerde gateways gevonden`,
  [`No OpenAPI extensions associated with the configured gateway`]: `Er zijn geen OpenAPI-extensies gekoppeld aan de geconfigureerde gateway`,
  [`Extension`]: `Extensie`,
  [`Select the extensions to add to the API document`]: `Selecteer de extensies die aan het API-document moeten worden toegevoegd`,
  [`(Operations: {operations})`]: `(Bewerkingen: {operations})`,
  [`Failed to get application`]: `Ophalen van toepassing is mislukt`,
  [`Failed to get credentials for application`]: `Ophalen van legitimatiegegevens voor toepassing is mislukt`,
  [`Failed to get published product`]: `Ophalen van gepubliceerd product is mislukt`,
  [`Failed to get the published API`]: `Ophalen van gepubliceerde API is mislukt`,
  [`API must be activated to use the Test tool`]: `API moet worden geactiveerd om de testtool te kunnen gebruiken`,
  [`Import from Asset repository`]: `Importeren uit assetrepository`,
  [`Untitled {componentLabel}`]: `Naamloze {componentLabel}`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Voor uw licentie is een analyseservice vereist voor het volgen van het gebruik. Klik op "Service registreren" om een analyseservice te configureren.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Voor alle DataPower-gatewayservices is een Analytics Service vereist voor het volgen van het gebruik. Klik op "Analytics-service koppelen" voor elke gateway om te voldoen aan de licentievoorwaarden.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Catalogus is incompatibel met het type gateway dat is opgegeven in de API of het product`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `Ruimte is incompatibel met het type gateway dat is opgegeven in de API of het product`,
  [`Preserve Subscription`]: `Abonnement behouden`,
  [`Publish validation`]: `Publicatievalidatie`,
  [`Publish validations`]: `Validaties publiceren`,
  [`Edit publish validations`]: `Publicatievalidaties bewerken`,
  [`The following validations will be performed when publishing a product`]: `De volgende validaties worden uitgevoerd bij het publiceren van een product`,
  [`There are no publish validations enabled`]: `Er zijn geen publicatievalidaties ingeschakeld`,
  [`Publish validations have been updated`]: `Publicatievalidaties zijn bijgewerkt`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Geef de certificaatautoriteit (CA) op die de Event Gateway Service gebruikt om het certificaat van de backend Kafka-server te vertrouwen. Als de server een certificaat gebruikt dat is afgegeven door een bekende autoriteit, hoeft u de certificaatautoriteit niet te verstrekken.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `De opgegeven waarde voor de bootstrapservers is ongeldig. Geef de host en poort van elke Kafka-broker op uw cluster op, gescheiden door komma's. Bijvoorbeeld: broker1.com:9092,broker2.com:9092,broker3.com:9092.`,
  [`Upload OpenAPI Extension`]: `OpenAPI-extensie uploaden`,
  [`Add OpenAPI Extension`]: `OpenAPI-extensie toevoegen`,
  [`Gateway Endpoint`]: `Gateway-eindpunt`,
  [`API Dashboard`]: `API-dashboard`,
  [`API call data`]: `API-oproepgegevens`,
  [`Product Dashboard`]: `Dashboard voor producten`,
  [`API Product consumption metrics`]: `Gebruiksmetrieken voor API-producten`,
  [`Monitoring Latency Dashboard`]: `Latentie-dashboard aan het bewaken`,
  [`Data about the time taken to serve API calls`]: `Gegevens over de tijd die nodig is om API-aanroepen te dienen`,
  [`Monitoring Status Dashboard`]: `Status-dashboard aan het bewaken`,
  [`API Error and success information`]: `API-fout- en succesinformatie`,
  [`Usage Dashboard`]: `Gebruiks-dashboard`,
  [`Usage for gateway {gateway}`]: `Syntaxis voor gateway {gateway}`,
  [`Latency for gateway {gateway}`]: `Wachttijd voor gateway {gateway}`,
  [`Most popular products, APIs and applications`]: `Populairste producten, API's en toepassingen`,
  [`Consumption Dashboard`]: `Verbruiksdashboard`,
  [`Total API volume useful for auditing and compliance`]: `Totaal API-volume dat nuttig is voor auditing en compliance`,
  [`Monthly Summary by Status Code`]: `Maandoverzicht per statuscode`,
  [`Monthly Summary Data by Status Code`]: `Maandelijkse overzichtsgegevens per statuscode`,
  [`Daily Summary by Status Code`]: `Dagelijkse samenvatting per statuscode`,
  [`Daily Summary Data by Status Code`]: `Dagelijkse overzichtsgegevens per statuscode`,
  [`Response time (ms)`]: `Responstijd (ms)`,
  [`Datetime`]: `Datetime`,
  [`Transaction ID:`]: `Transactie-ID:`,
  [`Other`]: `Overige`,
  [`Date time`]: `Datum/tijd`,
  [`Total calls`]: `Totaal aantal aanroepen`,
  [`Date`]: `Datum`,
  [`Month`]: `Maand`,
  [`Minimum response time`]: `Minimum responstijd`,
  [`Maximum response time`]: `Maximale responstijd`,
  [`Average response time`]: `Gemiddelde responstijd`,
  [`Total API calls`]: `Totaal aantal API-aanroepen`,
  [`Total APIs`]: `Totaal API's`,
  [`APIs called`]: `API's aangeroepen`,
  [`Total products`]: `Totaal producten`,
  [`products called`]: `producten aangeroepen`,
  [`Total errors`]: `Totaal aantal fouten`,
  [`Status codes (detailed)`]: `Status Codes (gedetailleerd)`,
  [`Response time percentiles`]: `Percentielen responstijd`,
  [`API calls per day`]: `API-aanroepen per dag`,
  [`Time zone:`]: `Tijdzone:`,
  [`Time zone`]: `Tijdzone`,
  [`Toggle axes`]: `Assen omschakelen`,
  [`Datetime:`]: `Datum/tijd:`,
  [`datetime per 30 seconds`]: `datumtijd per 30 seconden`,
  [`Datetime (local time)`]: `Datum/tijd (lokale tijd)`,
  [`Data usage (bytes)`]: `Datagebruik (bytes)`,
  [`Time to Serve Request`]: `Tijd voor serviceaanvraag`,
  [`API name`]: `API-naam`,
  [`Product name`]: `Productnaam`,
  [`Data usage (bytes received)`]: `Datagebruik (bytes ontvangen)`,
  [`Data usage (bytes sent)`]: `Datagebruik (bytes verzonden)`,
  [`Response times (>1s)`]: `Responstijden (>1s)`,
  [`milliseconds`]: `milliseconden`,
  [`errors`]: `fouten`,
  [`Success Rate`]: `Success Rate`,
  [`Errors Data`]: `Fouten gegevens`,
  [`Success Data`]: `Succesgegevens`,
  [`Total consumer organizations`]: `Totaal consumentenorganisaties`,
  [`making API calls`]: `API-aanroepen aan het maken`,
  [`Applications per plan`]: `Aanvragen per plan`,
  [`Top products by calls`]: `Top producten door oproepen`,
  [`Top APIs by calls`]: `Top-API's door aanroepen`,
  [`Top client IP addresses by calls`]: `IP-adressen van top client door aanroepen`,
  [`Client IP address`]: `IP-adres client`,
  [`Top APIs by request size`]: `Top-API's op opdrachtgrootte`,
  [`Top APIs by response size`]: `Top-API's per responsgrootte`,
  [`Top APIs by response time`]: `Top-API's op responstijd`,
  [`Top applications by calls`]: `Toptoepassingen door oproepen`,
  [`Dashboards`]: `Dashboards`,
  [`Discover`]: `Ontdekken`,
  [`UTC`]: `UTC`,
  [`Local time`]: `Lokale tijd`,
  [`URI`]: `URI`,
  [`Response code`]: `Responscode`,
  [`Total time elapsed (ms)`]: `Totale verstreken tijd (ms)`,
  [`Timestamp`]: `Tijdsaanduiding`,
  [`Method`]: `Methode`,
  [`Gateway IP`]: `Gateway-IP`,
  [`Gateway Operations Dashboard`]: `Dashboard voor gatewaybewerkingen`,
  [`Usage and latency information per gateway`]: `Informatie over gebruik en wachttijd per gateway`,
  [`Transaction ID`]: `Transactie-ID`,
  [`Filters`]: `Filters`,
  [`Fields`]: `Velden`,
  [`Clear all`]: `Alles wissen`,
  [`API Event`]: `API-event`,
  [`API Assembly Policy Latencies`]: `Wachttijden voor API-assemblagebeleid`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `In het onderstaande diagram ziet u hoe de wachttijd in milliseconden toeneemt naarmate de aanvraag door het API-assemblagebeleid is gevorderd. Dit kan helpen bij het vaststellen van trage punten of knelpunten in de API-assemblage.`,
  [`Collapse`]: `Samenvouwen`,
  [`Expand`]: `Uitvouwen`,
  [`enter name`]: `Naam opgeven`,
  [`Enter value`]: `Geef waarde op`,
  [`Time range`]: `Periode`,
  [`Select one of the following time range filters`]: `Selecteer een van de volgende tijdbereiksfilters`,
  [`Last 1 hour`]: `Afgelopen 1 uur`,
  [`Last {number} minutes`]: `Afgelopen {number} minuten`,
  [`Last {number} hours`]: `Afgelopen {number} uur`,
  [`Last {number} days`]: `Afgelopen {number} dagen`,
  [`Start date`]: `Begindatum`,
  [`End date`]: `Einddatum`,
  [`Select from the following dropdowns and enter a value`]: `Selecteer een van de volgende vervolgkeuzemenu's en geef een waarde op`,
  [`Select one of the following type filters`]: `Selecteer een van de volgende typefilters`,
  [`All events`]: `Alle events`,
  [`Success only (2xx, 3xx)`]: `Alleen succes (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Alleen fouten (4xx, 5xx)`,
  [`Import/Export`]: `Importeren/Exporteren`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Info over {totalItems} resultaten ({searchTime} seconden) op {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Info over {totalItems} -resultaten ({searchTime} seconden) in {time} ({utcTime})`,
  [`API calls`]: `API calls`,
  [`Export as CSV`]: `Exporteren als CSV`,
  [`Export as JSON`]: `Exporteren als JSON`,
  [`Export as PNG`]: `Exporteren als PNG`,
  [`Export as JPG`]: `Exporteren als JPG`,
  [`Edit filter`]: `Filter bewerken`,
  [`Edit query`]: `Query bewerken`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `Dit is een gedeelde query. Wijzigingen worden aangebracht voor alle gebruikers waarmee deze query wordt gedeeld.`,
  [`This is a shared query. This will delete it for all users.`]: `Dit is een gedeelde query. Dit verwijdert het voor alle gebruikers.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `{queryname} is bijgewerkt voor alle gebruikers in de organisatie van de {org}-provider`,
  [`{queryname} has been updated for all users in the {space} space`]: `{queryname} is bijgewerkt voor alle gebruikers in de ruimte {space}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `{queryname} is bijgewerkt voor alle gebruikers in de catalogus van {catalog}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `{queryname} is bijgewerkt voor alle gebruikers in de cloud manager`,
  [`Stop sharing`]: `Delen stoppen`,
  [`Share query`]: `Query delen`,
  [`Share filter`]: `Filter delen`,
  [`You do not have permissions to delete this filter`]: `U bent niet gemachtigd om dit filter te wissen`,
  [`You do not have permissions to delete this query`]: `U bent niet gemachtigd om deze query te wissen`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Weet u zeker dat u de query {queryTitle} wilt delen in het {org}-provider organisatiebereik?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Weet u zeker dat u de query {queryTitle} wilt delen in het ruimtebereik {space}?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Weet u zeker dat u de query {queryTitle} wilt delen in het catalogusbereik {catalog}?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Weet u zeker dat u de query {queryTitle} wilt delen in het cloud-bereik?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Weet u zeker dat u het delen van de query {queryTitle} wilt stoppen?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Weet u zeker dat u de query {queryTitle} in het ruimtebereik {space} wilt stoppen?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Weet u zeker dat u het delen van de query {queryTitle} in het catalogusbereik {catalog} wilt stoppen?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Weet u zeker dat u het delen van de query {queryTitle} in het cloud-bereik wilt stoppen?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Weet u zeker dat u de query {queryTitle} wilt wissen?`,
  [`At least one query parameter expected`]: `Ten minste één queryparameter verwacht`,
  [`A provided query parameter is incomplete`]: `Een opgegeven queryparameter is onvolledig`,
  [`Could not parse query string`]: `Kan queryreeks niet ontleden`,
  [`An unsupported query parameter was provided: {query}`]: `Er is een niet-ondersteunde queryparameter opgegeven: {query}`,
  [`Invalid timeframe value: {value}`]: `Ongeldige waarde voor tijdschema: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `De waarde van de waarde {field} moet de datumnotatie volgen zoals gedefinieerd in RFC 3339, sectie 5.6. Voorbeelden, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `Operator {operator} wordt niet ondersteund voor veld {field}`,
  [`The value of {field} must be a number`]: `De waarde van {field} moet een getal zijn`,
  [`Delete query`]: `Query wissen`,
  [`Delete filter`]: `Filter wissen`,
  [`Save query`]: `Query opslaan`,
  [`Save filter`]: `Filter opslaan`,
  [`Save filter as`]: `Filter opslaan als`,
  [`Save query as`]: `Query opslaan als`,
  [`Save as...`]: `Opslaan als…`,
  [`Save as`]: `Opslaan als`,
  [`Paste query string to load the query`]: `Queryreeks plakken om de query te laden`,
  [`Copy or paste filter string to load filters`]: `Filterreeks kopiëren of plakken om filters te laden`,
  [`Query string`]: `Queryreeks`,
  [`View filter`]: `Filter bekijken`,
  [`View filter string`]: `Filterreeks bekijken`,
  [`Date (local time)`]: `Datum (lokale tijd)`,
  [`Coming soon, a new built in analytics feature`]: `Binnenkort komen, een nieuwe ingebouwde analytics-functie`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `Analyse helpt bij het verzamelen van inzichten in uw API-runtime, het beheren van serviceniveaus, het instellen van quota, het instellen van besturingselementen, het instellen van beveiligingsbeleid, het beheren van community's en het analyseren van trends.`,
  [`Documentation`]: `Documentatie`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Duik diep in de soorten API-runtimegegevens en hoe u AWS s3-buckets kunt gebruiken om deze te visualiseren.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Informatie over welke gegevens er vandaag beschikbaar zijn en hoe u er toegang toe krijgt.`,
  [`Next steps`]: `Volgende stappen`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `De geselecteerde Secrets Manager-service beheert geen certificaten.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Voor het beveiligen van de verbinding tussen API Connect en het domein van het gatewaybeheereindpunt is een certificaat vereist. Er zijn ook certificaten vereist om de verbindingen tussen de gateway en de domeinen te beveiligen. Alle certificaten moeten worden opgeslagen in een Secrets Manager-service. De service biedt een beveiligde repository voor de certificaten en helpt storingen te voorkomen door u meldingen te verzenden wanneer de certificaten op het punt staan te vervallen op integratie met Event Notifications-service. U gaat als volgt aan de slag,`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Certificaten zijn vereist om de verbindingen tussen de gateway en de domeinen te beveiligen. Alle certificaten moeten worden opgeslagen in een Secrets Manager-service. De service biedt een beveiligde repository voor de certificaten en helpt storingen te voorkomen door u meldingen te verzenden wanneer de certificaten op het punt staan te vervallen op integratie met Event Notifications-service. U gaat als volgt aan de slag,`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Als u de Secrets Manager wijzigt, worden alle bestaande toewijzingen van certificaten en CA-bundels verwijderd.`,
  [`Create a [Secrets Manager]({link}) service`]: `Een [Secrets Manager]({link})-service maken`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Importeer de vereiste certificaten en CA-bundels in de service Secrets Manager.`,
  [`No Secrets Manager services available`]: `Geen Secrets Manager-services beschikbaar`,
  [`Select Secrets Manager service`]: `Selecteer de Secrets Manager-service`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Selecteer de [Secrets Manager]({link})-service die de certificaten voor deze gateway beheert.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `De Secrets Manager-service moet de certificaten beheren voor het domein van het gateway-beheerseindpunt en de domeinen die de gateway afhandelt. Bovendien moet u IBM Cloud IAM gebruiken om de API Connect Reserved Instance-service te autoriseren om toegang te krijgen tot de Secrets Manager-service.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Selecteer vervolgens het certificaat voor het domein van het beheereindpunt. Selecteer ook de bijbehorende CA-bundel als het certificaat niet is toegewezen door een bekende certificaatgever. Het certificaat en de bundel moeten worden beheerd door de service Secrets Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Vervolgens geeft u de domeinen op die door deze gateway worden afgehandeld. De SNI-techniek wordt gebruikt. Als gevolg hiervan kunnen meerdere domeinen worden bediend via hetzelfde IP-adres en dezelfde poort zonder dat hetzelfde certificaat is vereist. Jokertekens zijn toegestaan. Het standaard (catch-all)-domein ‘*’ moet worden opgenomen als laatste item in de tabel. Geef indien nodig andere domeinnamen op, waarbij u telkens een certificaat opgeeft dat wordt beheerd door de service Secrets Manager.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Gebruik [IBM Cloud IAM]({link}), autoriseer de API Connect Reserved Instance-service om toegang te krijgen tot de Secrets Manager-service.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Werk bestaande certificaten en CA-bundeltoewijzingen bij door het te laden vanuit Secrets Manager.`,
  [`Access s3 data`]: `Toegang tot s3-gegevens`,
  [`Create resource`]: `Resource maken`,
  [`Connect analytics data in s3 bucket with AWS`]: `Sluit analysegegevens in s3-bucket met AWS`,
  [`AWS account`]: `AWS-account`,
  [`AWS account number`]: `AWS-rekeningnummer`,
  [`Enter your 12 digit aws account id`]: `Voer uw 12-cijferige rekening-ID in`,
  [`Account id must be 12 digits`]: `Account-ID moet 12 cijfers zijn`,
  [`Failed to update AWS account id.`]: `Bijwerken van AWS-account-ID is mislukt.`,
  [`You have limited access to the catalogs.`]: `U hebt beperkte toegang tot de catalogi.`,
  [`You have limited access to the spaces.`]: `U hebt beperkte toegang tot de ruimten.`,
  [`Contact admin to get more access.`]: `Neem contact op met admin om meer toegang te krijgen.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `U kunt deze catalogus niet selecteren. Neem contact op met een beheerder als u toegang nodig hebt.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `U kunt deze ruimte niet selecteren. Neem contact op met een beheerder als u toegang nodig hebt.`,
  [`You have limited access to the product billing.`]: `U hebt beperkte toegang tot de productfacturering.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `AWS-account-ID is bijgewerkt. Het kan enkele seconden duren voordat u toegang hebt tot S3-gegevens.`,
  [`An organization or a group is missing in the catalog chosen.`]: `Er ontbreekt een organisatie of een groep in de gekozen catalogus.`,
  [`Secrets Manager instance`]: `Secrets Manager-instance`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Consumentenorganisaties met eigenaren die een e-mail missen, kunnen niet worden geselecteerd`,
  [`You currently don’t have any data.`]: `U hebt momenteel geen gegevens.`,
  [`You currently don’t have any organizations.`]: `U hebt momenteel geen organisaties.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Aanvraag om app {appname} te abonneren op plan {plan} in product {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Aanvraag voor {operation} product: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Aanvraag voor upgrade van toepassing {appname} naar productie`,
  [`Onboarding request for {email}`]: `Onboarding-aanvraag voor {email}`,
  [`Product lifecycle request`]: `Levenscyclusaanvraag product`,
  [`Subscription request`]: `Abonnemtaanvraag`,
  [`Application lifecycle request`]: `Aanvraag levenscyclusaanvraag`,
  [`Consumer Onboarding request`]: `Aanvraag consument onboarding`,
  [`Originator`]: `Auteur`,
  [`Edit analytics service advanced settings`]: `Geavanceerde instellingen voor analyseservices bewerken`,
  [`Advanced settings`]: `Geavanceerde instellingen`,
  [`Must be a positive integer`]: `Moet een positief geheel getal zijn`,
  [`Maximum value is {maxValue}`]: `Maximumwaarde is {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `Maximumwaarde is {maxValueDays}d of {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Geavanceerde instellingen configureren voor de implementatie van de analyse.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `Waarde moet eindigen in 'd' voor dagen of 'h ' voor uren. Bijvoorbeeld 30d of 12h.`,
  [`Rollover settings`]: `Rollover-instellingen`,
  [`Retention settings`]: `Bewaarinstellingen`,
  [`Delete an index when it meets the following condition:`]: `Een index wissen als deze voldoet aan de volgende voorwaarde:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Rollover de schrijfalias naar een nieuwe index wanneer de beheerde index voldoet aan een van de volgende voorwaarden:`,
  [`Minimum document count`]: `Minimumaantal documenten`,
  [`The minimum number of documents required to roll over the index.`]: `Het minimumaantal documenten dat vereist is om de index uit te rollen.`,
  [`Minimum index age`]: `Minimumleeftijd van de index`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `De minimumleeftijd voor het indexeren van de index. Indextijd is de tijd tussen het maken van de index en nu. Gebruik 'd' for days and' h ' voor uren. Bijvoorbeeld: 30d betekent dat de index wordt gerold zodra de leeftijd 30 dagen bereikt, terwijl 96h betekent dat de index wordt gerold zodra de leeftijd 96 uur bereikt is. Maximumwaarden zijn {maxValueDays}d of {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `De minimumleeftijd voor de index die vereist is om de index te wissen. Indextijd is de tijd tussen het maken van de index en nu. Gebruik 'd' for days and' h ' voor uren. Bijvoorbeeld: 30d betekent dat de index wordt gewist zodra de leeftijd 30 dagen bereikt, terwijl 96h betekent dat de index wordt gewist zodra de leeftijd van de index 96 uur bereikt is. Maximumwaarden zijn {maxValueDays}d of {maxValueHours}h.`,
  [`Time range:`]: `Tijdbereik:`,
  [`Time range: Before {datetime}`]: `Tijdbereik: Vóór {datetime}`,
  [`Time range: After {datetime}`]: `Tijdbereik: Na {datetime}`,
  [`Filter before`]: `Filteren voor`,
  [`Filter after`]: `Filteren na`,
  [`Filter exact`]: `Exact filteren`,
  [`Filter gt`]: `Filter gt`,
  [`Filter lt`]: `Filter lt`,
  [`Filter equals`]: `Filter is gelijk`,
  [`Filter by`]: `Filteren op`,
  [`Filter out`]: `Uitfilteren`,
  [`Delete failed`]: `Wissen is mislukt`,
  [`Delete warning`]: `Waarschuwing wissen`,
  [`Could not delete saved query {queryTitle}.`]: `Opgeslagen query {queryTitle} kan niet worden gewist.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `De opdracht is correct beantwoord, maar {queryTitle} is nog niet gewist.`,
  [`API governance`]: `API-governance`,
  [`Something's wrong`]: `Er is iets mis.`,
  [`We were unable to connect to the service.`]: `We konden geen verbinding maken met de service.`,
  [`Invalid date`]: `Ongeldige datum`,
  [`Invalid time`]: `Ongeldige tijd`,
  [`End date must be after the start date`]: `Einddatum moet na de begindatum vallen`,
  [`Duplicate`]: `Dupliceren`,
  [`Duplicate query`]: `Dubbele query`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Weet u zeker dat u een duplicaat van {queryTitle} wilt maken?`,
  [`No queries saved`]: `Geen query's opgeslagen`,
  [`No queries shared`]: `Geen query's gedeeld`,
  [`To save a query, start by creating one then save.`]: `Om een query op te slaan, begint u met het maken van een query.`,
  [`Share a saved query and allow others to use it.`]: `Deel een opgeslagen query en laat anderen deze gebruiken.`,
  [`Query updated`]: `Query bijgewerkt`,
  [`Query update failed`]: `Bijwerken van query is mislukt`,
  [`Share successful`]: `Delen geslaagd`,
  [`Share failed`]: `Share is mislukt`,
  [`Delete successful`]: `Wissen geslaagd`,
  [`Query unshared`]: `Query niet gedeeld`,
  [`Unshare {queryTitle} failed`]: `Verwijderen van {queryTitle} is mislukt`,
  [`Query copied`]: `Query gekopieerd`,
  [`Duplicate {queryTitle} failed`]: `Dubbele {queryTitle} is mislukt`,
  [`The query {queryTitle} has been updated.`]: `De query {queryTitle} is bijgewerkt.`,
  [`The query {queryTitle} has been shared.`]: `De query {queryTitle} is gedeeld.`,
  [`The query {queryTitle} has been deleted.`]: `De query {queryTitle} is gewist.`,
  [`The query {queryTitle} is no longer shared.`]: `De query {queryTitle} is niet meer gedeeld.`,
  [`A copy of {queryTitle} has been created.`]: `Er is een kopie van {queryTitle} gemaakt.`,
  [`About queries`]: `Over query's`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Query's zijn een manier om de analysegegevens in de dashboards en de view Ontdekken op te stellen. Wanneer een query wordt toegepast, worden alle dashboards bijgewerkt met alleen de geselecteerde gegevens.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `Het is mogelijk om een van de vooraf gedefinieerde tijdbereiken te gebruiken of een bepaalde tijdsperiode te gebruiken voor de dichtstbijzijnde seconde met de optie Aangepast.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Velden kunnen worden gebruikt om alleen bepaalde gegevens op te geven. Het is mogelijk om te filteren op bijna elk attribuut van een api event.`,
  [`There are then different operators available depending on the field type.`]: `Er zijn dan verschillende operatoren beschikbaar, afhankelijk van het veldtype.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Tekenreeksvelden toestaan is gelijk aan, niet, begint met, eindigt met, regex.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `Het is mogelijk om te gebruiken bevat en bevat geen lijst met waarden voor een enkel veld (bijvoorbeeld een lijst met API-versies).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `IP-adresvelden staan gelijk aan gelijken en niet van operatoren. Ze staan ook toe dat de waarde in de CIDR-notatie wordt opgegeven om het gebruik van netto-maskers mogelijk te maken voor het selecteren van specifieke netwerken.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Reguliere expressies kunnen zeer krachtig zijn om specifieke waarden te selecteren, maar zijn trager om te worden uitgevoerd, dus het wordt aanbevolen om andere operatoren te gebruiken zoals 'begint met' en 'eindigt met' indien mogelijk.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Queryfilters voor verschillende velden worden gecombineerd met de logische operator AND. Dit betekent dat het toevoegen van twee filters voor dezelfde veldnaam alleen resultaten levert die aan beide filters voldoen.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `De queryparameter {type} is een eenvoudige manier om alleen succes- of foutevents op te geven. In dit veld wordt het veld {statusCode} gebruikt. Als er alleen specifieke statuscodes nodig zijn, kunt u een veldfilter gebruiken. De waarde voor {statusCodeTwo} -velden is normaal gesproken een volledige reeks, zoals {okStatus} en niet alleen de numerieke HTTP-retourcode.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `Het is mogelijk om query's op te slaan voor later hergebruik en deze te delen met uw huidige bereikniveau voor anderen om te gebruiken-bijvoorbeeld provider organisatie of catalogus.`,
  [`n/a`]: `n.v.t.`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Opmerking: de velden 'provider organisatie-ID' en 'provider organisatienaam' worden afgebeeld in de payload van de event als respectievelijk {orgId} en {orgName} ; de velden 'consumer organization ID' en 'consumer organization name' worden afgebeeld als {developerOrgId} en {developerOrgName}.`,
  [`No authentication token`]: `Geen verificatietoken`,
  [`Contact your administrator for further assistance.`]: `Neem voor assistentie contact op met de beheerder.`
};
