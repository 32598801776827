// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

export default {
  // lts+vnext shared translations
  [`Catalog has not been updated`]: `Katalog nie został zaktualizowany.`,
  [`Catalog has been updated`]: `Katalog został zaktualizowany.`,
  [`The draft product {draftProduct} must reference one or more APIs`]: `Wersja robocza produktu {draftProduct} musi odwoływać się do jednego lub większej liczby interfejsów API`,
  [`Number of Calls`]: `Liczba wywołań`,
  [`Changes will apply to newly published APIs only`]: `Zmiany będą miały zastosowanie tylko do nowo opublikowanych interfejsów API`,
  [`There are incompatible {label} due to them having a different Gateway type to this API.`]: `Istnieją niekompatybilne elementy {label} mające inny typ bramy niż ten interfejs API.`,
  [`{label} is required`]: `Właściwość {label} jest wymagana.`,
  [`Application is required`]: `Podanie jest wymagane`,
  [`Plan is required`]: `Plan jest wymagany`,
  [`Gateway is required`]: `Brama jest wymagana`,
  [`Consumer Org is required`]: `Organizacja konsumenta jest wymagana`,
  [`LDAP ATTRIBUTE NAME`]: `NAZWA ATRYBUTU LDAP`,
  [`Use the build in Test Application (sandbox only)`]: `Używaj wbudowanej aplikacji testowej (tylko w środowisku testowym)`,
  [`Select an Application`]: `Wybierz aplikację`,
  [`Use the built-in Test Application (sandbox only) - disabled when a non-sandbox catalog is chosen`]: `Używaj wbudowanej aplikacji testowej (tylko w środowisku testowym) - opcja wyłączona, gdy wybrany jest katalog inny niż katalog środowiska testowego`,
  [`Choose an existing Application`]: `Wybierz istniejącą aplikację`,
  [`Use JWT for gateway authentication to analytics service`]: `Użyj JWT do uwierzytelniania bramy w usłudze analitycznej`,
  [`Select an application to consume the API`]: `Wybierz aplikację konsumenta interfejsu API`,
  [`Use the default built-in Sandbox Catalog'`]: `Użyj domyślnego wbudowanego katalogu środowiska testowego'`,
  [`Note: This catalog will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Uwaga: ten katalog będzie używany jako zasięg dla wszystkich innych preferencji. Zmiana tego ustawienia będzie wymagać dostosowania innych preferencji.`,
  [`Select a Consumer Org`]: `Wybierz organizację konsumenta`,
  [`Select the Consumer Org your generated application will belong to`]: `Wybierz wygenerowaną aplikację konsumenta, do której będzie należeć wygenerowana aplikacja`,
  [`Note: This option can only be changed when using the built in Sandbox Test Application as existing applications will already belong to a Consumer Org`]: `Uwaga: ta opcja może zostać zmieniona tylko wtedy, gdy używana jest wbudowana aplikacja testowa środowiska testowego, ponieważ istniejące aplikacje będą już należeć do organizacji konsumenta`,
  [`Generate auto product`]: `Generuj produkt automatyczny`,
  [`Select a product to associate the current API with`]: `Wybierz produkt, z którym ma zostać powiązany bieżący interfejs API`,
  [`Associate the current API to a selected product`]: `Powiąż bieżący interfejs API z wybranym produktem`,
  [`API management`]: `API Management`,
  [`Select the API management instance you would like this API to be published to.`]: `Wybierz instancję API Management, do której ma zostać opublikowany ten interfejs API.`,
  [`Note: This instance will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Uwaga: Ta instancja będzie używana jako zasięg dla wszystkich innych preferencji. Zmiana tego ustawienia będzie wymagać dostosowania innych preferencji.`,
  [`Provider organization`]: `Organizacja dostawcy`,
  [`Select the provider organization you would like this API to be published to.`]: `Wybierz organizację dostawcy, do której ma zostać opublikowany ten interfejs API.`,
  [`Note: This organization will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Uwaga: Ta organizacja będzie używana jako zasięg dla wszystkich innych preferencji. Zmiana tego ustawienia będzie wymagać dostosowania innych preferencji.`,
  [`Note: This space will be used as the scope for all other preferences. Changing this setting will require adjusting other preferences.`]: `Uwaga: ten obszar będzie używany jako zasięg dla wszystkich innych preferencji. Zmiana tego ustawienia będzie wymagać dostosowania innych preferencji.`,
  [`Selected a draft product`]: `Wybrano roboczą wersję produktu`,
  [`Use any available gateway service`]: `Użyj dowolnej dostępnej usługi bramy`,
  [`Select a compatible gateway service`]: `Wybierz kompatybilną usługę bramy`,
  [`Note: This Gateway Service will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Uwaga: ta usługa bramy będzie używana jako zasięg dla wszystkich innych preferencji. W oparciu o to ustawienie zostaną załadowane strategie i inna treść specyficzna dla usługi bramy.`,
  [`Select a gateway`]: `Wybierz bramę`,
  [`"Note: This option is disabled when the 'Existing Product' option is selected and will instead use the Default Plan for the generated product`]: `"Uwaga: ta opcja jest wyłączona, gdy wybrano opcję 'Istniejący produkt', a zamiast niej będzie używany domyślny plan dla wygenerowanego produktu`,
  [`Select a Plan`]: `Wybierz plan`,
  [`Generate subscription`]: `Generuj subskrypcję`,
  [`A subscription will be created between the selected Product plan and Application`]: `Zostanie utworzona subskrypcja między wybranym planem produktu a aplikacją`,
  [`Apply a rate limit to the generated product`]: `Zastosuj limit częstotliwości do wygenerowanego produktu`,
  [`Apply a rate limit to the generated products' Default Plan. This option is disabled when the 'Existing Product' option is selected and will instead use the rate limit defined in the selected plan`]: `Zastosuj limit częstotliwości do domyślnego planu wygenerowanych produktów. Ta opcja jest wyłączona, jeśli wybrano opcję 'Istniejący produkt', a zamiast niej zostanie użyty limit częstotliwości zdefiniowany w wybranym planie.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication. If you want to also provide user onboarding for new Developer Portal users, you must complete the Attribute mapping section to enable writable LDAP. In this section, select the User managed checkbox, and provide the mapping of your source LDAP attribute names to the target API Connect values.`]: `Protokół LDAP (Lightweight Directory Access Protocol) jest protokołem internetowym używanym do uzyskiwania dostępu do rozproszonych usług katalogowych za pośrednictwem sieci oraz do zarządzania nimi. Jeśli korporacyjna usługa LDAP jest włączona, należy ją skonfigurować jako zasób, aby umożliwić uwierzytelnianie użytkowników. Aby podać także informacje o rejestracji nowych użytkowników portalu Developer Portal, należy wypełnić sekcję Odwzorowanie atrybutów w celu włączenia zapisywalnego katalogu LDAP. W tej sekcji zaznacz pole wyboru Zarządzane przez użytkownika i podaj odwzorowanie nazw źródłowych atrybutów LDAP w docelowych wartościach produktu API Connect.`,
  [`Enabling task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Włączenie samozatwierdzania zadań pozwala na zatwierdzanie zadań przez ich inicjatora, jak również przez współpracowników. Opcja ta może być przydatna, gdy inne osoby zatwierdzające nie są dostępne, ale efektywnie pozwala na pominięcie sprawdzania przez innego użytkownika.`,
  [`A billing integration connects API Connect to an account in a third-party subscription billing system that manages customers, their payment methods, invoicing and active subscriptions`]: `Integracja rozliczeń łączy API Connect z kontem w zewnętrznym subskrypcyjnym systemie rozliczeniowym, który zarządza klientami, ich metodami płatności, fakturowaniem i aktywnymi subskrypcjami`,
  [`A native OAuth provider object provides settings for OAuth processing operations such as generating and validating OAuth tokens. An OAuth provider object can be referenced by an OAuth security definition to protect an API. When a native OAuth provider is used, the OAuth operations are performed natively by API Connect. Every OAuth provider object has a backing API. Your configuration here automatically updates the swagger document of the API. You can edit the swagger document by navigating to the API Editor page. When a published API references an OAuth provider object, the backing API is automatically made available in the gateway.`]: `Obiekt własnego dostawcy OAuth udostępnia ustawienia na potrzeby operacji przetwarzania OAuth, takich jak generowanie i sprawdzanie poprawności tokenów OAuth. Do obiektu dostawcy OAuth można odwoływać się za pomocą definicji zabezpieczeń OAuth w celu zabezpieczenia interfejsu API. Gdy używany jest własny dostawca OAuth, operacje OAuth są wykonywane w trybie rodzimym przez produkt API Connect. Każdy obiekt dostawcy OAuth ma bazowy interfejs API. Konfiguracja w tym miejscu automatycznie aktualizuje dokument Swagger związany z interfejsem API. Aby przeprowadzić edycję dokumentu swagger, należy przejść na stronę edytora interfejsów API. Gdy opublikowany interfejs API odwołuje się do obiektu dostawcy OAuth, bazowy interfejs API zostaje automatycznie udostępniony w bramie.`,
  [`About token management`]: `Informacje o zarządzaniu tokenami`,
  [`About user security`]: `Informacje o zabezpieczeniach użytkownika`,
  [`APIs or products`]: `Interfejsy API lub produkty`,
  [`ATM is not deployed, please contact your administrator`]: `Nie wdrożono ATM, skontaktuj się z administratorem`,
  [`Application state change approvals will be enabled`]: `Zostaną włączone zatwierdzenia zmiany stanu aplikacji`,
  [`Define the settings to use to extract the application users' credentials, authenticate their identities, and grant authorization.`]: `Zdefiniuj ustawienia, które mają być używane do wyodrębnienia referencji użytkowników aplikacji, uwierzytelnienia ich tożsamości i przyznawania autoryzacji.`,
  [`See console for details.`]: `Szczegółowe informacje można znaleźć w konsoli.`,
  [`The selected Certificate Manager service does not manage any certificates.`]: `Wybrana usługa Certificate Manager nie zarządza żadnymi certyfikatami.`,
  [`The selected product is not published to the Sandbox catalog`]: `Wybrany produkt nie jest opublikowany w katalogu środowiska testowego`,
  [`The selected product is not published to the Sandbox catalog, so it is not possible to subscribe the application`]: `Wybrany produkt nie jest opublikowany w katalogu środowiska testowego, dlatego nie można zasubskrybować aplikacji`,
  [`the IBM Cloud CLI for your platform (if not previously installed).`]: `wtyczkę IBM Cloud CLI dla posiadanej platformy (jeśli nie została jeszcze zainstalowana).`,
  [`(Only supported by TLS 1.3)`]: `(Obsługiwane tylko przez TLS 1.3)`,
  [`"@listSize" directive on this field defines sized fields that do not return lists.`]: `Dyrektywa "@listSize" dotycząca tego pola definiuje pola wymiarowane, które nie zwracają list.`,
  [`"@listSize" directive on this field defines sized fields not defined on the return type of the field.`]: `Dyrektywa "@listSize" dotycząca tego pola definiuje pola wymiarowane, które nie są zdefiniowane w typie zwracanym pola.`,
  [`"@listSize" directive on this field defines slicing arguments not defined on the field.`]: `Dyrektywa "@listSize" dotycząca tego pola definiuje argumenty przekrojów, które nie są zdefiniowane w polu.`,
  [`"@listSize" directive on this field defines slicing arguments whose types are not "Int" or "Int!".`]: `Dyrektywa "@listSize" dotycząca tego pola definiuje argumenty przekrojów o typach innych niż "Int" lub "Int!".`,
  [`"@listSize" directive on this field defines multiple non-null slicing arguments.`]: `Dyrektywa "@listSize" dotycząca tego pola definiuje wiele argumentów przekrojów różnych od null.`,
  [`"{name}" (Role) has been created`]: `Utworzono rolę "{name}".`,
  [`"{name}" (Role) has been updated`]: `Zaktualizowano rolę "{name}".`,
  [`API ({api}) has been created.`]: `Interfejs API ({api}) został utworzony.`,
  [`API ({api}) has been renamed.`]: `Nazwa interfejsu API ({api}) została zmieniona.`,
  [`API ({api}) has been updated.`]: `Interfejs API ({api}) został zaktualizowany.`,
  [`API ({api}) has been deleted.`]: `Interfejs API ({api}) został usunięty.`,
  [`API ({api}) has been published.`]: `Interfejs API ({api}) został opublikowany.`,
  [`API ({api}) has validation error.`]: `W interfejsie API ({api}) występuje błąd sprawdzania poprawności`,
  [`API ({api}) has validation errors.`]: `W interfejsie API ({api}) występują błędy sprawdzania poprawności`,
  [`API version`]: `Wersja interfejsu API`,
  [`APIM data version`]: `Wersja danych APIM`,
  [`APIM schema update date`]: `Data aktualizacji schematu APIM`,
  [`APIM schema version`]: `Wersja schematu APIM`,
  [`APIM target data version`]: `Docelowa wersja danych APIM`,
  [`APIM target schema version`]: `Docelowa wersja schematu APIM`,
  [`APIM update date`]: `Data aktualizacji APIM`,
  [`Product ({product}) has been created.`]: `Produkt ({product}) został utworzony.`,
  [`Product ({product}) has been updated.`]: `Produkt ({product}) został zaktualizowany.`,
  [`Product ({product}) has been delete.`]: `Produkt ({product}) został usunięty.`,
  [`Product ({product}) has been published.`]: `Produkt ({product}) został opublikowany.`,
  [`"{title}" ({type}) has been {changedName}.`]: `{type} "{title}" została {changedName}.`,
  [`"{title}" ({type}) has not been {changedName}!`]: `{type} "{title}" nie została {changedName}!`,
  [`({units} {timeSuffix} after being queued)`]: `({units} {timeSuffix} po umieszczeniu w kolejce)`,
  [`({units} {timeSuffix} after being sent)`]: `({units} {timeSuffix} po wysłaniu)`,
  [`(none)`]: `(brak)`,
  [`(optional)`]: `(opcjonalnie)`,
  [`*Base endpoint list empty`]: `*Lista podstawowych punktów końcowych jest pusta`,
  [`*Must be a valid url`]: `*Należy wprowadzić poprawny adres URL`,
  [`*System will also send an email notification to the requester.`]: `*System wyśle również powiadomienie e-mail do osoby wnioskującej.`,
  [`+ redact from...`]: `+ redaguj od...`,
  [`. You can also manage the lifecycle of this product inside the catalog.`]: `. Można także zarządzać cyklem życia tego produktu wewnątrz katalogu.`,
  [`0x`]: `0x`,
  [`0x followed by 64 hex characters`]: `0x, a następnie 64 cyfry szesnastkowe`,
  [`1) Owns and administrates API consumer organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Jest właścicielem i administratorem organizacji konsumenta interfejsu API; 2) zarządza społecznością twórców aplikacji; 3) tworzy definicje interfejsów API i produktów; 4) zarządza cyklem życia produktu interfejsów API`,
  [`1) Owns and administrates API provider organizations 2) Manages application developer communities 3) Authors API and product definitions 4) Manages the API product lifecycle`]: `1) Jest właścicielem i administratorem organizacji dostawcy interfejsu API; 2) zarządza społecznością twórców aplikacji; 3) tworzy definicje interfejsów API i produktów; 4) zarządza cyklem życia produktu interfejsu API`,
  [`5 Most Active APIs`]: `5 najaktywniejszych interfejsów API`,
  [`5 Most Active Products`]: `5 najaktywniejszych produktów`,
  [`5 most active APIs`]: `5 najaktywniejszych interfejsów API`,
  [`5 most active Products`]: `5 najaktywniejszych produktów`,
  [`503 Service Unavailable`]: `503 Usługa niedostępna`,
  [`A request to the server was made without any credentials.`]: `Żądanie wysłane do serwera zostało wykonane bez żadnych referencji.`,
  [`A server error occurred`]: `Wystąpił błąd serwera`,
  [`A TLS client profile configures the certificate chain and cipher suite used by API Connect when connecting as a client to other systems.`]: `Profil klienta TLS konfiguruje łańcuch certyfikatów i zestaw algorytmów szyfrowania używany przez produkt API Connect podczas nawiązywania połączenia jako klient z innymi systemami.`,
  [`A TLS server profile configures the certificate chain and cipher suite used by API Connect when presenting server endpoints to other systems.`]: `Profil serwera TLS konfiguruje łańcuch certyfikatów i zestaw algorytmów szyfrowania używany przez produkt API Connect podczas prezentowania punktów końcowych serwera innym systemom.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published`]: `Katalog udostępnia kolekcję produktów interfejsów API, które po opublikowaniu będą widoczne w powiązanym portalu Developer Portal.`,
  [`A catalog hosts a collection of API products that are visible in the associated developer portal when published.`]: `Katalog udostępnia kolekcję produktów interfejsów API, które po opublikowaniu będą widoczne w powiązanym portalu Developer Portal.`,
  [`A catalog represents a collection of managed API products. Products that are published are visible to consumers on the developer portal associated with the catalog.`]: `Katalog reprezentuje kolekcję zarządzanych produktów interfejsów API. Opublikowane produkty będą widoczne w portalu Developer Portal powiązanym z danym katalogiem.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Certyfikat jest wymagany do zabezpieczenia połączenia między oprogramowaniem API Connect a domeną punktu końcowego zarządzania bramą. Certyfikaty są również wymagane do zabezpieczania połączeń między bramą a obsługiwanymi przez nią domenami. Wszystkie certyfikaty muszą być przechowywane w usłudze Certificate Manager. Usługa ta udostępnia bezpieczne repozytorium dla certyfikatów i pomaga zapobiegać przestojom, wysyłając powiadomienia o zbliżających się terminach ważności certyfikatów. Aby rozpocząć:`,
  [`A default value which will be used if the inputs to the map are not defined.`]: `Wartość domyślna, która zostanie użyta, jeśli dane wejściowe dla odwzorowania nie są zdefiniowane.`,
  [`A gateway service represents a set of gateway servers or containers that host published APIs and provide the API endpoints used by client applications. Gateways execute API proxy invocations to backend systems and enforce API policies including client identification, security and rate limiting.`]: `Usługa bramy reprezentuje zestaw serwerów lub kontenerów bramy, które udostępniają opublikowane interfejsy API oraz punkty końcowe interfejsów API używane przez aplikacje klienckie. Bramy wykonują wywołania pośredniczące interfejsów API kierowane do systemów zaplecza i egzekwują strategie interfejsów API, takie jak identyfikacja klienta, zabezpieczanie i limit częstotliwości.`,
  [`A gateway host publishes APIs and provides the API endpoints used by client applications. Gateways execute API proxy invocations to back end systems and enforce API policies including client identification, security, and rate limiting.`]: `Host bramy publikuje interfejsy API i udostępnia punkty końcowe interfejsu API używane przez aplikacje klienckie. Bramy wykonują wywołania proxy interfejsu API kierowane do systemów zaplecza i egzekwują strategie interfejsu API, w tym dotyczące identyfikacji klientów, zabezpieczeń i ograniczania częstotliwości.`,
  [`A migration target can be set on a source product to define the migration target for subscriptions.  The migration target includes a plan mapping that describes the mapping of plans on the source product to plans on the target product.  The migration target is visible in the developer portal to communicate the migration target to subscribers of the source product.`]: `Element docelowy migracji można ustawić na produkt źródłowy, definiując element docelowy migracji dla subskrypcji.  Element docelowy migracji obejmuje odwzorowanie planu, które opisuje odwzorowanie planów w produkcie źródłowym na plany w produkcie docelowym.  Element docelowy migracji jest widoczny w portalu Developer Portal, dzięki czemu subskrybenci produktu źródłowego mają dostęp do informacji o elemencie docelowym migracji.`,
  [`A modular OAuth policy kit. It can perform all OAuth/OpenID Connect protocol steps in one policy (default) or it can be split into multiple policies to perform just one  or “n” number of steps at a time for a finer control. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Modularny zestaw strategii OAuth. Ten zestaw może wykonywać wszystkie kroki protokołu OAuth/OpenID Connect w ramach jednej strategii (domyślnie) albo może zostać podzielony na wiele strategii w celu wykonania tylko jednego kroku lub innej liczby kroków naraz (aby umożliwić dokładniejsze sterowanie). Dane wejściowe i wyjściowe każdego kroku są sterowane udokumentowanymi zmiennymi kontekstowymi. W razie potrzeby dodaj lub usuń obsługiwane komponenty OAuth.`,
  [`A portal cannot be created without an available portal service. Please contact your cloud administrator for support.`]: `Nie można utworzyć portalu bez dostępnej usługi portalu. Skontaktuj się z administratorem chmury w celu uzyskania wsparcia.`,
  [`A provider organization is a team that owns APIs and the associated plans and products. A provider organization requires an owner who is assigned all permissions. Owners are assigned from members.`]: `Organizacja dostawcy to zespół, który jest właścicielem interfejsów API oraz powiązanych z nimi planów i produktów. Organizacja dostawcy musi mieć właściciela, któremu przypisano wszystkie uprawnienia. Właściciele są wyznaczani spośród członków.`,
  [`A provider organization includes users who manage APIs, products, plans, catalogs, consumer organizations, and other related resources. You can use provider organizations to group users and API management resources by project, department, lifecycle stage, and so on. To manage and assign roles that govern user privileges, go to IBM Cloud Identity and Access Management.`]: `Organizacja dostawcy obejmuje użytkowników, którzy zarządzają interfejsami API, produktami, planami, katalogami, organizacjami konsumentów i innymi powiązanymi zasobami. Korzystając z organizacji dostawców, można grupować użytkowników i zasoby zarządzania interfejsami API według projektu, działu, etapu cyklu życia itd. Aby zarządzać rolami i przypisywać role, które sterują uprawnieniami użytkowników, należy przejść do produktu IBM Cloud Identity and Access Management.`,
  [`A simple JavaScript expression which resolves to the value of the output. This could be a static string ("my static string"), or an expression involving any mapped inputs ($(input.firstName) + " " + $(input.lastName))`]: `Proste wyrażenie w języku JavaScript, które jest tłumaczone na wartość wyjściową. Może to być łańcuch statyczny ("mój łańcuch statyczny") lub wyrażenie zawierające dowolne odwzorowane dane wejściowe ($(input.firstName) + " " + $(input.lastName))`,
  [`ACTION`]: `DZIAŁANIE`,
  [`ANALYTICS`]: `ANALIZA DANYCH`,
  [`API`]: `Interfejs API`,
  [`API (GraphQL)`]: `API (GraphQL)`,
  [`API (REST)`]: `API (REST)`,
  [`API (WSDL)`]: `API (WSDL)`,
  [`API (SOAP)`]: `API (SOAP)`,
  [`API Administrator`]: `Administrator interfejsu API`,
  [`API CONNECT VALUE`]: `WARTOŚĆ API CONNECT`,
  [`API assemble`]: `Składanie interfejsu API`,
  [`API base endpoints`]: `Podstawowe punkty końcowe interfejsu API`,
  [`API base URL`]: `Podstawowy adres URL interfejsu API`,
  [`API base URLs`]: `Podstawowe adresy URL interfejsu API`,
  [`API Calls`]: `Wywołania interfejsu API`,
  [`API Connect UI`]: `Interfejs użytkownika produktu API Connect`,
  [`API Connect services`]: `Usługi API Connect`,
  [`API Connect requires an email server to send invitations and activation links to members, and for other purposes.`]: `Produkt API Connect wymaga, aby serwer poczty elektronicznej wysyłał zaproszenia i odsyłacze aktywacyjne do członków, a także do wykonywania innych operacji.`,
  [`API Connect verify token`]: `Weryfikowanie tokenu API Connect`,
  [`API Designer`]: `API Designer`,
  [`API Designer Licence`]: `Licencja aplikacji API Designer`,
  [`API Designer credentials`]: `Referencje aplikacji API Designer`,
  [`API Designer licence`]: `Licencja aplikacji API Designer`,
  [`API Designer does not currently support WSDL`]: `API Designer nie obsługuje aktualnie języka WSDL`,
  [`API editor`]: `Edytor interfejsów API`,
  [`API Endpoint`]: `Punkt końcowy interfejsu API`,
  [`API Endpoint Base`]: `Podstawa punktu końcowego interfejsu API`,
  [`API Endpoint for Unenforced APIs`]: `Punkt końcowy interfejsu API dla niewymuszonych interfejsów API`,
  [`API Endpoint for unenforced APIs`]: `Punkt końcowy interfejsu API dla niewymuszonych interfejsów API`,
  [`API Endpoints`]: `Punkty końcowe interfejsu API`,
  [`API Error`]: `Błąd interfejsu API`,
  [`API gateway`]: `Brama interfejsu API`,
  [`API Gateway`]: `Brama interfejsu API`,
  [`API gateways`]: `Bramy interfejsu API`,
  [`API Gateways`]: `Bramy interfejsu API`,
  [`API Invocation Endpoint`]: `Punkt końcowy wywołania interfejsu API`,
  [`API is not published. API Test(Try it) in Explorer will not be enabled.`]: `Interfejs API nie jest opublikowany. Test interfejsu API (Wypróbuj) w Eksploratorze nie zostanie włączony.`,
  [`API Key`]: `Klucz API`,
  [`API key`]: `Klucz API`,
  [`API key secret`]: `Dane niejawne klucza API`,
  [`API Manager`]: `API Manager`,
  [`API Manager Local User Registry`]: `Lokalny rejestr użytkowników produktu API Manager`,
  [`API Manager URL`]: `Adres URL produktu API Manager`,
  [`API Management`]: `API Management`,
  [`API Path Parameters`]: `Parametry ścieżki interfejsu API`,
  [`API Security Definition`]: `Definicja zabezpieczeń interfejsu API`,
  [`API Test (Try it) in Explorer will not be enabled for this API because it is unenforced.`]: `Test tego interfejsu API (Wypróbuj) w Eksploratorze nie zostanie włączony, ponieważ ten interfejs API nie jest wymuszony`,
  [`API Setup`]: `Konfiguracja interfejsu API`,
  [`API Subscription`]: `Subskrypcja interfejsu API`,
  [`API endpoint`]: `Punkt końcowy interfejsu API`,
  [`API endpoint base`]: `Podstawa punktu końcowego interfejsu API`,
  [`API endpoint for unenforced APIs`]: `Punkt końcowy interfejsu API dla niewymuszonych interfejsów API`,
  [`API endpoints`]: `Punkty końcowe interfejsu API`,
  [`API error`]: `Błąd interfejsu API`,
  [`API invocation endpoint`]: `Punkt końcowy wywołania interfejsu API`,
  [`API is not enforced on the API gateway.`]: `Interfejs API nie jest wymuszony w bramie Interfejs API.`,
  [`API path parameters`]: `Parametry ścieżki interfejsu API`,
  [`API security definition`]: `Definicja zabezpieczeń interfejsu API`,
  [`API setup`]: `Konfiguracja interfejsu API`,
  [`API subscription`]: `Subskrypcja interfejsu API`,
  [`API Update`]: `Aktualizacja interfejsu API`,
  [`API user registries`]: `Rejestry użytkowników interfejsu API`,
  [`API and product generated successfully!`]: `Pomyślnie wygenerowano interfejs API i produkt!`,
  [`API deletion failed`]: `Usuwanie interfejsu API nie powiodło się`,
  [`API does not exist!`]: `Interfejs API nie istnieje!`,
  [`API from existing REST service`]: `Interfejs API z istniejącej usługi REST`,
  [`API from existing SOAP service`]: `Interfejs API z istniejącej usługi SOAP`,
  [`API has been updated.`]: `Interfejs API został zaktualizowany.`,
  [`API has been updated`]: `Interfejs API został zaktualizowany`,
  [`API has not been created!`]: `Interfejs API nie został utworzony!`,
  [`API has not been updated!`]: `Interfejs API nie został zaktualizowany!`,
  [`API priority (use vanity endpoint(s) defined in the OpenAPI definitions)`]: `Priorytet interfejsu API (używaj niestandardowych punktów końcowych określonych w definicjach OpenAPI)`,
  [`API properties`]: `Właściwości interfejsu API`,
  [`API protection source`]: `Źródło ochrony interfejsu API`,
  [`API referenced from the product doesn't exist. Create or import the API first.`]: `Interfejs API przywoływany z poziomu produktu nie istnieje. Najpierw utwórz lub zaimportuj interfejs API.`,
  [`API request and response payload structures are composed using OpenAPI schema definitions.`]: `Struktury ładunków żądań i odpowiedzi interfejsu API są budowane z użyciem definicji schematów OpenAPI.`,
  [`API subscription request approved for app {{appName}}`]: `Żądanie subskrypcji API zostało zatwierdzone dla aplikacji {appName}.`,
  [`API subscription request denied for app {{appName}}`]: `Żądanie subskrypcji API zostało odrzucone dla aplikacji {appName}.`,
  [`API subscription request received for app {{appName}}`]: `Żądanie subskrypcji API zostało odebrane dla aplikacji {appName}.`,
  [`API type`]: `Typ interfejsu API`,
  [`API with key ''{key}'' under 'apis' property does not exist and cannot be listed. Please check the Source tab.`]: `Interfejs API z kluczem '{key}' we właściwości 'apis' nie istnieje i nie można go wymienić na liście. Zajrzyj na kartę Źródło.`,
  [`APIs`]: `Interfejsy API`,
  [`APIs and products`]: `Interfejsy API i produkty`,
  [`APIs included in the plan.`]: `Interfejsy API uwzględnione w tym planie.`,
  [`API Key is created`]: `Utworzono klucz interfejsu API`,
  [`API Key is deleted`]: `Usunięto klucz interfejsu API`,
  [`API Key is successfully created`]: `Klucz interfejsu API został pomyślnie utworzony`,
  [`API key timeout is **{seconds} seconds ({time})**`]: `Limit czasu klucza API wynosi **{seconds} s ({time})**`,
  [`API key timeout`]: `Limit czasu klucza API`,
  [`API Key timeout has been changed`]: `Limit czasu klucza API został zmieniony`,
  [`Application type`]: `Typ aplikacji`,
  [`Associated analytics service`]: `Powiązana usługa analizy danych`,
  [`AVAILABILITY`]: `DOSTĘPNOŚĆ`,
  [`AVAILABILITY ZONE`]: `STREFA DOSTĘPNOŚCI`,
  [`About`]: `Informacje o`,
  [`About audit setting`]: `Informacje o ustawieniu kontroli`,
  [`About Authentication URL user registry`]: `Informacje o rejestrze użytkowników z adresem URL uwierzytelniania`,
  [`About availability zones`]: `Informacje o strefach dostępności`,
  [`About catalogs`]: `Informacje o katalogach`,
  [`About consumer organization owners`]: `Informacje o właścicielach organizacji konsumenta`,
  [`About consumer organizations`]: `Informacje o organizacjach konsumentów`,
  [`About developer portals`]: `Informacje o portalach Developer Portal`,
  [`About email servers`]: `Informacje o serwerach poczty elektronicznej`,
  [`About Endpoints`]: `Informacje o punktach końcowych`,
  [`About gateways`]: `Informacje o bramach`,
  [`About gateway visibility`]: `Informacje o widoczności bram`,
  [`About Gateway Processing Status`]: `Informacje o statusie przetwarzania bramy`,
  [`About introspection`]: `Informacje o introspekcji`,
  [`About Keystores`]: `Informacje o magazynach kluczy`,
  [`About LDAP`]: `Informacje o protokole LDAP`,
  [`About local user registries`]: `Informacje o lokalnych rejestrach użytkowników`,
  [`About metadata`]: `Informacje o metadanych`,
  [`About Migration Target`]: `Informacje o elemencie docelowym migracji`,
  [`About migration targets`]: `Informacje o elementach docelowych migracji`,
  [`About native OAuth provider`]: `Informacje o własnym dostawcy OAuth`,
  [`About OpenID Connect`]: `Informacje o protokole OpenID Connect`,
  [`About replacing a product`]: `Informacje o zastępowaniu produktu`,
  [`About Role defaults`]: `Informacje o wartościach domyślnych roli`,
  [`About roles`]: `Informacje o rolach`,
  [`About scopes`]: `Informacje o zasięgach`,
  [`About Set Migration Targets`]: `Informacje o ustawionych elementach docelowych migracji`,
  [`About spaces`]: `Informacje o obszarach`,
  [`About Subscription`]: `Informacje o subskrypcji`,
  [`About superseding a product`]: `Informacje o zastępowaniu produktu nowszą wersją`,
  [`About TLS server profile`]: `Informacje o profilu serwera TLS`,
  [`About TLS client profiles`]: `Informacje o profilach klienta TLS`,
  [`About TLS server profiles`]: `Informacje o profilach serwera TLS`,
  [`About third party OAuth provider`]: `Informacje o zewnętrznym dostawcy OAuth`,
  [`About tokens`]: `Informacje o tokenach`,
  [`About Transfer Ownership`]: `Informacje o przekazywaniu prawa własności`,
  [`About Transferring Ownership`]: `Informacje o przekazywaniu prawa własności`,
  [`About Truststores`]: `Informacje o magazynach zaufanych certyfikatów`,
  [`About adding a member`]: `Informacje o dodawaniu członka`,
  [`About adding provider organization member`]: `Informacje o dodawaniu członka organizacji dostawcy`,
  [`About adding members to a Space`]: `Informacje o dodawaniu członków do obszaru`,
  [`About adding members to a catalog`]: `Informacje o dodawaniu członków do katalogu`,
  [`About changing owners`]: `Informacje o zmienianiu właścicieli`,
  [`About enabling gateway services`]: `Informacje o włączaniu usług bramy`,
  [`About inviting a member`]: `Informacje o zapraszaniu członka organizacji`,
  [`About inviting catalog members`]: `Informacje o zapraszaniu członków katalogu`,
  [`About inviting provider organization members`]: `Informacje o zapraszaniu członków organizacji dostawcy`,
  [`About inviting space members`]: `Informacje o zapraszaniu członków obszaru`,
  [`About keystores`]: `Informacje o magazynach kluczy`,
  [`About managing Space membership`]: `Informacje o zarządzaniu przynależnością do obszaru`,
  [`About migrating subscriptions`]: `Informacje o migracji subskrypcji`,
  [`About provider organizations`]: `Informacje o organizacjach dostawców`,
  [`About roles and role defaults`]: `Informacje o rolach i wartościach domyślnych ról`,
  [`About roles in spaces`]: `Informacje o rolach w obszarach`,
  [`About selecting gateway services`]: `Informacje o wyborze usług bramy`,
  [`About send message`]: `Informacje o wysyłaniu wiadomości`,
  [`About sending messages`]: `Informacje o wysyłaniu wiadomości`,
  [`About the analytics service`]: `Informacje o usłudze analizy danych`,
  [`About the DNS Scheme`]: `Informacje o schemacie DNS`,
  [`About the Gateway Service`]: `Informacje o usłudze bramy`,
  [`About the Portal Service`]: `Informacje o usłudze portalu`,
  [`About the product lifecycle`]: `Informacje o cyklu życia produktu`,
  [`About the provider organization owner`]: `Informacje o właścicielu organizacji dostawcy`,
  [`About this Cloud`]: `Informacje o chmurze`,
  [`About transferring ownership`]: `Informacje o przekazywaniu prawa własności`,
  [`About truststores`]: `Informacje o magazynach zaufanych certyfikatów`,
  [`About visibility`]: `Informacje o widoczności`,
  [`About visibility and subscribability`]: `Informacje o widoczności i możliwości subskrypcji`,
  [`Accept`]: `Akceptuj`,
  [`Access Code`]: `Kod dostępu`,
  [`Access Token`]: `Token dostępu`,
  [`Access URL through Secure Gateway`]: `Dostęp do adresu URL za pośrednictwem bezpiecznej bramy`,
  [`Access code`]: `Kod dostępu`,
  [`Access code is not chosen in supported grant type.`]: `Kod dostępu nie został wybrany w obsługiwanym typie nadania.`,
  [`Access the URL through a Secure Gateway. The gateway is set when the API is moved from staged state to published state.`]: `Dostęp do adresu URL za pośrednictwem bezpiecznej bramy. Brama jest ustawiana, gdy interfejs API jest przenoszony ze stanu przemieszczania do stanu opublikowania.`,
  [`Access token TTL has been changed`]: `Zmieniono czas życia tokenu dostępu`,
  [`Access token time to live`]: `Czas życia tokenu dostępu`,
  [`Access token time-to-live`]: `Informacje o czasie życia tokenu dostępu`,
  [`Access token will expire in **{seconds} seconds ({time})**`]: `Token dostępu utraci ważność za **{seconds} s ({time})**`,
  [`Access tokens are granted to the client application to allow the application to access resources on behalf of the application user.  Refresh tokens are issued to the client to obtain a new access token when the current access token becomes invalid or expires, or to obtain additional access tokens with identical or narrower scope. You can also specify how long the consent given by the combination of any number of access and refresh token remains valid.`]: `Tokeny dostępu są przyznawane aplikacjom klienckim w celu umożliwienia aplikacjom dostępu do zasobów w imieniu użytkownika aplikacji.  Tokeny odświeżania są wystawiane dla klienta w celu uzyskania nowego tokenu dostępu, gdy bieżący token dostępu staje się niepoprawny albo traci ważność lub w celu uzyskania tokenów dostępu o identycznym bądź węższym zasięgu. Można również określić, jak długo pozostaje ważna zgoda wyrażona przez kombinację dowolnej liczby tokenów dostępu i odświeżania.`,
  [`Access tokens time to live (seconds)`]: `Czas życia tokenów dostępu (w sekundach)`,
  [`Access token, ID token and Temporary token can be assigned to any of the existing Keystores. History of Keystore assignment is preserved.`]: `Token dostępu, token identyfikatora i token tymczasowy mogą być przypisywane do dowolnych istniejących magazynów kluczy. Historia przypisań do magazynów kluczy jest zachowywana.`,
  [`Account`]: `Konto`,
  [`Account has been updated.`]: `Konto zostało zaktualizowane.`,
  [`Account password has been changed.`]: `Hasło konta zostało zmienione.`,
  [`account-approved`]: `account-approved`,
  [`account-denied`]: `account-denied`,
  [`account-pending-approval`]: `account-pending-approval`,
  [`Action`]: `Działanie`,
  [`Activate API`]: `Aktywuj interfejs API`,
  [`Activate API|button text`]: `Aktywuj interfejs API`,
  [`Activate API for testing|title`]: `Aktywuj interfejs API`,
  [`Activation link`]: `Odsyłacz aktywacyjny`,
  [`Active`]: `Aktywny`,
  [`Activity log`]: `Dziennik działań`,
  [`Activity log API setting is not available for this API's gateway type.`]: `Ustawienie interfejsu API dziennika działań nie jest dostępne dla typu bramy tego interfejsu API.`,
  [`Active loading indicator`]: `Wskaźnik aktywnego ładowania`,
  [`Add`]: `Dodaj`,
  [`Add allowlist`]: `Dodaj listę zaakceptowanych`,
  [`Add a new billing integration in Resources`]: `Dodaj nową integrację rozliczeń w obszarze Zasoby`,
  [`Add API user registries`]: `Dodaj rejestry użytkowników interfejsu API`,
  [`Add APIs to Product`]: `Dodawanie interfejsów API do produktu`,
  [`Add another cloud`]: `Dodaj kolejną chmurę`,
  [`Add billing integration`]: `Dodaj integrację rozliczeń`,
  [`Add Burst Limit`]: `Dodaj limit wywołań`,
  [`Add Ciphers for TLS client profile`]: `Dodaj szyfry dla profilu klienta TLS`,
  [`Add Ciphers for TLS server profile`]: `Dodaj szyfry dla profilu serwera TLS`,
  [`Add Condition`]: `Dodaj warunek`,
  [`Add Consumer Role`]: `Dodawanie roli konsumenta`,
  [`Add Count Limit`]: `Dodaj limit liczby`,
  [`Add Credential`]: `Dodaj referencje`,
  [`Add domain`]: `Dodaj domenę`,
  [`Add credential`]: `Dodaj referencje`,
  [`Add Extension`]: `Dodaj rozszerzenie`,
  [`Add GraphQL schema`]: `Dodaj schemat GraphQL`,
  [`Add HTTP Endpoint`]: `Dodaj punkt końcowy HTTP`,
  [`Add group`]: `Dodaj grupę`,
  [`Add JSON schema`]: `Dodaj schemat JSON`,
  [`Add Keystore to TLS client profile`]: `Dodaj magazyn kluczy do profilu klienta TLS`,
  [`Add Keystore to TLS server profile`]: `Dodaj magazyn kluczy do profilu serwera TLS`,
  [`Add member`]: `Dodaj członka`,
  [`Add New Role for Consumer organization`]: `Dodawanie nowej roli na potrzeby organizacji konsumenta`,
  [`Add operation`]: `Dodaj operację`,
  [`Add Parameters`]: `Dodaj parametry`,
  [`Add Policy`]: `Dodaj strategię`,
  [`Add Rate limit`]: `Dodaj limit częstotliwości`,
  [`Add Rate Limit`]: `Dodaj limit częstotliwości`,
  [`Add role`]: `Dodaj rolę`,
  [`Add Syslog TCP Endpoint`]: `Dodaj punkt końcowy TCP Syslog`,
  [`Add Syslog TLS Endpoint`]: `Dodaj punkt końcowy TLS Syslog`,
  [`Add Syslog UDP Endpoint`]: `Dodaj punkt końcowy UDP Syslog`,
  [`Add XML schema`]: `Dodawanie schematu XML`,
  [`Add a Gateway endpoint that you want to make available to calls to APIs in this Catalog.`]: `Dodaj punkt końcowy bramy, który ma być dostępny dla wywołań do interfejsów API w tym katalogu.`,
  [`Add a different Cloud Connection.`]: `Dodaj inne połączenie z chmurą`,
  [`Add a member from the user registry`]: `Dodaj członka z rejestru użytkowników`,
  [`Add a user to the Admin organization, and assign the required roles`]: `Dodaj użytkownika do organizacji administracyjnej i przypisz mu wymagane role`,
  [`Add a user to the provider organization, and assign the required roles`]: `Dodaj użytkownika do organizacji dostawcy i przypisz mu wymagane role`,
  [`Add a user to the Catalog, and assign the required roles`]: `Dodaj użytkownika do katalogu i przypisz mu wymagane role`,
  [`Add a user to the Space, and assign the required roles`]: `Dodaj użytkownika do przestrzeni i przypisz mu wymagane role`,
  [`Add action`]: `Dodaj działanie`,
  [`Add additional client ID/client secret pairs`]: `Dodaj kolejne pary identyfikator klienta / dane niejawne klienta`,
  [`Add blocklist`]: `Dodaj listę zablokowanych`,
  [`Add case`]: `Dodaj przypadek`,
  [`Add catch`]: `Dodaj element wychwycony`,
  [`Add default catch`]: `Dodaj domyślny element wychwycony`,
  [`Add destination`]: `Dodaj miejsce docelowe`,
  [`Add entry`]: `Dodaj pozycję`,
  [`Add group failed.`]: `Dodanie grupy nie powiodło się.`,
  [`Add input`]: `Dodaj wejście`,
  [`Add media type`]: `Dodaj typ nośnika`,
  [`Add member failed`]: `Dodanie elementu nie powiodło się`,
  [`Add object`]: `Dodaj obiekt`,
  [`Add otherwise`]: `Dodaj W przeciwnym razie`,
  [`Add output`]: `Dodaj wyjście`,
  [`Add outputs for operation...`]: `Dodaj wyjścia dla operacji...`,
  [`Add parameters for operation...`]: `Dodaj parametry dla operacji...`,
  [`Add parameters to this API`]: `Dodawanie parametrów do interfejsu API`,
  [`Add plans to product`]: `Dodaj plany do produktu.`,
  [`Add plans to this product`]: `Dodaj plany do tego produktu`,
  [`Add schema`]: `Dodaj schemat`,
  [`Add scopes for this OAuth provider.`]: `Dodaj zasięgi na potrzeby tego dostawcy OAuth.`,
  [`Add scopes to allow access to`]: `Dodaj zasięgi, aby umożliwić dostęp do`,
  [`Add scopes to allow access to.`]: `Dodaj zasięgi w celu umożliwienia dostępu.`,
  [`Add tags and external documentation details for this API`]: `Dodawanie znaczników i szczegółów dokumentacji zewnętrznej dla interfejsu API`,
  [`Add to`]: `Dodaj do`,
  [`Add to existing product`]: `Dodaj do istniejącego produktu`,
  [`Add/Remove APIs`]: `Dodawanie/usuwanie interfejsów API`,
  [`AddProperties`]: `Dodaj właściwości`,
  [`Added APIs`]: `Dodano interfejsy API`,
  [`Added rate limit`]: `Dodano limit częstotliwości`,
  [`Added rate limits`]: `Dodano limity częstotliwości`,
  [`Adding an API to a product for publishing`]: `Dodawanie interfejsu API do produktu w celu opublikowania`,
  [`Additional support`]: `Dodatkowa obsługa`,
  [`Additional properties`]: `Właściwości dodatkowe`,
  [`Additionally, consider removing the associated registry, {name} Catalog User Registry, if it is unused.`]: `Ponadto rozważ usunięcie powiązanego rejestru, rejestru użytkowników katalogu {name}, jeśli nie jest używany.`,
  [`Address`]: `Adres`,
  [`Admin DN`]: `Nazwa wyróżniająca administratora`,
  [`Admin organization invitation timeout`]: `Limit czasu zaproszenia organizacji administracyjnej`,
  [`Admin organization invitation timeout has been changed`]: `Limit czasu zaproszenia organizacji administracyjnej został zmieniony`,
  [`Admin password`]: `Hasło administratora`,
  [`Admin request reset password`]: `Żądanie administratora – resetowanie hasła`,
  [`Admin reset password`]: `Administrator – resetowanie hasła`,
  [`Admin Sign In`]: `Administrator – logowanie`,
  [`Admin add catalog failed`]: `Nie powiodła się próba dodania katalogu przez administratora.`,
  [`Admin add space failed`]: `Nie powiodła się próba dodania obszaru przez administratora.`,
  [`Administer consumer organizations`]: `Administrowanie organizacjami konsumentów`,
  [`Administers the API consumer organization`]: `Administruje organizacją konsumenta interfejsu API`,
  [`Administers the API provider organization`]: `Administruje organizacją dostawcy interfejsu API`,
  [`Administers the admin organization`]: `Administruje organizacją administracyjną`,
  [`Administers the admin topology`]: `Administruje topologią administracyjną`,
  [`Administers the app developer organization`]: `Administruje organizacją twórców aplikacji`,
  [`Administers the catalog`]: `Administruje katalogiem`,
  [`Administers the cloud topology`]: `Administruje topologią chmury`,
  [`Administers the space`]: `Administruje obszarem`,
  [`Administration management console`]: `Konsola zarządzania administracyjnego`,
  [`Administrator`]: `Administrator`,
  [`Advanced analytics configuration`]: `Zaawansowana konfiguracja analizy danych`,
  [`Advanced features`]: `Funkcje zaawansowane`,
  [`Advanced scope check`]: `Zaawansowane sprawdzanie zasięgu`,
  [`Advanced scope check after Token Validation`]: `Zaawansowane sprawdzanie zasięgu po sprawdzeniu poprawności tokenu`,
  [`Advanced scope check before Token Generation`]: `Zaawansowane sprawdzanie zasięgu przed wygenerowaniem tokenu`,
  [`Aggregation`]: `Agregacja`,
  [`Agree`]: `Zgadzam się`,
  [`All`]: `Wszystko`,
  [`All authenticated developers in consumer organizations who have signed up for the developer portal can see this product.`]: `Ten produkt jest widoczny dla wszystkich uwierzytelnionych programistów w organizacjach konsumentów, którzy zarejestrowali się w portalu Developer Portal.`,
  [`All consumer organizations will be able to see this product.`]: `Wszystkie organizacje konsumentów będą w stanie zobaczyć ten produkt.`,
  [`Allowlist`]: `Lista zaakceptowanych`,
  [`Allow Chunked Uploads`]: `Zezwalaj na przesyłanie w porcjach`,
  [`Allow chunked uploads`]: `Zezwalaj na przesyłanie w porcjach`,
  [`Allow "plain" challenge method`]: `Zezwalaj na stosowanie metody wezwania jawnego`,
  [`Allow renegotiation`]: `Zezwalaj na ponowną negocjację`,
  [`Allow case sensitive usernames`]: `Zezwalaj na nazwy użytkowników z rozróżnianiem wielkości`,
  [`Allow clients to inject an SNI extension with the desired hostname in its initial handshake with the server.`]: `Zezwalaj klientom na wstrzykiwanie rozszerzenia SNI z pożądaną nazwą hosta podczas początkowego uzgadniania z serwerem.`,
  [`Allow connection to be renegotiated`]: `Zezwól na ponowną negocjację połączenia`,
  [`Allow default introspection`]: `Zezwalaj na introspekcję domyślną`,
  [`Allow insecure server connections`]: `Zezwalaj na niezabezpieczone połączenia z serwerem`,
  [`Allow null value`]: `Zezwalaj na wartość pustą`,
  [`Allow access_token/refresh_token to send in 302 redirect for logout`]: `Zezwalaj na wysyłanie przez access_token/refresh_token przekierowania 302 przy wylogowaniu`,
  [`All recommendations have been applied. There are no warnings for your schema.`]: `Wszystkie rekomendacje zostały zastosowane. Brak ostrzeżeń dotyczących Twojego schematu.`,
  [`Allow the API's operations to be tested using the test tools in the Developer Portal.`]: `Zezwól na testowanie operacji interfejsu API za pomocą narzędzi testowych w serwisie Developer Portal.`,
  [`Allow the connection to proceed with weak or insecure credentials`]: `Zezwól na kontynuowanie połączenia ze słabymi lub niezabezpieczonymi referencjami`,
  [`Allow the connection to proceed with weak or insecure credentials.`]: `Zezwól na kontynuowanie połączenia ze słabymi lub niezabezpieczonymi referencjami.`,
  [`Allow the external OIDC provider to communicate with the Developer Portal, not with the API Manager.`]: `Zezwalaj zewnętrznemu dostawcy OIDC na komunikację z portalem Developer Portal, nie z produktem API Manager.`,
  [`Allow to manage this user registry`]: `Zezwalaj na zarządzanie tym rejestrem użytkowników`,
  [`Allow to manage users under this user registry`]: `Zezwalaj na zarządzanie użytkownikami w tym rejestrze użytkowników`,
  [`Allow users to automatically onboard when APIC is presented with a token issued by the issuer`]: `Umożliwia użytkownikom automatyczne rejestrowanie się, gdy do kontrolera APIC przekazywany jest token wystawiony przez wystawcę`,
  [`Already have an account?`]: `Masz już konto?`,
  [`Also transfer ownership of owned Spaces`]: `Przekazuj także prawo własności do posiadanych obszarów`,
  [`Always fetch user data from userinfo endpoint if enabled`]: `Zawsze pobieraj dane użytkownika z punktu końcowego userinfo, jeśli jest włączony`,
  [`Always output the root element`]: `Zawsze zwracaj element główny`,
  [`Always output the root element.`]: `Zawsze zwracaj element główny.`,
  [`Always required`]: `Zawsze wymagane`,
  [`Always use userinfo endpoint`]: `Zawsze używaj punktu końcowego userinfo`,
  [`An OAuth provider API contains the authorization and token endpoints of an OAuth flow. Configure your OAuth providers here; then, when you create an OAuth secured API you can select the required provider.`]: `Interfejs API dostawcy OAuth zawiera punkty końcowe autoryzacji i tokenów przepływu OAuth. Skonfiguruj tutaj dostawców OAuth. Później, podczas tworzenia interfejsu API zabezpieczonego protokołem OAuth, można wybrać wymaganego dostawcę.`,
  [`An OAuth provider contains the authorization and token endpoints of an OAuth flow; select the OAuth provider that you want to use to secure your API with OAuth`]: `Dostawca OAuth zawiera punkty końcowe autoryzacji i tokeny przepływu OAuth. Wybierz dostawcę OAuth, który ma zostać użyty do zabezpieczenia interfejsu API przy użyciu protokołu OAuth.`,
  [`An application is listed here when a developer subscribes it to a plan in the space so that they can call the associated APIs; you can suspend a developer application to block it from accessing your APIs, and can also create your own applications. [Learn more]({link})`]: `Aplikacja jest w tym miejscu wyświetlana, gdy programista subskrybuje ją na potrzeby planu w obszarze, aby można było wywoływać powiązane interfejsy API. Aplikację programisty można zawiesić, aby zablokować jej dostęp do interfejsów API. Można także tworzyć własne aplikacje. [Więcej informacji]({link})`,
  [`An authentication URL points to a third-party authentication provider as the user registry. An Authentication URL enables integration with a third party user registry other than LDAP.`]: `Adres URL uwierzytelniania wskazuje zewnętrznego dostawcę uwierzytelniania jako rejestr użytkowników. Adres URL uwierzytelniania umożliwia integrację z zewnętrznym rejestrem użytkowników innym niż LDAP.`,
  [`An authentication URL provides a simple mechanism for authenticating users against a custom identity provider.`]: `Adres URL uwierzytelniania udostępnia prosty mechanizm uwierzytelniania użytkowników wobec niestandardowego dostawcy tożsamości.`,
  [`An error occurred communicating with the gateways subsystem.`]: `Wystąpił błąd podczas komunikowania się z podsystemem bram.`,
  [`An organization is required.`]: `Organizacja jest wymagana.`,
  [`An unknown error occurred.`]: `Wystąpił nieznany błąd.`,
  [`Analytics`]: `Analiza`,
  [`Analytics Insights`]: `Analytics Insights`,
  [`Analytics Insights Service'`]: `Usługa Analytics Insights'`,
  [`Analytics Details`]: `Szczegóły analizy danych`,
  [`Analytics destinations`]: `Miejsca docelowe analiz`,
  [`Analytics Director keystore`]: `Magazyn kluczy Analytics Director`,
  [`Analytics Service`]: `Usługa analizy danych`,
  [`Analytics Service URL`]: `Adres URL usługi analitycznej`,
  [`Analytics Service {arg} has been removed.`]: `Usługa analizy {arg} została usunięta.`,
  [`Analytics details`]: `Szczegóły analizy danych`,
  [`Analytics service`]: `Usługa analizy danych`,
  [`Analytics service details`]: `Szczegóły usługi analizy`,
  [`Analytics service URL`]: `Adres URL usługi analizy`,
  [`Analytics service {arg} has been removed.`]: `Usługa analizy {arg} została usunięta.`,
  [`Analytics client TLS client profile`]: `Profil klienta TLS klienta analizy danych`,
  [`Analytics client keystore`]: `Magazyn kluczy klienta analizy danych`,
  [`Analytics client truststore`]: `Magazyn zaufanych certyfikatów klienta analizy danych`,
  [`Analytics ingestion TLS client profile`]: `Profil klienta TLS pozyskiwania analizy danych`,
  [`Analytics ingestion keystore`]: `Magazyn kluczy pozyskiwania analizy danych`,
  [`Analytics ingestion truststore`]: `Magazyn zaufanych certyfikatów pozyskiwania analizy danych`,
  [`Analytics service {title} has been created.`]: `Utworzono usługę analizy danych {title}.`,
  [`Analytics service {title} has been updated.`]: `Zaktualizowano usługę analizy danych {title}.`,
  [`The selected consumer organization is`]: `Wybrana organizacji konsumenta to`,
  [`Analytics services are configured and analytics data is offloaded externally`]: `Usługi analityczne są skonfigurowane i dane analityczne są przekazywane do przetworzenia na zewnątrz`,
  [`Analyze API usage and performance`]: `Analiza wykorzystania i wydajności interfejsów API`,
  [`Anonymous bind`]: `Anonimowe powiązanie`,
  [`Another user registry`]: `Inny rejestr użytkowników`,
  [`api-administrator`]: `administrator api`,
  [`Api-Analytics`]: `Analiza danych API`,
  [`Api-Drafts`]: `Api-Drafts`,
  [`App`]: `Aplikacja`,
  [`App name`]: `Nazwa aplikacji`,
  [`App-Analytics`]: `Analiza danych aplikacji`,
  [`App-Approval`]: `Zatwierdzanie API`,
  [`App-Dev`]: `Programowanie aplikacji`,
  [`App-analytics`]: `Analiza danych aplikacji`,
  [`App-dev`]: `Programowanie aplikacji`,
  [`app-lifecycle-approved`]: `app-lifecycle-approved`,
  [`app-lifecycle-cancelled`]: `app-lifecycle-cancelled`,
  [`app-lifecycle-denied`]: `app-lifecycle-denied`,
  [`app-lifecycle-pending`]: `app-lifecycle-pending`,
  [`app-lifecycle-request`]: `app-lifecycle-request`,
  [`app-reinstated`]: `app-reinstated`,
  [`app-suspended`]: `app-suspended`,
  [`Application`]: `Aplikacja`,
  [`Application Authentication`]: `Uwierzytelnianie aplikacji`,
  [`Application Authentication Source`]: `Źródło uwierzytelniania aplikacji`,
  [`Application lifecycle`]: `Cykl życia aplikacji`,
  [`Application approval task for upgrading application`]: `Zadanie zatwierdzenia aplikacji w celu zaktualizowania aplikacji`,
  [`Application approval task for upgrading application {taskname} to production requested by {username} ({orgname})`]: `Użytkownik {username} ({orgname}) zażądał wykonania zadania zatwierdzenia aplikacji w celu zaktualizowania aplikacji {taskname} do wersji produkcyjnej`,
  [`Application authentication`]: `Uwierzytelnianie aplikacji`,
  [`Application developers initially subscribe their applications to one or more Plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Twórcy aplikacji wstępnie subskrybują swoje aplikacje w jednym lub większej liczbie planów, korzystając z portalu Developer Portal. Jednak w przypadku wybranych planów istnieje możliwość przeprowadzenia migracji subskrypcji aplikacji do planu w innym produkcie.`,
  [`Application developers initially subscribe their applications to one or more plans by using the developer portal. However, for a selected plan you can migrate application subscriptions to a plan in another product.`]: `Twórcy aplikacji wstępnie subskrybują swoje aplikacje w jednym lub większej liczbie planów, korzystając z portalu Developer Portal. Jednak w przypadku wybranych planów istnieje możliwość przeprowadzenia migracji subskrypcji aplikacji do planu w innym produkcie.`,
  [`Application developers subscribe an application to a plan so that they can then call the APIs in that plan.`]: `Twórcy aplikacji subskrybują aplikację na potrzeby planu, aby móc wywoływać interfejsy API w danym planie.`,
  [`Application is being created. Please Wait`]: `Trwa tworzenie aplikacji. Proszę czekać.`,
  [`Application scope check`]: `Sprawdzanie zasięgu aplikacji`,
  [`Applications`]: `Aplikacje`,
  [`Applications that existed before turning on application lifecycle will remain in the production state`]: `Aplikacje, które istniały przed włączeniem cyklu życia aplikacji, pozostaną w stanie produkcji`,
  [`Applied security`]: `Zastosowano zabezpieczenia`,
  [`Apply`]: `Zastosuj`,
  [`Apply all`]: `Zastosuj wszystkie`,
  [`Apply all suggestions`]: `Zastosuj wszystkie sugestie`,
  [`Apply analysis configuration`]: `Zastosuj konfigurację analizy`,
  [`Apply the invoke policy to call an existing service from within your operation. The policy can be used with JSON or XML data, and can be applied multiple times within your assembly.`]: `Zastosuj strategię wywoływania w celu wywołania istniejącej usługi z poziomu operacji. Strategia może być używana z danymi JSON lub XML i może być stosowana wiele razy w ramach zespołu.`,
  [`Apply the websocket upgrade policy to establish a websocket connection to call an existing service from within your operation.`]: `Zastosuj strategię aktualizacji websocket, aby ustanowić połączenie websocket w celu wywołania istniejącej usługi z poziomu operacji.`,
  [`Apply this policy to invoke a proxy API within your operation, particularly if you need to call a large payload. Only one proxy policy is permitted to be called per assembly.`]: `Zastosuj tę strategię, aby wywołać interfejs API proxy w ramach operacji, szczególnie w sytuacji, gdy konieczne jest wywołanie dużego ładunku. Tylko jedna strategia proxy może być wywołana na zespół.`,
  [`Apply to selected`]: `Zastosuj do wybranych`,
  [`Apply type analysis configuration`]: `Zastosuj konfigurację analizy typów`,
  [`Approval History`]: `Historia zatwierdzania`,
  [`Approval tasks`]: `Zadania zatwierdzania`,
  [`Approvals`]: `Zatwierdzenia`,
  [`Approve`]: `Zatwierdź`,
  [`Archive`]: `Archiwizuj`,
  [`Archive Product`]: `Archiwizowanie produktu`,
  [`Archive|permission`]: `Archiwizowanie`,
  [`Are you sure you want to clear the constraints from your selected items?`]: `Czy na pewno chcesz usunąć ograniczenia z wybranych elementów?`,
  [`Are you sure you want to delete API?`]: `Czy na pewno chcesz usunąć interfejs API?`,
  [`Are you sure you want to delete Product?`]: `Czy na pewno chcesz usunąć produkt?`,
  [`Are you sure you want to delete a catalog?`]: `Czy na pewno chcesz usunąć katalog?`,
  [`Are you sure you want to delete a space?`]: `Czy na pewno chcesz usunąć obszar?`,
  [`Are you sure you want to delete this application?`]: `Czy na pewno chcesz usunąć tę aplikację?`,
  [`Are you sure you want to delete this billing integration?`]: `Czy na pewno chcesz usunąć tę integrację rozliczeń?`,
  [`Are you sure you want to delete this field?`]: `Czy na pewno chcesz usunąć to pole?`,
  [`Are you sure you want to delete this policy?`]: `Czy na pewno chcesz usunąć tę strategię?`,
  [`Are you sure you want to delete this type?`]: `Czy na pewno chcesz usunąć ten typ?`,
  [`Are you sure you want to disable spaces?`]: `Czy na pewno chcesz wyłączyć obszary?`,
  [`Are you sure you want to disable custom email sender information?`]: `Czy na pewno chcesz wyłączyć niestandardowe informacje o nadawcy wiadomości e-mail?`,
  [`Are you sure you want to disable custom notification templates?`]: `Czy na pewno chcesz wyłączyć niestandardowe szablony powiadomień?`,
  [`Are you sure you want to discontinue using the IBM Developer Portal? All portal customizations for catalog will be deleted`]: `Czy na pewno chcesz zakończyć korzystanie z produktu IBM Developer Portal? Wszystkie dostosowania portalu związane z katalogiem zostaną usunięte`,
  [`Are you sure you want to enable spaces?`]: `Czy na pewno chcesz włączyć obszary?`,
  [`Are you sure you want to enable custom email sender information?`]: `Czy na pewno chcesz włączyć niestandardowe informacje o nadawcy wiadomości e-mail?`,
  [`Are you sure you want to enable custom notification templates?`]: `Czy na pewno chcesz włączyć niestandardowe szablony powiadomień?`,
  [`Are you sure you want to proceed with delete?`]: `Czy na pewno kontynuować usuwanie?`,
  [`Are you sure you want to publish?`]: `Czy na pewno chcesz opublikować?`,
  [`Are you sure you want to re-stage?`]: `Czy na pewno chcesz ponownie przemieścić?`,
  [`Are you sure you want to remove the product from the catalog?`]: `Czy na pewno usunąć produkt z katalogu?`,
  [`Are you sure you want to unassociate?`]: `Czy na pewno chcesz anulować powiązanie?`,
  [`Are you sure you want to update Self Service Onboarding`]: `Czy na pewno chcesz zaktualizować narzędzie samodzielnej rejestracji`,
  [`Are you sure?`]: `Czy na pewno?`,
  [`Argument`]: `Argument`,
  [`Array`]: `Tablica`,
  [`Assemble`]: `Składanie`,
  [`Assemble the policy flow required for the OAuth provider.`]: `Składanie przepływu strategii wymaganego dla dostawcy OAuth.`,
  [`Assembly`]: `Zespół`,
  [`Assembly Execute`]: `Wykonanie zespołu`,
  [`Assembly saved`]: `Zespół zapisany`,
  [`Assembly burst limits`]: `Limity wywołań zespołu`,
  [`Assembly count limits`]: `Limity liczby wywołań zespołu`,
  [`Assign roles`]: `Przypisz role`,
  [`Assign roles to the current owner`]: `Przypisz role do bieżącego właściciela`,
  [`Associate`]: `Powiąż`,
  [`Associate analytics service`]: `Powiąż usługę analizy danych`,
  [`Associate analytics`]: `Powiąż analizę danych`,
  [`Assumed size`]: `Założona wielkość`,
  [`Authetication error trying to get API from URL {url}. Check username and password.`]: `Wystąpił błąd uwierzytelniania podczas próby pobrania interfejsu API z adresu URL {url}. Sprawdź nazwę i hasło użytkownika.`,
  [`Audience claim`]: `Roszczenie audytorium`,
  [`Audience Claim`]: `Roszczenie audytorium`,
  [`Audit setting`]: `Ustawienie kontroli`,
  [`Audit setting has been changed`]: `Ustawienie kontroli zostało zmienione`,
  [`Auditing is **{mode}**`]: `Stan kontroli: **{mode}**`,
  [`Auditing is used to monitor API calls and log information about the calling users, the time of each call, and the activity involved in each event.`]: `Kontrola służy do monitorowania wywołań interfejsów API i rejestrowania informacji o użytkownikach wywołujących, czasu każdego wywołania oraz aktywności związanej z każdym zdarzeniem.`,
  [`Authenticate Client Method`]: `Metoda uwierzytelniania klienta`,
  [`Authenticate password`]: `Hasło na potrzeby uwierzytelniania`,
  [`Authenticate user`]: `Użytkownik na potrzeby uwierzytelniania`,
  [`Authenticate user settings`]: `Ustawienia uwierzytelniania użytkownika`,
  [`Authenticate application users using`]: `Uwierzytelniaj użytkowników aplikacji przy użyciu`,
  [`Authenticate using Basic Authentication`]: `Uwierzytelnianie przy użyciu uwierzytelniania podstawowego`,
  [`Authenticate using OAuth`]: `Uwierzytelniaj przy użyciu protokołu OAuth`,
  [`Authenticated`]: `Uwierzytelnione`,
  [`Authenticated bind`]: `Uwierzytelnione powiązanie`,
  [`Authenticated User Name`]: `Uwierzytelniona nazwa użytkownika`,
  [`Authentication`]: `Uwierzytelnianie`,
  [`Authentication Bind`]: `Powiązanie uwierzytelniania`,
  [`Authentication error`]: `Błąd uwierzytelniania`,
  [`Authentication method`]: `Metoda uwierzytelniania`,
  [`Authentication TLS profile`]: `Profil TLS uwierzytelniania`,
  [`Authentication URL`]: `Adres URL uwierzytelniania`,
  [`Authentication URL user registry`]: `Rejestr użytkowników z adresem URL uwierzytelniania`,
  [`Authentication URL user registry has been created.`]: `Utworzono rejestr użytkowników z adresem URL uwierzytelniania.`,
  [`Authentication URL to use for validation.`]: `Adres URL uwierzytelniania, który ma być używany do sprawdzania poprawności.`,
  [`Authentication response header credential`]: `Referencja nagłówka odpowiedzi uwierzytelniania`,
  [`Authentication response header pattern`]: `Wzorzec nagłówka odpowiedzi uwierzytelniania`,
  [`Authentication type`]: `Typ uwierzytelniania`,
  [`Authentication type to use to validate the UsernameToken.`]: `Typ uwierzytelniania, który ma być używany do sprawdzania poprawności tokenu UsernameToken.`,
  [`Authorization`]: `Autoryzacja`,
  [`Authorization Code`]: `Kod autoryzacji`,
  [`Authorization URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Adres URL autoryzacji musi być zgodny z {endpoint}, tak jak zdefiniował to dostawca OAuth „{providerTitle}”`,
  [`Authorization endpoint`]: `Punkt końcowy autoryzacji`,
  [`Authorization Request`]: `Żądanie autoryzacji`,
  [`Authorization URL`]: `Adres URL autoryzacji`,
  [`Authorize`]: `Autoryzuj`,
  [`Authorize User Settings`]: `Ustawienia autoryzacji użytkownika`,
  [`Authorize application users using`]: `Autoryzuj użytkowników aplikacji przy użyciu`,
  [`Authorize path`]: `Ścieżka autoryzacji`,
  [`Authors API and product definitions`]: `Tworzy definicje produktów i interfejsów API`,
  [`Auto Generate OIDC API Assembly`]: `Automatycznie generuj zespół interfejsu API OIDC`,
  [`Auto onboard`]: `Automatyczne rejestrowanie`,
  [`Automatically-generated name for use in the API Connect management APIs and commands.`]: `Automatycznie wygenerowana nazwa przeznaczona do użycia w interfejsach API zarządzania i komendach produktu API Connect.`,
  [`Automation`]: `Automatyzacja`,
  [`Automation Management Console`]: `Konsola zarządzania automatyzacją`,
  [`Automation management console`]: `Konsola zarządzania automatyzacją`,
  [`Availability Zone`]: `Strefa dostępności`,
  [`Availability zone`]: `Strefa dostępności`,
  [`Availability zone {arg} has been removed.`]: `Strefa dostępności {arg} została usunięta.`,
  [`Availability zones allow you to group API Connect services to suit your business needs. For example, you can group gateway services according to the region or data center they are located in.`]: `Strefy dostępności umożliwiają grupowanie usług API Connect w celu ich dostosowania do potrzeb biznesowych użytkownika. Można na przykład pogrupować usługi bramy w zależności od regionu lub centrum przetwarzania danych, w którym się one znajdują.`,
  [`Average response time: {{value}}ms`]: `Średni czas odpowiedzi: {{value}} ms`,
  [`Average time`]: `Średni czas`,
  [`Avoid CORS errors by using the API Designer server as a local proxy.`]: `Uniknij błędów CORS, używając serwera API Designer jako lokalnego proxy.`,
  [`Back`]: `Wstecz`,
  [`Back to sign in`]: `Powrót do logowania`,
  [`Back to test`]: `Powrót do testu`,
  [`Backend type`]: `Typ zaplecza`,
  [`Bad gateway`]: `Błędna brama`,
  [`BadgerFish`]: `BadgerFish`,
  [`Base DN`]: `Podstawowa nazwa wyróżniająca`,
  [`Base Path`]: `Ścieżka podstawowa`,
  [`Base path`]: `Ścieżka podstawowa`,
  [`Base endpoint`]: `Podstawowy punkt końcowy`,
  [`Base endpoints`]: `Podstawowe punkty końcowe`,
  [`Base64 encoding`]: `Kodowanie Base64`,
  [`Base64 encoding for invitations and password reset tokens is **{base64Setting}**`]: `Kodowanie Base64 dla zaproszeń i tokenów resetowania hasła jest **{base64Setting}**`,
  [`Base64 encoding setting for temporary token has been changed`]: `Ustawienie kodowania Base64 dla tokenu tymczasowego zostało zmienione`,
  [`Base URL of API invocation endpoint`]: `Podstawowy adres URL punktu końcowego wywołania interfejsu API`,
  [`Basepath root`]: `Katalog główny ścieżki podstawowej`,
  [`Basic`]: `Podstawowe`,
  [`Basic authentication`]: `Podstawowe uwierzytelnianie`,
  [`Basic authentication password`]: `Hasło uwierzytelniania podstawowego`,
  [`Basic authentication request header name`]: `Hasło nagłówka żądania uwierzytelniania podstawowego`,
  [`Basic authentication username`]: `Nazwa użytkownika uwierzytelniania podstawowego`,
  [`Before you begin`]: `Przed rozpoczęciem`,
  [`Before you begin...`]: `Przed rozpoczęciem...`,
  [`Between 0 and 10 events are waiting to be processed`]: `Na przetworzenie oczekuje od 0 do 10 zdarzeń`,
  [`Between 10 and 20 events are waiting to be processed`]: `Na przetworzenie oczekuje od 10 do 20 zdarzeń`,
  [`Billing`]: `Rozliczenie`,
  [`Billing and Payment`]: `Rozliczenia i płatności`,
  [`Billing has been updated`]: `Rozliczanie zostało zaktualizowane`,
  [`Billing integrations`]: `Integracje rozliczeń`,
  [`Billing integration`]: `Integracja rozliczeń`,
  [`Billing Integration`]: `Integracja rozliczeń`,
  [`Billing {title} successfully added.`]: `Rozliczenie {title} zostało pomyślnie dodane.`,
  [`Billing {title} successfully deleted.`]: `Rozliczenie {title} została pomyślnie usunięte.`,
  [`Billing {title} successfully updated.`]: `Rozliczenie {title} zostało pomyślnie zaktualizowane.`,
  [`Billing Options`]: `Opcje rozliczania`,
  [`Blocklist`]: `Lista zablokowanych`,
  [`Blocking`]: `Blokowanie`,
  [`Blocking option`]: `Opcja blokowania`,
  [`Body`]: `Treść`,
  [`Boolean`]: `Wartość boolowska`,
  [`Both`]: `Oba`,
  [`Browse`]: `Przeglądaj`,
  [`Buffer API requests and responses before being processed on the DataPower API Gateway.`]: `Buforuj żądania i odpowiedzi API przed przetworzeniem w bramie DataPower API Gateway.`,
  [`Buffering`]: `Buforowanie`,
  [`Builds and manages apps in the developer organization`]: `Buduje aplikacje i zarządza nimi w organizacji twórców oprogramowania`,
  [`Burst Limit Name`]: `Nazwa limitu wywołań`,
  [`Burst limits`]: `Limity wywołań`,
  [`Burst limit value can't be negative`]: `Wartość limitu wywołań nie może być liczbą ujemną.`,
  [`Buy`]: `Kup`,
  [`but unable to remove because of limitation`]: `- ale nie można usunąć z powodu ograniczenia`,
  [`By configuring the visibility and subscribability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Konfigurując widoczność i możliwość subskrybowania produktu, kontroluje się dostępność jego interfejsów API dla twórców aplikacji w portalu Developer Portal. Można określić, którzy twórcy aplikacji będą widzieć produkt i którzy twórcy aplikacji mogą subskrybować użycie interfejsów API w tym produkcie.`,
  [`By configuring the visibility and subscribeability of a product, you control the availability of its APIs to application developers in the developer portal. You can control which application developers can see the product, and which application developers can subscribe to use the APIs in the product.`]: `Konfigurując widoczność i możliwość subskrybowania produktu, kontroluje się dostępność jego interfejsów API dla twórców aplikacji w portalu Developer Portal. Można określić, którzy twórcy aplikacji będą widzieć produkt i którzy twórcy aplikacji mogą subskrybować użycie interfejsów API w tym produkcie.`,
  [`By default, mapping from multiple sources (say array1 of length 2 and array2 of length 3) will result in a target array containing 5 items (2 by processing array1, and 3 by then processing array2). If you would prefer these rules to be combined (creating an array containing either 2 or 3 items with properties from each source array combined), then check this option.`]: `Domyślnie odwzorowanie z wielu źródeł (np. tablica1 o długości 2 i tablica2 o długości 3) spowoduje utworzenie tablicy docelowej zawierającej 5 pozycji (2 pozycje utworzone w wyniku przetworzenia tablicy1 i 3 pozycje utworzone w wyniku przetworzenia tablicy2). Zaznacz tę opcję, jeśli chcesz połączyć te reguły, tworząc tablicę zawierającą 2 lub 3 pozycje z połączonymi właściwościami z każdej tablicy źródłowej.`,
  [`By default, this product is published to all relevant gateway services. You can also publish to specific gateway services by enabling this option.`]: `Domyślnie ten produkt zostanie opublikowany we wszystkich usługach bramy. Włącz tę opcję, aby opublikować go w konkretnych usługach bramy.`,
  [`By payload`]: `Według ładunku`,
  [`By URL parameter`]: `Według parametru adresu URL`,
  [`Bytes`]: `B`,
  [`CALLS`]: `WYWOŁANIA`,
  [`Calls`]: `Wywołania`,
  [`CATALOG`]: `KATALOG`,
  [`CERTIFICATE NAME`]: `NAZWA CERTYFIKATU`,
  [`CIPHER`]: `SZYFR`,
  [`CIPHER SUITES`]: `ZESTAW ALGORYTMÓW SZYFROWANIA`,
  [`CLI`]: `CLI`,
  [`CLI only`]: `Tylko CLI`,
  [`CLI + LoopBack + API Designer`]: `CLI + LoopBack + API Designer`,
  [`Client ID`]: `Identyfikator klienta`,
  [`CLOUD ADMINISTRATOR`]: `ADMINISTRATOR CHMURY`,
  [`Consumer organization`]: `Organizacja konsumenta`,
  [`CONSUMER ORGANIZATION / GROUP`]: `ORGANIZACJA / GRUPA KONSUMENTA`,
  [`Consumer-Onboard-Approval`]: `Consumer-Onboard-Approval`,
  [`CORS`]: `CORS`,
  [`CA bundle`]: `Pakunek ośrodka CA`,
  [`Cache Key`]: `Klucz pamięci podręcznej`,
  [`Cache Time to Live (second)`]: `Czas życia pamięci podręcznej (sekundy)`,
  [`Cache key`]: `Klucz pamięci podręcznej`,
  [`Cache Time-To-Live`]: `Czas życia pamięci podręcznej`,
  [`Cache type`]: `Typ pamięci podręcznej`,
  [`Can't find the one you want?`]: `Nie można znaleźć potrzebnego elementu?`,
  [`Can't send an invite with invalid mail server, please check your mail server configurations and try again`]: `Nie można przesłać zaproszenia z powodu niepoprawnego serwera poczty? Sprawdź konfigurację serwera poczty i spróbuj ponownie`,
  [`Cancel`]: `Anuluj`,
  [`Cannot be below {min}`]: `Wartość nie może być mniejsza niż {min}.`,
  [`Cannot be empty`]: `Nie może być puste`,
  [`Cannot delete root type.`]: `Nie można usunąć typu głównego.`,
  [`Cannot exceed {max}`]: `Wartość nie może być większa niż {max}.`,
  [`Cannot find any APIs for this product`]: `Nie można znaleźć żadnych interfejsów API dla tego produktu`,
  [`Cannot find the one you want?`]: `Nie możesz znaleźć odpowiedniego rejestru użytkowników?`,
  [`Cannot use directive "@listSize" on field that does not return a list when also not definining "sizedFields".`]: `Nie można użyć dyrektywy "@listSize" względem pola, które nie zwraca listy, gdy nie jest zdefiniowana opcja "sizedFields".`,
  [`Cannot remove built-in scalar types`]: `Nie można usuwać wbudowanych typów skalarnych`,
  [`Cannot remove the query, mutation, and subscription root operation type`]: `Nie można usunąć operacji typu zapytanie, mutacja i element główny subskrypcji`,
  [`Cannot remove arguments of built-in directives`]: `Nie można usuwać argumentów dyrektyw wbudowanych`,
  [`Cannot remove non-null arguments of directives`]: `Nie można usuwać argumentów dyrektyw różnych od null`,
  [`Cannot remove input types of arguments of directives`]: `Nie można usuwać typów wejściowych argumentów dyrektyw`,
  [`Cannot remove enum values used as default values of arguments of directives`]: `Nie można usuwać wartości wyliczeniowych używanych jako domyślne wartości argumentów dyrektyw`,
  [`Cannot remove enum values used as default values of input fields`]: `Nie można usuwać wartości wyliczeniowych używanych jako domyślne wartości pól wejściowych`,
  [`Cannot remove all fields of an object type`]: `Nie można usunąć wszystkich pól typu obiekt`,
  [`Cannot remove all fields of an interface type`]: `Nie można usunąć wszystkich pól typu interfejs`,
  [`Cannot remove all slicing arguments`]: `Nie można usunąć wszystkich argumentów przekrojów`,
  [`Cannot remove all input fields of an input object type`]: `Nie można usunąć wszystkich pól typu wejściowego obiekt`,
  [`Cannot remove all values of enum type`]: `Nie można usunąć wszystkich wartości typu wyliczeniowego`,
  [`Cannot remove fields of interfaces`]: `Nie można usuwać pól interfejsów`,
  [`Cannot remove non-null input fields`]: `Nie można usuwać pól wejściowych różnych od null`,
  [`Case`]: `Przypadek`,
  [`Case sensitive`]: `Rozróżniaj wielkość liter`,
  [`Catalog`]: `Katalog`,
  [`Catalog defaults`]: `Wartości domyślne katalogów`,
  [`Catalog Invite`]: `Zaproszenie do katalogu`,
  [`Catalog invite`]: `Zaproszenie do katalogu`,
  [`Catalog invites`]: `Zaproszenia do katalogu`,
  [`Catalog name`]: `Nazwa katalogu`,
  [`Catalog owner`]: `Właściciel katalogu`,
  [`Catalog owner invitation`]: `Zaproszenie właściciela katalogu`,
  [`Catalog properties`]: `Właściwości katalogu`,
  [`Catalog summary`]: `Podsumowanie katalogu`,
  [`Catalog title`]: `Tytuł katalogu`,
  [`Catalog user registries`]: `Rejestry użytkowników katalogu`,
  [`Catalog by Name`]: `Kataloguj wg nazwy`,
  [`Catalog priority (use vanity endpoint(s) defined by the catalog administrator)`]: `Priorytet katalogu (używaj niestandardowych punktów końcowych zdefiniowanych przez administratora katalogu)`,
  [`Catalog {name} created`]: `Katalog {name} został utworzony.`,
  [`Catalog selection`]: `Wybór katalogu`,
  [`Catalog settings`]: `Ustawienia katalogu`,
  [`Catalog settings have been updated`]: `Zaktualizowano ustawienia katalogu`,
  [`Catalog User`]: `Użytkownik katalogu`,
  [`Catalog Users`]: `Użytkownicy katalogu`,
  [`Catalog user registry`]: `Rejestr użytkowników katalogu`,
  [`Catalog with LifeCycle enabled cannot be used in Test Preferences.`]: `W preferencjach testowania nie można używać katalogu z włączonym cyklem życia.`,
  [`Catalog with lifeCycle and production mode enabled cannot be used in Test Preferences.`]: `Nie można używać katalogu z włączonym trybem życia i trybem produkcyjnym w preferencjach testowych.`,
  [`Catalogs`]: `Katalogi`,
  [`Catch`]: `Element wychwycony`,
  [`Catches`]: `Elementy wychwycone`,
  [`Categories`]: `Kategorie`,
  [`Categories establish a hierarchical display of API products in the developer portal. Use the following syntax: top_level_element/next_level_element/lower_level_element.`]: `Kategorie ustanawiają hierarchiczną reprezentację produktów API wyświetlanych w portalu Developer Portal. Należy stosować następującą składnię: element_najwyższego_poziomu/element_następnego_poziomu/element_niższego_poziomu.`,
  [`Category`]: `Kategoria`,
  [`Certifcate or Shared Secret for Verify`]: `Certyfikat lub współużytkowane dane niejawne do weryfikacji`,
  [`Certificate`]: `Certyfikat`,
  [`Certificate (Optional)`]: `Certyfikat (opcjonalnie)`,
  [`Certificate management`]: `Zarządzanie certyfikatami`,
  [`Certificate Manager instance`]: `Instancja menedżera certyfikatów`,
  [`Certificate (optional)`]: `Certyfikat (opcjonalnie)`,
  [`Certificates`]: `Certyfikaty`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Certificate Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire. To get started,`]: `Certyfikaty są wymagane do zabezpieczania połączeń między bramą a obsługiwanymi przez nią domenami. Wszystkie certyfikaty muszą być przechowywane w usłudze Certificate Manager. Usługa ta udostępnia bezpieczne repozytorium dla certyfikatów i pomaga zapobiegać przestojom, wysyłając powiadomienia o zbliżających się terminach ważności certyfikatów. Aby rozpocząć:`,
  [`Certificates details in this keystore`]: `Szczegóły certyfikatów w tym magazynie kluczy`,
  [`Certificates details in this truststore`]: `Szczegóły certyfikatów w tym magazynie zaufanych certyfikatów`,
  [`Change`]: `Zmień`,
  [`Change Consumer Organization owner to an existing user`]: `Zmiana właściciela organizacji konsumenta na istniejącego użytkownika`,
  [`Change Ownership to an Existing User`]: `Zmiana prawa własności na istniejącego użytkownika`,
  [`Change portal service`]: `Zmień usługę portalu`,
  [`Change owner to existing user`]: `Zmiana właściciela na istniejącego użytkownika`,
  [`Change owner to new user`]: `Zmiana właściciela na nowego użytkownika`,
  [`Change ownership by inviting a new user`]: `Zmień właściciela, zapraszając nowego użytkownika`,
  [`Change ownership to`]: `Zmień właściciela na`,
  [`Change ownership to a new user via email invitation`]: `Zmień właściciela na nowego użytkownika za pośrednictwem wiadomości e-mail z zaproszeniem`,
  [`Change ownership to an existing user`]: `Zmień właściciela na istniejącego użytkownika`,
  [`Change password`]: `Zmiana hasła`,
  [`Change setup`]: `Zmień konfigurację`,
  [`Changes that you make directly to the API or Assembly that underlies an OAuth provider might render the OAuth provider invalid if you do not also update any corresponding values in the definition of the OAuth provider itself. If you save these changes, ensure that you also make any corresponding updates in the OAuth provider definition.`]: `Zmiany wprowadzane bezpośrednio do interfejsu API lub elementu złożonego, na którym opiera się dostawca OAuth, mogą spowodować niepoprawne wyświetlenie dostawcy OAuth, jeśli nie zostaną również zaktualizowane odpowiednie wartości w definicji samego dostawcy OAuth. Po zapisaniu tych zmian sprawdź, czy wprowadzono również odpowiednie aktualizacje w definicji dostawcy OAuth.`,
  [`Change the following extra constraints in the schema:`]: `Zmień następujące dodatkowe ograniczenia w schemacie:`,
  [`Changing the Certificate Manager will remove all existing certificate and CA bundle assignments.`]: `Zmiana usługi Certificate Manager spowoduje usunięcie wszystkich istniejących przypisań certyfikatów i pakunków CA.`,
  [`Changing the DNS scheme will change the format of the URL links to API Connect and the Developer Portal. You will need to communicate the new links to users. Click Cancel if you do not want to proceed.`]: `Zmiana schematu DNS spowoduje zmianę formatu odsyłaczy URL do produktu API Connect i portalu Developer Portal. Konieczne będzie powiadomienie użytkowników o nowych odsyłaczach. Kliknij przycisk Anuluj, jeśli nie chcesz kontynuować.`,
  [`Changing the name or version of an API will create a new API. Are you sure you want to save your changes?`]: `Zmiana nazwy lub wersji interfejsu API spowoduje utworzenie nowego interfejsu API. Czy na pewno zapisać wprowadzone zmiany?`,
  [`Check your email`]: `Sprawdź pocztę elektroniczną`,
  [`Child`]: `Element potomny`,
  [`Chinese (Simplified)`]: `chiński (uproszczony)`,
  [`Chinese (Traditional)`]: `chiński (tradycyjny)`,
  [`Choose`]: `Wybierz`,
  [`Choose a`]: `Wybierz`,
  [`Choose {docTypeLabel}`]: `Wybierz dokument: {docTypeLabel}`,
  [`Choose Catalog`]: `Wybierz katalog`,
  [`Choose a Catalog`]: `Wybierz katalog`,
  [`Choose a Consumer Org`]: `Wybierz organizację konsumenta`,
  [`Choose Gateway`]: `Wybierz bramę`,
  [`Choose a Gateway`]: `Wybierz bramę`,
  [`Choose Product`]: `Wybierz produkt`,
  [`Choose a Product`]: `Wybierz produkt`,
  [`Choose Rate Limit`]: `Wybierz limit szybkości`,
  [`Choose a Rate Limit`]: `Wybierz limit szybkości`,
  [`Choose Application`]: `Wybierz aplikację`,
  [`Choose an Application`]: `Wybierz aplikację`,
  [`Choose a {docTypeLabel}`]: `Wybierz: {docTypeLabel}`,
  [`Choose a publish destination`]: `Wybierz miejsce docelowe publikowania`,
  [`Choose a billing integration`]: `Wybierz integrację rozliczeń`,
  [`Choose a catalog to test within:`]: `Wybierz katalog, w którym zostanie przeprowadzony test:`,
  [`Choose a plan against which to test:`]: `Wybierz plan, według którego ma zostać przeprowadzony test:`,
  [`Choose a product containing this API, or create a new one:`]: `Wybierz produkt zawierający ten interfejs API lub utwórz nowy:`,
  [`Choose a user registry`]: `Wybierz rejestr użytkowników`,
  [`Choose an application with which to test, or create a new one:`]: `Wybierz aplikację, za pomocą której ma zostać przeprowadzony test, lub utwórz nową:`,
  [`Choose an existing application`]: `Wybierz istniejącą aplikację`,
  [`Choose an existing catalog`]: `Wybierz istniejący katalog`,
  [`Choose an existing product published to your Sandbox catalog`]: `Wybierz istniejący produkt opublikowany w katalogu środowiska testowego`,
  [`Choose an operation to invoke:`]: `Wybierz operację do wywołania:`,
  [`Choose an option`]: `Wybierz opcję`,
  [`Choose an option to determine how the map policy handles empty array output. The choice of all (the default choice) will output all empty arrays and empty children arrays. The choice of parent will output only the parent empty array. The choice of none will not output the empty array.`]: `Wybierz opcję, aby określić, w jaki sposób strategia odwzorowania obsługuje puste dane wyjściowe tablicy. Wybór wszystkich (wybór domyślny) spowoduje wyprowadzenie wszystkich pustych tablic i pustych tablic potomnych. Wybór elementu nadrzędnego spowoduje wyprowadzenie tylko nadrzędnej tablicy pustej. Niewybranie żadnej opcji nie spowoduje zwrócenia pustej tablicy.`,
  [`Choosing blocking mode will have adverse effects on the system performance`]: `Wybór trybu blokowania niekorzystnie wpłynie na wydajność systemu`,
  [`Choose existing directory`]: `Wybierz istniejący katalog`,
  [`Choose one...`]: `Wybierz jedną z opcji...`,
  [`Choose operations and paths to generate into this API`]: `Wybierz operacje i ścieżki, które mają zostać wygenerowane w tym interfejsie API`,
  [`Choose paths to generate into this API`]: `Wybierz ścieżki, które mają zostać wygenerowane w tym interfejsie API`,
  [`Choose the DNS scheme`]: `Wybór schematu DNS`,
  [`Choose the permissions for the role.`]: `Wybierz uprawnienia dla roli.`,
  [`Choose whether to use dynamic or static DNS addresses for inbound API calls to the gateway service and for accessing the developer portal.`]: `Wybierz, czy na potrzeby przychodzących wywołań interfejsu API do usługi bramy oraz do uzyskiwania dostępu do portalu Developer Portal mają być używane dynamiczne, czy statyczne adresy DNS.`,
  [`Cipher`]: `Szyfr`,
  [`Ciphers`]: `Szyfry`,
  [`Clear`]: `Wyczyść`,
  [`Clear constraints`]: `Wyczyść ograniczenia`,
  [`Clear file`]: `Wyczyść plik`,
  [`Clear filters`]: `Wyczyść filtry`,
  [`Clear from selected`]: `Usuń z wybranych`,
  [`Clear operation filter`]: `Wyczyść filtr operacji`,
  [`Click a certificate to view details regarding its issuing organization and fingerprint.`]: `Kliknij certyfikat, aby wyświetlić szczegółowe informacje dotyczące organizacji, która go wydała, oraz odcisku.`,
  [`Click the link below to continue authorizing APIs in another tab and you will get the Authorization code for step 2.`]: `Kliknij poniższy odsyłacz, aby kontynuować autoryzowanie interfejsów API na innej karcie, a otrzymasz kod autoryzacji dla kroku 2.`,
  [`Clicking the link below will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Kliknięcie poniższego odsyłacza spowoduje otwarcie serwera na nowej karcie. Jeśli przeglądarka wyświetli komunikat o problemie z certyfikatem, możesz go zaakceptować i wrócić tutaj, aby ponownie wykonać test.`,
  [`Click add to add a billing integration.`]: `Kliknij opcję Dodaj, aby dodać integrację rozliczeń.`,
  [`Click Add to add a gateway extension.`]: `Kliknij przycisk Dodaj, aby dodać rozszerzenie bramy.`,
  [`Click **Add domain** to add domains.`]: `Kliknij opcję **Dodaj domenę**, aby dodać domeny.`,
  [`Click Add to add a new base endpoint.`]: `Kliknij przycisk Dodaj, aby dodać nowy podstawowy punkt końcowy.`,
  [`Click Add to add a new HTTP header.`]: `Kliknij przycisk Dodaj, aby dodać nowy nagłówek HTTP.`,
  [`Click Add to add a new member.`]: `Kliknij przycisk Dodaj, aby dodać nowego członka.`,
  [`Click Add to add a new role.`]: `Kliknij przycisk Dodaj, aby dodać nową rolę.`,
  [`Click Add to add a new scope.`]: `Kliknij przycisk Dodaj, aby dodać nowy zasięg.`,
  [`Click Add to add a new consumer organization group.`]: `Kliknij przycisk Dodaj, aby dodać nową grupę organizacji konsumenta.`,
  [`Click Add to create the API Key.`]: `Kliknij przycisk Dodaj, aby utworzyć klucz interfejsu API.`,
  [`Click Create to add the API Key.`]: `Kliknij przycisk Utwórz, aby dodać klucz interfejsu API.`,
  [`Click Create to add a new user registry.`]: `Kliknij przycisk Utwórz, aby dodać nowy rejestr użytkowników.`,
  [`Click Create user registry to add a new user registry.`]: `Kliknij opcję Tworzenie rejestru użytkowników, aby dodać nowy rejestr użytkowników.`,
  [`Click **Create provider organization** to add provider organizations.`]: `Kliknij opcję **Utwórz organizację dostawcy**, aby dodać organizacje dostawców.`,
  [`Click Edit to add OAuth providers.`]: `Kliknij przycisk Edytuj, aby dodać dostawców OAuth.`,
  [`Click Edit to add a user registry.`]: `Kliknij przycisk Edytuj, aby dodać rejestr użytkowników.`,
  [`Click Edit to add an API to the product.`]: `Kliknij przycisk Edytuj, aby dodać interfejs API do produktu.`,
  [`Click Create to add a property.`]: `Kliknij przycisk Utwórz, aby dodać właściwość.`,
  [`Click Edit to add TLS client profiles.`]: `Kliknij przycisk Edytuj, aby dodać profile klienta TLS.`,
  [`Click Edit to add user registries.`]: `Kliknij przycisk Edytuj, aby dodać rejestry użytkowników.`,
  [`Click Add to add a new consumer organization.`]: `Kliknij przycisk Dodaj, aby dodać nową organizację konsumenta.`,
  [`Click Consumer organizations to add one.`]: `Kliknij opcję Organizacje konsumenta, aby dodać taką organizację.`,
  [`Click Add to add a new application.`]: `Kliknij przycisk Dodaj, aby dodać nową aplikację.`,
  [`Click Add to add parameters.`]: `Kliknij przycisk Dodaj, aby dodać parametry.`,
  [`Click Add to add response.`]: `Kliknij przycisk Dodaj, aby dodać odpowiedź.`,
  [`Click Create to add a new TLS client profile.`]: `Kliknij przycisk Utwórz, aby dodać nowy profil klienta TLS.`,
  [`Click Create to add a new keystore.`]: `Kliknij przycisk Utwórz, aby dodać nowy magazyn kluczy.`,
  [`Click Create to add a new truststore.`]: `Kliknij przycisk Utwórz, aby dodać nowy magazyn zaufanych certyfikatów.`,
  [`Click **Register remote gateway** to add gateways.`]: `Kliknij opcję **Zarejestruj bramę zdalną**, aby dodać bramy.`,
  [`Click Register gateways to add new Gateway Services.`]: `Kliknij opcję Zarejestruj bramy, aby dodać nowe usługi bramy.`,
  [`Click Edit to add Gateway Services.`]: `Kliknij przycisk Edytuj, aby dodać usługi bramy.`,
  [`Click Register gateways to add new gateway services.`]: `Kliknij opcję Zarejestruj bramy, aby dodać nowe usługi bramy.`,
  [`Click Edit to add gateway services.`]: `Kliknij przycisk Edytuj, aby dodać usługi bramy.`,
  [`Click Edit to add an email server.`]: `Kliknij przycisk Edytuj, aby dodać serwer poczty elektronicznej.`,
  [`Click Create to add a new TLS server profile.`]: `Kliknij przycisk Utwórz, aby dodać nowy profil serwera TLS.`,
  [`Click Create to add a new email server.`]: `Kliknij przycisk Utwórz, aby dodać nowy serwer poczty elektronicznej.`,
  [`Click Add to add a new schema definition.`]: `Kliknij przycisk Dodaj, aby dodać nową definicję schematu.`,
  [`Click Add to add a new property for this definition.`]: `Kliknij przycisk Dodaj, aby dodać nową właściwość dla tej definicji.`,
  [`Click Add to add a new parameter for this API.`]: `Kliknij przycisk Dodaj, aby dodać nowy parametr dla tego interfejsu API.`,
  [`Click Add to add tags and external documentation for this API.`]: `Kliknij przycisk Dodaj, aby dodać znaczniki i dokumentację zewnętrzną dla tego interfejsu API.`,
  [`Click Add to add a new path.`]: `Kliknij przycisk Dodaj, aby dodać nową ścieżkę.`,
  [`Click Add to add a property for this API.`]: `Kliknij przycisk Dodaj, aby dodać właściwość dla tego interfejsu API.`,
  [`Click here to create a security definition`]: `Kliknij tutaj, aby utworzyć definicję zabezpieczeń`,
  [`Click Add to add a new security definition.`]: `Kliknij przycisk Dodaj, aby dodać nową definicję zabezpieczeń.`,
  [`Click Add to add a target service for this API.`]: `Kliknij przycisk Dodaj, aby dodać usługę docelową dla tego interfejsu API.`,
  [`Click Add to add an operation for this path.`]: `Kliknij przycisk Dodaj, aby dodać operację dla tej ścieżki.`,
  [`Click Add to add a path parameter for this path.`]: `Kliknij przycisk Dodaj, aby dodać parametr ścieżki dla tej ścieżki.`,
  [`Click Add to add a new OAuth provider.`]: `Kliknij przycisk Dodaj, aby dodać nowego dostawcę OAuth.`,
  [`Click Add to add a new provider organization.`]: `Kliknij przycisk Dodaj, aby dodać nową organizację dostawcy.`,
  [`Click Add to add a new API or product.`]: `Kliknij przycisk Dodaj, aby dodać nowy interfejs API lub produkt.`,
  [`Click Add to add a new API.`]: `Kliknij przycisk Dodaj, aby dodać nowy interfejs API.`,
  [`Click Add to add a new Extension.`]: `Kliknij przycisk Dodaj, aby dodać nowe rozszerzenie.`,
  [`Click Add to add new {resource}.`]: `Kliknij przycisk Dodaj, aby dodać: {resource}.`,
  [`Click to expand`]: `Kliknij, aby rozwinąć`,
  [`Click to collapse`]: `Kliknij, aby zwinąć`,
  [`Click to learn how to upload and manage the certificates required by the gateway.`]: `Kliknij, aby dowiedzieć się, jak przesyłać certyfikaty wymagane przez bramę i zarządzać nimi.`,
  [`Client Credentials`]: `Referencje klienta`,
  [`Client information`]: `Informacje o kliencie`,
  [`Client revocation path`]: `Ścieżka odwołania klienta`,
  [`Click Register service to register a service. This will allow you to test endpoints when configuring other services.`]: `Kliknij opcję Zarejestruj usługę, aby zarejestrować usługę. Pozwoli to na testowanie punktów końcowych podczas konfigurowania innych usług.`,
  [`Client secret`]: `Dane niejawne klienta`,
  [`Client security`]: `Zabezpieczenia klienta`,
  [`Client Type`]: `Typ klienta`,
  [`Client authentication method`]: `Metoda uwierzytelniania klienta`,
  [`Clone`]: `Klonuj`,
  [`Close`]: `Zamknij`,
  [`Cloud`]: `Chmura`,
  [`Cloud Admin`]: `Administrowanie chmurą`,
  [`Cloud Administrator`]: `Administrator chmury`,
  [`Cloud Administrator, Organization Manager, Topology Administrator. The Member role is automatically assigned to all users.`]: `Administrator chmury, menedżer organizacji, administrator topologii. Rola członka jest automatycznie przypisywana do wszystkich użytkowników.`,
  [`Cloud administration`]: `Administrowanie chmurą`,
  [`Cloud Discovery Agent`]: `Agent wykrywania w chmurze`,
  [`Cloud Manager`]: `Cloud Manager`,
  [`Cloud Manager Local User Registry`]: `Lokalny rejestr użytkowników programu Cloud Manager`,
  [`Cloud-Settings`]: `Ustawienia chmury`,
  [`Cloud settings invitation timeout`]: `Limit czasu zaproszenia w ustawieniach chmury`,
  [`Cloud settings invitation timeout has been changed`]: `Limit czasu zaproszenia w ustawieniach chmury został zmieniony`,
  [`Code editor`]: `Edytor kodu`,
  [`Collect Metadata`]: `Gromadź metadane`,
  [`Collect credentials using`]: `Gromadź referencje przy użyciu`,
  [`Collect metadata`]: `Gromadź metadane`,
  [`Combo box`]: `Pole złożone`,
  [`Community Manager`]: `Menedżer społeczności`,
  [`community-manager`]: `menedżer społeczności`,
  [`Compatibility`]: `Kompatybilność`,
  [`Complex Definitions must be edited in the editor`]: `Definicje złożone muszą być edytowane w edytorze`,
  [`Compose DN`]: `Utwórz nazwę DN`,
  [`Compose UPN`]: `Utwórz nazwę UPN`,
  [`Compose a new REST proxy by defining paths and operations`]: `Utwórz nowe proxy usług REST, definiując ścieżki i operacje`,
  [`Compose a new product by adding rate limits and plans`]: `Utwórz nowy produkt, dodając limity częstotliwości i plany`,
  [`Compression`]: `Kompresja`,
  [`Condition`]: `Warunek`,
  [`Condition editor`]: `Edytor warunków`,
  [`Confidential`]: `Poufne`,
  [`Configuration`]: `Konfiguracja`,
  [`Configurations`]: `Konfiguracje`,
  [`Configure API endpoint`]: `Skonfiguruj punkt końcowy interfejsu API`,
  [`Configure API Gateway Service`]: `Konfigurowanie usługi bramy interfejsu API`,
  [`Configure analytics service`]: `Skonfiguruj usługę analizy danych`,
  [`Configure availability zone`]: `Skonfiguruj strefę dostępności`,
  [`Configure catalog user registries`]: `Skonfiguruj rejestry użytkowników katalogu`,
  [`Configure cloud`]: `Skonfiguruj chmurę`,
  [`Configure DataPower API gateway service`]: `Skonfiguruj usługę bramy interfejsu API DataPower`,
  [`Configure DataPower gateway service`]: `Skonfiguruj usługę bramy DataPower`,
  [`Configure Email Server`]: `Konfigurowanie serwera poczty elektronicznej`,
  [`Configure gateway extension`]: `Skonfiguruj rozszerzenie bramy`,
  [`Configure portal service`]: `Skonfiguruj usługę portalu`,
  [`Configure service`]: `Skonfiguruj usługę`,
  [`Configure topology`]: `Skonfiguruj topologię`,
  [`Configure XML`]: `Konfiguracja formatu XML`,
  [`Configure a DataPower API gateway service for securing and enforcing APIs`]: `Konfigurowanie usługi bramy interfejsu API DataPower na potrzeby zabezpieczania i wymuszania interfejsów API`,
  [`Configure a DataPower gateway service for securing and enforcing APIs`]: `Konfigurowanie usługi bramy DataPower na potrzeby zabezpieczania i wymuszania interfejsów API`,
  [`Configure a developer portal service for API consumers`]: `Konfigurowanie usługi portalu Developer Portal dla konsumentów interfejsów API`,
  [`Configure advanced features for OIDC settings`]: `Skonfiguruj funkcje zaawansowane w ustawieniach OIDC`,
  [`Configure an analytics service to collect API call data`]: `Konfigurowanie usługi analizy danych służącej do gromadzenia danych dotyczących wywołań interfejsów API`,
  [`Configure an email server to send invitations and notifications to users.`]: `Skonfiguruj serwer poczty elektronicznej pod kątem wysyłania zaproszeń i powiadomień do użytkowników.`,
  [`Configure auditing to monitor the operations of Admin UI, Manager UI, Toolkit, Management REST API, and Portal users. Logs of each create, update, and delete user operation are sent to the remote logging services specified below.`]: `Konfigurowanie kontroli w celu monitorowania operacji interfejsu użytkownika administracyjnego, interfejsu menedżera, biblioteki narzędziowej, interfejsu API usług REST zarządzania oraz użytkowników portalu. Rejestruje każdą operację tworzenia, aktualizowania i usuwania użytkowników wysyłaną do zdalnych usług rejestrowania określonych poniżej.`,
  [`Configure availability zones and services`]: `Skonfiguruj strefy dostępności i usługi.`,
  [`Configure how API endpoint URLs are composed in published APIs`]: `Skonfiguruj sposób, w jaki budowane są adresy URL punktów końcowych interfejsu API w opublikowanych interfejsach API`,
  [`Configure how vanity endpoints are displayed in the developer portal`]: `Skonfiguruj sposób wyświetlania niestandardowych punktów końcowych w portalu Developer Portal`,
  [`Configure mapping`]: `Skonfiguruj odwzorowania`,
  [`Configure properties of the activity log`]: `Skonfiguruj właściwości dziennika działań`,
  [`Configure settings for token management and revocation.`]: `Skonfiguruj ustawienia zarządzania tokenami i ich unieważniania.`,
  [`Configure settings to generate and validate tokens.`]: `Skonfiguruj ustawienia generowania i sprawdzania poprawności tokenów.`,
  [`Configure the API security`]: `Konfigurowanie zabezpieczeń interfejsu API`,
  [`Configure the default set of gateway services configured for each catalog`]: `Skonfiguruj domyślny zestaw usług bramy skonfigurowanych na potrzeby poszczególnych katalogów.`,
  [`Configure the developer portal that is used by application developers to access the APIs in this catalog`]: `Skonfiguruj portal Developer Portal używany przez twórców aplikacji w celu uzyskiwania dostępu do interfejsów API w tym katalogu.`,
  [`Configure the gateway services used by default in each catalog`]: `Skonfiguruj usługi bramy używane domyślnie w poszczególnych katalogach.`,
  [`Configure the gateway extension for the selected gateway`]: `Skonfiguruj rozszerzenie bramy dla wybranej bramy`,
  [`Configure the IBM-managed DataPower API gateway and manage your own remote gateways. [Learn more]({link})`]: `Skonfiguruj bramę IBM DataPower API zarządzaną przez IBM i zarządzaj własnymi bramami zdalnymi. [Więcej informacji]({link})`,
  [`Configure the keystore and upload certificates`]: `Skonfiguruj magazyn kluczy i prześlij certyfikaty.`,
  [`Configure the name and email address to be used in the from field of emails`]: `Skonfiguruj nazwę i adres e-mail, które mają być używane w polu Od wiadomości e-mail`,
  [`Configure the portal service for the catalog`]: `Skonfiguruj usługę portalu na potrzeby katalogu.`,
  [`Configure the role and assign permissions`]: `Skonfiguruj rolę i przypisz uprawnienia.`,
  [`Configure the security of this API`]: `Skonfiguruj zabezpieczenia tego interfejsu API.`,
  [`Configure the sender name and address to use for email notifications`]: `Skonfiguruj nazwę i adres nadawcy do użycia na potrzeby powiadomień e-mail`,
  [`Configure the sender name, address and the email server used to send invitations and notifications to users`]: `Skonfiguruj nazwę i adres nadawcy oraz serwer poczty elektronicznej używany do wysyłania zaproszeń i powiadomień do użytkowników`,
  [`Configure the set of roles to use by default when a consumer organization is created`]: `Skonfiguruj zestaw ról, które mają być domyślnie używane podczas tworzenia organizacji konsumenta.`,
  [`Configure the set of roles to use by default when a provider organization is created`]: `Skonfiguruj zestaw ról, które mają być domyślnie używane podczas tworzenia organizacji dostawcy.`,
  [`Configure the templates used to invite and notify users`]: `Skonfiguruj szablony używane do zapraszania i powiadamiania użytkowników`,
  [`Configure the truststore and upload certificates`]: `Skonfiguruj magazyn zaufanych certyfikatów i prześlij certyfikaty.`,
  [`Configure user authentication using`]: `Skonfiguruj uwierzytelnianie użytkownika z użyciem`,
  [`Configure user authentication using JSON Web Tokens`]: `Skonfiguruj uwierzytelnianie użytkowników przy użyciu tokenów JSON Web Token`,
  [`Configure user authentication using a LDAP provider`]: `Konfigurowanie uwierzytelniania użytkownika przy użyciu dostawcy LDAP`,
  [`Configure user authentication using an API Connect Local User Registry`]: `Konfigurowanie uwierzytelniania użytkownika przy użyciu lokalnego rejestru użytkowników produktu API Connect`,
  [`Configure user authentication using an authentication URL`]: `Konfigurowanie uwierzytelniania użytkownika przy użyciu adresu URL uwierzytelniania`,
  [`Configure user authentication using this user registry type`]: `Skonfiguruj uwierzytelnianie użytkowników przy użyciu tego typu rejestru użytkowników`,
  [`Configure user registries, OAuth providers and TLS`]: `Skonfiguruj rejestry użytkowników, dostawców OAuth i protokół TLS`,
  [`Configure user registries, TLS, OAuth providers and email servers`]: `Służy do konfigurowania rejestrów użytkowników, protokołu TLS, dostawców OAuth i serwerów poczty.`,
  [`Configure Keystore for Access token, ID token and Temporary token`]: `Skonfiguruj magazyn kluczy dla tokenu dostępu, tokenu identyfikatora i tokenu tymczasowego`,
  [`Configured OAuth Provider`]: `Skonfigurowany dostawca OAuth`,
  [`Configured OAuth Providers`]: `Skonfigurowani dostawcy OAuth`,
  [`Configured OAuth provider`]: `Skonfigurowany dostawca OAuth`,
  [`Configured OAuth provider list has been updated`]: `Zaktualizowano listę skonfigurowanych dostawców OAuth`,
  [`Configured OAuth provider list has not been updated`]: `Lista skonfigurowanych dostawców OAuth nie została zaktualizowana`,
  [`Confirm`]: `Potwierdź`,
  [`Confirm deletion`]: `Potwierdź usunięcie`,
  [`Confirm Deletion of Portal`]: `Potwierdzenie usunięcia portalu`,
  [`Confirm visibility settings`]: `Potwierdź ustawienia widoczności`,
  [`Confirm new password`]: `Potwierdź nowe hasło`,
  [`Confirm password`]: `Potwierdź hasło`,
  [`Confirm password should match password`]: `Potwierdzenie hasła powinno być zgodne z hasłem`,
  [`Confirmation`]: `Potwierdzenie`,
  [`Congratulations, you are now registered.`]: `Gratulacje, jesteś zarejestrowanym użytkownikiem.`,
  [`Congratulations, your password has been reset!`]: `Gratulacje, hasło zostało zresetowane.`,
  [`Connect`]: `Połącz`,
  [`Connect to API Connect`]: `Nawiąż połączenie z produktem API Connect`,
  [`Connected to API Manager Product version`]: `Połączono z produktem API Manager w wersji`,
  [`Connect to Cloud`]: `Nawiąż połączenie z chmurą`,
  [`Connected`]: `Połączono`,
  [`Construct your schema definition by adding properties of the required types. You can nest properties`]: `Skonstruuj definicję schematu, dodając właściwości wymaganych typów. Właściwości można zagnieżdżać`,
  [`Consider for nesting`]: `Rozważ zagnieżdżenie`,
  [`Consumed Media Types`]: `Wykorzystywane typy danych multimedialnych`,
  [`Consumer`]: `Konsument`,
  [`Consumer invitation and roles`]: `Zaproszenie i role dla konsumentów`,
  [`Consumer onboarding`]: `Rejestrowanie konsumenta`,
  [`Consumer organizations`]: `Organizacje konsumentów`,
  [`Consumer organizations or groups`]: `Organizacje konsumenta lub grupy`,
  [`Consumer organization {name} created`]: `Utworzono organizację konsumenta {name}`,
  [`Consumer organizations are created by application developers in the Developer Portal, and you can also create consumer organizations by using the API Manager UI`]: `Organizacje konsumentów są tworzone przez twórców aplikacji w portalu Developer Portal. Organizacje konsumentów można także tworzyć za pomocą interfejsu użytkownika produktu API Manager.`,
  [`Consumer organizations are created by application developers in the developer portal, and you can also create consumer organizations here`]: `Organizacje konsumentów są tworzone przez twórców aplikacji w portalu Developer Portal; w tym miejscu można także tworzyć organizacje konsumentów`,
  [`Consumer-Org`]: `Organizacja konsumenta`,
  [`Consumers`]: `Konsumenci`,
  [`Consumes`]: `Używa`,
  [`Contact`]: `Kontakt`,
  [`Contact information for the owners of the API.`]: `Informacje kontaktowe dla właścicieli interfejsu API.`,
  [`Contact your administrator about configuring the analytics service so you can view your data here.`]: `Skontaktuj się z administratorem w sprawie skonfigurowania usługi analizy, aby można było tutaj przeglądać dane.`,
  [`Contact your administrator for more information.`]: `Skontaktuj się z administratorem, aby uzyskać dodatkowe informacje.`,
  [`Content`]: `Treść`,
  [`Content on error`]: `Treść w przypadku błędu`,
  [`Content type`]: `Typ treści`,
  [`Context Variable`]: `Zmienna kontekstowa`,
  [`Context or runtime variable that contains the JWT to be validated. If not set, the policy looks for the JWT in request.headers.authorization.`]: `Kontekst lub zmienna środowiska wykonawczego zawierająca znacznik JWT, którego poprawność ma zostać sprawdzona. Jeśli nie zostanie ustawiona, strategia szuka znacznika JWT we właściwości request.headers.authorization.`,
  [`Context variable`]: `Zmienna kontekstu`,
  [`Continue`]: `Kontynuuj`,
  [`Continue on error`]: `Kontynuuj w razie błędu`,
  [`Continue with`]: `Kontynuuj przy użyciu`,
  [`Continue with:`]: `Kontynuuj z:`,
  [`Conversion type`]: `Typ konwersji`,
  [`Convert to expression`]: `Przekształć w wyrażenie`,
  [`Cookie`]: `Informacja cookie`,
  [`Copied`]: `Skopiowano`,
  [`Copied to clipboard`]: `Skopiowano do schowka`,
  [`Copy`]: `Kopiuj`,
  [`Copy Id Headers To Message`]: `Kopiuj Nagłówki Identyfikatorów Do Komunikatu`,
  [`Copy to clipboard`]: `Kopiuj do schowka`,
  [`Copy file path to clipboard`]: `Kopiuj ścieżkę pliku do schowka`,
  [`Copy the cost estimate for the GraphQL query to the output`]: `Kopiuj oszacowanie kosztu dla zapytania GraphQL do danych wyjściowych`,
  [`Copy the endpoints below to`]: `Skopiuj poniższe punkty końcowe, aby`,
  [`Copy the API Key to use. You are able to see this API Key again with the View icon.`]: `Skopiuj klucz interfejsu API w celu użycia. Ten klucz interfejsu API można ponownie wyświetlić za pomocą ikony Wyświetl.`,
  [`Copyright IBM Corporation 2012, 2022`]: `Copyright IBM Corporation 2012, 2022`,
  [`Copyright Information`]: `Informacje o prawach autorskich`,
  [`Correct the errors in the editor above or re-upload a valid file`]: `Popraw powyżej błędy w edytorze lub wyślij ponownie poprawny plik`,
  [`Cost`]: `Koszt`,
  [`Count`]: `Liczba`,
  [`Count Limit Name`]: `Nazwa limitu liczby`,
  [`Count Limits`]: `Limity liczby`,
  [`Created at`]: `Utworzono`,
  [`Counter`]: `Licznik`,
  [`Create`]: `Utwórz`,
  [`Create a [Certificate Manager]({link}) service`]: `Utwórz usługę [Certificate Manager]({link})`,
  [`Create API`]: `Tworzenie interfejsu API`,
  [`Create an API from the Develop section.`]: `Utwórz interfejs API w sekcji Tworzenie.`,
  [`Create API Connect API Key`]: `Tworzenie klucza interfejsu API Connect`,
  [`Create API from existing GraphQL service (GraphQL proxy)`]: `Tworzenie interfejsu API na podstawie istniejącej usługi GraphQL (proxy usług GraphQL)`,
  [`Create API from existing OpenAPI service`]: `Utwórz interfejs API na podstawie istniejącej usługi OpenAPI`,
  [`Create API from existing WSDL service (REST proxy)`]: `Utwórz interfejs API na podstawie istniejącej usługi WSDL (proxy usług REST)`,
  [`Create API from existing WSDL service (SOAP proxy)`]: `Utwórz interfejs API na podstawie istniejącej usługi WSDL (proxy usług SOAP)`,
  [`Create API from target service`]: `Utwórz interfejs API na podstawie usługi docelowej`,
  [`Create API from existing REST service`]: `Tworzenie interfejsu API na podstawie istniejącej usługi REST`,
  [`Create API from existing SOAP service`]: `Tworzenie interfejsu API na podstawie istniejącej usługi SOAP`,
  [`Create API from existing WSDL service`]: `Tworzenie interfejsu API na podstawie istniejącej usługi WSDL`,
  [`Create API Key failed`]: `Utworzenie klucza interfejsu API się nie powiodło`,
  [`Create application`]: `Utwórz aplikację`,
  [`Create authentication URL user registry`]: `Utwórz rejestr użytkowników z adresem URL uwierzytelniania`,
  [`Create availability zone`]: `Utwórz strefę dostępności`,
  [`Create Billing`]: `Tworzenie rozliczenia`,
  [`Create catalog`]: `Utwórz katalog`,
  [`Create catalog property`]: `Utwórz właściwość katalogu`,
  [`Create consumer organization`]: `Utwórz organizację konsumenta`,
  [`Create Credentials`]: `Tworzenie referencji`,
  [`Create definition`]: `Utwórz definicję`,
  [`Create email server`]: `Utwórz serwer poczty elektronicznej`,
  [`Create IBM Developer Portal`]: `Utwórz portal IBM Developer Portal`,
  [`Create keystore`]: `Utwórz magazyn kluczy`,
  [`Create LDAP user registry`]: `Utwórz rejestr użytkowników LDAP`,
  [`Create LTPA Key`]: `Tworzenie klucza LTPA`,
  [`Create local user registry`]: `Utwórz lokalny rejestr użytkowników`,
  [`Create native OAuth provider`]: `Utwórz własnego dostawcę OAuth`,
  [`Create New GraphQL API`]: `Tworzenie nowego interfejsu API GraphQL`,
  [`Create new OpenAPI`]: `Utwórz nową usługę OpenAPI`,
  [`Create new product`]: `Utwórz nowy produkt`,
  [`Create OIDC user registry`]: `Utwórz rejestr użytkowników OIDC`,
  [`Create OpenAPI definition and product definition`]: `Tworzenie definicji OpenAPI i definicji produktu`,
  [`Create operation`]: `Utwórz operację`,
  [`Create organization`]: `Utwórz organizację`,
  [`Create path`]: `Utwórz ścieżkę`,
  [`Create plan`]: `Utwórz plan`,
  [`Create portal`]: `Utwórz portal`,
  [`Create Product`]: `Tworzenie produktu`,
  [`Create property`]: `Utwórz właściwość`,
  [`Create role`]: `Utwórz rolę`,
  [`Create role default for the Organization`]: `Utwórz wartość domyślną roli dla organizacji`,
  [`Create sample user registry`]: `Utwórz przykładowy rejestr użytkowników`,
  [`Create security definition`]: `Utwórz definicję zabezpieczeń`,
  [`Create space`]: `Utwórz obszar`,
  [`Create subscription`]: `Utwórz subskrypcję`,
  [`Create TLS client profile`]: `Utwórz profil klienta TLS`,
  [`Create TLS server profile`]: `Utwórz profil serwera TLS`,
  [`Create target service`]: `Utwórz usługę docelową`,
  [`Create third party OAuth provider`]: `Utwórz zewnętrznego dostawcę OAuth`,
  [`Create truststore`]: `Utwórz magazyn zaufanych certyfikatów`,
  [`Create user registry`]: `Utwórz rejestr użytkowników`,
  [`Create a GraphQL proxy based on a GraphQL service`]: `Utwórz proxy usług GraphQL na podstawie usługi GraphQL`,
  [`Create a REST proxy based on an OpenAPI described target service`]: `Utwórz proxy usług REST na podstawie opisanej usługi docelowej OpenAPI.`,
  [`Create a REST proxy based upon a WSDL described target service`]: `Utwórz proxy usług REST na podstawie opisanej usługi docelowej WSDL`,
  [`Create a REST proxy based upon an OpenAPI described target service`]: `Utwórz proxy usług REST na podstawie opisanej usługi docelowej OpenAPI`,
  [`Create a REST proxy based upon the WSDL described target service`]: `Utwórz proxy usług REST na podstawie opisanej usługi docelowej WSDL.`,
  [`Create a REST proxy that routes all traffic to a target API or service endpoint`]: `Utwórz proxy usług REST, które kieruje cały ruch do docelowego interfejsu API lub punktu końcowego usługi`,
  [`Create a SOAP proxy based upon a WSDL described target service`]: `Utwórz proxy usług SOAP na podstawie opisanej usługi docelowej WSDL`,
  [`Create a SOAP proxy based upon the WSDL described target service`]: `Utwórz proxy usług SOAP na podstawie opisanej usługi docelowej WSDL.`,
  [`Create a new object using a handlebars template. Variables are taken from the context.`]: `Utwórz nowy obiekt przy użyciu szablonu uchwytów. Zmienne są pobierane z kontekstu.`,
  [`Create a portal site for the catalog`]: `Utwórz serwis portalu dla tego katalogu`,
  [`Create a product by importing a product definition`]: `Tworzenie produktu przez zaimportowanie definicji produktu`,
  [`Create a security definition`]: `Utwórz definicję zabezpieczeń`,
  [`Create a subscription`]: `Utwórz subskrypcję`,
  [`Create an API by importing an OpenAPI definition`]: `Tworzenie interfejsu API przez zaimportowanie definicji OpenAPI`,
  [`Create an API that calls an existing SOAP service`]: `Tworzenie interfejsu API wywołującego istniejącą usługę SOAP`,
  [`Create and manage API provider organizations and owners`]: `Służy do tworzenia organizacji i właścicieli dostawców interfejsów API oraz zarządzania nimi.`,
  [`Create and manage the spaces in your catalog; spaces allow you to partition your catalog to support different API provider development teams`]: `Utwórz obszary w katalogu i zarządzaj nimi. Obszary umożliwiają partycjonowanie katalogu w celu wspierania różnych zespołów programistycznych dostawców interfejsu API.`,
  [`Create and publish`]: `Utwórz i opublikuj`,
  [`Create and publish APIs and Products`]: `Tworzenie i publikowanie interfejsów API i produktów`,
  [`Create and subscribe`]: `Utwórz i zasubskrybuj`,
  [`Create assembly`]: `Utwórz zespół`,
  [`Create empty parent object for failed mapping`]: `Twórz pusty obiekt nadrzędny w przypadku niepowodzenia odwzorowania`,
  [`Create endpoint`]: `Utwórz punkt końcowy`,
  [`Create from`]: `Tworzenie na podstawie`,
  [`Create from Existing WSDL service`]: `Tworzenie na podstawie istniejącej usługi WSDL`,
  [`Create from File or URL`]: `tworzenie na podstawie pliku lub adresu URL`,
  [`Create from existing openAPI service`]: `Tworzenie na podstawie istniejącej usługi OpenAPI`,
  [`Create gateway extension`]: `Utwórz rozszerzenie bramy`,
  [`Create new user`]: `Utwórz nowego użytkownika`,
  [`Create new version`]: `Utwórz nową wersję`,
  [`Create product using a template`]: `Utwórz produkt przy użyciu szablonu`,
  [`Create provider organization`]: `Utwórz organizację dostawcy`,
  [`Create required child properties for array objects and mapped optional objects`]: `Twórz wymagane właściwości potomne dla obiektów tablicy i odwzorowanych obiektów opcjonalnych`,
  [`Created by OAuth Provider configuration as a sample. Make sure to update the OAuth providers using this sample with a valid User Registry.`]: `Przykładowa konfiguracja utworzona przez dostawcę OAuth. Zaktualizuj dostawców OAuth korzystających z tego przykładu, podając poprawny rejestr użytkowników.`,
  [`Created new product`]: `Utworzono nowy produkt`,
  [`Create|permission`]: `Tworzenie`,
  [`Create|title`]: `Tworzenie`,
  [`Creating Draft Product`]: `Tworzenie wersji roboczej produktu`,
  [`Creating a Consumer organization`]: `Tworzenie organizacji konsumenta`,
  [`Creating a consumer organization`]: `Tworzenie organizacji konsumenta`,
  [`Creating a new Product`]: `Tworzenie nowego produktu`,
  [`Creative Commons (CC-BY-4.0) license`]: `Licencje Creative Commons (CC-BY-4.0)`,
  [`Credential Extraction Method`]: `Metoda ekstrakcji referencji`,
  [`Credentials for the Sandbox catalog`]: `Referencje dla katalogu środowiska testowego`,
  [`Credentials for the {catalogName} catalog`]: `Referencje dla katalogu {catalogName}`,
  [`Credential Name`]: `Nazwa referencji`,
  [`Credential has been added.`]: `Dodano referencje.`,
  [`Credential`]: `Referencja`,
  [`Credentials`]: `Referencje`,
  [`Credentials are required to run the API Designer and Toolkit.`]: `Do uruchomienia komponentu API Designer i biblioteki narzędziowej są potrzebne referencje.`,
  [`Credentials are required to run the toolkit. Place the credential files in the folder where your toolkit is installed.`]: `Referencje są wymagane do działania biblioteki narzędziowej. Umieść pliki referencji w folderze, w którym jest zainstalowana biblioteka narzędziowa.`,
  [`Credit Card`]: `Karta kredytowa`,
  [`Cryptographic Algorithm`]: `Algorytm szyfrowania`,
  [`Currency`]: `Waluta`,
  [`Current password`]: `Bieżące hasło`,
  [`Current password is invalid.`]: `Bieżące hasło jest niepoprawne.`,
  [`Custom`]: `Niestandardowe`,
  [`Custom API URL`]: `Niestandardowy adres URL interfejsu API`,
  [`Custom expression (optional, one per line)`]: `Wyrażenie niestandardowe (opcjonalne, po jednym na wiersz)`,
  [`Custom expression to remove (optional, one per line)`]: `Wyrażenie niestandardowe do usunięcia (opcjonalne, po jednym na wiersz)`,
  [`Custom HTML form`]: `Niestandardowy formularz HTML`,
  [`Custom policies`]: `Strategie niestandardowe`,
  [`Custom form TLS profile`]: `Profil TLS formularza niestandardowego`,
  [`Custom form content security policy`]: `Strategia bezpieczeństwa treści formularza niestandardowego`,
  [`Custom form CSP header value`]: `Wartość nagłówka CSP niestandardowego formularza`,
  [`Custom form endpoint`]: `Punkt końcowy formularza niestandardowego`,
  [`Custom header pattern`]: `Niestandardowy wzorzec nagłówka`,
  [`Custom subscribability is required if custom visibility is selected`]: `Wymagana jest możliwość subskrypcji niestandardowej, jeśli wybrano widoczność niestandardową`,
  [`Customize`]: `Dostosowywanie`,
  [`Customize email sender information`]: `Dostosuj informacje o nadawcy wiadomości e-mail`,
  [`Customize notification templates`]: `Dostosowywanie szablonów powiadomień`,
  [`Customize the plan API list`]: `Dostosowywanie listy interfejsów API planu`,
  [`Czech`]: `czeski`,
  [`DATE MODIFIED`]: `DATA MODYFIKACJI`,
  [`DELETE`]: `USUWANIE`,
  [`Date created`]: `Data utworzenia`,
  [`Description`]: `Opis`,
  [`DETAIL`]: `SZCZEGÓŁY`,
  [`DN Base`]: `Podstawa nazwy wyróżniającej`,
  [`DNS Schemes`]: `Schematy DNS`,
  [`Dashboard`]: `Panel kontrolny`,
  [`Data encoded form body`]: `Treść formularza z zakodowanymi danymi`,
  [`Data flow in API Connect`]: `Przepływ danych w produkcie API Connect`,
  [`DataPower (v5 compatible)`]: `DataPower (kompatybilność z wersją 5)`,
  [`DataPower API`]: `Interfejs API DataPower`,
  [`DataPower API Gateway`]: `Brama interfejsu API DataPower`,
  [`DataPower Gateway (v5 compatible)`]: `Brama DataPower (kompatybilność z wersją 5)`,
  [`DataPower Gateway policies`]: `Strategie bramy DataPower`,
  [`Debug`]: `Debuguj`,
  [`Debug rule`]: `Debuguj regułę`,
  [`Debug XACML policy`]: `Debuguj strategię XACML`,
  [`Dec`]: `Gru`,
  [`Decline`]: `Odrzuć`,
  [`Decline and send`]: `Odrzuć i wyślij`,
  [`Decode Request Params`]: `Dekoduj parametry żądania`,
  [`Decrement`]: `Ubytek`,
  [`Decrypt`]: `Deszyfruj`,
  [`Decrypt Crypto JWK variable name`]: `Nazwa szyfrującej zmiennej JWK używanej do deszyfrowania`,
  [`Decrypt Crypto Object`]: `Obiekt szyfrujący używany do deszyfrowania`,
  [`Decrypt a buffer using the specified certificate`]: `Deszyfruj bufor przy użyciu określonego certyfikatu`,
  [`Default`]: `Domyślne`,
  [`Default behavior`]: `Zachowanie domyślne`,
  [`Default gateways`]: `Bramy domyślne`,
  [`default gateway services configured`]: `skonfigurowane domyślne usługi bramy`,
  [`Default Availability Zone`]: `Domyślna strefa dostępności`,
  [`Default Base endpoints`]: `Domyślne podstawowe punkty końcowe`,
  [`Default availability zone`]: `Domyślna strefa dostępności`,
  [`Default base endpoints`]: `Domyślne podstawowe punkty końcowe`,
  [`Default HTML form`]: `Domyślny formularz HTML`,
  [`Default limit`]: `Domyślny limit`,
  [`Default OAuth Provider Settings`]: `Domyślne ustawienia dostawcy OAuth`,
  [`Default OAuth Provider Settings Object`]: `Domyślny obiekt ustawień dostawcy OAuth`,
  [`Default Plan`]: `Plan domyślny`,
  [`Default TLS client profile`]: `Domyślny profil klienta TLS`,
  [`Default TLS server keystore`]: `Domyślny magazyn kluczy serwera TLS`,
  [`Default TLS server profile`]: `Domyślny profil serwera TLS`,
  [`Default burst-limit`]: `Domyślny limit wywołań`,
  [`Default form`]: `Formularz domyślny`,
  [`Default keystore for access_token signing key`]: `Domyślny magazyn kluczy dla klucza podpisywania tokenu dostępu`,
  [`Default keystore for id_token signing key`]: `Domyślny magazyn kluczy dla klucza podpisywania tokenu identyfikatora`,
  [`Default keystore for temporary token signing key`]: `Domyślny magazyn kluczy dla klucza podpisywania tokenu tymczasowego`,
  [`Default rate-limit`]: `Domyślny limit częstotliwości`,
  [`Default scopes`]: `Zasięgi domyślne`,
  [`Default table entry`]: `Domyślna pozycja tabeli`,
  [`Default validator endpoint`]: `Domyślny punkt końcowy analizatora poprawności`,
  [`Default validator endpoint set by OAuth provider`]: `Domyślny punkt końcowy analizatora poprawności ustawiony przez dostawcę OAuth`,
  [`Default value - no extra constraints apply.`]: `Wartość domyślna — brak dodatkowych ograniczeń.`,
  [`Define a third-party OAuth provider to issue and validate tokens to protect access to the API.`]: `Definiowanie zewnętrznego dostawcy OAuth służącego do wydawania tokenów i sprawdzania ich poprawności w celu zabezpieczenia dostępu do interfejsu API.`,
  [`Define catalog specific values for this property`]: `Zdefiniuj specyficzne dla katalogu wartości tej właściwości`,
  [`Define groups that manage APIs, products, catalogs, applications, and related settings.`]: `Zdefiniuj grupy, które zarządzają interfejsami API, produktami, katalogami, aplikacjami i powiązanymi z nimi ustawieniami.`,
  [`Define sets of policies to execute for specific conditions. Multiple cases can be defined, and each case may be associated with an arbitrary condition. Only the first matching case will be executed.`]: `Zdefiniuj zestawy strategii do wykonania dla konkretnych warunków. Można zdefiniować wiele przypadków, a każdy przypadek może być powiązany z dowolnym warunkiem. Zostanie wykonany tylko pierwszy zgodny przypadek.`,
  [`Define the API protection source. At least one option must be enabled.`]: `Zdefiniuj źródło ochrony interfejsu API. Musi być włączona co najmniej jedna opcja.`,
  [`The OIDC authorization server will redirect the authorization code to the following endpoints. This redirect endpoint is required when a customer registers the application on the OIDC provider. For example, if this user registry is used by a provider organization, the customer must register the provider organization's redirect endpoint with the OIDC provider.`]: `Serwer autoryzacji OIDC przekieruje kod autoryzacji do następujących punktów końcowych. Ten punkt końcowy przekierowania jest wymagany, gdy klient zarejestruje aplikację w dostawcy OIDC. Jeśli na przykład ten rejestr użytkowników jest używany przez organizację dostawcy, klient musi zarejestrować punkt końcowy przekierowania organizacji dostawcy w dostawcy OIDC.`,
  [`Define the inputs to be used in the map. You can also assign a title and description to the map.`]: `Zdefiniuj dane wejściowe, które mają być używane w odwzorowaniu. Do odwzorowania można również przypisać tytuł i opis.`,
  [`Define the OpenID provider endpoints to send an authentication request to your OpenID provider`]: `Zdefiniuj punkty końcowe dostawcy OpenID, aby wysyłać żądania uwierzytelnienia do dostawcy OpenID`,
  [`Define the outputs to be used in the map.`]: `Zdefiniuj dane wyjściowe, które mają być używane w odwzorowaniu.`,
  [`Define the number of free trial days and the plan pricing. Users will be billed based on their subscription date.`]: `Zdefiniuj liczbę dni bezpłatnego okresu próbnego i cennik planu. Użytkownicy będą obciążani opłatami na podstawie daty subskrypcji.`,
  [`Defines whether the flow continues when an error is thrown during the policy execution. If not selected, a catch flow is triggered.`]: `Określa, czy przepływ jest kontynuowany w przypadku zgłoszenia błędu w trakcie wykonywania strategii. Jeśli ta właściwość nie zostanie wybrana, zostanie wyzwolony przepływ wychwytywania.`,
  [`Definition`]: `Definicja`,
  [`Definition Object`]: `Obiekt definicji`,
  [`Definitions`]: `Definicje`,
  [`Delete`]: `Usuń`,
  [`Delete Application`]: `Usuwanie aplikacji`,
  [`Delete Cloud Connection.`]: `Usuń połączenie z chmurą`,
  [`Delete Selected APIs`]: `Usuń wybrane interfejsy API`,
  [`Delete selected client profiles`]: `Usuń wybrane profile klienta`,
  [`Delete selected keystores`]: `Usuń wybrane magazyny kluczy`,
  [`Delete selected mail servers`]: `Usuń wybrane serwery poczty`,
  [`Delete Selected Products`]: `Usuń wybrane produkty`,
  [`Delete selected truststores`]: `Usuń wybrane magazyny zaufanych certyfikatów`,
  [`Delete selected user registries`]: `Usuń wybrane rejestry użytkowników`,
  [`Delete Staged Product`]: `Usuwanie przemieszczonego produktu`,
  [`Delete a space`]: `Usuwanie obszaru`,
  [`Delete a catalog`]: `Usuwanie katalogu`,
  [`Delete API Key`]: `Wykasuj klucz API`,
  [`Delete API Key failed`]: `Usunięcie klucza interfejsu API się nie powiodło`,
  [`Delete availability zone`]: `Usuń strefę dostępności`,
  [`Delete selected requests`]: `Usuń wybrane żądania`,
  [`Delete row`]: `Usuń wiersz`,
  [`Delete|title`]: `Usuwanie`,
  [`Deleting a catalog can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the catalog.`]: `Usunięcie katalogu może się nie powieść, jeśli istnieje duża liczba organizacji konsumenta, aplikacji, opublikowanych produktów i innych zasobów.  Przed usunięciem katalogu rozważ wyczyszczenie tych zasobów.`,
  [`Deleting a provider organization can fail if you have a large number of catalogs, consumer organizations, applications, published products and other resources. Please consider cleanup of these resources prior to deleting the provider organization.`]: `Usunięcie organizacji dostawcy może się nie powieść, jeśli istnieje duża liczba katalogów, organizacji konsumenta, aplikacji, opublikowanych produktów i innych zasobów. Przed usunięciem organizacji dostawcy rozważ wyczyszczenie tych zasobów.`,
  [`Deleting a space can fail if you have a large number of consumer organizations, applications, published products and other resources.  Please consider cleanup of these resources prior to deleting the space.`]: `Usunięcie obszaru może się nie powieść, jeśli istnieje duża liczba organizacji konsumenta, aplikacji, opublikowanych produktów i innych zasobów.  Przed usunięciem obszaru rozważ wyczyszczenie tych zasobów.`,
  [`Deleting {{name}}...`]: `Usuwanie {name}...`,
  [`Deprecate`]: `Ustaw jako nieaktualny`,
  [`Deprecate product`]: `Ustaw produkt jako nieaktualny`,
  [`Deprecated`]: `Nieaktualne`,
  [`Deprecate|permission`]: `Ustawianie jako nieaktualnego`,
  [`Description of gateway`]: `Opis bramy`,
  [`Description text for the behaviour of the modal.`]: `Tekst opisujący zachowanie elementu modalnego.`,
  [`Descriptive name`]: `Nazwa opisowa`,
  [`Design`]: `Projekt`,
  [`Designate a current member as owner of a consumer organization.`]: `Wyznacz bieżącego członka jako właściciela organizacji konsumenta.`,
  [`Designate a current member as owner of a provider organization.`]: `Wyznacz bieżącego członka jako właściciela organizacji dostawcy.`,
  [`Designer`]: `Designer`,
  [`Destination`]: `Przeznaczenie`,
  [`Determines which credential-extraction method to use.`]: `Decyduje o tym, która metoda ekstrakcji referencji będzie używana.`,
  [`Determine whether to include this type/field and any applicable arguments in the schema.`]: `Określ, czy uwzględniać ten typ/to pole w odpowiednich argumentach w schemacie.`,
  [`Develop`]: `Tworzenie`,
  [`Develop APIs`]: `Projektowanie interfejsów API`,
  [`Develop APIs and products`]: `Utwórz interfejsy API i produkty`,
  [`Developer`]: `Programista`,
  [`developer`]: `programista`,
  [`Developer Portal`]: `Developer Portal`,
  [`Developer community`]: `Społeczność programistów`,
  [`Developers can request a state upgrade from development to production`]: `Programiści mogą żądać aktualizacji stanu z programowania do produkcji`,
  [`Development`]: `Środowisko programistyczne`,
  [`Directive`]: `Dyrektywa`,
  [`Director Endpoint`]: `Punkt końcowy katalogu`,
  [`Director TLS client profile`]: `Profil klienta TLS produktu Director`,
  [`Disable`]: `Wyłącz`,
  [`Disable Activity log`]: `Wyłącz dziennik działań`,
  [`Disable Self-Service Onboarding`]: `Wyłączanie samodzielnej rejestracji`,
  [`Disable spaces`]: `Wyłącz obszar`,
  [`Disable buffering`]: `Wyłącz buforowanie`,
  [`Disable Self-Service Onboarding Approval`]: `Wyłącz zatwierdzanie samodzielnej rejestracji`,
  [`Disable overriding consumer organizations invitation timeout.`]: `Wyłącz zastępowanie limitu czasu zaproszenia dla organizacji konsumentów.`,
  [`Disabled`]: `Wyłączone`,
  [`disabled`]: `wyłączone`,
  [`Disabling self-service onboarding will require all application developers to be invited by the API provider, an existing consumer organization owner or administrator.`]: `Wyłączenie samodzielnej rejestracji spowoduje, że wszyscy programiści aplikacji będą musieli zostać zaproszeni przez dostawcę interfejsu API, istniejącego właściciela organizacji konsumenta lub administratora.`,
  [`Disabling self-service onboarding approval will automatically approve all onboarding requests.`]: `Wyłączenie zatwierdzania samodzielnej rejestracji spowoduje automatyczne zatwierdzenie wszystkich wniosków o rejestrację.`,
  [`Disabling will not set consumer organizations invitation timeout to the catalaog timeout.`]: `Wyłączenie spowoduje nieustawienie limitu czasu zaproszenia dla organizacji konsumenckich na limit czasu katalogu.`,
  [`Discover targets`]: `Wykryj cele`,
  [`Display table checkboxes`]: `Wyświetl pola wyboru tabeli`,
  [`Display vanity endpoint`]: `Wyświetlaj niestandardowy punkt końcowy`,
  [`Do not publish API (continue editting)`]: `Nie publikuj interfejsu API (kontynuuj edycję)`,
  [`Do not require applications or users to authenticate`]: `Nie wymagaj uwierzytelniania aplikacji ani użytkowników`,
  [`Do not require authentication`]: `Nie wymagaj uwierzytelniania`,
  [`Do not share the resource with provider organizations`]: `Nie udostępniaj tego zasobu organizacjom dostawców`,
  [`Do not share the API gateway with provider organizations`]: `Nie udostępniaj bramy interfejsu API organizacjom dostawców`,
  [`Do not share the service with provider organizations`]: `Nie udostępniaj tej usługi organizacjom dostawców`,
  [`Do not use Dynamic DNS`]: `Nie używaj dynamicznego DNS`,
  [`Do you want to remove these references?`]: `Czy chcesz usunąć te odwołania?`,
  [`Docker`]: `Docker`,
  [`Docker docs`]: `Dokumentacja Dockera`,
  [`Docs`]: `Dokumentacja`,
  [`Document Editor`]: `Edytor dokumentów`,
  [`Documentation and tutorials with step-by-step instructions`]: `Dokumentacja i kursy ze szczegółowymi instrukcjami.`,
  [`Domain name`]: `Nazwa domeny`,
  [`Domain Name`]: `Nazwa domeny`,
  [`Domains handled by gateway via SNI`]: `Domeny obsługiwane przez bramę za pośrednictwem SNI`,
  [`Don't have an account?`]: `Nie masz konta?`,
  [`Done`]: `Gotowe`,
  [`Download`]: `Pobierz`,
  [`Download Gateway`]: `Pobierz bramę`,
  [`Download and install`]: `Pobierz i zainstaluj`,
  [`Download and set up DataPower API Gateway`]: `Pobierz i skonfiguruj bramę DataPower API Gateway`,
  [`Download and set up DataPower API Gateway for use on premises or on any cloud.`]: `Pobierz i skonfiguruj bramę DataPower API Gateway do użytku lokalnego lub w dowolnej chmurze.`,
  [`Download for Linux`]: `Pobierz dla systemu Linux`,
  [`Download for Mac`]: `Pobierz dla komputerów Mac`,
  [`Download for Windows`]: `Pobierz dla systemu Windows`,
  [`Download gateway`]: `Pobierz bramę`,
  [`Download schema`]: `Pobierz schemat`,
  [`Download Toolkit`]: `Pobierz bibliotekę narzędziową`,
  [`Download toolkit`]: `Pobierz bibliotekę narzędziową`,
  [`Download toolkit and credentials for various platforms`]: `Pobierz bibliotekę narzędziową i referencje dla różnych platform`,
  [`Downloaded Credentials not found for this cloud connection. Login will use default credentials.`]: `Nie znaleziono pobranych referencji dla tego połączenia z chmurą. Do logowania zostaną użyte referencje domyślne.`,
  [`Dutch`]: `holenderski`,
  [`Draft {type} creation failed`]: `Tworzenie kopii roboczej {type} nie powiodło się`,
  [`Drafts`]: `Wersje robocze`,
  [`Drag and drop files here or click to upload`]: `Przeciągnij i upuść pliki w tym miejscu lub kliknij, aby przesłać`,
  [`Drag & Drop`]: `Przeciągnij i upuść`,
  [`Drupal 8`]: `Drupal 8`,
  [`Dynamic DNS`]: `Dynamiczny DNS`,
  [`Dynamic OAuth configuration from a URL`]: `Dynamiczna konfiguracja OAuth z adresu URL`,
  [`Dynamic OAuth configuration from a literal string`]: `Dynamiczna konfiguracja OAuth na podstawie literału łańcuchowego`,
  [`Dynamic group`]: `Grupa dynamiczna`,
  [`Dynamic table entries`]: `Pozycje tabeli dynamicznej`,
  [`ENABLE`]: `WŁĄCZ`,
  [`Encoded`]: `Zakodowane`,
  [`Each Space is used by a different API provider development team and has its own set of management capabilities relating specifically to the APIs that the associated team publishes to that Space, enabling each team to manage their APIs independently. When you stage or publish an API to a Catalog that has Spaces enabled, you specify the Space within that Catalog that you want to stage or publish to.`]: `Każdy obszar jest używany przez inny zespół programistyczny dostawcy interfejsu API i ma własny zestaw funkcji zarządzania odnoszących się do interfejsów API opublikowanych w danym obszarze przez powiązany zespół. Umożliwia to niezależne zarządzanie interfejsami API przez poszczególne zespoły. Przy przemieszczaniu lub publikowaniu interfejsu API w katalogu, w którym włączono obszary, określa się obszar w danym katalogu, do którego ma nastąpić przemieszczenie lub w którym ma dojść do publikacji.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs.`]: `Każda organizacja dostawcy posiada zestaw interfejsów API, produktów, planów i katalogów.`,
  [`Easily create, secure, manage, share, and analyze APIs located on cloud and on-premises.`]: `Możesz łatwo tworzyć, zabezpieczać, udostępniać i analizować interfejsy API w chmurze i lokalne, a także zarządzać nimi.`,
  [`Each provider organization owns a set of APIs, products, plans, and catalogs. [Learn more]({link})`]: `Każda organizacja dostawcy posiada zestaw interfejsów API, produktów, planów i katalogów. [Więcej informacji]({link})`,
  [`Edit`]: `Edytuj`,
  [`Edit access token TTL`]: `Edytuj czas życia tokenu dostępu`,
  [`Edit admin organization invitation timeout`]: `Edytuj limit czasu zaproszenia organizacji administracyjnej`,
  [`Edit API`]: `Edytuj interfejs API`,
  [`Edit API Connect UI`]: `Edycja interfejsu użytkownika produktu API Connect`,
  [`Edit API endpoint for unenforced APIs`]: `Edytuj punkt końcowy interfejsu API dla niewymuszonych interfejsów API`,
  [`Edit API gateway service`]: `Edytuj usługę bramy interfejsu API`,
  [`Edit API Path`]: `Edycja ścieżki interfejsu API`,
  [`Edit API security definition`]: `Edytuj definicję zabezpieczeń interfejsu API`,
  [`Edit API user registries`]: `Edytuj rejestry użytkowników interfejsu API`,
  [`Edit analytics service`]: `Edytuj usługę analizy danych`,
  [`Edit application`]: `Edytuj aplikację`,
  [`Edit Assembly`]: `Edytuj zespół`,
  [`Edit authentication URL user registry`]: `Edytuj rejestr użytkowników z adresem URL uwierzytelniania`,
  [`Edit availability zone`]: `Edytuj strefę dostępności`,
  [`Edit Availablity Zone`]: `Edycja strefy dostępności`,
  [`Edit and apply constraints to your selected items.`]: `Edytuj i zastosuj ograniczenia do wybranych elementów`,
  [`Edit billing integration`]: `Edytuj integrację rozliczeń`,
  [`Edit Catalog Details`]: `Edycja szczegółów katalogu`,
  [`Edit cloud setting invitation timeout`]: `Edytuj limit czasu zaproszenia w ustawieniach chmury`,
  [`Edit constraints`]: `Edytuj ograniczenia`,
  [`Edit consumer organization`]: `Edytuj organizację konsumenta`,
  [`Edit DNS Scheme`]: `Edycja schematu DNS`,
  [`Edit DataPower API gateway service`]: `Edytuj usługę bramy interfejsu API DataPower`,
  [`Edit DataPower gateway service`]: `Edytuj usługę bramy DataPower`,
  [`Edit definition`]: `Edytuj definicję`,
  [`Edit dynamic DNS`]: `Edytuj dynamiczny DNS`,
  [`Edit email server`]: `Edytuj serwer poczty elektronicznej`,
  [`Edit extensions`]: `Edytuj rozszerzenia`,
  [`Edit Gateway Service`]: `Edycja usługi bramy`,
  [`Edit gateway`]: `Edytuj bramę`,
  [`Edit gateway extension`]: `Edytuj rozszerzenie bramy`,
  [`Edit Gateways`]: `Edycja bram`,
  [`Edit IBM-managed gateway`]: `Edytuj bramę zarządzaną przez IBM`,
  [`Edit Invitation Timeout`]: `Edycja limitu czasu zaproszenia`,
  [`Edit gateways`]: `Edytuj bramy`,
  [`Edit invitation timeout`]: `Edytuj limit czasu zaproszenia`,
  [`Edit JSON`]: `Edytuj dane JSON`,
  [`Edit keystore`]: `Edytuj magazyn kluczy`,
  [`Edit LDAP user registry`]: `Edytuj rejestr użytkowników LDAP`,
  [`Edit LTPA Token`]: `Edycja tokenu LTPA`,
  [`Edit lifecycle approvals`]: `Edytuj zatwierdzenia cyklu życia`,
  [`Edit local user registry`]: `Edytuj lokalny rejestr użytkowników`,
  [`Edit native OAuth provider`]: `Edytuj własnego dostawcę OAuth`,
  [`Edit New Billing System`]: `Edycja nowego systemu rozliczeniowego`,
  [`Edit Notification Server`]: `Edycja serwera powiadomień`,
  [`Edit Notification template`]: `Edytuj szablon powiadomienia`,
  [`Edit OAuth Provider`]: `Edycja dostawcy OAuth`,
  [`Edit OAuth provider details`]: `Edytuj szczegóły dostawcy OAuth`,
  [`Edit OAuth Provider Visibility`]: `Edycja widoczności dostawcy OAuth`,
  [`Edit OIDC User Registry`]: `Edycja rejestru użytkowników OIDC`,
  [`Edit Onboarding`]: `Edycja rejestrowania`,
  [`Edit Operation`]: `Edytuj operację`,
  [`Edit Organization`]: `Edycja organizacji`,
  [`Edit Path`]: `Edytuj ścieżkę`,
  [`Edit Path Parameters`]: `Edycja parametrów ścieżki`,
  [`Edit notification template`]: `Edytuj szablon powiadomienia`,
  [`Edit OAuth provider`]: `Edytuj dostawcę OAuth`,
  [`Edit OAuth provider visibility`]: `Edytuj widoczność dostawcy OAuth`,
  [`Edit OIDC user registry`]: `Edytuj rejestr użytkowników OIDC`,
  [`Edit onboarding`]: `Edytuj rejestrowanie`,
  [`Edit operation`]: `Edytuj operację`,
  [`Edit organization`]: `Edytuj organizację`,
  [`Edit path`]: `Edytuj ścieżkę`,
  [`Edit path parameters`]: `Edytuj parametry ścieżki`,
  [`Edit plan`]: `Edytuj plan`,
  [`Edit portal`]: `Edytuj portal`,
  [`Edit portal service`]: `Edytuj usługę portalu`,
  [`Edit Portal Settings`]: `Edycja ustawień portalu`,
  [`Edit product`]: `Edytuj produkt`,
  [`Edit product APIs`]: `Edytuj interfejsy API produktu`,
  [`Edit Product Subscribability`]: `Edycja możliwości subskrypcji produktu`,
  [`Edit Product Visibility`]: `Edycja widoczności produktu`,
  [`Edit Property`]: `Edycja właściwości`,
  [`Edit Provider Organization`]: `Edycja organizacji dostawcy`,
  [`Edit Refresh Token TTL`]: `Edytuj czas życia tokenu odświeżania`,
  [`Edit remote gateway`]: `Edytuj bramę zdalną`,
  [`Edit Reset Password TTL`]: `Edytuj czas życia resetowania hasła`,
  [`Edit Role`]: `Edycja roli`,
  [`Edit Role Default for Provider Organizations`]: `Edycja wartości domyślnej roli dla organizacji dostawców`,
  [`Edit Role for Provider Organizations`]: `Edycja roli na potrzeby organizacji dostawców`,
  [`Edit property`]: `Edytuj właściwość`,
  [`Edit provider organization`]: `Edytuj organizację dostawcy`,
  [`Edit refresh token TTL`]: `Edytuj czas życia tokenu odświeżania`,
  [`Edit reset password TTL`]: `Edytuj czas życia resetowania hasła`,
  [`Edit role`]: `Edytuj rolę`,
  [`Edit role default for Provider Organizations`]: `Edytuj wartość domyślną roli dla organizacji dostawców`,
  [`Edit role for Provider Organizations`]: `Edytuj rolę na potrzeby organizacji dostawców`,
  [`Edit Roles`]: `Edycja ról`,
  [`Edit Schema in JSON/XML`]: `Edytuj schemat w JSON/XML`,
  [`Edit security definition`]: `Edytuj definicję zabezpieczeń`,
  [`Edit sender`]: `Edytuj nadawcę`,
  [`Edit sender & email server`]: `Edytuj nadawcę i serwer poczty elektronicznej`,
  [`Edit sender Info`]: `Edytuj informację o nadawcy`,
  [`Edit service visibility`]: `Edytuj widoczność usługi`,
  [`Edit show/hide settings`]: `Edytuj ustawienia widoczności`,
  [`Edit space details`]: `Edytuj szczegóły obszaru`,
  [`Edit TLS client profile`]: `Edytuj profil klienta TLS`,
  [`Edit TLS client profile visibility`]: `Edytuj widoczność profilu klienta TLS`,
  [`Edit TLS server profile`]: `Edytuj profil serwera TLS`,
  [`Edit third party OAuth provider`]: `Edytuj zewnętrznego dostawcę OAuth`,
  [`Edit truststore`]: `Edytuj magazyn zaufanych certyfikatów`,
  [`Edit unenforced API`]: `Edytuj niewymuszany interfejs API`,
  [`Edit user registries`]: `Edytuj rejestry użytkowników`,
  [`Edit user registry`]: `Edytuj rejestr użytkowników`,
  [`Edit user registry visibility`]: `Edytuj widoczność rejestru użytkowników`,
  [`Edit user registry for API Manager`]: `Edytuj rejestr użytkowników dla programu API Manager`,
  [`Edit user registry for Cloud Manager`]: `Edytuj rejestr użytkowników dla programu Cloud Manager`,
  [`Edit vanity API endpoints`]: `Edytuj niestandardowe punkty końcowe interfejsu API`,
  [`Edit visibility`]: `Edytuj widoczność`,
  [`Edit XML`]: `Edytuj kod XML`,
  [`Edit app lifecycle approved`]: `Edycja cyklu życia aplikacji została zatwierdzona`,
  [`Edit app lifecycle cancelled`]: `Edycja cyklu życia aplikacji została anulowana`,
  [`Edit app lifecycle denied`]: `Odmowa edycji cyklu życia aplikacji`,
  [`Edit app lifecycle pending`]: `Oczekiwanie na edycję cyklu życia aplikacji`,
  [`Edit app lifecycle request`]: `Żądanie edycji cyklu życia aplikacji`,
  [`Edit app reinstated`]: `Przywrócono edycję aplikacji`,
  [`Edit app suspended`]: `Zawieszono edycję aplikacji`,
  [`Edit audit setting`]: `Edytuj ustawienie kontroli`,
  [`Edit definitions`]: `Edycja definicji`,
  [`Enable external group mapping`]: `Włącz odwzorowanie grupy zewnętrznej`,
  [`Edit extra constraints for the GraphQL schema`]: `Edytuj dodatkowe ograniczenia dla schematu GraphQL`,
  [`Edit GraphQL schema|title`]: `Edytuj schemat GraphQL`,
  [`Edit inline schema`]: `Edytuj schemat wstawiany`,
  [`Edit inputs`]: `Edytuj dane wejściowe`,
  [`Edit invitation`]: `Edycja zaproszenia`,
  [`Edit mail server test connection`]: `Edycja połączenia testowego z serwerem poczty elektronicznej`,
  [`Edit member invitation`]: `Edycja zaproszenia członka`,
  [`Edit notification templates`]: `Edycja szablonów powiadomień`,
  [`Edit notification templates:`]: `Edycja szablonów powiadomień:`,
  [`Edit operation list`]: `Edytuj listę operacji`,
  [`Edit or upload JSON schema`]: `Edytuj lub prześlij schemat JSON`,
  [`Edit outputs`]: `Edytuj dane wyjściowe`,
  [`Edit password changed`]: `Edycja zmiany hasła`,
  [`Edit password reset`]: `Edycja resetowania hasła`,
  [`Edit plan APIs`]: `Edytuj interfejsy API planu`,
  [`Edit schema`]: `Edytuj schemat`,
  [`Edit schema|button`]: `Edytuj schemat`,
  [`Edit schema|title`]: `Edycja schematu`,
  [`Edit settings for roles, notifications and more.`]: `Służy do edytowania ustawień ról, powiadomień i innych elementów.`,
  [`Edit settings for user registries, roles, endpoints, and more`]: `Służy do edytowania ustawień rejestrów użytkowników, ról, punktów końcowych i innych elementów.`,
  [`Edit sign up`]: `Edycja rejestracji`,
  [`Edit task product lifecycle approved`]: `Edycja cyklu życia produktu zadania została zatwierdzona`,
  [`Edit task product lifecycle cancelled`]: `Edycja cyklu życia produktu zadania została anulowana`,
  [`Edit task product lifecycle denied`]: `Odmowa edycji cyklu życia produktu zadania`,
  [`Edit task product lifecycle pending`]: `Oczekiwanie na edycję cyklu życia produktu zadania`,
  [`Edit task product lifecycle request`]: `Żądanie edycji cyklu życia produktu zadania`,
  [`Edit task subscription approved`]: `Edycja subskrypcji zadania została zatwierdzona`,
  [`Edit task subscription cancelled`]: `Edycja subskrypcji zadania została anulowana`,
  [`Edit task subscription denied`]: `Odmowa edycji subskrypcji zadania`,
  [`Edit task subscription pending`]: `Oczekiwanie na edycję subskrypcji zadania`,
  [`Edit task subscription request`]: `Żądanie edycji subskrypcji zadania`,
  [`Edit template`]: `Edytuj szablon`,
  [`Edit the TLS client profile.`]: `Edytuj profil klienta TLS.`,
  [`Edit the TLS Server profiles`]: `Edycja profili serwera TLS`,
  [`Edit the keystore title.`]: `Edycja tytułu magazynu kluczy.`,
  [`Edit the organization summary details as required`]: `W razie potrzeby edytuj szczegóły podsumowania organizacji.`,
  [`Edit the organization title`]: `Edytuj tytuł organizacji`,
  [`Edit the parameters to configure an email server. Fields are required unless designated as optional.`]: `Edytuj parametry w celu skonfigurowania serwera poczty elektronicznej. Pola są wymagane, chyba że są oznaczone jako opcjonalne.`,
  [`Edit signed token`]: `Edytuj podpisany token`,
  [`Edit the text for the email template. Variables are contained in braces and cannot be edited.`]: `Edytuj tekst szablonu wiadomości e-mail. Zmienne są umieszczone w nawiasach klamrowych i nie można ich edytować.`,
  [`Edit the truststore.`]: `Edycja magazynu zaufanych certyfikatów.`,
  [`Edit the visibility for each resource.`]: `Edytowanie widoczności każdego zasobu.`,
  [`Edit user details`]: `Edytuj szczegółowe informacje o użytkowniku.`,
  [`Edit {notificationTemplate}`]: `Edytuj {notificationTemplate}`,
  [`Edit, assemble, secure and test APIs`]: `Edytowanie, składanie, zabezpieczanie i testowanie interfejsów API`,
  [`Edit, assemble, secure and test APIs. Package APIs using products for publishing to consumers.`]: `Służy do edytowania, składania, zabezpieczania i testowania interfejsów API. W tej sekcji można tworzyć pakiety interfejsów API przy użyciu produktów w celu publikowania dla konsumentów.`,
  [`Edit, upload, or infer XML schema`]: `Edytuj, prześlij lub wywnioskuj ze schematu XML.`,
  [`Editors`]: `Edytory`,
  [`Edit|permission`]: `Edytowanie`,
  [`Edit API key timeout`]: `Edytuj limit czasu klucza API`,
  [`Edit Base64 encoding for temporary tokens`]: `Edytuj kodowanie Base64 dla tokenów tymczasowych`,
  [`Either "ID token signing crypto object" or "ID token signing key" must be provided.`]: `Należy podać wartość w polu Obiekt szyfrujący do podpisywania tokenu identyfikatora albo Klucz podpisywania tokenu identyfikatora.`,
  [`Either "JWT verification crypto object" or "JWT verification JWK" must be provided.`]: `Należy podać wartość w polu Obiekt szyfrujący weryfikacji JWT lub JWK weryfikacji JWT.`,
  [`Either select the option to use the endpoint that is defined in the API, or provide a default validator endpoint.`]: `Wybierz opcję używania punktu końcowego zdefiniowanego w interfejsie API lub podaj domyślny punkt końcowy analizatora poprawności.`,
  [`Element name for JSON array elements`]: `Nazwa elementu na potrzeby elementów tablicy JSON`,
  [`Email`]: `Adres e-mail`,
  [`Email address`]: `Adres e-mail`,
  [`Email Content`]: `Treść wiadomości e-mail`,
  [`Email endpoint`]: `Punkt końcowy poczty elektronicznej`,
  [`Email required`]: `Wymagany jest adres e-mail`,
  [`Email sender`]: `Nadawca wiadomości e-mail`,
  [`Email server`]: `Serwer poczty elektronicznej`,
  [`Email server configuration`]: `Konfiguracja serwera poczty elektronicznej`,
  [`Email servers`]: `Serwery poczty elektronicznej`,
  [`Empty case`]: `Przypadek pusty`,
  [`Empty catch`]: `Pusty element wychwycony`,
  [`Empty JSON array handling`]: `Obsługa pustej tablicy JSON`,
  [`Empty XML element handling`]: `Obsługa pustego elementu XML`,
  [`Enable`]: `Włącz`,
  [`Enable API consumers to invite collaborators and assign roles`]: `Zezwalaj konsumentom interfejsu API na zapraszanie współpracowników i przypisywanie im ról`,
  [`Enable application lifecycle`]: `Włącz cykl życia aplikacji`,
  [`Enable billing`]: `Włącz rozliczanie`,
  [`Enable gateway services`]: `Włącz usługi bramy`,
  [`Enable gateway services and policies`]: `Włącz usługi i strategie bramy`,
  [`Enable GraphiQL`]: `Włącz GraphiQL`,
  [`Enable GraphiQL editor`]: `Włącz edytor GraphiQL`,
  [`Enable GraphiQL Editor on browser`]: `Włącz edytor GraphiQL w przeglądarce`,
  [`Enable JSON post processing`]: `Włącz przetwarzanie końcowe JSON`,
  [`Enable lifecycle approvals`]: `Włącz zatwierdzenia cyklu życia`,
  [`Enable NONCE extension to prevent compromised requests from being used again (replayed)`]: `Włącz rozszerzenie NONCE, aby zapobiec ponownemu użyciu żądań powodujących naruszenie ochrony danych (ich ponownemu odtwarzaniu)`,
  [`Enable OAuth providers`]: `Włącz dostawców OAuth`,
  [`Enable OIDC`]: `Włącz protokół OIDC`,
  [`Enable OpenID Connect template to generate ID tokens.`]: `Włącz szablon protokołu OpenID Connect w celu generowania tokenów identyfikatora.`,
  [`Enable PKCE extension to allow public clients to mitigate the threat of having the authorization code intercepted`]: `Włącz rozszerzenie PKCE, aby zezwolić publicznym klientom na ograniczanie ryzyka przechwycenia kodu autoryzacji`,
  [`Enable production mode`]: `Włącz tryb produkcyjny`,
  [`Enable Proxy`]: `Włącz proxy`,
  [`Enable Self-Service Onboarding`]: `Włączanie samodzielnej rejestracji`,
  [`Enable spaces`]: `Włącz obszary`,
  [`Enable token management`]: `Włącz zarządzanie tokenami`,
  [`Enable buffering`]: `Włącz buforowanie`,
  [`Enable API consumers to create development portal accounts without requiring an invitation.`]: `Zezwalaj konsumentom API na tworzenie kont w portalu programistycznym bez konieczności uzyskania zaproszenia.`,
  [`Enable cross-origin resource sharing (CORS) access control for your API.`]: `Włącz kontrolę dostępu współużytkowania zasobów między serwerami (CORS, cross-origin resource sharing) dla interfejsu API.`,
  [`Enable debug response headers`]: `Włącz debugowanie nagłówków odpowiedzi`,
  [`Enable proof key for code exchange`]: `Włącz klucz zaufany na potrzeby wymiany kodu`,
  [`Enable publishing of this product`]: `Włącz publikowanie tego produktu`,
  [`Enable redirect of third-party authorization code through Portal OIDC endpoint`]: `Włącz przekierowanie kodu autoryzacji innego podmiotu przez punkt końcowy OIDC portalu`,
  [`Enable security`]: `Włącz zabezpieczenia`,
  [`Enable Self-Service Onboarding Approval`]: `Włącz zatwierdzanie samodzielnej rejestracji`,
  [`Enable the following gateway services and policies for this catalog`]: `Włącz poniższe usługi i strategie bramy dla tego katalogu`,
  [`Enable users to configure an audit event. An audit event is a detailed summary of who has performed what event, at when.`]: `Umożliwiaj użytkownikom konfigurowanie zdarzeń kontroli. Zdarzenie kontroli jest szczegółowym podsumowaniem informacji o tym, kto i kiedy brał udział w zdarzeniu.`,
  [`Enable/disable custom notification templates`]: `Włącz/wyłącz niestandardowe szablony powiadomień`,
  [`Enable or disable Base64 encoding for invitations and password reset tokens`]: `Włącz lub wyłącz kodowanie Base64 dla zaproszeń i tokenów resetowania hasła`,
  [`Enable overriding consumer organizations invitation timeout`]: `Włącz zastępowanie limitu czasu zaproszenia dla organizacji konsumenta`,
  [`You can enable or disable Base64 encoding for temporary tokens. Temporary tokens are invitations, and password reset tokens. When Base64 encoding is enabled, the temporary token is encoded in Base64 format. When Base64 encoding is disabled, the temporary token remains in the JSON Web Token (JWT) format, and means that the length of the URL that the user receives is reduced.`]: `Istnieje możliwość włączenia lub wyłączenia kodowania Base64 dla tokenów tymczasowych. Tokeny tymczasowe to zaproszenia, a także tokeny resetowania hasła. Gdy kodowanie Base64 jest włączone, token tymczasowy jest kodowany w formacie Base64. Gdy kodowanie Base64 jest wyłączone, token tymczasowy pozostaje w formacie JWT (JSON Web Token), co oznacza, że długość adresu URL, który otrzymuje użytkownik, jest zmniejszona.`,
  [`Enabled`]: `Włączone`,
  [`enabled`]: `włączone`,
  [`Enabling application lifecycle will allow applications to be in development or production status and can be routed to different gateways and endpoints.`]: `Włączenie cyklu życia aplikacji pozwala na ustawianie aplikacji w stanie programowania lub produkcji oraz na ich kierowanie do różnych bram i punktów końcowych.`,
  [`Enabling self-service onboarding approval will require all onboarding requests to be approved by the API provider or an administrator.`]: `Włączenie zatwierdzania samodzielnej rejestracji spowoduje konieczność zatwierdzenia wszystkich wniosków o rejestrację przez dostawcę interfejsu API lub administratora.`,
  [`Enabling self-service onboarding allows an application developer to sign up without an invitation from the API provider, an existing consumer organization owner or administrator.`]: `Włączenie samodzielnej rejestracji umożliwia programiście aplikacji rejestrowanie się bez zaproszenia od dostawcy interfejsu API, istniejącego właściciela organizacji konsumenta lub administratora.`,
  [`Enabling will set all consumer organizations invitation timeout to the catalaog invitation timeout.`]: `Włączenie spowoduje ustawienie limitu czasu zaproszenia dla wszystkich organizacji konsumenta na limit czasu zaproszenia katalogu.`,
  [`Encode Query params`]: `Koduj parametry zapytania`,
  [`Encrypt`]: `Szyfruj`,
  [`Encrypt Crypto Object`]: `Obiekt szyfrujący używany do szyfrowania`,
  [`Encrypt JWK variable name`]: `Nazwa zmiennej JWK używanej do szyfrowania`,
  [`Encrypt a buffer using the specified certificate`]: `Szyfruj bufor przy użyciu określonego certyfikatu`,
  [`Encryption Algorithm`]: `Algorytm szyfrowania`,
  [`Endpoint`]: `Punkt końcowy`,
  [`Endpoint URLs`]: `Adresy URL punktów końcowych`,
  [`Endpoint behavior`]: `Zachowanie punktu końcowego`,
  [`Endpoint Behavior`]: `Zachowanie punktu końcowego`,
  [`Endpoint for unenforced APIs`]: `Punkt końcowy dla niewymuszonych interfejsów API`,
  [`Endpoints`]: `Punkty końcowe`,
  [`Enforce Required Params`]: `Wymuś wymagane parametry`,
  [`Enforce the API by using API Connect Gateway.`]: `Egzekwuj interfejs API przy użyciu API Connect Gateway.`,
  [`Enforced`]: `Wymuszone`,
  [`English`]: `angielski`,
  [`Enter Summary`]: `Wprowadź podsumowanie`,
  [`Enter URL`]: `Wprowadź adres URL`,
  [`Enter user registry name. If empty, API Manager Local User Registry is used by default`]: `Wprowadź nazwę rejestru użytkowników. Jeśli nie podasz nazwy, domyślnie zostanie użyty lokalny rejestr użytkowników produktu API Manager.`,
  [`Enter a catalog name`]: `Wprowadź nazwę katalogu`,
  [`Enter a Display Name`]: `Wprowadź nazwę wyświetlaną`,
  [`Enter a space name`]: `Wprowadź nazwę obszaru`,
  [`Enter a brief description`]: `Wprowadź krótki opis`,
  [`Enter a display name`]: `Wprowadź nazwę wyświetlaną`,
  [`Enter a temporary sign in password`]: `Wprowadź tymczasowe hasło logowania`,
  [`Enter an Organization Name`]: `Wprowadź nazwę organizacji`,
  [`Enter an Organization Title`]: `Wprowadź tytuł organizacji`,
  [`Enter catalog title`]: `Wprowadź tytuł katalogu`,
  [`Enter details of the consumer organization`]: `Wprowadź szczegóły organizacji konsumenta`,
  [`Enter details of the product`]: `Wprowadź szczegóły produktu`,
  [`Enter details of the provider organization`]: `Wprowadź szczegóły organizacji dostawcy`,
  [`Enter details of this API`]: `Wprowadź szczegóły dotyczące tego interfejsu API`,
  [`Enter email of the new user`]: `Wprowadź adres e-mail nowego użytkownika`,
  [`Enter first name of the new user`]: `Wprowadź imię nowego użytkownika`,
  [`Enter one or more email addresses. Use commas to separate multiple addresses.`]: `Wprowadź jeden lub więcej adresów e-mail. Użyj przecinków do rozdzielenia wielu adresów.`,
  [`Enter organization name`]: `Wprowadź nazwę organizacji`,
  [`Enter organization title`]: `Wprowadź tytuł organizacji`,
  [`Enter password`]: `Wprowadź hasło`,
  [`Enter space title`]: `Wprowadź tytuł obszaru`,
  [`Enter the API endpoint for the gateway by providing the hostname and port number. For example: api.mycompany.com:8090`]: `Wprowadź punkt końcowy interfejsu API dla bramy, podając nazwę hosta i numer portu. Na przykład: api.moja-firma.com.pl:8090`,
  [`Enter the API summary details`]: `Wprowadź szczegóły podsumowania interfejsu API.`,
  [`Enter the API summary details. [Learn more]({link})`]: `Wprowadź szczegóły podsumowania interfejsu API. [Więcej informacji]({link})`,
  [`Enter the URL for the target service you would like to proxy`]: `Wprowadź adres URL usługi docelowej na potrzeby proxy`,
  [`Enter the catalog summary details`]: `Wprowadź szczegóły podsumowania katalogu`,
  [`Enter the catalog summary details; you can fully configure the catalog after you create it`]: `Wprowadź szczegóły podsumowania katalogu. Katalog można w pełni skonfigurować po utworzeniu.`,
  [`Enter the email address of the user to invite as a member of the admin organization`]: `Wprowadź adres e-mail użytkownika, który ma zostać zaproszony jako członek organizacji administracyjnej`,
  [`Enter the email address of the user to invite as a member of the catalog`]: `Wprowadź adres e-mail użytkownika, który ma zostać zaproszony jako członek katalogu`,
  [`Enter the email address of the user to invite as a member of the provider organization`]: `Wprowadź adres e-mail użytkownika, który ma zostać zaproszony jako członek organizacji dostawcy`,
  [`Enter the email address of the user to invite as a member of the space`]: `Wprowadź adres e-mail użytkownika, który ma zostać zaproszony jako członek obszaru`,
  [`Enter the email address of the user to whom you want to transfer ownership.`]: `Wprowadź adres e-mail użytkownika, do którego ma zostać przekazane prawo własności.`,
  [`Enter the full path to JSON or YAML file`]: `Wprowadź pełną ścieżkę do pliku JSON lub YAML`,
  [`Enter the full path to WSDL file`]: `Wprowadź pełną ścieżkę do pliku WSDL`,
  [`Enter the full path to YAML file`]: `Wprowadź pełną ścieżkę do pliku YAML`,
  [`Enter the fully-qualified domain name for the Analytics ingestion endpoint that you defined during installation.`]: `Wprowadź pełną nazwę domenową dla punktu końcowego pozyskiwania usługi analizy, który został zdefiniowany podczas instalacji.`,
  [`Enter the last name of the new user`]: `Wprowadź nazwisko nowego użytkownika`,
  [`Enter the owner's email address to send the invitation`]: `Wprowadź adres e-mail właściciela, aby wysłać zaproszenie`,
  [`Enter the parameters to configure the gateway service. Fields are required unless designated as optional. Endpoint is API gateway service endpoint on DataPower. API endpoint base is the API gateway endpoint for incoming API calls. For SNI, enter * to allow all hosts (required), and also enter hostnames to allow specific hosts. Choose the TLS profile to support each host.`]: `Wprowadź parametry konfigurujące usługę bramy. Pola są wymagane, chyba że są oznaczone jako opcjonalne. Punkt końcowy to punkt końcowy usługi bramy interfejsu API w DataPower. Podstawą punktu końcowego interfejsu API jest punkt końcowy bramy interfejsu API dla przychodzących wywołań interfejsu API. W przypadku SNI należy wpisać *, aby zezwolić na wszystkie hosty (wymagane). Można także wprowadzić nazwy hostów, aby zezwolić na określone hosty. Wybierz profil TLS do obsługi każdego hosta.`,
  [`Enter the password for the certificate file if required.`]: `Wprowadź hasło pliku certyfikatu, jeśli jest to wymagane.`,
  [`Enter the provider organization summary details`]: `Wprowadź szczegóły podsumowania organizacji dostawcy`,
  [`Enter the schema definitiom summary details`]: `Wprowadź szczegóły podsumowania definicji schematu`,
  [`Enter the space summary details; you can fully configure the Space after you create it`]: `Wprowadź szczegóły podsumowania obszaru. Obszar można w pełni skonfigurować po utworzeniu.`,
  [`Enter the space summary details; you can fully configure the space after you create it`]: `Wprowadź szczegóły podsumowania obszaru. Obszar można w pełni skonfigurować po utworzeniu.`,
  [`Enter the summary details for the new product`]: `Wprowadź szczegóły podsumowania nowego produktu`,
  [`Enter the summary details for this role`]: `Wprowadź szczegóły podsumowania roli`,
  [`Enter title of the organization`]: `Wprowadź tytuł organizacji`,
  [`Enter user email`]: `Wprowadź adres e-mail użytkownika`,
  [`Enter username of the existing user`]: `Wprowadź nazwę istniejącego użytkownika`,
  [`Enter username of the new user`]: `Wprowadź nazwę nowego użytkownika`,
  [`Error`]: `Błąd`,
  [`Error ID`]: `Identyfikator błędu`,
  [`Error:`]: `Błąd:`,
  [`Copy error ID to Clipboard`]: `Skopiuj identyfikator błędu do schowka`,
  [`Error Console`]: `Konsola błędów`,
  [`Error connecting`]: `Błąd podczas nawiązywania połączenia`,
  [`Error connecting to GraphQL server`]: `Błąd podczas nawiązywania połączenia z serwerem GraphQL`,
  [`Error getting user info during login. Please login with different user.`]: `Błąd podczas pobierania informacji o użytkowniku podczas logowania. Zaloguj się z innym kontem użytkownika.`,
  [`Error content`]: `Treść błędu`,
  [`Error message`]: `Komunikat o błędzie`,
  [`Error name`]: `Nazwa błędu`,
  [`Error status code`]: `Kod statusu błędu`,
  [`Error status code is invalid`]: `Kod statusu błędu jest niepoprawny`,
  [`Error status reason`]: `Przyczyna statusu błędu`,
  [`Errors`]: `Błędy`,
  [`Errors usually come from the wrong information and mischaracter. Please make sure you provide the correct provider information, client information before the test.`]: `Błędy zwykle wynikają z błędnych informacji i wprowadzenia błędnych znaków. Przed rozpoczęciem testu upewnij się, że podano poprawne informacje na temat dostawcy i informacje o kliencie.`,
  [`Error trying to get API from URL {url}. Check URL, username and password. Error message: {message}`]: `Wystąpił błąd podczas próby pobrania interfejsu API z adresu URL {url}. Sprawdź adres URL oraz nazwę i hasło użytkownika. Komunikat o błędzie: {message}`,
  [`Event`]: `Zdarzenie`,
  [`Event gateway management client TLS client profile`]: `Profil klienta TLS klienta zarządzania bramą zdarzeń`,
  [`Event load`]: `Ładowanie zdarzenia`,
  [`Every consumer organization has an owner. The owner has full administration permissions, and can manage subscriptions and applications.`]: `Każda organizacja konsumenta ma właściciela. Właściciel ma pełne uprawnienia administracyjne i może zarządzać subskrypcjami oraz aplikacjami.`,
  [`Exchange authorization code for tokens`]: `Kod autoryzacji wymiany dla tokenów`,
  [`Exchange code for token`]: `Kod wymiany dla tokenu`,
  [`Execute`]: `Wykonaj`,
  [`Execute migration target`]: `Element docelowy wykonania migracji`,
  [`Executes any catalog-level post-invoke handlers`]: `Wykonuje dowolne procedury obsługi po wywołaniu na poziomie katalogu`,
  [`Executes any catalog-level pre-invoke handlers`]: `Wykonuje dowolne procedury obsługi przed wywołaniem na poziomie katalogu`,
  [`Existing`]: `Istniejący`,
  [`Existing {docType}`]: `Istniejący: {docType}`,
  [`Existing API Connect user`]: `Istniejący użytkownik interfejsu API Connect`,
  [`Existing OpenAPI`]: `Istniejąca usługa OpenAPI`,
  [`Existing product`]: `Istniejący produkt`,
  [`Existing SOAP API has been validated`]: `Sprawdzono poprawność istniejącego interfejsu API SOAP`,
  [`Existing products in the catalog will be moved to a default space.`]: `Produkty istniejące w katalogu zostaną przeniesione do obszaru domyślnego.`,
  [`Existing users are current members. You can also activate a new member from another user registry.`]: `Istniejący użytkownicy są bieżącymi członkami. Można również aktywować nowego członka z innego rejestru użytkowników.`,
  [`Expires On`]: `Traci ważność dnia`,
  [`Expiry`]: `Termin ważności`,
  [`Explorer`]: `Eksplorator`,
  [`Expose`]: `Eksponuj`,
  [`Extend the schema to specify the cost of types and fields`]: `Rozszerz schemat, aby określić koszt typów i pól`,
  [`Extended`]: `Rozszerzone`,
  [`Extension Type:`]: `Typ rozszerzenia:`,
  [`Extensions`]: `Rozszerzenia`,
  [`Extension has been uploaded.`]: `Rozszerzenie zostało przesłane.`,
  [`External`]: `Zewnętrzne`,
  [`External Doc Description`]: `Opis dokumentacji zewnętrznej`,
  [`External doc description`]: `Opis dokumentacji zewnętrznej`,
  [`External Documentation URL`]: `Adres URL dokumentacji zewnętrznej`,
  [`External documentation URL`]: `Adres URL dokumentacji zewnętrznej`,
  [`External Role mapping`]: `Odwzorowanie roli zewnętrznej`,
  [`External URL`]: `Zewnętrzny adres URL`,
  [`Extract Identity Settings`]: `Ustawienia wyodrębniania tożsamości`,
  [`Facebook`]: `Facebook`,
  [`Factor ID`]: `Identyfikator czynnika`,
  [`Fail`]: `Niepowodzenie`,
  [`Failed to change account password.`]: `Nie powiodła się próba zmiany hasła konta`,
  [`Failed to create analytics service {title}.`]: `Nie powiodła się próba utworzenia usługi analizy danych {title}.`,
  [`Failed to create analytics insights service {title}.`]: `Nie powiodło się utworzenie usługi analizy spostrzeżeń {title}.`,
  [`Analytics insights service {title} has been updated.`]: `Usługa analizy spostrzeżeń {title} została zaktualizowana.`,
  [`About the analytics insights service`]: `Informacje o usłudze analizy spostrzeżeń`,
  [`The analytics insights service collects API events from the gateway service. Each gateway service can have an associated analytics insights service.`]: `Usługa analizy spostrzeżeń gromadzi zdarzenia interfejsu API z usługi bramy. Każda usługa bramy może mieć powiązaną usługę analizy spostrzeżeń.`,
  [`Advanced analytics insights configuration`]: `Zaawansowana konfiguracja usługi analiz spostrzeżeń`,
  [`Analytics insights service {title} has been created.`]: `Usługa analizy spostrzeżeń {title} została utworzona.`,
  [`Analytics Insights details`]: `Szczegóły analizy spostrzeżeń`,
  [`Management endpoint on the analytics insights service`]: `Punkt końcowy zarządzania w usłudze analizy spostrzeżeń`,
  [`Enter the fully-qualified domain name for the Analytics Insights director endpoint that you defined during installation.`]: `Wprowadź pełną nazwę domenową dla punktu końcowego director usługi analizy spostrzeżeń, który został zdefiniowany podczas instalacji.`,
  [`TYPE`]: `TYP`,
  [`Failed to create email server`]: `Nie powiodła się próba utworzenia serwera poczty elektronicznej`,
  [`Failed to create gateway service`]: `Nie powiodła się próba utworzenia usługi bramy`,
  [`Failed to create gateway service {name}.`]: `Nie powiodła się próba utworzenia usługi bramy {name}`,
  [`Failed to create user registry {title}.`]: `Nie powiodło się utworzenie rejestru użytkowników {title}.`,
  [`Failed to delete a group`]: `Nie powiodło się usunięcie grupy.`,
  [`Failed to get TLS client profiles`]: `Nie powiodło się pobranie profili klienta TLS`,
  [`Failed to get catalogs`]: `Nie powiodło się pobranie katalogów`,
  [`Failed to get configured OAuth providers`]: `Nie powiodło się pobranie skonfigurowanych dostawców OAuth`,
  [`Failed to get policies of gateways`]: `Nie powiodło się pobranie strategii bram`,
  [`Failed to get user registries`]: `Nie powiodło się pobranie rejestrów użytkowników`,
  [`Failed to introspect schema from server:`]: `Nie powiodła się introspekcja schematu z serwera:`,
  [`Failed to make API online`]: `Nie powiodło się udostępnienie interfejsu API`,
  [`Failed to register catalog member {user}.`]: `Nie powiodło się zarejestrowanie członka katalogu {user}.`,
  [`Failed to remove analytics service: {arg}`]: `Nie powiodła się próba usunięcia usługi analizy: {arg}`,
  [`Failed to remove availability zone: {arg}`]: `Nie powiodła się próba usunięcia strefy dostępności: {arg}`,
  [`Failed to remove gateway service: {arg}`]: `Nie powiodła się próba usunięcia usługi bramy: {arg}`,
  [`Failed to remove mailserver: {arg}`]: `Nie powiodła się próba usunięcia serwera poczty: {arg}.`,
  [`Failed to remove portal service: {arg}`]: `Nie powiodła się próba usunięcia usługi portalu: {arg}.`,
  [`Failed to create {name}.`]: `Nie powiodło się utworzenie: {name}.`,
  [`Failed to sign out! Please check the console for details.`]: `Wylogowanie nie powiodło się! Szczegółowe informacje można znaleźć w konsoli.`,
  [`Failed to update {name}.`]: `Nie powiodło się zaktualizowanie: {name}.`,
  [`Failed to update OAuth provider`]: `Nie powiodła się aktualizacja dostawcy OAuth.`,
  [`Failed to update account.`]: `Nie powiodła się próba aktualizacji konta.`,
  [`Failed to update analytics service {title}.`]: `Nie powiodła się próba zaktualizowania usługi analizy danych {title}.`,
  [`Failed to update gateway service {title}.`]: `Nie powiodła się próba zaktualizowania usługi bramy {title}.`,
  [`Failed to update task`]: `Nie powiodło się zaktualizowanie zadania`,
  [`Failed to create app {title}`]: `Utworzenie aplikacji {title} się nie powiodło`,
  [`Feature flags`]: `Flagi funkcji`,
  [`Fetch schema`]: `Pobierz schemat`,
  [`Fetched endpoints`]: `Pobrane punkty końcowe`,
  [`Field`]: `Pole`,
  [`Field label`]: `Etykieta pola`,
  [`Field Mapping`]: `Odwzorowanie pól`,
  [`Field name`]: `Nazwa pola`,
  [`Field weight`]: `Waga pola`,
  [`File Name`]: `Nazwa pliku`,
  [`File Preview`]: `Podgląd pliku`,
  [`File contains validation errors`]: `Plik zawiera błędy`,
  [`File is not a valid OpenAPI definition`]: `Plik nie jest poprawną definicją dokumentacji OpenAPI`,
  [`File is not a valid extension format.`]: `Plik nie jest poprawnym formatem rozszerzenia`,
  [`File is not a valid {fileType} format.`]: `Plik nie jest poprawnym formatem {fileType}.`,
  [`File name`]: `Nazwa pliku`,
  [`File upload`]: `Przesyłanie plików`,
  [`File uploaded is not a valid file`]: `Przesłany plik jest niepoprawny`,
  [`File uploaded successfully`]: `Plik został pomyślnie przesłany`,
  [`File was updated and validated!`]: `Plik został zaktualizowany i zweryfikowany!`,
  [`File was uploaded and validated!`]: `Plik został przesłany i zweryfikowany!`,
  [`File {draftFilePath} exists. Try changing name/version combination of the draft`]: `Plik {draftFilePath} istnieje. Spróbuj zmienić kombinację nazwa/wersja kopii roboczej`,
  [`Fill out the details of this service`]: `Podaj szczegóły tej usługi`,
  [`Filter`]: `Filtr`,
  [`Filter by operation`]: `Filtruj według operacji`,
  [`Filter policies`]: `Strategie filtrowania`,
  [`Final Action`]: `Działanie końcowe`,
  [`Find existing user`]: `Znajdź istniejącego użytkownika`,
  [`Find expert answers in the API Connect community forum`]: `Służy do znajdowania odpowiedzi ekspertów na forum społeczności użytkowników produktu API Connect.`,
  [`Find expert answers in the API Connect community forum.`]: `Znajdź odpowiedzi ekspertów na forum społeczności użytkowników API Connect.`,
  [`Find schema`]: `Znajdź schemat`,
  [`Finger Print`]: `Odcisk`,
  [`Fingerprint SHA-1`]: `Odcisk SHA-1`,
  [`Fingerprint SHA-256`]: `Odcisk SHA-256`,
  [`Finish`]: `Zakończ`,
  [`First name`]: `Imię`,
  [`First specify the URL of the endpoint that the gateway uses for API traffic. The value must be a public, fully-qualified domain name with additional paths that are specific to your API calls, for example: https://api.mycompany.com.`]: `Najpierw podaj adres URL punktu końcowego używanego przez bramę do obsługi ruchu API. Wartością musi być publiczna, pełna nazwa domeny z dodatkowymi ścieżkami, które są specyficzne dla wywołań konkretnego interfejsu API, na przykład: https://api.mycompany.com.`,
  [`First specify the URL of the gateway management endpoint. For gateways running in Kubernetes environments, the value must match the endpoint specified via the gateway setup command set gwy apic-gw-service. For gateway appliances, the value must be in the form http://<ip-address-for-gateway>:3000. When working with a gateway cluster, specify the address:port of the load balancer.`]: `Najpierw podaj adres URL punktu końcowego zarządzania bramą. W przypadku bram działających w środowiskach Kubernetes wartość musi być zgodna z punktem końcowym podanym w komendzie konfiguracji bramy set gwy apic-gw-service. W przypadku bram w postaci urządzenia dedykowanego wartość musi mieć postać http://<ip-address-for-gateway>:3000. Pracując z klastrem bram, podaj adres:port systemu równoważenia obciążenia.`,
  [`Fit to screen`]: `Dopasuj do ekranu`,
  [`Flow`]: `Przepływ`,
  [`Follow redirects`]: `Używaj przekierowań`,
  [`Found In`]: `Znaleziono w`,
  [`Fonts contained in the following file are available under the`]: `Czcionki zawarte w następującym pliku są dostępne w`,
  [`For WSDL definitions, upload a zip file containing both the WSDL file and API definition (YAML)`]: `W przypadku definicji WSDL należy przesłać plik zip zawierający zarówno plik WSDL, jak i definicję interfejsu API (YAML)`,
  [`For each plan in the original product, select the plan in the replacement product that you want to migrate the subscriptions to`]: `Dla każdego planu w oryginalnym produkcie wybierz plan w produkcie zastępującym, do którego ma zostać przeprowadzona migracja subskrypcji.`,
  [`For each plan in the source product, select the plan in the target product that you want to migrate the subscriptions to`]: `Dla każdego planu w produkcie źródłowym wybierz plan w produkcie docelowym, do którego ma zostać przeprowadzona migracja subskrypcji.`,
  [`For guidance on the available command set, access the CLI's help`]: `Więcej porad dotyczących dostępnego zestawu komend można uzyskać po uzyskaniu dostępu do pomocy interfejsu CLI`,
  [`For sites using native OAuth providers, optionally specify the shared secret that will be used by all API calls.`]: `W przypadku serwisów korzystających z rodzimych dostawców OAuth opcjonalnie można określić współużytkowane dane niejawne, które będą używane przez wszystkie wywołania API.`,
  [`For writable LDAP, select User managed, and add the mapping of your source LDAP registry attribute names to the API Connect LDAP attribute values. The default user profile properties that API Connect requires during user registration are username, first_name, last_name, email, and password. An example mapping configuration is provided - edit the mapping to match your requirements`]: `W przypadku zapisywalnego katalogu LDAP można wybrać opcję Zarządzane przez użytkownika, a następnie dodać odwzorowanie nazw atrybutów źródłowego rejestru LDAP na wartości atrybutów LDAP API Connect. Domyślne właściwości profilu użytkownika, których API Connect wymaga podczas rejestracji użytkownika, to username, first_name, last_name, email i password. Dostępna jest przykładowa konfiguracja odwzorowania - można ją zmodyfikować w celu dopasowania do własnych wymagań`,
  [`Forbidden`]: `Zabronione`,
  [`Forget token`]: `Zapomnij token`,
  [`Forgot password?`]: `Nie pamiętasz hasła?`,
  [`Forgot password`]: `Nie pamiętam hasła`,
  [`Form`]: `Formularz`,
  [`Forum`]: `Forum`,
  [`French`]: `francuski`,
  [`From File`]: `Z pliku`,
  [`From Sample JSON`]: `Z przykładowego JSON`,
  [`From Sample XML`]: `Z przykładowego XML`,
  [`From URL`]: `Z adresu URL`,
  [`From existing GraphQL service (GraphQL proxy)`]: `Na podstawie istniejącej usługi GraphQL (proxy usług GraphQL)`,
  [`From existing OpenAPI service`]: `Na podstawie istniejącej usługi OpenAPI`,
  [`From existing WSDL service (REST proxy)`]: `Na podstawie istniejącej usługi WSDL (proxy usług REST)`,
  [`From existing WSDL service (SOAP proxy)`]: `Na podstawie istniejącej usługi WSDL (proxy usług SOAP)`,
  [`From target service`]: `Na podstawie usługi docelowej`,
  [`From the operations that are defined in the WSDL definition for the SOAP service, select the ones that you want to use in your API, and specify the methods and URL paths.`]: `Spośród operacji określonych w definicji WSDL usługi SOAP należy wybrać te, które mają być używane w interfejsie API, oraz określić metody i ścieżki adresów URL.`,
  [`Gateway Extensions`]: `Rozszerzenia bramy`,
  [`Gateway extension`]: `Rozszerzenie bramy`,
  [`Gateway Services`]: `Usługi bramy`,
  [`Gateway Type`]: `Typ bramy`,
  [`Gateway by Name`]: `Brama według nazwy`,
  [`Gateway services`]: `Usługi bramy`,
  [`Gateway type`]: `Typ bramy`,
  [`GET`]: `GET`,
  [`GPL v2 license`]: `Licencja GPL 2`,
  [`GROUP`]: `GRUPA`,
  [`Group name`]: `Nazwa grupy`,
  [`Gateway`]: `Brama`,
  [`Gateways`]: `Bramy`,
  [`Gateway details`]: `Szczegóły bramy`,
  [`Gateway Details`]: `Szczegóły bramy`,
  [`Gateway Error`]: `Błąd bramy`,
  [`Gateway service details`]: `Szczegóły usługi bramy`,
  [`Gateway management client TLS client profile`]: `Profil TLS klienta zarządzania bramą`,
  [`Gateway management client keystore`]: `Magazyn kluczy klienta zarządzania bramą`,
  [`Gateway management client truststore`]: `Magazyn zaufanych certyfikatów klienta zarządzania bramą`,
  [`Gateway processing status`]: `Status przetwarzania bramy`,
  [`Gateway policies (TODO)`]: `Strategie bramy (TODO)`,
  [`Gateway service`]: `Usługa bramy`,
  [`Gateway Service US East (TODO)`]: `Usługa bramy: US East (TODO)`,
  [`Gateway Service {arg} has been removed.`]: `Usługa bramy {arg} została usunięta.`,
  [`Gateway service configuration`]: `Konfiguracja usługi bramy`,
  [`Gateway service host is not available. API Test(Try it) in Explorer will not be enabled.`]: `Host usługi bramy jest niedostępny. Test interfejsu API (Wypróbuj) w Eksploratorze nie zostanie włączony.`,
  [`Gateway service configuration has been updated.`]: `Konfiguracja usługi bramy została zaktualizowana.`,
  [`Gateway service {title} has been created.`]: `Usługa bramy {title} została utworzona.`,
  [`Gateway service {title} has been updated.`]: `Usługa bramy {title} została zaktualizowana.`,
  [`Gateway services are managed in each space when spaces are enabled. Please go to the space settings to manage gateway services.`]: `Gdy obszary są włączone, usługami bramy zarządza się w poszczególnych obszarach. Przejdź do ustawień obszarów, aby zarządzać usługami bramy.`,
  [`Gateway version`]: `Wersja bramy`,
  [`GatewayScript`]: `GatewayScript`,
  [`Gateways per page`]: `Bramy na stronę`,
  [`Gather-and-send`]: `Gather-and-send`,
  [`Gather-only`]: `Gather-only`,
  [`General configuration`]: `Konfiguracja ogólna`,
  [`General information about the API.`]: `Informacje ogólne na temat interfejsu API.`,
  [`Generate`]: `Generuj`,
  [`Generate an OpenAPI definition for the proxy`]: `Generuj definicję OpenAPI dla proxy`,
  [`Generate JSON Web Token (JWT)`]: `Generuj token WWW JSON (JWT)`,
  [`Generate JWT`]: `Generuj znacznik JWT`,
  [`Generate LTPA Token`]: `Generuj token LTPA`,
  [`Generate OAuth Access Tokens`]: `Generuj tokeny dostępu OAuth`,
  [`Generate OAuth access tokens`]: `Generuj tokeny dostępu OAuth`,
  [`Generate a default product`]: `Generuj produkt domyślny`,
  [`Generate access token`]: `Generuj token dostępu`,
  [`Generate an LTPA token so that your API can securely authenticate with WebSphere Application Server web servers.`]: `Wygeneruj token LTPA w taki sposób, aby interfejs API mógł bezpiecznie uwierzytelniać się na serwerach WWW WebSphere Application Server.`,
  [`Generate an object`]: `Wygeneruj obiekt`,
  [`Generate authorization code`]: `Generuj kod autoryzacji`,
  [`Generate from sample JSON`]: `Generuj z przykładu JSON`,
  [`Generate from sample XML`]: `Generuj z przykładu XML`,
  [`Generate tests automatically to build high quality APIs`]: `Automatycznie generuj testy, by tworzyć interfejsy API o wysokiej jakości`,
  [`Generated OpenAPI 2.0 definition`]: `Wygenerowano definicję OpenAPI 2.0`,
  [`Generated default product for the API`]: `Wygenerowany produkt domyślny dla interfejsu API`,
  [`Generated name for use in commands and API calls`]: `Wygenerowana nazwa do wykorzystania w komendach i wywołaniach API`,
  [`German`]: `niemiecki`,
  [`Get Base DN`]: `Pobierz podstawową nazwę wyróżniającą`,
  [`Get Code`]: `Pobierz kod`,
  [`Get Code Snippet`]: `Pobieranie fragmentu kodu`,
  [`Get Support`]: `Uzyskaj wsparcie`,
  [`Get Token`]: `Pobierz token`,
  [`Get activation link`]: `Uzyskaj odsyłacz aktywacyjny`,
  [`Get started by registering a gateway service. This will allow you to test endpoints when configuring other services. Once you register a service, you will see it here. [Learn more]({link})`]: `Rozpocznij od zarejestrowania usługi bramy. Pozwoli to na testowanie punktów końcowych podczas konfigurowania innych usług. Po zarejestrowaniu usługi zostanie ona wyświetlona w tym miejscu. [Więcej informacji]({link})`,
  [`Get subscription ID`]: `Pobierz identyfikator subskrypcji`,
  [`Getting Started`]: `Pierwsze kroki`,
  [`Getting started`]: `Pierwsze kroki`,
  [`Git information`]: `Informacje o Git`,
  [`Github`]: `Github`,
  [`Global policies`]: `Strategie globalne`,
  [`Go to Spaces`]: `Idź do obszarów`,
  [`Go to the Test tab`]: `Przejdź na kartę Test`,
  [`Google`]: `Google`,
  [`GraphQL rate limits`]: `Limity częstotliwości GraphQL`,
  [`GraphQL service`]: `Usługa GraphQL`,
  [`GraphQL schema`]: `Schemat GraphQL`,
  [`GraphQL Schema`]: `Schemat GraphQL`,
  [`GraphQL schema has been successfully validated.`]: `Pomyślnie sprawdzono poprawność schematu GraphQL.`,
  [`GraphQL Schema URL`]: `Adres URL schematu GraphQL`,
  [`GraphQL send type`]: `Typ wysyłania GraphQL`,
  [`GraphQL Websocket Upgrade`]: `Aktualizacja komponentu Websocket GraphQL`,
  [`Group Based DN`]: `Nazwa wyróżniająca oparta na grupie`,
  [`Group {title} added`]: `Dodano grupę {title}`,
  [`Group {title} updated`]: `Zaktualizowano grupę {title}`,
  [`Group {title} has been deleted`]: `Usunięto grupę {title}`,
  [`Groups`]: `Grupy`,
  [`HEAD`]: `HEAD`,
  [`HOST NAME`]: `NAZWA HOSTA`,
  [`HOST URL`]: `ADRES URL HOSTA`,
  [`HTML`]: `HTML`,
  [`HTML Form`]: `Formularz HTML`,
  [`HTML form time limit`]: `Limit czasu formularza HTML`,
  [`HTTP`]: `HTTP`,
  [`HTTP/2 Required`]: `Wymagana HTTP/2`,
  [`HTTP Header`]: `Nagłówek HTTP`,
  [`HTTP Method`]: `Metoda HTTP`,
  [`HTTP Type`]: `Typ HTTP`,
  [`HTTP Version`]: `Wersja HTTP`,
  [`HTTPS`]: `HTTPS`,
  [`Handlebars`]: `Uchwyty`,
  [`Hard limit`]: `Twardy limit`,
  [`Header`]: `Nagłówek`,
  [`Header control`]: `Element sterujący nagłówka`,
  [`Header name payload`]: `Ładunek nazwy nagłówka`,
  [`Header name token`]: `Token nazwy nagłówka`,
  [`Headers`]: `Nagłówki`,
  [`Hello`]: `Witamy`,
  [`Hello!`]: `Witamy!`,
  [`Hello [IBM](url)`]: `Witaj [IBM](http://ibm.com)`,
  [`Hello {name}`]: `Witaj {name}`,
  [`Hello {name}!`]: `Witaj {name}!`,
  [`Hello {name}, [see IBM profile](url)`]: `Witaj {name}, [patrz profil IBM](http://bob.ibm.com)`,
  [`Hello {{firstName}},\n\nThank you for signing up for an account on the {{catalogTitle}} developer portal.\n\nTo complete your registration, use this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\ndziękujemy za zarejestrowanie konta w portalu Developer Portal {catalogTitle}.\n\nAby ukończyć rejestrację, użyj tego odsyłacza:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has reinstated the\n{{appName}} app. API calls from this app will no longer be blocked by the\nAPI gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nadministrator portalu Developer Portal {consumerOrg} przywrócił aplikację\n{appName} do normalnego działania. Wywołania interfejsów API z tej aplikacji nie będą już blokowane przez\nbramę interfejsów API.\n\nSzczegóły aplikacji można wyświetlić, klikając ten odsyłacz:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nThe administrator for the {{consumerOrg}} developer portal has suspended the\n{{appName}} app. API calls from this app will be blocked by the API gateway.\n\nYou can view the app details using this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nadministrator portalu Developer Portal {consumerOrg} zawiesił aplikację\n{appName}. Wywołania interfejsów API z tej aplikacji będą blokowane przez bramę interfejsów API.\n\nSzczegóły aplikacji można wyświetlić, klikając ten odsyłacz:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe to the {{planName}} plan\nfor version {{productVersion}} of the {{productName}} API product in the {{catalog}}\ncatalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nużytkownik {originator} zażądał zatwierdzenia subskrypcji planu {planName}\ndla wersji {productVersion} produktu API {{productName} w katalogu\n{catalog}.\n\nMożesz otworzyć to żądanie na liście zadań przy użyciu tego odsyłacza:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product\nin the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nużytkownik {originator} zażądał zatwierdzenia subskrypcji aplikacji {appName}\nw planie {planName} dla wersji {productVersion} produktu API {productName}\nw katalogu {catalog}.\n\nMożesz otworzyć to żądanie na liście zadań przy użyciu tego odsyłacza:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} the app {{appName}} in the\n{{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nużytkownik {originator} zażądał zatwierdzenia działania {action} na aplikacji {appName}\nw katalogu {catalog}.\n\nMożesz otworzyć to żądanie na liście zadań przy użyciu tego odsyłacza:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nUser {{originator}} has requested approval to {{action}} version {{version}}\nof the {{productName}} API product in the {{catalog}} catalog.\n\nYou can open the request in your task list using this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nużytkownik {originator} zażądał zatwierdzenia działania {action} dla wersji {version}\nproduktu API {productName} w katalogu {catalog}.\n\nMożesz otworzyć to żądanie na liście zadań przy użyciu tego odsyłacza:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nWe've received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn't make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Witaj {{firstName}},\n\nodebraliśmy żądanie zresetowania hasła dla Twojego konta użytkownika\n{{username}} w portalu Developer Portal {{catalog}}.\n\nJeśli to żądanie nie zostało wysłane przez Ciebie, zignoruj ten komunikat. W przeciwnym razie\nmożesz zresetować hasło przy użyciu tego odsyłacza:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in IBM API Connect.\n\nTo reset your password, use this link:\n\n{{{link}}}\n\n(If you didn’t make the request, you can ignore this message.)\n`]: `Witaj {firstName},\n\nodebraliśmy żądanie zresetowania hasła dla Twojego konta użytkownika\n{username} w produkcie IBM API Connect.\n\nAby zresetować hasło, użyj tego odsyłacza:\n\n{link}\n\nJeśli to żądanie nie zostało wysłane przez Ciebie, możesz zignorować ten komunikat.\n`,
  [`Hello {{firstName}},\n\nWe’ve received a request to reset the password for your user account\n{{username}} in the {{catalog}} developer portal.\n\nIf you didn’t make the request, just ignore this message. Otherwise, you\ncan reset your password using this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nodebraliśmy żądanie zresetowania hasła dla Twojego konta użytkownika\n{username} w portalu Developer Portal {catalog}.\n\nJeśli to żądanie nie zostało wysłane przez Ciebie, zignoruj ten komunikat. W przeciwnym razie\nmożesz zresetować hasło przy użyciu tego odsyłacza:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the app here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Witaj {firstName},\n\nżądanie utworzenia subskrypcji dla aplikacji {appName} w katalogu\n{catalog} oczekuje na zatwierdzenie. Wyślemy kolejne powiadomienie,\ngdy żądanie zostanie rozpatrzone.\n\nStatus aplikacji można sprawdzić tutaj:\n\n{link}\n\nJeśli w jakimkolwiek momencie zaistnieje potrzeba anulowania tego żądania, użyj tego odsyłacza:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Witaj {{firstName}},\n\nżądanie utworzenia subskrypcji dla aplikacji {{appName}} w planie\n{{planName}} dla produktu API {{productName}} w wersji\n{{productVersion}} zostało zatwierdzone.\n\n{{#if comments}}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n"{{comments}}"\n\n{{/if}}\nStatus aplikacji można sprawdzić tutaj:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie utworzenia subskrypcji dla aplikacji {appName} w planie\n{planName} dla produktu API {productName} w wersji\n{productVersion} zostało zatwierdzone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{comments}\n\n{/if}\nStatus aplikacji można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to create a subscription for the {{appName}} app to the\n{{planName}} plan for the API product {{productName}} version\n{{productVersion}} has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Witaj {{firstName}},\n\nżądanie utworzenia subskrypcji dla aplikacji {{appName}} w planie\n{{planName}} dla produktu API {{productName}} w wersji\n{{productVersion}} zostało odrzucone.\n\n{{#if comments}}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n"{{comments}}"\n\n{{/if}}\nStatus aplikacji można sprawdzić tutaj:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Witaj {{firstName}},\n\nżądanie dotyczące działania {{action}} dla produktu API {{productName}}:{{version}} w katalogu\n{{catalog}} zostało zatwierdzone.\n\n{{#if comments}}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{{comments}}\n\n{{/if}}\nStatus produktu można sprawdzić tutaj:\n\n{{{link}}}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla produktu API {productName}:{version} w katalogu\n{catalog} zostało zatwierdzone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{comments}\n\n{/if}\nStatus produktu można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{comments}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Witaj {firstName}},\n\nżądanie dotyczące działania {action}} dla produktu API {productName}:{version} w katalogu\n{catalog} zostało odrzucone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{comments}\n\n{/if}\nStatus produktu można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the product here:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla produktu API {productName}:{version} w katalogu\n{catalog} zostało odrzucone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{comments}\n\n{/if}\nStatus produktu można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the API product {{productName}}:{{version}} in the\n{{catalog}} catalog is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nYou can check the status of the product here:\n\n{{{link}}}\n\nIf at any time you want to cancel the request, use this link:\n\n{{{cancelLink}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla produktu API {productName}:{version} w katalogu\n{catalog} oczekuje na zatwierdzenie. Wyślemy kolejne powiadomienie,\ngdy żądanie zostanie rozpatrzone.\n\nStatus produktu można sprawdzić tutaj:\n\n{link}\n\nJeśli w jakimkolwiek momencie zaistnieje potrzeba anulowania tego żądania, użyj tego odsyłacza:\n\n{cancelLink}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla aplikacji {appName} w portalu Developer Portal\n{consumerOrg} zostało zatwierdzone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n"{comments}"\n\n{/if}\nStatus aplikacji można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been approved.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla aplikacji {appName} w portalu Developer Portal\n{consumerOrg} zostało zatwierdzone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{comments}\n\n{/if}\nStatus aplikacji można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n"{{comments}}"\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla aplikacji {appName} w portalu Developer Portal\n{consumerOrg} zostało odrzucone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n"{comments}"\n\n{/if}\nStatus aplikacji można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the app {{appName}} in the\n{{consumerOrg}} developer portal has been denied.\n\n{{#if comments}}\nThe approver included the following comments:\n\n{{{comments}}}\n\n{{/if}}\nYou can check the status of the app here:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla aplikacji {appName} w portalu Developer Portal\n{consumerOrg} zostało odrzucone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{comments}\n\n{/if}\nStatus aplikacji można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello {{firstName}},\n\nYour request to {{action}} the {{appName}} app in the {{consumerOrg}}\ndeveloper portal is awaiting approval. We'll send another notification\nwhen your request has been handled.\n\nIf at any time you want to cancel the request, use this link:\n\n{{{link}}}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla aplikacji {appName} w portalu Developer Portal {consumerOrg}\noczekuje na zatwierdzenie. Wyślemy kolejne powiadomienie,\ngdy żądanie zostanie rozpatrzone.\n\nJeśli w jakimkolwiek momencie zaistnieje potrzeba anulowania tego żądania, użyj tego odsyłacza:\n\n{link}\n`,
  [`Hello,\n\nThe account administrator for IBM API Connect has invited you to create\nan API provider organization. A provider organization allows you and your\nteam to develop APIs and manage catalogs of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witamy,\n\nadministrator konta produktu API Connect\nzaprasza do utworzenia organizacji dostawcy API. Organizacja dostawcy umożliwia użytkownikowi i jego zespołowi\n tworzenie interfejsów API i zarządzanie katalogami produktów API.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto create a space in the catalog. A space allows you and your team to\nmanage a set of API products in an API catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator katalogu interfejsów API {catalog} należącego do organizacji dostawcy API {org}\n w produkcie IBM API Connect zaprosił Cię\n do utworzenia obszaru w tym katalogu. Obszar umożliwia Tobie i twojemu zespołowi\nzarządzanie zestawem produktów API w katalogu API.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} API catalog, belonging to the\n{{org}} API provider organization in IBM API Connect, has invited you\nto the catalog.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator katalogu interfejsów API {catalog} należącego do organizacji dostawcy API {org}\n w produkcie IBM API Connect zaprosił Cię\n do katalogu.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{catalog}} developer portal has invited\nyou to create an API consumer organization. A consumer organization\nallows you and your team to access APIs and register client apps.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator portalu Developer Portal {catalog} zaprosił Cię\n do utworzenia organizacji konsumenta API. Organizacja konsumenta\numożliwia Tobie i twojemu zespołowi uzyskiwanie dostępu do interfejsów API i rejestrowanie aplikacji klienckich.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{consumerOrg}} API consumer organization in the {{catalog}}\ndeveloper portal has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator organizacji konsumenta API {consumerOrg} w portalu Developer Portal {catalog}\n zaprosił Cię do tej organizacji.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to create an API catalog. A catalog allows you\nand your team to publish and manage a collection of API products.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator organizacji dostawcy API {org} w produkcie IBM API\nConnect zaprosił Cię do utworzenia katalogu interfejsów API. Katalog umożliwia Tobie i twojemu\nzespołowi publikowanie kolekcji produktów API i zarządzanie nimi.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{org}} API provider organization in IBM API\nConnect has invited you to the organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator organizacji dostawcy API {org} w produkcie IBM API\nConnect zaprosił Cię do organizacji.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe administrator for the {{space}} space in the {{catalog}} API catalog,\nbelonging to the {{org}} API provider organization in IBM API Connect,\nhas invited you to the space.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator obszaru {space} w katalogu interfejsów API {catalog},\nktóry należy do organizacji dostawcy API {org} w produkcie IBM API Connect,\nzaprosił Cię do tego obszaru.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin IBM API Connect has been changed successfully.\n\nYou can log in to API Connect here:\n\n{{{link}}}\n`]: `Witaj,\n\nhasło dla konta użytkownika {username}\nw produkcie IBM API Connect zostało pomyślnie zmienione.\n\nMożna zalogować się do produktu API Connect tutaj:\n\n{link}\n`,
  [`Hello,\n\nThe password for your user account {{username}}\nin the {{portalTitle}} developer portal has been changed.\n\nYou can log in to the {{portalTitle}} developer portal here:\n\n{{{link}}}\n`]: `Witaj,\n\nhasło dla konta użytkownika {username}\nw portalu Developer Portal {portalTitle} zostało zmienione.\n\nMożna zalogować się do portalu Developer Portal {portalTitle} tutaj:\n\n{link}\n`,
  [`Hello,\n\nThe system administrator for IBM API Connect has invited you to the\n{{org}} organization.\n\nTo continue, please use the link below:\n\n{{{activationLink}}}\n`]: `Witaj,\n\nadministrator systemu produktu API Connect zaprasza Cię do\norganizacji {org}.\n\nAby kontynuować, użyj poniższego odsyłacza:\n\n{activationLink}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\n{{{mailServer}}}.\n\nIf you received this message as expected, the test was successful!\n`]: `Witaj,\n\nto jest wiadomość testowa z produktu IBM API Connect przesłana za pośrednictwem skonfigurowanego serwera poczty\n{mailServer}.\n\nJeśli ta wiadomość została odebrana zgodnie z oczekiwaniami, test zakończył się powodzeniem!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe to the {{planName}} plan for\nversion {{productVersion}} of the {{productName}} API product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Witaj,\n\nużytkownik {originator} wycofał żądanie subskrypcji do planu {planName} dla\nwersji {productVersion} produktu API {productName} w katalogu {catalog}.\n\nNie są wymagane żadne dalsze działania.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} the app {{appName}} in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Witaj,\n\nużytkownik {originator} wycofał żądanie wykonania operacji {action} dla aplikacji {appName} w katalogu {catalog}.\n\nNie są wymagane żadne dalsze działania.\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to {{action}} version {{version}} of the {{productName}}\nAPI product in the {{catalog}} catalog.\n\nNo further action is required.\n`]: `Witaj,\n\nużytkownik {originator} wycofał żądanie wykonania operacji {action} dla wersji {version} produktu API {productName}\nw katalogu {catalog}.\n\nNie są wymagane żadne dalsze działania.\n`,
  [`Hello, This is a test message from IBM API Connect from the configured mail server {{{mailServer}}}. If you received this message as expected, the test was successful`]: `Witaj, to jest wiadomość testowa z produktu IBM API Connect przesłana za pośrednictwem skonfigurowanego serwera poczty {{{mailServer}}}. Jeśli ta wiadomość została odebrana zgodnie z oczekiwaniami, test zakończył się powodzeniem.`,
  [`Hello|header`]: `Witamy`,
  [`Help`]: `Pomoc`,
  [`Hi, Steve (todo)`]: `Witaj, Steve (do wykonania)`,
  [`Hide`]: `Ukryj`,
  [`Hide field`]: `Ukryj pole`,
  [`Hide in schema`]: `Ukryj w schemacie`,
  [`Hide new types, fields, directives or optional arguments. Items that are no longer supported by the backend will be removed.`]: `Ukryj nowe typy, pola dyrektywy lub argumenty opcjonalne. Elementy, które nie są już obsługiwane przez zaplecze, zostaną usunięte.`,
  [`High`]: `Wysoki`,
  [`History`]: `Historia`,
  [`Histories`]: `Historie`,
  [`History log`]: `Dziennik historii`,
  [`Home`]: `Strona główna`,
  [`Host`]: `Host`,
  [`Host context variable`]: `Zmienna kontekstowa hosta`,
  [`Host name`]: `Nazwa hosta`,
  [`Host value (if present)`]: `Wartość hosta (jeśli istnieje)`,
  [`Hostname`]: `Nazwa hosta`,
  [`Hours`]: `Godziny`,
  [`Http basic authentication schema`]: `Podstawowy schemat uwierzytelniania Http`,
  [`Hmm, your search didn't return any results.`]: `Wyszukiwanie nie zwróciło żadnych wyników.`,
  [`HTTP(s)`]: `HTTP(s)`,
  [`IBM API Connect cloud`]: `Chmura IBM API Connect`,
  [`IBM Cloud`]: `IBM Cloud`,
  [`IBM Cloud Authentication`]: `Uwierzytelnianie w IBM Cloud`,
  [`IBM Cloud Docs`]: `Dokumenty platformy IBM Cloud`,
  [`IBM Schema`]: `Schemat IBM`,
  [`IBM managed`]: `Zarządzana przez IBM`,
  [`ID Name`]: `Nazwa identyfikatora`,
  [`ID token custom claims`]: `Niestandardowe roszczenia tokenu identyfikatora`,
  [`ID token issuer`]: `Wystawca tokenu identyfikatora`,
  [`ID token signing algorithm`]: `Algorytm podpisywania tokenu identyfikatora`,
  [`ID token signing crypto object`]: `Obiekt szyfrujący do podpisywania tokenu identyfikatora`,
  [`ID token signing key`]: `Klucz podpisywania tokenu identyfikatora`,
  [`ID token signing key identifier`]: `Identyfikator klucza podpisywania tokenu identyfikatora`,
  [`IDENTITY PROVIDER`]: `DOSTAWCA TOŻSAMOŚCI`,
  [`IDENTITY PROVIDER SUPPORT`]: `OBSŁUGA DOSTAWCY TOŻSAMOŚCI`,
  [`Identification`]: `Identyfikacja`,
  [`Identity extraction`]: `Wyodrębnianie tożsamości`,
  [`If`]: `Jeśli`,
  [`If Production mode is disabled, meaning that this is a Development Catalog, all publish operations are forced, and any conflicts are resolved automatically, allowing you, for example, to repeatedly republish the same Product version during testing.`]: `Jeśli tryb produkcyjny jest wyłączony, co oznacza, że jest to katalog programistyczny, wszystkie operacje publikowania są wymuszane, a wszystkie konflikty są rozstrzygane automatycznie, umożliwiając na przykład ponowne publikowanie tej samej wersji produktu podczas testowania.`,
  [`If Production mode is enabled, meaning that this a Production Catalog, you will be prevented from publishing a Product to the Catalog if there are conflicts with any Products that are already published; you must resolve any conflicts before retrying the publish operation.`]: `Jeśli tryb produkcyjny jest włączony, co oznacza, że jest to katalog produkcyjny, nie będzie można opublikować produktu w tym katalogu, jeśli istnieją jakiekolwiek konflikty z już opublikowanymi produktami. Należy rozstrzygnąć wszystkie konflikty przed podjęciem kolejnej próby wykonania operacji publikowania.`,
  [`If Spaces are enabled in a Catalog, you can manage the members within the Space. You manage Space membership by adding new users to the Space and assigning roles to the users.`]: `Włączenie obszarów w katalogu pozwala zarządzać członkami w obrębie obszaru. Zarządzanie przynależnością do obszaru oznacza dodawanie nowych użytkowników do obszaru i przypisywanie ról do użytkowników.`,
  [`If enabled, input properties with a null value will be mapped to the output document. Otherwise, those properties will be omitted from the output.`]: `Jeśli pole wyboru zostanie zaznaczone, wartości wejściowe z wartością pustą będą odwzorowywane na dokument wyjściowy. W przeciwnym razie te właściwości będą pomijane na wyjściu.`,
  [`If production mode is enabled, any staging and publishing actions will not be forced. If conflicts are found, they will be automatically resolved by the system. Unpublish actions will happen automatically.`]: `Jeśli tryb produkcyjny zostanie włączony, żadne działania przemieszczania i publikowania nie będą wymuszane. Jeśli zostaną znalezione konflikty, zostaną one automatycznie rozwiązane przez system. Działania cofania publikacji zostaną przeprowadzone automatycznie.`,
  [`If recipients didn't receive the email, please check the email server settings.`]: `Jeśli odbiorcy nie otrzymali tej wiadomości e-mail, sprawdź ustawienia serwera poczty elektronicznej.`,
  [`If recipients didn't receive the email, please check the email server settings for {username}.`]: `Jeśli odbiorcy nie otrzymali tej wiadomości e-mail, sprawdź ustawienia serwera poczty e-mail dla użytkownika {username}.`,
  [`If set to a value of true, all + characters in the query parameter values of the target-url of the Invoke and Proxy policies are encoded to %2B.`]: `Jeśli zostanie ustawiona wartość true, wszystkie znaki + w wartościach parametrów zapytania target-url strategii wywoływania i proxy są kodowane jako %2B.`,
  [`If set to a value of true, any request parameters that are referenced by a variable definition on an invoke target-url are URL-decoded.`]: `Jeśli zostanie ustawiona wartość true, wszystkie parametry żądania przywoływane przez definicję zmiennych w docelowym adresie URL wywoływania są dekodowane jako adresy URL.`,
  [`If set to a value of true, the invoke policy sends a payload on an HTTP DELETE method.`]: `Jeśli zostanie ustawiona wartość true, strategia wywoływania wysyła ładunek przy użyciu metody HTTP DELETE.`,
  [`If set to true, the property will be redacted or removed from the activity logs.`]: `Jeśli zostanie ustawiona wartość true, właściwość będzie redagowana lub zostanie usunięta z dzienników działań.`,
  [`If set to true, the property will be redacted or removed from the input at the beginning of the execution.`]: `Jeśli zostanie ustawiona wartość true, właściwość będzie redagowana lub zostanie usunięta z danych wejściowych na początku wykonywania.`,
  [`If set to true, the property will be redacted or removed from the response.`]: `Jeśli zostanie ustawiona wartość true, właściwość będzie redagowana lub zostanie usunięta z odpowiedzi.`,
  [`If spaces are enabled in a catalog, you can manage the access that users have within the space. You manage access by specifying the permissions that are assigned to user roles. The permissions that are assigned to each role by default when you create a new space are determined by the settings in the default space permissions template.`]: `Włączenie obszarów w katalogu pozwala zarządzać dostępem użytkowników w obrębie obszaru. Dostępem zarządza się, określając uprawnienia przypisane do ról użytkowników. Uprawnienia domyślnie przypisane do każdej roli podczas tworzenia nowego obszaru są określane przez ustawienia w szablonie domyślnych uprawnień w obszarze.`,
  [`If the check box is selected (the default option), XML namespaces are inherited from the parent element. Clear the check box if you want the map policy to use explicit namespaces.`]: `Jeśli pole wyboru zostanie zaznaczone (opcja domyślna), przestrzenie nazw XML będą dziedziczone z elementu nadrzędnego. Usuń zaznaczenie pola wyboru, jeśli chcesz, aby strategia odwzorowania używała jawnych przestrzeni nazw.`,
  [`If the check box is selected (the default option), XML namespaces will be inserted into the document where they are first used. Clear the check box if you want namespaces to all be defined on the root element.`]: `Jeśli pole wyboru zostanie zaznaczone (opcja domyślna), przestrzenie nazw XML będą wstawiane do dokumentu, w którym zostały użyte po raz pierwszy. Usuń zaznaczenie pola wyboru, jeśli chcesz, aby wszystkie przestrzenie nazw były definiowane w elemencie głównym.`,
  [`If the check box is selected (the default option), empty XML elements are included in the output of the map policy. Clear the check box if you do not want empty XML elements to be included in the output of the map policy.`]: `Jeśli pole wyboru zostanie zaznaczone (opcja domyślna), puste elementy XML będą uwzględniane w danych wyjściowych strategii odwzorowania. Usuń zaznaczenie pola wyboru, jeśli nie chcesz, aby puste elementy XML były uwzględniane w danych wyjściowych strategii odwzorowania.`,
  [`If the check box is selected, post processing of mapped JSON output is enabled. The post processing of JSON output will use the output schema to ensure that property values are of the same data type as that defined in the schema. It will also normalize output property values that have a Badgerfish JSON syntax due to object mapping of an XML input. Clear the check box (the default) for no post processing of mapped JSON output.`]: `Jeśli to pole wyboru zostanie zaznaczone, przetwarzanie końcowe odwzorowanych danych wyjściowych JSON będzie włączone. Przetwarzanie końcowe danych wyjściowych JSON będzie korzystało ze schematu wyjściowego, aby mieć pewność, że wartości właściwości są tego samego typu co te zdefiniowane w schemacie. Ponadto wartości właściwości wyjściowych, które mają składnię Badgerfish JSON z powodu odwzorowania obiektów danych wejściowych XML, będą normalizowane. Usuń zaznaczenie tego pola wyboru (ustawienie domyślne), aby nie wykonywać przetwarzania końcowego odwzorowanych danych wyjściowych JSON.`,
  [`If the checkbox is selected (the default option), API Connect variable references found in the map properties will be resolved. Clear the check box if you want the map policy to ignore API Connect variable references in the map policies.`]: `Jeśli pole wyboru zostanie zaznaczone (opcja domyślna), odwołania do zmiennych produktu API Connect znalezione we właściwościach odwzorowania będą rozstrzygane. Usuń zaznaczenie pola wyboru, jeśli chcesz, aby strategia odwzorowania ignorowała odwołania do zmiennych produktu API Connect w strategiach odwzorowania.`,
  [`If the checkbox is selected, API Connect variable references found in the map properties will be resolved. Clear the checkbox (the default option) if you want the map policy to ignore API Connect variable references in the map policies.`]: `Jeśli pole wyboru zostanie zaznaczone, odwołania do zmiennych produktu API Connect znalezione we właściwościach odwzorowania będą rozstrzygane. Usuń zaznaczenie pola wyboru (opcja domyślna), jeśli chcesz, aby strategia odwzorowania ignorowała odwołania do zmiennych produktu API Connect w strategiach odwzorowania.`,
  [`If the checkbox is selected (the default option), if an array is encountered in the traversal of the input, only the first element will be used. Clear the check box if you want the map policy to use all array elements.`]: `Jeśli pole wyboru zostanie zaznaczone (opcja domyślna), w przypadku napotkania tablicy podczas przeglądania danych wejściowych będzie używany tylko pierwszy element. Usuń zaznaczenie pola wyboru, jeśli chcesz, aby strategia odwzorowania używała wszystkich elementów tablicy.`,
  [`If the checkbox is selected, XML elements whose schema is configured as type boolean or numeric will be converted to that data type. If not selected (the default option) all XML element values will be returned as a string.`]: `Jeśli to pole wyboru zostanie zaznaczone, elementy XML, których schemat został skonfigurowany jako typ boolowski lub liczbowy, będą przekształcane w ten typ danych. Jeśli nie zostanie zaznaczone (opcja domyślna), wszystkie wartości elementu XML będą zwracane jako łańcuchy.`,
  [`If the checkbox is selected, complex schema types evaluation will handle circular type references in an optimized manner. Clear this checkbox (the default option) to evaluate these schema types in a standard manner.`]: `Jeśli pole wyboru zostanie zaznaczone, wartościowanie typów złożonych schematu będzie obsługiwać cykliczne odwołania do typu w zoptymalizowany sposób. Usuń zaznaczenie tego pola wyboru (opcja domyślna), aby wartościować te typy schematów w standardowy sposób.`,
  [`If the error is caused by a lack of CORS support, click the link to open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Jeśli błąd jest spowodowany brakiem obsługi CORS, kliknij ten odsyłacz, aby otworzyć serwer na nowej karcie. Jeśli przeglądarka wyświetli komunikat o problemie z certyfikatem, możesz go zaakceptować i wrócić tutaj, aby ponownie wykonać test.`,
  [`If this checkbox is selected, any output array or child property of an optional object noted in the schema as required will have required child object properties initialized to a default value unless successfully mapped. This will emulate how version 4 mapping was done for required properties for these objects. Clear this checkbox (the default value) to only output these objects with successfully mapped data.`]: `Jeśli to pole wyboru zostanie zaznaczone, każda wynikowa tablica lub właściwość potomna obiektu opcjonalnego oznaczona w schemacie jako wymagana będzie miała właściwości wymaganego obiektu potomnego zainicjowane wartością domyślną, chyba że zostanie pomyślnie odwzorowana. Dzięki temu będzie emulowany sposób odwzorowywania wymaganych właściwości tych obiektów stosowany w wersji 4. Usuń zaznaczenie tego pola wyboru (wartość domyślna), aby wyprowadzać tylko te obiekty, dla których pomyślnie odwzorowano dane.`,
  [`If this checkbox is selected (default) and the owner user is changed, the ownership of any child-resources (Spaces) will also be transferred to the new owner.`]: `Jeśli to pole wyboru jest zaznaczone (domyślnie), a użytkownik właściciel zostanie zmieniony, prawo własności do wszelkich zasobów potomnych (Obszarów) również zostanie przeniesione na nowego właściciela.`,
  [`If this option is selected, the map policy will write XML output to message.body as a parsed XML document. By default, the XML will be output as an XML string. XML output to any other variable will always be written as an XML string.`]: `Gdy ta opcja jest wybrana, strategia odwzorowania będzie zapisywać wynikowy kod XML w treści message.body jako analizowany dokument XML. Domyślnie kod XML będzie generowany jako łańcuch XML. Kod XML kierowany do dowolnej innej zmiennej będzie zawsze zapisywany jako łańcuch XML.`,
  [`If you enable lifecycle approval for the Stage, Publish, or Replace actions, then some API creation, editing, or testing operations that perform these actions automatically might not function correctly. Are you sure you want to enable any of these approvals?`]: `Jeśli użytkownik włączy zatwierdzanie cyklu życia dla działań Etap, Publikowanie lub Zastępowanie, to niektóre operacje tworzenia, edytowania i testowania interfejsu API, które automatycznie wykonują te działania, mogą nie działać poprawnie. Czy na pewno chcesz włączyć któreś z tych zatwierdzeń?`,
  [`If you have an existing SOAP service that you want to expose through an API Connect API definition, you can create an API and specify the target SOAP service. API Connect will then create an API definition that calls that SOAP service.`]: `Jeśli istnieje usługa SOAP, która ma zostać ujawniona za pomocą definicji interfejsu produktu API Connect, można utworzyć interfejs API i określić docelową usługę SOAP. Produkt API Connect utworzy definicję interfejsu API, który wywołuje tę usługę SOAP.`,
  [`If you save your changes by clicking "Yes. Update API Assembly", the API assembly that underlies this OAuth provider will also be updated. However, if you have customized the underlying API assembly and want to update it separately, click "No. Do not update API Assembly", then update the underlying API assembly in the API Editor.`]: `Jeśli zmiany zostaną zapisane przez kliknięcie przycisku Tak. Zaktualizuj zespół API, zespół API, na którym jest oparty dostawca OAuth, również zostanie zaktualizowany. Jeśli jednak bazowy zespół interfejsu API został dostosowany i chcesz zaktualizować go oddzielnie, kliknij opcję "Nie. Nie aktualizuj zespołu interfejsu API", a następnie zaktualizuj bazowy zespół interfejsu API w edytorze interfejsów API.`,
  [`If you are still having issues, upload a schema definition language file.`]: `Jeśli problemy nadal występują, prześlij plik języka definicji schematu.`,
  [`If your file contains both the private and public keys, upload it in step 1`]: `Jeśli plik zawiera zarówno klucze prywatne, jak i publiczne, należy go przesłać w kroku 1.`,
  [`If you need to register a gateway that is located behind a firewall, see the IBM Cloud Docs for information on using a VPN or the Secure Gateway service to establish the connection.`]: `Jeśli konieczne jest zarejestrowanie bramy, która znajduje się za firewallem, należy zapoznać się z informacjami na temat używania sieci VPN lub usługi bezpiecznej bramy w celu nawiązania połączenia z programem IBM Cloud Docs.`,
  [`Ignore`]: `Ignoruj`,
  [`Implement policy`]: `Wdróż strategię`,
  [`Implementation file is required`]: `Plik implementacji jest wymagany`,
  [`Implicit`]: `Niejawne`,
  [`Import`]: `Importuj`,
  [`Import API`]: `Importuj interfejs API`,
  [`Import API|title`]: `Import interfejsu API`,
  [`Import OpenAPI definition from asset repository`]: `Import definicji interfejsu OpenAPI z repozytorium zasobów`,
  [`Import Product`]: `Importuj produkt`,
  [`Import policy`]: `Importuj strategię`,
  [`Import a product from a service URL`]: `Import produktu z adresu URL usługi`,
  [`Import a product from the definition file`]: `Import produktu z pliku definicji`,
  [`Import an existing product`]: `Importuj istniejący produkt`,
  [`Import an OpenAPI definition from a URL`]: `Importuj definicję OpenAPI z adresu URL`,
  [`Import an OpenAPI definition from a file`]: `Importuj definicję OpenAPI z pliku`,
  [`Import definitions`]: `Importuj definicje`,
  [`Import from existing API service`]: `Importuj z istniejącej usługi API`,
  [`Import from file`]: `Import z pliku`,
  [`Importing an API definition`]: `Import definicji interfejsu API`,
  [`Import|title`]: `Import`,
  [`Import the required certificates and CA bundles into the Certificate Manager service.`]: `Zaimportuj wymagane certyfikaty i pakunki CA do usługi Certificate Manager.`,
  [`In Cookie Header`]: `W nagłówku informacji cookie`,
  [`In WSSec Header`]: `W nagłówku WSSec`,
  [`In order to invoke the endpoints above, you need to first`]: `W celu wywołania powyższych punktów końcowych należy najpierw wykonać następującą operację:`,
  [`Inactive`]: `Nieaktywne`,
  [`Inc`]: `Uwzględnij`,
  [`Include empty`]: `Uwzględnij puste`,
  [`Include free trial days`]: `Uwzględnij dni bezpłatnego okresu próbnego`,
  [`Incompatible apis with the configured gateway service can't be selected.`]: `Nie można wybrać niekompatybilnych interfejsów API ze skonfigurowaną usługą bramy.`,
  [`Incorrect username or password`]: `Niepoprawna nazwa użytkownika lub hasło`,
  [`Incorrect username, password, or credentials`]: `Niepoprawna nazwa użytkownika, hasło lub referencje`,
  [`Indicate if the invoke is the final action`]: `Wskaż, czy wywołanie jest działaniem końcowym`,
  [`Indicates whether a JWT ID (jti) claim should be added to the JWT. If selected, the jti claim value will be a UUID.`]: `Wskazuje, czy roszczenie identyfikatora JWT (jti) powinno zostać dodane do znacznika JWT. W razie wybrania wartością roszczenia jti będzie identyfikator UUID.`,
  [`Indicates whether the clients secret is required.`]: `Wskazuje, czy wymagane są dane niejawne klienta.`,
  [`Indicates whether this XSLT input document uses the context current payload, or if there is no input.`]: `Wskazuje, czy ten dokument wejściowy XSLT używa kontekstu bieżącego ładunku, czy też nie ma danych wejściowych.`,
  [`Indicates whether to stop processing if client security fails.`]: `Wskazuje, czy ma zostać zatrzymane przetwarzanie, gdy zabezpieczenia klienta zawiodą.`,
  [`Infer from JSON schema`]: `Wywnioskuj ze schematu JSON`,
  [`Infer from Sample`]: `Wnioskowanie z przykładu`,
  [`Info`]: `Informacje`,
  [`Information context variable`]: `Zmienna kontekstowa informacji`,
  [`Inject proxy headers`]: `Wstawiaj nagłówki proxy`,
  [`Inline schema`]: `Schemat wstawiany`,
  [`Input`]: `Wejście`,
  [`Input field`]: `Pole wejściowe`,
  [`Input object`]: `Obiekt wejściowy`,
  [`Insecure Server Connections`]: `Niezabezpieczone połączenia z serwerem`,
  [`Install API Connect CLI & API Designer|button text`]: `Instaluj interfejs CLI produktu API Connect i produkt API Designer`,
  [`Install API Connect CLI & API Designer|title`]: `Instalacja interfejsu CLI produktu API Connect i produktu API Designer`,
  [`Install API Connect Toolkit`]: `Zainstaluj bibliotekę narzędziową API Connect`,
  [`Install the API Connect plugin`]: `Zainstaluj wtyczkę produktu API Connect`,
  [`Instance ID`]: `Identyfikator instancji`,
  [`Instance name`]: `Nazwa instancji`,
  [`Instance owner`]: `Właściciel instancji`,
  [`Interact to expand Tile`]: `Interakcja w celu rozwinięcia kafla`,
  [`Interact to collapse Tile`]: `Interakcja w celu zwinięcia kafla`,
  [`Interval`]: `Interwał`,
  [`Interface`]: `Interfejs`,
  [`Introspect`]: `Introspekcja`,
  [`Introspect cache type`]: `Typ pamięci podręcznej introspekcji`,
  [`Introspect from Server URL`]: `Introspekcja z adresu URL serwera`,
  [`Introspect from URL`]: `Introspekcja z adresu URL`,
  [`Introspect URL`]: `Adres URL introspekcji`,
  [`Introspecting using a different URL will not change the GraphQL server URL.`]: `Introspekcja z użyciem innego adresu URL nie spowoduje zmiany adresu URL serwera GraphQL`,
  [`Introspect token`]: `Przeprowadź introspekcję tokenu`,
  [`Introspection`]: `Introspekcja`,
  [`Introspection Path`]: `Ścieżka introspekcji`,
  [`Introspect the GraphQL schema from the server or upload the schema`]: `Przeprowadź introspekcję schematu GraphQL z serwera lub prześlij schemat`,
  [`Introspect the GraphQL schema using the query`]: `Introspekcja schematu GraphQL przy użyciu zapytania`,
  [`Introspection URL`]: `Adres URL introspekcji`,
  [`Invalid HOST URL`]: `Niepoprawny adres URL hosta`,
  [`Invalid URL. Please enter valid API Manager HOST URL. e.g https://api-connect-host.com`]: `Niepoprawny adres URL. Wprowadź poprawny adres URL hosta produktu API Manager. Na przykład: https://host-api-connect.com.`,
  [`Invalid file URL`]: `Niepoprawny adres URL pliku`,
  [`Invalid URL format, must start with http:// or https:// and contain at least 1 character`]: `Niepoprawny format adresu URL. Adres musi rozpoczynać się od łańcucha http:// lub https:// i zawierać co najmniej 1 znak`,
  [`Invalid URL format, only hostname needs to be specified. i.e syslog:// can be removed`]: `Niepoprawny format adresu URL, należy podać tylko nazwę hosta. To znaczy: można usunąć syslog://`,
  [`Invalid emails.`]: `Niepoprawne adresy e-mail.`,
  [`Invalid JSONata expression`]: `Niepoprawne wyrażenie JSONata`,
  [`Invalid remove target`]: `Niepoprawny cel usuwania`,
  [`Invalid assumed size`]: `Niepoprawna zakładana wielkość`,
  [`Invalid cost target`]: `Niepoprawny koszt docelowy`,
  [`Invalid list size`]: `Niepoprawna wielkość listy`,
  [`Invalid port value, must not be negative`]: `Niepoprawny numer portu. Nie może być ujemny.`,
  [`Invalid sized fields`]: `Niepoprawne wielkości pól`,
  [`Invalid value, length has to be greater than 1`]: `Niepoprawna wartość. Długość musi być większa od 1.`,
  [`Invalid weight value`]: `Niepoprawna wartość wagi`,
  [`invitation`]: `invitation`,
  [`Invitation`]: `Zaproszenie`,
  [`Invitation timeout`]: `Limit czasu zaproszenia`,
  [`Invitation link will expire in {time}`]: `Odsyłacz do zaproszenia utraci ważność za **{time}**`,
  [`Invitation link will expire in **{seconds} seconds ({time})**`]: `Odsyłacz do zaproszenia utraci ważność za **{seconds} s ({time})**`,
  [`Invitation link will expire in __{value} {unit}__.`]: `Odsyłacz do zaproszenia utraci ważność po __{value} {unit}__.`,
  [`Invitation sent to`]: `Zaproszenie zostało wysłane do użytkownika`,
  [`Invitation sent to {email}`]: `Wysłano zaproszenie do {email}`,
  [`Invitation to an API catalog in IBM API Connect`]: `Zaproszenie do katalogu interfejsów API w produkcie IBM API Connect`,
  [`Invitation to an API consumer organization in the {{catalog}} developer portal`]: `Zaproszenie do organizacji konsumenta API w portalu Developer Portal {catalog}`,
  [`Invitation to an API provider organization in IBM API Connect`]: `Zaproszenie do organizacji dostawcy API w produkcie IBM API Connect`,
  [`Invitation to an API space in IBM API Connect`]: `Zaproszenie do obszaru API w produkcie IBM API Connect`,
  [`Invitation to an admin organization in IBM API Connect`]: `Zaproszenie do organizacji administratora w produkcie IBM API Connect`,
  [`Invitation to create an API catalog in IBM API Connect`]: `Zaproszenie do utworzenia katalogu interfejsów API w produkcie IBM API Connect`,
  [`Invitation to create an API consumer organization in the {{catalog}} developer portal`]: `Zaproszenie do utworzenia organizacji konsumenta API w portalu Developer Portal {catalog}`,
  [`Invitation to create an API provider organization in IBM API Connect`]: `Zaproszenie do utworzenia organizacji dostawcy API w produkcie IBM API Connect`,
  [`Invitation to create an API space in IBM API Connect`]: `Zaproszenie do utworzenia obszaru API w produkcie IBM API Connect`,
  [`Invitation ttl has been created.`]: `Utworzono ttl zaproszenia.`,
  [`Invitation ttl has been updated.`]: `Zaktualizowano ttl zaproszenia.`,
  [`Invitation ttl has not been created!`]: `Nie utworzono ttl zaproszenia!`,
  [`Invitation ttl has not been updated!`]: `Nie zaktualizowano ttl zaproszenia!`,
  [`Invite`]: `Zaproś`,
  [`Invite catalog owner`]: `Zaproś właściciela katalogu`,
  [`Invite consumer organization owner`]: `Zapraszanie właściciela organizacji konsumenta`,
  [`Invite member`]: `Zaproś członka`,
  [`Invite organization owner`]: `Zaproś właściciela organizacji`,
  [`Invite space owner`]: `Zaproś właściciela obszaru`,
  [`Invite a new consumer organization owner`]: `Zapraszanie nowego właściciela organizacji konsumenta`,
  [`Invite a new member`]: `Zapraszanie nowego członka`,
  [`Invite a new provider organization owner`]: `Zapraszanie nowego właściciela organizacji dostawcy`,
  [`Invite a new user to register as manager of a catalog by entering the recipient email address. An invitation email is sent to the user with a link to the registration form`]: `Zaproś nowego użytkownika, by zarejestrował się jako menedżer katalogu, wprowadzając adres e-mail odbiorcy. Do użytkownika wysyłana jest wiadomość e-mail z zaproszeniem zawierająca odsyłacz do formularza rejestracji.`,
  [`Invite a new user to register as manager of a space by entering the recipient email address. An invitation email is sent to the user with a link to the registration form.`]: `Zaproś nowego użytkownika, by zarejestrował się jako menedżer obszaru, wprowadzając adres e-mail odbiorcy. Do użytkownika wysyłana jest wiadomość e-mail z zaproszeniem zawierająca odsyłacz do formularza rejestracji.`,
  [`Invite by email`]: `Zaproś pocztą elektroniczną`,
  [`Invite from User Registry`]: `Zaproś z rejestru użytkowników`,
  [`Invite team member`]: `Zaproś członka zespołu`,
  [`Inviting members to consumer organizations is enabled`]: `Zapraszanie członków do organizacji konsumentów jest włączone`,
  [`Invocations`]: `Wywołania`,
  [`Invoke`]: `Wywołaj`,
  [`Issuer Claim`]: `Roszczenie wystawcy`,
  [`Issuer Origin`]: `Pochodzenie wystawcy`,
  [`Issuer common name`]: `Nazwa zwykła wystawcy`,
  [`It might influence how the API is deployed`]: `Może wpłynąć na sposób wdrażania interfejsu API`,
  [`Italian`]: `włoski`,
  [`Items per page`]: `Liczba elementów na stronie`,
  [`Items per page:`]: `Liczba elementów na stronie:`,
  [`items selected`]: `wybrane elementy`,
  [`item selected`]: `wybrany element`,
  [`Iterate on`]: `Iteruj w`,
  [`Iterate?`]: `Iterować?`,
  [`JSON objects describing re-usable channel parameters.`]: `Obiekty JSON opisujące parametry kanału wielokrotnego użytku.`,
  [`JSON objects describing the messages being consumed and produced by the API.`]: `Obiekty JSON opisujące komunikaty używane i generowane przez interfejs API.`,
  [`JSON Schema URL`]: `Adres URL schematu JSON`,
  [`JSON Schema preview`]: `Podgląd schematu JSON`,
  [`JSON Web Token (JWT)`]: `Token WWW JSON (JWT)`,
  [`JSON has been successfully validated`]: `Pomyślnie sprawdzono poprawność danych JSON`,
  [`JSON schema is not valid. Please correct the error.`]: `Schemat JSON jest niepoprawny. Popraw błąd.`,
  [`JSON to XML`]: `JSON do XML`,
  [`JWT ID Claim`]: `Roszczenie identyfikatora JWT`,
  [`JWT verification JWK`]: `JWK weryfikacji JWT`,
  [`JWT verification crypto object`]: `Obiekt szyfrujący weryfikacji JWT`,
  [`Japanese`]: `japoński`,
  [`JavaScript`]: `JavaScript`,
  [`Jobs queue`]: `Kolejka zadań`,
  [`Jump to source`]: `Skocz do źródła`,
  [`Just now`]: `Właśnie teraz`,
  [`KEY EXCHANGE / ENCRYPTION / MAC`]: `WYMIANA KLUCZY / SZYFROWANIE / MAC`,
  [`KEY SIZE`]: `WIELKOŚĆ KLUCZA`,
  [`Keystore`]: `Magazyn kluczy`,
  [`keystore`]: `magazyn kluczy`,
  [`Keep`]: `Zachowaj`,
  [`Keep Payload`]: `Zachowuj ładunek`,
  [`Key Encryption Algorithm`]: `Algorytm szyfrowania klucza`,
  [`Key Name`]: `Nazwa klucza`,
  [`Key type`]: `Typ klucza`,
  [`Key name`]: `Nazwa klucza`,
  [`Keystore for the token has been updated.`]: `Magazyn kluczy dla tokenu został zaktualizowany.`,
  [`Keystore Certificates`]: `Certyfikaty magazynu kluczy`,
  [`Keystore/truststore`]: `Magazyn kluczy / magazyn zaufanych certyfikatów`,
  [`Korean`]: `koreański`,
  [`LABEL`]: `ETYKIETA`,
  [`LABELS`]: `ETYKIETY`,
  [`LAST MODIFIED`]: `OSTATNIA MODYFIKACJA`,
  [`Last state changed`]: `Zmiana ostatniego stanu`,
  [`Large`]: `Duży`,
  [`Lax`]: `Swobodne`,
  [`LDAP`]: `LDAP`,
  [`LDAP groups`]: `Grupy LDAP`,
  [`LDAP user registry`]: `Rejestr użytkowników LDAP`,
  [`LDAP registry`]: `Rejestr LDAP`,
  [`LDAP registry name`]: `Nazwa rejestru LDAP`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Enable External group mapping for this user registry or disable External group mapping in role before saving.`]: `Rejestr użytkowników LDAP roli {{name}} nie obsługuje już odwzorowania grupy zewnętrznej. Włącz odwzorowywanie grup zewnętrznych dla tego rejestru użytkowników lub wyłącz odwzorowanie grupy zewnętrznej w roli przed zapisaniem.`,
  [`Role's LDAP user registry {{name}} no longer supports External group mapping. Select another user registry with External group mapping or disable External group mapping in role before saving.`]: `Rejestr użytkowników LDAP roli {{name}} nie obsługuje już odwzorowania grupy zewnętrznej. Wybierz inny rejestr użytkowników z odwzorowaniem grupy zewnętrznej lub wyłącz odwzorowanie grupy zewnętrznej w roli przed zapisaniem.`,
  [`LDAP search attribute`]: `Atrybut wyszukiwania LDAP`,
  [`LOCATED IN`]: `ZNAJDUJE SIĘ W`,
  [`Located in`]: `Znajduje się w`,
  [`LTPA Key`]: `Klucz LTPA`,
  [`LTPA Key Details`]: `Szczegóły klucza LTPA`,
  [`LTPA Keys`]: `Klucze LTPA`,
  [`LTPA key file`]: `Plik klucza LTPA`,
  [`LTPA key password`]: `Hasło klucza LTPA`,
  [`LUR data version`]: `Wersja danych LUR`,
  [`LUR schema update date`]: `Data aktualizacji schematu LUR`,
  [`LUR schema version`]: `Wersja schematu LUR`,
  [`LUR target data version`]: `Docelowa wersja danych LUR`,
  [`LUR target schema version`]: `Docelowa wersja schematu LUR`,
  [`LUR update date`]: `Data aktualizacji LUR`,
  [`Last name`]: `Nazwisko`,
  [`Last modified`]: `Data ostatniej modyfikacji`,
  [`Launch portal`]: `Uruchom portal`,
  [`Launch IBM Automation management console`]: `Uruchom konsolę zarządzania automatyzacją IBM`,
  [`Launch plan upgrade`]: `Uruchom aktualizację planu`,
  [`Learn more`]: `Dodatkowe informacje`,
  [`Learn More`]: `Dowiedz się więcej`,
  [`Legacy`]: `Starsza wersja`,
  [`Length cannot be below {min}`]: `Długość nie może być mniejsza niż {min}`,
  [`Length cannot exceed {max}`]: `Długość nie może być większa niż {max}`,
  [`Let's customize API Connect`]: `Dostosujmy API Connect do Twoich potrzeb`,
  [`Let's get started...`]: `Zatem do dzieła!`,
  [`Let's get you up and running`]: `Zaczynamy`,
  [`License`]: `Licencja`,
  [`Licensed Materials - Property of IBM`]: `Licensed Materials - Property of IBM`,
  [`Lifecycle`]: `Cykl życia`,
  [`Lifecycle approvals`]: `Zatwierdzenia cyklu życia`,
  [`Lifecycle actions have been updated`]: `Działania cyklu życia zostały zaktualizowane.`,
  [`Lifecycle approvals are enabled for the Sandbox Catalog. To use the automatic Activate API option, you must first disable lifecycle approvals in the Sandbox Catalog settings.`]: `Zatwierdzenia cyklu życia są włączone dla katalogu środowiska testowego. Aby użyć opcji automatycznego aktywowania interfejsu API, należy najpierw wyłączyć zatwierdzenia cyklu życia w ustawieniach katalogu środowiska testowego.`,
  [`Limited fields`]: `Pola ograniczone`,
  [`Limit API calls on a per key basis`]: `Ograniczaj wywołania interfejsu API dla poszczególnych kluczy`,
  [`Limit fields`]: `Ogranicz pola`,
  [`Limit renegotiation`]: `Ograniczaj ponowną negocjację`,
  [`Linkedin`]: `Linkedin`,
  [`Load`]: `Ładuj`,
  [`Load query`]: `Zapytanie ładowania`,
  [`Load filter`]: `Filtr ładowania`,
  [`Load schema from WSDL`]: `Ładuj schemat z pliku WSDL`,
  [`Loading`]: `Ładowanie`,
  [`Local User Registries (LURs) are the default user registries included in API Connect and are stored locally.`]: `Lokalne rejestry użytkowników (Local User Registries – LUR) są domyślnymi rejestrami użytkowników zawartymi w produkcie API Connect i są one przechowywane lokalnie.`,
  [`Local User Registries are the default user registries included in API Connect and stored locally. Two LURs are installed and configured during installation of API Connect. Additional LURs may be configured.`]: `Lokalne rejestry użytkowników są domyślnymi rejestrami użytkowników zawartymi w produkcie API Connect i są one przechowywane lokalnie. Podczas instalowania produktu API Connect instalowane są i konfigurowane dwa lokalne rejestry użytkowników. Istnieje możliwość skonfigurowania dodatkowych lokalnych rejestrów użytkowników.`,
  [`Local user registry`]: `Lokalny rejestr użytkowników`,
  [`Local User Registry has been created.`]: `Utworzono lokalny rejestr użytkowników.`,
  [`Location`]: `Położenie`,
  [`Location of ciphertext to decrypt`]: `Położenie tekstu zaszyfrowanego do deszyfrowania`,
  [`Location of plaintext information to encrypt`]: `Położenie jawnej informacji tekstowej do zaszyfrowania`,
  [`Log`]: `Dziennik`,
  [`Log in`]: `Zaloguj się`,
  [`Log in to:`]: `Zaloguj się do:`,
  [`Log in with a different account`]: `Zaloguj się na innym koncie`,
  [`Log into IBM Cloud`]: `Zaloguj się do IBM Cloud`,
  [`Log out`]: `Wyloguj się`,
  [`Log in using the {userRegistryType} user registry`]: `Zaloguj się, korzystając z rejestru użytkowników {userRegistryType}`,
  [`Logging in with IBM Cloud ...`]: `Logowanie do IBM Cloud...`,
  [`Logging out`]: `Wylogowywanie`,
  [`Logic`]: `Logika`,
  [`Login`]: `Logowanie`,
  [`Logs`]: `Dzienniki`,
  [`Longer, descriptive name for this policy.`]: `Dłuższa opisowa nazwa tej strategii.`,
  [`Looks like the server is experiencing an error while processing your request.`]: `Prawdopodobnie na serwerze wystąpił błąd podczas przetwarzania żądania.`,
  [`LoopBack`]: `LoopBack`,
  [`Mail server`]: `Serwer poczty elektronicznej`,
  [`mail-server-test-connection`]: `mail-server-test-connection`,
  [`MEMBER`]: `CZŁONEK`,
  [`MIME Types`]: `Typy MIME`,
  [`Mail server has been deleted.`]: `Serwer poczty został usunięty.`,
  [`Mail server has not been deleted.`]: `Serwer poczty nie został usunięty.`,
  [`Mailserver {arg} has been removed.`]: `Serwer poczty {arg} został usunięty.`,
  [`Make sure that the server is running and that there is network connectivity.`]: `Upewnij się, że serwer jest uruchomiony i że istnieje połączenie sieciowe.`,
  [`This might be caused by a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or that Mutual SSL authentication is required.`]: `Możliwe przyczyny to między innymi brak obsługi funkcji CORS na serwerze docelowym, brak dostępności serwera, niezaufany certyfikat lub wymagane jest wzajemne uwierzytelnianie SSL.`,
  [`This might be caused by one or more of the following:`]: `Może to być spowodowane przez jedną lub kilka z poniższych przyczyn:`,
  [`Make a call and move on`]: `Wywołaj i przejdź dalej`,
  [`Make a call and wait for response`]: `Wywołaj i czekaj na odpowiedź`,
  [`Manage`]: `Zarządzaj`,
  [`Manage API life cycles`]: `Zarządzanie cyklami życia interfejsów API`,
  [`Manage API product lifecycle approval permissions`]: `Zarządzanie uprawnieniami do zatwierdzania w cyklu życia produktu interfejsu API`,
  [`Manage API product lifecycle approvals permissions`]: `Zarządzaj uprawnieniami do zatwierdzeń cyklu życia produktu interfejsu API`,
  [`Manage API provider organizations, local and remote gateways, and related settings.`]: `Zarządzaj organizacjami dostawców interfejsów API, bramami lokalnymi i zdalnymi oraz powiązanymi z nimi ustawieniami.`,
  [`Manage APIs`]: `Zarządzaj interfejsami API`,
  [`Manage Catalogs`]: `Zarządzanie katalogami`,
  [`Manage Organizations`]: `Zarządzanie organizacjami`,
  [`Manage Resources`]: `Zarządzanie zasobami`,
  [`Manage Settings`]: `Zarządzanie ustawieniami`,
  [`Manage destinations for API analytics, including API Connect, HTTP, Kafka, Elasticsearch, and Syslog.`]: `Zarządzaj miejscami docelowymi analizy interfejsów API, w tym programami API Connect, HTTP, Kafka, Elasticsearch i Syslog.`,
  [`Manage local and remote gateways that secure your APIs.`]: `Zarządzaj bramami lokalnymi i zdalnymi, które zabezpieczają interfejsy API.`,
  [`Manage catalogs`]: `Zarządzaj katalogami`,
  [`Manage organizations`]: `Zarządzaj organizacjami`,
  [`Manage resources`]: `Zarządzaj zasobami`,
  [`Manage settings`]: `Zarządzaj ustawieniami`,
  [`Manage users`]: `Zarządzanie użytkownikami`,
  [`Manage active APIs and consumers`]: `Służy do zarządzania aktywnymi interfejsami API i konsumentami.`,
  [`Manage availability zones and services`]: `Służy do zarządzania strefami dostępności i usługami.`,
  [`Manage policies`]: `Zarządzaj strategiami`,
  [`Manage product lifecycle change requests and API subscription and application upgrade requests from application developers`]: `Zarządzaj żądaniami zmiany cyklu życia produktu oraz żądaniami subskrypcji interfejsu API i aktualizacji aplikacji zgłaszanymi przez twórców aplikacji`,
  [`Manage roles and permissions`]: `Zarządzaj rolami i uprawnieniami.`,
  [`Manage the OAuth providers configured for API Manager`]: `Zarządzaj dostawcami OAuth skonfigurowanymi na potrzeby programu API Manager`,
  [`Manage the user registries configured for API Manager`]: `Zarządzaj rejestrami użytkowników skonfigurowanymi na potrzeby programu API Manager.`,
  [`Manage the user registries configured for Cloud Manager`]: `Zarządzaj rejestrami użytkowników skonfigurowanymi na potrzeby programu Cloud Manager.`,
  [`Management`]: `Zarządzanie`,
  [`Management Endpoint`]: `Punkt końcowy zarządzania`,
  [`Management endpoint`]: `Punkt końcowy zarządzania`,
  [`Management endpoint on the analytics service`]: `Punkt końcowy zarządzania w usłudze analitycznej`,
  [`Service endpoint configuration`]: `Konfiguracja punktu końcowego usługi`,
  [`Endpoint used by the management service to send configuration information to the portal service`]: `Punkt końcowy używany przez usługę zarządzania do wysyłania informacji konfiguracyjnych do usługi portalu`,
  [`TLS client profile used by the management service when communicating with the portal service`]: `Profil klienta TLS używany przez usługę zarządzania podczas komunikowania się z usługą portalu`,
  [`Endpoint used by the management service to send configuration information to the gateway service`]: `Punkt końcowy używany przez usługę zarządzania do wysyłania informacji konfiguracyjnych do usługi bramy`,
  [`TLS client profile used by the management service when communicating with the gateway service`]: `Profil klienta TLS używany przez usługę zarządzania podczas komunikowania się z usługą bramy`,
  [`Use in-cluster communication for internal traffic between the gateway and management service`]: `Użyj komunikacji w klastrze dla ruchu wewnętrznego między bramą i usługą zarządzania`,
  [`Use in-cluster communication for both ingestion and queries`]: `Użyj komunikacji w klastrze zarówno w przypadku przyjmowania, jak i zapytań`,
  [`Use in-cluster for ingestion and external for queries, or vice versa`]: `Użyj komunikacji w klastrze do przyjmowania i zewnętrznej dla zapytań, lub odwrotnie`,
  [`Use external communication for both ingestion and queries`]: `Użyj komunikacji zewnętrznej zarówno w przypadku przyjmowania, jak i zapytań`,
  [`Use in-cluster communication for internal traffic between analytics and the management service`]: `Użyj komunikacji w klastrze dla ruchu wewnętrznego między funkcją analityczną i usługą zarządzania`,
  [`Management endpoint on the gateway service`]: `Punkt końcowy zarządzania w usłudze bramy`,
  [`Use in-cluster communication for internal traffic between the portal and management service`]: `Użyj komunikacji w klastrze dla ruchu wewnętrznego między portalem i usługą zarządzania`,
  [`If you want to use in-cluster communication between the management and portal subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Włącz ten przełącznik, jeśli chcesz używać komunikacji w klastrze między podsystemami zarządzania i portalu. Jeśli nie masz pewności, pozostaw ten przełącznik wyłączony (ustawienie domyślnie).`,
  [`If you want to use in-cluster communication between the management and gateway subsystems, then enable this switch. If you are not sure, then leave this switch disabled (the default).`]: `Włącz ten przełącznik, jeśli chcesz używać komunikacji w klastrze między podsystemami zarządzania i bramy. Jeśli nie masz pewności, pozostaw ten przełącznik wyłączony (ustawienie domyślnie).`,
  [`Management endpoint on the portal service`]: `Punkt końcowy zarządzania w usłudze portalu`,
  [`This option is the default, and is what is used for analytics services that are upgraded from a pre-10.0.5.3 release. For OVA deployments, or if your analytics subsystem is in a different environment then external is the only option that you can use. Both the gateway and the management service communicate with the analytics service by using the external endpoint, which is a Kubernetes ingress or OpenShift route depending on your platform.`]: `Jest to opcja domyślna, która jest używana dla usług analitycznych, które zostały zaktualizowane z wersji wcześniejszych niż 10.0.5.3. W przypadku wdrożeń OVA lub jeśli podsystem analityczny znajduje się w innym środowisku, to jedyną opcją, której można użyć, jest opcja zewnętrzna. Zarówno brama, jak i usługa zarządzania komunikują się z usługą analityczną przy użyciu zewnętrznego punktu końcowego, którym jest wejście Kubernetes lub trasa OpenShift w zależności od platformy.`,
  [`You can select in-cluster if you have a Kubernetes or OpenShift deployment where all subsystems are within the same cluster. When this option is selected, the management, gateway, and analytics subsystems communicate with each other through internal service endpoints rather than externally accessible ingresses (Kubernetes) or routes (OpenShift).`]: `W przypadku wdrożenia Kubernetes lub OpenShift, w którym wszystkie podsystemy znajdują się w tym samym klastrze, można wybrać opcję komunikacji w klastrze. Jeśli ta opcja jest wybrana, podsystemy zarządzania, bramy i analizy komunikują się ze sobą za pośrednictwem wewnętrznych punktów końcowych usług, a nie z zewnętrznie dostępnymi dla nich danymi wejściowymi (Kubernetes) lub trasami (OpenShift).`,
  [`You can select this option if you have a Kubernetes or OpenShift deployment and you have some of your subsystems installed in the same cluster. When this option is selected, you can use different communication methods for the different analytics communication flows. For example, the management to analytics communication can use in-cluster, and the gateway to analytics can use external. You might choose this configuration if your gateway is in a different cluster to the rest of your subsystems.`]: `Tę opcję można wybrać, jeśli używane jest wdrożenie Kubernetes lub OpenShift i masz kilka podsystemów zainstalowanych w tym samym klastrze. Po wybraniu tej opcji można korzystać z różnych metod komunikacji dla różnych przepływów komunikatów analitycznych. Na przykład komunikacja zarządzanie–analiza może być w klastrze, a komunikacja brama–analiza może być zewnętrzna. Tę konfigurację można wybrać, jeśli brama znajduje się w innym klastrze, niż pozostałe podsystemy.`,
  [`If you specified in-cluster communication when you registered the analytics service, you can enable the gateway to send API event data to the internal analytics endpoint.`]: `Jeśli podczas rejestrowania usługi analizy określono komunikację w klastrze, można włączyć bramę w celu wysyłania danych zdarzeń interfejsu API do wewnętrznego punktu końcowego analizy.`,
  [`Use internal endpoint for ingestion of analytics data from the gateway service`]: `Użyj wewnętrznego punktu końcowego do przyjmowania danych analitycznych z usługi bramy`,
  [`Endpoint configuration`]: `Użyj komunikacji zewnętrznej zarówno w przypadku przyjmowania, jak i zapytań`,
  [`Portal endpoint configuration`]: `Konfiguracja punktu końcowego portalu`,
  [`URL used by developer portal users to access the portal website`]: `Adres URL używany przez użytkowników portalu dla programistów w celu uzyskania dostępu do serwisu WWW portalu`,
  [`External ingestion endpoint on the analytics service`]: `Zewnętrzny punkt końcowy przyjmowania w usłudze analitycznej`,
  [`TLS client profile for external analytics ingestion endpoint`]: `Profil klienta TLS dla zewnętrznego punktu końcowego przyjmowania analiz`,
  [`Internal ingestion endpoint on the analytics service`]: `Wewnętrzny punkt końcowy przyjmowania w usłudze analitycznej`,
  [`TLS client profile for internal service endpoint`]: `Profil klienta TLS dla wewnętrznego punktu końcowego usługi`,
  [`Manager`]: `Menedżer`,
  [`Manager Request Reset Password`]: `Żądanie zresetowania hasła menedżera`,
  [`Manager Reset Password`]: `Menedżer - resetowanie hasła`,
  [`Manager Sign In`]: `Menedżer - logowanie`,
  [`Manages API provider organizations`]: `Zarządza organizacjami dostawców interfejsów API`,
  [`Manages application developer communities`]: `Zarządza społecznościami programistów aplikacji`,
  [`Manages the API product lifecycle`]: `Zarządza cyklem życia produktu API`,
  [`Manage|link`]: `Zarządzaj`,
  [`Manage|permission`]: `Zarządzanie`,
  [`Manage|title`]: `Zarządzanie`,
  [`Manage Keystore assignment and Keystore history for tokens`]: `Zarządzaj przypisaniami magazynu kluczy i historią magazynu kluczy dla tokenów`,
  [`Map`]: `Odwzoruj`,
  [`Map information from OIDC provider to an APIC user`]: `Odwzoruj informacje od dostawcy OIDC na użytkownika APIC`,
  [`Map the migration source plans to the migration target plans`]: `Odwzorowywanie źródłowych planów migracji w docelowe plany migracji`,
  [`Map the roles to groups`]: `Odwzoruj role na grupy`,
  [`Mapped from: `]: `Odwzorowane z: `,
  [`Marketing`]: `Marketing`,
  [`Maximize / minimize`]: `Maksymalizuj/minimalizuj`,
  [`Maximum Consent Time to Live (seconds)`]: `Czas życia maksymalnej zgody (w sekundach)`,
  [`Maximum consent`]: `Maksymalna zgoda`,
  [`Menu`]: `Menu`,
  [`Member`]: `Członek organizacji`,
  [`Member Invitation`]: `Zaproszenie członka`,
  [`Member Invitation has been deleted.`]: `Zaproszenie członka zostało usunięte.`,
  [`Member has been deleted.`]: `Członek został usunięty.`,
  [`Member {name} created`]: `Utworzono członka o nazwie {name}`,
  [`Member {title} has been created.`]: `Członek {title} został utworzony.`,
  [`Members`]: `Członkowie`,
  [`member-invitation`]: `member-invitation`,
  [`Member Invitations`]: `Zaproszenia członka`,
  [`Member Roles`]: `Role członków`,
  [`Merchant ID`]: `Identyfikator handlowca`,
  [`Merge`]: `Scal`,
  [`Merge this mapping with any others that operate on the same target array?`]: `Scalić to odwzorowanie z innymi, które działają na tej samej tablicy docelowej?`,
  [`Message`]: `Wiadomość`,
  [`Message Body`]: `message.body`,
  [`Message for parsing`]: `Komunikat dla analizowania`,
  [`Message for resulting parsed data`]: `Komunikat dla wynikowych analizowanych danych`,
  [`Metadata`]: `Metadane`,
  [`Metadata allows you to collect custom metadata to include during the access token generation process. You can collect the metadata through an authentication URL or a remote server where the custom metadata is stored, or both.`]: `Funkcja Metadane umożliwia gromadzenie niestandardowych metadanych, które są dołączane podczas procesu generowania tokenu dostępu. Metadane można gromadzić za pośrednictwem adresu URL uwierzytelniania, zdalnego serwera, na którym przechowywane są niestandardowe metadane, lub obydwoma tymi metodami.`,
  [`Micro Gateway policies`]: `Strategie Micro Gateway`,
  [`Migrate`]: `Przeprowadź migrację`,
  [`Migrate Subscriptions`]: `Migracja subskrypcji`,
  [`Migrate plans`]: `Przeprowadzanie migracji planów`,
  [`Migrate subscription`]: `Przeprowadź migrację subskrypcji`,
  [`Migrate subscriptions`]: `Migracja subskrypcji`,
  [`Migration Target`]: `Element docelowy migracji`,
  [`Migration from "{source}" (Product) to "{target}" (Product) has been executed`]: `Migracja z (produktu) "{source}" do (produktu) "{target}" została wykonana`,
  [`Migration from "{source}" failed`]: `Migracja z "{source}" nie powiodła się`,
  [`Migration source`]: `Źródło migracji`,
  [`Migration target`]: `Cel migracji`,
  [`Minimum role`]: `Rola minimalna`,
  [`Minimum output escaping rule`]: `Reguła minimalnej zmiany znaczenia na wyjściu`,
  [`Minutes`]: `Minuty`,
  [`Mode`]: `Tryb`,
  [`Modifications`]: `Modyfikacje`,
  [`Modified`]: `Zmodyfikowano`,
  [`More`]: `Więcej`,
  [`More than 20 events are waiting to be processed`]: `Na przetworzenie oczekuje więcej niż 20 zdarzeń`,
  [`Move down`]: `Przenieś w dół`,
  [`Move up`]: `Przenieś w górę`,
  [`Must be a number`]: `Musi być liczbą`,
  [`Must be a valid URI`]: `Musi być poprawnym identyfikatorem URI`,
  [`Must be a valid email`]: `Należy wprowadzić poprawny adres e-mail.`,
  [`Must be a valid path starting with /`]: `Należy wprowadzić poprawną ścieżkę rozpoczynającą się od znaku /.`,
  [`Must be a valid url`]: `Należy wprowadzić poprawny adres URL.`,
  [`Must be an integer`]: `Należy wprowadzić liczbę całkowitą.`,
  [`Must be a valid url with no protocol`]: `Musi być poprawnym adresem URL bez protokołu`,
  [`Must be of the format 64 hex characters (prefixed with "0x")`]: `Musi mieć format 64 znaków szesnastkowych (poprzedzonych przedrostkiem 0x)`,
  [`Must start with '/'`]: `Musi zaczynać się od znaku '/'`,
  [`Must start with https://`]: `Musi zaczynać się od łańcucha https://`,
  [`Must start with https:// or $(`]: `Musi zaczynać się od https:// lub $(`,
  [`Mutual auth`]: `Uwierzytelnianie wzajemne`,
  [`Mutual authentication`]: `Uwierzytelnianie wzajemne`,
  [`My account`]: `Moje konto`,
  [`Name`]: `Nazwa`,
  [`NONCE`]: `NONCE`,
  [`NOT`]: `NOT`,
  [`Name cannot be empty.`]: `Pole nazwy nie może być puste.`,
  [`Name of Schema in OpenAPI Definitions`]: `Nazwa schematu w definicjach OpenAPI`,
  [`Name of the LDAP user password attribute.`]: `Nazwa atrybutu hasła użytkownika LDAP.`,
  [`Name of the LDAP user registry to validate against. Select from the list, or type manually.`]: `Nazwa rejestru użytkowników LDAP, dla którego ma zostać sprawdzona poprawność. Wybierz z listy lub wpisz ręcznie.`,
  [`Name of the organization`]: `Nazwa organizacji`,
  [`Namespace inheritance`]: `Dziedziczenie przestrzeni nazw`,
  [`Namespace inlining`]: `Wstawianie przestrzeni nazw`,
  [`Native`]: `Własne`,
  [`Native OAuth provider`]: `Własny dostawca OAuth`,
  [`Native OAuth provider summary`]: `Podsumowanie własnego dostawcy OAuth`,
  [`Navigate to Source View`]: `Przejdź do widoku źródłowego`,
  [`New`]: `Nowa`,
  [`New API`]: `Nowy interfejs API`,
  [`New API Connect connection`]: `Nowe połączenie z produktem API Connect`,
  [`New Application`]: `Nowa aplikacja`,
  [`New Catalog`]: `Nowy katalog`,
  [`New Consumer organization`]: `Nowa organizacja konsumenta`,
  [`New Endpoint`]: `Nowy punkt końcowy`,
  [`New native OAuth provider`]: `Nowy własny dostawca OAuth`,
  [`New OpenAPI`]: `Nowa usługa OpenAPI`,
  [`New OpenAPI directory`]: `Nowy katalog OpenAPI`,
  [`New Organization Title`]: `Tytuł nowej organizacji`,
  [`New Parameter`]: `Nowy parametr`,
  [`New Password`]: `Nowe hasło`,
  [`New product`]: `Nowy produkt`,
  [`New Role`]: `Nowa rola`,
  [`New schema`]: `Nowy schemat`,
  [`New Test`]: `Nowy test`,
  [`New third party OAuth provider`]: `Nowy zewnętrzny dostawca OAuth`,
  [`New user`]: `Nowy użytkownik`,
  [`New applications will be created in the development state`]: `Nowe aplikacje będą tworzone w stanie programowania`,
  [`Next`]: `Dalej`,
  [`Next page`]: `Następna strona`,
  [`No`]: `Nie`,
  [`No account?`]: `Nie masz konta?`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog`]: `W katalogu środowiska testowego nie skonfigurowano adresu URL uwierzytelniania ani rejestrów użytkowników LDAP`,
  [`No Authentication URL or LDAP User Registries configured in the sandbox catalog, create one [here]({link})`]: `Nie skonfigurowano adresu URL uwierzytelniania lub rejestrów użytkowników LDAP w katalogu środowiska testowego. Utwórz jeden z tych elementów [tutaj]({link}).`,
  [`No Billing`]: `Bez rozliczeń`,
  [`No billing integration has been selected.`]: `Nie wybrano integracji rozliczeń.`,
  [`No billing integration can be shown when offline.`]: `W trybie bez połączenia nie można wyświetlić integracji z fakturowaniem.`,
  [`No gateways`]: `Brak bram`,
  [`No cache`]: `Brak pamięci podręcznej`,
  [`No Cache`]: `Brak pamięci podręcznej`,
  [`No Certificate Manager services available`]: `Brak dostępnych usług Certificate Manager`,
  [`No default gateways`]: `Brak domyślnych bram`,
  [`No duplicate email address within the user registry. This only applies to the users with email.`]: `Bez zduplikowanych adresów e-mail w rejestrze użytkowników. Dotyczy to tylko użytkowników z adresem e-mail.`,
  [`No compatible gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled.`]: `Nie skonfigurowano kompatybilnych bram dla katalogu środowiska testowego. Test interfejsu API (Wypróbuj) w Eksploratorze nie zostanie włączony.`,
  [`No Errors`]: `Brak błędów`,
  [`No Histories found`]: `Nie znaleziono historii`,
  [`No Gateway services found. Please configure a Gateway Service to display supported assembly policies`]: `Nie znaleziono usług bramy. Skonfiguruj usługę bramy, aby wyświetlić obsługiwane strategie składania`,
  [`No gateways are configured for sandbox catalog`]: `Nie skonfigurowano bram dla katalogu środowiska testowego`,
  [`No gateways are configured for sandbox catalog. API Test(Try it) in Explorer will not be enabled`]: `Nie skonfigurowano bram dla katalogu środowiska testowego. Test interfejsu API (Wypróbuj) w Eksploratorze nie zostanie włączony`,
  [`No LDAP or authentication URL user registries found.`]: `Nie znaleziono rejestrów użytkowników LDAP ani adresów URL uwierzytelniania.`,
  [`No limit on size of list`]: `Brak limitu wielkości listy`,
  [`No OAuth providers configured in the sandbox catalog`]: `Nie skonfigurowano dostawców OAuth w katalogu środowiska testowego`,
  [`No OAuth providers configured in the selected catalog`]: `Nie skonfigurowano dostawców OAuth w wybranym katalogu`,
  [`No Permission`]: `Brak uprawnienia`,
  [`No portal added to this catalog`]: `Do tego katalogu nie dodano żadnego portalu`,
  [`No Roles exist`]: `Nie istnieją żadne role`,
  [`No recent items found.`]: `Nie znaleziono ostatnich elementów.`,
  [`No TLS profile`]: `Brak profilu TLS`,
  [`No TLS client profile`]: `Brak profilu klienta TLS`,
  [`No Truststore`]: `Brak magazynu zaufanych certyfikatów`,
  [`No analytics service is configured`]: `Nie skonfigurowano usługi analizy.`,
  [`No analytics services found. You can create one [here]({link})`]: `Nie znaleziono usługi analizy danych. Można utworzyć usługę [tutaj]({link})`,
  [`No assemblies found.`]: `Nie znaleziono elementów złożonych.`,
  [`No available extensions`]: `Brak dostępnych rozszerzeń`,
  [`No compatible flows configured in this OAuth provider`]: `W tym dostawcy OAuth nie skonfigurowano żadnego zgodnego przepływu`,
  [`No consumer organization will be able to see this product.`]: `Żadna organizacja konsumenta nie będzie mogła wyświetlić tego produktu.`,
  [`No debug data was found for this API call`]: `Nie znaleziono danych debugowania dla tego wywołania interfejsu API`,
  [`No details are available.`]: `Brak dostępnych szczegółów.`,
  [`No existing products`]: `Brak istniejących produktów`,
  [`No extensions have been configured for the API`]: `Nie skonfigurowano rozszerzeń dla interfejsu API`,
  [`No flow configured in this OAuth provider`]: `W tym dostawcy OAuth nie skonfigurowano żadnego przepływu`,
  [`No items found`]: `Nie znaleziono żadnych elementów`,
  [`No non-member user to add.`]: `Brak użytkownika niebędącego członkiem do dodania`,
  [`No organization found`]: `Nie znaleziono organizacji`,
  [`No portal service exists:`]: `Nie istnieje usługa portalu:`,
  [`No product APIs`]: `Brak interfejsów API produktu`,
  [`No products or plans were found.`]: `Nie znaleziono żadnych produktów ani planów.`,
  [`No provider organizations`]: `Brak organizacji dostawców`,
  [`No response received. Causes include a lack of CORS support on the target server, the server being unavailable, an untrusted certificate being encountered or Mutual SSL authentication is required.`]: `Nie odebrano żadnej odpowiedzi. Przyczyny to między innymi brak obsługi funkcji CORS na serwerze docelowym, brak dostępności serwera, niezaufany certyfikat lub wymagane jest wzajemne uwierzytelnianie SSL.`,
  [`No resource groups available. To get started, create a resource group in IBM Cloud Account resources.`]: `Brak dostępnych grup zasobów. Na początek utwórz grupę zasobów w zasobach konta IBM Cloud.`,
  [`No security`]: `Brak zabezpieczeń`,
  [`No services found`]: `Nie znaleziono żadnych usług`,
  [`No warnings`]: `Brak ostrzeżeń`,
  [`No warnings.`]: `Brak ostrzeżeń.`,
  [`No. Do not update API Assembly`]: `Nie. Nie aktualizuj zespołu interfejsu API`,
  [`Non-blocking`]: `Bez blokowania`,
  [`Non int slicing arguments`]: `Niecałkowitoliczbowe argumenty przekrojów`,
  [`None`]: `Brak`,
  [`None of the configured OAuth providers match the gateway type of this API`]: `Żaden ze skonfigurowanych dostawców OAuth nie jest zgodny z typem bramy tego interfejsu API`,
  [`Normal`]: `Normalny`,
  [`Not Available`]: `Niedostępne`,
  [`Not applicable for application grant type.`]: `Nie można stosować w przypadku typu nadania aplikacji.`,
  [`Not valid before`]: `Niepoprawny przed dniem`,
  [`Not valid after`]: `Nieważny po`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected.`]: `Uwaga: ta opcja jest wyłączona, gdy wybrana jest opcja 'Generuj produkt automatyczny'.`,
  [`Note: This option is disabled when the 'Generate auto product' option is selected and will instead use the Default Plan for the generated product`]: `Uwaga: ta opcja jest wyłączona, gdy wybrano opcję 'Generuj produkt automatyczny', a zamiast niej będzie używany domyślny plan dla wygenerowanego produktu`,
  [`Note: Gateway Services preference will be used as the scope for all other preferences. Policies and other Gateway Service specific content will be loaded based on this setting.`]: `Uwaga: preferencja usług bramy zostanie użyta jako zasięg dla wszystkich innych preferencji. W oparciu o to ustawienie zostaną załadowane strategie i inna treść specyficzna dla usługi bramy.`,
  [`Note: This option is only for informational purpose and is updated based on the test application option above`]: `Uwaga: ta opcja ma charakter wyłącznie informacyjny i jest aktualizowana na podstawie powyższej opcji aplikacji testowej.`,
  [`Notices`]: `Uwagi`,
  [`Notification template`]: `Szablon powiadomienia`,
  [`Notification templates`]: `Szablony powiadomień`,
  [`Notification server configured`]: `Skonfigurowano serwer powiadomień`,
  [`Notifications`]: `Powiadomienia`,
  [`Null Badgerfish`]: `Null w notacji Badgerfish`,
  [`Null`]: `Null`,
  [`Number`]: `Liczba`,
  [`Number of Operations`]: `Liczba operacji`,
  [`Numeric`]: `Liczbowe`,
  [`OAuth`]: `OAuth`,
  [`OAuth Provider`]: `Dostawca OAuth`,
  [`OAuth provider`]: `Dostawca OAuth`,
  [`OAuth Provider Policy Assembly`]: `Zespół strategii dostawcy OAuth`,
  [`OAuth providers`]: `Dostawcy OAuth`,
  [`OAuth Redirect URL`]: `Adres URL przekierowania OAuth`,
  [`OAuth shared secret`]: `Współużytkowane dane niejawne OAuth`,
  [`OAuth provider policy assembly`]: `Zespół strategii dostawcy OAuth`,
  [`OAuth provider {currentProviderName} was not found. Please select a configured OAuth provider.`]: `Dostawca OAuth {currentProviderName} nie został znaleziony. Wybierz skonfigurowanego dostawcę OAuth.`,
  [`OAuth redirect URL`]: `Adres URL przekierowania OAuth`,
  [`OAuth Shared Secret`]: `Współużytkowane dane niejawne OAuth`,
  [`OAuth Signature Method`]: `Metoda podpisywania OAuth`,
  [`OAuth signature method`]: `Metoda podpisywania OAuth`,
  [`OAuth providers can be created and managed in the following list.`]: `Na poniższej liście można tworzyć dostawców OAuth i nimi zarządzać.`,
  [`OAuth symmetric key name`]: `Nazwa klucza symetrycznego OAuth`,
  [`OAuth symmetric key value`]: `Wartość klucza symetrycznego OAuth`,
  [`OAuth2`]: `OAuth2`,
  [`OAuth2 Access Code Security`]: `OAuth2 — zabezpieczenie Kod dostępu`,
  [`OAuth2 Application Security`]: `OAuth2 — zabezpieczenie Aplikacja`,
  [`OAuth2 Implicit Security`]: `OAuth2 — zabezpieczenie Niejawne`,
  [`OAuth2 Password Security`]: `OAuth2 — zabezpieczenie Hasło`,
  [`OIDC`]: `OIDC`,
  [`OK`]: `OK`,
  [`OPERATION ID`]: `ID OPERACJI`,
  [`OPTIONS`]: `OPTIONS`,
  [`ORDER`]: `KOLEJNOŚĆ`,
  [`ORGANIZATION`]: `ORGANIZACJA`,
  [`ORGANIZATION MANAGER`]: `MENEDŻER ORGANIZACJI`,
  [`Organizations`]: `Organizacje`,
  [`Owner`]: `Właściciel`,
  [`Oauth providers are managed in each space when spaces are enabled. Please go to the space settings to manage oauth providers.`]: `Gdy obszary są włączone, dostawcami OAuth zarządza się w poszczególnych obszarach. Przejdź do ustawień obszarów, aby zarządzać dostawcami OAuth.`,
  [`Off`]: `Wył.`,
  [`Offline`]: `Bez połączenia`,
  [`Old schema`]: `Stary schemat`,
  [`Omnisearch allows searching for APIs, Products, Applications, Subscriptions and other resources within a provider organization or a catalog. In addition, It can also help in discovering the relationships between these resources.`]: `Omnisearch umożliwia wyszukiwanie interfejsów API, produktów, aplikacji, subskrypcji i innych zasobów w organizacji dostawcy lub katalogu. Może także pomóc w ujawnieniu relacji między tymi zasobami.`,
  [`Omnisearch for all supported list pages`]: `Omnisearch dla wszystkich obsługiwanych stron list`,
  [`Omnisearch for draft APIs and products`]: `Omnisearch dla wersji roboczych interfejsów API i produktów`,
  [`Omnisearch for published products in catalogs and spaces`]: `Omnisearch dla produktów opublikowanych w katalogach i obszarach`,
  [`Omnisearch for consumer organizations`]: `Omnisearch dla organizacji konsumentów`,
  [`Omnisearch for applications`]: `Omnisearch dla aplikacji`,
  [`Omnisearch for requested approvals`]: `Omnisearch dla żądanych zatwierdzeń`,
  [`Omnisearch for approvals tasks`]: `Omnisearch dla zadań zatwierdzania`,
  [`Omnisearch for product apis`]: `Omnisearch dla interfejsów API produktu`,
  [`Omnisearch for subscriptions`]: `Omnisearch dla subskrypcji`,
  [`Omnisearch for members`]: `Omnisearch dla członków`,
  [`Omnisearch for provider organizations`]: `Omnisearch dla organizacji dostawcy`,
  [`Disable the new editor for OpenAPI2`]: `Wyłącz nowy edytor dla OpenAPI2`,
  [`On`]: `Wł.`,
  [`Onboarding`]: `Rejestrowanie`,
  [`Onboarding Edit Admin Org`]: `Edycja rejestrowania - organizacja administracyjna`,
  [`Onboarding Edit Cloud Org`]: `Edycja rejestrowania - organizacja w chmurze`,
  [`Onboarding Edit Reset Password`]: `Edycja rejestrowania - resetowanie hasła`,
  [`Once installed, use the IBM Cloud CLI to target the account and region in which you provisioned API Connect, then try out the CLI by listing all of your API Connect provider organizations`]: `Po zainstalowaniu użyj wtyczki IBM Cloud CLI, aby określić konto i region, dla których udostępniany jest produkt API Connect, a następnie wypróbuj interfejs CLI, wyświetlając wszystkie organizacje dostawców produktu API Connect`,
  [`One or more JSON objects describing the schemas being consumed and produced by the API.`]: `Jeden lub więcej obiektów JSON opisujących schematy, które są używane i generowane przez interfejs API.`,
  [`One or more JSON representations for parameters`]: `Jedna lub większa liczba reprezentacji JSON dla parametrów`,
  [`One time use access token`]: `Token dostępu jednokrotnego użytku`,
  [`One time use refresh token`]: `Token odświeżania jednokrotnego użytku`,
  [`Online`]: `Z połączeniem`,
  [`Only one API file should be present in the zip file.`]: `W pliku zip powinien znajdować się tylko jeden plik interfejsu API.`,
  [`OpenAPI Specification Version`]: `Wersja specyfikacji OpenAPI`,
  [`Open ID Connect (OIDC) provides an additional authentication protocol base on OAuth 2.0. OIDC providers user information encoded in a JSON Web Token, or JWT. When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created.`]: `Open ID Connect (OIDC) udostępniania dodatkowy protokół uwierzytelniania bazujący na protokole OAuth 2.0. Informacje o użytkownikach dostawcy OIDC są kodowane w tokenie WWW JSON lub JWT. Po włączeniu protokołu OpenID Connect udostępniony zostaje szablon umożliwiający generowanie tokenów identyfikatora razem z tokenami dostępu. Ponadto automatycznie tworzone są wymagane strategie składania.`,
  [`Open a Directory`]: `Otwórz katalog`,
  [`Open in external editor`]: `Otwórz w zewnętrznym edytorze`,
  [`Open the server`]: `Otwórz serwer`,
  [`OpenAPI extensions`]: `Rozszerzenia OpenAPI`,
  [`OpenID Connect`]: `OpenID Connect`,
  [`OpenID Connect (OIDC)`]: `OpenID Connect (OIDC)`,
  [`OpenID Connect not yet supported when Gateway version is 6000`]: `Brak obsługi standardu OpenID Connect, gdy brama jest w wersji 6000`,
  [`OpenID Connect settings are applicable only if your selected grant types include one or more of the following: Implicit and Access code.`]: `Ustawienia OpenID Connect są stosowane tylko wtedy, gdy wybrane typy uprawnień obejmują jedno lub więcej z następujących: Niejawne i Kod dostępu.`,
  [`Operation`]: `Operacja`,
  [`Operation Id`]: `Identyfikator operacji`,
  [`Operation switch`]: `Przełącznik operacji`,
  [`Operation Switch`]: `Przełącznik operacji`,
  [`Operations`]: `Operacje`,
  [`Operator build date`]: `Data kompilacji operatora`,
  [`Operator build tag`]: `Znacznik kompilacji operatora`,
  [`Operator version`]: `Wersja operatora`,
  [`Optionally select a CA bundle`]: `Opcjonalnie wybierz pakunek CA`,
  [`Optimize schema definition`]: `Optymalizuj definicję schematu`,
  [`Optional`]: `Opcjonalnie`,
  [`Or`]: `Lub`,
  [`Or create a new application and subscribe to the selected plan`]: `Lub utwórz nową aplikację i zasubskrybuj wybrany plan`,
  [`Or create a new product and publish it to your Sandbox catalog`]: `Można też utworzyć nowy produkt i opublikować go w katalogu środowiska testowego`,
  [`Order`]: `Kolejność`,
  [`Org`]: `Organizacja`,
  [`Org owner`]: `Właściciel organizacji`,
  [`Organization`]: `Organizacja`,
  [`Organization:`]: `Organizacja:`,
  [`Organization information`]: `Informacje o organizacji`,
  [`Organization Manager`]: `Menedżer organizacji`,
  [`Organization has been updated.`]: `Organizacja została zaktualizowana.`,
  [`Organizations will be listed here.`]: `W tym miejscu zostaną wyświetlone organizacje.`,
  [`Otherwise`]: `W przeciwnym razie`,
  [`Output`]: `Wyjście`,
  [`Output Claims`]: `Roszczenia wyjściowe`,
  [`Output array`]: `Tablica wyjściowa`,
  [`Output the root element even if it is not required to make the XML document well formed.`]: `Zwracaj element główny nawet wtedy, gdy nie jest on wymagany do utworzenia poprawnego dokumentu XML.`,
  [`Output variable`]: `Zmienna wyjściowa`,
  [`Override API consume types`]: `Nadpisz typy użycia interfejsu API`,
  [`Override API produce types`]: `Nadpisz typy generowania interfejsu API`,
  [`Override API security definitions`]: `Nadpisz definicje zabezpieczeń interfejsu API`,
  [`Override rate limits`]: `Nadpisz limity częstotliwości`,
  [`Override default "provider" settings with configuration from this URL`]: `Nadpisz domyślne ustawienia dostawcy przy użyciu konfiguracji z tego adresu URL`,
  [`Override default "provider" settings with configuration from this literal`]: `Nadpisz domyślne ustawienia dostawcy przy użyciu konfiguracji z tego literału`,
  [`Override plan rate limits`]: `Nadpisz limity częstotliwości planu`,
  [`Override plan rate limits for individual operation`]: `Nadpisz limity częstotliwości planu dla konkretnej operacji`,
  [`Override consumer organizations invitation timeout with catalog invitation timeout`]: `Zastąp limit czasu zaproszenia dla organizacji konsumenta limitem czasu zaproszenia katalogu`,
  [`Overview`]: `Przegląd`,
  [`Owner scope check`]: `Sprawdzanie zasięgu właściciela`,
  [`Owner's Email`]: `Adres e-mail właściciela`,
  [`Owner's Name`]: `Nazwa właściciela`,
  [`Owner's email`]: `Adres e-mail właściciela`,
  [`Owns and administers the API provider organization`]: `Jest właścicielem organizacji dostawcy interfejsu API i administruje nią`,
  [`Owns and administers the admin organization`]: `Jest właścicielem organizacji administracyjnej i administruje nią`,
  [`Owns and administers the app developer organization`]: `Jest właścicielem organizacji twórców aplikacji i administruje nią`,
  [`Owns and administrates the app developer organization`]: `Jest właścicielem organizacji programistów aplikacji i administruje nią`,
  [`PARAMETER NAME`]: `NAZWA PARAMETRU`,
  [`PATCH`]: `PATCH`,
  [`PCRE to use to validate the Audience (aud) claim.`]: `Wyrażenie PCRE do użycia w celu sprawdzenia poprawności roszczenia audytorium (aud).`,
  [`PCRE to use to validate the Issuer (iss) claim.`]: `Wyrażenie PCRE do użycia w celu sprawdzenia poprawności roszczenia wystawcy (iss).`,
  [`PER`]: `W CIĄGU`,
  [`Per`]: `na`,
  [`Plan`]: `Plan`,
  [`Plan:`]: `Plan:`,
  [`pending Consumer organization invitation`]: `oczekujące zaproszenie do organizacji konsumenta`,
  [`PLAN`]: `PLAN`,
  [`PLANS`]: `PLANY`,
  [`Policy`]: `Zestaw zasad`,
  [`policy`]: `strategia`,
  [`POLICIES`]: `STRATEGIE`,
  [`POST`]: `POST`,
  [`PRODUCT`]: `PRODUKT`,
  [`Product Plan`]: `Plan produktu`,
  [`Product Plans`]: `Plany produktów`,
  [`Product Plans per page`]: `Plany produktów na stronie`,
  [`Property name`]: `Nazwa właściwości`,
  [`Property type`]: `Typ właściwości`,
  [`Property example`]: `Przykład właściwości`,
  [`Property description`]: `Opis właściwości`,
  [`PUT`]: `PUT`,
  [`Page Not Found`]: `Nie znaleziono strony`,
  [`Parameter name`]: `Nazwa parametru`,
  [`Parameter control`]: `Element sterujący parametru`,
  [`Parameters`]: `Parametry`,
  [`Parent`]: `Element nadrzędny`,
  [`Parse`]: `Analizuj`,
  [`Parse settings URL reference`]: `Odwołanie do adresu URL ustawień analizy`,
  [`Parse settings literal configuration`]: `Konfiguracja literałów ustawień analizy`,
  [`Parse settings object reference`]: `Odwołanie do obiektu ustawień analizy`,
  [`Parse the GraphQL query`]: `Analizuj zapytanie GraphQL`,
  [`Parse the response from the GraphQL backend server`]: `Analizuj odpowiedź z serwera zaplecza GraphQL`,
  [`Parse XML output`]: `Analizuj wynikowy kod XML`,
  [`Pass-through`]: `Tranzyt`,
  [`Password`]: `Hasło`,
  [`password-changed`]: `password-changed`,
  [`Password changed for your user account in IBM API Connect`]: `Hasło zostało zmienione dla konta użytkownika w produkcie IBM API Connect`,
  [`Password changed for your user account in {{portalTitle}} developer portal`]: `Hasło zostało zmienione dla konta użytkownika w portalu Developer Portal {portalTitle}`,
  [`Password context variable`]: `Zmienna kontekstowa hasła`,
  [`Password is invalid. Re-enter the password.`]: `Hasło jest niepoprawne. Wprowadź hasło ponownie.`,
  [`password-reset`]: `password-reset`,
  [`Password reset request for your user account in IBM API Connect`]: `Żądanie zresetowania hasła dla konta użytkownika w produkcie IBM API Connect`,
  [`Password reset request for your {{catalog}} developer portal account`]: `Żądanie zresetowania hasła dla Twojego konta portalu Developer Portal {catalog}`,
  [`Passwords must contain at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %)`]: `Hasła muszą zawierać co najmniej jeden znak z dwóch następujących kategorii: małe litery, wielkie litery, cyfry i znaki przestankowe (np. !, $, #, %).`,
  [`Passwords must contain eight or more characters`]: `Hasło musi zawierać co najmniej osiem znaków.`,
  [`Passwords must contain eight or more characters, with at least one character from two of the following categories: lowercase, uppercase, numerals, and punctuation (e.g. !, $, #, %). Additionally, the same character cannot be used more than twice consecutively.`]: `Hasła muszą zawierać osiem lub więcej znaków, w tym co najmniej jeden znak z dwóch następujących kategorii: małe litery, wielkie litery, cyfry i znaki interpunkcyjne (np. !, $, #, %). Ponadto ten sam znak nie może być używany więcej niż dwa razy obok siebie.`,
  [`Passwords must not contain the same character used more than twice consecutively.`]: `Hasła nie mogą zawierać tego samego znaku używanego więcej niż dwa razy pod rząd.`,
  [`Passwords should match`]: `Hasła powinny być zgodne`,
  [`Path`]: `Ścieżka`,
  [`Path parameters`]: `Parametry ścieżki`,
  [`Path name`]: `Nazwa ścieżki`,
  [`Paths`]: `Ścieżki`,
  [`Paths identify the REST resources exposed by the API. An operation combines a path with an HTTP verb, parameters, and definitions for requests and responses.`]: `Ścieżki identyfikują zasoby REST udostępniane przez interfejs API. Operacja łączy ścieżkę z komendą HTTP, parametrami i definicjami na potrzeby żądań i odpowiedzi.`,
  [`Payments Authorization`]: `Autoryzacja płatności`,
  [`Payment method`]: `Metoda płatności`,
  [`Pending`]: `Oczekujący`,
  [`Pending State`]: `Stan oczekiwania`,
  [`Pending approval to be published in catalog`]: `Oczekujące zatwierdzenie do opublikowania w katalogu`,
  [`Pending approval to be staged in catalog`]: `Oczekujące zatwierdzenie do przemieszczenia w katalogu`,
  [`Pending consumer organization invitations`]: `Oczekujące zaproszenia do organizacji konsumenta`,
  [`View endpoints`]: `Wyświetl punkty końcowe`,
  [`View base endpoints for this API`]: `Wyświetl podstawowe punkty końcowe dla tego interfejsu API`,
  [`View pending invitations`]: `Wyświetl oczekujące zaproszenia`,
  [`Permission Denied`]: `Odmowa przyznania uprawnień`,
  [`Permissions`]: `Uprawnienia`,
  [`Persistent Connection`]: `Połączenie trwałe`,
  [`Pin menu`]: `Przypnij menu`,
  [`PlainText`]: `Zwykły tekst`,
  [`Plan APIs`]: `Interfejsy API planu`,
  [`Plan burst limits`]: `Planuj limit wywołań`,
  [`Plan by name`]: `Planuj według nazwy`,
  [`Plan Default`]: `Plan domyślny`,
  [`Plan Details`]: `Szczegóły planu`,
  [`Plan migration targets`]: `Elementy docelowe migracji planu`,
  [`Plan rate limits`]: `Limity częstotliwości planu`,
  [`Plan name`]: `Nazwa planu`,
  [`Plan pricing`]: `Cennik planu`,
  [`Plan title already exists`]: `Taki tytuł planu już istnieje`,
  [`Plans`]: `Plany`,
  [`Platform REST API endpoint for admin and provider APIs`]: `Punkt końcowy interfejsu REST API platformy dla interfejsów API administratora i dostawcy`,
  [`Platform REST API endpoint for consumer APIs`]: `Punkt końcowy interfejsu REST API platformy dla interfejsów API konsumenta`,
  [`Please choose a bind method`]: `Wybierz metodę wiązania`,
  [`Please choose an authentication method`]: `Wybierz metodę uwierzytelniania`,
  [`Please contact the system administrator.`]: `Skontaktuj się z administratorem systemu.`,
  [`Please contact your administrator for support.`]: `Skontaktuj się z administratorem w celu uzyskania wsparcia.`,
  [`Please enter the reason.`]: `Wprowadź przyczynę.`,
  [`Please enter your username`]: `Wprowadź swoją nazwę użytkownika`,
  [`Please go to the space settings to manage API endpoints.`]: `Przejdź do ustawień obszaru, aby zarządzać punktami końcowymi interfejsu API.`,
  [`Please make sure the server is online and try again.`]: `Upewnij się, że serwer jest dostępny, a następnie spróbuj ponownie.`,
  [`Please note that disabling activity log will remove the properties associated with the type of content to log in the event of success or error. Are you sure you want to disable it?`]: `Należy pamiętać, że wyłączenie dziennika działań spowoduje usunięcie właściwości powiązanych z typem treści, który ma być rejestrowany w przypadku powodzenia lub błędu. Czy na pewno chcesz go wyłączyć?`,
  [`Please review the license for API Connect by accessing`]: `Przejrzyj licencję na produkt API Connect, uzyskując dostęp do`,
  [`Please select a TLS`]: `Wybierz protokół TLS`,
  [`Please select a TLS client profile`]: `Wybierz profil klienta TLS`,
  [`Please select a group type`]: `Wybierz typ grupy`,
  [`Please select an LDAP protocol version`]: `Wybierz wersję protokołu LDAP`,
  [`Please select authentication method`]: `Wybierz metodę uwierzytelniania`,
  [`Please select ciphers from the list below:`]: `Wybierz szyfry z poniższej listy:`,
  [`Please select consumer organization`]: `Wybierz organizację konsumenta`,
  [`Please select one Keystore from the list below:`]: `Wybierz jeden magazyn kluczy z poniższej listy:`,
  [`Please select one keystore from the list below:`]: `Wybierz jeden magazyn kluczy z poniższej listy:`,
  [`Please take note of the client ID and secret for this new application. Store the secret somewhere safe as it cannot be retrieved again, although it can be reset if required:`]: `Zanotuj identyfikator klienta i dane niejawne dla tej nowej aplikacji. Przechowuj dane niejawne w bezpiecznym miejscu, ponieważ nie można będzie ich odtworzyć, chociaż będzie można je zresetować:`,
  [`Please try again in a few minutes.`]: `Spróbuj ponownie za kilka minut.`,
  [`Please refresh catalog/space product list page and try again.`]: `Odśwież stronę listy produktów katalogu/obszaru i spróbuj ponownie.`,
  [`Policies`]: `Strategie`,
  [`Policy Assemblies`]: `Zespoły strategii`,
  [`Policy assembly`]: `Zespół strategii`,
  [`Policy description.`]: `Opis strategii.`,
  [`Policy title`]: `Tytuł strategii`,
  [`Policy has been uploaded.`]: `Strategia została przesłana.`,
  [`Polish`]: `polski`,
  [`Port`]: `Port`,
  [`Portal`]: `Portal`,
  [`Portal details`]: `Szczegóły portalu`,
  [`Portal Director TLS client profile`]: `Profil klienta TLS produktu Portal Director`,
  [`Portal Director keystore`]: `Magazyn kluczy produktu Portal Director`,
  [`Portal Director truststore`]: `Magazyn zaufanych certyfikatów produktu Portal Director`,
  [`Portal service`]: `Usługa portalu`,
  [`Portal Service`]: `Usługa portalu`,
  [`Portal service name`]: `Nazwa usługi portalu`,
  [`Portal service details`]: `Szczegóły usługi portalu`,
  [`Portal Service and URL`]: `Usługa i adres URL portalu`,
  [`Portal service {name} was successfully created.`]: `Pomyślnie utworzono usługę portalu {name}.`,
  [`Portal service {name} was successfully updated.`]: `Pomyślnie zaktualizowano usługę portalu {name}.`,
  [`Portal Service {arg} has been removed.`]: `Usługa portalu {arg} została usunięta.`,
  [`Portal site`]: `Serwis portalu`,
  [`Portal URL`]: `Adres URL portalu`,
  [`Portal site URLs cannot be greater than 200 characters`]: `Adresy URL serwisu portalu nie mogą zawierać więcej, niż 200 znaków`,
  [`Portal website URL`]: `Adres URL serwisu WWW portalu`,
  [`Portal and Gateway Settings`]: `Ustawienia portalu i bramy`,
  [`Portal has been created`]: `Portal został utworzony.`,
  [`Portal has been created.`]: `Portal został utworzony.`,
  [`Portal has been deleted`]: `Portal został usunięty.`,
  [`Portals`]: `Portale`,
  [`Portuguese`]: `portugalski`,
  [`Post Response`]: `Po odpowiedzi`,
  [`Post-Invoke Hook`]: `Hak po wywołaniu`,
  [`Preserve subscriptions on re-publish`]: `Zachowaj subskrypcje przy ponownej publikacji`,
  [`Preserve subscriptions on re-publish product`]: `Zachowaj subskrypcje przy ponownej publikacji produktu`,
  [`Pre-Invoke Hook`]: `Hak przed wywołaniem`,
  [`Pre-Request`]: `Żądanie wstępne`,
  [`Preference`]: `Preferencje`,
  [`Prefix`]: `Przedrostek`,
  [`Preparing API`]: `Przygotowywanie interfejsu API`,
  [`Preserved request header`]: `Zachowany nagłówek żądania`,
  [`preserved request header`]: `zachowany nagłówek żądania`,
  [`Preserved response header`]: `Zachowany nagłówek odpowiedzi`,
  [`preserved response header`]: `zachowany nagłówek odpowiedzi`,
  [`Preview`]: `Podgląd`,
  [`Preview of login screen registry prompt`]: `Podgląd zachęty rejestru ekranu logowania`,
  [`Previous`]: `Wstecz`,
  [`Previous page`]: `Poprzednia strona`,
  [`Prettify`]: `Formatuj`,
  [`Price per month`]: `Cena za miesiąc`,
  [`Private`]: `Prywatny`,
  [`Private Claims`]: `Roszczenia prywatne`,
  [`Private key & public key`]: `Klucz prywatny i klucz publiczny`,
  [`Private Key or Shared Secret for Decrypt`]: `Klucz prywatny lub współużytkowane dane niejawne dla deszyfrowania`,
  [`Private Key or Shared Secret for Sign`]: `Klucz prywatny lub współużytkowane dane niejawne dla podpisywania`,
  [`Private key detected`]: `Wykryto klucz prywatny`,
  [`Private key password`]: `Hasło klucza prywatnego`,
  [`Processed`]: `Przetworzono`,
  [`Problem`]: `Problem`,
  [`Problem fetching Trace data. Please try again. See console for error details.`]: `Wystąpił problem podczas pobierania danych śledzenia. Spróbuj ponownie. Szczegółowe informacje można znaleźć w konsoli.`,
  [`Produced Media Types`]: `Generowane typy danych multimedialnych`,
  [`Produces`]: `Generuje`,
  [`Product`]: `Produkt`,
  [`Product:`]: `Produkt:`,
  [`Product / Plan`]: `Produkt / Plan`,
  [`Product APIs`]: `Interfejsy API produktu`,
  [`Product editor`]: `Edytor produktu`,
  [`Product lifecycle`]: `Cykl życia produktu`,
  [`Product Lifecycle`]: `Cykl życia produktu`,
  [`Product name can not end with '-auto-product'. Please change the name and try importing again.`]: `Nazwa produktu nie może kończyć się członem '-auto-product'. Zmień nazwę i ponów próbę zaimportowania.`,
  [`Product setup`]: `Konfiguracja produktu`,
  [`Product details`]: `Szczegóły produktu`,
  [`Product documentation`]: `Dokumentacja produktu`,
  [`Product has been created.`]: `Produkt został utworzony`,
  [`Product has been deleted`]: `Produkt został usunięty.`,
  [`Product has been updated`]: `Produkt został zaktualizowany.`,
  [`Product has been updated.`]: `Produkt został zaktualizowany.`,
  [`Product has not been created!`]: `Produkt nie został utworzony!`,
  [`Product has not been published!`]: `Produkt nie został opublikowany!`,
  [`Product has not been updated!`]: `Produkt nie został zaktualizowany!`,
  [`Product has not been staged!`]: `Produkt nie został przemieszczony!`,
  [`Product information`]: `Informacje o produkcie`,
  [`Product summary details`]: `Szczegóły podsumowania produktu`,
  [`Product version`]: `Wersja produktu`,
  [`Product visibility`]: `Widoczność produktu`,
  [`Product-Approval`]: `Zatwierdzanie produktu`,
  [`Product-Drafts`]: `Product-Drafts`,
  [`Product/plan`]: `Produkt/plan`,
  [`Production`]: `Środowisko produkcyjne`,
  [`Production mode`]: `Tryb produkcyjny`,
  [`Production endpoints`]: `Punkty końcowe produkcji`,
  [`Products`]: `Produkty`,
  [`Products must be staged to a Catalog and then published to become available to application developers. A Catalog behaves as a logical partition of the gateway and the Developer Portal. The URL for API calls and the Developer Portal are specific to a particular Catalog. In a typical configuration, an API provider organization uses a development Catalog for testing APIs under development and a production Catalog for hosting APIs that are ready for full use.`]: `Produkty należy przemieścić do katalogu, a następnie opublikować, aby były dostępne dla twórców aplikacji. Katalog działa jako partycja logiczna bramy i portal Developer Portal. Adres URL wywołań interfejsu API i portal Developer Portal są specyficzne dla konkretnego katalogu. W typowej konfiguracji organizacja dostawcy interfejsu API używa katalogu programistycznego do testowania tworzonych interfejsów API, a katalogu produkcyjnego do udostępniania interfejsów API, które są w pełni gotowe do użytku.`,
  [`Profile`]: `Profil`,
  [`Profile rule`]: `Reguła profilu`,
  [`Promote to production`]: `Awansuj do wersji produkcyjnej`,
  [`Proof Key for Code Exchange`]: `Klucz zaufany na potrzeby wymiany kodu`,
  [`Proof Key for Code Exchange (PKCE)`]: `Klucz zaufany na potrzeby wymiany kodu (PKCE)`,
  [`Properties`]: `Właściwości`,
  [`Property`]: `Właściwość`,
  [`Property Description`]: `Opis właściwości`,
  [`Property {{target}} can be assigned a static value by entering it below.`]: `Właściwości {{target}} można przypisać wartość statyczną, wprowadzając ją poniżej.`,
  [`Property {{target}} is currently mapped to {{from}} - the mapping can be removed below.`]: `Właściwość {{target}} jest obecnie odwzorowana na {{from}} – odwzorowanie można usunąć poniżej.`,
  [`Property Value is Base64 Encoded`]: `Wartość właściwości jest zakodowana w Base64`,
  [`Protocol`]: `Protokół`,
  [`Protocols`]: `Protokoły`,
  [`Protocols that can be used to invoke the API (only https is supported for gateway enforced APIs)`]: `Protokoły, których można używać do wywoływania interfejsu API (tylko protokół https jest obsługiwany w przypadku interfejsów API wymuszonych przez bramę).`,
  [`Provide OAuth {oauthVer} credentials to authenticate users and gain access to your OpenID provider's APIs`]: `Podaj informacje autoryzacyjne OAuth {oauthVer}, aby uwierzytelnić użytkowników i uzyskać dostęp do interfejsów API dostawcy OpenID.`,
  [`Provide a schema`]: `Udostępnij schemat`,
  [`Provide GraphQL schema`]: `Podaj schemat GraphQL`,
  [`Provide the URL to the backend GraphQL server`]: `Podaj adres URL serwera zaplecza GraphQL`,
  [`Provide your email address to receive password reset instructions`]: `Podaj swój adres e-mail, aby otrzymać instrukcje dotyczące resetowania hasła.`,
  [`Provide your new password below`]: `Podaj nowe hasło poniżej`,
  [`Provider endpoint`]: `Punkt końcowy dostawcy`,
  [`Provider Information`]: `Informacje o dostawcy`,
  [`Provider Organization`]: `Organizacja dostawcy`,
  [`Provider Organizations`]: `Organizacje dostawców`,
  [`Provider Type`]: `Typ dostawcy`,
  [`Provider organizations`]: `Organizacje dostawców`,
  [`Provider type`]: `Typ dostawcy`,
  [`Provider org invite failed`]: `Wysyłanie zaproszenia do organizacji dostawcy nie powiodło się`,
  [`Provider organization {name} created`]: `Utworzono organizację {name} dostawcy.`,
  [`Provider organizations will be listed here`]: `Organizacje dostawców będą wymienione w tym miejscu`,
  [`Provider-Org`]: `Organizacja dostawcy`,
  [`Provides contact information for the exposed API.`]: `Udostępnia informacje kontaktowe dla ujawnionego interfejsu API.`,
  [`Provides license information for the exposed API.`]: `Udostępnia informacje licencyjne dla ujawnionego interfejsu API.`,
  [`Provides metadata about the API. The metadata can be used by the clients if needed.`]: `Udostępnia metadane dotyczące interfejsu API. W razie potrzeby metadane mogą być używane przez klienty.`,
  [`Provisioning of the developer portal has been initiated. It will take a few minutes to complete. Once complete, you will receive an email containing a link to set the password for the portal admin account.`]: `Zainicjowano udostępnianie portalu Developer Portal. Wykonanie tej operacji zajmie kilka minut. Po jej zakończeniu otrzymasz wiadomość e-mail zawierającą odsyłacz umożliwiający ustawienie hasła dla konta administratora portalu.`,
  [`The update of the developer portal URL has been initiated. The process will take a few minutes to complete.`]: `Zainicjowano aktualizację adresu portalu Developer Portal. Wykonanie tej operacji zajmie kilka minut.`,
  [`Proxy`]: `Proxy`,
  [`Proxy URL`]: `Adres URL proxy`,
  [`Public`]: `Publiczne`,
  [`Public Key or Shared Secret for Encrypt`]: `Klucz publiczny lub współużytkowane dane niejawne dla szyfrowania`,
  [`Publish`]: `Publikuj`,
  [`Publish API`]: `Publikuj interfejs API`,
  [`Publish a product from the Develop section.`]: `Opublikuj produkt z sekcji Tworzenie.`,
  [`Publish product`]: `Opublikuj produkt`,
  [`Publish to`]: `Publikuj do`,
  [`Publish some products to this catalog in order to create subscriptions to them.`]: `Publikowanie niektórych produktów do tego katalogu w celu utworzenia subskrypcji do nich.`,
  [`Publish target`]: `Miejsce docelowe publikowania`,
  [`Publish this API to the Sandbox catalog.`]: `Publikuj ten interfejs API do katalogu Środowisko testowe.`,
  [`Publish to specific gateway services`]: `Publikuj w konkretnych usługach bramy`,
  [`Publish using a new product`]: `Publikuj przy użyciu nowego produktu`,
  [`Publish using an existing product`]: `Publikuj przy użyciu istniejącego produktu`,
  [`Publishable Key`]: `Klucz możliwy do opublikowania`,
  [`Publishable key`]: `Klucz możliwy do opublikowania`,
  [`Publishable key tip`]: `Wskazówka do klucza możliwego do opublikowania`,
  [`Published`]: `Opublikowano`,
  [`Published product`]: `Opublikowany produkt`,
  [`Publishing`]: `Publikowanie`,
  [`Publishing New API`]: `Publikowanie nowego interfejsu API`,
  [`Publish (preserve subscriptions)`]: `Opublikuj (zachowaj subskrypcje)`,
  [`Publish|permission`]: `Publikowanie`,
  [`Put online`]: `Przełącz w tryb z połączeniem`,
  [`Query`]: `Zapytanie`,
  [`Queryparam Encode`]: `Kodowanie parametrów zapytania`,
  [`Queued`]: `W kolejce`,
  [`Queued events: **{queued}**`]: `Zdarzenia w kolejce: **{queued}**`,
  [`Retry blocked jobs`]: `Spróbuj ponownie wykonać zablokowane zadania`,
  [`REQUIRED`]: `WYMAGANE`,
  [`ROLES`]: `ROLE`,
  [`Rate limit`]: `Limit częstotliwości`,
  [`Rate Limit`]: `Limit szybkości`,
  [`Rate Limit Name`]: `Nazwa limitu częstotliwości`,
  [`Rate limits`]: `Limity częstotliwości`,
  [`Rate limit name can't be a duplicate`]: `Nazwa limitu częstotliwości nie może być duplikatem.`,
  [`Rate limit name can't be empty`]: `Nazwa limitu częstotliwości nie może być pusta.`,
  [`Rate limit overrides`]: `Nadpisania limitów częstotliwości`,
  [`Rate limit time interval can't be 0`]: `Odstęp czasu limitu częstotliwości nie może mieć wartości 0.`,
  [`Rate limit time interval can't be empty`]: `Odstęp czasu limitu częstotliwości nie może być pusty`,
  [`Rate limit time interval unit must be selected`]: `Należy wybrać jednostkę odstępu czasu limitu częstotliwości.`,
  [`Rate limit value can't be empty`]: `Wartość limitu częstotliwości nie może być pusta.`,
  [`Rate limit value can't be negative`]: `Wartość limitu częstotliwości nie może być liczbą ujemną.`,
  [`Re-stage`]: `Przemieść ponownie`,
  [`Re-stage product`]: `Ponowne przemieszczanie produktu`,
  [`Read only`]: `Tylko do odczytu`,
  [`Read-Only`]: `Tylko do odczytu`,
  [`Realized`]: `Zrealizowane`,
  [`Reason`]: `Przyczyna`,
  [`Reason for declining`]: `Przyczyna odrzucenia`,
  [`Recommendations`]: `Rekomendacje`,
  [`Recommended configuration`]: `Rekomendowana konfiguracja`,
  [`Record the client ID and secret shown below for later use. The client secret cannot be displayed later.`]: `Zanotuj wyświetlone poniżej identyfikator i dane niejawne klienta w celu późniejszego wykorzystania. Danych niejawnych klienta nie można już będzie wyświetlić później.`,
  [`Recommendations applied`]: `Zastosowano rekomendacje`,
  [`Redact`]: `Redaguj`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath expression.`]: `Zredaguj lub usuń określone pola z treści żądania, treści odpowiedzi i dzienników działań. Zdefiniuj pole do zredagowania lub usunięcia, podając wyrażenie XPath.`,
  [`Redact or remove specified fields from the Request body, the Response body, and the activity logs. Define the field to redact or remove by specifing an XPath or JSONata expression.`]: `Zredaguj lub usuń określone pola z treści żądania, treści odpowiedzi i dzienników działań. Zdefiniuj pole do zredagowania lub usunięcia, podając wyrażenie XPath lub JSONata.`,
  [`Redaction`]: `Redakcja`,
  [`Redactions`]: `Redakcje`,
  [`Redirect`]: `Przekierowanie`,
  [`Redirect URI`]: `Identyfikator URI przekierowania`,
  [`Redirect URL`]: `Adres URL przekierowania`,
  [`Redirect through Portal`]: `Przekieruj przez portal`,
  [`Redirect time limit`]: `limit czasu przekierowania`,
  [`Redirect time limit must be between 10 and 600 seconds`]: `Limit czasu przekierowania musi być wartością z zakresu od 10 do 600 sekund`,
  [`References`]: `Odwołania`,
  [`Refer to the [IBM Cloud Docs]({link}) for additional guidance.`]: `Aby uzyskać dodatkowe wskazówki, patrz [IBM Cloud Docs]({link}).`,
  [`Referral`]: `Konsultacja`,
  [`Refresh`]: `Odśwież`,
  [`Refresh Token`]: `Token odświeżania`,
  [`Refresh button`]: `Przycisk Odśwież`,
  [`Refresh products`]: `Odśwież produkty`,
  [`Refresh results from server`]: `Odśwież wyniki z serwera`,
  [`Refresh token`]: `Token odświeżania`,
  [`Refresh token time to live`]: `Czas życia tokenu odświeżania`,
  [`Refresh token time-to-live`]: `Czas życia tokenu odświeżania`,
  [`Refresh token time-to-live (seconds)`]: `Czas życia tokenu odświeżania (w sekundach)`,
  [`Refresh token TTL has been changed`]: `Czas życia tokenu odświeżania został zmieniony`,
  [`Refresh token time to live is **{seconds} seconds ({time})**`]: `Czas życia tokenu odświeżania to **{seconds} s ({time})**`,
  [`Refresh token time to live is **off**`]: `Czas życia tokenu odświeżania ustawiony na **wył.**`,
  [`Refresh tokens`]: `Tokeny odświeżania`,
  [`Refreshing`]: `Odświeżanie`,
  [`Refreshing...`]: `Odświeżanie...`,
  [`Region`]: `Region`,
  [`Register`]: `Zarejestruj`,
  [`Register Gateways`]: `Zarejestruj bramy.`,
  [`Register Service`]: `Zarejestruj usługę`,
  [`Register gateways`]: `Zarejestruj bramy`,
  [`Register service`]: `Zarejestruj usługę`,
  [`Register a service`]: `Zarejestruj usługę`,
  [`Register new services and manage existing services`]: `Zarejestruj nowe usługi i zarządzaj istniejącymi.`,
  [`Register remote gateway`]: `Zarejestruj bramę zdalną`,
  [`Registered Admin Member`]: `Zarejestrowany członek administracyjny`,
  [`Registered Provider Org Owner or Member`]: `Członek lub właściciel zarejestrowanej organizacji dostawcy`,
  [`Registered client information on the OpenID provider`]: `Informacje o kliencie zarejestrowanym u dostawcy OpenID`,
  [`Registration completed successfully`]: `Rejestracja została pomyślnie zakończona`,
  [`Registration failed.`]: `Rejestracja nie powiodła się.`,
  [`Relative paths to the individual endpoints. They must be relative to the 'basePath'.`]: `Ścieżki względne do poszczególnych punktów końcowych. Muszą one być względne w stosunku do ścieżki basePath.`,
  [`Relaxed Badgerfish`]: `Relaxed Badgerfish`,
  [`Remove analysis configuration`]: `Usuń konfigurację analizy`,
  [`Remote API Connect`]: `Zdalny interfejs API Connect`,
  [`Remote login`]: `Logowanie zdalne`,
  [`Remote publish`]: `Publikowanie zdalne`,
  [`Remove`]: `Usuń`,
  [`Remove Burst Limit`]: `Usuń limit wywołań`,
  [`Remove constraints`]: `Usuń ograniczenia`,
  [`Remove Count Limit`]: `Usuń limit liczby`,
  [`Remove gateway extension`]: `Usuń rozszerzenie bramy`,
  [`Remove product`]: `Usuń produkt`,
  [`Remove Rate limit`]: `Usuń limit częstotliwości`,
  [`Remove the invalid constraints`]: `Usuń niepoprawne ograniczenia`,
  [`Remove the invalid constraints from`]: `Usuń niepoprawne ograniczenia z`,
  [`Remove action`]: `Usuń działanie`,
  [`Remove case`]: `Usuń przypadek`,
  [`Remove catch`]: `Usuń element wychwycony`,
  [`Remove filter`]: `Usuń filtr`,
  [`Remove input`]: `Usuń wejście`,
  [`Remove mapping`]: `Usuń odwzorowanie`,
  [`Remove output`]: `Usuń wyjście`,
  [`Remove static value`]: `Usuń wartość statyczną`,
  [`Removed`]: `Usunięto`,
  [`Removed by user`]: `Usunięte przez użytkownika`,
  [`Reorder`]: `Zmień kolejność`,
  [`Repeat`]: `Powtórz`,
  [`Repeat the API invocation a set number of times, or until the stop button is clicked`]: `Powtórz wywołanie interfejsu API określoną liczbę razy lub do chwili kliknięcia przycisku zatrzymania`,
  [`Replace`]: `Zastąp`,
  [`Replace Free Trial with`]: `Zastąp bezpłatną wersję próbną`,
  [`Replace Gold with`]: `Zastąp plan Gold planem`,
  [`Replace GraphQL schema`]: `Zastąp schemat GraphQL`,
  [`Replace plans`]: `Zastępowanie planów`,
  [`Replace private key & public key`]: `Zastąp klucz prywatny i klucz publiczny`,
  [`Replace Product`]: `Zastępowanie produktu`,
  [`Replace product`]: `Zastąp produkt`,
  [`Replace schema`]: `Zastąp schemat`,
  [`Replace Silver with`]: `Zastąp plan Silver planem`,
  [`Replace the current owner by choosing the new owner from the list of suggestions. Assign the replaced owner new permissions so they can continue to work.`]: `Zastąp bieżącego właściciela, wybierając nowego z listy proponowanych. Przypisz do nowego właściciela nowe uprawnienia, aby mógł kontynuować pracę.`,
  [`Replace|permission`]: `Zastępowanie`,
  [`Republish`]: `Publikuj ponownie`,
  [`Republish product`]: `Ponownie opublikuj produkt`,
  [`Request`]: `Żądanie`,
  [`Request catch`]: `Wychwyt żądania`,
  [`Request endpoint`]: `Punkt końcowy żądania`,
  [`Request approved to {{action}} API product in catalog {{catalog}}`]: `Zatwierdzono żądanie dotyczące działania {action} dla produktu API w katalogu {catalog}`,
  [`Request approved to {{action}} app {{appName}}`]: `Zatwierdzono żądanie dotyczące działania {action} dla aplikacji {appName}`,
  [`Request denied to {{action}} API product in catalog {{catalog}}`]: `Odrzucono żądanie dotyczące działania {action} dla produktu API w katalogu {catalog}`,
  [`Request denied to {{action}} app {{appName}}`]: `Odrzucono żądanie dotyczące działania {action} dla aplikacji {appName}`,
  [`Request for approval to subscribe to an API product in the {{catalog}} catalog`]: `Żądanie zatwierdzenia subskrypcji produktu API w katalogu {catalog}`,
  [`Request for approval to {{action}} app {{appName}}`]: `Żądanie zatwierdzenia działania {action} dla aplikacji {appName}`,
  [`Request headers`]: `Nagłówki żądań`,
  [`Request pending to promote application to production`]: `Oczekuje żądanie dotyczące awansowania aplikacji do wersji produkcyjnej`,
  [`Request query`]: `Zapytanie żądania`,
  [`Request received to {{action}} API product in catalog {{catalog}}`]: `Odebrano żądanie dotyczące działania {action} dla produktu API w katalogu {catalog}`,
  [`Request received to {{action}} app {{appName}}`]: `Odebrano żądanie dotyczące działania {action} dla aplikacji {appName}`,
  [`Request withdrawn to subscribe to an API product in the {{catalog}} catalog`]: `Wycofano żądanie subskrypcji produktu API w katalogu {catalog}`,
  [`Request withdrawn to {{action}} an API product in the {{catalog}} catalog`]: `Wycofano żądanie dotyczące działania {action} dla produktu API w katalogu {catalog}`,
  [`Request withdrawn to {{action}} app {{appName}}`]: `Żądanie wykonania operacji {action} dla aplikacji {appName} zostało wycofane`,
  [`Requested approvals`]: `Żądane zatwierdzenia`,
  [`Requested By`]: `Żądane przez`,
  [`Requested To`]: `Żądane dla`,
  [`Require`]: `Wymagaj`,
  [`Require applications to provide a client ID`]: `Wymagaj podawania przez aplikacje identyfikatora klienta`,
  [`Require approval for all new self service development accounts`]: `Wymagaj zatwierdzenia dla wszystkich nowych samoobsługowych kont programistycznych`,
  [`Required`]: `Wymagane`,
  [`Required for rate limiting`]: `Wymagane do ograniczenia częstotliwości`,
  [`Required must be checked off when location is in path.`]: `Pole Wymagane musi być zaznaczone, gdy lokalizacja znajduje się w ścieżce.`,
  [`Required path`]: `Wymagana ścieżka`,
  [`Resend Invite`]: `Ponownie wyślij zaproszenie`,
  [`Resend invitation`]: `Ponownie wyślij zaproszenie`,
  [`Resend invite`]: `Ponownie wyślij zaproszenie`,
  [`Reset`]: `Resetuj`,
  [`Reset defaults`]: `Przywróć wartości domyślne`,
  [`Reset Notification template`]: `Resetuj szablon powiadomienia`,
  [`Reset password`]: `Resetuj hasło`,
  [`Reset password TTL has been changed`]: `Zmieniono czas życia resetowania hasła`,
  [`Reset password time to live`]: `Czas życia resetowania hasła`,
  [`Reset password time-to-live`]: `Czas życia resetowania hasła`,
  [`Reset password email has been sent`]: `Wiadomość e-mail dotycząca resetowania hasła została wysłana.`,
  [`Reset password email has not been sent`]: `Wiadomość e-mail dotycząca resetowania hasła nie została wysłana.`,
  [`Reset password invitation timeout`]: `Limit czasu zaproszenia do resetowania hasła`,
  [`Reset to default preferences`]: `Przywróć preferencje domyślne`,
  [`Resolve API Connect variable references`]: `Rozstrzygaj odwołania do zmiennych produktu API Connect`,
  [`Resolve XML input data type`]: `Rozstrzygaj typ danych wejściowych XML`,
  [`resolver`]: `resolver`,
  [`Resource group`]: `Grupa zasobów`,
  [`Resource Owner`]: `Właściciel zasobu`,
  [`Resource owner password grant`]: `Nadanie hasła właściciela zasobu`,
  [`Resource owner revocation`]: `Odwołanie właściciela zasobu`,
  [`Resource owner revocation path`]: `Ścieżka odwołania właściciela zasobu`,
  [`Resource Owner Security`]: `Zabezpieczenia właściciela zasobu`,
  [`Resource owner - JWT`]: `Właściciel zasobu – znacznik JWT`,
  [`Resource owner - Password`]: `Właściciel zasobu – hasło`,
  [`Resource owner password`]: `Hasło właściciela zasobu`,
  [`Resources`]: `Zasoby`,
  [`Response`]: `Odpowiedź`,
  [`Response catch`]: `Wychwyt odpowiedzi`,
  [`Response Definition`]: `Definicja odpowiedzi`,
  [`Response Time`]: `Czas odpowiedzi`,
  [`Response type`]: `Typ odpowiedzi`,
  [`Response context variables`]: `Zmienne kontekstowe odpowiedzi`,
  [`Response headers`]: `Nagłówki odpowiedzi`,
  [`Response must have a description`]: `Odpowiedź musi mieć opis`,
  [`Response must have a schema`]: `Odpowiedź musi mieć schemat`,
  [`Response must have a status code`]: `Odpowiedź musi mieć kod statusu`,
  [`Response object variable`]: `Zmienna obiektu odpowiedzi`,
  [`Response time`]: `Czas odpowiedzi`,
  [`Response time: {{value}}ms`]: `Czas odpowiedzi: {{value}} ms`,
  [`Result of removing`]: `Wynik usunięcia`,
  [`Restrict HTTP transactions to version 1.0.`]: `Ogranicz liczbę transakcji HTTP do wersji 1.0.`,
  [`Restrict to HTTP 1.0`]: `Ogranicz do HTTP 1.0`,
  [`Retire`]: `Wycofaj`,
  [`Retire product`]: `Wycofywanie produktu`,
  [`Retired`]: `Wycofane`,
  [`Retire|permission`]: `Wycofywanie`,
  [`Return V5 Responses`]: `Zwroty odpowiedzi V5`,
  [`Retry selected requests`]: `Ponów wybrane żądania`,
  [`Return results for standard GraphQL introspection queries from GraphQL proxy`]: `Zwróć wyniki standardowych zapytań introspekcyjnych GraphQL z proxy GraphQL`,
  [`Return third-party access_token`]: `Zwracaj token access_token innego podmiotu`,
  [`Return third-party access_token with the token response`]: `Zwracaj token access_token innego podmiotu z odpowiedzią na token`,
  [`Return third-party id_token`]: `Zwracaj token id_token innego podmiotu`,
  [`Return third-party id_token with the token response`]: `Zwracaj token id_token innego podmiotu z odpowiedzią na token`,
  [`Return to Login`]: `Wróć do logowania`,
  [`Reveal in finder`]: `Ujawnij w wyszukiwarce`,
  [`Review and determine what to show or hide in the schema. Addtional changes may be required to keep the shema valid.`]: `Przejrzyj i określ elementy, które mają być widoczne lub ukryte w schemacie. Mogą być wymagane dodatkowe zmiany w celu zapewnienia poprawności schematu.`,
  [`Review any warnings. We will help you improve your schema with intelligent recommendations later in the editor.`]: `Przejrzyj wszystkie ostrzeżenia. Pomożemy udoskonalić schemat dzięki inteligentnym rekomendacjom dostępnym później w edytorze.`,
  [`Review any warnings and apply our intelligent recommendations to improve your schema and protect your backend.`]: `Zapoznaj się z ewentualnymi ostrzeżeniami i zastosuj nasze inteligentne rekomendacje, aby udoskonalić schemat i zabezpieczyć zaplecze.`,
  [`Review the base URL of the API invocation endpoint and specify the domains handled by the gateway.`]: `Sprawdź podstawowy adres URL punktu końcowego wywołania interfejsu API i określ domeny obsługiwane przez bramę.`,
  [`Review the gateway type, title, name, and summary. When invoking API Connect commands and APIs, use the gateway name.`]: `Sprawdź typ, tytuł, nazwę i podsumowanie bramy. Wywołując komendy API Connect i interfejsy API, należy używać nazwy bramy.`,
  [`Review the summary of changes, which may include additional changes to keep the schema valid.`]: `Przejrzyj podsumowanie zmian, które mogą obejmować dodatkowe zmiany wymagane dla zapewnienia poprawności schematu.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations later in the editor.`]: `Zapoznaj się z ostrzeżeniami dotyczącymi schematu. Pomożemy udoskonalić schemat i chronić zaplecze dzięki inteligentnym rekomendacjom dostępnym później w edytorze.`,
  [`Review the warnings we found in your schema. We will help you improve your schema and protect your backend with intelligent recommendations.`]: `Zapoznaj się z ostrzeżeniami dotyczącymi schematu. Pomożemy udoskonalić schemat i chronić zaplecze dzięki inteligentnym rekomendacjom.`,
  [`Reviewed schema, paths and operations`]: `Sprawdzono schemat, ścieżki i operacje`,
  [`Revocation protocol`]: `Protokół unieważniania`,
  [`Revoke token`]: `Unieważnij token`,
  [`RHEL`]: `RHEL`,
  [`RHEL docs`]: `Dokumentacja RHEL`,
  [`Role`]: `Rola`,
  [`Role default`]: `Wartość domyślna roli`,
  [`Role default has been deleted`]: `Usunięto wartość domyślną roli`,
  [`Role defaults`]: `Wartości domyślne ról`,
  [`Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Wartości domyślne ról są wstępnie skonfigurowanymi szablonami ról używanymi do określania domyślnych ról, które są dostępne podczas tworzenia nowej organizacji.`,
  [`Role has been created`]: `Utworzono rolę`,
  [`Role has been deleted`]: `Rola została usunięta`,
  [`Role has been updated`]: `Zaktualizowano rolę`,
  [`Roles`]: `Role`,
  [`Roles (and their assigned permissions) control access to features in API Connect. The admin organization roles apply only to people using Cloud Manager.`]: `Role (i przypisane im uprawnienia) sterują dostępem do funkcji w produkcie API Connect. Role w organizacji administracyjnej mają zastosowanie tylko do osób korzystających z programu Cloud Manager.`,
  [`Roles and permissions control access to API Manager capabilities.`]: `Role i uprawnienia umożliwiają sterowanie dostępem do możliwości produktu API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in API Connect.`]: `Role i powiązane z nimi uprawnienia sterują dostępem do funkcji i możliwością wykonywania zadań w produkcie API Connect.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks in the API Manager UI.`]: `Role i powiązane z nimi uprawnienia sterują dostępem do funkcji i możliwością wykonywania zadań w interfejsie użytkownika produktu API Manager.`,
  [`Roles and their associated permissions control access to features and the ability to perform tasks.`]: `Role i powiązane z nimi uprawnienia sterują dostępem do funkcji i możliwością wykonywania zadań.`,
  [`Root`]: `Element główny`,
  [`Root XML Element Name`]: `Nazwa elementu głównego XML`,
  [`Run the following command in the CLI to register the credentials:`]: `Uruchom następującą komendę w interfejsie CLI, aby zarejestrować referencje:`,
  [`Run the following command in the CLI to register the Toolkit credentials:`]: `Uruchom następującą komendę w interfejsie CLI, aby zarejestrować referencje biblioteki narzędziowej:`,
  [`Running`]: `Uruchomiony`,
  [`Runtime variable containing the JWK to use to decrypt the JWT.`]: `Zmienna środowiska wykonawczego zawierająca klucz JWK, która będzie używana do deszyfrowania znacznika JWT.`,
  [`Runtime variable containing the JWK to use to encrypt the JWT.`]: `Zmienna środowiska wykonawczego zawierająca klucz JWK, która ma być używana do szyfrowania znacznika JWT.`,
  [`Runtime variable containing the JWK to use to sign the JWT.`]: `Zmienna środowiska wykonawczego zawierająca klucz JWK, która ma być używana do podpisywania znacznika JWT.`,
  [`Runtime variable containing the JWK to use to verify the signature.`]: `Zmienna środowiska wykonawczego zawierająca klucz JWK, która będzie używana do weryfikowania podpisu.`,
  [`Runtime variable from which a valid set of JSON claims can be retrieved.`]: `Zmienna środowiska wykonawczego, z której można pobrać poprawny zestaw roszczeń JSON.`,
  [`Runtime variable from which the Audience (aud) claim string can be retrieved. Multiple variables are set via a comma-separated string.`]: `Zmienna środowiska wykonawczego, z której można pobrać łańcuch roszczenia audytorium (aud). Można ustawić wiele zmiennych, używając łańcucha rozdzielanego przecinkami.`,
  [`Runtime variable from which the Issuer (iss) claim string can be retrieved. This claim represents the Principal that issued the JWT.`]: `Zmienna środowiska wykonawczego, z której można pobrać łańcuch roszczenia wystawcy (iss). To roszczenie reprezentuje podmiot, który wystawił znacznik JWT.`,
  [`Runtime variable from which the Subject (sub) claim string can be retrieved.`]: `Zmienna środowiska wykonawczego, z której można pobrać łańcuch roszczenia podmiotu (sub).`,
  [`Runtime variable in which to place the generated JWT. If not set, the JWT is placed in the Authorization Header as a Bearer token.`]: `Zmienna środowiska wykonawczego, w której ma zostać umieszczony wygenerowany token JWT. Jeśli nie zostanie ustawiona, token JWT zostanie umieszczony w nagłówku autoryzacji jako token posiadacza.`,
  [`Runtime variable that contains the authenticated user name. The LTPA token is generated with this property as the user. For example, $(client.app.id), or $(oauth.resource-owner), or a runtime variable that is configured in a set-variable policy.`]: `Zmienna środowiska wykonawczego, która zawiera uwierzytelnioną nazwę użytkownika. Token LTPA jest generowany z wartością tej właściwości jako nazwą użytkownika. Na przykład $(client.app.id), $(oauth.resource-owner) lub zmienna środowiska wykonawczego, która jest skonfigurowana w strategii ustawiania zmiennej.`,
  [`Runtime variable to which the full set of claims that are in the JWT is assigned.`]: `Zmienna środowiska wykonawczego, do której przypisany jest pełny zestaw roszczeń w znaczniku JWT.`,
  [`Russian`]: `rosyjski`,
  [`Scalar`]: `Skalarny`,
  [`Schema`]: `Schemat`,
  [`SCHEMA`]: `SCHEMAT`,
  [`SCOPE`]: `ZASIĘG`,
  [`SCOPES`]: `ZASIĘGI`,
  [`SECURITY DEFINITIONS`]: `DEFINICJE ZABEZPIECZEŃ`,
  [`Service`]: `Usługa`,
  [`Service state:`]: `Stan usługi:`,
  [`SERVICE TYPE`]: `TYP USŁUGI`,
  [`SIL-OFL 1.1 license`]: `Licencja SIL-OFL 1.1`,
  [`SOURCE`]: `ŹRÓDŁO`,
  [`SSL Profile`]: `Profil SSL`,
  [`State`]: `Stan`,
  [`STATUS`]: `STATUS`,
  [`Status Code`]: `Kod statusu`,
  [`STATUS CODE`]: `KOD STATUSU`,
  [`SUBMITTED`]: `WPROWADZONE`,
  [`SUBSCRIBERS`]: `SUBSKRYBENCI`,
  [`Summary`]: `Podsumowanie`,
  [`Same as product`]: `Taki sam jak w przypadku produktu`,
  [`Sample ID token signing key (RS256)`]: `Przykładowy klucz podpisywania tokenu identyfikatora (RS256)`,
  [`Sample ID token validation key (RS256)`]: `Przykładowy klucz sprawdzania poprawności tokenu identyfikatora (RS256)`,
  [`Sample OAuth symmetric key value`]: `Przykładowa wartość klucza symetrycznego OAuth`,
  [`Sample URL: https://api.catalog.org.host/`]: `Przykładowy adres URL: https://api.catalog.org.host/`,
  [`Sample URL: https://host/org/catalog/`]: `Przykładowy adres URL: https://host/org/catalog/`,
  [`Sample scope description 1`]: `Opis przykładowego zasięgu 1`,
  [`Sandbox - Overview`]: `Środowisko testowe – przegląd`,
  [`Sandbox Catalog`]: `Katalog środowiska testowego`,
  [`Sandbox Catalog User Registry`]: `Rejestr użytkowników katalogu środowiska testowego`,
  [`Sandbox Default TLS client profile`]: `Domyślny profil klienta TLS środowiska testowego`,
  [`Sandbox Default TLS Client Profile`]: `Domyślny profil klienta TLS środowiska testowego`,
  [`Sandbox catalog should always exist in a provider organization.`]: `Katalog środowiska testowego musi zawsze istnieć w organizacji dostawcy.`,
  [`Save`]: `Zapisz`,
  [`Save API as a new version`]: `Zapisywanie interfejsu API jako nowej wersji`,
  [`Save Product as a new version`]: `Zapisywanie produktu jako nowej wersji`,
  [`Save and Edit`]: `Zapisz i edytuj`,
  [`Save and republish`]: `Zapisz i opublikuj ponownie`,
  [`Save as a new version`]: `Zapisz jako nową wersję`,
  [`Save the client secret (it will no longer be retrievable for security purposes)`]: `Zapisz dane niejawne klienta (później nie będzie można ich pobrać na potrzeby obsługi zabezpieczeń).`,
  [`Save this product as a new version`]: `Zapisywanie produktu jako nowej wersji`,
  [`Saved`]: `Zapisano`,
  [`Saving assembly...`]: `Zapisywanie zespołu...`,
  [`Saving...`]: `Zapisywanie...`,
  [`Schema Type`]: `Typ schematu`,
  [`Schema as JSON`]: `Schemat jako JSON`,
  [`Schema as XML`]: `Schemat jako XML`,
  [`Schema as YAML`]: `Schemat jako YAML`,
  [`Schema change`]: `Zmiana schematu`,
  [`Schema changes`]: `Zmiany schematu`,
  [`Schema definition circular reference limit`]: `Limit odwołań cyklicznych definicji schematu`,
  [`Schema name`]: `Nazwa schematu`,
  [`Schema warnings`]: `Ostrzeżenia dotyczące schematu`,
  [`Schema definition`]: `Definicja schematu`,
  [`Scheme`]: `Schemat`,
  [`Schemes`]: `Schematy`,
  [`Scope`]: `Zasięg`,
  [`Scopes`]: `Zasięgi`,
  [`Scopes cannot be empty`]: `Zasięgi nie mogą być puste`,
  [`Search`]: `Szukaj`,
  [`Search DN`]: `Wyszukaj nazwę DN`,
  [`Search in the field above above and select to add provider organizations`]: `Wyszukuj w powyższym polu organizacje dostawców, które chcesz dodać`,
  [`Search Limit`]: `Limit wyszukiwania`,
  [`Search Organization owner from`]: `Wyszukiwanie właściciela organizacji z`,
  [`Search orgs/groups`]: `Wyszukaj organizacje/grupy`,
  [`Search Owner`]: `Wyszukiwanie właściciela`,
  [`Search assemblies`]: `Zespoły wyszukiwania`,
  [`Search for`]: `Wyszukaj`,
  [`Search for provider organizations`]: `Szukaj organizacji dostawców`,
  [`Search for Owner`]: `Wyszukiwanie właściciela`,
  [`Search for a new member to become the new owner.`]: `Wyszukiwanie nowego członka, który ma zostać nowym właścicielem.`,
  [`Search for an existing member to become the new owner.`]: `Wyszukiwanie istniejącego członka, który ma zostać nowym właścicielem.`,
  [`Search for new owner`]: `Wyszukiwanie nowego właściciela`,
  [`Search for owner from:`]: `Wyszukaj właściciela z:`,
  [`Search for the member`]: `Wyszukiwanie członka`,
  [`Search for title or name or version`]: `Wyszukaj tytuł, nazwę lub wersję`,
  [`Search for the user that you want to add as a member, and assign the required roles.`]: `Wyszukaj użytkownika, który ma zostać dodany jako członek, a następnie przypisz wymagane role.`,
  [`Search overflow`]: `Przepełnienie wyszukiwania`,
  [`Search the registry for the existing user that you want to add as a member, and assign the required roles`]: `Przeszukaj rejestr, aby znaleźć użytkownika, który ma zostać dodany jako członek, a następnie przypisz wymagane role`,
  [`Search types/fields`]: `Typy wyszukiwania/pola`,
  [`Search user`]: `Wyszukaj użytkownika`,
  [`Search schema`]: `Szukaj schematu`,
  [`Seconds`]: `Sekundy`,
  [`Secret Key`]: `Klucz niejawny`,
  [`Secret Name`]: `Nazwa niejawna`,
  [`Secret Required`]: `Dane niejawne są wymagane`,
  [`Secret key`]: `Klucz niejawny`,
  [`Secure`]: `Zabezpieczanie`,
  [`Secure connection`]: `Bezpieczne połączenie`,
  [`Secure using Client ID`]: `Zabezpieczaj za pomocą identyfikatora klienta`,
  [`Security`]: `Zabezpieczenia`,
  [`Security & Product Definitions`]: `Zabezpieczenia i definicje produktów`,
  [`Security definitions`]: `Definicje zabezpieczeń`,
  [`Security and Publish`]: `Bezpieczeństwo i publikowanie`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth.`]: `Definicje zabezpieczeń umożliwiają kontrolę dostępu klienta do punktów końcowych interfejsów API, w tym sprawdzanie poprawności klucza API, podstawowe uwierzytelnianie przy użyciu określonego rejestru użytkowników i protokołu OAuth.`,
  [`Security definitions control client access to API endpoints, including API key validation, basic authentication through a specified user registry, and OAuth. [Learn more]({link})`]: `Definicje zabezpieczeń umożliwiają kontrolę dostępu klienta do punktów końcowych interfejsów API, w tym sprawdzanie poprawności klucza API, podstawowe uwierzytelnianie przy użyciu określonego rejestru użytkowników i protokołu OAuth. [Więcej informacji]({link})`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations.`]: `Wybrane w tym miejscu definicje zabezpieczeń mają zastosowanie w całym interfejsie API, ale mogą być przesłaniane w przypadku poszczególnych operacji.`,
  [`Security definitions selected here apply across the API, but can be overridden for individual operations. [Learn more]({link})`]: `Wybrane w tym miejscu definicje zabezpieczeń mają zastosowanie w całym interfejsie API, ale mogą być przesłaniane w przypadku poszczególnych operacji. [Więcej informacji]({link})`,
  [`Security object to use to decode the claim.`]: `Obiekt zabezpieczeń, który ma być używany do dekodowania roszczenia.`,
  [`Security object used to encrypt the JWT.`]: `Obiekt zabezpieczeń używany do szyfrowania JWT.`,
  [`Security object used to sign the JWT.`]: `Obiekt zabezpieczeń używany do podpisywania JWT.`,
  [`Security object used to verify the claim.`]: `Obiekt zabezpieczeń używany do weryfikowania roszczenia.`,
  [`Select APIs to add to this product`]: `Wybierz interfejsy API, które mają zostać dodane do tego produktu`,
  [`Select API Type`]: `Wybierz typ interfejsu API`,
  [`Select API type`]: `Wybierz typ interfejsu API`,
  [`Select a billing integration in the Product plans.`]: `Wybierz integrację rozliczeń w obszarze Plany produktu.`,
  [`Select a flow...`]: `Wybierz przepływ...`,
  [`Select a plan from your existing product`]: `Wybierz plan z istniejącego produktu`,
  [`Select a policy file to import to this catalog`]: `Wybierz plik strategii do zaimportowania do tego katalogu`,
  [`Select a policy implementation file to import to this catalog`]: `Wybierz plik implementacji strategii do zaimportowania do tego katalogu`,
  [`Select a product`]: `Wybierz produkt`,
  [`Select Certificate Manager service`]: `Wybierz usługę Certificate Manager`,
  [`Select Cloud`]: `Wybór chmury`,
  [`Select Email Server`]: `Wybór serwera poczty elektronicznej`,
  [`Select gateway services`]: `Wybór usług bramy`,
  [`Select Import Type`]: `Wybór typu importu`,
  [`Select product`]: `Wybierz produkt`,
  [`Select product type`]: `Wybierz typ produktu`,
  [`Select Service`]: `Wybór usługi`,
  [`Select Service Type`]: `Wybór typu usługi`,
  [`Select target endpoints`]: `Wybierz docelowe punkty końcowe`,
  [`Select target service`]: `Wybierz usługę docelową`,
  [`Select user registry type`]: `Wybierz typ rejestru użytkowników`,
  [`Select a WSDL service from the imported file`]: `Wybierz usługę WSDL z zaimportowanego pliku.`,
  [`Select a certificate`]: `Wybierz certyfikat`,
  [`Select a Certificate Manager service`]: `Wybierz usługę w powyższym polu`,
  [`Select a cryptographic algorithm.`]: `Wybierz algorytm szyfrowania.`,
  [`Select a default catalog and organization`]: `Wybór katalogu domyślnego i organizacji`,
  [`Select a different cloud`]: `Wybierz inną chmurę`,
  [`Select a key encryption algorithm.`]: `Wybierz algorytm szyfrowania klucza.`,
  [`Select a plan to migrate`]: `Wybierz plan do migracji`,
  [`Select a plan to replace`]: `Wybierz plan do zastąpienia`,
  [`Select a plan to supersede`]: `Wybierz plan do zastąpienia nowszą wersją`,
  [`Select a product to set as the migration target`]: `Wybierz produkt, który ma zostać ustawiony jako element docelowy migracji.`,
  [`Select a product to supersede`]: `Wybierz produkt do zastąpienia`,
  [`Select a product to supersede {sourceProductName}:`]: `Wybierz produkt, który ma zastąpić produkt {sourceProductName}:`,
  [`Select a resource group`]: `Wybierz grupę zasobów`,
  [`Select a service`]: `Wybierz usługę`,
  [`Select a supported file ({type}) and try again.`]: `Wybierz obsługiwany plik ({type}) i spróbuj ponownie.`,
  [`Select a target SOAP service`]: `Wybór docelowej usługi SOAP`,
  [`Select a toolkit package to download. Skip this step if you have the toolkit installed.`]: `Wybierz pakiet biblioteki narzędziowej do pobrania. Pomiń ten krok, jeśli biblioteka narzędziowa jest już zainstalowana.`,
  [`Select an encryption algorithm.`]: `Wybierz algorytm szyfrowania.`,
  [`Select an extension file for this gateway`]: `Wybierz plik rozszerzenia dla tej bramy`,
  [`Select an org`]: `Wybierz organizację`,
  [`Select catalog`]: `Wybierz katalog`,
  [`Select Catalogs to Search`]: `Wybierz katalogi do przeszukiwania`,
  [`Select compatible gateway services`]: `Wybierz kompatybilne usługi bramy`,
  [`Select one Keystore from the list below:`]: `Wybierz jeden magazyn kluczy z poniższej listy:`,
  [`Select one keystore from the list below:`]: `Wybierz jeden magazyn kluczy z poniższej listy:`,
  [`Select one keystore from the list.`]: `Wybierz jeden magazyn kluczy z listy.`,
  [`Select operation...`]: `Wybierz operację...`,
  [`Select operations you want to include`]: `Wybierz operacje, które mają zostać dołączone`,
  [`Select organization`]: `Wybierz organizację`,
  [`Select the API definition or package file to import`]: `Wybierz definicję interfejsu API lub plik pakietu do zaimportowania`,
  [`Select the billing integration for this catalog`]: `Wybierz integrację rozliczeń dla tego katalogu`,
  [`Select the billing integration for this product`]: `Wybierz integrację rozliczeń dla tego produktu`,
  [`Select the gateways that will secure the APIs in a new catalog. To register a new gateway, visit the **Gateways** page.`]: `Wybierz bramy, które będą zabezpieczać interfejsy API w nowym katalogu. Aby zarejestrować nową bramę, odwiedź stronę **Bramy**.`,
  [`Select the [Certificate Manager]({link}) service that manages the certificates for this gateway.`]: `Wybierz usługę [Certificate Manager]({link}) zarządzającą certyfikatami na potrzeby tej bramy.`,
  [`Select the APIs to add to this product`]: `Wybierz interfejsy API do dodania do tego produktu`,
  [`Select the OpenAPI definition file to import`]: `Wybierz plik definicji OpenAPI do zaimportowania`,
  [`Select the Product lifecycle actions that require approval. When any of the selected actions is taken, an approval request is generated; the request must be approved for the action to complete.`]: `Wybierz działanie związane z cyklem życia produktu, które wymagają zatwierdzenia. Po podjęciu dowolnego z tych wybranych działań zostanie wygenerowane żądanie zatwierdzenia. Żądanie musi zostać zatwierdzone, aby możliwe było wykonanie działania.`,
  [`Select the TLS protocol versions for this profile.`]: `Wybierz wersje protokołu TLS dla tego profilu.`,
  [`Select the analytics service you would like to associate with the gateway service`]: `Wybierz usługę analizy danych, która ma zostać powiązana z usługą bramy`,
  [`Select the consumer organization in which you want to create the application`]: `Wybierz organizację konsumenta, w której ma zostać utworzona aplikacja`,
  [`Select the consumer organization that will own the application`]: `Wybierz organizację konsumenta, która będzie właścicielem aplikacji`,
  [`Select the endpoints to publish this API and define the rate limit as needed`]: `Wybór punktów końcowych do opublikowania interfejsu API i w razie potrzeby zdefiniowanie limitu częstotliwości`,
  [`Select the errors that, if thrown during the policy execution, cause the assembly flow to stop. If there is a catch flow configured for the error, it is triggered to handle the error thrown. If an error is thrown and there are no errors selected for the Stop on error setting, or if the error thrown is not one of the selected errors, the policy execution is allowed to complete, and the assembly flow continues.`]: `Wybierz błędy, które, jeśli zostaną zgłoszone podczas wykonywania strategii, powodują zatrzymanie przepływu zespołu. Jeśli dla błędu skonfigurowano przepływ wychwytywania, jest on wyzwalany w celu obsługi zgłoszonego błędu. Jeśli zostanie zgłoszony błąd i nie wybrano żadnych błędów dla ustawienia Zatrzymaj w razie błędu lub zgłoszony błąd nie jest jednym z wybranych błędów, wykonywanie strategii może zostać ukończone, a przepływ zespołu jest kontynuowany.`,
  [`Select the gateway services at which the APIs in the Product are to be made available.`]: `Wybierz usługi bramy, za pośrednictwem których interfejsy API w produkcie mają być udostępniane.`,
  [`Select the gateway type for this API`]: `Wybierz typ bramy dla tego interfejsu API`,
  [`Select the gateway type for this OAuth provider`]: `Wybierz typ bramy dla danego dostawcy OAuth`,
  [`Select the keystores and truststores for this profile.`]: `Wybierz magazyny kluczy i magazyny zaufanych certyfikatów dla tego profilu.`,
  [`Select the organizations or groups you would like to make this product visible to`]: `Wybierz organizacje lub grupy, dla których ten produkt ma być widoczny.`,
  [`Select the organizations or groups you would like to subscribe to this product`]: `Wybierz organizacje lub grupy, które mają zasubskrybować ten produkt.`,
  [`Select the portal service to use for this catalog`]: `Wybierz usługę portalu, która ma być używana na potrzeby tego katalogu`,
  [`Select the product and plan for the application subscription`]: `Wybierz produkt i plan subskrypcji aplikacji`,
  [`Select the product definition file to import`]: `Wybierz plik definicji produktu do zaimportowania`,
  [`Select the product definition file to import from`]: `Wybierz plik definicji produktu, z którego ma zostać przeprowadzone importowanie.`,
  [`Select the product that will supersede the originally selected product`]: `Wybierz produkt, który zastąpi oryginalnie wybrany produkt`,
  [`Select the product that you want to add the API to`]: `Wybierz produkt, do którego ma zostać dodany interfejs API`,
  [`Select the registries that you want to use to control access to the APIs in this catalog`]: `Wybierz rejestry, które mają być używane do kontrolowania dostępu do interfejsów API w tym katalogu`,
  [`Select the required operations`]: `Wybierz wymagane operacje`,
  [`Select the severity level for log messages that relate to input data; the possible levels are error, warn, and info.`]: `Wybierz poziom istotności komunikatów dziennika powiązanych z danymi wejściowymi. Dostępne poziomy to błąd, ostrzeżenie i informacja.`,
  [`Select the target wsdl file to create from`]: `Wybierz docelowy plik WSDL, na podstawie którego zostanie utworzony interfejs.`,
  [`Select the target wsdl, xsd or zip file to create the API definition from`]: `Wybierz docelowy plik wsdl, xsd lub zip, z którego ma zostać utworzona definicja interfejsu API`,
  [`Select the target WSDL file to import`]: `Wybierz docelowy plik WSDL do zaimportowania`,
  [`Select the user registries for authenticating API Manager users, or create a new use registry.`]: `Wybierz rejestry użytkowników na potrzeby uwierzytelniania użytkowników programu API Manager lub utwórz nowy rejestr użytkowników.`,
  [`Select the user registries for authenticating users of API Manager, or create a new user registry.`]: `Wybierz rejestry użytkowników na potrzeby uwierzytelniania użytkowników programu API Manager lub utwórz nowy rejestr użytkowników.`,
  [`Select the user registries for authenticating users of Cloud Manager, or create a new user registry.`]: `Wybierz rejestry użytkowników na potrzeby uwierzytelniania użytkowników programu Cloud Manager lub utwórz nowy rejestr użytkowników.`,
  [`Select the user registries that are used to authenticate login to the Developer Portal`]: `Wybierz rejestry użytkowników, które są używane do uwierzytelniania logowania w portalu Developer Portal`,
  [`Select the user registry type`]: `Wybierz typ rejestru użytkowników`,
  [`Select where in the output source the policy should place the generated LTPA token.  In WSSec Header should be selected only if the content-type on the request is application/xml.`]: `Wybierz miejsce w źródle danych wyjściowych, gdzie strategia powinna umieścić wygenerowany token LTPA.  Nagłówek WSSec należy wybrać tylko wtedy, gdy typem treści w żądaniu jest application/xml.`,
  [`Select whether to remove the property completely, or to redact the field by using "*"s to block out the data.`]: `Wybierz, czy chcesz całkowicie usunąć właściwość, czy też zredagować pole, używając znaków „*”, aby zablokować dane.`,
  [`Selected product has been modified. Please refresh this page and try again.`]: `Wybrany produkt został zmodyfikowany. Odśwież tę stronę i spróbuj ponownie.`,
  [`Selecting operations from WSDL service definition`]: `Wybieranie operacji z definicji usługi WSDL`,
  [`Self Service Onboarding for Consumer organization`]: `Samodzielna rejestracja organizacji konsumentów`,
  [`Self service onboarding`]: `Samodzielna rejestracja`,
  [`Self service onboarding approval`]: `Zatwierdzanie samodzielnej rejestracji`,
  [`Send`]: `Wyślij`,
  [`Send Message`]: `Wysyłanie wiadomości`,
  [`Send Message to Owners`]: `Wysyłanie wiadomości do właścicieli`,
  [`Send To`]: `Wyślij do`,
  [`Send a registration link to a new user to register as owner.`]: `Wyślij odsyłacz rejestracyjny do nowego użytkownika, aby zarejestrował się jako właściciel.`,
  [`Send a test email`]: `Wysyłanie testowej wiadomości e-mail`,
  [`Send a test email to confirm that the email server is properly configured.`]: `Wyślij testową wiadomość e-mail, aby sprawdzić, czy serwer poczty elektronicznej został poprawnie skonfigurowany.`,
  [`Send a test signal to confirm the endpoint is properly configured.`]: `Wyślij sygnał testowy, aby potwierdzić, że punkt końcowy jest prawidłowo skonfigurowany.`,
  [`Send a test signal to confirm the endpoint is properly configured`]: `Wyślij sygnał testowy, aby potwierdzić, że punkt końcowy jest prawidłowo skonfigurowany`,
  [`Send an email message to Admin organization owner. Place a checkmark next to the owner's name`]: `Wyślij wiadomość e-mail do właściciela organizacji administracyjnej. Umieść znacznik wyboru obok nazwy właściciela`,
  [`Send an email message to an owner. Place a checkmark next to the owner's name`]: `Wyślij wiadomość e-mail do właściciela. Umieść znacznik wyboru obok nazwy właściciela`,
  [`Send chunked-encoded documents to the target server.`]: `Wyślij dokumenty zakodowane w porcjach do serwera docelowego.`,
  [`Send message`]: `Wyślij wiadomość`,
  [`Send message to owner`]: `Wysyłanie wiadomości do właściciela`,
  [`Send test email`]: `Wyślij testową wiadomość e-mail`,
  [`Send the activation link to a user to invite them to sign in or register.`]: `Wyślij odsyłacz aktywacyjny do użytkownika, aby zaprosić go do zalogowania się lub zarejestrowania.`,
  [`Send-only`]: `Tylko wyślij`,
  [`Sender`]: `Nadawca`,
  [`Sender & email server`]: `Nadawca i serwer poczty elektronicznej`,
  [`Sender & email server have been changed`]: `Zmieniono nadawcę i serwer poczty elektronicznej`,
  [`Sender Info`]: `Informacje o nadawcy`,
  [`Sent`]: `Wysłano`,
  [`Sent events: **{sent}**`]: `Zdarzenia wysłane: **{sent}**`,
  [`Serial`]: `Numer seryjny`,
  [`Serialize output`]: `Przekształć dane wyjściowe do postaci szeregowej`,
  [`Server connection`]: `Połączenie z serwerem`,
  [`Serve HTML to web browsers to enable GUI GraphQL client`]: `Udostępniaj HTML przeglądarkom WWW, aby umożliwić korzystanie z klienta interfejsu GUI GraphQL`,
  [`Serve HTML to web browsers to enable GUI GraphQL client.`]: `Udostępniaj HTML przeglądarkom WWW, aby umożliwić korzystanie z klienta interfejsu GUI GraphQL.`,
  [`Server Name Indication (SNI)`]: `Wskazanie nazwy serwera (SNI)`,
  [`Server URL`]: `Adres URL serwera`,
  [`Server returned an error in hash instead of token in OIDC redirect URL. Hash:`]: `Pod adresem URL przekierowania OIDC serwer zwrócił skrót z informacją o błędzie zamiast tokenu. Skrót:`,
  [`Service name`]: `Nazwa usługi`,
  [`Services`]: `Usługi`,
  [`Set`]: `Ustaw`,
  [`Set APIC_DESIGNER_CREDENTIALS OS environment variable for API Designer. For example on MAC, user can pass in the environment variable while launching API Designer.`]: `Ustaw zmienną środowiskową APIC_DESIGNER_CREDENTIALS OS dla produktu API Designer. Na przykład w systemie MAC użytkownik może przekazać zmienną środowiskową podczas uruchamiania komponentu API Designer.`,
  [`Set Migration Target`]: `Ustawianie elementu docelowego migracji`,
  [`Set migration target`]: `Ustaw cel migracji`,
  [`Set Variable`]: `Ustaw zmienną`,
  [`Set as default`]: `Ustaw jako domyślne`,
  [`Set default`]: `Ustaw domyślne`,
  [`Set rate and burst limits in an assembly`]: `Ustaw limity częstotliwości i wywołań w elemencie złożonym`,
  [`Set the timeout period for invitations`]: `Ustaw limit czasu zaproszeń`,
  [`Set the trust level for the profile`]: `Ustaw poziom zaufania dla profilu`,
  [`Set the value to the number of iterations of a circular schema definition that are allowed (default of 1, maximum of 5)`]: `Ustaw tę wartość na liczbę dozwolonych iteracji cyklicznych definicji schematu (wartość domyślna 1, wartość maksymalna 5)`,
  [`Set the visibility for a gateway service`]: `Ustaw widoczność usługi bramy`,
  [`Set the visibility for a portal service`]: `Ustaw widoczność usługi portalu`,
  [`Set the visibility for each service`]: `Ustaw widoczność każdej usługi.`,
  [`Set up time frame to get a new access token issued by the authentication server`]: `Skonfiguruj czas przeznaczony na uzyskanie nowego tokenu dostępu z serwera uwierzytelniania`,
  [`Set visibility`]: `Ustaw widoczność`,
  [`Set visibility of gateway`]: `Ustaw widoczność bramy`,
  [`Set time interval`]: `Ustaw przedział czasu`,
  [`Set, Add, or Clear a runtime variable.`]: `Ustaw, dodaj lub wyczyść wartość zmiennej wykonawczej.`,
  [`Set the timeout period for API key`]: `Ustaw limit czasu dla klucza API`,
  [`Sets the validity period in seconds for documents in the cache. Applies only if the Cache Type is set to "Time to Live". Enter a value in the range 5 - 31708800. The default value is 900.`]: `Ustawia okres ważności (w sekundach) dla dokumentów w pamięci podręcznej. Ma zastosowanie tylko wtedy, gdy typ pamięci podręcznej jest ustawiony na „Czas życia”. Wprowadź wartość z zakresu 5–31708800. Wartością domyślną jest 900.`,
  [`Setting a value for "assumedSize" cannot be combined with defining slicing argument which has a default value or cannot be combined with enabling "requireOneSlicingArgument".`]: `Nie można jednocześnie ustawić wartości "assumedSize" i zdefiniować argumentu przekrojów o wartości domyślnej ani włączyć "requireOneSlicingArgument".`,
  [`Setting this catalog as the default catalog will allow it to be accessed using a shorter URL, omitting the catalog name. You can only set one catalog as default.`]: `Ustawienie tego katalogu jako katalogu domyślnego pozwoli na dostęp do niego przy użyciu krótszego adresu URL, z pominięciem nazwy katalogu. Można ustawić tylko jeden katalog jako domyślny.`,
  [`Settings`]: `Ustawienia`,
  [`Setup`]: `Konfiguracja`,
  [`Severity level for input data log messages`]: `Poziom istotności komunikatów dziennika dla danych wejściowych`,
  [`Share the resource with a custom list of provider organizations`]: `Udostępniaj zasób organizacjom dostawców wymienionym na niestandardowej liście`,
  [`Share the resource with provider organizations`]: `Udostępniaj zasób organizacjom dostawców`,
  [`Share the API gateway with a custom list of provider organizations`]: `Współużytkuj bramę API z niestandardową listą organizacji dostawców`,
  [`Share the service with a custom list of provider organizations`]: `Udostępniaj usługę organizacjom dostawców wymienionym na niestandardowej liście`,
  [`Share the API gateway with provider organizations`]: `Współużytkuj bramę API z organizacjami dostawców`,
  [`Share the service with provider organizations`]: `Udostępniaj usługę organizacjom dostawców`,
  [`Share`]: `Udostępnij`,
  [`Shared`]: `Współużytkowane`,
  [`Shared by`]: `Udostępniane przez`,
  [`Short but descriptive name for this policy.`]: `Krótka, ale opisowa nazwa tej strategii.`,
  [`Show`]: `Pokaż`,
  [`Show / hide policy palette`]: `Pokaż/ukryj paletę strategii`,
  [`Show/hide`]: `Pokaż/ukryj`,
  [`Show/hide settings`]: `Pokaż/ukryj ustawienia`,
  [`Show and hide types and fields to include in the schema.`]: `Pokazuj lub ukrywaj typy i pola, które mają być uwzględnione w schemacie.`,
  [`Show added only`]: `Pokaż tylko dodane`,
  [`Show all`]: `Pokaż wszystko`,
  [`Show all UDP versions`]: `Pokaż wszystkie wersje UDP`,
  [`Show app identifier`]: `Pokaż identyfikator aplikacji`,
  [`Show breaking changes only`]: `Pokaż tylko ważne zmiany`,
  [`Show catalog identifier`]: `Pokaż identyfikator katalogu`,
  [`Show catches`]: `Pokaż elementy wychwycone`,
  [`Show in schema`]: `Pokaż w schemacie`,
  [`Show less`]: `Pokaż mniej`,
  [`Show modified only`]: `Pokaż tylko zmodyfikowane`,
  [`Show more`]: `Pokaż więcej`,
  [`Show new types, fields, directives or optional arguments. Items that are no longer supported by the backend will still be removed.`]: `Pokaż nowe typy, pola, dyrektywy lub argumenty opcjonalne. Elementy, które nie są już obsługiwane przez zaplecze, mimo to zostaną usunięte.`,
  [`Show removed only`]: `Pokaż tylko usunięte`,
  [`Show schema`]: `Pokaż schemat`,
  [`Show warnings only`]: `Pokaż tylko ostrzeżenia`,
  [`Shows history of keystore assigments except currently assigned keystore.`]: `Przedstawia historię przypisań do magazynów kluczy z wyjątkiem obecnie przypisanego magazynu.`,
  [`Sign Crypto Object`]: `Obiekt szyfrujący używany do podpisywania`,
  [`Sign in`]: `Zaloguj się`,
  [`Sign JWK variable name`]: `Nazwa zmiennej JWK używanej do podpisywania`,
  [`Sign Out`]: `Wyloguj`,
  [`Sign up`]: `Zarejestruj się`,
  [`sign-up`]: `sign-up`,
  [`Sign in using the {userRegistryType} User Registry`]: `Zaloguj się, korzystając z rejestru użytkowników {userRegistryType}`,
  [`Sign in with`]: `Zaloguj się za pomocą`,
  [`Sign out`]: `Wyloguj`,
  [`Sign up with`]: `Zarejestruj się na`,
  [`Signed token`]: `Podpisany token`,
  [`Since buffering has been set to off, it is required that the content and/or error-content be decreased from the payload to headers. Would you like to continue with the required change or cancel your recent change?`]: `Wyłączono buforowanie, więc jest konieczne zredukowanie treści lub treści błędów z ładunku do nagłówków. Czy kontynuować z tą wymaganą zmianą, czy anulować ostatnio wprowadzoną zmianę?`,
  [`Since content and/or error-content has been set to payload, it is required that buffering be set to on. Would you like to continue with the required change or cancel your recent change?`]: `Treść lub treść błędów ustawiono na ładunek, więc jest konieczne włączenie buforowania. Czy kontynuować z tą wymaganą zmianą, czy anulować ostatnio wprowadzoną zmianę?`,
  [`Sized fields`]: `Pola wymiarowane`,
  [`Slack`]: `Slack`,
  [`Slicing arguments`]: `Argumenty przekrojów`,
  [`Some inputs are arrays, or contained within arrays. To iterate over the contents, choose the iterate option and choose the input over which to iterate.`]: `Niektóre dane wejściowe są tablicami lub są zawarte w tablicach. Aby przeprowadzić iterację treści, wybierz opcję iteracji i wybierz dane wejściowe, które mają być objęte iteracją.`,
  [`Something went wrong.`]: `Wystąpił problem.`,
  [`Something went wrong...`]: `Wystąpił problem...`,
  [`Source`]: `Źródło`,
  [`Source schema`]: `Schemat źródłowy`,
  [`Space`]: `Obszar`,
  [`Space is not allowed in scope name`]: `Nazwa zasięgu nie może zawierać spacji`,
  [`Space details`]: `Szczegóły obszaru`,
  [`Space Enabled`]: `Włączone obszary`,
  [`Space Name`]: `Nazwa obszaru`,
  [`Space Summary`]: `Podsumowanie obszaru`,
  [`Space Title`]: `Tytuł obszaru`,
  [`Space owner`]: `Właściciel obszaru`,
  [`Space {name} created`]: `Obszar {name} został utworzony.`,
  [`Spaces`]: `Obszary`,
  [`Spaces are enabled`]: `Obszary są włączone`,
  [`Spaces are not enabled`]: `Obszary nie są włączone`,
  [`Spaces cannot be enabled for the sandbox catalog.`]: `Nie można włączyć obszarów dla katalogu środowiska testowego.`,
  [`Spaces cannot be enabled.`]: `Nie można włączyć obszarów.`,
  [`Spaces dropdown`]: `Lista rozwijana przestrzeni`,
  [`Spaces has been enabled`]: `Obszary zostały włączone.`,
  [`Spaces have been enabled.`]: `Obszary zostały włączone.`,
  [`Spaces per page`]: `Liczba obszarów na stronie`,
  [`Spanish`]: `hiszpański`,
  [`Special characters are not accepted`]: `Znaki specjalne nie są akceptowane`,
  [`Specifies a URL or string that represents a named context from which to retrieve the serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing literal or default properties.`]: `Określa adres URL lub łańcuch reprezentujący nazwany kontekst, z którego mają zostać pobrane przekształcone do postaci szeregowej właściwości XML lub JSON, które scalają się w obiekt dynamiczny. Te właściwości mają pierwszeństwo przed wszystkimi istniejącymi literałami lub właściwościami domyślnymi.`,
  [`Specifies a literal string as serialized XML or JSON properties that are merging into the dynamic object. These properties take precedence over any existing default properties.`]: `Określa literał łańcuchowy jako przekształcone do postaci szeregowej właściwości XML lub JSON, które scalają się w obiekt dynamiczny. Te właściwości mają pierwszeństwo przed wszystkimi istniejącymi właściwościami domyślnymi.`,
  [`Specifies an existing valid object from which to retrieve default property values for the dynamic object. Warning: If you change from the default string, the parse setting object specified must be available on the DataPower appliance.`]: `Określa istniejący poprawny obiekt, z którego mają zostać pobrane domyślne wartości właściwości dla obiektu dynamicznego. Ostrzeżenie: W przypadku zmiany z domyślnego łańcucha obiekt konfiguracji analizy musi być dostępny na urządzeniu DataPower.`,
  [`Specifies the data source that contains the content to redact or remove. If the root is not specified, the action is applied to the entire API context. You can use any supported JSONata path expression.\n\nRedaction of request and response data may be performed with a root of "message.body". If the assembly redact action is used in an assembly after an assembly log action that specifies gather-only mode, the root can specify "log.request_body" for the logged request payload or "log.response_body" for the logged response payload.`]: `Określa źródło danych zawierające treść do zredagowania lub usunięcia. Jeśli nie zostanie podany element główny, działanie zostanie zastosowane względem całego kontekstu interfejsu API. Można korzystać ze wszystkich obsługiwanych wyrażeń ścieżek JSONata.\n\nRedakcja danych żądania lub odpowiedzi może zostać przeprowadzona w elemencie głównym „message.body”. Jeśli działanie redakcji elementu złożonego zostanie zastosowane względem elementu złożonego po wykonaniu działania rejestrowania elementu złożonego określającego tryb gather-only (tylko gromadzenie), elementem głównym może być „log.request_body” dla zarejestrowanego ładunku żądania lub „log.response_body” dla zarejestrowanego ładunku odpowiedzi.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the input to the action. The default variable name is "message".`]: `Określa nazwę zmiennej w kontekście interfejsu API. Zawartość pola „treść” zmiennej jest wartością wejściową dla działania. Domyślna nazwa zmiennej to „komunikat”.`,
  [`Specifies the name of a variable in the API context. The content of the "body" field of the variable is the output of the parse. The parse metrics of the parsed document can be stored in a different part of the message. The default variable name is whatever the Input name is, so by default the Input message will be overwritten with the Output message.`]: `Określa nazwę zmiennej w kontekście interfejsu API. Zawartość pola „treść” zmiennej stanowi dane wyjściowe analizy. Parametry analizowania przeanalizowanego dokumentu mogą być przechowywane w innej części komunikatu. Domyślna nazwa zmiennej jest taka sama, jak nazwa danych wejściowych, więc domyślnie komunikat wejściowy zostanie nadpisany przez komunikat wyjściowy.`,
  [`Specifies whether and where to obtain custom metadata for the access token.`]: `Wskazuje, czy i gdzie należy uzyskać niestandardowe metadane dla tokenu dostępu.`,
  [`Specifies whether to attempt a parse with the specified content-type. This is only applicable if the expected content-type is either json or xml. With this option enabled, a parse action will result in failure if content-type does not match the parse-settings (i.e Content-type:application/json and parse-settings is configured for xml). If the parse-setting is configured to detect input content, it will be forced to the specified content-type.`]: `Określa, czy podejmować próbę analizy dla określonego typu treści. Ma zastosowanie tylko wtedy, gdy oczekiwanym typem treści jest json lub xml. Gdy ta opcja jest włączona, działanie analizy spowoduje niepowodzenie, gdy typ treści nie jest zgodny z ustawieniami analizy (np. Content-type:application/json, a ustawienia analizy są skonfigurowane dla xml). Jeśli ustawienia analizy są skonfigurowane do wykrywania treści wejściowej, zostanie wymuszony określony typ treści.`,
  [`Specifies whether to serialize the XSLT output tree into binary data after the transformation.`]: `Określa, czy przekształcić drzewo wyjściowe XSLT w dane binarne po transformacji.`,
  [`Specify default gateways`]: `Określ bramy domyślne`,
  [`Specify email`]: `Podawanie adresu e-mail`,
  [`Specify an OpenAPI (Swagger) schema definition with which to validate the payload ("request", "response" or "#/definitions/XXXX").`]: `Określ definicję schematu OpenAPI (Swagger), za pomocą której ma zostać sprawdzona poprawność ładunku ("request", "response" lub "#/definitions/XXXX").`,
  [`Specify owner of the consumer organization`]: `Podaj właściciela organizacji konsumenta`,
  [`Specify owner of the provider organization`]: `Podaj właściciela organizacji dostawcy`,
  [`Specify settings for the third party OAuth provider.`]: `Określ ustawienia zewnętrznego dostawcy OAuth.`,
  [`Specify the MIME types that your API consumes and produces`]: `Podaj typy MIME używane i generowane przez interfejs API`,
  [`Specify the catalog owner; the owner has all catalog permissions`]: `Podaj właściciela katalogu. Właściciel dysponuje wszystkimi uprawnieniami do katalogu`,
  [`Specify the consumer organizations or communities of developers that will be able to see the product in the developer portal`]: `Określ organizacje konsumentów lub społeczności programistów, które będą widzieć produkt w portalu Developer Portal`,
  [`Specify the consumer organizations or communities of developers that will be able to subscribe to use the APIS in the product`]: `Określ organizacje konsumentów lub społeczności programistów, które będą mogły subskrybować korzystanie z interfejsów API w produkcie`,
  [`Specify the base URL of the API invocation endpoint and the domains handled by the gateway.`]: `Podaj podstawowy adres URL punktu końcowego wywołania interfejsu API i domeny obsługiwane przez bramę.`,
  [`Specify the details of the catalog that you want to publish to`]: `Określ szczegóły katalogu, który ma być miejscem docelowym publikowania`,
  [`Specify the default set of gateways that will secure the APIs in a new catalog. [Learn more]({link})`]: `Wybierz domyślny zestaw bram, które będą zabezpieczać interfejsy API w nowym katalogu. [Więcej informacji]({link})`,
  [`Specify the endpoints to which this API is to be published, and whether you want to limit the rate of API calls`]: `Podaj punkty końcowe, w których ten interfejs API ma być publikowany, oraz określ, czy ma być ograniczane tempo wywołań funkcji API.`,
  [`Specify the new owner`]: `Określanie nowego właściciela`,
  [`Specify the owner`]: `Określanie właściciela`,
  [`Specify the owner and title`]: `Określanie właściciela i tytułu`,
  [`Specify the properties to redact or remove.`]: `Określ właściwości w celu zredagowania lub usunięcia.`,
  [`Specify the publish and rate limiting requirements for this API`]: `Określ wymagania dotyczące publikowania i limitu częstotliwości dla tego interfejsu API`,
  [`Specify the scope check endpoint where additional scope verification is performed in addition to the defined scopes.`]: `Określ punkt końcowy sprawdzania zasięgu, w którym oprócz zdefiniowanych zasięgów przeprowadzana jest dodatkowa weryfikacja zasięgu.`,
  [`Specify the security settings for the imported API definition`]: `Określanie ustawień zabezpieczeń dla zaimportowanej definicji interfejsu API`,
  [`Specify the security settings for your API definition`]: `Określanie ustawień zabezpieczeń dla definicji interfejsu API`,
  [`Specify the space owner; the owner has all space permissions`]: `Podaj właściciela obszaru. Właściciel dysponuje wszystkimi uprawnieniami do obszaru`,
  [`Specify the target SOAP service and the API summary details; if required, you can further configure the API definition after you create it`]: `Podaj docelową usługę SOAP i szczegóły podsumowania interfejsu API. Jeśli jest to wymagane, można skonfigurować definicję interfejsu API po jej utworzeniu.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account`]: `Podaj limit czasu, po upływie którego wiadomości e-mail z zaproszeniem tracą ważność, jeśli użytkownik nie aktywuje swojego konta.`,
  [`Specify the timeout period after which invitation emails expire if the user does not activate their account.`]: `Podaj limit czasu, po upływie którego wiadomości e-mail z zaproszeniem tracą ważność, jeśli użytkownik nie aktywuje swojego konta.`,
  [`Specify the type of gateway, a title, and a summary that describes the gateway.`]: `Określ typ bramy, tytuł i podsumowanie opisujące bramę.`,
  [`Specify the visibility of the API gateway to API Connect provider organization.`]: `Określ widoczność bramy interfejsu API dla organizacji dostawcy API Connect.`,
  [`Specify the way you want to validate the message.`]: `Podaj sposób, w jaki ma być sprawdzana poprawność komunikatu.`,
  [`Specify the URL of the gateway management endpoint and the certificate for the domain of the endpoint.`]: `Określ adres URL punktu końcowego zarządzania bramą i certyfikat dla domeny punktu końcowego.`,
  [`Specify what content to log in the event of an error.`]: `Określ, jaka treść ma być rejestrowana w przypadku wystąpienia błędu.`,
  [`Specify what content to log.`]: `Określ, jaka treść ma być rejestrowana.`,
  [`Specifying the publish target details`]: `Określanie szczegółów miejsca docelowego publikowania`,
  [`Specifically allow xsi:type=SOAP-ENC:Array rule`]: `Wyraźnie zezwól na regułę xsi:type=SOAP-ENC:Array`,
  [`Stage`]: `Przemieszczanie`,
  [`Stage API`]: `Przemieszczanie interfejsu API`,
  [`Stage product`]: `Przemieszczanie produktu`,
  [`Stage to`]: `Miejsce docelowe przemieszczania`,
  [`Staged`]: `Przemieszczane`,
  [`Stage|permission`]: `Przemieszczanie`,
  [`Standard OIDC`]: `Standardowy interfejs OIDC`,
  [`Static DNS`]: `Statyczny DNS`,
  [`Static group`]: `Grupa statyczna`,
  [`Status`]: `Status`,
  [`Status code`]: `Kod statusu`,
  [`Step 1`]: `Krok 1`,
  [`Step 1: Download`]: `Krok 1: Pobieranie`,
  [`Step 1: Download toolkit`]: `Krok 1: Pobierz bibliotekę narzędziową`,
  [`Step 1: Upload private key`]: `Krok 1: Przesyłanie klucza prywatnego`,
  [`Step 2`]: `Krok 2`,
  [`Step 2: Download credentials`]: `Krok 2: Pobieranie referencji`,
  [`Step 2: Download toolkit credentials`]: `Krok 2: Pobierz referencje biblioteki narzędziowej`,
  [`Step 2: Install`]: `Krok 2: Instalowanie`,
  [`Step 2: Upload public key`]: `Krok 2: Przesyłanie klucza publicznego`,
  [`Step 3`]: `Krok 3`,
  [`Step 3: Configure`]: `Krok 3: Konfigurowanie`,
  [`Step 3: Run command`]: `Krok 3: Uruchom komendę`,
  [`Step 3: Run commands`]: `Krok 3: Uruchamianie komend`,
  [`Step 4: Register`]: `Krok 4: Rejestrowanie`,
  [`Stop`]: `Zatrzymaj`,
  [`Stop after:`]: `Zatrzymaj po:`,
  [`Stop all the retries`]: `Zatrzymaj wszystkie ponowienia`,
  [`Stop Connection`]: `Zatrzymaj połączenie`,
  [`Stop on error`]: `Zatrzymaj w razie błędu`,
  [`Stop running this API and remove it from the Sandbox catalog.`]: `Zatrzymaj działanie tego interfejsu API i usuń go z katalogu Środowisko testowe.`,
  [`Stopped`]: `Zatrzymany`,
  [`Stopping Published API`]: `Zatrzymanie publikowania interfejsu API`,
  [`Strict`]: `Ścisłe`,
  [`Strict SOAP envelope version`]: `Ścisła wersja koperty SOAP`,
  [`String Badgerfish`]: `Łańcuch w notacji Badgerfish`,
  [`String`]: `Łańcuch`,
  [`String included in organization segment of URL for API calls. Use lower-case alphabetics, numerals, and hyphens.`]: `Łańcuch zawarty w segmencie organizacji adresu URL wywołań interfejsu API. Użyj małych liter alfabetu, cyfr i łączników.`,
  [`Stripe`]: `Stripe`,
  [`Stripe integration`]: `Integracja produktu Stripe`,
  [`Streaming rule`]: `Reguła strumieniowania`,
  [`Subject`]: `Temat`,
  [`Subject Claim`]: `Roszczenie podmiotu`,
  [`Subject Origin`]: `Pochodzenie podmiotu`,
  [`Subject common name`]: `Nazwa zwykła podmiotu`,
  [`Subject|certificate`]: `Temat`,
  [`Submit`]: `Wyślij`,
  [`Submitted`]: `Wprowadzone`,
  [`Subscribability`]: `Możliwość subskrypcji`,
  [`Subscribability must be disabled if visibility is disabled`]: `Gdy widoczność jest wyłączona, możliwość subskrypcji również musi być wyłączona`,
  [`Subscribability options`]: `Opcje możliwości subskrypcji`,
  [`Subscribable by`]: `Możliwe do zasubskrybowania przez`,
  [`Subscribe`]: `Subskrybuj`,
  [`Subscribe Financial Management App`]: `Subskrybowanie aplikacji do zarządzania finansami`,
  [`Subscribe TODO App`]: `Subskrypcja aplikacji TODO`,
  [`Subscription`]: `Subskrypcja`,
  [`Subscription approval task for subscription of`]: `Zadanie zatwierdzenia subskrypcji`,
  [`Subscription created`]: `Utworzono subskrypcję`,
  [`Subscription has been added`]: `Dodano subskrypcję`,
  [`Subscription has not been added`]: `Subskrypcja nie została dodana`,
  [`Subscription pending approval`]: `Subskrypcja oczekuje na zatwierdzenie`,
  [`Subscription type has been removed since GraphQL Subscriptions are not supported.`]: `Typ subskrypcji został usunięty, ponieważ subskrypcje GraphQL nie są obsługiwane.`,
  [`Subscription-Approval`]: `Zatwierdzanie subskrypcji`,
  [`Subscriptions`]: `Subskrypcje`,
  [`Success`]: `Powodzenie`,
  [`Suffix`]: `Przyrostek`,
  [`Suggestion`]: `Sugestia`,
  [`Supersede`]: `Zastąp nowszą wersją`,
  [`Supersede Product`]: `Zastępowanie produktu nowszą wersją`,
  [`Supersede product`]: `Zastąp produkt nowszą wersją`,
  [`Supersede plans`]: `Przechodzenie na nowszy plan`,
  [`Supersede {product1} with {product2}`]: `Zastąp produkt {product1} produktem {product2}`,
  [`Supersede|permission`]: `Zastępowanie nowszą wersją`,
  [`Supersede|supersede with`]: `Zastąp nowszą wersją`,
  [`Support`]: `Wsparcie`,
  [`support default introspection`]: `obsługuj introspekcję domyślną`,
  [`Support Server Name Indication (SNI)`]: `Wskazanie nazwy serwera wsparcia (SNI)`,
  [`Support hybrid response types`]: `Typy odpowiedzi hybrydowych wsparcia`,
  [`Support standard introspection`]: `Obsługuj introspekcję domyślną`,
  [`Supported OAuth components`]: `Obsługiwane komponenty OAuth`,
  [`Supported client types`]: `Obsługiwane typy klientów`,
  [`Supported client types can not be empty.`]: `Obsługiwany typ klienta nie może być pusty.`,
  [`Supported grant type can not be empty.`]: `Obsługiwany typ nadania nie może być pusty.`,
  [`Supported grant types`]: `Obsługiwane typy nadania`,
  [`Suspend`]: `Zawieś`,
  [`Suspend API`]: `Zawieszanie interfejsu API`,
  [`Suspend Application`]: `Zawieszanie aplikacji`,
  [`Switch`]: `Przełącznik`,
  [`Switch account`]: `Przełącz konto`,
  [`Switch cloud connection`]: `Przełącz połączenie z chmurą`,
  [`Switch directory`]: `Przełącz katalog`,
  [`TARGET`]: `CEL`,
  [`Title`]: `Tytuł`,
  [`TlS`]: `TlS`,
  [`TLS`]: `TLS`,
  [`TLS Client`]: `Klient TLS`,
  [`TLS Client Profile`]: `Profil klienta TLS`,
  [`TLS Client Profile has been configured.`]: `Skonfigurowano profil klienta TLS`,
  [`TLS client profile is required when endpoint starts with https://`]: `Profil klienta TLS jest wymagany, gdy punkt końcowy rozpoczyna się od https://`,
  [`TLS Client Profiles`]: `Profile klienta TLS`,
  [`TLS client profile has been configured.`]: `Skonfigurowano profil klienta TLS.`,
  [`TLS client profiles`]: `Profile klienta TLS`,
  [`TLS Profile`]: `Profil TLS`,
  [`TLS SERVER PROFILE`]: `PROFIL SERWERA TLS`,
  [`TLS server profile`]: `Profil serwera TLS`,
  [`TLS version 1.0`]: `TLS 1.0`,
  [`TLS version 1.1`]: `TLS 1.1`,
  [`TLS version 1.2`]: `TLS 1.2`,
  [`TLS version 1.3`]: `TLS 1.3`,
  [`TLS client profile`]: `Profil klienta TLS`,
  [`TLS client profiles are managed in each space when spaces are enabled. Please go to the space settings to manage TLS client profiles.`]: `Gdy obszary są włączone, profilami klienta TLS zarządza się w poszczególnych obszarach. Przejdź do ustawień obszarów, aby zarządzać profilami klienta TLS.`,
  [`TLS profile`]: `Profil TLS`,
  [`TLS profile is not visible or not available.`]: `Profil TLS jest niewidoczny lub niedostępny.`,
  [`TLS profile to use for the secure transmission of data to the Authentication URL.`]: `Profil protokołu TLS, który ma być używany do bezpiecznej transmisji danych do adresu URL uwierzytelniania.`,
  [`Tabs for code language options`]: `Karty z opcjami języka kodu`,
  [`Truststore`]: `Magazyn zaufanych certyfikatów`,
  [`truststore`]: `magazyn zaufanych certyfikatów`,
  [`Type`]: `Typ`,
  [`Type name`]: `Wpisz nazwę`,
  [`Type/Field`]: `Typ/pole`,
  [`Type weight`]: `Waga typu`,
  [`Types per page:`]: `Liczba typów na stronie:`,
  [`Tags`]: `Znaczniki`,
  [`Tags and external documentation`]: `Znaczniki i dokumentacja zewnętrzna`,
  [`Take offline`]: `Przełącz w tryb bez połączenia`,
  [`Target`]: `Cel`,
  [`Target API (URL)`]: `Docelowy interfejs API (adres URL)`,
  [`Target SOAP service`]: `Docelowa usługa SOAP`,
  [`Target Services`]: `Usługi docelowe`,
  [`Target service URL`]: `Adres URL usługi docelowej`,
  [`Target services`]: `Usługi docelowe`,
  [`Target URL`]: `Docelowy adres URL`,
  [`Target application`]: `Docelowa aplikacja`,
  [`Target catalog`]: `Docelowy katalog`,
  [`Target gateway service`]: `Docelowa usługa bramy`,
  [`Target gateway services`]: `Usługi bramy docelowej`,
  [`Target plan`]: `Docelowy plan`,
  [`Target product`]: `Docelowy produkt`,
  [`Target product rate limit`]: `Limit częstotliwości produktu docelowego`,
  [`Target product subscribability`]: `Możliwość subskrypcji produktu docelowego`,
  [`Target product visibility`]: `Widoczność produktu docelowego`,
  [`Target schema`]: `Schemat docelowy`,
  [`Target services have been updated.`]: `Usługi docelowe zostały zaktualizowane.`,
  [`Task`]: `Zadanie`,
  [`Task has been updated`]: `Zaktualizowano zadanie`,
  [`Task self approval`]: `Samozatwierdzanie zadań`,
  [`Task self-approval`]: `Samozatwierdzenie zadania`,
  [`task-consumer-onboard-request`]: `task-consumer-onboard-request`,
  [`task-product-lifecycle-approved`]: `task-product-lifecycle-approved`,
  [`task-product-lifecycle-cancelled`]: `task-product-lifecycle-cancelled`,
  [`task-product-lifecycle-denied`]: `task-product-lifecycle-denied`,
  [`task-product-lifecycle-pending`]: `task-product-lifecycle-pending`,
  [`task-product-lifecycle-request`]: `task-product-lifecycle-request`,
  [`task-subscription-approved`]: `task-subscription-approved`,
  [`task-subscription-cancelled`]: `task-subscription-cancelled`,
  [`task-subscription-denied`]: `task-subscription-denied`,
  [`task-subscription-pending`]: `task-subscription-pending`,
  [`task-subscription-request`]: `task-subscription-request`,
  [`Tasks`]: `Zadania`,
  [`Tasks for creating new provider organizations`]: `Zadania związane z tworzeniem nowych organizacji dostawców`,
  [`TCP`]: `protokół TCP`,
  [`Template`]: `Szablon`,
  [`Template: {title}`]: `Szablon: {title}`,
  [`Template: account approved`]: `Szablon: konto zatwierdzone`,
  [`Template: account denied`]: `Szablon: odmowa konta`,
  [`Template: account pending approval`]: `Szablon: konto oczekujące na zatwierdzenie`,
  [`Template: app lifecycle approved`]: `Szablon: cykl życia aplikacji zatwierdzony`,
  [`Template: app lifecycle denied`]: `Szablon: odmowa cyklu życia aplikacji`,
  [`Template: app lifecycle pending`]: `Szablon: oczekujący cykl życia aplikacji`,
  [`Template: app reinstated`]: `Szablon: aplikacja przywrócona`,
  [`Template: app suspended`]: `Szablon: aplikacja zawieszona`,
  [`Template: app lifecycle cancelled`]: `Szablon: cykl życia aplikacji anulowany`,
  [`Template: app lifecycle request`]: `Szablon: żądanie cyklu życia aplikacji`,
  [`Template: invitation`]: `Szablon: zaproszenie`,
  [`Template: mail server test connection`]: `Szablon: test połączenia z serwerem poczty elektronicznej`,
  [`Template: member invitation`]: `Szablon: zaproszenie członka`,
  [`Template: password changed`]: `Szablon: zmieniono hasło`,
  [`Template: password reset`]: `Szablon: resetowanie hasła`,
  [`Template: sign up`]: `Szablon: rejestracja`,
  [`Template: task consumer onboard request`]: `Szablon: zadanie – żądanie wprowadzenia konsumenta`,
  [`Template: task product lifecycle approved`]: `Szablon: zadanie – zatwierdzenie cyklu życia produktu`,
  [`Template: task product lifecycle cancelled`]: `Szablon: zadanie – anulowanie cyklu życia produktu`,
  [`Template: task product lifecycle denied`]: `Szablon: zadanie – odmowa cyklu życia produktu`,
  [`Template: task product lifecycle pending`]: `Szablon: zadanie – oczekujący cykl życia produktu`,
  [`Template: task product lifecycle request`]: `Szablon: zadanie – żądanie cyklu życia produktu`,
  [`Template: task subscription cancelled`]: `Szablon: zadanie – anulowanie subskrypcji`,
  [`Template: task subscription request`]: `Szablon: zadanie – żądanie subskrypcji`,
  [`Template: task subscription approved`]: `Szablon: zadanie – zatwierdzenie subskrypcji`,
  [`Template: task subscription denied`]: `Szablon: zadanie – odmowa subskrypcji`,
  [`Template: task subscription pending`]: `Szablon: zadanie – oczekująca subskrypcja`,
  [`Temporarily disable subscribability`]: `Tymczasowo wyłącz możliwość subskrybowania`,
  [`Temporarily disable visibility`]: `Tymczasowo wyłącz widoczność`,
  [`Terms of service`]: `Warunki korzystania z usługi`,
  [`Test`]: `Test`,
  [`Test API`]: `Testuj interfejs API`,
  [`Test APIs`]: `Testuj interfejsy API`,
  [`Test Bind & Get Base DN`]: `Testuj powiązanie i pobierz podstawową nazwę wyróżniającą`,
  [`Test configuration`]: `Testuj konfigurację`,
  [`Test connection`]: `Test połączenia`,
  [`Test connection failed.`]: `Test połączenia zakończył się niepowodzeniem.`,
  [`Test and production endpoints`]: `Punkty końcowe testowania i produkcji`,
  [`Test and monitor response error: redirect_uri prop is required, got {redirectUri}`]: `Błędna odpowiedź z testowania i monitorowania: właściwość redirect_uri jest wymagana, a odebrano {redirectUri}`,
  [`Test application`]: `Testuj aplikację`,
  [`Test consumer org`]: `Testuj organizację konsumenta`,
  [`Test email`]: `Testuj pocztę`,
  [`Test endpoints`]: `Punkty końcowe testowania`,
  [`Test message from IBM API Connect`]: `Wiadomość testowa z produktu IBM API Connect`,
  [`Testable`]: `Możliwe do testowania`,
  [`TEXT`]: `TEKST`,
  [`The "Definition" property must have a valid value.`]: `Właściwość "Definicja" musi mieć poprawną wartość.`,
  [`The "Definition" property must have a value of "request", "response", or start with "#/definitions/".`]: `Właściwość "Definicja" musi mieć wartość "request", "response" lub zaczynać się od "#/definitions/".`,
  [`The API Connect CLI is offered as an IBM Cloud CLI plugin. To get started:`]: `Interfejs CLI produktu API Connect jest oferowany jako wtyczka IBM Cloud CLI. Aby rozpocząć:`,
  [`The API has unsaved changes.`]: `Interfejs API ma niezapisane zmiany.`,
  [`The API has been fully designed and passed an internal milestone but has not yet implemented.`]: `Interfejs API został w pełni zaprojektowany i osiągnął wewnętrzny kamień milowy, ale nie został jeszcze zaimplementowany.`,
  [`The API is in the implementation phase.`]: `Interfejs API znajduje się w fazie implementacji.`,
  [`The API is in the early conceptual phase and is neither fully designed or implemented.`]: `Interfejs API znajduje się we wczesnej fazie koncepcyjnej i nie jest w pełni zaprojektowany ani zaimplementowany.`,
  [`The API may have unpublished changes. Would you like to republish the product?`]: `Interfejs API może mieć nieopublikowane zmiany. Czy chcesz ponownie opublikować produkt?`,
  [`The DNS scheme determines the composition of the endpoint URLs for inbound API calls to the gateway service and for accessing the developer portal.`]: `Schemat DNS określa budowę adresów URL punktów końcowych na potrzeby przychodzących wywołań interfejsu API do usługi bramy oraz do uzyskiwania dostępu do portalu Developer Portal.`,
  [`The GatewayScript source code to execute.`]: `Kod źródłowy GatewayScript do wykonania.`,
  [`The GraphQL schema URL with which a GraphQL payload must be validated.`]: `Adres URL schematu GraphQL, względem którego ma być sprawdzana poprawność ładunku GraphQL.`,
  [`The HTTP method to use for the invocation. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Metoda HTTP, która ma być używana na potrzeby wywołania. Jeśli zostanie ona pominięta lub ustawiona na wartość „Zachowaj”, wówczas zostanie użyta metoda z żądania przychodzącego.`,
  [`The HTTP method to use to replace the current HTTP method. If omitted or set to "Keep", then the method from the incoming request will be used.`]: `Metoda HTTP, która ma być używana do zastępowania bieżącej metody HTTP. Jeśli zostanie ona pominięta lub ustawiona na wartość „Zachowaj”, wówczas zostanie użyta metoda z żądania przychodzącego.`,
  [`The JavaScript source code to execute.`]: `Kod źródłowy JavaScript do wykonania.`,
  [`The Lightweight Directory Access Protocol (LDAP) is an internet protocol for accessing and maintaining distributed directory information services over a network. If you have an enterprise LDAP service enabled, configure it as a resource to provide user authentication.`]: `Protokół LDAP (Lightweight Directory Access Protocol) jest protokołem internetowym używanym do uzyskiwania dostępu do rozproszonych usług katalogowych za pośrednictwem sieci oraz do zarządzania nimi. Jeśli korporacyjna usługa LDAP jest włączona, należy ją skonfigurować jako zasób, aby umożliwić uwierzytelnianie użytkowników.`,
  [`The Proxy URL to be used.`]: `Adres URL proxy, który ma być używany.`,
  [`The SSL Profile to use for the secure transmission of data.`]: `Profil SSL, który ma być używany do bezpiecznej transmisji danych.`,
  [`The TLS Profile to use for the secure transmission of data.`]: `Profil protokołu TLS, który ma być używany do bezpiecznej transmisji danych.`,
  [`The URL of the JSON schema for validating a JSON payload.`]: `Adres URL schematu JSON na potrzeby sprawdzania poprawności ładunku JSON.`,
  [`The URL of the XML schema for validating an XML payload.`]: `Adres URL schematu XML na potrzeby sprawdzania poprawności ładunku XML.`,
  [`The URL to be invoked.`]: `Adres URL, który ma zostać wywołany.`,
  [`The WSDL schema URL with which a SOAP payload must be validated.`]: `Adres URL schematu WSDL, przy użyciu którego musi zostać sprawdzona poprawność ładunku SOAP.`,
  [`The XML element name to be used for JSON array elements.`]: `Nazwa elementu XML na potrzeby elementów tablicy JSON.`,
  [`The XSLT source to execute.`]: `Źródło XSLT do wykonania.`,
  [`The activation link can be sent to a user to enable them to activate their account`]: `Odsyłacz aktywacyjny można wysłać do użytkownika, aby umożliwić mu aktywowanie konta`,
  [`The analytics service collects API events from the gateway service. Each gateway has an associated analytics service. An API event with a timestamp is logged whenever an API operation is invoked.`]: `Usługa analizy danych gromadzi zdarzenia interfejsów API z usługi bramy. Z każdą bramą może być powiązana usługa analizy danych. Przy każdym wywołaniu operacji interfejsu API rejestrowane jest zdarzenie interfejsu API ze znacznikiem czasu.`,
  [`The analytics service collects API events from the gateway service. Each gateway service can have an associated analytics service.`]: `Usługa analizy danych gromadzi zdarzenia interfejsów API z usługi bramy. Z każdą usługą bramy może być powiązana usługa analizy danych.`,
  [`The authorization URL is where the client application obtains authorization grant. The token URL is where the client application exchanges an authorization grant for an access token.  The introspection URL is where the API gateway validates the access tokens that are issued by the third party provider.`]: `Adres URL autoryzacji to miejsce, w którym aplikacji klienckiej zostaje przyznana autoryzacja. Adres URL tokenu to miejsce, w którym aplikacja kliencka wymienia przyznaną autoryzację na token dostępu.  Adres URL introspekcji to miejsce, w którym brama interfejsu API sprawdza poprawność tokenów dostępu wystawionych przez zewnętrznego dostawcę.`,
  [`The base path is the initial URL segment of the API and does not include the host name or any additional segments for paths or operations`]: `Ścieżka podstawowa to początkowy segment adresu URL interfejsu API. Nie zawiera nazwy hosta ani żadnych dodatkowych segmentów związanych ze ścieżkami lub operacjami.`,
  [`The cache type determines whether to cache documents, honoring or overriding the HTTP Cache Control directives received from the response of the Server. This property takes effect only when a response is received from the Server, otherwise, the policy always returns the non-expired response that was previously saved in cache.`]: `Typ pamięci podręcznej określa, czy należy buforować dokumenty, uwzględniając lub nadpisując dyrektywy sterowania pamięcią podręczną HTTP odebrane z odpowiedzi serwera. Ta właściwość ma zastosowanie tylko wtedy, gdy odpowiedź zostanie odebrana z serwera. W przeciwnym razie strategia zawsze zwraca niewygasłą odpowiedź, która została poprzednio zapisana w pamięci podręcznej.`,
  [`The Certificate Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Usługa Certificate Manager musi zarządzać certyfikatami dla domeny punktu końcowego zarządzania bramą i domenami, które są obsługiwane przez bramę. Ponadto należy za pomocą systemu IBM Cloud IAM upoważnić usługę API Connect Reserved Instance do dostępu do usługi Certificate Manager.`,
  [`The certificate with which to decrypt the buffer`]: `Certyfikat używany do deszyfrowania buforu`,
  [`The certificate with which to encrypt the buffer`]: `Certyfikat używany do szyfrowania buforu`,
  [`The change is critical`]: `Zmiana jest krytyczna`,
  [`The client applications can request only the scopes or a subset of the scopes that you define here. The scopes are included in the access tokens that are generated from the provider. When an OAuth protected API is invoked, the gateway checks the scopes carried in the access tokens against the list of allowed scopes in the security definition to determine whether to grant access. In addition, you can enforce advanced scope check. The advanced scope check URLs are invoked after application authentication or after user authentication based on which URLs are configured. The final scope permission that is granted by the access token is the result of all scope checks.`]: `Aplikacje klienckie mogą żądać wyłącznie tych zasięgów lub podzbioru zasięgów, które zostały zdefiniowane w tym miejscu. Zasięgi są dołączone do tokenów dostępu generowanych z dostawcy. Gdy wywoływany jest interfejs API zabezpieczony z użyciem protokołu OAuth, brama sprawdza zasięgi przenoszone w znacznikach dostępu w odniesieniu do listy dozwolonych zasięgów w definicji zabezpieczeń, aby określić, czy ma zostać przyznany dostęp. Ponadto możliwe jest wymuszenie zaawansowanego sprawdzania zasięgu. Adresy URL zaawansowanego sprawdzania zasięgu są wywoływane po uwierzytelnieniu aplikacji lub po uwierzytelnieniu użytkownika w zależności od tego, które adresy URL skonfigurowano. Ostateczne uprawnienie zasięgu, które jest nadawane przez token dostępu, jest wynikiem wszystkich sprawdzeń zasięgu.`,
  [`The crypto object to use to decode the claim.`]: `Obiekt szyfrujący, który będzie używany do dekodowania roszczenia.`,
  [`The crypto object to use to encrypt the message.`]: `Obiekt szyfrujący, który ma być używany do szyfrowania komunikatu.`,
  [`The crypto object to use to sign the JWT.`]: `Obiekt szyfrujący, który ma być używany do podpisywania znacznika JWT.`,
  [`The crypto object to use to verify the signature.`]: `Obiekt szyfrujący, który będzie używany do weryfikowania podpisu.`,
  [`The current mapping contains references to unrecognized schema elements.`]: `Bieżące odwzorowanie zawiera odwołania do nierozpoznanych elementów schematu.`,
  [`The current organization does not contain any catalogs.`]: `Bieżąca organizacja nie zawiera żadnych katalogów.`,
  [`The default User registry must be included in the list`]: `Lista musi uwzględniać domyślny rejestr użytkowników`,
  [`The definition against which the payload must be validated ("#/definitions/XXXX")`]: `Definicja, względem której musi zostać sprawdzona poprawność ładunku ("#/definitions/XXXX")`,
  [`The developer portal provides the consumer view of published API products. After you have configured the developer portal for your catalog, and or more API products have been published, application developers can browse and use the APIs.`]: `Portal Developer Portal udostępnia widok konsumenta zawierający opublikowane produkty interfejsów API. Po skonfigurowaniu portalu Developer Portal na potrzeby katalogu i opublikowaniu jednego lub większej liczby produktów interfejsów API twórcy aplikacji mogą przeglądać interfejsy API i z nich korzystać.`,
  [`The document is missing draft_api.info.version field.`]: `W dokumencie brakuje pola draft_api.info.version.`,
  [`The document is missing draft_api.info.x-ibm-name field.`]: `W dokumencie brakuje pola draft_api.info.x-ibm-name.`,
  [`The document is missing draft_product.info.name field.`]: `W dokumencie brakuje pola draft_product.info.name.`,
  [`The document is missing draft_product.info.version field.`]: `W dokumencie brakuje pola draft_product.info.version.`,
  [`The extra constraints specify a slicing argument that is not an argument on this field.`]: `Dodatkowe ograniczenia określają argument przekrojów, który nie jest argumentem w tym polu.`,
  [`This field has a limit argument with a default value. Therefore, the given assumed size can never apply.`]: `To pole zawiera argument limitu z wartością domyślną. Dlatego podana założona wielkość nigdy nie ma zastosowania.`,
  [`This field has a limit argument with a default value or is required. Therefore, the given assumed size can never apply.`]: `To pole zawiera argument limitu z wartością domyślną lub jest wymagane. Dlatego podana założona wielkość nigdy nie ma zastosowania.`,
  [`This field has neither an assumed size nor any slicing arguments.`]: `To pole nie ma ani założonej wielkości, ani żadnych argumentów przekrojów.`,
  [`This field returns unbound list of values with composite type.`]: `To pole zawiera niepowiązaną listę wartości typu złożonego.`,
  [`This field returns unbound list of values with scalar type and type weight != 0.0.`]: `To pole zwraca niepowiązaną listę wartości typu skalarnego o wadze typu != 0.0.`,
  [`This test panel is deprecated and will be removed soon.`]: `Ten panel testowy jest nieaktualny i wkrótce zostanie usunięty.`,
  [`This type has no fields or no matching field.`]: `Ten typ nie ma pól lub nie ma zgodnych pól.`,
  [`This GatewayScript policy should not use the apim module, which is only for migrating old APIs.`]: `Ta strategia GatewayScript nie powinna używać modułu apim, który jest przeznaczony tylko do migrowania starych interfejsów API.`,
  [`This XSLT policy should not use the apim module, which is only for migrating old APIs.`]: `Ta strategia XSLT nie powinna używać modułu apim, który jest przeznaczony tylko do migrowania starych interfejsów API.`,
  [`This type will be deleted.`]: `Ten typ zostanie usunięty.`,
  [`The following diagram shows the assembly flow for your API. To modify the assembly, use the assembly editor.`]: `Na poniższym diagramie przedstawiono przepływ zespołu dla interfejsu API. Aby zmodyfikować zespół, należy użyć edytora zespołów.`,
  [`The following files are available under the`]: `Następujące pliki są dostępne w`,
  [`The following files contain a private key and cannot be uploaded:`]: `Następujące pliki zawierają klucz prywatny i nie można ich przesłać:`,
  [`The following is available under the`]: `Poniższe elementy są dostępne w`,
  [`The following lifecycle actions require approval`]: `Poniższe działania w cyklu życia wymagają zatwierdzenia`,
  [`The following list displays the draft APIs that have been created in this provider organization`]: `Na poniższej liście znajdują się wersje robocze interfejsów API, które zostały utworzone w tej organizacji dostawcy`,
  [`The following list displays the draft products that have been created in this provider organization`]: `Na poniższej liście znajdują się wersje robocze produktów, które zostały utworzone w tej organizacji dostawcy`,
  [`The following list shows all the users that have been added as members of this catalog, and their assigned roles`]: `Poniższa lista zawiera wszystkich użytkowników, którzy zostali dodani jako członkowie tego katalogu, oraz przypisane do nich role`,
  [`The following list shows all the users that have been added as members of this space, and their assigned roles`]: `Poniższa lista zawiera wszystkich użytkowników, którzy zostali dodani jako członkowie tego obszaru, oraz przypisane do nich role`,
  [`The following parameters do not match with the values configured in the OAuth provider`]: `Następujące parametry nie są zgodne z wartościami skonfigurowanymi w dostawcy OAuth.`,
  [`The following roles are available in the Cloud Manager`]: `W programie Cloud Manager dostępne są poniższe role`,
  [`The following types/fields will also be removed:`]: `Następujące typy/pola zostaną również usunięte:`,
  [`The following types/fields will be affected:`]: `Będzie to miało wpływ na następujące typy/pola:`,
  [`The gateway property value {gatewayType} is not allowed. Allowable values are datapower-gateway, datapower-api-gateway`]: `Wartość właściwości bramy {gatewayType} jest niedozwolona. Dozwolone wartości to: datapower-gateway, datapower-api-gateway`,
  [`The host used to invoke the API`]: `Host używany do wywoływania interfejsu API.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS.`]: `Magazyn kluczy zawiera pary certyfikatów publicznych i kluczy prywatnych używanych do potwierdzania tożsamości i szyfrowania/deszyfrowania transmisji danych za pośrednictwem protokołu HTTPS.`,
  [`The keystore contains matched pairs of public certificates and private keys used to confirm identity and encrypt/decrypt data transmission over HTTPS. The Server Profile requires a keystore.`]: `Magazyn kluczy zawiera pary certyfikatów publicznych i kluczy prywatnych używanych do potwierdzania tożsamości i szyfrowania/deszyfrowania transmisji danych za pośrednictwem protokołu HTTPS. Profil serwera wymaga magazynu kluczy.`,
  [`The keystore contains the public keys and private certificates presented by the server or client to confirm its identity.`]: `Magazyn kluczy zawiera klucze publiczne i certyfikaty prywatne przedstawiane przez serwer lub klienta w celu potwierdzenia jego tożsamości.`,
  [`The length of time (in seconds) that is added to the current date and time, in which the LTPA key is considered valid.`]: `Czas (w sekundach), który jest dodawany do bieżącej daty i godziny, kiedy klucz LTPA jest uznawany za poprawny.`,
  [`The length of time (in seconds), that is added to the current date and time, in which the JWT is considered valid.`]: `Czas (w sekundach), który jest dodawany do bieżącej daty i godziny, kiedy znacznik JWT jest uznawany za poprawny.`,
  [`The message to be sent with the error`]: `Komunikat, który ma zostać wysłany z błędem`,
  [`The message to be included within exception.`]: `Komunikat do uwzględnienia w wyjątku.`,
  [`The HTTP status code to be included within exception.`]: `Kod statusu HTTP do uwzględnienia w wyjątku.`,
  [`The HTTP status reason phrase to be included within exception.`]: `Fraza opisująca przyczynę statusu HTTP do uwzględnienia w wyjątku.`,
  [`The name of a burst limit defined in the datapower configuration.`]: `Nazwa limitu wywołań zdefiniowana w konfiguracji datapower.`,
  [`The name of a count limit defined in the datapower configuration.`]: `Nazwa limitu liczby zdefiniowana w konfiguracji datapower.`,
  [`The name of a rate limit defined in the datapower configuration.`]: `Nazwa limitu częstotliwości zdefiniowana w konfiguracji datapower.`,
  [`The name of a variable that will be used to store the response data from the request. This can then be referenced in other actions, such as "Map".`]: `Nazwa zmiennej, która będzie używana do przechowywania danych odpowiedzi z żądania. Następnie będzie można się do niej odwoływać w innych działaniach, takich jak odwzorowanie.`,
  [`The name of a variable that will be used to store the result. By default, "message" will be used.`]: `Nazwa zmiennej, która będzie używana do przechowywania wyniku. Domyślnie zostanie użyta nazwa "komunikat".`,
  [`The name of parameter to find the ID.`]: `Nazwa parametru potrzebna do znalezienia identyfikatora.`,
  [`The name of parameter to find the secret.`]: `Nazwa parametru potrzebna do znalezienia danych niejawnych.`,
  [`The name of the LTPA key that you want to use to generate the LTPA token. If you want a specific version of the key to be used, specify <keyname>:<version>. For example, my-ltpa-key:1.0.1. If you want the policy to automatically select the latest version of the key to use, specify <keyname>:latest. For example, my-ltpa-key:latest.`]: `Nazwa klucza LTPA, który ma zostać użyty do wygenerowania tokenu LTPA. Jeśli ma być używana konkretna wersja klucza, podaj nazwę w formacie <keyname>:<version>. Na przykład mój-klucz-ltpa:1.0.1. Jeśli strategia ma automatycznie wybierać najnowszą wersję klucza do użycia, podaj nazwę w formacie <keyname>:latest. Na przykład mój-klucz-ltpa:latest.`,
  [`The name of the error to be thrown`]: `Nazwa błędu, który ma zostać zgłoszony`,
  [`The name of the variable to be added.`]: `Nazwa zmiennej, która ma zostać dodana.`,
  [`The name of the variable to be cleared.`]: `Nazwa zmiennej, która ma zostać wyczyszczona.`,
  [`The name of the variable to be set.`]: `Nazwa zmiennej, która ma zostać ustawiona.`,
  [`The nature of the XML validation to be performed.`]: `Charakter sprawdzania poprawności kodu XML, które ma zostać przeprowadzone.`,
  [`The operation to apply.`]: `Operacja do zastosowania`,
  [`The output of this mapping is within an array and it's contents will be created by iterating over the chosen input. If the chosen input isn't an array, an array of length 1 will be produced.`]: `Dane wyjściowe tego odwzorowania znajdują się w tablicy, a jej zawartość zostanie utworzona przez iterowanie wybranego wejścia. Jeśli wybrane dane wejściowe nie są tablicą, zostanie utworzona tablica o długości 1.`,
  [`The owner must be an existing user in your user registry`]: `Właścicielem musi być istniejący użytkownik w rejestrze użytkowników`,
  [`The owner of consumer organization has the following privileges:`]: `Właściciel organizacji konsumenta ma następujące uprawnienia:`,
  [`The owner of provider organization has the following privileges:`]: `Właściciel organizacji dostawcy ma następujące uprawnienia:`,
  [`The owner's duties include: manage the API product lifecycle, manage one or more API provider organizations, manage application provider communities, and author APIs and product definitions.`]: `Do obowiązków właściciela należy: zarządzanie cyklem życia produktu interfejsu API, zarządzanie jedną lub większą liczbą organizacji dostawców interfejsu API, zarządzanie społecznościami dostawców aplikacji oraz tworzenie definicji interfejsów API i produktów.`,
  [`The page you are looking for might have been moved or does not exist.`]: `Szukana strona została przeniesiona lub nie istnieje.`,
  [`The page you are looking for might have been moved or does not exist. Please contact your administrator for support.`]: `Szukana strona została przeniesiona lub nie istnieje. Skontaktuj się z administratorem w celu uzyskania wsparcia.`,
  [`The parameters needed to send a valid API call.`]: `Parametry wymagane do wysłania poprawnego wywołania funkcji API.`,
  [`The password to use for HTTP Basic authentication.`]: `Hasło, które ma być używane na potrzeby podstawowego uwierzytelniania HTTP.`,
  [`The portal service provides a developer portal used by application developers to discover APIs and onboard consumers.`]: `Usługa portalu udostępnia portal Developer Portal używany przez twórców aplikacji do wykrywania interfejsów API oraz rejestrowania konsumentów.`,
  [`The portal service provides a developer portal used by application developers to discover and implement APIs.`]: `Usługa portalu udostępnia portal Developer Portal używany przez twórców aplikacji do wykrywania i implementowania interfejsów API.`,
  [`The processing status provides a view of a gateway’s health with a summary of recent activity`]: `Status przetwarzania jest odzwierciedleniem stanu poprawności bramy i podsumowaniem ostatniej aktywności`,
  [`The recipient registers as an owner and creates a new provider organization. Once registered, the user can log in to API Manager as the provider organization owner.`]: `Odbiorca rejestruje się jako właściciel i tworzy nową organizację dostawcy. Po zarejestrowaniu użytkownik może zalogować się do produktu API Manager jako właściciel organizacji dostawcy.`,
  [`The root element name of the resultant XML document. This is only required if the input JSON document is not hierarchical and has more than one top level property.`]: `Nazwa elementu głównego wynikowego dokumentu XML. Jest ona wymagana tylko wtedy, gdy dokument wejściowy JSON nie jest hierarchiczny i ma więcej niż jedną właściwość najwyższego poziomu.`,
  [`The scopes here are for developers to  understand what are allowed to access and do not take effect for scope check.`]: `Zasięgi w tym miejscu są przeznaczone dla programistów i udostępniają im informacje o tym, do czego mogą uzyskać dostęp i co nie odnosi skutku w przypadku sprawdzania zasięgu.`,
  [`The selected catalog will only be used to search consumer organizations`]: `Wybrany katalog będzie używany tylko do wyszukiwania organizacji konsumentów`,
  [`The selected catalog does not have any configured gateways`]: `Wybrany katalog nie ma żadnych skonfigurowanych bram`,
  [`The selected catalog does not have any compatible gateways`]: `Wybrany katalog nie ma żadnych kompatybilnych bram`,
  [`Save Preferences`]: `Zapisz preferencje`,
  [`Preferences`]: `Preferencje`,
  [`The selected catalog will only be used to search consumer organizations and groups'`]: `Wybrany katalog będzie używany tylko do wyszukiwania organizacji konsumentów i grup'`,
  [`The selected gateway type will render the following policies in your assembly as invalid. You will need to delete these policies before you can run this API.`]: `Wybrany typ bramy spowoduje, że następujące strategie w zespole staną się niepoprawne. Konieczne będzie usunięcie tych strategii, zanim można będzie uruchomić ten interfejs API.`,
  [`The selected product does not contain this API`]: `Wybrany produkt nie zawiera tego interfejsu API`,
  [`The selected product will be subscribable to a specific list of consumer organizations or groups.`]: `Wybrany produkt będzie mógł być subskrybowany przez organizacje konsumentów lub grupy z konkretnej listy.`,
  [`The selected services will be available to a specific list of provider organizations.`]: `Wybrane usługi będą dostępne dla organizacji dostawców z konkretnej listy.`,
  [`The selected services will be available to all existing and new provider organizations.`]: `Wybrane usługi będą dostępne dla wszystkich istniejących i nowych organizacji dostawców.`,
  [`The selected services will not be available to any provider organizations.`]: `Wybrane usługi nie będą dostępne dla żadnej organizacji dostawcy.`,
  [`The string to use as a key for the cache. If omitted, the entire URL string is used as the key.`]: `Łańcuch, który ma być używany jako klucz pamięci podręcznej. Jeśli zostanie on pominięty, jako klucz zostanie użyty cały łańcuch adresu URL.`,
  [`The server responded with a non-JSON body \n{body}`]: `Serwer odpowiedział treścią inną niż JSON: \n{body}`,
  [`The time interval needs to be higher than the access token time to live.`]: `Odstęp czasu musi być dłuższy niż czas życia tokenu dostępu.`,
  [`The token will not be stored in either a cookie or local storage. If you close the test panel, the token will be lost but may still be valid.`]: `Token nie będzie przechowywany ani w informacji cookie, ani w lokalnej pamięci masowej. W przypadku zamknięcia panelu testu token zostanie utracony, ale nadal może być poprawny.`,
  [`The transfer protocol of the API.`]: `Protokół przesyłania interfejsu API.`,
  [`The type of authentication.`]: `Typ uwierzytelniania.`,
  [`The type of authorization header.`]: `Typ nagłówka autoryzacji.`,
  [`The type of the value to set. This can be any, string, number or boolean.`]: `Typ wartości, który ma zostać ustawiony. Może to być dowolna wartość (any), łańcuch (string), liczba (number) lub wartość boolowska (boolean).`,
  [`The type of the value to set. This can be string, number or boolean.`]: `Typ wartości, który ma zostać ustawiony. Może to być łańcuch (string), liczba (number) lub wartość boolowska (boolean).`,
  [`The username to use for HTTP Basic authentication.`]: `Nazwa użytkownika, która ma być używana na potrzeby podstawowego uwierzytelniania HTTP.`,
  [`The user for this connection is no longer valid. Please create a new connection.`]: `Użytkownik dla tego połączenia nie jest już poprawny. Utwórz nowe połączenie.`,
  [`The value that the variable will be set to.`]: `Wartość, na którą zostanie ustawiona zmienna.`,
  [`The value of this field is a list of the composite type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Wartość tego pola jest listą typu złożonego, która nie ma ani założonej wielkości, ani żadnych argumentów przekrojów. W przypadku jej napotkania brama interfejsu API nie może ustawić limitu liczby obiektów zwracanych przez serwer GraphQL po otrzymaniu zapytania.`,
  [`The value of this field is a list of the scalar type, which has neither an assumed size nor any slicing arguments. If it is encountered, the API Gateway cannot set a limit on the number of objects that the GraphQL server might return if it receives the query.`]: `Wartość tego pola jest listą typu skalarnego, która nie ma ani założonej wielkości, ani żadnych argumentów przekrojów. W przypadku jej napotkania brama interfejsu API nie może ustawić limitu liczby obiektów zwracanych przez serwer GraphQL po otrzymaniu zapytania.`,
  [`The version needs to be url safe. The object's version {version} and the slugified version {slugVersion} do not match.`]: `Ta wersja musi być bezpieczna pod względem adresów URL. Wersja obiektu {version} i wersja części ze słowami kluczowymi {slugVersion} nie są zgodne.`,
  [`The "name" property must be URL safe (the object's name value "{name}" and the slugified name value "{slugName}" must match).`]: `Właściwość "name" musi być bezpieczna do stosowania w adresie URL (wartość nazwy obiektu "{name}" i wartość nazwy czytelnej dla użytkownika "{slugName}" muszą być takie same).`,
  [`The 'draft_api' property must contain an OpenAPI definition. The OpenAPI version 3 only supports versions 3.0.0 - 3.0.3 (specified version {version})`]: `Właściwość 'draft_api' musi zawierać definicję OpenAPI. Interfejs OpenAPI w wersji 3 obsługuje tylko wersje 3.0.0 - 3.0.3 (określona wersja {version})`,
  [`The name field is automatically populated based on the title that you provide. Use the resulting value within API Connect commands and API calls.`]: `Pole nazwy jest wypełniane automatycznie na podstawie tytułu podanego przez użytkownika. Używaj wartości wynikowej w komendach API Connect i wywołaniach interfejsu API.`,
  [`The visibility setting determines which provider organizations can access a resource. The choices are: Public (the resource can be used by all provider organizations); Custom (the resource can be used only by provider organizations designated by you); and Private (the resource is not visible and cannot be used by any provider organization).`]: `Ustawienie widoczności określa, które organizacje dostawcy mogą uzyskać dostęp do zasobu. Dostępne opcje to: Publiczny (zasób może być używany przez wszystkie organizacje dostawców); Niestandardowy (zasób może być używany tylko przez organizacje dostawców wyznaczone przez użytkownika); Prywatny (zasób nie jest widoczny i nie może być używany przez żadną organizację dostawcy).`,
  [`The visibility setting determines which provider organizations can use a service. The choices are: Public (the service can be used by all provider organizations); Custom (the service can be used only by provider organizations designated by you); and Private (the service is not visible and cannot be used by any provider organization).`]: `Ustawienie widoczności określa, które organizacje dostawcy mogą korzystać z usługi. Dostępne opcje to: Publiczna (usługa może być używana przez wszystkie organizacje dostawców); Niestandardowa (usługa może być używana tylko przez organizacje dostawców wyznaczone przez użytkownika); Prywatna (usługa nie jest widoczna i nie może być używana przez żadną organizację dostawcy).`,
  [`The visibility setting determines which provider organizations can use a gateway. Visibility levels include: Public (the gateway can be used by all provider organizations); Custom (the gateway can be used only by provider organizations designated by you); and Private (the gateway is not visible and cannot be used by any provider organizations).`]: `Ustawienie widoczności określa, które organizacje dostawców mogą korzystać z bramy. Dostępne są następujące poziomy widoczności: Publiczna (brama może być używana przez wszystkie organizacje dostawców), Niestandardowa (brama może być używana tylko przez organizacje dostawców wyznaczone przez użytkownika). Prywatna (brama nie jest widoczna i nie może być używana przez żadną organizację dostawców).`,
  [`The {title} role`]: `Rola {title}`,
  [`The {url} resource does not exist`]: `Zasób {url} nie istnieje`,
  [`There are incompatible APIs due to them having a different Gateway type to this product.`]: `Istnieją niekompatybilne interfejsy API mające inny typ bramy niż ten produkt.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Certificate Manager service.`]: `Następnie wybierz certyfikat dla domeny punktu końcowego zarządzania. Wybierz także odpowiedni pakunek ośrodka CA, jeśli certyfikat nie został przypisany przez powszechnie znany ośrodek certyfikacji. Certyfikat i pakunek muszą być zarządzane przez usługę Certificate Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Certificate Manager service.`]: `Następnie określ domeny, które będą obsługiwane przez tę bramę. Używana jest technika SNI; w rezultacie wiele domen może być obsługiwanych za pośrednictwem tego samego adresu IP i portu, bez konieczności stosowania tego samego certyfikatu. Obsługiwany jest format wieloznaczny. Ostatnią pozycją musi być domena domyślna (catch-all) oznaczona ‘*’. W razie potrzeby wprowadź inne nazwy domen, w każdym przypadku określając certyfikat zarządzany przez usługę Certificate Manager.`,
  [`There should be at least {minSelected} {label} selected.`]: `Minimalna liczba elementów (typu {label}), jaką należy wybrać: {minSelected}.`,
  [`There are no API changes to publish.`]: `Brak zmian interfejsu API do opublikowania.`,
  [`There are no gateway services`]: `Nie ma żadnych usług bramy`,
  [`There are no assemblies that match the chosen filters.`]: `Nie ma elementów złożonych zgodnych z wybranymi filtrami.`,
  [`There are no burst limits yet.`]: `Nie istnieją jeszcze limity wywołań.`,
  [`There are no catalogs`]: `Nie istnieją żadne katalogi`,
  [`There are no consumer organizations.`]: `Nie ma organizacji konsumentów.`,
  [`There are no consumer organizations. You can create one`]: `Nie ma organizacji konsumentów. Można ją utworzyć`,
  [`There are no consumer organizations. You can create one [here]({link})`]: `Nie ma organizacji konsumentów. Można utworzyć usługę [tutaj]({link})`,
  [`There are no email servers`]: `Nie ma serwerów poczty elektronicznej`,
  [`There are no gateway services configured for the sandbox catalog`]: `Brak usług bramy skonfigurowanych dla katalogu środowiska testowego`,
  [`There are no lifecycle approvals enabled`]: `Nie włączono żadnych zatwierdzeń w cyklu życia`,
  [`There are no new changes.`]: `Nie ma żadnych nowych zmian.`,
  [`There are no products to display`]: `Brak produktów do wyświetlenia`,
  [`There are no products to replace`]: `Brak produktów do zastąpienia`,
  [`There are no products to set migration target`]: `Brak produktów, aby ustawić cel migracji`,
  [`There are no products to supersede`]: `Brak produktów do zastąpienia`,
  [`There are no properties to configure for this policy`]: `Nie ma właściwości do skonfigurowania dla tej strategii`,
  [`There are no rate limits yet.`]: `Nie istnieją jeszcze limity częstotliwości.`,
  [`There are no security definitions`]: `Nie ma definicji zabezpieczeń`,
  [`There are no spaces`]: `Nie istnieją żadne obszary`,
  [`There are no target services`]: `Brak usług docelowych`,
  [`There are no tasks to be displayed`]: `Brak zadań do wyświetlenia`,
  [`There are unsaved changes. Are you sure you want to continue?`]: `Istnieją niezapisane zmiany. Czy na pewno chcesz kontynuować?`,
  [`There are unsaved changes. They will be discarded if you continue. Are you sure?`]: `Istnieją niezapisane zmiany. W przypadku kontynuacji nastąpi ich usunięcie. Czy na pewno chcesz kontynuować?`,
  [`There is an api in your yaml that does not exist and cannot be listed, please check the source tab.`]: `W pliku yaml znajduje się interfejs API, który nie istnieje lub którego nie można wyświetlić. Sprawdź kartę kodu źródłowego.`,
  [`There is no item in the list.`]: `Na liście nie ma żadnych elementów.`,
  [`There is no space`]: `Nie ma obszaru`,
  [`There is no warning in the schema.`]: `W schemacie nie ma ostrzeżenia.`,
  [`There was no data found to populate the data table.`]: `Nie znaleziono danych potrzebnych, aby zapełnić tabelę danych.`,
  [`These endpoints are used for all the APIs in the catalog`]: `Te punkty końcowe są używane dla wszystkich interfejsów API w katalogu`,
  [`Third party OAuth provider`]: `Zewnętrzny dostawca OAuth`,
  [`Third party OAuth provider summary`]: `Podsumowanie zewnętrznego dostawcy OAuth`,
  [`Third party`]: `Zewnętrzne`,
  [`This API does not yet contain an assembly. Would you like to create one?`]: `Ten interfejs API jeszcze nie zawiera elementu złożonego. Czy chcesz go utworzyć?`,
  [`This resource no longer exists, please refresh the results from the server.`]: `Ten zasób już nie istnieje, odśwież wyniki z serwera.`,
  [`Please refresh the results from the server`]: `Odśwież wyniki z serwera`,
  [`Exact match`]: `Dokładne dopasowanie`,
  [`Closest match`]: `Najbliższe dopasowanie`,
  [`This API is currently being used by the product {productName}. Please delete it from the product, then proceed to delete the API.`]: `Ten interfejs API jest obecnie używany przez produkt {productName}. Usuń go z produktu, a następnie przejdź do usuwania interfejsu API.`,
  [`This API will be available to be invoked when the following option is enabled.`]: `Ten interfejs API będzie dostępny do wywołania, gdy zostanie włączona poniższa opcja.`,
  [`This catalog does not have an automatic subscription enabled. To test in this catalog, please select a product, plan, and application below.`]: `Ten katalog nie ma włączonej subskrypcji automatycznej. Aby przeprowadzić test w tym katalogu, wybierz poniżej produkt, plan i aplikację.`,
  [`This endpoint is used as the default for APIs that do not define a base endpoint`]: `Ten punkt końcowy jest używany jako domyślny dla interfejsów API, które nie definiują podstawowego punktu końcowego`,
  [`This is a technical preview feature which will evolve and continue to be improved in the future.`]: `Jest to funkcja do oceny technicznej, która będzie w przyszłości podlegać ewolucji i doskonaleniu.`,
  [`This is the currently configured notification server.`]: `To jest aktualnie skonfigurowany serwer powiadomień.`,
  [`This is the directory where your files including policies and extensions will be stored`]: `To jest katalog, w którym będą przechowywane pliki zawierające strategie i rozszerzenia`,
  [`This is where APIs will run and policies and extensions can be accessed`]: `Jest to miejsce uruchamiania interfejsów API i uzyskiwania dostępu do strategii i rozszerzeń`,
  [`This oauth policy performs all OAuth protocol steps that are needed for issued path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Ta strategia OAuth wykonuje wszystkie kroki protokołu OAuth, które są domyślnie wymagane w przypadku wprowadzonej ścieżki. Dane wejściowe i wyjściowe każdego kroku są sterowane udokumentowanymi zmiennymi kontekstowymi. W razie potrzeby dodaj lub usuń obsługiwane komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for OAuth Validation by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Ta strategia OAuth wykonuje wszystkie kroki protokołu OAuth/OpenID Connect, które są domyślnie wymagane w przypadku sprawdzania poprawności OAuth. Dane wejściowe i wyjściowe każdego kroku są sterowane udokumentowanymi zmiennymi kontekstowymi. W razie potrzeby dodaj lub usuń obsługiwane komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for all other paths by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Ta strategia OAuth wykonuje wszystkie kroki protokołu OAuth/OpenID Connect, które są domyślnie wymagane w przypadku wszystkich pozostałych ścieżek. Dane wejściowe i wyjściowe każdego kroku są sterowane udokumentowanymi zmiennymi kontekstowymi. W razie potrzeby dodaj lub usuń obsługiwane komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for az code path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Ta strategia OAuth wykonuje wszystkie kroki protokołu OAuth/OpenID Connect, które są domyślnie wymagane w przypadku ścieżki kodu az. Dane wejściowe i wyjściowe każdego kroku są sterowane udokumentowanymi zmiennymi kontekstowymi. W razie potrzeby dodaj lub usuń obsługiwane komponenty OAuth.`,
  [`This oauth policy performs all OAuth/OpenID Connect protocol steps that are needed for token path by default. The inputs and outputs of each of the steps are driven by documented context variables. Add or remove the Supported OAuth Components as required.`]: `Ta strategia OAuth wykonuje wszystkie kroki protokołu OAuth/OpenID Connect, które są domyślnie wymagane w przypadku ścieżki tokenu. Dane wejściowe i wyjściowe każdego kroku są sterowane udokumentowanymi zmiennymi kontekstowymi. W razie potrzeby dodaj lub usuń obsługiwane komponenty OAuth.`,
  [`This operation is secured with access code flow OAuth`]: `Ta operacja jest zabezpieczona przy użyciu uwierzytelniania OAuth z przepływem kodu dostępu`,
  [`This operation is secured with application flow OAuth`]: `Ta operacja jest zabezpieczona przy użyciu uwierzytelniania OAuth z przepływem aplikacji`,
  [`This operation is secured with basic authentication`]: `Ta operacja jest zabezpieczona przy użyciu podstawowego uwierzytelniania`,
  [`This operation is secured with implicit flow OAuth`]: `Ta operacja jest zabezpieczona przy użyciu uwierzytelniania OAuth z przepływem niejawnym`,
  [`This operation is secured with password flow OAuth`]: `Ta operacja jest zabezpieczona przy użyciu uwierzytelniania OAuth z przepływem hasła`,
  [`This policy is not available on the selected gateway`]: `Ta strategia nie jest dostępna dla wybranej bramy`,
  [`This policy is used by one other operation`]: `Ta strategia jest używana przez inną operację`,
  [`This policy is used by {{count}} other operations`]: `Ta strategia jest używana przez następującą liczbę innych operacji: {{count}}`,
  [`This role cannot be edited`]: `Tej roli nie można edytować`,
  [`This role cannot be edited.`]: `Tej roli nie można edytować.`,
  [`This setting controls the behavior if a mapping fails because its input is not present and there is no default mapping configured. The default behavior is to make no change to the output, but if you select this checkbox an empty object is created for the parent of the target mapping, emulating the behavior of version 4.`]: `To ustawienie steruje zachowaniem w przypadku niepowodzenia odwzorowania z powodu braku danych wyjściowych i nieskonfigurowanego odwzorowania domyślnego. Zachowaniem domyślnym jest niewprowadzanie żadnych zmian w danych wyjściowych, ale jeśli to pole wyboru zostanie zaznaczone, tworzony będzie pusty obiekt dla elementu nadrzędnego odwzorowania docelowego, co emuluje zachowanie z wersji 4.`,
  [`This user security policy performs EI(basic) and AU(auth url) check for oauth assembly. Change the security check method as required`]: `Ta strategia bezpieczeństwa użytkownika wykonuje operacje sprawdzania EI (podstawowe) i AU (adres URL uwierzytelniania) w przypadku elementu złożonego OAuth. W razie potrzeby zmień metodę sprawdzania zabezpieczeń.`,
  [`This will enable/disable application lifecycle`]: `To spowoduje włączenie/wyłączenie cyklu życia aplikacji`,
  [`This will enable/disable task self-approval. Task self-approval allows tasks to be approved by their originator as well as by collaborators. This option can be convenient when other approvers are not available, but effectively allows checks by another user to be bypassed.`]: `Spowoduje to włączenie/wyłączenie samozatwierdzania zadania. Samozatwierdzanie zadania umożliwia zatwierdzanie zadań przez ich inicjatora oraz przez współpracowników. Opcja ta może być przydatna, gdy inne osoby zatwierdzające nie są dostępne, ale efektywnie pozwala na pominięcie sprawdzania przez innego użytkownika.`,
  [`This will unset the catalog as the default catalog.`]: `Spowoduje to anulowanie ustawienia katalogu jako domyślnego.`,
  [`Throw`]: `Zgłoś`,
  [`Time`]: `Czas`,
  [`Time Interval`]: `Odstęp czasu`,
  [`Time Limit`]: `Limit czasu`,
  [`Time to Live`]: `Czas życia`,
  [`Time to live`]: `Czas życia`,
  [`Time to wait before a reply back from the endpoint. Default is 60 seconds.`]: `Czas oczekiwania na odpowiedź z punktu końcowego. Wartość domyślna to 60 sekund.`,
  [`Timeout`]: `Limit czasu`,
  [`To`]: `Do`,
  [`to`]: `do`,
  [`To add a user as a member of the Admin organization, select their user registry, enter their user name, and select the roles that you want to assign. If the user registry type is Local User Registry, you also have the option to create a new user that is then added as a member. The membership is enabled immediately, and the specified user can log in to the Cloud Manager user interface`]: `Aby dodać użytkownika jako członka organizacji administracyjnej, wybierz jego rejestr użytkowników, wprowadź jego nazwę użytkownika i wybierz role do przypisania. Jeśli typem rejestru użytkowników jest Lokalny rejestr użytkowników, istnieje także opcja utworzenia nowego użytkownika, który jest następnie dodawany jako członek organizacji. Członkostwo jest włączane natychmiast, a określony użytkownik może zalogować się do interfejsu użytkownika menedżera chmury.`,
  [`To add a user as a member of a provider organization`]: `Aby dodać użytkownika jako członka organizacji dostawcy`,
  [`To add a user as a member of the Catalog, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Catalog, nor the Catalog owner.`]: `Aby dodać użytkownika jako członka do katalogu, wybierz jego nazwę użytkownika i przypisz mu wymagane role. Można dodać dowolnego użytkownika będącego członkiem innego katalogu lub obszaru w organizacji dostawcy, a nie jest członkiem ani właścicielem tego katalogu.`,
  [`To add a user as a member of the Space, select their username and assign the required roles. You can add any user who is already a member of another Catalog, or of a Space, in the provider organization, and is neither currently a member of this Space, nor the Space owner.`]: `Aby dodać użytkownika jako członka do obszaru, wybierz jego nazwę użytkownika i przypisz mu wymagane role. Można dodać dowolnego użytkownika będącego członkiem innego katalogu lub obszaru w organizacji dostawcy, a nie jest członkiem ani właścicielem tego obszaru.`,
  [`To add or remove members, use the [identity and access](/iam) service.`]: `Aby dodać lub usunąć członków, należy użyć usługi [tożsamość i dostęp](/iam).`,
  [`To add members, use the [identity and access](/iam) service.`]: `Aby dodać członków, należy użyć usługi [tożsamość i dostęp](/iam).`,
  [`To add user as a member of the organization, select their user registry, enter their username, and assign a set of roles.`]: `Aby dodać użytkownika jako członka organizacji, wybierz jego rejestr użytkowników, wprowadź nazwę użytkownika i przypisz zestaw ról.`,
  [`To add user as a member, select their username and assign it a set of roles.`]: `Aby dodać użytkownika jako członka, wybierz jego nazwę użytkownika i przypisz mu zestaw ról.`,
  [`To assign default gateways, click **Edit**.`]: `Aby przypisać domyślne bramy, kliknij opcję **Edytuj**.`,
  [`To continue using this software, you must agree to the terms of the software license agreement.`]: `Aby kontynuować korzystanie z tego oprogramowania, należy wyrazić zgodę na warunki umowy licencyjnej na oprogramowanie.`,
  [`To continue using API Connect, upgrade to an Enterprise plan.`]: `Aby kontynuować korzystanie z rozwiązania API Connect, należy przejść na plan Enterprise.`,
  [`To get started, you must first enable spaces.`]: `Aby rozpocząć, należy najpierw włączyć obszary.`,
  [`To install and run the toolkit, complete the following steps. [Learn more]({link})`]: `Aby zainstalować i uruchomić bibliotekę narzędziową, wykonaj następujące czynności. [Więcej informacji]({link})`,
  [`To invite a new user as a member of the Admin organization, enter the user's email address or name, or select their name from a user registry.  All members are automatically assigned the Member role, which provides a basic set of permissions and cannot be deleted. If no other permissions are required, leave the other roles unchecked.`]: `Aby zaprosić nowego użytkownika jako członka organizacji administracyjnej, wprowadź adres e-mail lub nazwę użytkownika albo wybierz nazwę z rejestru użytkowników.  Wszystkim członkom jest automatycznie przypisywana rola członka, która udostępnia podstawowy zestaw uprawnień i której nie można usunąć. Jeśli nie są wymagane żadne inne uprawnienia, można nie zaznaczać pozostałych ról.`,
  [`To invite an owner who is not already in your user registry, enter their email address`]: `Aby zaprosić właściciela, który nie znajduje się jeszcze w rejestrze użytkowników, wprowadź jego adres e-mail`,
  [`To register a new gateway, visit the **Gateways** page.`]: `Aby zarejestrować nową bramę, odwiedź stronę **Bramy**.`,
  [`To replace values with one of the following space properties, type $() with the name of the property inside the parenthesis`]: `Aby zastąpić wartości jedną z następujących właściwości obszaru, wpisz $(), umieszczając w nawiasach nazwę właściwości`,
  [`To send an email invitation to a new user to register as a member of the Admin organization, enter a valid email address, and select the roles that you want to assign to the user. An email containing an activation link is sent to the email address inviting the person to register. The user is placed in Pending status, and is changed to Enabled status after they complete the registration form to activate their account. They can then log in to the Cloud Manager user interface with the API Connect user name that they specified during account activation.`]: `Aby wysłać do nowego użytkownika zaproszenie w postaci wiadomości e-mail umożliwiające mu zarejestrowanie się jako członek organizacji administracyjnej, wprowadź poprawny adres e-mail i wybierz role do przypisania do użytkownika. Na podany adres e-mail zostanie wysłana wiadomość e-mail z odsyłaczem aktywacyjnym stanowiącym zaproszenie do zarejestrowania się. Użytkownik otrzymuje status Oczekujący, który zostanie zmieniony na status Włączony po wypełnieniu przez niego formularza rejestracyjnego i aktywowaniu konta. Następnie użytkownik będzie mógł zalogować się w interfejsie użytkownika menedżera chmury przy użyciu nazwy użytkownika produktu API Connect podanej podczas aktywowania konta.`,
  [`Toggle`]: `Przełącz`,
  [`Toggle application lifecycle?`]: `Czy włączyć/wyłączyć cykl życia aplikacji?`,
  [`Toggle default catalog?`]: `Czy przełączyć katalog domyślny?`,
  [`Toggle info panel between right and bottom`]: `Przełącz panel informacyjny - prawa strona lub dół`,
  [`Toggle profile`]: `Przełącz profil`,
  [`Toggle spaces?`]: `Czy przełączać spacje?`,
  [`Toggle task self-approval?`]: `Czy przełączyć samozatwierdzanie zadań?`,
  [`Token`]: `Token`,
  [`Token endpoint`]: `Punkt końcowy tokenu`,
  [`Token Expiry`]: `Utrata ważności tokenu`,
  [`Token Management`]: `Zarządzanie tokenami`,
  [`Token Output`]: `Dane wyjściowe tokenu`,
  [`Token URL`]: `Adres URL tokenu`,
  [`Token URL must match {endpoint} as defined by OAuth provider "{providerTitle}"`]: `Adres URL tokenu musi być zgodny z {endpoint}, tak jak zdefiniował to dostawca OAuth „{providerTitle}”`,
  [`Token Version`]: `Wersja tokenu`,
  [`Token management`]: `Zarządzanie tokenami`,
  [`Token management type`]: `Typ zarządzania tokenami`,
  [`Token management enables you to prevent replay attacks, manage tokens, including whether and how the client applications or resource owners revoke OAuth tokens. API Connect supports token management with a native gateway or a third party endpoint. When a native gateway, quota enforcement is used to manage tokens. When a third party endpoint, you provide an URL to an external service to manage tokens.`]: `Zarządzanie tokenami umożliwia zapobieganie atakom polegającym na odtwarzaniu, jak również decydowanie o sposobie wykonywania przez aplikacje klienckie lub właścicieli zasobów operacji na tokenach, takich jak unieważnianie tokenów OAuth. Produkt API Connect obsługuje zarządzanie tokenami przy użyciu własnej bramy lub zewnętrznego punktu końcowego. W przypadku własnej bramy przy zarządzaniu tokenami stosowane jest egzekwowanie limitów. W przypadku zewnętrznego punktu końcowego w celu zarządzania tokenami należy podać adres URL usługi zewnętrznej.`,
  [`Token path`]: `Ścieżka tokenu`,
  [`Token revocation`]: `Unieważnienie tokenu`,
  [`Token secret`]: `Dane niejawne tokenu`,
  [`Token validation`]: `Sprawdzenie poprawności tokenu`,
  [`Tokens`]: `Tokeny`,
  [`Token relay`]: `Przekaźnik tokenu`,
  [`Toolkit credentials`]: `Referencje biblioteki narzędziowej`,
  [`Token Relay`]: `Przekaźnik tokenu`,
  [`Topology`]: `Topologia`,
  [`Topology Administrator`]: `Administrator topologii`,
  [`Total time`]: `Łączny czas`,
  [`Transfer Ownership to a New User`]: `Przekazywanie prawa własności do nowego użytkownika`,
  [`Transfer Ownership to an Existing User`]: `Przekazywanie prawa własności do istniejącego użytkownika`,
  [`Transfer ownership`]: `Przekaż prawo własności`,
  [`Transfer ownership to`]: `Przekaż prawo własności do`,
  [`Transfer ownership to a new user`]: `Przekaż prawo własności do nowego użytkownika`,
  [`Transfer ownership to an existing user`]: `Przekaż prawo własności do istniejącego użytkownika`,
  [`Transforms`]: `Transformacje`,
  [`Truststore Certificates`]: `Certyfikaty magazynu zaufanych certyfikatów`,
  [`Truststore certificates are public certifcates issued by a certifcate authority.`]: `Certyfikaty magazynu zaufanych certyfikatów to certyfikaty publiczne wydawane przez ośrodek certyfikacji.`,
  [`Truststores contain trusted certificates containing verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `W magazynach zaufanych certyfikatów przechowywane są zaufane certyfikaty zawierające sprawdzone klucze publiczne. Certyfikaty znajdujące się w magazynie zaufanych certyfikatów są zwykle uzyskiwane z zewnętrznego ośrodka certyfikacji (CA).`,
  [`Truststores contain trusted certificates with verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `W magazynach zaufanych certyfikatów przechowywane są zaufane certyfikaty zawierające sprawdzone klucze publiczne. Certyfikaty znajdujące się w magazynie zaufanych certyfikatów są zwykle uzyskiwane z zewnętrznego ośrodka certyfikacji (CA).`,
  [`Truststores store trusted certificates, including verified public keys. The certificates in the truststore are usually obtained from a third-party certificate authority (CA).`]: `W magazynach zaufanych certyfikatów przechowywane są zaufane certyfikaty zawierające sprawdzone klucze publiczne. Certyfikaty znajdujące się w magazynie zaufanych certyfikatów są zwykle uzyskiwane z zewnętrznego ośrodka certyfikacji (CA).`,
  [`Try again or upload a schema`]: `Spróbuj ponownie lub prześlij schemat`,
  [`Try refreshing the page or contacting support.`]: `Spróbuj odświeżyć stronę lub skontaktować się z działem wsparcia.`,
  [`Turkish`]: `turecki`,
  [`Tutorials`]: `Kursy`,
  [`Types`]: `Typy`,
  [`Type of user`]: `Typ użytkownika`,
  [`Type or select a catalog`]: `Wpisz lub wybierz katalog`,
  [`Type to add organizations`]: `Wpisz, aby dodać organizacje`,
  [`TypeError: Failed to fetch`]: `TypeError: Pobranie nie powiodło się`,
  [`UDP`]: `UDP`,
  [`URL`]: `Adres URL`,
  [`URL of management endpoint`]: `Adres URL punktu końcowego zarządzania`,
  [`URLs for all operations in the API begin with this value.`]: `Adresy URL w przypadku wszystkich operacji w interfejsie API zaczynają się od tej wartości.`,
  [`US Government Users Restricted Rights - Use, duplication or disclosure restricted by GSA ADP Schedule Contract with IBM Corp.`]: `Użytkowników z instytucji rządowych USA obowiązują warunki umowy GSA ADP Schedule Contract z IBM Corp.`,
  [`User Defined`]: `Zdefiniowane przez użytkownika`,
  [`Userinfo POST`]: `POST informacji o użytkowniku`,
  [`USER MANAGED`]: `UŻYTKOWNIK ZARZĄDZANY`,
  [`USERNAME`]: `NAZWA UŻYTKOWNIKA`,
  [`User can delete the keystore history.`]: `Użytkownik może usunąć historię magazynu kluczy.`,
  [`Unable to parse API definition: {msg}`]: `Nie można przeanalizować definicji API: {msg}`,
  [`Unable to proceed because of errors found when removing types and/or fields.`]: `Nie można kontynuować z powodu błędów napotkanych podczas usuwania typów i/lub pól.`,
  [`Unarchive`]: `Anuluj archiwizację`,
  [`Unassociate`]: `Anuluj powiązanie`,
  [`Unassociate analytics service`]: `Anuluj powiązanie usługi analizy danych`,
  [`Unauthorized`]: `Brak autoryzacji`,
  [`Unauthorized API Request`]: `Nieautoryzowane żądanie interfejsu API`,
  [`Unbound lists`]: `Niepowiązane listy`,
  [`Unit`]: `Jednostka`,
  [`Union`]: `Unia`,
  [`Union type`]: `Typ unii`,
  [`Unique email address`]: `Unikalny adres e-mail`,
  [`Unlimited`]: `Brak ograniczenia`,
  [`Unnecessary assumed size`]: `Niepotrzebna założona wielkość`,
  [`Unnecessary slicing arguments`]: `Niepotrzebne argumenty przekrojów`,
  [`Unrecognized Schema Element(s) Encountered`]: `Napotkano nierozpoznane elementy schematu`,
  [`Unsupported media type ({type})`]: `Nieobsługiwany typ danych multimedialnych ({type})`,
  [`Untitled`]: `Bez tytułu`,
  [`Update`]: `Aktualizuj`,
  [`Update gateway services`]: `Aktualizuj usługi bramy`,
  [`Update assembly`]: `Aktualizacja elementu złożonego`,
  [`Update group failed.`]: `Aktualizacja grupy nie powiodła się.`,
  [`Update schema defaults giving preference to`]: `Aktualizuj ustawienia domyślne schematu z preferencją dla`,
  [`Update the sender details that are displayed on invitation emails`]: `Aktualizacja szczegółów nadawcy, które są wyświetlane w wiadomościach e-mail z zaproszeniem`,
  [`Update the summary details for this role`]: `Zaktualizuj szczegóły podsumowania roli`,
  [`Updating Draft API`]: `Aktualizowanie wersji roboczej interfejsu API`,
  [`Updating schema defaults will override your changes. Are you sure you want to continue?`]: `Zaktualizowanie ustawień domyślnych schematu spowoduje przesłonięcie zmian. Czy na pewno chcesz kontynuować?`,
  [`Updating API of type {existingType} with API of type {type} is not allowed.`]: `Zmiana interfejsu API typu {existingType} na interfejs API typu {type} nie jest dozwolona.`,
  [`Upload`]: `Prześlij`,
  [`Upload a schema definition language file to replace your schema.`]: `Prześlij plik w języku definicji schematów (SDL), aby zastąpić swój schemat.`,
  [`Upload GraphQL schema`]: `Prześlij schemat GraphQL`,
  [`Upload local`]: `Prześlij lokalnie`,
  [`Upload policies`]: `Prześlij strategie`,
  [`Upload policy`]: `Prześlij strategię`,
  [`Upload schema`]: `Prześlij schemat`,
  [`Upload schema definition language (SDL) file`]: `Prześlij plik w języku definicji schematów (SDL)`,
  [`Upload truststore certificates`]: `Prześlij certyfikaty magazynu zaufanych certyfikatów`,
  [`Upload the X509 certificate for your application in PEM format. You can only upload one PEM file but can include multiple certificates in a single file.`]: `Prześlij certyfikat X509 dla aplikacji w formacie PEM. Można przesłać tylko jeden plik PEM, ale może on zawierać wiele certyfikatów.`,
  [`Upload the X509 certificate for your application, in PEM format`]: `Prześlij certyfikat X509 dla aplikacji w formacie PEM`,
  [`Upload your public certificate`]: `Prześlij certyfikat publiczny`,
  [`Uploaded and validated YAML file`]: `Przesłano plik YAML i sprawdzono jego poprawność`,
  [`Upload extension`]: `Prześlij rozszerzenie`,
  [`Use all compatible gateway services`]: `Użyj wszystkich kompatybilnych usług bramy`,
  [`Use Content Type`]: `Użyj typu treści`,
  [`Use DataPower API Gateway`]: `Użyj bramy interfejsu API DataPower`,
  [`Use DataPower Gateway`]: `Użyj bramy DataPower`,
  [`Use Dynamic DNS`]: `Używaj dynamicznego DNS`,
  [`Use JSONata to specify the property to redact or remove`]: `Użyj wyrażenia JSONata, aby określić właściwość do zredagowania lub usunięcia`,
  [`Use XPath to specify the property to redact or remove.`]: `Użyj wyrażenia XPath, aby określić właściwość do zredagowania lub usunięcia.`,
  [`Use a selected catalog as a default catalog you want to publish to`]: `Użyj wybranego katalogu jako katalogu domyślnego, który ma być używany do publikowania`,
  [`Use an existing JSON or YAML definition of the product`]: `Użyj istniejącej definicji JSON lub YAML produktu`,
  [`Use an existing definition of a REST proxy`]: `Użyj istniejącej definicji proxy usług REST`,
  [`Use an existing definition of a REST proxy or SOAP API`]: `Użyj istniejącej definicji proxy usług REST lub interfejsu API SOAP`,
  [`Use an existing definition of a REST proxy, GraphQL proxy, or SOAP API`]: `Użyj istniejącej definicji proxy usług REST, proxy GraphQL lub interfejsu API SOAP`,
  [`Use an existing definition of a product`]: `Użyj istniejącej definicji produktu`,
  [`Use context current payload`]: `Użyj kontekstu bieżącego ładunku`,
  [`Use customized catalog endpoint URL (v5 compatibility)`]: `Użyj dostosowanego adresu URL punktu końcowego katalogu (kompatybilność z wersją 5)`,
  [`Use dynamic DNS`]: `Używaj dynamicznego DNS`,
  [`Use endpoint from API`]: `Użyj punktu końcowego z interfejsu API`,
  [`Use gateway URLs`]: `Używaj adresów URL bramy`,
  [`Use gateway service configured OAuth shared secret key`]: `Użyj współużytkowanych danych niejawnych OAuth skonfigurowanych przez usługę bramy`,
  [`Use group authentication`]: `Użyj uwierzytelniania grupowego`,
  [`Use only first array element`]: `Używaj tylko pierwszego elementu tablicy`,
  [`Use static DNS`]: `Używaj statycznego DNS`,
  [`Use portal as endpoint for external OIDC provider traffic`]: `Użyj portalu jako punktu końcowego dla ruchu zewnętrznego dostawcy OIDC`,
  [`Use the API Explorer to test and explore your APIs. Explorer shows the operations, definitions, and documentation for all of the APIs that are contained in your project directory. Explorer lists the parameters, model instance data, and response codes together with template code for running your API.`]: `Użyj eksploratora API do testowania i eksplorowania interfejsów API. Eksplorator wyświetla operacje, definicje i dokumentację dla wszystkich interfejsów API znajdujących się w katalogu projektu. Wyświetla też parametry, dane instancji modelu i kody odpowiedzi wraz z kodem szablonu na potrzeby uruchamiania interfejsu API.`,
  [`Use the actions menu to manage and publish your APIs.`]: `Użyj menu działań do zarządzania interfejsami API i ich publikowania.`,
  [`Use the actions menu to manage and publish your products`]: `Użyj menu działań do zarządzania produktami i ich publikowania.`,
  [`Use the activity-log policy to configure your logging preferences for the API activity that is stored in Analytics. The preferences that you specify will override the default settings for collecting and storing details of the API activity.`]: `Użyj strategii dziennika działań, aby skonfigurować preferencje rejestrowania dla działania interfejsu API, które jest przechowywane w analizie danych. Określane przez użytkownika preferencje nadpisują ustawienia domyślne gromadzenia i zapisywania szczegółów działania interfejsu API.`,
  [`Use the client security policy to extract and authenticate the clients credentials.`]: `Użyj strategii zabezpieczeń klienta, aby wyodrębnić i uwierzytelnić referencje klienta.`,
  [`Use the default built-in Sandbox Catalog`]: `Użyj domyślnego wbudowanego katalogu przestrzeni prywatnej`,
  [`Use the editor for manual input or upload a file below.`]: `Wprowadź ręcznie za pomocą edytora lub prześlij plik poniżej.`,
  [`Use the editor for manual input or upload a file below. The current schema can only be edited in XML if an XML/XSD file is defined.`]: `Wprowadź ręcznie za pomocą edytora lub prześlij plik poniżej. Bieżący schemat można edytować w języku XML tylko wtedy, gdy jest zdefiniowany plik XML/XSD.`,
  [`Use the enforced extension to specify if the API Connect gateway is used to enforce the API`]: `Użyj wymuszanego rozszerzenia, aby określić, czy brama API Connect ma być używana do wymuszania interfejsu API`,
  [`Use the following Management Endpoint URL when working with the CLI.`]: `Używaj następującego adresu URL punktu końcowego zarządzania podczas pracy z interfejsem CLI.`,
  [`Use the following Management Endpoint URL when creating a cloud connection in API Designer.`]: `Użyj następującego adresu URL punktu końcowego podczas tworzenia połączenia z chmurą w komponencie API Designer.`,
  [`Use the gatewayscript policy to execute a specified DataPower GatewayScript program.`]: `Użyj strategii gatewayscript, aby wykonać określony program DataPower GatewayScript.`,
  [`Use the graphql-introspect policy to introspect a GraphQL schema.`]: `Użyj strategii graphql-introspect do introspekcji schematu GraphQL.`,
  [`Use the host value from Open API Definition`]: `Użyj wartości hosta z definicji interfejsu Open API`,
  [`Use the if policy to apply a section of the assembly when a condition is fulfilled.`]: `Użyj strategii if, aby zastosować sekcję zespołu, gdy warunek jest spełniony.`,
  [`Use the javascript policy to execute a specified JavaScript program.`]: `Użyj strategii javascript, aby wykonać określony program JavaScript.`,
  [`Use the json-to-xml policy to convert the content of your payload from JSON to XML.`]: `Użyj strategii json-to-xml, aby przekształcić treść ładunku z formatu JSON do formatu XML.`,
  [`Use the Log policy to customize or override the default activity logging configuration for an API.`]: `Użyj strategii Log, aby zmodyfikować lub przesłonić domyślną konfigurację rejestrowania aktywności interfejsu API.`,
  [`Use the Map policy to apply transformations to your assembly flow and specify relationships between variables.`]: `Użyj strategii Map, aby zastosować transformacje do przepływu zespołu i określić relacje między zmiennymi.`,
  [`Use the map policy to specify the relationships between variables within your API flow, and to apply transformations to these variables.`]: `Użyj strategii odwzorowania, aby określić relacje między zmiennymi w przepływie interfejsu API, a następnie zastosować transformacje do tych zmiennych.`,
  [`Use the oauth policy to generate OAuth tokens.`]: `Użyj strategii OAuth w celu wygenerowania tokenów OAuth.`,
  [`Use the Operation switch policy to apply a section of the assembly to a specific operation.`]: `Użyj strategii Przełącznik operacji, aby zastosować sekcję zespołu do konkretnej operacji.`,
  [`Use the parse policy to parse a request or a message as XML or JSON, or parse binary data into a binary large object (BLOB).`]: `Strategia analizowania składni służy do analizowania żądania lub komunikatu w formacie XML lub JSON albo analizowania danych binarnych do dużego obiektu binarnego (BLOB).`,
  [`Use the set-variable policy to set a runtime variable to a string value, or to add or clear a runtime variable.`]: `Użyj strategii ustawiania zmiennej, aby ustawić zmienną czasu wykonania na wartość łańcuchową albo dodać lub skasować zmienną środowiska wykonawczego.`,
  [`Use the throw policy to throw an error when it is reached during the execution of an assembly flow.`]: `Użyj strategii throw, aby zgłosić błąd, gdy ten punkt zostanie osiągnięty podczas wykonywania przepływu zespołu.`,
  [`Use the user security policy to extract, authenticate, and authorize the user's identity.`]: `Użyj strategii bezpieczeństwa użytkownika w celu wyodrębnienia, uwierzytelnienia i autoryzowania tożsamości użytkownika.`,
  [`Use the validate policy with a DataPower Gateway to validate the payload in an assembly flow against a JSON or XML schema.`]: `Użyj strategii sprawdzania poprawności z bramą DataPower w celu sprawdzenia poprawności ładunku w przepływie zespołu w odniesieniu do schematu JSON lub XML.`,
  [`Use the xml-to-json policy to convert the content of your payload from XML to JSON.`]: `Użyj strategii xml-to-json, aby przekształcić treść ładunku z formatu XML do formatu JSON.`,
  [`Use the xslt policy to apply an XSLT transform to the payload.`]: `Użyj strategii xslt, aby zastosować transformację XSLT do ładunku.`,
  [`Used to enable/disable Content-Encoding compression on upload.`]: `Służy do włączania/wyłączania kompresji Content-Encoding podczas przesyłania.`,
  [`User`]: `Użytkownik`,
  [`User group prefix`]: `Przedrostek grupy użytkowników`,
  [`User group suffix`]: `Przyrostek grupy użytkowników`,
  [`User mapping`]: `Odwzorowanie użytkowników`,
  [`User Registries`]: `Rejestry użytkowników`,
  [`User registries`]: `Rejestry użytkowników`,
  [`User Registry Name`]: `Nazwa rejestru użytkowników`,
  [`User registry name`]: `Nazwa rejestru użytkowników`,
  [`User Registry cannot be deleted`]: `Nie można usunąć rejestru użytkowników`,
  [`User Registry "{name}" has been deleted`]: `Rejestr użytkowników "{name}" został usunięty`,
  [`User security`]: `Zabezpieczenia użytkowników`,
  [`User registries are managed in each space when spaces are enabled. Please go to the space settings to manage user registries.`]: `Gdy obszary są włączone, rejestrami użytkowników zarządza się w poszczególnych obszarach. Przejdź do ustawień obszarów, aby zarządzać rejestrami użytkowników.`,
  [`User registries defined for consumer onboarding`]: `Rejestry użytkowników zdefiniowane na potrzeby rejestrowania konsumentów`,
  [`User registries defined for consumer onboarding\n\nEvery account in the Developer Portal, including across different user registries for the same site, must have a unique email address, including the site Admin account.`]: `Rejestry użytkowników zdefiniowane na potrzeby rejestrowania konsumentów\n\nKażde konto w portalu Developer Portal, w tym w różnych rejestrach użytkowników dla tego samego ośrodka, musi mieć unikatowy adres e-mail (dotyczy to także konta Administratora portalu).`,
  [`User registry`]: `Rejestr użytkowników`,
  [`User registry configuration has been updated.`]: `Konfiguracja rejestru użytkowników została zaktualizowana.`,
  [`User registry has been created`]: `Rejestr użytkowników został utworzony.`,
  [`User registry {title} deleted.`]: `Rejestr użytkowników {title} został usunięty.`,
  [`User registry {title} has been created.`]: `Rejestr użytkowników {title} został utworzony.`,
  [`User registry is not visible or not available.`]: `Rejestr użytkowników jest niewidoczny lub niedostępny.`,
  [`User registry {title} has been updated.`]: `Rejestr użytkowników {title} został zaktualizowany.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password.`]: `Ustawienia zabezpieczeń użytkownika są stosowane tylko wtedy, gdy wybrane typy uprawnień obejmują jedno lub więcej z następujących: Niejawne, Kod dostępu i Właściciel zasobu - hasło.`,
  [`User security settings are applicable only if your selected grant types include one or more of the following: Implicit, Access code, and Resource owner - Password. If you make any changes on this page, you should also select, on the Configuration page, at least one of these grant types.`]: `Ustawienia zabezpieczeń użytkownika są stosowane tylko wtedy, gdy wybrane typy uprawnień obejmują jedno lub więcej z następujących: Niejawne, Kod dostępu i Właściciel zasobu - hasło. Jeśli na tej stronie zostaną wprowadzone jakiekolwiek zmiany, należy także wybrać co najmniej jeden z tych typów nadania na stronie Konfiguracja.`,
  [`UserInfo endpoint`]: `Punkt końcowy UserInfo`,
  [`UserInfo URL`]: `Adres URL informacji o użytkowniku`,
  [`Username`]: `Nazwa użytkownika`,
  [`Username / Email`]: `Nazwa użytkownika / adres e-mail`,
  [`Username and password fields are required in order to test configuration`]: `Aby przetestować konfigurację, należy podać wartości w polach Nazwa użytkownika i Hasło.`,
  [`Username context variable`]: `Zmienna kontekstowa nazwy użytkownika`,
  [`Username of the new user`]: `Nazwa nowego użytkownika`,
  [`Username should not contain special characters!`]: `Nazwa użytkownika nie powinna zawierać znaków specjalnych!`,
  [`Username should not include spaces!`]: `Nazwa użytkownika nie powinna zawierać spacji!`,
  [`Users belonging to a consumer organization have access to the developer portal and its published API products, as determined by their visibility and subscribability.`]: `Użytkownicy należący do organizacji konsumenta mają dostęp do portalu Developer Portal i opublikowanych w nim produktów interfejsów API, zgodnie z ich ustawieniami widoczności i możliwościami subskrypcji.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Certificate Manager service.`]: `Korzystając z systemu [IBM Cloud IAM]({link}), upoważnij usługę API Connect Reserved Instance do dostępu do usługi Certificate Manager.`,
  [`Using HTTP POST method when contacting Userinfo endpoint`]: `Używanie metody HTTP POST w połączeniach z punktem końcowym informacji o użytkowniku`,
  [`Use IBM APIC token expiration setting from the cloud`]: `Użyj ustawienia utraty ważności tokenu IBM APIC z chmury`,
  [`Update API`]: `Aktualizacja interfejsu API`,
  [`VALUE`]: `WARTOŚĆ`,
  [`Version`]: `Wersja`,
  [`Visible to`]: `Widoczność`,
  [`Validate`]: `Sprawdź poprawność`,
  [`Validate message body`]: `Sprawdzanie poprawności treści wiadomości`,
  [`Validate message headers`]: `Sprawdzanie poprawności nagłówków wiadomości`,
  [`Validate message fault details`]: `Sprawdzanie poprawności szczegółów błędu w wiadomości`,
  [`Validate SOAP 1.1 encoding rule`]: `Sprawdzanie zgodności z regułą kodowania SOAP 1.1`,
  [`Validate JSON Web Token (JWT)`]: `Sprawdź poprawność tokenu WWW JSON (JWT)`,
  [`Validate JWT`]: `Sprawdź poprawność znacznika JWT`,
  [`Validate Username Token`]: `Sprawdź poprawność tokenu nazwy użytkownika`,
  [`Validate a Web Services Security UsernameToken payload by using Authentication URL or LDAP user registry.`]: `Sprawdź poprawność ładunku tokenu UsernameToken zabezpieczeń usług WWW przy użyciu adresu URL uwierzytelniania lub rejestru użytkowników LDAP.`,
  [`Validate against`]: `Sprawdź poprawność względem`,
  [`Validate request`]: `Sprawdź poprawność żądania`,
  [`Validate the GraphQL query and analyze the expected cost`]: `Sprawdź poprawność zapytania GraphQL i przeanalizuj spodziewany koszt`,
  [`Validate the returned JSON and analyze the real cost`]: `Sprawdź poprawność zwróconego kodu JSON i przeanalizuj spodziewany koszt`,
  [`Validating API ...`]: `Sprawdzanie poprawności interfejsu API...`,
  [`Validating Draft API`]: `Sprawdzanie poprawności wersji roboczej interfejsu API`,
  [`Validity Period`]: `Okres ważności`,
  [`Value`]: `Wartość`,
  [`Value for located in must be selected.`]: `Należy wybrać wartość położenia.`,
  [`Value for the variable to be added.`]: `Wartość zmiennej, która ma zostać dodana.`,
  [`Value for type must be selected.`]: `Należy wybrać wartość typu.`,
  [`Value of "weight" argument in "@cost" directive is < 0.`]: `Wartość argumentu "weight" w dyrektywie "@cost" jest < 0.`,
  [`Values`]: `Wartości`,
  [`Vanity API endpoint`]: `Niestandardowy punkt końcowy interfejsu API`,
  [`Variable name`]: `Nazwa zmiennej`,
  [`Variable value`]: `Wartość zmiennej`,
  [`Vendor Extensions`]: `Rozszerzenia dostawcy`,
  [`Verb`]: `Komenda`,
  [`Verify`]: `Weryfikuj`,
  [`Verify Access Token`]: `Weryfikuj token dostępu`,
  [`Verify Crypto JWK variable name`]: `Nazwa szyfrującej zmiennej JWK używanej do weryfikowania`,
  [`Verify Crypto Object`]: `Obiekt szyfrujący używany do weryfikowania`,
  [`Verify Token`]: `Weryfikuj token`,
  [`Verify authorization code`]: `Sprawdź kod autoryzacji`,
  [`Verify refresh token`]: `Weryfikuj token odświeżania`,
  [`Version of the LTPA token.`]: `Wersja tokenu LTPA.`,
  [`Check 'apis' property under 'plans' in the imported product file. Each plan should have 'apis' property pointing to atleast one api from 'apis' section.`]: `Sprawdź właściwość 'apis' w sekcji 'plans' w zaimportowanym pliku produktu. Każdy plan powinien mieć właściwość 'apis' wskazującą przynajmniej jeden interfejs api z sekcji 'apis'.`,
  [`View`]: `Wyświetl`,
  [`View extensions`]: `Wyświetl rozszerzenia`,
  [`View Forum`]: `Wyświetl forum`,
  [`View owners`]: `Wyświetl właścicieli`,
  [`View policies`]: `Wyświetl strategie`,
  [`View amd manage the ciphers available for the selected protocol version.`]: `Wyświetlanie szyfrów dostępnych dla wybranej wersji protokołu i zarządzanie nimi.`,
  [`View analytics`]: `Wyświetl analizę`,
  [`View and edit permissions for the roles in this space`]: `Wyświetlanie i edytowanie uprawnień ról w danym obszarze`,
  [`View and edit the permissions for the roles in the current organization`]: `Wyświetlanie i edytowanie uprawnień ról w bieżącej organizacji`,
  [`View and edit the roles contained in the role defaults for consumer organizations`]: `Wyświetlanie i edycja ról zawartych w wartościach domyślnych ról dla organizacji konsumentów`,
  [`View and edit the roles contained in the role defaults for provider organizations`]: `Wyświetlanie i edycja ról zawartych w wartościach domyślnych ról dla organizacji dostawców`,
  [`View approval history`]: `Wyświetl historię zatwierdzania`,
  [`View Approval History`]: `Wyświetl historię zatwierdzania`,
  [`View documentation and tutorials with step-by-step instructions.`]: `Zapoznaj się z dokumentacją i kursami zawierającymi instrukcje krok po kroku.`,
  [`View error ID`]: `Wyświetl identyfikator błędu`,
  [`View status`]: `Wyświetl status`,
  [`View more`]: `Wyświetl więcej`,
  [`View source`]: `Wyświetl źródło`,
  [`View subscription`]: `Wyświetl subskrypcję`,
  [`View template in`]: `Wyświetl szablon w następującym języku`,
  [`View the endpoints for accessing API Connect user interfaces.`]: `Wyświetl punkty końcowe umożliwiające uzyskiwanie dostępu do interfejsów użytkownika produktu API Connect.`,
  [`View the endpoints for the admin and provider REST APIs.`]: `Wyświetl punkty końcowe dla interfejsów REST API administratora i dostawcy.`,
  [`View the endpoints for the consumer REST APIs.`]: `Wyświetl punkty końcowe dla interfejsów REST API konsumenta.`,
  [`Viewer`]: `Przeglądający`,
  [`Views the API consumer organization`]: `Przegląda organizację konsumenta interfejsu API`,
  [`Views the API provider organization`]: `Przegląda organizację dostawcy interfejsu API`,
  [`Views the admin organization`]: `Przegląda organizację administracyjną`,
  [`Views the app developer organization`]: `Przegląda organizację twórców aplikacji`,
  [`Views the catalog`]: `Przegląda katalog`,
  [`Views the space`]: `Wyświetla obszar`,
  [`View|permission`]: `Wyświetl`,
  [`Visibility`]: `Widoczność`,
  [`Visibility options`]: `Opcja widoczności`,
  [`VMware`]: `VMware`,
  [`VMware docs`]: `Dokumentacja VMWare`,
  [`WS`]: `WS`,
  [`WSDL URL`]: `Adres URL pliku WSDL`,
  [`WSDL has been successfully validated`]: `Pomyślnie sprawdzono poprawność pliku WSDL`,
  [`WSDL to REST`]: `WSDL do REST`,
  [`WSS`]: `WSS`,
  [`WS-I Basic Profile validation`]: `Sprawdzanie zgodności z WS-I Basic Profile`,
  [`Warn`]: `Ostrzeżenie`,
  [`Warning`]: `Ostrzeżenie`,
  [`Warning:`]: `Ostrzeżenie:`,
  [`Warnings`]: `Ostrzeżenia`,
  [`We just emailed recipients a test message:`]: `Właśnie przesłaliśmy do odbiorców wiadomość testową:`,
  [`We're moving it to the "Test" tab, where you'll be able to test and debug your API.`]: `Przenosimy go na kartę Test, na której będzie można przetestować i zdebugować interfejs API.`,
  [`Weather Product`]: `Produkt pogodowy`,
  [`Web Endpoint`]: `Punkt końcowy WWW`,
  [`Web service operations`]: `Operacje usługi WWW`,
  [`weight`]: `Waga`,
  [`Weight`]: `Waga`,
  [`Welcome to API Connect Administration`]: `Witamy w programie API Connect Administration`,
  [`Welcome to API Connect Reserved`]: `Witamy w programie API Connect Reserved`,
  [`Welcome to API Designer`]: `Witamy w programie API Designer`,
  [`Welcome to API Manager`]: `Witamy w programie API Manager`,
  [`Welcome to Cloud Manager`]: `Witamy w programie Cloud Manager`,
  [`Welcome to the API Designer`]: `Witamy w programie API Designer`,
  [`Welcome to the API Manager`]: `Witamy w programie API Manager`,
  [`Welcome to the Cloud Manager`]: `Witamy w programie Cloud Manager`,
  [`What are you looking for today?`]: `Czego dzisiaj szukasz?`,
  [`What's new`]: `Co nowego`,
  [`What's next? Enable your billing integration in a catalog to allow users to add plans to their products.`]: `Co dalej? Włącz integrację rozliczeń w katalogu, aby umożliwić użytkownikom dodawanie planów do ich produktów.`,
  [`When it is set to true, the invoke policy will inject the "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" headers to the request send to the target URL.`]: `Jeśli ta właściwość ma wartość true, strategia wywoływania będzie wstawiała nagłówki "X-Forwarded-For", "X-Forwarded-To", "X-Forwarded-Host", "X-Forwarded-Proto" do żądania wysłanego do docelowego adresu URL.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers.`]: `Po opublikowaniu produkt interfejsu API staje się widoczny w portalu Developer Portal powiązanym z danym katalogiem. Punkty końcowe dla interfejsów API w produkcie zostają udostępnione w bramach powiązanych z docelowym katalogiem lub obszarem. Przemieszczane produkty są obecne w katalogu, ale nie są dostępne ani widoczne dla konsumentów.`,
  [`When published, an API product becomes visible in the developer portal associated with the catalog. Endpoints for APIs in the product are made available on gateways associated with the targeted catalog or space. Staged products are present in the catalog but are not live or visible to consumers. Please note that re-staging / re-publishing a product in a non-production catalog will remove existing subscriptions.`]: `Po opublikowaniu produkt interfejsu API staje się widoczny w portalu Developer Portal powiązanym z danym katalogiem. Punkty końcowe dla interfejsów API w produkcie zostają udostępnione w bramach powiązanych z docelowym katalogiem lub obszarem. Przemieszczane produkty są obecne w katalogu, ale nie są dostępne ani widoczne dla konsumentów. Należy pamiętać, że ponowne przemieszczenie lub opublikowanie produktu w katalogu nieprodukcyjnym spowoduje usunięcie istniejących subskrypcji.`,
  [`When spaces are enabled for a catalog the configured OAuth provider must be viewed from a space.`]: `Jeśli dla katalogu włączone są obszary, skonfigurowanego dostawcę OAuth należy wyświetlać z obszaru.`,
  [`When spaces are enabled, existing products in the catalog will be moved to a default space.`]: `Jeśli obszary zostaną włączone, produkty istniejące w katalogu zostaną przeniesione do obszaru domyślnego.`,
  [`When the user clicks the activation link, they are asked to complete the registration form. After registering, the user can log in.`]: `Gdy użytkownik kliknie odsyłacz aktywacyjny, zostanie poproszony o wypełnienie formularza rejestracji. Po zarejestrowaniu użytkownik może się zalogować.`,
  [`When you add a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Po dodaniu roli dla organizacji dostawców lub konsumentów zostaje ona dołączona do wartości domyślnej roli. Wartości domyślne ról są wstępnie skonfigurowanymi szablonami ról używanymi do określania domyślnych ról, które są dostępne podczas tworzenia nowej organizacji.`,
  [`When you deprecate a product, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.\n\n [Learn more]({link})`]: `Po ustawieniu produktu jako nieaktualnego twórcy aplikacji, którzy subskrybowali produkt, nadal będą mogli go używać, ale nowi programiści nie będą mogli subskrybować tego produktu.\n\n [Więcej informacji]({link})`,
  [`When you edit a role for provider or consumer organizations, it will be included in the role default. Role defaults are pre-configured role templates used to determine the default roles that are available when a new organization is created.`]: `Po zmodyfikowaniu roli dla organizacji dostawców lub konsumentów zostaje ona dołączona do wartości domyślnej roli. Wartości domyślne ról są wstępnie skonfigurowanymi szablonami ról używanymi do określania domyślnych ról, które są dostępne podczas tworzenia nowej organizacji.`,
  [`When you enable OpenID Connect, a template is provided for generating ID tokens along with access tokens and the required assembly policies are automatically created. You can customize the policies to suit your needs. The sample key is for test purposes only and is used to sign the JWT token.`]: `Po włączeniu protokołu OpenID Connect udostępniony zostaje szablon umożliwiający generowanie tokenów identyfikatora razem z tokenami dostępu. Ponadto automatycznie tworzone są wymagane strategie składania. Strategie te można dostosować do własnych potrzeb. Przykładowy klucz służy tylko do celów testowych i jest używany do podpisywania tokenu JWT.`,
  [`When you enable token introspection, access token can be examined through the introspection path.`]: `Po włączeniu introspekcji token dostępu można sprawdzić za pomocą ścieżki introspekcji.`,
  [`When you enable token introspection, the holders of access tokens can examine the contents of tokens by using an introspection path. The access token to introspect must be obtained through the native OAuth provider.`]: `Po włączeniu introspekcji znaczników posiadacze tokenów dostępu mogą sprawdzać zawartość tokenów przy użyciu ścieżki introspekcji. Token dostępu do introspekcji musi zostać uzyskany za pośrednictwem własnego dostawcy OAuth.`,
  [`When you invite a user to be a member of a provider organization, you assign them one or more roles to control their level of access to information, and the tasks that they can perform.`]: `Zapraszając użytkownika, aby został członkiem organizacji dostawcy, przypisuje się mu jedną lub więcej ról, które określają jego poziom dostępu do informacji oraz zadania, które może wykonywać.`,
  [`When you manage your product versions, you move them through a series of lifecycle states, from initially staging a product version to a catalog, through to publishing to make the product version available to your application developers, and to eventual retiring and archiving.`]: `Zarządzania wersjami produktu polega na przenoszeniu ich między kolejnymi stanami cyklu życia od początkowego przemieszczenia wersji produktu do katalogu, przez jego opublikowanie w celu udostępnienia twórcom aplikacji, aż do końcowego wycofania i zarchiwizowania.`,
  [`When you replace a product with another product, the following actions are taken: 1) The replacement product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original product are used in the replacement product, 3) The subscribers to the original product are migrated to the replacement product.`]: `Podczas zastępowania produktu wykonywane są następujące działania: 1) produkt zastępujący jest publikowany; 2) ustawienia widoczności, subskrybenta i wymuszania przez bramę z produktu oryginalnego są stosowane w produkcie zastępującym; 3) subskrybenci oryginalnego produktu są przenoszeni do produktu zastępującego.`,
  [`When you replace a product with another product, the following actions are taken: 1) the replacement product assumes the state of the original product, 2) If the visibility and subscribability settings in the replacement product are such that access is the same as, or less restrictive than, the original product, the settings in the replacement product are used. If the settings in the replacement product are more restrictive and would prevent some consumer organizations with access to the original product from accessing the replacement, the replace operation cannot be completed. 3) the subscribers to the original product are migrated to the replacement product, and (4) the original product is retired.`]: `W przypadku zastąpienia produktu innym produktem wykonywane są następujące działania: 1) produkt zastępujący przyjmuje stan oryginalnego produktu; 2) jeśli ustawienia widoczności i możliwości subskrybowania w produkcie zastępującym są takie, że dostęp jest taki sam lub mniej restrykcyjny niż w przypadku oryginalnego produktu, używane są ustawienia produktu zastępującego; jeśli ustawienia w produkcie zastępującym są bardziej restrykcyjne i uniemożliwiałyby niektórym organizacjom konsumentów mającym dostęp do oryginalnego produktu dostęp do produktu zastępującego, operacja zastąpienia nie może zostać wykonana; 3) subskrybenty oryginalnego produktu są migrowane do produktu zastępującego, a (4) oryginalny produkt jest wycofany.`,
  [`When you retire a product, all associated APIs are taken offline, and any subscriptions become inactive.\n\n [Learn more]({link})`]: `Wycofanie produktu oznacza przełączenie wszystkich powiązanych interfejsów API w tryb bez połączenia. Wszystkie subskrypcje staną się nieaktywne.\n\n [Więcej informacji]({link})`,
  [`When you supersede a product with another product, the following actions are taken: 1) The superseding product is published, 2) The same visibility, subscriber, and gateway enforcement settings from the original Product are used for the superseding product, 3) The original product is moved to the deprecated state. When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product. A deprecated product can be published again if required.`]: `Podczas zastępowania produktu nowszą wersją wykonywane są następujące działania: 1) produkt zastępujący jest publikowany; 2) ustawienia widoczności, subskrybenta i wymuszania przez bramę z produktu oryginalnego są stosowane w produkcie zastępującym; 3) oryginalny produkt jest ustawiany jako nieaktualny. Po ustawieniu produktu jako nieaktualnego programiści aplikacji, którzy subskrybowali produkt, nadal będą mogli go używać, ale nowi programiści nie będą mogli subskrybować tego produktu. Nieaktualny produkt można w razie potrzeby ponownie opublikować.`,
  [`When you supersede a product with another product, the following actions are taken: 1) the superseding product is published, 2) the visibility and subscribeability settings from the original product are used in the superseding product, and 3) the original product is moved to the deprecated state.  When a product is deprecated, application developers that are already subscribed to the product can continue to use it, but no new developers can subscribe to the product.  A deprecated product can be re-published if required.`]: `Podczas zastępowania produktu nowszą wersją wykonywane są następujące działania: 1) produkt zastępujący jest publikowany; 2) ustawienia widoczności i możliwości subskrypcji oryginalnego produktu są stosowane w produkcie zastępującym; 3) oryginalny produkt jest ustawiany jako nieaktualny.  Po ustawieniu produktu jako nieaktualnego programiści aplikacji, którzy subskrybowali produkt, nadal będą mogli go używać, ale nowi programiści nie będą mogli subskrybować tego produktu.  Nieaktualny produkt można w razie potrzeby ponownie opublikować.`,
  [`When you suspend an application, the associated client IDs are blocked from making API calls. You can resume the application at any time.`]: `Zawieszenie aplikacji powoduje, że powiązane z nią identyfikatory klientów nie mogą wywoływać interfejsów API. Działanie aplikacji można wznowić w dowolnym momencie.`,
  [`When enabled, all consumer organizations invitation timeout will be set to the catalog invitation timeout and any update to catalog invitation timeout will update the consumer organizations invitation timeout.`]: `Włączenie tej opcji spowoduje ustawienie limitu czasu zaproszenia dla wszystkich organizacji konsumentów na limit czasu zaproszenia katalogu, a każda aktualizacja limitu czasu zaproszenia katalogu będzie powodować aktualizację limitu czasu zaproszenia dla organizacji konsumenta.`,
  [`Where did the roles go?`]: `Gdzie znajdują się role?`,
  [`Where to find the named rate limit.`]: `Gdzie znaleźć limit częstotliwości o tej nazwie.`,
  [`Where to put the decrypted result`]: `Miejsce, w którym należy umieścić zdeszyfrowany wynik`,
  [`Where to put the encrypted result`]: `Miejsce, w którym należy umieścić zaszyfrowany wynik`,
  [`Whether to reuse the connection to the target server.`]: `Czy ponownie używać połączenia z serwerem docelowym.`,
  [`Windows live`]: `Windows live`,
  [`With`]: `Z`,
  [`Wildcards ignore xsi:type rule`]: `Symbole wieloznaczne ignorują regułę xsi:type`,
  [`Work offline`]: `Pracuj w trybie bez połączenia`,
  [`Work with existing definitions, models and/or data sources`]: `Praca z istniejącymi definicjami, modelami i/lub źródłami danych`,
  [`Working...`]: `W toku...`,
  [`Wrapper Policies`]: `Strategie opakowań`,
  [`Wrong slicing arguments`]: `Nieprawidłowe argumenty przekrojów`,
  [`WSDL and XSDs should be inside its own zip file within the zip file you are trying to import.`]: `Pliki WSDL i XSD powinny znajdować się w swoim własnym pliku zip wewnątrz pliku zip, który próbujesz zaimportować.`,
  [`XML Schema`]: `Schemat XML`,
  [`XML Schema URL`]: `Adres URL schematu XML`,
  [`XML Schema preview`]: `Podgląd schematu XML`,
  [`XML Validation Mode`]: `Tryb sprawdzania poprawności XML`,
  [`XML schema is not valid. Please correct the error.`]: `Schemat XML jest niepoprawny. Popraw błąd.`,
  [`XML to JSON`]: `XML do JSON`,
  [`XSLT`]: `XSLT`,
  [`XSLT version`]: `Wersja XSLT`,
  [`YAML has been successfully validated`]: `Pomyślnie sprawdzono poprawność danych YAML`,
  [`Yes`]: `Tak`,
  [`Yes, enable it`]: `Tak, włącz`,
  [`Yes, reset`]: `Tak, zresetuj`,
  [`Yes. Update API Assembly`]: `Tak. Aktualizuj zespół interfejsu API`,
  [`You are authenticated!`]: `Użytkownik został uwierzytelniony!`,
  [`You do not have permission to use the Test Application`]: `Nie masz uprawnień do korzystania z aplikacji testowej`,
  [`You can also copy the link and send it directly to the user.`]: `Można także skopiować odsyłacz i wysłać go bezpośrednio do użytkownika.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls over a given time interval.`]: `Można nie publikować interfejsu API w tym momencie lub można opublikować go natychmiast w punktach końcowych testowania, produkcji lub w punktach obu rodzajów. Opcjonalnie można określić maksymalne dozwolone tempo wywołań interfejsu API na podstawie liczby wywołań w danym przedziale czasu.`,
  [`You can choose not publish the API at this time, or you can publish immediately to eiher your test or production endpoints or both. Optionally, you can specify a maximum allowed API call rate based on a number of calls per specified time interval.`]: `Można nie publikować interfejsu API w tym momencie lub można opublikować go natychmiast w punktach końcowych testowania, produkcji lub w punktach obu rodzajów. Opcjonalnie można określić maksymalne dozwolone tempo wywołań interfejsu API na podstawie liczby wywołań w danym przedziale czasu.`,
  [`You can choose to leave the API unsecured at this time, or you can require that a calling application must suuply a client ID. You can configure further security options after you have created the API definition.`]: `Można pozostawić w tej chwili niezabezpieczony interfejs API lub można wymagać, aby aplikacja wywołująca podawała identyfikator klienta. Dodatkowe opcje zabezpieczeń można skonfigurować po utworzeniu definicji interfejsu API.`,
  [`You can input a catalog name or title. The catalog name will take precedence if both are available.`]: `Można wprowadzić nazwę lub tytuł katalogu. Nazwa katalogu będzie miała pierwszeństwo, jeśli obie wartości są dostępne.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in Notifications.`]: `Istnieje możliwość wysłania wiadomości e-mail do właściciela organizacji konsumenta. Wiadomość jest wysyłana z użyciem serwera poczty elektronicznej skonfigurowanego w opcjach powiadomień.`,
  [`You can send an email to a consumer organization owner. The message is sent by using the email server configured in the Notifications section.`]: `Istnieje możliwość wysłania wiadomości e-mail do właściciela organizacji konsumenta. Wiadomość jest wysyłana z użyciem serwera poczty elektronicznej skonfigurowanego w sekcji powiadomień.`,
  [`You can send an email to a provider organization owner.`]: `Istnieje możliwość wysłania wiadomości e-mail do właściciela organizacji dostawcy.`,
  [`You can transfer ownership to a new user by entering their email address. They will receive an email with an activation link to register. Once registered, the user will become the new owner. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Można przekazać prawo własności do nowego użytkownika, wpisując jego adres e-mail. Otrzyma on wiadomość e-mail z odsyłaczem aktywacyjnym umożliwiającym rejestrację. Po zarejestrowaniu użytkownik stanie się nowym właścicielem. Do bieżącego właściciela należy przypisać nowe role, aby mógł kontynuować pracę w programie Cloud Manager.`,
  [`You can transfer ownership to a registered user by entering their email address. Assign new roles to the current owner, so they may continue to work in Cloud Manager.`]: `Można przekazać prawo własności do zarejestrowanego użytkownika, wpisując jego adres e-mail. Do bieżącego właściciela należy przypisać nowe role, aby mógł kontynuować pracę w programie Cloud Manager.`,
  [`You can use an OpenAPI definition file to add a REST API in IBM API Connect. The format of the file can be JSON or YAML.  When the API definition has been imported, it is shown in the list of API definitions.`]: `Za pomocą pliku definicji OpenAPI można dodać interfejs REST API w produkcie IBM API Connect. Plik może mieć format JSON lub YAML.  Zaimportowana definicja interfejsu API jest wyświetlana na liście definicji interfejsów API.`,
  [`You cannot delete this type because it will remove the root type ('Query').`]: `Nie można usunąć tego typu, ponieważ spowoduje on usunięcie typu głównego ('Query').`,
  [`You cannot select an API whose gateway type does not match the gateway type of this Product.`]: `Nie można wybrać interfejsu API, którego typ bramy nie jest zgodny z typem bramy tego produktu.`,
  [`You currently don’t have any gateway extensions`]: `Obecnie nie masz żadnych rozszerzeń.`,
  [`You currently don’t have any plans to migrate`]: `Obecnie nie masz żadnych planów do zmigrowania`,
  [`You currently don't have any policy.`]: `Obecnie nie ma żadnej strategii.`,
  [`You do not have permission to any spaces.`]: `Użytkownik nie ma uprawnień do żadnych obszarów.`,
  [`You do not have permission to publish products to any catalog or space.`]: `Nie masz uprawnień do publikowania produktów w żadnym katalogu ani obszarze.`,
  [`You do not have permission to stage products to any catalog or space.`]: `Nie masz uprawnień do przemieszczania produktów do żadnego katalogu ani obszaru.`,
  [`You don’t currently have any billing integrations.`]: `Obecnie nie masz żadnych integracji rozliczeń.`,
  [`You don't currently have any response.`]: `Aktualnie nie masz żadnej odpowiedzi.`,
  [`You don't currently have any user-defined policies.`]: `Obecnie nie masz żadnych strategii zdefiniowanych przez użytkownika.`,
  [`You don't have permission to access this page.`]: `Nie masz uprawnień, aby uzyskać dostęp do tej strony.`,
  [`You have modified this certificate. Saving this application will update its configuration.`]: `Zmodyfikowano ten certyfikat. Zapisanie tej aplikacji spowoduje zaktualizowanie jej konfiguracji.`,
  [`You haven't added any APIs or Products`]: `Nie dodano żadnych interfejsów API ani produktów.`,
  [`You manage catalog membership by adding new users to the catalog and assigning roles to the users.`]: `Zarządzanie przynależnością do katalogu oznacza dodawanie nowych użytkowników do katalogu i przypisywanie ról do użytkowników.`,
  [`You manage space membership by adding new users to the Space and assigning roles to the users.`]: `Zarządzanie przynależnością do obszaru oznacza dodawanie nowych użytkowników do obszaru i przypisywanie ról do użytkowników.`,
  [`You manage space membership by adding new users to the space and assigning roles to the users.`]: `Zarządzanie przynależnością do obszaru oznacza dodawanie nowych użytkowników do obszaru i przypisywanie ról do użytkowników.`,
  [`You must add your API to a product ready for publishing. A product collects a set of APIs and plans into one offering that you make available to your developers. A plan includes rate limit settings that can be applied to the plan as a whole, or specified for each operation in an API.`]: `Należy dodać interfejs API do produktu gotowego do opublikowania. W produkcie zgromadzony jest zbiór interfejsów API i planów w postaci jednej oferty, która zostaje udostępniona programistom. Plan obejmuje ustawienia limitu częstotliwości, które można zastosować do planu jako całości lub określić dla poszczególnych operacji w interfejsie API.`,
  [`You must choose a definition`]: `Należy wybrać definicję`,
  [`You must enable at least one gateway service so that APIs published to this Catalog are available to be called at a gateway service endpoint. You can enable multiple gateway services.`]: `Musisz włączyć co najmniej jedną usługę bramy, aby interfejsy API opublikowane w tym katalogu były dostępne do wywołania w punkcie końcowym usługi bramy. Możesz włączyć wiele usług bramy.`,
  [`You must enable spaces`]: `Konieczne jest włączenie obszarów`,
  [`You should record the client ID and secret below for use when developing your application. The client secret cannot be displayed again.`]: `Należy zapisać identyfikator klienta i dane niejawne w celu użycia podczas programowania aplikacji. Danych niejawnych klienta nie można wyświetlić ponownie.`,
  [`Your API Connect Reserved Instance license entitles you to download and set up one or more remote DataPower API Gateways. The gateways can be located on on-premises or on any cloud.`]: `Licencja na API Connect Reserved Instance uprawnia do pobrania i skonfigurowania jednej lub większej liczby zdalnych bram DataPower API Gateway. Bramy mogą być wdrożone lokalnie lub w dowolnej chmurze.`,
  [`Your API has been created`]: `Interfejs API został utworzony`,
  [`Your API is live!`]: `Twój interfejs API jest aktywny!`,
  [`Your API is online!`]: `Twój interfejs API jest dostępny!`,
  [`Your Catalog is being deleted. This may take a while...`]: `Trwa usuwanie katalogu. Może to chwilę potrwać...`,
  [`Your Consumer organization is being created. This may take a while...`]: `Trwa tworzenie organizacji konsumenta. Może to chwilę potrwać...`,
  [`Your invitation is no longer valid. Check your invitation timeout setting and resend the invitation.`]: `Zaproszenie jest już nieważne. Sprawdź ustawienie limitu czasu zaproszenia i ponownie wyślij zaproszenie.`,
  [`Your Provider organization is being created. This may take a while...`]: `Trwa tworzenie organizacji dostawcy. Może to chwilę potrwać...`,
  [`Your Provider organization is being deleted. This may take a while...`]: `Trwa usuwanie organizacji dostawcy. Może to chwilę potrwać...`,
  [`Your member is being deleted. This may take a while...`]: `Trwa usuwanie członka. Może to chwilę potrwać...`,
  [`Your Space is being deleted. This may take a while...`]: `Trwa usuwanie obszaru. Może to chwilę potrwać...`,
  [`Your OAuth provider has unsaved changes. Click OK to continue without saving.`]: `Dostawca OAuth ma niezapisane zmiany. Kliknij przycisk OK, aby kontynuować bez zapisywania.`,
  [`Your credentials have expired.`]: `Twoje referencje utraciły ważność.`,
  [`Your invitation has been sent successfully with the following invitation link:`]: `Zaproszenie zostało wysłane pomyślnie z następującym odsyłaczem zaproszenia:`,
  [`Your request is still being processed and is taking longer than expected. Please refresh in a few minutes before retrying the request.`]: `Żądanie jest nadal przetwarzane i trwa to dłużej niż oczekiwano. Odśwież żądanie za kilka minut, zanim je ponowisz.`,
  [`Your product will be saved before proceeding, are you sure?`]: `Przed kontynuacją produkt zostanie zapisany. Czy na pewno chcesz kontynuować?`,
  [`Your trial ends on {date}`]: `Okres próbny kończy się w dniu {date}`,
  [`Your trial expired on {date}.`]: `Okres próbny wygasł w dniu {date}.`,
  [`Zoom in`]: `Powiększ`,
  [`Zoom in and out`]: `Powiększanie i zmniejszanie`,
  [`Zoom out`]: `Zmniejsz`,
  [`a lack of CORS support on the target server(see suggestion below)`]: `brak obsługi mechanizmu CORS na serwerze docelowym (patrz sugestia poniżej),`,
  [`a LDAP provider`]: `dostawcy LDAP`,
  [`activity`]: `działanie`,
  [`add item...`]: `dodaj element...`,
  [`add property...`]: `dodaj właściwość...`,
  [`added`]: `dodano`,
  [`admin`]: `administrator`,
  [`administrator`]: `administrator`,
  [`all`]: `wszystkie`,
  [`an API Connect Local User Registry`]: `lokalnego rejestru użytkowników produktu API Connect`,
  [`an authentication URL`]: `adresu URL uwierzytelniania`,
  [`an untrusted certificate has been encountered`]: `napotkano niezaufany certyfikat,`,
  [`and`]: `i`,
  [`apicv5`]: `apicv5`,
  [`application:`]: `Aplikacja:`,
  [`application/json`]: `application/json`,
  [`application/xml`]: `application/xml`,
  [`approval`]: `zatwierdzenie`,
  [`approval task for product`]: `zadanie zatwierdzenia produktu`,
  [`archived`]: `zarchiwizowane`,
  [`authenticated`]: `Uwierzytelniono`,
  [`authorization code time to live (seconds)`]: `Czas życia kodu autoryzacji (w sekundach)`,
  [`Authorization header pass through`]: `Tranzyt nagłówka autoryzacji`,
  [`badgerFish`]: `badgerFish`,
  [`blocking`]: `blokowanie`,
  [`body`]: `treść`,
  [`body-param`]: `parametr treści`,
  [`cancel`]: `Anuluj`,
  [`case`]: `przypadek`,
  [`catalog`]: `Katalog`,
  [`catalog:`]: `Katalog:`,
  [`catch`]: `wychwycone`,
  [`changed`]: `zmienione`,
  [`cipher`]: `szyfr`,
  [`code id_token`]: `token identyfikatora kodu`,
  [`code id_token token`]: `token tokenu identyfikatora kodu`,
  [`code token`]: `token kodu`,
  [`Consume`]: `Wykorzystaj`,
  [`consumer`]: `konsument`,
  [`consumes`]: `wykorzystuje`,
  [`composite`]: `złożony`,
  [`created`]: `utworzone`,
  [`Created API`]: `Utworzony interfejs API`,
  [`custom string`]: `łańcuch niestandardowy`,
  [`day`]: `dzień/dni`,
  [`day(s)`]: `d.`,
  [`default`]: `domyślny`,
  [`default value`]: `wartość domyślna`,
  [`definition`]: `definicja`,
  [`deprecated`]: `nieaktualne`,
  [`description`]: `opis`,
  [`development`]: `programowanie`,
  [`edit condition`]: `edytuj warunek`,
  [`edited`]: `edytowane`,
  [`environment`]: `Katalog`,
  [`error`]: `błąd`,
  [`false`]: `fałsz`,
  [`file`]: `plik`,
  [`font-awesome 4.5.0`]: `font-awesome 4.5.0`,
  [`font-awesome 4.5.0 icons`]: `ikony font-awesome 4.5.0`,
  [`for`]: `dla`,
  [`header`]: `nagłówek`,
  [`hear`]: `słychać`,
  [`hello`]: `Witamy`,
  [`helloName`]: `Witaj {name}!`,
  [`here`]: `tutaj`,
  [`hit`]: `trafienie`,
  [`hits`]: `trafienia`,
  [`hour`]: `godz.`,
  [`hour(s)`]: `godz.`,
  [`hours`]: `godz.`,
  [`identified`]: `zidentyfikowane`,
  [`Increased`]: `Zwiększone`,
  [`Increment`]: `Przyrost`,
  [`info`]: `informacja`,
  [`information about external documentation`]: `informacje o dokumentacji zewnętrznej`,
  [`Instead of honoring the exp claim from the OIDC provider, the access_token/refresh_token generated by APIC will honor APIC setting`]: `Zamiast uznawać roszczenie exp z dostawcy OIDC token access_token/refresh_token wygenerowany przez APIc będzie uznawał ustawienie APIC`,
  [`introspect URL`]: `Adres URL introspekcji`,
  [`Issue`]: `Problem`,
  [`Issues`]: `Problemy`,
  [`item`]: `element`,
  [`iterate over`]: `iteruj dla`,
  [`last modified`]: `ostatnia modyfikacja`,
  [`Log Level`]: `Poziom rejestrowania`,
  [`log.request_body`]: `log.request_body`,
  [`log.response_body`]: `log.response_body`,
  [`logs`]: `dzienniki`,
  [`Logout`]: `Wyloguj`,
  [`Logout redirect`]: `Przekierowanie przy wylogowaniu`,
  [`Logout redirect URL`]: `Adres URL przekierowania przy wylogowaniu`,
  [`location`]: `położenie`,
  [`material-design-icons-iconfont 3.0.3`]: `material-design-icons-iconfont 3.0.3`,
  [`minute`]: `min`,
  [`minute(s)`]: `min`,
  [`minutes`]: `min`,
  [`mutation type`]: `typ mutacji`,
  [`mutual SSL authentication is required`]: `wymagane jest uwierzytelnianie wzajemne SSL.`,
  [`name`]: `Nazwa`,
  [`Name|extension`]: `Nazwa`,
  [`Name|path parameters`]: `Nazwa`,
  [`Name|security definitions`]: `Nazwa`,
  [`non-blocking`]: `bez blokowania`,
  [`none`]: `brak`,
  [`none selected`]: `brak wybranych`,
  [`Object`]: `Obiekt`,
  [`off`]: `wyłącz`,
  [`of {total} pages`]: `z {total} str.`,
  [`of {total} page`]: `z {total} strony`,
  [`offline`]: `bez połączenia`,
  [`on`]: `włącz`,
  [`online`]: `tryb z połączeniem`,
  [`or`]: `lub`,
  [`or redact from all`]: `lub redaguj ze wszystkich`,
  [`open and close list of options`]: `otwórz lub zamknij listę opcji`,
  [`organization saved`]: `Organizacja została zapisana`,
  [`organization-manager`]: `menedżer organizacji`,
  [`otherwise`]: `w przeciwnym razie`,
  [`payload`]: `ładunek`,
  [`Payload`]: `Ładunek`,
  [`pending`]: `oczekujący`,
  [`produces`]: `generuje`,
  [`production`]: `produkcja`,
  [`production and development`]: `produkcja i programowanie`,
  [`property`]: `właściwość`,
  [`provider`]: `dostawca`,
  [`publish product`]: `opublikuj produkt`,
  [`published`]: `opublikowane`,
  [`query type`]: `typ zapytania`,
  [`query-parameters`]: `parametry zapytania`,
  [`realized`]: `zrealizowane`,
  [`recipients`]: `odbiorcy`,
  [`removed`]: `usunięte`,
  [`replaced`]: `zastąpione`,
  [`repeatable`]: `powtarzalne`,
  [`Replenish`]: `Uzupełnij`,
  [`request`]: `żądanie`,
  [`requested by`]: `zostało zażądane przez użytkownika`,
  [`Resolver weight`]: `Waga resolvera`,
  [`response`]: `odpowiedź`,
  [`response-param`]: `parametr odpowiedzi`,
  [`retired`]: `wycofane`,
  [`sample_scope_1`]: `przykładowy_zasięg_1`,
  [`saved`]: `zapisane`,
  [`scalar`]: `skalarny`,
  [`second`]: `s`,
  [`second(s)`]: `s`,
  [`seconds`]: `s`,
  [`select owner user`]: `wybierz użytkownika właściciela`,
  [`select user`]: `wybierz użytkownika`,
  [`soap-body`]: `treść soap`,
  [`source`]: `źródło`,
  [`space`]: `obszar`,
  [`Space settings`]: `Ustawienia obszaru`,
  [`Skip`]: `Pomiń`,
  [`specified`]: `określone`,
  [`staged`]: `przemieszczone`,
  [`start generating tests automatically.`]: `rozpocząć automatyczne generowanie testów.`,
  [`subject`]: `podmiot`,
  [`subscription type`]: `typ subskrypcji`,
  [`supersede`]: `zastąp`,
  [`superseded`]: `zastąpione`,
  [`target`]: `cel`,
  [`the server is unavailable`]: `serwer jest niedostępny`,
  [`this user registry type`]: `tego typu rejestru użytkowników`,
  [`This is a temporary publish page where doing re-publish preserves subscriptions.`]: `To jest tymczasowa strona publikowania, na której subskrypcje są zachowywane przy ponownej publikacji.`,
  [`to production`]: `do wersji produkcyjnej`,
  [`topology-administrator`]: `administrator topologii`,
  [`Trial days left`]: `Pozostałe dni okresu próbnego`,
  [`true`]: `prawda`,
  [`ttl`]: `ttl`,
  [`ttl has been changed`]: `Zmieniono ttl`,
  [`type`]: `typ`,
  [`unarchived`]: `rozpakowywane`,
  [`union type`]: `typ unii`,
  [`unlimited`]: `Nieograniczony`,
  [`updated`]: `zaktualizowane`,
  [`Updated from new schema`]: `Zaktualizowano z nowego schematu`,
  [`Upgrade`]: `Aktualizuj`,
  [`v5 legacy behavior`]: `Zachowanie starszej wersji 5`,
  [`version 2`]: `Wersja 2`,
  [`version 3`]: `Wersja 3`,
  [`viewer`]: `przeglądający`,
  [`value`]: `wartość`,
  [`warn`]: `ostrzeżenie`,
  [`warning`]: `ostrzeżenie`,
  [`warnings`]: `ostrzeżenia`,
  [`was not found. Please select a configured OAuth provider.`]: `nie został znaleziony. Wybierz skonfigurowanego dostawcę OAuth.`,
  [`week`]: `tyg.`,
  [`with`]: `za pomocą`,
  [`with|supersede with`]: `za pomocą`,
  [`wsdl`]: `wsdl`,
  [`xsd`]: `xsd`,
  [`status in sandbox`]: `status w środowisku testowym`,
  [`your file here, or`]: `plik w tym miejscu lub kliknij przycisk`,
  [`{count} found!`]: `Znaleziono: {count}`,
  [`{current} of {total} page`]: `Strona: {current} z {total}`,
  [`{current} of {total} pages`]: `Strona: {current} z {total}`,
  [`{days} day(s), {hours} hour(s), and {minutes} minute(s)`]: `{days} d., {hours} godz. i {minutes} min`,
  [`{days} day(s) and {hours} hour(s)`]: `{days} d. i {hours} godz.`,
  [`{docType} is required`]: `Dokument {docType} jest wymagany`,
  [`{docType} title`]: `Tytuł: {docType}`,
  [`Product title`]: `Tytuł produktu`,
  [`Application title`]: `Tytuł aplikacji`,
  [`{hours} hour(s) and {minutes} minute(s)`]: `{hours} godz. i {minutes} min`,
  [`{incompatibleGatewayCount} gateway(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `Bramy {incompatibleGatewayCount} zostały ukryte z powodu niezgodności z typem bramy określonym w interfejsie API lub w produkcie.`,
  [`{min}-{max} of {total} items`]: `Pozycje: {min}–{max} z {total}`,
  [`{min}-{max} of {total} item`]: `Elementy: {min}-{max} z {total}`,
  [`{min}-{max} of {total} Policies`]: `{min}–{max} z {total} strategii`,
  [`{min}-{max} of {total} Policy`]: `Strategie: {min}-{max} z {total}`,
  [`{min}-{max} of {total} types`]: `{min}–{max} z {total} typów`,
  [`{min}-{max} of {total} type`]: `Typy: {min}-{max} z {total}`,
  [`{min}-{max} of {total} search results`]: `{min}–{max} z {total} wyników wyszukiwania`,
  [`{min}-{max} of {total} search result`]: `Wyniki wyszukiwania: {min}-{max} z {total}`,
  [`{min}-{max} of {total} Catalogs`]: `{min}–{max} z {total} katalogów`,
  [`{min}-{max} of {total} Catalog`]: `Katalogi: {min}-{max} z {total}`,
  [`{min}-{max} of {total} Spaces`]: `{min}–{max} z {total} obszarów`,
  [`{min}-{max} of {total} Space`]: `Obszary: {min}-{max} z {total}`,
  [`{min}-{max} of {total} Extensions`]: `{min}–{max} z {total} rozszerzeń`,
  [`{min}-{max} of {total} Extension`]: `Rozszerzenia: {min}-{max} z {total}`,
  [`{min}-{max} of {total} Product Plans`]: `{min}-{max} z {total} planów produktów`,
  [`{min}-{max} of {total} Product Plan`]: `Plany produktu: {min}-{max} z {total}`,
  [`{min}-{max} of {total} Gateways`]: `{min}-{max} z {total} bram`,
  [`{min}-{max} of {total} Gateway`]: `Bramy: {min}-{max} z {total}`,
  [`{name} (API) is missing 'x-ibm-configuration'`]: `Przy interfejsie {name} (API) nie ma wpisu 'x-ibm-configuration'`,
  [`{name} (Product) has been published!`]: `Opublikowano produkt {name}!`,
  [`{name} (Product) has been staged!`]: `Produkt {name} został przemieszczony!`,
  [`{name} (Product) has not been published!`]: `Nie opublikowano produktu {name}!`,
  [`{name} (Role) has been created`]: `Utworzono rolę {name}.`,
  [`{name} successfully deleted`]: `Pomyślnie usunięto {name}.`,
  [`{name} (Role) has been updated`]: `Zaktualizowano rolę {name}.`,
  [`{name} has been assigned the owner`]: `Do elementu {name} przypisano właściciela.`,
  [`{number} Error`]: `{number} błąd`,
  [`{number} Errors`]: `{number} błędy/-ów`,
  [`{operation} approval task for product {prodname}:{version} requested by {username}`]: `Użytkownik {username} zażądał wykonania zadania zatwierdzenia operacji {operation} dotyczącego produktu {prodname}:{version}`,
  [`{path} does not exist`]: `{path} nie istnieje`,
  [`{productName} has no migration target set.`]: `Produkt {productName} nie ma ustawionego elementu docelowego migracji.`,
  [`{state} Pending`]: `{state} – w toku`,
  [`{status} API error`]: `Błąd interfejsu API: {status}`,
  [`{status} Bad Request`]: `{status} – błędne żądanie`,
  [`{status} Forbidden`]: `{status} – zabronione`,
  [`{status} Gateway Error`]: `{status} – błąd bramy`,
  [`{status} Unauthorized`]: `{status} – brak autoryzacji`,
  [`({time} after being sent)`]: `({time} po wysłaniu)`,
  [`({time} after being queued)`]: `({time} po umieszczeniu w kolejce)`,
  [`{title} (Product) has been {state}.`]: `Produkt {title} został {state}.`,
  [`{title} has been deleted`]: `Element {title} został usunięty.`,
  [`{title} has been {changedName}.`]: `{title} został {changedName}.`,
  [`{title} has not been deleted!`]: `Element {title} nie został usunięty!`,
  [`{title} has not been {changedName}!`]: `{title} nie został {changedName}!`,
  [`{title} {changedName}.`]: `{title} {changedName}.`,
  [`{type} has been {changedName}.`]: `Typ {type} został {changedName}.`,
  [`{type} has not been {changedName}!`]: `Typ {type} nie został {changedName}!`,
  [`{type} history log`]: `Dziennik historii: {type}`,
  [`{username} does not exist`]: `Użytkownik {username} nie istnieje.`,
  [`{username} does not exist in the user registry`]: `Użytkownik {username} nie istnieje w rejestrze użytkowników`,
  [`{username} is not associated with this consumer org`]: `Użytkownik {username} nie jest powiązany z tą organizacją konsumenta`,
  [`{username} has been created as a {type}.`]: `Użytkownik {username} został utworzony jako użytkownik typu {type}.`,
  [`{{appName}} app reinstated in the {{consumerOrg}} developer portal`]: `Przywrócono normalne działanie aplikacji {appName} w portalu Developer Portal {consumerOrg}`,
  [`{{appName}} app suspended in the {{consumerOrg}} developer portal`]: `Zawieszono aplikację {appName} w portalu Developer Portal {consumerOrg}`,
  [`{{catalogTitle}} developer portal account registration`]: `Rejestracja konta portalu Developer Portal {catalogTitle}`,
  [`{{count}} errors need attention`]: `Liczba błędów wymagających uwagi: {count}`,
  // lts only translations
  [`Configure JSON settings`]: `Skonfiguruj ustawienia JSON`,
  [`Configure XML settings`]: `Skonfiguruj ustawienia XML`,
  [`Configure options`]: `Skonfiguruj opcje`,
  [`Detect`]: `Wykryj`,
  [`Document type`]: `Typ dokumentu`,
  [`JSON`]: `JSON`,
  [`Maximum document size`]: `Maksymalna wielkość dokumentu`,
  [`Maximum name length`]: `Maksymalna długość nazwy`,
  [`Maximum nesting depth`]: `Maksymalna głębokość zagnieżdżania`,
  [`Maximum number length`]: `Maksymalna długość liczby`,
  [`Maximum number of unique names`]: `Maksymalna liczba unikalnych nazw`,
  [`Maximum number of unique namespaces`]: `Maksymalna liczba unikalnych przestrzeni nazw`,
  [`Maximum number of unique prefixes`]: `Maksymalna liczba unikalnych przedrostków`,
  [`Maximum value length`]: `Maksymalna długość wartości`,
  [`Maximum width`]: `Maksymalna szerokość`,
  [`Maximum stack size`]: `Maksymalna wielkość stosu`,
  [`Parse settings`]: `Ustawienia analizy`,
  [`Specifies the maximum document size in bytes that the parse action accepts.`]: `Określa maksymalną wielkość dokumentu (w bajtach), który jest akceptowany przez działanie analizy.`,
  [`Specifies the maximum level of nested element depth in an XML or a JSON message that the parse action accepts.`]: `Określa maksymalną głębokość zagnieżdżania elementu w komunikacie XML lub JSON, która jest akceptowana przez działanie analizy.`,
  [`Specifies the maximum level of nested element depth in an XML, a JSON or a GraphQL message that the parse action accepts.`]: `Określa maksymalną głębokość zagnieżdżania elementu w komunikacie XML, JSON lub GraphQL, która jest akceptowana przez działanie analizy.`,
  [`Specifies the maximum name length in bytes that the parse action accepts.`]: `Określa maksymalną długość nazw (w bajtach), które są akceptowane przez działanie analizy.`,
  [`Specifies the maximum number length limits the number of bytes in the value portion of a label-value pair when the value is a number.`]: `Określa maksymalną długość liczby ograniczającą liczbę bajtów w wartości w parach nazwa-wartość, gdy wartość jest liczbą.`,
  [`Specifies the maximum number of unique XML namespace URIs that the parse action accepts. This limit counts all XML namespaces, regardless of how many prefixes are used to declare them.`]: `Określa maksymalną liczbę unikalnych identyfikatorów URI przestrzeni nazw XML, które są akceptowane przez działanie analizy. Ten limit zlicza wszystkie przestrzenie nazw XML, niezależnie od liczby przedrostków użytych do ich zadeklarowania.`,
  [`Specifies the maximum number of unique XML namespace prefixes in a document that the parse action accepts. This limit counts multiple prefixes defined for the same namespace, but does not count multiple namespaces defined in different parts of the input document under a single prefix.`]: `Określa maksymalną liczbę unikalnych przedrostków przestrzeni nazw XML w dokumencie, które są akceptowane przez działanie analizy. Ten limit zlicza wiele przedrostków zdefiniowanych dla tej samej przestrzeni nazw, ale nie zlicza wielu przestrzeni nazw zdefiniowanych w różnych częściach dokumentu wejściowego przy użyciu pojedynczego przedrostka.`,
  [`Specifies the maximum number of unique names that the parse action accepts.`]: `Określa maksymalną liczbę unikalnych nazw akceptowanych przez działanie analizy.`,
  [`Specifies the maximum value length in bytes that the parse action accepts.`]: `Podaj maksymalną długość wartości w bajtach, która jest akceptowana przez działanie analizy.`,
  [`Specifies the maximum width of a payload that the parse action accepts.`]: `Określa maksymalną szerokość ładunku, która jest akceptowana przez działanie analizy.`,
  [`Specifies the type of document to parse.`]: `Określa typ dokumentu do przeanalizowania.`,
  [`Specifies the XSLT processor version. The default value is XSLT10.`]: `Określa wersję procesora XSLT. Wartością domyślną jest XSLT10.`,
  [`Specifies whether to enable strict XSLT error checking. Non-strict operations attempt to recover from certain errors, such as use of undeclared variables, calling undeclared templates, and so forth. By default, strict XSLT error checking is enabled.`]: `Określa, czy ma być włączone ścisłe sprawdzanie XSLT pod kątem błędów. Przy sprawdzaniu nieścisłym podejmowane są próby kontynuacji po niektórych błędach, takich jak użycie niezadeklarowanych zmiennych, wywoływanie niezadeklarowanych szablonów itd. Domyślnie ścisłe sprawdzanie XSLT pod kątem błędów jest włączone.`,
  [`Specifies whether to enable stylesheet profiling. This option should not be used in production environments. By default, stylesheet profiling is disabled.`]: `Określa, czy ma być włączone profilowanie arkusza stylów. Nie należy używać tej opcji w środowiskach produkcyjnych. Domyślnie profilowanie arkusza stylów jest wyłączone.`,
  [`Specifies whether to run the stylesheet, XQuery script, and JSONiq script in debug mode. When a stylesheet, XQuery script, or JSONiq script is run in debug mode, it generates a custom web page instead of displaying its normal output. The web page details exactly what occurred during execution, including the values of variables and where particular pieces of the output came from. This option should not be used in production environments. By default, debug mode is disabled.`]: `Określa, czy arkusz stylów, skrypt XQuery i skrypt JSONiq mają działa w trybie debugowania. Gdy arkusz stylów, skrypt XQuery lub skrypt JSONiq działają w trybie debugowania, generują niestandardową stronę WWW zamiast wyświetlać zwykłe wyniki. Ta strona WWW zawiera szczegółowe informacje o przebiegu wykonywania, w tym wartości zmiennych i informacje o pochodzeniu poszczególnych elementów wyników. Nie należy używać tej opcji w środowiskach produkcyjnych. Domyślnie tryb debugowania jest wyłączony.`,
  [`Specifies whether the stylesheet must be run in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, an error is generated and the input is not processed. By default, streaming mode is disabled.`]: `Określa, czy arkusz stylów ma działać w trybie strumieniowania. Transformacja dokumentu rozpoczyna się po przeanalizowaniu całych danych wejściowych. Nie wszystkie arkusze stylów mogą być strumieniowane. Jeśli strumieniowanie arkusza stylów nie jest możliwe, zgłaszany jest błąd i dane wejściowe nie są przetwarzane. Domyślnie tryb strumieniowania jest wyłączony.`,
  [`Specifies whether to attempt to run the stylesheet in streaming mode. Transformation of the document begins before the input is fully parsed. Not all stylesheets can be streamed. If the stylesheet cannot be streamed, a warning is generated during compilation and the stylesheet is read in the entire input as normal at execution time. By default, attempting to run the stylesheet in streaming mode is disabled.`]: `Określa, czy mają być podejmowane próby wykonywania arkusza stylów w trybie strumieniowania. Transformacja dokumentu rozpoczyna się po przeanalizowaniu całych danych wejściowych. Nie wszystkie arkusze stylów mogą być strumieniowane. Jeśli strumieniowanie arkusza stylów nie jest możliwe, w trakcie kompilacji generowane jest ostrzeżenie, a arkusz stylów jest wczytywany w całych danych wejściowych w zwykły sposób w trakcie wykonywania. Domyślnie próby wykonywania arkusza stylów w trybie strumieniowania są wyłączone.`,
  [`Specifies whether to escape output produced from the stylesheet during processing. Minimal escaping is particularly useful when handling non-English character sets. By default, minimum escaping is disabled.`]: `Określa, czy ma być wykonywana zmiana znaczenia wyników generowanych przez arkusz stylów podczas przetwarzania. Minimalna zmiana znaczenia jest szczególnie przydatna przy pracy z zestawami znaków języków innych niż angielski. Domyślnie minimalna zmiana znaczenia jest wyłączona.`,
  [`Indicates the maximum number of bytes that the stack is allowed to use while executing a stylesheet or other compiled content. This setting is used to block infinite recursion. The minimum value is 10 kilobytes, or 10,240 bytes. The maximum value is 100 megabytes, or 104,857,600 bytes. The default value is 1 megabyte, or 1,048,576 bytes.`]: `Określa maksymalną liczbę bajtów, jaką może zająć stos podczas wykonywania arkusza stylów lub innej skompilowanej treści. To ustawienie ma w zamierzeniu blokować nieskończoną rekurencję. Minimalna wartość to 10 kilobajtów lub 10 240 bajtów. Maksymalna wartość to 100 megabajtów lub 104 857 600 bajtów. Wartość domyślna to 1 megabajt lub 1 048 576 bajtów.`,
  [`Specifies the validation behavior to apply to WSDL files that are checked for conformance to section 5 of WS-I Basic Profile (version 1.0, April 2004). The default setting is Warn.`]: `Określa sposób sprawdzania plików WSDL pod względem zgodności z sekcją 5 specyfikacji WS-I Basic Profile (wersja 1.0, kwiecień 2004 r.). Ustawienie domyślne to Ostrzeżenie.`,
  [`Specifies the validation behavior for the soap:Body. The default setting is Strict.`]: `Określa sposób sprawdzania poprawności pola soap:Body. Ustawienie domyślne to Ścisłe.`,
  [`Specifies the validation behavior for the soap:Header. The default setting is Lax.`]: `Określa sposób sprawdzania poprawności pola soap:Header. Ustawienie domyślne to Swobodne.`,
  [`Specifies the validation behavior for the fault detail. The default setting is Strict.`]: `Określa sposób sprawdzania poprawności szczegółów błędu. Ustawienie domyślne to Ścisłe.`,
  [`Specifies whether to require compatibility with RPC-style wrappers. By default, RPC-style wrappers are not required.`]: `Określa, czy ma być wymagana zgodność z opakowaniami w stylu RPC. Domyślnie opakowania w stylu RPC nie są wymagane.`,
  [`Specifies whether to allow the schema to accept most uses of elements with xsi:type='SOAP-ENC:Array' consistent with SOAP 1.1 Section 5, even when these attributes violate the XML Schema specification. Normally the xsi:type attribute must name a type equal to or derived from the actual type of the element. For schemas compiled with this option, xsi:type is accepted specifically for the SOAP 1.1 Encoding 'Array' complex type if the element type is derived from SOAP-ENC:Array. The opposite is the normal allowable case. By default, elements with xsi:type='SOAP-ENC:Array' are not accepted.`]: `Określa, czy zezwalać schematowi na akceptowanie większości zastosowań elementów z xsi:type='SOAP-ENC:Array' zgodnie z sekcją 5 specyfikacji SOAP 1.1, nawet gdy te atrybuty naruszają specyfikację schematu XML. W normalnych warunkach atrybut xsi:type musi wskazywać typ równy rzeczywistemu typowi elementu lub z niego wywiedziony. W przypadku schematów skompilowanych z tą opcją akceptowany jest typ xsi:type dla złożonego 'Array' według kodowania SOAP 1.1, jeśli typ elementu jest wywiedziony z SOAP-ENC:Array. Sytuacja odwrotna jest dopuszczalna w normalnych warunkach. Domyślnie elementy z xsi:type='SOAP-ENC:Array' nie są akceptowane.`,
  [`Specifies whether to perform extra schema validation following the encoding rules in SOAP 1.1 Section 5. When enabled, members of SOAP arrays are validated, attributes such as @id and @href are allowed even if they are not allowed by the schema, and @href values are checked to ensure that they have a corresponding @id element. By default, the extra validation is not performed.`]: `Określa, czy ma być przeprowadzane dodatkowe sprawdzanie zgodności schematu z regułami kodowania określonymi w sekcji 5 specyfikacji SOAP 1.1. Gdy ta opcja jest włączona, elementy tablic SOAP są sprawdzane, a takie atrybuty, jak @id i @href, są dozwolone nawet wtedy, gdy nie zezwala na nie schemat, przy czym wartości @href muszą mieć odpowiadające im elementy @id. Domyślnie to dodatkowe sprawdzanie nie jest przeprowadzane.`,
  [`Specifies whether xs:any elements in the schema validate only child elements by name. The XML Schema specification requires that, if a wildcard matches an element but that element does not have an element declaration, the element is instead validated according to an xsi:type attribute on it. This option ignores those xsi:type attributes. It should be used for cases such as SOAP envelope validation where a further validation step will validate the contents matching the wildcard, possibly using the SOAP 1.1 encoding rules. By default, xsi:type attributes are not ignored.`]: `Określa, czy elementy xs:any w schemacie mają sprawdzać według nazwy tylko elementy potomne. Specyfikacja schematu XML wymaga, aby w sytuacji, gdy symbol wieloznaczny pasuje do elementu, ale element nie ma deklaracji elementu, sprawdzanie elementu odbywało się według jego atrybutu xsi:type. Ta opcja powoduje, że te atrybuty xsi:type są ignorowane. Należy jej używać w takich przypadkach, jak sprawdzanie poprawności koperty SOAP, gdy następny krok sprawdza zawartość pasującą do symbolu wieloznacznego, potencjalnie według reguł kodowania SOAP 1.1. Domyślnie atrybuty xsi:type nie są ignorowane.`,
  [`Specifies whether to strictly follow the SOAP binding in the WSDL. When enabled, only messages bound to SOAP 1.2 appear in SOAP 1.2 envelopes and only messages bound to SOAP 1.1 appear in SOAP 1.1 envelopes. By default, strict SOAP binding is disabled.`]: `Określa, czy powiązanie SOAP w kodzie WSDL ma być ściśle przestrzegane. Gdy ta opcja jest włączona, w kopertach SOAP 1.2 będą widoczne tylko wiadomości powiązane z SOAP 1.2, a w kopertach SOAP 1.1 — tylko wiadomości powiązane z SOAP 1.1. Domyślnie ścisłe powiązanie z SOAP jest wyłączone.`,
  [`Specifies whether to compile XACML policies with debug information. Note that the XACML debugging messages are also controlled by the log event in the XACML category. Use the debug log level to view the full XACML debugging messages. By default, XACML policies are not compiled with debug information.`]: `Określa, czy strategie XACML mają być kompilowane z informacjami debugowania. Należy zauważyć, że komunikatami debugowania XACML steruje także zdarzenie dziennika w kategorii XACML. Aby przeglądać kompletne komunikaty debugowania XACML, należy używać poziomu rejestrowania Debugowanie. Domyślnie strategie XACML nie są kompilowane z informacjami debugowania.`,
  [`Specifies whether the schema or WSDL document accepts messages where base64-encoded binary content was optimized according to the MTOM/XOP specifications. XOP binary-optimization replaces base64-encoded binary data with an xop:Include reference element that references the unencoded binary data located in an attachment. By default, MTOM/XOP optimized messages are disabled.`]: `Określa, czy schemat lub dokument WSDL akceptuje wiadomości, w których treść binarna zakodowana w standardzie base64 została zoptymalizowana zgodnie ze specyfikacją MTOM/XOP. Optymalizacja treści binarnej XOP zastępuje dane binarne zakodowane w standardzie base64 elementem xop:Include reference, który odwołuje się do niezakodowanych danych binarnych znajdujących się w załączniku. Domyślnie komunikaty z optymalizacją MTOM/XOP są wyłączone.`,
  [`XML`]: `XML`,
  // vnext only translations
  [`A Consumer organization group is a collection of Consumer organizations, and provides an efficient way of controlling who can see, and subscribe to, the APIs in your Products. By using a Consumer organization group, you can define this access for all the developers in the organizations in that group in a single operation, rather than having to define access for the organizations separately.`]: `Grupa organizacji konsumenta jest kolekcją organizacji klienta. Zapewnia ona efektywne metody kontrolowania tego, kto może wyświetlać i subskrybować interfejsy API w Twoich produktach. Używając grupy organizacji konsumenta, można przy użyciu jednej operacji zdefiniować ten poziom dostępu dla wszystkich programistów w organizacjach zawartych w tej grupie. Nie trzeba definiować dostępu dla każdej organizacji osobno.`,
  [`API Connect Home`]: `Strona główna produktu API Connect`,
  [`Add new group`]: `Dodaj nową grupę`,
  [`Add consumer organizations to the group as required`]: `W razie potrzeby dodaj organizacje konsumenta do grupy.`,
  [`Add the following configuration`]: `Dodaj następującą konfigurację`,
  [`Add the following configuration to`]: `Dodaj następującą konfigurację do`,
  [`Additions`]: `Dodania`,
  [`Additional GraphQL capabilities`]: `Dodatkowe możliwości języka GraphQL`,
  [`Additional settings for graphQL APIs that are included in this plan`]: `Ustawienia dodatkowe interfejsów API graphQL uwzględnionych w tym planie.`,
  [`An organization or a group is required.`]: `Organizacja lub grupa jest wymagana.`,
  [`An organization/group is required.`]: `Organizacja/grupa jest wymagana.`,
  [`Analysis configuration`]: `Konfiguracja analizy danych`,
  [`Application & Credentials`]: `Aplikacja i referencje`,
  [`Are you sure you want to delete this API version?`]: `Czy na pewno chcesz usunąć tę wersję interfejsu API?`,
  [`Are you sure you want to delete this API with all versions?`]: `Czy na pewno chcesz usunąć ten interfejs API i wszystkie jego wersje?`,
  [`Are you sure you want to remove the gateway extension from the gateway?`]: `Czy na pewno chcesz usunąć rozszerzenie bramy z bramy?`,
  [`Are you sure you want to rename all versions of this API?`]: `Czy na pewno chcesz zmienić nazwy wszystkich wersji tego interfejsu API?`,
  [`Are you sure you want to rename this API version?`]: `Czy na pewno chcesz zmienić nazwę tej wersji interfejsu API?`,
  [`Associate groups to the organization as required`]: `W razie potrzeby powiąż grupy z organizacją.`,
  [`At least one option must be enabled`]: `Musi być włączona co najmniej jedna opcja`,
  [`Attribute mapping`]: `Odwzorowanie atrybutu`,
  [`Attempt streaming rule`]: `Reguła prób strumieniowania`,
  [`Auto generated default paths and operations`]: `Automatycznie wygenerowano operacje i ścieżki domyślne`,
  [`Base API endpoint`]: `Podstawowy punkt końcowy interfejsu API`,
  [`Consumer orgs`]: `Organizacje konsumentów`,
  [`Calculate Query Characteristics`]: `Obliczaj parametry zapytania`,
  [`Calculate Response Characteristics`]: `Obliczaj parametry odpowiedzi`,
  [`Certificate or Shared Secret for Verify`]: `Certyfikat lub współużytkowane dane niejawne do weryfikacji`,
  [`Check out our hands on labs where you can request to try out the full API management capabilities for up to a week!`]: `Zapoznaj się z naszymi laboratoriami praktycznymi, w których można poprosić o wypróbowanie przez tydzień pełnych możliwości zarządzania interfejsami API!`,
  [`Clone API`]: `Klonuj API`,
  [`Consumer organizations and groups`]: `Organizacje i grupy konsumentów`,
  [`Create new API version`]: `Utwórz nową wersję interfejsu API`,
  [`Create new product version`]: `Utwórz nową wersję produktu`,
  [`Create a GraphQL API based on a GraphQL service`]: `Utwórz interfejs API GraphQL na podstawie usługi GraphQL`,
  [`Custom rate limit`]: `Niestandardowy limit częstotliwości`,
  [`Delete API`]: `Usuń interfejs API`,
  [`Delete this version`]: `Usuwanie wersji`,
  [`Deletion confirmation`]: `Potwierdzenie usunięcia`,
  [`Deletions`]: `Usunięcia`,
  [`Display name`]: `Nazwa wyświetlana`,
  [`Display Name`]: `Nazwa wyświetlana`,
  [`Details`]: `Szczegóły`,
  [`Edit GraphQL Rate Limit|title`]: `Edytuj limit częstotliwości GraphQL |tytuł`,
  [`Edit GraphQL rate limits`]: `Edycja limitów częstotliwości GraphQL`,
  [`Edit group`]: `Edytuj grupę`,
  [`Edit condition`]: `Edytuj warunek`,
  [`Edit rate limit|title`]: `Edytuj limit częstotliwości`,
  [`Edit rate limit|menu`]: `Edytuj limit częstotliwości`,
  [`Either there are no Products published to this Catalog, or this application is already subscribed to all Plans in all Products.`]: `Żadne produkty nie zostały opublikowane w tym katalogu lub wszystkie plany i produkty zostały już zasubskrybowane przez aplikację.`,
  [`Enforce Policy`]: `Wymuszaj strategię`,
  [`Enter a group summary`]: `Wprowadź podsumowanie grupy`,
  [`Enter a group title`]: `Wprowadź tytuł grupy`,
  [`Enter group details below`]: `Wprowadź poniżej szczegółowe informacje o grupie`,
  [`Enter the URL and schema name for the GraphQL service you would like to proxy`]: `Wprowadź adres URL i nazwę schematu dla usługi GraphQL na potrzeby proxy`,
  [`Enum value`]: `Wartość wyliczeniowa`,
  [`Go to catalog settings to configure a new billing integration`]: `Przejdź do ustawień katalogu, aby skonfigurować nową integrację fakturowania`,
  [`GraphQL introspect`]: `Introspekcja GraphQL`,
  [`GraphQL server`]: `Serwer GraphQL`,
  [`GraphQL Setting`]: `Ustawienia języka GraphQL`,
  [`GraphQL server URL`]: `Adres URL serwera GraphQL`,
  [`GraphQL server name`]: `Nazwa serwera GraphQL`,
  [`GraphQL type analysis configuration`]: `Konfiguracja analizy typu GraphQL`,
  [`GraphQL resolver analysis configuration`]: `Konfiguracja analizy resolvera GraphQL`,
  [`GraphQL`]: `GraphQL`,
  [`Groups can be created and managed in the following list.`]: `Na poniższej liście można tworzyć grupy i nimi zarządzać.`,
  [`Groups will be listed here.`]: `W tym miejscu zostaną wyświetlone grupy.`,
  [`Hello {firstName},\n\nYour request to {action} the API product {productName}:{version} in the\n{catalog} catalog has been approved.\n\n{#if comments}\nThe approver included the following comments:\n\n{comments}\n\n{{/if}}\nYou can check the status of the product here:\n\n{link}\n`]: `Witaj {firstName},\n\nżądanie dotyczące działania {action} dla produktu API {productName}:{version} w katalogu\n{catalog} zostało zatwierdzone.\n\n{#if comments}\nOsoba zatwierdzająca dodała następujące komentarze:\n\n{comments}\n\n{{/if}}\nStatus produktu można sprawdzić tutaj:\n\n{link}\n`,
  [`Hello,\n\nThis is a test message from IBM API Connect from the configured mail server\nmailServer.\n\nIf you received this message as expected, the test was successful!\n`]: `Witaj,\n\nto jest wiadomość testowa z produktu IBM API Connect przesłana za pośrednictwem skonfigurowanego serwera poczty\n{mailServer}.\n\nJeśli ta wiadomość została odebrana zgodnie z oczekiwaniami, test zakończył się powodzeniem!\n`,
  [`Hello,\n\nUser {{originator}} has withdrawn their request to subscribe with the application {{appName}}\nto the {{planName}} plan for version {{productVersion}} of the {{productName}} API product in\nthe {{catalog}} catalog.\n\nNo further action is required.\n`]: `Witaj,\n\nużytkownik {originator} wycofał swoje żądanie subskrypcji aplikacji {appName}\nw planie {planName} dla wersji {productVersion} produktu API {productName}\nw katalogu {catalog}.\n\nNie są wymagane żadne dalsze działania.\n`,
  [`Invoke GraphQL server`]: `Wywołuj serwer GraphQL`,
  [`Invoke the backend GraphQL server`]: `Wywołaj serwer zaplecza GraphQL`,
  [`JWKS endpoint`]: `Punkt końcowy JWKS`,
  [`LDAP Field Name`]: `Nazwa pola LDAP`,
  [`Limit maximum nesting per query`]: `Ograniczaj stopień zagnieżdżenia dla poszczególnych zapytań`,
  [`Limit resolve complexity rate on a per key basis`]: `Ograniczaj stopień złożoności rozstrzygnięć dla poszczególnych kluczy`,
  [`Limit type complexity rate on a per key basis`]: `Ograniczaj stopień złożoności typów dla poszczególnych kluczy`,
  [`Manage APIs included in this product`]: `Zarządzaj interfejsami API uwzględnionymi w tym produkcie`,
  [`Manage groups`]: `Zarządzaj grupami`,
  [`Maximum nesting`]: `Maksymalne zagnieżdżenie`,
  [`Maximum resolve complexity`]: `Maksymalna złożoność rozstrzygnięć`,
  [`Maximum type complexity`]: `Maksymalna złożoność typów`,
  [`Name of the group`]: `Nazwa grupy`,
  [`New API version`]: `Nowa wersja interfejsu API`,
  [`New GraphQL API`]: `Nowy interfejs API GraphQL`,
  [`New product version`]: `Nowa wersja produktu`,
  [`New Version`]: `Nowa wersja`,
  [`New version`]: `Nowa wersja`,
  [`No billing integration exists`]: `Nie istnieje żadna integracja rozliczeń`,
  [`No CA bundles available`]: `Brak dostępnych pakunków CA`,
  [`No Data`]: `Brak danych`,
  [`No certificates available`]: `Brak dostępnych certyfikatów`,
  [`No data`]: `Brak danych`,
  [`No group found`]: `Nie znaleziono grupy`,
  [`OAuth Auth URL`]: `Adres URL uwierzytelniania OAuth`,
  [`OAuth Token URL`]: `Adres URL tokenu OAuth`,
  [`Open Stripe dashboard`]: `Otwórz panel kontrolny Stripe`,
  [`Operation/Path`]: `Operacja/ścieżka`,
  [`Operation Path`]: `Ścieżka operacji`,
  [`Operations and Paths`]: `Operacje i ścieżki`,
  [`Operator`]: `Operator`,
  [`Organizations and groups will be listed here.`]: `W tym miejscu zostaną wyświetlone organizacje i grupy.`,
  [`Page not found`]: `Nie znaleziono strony`,
  [`Please copy the code below to CLI to continue the process. The code will expire in **{ttl}**.`]: `Skopiuj kod znajdujący się poniżej do interfejsu CLI, aby kontynuować proces. Kod utraci ważność za ** {ttl}* *.`,
  [`Please reference your Stripe dashboard to access the API key and secret`]: `Aby uzyskać dostęp do klucza i danych niejawnych interfejsu API, skorzystaj z panelu kontrolnego Stripe`,
  [`Please upload a schema definition language (SDL) file.`]: `Prześlij plik w języku definicji schematów (SDL).`,
  [`Please upload a valid GraphQL schema.`]: `Prześlij poprawny schemat GraphQL.`,
  [`Please upload a valid YAML file.`]: `Prześlij poprawny plik YAML.`,
  [`Populate Server Configuration into Context Variables`]: `Zapełniaj zmienne kontekstowe danymi z konfiguracji serwera`,
  [`POST/GET a query to be validated and sent to the backend server`]: `Wyślij (POST)/Pobierz (GET) zapytanie, którego poprawność ma zostać sprawdzona, i które ma być wysłane na serwer zaplecza`,
  [`POST/GET a query to get the estimated cost of invoking that query`]: `Wyślij (POST)/pobierz (GET) zapytanie, aby uzyskać szacowany koszt wywołania tego zapytania`,
  [`Preparing`]: `Przygotowywanie`,
  [`Rate limit amount cannot be negative`]: `Wartość limitu częstotliwości nie może być ujemna`,
  [`Rate limit per cannot be negative`]: `Częstotliwość limitu nie może być ujemna`,
  [`Rename API`]: `Zmień nazwę interfejsu API`,
  [`Rename`]: `Zmień nazwę`,
  [`Request for approval to {{action}} an API product in the {{catalog}} catalog`]: `Żądanie zatwierdzenia działania {action} dla produktu API w katalogu {catalog}`,
  [`Sandbox Sample Application`]: `Przykładowa aplikacja środowiska testowego`,
  [`Sandbox sample application credentials`]: `Referencje przykładowej aplikacji środowiska testowego`,
  [`sample application credentials`]: `przykładowe referencje aplikacji`,
  [`Saving`]: `Zapisywanie`,
  [`Search Orgs`]: `Szukaj organizacji`,
  [`Search definitions...`]: `Szukaj definicji...`,
  [`Search errors...`]: `Szukaj błędów...`,
  [`Search operations...`]: `Szukaj operacji...`,
  [`Search organizations and groups`]: `Szukaj organizacji i grup`,
  [`Sorry, This feature is not available in`]: `Niestety, ta funkcja jest niedostępna w`,
  [`Stopping`]: `Zatrzymywanie`,
  [`The user with username {username} already exists in the user registry.`]: `Użytkownik o nazwie {username} już istnieje w rejestrze użytkowników.`,
  [`There are API changes that are not running. Republish this API to the Sandbox catalog.`]: `Istnieją zmiany API, które nie są aktywne. Publikuj ten interfejs API do katalogu Środowisko testowe.`,
  [`There are no plans available for subscription.`]: `Brak planów dostępnych do zasubskrybowania.`,
  [`There are no attributes yet.`]: `Jeszcze nie ma żadnych atrybutów.`,
  [`Twitter`]: `Twitter`,
  [`Type to add consumer organizations to the group`]: `Wpisz, aby dodać organizacje konsumenta do grupy`,
  [`Type to add groups to the organization`]: `Wpisz tekst, aby dodać grupy do organizacji`,
  [`Type to add organizations from {catalog}`]: `Wpisz, aby dodać organizacje z katalogu {catalog}`,
  [`Type to add organizations or groups`]: `Wpisz, aby dodać organizacje lub grupy`,
  [`Type to add organizations or groups from {catalog}`]: `Wpisz, aby dodać organizacje lub grupy z katalogu {catalog}`,
  [`Type to add {title} to groups`]: `Wpisz, aby dodać element {title} do grupy`,
  [`Update Rate Limits`]: `Aktualizuj limity częstotliwości`,
  [`Upload the YAML file with configuration`]: `Prześlij plik YAML z konfiguracją`,
  [`Use the sample below as a template to configure your GraphQL server`]: `Poniższy przykład to szablon, według którego możesz skonfigurować własny serwer GraphQL.`,
  [`User managed`]: `Zarządzane przez użytkownika`,
  [`URL {url} does not contain valid OpenAPI.`]: `Adres URL {url} nie zawiera poprawnego OpenAPI.`,
  [`Validating`]: `Sprawdzanie poprawności`,
  [`View analytics and performance metrics for your APIs`]: `Wyświetl analizy i charakterystyki wydajności dla interfejsów API`,
  [`View and add custom policies to the catalog`]: `Wyświetl i dodaj strategie niestandardowe do katalogu`,
  [`View and add custom exentions to the catalog`]: `Wyświetl i dodaj rozszerzenia niestandardowe do katalogu`,
  [`View user registries and OAuth providers`]: `Wyświetl rejestry użytkowników i dostawców OAuth`,
  [`View your application and manage API credentials`]: `Wyświetl aplikację i zarządzaj referencjami API`,
  [`Welcome to the API Connect Trial`]: `Witamy w wersji próbnej produktu API Connect`,
  [`You haven't added any APIs`]: `Nie dodano żadnych interfejsów API`,
  [`You currently don’t have any {resource}.`]: `Nie masz: {resource}.`,
  [`plans to migrate`]: `plany migracji`,
  [`gateway extensions`]: `rozszerzenia bramy`,
  [`application`]: `aplikacja`,
  [`application authentication source`]: `źródło uwierzytelniania aplikacji`,
  [`API or product`]: `interfejs API lub produkt`,
  [`base endpoints`]: `podstawowe punkty końcowe`,
  [`categories`]: `kategorie`,
  [`certificates`]: `certyfikaty`,
  [`channels`]: `kanały`,
  [`consumer organizations`]: `organizacje konsumenta`,
  [`consumer organization group`]: `grupa organizacji konsumenta`,
  [`consumer organizations or groups`]: `organizacje lub grupy konsumenta`,
  [`correlation ids`]: `identyfikatory korelacji`,
  [`cors policy`]: `strategia mechanizmu CORS`,
  [`definitions`]: `definicje`,
  [`examples`]: `przykłady`,
  [`gateway services`]: `usługi bramy`,
  [`groups`]: `grupy`,
  [`headers`]: `nagłówki`,
  [`links`]: `odsyłacze`,
  [`member`]: `członek`,
  [`messages`]: `komunikaty`,
  [`message traits`]: `cechy komunikatu`,
  [`operation traits`]: `cechy operacji`,
  [`parameter definitions`]: `definicje parametrów`,
  [`parameters`]: `parametry`,
  [`path`]: `ścieżka`,
  [`properties`]: `właściwości`,
  [`provider organizations`]: `organizacje dostawcy`,
  [`request bodies`]: `treści żądań`,
  [`response definitions`]: `definicje odpowiedzi`,
  [`responses`]: `odpowiedzi`,
  [`role`]: `rola`,
  [`scope`]: `zasięg`,
  [`scopes`]: `zasięgi`,
  [`schemas`]: `schematy`,
  [`schema definition`]: `definicja schematu`,
  [`schemes list`]: `lista schematów`,
  [`security definitions`]: `definicje zabezpieczeń`,
  [`security schemes`]: `schematy zabezpieczeń`,
  [`servers`]: `serwery`,
  [`services`]: `usługi`,
  [`tags`]: `znaczniki`,
  [`tags and external documentation`]: `znaczniki i dokumentacja zewnętrzna`,
  [`target services`]: `usługi docelowe`,
  [`tasks`]: `zadania`,
  [`user registries`]: `rejestry użytkowników`,
  [`HTTP header`]: `Nagłówek HTTP`,
  [`You currently don’t have any application authentication source.`]: `Obecnie nie ma żadnego źródła uwierzytelniania aplikacji.`,
  [`You currently don’t have any API or product.`]: `Obecnie nie masz żadnych interfejsów API ani produktów.`,
  [`You currently don’t have any API user registries`]: `Obecnie nie masz żadnych rejestrów użytkowników interfejsu API`,
  [`You currently don’t have any APIs.`]: `Obecnie nie masz żadnych interfejsów API.`,
  [`You currently don’t have any TLS client profiles`]: `Obecnie nie masz żadnych profili klienta TLS`,
  [`You currently don’t have any base endpoints.`]: `Obecnie nie masz żadnych podstawowych punktów końcowych.`,
  [`You currently don’t have any catalog properties.`]: `Obecnie nie ma żadnych właściwości katalogu.`,
  [`You currently don’t have any categories.`]: `Obecnie nie ma żadnych kategorii.`,
  [`You currently don’t have any certificates.`]: `Obecnie nie ma żadnych certyfikatów.`,
  [`You currently don’t have any channels.`]: `Obecnie nie ma żadnych kanałów.`,
  [`You currently don’t have any consumer organizations.`]: `Obecnie nie masz żadnych organizacji konsumentów.`,
  [`You currently don’t have any consumer organizations or groups.`]: `Obecnie nie masz żadnych organizacji konsumentów ani grup.`,
  [`You currently don’t have any consumes.`]: `Obecnie nie ma żadnych konsumentów.`,
  [`You currently don’t have any correlation ids.`]: `Obecnie nie ma żadnych identyfikatorów korelacji.`,
  [`You currently don’t have any cors policy.`]: `Obecnie nie ma żadnej strategii CORS.`,
  [`You currently don’t have any definitions.`]: `Obecnie nie ma żadnych definicji.`,
  [`You currently don’t have any examples.`]: `Obecnie nie ma żadnych przykładów.`,
  [`You currently don’t have any gateway services.`]: `Obecnie nie masz żadnych usług bramy.`,
  [`You currently don’t have any groups.`]: `Obecnie nie masz żadnych grup.`,
  [`You currently don’t have any headers.`]: `Obecnie nie ma żadnych nagłówków.`,
  [`You currently don’t have any keystore.`]: `Obecnie nie ma żadnego magazynu kluczy.`,
  [`You currently don’t have any links.`]: `Obecnie nie ma żadnych odsyłaczy.`,
  [`You currently don’t have any messages.`]: `Obecnie nie ma żadnych komunikatów.`,
  [`You currently don’t have any message traits.`]: `Obecnie nie ma żadnych cech komunikatów.`,
  [`You currently don’t have any OAuth providers`]: `Obecnie nie masz żadnych dostawców OAuth`,
  [`You currently don’t have any operation traits.`]: `Obecnie nie ma żadnych cech operacji.`,
  [`You currently don’t have any parameter definitions.`]: `Obecnie nie ma żadnych definicji parametrów.`,
  [`You currently don’t have any parameters.`]: `Obecnie nie masz żadnych parametrów.`,
  [`You currently don’t have any preserved request header.`]: `Obecnie nie ma żadnego zachowanego nagłówka żądania.`,
  [`You currently don’t have any preserved response header.`]: `Obecnie nie ma żadnego zachowanego nagłówka odpowiedzi.`,
  [`You currently don’t have any produces.`]: `Obecnie nie ma żadnych produktów.`,
  [`You currently don’t have any properties.`]: `Obecnie nie masz żadnych właściwości.`,
  [`You currently don’t have any provider organizations.`]: `Obecnie nie masz żadnych organizacji dostawców.`,
  [`You currently don’t have any request bodies.`]: `Obecnie nie ma żadnych treści żądań.`,
  [`You currently don’t have any response definitions.`]: `Obecnie nie ma żadnych definicji odpowiedzi.`,
  [`You currently don’t have any responses.`]: `Obecnie nie ma żadnych odpowiedzi.`,
  [`You currently don’t have any scopes.`]: `Obecnie nie masz żadnych zasięgów.`,
  [`You currently don’t have any schemas.`]: `Obecnie nie ma żadnych schematów.`,
  [`You currently don’t have any schemes list.`]: `Obecnie nie ma żadnej listy schematów.`,
  [`You currently don’t have any security definitions.`]: `Obecnie nie masz żadnych definicji zabezpieczeń.`,
  [`You currently don’t have any security schemes.`]: `Obecnie nie ma żadnych schematów zabezpieczeń.`,
  [`You currently don’t have any servers.`]: `Obecnie nie ma żadnych serwerów.`,
  [`You currently don’t have any services.`]: `Obecnie nie masz żadnych usług.`,
  [`You currently don’t have any tags.`]: `Obecnie nie ma żadnych znaczników.`,
  [`You currently don’t have any tags and external documentation.`]: `Obecnie nie masz żadnych znaczników ani dokumentacji zewnętrznej.`,
  [`You currently don’t have any target services.`]: `Obecnie nie ma żadnych usług docelowych.`,
  [`You currently don’t have any tasks.`]: `Obecnie nie masz żadnych zadań.`,
  [`You currently don’t have any truststore.`]: `Obecnie nie ma żadnego magazynu zaufanych certyfikatów.`,
  [`You currently don’t have any user registries.`]: `Obecnie nie masz żadnych rejestrów użytkowników.`,
  [`You currently don’t have any extensions.`]: `Obecnie nie masz żadnych rozszerzeń.`,
  [`You currently don’t have any HTTP header.`]: `Obecnie nie masz żadnych nagłówków HTTP.`,
  [`Your account is being activated. This may take a while...`]: `Twoje konto jest właśnie aktywowane. Może to chwilę potrwać...`,
  [`all of`]: `wszystkie z`,
  [`any of`]: `jakikolwiek z`,
  [`calls`]: `wywołania`,
  [`ciphers`]: `szyfry`,
  [`extensions`]: `rozszerzenia`,
  [`one of`]: `jeden z`,
  [`shared`]: `współużytkowane`,
  [`stopping`]: `zatrzymywanie`,
  [`to:`]: `do:`,
  [`Key`]: `Klucz`,
  [`kind`]: `rodzaj`,
  [`Trace`]: `Wynik śledzenia`,
  [`Http Status`]: `Status HTTP`,
  [`Parsed`]: `Przeanalizowano`,
  [`Raw`]: `Nieprzetworzone`,
  [`Showing Full Trace`]: `Pokazywanie całego wyniku śledzenia`,
  [`Show Entire Trace`]: `Pokaż cały wynik śledzenia`,
  [`Show advanced`]: `Pokaż zaawansowane`,
  [`Advanced`]: `Zaawansowane`,
  [`Sending Request`]: `Wysyłanie żądania`,
  [`Please send a request above`]: `Wyślij żądanie powyżej`,
  [`Please send a request above.`]: `Wyślij żądanie powyżej.`,
  [`Authentication Type`]: `Typ uwierzytelniania`,
  [`You don't currently have any response`]: `Obecnie nie masz żadnych odpowiedzi`,
  [`You don't currently have any trace.`]: `Obecnie nie masz żadnych zapisów śledzenia.`,
  [`No response received`]: `Nie odebrano żadnej odpowiedzi`,
  [`Clicking “Open link to accept exception” will open the server in a new tab. If the browser displays a certificate issue, you may choose to accept it and return here to test again.`]: `Kliknięcie opcji „Otwórz odsyłacz, aby zaakceptować wyjątek” spowoduje otwarcie serwera na nowej karcie. Jeśli przeglądarka wyświetli komunikat o problemie z certyfikatem, możesz go zaakceptować i wrócić tutaj, aby ponownie wykonać test.`,
  [`Open link to accept exception`]: `Otwórz odsyłacz, aby zaakceptować wyjątek`,
  [`Back to {name}`]: `Powrót do {name}`,
  [`0 seconds`]: `0 sekund`,
  [`close.menu`]: `Zamknij menu`,
  [`open.menu`]: `Otwórz menu`,
  [`Referenced from "{ref}"`]: `Przywoływane z "{ref}"`,
  [`Go to {definition}`]: `Idź do {definition}`,
  [`General`]: `Ogólne`,
  [`Servers`]: `Serwery`,
  [`External Docs`]: `Dokumenty zewnętrzne`,
  [`Components`]: `Komponenty`,
  [`Schemas`]: `Schematy`,
  [`Responses`]: `Odpowiedzi`,
  [`Examples`]: `Przykłady`,
  [`Request Bodies`]: `Treści żądań`,
  [`Security Schemes`]: `Schematy zabezpieczeń`,
  [`Links`]: `Odsyłacze`,
  [`Callbacks`]: `Wywołania zwrotne`,
  [`Terms of Service`]: `Warunki korzystania z usługi`,
  [`Contact Name`]: `Nazwisko osoby kontaktowej`,
  [`Contact URL`]: `Adres URL kontaktu`,
  [`Contact Email`]: `Adres e-mail osoby kontaktowej`,
  [`Contact sales`]: `Kontakt ze sprzedażą`,
  [`License Name`]: `Nazwa licencji`,
  [`License URL`]: `Adres URL licencji`,
  [`Content-Type`]: `Nagłówek Content-Type (typ treści)`,
  [`Accept-Language`]: `Accept-Language`,
  [`Accept MTOM/XOP optimized messages`]: `Akceptuj wiadomości z optymalizacją MTOM/XOP`,
  [`User-Agent`]: `User-Agent`,
  [`token`]: `token`,
  [`Tag`]: `Znacznik`,
  [`Request Body`]: `Treść żądania`,
  [`Security Requirements`]: `Wymagania dotyczące bezpieczeństwa`,
  [`Located In`]: `Znajduje się w`,
  [`Example Value`]: `Wartość przykładowa`,
  [`External Value`]: `Wartość zewnętrzna`,
  [`Server`]: `Serwer`,
  [`Server Description`]: `Opis serwera`,
  [`Server Variables`]: `Zmienne serwera`,
  [`Format`]: `Format`,
  [`Pattern`]: `Wzorzec`,
  [`Multiple Of`]: `Wielokrotność`,
  [`Multiple non null slicing arguments`]: `Wiele argumentów przekrojów różnych od null`,
  [`Missing slicing arguments`]: `Brak argumentów przekrojów`,
  [`Missing sized fields`]: `Brak pól o określonej wielkości`,
  [`Maximum`]: `Maksimum`,
  [`Exclusive Maximum`]: `Maksimum wyłączne`,
  [`Minimum`]: `Minimum`,
  [`Exclusive Minimum`]: `Minimum wyłączne`,
  [`Max Length`]: `Maks. długość`,
  [`Min Length`]: `Min. długość`,
  [`Max Items`]: `Maks. elementów`,
  [`Min Items`]: `Min. elementów`,
  [`Unique Items`]: `Unikalne elementy`,
  [`Max Properties`]: `Maks. właściwości`,
  [`Min Properties`]: `Min. właściwości`,
  [`Required Properties`]: `Wymagane właściwości`,
  [`Required Property Name`]: `Nazwa wymaganej właściwości`,
  [`Enum`]: `Wyliczenie`,
  [`Enum Value`]: `Wartość wyliczeniowa`,
  [`Nullable`]: `Dozwolona wartość null`,
  [`Read Only`]: `Tylko do odczytu`,
  [`Write Only`]: `Tylko do zapisu`,
  [`api`]: `api`,
  [`version`]: `version`,
  [`username`]: `username`,
  [`password`]: `password`,
  [`service`]: `service`,
  [`Allows Empty Value`]: `Dozwolona wartość pusta`,
  [`Style`]: `Styl`,
  [`Explode`]: `Rozwiń`,
  [`Allow Reserved`]: `Zezwalaj na zarezerwowane`,
  [`Allow WebSocket Upgrade`]: `Zezwalaj na aktualizację WebSocket`,
  [`Ref`]: `Ref.`,
  [`Operation ID`]: `Id. operacji`,
  [`Parameter`]: `Parametr`,
  [`Gateway and portal settings`]: `Ustawienia bramy i portalu`,
  [`Targets`]: `Cele`,
  [`Catalog Properties`]: `Właściwości katalogu`,
  [`catalog properties`]: `właściwości katalogu`,
  [`Attachments`]: `Załączniki`,
  [`Activity Log`]: `Dziennik działań`,
  [`API Type`]: `Typ interfejsu API`,
  [`Phase`]: `Faza`,
  [`Enable CORS`]: `Włącz mechanizm CORS`,
  [`CORS Policy`]: `Strategia mechanizmu CORS`,
  [`Allowed Origins`]: `Dozwolone pochodzenia`,
  [`Allow Credentials`]: `Zezwalaj na referencje`,
  [`ID`]: `Identyfikator`,
  [`Success Content`]: `Treść z powodzeniem`,
  [`Error Content`]: `Treść z błędem`,
  [`Attachment`]: `Załącznik`,
  [`Link`]: `Odsyłacz`,
  [`Operation Ref`]: `Ref. operacji`,
  [`TRACE`]: `WYNIK ŚLEDZENIA`,
  [`No Security Requirements available`]: `Brak dostępnych wymagań dotyczących bezpieczeństwa`,
  [`Create a security scheme`]: `Utwórz schemat zabezpieczeń`,
  [`Create a Security Requirement`]: `Utwórz wymaganie dotyczące bezpieczeństwa`,
  [`Require one of the following Security Requirements. If unchecked, security is optional.`]: `Egzekwuj jedno z następujących wymagań dotyczących bezpieczeństwa. Zabezpieczenia niezaznaczone są opcjonalne.`,
  [`Require wrappers on fault details specified by type`]: `Wymagaj opakowań szczegółów błędu określonych według typu`,
  [`There are no "Servers"`]: `Nie ma serwerów`,
  [`Write`]: `Zapisz`,
  [`Server Variable`]: `Zmienna serwera`,
  [`Security Requirement`]: `Wymaganie dotyczące bezpieczeństwa`,
  [`Security Scheme Type`]: `Typ schematu zabezpieczeń`,
  [`Security Scheme`]: `Schemat zabezpieczeń`,
  [`OAuth Flows`]: `Przepływy OAuth`,
  [`Implicit Flow`]: `Przepływ niejawny`,
  [`Password Flow`]: `Przepływ hasła`,
  [`Client Credentials Flow`]: `Przepływ referencji klienta`,
  [`Authorization Code Flow`]: `Przepływ kodu autoryzacji`,
  [`Refresh URL`]: `Adres URL odświeżania`,
  [`schema`]: `schemat`,
  [`Allow Empty Value`]: `Zezwalaj na wartość pustą`,
  [`Encoding`]: `Kodowanie`,
  [`This field is required`]: `To pole jest wymagane`,
  [`Temporary token`]: `Token tymczasowy`,
  [`Access token`]: `Token dostępu`,
  [`ID token`]: `Token identyfikatora`,
  [`No Security Schemes available`]: `Brak dostępnych schematów zabezpieczeń`,
  [`Security Scheme (Key)`]: `Schemat zabezpieczeń (klucz)`,
  [`There are no "{label}"`]: `Nie ma elementów "{label}"`,
  [`You must select at least one scheme to define a Security Requirement`]: `Aby zdefiniować wymaganie dotyczące bezpieczeństwa, należy wybrać co najmniej jeden schemat`,
  [`Select one or more Security Schemes`]: `Wybierz co najmniej jeden schemat zabezpieczeń`,
  [`No OAuth Scopes available for the "{key}" Security Scheme`]: `Brak dostępnych zasięgów OAuth dla schematu zabezpieczeń "{key}"`,
  [`Create a Scope`]: `Utwórz zasięg`,
  [`Remove this Security Requirement`]: `Usuń to wymaganie dotyczące bezpieczeństwa`,
  [`Edit this Security Requirement`]: `Edytuj to wymaganie dotyczące bezpieczeństwa`,
  [`Invalid Security Scheme Reference`]: `Niepoprawne odwołanie do schematu zabezpieczeń`,
  [`The following Security Schemes do not exist!`]: `Następujące schematy zabezpieczeń nie istnieją`,
  [`Edit source`]: `Edytuj źródło`,
  [`OR`]: `LUB`,
  [`Required Security Schemes`]: `Wymagane schematy zabezpieczeń`,
  [`Optional Security`]: `Opcjonalne zabezpieczenia`,
  [`Optional custom expression (one per line)`]: `Opcjonalne wyrażenie niestandardowe (jedno na wiersz)`,
  [`Flow Type`]: `Typ przepływu`,
  [`OAuth2 Security Scheme`]: `Schemat zabezpieczeń OAuth2`,
  [`Only source editing is supported.`]: `Obsługiwane jest tylko edytowanie źródła.`,
  [`Unsupported Field.`]: `Nieobsługiwane pole.`,
  [`Select an option`]: `Wybierz opcję`,
  [`Select {labelText}`]: `Wybierz {labelText}`,
  [`'"{changedValue}" is already used'`]: `'Wartość "{changedValue}" jest już używana'`,
  [`Referenced from "{originalRef}"`]: `Przywoływane z "{originalRef}"`,
  [`Go to {label}`]: `Idź do {label}`,
  [`Delete "{label}"`]: `Usuń "{label}"`,
  [`Are you sure you want to delete "{label}"?`]: `Czy na pewno chcesz usunąć element "{label}"?`,
  [`No recent items found`]: `Nie znaleziono ostatnich elementów`,
  [`Found {numDocs} results ({took} seconds)`]: `Liczba znalezionych wyników: {numDocs} ({took} s)`,
  [`No Consumer Organizations found`]: `Nie znaleziono organizacji konsumentów`,
  [`No Catalog Products found`]: `Nie znaleziono produktów katalogu`,
  [`No APIs found`]: `Nie znaleziono interfejsów API`,
  [`No Catalogs found`]: `Nie znaleziono katalogów`,
  [`No Spaces found`]: `Nie znaleziono obszarów`,
  [`No results found`]: `Nie znaleziono żadnych wyników`,
  [`No Catalog APIs found`]: `Nie znaleziono interfejsów API katalogu`,
  [`No Catalogs found matching query "{query}"`]: `Nie znaleziono katalogów pasujących do zapytania "{query}"`,
  [`No APIs found matching query "{query}"`]: `Nie znaleziono interfejsów API pasujących do zapytania "{query}"`,
  [`No Subscriptions found`]: `Nie znaleziono subskrypcji`,
  [`No Plans found matching query "{query}"`]: `Nie znaleziono planów pasujących do zapytania "{query}"`,
  [`No Subscriptions found matching query "{query}"`]: `Nie znaleziono subskrypcji pasujących do zapytania "{query}"`,
  [`No Catalog Products found matching query "{query}"`]: `Nie znaleziono produktów katalogu pasujących do zapytania "{query}"`,
  [`No Catalog APIs found matching query "{query}"`]: `Nie znaleziono interfejsów API katalogu pasujących do zapytania "{query}"`,
  [`No Consumer Organizations found matching query "{query}"`]: `Nie znaleziono organizacji konsumentów pasujących do zapytania "{query}"`,
  [`No Applications found matching query "{query}"`]: `Nie znaleziono aplikacji pasujących do zapytania "{query}"`,
  [`APIs per page`]: `Liczba interfejsów API na stronie`,
  [`Catalogs per page`]: `Liczba katalogów na stronie`,
  [`items per page`]: `Liczba elementów na stronie`,
  [`{min}-{max} of {total} APIs`]: `{min}–{max} z {total} interfejsów API`,
  [`{min}-{max} of {total} API`]: `API: {min}-{max} z {total}`,
  [`Products per page`]: `Liczba produktów na stronie`,
  [`Product should be published on the catalog to be able to configure a new billing integration`]: `Produkt powinien zostać opublikowany w katalogu, aby możliwe było skonfigurowanie nowej integracji fakturowania.`,
  [`{min}-{max} of {total} Products`]: `{min}–{max} z {total} produktów`,
  [`{min}-{max} of {total} Product`]: `Produkty: {min}-{max} z {total}`,
  [`Catalog Products per page`]: `Liczba produktów katalogu na stronie`,
  [`{min}-{max} of {total} Catalog Products`]: `{min}–{max} z {total} produktów katalogu`,
  [`{min}-{max} of {total} Catalog Product`]: `Produkty katalogu: {min}-{max} z {total}`,
  [`Plans per page`]: `Liczba planów na stronie`,
  [`Policies per page`]: `Liczba strategii na stronie`,
  [`{min}-{max} of {total} Plans`]: `{min}–{max} z {total} planów`,
  [`{min}-{max} of {total} Plan`]: `Plany: {min}-{max} z {total}`,
  [`Catalog APIs per page`]: `Liczba interfejsów API katalogu na stronie`,
  [`{min}-{max} of {total} Catalog APIs`]: `{min}–{max} z {total} interfejsów API katalogu`,
  [`{min}-{max} of {total} Catalog API`]: `API katalogu: {min}-{max} z {total}`,
  [`Subscriptions per page`]: `Liczba subskrypcji na stronie`,
  [`{min}-{max} of {total} Subscriptions`]: `{min}—{max} z {total} subskrypcji`,
  [`{min}-{max} of {total} Subscription`]: `Subskrypcje: {min}-{max} z {total}`,
  [`Consumer Organizations per page`]: `Liczba organizacji konsumentów na stronie`,
  [`{min}-{max} of {total} Consumer Organizations`]: `{min}–{max} z {total} organizacji konsumentów`,
  [`{min}-{max} of {total} Consumer Organization`]: `Organizacje klientów: {min}-{max} z {total}`,
  [`Applications per page`]: `Liczba aplikacji na stronie`,
  [`{min}-{max} of {total} Applications`]: `{min}–{max} z {total} aplikacji`,
  [`{min}-{max} of {total} Application`]: `Aplikacje: {min}-{max} z {total}`,
  [`Histories per page`]: `Liczba historii na stronie`,
  [`{min}-{max} of {total} Histories`]: `{min}-{max} z {total} historii`,
  [`{min}-{max} of {total} History`]: `Historie: {min}-{max} z {total}`,
  [`No Plans found that may be subscribed to. A Product must be in the "published" state before a subscription may be created. An application may only subscribe to one plan in a product.`]: `Nie znaleziono planów, które można zasubskrybować. Aby możliwe było utworzenie subskrypcji, stan produktu musi być oznaczony jako "opublikowany". Aplikacja może subskrybować tylko jeden plan w produkcie.`,
  [`Showing published "{draftApiTitle}:{draftApiVersion}" APIs`]: `Pokazywanie opublikowanych interfejsów API "{draftApiTitle}:{draftApiVersion}"`,
  [`Showing Consumer Organization for "{appTitle}"`]: `Pokazywanie organizacji konsumenta dla "{appTitle}"`,
  [`Showing Subscriptions for "{productTitle}:{productVersion}"`]: `Pokazywanie subskrypcji dla "{productTitle}:{productVersion}"`,
  [`Showing Plans for "{productTitle}:{productVersion}"`]: `Pokazywanie planów dla "{productTitle}:{productVersion}"`,
  [`Showing APIs for "{productTitle}:{productVersion}"`]: `Pokazywanie interfejsów API dla "{productTitle}:{productVersion}"`,
  [`Showing Product for "{planTitle}"`]: `Pokazywanie produktu dla "{planTitle}"`,
  [`Showing APIs for "{planTitle}"`]: `Pokazywanie interfejsów API dla "{planTitle}"`,
  [`Showing Subscriptions for Plan: "{docTitle}" in Product: "{docFoundIn}"`]: `Pokazywanie subskrypcji dla planu: "{docTitle}" w produkcie: "{docFoundIn}"`,
  [`Showing Plans for "{apiTitle}:{apiVersion}"`]: `Pokazywanie planów dla "{apiTitle}:{apiVersion}"`,
  [`Showing Draft APIs for "{catalogApiTitle}:{catalogApiVersion}"`]: `Pokazywanie wersji roboczych interfejsów API dla "{catalogApiTitle}:{catalogApiVersion}"`,
  [`Showing Histories for "{productTitle}:{productVersion}"`]: `Wyświetlanie historii dla "{productTitle}:{productVersion}"`,
  [`Showing Subscriptions for "{appTitle}"`]: `Pokazywanie subskrypcji dla "{appTitle}"`,
  [`Showing Applications for "{consumerOrgTitle}"`]: `Pokazywanie aplikacji dla "{consumerOrgTitle}"`,
  [`Showing Subscriptions for "{consumerOrgTitle}"`]: `Pokazywanie subskrypcji dla "{consumerOrgTitle}"`,
  [`Showing Application for selected Subscription`]: `Pokazywanie aplikacji dla wybranej subskrypcji`,
  [`Showing Plan for selected Subscription`]: `Pokazywanie planu dla wybranej subskrypcji`,
  [`Showing Product for selected Subscription`]: `Pokazywanie produktu dla wybranej subskrypcji`,
  [`Select Draft APIs to include in the "{productTitle}" Product`]: `Wybierz wersje robocze interfejsów API, które mają być uwzględnione w produkcie "{productTitle}"`,
  [`Product Version`]: `Wersja produktu`,
  [`OAuth Providers`]: `Dostawcy OAuth`,
  [`Application Type`]: `Typ aplikacji`,
  [`Client IDs`]: `Identyfikatory klientów`,
  [`Save as New Version`]: `Zapisz jako nową wersję`,
  [`Update Selected APIs`]: `Aktualizuj wybrane interfejsy API`,
  [`This action is not reversible. The{titleAndVersion}API will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Interfejs API {titleAndVersion} zostanie trwale usunięty. Czy kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Product will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Produkt {titleAndVersion} zostanie trwale usunięty. Czy kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Catalog Product will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Produkt katalogu {titleAndVersion} zostanie trwale usunięty. Czy kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Organization will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Organizacja konsumenta {titleAndVersion} zostanie trwale usunięta. Czy kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Consumer Group will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Grupa konsumentów {titleAndVersion} zostanie trwale usunięta. Czy chcesz kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Policy will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Strategia {titleAndVersion} zostanie trwale usunięta. Czy kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Subscription will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Subskrypcja {titleAndVersion} zostanie trwale usunięta. Czy kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Catalog will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Katalog {titleAndVersion} zostanie trwale usunięty. Czy chcesz kontynuować?`,
  [`This action is not reversible. The{titleAndVersion}Space will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Obszar {titleAndVersion} zostanie trwale usunięty. Czy chcesz kontynuować?`,
  [`The{titleAndVersion}Catalog Product will Republish. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} zostanie ponownie opublikowany. Czy kontynuować?`,
  [`The{titleAndVersion}Catalog Product will Retire. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} zostanie wycofany. Czy kontynuować?`,
  [`The{titleAndVersion}Catalog Product will Deprecate. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} zostanie ustawiony jako nieaktualny. Czy kontynuować?`,
  [`The{titleAndVersion}Catalog Product will Re-stage. Do you want to continue?`]: `Produkt katalogu {titleAndVersion} zostanie ponownie przemieszczony. Czy kontynuować?`,
  [`Put Online`]: `Przełącz w tryb z połączeniem`,
  [`Take Offline`]: `Przełącz w tryb bez połączenia`,
  [`View Subscriptions`]: `Wyświetl subskrypcje`,
  [`View Plans`]: `Wyświetl plany`,
  [`No Tasks found`]: `Nie znaleziono zadań`,
  [`Tasks per page`]: `Liczba zadań na stronie`,
  [`{min}-{max} of {total} Tasks`]: `{min}–{max} z {total} zadań`,
  [`{min}-{max} of {total} Task`]: `Zadania: {min}-{max} z {total}`,
  [`Decline and Send`]: `Odrzuć i wyślij`,
  [`View Applications`]: `Wyświetl aplikacje`,
  [`Publish (Preserve Subscriptions)`]: `Opublikuj (zachowaj subskrypcje)`,
  [`Update APIs`]: `Aktualizuj interfejsy API`,
  [`View in Catalogs`]: `Wyświetl w katalogach`,
  [`View Consumer Organization`]: `Wyświetl organizację konsumenta`,
  [`View Application`]: `Wyświetl aplikację`,
  [`View Plan`]: `Wyświetl plan`,
  [`View Product`]: `Wyświetl produkt`,
  [`Create Subscription`]: `Utwórz subskrypcję`,
  [`No Applications found`]: `Nie znaleziono aplikacji`,
  [`Select a Plan to Create a Subscription to`]: `Wybierz plan, którego ma dotyczyć tworzona subskrypcja`,
  [`This action is not reversible. The{titleAndVersion}Application will be deleted permanently. Do you want to continue?`]: `Tego działania nie będzie można cofnąć. Aplikacja {titleAndVersion} zostanie trwale usunięta. Czy kontynuować?`,
  [`Save as new version`]: `Zapisz jako nową wersję`,
  [`Filter selected catalogs`]: `Filtruj wybrane katalogi`,
  [`Select draft APIs to include in the "{productTitle}" product`]: `Wybierz wersje robocze interfejsów API, które mają być uwzględnione w produkcie "{productTitle}"`,
  [`Update selected APIs`]: `Aktualizuj wybrane interfejsy API`,
  [`Catalog API`]: `Interfejs API katalogu`,
  [`Catalog APIs`]: `Interfejsy API katalogu`,
  [`View Products`]: `Wyświetl produkty`,
  [`View Draft APIs`]: `Wyświetl interfejsy API kopii roboczych`,
  [`Showing Products referencing "{apiTitle}:{apiVersion}"`]: `Pokazywanie produktów odwołujących się do "{apiTitle}:{apiVersion}"`,
  [`No Plans found`]: `Nie znaleziono planów`,
  [`View APIs`]: `Wyświetl interfejsy API`,
  [`Document type tabs`]: `Karty typów dokumentów`,
  [`No Products found`]: `Nie znaleziono produktów`,
  [`Consumer Organization`]: `Organizacja konsumenta`,
  [`Consumer Organizations`]: `Organizacje konsumenta`,
  [`Catalog Product`]: `Produkt katalogu`,
  [`Catalog Products`]: `Produkty katalogu`,
  [`Search for a Product and Plan`]: `Wyszukaj produkt i plan`,
  [`OpenAPI Version`]: `Wersja OpenAPI`,
  // develop dropdown strings
  [`API (from REST, GraphQL or SOAP)`]: `Interfejs API (z usług REST, GraphQL lub SOAP)`,
  [`AsyncAPI (from Kafka topic)`]: `AsyncAPI (z tematu Kafka)`,
  // async api create flow
  [`Create AsyncAPI (from Kafka topic)`]: `Utwórz AsyncAPI (z tematu Kafka)`,
  [`An error occurred during file upload`]: `Wystąpił błąd podczas przesyłania pliku`,
  [`Delete uploaded file`]: `Usuń przesłany plik`,
  [`Copied!`]: `Skopiowano!`,
  [`Uploading`]: `Przesyłanie`,
  [`Validation icon`]: `Ikona sprawdzania poprawności`,
  [`Validation error`]: `Błąd sprawdzania poprawności`,
  [`Uploading image..`]: `Przesyłanie obrazu...`,
  [`Unsupported media type {providedFileType}`]: `Nieobsługiwany typ danych multimedialnych {providedFileType}`,
  [`Select a supported file {expectedFileTypes} and try again`]: `Wybierz obsługiwany plik ({expectedFileTypes}) i spróbuj ponownie`,
  [`Pasted`]: `Wklejono`,
  [`Enter the API summary details.`]: `Wprowadź szczegóły podsumowania interfejsu API.`,
  [`Define your Kafka cluster connection details.`]: `Zdefiniuj szczegóły połączenia klastra Kafka.`,
  [`Channel`]: `Kanał`,
  [`Describe your Kafka topic and its associated schema.`]: `Opisz temat Kafka i powiązany z nim schemat.`,
  [`AsyncAPI specification version`]: `Wersja specyfikacji AsyncAPI`,
  [`A name that describes your API and helps developers discover it in the Developer Portal.`]: `Nazwa, która opisuje interfejs API i pomaga programistom wykryć go w portalu Developer Portal.`,
  [`A version for your API definition. The combination of Name and Version uniquely identifies your API definition.`]: `Wersja definicji interfejsu API. Kombinacja nazwy i wersji jednoznacznie identyfikuje definicję interfejsu API.`,
  [`Description (optional)`]: `Opis (opcjonalny)`,
  [`Introduce your API and provide helpful information to developers consuming it.`]: `Przedstaw swój interfejs API i zamieść informacje przydatne dla programistów, którzy będą go używać.`,
  [`Summary (optional)`]: `Podsumowanie (opcjonalne)`,
  [`A brief summary of the events contained in this API. Maximum 1000 characters.`]: `Krótkie podsumowanie zdarzeń zawartych w tym interfejsie API. Maksymalna liczba znaków: 1000.`,
  [`Bootstrap servers`]: `Serwery startowe`,
  [`A list of the Kafka brokers in your cluster, separated by commas.`]: `Lista brokerów Kafka w klastrze (rozdzielanych przecinkami).`,
  [`Secure the API using an API Key and Secret`]: `Zabezpiecz interfejs API przy użyciu klucza i danych niejawnych API`,
  [`The credential provided should be in SASL-PLAIN format`]: `Podana referencja powinna być zapisana w formacie SASL-PLAIN`,
  [`Publish this API:`]: `Opublikuj ten interfejs API:`,
  [`Creates a product`]: `Tworzy produkt`,
  [`Associates this API to the product`]: `Kojarzy ten interfejs API z produktem`,
  [`Publish the product to the Sandbox catalog`]: `Publikuje produkt w katalogu środowiska testowego`,
  [`Associates the product to the test app`]: `Kojarzy produkt z aplikacją testową`,
  [`A schema in "{fileType}" format has been successfully uploaded.`]: `Schemat w formacie "{fileType}" został pomyślnie przesłany i sprawdzony.`,
  [`The uploaded file is not a valid Avro Schema, and will not be included as a part of your API.`]: `Przesłany plik nie jest poprawnym schematem Avro i nie będzie stanowił części interfejsu API.`,
  [`Server response: "{err}". Click "Back" to return to the API definition page.`]: `Odpowiedź serwera: "{err}". Kliknij przycisk "Wstecz", aby powrócić do strony definicji interfejsu API.`,
  [`Server response: "{err}". Click "Done" to exit the wizard.`]: `Odpowiedź serwera: "{err}". Kliknij przycisk "Gotowe", aby zakończyć pracę z kreatorem.`,
  [`Topic name`]: `Nazwa tematu`,
  [`The name of the topic you want to expose as a part of this API. A topic is a named stream of messages.`]: `Nazwa tematu, który ma być eksponowany jako część tego interfejsu API. Temat jest nazwanym strumieniem wiadomości.`,
  [`Schema for message body in .avsc format (optional)`]: `Schemat treści komunikatu w formacie .avsc (opcjonalny)`,
  [`Drag and drop files here, or click to upload`]: `Przeciągnij i upuść pliki tutaj lub kliknij, aby przesłać pliki.`,
  [`About this step`]: `O tym kroku`,
  [`Events can be described as asynchronous APIs because consumers receive events as they become available. Synchronous APIs on the other hand, such as REST, only receive responses when they request them.`]: `Zdarzenia można opisać jako asynchroniczne interfejsy API, ponieważ konsumenci odbierają zdarzenia w miarę jak stają się one dostępne. Z kolei synchroniczne interfejsy API, takie jak REST, otrzymują odpowiedzi tylko wtedy, gdy ich żądają.`,
  [`Event-driven asynchronous APIs are documented by using the AsyncAPI specification.`]: `Asynchroniczne interfejsy API oparte na zdarzeniach są udokumentowane w specyfikacji AsyncAPI.`,
  [`API secret`]: `Dane niejawne API`,
  [`Generated AsyncAPI 2.1.0 definition`]: `Wygenerowano definicję AsyncAPI 2.1.0`,
  [`API creating`]: `Tworzenie interfejsu API`,
  [`An error occurred while creating your document`]: `Wystąpił błąd podczas tworzenia dokumentu`,
  [`Publishing your API`]: `Publikowanie interfejsu API`,
  [`Your API has been published`]: `Interfejs API został opublikowany`,
  [`An error occurred while publishing your API`]: `Podczas publikowania interfejsu API wystąpił błąd`,
  [`Configure security for this API`]: `Skonfiguruj zabezpieczenia dla tego interfejsu API`,
  [`Cluster connection security`]: `Zabezpieczenia połączenia z klastrem`,
  [`Provide the credentials Event Gateway Services would use to connect to your cluster when handling requests to this API. You can change these values later in the editor. For additional information, see documentation on creating credentials.`]: `Podaj referencje, których usługi bramy zdarzeń będą używały do łączenia się z klastrem podczas obsługi żądań kierowanych do tego interfejsu API. Te wartości można zmienić później w edytorze. Więcej informacji na ten temat zawiera dokumentacja dotycząca tworzenia danych uwierzytelniających.`,
  [`SASL Username`]: `Nazwa użytkownika SASL`,
  [`SASL Password`]: `Hasło SASL`,
  [`Transport CA certificate (.pem file - optional)`]: `Certyfikat ośrodka CA dla transportu (plik .pem - opcjonalny)`,
  [`If provided, this will be used by the Event Gateway Service to provide TLS encryption for the communication between the gateway and the Kafka cluster.`]: `Jeśli zostanie podany, będzie używany przez usługę bramy zdarzeń w celu zapewnienia szyfrowania TLS komunikacji między bramą a klastrem Kafka.`,
  [`Describe the API details of your Apache Kafka event source to generate an AsyncAPI document.`]: `Opisz szczegóły interfejsu API źródła zdarzeń Apache Kafka w celu wygenerowania dokumentu AsyncAPI.`,
  [`You can then publish the AsyncAPI document to expose the event source to application developers, who can configure their applications to subscribe to the related stream of events.`]: `Następnie można opublikować dokument AsyncAPI w celu ujawnienia źródła zdarzeń dla programistów aplikacji, którzy będą mogli skonfigurować swoje aplikacje w taki sposób, aby subskrybowały odpowiedni strumień zdarzeń.`,
  [`Set up security for your API if you want to control who can access it.`]: `Skonfiguruj zabezpieczenia interfejsu API, jeśli chcesz wpływać na to, kto będzie miał do niego dostęp.`,
  [`Access to your API will be managed by an Event Gateway Service that checks for a valid API key and secret.`]: `Dostęp do interfejsu API będzie zarządzany przez usługę bramy zdarzeń sprawdzającą poprawność klucza i danych niejawnych API.`,
  [`Gateways require clients to encrypt their communication with TLS.`]: `Bramy wymagają od klientów szyfrowania ich komunikacji przy użyciu protokołu TLS.`,
  [`After your API is created, click ‘Edit API’ to add more detail, such as security details to connect to your Kafka cluster from your Event Gateway Service.`]: `Po utworzeniu interfejsu API kliknij opcję Edytuj interfejs API, aby dodać więcej szczegółów, takich jak szczegóły zabezpieczeń potrzebne do nawiązywania połączeń z klastrem Kafka z usługi bramy zdarzeń.`,
  [`You can then publish your API, making it available to developers who can then discover and request access to the API, and use the information to set their applications to consume from the event source.`]: `Następnie można opublikować interfejs API, udostępniając go programistom, którzy będą mogli wykrywać interfejs API i żądać do niego dostępu, a także używać informacji potrzebnych aplikacjom do korzystania ze źródła zdarzeń.`,
  [`Pending backend response`]: `Oczekująca odpowiedź zaplecza`,
  [`Complete`]: `Zakończono:`,
  [`Rate limiting is only applied to enforced APIs`]: `Ograniczenie częstotliwości jest stosowane tylko w przypadku wymuszonych interfejsów API`,
  [`No Plans Available`]: `Brak dostępnych planów`,
  [`Modal content is not provided`]: `Nie podano treści modalnej`,
  [`Modal heading is not provided`]: `Nie podano nagłówka modalnego`,
  [`Edit Rate Limit`]: `Edytuj limit częstotliwości`,
  [`External Documentation`]: `Dokumentacja zewnętrzna`,
  [`No Label Available`]: `Brak dostępnej etykiety`,
  [`optional`]: `(opcjonalnie)`,
  [`Uploading image...`]: `Przesyłanie obrazu...`,
  [`Attach files by dragging, dropping, selecting or pasting them.`]: `Załączaj pliki przez przeciąganie, upuszczanie, wybieranie lub wklejanie.`,
  [`Response name`]: `Nazwa odpowiedzi`,
  [`Click Add to add a new {resource}.`]: `Kliknij przycisk Dodaj, aby dodać nowy zasób: {resource}.`,
  [`Schema Name (Key)`]: `Nazwa schematu (klucz)`,
  [`Xml`]: `Xml`,
  [`Namespace`]: `Przestrzeń nazw`,
  [`Attribute`]: `Atrybut`,
  [`Wrapped`]: `Opakowane`,
  [`Example`]: `Przykład`,
  [`Example Name (Key)`]: `Przykładowa nazwa (klucz)`,
  [`"{changedValue}" is already used`]: `Wartość "{changedValue}" jest już używana`,
  [`Name (Key)`]: `Nazwa (klucz)`,
  [`Content Type (Key)`]: `Typ treści (klucz)`,
  [`Header name`]: `Nazwa nagłówka`,
  [`Reference`]: `Informacje dodatkowe`,
  [`Security Scheme Name (Key)`]: `Nazwa schematu zabezpieczeń (klucz)`,
  [`Choose a Reference`]: `Wybierz odwołanie`,
  [`Reference from`]: `Odwołanie z`,
  [`Content Type`]: `Typ treści`,
  [`array`]: `tablica`,
  [`boolean`]: `wartość boolowska`,
  [`integer`]: `liczba całkowita`,
  [`number`]: `liczba`,
  [`object`]: `obiekt`,
  [`string`]: `Łańcuch`,
  [`query`]: `zapytanie`,
  [`cookie`]: `informacja cookie`,
  [`matrix`]: `macierz`,
  [`label`]: `etykieta`,
  [`simple`]: `prosty`,
  [`form`]: `formularz`,
  [`spaceDelimited`]: `rozdzielanySpacjami`,
  [`pipeDelimited`]: `rozdzielanyPionowąKreską`,
  [`deepObject`]: `głębokiObiekt`,
  [`OAuth Provider (Name)`]: `Dostawca OAuth (nazwa)`,
  [`Authenticate using User Registry (name)`]: `Uwierzytelniaj przy użyciu rejestru użytkowników (nazwa)`,
  [`Bearer Format`]: `Format posiadacza`,
  [`Server Variable Name`]: `Nazwa zmiennej serwera`,
  [`Verb (Key)`]: `Komenda (klucz)`,
  [`Parameter Definitions`]: `Definicje parametrów`,
  [`Path Parameters`]: `Parametry ścieżki`,
  [`Media Type List`]: `Lista typów multimediów`,
  [`Medium`]: `Średni`,
  [`Schemes List`]: `Lista schematów`,
  [`Small`]: `Mały`,
  [`Response Definitions`]: `Definicje odpowiedzi`,
  [`Terms Of Service`]: `Warunki korzystania z usługi`,
  [`Url`]: `Adres url`,
  [`Untitled Parameter`]: `Parametr bez tytułu`,
  [`Parameter Name`]: `Nazwa parametru`,
  [`Mime Type`]: `Typ Mime`,
  [`Async API`]: `Asynchroniczny interfejs API`,
  [`Channels`]: `Kanały`,
  [`Operation Traits`]: `Cechy operacji`,
  [`Messages`]: `Wiadomości`,
  [`Correlation IDs`]: `Identyfikatory korelacji`,
  [`Traits`]: `Cechy`,
  [`Message Traits`]: `Cechy wiadomości`,
  [`Bindings`]: `Powiązania`,
  [`Server Bindings`]: `Powiązania serwera`,
  [`Channel Bindings`]: `Powiązania kanału`,
  [`Operation Bindings`]: `Powiązania operacji`,
  [`Message Bindings`]: `Powiązania komunikatów`,
  [`Protocol Version`]: `Wersja protokołu`,
  [`Define multiple messages`]: `Definiuj wiele komunikatów`,
  [`Schema Format`]: `Format schematu`,
  [`Unsupported Field`]: `Nieobsługiwane pole`,
  [`OpenIdConnectUrl`]: `OpenIdConnectUrl`,
  [`Operation Trait`]: `Cecha operacji`,
  [`Message Trait`]: `Cecha komunikatu`,
  [`Use the Operation Switch policy to apply a section of the assembly to a specific operation.`]: `Użyj strategii Operation Switch, aby zastosować sekcję zespołu do konkretnej operacji.`,
  [`HTML page`]: `Strona HTML`,
  [`Rule`]: `Reguła`,
  [`Origin`]: `Początek`,
  [`Property Name (Key)`]: `Nazwa właściwości (klucz)`,
  [`Catalog Name (Key)`]: `Nazwa katalogu (klucz)`,
  [`Property Overrides`]: `Przesłonięcia właściwości`,
  [`Property Name`]: `Nazwa właściwości`,
  [`Property Value`]: `Wartość właściwości`,
  [`About subscriptions and plans`]: `Informacje o subskrypcjach i planach`,
  [`Subscription Plan Name`]: `Nazwa planu subskrypcji`,
  [`Select plan`]: `Wybierz plan`,
  [`Select the plan to migrate application subscriptions from`]: `Wybierz plan, z którego mają być migrowane subskrypcje aplikacji`,
  [`Select subscriptions`]: `Wybierz subskrypcje`,
  [`Select the application subscriptions to migrate`]: `Wybierz subskrypcje aplikacji do migracji`,
  [`Select the product to migrate application subscriptions to`]: `Wybierz produkt, do którego mają zostać zmigrowane subskrypcje aplikacji`,
  [`Select the plan to migrate application subscriptions to`]: `Wybierz plan, do którego mają zostać zmigrowane subskrypcje aplikacji`,
  [`Message successfully sent to {sent} owner(s)`]: `Wiadomość została pomyślnie wysłana do {sent} właściciela(-i)`,
  [`{failed}/{sent} messages failed to send`]: `Nie powiodło się wysłanie {failed}/{sent} wiadomości`,
  [`Failed email addresses`]: `Adresy e-mail, do których nie udało się wysłać wiadomości`,
  [`Failed to save the API`]: `Nie powiodło się zapisanie interfejsu API`,
  [`and {numExtra} more...`]: `i jeszcze {numExtra}...`,
  [`Copy to clipboard the list of email addresses that failed to send`]: `Skopiuj do schowka listę adresów e-mail, do których nie udało się wysłać wiadomości`,
  [`From`]: `Od`,
  [`Message Owner`]: `Wyślij wiadomość do właściciela`,
  [`Message owner`]: `Wyślij wiadomość do właściciela`,
  [`Message owners`]: `Wyślij wiadomość do właścicieli`,
  [`Message group`]: `Wyślij wiadomość do grupy`,
  [`Select consumer organizations`]: `Wybierz organizacje konsumentów`,
  [`Message consumer organization owners`]: `Wyślij wiadomość do właścicieli organizacji konsumenta`,
  [`Message consumer organization owner`]: `Właściciel organizacji konsumenta komunikatów`,
  [`Message provider organization owner`]: `Wyślij wiadomość do właściciela organizacji dostawcy`,
  [`The identifier for the Kafka cluster related to this Invoke policy`]: `Identyfikator klastra Kafka związany z tą strategią wywoływania`,
  [`A comma-separated list of host:port pairs to use for establishing connections to the Kafka cluster`]: `Lista rozdzielonych przecinkami par host:port używana do nawiązywania połączeń z klastrem Kafka`,
  [`The username to use when connecting to the Kafka cluster.`]: `Nazwa użytkownika, która ma być używana podczas nawiązywania połączenia z klastrem Kafka.`,
  [`The password to use when connecting to the Kafka cluster.`]: `Hasło, które ma być używane podczas nawiązywania połączenia z klastrem Kafka.`,
  // Event Gateway Service cloud manager strings
  [`Event Gateway Service`]: `Usługa bramy zdarzeń`,
  [`Event gateway management client keystore`]: `Magazyn kluczy klienta zarządzania bramą zdarzeń`,
  [`Edit Event gateway Service`]: `Edytuj usługę bramy zdarzeń`,
  [`Configure Event Gateway Service`]: `Konfiguruj usługę bramy zdarzeń`,
  [`Configure an Event Gateway Service for securing and enforcing asynchronous APIs`]: `Skonfiguruj usługę bramy zdarzeń do zabezpieczania i egzekwowania asynchronicznych interfejsów API`,
  [`Catalogs with published product`]: `Katalogi z opublikowanym produktem`,
  [`Cluster Config ID`]: `Id. konfiguracji klastra`,
  [`SASL mechanism`]: `Mechanizm SASL`,
  [`Security protocol`]: `Protokół zabezpieczeń`,
  [`Transport CA certificate`]: `Certyfikat ośrodka certyfikacji dla transportu`,
  [`Max file size is {size}. Supported file types are json, xml, yml, yaml & zip.`]: `Maksymalna wielkość pliku: {size}. Obsługiwane typy plików to: json, xml, yml, yaml oraz zip.`,
  [`Supported file types are json, xml, yml & yaml.`]: `Obsługiwane typy plików to: json, xml, yml oraz yaml.`,
  [`Supported file types are json, wsdl, xml, xsd, yml, yaml & zip.`]: `Obsługiwane typy plików to: json, wsdl, xml, xsd, yml, yaml oraz zip.`,
  [`Supported file types are json, wsdl, xml, yml & yaml.`]: `Obsługiwane typy plików to: json, wsdl, xml, yml oraz yaml.`,
  [`Select a file`]: `Wybierz plik`,
  [`or specify a file URL`]: `lub podaj adres URL pliku`,
  [`Generated OpenAPI {version} definition`]: `Wygenerowano definicję OpenAPI {version}`,
  [`User registry type`]: `Typ rejestru użytkowników`,
  [`User registry types`]: `Typy rejestrów użytkowników`,
  [`User registry type {title} has been created.`]: `Typ rejestru użytkowników {title} został utworzony.`,
  [`User registry type {title} has been updated.`]: `Typ rejestru użytkowników {title} został zaktualizowany.`,
  [`Failed to create user registry type {title}.`]: `Nie powiodło się utworzenie typu rejestru użytkowników {title}.`,
  [`Failed to update user registry type {title}.`]: `Nie powiodła się aktualizacja typu rejestru użytkowników {title}.`,
  [`Failed to update user registry {title}.`]: `Nie powiodła się aktualizacja rejestru użytkowników {title}.`,
  [`Custom user registry`]: `Niestandardowy rejestr użytkowników`,
  [`Create custom user registry`]: `Utwórz niestandardowe rejestr użytkowników`,
  [`Edit custom user registry`]: `Edytuj niestandardowy rejestr użytkowników`,
  [`Create user registry type`]: `Utwórz typ rejestru użytkowników`,
  [`Edit user registry type`]: `Edytuj typ rejestru użytkowników`,
  [`Configure user authentication using a custom external user registry`]: `Skonfiguruj uwierzytelnianie użytkownika przy użyciu niestandardowego zewnętrznego rejestru użytkowników`,
  [`Secured Endpoint`]: `Zabezpieczony punkt końcowy`,
  [`Configuration schema`]: `Schemat konfiguracji`,
  [`About custom user registries`]: `Informacje o niestandardowych rejestrach użytkowników`,
  [`Custom user registries can be used for authenticating users to the Developer Portal, but cannot be used to secure APIs. To configure a custom user registry as a resource in API Manager, the external user directory must be created and available to use with your API Connect ecosystem.`]: `Niestandardowe rejestry użytkowników mogą być używane do uwierzytelniania użytkowników w portalu Developer Portal, ale nie mogą być używane do zabezpieczania interfejsów API. Aby można było skonfigurować niestandardowy rejestr użytkowników jako zasób w produkcie API Manager, musi być dostępny zewnętrzny katalog użytkownika przystosowany do działania z ekosystemem produktu API Connect.`,
  [`Update Wsdl`]: `Zaktualizuj WSDL`,
  [`Update Existing WSDL service`]: `Zaktualizuj istniejącą usługę WSDL`,
  [`Format of selected WSDL has been successfully validated`]: `Poprawność formatu wybranego pliku WSDL została pomyślnie sprawdzona`,
  [`Select the target WSDL file to update from`]: `Wybierz docelowy plik WSDL, na podstawie którego zostanie przeprowadzona aktualizacja.`,
  [`Selected WSDL has been successfully uploaded`]: `Wybrany plik WSDL został pomyślnie przesłany`,
  [`Services available in selected API`]: `Usługi dostępne w wybranym interfejsie API`,
  [`Enable legacy Open API Editor for OpenAPI 2.0 documents (deprecated)`]: `Włącz wcześniejszy edytor Open API dla dokumentów OpenAPI 2.0 (nieaktualne)`,
  [`Unknown`]: `Nieznane`,
  [`{incompatibleCatalogCount} catalog(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `Katalogi w liczbie {incompatibleCatalogCount} zostały ukryte z powodu niezgodności z typem bramy określonym w interfejsie API lub w produkcie`,
  [`{incompatibleSpacesCount} space(s) hidden due to incompatibility with the gateway type specified in the API or product`]: `Obszary w liczbie {incompatibleSpacesCount} zostały ukryte z powodu niezgodności z typem bramy określonym w interfejsie API lub w produkcie`,
  [`Keep in mind`]: `Pamiętaj`,
  [`Only one type of enforced APIs is allowed per product`]: `Dla każdego produktu dozwolony jest tylko jeden typ wymuszonych interfejsów API`,
  [`{incompatibleAPIsCount} API(s) cannot be selected due to incompatibility with the gateway type specified in the product`]: `Nie można wybrać {incompatibleAPIsCount} interfejsu/-ów API z powodu niekompatybilności z typem bramy określonym w produkcie`,
  [`{incompatibleProductsCount} Product(s) cannot be selected due to incompatibility with the gateway type specified in the API`]: `Nie można wybrać {incompatibleProductsCount} produktu/-ów z powodu niekompatybilności z typem bramy określonym w interfejsie API`,
  [`Unenforced`]: `Niewymuszony`,
  [`Create a new product by adding APIs and plans.`]: `Utwórz nowy produkt, dodając interfejsy API i plany.`,
  [`Rate limits are only applied to enforced APIs.`]: `Limity częstotliwości są stosowane tylko w przypadku wymuszonych interfejsów API.`,
  [`Add plans and API limits to your product. Plan limit(s) only apply to enforced APIs and uneforced APIs will be unlimted.`]: `Dodaj plany i limity API do produktu. Limity planów mają zastosowanie tylko do wymuszonych interfejsów API, a niewymuszone interfejsy API nie podlegają ograniczeniom.`,
  [`Add plans and rate limits to your product. Plan(s) only apply to enforced APIs and unenforced APIs will be unlimited.`]: `Dodaj plany i limity częstotliwości do produktu. Plany mają zastosowanie tylko do wymuszonych interfejsów API, a niewymuszone interfejsy API podlegają ograniczeniom.`,
  [`Delete plan`]: `Usuń plan`,
  [`Select Event Gateway Service for AsyncAPIs. For all other types of APIs, select either DataPower API Gateway or the v5 compatible version. To update this setting, remove the associated APIs first.`]: `Wybierz usługę bramy zdarzeń dla interfejsów AsyncAPI. W przypadku wszystkich pozostałych typów interfejsów API należy wybrać bramę API DataPower Gateway lub kompatybilną wersję 5. Aby zmienić to ustawienie, należy najpierw usunąć powiązane z nim interfejsy API.`,
  [`Edit API list`]: `Edytuj listę interfejsów API`,
  [`Enforced API(s) in this product require more than one gateway type.`]: `Wymuszone interfejsy API w tym produkcie wymagają więcej niż jednego typu bramy.`,
  [`Enforced {apiType} in this product require the {gatewayType}.`]: `Wymuszony typ {apiType} w tym produkcie wymaga {gatewayType}`,
  [`OpenAPI 2.0 API(s)`]: `Interfejsy OpenAPI 2.0`,
  [`OpenAPI 3.0 API(s)`]: `Interfejsy OpenAPI 3.0`,
  [`AsyncAPI(s)`]: `Interfejsy AsyncAPI`,
  [`Select the gateway type for this Product`]: `Wybierz typ bramy dla tego produktu`,
  [`Added default plan`]: `Dodano plan domyślny`,
  [`Publish the product to the Sandbox catalog. This associates the product to the test app.`]: `Opublikuj produkt w katalogu środowiska testowego. Spowoduje to powiązanie produktu z aplikacją testową.`,
  [`cluster`]: `klaster`,
  [`{first_name} {last_name} ({email})`]: `{first_name} {last_name} ({email})`,
  [`Message from the rejector`]: `Wiadomość od odrzucającego`,
  [`Vendor extensions feature disabled`]: `Funkcja rozszerzenia dostawcy wyłączona`,
  [`No configured gateways found`]: `Nie znaleziono skonfigurowanych bram`,
  [`No OpenAPI extensions associated with the configured gateway`]: `Brak rozszerzeń OpenAPI skojarzonych ze skonfigurowaną bramą`,
  [`Extension`]: `Rozszerzenie`,
  [`Select the extensions to add to the API document`]: `Wybierz rozszerzenia, które mają zostać dodane do dokumentu API`,
  [`(Operations: {operations})`]: `(Operacje: {operations})`,
  [`Failed to get application`]: `Nie powiodło się pobranie aplikacji`,
  [`Failed to get credentials for application`]: `Nie powiodło się pobranie referencji dla aplikacji`,
  [`Failed to get published product`]: `Nie powiodło się pobranie opublikowanego produktu`,
  [`Failed to get the published API`]: `Nie powiodło się pobranie opublikowanego interfejsu API`,
  [`API must be activated to use the Test tool`]: `W celu użycia narzędzia testowego interfejs API musi być aktywowany`,
  [`Import from Asset repository`]: `Import z repozytorium zasobów`,
  [`Untitled {componentLabel}`]: `{componentLabel} bez tytułu`,
  [`Your license metric requires an Analytics Service to track usage. Click "Register Service" to configure an Analytics Service.`]: `Metryka Twojej licencji wymaga usługi analitycznej do śledzenia wykorzystania. Kliknij opcję „Zarejestruj usługę”, aby skonfigurować usługę Analytics Service.`,
  [`All DataPower gateway services require an Analytics Service to track usage. Click "Associate analytics service" for each gateway to comply with the license terms.`]: `Wszystkie usługi bramy DataPower wymagają usługi analitycznej, aby śledzić wykorzystanie. Kliknij opcję „Powiąż usługę analizy danych” dla każdej bramy, aby spełnić warunki licencji.`,
  [`Catalog is incompatibile with the gateway type specified in the API or product`]: `Katalog jest niekompatybilny z typem bramy określonym w interfejsie API lub w produkcie`,
  [`Space is incompatibile with the gateway type specified in the API or product`]: `Obszar jest niekompatybilny z typem bramy określonym w interfejsie API lub w produkcie`,
  [`Preserve Subscription`]: `Zachowaj subskrypcję`,
  [`Publish validation`]: `Sprawdzanie poprawności publikowania`,
  [`Publish validations`]: `Sprawdzenia poprawności podczas publikowania`,
  [`Edit publish validations`]: `Edytuj sprawdzanie poprawności publikowania`,
  [`The following validations will be performed when publishing a product`]: `Podczas publikowania produktu zostaną wykonane następujące operacje sprawdzenia poprawności`,
  [`There are no publish validations enabled`]: `Nie włączono przeprowadzania sprawdzenia poprawności podczas publikowania`,
  [`Publish validations have been updated`]: `Sprawdzenie poprawności podczas publikowania zostały zaktualizowane`,
  [`Provide the certificate authority (CA) that the Event Gateway Service uses to trust the backend Kafka server certificate. If the server is using a certificate issued by a well-known authority, you do not need to provide the CA.`]: `Podaj ośrodek certyfikacji (CA) używany przez usługę bramy zdarzeń, aby ufać certyfikatowi serwera zaplecza Kafka . Jeśli serwer korzysta z certyfikatu wystawionego przez powszechnie znany ośrodek certyfikacji, nie trzeba podawać ośrodka CA.`,
  [`The value entered for the bootstrap servers is not valid. Enter the host and port of each Kafka broker on your cluster, separated by commas. For example, broker1.com:9092,broker2.com:9092,broker3.com:9092.`]: `Wartość wprowadzona dla serwerów startowych jest niepoprawna. Wprowadź host i port każdego brokera Kafka w klastrze, rozdzielając je przecinkami. Na przykład broker1.com: 9092 ,broker2.com: 9092 ,broker3.com: 9092.`,
  [`Upload OpenAPI Extension`]: `Załaduj rozszerzenie OpenAPI`,
  [`Add OpenAPI Extension`]: `Dodaj rozszerzenie OpenAPI`,
  [`Gateway Endpoint`]: `Punkt końcowy bramy`,
  [`API Dashboard`]: `Panel kontrolny interfejsu API`,
  [`API call data`]: `Dane wywołania interfejsu API`,
  [`Product Dashboard`]: `Panel kontrolny produktu`,
  [`API Product consumption metrics`]: `Parametry wykorzystania produktu interfejsu API`,
  [`Monitoring Latency Dashboard`]: `Panel kontrolny opóźnienia monitorowania`,
  [`Data about the time taken to serve API calls`]: `Dane na temat czasu obsługi wywołań interfejsu API`,
  [`Monitoring Status Dashboard`]: `Panel kontrolny statusu monitorowania`,
  [`API Error and success information`]: `Informacje o błędach i powodzeniach interfejsu API`,
  [`Usage Dashboard`]: `Panel kontrolny użycia`,
  [`Usage for gateway {gateway}`]: `Wykorzystanie dla bramy {gateway}`,
  [`Latency for gateway {gateway}`]: `Opóźnienie dla bramy {gateway}`,
  [`Most popular products, APIs and applications`]: `Najpopularniejsze produkty, interfejsy API i aplikacje`,
  [`Consumption Dashboard`]: `Panel kontrolny wykorzystania`,
  [`Total API volume useful for auditing and compliance`]: `Łączny wolumen interfejsu API na potrzeby kontroli i zgodności`,
  [`Monthly Summary by Status Code`]: `Podsumowanie miesięczne wg kodu statusu`,
  [`Monthly Summary Data by Status Code`]: `Dane podsumowania miesięcznego wg kodu statusu`,
  [`Daily Summary by Status Code`]: `Podsumowanie dzienne wg kodu statusu`,
  [`Daily Summary Data by Status Code`]: `Dane podsumowania dziennego wg kodu statusu`,
  [`Response time (ms)`]: `Czas odpowiedzi (ms)`,
  [`Datetime`]: `Data/godzina`,
  [`Transaction ID:`]: `Identyfikator transakcji:`,
  [`Other`]: `Inna`,
  [`Date time`]: `Data i godzina`,
  [`Total calls`]: `Łączna liczba wywołań`,
  [`Date`]: `Data`,
  [`Month`]: `Miesiąc`,
  [`Minimum response time`]: `Minimalny czas odpowiedzi`,
  [`Maximum response time`]: `Maksymalny czas odpowiedzi`,
  [`Average response time`]: `Średni czas odpowiedzi`,
  [`Total API calls`]: `Łączna liczba wywołań interfejsu API`,
  [`Total APIs`]: `Funkcje API łącznie`,
  [`APIs called`]: `Wywołania API`,
  [`Total products`]: `Produkty łącznie`,
  [`products called`]: `produkty wywołane`,
  [`Total errors`]: `Łączna liczba błędów`,
  [`Status codes (detailed)`]: `Kody statusu (szczegółowe)`,
  [`Response time percentiles`]: `Centyle czasu odpowiedzi`,
  [`API calls per day`]: `Wywołania interfejsu API na dzień`,
  [`Time zone:`]: `Strefa czasowa:`,
  [`Time zone`]: `Strefa czasowa`,
  [`Toggle axes`]: `Przełącz osie`,
  [`Datetime:`]: `Data i godzina:`,
  [`datetime per 30 seconds`]: `data i godzina co 30 sekund`,
  [`Datetime (local time)`]: `Data i godzina (czas miejscowy)`,
  [`Data usage (bytes)`]: `Wykorzystanie danych (bajty)`,
  [`Time to Serve Request`]: `Czas na obsługę żądania`,
  [`API name`]: `Nazwa interfejsu API`,
  [`Product name`]: `Nazwa produktu`,
  [`Data usage (bytes received)`]: `Wykorzystanie danych (odebrane bajty)`,
  [`Data usage (bytes sent)`]: `Wykorzystanie danych (wysłane bajty)`,
  [`Response times (>1s)`]: `Czasy odpowiedzi (> 1 s)`,
  [`milliseconds`]: `milisekundy`,
  [`errors`]: `błędy`,
  [`Success Rate`]: `Współczynnik powodzenia`,
  [`Errors Data`]: `Dane o błędach`,
  [`Success Data`]: `Dane o powodzeniach`,
  [`Total consumer organizations`]: `Łączna liczba organizacji konsumenta`,
  [`making API calls`]: `wywołujących interfejsy API`,
  [`Applications per plan`]: `Aplikacje na plan`,
  [`Top products by calls`]: `Pierwsze produkty wg wywołań`,
  [`Top APIs by calls`]: `Pierwsze interfejsy API wg wywołań`,
  [`Top client IP addresses by calls`]: `Pierwsze adresy IP klientów wg wywołań`,
  [`Client IP address`]: `Adres IP klienta`,
  [`Top APIs by request size`]: `Pierwsze interfejsy API wg wielkości żądania`,
  [`Top APIs by response size`]: `Pierwsze interfejsy API wg wielkości odpowiedzi`,
  [`Top APIs by response time`]: `Pierwsze interfejsy API wg czasu odpowiedzi`,
  [`Top applications by calls`]: `Pierwsze aplikacje wg wywołań`,
  [`Dashboards`]: `Panele kontrolne`,
  [`Discover`]: `Wykryj`,
  [`UTC`]: `UTC`,
  [`Local time`]: `Czas miejscowy`,
  [`URI`]: `Identyfikator URI`,
  [`Response code`]: `Kod odpowiedzi`,
  [`Total time elapsed (ms)`]: `Łączny czas (ms)`,
  [`Timestamp`]: `Datownik`,
  [`Method`]: `Metoda`,
  [`Gateway IP`]: `Adres IP bramy`,
  [`Gateway Operations Dashboard`]: `Panel kontrolny operacji bramy`,
  [`Usage and latency information per gateway`]: `Informacje o wykorzystaniu i opóźnieniu na bramę`,
  [`Transaction ID`]: `ID transakcji`,
  [`Filters`]: `Filtry`,
  [`Fields`]: `Pola`,
  [`Clear all`]: `Wyczyść wszystko`,
  [`API Event`]: `Zdarzenie interfejsu API`,
  [`API Assembly Policy Latencies`]: `Opóźnienia strategii zespołu interfejsu API`,
  [`The diagram below shows the increasing latency time in milliseconds as the request progressed through the API assembly policies. This can be helpful in determining slow points or bottlenecks in the API assembly.`]: `Na poniższym diagramie przedstawiono rosnący czas opóźnienia (w milisekundach) w miarę przechodzenia żądania przez strategie zespołu interfejsu API. Może to być pomocne przy określaniu powolnych miejsc lub wąskich gardeł w zespole API.`,
  [`Collapse`]: `Zwiń`,
  [`Expand`]: `Rozwiń`,
  [`enter name`]: `Wprowadź nazwę`,
  [`Enter value`]: `Podaj wartość`,
  [`Time range`]: `Zakres czasu`,
  [`Select one of the following time range filters`]: `Wybierz jeden z następujących filtrów zakresu czasu`,
  [`Last 1 hour`]: `Ostatnia godzina`,
  [`Last {number} minutes`]: `Ostatnie {number} min.`,
  [`Last {number} hours`]: `Ostatnie {number} godz.`,
  [`Last {number} days`]: `Ostatnie {number} dni`,
  [`Start date`]: `Data rozpoczęcia`,
  [`End date`]: `Data końcowa`,
  [`Select from the following dropdowns and enter a value`]: `Wybierz z poniższych list rozwijanych i wprowadź wartość`,
  [`Select one of the following type filters`]: `Wybierz jeden z następujących filtrów typów`,
  [`All events`]: `Wszystkie zdarzenia`,
  [`Success only (2xx, 3xx)`]: `Tylko powodzenie (2xx, 3xx)`,
  [`Errors only (4xx, 5xx)`]: `Tylko błędy (4xx, 5xx)`,
  [`Import/Export`]: `Importuj/Eksportuj`,
  [`About {totalItems} results ({searchTime} seconds) at {time}`]: `Wyniki: około {totalItems} ({searchTime} s) o {time}`,
  [`About {totalItems} results ({searchTime} seconds) at {time} ({utcTime})`]: `Informacje o wynikach {totalItems} ({searchTime} sek.) o godzinie {time} ({utcTime})`,
  [`API calls`]: `Wywołania interfejsu API`,
  [`Export as CSV`]: `Eksportuj jako CSV`,
  [`Export as JSON`]: `Eksportuj jako JSON`,
  [`Export as PNG`]: `Eksportuj jako PNG`,
  [`Export as JPG`]: `Eksportuj jako JPG`,
  [`Edit filter`]: `Edytuj filtr`,
  [`Edit query`]: `Edytuj zapytanie`,
  [`This is a shared query. Any modifications will be made for all users this query is shared with.`]: `To jest zapytanie współużytkowane. Wszystkie modyfikacje zostaną wprowadzone dla wszystkich użytkowników, którym to zapytanie zostało udostępnione.`,
  [`This is a shared query. This will delete it for all users.`]: `To jest zapytanie współużytkowane. Spowoduje to usunięcie go dla wszystkich użytkowników.`,
  [`{queryname} has been updated for all users in the {org} provider organization`]: `Zapytanie {queryname} zostało zaktualizowane dla wszystkich użytkowników w organizacji dostawcy {org}`,
  [`{queryname} has been updated for all users in the {space} space`]: `Zapytanie {queryname} zostało zaktualizowany dla wszystkich użytkowników w obszarze {space}`,
  [`{queryname} has been updated for all users in the {catalog} catalog`]: `Zapytanie {queryname} zostało zaktualizowane dla wszystkich użytkowników w katalogu {catalog}`,
  [`{queryname} has been updated for all users in the cloud manager`]: `Zapytanie {queryname} zostało zaktualizowane dla wszystkich użytkowników w menedżerze chmury`,
  [`Stop sharing`]: `Zatrzymaj współużytkowanie`,
  [`Share query`]: `Współużytkuj zapytanie`,
  [`Share filter`]: `Współużytkuj filtr`,
  [`You do not have permissions to delete this filter`]: `Nie masz uprawnień do usunięcia tego filtru`,
  [`You do not have permissions to delete this query`]: `Nie masz uprawnień do usunięcia tego zapytania`,
  [`Are you sure you want to share the query {queryTitle} at the {org} provider organization scope?`]: `Czy na pewno chcesz udostępnić zapytanie {queryTitle} w zasięgu organizacji dostawcy {org}?`,
  [`Are you sure you want to share the query {queryTitle} at the {space} space scope?`]: `Czy na pewno chcesz udostępnić zapytanie {queryTitle} w zasięgu obszaru {space}?`,
  [`Are you sure you want to share the query {queryTitle} at the {catalog} catalog scope?`]: `Czy na pewno chcesz udostępnić zapytanie {queryTitle} w zasięgu katalogu {catalog}?`,
  [`Are you sure you want to share the query {queryTitle} at the cloud scope?`]: `Czy na pewno chcesz udostępnić zapytanie {queryTitle} w zasięgu chmury?`,
  [`Are you sure you want to stop sharing the query {queryTitle}?`]: `Czy na pewno zakończyć udostępnianie zapytania {queryTitle}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {org} provider organization scope?`]: `Czy na pewno zakończyć udostępnianie zapytania {queryTitle} w zasięgu organizacji dostawcy {org}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {space} space scope?`]: `Czy na pewno zakończyć udostępnianie zapytania {queryTitle} w zasięgu obszaru {space}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the {catalog} catalog scope?`]: `Czy na pewno zakończyć udostępnianie zapytania {queryTitle} w zasięgu katalogu {catalog}?`,
  [`Are you sure you want to stop sharing the query {queryTitle} at the cloud scope?`]: `Czy na pewno zakończyć udostępnianie zapytania {queryTitle} w zasięgu chmury?`,
  [`Are you sure you want to delete the query {queryTitle}?`]: `Czy na pewno chcesz usunąć zapytanie {queryTitle}?`,
  [`At least one query parameter expected`]: `Oczekiwano przynajmniej jednego parametru zapytania`,
  [`A provided query parameter is incomplete`]: `Podany parametr zapytania nie jest kompletny`,
  [`Could not parse query string`]: `Nie można przeanalizować łańcucha zapytania`,
  [`An unsupported query parameter was provided: {query}`]: `Podano nieobsługiwany parametr zapytania: {query}`,
  [`Invalid timeframe value: {value}`]: `Niepoprawna wartość przedziału czasu: {value}`,
  [`Value of {field} value must follow the date-time notation as defined by RFC 3339, section 5.6. Examples, 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`]: `Wartość {field} musi być zgodna z notacją daty i godziny zdefiniowaną w dokumencie RFC 3339 w sekcji 5.6. Przykłady: 2023, 2023-01, 2023-01-02, 2023-01-01T01:02:03Z`,
  [`Operator {operator} is not supported for field {field}`]: `Operator {operator} nie jest obsługiwany dla pola {field}`,
  [`The value of {field} must be a number`]: `Wartość {field} musi być liczbą`,
  [`Delete query`]: `Usuń zapytanie`,
  [`Delete filter`]: `Usuń filtr`,
  [`Save query`]: `Zapisz zapytanie`,
  [`Save filter`]: `Zapisz filtr`,
  [`Save filter as`]: `Zapisz filtr jako`,
  [`Save query as`]: `Zapisz zapytanie jako`,
  [`Save as...`]: `Zapisz jako...`,
  [`Save as`]: `Zapisz jako`,
  [`Paste query string to load the query`]: `Wklej łańcuch zapytania, aby załadować zapytanie`,
  [`Copy or paste filter string to load filters`]: `Skopiuj lub wklej łańcuch filtru w celu załadowania filtrów`,
  [`Query string`]: `Łańcuch zapytania`,
  [`View filter`]: `Wyświetl filtr`,
  [`View filter string`]: `Wyświetl łańcuch filtru`,
  [`Date (local time)`]: `Data (czas miejscowy)`,
  [`Coming soon, a new built in analytics feature`]: `Wkrótce dostępna będzie nowa wbudowana funkcja analityczna`,
  [`Analytics will help gather insights into your API runtime, manage service levels, set quotas, establish controls, set up security policies, manage communities, and analyse trends.`]: `Analiza pozwoli na zgromadzenie informacji o środowisku wykonawczym interfejsu API, zarządzanie poziomami usług, ustawianie limitów, tworzenie elementów sterujących, konfigurowanie strategii bezpieczeństwa, zarządzanie społecznościami i analizowanie trendów.`,
  [`Documentation`]: `Dokumentacja`,
  [`Deep dive into the types of API runtime data and how to use AWS s3 buckets to visualise it.`]: `Szczegółowy wgląd w typy danych środowiska wykonawczego API oraz sposób wykorzystania zasobników AWS s3 w celu ich wizualizacji.`,
  [`Blog`]: `Blog`,
  [`Learn about what data is available today and how to access it.`]: `Dowiedz się, jakie dane są dostępne dzisiaj i jak uzyskać do niego dostęp.`,
  [`Next steps`]: `Dalsze kroki`,
  [`The selected Secrets Manager service does not manage any certificates.`]: `Wybrana usługa Secrets Manager nie zarządza żadnymi certyfikatami.`,
  [`A certificate is required to secure the connection between API Connect and the domain of the gateway management endpoint. Certificates are also required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Certyfikat jest wymagany do zabezpieczenia połączenia między oprogramowaniem API Connect a domeną punktu końcowego zarządzania bramą. Certyfikaty są również wymagane do zabezpieczania połączeń między bramą a obsługiwanymi przez nią domenami. Wszystkie certyfikaty muszą być przechowywane w usłudze Secrets Manager. Usługa udostępnia bezpieczne repozytorium dla certyfikatów i pomaga zapobiegać przestojom, wysyłając powiadomienia, gdy zbliża się data utraty ważności certyfikatu po integracji z usługą Event Notifications. Aby rozpocząć:`,
  [`Certificates are required to secure the connections between the gateway and the domains that it handles. All certificates must be stored in a Secrets Manager service. The service provides a secure repository for the certificates, and helps to prevent outages by sending you notifications when the certificates are about to expire on integration with Event Notifications service. To get started,`]: `Certyfikaty są wymagane do zabezpieczania połączeń między bramą a obsługiwanymi przez nią domenami. Wszystkie certyfikaty muszą być przechowywane w usłudze Secrets Manager. Usługa udostępnia bezpieczne repozytorium dla certyfikatów i pomaga zapobiegać przestojom, wysyłając powiadomienia, gdy zbliża się data utraty ważności certyfikatu po integracji z usługą Event Notifications. Aby rozpocząć:`,
  [`Changing the Secrets Manager will remove all existing certificate and CA bundle assignments.`]: `Zmiana usługi Secrets Manager spowoduje usunięcie wszystkich istniejących przypisań certyfikatów i pakunków CA.`,
  [`Create a [Secrets Manager]({link}) service`]: `Tworzenie usługi [Secrets Manager]({link})`,
  [`Import the required certificates and CA bundles into the Secrets Manager service.`]: `Zaimportuj wymagane certyfikaty i pakunki CA do usługi Secrets Manager.`,
  [`No Secrets Manager services available`]: `Brak dostępnych usług Secrets Manager`,
  [`Select Secrets Manager service`]: `Wybierz usługę Secrets Manager`,
  [`Select the [Secrets Manager]({link}) service that manages the certificates for this gateway.`]: `Wybierz usługę [Secrets Manager]({link}), która zarządza certyfikatami dla tej bramy.`,
  [`The Secrets Manager service must manage the certificates for the domain of the gateway management endpoint and the domains that the gateway handles. Additionally, you must use IBM Cloud IAM to authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Usługa Secrets Manager musi zarządzać certyfikatami domeny punktu końcowego zarządzania bramą oraz domenami, które są obsługiwane przez bramę. Ponadto należy użyć programu IBM Cloud IAM, aby autoryzować usługę instancji zarezerwowanej produktu API Connect w celu uzyskania dostępu do usługi Secrets Manager.`,
  [`Then select the certificate for the domain of the management endpoint. Also select the corresponding CA bundle if the certificate was not assigned by a well-known certificate authority. The certificate and the bundle must be managed by the Secrets Manager service.`]: `Następnie wybierz certyfikat dla domeny punktu końcowego zarządzania. Wybierz także odpowiedni pakunek ośrodka CA, jeśli certyfikat nie został przypisany przez powszechnie znany ośrodek certyfikacji. Certyfikat i pakunek muszą być zarządzane przez usługę Secrets Manager.`,
  [`Then specify the domains that will be handled by this gateway. The SNI technique is utilized; as a result, multiple domains can be served via the same IP address and port without requiring the same certificate. Wild card format is supported. The default  (catch-all) domain of ‘*’ must be included as the final entry in the table. Enter other domain names as necessary, in each case specifying a certificate managed by the Secrets Manager service.`]: `Następnie określ domeny, które będą obsługiwane przez tę bramę. Używana jest technika SNI; w rezultacie wiele domen może być obsługiwanych za pośrednictwem tego samego adresu IP i portu, bez konieczności stosowania tego samego certyfikatu. Obsługiwany jest format wieloznaczny. Ostatnią pozycją musi być domena domyślna (catch-all) oznaczona ‘*’. W razie potrzeby wprowadź inne nazwy domenowe, podając w każdym przypadku certyfikat zarządzany przez usługę Secrets Manager.`,
  [`Using [IBM Cloud IAM]({link}), authorize the API Connect Reserved Instance service to access the Secrets Manager service.`]: `Za pomocą programu [IBM Cloud IAM]({link}) autoryzuj usługę instancji zarezerwowanej produktu API Connect w celu uzyskania dostępu do usługi Secrets Manager.`,
  [`Update existing certificate and CA bundle assignments by loading it from Secrets Manager.`]: `Zaktualizuj istniejące przypisania certyfikatów i pakunków CA, ładując je z programu Secrets Manager.`,
  [`Access s3 data`]: `Access s3 data`,
  [`Create resource`]: `Utwórz zasób`,
  [`Connect analytics data in s3 bucket with AWS`]: `Połącz dane analityczne w zasobniku s3 z AWS`,
  [`AWS account`]: `Konto AWS`,
  [`AWS account number`]: `Numer konta AWS`,
  [`Enter your 12 digit aws account id`]: `Wprowadź swój 12-cyfrowy identyfikator konta AWS`,
  [`Account id must be 12 digits`]: `Identyfikator konta musi mieć 12 cyfr`,
  [`Failed to update AWS account id.`]: `Aktualizacja identyfikatora konta AWS nie powiodła się.`,
  [`You have limited access to the catalogs.`]: `Użytkownik ma ograniczony dostęp do katalogów.`,
  [`You have limited access to the spaces.`]: `Masz ograniczony dostęp do obszarów.`,
  [`Contact admin to get more access.`]: `Skontaktuj się z administratorem, aby uzyskać więcej dostępu.`,
  [`You can't select this catalog. Please contact an admin if you require access.`]: `Nie można wybrać tego katalogu. Jeśli wymagany jest dostęp, skontaktuj się z administratorem.`,
  [`You can't select this space. Please contact an admin if you require access.`]: `Nie można wybrać tego obszaru. Jeśli wymagany jest dostęp, skontaktuj się z administratorem.`,
  [`You have limited access to the product billing.`]: `Masz ograniczony dostęp do informacji o opłatach za produkt.`,
  [`Successfully updated AWS account id. It may take few seconds to grant access to S3 data.`]: `Pomyślnie zaktualizowano identyfikator konta AWS. Uzyskanie dostępu do danych S3 może potrwać kilka sekund.`,
  [`An organization or a group is missing in the catalog chosen.`]: `W wybranym katalogu brakuje organizacji lub grupy.`,
  [`Secrets Manager instance`]: `Instancja komponentu Secrets Manager`,
  [`Consumer organizations with owners that are missing an email cannot be selected`]: `Nie można wybrać organizacji konsumenckich z właścicielami bez adresów e-mail`,
  [`You currently don’t have any data.`]: `Obecnie nie masz żadnych danych.`,
  [`You currently don’t have any organizations.`]: `Obecnie nie masz żadnych organizacji.`,
  [`Request to subscribe app {appname} to plan {plan} in product {prodname}:{version} ({orgname})`]: `Żądanie zasubskrybowania aplikacji {appname} w planie {plan} w produkcie {prodname}:{version} ({orgname})`,
  [`Request to {operation} product: {prodname}:{version}`]: `Żądanie wykonania operacji {operation} dla produktu: {prodname}:{version}`,
  [`Request to upgrade application {appname} to production`]: `Żądanie zaktualizowania aplikacji {appname} do wersji produkcyjnej`,
  [`Onboarding request for {email}`]: `Żądanie aktywowania dla {email}`,
  [`Product lifecycle request`]: `Żądanie cyklu życia produktu`,
  [`Subscription request`]: `Żądanie subskrypcji`,
  [`Application lifecycle request`]: `Żądanie cyklu życia aplikacji`,
  [`Consumer Onboarding request`]: `Żądanie aktywowania konsumenta`,
  [`Originator`]: `Osoba rozpoczynająca`,
  [`Edit analytics service advanced settings`]: `Edytuj zaawansowane ustawienia usługi analizy`,
  [`Advanced settings`]: `Ustawienia zaawansowane`,
  [`Must be a positive integer`]: `Musi być dodatnią liczbą całkowitą`,
  [`Maximum value is {maxValue}`]: `Maksymalna wartość to {maxValue}`,
  [`Maximum value is {maxValueDays}d or {maxValueHours}h`]: `Maksymalna wartość to {maxValueDays}d lub {maxValueHours}h`,
  [`Configure advanced settings for the analytics deployment.`]: `Skonfiguruj zaawansowane ustawienia dla wdrożenia analizy.`,
  [`Value must end in 'd' for days or 'h' for hours. For example 30d or 12h.`]: `Wartość musi się kończyć znakiem 'd' dla dni lub 'h' dla godzin. Na przykład: 30d lub 12h.`,
  [`Rollover settings`]: `Ustawienia zastępowania`,
  [`Retention settings`]: `Ustawienia czasu przechowywania`,
  [`Delete an index when it meets the following condition:`]: `Usuń indeks, gdy spełni on następujący warunek:`,
  [`Rollover the write alias to a new index when the managed index meets one of the following conditions:`]: `Przełącz alias zapisu na nowy indeks, gdy indeks zarządzany spełni jeden z następujących warunków:`,
  [`Minimum document count`]: `Minimalna liczba dokumentów`,
  [`The minimum number of documents required to roll over the index.`]: `Minimalna liczba dokumentów wymaganych do przewinięcia indeksu.`,
  [`Minimum index age`]: `Minimalny wiek indeksu`,
  [`The minimum index age required to roll over the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be rolled over once its age reaches 30 days, while 96h means the index will be rolled over once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Minimalny wiek indeksu wymagany do przewinięcia indeksu. Wiek indeksu to czas między utworzeniem indeksu a chwilą obecną. Użyj 'd' dla dni i 'h' dla godzin. Na przykład wartość 30d oznacza, że indeks zostanie zastąpiony po osiągnięciu wieku 30 dni, a wartość 96h oznacza, że indeks zostanie zastąpiony po osiągnięciu wieku 96 godzin. Maksymalna wartość to {maxValueDays}d lub {maxValueHours}h.`,
  [`The minimum index age required to delete the index. Index age is the time between index creation and now. Use 'd' for days and 'h' for hours. For example, 30d means the index will be deleted once its age reaches 30 days, while 96h means the index will be deleted once its age reaches 96 hours. Maximum values are {maxValueDays}d or {maxValueHours}h.`]: `Minimalny wiek indeksu wymagany do usunięcia indeksu. Wiek indeksu to czas między utworzeniem indeksu a chwilą obecną. Użyj 'd' dla dni i 'h' dla godzin. Na przykład wartość 30d oznacza, że indeks zostanie usunięty po osiągnięciu wieku 30 dni, a wartość 96h oznacza, że indeks zostanie usunięty po osiągnięciu wieku 96 godzin. Maksymalna wartość to {maxValueDays}d lub {maxValueHours}h.`,
  [`Time range:`]: `Przedział czasu:`,
  [`Time range: Before {datetime}`]: `Zakres czasu: przed {datetime}`,
  [`Time range: After {datetime}`]: `Zakres czasu: po {datetime}`,
  [`Filter before`]: `Filtruj przed`,
  [`Filter after`]: `Filtruj po`,
  [`Filter exact`]: `Filtruj dokładnie`,
  [`Filter gt`]: `Filtruj większe niż`,
  [`Filter lt`]: `Filtruj mniejsze niż`,
  [`Filter equals`]: `Filtruj równe`,
  [`Filter by`]: `Filtruj wg`,
  [`Filter out`]: `Odfiltruj`,
  [`Delete failed`]: `Niepowodzenie usuwania`,
  [`Delete warning`]: `Ostrzeżenie usuwania`,
  [`Could not delete saved query {queryTitle}.`]: `Nie można usunąć zapisanego zapytania {queryTitle}.`,
  [`Request responded successfully but {queryTitle} has not finished deleting yet.`]: `Żądanie odpowiedziało pomyślnie, ale {queryTitle} nie zakończyło jeszcze usuwania.`,
  [`API governance`]: `Zarządzanie interfejsem API`,
  [`Something's wrong`]: `Coś jest nie tak`,
  [`We were unable to connect to the service.`]: `Nie można było się połączyć się z usługą.`,
  [`Invalid date`]: `Niepoprawna data`,
  [`Invalid time`]: `Niepoprawny czas`,
  [`End date must be after the start date`]: `Data końcowa musi być późniejsza od daty początkowej`,
  [`Duplicate`]: `Duplikuj`,
  [`Duplicate query`]: `Zduplikuj zapytanie`,
  [`Are you sure you want to make a duplicate of {queryTitle}?`]: `Czy na pewno chcesz utworzyć duplikat zapytania {queryTitle}?`,
  [`No queries saved`]: `Nie zapisano zapytań`,
  [`No queries shared`]: `Brak udostępnionych zapytań`,
  [`To save a query, start by creating one then save.`]: `Aby zapisać zapytanie, zacznij od jego utworzenia, a następnie zapisz je.`,
  [`Share a saved query and allow others to use it.`]: `Udostępnij zapisane zapytanie i pozwól innym na jego używanie.`,
  [`Query updated`]: `Zaktualizowano zapytanie`,
  [`Query update failed`]: `Aktualizacja zapytania się nie powiodła`,
  [`Share successful`]: `Udostępnienie się powiodło`,
  [`Share failed`]: `Udostępnienie się nie powiodło`,
  [`Delete successful`]: `Usunięcie się powiodło`,
  [`Query unshared`]: `Zakończono udostępnienie zapytania`,
  [`Unshare {queryTitle} failed`]: `Zakończenie udostępniania zapytania {queryTitle} się nie powiodło`,
  [`Query copied`]: `Zapytanie zostało skopiowane`,
  [`Duplicate {queryTitle} failed`]: `Zduplikowanie zapytania {queryTitle} się nie powiodło`,
  [`The query {queryTitle} has been updated.`]: `Zapytanie {queryTitle} zostało zaktualizowane.`,
  [`The query {queryTitle} has been shared.`]: `Zapytanie {queryTitle} zostało udostępnione.`,
  [`The query {queryTitle} has been deleted.`]: `Zapytanie {queryTitle} zostało usunięte.`,
  [`The query {queryTitle} is no longer shared.`]: `Zapytanie {queryTitle} nie jest już udostępnione.`,
  [`A copy of {queryTitle} has been created.`]: `Utworzono kopię zapytania {queryTitle}.`,
  [`About queries`]: `Informacje o zapytaniach`,
  [`Queries are a means of fine-tuning the analytics data used in the dashboards and the Discover view. When a query is applied, all dashboards will be updated to include only the selected data.`]: `Zapytania są sposobem dostrajania danych analitycznych używanych w panelach kontrolnych i w widoku Wykryj. Po zastosowaniu zapytania wszystkie panele kontrolne zostaną zaktualizowane tak, aby zawierały tylko wybrane dane.`,
  [`It is possible to use one of the predefined time ranges or to use a specific time range to the nearest second using the Custom option.`]: `Możliwe jest użycie jednego z predefiniowanych zakresów czasu lub użycie określonego zakresu czasu z dokładnością do najbliższej sekundy przy użyciu opcji Niestandardowa.`,
  [`Fields can be used to specify only certain data should be included. It is possible to filter by almost every attribute of an api event.`]: `Pola mogą być używane do określenia tylko niektórych danych. Możliwe jest filtrowanie według niemal każdego atrybutu zdarzenia interfejsu API.`,
  [`There are then different operators available depending on the field type.`]: `W zależności od typu pola dostępne są różne operatory.`,
  [`String fields allow equals, not, starts with, ends with, regex.`]: `Pola łańcuchowe pozwalają na używanie operatorów równe, nie, zaczyna się od, kończy się na, wyrażenie regularne.`,
  [`It is possible to use contains and not contains to provide a list of values for a single field (for example a list of API versions).`]: `Istnieje możliwość użycia operatora zawiera i nie zawiera w celu podania listy wartości dla pojedynczego pola (na przykład listy wersji interfejsu API).`,
  [`IP address fields allow equals and not operators, they also allow the value to be specified in CIDR notation to allow the use of net masks to select specific networks.`]: `Pola adresu IP pozwalają na operatory równe oraz nie, umożliwiają również określenie wartości w notacji CIDR, aby umożliwić korzystanie z masek sieciowych w celu wybrania określonych sieci.`,
  [`Regular expressions can be very powerful to select specific values but are slower to execute so it is recommended to use other operators such as 'starts with' and 'ends with' if possible.`]: `Wyrażenia regularne mogą dawać wiele możliwości w celu wybrania konkretnych wartości, ale są wolniejsze, dlatego zaleca się używanie innych operatorów, takich jak 'zaczyna się od' i 'kończy się na', jeśli to możliwe.`,
  [`Query filters for different fields are combined using the logical AND operator. This means that adding two filters for the same field name will only return results that satisfy both filters.`]: `Filtry zapytań dla różnych pól są łączone za pomocą operatora logicznego I. Oznacza to, że dodanie dwóch filtrów dla tej samej nazwy pola spowoduje zwrócenie tylko tych wyników, które spełniają oba filtry.`,
  [`The {type} query parameter is a simple way to specify only success or error events. This is uses the {statusCode} field. If only specific status codes are needed then a field filter can be used. The value for {statusCodeTwo} fields is normally a full string such as {okStatus} and not just the numerical HTTP return code.`]: `Parametr zapytania {type} jest prostym sposobem określania tylko zdarzeń powodzenia lub błędów. W tym celu używane jest pole {statusCode}. Jeśli wymagane są tylko określone kody statusu, można użyć filtru pola. Wartość w polach {statusCodeTwo} jest zwykle pełnym łańcuchem, takim jak {okStatus}, a nie tylko numerycznym kodem powrotu HTTP.`,
  [`It is possible to save queries for later reuse and also share them with your current scope level for others to use too - for example provider organization or catalog.`]: `Możliwe jest zapisywanie zapytań w celu późniejszego ponownego wykorzystania, a także współużytkowanie ich z bieżącym poziomem zasięgu, aby inne osoby używały zbyt-na przykład organizacji dostawcy lub katalogu.`,
  [`n/a`]: `n/d`,
  [`Note: The 'provider organization ID' and 'provider organization name' fields will be shown in the event payload as {orgId} and {orgName} respectively; the 'consumer organization ID' and 'consumer organization name' fields will be shown as {developerOrgId} and {developerOrgName}.`]: `Uwaga: pola 'ID organizacji dostawcy' i 'nazwa organizacji dostawcy' zostaną wyświetlone w ładunku zdarzenia odpowiednio jako {orgId} i {orgName}. Pola 'ID organizacji konsumenta' i 'nazwa organizacji konsumenta' będą wyświetlane jako {developerOrgId} i {developerOrgName}.`,
  [`No authentication token`]: `Brak tokenu uwierzytelniania`,
  [`Contact your administrator for further assistance.`]: `Aby uzyskać dalszą pomoc, skontaktuj się z administratorem.`
};
