// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2021
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

let instanceCounters = {}
function unwrapOperationSwitch(
  operation,
  policy,
  nodes,
  policies,
  operationMap
) {
  if (!policy['operation-switch']) return
  if (!policy['operation-switch'].case) return
  for (let i = 0; i < policy['operation-switch'].case.length; i++) {
    var thisCase = policy['operation-switch'].case[i]
    if (!thisCase.operations || thisCase.operations.length === 0) continue
    for (let j = 0; j < thisCase.operations.length; j++) {
      let policiesToProcess = []
      if (
        operation.verb &&
        operation.path &&
        operation.verb === thisCase.operations[j].verb &&
        operation.path === thisCase.operations[j].path
      ) {
        policiesToProcess = thisCase.execute
      } else if (
        typeof operation === 'string' &&
        operation === thisCase.operations[j]
      ) {
        policiesToProcess = thisCase.execute
      } else if (
        typeof operation === 'string' &&
        operationMap[operation].verb === thisCase.operations[j].verb &&
        operationMap[operation].path === thisCase.operations[j].path
      ) {
        policiesToProcess = thisCase.execute
      } else if (
        operation.operationId &&
        operation.operationId === thisCase.operations[j]
      ) {
        policiesToProcess = thisCase.execute
      } else if (
        operation.operationId &&
        operationMap[operation.operationId].verb ===
          thisCase.operations[j].verb &&
        operationMap[operation.operationId].path === thisCase.operations[j].path
      ) {
        policiesToProcess = thisCase.execute
      }
      if (policiesToProcess.length > 0) {
        policiesToProcess.forEach(function(policy) {
          const key = Object.keys(policy)[0]
          if (key === 'operation-switch') {
            // look deeper through the policy to unwrap the operation-switches
            unwrapOperationSwitch(operation, policy, nodes, operationMap)
          } else {
            // just push it on the stack
            const obj = policy[key]
            obj.$$type = key
            const versionedKey = obj.version ? `${key}:${obj.version}` : key
            if (policies) {
              let definition = policies[versionedKey] || policies[key]
              if (!definition && key === 'assembly') {
                definition = {
                  type: 'subflow',
                  info: {
                    display: {
                      color: '#4B68FA',
                      icon: 'open_in_browser',
                    },
                  },
                }
              }
              if (definition) {
                obj.$$display = definition.info.display
                obj.$$schema = definition.properties
                obj.$$classes = definition.classes || []
                obj.$$logic = !!definition.logic
                obj.$$subflow = definition.type === 'subflow'
                obj.$$application = !!definition.application
              }
            }
            if (instanceCounters[key] === undefined) {
              instanceCounters[key] = 1
              obj.$$instance = 1
            } else {
              instanceCounters[key]++
              obj.$$instance = instanceCounters[key]
            }
            if (thisCase.operations.length > 1)
              obj.$$operations = thisCase.operations.length - 1
            nodes.push(obj)
          }
        })
        return
      }
    }
  }
}

angular
  .module('apiconnect-assembly')
  .filter('byCategory', function() {
    return function byCategory(policies) {
      const categorized = {}
      policies.forEach(function(policy) {
        policy.categories.forEach(function(category) {
          let thesePolicies = categorized[category]
          if (!thesePolicies) thesePolicies = []
          thesePolicies.push(policy)
          categorized[category] = thesePolicies
        })
      })
      return categorized
    }
  })
  .filter('debug', function() {
    return function(obj) {
      try {
        return JSON.stringify(obj, false, ' ')
      } catch (e) {
        return 'Cannot transform'
      }
    }
  })
  .filter('transformNodeModel', [
    '$injector',
    'DynamicIcon',
    function($injector, DynamicIcon) {
      return function transformNodeModel(
        nodes,
        addressBase,
        policies,
        filter,
        operationMap,
        resetCounters
      ) {
        if (resetCounters) instanceCounters = {}
        const transformedNodes = []
        if (!nodes) return
        let ComponentRegistry
        try {
          ComponentRegistry = $injector.get('ComponentRegistry')
        } catch (e) {
          // that's ok - it isn't guaranteed to be available and won't be used if not
        }

        for (var i = 0; i < nodes.length; i++) {
          var obj = {}
          var path = [addressBase, i]

          Object.keys(nodes[i]).forEach(function(key) {
            const localPath = path.concat(key)
            if (key.indexOf('$$') !== 0) {
              if (filter && key === 'operation-switch') {
                // look deeper through the policy to unwrap the operation-switches
                unwrapOperationSwitch(
                  filter,
                  nodes[i],
                  transformedNodes,
                  policies,
                  operationMap
                )
              } else {
                obj = nodes[i][key]

                if (obj.$ref) {
                  if (!obj.$$resolved) {
                    // de-ref if ComponentRegistry is available
                    if (ComponentRegistry) {
                      const deref = ComponentRegistry.getReference(
                        obj.$ref,
                        'assemblies'
                      )
                      obj.$$resolved = angular.copy(deref)
                      obj.$$resolved.$ref = obj.$ref
                      obj.$$resolved.$$readOnly = true
                      obj.$$resolved.$$parent = obj
                      obj.$$resolved.title = obj.title
                    }
                  }
                  obj = obj.$$resolved
                }
                // just push it on the stack
                obj.$$type = key
                obj.$$address = localPath.join('.')
                obj.$$container = nodes
                obj.$$containerIndex = i
                const versionedKey = obj.version ? `${key}:${obj.version}` : key
                if (policies) {
                  let definition = policies[versionedKey] || policies[key]
                  if (!definition && key === 'assembly') {
                    definition = {
                      type: 'subflow',
                      info: {
                        display: {
                          color: '#4B68FA',
                          icon: 'open_in_browser',
                        },
                      },
                    }
                  }
                  if (
                    (definition && versionedKey === 'switch:1.5.0') ||
                    versionedKey === 'if:1.5.0'
                  ) {
                    definition = {
                      ...definition,
                      logic: true,
                      classes: ['logic'],
                      info: {
                        ...definition.info,
                        display: {
                          color: '#878d96',
                          icon: 'choices',
                        },
                      },
                    }
                  }
                  if (definition) {
                    obj.$$display =
                      definition.info.display && definition.info.display.custom
                        ? DynamicIcon(key, obj)
                        : definition.info.display
                    obj.$$schema = definition.properties
                    obj.$$classes = definition.classes || []
                    obj.$$logic = !!definition.logic
                    obj.$$subflow = definition.type === 'subflow'
                    obj.$$application = !!definition.application
                  }
                }
                if (instanceCounters[key] === undefined) {
                  instanceCounters[key] = 1
                  obj.$$instance = 1
                } else {
                  instanceCounters[key]++
                  obj.$$instance = instanceCounters[key]
                }
                transformedNodes.push(obj)
              }
            }
          })
        }
        return transformedNodes
      }
    },
  ])
  .filter('policyDisplayName', function() {
    return function policyDisplayName(policy) {
      if (policy.name) return policy.name
      if (policy.selectedApplication) {
        if (policy.selectedApplication.displayName) {
          return policy.selectedApplication.displayName
        }
        if (policy.selectedApplication.name) {
          return policy.selectedApplication.name
        }
        if (policy.selectedApplication.type) {
          return policy.selectedApplication.type
        }
      }
      if (policy.nodeName) return policy.nodeName
      if (policy.title) return policy.title
      return policy.$$type
    }
  })
  .filter('policyDisplayVersion', function() {
    return function policyDisplayVersion(policy, swaggerDocument) {
      const legacyPolicies = [
        'gatewayscript:1.0.0',
        'invoke:1.0.0',
        'invoke:1.5.0',
        'json-to-xml:1.0.0',
        'jwt-generate:1.0.0',
        'jwt-validate:1.0.0',
        'activity-log:1.0.0',
        'activity-log:1.5.0',
        'map:1.0.0',
        'proxy:1.0.0',
        'proxy:1.5.0',
        'redact:1.0.0',
        'redact:1.5.0',
        'validate:1.0.0',
        'set-variable:1.0.0',
        'switch:1.0.0',
        'switch:1.5.0',
        'if:1.5.0',
        'xslt:1.0.0',
        'validate-usernametoken:1.0.0',
        'validate-usernametoken:1.1.0',
        'xml-to-json:1.0.0',
      ]

      if (swaggerDocument) {
        if (
          legacyPolicies.indexOf(`${policy.title}:${policy.version}`) !== -1 &&
          swaggerDocument['x-ibm-configuration'].gateway ===
            'datapower-api-gateway' &&
          policy.version
        )
          return policy.version
      }
      return ''
    }
  })
  .filter('clauseMessage', [
    'translateFilter',
    'Expressions',
    function(translateFilter, Expressions) {
      return function(clause) {
        if (clause.$$expressionString) return clause.$$expressionString
        let message = ''
        if (clause.operations) {
          clause.operations.forEach(function(operation) {
            if (!operation) return
            if (typeof operation === 'string') {
              message += `${operation}, `
            } else if (operation.operationId) {
              message += `${operation.operationId}, `
            } else {
              message += `${operation.verb} ${operation.path}, `
            }
          })
          if (message.length > 2)
            message = message.substring(0, message.length - 2)
          if (message === '') return translateFilter('empty case')
        } else if (clause.condition && clause.condition.operations) {
          clause.condition.operations.forEach(function(operation) {
            if (!operation) return
            if (typeof operation === 'string') {
              message += `${operation}, `
            } else {
              message += `${operation.verb} ${operation.path}, `
            }
          })
          if (message.length > 2)
            message = message.substring(0, message.length - 2)
          if (message === '') return translateFilter('empty case')
        } else if (clause.condition && clause.condition.expression) {
          message = clause.condition.expression
        } else if (typeof clause.condition === 'string') {
          if (clause.$$expression)
            return (
              Expressions.expressionAsString(clause.$$expression) ||
              clause.condition
            )
          if (clause.condition) {
            // if condition matchs verb + path pattern, adjust the format
            const condition = clause.condition.replace(/^\(|\)$/g, '')
            let resPart = condition
            let verbIdx = 0
            do {
              verbIdx = resPart.indexOf('$httpVerb()')
              if (verbIdx > -1) {
                const origin = resPart
                resPart = resPart
                  .substring(verbIdx + '$httpVerb()'.length)
                  .trim()
                if (resPart.indexOf('=') === 0) {
                  resPart = resPart.substring(1).trim()
                  let secondIdx = resPart.indexOf("'", resPart.indexOf("'") + 1)
                  if (secondIdx < 0) secondIdx = resPart.length
                  const verb = resPart
                    .substring(0, secondIdx + 1)
                    .replace(/^\'|\'$/g, '')
                  resPart = resPart.substring(secondIdx + 1).trim()
                  const pathIdx = resPart.indexOf('$operationPath()')
                  if (pathIdx > -1) {
                    resPart = resPart
                      .substring(pathIdx + '$operationPath()'.length)
                      .trim()
                    if (resPart.indexOf('=') === 0) {
                      resPart = resPart.substring(1).trim()
                      let secondIdx = resPart.indexOf(
                        "'",
                        resPart.indexOf("'") + 1
                      )
                      if (secondIdx < 0) secondIdx = resPart.length
                      const path = resPart
                        .substring(0, secondIdx + 1)
                        .replace(/^\'|\'$|\)$/g, '')
                      resPart = `${origin
                        .substring(0, verbIdx)
                        .trim()} ${verb} ${path} ${resPart
                        .substring(secondIdx + 1)
                        .trim()}`
                    }
                  } else {
                    resPart = `${origin
                      .substring(0, verbIdx)
                      .trim()} ${verb} ${resPart}`
                  }
                }
              }
            } while (resPart.indexOf('$httpVerb()', verbIdx + 1) > -1)
            return resPart
          }
          if (clause.condition === '') return translateFilter('Empty case')
        } else if (clause.otherwise) {
          return translateFilter('Otherwise')
        } else if (clause.errors) {
          message = clause.errors.join(', ')
        } else if (clause.default) {
          return translateFilter('Default')
        }
        return message
      }
    },
  ])
  .filter('catchMessage', [
    'translateFilter',
    function(translateFilter) {
      return function(catchStatement) {
        if (catchStatement.errors && catchStatement.errors.length > 0) {
          return catchStatement.errors.join(', ')
        } else if (catchStatement.default) {
          return translateFilter('Default')
        }
        return translateFilter('Empty catch')
      }
    },
  ])
  .filter('iconForNode', function() {
    return function(node) {
      if (node.selectedApplication && node.selectedApplication.name)
        return `connector:${node.selectedApplication.name}`
      if (node.info && node.info.display && node.info.display.icon)
        return node.info.display.icon
      if (node.$$display && node.$$display.icon) return node.$$display.icon
      if (node.$$type === 'invoke') return 'rss'
      if (node.$$type === 'catch') return 'error'
      return ''
    }
  })
  .filter('formatResponseData', function() {
    return function(data, headers) {
      if (headers['content-type'] === 'application/json') {
        return JSON.stringify(data, null, 2)
      }
      return data
    }
  })
  .filter('httpErrorToMessage', [
    'HttpErrors',
    function(HttpErrors) {
      return function(errorCode) {
        if (HttpErrors[errorCode]) return HttpErrors[errorCode]
        return ''
      }
    },
  ])
  .filter('noAssemblies', [
    'translateFilter',
    function(translateFilter) {
      return function(assemblies) {
        if (!assemblies || assemblies.length === 0)
          return translateFilter('No assemblies found.')
        return translateFilter(
          'There are no assemblies that match the chosen filters.'
        )
      }
    },
  ])
  .filter('assemblyTitleQuery', function() {
    return function(assemblies, query) {
      if (!assemblies) return assemblies
      if (!query) return assemblies
      const term = query.toLowerCase()
      return assemblies.filter(function(assembly) {
        return (
          assembly.info &&
          assembly.info.title &&
          assembly.info.title.toLowerCase().indexOf(term) > -1
        )
      })
    }
  })
  .filter('expressionToLabel', function() {
    return function(expression) {
      let expr = expression
      if (angular.isArray(expression)) expr = expression[0]
      if (angular.isObject(expr)) return window.jseb(expr)
      return expr
    }
  })
  .filter('highlight', function() {
    return function(input, matching) {
      if (!input || !matching || matching === '') return input
      // Escape any special characters (e.g. $, [, {, etc.) with a backslash
      matching = matching.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')
      const reg = new RegExp(matching, 'ig')
      return input.replace(reg, function(match) {
        return `<span class="ui-match">${match}</span>`
      })
    }
  })
  .filter('isActionCategory', function() {
    return function(node) {
      if (
        node.info &&
        node.info.categories &&
        node.info.categories.length > 0 &&
        node.info.categories.indexOf('Action') > -1
      )
        return true
      if (
        node.$$categories &&
        node.$$categories.length > 0 &&
        node.$$categories.indexOf('Action') > -1
      )
        return true
      return false
    }
  })
  .filter('isTriggerCategory', function() {
    return function(node) {
      if (
        node.info &&
        node.info.categories &&
        node.info.categories.length > 0 &&
        node.info.categories.indexOf('Trigger') > -1
      )
        return true
      if (
        node.$$categories &&
        node.$$categories.length > 0 &&
        node.$$categories.indexOf('Trigger') > -1
      )
        return true
      return false
    }
  })
  .filter('actionForTrigger', function() {
    return function actionForTrigger(trigger) {
      const action = ''

      if (trigger.description && trigger.description.length > 0) {
        return trigger.description
      }

      if (trigger.selectedTrigger) {
        return trigger.selectedTrigger.displayName
          ? trigger.selectedTrigger.displayName
          : trigger.selectedTrigger.name
      }

      return action
    }
  })
  .filter('actionForNode', function() {
    return function actionForNode(node, nodes) {
      let action = ''
      const existingNames = {}

      if (node.description && node.description.length > 0) {
        return node.description
      }

      if (node.name && node.dataModel) {
        return `${node.name} ${node.dataModel}`
      }
      if (node.$$type === 'Application') {
        if (node.nodeName) {
          const applicationName = `${node.selectedApplication.displayName} `
          return node.nodeName.replace(applicationName, '')
        }
        if (node.selectedAction.displayName) {
          action = node.selectedAction.displayName
        } else {
          action = node.selectedAction.name
        }

        let index = 2
        nodes.forEach(function(n) {
          const key = Object.keys(n)[0]
          if (key === 'Application') {
            if (
              node.selectedApplication.name ===
                n.Application.selectedApplication.name &&
              node.selectedAction.name === n.Application.selectedAction.name
            ) {
              if (n.Application.nodeName) {
                const applicationName = `${n.Application.selectedApplication.displayName} `
                existingNames[
                  n.Application.nodeName.replace(applicationName, '')
                ] = true
              }
            }
          }
        })
        let foundId = false
        if (existingNames[action]) {
          while (!foundId) {
            if (!existingNames[`${action} ${index}`]) {
              foundId = true
            } else {
              index++
            }
          }
          action = `${action} ${index}`
        }
      }
      return action
    }
  })
