// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2016, 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

// Node module: apiconnect-assembly

'use strict'

const basicTypes = {
  integer: {
    type: 'integer',
    format: 'int32',
  },
  long: {
    type: 'integer',
    format: 'int64',
  },
  float: {
    type: 'number',
    format: 'float',
  },
  double: {
    type: 'number',
    format: 'double',
  },
  string: {
    type: 'string',
  },
  byte: {
    type: 'string',
    format: 'byte',
  },
  binary: {
    type: 'string',
    format: 'binary',
  },
  boolean: {
    type: 'boolean',
  },
  date: {
    type: 'string',
    format: 'date',
  },
  dateTime: {
    type: 'string',
    format: 'date-time',
  },
  password: {
    type: 'string',
    format: 'password',
  },
  array: {
    type: 'array',
  },
  object: {
    type: 'object',
  },
}

// specialization of the excellent json-schema-view listed in package.json
angular
  .module('apiconnect-assembly.mapper-schema', [
    'apiconnect-assembly.recursion-helper',
  ])

  .controller('SchemaPropertyController', [
    '$scope',
    'SchemaReferences',
    function($scope, SchemaReferences) {
      if ($scope.schema && $scope.schema.$defName) {
        $scope.extendingObjectName = $scope.schema.$defName
      }

      $scope.propertyCollapsed = $scope.open < 1 && !$scope.isPrimitive

      $scope.toggleProperty = function() {
        $scope.$broadcast('collapse-property', $scope.property)
        $scope.propertyCollapsed = !$scope.propertyCollapsed
      }

      if (!$scope.propertyCollapsed)
        SchemaReferences.unwindRefs(
          $scope.property,
          $scope.swaggerDocument,
          $scope.references
        )

      $scope.propertyIsArray = $scope.property.type === 'array'

      if ($scope.property.type === 'object') $scope.propertyIsObject = true // is an object...
      if ($scope.property.properties !== undefined)
        $scope.propertyIsObject = true // or has properties defined...
      if (
        $scope.property.type === 'array' &&
        $scope.property.items &&
        $scope.property.items.type === 'object'
      )
        $scope.propertyIsObject = true // or is an array of objects
    },
  ])

  .directive('apicMapperSchema', [
    'RecursionHelper',
    'SchemaReferences',
    'ExtensionType',
    function(RecursionHelper, SchemaReferences, ExtensionType) {
      function link($scope) {
        /*
         * Recursively walk the schema and add property 'name' to property objects
         */
        function addPropertyName(schema) {
          if (!schema) {
            return
          }
          if (angular.isObject(schema.items)) {
            addPropertyName(schema.items)
          } else if (angular.isObject(schema.properties)) {
            Object.keys(schema.properties).forEach(function(propertyName) {
              let prefix = ''
              if (
                angular.isObject(schema.properties[propertyName].xml) &&
                schema.properties[propertyName].xml.attribute === true &&
                propertyName.indexOf('@') !== 0
              ) {
                prefix = '@'
              }
              schema.properties[propertyName].name = prefix + propertyName
              addPropertyName(schema.properties[propertyName])
            })
          }
        }

        // Determine unique name for an extending type by looking at the
        // x-xsi-type and x-xsi-type-uniquename attrbutes
        $scope.getUniqueTypeName = function(type) {
          if (type['x-xsi-type-uniquename']) {
            return type['x-xsi-type-uniquename']
          }
          return type['x-xsi-type']
        }

        // are we a root?
        $scope.isRoot = typeof $scope.root === 'undefined' ? true : false
        if ($scope.editable === false) $scope.schemaEditable = false
        if (typeof $scope.schemaEditable === 'undefined') {
          $scope.schemaEditable = !$scope.schema.$ref
        }

        if (!$scope.editable) {
          // if we're not editable, clone the schema to prevent
          // accidental modifications leaking into the document
          $scope.schema = angular.copy($scope.schema)
        }

        SchemaReferences.unwindRefs(
          $scope.schema,
          $scope.swaggerDocument,
          $scope.references
        )

        addPropertyName($scope.schema)

        // Determine if a schema is an array
        $scope.isArray = $scope.schema && $scope.schema.type === 'array'

        // Determine if a schema is a primitive
        // primitive if it has a schema with a type, but not object or array, and no properties or items
        $scope.isPrimitive =
          $scope.schema &&
          !$scope.schema.properties &&
          !$scope.schema.items &&
          typeof $scope.schema.type !== 'undefined' &&
          $scope.schema.type !== 'array' &&
          $scope.schema.type !== 'object'

        // mark as collapsed in the open count has reduced to zero, and the item is not a primitive
        $scope.isCollapsed = $scope.open < 1 && !$scope.isPrimitive

        if (!$scope.isCollapsed)
          SchemaReferences.unwindRefs(
            $scope.schema,
            $scope.swaggerDocument,
            $scope.references
          )

        $scope.isDiscriminator = false
        if (
          !$scope.isArray &&
          !$scope.isPrimitive &&
          $scope.schema &&
          ($scope.schema['x-ibm-discriminator'] ||
            ($scope.schema.discriminator &&
              $scope.schema.discriminator.propertyName))
        ) {
          // must check if parent is one of the concrete types this schema can be extended by
          let concreteParent = false
          if ($scope.schema.$$polyPattern === 'allOf') {
            const typeLen = $scope.schema.$$polyTypes.length
            for (let i = 0; i < typeLen; i++) {
              const extendingType = $scope.schema.$$polyTypes[i]
              if (
                $scope.getUniqueTypeName(extendingType) ===
                $scope.getUniqueTypeName($scope.parentSchema)
              ) {
                concreteParent = true
                break
              }
            } // end for
          }
          // no need to show discriminator choice with a concrete parent type
          if (!concreteParent) {
            $scope.isDiscriminator = true
          }
        }

        $scope.showProperty = function(propertyName) {
          if ($scope.isCollapsed) {
            // do not show any properties when collapsed
            return false
          }
          if (propertyName === 'x-ibm-discriminator') {
            // do not show the x-ibm-discriminator property when there are no
            // extending types or this is not a discriminator
            if (!$scope.schema.$$polyTypes || !$scope.isDiscriminator) {
              return false
            }
          }
          return true
        }

        /*
         * Toggles the 'collapsed' state
         */
        $scope.toggle = function() {
          $scope.isCollapsed = !$scope.isCollapsed
          SchemaReferences.unwindRefs(
            $scope.schema,
            $scope.swaggerDocument,
            $scope.references
          )
          $scope.$emit('json-schema-view-property-collapsed')
        }

        $scope.deleteProperty = function() {
          if (
            $scope.parentSchema &&
            $scope.parentSchema.properties &&
            $scope.parentSchema.properties[$scope.schema.name]
          ) {
            delete $scope.parentSchema.properties[$scope.schema.name]
          } else if ($scope.parentSchema && $scope.parentSchema.items) {
            delete $scope.parentSchema.items
          }
          $scope.$emit(
            'json-schema-view-property-modified',
            $scope.parentSchema
          )
        }

        /*
         * Returns true if property is required in given schema
         */
        $scope.isRequired = function(schema) {
          const parent = $scope.$parent.schema

          if (parent && Array.isArray(parent.required) && schema.name) {
            return parent.required.indexOf(schema.name) > -1
          }

          return false
        }

        /*
         * Returns true if the schema is too simple to be collapsible
         */
        $scope.isPrimitiveCollapsible = function() {
          return $scope.schema.description
        }

        $scope.$on('collapse-property', function(event, property) {
          if (property === $scope.schema) $scope.toggle()
        })

        $scope.getPath = function() {
          if (!$scope.$parent.getPath) {
            // we're at the root. Escape periods in root property with '\\.' apiconnect-assembly/#446
            return $scope.schema.$$title.replace(/\./g, '\\.')
          }
          let parentPath = $scope.$parent.getPath()
          parentPath = parentPath.replace(/\.\$item$/, '')
          if ($scope.schema.name) {
            return `${parentPath}.${$scope.schema.name.replace(/\./g, '\\.')}`
          }
          if ($scope.$parent.isArray) {
            return parentPath
          }
          return parentPath
        }

        // $scope.getDimensionArray = function() {
        //   var dimensions;
        //   if (!$scope.$parent.getDimensionArray) {
        //     // we're at the root
        //     if ($scope.isArray || $scope.$parent.isArray) {
        //       dimensions = [$scope.getPath()];
        //     } else {
        //       dimensions = [];
        //     }
        //     return dimensions;
        //   }
        //   if ($scope.isArray || $scope.$parent.isArray) {
        //     dimensions = $scope.$parent.getDimensionArray();
        //     dimensions.pop();
        //     dimensions.push($scope.getPath());
        //     return dimensions;
        //   } else {
        //     // we're not an array, so replace the parent path
        //     dimensions = $scope.$parent.getDimensionArray();
        //     dimensions.pop();
        //     dimensions.push($scope.getPath());
        //     return dimensions;
        //   }
        // };

        $scope.getDimensionArray = function() {
          if (!$scope.$parent.getDimensionArray) {
            // we're at the root
            if ($scope.isArray) {
              return [$scope.getPath()]
            }
            return []
          }
          if ($scope.isArray) {
            const dimensions = $scope.$parent.getDimensionArray()
            dimensions.push($scope.getPath())
            return dimensions
          }
          return $scope.$parent.getDimensionArray()
        }

        $scope.getContainer = function() {
          if (!$scope.$parent.getContainer) {
            // we're at the root
            return $scope.getPath()
          }
          return $scope.isArray ? $scope.getPath() : $scope.$parent.getPath()
        }

        function flattenParentTypes(allOf) {
          let ret = []
          if (allOf) {
            allOf.forEach(function(allOfItem) {
              if (allOfItem.$$extendedParents) {
                let parents = allOfItem.$$extendedParents
                while (parents && parents.types && parents.types.length > 0) {
                  ret = ret.concat(parents.types)
                  parents = parents.parentTypes
                } // end while
              }
            })
          }
          return ret
        }

        function setTypesFromDiscriminator(discriminatorValue) {
          if ($scope.schema.$$polyPattern === 'allOf') {
            $scope.schema.allOf = []
          } else {
            $scope.schema.oneOf = []
          }
          // If there is a mapping table, then use the definition name from the table.
          if (
            $scope.schema.discriminator &&
            $scope.schema.discriminator.mapping &&
            $scope.schema.discriminator.mapping[discriminatorValue]
          ) {
            discriminatorValue =
              $scope.schema.discriminator.mapping[discriminatorValue]
          }
          if (!discriminatorValue) {
            // unset selection will show all possible types again
            $scope.schema.$$polyTypes.forEach(function(extendingType) {
              if ($scope.schema.$$polyPattern === 'allOf') {
                $scope.schema.allOf = $scope.schema.allOf.concat(
                  extendingType.allOf
                )
              } else {
                $scope.schema.oneOf.push(extendingType)
              }
            })
          } else {
            $scope.schema.$$polyTypes.forEach(function(extendingType) {
              if (
                extendingType.$ref === discriminatorValue ||
                $scope.getUniqueTypeName(extendingType) === discriminatorValue
              ) {
                if ($scope.schema.$$polyPattern === 'allOf') {
                  $scope.schema.allOf = $scope.schema.allOf.concat(
                    extendingType.allOf
                  )
                  const parentTypes = flattenParentTypes(extendingType.allOf)
                  if (parentTypes.length > 0) {
                    $scope.schema.allOf = $scope.schema.allOf.concat(
                      parentTypes
                    )
                  }
                } else {
                  $scope.schema.oneOf.push(extendingType)
                }
              }
            })
          }
        }

        $scope.previousType = undefined
        $scope.$discriminatorType = function(type) {
          const discriminatorName = $scope.schema['x-ibm-discriminator']
            ? 'x-ibm-discriminator'
            : $scope.schema.discriminator.propertyName
          if (arguments.length) {
            // setter
            type = type === 'undefined' ? undefined : type
            setTypesFromDiscriminator(type)
            const path = `${$scope.getPath()}.${discriminatorName}`
            ExtensionType.setValueForPath(path, type)
            $scope.$emit('json-schema-view-refresh', true) // trigger canvas update
            $scope.previousType = undefined // Force update
          }
          const extensionPath = `${$scope.getPath()}.${discriminatorName}`
          // The discriminator is used to select whether all of one of the child extension types
          // is displayed.
          const discriminator =
            ExtensionType.getNumberOfMappingsForPath(extensionPath) >= 1
              ? ExtensionType.getValueForPath(extensionPath)
              : ''
          // first time through, show the extension(s)
          if (
            $scope.schema.$$polyTypes &&
            $scope.previousType !== discriminator
          ) {
            $scope.previousType = discriminator
            setTypesFromDiscriminator(discriminator)
            if ($scope.schema.allOf) {
              $scope.schema.allOf.forEach(function(allOfItem) {
                addPropertyName(allOfItem)
              })
            }
            $scope.$emit('json-schema-view-refresh', false) // trigger canvas update
          }
          // The default value of the (first) mapping is displayed in the Extension Type field
          const defaultDiscriminator = ExtensionType.getDefaultValueForPath(
            extensionPath
          )
          return defaultDiscriminator
        }
      }

      return {
        restrict: 'E',
        template: require('../../html/mapper-schema/json-schema-view.html'),
        scope: {
          schema: '=',
          parentSchema: '=',
          name: '=',
          open: '=',
          swaggerDocument: '=',
          root: '=',
          editable: '=',
          references: '=',
        },
        compile(element) {
          // Use the compile function from the RecursionHelper,
          // And return the linking function(s) which it returns
          return RecursionHelper.compile(element, link)
        },
      }
    },
  ])

  .controller('AddPropertyController', [
    '$scope',
    function($scope) {
      $scope.basicTypes = basicTypes
      $scope.propertyType = 'string'

      $scope.addProperty = function() {
        if ($scope.isArray) {
          // we're adding an item definition to an array schema
          $scope.schema.items = angular.copy(basicTypes[$scope.propertyType])
        } else {
          // we're adding a property definition to an object schema
          if (!$scope.propertyName || $scope.propertyName === '') return
          if (!$scope.schema.properties) $scope.schema.properties = {}
          if ($scope.schema.properties[$scope.propertyName]) return
          $scope.schema.properties[$scope.propertyName] = angular.copy(
            basicTypes[$scope.propertyType]
          )
          $scope.schema.properties[$scope.propertyName].name =
            $scope.propertyName
        }
        $scope.addProp = false
        delete $scope.propertyName
        $scope.$emit('json-schema-view-property-modified')
      }
    },
  ])
